import React, { useState } from 'react';
import {
  View,
  TouchableOpacity,
  FlatList,
  Text,
  useWindowDimensions,
  // Image,
  // Platform,
} from 'react-native';
// import { Icon } from 'react-native-elements';
import { connect } from 'react-redux';
import * as actions from '@actions';
import { bindActionCreators } from 'redux';
import { SwipeRow } from 'react-native-swipe-list-view';
import Checked from '@images/icons/check.svg';
import { HeaderProfile } from '@components/organisms/headers';
import Error from '@images/icons/error.svg';
import Delete from '@images/icons/delete_white.svg';
import Trash from '@images/icons/trash.svg';
import { Button } from '@components/atoms';
import { styles } from './styles';

const FavouritesView = ({
  favourites,
  setFavourites,
  deleteFavourites,
  fontSize,
  navigation,
}) => {
  const [visibleAnswers, setVisibleAnswers] = useState([]);

  const { height } = useWindowDimensions();

  const doTestFavourites = () => {
    navigation.navigate('Questions');
  };

  const renderItem = ({ item, index }) => {
    const question = item.text;
    return (
      <SwipeRow
        // style={styles.containerSwipe}
        leftOpenValue={75}
        rightOpenValue={-75}
        swipeToOpenPercent={90}
        closeOnRowPress={true}
        // setScrollEnabled={() => {}}
        recalculateHiddenLayout
        disableRightSwipe={true}
        onRowPress={() => {
          if (visibleAnswers.includes(index)) {
            setVisibleAnswers(
              visibleAnswers.filter((value) => !(value === index)),
            );
          } else {
            setVisibleAnswers([...visibleAnswers, index]);
          }
        }}
      >
        <View style={styles.swipeRowBack}>
          <TouchableOpacity
            style={[styles.backBtn, styles.backBtnRight]}
            onPress={() => setFavourites(item)}
          >
            <View style={{ padding: 22 }}>
              <Delete />
            </View>
          </TouchableOpacity>
        </View>
        <View style={styles.titleContainer}>
          <Text style={{ fontSize }}>
            <Text style={{ fontSize, fontWeight: 'bold' }}>
              {item.block}
              {'  '}
            </Text>
            {question}
          </Text>
          {visibleAnswers.includes(index) && (
            <View>
              {item.answers.map((answer, i) => (
                <View
                  key={`${i}-answer`}
                  style={{ flexDirection: 'row', marginTop: 10, paddingTop: 8 }}
                >
                  <View style={{ flex: 1, alignItems: 'center' }}>
                    {item.correctAnswers.includes(answer) ? (
                      <Checked />
                    ) : (
                      <Error />
                    )}
                  </View>
                  <View style={{ flex: 6 }}>
                    <Text style={{ fontSize }}>{answer}</Text>
                  </View>
                </View>
              ))}
            </View>
          )}
        </View>
      </SwipeRow>
    );
  };

  const renderDelimiter = () => {
    return <View style={styles.delimiter} />;
  };

  const renderEmptyComponent = () => {
    return (
      <View>
        <Text style={[styles.titleEmpty, { fontSize }]}>
          Cюда можно добавить вопросы из теста
        </Text>
      </View>
    );
  };
 
  return (
    <View style={[styles.root, { height, minHeight: 500 }]}>
      <HeaderProfile welcome />
      <View style={styles.buttonContainer} >
      <Button
        customStyles={styles.btn}
        title="Тест по избранному"
        titleStyle={styles.titleButton}
        light
        iconContainerStyle={styles.iconButton}
        onPress={() => doTestFavourites()}
      />
      <Button
        customStyles={[styles.btn, styles.delete]}
        title="Удалить всё"
        titleStyle={styles.titleButton}
        light
        icon={Trash}
        iconContainerStyle={styles.iconButton}
        onPress={() => deleteFavourites()}
      />
      </View>
      <FlatList
        data={favourites}
        ListEmptyComponent={renderEmptyComponent}
        keyExtractor={(item) => item.code}
        renderItem={renderItem}
        contentContainerStyle={
          favourites.length
            ? styles.contentContainer
            : styles.emptyContentContainer
        }
        ItemSeparatorComponent={renderDelimiter}
      />
    </View>
  );
};

const mapStateToProps = ({ favourites, settings: { fontSize } }) => ({
  favourites,
  fontSize,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFavourites: actions.setFavourites,
      deleteFavourites: actions.deleteFavourites,
    },
    dispatch,
  );

export const Favourites = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FavouritesView);
