import React from 'react';
import { ImageBackground, TouchableOpacity, View, Text } from 'react-native';
import { connect } from 'react-redux';
import { useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import imageProfile from '@images/profile-header.png';
import Apple from '@images/icons/apple.svg';
import Menu from '@images/icons/menu.svg';
import { styles } from './styles';

const Header = ({
  displayName = '',
  welcome,
  style,
  //navigation,
}) => {
  const navigation = useNavigation();
 
  const { width } = useWindowDimensions();

  return (
    <ImageBackground
      source={imageProfile}
      style={[
        styles.image,
        style,
        { flexDirection: 'row', justifyContent: 'space-between' },
      ]}
    >
      <View>
        {width < 1070 && (
          <TouchableOpacity onPress={() => navigation.openDrawer()}>
            <Menu />
          </TouchableOpacity>
        )}
        <Text style={styles.text}>{displayName} </Text>
        {welcome && (
          <Text style={styles.title}>Добро пожаловать в приложение</Text>
        )}
      </View>
      {!(width < 1070) && (
        <Text style={[styles.title, styles.details]}>
          Официальные вопросы Ростехнадзора на 2022 год{'\n'}Последняя дата
          обновления: 01.04.2022
        </Text>
      )}
    </ImageBackground>
  );
};

const mapStateToProps = ({ user }) => ({
  user,
  isLogin: !!user.id,
  displayName: user.displayName,
});

export const HeaderProfile = connect(mapStateToProps)(Header);
