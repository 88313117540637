export default {
  76: {
    632: [
      {
        code: "58356",
        text: 'Какое определение соответствует понятию "авария", изложенному в Федеральном законе от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов"?',
        answers: [
          "Отказ и (или) повреждение технических устройств, применяемых на опасном производственном объекте, отклонение от установленного режима технологического процесса",
          "Разрушение сооружений и (или) технических устройств, применяемых на опасном производственном объекте, неконтролируемые взрыв и (или) выброс опасных веществ",
          "Контролируемое или неконтролируемое горение, а также взрыв опасного производственного объекта",
          "Нарушение целостности или разрушение сооружений и технических устройств опасного производственного объекта при отсутствии взрыва либо выброса опасных веществ",
        ],
        correctAnswers: [
          "Разрушение сооружений и (или) технических устройств, применяемых на опасном производственном объекте, неконтролируемые взрыв и (или) выброс опасных веществ",
        ],
      },
      {
        code: "58357",
        text: 'Что входит в понятие "инцидент" в соответствии с Федеральным законом от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов"?',
        answers: [
          "Отказ или повреждение технических устройств, применяемых на опасном производственном объекте, отклонение от установленного режима технологического процесса",
          "Разрушение сооружений и (или) технических устройств, применяемых на опасном производственном объекте, неконтролируемые взрыв и (или) выброс опасных веществ, при которых нет пострадавших",
          "Контролируемое и (или) неконтролируемое горение, а также взрыв опасного производственного объекта, не сопровождающиеся выбросом в окружающую среду опасных веществ",
          "Нарушение целостности или полное разрушение сооружений и технических устройств опасного производственного объекта при отсутствии взрыва либо выброса опасных веществ",
        ],
        correctAnswers: [
          "Отказ или повреждение технических устройств, применяемых на опасном производственном объекте, отклонение от установленного режима технологического процесса",
        ],
      },
      {
        code: "58358",
        text: 'На какие организации распространяются нормы Федерального закона от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов"?',
        answers: [
          "На все организации независимо от их организационно-правовых форм и форм собственности, осуществляющие деятельность в области промышленной безопасности опасных производственных объектов на территории Российской Федерации и на иных территориях, над которыми Российская Федерация осуществляет юрисдикцию в соответствии с законодательством Российской Федерации и нормами международного права",
          "На организации независимо от их организационно-правовых форм и форм собственности, осуществляющие деятельность в области промышленной безопасности опасных производственных объектов только на территории Российской Федерации",
          "На все государственные и негосударственные некоммерческие организации, эксплуатирующие опасные производственные объекты в порядке, установленном законодательством Российской Федерации",
          "На коммерческие организации независимо от форм осуществления деятельности в области промышленной безопасности опасных производственных объектов",
        ],
        correctAnswers: [
          "На все организации независимо от их организационно-правовых форм и форм собственности, осуществляющие деятельность в области промышленной безопасности опасных производственных объектов на территории Российской Федерации и на иных территориях, над которыми Российская Федерация осуществляет юрисдикцию в соответствии с законодательством Российской Федерации и нормами международного права",
        ],
      },
      {
        code: "58359",
        text: 'Что понимается под требованиями промышленной безопасности в соответствии с Федеральным законом от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов"?',
        answers: [
          "Условия, запреты, ограничения, а также другие обязательные требования, содержащиеся в федеральных законах, соблюдение которых обеспечивает промышленную безопасность",
          "Требования, содержащиеся в нормативных технических документах, принимаемых федеральным органом исполнительной власти, специально уполномоченным в области промышленной безопасности, в рамках его компетенции",
          "Условия, запреты, ограничения и другие обязательные требования, содержащиеся в Федеральном законе от 21.07.1997 N 116-ФЗ, других федеральных законах и принимаемых в соответствии с ними нормативных правовых актах Президента Российской Федерации, нормативных правовых актах Правительства Российской Федерации, а также федеральных нормах и правилах в области промышленной безопасности",
          "Условия, запреты, ограничения, установленные в нормативных актах, соблюдение которых обеспечивает состояние защищенности жизненно важных интересов личности и общества от аварий на опасных производственных объектах, а также последствий указанных аварий",
        ],
        correctAnswers: [
          "Условия, запреты, ограничения и другие обязательные требования, содержащиеся в Федеральном законе от 21.07.1997 N 116-ФЗ, других федеральных законах и принимаемых в соответствии с ними нормативных правовых актах Президента Российской Федерации, нормативных правовых актах Правительства Российской Федерации, а также федеральных нормах и правилах в области промышленной безопасности",
        ],
      },
      {
        code: "58360",
        text: "В каком нормативном правовом акте содержится перечень критериев, по которым производственный объект относится к категории опасных?",
        answers: [
          'В Федеральном законе "О промышленной безопасности опасных производственных объектов"',
          'В постановлении Правительства Российской Федерации "О регистрации объектов в государственном реестре"',
          'В Указе Президента Российской Федерации "Об утверждении перечня опасных производственных объектов"',
          "В Положении о Федеральной службе по экологическому, технологическому и атомному надзору",
        ],
        correctAnswers: [
          'В Федеральном законе "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "58361",
        text: "На какие классы опасности, в зависимости от уровня потенциальной опасности аварий на них для жизненно важных интересов личности и общества, подразделяются опасные производственные объекты?",
        answers: [
          "I класс - опасные производственные объекты чрезвычайно высокой опасности;  II класс - опасные производственные объекты высокой опасности;  III класс - опасные производственные объекты средней опасности;  IV класс - опасные производственные объекты низкой опасности",
          "I класс - опасные производственные объекты низкой опасности; II класс - опасные производственные объекты средней опасности;  III класс - опасные производственные объекты высокой опасности;  IV класс - опасные производственные объекты чрезвычайно высокой опасности",
          "I класс - опасные производственные объекты высокой опасности; II класс - опасные производственные объекты средней опасности;  III класс - опасные производственные объекты низкой опасности;  IV класс - неопасные производственные объекты (вероятность аварии равна нулю)",
        ],
        correctAnswers: [
          "I класс - опасные производственные объекты чрезвычайно высокой опасности;  II класс - опасные производственные объекты высокой опасности;  III класс - опасные производственные объекты средней опасности;  IV класс - опасные производственные объекты низкой опасности",
        ],
      },
      {
        code: "58362",
        text: "Что понимается под обоснованием безопасности опасного производственного объекта?",
        answers: [
          "Это документ, содержащий сведения об условиях безопасной эксплуатации опасного производственного объекта, требования к эксплуатации, капитальному ремонту, консервации и ликвидации опасного производственного объекта",
          "Это документ, содержащий сведения о результатах оценки риска аварии на опасном производственном объекте и связанной с ней угрозы, требования к безопасной эксплуатации опасного производственного объекта, требования к обслуживающему персоналу",
          "Это документ, содержащий сведения о результатах оценки риска аварии на опасном производственном объекте и связанной с ней угрозы, условия безопасной эксплуатации опасного производственного объекта, требования к эксплуатации, капитальному ремонту, консервации и ликвидации опасного производственного объекта",
        ],
        correctAnswers: [
          "Это документ, содержащий сведения о результатах оценки риска аварии на опасном производственном объекте и связанной с ней угрозы, условия безопасной эксплуатации опасного производственного объекта, требования к эксплуатации, капитальному ремонту, консервации и ликвидации опасного производственного объекта",
        ],
      },
      {
        code: "58363",
        text: "В каком из перечисленных случаев требования промышленной безопасности к эксплуатации, капитальному ремонту, консервации и ликвидации опасного производственного объекта (ОПО) могут быть установлены в обосновании безопасности опасного производственного объекта?",
        answers: [
          "В случае, если при проектировании, строительстве, эксплуатации, реконструкции, капитальном ремонте, консервации или ликвидации опасного производственного объекта требуется отступление от требований промышленной безопасности, установленных федеральными нормами и правилами в области промышленной безопасности, таких требований недостаточно и (или) они не установлены",
          "При подготовке проектной документации на любой опасный объект независимо от класса опасности",
          "В случае, если разработчиком проектной документации является иностранная организация",
          "При разработке плана по локализации и ликвидации последствий аварий на опасных объектах",
        ],
        correctAnswers: [
          "В случае, если при проектировании, строительстве, эксплуатации, реконструкции, капитальном ремонте, консервации или ликвидации опасного производственного объекта требуется отступление от требований промышленной безопасности, установленных федеральными нормами и правилами в области промышленной безопасности, таких требований недостаточно и (или) они не установлены",
        ],
      },
      {
        code: "58364",
        text: 'Какой экспертизе в соответствии с Федеральным законом от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов" подлежит обоснование безопасности опасного производственного объекта?',
        answers: [
          "Государственной экспертизе",
          "Экспертизе промышленной безопасности",
          "Экологической экспертизе",
        ],
        correctAnswers: ["Экспертизе промышленной безопасности"],
      },
      {
        code: "58365",
        text: "В течение какого времени организация, эксплуатирующая опасный производственный объект, при внесении изменений в обоснование безопасности опасного производственного объекта должна направить их в Ростехнадзор?",
        answers: [
          "В течение одного месяца после внесения изменений",
          "В течение 10 рабочих дней со дня получения положительного заключения экспертизы промышленной безопасности",
          "В течение десяти рабочих дней со дня передачи обоснования на экспертизу промышленной безопасности",
          "В течение одного месяца после утверждения изменений",
        ],
        correctAnswers: [
          "В течение 10 рабочих дней со дня получения положительного заключения экспертизы промышленной безопасности",
        ],
      },
      {
        code: "58366",
        text: "Уполномочены ли иные федеральные органы исполнительной власти помимо Федеральной службы по экологическому, технологическому и атомному надзору осуществлять специальные разрешительные, контрольные или надзорные функции в области промышленной безопасности?",
        answers: [
          "Да, если Президентом Российской Федерации или Правительством Российской Федерации им предоставлено такое право",
          'Нет, осуществление этих функций противоречит Федеральному закону от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
          "Да, только если указанные органы функционируют в условиях чрезвычайной ситуации",
        ],
        correctAnswers: [
          "Да, если Президентом Российской Федерации или Правительством Российской Федерации им предоставлено такое право",
        ],
      },
      {
        code: "58367",
        text: "Какое право не предоставлено должностным лицам Ростехнадзора при осуществлении федерального государственного надзора в области промышленной безопасности?",
        answers: [
          "Знакомиться с технической документацией, электронными базами данных, информационными системами контролируемых лиц в части, относящейся к предмету и объему контрольного (надзорного) мероприятия",
          "Выдавать лицензии на отдельные виды деятельности, связанные с повышенной опасностью промышленных производств",
          "Выдавать контролируемым лицам рекомендации по обеспечению безопасности и предотвращению нарушений обязательных требований, принимать решения об устранении контролируемыми лицами выявленных нарушений обязательных требований и о восстановлении нарушенного положения;",
          "Беспрепятственно по предъявлении служебного удостоверения и в соответствии с полномочиями, установленными решением контрольного (надзорного) органа о проведении контрольного (надзорного) мероприятия, посещать (осматривать) производственные объекты, если иное не предусмотрено федеральными законами",
        ],
        correctAnswers: [
          "Выдавать лицензии на отдельные виды деятельности, связанные с повышенной опасностью промышленных производств",
        ],
      },
      {
        code: "58368",
        text: "В каком случае должностные лица Ростехнадзора вправе привлекать к административной ответственности лиц, виновных в нарушении требований промышленной безопасности?",
        answers: [
          "Это не относится к их компетенции",
          "При осуществлении федерального государственного надзора в области промышленной безопасности",
          "Только если это сопряжено с направлением в суд материалов о привлечении указанных лиц к уголовной ответственности за нарушение требований",
        ],
        correctAnswers: [
          "При осуществлении федерального государственного надзора в области промышленной безопасности",
        ],
      },
      {
        code: "58369",
        text: "Какие виды экспертизы проектной документации проводятся в соответствии с Градостроительным кодексом Российской Федерации?",
        answers: [
          "Только государственная экспертиза проектной документации",
          "Государственная экспертиза проектной документации - для особо опасных, технически сложных и уникальных объектов, для всех остальных - негосударственная экспертиза",
          "Как государственная, так и негосударственная экспертиза по выбору застройщика или технического заказчика, за исключением случаев, когда проводится только государственная экспертиза",
        ],
        correctAnswers: [
          "Как государственная, так и негосударственная экспертиза по выбору застройщика или технического заказчика, за исключением случаев, когда проводится только государственная экспертиза",
        ],
      },
      {
        code: "58370",
        text: "Кто устанавливает порядок организации и проведения государственной экспертизы проектной документации и результатов инженерных изысканий?",
        answers: [
          "Минстрой России",
          "Правительство Российской Федерации",
          "Минстрой России совместно с Ростехнадзором",
          "Главгосэкспертиза",
        ],
        correctAnswers: ["Правительство Российской Федерации"],
      },
      {
        code: "58371",
        text: "В отношении каких из перечисленных объектов капитального строительства государственная экспертиза проектов не проводится?",
        answers: [
          "Объектов, строительство, реконструкцию и (или) капитальный ремонт которых предполагается осуществлять на территориях двух и более субъектов Российской Федерации",
          "Объектов капитального строительства, в отношении которых не требуется получение разрешения на строительство",
          "Особо опасных, технически сложных и уникальных объектов",
          "Объектов, строительство, реконструкцию и (или) капитальный ремонт которых предполагается осуществлять в исключительной экономической зоне Российской Федерации, на континентальном шельфе Российской Федерации, во внутренних морских водах и в территориальном море Российской Федерации",
        ],
        correctAnswers: [
          "Объектов капитального строительства, в отношении которых не требуется получение разрешения на строительство",
        ],
      },
      {
        code: "58372",
        text: "Кто проводит государственную экспертизу проектной документации особо опасных и технически сложных объектов?",
        answers: [
          "Организация, имеющая лицензию Министерства строительства и жилищно-коммунального хозяйства Российской Федерации",
          "Федеральный орган исполнительной власти, осуществляющий функции по выработке и реализации государственной политики и нормативно-правовому регулированию в сфере строительства, архитектуры, градостроительства или подведомственное ему государственное (бюджетное или автономное) учреждение",
          "Организация, имеющая лицензию Ростехнадзора или Федеральной службы по надзору в сфере природопользования на проведение данного вида экспертизы",
          "Независимые эксперты",
          "Органы государственной власти субъектов Российской Федерации",
        ],
        correctAnswers: [
          "Федеральный орган исполнительной власти, осуществляющий функции по выработке и реализации государственной политики и нормативно-правовому регулированию в сфере строительства, архитектуры, градостроительства или подведомственное ему государственное (бюджетное или автономное) учреждение",
        ],
      },
      {
        code: "58373",
        text: "Кто проводит строительный контроль?",
        answers: [
          "Подрядчик и застройщик, технический заказчик, лицо, ответственное за эксплуатацию здания, сооружения либо организация, осуществляющая подготовку проектной документации и привлеченная техническим заказчиком (застройщиком) по договору для осуществления строительного контроля",
          "Саморегулируемая организация",
          "Федеральный орган исполнительной власти, уполномоченный на осуществление строительного надзора (контроля)",
          "Органы исполнительной власти субъектов Российской Федерации, уполномоченные на осуществление регионального строительного надзора (контроля)",
        ],
        correctAnswers: [
          "Подрядчик и застройщик, технический заказчик, лицо, ответственное за эксплуатацию здания, сооружения либо организация, осуществляющая подготовку проектной документации и привлеченная техническим заказчиком (застройщиком) по договору для осуществления строительного контроля",
        ],
      },
      {
        code: "58374",
        text: "При строительстве и реконструкции каких объектов капитального строительства осуществляется государственный строительный надзор?",
        answers: [
          "При строительстве объектов капитального строительства, проектная документация которых подлежит экспертизе в соответствии со статьей 49 Градостроительного кодекса Российской Федерации",
          "При строительстве и реконструкции любых объектов",
          "Только при строительстве объектов, которые в соответствии с Градостроительным кодексом Российской Федерации являются особо опасными, уникальными или технически сложными",
          "Только при строительстве объектов, площадь которых составляет более 1500 м2",
        ],
        correctAnswers: [
          "При строительстве объектов капитального строительства, проектная документация которых подлежит экспертизе в соответствии со статьей 49 Градостроительного кодекса Российской Федерации",
        ],
      },
      {
        code: "58375",
        text: "Что не является предметом государственного строительного надзора?",
        answers: [
          "Наличие разрешения на строительство",
          "Соблюдение требований к порядку осуществления строительного контроля, установленных Градостроительным кодексом, иными нормативными правовыми актами",
          "Соблюдение требований, установленные частью 4 статьи 52 Градостроительного кодекса Российской Федерации, к обеспечению консервации объекта капитального строительства",
          "Наличие декларации промышленной безопасности",
        ],
        correctAnswers: ["Наличие декларации промышленной безопасности"],
      },
      {
        code: "58376",
        text: "Кто осуществляет государственный строительный надзор за строительством, реконструкцией объектов капитального строительства, отнесенных Градостроительным кодексом Российской Федерации к особо опасным, технически сложным и уникальным?",
        answers: [
          "Федеральный орган исполнительной власти, уполномоченный на осуществление федерального государственного строительного надзора",
          "Орган исполнительной власти субъекта Российской Федерации, уполномоченный на осуществление регионального государственного строительного надзора",
          "Федеральный орган исполнительной власти, уполномоченный на проведение государственной экспертизы проектной документации, либо подведомственные ему государственные (бюджетные или автономные) учреждения",
        ],
        correctAnswers: [
          "Федеральный орган исполнительной власти, уполномоченный на осуществление федерального государственного строительного надзора",
        ],
      },
      {
        code: "58377",
        text: "Что входит в обязанности лица, осуществляющего строительство здания или сооружения, в соответствии с законодательством о градостроительной деятельности?",
        answers: [
          "Контроль за соответствием применяемых строительных материалов и изделий, в том числе строительных материалов, производимых на территории, на которой осуществляется строительство, требованиям проектной документации в течение всего процесса строительства",
          "Наблюдение за производством работ, регистрация действий, противоречащих законодательству о градостроительной деятельности",
          "Только наблюдение за производством работ, своевременной доставкой строительных материалов и изделий",
          "Только осуществление контроля за качеством применяемых строительных материалов",
        ],
        correctAnswers: [
          "Контроль за соответствием применяемых строительных материалов и изделий, в том числе строительных материалов, производимых на территории, на которой осуществляется строительство, требованиям проектной документации в течение всего процесса строительства",
        ],
      },
      {
        code: "58378",
        text: "Каким образом должна обеспечиваться безопасность здания или сооружения в процессе эксплуатации?",
        answers: [
          "Только посредством мониторинга состояния основания",
          "Только посредством периодических осмотров строительных конструкций",
          "Только посредством технического обслуживания систем инженерно-технического обеспечения",
          "Только посредством проведения экспертизы промышленной безопасности",
          "Посредством проведения всех перечисленных мероприятий, включая проведение текущих ремонтов здания или сооружения",
        ],
        correctAnswers: [
          "Посредством проведения всех перечисленных мероприятий, включая проведение текущих ремонтов здания или сооружения",
        ],
      },
      {
        code: "58379",
        text: "Кто должен принять меры, предупреждающие причинение вреда населению и окружающей среде, при прекращении эксплуатации здания или сооружения согласно Техническому регламенту о безопасности зданий и сооружений?",
        answers: [
          "Представитель территориального органа Ростехнадзора",
          "Организация, эксплуатирующая здание или сооружение",
          "Собственник здания или сооружения",
          "Организация, проводившая экспертизу промышленной безопасности",
        ],
        correctAnswers: ["Собственник здания или сооружения"],
      },
      {
        code: "58380",
        text: "В какой форме осуществляется обязательная оценка соответствия зданий и сооружений, а также связанных со зданиями и с сооружениями процессов эксплуатации?",
        answers: [
          "В форме производственного контроля (надзора)",
          "В форме государственного строительного надзора, а также государственного контроля",
          "В форме эксплуатационного и государственного контроля (надзора)",
        ],
        correctAnswers: [
          "В форме эксплуатационного и государственного контроля (надзора)",
        ],
      },
      {
        code: "58381",
        text: "Какие опасные производственные объекты не относятся к особо опасным и технически сложным объектам?",
        answers: [
          "Опасные производственные объекты I и II классов опасности, на которых получаются, используются, перерабатываются, образуются, хранятся, транспортируются, уничтожаются опасные вещества",
          "Опасные производственные объекты, на которых получаются, транспортируются, используются расплавы черных и цветных металлов, сплавы на основе этих расплавов с применением оборудования, рассчитанного на максимальное количество расплава 500 килограммов и более",
          "Опасные производственные объекты, на которых используются стационарно установленные грузоподъемные механизмы",
          "Опасные производственные объекты, на которых ведутся горные работы (за исключением добычи общераспространенных полезных ископаемых и разработки россыпных месторождений полезных ископаемых, осуществляемых открытым способом без применения взрывных работ), работы по обогащению полезных ископаемых",
        ],
        correctAnswers: [
          "Опасные производственные объекты, на которых используются стационарно установленные грузоподъемные механизмы",
        ],
      },
      {
        code: "58382",
        text: "Какими документами могут устанавливаться обязательные требования в сфере технического регулирования?",
        answers: [
          "Техническими регламентами",
          "Национальными стандартами, сводами правил",
          "Техническими регламентами, национальными стандартами, сводами правил",
        ],
        correctAnswers: ["Техническими регламентами"],
      },
      {
        code: "58383",
        text: 'Какими документами могут приниматься технические регламенты в соответствии с Федеральным законом от 27.12.2002 N 184-ФЗ "О техническом регулировании"?',
        answers: [
          "Только федеральными законами и межправительственными соглашениями стран - участниц Евразийского экономического союза",
          "Только постановлениями Правительства Российской Федерации и федеральными законами",
          "Любыми нормативными актами Российской Федерации",
          "Международными договорами Российской Федерации, подлежащими ратификации в порядке, установленном законодательством Российской Федерации, или в соответствии с международными договорами Российской Федерации, ратифицированными в порядке, установленном законодательством Российской Федерации, или указами Президента Российской Федерации, или постановлениями Правительства Российской Федерации, или нормативным правовым актом федерального органа исполнительной власти по техническому регулированию",
        ],
        correctAnswers: [
          "Международными договорами Российской Федерации, подлежащими ратификации в порядке, установленном законодательством Российской Федерации, или в соответствии с международными договорами Российской Федерации, ратифицированными в порядке, установленном законодательством Российской Федерации, или указами Президента Российской Федерации, или постановлениями Правительства Российской Федерации, или нормативным правовым актом федерального органа исполнительной власти по техническому регулированию",
        ],
      },
      {
        code: "58384",
        text: 'Какие формы обязательного подтверждения соответствия установлены Федеральным законом от 27.12.2002 N 184-ФЗ "О техническом регулировании"?',
        answers: [
          "Экспертиза промышленной безопасности объекта",
          "Только обязательная сертификация",
          "Обязательная сертификация или декларирование соответствия продукции",
          "Оценка рисков применения продукции",
        ],
        correctAnswers: [
          "Обязательная сертификация или декларирование соответствия продукции",
        ],
      },
      {
        code: "58385",
        text: "В каких документах устанавливаются формы оценки соответствия обязательным требованиям к техническим устройствам, применяемым на опасном производственном объекте?",
        answers: [
          "В федеральных нормах и правилах в области промышленной безопасности",
          "В технических регламентах",
          "В нормативных правовых актах, утверждаемых Правительством Российской Федерации",
          'В Федеральном законе от 21 июля 1997 года N 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
        ],
        correctAnswers: ["В технических регламентах"],
      },
      {
        code: "58386",
        text: "Кто имеет право проводить сертификацию технических устройств, применяемых на опасных производственных объектах?",
        answers: [
          "Орган по сертификации, аккредитованный в соответствии с законодательством Российской Федерации об аккредитации в национальной системе аккредитации",
          "Организация, аккредитованная федеральным органом исполнительной власти по стандартизации, метрологии и сертификации",
          "Организация, аккредитованная федеральным органом исполнительной власти в области промышленной безопасности, совместно с федеральным органом исполнительной власти по стандартизации, метрологии и сертификации",
        ],
        correctAnswers: [
          "Орган по сертификации, аккредитованный в соответствии с законодательством Российской Федерации об аккредитации в национальной системе аккредитации",
        ],
      },
      {
        code: "58387",
        text: "В случае если техническим регламентом не установлена иная форма оценки соответствия технического устройства, применяемого на опасном производственном объекте, обязательным требованиям к такому техническому устройству, то до начала эксплуатации оно подлежит:",
        answers: [
          "Техническому аудиту",
          "Добровольной сертификации или добровольному декларированию соответствия по выбору производителя технического устройства",
          "Экспертизе промышленной безопасности",
        ],
        correctAnswers: ["Экспертизе промышленной безопасности"],
      },
      {
        code: "58388",
        text: "Машины и оборудование, находящиеся в эксплуатации или изготовленные для собственных нужд, не подлежат:",
        answers: [
          "Декларированию соответствия или обязательной сертификации",
          "Техническому аудиту",
          "Экспертизе промышленной безопасности, если иные формы соответствия не установлены в технических регламентах",
        ],
        correctAnswers: [
          "Декларированию соответствия или обязательной сертификации",
        ],
      },
      {
        code: "58389",
        text: 'Какие требования устанавливает Технический регламент Таможенного союза "О безопасности машин и оборудования"?',
        answers: [
          "Обеспечение безопасности эксплуатации машин и (или) оборудования",
          "Обеспечение на единой таможенной территории Таможенного союза обязательных для применения и исполнения минимально необходимых требований к машинам и оборудованию",
          "Условия свободного перемещения машин и (или) оборудования, выпускаемого в обращение на единой таможенной территории",
        ],
        correctAnswers: [
          "Обеспечение на единой таможенной территории Таможенного союза обязательных для применения и исполнения минимально необходимых требований к машинам и оборудованию",
        ],
      },
      {
        code: "58390",
        text: "Что из перечисленного не определяется при разработке и проектировании машины и (или) оборудования?",
        answers: [
          "Допустимый риск для машины и/или оборудования",
          "Методика измерений и правила отбора образцов, необходимых для применения и исполнения требований ТР ТС 010/2011",
          "Условия безопасной эксплуатации машин и/или оборудования",
        ],
        correctAnswers: [
          "Методика измерений и правила отбора образцов, необходимых для применения и исполнения требований ТР ТС 010/2011",
        ],
      },
      {
        code: "58391",
        text: "Что является идентификационным признаком оборудования для работы во взрывоопасных средах?",
        answers: [
          "Только наличие маркировки взрывозащиты",
          "Только наличие сертификата взрывозащиты, выданного аккредитованным органом по сертификации",
          "Наличие средств обеспечения взрывозащиты, указанных в технической документации изготовителя, и маркировки взрывозащиты, нанесенной на оборудование",
        ],
        correctAnswers: [
          "Наличие средств обеспечения взрывозащиты, указанных в технической документации изготовителя, и маркировки взрывозащиты, нанесенной на оборудование",
        ],
      },
      {
        code: "58392",
        text: 'Какие виды классификаций оборудования для работы во взрывоопасных средах не устанавливает ТР "О безопасности оборудования для работы во взрывоопасных средах"?',
        answers: [
          "Классификация взрывоопасных зон",
          "Классификация оборудования по группам (в зависимости от области применения)",
          "Классификация оборудования по уровням и видам взрывозащиты",
          "Классификация оборудования по температурным классам",
          "Классификация оборудования по давлению",
        ],
        correctAnswers: ["Классификация оборудования по давлению"],
      },
      {
        code: "58393",
        text: "В каких законах устанавливаются виды деятельности, подлежащие лицензированию в области промышленной безопасности?",
        answers: [
          'Только в Федеральном законе от 04 мая 2011 года N 99-ФЗ "О лицензировании отдельных видов деятельности"',
          'Только в Федеральном законе от 21 июля 1997 года N 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
          'В Федеральном законе от 21 июля 1997 года N 116-ФЗ "О промышленной безопасности опасных производственных объектов" и Федеральном законе от 04 мая 2011 года N 99-ФЗ "О лицензировании отдельных видов деятельности"',
          'В Федеральном законе от 21 июля 1997 года N 116-ФЗ "О промышленной безопасности опасных производственных объектов", Федеральном законе от 04 мая 2011 года N 99-ФЗ "О лицензировании отдельных видов деятельности" и Федеральном законе от 21 декабря 1994 года N 68-ФЗ "О защите населения и территорий от чрезвычайных ситуаций природного и техногенного характера"',
        ],
        correctAnswers: [
          'Только в Федеральном законе от 04 мая 2011 года N 99-ФЗ "О лицензировании отдельных видов деятельности"',
        ],
      },
      {
        code: "58394",
        text: 'Как называется один из видов деятельности в области промышленной безопасности, подлежащий лицензированию в соответствии с Федеральным законом от 04.05.2011 N 99-ФЗ "О лицензировании отдельных видов деятельности"?',
        answers: [
          "Эксплуатация взрывопожароопасных и химически опасных производственных объектов I и II классов опасности",
          "Эксплуатация взрывопожароопасных и химически опасных производственных объектов I, II и III классов опасности",
          "Эксплуатация взрывопожароопасных производственных объектов всех классов опасности",
          "Эксплуатация химически опасных производственных объектов I и II классов опасности",
        ],
        correctAnswers: [
          "Эксплуатация взрывопожароопасных и химически опасных производственных объектов I, II и III классов опасности",
        ],
      },
      {
        code: "58395",
        text: 'Какой минимальный срок действия лицензии установлен Федеральным законом от 04.05.2011 N 99-ФЗ "О лицензировании отдельных видов деятельности"?',
        answers: ["1 год", "3 года", "5 лет", "Лицензия действует бессрочно"],
        correctAnswers: ["Лицензия действует бессрочно"],
      },
      {
        code: "58396",
        text: "Какие из перечисленных документов не вправе требовать лицензирующий орган у соискателей лицензий на эксплуатацию взрывопожароопасных и химически опасных производственных объектов I, II и III классов опасности?",
        answers: [
          "Заявление о предоставлении лицензии в котором указан идентификационный номер налогоплательщика, данные документа о постановке соискателя лицензии на учет в налоговом органе",
          "Копии документов, перечень которых определяется положением о лицензировании конкретного вида деятельности и которые свидетельствуют о соответствии соискателя лицензии лицензионным требованиям",
          "Копии документов, свидетельствующие об отсутствии у юридического лица налоговой задолженности за предыдущий год",
        ],
        correctAnswers: [
          "Копии документов, свидетельствующие об отсутствии у юридического лица налоговой задолженности за предыдущий год",
        ],
      },
      {
        code: "58397",
        text: "В какой срок лицензирующий орган обязан принять решение о предоставлении или об отказе в предоставлении лицензии?",
        answers: [
          "Не позднее 60 календарных дней со дня получения заявления соискателя лицензии со всеми необходимыми документами",
          "Не позднее 30 рабочих дней со дня получения заявления соискателя лицензии со всеми необходимыми документами",
          "Не позднее 45 рабочих дней со дня приема заявления о предоставлении лицензии и прилагаемых к нему документов",
          "Срок определяется договором между лицензиатом и лицензирующим органом",
        ],
        correctAnswers: [
          "Не позднее 45 рабочих дней со дня приема заявления о предоставлении лицензии и прилагаемых к нему документов",
        ],
      },
      {
        code: "58398",
        text: "Кем осуществляется контроль за соблюдением лицензиатом лицензионных требований?",
        answers: [
          "Лицензирующим органом совместно с прокуратурой",
          "Органом исполнительной власти субъекта Российской Федерации, на территории которого эксплуатируется опасный объект",
          "Лицензирующим органом",
          "Органом местного самоуправления",
        ],
        correctAnswers: ["Лицензирующим органом"],
      },
      {
        code: "58399",
        text: "В каком случае лицензирующие органы могут приостанавливать действие лицензии?",
        answers: [
          "В случае ликвидации юридического лица и прекращения его деятельности в результате реорганизации",
          "В случае неуплаты лицензиатом в течение 3 месяцев лицензионного сбора",
          "В случае смены владельца организации",
          "В случае привлечения лицензиата к административной ответственности за неисполнение в установленный срок предписания об устранении грубого нарушения лицензионных требований",
        ],
        correctAnswers: [
          "В случае привлечения лицензиата к административной ответственности за неисполнение в установленный срок предписания об устранении грубого нарушения лицензионных требований",
        ],
      },
      {
        code: "58400",
        text: "В каком случае лицензия может быть аннулирована решением суда?",
        answers: [
          "При обнаружении недостоверных или искаженных данных в документах, представленных в лицензирующий орган для получения лицензии",
          "При ликвидации юридического лица или прекращении его деятельности в результате реорганизации",
          "Из-за неуплаты лицензиатом в течение трех месяцев лицензионного сбора",
          "Если в установленный судом срок административного наказания в виде административного приостановления деятельности и приостановления действия лицензии лицензиат не устранил грубое нарушение лицензионных требований",
        ],
        correctAnswers: [
          "Если в установленный судом срок административного наказания в виде административного приостановления деятельности и приостановления действия лицензии лицензиат не устранил грубое нарушение лицензионных требований",
        ],
      },
      {
        code: "58401",
        text: "В какой срок и на какой период времени в случае вынесения решения суда или должностного лица Ростехнадзора о назначении административного наказания в виде административного приостановления деятельности лицензиата лицензирующий орган приостанавливает действие лицензии?",
        answers: [
          "В течение 2 суток со дня принятия решения на срок административного приостановления деятельности лицензиата",
          "В течение суток со дня вступления этого решения в законную силу на срок административного приостановления деятельности лицензиата",
          "В течение суток со дня вступления этого решения в законную силу на срок не более 30 суток",
          "В течение 3 суток со дня вступления этого решения в законную силу на срок административного приостановления деятельности лицензиата",
        ],
        correctAnswers: [
          "В течение суток со дня вступления этого решения в законную силу на срок административного приостановления деятельности лицензиата",
        ],
      },
      {
        code: "58402",
        text: "Какое количество экспертов в области промышленной безопасности должно быть в штате соискателя лицензии по проведению экспертизы промышленной безопасности?",
        answers: [
          'Не менее трех экспертов в области промышленной безопасности, которые соответствуют требованиям, установленным Федеральным законом "О промышленной безопасности опасных производственных объектов", для которых работа в этой организации является основной',
          "Не менее двух экспертов, аттестованных в областях аттестации, соответствующих заявляемым работам (услугам)",
          'Не менее четырех экспертов в области промышленной безопасности, которые соответствуют требованиям, установленным Федеральным законом "О промышленной безопасности опасных производственных объектов", для которых работа в этой организации является основной',
        ],
        correctAnswers: [
          'Не менее трех экспертов в области промышленной безопасности, которые соответствуют требованиям, установленным Федеральным законом "О промышленной безопасности опасных производственных объектов", для которых работа в этой организации является основной',
        ],
      },
      {
        code: "58403",
        text: "Какие из перечисленных требований не являются лицензионными требованиями к лицензиату при осуществлении им лицензируемой деятельности по проведению экспертизы промышленной безопасности?",
        answers: [
          "Наличие дипломов о высшем техническом образовании, соответствующим заявленным работам (услугам) как минимум у 3 специалистов, состоящих в штате организации и для которых работа в данной организации является основной",
          'Наличие в штате лицензиата как минимум 3 экспертов, соответствующих требованиям, установленным Федеральным законом "О промышленной безопасности опасных производственных объектов", и для которых работа в этой организации является основной',
          'Проведение экспертизы в соответствии с работами (услугами), указанными в лицензии, экспертами в области промышленной безопасности, которые соответствуют требованиям, установленным Федеральным законом "О промышленной безопасности опасных производственных объектов"',
          "Проведение экспертизы и оформление ее результатов в соответствии с требованиями, установленными нормативными правовыми актами Российской Федерации в области промышленной безопасности",
        ],
        correctAnswers: [
          "Наличие дипломов о высшем техническом образовании, соответствующим заявленным работам (услугам) как минимум у 3 специалистов, состоящих в штате организации и для которых работа в данной организации является основной",
        ],
      },
      {
        code: "58404",
        text: "Что обязан сделать лицензиат, если он намерен изменить адрес места осуществления лицензируемого вида деятельности?",
        answers: [
          "Подать заявление в лицензирующий орган о переоформлении лицензии",
          "Подать заявление в лицензирующий орган о выдаче новой лицензии",
          "Направить в лицензирующий орган уведомление о своих намерениях изменить адрес",
        ],
        correctAnswers: [
          "Подать заявление в лицензирующий орган о переоформлении лицензии",
        ],
      },
      {
        code: "58405",
        text: "Что обязан сделать лицензиат, если он планирует выполнять работы (оказывать услуги), составляющие лицензируемую деятельность, и не указанные в лицензии?",
        answers: [
          "Подать заявление в лицензирующий орган о переоформлении лицензии",
          "Подать заявление в лицензирующий орган о выдаче новый лицензии",
          "Направить в лицензирующий орган уведомление о своих намерениях",
          "Направить в лицензирующий орган информацию о наличии экспертов, аттестованных в областях аттестации, соответствующих вновь заявляемым работам (услугам)",
        ],
        correctAnswers: [
          "Подать заявление в лицензирующий орган о переоформлении лицензии",
        ],
      },
      {
        code: "58406",
        text: "Какими нормативными правовыми актами устанавливаются требования к порядку осуществления федерального лицензионного контроля за соблюдением лицензионных требований при осуществлении деятельности по проведению экспертизы промышленной безопасности?",
        answers: [
          'Федеральным законом "О защите прав юридических лиц и индивидуальных предпринимателей при осуществлении государственного контроля (надзора) и муниципального контроля" и Федеральным законом "О лицензировании отдельных видов деятельности"',
          'Исключительно Федеральным законом "О лицензировании отдельных видов деятельности"',
          'Федеральным законом "О лицензировании отдельных видов деятельности" и постановлением Правительства Российской Федерации "О лицензировании деятельности по проведению экспертизы промышленной безопасности"',
          'Постановлением Правительства Российской Федерации "О лицензировании деятельности по проведению экспертизы промышленной безопасности"',
        ],
        correctAnswers: [
          'Федеральным законом "О защите прав юридических лиц и индивидуальных предпринимателей при осуществлении государственного контроля (надзора) и муниципального контроля" и Федеральным законом "О лицензировании отдельных видов деятельности"',
        ],
      },
      {
        code: "58407",
        text: "Взимается ли плата за предоставление или переоформление лицензии, если да, то в соответствии с каким законодательством?",
        answers: [
          "Да, в соответствии с законодательством Российской Федерации о налогах и сборах",
          "Да, в соответствии с законодательством о лицензировании",
          "Нет",
        ],
        correctAnswers: [
          "Да, в соответствии с законодательством Российской Федерации о налогах и сборах",
        ],
      },
      {
        code: "58408",
        text: "Выполнение каких работ на объектах не включает в себя лицензируемый вид деятельности по эксплуатации взрывопожароопасных и химических опасных производственных объектов I, II и III классов опасности?",
        answers: [
          "Использование воспламеняющихся, окисляющих, горючих, взрывчатых, токсичных, высокотоксичных веществ и веществ, представляющих опасность для окружающей среды, на взрывопожароопасных и химически опасных производственных объектах I, II и III классов опасности",
          "Получение расплавов черных и цветных металлов, сплавов на основе этих расплавов с применением оборудования, рассчитанного на максимальное количество расплава, составляющее 500 кг и более",
          "Работы, связанные с получением, использованием, переработкой, хранением, транспортированием и уничтожением взрывчатых материалов промышленного назначения",
          "Использование (эксплуатация) на объектах оборудования, работающего под избыточным давлением более 0,07 МПа иных жидкостей при температуре, превышающей температуру их кипения при избыточном давлении 0,07 МПа",
        ],
        correctAnswers: [
          "Работы, связанные с получением, использованием, переработкой, хранением, транспортированием и уничтожением взрывчатых материалов промышленного назначения",
        ],
      },
      {
        code: "58409",
        text: "Кто осуществляет лицензирование эксплуатации взрывопожароопасных и химически опасных производственных объектов I, II и III классов опасности?",
        answers: [
          "Федеральная служба по экологическому, технологическому и атомному надзору",
          "Росстандарт",
          "Росаккредитация",
          "МЧС России",
        ],
        correctAnswers: [
          "Федеральная служба по экологическому, технологическому и атомному надзору",
        ],
      },
      {
        code: "58410",
        text: "В какие федеральные органы исполнительной власти заявитель, предполагающий выполнение работ (оказание услуг) при эксплуатации взрывопожароопасных и химически опасных производственных объектов IV класса опасности, должен представлять уведомления о начале осуществления своей деятельности?",
        answers: [
          "В Федеральную службу по труду и занятости",
          "В Федеральное агентство по техническому регулированию и метрологии",
          "В Федеральную службу по экологическому, технологическому и атомному надзору",
          "В Министерство Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "В Федеральную службу по надзору в сфере здравоохранения",
          "В Федеральное медико-биологическое агентство",
        ],
        correctAnswers: [
          "В Федеральную службу по экологическому, технологическому и атомному надзору",
        ],
      },
      {
        code: "58411",
        text: "В каких случаях лицензия подлежит переоформлению?",
        answers: [
          "В случае окончания срока действия лицензии",
          "В случае изменения места жительства, имени, фамилии и (в случае, если имеется) отчества руководителя юридического лица",
          "В случаях реорганизации юридического лица в форме преобразования, изменения его наименования, адреса места нахождения",
          "Во всех перечисленных",
        ],
        correctAnswers: [
          "В случаях реорганизации юридического лица в форме преобразования, изменения его наименования, адреса места нахождения",
        ],
      },
      {
        code: "58412",
        text: "Какие требования не могут быть отнесены к лицензионным требованиям? Укажите все правильные ответы.",
        answers: [
          "Требования к конкретным видам и объему выпускаемой или планируемой к выпуску продукции",
          "Требования о наличии у соискателя лицензии и лицензиата работников, заключивших с ними трудовые договоры, имеющих профессиональное образование, обладающих соответствующей квалификацией и/или имеющих стаж работы, необходимый для осуществления лицензируемого вида деятельности",
          "Требования о соблюдении законодательства Российской Федерации в соответствующей сфере деятельности в целом",
          "Требования о соответствии соискателя лицензии и лицензиата требованиям, установленным федеральными законами и касающимся организационно-правовой формы юридического лица, размера уставного капитала, а также отсутствия задолженности по обязательствам перед третьими лицами",
        ],
        correctAnswers: [
          "Требования к конкретным видам и объему выпускаемой или планируемой к выпуску продукции",
          "Требования о соблюдении законодательства Российской Федерации в соответствующей сфере деятельности в целом",
        ],
      },
      {
        code: "58413",
        text: "Что является грубым нарушением лицензионных требований при осуществлении лицензируемого вида деятельности?",
        answers: [
          "Неукомплектованность штата работников, соответствующих требованиям, установленным федеральными нормами и правилами в области промышленной безопасности",
          'Отсутствие документов, подтверждающих ввод в эксплуатацию объектов, или наличие положительных заключений экспертизы промышленной безопасности в соответствии со статьями 6, 7 и 13 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Отсутствие подготовки и аттестации в области промышленной безопасности работников, в том числе руководителей организаций, осуществляющих деятельность на объектах, в соответствии со статьями 9 и 14.1 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Отсутствие в соответствии со статьей 10 Федерального закона "О промышленной безопасности опасных производственных объектов" договоров на обслуживание с профессиональными аварийно-спасательными службами или формированиями, а в случаях, предусмотренных указанным Федеральным законом, другими федеральными законами и принимаемыми в соответствии с ними иными нормативными правовыми актами Российской Федерации, - наличие собственных профессиональных аварийно-спасательных служб или формирований, а также наличие нештатного аварийно-спасательного формирования из числа работников лицензиата',
        ],
        correctAnswers: [
          'Отсутствие подготовки и аттестации в области промышленной безопасности работников, в том числе руководителей организаций, осуществляющих деятельность на объектах, в соответствии со статьями 9 и 14.1 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "58414",
        text: "В каком нормативном правовом акте установлен порядок принятия решений лицензирующим органом о предоставлении и переоформлении лицензии на деятельность по проведению экспертизы промышленной безопасности?",
        answers: [
          'В Федеральном законе от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
          'В Федеральном законе "О лицензировании отдельных видов деятельности"',
          'В постановление Правительства Российской Федерации от 16.09.2020 N 1477 "О лицензировании деятельности по проведению экспертизы промышленной безопасности"',
          'В приказе Ростехнадзора от 20.10.2020 N 420 "Об утверждении федеральных норм и правил в области промышленной безопасности "Правила проведения экспертизы промышленной безопасности"',
        ],
        correctAnswers: [
          'В Федеральном законе "О лицензировании отдельных видов деятельности"',
        ],
      },
      {
        code: "58415",
        text: "Кем проводится техническое расследование причин аварии на опасном производственном объекте?",
        answers: [
          "Специальной комиссией по расследованию, возглавляемой представителем федерального органа исполнительной власти в области охраны труда или его территориального органа",
          "Специальной комиссией по расследованию, возглавляемой представителем Ростехнадзора или его территориального органа",
          "Комиссией по расследованию, возглавляемой либо представителем федерального органа исполнительной власти, специально уполномоченного в области охраны труда, либо представителем Ростехнадзора",
          "Комиссией по расследованию, возглавляемой руководителем эксплуатирующей организации, на которой произошла авария, с обязательным участием представителей Ростехнадзора",
        ],
        correctAnswers: [
          "Специальной комиссией по расследованию, возглавляемой представителем Ростехнадзора или его территориального органа",
        ],
      },
      {
        code: "58416",
        text: "Кто имеет право принимать решение о создании государственной комиссии по техническому расследованию причин аварии и назначать председателя указанной комиссии?",
        answers: [
          "Только Президент Российской Федерации",
          "Только Правительство Российской Федерации",
          "Президент Российской Федерации или Правительство Российской Федерации",
          "Президент Российской Федерации, Правительство Российской Федерации и руководитель федерального органа исполнительной власти в области промышленной безопасности",
        ],
        correctAnswers: [
          "Президент Российской Федерации или Правительство Российской Федерации",
        ],
      },
      {
        code: "58417",
        text: "В каком документе устанавливается порядок проведения технического расследования причин аварий на опасных производственных объектах?",
        answers: [
          'В Федеральном законе от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
          "В постановлении Правительства Российской Федерации",
          "В Трудовом кодексе Российской Федерации",
          "В нормативном документе, утвержденном федеральным органом исполнительной власти в области промышленной безопасности",
        ],
        correctAnswers: [
          "В нормативном документе, утвержденном федеральным органом исполнительной власти в области промышленной безопасности",
        ],
      },
      {
        code: "58418",
        text: "При каком условии представители организации, эксплуатирующей опасный производственный объект, принимают участие в техническом расследовании причин аварии?",
        answers: [
          "Представители организации не принимают участия в расследовании",
          "В качестве членов комиссии по расследованию, но их число не должно превышать 25% от общего числа членов комиссии",
          "В качестве членов комиссии по расследованию, но их число не должно превышать 30% от общего числа членов комиссии",
        ],
        correctAnswers: [
          "В качестве членов комиссии по расследованию, но их число не должно превышать 30% от общего числа членов комиссии",
        ],
      },
      {
        code: "58419",
        text: "Куда организация обязана направить результаты технического расследования причин аварии?",
        answers: [
          "В федеральный орган исполнительной власти в области промышленной безопасности",
          "В уполномоченный орган или его территориальный орган, сформировавший комиссию по проведению технического расследования, в соответствующие органы, представители которых принимали участие в работе комиссии по техническому расследованию, и в другие органы, определенные председателем комиссии",
          "В центральный аппарат или территориальный орган Ростехнадзора, проводивший расследование, страховую организацию, территориальный орган МЧС России",
          "В федеральный орган исполнительной власти в области промышленной безопасности, орган местного самоуправления, государственную инспекцию труда субъекта Российской Федерации, территориальное объединение профсоюза, а также в территориальные органы МЧС России",
        ],
        correctAnswers: [
          "В уполномоченный орган или его территориальный орган, сформировавший комиссию по проведению технического расследования, в соответствующие органы, представители которых принимали участие в работе комиссии по техническому расследованию, и в другие органы, определенные председателем комиссии",
        ],
      },
      {
        code: "58420",
        text: "Как назначается специальная комиссия по техническому расследованию причин аварии?",
        answers: [
          "Приказом руководителя организации, в которой произошла авария",
          "В зависимости от характера и возможных последствий аварии правовым актом уполномоченного органа или его территориального органа",
          "Совместным актом Ростехнадзора и МЧС России",
          "Распоряжением Правительства Российской Федерации",
        ],
        correctAnswers: [
          "В зависимости от характера и возможных последствий аварии правовым актом уполномоченного органа или его территориального органа",
        ],
      },
      {
        code: "58421",
        text: "На кого возлагается финансирование расходов на техническое расследование причин аварий?",
        answers: [
          "На территориальный орган Ростехнадзора",
          "На организацию, эксплуатирующую опасные производственные объекты",
          "На страховую компанию, с которой заключен договор обязательного страхования гражданской ответственности владельца опасного объекта за причинение вреда в результате аварии на опасном производственном объекте",
          "На организацию, эксплуатирующую опасный производственный объект, или страховую компанию, в которой застрахована гражданская ответственность этой организации",
        ],
        correctAnswers: [
          "На организацию, эксплуатирующую опасные производственные объекты",
        ],
      },
      {
        code: "58422",
        text: "Чем регламентируется порядок проведения работ по установлению причин инцидентов на опасном производственном объекте?",
        answers: [
          "Документами, утвержденными организацией, эксплуатирующей опасные производственные объекты, по согласованию с представительным органом работников данной организации",
          "Документом, утвержденным организацией, эксплуатирующей опасный производственный объект",
          "Документами, утвержденными организацией, эксплуатирующей опасный производственный объект, согласованными с органом исполнительной власти субъекта Российской Федерации, на территории которого находится опасный производственный объект",
        ],
        correctAnswers: [
          "Документом, утвержденным организацией, эксплуатирующей опасный производственный объект",
        ],
      },
      {
        code: "58423",
        text: "Кем осуществляется расчет вреда (экономического и экологического ущерба) от аварии?",
        answers: [
          "Специализированной сторонней организацией",
          "Территориальным органом Ростехнадзора",
          "Организацией, на объекте которой произошла авария",
          "Комиссией по техническому расследованию аварии",
        ],
        correctAnswers: ["Организацией, на объекте которой произошла авария"],
      },
      {
        code: "58424",
        text: "В какой срок материалы технического расследования аварии направляются территориальным органом Ростехнадзора в центральный аппарат Ростехнадзора?",
        answers: [
          "В недельный срок",
          "В десятидневный срок",
          "В месячный срок",
          "В двухнедельный срок",
        ],
        correctAnswers: ["В двухнедельный срок"],
      },
      {
        code: "58425",
        text: "С какой периодичностью организация, эксплуатирующая опасные производственные объекты, должна направлять информацию об инцидентах, происшедших на опасных производственных объектах, в территориальный орган Ростехнадзора?",
        answers: [
          "Ежемесячно",
          "Ежеквартально",
          "Не реже одного раза в полгода",
          "Ежегодно",
        ],
        correctAnswers: ["Ежеквартально"],
      },
      {
        code: "58426",
        text: "В какой срок должен быть составлен акт технического расследования причин аварии?",
        answers: [
          "В течение 30 рабочих дней",
          "В течение 30 календарных дней",
          "В течение 20 рабочих дней",
          "В течение 20 календарных дней",
        ],
        correctAnswers: ["В течение 30 календарных дней"],
      },
      {
        code: "58427",
        text: "Кто является страхователями гражданской ответственности за причинение вреда в результате аварии на опасном производственном объекте?",
        answers: [
          "Юридические лица или физические лица, заключившие со страховщиками договоры страхования",
          "Владельцы опасных производственных объектов (юридические лица или индивидуальные предприниматели), заключившие договор обязательного страхования гражданской ответственности за причинение вреда потерпевшим в результате аварии на опасном объекте",
          "Владельцы опасных производственных объектов, за исключением индивидуальных предпринимателей, заключившие договор страхования гражданской ответственности за причинение вреда потерпевшим в результате аварии на опасном объекте",
        ],
        correctAnswers: [
          "Владельцы опасных производственных объектов (юридические лица или индивидуальные предприниматели), заключившие договор обязательного страхования гражданской ответственности за причинение вреда потерпевшим в результате аварии на опасном объекте",
        ],
      },
      {
        code: "58428",
        text: 'Кто является владельцем опасного объекта в терминологии Федерального закона от 27.07.2010 N 225-ФЗ "Об обязательном страховании гражданской ответственности владельцев опасных объектов за причинение вреда в результате аварии на опасном объекте"?',
        answers: [
          "Юридическое лицо, владеющее опасным производственным объектом на праве собственности",
          "Юридическое лицо или индивидуальный предприниматель, владеющие опасным объектом на праве собственности, праве хозяйственного ведения или праве оперативного управления либо на ином законном основании и осуществляющие эксплуатацию опасного объекта",
          "Юридические лица, владеющие опасным производственным объектом на праве собственности, праве хозяйственного ведения или праве оперативного управления либо на ином законном основании, независимо от того, осуществляют они эксплуатацию опасного производственного объекта или нет",
        ],
        correctAnswers: [
          "Юридическое лицо или индивидуальный предприниматель, владеющие опасным объектом на праве собственности, праве хозяйственного ведения или праве оперативного управления либо на ином законном основании и осуществляющие эксплуатацию опасного объекта",
        ],
      },
      {
        code: "58429",
        text: "Какие из указанных опасных объектов не относятся к объектам, владельцы которых обязаны осуществлять обязательное страхование?",
        answers: [
          "Опасные производственные объекты, подлежащие регистрации в государственном реестре",
          "Лифты, подъемные платформы для инвалидов, эскалаторы (за исключением эскалаторов в метрополитенах)",
          "Автозаправочные станции жидкого моторного топлива",
          "Опасные производственные объекты, расположенные в границах объектов использования атомной энергии",
          "Пассажирские конвейеры (движущиеся пешеходные дорожки)",
        ],
        correctAnswers: [
          "Опасные производственные объекты, расположенные в границах объектов использования атомной энергии",
        ],
      },
      {
        code: "58430",
        text: 'Кому вменена обязанность страховать свою ответственность за причинение вреда в результате аварии на опасном объекте в соответствии с Федеральным законом от 27.07.2010 N 225-ФЗ "Об обязательном страховании гражданской ответственности владельца опасного объекта за причинение вреда в результате аварии на опасном объекте"?',
        answers: [
          "Эксплуатирующим организациям независимо от того, являются они владельцами опасного объекта или нет",
          "Проектным организациям",
          "Владельцам опасного объекта",
          "Экспертным организациям",
        ],
        correctAnswers: ["Владельцам опасного объекта"],
      },
      {
        code: "58431",
        text: "Каков размер страховой выплаты за вред, причиненный здоровью каждого потерпевшего в результате аварии на опасном производственном объекте?",
        answers: [
          "Не более 500 тысяч рублей",
          "Не более 300 шестидесяти тысяч рублей",
          "Не более 2 миллионов рублей",
          "Не более 200 тысяч рублей",
        ],
        correctAnswers: ["Не более 2 миллионов рублей"],
      },
      {
        code: "58432",
        text: "В отношении каких опасных объектов заключается договор обязательного страхования?",
        answers: [
          "В отношении всего предприятия полностью",
          "В отношении каждого опасного объекта, если иное не предусмотрено договором в отношении опасных объектов",
          "В отношении только декларируемых опасных производственных объектов",
          "В отношении групп опасных объектов, объединенных по территориальному принципу (или по специфике технологических операций)",
        ],
        correctAnswers: [
          "В отношении каждого опасного объекта, если иное не предусмотрено договором в отношении опасных объектов",
        ],
      },
      {
        code: "58433",
        text: "На какой срок заключается договор обязательного страхования гражданской ответственности за причинение вреда в результате аварии или инцидента на опасном производственном объекте?",
        answers: [
          "На срок не более года",
          "На срок не более 6 месяцев",
          "На срок не менее чем один год",
          "На срок не менее 9 месяцев",
        ],
        correctAnswers: ["На срок не менее чем один год"],
      },
      {
        code: "58434",
        text: "Кем осуществляется контроль за исполнением владельцем опасного производственного объекта обязанности по обязательному страхованию гражданской ответственности за причинение вреда в результате аварии на опасном объекте?",
        answers: [
          "Ростехнадзором, осуществляющим в пределах своей компетенции функции по контролю и надзору в области безопасности соответствующих производственных объектов",
          "Фондом социального страхования",
          "Национальным союзом страховщиков",
          "Страховой компанией владельца опасного объекта",
        ],
        correctAnswers: [
          "Ростехнадзором, осуществляющим в пределах своей компетенции функции по контролю и надзору в области безопасности соответствующих производственных объектов",
        ],
      },
      {
        code: "58435",
        text: "При каком условии событие признается страховым случаем?",
        answers: [
          "При условии, если в результате аварии на опасном объекте после окончания действия договора страхования причинен вред нескольким потерпевшим",
          "Если причинен вред потерпевшим, явившийся результатом последствий воздействия аварии, произошедшей в период действия договора обязательного страхования, которое влечет за собой обязанность страховщика произвести страховую выплату потерпевшим",
          "При условии, если вред, причиненный в период действия договора страхования, является результатом последствий или продолжающегося воздействия аварии, произошедшей до заключения договора обязательного страхования",
        ],
        correctAnswers: [
          "Если причинен вред потерпевшим, явившийся результатом последствий воздействия аварии, произошедшей в период действия договора обязательного страхования, которое влечет за собой обязанность страховщика произвести страховую выплату потерпевшим",
        ],
      },
      {
        code: "58436",
        text: "При каком обстоятельстве в случае досрочного прекращения действия договора обязательного страхования гражданской ответственности владельца опасного объекта страховая премия по договору не возвращается страхователю?",
        answers: [
          "Только по соглашению сторон",
          "Только при смене владельца опасного объекта в период действия договора обязательного страхования, если новый владелец опасного объекта не уведомил страховщика в течение тридцати календарных дней со дня вступления во владение опасным объектом в письменной форме",
          "Только по требованию страховщика в случае просрочки уплаты страховой премии (очередного страхового взноса) более чем на тридцать календарных дней",
          "Во всех перечисленных обстоятельствах",
        ],
        correctAnswers: ["Во всех перечисленных обстоятельствах"],
      },
      {
        code: "58437",
        text: "Исходя из данных в каком документе определяется страховая сумма для находящихся на расстоянии менее 500 м друг от друга опасных производственных объектов 1 и 2 классов опасности в случае суммарного количества обращающегося на них опасного вещества, равного или превышающего предельно допустимое количество?",
        answers: [
          "Сведений, указанных в отчете о специальной оценке условий труда",
          "Данных, указанных в декларации промышленной безопасности",
          "Данных, указанных в карте учета опасного производственного объекта",
          "Исходя из данных всех перечисленных документов",
        ],
        correctAnswers: [
          "Данных, указанных в декларации промышленной безопасности",
        ],
      },
      {
        code: "58438",
        text: "В течение какого времени при наступлении события, имеющего признаки страхового случая, страхователь обязан сообщить об этом страховщику в письменной форме?",
        answers: [
          "В течение 12 часов",
          "В течение 24 часов",
          "В течение 48 часов",
          "В течение 72 часов",
        ],
        correctAnswers: ["В течение 24 часов"],
      },
      {
        code: "58439",
        text: "В каком случае при смене владельца опасного объекта в период действия договора обязательного страхования права и обязанности страхователя по этому договору переходят к новому владельцу опасного объекта?",
        answers: [
          "Если новый владелец опасного объекта в течение 30 календарных дней со дня вступления во владение опасным объектом в письменной форме уведомил об этом страховщика",
          "Если новый владелец опасного объекта в течение 10 календарных дней со дня вступления во владение опасным объектом уведомил об этом страховщика",
          "При смене владельца опасного объекта необходимо заключать новый договор обязательного страхования",
          "Права и обязанности страхователя переходят новому владельцу опасного объекта автоматически",
        ],
        correctAnswers: [
          "Если новый владелец опасного объекта в течение 30 календарных дней со дня вступления во владение опасным объектом в письменной форме уведомил об этом страховщика",
        ],
      },
      {
        code: "58440",
        text: "Какие действия не вправе осуществлять страхователь при заключении договора обязательного страхования гражданской ответственности владельца опасного объекта, а также в период действия договора?",
        answers: [
          "Требовать от страховщика разъяснения условий обязательного страхования и проведения консультаций по заключению договора обязательного страхования",
          "В случае повреждения или утраты страхового полиса обязательного страхования в период его действия страхователю на основании письменного заявления страховщик за установленную плату может выдавать его дубликат",
          "Запрашивать у Ростехнадзора в письменной форме и получать сведения о выполнении страхователем норм и правил эксплуатации опасного объекта, установленных в соответствии с законодательством Российской Федерации",
          "Проводить за свой счет экспертизу опасного объекта в целях оценки вреда, который может быть причинен в результате аварии на этом объекте",
        ],
        correctAnswers: [
          "В случае повреждения или утраты страхового полиса обязательного страхования в период его действия страхователю на основании письменного заявления страховщик за установленную плату может выдавать его дубликат",
        ],
      },
      {
        code: "58441",
        text: "Какие документы предоставляет страховщику владелец опасного производственного объекта для заключения договора обязательного страхования гражданской ответственности?",
        answers: [
          "Только заявление об обязательном страховании",
          "Только копию свидетельства о регистрации опасного производственного объекта в государственном реестре опасных производственных объектов",
          "Только копию карты учета опасного производственного объекта",
          "Только копии документов, подтверждающих право собственности и (или) владения опасным объектом",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "58442",
        text: "В какой срок со дня поступления требования страхователя об изменении условий договора обязательного страхования в связи с уменьшением страхового риска, включая уменьшение размера страховой премии, страховщик обязан рассмотреть такое требование?",
        answers: [
          "20 рабочих дней",
          "30 рабочих дней",
          "5 рабочих дней",
          "15 рабочих дней",
        ],
        correctAnswers: ["30 рабочих дней"],
      },
      {
        code: "58443",
        text: "Какие документы страхователь предоставляет страховщику при заключении договора обязательного страхования до регистрации опасного производственного объекта? Укажите все правильные ответы.",
        answers: [
          "Заявление об обязательном страховании",
          "Копию документа, содержащего сведения, характеризующие опасный производственный объект",
          "Копии документов об аттестации в области промышленной безопасности всех работников опасного производственного объекта",
          "Сведения о количестве и характере наступивших страховых случаев, об осуществленных и предстоящих страховых выплатах",
          "Копию выписки из государственного регистра опасных производственных объектов",
        ],
        correctAnswers: [
          "Заявление об обязательном страховании",
          "Копию документа, содержащего сведения, характеризующие опасный производственный объект",
        ],
      },
      {
        code: "58444",
        text: "Кто осуществляет регистрацию объектов в государственном реестре опасных производственных объектов и ведение этого реестра?",
        answers: [
          "Только Ростехнадзор",
          "Только Регистрационная палата при Правительстве Российской Федерации",
          'Федеральная служба по экологическому, технологическому и атомному надзору, а также федеральные органы исполнительной власти, которым в установленном порядке предоставлено право проводить регистрацию подведомственных объектов, и Государственная корпорация по атомной энергии "Росатом"',
          "Министерство промышленности и торговли Российской Федерации, а также федеральные органы исполнительной власти, которым предоставлено право проводить регистрацию подведомственных объектов",
        ],
        correctAnswers: [
          'Федеральная служба по экологическому, технологическому и атомному надзору, а также федеральные органы исполнительной власти, которым в установленном порядке предоставлено право проводить регистрацию подведомственных объектов, и Государственная корпорация по атомной энергии "Росатом"',
        ],
      },
      {
        code: "58445",
        text: "В каком нормативном правовом акте устанавливаются критерии классификации опасных производственных объектов?",
        answers: [
          "В Федеральном законе",
          "В постановлении Правительства Российской Федерации",
          "В нормативном акте Ростехнадзора",
          "В нормативном акте МЧС России",
        ],
        correctAnswers: ["В Федеральном законе"],
      },
      {
        code: "58446",
        text: "На сколько классов опасности подразделяются опасные производственные объекты?",
        answers: ["На 3", "На 4", "На 2", "На 5"],
        correctAnswers: ["На 4"],
      },
      {
        code: "58447",
        text: "Кто обязан представлять в Ростехнадзор сведения, необходимые для формирования и ведения государственного реестра опасных производственных объектов?",
        answers: [
          "Территориальные органы Ростехнадзора",
          "Территориальные органы МЧС России",
          "Юридические лица независимо от организационно-правовой формы и индивидуальные предприниматели, осуществляющие эксплуатацию опасных производственных объектов",
          "Федеральные государственные бюджетные учреждения, эксплуатирующие опасные производственные объекты",
        ],
        correctAnswers: [
          "Юридические лица независимо от организационно-правовой формы и индивидуальные предприниматели, осуществляющие эксплуатацию опасных производственных объектов",
        ],
      },
      {
        code: "58448",
        text: "В какой срок эксплуатирующие организации и индивидуальные предприниматели обязаны предоставить в регистрирующий орган сведения, характеризующие опасные производственные объекты?",
        answers: [
          "Не позднее 3 месяцев с даты начала эксплуатации",
          "Не позднее 10 рабочих дней со дня начала их эксплуатации",
          "Не позднее тридцати рабочих дней со дня начала их эксплуатации",
          "Срок предоставления сведений не регламентируется",
        ],
        correctAnswers: [
          "Не позднее 10 рабочих дней со дня начала их эксплуатации",
        ],
      },
      {
        code: "58449",
        text: "В какой срок опасные производственные объекты, вводимые в эксплуатацию, должны быть внесены в государственный реестр?",
        answers: [
          "Не позднее 3 месяцев с даты начала их эксплуатации",
          "В течение 40 дней с даты начала их эксплуатации",
          "Не позднее 20 рабочих дней со дня поступления в регистрирующий орган сведений, характеризующих каждый объект",
          "Срок не регламентируется",
        ],
        correctAnswers: [
          "Не позднее 20 рабочих дней со дня поступления в регистрирующий орган сведений, характеризующих каждый объект",
        ],
      },
      {
        code: "58450",
        text: "Какой из перечисленных случаев не может являться основанием для исключения объекта из государственного реестра опасных производственных объектов?",
        answers: [
          "Ликвидация опасного объекта или вывод его из эксплуатации",
          "Утрата опасным объектом признаков опасности",
          "Грубое нарушение требований промышленной безопасности при эксплуатации опасного производственного объекта",
          "Изменение критериев отнесения объектов к категории опасных производственных объектов либо требований к идентификации опасных производственных объектов",
        ],
        correctAnswers: [
          "Грубое нарушение требований промышленной безопасности при эксплуатации опасного производственного объекта",
        ],
      },
      {
        code: "58451",
        text: "На каком этапе осуществляется присвоение класса опасности опасному производственному объекту?",
        answers: [
          "На этапе подготовки его проектной документации",
          "На этапе проведения экспертизы промышленной безопасности зданий, сооружений и технических устройств, применяемых на опасном объекте",
          "На этапе его регистрации в государственном реестре опасных производственных объектов",
          "На этапе его ввода в эксплуатацию",
        ],
        correctAnswers: [
          "На этапе его регистрации в государственном реестре опасных производственных объектов",
        ],
      },
      {
        code: "58452",
        text: "В каком из перечисленных случаев при внесении изменений в государственный реестр объекту присваивается иной регистрационный номер?",
        answers: [
          "При внесении изменений в государственный реестр, связанных с исключением опасного производственного объекта в связи со сменой эксплуатирующей организации",
          "В случае изменения только количественного состава технического устройства опасного производственного объекта",
          "В случае изменения только качественного состава технического устройства опасного производственного объекта",
          "При внесении изменений в количественный и качественный состав технического устройства опасного производственного объекта",
        ],
        correctAnswers: [
          "При внесении изменений в государственный реестр, связанных с исключением опасного производственного объекта в связи со сменой эксплуатирующей организации",
        ],
      },
      {
        code: "58453",
        text: "В какой срок осуществляется внесение в государственный реестр изменений сведений, связанных с исключением опасного производственного объекта в связи со сменой эксплуатирующей организации?",
        answers: [
          "В срок, не превышающий 10 (десяти) рабочих дней с даты регистрации заявления о внесении изменений",
          "В срок, не превышающий 20 (двадцати) рабочих дней с даты регистрации заявления о внесении изменений",
          "В срок, не превышающий 30 (тридцати) рабочих дней со дня наступления указанных изменений",
          "В срок, не превышающий 5 (пяти) рабочих дней со дня наступления указанных изменений",
        ],
        correctAnswers: [
          "В срок, не превышающий 20 (двадцати) рабочих дней с даты регистрации заявления о внесении изменений",
        ],
      },
      {
        code: "58454",
        text: "В какой срок осуществляется внесение в государственный реестр изменений сведений, связанных с изменением адреса места нахождения опасного производственного объекта?",
        answers: [
          "В срок, не превышающий 10 (десяти) рабочих дней с даты регистрации заявления о внесении изменений",
          "В срок, не превышающий 20 (двадцати) рабочих дней с даты регистрации заявления о внесении изменений",
          "В срок, не превышающий 30 (тридцати) рабочих дней со дня наступления указанных изменений",
          "В срок, не превышающий 5 (пяти) рабочих дней со дня наступления указанных изменений",
        ],
        correctAnswers: [
          "В срок, не превышающий 10 (десяти) рабочих дней с даты регистрации заявления о внесении изменений",
        ],
      },
      {
        code: "58455",
        text: "Что должно быть осуществлено эксплуатирующей организацией при проведении идентификации опасных производственных объектов?",
        answers: [
          "Должны быть учтены все осуществляемые на объекте технологические процессы и применяемые технические устройства, обладающие признаками опасности",
          "Должны быть выявлены все признаки опасности на объекте",
          "Необходимо учесть количественные и качественные характеристики признаков опасности на объекте",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "58456",
        text: "Внесение каких изменений в государственный реестр осуществляется в срок, не превышающий 10 (десяти) рабочих дней с даты регистрации заявления о внесении изменений? Выберите два правильных варианта ответов.",
        answers: [
          "Изменение состава опасного производственного объекта; технологического процесса; признаков или класса опасности опасного производственного объекта",
          "Изменение адреса места нахождения опасного производственного объекта",
          "Изменение сведений об эксплуатирующей организации, собственнике опасного производственного объекта",
          "Изменение сведений, связанных с исключением опасного производственного объекта в связи со сменой эксплуатирующей организации",
        ],
        correctAnswers: [
          "Изменение адреса места нахождения опасного производственного объекта",
          "Изменение сведений об эксплуатирующей организации, собственнике опасного производственного объекта",
        ],
      },
      {
        code: "58457",
        text: "В каких случаях из перечисленных регистрирующим органом вносятся изменения в государственный реестр?",
        answers: [
          "Только в случае изменения адреса места нахождения опасного производственного объекта",
          "Только в случае изменения сведений об эксплуатирующей организации, собственнике опасного производственного объекта",
          "Только в случае изменения сведений, связанных с исключением опасного производственного объекта в связи со сменой эксплуатирующей организации",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "58458",
        text: 'Что из перечисленного относится к обязанностям организации в области промышленной безопасности в соответствии с Федеральным законом от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов"?',
        answers: [
          "Специальная оценка условий труда работников",
          "Обеспечение укомплектованности штата работников опасного производственного объекта",
          "Обязательное страхование от несчастных случаев на производстве и профессиональных заболеваний физических лиц, выполняющих работу на основании гражданско-правового договора",
        ],
        correctAnswers: [
          "Обеспечение укомплектованности штата работников опасного производственного объекта",
        ],
      },
      {
        code: "58459",
        text: 'Что из указанного относится к обязанностям организации в области промышленной безопасности в соответствии с Федеральным законом от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов"?',
        answers: [
          "Разработка в организации локальных нормативных документов по охране труда",
          "Наличие на опасном производственном объекте нормативных правовых актов, устанавливающих требования промышленной безопасности, а также правил ведения работ на опасном производственном объекте",
          "Обеспечение работников опасного производственного объекта средствами индивидуальной защиты (СИЗ)",
          "Декларирование соответствия условий труда в организации государственным нормативным требованиям охраны труда",
        ],
        correctAnswers: [
          "Наличие на опасном производственном объекте нормативных правовых актов, устанавливающих требования промышленной безопасности, а также правил ведения работ на опасном производственном объекте",
        ],
      },
      {
        code: "58460",
        text: 'Что из перечисленного не обязана выполнять организация в области промышленной безопасности в соответствии с Федеральным законом от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов"?',
        answers: [
          "Обеспечивать наличие и функционирование необходимых приборов и систем контроля за производственными процессами в соответствии с установленными требованиями",
          "Предотвращать проникновение на опасный производственный объект посторонних лиц",
          "Организовывать и осуществлять производственный контроль за соблюдением требований промышленной безопасности",
          "Создавать систему управления промышленной безопасностью и обеспечивать ее функционирование на опасных производственных объектах III класса опасности",
        ],
        correctAnswers: [
          "Создавать систему управления промышленной безопасностью и обеспечивать ее функционирование на опасных производственных объектах III класса опасности",
        ],
      },
      {
        code: "58461",
        text: 'Что из перечисленного входит в обязанности организации в области промышленной безопасности в соответствии с Федеральным законом от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов"?',
        answers: [
          "Выполнение обязательств по охране труда, предусмотренных коллективными договорами и другими соглашениями",
          "Приостановление эксплуатации опасного производственного объекта в случае аварии или инцидента на опасном производственном объекте",
          "Участие в рассмотрении вопросов, связанных с обеспечением безопасных условий труда на рабочем месте, а также в расследовании происшедшего несчастного случая на производстве или профессионального заболевания",
          "Все перечисленные обязанности",
        ],
        correctAnswers: [
          "Приостановление эксплуатации опасного производственного объекта в случае аварии или инцидента на опасном производственном объекте",
        ],
      },
      {
        code: "58462",
        text: "Каким нормативным документом устанавливается обязательность проведения подготовки и аттестации работников, эксплуатирующих опасные производственные объекты, в области промышленной безопасности?",
        answers: [
          'Приказом Ростехнадзора от 29.01.2007 N 37 "О порядке подготовки и аттестации работников организаций, поднадзорных Федеральной службе по экологическому, технологическому и атомному надзору"',
          'Федеральным законом от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
          "Трудовым кодексом Российской Федерации",
        ],
        correctAnswers: [
          'Федеральным законом от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "58463",
        text: "Как производится ввод в эксплуатацию опасного производственного объекта?",
        answers: [
          "В порядке, установленном законодательством Российской Федерации о промышленной безопасности",
          "В порядке, установленном законодательством Российской Федерации о градостроительной деятельности",
          "В порядке, установленном законодательством Российской Федерации о техническом регулировании",
        ],
        correctAnswers: [
          "В порядке, установленном законодательством Российской Федерации о градостроительной деятельности",
        ],
      },
      {
        code: "58464",
        text: "Кем устанавливаются порядок разработки и требования к содержанию планов мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Ростехнадзором",
          "Правительством Российской Федерации",
          "МЧС России",
        ],
        correctAnswers: ["Правительством Российской Федерации"],
      },
      {
        code: "58465",
        text: "В отношении каких объектов предусмотрена разработка планов мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "В отношении всех опасных производственных объектов",
          "В отношении опасных производственных объектов I и II классов опасности",
          'Опасных производственных объектов I, II и III классов опасности, предусмотренных пп. 1, 4, 5 и 6 приложения 1 к Федеральному закону от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
        ],
        correctAnswers: [
          'Опасных производственных объектов I, II и III классов опасности, предусмотренных пп. 1, 4, 5 и 6 приложения 1 к Федеральному закону от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "58466",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов I класса опасности (за исключением объектов, на которых ведутся горные работы)?",
        answers: ["1 год", "2 года", "3 года", "5 лет"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "58467",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов II класса опасности (за исключением объектов, на которых ведутся горные работы)?",
        answers: ["1 год", "2 года", "3 года", "5 лет"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "58468",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов III класса опасности (за исключением объектов, на которых ведутся горные работы)?",
        answers: ["1 год", "2 года", "3 года", "5 лет"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "58469",
        text: "Когда план мероприятий по локализации и ликвидации последствий аварий считается принятым?",
        answers: [
          "После утверждения руководителем организации, эксплуатирующей опасные производственные объекты, либо руководителями обособленных подразделений",
          "После утверждения руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях) и согласования руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
          "После утверждения руководителем организации, эксплуатирующей опасные производственные объекты, либо руководителями обособленных подразделений и согласования с органами Ростехнадзора",
        ],
        correctAnswers: [
          "После утверждения руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях) и согласования руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
        ],
      },
      {
        code: "58470",
        text: "Какие организации обязаны создавать системы управления промышленной безопасностью?",
        answers: [
          "Все организации, эксплуатирующие опасные объекты",
          "Все юридические лица, эксплуатирующие опасные объекты",
          "Организации, эксплуатирующие объекты I или II классов опасности",
          "Организации, эксплуатирующие объекты I, II или III классов опасности",
        ],
        correctAnswers: [
          "Организации, эксплуатирующие объекты I или II классов опасности",
        ],
      },
      {
        code: "58471",
        text: "Кто устанавливает требования к документационному обеспечению систем управления промышленной безопасностью?",
        answers: [
          "Ростехнадзор",
          "Правительство Российской Федерации",
          "Росстандарт",
          "Росаккредитация",
        ],
        correctAnswers: ["Правительство Российской Федерации"],
      },
      {
        code: "58472",
        text: "Разработка каких документов в рамках организации документационного обеспечения систем управления промышленной безопасностью не предусмотрена в нормативном правовом акте?",
        answers: [
          "Плана мероприятий по снижению риска аварий на опасных производственных объектах на срок более 1 календарного года",
          "Плана мероприятий по обеспечению промышленной безопасности на календарный год",
          "Плана работ по модернизации опасного производственного объекта",
          "Разработка всех перечисленных документов обязательна",
        ],
        correctAnswers: [
          "Плана работ по модернизации опасного производственного объекта",
        ],
      },
      {
        code: "58473",
        text: "С какой периодичностью проводится документальное оформление результатов анализа функционирования системы управления промышленной безопасностью эксплуатирующей организацией?",
        answers: [
          "Не реже 1 раза в течение календарного года",
          "Не реже 2 раз в течение календарного года",
          "Не реже 1 раза в течение квартала",
          "На усмотрение эксплуатирующей организации",
        ],
        correctAnswers: ["Не реже 1 раза в течение календарного года"],
      },
      {
        code: "58474",
        text: "Какая организация осуществляет авторский надзор в процессе капитального ремонта или технического перевооружения опасного производственного объекта?",
        answers: [
          "Организация, эксплуатирующая опасный объект",
          'Организация, разработавшая соответствующую документацию в порядке, установленном сводом правил "Положение об авторском надзоре за строительством зданий и сооружений"',
          "Территориальный орган Ростехнадзора",
          "Орган местного самоуправления, на территории которого расположен опасный объект",
        ],
        correctAnswers: [
          'Организация, разработавшая соответствующую документацию в порядке, установленном сводом правил "Положение об авторском надзоре за строительством зданий и сооружений"',
        ],
      },
      {
        code: "58475",
        text: "Кто устанавливает требования к организации и осуществлению производственного контроля за соблюдением требований промышленной безопасности?",
        answers: [
          "Организация, эксплуатирующая опасный объект",
          "Правительство Российской Федерации",
          "Ростехнадзор",
          "Ростехнадзор совместно с МЧС России",
        ],
        correctAnswers: ["Правительство Российской Федерации"],
      },
      {
        code: "58476",
        text: "Какая из перечисленных задач не относится к задачам производственного контроля за соблюдением требований промышленной безопасности на опасном производственном объекте?",
        answers: [
          "Анализ состояния промышленной безопасности в эксплуатирующей опасный объект организации",
          "Координация работ, направленных на предупреждение аварий на опасном производственном объекте",
          "Контроль за своевременным проведением необходимых испытаний и технических освидетельствований технических устройств, применяемых на опасном производственном объекте, ремонтом и поверкой контрольных средств измерений",
          "Декларирование соответствия условий труда государственным нормативным требованиям охраны труда",
        ],
        correctAnswers: [
          "Декларирование соответствия условий труда государственным нормативным требованиям охраны труда",
        ],
      },
      {
        code: "58477",
        text: "При какой численности работников организации, эксплуатирующей опасный производственный объект, функции лица, ответственного за осуществление производственного контроля, возлагаются на специально назначенного работника?",
        answers: [
          "Менее 150 человек",
          "От 150 до 500 человек",
          "Свыше 500 человек",
        ],
        correctAnswers: ["От 150 до 500 человек"],
      },
      {
        code: "58478",
        text: "Что из перечисленного не относится к обязанностям работника, на которого возложены функции ответственного за осуществление производственного контроля?",
        answers: [
          "Организация и проведение проверок состояния промышленной безопасности",
          "Разработка плана работы по осуществлению производственного контроля",
          "Организация и проведение работ по специальной оценке условий труда",
          "Участие в техническом расследовании причин аварий, а также участие в расследовании инцидентов и несчастных случаев",
        ],
        correctAnswers: [
          "Организация и проведение работ по специальной оценке условий труда",
        ],
      },
      {
        code: "58479",
        text: "Кто должен разрабатывать Положение о производственном контроле?",
        answers: [
          "Только эксплуатирующая организация",
          "Только структурные подразделения эксплуатирующей организации",
          "Эксплуатирующая организация (обособленные подразделения юридического лица в случаях, предусмотренных положениями об обособленных подразделениях), индивидуальный предприниматель",
        ],
        correctAnswers: [
          "Эксплуатирующая организация (обособленные подразделения юридического лица в случаях, предусмотренных положениями об обособленных подразделениях), индивидуальный предприниматель",
        ],
      },
      {
        code: "58480",
        text: "Когда положение о производственном контроле считается принятым?",
        answers: [
          "После утверждения его руководителем эксплуатирующей организации (руководителем обособленного подразделения юридического лица), индивидуальным предпринимателем",
          "После его утверждения руководителем эксплуатирующей организации (руководителем обособленного подразделения юридического лица), индивидуальным предпринимателем и согласования с территориальным органом Ростехнадзора",
          "После его утверждения территориальным органом Ростехнадзора",
          "После его утверждения руководителем эксплуатирующей организации (руководителем обособленного подразделения юридического лица), индивидуальным предпринимателем и согласования с центральным аппаратом Ростехнадзора",
        ],
        correctAnswers: [
          "После утверждения его руководителем эксплуатирующей организации (руководителем обособленного подразделения юридического лица), индивидуальным предпринимателем",
        ],
      },
      {
        code: "58481",
        text: "Кто устанавливает требования к форме предоставления сведений об организации производственного контроля за соблюдением требований промышленной безопасности?",
        answers: [
          "Правительство Российской Федерации",
          "Ростехнадзор",
          "Федеральная служба по труду и занятости",
          "Эксплуатирующая организация",
        ],
        correctAnswers: ["Ростехнадзор"],
      },
      {
        code: "58482",
        text: "В каком документе установлен перечень сведений об организации производственного контроля за соблюдением требований промышленной безопасности, направляемых эксплуатирующей организацией в Ростехнадзор?",
        answers: [
          'В статье 4 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          "В Правилах организации и осуществления производственного контроля за соблюдением требований промышленной безопасности",
          "В Общих правилах промышленной безопасности для организаций, осуществляющих свою деятельность в области промышленной безопасности опасных производственных объектов",
          "Во всех перечисленных документах",
        ],
        correctAnswers: [
          "В Правилах организации и осуществления производственного контроля за соблюдением требований промышленной безопасности",
        ],
      },
      {
        code: "58483",
        text: "В каком случае юридическое лицо признается виновным в совершении административного правонарушения?",
        answers: [
          "Если будет установлено, что у него имелась возможность для соблюдения правил и норм, за нарушение которых предусмотрена административная ответственность, но им не были приняты все зависящие от него меры по их соблюдению",
          "Если должностное лицо, рассматривающее дело об административном правонарушении, абсолютно уверено в виновности юридического лица",
          "Если юридическое лицо признает факт совершения административного правонарушения",
        ],
        correctAnswers: [
          "Если будет установлено, что у него имелась возможность для соблюдения правил и норм, за нарушение которых предусмотрена административная ответственность, но им не были приняты все зависящие от него меры по их соблюдению",
        ],
      },
      {
        code: "58484",
        text: "Какая административная ответственность предусмотрена законодательством Российской Федерации за нарушение должностными лицами требований промышленной безопасности или лицензионных требований на осуществление видов деятельности в области промышленной безопасности?",
        answers: [
          "Вынесение письменного предупреждения, о чем делается соответствующая отметка в личном деле привлеченного к ответственности лица, или штраф в размере до 1 минимального размера оплаты труда",
          "Административный арест на срок до 15 суток или административный штраф в размере до 30 тысяч рублей",
          "Исправительные работы или административный штраф в размере до 50 тысяч рублей",
          "Наложение административного штрафа в размере от 20 до 30 тысяч рублей или дисквалификация на срок от 6 месяцев до одного года",
        ],
        correctAnswers: [
          "Наложение административного штрафа в размере от 20 до 30 тысяч рублей или дисквалификация на срок от 6 месяцев до одного года",
        ],
      },
      {
        code: "58485",
        text: "Что является грубым нарушением требований промышленной безопасности в соответствии с Кодексом Российской Федерации об административных правонарушениях?",
        answers: [
          "Нарушение требований промышленной безопасности, приведшее к возникновению непосредственной угрозы жизни или здоровью людей",
          "Нарушение требований промышленной безопасности, которое может привести к продолжительному простою оборудования",
          "Нарушение требований промышленной безопасности, которое может привести к остановке технологического процесса предприятия и вынужденным отпускам работников",
          "Нарушение требований промышленной безопасности, результатом которого может стать инцидент на опасном производственном объекте без возникновения угрозы жизни или здоровью работников",
        ],
        correctAnswers: [
          "Нарушение требований промышленной безопасности, приведшее к возникновению непосредственной угрозы жизни или здоровью людей",
        ],
      },
      {
        code: "58486",
        text: "В каком виде допускается представлять сведения об организации производственного контроля за соблюдением требований промышленной безопасности в Ростехнадзор?",
        answers: [
          "В письменной форме либо в форме электронного документа, подписанного усиленной квалифицированной электронной подписью",
          "В виде электронного документа в формате .pdf или .dif",
          "Только на бумажном носителе",
        ],
        correctAnswers: [
          "В письменной форме либо в форме электронного документа, подписанного усиленной квалифицированной электронной подписью",
        ],
      },
      {
        code: "58487",
        text: "Допускается ли подача сведений об организации производственного контроля на бумажном носителе с приложением электронных таблиц в формате .xls или .xlsx на машиночитаемом носителе?",
        answers: [
          "Да, с приложением электронных таблиц в случае наличия технической возможности",
          "Да, с обязательным приложением электронных таблиц только на машиночитаемом носителе",
          "Нет, не допускается",
        ],
        correctAnswers: [
          "Да, с приложением электронных таблиц в случае наличия технической возможности",
        ],
      },
      {
        code: "58488",
        text: "Каким образом допускается представлять сведения об организации производственного контроля организацией, эксплуатирующей несколько опасных производственных объектов?",
        answers: [
          "Сведения могут представляться в виде единого файла или нескольких файлов",
          "Сведения следует предоставлять в виде отдельных файлов по каждому опасному производственному объекту",
          "Сведения следует предоставлять на бумажном носителе в виде общего отчета организации, эксплуатирующей опасные производственные объекты",
        ],
        correctAnswers: [
          "Сведения могут представляться в виде единого файла или нескольких файлов",
        ],
      },
      {
        code: "58489",
        text: "В виде каких файлов должны формироваться электронные документы при подготовке отчета о производственном контроле?",
        answers: [
          "В формате XML",
          "В формате DIF, PDF",
          "В форматах JPEG, TIFF, BMP",
        ],
        correctAnswers: ["В формате XML"],
      },
      {
        code: "58490",
        text: "Вложения в каком формате не могут содержать электронные документы?",
        answers: [
          "В бинарном формате",
          "В форматах .doc, .docx",
          "XML-документы",
        ],
        correctAnswers: ["В бинарном формате"],
      },
      {
        code: "58491",
        text: "В какой срок проводится первичная аттестация работников в области промышленной безопасности при назначении на соответствующую должность?",
        answers: [
          "Не позднее 10 дней",
          "Не позднее 2 недель",
          "Не позднее 1 месяца",
          "Не позднее 2 месяцев",
        ],
        correctAnswers: ["Не позднее 1 месяца"],
      },
      {
        code: "58492",
        text: "Кем устанавливается порядок проведения аттестации в области промышленной безопасности?",
        answers: [
          "Федеральным агентством по техническому регулированию и метрологии",
          "Федеральными органами исполнительной власти в области промышленной безопасности",
          "Федеральной службой по экологическому, технологическому и атомному надзору",
          "Правительством Российской Федерации",
        ],
        correctAnswers: ["Правительством Российской Федерации"],
      },
      {
        code: "58493",
        text: "Что из перечисленного включает в себя документация системы управления промышленной безопасностью?",
        answers: [
          "Только документы планирования мероприятий по снижению риска аварий на опасных производственных объектах",
          "Только заявление о политике эксплуатирующих организаций в области промышленной безопасности",
          "Только положение о системе управления промышленной безопасностью",
          "Только положение (положения) о производственном контроле за соблюдением требований промышленной безопасности на опасных производственных объектах",
          "Все перечисленные документы",
        ],
        correctAnswers: ["Все перечисленные документы"],
      },
      {
        code: "58494",
        text: "Какие квалификационные требования предъявляются к работникам, ответственным за осуществление производственного контроля на опасных производственных объектах IV класса опасности?",
        answers: [
          "Высшее образование, общий стаж работы не менее 3 лет, аттестация в области промышленной безопасности",
          "Высшее или среднее техническое образование, стаж работы не менее 3 лет на соответствующем опасном производственном объекте отрасли, аттестация в области промышленной безопасности",
          "Высшее техническое образование, общий стаж работы не менее 3 лет, аттестация в области промышленной безопасности",
          "Высшее техническое образование и дополнительное профессиональное образование в области промышленной безопасности, стаж работы на опасном производственном объекте отрасли не менее 3 лет, аттестация в области промышленной безопасности",
        ],
        correctAnswers: [
          "Высшее техническое образование и дополнительное профессиональное образование в области промышленной безопасности, стаж работы на опасном производственном объекте отрасли не менее 3 лет, аттестация в области промышленной безопасности",
        ],
      },
      {
        code: "58495",
        text: "На что направлены мероприятия, проводимые эксплуатирующей организацией в рамках осуществления производственного контроля?",
        answers: [
          "Только на обеспечение готовности к действиям по локализации аварий и ликвидации их последствий",
          "Только на обеспечение безопасного функционирования опасных производственных объектов.",
          "Только на предупреждение аварий и инцидентов на опасных производственных объектах",
          "На все перечисленное",
        ],
        correctAnswers: ["На все перечисленное"],
      },
      {
        code: "58496",
        text: "В каком случае эксплуатирующая организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах на несколько опасных объектов?",
        answers: [
          "В случае, если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
          "В случае, если план мероприятий разрабатывается на каждый опасный объект отдельно",
          "В случае, если это регламентировано внутренней документацией организации",
          "В случае, если объекты зарегистрированы в государственном реестре опасных производственных объектов",
        ],
        correctAnswers: [
          "В случае, если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
        ],
      },
      {
        code: "58497",
        text: "Что входит в обязанности работника, на которого возложены функции лица, ответственного за осуществление производственного контроля? Укажите все правильные ответы.",
        answers: [
          "Контроль за устранением причин возникновения аварий, инцидентов и несчастных случаев",
          "Контроль за выполнением предписаний Федеральной службы по экологическому, технологическому и атомному надзору и ее территориальных органов, а также соответствующих федеральных органов исполнительной власти по вопросам промышленной безопасности",
          "Выполнение лицензионных требований при оформлении лицензии на эксплуатацию взрывопожароопасных и химически опасных производственных объектов I, II и III классов опасности",
          "Контроль за выполнением лицензионных требований при осуществлении лицензируемой деятельности в области промышленной безопасности",
        ],
        correctAnswers: [
          "Контроль за устранением причин возникновения аварий, инцидентов и несчастных случаев",
          "Контроль за выполнением предписаний Федеральной службы по экологическому, технологическому и атомному надзору и ее территориальных органов, а также соответствующих федеральных органов исполнительной власти по вопросам промышленной безопасности",
          "Контроль за выполнением лицензионных требований при осуществлении лицензируемой деятельности в области промышленной безопасности",
        ],
      },
      {
        code: "58498",
        text: "В какой документации определяются обязанности и права работников, на которых возложены функции лиц, ответственных за организацию и осуществление производственного контроля? Укажите все правильные ответы.",
        answers: [
          "В положении о производственном контроле",
          'В постановлении Правительства Российской Федерации "Об организации и осуществлении производственного контроля за соблюдением требований промышленной безопасности"',
          "В должностных инструкциях или заключаемых с этими работниками договорах (контрактах)",
          "В плане мероприятий по обеспечению промышленной безопасности на основании результатов проверки состояния промышленной безопасности на объекте",
        ],
        correctAnswers: [
          "В положении о производственном контроле",
          "В должностных инструкциях или заключаемых с этими работниками договорах (контрактах)",
        ],
      },
      {
        code: "58499",
        text: "Кем утверждается положение о системе управления промышленной безопасностью?",
        answers: [
          "Руководителем организации, эксплуатирующей опасный производственный объект, руководителями обособленных подразделений, а также инспектором территориального органа Ростехнадзора",
          "Руководителем эксплуатирующей организации",
          "Руководителем организации, проводившей экспертизу промышленной безопасности документа",
        ],
        correctAnswers: ["Руководителем эксплуатирующей организации"],
      },
      {
        code: "58500",
        text: "Какую информацию не включает в себя общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий",
          "Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
          "Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах",
          "Характеристику объекта, в отношении которого разрабатывается план мероприятий",
        ],
        correctAnswers: [
          "Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
        ],
      },
      {
        code: "58501",
        text: "Кто утверждает планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два варианта ответа.",
        answers: [
          "Руководитель (заместители руководителей) организаций, эксплуатирующих объекты",
          "Руководители профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований (ПАСФ)",
          "Руководители обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
          "Представитель Ростехнадзора",
        ],
        correctAnswers: [
          "Руководитель (заместители руководителей) организаций, эксплуатирующих объекты",
          "Руководители обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
        ],
      },
      {
        code: "58502",
        text: "Какие квалификационные требования предъявляются к работникам, ответственным за осуществление производственного контроля на опасных производственных объектах I-III класса опасности?",
        answers: [
          "Высшее техническое образование, общий стаж работы не менее 3 лет, дополнительное профессиональное образование в области промышленной безопасности, аттестация в области промышленной безопасности",
          "Высшее или среднее техническое образование, стаж работы не менее 3 лет на соответствующей работе на опасном производственном объекте отрасли, дополнительное профессиональное образование в области промышленной безопасности, аттестация в области промышленной безопасности",
          "Высшее техническое образование, стаж работы на опасном производственном объекте отрасли не менее 3 лет, не реже одного раза в 5 лет получать дополнительное профессиональное образование в области промышленной безопасности и проходить аттестацию в области промышленной безопасности",
        ],
        correctAnswers: [
          "Высшее техническое образование, стаж работы на опасном производственном объекте отрасли не менее 3 лет, не реже одного раза в 5 лет получать дополнительное профессиональное образование в области промышленной безопасности и проходить аттестацию в области промышленной безопасности",
        ],
      },
      {
        code: "58503",
        text: "Кем утверждается заявление о политике эксплуатирующей организации в области промышленной безопасности?",
        answers: [
          "Территориальным органом Ростехнадзора",
          "Техническим руководителем эксплуатирующей организации",
          "Руководителем эксплуатирующей организации",
          "Руководителем муниципального образования, на территории которого расположен объект",
        ],
        correctAnswers: ["Руководителем эксплуатирующей организации"],
      },
      {
        code: "58504",
        text: "В каком из перечисленных случаев пересматриваются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Укажите все правильные ответы.",
        answers: [
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
          "Не позднее 40 календарных дней после внесения изменений в системы управления технологическими процессами на объекте",
          "Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий",
          "Не позднее 15 календарных дней после изменения сведений, содержащихся в общих или специальных разделах плана мероприятий",
        ],
        correctAnswers: [
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
          "Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий",
        ],
      },
      {
        code: "58505",
        text: "Кем осуществляется производственный контроль в эксплуатирующей организации? Укажите все правильные ответы.",
        answers: [
          "Назначенным (определенным) решением руководителя эксплуатирующей организации работником",
          "Инспектором территориального органа Ростехнадзора",
          "Службой производственного контроля",
          "Техническим руководителем организации",
          "Разработчиком системы управления промышленной безопасностью",
        ],
        correctAnswers: [
          "Назначенным (определенным) решением руководителя эксплуатирующей организации работником",
          "Службой производственного контроля",
        ],
      },
      {
        code: "58506",
        text: "В каких комиссиях проходят аттестацию члены аттестационных комиссий организаций, эксплуатирующих опасные производственные объекты (за исключением организаций, работники которых подлежат аттестации в ведомственных аттестационных комиссиях)?",
        answers: [
          "Только в территориальных аттестационных комиссиях",
          "В аттестационных комиссиях организации",
          "Только в центральной аттестационной комиссии",
          "В ведомственных аттестационных комиссиях",
        ],
        correctAnswers: ["Только в территориальных аттестационных комиссиях"],
      },
      {
        code: "58507",
        text: "Куда организация, эксплуатирующая опасный производственный объект, представляет информацию об организации производственного контроля за соблюдением требований промышленной безопасности?",
        answers: [
          "Только в Ростехнадзор",
          "В Ростехнадзор или его территориальные органы",
          "Только в территориальные органы Ростехнадзора",
          "В Министерство цифрового развития, связи и массовых коммуникаций Российской Федерации и территориальные органы Ростехнадзора",
        ],
        correctAnswers: ["В Ростехнадзор или его территориальные органы"],
      },
      {
        code: "58508",
        text: "В какие сроки эксплуатирующая организация представляет в Ростехнадзор или его территориальные органы сведения об организации производственного контроля за соблюдением требований промышленной безопасности?",
        answers: [
          "Ежегодно до 1 февраля соответствующего календарного года",
          "Ежегодно до 1 марта соответствующего календарного года",
          "Ежегодно до 1 апреля соответствующего календарного года",
          "Ежегодно до 1 мая соответствующего календарного года",
        ],
        correctAnswers: [
          "Ежегодно до 1 апреля соответствующего календарного года",
        ],
      },
      {
        code: "58509",
        text: "Что из перечисленного не подлежит экспертизе промышленной безопасности?",
        answers: [
          "Документация на техническое перевооружение, консервацию, ликвидацию опасного производственного объекта",
          "Технические устройства, применяемые на опасном производственном объекте",
          "Здания и сооружения на опасном производственном объекте, предназначенные для технологических процессов, хранения сырья или продукции, перемещения людей и грузов, локализации или ликвидации последствий аварий",
          "Декларация промышленной безопасности, разрабатываемая в составе документации на техническое перевооружение (в случае, если указанная документация входит в состав проектной документации опасного производственного объекта, подлежащей экспертизе в соответствии с законодательством о градостроительной деятельности)",
          "Обоснование безопасности опасного производственного объекта, а также изменения, вносимые в обоснование безопасности опасного производственного объекта",
        ],
        correctAnswers: [
          "Декларация промышленной безопасности, разрабатываемая в составе документации на техническое перевооружение (в случае, если указанная документация входит в состав проектной документации опасного производственного объекта, подлежащей экспертизе в соответствии с законодательством о градостроительной деятельности)",
        ],
      },
      {
        code: "58510",
        text: "Что из перечисленного не подлежит экспертизе промышленной безопасности?",
        answers: [
          "Документация на капитальный ремонт опасного производственного объекта",
          "Технические устройства, которые применяются на опасном производственном объекте",
          "Здания и сооружения на опасном производственном объекте, предназначенные для технологических процессов, хранения сырья или продукции, перемещения людей и грузов, а также локализации и ликвидации последствий аварий",
          "Декларация промышленной безопасности опасного производственного объекта",
          "Обоснование безопасности опасного производственного объекта, а также изменения к обоснованию безопасности опасного производственного объекта",
        ],
        correctAnswers: [
          "Документация на капитальный ремонт опасного производственного объекта",
        ],
      },
      {
        code: "58511",
        text: "Какая организация имеет право проводить экспертизу промышленной безопасности?",
        answers: [
          "Организация, имеющая лицензию на проведение указанной экспертизы промышленной безопасности",
          "Организация, аккредитованная в Федеральной службе по аккредитации на проведение указанной экспертизы промышленной безопасности",
          "Организация, имеющая допуск СРО на проведение указанной экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "Организация, имеющая лицензию на проведение указанной экспертизы промышленной безопасности",
        ],
      },
      {
        code: "58512",
        text: "В отношении какого опасного производственного объекта эксперту запрещается участвовать в проведении экспертизы промышленной безопасности?",
        answers: [
          "В отношении опасных производственных объектов по хранению и уничтожению химического оружия",
          "В отношении объектов, которые находятся в государственной собственности",
          "В отношении опасного производственного объекта, принадлежащего на праве собственности или ином законном основании организации, в трудовых отношениях с которой состоит эксперт",
        ],
        correctAnswers: [
          "В отношении опасного производственного объекта, принадлежащего на праве собственности или ином законном основании организации, в трудовых отношениях с которой состоит эксперт",
        ],
      },
      {
        code: "58513",
        text: "Какими нормативными правовыми актами устанавливаются требования к проведению экспертизы промышленной безопасности и к оформлению заключения экспертизы промышленной безопасности?",
        answers: [
          "Постановлениями Правительства Российской Федерации",
          "Федеральными законами",
          "Федеральными нормами и правилами в области промышленной безопасности",
          "Стандартами саморегулируемых организаций в области экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "Федеральными нормами и правилами в области промышленной безопасности",
        ],
      },
      {
        code: "58514",
        text: 'После прохождения каких процедур заключение экспертизы промышленной безопасности может быть использовано в целях, установленных Федеральным законом от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов"?',
        answers: [
          "Сразу после подписания заключения экспертизы руководителем экспертной организации и экспертами в области промышленной безопасности, проводившими экспертизу",
          "После утверждения заключения экспертизы промышленной безопасности в Ростехнадзоре",
          "После подписания заключения экспертизы руководителем экспертной организации и экспертами, проводившими экспертизу, и внесения его в реестр заключений экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "После подписания заключения экспертизы руководителем экспертной организации и экспертами, проводившими экспертизу, и внесения его в реестр заключений экспертизы промышленной безопасности",
        ],
      },
      {
        code: "58515",
        text: "Кто ведет реестр заключений экспертизы промышленной безопасности?",
        answers: [
          "Ростехнадзор и его территориальные органы",
          'Федеральное учреждение "Главное управление государственной экспертизы"',
          "Росстандарт",
          "Росаккредитация",
        ],
        correctAnswers: ["Ростехнадзор и его территориальные органы"],
      },
      {
        code: "58516",
        text: "Что является результатом проведения экспертизы промышленной безопасности?",
        answers: [
          "Заключение экспертизы промышленной безопасности в письменной форме либо в форме электронного документа",
          "Сертификат соответствия объекта экспертизы в электронной форме",
          "Экспертная оценка объекта экспертизы, оформленная протоколом в письменной форме",
        ],
        correctAnswers: [
          "Заключение экспертизы промышленной безопасности в письменной форме либо в форме электронного документа",
        ],
      },
      {
        code: "58517",
        text: "В каких случаях техническое устройство, применяемое на опасном производственном объекте, подлежит экспертизе промышленной безопасности, если техническим регламентом не установлена иная форма оценки соответствия указанного устройства обязательным требованиям?",
        answers: [
          "Только по истечении срока службы или при превышении количества циклов нагрузки такого технического устройства, установленных его производителем",
          "Только после проведения работ, связанных с изменением конструкции, заменой материала несущих элементов такого технического устройства",
          "Только до начала применения на опасном производственном объекте",
          "Только при отсутствии в технической документации данных о сроке службы такого технического устройства, если фактический срок его службы превышает двадцать лет",
          "Во всех перечисленных",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "58518",
        text: "Какие выводы может содержать заключение экспертизы промышленной безопасности технических устройств?",
        answers: [
          "Только вывод о том, что объект экспертизы соответствует требованиям промышленной безопасности и может быть применен при эксплуатации опасного производственного объекта",
          "Только вывод о том, что объект экспертизы не в полной мере соответствует требованиям промышленной безопасности и может быть применен при условии выполнения соответствующих мероприятий",
          "Только вывод о том, что объект экспертизы не соответствует требованиям промышленной безопасности и не может быть применен при эксплуатации опасного производственного объекта",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "58519",
        text: "После выполнения каких мероприятий экспертная организация приступает к проведению экспертизы промышленной безопасности? Укажите все правильные ответы.",
        answers: [
          "Предоставления заказчиком необходимых для проведения экспертизы документов",
          "После проведения расчетных и аналитических процедур оценки и прогнозирования технического состояния технических устройств",
          "Проведения технического диагностирования технических устройств",
          "Предоставления образцов технических устройств либо обеспечения доступа экспертов к техническим устройствам, применяемым на опасном производственном объекте",
        ],
        correctAnswers: [
          "Предоставления заказчиком необходимых для проведения экспертизы документов",
          "Предоставления образцов технических устройств либо обеспечения доступа экспертов к техническим устройствам, применяемым на опасном производственном объекте",
        ],
      },
      {
        code: "58520",
        text: "Каким из указанных требованиям должен соответствовать эксперт второй категории в области промышленной безопасности? Укажите все правильные ответы.",
        answers: [
          "Иметь действующий протокол об аттестации в области промышленной безопасности, выданный территориальной аттестационной комиссией",
          "Иметь стаж работы не менее 7 лет по специальности, соответствующей его области (областям) аттестации",
          "Иметь лицензию на осуществление деятельности по проведению экспертизы промышленной безопасности",
          "Иметь опыт проведения не менее пяти экспертиз",
          "Иметь высшее образование",
        ],
        correctAnswers: [
          "Иметь стаж работы не менее 7 лет по специальности, соответствующей его области (областям) аттестации",
          "Иметь высшее образование",
        ],
      },
      {
        code: "58521",
        text: "Кем представляется заключение экспертизы промышленной безопасности технических устройств в Ростехнадзор для внесения в реестр?",
        answers: [
          "Заказчиком экспертизы",
          "Лабораторией, проводившей неразрушающий контроль металла и сварных соединений",
          "Проектной организацией",
          "Экспертной организацией",
        ],
        correctAnswers: ["Заказчиком экспертизы"],
      },
      {
        code: "58522",
        text: "Эксперты какой категории имеют право участвовать в проведении экспертизы промышленной безопасности опасных производственных объектов II класса опасности?",
        answers: [
          "Только первой",
          "Второй и (или) третьей",
          "Первой и (или) второй",
          "Только второй",
        ],
        correctAnswers: ["Первой и (или) второй"],
      },
      {
        code: "58523",
        text: "Какие сведения отражаются в заключении экспертизы промышленной безопасности по результатам экспертизы технического устройства?",
        answers: [
          "Расчетные и аналитические процедуры оценки и прогнозирования технического состояния объекта экспертизы, включающие определение остаточного ресурса (срока службы)",
          "Выводы о правильности и достоверности выполненных расчетов по анализу риска, а также полноты учета факторов, влияющих на конечные результаты",
          "Обоснованность применяемых физико-математических моделей и использованных методов расчета последствий аварии и показателей риска",
          "Выводы о достаточности мер предотвращения проникновения на опасный производственный объект посторонних лиц",
        ],
        correctAnswers: [
          "Расчетные и аналитические процедуры оценки и прогнозирования технического состояния объекта экспертизы, включающие определение остаточного ресурса (срока службы)",
        ],
      },
      {
        code: "58524",
        text: "Какие требования к экспертам в области промышленной безопасности указаны верно?",
        answers: [
          "При аттестации на 1 категорию эксперт обязан иметь высшее образование и стаж работы в области проведения экспертизы промышленной безопасности не менее пяти лет",
          "Эксперт обязан иметь лицензию на осуществление деятельности по проведению экспертизы промышленной безопасности",
          "Эксперту запрещается участвовать в проведении экспертизы в отношении опасных производственных объектов, принадлежащих на праве собственности или ином законном основании организации, в трудовых отношениях с которой он состоит",
          "Эксперт обязан подготавливать заключение экспертизы промышленной безопасности и предоставлять его в территориальный орган Ростехнадзора для дальнейшей регистрации",
        ],
        correctAnswers: [
          "Эксперту запрещается участвовать в проведении экспертизы в отношении опасных производственных объектов, принадлежащих на праве собственности или ином законном основании организации, в трудовых отношениях с которой он состоит",
        ],
      },
      {
        code: "58525",
        text: 'Какой срок проведения экспертизы промышленной безопасности установлен Федеральными норами и правилами "Правила проведения экспертизы промышленной безопасности"?',
        answers: [
          "Определяется экспертной организацией, но не должен превышать пяти месяцев со дня получения экспертной организацией от заказчика экспертизы комплекта необходимых материалов и документов",
          "Срок проведения экспертизы определяется сложностью объекта экспертизы, но не должен превышать трех месяцев со дня получения экспертной организацией от заказчика экспертизы комплекта необходимых материалов и документов",
          "Определяется заказчиком экспертизы, но не должен превышать двух месяцев со дня получения экспертной организацией от заказчика экспертизы комплекта необходимых материалов и документов",
          "Определяется по соглашению сторон, но не должен превышать одного месяца со дня получения экспертной организацией от заказчика экспертизы комплекта необходимых материалов и документов",
        ],
        correctAnswers: [
          "Срок проведения экспертизы определяется сложностью объекта экспертизы, но не должен превышать трех месяцев со дня получения экспертной организацией от заказчика экспертизы комплекта необходимых материалов и документов",
        ],
      },
      {
        code: "58526",
        text: "Каким документом установлен перечень сведений, содержащихся в декларации промышленной безопасности, и порядок ее оформления?",
        answers: [
          'Федеральным законом от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
          "Правилами, утвержденными постановлением Правительства Российской Федерации",
          "Документом, утвержденным федеральным органом исполнительной власти в области промышленной безопасности",
          "Положением, утвержденным совместным приказом Ростехнадзора и МЧС России",
        ],
        correctAnswers: [
          "Документом, утвержденным федеральным органом исполнительной власти в области промышленной безопасности",
        ],
      },
      {
        code: "58527",
        text: "Для каких опасных производственных объектов обязательна разработка декларации промышленной безопасности?",
        answers: [
          'Для опасных производственных объектов I, II и III классов опасности, на которых получаются, используются, перерабатываются, образуются, хранятся, транспортируются опасные вещества в количествах, указанных в приложении 2 к Федеральному закону от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
          "Для всех опасных производственных объектов независимо от их класса опасности",
          'Для опасных производственных объектов I и II классов опасности, на которых получаются, используются, перерабатываются, образуются, хранятся, транспортируются, уничтожаются опасные вещества в количествах, указанных в приложении 2 к Федеральному закону от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов" (за исключением использования взрывчатых веществ при проведении взрывных работ)',
          'Для опасных производственных объектов, указанных в приложении 1 к Федеральному закону от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
        ],
        correctAnswers: [
          'Для опасных производственных объектов I и II классов опасности, на которых получаются, используются, перерабатываются, образуются, хранятся, транспортируются, уничтожаются опасные вещества в количествах, указанных в приложении 2 к Федеральному закону от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов" (за исключением использования взрывчатых веществ при проведении взрывных работ)',
        ],
      },
      {
        code: "58528",
        text: "В какой срок после внесения в реестр последней декларации промышленной безопасности для действующих опасных производственных объектов декларация должна быть разработана вновь?",
        answers: [
          "По истечении 10 лет",
          "По истечении 5 лет",
          "Декларация не разрабатывается повторно",
          "В десятидневный срок при смене владельца опасного объекта",
        ],
        correctAnswers: ["По истечении 10 лет"],
      },
      {
        code: "58529",
        text: "В каком случае для действующих опасных производственных объектов декларация промышленной безопасности не должна разрабатываться вновь?",
        answers: [
          "В случае истечения 10 лет со дня внесения в реестр деклараций промышленной безопасности последней декларации промышленной безопасности",
          "В случае увеличения на 5 процентов количества опасных веществ, которые находятся или могут находиться на опасном производственном объекте",
          "В случае изменения требований промышленной безопасности или изменения технологического процесса на опасном производственном объекте",
          "По предписанию федерального органа исполнительной власти в области промышленной безопасности (или его территориального органа) в случае выявления несоответствия сведений, содержащихся в декларации промышленной безопасности, сведениям, полученным в ходе осуществления федерального государственного надзора в области промышленной безопасности",
        ],
        correctAnswers: [
          "В случае увеличения на 5 процентов количества опасных веществ, которые находятся или могут находиться на опасном производственном объекте",
        ],
      },
      {
        code: "58530",
        text: "Кто утверждает декларацию промышленной безопасности?",
        answers: [
          "Руководитель территориального органа Ростехнадзора или его заместители",
          "Руководитель экспертной организации, выполнившей экспертизу декларации промышленной безопасности",
          "Руководитель организации, эксплуатирующей опасный производственный объект",
          "Руководитель эксплуатирующей объект организации совместно с территориальным органом Ростехнадзора",
        ],
        correctAnswers: [
          "Руководитель организации, эксплуатирующей опасный производственный объект",
        ],
      },
      {
        code: "58531",
        text: "Кто осуществляет ведение реестра деклараций промышленной безопасности опасных производственных объектов?",
        answers: [
          "Ростехнадзор",
          "Государственная регистрационная палата",
          "Ростехнадзор совместно с МЧС России",
          "Минстрой России совместно с МЧС России",
        ],
        correctAnswers: ["Ростехнадзор"],
      },
      {
        code: "58532",
        text: "Какой экспертизе подлежит декларация промышленной безопасности, разрабатываемая в составе документации на техническое перевооружение опасного производственного объекта?",
        answers: [
          "Экспертизе промышленной безопасности в установленном порядке",
          "Государственной экспертизе в соответствии с законодательством Российской Федерации о градостроительной деятельности",
          "Декларация промышленной безопасности экспертизу проходить не должна",
          "Экологической экспертизе",
        ],
        correctAnswers: [
          "Экспертизе промышленной безопасности в установленном порядке",
        ],
      },
      {
        code: "58533",
        text: "В каком из перечисленных случаев декларация промышленной безопасности находящегося в эксплуатации опасного производственного объекта не разрабатывается вновь?",
        answers: [
          "В случае истечения 9 лет со дня внесения в реестр деклараций промышленной безопасности последней декларации промышленной безопасности",
          "В случае изменения технологических процессов на опасном производственном объекте либо увеличения более чем на 20 процентов количества опасных веществ, которые находятся или могут находиться на опасном производственном объекте",
          "В случае изменения требований промышленной безопасности",
          "По предписанию федерального органа исполнительной власти в области промышленной безопасности (или его территориального органа) в случае выявления несоответствия сведений, содержащихся в декларации промышленной безопасности, сведениям, полученным в ходе осуществления федерального государственного надзора в области промышленной безопасности",
        ],
        correctAnswers: [
          "В случае истечения 9 лет со дня внесения в реестр деклараций промышленной безопасности последней декларации промышленной безопасности",
        ],
      },
      {
        code: "58534",
        text: "В каком случае руководитель или иное уполномоченное лицо организации, эксплуатирующей опасный производственный объект, обязан представлять утвержденный экземпляр декларации в Федеральную службу по экологическому, технологическому и атомному надзору?",
        answers: [
          "Не обязан ни в каком случае",
          "Только в случае, если обяжет суд",
          "Правилами не регламентируется",
          "Обязан в любом случае",
        ],
        correctAnswers: ["Обязан в любом случае"],
      },
      {
        code: "58535",
        text: "Кем устанавливается перечень сведений, содержащихся в декларации и информационном листе (приложении к декларации), и порядок их оформления?",
        answers: [
          "Органами местного самоуправления по месту нахождения опасного объекта",
          "Правительством РФ",
          "Организацией, эксплуатирующей опасный объект",
          "Федеральной службой по экологическому, технологическому и атомному надзору",
        ],
        correctAnswers: [
          "Федеральной службой по экологическому, технологическому и атомному надзору",
        ],
      },
      {
        code: "58536",
        text: "Где должны храниться оригиналы декларации промышленной безопасности?",
        answers: [
          "В Ростехнадзоре",
          "В организациях, эксплуатирующих опасные производственные объекты",
          "В органах местного самоуправления",
          "В МЧС России",
        ],
        correctAnswers: [
          "В организациях, эксплуатирующих опасные производственные объекты",
        ],
      },
      {
        code: "58537",
        text: "В целях решения каких задач разработаны Правила представления декларации промышленной безопасности опасных производственных объектов?",
        answers: [
          "Только в целях обеспечения деятельности в области охраны окружающей среды и защиты населения и территорий от чрезвычайных ситуаций",
          "Только в целях повышения эффективности взаимодействия органов государственной власти, органов местного самоуправления и общественных объединений по проблемам обеспечения промышленной безопасности опасных производственных объектов",
          "Только в целях создания условий для организации и осуществления государственного надзора в области промышленной безопасности",
          "Всех перечисленных задач",
        ],
        correctAnswers: ["Всех перечисленных задач"],
      },
    ],
  },
  78: {
    20101: [
      {
        code: "20101000",
        text: "Что необходимо предпринять организации, эксплуатирующей химически опасный производственный объект, в целях приведения его в соответствие требованиям Правил безопасности химически опасных производственных объектов?",
        answers: [
          "Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь",
          "Провести экспертизу промышленной безопасности",
          "Провести капитальный ремонт ХОПО",
          "Проинформировать Ростехнадзор о выявленных несоответствиях Правилам в рамках проведения производственного контроля",
        ],
        correctAnswers: [
          "Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь",
        ],
      },
      {
        code: "20101001",
        text: "В каком документе указываются регламентированные значения параметров по ведению технологического процесса?",
        answers: [
          "В проектной документации",
          "В технологическом регламенте на производство продукции",
          "В руководстве по безопасности",
        ],
        correctAnswers: [
          "В технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20101002",
        text: "Кем разрабатываются исходные данные на разработку документации на химически опасных производственных объектах?",
        answers: [
          "Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области",
          "Проектными организациями",
          "Организациями, эксплуатирующими химически опасные производственные объекты",
          "Организациями, осуществляющими проведение экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области",
        ],
      },
      {
        code: "20101003",
        text: "Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?",
        answers: [
          "Выбор значений параметров состояния технологической среды (состава, давления, температуры), снижающих ее химическую опасность",
          "Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки",
          "Надежное энергообеспечение",
          "Рациональный подбор взаимодействующих компонентов исходя из условия максимального снижения или исключения образования химически опасных смесей или продуктов",
        ],
        correctAnswers: [
          "Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки",
        ],
      },
      {
        code: "20101004",
        text: "В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?",
        answers: [
          "В течение 24 часов",
          "В течение 48 часов",
          "В течение времени, установленного проектом",
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
        correctAnswers: [
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
      },
      {
        code: "20101005",
        text: "Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах I и II классов опасности?",
        answers: [
          "Не более 12 секунд",
          "Не более 120 секунд",
          "Не более 300 секунд",
          "Не более 240 секунд",
        ],
        correctAnswers: ["Не более 12 секунд"],
      },
      {
        code: "20101006",
        text: "Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах III класса опасности?",
        answers: [
          "Не менее 12 секунд",
          "Не более 120 секунд",
          "Не менее 300 секунд",
          "Не более 240 секунд",
        ],
        correctAnswers: ["Не более 120 секунд"],
      },
      {
        code: "20101007",
        text: "Для каких из перечисленных целей запрещается использовать специальные системы аварийного освобождения?",
        answers: [
          "Для исключения образования химически опасных смесей как в самих системах, так и в окружающей атмосфере",
          "Для обеспечения минимально возможного времени освобождения",
          "Для исключения образования химически опасных смесей в окружающей атмосфере",
          "Для исключения развития аварий",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20101008",
        text: "В каком случае допускается объединение выбросов химически опасных веществ, содержащих вещества, способные при смешивании образовывать более опасные по воздействиям химические соединения?",
        answers: [
          "В случае использования специальных систем аварийного освобождения",
          "В случае применения разработанных и утвержденных мер необходимой безопасности",
          "В случае если производится расчет количества выбросов, определяемого условиями безопасной остановки технологического процесса",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20101009",
        text: "Куда следует направлять сбрасываемые химически опасные вещества?",
        answers: [
          "На факельную установку",
          "В специальные контейнеры",
          "В закрытые системы для дальнейшей утилизации",
          "В централизованную систему водоотведения",
        ],
        correctAnswers: ["В закрытые системы для дальнейшей утилизации"],
      },
      {
        code: "20101010",
        text: "В каком документе организация, эксплуатирующая химически опасные производственные объекты I, II и III классов опасности, должна предусматривать действия работников по предупреждению аварий, их локализации и максимальному снижению тяжести последствий?",
        answers: [
          "В локальном нормативном акте",
          "В плане мероприятий по локализации и ликвидации последствий аварий",
          "В Положении о производственном контроле",
          "В технологическом регламенте",
        ],
        correctAnswers: [
          "В плане мероприятий по локализации и ликвидации последствий аварий",
        ],
      },
      {
        code: "20101011",
        text: "В соответствии с какими документами осуществляют ведение технологических процессов на химически опасных производственных объектах?",
        answers: [
          "В соответствии с нормативно-правовыми документами",
          "В соответствии с инструкциями по эксплуатации технических устройств",
          "В соответствии с технологическими регламентами на производство продукции",
          "В соответствии с технологической частью проектной документации",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами на производство продукции",
        ],
      },
      {
        code: "20101012",
        text: "Какие типы технологических регламентов предусматриваются в зависимости от степени освоенности производств и целей осуществляемых работ?",
        answers: [
          "Постоянные, временные, разовые и лабораторные",
          "Входящие в состав проектной документации",
          "Только пусковые",
        ],
        correctAnswers: ["Постоянные, временные, разовые и лабораторные"],
      },
      {
        code: "20101013",
        text: "Какие технологические регламенты разрабатываются при выпуске товарной продукции на опытных и опытно-промышленных установках (цехах), а также для опытных и опытно-промышленных работ, проводимых на действующих производствах?",
        answers: [
          "Разовые (опытные) технологические регламенты",
          "Постоянные технологические регламенты",
          "Лабораторные технологические регламенты (пусковые записки, производственные методики)",
          "Временные (пусковые) технологические регламенты",
        ],
        correctAnswers: ["Разовые (опытные) технологические регламенты"],
      },
      {
        code: "20101014",
        text: "Для каких из перечисленных производств разрабатываются постоянные технологические регламенты?",
        answers: [
          "Только для действующих химико-технологических производств, в технологию которых внесены принципиальные изменения",
          "Только для производств с новой технологией",
          "Для новых производств в данной организации",
          "Для всех перечисленных производств",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20101015",
        text: "В каком случае допускается наработка товарной продукции по лабораторным регламентам (пусковым запискам, производственным методикам)?",
        answers: [
          "Объемом до 1000 кг/год",
          "Объемом до 2000 кг/год",
          "На действующих химико-технологических производствах, в технологию которых внесены принципиальные изменения",
          "Ни в каком случае",
        ],
        correctAnswers: ["Объемом до 1000 кг/год"],
      },
      {
        code: "20101016",
        text: "Какой из перечисленных разделов не относится к постоянным, временным и разовым технологическим регламентам, связанным с необходимостью обеспечения промышленной безопасности технологических процессов?",
        answers: [
          '"Контроль производства и управление технологическим процессом"',
          '"Нормы расхода основных видов сырья, материалов и энергоресурсов"',
          '"Характеристика производимой продукции"',
          '"Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств"',
        ],
        correctAnswers: [
          '"Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств"',
        ],
      },
      {
        code: "20101017",
        text: 'Информацию о каких организациях должен содержать раздел технологического регламента "Общая характеристика производства"?',
        answers: [
          "Только об организации, выполнявшей функции генерального проектировщика",
          "Только об организации - разработчике технологической части проектной документации",
          "Только об организации - разработчике технологического процесса",
          "Только об организации, выполнявшей проектную документацию",
          "Обо всех перечисленных организациях",
        ],
        correctAnswers: ["Обо всех перечисленных организациях"],
      },
      {
        code: "20101018",
        text: 'Как производится описание технологической схемы в разделе технологического регламента "Описание технологического процесса и схемы"?',
        answers: [
          "По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта",
          "По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование",
          "По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование и заканчивая отгрузкой готового продукта",
        ],
        correctAnswers: [
          "По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта",
        ],
      },
      {
        code: "20101019",
        text: 'Что необходимо указывать в описании процессов разделения химических продуктов (горючих или их смесей с негорючими) в разделе технологического регламента "Описание технологического процесса и схемы"?',
        answers: [
          "Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса",
          "Показатели пожароопасности, степень разделения сред",
          "Токсичные свойства всех веществ, участвующих в процессе на всех стадиях процесса",
        ],
        correctAnswers: [
          "Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса",
        ],
      },
      {
        code: "20101020",
        text: 'Какой устанавливается срок действия "Накопительной ведомости"?',
        answers: ["3 месяца", "6 месяцев", "9 месяцев", "12 месяцев"],
        correctAnswers: ["6 месяцев"],
      },
      {
        code: "20101021",
        text: 'Какие разновидности материального баланса допускается составлять в разделе технологического регламента "Материальный баланс"?',
        answers: [
          "Только на единицу выпускаемой продукции",
          "Только на единицу времени",
          "Только на один производственный поток или на мощность производства в целом",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20101022",
        text: "На основе каких данных составляется материальный баланс для действующих производств?",
        answers: [
          "На основе достигнутых показателей работы производств в последний год перед составлением технологического регламента",
          "На основе данных проектной документации",
          "На основе данных проектной документации с учетом внесенных в нее изменений, включения или исключения дополнительных операций или стадий",
        ],
        correctAnswers: [
          "На основе достигнутых показателей работы производств в последний год перед составлением технологического регламента",
        ],
      },
      {
        code: "20101023",
        text: 'Какое из перечисленных требований не соответствует разделу технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          "Средства автоматики, используемые по плану мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, должны быть определены особо",
          "Периодичность испытаний запорной регулирующей арматуры, исполнительных механизмов, периодичность проверок приборов и испытаний других технических средств, участвующих в схемах контроля, управления и противоаварийной защиты технологических процессов, должны быть взаимоувязаны",
          "Допускается разделять в отдельные таблицы перечень систем сигнализации и блокировок и (или) перечень аналитического контроля",
          "Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается",
        ],
        correctAnswers: [
          "Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается",
        ],
      },
      {
        code: "20101024",
        text: "Кто утверждает все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?",
        answers: [
          "Начальник технического отдела организации, эксплуатирующей химико-технологическое производство",
          "Техническая комиссия организации, эксплуатирующей химико-технологическое производство",
          "Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство",
          "Технологический руководитель организации, эксплуатирующей химико-технологическое производство",
        ],
        correctAnswers: [
          "Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство",
        ],
      },
      {
        code: "20101025",
        text: "На кого возлагается ответственность за полноту и качество разработки разделов технологического регламента производства продукции и контроль за обеспечением его исполнения?",
        answers: [
          "На технологическую службу организации, производства, отделения, установки",
          "На организацию - разработчика технологического процесса",
          "На комиссию организации, эксплуатирующей химико-технологическое производство",
          "На руководителя организации, эксплуатирующей химико-технологическое производство",
        ],
        correctAnswers: [
          "На технологическую службу организации, производства, отделения, установки",
        ],
      },
      {
        code: "20101026",
        text: "Кем разрабатываются все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?",
        answers: [
          "Специализированной сторонней организацией",
          "Организацией - разработчиком технологического процесса",
          "Организацией, эксплуатирующей химико-технологическое производство",
          "Организацией - разработчиком технологической части проектной документации",
        ],
        correctAnswers: [
          "Организацией, эксплуатирующей химико-технологическое производство",
        ],
      },
      {
        code: "20101027",
        text: 'Кто подписывается в технологическом регламенте под грифом "согласовано"? Укажите все правильные ответы.',
        answers: [
          "Начальник производственно-технического (технического) отдела организации",
          "Главный механик и главный энергетик организации",
          "Главный метролог организации",
          "Начальник производства",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Главный механик и главный энергетик организации",
          "Главный метролог организации",
        ],
      },
      {
        code: "20101028",
        text: "Какое количество копий технологических регламентов устанавливается требованиями Правил безопасности химически опасных производственных объектов?",
        answers: [
          "Не менее 6 копий",
          "Не менее 10 копий",
          "Не менее 5 копий",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20101029",
        text: "Какой устанавливается срок действия постоянного технологического регламента?",
        answers: [
          "Не более 15 лет",
          "Не более 20 лет",
          "Не более 12 лет",
          "Не более 10 лет",
        ],
        correctAnswers: ["Не более 10 лет"],
      },
      {
        code: "20101030",
        text: "Каким образом определяется срок действия временного технологического регламента при отсутствии установленных планами норм освоения производства?",
        answers: [
          "В соответствии с проектом",
          "Главным инженером организации",
          "Приказом руководителя организации",
          "Лицом, утверждающим технологический регламент",
        ],
        correctAnswers: ["Лицом, утверждающим технологический регламент"],
      },
      {
        code: "20101031",
        text: "Каким образом устанавливается и оформляется срок продления действия временного технологического регламента?",
        answers: [
          "Приказом главного инженера организации",
          "Приказом руководителя службы производственного контроля организации",
          "Приказом руководителя организации",
          "Приказом главного технолога организации",
        ],
        correctAnswers: ["Приказом руководителя организации"],
      },
      {
        code: "20101032",
        text: "В каком из перечисленных случаев должен быть составлен временный технологический регламент на новый срок? Укажите все правильные ответы.",
        answers: [
          "Если в тексте временного технологического регламента обнаружены ошибки и исправления",
          "Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса",
          "Если освоение производства длиться больше года",
          "Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей",
        ],
        correctAnswers: [
          "Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса",
          "Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей",
        ],
      },
      {
        code: "20101033",
        text: "Какой устанавливается срок действия разовых (опытных) технологических регламентов, в соответствии с которыми проводится наработка опытной продукции в течение нескольких лет?",
        answers: [
          "Не более 5 лет",
          "Не более 8 лет",
          "Не более 10 лет",
          "Не более 6 лет",
        ],
        correctAnswers: ["Не более 5 лет"],
      },
      {
        code: "20101034",
        text: 'Какое из перечисленных требований соответствует нормам заполнения и хранения "Листа регистрации изменений и дополнений"?',
        answers: [
          "Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента",
          "Регистрация изменений и дополнений выполняется главным технологом организации",
          "Запись в регистрационном листе выполняется чернилами черного цвета",
          "Лист регистрации изменений и дополнений размещается после титульного листа технологического регламента",
        ],
        correctAnswers: [
          "Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента",
        ],
      },
      {
        code: "20101035",
        text: "Каким образом определяются способ хранения жидкого аммиака, количество, вместимость?",
        answers: [
          "Нормативно-технической документацией",
          "Проектом из условия обеспечения безопасной эксплуатации",
          "Правилами безопасности химически опасных производственных объектов",
          "Технологическими регламентами",
        ],
        correctAnswers: [
          "Проектом из условия обеспечения безопасной эксплуатации",
        ],
      },
      {
        code: "20101036",
        text: "В каких резервуарах не допускается осуществлять хранение жидкого аммиака?",
        answers: [
          "В резервуарах под избыточным давлением до 2,0 МПа включительно без отвода аммиака. Рабочее давление в резервуаре принимается исходя из максимальной температуры окружающего воздуха с учетом солнечной радиации, наличия тепловой изоляции и защитных конструкций",
          "В резервуарах под избыточным давлением до 4,0 МПа включительно без отвода аммиака. Рабочее давление в резервуаре принимается исходя из максимальной температуры окружающего воздуха с учетом солнечной радиации, наличия тепловой изоляции и защитных конструкций",
          "В изотермических резервуарах под давлением, близким к атмосферному, с отводом испаряющегося аммиака, компримированием, конденсацией и возвратом в резервуар или потребителю",
          "В резервуарах под избыточным давлением до 1,0 МПа включительно с отводом аммиака, испаряющегося от теплопритока, с выдачей его потребителю или компримированием испарившегося аммиака с последующей конденсацией и возвратом его в резервуар",
        ],
        correctAnswers: [
          "В резервуарах под избыточным давлением до 4,0 МПа включительно без отвода аммиака. Рабочее давление в резервуаре принимается исходя из максимальной температуры окружающего воздуха с учетом солнечной радиации, наличия тепловой изоляции и защитных конструкций",
        ],
      },
      {
        code: "20101037",
        text: "Учитывается ли вместимость резервного резервуара при определении вместимости склада в процессе хранения аммиака под давлением?",
        answers: [
          "Учитывается в проектной документации",
          "Учитывается в процессе хранения аммиака в резервуарах под давлением",
          "Учитывается при возникновении неисправности резервуара",
          "Не учитывается",
        ],
        correctAnswers: ["Не учитывается"],
      },
      {
        code: "20101038",
        text: "Какое из перечисленных требований не допускается на территории склада жидкого аммиака?",
        answers: [
          "Сопряжение 2 наружных ограждений резервуаров для хранения жидкого аммиака",
          "Расположение сливоналивных устройств и эстакады по одну сторону железнодорожного пути",
          "Прокладывание транзитных трубопроводов, не относящихся к резервуарам для хранения жидкого аммиака, и кабелей через огражденные территории резервуаров для хранения жидкого аммиака",
          "Планирование территории в ограждении резервуаров для хранения жидкого аммиака с уклоном в сторону приямка",
        ],
        correctAnswers: [
          "Прокладывание транзитных трубопроводов, не относящихся к резервуарам для хранения жидкого аммиака, и кабелей через огражденные территории резервуаров для хранения жидкого аммиака",
        ],
      },
      {
        code: "20101039",
        text: "Кто проводит регистрацию изотермического резервуара в установленном порядке?",
        answers: [
          "Организация, эксплуатирующая изотермический резервуар",
          "Проектная организация",
          "Специализированная организация, осуществляющая экспертизу промышленной безопасности",
          "Регистрация изотермического резервуара не требуется",
        ],
        correctAnswers: [
          "Организация, эксплуатирующая изотермический резервуар",
        ],
      },
      {
        code: "20101040",
        text: "Какой параметр является критерием установления категории взрывоопасности технологических блоков согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?",
        answers: [
          "Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему",
          "Класс опасности обращающихся в процессе веществ",
          "Температура самовоспламенения паров обращающихся в процессе веществ",
          "Скорость распространения горения обращающихся в процессе веществ",
        ],
        correctAnswers: [
          "Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему",
        ],
      },
      {
        code: "20101041",
        text: "Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?",
        answers: [
          "Энергией сгорания парогазовой фазы",
          "Радиусом зон разрушения",
          "Приведенной массой вещества, участвующего во взрыве",
          "Категорией взрывоопасности технологических блоков",
        ],
        correctAnswers: ["Категорией взрывоопасности технологических блоков"],
      },
      {
        code: "20101042",
        text: "Каким образом обеспечивается отработка персоналом практических навыков безопасного выполнения работ, предупреждения аварий и ликвидации их последствий на технологических объектах с блоками I и II категорий взрывоопасности?",
        answers: [
          "Посредством допуска персонала к самостоятельной работе не ранее чем через 3 месяцев после стажировки на объекте",
          "Посредством прохождения подготовки в собственных специализированных центрах обучения",
          "Посредством прохождения курса подготовки с использованием современных средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)",
          "Посредством изучения наглядных пособий (плакатов) по охране труда и пожарной безопасности",
        ],
        correctAnswers: [
          "Посредством прохождения курса подготовки с использованием современных средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)",
        ],
      },
      {
        code: "20101043",
        text: "В какой документации приводятся способы и средства, исключающие выход параметров за установленные пределы?",
        answers: [
          "Только в исходных данных на проектирование",
          "Только в проектной документации",
          "Только в технологическом регламенте",
          "В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции",
        ],
        correctAnswers: [
          "В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20101044",
        text: "Каким образом осуществляется управление подачей инертных сред на установку с технологическими блоками любой категории взрывоопасности там, где при отклонении от регламентированных значений параметров возможно образование взрывоопасных смесей?",
        answers: [
          "Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, для установок с технологическими блоками II категории взрывоопасности - ручное, дистанционное, для установок с технологическими блоками III категории взрывоопасности допускается ручное по месту",
          "Для установок с технологическими блоками I и II категории взрывоопасности предусматривается автоматическое управление подачей инертных сред, с технологическими блоками III категории - управление дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление",
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление",
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Qв ≤ 10 - управление ручное, дистанционное",
        ],
        correctAnswers: [
          "Для установок с технологическими блоками I и II категории взрывоопасности предусматривается автоматическое управление подачей инертных сред, с технологическими блоками III категории - управление дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление",
        ],
      },
      {
        code: "20101045",
        text: "Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?",
        answers: [
          "Системами ручного (без применения вычислительной техники) регулирования",
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
          "Средствами обеспечения питания инертными газами систем контрольно-измерительных приборов и автоматики",
        ],
        correctAnswers: [
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
        ],
      },
      {
        code: "20101046",
        text: "Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?",
        answers: [
          "Время срабатывания установлено для каждого технологического блока в соответствии с категорией взрывоопасности",
          "Время срабатывания определяется расчетом",
          "Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и установлено для блоков III категории",
          "Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и установлено для блоков I и II категорий",
        ],
        correctAnswers: ["Время срабатывания определяется расчетом"],
      },
      {
        code: "20101047",
        text: "Какими блокировками должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей?",
        answers: [
          "Блокировками, исключающими пуск или прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях",
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе, достижении опасных значений в приемной емкости",
          "Блокировками, исключающими пуск или прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях",
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
        correctAnswers: [
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
      },
      {
        code: "20101048",
        text: "Каким образом осуществляется регулирование массообменных процессов, в которых при отклонении технологических параметров от регламентированных значений возможно образование неустойчивых взрывоопасных соединений?",
        answers: [
          "Для установок с технологическими блоками I категории взрывоопасности - автоматическое регулирование, с технологическими блоками II категории взрывоопасности - ручное дистанционное регулирование, с технологическими блоками III категории взрывоопасности допускается ручное регулирование по месту",
          "Для установок с технологическими блоками I и II категорий взрывоопасности - при помощи средств автоматического регулирования, для установок III категории взрывоопасности предусматривается выполнение операций регулирования в ручном режиме (производственным персоналом) при обеспечении автоматического контроля указанных параметров процесса и сигнализации о превышении их допустимых значений",
          "Для установок с технологическими блоками I категории взрывоопасности - автоматическое регулирование, с технологическими блоками II категории взрывоопасности - ручное дистанционное регулирование при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений, с технологическими блоками III категории взрывоопасности допускается ручное регулирование по месту",
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое регулирование, а при Qв ≤ 10 допускается ручное дистанционное регулирование",
        ],
        correctAnswers: [
          "Для установок с технологическими блоками I и II категорий взрывоопасности - при помощи средств автоматического регулирования, для установок III категории взрывоопасности предусматривается выполнение операций регулирования в ручном режиме (производственным персоналом) при обеспечении автоматического контроля указанных параметров процесса и сигнализации о превышении их допустимых значений",
        ],
      },
      {
        code: "20101049",
        text: "Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?",
        answers: [
          "На установках с технологическими блоками I категории взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками II и III категорий взрывоопасности - не регламентируется",
          "На установках с технологическими блоками I и II категорий взрывоопасности давление теплоносителя (хладагента) не должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками III категорий взрывоопасности - не регламентируется",
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
          "Давление теплоносителя (хладагента) не должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
        ],
        correctAnswers: [
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
        ],
      },
      {
        code: "20101050",
        text: "Кто определяет выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?",
        answers: [
          "Заказчик",
          "Разработчик процесса",
          "Разработчик проекта",
          "Разработчики проекта и процесса",
        ],
        correctAnswers: ["Разработчик процесса"],
      },
      {
        code: "20101051",
        text: "Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся и горючие жидкости на сливоналивных эстакадах?",
        answers: [
          "Должно быть организовано только управление по месту",
          "Должно быть организовано только управление дистанционно (из безопасного места)",
          "Должно быть организовано управление и по месту, и дистанционно (из безопасного места)",
        ],
        correctAnswers: [
          "Должно быть организовано управление и по месту, и дистанционно (из безопасного места)",
        ],
      },
      {
        code: "20101052",
        text: "Какие сведения являются основополагающими при выборе технологического оборудования для обеспечения технологических процессов?",
        answers: [
          "Расчетные данные, которым должны соответствовать параметры оборудования, и показатели надежности",
          "Требования действующих нормативных документов и расчетные данные, которым должны соответствовать параметры оборудования",
          "Расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование и требования действующих нормативных документов",
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
        correctAnswers: [
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
      },
      {
        code: "20101053",
        text: "Как производителем должна подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств до начала их применения на опасном производственном объекте?",
        answers: [
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
          "Оценкой научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования",
          "Опытными работами, проводимыми разработчиком данного оборудования, и экспертизой промышленной безопасности",
          "Получением заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизой промышленной безопасности",
        ],
        correctAnswers: [
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
        ],
      },
      {
        code: "20101054",
        text: "Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?",
        answers: [
          "Оборудование должно быть изолировано от действующей технологической системы, и нанесенное на нем обозначение номера по технологической документации должно быть закрашено на схеме",
          "Оборудование должно быть демонтировано, если оно расположено в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих технологических систем",
          "Оборудование должно быть демонтировано, если оно расположено в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно должно быть изолировано от действующих технологических систем",
          "Оборудование должно быть демонтировано",
        ],
        correctAnswers: [
          "Оборудование должно быть демонтировано, если оно расположено в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих технологических систем",
        ],
      },
      {
        code: "20101055",
        text: "В каких случаях допускается применение для нагнетания легковоспламеняющихся и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?",
        answers: [
          "При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости",
          "При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях",
          "При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации",
          "При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости",
        ],
        correctAnswers: [
          "При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации",
        ],
      },
      {
        code: "20101056",
        text: "В каких местах не допускается размещать фланцевые соединения трубопроводов с пожаровзрывоопасными, токсичными и едкими веществами?",
        answers: [
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
          "Над автодорогами",
          "На трубопроводах, идущих по стенам зданий",
          "На трубопроводах, проложенных по эстакадам",
        ],
        correctAnswers: [
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
        ],
      },
      {
        code: "20101057",
        text: "На каких трубопроводах следует применять арматуру под приварку для повышения надежности и плотности соединений?",
        answers: [
          "На трубопроводах технологических блоков II категории взрывоопасности с температурой, равной температуре кипения среды при регламентированном давлении",
          "На трубопроводах технологических блоков I категории взрывоопасности с давлением среды менее 2,5 МПа",
          "На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды при регламентированном давлении",
          "На трубопроводах технологических блоков III категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды при регламентированном давлении",
        ],
        correctAnswers: [
          "На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды при регламентированном давлении",
        ],
      },
      {
        code: "20101058",
        text: "Что в химико-технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и их развития?",
        answers: [
          "Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы",
          "Запорная арматура, средства защиты от превышения давления, огнепреградители",
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления",
          "Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления",
        ],
      },
      {
        code: "20101059",
        text: 'Какое требование к системам вентиляции не соответствует ФНП "Общие правила взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств"?',
        answers: [
          "Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания",
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
          "Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства",
          'Электрооборудование вентиляционных систем только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о") допускается устанавливать в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах)',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о") допускается устанавливать в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах)',
        ],
      },
      {
        code: "20101060",
        text: "Какая система отопления предусматривается в помещениях, имеющих взрывоопасные зоны?",
        answers: [
          "Только система водяного отопления",
          "Только система парового отопления",
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
        ],
        correctAnswers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
        ],
      },
      {
        code: "20101061",
        text: "Какие требования предъявляются к системам канализации технологических объектов при сбросе химически загрязненных стоков в магистральную сеть канализации?",
        answers: [
          "Системы канализации технологических объектов должны осуществлять сброс стоков в магистральную сеть в порядке, установленном организацией",
          "Системы канализации технологических объектов должны быть оборудованы звуковой и световой сигнализацией",
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
          "Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть",
        ],
        correctAnswers: [
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
        ],
      },
      {
        code: "20101062",
        text: "Какой максимальный коэффициент заполнения объема резервуара допускается при хранении жидкого аммиака под избыточным давлением?",
        answers: [
          "0,8 от геометрического объема резервуара",
          "0,75 от геометрического объема резервуара",
          "0,85 от геометрического объема резервуара",
          "0,9 от геометрического объема резервуара",
        ],
        correctAnswers: ["0,85 от геометрического объема резервуара"],
      },
      {
        code: "20101063",
        text: "Каким забором следует ограждать территорию склада жидкого аммиака, расположенного на предприятии?",
        answers: [
          "Бетонным забором высотой более 2 м",
          "Деревянным забором высотой не менее 1,5 м",
          "Сетчатым забором",
          "Забором из несгораемых материалов высотой не менее 2 м",
        ],
        correctAnswers: ["Сетчатым забором"],
      },
      {
        code: "20101064",
        text: "Какое устройство должно быть установлено на территории склада жидкого аммиака?",
        answers: [
          "Барометр",
          "Указатель направления ветра",
          "Термометр",
          "Гигрометр",
        ],
        correctAnswers: ["Указатель направления ветра"],
      },
      {
        code: "20101065",
        text: "Какой должна быть высота ограждения резервуаров для хранения жидкого аммиака?",
        answers: [
          "Не менее расчетного уровня разлившегося жидкого аммиака",
          "Не менее чем на 0,5 м выше расчетного уровня разлившегося жидкого аммиака",
          "Не менее чем на 0,2 м выше расчетного уровня разлившегося жидкого аммиака",
          "Не менее чем на 0,3 м выше расчетного уровня разлившегося жидкого аммиака, но не менее 1 м, а для изотермических резервуаров - не менее 1,5 м",
        ],
        correctAnswers: [
          "Не менее чем на 0,3 м выше расчетного уровня разлившегося жидкого аммиака, но не менее 1 м, а для изотермических резервуаров - не менее 1,5 м",
        ],
      },
      {
        code: "20101066",
        text: "Какое расстояние устанавливается внутри ограждения между лестницами, применяемыми для переходов через ограждения резервуаров для хранения жидкого аммиака?",
        answers: [
          "Не более 100 м",
          "Не более 80 м",
          "Не более 95 м",
          "Не более 90 м",
        ],
        correctAnswers: ["Не более 80 м"],
      },
      {
        code: "20101067",
        text: "На каком расстоянии со стороны зданий и открытых установок, примыкающих к ограждению резервуаров для хранения жидкого аммиака, допускается располагать объезд?",
        answers: [
          "Не более 39 м от ограждения резервуаров",
          "Не более 40 м от ограждения резервуаров",
          "Не более 50 м от ограждения резервуаров",
          "Не более 49 м от ограждения резервуаров",
        ],
        correctAnswers: ["Не более 39 м от ограждения резервуаров"],
      },
      {
        code: "20101068",
        text: "Какая ширина должна быть у автомобильных дорог для подъезда к складу жидкого аммиака и проезду по его территории к зданиям и сооружениям?",
        answers: [
          "Не менее 3 м",
          "Не менее 3,5 м",
          "Не менее 2 м",
          "Не менее 2,5 м",
        ],
        correctAnswers: ["Не менее 3,5 м"],
      },
      {
        code: "20101069",
        text: "Какое из перечисленных требований к расположению цистерн с жидким аммиаком в организации указано верно?",
        answers: [
          'До и во время проведения слива цистерна должна ограждаться переносными сигналами и должен быть установлен знак размером 300 x 500 мм с надписью "Осторожно! Слив аммиака"',
          "Перед сливом аммиака цистерна должна быть заземлена и подключена к блокировке сдвига цистерны",
          "На внутренних железнодорожных путях организации, не имеющих стрелочных переводов, должен устанавливаться затворный предохранительный брус на расстоянии не менее 2 м от цистерны",
          "Колеса цистерны на рельсовом пути должны быть закреплены и с одной стороны подклинены тормозными башмаками",
        ],
        correctAnswers: [
          "Перед сливом аммиака цистерна должна быть заземлена и подключена к блокировке сдвига цистерны",
        ],
      },
      {
        code: "20101070",
        text: "Какие требования к условиям устойчивой работы объектов производств масел указано неверно?",
        answers: [
          "Обеспечение электроэнергией, паром, водой, сжатым воздухом, инертным газом (азотом)",
          "Обеспечение производства системами контроля и регулирования технологических параметров в заданном диапазоне",
          "Проведение своевременной диспансеризации технического персонала",
          "Проведение своевременного диагностирования технического состояния технологического оборудования",
        ],
        correctAnswers: [
          "Проведение своевременной диспансеризации технического персонала",
        ],
      },
      {
        code: "20101071",
        text: "Каким документом обосновывается достаточность мер по максимальному снижению уровня взрывоопасности технологических блоков, предотвращению взрывов и загораний внутри технологического оборудования, производственных помещений и наружных установок объектов, связанных с производством растительных масел?",
        answers: [
          "Инструкцией по безопасности технологического производства растительных масел",
          "Планом мероприятий по предотвращению взрывов и загораний внутри технологического оборудования, производственных помещений и наружных установок объектов, связанных с производством растительных масел",
          "Проектной документацией",
          "Технологическим регламентом",
        ],
        correctAnswers: ["Проектной документацией"],
      },
      {
        code: "20101072",
        text: "Кто устанавливает значения параметров процесса и допустимых диапазонов их изменения, исключающих возможность возникновения опасных отклонений, способных стать причиной аварийной ситуации или аварии на объектах, связанных с производством растительных масел?",
        answers: [
          "Разработчик процесса",
          "Главный технолог",
          "Главный инженер организации, эксплуатирующей объекты производства масел",
          "Проектировщик",
        ],
        correctAnswers: ["Разработчик процесса"],
      },
      {
        code: "20101073",
        text: "При каких условиях допускается проводить технологический взрывоопасный процесс в области критических значений температур на объектах, связанных с производством растительных масел?",
        answers: [
          "В случае наличия оформленного наряда-допуска на производство работ повышенной опасности",
          "При условии, что персонал обеспечен средствами индивидуальной защиты",
          "В случае принятия мер, исключающих или предотвращающих появление источников зажигания",
          "Ни при каких условиях",
        ],
        correctAnswers: [
          "В случае принятия мер, исключающих или предотвращающих появление источников зажигания",
        ],
      },
      {
        code: "20101074",
        text: "Чем должно быть обеспечено технологическое оборудование, в котором возможно образование взрывоопасных смесей на объектах, связанных с производством растительных масел?",
        answers: [
          "Системами подачи флегматизатора (азота)",
          "Системами пожаротушения",
          "Предупреждающими знаками и ограждением",
          "Защитными кожухами",
        ],
        correctAnswers: ["Системами подачи флегматизатора (азота)"],
      },
      {
        code: "20101075",
        text: "Каким документом определяются выбор способа подачи флегматизатора, его количества для обеспечения эффективности продувки и исключения возможности образования застойных зон на объектах, связанных с производством растительных масел?",
        answers: [
          "Технической документацией по эксплуатации",
          "Технологической картой",
          "Технологическим регламентом",
          "Проектной документацией",
        ],
        correctAnswers: ["Проектной документацией"],
      },
      {
        code: "20101076",
        text: "Что предусматривается для максимального снижения выбросов в окружающую среду растворителя в виде паров и жидкой фазы при аварийной разгерметизации системы в технологических системах объектов производств растительных масел для технологических блоков I категории взрывоопасности?",
        answers: [
          "Установка запорных и (или) отсекающих устройств с дистанционным управлением и временем срабатывания не более 120 с",
          "Установка автоматических быстродействующих запорных и (или) отсекающих устройств со временем срабатывания не более 12 с",
          "Установка запорных устройств с ручным приводом, при этом предусматривается минимальное время приведения их в действие за счет рационального размещения (максимально допустимого приближения к рабочему месту оператора), но не более 300 с",
          "Установка автоматических быстродействующих запорных и (или) отсекающих устройств со временем срабатывания не более 20 с",
        ],
        correctAnswers: [
          "Установка автоматических быстродействующих запорных и (или) отсекающих устройств со временем срабатывания не более 12 с",
        ],
      },
      {
        code: "20101077",
        text: "Какое требование установлено к газосигнализаторам довзрывных концентраций горючих газов в помещениях цеха экстракции, отгонки растворителя из шрота, дистилляции, насосных для перекачки растворителя объектов производств растительных масел?",
        answers: [
          "Газосигнализаторы довзрывных концентраций горючих газов с сигнализацией превышения 5 % уровня от нижнего концентрационного предела распространения пламени",
          "Газосигнализаторы с сигнализацией превышения 10 % уровня от нижнего концентрационного предела распространения пламени",
          "Газосигнализаторы довзрывных концентраций горючих газов с сигнализацией превышения 15 % уровня от нижнего концентрационного предела распространения пламени",
          "Газосигнализаторы с сигнализацией превышения 20 % уровня от нижнего концентрационного предела распространения пламени",
        ],
        correctAnswers: [
          "Газосигнализаторы с сигнализацией превышения 10 % уровня от нижнего концентрационного предела распространения пламени",
        ],
      },
      {
        code: "20101078",
        text: "Кем определяются порядок, способы и периодичность уборки пыли в производственных помещениях объектов производств растительных масел?",
        answers: [
          "Начальником производства",
          "Главным технологом",
          "Главным инженером",
          "Руководителем организации",
          "Начальником технического отдела",
        ],
        correctAnswers: ["Руководителем организации"],
      },
      {
        code: "20101079",
        text: "Какое содержание кислорода допускается при анализе продувочного газа после продувки экстракционной линии?",
        answers: [
          "Не более 7 %",
          "Не более 8 %",
          "Не более 10 %",
          "Содержание кислорода определяется технологическим регламентом",
        ],
        correctAnswers: ["Не более 7 %"],
      },
      {
        code: "20101080",
        text: "На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?",
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          "На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах горнорудной и металлургической промышленности",
          "На ведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на выделенной территории находящихся в эксплуатации опасных производственных объектов, доступ на площадку к которым ограничен сплошными ограждающими конструкциями",
          "На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы",
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "20101081",
        text: "Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года",
          "Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
      {
        code: "20101082",
        text: "Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?",
        answers: [
          "Лицо, зарегистрировавшее наряд-допуск, на требуемый для окончания работ срок",
          "Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
          "Руководитель структурного подразделения не более чем на 1 рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск, не более чем на 1 дневную смену",
        ],
        correctAnswers: [
          "Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
        ],
      },
      {
        code: "20101083",
        text: "В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?",
        answers: [
          "Не менее 1 месяца со дня закрытия наряда-допуска",
          "Не менее 3 месяцев со дня закрытия наряда-допуска",
          "Не менее 6 месяцев со дня закрытия наряда-допуска",
        ],
        correctAnswers: ["Не менее 6 месяцев со дня закрытия наряда-допуска"],
      },
      {
        code: "20101084",
        text: "Какие должны быть здания на территории склада жидкого аммиака по степени огнестойкости?",
        answers: [
          "Не ниже II степени огнестойкости",
          "Не ниже III степени огнестойкости",
          "Не ниже IV степени огнестойкости",
          "Не ниже V степени огнестойкости",
        ],
        correctAnswers: ["Не ниже II степени огнестойкости"],
      },
      {
        code: "20101085",
        text: "Кто устанавливает назначенный срок службы для технологического оборудования, машин и трубопроводной арматуры?",
        answers: [
          "Организация-изготовитель",
          "Орган по сертификации",
          "Орган по сертификации на основании заключения испытательной лаборатории",
          "Проектная организация",
        ],
        correctAnswers: ["Организация-изготовитель"],
      },
      {
        code: "20101086",
        text: "Кто устанавливает назначенный срок службы для технологических трубопроводов?",
        answers: [
          "Орган по сертификации",
          "Орган по сертификации на основании заключения испытательной лаборатории",
          "Разработчик документации на химически опасном производственном объекте",
          "Организация-изготовитель",
        ],
        correctAnswers: [
          "Разработчик документации на химически опасном производственном объекте",
        ],
      },
      {
        code: "20101087",
        text: "В каком случае допускается определение толщин стенок трубопроводов иным способом, отличным от метода неразрушающего контроля?",
        answers: [
          "Допускается в местах, где применение неразрушающего контроля затруднено или невозможно",
          "Допускается в соответствии с технической документацией на химически опасные производственные объекты",
          "Допускается в присутствии инспектора Ростехнадзора",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "Допускается в местах, где применение неразрушающего контроля затруднено или невозможно",
        ],
      },
      {
        code: "20101088",
        text: "Какой объем неразрушающего контроля сварных соединений технологических трубопроводов, транспортирующих токсичные и высокотоксичные вещества, предусмотрен в Правилах безопасности химически опасных производственных объектов?",
        answers: [
          "Не менее 50 % длины сварного шва каждого сварного соединения",
          "Не менее 70 % длины сварного шва каждого сварного соединения",
          "Не менее 90 % длины сварного шва каждого сварного соединения",
          "Не менее 100 % длины сварного шва каждого сварного соединения",
        ],
        correctAnswers: [
          "Не менее 100 % длины сварного шва каждого сварного соединения",
        ],
      },
      {
        code: "20101089",
        text: "Что необходимо предусматривать в химико-технологических системах для эффективного проведения периодических работ по очистке технологического оборудования?",
        answers: [
          "Наличие оросительных систем",
          "Наличие средств гидравлической, механической или химической чистки",
          "Наличие специально обученного и имеющего допуски персонала для очистки технологического оборудования",
          "Возможность изоляции близстоящего оборудования",
        ],
        correctAnswers: [
          "Наличие средств гидравлической, механической или химической чистки",
        ],
      },
      {
        code: "20101090",
        text: "Что необходимо учитывать при размещении технологического оборудования, трубопроводной арматуры в производственных зданиях и на открытых площадках?",
        answers: [
          "Возможность быстрого демонтажа оборудования",
          "Возможность проведения ремонтных работ, визуального контроля и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий",
          "Наличие специального персонала, имеющего необходимые допуски для очистки оборудования",
          "Минимизацию контакта с коррозионно-активными веществами",
        ],
        correctAnswers: [
          "Возможность проведения ремонтных работ, визуального контроля и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий",
        ],
      },
      {
        code: "20101091",
        text: "В какой документации должны быть определены порядок контроля за степенью коррозионного износа оборудования и трубопроводов с использованием методов неразрушающего контроля, способы, периодичность и места проведения контрольных замеров?",
        answers: [
          "В справочной литературе",
          "В эксплуатационной документации организации-изготовителя",
          "В проектной документации",
          "В технологических регламентах",
        ],
        correctAnswers: [
          "В эксплуатационной документации организации-изготовителя",
        ],
      },
      {
        code: "20101092",
        text: "Какие дополнительные требования установлены при использовании технологического оборудования и трубопроводов, в которых обращаются коррозионно-активные вещества?",
        answers: [
          "Запрещено для защиты технологического оборудования использовать неметаллические покрытия",
          "Контроль за технологическим оборудованием и трубопроводами, контактирующими с коррозионно-активными веществами, должен осуществляться не реже 1 раза в месяц",
          "Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии",
          "Технологическое оборудование и трубопроводы должны быть защищены металлическими коррозионно-стойкими покрытиями",
        ],
        correctAnswers: [
          "Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии",
        ],
      },
      {
        code: "20101093",
        text: "Чем определяется количество насосов и компрессоров, используемых для перемещения химически опасных веществ в технологическом процессе?",
        answers: [
          "Техническими требованиями к безопасности работы в химически опасных средах",
          "Необходимостью и условиями обеспечения непрерывности технологического процесса",
          "Физико-химическими свойствами перемещаемых химически опасных веществ",
          "Техническими характеристиками и расчетом обеспечения надежности применяемых насосов и компрессоров",
        ],
        correctAnswers: [
          "Необходимостью и условиями обеспечения непрерывности технологического процесса",
        ],
      },
      {
        code: "20101094",
        text: "Какие условия должны выполняться для допуска к эксплуатации компрессорных установок?",
        answers: [
          "Получение разрешения на применение технических устройств, выдаваемого органами Ростехнадзора",
          "Наличие положительного заключения экспертизы промышленной безопасности",
          "Наличие и исправное состояние средств автоматизации, контроля и системы блокировок",
          "Все перечисленные условия",
        ],
        correctAnswers: [
          "Наличие и исправное состояние средств автоматизации, контроля и системы блокировок",
        ],
      },
      {
        code: "20101095",
        text: "Каким должен быть объем контроля сварных швов резервуаров для хранения жидкого аммиака?",
        answers: [
          "Не менее 80 % от длины каждого шва",
          "Не менее 50 % от длины каждого шва",
          "Не менее 30 % от длины каждого шва",
          "100 %",
        ],
        correctAnswers: ["100 %"],
      },
      {
        code: "20101096",
        text: "При какой вместимости резервуаров, работающих под избыточным внутренним давлением, допускается применение подогревательных устройств, размещаемых внутри или на наружной поверхности резервуаров?",
        answers: [
          "Не более 60 т",
          "Не более 50 т",
          "Не более 100 т",
          "Не более 80 т",
        ],
        correctAnswers: ["Не более 50 т"],
      },
      {
        code: "20101097",
        text: "В каком случае необходимо контролировать величину и равномерность осадки фундаментов шаровых резервуаров в эксплуатации?",
        answers: [
          "Только периодически во время эксплуатации",
          "Только до и после гидравлического испытания резервуара",
          "Только перед подачей в резервуар жидкого аммиака",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20101098",
        text: "Каким образом разрешается размещать штуцера на резервуарах для хранения жидкого аммиака?",
        answers: [
          "Штуцера для выдачи жидкого аммиака разрешается размещать в нижней части резервуаров, штуцера для выдачи дренажа - в верхней части резервуаров",
          "Штуцера для дренажа разрешается размещать в нижней части резервуаров, остальные штуцера - в верхней части резервуаров",
          "Штуцера для контрольно-измерительных приборов и автоматизации разрешается размещать в нижней части резервуаров, остальные штуцера - в верхней части резервуаров",
          "Штуцера для выдачи жидкого аммиака, дренажа, промывки и контрольно-измерительных приборов и автоматизации разрешается размещать в нижней части резервуаров, остальные штуцера - в верхней части резервуаров",
        ],
        correctAnswers: [
          "Штуцера для выдачи жидкого аммиака, дренажа, промывки и контрольно-измерительных приборов и автоматизации разрешается размещать в нижней части резервуаров, остальные штуцера - в верхней части резервуаров",
        ],
      },
      {
        code: "20101099",
        text: "Какой величины должно приниматься расчетное давление при проектировании резервуаров для хранения жидкого аммиака?",
        answers: [
          "Больше рабочего на 15 %, но не менее чем на 98,06 Па (10 мм вод. ст.)",
          "Больше рабочего на 25 %, но не менее чем на 98,06 Па (10 мм вод. ст.)",
          "Больше рабочего на 20 %, но не менее чем на 98,06 Па (10 мм вод. ст.)",
          "Больше рабочего на 10 %, но не менее чем на 98,06 Па (10 мм вод. ст.)",
        ],
        correctAnswers: [
          "Больше рабочего на 25 %, но не менее чем на 98,06 Па (10 мм вод. ст.)",
        ],
      },
      {
        code: "20101100",
        text: "Как осуществляется охлаждение изотермического резервуара с аммиаком?",
        answers: [
          "Установкой разбрызгивающего устройства, расположенного выше допустимого уровня аммиака",
          "Подачей охлаждающей жидкости в змеевик, расположенный в нижней части резервуара",
          "За счет возврата в резервуар под слой сжиженных на холодильной установке отводимых из резервуара паров аммиака",
          "Подачей охлаждающей жидкости в рубашку резервуара",
        ],
        correctAnswers: [
          "Установкой разбрызгивающего устройства, расположенного выше допустимого уровня аммиака",
        ],
      },
      {
        code: "20101101",
        text: "Какое из перечисленных требований к арматурам и трубопроводам жидкого аммиака указано верно?",
        answers: [
          "Защитные устройства следует устанавливать после запорной арматуры на трубопроводе подачи аммиака",
          "Резервуары для хранения жидкого аммиака следует отключать от трубопроводов 1 запорным органом",
          "Арматура, расположенная непосредственно у шаровых, изотермических и горизонтальных резервуаров вместимостью 50 т и более, должна иметь дистанционное и ручное управление",
          "Все перечисленные требования указаны верно",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20101102",
        text: "Какая арматура применяется на трубопроводах жидкого и газообразного аммиака?",
        answers: [
          "Стальная арматура и фасонные части",
          "Стальная арматура и фасонные части, а также арматура и фитинги с деталями из меди, цинка и их сплавов",
          "Стальная арматура и фасонные части, а также запорная арматура из ковкого и высокопрочного чугуна",
          "Стальная арматура и фасонные части, а также чугунная запорно-регулирующая арматура",
        ],
        correctAnswers: ["Стальная арматура и фасонные части"],
      },
      {
        code: "20101103",
        text: "Какие требования к предохранительным клапанам, устанавливаемым на резервуары жидкого аммиака, указаны неверно?",
        answers: [
          "Параллельно с рабочими предохранительными клапанами необходимо установить резервные предохранительные клапаны",
          "Применение рычажно-грузовых предохранительных клапанов не допускается",
          "На наружных оболочках изотермических резервуаров с засыпной изоляцией разрешается не устанавливать предохранительные клапаны, если такие клапаны имеются на буферном сосуде (газгольдере) азота или на трубопроводе, который соединяет наружную оболочку с буферным сосудом",
          "Не допускается изготовление предохранительных и вакуумных клапанов для изотермических резервуаров из алюминиевых сплавов",
        ],
        correctAnswers: [
          "Не допускается изготовление предохранительных и вакуумных клапанов для изотермических резервуаров из алюминиевых сплавов",
        ],
      },
      {
        code: "20101104",
        text: "В каком случае допускается применение резиновых и резинометаллических рукавов для слива (налива) цистерн жидкого аммиака?",
        answers: [
          "Если рукава для слива (налива) рассчитаны на рабочее давление не менее 1 МПа",
          "Если рукава с внутренним диаметром 50 мм с текстильным каркасом",
          "Если рукава для слива (налива) рассчитаны на рабочее давление не менее 2 МПа",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "Если рукава для слива (налива) рассчитаны на рабочее давление не менее 2 МПа",
        ],
      },
      {
        code: "20101105",
        text: "С какой периодичностью проводится ревизия и ремонт предохранительных клапанов резервуаров жидкого аммиака со снятием их с мест установки, проверкой и настройкой на стенде?",
        answers: [
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 2 года",
          "В сроки, установленные технологическим регламентом и эксплуатационной документацией",
          "Не реже 1 раза в год",
        ],
        correctAnswers: [
          "В сроки, установленные технологическим регламентом и эксплуатационной документацией",
        ],
      },
      {
        code: "20101106",
        text: "Какие требования к тепловой изоляции резервуаров жидкого аммиака указаны неверно?",
        answers: [
          "Тепловая изоляция должна выполняться из негорючих или трудносгораемых материалов",
          "Для изоляции внутренних стен и крыши изотермических резервуаров рекомендуется применять с влажностью не более 0,8 % мас. вспученный перлитовый песок стандартного гранулометрического состава",
          "При применении в качестве наружной изоляции материалов, подверженных горению, обеспечиваются меры, исключающие возможность воспламенения изоляции (орошение, защита негорючими покрытиями и т. п.), согласованные в установленном порядке",
          "Не допускается увлажнение теплоизоляционных материалов и устройств в процессе их хранения, транспортировки и монтажа",
        ],
        correctAnswers: [
          "При применении в качестве наружной изоляции материалов, подверженных горению, обеспечиваются меры, исключающие возможность воспламенения изоляции (орошение, защита негорючими покрытиями и т. п.), согласованные в установленном порядке",
        ],
      },
      {
        code: "20101107",
        text: "При каких погодных условиях производится монтаж изоляции изотермических резервуаров жидкого аммиака?",
        answers: [
          "Только в теплое время года",
          "Только при температуре не ниже предусмотренной техническими условиями",
          "Только при отсутствии атмосферных осадков",
          "При соблюдении всех перечисленных погодных условий",
        ],
        correctAnswers: ["При соблюдении всех перечисленных погодных условий"],
      },
      {
        code: "20101108",
        text: "Какое из перечисленных требований во избежание попадания водяных паров из окружающего воздуха в теплоизоляционный слой изотермических резервуаров жидкого аммиака с засыпной изоляцией указано верно?",
        answers: [
          "Межстенное пространство должно быть постоянно заполнено аргоном",
          "Избыточное давление в межстенном пространстве должно определяться проектной документацией",
          "Межстенное пространство должно быть постоянно заполнено осушенным азотом с точкой росы -30 °C",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20101109",
        text: "С какой периодичностью проводится термографирование наружной поверхности резервуара жидкого аммиака в целях выявления участков с нарушенной теплоизоляцией?",
        answers: [
          "Один раз в квартал",
          "Один раз в полугодие",
          "Один раз в год",
          "Один раз в неделю",
        ],
        correctAnswers: ["Один раз в год"],
      },
      {
        code: "20101110",
        text: "Какая максимальная объемная доля аммиака в межстенном пространстве резервуара во время эксплуатации?",
        answers: ["0,8 %", "0,5 %", "1 %", "1,5 %"],
        correctAnswers: ["0,5 %"],
      },
      {
        code: "20101111",
        text: "Какие действия должны быть предприняты при обнаружении нарушений требований Правил безопасности химически опасных производственных объектов в отношении цистерн с жидким аммиаком?",
        answers: [
          "Должен быть составлен акт и необходимо сообщить об этом организации-наполнителю",
          "Необходимо слить аммиак из цистерны",
          "Необходимо составить акт, слить аммиак из цистерны и сообщить в Ростехнадзор",
          "Необходимо поставить круглосуточное наблюдение за цистернами до устранения нарушений",
        ],
        correctAnswers: [
          "Должен быть составлен акт и необходимо сообщить об этом организации-наполнителю",
        ],
      },
      {
        code: "20101112",
        text: "Кто устанавливает сроки проведения ревизии трубопроводов, запорной арматуры и предохранительных клапанов для неорганических жидких кислот и (или) щелочей в зависимости от скорости коррозионно-эрозионного износа?",
        answers: [
          "Организация, обслуживающая трубопровод",
          "Предприятие - владелец трубопровода",
          "Ростехнадзор",
          "Организация-изготовитель",
        ],
        correctAnswers: ["Предприятие - владелец трубопровода"],
      },
      {
        code: "20101113",
        text: "Какие материалы допускается перемещать на ленточных транспортерах?",
        answers: [
          "Только хромосодержащие пигменты",
          "Только высокотоксичные материалы в сухом виде",
          "Только высокотоксичные материалы в пастообразном виде",
          "Все перечисленные материалы",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20101114",
        text: "Какое из перечисленных требований к образованию, хранению, транспортированию, уничтожению лаков и красок указано неверно?",
        answers: [
          "Приводы аппаратов, расположенных в помещениях, где возможно скапливание пыли, следует выполнять на одном валу с электродвигателем",
          "Высокие опоры вращающихся печей и сушилок должны быть снабжены обслуживающими площадками, расположенными на расстоянии не более 300 мм от верха опоры",
          "Хранение суховальцованных паст в цехе допускается только для подколеровки эмалей, но в ограниченном количестве не более 2 % сменной потребности",
          "В отдельных случаях допускается отвод продуктов сгорания в один боров от агрегатов, работающих на разных видах топлива",
        ],
        correctAnswers: [
          "В отдельных случаях допускается отвод продуктов сгорания в один боров от агрегатов, работающих на разных видах топлива",
        ],
      },
      {
        code: "20101115",
        text: "Какое из перечисленных требований к феррофосфорам указано верно?",
        answers: [
          "Под феррофосфорными летками в перерывах между выпусками феррофосфора должен быть установлен ковш",
          "После уборки феррофосфора из приямков допускается незначительное наличие в них влаги",
          "Состояние футеровки (в том числе температура) печи и околошлаковых и феррофосфорных леток необходимо контролировать 1 раз в смену",
          "В аварийных случаях феррофосфор допускается сливать в аварийные приямки или в аварийные емкости, где по истечении 3 часов после слива его необходимо охлаждать водой",
        ],
        correctAnswers: [
          "Под феррофосфорными летками в перерывах между выпусками феррофосфора должен быть установлен ковш",
        ],
      },
      {
        code: "20101116",
        text: "Что должна обеспечивать система электрического управления механизмами поточно-транспортных систем при производстве фосфора и его соединений?",
        answers: [
          "Только электрическую блокировку всех механизмов от завала транспортируемых веществ с применением реле скорости для элеваторов и транспортеров",
          "Только предотвращение пуска механизмов при проведении ремонтных и профилактических работ на оборудовании",
          "Только аварийное отключение транспортеров с помощью троса, соединенного с выключателем",
          "Только предпусковую звуковую сигнализацию",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20101117",
        text: "В каком месте может размещаться оборудование, работающее на аммиаке?",
        answers: [
          "Только в специальном помещении - машинном или аппаратном отделении",
          "Только в помещении потребителей холода",
          "Только на открытой площадке",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20101118",
        text: "Какое из перечисленных требований к поддонам (приямкам) для сбора жидкого аммиака в случае разгерметизации сосуда указано верно? Укажите все правильные ответы.",
        answers: [
          "Приямки должны иметь не менее двух лестниц, а при глубине приямка более 2 м - выход непосредственно наружу",
          "Количество пролитого аммиака из защитного ресивера должно определяться по рабочему заполнению сосуда",
          "Глубина приямка должна быть не более 3 м",
          "Расчетный уровень жидкого аммиака в случае аварийного вытекания хладагента из наиболее аммиакоемкого сосуда в поддон (приямок) должен быть ниже бортика поддона (края приямка)",
          "Количество пролитого аммиака из циркуляционного ресивера должно определяться по максимально допустимому заполнению сосуда",
        ],
        correctAnswers: [
          "Приямки должны иметь не менее двух лестниц, а при глубине приямка более 2 м - выход непосредственно наружу",
          "Расчетный уровень жидкого аммиака в случае аварийного вытекания хладагента из наиболее аммиакоемкого сосуда в поддон (приямок) должен быть ниже бортика поддона (края приямка)",
        ],
      },
      {
        code: "20101119",
        text: "Для каких производств управление системами подачи флегматизирующих веществ на объектах, связанных с производством растительных масел, осуществляется дистанционно?",
        answers: [
          "Для производств с блоками I категории",
          "Для производств с блоками II категории",
          "Для производств с блоками III категории",
          "Для производств при относительных энергетических потенциалах Qв &lt; 10",
        ],
        correctAnswers: ["Для производств с блоками III категории"],
      },
      {
        code: "20101120",
        text: "На каком расстоянии от опор и подвесок следует располагать сварные стыки трубопроводов для труб диаметром менее 50 мм?",
        answers: [
          "Не менее 250 мм",
          "Не менее 200 мм",
          "Не менее 150 мм",
          "Не менее 100 мм",
        ],
        correctAnswers: ["Не менее 100 мм"],
      },
      {
        code: "20101121",
        text: "Какой документ необходимо оформлять при выполнении монтажных работ в помещении и на участках действующей холодильной системы, а также в условиях недействующих узлов, находящихся под аммиаком или не отсоединенных от остальной части системы?",
        answers: [
          "Перечень требуемых работ, утвержденный главным инженером организации",
          "Распоряжение руководителя структурного подразделения на производство монтажных работ",
          "Оформление разрешающего документа на проведение монтажных работ не требуется",
          "Наряд-допуск на указанные работы",
        ],
        correctAnswers: ["Наряд-допуск на указанные работы"],
      },
      {
        code: "20101122",
        text: "В случае какой остановки сосуды, аппараты и трубопроводы холодильных установок должны подвергаться техническому освидетельствованию?",
        answers: [
          "В случае остановки продолжительностью более 3 месяцев",
          "В случае остановки продолжительностью более 1 месяца",
          "Продолжительность остановки не влияет на необходимость проведения технического освидетельствования",
          "В случае остановки продолжительностью более 6 месяцев",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20101123",
        text: "Какая минимальная продолжительность испытаний на плотность всей системы сосудов, аппаратов и трубопроводов?",
        answers: ["8 часов", "12 часов", "10 часов", "5 часов"],
        correctAnswers: ["12 часов"],
      },
      {
        code: "20101124",
        text: "С какой периодичностью проводится только наружный осмотр при техническом освидетельствовании трубопроводов?",
        answers: [
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 5 лет",
          "Не реже 1 раза в 8 лет",
        ],
        correctAnswers: ["Не реже 1 раза в 2 года"],
      },
      {
        code: "20101125",
        text: "С какой периодичностью проводятся наружный осмотр и испытание пробным давлением при техническом освидетельствовании трубопроводов? Укажите все правильные ответы.",
        answers: [
          "Не реже 1 раза в 2 года",
          "По окончании монтажных работ перед пуском в эксплуатацию",
          "Не реже 1 раза в 8 лет",
          "Не реже 1 раза в 5 лет",
        ],
        correctAnswers: [
          "По окончании монтажных работ перед пуском в эксплуатацию",
          "Не реже 1 раза в 8 лет",
        ],
      },
      {
        code: "20101126",
        text: "В течение какого минимального отрезка времени сосуд (аппарат), трубопровод должен находиться под пробным давлением, после чего давление постепенно должно быть снижено до расчетного, при котором проводится осмотр наружной поверхности сосуда (аппарата, трубопровода) с проверкой плотности его швов и разъемных соединений мыльным раствором или другим способом?",
        answers: [
          "В течение 5 минут",
          "В течение 10 минут",
          "В течение 15 минут",
          "Не регламентируется",
        ],
        correctAnswers: ["В течение 15 минут"],
      },
      {
        code: "20101127",
        text: "В течение какого времени и при каком давлении следует проводить вакуумирование холодильной установки перед пуском в эксплуатацию после пневматических испытаний?",
        answers: [
          "В течение 8 часов при остаточном давлении 0,05 МПа (0,5 кгс/см²)",
          "В течение 12 часов при остаточном давлении 0,015 МПа (0,15 кгс/см²)",
          "В течение 15 часов при остаточном давлении 0,03 МПа (0,3 кгс/см²)",
          "В течение 18 часов при остаточном давлении 0,01 МПа (0,1 кгс/см²)",
        ],
        correctAnswers: [
          "В течение 18 часов при остаточном давлении 0,01 МПа (0,1 кгс/см²)",
        ],
      },
      {
        code: "20101128",
        text: "Какое из перечисленных требований к испытанию на прочность после монтажа до пуска в эксплуатацию сосуда (аппарата), трубопровода (его участка) указано верно?",
        answers: [
          "Использование запорной арматуры для отключения испытываемого сосуда (аппарата) и трубопровода допускается в присутствии лица, ответственного за исправное состояние и безопасную работу сосудов (аппаратов)",
          "При испытании на прочность после монтажа до пуска в эксплуатацию испытываемый сосуд (аппарат), трубопровод (его участок) должны быть отсоединены от других сосудов, аппаратов и других трубопроводов с использованием металлических заглушек с прокладками, имеющих хвостовики, выступающие за пределы фланцев не менее 20 мм",
          "Толщина заглушки должна быть рассчитана на условия работы при давлении выше пробного в 2,5 раза",
          "При периодическом освидетельствовании и техническом диагностировании с использованием метода АЭ и аммиака в качестве нагружающей среды не допускается испытывать отдельные технологические линии блоком",
        ],
        correctAnswers: [
          "При испытании на прочность после монтажа до пуска в эксплуатацию испытываемый сосуд (аппарат), трубопровод (его участок) должны быть отсоединены от других сосудов, аппаратов и других трубопроводов с использованием металлических заглушек с прокладками, имеющих хвостовики, выступающие за пределы фланцев не менее 20 мм",
        ],
      },
      {
        code: "20101129",
        text: "Какое из перечисленных требований к манометрам, контролирующим давление при испытании на прочность сосудов (аппаратов), трубопроводов, указано неверно?",
        answers: [
          "Один манометр должен устанавливаться у источника давления (воздушный компрессор, баллон с инертным газом), другой - на сосуде (аппарате) и трубопроводе в точке, наиболее удаленной от воздушного компрессора",
          "Давление при испытании должно контролироваться двумя манометрами, опломбированными и прошедшими поверку",
          "Манометры должны быть одинакового класса точности, не ниже 2,5",
          "Манометры должны быть с диаметром корпуса не менее 120 мм и шкалой на номинальное давление, равное 4/3 от измеряемого давления",
        ],
        correctAnswers: [
          "Манометры должны быть одинакового класса точности, не ниже 2,5",
        ],
      },
      {
        code: "20101130",
        text: "Кем должна определяться готовность холодильной системы к заполнению хладагентом после завершения монтажных работ и проведения испытаний на прочность и плотность?",
        answers: [
          "Комиссией под председательством представителя организации-изготовителя",
          "Главным инженером эксплуатирующей организацией",
          "Комиссией эксплуатирующей организации",
          "Комиссией по председательством представителя Ростехнадзора",
        ],
        correctAnswers: ["Комиссией эксплуатирующей организации"],
      },
      {
        code: "20101131",
        text: "Какой вид временных соединений может применяться для стыковки железнодорожной цистерны к стационарным узлам холодильной установки?",
        answers: [
          "Шарнирные поворотные соединения",
          "Гибкие рукава из неметаллических материалов",
          "Гибкие металлические рукава",
          "Все перечисленные виды соединений",
        ],
        correctAnswers: ["Все перечисленные виды соединений"],
      },
      {
        code: "20101132",
        text: "Что из перечисленного не допускается при эксплуатации систем холодоснабжения? Укажите все правильные ответы.",
        answers: [
          "Установка вспрыскивающих устройств, предусмотренных инструкцией организации-изготовителя",
          "Впрыск жидкого аммиака во всасывающий трубопровод (полость) поршневого компрессора",
          "Использование переносных приборов в местах регулярного контроля работы аммиачной холодильной установки",
          "Эксплуатация винтовых компрессоров с впрыском жидкого аммиака, если это предусмотрено инструкцией организации-изготовителя",
        ],
        correctAnswers: [
          "Впрыск жидкого аммиака во всасывающий трубопровод (полость) поршневого компрессора",
          "Использование переносных приборов в местах регулярного контроля работы аммиачной холодильной установки",
        ],
      },
      {
        code: "20101133",
        text: "В каком случае допускается запускать аммиачный насос?",
        answers: [
          "Только если клапаны на его входе и выходе закрыты",
          "Только если насос не до конца заполнен жидким хладагентом",
          "Только если отсутствует защитный кожух муфты",
          "Во всех перечисленных случаях",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20101134",
        text: "В каком случае не допускается применение гибких резиновых или пластмассовых шлангов?",
        answers: [
          "Для проведения операций слива аммиака (при заполнении системы) из цистерны",
          "Для выполнения вспомогательных операций (освобождение трубопроводов, аппаратов, фильтров от остатков аммиака, масла)",
          "В качестве стационарных трубопроводов для отсоса паров или подачи жидкого аммиака",
          "Для проведения операций налива аммиака в нестандартное оборудование",
        ],
        correctAnswers: [
          "В качестве стационарных трубопроводов для отсоса паров или подачи жидкого аммиака",
        ],
      },
      {
        code: "20101135",
        text: "На какие наземные склады жидкого аммиака не распространяются Правила безопасности химически опасных производственных объектов?",
        answers: [
          "На склады аммиака в баллонах",
          "На заводские склады, расположенные на территории организаций, производящих аммиак",
          "На перевалочные склады, расположенные на припортовых заводах или базах водного транспорта",
          "На глубинные сельскохозяйственные склады, размещаемые на территории сельскохозяйственного района",
        ],
        correctAnswers: ["На склады аммиака в баллонах"],
      },
      {
        code: "20101136",
        text: "В течение какого срока службы устройства резервуаров для хранения аммиака должны обеспечивать надежную и безопасную эксплуатацию?",
        answers: [
          "В течение 10 лет",
          "В течение 15 лет",
          "В течение 20 лет",
          "В течение срока службы, указанного в паспорте организации-изготовителя",
        ],
        correctAnswers: [
          "В течение срока службы, указанного в паспорте организации-изготовителя",
        ],
      },
      {
        code: "20101137",
        text: "Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития?",
        answers: [
          "Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы",
          "Запорную арматуру, средства защиты от превышения давления, огнепреградители",
          "Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления",
          "Запорную арматуру, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов",
        ],
        correctAnswers: [
          "Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления",
        ],
      },
      {
        code: "20101138",
        text: "Какое минимальное количество датчиков должно устанавливаться на химически опасных производственных объектах I и II классов опасности для осуществления контроля за текущими показателями параметров, определяющими химическую опасность технологических процессов ХОПО?",
        answers: [
          "Не менее 2 независимых датчиков с раздельными точками отбора",
          "Правилами не регламентируется",
          "Не более 2 независимых датчиков с раздельными точками отбора",
          "Не менее 5 независимых датчиков с раздельными точками отбора",
        ],
        correctAnswers: [
          "Не менее 2 независимых датчиков с раздельными точками отбора",
        ],
      },
      {
        code: "20101139",
        text: "Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?",
        answers: [
          "Средствами автоматического управления объектом по действующим программам",
          "Обслуживающим персоналом по инструкции",
          "Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений",
          "Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию системы противоаварийной защиты",
        ],
        correctAnswers: ["Обслуживающим персоналом по инструкции"],
      },
      {
        code: "20101140",
        text: "Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте на производство продукции и инструкциях",
          "Средства автоматики должны быть обозначены на мнемосхемах",
          "Средства автоматики должны быть обозначены только в технологическом регламенте на производство продукции",
          "Требования к обозначению определяются при разработке плана мероприятий по локализации и ликвидации последствий аварий",
        ],
        correctAnswers: [
          "Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте на производство продукции и инструкциях",
        ],
      },
      {
        code: "20101141",
        text: "Каким образом обеспечивается надежность обеспечения средств управления и системы противоаварийной защиты сжатым воздухом?",
        answers: [
          "Установкой резервного компрессора с автоматическим включением при остановке рабочего",
          "Переключением сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования на заводскую сеть сжатого воздуха через осушитель",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
        correctAnswers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20101142",
        text: "По какой категории надежности должно осуществляться электроснабжение химически опасных производственных объектов?",
        answers: [
          "По I или II категории надежности",
          "Только по I категории надежности",
          "По II или III категории надежности",
          "Допустимая категория надежности определяется проектом в зависимости от применяемой технологии",
        ],
        correctAnswers: ["По I или II категории надежности"],
      },
      {
        code: "20101143",
        text: "Что должно быть учтено в системах управления и защит электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?",
        answers: [
          "Наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения",
          "Линии электроснабжения не должны оборудоваться устройствами автоматической частотной разгрузки",
          "Должна быть обеспечена селективность защит на устройствах электроснабжающей и электропотребляющей организаций",
          "Должна быть обеспечена возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой",
        ],
        correctAnswers: [
          "Линии электроснабжения не должны оборудоваться устройствами автоматической частотной разгрузки",
        ],
      },
      {
        code: "20101144",
        text: "Какие требования к системам противоаварийной защиты, обеспечивающим защиту резервуаров жидкого аммиака от переполнения, указаны неверно?",
        answers: [
          "Для резервуаров вместимостью до 10 м³ (включительно) защита обеспечивается дублированием систем контроля параметров",
          "Для резервуаров вместимостью до 25 м³ защита обеспечивается дублированием систем контроля и сопоставлением технологически связанных параметров",
          "Для резервуаров вместимостью до 50 м³ защита обеспечивается дублированием систем контроля и наличием систем самодиагностики с индикацией исправного состояния",
          "Для резервуаров вместимостью 30 м³ и более защита обеспечивается дублированием систем контроля, наличием систем самодиагностики и сопоставлением технологически связанных параметров",
        ],
        correctAnswers: [
          "Для резервуаров вместимостью до 25 м³ защита обеспечивается дублированием систем контроля и сопоставлением технологически связанных параметров",
        ],
      },
      {
        code: "20101145",
        text: "Какие требования предъявляются к структуре системы контроля утечек аммиака из резервуаров жидкого аммиака?",
        answers: [
          "Структура системы контроля утечек аммиака должна быть двухконтурной и двухуровневой",
          "Структура системы контроля утечек аммиака не должна быть двухконтурной",
          "Структура системы контроля утечек аммиака не должна быть двухуровневой",
          "Структура системы контроля утечек аммиака должна быть трехконтурной и двухуровневой",
        ],
        correctAnswers: [
          "Структура системы контроля утечек аммиака должна быть двухконтурной и двухуровневой",
        ],
      },
      {
        code: "20101146",
        text: "При каких параметрах экстракционной установки остановка цеха не требуется?",
        answers: [
          "При падении давления и температуры пара на коллекторах",
          "При падении давления воды в циркуляционной системе",
          "При падении давления сжатого воздуха ниже 0,2 МПа (2 кгс/см²) для питания систем контроля и управления системы противоаварийной защиты",
          "При повышении концентрации паров растворителя в воздушной среде цеха до 20 % от нижнего концентрационного предела распространения пламени",
        ],
        correctAnswers: [
          "При повышении концентрации паров растворителя в воздушной среде цеха до 20 % от нижнего концентрационного предела распространения пламени",
        ],
      },
      {
        code: "20101147",
        text: "Какова предельно допустимая величина концентрации взрывоопасной парогазовой фазы сигнализации средств автоматического газового анализа в производственных помещениях на открытых наружных установках?",
        answers: [
          "Не более 30 % от нижнего концентрационного предела распространения пламени",
          "Не более 40 % от нижнего концентрационного предела распространения пламени",
          "Не более 20 % от нижнего концентрационного предела распространения пламени",
          "Не более 25 % от нижнего концентрационного предела распространения пламени",
        ],
        correctAnswers: [
          "Не более 20 % от нижнего концентрационного предела распространения пламени",
        ],
      },
      {
        code: "20101148",
        text: "По какой категории надежности должно осуществляться электроснабжение электроприемников объектов производств масел?",
        answers: [
          "Электроснабжение должно осуществляться только по 1 категории надежности",
          "Для блоков II, III категорий взрывопожароопасности должно предусматриваться электроснабжение не ниже 2 категории надежности, а электроприемников систем оборотного водоснабжения, аварийной вентиляции, аварийного освещения, обеспечения контрольно-измерительных приборов и автоматики сжатым воздухом, систем противопожарной защиты - не ниже 1 категории",
          "Электроснабжение должно осуществляться по категории надежности не ниже 2",
        ],
        correctAnswers: [
          "Для блоков II, III категорий взрывопожароопасности должно предусматриваться электроснабжение не ниже 2 категории надежности, а электроприемников систем оборотного водоснабжения, аварийной вентиляции, аварийного освещения, обеспечения контрольно-измерительных приборов и автоматики сжатым воздухом, систем противопожарной защиты - не ниже 1 категории",
        ],
      },
      {
        code: "20101149",
        text: "Какие устройства применяются в качестве предохранительных на аммиачных холодильных установках?",
        answers: [
          "Только рычажно-грузовые предохранительные клапаны",
          "Только пружинные предохранительные клапаны и мембранные предохранительные устройства",
          "Только предохранительные клапаны прямого действия",
          "Только рычажно-грузовые предохранительные клапаны, совмещенные с предохранительными мембранами",
          "Любые из перечисленных",
        ],
        correctAnswers: [
          "Только пружинные предохранительные клапаны и мембранные предохранительные устройства",
        ],
      },
      {
        code: "20101150",
        text: "Какая должна быть степень защиты электроприборов и средств автоматического и дистанционного управления, располагающихся в помещениях с аммиачным оборудованием?",
        answers: [
          "Не ниже IP44",
          "Не ниже IP54",
          "Не ниже IP20",
          "Не ниже IP65",
        ],
        correctAnswers: ["Не ниже IP44"],
      },
      {
        code: "20101151",
        text: "Каким образом определяется выбор метода измерения (объемный, весовой) жидкого аммиака?",
        answers: [
          "Указаниями Ростехнадзора",
          "Внутренней эксплуатационной документацией",
          "Правилами безопасности складов жидкого аммиака",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20101152",
        text: "Какие требования к системам автоматического контроля и управления технологическими процессами объектов производств растительных масел указаны неверно?",
        answers: [
          "Должны обеспечивать сигнализацию и регистрацию в реальном времени срабатывания средств системы противоаварийной защиты",
          "Должны обеспечивать постоянную связь оператора установки с механиком цеха",
          "Должны обеспечивать управление безаварийным пуском, остановкой и всеми необходимыми для этого переключениями",
          "Должны обеспечивать постоянный контроль, регистрацию отклонений и сигнализацию состояния воздушной среды в пределах объекта",
        ],
        correctAnswers: [
          "Должны обеспечивать постоянную связь оператора установки с механиком цеха",
        ],
      },
      {
        code: "20101153",
        text: "Какое требование к первичному пуску компрессоров в работу после длительной остановки, ремонта, профилактики, а также после остановки компрессора при срабатывании приборов предаварийной защиты указано верно?",
        answers: [
          "Первичный пуск компрессора в работу необходимо выполнять вручную с закрытыми всасывающими клапанами в соответствии с инструкцией организации-изготовителя",
          "Перед пуском компрессора в работу следует убедиться, что все запорные клапаны на нагнетательном трубопроводе от компрессора до конденсатора открыты",
          "При пуске компрессора с использованием встроенного байпаса нагнетательный вентиль компрессора должен быть закрыт, а клапан байпаса открыт, если это предусмотрено инструкцией организации-изготовителя",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20101154",
        text: "Какие параметры экстракционной установки, требующие остановки цеха, указаны верно?",
        answers: [
          "Падение давления сжатого воздуха для питания систем контроля и управления системы ПАЗ ниже 0,4 МПа (4 кгс/см²)",
          "Падение давления и температуры пара на коллекторах",
          "Повышение концентрации паров растворителя в воздушной среде цеха до 30 % от нижнего концентрационного предела распространения пламени",
          "Повышение давления воды в циркуляционной системе на 10 %",
        ],
        correctAnswers: ["Падение давления и температуры пара на коллекторах"],
      },
      {
        code: "20101155",
        text: "О чем сигнализирует красная лампа световой сигнализации?",
        answers: [
          "Сигнал об аварийной остановке аппарата",
          "Сигнал об опасном понижении верхнего уровня жидкого аммиака в сосуде (предаварийная сигнализация)",
          "Сигнал о предельно допустимом уровне жидкого аммиака в сосуде (предаварийная сигнализация)",
          "Сигнал об опасном повышении верхнего уровня жидкого аммиака в сосуде (предупредительная сигнализация)",
        ],
        correctAnswers: [
          "Сигнал о предельно допустимом уровне жидкого аммиака в сосуде (предаварийная сигнализация)",
        ],
      },
      {
        code: "20101156",
        text: "С какой периодичностью необходимо проверять исправность автоматических приборов защиты аммиачных компрессоров и сигнализаторов концентрации паров аммиака в воздухе помещений и наружных площадок?",
        answers: [
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в год",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20101157",
        text: "Какое из перечисленных требований к выполнению управляющих функций систем ПАЗ указано неверно?",
        answers: [
          "Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы",
          "Команды управления, сформированные алгоритмами защит (блокировок), должны иметь приоритет по отношению к любым другим командам управления технологическим оборудованием, в том числе к командам, формируемым оперативным персоналом АСУТП, если иное не оговорено в техническом задании на создание команды",
          "В алгоритмах срабатывания защит следует предусматривать возможность включения блокировки команд управления оборудованием, технологически связанным с аппаратом, агрегатом или иным оборудованием, вызвавшим такое срабатывание",
          "Системы ПАЗ должны реализовываться на принципах приоритетности защиты технологических процессов комплектно, с одновременной защитой отдельных единиц оборудования",
        ],
        correctAnswers: [
          "Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы",
        ],
      },
      {
        code: "20101158",
        text: "Каким из перечисленных способом не допускается прокладка кабелей по территории предприятий и установок?",
        answers: [
          "Только в галереях",
          "Только на кабельных конструкциях технологических эстакад",
          "Только в траншеях и каналах, засыпанных песком",
          "Допускаются все перечисленные способы прокладки",
        ],
        correctAnswers: ["Допускаются все перечисленные способы прокладки"],
      },
      {
        code: "20101159",
        text: "По указанию какого лица должны производиться снятие предохранительных клапанов на проверку, их установка и пломбирование?",
        answers: [
          "Лица, ответственного за исправное состояние и безопасную работу сосудов (аппаратов)",
          "Лица, ответственного за проведение ремонтных работ компрессорных агрегатов",
          "Лица, ответственного за обслуживание компрессорных агрегатов",
          "Начальника производства",
        ],
        correctAnswers: [
          "Лица, ответственного за исправное состояние и безопасную работу сосудов (аппаратов)",
        ],
      },
      {
        code: "20101160",
        text: "С какой периодичностью необходимо проверять исправность защитных реле уровня на аппаратах (сосудах)?",
        answers: [
          "1 раз в месяц",
          "1 раз в 3 месяца",
          "1 раз в 6 месяцев",
          "1 раз в год",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20101161",
        text: "Допускается ли применение мерных стекол на резервуарах для хранения жидкого аммиака?",
        answers: [
          "Допускается",
          "Не допускается",
          "Допускается, если применение обосновано проектом",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "20101162",
        text: "Для каких блоков объектов производств растительных масел допускается применение автоматических средств контроля параметров, значения которых определяют взрывоопасность процесса, и ручного регулирования?",
        answers: [
          "Для блоков, имеющих относительный энергетический потенциал Qв меньше 10",
          "Для блоков I категории",
          "Для блоков II категории",
          "Для блоков III категории",
        ],
        correctAnswers: [
          "Для блоков, имеющих относительный энергетический потенциал Qв меньше 10",
        ],
      },
      {
        code: "20101163",
        text: "Какие из функций, которые должна обеспечивать автоматизированная система управления технологическими процессами объектов производств растительных масел, указаны верно? Укажите все правильные ответы.",
        answers: [
          "Постоянный анализ изменения параметров в сторону критических значений и прогнозирование возможной аварии",
          "Регистрация срабатывания и контроль за работоспособным состоянием средств противоаварийной защиты",
          "Выдача информации о состоянии безопасности на объекте в Ростехнадзор",
          "Связь оператора установки с начальником цеха и главным инженером организации",
        ],
        correctAnswers: [
          "Постоянный анализ изменения параметров в сторону критических значений и прогнозирование возможной аварии",
          "Регистрация срабатывания и контроль за работоспособным состоянием средств противоаварийной защиты",
        ],
      },
      {
        code: "20101164",
        text: "Какими устройствами не должны оснащаться насосы, применяемые для нагнетания легковоспламеняющихся и горючих жидкостей (масло растительное и минеральное, мисцелла, растворитель)?",
        answers: [
          "Средствами предупредительной сигнализации о нарушении параметров работы, влияющих на безопасность",
          "Устройствами, регулирующими частоту вращения вала",
          'Блокировками, исключающими пуск и работу насоса "всухую" или прекращающими работу насоса при падении давления перемещаемой жидкости в нагнетательном патрубке насоса ниже установленного регламентом или паспортными данными или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений (верхний и нижний уровни)',
          "Средствами местного и дистанционного отключения, расположенными в легкодоступных местах",
        ],
        correctAnswers: ["Устройствами, регулирующими частоту вращения вала"],
      },
      {
        code: "20101165",
        text: "На каких объектах ХОПО технические решения по обеспечению надежности контроля параметров, имеющих критические значения, обосновываются разработчиком документации на ХОПО? Укажите все правильные ответы.",
        answers: [
          "На объектах ХОПО I класса опасности",
          "На объектах ХОПО II класса опасности",
          "На объектах ХОПО III класса опасности",
          "На объектах ХОПО IV класса опасности",
          "На всех объектах ХОПО",
        ],
        correctAnswers: [
          "На объектах ХОПО III класса опасности",
          "На объектах ХОПО IV класса опасности",
        ],
      },
      {
        code: "20101166",
        text: "Какие из перечисленных требований к датчикам и пробоотборным устройствам анализаторов объектов производств растительных масел указаны верно?",
        answers: [
          "Для экстракторов карусельного типа должен устанавливаться датчик на пары растворителя в загрузочном бункере экстрактора с уставками срабатывания 3 г/м³; 3…4 г/м³; 4 г/м³",
          "В случае отсутствия загрузочного бункера места установки, количество датчиков и уставки определяются техническим руководителем организации",
          "Для экстракторов карусельного типа должен устанавливаться датчик на пары растворителя в разгрузочном винтовом конвейере шрота с уставками срабатывания 5 г/м³; 5…10 г/м³; 10 г/м³",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20101167",
        text: "Каким образом обеспечивается противоаварийная защита от превышения уровня аммиака сверх допустимого в резервуарах вместимостью до 50 м³?",
        answers: [
          "Дублированием систем контроля и наличием систем самодиагностики с индикацией исправного состояния",
          "Дублированием систем контроля параметров",
          "Наличием систем самодиагностики",
          "Сопоставлением технологически связанных параметров",
        ],
        correctAnswers: [
          "Дублированием систем контроля и наличием систем самодиагностики с индикацией исправного состояния",
        ],
      },
      {
        code: "20101168",
        text: "В каком случае допускается неавтоматическое включение технических устройств, задействованных в системе локализации аварийных ситуаций на складах жидкого аммиака?",
        answers: [
          "Если это обосновано проектом",
          "Если это установлено внутренним регламентом организации",
          "Если отсутствует угроза распространения химического заражения вне территории объекта",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Если это обосновано проектом"],
      },
      {
        code: "20101169",
        text: "Куда допускается сброс неорганических жидких кислот и (или) щелочей от предохранительных клапанов?",
        answers: [
          "В рабочую зону",
          "В окружающую среду",
          "В специальные емкости",
          "В обоснованных случаях допускается сброс во все перечисленные места",
        ],
        correctAnswers: ["В специальные емкости"],
      },
      {
        code: "20101170",
        text: "Какие материалы применяются для изготовления, монтажа и ремонта технологического оборудования и трубопроводов для производств, использующих неорганические кислоты и щелочи?",
        answers: [
          "Нержавеющая сталь",
          "Полимерные материалы",
          "Материалы, обеспечивающие коррозионную стойкость к рабочей среде",
          "Композитные материалы",
        ],
        correctAnswers: [
          "Материалы, обеспечивающие коррозионную стойкость к рабочей среде",
        ],
      },
      {
        code: "20101171",
        text: "Каким должен быть радиус кривизны отвода при изготовлении отводов способом гиба на специальных станках?",
        answers: [
          "Не менее четырех диаметров трубы",
          "Не менее трех диаметров трубы",
          "Не менее двух диаметров трубы",
          "Соответствовать диаметру трубы",
        ],
        correctAnswers: ["Не менее трех диаметров трубы"],
      },
      {
        code: "20101172",
        text: "Какой ширины предусматривается охранная зона межзаводского трубопровода кислот или щелочей, прокладываемого вне территории предприятия?",
        answers: [
          "Не более 2 м с каждой его стороны",
          "Не менее 2 м с каждой его стороны",
          "Не менее 1 м с каждой его стороны",
          "Не более 1 м с каждой его стороны",
        ],
        correctAnswers: ["Не менее 2 м с каждой его стороны"],
      },
      {
        code: "20101173",
        text: "Где допускается прокладка трубопроводов неорганических жидких кислот и (или) щелочей при условии, что трубопроводы должны быть заключены в специальные желоба или короба (коллекторы) с отводом утечек кислот и щелочей в безопасные места, определяемые проектом?",
        answers: [
          "По наружным стенам зданий, не связанных с обращением кислот и щелочей",
          "Через вспомогательные и подсобные помещения",
          "В местах пересечения железных и автомобильных дорог",
          "Через административные и бытовые помещения",
        ],
        correctAnswers: ["В местах пересечения железных и автомобильных дорог"],
      },
      {
        code: "20101174",
        text: "Каким образом устанавливается минимально допустимое расстояние от складов кислот и щелочей до взрывоопасных объектов?",
        answers: [
          "Расстояние устанавливается в соответствии с требованиями строительных норм и правил",
          "Расстояние устанавливается в соответствии с требованиями норм пожарной безопасности",
          "Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения и должно обеспечивать устойчивость зданий складов к воздействию данных факторов",
          "Расстояние должно быть обусловлено только устойчивостью здания склада к воздействию ударной волны",
        ],
        correctAnswers: [
          "Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения и должно обеспечивать устойчивость зданий складов к воздействию данных факторов",
        ],
      },
      {
        code: "20101175",
        text: "Что необходимо предусматривать на складах, пунктах слива-налива, расположенных на открытых площадках, где в условиях эксплуатации возможно поступление в воздух рабочей зоны паров кислот и щелочей, для обеспечения требований безопасности?",
        answers: [
          "Только автоматический контроль с сигнализацией превышения предельно допустимых концентраций",
          "Только включение светового и звукового сигналов при превышении предельно допустимых концентраций",
          "Только регистрация всех случаев загазованности приборами",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20101176",
        text: "Чему соответствует вместимость поддонов, которыми оснащается емкостное оборудование для использования кислот и (или) щелочей объемом 1000 л и более?",
        answers: [
          "Объему всего расположенного в них оборудования",
          "Объему, достаточному для содержания одного аппарата максимальной емкости в случае его аварийного разрушения",
          "70 % объема всех расположенных в поддоне или на площадке с бортиками аппаратов и емкостей",
          "50 % объема всех расположенных в поддоне или на площадке с бортиками аппаратов и емкостей",
        ],
        correctAnswers: [
          "Объему, достаточному для содержания одного аппарата максимальной емкости в случае его аварийного разрушения",
        ],
      },
      {
        code: "20101177",
        text: "Какие условия должны соблюдаться при перемещении по трубопроводам застывающих продуктов и расплавов, способных кристаллизоваться?",
        answers: [
          'Перемещения должны осуществляться по обогреваемым трубопроводам типа "труба в трубе" или со спутниками-теплоносителями в режиме, исключающем забивку трубопроводов',
          "Перемещения должны осуществляться по охлаждаемым трубопроводам",
          "Перемещения в трубопроводе должны осуществляться в среде соответствующего растворителя",
          "Перемещения по трубопроводам не допускаются для застывающих продуктов и расплавов, способных кристаллизоваться",
        ],
        correctAnswers: [
          'Перемещения должны осуществляться по обогреваемым трубопроводам типа "труба в трубе" или со спутниками-теплоносителями в режиме, исключающем забивку трубопроводов',
        ],
      },
      {
        code: "20101178",
        text: "Как должны быть оборудованы места пересыпки и транспортирования пылящего химически опасного продукта в производстве пигментов?",
        answers: [
          "Места пересыпки и транспортирования должны оборудоваться на открытых площадках, защищенных от ветра",
          "Места пересыпки и транспортирования должны быть герметизированы и снабжены укрытиями, присоединенными к аспирационным вентиляционным установкам",
          "Пересыпка и транспортирование должны осуществляться в закрытых бункерах и конвейерах",
          "Требования к оборудованию мест пересыпки и транспортирования устанавливаются в проектной документации",
        ],
        correctAnswers: [
          "Места пересыпки и транспортирования должны быть герметизированы и снабжены укрытиями, присоединенными к аспирационным вентиляционным установкам",
        ],
      },
      {
        code: "20101179",
        text: "Чем должно быть оснащено оборудование для разделения суспензий и фильтрации?",
        answers: [
          "Манометром, обеспечивающим измерение давления при подаче суспензий",
          "Блокировками, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды",
          "Блокировками, обеспечивающими прекращение подачи суспензий при допустимых отклонениях параметров инертной среды",
          "Укрытиями, обеспечивающими прекращение подачи суспензий при отклонениях параметров инертной среды",
        ],
        correctAnswers: [
          "Блокировками, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды",
        ],
      },
      {
        code: "20101180",
        text: "Какие параметры должны регламентироваться в процессах смешивания при возможности развития самоускоряющихся экзотермических реакций?",
        answers: [
          "Только последовательность и допустимые количества загружаемых в аппаратуру веществ",
          "Только скорость сгрузки (поступления) реагентов",
          "Только подача флегматизирующих агентов",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20101181",
        text: "Какое количество суховальцованных паст для подколеровки эмалей допускается хранить в помещениях цехов, предназначенных для лаков и эмалей на эфирах целлюлозы?",
        answers: [
          "Не менее сменной потребности",
          "Не более 50 % сменной потребности",
          "Не более 2 % сменной потребности",
          "Не менее 10 % сменной потребности",
        ],
        correctAnswers: ["Не более 2 % сменной потребности"],
      },
      {
        code: "20101182",
        text: "Какие требования установлены к насосам для перекачки растворов коллоксилина?",
        answers: [
          "Насосы должны быть тихоходными и соответствовать требованиям токсической безопасности",
          "Насосы должны быть быстроходными и соответствовать требованиям токсической безопасности",
          "Для насосов, расположенных в зданиях, необходимо предусматривать их дистанционное отключение с внешней стороны помещения насосной",
        ],
        correctAnswers: [
          "Насосы должны быть тихоходными и соответствовать требованиям токсической безопасности",
        ],
      },
      {
        code: "20101183",
        text: "На какой высоте должны быть ограждения в местах прохода людей и проезда транспорта под подвесными конвейерами и транспортерами при производстве фосфора и его соединений?",
        answers: [
          "Не более 2,2 м",
          "Не менее 2,2 м",
          "Не более 1,8 м",
          "Не менее 1,8 м",
        ],
        correctAnswers: ["Не менее 2,2 м"],
      },
      {
        code: "20101184",
        text: "При какой температуре поверхности аппаратов, находящихся в помещении, должны быть теплоизолированы несгораемыми материалами при производстве фосфора и его соединений?",
        answers: [
          "25 °C и выше",
          "35 °C и выше",
          "45 °C и выше",
          "Теплоизоляция предусматривается при любой температуре поверхности аппаратов",
        ],
        correctAnswers: ["45 °C и выше"],
      },
      {
        code: "20101185",
        text: "Какие меры безопасности должны соблюдаться при нахождении фосфора и фосфорного шлама в аппаратах?",
        answers: [
          "Аппараты должны быть герметичны",
          "Аппараты должны быть заполнены инертным газом",
          "В аппаратах фосфор и фосфорный шлам должны находиться под слоем воды не менее 300 мм",
          "В аппаратах фосфор и фосфорный шлам должны находиться под слоем воды не менее 100 мм",
        ],
        correctAnswers: [
          "В аппаратах фосфор и фосфорный шлам должны находиться под слоем воды не менее 300 мм",
        ],
      },
      {
        code: "20101186",
        text: "Какие меры безопасности должны соблюдаться при хранении и перекачке фосфора и фосфорного шлама?",
        answers: [
          "Температура фосфора и фосфорного шлама не должна быть более 80 °С",
          "Паропроводы, подводящие острый пар для разогрева фосфора и поддержания его в расплавленном состоянии, должны быть оснащены приборами контроля давления пара",
          'Паропроводы, подводящие острый пар для разогрева фосфора и поддержания его в расплавленном состоянии, должны быть оснащены устройствами ("воздушками") для предотвращения образования вакуума и попадания фосфора в паропровод',
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20101187",
        text: "Какой должна быть вместимость поддона, на который следует устанавливать производственные емкости с фосфором?",
        answers: [
          "Объемом не менее емкости резервуара с фосфором",
          "Объемом не менее вместимости одного наибольшего резервуара и слоя воды не менее 200 мм",
          "Объемом, равным сумме объемов всех производственных емкостей с фосфором",
          "Объемом не менее вместимости одного наибольшего резервуара и слоя воды не более 100 мм",
        ],
        correctAnswers: [
          "Объемом не менее вместимости одного наибольшего резервуара и слоя воды не менее 200 мм",
        ],
      },
      {
        code: "20101188",
        text: "Какое из перечисленных требований к хранению фосфора и его соединений указано верно? Укажите все правильные ответы.",
        answers: [
          "Расстояние в свету между резервуарами должно быть не менее 0,5 диаметра наибольшего резервуара",
          "Резервуары для хранения фосфора следует размещать не более чем в три ряда",
          "Поддоны склада не нуждаются в усиленной гидроизоляции",
          "Поддоны (отсеки) склада следует выполнять с уклонами в сторону приямка для сбора возможных проливов фосфора и воды",
          "Расстояние в свету от крайних резервуаров до стен склада или стенок поддона (отсека) должно быть не менее 2 м",
        ],
        correctAnswers: [
          "Расстояние в свету между резервуарами должно быть не менее 0,5 диаметра наибольшего резервуара",
          "Поддоны (отсеки) склада следует выполнять с уклонами в сторону приямка для сбора возможных проливов фосфора и воды",
        ],
      },
      {
        code: "20101189",
        text: "Каким должен быть остаточный слой материалов при разгрузке приемных бункеров для предотвращения поступления запыленного воздуха в помещение при производстве фосфора и его соединений?",
        answers: [
          "На 1,1 м выше разгрузочного проема",
          "На 0,8 м выше разгрузочного проема",
          "На 0,7 м выше разгрузочного проема",
          "На 0,5 м выше разгрузочного проема",
        ],
        correctAnswers: ["На 0,7 м выше разгрузочного проема"],
      },
      {
        code: "20101190",
        text: "Какие требования установлены к отделениям, в которых производят дробление пека, приготовление или разогрев электродной массы, и к оборудованию в этих отделениях?",
        answers: [
          "Отделения должны быть изолированы от остальных рабочих помещений, к оборудованию специальных требований не установлено",
          "Отделения должны быть совмещены с остальными рабочими помещениями, оборудование должно быть герметичным",
          "Отделения должны быть изолированы от остальных рабочих помещений, а оборудование должно быть герметизировано или надежно укрыто и снабжено средствами местного отсоса",
          "Отделения должны быть совмещены с остальными рабочими помещениями, оборудование должно быть снабжено средствами местного отсоса",
        ],
        correctAnswers: [
          "Отделения должны быть изолированы от остальных рабочих помещений, а оборудование должно быть герметизировано или надежно укрыто и снабжено средствами местного отсоса",
        ],
      },
      {
        code: "20101191",
        text: "Под каким избыточным давлением должна постоянно находиться вся система электровозгонки фосфора?",
        answers: [
          "Не менее 2 мм водяного столба",
          "Не более 2 мм водяного столба",
          "Не более 3 мм водяного столба",
          "Не менее 3 мм водяного столба",
        ],
        correctAnswers: ["Не менее 3 мм водяного столба"],
      },
      {
        code: "20101192",
        text: "Что необходимо сделать перед включением электропечи после ремонта, выполненного с ее разгерметизацией?",
        answers: [
          "Продуть все аппараты и газоходы инертным газом до содержания кислорода не более 2 %",
          "Продуть все аппараты и газоходы инертным газом до содержания кислорода более 5 %",
          "Очистить все аппараты и газоходы и провести осмотр",
          "Продуть все аппараты и газоходы инертным газом до содержания азота не более 2 %",
        ],
        correctAnswers: [
          "Продуть все аппараты и газоходы инертным газом до содержания кислорода не более 2 %",
        ],
      },
      {
        code: "20101193",
        text: "Какая устанавливается минимальная высота гидрозатвора в приемном баке при гидравлическом способе удаления пыли из электрофильтров при производстве фосфора и его соединений?",
        answers: [
          "100 мм с учетом конуса, образующегося при работе мешалки",
          "150 мм с учетом конуса, образующегося при работе мешалки",
          "200 мм с учетом конуса, образующегося при работе мешалки",
          "250 мм с учетом конуса, образующегося при работе мешалки",
        ],
        correctAnswers: [
          "200 мм с учетом конуса, образующегося при работе мешалки",
        ],
      },
      {
        code: "20101194",
        text: "На какой уровень должны быть заглублены полуподземные резервуары и хранилища фосфора?",
        answers: [
          "На уровень, обеспечивающий вместимость не менее 40 % хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,2 м",
          "На уровень, обеспечивающий вместимость не менее 50 % хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,2 м",
          "На уровень, обеспечивающий вместимость не менее 40 % хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,1 м",
          "На уровень, обеспечивающий вместимость не менее 30 % хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,1 м",
        ],
        correctAnswers: [
          "На уровень, обеспечивающий вместимость не менее 50 % хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,2 м",
        ],
      },
      {
        code: "20101195",
        text: "Какая максимальная вместимость одного отсека на складах предприятий, производящих желтый фосфор?",
        answers: ["50 т", "100 т", "150 т", "200 т"],
        correctAnswers: ["100 т"],
      },
      {
        code: "20101196",
        text: "Какое количество бочек с фосфором должно быть в каждом ярусе по длине и по ширине?",
        answers: [
          "Не более 20 бочек и не более 3 бочек соответственно",
          "Не более 15 бочек и не более 2 бочек соответственно",
          "Не более 20 бочек и не более 4 бочек соответственно",
          "Не более 10 бочек и не более 2 бочек соответственно",
        ],
        correctAnswers: ["Не более 15 бочек и не более 2 бочек соответственно"],
      },
      {
        code: "20101197",
        text: "Какая должна быть ширина основных проходов (для транспортирования бочек) в складе желтого фосфора при хранении его в бочках?",
        answers: [
          "Не менее 0,9 м",
          "Не менее 1,5 м",
          "Не менее 1,8 м",
          "Не менее 1 м",
        ],
        correctAnswers: ["Не менее 1,8 м"],
      },
      {
        code: "20101198",
        text: "Каким образом необходимо наполнять цистерны фосфором?",
        answers: [
          "Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 50 °С. Слой воды или раствора должен быть высотой не менее 500 мм",
          "Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 50 °C с таким расчетом, чтобы после заполнения цистерны над поверхностью фосфора был слой воды или незамерзающего раствора высотой не менее 300 мм и свободное пространство не менее 10 % объема цистерны",
          "Перед наполнением цистерн их необходимо продуть инертным газом",
          "Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 25 °С. Слой воды или раствора должен быть высотой не менее 100 мм, свободное пространство цистерны - не менее 10 %",
        ],
        correctAnswers: [
          "Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 50 °C с таким расчетом, чтобы после заполнения цистерны над поверхностью фосфора был слой воды или незамерзающего раствора высотой не менее 300 мм и свободное пространство не менее 10 % объема цистерны",
        ],
      },
      {
        code: "20101199",
        text: "Какая вместимость емкостей с фосфором допустима при их установке в производственном помещении?",
        answers: [
          "Не более 3-суточной потребности",
          "Не более 2-суточной потребности",
          "Не более 50 м³",
          "Не более 20 м³",
        ],
        correctAnswers: ["Не более 20 м³"],
      },
      {
        code: "20101200",
        text: "Какую потребность не должна превышать вместимость резервуаров в дозаторном отделении цехов, потребляющих фосфор?",
        answers: [
          "Трехсуточной потребности производства в фосфоре",
          "Пятисуточной потребности производства в фосфоре",
          "Суточной потребности производства в фосфоре",
          "Двухсуточной потребности производства в фосфоре",
        ],
        correctAnswers: ["Двухсуточной потребности производства в фосфоре"],
      },
      {
        code: "20101201",
        text: "Какой показатель необходимо контролировать для предотвращения попадания фосфорной кислоты в оборотную систему водоснабжения?",
        answers: [
          "Температуру раствора",
          "Давление в системе",
          "рН нагретой воды",
          "Температуру воды",
        ],
        correctAnswers: ["рН нагретой воды"],
      },
      {
        code: "20101202",
        text: "Какое максимальное разрежение поддерживается для предотвращения попадания фосфорного ангидрида в атмосферу цеха в башне сжигания?",
        answers: [
          "3 мм водяного столба",
          "5 мм водяного столба",
          "6 мм водяного столба",
          "10 мм водяного столба",
        ],
        correctAnswers: ["5 мм водяного столба"],
      },
      {
        code: "20101203",
        text: "Какая минимальная ширина прохода должна быть предусмотрена в складе между штабелями мешков с серой?",
        answers: ["1 м", "2 м", "3 м", "4 м"],
        correctAnswers: ["1 м"],
      },
      {
        code: "20101204",
        text: "Как следует хранить жидкую серу?",
        answers: [
          "Под слоем воды",
          "В герметичной таре",
          "В охлаждаемых емкостях, продуваемых инертным газом",
          "В обогреваемых паром или наружными электрическими устройствами теплоизолированных емкостях, продуваемых инертным газом",
        ],
        correctAnswers: [
          "В обогреваемых паром или наружными электрическими устройствами теплоизолированных емкостях, продуваемых инертным газом",
        ],
      },
      {
        code: "20101205",
        text: "В каких условиях должна проводиться реакция синтеза пятисернистого фосфора?",
        answers: [
          "В герметичных аппаратах в атмосфере инертного газа",
          "В герметичной таре",
          "В охлаждаемых аппаратах, снабженных устройствами для отвода тепла",
          "В аппаратах, обогреваемых паром",
        ],
        correctAnswers: ["В герметичных аппаратах в атмосфере инертного газа"],
      },
      {
        code: "20101206",
        text: "Какие требования к барабанам и контейнерам, заполненным пятисернистым фосфором, установлены Правилами безопасности химически опасных производственных объектов?",
        answers: [
          "Барабаны и контейнеры должны храниться в специальных сухих, непроветриваемых помещениях",
          "Барабаны и контейнеры должны быть герметично закрыты и храниться в сухих проветриваемых складах",
          "Барабаны и контейнеры должны храниться при температуре не выше 15 °С",
          "Барабаны и контейнеры должны быть оборудованы световыми оповещателями об их переполнении",
        ],
        correctAnswers: [
          "Барабаны и контейнеры должны быть герметично закрыты и храниться в сухих проветриваемых складах",
        ],
      },
      {
        code: "20101207",
        text: "В каких условиях должна проводиться реакция получения фосфида цинка?",
        answers: [
          "Мерник для фосфора должен быть снабжен устройством, предотвращающим попадание воздуха в реактор",
          "Перед установкой реактора необходимо включить муфель",
          "Мерник для фосфора должен быть снабжен устройством, предотвращающим попадание воды в реактор",
          "Перед дозировкой фосфора реактор должен быть тщательно продут инертным газом в течение не более 3 минут",
        ],
        correctAnswers: [
          "Мерник для фосфора должен быть снабжен устройством, предотвращающим попадание воды в реактор",
        ],
      },
      {
        code: "20101208",
        text: "Какое из перечисленных требований при складировании фосфида цинка указано верно?",
        answers: [
          "Высота штабелей не должна превышать высоту двух банок",
          "Укладывать банки с фосфидом цинка необходимо так, чтобы верхняя опиралась дном на две банки нижнего ряда",
          "Расстояние между штабелями банок должно быть не менее 1 м",
          "Все перечисленные требования верны",
        ],
        correctAnswers: [
          "Укладывать банки с фосфидом цинка необходимо так, чтобы верхняя опиралась дном на две банки нижнего ряда",
        ],
      },
      {
        code: "20101209",
        text: "Какие требования установлены для трубопроводов, предназначенных для транспортирования фосфора и фосфорного шлама?",
        answers: [
          "Фосфоропроводы прокладываются в закрытых галереях эстакадного типа",
          "Трубопроводы для транспортирования фосфора и фосфорного шлама не допускается прокладывать с обогревающим спутником в одной изоляции",
          "Трубопроводы фосфора и фосфорного шлама следует располагать по верхнему ярусу пролетного строения эстакад",
          "Расстояние по горизонтали от трубопроводов фосфора, фосфорного шлама до трубопроводов, содержащих пожароопасные и токсичные продукты, не должно быть менее 1,5 м",
        ],
        correctAnswers: [
          "Расстояние по горизонтали от трубопроводов фосфора, фосфорного шлама до трубопроводов, содержащих пожароопасные и токсичные продукты, не должно быть менее 1,5 м",
        ],
      },
      {
        code: "20101210",
        text: "В какой цвет должны быть окрашены участки аммиачных трубопроводов, на которые наносятся опознавательные кольца?",
        answers: [
          "В желтый цвет",
          "В красный цвет",
          "В белый цвет",
          "В оранжевый цвет",
        ],
        correctAnswers: ["В желтый цвет"],
      },
      {
        code: "20101211",
        text: "На каких из перечисленных участках аммиачных трубопроводов должны быть нанесены три опознавательных кольца?",
        answers: [
          "На паровых, парожидкостных линиях стороны низкого давления систем холодоснабжения",
          "На жидкостных линиях стороны низкого давления",
          "На жидкостных линиях стороны высокого давления",
          "На паровых линиях стороны высокого давления",
        ],
        correctAnswers: ["На жидкостных линиях стороны высокого давления"],
      },
      {
        code: "20101212",
        text: "Какую температуру должна иметь вода для охлаждения компрессора на входе и на выходе из рубашек цилиндров, если заводом-изготовителем не предусмотрены другие предельные значения?",
        answers: [
          "Не ниже 5 °C и не более 35 °C соответственно",
          "Не ниже 15 °C и не более 40 °C соответственно",
          "Не ниже 10 °C и не более 45 °C соответственно",
          "Не ниже 20 °C и не более 50 °C соответственно",
        ],
        correctAnswers: ["Не ниже 10 °C и не более 45 °C соответственно"],
      },
      {
        code: "20101213",
        text: "С какой периодичностью необходимо проверять отходящую из конденсатора воду на присутствие аммиака?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в три месяца",
          "Не реже одного раза в шесть месяцев",
          "Не реже одного раза в девять месяцев",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20101214",
        text: "Какое условие при механической очистке труб конденсатора от водяного камня является неверным?",
        answers: [
          "Не реже одного раза в месяц необходимо проверять отходящую из конденсатора воду на присутствие аммиака",
          "Механическая очистка труб конденсатора от водяного камня должна выполняться под руководством начальника цеха",
          "Механическая очистка труб конденсатора от водяного камня должна выполняться с оформлением наряда-допуска и только после освобождения конденсатора от аммиака",
          "Работа должна выполняться под руководством технического руководителя организации",
        ],
        correctAnswers: [
          "Работа должна выполняться под руководством технического руководителя организации",
        ],
      },
      {
        code: "20101215",
        text: "Кто определяет график контроля воздушной среды в подвальных и полуподвальных помещениях масличного сырья, галереях, туннелях и приямках, связанных с транспортированием масличного сырья?",
        answers: [
          "Начальник производства",
          "Технический руководитель организации",
          "Главный технолог организации",
          "Ответственный за охрану труда",
        ],
        correctAnswers: ["Технический руководитель организации"],
      },
      {
        code: "20101216",
        text: "Какое объемное содержание диоксида (двуокиси) углерода и кислорода допускается в подвальных и полуподвальных помещениях складов масличного сырья, галерей и туннелей, предназначенных для транспортирования маслосемян, а также приямков, в которых размещено оборудование для транспортирования масличного сырья, в случае неисправности вентиляции?",
        answers: [
          "Содержание в воздушной среде СО2 не должно превышать 1,5 % объемных при наличии О2 не менее 18 %",
          "Содержание в воздушной среде СО2 не должно превышать 2,5 %, а содержание О2 должно быть не менее 15 %",
          "Содержание в воздухе СО2 не должно превышать 0,5 %, а О2 должно быть не менее 20 %",
          "Содержание в воздухе СО2 не должно превышать 2,0 % при наличии О2 не менее 20 %",
        ],
        correctAnswers: [
          "Содержание в воздухе СО2 не должно превышать 0,5 %, а О2 должно быть не менее 20 %",
        ],
      },
      {
        code: "20101217",
        text: "Какая должна быть высота решетчатого вертикального ограждения для бункеров и завальных ям объектов производств растительных масел?",
        answers: [
          "Не менее чем на 1,5 м выше уровня загрузки масличного сырья",
          "Не менее чем на 1 м выше уровня загрузки масличного сырья",
          "Не менее чем на 0,5 м выше уровня загрузки масличного сырья",
          "Правилами не регламентируется",
        ],
        correctAnswers: [
          "Не менее чем на 0,5 м выше уровня загрузки масличного сырья",
        ],
      },
      {
        code: "20101218",
        text: "Какая устанавливается максимальная температура масличного сырья в бункерах складов и силосных ячейках элеваторов объектов производств растительных масел?",
        answers: [
          "40 °С",
          "50 °С",
          "45 °С",
          "30 °С",
          "Правилами не регламентируется",
        ],
        correctAnswers: ["40 °С"],
      },
      {
        code: "20101219",
        text: "Какие действия персонала предусмотрены в случае повышения температуры масличного сырья выше допустимой, указанной в технологическом регламенте объектов производств растительных масел?",
        answers: [
          "Следует вызвать пожарную охрану предприятия и сообщить руководителю производства",
          "Следует применить активное вентилирование, произвести перекачку семян из одного силоса (бункера) в другой, с площадки на площадку",
          "Следует произвести запись в журнале эксплуатации и ремонта оборудования и сообщить главному технологу предприятия",
          "Следует вызвать пожарную охрану предприятия",
        ],
        correctAnswers: [
          "Следует применить активное вентилирование, произвести перекачку семян из одного силоса (бункера) в другой, с площадки на площадку",
        ],
      },
      {
        code: "20101220",
        text: "Какими типами предохранительных клапанов необходимо оборудовать шроторазгрузитель, микроциклоны и шротопровод объектов производств растительных масел?",
        answers: [
          "Пружинного типа",
          "Рычажно-грузового типа",
          "Магнитно-пружинного типа",
          "Мембранного типа",
        ],
        correctAnswers: ["Мембранного типа"],
      },
      {
        code: "20101221",
        text: "Какое из перечисленных требований к процессам транспортирования и хранения масличного сырья, жмыхов и шротов указано неверно?",
        answers: [
          "Температуру заложенных на хранение жмыха и шрота необходимо проверять по графику, утвержденному в эксплуатирующей организации, с помощью дистанционных стационарных термометров (термоподвесок)",
          "Для вновь строящихся элеваторов для шрота должны предусматриваться устройства, обеспечивающие разрыхление слежавшегося шрота",
          "Элеваторы для хранения масличного сырья и шрота должны снабжаться передвижными лебедками с люльками для спуска людей внутрь ячейки силоса в случае необходимости. Установка внутри лестниц (скоб) не допускается",
          "Пневмотранспорт и его элементы должны быть заземлены путем установки электропроводящих перемычек в местах фланцевых соединений элементов",
        ],
        correctAnswers: [
          "Элеваторы для хранения масличного сырья и шрота должны снабжаться передвижными лебедками с люльками для спуска людей внутрь ячейки силоса в случае необходимости. Установка внутри лестниц (скоб) не допускается",
        ],
      },
      {
        code: "20101222",
        text: "Какие требования к экстракторам объектов производства масел указаны неверно?",
        answers: [
          "Шнековые экстракторы оснащаются системами аварийного освобождения",
          "Шнековые экстракторы оснащаются приборами контроля и регулирования частоты вращения валов",
          "Ленточные экстракторы и экстракторы других типов, имеющие в своем составе конвейеры, оснащаются системами регулирования скорости движения ленты (конвейера) в зависимости от количества подаваемого материала",
          "Приводы экстракторов обеспечиваются системами защиты от превышения предельно допустимой нагрузки на валы, исключающими их поломку при запрессовках и заклинивании в случае попадания посторонних предметов",
        ],
        correctAnswers: [
          "Шнековые экстракторы оснащаются системами аварийного освобождения",
        ],
      },
      {
        code: "20101223",
        text: "Какие требования к экстракторам объектов производств растительных масел указаны неверно?",
        answers: [
          "Приводы экстракторов обеспечиваются системами защиты от превышения предельно допустимой нагрузки на валы, исключающими их поломку при запрессовках и заклинивании в случае попадания посторонних предметов",
          "Шнековые экстракторы оснащаются приборами контроля и регулирования частоты вращения валов",
          "Шнековые экстракторы оснащаются системами аварийного освобождения",
          "Ленточные экстракторы и экстракторы других типов, имеющие в своем составе конвейеры, оснащаются системами регулирования скорости движения ленты (конвейера) в зависимости от количества подаваемого материала",
        ],
        correctAnswers: [
          "Шнековые экстракторы оснащаются системами аварийного освобождения",
        ],
      },
      {
        code: "20101224",
        text: "Какие действия должны быть выполнены при остановке чанного испарителя (тостера) во избежание возможного обугливания в нем шрота и возникновения аварийной ситуации?",
        answers: [
          "Подача острого пара во все чаны и загрузка чанного испарителя непроэкстрагированным материалом (лепестком, крупкой)",
          "Отключение подачи глухого пара",
          "Загрузка чанного испарителя непроэкстрагированным материалом (лепестком, крупкой)",
        ],
        correctAnswers: ["Отключение подачи глухого пара"],
      },
      {
        code: "20101225",
        text: "Какова периодичность осмотра технического состояния трубок конденсаторов объектов производства масел?",
        answers: [
          "Согласно графику, определенному руководителем организации, но не реже 1 раза в год",
          "Определяется технологическим регламентом",
          "Не реже 1 раза в 1,5 года",
          "Не реже 1 раза в 2 года",
        ],
        correctAnswers: [
          "Согласно графику, определенному руководителем организации, но не реже 1 раза в год",
        ],
      },
      {
        code: "20101226",
        text: "Какие требования к тепловой изоляции аппаратов, технологических трубопроводов для растительных масел и мисцеллы объектов производств растительных масел указаны неверно?",
        answers: [
          "Для теплоизоляции аппаратов, технологических трубопроводов для растительных масел и мисцеллы должен использоваться непористый негорючий материал",
          "Должны предусматриваться меры защиты от попаданий масла, мисцеллы, растворителя на изоляцию или под ее слой",
          "Для теплоизоляции используется трудносгораемый материал",
          "Должны применяться теплоизоляционные изделия из минеральной ваты, базальтового или супертонкого стекла только в обкладках со всех сторон из стеклянной или кремнеземной ткани и под металлическим покрывным слоем",
        ],
        correctAnswers: [
          "Для теплоизоляции используется трудносгораемый материал",
        ],
      },
      {
        code: "20101227",
        text: "Какие требования к подшипниковым узлам шнековых питателей, ленточных конвейеров, норий, скребковых конвейеров, валов, винтовых конвейеров и перемешивающих устройств экстракторов, тостеров объектов производств растительных масел указаны верно?",
        answers: [
          "Должны находиться в зоне видимости оператора установки",
          "Должны выноситься из зоны, в которую возможно попадание и накопление перемещаемых продуктов, или должны иметь защиту от попадания в них этих продуктов",
          "Должны проходить ежесменный контроль состояния",
          "Должны смазываться перед началом смены",
        ],
        correctAnswers: [
          "Должны выноситься из зоны, в которую возможно попадание и накопление перемещаемых продуктов, или должны иметь защиту от попадания в них этих продуктов",
        ],
      },
      {
        code: "20101228",
        text: "Какие требования к газоходу, соединяющему тостер и мокрую шротоловушку, указаны неверно?",
        answers: [
          "Должен иметь подвод пара для пропаривания газохода и шротоловушки перед вскрытием их для осмотра или ремонта",
          "Должен иметь лючки в торцах для ревизии и очистки внутренней поверхности",
          "Должен иметь уклон в сторону мокрой шротоловушки не менее 2 см на 1 м трубы газохода",
          "Должен быть оборудован показывающим манометром",
        ],
        correctAnswers: ["Должен быть оборудован показывающим манометром"],
      },
      {
        code: "20101229",
        text: "В каких теплообменных аппаратах объектов производств растительных масел допускается возможность взаимного проникновения пара (воды) и нагреваемого (охлаждаемого) продукта?",
        answers: [
          "Только в оборудовании, где это предусматривается технологией",
          "Только в подогревателях",
          "Только в дистилляторах",
          "Во всех перечисленных аппаратах",
        ],
        correctAnswers: [
          "Только в оборудовании, где это предусматривается технологией",
        ],
      },
      {
        code: "20101230",
        text: "Какие устройства, оснащаемые фильтр для мисцеллы, указаны неверно?",
        answers: [
          "Манометр для измерения давления фильтруемой жидкости",
          "Смотровой фонарь на сливной трубе фильтрованной мисцеллы",
          "Вакуумметр на сливной трубе фильтрованной мисцеллы",
          "Штуцер для отбора проб",
        ],
        correctAnswers: ["Вакуумметр на сливной трубе фильтрованной мисцеллы"],
      },
      {
        code: "20101231",
        text: "В каком случае разрешается установка запорного устройства на вытяжной трубе объектов производств растительных масел?",
        answers: [
          "Если запорное устройство устанавливается на вытяжной трубе конденсатора",
          "Если вытяжная труба оборудована пароэжектором или вентилятором",
          "Если запорное устройство устанавливается на вытяжной трубе дефлегматора",
          "Если запорное устройство устанавливается на вытяжной трубе абсорбера",
        ],
        correctAnswers: [
          "Если вытяжная труба оборудована пароэжектором или вентилятором",
        ],
      },
      {
        code: "20101232",
        text: "Какие требования к технологическим трубопроводам для растворителя, мисцеллы и паров растворителя указаны неверно?",
        answers: [
          "Технологические трубопроводы не должны иметь цифровых обозначений, маркировка трубопроводов осуществляется различной окраской, выполненной в соответствии с государственным стандартом",
          "Технологические трубопроводы должны размещаться с учетом возможности проведения визуального контроля их состояния",
          "Технологические трубопроводы должны размещаться с учетом возможности выполнения работ по обслуживанию и ремонту",
          "На маховиках или рукоятках арматуры технологических трубопроводов должны быть нанесены стрелки, указывающие направления их вращения",
        ],
        correctAnswers: [
          "Технологические трубопроводы не должны иметь цифровых обозначений, маркировка трубопроводов осуществляется различной окраской, выполненной в соответствии с государственным стандартом",
        ],
      },
      {
        code: "20101233",
        text: "Для постоянного обслуживания какого оборудования (арматуры) должна быть устроена металлическая площадка с ограждением и лестницей?",
        answers: [
          "Расположенного на уровне выше 1,5 м от пола",
          "Расположенного на уровне выше 1,2 м от пола",
          "Расположенного на уровне выше 1,8 м от пола",
          "Расположенного на уровне выше 1,0 м от пола",
          "Расположенного на уровне, предусмотренном проектом",
        ],
        correctAnswers: ["Расположенного на уровне выше 1,8 м от пола"],
      },
      {
        code: "20101234",
        text: "Какой должна быть ширина центрального прохода для обслуживания оборудования у вновь строящихся и реконструируемых систем холодоснабжения?",
        answers: [
          "Не более 1,2 м",
          "Не менее 1,5 м",
          "Не менее 1 м",
          "Не более 0,8 м",
        ],
        correctAnswers: ["Не менее 1,5 м"],
      },
      {
        code: "20101235",
        text: "В каком случае допускается предусматривать отдельные отделители жидкости, соединенные трубопроводами с циркуляционными (защитными) ресиверами, не совмещающими функции отделителя жидкости, для отделения жидкой фазы из перемещаемой парожидкостной смеси в системах холодоснабжения?",
        answers: [
          "Допускается для аппаратов вертикального типа",
          "Допускается в обоснованных в проектной документации случаях",
          "Допускается для аппаратов горизонтального типа",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "Допускается в обоснованных в проектной документации случаях",
        ],
      },
      {
        code: "20101236",
        text: "Какие номинальные величины загазованности аммиаком должны контролироваться в помещениях машинных и аппаратных отделений аммиачных холодильных установок?",
        answers: [
          "10, 125 и 500 мг/м³",
          "15, 70 и 1000 мг/м³",
          "20, 60 и 500 мг/м³",
          "5, 300 и 1500 мг/м³",
        ],
        correctAnswers: ["20, 60 и 500 мг/м³"],
      },
      {
        code: "20101237",
        text: "На какую массовую нагрузку должны быть рассчитаны специальные опоры или подвески, на которые монтируются трубопроводы аммиачных холодильных установок?",
        answers: [
          "Только на собственную массу трубопровода и массу хладагента",
          "Только на массу тепловой изоляции и массу хладагента",
          "На собственную массу трубопровода, массу хладагента и тепловой изоляции, принятые с коэффициентом запаса 1,2",
          "Только на массу тепловой изоляции трубопровода, принятую с коэффициентом запаса 1,5",
        ],
        correctAnswers: [
          "На собственную массу трубопровода, массу хладагента и тепловой изоляции, принятые с коэффициентом запаса 1,2",
        ],
      },
      {
        code: "20101238",
        text: "Каким должно быть расстояние от охлаждающих батарей до грузового штабеля в холодильных камерах?",
        answers: [
          "Не менее 0,1 м",
          "Не менее 0,2 м",
          "Не менее 0,3 м",
          "Расстояние не регламентируется",
        ],
        correctAnswers: ["Не менее 0,3 м"],
      },
      {
        code: "20101239",
        text: "Какую скорость паров аммиака в сечении паровой зоны должен обеспечивать размер паровой зоны вертикального сосуда или аппарата, исполняющего функции отделения жидкости?",
        answers: [
          "Не более 0,5 м/с",
          "Не более 1 м/с",
          "Правилами не регламентируется",
          "Не более 1,5 м/с",
        ],
        correctAnswers: ["Не более 0,5 м/с"],
      },
      {
        code: "20101240",
        text: "Что учитывается при определении пропускной способности предохранительных устройств для защиты от разрушений сосудов, аппаратов и технологического оборудования, содержащих жидкий аммиак?",
        answers: [
          "Площадь наружной поверхности аппарата и удельная теплота парообразования аммиака при давлении насыщения в 2 раза больше расчетного давления защищаемого аппарата",
          "Скорость теплового потока испарившегося аммиака в случае пожара",
          "Плотность теплового потока через наружные стенки сосуда, площадь наружной поверхности аппарата и удельная теплота парообразования аммиака при давлении насыщения в 1,15 раза больше расчетного давления защищаемого аппарата",
          "Общий объем аммиака в аппарате",
        ],
        correctAnswers: [
          "Плотность теплового потока через наружные стенки сосуда, площадь наружной поверхности аппарата и удельная теплота парообразования аммиака при давлении насыщения в 1,15 раза больше расчетного давления защищаемого аппарата",
        ],
      },
      {
        code: "20101241",
        text: "В каком случае допускается использование ртутных термометров и ртутных устройств для измерения температуры в контрольных точках аммиачной холодильной системы?",
        answers: [
          "Если используется защита от механических повреждений",
          "Допускается в любом случае",
          "Если это обосновано проектной документацией",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20101242",
        text: "Где должны быть установлены манометры (мановакуумметры) в целях обеспечения безопасности ведения технологических процессов в системах холодоснабжения на холодильном оборудовании и машинах?",
        answers: [
          "Только на компрессоре для наблюдения за рабочими давлениями всасывания, нагнетания",
          "Только на аппаратах, сосудах, аммиачных насосах, технологическом оборудовании с непосредственным охлаждением",
          "Только на жидкостных и оттаивательных коллекторах распределительных аммиачных устройств, соединенных трубопроводами с оборудованием холодильных камер",
          "Только в системе смазки (при принудительной подаче масла насосом) и в картере (поршневых компрессоров, не имеющих уравнивания между всасыванием и картером)",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20101243",
        text: "Где допускается размещать насосы оборотного водоснабжения в обоснованных в проектной документации случаях? Укажите все правильные ответы.",
        answers: [
          "В машинном отделении",
          "В аппаратном отделении",
          "На открытой площадке",
          "Над машинным отделением",
        ],
        correctAnswers: ["В машинном отделении", "В аппаратном отделении"],
      },
      {
        code: "20101244",
        text: "С какой периодичностью должно проводиться техническое освидетельствование сосудов и аппаратов холодильных установок?",
        answers: [
          "Периодичность устанавливается внутренними распорядительными документами эксплуатирующей организации",
          "1 раз перед пуском в работу",
          "Не реже 1 раза в 8 лет",
          "Периодичность определяется с учетом требований федеральных норм и правил в области промышленной безопасности, устанавливающих правила промышленной безопасности опасных производственных объектов, на которых используется оборудование, работающее под избыточным давлением",
        ],
        correctAnswers: [
          "Периодичность определяется с учетом требований федеральных норм и правил в области промышленной безопасности, устанавливающих правила промышленной безопасности опасных производственных объектов, на которых используется оборудование, работающее под избыточным давлением",
        ],
      },
      {
        code: "20101245",
        text: "Какие сроки освидетельствования систем холодоснабжения с ограниченной зарядкой аммиаком (не более 50 кг), поставляемых комплектно организациями-изготовителями?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 2 раз в год",
          "Сроки устанавливаются лицом, ответственным за исправное состояние и безопасную работу сосудов (аппаратов)",
          "Устанавливаются эксплуатационной документацией организаций-изготовителей",
        ],
        correctAnswers: [
          "Устанавливаются эксплуатационной документацией организаций-изготовителей",
        ],
      },
      {
        code: "20101246",
        text: "В каком случае допускается оснащать сосуды холодильных систем одним предохранительным клапаном?",
        answers: [
          "Если геометрический объем сосуда не превышает 0,5 м³",
          "Если оснащение одним предохранительным клапаном предусмотрено проектом",
          "Если клапан рассчитан на полную пропускную способность",
          "Если геометрический объем сосуда не превышает 0,3 м³",
        ],
        correctAnswers: [
          "Если геометрический объем сосуда не превышает 0,3 м³",
        ],
      },
      {
        code: "20101247",
        text: "С какой периодичностью предохранительные устройства компрессорных агрегатов должны проверяться на давление срабатывания?",
        answers: [
          "Не реже 1 раза в 10 лет",
          "Не реже 1 раза в 5 лет",
          "Не реже 1 раза в год",
          "Не реже 1 раза в 3 года",
          "Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией",
        ],
        correctAnswers: [
          "Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией",
        ],
      },
      {
        code: "20101248",
        text: "Какое допускается максимальное заполнение геометрического объема дренажного ресивера для аварийного (ремонтного) освобождения от жидкого аммиака охлаждающих устройств, аппаратов, сосудов и блоков?",
        answers: ["На 60 %", "На 70 %", "На 80 %", "На 90 %"],
        correctAnswers: ["На 80 %"],
      },
      {
        code: "20101249",
        text: "В каком случае допускается предусматривать линейный ресивер для холодильных машин с дозированной зарядкой аммиака?",
        answers: [
          "Если использование линейного ресивера обосновано проектной документацией",
          "Если линейный ресивер используется в качестве защитного",
          "Если линейный ресивер используется в качестве дренажного или циркуляционного",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20101250",
        text: "В каком случае допускается размещать холодильное оборудование над площадками открытых насосных и компрессорных установок? Укажите все правильные ответы.",
        answers: [
          "В случае применения герметичных (бессальниковых) насосов",
          "При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование",
          "Не допускается ни в каком случае",
          "В случае размещения холодильного оборудования над площадками на расстоянии, определенном проектной документацией",
        ],
        correctAnswers: [
          "В случае применения герметичных (бессальниковых) насосов",
          "При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование",
        ],
      },
      {
        code: "20101251",
        text: "Каким следует принимать расстояние в свету от аппаратов (сосудов), расположенных снаружи машинного (аппаратного) отделения?",
        answers: [
          "Не менее 2,5 м от стены здания",
          "Не менее 1,0 м от стены здания",
          "Не менее 1,5 м от стены здания",
          "Не менее 2,0 м от стены здания",
        ],
        correctAnswers: ["Не менее 1,0 м от стены здания"],
      },
      {
        code: "20101252",
        text: "Каким должно быть минимальное остаточное избыточное давление в транспортировочных емкостях аммиака при их полном опорожнении?",
        answers: ["0,01 МПа", "0,02 МПа", "0,05 МПа", "0,03 МПа"],
        correctAnswers: ["0,05 МПа"],
      },
      {
        code: "20101253",
        text: "В течение какого времени нахождения цистерн с жидким аммиаком на территории организации должно быть организовано наблюдение за ними?",
        answers: [
          "В течение слива аммиака",
          "В течение всего времени нахождения",
          "В течение периода приемки цистерны",
          "Наблюдение за цистернами с жидким аммиаком не требуется",
        ],
        correctAnswers: ["В течение всего времени нахождения"],
      },
      {
        code: "20101254",
        text: "Какое действие необходимо предпринять в случае перерыва слива аммиака?",
        answers: [
          "Допускается оставлять цистерну присоединенной к системе на время перерыва слива",
          "Допускается оставлять цистерну присоединенной к системе под наблюдением ответственных работников, назначенных приказом",
          "Предпринимаются действия по решению лица, ответственного за производство работ по сливу аммиака",
          "Съемные участки трубопроводов должны быть отсоединены от цистерн",
        ],
        correctAnswers: [
          "Съемные участки трубопроводов должны быть отсоединены от цистерн",
        ],
      },
      {
        code: "20101255",
        text: "В каком случае перегрев паров аммиака, всасываемых компрессором, должен быть не менее 10 К (°C)?",
        answers: [
          "Для одноступенчатых компрессоров",
          "Для ступени высокого давления двухступенчатых компрессоров",
          "Для ступени низкого давления двухступенчатых компрессоров",
          "Для двухступенчатых компрессоров",
        ],
        correctAnswers: [
          "Для ступени низкого давления двухступенчатых компрессоров",
        ],
      },
      {
        code: "20101256",
        text: "Как рассчитывается геометрический объем защитных ресиверов (Vз.р.) вертикального типа, совмещающих функцию отделителя жидкости аммиачных холодильных установок, для каждой температуры кипения аммиака?",
        answers: [
          "Vз.р больше Vс x 0,2 м³",
          "Vз.р больше Vс x 0,5 м³",
          "Vз.р больше Vс x 0,6 м³",
          "Vз.р больше Vс x 1,2 м³",
        ],
        correctAnswers: ["Vз.р больше Vс x 0,5 м³"],
      },
      {
        code: "20101257",
        text: "Какая максимальная температура нагнетания должна быть для поршневых компрессоров, если инструкцией организации-изготовителя не предусмотрено иное значение?",
        answers: ["185 °C", "160 °C", "135 °C", "90 °C"],
        correctAnswers: ["160 °C"],
      },
      {
        code: "20101258",
        text: "В каком случае насос должен быть немедленно остановлен?",
        answers: [
          "Только если упало давление напора или разность давлений напора и всасывания (при отсутствии или отказе приборов автоматики)",
          "Только если появились утечки аммиака через неплотности агрегата",
          "Только если обнаружены неисправности манометров, обратных клапанов, средств контрольно-измерительных приборов и автоматики",
          "В любом из перечисленных случаев",
        ],
        correctAnswers: ["В любом из перечисленных случаев"],
      },
      {
        code: "20101259",
        text: "В каком положении должны быть опломбированы запорные клапаны на аммиачных газовых нагнетательных трубопроводах?",
        answers: ["В открытом", "В закрытом", "Не регламентируется"],
        correctAnswers: ["В открытом"],
      },
      {
        code: "20101260",
        text: "В каком случае аппарат (сосуд) подлежит немедленной остановке?",
        answers: [
          "Только в случае неисправности предусмотренных проектом контрольно-измерительных приборов и средств автоматики",
          "Только в случае неисправности предохранительных клапанов",
          "Только в случае неисправности указателя уровня жидкости",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20101261",
        text: "Каким должен быть процент первичного заполнения жидким аммиаком внутреннего объема воздухоохладителей с верхней подачей аммиака?",
        answers: ["30 %", "50 %", "70%", "80 %"],
        correctAnswers: ["50 %"],
      },
      {
        code: "20101262",
        text: "Для какого оборудования процент первичного заполнения жидким аммиаком не превышает 30 %?",
        answers: [
          "Для испарителей змеевиковых и листотрубных (панельных), независимо от наличия отделителей жидкости",
          "Для батарей холодильных камер с верхней подачей аммиака",
          "Для воздухоохладителей с верхней подачей аммиака",
          "Для морозильных и плиточных аппаратов непосредственного охлаждения",
        ],
        correctAnswers: [
          "Для батарей холодильных камер с верхней подачей аммиака",
        ],
      },
      {
        code: "20101263",
        text: "С какой периодичностью необходимо проверять промежуточный хладоноситель в системах охлаждения на присутствие аммиака?",
        answers: [
          "Не реже одного раза в год",
          "Не реже одного раза в полгода",
          "Не реже одного раза в три месяца",
          "Не реже одного раза в неделю",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20101264",
        text: "Кто утверждает годовые и месячные графики ремонта холодильного оборудования?",
        answers: [
          "Технический руководитель организации",
          "Главный механик организации",
          "Руководитель организации",
          "Руководитель службы технического контроля",
        ],
        correctAnswers: ["Технический руководитель организации"],
      },
      {
        code: "20101265",
        text: "Какие изотермические резервуары дополнительно рассчитываются на сейсмические нагрузки?",
        answers: [
          "Двустенные резервуары с подвесной крышей внутреннего резервуара",
          "Все изотермические резервуары",
          "Двустенные резервуары с двумя самонесущими крышами",
          "Одностенные резервуары",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20101266",
        text: "Какую минимальную температуру следует принимать для сливных, наливных и эвакуационных насосов жидкого аммиака?",
        answers: ["-35 °C", "-34 °C", "-28 °C", "-20 °C"],
        correctAnswers: ["-34 °C"],
      },
      {
        code: "20101267",
        text: "Что происходит с выбросами аммиака при продувках оборудования и трубопроводов, снижении в них давления, сливе (наливе) цистерн, а также со сбросами от предохранительных клапанов?",
        answers: [
          "Выбросы и сбросы утилизируются или направляются в факельную систему",
          "Выбросы и сбросы поступают в резервный резервуар",
          "Выбросы и сбросы поступают обратно в резервуар",
        ],
        correctAnswers: [
          "Выбросы и сбросы утилизируются или направляются в факельную систему",
        ],
      },
      {
        code: "20101268",
        text: "Какая минимальная вместимость газгольдера, устанавливаемого на линии подачи азота, определяется для поддержания в межстенном пространстве резервуара с засыпной изоляцией постоянной величины избыточного давления при изменениях барометрического давления и температуры воздуха?",
        answers: [
          "Не менее 15 - 18 % объема межстенного пространства резервуара, с которым соединен газгольдер",
          "Не менее 4 - 7 % объема межстенного пространства резервуара, с которым соединен газгольдер",
          "Не менее 12 - 15 % объема межстенного пространства резервуара, с которым соединен газгольдер",
          "Не менее 3 - 6 % объема межстенного пространства резервуара, с которым соединен газгольдер",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20101269",
        text: "Какие из перечисленных электроприемников складов жидкого аммиака являются потребителями II категории надежности?",
        answers: [
          "С резервуарами под избыточным давлением и вместимостью свыше 100 т",
          "С изотермическими резервуарами",
          "С шаровыми резервуарами",
          "С резервуарами под избыточным давлением и вместимостью до 100 т включительно",
        ],
        correctAnswers: [
          "С резервуарами под избыточным давлением и вместимостью до 100 т включительно",
        ],
      },
      {
        code: "20101270",
        text: "Чем производится продувка резервуаров для хранения жидкого аммиака перед включением в работу?",
        answers: [
          "Азотом",
          "Кислородом",
          "Газообразным аммиаком",
          "Любым благородным газом",
        ],
        correctAnswers: ["Азотом"],
      },
      {
        code: "20101271",
        text: "Чем должен быть продут изотермический резервуар перед наливом в него жидкого аммиака?",
        answers: [
          "Азотом",
          "Воздухом",
          "Газообразным аммиаком",
          "Инертным газом",
        ],
        correctAnswers: ["Газообразным аммиаком"],
      },
    ],

    20102: [
      {
        code: "20102000",
        text: "В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
          "В целях согласования действий подразделений муниципальной пожарной охраны при возникновении аварии",
          "В целях обеспечения соответствия объекта требованиям промышленной безопасности",
          "Во всех перечисленных целях",
        ],
        correctAnswers: [
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
        ],
      },
      {
        code: "20102001",
        text: "Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?",
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          "Только для ОПО I класса",
          "Только для ОПО IV класса",
          "Только для ОПО III класса",
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "20102002",
        text: "В каком случае организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий для нескольких опасных производственных объектов?",
        answers: [
          "В случае если 3 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке",
          "В случае если 3 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
          "В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
          "На каждый объект должен быть разработан свой план в любом случае",
        ],
        correctAnswers: [
          "В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
        ],
      },
      {
        code: "20102003",
        text: "Сколько необходимо разработать планов мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, в случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?",
        answers: [
          "Разрабатывается единый план мероприятий для двух и более объектов",
          "Разрабатываются планы мероприятий для каждого объекта отдельно",
          "Разрабатывается один план мероприятий для объектов, расположенных на одном участке, и несколько для объектов на смежных участках",
          "Разрабатывается единый план мероприятий только для объектов, находящихся на расстоянии менее 1 км друг от друга",
        ],
        correctAnswers: [
          "Разрабатывается единый план мероприятий для двух и более объектов",
        ],
      },
      {
        code: "20102004",
        text: "В каком случае эксплуатирующая организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах на несколько опасных объектов?",
        answers: [
          "В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
          "План мероприятий разрабатывается на каждый опасный объект отдельно",
          "В случае если это регламентировано внутренней документацией организации",
          "В случае если объекты зарегистрированы в государственном реестре опасных производственных объектов",
        ],
        correctAnswers: [
          "В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
        ],
      },
      {
        code: "20102005",
        text: "Каким образом необходимо разработать план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах в случае, если 2 и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?",
        answers: [
          "Допускается разработка единого плана мероприятий для 2 и более объектов",
          "Необходимо разработать планы мероприятий для каждого объекта отдельно",
          "Разрабатывается единый план мероприятий только для объектов, находящихся на расстоянии менее 100 м друг от друга",
          "Разрабатывается один план мероприятий для объектов, расположенных на одном участке, и несколько для объектов на смежных участках",
        ],
        correctAnswers: [
          "Допускается разработка единого плана мероприятий для 2 и более объектов",
        ],
      },
      {
        code: "20102006",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены на опасных производственных объектах II класса опасности (за исключением объектов, на которых ведутся горные работы)?",
        answers: ["5 лет", "1 год", "3 года", "2 года"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20102007",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов III класса опасности (за исключением объектов, на которых ведутся горные работы)?",
        answers: ["5 лет", "1 год", "2 года", "3 года"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20102008",
        text: "Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?",
        answers: [
          "Наименьший срок, предусмотренный для этих объектов",
          "Наибольший срок, предусмотренный для этих объектов",
          "Не более 3 лет",
          "Не более 5 лет",
        ],
        correctAnswers: ["Наименьший срок, предусмотренный для этих объектов"],
      },
      {
        code: "20102009",
        text: "В каком из перечисленных случаев план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах должен быть пересмотрен?",
        answers: [
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
          "Не менее чем за 30 календарных дней до истечения срока действия предыдущего плана мероприятий",
          "Не позднее 10 календарных дней после внесения изменений в системы управления технологическими процессами на объекте",
          "После назначения нового руководителя организации, эксплуатирующей объект",
        ],
        correctAnswers: [
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
        ],
      },
      {
        code: "20102010",
        text: "В каком из перечисленных случаев пересматриваются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.",
        answers: [
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
          "Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий",
          "В случае назначения нового руководителя организации",
          "Не позднее 40 календарных дней после внесения изменений в системы управления технологическими процессами на объекте",
          "Не позднее 15 календарных дней после внесения изменений в применяемые при осуществлении производственного контроля соблюдения требований промышленной безопасности на объекте методики (методы) измерений или типы средств измерений",
        ],
        correctAnswers: [
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
          "Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий",
        ],
      },
      {
        code: "20102011",
        text: "В течение какого срока после реконструкции или технического перевооружения объекта должен быть пересмотрен план мероприятий по локализации и ликвидации последствий аварий на ОПО?",
        answers: [
          "Не позднее 30 календарных дней",
          "Не позднее 45 календарных дней",
          "Не позднее 6 месяцев",
          "Не позднее 3 месяцев",
        ],
        correctAnswers: ["Не позднее 30 календарных дней"],
      },
      {
        code: "20102012",
        text: "В течение какого времени пересматривается план мероприятий по локализации и ликвидации последствий аварий после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства?",
        answers: [
          "Не позднее 30 календарных дней",
          "Не позднее 15 календарных дней",
          "Не позднее 10 календарных дней",
          "Не позднее 3 месяцев",
        ],
        correctAnswers: ["Не позднее 30 календарных дней"],
      },
      {
        code: "20102013",
        text: "В течение какого времени после внесения изменений в системы управления технологическими процессами на объекте должен быть пересмотрен план мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Не позднее 30 календарных дней",
          "Не позднее 3 месяцев",
          "Не позднее 6 месяцев",
          "Не позднее 1 года",
        ],
        correctAnswers: ["Не позднее 30 календарных дней"],
      },
      {
        code: "20102014",
        text: "Кем утверждаются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
          "Главными техническими специалистами организаций, эксплуатирующих объекты",
          "Должностным лицом территориального управления Ростехнадзора",
          "Специалистом военизированной газоспасательной службы",
        ],
        correctAnswers: [
          "Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
        ],
      },
      {
        code: "20102015",
        text: "Что из перечисленного должен предусматривать план мероприятий по локализации и ликвидации последствий аварий на ОПО?",
        answers: [
          "Только организацию материально-технического, инженерного и финансового обеспечения операций по локализации и ликвидации аварий на объекте",
          "Только сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий",
          "Только состав и дислокацию сил и средств",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20102016",
        text: "Какую информацию не включает в себя общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
          "Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий, а также источники (места) их возникновения",
          "Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и характеристики травматизма на таких объектах",
          "Характеристику объекта, в отношении которого разрабатывается план мероприятий",
        ],
        correctAnswers: [
          "Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
        ],
      },
      {
        code: "20102017",
        text: "Что из перечисленного должно быть предусмотрено планом мероприятий по локализации и ликвидации последствий аварий на ОПО?",
        answers: [
          "Только порядок обеспечения постоянной готовности сил и средств к локализации и ликвидации последствий аварий на объекте с указанием организаций, которые несут ответственность за поддержание этих сил и средств в установленной степени готовности",
          "Только система взаимного обмена информацией между организациями - участниками локализации и ликвидации последствий аварий на объекте",
          "Только организация материально-технического, инженерного и финансового обеспечения операций по локализации и ликвидации аварий на объекте",
          "Только первоочередные действия при получении сигнала об авариях на объекте",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20102018",
        text: "Что из перечисленного не включает в себя план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Организацию управления, связи и оповещения при аварии на объекте",
          "Мероприятия, направленные на обеспечение безопасности населения",
          "Порядок ремонтно-восстановительных работ, проводимых после полной ликвидации последствий аварии",
          "Состав и дислокацию сил и средств",
          "Организацию материально-технического, инженерного и финансового обеспечения операций по локализации и ликвидации аварий на объекте",
        ],
        correctAnswers: [
          "Порядок ремонтно-восстановительных работ, проводимых после полной ликвидации последствий аварии",
        ],
      },
      {
        code: "20102019",
        text: "Что из перечисленного не входит в общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Характеристика объекта, в отношении которого разрабатывается план мероприятий",
          "Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий, а также источники (места) их возникновения",
          "Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и характеристики травматизма на таких объектах",
          "Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
        ],
        correctAnswers: [
          "Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
        ],
      },
      {
        code: "20102020",
        text: "Что из перечисленного не предусматривает план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Порядок проведения восстановительных работ по окончании ликвидации аварии на объекте",
          "Состав и дислокацию сил и средств",
          "Организацию управления, связи и оповещения при аварии на объекте, первоочередные действия при получении сигнала об авариях на объекте",
          "Количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте",
          "Мероприятия, направленные на обеспечение безопасности населения, сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий",
          "Организацию взаимодействия сил и средств",
        ],
        correctAnswers: [
          "Порядок проведения восстановительных работ по окончании ликвидации аварии на объекте",
        ],
      },
      {
        code: "20102021",
        text: "Что из перечисленного не является обязательной составляющей плана мероприятий по локализации и ликвидации последствий аварий на ОПО?",
        answers: [
          "Результаты расчета риска возникновения аварий на опасном производственном объекте",
          "Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и характеристики травматизма на таких объектах",
          "Мероприятия, направленные на обеспечение безопасности населения (в случае если в результате аварий на объекте может возникнуть угроза безопасности населения)",
          "Характеристика объекта, в отношении которого разрабатывается план мероприятий",
        ],
        correctAnswers: [
          "Результаты расчета риска возникновения аварий на опасном производственном объекте",
        ],
      },
      {
        code: "20102022",
        text: "Что из перечисленного содержится в специальном разделе мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Порядок действий в случае аварий на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
          "Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и характеристики травматизма на таких объектах",
          "Характеристика объекта, в отношении которого разрабатывается план мероприятий",
          "Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий",
        ],
        correctAnswers: [
          "Порядок действий в случае аварий на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
        ],
      },
      {
        code: "20102023",
        text: "Что определяется в специальном разделе плана мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Порядок действий в случае аварий на объекте",
          "Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий",
          "Характеристика объекта, в отношении которого разрабатывается план мероприятий",
          "Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и характеристики травматизма на таких объектах",
        ],
        correctAnswers: ["Порядок действий в случае аварий на объекте"],
      },
      {
        code: "20102024",
        text: "Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?",
        answers: [
          "Энергией сгорания парогазовой фазы",
          "Категорией взрывоопасности технологических блоков",
          "Приведенной массой вещества, участвующего во взрыве",
          "Радиусом зон разрушения",
        ],
        correctAnswers: ["Категорией взрывоопасности технологических блоков"],
      },
      {
        code: "20102025",
        text: "Что является критерием взрывоопасности согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?",
        answers: [
          "Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом",
          "Класс опасности обращающихся в процессе веществ",
          "Температура самовоспламенения паров обращающихся в процессе веществ",
          "Скорость распространения горения обращающихся в процессе веществ",
        ],
        correctAnswers: [
          "Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом",
        ],
      },
      {
        code: "20102026",
        text: "На сколько категорий взрывоопасности подразделяются при проектировании технологические блоки взрывопожароопасных производств и объектов?",
        answers: [
          "На три категории",
          "На четыре категории",
          "На пять категорий",
          "На две категории",
        ],
        correctAnswers: ["На три категории"],
      },
      {
        code: "20102027",
        text: "Кто делает обоснование по применению эффективности и надежности мер и технических средств противоаварийной защиты, направленных на обеспечение взрывобезопасности отдельного блока и в целом всей технологической системы?",
        answers: [
          "Проектная организация",
          "Эксплуатирующая организация",
          "Организация, проводящая экспертизу промышленной безопасности опасных производственных объектов",
          "Строительная организация",
        ],
        correctAnswers: ["Проектная организация"],
      },
      {
        code: "20102028",
        text: "В соответствии с чем осуществляется ведение технологических процессов на опасных производственных объектах нефтехимических и нефтегазоперерабатывающих производств?",
        answers: [
          "В соответствии с технологическими регламентами на производство продукции",
          "В соответствии с распоряжениями руководителя эксплуатирующей организации",
          "В соответствии с заключением экспертизы промышленной безопасности",
          "В соответствии с инструкциями",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами на производство продукции",
        ],
      },
      {
        code: "20102029",
        text: "Какая из перечисленных мер должна предусматриваться для блоков технологической системы по максимальному снижению взрывоопасности?",
        answers: [
          "Предотвращение взрывов внутри технологического оборудования",
          "Повышение выбросов горючих веществ в атмосферу при аварийной разгерметизации",
          "Использование вентиляционных систем для исключения возможности взрывов и пожаров",
          "Разрушение производственных зданий и сооружений при взрывах оборудования",
        ],
        correctAnswers: [
          "Предотвращение взрывов внутри технологического оборудования",
        ],
      },
      {
        code: "20102030",
        text: "В какой документации приводятся способы и средства, исключающие выход параметров за установленные пределы?",
        answers: [
          "В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции",
          "Только в исходных данных на проектирование и технологическом регламенте на производство продукции",
          "Только в технологическом регламенте на производство продукции",
          "Только в проектной документации",
        ],
        correctAnswers: [
          "В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20102031",
        text: "Каким образом осуществляется управление подачей инертных сред на установку с технологическими блоками любой категории взрывоопасности там, где при отклонении от регламентированных значений параметров возможно образование взрывоопасных смесей?",
        answers: [
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Qв ≤ 10 - управление ручное, дистанционное",
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту",
          "Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, для установок с технологическими блоками II категории взрывоопасности - ручное, дистанционное управление, для установок с технологическими блоками III категории взрывоопасности допускается ручное управление по месту",
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление",
        ],
        correctAnswers: [
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту",
        ],
      },
      {
        code: "20102032",
        text: "Какое управление системами подачи инертных сред в технологические системы должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категории взрывоопасности?",
        answers: [
          "Автоматическое",
          "Дистанционное, неавтоматическое",
          "Ручное управление по месту",
        ],
        correctAnswers: ["Автоматическое"],
      },
      {
        code: "20102033",
        text: "В течение какого времени средства обеспечения энергоустойчивости технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?",
        answers: [
          "В течение времени, достаточного для исключения опасной ситуации",
          "В течение 2 часов",
          "В течение 4 часов",
          "В течение 8 часов",
        ],
        correctAnswers: [
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
      },
      {
        code: "20102034",
        text: "Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?",
        answers: [
          "Системами ручного (без применения вычислительной техники) регулирования",
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
          "Эффективными быстродействующими системами, обеспечивающими непрерывность технологического процесса",
        ],
        correctAnswers: [
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
        ],
      },
      {
        code: "20102035",
        text: "Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?",
        answers: [
          "Время срабатывания устанавливается для каждого технологического блока в соответствии с категорией взрывоопасности",
          "Время срабатывания определяется расчетом",
          "Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и устанавливается для блоков III категории",
          "Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и устанавливается для блоков I и II категорий",
        ],
        correctAnswers: ["Время срабатывания определяется расчетом"],
      },
      {
        code: "20102036",
        text: "В зависимости от чего осуществляется выбор конструкции и конструкционных материалов, уплотнительных устройств для насосов и компрессоров?",
        answers: [
          "В зависимости от свойств перемещаемой среды",
          "В зависимости от протяженности трубопровода и его конструктивных особенностей",
          "В зависимости от вида взрывозащиты",
          "В зависимости от всех перечисленных факторов",
        ],
        correctAnswers: ["В зависимости от свойств перемещаемой среды"],
      },
      {
        code: "20102037",
        text: "Какими приборами и средствами автоматизации оснащаются сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Выберите два правильных варианта ответа.",
        answers: [
          "Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации",
          "Блокировками отключения компрессора при превышении предельно допустимого значения уровня",
          "Приборами измерения перепада давления на сепараторе",
          "Приборами контроля температуры и плотности отделяемой жидкой фазы",
        ],
        correctAnswers: [
          "Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации",
          "Блокировками отключения компрессора при превышении предельно допустимого значения уровня",
        ],
      },
      {
        code: "20102038",
        text: "Какими блокировками на отключение должны быть оснащены насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?",
        answers: [
          "Исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях",
          "Исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости в корпусе насоса, достижении опасных значений в приемной емкости",
          "Исключающими пуск и (или) прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях",
          "Исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
        correctAnswers: [
          "Исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
      },
      {
        code: "20102039",
        text: "В массообменных процессах при отклонении технологических параметров от регламентированных значений возможно образование неустойчивых взрывоопасных соединений. Как в таком случае должно осуществляться регулирование этих параметров?",
        answers: [
          "Для установок с технологическими блоками I категории взрывоопасности - автоматически, с технологическими блоками II категории взрывоопасности - ручным дистанционным управлением, с технологическими блоками III категории взрывоопасности допускается ручное по месту",
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматически, для установок III категории взрывоопасности допускается управление вручную при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений",
          "Для установок с технологическими блоками I категории взрывоопасности - автоматически, с технологическими блоками II категории взрывоопасности - ручным дистанционным управлением при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений, с технологическими блоками III категории взрывоопасности допускается ручное по месту",
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматически, а при Qв ≤ 10 допускается ручное дистанционное управление",
        ],
        correctAnswers: [
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматически, для установок III категории взрывоопасности допускается управление вручную при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений",
        ],
      },
      {
        code: "20102040",
        text: "Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?",
        answers: [
          "На установках с технологическими блоками I категории взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками II и III категорий взрывоопасности - не регламентируется",
          "На установках с технологическими блоками I и II категорий взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками III категории взрывоопасности - не регламентируется",
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
          "Давление теплоносителя (хладагента) не должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
        ],
        correctAnswers: [
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
        ],
      },
      {
        code: "20102041",
        text: "Для каких целей печи с открытым огневым процессом должны быть оборудованы паровой завесой?",
        answers: [
          "Для изоляции печи от взрывоопасной среды при авариях на наружных установках или в зданиях печи",
          "Для локализации пожара на печи при установке ее внутри помещений",
          "Для локализации пожара на печи при установке ее вне зданий",
          "Для пожаротушения при аварии на печи",
        ],
        correctAnswers: [
          "Для изоляции печи от взрывоопасной среды при авариях на наружных установках или в зданиях печи",
        ],
      },
      {
        code: "20102042",
        text: "Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?",
        answers: [
          "Заказчиком в задании на проектирование",
          "Разработчиком процесса",
          "Разработчиком проекта",
          "Эксплуатирующей организацией",
        ],
        correctAnswers: ["Разработчиком процесса"],
      },
      {
        code: "20102043",
        text: "Кто определяет выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?",
        answers: [
          "Разработчик процесса",
          "Руководитель, утверждающий регламент работ",
          "Заказчик в техническом задании на проектирование",
          "Все перечисленные",
        ],
        correctAnswers: ["Разработчик процесса"],
      },
      {
        code: "20102044",
        text: "Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся жидкости и горючие жидкости на сливоналивных эстакадах?",
        answers: [
          "Должно быть организовано управление только по месту",
          "Должно быть организовано управление только дистанционно (из безопасного места)",
          "Должно быть организовано управление по месту и дистанционно (из безопасного места)",
          "Должно быть определено разработчиком проекта",
        ],
        correctAnswers: [
          "Должно быть организовано управление по месту и дистанционно (из безопасного места)",
        ],
      },
      {
        code: "20102045",
        text: "Какие сведения являются основополагающими для выбора оборудования при разработке технологических процессов?",
        answers: [
          "Расчетные данные, которым должны соответствовать параметры оборудования и показатели надежности",
          "Расчетные данные, которым должны соответствовать параметры оборудования и требования действующих нормативных документов",
          "Расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование и требования действующих нормативных документов",
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
        correctAnswers: [
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
      },
      {
        code: "20102046",
        text: "Чем подтверждаются эффективность и надежность средств взрывозащиты и локализации пламени и других противоаварийных устройств, направленных на взрывозащищенность оборудования?",
        answers: [
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
          "Оценкой научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования",
          "Опытными работами, проводимыми разработчиком этого оборудования, и экспертизой промышленной безопасности",
          "Заключением научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования",
        ],
        correctAnswers: [
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
        ],
      },
      {
        code: "20102047",
        text: "Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?",
        answers: [
          "Оборудование должно быть изолировано от действующей системы, нанесенное на нем обозначение номера по технологической схеме должно быть закрашено",
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем",
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно должно быть изолировано от действующих систем",
          "Оборудование должно быть демонтировано",
        ],
        correctAnswers: [
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем",
        ],
      },
      {
        code: "20102048",
        text: "Что из перечисленного должно быть учтено при выборе компрессоров и насосов для перемещения горючих, сжатых и сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?",
        answers: [
          "Только физико-химические свойства перемещаемых продуктов",
          "Только регламентированные параметры технологического процесса",
          "Только показатели надежности и конструктивные особенности с учетом критических параметров",
          "Должны быть учтены все перечисленные характеристики",
        ],
        correctAnswers: ["Должны быть учтены все перечисленные характеристики"],
      },
      {
        code: "20102049",
        text: "В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?",
        answers: [
          "При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации",
          "При разрешении технического руководителя",
          "При проведении экспертизы промышленной безопасности",
          "При наличии блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях",
        ],
        correctAnswers: [
          "При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации",
        ],
      },
      {
        code: "20102050",
        text: "В каких местах не допускается размещать фланцевые соединения трубопроводов с взрывопожароопасными, токсичными и едкими веществами?",
        answers: [
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
          "В местах установки арматуры",
          "В местах подсоединения трубопроводов к аппаратам",
          "В местах, доступных для обслуживания персоналом",
        ],
        correctAnswers: [
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
        ],
      },
      {
        code: "20102051",
        text: "В каких случаях на трубопроводах следует применять арматуру под приварку?",
        answers: [
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков любой категории взрывоопасности",
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения, при регламентированном давлении",
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I и II категорий взрывоопасности с температурой, равной температуре кипения, при регламентированном давлении",
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 3,5 МПа",
        ],
        correctAnswers: [
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения, при регламентированном давлении",
        ],
      },
      {
        code: "20102052",
        text: "Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития?",
        answers: [
          "Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
          "Измерительные преобразователи, блоки нормирования и усиления сигналов",
          "Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы",
          "Запорную арматуру, средства защиты от превышения давления, огнепреградители",
        ],
        correctAnswers: [
          "Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20102053",
        text: "Что в технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?",
        answers: [
          "Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы",
          "Запорная арматура, средства защиты от превышения давления, огнепреградители",
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
          "Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20102054",
        text: "Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предотвращения аварий и предупреждения их развития?",
        answers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
          "Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов",
          "Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы",
          "Запорная арматура, средства защиты от превышения давления, огнепреградители",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20102055",
        text: "Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Средства автоматики должны быть обозначены по месту их размещения в технологическом регламенте и инструкциях",
          "Средства автоматики должны быть обозначены на мнемосхемах",
          "Средства автоматики должны быть обозначены только в технологическом регламенте",
          "Требования к обозначению средств автоматики не регламентируются",
        ],
        correctAnswers: [
          "Средства автоматики должны быть обозначены по месту их размещения в технологическом регламенте и инструкциях",
        ],
      },
      {
        code: "20102056",
        text: "Где приводятся конкретные значения уставок систем защиты по опасным параметрам?",
        answers: [
          "Только в проектной документации",
          "Только в технологическом регламенте на производство продукции",
          "В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции",
        ],
        correctAnswers: [
          "В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20102057",
        text: "Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?",
        answers: [
          "Средствами автоматического управления объектом по действующим программам",
          "Выполняется обслуживающим персоналом по инструкции",
          "Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений",
          "Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию системы противоаварийной защиты",
        ],
        correctAnswers: ["Выполняется обслуживающим персоналом по инструкции"],
      },
      {
        code: "20102058",
        text: "Каким образом обеспечивается надежность обеспечения средств управления и систем противоаварийной защиты сжатым воздухом?",
        answers: [
          "Установкой резервного компрессора с его автоматическим включением при остановке рабочего",
          "Переключением сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования на заводскую сеть сжатого воздуха через осушитель",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
        correctAnswers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20102059",
        text: "Каким образом обеспечивается надежность обеспечения средств управления и системы противоаварийной защиты сжатым воздухом?",
        answers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа",
          "Переключением сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования на заводскую сеть сжатого воздуха через осушитель",
          "Установкой резервного компрессора с включением его автоматически при остановке рабочего",
        ],
        correctAnswers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20102060",
        text: "В течение какого времени буферные емкости (реципиенты) должны обеспечивать питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров?",
        answers: [
          "В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
          "В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено опытным путем",
          "В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено экспериментом",
          "В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено теоретическими данными",
        ],
        correctAnswers: [
          "В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20102061",
        text: "Какое из перечисленных положений не соответствует требованиям по размещению и устройству помещений управления взрывоопасных производств?",
        answers: [
          "Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами",
          "В помещениях не должны размещаться оборудование и другие устройства, не связанные с системой управления технологическим процессом",
          "Помещения управления должны быть отдельно стоящими",
          "В отдельных случаях при соответствующем обосновании в проекте разрешается пристраивать помещения управления к зданиям",
        ],
        correctAnswers: [
          "Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами",
        ],
      },
      {
        code: "20102062",
        text: "При каких условиях допускается отключение защит (единовременно не более одного параметра) для непрерывных процессов?",
        answers: [
          "При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами",
          "При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, и в присутствии начальника производства по устному разрешению технического руководителя организации только в дневную смену",
          "При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в присутствии начальника производства и начальника службы контрольно-измерительных приборов и автоматики (главного прибориста) только в дневную смену",
          "При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в присутствии технического руководителя предприятия",
        ],
        correctAnswers: [
          "При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами",
        ],
      },
      {
        code: "20102063",
        text: "Что должно быть учтено в системах управления и защит электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?",
        answers: [
          "Должен быть автоматический ввод резерва между каждым из трех самостоятельных источников электроснабжения",
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
          "Должна быть обеспечена селективность защит на устройствах электроснабжающей и электропотребляющей организаций",
          "Должна быть обеспечена возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой",
        ],
        correctAnswers: [
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
        ],
      },
      {
        code: "20102064",
        text: "Какое положение не соответствует установленным требованиям к электрообеспечению и электрооборудованию взрывоопасных технологических схем?",
        answers: [
          "Запрещается прокладка кабелей в каналах, засыпанных песком, и траншеях",
          "Прокладку кабелей по территории предприятий и установок разрешается выполнять открыто: по эстакадам, в галереях и на кабельных конструкциях технологических эстакад",
          "Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов в соответствии с требованиями нормативно-технических документов по устройству электроустановок",
          "Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение",
        ],
        correctAnswers: [
          "Запрещается прокладка кабелей в каналах, засыпанных песком, и траншеях",
        ],
      },
      {
        code: "20102065",
        text: 'Какое требование к системам вентиляции не соответствует ФНП "Общие правила взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств"?',
        answers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
          "Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания",
          "Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства",
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: "20102066",
        text: "Какое требование к системам вентиляции указано неверно?",
        answers: [
          "Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания",
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
          "Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства",
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: "20102067",
        text: "Какая система отопления должна применяться в помещениях, имеющих взрывоопасные зоны?",
        answers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
          "Система водяного отопления",
          "Система парового отопления",
        ],
        correctAnswers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
        ],
      },
      {
        code: "20102068",
        text: "Каков порядок сброса химически загрязненных стоков от отдельных технологических объектов в магистральную сеть канализации?",
        answers: [
          "Порядок сброса стоков в магистральную сеть канализации устанавливается организацией",
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны быть оборудованы устройствами для улавливания аварийных стоков",
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
          "Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть",
        ],
        correctAnswers: [
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
        ],
      },
      {
        code: "20102069",
        text: "Что из перечисленного является недопустимым на территории предприятия, имеющего в своем составе взрывопожароопасные производства?",
        answers: [
          "Только наличие природных оврагов, выемок, низин",
          "Только устройство открытых траншей, котлованов, приямков, в которых возможно скопление взрывопожароопасных паров и газов",
          "Только траншейная и наземная прокладка трасс трубопроводов с сжиженными горючими газами, легковоспламеняющимися и горючими жидкостями в искусственных или естественных углублениях",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20102070",
        text: "Какие требования должны быть выполнены для вновь проектируемых взрывопожароопасных и химически опасных объектов? Выберите два правильных варианта ответа.",
        answers: [
          "Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия",
          "Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов",
          "Окна зданий должны быть оборудованы жалюзи из прочных материалов",
          "Вокруг зданий должна предусматриваться вспаханная полоса земли шириной не менее 3 м",
        ],
        correctAnswers: [
          "Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия",
          "Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов",
        ],
      },
      {
        code: "20102071",
        text: "На какие опасные производственные объекты не распространяются Правила промышленной безопасности складов нефти и нефтепродуктов?",
        answers: [
          "На объекты хранения нефтепродуктов с упругостью паров выше 93,3 кПа",
          "На товарно-сырьевые парки центральных пунктов сбора нефтяных месторождений",
          "На наливные станции магистральных нефтепроводов и нефтепродуктопроводов",
          "На объекты, предназначенные для приема и выдачи нефти и нефтепродуктов",
        ],
        correctAnswers: [
          "На объекты хранения нефтепродуктов с упругостью паров выше 93,3 кПа",
        ],
      },
      {
        code: "20102072",
        text: "В каком случае допускается торможение цистерн башмаками, изготовленными из материала, дающего искрение, на участках слива-налива?",
        answers: [
          "Если это разрешается внутренними регламентами",
          "Если цистерны оборудованы специальными устройствами",
          "Если прием и отгрузка нефти и нефтепродуктов осуществляется через специально оборудованные сливоналивные устройства",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20102073",
        text: "В каких документах должны быть обоснованы технические решения по герметизации налива нефтепродуктов в железнодорожные цистерны?",
        answers: [
          "В проектной документации",
          "В инструкциях по охране труда",
          "В технологическом регламенте",
          "В положении о производственном контроле",
        ],
        correctAnswers: ["В проектной документации"],
      },
      {
        code: "20102074",
        text: "Какие нефтепродукты допускается сливать через герметичные верхние сливные устройства?",
        answers: [
          "Светлые нефтепродукты",
          "Авиационное топливо",
          "Мазут",
          "Дистиллятные масла",
        ],
        correctAnswers: ["Светлые нефтепродукты"],
      },
      {
        code: "20102075",
        text: "Каким должно быть покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков, а также для смыва пролитых нефтепродуктов?",
        answers: ["Бетонным", "Асфальтированным", "Металлическим", "Глиняным"],
        correctAnswers: ["Бетонным"],
      },
      {
        code: "20102076",
        text: "Какие минимальные уклоны для стока жидкости к приемным устройствам (лоткам, колодцам, приямкам) должно иметь покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков?",
        answers: ["2 %", "3 %", "4 %", "5 %"],
        correctAnswers: ["2 %"],
      },
      {
        code: "20102077",
        text: "Бортиками какой высоты должно ограждаться по периметру покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков?",
        answers: [
          "Не менее 0,2 м",
          "Не менее 0,3 м",
          "Не менее 0,5 м",
          "Не менее 0,6 м",
        ],
        correctAnswers: ["Не менее 0,2 м"],
      },
      {
        code: "20102078",
        text: "В каком из перечисленных случаев должны срабатывать быстродействующие отключающие системы (автоматические устройства) на сливоналивных эстакадах?",
        answers: [
          "Только в случае выдачи заданной нормы нефти и нефтепродуктов",
          "Только в случае достижения предельного уровня заполнения железнодорожной цистерны",
          "Только в случае нарушения целостности цепи заземления железнодорожной цистерны",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20102079",
        text: "Какой документацией обосновывается максимальная безопасная скорость налива нефти и нефтепродуктов на железнодорожных сливоналивных эстакадах?",
        answers: [
          "Проектной документацией",
          "Технологическим регламентом",
          "Техническим регламентом",
          "Эксплуатационной документацией",
        ],
        correctAnswers: ["Проектной документацией"],
      },
      {
        code: "20102080",
        text: "Каким образом должно обеспечиваться ограничение максимальной скорости налива нефти и нефтепродуктов до безопасных пределов на железнодорожных сливоналивных эстакадах?",
        answers: [
          "Только установкой частотно-регулируемого электропривода насоса",
          "Только применением запорно-регулирующей арматуры на линии подачи нефти или нефтепродукта к железнодорожной эстакаде",
          "Только перепуском части продукта во всасывающий трубопровод насоса",
          "Любым из перечисленных способов",
        ],
        correctAnswers: ["Любым из перечисленных способов"],
      },
      {
        code: "20102081",
        text: "Каким образом не должен производиться разогрев застывающих и высоковязких нефтепродуктов в железнодорожных цистернах, сливоналивных устройствах?",
        answers: [
          "Горячей водой",
          "Паром",
          "Электроподогревом не выше 90 °C",
          "Нефтепродуктом, нагретым циркуляционным способом",
        ],
        correctAnswers: ["Горячей водой"],
      },
      {
        code: "20102082",
        text: "В каком случае не допускается применение электроподогрева при проведении сливоналивных операций нефтепродуктов?",
        answers: [
          "При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 61 °C",
          "При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 65 °C",
          "При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 71 °C",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 61 °C",
        ],
      },
      {
        code: "20102083",
        text: "Какое из перечисленных требований к использованию переносных подогревателей на железнодорожных сливоналивных эстакадах указано неверно?",
        answers: [
          "При использовании переносных подогревателей допускается непосредственный непродолжительный контакт теплоносителя с нефтепродуктом",
          "Разогрев нефтепродуктов в железнодорожных цистернах переносными электрическими подогревателями должен производиться только в сочетании с циркуляционным нагревом в выносном подогревателе (теплообменнике)",
          "При использовании переносных электрических подогревателей последние должны быть оснащены блокировочными устройствами, отключающими их при снижении уровня жидкости над нагревательным устройством ниже 500 мм",
          "При использовании переносных пароподогревателей давление пара в подогревателе должно соответствовать показателям, установленным в технической документации (паспорте) пароподогревателя",
        ],
        correctAnswers: [
          "При использовании переносных подогревателей допускается непосредственный непродолжительный контакт теплоносителя с нефтепродуктом",
        ],
      },
      {
        code: "20102084",
        text: "На какую минимальную глубину от уровня верхней кромки подогревателя должны погружаться в нефтепродукт переносные паровые змеевики и переносные электрические подогреватели?",
        answers: ["500 мм", "300 мм", "600 мм", "800 мм"],
        correctAnswers: ["500 мм"],
      },
      {
        code: "20102085",
        text: "Какой длины должно быть наливное устройство во избежание налива нефти и нефтепродуктов свободно падающей струей?",
        answers: [
          "Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 200 мм",
          "Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 250 мм",
          "Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 300 мм",
        ],
        correctAnswers: [
          "Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 200 мм",
        ],
      },
      {
        code: "20102086",
        text: "При каком уровне загазованности воздушной среды должен автоматически прекращаться слив и налив нефти и светлых нефтепродуктов на сливоналивных железнодорожных эстакадах?",
        answers: [
          "50 % объемных от нижнего концентрационного предела распространения пламени",
          "30 % объемных от нижнего концентрационного предела распространения пламени",
          "20 % объемных от нижнего концентрационного предела распространения пламени",
        ],
        correctAnswers: [
          "50 % объемных от нижнего концентрационного предела распространения пламени",
        ],
      },
      {
        code: "20102087",
        text: "Для налива каких ЛВЖ сливоналивные устройства должны снабжаться устройствами отвода паров?",
        answers: [
          "С упругостью паров от 66,65 кПа",
          "С упругостью паров от 56,65 кПа",
          "С упругостью паров от 46,65 кПа",
          "С упругостью паров от 42,65 кПа",
        ],
        correctAnswers: ["С упругостью паров от 66,65 кПа"],
      },
      {
        code: "20102088",
        text: "При каких условиях допускается налив нефтепродуктов в автомобильные цистерны с применением гибких шлангов?",
        answers: [
          "При обосновании в проектной документации",
          "При согласовании с территориальными органами Ростехнадзора",
          "При разрешении руководителя организации",
        ],
        correctAnswers: ["При обосновании в проектной документации"],
      },
      {
        code: "20102089",
        text: "Какое из перечисленных требований допускается к автомобильным сливоналивным станциям?",
        answers: [
          "Применение гибких шлангов для налива при обосновании в проектной документации",
          "Нахождение водителей автомобильных цистерн, выполняющих операции слива-налива нефтепродуктов, в одежде, способной накапливать заряды статического электричества",
          "Запуск двигателей автомобильных цистерн, находящихся на площадке, в случаях пролива нефтепродукта",
          "Эксплуатация сливоналивных устройств при обнаружении нарушения целостности единого контура заземления при обосновании в проектной документации",
        ],
        correctAnswers: [
          "Применение гибких шлангов для налива при обосновании в проектной документации",
        ],
      },
      {
        code: "20102090",
        text: "С какой периодичностью следует визуально проверять целостность заземления элементов сливоналивных устройств, соединенных шарнирами с сальниковыми уплотнениями, изготовленными из неметаллических материалов, с регистрацией (записью) результатов осмотра в журнале приема-передачи смены?",
        answers: [
          "Каждую смену",
          "1 раз в неделю",
          "1 раз в месяц",
          "1 раз в квартал",
        ],
        correctAnswers: ["Каждую смену"],
      },
      {
        code: "20102091",
        text: "При каком минимальном превышении концентрации паров нефтепродуктов на площадках сливоналивных станций и пунктов слива-налива должны быть установлены блокировки по прекращению операций слива-налива и сигнализация, оповещающая о запрете запуска двигателей автомобилей?",
        answers: [
          "При превышении концентрации паров более 20 %",
          "При превышении концентрации паров более 30 %",
          "При превышении концентрации паров более 35 %",
        ],
        correctAnswers: ["При превышении концентрации паров более 20 %"],
      },
      {
        code: "20102092",
        text: "В соответствии с какими нормами и требованиями должны быть обустроены сливоналивные причалы для осуществления операций с нефтью и нефтепродуктами?",
        answers: [
          "В соответствии с нормами технологического проектирования морских и речных портов и требованиями законодательства в области промышленной безопасности",
          "В соответствии с требованиями градостроительного законодательства",
          "В соответствии с нормами морского регистра и требованиями законодательства по перевозке опасных грузов",
        ],
        correctAnswers: [
          "В соответствии с нормами технологического проектирования морских и речных портов и требованиями законодательства в области промышленной безопасности",
        ],
      },
      {
        code: "20102093",
        text: "В какой документации устанавливаются места установки приборов, их количество и параметры контроля процесса перекачки нефти и нефтепродукта по трубопроводу у насосной станции и стендеров?",
        answers: [
          "В проектной документации",
          "В декларации промышленной безопасности",
          "В техническом регламенте",
          "В любой перечисленной документации",
        ],
        correctAnswers: ["В проектной документации"],
      },
      {
        code: "20102094",
        text: "Каким способом срабатывает система аварийного разъединения стендеров для предотвращения пролива нефтепродуктов?",
        answers: [
          "Только автоматически, когда стендер достигает обусловленного граничного положения",
          "Только дистанционно с учетом нажатия кнопки на центральном пульте управления",
          "Только вручную посредством управления гидравлическими клапанами в случае прекращения подачи электроэнергии на терминал",
          "Всеми перечисленными способами",
        ],
        correctAnswers: ["Всеми перечисленными способами"],
      },
      {
        code: "20102095",
        text: "Кто утверждает график проведения периодической проверки стендеров?",
        answers: [
          "Эксплуатирующая организация",
          "Проектная организация",
          "Организация-изготовитель",
          "Организация, проводящая проверку стендеров",
        ],
        correctAnswers: ["Эксплуатирующая организация"],
      },
      {
        code: "20102096",
        text: "В каком случае автоматические предохранительные клапаны должны быть установлены на причале, чтобы исключить возможное повышение давления потоком нефти и нефтепродукта?",
        answers: [
          "При расположении береговых насосов более чем в 100 м от причала",
          "При обосновании в проектной документации",
          "При расположении береговых насосов более чем в 50 м от причала",
          "В любом из перечисленных случаев",
        ],
        correctAnswers: [
          "При расположении береговых насосов более чем в 100 м от причала",
        ],
      },
      {
        code: "20102097",
        text: "В каком случае автоматические предохранительные клапаны должны быть установлены на причале, чтобы исключить возможное повышение давления потоком нефти и нефтепродукта?",
        answers: [
          "При расположении береговых насосов более чем в 100 м от причала",
          "При обосновании в рабочей документации",
          "При расположении береговых насосов более чем в 40 м от причала",
          "Не регламентируется",
        ],
        correctAnswers: [
          "При расположении береговых насосов более чем в 100 м от причала",
        ],
      },
      {
        code: "20102098",
        text: "Что не должно учитываться при выборе шлангов для обеспечения безопасности грузовых (сливоналивных) операций?",
        answers: [
          "Требуемая скорость перемещения нефтепродукта",
          "Физико-химические свойства перемещаемой среды",
          "Параметры давления и температура перемещаемых веществ",
          "Размер судового трубопровода",
        ],
        correctAnswers: ["Требуемая скорость перемещения нефтепродукта"],
      },
      {
        code: "20102099",
        text: "Какие из перечисленных данных по каждому шлангу не должны иметь лица, ответственные за проведение сливоналивных операций с нефтепродуктами?",
        answers: [
          "Дата следующего испытания с указанием необходимого давления, при котором он должен испытываться",
          "Значение величины рабочего давления",
          "Название нефтепродукта, для которого он предназначен",
          "Значение величины разрывного давления",
        ],
        correctAnswers: [
          "Дата следующего испытания с указанием необходимого давления, при котором он должен испытываться",
        ],
      },
      {
        code: "20102100",
        text: "Каким давлением испытываются грузовые шланги, находящиеся в эксплуатации, на сливоналивных причалах для выявления утечки содержимого шланга или смещения его концевых соединительных устройств?",
        answers: [
          "Давлением, значение которого составляет 1,5 номинального рабочего давления",
          "Давлением, значение которого составляет 2,0 номинального рабочего давления",
          "Давлением, значение которого составляет 2,5 номинального рабочего давления",
          "Номинальным рабочим давлением",
        ],
        correctAnswers: [
          "Давлением, значение которого составляет 1,5 номинального рабочего давления",
        ],
      },
      {
        code: "20102101",
        text: "При какой минимальной скорости ветра запрещается проведение сливоналивных операций с легковоспламеняющимися жидкостями?",
        answers: [
          "Более 15 м/с",
          "Более 20 м/с",
          "Более 12 м/с",
          "Более 17 м/с",
        ],
        correctAnswers: ["Более 15 м/с"],
      },
      {
        code: "20102102",
        text: "Какое из нижеуказанных свойств, показателей или условий не влияет на выбор типа резервуара для хранения нефти и нефтепродуктов?",
        answers: [
          "Годовое число циклов заполнений-опорожнений резервуара",
          "Показатели взрывоопасности хранимых нефтепродуктов",
          "Физико-химические свойства хранимых нефтепродуктов",
        ],
        correctAnswers: [
          "Годовое число циклов заполнений-опорожнений резервуара",
        ],
      },
      {
        code: "20102103",
        text: "Какое из перечисленных требований к резервуарам для хранения нефти и нефтепродуктов указано верно?",
        answers: [
          "Полный комплект устанавливаемых на резервуаре устройств и оборудования и схема их расположения обосновываются в проектной документации",
          "При обнаружении нарушения герметичности основного стального резервуара с защитной стенкой необходимо немедленно устранить причину утечки продукта",
          "При оснащении резервуарных парков газоуравнительной системой допускается объединять ею резервуары с авиационными и автомобильными бензинами",
          "Допускается превышение производительности наполнения (опорожнения) резервуаров над суммарной пропускной способностью установленных на резервуаре дыхательных и предохранительных устройств, если это обусловлено в проектной документации",
        ],
        correctAnswers: [
          "Полный комплект устанавливаемых на резервуаре устройств и оборудования и схема их расположения обосновываются в проектной документации",
        ],
      },
      {
        code: "20102104",
        text: "Какая допускается скорость понтона (плавающей крыши) резервуаров при сдвиге?",
        answers: [
          "Не более 2,5 м/ч",
          "Не более 2,0 м/ч",
          "Не более 3,5 м/ч",
          "Не более 3,0 м/ч",
        ],
        correctAnswers: ["Не более 2,5 м/ч"],
      },
      {
        code: "20102105",
        text: "Какое значение не должна превышать скорость движения понтона (плавающей крыши) для резервуаров емкостью до 30 000 м³?",
        answers: ["6 м/ч", "2 м/ч", "4 м/ч", "8 м/ч"],
        correctAnswers: ["6 м/ч"],
      },
      {
        code: "20102106",
        text: "Что из перечисленного допускается в отношении резервуарных парков?",
        answers: [
          "Наличие подтоварной воды ниже минимального уровня, обеспечиваемого устройством для дренажа воды, при хранении нефтепродуктов в резервуарах",
          "Отбор проб светлых нефтепродуктов во время закачки или откачки продукта",
          "Ручной отбор проб светлых нефтепродуктов через люк на крыше резервуара",
          "Все перечисленное не допускается",
        ],
        correctAnswers: ["Все перечисленное не допускается"],
      },
      {
        code: "20102107",
        text: "В каком случае допускается ручной отбор проб светлых нефтепродуктов через люк на крыше резервуара?",
        answers: [
          "В случае коммерческого учета нефти и нефтепродуктов, хранящихся в резервуарах",
          "В случае вновь строящихся и реконструируемых резервуаров",
          "В случае определения качества нефти и нефтепродуктов, хранящихся в резервуарах",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20102108",
        text: "Какие требования предъявляются к температуре подогрева мазута в резервуарах?",
        answers: [
          "Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 90 °C",
          "Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 120 °C",
          "Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 20 °C и не должна превышать 80 °C",
        ],
        correctAnswers: [
          "Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 90 °C",
        ],
      },
      {
        code: "20102109",
        text: "Какое утверждение, относящееся к резервуарным паркам для нефти и нефтепродуктов, указано неверно?",
        answers: [
          "Транзитная прокладка электрокабельных линий производится внутри обвалования резервуаров",
          "Применение арматуры с дистанционным управлением (электро-, пневмо- или гидроприводной) определяется условиями технологического процесса перекачки с обоснованием в проектной документации",
          "Запорные устройства, установленные непосредственно у резервуара, должны дублироваться установкой запорных устройств на технологических трубопроводах вне обвалования",
          "Для вновь проектируемых резервуаров управление приводами запорной арматуры должно быть дистанционным из операторной и по месту ее установки",
        ],
        correctAnswers: [
          "Транзитная прокладка электрокабельных линий производится внутри обвалования резервуаров",
        ],
      },
      {
        code: "20102110",
        text: "На каком минимальном расстоянии от резервуаров устанавливаются прожекторные мачты вне обвалования или ограждающих стен?",
        answers: ["10 м", "15 м", "12 м", "8 м", "7 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "20102111",
        text: "До какого момента при заполнении порожнего резервуара должны подаваться нефть или нефтепродукты со скоростью не более 1 м/с?",
        answers: [
          "До момента заполнения приемного патрубка или до всплытия понтона (плавающей крыши)",
          "До момента заполнения резервуара до верхней проектной отметки",
          "До момента срабатывания блокировки ограничения скорости заполнения резервуара",
        ],
        correctAnswers: [
          "До момента заполнения приемного патрубка или до всплытия понтона (плавающей крыши)",
        ],
      },
      {
        code: "20102112",
        text: "Где допускается осуществлять затаривание и расфасовку нефтепродуктов (масел, смазок) в бочки и мелкую тару?",
        answers: [
          "В пунктах разлива и фасовки нефтепродуктов",
          "В складских помещениях для хранения нефтепродуктов в таре",
          "Только на открытых площадках",
          "В цехе по производству нефтепродуктов",
        ],
        correctAnswers: ["В пунктах разлива и фасовки нефтепродуктов"],
      },
      {
        code: "20102113",
        text: "На каком расстоянии от сплошной (без проемов) стены помещения пунктов разлива и фасовки размещаются раздаточные резервуары с нефтепродуктами единичной вместимостью до 25 м³ включительно при общей вместимости до 200 м³ в зависимости от вида отпускаемых нефтепродуктов?",
        answers: ["2 м", "3 м", "1,5 м", "3,5 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "20102114",
        text: "В соответствии с требованиями какой документации проводятся все технологические операции по приему, хранению и разливу нефтепродуктов в тару? Укажите все правильные ответы.",
        answers: [
          "Производственных инструкций, утвержденных эксплуатирующей организацией",
          "Правил промышленной безопасности складов нефти и нефтепродуктов",
          "Технических регламентов",
          "Проектной документации",
        ],
        correctAnswers: [
          "Производственных инструкций, утвержденных эксплуатирующей организацией",
          "Правил промышленной безопасности складов нефти и нефтепродуктов",
        ],
      },
      {
        code: "20102115",
        text: "Из какого материала должны применяться трубопроводы для транспортировки нефти и нефтепродуктов?",
        answers: [
          "Из стали",
          "Из полиэтилена",
          "Из фторопласта",
          "Из полихлорвинила",
          "Из чугуна",
        ],
        correctAnswers: ["Из стали"],
      },
      {
        code: "20102116",
        text: "Что используется в качестве теплоносителей на технологических трубопроводах складов нефти и нефтепродуктов?",
        answers: [
          "Только электрообогрев",
          "Только пар",
          "Только промтеплофикационная вода",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20102117",
        text: "За счет чего должны компенсироваться температурные деформации трубопроводов для транспортировки мазута?",
        answers: [
          "За счет поворотов и изгибов трассы трубопроводов (самокомпенсация) или установки специальных компенсирующих устройств (П-образных компенсаторов)",
          "За счет установки на технологических трубопроводах для транспортировки мазута антивибрационных компенсаторов",
          "За счет установки на технологических трубопроводах для транспортировки мазута линзовых компенсаторов",
          "За счет установки на технологических трубопроводах для транспортировки мазута сальниковых компенсаторов",
        ],
        correctAnswers: [
          "За счет поворотов и изгибов трассы трубопроводов (самокомпенсация) или установки специальных компенсирующих устройств (П-образных компенсаторов)",
        ],
      },
      {
        code: "20102118",
        text: "Применение каких компенсаторов на технологических трубопроводах допускается для транспортировки мазута?",
        answers: [
          "Только сальниковых",
          "Только волнистых",
          "Только линзовых",
          "Всех перечисленных",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20102119",
        text: "Какая запорная арматура, установленная на технологических трубопроводах, должна иметь механический привод (электро-, пневмо- или гидропривод) с дистанционным управлением и ручным дублированием?",
        answers: [
          "Установленная на технологических трубопроводах с условным диаметром более 400 мм",
          "Установленная на технологических трубопроводах с условным диаметром более 300 мм",
          "Установленная на технологических трубопроводах с условным диаметром более 250 мм",
          "Не регламентируется",
        ],
        correctAnswers: [
          "Установленная на технологических трубопроводах с условным диаметром более 400 мм",
        ],
      },
      {
        code: "20102120",
        text: "На какое минимальное расстояние должны не доходить защитные боковые ограждения открытых насосных станций до пола и покрытия (перекрытия) насосной станции?",
        answers: ["На 0,3 м", "На 0,5 м", "На 0,7 м", "На 1,0 м"],
        correctAnswers: ["На 0,3 м"],
      },
      {
        code: "20102121",
        text: "В каком случае допускается применение на складах нефти и нефтепродуктов поршневых насосов для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей при малых объемных скоростях подачи, в том числе в системах дозирования?",
        answers: [
          "Допускается при обосновании принятого технического решения в проектной документации",
          "Допускается в случае, если данное условие их применения определено в технической документации организации-изготовителя",
          "Допускается при согласовании с организацией-изготовителем и федеральным органом исполнительной власти, уполномоченным в области промышленной безопасности",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "Допускается при обосновании принятого технического решения в проектной документации",
        ],
      },
      {
        code: "20102122",
        text: "В соответствии с требованиями какой документации насосные агрегаты должны оснащаться системами автоматизации, обеспечивающими их безопасную эксплуатацию? Укажите все правильные ответы.",
        answers: [
          "Проектной документации",
          "Технической документации организации-изготовителя",
          "Руководств по безопасной эксплуатации",
          "Технологических инструкций эксплуатирующей организации",
        ],
        correctAnswers: [
          "Проектной документации",
          "Технической документации организации-изготовителя",
        ],
      },
      {
        code: "20102123",
        text: "При каком достижении горючих газов и паров нефтепродуктов осуществляется включение аварийной вентиляции в помещениях насосных станций нефти и нефтепродуктов?",
        answers: [
          "50 % объемных от НКПРП",
          "20 % объемных от НКПРП",
          "40 % объемных от НКПРП",
          "30 % объемных от НКПРП",
        ],
        correctAnswers: ["20 % объемных от НКПРП"],
      },
      {
        code: "20102124",
        text: "Кем утверждается перечень уставок срабатывания блокировок и сигнализации для осуществления технологических операций, предусмотренных проектной документацией?",
        answers: [
          "Организацией, эксплуатирующей опасные производственные объекты складов нефти и нефтепродуктов",
          "Организацией, осуществляющей техническое обслуживание и ремонт автоматизированных систем управления на опасных производственных объектах складов нефти и нефтепродуктов",
          "Разработчиком проектной документации или организацией, специализирующейся на проектировании аналогичных объектов",
          "Разработчиком технологического процесса по согласованию с разработчиком проектной документации",
        ],
        correctAnswers: [
          "Организацией, эксплуатирующей опасные производственные объекты складов нефти и нефтепродуктов",
        ],
      },
      {
        code: "20102125",
        text: "При каком условии допускается использовать приборы, отработавшие назначенный срок службы, в системах автоматизации, связи и оповещения на опасных производственных объектах складов нефти и нефтепродуктов?",
        answers: [
          "Не допускается ни в каком случае",
          "Если это утверждено внутренними распорядительными документами",
          "Если обеспечена безопасная эксплуатация таких приборов",
          "Если есть разрешение на применение таких приборов территориальными органами Ростехнадзора",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20102126",
        text: "Какой документацией определяется электроснабжение электроприемников по категории надежности опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: [
          "Проектной документацией (документацией на техническое перевооружение)",
          "Эксплуатационной документацией",
          "Исполнительной документацией",
          "Инструкцией по эксплуатации электроприемников, производственными инструкциями",
        ],
        correctAnswers: [
          "Проектной документацией (документацией на техническое перевооружение)",
        ],
      },
      {
        code: "20102127",
        text: "В каком случае допускается на опасных производственных объектах складов нефти и нефтепродуктов прокладка кабельных трасс и технологических трубопроводов на общих строительных конструкциях?",
        answers: [
          "Допускается при обосновании принятых технических решений в проектной документации (документации на техническое перевооружение)",
          "Допускается при обосновании мер пожарной и промышленной безопасности",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "Допускается при обосновании принятых технических решений в проектной документации (документации на техническое перевооружение)",
        ],
      },
      {
        code: "20102128",
        text: "Какой электрифицированный транспорт во взрывозащищенном исполнении допускается применять на территории опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: [
          "Только самоходные аккумуляторные тележки (электрокары)",
          "Только электропогрузчики",
          "Только тягачи",
          "Любой из перечисленных видов транспорта",
        ],
        correctAnswers: ["Любой из перечисленных видов транспорта"],
      },
      {
        code: "20102129",
        text: "Что из перечисленного не требует защиты от прямых ударов молнии?",
        answers: [
          "Насосные установки (станции) нефти и нефтепродуктов и пространство над ними",
          "Дыхательная арматура резервуаров с легковоспламеняющимися жидкостями и пространство над ней",
          "Пространство над срезом горловины цистерн с легковоспламеняющимися жидкостями при открытом наливе продукта на наливной эстакаде, ограниченное цилиндром высотой 2,5 м и радиусом 5 м",
        ],
        correctAnswers: [
          "Насосные установки (станции) нефти и нефтепродуктов и пространство над ними",
        ],
      },
      {
        code: "20102130",
        text: "Какие переходные сопротивления должны быть в соединениях элементов трубопроводов или других протяженных металлических предметов для защиты зданий (сооружений) и электрооборудования от вторичных проявлений молнии?",
        answers: [
          "Не более 0,03 Ом на каждый контакт",
          "Не более 0,05 Ом на каждый контакт",
          "Не более 0,07 Ом на каждый контакт",
          "Не более 0,02 Ом на каждый контакт",
        ],
        correctAnswers: ["Не более 0,03 Ом на каждый контакт"],
      },
      {
        code: "20102131",
        text: "Какое из перечисленных требований к молниезащите и защите от статического электричества указано верно?",
        answers: [
          "Заземленное металлическое оборудование, покрытое лакокрасочными материалами, считается электростатически заземленным, если сопротивление любой точки его внутренней и внешней поверхности относительно магистрали заземления не превышает 10 Ом",
          "Измерение сопротивления любой точки внутренней и внешней поверхности заземленного металлического оборудования относительно магистрали заземления должно проводиться при относительной влажности окружающего воздуха не выше 40 %",
          "При измерениях электрод должен располагаться в точках поверхности оборудования, наименее удаленных от точек контакта поверхности с заземленными металлическими элементами, деталями, арматурой",
          "Площадь соприкосновения измерительного электрода с поверхностью оборудования не должна превышать 30 см²",
        ],
        correctAnswers: [
          "Заземленное металлическое оборудование, покрытое лакокрасочными материалами, считается электростатически заземленным, если сопротивление любой точки его внутренней и внешней поверхности относительно магистрали заземления не превышает 10 Ом",
        ],
      },
      {
        code: "20102132",
        text: "В каком случае разрешается выполнение болтовых соединений молниеприемников с токоотводами и токоотводов с заземлителями с переходным сопротивлением не более 0,05 Ом?",
        answers: [
          "Разрешается при недопустимости проведения огневых работ и при условии обязательного ежегодного контроля сопротивления перед началом грозового периода",
          "Разрешается при согласовании с федеральным органом исполнительной власти, уполномоченным в области пожарной безопасности",
          "Разрешается при согласовании с федеральным органом исполнительной власти, уполномоченным в области промышленной безопасности",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "Разрешается при недопустимости проведения огневых работ и при условии обязательного ежегодного контроля сопротивления перед началом грозового периода",
        ],
      },
      {
        code: "20102133",
        text: "С какой периодичностью заземлители, токоотводы подвергаются периодическому контролю?",
        answers: [
          "Один раз в 5 лет",
          "Один раз в год",
          "Один раз в 3 года",
          "Один раз в 2 года",
        ],
        correctAnswers: ["Один раз в 5 лет"],
      },
      {
        code: "20102134",
        text: "Какие из перечисленных конструкций подлежат заземлению для защиты от проявлений статического электричества?",
        answers: [
          "Только наземные резервуары для легковоспламеняющихся, горючих  и других жидкостей, являющихся диэлектриками и способных при испарении создавать взрывоопасные смеси паров с воздухом",
          "Только металлические оголовки и патрубки гибких шлангов для слива и налива нефти и нефтепродуктов",
          "Только железнодорожные рельсы сливоналивных участков, электрически соединенные между собой, а также металлические конструкции сливоналивных эстакад с двух сторон по длине",
          "Все перечисленные конструкции",
        ],
        correctAnswers: ["Все перечисленные конструкции"],
      },
      {
        code: "20102135",
        text: "Каким должно быть максимальное сопротивление заземляющего устройства, предназначенного только для защиты от статического электричества?",
        answers: ["100 Ом", "90 Ом", "70 Ом", "50 Ом"],
        correctAnswers: ["100 Ом"],
      },
      {
        code: "20102136",
        text: "Из какого материала следует выполнять соединение между собой неподвижных металлических конструкций (резервуары, трубопроводы), а также присоединение их к заземлителям? Укажите все правильные ответы.",
        answers: [
          "Из круглой стали диаметром более 6 мм",
          "Из полосовой стали сечением не менее 48 мм²",
          "Из стальной проволоки диаметром не менее 5 мм",
          "Из стальной ленты сечением не менее 24 мм²",
        ],
        correctAnswers: [
          "Из круглой стали диаметром более 6 мм",
          "Из полосовой стали сечением не менее 48 мм²",
        ],
      },
      {
        code: "20102137",
        text: "На каком минимальном расстоянии необходимо находиться от молниеотводов во время грозы?",
        answers: ["4 м", "3 м", "5 м", "10 м"],
        correctAnswers: ["4 м"],
      },
      {
        code: "20102138",
        text: "В каком случае допускается установка оборудования приточных систем вентиляции обычного исполнения в помещениях взрывоопасных категорий опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: [
          "Допускается, если на воздуховодах при выходе из венткамеры предусмотрены взрывозащитные обратные клапаны",
          "Допускается, если установка оборудования согласована с федеральным органом исполнительной власти, уполномоченным в области промышленной безопасности",
          "В любом случае",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "Допускается, если на воздуховодах при выходе из венткамеры предусмотрены взрывозащитные обратные клапаны",
        ],
      },
      {
        code: "20102139",
        text: "При достижении какой концентрации горючих газов и паров нефтепродуктов предусматривается автоматическое включение аварийной вентиляции?",
        answers: [
          "20 % объемных от НКПРП",
          "25 % объемных от НКПРП",
          "30 % объемных от НКПРП",
          "35 % объемных от НКПРП",
        ],
        correctAnswers: ["20 % объемных от НКПРП"],
      },
      {
        code: "20102140",
        text: "Какие насосные станции производственной канализации должны оснащаться датчиками загазованности с выводом сигнала на пульт помещения управления?",
        answers: [
          "Заглубленные более чем на 0,5 м",
          "Заглубленные более чем на 1,0 м",
          "Все насосные станции",
          "Только производственные станции",
        ],
        correctAnswers: ["Заглубленные более чем на 0,5 м"],
      },
      {
        code: "20102141",
        text: "В каком случае допускается сброс химически загрязненных, технологических, смывных и других сточных вод без предварительной очистки?",
        answers: [
          "В случае, когда в эксплуатирующей организации имеются собственные очистные сооружения и магистральная сеть, предназначенная для приема таких стоков",
          "В случае, когда это утверждено внутренним регламентом организации",
          "В случае, когда имеется разрешение Минприроды",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "В случае, когда в эксплуатирующей организации имеются собственные очистные сооружения и магистральная сеть, предназначенная для приема таких стоков",
        ],
      },
      {
        code: "20102142",
        text: "Какая из перечисленных систем канализации не предусматривается на площадках опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: ["Аварийная", "Производственная", "Ливневая"],
        correctAnswers: ["Аварийная"],
      },
      {
        code: "20102143",
        text: "Что из перечисленного должно отводиться в производственную канализацию на объектах складов нефти и нефтепродуктов?",
        answers: [
          "Подтоварные воды от отстоя нефти и нефтепродуктов",
          "Нефтешлам",
          "Сточные воды от зачистки и пропарки резервуаров для нефти и нефтепродуктов",
          "Все перечисленное",
        ],
        correctAnswers: ["Подтоварные воды от отстоя нефти и нефтепродуктов"],
      },
      {
        code: "20102144",
        text: "В каком случае допускается сбрасывать взрывопожароопасные и пожароопасные нефтепродукты в канализацию?",
        answers: [
          "Не допускается ни в каком случае",
          "В случае аварийной ситуации",
          "В случае получения разрешения от технического руководителя",
          "В случае применения специальных присадок",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20102145",
        text: "Какая высота столба жидкости в гидравлическом затворе, установленном на колодце, за пределами обвалования?",
        answers: [
          "Не менее 0,25 м",
          "Не менее 0,20 м",
          "Не менее 0,30 м",
          "Не менее 0,35 м",
        ],
        correctAnswers: ["Не менее 0,25 м"],
      },
      {
        code: "20102146",
        text: "Какая должна быть максимальная температура производственных сточных вод при сбросе в канализацию опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: ["40 °С", "50 °С", "45 °С"],
        correctAnswers: ["40 °С"],
      },
      {
        code: "20102147",
        text: "На каком расстоянии от нефтеловушек необходимо устраивать на канализационной сети колодцы с гидравлическим затвором?",
        answers: [
          "Не менее 10 м до и после нефтеловушек",
          "Не менее 15 м после нефтеловушек",
          "Не менее 5 м до нефтеловушек",
        ],
        correctAnswers: ["Не менее 10 м до и после нефтеловушек"],
      },
      {
        code: "20102148",
        text: "Какими документами определяются объем, периодичность и порядок организации и проведения работ по техническому обслуживанию и ремонту оборудования, резервуаров и технологических трубопроводов, систем инженерно-технического обеспечения с учетом конкретных условий эксплуатации опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: [
          "Нормативно-техническими документами, разработанными эксплуатирующей организацией",
          "Инструкциями по эксплуатации оборудования",
          "Федеральными нормами и правилами в области промышленной безопасности",
        ],
        correctAnswers: [
          "Нормативно-техническими документами, разработанными эксплуатирующей организацией",
        ],
      },
      {
        code: "20102149",
        text: "В течение какого времени после ввода резервуара в эксплуатацию необходимо ежегодно проводить нивелирование окрайки днища в абсолютных отметках?",
        answers: [
          "В течение четырех лет",
          "В течение года",
          "В течение пяти лет",
          "В течение шести лет",
        ],
        correctAnswers: ["В течение четырех лет"],
      },
      {
        code: "20102150",
        text: "Какова периодичность зачистки металлических резервуаров для хранения нефтепродуктов?",
        answers: [
          "Не менее 2 раз в год - для авиационного топлива и не менее 1 раза в 2 года - для остальных светлых нефтепродуктов и масел",
          "Не менее 1 раза в год - для авиационного топлива и не менее 1 раза в 3 года - для остальных светлых нефтепродуктов и масел",
          "Не менее 3 раз в год - для авиационного топлива и не менее 1 раза в 1,5 года - для остальных светлых нефтепродуктов и масел",
        ],
        correctAnswers: [
          "Не менее 2 раз в год - для авиационного топлива и не менее 1 раза в 2 года - для остальных светлых нефтепродуктов и масел",
        ],
      },
      {
        code: "20102151",
        text: "В каком месяце должны быть проведены все ремонты молниезащитных устройств на объектах складов нефти и нефтепродуктов?",
        answers: ["В апреле", "В мае", "В марте", "В сентябре"],
        correctAnswers: ["В апреле"],
      },
      {
        code: "20102152",
        text: "Какой документ должен быть оформлен для проведения земляных работ на территории опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: [
          "Наряд-допуск",
          "Акт-допуск",
          "Проект производства работ",
          "Письменное разрешение руководителя организации",
        ],
        correctAnswers: ["Наряд-допуск"],
      },
      {
        code: "20102153",
        text: "Какие из перечисленных закрытых помещений не считаются взрывоопасными?",
        answers: [
          "Любые закрытые помещения, имеющие сообщение с зонами класса 2",
          "Любые закрытые помещения, имеющие сообщение с зонами класса 1",
          "Любые закрытые помещения, имеющие сообщение с зонами класса 0",
          "Все перечисленные",
        ],
        correctAnswers: [
          "Любые закрытые помещения, имеющие сообщение с зонами класса 2",
        ],
      },
      {
        code: "20102154",
        text: "В каких зонах классов взрывоопасности помещений МСП, ПБУ, МЭ и ПТК допускается установка электрооборудования, не имеющего маркировки по взрывозащите?",
        answers: ["В зоне 0", "В зоне 1", "В зоне 2", "Все ответы неверны"],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20102155",
        text: "Какие требования предъявляются правилами к системе контроля состояния воздушной среды для закрытых помещений объектов сбора, подготовки и транспортировки нефти, газа и конденсата?",
        answers: [
          "Система должна быть сблокирована с системой звуковой и световой аварийной сигнализации, все помещения должны иметь постоянно действующую систему приточно-вытяжной вентиляции",
          "Система должна иметь блокировку для включения оборудования, все помещения должны иметь постоянно действующую противопожарную систему",
          "Система должна быть сблокирована с системой выключения оборудования, включая перекрытие клапанов, все помещения должны иметь постоянно действующую систему оповещения",
          "В системе контроля состояния воздушной среды должно быть предусмотрено дублирование датчиков аварийного включения оборудования и системы приточно-вытяжной вентиляции",
          "Система должна иметь блокировку от выключения оборудования, включая перекрытие запорной арматуры, все помещения должны иметь постоянно действующую систему вытяжной вентиляции",
        ],
        correctAnswers: [
          "Система должна быть сблокирована с системой звуковой и световой аварийной сигнализации, все помещения должны иметь постоянно действующую систему приточно-вытяжной вентиляции",
        ],
      },
      {
        code: "20102156",
        text: "Какая документация должна быть размещена на объектах сбора и подготовки нефти и газа (ЦПС, УПН, УКПГ, ГП), насосных и компрессорных станциях (ДНС, КС)?",
        answers: [
          "Технологическая схема, утвержденная техническим руководителем организации, с указанием номеров задвижек, аппаратов, направлений потоков, полностью соответствующих их нумерации в проектной документации",
          "Технические паспорта и документация на оборудование насосных и компрессорных станций",
          "План аварийно-спасательных мероприятий, утвержденный руководством организации",
          "Технические паспорта на оборудование и приказ о назначении ответственного за проведение работ",
          "Вся перечисленная документация",
        ],
        correctAnswers: [
          "Технологическая схема, утвержденная техническим руководителем организации, с указанием номеров задвижек, аппаратов, направлений потоков, полностью соответствующих их нумерации в проектной документации",
        ],
      },
      {
        code: "20102157",
        text: "В каких случаях персонал должен быть обеспечен необходимыми средствами индивидуальной защиты?",
        answers: [
          "При наличии в продукции, технологических аппаратах, резервуарах и других емкостях сернистого водорода или возможности образования вредных веществ при пожарах, взрывах, нарушении герметичности емкостей и других аварийных ситуациях",
          "При обнаружении вредных веществ и примесей в продукции",
          "При частом срабатывании датчиков загазованности",
          "При разгерметизации оборудования",
        ],
        correctAnswers: [
          "При наличии в продукции, технологических аппаратах, резервуарах и других емкостях сернистого водорода или возможности образования вредных веществ при пожарах, взрывах, нарушении герметичности емкостей и других аварийных ситуациях",
        ],
      },
      {
        code: "20102158",
        text: "В соответствии с какими документами должна устанавливаться скорость изменения технологических параметров?",
        answers: [
          "В соответствии с технологическим регламентом и заводскими инструкциями по эксплуатации оборудования",
          "В соответствии с инструкциями завода-изготовителя",
          "В соответствии с Правилами безопасности в нефтяной и газовой промышленности",
          "В соответствии с Планами проведения работ, утвержденными техническим руководителем организации",
          "В соответствии с планами производства работ",
        ],
        correctAnswers: [
          "В соответствии с технологическим регламентом и заводскими инструкциями по эксплуатации оборудования",
        ],
      },
      {
        code: "20102159",
        text: "Что необходимо предпринять в случае обнаружения загазованности воздуха рабочей зоны?",
        answers: [
          "Незамедлительно предупредить обслуживающий персонал близлежащих установок о возможной опасности, оградить загазованный участок и принять меры по устранению источника загазованности",
          "Незамедлительно покинуть загазованный участок и сообщить о случившемся ответственному руководителю",
          "Незамедлительно предупредить обслуживающий персонал о возможной опасности",
          "Незамедлительно подать сигнал тревоги и предупредить ответственного руководителя",
          "Незамедлительно предупредить обслуживающий персонал и покинуть загазованный участок",
        ],
        correctAnswers: [
          "Незамедлительно предупредить обслуживающий персонал близлежащих установок о возможной опасности, оградить загазованный участок и принять меры по устранению источника загазованности",
        ],
      },
      {
        code: "20102160",
        text: "Кем должно обслуживаться электрооборудование установки?",
        answers: [
          "Электротехническим персоналом, имеющим соответствующую квалификацию и допуск к работе",
          "Эксплуатационным персоналом, прошедшим инструктаж",
          "Персоналом, обслуживающим установку, в присутствии представителя электротехнического персонала",
          "Электротехнологическим персоналом",
          "Персоналом подрядной организации",
        ],
        correctAnswers: [
          "Электротехническим персоналом, имеющим соответствующую квалификацию и допуск к работе",
        ],
      },
      {
        code: "20102161",
        text: "Какие требования предъявляются к электрическим датчикам систем контроля и управления технологическим процессом?",
        answers: [
          "Должны быть во взрывозащищенном исполнении",
          "Должны быть рассчитаны на применение в условиях газовых гидратов",
          "Должны быть рассчитаны на применение в условиях вибрации",
          "Все вышеперечисленные требования",
        ],
        correctAnswers: ["Все вышеперечисленные требования"],
      },
      {
        code: "20102162",
        text: "С какой целью насосы, перекачивающие сернистую нефть, должны быть заполнены перекачиваемой жидкостью?",
        answers: [
          "С целью избегания образования пирофорных отложений",
          "С целью равномерной подачи жидкости при пуске насоса",
          "С целью избегания попадания воды в насос",
          "С целью постоянной готовности к пуску",
        ],
        correctAnswers: ["С целью избегания образования пирофорных отложений"],
      },
      {
        code: "20102163",
        text: "Каким проверкам должны подвергаться разъемные соединения компрессоров и их газопроводов?",
        answers: [
          "На герметичность",
          "На отсутствие дефектов в металлических соединениях",
          "На отсутствие коррозии",
          "На отсутствие отложений",
        ],
        correctAnswers: ["На герметичность"],
      },
      {
        code: "20102164",
        text: "В соответствии с каким документом проводится перед пуском проверка исправности комплекса установки подготовки нефти?",
        answers: [
          "В соответствии с планом, утвержденным техническим руководителем организации",
          "В соответствии с технологическим регламентом",
          "В соответствии с распорядительными документами эксплуатирующей организации",
          "В соответствии графиком производства работ",
        ],
        correctAnswers: [
          "В соответствии с планом, утвержденным техническим руководителем организации",
        ],
      },
      {
        code: "20102165",
        text: "Какие работники должны обслуживать электрическую часть электрообессоливающей установки в технологической цепи подготовки нефти?",
        answers: [
          "Электротехнический персонал, имеющий допуск на проведение работ с электроустановками напряжением выше 1000 В",
          "Персонал, обслуживающий установку, в присутствии представителя электротехнического персонала",
          "Персонал подрядной организации",
          "Электротехнологический персонал",
        ],
        correctAnswers: [
          "Электротехнический персонал, имеющий допуск на проведение работ с электроустановками напряжением выше 1000 В",
        ],
      },
      {
        code: "20102166",
        text: "Какое устройство должен иметь электродегидратор?",
        answers: [
          "Отключающее напряжение при понижении уровня продукта в аппарате",
          "Предохраняющее от превышения давления",
          "Сигнализирующее при понижении уровня продукта в аппарате",
          "Регулирующее уровень продукта в аппарате",
          "Отключающее подачу продукта в аппарат при отключении электроэнергии",
        ],
        correctAnswers: [
          "Отключающее напряжение при понижении уровня продукта в аппарате",
        ],
      },
      {
        code: "20102167",
        text: "Что необходимо сделать после заполнения электродегидратора продуктом перед подачей напряжения?",
        answers: [
          "Удалить скопившиеся в электродегидраторе газы и пары",
          "Проверить блокировки ограждений",
          "Проверить герметичность соединений",
          "Проверить заземляющие устройства",
        ],
        correctAnswers: [
          "Удалить скопившиеся в электродегидраторе газы и пары",
        ],
      },
      {
        code: "20102168",
        text: "Кем осуществляется подача напряжения на электрообессоливающую установку в технологической цепи подготовки нефти?",
        answers: [
          "Дежурным электроперсоналом по указанию начальника установки или лица, его заменяющего",
          "Эксплуатационным персоналом по указанию начальника установки",
          "Главным энергетиком",
          "Мастером установки",
        ],
        correctAnswers: [
          "Дежурным электроперсоналом по указанию начальника установки или лица, его заменяющего",
        ],
      },
      {
        code: "20102169",
        text: "Какие требования предъявляются к процессу дренирования воды из электродегидраторов и отстойников?",
        answers: [
          "Процесс должен быть автоматизированным и осуществляться закрытым способом",
          "Процесс должен быть автоматизированным и осуществляться открытым способом",
          "Процесс должен быть с ручным управлением и осуществляться открытым способом",
          "Процесс должен быть с ручным управлением и осуществляться закрытым способом",
        ],
        correctAnswers: [
          "Процесс должен быть автоматизированным и осуществляться закрытым способом",
        ],
      },
      {
        code: "20102170",
        text: "Какими документами следует руководствоваться при эксплуатации УКПГ, газосборных пунктов, головных сооружений?",
        answers: [
          "Технологическим регламентом",
          "Техническим заданием",
          "Руководством по эксплуатации, утвержденным техническим директором организации",
          "Планом производства работ",
          "Инструкциями завода-изготовителя",
        ],
        correctAnswers: ["Технологическим регламентом"],
      },
      {
        code: "20102171",
        text: "Какие способы используются для ликвидации гидратных пробок в газопроводах, арматуре, оборудовании и приборах установки комплексной подготовки газа?",
        answers: [
          "Введение ингибиторов гидратообразования, теплоносителей (пара, горячей воды), понижение давления в системе",
          "Разогрев открытым огнем с соблюдением мер безопасности, повышение давления в системе",
          "Все перечисленные способы",
          "Введение поверхностно-активных веществ, окислителей, понижение давления в системе",
        ],
        correctAnswers: [
          "Введение ингибиторов гидратообразования, теплоносителей (пара, горячей воды), понижение давления в системе",
        ],
      },
      {
        code: "20102172",
        text: "С какой периодичностью проводится проверка на герметичность игольчатых вентилей пробоотборников?",
        answers: [
          "Не реже одного раза в шесть месяцев",
          "Не реже одного раза в год",
          "Каждый раз при остановке оборудования",
          "Каждую смену",
        ],
        correctAnswers: ["Не реже одного раза в шесть месяцев"],
      },
      {
        code: "20102173",
        text: "Какие меры безопасности должны быть предусмотрены для насосов (группы насосов), перекачивающих горючие продукты?",
        answers: [
          "Дистанционное отключение и установка на линиях входа и нагнетания запорных или отсекающих устройств",
          "Ручное управление и установка на линиях входа и нагнетания обратных клапанов",
          "Ручное управление и установка на линии нагнетания насоса предохранительного клапана",
          "Дистанционное отключение и установка на линии нагнетания предохранительного клапана",
        ],
        correctAnswers: [
          "Дистанционное отключение и установка на линиях входа и нагнетания запорных или отсекающих устройств",
        ],
      },
      {
        code: "20102174",
        text: "Чем должен быть оборудован насос, подающий масло на торцевые уплотнения?",
        answers: [
          "Блокировочным устройством, включающим резервный масляный насос при падении давления масла",
          "Контактным манометром, включающим предупредительный сигнал о падении давления масла",
          "Предохранительным устройством, предотвращающим превышение давления масла сверх установленного заводом-изготовителем",
          "Дифференциальным преобразователем давления, подающим сигнал о падении давления масла на диспетчерский пульт",
        ],
        correctAnswers: [
          "Блокировочным устройством, включающим резервный масляный насос при падении давления масла",
        ],
      },
      {
        code: "20102175",
        text: "Какой клапан должен быть установлен на напорном (нагнетательном) трубопроводе центробежного насоса?",
        answers: [
          "Обратный клапан",
          "Предохранительный клапан",
          "Отсечной клапан",
        ],
        correctAnswers: ["Обратный клапан"],
      },
      {
        code: "20102176",
        text: "Куда должен отводиться сбрасываемый продукт при освобождении и продувке насосов?",
        answers: [
          "За пределы помещений: жидкий - по трубопроводам в специально предназначенную емкость, а пары и газы - на факел или свечу",
          "В пределах помещения: жидкий - по трубопроводам в специально предназначенную емкость, а пары и газы - в сепаратор",
          "За пределы помещений: жидкий - по трубопроводам в дренажную систему, а пары и газы - в эмульгатор",
          "За пределы помещений: жидкий - по трубопроводам в закрытую дренажную систему, а пары и газы - на факел или свечу",
          "За пределы помещений: жидкий - через скруббер в предназначенную емкость, а пары и газы - на факел или свечу",
        ],
        correctAnswers: [
          "За пределы помещений: жидкий - по трубопроводам в специально предназначенную емкость, а пары и газы - на факел или свечу",
        ],
      },
      {
        code: "20102177",
        text: "Что следует использовать для отключения резервного насоса от всасывающих и напорных коллекторов?",
        answers: [
          "Задвижки",
          "Обратный клапан",
          "Заглушку",
          "Запорный клапан (вентиль)",
          "Заслонку",
        ],
        correctAnswers: ["Задвижки"],
      },
      {
        code: "20102178",
        text: "В каком положении должны находиться задвижки на всасывающем и нагнетательном продуктопроводах при необходимости сдвига поршня парового насоса с мертвого положения вручную?",
        answers: [
          "На всасывающем и нагнетательном продуктопроводах - в закрытом положении",
          "На всасывающем и нагнетательном продуктопроводах - в открытом положении",
          "На всасывающем продуктопроводе - в закрытом, на нагнетательном - в открытом",
          "На всасывающем продуктопроводе - в открытом, на нагнетательном - в закрытом",
        ],
        correctAnswers: [
          "На всасывающем и нагнетательном продуктопроводах - в закрытом положении",
        ],
      },
      {
        code: "20102179",
        text: "Какими документами устанавливаются сроки по проверке на герметичность соединений компрессоров и их газопроводов?",
        answers: [
          "Инструкциями завода-изготовителя и технологическим регламентом",
          "Планом работ, утвержденным техническим руководителем организации и технологическим регламентом",
          "Ведомостью дефектов и производственной инструкцией",
          "Инструкцией по охране труда и планом производства работ",
        ],
        correctAnswers: [
          "Инструкциями завода-изготовителя и технологическим регламентом",
        ],
      },
      {
        code: "20102180",
        text: "Какими устройствами необходимо оборудовать отделители жидкости (сепараторы)?",
        answers: [
          "Световой и звуковой сигнализацией, блокировкой, производящей остановку компрессора при достижении предельно допустимого уровня жидкости в сепараторе",
          "Индикатором уровня жидкости в сепараторе",
          "Манометром и сигнализаторами",
          "Термопарой и расходомером",
        ],
        correctAnswers: [
          "Световой и звуковой сигнализацией, блокировкой, производящей остановку компрессора при достижении предельно допустимого уровня жидкости в сепараторе",
        ],
      },
      {
        code: "20102181",
        text: "Что и с какой целью необходимо осмотреть после каждой остановки компрессора?",
        answers: [
          "Недоступные к осмотру во время его работы движущиеся детали и убедиться в отсутствии превышения допустимых температур нагрева",
          "Недоступные к осмотру во время его работы движущиеся детали и убедиться в их целостности",
          "Недоступные к осмотру во время его работы движущиеся детали и проверить уровень масла",
          "Недоступные к осмотру во время его работы движущиеся детали и проверить их ограждения",
          "Недоступные к осмотру во время его работы движущиеся детали и проверить их крепление",
        ],
        correctAnswers: [
          "Недоступные к осмотру во время его работы движущиеся детали и убедиться в отсутствии превышения допустимых температур нагрева",
        ],
      },
      {
        code: "20102182",
        text: "Кто дает разрешение на пуск компрессора после ревизии, ремонта и длительного вынужденного отключения (кроме резервного)?",
        answers: [
          "Руководитель объекта (мастер, начальник компрессорной станции) или механик",
          "Главный энергетик организации",
          "Главный инженер организации",
          "Руководитель ремонтной бригады",
        ],
        correctAnswers: [
          "Руководитель объекта (мастер, начальник компрессорной станции) или механик",
        ],
      },
      {
        code: "20102183",
        text: "Чем должны оснащаться воздухопроводы при работе нескольких компрессоров в общую сеть?",
        answers: [
          "Обратным клапаном и отсекающей задвижкой или вентилем",
          "Манометром",
          "Расходомером",
          "Запорной арматурой",
          "Заглушкой",
        ],
        correctAnswers: [
          "Обратным клапаном и отсекающей задвижкой или вентилем",
        ],
      },
      {
        code: "20102184",
        text: "В каком случае установка автоматического клапана-отсекателя на выкидной линии газовых и газоконденсатных скважин не требуется?",
        answers: [
          "В случае наличия подземного клапана-отсекателя в составе комплекса подземного оборудования скважины",
          "В случае содержания сероводорода в природном газе или газоконденсате не более 2 %",
          "В случае выполнения пробного бурения",
          "В случае выполнения разведочного бурения",
        ],
        correctAnswers: [
          "В случае наличия подземного клапана-отсекателя в составе комплекса подземного оборудования скважины",
        ],
      },
      {
        code: "20102185",
        text: "Чем должны фиксироваться колеса железнодорожных цистерн при сливе и наливе?",
        answers: ["Башмаками", "Тормозом", "Упорами"],
        correctAnswers: ["Башмаками"],
      },
      {
        code: "20102186",
        text: "На какое расстояние от эстакады должен быть удален локомотив перед началом операции по сливу и наливу железнодорожных цистерн?",
        answers: [
          "На расстояние не менее 100 м",
          "На расстояние не менее 50 м",
          "На расстояние не менее 80 м",
          "На расстояние не менее 30 м",
        ],
        correctAnswers: ["На расстояние не менее 100 м"],
      },
      {
        code: "20102187",
        text: "С какой периодичностью рукава для слива и налива сжиженного газа, ЛВЖ и ГЖ должны подвергаться гидравлическому испытанию на прочность?",
        answers: [
          "Не реже одного раза в три месяца",
          "Не реже одного раза в шесть месяцев",
          "Не реже одного раза в год",
          "Не регламентируется",
        ],
        correctAnswers: ["Не реже одного раза в три месяца"],
      },
      {
        code: "20102188",
        text: "Разрешается ли применение гибких шлангов в качестве стационарных трубопроводов?",
        answers: [
          "Запрещается",
          "Разрешается, по согласованию с территориальным органом Ростехнадзора",
          "Разрешается, при соблюдении мер безопасности",
          "Разрешается, если это указано в регламенте",
          "Разрешается в любом случае",
        ],
        correctAnswers: ["Запрещается"],
      },
      {
        code: "20102189",
        text: "При каком остаточном давлении паров продукта не допускается налив в цистерны?",
        answers: [
          "При давлении менее 0,05 МПа",
          "При давлении менее 0,07 МПа",
          "При давлении менее 0,2 МПа",
          "При давлении менее 0,1 МПа",
        ],
        correctAnswers: ["При давлении менее 0,05 МПа"],
      },
      {
        code: "20102190",
        text: "Какие процедуры производятся обслуживающим персоналом перед вводом в эксплуатацию участка трубопровода или всего трубопровода?",
        answers: [
          "Очистка полости, испытание на прочность и герметичность",
          "Испытание на коррозионную стойкость",
          "Осмотр наружной поверхности",
          "Изоляция участка трубопровода",
          "Неразрушающий контроль участка трубопровода",
        ],
        correctAnswers: [
          "Очистка полости, испытание на прочность и герметичность",
        ],
      },
      {
        code: "20102191",
        text: "Какие меры безопасности должны быть выполнены перед началом продувки и испытания трубопровода газом или воздухом?",
        answers: [
          "Необходимо определить и обозначить знаками опасные зоны, в которых запрещено находиться людям",
          "Необходимо определить опасные зоны и поставить наблюдающих",
          "Необходимо установить заградительные сооружения вдоль трассы со световой сигнализацией",
          "Необходимо подать длинный звуковой сигнал для удаления персонала в безопасную зону",
          "Необходимо установить ограждения по периметру опасной зоны",
        ],
        correctAnswers: [
          "Необходимо определить и обозначить знаками опасные зоны, в которых запрещено находиться людям",
        ],
      },
      {
        code: "20102192",
        text: "Как должен проводиться подвод инертного газа или пара к трубопроводам для продувки?",
        answers: [
          "С помощью съемных участков трубопроводов или гибких шлангов, с установкой запорной арматуры с обеих сторон съемного участка",
          "Только с помощью стационарно установленного трубопровода",
          "Только с помощью стационарно установленного гибкого шланга",
          "С помощью стационарно установленного трубопровода или гибкого шланга, с установкой на его конце обратного клапана",
          "С помощью шлангов, имеющих металлическую оплетку, предотвращающую перегиб шланга",
        ],
        correctAnswers: [
          "С помощью съемных участков трубопроводов или гибких шлангов, с установкой запорной арматуры с обеих сторон съемного участка",
        ],
      },
      {
        code: "20102193",
        text: "Допускается ли на территории охранной зоны нефтегазопроводов устройство колодцев, котлованов и других заглублений?",
        answers: [
          "Не допускается, кроме углублений, выполняемых при ремонте или реконструкции по плану производства работ эксплуатирующей организации",
          "Допускается",
          "Допускается по согласовано с Ростехнадзором",
          "Допускается, если они предусмотрены проектом",
        ],
        correctAnswers: [
          "Не допускается, кроме углублений, выполняемых при ремонте или реконструкции по плану производства работ эксплуатирующей организации",
        ],
      },
      {
        code: "20102194",
        text: "Что запрещается выполнять обходчикам при профилактических осмотрах нефтегазопроводов?",
        answers: [
          "Спускаться в колодцы и другие углубления на территории охранной зоны",
          "Проводить осмотр любого участка трубопровода",
          "Подходить к станции катодной защиты на расстояние ближе 15 м",
          "Все перечисленное",
        ],
        correctAnswers: [
          "Спускаться в колодцы и другие углубления на территории охранной зоны",
        ],
      },
      {
        code: "20102195",
        text: "Какие меры необходимо принять при обнаружении участков изоляции, пропитанной горючим веществом?",
        answers: [
          "Снять пропитанную изоляцию, подвести водяной пар",
          "Промыть загрязненное место водой",
          "Очистить место загрязнения",
          "Заизолировать место повреждения изоляции",
        ],
        correctAnswers: ["Снять пропитанную изоляцию, подвести водяной пар"],
      },
      {
        code: "20102196",
        text: "Какими клапанами должен быть оснащен каждый резервуар?",
        answers: [
          "Дыхательными и предохранительными",
          "Перепускными и сливными",
          "Предохранительными и шаровыми",
          "Обратными и вентиляционными",
        ],
        correctAnswers: ["Дыхательными и предохранительными"],
      },
      {
        code: "20102197",
        text: "Какими светильниками разрешается пользоваться на территории резервуарного парка?",
        answers: [
          "Взрывозащищенными переносными светильниками (аккумуляторными и батарейными)",
          "Стационарными светодиодными светильниками в пожаробезопасном исполнении",
          "Флуоресцентными светильниками при условии их размещения на расстоянии не менее 20 м от резервуаров",
          "Светильниками напряжением до 110 В во взрывозащищенном исполнении",
        ],
        correctAnswers: [
          "Взрывозащищенными переносными светильниками (аккумуляторными и батарейными)",
        ],
      },
      {
        code: "20102198",
        text: "В каких случаях разрешается одновременное автоматическое переключение задвижек в резервуарном парке?",
        answers: [
          "Только при условии защиты трубопроводов от превышения давления",
          "Только при отказе в работе одной из задвижек",
          "Только при максимальном уровне продукта в резервуаре",
          "Только при проведении мероприятий по профилактическому ремонту задвижек",
        ],
        correctAnswers: [
          "Только при условии защиты трубопроводов от превышения давления",
        ],
      },
      {
        code: "20102199",
        text: "Какое устройство должно быть предусмотрено для парового змеевика, расположенного внутри резервуара?",
        answers: [
          "Устройство для спуска из него конденсата",
          "Устройство для предупреждения увеличения давления пара выше рабочего",
          "Устройство для предотвращения замерзания в зимнее время при прекращении подачи пара",
          "Устройство, сигнализирующее о прекращении подачи пара",
        ],
        correctAnswers: ["Устройство для спуска из него конденсата"],
      },
      {
        code: "20102200",
        text: "Чем должен быть снабжен замерный люк на резервуарах?",
        answers: [
          "Герметичной крышкой с педалью для открывания ногой",
          "Герметичной крышкой с приспособлением для открывания рукой",
          "Герметичной крышкой с уплотнительной прокладкой",
          "Фиксатором",
          "Герметичной крышкой с запорным устройством",
        ],
        correctAnswers: ["Герметичной крышкой с педалью для открывания ногой"],
      },
      {
        code: "20102201",
        text: "С какой стороны следует становиться при открывании замерного люка, измерении уровня, отборе проб, а также при дренировании резервуаров?",
        answers: [
          "С наветренной стороны",
          "С подветренной стороны",
          "Над замерным люком",
          "На усмотрение работника, производящего отбор проб",
        ],
        correctAnswers: ["С наветренной стороны"],
      },
      {
        code: "20102202",
        text: "Кто имеет право на проведение диагностики резервуаров?",
        answers: [
          "Специализированная организация",
          "Эксплуатирующая организация",
          "Монтажная организация",
          "Все перечисленные организации",
        ],
        correctAnswers: ["Специализированная организация"],
      },
      {
        code: "20102203",
        text: "Какие работы не запрещается производить во время стоянки нефтеналивного судна у причала?",
        answers: [
          "Отогревание замерзших трубопроводов паром",
          "Налив при грозовых разрядах",
          "Подход к нефтеналивному судну и швартовка к нему иных судов и плавсредств, не связанных с операциями по наливу нефти",
        ],
        correctAnswers: ["Отогревание замерзших трубопроводов паром"],
      },
      {
        code: "20102204",
        text: "Куда должен производиться сброс нефти и нефтепродуктов из аппаратов, резервуаров и оборудования при их подготовке?",
        answers: [
          "В специальные (аварийные) емкости",
          "В производственную канализацию",
          "В ливневую канализацию",
          "В дренажную систему",
        ],
        correctAnswers: ["В специальные (аварийные) емкости"],
      },
      {
        code: "20102205",
        text: "Какой должна быть температура внутри резервуаров во время пропаривания?",
        answers: [
          "Не выше 60 °С",
          "Не выше 75 °С",
          "Не выше 65 °С",
          "Не выше 70 °С",
        ],
        correctAnswers: ["Не выше 60 °С"],
      },
      {
        code: "20102206",
        text: "Какие светильники должны применяться для освещения внутри аппаратов и резервуаров?",
        answers: [
          "Переносные светильники во взрывозащищенном исполнении с лампами напряжением не выше 12 В",
          "Переносные светильники во взрывозащищенном исполнении с лампами напряжением не выше 24 В",
          "Переносные светильники во взрывозащищенном исполнении с лампами напряжением не выше 36 В",
          "Стационарные светильники во взрывозащищенном исполнении с лампами напряжением до 42 В",
        ],
        correctAnswers: [
          "Переносные светильники во взрывозащищенном исполнении с лампами напряжением не выше 12 В",
        ],
      },
      {
        code: "20102207",
        text: "Какие меры должны быть приняты при очистке теплообменника или конденсатора механическим способом?",
        answers: [
          'Необходимо с противоположной стороны сделать ограждение и вывесить предупреждающую надпись "Опасная зона!"',
          "Оградить место проведения работ сигнальной лентой",
          "Установить сигнальные фонари по периметру выполнения ремонтных работ",
          "Выставить наблюдающего в опасной зоне",
        ],
        correctAnswers: [
          'Необходимо с противоположной стороны сделать ограждение и вывесить предупреждающую надпись "Опасная зона!"',
        ],
      },
      {
        code: "20102208",
        text: "Что из перечисленного разрешено использовать для промывки деталей оборудования?",
        answers: ["Керосин", "Воду", "Бензин", "Ацетон"],
        correctAnswers: ["Керосин"],
      },
      {
        code: "20102209",
        text: "Кем должны производиться работы по вскрытию и ремонту любого электрооборудования и освещения?",
        answers: [
          "Электротехническим персоналом",
          "Персоналом, эксплуатирующим установку",
          "Персоналом подрядной организации",
          "Любым персоналом, прошедшим инструктаж",
        ],
        correctAnswers: ["Электротехническим персоналом"],
      },
      {
        code: "20102210",
        text: "Где должна производиться запись о проведенном ремонте оборудования?",
        answers: [
          "В паспорте оборудования",
          "В ведомости дефектов",
          "В ремонтном журнале",
          "В оперативном журнале",
        ],
        correctAnswers: ["В паспорте оборудования"],
      },
      {
        code: "20102211",
        text: "Какой персонал имеет право на проведение ремонта электрообезвоживающей и обессоливающей установок?",
        answers: [
          "Электротехнический персонал, допущенный к работам на электроустановках напряжением выше 1000 В",
          "Электротехнический персонал",
          "Электротехнологический персонал",
        ],
        correctAnswers: [
          "Электротехнический персонал, допущенный к работам на электроустановках напряжением выше 1000 В",
        ],
      },
      {
        code: "20102212",
        text: "Каким образом должен быть подготовлен к ремонту технологический трубопровод?",
        answers: [
          "Перед проведением ремонтных работ необходимо трубопровод освободить от транспортируемого продукта, продуть паром",
          "Перед проведением ремонтных работ необходимо продуть трубопровод техническим воздухом",
          "Перед проведением ремонтных работ необходимо провести проверку воздуха в районе работ на загазованность",
        ],
        correctAnswers: [
          "Перед проведением ремонтных работ необходимо трубопровод освободить от транспортируемого продукта, продуть паром",
        ],
      },
      {
        code: "20102213",
        text: "Кем должны осуществляться подключение переносных светильников и разводка кабелей в полевых условиях при проведении ремонтных работ?",
        answers: [
          "Двумя рабочими: электромонтером и рабочим бригады или двумя рабочими бригады, прошедшими соответствующий инструктаж, один из которых имеет квалификационную группу не ниже II",
          "Тремя рабочими, минимум двое из которых относятся к электротехническому персоналу",
          "Электромонтером и двумя рабочими бригады, прошедшими соответствующий инструктаж",
        ],
        correctAnswers: [
          "Двумя рабочими: электромонтером и рабочим бригады или двумя рабочими бригады, прошедшими соответствующий инструктаж, один из которых имеет квалификационную группу не ниже II",
        ],
      },
      {
        code: "20102214",
        text: "В каком исполнении должны быть технические устройства, применяемые во взрывопожароопасных зонах?",
        answers: [
          "Во взрывозащищенном",
          "В пылезащищенном",
          "Во влагозащищенном",
          "В особо взрывобезопасном",
        ],
        correctAnswers: ["Во взрывозащищенном"],
      },
      {
        code: "20102215",
        text: "С какой периодичностью проводится проверка герметичности фланцевых соединений, арматуры, люков и других источников возможных выделений сернистого водорода посредством обхода (объезда) каждого опасного объекта?",
        answers: ["Ежедневно", "Ежемесячно", "Еженедельно", "Ежедекадно"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "20102216",
        text: "Какая температура поверхностей должна быть во время пропарки аппаратов, емкостей, резервуаров для сбора и подготовки нефти, газа и газоконденсата, содержащих сернистый водород?",
        answers: [
          "Не выше 60 °C",
          "Не выше 65 °C",
          "Не выше 75 °C",
          "Не выше 70 °C",
        ],
        correctAnswers: ["Не выше 60 °C"],
      },
      {
        code: "20102217",
        text: "Что необходимо предпринять организации, эксплуатирующей химически опасный производственный объект, в целях приведения его в соответствие требованиям Правил безопасности химически опасных производственных объектов?",
        answers: [
          "Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь",
          "Провести экспертизу промышленной безопасности",
          "Провести реконструкцию химически опасного производственного объекта",
          "Сообщить в Ростехнадзор о выявленных в рамках проведения производственного контроля несоответствиях Правилам безопасности химически опасных производственных объектов",
        ],
        correctAnswers: [
          "Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь",
        ],
      },
      {
        code: "20102218",
        text: "В каком документе указываются регламентированные значения параметров по ведению технологического процесса?",
        answers: [
          "В технологическом регламенте",
          "В руководстве по безопасности",
          "В техническом регламенте",
          "В проектной документации",
        ],
        correctAnswers: ["В технологическом регламенте"],
      },
      {
        code: "20102219",
        text: "Кем разрабатываются исходные данные на разработку документации на химически опасных производственных объектах?",
        answers: [
          "Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области",
          "Организациями, осуществляющими проведение экспертизы промышленной безопасности",
          "Эксплуатирующими организациями",
          "Территориальным управлением Ростехнадзора",
        ],
        correctAnswers: [
          "Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области",
        ],
      },
      {
        code: "20102220",
        text: "Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?",
        answers: [
          "Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки",
          "Надежное энергообеспечение",
          "Применение компонентов в фазовом состоянии, затрудняющем или исключающем образование химически опасной смеси",
          "Выбор рациональных режимов дозирования компонентов",
        ],
        correctAnswers: [
          "Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки",
        ],
      },
      {
        code: "20102221",
        text: "В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?",
        answers: [
          "В течение времени, достаточного для исключения опасной ситуации",
          "В течение времени, установленного в проектной документации",
          "В течение рабочей смены",
          "В течение суток",
        ],
        correctAnswers: [
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
      },
      {
        code: "20102222",
        text: "Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах III класса опасности?",
        answers: [
          "Не более 120 секунд",
          "Не более 300 секунд",
          "Не более 240 секунд",
          "Не более 350 секунд",
        ],
        correctAnswers: ["Не более 120 секунд"],
      },
      {
        code: "20102223",
        text: "Для каких из перечисленных целей запрещается использовать специальные системы аварийного освобождения?",
        answers: [
          "Для исключения образования химически опасных смесей в самих системах",
          "Для обеспечения минимально возможного времени освобождения системы от продукта",
          "Для исключения образования химически опасных смесей в окружающей атмосфере",
          "Для исключения развития аварий",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20102224",
        text: "Куда следует направлять сбрасываемые химически опасные вещества?",
        answers: [
          "В закрытые системы для дальнейшей утилизации",
          "На факельную установку",
          "В специальные емкости",
          "В систему сбора продуктов переработки",
        ],
        correctAnswers: ["В закрытые системы для дальнейшей утилизации"],
      },
      {
        code: "20102225",
        text: "В каком случае допускается объединение выбросов химически опасных веществ, содержащих вещества, способные при смешивании образовывать более опасные по воздействиям химические соединения?",
        answers: [
          "В случае расчета выбросов в количествах, определяемых условиями безопасной остановки технологического процесса",
          "В случае разработки необходимых мер, предусмотренных документацией на ХОПО",
          "В случае использования специальных систем аварийного освобождения",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20102226",
        text: "В каком документе организация, эксплуатирующая химически опасный производственный объект I, II и III классов опасности, должна предусматривать действия работников по предупреждению аварий, локализации и ликвидации их последствий?",
        answers: [
          "В плане мероприятий по локализации и ликвидации последствий аварий",
          "В технологическом регламенте",
          "В положении о производственном контроле",
          "В инструкции по охране труда",
        ],
        correctAnswers: [
          "В плане мероприятий по локализации и ликвидации последствий аварий",
        ],
      },
      {
        code: "20102227",
        text: "В соответствии с какими документами осуществляют ведение технологических процессов на химически опасных производственных объектах?",
        answers: [
          "В соответствии с технологическими регламентами",
          "В соответствии с руководствами (инструкциями) по эксплуатации технических устройств",
          "В соответствии с проектной документацией",
          "В соответствии с декларацией промышленной безопасности",
        ],
        correctAnswers: ["В соответствии с технологическими регламентами"],
      },
      {
        code: "20102228",
        text: "Какие существуют типы технологических регламентов в зависимости от степени освоенности производств и целей осуществляемых работ?",
        answers: [
          "Постоянные, временные, разовые и лабораторные",
          "Входящие в состав проектной документации или пусковые",
          "Периодически пересматриваемые",
        ],
        correctAnswers: ["Постоянные, временные, разовые и лабораторные"],
      },
      {
        code: "20102229",
        text: "Для каких из перечисленных производств разрабатываются постоянные технологические регламенты?",
        answers: [
          "Только для новых в данной организации производств",
          "Только для производств с новой технологией",
          "Только для действующих химико-технологических производств, в технологию которых внесены принципиальные изменения",
          "Для всех перечисленных производств",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20102230",
        text: "Какой из перечисленных разделов не относится к постоянным, временным и разовым технологическим регламентам, связанным с необходимостью обеспечения промышленной безопасности технологических процессов?",
        answers: [
          "Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств",
          "Материальный баланс",
          "Характеристика производимой продукции",
          "Технологические схемы производства",
        ],
        correctAnswers: [
          "Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств",
        ],
      },
      {
        code: "20102231",
        text: 'Информацию о каких организациях должен содержать раздел технологического регламента "Общая характеристика производства"?',
        answers: [
          "Только об организации, выполнившей проектную документацию",
          "Только об организации, выполнившей функции генерального проектировщика",
          "Только об организации - разработчике технологического процесса",
          "Только об организации - разработчике технологической части проектной документации",
          "О всех перечисленных организациях",
        ],
        correctAnswers: ["О всех перечисленных организациях"],
      },
      {
        code: "20102232",
        text: 'Как производится описание технологической схемы в разделе "Описание химико-технологического процесса и схемы"?',
        answers: [
          "По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта",
          "По выборочным стадиям технологического процесса, начиная с загрузки сырья",
          "По промежуточным стадиям технологического процесса, начиная с опытных партий продукции",
        ],
        correctAnswers: [
          "По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта",
        ],
      },
      {
        code: "20102233",
        text: 'Что необходимо указывать в описании процессов разделения химических продуктов (горючих или их смесей с негорючими) в разделе "Описание химико-технологического процесса и схемы"?',
        answers: [
          "Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса",
          "Показатели взрыво- и пожароопасности, а также токсичные свойства всех веществ, участвующих в процессе на всех стадиях",
          "Степень разделения сред, меры взрывобезопасности, показатели пожароопасности и токсичности",
        ],
        correctAnswers: [
          "Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса",
        ],
      },
      {
        code: "20102234",
        text: 'Какие разновидности материального баланса допускается составлять в разделе технологического регламента "Материальный баланс"?',
        answers: [
          "Только на единицу времени (час)",
          "Только на единицу выпускаемой продукции",
          "Только на один производственный поток или на мощность производства в целом",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20102235",
        text: "На основе каких данных составляется материальный баланс для действующих производств?",
        answers: [
          "Материальный баланс для действующих производств составляется по достигнутым показателям работы производств в последний год перед составлением технологического регламента",
          "Материальный баланс для действующих производств составляется по данным технологического регламента",
          "Материальный баланс для действующих производств составляется по данным проекта с учетом внесенных в проект изменений, включения или исключения дополнительных операций или стадий",
        ],
        correctAnswers: [
          "Материальный баланс для действующих производств составляется по достигнутым показателям работы производств в последний год перед составлением технологического регламента",
        ],
      },
      {
        code: "20102236",
        text: 'Какое из перечисленных требований не соответствует разделу технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          "Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается",
          "Средства автоматики, используемые по плану мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, должны быть определены особо",
          "Допускается разделять в отдельные таблицы перечень систем сигнализации и блокировок и (или) перечень аналитического контроля",
          "Периодичность испытаний запорной регулирующей арматуры, исполнительных механизмов, периодичность проверок приборов и испытаний других технических средств, участвующих в схемах контроля, управления и противоаварийной защиты технологических процессов, должны быть взаимоувязаны",
        ],
        correctAnswers: [
          "Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается",
        ],
      },
      {
        code: "20102237",
        text: "Кем разрабатываются все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?",
        answers: [
          "Организацией, эксплуатирующей химико-технологическое производство",
          "Специализированной организацией",
          "Организацией - разработчиком процесса",
          "Руководителем территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "Организацией, эксплуатирующей химико-технологическое производство",
        ],
      },
      {
        code: "20102238",
        text: "На кого возлагается ответственность за полноту и качество разработки разделов технологического регламента производства продукции и контроль за обеспечением его исполнения?",
        answers: [
          "На технологическую службу организации, производства, отделения, установки",
          "На организацию - разработчика процесса",
          "На техническую службу организации",
          "На главного инженера организации",
        ],
        correctAnswers: [
          "На технологическую службу организации, производства, отделения, установки",
        ],
      },
      {
        code: "20102239",
        text: "Кто утверждает все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?",
        answers: [
          "Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство",
          "Технический руководитель организации, эксплуатирующей химико-технологическое производство",
          "Руководитель отдела технического контроля организации, эксплуатирующей химико-технологическое производство",
          "Специально созданная комиссия организации, эксплуатирующей химико-технологическое производство",
        ],
        correctAnswers: [
          "Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство",
        ],
      },
      {
        code: "20102240",
        text: 'Кто подписывается в технологическом регламенте под грифом "согласовано"? Укажите все правильные ответы.',
        answers: [
          "Главный метролог организации",
          "Главный механик и главный энергетик организации",
          "Начальник производственно-технического (технического) отдела организации",
          "Начальник цеха",
        ],
        correctAnswers: [
          "Главный метролог организации",
          "Главный механик и главный энергетик организации",
        ],
      },
      {
        code: "20102241",
        text: "Какое количество копий технологических регламентов устанавливается требованиями Правил безопасности химически опасных производственных объектов?",
        answers: [
          "Не более 4 копий",
          "Не более 6 копий",
          "Не более 10 копий",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20102242",
        text: 'Какое из перечисленных требований соответствует нормам заполнения и хранения "Листа регистрации изменений и дополнений"?',
        answers: [
          "Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента",
          "Лист регистрации изменений и дополнений размещается в начале технологического регламента",
          "Регистрация изменений и дополнений выполняется главным инженером организации",
          "Запись в регистрационном листе выполняется чернилами красного цвета",
        ],
        correctAnswers: [
          "Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента",
        ],
      },
      {
        code: "20102243",
        text: "Какой устанавливается срок действия постоянного технологического регламента?",
        answers: [
          "Не более 10 лет",
          "Не более 15 лет",
          "Не более 12 лет",
          "Не более 20 лет",
        ],
        correctAnswers: ["Не более 10 лет"],
      },
      {
        code: "20102244",
        text: "Каким образом определяется срок действия временного технологического регламента при отсутствии установленных планами норм освоения производства?",
        answers: [
          "Лицом, утверждающим технологический регламент",
          "На основании утвержденных норм",
          "На основании плана развития производства",
          "Не регламентируется",
        ],
        correctAnswers: ["Лицом, утверждающим технологический регламент"],
      },
      {
        code: "20102245",
        text: "Каким образом устанавливается и оформляется срок продления действия временного технологического регламента?",
        answers: [
          "Приказом руководителя организации",
          "Приказом технологической службы организации, производства, отделения, установки",
          "Распоряжением по организации",
          "Служебной запиской руководителя организации",
        ],
        correctAnswers: ["Приказом руководителя организации"],
      },
      {
        code: "20102246",
        text: "Какой устанавливается срок действия разовых (опытных) технологических регламентов, в соответствии с которыми проводится наработка опытной продукции в течение нескольких лет?",
        answers: [
          "Не более 5 лет",
          "Не более 10 лет",
          "Не более 7 лет",
          "Не более 3 лет",
        ],
        correctAnswers: ["Не более 5 лет"],
      },
      {
        code: "20102247",
        text: "Кто устанавливает назначенный срок службы для технологического оборудования, машин и трубопроводной арматуры?",
        answers: [
          "Организация-изготовитель",
          "Эксплуатирующая организация",
          "Ремонтная организация",
          "Организация по сертификации оборудования",
        ],
        correctAnswers: ["Организация-изготовитель"],
      },
      {
        code: "20102248",
        text: "Какие дополнительные требования установлены при использовании технологического оборудования и трубопроводов, в которых обращаются коррозионно-активные вещества?",
        answers: [
          "Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии",
          "Контроль за технологическим оборудованием и трубопроводами, контактирующими с коррозионно-активными веществами, должен осуществляться не реже чем 1 раз в месяц",
          "Технологическое оборудование и трубопроводы должны быть защищены металлическими коррозионно-стойкими покрытиями",
          "Запрещается для защиты технологического оборудования использовать неметаллические покрытия",
        ],
        correctAnswers: [
          "Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии",
        ],
      },
      {
        code: "20102249",
        text: "Чем определяется количество насосов и компрессоров, используемых для перемещения химически опасных веществ в технологическом процессе?",
        answers: [
          "Необходимостью и условиями обеспечения непрерывности технологического процесса",
          "Техническими характеристиками применяемых насосов и компрессоров",
          "Критическими параметрами технологического процесса",
          "Физико-химическими свойствами перемещаемых продуктов",
        ],
        correctAnswers: [
          "Необходимостью и условиями обеспечения непрерывности технологического процесса",
        ],
      },
      {
        code: "20102250",
        text: "На чем основаны оптимальные методы и средства противоаварийной автоматической защиты?",
        answers: [
          "На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления",
          "На алгоритмах, разработанных по сценариям всех возможных аварий и их развития",
          "На сценариях возможных аварийных ситуаций и способах перевода объекта в безопасное состояние",
          "На методиках и программных продуктах, применяемых для моделирования аварийных ситуаций",
        ],
        correctAnswers: [
          "На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления",
        ],
      },
      {
        code: "20102251",
        text: "Какое минимальное количество датчиков должно устанавливаться на химически опасных производственных объектах I и II классов опасности для осуществления контроля за текущими показателями параметров, определяющими химическую опасность технологических процессов ХОПО?",
        answers: [
          "Не менее 2 независимых датчиков с раздельными точками отбора",
          "1 датчик",
          "Не более 3 датчиков",
          "Не регламентируется",
        ],
        correctAnswers: [
          "Не менее 2 независимых датчиков с раздельными точками отбора",
        ],
      },
      {
        code: "20102252",
        text: "Какое из перечисленных требований к выполнению управляющих функций систем ПАЗ указано неверно?",
        answers: [
          "Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы",
          "Системы ПАЗ должны реализовываться на принципах приоритетности защиты технологических процессов комплектно, с одновременной защитой отдельных единиц оборудования",
          "Команды управления, сформированные алгоритмами защит (блокировок), должны иметь приоритет по отношению к любым другим командам управления технологическим оборудованием, в том числе к командам, формируемым оперативным персоналом АСУТП, если иное не оговорено в техническом задании на ее создание",
          "В алгоритмах срабатывания защит следует предусматривать возможность включения блокировки команд управления оборудованием, технологически связанным с аппаратом, агрегатом или иным оборудованием, вызвавшим такое срабатывание",
        ],
        correctAnswers: [
          "Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы",
        ],
      },
      {
        code: "20102253",
        text: "На каких объектах ХОПО технические решения по обеспечению надежности контроля параметров, имеющих критические значения, обосновываются разработчиком документации на ХОПО? Укажите все правильные ответы.",
        answers: [
          "На объектах ХОПО III класса опасности",
          "На объектах ХОПО II класса опасности",
          "На объектах ХОПО I класса опасности",
          "На объектах ХОПО IV класса опасности",
          "На всех перечисленных объектах",
        ],
        correctAnswers: [
          "На объектах ХОПО III класса опасности",
          "На объектах ХОПО IV класса опасности",
        ],
      },
      {
        code: "20102254",
        text: 'Какой устанавливается срок действия "Накопительной ведомости"?',
        answers: ["6 месяцев", "1 месяц", "3 месяца", "1 год"],
        correctAnswers: ["6 месяцев"],
      },
    ],

    20103: [
      {
        code: "20103000",
        text: "Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?",
        answers: [
          "Приведенной массой вещества, участвующего во взрыве",
          "Категорией взрывоопасности технологических блоков",
          "Радиусом зон разрушения",
          "Энергией сгорания парогазовой фазы",
        ],
        correctAnswers: ["Категорией взрывоопасности технологических блоков"],
      },
      {
        code: "20103001",
        text: "Какой параметр является критерием установления категории взрывоопасности технологических блоков согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?",
        answers: [
          "Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему",
          "Класс опасности веществ, обращающихся в процессе",
          "Температура самовоспламенения паров, обращающихся в процессе веществ",
          "Скорость распространения горения веществ, обращающихся в процессе",
        ],
        correctAnswers: [
          "Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему",
        ],
      },
      {
        code: "20103002",
        text: "Какой следует принимать категорию взрывоопасности блоков, определяемую расчетом, если обращающиеся в технологическом блоке опасные вещества относятся к токсичным, высокотоксичным веществам?",
        answers: [
          "На одну выше",
          "I категории",
          "II категории",
          "III категории",
        ],
        correctAnswers: ["На одну выше"],
      },
      {
        code: "20103003",
        text: "Какой порядок установлен для внесения изменений в технологическую схему, аппаратурное оформление, в системы контроля, связи, оповещения и противоаварийной автоматической защиты?",
        answers: [
          "Изменения вносятся после внесения изменений в проектную документацию или документацию на техническое перевооружение, согласованную с разработчиком проектной документации или с организацией, специализирующейся на проектировании аналогичных объектов, или при наличии положительного заключения экспертиз по проектной документации (документации)",
          "Изменения вносятся при наличии положительного заключения экспертизы промышленной безопасности и внесения его в реестр заключений экспертизы",
          "Изменения вносятся путем разработки обоснования безопасности опасного производственного объекта, получения на него положительного заключения экспертизы промышленной безопасности и внесения его в реестр заключений экспертизы",
        ],
        correctAnswers: [
          "Изменения вносятся после внесения изменений в проектную документацию или документацию на техническое перевооружение, согласованную с разработчиком проектной документации или с организацией, специализирующейся на проектировании аналогичных объектов, или при наличии положительного заключения экспертиз по проектной документации (документации)",
        ],
      },
      {
        code: "20103004",
        text: "Какая из перечисленных мер должна предусматриваться для блоков технологической системы по максимальному снижению взрывоопасности?",
        answers: [
          "Предотвращение взрывов внутри технологического оборудования",
          "Использование вентиляционных систем для исключения возможности взрывов и пожаров в объеме производственных зданий, сооружений",
          "Контроль за состоянием воздушной среды",
          "Использование эффективных систем пожаротушения в зависимости от особенностей технологического процесса",
        ],
        correctAnswers: [
          "Предотвращение взрывов внутри технологического оборудования",
        ],
      },
      {
        code: "20103005",
        text: "В каких документах приводятся способы и средства, исключающие выход параметров за установленные пределы?",
        answers: [
          "Только в исходных данных на проектирование",
          "Только в технологическом регламенте на производство продукции",
          "Только в проектной документации",
          "Во всех перечисленных",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "20103006",
        text: "Какое управление системами подачи инертных сред в технологические системы должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категории взрывоопасности?",
        answers: [
          "Дистанционное, неавтоматическое",
          "Ручное управление по месту",
          "Автоматическое",
        ],
        correctAnswers: ["Автоматическое"],
      },
      {
        code: "20103007",
        text: "Чем должны оснащаться технологические системы для обеспечения безопасности ведения технологических процессов?",
        answers: [
          "Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ",
          "Только средствами противоаварийной защиты",
          "Только средствами контроля за параметрами, определяющими взрывоопасность процесса",
          "Только средствами автоматического регулирования",
        ],
        correctAnswers: [
          "Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ",
        ],
      },
      {
        code: "20103008",
        text: "В течение какого времени средства обеспечения энергоустойчивости технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?",
        answers: [
          "В течение времени, достаточного для исключения опасной ситуации",
          "В течение 24 часов",
          "В течение 12 часов",
          "В течение 8 часов",
        ],
        correctAnswers: [
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
      },
      {
        code: "20103009",
        text: "Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?",
        answers: [
          "Системами ручного (без применения вычислительной техники) регулирования",
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
          "Системами, обеспечивающими непрерывность технологического процесса",
        ],
        correctAnswers: [
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
        ],
      },
      {
        code: "20103010",
        text: "Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?",
        answers: [
          "Время срабатывания установлено для каждого технологического блока в соответствии с категорией взрывоопасности",
          "Время срабатывания определяется расчетом",
          "Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и установлено для блоков III категории",
          "Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и установлено для блоков I и II категорий",
        ],
        correctAnswers: ["Время срабатывания определяется расчетом"],
      },
      {
        code: "20103011",
        text: "Каким требованиям должны соответствовать специальные системы аварийного освобождения технологических блоков от обращающихся продуктов? Выберите 2 правильных варианта ответа.",
        answers: [
          "Обеспечивать минимально возможное время освобождения",
          "Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий",
          "Иметь небольшие габариты и вес",
          "Переход из режима ожидания в рабочее состояние должен осуществляться в течение 45 секунд",
        ],
        correctAnswers: [
          "Обеспечивать минимально возможное время освобождения",
          "Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий",
        ],
      },
      {
        code: "20103012",
        text: "Что устанавливается на линию подачи инертных газов (пар, азот, и другие среды) в процессах, при которых в результате отклонения от заданных технологических режимов возможно попадание взрывопожароопасных продуктов в нее?",
        answers: [
          "Обратный клапан",
          "Регулирующий клапан",
          "Гидроклапан",
          "Предохранительный клапан",
          "Запорный клапан",
        ],
        correctAnswers: ["Обратный клапан"],
      },
      {
        code: "20103013",
        text: "Кем определяются предельные значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывоопасных характеристик, физико-химических свойств транспортируемых веществ, свойств конструкционных материалов и характеристик технических устройств, применяемых для перемещения горючих продуктов?",
        answers: [
          "Руководителем службы производственного контроля",
          "Техническими специалистами организаций, эксплуатирующих объекты",
          "Разработчиком проекта",
          "Руководителем (заместителем руководителя) организации, эксплуатирующей объекты",
        ],
        correctAnswers: ["Разработчиком проекта"],
      },
      {
        code: "20103014",
        text: "В зависимости от чего осуществляется выбор конструкции и конструкционных материалов, уплотнительных устройств для насосов и компрессоров?",
        answers: [
          "В зависимости от свойств перемещаемой среды",
          "В зависимости от протяженности трубопровода",
          "В зависимости от конструктивных особенностей трубопровода",
          "В зависимости от всех перечисленных факторов",
        ],
        correctAnswers: ["В зависимости от свойств перемещаемой среды"],
      },
      {
        code: "20103015",
        text: "Какими приборами и средствами автоматизации оснащаются сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Выберите 2 правильных варианта ответа.",
        answers: [
          "Блокировками отключения компрессора при превышении предельно допустимого значения уровня",
          "Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации",
          "Приборами контроля перепада давления на сепараторе",
          "Приборами контроля температуры и плотности отделяемой жидкой фазы",
        ],
        correctAnswers: [
          "Блокировками отключения компрессора при превышении предельно допустимого значения уровня",
          "Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации",
        ],
      },
      {
        code: "20103016",
        text: "Каким образом осуществляется контроль за содержанием кислорода в горючем газе во всасывающих линиях компрессоров, работающих под разрежением? Укажите все правильные ответы.",
        answers: [
          "Определяются места размещения пробоотборников и способы контроля",
          "Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения",
          "Проводится систематический контроль за содержанием кислорода в горючем газе не реже двух раз в сутки",
          "Закачивается инертный газ при остановке компрессора во всасывающие линии насоса",
        ],
        correctAnswers: [
          "Определяются места размещения пробоотборников и способы контроля",
          "Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения",
        ],
      },
      {
        code: "20103017",
        text: "Какими блокировками на отключение должны быть оснащены насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?",
        answers: [
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях",
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости в корпусе насоса, достижении опасных значений в приемной емкости",
          "Блокировками, исключающими пуск и (или) прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях",
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
        correctAnswers: [
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
      },
      {
        code: "20103018",
        text: "Кем определяется степень разделения материальных сред и меры взрывобезопасности на всех стадиях процесса?",
        answers: [
          "Разработчиком процесса",
          "Разработчиком проекта",
          "Степень разделения определяется заказчиком в задании на проектирование, а меры взрывобезопасности - разработчиком проекта",
        ],
        correctAnswers: ["Разработчиком процесса"],
      },
      {
        code: "20103019",
        text: "В массообменных процессах при отклонении технологических параметров от регламентированных значений возможно образование неустойчивых взрывоопасных соединений. Как в таком случае должно осуществляться регулирование этих параметров?",
        answers: [
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматически, для установок III категории взрывоопасности допускается управление вручную при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений",
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматически, а при Qв ≤ 10 допускается ручное дистанционное управление",
          "Для установок с технологическими блоками I категории взрывоопасности - автоматически, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту",
          "Для установок с технологическими блоками I категории взрывоопасности - автоматически, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту",
        ],
        correctAnswers: [
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматически, для установок III категории взрывоопасности допускается управление вручную при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений",
        ],
      },
      {
        code: "20103020",
        text: "Чем должны оснащаться колонны ректификации горючих жидкостей? Выберите 2 правильных варианта ответа.",
        answers: [
          "Средствами сигнализации об опасных отклонениях значений параметров, определяющих взрывобезопасность процесса",
          "Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы",
          "Средствами контроля за компонентным составом поступающего на разделение продукта",
          "Средствами контроля за плотностью поступающих на разделение продукта и флегмы",
        ],
        correctAnswers: [
          "Средствами сигнализации об опасных отклонениях значений параметров, определяющих взрывобезопасность процесса",
          "Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы",
        ],
      },
      {
        code: "20103021",
        text: "Что применяется при непрерывных процессах смешивания веществ, взаимодействие которых может привести к развитию экзотермических реакций, для исключения их неуправляемого течения? Выберите 2 правильных варианта ответа.",
        answers: [
          "Определение безопасных объемных скоростей дозирования смешиваемых веществ",
          "Разработка методов отвода тепла",
          "Эффективное разделение этих продуктов и возможность образования застойных зон",
          "Разработка методов приостановки реакции путем увеличения подачи одного из смешиваемых веществ",
        ],
        correctAnswers: [
          "Определение безопасных объемных скоростей дозирования смешиваемых веществ",
          "Разработка методов отвода тепла",
        ],
      },
      {
        code: "20103022",
        text: "Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?",
        answers: [
          "На установках с технологическими блоками I категории взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками II и III категорий взрывоопасности не регламентируется",
          "На установках с технологическими блоками I и II категорий взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками III категории взрывоопасности не регламентируется",
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
          "Давление теплоносителя (хладагента) не должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
        ],
        correctAnswers: [
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
        ],
      },
      {
        code: "20103023",
        text: "Чем обеспечивается противоаварийная автоматическая защита нагреваемых элементов (змеевиков) нагревательных печей? Выберите 2 правильных варианта ответа.",
        answers: [
          "Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции",
          "Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков",
          "Средствами контроля за температурой нагреваемого жидкого продукта и сигнализацией",
          "Блокировками по отключению подачи топлива при превышении значения температуры нагреваемого жидкого продукта",
        ],
        correctAnswers: [
          "Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции",
          "Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков",
        ],
      },
      {
        code: "20103024",
        text: "Что должно быть учтено при проектировании программного обеспечения противоаварийной защиты технологической аппаратуры реакционных процессов?",
        answers: [
          "Риски срабатывания автоматических систем противоаварийной защиты",
          "Требования территориального органа Ростехнадзора",
          "Указания технического руководителя организации",
          "Требования организации - изготовителя реакционной аппаратуры",
        ],
        correctAnswers: [
          "Риски срабатывания автоматических систем противоаварийной защиты",
        ],
      },
      {
        code: "20103025",
        text: "Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и др., нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?",
        answers: [
          "Заказчиком",
          "Разработчиком процесса",
          "Разработчиком проекта",
          "Руководителем службы производственного контроля",
        ],
        correctAnswers: ["Разработчиком процесса"],
      },
      {
        code: "20103026",
        text: "Где разрешается использование железнодорожных цистерн с легковоспламеняющимися жидкостями, находящимися на железнодорожных путях, в качестве стационарных, складских (расходных) емкостей?",
        answers: [
          "В местах проведения погрузочно-разгрузочных работ",
          "На путях общего пользования",
          "На технологических путях организации",
          "Нигде не разрешается",
        ],
        correctAnswers: ["Нигде не разрешается"],
      },
      {
        code: "20103027",
        text: "Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся и горючие жидкости на сливоналивных эстакадах?",
        answers: [
          "Должно быть организовано управление по месту",
          "Должно быть организовано управление дистанционно (из безопасного места)",
          "Должно быть организовано управление по месту и дистанционно (из безопасного места)",
          "Определяется разработчиком проекта",
        ],
        correctAnswers: [
          "Должно быть организовано управление по месту и дистанционно (из безопасного места)",
        ],
      },
      {
        code: "20103028",
        text: "Какие сведения являются основополагающими для выбора оборудования при разработке технологических процессов?",
        answers: [
          "Расчетные данные, которым должны соответствовать параметры оборудования и показатели надежности",
          "Расчетные данные, которым должны соответствовать параметры оборудования и требования действующих нормативных документов",
          "Расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование и требования действующих нормативных документов",
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
        correctAnswers: [
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
      },
      {
        code: "20103029",
        text: "В каком документе указываются данные о сроке службы технологического оборудования и трубопроводной арматуры производителем?",
        answers: [
          "В паспортах оборудования и трубопроводной арматуры",
          "В техническом задании на проектирование оборудования",
          "В сертификате на оборудование",
          "В технологическом регламенте",
        ],
        correctAnswers: ["В паспортах оборудования и трубопроводной арматуры"],
      },
      {
        code: "20103030",
        text: "Как производителем должна подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств до начала их применения на опасном производственном объекте?",
        answers: [
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
          "Посредством заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования и экспертизы промышленной безопасности",
          "Посредством оценки научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования",
          "Посредством опытных работ, проводимых разработчиком данного оборудования, и экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
        ],
      },
      {
        code: "20103031",
        text: "Какой должна быть температура наружных поверхностей оборудования и кожухов теплоизоляционных покрытий в местах, доступных для обслуживающего персонала?",
        answers: [
          "Не более 45 °С внутри помещений и 60 °С на наружных установках",
          "Не более 50 °С внутри помещений и 55 °С на наружных установках",
          "Не более 55 °С внутри помещений и 65 °С на наружных установках",
          "Не более 60 °С внутри помещений и 70 °С на наружных установках",
        ],
        correctAnswers: [
          "Не более 45 °С внутри помещений и 60 °С на наружных установках",
        ],
      },
      {
        code: "20103032",
        text: "Чем определяется порядок испытаний, контроль за состоянием и эксплуатацией теплообменных устройств?",
        answers: [
          "Технической документацией производителя",
          "Проектной документацией",
          "Исполнительной документацией",
          "Технологическим регламентом",
        ],
        correctAnswers: ["Технической документацией производителя"],
      },
      {
        code: "20103033",
        text: "Что должно обеспечивать размещение технологического оборудования, трубопроводной арматуры в производственных зданиях и на открытых площадках? Выберите 2 правильных варианта ответа.",
        answers: [
          "Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий",
          "Возможность проведения ремонтных работ",
          "Уменьшение взрывоопасности объекта путем равномерного распределения технологических блоков II категории взрывоопасности",
          "Использование строительных конструкций зданий в несущих элементах технологического оборудования",
        ],
        correctAnswers: [
          "Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий",
          "Возможность проведения ремонтных работ",
        ],
      },
      {
        code: "20103034",
        text: "Как требуется размещать технологическое оборудование взрывопожароопасных производств?",
        answers: [
          "Только с учетом возможности проведения ремонтных работ и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий",
          "Только с учетом возможности проведения визуального контроля за их состоянием, выполнения работ по обслуживанию, ремонту и замене",
          "Только с учетом обеспечения безопасности обслуживания и эксплуатации",
          "С учетом всех перечисленных требований",
        ],
        correctAnswers: ["С учетом всех перечисленных требований"],
      },
      {
        code: "20103035",
        text: "Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?",
        answers: [
          "Оборудование должно быть изолировано от действующей системы, а нанесенное на него обозначение номера по технологической схеме закрашено",
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем",
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно должно быть изолировано от действующих систем",
          "Оборудование должно быть демонтировано",
        ],
        correctAnswers: [
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем",
        ],
      },
      {
        code: "20103036",
        text: "В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?",
        answers: [
          "При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости",
          "При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости",
          "При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях",
          "В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования",
        ],
        correctAnswers: [
          "В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования",
        ],
      },
      {
        code: "20103037",
        text: "Что используется для соединения оборудования и технологических трубопроводов со стационарными линиями во взрывопожароопасных технологических системах?",
        answers: [
          "Съемные участки трубопроводов",
          "Резиновые гибкие шланги",
          "Металлические гибкие шланги",
          "Пластмассовые гибкие шланги",
        ],
        correctAnswers: ["Съемные участки трубопроводов"],
      },
      {
        code: "20103038",
        text: "Где допускается размещение фланцевых соединений на трубопроводах с пожаровзрывоопасными, токсичными и едкими веществами?",
        answers: [
          "В местах установки арматуры или подсоединения трубопроводов к аппаратам",
          "Над местами, предназначенными для прохода людей",
          "Над рабочими площадками",
          "В местах ввода в технологические здания и сооружения",
        ],
        correctAnswers: [
          "В местах установки арматуры или подсоединения трубопроводов к аппаратам",
        ],
      },
      {
        code: "20103039",
        text: "В каких местах не допускается размещать фланцевые соединения трубопроводов с пожаровзрывоопасными, токсичными и едкими веществами?",
        answers: [
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
          "Над автодорогами",
          "На трубопроводах, идущих по стенам зданий",
          "На трубопроводах, проложенных по эстакадам",
        ],
        correctAnswers: [
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
        ],
      },
      {
        code: "20103040",
        text: "Запорная арматура из каких материалов должна применяться в технологических системах с блоками любой категории взрывоопасности?",
        answers: [
          "Из стали",
          "Из алюминия и его сплавов",
          "Из латуни и ее сплавов",
          "Из меди и ее сплавов",
        ],
        correctAnswers: ["Из стали"],
      },
      {
        code: "20103041",
        text: "Какая арматура устанавливается на трубопроводах для транспортирования взрывопожароопасных продуктов?",
        answers: [
          "Соответствующая проектной документации (документации на техническое перевооружение)",
          "Только арматура с резиновым уплотнением в затворе",
          "Только арматура с тканевым уплотнением в затворе",
          "Только арматура с пластмассовым уплотнением в затворе",
        ],
        correctAnswers: [
          "Соответствующая проектной документации (документации на техническое перевооружение)",
        ],
      },
      {
        code: "20103042",
        text: "На каких трубопроводах следует применять арматуру под приварку для повышения надежности и плотности соединений?",
        answers: [
          "На трубопроводах технологических блоков любой категории взрывоопасности",
          "На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды, при регламентированном давлении",
          "На трубопроводах технологических блоков III категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды, при регламентированном давлении",
          "На трубопроводах технологических блоков II категории взрывоопасности с давлением среды более 1,5 МПа",
        ],
        correctAnswers: [
          "На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды, при регламентированном давлении",
        ],
      },
      {
        code: "20103043",
        text: "Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития? Выберите 2 правильных варианта ответа.",
        answers: [
          "Запорную и запорно-регулирующую арматуру",
          "Огнепреградители",
          "Клапаны, отсекающие и другие отключающие устройства",
          "Средства, регистрирующие превышение давления",
        ],
        correctAnswers: [
          "Запорную и запорно-регулирующую арматуру",
          "Клапаны, отсекающие и другие отключающие устройства",
        ],
      },
      {
        code: "20103044",
        text: "Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте и инструкциях",
          "Средства автоматики должны быть обозначены только в технологическом регламенте",
          "Средства автоматики должны быть обозначены в заключении экспертизы промышленной безопасности",
          "Средства автоматики должны быть обозначены на мнемосхемах",
        ],
        correctAnswers: [
          "Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте и инструкциях",
        ],
      },
      {
        code: "20103045",
        text: "Какие функции должна обеспечивать автоматизированная система управления технологическими процессами на базе средств вычислительной техники? Выберите 2 правильных варианта ответа.",
        answers: [
          "Периодический, не реже 2 раз в смену, контроль за состоянием воздушной среды в пределах объекта",
          "Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений",
          "Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации",
          "Периодический контроль за параметрами процесса и управление режимом для поддержания их регламентированных значений",
        ],
        correctAnswers: [
          "Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений",
          "Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации",
        ],
      },
      {
        code: "20103046",
        text: "В соответствии с чем должны определяться оптимальные методы создания системы противоаварийной защиты на стадии формирования требований при проектировании автоматизированной системы управления технологическим процессом?",
        answers: [
          "В соответствии со сценарием возможных аварийных ситуаций и способами перевода объекта в безопасное состояние",
          "В соответствии с алгоритмом, разработанным по сценариям развития возможных аварий",
          "В соответствии с методикой, применяемой для моделирования аварийных ситуаций, согласованной с Ростехнадзором",
          "В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности",
        ],
        correctAnswers: [
          "В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности",
        ],
      },
      {
        code: "20103047",
        text: "Где приводятся конкретные значения уставок систем защиты по опасным параметрам?",
        answers: [
          "В техническом паспорте взрывобезопасности ОПО",
          "В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции",
          "В техническом регламенте о безопасности оборудования для работы во взрывоопасных средах",
        ],
        correctAnswers: [
          "В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20103048",
        text: "Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?",
        answers: [
          "Средствами автоматического управления объектом по действующим программам",
          "Обслуживающим персоналом по инструкции",
          "Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений",
          "Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию противоаварийной автоматической защиты",
        ],
        correctAnswers: ["Обслуживающим персоналом по инструкции"],
      },
      {
        code: "20103049",
        text: "Каким образом обеспечивается надежность обеспечения средств управления и противоаварийной автоматической защиты сжатым воздухом?",
        answers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
          "Установкой резервного компрессора с включением его автоматически при остановке основного оборудования",
          "Переключением сети воздуха устройств автоматического регулирования и контрольно-измерительных приборов через осушитель на заводскую сеть сжатого воздуха",
          "Установкой буферных емкостей, обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 30 минут",
        ],
        correctAnswers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20103050",
        text: "Какое из перечисленных положений не соответствует требованиям по размещению и устройству помещений управления взрывоопасных производств?",
        answers: [
          "Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами",
          "Помещения управления должны быть отдельно стоящими",
          "В отдельных случаях при соответствующем обосновании в проекте разрешается пристраивать их к зданиям",
          "В помещениях не должны размещаться оборудование и другие устройства, не связанные с системой управления технологическим процессом",
        ],
        correctAnswers: [
          "Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами",
        ],
      },
      {
        code: "20103051",
        text: "Каким требованиям должны соответствовать помещения управления? Выберите 2 правильных варианта ответа.",
        answers: [
          "Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности",
          "Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам",
          "Прокладка по полу кабелей должна осуществляться с использованием швеллеров и уголков",
          "Полы в помещениях должны быть с повышенной механической стойкостью",
        ],
        correctAnswers: [
          "Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности",
          "Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам",
        ],
      },
      {
        code: "20103052",
        text: "Когда должно происходить автоматическое включение аварийной вентиляции, установленной в анализаторных помещениях?",
        answers: [
          "Если концентрация обращающихся веществ в воздухе помещения достигает 20 % НКПР",
          "Если концентрация обращающихся веществ в воздухе помещения достигает 10 % НКПР",
          "Если концентрация обращающихся веществ в воздухе помещения достигает 15 % НКПР",
          "Если концентрация обращающихся веществ в воздухе помещения достигает 5 % НКПР",
        ],
        correctAnswers: [
          "Если концентрация обращающихся веществ в воздухе помещения достигает 20 % НКПР",
        ],
      },
      {
        code: "20103053",
        text: "С какими подразделениями должна быть обеспечена связь посредством системы двусторонней громкоговорящей связи на объектах с технологическими блоками I категории взрывоопасности?",
        answers: [
          "С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов",
          "Только с персоналом диспетчерских пунктов, газоспасательной службой и штабом гражданской обороны",
          "Только с пожарной частью и сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов",
        ],
        correctAnswers: [
          "С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов",
        ],
      },
      {
        code: "20103054",
        text: "Что должно быть учтено в системах управления и защиты электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?",
        answers: [
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
          "Возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой",
          "Наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения",
          "Селективность защит на устройствах электроснабжающей и электропотребляющей организаций",
        ],
        correctAnswers: [
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
        ],
      },
      {
        code: "20103055",
        text: "Какие требования необходимо выполнять при прокладке кабелей по территории технологических установок? Выберите 2 правильных варианта ответа.",
        answers: [
          "Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов",
          "Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение",
          "Провода и кабели с полиэтиленовой изоляцией или оболочкой должны прокладываться на верхних ярусах технологических эстакад",
          "Прокладка кабелей в каналах и траншеях запрещается",
        ],
        correctAnswers: [
          "Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов",
          "Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение",
        ],
      },
      {
        code: "20103056",
        text: "Какое требование к системам вентиляции указано неверно?",
        answers: [
          "Системы аварийной вентиляции должны быть оснащены средствами их автоматического включения при срабатывании установленных в помещении сигнализаторов довзрывных концентраций или газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
          "Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства",
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: "20103057",
        text: "В каком случае системы аварийной вентиляции должны включаться автоматически? Выберите 2 правильных варианта ответа.",
        answers: [
          "При срабатывании сигнализаторов довзрывных концентраций вредных веществ",
          "При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
          "При поступлении сигнала от датчиков контроля за содержанием взрывопожароопасной пыли в воздухе помещения",
          "При поступлении сигнала от датчиков повышения температуры",
        ],
        correctAnswers: [
          "При срабатывании сигнализаторов довзрывных концентраций вредных веществ",
          "При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
        ],
      },
      {
        code: "20103058",
        text: "В каких случаях должны автоматически включаться системы аварийной вентиляции? Укажите все правильные ответы.",
        answers: [
          "При срабатывании сигнализаторов довзрывных концентраций вредных веществ",
          "При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
          "При поступлении сигнала от датчиков контроля за содержанием взрывопожароопасной пыли в воздухе помещения",
          "При поступлении сигнала от датчиков повышения температуры",
        ],
        correctAnswers: [
          "При срабатывании сигнализаторов довзрывных концентраций вредных веществ",
          "При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
        ],
      },
      {
        code: "20103059",
        text: "Какая система отопления должна применяться в помещениях, имеющих взрывоопасные зоны?",
        answers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
          "Система парового отопления",
          "Система водяного отопления",
        ],
        correctAnswers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
        ],
      },
      {
        code: "20103060",
        text: "Каков порядок сброса химически загрязненных стоков от отдельных технологических объектов в магистральную сеть канализации?",
        answers: [
          "Порядок сброса стоков в магистральную сеть канализации устанавливается организацией",
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны быть оборудованы устройствами для улавливания аварийных стоков",
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
          "Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть",
        ],
        correctAnswers: [
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
        ],
      },
      {
        code: "20103061",
        text: "Что из перечисленного является недопустимым на территории предприятия, имеющего в своем составе взрывопожароопасные производства?",
        answers: [
          "Только устройство открытых траншей, котлованов, приямков, в которых возможно скопление взрывопожароопасных паров и газов",
          "Только наличие природных оврагов, выемок, низин",
          "Только траншейная и наземная прокладка трасс трубопроводов со сжиженными горючими газами, легковоспламеняющимися и горючими жидкостями в искусственных или естественных углублениях",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20103062",
        text: "Какие требования должны быть выполнены для вновь проектируемых взрывопожароопасных и химически опасных объектов? Выберите 2 правильных варианта ответа.",
        answers: [
          "Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов",
          "Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия",
          "Вокруг зданий на химически опасных объектах должна предусматриваться вспаханная полоса земли шириной не менее 5 м",
          "Окна зданий на химически опасных объектах должны быть оборудованы жалюзи из прочных материалов",
        ],
        correctAnswers: [
          "Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов",
          "Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия",
        ],
      },
      {
        code: "20103063",
        text: "Какое утверждение соответствует требованиям Правил безопасности автогазозаправочных станций газомоторного топлива к хранению СПГ на криоАЗС?",
        answers: [
          "Хранение СПГ в резервуарах разрешается как с периодическим, так и с непрерывным газосбросом. В обоих случаях избыточное давление не должно быть ниже минимального давления, установленного руководством по эксплуатации резервуара (не менее 0,05 МПа)",
          "Хранение СПГ в резервуарах разрешается только с непрерывным газосбросом. Избыточное давление не должно быть ниже минимального давления, установленного руководством по эксплуатации резервуара (не менее 0,5 МПа)",
          "Хранение СПГ в резервуарах разрешается только с периодическим газосбросом. Избыточное давление не должно быть ниже минимального давления, установленного руководством по эксплуатации резервуара (не менее 0,05 МПа)",
          "Хранение СПГ в резервуарах разрешается как с периодическим, так и с непрерывным газосбросом. В обоих случаях избыточное давление не должно быть ниже минимального давления, установленного руководством по эксплуатации резервуара (не менее 0,5 МПа)",
        ],
        correctAnswers: [
          "Хранение СПГ в резервуарах разрешается как с периодическим, так и с непрерывным газосбросом. В обоих случаях избыточное давление не должно быть ниже минимального давления, установленного руководством по эксплуатации резервуара (не менее 0,05 МПа)",
        ],
      },
      {
        code: "20103064",
        text: "Каких пределов не должна превышать степень заполнения резервуаров СПГ на КриоАЗС?",
        answers: [
          "Пределов, указанных в паспортах на оборудование систем хранения, выдачи и регазификации СПГ",
          "100 % геометрического объема резервуара",
          "50 % геометрического объема резервуара",
          "75 % геометрического объема резервуара",
        ],
        correctAnswers: [
          "Пределов, указанных в паспортах на оборудование систем хранения, выдачи и регазификации СПГ",
        ],
      },
      {
        code: "20103065",
        text: "В какой документации определяются технологическая схема производства СПГ и основные параметры технологического процесса, влияющие на его безопасность? Выберите 2 варианта ответа.",
        answers: [
          "В исходных данных на проектирование",
          "В проектной документации",
          "В руководстве по эксплуатации",
          "В исполнительной документации",
        ],
        correctAnswers: [
          "В исходных данных на проектирование",
          "В проектной документации",
        ],
      },
      {
        code: "20103066",
        text: "Какое из перечисленных требований к технологическим процессам, связанным с обращением сжиженного природного газа, указано неверно?",
        answers: [
          "Требования к резервированию систем и устройств (оборудования) определяются в руководствах по безопасности с учетом результатов анализа опасностей технологических процессов",
          "На этапе проектирования при выборе оборудования необходимо учитывать электрохимическую совместимость, технологические особенности и физические и механические свойства материалов",
          "В проектной документации должны быть предусмотрены решения по локализации аварийных проливов сжиженного природного газа и хладагентов на опасных производственных объектах сжиженного природного газа",
          "При проектировании технологических систем следует учитывать возможное снижение температуры среды при аварийном сбросе, дросселировании газового потока, испарении жидкой фазы",
        ],
        correctAnswers: [
          "Требования к резервированию систем и устройств (оборудования) определяются в руководствах по безопасности с учетом результатов анализа опасностей технологических процессов",
        ],
      },
      {
        code: "20103067",
        text: "В какой документации определяется защита оборудования, несущих конструкций и фундаментов от криогенного воздействия при возможных аварийных утечках СПГ?",
        answers: [
          "В исполнительной документации",
          "В эксплуатационной документации",
          "В нормативной документации",
          "В технической документации завода-изготовителя",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20103068",
        text: "Какие меры должны быть предусмотрены для насосов, предназначенных для перекачки сжиженного горючего газа?",
        answers: [
          "Только меры по защите от превышения давления",
          "Только меры по обеспечению бескавитационных режимов работы",
          "Только меры по предотвращению образования газовых пробок внутри корпуса насоса",
          "Все перечисленные меры",
        ],
        correctAnswers: ["Все перечисленные меры"],
      },
      {
        code: "20103069",
        text: "Какая предусматривается прокладка технологических трубопроводов для криогенных, взрывопожароопасных и токсичных сред?",
        answers: ["Надземная", "Наземная", "Подземная", "Все перечисленные"],
        correctAnswers: ["Надземная"],
      },
      {
        code: "20103070",
        text: "Применение каких соединений технологических трубопроводов, транспортирующих криогенные среды, и трубопроводной арматуры, установленной на них, не допускается?",
        answers: [
          "Резьбовых соединений",
          "Сварных соединений",
          "Фланцевых соединений",
        ],
        correctAnswers: ["Резьбовых соединений"],
      },
      {
        code: "20103071",
        text: "В каком случае допускается применение фланцевых соединений с гладкой уплотнительной поверхностью (соединительный выступ)?",
        answers: [
          "При использовании спирально-навитых прокладок с двумя ограничительными кольцами на давление до 4,0 МПа включительно",
          "При использовании спирально-навитых прокладок с двумя ограничительными кольцами на давление до 6,0 МПа включительно",
          "Для технологических трубопроводов со взрывоопасными средами в составе блоков I категории взрывоопасности",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "При использовании спирально-навитых прокладок с двумя ограничительными кольцами на давление до 4,0 МПа включительно",
        ],
      },
      {
        code: "20103072",
        text: "В каком случае допускается транзитная прокладка технологических трубопроводов через помещения?",
        answers: [
          "Если это прописано в техническом регламенте",
          "Если это прописано в проектной документации",
          "Если это прописано в эксплуатационной документации",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20103073",
        text: "На какое воздействие должны быть рассчитаны конструкция и материалы ограждающей стены или обвалования вокруг каждого резервуара для хранения СГГ и ЛВЖ?",
        answers: [
          "Только на тепловое воздействие от горящего в пределах ограждения разлитого СГГ и ЛВЖ",
          "Только на гидродинамическое воздействие СГГ и ЛВЖ",
          "Только на низкотемпературное воздействие СГГ и ЛВЖ",
          "На все перечисленные виды воздействия СГГ и ЛВЖ",
        ],
        correctAnswers: ["На все перечисленные виды воздействия СГГ и ЛВЖ"],
      },
      {
        code: "20103074",
        text: "Какое оборудование ОПО СПГ следует располагать в корпусе основания гравитационного типа?",
        answers: [
          "Резервуары хранения хладагента",
          "Оборудование отгрузки СПГ",
          "Оборудование производства СПГ",
          "Факельные системы отпарного газа",
        ],
        correctAnswers: ["Резервуары хранения хладагента"],
      },
      {
        code: "20103075",
        text: "Какая факельная система для сбросов газов от технологических установок не предусматривается на ОПО СПГ?",
        answers: [
          "Факельная система высокого давления",
          'Факельная система "холодных" газов',
          'Факельная система "теплых" газов',
          "Факельная система отпарного газа изотермического хранения и отгрузки сжиженного природного газа",
        ],
        correctAnswers: ["Факельная система высокого давления"],
      },
      {
        code: "20103076",
        text: "Какое устройство выходов должны иметь площадки обслуживания факельных стволов, расположенных в составе единой конструкции?",
        answers: [
          "Не менее двух выходов в противоположных направлениях",
          "Не менее двух выходов в одном направлении",
          "Не более одного выхода",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Не менее двух выходов в противоположных направлениях",
        ],
      },
      {
        code: "20103077",
        text: "Какое значение не должно превышать соотношение расчетной скорости сброса к скорости звука в факельных системах?",
        answers: ["0,95", "1,0", "1,15", "1,25"],
        correctAnswers: ["0,95"],
      },
      {
        code: "20103078",
        text: "В каком случае допускается учитывать сбросы жидкой фазы рабочих сред в факельные системы?",
        answers: [
          "При расчетном обосновании при проектировании факельной системы",
          "При выполнении работ в соответствии с проектной документацией",
          "При сбросе в резервную факельную установку",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "При расчетном обосновании при проектировании факельной системы",
        ],
      },
      {
        code: "20103079",
        text: "Для какого оборудования объектов сжиженного природного газа аварийное освобождение допускается осуществлять за счет испарения среды в факельную систему?",
        answers: [
          "Для оборудования, содержащего жидкости, температура кипения которых при абсолютном давлении 0,1013 МПа не превышает минус 40 °С",
          "Для оборудования, содержащего жидкости, температура кипения которых при абсолютном давлении 0,1013 МПа не превышает минус 30 °С",
          "Для оборудования, содержащего жидкости, температура кипения которых при абсолютном давлении 0,1013 МПа не превышает минус 37 °С",
          "Для оборудования, содержащего жидкости, температура кипения которых при абсолютном давлении 0,1013 МПа не превышает минус 35 °С",
        ],
        correctAnswers: [
          "Для оборудования, содержащего жидкости, температура кипения которых при абсолютном давлении 0,1013 МПа не превышает минус 40 °С",
        ],
      },
      {
        code: "20103080",
        text: "Какие условия должны быть выполнены при проведении испытания технологических трубопроводов объектов сжиженного природного газа пневматическим способом?",
        answers: [
          "Величина давления пневматического испытания трубопровода устанавливается в проектной документации, но должна составлять не менее 1,1 расчетного давления",
          "Испытания труб в организации-изготовителе должны быть выполнены гидравлическим способом на давление не более 0,95 предела текучести материалов",
          "Испытания трубопроводов следует проводить с применением метода акустико-эмиссионного контроля",
          "Все перечисленные условия",
        ],
        correctAnswers: ["Все перечисленные условия"],
      },
      {
        code: "20103081",
        text: "Из какого материала должен быть выполнен внутренний резервуар (первичный контейнер), предназначенный для хранения СПГ?",
        answers: [
          "Из хладостойкой стали",
          "Из бетона",
          "Из чугуна",
          "Из полимера",
        ],
        correctAnswers: ["Из хладостойкой стали"],
      },
      {
        code: "20103082",
        text: "Из какого материала должен быть выполнен внешний резервуар (вторичный контейнер), предназначенный для хранения СПГ, объемом хранения более 60 000 м³?",
        answers: [
          "Из полимера",
          "Из стали",
          "Из чугуна",
          "Из предварительно напряженного железобетона",
        ],
        correctAnswers: ["Из предварительно напряженного железобетона"],
      },
      {
        code: "20103083",
        text: "В каком случае допускается выполнение внешнего резервуара (вторичного контейнера) из криогенной хладостойкой стали, предназначенного для хранения СПГ?",
        answers: [
          "Для изотермических резервуаров СПГ объемом хранения до 60 000 м³",
          "Для изотермических резервуаров СПГ объемом хранения до 70 000 м³",
          "В случае опасности, связанной с разгерметизацией внутреннего резервуара",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "Для изотермических резервуаров СПГ объемом хранения до 60 000 м³",
        ],
      },
      {
        code: "20103084",
        text: "Чем должна обеспечиваться продувка трубопроводной обвязки насосных колонн резервуаров СПГ?",
        answers: [
          "Инертным газом",
          "Паром",
          "Техническим воздухом",
          "Всем перечисленным",
        ],
        correctAnswers: ["Инертным газом"],
      },
      {
        code: "20103085",
        text: "На каких трубопроводах должен быть предусмотрен контроль значения температуры СПГ?",
        answers: [
          "Только на трубопроводах приема сжиженного природного газа в резервуар",
          "Только на трубопроводах нагнетания насосов",
          "Только на трубопроводах линии рециркуляции",
          "На всех перечисленных трубопроводах",
        ],
        correctAnswers: ["На всех перечисленных трубопроводах"],
      },
      {
        code: "20103086",
        text: "Какое из перечисленных требований допускается при проведении испытаний резервуаров СПГ?",
        answers: [
          "Подвергать мембранные внешние резервуары испытаниям на прочность и герметичность",
          "Проводить испытания внешнего резервуара двустенного изотермического резервуара (двухоболочечного с полной герметизацией) гидравлическим способом на прочность и герметичность внутреннего резервуара",
          "Проводить испытания двустенных изотермических резервуаров (двухоболочечных с полной герметизацией) пневматическим способом",
        ],
        correctAnswers: [
          "Подвергать мембранные внешние резервуары испытаниям на прочность и герметичность",
        ],
      },
      {
        code: "20103087",
        text: "Чем должна быть проведена продувка и осушка изотермического резервуара перед захолаживанием?",
        answers: ["Азотом", "Паром", "Техническим воздухом", "Аммиаком"],
        correctAnswers: ["Азотом"],
      },
      {
        code: "20103088",
        text: "Какой документ должен быть разработан на проведение пусконаладочных работ (включая осушку, продувку, захолаживание изотермического резервуара) и эксплуатацию изотермического резервуара ОПО СПГ?",
        answers: [
          "Технологический регламент",
          "Технический регламент",
          "Технологическая инструкция",
          "Проект производства работ",
        ],
        correctAnswers: ["Технологический регламент"],
      },
      {
        code: "20103089",
        text: "В какой документации должно определяться время срабатывания отсечных клапанов на трубопроводах приема/отгрузки СПГ при аварийных ситуациях?",
        answers: [
          "В проектной документации",
          "В эксплуатационной документации",
          "В нормативной документации",
          "В исполнительной документации",
        ],
        correctAnswers: ["В проектной документации"],
      },
      {
        code: "20103090",
        text: "Какую систему продувки должна предусматривать конструкция стендера, включая шарнирные соединения на трубопроводах для перекачки СПГ?",
        answers: [
          "Воздухом",
          "Аргоном",
          "Аммиаком",
          "Паром",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20103091",
        text: "Каким образом определяется полный перечень испытаний стендеров на площадке?",
        answers: [
          "Программой, разработанной организацией-изготовителем (поставщиком) стендеров",
          "Нормативной документацией",
          "Программой производственного контроля",
          "Эксплуатационной документацией",
        ],
        correctAnswers: [
          "Программой, разработанной организацией-изготовителем (поставщиком) стендеров",
        ],
      },
      {
        code: "20103092",
        text: "Кем определяется порядок организации и проведения работ по техническому обслуживанию и ремонту технологического оборудования ОПО СПГ?",
        answers: [
          "Эксплуатирующей организацией",
          "Проектной организацией",
          "Экспертной организацией",
          "Специализированной организацией",
        ],
        correctAnswers: ["Эксплуатирующей организацией"],
      },
      {
        code: "20103093",
        text: "Какие из перечисленных процедур должны быть проведены при количественном анализе риска аварий на ОПО СПГ?",
        answers: [
          "Только определение вероятностей (частот) возникновения инцидентов и аварий",
          "Только расчет зон действия поражающих факторов",
          "Только идентификация опасностей, которые могут привести к инцидентам и авариям",
          "Только оценка последствий аварий",
          "Все перечисленные процедуры",
        ],
        correctAnswers: ["Все перечисленные процедуры"],
      },
      {
        code: "20103094",
        text: "Каким образом должны быть оформлены результаты анализа риска аварии на ОПО СПГ?",
        answers: [
          "Так, чтобы выполненные расчеты и выводы могли быть проверены и повторены специалистами, которые не участвовали при первоначальной процедуре оценки риска аварии",
          "Так, чтобы выполненные расчеты и выводы могли быть проверены и повторены только теми специалистами, которые участвовали при первоначальной процедуре оценки риска аварии",
          "Так, чтобы выполненные расчеты и выводы могли быть проверены и повторены любыми специалистами",
        ],
        correctAnswers: [
          "Так, чтобы выполненные расчеты и выводы могли быть проверены и повторены специалистами, которые не участвовали при первоначальной процедуре оценки риска аварии",
        ],
      },
      {
        code: "20103095",
        text: "В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
          "В целях обеспечения готовности подразделений муниципальной пожарной охраны при возникновении аварии",
          "В целях обеспечения соответствия объекта требованиям промышленной безопасности",
          "В целях обеспечения готовности персонала при возникновении аварии",
        ],
        correctAnswers: [
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
        ],
      },
      {
        code: "20103096",
        text: "Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?",
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          "Для всех ОПО без исключения",
          "Только для ОПО I и II классов опасности",
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "20103097",
        text: "Каким образом необходимо разработать план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах в случае, если 2 и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?",
        answers: [
          "Допускается разработка единого плана мероприятий для 2 и более объектов",
          "Допускается разработка единого плана мероприятий для объектов, расположенных на одном участке, и несколько - для объектов на смежных участках",
          "Необходимо разработать планы мероприятий для каждого объекта отдельно",
          "Допускается разработка единого плана мероприятий только для объектов, находящихся на расстоянии менее 100 м друг от друга",
        ],
        correctAnswers: [
          "Допускается разработка единого плана мероприятий для 2 и более объектов",
        ],
      },
      {
        code: "20103098",
        text: "Сколько необходимо разработать планов мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, в случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?",
        answers: [
          "Разрабатывается единый план мероприятий для двух и более объектов",
          "Разрабатывается единый план мероприятий для объектов, расположенных на расстоянии менее 100 м друг от друга",
          "Для каждого объекта разрабатывается свой план мероприятий",
          "Разрабатывается единый план мероприятий для объектов, расположенных на расстоянии менее 50 м друг от друга",
        ],
        correctAnswers: [
          "Разрабатывается единый план мероприятий для двух и более объектов",
        ],
      },
      {
        code: "20103099",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов I класса опасности (за исключением объектов, на которых ведутся горные работы)?",
        answers: ["5 лет", "1 год", "3 года", "7 лет"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20103100",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов II класса опасности (за исключением объектов, на которых ведутся горные работы)?",
        answers: ["5 лет", "1 год", "3 года", "7 лет"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20103101",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены на опасных производственных объектах III класса опасности (за исключением объектов, на которых ведутся горные работы)?",
        answers: ["5 лет", "1 год", "3 года", "7 лет"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20103102",
        text: "На какой срок составляется план мероприятий по локализации и ликвидации последствий аварий для объектов, на которых ведутся горные работы в подземных условиях?",
        answers: ["На 6 месяцев", "На 1 год", "На 1 квартал", "На 2 года"],
        correctAnswers: ["На 6 месяцев"],
      },
      {
        code: "20103103",
        text: "Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?",
        answers: [
          "Наименьший срок, предусмотренный для этих объектов",
          "Наибольший срок, предусмотренный для этих объектов",
          "3 года",
          "5 лет",
        ],
        correctAnswers: ["Наименьший срок, предусмотренный для этих объектов"],
      },
      {
        code: "20103104",
        text: "В какой срок пересматривается план мероприятий по локализации и ликвидации последствий аварий до истечения срока действия предыдущего плана мероприятий?",
        answers: [
          "Не менее чем за 15 календарных дней",
          "Не менее чем за 7 календарных дней",
          "Не менее чем за 10 календарных дней",
          "Не менее чем за 30 календарных дней",
        ],
        correctAnswers: ["Не менее чем за 15 календарных дней"],
      },
      {
        code: "20103105",
        text: "В каком из перечисленных случаев план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах должен быть пересмотрен?",
        answers: [
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
          "Не позднее 45 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
          "Не позднее 90 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
          "Не позднее 60 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
        ],
        correctAnswers: [
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
        ],
      },
      {
        code: "20103106",
        text: "Кто утверждает план мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Руководитель (заместитель руководителя)",
          "Главный инженер организации",
          "Главный механик",
          "Сотрудник, ответственный за организацию и осуществление производственного контроля",
        ],
        correctAnswers: ["Руководитель (заместитель руководителя)"],
      },
      {
        code: "20103107",
        text: "Кем утверждаются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
          "Уполномоченным представителем территориального управления Ростехнадзора",
          "Техническими специалистами организаций, эксплуатирующих объекты",
          "Специалистами военизированной газоспасательной службы",
        ],
        correctAnswers: [
          "Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
        ],
      },
      {
        code: "20103108",
        text: "С кем согласовываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
          "С руководителем Ростехнадзора",
          "С представителем Министерства энергетики",
        ],
        correctAnswers: [
          "С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
        ],
      },
      {
        code: "20103109",
        text: "Что из перечисленного не входит в общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Порядок действий в случае аварий на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
          "Характеристика объекта, в отношении которого разрабатывается план мероприятий",
          "Мероприятия, направленные на обеспечение безопасности населения",
          "Количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте, и их соответствие задачам по локализации и ликвидации последствий аварий",
        ],
        correctAnswers: [
          "Порядок действий в случае аварий на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
        ],
      },
      {
        code: "20103110",
        text: "Что определяется в специальном разделе плана мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Порядок действий в случае аварий на объекте",
          "Порядок обеспечения постоянной готовности сил и средств к локализации и ликвидации последствий аварий на объекте с указанием организаций, которые несут ответственность за поддержание этих сил и средств в надлежащей степени готовности",
          "Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах",
          "Характеристика объекта, в отношении которого разрабатывается план мероприятий",
        ],
        correctAnswers: ["Порядок действий в случае аварий на объекте"],
      },
      {
        code: "20103111",
        text: "Что из перечисленного должно быть предусмотрено планом мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Только порядок обеспечения постоянной готовности сил и средств к локализации и ликвидации последствий аварий на объекте с указанием организаций, которые несут ответственность за поддержание этих сил и средств в надлежащей степени готовности",
          "Только сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий, а также источники (места) их возникновения",
          "Только первоочередные действия при получении сигнала об авариях на объекте",
          "Только действия производственного персонала и аварийно-спасательных служб (формирований) по локализации и ликвидации аварий",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20103112",
        text: "Что необходимо предпринять организации, эксплуатирующей химически опасный производственный объект, в целях приведения его в соответствие требованиям Правил безопасности химически опасных производственных объектов?",
        answers: [
          "Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь",
          "Провести экспертизу с оценкой соответствия объекта требованиям и нормам безопасной эксплуатации",
          "Провести реконcтрукцию химически опасного производственного объекта",
          "Немедленно сообщить в территориальный надзорный орган о выявленных в рамках проведения производственного контроля несоответствиях требованиям правил безопасности химически опасных производственных объектов",
        ],
        correctAnswers: [
          "Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь",
        ],
      },
      {
        code: "20103113",
        text: "В каком документе указываются регламентированные значения параметров по ведению технологического процесса?",
        answers: [
          "В технологическом регламенте",
          "В технологической инструкции",
          "В проекте",
          "В техническом регламенте",
        ],
        correctAnswers: ["В технологическом регламенте"],
      },
      {
        code: "20103114",
        text: "Кем разрабатываются исходные данные на разработку документации на химически опасных производственных объектах?",
        answers: [
          "Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области",
          "Эксплуатирующей организацией",
          "Экспертной организацией",
          "Организацией, осуществляющей проведение экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области",
        ],
      },
      {
        code: "20103115",
        text: "Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?",
        answers: [
          "Применение компонентов в фазовом состоянии, затрудняющем или исключающем образование химически опасной смеси",
          "Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки",
          "Рациональный подбор взаимодействующих компонентов исходя из условия максимального снижения или исключения образования химически опасных смесей или продуктов",
          "Выбор значений параметров состояния технологической среды (состава, давления, температуры), снижающих ее химическую опасность",
        ],
        correctAnswers: [
          "Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки",
        ],
      },
      {
        code: "20103116",
        text: "В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?",
        answers: [
          "В течение времени, достаточного для исключения опасной ситуации",
          "В течение 8 часов",
          "В течение 24 часов",
          "В течение 20 часов",
        ],
        correctAnswers: [
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
      },
      {
        code: "20103117",
        text: "Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах III класса опасности?",
        answers: [
          "Не более 12 секунд",
          "Не более 120 секунд",
          "Не более 300 секунд",
          "Не более 240 секунд",
        ],
        correctAnswers: ["Не более 120 секунд"],
      },
      {
        code: "20103118",
        text: "Для каких из перечисленных целей запрещается использовать специальные системы аварийного освобождения?",
        answers: [
          "Для исключения образования химически опасных смесей в системах",
          "Для обеспечения минимально возможного времени освобождения химико-технологических систем от обращающихся химически опасных продуктов",
          "Для контроля и исключения образования химически опасных смесей в окружающей атмосфере производственного помещения",
          "Для исключения развития аварий",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20103119",
        text: "Куда следует направлять сбрасываемые химически опасные вещества?",
        answers: [
          "В закрытые системы для дальнейшей утилизации",
          "В промышленную канализацию",
          "В хозяйственно-бытовую канализацию",
          "На факельную установку",
        ],
        correctAnswers: ["В закрытые системы для дальнейшей утилизации"],
      },
      {
        code: "20103120",
        text: "В каком случае допускается объединение выбросов химически опасных веществ, содержащих вещества, способные при смешивании образовывать более опасные по воздействиям химические соединения?",
        answers: [
          "Если используется специальная система аварийного освобождения",
          "Если разработаны необходимые меры, предусмотренные документацией на химически опасный производственный объект",
          "Если выбросы рассчитываются в количествах, определяемых условиями безопасной остановки технологического процесса",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20103121",
        text: "В каком документе организация, эксплуатирующая химически опасный производственный объект I, II и III классов опасности, должна предусматривать действия работников по предупреждению аварий, локализации и ликвидации их последствий?",
        answers: [
          "В проекте производства работ",
          "В плане мероприятий по локализации и ликвидации последствий аварий",
          "В Положении об организации и осуществлении производственного контроля за соблюдением требований промышленной безопасности",
          "В технологическом регламенте",
        ],
        correctAnswers: [
          "В плане мероприятий по локализации и ликвидации последствий аварий",
        ],
      },
      {
        code: "20103122",
        text: "В соответствии с какими документами осуществляют ведение технологических процессов на химически опасных производственных объектах?",
        answers: [
          "В соответствии с технологическими регламентами",
          "В соответствии с исполнительной документацией",
          "В соответствии с проектной документацией",
          "В соответствии с техническими регламентами",
        ],
        correctAnswers: ["В соответствии с технологическими регламентами"],
      },
      {
        code: "20103123",
        text: "Какие существуют типы технологических регламентов в зависимости от степени освоенности производств и целей осуществляемых работ?",
        answers: [
          "Постоянные, временные, разовые и лабораторные",
          "Периодические, ежегодные",
          "Систематически пересматриваемые",
        ],
        correctAnswers: ["Постоянные, временные, разовые и лабораторные"],
      },
      {
        code: "20103124",
        text: "Для каких из перечисленных производств разрабатываются постоянные технологические регламенты?",
        answers: [
          "Только для новых производств в организации",
          "Только для действующих химико-технологических производств, в технологии которых произошли принципиальные изменения",
          "Только для производств с новой технологией по выпуску продукции",
          "Для всех перечисленных производств",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20103125",
        text: "Какой из перечисленных разделов не относится к постоянным, временным и разовым технологическим регламентам, связанным с необходимостью обеспечения промышленной безопасности технологических процессов?",
        answers: [
          "Характеристика производимой продукции",
          "Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств",
          "Контроль производства и управление технологическим процессом",
          "Нормы расхода основных видов сырья, материалов и энергоресурсов",
        ],
        correctAnswers: [
          "Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств",
        ],
      },
      {
        code: "20103126",
        text: 'Информацию о каких организациях должен содержать раздел технологического регламента "Общая характеристика производства"?',
        answers: [
          "Только об организации - разработчике технологического процесса",
          "Только об организации, выполняющей функции генерального проектировщика",
          "Только об организации - разработчике технологической части проектной документации",
          "Только об организации, выполнившей проектную документацию",
          "Обо всех перечисленных организациях",
        ],
        correctAnswers: ["Обо всех перечисленных организациях"],
      },
      {
        code: "20103127",
        text: 'Как производится описание технологической схемы в разделе "Описание химико-технологического процесса и схемы"?',
        answers: [
          "По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта",
          "По этапам технологического процесса, начиная с отгрузки сырья со склада",
          "По видам производственных операций, включая переработку сырья с использованием оборудования и выпуском готовой продукции",
        ],
        correctAnswers: [
          "По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта",
        ],
      },
      {
        code: "20103128",
        text: 'Что необходимо указывать в описании процессов разделения химических продуктов (горючих или их смесей с негорючими) в разделе "Описание химико-технологического процесса и схемы"?',
        answers: [
          "Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса",
          "Степень разделения агрессивных сред, показатели пожароопасности, токсичности используемых материалов",
          "Показатели взрывопожароопасности и токсичные свойства всех химических веществ, участвующих во всех стадиях процесса",
        ],
        correctAnswers: [
          "Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса",
        ],
      },
      {
        code: "20103129",
        text: 'Какие разновидности материального баланса допускается составлять в разделе технологического регламента "Материальный баланс"?',
        answers: [
          "Только на единицу выпускаемой продукции",
          "Только на единицу времени",
          "Только на один производственный поток или на мощность производства в целом",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20103130",
        text: "На основе каких данных составляется материальный баланс для действующих производств?",
        answers: [
          "Материальный баланс для действующих производств составляется по достигнутым показателям работы производств в последний год перед составлением технологического регламента",
          "Материальный баланс для действующих производств составляется по данным проектной документации",
          "Материальный баланс для действующих производств составляется по данным технологического регламента",
        ],
        correctAnswers: [
          "Материальный баланс для действующих производств составляется по достигнутым показателям работы производств в последний год перед составлением технологического регламента",
        ],
      },
      {
        code: "20103131",
        text: 'Какое из перечисленных требований не соответствует разделу технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          "Периодичность испытаний запорной регулирующей арматуры, исполнительных механизмов, периодичность проверок приборов и испытаний других технических средств, участвующих в схемах контроля, управления и противоаварийной защиты технологических процессов, должны быть взаимоувязаны",
          "Допускается разделение в отдельные таблицы перечней систем сигнализации (блокировок) и аналитического контроля",
          "Средства автоматики, используемые по плану мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, должны иметь особые пометки и выделения на технологической схеме",
          "Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается",
        ],
        correctAnswers: [
          "Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается",
        ],
      },
      {
        code: "20103132",
        text: "На кого возлагается ответственность за полноту и качество разработки разделов технологического регламента производства продукции и контроль за обеспечением его исполнения?",
        answers: [
          "На технологическую службу организации, производства, отделения, установки",
          "На организацию - разработчика процесса",
          "На руководителя организации",
          "На службу производственного контроля",
        ],
        correctAnswers: [
          "На технологическую службу организации, производства, отделения, установки",
        ],
      },
      {
        code: "20103133",
        text: "Кто утверждает все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?",
        answers: [
          "Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство",
          "Сотрудник, ответственный за организацию и осуществление производственного контроля",
          "Начальник отдела технического контроля организации, эксплуатирующей химико-технологическое производство",
          "Главный инженер организации",
        ],
        correctAnswers: [
          "Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство",
        ],
      },
      {
        code: "20103134",
        text: 'Кто подписывается в технологическом регламенте под грифом "согласовано"? Укажите все правильные ответы.',
        answers: [
          "Главный метролог организации",
          "Главный механик и главный энергетик организации",
          "Главный инженер",
          "Начальник производства",
        ],
        correctAnswers: [
          "Главный метролог организации",
          "Главный механик и главный энергетик организации",
        ],
      },
      {
        code: "20103135",
        text: "Какое количество копий технологических регламентов устанавливается требованиями Правил безопасности химически опасных производственных объектов?",
        answers: [
          "Не менее 6",
          "Не менее 15",
          "Не менее 10",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20103136",
        text: "Каким образом устанавливается и оформляется срок продления действия временного технологического регламента?",
        answers: [
          "Приказом руководителя организации",
          "Письменным распоряжением технического директора (главного инженера) организации",
          "Приказом комиссии организации",
          "Приказом технологической службы организации",
        ],
        correctAnswers: ["Приказом руководителя организации"],
      },
      {
        code: "20103137",
        text: "Кем разрабатываются все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?",
        answers: [
          "Организацией, эксплуатирующей химико-технологическое производство",
          "Организацией - разработчиком производственного процесса",
          "Комиссией во главе с руководителем территориального отделения Ростехнадзора",
          "Специализированной проектной организацией",
        ],
        correctAnswers: [
          "Организацией, эксплуатирующей химико-технологическое производство",
        ],
      },
      {
        code: "20103138",
        text: "Какой устанавливается срок действия постоянного технологического регламента?",
        answers: [
          "Не более 3 лет",
          "Не более 10 лет",
          "Не более 5 лет",
          "Не более 15 лет",
        ],
        correctAnswers: ["Не более 10 лет"],
      },
      {
        code: "20103139",
        text: "Каким образом определяется срок действия временного технологического регламента при отсутствии установленных планами норм освоения производства?",
        answers: [
          "Руководителем организации, учитывая объем работ",
          "Начальником производственного участка с учетом затрат",
          "Лицом, утверждающим технологический регламент",
          "В соответствии с проектной документацией",
        ],
        correctAnswers: ["Лицом, утверждающим технологический регламент"],
      },
      {
        code: "20103140",
        text: "Какой устанавливается срок действия разовых (опытных) технологических регламентов, в соответствии с которыми проводится наработка опытной продукции в течение нескольких лет?",
        answers: [
          "Не более 5 лет",
          "Не более года",
          "Не более 3 лет",
          "Не более 10 лет",
        ],
        correctAnswers: ["Не более 5 лет"],
      },
      {
        code: "20103141",
        text: "Кто устанавливает назначенный срок службы для технологического оборудования, машин и трубопроводной арматуры?",
        answers: [
          "Разработчик документации",
          "Организация-изготовитель",
          "Орган по сертификации на основании заключения испытательной лаборатории",
          "Эксплуатирующая организация",
        ],
        correctAnswers: ["Организация-изготовитель"],
      },
      {
        code: "20103142",
        text: "Какие дополнительные требования установлены при использовании технологического оборудования и трубопроводов, в которых обращаются коррозионно-активные вещества?",
        answers: [
          "Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии",
          "Технологическое оборудование и трубопроводы должны быть защищены металлическими коррозионностойкими покрытиями",
          "Контроль за технологическим оборудованием и трубопроводами, контактирующими с коррозионно-активными веществами, должен осуществляться не реже чем 2 раза в месяц",
          "Для защиты технологического оборудования запрещается использовать неметаллические покрытия",
        ],
        correctAnswers: [
          "Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии",
        ],
      },
      {
        code: "20103143",
        text: "Чем определяется количество насосов и компрессоров, используемых для перемещения химически опасных веществ в технологическом процессе?",
        answers: [
          "Необходимостью и условиями обеспечения непрерывности технологического процесса",
          "Свойствами перемещаемых продуктов",
          "Техническими характеристиками применяемых компрессоров и насосов",
          "Критическими параметрами технологического процесса",
        ],
        correctAnswers: [
          "Необходимостью и условиями обеспечения непрерывности технологического процесса",
        ],
      },
      {
        code: "20103144",
        text: "Что в химико-технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и их развития?",
        answers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления",
          "Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов",
          "Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы",
          "Запорная арматура, средства защиты от превышения давления, огнепреградители",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления",
        ],
      },
      {
        code: "20103145",
        text: "На чем основаны оптимальные методы и средства противоаварийной автоматической защиты?",
        answers: [
          "На обучающих программных продуктах, применяемых для моделирования аварийных ситуаций, утвержденных управлением Ростехнадзора",
          "На алгоритмах, разработанных по сценариям всех возможных аварий",
          "На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления",
          "На структуре сценария возможной аварийной ситуации и способах перевода объекта в безопасное состояние",
        ],
        correctAnswers: [
          "На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления",
        ],
      },
      {
        code: "20103146",
        text: "Какое минимальное количество датчиков должно устанавливаться на химически опасных производственных объектах I и II классов опасности для осуществления контроля за текущими показателями параметров, определяющими химическую опасность технологических процессов ХОПО?",
        answers: [
          "Не менее 2 независимых датчиков с раздельными точками отбора",
          "1 датчик",
          "Не менее 3 датчиков",
          "Не регламентируется",
        ],
        correctAnswers: [
          "Не менее 2 независимых датчиков с раздельными точками отбора",
        ],
      },
      {
        code: "20103147",
        text: "Какое из перечисленных требований к выполнению управляющих функций систем ПАЗ указано неверно?",
        answers: [
          "Команды управления, сформированные алгоритмами защит (блокировок), должны иметь приоритет по отношению к любым другим командам управления технологическим оборудованием",
          "В алгоритмах срабатывания защит следует предусматривать возможность включения блокировки команд управления оборудованием, технологически связанным с аппаратом, агрегатом или иным оборудованием, вызвавшим такое срабатывание",
          "Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы",
          "Системы ПАЗ должны реализоваться на принципах приоритетности защиты технологических процессов комплектно, с одновременной защитой отдельных единиц оборудования",
        ],
        correctAnswers: [
          "Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы",
        ],
      },
      {
        code: "20103148",
        text: "На каких объектах ХОПО технические решения по обеспечению надежности контроля параметров, имеющих критические значения, обосновываются разработчиком документации на ХОПО? Укажите все правильные ответы.",
        answers: [
          "На объектах ХОПО II класса опасности",
          "На объектах ХОПО I класса опасности",
          "На объектах ХОПО IV класса опасности",
          "На объектах ХОПО III класса опасности",
          "На всех объектах ХОПО",
        ],
        correctAnswers: [
          "На объектах ХОПО IV класса опасности",
          "На объектах ХОПО III класса опасности",
        ],
      },
      {
        code: "20103149",
        text: 'Какое из перечисленных требований соответствует нормам заполнения и хранения "Листа регистрации изменений и дополнений"?',
        answers: [
          "Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента",
          "Лист регистрации изменений и дополнений размещается в начале технологического регламента",
          "Запись в регистрационном листе выполняется чернилами черного цвета",
          "Регистрация изменений и дополнений выполняется руководителем организации",
        ],
        correctAnswers: [
          "Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента",
        ],
      },
      {
        code: "20103150",
        text: 'Какой устанавливается срок действия "Накопительной ведомости"?',
        answers: ["1 месяц", "6 месяцев", "3 месяца", "1 год"],
        correctAnswers: ["6 месяцев"],
      },
    ],

    20104: [
      {
        code: "20104000",
        text: "Что необходимо предпринять организации, эксплуатирующей химически опасный производственный объект, в целях приведения его в соответствие требованиям Правил безопасности химически опасных производственных объектов?",
        answers: [
          "Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь",
          "Провести экспертизу промышленной безопасности",
          "Провести реконструкцию химически опасного производственного объекта",
          "Все перечисленные действия",
        ],
        correctAnswers: [
          "Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь",
        ],
      },
      {
        code: "20104001",
        text: "В каком документе указываются регламентированные значения параметров по ведению технологического процесса?",
        answers: [
          "В декларации безопасности",
          "В технологическом регламенте",
          "В производственной инструкции",
          "В руководствах по безопасности",
        ],
        correctAnswers: ["В технологическом регламенте"],
      },
      {
        code: "20104002",
        text: "Кем разрабатываются исходные данные на разработку документации на химически опасных производственных объектах?",
        answers: [
          "Территориальным органом Ростехнадзора",
          "Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области",
          "Эксплуатирующей организацией",
          "Проектной организацией",
        ],
        correctAnswers: [
          "Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области",
        ],
      },
      {
        code: "20104003",
        text: "Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?",
        answers: [
          "Рациональный подбор взаимодействующих компонентов, исходя из условия максимального снижения или исключения образования химически опасных смесей или продуктов",
          "Применение компонентов в фазовом состоянии, затрудняющем или исключающем образование химически опасной смеси",
          "Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки",
          "Выбор значений параметров состояния технологической среды (состава, давления, температуры), снижающих ее химическую опасность",
        ],
        correctAnswers: [
          "Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки",
        ],
      },
      {
        code: "20104004",
        text: "В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечить способность функционирования средств противоаварийной защиты?",
        answers: [
          "В течение 24 часов",
          "В течение 8 часов",
          "В течение 12 часов",
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
        correctAnswers: [
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
      },
      {
        code: "20104005",
        text: "Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах I и II классов опасности?",
        answers: [
          "Не более 12 секунд",
          "Не более 25 секунд",
          "Не более 120 секунд",
          "Не более 170 секунд",
        ],
        correctAnswers: ["Не более 12 секунд"],
      },
      {
        code: "20104006",
        text: "Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах III класса опасности?",
        answers: [
          "Не более 210 секунд",
          "Не более 120 секунд",
          "Не более 240 секунд",
          "Не более 300 секунд",
        ],
        correctAnswers: ["Не более 120 секунд"],
      },
      {
        code: "20104007",
        text: "Для каких из перечисленных целей запрещается использовать специальные системы аварийного освобождения?",
        answers: [
          "Для исключения образования химически опасных смесей в самих системах",
          "Для исключения образования химически опасных смесей в окружающей атмосфере",
          "Для исключения развития аварий",
          "Для обеспечения минимально возможного времени освобождения",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20104008",
        text: "Куда следует направлять сбрасываемые химически опасные вещества?",
        answers: [
          "На факельную установку",
          "В специальные контейнеры",
          "В закрытые системы для дальнейшей утилизации",
          "Не регламентируется",
        ],
        correctAnswers: ["В закрытые системы для дальнейшей утилизации"],
      },
      {
        code: "20104009",
        text: "В каком случае допускается объединение выбросов химически опасных веществ, содержащих вещества, способные при смешивании образовывать более опасные по воздействиям химические соединения?",
        answers: [
          "Если разработаны необходимые меры, предусмотренные документацией на ХОПО",
          "Если выбросы рассчитываются в количествах, определяемых условиями безопасной остановки технологического процесса",
          "Если используются специальные системы аварийного освобождения",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20104010",
        text: "В каком документе организация, эксплуатирующая химически опасный производственный объект I, II и III классов опасности, должна предусматривать действия работников по предупреждению аварий, локализации и ликвидации их последствий?",
        answers: [
          "В производственной инструкции",
          "В плане мероприятий по локализации и ликвидации последствий аварий",
          "В положении о производственном контроле",
          "В технологическом регламенте",
        ],
        correctAnswers: [
          "В плане мероприятий по локализации и ликвидации последствий аварий",
        ],
      },
      {
        code: "20104011",
        text: "В соответствии с какими документами осуществляют ведение технологических процессов на химически опасных производственных объектах?",
        answers: [
          "В соответствии с инструкциями по эксплуатации технических устройств",
          "В соответствии с технологическими регламентами",
          "В соответствии с проектной документацией",
          "В соответствии с декларацией промышленной безопасности и производственными инструкциями",
        ],
        correctAnswers: ["В соответствии с технологическими регламентами"],
      },
      {
        code: "20104012",
        text: "Какие существуют типы технологических регламентов в зависимости от степени освоенности производств и целей осуществляемых работ?",
        answers: [
          "Постоянные, временные, разовые и лабораторные",
          "Только постоянные и временные",
          "Только опытные и пусковые",
        ],
        correctAnswers: ["Постоянные, временные, разовые и лабораторные"],
      },
      {
        code: "20104013",
        text: "Для каких из перечисленных производств разрабатываются постоянные технологические регламенты?",
        answers: [
          "Только для новых в данной организации производств",
          "Только для производств с новой технологией",
          "Только для действующих химико-технологических производств, в технологию которых внесены принципиальные изменения",
          "Все ответы неверны",
          "Для всех перечисленных производств",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20104014",
        text: "Какие технологические регламенты разрабатываются при выпуске товарной продукции на опытных и опытно-промышленных установках (в цехах), а также для опытных и опытно-промышленных работ, проводимых на действующих производствах?",
        answers: [
          "Разовые (опытные) технологические регламенты",
          "Временные технологические регламенты",
          "Постоянные технологические регламенты",
          "Лабораторные технологические регламенты",
        ],
        correctAnswers: ["Разовые (опытные) технологические регламенты"],
      },
      {
        code: "20104015",
        text: "В каком случае допускается наработка товарной продукции по лабораторным регламентам (пусковым запискам, производственным методикам)?",
        answers: [
          "Объемом до 1000 кг/год",
          "Объемом до 1500 кг/год",
          "Объемом до 2000 кг/год",
          "Ни в каком случае",
        ],
        correctAnswers: ["Объемом до 1000 кг/год"],
      },
      {
        code: "20104016",
        text: "Какой из перечисленных разделов не относится к постоянным, временным и разовым технологическим регламентам, связанным с необходимостью обеспечения промышленной безопасности технологических процессов?",
        answers: [
          "Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств",
          "Характеристика производимой продукции",
          "Описание химико-технологического процесса и схемы",
          "Нормы расхода основных видов сырья, материалов и энергоресурсов",
        ],
        correctAnswers: [
          "Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств",
        ],
      },
      {
        code: "20104017",
        text: 'Информацию о каких организациях должен содержать раздел технологического регламента "Общая характеристика производства"?',
        answers: [
          "Только об организации, выполнявшей проектную документацию",
          "Только об организации, выполнявшей функции генерального проектировщика",
          "Только об организации - разработчике технологической части проектной документации",
          "Только об организации - разработчике технологического процесса",
          "О всех перечисленных организациях",
        ],
        correctAnswers: ["О всех перечисленных организациях"],
      },
      {
        code: "20104018",
        text: 'Как производится описание технологической схемы в разделе "Описание химико-технологического процесса и схемы"?',
        answers: [
          "По стадиям технологического процесса, начиная с поступления и подготовки сырья и заканчивая отгрузкой готового продукта",
          "По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование",
          "По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование и заканчивая отгрузкой готового продукта",
        ],
        correctAnswers: [
          "По стадиям технологического процесса, начиная с поступления и подготовки сырья и заканчивая отгрузкой готового продукта",
        ],
      },
      {
        code: "20104019",
        text: 'Что необходимо указывать в описании процессов разделения химических продуктов (горючих или их смесей с негорючими) в разделе "Описание химико-технологического процесса и схемы"?',
        answers: [
          "Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса",
          "Только степень разделения сред",
          "Токсичные свойства веществ, участвующих на всех стадиях процесса",
        ],
        correctAnswers: [
          "Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса",
        ],
      },
      {
        code: "20104020",
        text: 'Какие разновидности материального баланса допускается составлять в разделе технологического регламента "Материальный баланс"?',
        answers: [
          "Только баланс на единицу времени",
          "Только баланс на единицу выпускаемой продукции",
          "Только баланс на один производственный поток или на мощность производства в целом",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20104021",
        text: "На основе каких данных составляется материальный баланс для действующих производств?",
        answers: [
          "Материальный баланс для действующих производств составляется по достигнутым показателям работы производств в последний год перед составлением технологического регламента",
          "Материальный баланс для действующих производств составляется по данным технологического регламента",
          "Материальный баланс для действующих производств составляется по данным проекта с учетом внесенных в проект изменений, включения или исключения дополнительных операций или стадий",
        ],
        correctAnswers: [
          "Материальный баланс для действующих производств составляется по достигнутым показателям работы производств в последний год перед составлением технологического регламента",
        ],
      },
      {
        code: "20104022",
        text: 'Какое из перечисленных требований не соответствует разделу технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          "Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается",
          "Допускается разделять в отдельные таблицы перечень систем сигнализации и блокировок и (или) перечень аналитического контроля",
          "Должны быть определены особо средства автоматики, используемые по плану мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах,",
          "Должны быть взаимоувязаны периодичность испытаний запорной регулирующей арматуры, исполнительных механизмов, периодичность проверок приборов и испытаний других технических средств, участвующих в схемах контроля, управления и противоаварийной защиты технологических процессов,",
        ],
        correctAnswers: [
          "Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается",
        ],
      },
      {
        code: "20104023",
        text: "Кем разрабатываются все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?",
        answers: [
          "Организацией - разработчиком процесса",
          "Организацией, эксплуатирующей химико-технологическое производство",
          "Специализированной организацией",
          "Территориальным органом Ростехнадзора",
        ],
        correctAnswers: [
          "Организацией, эксплуатирующей химико-технологическое производство",
        ],
      },
      {
        code: "20104024",
        text: "На кого возлагается ответственность за полноту и качество разработки разделов технологического регламента производства продукции и контроль за обеспечением его исполнения?",
        answers: [
          "На технологическую службу организации, производства, отделения, установки",
          "На организацию-разработчика процесса",
          "На руководителя организации, эксплуатирующей химико-технологическое производство",
          "На комиссию организации, эксплуатирующей химико-технологическое производство",
        ],
        correctAnswers: [
          "На технологическую службу организации, производства, отделения, установки",
        ],
      },
      {
        code: "20104025",
        text: "Кто утверждает все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?",
        answers: [
          "Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство",
          "Технический руководитель организации, эксплуатирующей химико-технологическое производство",
          "Комиссия организации, эксплуатирующей химико-технологическое производство",
          "Руководитель отдела технического контроля организации, эксплуатирующей химико-технологическое производство",
        ],
        correctAnswers: [
          "Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство",
        ],
      },
      {
        code: "20104026",
        text: 'Кто подписывается в технологическом регламенте под грифом "согласовано"? Укажите все правильные ответы.',
        answers: [
          "Главный механик и главный энергетик организации",
          "Главный метролог организации",
          "Специалист по охране труда",
          "Начальник производства",
        ],
        correctAnswers: [
          "Главный механик и главный энергетик организации",
          "Главный метролог организации",
        ],
      },
      {
        code: "20104027",
        text: "Какое количество копий технологических регламентов устанавливается требованиями Правил безопасности химически опасных производственных объектов?",
        answers: [
          "Не менее 3 копий",
          "Не менее 5 копий",
          "Не менее 10 копий",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20104028",
        text: "Какой устанавливается срок действия постоянного технологического регламента?",
        answers: [
          "Не более 10 лет",
          "Не более 15 лет",
          "Не более 20 лет",
          "Не более 12 лет",
        ],
        correctAnswers: ["Не более 10 лет"],
      },
      {
        code: "20104029",
        text: "Каким образом определяется срок действия временного технологического регламента при отсутствии установленных планами норм освоения производства?",
        answers: [
          "Приказом руководителя организации",
          "Территориальным органом Ростехнадзора",
          "Лицом, утверждающим технологический регламент",
          "Техническим руководителем организации",
        ],
        correctAnswers: ["Лицом, утверждающим технологический регламент"],
      },
      {
        code: "20104030",
        text: "Каким образом устанавливается и оформляется срок продления действия временного технологического регламента?",
        answers: [
          "Приказом руководителя организации",
          "Распоряжением технического руководителя организации",
          "Распоряжением специальной комиссии организации",
          "Приказом территориального органа Ростехнадзора",
        ],
        correctAnswers: ["Приказом руководителя организации"],
      },
      {
        code: "20104031",
        text: "В каком из перечисленных случаев должен быть составлен временный технологический регламент на новый срок? Укажите все правильные ответы.",
        answers: [
          "Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей",
          "Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса",
          "Если есть решение территориального органа Ростехнадзора",
          "Если есть решение технологической службы организации, производства, отделения, установки",
        ],
        correctAnswers: [
          "Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей",
          "Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса",
        ],
      },
      {
        code: "20104032",
        text: "Какой устанавливается срок действия разовых (опытных) технологических регламентов, в соответствии с которыми проводится наработка опытной продукции в течение нескольких лет?",
        answers: [
          "Не более 5 лет",
          "Не более 15 лет",
          "Не более 7 лет",
          "Не более 10 лет",
        ],
        correctAnswers: ["Не более 5 лет"],
      },
      {
        code: "20104033",
        text: "Кто устанавливает назначенный срок службы для технологических трубопроводов?",
        answers: [
          "Разработчик документации",
          "Организация-изготовитель",
          "Территориальный орган Ростехнадзора",
          "Орган по сертификации",
        ],
        correctAnswers: ["Разработчик документации"],
      },
      {
        code: "20104034",
        text: "Кто устанавливает назначенный срок службы для технологического оборудования, машин и трубопроводной арматуры?",
        answers: [
          "Территориальный орган Ростехнадзора",
          "Организация-изготовитель",
          "Разработчик документации",
          "Орган по сертификации",
        ],
        correctAnswers: ["Организация-изготовитель"],
      },
      {
        code: "20104035",
        text: "Какие дополнительные требования установлены при использовании технологического оборудования и трубопроводов, в которых обращаются коррозионно-активные вещества?",
        answers: [
          "Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии",
          "Технологическое оборудование и трубопроводы должны быть защищены металлическими коррозионно-стойкими покрытиями",
          "Контроль за технологическим оборудованием и трубопроводами, контактирующими с коррозионно-активными веществами, должен осуществляться не реже чем 1 раз в месяц",
          "Для защиты технологического оборудования запрещается использовать неметаллические покрытия",
        ],
        correctAnswers: [
          "Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии",
        ],
      },
      {
        code: "20104036",
        text: "Чем определяется количество насосов и компрессоров, используемых для перемещения химически опасных веществ в технологическом процессе?",
        answers: [
          "Техническими характеристиками применяемых насосов и компрессоров",
          "Необходимостью и условиями обеспечения непрерывности технологического процесса",
          "Критическими параметрами технологического процесса",
          "Всем перечисленным",
        ],
        correctAnswers: [
          "Необходимостью и условиями обеспечения непрерывности технологического процесса",
        ],
      },
      {
        code: "20104037",
        text: "На чем основаны оптимальные методы и средства противоаварийной автоматической защиты?",
        answers: [
          "На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления",
          "На алгоритмах, разработанных по всем возможным сценариям возникновения и развития аварий",
          "На методиках и программных продуктах, применяемых для моделирования аварийных ситуаций, согласованных с Ростехнадзором",
          "На сценариях возможных аварийных ситуаций и способах перевода объекта в безопасное состояние",
        ],
        correctAnswers: [
          "На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления",
        ],
      },
      {
        code: "20104038",
        text: "Какое минимальное количество датчиков должно устанавливаться на химически опасных производственных объектах I и II классов опасности для осуществления контроля за текущими показателями параметров, определяющими химическую опасность технологических процессов ХОПО?",
        answers: [
          "Не менее 2 независимых датчиков с раздельными точками отбора",
          "1 датчик",
          "Не более 3 датчиков",
          "Не регламентируется",
        ],
        correctAnswers: [
          "Не менее 2 независимых датчиков с раздельными точками отбора",
        ],
      },
      {
        code: "20104039",
        text: "Какое из перечисленных требований к выполнению управляющих функций систем ПАЗ указано неверно?",
        answers: [
          "В алгоритмах срабатывания защит следует предусматривать возможность включения блокировки команд управления оборудованием, технологически связанным с аппаратом, агрегатом или иным оборудованием, вызвавшим такое срабатывание",
          "Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы",
          "Системы ПАЗ должны реализовываться на принципах приоритетности защиты технологических процессов комплектно, с одновременной защитой отдельных единиц оборудования",
          "Команды управления, сформированные алгоритмами защит (блокировок), должны иметь приоритет по отношению к любым другим командам управления технологическим оборудованием, в том числе к командам, формируемым оперативным персоналом АСУТП, если иное не оговорено в техническом задании на ее создание",
        ],
        correctAnswers: [
          "Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы",
        ],
      },
      {
        code: "20104040",
        text: "Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?",
        answers: [
          "Средствами автоматического управления объектом по определенному алгоритму",
          "Обслуживающим персоналом по инструкции",
          "Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых переключений",
          "Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию системы противоаварийной защиты",
        ],
        correctAnswers: ["Обслуживающим персоналом по инструкции"],
      },
      {
        code: "20104041",
        text: "На каких объектах ХОПО технические решения по обеспечению надежности контроля параметров, имеющих критические значения, обосновываются разработчиком документации на ХОПО? Укажите все правильные ответы.",
        answers: [
          "На объектах ХОПО III класса опасности",
          "На объектах ХОПО IV класса опасности",
          "На объектах ХОПО II класса опасности",
          "На объектах ХОПО I класса опасности",
        ],
        correctAnswers: [
          "На объектах ХОПО III класса опасности",
          "На объектах ХОПО IV класса опасности",
        ],
      },
      {
        code: "20104042",
        text: "По какой категории надежности должно осуществляться электроснабжение химически опасных производственных объектов?",
        answers: [
          "По I или II категории надежности",
          "Только по I категории надежности",
          "По III категории надежности",
          "Только по II категории надежности",
        ],
        correctAnswers: ["По I или II категории надежности"],
      },
      {
        code: "20104043",
        text: "Какой должна быть высота защитного ограждения каждой группы резервуаров относительно уровня расчетного объема разлившейся жидкости?",
        answers: [
          "На 0,5 м выше",
          "На 0,1 м выше",
          "На 0,2 м выше",
          "На 0,3 м выше",
        ],
        correctAnswers: ["На 0,2 м выше"],
      },
      {
        code: "20104044",
        text: 'Какое из перечисленных требований соответствует нормам заполнения и хранения "Листа регистрации изменений и дополнений"?',
        answers: [
          "Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента",
          "Регистрация изменений и дополнений выполняется техническим руководителем организации",
          "Запись в регистрационном листе выполняется чернилами черного цвета",
          "Лист регистрации изменений и дополнений размещается в начале технологического регламента",
        ],
        correctAnswers: [
          "Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента",
        ],
      },
      {
        code: "20104045",
        text: 'Какой устанавливается срок действия "Накопительной ведомости"?',
        answers: ["1 год", "6 месяцев", "3 месяца", "2 года"],
        correctAnswers: ["6 месяцев"],
      },
      {
        code: "20104046",
        text: "Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?",
        answers: [
          "Энергией сгорания парогазовой фазы",
          "Категорией взрывоопасности технологических блоков",
          "Приведенной массой вещества, участвующего во взрыве",
          "Радиусом зон разрушения",
        ],
        correctAnswers: ["Категорией взрывоопасности технологических блоков"],
      },
      {
        code: "20104047",
        text: 'Что является критерием взрывоопасности согласно "Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств"?',
        answers: [
          "Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом",
          "Класс опасности обращающихся в процессе веществ",
          "Температура самовоспламенения паров обращающихся в процессе веществ",
          "Скорость распространения горения обращающихся в процессе веществ",
        ],
        correctAnswers: [
          "Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом",
        ],
      },
      {
        code: "20104048",
        text: "Какого показателя категорий взрывоопасности технологических блоков не существует?",
        answers: [
          "I категории",
          "II категории",
          "III категории",
          "IV категории",
        ],
        correctAnswers: ["IV категории"],
      },
      {
        code: "20104049",
        text: "Какой следует принимать категорию взрывоопасности блоков, определяемую расчетом, если обращающиеся в технологическом блоке опасные вещества относятся к токсичным, высокотоксичным веществам?",
        answers: [
          "На одну выше",
          "I категории",
          "II категории",
          "III категории",
        ],
        correctAnswers: ["На одну выше"],
      },
      {
        code: "20104050",
        text: "В соответствии с чем осуществляется ведение технологических процессов на опасных производственных объектах химических, нефтехимических и нефтегазоперерабатывающих производств?",
        answers: [
          "В соответствии с технологическими регламентами на производство продукции",
          "В соответствии с рекомендациями Ростехнадзора",
          "В соответствии с заключением экспертизы промышленной безопасности",
          "В соответствии с распоряжением руководителя эксплуатирующей организации",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами на производство продукции",
        ],
      },
      {
        code: "20104051",
        text: "Каким образом осуществляется управление подачей инертных сред на установку с технологическими блоками любой категории взрывоопасности там, где при отклонении от регламентированных значений параметров возможно образование взрывоопасных смесей?",
        answers: [
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Qв ≤ 10 - ручное дистанционное",
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту",
          "Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, для установок с технологическими блоками II категории взрывоопасности - ручное дистанционное, для установок с технологическими блоками III категории взрывоопасности допускается ручное управление по месту",
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление",
        ],
        correctAnswers: [
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту",
        ],
      },
      {
        code: "20104052",
        text: "В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?",
        answers: [
          "В течение времени, достаточного для исключения опасной ситуации",
          "В течение 24 часов",
          "В течение 8 часов",
          "В течение 12 часов",
        ],
        correctAnswers: [
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
      },
      {
        code: "20104053",
        text: "Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?",
        answers: [
          "Системами ручного (без применения вычислительной техники) регулирования",
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
          "Эффективными быстродействующими системами, обеспечивающими непрерывность технологического процесса",
        ],
        correctAnswers: [
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
        ],
      },
      {
        code: "20104054",
        text: "Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?",
        answers: [
          "Время срабатывания установлено для каждого технологического блока в соответствии с категорией взрывоопасности",
          "Время срабатывания определяется расчетом",
          "Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и установлено для блоков III категории",
          "Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и установлено для блоков I и II категорий",
        ],
        correctAnswers: ["Время срабатывания определяется расчетом"],
      },
      {
        code: "20104055",
        text: "Что относится к технологическим трубопроводам?",
        answers: [
          "Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия сырья, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии",
          "Трубопроводы, предназначенные для транспортирования различных веществ, необходимых для ведения технологического процесса или эксплуатации оборудования",
          "Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования",
        ],
        correctAnswers: [
          "Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования",
        ],
      },
      {
        code: "20104056",
        text: "В зависимости от чего осуществляется выбор конструкции и конструкционных материалов, уплотнительных устройств для насосов и компрессоров?",
        answers: [
          "В зависимости от свойств перемещаемой среды",
          "В зависимости от вида взрывозащиты",
          "В зависимости от конструктивных особенностей трубопровода",
          "В зависимости от всех перечисленных факторов",
        ],
        correctAnswers: ["В зависимости от свойств перемещаемой среды"],
      },
      {
        code: "20104057",
        text: "Какими блокировками на отключение должны быть оснащены насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?",
        answers: [
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой среды и достижении критических значений параметров в расходной и приемной емкостях",
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости в корпусе насоса, достижении критических значений в приемной емкости",
          "Блокировками, исключающими пуск и (или) прекращающими работу только при отсутствии перемещаемой жидкости внутри корпуса насоса",
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
        correctAnswers: [
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
      },
      {
        code: "20104058",
        text: "Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?",
        answers: [
          "На установках с технологическими блоками I категории взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками II и III категорий взрывоопасности - не регламентируется",
          "На установках с технологическими блоками I и II категорий взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками III категории взрывоопасности - не регламентируется",
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
          "Давление теплоносителя (хладагента) должно быть равным давлению нагреваемых (охлаждаемых) горючих веществ",
        ],
        correctAnswers: [
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
        ],
      },
      {
        code: "20104059",
        text: "Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?",
        answers: [
          "Заказчиком",
          "Разработчиком процесса",
          "Разработчиком проекта",
        ],
        correctAnswers: ["Разработчиком процесса"],
      },
      {
        code: "20104060",
        text: "Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся жидкости и горючие жидкости на сливоналивных эстакадах?",
        answers: [
          "Должно быть организовано управление только по месту",
          "Должно быть организовано управление только дистанционно (из безопасного места)",
          "Должно быть организовано управление по месту и дистанционно (из безопасного места)",
        ],
        correctAnswers: [
          "Должно быть организовано управление по месту и дистанционно (из безопасного места)",
        ],
      },
      {
        code: "20104061",
        text: "Какие сведения являются основополагающими для выбора оборудования при разработке технологических процессов?",
        answers: [
          "Расчетные данные, которым должны соответствовать параметры оборудования, и показатели надежности",
          "Расчетные данные, которым должны соответствовать параметры оборудования, и требования действующих нормативных документов",
          "Требования действующих нормативных документов, расчетные данные, которым должны соответствовать параметры оборудования",
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
        correctAnswers: [
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
      },
      {
        code: "20104062",
        text: "В каком документе указываются данные о сроке службы технологического оборудования и трубопроводной арматуры производителем?",
        answers: [
          "В паспорте оборудования и трубопроводной арматуры",
          "В техническом задании на изготовление оборудования",
          "В технологическом регламенте",
          "В производственной инструкции",
        ],
        correctAnswers: ["В паспорте оборудования и трубопроводной арматуры"],
      },
      {
        code: "20104063",
        text: "В соответствии с чем следует производить монтаж технологического оборудования и трубопроводов?",
        answers: [
          "Только в соответствии с проектной документацией",
          "Только в соответствии с рабочей документацией",
          "В соответствии со всем перечисленным",
        ],
        correctAnswers: ["В соответствии со всем перечисленным"],
      },
      {
        code: "20104064",
        text: "Какой должна быть температура наружных поверхностей оборудования и кожухов теплоизоляционных покрытий в местах, доступных для обслуживающего персонала?",
        answers: [
          "Не более 45 °С внутри помещений и 60 °С на наружных установках",
          "Не более 50 °С внутри помещений и 70 °С на наружных установках",
          "Не более 55 °С внутри помещений и 75 °С на наружных установках",
          "Не более 50 °С внутри помещений и 65 °С на наружных установках",
        ],
        correctAnswers: [
          "Не более 45 °С внутри помещений и 60 °С на наружных установках",
        ],
      },
      {
        code: "20104065",
        text: "Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?",
        answers: [
          "Оборудование должно быть изолировано от действующей системы, а нанесенный на него номер по технологической схеме должен быть закрашен",
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно должно быть изолировано от действующих систем",
          "Оборудование должно быть демонтировано",
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем",
        ],
        correctAnswers: [
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем",
        ],
      },
      {
        code: "20104066",
        text: "В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?",
        answers: [
          "При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях",
          "При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации",
          "При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости",
          "При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости",
        ],
        correctAnswers: [
          "При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации",
        ],
      },
      {
        code: "20104067",
        text: "В каких местах не допускается размещать фланцевые соединения трубопроводов с взрывопожароопасными, токсичными и едкими веществами?",
        answers: [
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
          "Над автодорогами",
          "На трубопроводах, проложенных по эстакадам",
          "Во всех перечисленных местах",
        ],
        correctAnswers: [
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
        ],
      },
      {
        code: "20104068",
        text: "Что должно устанавливаться на трубопроводах для транспортирования взрывопожароопасных продуктов?",
        answers: [
          "Арматура в соответствии с проектной документацией",
          "Арматура с резиновым уплотнением в затворе",
          "Арматура с тканевым уплотнением в затворе",
          "Арматура с пластмассовым уплотнением в затворе",
        ],
        correctAnswers: ["Арматура в соответствии с проектной документацией"],
      },
      {
        code: "20104069",
        text: "В каких случаях на трубопроводах следует применять арматуру под приварку?",
        answers: [
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков любой категории взрывоопасности",
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температуре, равной температуре кипения при регламентированном давлении",
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I и II категорий взрывоопасности и температуре, равной температуре кипения при регламентированном давлении",
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 3,5 МПа",
        ],
        correctAnswers: [
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температуре, равной температуре кипения при регламентированном давлении",
        ],
      },
      {
        code: "20104070",
        text: "Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предотвращения их развития?",
        answers: [
          "Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы",
          "Запорная арматура, средства защиты от превышения давления, огнепреградители",
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
          "Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20104071",
        text: "Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте и инструкциях",
          "Средства автоматики должны быть обозначены на мнемосхемах",
          "Средства автоматики должны быть обозначены только в технологическом регламенте",
          "Все перечисленные требования",
        ],
        correctAnswers: [
          "Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте и инструкциях",
        ],
      },
      {
        code: "20104072",
        text: "В соответствии с чем должны определяться оптимальные методы создания системы противоаварийной защиты на стадии формирования требований при проектировании автоматизированной системы управления технологическим процессом?",
        answers: [
          "В соответствии с методиками и программными продуктами, применяемыми для моделирования аварийных ситуаций, согласованными с Ростехнадзором",
          "В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности",
          "В соответствии с алгоритмами, разработанными по возможным сценариям возникновения и развития аварийных ситуаций",
          "В соответствии со сценариями возможных аварийных ситуаций и способами перевода объекта в безопасное состояние",
        ],
        correctAnswers: [
          "В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности",
        ],
      },
      {
        code: "20104073",
        text: "Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания противоаварийной автоматической защиты?",
        answers: [
          "Осуществляется средствами автоматического управления объектом по действующим программам",
          "Выполняется обслуживающим персоналом по инструкции",
          "Осуществляется средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений",
          "Осуществляется средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию системы противоаварийной защиты",
        ],
        correctAnswers: ["Выполняется обслуживающим персоналом по инструкции"],
      },
      {
        code: "20104074",
        text: "Кто обосновывает достаточность аппаратурного резервирования и его тип?",
        answers: [
          "Представитель Ростехнадзора",
          "Разработчик проекта",
          "Руководитель организации",
          "Разработчик технологического процесса",
        ],
        correctAnswers: ["Разработчик проекта"],
      },
      {
        code: "20104075",
        text: "Какие требования предъявляются к пневматическим системам контроля, управления и противоаварийной автоматической защиты?",
        answers: [
          "Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги",
          "Качество сжатого воздуха необходимо проверять не реже 1 раза в полгода",
          "Использование инертного газа для питания систем контрольно-измерительных приборов и устройств автоматического регулирования необходимо определять исходя из конкретных условий и потребностей объекта",
          "Все перечисленные требования",
        ],
        correctAnswers: [
          "Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги",
        ],
      },
      {
        code: "20104076",
        text: "Каким образом обеспечивается надежность обеспечения средств управления и системы противоаварийной защиты сжатым воздухом?",
        answers: [
          "Установкой резервного компрессора, который включается автоматически при остановке рабочего",
          "Переключением сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования на заводскую сеть сжатого воздуха",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
        correctAnswers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20104077",
        text: "Что должно быть учтено в системах управления и защиты электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?",
        answers: [
          "Должен быть предусмотрен автоматический ввод резерва между каждым из трех самостоятельных источников электроснабжения",
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
          "Должна быть обеспечена селективность защит на устройствах электроснабжающей и электропотребляющей организаций",
          "Должна быть обеспечена возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой",
        ],
        correctAnswers: [
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
        ],
      },
      {
        code: "20104078",
        text: "Какое требование к системам вентиляции указано неверно?",
        answers: [
          "Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания",
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
          "Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства",
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: "20104079",
        text: "Какая система отопления должна применяться в помещениях, имеющих взрывоопасные зоны?",
        answers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
          "Система водяного отопления",
          "Система парового отопления",
        ],
        correctAnswers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
        ],
      },
      {
        code: "20104080",
        text: "Какая максимальная температура поверхностей нагрева систем отопления должна быть в помещениях, имеющих взрывоопасные зоны?",
        answers: [
          "Максимальная температура не должна превышать 80 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 85 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 90 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 95 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
        correctAnswers: [
          "Максимальная температура не должна превышать 80 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
      },
      {
        code: "20104081",
        text: "Каков порядок сброса химически загрязненных стоков от отдельных технологических объектов в магистральную сеть канализации?",
        answers: [
          "Порядок сброса стоков в магистральную сеть канализации устанавливается организацией",
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны быть оборудованы устройствами для улавливания аварийных стоков",
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
          "Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть",
        ],
        correctAnswers: [
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
        ],
      },
      {
        code: "20104082",
        text: "На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?",
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          "На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на выделенной и огражденной площадке на территории находящихся в эксплуатации опасных производственных объектов",
          "На ведение газоопасных, огневых и ремонтных работ на объектах электроэнергетики",
          "На ведение газоопасных, огневых и ремонтных работ на объектах атомной энергетики",
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "20104083",
        text: "Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?",
        answers: [
          "Только возраст не менее 18 лет",
          "Только прошедшие медицинского осмотра в соответствии с требованиями законодательства Российской Федерации",
          "Только прошедшие обучения приемам и методам проведения работ",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20104084",
        text: "Кем из перечисленных должностных лиц согласовываются сроки проведения газоопасных работ на опасных производственных объектах подрядными организациями?",
        answers: [
          "Руководителем эксплуатирующей организации или его уполномоченным заместителем либо руководителем филиала или его уполномоченным заместителем с учетом особенностей использования электронной подписи",
          "Представителем территориального органа Ростехнадзора",
          "Лицом, ответственным за осуществление производственного контроля",
          "Лицом, ответственным за выполнение газоопасных работ",
        ],
        correctAnswers: [
          "Руководителем эксплуатирующей организации или его уполномоченным заместителем либо руководителем филиала или его уполномоченным заместителем с учетом особенностей использования электронной подписи",
        ],
      },
      {
        code: "20104085",
        text: "К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?",
        answers: [
          "К II группе",
          "К I группе",
          "К группе средней степени опасности",
          "К группе низкой степени опасности",
        ],
        correctAnswers: ["К II группе"],
      },
      {
        code: "20104086",
        text: "Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение месяца",
          "Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
      {
        code: "20104087",
        text: "Какой минимальный срок хранения установлен для журнала регистрации нарядов-допусков на проведение газоопасных работ?",
        answers: [
          "3 месяца со дня его окончания",
          "6 месяцев со дня его окончания",
          "2 месяца со дня его окончания",
          "1 месяц со дня его окончания",
        ],
        correctAnswers: ["6 месяцев со дня его окончания"],
      },
      {
        code: "20104088",
        text: "Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?",
        answers: [
          "Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск, на требуемый для окончания работ срок",
          "Руководитель структурного подразделения не более чем на 3 рабочих смены",
          "Лицо, зарегистрировавшее наряд-допуск, не более чем на 2 рабочие смены",
        ],
        correctAnswers: [
          "Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
        ],
      },
      {
        code: "20104089",
        text: "В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?",
        answers: [
          "Не менее 3 месяцев со дня закрытия наряда-допуска",
          "Не менее 2 месяцев со дня закрытия наряда-допуска",
          "Не менее 6 месяцев со дня закрытия наряда-допуска",
        ],
        correctAnswers: ["Не менее 6 месяцев со дня закрытия наряда-допуска"],
      },
      {
        code: "20104090",
        text: "Что следует применять для охлаждения контактных поверхностей передвижных шунтирующих устройств при производстве хлора методом электролиза?",
        answers: [
          "Обессоленную воду",
          "Технический воздух",
          "Азот",
          "Охлажденный воздух",
        ],
        correctAnswers: ["Обессоленную воду"],
      },
      {
        code: "20104091",
        text: "Чем следует продуть водородные коллекторы до остаточного содержания кислорода в отходящих газах не более 2 % объемных перед пуском электролизеров?",
        answers: ["Азотом", "Техническим воздухом", "Паром", "Водородом"],
        correctAnswers: ["Азотом"],
      },
      {
        code: "20104092",
        text: "Какая максимальная объемная доля кислорода в водороде устанавливается в общем коллекторе при электролизе диафрагменным методом?",
        answers: ["0,5 %", "1,5 %", "0,7 %", "1,0 %"],
        correctAnswers: ["0,5 %"],
      },
      {
        code: "20104093",
        text: "Какая максимальная объемная доля водорода в хлоре устанавливается в общем хлорном коллекторе при электролизе мембранным методом?",
        answers: ["0,5 %", "0,2 %", "0,3 %", "0,4 %"],
        correctAnswers: ["0,2 %"],
      },
      {
        code: "20104094",
        text: "Какие из перечисленных величин устанавливает разработчик процесса при электролизе мембранным методом? Укажите все правильные ответы.",
        answers: [
          "Величину максимальной рабочей плотности тока",
          "Величину перепада давления между катодным и анодным пространствами",
          "Величины давления хлора и водорода в электролизере",
          "Величину расхода питающего рассола в зависимости от типа электролизера",
        ],
        correctAnswers: [
          "Величину перепада давления между катодным и анодным пространствами",
          "Величины давления хлора и водорода в электролизере",
        ],
      },
      {
        code: "20104095",
        text: "В каком случае допускается шунтировать электролизер вручную при электролизе ртутным методом?",
        answers: [
          "При нагрузке менее 50 кА",
          "Если это устанавливается разработчиком электролизера",
          "При прекращении циркуляции ртути и остановке ртутного насоса на одном электролизере",
          "Ни в каком случае",
        ],
        correctAnswers: ["При нагрузке менее 50 кА"],
      },
      {
        code: "20104096",
        text: "С какой периодичностью следует проводить анализ на содержание паров ртути в воздухе рабочей зоны в помещениях, где работают с ртутью, при электролизе ртутным методом?",
        answers: ["Ежесменно", "Ежедекадно", "Еженедельно", "Ежемесячно"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20104097",
        text: "Какое из перечисленных требований при производстве жидкого хлора указано неверно?",
        answers: [
          "Давление воздуха, подаваемого в систему сжижения, должно превышать давление подаваемого хлоргаза не менее чем на 0,1 МПа (1 кгс/см²)",
          "Объемная доля водорода в абгазах конденсации должна быть не более 4 %",
          "Воздух (азот) для разбавления абгазов, поступающих на вторую стадию сжижения хлора, необходимо осушать",
          "Попадание абгазов конденсации в приемники жидкого хлора допускается не выше допустимых значений",
        ],
        correctAnswers: [
          "Попадание абгазов конденсации в приемники жидкого хлора допускается не выше допустимых значений",
        ],
      },
      {
        code: "20104098",
        text: "Что должно быть учтено при расчете толщины стенок сосудов, работающих под давлением в среде хлора?",
        answers: [
          "Только расчетный срок эксплуатации",
          "Расчетное давление и прибавка на стенки для компенсации коррозии на всех элементах сосуда по исходным данным разработчика процесса",
          "Прибавка на стенки для компенсации коррозии на всех элементах сосуда по исходным данным разработчика процесса",
          "Расчетный срок эксплуатации, расчетное давление и прибавка на стенки для компенсации коррозии не менее 1 мм и не менее 2 мм для компенсации коррозии на штуцерах",
        ],
        correctAnswers: [
          "Расчетный срок эксплуатации, расчетное давление и прибавка на стенки для компенсации коррозии не менее 1 мм и не менее 2 мм для компенсации коррозии на штуцерах",
        ],
      },
      {
        code: "20104099",
        text: "Каким должно быть расчетное давление сосудов, содержащих жидкий хлор?",
        answers: [
          "Не менее 1,6 МПа",
          "Менее 1,5 МПа",
          "Не менее 1,4 МПа",
          "Менее 1,2 МПа",
        ],
        correctAnswers: ["Не менее 1,6 МПа"],
      },
      {
        code: "20104100",
        text: "Какие требования устанавливаются к наружной поверхности трубопроводов, предназначенных для транспортировки жидкого и газообразного хлора?",
        answers: [
          "Трубопроводы должны быть окрашены в желтый цвет",
          "Трубопроводы должны иметь антикоррозионное покрытие, опознавательную окраску, предупреждающие знаки и маркировочные щитки",
          "Трубопроводы должны иметь гладкую поверхность, необходимую окраску",
          'На трубопровод должна быть нанесена предупредительная надпись "Осторожно, яд!"',
        ],
        correctAnswers: [
          "Трубопроводы должны иметь антикоррозионное покрытие, опознавательную окраску, предупреждающие знаки и маркировочные щитки",
        ],
      },
      {
        code: "20104101",
        text: "Каким документом определяются порог чувствительности датчиков системы контроля утечек хлора, их количество и месторасположение?",
        answers: [
          "Проектом",
          "Технологической инструкцией",
          "Производственной инструкцией",
          "Правилами безопасности при производстве, хранении, транспортировании и применении хлора",
        ],
        correctAnswers: ["Проектом"],
      },
      {
        code: "20104102",
        text: "Какую суммарную погрешность измерения концентрации хлора должны иметь сигнализаторы хлора помещений, где обращается химический гипохлорит натрия?",
        answers: [
          "Не более ± 25 %",
          "Не более ± 35 %",
          "Не более ± 30 %",
          "Не более ± 28 %",
        ],
        correctAnswers: ["Не более ± 25 %"],
      },
      {
        code: "20104103",
        text: "Какие из перечисленных складов жидкого хлора предназначены для хранения его в контейнерах-цистернах, контейнерах, баллонах в количествах, необходимых для текущих нужд организации в период между поставками?",
        answers: [
          "Расходные склады",
          "Кустовые склады",
          "Прицеховые склады",
          "Базисные склады",
        ],
        correctAnswers: ["Расходные склады"],
      },
      {
        code: "20104104",
        text: "Каким документом обосновывается минимально необходимое количество жидкого хлора, которое должно храниться в организациях?",
        answers: [
          "Проектом",
          "Технологическим регламентом",
          "Федеральными нормами и правилами",
          "Стандартом организации",
        ],
        correctAnswers: ["Проектом"],
      },
      {
        code: "20104105",
        text: "Каким способом хранение жидкого хлора в резервуарах (танках, контейнерах-цистернах) не осуществляется?",
        answers: [
          "В захоложенном состоянии при температуре ниже температуры окружающей среды",
          "При температуре кипения жидкого хлора при атмосферном давлении (изотермический способ хранения)",
          "При температуре окружающей среды",
          "При температуре плавления жидкого хлора",
        ],
        correctAnswers: ["При температуре плавления жидкого хлора"],
      },
      {
        code: "20104106",
        text: "Каким принимается радиус опасной зоны для складов жидкого хлора?",
        answers: [
          "Ширина (радиус) опасной зоны определяется расчетом, исходя из глубины распространения хлорного облака с поражающей концентрацией",
          "Ширина (радиус) опасной зоны принимается равным 150 м",
          "Ширина (радиус) опасной зоны принимается равным 250 м",
          "Ширина (радиус) опасной зоны определяется проектом",
        ],
        correctAnswers: [
          "Ширина (радиус) опасной зоны определяется расчетом, исходя из глубины распространения хлорного облака с поражающей концентрацией",
        ],
      },
      {
        code: "20104107",
        text: "С какой целью склады хлора оборудуются сплошным глухим ограждением высотой не менее двух метров?",
        answers: [
          "Для ограничения распространения газовой волны в начальный период аварийной ситуации и исключения доступа посторонних лиц на территорию склада",
          "Только для исключения доступа посторонних лиц на территорию склада",
          "Только для ограничения распространения газовой волны в начальный период аварийной ситуации",
        ],
        correctAnswers: [
          "Для ограничения распространения газовой волны в начальный период аварийной ситуации и исключения доступа посторонних лиц на территорию склада",
        ],
      },
      {
        code: "20104108",
        text: "Какие требования установлены в Правилах безопасности производств хлора и хлорсодержащих сред для закрытых складов жидкого хлора?",
        answers: [
          "Склады хлора должны располагаться только в подземных сооружениях",
          "Склады хлора должны быть расположены в наземных и полузаглубленных одноэтажных зданиях или подземных сооружениях",
          "Освещенность в складских помещениях должна быть не менее 10 лк",
          "Двери складов должны открываться внутрь",
        ],
        correctAnswers: [
          "Склады хлора должны быть расположены в наземных и полузаглубленных одноэтажных зданиях или подземных сооружениях",
        ],
      },
      {
        code: "20104109",
        text: "Кто осуществляет окраску вновь изготовленной транспортной тары для жидкого хлора и нанесение на нее надписей?",
        answers: [
          "Организация-изготовитель",
          "Специализированная организация",
          "Организация, которой на праве собственности либо ином законном основании принадлежит хлорная тара",
          "Не регламентируется",
        ],
        correctAnswers: ["Организация-изготовитель"],
      },
      {
        code: "20104110",
        text: "Каким должно быть давление сжатого воздуха (азота) при проведении операции по сливу-наливу жидкого хлора с использованием сжатого газа?",
        answers: [
          "Давление сжатого воздуха (азота) не должно превышать 1,5 МПа",
          "Давление сжатого воздуха (азота) не должно превышать 1,2 МПа",
          "Давление сжатого воздуха (азота) не должно превышать 2,5 МПа и должно быть не менее чем на 0,2 МПа выше давления в сосуде, в который передавливается хлор",
          "Давление сжатого воздуха (азота) должно быть не менее чем на 0,05 МПа выше давления в сосуде, в который передавливается хлор",
        ],
        correctAnswers: [
          "Давление сжатого воздуха (азота) не должно превышать 1,2 МПа",
        ],
      },
      {
        code: "20104111",
        text: "Размещение какого количества одновременно отправляемых или поступающих вагонов-цистерн с жидким хлором должен обеспечивать отстойный путь (тупик)?",
        answers: [
          "Всей партии, но не более 10 штук",
          "Всей партии, но не более 12 штук",
          "Всей партии, но не более 15 штук",
          "Всей партии, но не более 20 штук",
        ],
        correctAnswers: ["Всей партии, но не более 10 штук"],
      },
      {
        code: "20104112",
        text: "Нахождение каких стационарных систем трубопроводов на пунктах слива-налива жидкого хлора не предусмотрено Правилами безопасности производств хлора и хлорсодержащих сред?",
        answers: [
          "Трубопроводов для подачи в вагон-цистерну сжатого воздуха (азота) или хлора для передавливания",
          "Трубопроводов пара и горячей воды для подогрева",
          "Трубопроводов для слива-налива жидкого хлора",
          "Трубопроводов для отвода газообразного хлора на потребление или поглощение",
        ],
        correctAnswers: ["Трубопроводов пара и горячей воды для подогрева"],
      },
      {
        code: "20104113",
        text: "Оснащение какими устройствами вагонов-цистерн и контейнеров-цистерн для перевозки жидкого хлора указано верно?",
        answers: [
          "Тремя вентилями, предназначенными для выпуска газообразного хлора (абгазов) или подачи газа для передавливания жидкого хлора, соединенными с укороченными сифонами, исключающими переполнение вагона-цистерны сверх установленной нормы налива",
          "Тремя предохранительными клапанами",
          "Двумя запорными клапанами для налива (слива) жидкого хлора с сифонными трубами и скоростными или внутренними отсечными клапанами, автоматически прекращающими выход жидкого хлора при разрыве трубопровода",
          "Двумя штуцерами с вентилем для съемного манометра",
        ],
        correctAnswers: [
          "Двумя запорными клапанами для налива (слива) жидкого хлора с сифонными трубами и скоростными или внутренними отсечными клапанами, автоматически прекращающими выход жидкого хлора при разрыве трубопровода",
        ],
      },
      {
        code: "20104114",
        text: "Кем должен быть осмотрен прибывший в организацию вагон-цистерна для перевозки жидкого хлора с целью проверки исправности ходовой части вагона-цистерны, а также крепления котла вагона-цистерны к раме?",
        answers: [
          "Представителем организации-грузополучателя",
          "Представителем организации-грузоперевозчика",
          "Уполномоченными лицами организации-грузоотправителя",
          "Представителем организации-разработчика",
        ],
        correctAnswers: ["Уполномоченными лицами организации-грузоотправителя"],
      },
      {
        code: "20104115",
        text: "На основании какой документации следует производить приемку и опорожнение вагонов-цистерн и контейнеров-цистерн с жидким хлором?",
        answers: [
          "Инструкции, утвержденной техническим руководителем принимающей организации",
          "Инструкции, утвержденной техническим руководителем отправляющей организации",
          "Разрешения территориального органа Ростехнадзора",
          "Правил безопасности при производстве, хранении, транспортировании и применении хлора",
        ],
        correctAnswers: [
          "Инструкции, утвержденной техническим руководителем принимающей организации",
        ],
      },
      {
        code: "20104116",
        text: "Какое минимальное время пребывания под навесом заполненных хлором контейнеров или баллонов?",
        answers: [
          "Не менее одних суток",
          "Не менее 12 часов",
          "Не менее двух суток",
          "Не менее 8 часов",
        ],
        correctAnswers: ["Не менее одних суток"],
      },
      {
        code: "20104117",
        text: "Какую функцию не должен обеспечивать кустовой склад хлора?",
        answers: [
          "Прием, хранение затаренного хлора с учетом повагонных поставок хлора",
          "Выполнение заявок потребителей на отправку хлора",
          "Организацию сбора порожней тары и ее упорядоченный возврат организации-наполнителю",
          "Розлив хлора в контейнеры и баллоны",
        ],
        correctAnswers: ["Розлив хлора в контейнеры и баллоны"],
      },
      {
        code: "20104118",
        text: "Какие требования предъявляются к размещению сосудов с хлором на складах при вертикальной укладке?",
        answers: [
          "У стен следует размещать не более 2 рядов баллонов и 1 ряд контейнеров, в проходах соответственно - 4 и 2 ряда, необходимо исключить возможность их падения или перемещения и обеспечить свободный доступ к запорным вентилям",
          "У стен следует размещать не более 3 рядов баллонов и 2 рядов контейнеров, в проходах соответственно - 4 и 3 ряда, необходимо исключить свободный доступ к запорным вентилям",
          "У стен следует размещать не более 4 рядов баллонов и 3 рядов контейнеров, размещение сосудов в проходах запрещено",
          "У стен следует размещать не более 2 рядов баллонов и 2 рядов контейнеров, в проходах соответственно - 4 и 2 ряда",
        ],
        correctAnswers: [
          "У стен следует размещать не более 2 рядов баллонов и 1 ряд контейнеров, в проходах соответственно - 4 и 2 ряда, необходимо исключить возможность их падения или перемещения и обеспечить свободный доступ к запорным вентилям",
        ],
      },
      {
        code: "20104119",
        text: "В каком случае допускается перевозка сосудов с истекшим сроком технического освидетельствования, заполненных хлором?",
        answers: [
          "Если срок технического освидетельствования истек не более 1 месяца назад",
          "Если сосуд заполнен хлором наполовину",
          "Если это согласовано с Ростехнадзором",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20104120",
        text: "В каком положении должен находиться баллон (без сифона) при отборе газообразного хлора?",
        answers: [
          "Баллон должен находиться в вертикальном или наклонном положении",
          "Баллон должен находиться только в наклонном положении",
          "Баллон должен находиться только в вертикальном положении",
          "Баллон должен находиться только в горизонтальном положении",
        ],
        correctAnswers: [
          "Баллон должен находиться в вертикальном или наклонном положении",
        ],
      },
      {
        code: "20104121",
        text: "При какой концентрации хлора в воздухе допускается применение средств индивидуальной защиты органов дыхания фильтрующих?",
        answers: [
          "Не более 0,5 % по объему",
          "Не более 0,7 % по объему",
          "Не более 1,5 % по объему",
          "Не более 1,0 % по объему",
        ],
        correctAnswers: ["Не более 0,5 % по объему"],
      },
      {
        code: "20104122",
        text: "Какие электролизеры не допускается использовать для производства химического гипохлорита натрия, применяемого для целей водоподготовки?",
        answers: [
          "Допускается использовать все перечисленные электролизеры",
          "Диафрагменные электролизеры",
          "Ртутные электролизеры",
          "Мембранные электролизеры",
        ],
        correctAnswers: ["Ртутные электролизеры"],
      },
      {
        code: "20104123",
        text: "При каких условиях допускается включение электролизера проточного действия, подающего воздух на разбавление и отдувку водорода, при электрохимическом способе получения гипохлорита натрия?",
        answers: [
          "После промывки электролизера от катодных карбонатных отложений",
          "После кислотной промывки электролизера",
          "Только после включения вентилятора",
          "До заполнения электролитом",
        ],
        correctAnswers: ["Только после включения вентилятора"],
      },
      {
        code: "20104124",
        text: "Каким образом должны быть заполнены емкости для хранения гипохлорита натрия?",
        answers: [
          "Не более чем на 92 % объема",
          "Не более чем на 90 % объема",
          "Не более чем на 95 % объема",
          "Не более чем на 97 % объема",
        ],
        correctAnswers: ["Не более чем на 90 % объема"],
      },
      {
        code: "20104125",
        text: "Какая устанавливается объемная доля водорода в газовой фазе в газовом сепараторе, емкостях хранения готового гипохлорита натрия или гипохлорита калия (накопителях) и трубопроводах, транспортирующих гипохлорит натрия (калия) от емкостей хранения готового продукта до мест потребления продукта?",
        answers: [
          "Не более 1 %",
          "Не более 1,5 %",
          "Не более 2 %",
          "Не более 2,5 %",
        ],
        correctAnswers: ["Не более 1 %"],
      },
      {
        code: "20104126",
        text: "Каким образом допускается налив гипохлорита натрия в транспортные емкости?",
        answers: [
          "Только методом передавливания сжатым воздухом (азотом)",
          "Только самотеком из напорных емкостей",
          "Только при помощи насосов, предназначенных для перекачки гипохлорита натрия",
          "Всеми перечисленными способами",
        ],
        correctAnswers: ["Всеми перечисленными способами"],
      },
      {
        code: "20104127",
        text: "В каком из перечисленных случаев следует предусматривать установку обратных клапанов на насосах, используемых для перекачки гипохлорита натрия?",
        answers: [
          "Только в случае установки на нагнетательных линиях дозирующих насосов",
          "Только в случае установки на всасывающих линиях при подключении нескольких насосов к общему всасывающему коллектору",
          "Только на нагнетательных линиях перекачивающих насосов",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20104128",
        text: "Какую температуру и расчетное давление следует принимать при определении категории и группы рабочей среды трубопроводов гипохлорита натрия?",
        answers: [
          "Не выше 50 °С и не более 1,6 МПа",
          "Не выше 60 °С и не более 1,8 МПа",
          "Не выше 55 °С и не более 1,7 МПа",
          "Не выше 65 °С и не более 1,9 МПа",
        ],
        correctAnswers: ["Не выше 50 °С и не более 1,6 МПа"],
      },
      {
        code: "20104129",
        text: "Как следует обезопасить место розлива гипохлорита натрия по окончании ликвидации аварии?",
        answers: [
          "Промыть большим количеством воды",
          "Засыпать песком",
          "Обработать сорбентом",
          "Выполнить все перечисленные действия",
        ],
        correctAnswers: ["Промыть большим количеством воды"],
      },
    ],

    20105: [
      {
        code: "20105000",
        text: "Что необходимо предпринять организации, эксплуатирующей химически опасный производственный объект, в целях приведения его в соответствие требованиям Правил безопасности химически опасных производственных объектов?",
        answers: [
          "Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь",
          "Провести экспертизу промышленной безопасности",
          "Провести реконструкцию химически опасного производственного объекта (ХОПО)",
          "Сообщить в Ростехнадзор о выявленных несоответствиях Правилам безопасности химически опасных производственных объектов",
        ],
        correctAnswers: [
          "Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь",
        ],
      },
      {
        code: "20105001",
        text: "В каком документе указываются регламентированные значения параметров по ведению технологического процесса?",
        answers: [
          "В технической инструкции",
          "В технологическом регламенте на производство продукции",
          "В проекте",
          "В руководстве по эксплуатации",
        ],
        correctAnswers: [
          "В технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20105002",
        text: "Какие существуют типы технологических регламентов в зависимости от степени освоенности производств и целей осуществляемых работ?",
        answers: [
          "Постоянные, временные, разовые и лабораторные",
          "Периодически пересматриваемые",
          "Входящие в состав проектной документации (пусковые)",
        ],
        correctAnswers: ["Постоянные, временные, разовые и лабораторные"],
      },
      {
        code: "20105003",
        text: 'Как производится описание технологической схемы в разделе "Описание химико-технологического процесса и схемы"?',
        answers: [
          "По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта",
          "По стадиям технологического процесса, исключая загрузку сырья в технологическое оборудование",
          "По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование и кончая отгрузкой готового продукта",
        ],
        correctAnswers: [
          "По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта",
        ],
      },
      {
        code: "20105004",
        text: 'Что необходимо указывать в описании процессов разделения химических продуктов (горючих или их смесей с негорючими) в разделе "Описание химико-технологического процесса и схемы"?',
        answers: [
          "Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса",
          "Степень разделения сред, меры взрывобезопасности, показатели пожароопасности и токсичности",
          "Показатели взрывопожароопасности, а также токсичные свойства всех веществ, участвующих в процессе на всех его стадиях",
        ],
        correctAnswers: [
          "Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса",
        ],
      },
      {
        code: "20105005",
        text: "На основе каких данных составляется материальный баланс для действующих производств?",
        answers: [
          "Материальный баланс для действующих производств составляется по достигнутым показателям работы производств в последний год перед составлением технологического регламента",
          "Материальный баланс для действующих производств составляется по данным технологического регламента",
          "Материальный баланс для действующих производств составляется по данным проекта с учетом внесенных в проект изменений, включения или исключения дополнительных операций или стадий ",
        ],
        correctAnswers: [
          "Материальный баланс для действующих производств составляется по достигнутым показателям работы производств в последний год перед составлением технологического регламента",
        ],
      },
      {
        code: "20105006",
        text: "Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?",
        answers: [
          "Рациональный подбор взаимодействующих компонентов, исходя из условия максимального снижения или исключения образования химически опасных смесей или продуктов",
          "Применение компонентов в фазовом состоянии, затрудняющем или исключающем образование химически опасной смеси",
          "Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки",
          "Нарушение энергообеспечения технологического процесса",
        ],
        correctAnswers: [
          "Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки",
        ],
      },
      {
        code: "20105007",
        text: "В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечить способность функционирования средств противоаварийной защиты?",
        answers: [
          "Время устанавливается в документации",
          "В течение 8 часов",
          "В течение времени, достаточного для исключения опасной ситуации",
          "В течение 24 часов",
        ],
        correctAnswers: [
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
      },
      {
        code: "20105008",
        text: "Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах I и II классов опасности?",
        answers: [
          "Не более 12 секунд",
          "Не более 120 секунд",
          "Не более 300 секунд",
          "Не более 350 секунд",
        ],
        correctAnswers: ["Не более 12 секунд"],
      },
      {
        code: "20105009",
        text: "Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах III класса опасности?",
        answers: [
          "Не менее 12 секунд",
          "Не более 120 секунд",
          "Не менее 240 секунд",
          "Не менее 300 секунд",
        ],
        correctAnswers: ["Не более 120 секунд"],
      },
      {
        code: "20105010",
        text: "Куда следует направлять сбрасываемые химически опасные вещества?",
        answers: [
          "На факельную установку",
          "В специализированные контейнеры",
          "В закрытые системы для дальнейшей утилизации",
          "В централизованную систему водоотведения",
        ],
        correctAnswers: ["В закрытые системы для дальнейшей утилизации"],
      },
      {
        code: "20105011",
        text: "В каком документе организация, эксплуатирующая химически опасные производственные объекты I, II и III классов опасности, должна предусматривать действия работников по предупреждению аварий, локализации и ликвидации их последствий?",
        answers: [
          "В коллективном договоре",
          "В плане мероприятий по локализации и ликвидации последствий аварий",
          "В Положении о производственном контроле",
          "В технологическом регламенте",
        ],
        correctAnswers: [
          "В плане мероприятий по локализации и ликвидации последствий аварий",
        ],
      },
      {
        code: "20105012",
        text: "Какой устанавливается срок действия постоянного технологического регламента?",
        answers: [
          "Не более 20 лет",
          "Не более 5 лет",
          "Не более 3 лет",
          "Не более 10 лет",
        ],
        correctAnswers: ["Не более 10 лет"],
      },
      {
        code: "20105013",
        text: "Кто утверждает все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?",
        answers: [
          "Специальная комиссия организации, эксплуатирующей химико-технологическое производство",
          "Начальник отдела технического контроля организации, эксплуатирующей химико-технологическое производство",
          "Технический руководитель (главный инженер) организации, эксплуатирующей химико-технологическое производство",
          "Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство",
        ],
        correctAnswers: [
          "Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство",
        ],
      },
      {
        code: "20105014",
        text: "Какой устанавливается срок действия разовых (опытных) технологических регламентов, в соответствии с которыми проводится наработка опытной продукции в течение нескольких лет?",
        answers: [
          "Не более 3 лет",
          "Не более 5 лет",
          "Не более 10 лет",
          "Не более 1 года",
        ],
        correctAnswers: ["Не более 5 лет"],
      },
      {
        code: "20105015",
        text: 'Какие разновидности материального баланса допускается составлять в разделе технологического регламента "Материальный баланс"?',
        answers: [
          "Все перечисленные",
          "Только баланс на единицу времени (час)",
          "Только баланс на единицу выпускаемой продукции",
          "Только баланс на один производственный поток или на мощность производства в целом",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20105016",
        text: "Какими источниками информации следует руководствоваться при разработке технологических процессов для определения регламентированных значений параметров, определяющих взрывоопасность процесса, допустимые диапазоны их измерений, критические значения параметров?",
        answers: [
          "Только справочной литературой",
          "Заданием на проектирование, на основании данных о критических значениях параметров или их совокупности для участвующих в процессе веществ",
          "Только данными, запрашиваемыми у научно-исследовательской организации",
          "Только научно-технической литературой",
        ],
        correctAnswers: [
          "Заданием на проектирование, на основании данных о критических значениях параметров или их совокупности для участвующих в процессе веществ",
        ],
      },
      {
        code: "20105017",
        text: 'Какое из перечисленных требований соответствует нормам заполнения и хранения "Листа регистрации изменений и дополнений"?',
        answers: [
          "Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента",
          "Регистрация изменений и дополнений выполняется главным инженером организации (техническим руководителем)",
          "Лист регистрации изменений и дополнений размещается в начале технологического регламента",
          "Запись в регистрационном листе выполняется чернилами красного цвета",
        ],
        correctAnswers: [
          "Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента",
        ],
      },
      {
        code: "20105018",
        text: "Кем разрабатываются все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?",
        answers: [
          "Специализированной сторонней организацией",
          "Разработчиком процесса",
          "Организацией, эксплуатирующей химико-технологическое производство",
          "Комиссией под председательством инспектора территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "Организацией, эксплуатирующей химико-технологическое производство",
        ],
      },
      {
        code: "20105019",
        text: 'Информацию о каких организациях должен содержать раздел технологического регламента "Общая характеристика производства"?',
        answers: [
          "Только об организации - разработчике технологической части проектной документации",
          "Только об организации - разработчике технологического процесса",
          "О всех перечисленных организациях",
          "Только об организации, выполнивший функции генерального проектировщика",
          "Только об организации, выполнивший проектную документацию",
        ],
        correctAnswers: ["О всех перечисленных организациях"],
      },
      {
        code: "20105020",
        text: "Кем разрабатываются исходные данные на разработку документации на химически опасных производственных объектах?",
        answers: [
          "Ростехнадзором",
          "Организациями, проводящими экспертизы промышленной безопасности",
          "Эксплуатирующими организациями",
          "Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области",
        ],
        correctAnswers: [
          "Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области",
        ],
      },
      {
        code: "20105021",
        text: "В каком случае допускается объединение выбросов химически опасных веществ, содержащих вещества, способные при смешивании образовывать более опасные по воздействиям химические соединения?",
        answers: [
          "Не допускается ни в каком случае",
          "Если выбросы рассчитываются в количествах определяемых условиями безопасной остановки технологического процесса",
          "В случае использования специальных систем аварийного освобождения",
          "В случае разработки необходимых мер, предусмотренных документацией на ХОПО",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20105022",
        text: "В каком случае допускается наработка товарной продукции по лабораторным регламентам (пусковым запискам, производственным методикам)?",
        answers: [
          "Не допускается ни в каком случае",
          "Объемом до 1000 кг/год",
          "Объемом до 1500 кг/год",
        ],
        correctAnswers: ["Объемом до 1000 кг/год"],
      },
      {
        code: "20105023",
        text: "На каких объектах ХОПО технические решения по обеспечению надежности контроля параметров, имеющих критические значения, обосновываются разработчиком документации на ХОПО? Укажите все правильные ответы.",
        answers: [
          "На объектах ХОПО I класса опасности",
          "На объектах ХОПО II класса опасности",
          "На объектах ХОПО III класса опасности",
          "На объектах ХОПО IV класса опасности",
        ],
        correctAnswers: [
          "На объектах ХОПО III класса опасности",
          "На объектах ХОПО IV класса опасности",
        ],
      },
      {
        code: "20105024",
        text: "В соответствии с какими документами осуществляют ведение технологических процессов на химически опасных производственных объектах?",
        answers: [
          "В соответствии с руководствами (инструкциями) по эксплуатации технических устройств",
          "В соответствии с проектной документацией",
          "В соответствии с технологическими регламентами на производство продукции",
          "В соответствии с декларацией промышленной безопасности",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами на производство продукции",
        ],
      },
      {
        code: "20105025",
        text: "Для каких из перечисленных целей запрещается использовать специальные системы аварийного освобождения?",
        answers: [
          "Для обеспечения минимально возможного времени освобождения",
          "Для исключения развития аварий",
          "Для исключения образования химически опасных смесей в окружающей атмосфере",
          "Все ответы неверны",
          "Для исключения образования химически опасных смесей в самих системах",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20105026",
        text: "Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?",
        answers: [
          "Средствами автоматического управления объектом по действующим программам",
          "Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений",
          "Обслуживающим персоналом по инструкции",
          "Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию системы противоаварийной защиты",
        ],
        correctAnswers: ["Обслуживающим персоналом по инструкции"],
      },
      {
        code: "20105027",
        text: "Что является критерием взрывоопасности согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?",
        answers: [
          "Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом",
          "Класс опасности обращающихся в технологическом процессе веществ",
          "Температура самовоспламенения паров обращающихся в технологическом процессе веществ",
          "Скорость распространения горения обращающихся в технологическом процессе веществ",
        ],
        correctAnswers: [
          "Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом",
        ],
      },
      {
        code: "20105028",
        text: "Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?",
        answers: [
          "Энергией сгорания парогазовой фазы, кДж",
          "Категорией взрывоопасности технологических блоков",
          "Приведенной массой вещества, участвующего во взрыве, кг",
          "Радиусом зон разрушения, м",
        ],
        correctAnswers: ["Категорией взрывоопасности технологических блоков"],
      },
      {
        code: "20105029",
        text: "Какого показателя категорий взрывоопасности технологических блоков не существует?",
        answers: [
          "I категории",
          "II категории",
          "III категории",
          "IV категории",
        ],
        correctAnswers: ["IV категории"],
      },
      {
        code: "20105030",
        text: "Какой следует принимать категорию взрывоопасности блоков, определяемую расчетом, если обращающиеся в технологическом блоке опасные вещества относятся к токсичным, высокотоксичным веществам?",
        answers: [
          "На одну выше",
          "I категории",
          "II категории",
          "III категории",
        ],
        correctAnswers: ["На одну выше"],
      },
      {
        code: "20105031",
        text: "В соответствии с чем осуществляется ведение технологических процессов на опасных производственных объектах химических, нефтехимических и нефтегазоперерабатывающих производств?",
        answers: [
          "В соответствии с технологическими регламентами на производство продукции",
          "В соответствии с рекомендациями территориального управления Ростехнадзора",
          "В соответствии с заключением экспертизы промышленной безопасности",
          "В соответствии с приказами руководителя эксплуатирующей организации",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами на производство продукции",
        ],
      },
      {
        code: "20105032",
        text: "Каким образом предприятие должно обеспечить наработку навыков действий персонала в нештатных (аварийных) ситуациях на установках с технологическими блоками I и II категорий взрывоопасности?",
        answers: [
          "Посредством допуска производственного персонала к самостоятельной работе не ранее чем через 6 месяцев после стажировки на объекте",
          "Посредством оборудования специализированных центров для обучения и подготовки производственного персонала",
          "Посредством обучения персонала на компьютерных тренажерах, включающих максимально приближенные к реальным динамические модели процессов и реальные средства управления",
          "Посредством установки компьютерных тренажеров, включающих приближенные к реальным динамические модели процессов и средства управления",
        ],
        correctAnswers: [
          "Посредством обучения персонала на компьютерных тренажерах, включающих максимально приближенные к реальным динамические модели процессов и реальные средства управления",
        ],
      },
      {
        code: "20105033",
        text: "В какой документации должны быть приведены способы и средства, исключающие выход параметров за установленные пределы?",
        answers: [
          "В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции",
          "Только в исходных данных на проектирование",
          "Только в проектной документации",
          "Только в технологическом регламенте",
        ],
        correctAnswers: [
          "В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20105034",
        text: "Каким образом осуществляется управление подачей инертных сред на установку с технологическими блоками любой категории взрывоопасности там, где при отклонении от регламентированных значений параметров возможно образование взрывоопасных смесей?",
        answers: [
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление",
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту",
          "Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, для установок с технологическими блоками II категории взрывоопасности - ручное дистанционное, для установок с технологическими блоками III категории взрывоопасности ручное управление",
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Qв ≤ 10 - ручное управление",
        ],
        correctAnswers: [
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту",
        ],
      },
      {
        code: "20105035",
        text: "Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?",
        answers: [
          "Системами ручного (без применения вычислительной техники) регулирования технологического процесса",
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
          "Эффективными быстродействующими системами, обеспечивающими непрерывность и безопасность технологического процесса",
        ],
        correctAnswers: [
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
        ],
      },
      {
        code: "20105036",
        text: "Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?",
        answers: [
          "Время срабатывания установлено для каждого технологического блока в зависимости от категории взрывоопасности",
          "Время срабатывания определяется расчетом",
          "Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и устанавливается для блоков III категории",
          "Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и устанавливается для блоков I и II категорий",
        ],
        correctAnswers: ["Время срабатывания определяется расчетом"],
      },
      {
        code: "20105037",
        text: "Какими блокировками на отключение должны быть оснащены насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?",
        answers: [
          "Блокировками, предотвращающими запуск при отсутствии нормального давления перемещаемой среды в расходной и приемной емкостях насосов",
          "Блокировками, исключающими работу насоса при приближении рабочего давления перемещаемой среды в приемной емкости к его максимальным значениям",
          "Блокировками, исключающими прекращение работы насосов в случаях приближения к опасным значения давления рабочей среды в расходной и приемной емкостях",
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
        correctAnswers: [
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
      },
      {
        code: "20105038",
        text: "Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?",
        answers: [
          "Соотношение давления теплоносителя (хладагента) и давления нагреваемых (охлаждаемых) горючих веществ не регламентируется",
          "На установках с технологическими блоками I и II категорий взрывоопасности давление теплоносителя (хладагента) в исключительных случаях может превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками III категории взрывоопасности соотношение давления теплоносителя (хладагента) и давления нагреваемых (охлаждаемых) горючих веществ не регламентируется",
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
          "Давление теплоносителя (хладагента) должно соответствовать давлению нагреваемых (охлаждаемых) горючих веществ",
        ],
        correctAnswers: [
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
        ],
      },
      {
        code: "20105039",
        text: "Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?",
        answers: [
          "Заказчиком",
          "Разработчиком процесса",
          "Разработчиком проекта",
          "Организатором процесса",
        ],
        correctAnswers: ["Разработчиком процесса"],
      },
      {
        code: "20105040",
        text: "Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся жидкости и горючие жидкости на сливо-наливных эстакадах?",
        answers: [
          "Должно быть организовано управление по месту",
          "Должно быть организовано управление дистанционно (из безопасного места)",
          "Должно быть организовано управление по месту и дистанционно (из безопасного места)",
          "Должно быть организовано автоматическое управление",
        ],
        correctAnswers: [
          "Должно быть организовано управление по месту и дистанционно (из безопасного места)",
        ],
      },
      {
        code: "20105041",
        text: "Какие сведения являются основополагающими для выбора оборудования при разработке технологических процессов?",
        answers: [
          "Расчетные данные, соответствующие параметрам оборудования, и показатели надежности",
          "Расчетные данные, соответствующие параметрам оборудования, и требования действующих нормативных документов",
          "Расчетные данные, соответствующие заданию на проектирование, и требования действующих нормативных документов",
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
        correctAnswers: [
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
      },
      {
        code: "20105042",
        text: "Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?",
        answers: [
          "Оборудование должно быть демонтировано или изолировано от действующей системы при расположении на наружной установке",
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем",
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с взрывоопасными технологическими блоками всех категорий взрывоопасности",
          "Оборудование должно быть демонтировано при любых условиях",
        ],
        correctAnswers: [
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем",
        ],
      },
      {
        code: "20105043",
        text: "В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?",
        answers: [
          "При наличии блокировок по предельно допустимым нижнему и верхнему уровням в расходной и приемной емкостях соответственно",
          "При наличии сигнализации о предельно допустимых нижнем и верхнем уровнях в расходной и приемной емкостях соответственно",
          "При наличии сигнализации и блокировок о предельно допустимых нижнем и верхнем уровнях в расходной и приемной емкостей соответственно",
          "При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации",
        ],
        correctAnswers: [
          "При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации",
        ],
      },
      {
        code: "20105044",
        text: "Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?",
        answers: [
          "Все устройства, задействованные в системе противоаварийной защиты, в том числе исполнительные механизмы",
          "Запорная арматура, средства защиты от превышения давления, огнепреградители",
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
          "Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20105045",
        text: "В соответствии с чем должны определяться оптимальные методы создания системы противоаварийной защиты на стадии формирования требований при проектировании автоматизированной системы управления технологическим процессом?",
        answers: [
          "В соответствии с алгоритмами, разработанными по сценариям развития всех возможных аварий",
          "В соответствии с методиками и программными продуктами, утвержденными (согласованными) Ростехнадзором, применяемыми для моделирования аварийных ситуаций,",
          "В соответствии со сценариями возможных аварийных ситуаций, а также способами перевода объекта в безопасное состояние",
          "В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности",
        ],
        correctAnswers: [
          "В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности",
        ],
      },
      {
        code: "20105046",
        text: "Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте и инструкциях",
          "Средства автоматики должны быть обозначены на мнемосхемах, размещаемых только на диспетчерских пультах и операторских панелях",
          "Средства автоматики должны быть обозначены только в технологическом регламенте",
          "Требования к обозначению средств автоматики должны определяться при разработке планов мероприятий по локализации и ликвидации последствий аварий",
        ],
        correctAnswers: [
          "Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте и инструкциях",
        ],
      },
      {
        code: "20105047",
        text: "Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?",
        answers: [
          "Выполняется средствами автоматического управления объектом по действующим программам",
          "Выполняется обслуживающим персоналом по инструкции",
          "Выполняется средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых переключений согласно соответствующим инструкциям",
          "Выполняется средствами автоматического управления объектом по действующим программам после устранения причин срабатывания системы противоаварийной защиты",
        ],
        correctAnswers: ["Выполняется обслуживающим персоналом по инструкции"],
      },
      {
        code: "20105048",
        text: "Каким образом обеспечивается надежность обеспечения средств управления и системы противоаварийной защиты сжатым воздухом?",
        answers: [
          "Установкой резервного компрессора с включением его автоматически при остановке рабочего компрессора",
          "Переключением сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования на заводскую сеть сжатого воздуха через осушитель",
          "Установкой буферных емкостей (реципиентов), обеспечивающих в течение 1 часа питание воздухом систем контроля, управления и противоаварийной защиты в целях исключения остановки компрессора",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
        correctAnswers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20105049",
        text: "Что должно быть учтено в системах управления и защиты электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?",
        answers: [
          "Наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения",
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
          "Селективность защит на устройствах электроснабжающей и электропотребляющей организаций",
          "Возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой",
        ],
        correctAnswers: [
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
        ],
      },
      {
        code: "20105050",
        text: "Какое требование к системам вентиляции указано неверно?",
        answers: [
          "Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания",
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
          "Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства",
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: "20105051",
        text: "Какая система отопления должна применяться в помещениях, имеющих взрывоопасные зоны?",
        answers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
          "Система водяного отопления",
          "Система парового отопления",
          "Система электрического отопления во взрывоопасном исполнении",
        ],
        correctAnswers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
        ],
      },
      {
        code: "20105052",
        text: "Какая максимальная температура поверхностей нагрева систем отопления должна быть в помещениях, имеющих взрывоопасные зоны?",
        answers: [
          "Максимальная температура не должна превышать 80 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 85 % температуры самовоспламенения вещества, имеющего самую высокую температуру самовоспламенения из обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 90 % температуры самовоспламенения вещества, имеющего среднюю температуру самовоспламенения из обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 95 % температуры самовоспламенения вещества, имеющего наиболее высокую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
        correctAnswers: [
          "Максимальная температура не должна превышать 80 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
      },
      {
        code: "20105053",
        text: "Каков порядок сброса химически загрязненных стоков от отдельных технологических объектов в магистральную сеть канализации?",
        answers: [
          "Порядок сброса стоков в магистральную сеть канализации устанавливается эксплуатирующей организацией",
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны быть оборудованы устройствами для улавливания аварийных стоков",
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
          "Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть",
        ],
        correctAnswers: [
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
        ],
      },
      {
        code: "20105054",
        text: "На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?",
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          "На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы",
          "На ведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на территории находящихся в эксплуатации опасных производственных объектов",
          "На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности",
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "20105055",
        text: "Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года",
          "По наряду-допуску на проведение газоопасных работ с соблюдением дополнительных мер предосторожности",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
      {
        code: "20105056",
        text: "Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?",
        answers: [
          "Руководитель структурного подразделения, или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск, на необходимый для окончания работ срок",
          "Руководитель структурного подразделения, не более чем на 1 рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск, не более чем на 1 дневную смену",
        ],
        correctAnswers: [
          "Руководитель структурного подразделения, или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
        ],
      },
      {
        code: "20105057",
        text: "В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?",
        answers: [
          "Не менее 1 года со дня закрытия наряда-допуска",
          "Не менее 6 месяцев со дня закрытия наряда-допуска",
          "Не менее 3 месяцев со дня закрытия наряда-допуска",
        ],
        correctAnswers: ["Не менее 6 месяцев со дня закрытия наряда-допуска"],
      },
      {
        code: "20105058",
        text: "Где допускается расположение узла ввода теплоносителя?",
        answers: [
          "Во всех указанных местах",
          "Только в помещениях систем приточной вентиляции (в вентиляционной камере)",
          "Только в самостоятельном помещении с отдельным входом с лестничной клетки или из невзрывопожароопасных производственных помещений",
          "Только в производственных помещениях, в которых предусмотрено применение водяного или парового отопления",
        ],
        correctAnswers: ["Во всех указанных местах"],
      },
      {
        code: "20105059",
        text: "Каким образом должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "По наряду-допуску на проведение газоопасных работ с дополнительными мерами безопасности",
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
      {
        code: "20105060",
        text: "Чем должны быть оборудованы аппараты со взрывопожароопасными веществами?",
        answers: [
          "Устройствами для подключения линий водорода",
          "Циклонами",
          "Устройствами для подключения линий воды, пара, инертного газа",
          "Виброситом",
        ],
        correctAnswers: [
          "Устройствами для подключения линий воды, пара, инертного газа",
        ],
      },
      {
        code: "20105061",
        text: "Какая из перечисленных мер должна предусматриваться для блоков технологической системы по максимальному снижению взрывоопасности?",
        answers: [
          "Предотвращение взрывов внутри технологического оборудования",
          "Периодический контроль за состоянием воздушной среды",
          "Использование в зависимости от особенностей технологического процесса эффективных систем пожаротушения",
          "Использование вентиляционных систем для исключения возможности взрывов и пожаров в объеме производственных зданий, сооружений",
        ],
        correctAnswers: [
          "Предотвращение взрывов внутри технологического оборудования",
        ],
      },
      {
        code: "20105062",
        text: "Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?",
        answers: [
          "Должны пройти только медицинский осмотр в соответствии с требованиями законодательства Российской Федерации",
          "Должны пройти только обучение приемам и методам проведения работ",
          "Все перечисленные требования",
          "Должны быть только не моложе 18 лет",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20105063",
        text: 'Кто подписывается в технологическом регламенте под грифом "согласовано"? Укажите все правильные ответы.',
        answers: [
          "Главный механик и главный энергетик организации",
          "Главный метролог организации",
          "Главный технолог организации",
          "Главный инженер организации",
        ],
        correctAnswers: [
          "Главный механик и главный энергетик организации",
          "Главный метролог организации",
        ],
      },
      {
        code: "20105064",
        text: "По какой категории надежности должно осуществляться электроснабжение химически опасных производственных объектов?",
        answers: [
          "По II или III категории надежности",
          "В зависимости от применяемой технологии",
          "По I или II категории надежности",
          "Только по I категории надежности",
        ],
        correctAnswers: ["По I или II категории надежности"],
      },
      {
        code: "20105065",
        text: "В каком из перечисленных случаев должен быть составлен временный технологический регламент на новый срок? Укажите все правильные ответы.",
        answers: [
          "Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей",
          "Если в тексте технологического регламента обнаружены подчистки и поправки",
          "Если срок освоения производства более года",
          "Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса",
          "Если такое решение принято технологической службой организации, производства, отделения, установки",
        ],
        correctAnswers: [
          "Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей",
          "Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса",
        ],
      },
      {
        code: "20105066",
        text: "На кого возлагается ответственность за полноту и качество разработки разделов технологического регламента производства продукции и контроль за обеспечением его исполнения?",
        answers: [
          "На разработчика процесса",
          "На контролирующую организацию",
          "На технологическую службу организации, производства, отделения, установки",
          "На эксплуатирующую организацию",
        ],
        correctAnswers: [
          "На технологическую службу организации, производства, отделения, установки",
        ],
      },
      {
        code: "20105067",
        text: "Каким образом устанавливается и оформляется срок продления действия временного технологического регламента?",
        answers: [
          "Приказом технического директора (главного инженера) организации",
          "Приказом технологической службы организации",
          "Приказом производства, отделения, установки",
          "Приказом руководителя организации",
        ],
        correctAnswers: ["Приказом руководителя организации"],
      },
      {
        code: "20105068",
        text: 'Что должны обеспечивать системы контроля, автоматического и дистанционного управления (системы управления), системы оповещения об аварийных ситуациях в разделе технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          "Надежность и безопасность проведения технологических процессов, мониторинг входных параметров процесса",
          "Мониторинг входных параметров процесса",
          "Точность поддержания технологических параметров, надежность и безопасность проведения технологических процессов",
          "Контроль за параметрами процесса, надежность применяемого оборудования, контроль за действиями персонала",
        ],
        correctAnswers: [
          "Точность поддержания технологических параметров, надежность и безопасность проведения технологических процессов",
        ],
      },
      {
        code: "20105069",
        text: 'Какой устаналивается срок действия "Накопительной ведомости"?',
        answers: ["6 месяцев", "1 месяц", "3 месяца", "1 год"],
        correctAnswers: ["6 месяцев"],
      },
      {
        code: "20105070",
        text: "Каким образом определяется срок действия временного технологического регламента при отсутствии установленных планами норм освоения производства?",
        answers: [
          "Приказом руководителя организации",
          "Лицом, утверждающим технологический регламент",
          "Техническим руководителем организации",
          "В соответствии с проектной документацией",
        ],
        correctAnswers: ["Лицом, утверждающим технологический регламент"],
      },
      {
        code: "20105071",
        text: "Когда могут проводиться газоопасные работы, выполняемые по наряду-допуску?",
        answers: [
          "Только в темное время суток с соблюдением мероприятий по обеспечению безопасного проведения работ, учитывающих условия их выполнения в темное время суток",
          "В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск",
          "Во время грозы с сильными порывами ветра",
          "В дневное или в темное время суток с участием или в присутствии лица, ответственного за выполнение газоопасных работ",
        ],
        correctAnswers: [
          "В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск",
        ],
      },
      {
        code: "20105072",
        text: "Для каких из перечисленных производств разрабатываются постоянные технологические регламенты?",
        answers: [
          "Все ответы неверны",
          "Для действующих химико-технологических производств, в технологию которых внесены изменения",
          "Для новых в данной организации производств",
          "Для производств с новыми технологиями",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20105073",
        text: "Как необходимо составлять описание схемы процесса при наличии нескольких аналогичных технологических ниток?",
        answers: [
          "Допускается делать описание схемы процесса не менее чем по двум технологическим ниткам",
          "Допускается делать описание схемы процесса по одной технологической нитке",
          "Необходимо описывать все технологические нитки",
        ],
        correctAnswers: [
          "Допускается делать описание схемы процесса по одной технологической нитке",
        ],
      },
      {
        code: "20105074",
        text: "Какой из перечисленных разделов не относится к постоянным, временным и разовым технологическим регламентам, связанным с необходимостью обеспечения промышленной безопасности технологических процессов?",
        answers: [
          "Характеристика производимой продукции",
          "Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств",
          "Технологические схемы производства",
          "Возможные инциденты в работе и способы их ликвидации",
        ],
        correctAnswers: [
          "Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств",
        ],
      },
      {
        code: "20105075",
        text: "В каких случаях должны автоматически включаться системы аварийной вентиляции? Укажите все правильные ответы.",
        answers: [
          "При срабатывании сигнализаторов довзрывных концентраций вредных веществ",
          "При поступлении сигнала от датчиков повышения температуры",
          "При поступлении сигнала от датчиков контроля за содержанием взрывопожароопасной пыли в воздухе помещения",
          "При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
        ],
        correctAnswers: [
          "При срабатывании сигнализаторов довзрывных концентраций вредных веществ",
          "При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
        ],
      },
      {
        code: "20105076",
        text: "Какие технологические регламенты разрабатываются при выпуске товарной продукции на опытных и опытно-промышленных установках (цехах), а также для опытных и опытно-промышленных работ, проводимых на действующих производствах?",
        answers: [
          "Разовые (опытные) технологические регламенты",
          "Постоянные технологические регламенты",
          "Лабораторные технологические регламенты",
          "Временные технологические регламенты",
        ],
        correctAnswers: ["Разовые (опытные) технологические регламенты"],
      },
      {
        code: "20105077",
        text: "Какое из перечисленных требований к выполнению управляющих функций систем ПАЗ указано неверно?",
        answers: [
          "Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы",
          "Системы ПАЗ должны реализовываться на принципах приоритетности защиты технологических процессов комплектно, с одновременной защитой каждой отдельной единицы оборудования",
          "В алгоритмах срабатывания защит должна предусматриваться возможность блокировки команд управления оборудованием, технологически связанным с аппаратом, агрегатом или иным оборудованием, вызвавшим такое срабатывание",
          "Команды управления, сформированные алгоритмами защит (блокировок), должны иметь приоритет по отношению к любым другим командам управления технологическим оборудованием, в том числе к командам управления, формируемым оперативным персоналом АСУТП, если иное не оговорено в техническом задании на ее создание",
        ],
        correctAnswers: [
          "Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы",
        ],
      },
      {
        code: "20105078",
        text: "На чем основаны оптимальные методы и средства противоаварийной автоматической защиты?",
        answers: [
          "На методиках и программных продуктах, применяемых для моделирования аварийных ситуаций, утвержденных (согласованных) Ростехнадзором",
          "На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления",
          "На сценариях возможных аварийных ситуаций и способах перевода объекта в безопасное состояние",
          "На алгоритмах, разработанных по сценариям возможных аварий и их развития",
        ],
        correctAnswers: [
          "На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления",
        ],
      },
      {
        code: "20105079",
        text: 'Какое из перечисленных требований не соответствует разделу технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          "Необходимо взаимоувязывать периодичность испытаний запорной регулирующей арматуры, исполнительных механизмов, периодичность проверок приборов и испытаний других технических средств, участвующих в схемах контроля, управления и противоаварийной защиты технологических процессов",
          "Допускается разделять в отдельные таблицы перечень систем сигнализации и блокировок и (или) перечень аналитического контроля",
          "Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается",
          "Необходимо определять особо средства автоматики, используемые по плану мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах",
        ],
        correctAnswers: [
          "Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается",
        ],
      },
      {
        code: "20105080",
        text: "В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечить способность функционирования средств противоаварийной защиты?",
        answers: [
          "В течение времени, достаточного для исключения опасной ситуации",
          "В течение 24 часов",
          "В течение 8 часов",
          "Время устанавливается при проектировании средств противоаврийной защиты",
        ],
        correctAnswers: [
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
      },
      {
        code: "20105081",
        text: "Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?",
        answers: [
          "Только специалист, обученный пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность",
          "Только руководитель структурного подразделения, где будет проводиться газоопасная работа, или его уполномоченный заместитель",
          "Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы, и приемы ведения газоопасных работ",
          "Любой специалист организации-заказчика или эксплуатирующей организации",
        ],
        correctAnswers: [
          "Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы, и приемы ведения газоопасных работ",
        ],
      },
      {
        code: "20105082",
        text: "Какое количество копий технологических регламентов устанавливается требованиями Правил безопасности химически опасных производственных объектов?",
        answers: [
          "Все ответы неверны",
          "Не менее 6",
          "Не менее 15",
          "Не менее 10",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20105083",
        text: "В каких местах не допускается размещать фланцевые соединения трубопроводов с взрывопожароопасными, токсичными и едкими веществами?",
        answers: [
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
          "Над автодорогами",
          "На трубопроводах, идущих по стенам зданий",
          "На трубопроводах, проложенных по эстакадам",
        ],
        correctAnswers: [
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
        ],
      },
      {
        code: "20105084",
        text: "В каких случаях на трубопроводах следует применять арматуру под приварку?",
        answers: [
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков любой категории взрывоопасности",
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении",
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I и II категорий взрывоопасности и температурой, равной температуре кипения при регламентированном давлении",
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков II категории взрывоопасности с давлением среды более 3,5 МПа",
        ],
        correctAnswers: [
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении",
        ],
      },
      {
        code: "20105085",
        text: "Что должно устанавливаться на трубопроводах для транспортирования взрывопожароопасных продуктов?",
        answers: [
          "Арматура в соответствии с проектной документацией",
          "Арматура с резиновым уплотнением в затворе",
          "Арматура с тканевым уплотнением в затворе",
          "Арматура с пластмассовым уплотнением в затворе",
        ],
        correctAnswers: ["Арматура в соответствии с проектной документацией"],
      },
      {
        code: "20105086",
        text: "Где допускается размещение фланцевых соединений на трубопроводах с пожаровзрывоопасными, токсичными и едкими веществами?",
        answers: [
          "В местах ввода трубопровода в технологические здания и сооружения",
          "Над местами, предназначенными для проезда транспорта на высоте не менее 5 м",
          "Над дверными и оконными проемами в случае применения спирально навитых прокладок",
          "В местах установки арматуры или подсоединения трубопроводов к аппаратам",
        ],
        correctAnswers: [
          "В местах установки арматуры или подсоединения трубопроводов к аппаратам",
        ],
      },
      {
        code: "20105087",
        text: "Кто устанавливает назначенный срок службы для технологических трубопроводов?",
        answers: [
          "Ростехнадзор",
          "Разработчик документации",
          "Организация-изготовитель",
          "Орган по сертификации на основании заключения испытательной лаборатории",
        ],
        correctAnswers: ["Разработчик документации"],
      },
      {
        code: "20105088",
        text: "Какие условия должны выполняться для допуска к эксплуатации компрессорных установок?",
        answers: [
          "Наличие положительного заключения экспертизы промышленной безопасности",
          "Все перечисленные условия",
          "Наличие разрешения на применение технических устройств, выдаваемого органами Ростехнадзора",
          "Наличие и исправное состояние средств автоматизации, контроля и системы блокировок",
        ],
        correctAnswers: [
          "Наличие и исправное состояние средств автоматизации, контроля и системы блокировок",
        ],
      },
      {
        code: "20105089",
        text: "В соответствии с чем следует производить монтаж технологического оборудования и трубопроводов?",
        answers: [
          "Только в соответствии с проектной документацией",
          "Только в соответствии с рабочей документацией",
          "В соответствии со всем перечисленным",
        ],
        correctAnswers: ["В соответствии со всем перечисленным"],
      },
      {
        code: "20105090",
        text: "Какие материалы применяются для изготовления, монтажа и ремонта технологического оборудования и трубопроводов для производств, использующих неорганические кислоты и щелочи?",
        answers: [
          "Теплоустойчивые стали",
          "Материалы, обеспечивающие коррозионную стойкость к рабочей среде",
          "Жаропрочные стали",
          "Жаростойкие (окалиностойкие) стали",
        ],
        correctAnswers: [
          "Материалы, обеспечивающие коррозионную стойкость к рабочей среде",
        ],
      },
      {
        code: "20105091",
        text: "Каким способом не допускается хранить жидкий аммиак на наземных складах?",
        answers: [
          "В резервуарах под избыточным давлением до 2 МПа включительно без отвода аммиака",
          "В изотермических резервуарах под давлением, близким к атмосферному, с отводом испаряющегося аммиака, компримированием, конденсацией и возвратом в резервуар или потребителю",
          "В резервуарах под избыточным давлением до 1 МПа включительно с отводом аммиака, испаряющегося от теплопритока",
          "В резервуарах под избыточным давлением более 2,5 МПа без отвода аммиака",
        ],
        correctAnswers: [
          "В резервуарах под избыточным давлением более 2,5 МПа без отвода аммиака",
        ],
      },
      {
        code: "20105092",
        text: "Каким должен быть коэффициент заполнения резервуаров при хранении аммиака под избыточным давлением?",
        answers: [
          "Не более 0,85 от геометрического объема резервуара",
          "Не более 0,91 от геометрического объема резервуара",
          "Не более 0,95 от геометрического объема резервуара",
          "Не более 0,87 от геометрического объема резервуара",
        ],
        correctAnswers: ["Не более 0,85 от геометрического объема резервуара"],
      },
      {
        code: "20105093",
        text: "Учитывается ли вместимость резервного резервуара при определении вместимости склада, в процессе хранения аммиака под давлением?",
        answers: [
          "Учитывается",
          "Не учитывается",
          "Учитывается только при хранении аммиака в резервуарах под давлением, близким к атмосферному",
          "Учитывается только при хранении аммиака в резервуарах под давлением до 2,0 МПа включительно",
        ],
        correctAnswers: ["Не учитывается"],
      },
      {
        code: "20105094",
        text: "Каким забором следует ограждать территорию склада жидкого аммиака, расположенного на предприятии?",
        answers: [
          "Забором из несгораемых материалов высотой не менее 2 м",
          "Деревянным забором высотой не менее 1,5 м",
          "Сетчатым забором",
          "Бетонным забором высотой более 2,5 м",
        ],
        correctAnswers: ["Сетчатым забором"],
      },
      {
        code: "20105095",
        text: "Какое устройство должно быть установлено на территории склада жидкого аммиака?",
        answers: [
          "Указатель направления ветра",
          "Термометр",
          "Барометр",
          "Гигрометр",
        ],
        correctAnswers: ["Указатель направления ветра"],
      },
      {
        code: "20105096",
        text: "Какая ширина верха земляного вала устанавливается для отдельно стоящего резервуара для хранения жидкого аммиака?",
        answers: [
          "Не менее 0,5 м",
          "Не менее 0,6 м",
          "Не менее 0,8 м",
          "Не менее 1 м",
        ],
        correctAnswers: ["Не менее 1 м"],
      },
      {
        code: "20105097",
        text: "Какое расстояние устанавливается внутри ограждения между лестницами, применяемыми для переходов через ограждения резервуаров для хранения жидкого аммиака?",
        answers: [
          "Не более 80 м",
          "Не более 85 м",
          "Не более 90 м",
          "Не более 95 м",
        ],
        correctAnswers: ["Не более 80 м"],
      },
      {
        code: "20105098",
        text: "Какая ширина должна быть у автомобильных дорог для подъезда к складу жидкого аммиака и проезду по его территории к зданиям и сооружениям?",
        answers: [
          "Не менее 3,5 м",
          "Не менее 3 м",
          "Не менее 2 м",
          "Не менее 2,5 м",
        ],
        correctAnswers: ["Не менее 3,5 м"],
      },
      {
        code: "20105099",
        text: "На каком расстоянии со стороны зданий и открытых установок, примыкающих к ограждению резервуаров для хранения жидкого аммиака, допускается располагать объезд?",
        answers: [
          "Не более 45 м от ограждения резервуаров",
          "Не более 40 м от ограждения резервуаров",
          "Не более 39 м от ограждения резервуаров",
          "Не более 50 м от ограждения резервуаров",
        ],
        correctAnswers: ["Не более 39 м от ограждения резервуаров"],
      },
      {
        code: "20105100",
        text: "В каком объеме сварные швы резервуаров для хранения жидкого аммиака подлежат контролю?",
        answers: ["100 %", "80 %", "30 %", "50 %"],
        correctAnswers: ["100 %"],
      },
      {
        code: "20105101",
        text: "При какой вместимости резервуаров, работающих под избыточным внутренним давлением, допускается применение подогревательных устройств, размещаемых внутри или на наружной поверхности резервуаров?",
        answers: [
          "Не более 100 т",
          "Не более 80 т",
          "Не более 60 т",
          "Не более 50 т",
        ],
        correctAnswers: ["Не более 50 т"],
      },
      {
        code: "20105102",
        text: "Как осуществляется охлаждение изотермического резервуара с аммиаком?",
        answers: [
          "Установкой разбрызгивающего устройства, расположенного выше допустимого уровня аммиака",
          "Подачей охлаждающей жидкости в змеевик, который расположен в нижней части резервуара",
          "Подачей охлаждающей жидкости в рубашку резервуара",
          "Возвратом в резервуар отводимых из резервуара паров аммиака под слой сжиженных паров аммиака на холодильной установке",
        ],
        correctAnswers: [
          "Установкой разбрызгивающего устройства, расположенного выше допустимого уровня аммиака",
        ],
      },
      {
        code: "20105103",
        text: "Что происходит с выбросами аммиака при продувках оборудования и трубопроводов, снижении в них давления, сливе (наливе) цистерн, а также со сбросами от предохранительных клапанов?",
        answers: [
          "Выбросы и сбросы поступают обратно в резервуар",
          "Выбросы и сбросы поступают в резервный резервуар",
          "Выбросы и сбросы утилизируются или направляются в факельную систему",
          "Выбросы и сбросы нейтрализуются химическими реагентами",
        ],
        correctAnswers: [
          "Выбросы и сбросы утилизируются или направляются в факельную систему",
        ],
      },
      {
        code: "20105104",
        text: "Что рекомендуется применять для изоляции внутренних стен и крыши изотермических резервуаров для хранения жидкого аммиака?",
        answers: [
          "Вспученный перлитовый песок",
          "Пенополиуретан",
          "Минеральную вату",
          "Стеклянное волокно",
          "Все перечисленное",
        ],
        correctAnswers: ["Вспученный перлитовый песок"],
      },
      {
        code: "20105105",
        text: "Каким образом обеспечивается противоаварийная защита от превышения уровня аммиака сверх допустимого в резервуарах вместимостью до 50 м3?",
        answers: [
          "Дублированием систем контроля параметров",
          "Дублированием систем контроля и наличием систем самодиагностики с индикацией исправного состояния",
          "Наличием систем самодиагностики",
          "Сопоставлением технологически связанных параметров",
        ],
        correctAnswers: [
          "Дублированием систем контроля и наличием систем самодиагностики с индикацией исправного состояния",
        ],
      },
      {
        code: "20105106",
        text: "Допускается ли применение мерных стекол на резервуарах для хранения жидкого аммиака?",
        answers: [
          "Допускается",
          "Не допускается",
          "Допускается только при специальном обосновании в проектной документации",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "20105107",
        text: "С какой периодичностью проводится термографирование наружной поверхности резервуара жидкого аммиака в целях выявления участков с нарушенной теплоизоляцией?",
        answers: [
          "Один раз в месяц",
          "Один раз в год",
          "Один раз в полгода",
          "Один раз в квартал",
        ],
        correctAnswers: ["Один раз в год"],
      },
      {
        code: "20105108",
        text: "В каком случае необходимо контролировать величину и равномерность осадки фундаментов шаровых резервуаров в эксплуатации?",
        answers: [
          "Во всех перечисленных случаях",
          "Перед подачей в него жидкого аммиака",
          "До гидравлического испытания резервуара",
          "После гидравлического испытания резервуара",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20105109",
        text: "Какие требования предъявляются к арматуре, работающей в среде жидкого аммиака?",
        answers: [
          "Применяются стальная арматура и фасонные части",
          "Применяются стальная арматура и фасонные части, в отдельных случаях допускается применение чугунной запорно-регулирующей арматуры",
          "Применяются стальная арматура и фасонные части, в особых случаях допускается применение арматуры и фитингов с деталями из меди, цинка и их сплавов при условии обоснования в проектной документации",
          "Применяются стальная арматура и фасонные части, в отдельных случаях допускается применение запорной арматуры из ковкого и высокопрочного чугуна",
        ],
        correctAnswers: ["Применяются стальная арматура и фасонные части"],
      },
      {
        code: "20105110",
        text: "Какую минимальную температуру следует принимать для сливных, наливных и эвакуационных насосов жидкого аммиака?",
        answers: ["-28 °C", "-20 °C", "-34 °C", "-35 °C"],
        correctAnswers: ["-34 °C"],
      },
      {
        code: "20105111",
        text: "Какое из перечисленных требований не допускается на территории склада жидкого аммиака?",
        answers: [
          "Сопряжение двух наружных ограждений резервуаров, предназначенных для хранения жидкого аммиака",
          "Прокладывание транзитных трубопроводов, не относящихся к резервуарам для хранения жидкого аммиака, и кабелей через огражденные территории резервуаров для хранения жидкого аммиака",
          "Планирование территории в ограждении резервуаров для хранения жидкого аммиака с уклоном в сторону приямка",
          "Размещение сливоналивных устройств между расположенными рядом путями",
        ],
        correctAnswers: [
          "Прокладывание транзитных трубопроводов, не относящихся к резервуарам для хранения жидкого аммиака, и кабелей через огражденные территории резервуаров для хранения жидкого аммиака",
        ],
      },
      {
        code: "20105112",
        text: "Какая максимальная объемная доля аммиака в межстенном пространстве резервуара во время эксплуатации?",
        answers: ["1 %", "0,5 %", "2 %", "1,5 %"],
        correctAnswers: ["0,5 %"],
      },
      {
        code: "20105113",
        text: "Какие должны быть здания на территории склада жидкого аммиака по степени огнестойкости?",
        answers: [
          "III степени огнестойкости",
          "Не ниже II степени огнестойкости",
          "Не ниже IV степени огнестойкости",
          "Не ниже V степени огнестойкости",
        ],
        correctAnswers: ["Не ниже II степени огнестойкости"],
      },
      {
        code: "20105114",
        text: "Каким образом определяется выбор метода измерения (объемный, весовой) жидкого аммиака?",
        answers: [
          "Все ответы неверны",
          "Внутренней эксплуатационной документацией",
          "Правилами безопасности работ в хранилищах жидкого аммиака",
          "Приказами территориального органа Ростехнадзора",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20105115",
        text: "Чем должен быть продут изотермический резервуар перед наливом в него жидкого аммиака?",
        answers: [
          "Кислородом",
          "Азотом",
          "Водяным паром",
          "Газообразным аммиаком",
        ],
        correctAnswers: ["Газообразным аммиаком"],
      },
      {
        code: "20105116",
        text: "Какая минимальная вместимость газгольдера, устанавливаемого на линии подачи азота, определяется для поддержания в межстенном пространстве резервуара с засыпной изоляцией постоянной величины избыточного давления при изменениях барометрического давления и температуры воздуха?",
        answers: [
          "14 - 16 % объема межстенного пространства резервуара, с которым соединен газгольдер",
          "Все ответы неверны",
          "4 - 6 % объема межстенного пространства резервуара, с которым соединен газгольдер",
          "12 - 14 % объема межстенного пространства резервуара, с которым соединен газгольдер",
          "2 - 5 % объема межстенного пространства резервуара, с которым соединен газгольдер",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20105117",
        text: "Кто проводит регистрацию изотермического резервуара в установленном порядке?",
        answers: [
          "Проектная организация",
          "Монтажная организация",
          "Организация, эксплуатирующая изотермический резервуар",
          "Специализированная организация",
        ],
        correctAnswers: [
          "Организация, эксплуатирующая изотермический резервуар",
        ],
      },
      {
        code: "20105118",
        text: "Каким образом рекомендуется располагать штуцера на резервуарах для хранения жидкого аммиака?",
        answers: [
          "Штуцера для дренажа рекомендуется размещать в нижней части резервуаров, остальные штуцера - в их верхней части",
          "Штуцера для контрольно-измерительных приборов и автоматизации рекомендуется размещать в нижней части резервуаров, остальные штуцера - в их верхней части",
          "Штуцера для выдачи жидкого аммиака, дренажа, промывки и контрольно-измерительных приборов и автоматизации разрешается размещать в нижней части резервуаров, остальные штуцера - в верхней части резервуаров",
          "Штуцера для выдачи жидкого аммиака рекомендуется размещать в нижней части резервуаров, остальные штуцера - в их верхней части",
        ],
        correctAnswers: [
          "Штуцера для выдачи жидкого аммиака, дренажа, промывки и контрольно-измерительных приборов и автоматизации разрешается размещать в нижней части резервуаров, остальные штуцера - в верхней части резервуаров",
        ],
      },
      {
        code: "20105119",
        text: "Какие изотермические резервуары дополнительно рассчитываются на сейсмические нагрузки?",
        answers: [
          "Двустенные с подвесной крышей внутреннего резервуара",
          "Одностенные",
          "Все ответы неверны",
          "Все изотермические резервуары",
          "Двустенные с двумя самонесущими крышами",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20105120",
        text: "Какие требования к предохранительным клапанам, устанавливаемым на резервуары жидкого аммиака, указаны неверно?",
        answers: [
          "Не допускается изготовление предохранительных и вакуумных клапанов для изотермических резервуаров из алюминиевых сплавов",
          "Применение рычажно-грузовых предохранительных клапанов не допускается",
          "На наружных оболочках изотермических резервуаров с засыпной изоляцией разрешается не устанавливать предохранительные клапаны, если такие клапаны имеются на буферном сосуде (газгольдере) азота или на трубопроводе, который соединяет наружную оболочку с буферным сосудом",
          "Параллельно с рабочими предохранительными клапанами необходимо установить резервные предохранительные клапаны",
        ],
        correctAnswers: [
          "Не допускается изготовление предохранительных и вакуумных клапанов для изотермических резервуаров из алюминиевых сплавов",
        ],
      },
      {
        code: "20105121",
        text: "С какой периодичностью проводится ревизия и ремонт предохранительных клапанов резервуаров жидкого аммиака?",
        answers: [
          "В сроки, установленные технологическим регламентом и эксплуатационной документацией",
          "Ежегодно",
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в 3 года",
        ],
        correctAnswers: [
          "В сроки, установленные технологическим регламентом и эксплуатационной документацией",
        ],
      },
      {
        code: "20105122",
        text: "На какие наземные склады жидкого аммиака не распространяются Правила безопасности химически опасных производственных объектов?",
        answers: [
          "На глубинные сельскохозяйственные склады",
          "На склады аммиака в баллонах",
          "На заводские склады, расположенные на территории организаций, производящих аммиак, использующих его в качестве сырья или вспомогательного материала для выпуска товарной продукции, применяющих аммиак в промышленных холодильных установках или для других целей",
          "На раздаточные станции, получающие аммиак из аммиакопровода",
        ],
        correctAnswers: ["На склады аммиака в баллонах"],
      },
      {
        code: "20105123",
        text: "Каким образом определяются способ хранения жидкого аммиака, количество, вместимость?",
        answers: [
          "Проектом из условия обеспечения безопасной эксплуатации",
          "Технологическими регламентами",
          "Правилами безопасности для складов жидкого аммиака",
          "Эксплуатационной документацией",
        ],
        correctAnswers: [
          "Проектом из условия обеспечения безопасной эксплуатации",
        ],
      },
      {
        code: "20105124",
        text: "Какое из перечисленных требований во избежание попадания водяных паров из окружающего воздуха в теплоизоляционный слой изотермических резервуаров жидкого аммиака с засыпной изоляцией указано верно?",
        answers: [
          "Межстенное пространство должно быть постоянно заполнено аргоном",
          "Избыточное давление в межстенном пространстве изотермических резервуаров должно определяться проектом",
          "Межстенное пространство изотермических резервуаров должно быть постоянно заполнено осушенным азотом с точкой росы -20 °C",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20105125",
        text: "В течение какого срока службы устройства резервуаров для хранения аммиака должны обеспечивать надежную и безопасную эксплуатацию?",
        answers: [
          "В течение срока службы, указанного в паспорте организации-изготовителя",
          "В течение 15 лет",
          "В течение 20 лет",
          "В течение 10 лет",
        ],
        correctAnswers: [
          "В течение срока службы, указанного в паспорте организации-изготовителя",
        ],
      },
      {
        code: "20105126",
        text: "В каком случае допускается применение резиновых и резинометаллических рукавов для слива (налива) цистерн жидкого аммиака?",
        answers: [
          "Если рукава для слива рассчитаны на рабочее давление не менее 2 МПа",
          "Если это регламентировано внутренней эксплуатационной документацией",
          "Не допускается ни в каком случае",
          "Если внутренний диаметр рукавов для слива 30 мм",
        ],
        correctAnswers: [
          "Если рукава для слива рассчитаны на рабочее давление не менее 2 МПа",
        ],
      },
      {
        code: "20105127",
        text: "При каких погодных условиях производится монтаж изоляции изотермических резервуаров жидкого аммиака?",
        answers: [
          "Только в теплое время года",
          "При соблюдении всех перечисленных погодных условий",
          "Только при отсутствии атмосферных осадков",
          "Только при температуре не ниже предусмотренной техническими условиями для монтажа изоляции изотермических резервуаров жидкого аммиака",
        ],
        correctAnswers: ["При соблюдении всех перечисленных погодных условий"],
      },
      {
        code: "20105128",
        text: "Какие из перечисленных электроприемников складов жидкого аммиака являются потребителями II категории надежности?",
        answers: [
          "С шаровыми резервуарами",
          "С резервуарами под избыточным давлением и вместимостью до 100 т включительно",
          "С изотермическими резервуарами",
        ],
        correctAnswers: [
          "С резервуарами под избыточным давлением и вместимостью до 100 т включительно",
        ],
      },
      {
        code: "20105129",
        text: "Чем производится продувка резервуаров для хранения жидкого аммиака перед включением в работу?",
        answers: [
          "Водяным паром",
          "Азотом",
          "Кислородом",
          "Любым инертным газом",
        ],
        correctAnswers: ["Азотом"],
      },
      {
        code: "20105130",
        text: "Какое из перечисленных требований к арматурам и трубопроводам жидкого аммиака указано верно?",
        answers: [
          "Защитные устройства должны устанавливаться после запорной арматуры на трубопроводе подачи аммиака",
          "Резервуары для хранения жидкого аммиака следует отключать от трубопроводов одним запорным органом",
          "Арматура, расположенная непосредственно у шаровых, изотермических и горизонтальных резервуаров вместимостью 50 т и более, должна иметь дистанционное и ручное управление",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20105131",
        text: "В каком случае допускается неавтоматическое включение технических устройств, задействованных в системе локализации аварийных ситуаций на складах жидкого аммиака?",
        answers: [
          "Если это обосновано проектом",
          "Ни в каком случае",
          "Если это установлено внутренним регламентом организации",
          "Если нет угрозы распространения химического заражения за территорию объекта",
        ],
        correctAnswers: ["Если это обосновано проектом"],
      },
    ],

    20106: [
      {
        code: "20106000",
        text: "Что необходимо предпринять организации, эксплуатирующей химически опасный производственный объект, в целях приведения его в соответствие требованиям Правил безопасности химически опасных производственных объектов?",
        answers: [
          "Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь",
          "Провести экспертизу с оценкой соответствия объекта требованиям и нормам безопасной эксплуатации",
          "Провести реконструкцию химически опасного производственного объекта",
          "Немедленно сообщить в территориальный надзорный орган о выявленных в рамках проведения производственного контроля несоответствиях требованиям Правил безопасности химически опасных производственных объектов",
        ],
        correctAnswers: [
          "Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь",
        ],
      },
      {
        code: "20106001",
        text: "В каком документе указываются регламентированные значения параметров по ведению технологического процесса?",
        answers: [
          "В техническом проекте",
          "В технологическом регламенте на производство продукции",
          "В руководстве по эксплуатации",
        ],
        correctAnswers: [
          "В технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20106002",
        text: "Какие типы технологических регламентов предусматриваются в зависимости от степени освоенности производств и целей осуществляемых работ?",
        answers: [
          "Постоянные, временные, разовые и лабораторные",
          "Периодические, ежегодные",
          "Плановые, целевые",
        ],
        correctAnswers: ["Постоянные, временные, разовые и лабораторные"],
      },
      {
        code: "20106003",
        text: "Какие технологические регламенты разрабатываются при выпуске товарной продукции на опытных и опытно-промышленных установках (цехах), а также для опытных и опытно-промышленных работ, проводимых на действующих производствах?",
        answers: [
          "Постоянные технологические регламенты",
          "Разовые (опытные) технологические регламенты",
          "Временные (пусковые) технологические регламенты",
          "Лабораторные регламенты (производственные методики)",
        ],
        correctAnswers: ["Разовые (опытные) технологические регламенты"],
      },
      {
        code: "20106004",
        text: "Кем разрабатываются все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?",
        answers: [
          "Организацией, эксплуатирующей химико-технологическое производство",
          "Организацией - разработчиком производственного процесса",
          "Комиссией под председательством руководителя территориального органа Ростехнадзора",
          "Специализированной проектной организацией",
        ],
        correctAnswers: [
          "Организацией, эксплуатирующей химико-технологическое производство",
        ],
      },
      {
        code: "20106005",
        text: 'Кто подписывается в технологическом регламенте под грифом "согласовано"? Укажите все правильные ответы.',
        answers: [
          "Руководитель проектного отдела организации",
          "Начальник производственного участка",
          "Главный механик и главный энергетик организации",
          "Главный метролог организации",
        ],
        correctAnswers: [
          "Главный механик и главный энергетик организации",
          "Главный метролог организации",
        ],
      },
      {
        code: "20106006",
        text: "Кто утверждает все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?",
        answers: [
          "Технический руководитель организации, эксплуатирующей производственный объект",
          "Председатель комиссии территориального органа государственного надзора в области промышленной безопасности",
          "Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство",
          "Начальник отдела технического контроля организации, являющейся разработчиком производственного процесса",
        ],
        correctAnswers: [
          "Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство",
        ],
      },
      {
        code: "20106007",
        text: "На кого возлагается ответственность за полноту и качество разработки разделов технологического регламента производства продукции и контроль за обеспечением его исполнения?",
        answers: [
          "На управляющего организации, эксплуатирующей технологическое производство",
          "На технологическую службу организации, производства, отделения, установки",
          "На организацию, осуществляющую разработку процесса производства",
          "На руководителя организации, эксплуатирующей химико-технологическое производство",
        ],
        correctAnswers: [
          "На технологическую службу организации, производства, отделения, установки",
        ],
      },
      {
        code: "20106008",
        text: "Какое количество копий технологических регламентов устанавливается требованиями Правил безопасности химически опасных производственных объектов?",
        answers: [
          "Не менее 6",
          "Не менее 15",
          "Не менее 10",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20106009",
        text: "Какой устанавливается срок действия постоянного технологического регламента?",
        answers: [
          "Не более 3 лет",
          "Не более 10 лет",
          "Не более 5 лет",
          "Не более 15 лет",
        ],
        correctAnswers: ["Не более 10 лет"],
      },
      {
        code: "20106010",
        text: "Какой устанавливается срок действия разовых (опытных) технологических регламентов, в соответствии с которыми проводится наработка опытной продукции в течение нескольких лет?",
        answers: [
          "Не более 5 лет",
          "Не более года",
          "Не более 3 лет",
          "Не более 10 лет",
        ],
        correctAnswers: ["Не более 5 лет"],
      },
      {
        code: "20106011",
        text: "Для каких из перечисленных производств разрабатываются постоянные технологические регламенты?",
        answers: [
          "Для новых производств в организации",
          "Для действующих химико-технологических производств, в технологии которых произошли принципиальные изменения",
          "Для производств с новой технологией по выпуску продукции",
          "Для всех перечисленных производств",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20106012",
        text: "В каком из перечисленных случаев должен быть составлен временный технологический регламент на новый срок? Укажите все правильные ответы.",
        answers: [
          "Если такое решение принято руководителем и технологической службой организации",
          "Если в тексте технологического регламента обнаружены несогласованные правки",
          "Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса",
          "Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей",
        ],
        correctAnswers: [
          "Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса",
          "Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей",
        ],
      },
      {
        code: "20106013",
        text: "Каким образом определяется срок действия временного технологического регламента при отсутствии установленных планами норм освоения производства?",
        answers: [
          "Руководителем организации с учетом объема работ",
          "Начальником производственного участка с учетом затрат",
          "Лицом, утверждающим технологический регламент",
          "В соответствии с проектной документацией",
        ],
        correctAnswers: ["Лицом, утверждающим технологический регламент"],
      },
      {
        code: "20106014",
        text: "Каким образом устанавливается и оформляется срок продления действия временного технологического регламента?",
        answers: [
          "Приказом руководителя организации",
          "Решением комиссии организации, эксплуатирующей производственный объект",
          "Указанием руководителя территориального подразделения Ростехнадзора",
          "Специализированной организацией - разработчиком технологического процесса",
        ],
        correctAnswers: ["Приказом руководителя организации"],
      },
      {
        code: "20106015",
        text: "Какой из перечисленных разделов не относится к постоянным, временным и разовым технологическим регламентам, связанным с необходимостью обеспечения промышленной безопасности технологических процессов?",
        answers: [
          "Характеристика производимой продукции",
          "Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств",
          "Контроль производства и управление технологическим процессом",
          "Нормы расхода основных видов сырья, материалов и энергоресурсов",
        ],
        correctAnswers: [
          "Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств",
        ],
      },
      {
        code: "20106016",
        text: 'Информацию о каких организациях должен содержать раздел технологического регламента "Общая характеристика производства"?',
        answers: [
          "Только об организации, осуществляющей разработку технологической части проектной документации",
          "Только об организации, выполняющей функции генерального проектировщика",
          "Только об организации, являющейся разработчиком технологического процесса",
          "Только об организациях, исполнивших проектную документацию",
          "Обо всех перечисленных организациях",
        ],
        correctAnswers: ["Обо всех перечисленных организациях"],
      },
      {
        code: "20106017",
        text: 'Какое из перечисленных требований не соответствует разделу технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          "Периодичность испытаний запорной регулирующей арматуры, исполнительных механизмов, периодичность проверок приборов и испытаний других технических средств, участвующих в схемах контроля, управления и противоаварийной защиты технологических процессов, должны быть взаимоувязаны",
          "Допускается разделение в отдельные таблицы перечней систем сигнализации (блокировок) и (или) аналитического контроля",
          "Средства автоматики, используемые по плану мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, должны иметь особые пометки и выделения на технологической схеме",
          "Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается",
        ],
        correctAnswers: [
          "Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается",
        ],
      },
      {
        code: "20106018",
        text: 'Как производится описание технологической схемы в разделе технологического регламента "Описание технологического процесса и схемы"?',
        answers: [
          "По стадиям технологического процесса, начиная с поступления и подготовки сырья и заканчивая отгрузкой готового продукта",
          "По этапам технологического процесса, начиная с отгрузки сырья со склада",
          "По видам производственных операций, включая переработку сырья с использованием оборудования и выпуском готовой продукции",
        ],
        correctAnswers: [
          "По стадиям технологического процесса, начиная с поступления и подготовки сырья и заканчивая отгрузкой готового продукта",
        ],
      },
      {
        code: "20106019",
        text: 'Что необходимо указывать в описании процессов разделения химических продуктов (горючих или их смесей с негорючими) в разделе технологического регламента "Описание технологического процесса и схемы"?',
        answers: [
          "Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса",
          "Разделение агрессивных сред, показатели пожароопасности, токсичности используемых материалов",
          "Показатели взрывопожароопасности, токсичные свойства всех химических веществ, участвующих во всех стадиях процесса",
        ],
        correctAnswers: [
          "Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса",
        ],
      },
      {
        code: "20106020",
        text: "На основе каких данных составляется материальный баланс для действующих производств?",
        answers: [
          "На основе достигнутых показателей работы производств в последний год перед составлением технологического регламента",
          "На основе данных проектной документации",
          "На основе данных проектной документации с учетом внесенных в нее изменений, включения или исключения дополнительных операций или стадий",
        ],
        correctAnswers: [
          "На основе достигнутых показателей работы производств в последний год перед составлением технологического регламента",
        ],
      },
      {
        code: "20106021",
        text: 'Какие разновидности материального баланса допускается составлять в разделе технологического регламента "Материальный баланс"?',
        answers: [
          "Только на единицу выпускаемой продукции",
          "Только на единицу времени (в часах)",
          "Только на мощность производства в целом или единичный производственный поток",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20106022",
        text: "Какое из перечисленных требований соответствует нормам заполнения и хранения листа регистрации изменений и дополнений?",
        answers: [
          "Лист регистрации изменений и дополнений должен размещаться в конце каждого раздела технологического регламента, которого коснулись изменения",
          "Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под подпись в подразделения, где находятся копии регламента",
          "Запись в регистрационном листе выполняется черными чернилами",
          "Регистрация изменений и дополнений выполняется делопроизводителем организации",
        ],
        correctAnswers: [
          "Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под подпись в подразделения, где находятся копии регламента",
        ],
      },
      {
        code: "20106023",
        text: "Какой устанавливается срок действия накопительной ведомости?",
        answers: ["1 месяц", "6 месяцев", "3 месяца", "1 год"],
        correctAnswers: ["6 месяцев"],
      },
      {
        code: "20106024",
        text: 'Что должны обеспечивать системы контроля, автоматического и дистанционного управления (системы управления), системы оповещения об аварийных ситуациях в разделе технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          "Точность поддержания технологических параметров, надежность и безопасность проведения технологических процессов",
          "Контроль рабочего процесса, применения технологического оборудования, действий работников на производстве",
          "Безопасность при использовании производственного оборудования и применяемых химических веществ",
        ],
        correctAnswers: [
          "Точность поддержания технологических параметров, надежность и безопасность проведения технологических процессов",
        ],
      },
      {
        code: "20106025",
        text: "В соответствии с чем должны определяться оптимальные методы создания системы противоаварийной защиты на стадии формирования требований при проектировании автоматизированной системы управления технологическим процессом?",
        answers: [
          "В соответствии с возможными аварийными ситуациями и способами сохранения безопасности объекта",
          "В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности",
          "В соответствии с методиками, применяемыми для моделирования аварийных ситуаций, разработанными надзорным органом в сфере промышленной безопасности",
          "В соответствии с алгоритмами развития сценариев всех возможных инцидентов (аварий) на производственных участках и объекте в целом",
        ],
        correctAnswers: [
          "В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности",
        ],
      },
      {
        code: "20106026",
        text: "В течение какого времени средства обеспечения энергоустойчивости технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?",
        answers: [
          "В течение 12 часов",
          "В течение 8 часов",
          "В течение 2 часов",
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
        correctAnswers: [
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
      },
      {
        code: "20106027",
        text: "Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах I и II классов опасности?",
        answers: [
          "Не более 12 секунд",
          "Не более 120 секунд",
          "Не более 300 секунд",
          "Не более 240 секунд",
        ],
        correctAnswers: ["Не более 12 секунд"],
      },
      {
        code: "20106028",
        text: "Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах III класса опасности?",
        answers: [
          "Не менее 12 секунд",
          "Не более 120 секунд",
          "Не менее 300 секунд",
          "Не менее 240 секунд",
        ],
        correctAnswers: ["Не более 120 секунд"],
      },
      {
        code: "20106029",
        text: "Что в химико-технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и их развития?",
        answers: [
          "Средства защиты от превышения давления, огневые предохранители",
          "Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы",
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления",
          "Пожарные извещатели, аудиоусилители и дополнительные источники питания",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления",
        ],
      },
      {
        code: "20106030",
        text: "Какие требования предъявляются к пневматическим системам контроля, управления и противоаварийной автоматической защиты?",
        answers: [
          "Качество сжатого воздуха на системах должно проверяться не реже 1 раза в месяц",
          "Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги",
          "Для пневматических систем контроля, управления и противоаварийной защиты должны предусматриваться совмещенные сети технологического и сжатого воздуха",
          "Использование инертного газа для питания систем контрольно-измерительных приборов и устройств автоматического регулирования должно определяться спецификой объекта",
        ],
        correctAnswers: [
          "Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги",
        ],
      },
      {
        code: "20106031",
        text: "Для каких из перечисленных целей запрещается использовать специальные системы аварийного освобождения?",
        answers: [
          "Для исключения образования химически опасных смесей в системах",
          "Для обеспечения минимально возможного времени освобождения химико-технологических систем от обращающихся химически опасных продуктов",
          "Для контроля и исключения образования химически опасных смесей в окружающей атмосфере производственного помещения",
          "Для исключения развития аварий",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20106032",
        text: "На чем основаны оптимальные методы и средства противоаварийной автоматической защиты?",
        answers: [
          "На обучающих программных продуктах, применяемых для моделирования аварийных ситуаций, утвержденных управлением Ростехнадзора",
          "На алгоритмах, разработанных по сценариям всех возможных аварий",
          "На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления",
          "На структуре сценария возможной аварийной ситуации и способах перевода объекта в безопасное состояние",
        ],
        correctAnswers: [
          "На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления",
        ],
      },
      {
        code: "20106033",
        text: "Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?",
        answers: [
          "После выполнения рабочим персоналом всех необходимых переключений средств автоматического управления объектом по действующим производственным программам",
          "Обслуживающим персоналом по инструкции",
          "Ответственным работником по действующим программам на объекте после устранения причин, приведших к срабатыванию системы противоаварийной защиты",
          "Начальником производства по рабочим схемам с применением средств автоматического управления объектом",
        ],
        correctAnswers: ["Обслуживающим персоналом по инструкции"],
      },
      {
        code: "20106034",
        text: "Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания противоаварийной защиты?",
        answers: [
          "После выполнения рабочим персоналом всех необходимых переключений средств автоматического управления объектом по действующим производственным программам",
          "Выполняется обслуживающим персоналом по инструкции",
          "Ответственным работником по действующим программам на объекте после устранения причин, приведших к срабатыванию системы противоаварийной защиты",
          "Начальником производства по рабочим схемам с применением средств автоматического управления объектом",
        ],
        correctAnswers: ["Выполняется обслуживающим персоналом по инструкции"],
      },
      {
        code: "20106035",
        text: "Куда следует направлять сбрасываемые химически опасные вещества?",
        answers: [
          "В открытую систему общезаводской факельной установки",
          "В специальные металлические емкости",
          "В закрытые системы для дальнейшей утилизации",
          "В централизованную систему водоотведения",
        ],
        correctAnswers: ["В закрытые системы для дальнейшей утилизации"],
      },
      {
        code: "20106036",
        text: "На каких объектах ХОПО технические решения по обеспечению надежности контроля параметров, имеющих критические значения, обосновываются разработчиком документации на ХОПО? Укажите все правильные ответы.",
        answers: [
          "На всех объектах ХОПО",
          "На объектах ХОПО II класса опасности",
          "На объектах ХОПО I класса опасности",
          "На объектах ХОПО IV класса опасности",
          "На объектах ХОПО III класса опасности",
        ],
        correctAnswers: [
          "На объектах ХОПО IV класса опасности",
          "На объектах ХОПО III класса опасности",
        ],
      },
      {
        code: "20106037",
        text: "В каком документе организация, эксплуатирующая химически опасные производственные объекты I, II и III классов опасности, должна предусматривать действия работников по предупреждению аварий, их локализации и максимальному снижению тяжести последствий?",
        answers: [
          "В техническом проекте",
          "В плане мероприятий по локализации и ликвидации последствий аварий",
          "В проекте производства работ",
          "В технологическом регламенте",
        ],
        correctAnswers: [
          "В плане мероприятий по локализации и ликвидации последствий аварий",
        ],
      },
      {
        code: "20106038",
        text: "Какое из перечисленных требований к выполнению управляющих функций систем ПАЗ указано неверно?",
        answers: [
          "Команды управления, сформированные алгоритмами защит (блокировок), должны иметь приоритет по отношению к любым другим командам управления технологическим оборудованием",
          "В алгоритмах срабатывания защит следует предусматривать возможность включения блокировки команд управления оборудованием, технологически связанным с аппаратом, агрегатом или иным оборудованием, вызвавшим такое срабатывание",
          "Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы",
          "Системы ПАЗ должны реализоваться на принципах приоритетности защиты технологических процессов комплектно, с одновременной защитой отдельных единиц оборудования",
        ],
        correctAnswers: [
          "Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы",
        ],
      },
      {
        code: "20106039",
        text: "Где приводятся конкретные значения уставок систем защиты по опасным параметрам?",
        answers: [
          "В техническом паспорте взрывобезопасности опасного производственного объекта",
          "В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции",
          'В техническом регламенте "О безопасности оборудования для работы во взрывоопасных средах"',
        ],
        correctAnswers: [
          "В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20106040",
        text: "Какой параметр является критерием установления категории взрывоопасности технологических блоков согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?",
        answers: [
          "Температура самовоспламенения паров, обращающихся в процессе производства",
          "Класс опасности веществ, обращающихся в технологическом процессе",
          "Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему",
          "Скорость развития процесса горения опасных веществ",
        ],
        correctAnswers: [
          "Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему",
        ],
      },
      {
        code: "20106041",
        text: "Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?",
        answers: [
          "Приведенной массой вещества, участвующего во взрыве, в килограммах",
          "Категорией взрывоопасности технологических блоков",
          "Радиусом зон разрушения в метрах",
          "Энергией сгорания парогазовой фазы в килоджоулях",
        ],
        correctAnswers: ["Категорией взрывоопасности технологических блоков"],
      },
      {
        code: "20106042",
        text: "Какого показателя категорий взрывоопасности технологических блоков не существует?",
        answers: [
          "I категории",
          "II категории",
          "III категории",
          "IV категории",
        ],
        correctAnswers: ["IV категории"],
      },
      {
        code: "20106043",
        text: "Какой следует принимать категорию взрывоопасности блоков, определяемую расчетом, если обращающиеся в технологическом блоке опасные вещества относятся к токсичным, высокотоксичным веществам?",
        answers: [
          "I категории",
          "На одну выше",
          "II категории",
          "III категории",
        ],
        correctAnswers: ["На одну выше"],
      },
      {
        code: "20106044",
        text: "Что из перечисленного является недопустимым на территории предприятия, имеющего в своем составе взрывопожароопасные производства?",
        answers: [
          "Только природные овраги, выемки, низины",
          "Только открытые траншеи, котлованы, приямки, в которых возможно скопление взрывопожароопасных паров и газов",
          "Только траншейная и наземная прокладка в искусственных или естественных углублениях трасс трубопроводов со сжиженными горючими газами, с легковоспламеняющимися и горючими жидкостями",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20106045",
        text: "Каким образом обеспечивается отработка персоналом практических навыков безопасного выполнения работ, предупреждения аварий и ликвидации их последствий на технологических объектах с блоками I и II категорий взрывоопасности?",
        answers: [
          "Посредством допуска персонала к работе с наставником",
          "Посредством обучения производственного персонала на базе учебного центра",
          "Посредством прохождения курса подготовки с использованием современных технических средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)",
          "Посредством наглядных пособий (плакатов) по охране труда",
        ],
        correctAnswers: [
          "Посредством прохождения курса подготовки с использованием современных технических средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)",
        ],
      },
      {
        code: "20106046",
        text: "Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?",
        answers: [
          "Системами ручного регулирования процесса безаварийной остановки производства",
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
          "Средствами обеспечения питания инертным газом систем контрольно-измерительных приборов и автоматики",
        ],
        correctAnswers: [
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
        ],
      },
      {
        code: "20106047",
        text: "Каким образом осуществляется управление подачей инертных сред на установку с технологическими блоками любой категории взрывоопасности там, где при отклонении от регламентированных значений параметров возможно образование взрывоопасных смесей?",
        answers: [
          "Для установок с технологическими блоками I, II категорий взрывоопасности предусматривается ручное, дистанционное управление",
          "Для установок с технологическими блоками I и II категории взрывоопасности предусматривается автоматическое управление подачей инертных сред, с технологическими блоками III категории - управление дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление",
          "Для установок с технологическими блоками III категории взрывоопасности предусматривается автоматическое управление подачей инертных сред",
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности предусматривается дистанционное управление, а при Qв ≤ 10 - автоматическое управление",
        ],
        correctAnswers: [
          "Для установок с технологическими блоками I и II категории взрывоопасности предусматривается автоматическое управление подачей инертных сред, с технологическими блоками III категории - управление дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление",
        ],
      },
      {
        code: "20106048",
        text: "В соответствии с чем осуществляется ведение технологических процессов на ОПО химических, нефтехимических и нефтегазоперерабатывающих производств?",
        answers: [
          "В соответствии с технологическими регламентами на производство продукции",
          "В соответствии с указаниями территориального управления Ростехнадзора",
          "В соответствии с заключением экспертизы промышленной безопасности",
          "В соответствии с распоряжениями руководителя эксплуатирующей организации",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами на производство продукции",
        ],
      },
      {
        code: "20106049",
        text: "В соответствии с какими документами осуществляют ведение технологических процессов на химически опасных производственных объектах?",
        answers: [
          "В соответствии с декларацией промышленной безопасности",
          "В соответствии с проектной документацией",
          "В соответствии с технологическими регламентами на производство продукции",
          "В соответствии с руководствами (инструкциями) по эксплуатации технических устройств",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами на производство продукции",
        ],
      },
      {
        code: "20106050",
        text: "Кем разрабатываются исходные данные на разработку документации на химически опасных производственных объектах?",
        answers: [
          "Эксплуатирующими организациями",
          "Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области",
          "Организациями, осуществляющими проведение экспертизы промышленной безопасности",
          "Территориальным управлением Ростехнадзора",
        ],
        correctAnswers: [
          "Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области",
        ],
      },
      {
        code: "20106051",
        text: "В какой документации приводятся способы и средства, исключающие выход параметров за установленные пределы?",
        answers: [
          "В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции",
          "Только в исходных данных на проектирование",
          "Только в проектной документации",
          "Только в технологическом регламенте",
        ],
        correctAnswers: [
          "В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20106052",
        text: "В каком случае допускается наработка товарной продукции по лабораторным регламентам (пусковым запискам, производственным методикам)?",
        answers: [
          "Объемом от 2000 до 3000 кг/год",
          "Объемом до 1000 кг/год",
          "Объемом 1500 кг/год",
          "Ни в каком случае",
        ],
        correctAnswers: ["Объемом до 1000 кг/год"],
      },
      {
        code: "20106053",
        text: "Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?",
        answers: [
          "Время срабатывания устанавливается для каждого технологического блока в соответствии с категорией взрывоопасности",
          "Время срабатывания определяется расчетом",
          "Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и устанавливается для блоков III категории в соответствии с категорией взрывоопасности",
          "Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и устанавливается для блоков I и II категорий в соответствии с категорией взрывоопасности",
        ],
        correctAnswers: ["Время срабатывания определяется расчетом"],
      },
      {
        code: "20106054",
        text: "Какими блокировками должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?",
        answers: [
          "Защитными устройствами, исключающими пуск при незначительных объемах перемещаемой среды",
          "Блокировочными устройствами, прекращающими работу при достижении опасных показателей в приемной емкости",
          "Предохранительными блокировками, исключающими пуск при отклонениях от опасных значений параметров в расходной емкости",
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
        correctAnswers: [
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
      },
      {
        code: "20106055",
        text: "Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?",
        answers: [
          "На установках с технологическими блоками I категории взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками II и III категорий взрывоопасности - не регламентируется",
          "На установках с технологическими блоками I и II категорий взрывоопасности давление теплоносителя (хладагента) не должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками III категории взрывоопасности - не регламентируется",
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
          "Давление теплоносителя (хладагента) не должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
        ],
        correctAnswers: [
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
        ],
      },
      {
        code: "20106056",
        text: "Кто определяет выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?",
        answers: [
          "Заказчик проекта производства работ",
          "Разработчик процесса",
          "Производитель работ",
        ],
        correctAnswers: ["Разработчик процесса"],
      },
      {
        code: "20106057",
        text: "Какая арматура устанавливается на трубопроводах для транспортирования взрывопожароопасных продуктов?",
        answers: [
          "Только с герметичными клапанами",
          "Соответствующая проектной документации (документации на техническое перевооружение)",
          "Только с резиновыми вкладышами в корпусе затвора",
          "Только с ламинарным уплотнением на затворе",
        ],
        correctAnswers: [
          "Соответствующая проектной документации (документации на техническое перевооружение)",
        ],
      },
      {
        code: "20106058",
        text: "Чем должны быть оборудованы аппараты со взрывопожароопасными веществами?",
        answers: [
          "Циклонами",
          "Устройствами для подключения подачи водорода",
          "Устройствами для подключения линий воды, пара, инертного газа",
          "Виброситом с двойными деками",
        ],
        correctAnswers: [
          "Устройствами для подключения линий воды, пара, инертного газа",
        ],
      },
      {
        code: "20106059",
        text: "Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся жидкости и горючие жидкости на сливоналивных эстакадах?",
        answers: [
          "Должно быть организовано вручную по месту",
          "Должно быть организовано только удаленно из безопасного места",
          "Должно быть организовано управление и по месту, и дистанционно (из безопасного места)",
        ],
        correctAnswers: [
          "Должно быть организовано управление и по месту, и дистанционно (из безопасного места)",
        ],
      },
      {
        code: "20106060",
        text: "Какие сведения являются основополагающими при выборе технологического оборудования для обеспечения технологических процессов?",
        answers: [
          "Расчетные данные и показатели надежности с учетом мощности оборудования",
          "Основные технические параметры, которым должно соответствовать оборудование, а также требования действующих нормативных документов",
          "Требования действующих нормативных документов, категория производства по взрывопожароопасности",
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
        correctAnswers: [
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
      },
      {
        code: "20106061",
        text: "В каком документе указываются данные о сроке службы технологического оборудования и трубопроводной арматуры производителем?",
        answers: [
          "В паспортах оборудования и трубопроводной арматуры",
          "В техническом задании на проектирование оборудования",
          "В сертификате на оборудование",
          "В технологическом регламенте",
        ],
        correctAnswers: ["В паспортах оборудования и трубопроводной арматуры"],
      },
      {
        code: "20106062",
        text: "Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?",
        answers: [
          "Оборудование должно быть помечено краской с нанесением специальных обозначающих знаков, а также отмечено на схеме технологической документации как выведенное из работы",
          "Оборудование должно быть демонтировано, если оно расположено в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих технологических систем",
          "Оборудование должно быть изолировано от действующих технологических систем",
          "Оборудование должно быть законсервировано",
        ],
        correctAnswers: [
          "Оборудование должно быть демонтировано, если оно расположено в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих технологических систем",
        ],
      },
      {
        code: "20106063",
        text: "С какой периодичностью проводится только наружный осмотр при техническом освидетельствовании трубопроводов?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 5 лет",
        ],
        correctAnswers: ["Не реже 1 раза в 2 года"],
      },
      {
        code: "20106064",
        text: "С какой периодичностью проводятся наружный осмотр и испытание пробным давлением при техническом освидетельствовании трубопроводов? Укажите все правильные ответы.",
        answers: [
          "Не реже 1 раза в 5 лет",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 8 лет",
          "По окончании монтажных работ перед пуском в эксплуатацию",
        ],
        correctAnswers: [
          "Не реже 1 раза в 8 лет",
          "По окончании монтажных работ перед пуском в эксплуатацию",
        ],
      },
      {
        code: "20106065",
        text: "Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития?",
        answers: [
          "Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы",
          "Запорную арматуру, средства защиты от превышения температурного режима, огнепреградители",
          "Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
          "Блокировочную арматуру, предохранительные устройства от превышения давления",
        ],
        correctAnswers: [
          "Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20106066",
        text: "Какие параметры должны регламентироваться в процессах смешивания при возможности развития самоускоряющихся экзотермических реакций?",
        answers: [
          "Только подача флегматизирующих агентов",
          "Только последовательность и допустимое количество загружаемых в аппаратуру веществ",
          "Только скорость сгрузки (поступления) реагентов",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20106067",
        text: "Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте на производство продукции и инструкциях",
          "Средства автоматики должны быть обозначены на мнемосхемах",
          "Средства автоматики должны быть обозначены только в технологическом регламенте на производство продукции",
          "Требования к обозначению определяются при разработке планов мероприятий по локализации и ликвидации последствий аварий",
        ],
        correctAnswers: [
          "Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте на производство продукции и инструкциях",
        ],
      },
      {
        code: "20106068",
        text: "Каким образом обеспечивается надежность обеспечения средств управления и системы противоаварийной защиты сжатым воздухом?",
        answers: [
          "Установкой резервного компрессора с его автоматическим включением при остановке основного оборудования",
          "Переключением сети воздуха устройств автоматического регулирования и контрольно-измерительных приборов через осушитель на заводскую сеть сжатого воздуха",
          "Установкой буферных емкостей, обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
        correctAnswers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20106069",
        text: "По какой категории надежности должно осуществляться электроснабжение химически опасных производственных объектов?",
        answers: [
          "По II или III категории надежности",
          "По I или II категории надежности",
          "Допустимая категория надежности устанавливается разработчиком проекта в зависимости от назначения производственного объекта",
          "Только по I категории надежности",
        ],
        correctAnswers: ["По I или II категории надежности"],
      },
      {
        code: "20106070",
        text: "Что должно быть учтено в системах управления и защит электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?",
        answers: [
          "Наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения",
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
          "Должна быть обеспечена селективность защит на устройствах электроснабжающей и электропотребляющей организаций",
          "Должна быть обеспечена возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой",
        ],
        correctAnswers: [
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
        ],
      },
      {
        code: "20106071",
        text: "Какая должна быть степень защиты электроприборов и средств автоматического и дистанционного управления, располагающихся в помещениях с аммиачным оборудованием?",
        answers: [
          "Не ниже IP65",
          "Не ниже IP44",
          "Не ниже IP54",
          "Не ниже IP20",
        ],
        correctAnswers: ["Не ниже IP44"],
      },
      {
        code: "20106072",
        text: 'Какое требование к системам вентиляции не соответствует ФНП "Общие правила взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств"?',
        answers: [
          "Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания",
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
          "Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства",
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: "20106073",
        text: "Какая система отопления должна применяться в помещениях, имеющих взрывоопасные зоны?",
        answers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
          "Только система водяного отопления",
          "Только автономная система парового отопления",
        ],
        correctAnswers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
        ],
      },
      {
        code: "20106074",
        text: "Какая максимальная температура поверхностей нагрева систем отопления должна быть в помещениях, имеющих взрывоопасные зоны?",
        answers: [
          "Максимальная температура не должна превышать 80 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 85 % температуры самовоспламенения всех обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 90 % температуры самовоспламенения вещества, имеющего самую высокую температуру самовоспламенения",
          "Максимальная температура не должна превышать 95 % температуры самовоспламенения обращающегося в процессе вещества",
        ],
        correctAnswers: [
          "Максимальная температура не должна превышать 80 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
      },
      {
        code: "20106075",
        text: "Какие требования предъявляются к системам канализации технологических объектов при сбросе химически загрязненных стоков в магистральную сеть канализации?",
        answers: [
          "Системы канализации технологических объектов должны осуществлять сброс стоков в магистральную сеть в установленном организацией порядке",
          "Системы канализации технологических объектов должны быть оборудованы звуковой и световой сигнализацией",
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
          "Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть",
        ],
        correctAnswers: [
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
        ],
      },
      {
        code: "20106076",
        text: "Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?",
        answers: [
          "Применение компонентов в фазовом состоянии, затрудняющем или исключающем образование химически опасной смеси",
          "Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки",
          "Рациональный подбор взаимодействующих компонентов исходя из условия максимального снижения или исключения образования химически опасных смесей или продуктов",
          "Выбор значений параметров состояния технологической среды (состава, давления, температуры), снижающих ее химическую опасность",
        ],
        correctAnswers: [
          "Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки",
        ],
      },
      {
        code: "20106077",
        text: "В каком случае допускается объединение выбросов химически опасных веществ, содержащих вещества, способные при смешивании образовывать более опасные по воздействиям химические соединения?",
        answers: [
          "В случае разработки необходимых мер безопасности, предусмотренных документацией производственного объекта",
          "В случае использования специальных систем аварийного освобождения",
          "В случае расчета выбросов, количество которых определяется условиями безопасной остановки технологического процесса",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20106078",
        text: "Где должны быть установлены манометры (мановакуумметры) в целях обеспечения безопасности ведения технологических процессов в системах холодоснабжения на холодильном оборудовании и машинах?",
        answers: [
          "Только на жидкостных и оттаивательных коллекторах распределительных аммиачных устройств, соединенных трубопроводами с оборудованием холодильных камер",
          "Только в картере поршневых компрессоров, не имеющих уравнивания между всасыванием и картером",
          "Только на компрессоре для наблюдения за рабочими давлениями всасывания, нагнетания",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20106079",
        text: "На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?",
        answers: [
          "На проведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы",
          "На проведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности",
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          "На ведение газоопасных, огневых и ремонтных работ на стационарных автомобильных газозаправочных станциях",
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "20106080",
        text: "Кем из перечисленных должностных лиц согласовываются сроки проведения газоопасных работ на опасных производственных объектах подрядными организациями?",
        answers: [
          "Непосредственным руководителем работ от подрядчика",
          "Руководителем эксплуатирующей организации или его уполномоченным заместителем, либо руководителем филиала или его уполномоченным заместителем с учетом особенностей использования электронной подписи",
          "Работником, ответственным за осуществление производственного контроля",
          "Представителем территориального управления Ростехнадзора",
        ],
        correctAnswers: [
          "Руководителем эксплуатирующей организации или его уполномоченным заместителем, либо руководителем филиала или его уполномоченным заместителем с учетом особенностей использования электронной подписи",
        ],
      },
      {
        code: "20106081",
        text: "Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?",
        answers: [
          "Лица, допущенные к выполнению газоопасных работ, должны пройти обучение приемам и методам проведения работ",
          "Лица, допущенные к выполнению газоопасных работ, должны пройти медицинский осмотр в соответствии с требованиями законодательства Российской Федерации",
          "Лица, допущенные к выполнению газоопасных работ, должны быть не моложе 18 лет",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20106082",
        text: "Что из перечисленного допускается при проведении газоопасных работ?",
        answers: [
          "Выполнение разовых газоопасных работ бригадой, два работника из числа которой не обеспечены специальным инструментом",
          "Выполнение газоопасных работ I группы бригадой исполнителей в составе двух человек",
          "Увеличение объема работ, предусмотренных нарядом-допуском на проведение газоопасных работ",
          "Совмещение газоопасных работ и огневых работ в непосредственной близости на открытой площадке при незначительном выделении пожаровзрывоопасных веществ в зоне проведения работ",
        ],
        correctAnswers: [
          "Выполнение газоопасных работ I группы бригадой исполнителей в составе двух человек",
        ],
      },
      {
        code: "20106083",
        text: "Когда могут проводиться газоопасные работы, выполняемые по наряду-допуску?",
        answers: [
          "Во время грозы при проведении неотложных газоопасных работ",
          "В вечернее время при проведении плановых работ с соблюдением мероприятий по обеспечению безопасного проведения газоопасных работ, учитывающих условия их выполнения в темное время суток",
          "В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск",
          "В темное время суток с участием или в присутствии лица, ответственного за выполнение газоопасных работ",
        ],
        correctAnswers: [
          "В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск",
        ],
      },
      {
        code: "20106084",
        text: "К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?",
        answers: [
          "К III группе",
          "К II группе",
          "К I группе",
          "К группе работ средней степени опасности",
        ],
        correctAnswers: ["К II группе"],
      },
      {
        code: "20106085",
        text: "Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
          "Без оформления наряда-допуска под контролем ответственного руководителя газоопасных работ",
          "Запрещается выполнение работ, не включенных в утвержденный перечень газоопасных работ",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
      {
        code: "20106086",
        text: "Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?",
        answers: [
          "Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
          "Лицо, выдающее наряд-допуск, на срок до окончания выполнения работ",
          "Руководитель структурного подразделения не более чем на 3 рабочих смены",
          "Ответственный производитель работ не более чем на сутки",
        ],
        correctAnswers: [
          "Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
        ],
      },
      {
        code: "20106087",
        text: "В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?",
        answers: [
          "Не менее 1 месяца со дня закрытия наряда-допуска",
          "Не менее 1 года со дня закрытия наряда-допуска",
          "Не менее 6 месяцев со дня закрытия наряда-допуска",
        ],
        correctAnswers: ["Не менее 6 месяцев со дня закрытия наряда-допуска"],
      },
      {
        code: "20106088",
        text: "Что относится к технологическим трубопроводам?",
        answers: [
          "Трубопроводы, предназначенные для обогрева технологических трубопроводов",
          "Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования",
          "Трубопроводы канализации неагрессивных стоков и ливневой канализации",
        ],
        correctAnswers: [
          "Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования",
        ],
      },
      {
        code: "20106089",
        text: "В соответствии с чем следует производить монтаж технологического оборудования и трубопроводов?",
        answers: [
          "Только в соответствии с проектной документацией",
          "Только в соответствии с рабочей документацией",
          "В соответствии со всем перечисленным",
        ],
        correctAnswers: ["В соответствии со всем перечисленным"],
      },
      {
        code: "20106090",
        text: "Кто устанавливает назначенный срок службы для технологических трубопроводов?",
        answers: [
          "Уполномоченный орган по сертификации",
          "Организация-изготовитель",
          "Разработчик документации",
          "Центр метрологии и стандартизации",
        ],
        correctAnswers: ["Разработчик документации"],
      },
      {
        code: "20106091",
        text: "Кто устанавливает назначенный срок службы для технологического оборудования, машин и трубопроводной арматуры?",
        answers: [
          "Разработчик документации",
          "Организация-изготовитель",
          "Орган по сертификации на основании заключения испытательной лаборатории",
          "Эксплуатирующая организация",
        ],
        correctAnswers: ["Организация-изготовитель"],
      },
      {
        code: "20106092",
        text: "В каких местах не допускается размещать фланцевые соединения трубопроводов с пожаровзрывоопасными, токсичными и едкими веществами?",
        answers: [
          "На трубопроводах, проложенных на отдельно стоящих эстакадах",
          "Над автодорогами",
          "На трубопроводах, расположенных на поверхности глухих стен вспомогательных помещений",
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
        ],
        correctAnswers: [
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
        ],
      },
      {
        code: "20106093",
        text: "На каких трубопроводах следует применять арматуру под приварку для повышения надежности и плотности соединений?",
        answers: [
          "На трубопроводах технологических блоков III категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды, при регламентированном давлении",
          "На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды, при регламентированном давлении",
          "На трубопроводах технологических блоков II категории взрывоопасности с температурой, равной температуре кипения среды при регламентированном давлении",
          "На трубопроводах технологических блоков I категории взрывоопасности с давлением среды менее 2,5 МПа",
        ],
        correctAnswers: [
          "На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды, при регламентированном давлении",
        ],
      },
      {
        code: "20106094",
        text: "В каком случае допускается предусматривать отдельные отделители жидкости, соединенные трубопроводами с циркуляционными (защитными) ресиверами, не совмещающими функции отделителя жидкости, для отделения жидкой фазы из перемещаемой парожидкостной смеси в системах холодоснабжения?",
        answers: [
          "Допускается в случае применения аппаратов горизонтального типа",
          "Допускается в случае использования аппаратов вертикального типа",
          "Ни в каком случае",
          "Допускается в обоснованных в проектной документации случаях",
        ],
        correctAnswers: [
          "Допускается в обоснованных в проектной документации случаях",
        ],
      },
      {
        code: "20106095",
        text: "В течение какого минимального отрезка времени сосуд (аппарат), трубопровод должен находиться под пробным давлением, после чего давление постепенно должно быть снижено до расчетного, при котором проводится осмотр наружной поверхности сосуда (аппарата, трубопровода) с проверкой плотности его швов и разъемных соединений мыльным раствором или другим способом?",
        answers: [
          "В течение 10 минут",
          "В течение 20 минут",
          "В течение 15 минут",
          "Время не регламентируется",
        ],
        correctAnswers: ["В течение 15 минут"],
      },
      {
        code: "20106096",
        text: "Какое из перечисленных требований к испытанию на прочность после монтажа до пуска в эксплуатацию сосуда (аппарата), трубопровода (его участка) указано верно?",
        answers: [
          "Использование запорной арматуры для отключения испытываемого сосуда (аппарата) и трубопровода допускается в присутствии работника, ответственного за испытания",
          "При испытании на прочность после монтажа до пуска в эксплуатацию испытываемый сосуд (аппарат), трубопровод (его участок) должны быть отсоединены от других сосудов, аппаратов и других трубопроводов с использованием металлических заглушек с прокладками, имеющих хвостовики, выступающие за пределы фланцев не менее 20 мм",
          "Толщина заглушки для отсоединений трубопроводов при проведении испытаний должна быть рассчитана на условия работы при давлении выше пробного в 2,5 раза",
          "При периодическом освидетельствовании и техническом диагностировании с использованием метода акустической эмиссии и применением аммиака в качестве нагружающей среды испытывать отдельные технологические линии блоком не допускается",
        ],
        correctAnswers: [
          "При испытании на прочность после монтажа до пуска в эксплуатацию испытываемый сосуд (аппарат), трубопровод (его участок) должны быть отсоединены от других сосудов, аппаратов и других трубопроводов с использованием металлических заглушек с прокладками, имеющих хвостовики, выступающие за пределы фланцев не менее 20 мм",
        ],
      },
      {
        code: "20106097",
        text: "На каком расстоянии от опор и подвесок следует располагать сварные стыки трубопроводов для труб диаметром менее 50 мм?",
        answers: [
          "Не менее 250 мм",
          "Не менее 100 мм",
          "Не менее 200 мм",
          "Не менее 150 мм",
        ],
        correctAnswers: ["Не менее 100 мм"],
      },
      {
        code: "20106098",
        text: "О чем сигнализирует красная лампа световой сигнализации?",
        answers: [
          "Сигнал о минимальном уровне жидкого аммиака в сосуде (предупредительная сигнализация)",
          "Сигнал об опасном повышении верхнего уровня жидкого аммиака в сосуде (предупредительная сигнализация)",
          "Сигнал о предельно допустимом уровне жидкого аммиака в сосуде (предаварийная сигнализация)",
          "Сигнал об аварийной остановке компрессора системы холодоснабжения",
        ],
        correctAnswers: [
          "Сигнал о предельно допустимом уровне жидкого аммиака в сосуде (предаварийная сигнализация)",
        ],
      },
      {
        code: "20106099",
        text: "В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?",
        answers: [
          "При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости",
          "При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации",
          "При необходимости повторного автоматического запуска электродвигателя",
          "При оборудовании насосов датчиками контроля температуры подшипников",
        ],
        correctAnswers: [
          "При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации",
        ],
      },
      {
        code: "20106100",
        text: "Где допускается размещать насосы оборотного водоснабжения в обоснованных в проектной документации случаях? Укажите все правильные ответы.",
        answers: [
          "На открытых площадках вне машинного (аппаратного) отделения",
          "В бытовом помещении",
          "В машинном отделении",
          "В аппаратном отделении",
        ],
        correctAnswers: ["В машинном отделении", "В аппаратном отделении"],
      },
      {
        code: "20106101",
        text: "В каком случае допускается размещать холодильное оборудование над площадками открытых насосных и компрессорных установок? Укажите все правильные ответы.",
        answers: [
          "В случае применения герметичных (бессальниковых) насосов",
          "При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование",
          "В случае размещения холодильного оборудования над площадками на расстоянии, соответствующем проектной документации",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "В случае применения герметичных (бессальниковых) насосов",
          "При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование",
        ],
      },
      {
        code: "20106102",
        text: "Чем определяется количество насосов и компрессоров, используемых для перемещения химически опасных веществ, в технологическом процессе?",
        answers: [
          "Физико-химическими свойствами перемещаемых продуктов",
          "Критическими параметрами технологического процесса",
          "Необходимостью и условиями обеспечения непрерывности технологического процесса",
          "Техническими характеристиками используемых насосов (компрессоров)",
        ],
        correctAnswers: [
          "Необходимостью и условиями обеспечения непрерывности технологического процесса",
        ],
      },
      {
        code: "20106103",
        text: "Какие условия должны выполняться для допуска к эксплуатации компрессорных установок?",
        answers: [
          "Наличие положительного заключения промышленной экспертизы",
          "Наличие и исправное состояние средств автоматизации, контроля и системы блокировок",
          "Наличие разрешения уполномоченного органа на применение оборудования (технического устройства)",
          "Все перечисленные условия",
        ],
        correctAnswers: [
          "Наличие и исправное состояние средств автоматизации, контроля и системы блокировок",
        ],
      },
      {
        code: "20106104",
        text: "Какое требование к первичному пуску компрессоров в работу после длительной остановки, ремонта, профилактики, а также после остановки компрессора при срабатывании приборов предаварийной защиты указано верно?",
        answers: [
          "Первичный пуск компрессора в работу необходимо производить вручную с закрытыми всасывающими клапанами в соответствии с инструкцией организации-изготовителя",
          "Перед пуском компрессора в работу следует убедиться, что все запорные клапаны на нагнетательном трубопроводе от компрессора до конденсатора открыты",
          "При пуске компрессора с использованием встроенного байпаса нагнетательный вентиль компрессора должен быть закрыт, а клапан байпаса открыт, если это предусмотрено инструкцией организации-изготовителя",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20106105",
        text: "Каким образом осуществляется контроль за содержанием кислорода в горючем газе во всасывающих линиях компрессоров, работающих под разрежением? Выберите 2 правильных варианта ответа.",
        answers: [
          "Определяются места размещения пробоотборников и способы контроля",
          "Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения",
          "Проводится систематический контроль содержания кислорода в горючем газе не реже 2 раз в смену",
          "Закачивается инертный газ во всасывающие линии насоса при остановке компрессора",
        ],
        correctAnswers: [
          "Определяются места размещения пробоотборников и способы контроля",
          "Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения",
        ],
      },
      {
        code: "20106106",
        text: "Какая максимальная температура нагнетания должна быть для поршневых компрессоров, если инструкцией организации-изготовителя не предусмотрено иное значение?",
        answers: ["185 °C", "135 °C", "160 °C", "90 °C"],
        correctAnswers: ["160 °C"],
      },
      {
        code: "20106107",
        text: "Какую температуру должна иметь вода для охлаждения компрессора на входе и на выходе из рубашек цилиндров, если заводом-изготовителем не предусмотрены другие предельные значения?",
        answers: [
          "Не ниже 20 °C и не более 55 °C соответственно",
          "Не ниже 10 °C и не более 45 °C соответственно",
          "Не ниже 15 °C и не более 40 °C соответственно",
          "Не ниже 5 °C и не более 35 °C соответственно",
        ],
        correctAnswers: ["Не ниже 10 °C и не более 45 °C соответственно"],
      },
      {
        code: "20106108",
        text: "С какой периодичностью предохранительные устройства компрессорных агрегатов должны проверяться на давление срабатывания?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 5 лет",
          "Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией",
        ],
        correctAnswers: [
          "Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией",
        ],
      },
      {
        code: "20106109",
        text: "Какими из перечисленных приборов могут быть оснащены сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора?",
        answers: [
          "Только средствами блокировки",
          "Только приборами контроля уровня",
          "Только сигнализацией по максимальному уровню",
          "Всеми перечисленными приборами",
        ],
        correctAnswers: ["Всеми перечисленными приборами"],
      },
      {
        code: "20106110",
        text: "Какое из перечисленных требований к манометрам, контролирующим давление при испытании на прочность сосудов (аппаратов), трубопроводов, указано неверно?",
        answers: [
          "Давление при испытании должно контролироваться двумя манометрами, опломбированными и прошедшими поверку",
          "Манометры должны устанавливаться в следующем порядке: один - у источника давления (воздушного компрессора, баллона с инертным газом), другой - на сосуде (аппарате) и трубопроводе в точке, наиболее удаленной от воздушного компрессора",
          "Манометры должны быть одинакового класса точности, не ниже 2,5",
          "Манометры должны быть не менее 160 мм в диаметре корпуса и иметь шкалу на номинальное давление, равное 4/3 от измеряемого давления",
        ],
        correctAnswers: [
          "Манометры должны быть одинакового класса точности, не ниже 2,5",
        ],
      },
      {
        code: "20106111",
        text: "Каким следует принимать расстояние в свету от аппаратов (сосудов), расположенных снаружи машинного (аппаратного) отделения?",
        answers: [
          "Не менее 2,5 м от стены здания",
          "Не менее 2,0 м от стены здания",
          "Не менее 1,0 м от стены здания",
          "Не менее 1,5 м от стены здания",
        ],
        correctAnswers: ["Не менее 1,0 м от стены здания"],
      },
      {
        code: "20106112",
        text: "Какая минимальная продолжительность испытаний на плотность всей системы сосудов, аппаратов и трубопроводов?",
        answers: ["12 часов", "10 часов", "5 часов", "3 часа"],
        correctAnswers: ["12 часов"],
      },
      {
        code: "20106113",
        text: "В случае какой остановки сосуды, аппараты и трубопроводы холодильных установок должны подвергаться техническому освидетельствованию?",
        answers: [
          "Ни в каком случае, длительность остановки не влияет на необходимость проведения технического освидетельствования",
          "В случае остановки более 1 месяца",
          "В случае остановки на 6 месяцев",
          "В случае остановки на год",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20106114",
        text: "С какой периодичностью должно проводиться техническое освидетельствование сосудов и аппаратов холодильных установок?",
        answers: [
          "Разово, перед пуском оборудования в работу",
          "Не реже 1 раза в 5 лет",
          "Периодичность определяется с учетом требований федеральных норм и правил в области промышленной безопасности, устанавливающих правила промышленной безопасности опасных производственных объектов, на которых используется оборудование, работающее под избыточным давлением",
          "Периодичность устанавливается самостоятельно эксплуатирующей организацией",
        ],
        correctAnswers: [
          "Периодичность определяется с учетом требований федеральных норм и правил в области промышленной безопасности, устанавливающих правила промышленной безопасности опасных производственных объектов, на которых используется оборудование, работающее под избыточным давлением",
        ],
      },
      {
        code: "20106115",
        text: "С какой периодичностью необходимо проверять исправность защитных реле уровня на аппаратах (сосудах)?",
        answers: [
          "1 раз в 3 месяца",
          "1 раз в месяц",
          "1 раз в 6 месяцев",
          "1 раз в год",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20106116",
        text: "Кем должна определяться готовность холодильной системы к заполнению хладагентом после завершения монтажных работ и проведения испытаний на прочность и плотность?",
        answers: [
          "Техническим руководителем комиссии по экспертизе промышленной безопасности",
          "Экспертом испытательной лаборатории",
          "Комиссией эксплуатирующей организации",
          "Представителем территориального управления Ростехнадзора",
        ],
        correctAnswers: ["Комиссией эксплуатирующей организации"],
      },
      {
        code: "20106117",
        text: "В течение какого времени и при каком давлении следует проводить вакуумирование холодильной установки перед пуском в эксплуатацию после пневматических испытаний?",
        answers: [
          "В течение 7 часов при остаточном давлении 0,05 МПа (0,5 кгс/см²)",
          "В течение 18 часов при остаточном давлении 0,01 МПа (0,1 кгс/см²)",
          "В течение 15 часов при остаточном давлении 0,03 МПа (0,3 кгс/см²)",
          "В течение 10 часов при остаточном давлении 0,015 МПа (0,15 кгс/см²)",
        ],
        correctAnswers: [
          "В течение 18 часов при остаточном давлении 0,01 МПа (0,1 кгс/см²)",
        ],
      },
      {
        code: "20106118",
        text: "Что из перечисленного не допускается при эксплуатации систем холодоснабжения? Укажите все правильные ответы.",
        answers: [
          "Впрыск жидкого аммиака во всасывающий трубопровод (полость) поршневого компрессора",
          "Использование переносных приборов в местах регулярного контроля работы аммиачной холодильной установки",
          "Эксплуатация винтовых компрессоров с впрыском жидкого аммиака по инструкции организации-изготовителя",
          "Установка вспрыскивающих устройств, предусмотренных инструкцией изготовителя оборудования",
        ],
        correctAnswers: [
          "Впрыск жидкого аммиака во всасывающий трубопровод (полость) поршневого компрессора",
          "Использование переносных приборов в местах регулярного контроля работы аммиачной холодильной установки",
        ],
      },
      {
        code: "20106119",
        text: "В каком случае допускается оснащать сосуды холодильных систем одним предохранительным клапаном?",
        answers: [
          "Ни в каком случае",
          "Если геометрический объем сосуда не превышает 0,3 м³",
          "Если предохранительное устройство рассчитано на полную пропускную способность",
          "Если это определено в технологической документации",
        ],
        correctAnswers: [
          "Если геометрический объем сосуда не превышает 0,3 м³",
        ],
      },
      {
        code: "20106120",
        text: "В каком случае допускается использование ртутных термометров и ртутных устройств для измерения температуры в контрольных точках аммиачной холодильной системы?",
        answers: [
          "Только если термометры на компрессорах предусмотрены заводом-изготовителем",
          "Только если используется защита от механических повреждений устройств",
          "Только если это обосновано проектной документацией",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20106121",
        text: "Каким должно быть расстояние от охлаждающих батарей до грузового штабеля в холодильных камерах?",
        answers: [
          "Не менее 0,1 м",
          "Не менее 0,2 м",
          "Не менее 0,3 м",
          "Расстояние не регламентируется",
        ],
        correctAnswers: ["Не менее 0,3 м"],
      },
      {
        code: "20106122",
        text: "Кто утверждает годовые и месячные графики ремонта холодильного оборудования?",
        answers: [
          "Председатель комиссии по приемке оборудования",
          "Главный инженер эксплуатирующей организации",
          "Технический руководитель организации",
          "Начальник службы производственного контроля",
        ],
        correctAnswers: ["Технический руководитель организации"],
      },
      {
        code: "20106123",
        text: "В каком месте может размещаться оборудование, работающее на аммиаке?",
        answers: [
          "Только на открытой площадке",
          "Только в специальном помещении машинного или аппаратного отделения",
          "Только в помещении потребителей холода",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20106124",
        text: "Какой должна быть ширина центрального прохода для обслуживания оборудования у вновь строящихся и реконструируемых аммиачных систем холодоснабжения?",
        answers: [
          "Не более 0,8 м",
          "Не более 1,2 м",
          "Не менее 1,5 м",
          "Не менее 1,0 м",
        ],
        correctAnswers: ["Не менее 1,5 м"],
      },
      {
        code: "20106125",
        text: "Для постоянного обслуживания какого оборудования (арматуры) должна быть устроена металлическая площадка с ограждением и лестницей?",
        answers: [
          "Расположенного на уровне не ниже 2 м от пола",
          "Расположенного на уровне выше 1,8 м от пола",
          "Расположенного на уровне выше 1,5 м от пола",
          "Расположенного на уровне, определенном технологической схемой",
        ],
        correctAnswers: ["Расположенного на уровне выше 1,8 м от пола"],
      },
      {
        code: "20106126",
        text: "По указанию какого лица должны производиться снятие предохранительных клапанов на проверку, их установка и пломбирование?",
        answers: [
          "Представителя проектной организации",
          "Лица, ответственного за исправное состояние и безопасную работу сосудов (аппаратов)",
          "Лица, ответственного за проведение ремонтных работ",
          "Начальника технического подразделения",
        ],
        correctAnswers: [
          "Лица, ответственного за исправное состояние и безопасную работу сосудов (аппаратов)",
        ],
      },
      {
        code: "20106127",
        text: "Какой документ необходимо оформлять при выполнении монтажных работ в помещении и на участках действующей холодильной системы, а также в условиях недействующих узлов, находящихся под аммиаком или не отсоединенных от остальной части системы?",
        answers: [
          "Технологическую карту оборудования",
          "График проведения работ",
          "Наряд-допуск на указанные работы",
          "План работ, утвержденный руководителем технического подразделения",
        ],
        correctAnswers: ["Наряд-допуск на указанные работы"],
      },
      {
        code: "20106128",
        text: "Какое допускается максимальное заполнение геометрического объема дренажного ресивера для аварийного (ремонтного) освобождения от жидкого аммиака охлаждающих устройств, аппаратов, сосудов и блоков?",
        answers: ["На 50 %", "На 80 %", "На 90 %", "На 70 %"],
        correctAnswers: ["На 80 %"],
      },
      {
        code: "20106129",
        text: "В каком случае допускается предусматривать линейный ресивер для холодильных машин с дозированной зарядкой аммиака?",
        answers: [
          "Если линейный ресивер предусматривается в качестве защитного ресивера",
          "Если это предусматривается в проекте производства работ",
          "Если линейный ресивер используется в качестве дренажного или циркуляционного",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20106130",
        text: "Какое из перечисленных требований к поддонам (приямкам) для сбора жидкого аммиака в случае разгерметизации сосуда указано верно? Укажите все правильные ответы.",
        answers: [
          "Количество пролитого аммиака из защитного ресивера должно определяться по рабочему заполнению сосуда",
          "Глубина приямка для сбора жидкого аммиака должна быть не более 2 м",
          "Количество пролитого аммиака из циркуляционного ресивера должно определяться по максимально допустимому заполнению сосуда",
          "Приямки должны иметь не менее двух лестниц, а при глубине приямка более 2 м - выход непосредственно наружу",
          "Расчетный уровень жидкого аммиака в случае аварийного вытекания хладагента из наиболее аммиакоемкого сосуда в поддон (приямок) должен быть ниже бортика поддона (края приямка)",
        ],
        correctAnswers: [
          "Приямки должны иметь не менее двух лестниц, а при глубине приямка более 2 м - выход непосредственно наружу",
          "Расчетный уровень жидкого аммиака в случае аварийного вытекания хладагента из наиболее аммиакоемкого сосуда в поддон (приямок) должен быть ниже бортика поддона (края приямка)",
        ],
      },
      {
        code: "20106131",
        text: "Какое из перечисленных требований к расположению цистерн с жидким аммиаком в организации указано верно?",
        answers: [
          'Во время слива цистерна должна ограждаться, должен быть выставлен знак с надписью "Внимание. Слив аммиака"',
          "Колесо цистерны на рельсовом пути должно быть подклинено с одной стороны тормозным башмаком",
          "Перед сливом аммиака цистерна должна быть заземлена и подключена к блокировке сдвига цистерны",
          "На внутренних железнодорожных путях организации, не имеющих стрелочных переводов, должен устанавливаться затворный предохранительный брус на расстоянии не менее 5 м от цистерны",
        ],
        correctAnswers: [
          "Перед сливом аммиака цистерна должна быть заземлена и подключена к блокировке сдвига цистерны",
        ],
      },
      {
        code: "20106132",
        text: "В течение какого времени нахождения цистерн с жидким аммиаком на территории организации должно быть организовано наблюдение за ними?",
        answers: [
          "В течение 2 часов, завершающих рабочую смену",
          "В течение всего времени нахождения",
          "Непосредственно перед сливом аммиака",
          "В течение 1 часа после приемки цистерн",
        ],
        correctAnswers: ["В течение всего времени нахождения"],
      },
      {
        code: "20106133",
        text: "Какие действия должны быть предприняты при обнаружении нарушений требований Правил безопасности химически опасных производственных объектов в отношении цистерн с жидким аммиаком?",
        answers: [
          "Должен быть уведомлен о нарушениях территориальный орган Ростехнадзора",
          "Незамедлительно должен быть слит аммиак из цистерн",
          "Должен быть составлен акт и необходимо сообщить об этом организации-наполнителю",
          "Должно быть организовано круглосуточное наблюдение за цистернами до устранения нарушений",
        ],
        correctAnswers: [
          "Должен быть составлен акт и необходимо сообщить об этом организации-наполнителю",
        ],
      },
      {
        code: "20106134",
        text: "С какой периодичностью необходимо проверять исправность автоматических приборов защиты аммиачных компрессоров и сигнализаторов концентрации паров аммиака в воздухе помещений и наружных площадок?",
        answers: [
          "Не реже 1 раза в 10 дней",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в неделю",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20106135",
        text: "Какой вид временных соединений может применяться для стыковки железнодорожной цистерны к стационарным узлам холодильной установки?",
        answers: [
          "Только гибкие рукава из металла или неметаллических материалов",
          "Только гибкая стыковка при помощи консольного участка стальной трубы длиной 5 - 7 м, изогнутой в виде колена или змеевика",
          "Только шарнирные поворотные соединения",
          "Все перечисленные виды соединений",
        ],
        correctAnswers: ["Все перечисленные виды соединений"],
      },
      {
        code: "20106136",
        text: "На каких из перечисленных участках аммиачных трубопроводов должны быть нанесены три опознавательных кольца?",
        answers: [
          "На паровых, парожидкостных линиях стороны низкого давления систем холодоснабжения",
          "На паровых линиях стороны высокого давления",
          "На жидкостных линиях стороны низкого давления систем холодоснабжения",
          "На жидкостных линиях стороны высокого давления",
        ],
        correctAnswers: ["На жидкостных линиях стороны высокого давления"],
      },
      {
        code: "20106137",
        text: "В какой цвет должны быть окрашены участки аммиачных трубопроводов, на которые наносятся опознавательные кольца?",
        answers: [
          "В белый цвет",
          "В черный цвет",
          "В желтый цвет",
          "В красный цвет",
        ],
        correctAnswers: ["В желтый цвет"],
      },
      {
        code: "20106138",
        text: "Какого цвета должен быть сигнал об опасном повышении верхнего уровня жидкого аммиака в сосудах и аппаратах (предупредительная сигнализация)?",
        answers: ["Белого", "Желтого", "Красного", "Оранжевого"],
        correctAnswers: ["Желтого"],
      },
      {
        code: "20106139",
        text: "На какую массовую нагрузку должны быть рассчитаны специальные опоры или подвески, на которые монтируются трубопроводы аммиачных холодильных установок?",
        answers: [
          "Только на собственную массу трубопровода и массу хладагента",
          "Только на массу тепловой изоляции и массу хладагента",
          "На собственную массу трубопровода, массу хладагента и тепловой изоляции, принятых с коэффициентом запаса 1,2",
          "На массу тепловой изоляции трубопровода, принятую с коэффициентом запаса 1,5",
        ],
        correctAnswers: [
          "На собственную массу трубопровода, массу хладагента и тепловой изоляции, принятых с коэффициентом запаса 1,2",
        ],
      },
      {
        code: "20106140",
        text: "Какие сроки освидетельствования систем холодоснабжения с ограниченной зарядкой аммиаком (не более 50 кг), поставляемых комплектно организациями-изготовителями?",
        answers: [
          "Устанавливаются руководящим лицом, ответственным за исправное состояние и безопасную эксплуатацию сосудов и трубопроводов",
          "Устанавливаются эксплуатационной документацией организаций-изготовителей",
          "Устанавливаются Правилами безопасности химически опасных производственных объектов",
          "Устанавливаются технологической документацией, но не реже 1 раза в год",
        ],
        correctAnswers: [
          "Устанавливаются эксплуатационной документацией организаций-изготовителей",
        ],
      },
      {
        code: "20106141",
        text: "Каким должен быть процент первичного заполнения жидким аммиаком внутреннего объема воздухоохладителей с верхней подачей аммиака?",
        answers: ["50 %", "30 %", "15 %", "80 %"],
        correctAnswers: ["50 %"],
      },
      {
        code: "20106142",
        text: "Какие номинальные величины загазованности аммиаком должны контролироваться в помещениях машинных и аппаратных отделений аммиачных холодильных установок?",
        answers: [
          "10, 125 и 500 мг/м³",
          "5, 300 и 1500 мг/м³",
          "20, 60 и 500 мг/м³",
          "15, 70 и 1000 мг/м³",
        ],
        correctAnswers: ["20, 60 и 500 мг/м³"],
      },
      {
        code: "20106143",
        text: "Какую скорость паров аммиака допускается принимать в сечении паровой зоны вертикального сосуда или аппарата, исполняющего функции отделителя жидкости?",
        answers: [
          "Не более 0,5 м/с",
          "Не более 1,5 м/с",
          "Не более 1 м/с",
          "Правилами не регламентируется",
        ],
        correctAnswers: ["Не более 0,5 м/с"],
      },
      {
        code: "20106144",
        text: "Какую скорость паров аммиака в сечении паровой зоны должен обеспечивать размер паровой зоны вертикального сосуда или аппарата, исполняющего функции отделения жидкости?",
        answers: [
          "Не более 0,5 м/с",
          "Не более 1,0 м/с",
          "Правилами не регламентируется",
          "Не более 1,5 м/с",
        ],
        correctAnswers: ["Не более 0,5 м/с"],
      },
      {
        code: "20106145",
        text: "Для какого оборудования процент первичного заполнения жидким аммиаком не превышает 30 %?",
        answers: [
          "Для змеевиковых и листотрубных (панельных) испарителей",
          "Для воздухоохладителей с верхней подачей аммиака",
          "Для батарей холодильных камер с верхней подачей аммиака",
          "Для линейных ресиверов",
        ],
        correctAnswers: [
          "Для батарей холодильных камер с верхней подачей аммиака",
        ],
      },
      {
        code: "20106146",
        text: "Каким должно быть минимальное остаточное избыточное давление в транспортировочных емкостях аммиака при их полном опорожнении?",
        answers: ["0,02 МПа", "0,05 МПа", "0,03 МПа", "0,01 МПа"],
        correctAnswers: ["0,05 МПа"],
      },
      {
        code: "20106147",
        text: "Какое действие необходимо предпринять в случае перерыва слива аммиака?",
        answers: [
          "Цистерна на время перерыва остается присоединенной к системе под наблюдением работника, ответственного за производство работ по сливу аммиака",
          "Съемные участки трубопроводов должны быть отсоединены от цистерн",
          "Во время перерыва слива аммиака разрешается оставлять цистерну присоединенной к системе",
          "Решение об отсоединении цистерны на время перерыва по сливу должен принимать ответственный производитель работ",
        ],
        correctAnswers: [
          "Съемные участки трубопроводов должны быть отсоединены от цистерн",
        ],
      },
      {
        code: "20106148",
        text: "Какие устройства применяются в качестве предохранительных на аммиачных холодильных установках?",
        answers: [
          "Предохранительные клапаны прямого действия",
          "Предохранительные клапаны с мембранным чувствительным элементом",
          "Пружинные предохранительные клапаны и мембранные предохранительные устройства",
          "Все перечисленные устройства",
        ],
        correctAnswers: [
          "Пружинные предохранительные клапаны и мембранные предохранительные устройства",
        ],
      },
      {
        code: "20106149",
        text: "Что учитывается при определении пропускной способности предохранительных устройств для защиты от разрушений сосудов, аппаратов и технологического оборудования, содержащих жидкий аммиак?",
        answers: [
          "Площадь наружной поверхности аппарата и удельная теплота парообразования аммиака при давлении насыщения в 3 раза больше расчетного давления защищаемого аппарата",
          "Скорость теплового потока испарившегося аммиака в случае пожара",
          "Плотность теплового потока через наружные стенки сосуда или аппарата, площадь наружной поверхности аппарата или сосуда и удельная теплота парообразования аммиака при давлении насыщения в 1,15 раза больше расчетного давления защищаемого сосуда (аппарата)",
          "Общий объем аммиака в аппарате",
        ],
        correctAnswers: [
          "Плотность теплового потока через наружные стенки сосуда или аппарата, площадь наружной поверхности аппарата или сосуда и удельная теплота парообразования аммиака при давлении насыщения в 1,15 раза больше расчетного давления защищаемого сосуда (аппарата)",
        ],
      },
      {
        code: "20106150",
        text: "Как рассчитывается геометрический объем защитных ресиверов (Vз.р.) вертикального типа, совмещающих функцию отделителя жидкости аммиачных холодильных установок, для каждой температуры кипения аммиака?",
        answers: [
          "Vз.р. больше Vс х 0,9 м³",
          "Vз.р. больше Vс х 0,2 м³",
          "Vз.р. больше Vс х 0,5 м³",
          "Vз.р. больше Vс х 1,5 м³",
        ],
        correctAnswers: ["Vз.р. больше Vс х 0,5 м³"],
      },
      {
        code: "20106151",
        text: "Как рассчитывается геометрический объем защитных ресиверов (Vз.р.) вертикального типа, совмещающих функцию отделителя жидкости аммиачных холодильных установок, для каждой температуры кипения аммиака?",
        answers: [
          "Vз.р. больше Vс х 0,5 м³",
          "Vз.р. больше Vс х 0,2 м³",
          "Vз.р. больше Vс х 1,5 м³",
          "Vз.р. больше Vс х 0,9 м³",
        ],
        correctAnswers: ["Vз.р. больше Vс х 0,5 м³"],
      },
      {
        code: "20106152",
        text: "В каком положении должны быть опломбированы запорные клапаны на аммиачных газовых нагнетательных трубопроводах?",
        answers: ["В открытом", "В закрытом", "Положение не регламентируется"],
        correctAnswers: ["В открытом"],
      },
      {
        code: "20106153",
        text: "С какой периодичностью необходимо проверять отходящую из конденсатора воду на присутствие аммиака?",
        answers: [
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в сутки",
          "Не реже одного раза в месяц",
          "Не реже одного раза в неделю",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20106154",
        text: "Какое условие при механической очистке труб конденсатора от водяного камня является неверным?",
        answers: [
          "Работа должна выполняться с оформлением наряда-допуска",
          "Необходимо ежемесячно проверять отходящую из конденсатора воду на присутствие аммиака",
          "Работа должна выполняться под руководством технического руководителя организации",
          "Работа должна выполняться только после освобождения конденсатора от аммиака",
        ],
        correctAnswers: [
          "Работа должна выполняться под руководством технического руководителя организации",
        ],
      },
      {
        code: "20106155",
        text: "С какой периодичностью необходимо проверять промежуточный хладоноситель в системах охлаждения на присутствие аммиака?",
        answers: [
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в неделю",
          "Не реже одного раза в год",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20106156",
        text: "В каком случае не допускается применение гибких резиновых или пластмассовых шлангов?",
        answers: [
          "Если проводятся операции слива аммиака (при заполнении системы) из цистерны",
          "В качестве стационарных трубопроводов для отсоса паров или подачи жидкого аммиака",
          "Если выполняются вспомогательные операции (освобождение трубопроводов, аппаратов, фильтров от остатков аммиака, масла) на период выполнения работ",
        ],
        correctAnswers: [
          "В качестве стационарных трубопроводов для отсоса паров или подачи жидкого аммиака",
        ],
      },
      {
        code: "20106157",
        text: "В каком случае допускается запускать аммиачный насос?",
        answers: [
          "Только в случае отсутствия защитного кожуха муфты",
          "Только в случае закрытия клапанов на его входе и выходе",
          "Только в случае неполного заполнения насоса жидким хладагентом",
          "Во всех перечисленных случаях",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20106158",
        text: "В каком случае насос должен быть немедленно остановлен?",
        answers: [
          "Только если упало давление напора или разность давлений напора и всасывания (при отсутствии или отказе приборов автоматики)",
          "Только если появились утечки аммиака через неплотности агрегата",
          "Только если обнаружены неисправности манометров, обратных клапанов, средств контрольно-измерительных приборов и автоматики",
          "В любом из перечисленных случаев",
        ],
        correctAnswers: ["В любом из перечисленных случаев"],
      },
      {
        code: "20106159",
        text: "В каком случае перегрев паров аммиака, всасываемых компрессором, должен быть не менее 10 К (°C)?",
        answers: [
          "Для ступени низкого давления двухступенчатых компрессоров",
          "Для ступени высокого давления двухступенчатых компрессоров",
          "Для одноступенчатых компрессоров",
        ],
        correctAnswers: [
          "Для ступени низкого давления двухступенчатых компрессоров",
        ],
      },
      {
        code: "20106160",
        text: "В каком случае аппарат (сосуд) подлежит немедленной остановке?",
        answers: [
          "Только в случае неисправности указателя уровня жидкости",
          "Только в случае неисправности предусмотренных проектом контрольно-измерительных приборов и средств автоматики",
          "Только в случае неисправности предохранительных клапанов",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
    ],

    20107: [
      {
        code: "20107000",
        text: "На какие опасные производственные объекты не распространяются Правила промышленной безопасности складов нефти и нефтепродуктов?",
        answers: [
          "На объекты хранения нефтепродуктов с упругостью паров выше 93,3 кПа",
          "На площадки нефтебаз по хранению и перевалке нефти и нефтепродуктов",
          "На наливные станции магистральных нефтепроводов и нефтепродуктопроводов",
          "На склады горюче-смазочных материалов, входящие в состав промышленных предприятий и организаций",
        ],
        correctAnswers: [
          "На объекты хранения нефтепродуктов с упругостью паров выше 93,3 кПа",
        ],
      },
      {
        code: "20107001",
        text: "В каких документах должны быть обоснованы технические решения по герметизации налива нефтепродуктов в железнодорожные цистерны?",
        answers: [
          "В проектной документации",
          "В технологической карте по наливу нефти и нефтепродуктов",
          "В исполнительной документации",
          "В нормативных правовых актах по промышленной безопасности",
        ],
        correctAnswers: ["В проектной документации"],
      },
      {
        code: "20107002",
        text: "Какие нефтепродукты допускается сливать через герметичные верхние сливные устройства?",
        answers: [
          "Высоковязкие нефтепродукты",
          "Авиационный керосин",
          "Светлые нефтепродукты",
          "Нефтепродукты с подтоварной водой",
        ],
        correctAnswers: ["Светлые нефтепродукты"],
      },
      {
        code: "20107003",
        text: "Какой длины должно быть наливное устройство во избежание налива нефти и нефтепродуктов свободно падающей струей?",
        answers: [
          "Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 200 мм",
          "Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 600 мм",
          "Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 400 мм",
        ],
        correctAnswers: [
          "Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 200 мм",
        ],
      },
      {
        code: "20107004",
        text: "При каком уровне загазованности воздушной среды должен автоматически прекращаться слив и налив нефти и светлых нефтепродуктов на сливоналивных железнодорожных эстакадах?",
        answers: [
          "20 % объемных от нижнего концентрационного предела распространения пламени",
          "30 % объемных от нижнего концентрационного предела распространения пламени",
          "50 % объемных от нижнего концентрационного предела распространения пламени",
        ],
        correctAnswers: [
          "50 % объемных от нижнего концентрационного предела распространения пламени",
        ],
      },
      {
        code: "20107005",
        text: "При каких условиях допускается налив нефтепродуктов в автомобильные цистерны с применением гибких шлангов?",
        answers: [
          "При разработке обоснования безопасности и реализации компенсирующих мероприятий",
          "При обосновании в проектной документации ",
          "При величине допустимого риска меньше чем 10-6",
        ],
        correctAnswers: ["При обосновании в проектной документации "],
      },
      {
        code: "20107006",
        text: "При каком минимальном превышении концентрации паров нефтепродуктов на площадках сливоналивных станций и пунктов слива-налива должны быть установлены блокировки по прекращению операций слива-налива и сигнализация, оповещающая о запрете запуска двигателей автомобилей?",
        answers: [
          "При превышении концентрации паров более 20 %",
          "При превышении концентрации паров более 30 %",
          "При превышении концентрации паров более 50 %",
        ],
        correctAnswers: ["При превышении концентрации паров более 20 %"],
      },
      {
        code: "20107007",
        text: "В соответствии с какими нормами и требованиями должны быть обустроены сливоналивные причалы для осуществления операций с нефтью и нефтепродуктами?",
        answers: [
          "В соответствии с нормами технологического проектирования морских и речных портов и требованиями законодательства в области промышленной безопасности",
          "В соответствии с требованиями природоохранного законодательства",
          "В соответствии с нормами морского регистра и требованиями законодательства по перевозке опасных грузов",
        ],
        correctAnswers: [
          "В соответствии с нормами технологического проектирования морских и речных портов и требованиями законодательства в области промышленной безопасности",
        ],
      },
      {
        code: "20107008",
        text: "Что не должно учитываться при выборе шлангов для обеспечения безопасности грузовых (сливоналивных) операций?",
        answers: [
          "Требуемая скорость перемещения нефтепродукта",
          "Физико-химические свойства перемещаемой среды",
          "Параметры давления и температуры перемещаемой среды",
          "Размер судового трубопровода",
        ],
        correctAnswers: ["Требуемая скорость перемещения нефтепродукта"],
      },
      {
        code: "20107009",
        text: "Какой должна быть максимальная температура производственных сточных вод при сбросе в канализацию опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: ["34 °C", "40 °C", "60 °C"],
        correctAnswers: ["40 °C"],
      },
      {
        code: "20107010",
        text: "На каком расстоянии от нефтеловушек необходимо устраивать на канализационной сети колодцы с гидравлическим затвором?",
        answers: [
          "Не менее 10 м до и после нефтеловушек",
          "На максимально близком расстоянии от нефтеловушек",
          "Не менее 20 м после нефтеловушек",
        ],
        correctAnswers: ["Не менее 10 м до и после нефтеловушек"],
      },
      {
        code: "20107011",
        text: "Какими документами определяются объем, периодичность и порядок организации и проведения работ по техническому обслуживанию и ремонту оборудования, резервуаров и технологических трубопроводов, систем инженерно-технического обеспечения с учетом конкретных условий эксплуатации опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: [
          "Нормативно-техническими документами, разработанными эксплуатирующей организацией",
          "Декларацией промышленной безопасности",
          "Нормативно-техническими документами, разработанными специализированной организацией",
        ],
        correctAnswers: [
          "Нормативно-техническими документами, разработанными эксплуатирующей организацией",
        ],
      },
      {
        code: "20107012",
        text: "Какова периодичность зачистки металлических резервуаров для хранения нефтепродуктов?",
        answers: [
          "Не менее 2 раз в год - для авиационного топлива и не менее 1 раза в 2 года - для остальных светлых нефтепродуктов и масел",
          "Не менее 1 раза в год - для авиационного топлива и не менее 2 раз в год - для остальных светлых нефтепродуктов и масел",
          "Не менее 2 раз в год для любых нефтепродуктов",
        ],
        correctAnswers: [
          "Не менее 2 раз в год - для авиационного топлива и не менее 1 раза в 2 года - для остальных светлых нефтепродуктов и масел",
        ],
      },
      {
        code: "20107013",
        text: "Какое из нижеуказанных свойств, показателей или условий не влияет на выбор типа резервуара для хранения нефти и нефтепродуктов?",
        answers: [
          "Физико-химические свойства хранимых нефтепродуктов",
          "Годовое число циклов заполнений-опорожнений резервуара",
          "Показатель взрывоопасности хранимых нефтепродуктов",
        ],
        correctAnswers: [
          "Годовое число циклов заполнений-опорожнений резервуара",
        ],
      },
      {
        code: "20107014",
        text: "Какие требования предъявляются к температуре подогрева мазута в резервуарах?",
        answers: [
          "Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 120 °C",
          "Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 20 °C и не должна превышать 80 °C",
          "Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 90 °C",
        ],
        correctAnswers: [
          "Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 90 °C",
        ],
      },
      {
        code: "20107015",
        text: "До какого момента при заполнении порожнего резервуара должны подаваться нефть или нефтепродукты со скоростью не более 1 м/с?",
        answers: [
          "До момента заполнения приемного патрубка или до всплытия понтона (плавающей крыши)",
          "До момента получения сигнала о наполнении резервуара",
          "До момента заполнения резервуара до верхней проектной отметки",
        ],
        correctAnswers: [
          "До момента заполнения приемного патрубка или до всплытия понтона (плавающей крыши)",
        ],
      },
      {
        code: "20107016",
        text: "За счет чего должны компенсироваться температурные деформации трубопроводов для транспортировки мазута?",
        answers: [
          "За счет установки на технологических трубопроводах для транспортировки мазута сальниковых компенсаторов",
          "За счет установки на технологических трубопроводах для транспортировки мазута линзовых компенсаторов",
          "За счет поворотов и изгибов трассы трубопроводов (самокомпенсация) или установки специальных компенсирующих устройств (П-образных компенсаторов)",
          "За счет установки на технологических трубопроводах теплоизолирующих кожухов",
        ],
        correctAnswers: [
          "За счет поворотов и изгибов трассы трубопроводов (самокомпенсация) или установки специальных компенсирующих устройств (П-образных компенсаторов)",
        ],
      },
      {
        code: "20107017",
        text: "В каком случае допускается применение на складах нефти и нефтепродуктов поршневых насосов для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей при малых объемных скоростях подачи, в том числе в системах дозирования?",
        answers: [
          "Ни в каком случае",
          "Допускается в случае установки газоанализатора для постоянного контроля за концентраций взрывоопасных веществ в воздухе",
          "Допускается при согласовании принятого технического решения территориальным органом Ростехнадзора",
          "Допускается при обосновании принятого технического решения в проектной документации",
        ],
        correctAnswers: [
          "Допускается при обосновании принятого технического решения в проектной документации",
        ],
      },
      {
        code: "20107018",
        text: "Кем утверждается перечень уставок срабатывания блокировок и сигнализации для осуществления технологических операций, предусмотренных проектной документацией?",
        answers: [
          "Организацией, эксплуатирующей опасные производственные объекты складов нефти и нефтепродуктов ",
          "Специализированной организацией, осуществляющей техническое обслуживание оборудования",
          "Проектной организацией",
          "Разработчиком технологического процесса по согласованию с разработчиком проектной документации",
        ],
        correctAnswers: [
          "Организацией, эксплуатирующей опасные производственные объекты складов нефти и нефтепродуктов ",
        ],
      },
      {
        code: "20107019",
        text: "В каком случае допускается на опасных производственных объектах складов нефти и нефтепродуктов прокладка кабельных трасс и технологических трубопроводов на общих строительных конструкциях?",
        answers: [
          "Допускается при обосновании принятых технических решений в проектной документации (документации на техническое перевооружение)",
          "Допускается в случае прокладки кабельных трасс и технологических трубопроводов в огнезащитном кожухе",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "Допускается при обосновании принятых технических решений в проектной документации (документации на техническое перевооружение)",
        ],
      },
      {
        code: "20107020",
        text: "Что из перечисленного не требует защиты от прямых ударов молнии?",
        answers: [
          "Дыхательная арматура резервуаров с легковоспламеняющимися жидкостями и пространство над ней",
          "Насосные установки (станции) нефти и нефтепродуктов и пространство над ними",
          "Пространство над срезом горловины цистерн с легковоспламеняющимися жидкостями при открытом наливе продукта на наливной эстакаде, ограниченное цилиндром высотой 2,5 м и радиусом 5 м",
        ],
        correctAnswers: [
          "Насосные установки (станции) нефти и нефтепродуктов и пространство над ними",
        ],
      },
      {
        code: "20107021",
        text: "В каком случае разрешается выполнение болтовых соединений молниеприемников с токоотводами и токоотводов с заземлителями с переходным сопротивлением не более 0,05 Ом?",
        answers: [
          "Разрешается при недопустимости проведения огневых работ и при условии обязательного ежегодного контроля сопротивления перед началом грозового периода",
          "Разрешается при обосновании такого решения в проектной документации",
          "Разрешается при обеспечении постоянного контроля за концентрацией взрывоопасных веществ в воздухе",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "Разрешается при недопустимости проведения огневых работ и при условии обязательного ежегодного контроля сопротивления перед началом грозового периода",
        ],
      },
      {
        code: "20107022",
        text: "В каком случае допускается установка оборудования приточных систем вентиляции обычного исполнения в помещениях взрывоопасных категорий опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: [
          "Допускается, если установка производится на объектах III и IV класса опасности",
          "Допускается, если на воздуховодах при выходе из венткамеры предусмотрены взрывозащитные обратные клапаны",
          "Ни в каком случае",
          "Допускается, если осуществляется постоянный контроль уровня углеводородов в воздухе помещения",
        ],
        correctAnswers: [
          "Допускается, если на воздуховодах при выходе из венткамеры предусмотрены взрывозащитные обратные клапаны",
        ],
      },
      {
        code: "20107023",
        text: "На какую минимальную глубину от уровня верхней кромки подогревателя должны погружаться в нефтепродукт переносные паровые змеевики и переносные электрические подогреватели?",
        answers: ["500 мм", "200 мм", "700 мм", "400 мм"],
        correctAnswers: ["500 мм"],
      },
      {
        code: "20107024",
        text: "Каким способом срабатывает система аварийного разъединения стендеров для предотвращения пролива нефтепродуктов?",
        answers: [
          "Только вручную посредством управления гидравлическими клапанами в случае прекращения подачи электроэнергии на терминал",
          "Всеми перечисленными способами",
          "Только дистанционно с учетом нажатия кнопки на центральном пульте управления",
          "Только автоматически, когда стендер достигает обусловленного граничного положения",
        ],
        correctAnswers: ["Всеми перечисленными способами"],
      },
      {
        code: "20107025",
        text: "Кто утверждает график проведения периодической проверки стендеров?",
        answers: [
          "Специализированная организация, проводившая экспертизу промышленной безопасности",
          "Проектная организация",
          "Территориальные органы Ростехнадзора",
          "Эксплуатирующая организация",
        ],
        correctAnswers: ["Эксплуатирующая организация"],
      },
      {
        code: "20107026",
        text: "Где допускается осуществлять затаривание и расфасовку нефтепродуктов (масел, смазок) в бочки и мелкую тару?",
        answers: [
          "Только на площадках, имеющих канализацию для отвода сточных вод в локальные очистные сооружения",
          "В пунктах разлива и фасовки нефтепродуктов",
          "Только в помещениях для хранения нефтепродуктов",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["В пунктах разлива и фасовки нефтепродуктов"],
      },
      {
        code: "20107027",
        text: "Какой документацией определяется электроснабжение электроприемников по категории надежности опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: [
          "Технологическим регламентом",
          "Генеральным планом опасного производственного объекта",
          "Исполнительной документацией",
          "Проектной документацией (документацией на техническое перевооружение)",
        ],
        correctAnswers: [
          "Проектной документацией (документацией на техническое перевооружение)",
        ],
      },
      {
        code: "20107028",
        text: "В каком случае не допускается применение электроподогрева при проведении сливоналивных операций нефтепродуктов?",
        answers: [
          "При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 61 °C",
          "При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 81 °C",
          "При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 71 °C",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 61 °C",
        ],
      },
      {
        code: "20107029",
        text: "Для налива каких ЛВЖ сливоналивные устройства должны снабжаться устройствами отвода паров?",
        answers: [
          "С упругостью паров от 56,65 кПа",
          "С упругостью паров от 55,66 кПа",
          "С упругостью паров от 66,65 кПа",
          "С упругостью паров от 61,65 кПа",
        ],
        correctAnswers: ["С упругостью паров от 66,65 кПа"],
      },
      {
        code: "20107030",
        text: "В каком случае автоматические предохранительные клапаны должны быть установлены на причале, чтобы исключить возможное повышение давления потоком нефти и нефтепродукта?",
        answers: [
          "При расположении береговых насосов более чем в 100 м от причала",
          "При расположении береговых насосов более чем в 30 м от причала",
          "В случае обоснования выбранного решения в проектной документации",
          "Во всех случаях",
        ],
        correctAnswers: [
          "При расположении береговых насосов более чем в 100 м от причала",
        ],
      },
      {
        code: "20107031",
        text: "Какие насосные станции производственной канализации должны оснащаться датчиками загазованности с выводом сигнала на пульт помещения управления?",
        answers: [
          "Заглубленные не менее чем на 1,5 м",
          "Находящиеся на расстоянии менее 20 м от рабочих мест",
          "Заглубленные более чем на 0,5 м",
          "Все насосные станции",
        ],
        correctAnswers: ["Заглубленные более чем на 0,5 м"],
      },
      {
        code: "20107032",
        text: "В соответствии с требованиями какой документации проводятся все технологические операции по приему, хранению и разливу нефтепродуктов в тару? Выберите два правильных варианта ответа.",
        answers: [
          "Правил промышленной безопасности складов нефти и нефтепродуктов",
          "Производственных инструкций, утвержденных эксплуатирующей организацией",
          "Проектной документации",
          "Технических регламентов",
        ],
        correctAnswers: [
          "Правил промышленной безопасности складов нефти и нефтепродуктов",
          "Производственных инструкций, утвержденных эксплуатирующей организацией",
        ],
      },
      {
        code: "20107033",
        text: "В каком из перечисленных случаев должны срабатывать быстродействующие отключающие системы (автоматические устройства) на сливоналивных эстакадах?",
        answers: [
          "Только в случае достижения предельного уровня заполнения железнодорожной цистерны",
          "Только в случае нарушения целостности цепи заземления железнодорожной цистерны",
          "Только если выдана заданная норма",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20107034",
        text: "Какое из перечисленных требований допускается к автомобильным сливоналивным станциям?",
        answers: [
          "Перегон автомобильных цистерн на другое место при возникновении пролива нефтепродукта на сливоналивной станции",
          "Отсутствие заземления у автоцистерн объемом менее 20 м³ при их нахождении на автомобильных сливоналивных станциях",
          "Применение гибких шлангов для налива при обосновании в проектной документации",
          "Применение водителями одежды из синтетических и шерстяных материалов",
        ],
        correctAnswers: [
          "Применение гибких шлангов для налива при обосновании в проектной документации",
        ],
      },
      {
        code: "20107035",
        text: "В какой документации устанавливаются места установки приборов, их количество и параметры контроля процесса перекачки нефти и нефтепродукта по трубопроводу у насосной станции и стендеров?",
        answers: [
          "В технологической документации",
          "В проектной документации",
          "В технической документации",
          "В исполнительной документации",
        ],
        correctAnswers: ["В проектной документации"],
      },
      {
        code: "20107036",
        text: "Какое из перечисленных требований к резервуарам для хранения нефти и нефтепродуктов указано верно?",
        answers: [
          "При оснащении резервуаров газоуравнительной системой следует предусматривать единую систему отключения резервуаров от этой системы",
          "Полный комплект устанавливаемых на резервуаре устройств и оборудования и схема их расположения обосновываются в проектной документации",
          "При применении стальных резервуаров с защитной стенкой должен быть обеспечен контроль утечек продукта в межстенное пространство только по параметру загазованности",
          "Все ответы верны",
        ],
        correctAnswers: [
          "Полный комплект устанавливаемых на резервуаре устройств и оборудования и схема их расположения обосновываются в проектной документации",
        ],
      },
      {
        code: "20107037",
        text: "В каком случае допускается торможение цистерн башмаками, изготовленными из материала, дающего искрение, на участках слива-налива?",
        answers: [
          "Если цистерны заземлены",
          "Если цистерны опустошены",
          "Не допускается ни в каком случае",
          "Если цистерны имеют прокладки, устойчивые к разрушению парами нефтепродуктов",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20107038",
        text: "С какой периодичностью следует визуально проверять целостность заземления элементов сливоналивных устройств, соединенных шарнирами с сальниковыми уплотнениями, изготовленными из неметаллических материалов, с регистрацией (записью) результатов осмотра в журнале приема-передачи смены?",
        answers: [
          "Каждую смену",
          "Еженедельно",
          "Ежемесячно",
          "Дважды в месяц",
        ],
        correctAnswers: ["Каждую смену"],
      },
      {
        code: "20107039",
        text: "На каком минимальном расстоянии от резервуаров устанавливаются прожекторные мачты вне обвалования или ограждающих стен?",
        answers: ["8 м", "5 м", "10 м", "15 м", "12 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "20107040",
        text: "В соответствии с требованиями какой документации насосные агрегаты должны оснащаться системами автоматизации, обеспечивающими их безопасную эксплуатацию? Выберите два правильных варианта ответа.",
        answers: [
          "Технологических карт",
          "Проектной документации",
          "Технической документации организации-изготовителя",
          "Производственных инструкций",
        ],
        correctAnswers: [
          "Проектной документации",
          "Технической документации организации-изготовителя",
        ],
      },
      {
        code: "20107041",
        text: "С какой периодичностью заземлители, токоотводы подвергаются периодическому контролю?",
        answers: [
          "1 раз в 2 года",
          "1 раз в 5 лет",
          "1 раз в 4 года",
          "1 раз в год",
        ],
        correctAnswers: ["1 раз в 5 лет"],
      },
      {
        code: "20107042",
        text: "Какая из перечисленных систем канализации не предусматривается на площадках опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: ["Производственная", "Аварийная", "Дождевая"],
        correctAnswers: ["Аварийная"],
      },
      {
        code: "20107043",
        text: "В течение какого времени после ввода резервуара в эксплуатацию необходимо ежегодно проводить нивелирование окрайки днища в абсолютных отметках?",
        answers: [
          "В течение 4 лет",
          "В течение 3 лет",
          "В течение года",
          "В течение 6 лет",
        ],
        correctAnswers: ["В течение 4 лет"],
      },
      {
        code: "20107044",
        text: "При какой минимальной скорости ветра запрещается проведение сливоналивных операций с легковоспламеняющимися жидкостями?",
        answers: [
          "Более 20 м/с",
          "Более 10 м/с",
          "Более 8 м/с",
          "Более 15 м/с",
        ],
        correctAnswers: ["Более 15 м/с"],
      },
      {
        code: "20107045",
        text: "На какое минимальное расстояние должны не доходить защитные боковые ограждения открытых насосных станций до пола и покрытия (перекрытия) насосной станции?",
        answers: ["На 1,0 м", "На 0,3 м", "На 0,5 м", "На 0,2 м"],
        correctAnswers: ["На 0,3 м"],
      },
      {
        code: "20107046",
        text: "Каким должно быть максимальное сопротивление заземляющего устройства, предназначенного только для защиты от статического электричества?",
        answers: ["100 Ом", "120 Ом", "150 Ом", "80 Ом"],
        correctAnswers: ["100 Ом"],
      },
      {
        code: "20107047",
        text: "Какие минимальные уклоны для стока жидкости к приемным устройствам (лоткам, колодцам, приямкам) должно иметь покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков?",
        answers: ["2 %", "3 %", "5 %", "1 %"],
        correctAnswers: ["2 %"],
      },
      {
        code: "20107048",
        text: "Бортиками какой высоты должно ограждаться по периметру покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков?",
        answers: [
          "Не менее 0,2 м",
          "Не менее 0,3 м",
          "Не менее 0,4 м",
          "Не менее 0,5 м",
        ],
        correctAnswers: ["Не менее 0,2 м"],
      },
      {
        code: "20107049",
        text: "Каким должно быть покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков, а также для смыва пролитых нефтепродуктов?",
        answers: ["Бетонным", "Керамзитным", "Металлическим", "Деревянным"],
        correctAnswers: ["Бетонным"],
      },
      {
        code: "20107050",
        text: "Какой документ должен быть оформлен для проведения земляных работ на территории опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: [
          "План производства работ",
          "Акт проведения работ",
          "Распоряжение технического директора",
          "Наряд-допуск",
        ],
        correctAnswers: ["Наряд-допуск"],
      },
      {
        code: "20107051",
        text: "Каким образом не должен производиться разогрев застывающих и высоковязких нефтепродуктов в железнодорожных цистернах, сливоналивных устройствах?",
        answers: [
          "Горячей водой",
          "Нефтепродуктом, нагретым циркуляционным способом",
          "Паром",
          "Электроподогревом не выше 90 °C",
        ],
        correctAnswers: ["Горячей водой"],
      },
      {
        code: "20107052",
        text: "Что из перечисленного допускается в отношении резервуарных парков?",
        answers: [
          "При хранении нефтепродуктов в резервуарах наличие подтоварной воды выше минимального уровня, обеспечиваемого устройством для дренажа воды",
          "Ручной отбор проб светлых нефтепродуктов во время закачки или откачки продукта",
          "Ручной отбор проб светлых нефтепродуктов через люк на крыше резервуара",
          "Все перечисленное не допускается",
        ],
        correctAnswers: ["Все перечисленное не допускается"],
      },
      {
        code: "20107053",
        text: "При каком условии допускается использовать приборы, отработавшие назначенный срок службы, в системах автоматизации, связи и оповещения на опасных производственных объектах складов нефти и нефтепродуктов?",
        answers: [
          "Не допускается ни в каком случае",
          "Если приборы прошли проверку внутренней комиссии",
          "Если проведена комплексная проверка работоспособности",
          "Если это предусматривается проектной документацией",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20107054",
        text: "При достижении какой концентрации горючих газов и паров нефтепродуктов предусматривается автоматическое включение аварийной вентиляции?",
        answers: [
          "20 % объемных от НКПРП",
          "30 % объемных от НКПРП",
          "40 % объемных от НКПРП",
          "50 % объемных от НКПРП",
        ],
        correctAnswers: ["20 % объемных от НКПРП"],
      },
      {
        code: "20107055",
        text: "Из какого материала должны применяться трубопроводы для транспортировки нефти и нефтепродуктов?",
        answers: [
          "Из стали",
          "Из железобетона",
          "Из асбестоцемента",
          "Из поливинилхлорида",
        ],
        correctAnswers: ["Из стали"],
      },
      {
        code: "20107056",
        text: "Какой электрифицированный транспорт во взрывозащищенном исполнении допускается применять на территории опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: [
          "Только самоходные аккумуляторные тележки (электрокары)",
          "Только электропогрузчики",
          "Только тягачи",
          "Любой из перечисленных видов транспорта",
        ],
        correctAnswers: ["Любой из перечисленных видов транспорта"],
      },
      {
        code: "20107057",
        text: "Какое из перечисленных требований к молниезащите и защите от статического электричества указано верно?",
        answers: [
          "Заземленное металлическое оборудование, покрытое лакокрасочными материалами, считается электростатически заземленным, если сопротивление любой точки его внутренней и внешней поверхности относительно магистрали заземления не превышает 10 Ом",
          "Измерение указанного сопротивления должно проводиться при относительной влажности окружающего воздуха не выше 40%",
          "Металлическое оборудование, подлежащее заземлению, запрещается покрывать лакокрасочными материалами",
          "Площадь соприкосновения измерительного электрода с поверхностью оборудования не должна превышать 30 см²",
        ],
        correctAnswers: [
          "Заземленное металлическое оборудование, покрытое лакокрасочными материалами, считается электростатически заземленным, если сопротивление любой точки его внутренней и внешней поверхности относительно магистрали заземления не превышает 10 Ом",
        ],
      },
      {
        code: "20107058",
        text: "В каком случае допускается сброс химически загрязненных, технологических, смывных и других сточных вод без предварительной очистки?",
        answers: [
          "В случае когда в эксплуатирующей организации имеются собственные очистные сооружения и магистральная сеть, предназначенная для приема таких стоков",
          "Ни в каком случае",
          "В случае если сброс не превышает установленный Росприроднадзором норматив",
          "В случае если это установлено проектной документацией",
        ],
        correctAnswers: [
          "В случае когда в эксплуатирующей организации имеются собственные очистные сооружения и магистральная сеть, предназначенная для приема таких стоков",
        ],
      },
      {
        code: "20107059",
        text: "На каком минимальном расстоянии необходимо находиться от молниеотводов во время грозы?",
        answers: ["4 м", "3 м", "5 м", "10 м"],
        correctAnswers: ["4 м"],
      },
      {
        code: "20107060",
        text: "Какой документацией обосновывается максимальная безопасная скорость налива нефти и нефтепродуктов на железнодорожных сливоналивных эстакадах?",
        answers: [
          "Проектной документацией",
          "Паспортом на объект",
          "Технологией работ",
          "Инструкцией по наливу нефтепродуктов",
        ],
        correctAnswers: ["Проектной документацией"],
      },
      {
        code: "20107061",
        text: "Каким образом должно обеспечиваться ограничение максимальной скорости налива нефти и нефтепродуктов до безопасных пределов на железнодорожных сливоналивных эстакадах?",
        answers: [
          "Любым из перечисленных способов",
          "Посредством запорно-регулирующей арматуры на линии подачи нефти или нефтепродукта к железнодорожной эстакаде",
          "Установкой частотно-регулируемого электропривода насоса",
          "Перепуском части продукта во всасывающий трубопровод насоса",
        ],
        correctAnswers: ["Любым из перечисленных способов"],
      },
      {
        code: "20107062",
        text: "Какое значение не должна превышать скорость движения понтона (плавающей крыши) для резервуаров емкостью до 30 000 м³?",
        answers: ["6 м/ч", "8 м/ч", "2 м/ч", "4 м/ч"],
        correctAnswers: ["6 м/ч"],
      },
      {
        code: "20107063",
        text: "Какое из перечисленных требований к использованию переносных подогревателей на железнодорожных сливоналивных эстакадах указано неверно?",
        answers: [
          "При использовании переносных подогревателей допускается непосредственный непродолжительный контакт теплоносителя с нефтепродуктом",
          "Разогрев нефтепродуктов в железнодорожных цистернах переносными электрическими подогревателями должен производиться только в сочетании с циркуляционным нагревом в выносном подогревателе (теплообменнике)",
          "При использовании переносных пароподогревателей давление пара в подогревателе должно соответствовать показателям, установленным в технической документации (паспорте) пароподогревателя",
          "При использовании переносных электрических подогревателей последние должны быть оснащены блокировочными устройствами, отключающими их при снижении уровня жидкости над нагревательным устройством ниже 500 мм",
        ],
        correctAnswers: [
          "При использовании переносных подогревателей допускается непосредственный непродолжительный контакт теплоносителя с нефтепродуктом",
        ],
      },
      {
        code: "20107064",
        text: "Каким давлением испытываются грузовые шланги, находящиеся в эксплуатации, на сливоналивных причалах для выявления утечки содержимого шланга или смещения его концевых соединительных устройств?",
        answers: [
          "Давлением, значение которого составляет 1,5 номинального рабочего давления",
          "Давлением, значение которого составляет 2,5 номинального рабочего давления",
          "Давлением, значение которого составляет 2,0 номинального рабочего давления",
          "Номинальным рабочим давлением",
        ],
        correctAnswers: [
          "Давлением, значение которого составляет 1,5 номинального рабочего давления",
        ],
      },
      {
        code: "20107065",
        text: "Какая устанавливается максимальная скорость движения понтона (плавающей крыши) для резервуаров емкостью свыше 30 000 м³?",
        answers: ["4 м/ч", "5 м/ч", "6 м/ч", "8 м/ч"],
        correctAnswers: ["4 м/ч"],
      },
      {
        code: "20107066",
        text: "Какая допускается скорость понтона (плавающей крыши) резервуаров при сдвиге?",
        answers: [
          "Не более 2,5 м/ч",
          "Не более 3,0 м/ч",
          "Не более 1,0 м/ч",
          "Не более 1,5 м/ч",
        ],
        correctAnswers: ["Не более 2,5 м/ч"],
      },
      {
        code: "20107067",
        text: "Какое утверждение, относящееся к резервуарным паркам для нефти и нефтепродуктов, указано неверно?",
        answers: [
          "Транзитная прокладка электрокабельных линий производится внутри обвалования резервуаров",
          "Для вновь проектируемых резервуаров управление приводами запорной арматуры должно быть дистанционным из операторной и по месту ее установки",
          "Запорные устройства, установленные непосредственно у резервуара, должны дублироваться установкой запорных устройств на технологических трубопроводах вне обвалования",
          "Применение арматуры с дистанционным управлением (электро-, пневмо- или гидроприводной) определяется условиями технологического процесса перекачки с обоснованием в проектной документации",
        ],
        correctAnswers: [
          "Транзитная прокладка электрокабельных линий производится внутри обвалования резервуаров",
        ],
      },
      {
        code: "20107068",
        text: "На каком расстоянии от сплошной (без проемов) стены помещения пунктов разлива и фасовки размещаются раздаточные резервуары с нефтепродуктами единичной вместимостью до 25 м³ включительно при общей вместимости до 200 м³ в зависимости от вида отпускаемых нефтепродуктов?",
        answers: ["2 м", "1 м", "1,5 м", "3 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "20107069",
        text: "Что используется в качестве теплоносителей на технологических трубопроводах складов нефти и нефтепродуктов?",
        answers: [
          "Все перечисленное",
          "Только пар",
          "Только электрообогрев",
          "Только промтеплофикационная вода",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20107070",
        text: "Применение каких компенсаторов на технологических трубопроводах допускается для транспортировки мазута?",
        answers: [
          "Сальниковых компенсаторов",
          "Всех перечисленных компенсаторов",
          "Линзовых компенсаторов",
          "Волнистых компенсаторов",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20107071",
        text: "Какая запорная арматура, установленная на технологических трубопроводах, должна иметь механический привод (электро-, пневмо- или гидропривод) с дистанционным управлением и ручным дублированием?",
        answers: [
          "Установленная на технологических трубопроводах с условным диаметром более 400 мм",
          "Установленная на отдаленном расстоянии от пункта управления",
          "Установленная на высоте 4 м от уровня рабочей площадки",
          "Установленная на технологических трубопроводах с условным диаметром более 200 мм",
        ],
        correctAnswers: [
          "Установленная на технологических трубопроводах с условным диаметром более 400 мм",
        ],
      },
      {
        code: "20107072",
        text: "Из какого материала следует выполнять соединение между собой неподвижных металлических конструкций (резервуары, трубопроводы), а также присоединение их к заземлителям? Выберите два правильных варианта ответа.",
        answers: [
          "Из круглой стали диаметром более 6 мм",
          "Из полосовой стали сечением не менее 48 мм²",
          "Из стальной проволоки диаметром не менее 5 мм",
          "Из стальной ленты сечением не менее 24 мм²",
        ],
        correctAnswers: [
          "Из круглой стали диаметром более 6 мм",
          "Из полосовой стали сечением не менее 48 мм²",
        ],
      },
      {
        code: "20107073",
        text: "Что из перечисленного должно отводиться в производственную канализацию на объектах складов нефти и нефтепродуктов?",
        answers: [
          "Подтоварные воды от отстоя нефти и нефтепродуктов",
          "Жидкие отходы, образовавшиеся в процессе зачистки резервуаров",
          "Нефтешлам",
          "Все перечисленное",
        ],
        correctAnswers: ["Подтоварные воды от отстоя нефти и нефтепродуктов"],
      },
      {
        code: "20107074",
        text: "При каком достижении горючих газов и паров нефтепродуктов осуществляется включение аварийной вентиляции в помещениях насосных станций нефти и нефтепродуктов?",
        answers: [
          "50 % объемных от НКПРП",
          "20 % объемных от НКПРП",
          "40 % объемных от НКПРП",
          "30 % объемных от НКПРП",
        ],
        correctAnswers: ["50 % объемных от НКПРП"],
      },
      {
        code: "20107075",
        text: "Какие переходные сопротивления должны быть в соединениях элементов трубопроводов или других протяженных металлических предметов для защиты зданий (сооружений) и электрооборудования от вторичных проявлений молнии?",
        answers: [
          "Не более 0,03 Ом на каждый контакт",
          "Не более 0,04 Ом на каждый контакт",
          "Не более 0,6 Ом на каждый контакт",
          "Не более 0,2 Ом на каждый контакт",
        ],
        correctAnswers: ["Не более 0,03 Ом на каждый контакт"],
      },
      {
        code: "20107076",
        text: "Какие из перечисленных конструкций подлежат заземлению для защиты от проявлений статического электричества?",
        answers: [
          "Все перечисленные конструкции",
          "Металлические оголовки и патрубки гибких шлангов для слива и налива нефти и нефтепродуктов",
          "Наземные трубопроводы через каждые 200 м и дополнительно на каждом ответвлении с присоединением каждого ответвления к заземлителю",
          "Железнодорожные рельсы сливоналивных участков, электрически соединенные между собой, а также металлические конструкции сливоналивных эстакад с 2 сторон по длине",
        ],
        correctAnswers: ["Все перечисленные конструкции"],
      },
      {
        code: "20107077",
        text: "В каком случае допускается сбрасывать взрывопожароопасные и пожароопасные нефтепродукты в канализацию?",
        answers: [
          "Не допускается ни в каком случае",
          "Если объем сброса не превышает установленную норму",
          "Если получено разрешение территориального органа Ростехнадзора",
          "Если происходит авария",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20107078",
        text: "Какая высота столба жидкости в гидравлическом затворе, установленном на колодце, за пределами обвалования?",
        answers: [
          "Не менее 0,25 м",
          "Не менее 1 м",
          "Не менее 0,75 м",
          "Не менее 1,25 м",
        ],
        correctAnswers: ["Не менее 0,25 м"],
      },
      {
        code: "20107079",
        text: "В каком месяце должны быть проведены все ремонты молниезащитных устройств на объектах складов нефти и нефтепродуктов?",
        answers: ["В апреле", "В мае", "В феврале", "В октябре"],
        correctAnswers: ["В апреле"],
      },
      {
        code: "20107080",
        text: "Каким образом осуществляется контроль за содержанием кислорода в горючем газе во всасывающих линиях компрессоров, работающих под разрежением? Выберите два правильных варианта ответа.",
        answers: [
          "Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения",
          "Определяются места размещения пробоотборников и способы контроля",
          "Регулярный контроль уровня кислорода осуществляется ручным газоанализатором",
          "При остановке компрессора во всасывающие линии насоса закачивается инертный газ",
        ],
        correctAnswers: [
          "Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения",
          "Определяются места размещения пробоотборников и способы контроля",
        ],
      },
      {
        code: "20107081",
        text: "Какими приборами и средствами автоматизации оснащаются сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Выберите два правильных варианта ответа.",
        answers: [
          "Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации",
          "Блокировками отключения компрессора при превышении предельно допустимого значения уровня",
          "Приборами измерения плотности жидкой фазы",
          "Приборами измерения температуры отделяемого газа",
        ],
        correctAnswers: [
          "Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации",
          "Блокировками отключения компрессора при превышении предельно допустимого значения уровня",
        ],
      },
      {
        code: "20107082",
        text: "В зависимости от чего осуществляется выбор конструкции и конструкционных материалов, уплотнительных устройств для насосов и компрессоров?",
        answers: [
          "В зависимости от свойств перемещаемой среды",
          "В зависимости от необходимых объемов для перемещения",
          "В зависимости от класса опасного объекта",
          "В зависимости от пропускной способности трубопровода",
        ],
        correctAnswers: ["В зависимости от свойств перемещаемой среды"],
      },
      {
        code: "20107083",
        text: "Кем определяются предельные значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывоопасных характеристик, физико-химических свойств транспортируемых веществ, свойств конструкционных материалов и характеристик технических устройств, применяемых для перемещения горючих продуктов?",
        answers: [
          "Разработчиком проекта",
          "Территориальным органом Ростехнадзора",
          "Эксплуатантом опасного производственного объекта",
          "Разработчиком проекта на основании рекомендаций Ростехнадзора",
        ],
        correctAnswers: ["Разработчиком проекта"],
      },
      {
        code: "20107084",
        text: "Какие требования должны быть выполнены для вновь проектируемых взрывопожароопасных и химически опасных объектов? Выберите два правильных варианта ответа.",
        answers: [
          "Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов",
          "Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия",
          "Обеспечено наличие на объекте нештатных аварийно-спасательных формирований",
          "Обеспечено наличие защитных решеток на окнах",
        ],
        correctAnswers: [
          "Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов",
          "Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия",
        ],
      },
      {
        code: "20107085",
        text: "В каких случаях должны автоматически включаться системы аварийной вентиляции? Выберите два правильных варианта ответа.",
        answers: [
          "При срабатывании сигнализаторов довзрывных концентраций вредных веществ",
          "При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
          "При поступлении сигнала от датчиков повышения концентрации монооксида углерода",
          "При поступлении сигнала от датчиков о резком повышении температуры более чем на 20 °C",
        ],
        correctAnswers: [
          "При срабатывании сигнализаторов довзрывных концентраций вредных веществ",
          "При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
        ],
      },
      {
        code: "20107086",
        text: "Какое требование к системам вентиляции указано неверно?",
        answers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
          "Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания",
          "Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства",
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: "20107087",
        text: "Какие требования необходимо выполнять при прокладке кабелей по территории технологических установок? Выберите два правильных варианта ответа.",
        answers: [
          "Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов",
          "Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение",
          "Кабели, прокладываемые на территории установок, должны иметь огнеупорный короб",
          "Запрещается прокладывать кабели в зоне установки задвижек и штуцеров для отбора проб",
        ],
        correctAnswers: [
          "Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов",
          "Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение",
        ],
      },
      {
        code: "20107088",
        text: "Где приводятся конкретные значения уставок систем защиты по опасным параметрам?",
        answers: [
          "В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции",
          "В технологической карте",
          "В инструкции завода-изготовителя",
        ],
        correctAnswers: [
          "В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20107089",
        text: "Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития? Выберите два правильных варианта ответа.",
        answers: [
          "Клапаны, отсекающие и другие отключающие устройства",
          "Запорную и запорно-регулирующую арматуру",
          "Защитные экраны",
          "Устройства автоматического контроля за параметрами технологического процесса",
        ],
        correctAnswers: [
          "Клапаны, отсекающие и другие отключающие устройства",
          "Запорную и запорно-регулирующую арматуру",
        ],
      },
      {
        code: "20107090",
        text: "Какая арматура устанавливается на трубопроводах для транспортирования взрывопожароопасных продуктов?",
        answers: [
          "Соответствующая проектной документации (документации на техническое перевооружение)",
          "Только искробезопасная арматура",
          "Только арматура с износостойкими уплотнителями",
          "Только арматура с автоматическим управлением",
        ],
        correctAnswers: [
          "Соответствующая проектной документации (документации на техническое перевооружение)",
        ],
      },
      {
        code: "20107091",
        text: "В каких местах не допускается размещать фланцевые соединения трубопроводов с пожаровзрывоопасными, токсичными и едкими веществами?",
        answers: [
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
          "На трубопроводах, идущих по стенам зданий",
          "На трубопроводах, проложенных по эстакадам",
          "Над автодорогами",
        ],
        correctAnswers: [
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
        ],
      },
      {
        code: "20107092",
        text: "Где допускается размещение фланцевых соединений на трубопроводах с пожаровзрывоопасными, токсичными и едкими веществами?",
        answers: [
          "В местах установки арматуры или подсоединения трубопроводов к аппаратам",
          "Над местами, предназначенными для прохода людей",
          "Над рабочими площадками",
          "В местах заведения трубопровода внутрь здания",
        ],
        correctAnswers: [
          "В местах установки арматуры или подсоединения трубопроводов к аппаратам",
        ],
      },
      {
        code: "20107093",
        text: "Что используется для соединения оборудования и технологических трубопроводов со стационарными линиями во взрывопожароопасных технологических системах?",
        answers: [
          "Съемные участки трубопроводов",
          "Гибкие резиновые и пластмассовые шланги",
          "Только гибкие резиновые шланги",
          "Только пластмассовые шланги, имеющие защиту от статического электричества",
        ],
        correctAnswers: ["Съемные участки трубопроводов"],
      },
      {
        code: "20107094",
        text: "Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?",
        answers: [
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем",
          "Оборудование должно быть отключено от питающего трубопровода",
          'Оборудование должно быть отключено, на нем должен быть вывешен плакат "Не включать"',
          "Оборудование должно быть демонтировано сразу же после его отключения",
        ],
        correctAnswers: [
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем",
        ],
      },
      {
        code: "20107095",
        text: "Как требуется размещать технологическое оборудование взрывопожароопасных производств?",
        answers: [
          "С учетом всех перечисленных требований",
          "С учетом обеспечения безопасности обслуживания и эксплуатации",
          "С учетом возможности проведения ремонтных работ и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий",
          "С учетом возможности проведения визуального контроля за их состоянием, выполнения работ по обслуживанию, ремонту и замене",
        ],
        correctAnswers: ["С учетом всех перечисленных требований"],
      },
      {
        code: "20107096",
        text: "Что должно обеспечивать размещение технологического оборудования, трубопроводной арматуры в производственных зданиях и на открытых площадках? Выберите два правильных варианта ответа.",
        answers: [
          "Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий",
          "Возможность проведения ремонтных работ",
          "Уменьшение взрывоопасности объекта путем равномерного распределения технологических блоков I категории взрывоопасности",
          "Использование строительных конструкций зданий и сооружений в несущих элементах технологического оборудования",
        ],
        correctAnswers: [
          "Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий",
          "Возможность проведения ремонтных работ",
        ],
      },
      {
        code: "20107097",
        text: "Чем определяется порядок испытаний, контроль за состоянием и эксплуатацией теплообменных устройств?",
        answers: [
          "Технической документацией производителя",
          "Проектной документацией",
          "Технологическими картами",
          "Инструкцией по эксплуатации",
        ],
        correctAnswers: ["Технической документацией производителя"],
      },
      {
        code: "20107098",
        text: "Какой должна быть температура наружных поверхностей оборудования и кожухов теплоизоляционных покрытий в местах, доступных для обслуживающего персонала?",
        answers: [
          "Не более 45 °С внутри помещений и 60 °С на наружных установках",
          "Не более 40 °С внутри помещений и 70 °С на наружных установках",
          "Не более 50 °С внутри помещений и 55 °С на наружных установках",
          "Не более 35 °С внутри помещений и 65 °С на наружных установках",
        ],
        correctAnswers: [
          "Не более 45 °С внутри помещений и 60 °С на наружных установках",
        ],
      },
      {
        code: "20107099",
        text: "Как производителем должна подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств до начала их применения на опасном производственном объекте?",
        answers: [
          "Посредством передачи средств взрывозащиты на тестирование в научно-исследовательские организации",
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
          "Посредством получения заключения органов Ростехнадзора",
          "Посредством проведения экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
        ],
      },
      {
        code: "20107100",
        text: "В каком документе указываются данные о сроке службы технологического оборудования и трубопроводной арматуры производителем?",
        answers: [
          "В паспортах оборудования и трубопроводной арматуры",
          "В технологической документации",
          "В исполнительной документации",
          "В проектной документации",
        ],
        correctAnswers: ["В паспортах оборудования и трубопроводной арматуры"],
      },
      {
        code: "20107101",
        text: "Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся и горючие жидкости на сливоналивных эстакадах?",
        answers: [
          "Должно быть организовано управление по месту и дистанционно (из безопасного места)",
          "Должно быть организовано на удалении от производственной площадки",
          "Должно быть организовано на производственной площадке",
          "Определяется проектной организацией",
        ],
        correctAnswers: [
          "Должно быть организовано управление по месту и дистанционно (из безопасного места)",
        ],
      },
      {
        code: "20107102",
        text: "Где разрешается использование железнодорожных цистерн с легковоспламеняющимися жидкостями, находящимися на железнодорожных путях, в качестве стационарных, складских (расходных) емкостей?",
        answers: [
          "Нигде не разрешается",
          "На железнодорожных путях общего пользования",
          "На железнодорожных путях необщего пользования",
          "На месте нахождения железнодорожной эстакады",
        ],
        correctAnswers: ["Нигде не разрешается"],
      },
      {
        code: "20107103",
        text: "Чем должны быть оборудованы аппараты со взрывопожароопасными веществами?",
        answers: [
          "Устройствами для подключения линий воды, пара, инертного газа",
          "Вытяжной вентиляцией",
          "Устройствами подачи огнетушащего порошка",
          "Защитными колпаками",
        ],
        correctAnswers: [
          "Устройствами для подключения линий воды, пара, инертного газа",
        ],
      },
      {
        code: "20107104",
        text: "Кто обосновывает достаточность аппаратурного резервирования и его тип?",
        answers: [
          "Территориальные органы Ростехнадзора",
          "Специализированная организация",
          "Разработчик проекта",
          "Эксплуатант опасного производственного объекта",
        ],
        correctAnswers: ["Разработчик проекта"],
      },
      {
        code: "20107105",
        text: "Какими блокировками должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?",
        answers: [
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
          "Блокировками, исключающими пуск в работу насоса при превышении в воздухе концентрации взрывоопасных веществ",
          "Блокировками, прекращающими работу насоса при изменении плотности рабочей жидкости",
          "Блокировками, исключающими пуск или прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях",
        ],
        correctAnswers: [
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
      },
      {
        code: "20107106",
        text: "Какие из перечисленных данных по каждому шлангу не должны иметь лица, ответственные за проведение сливоналивных операций с нефтепродуктами?",
        answers: [
          "Дата следующего испытания с указанием необходимого давления, при котором он должен испытываться",
          "Название нефтепродукта, для которого он предназначен",
          "Значение величины рабочего давления",
          "Значение величины разрывного давления",
        ],
        correctAnswers: [
          "Дата следующего испытания с указанием необходимого давления, при котором он должен испытываться",
        ],
      },
      {
        code: "20107107",
        text: "В каком случае допускается ручной отбор проб светлых нефтепродуктов через люк на крыше резервуара?",
        answers: [
          "Не допускается ни в каком случае",
          "В случае отсутствия ветра более 20 м/с",
          "Если объем резервуара не превышает 5000 м³",
          "Для вновь строящихся резервуаров",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20107108",
        text: "В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?",
        answers: [
          "В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования",
          "Если организован постоянный контроль воздуха на наличие в нем взрывоопасных веществ и их концентрации",
          "Если установлена система автоматического включения вытяжной вентиляции при превышении ПДК взрывоопасных веществ в воздухе",
          "В случае наличия сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости",
        ],
        correctAnswers: [
          "В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования",
        ],
      },
      {
        code: "20107109",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов I класса опасности (за исключением объектов, на которых ведутся горные работы)?",
        answers: ["1 год", "2 года", "3 года", "5 лет"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20107110",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов II класса опасности (за исключением объектов, на которых ведутся горные работы)?",
        answers: ["1 год", "2 года", "3 года", "5 лет"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20107111",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов III класса опасности (за исключением объектов, на которых ведутся горные работы)?",
        answers: ["1 год", "2 года", "3 года", "5 лет"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20107112",
        text: "Что из перечисленного не является обязательной составляющей плана мероприятий по локализации и ликвидации последствий аварий на ОПО?",
        answers: [
          "Результаты расчета риска возникновения аварий на опасном производственном объекте",
          "Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий",
          "Действия производственного персонала и аварийно-спасательных служб (формирований) по локализации и ликвидации аварийных ситуаций",
          "Количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте и их соответствие задачам по локализации и ликвидации последствий аварий",
        ],
        correctAnswers: [
          "Результаты расчета риска возникновения аварий на опасном производственном объекте",
        ],
      },
      {
        code: "20107113",
        text: "Что определяется в специальном разделе плана мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Порядок действий в случае аварий на объекте",
          "Количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте, и их соответствие задачам по локализации и ликвидации последствий аварий",
          "Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах",
          "Действия производственного персонала и аварийно-спасательных служб (формирований) по локализации и ликвидации аварий",
        ],
        correctAnswers: ["Порядок действий в случае аварий на объекте"],
      },
      {
        code: "20107114",
        text: "В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
          "В целях координации работников в случае возникновения аварии",
          "В целях определения схемы оповещения служб, участвующих в локализации и ликвидации последствий аварии",
          "В целях определения ответственных работников при проведении мероприятий по локализации и ликвидации последствий аварии",
        ],
        correctAnswers: [
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
        ],
      },
      {
        code: "20107115",
        text: "Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?",
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          "Только для опасных производственных объектов I класса",
          "Только для опасных производственных объектов I и II классов",
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "20107116",
        text: "В каком случае организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий для нескольких опасных производственных объектов?",
        answers: [
          "В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
          "В случае расположения 2 идентичных объектов на отдельных участках",
          "В случае если у организации несколько производственных участков",
          "В случае если у 2 объектов, расположенных на смежных участках, идентичный класс опасности",
        ],
        correctAnswers: [
          "В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
        ],
      },
      {
        code: "20107117",
        text: "Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?",
        answers: [
          "Наименьший срок, предусмотренный для этих объектов",
          "5 лет",
          "2 года",
          "Срок устанавливается территориальным органом Ростехнадзора",
        ],
        correctAnswers: ["Наименьший срок, предусмотренный для этих объектов"],
      },
      {
        code: "20107118",
        text: "В какой срок должен быть пересмотрен план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах до истечения срока действия предыдущего плана мероприятий?",
        answers: [
          "Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий",
          "Не менее чем за 30 календарных дней до истечения срока действия предыдущего плана мероприятий",
          "Не менее чем за 3 месяца до истечения срока действия предыдущего плана мероприятий",
          "Не менее чем за 10 рабочих дней до истечения срока действия предыдущего плана мероприятий",
        ],
        correctAnswers: [
          "Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий",
        ],
      },
    ],

    20108: [
      {
        code: "20108000",
        text: 'Федеральные нормы и правила в области промышленной безопасности "Правила проведения экспертизы промышленной безопасности" не устанавливают:',
        answers: [
          "Требования к экспертам в области промышленной безопасности",
          "Процедуру проведения экспертизы промышленной безопасности",
          "Требования к оформлению заключения экспертизы промышленной безопасности",
          "Порядок ведения реестра заключений экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "Порядок ведения реестра заключений экспертизы промышленной безопасности",
        ],
      },
      {
        code: "20108001",
        text: "В каком случае не предусмотрено проведение экспертизы промышленной безопасности зданий и сооружений на опасном производственном объекте?",
        answers: [
          "После аварии на опасном производственном объекте, в результате которой не были повреждены несущие конструкции данных зданий и сооружений",
          "После истечения срока эксплуатации здания или сооружения, установленного проектной документацией",
          "При отсутствии проектной документации, либо отсутствии в проектной документации данных о сроке эксплуатации здания или сооружения",
          "По истечении сроков безопасной эксплуатации, установленных заключениями экспертизы",
        ],
        correctAnswers: [
          "После аварии на опасном производственном объекте, в результате которой не были повреждены несущие конструкции данных зданий и сооружений",
        ],
      },
      {
        code: "20108002",
        text: "Что не входит в обязанности эксперта в области промышленной безопасности?",
        answers: [
          "Представлять заключение экспертизы промышленной безопасности в Ростехнадзор для регистрации",
          "Определять соответствие объектов экспертизы промышленной безопасности требованиям промышленной безопасности",
          "Обеспечивать объективность и обоснованность выводов заключения экспертизы",
          "Обеспечивать сохранность документов и конфиденциальность сведений, представленных на экспертизу",
        ],
        correctAnswers: [
          "Представлять заключение экспертизы промышленной безопасности в Ростехнадзор для регистрации",
        ],
      },
      {
        code: "20108003",
        text: "Каким должен быть срок проведения экспертизы промышленной безопасности со дня получения экспертной организацией от заказчика экспертизы комплекта необходимых материалов и документов?",
        answers: [
          "Не более трех месяцев, но, по соглашению сторон, может быть продлен",
          "Не более одного месяца",
          "Не более двух месяцев",
          "Не менее шести месяцев",
        ],
        correctAnswers: [
          "Не более трех месяцев, но, по соглашению сторон, может быть продлен",
        ],
      },
      {
        code: "20108004",
        text: "Какие выводы может содержать заключение экспертизы промышленной безопасности опасного производственного объекта?",
        answers: [
          "Только о соответствии объекта экспертизы требованиям промышленной безопасности и возможности его применения при эксплуатации опасного производственного объекта",
          "Только о том, что объект экспертизы не в полной мере соответствует требованиям промышленной безопасности и может быть применен при условии внесения соответствующих изменений в документацию или выполнения соответствующих мероприятий в отношении технических устройств, зданий и сооружений",
          "Только о несоответствии объекта экспертизы требованиям промышленной безопасности и невозможности его применения при эксплуатации опасного производственного объекта",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20108005",
        text: "Какой документ составляется по результатам технического диагностирования, неразрушающего контроля, разрушающего контроля технических устройств, обследования зданий и сооружений?",
        answers: [
          "Акты по результатам проведения указанных работ",
          "Отчет о проведении испытательных работ",
          "Соответствующий пункт заключения экспертизы промышленной безопасности",
          "Техническая справка о проведении обследований",
        ],
        correctAnswers: ["Акты по результатам проведения указанных работ"],
      },
      {
        code: "20108006",
        text: "При каком сроке службы технического устройства из-за отсутствия в технической документации соответствующих данных для оценки его фактического состояния проводится техническое диагностирование?",
        answers: [
          "Свыше 20 лет",
          "Свыше 10 лет",
          "Свыше 12 лет",
          "Свыше 5 лет",
        ],
        correctAnswers: ["Свыше 20 лет"],
      },
      {
        code: "20108007",
        text: "В каком документе указываются регламентированные значения параметров по ведению технологического процесса?",
        answers: [
          "В плане производства работ",
          "В руководстве по эксплуатации",
          "В технологическом регламенте",
          "В техническом проекте",
        ],
        correctAnswers: ["В технологическом регламенте"],
      },
      {
        code: "20108008",
        text: "Кем разрабатываются все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?",
        answers: [
          "Организацией-разработчиком производственного процесса",
          "Специализированной проектной организацией",
          "Организацией, эксплуатирующей химико-технологическое производство",
          "Комиссией под председательством руководителя территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "Организацией, эксплуатирующей химико-технологическое производство",
        ],
      },
      {
        code: "20108009",
        text: "Кто утверждает все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?",
        answers: [
          "Технический руководитель организации, эксплуатирующей производственный объект",
          "Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство",
          "Начальник отдела технического контроля организации, являющейся разработчиком производственного процесса",
          "Председатель комиссии территориального органа государственного надзора в области промышленной безопасности",
        ],
        correctAnswers: [
          "Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство",
        ],
      },
      {
        code: "20108010",
        text: 'Кто подписывается в технологическом регламенте под грифом "согласовано"? Укажите все правильные ответы.',
        answers: [
          "Руководитель проектного отдела организации",
          "Главный механик и главный энергетик организации",
          "Главный метролог организации",
          "Начальник производственного участка",
        ],
        correctAnswers: [
          "Главный механик и главный энергетик организации",
          "Главный метролог организации",
        ],
      },
      {
        code: "20108011",
        text: "Какое количество копий технологических регламентов устанавливается требованиями Правил безопасности химически опасных производственных объектов?",
        answers: [
          "Не менее 10",
          "Не менее 15",
          "Не менее 5",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20108012",
        text: "Какой устанавливается срок действия постоянного технологического регламента?",
        answers: [
          "Не более 5 лет",
          "Не более 3 лет",
          "Не более 10 лет",
          "Не более 15 лет",
        ],
        correctAnswers: ["Не более 10 лет"],
      },
      {
        code: "20108013",
        text: "В каком из перечисленных случаев должен быть составлен временный технологический регламент на новый срок? Укажите все правильные ответы.",
        answers: [
          "Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса",
          "Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей",
          "Если такое решение принято руководителем и технологической службой организации",
          "Если в тексте технологического регламента обнаружены несогласованные правки",
        ],
        correctAnswers: [
          "Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса",
          "Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей",
        ],
      },
      {
        code: "20108014",
        text: "Каким образом определяется срок действия временного технологического регламента при отсутствии установленных планами норм освоения производства?",
        answers: [
          "Руководителем организации с учетом объема работ",
          "Лицом, утверждающим технологический регламент",
          "Начальником производственного участка с учетом затрат",
          "В соответствии с проектной документацией руководящим составом производства",
        ],
        correctAnswers: ["Лицом, утверждающим технологический регламент"],
      },
      {
        code: "20108015",
        text: "Какой устанавливается срок действия разовых (опытных) технологических регламентов, в соответствии с которыми проводится наработка опытной продукции в течение нескольких лет?",
        answers: [
          "Не более 10 лет",
          "Не более 5 лет",
          "Не более 3 лет",
          "Не более 1 года",
        ],
        correctAnswers: ["Не более 5 лет"],
      },
      {
        code: "20108016",
        text: "Каким образом устанавливается и оформляется срок продления действия временного технологического регламента?",
        answers: [
          "Приказом руководителя организации",
          "Приказом председателя комиссии организации",
          "Приказом заведующего производством организации",
          "Приказом начальника технического отдела организации",
        ],
        correctAnswers: ["Приказом руководителя организации"],
      },
      {
        code: "20108017",
        text: "Какой из перечисленных разделов не относится к постоянным, временным и разовым технологическим регламентам, связанным с необходимостью обеспечения промышленной безопасности технологических процессов?",
        answers: [
          "Контроль производства и управление технологическим процессом",
          "Характеристика производимой продукции",
          "Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств",
          "Нормы расхода основных видов сырья, материалов и энергоресурсов",
        ],
        correctAnswers: [
          "Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств",
        ],
      },
      {
        code: "20108018",
        text: "На кого возлагается ответственность за полноту и качество разработки разделов технологического регламента производства продукции и контроль за обеспечением его исполнения?",
        answers: [
          "На главного инженера организации, эксплуатирующей технологическое производство",
          "На организацию, осуществляющую разработку процесса производства",
          "На руководителя организации, эксплуатирующей химико-технологическое производство",
          "На технологическую службу организации, производства, отделения, установки",
        ],
        correctAnswers: [
          "На технологическую службу организации, производства, отделения, установки",
        ],
      },
      {
        code: "20108019",
        text: 'Как производится описание технологической схемы в разделе технологического регламента "Описание технологического процесса и схемы"?',
        answers: [
          "По этапам технологического процесса, начиная с отгрузки сырья со склада",
          "По видам производственных операций, включая переработку сырья с использованием оборудования и выпуском готовой продукции",
          "По стадиям технологического процесса, начиная с поступления и подготовки сырья и заканчивая отгрузкой готового продукта",
        ],
        correctAnswers: [
          "По стадиям технологического процесса, начиная с поступления и подготовки сырья и заканчивая отгрузкой готового продукта",
        ],
      },
      {
        code: "20108020",
        text: 'Что необходимо указывать в описании процессов разделения химических продуктов (горючих или их смесей с негорючими) в разделе технологического регламента "Описание технологического процесса и схемы"?',
        answers: [
          "Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса",
          "Разделение агрессивных сред, показатели пожароопасности, токсичности используемых материалов",
          "Показатели взрывопожароопасности, токсичные свойства всех химических веществ, участвующих во всех стадиях процесса",
        ],
        correctAnswers: [
          "Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса",
        ],
      },
      {
        code: "20108021",
        text: 'Что необходимо указать в разделе "Описание химико-технологического процесса и схемы" при применении катализаторов, в том числе металлоорганических, которые при взаимодействии с кислородом воздуха и (или) водой могут самовозгораться и (или) взрываться?',
        answers: [
          "Меры, исключающие возможность подачи в систему сырья, материалов и инертного газа, содержащих кислород и (или) влагу в количествах, превышающих предельно допустимые значения",
          "Количество металлоорганических катализаторов, допустимых к применению с учетом их физико-химических свойств",
          "Необходимые меры, исключающие превышение установленного количества катализаторов, подаваемых в систему",
        ],
        correctAnswers: [
          "Меры, исключающие возможность подачи в систему сырья, материалов и инертного газа, содержащих кислород и (или) влагу в количествах, превышающих предельно допустимые значения",
        ],
      },
      {
        code: "20108022",
        text: "Как необходимо составлять описание схемы процесса при наличии нескольких аналогичных технологических ниток?",
        answers: [
          "Допускается делать описание схемы процесса по одной технологической линии",
          "Необходимо представить в начале раздела описание всех технологических линий",
          "Составлять описание схемы производственного процесса допускается при наличии не менее 2 технологических линий",
        ],
        correctAnswers: [
          "Допускается делать описание схемы процесса по одной технологической линии",
        ],
      },
      {
        code: "20108023",
        text: 'Что должны обеспечивать системы контроля, автоматического и дистанционного управления (системы управления), системы оповещения об аварийных ситуациях в разделе технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          "Точность поддержания технологических параметров, надежность и безопасность проведения технологических процессов ",
          "Контроль за параметрами рабочего процесса, надежность применяемого технологического оборудования, контроль за действиями работников",
          "Надежность и безопасность проведения технологических процессов, мониторинг входных параметров процесса",
        ],
        correctAnswers: [
          "Точность поддержания технологических параметров, надежность и безопасность проведения технологических процессов ",
        ],
      },
      {
        code: "20108024",
        text: 'Какое из перечисленных требований не соответствует разделу технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          "Допускается разделение в отдельные таблицы перечней систем сигнализации (блокировок) и (или) аналитического контроля",
          "Периодичность испытаний запорной регулирующей арматуры, исполнительных механизмов, периодичность проверок приборов и испытаний других технических средств, участвующих в схемах контроля, управления и противоаварийной защиты технологических процессов, должны быть взаимоувязаны",
          "Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается",
          "Средства автоматики, используемые по плану мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, должны иметь особые пометки и выделения на технологической схеме",
        ],
        correctAnswers: [
          "Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается",
        ],
      },
      {
        code: "20108025",
        text: 'Информацию о каких организациях должен содержать раздел технологического регламента "Общая характеристика производства"?',
        answers: [
          "Только об организации, являющейся разработчиком технологического процесса",
          "Только об организации, выполняющей функции генерального проектировщика",
          "Только об организациях, исполнивших проектную документацию",
          "Только об организации, осуществляющей разработку технологической части проектной документации",
          "Обо всех перечисленных организациях",
        ],
        correctAnswers: ["Обо всех перечисленных организациях"],
      },
      {
        code: "20108026",
        text: 'Какие разновидности материального баланса допускается составлять в разделе технологического регламента "Материальный баланс"?',
        answers: [
          "Только на единицу выпускаемой продукции",
          "Только на единицу времени (в часах)",
          "Только на мощность производства в целом или единичный производственный поток",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20108027",
        text: 'Какой устанавливается срок действия "Накопительной ведомости"?',
        answers: ["1 год", "3 месяца", "6 месяцев", "1 месяц"],
        correctAnswers: ["6 месяцев"],
      },
      {
        code: "20108028",
        text: 'Какое из перечисленных требований соответствует нормам заполнения и хранения "Листа регистрации изменений и дополнений"?',
        answers: [
          "Регистрация изменений и дополнений выполняется техническим руководителем организации",
          "Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под подпись в подразделения, где находятся копии регламента",
          "Лист регистрации изменений и дополнений должен размещаться в начале технологического регламента",
          "Запись в регистрационном листе выполняется черными чернилами",
        ],
        correctAnswers: [
          "Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под подпись в подразделения, где находятся копии регламента",
        ],
      },
      {
        code: "20108029",
        text: "Каким документом обосновывается повышение категории взрывоопасности технологических блоков, определяемое количеством токсичных, высокотоксичных веществ, опасностью причинения ими вреда обслуживающему персоналу при вероятных сценариях развития аварийной ситуации?",
        answers: [
          "Технологическим регламентом",
          "Проектной документацией",
          "Правилами безопасности при производстве работ",
        ],
        correctAnswers: ["Проектной документацией"],
      },
      {
        code: "20108030",
        text: "Кто делает обоснование по применению эффективности и надежности мер и технических средств противоаварийной защиты, направленных на обеспечение взрывобезопасности отдельного блока и в целом всей технологической системы?",
        answers: [
          "Эксплуатирующая организация",
          "Проектная организация",
          "Подрядная организация",
          "Организация, осуществляющая экспертизу промышленной безопасности",
        ],
        correctAnswers: ["Проектная организация"],
      },
      {
        code: "20108031",
        text: "На сколько категорий взрывоопасности подразделяются при проектировании технологические блоки взрывопожароопасных производств и объектов?",
        answers: [
          "На две категории",
          "На три категории",
          "На четыре категории",
          "На пять категорий",
        ],
        correctAnswers: ["На три категории"],
      },
      {
        code: "20108032",
        text: "Какое управление системами подачи инертных сред в технологические системы должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категории взрывоопасности?",
        answers: [
          "Автоматическое",
          "Дистанционное неавтоматическое",
          "Ручное управление по месту",
        ],
        correctAnswers: ["Автоматическое"],
      },
      {
        code: "20108033",
        text: "С какими подразделениями должна быть обеспечена связь посредством системы двусторонней громкоговорящей связи на объектах с технологическими блоками I категории взрывоопасности?",
        answers: [
          "С операторами аварийно-диспетчерской службы и ответственными работниками бригады быстрого реагирования",
          "С сотрудниками противопожарной службы и спасателями МЧС",
          "С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов",
        ],
        correctAnswers: [
          "С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов",
        ],
      },
      {
        code: "20108034",
        text: "В каком из перечисленных случаев категорию взрывоопасности блоков, определяемую расчетом, следует рассматривать на одну выше?",
        answers: [
          "Только если опасные вещества, обращающиеся в технологическом блоке, относятся к высокотоксичным веществам",
          "Только если вещества, обращающиеся в технологическом блоке, относятся к токсичным веществам",
          "В любом из перечисленных случаев",
        ],
        correctAnswers: ["В любом из перечисленных случаев"],
      },
      {
        code: "20108035",
        text: "Где предусматривается установка постов управления и технических средств связи и оповещения для извещения об опасных выбросах химически опасных веществ на объектах, имеющих в своем составе блоки I категории взрывоопасности? Выберите 2 правильных варианта ответа.",
        answers: [
          "В помещении диспетчера предприятия",
          "На наружных установках",
          "В местах расположения эвакуационных выходов",
          "На контрольно-пропускном пункте объекта",
        ],
        correctAnswers: [
          "В помещении диспетчера предприятия",
          "На наружных установках",
        ],
      },
      {
        code: "20108036",
        text: "Какие меры по обеспечению взрывобезопасности предусматриваются проектной организацией для каждого технологического блока с учетом его энергетического потенциала? Выберите 2 правильных варианта ответа.",
        answers: [
          "Уменьшение ущерба от аварии на взрывопожароопасном объекте",
          "Повышение квалификации обслуживающего персонала на курсах переподготовки",
          "Предупреждение взрывов и предотвращение травмирования производственного персонала",
          "Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества",
        ],
        correctAnswers: [
          "Предупреждение взрывов и предотвращение травмирования производственного персонала",
          "Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества",
        ],
      },
      {
        code: "20108037",
        text: "Какая из перечисленных мер должна предусматриваться для блоков технологической системы по максимальному снижению взрывоопасности?",
        answers: [
          "Применение моноблочных вентиляционных систем для исключения возможности взрывов и пожаров в объеме производственных зданий, сооружений",
          "Наличие эффективных систем пожаротушения в зависимости от особенностей технологического процесса",
          "Систематический контроль за состоянием воздушной среды",
          "Предотвращение взрывов внутри технологического оборудования",
        ],
        correctAnswers: [
          "Предотвращение взрывов внутри технологического оборудования",
        ],
      },
      {
        code: "20108038",
        text: "Каким требованиям должны соответствовать специальные системы аварийного освобождения технологических блоков от обращающихся продуктов? Выберите 2 правильных варианта ответа.",
        answers: [
          "Обеспечивать минимально возможное время освобождения",
          "Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий",
          "Соответствовать установленным параметрам и быть мобильным",
          "Осуществлять переход из режима ожидания в рабочее состояние в течение 30 секунд",
        ],
        correctAnswers: [
          "Обеспечивать минимально возможное время освобождения",
          "Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий",
        ],
      },
      {
        code: "20108039",
        text: "Запорная арматура из каких материалов должна применяться в технологических системах с блоками любой категории взрывоопасности?",
        answers: [
          "Из стали",
          "Из чугуна",
          "Из сплавов титана",
          "Из неметаллических материалов",
        ],
        correctAnswers: ["Из стали"],
      },
      {
        code: "20108040",
        text: "Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?",
        answers: [
          "Время срабатывания определяется расчетом для технологических блоков I и II категорий и устанавливается для блоков III категории в соответствии с категорией взрывоопасности",
          "Время срабатывания устанавливается для каждого технологического блока в соответствии с категорией взрывоопасности",
          "Время срабатывания определяется расчетом",
          "Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и устанавливается для блоков I и II категорий в соответствии с категорией взрывоопасности",
        ],
        correctAnswers: ["Время срабатывания определяется расчетом"],
      },
      {
        code: "20108041",
        text: "В соответствии с чем должно осуществляться размещение организации, имеющей в своем составе взрывоопасные технологические объекты, планировка ее территории, объемно-планировочные решения строительных объектов?",
        answers: [
          "В соответствии с техническим регламентом",
          "В соответствии с требованиями территориального управления Ростехнадзора",
          "В соответствии с требованиями законодательства о градостроительной деятельности",
          "В соответствии с проектной документацией",
        ],
        correctAnswers: [
          "В соответствии с требованиями законодательства о градостроительной деятельности",
        ],
      },
      {
        code: "20108042",
        text: "Что предусматривается во взрывоопасных помещениях и вне их перед входными дверями?",
        answers: [
          "Только устройство световой сигнализации",
          "Устройство световой и звуковой сигнализации загазованности воздушной среды",
          "Только устройство звуковой сигнализации",
          "Стенд с информацией о действиях персонала в аварийной ситуации",
        ],
        correctAnswers: [
          "Устройство световой и звуковой сигнализации загазованности воздушной среды",
        ],
      },
      {
        code: "20108043",
        text: "Какие устройства должны иметь машинные, аппаратные и конденсаторные отделения, относящиеся к помещениям с взрывоопасной зоной В-1б?",
        answers: [
          "Только устройства молниезащиты зданий по II категории",
          "Только защиту от заноса высокого потенциала по наземным и подземным коммуникациям",
          "Только защиту от вторичных проявлений молнии",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20108044",
        text: "В каком случае допускается размещение машинного (аппаратного) отделения в подвальных и цокольных этажах?",
        answers: [
          "Если расположение отделения соответствует требованиям технического регламента",
          "Если имеется согласование с территориальным управлением Ростехнадзора",
          "Если это обосновано проектной документацией",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20108045",
        text: "Каким следует принимать расстояние в свету от аппаратов (сосудов), расположенных снаружи машинного (аппаратного) отделения?",
        answers: [
          "Не менее 1,5 м от стены здания",
          "Не менее 1,0 м от стены здания",
          "Не менее 2,5 м от стены здания",
          "Не менее 2,0 м от стены здания",
        ],
        correctAnswers: ["Не менее 1,0 м от стены здания"],
      },
      {
        code: "20108046",
        text: "Каким минимальным количеством эвакуационных выходов должны оснащаться помещения машинного и аппаратного отделения?",
        answers: [
          "Одним",
          "Тремя",
          "Двумя",
          "Количество определяется исходя из параметров помещения",
        ],
        correctAnswers: ["Двумя"],
      },
      {
        code: "20108047",
        text: "Что необходимо предусматривать в проектной документации или документации на техническое перевооружение для максимального снижения выбросов горючих и взрывопожароопасных веществ при аварийной разгерметизации системы?",
        answers: [
          "Установку запорных и (или) отсекающих устройств с временем срабатывания не более 120 секунд",
          "Установку запорных и (или) отсекающих устройств с автоматическим управлением и временем срабатывания не более 300 секунд",
          "Установку запорных и (или) отсекающих устройств с временем срабатывания, устанавливаемым в проектной документации",
        ],
        correctAnswers: [
          "Установку запорных и (или) отсекающих устройств с временем срабатывания, устанавливаемым в проектной документации",
        ],
      },
      {
        code: "20108048",
        text: "Какие из перечисленных мер предусматриваются проектной документацией или документацией на техническое перевооружение для обеспечения взрывобезопасности технологической системы при пуске в работу и остановке оборудования? Укажите все правильные ответы.",
        answers: [
          "Разработка режимов и порядка пуска и остановки оборудования",
          "Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон",
          "Проведение контроля за содержанием горючих веществ в технологической системе после остановки оборудования",
          "Поддержание избыточного давления инертного газа в технологической системе во время остановки оборудования",
        ],
        correctAnswers: [
          "Разработка режимов и порядка пуска и остановки оборудования",
          "Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон",
        ],
      },
      {
        code: "20108049",
        text: "Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?",
        answers: [
          "Выбор рациональных режимов дозирования компонентов, предотвращение возможности отклонения их соотношений от регламентированных значений",
          "Применение компонентов в фазовом состоянии, затрудняющем или исключающем образование химически опасной смеси",
          "Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки",
          "Рациональный выбор гидродинамических и теплообменных характеристик процесса",
        ],
        correctAnswers: [
          "Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки",
        ],
      },
      {
        code: "20108050",
        text: "Чем должны оснащаться технологические системы для обеспечения безопасности ведения технологических процессов?",
        answers: [
          "Модернизированными системами пожаротушения",
          "Системой круглосуточного видеонаблюдения",
          "Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ",
          "Только системами автоматического регулирования",
        ],
        correctAnswers: [
          "Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ",
        ],
      },
      {
        code: "20108051",
        text: "С учетом каких параметров в каждом конкретном случае в проектной документации (документации на техническое перевооружение) обосновывается решение о типе арматуры и месте ее установки на линиях всасывания и нагнетания, а также способе ее отключения, в том числе дистанционном? Укажите все правильные ответы.",
        answers: [
          "С учетом марки стали трубопровода",
          "С учетом толщины стенок трубопровода",
          "С учетом диаметра и протяженности трубопровода",
          "С учетом характеристики транспортируемой среды",
        ],
        correctAnswers: [
          "С учетом диаметра и протяженности трубопровода",
          "С учетом характеристики транспортируемой среды",
        ],
      },
      {
        code: "20108052",
        text: "Какие функции должна обеспечивать автоматизированная система управления технологическими процессами на базе средств вычислительной техники? Выберите 2 правильных варианта ответа.",
        answers: [
          "Периодический контроль за состоянием воздушной среды в пределах объекта, но не реже одного раза в смену",
          "Цикличный контроль за параметрами производственного процесса и управление режимами для поддержания их постоянных значений",
          "Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений",
          "Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации",
        ],
        correctAnswers: [
          "Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений",
          "Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации",
        ],
      },
      {
        code: "20108053",
        text: "Кто обосновывает достаточность аппаратурного резервирования и его тип?",
        answers: [
          "Технический руководитель организации",
          "Руководитель эксплуатирующей организации",
          "Разработчик проекта",
          "Руководитель технологического процесса",
        ],
        correctAnswers: ["Разработчик проекта"],
      },
      {
        code: "20108054",
        text: "Каким требованиям должны соответствовать помещения управления? Выберите 2 правильных варианта ответа.",
        answers: [
          "Защита от механических повреждений кабелей, проложенных по полу, должна осуществляться с помощью швеллеров",
          "Полы в помещении должны быть выполнены из термостойких материалов",
          "Помещения должны иметь световую и звуковую сигнализацию о загазованности",
          "Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам",
        ],
        correctAnswers: [
          "Помещения должны иметь световую и звуковую сигнализацию о загазованности",
          "Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам",
        ],
      },
      {
        code: "20108055",
        text: "Какое из перечисленных положений не соответствует требованиям по размещению и устройству помещений управления взрывоопасных производств?",
        answers: [
          "В помещениях не допускается размещение оборудования и других устройств, не связанных с системой управления технологическим процессом",
          "Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами",
          "Помещения управления должны быть отдельно стоящими",
          "При соответствующем обосновании в проекте в отдельных случаях разрешено пристраивать помещения управления к зданиям",
        ],
        correctAnswers: [
          "Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами",
        ],
      },
      {
        code: "20108056",
        text: "Что необходимо предусматривать в химико-технологических системах для эффективного проведения периодических работ по очистке технологического оборудования?",
        answers: [
          "Наличие оросительных систем",
          "Наличие дежурного персонала во время выполнения работ",
          "Наличие средств гидравлической, механической или химической чистки",
          "Наличие специального инвентаря для очистки оборудования",
        ],
        correctAnswers: [
          "Наличие средств гидравлической, механической или химической чистки",
        ],
      },
      {
        code: "20108057",
        text: "Какие требования должны быть выполнены для вновь проектируемых взрывопожароопасных и химически опасных объектов? Выберите 2 правильных варианта ответа.",
        answers: [
          "Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов",
          "Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия",
          "Планировка территории предприятия, имеющего в своем составе взрывоопасные технологические объекты, выполняется в соответствии с указаниями территориального органа Ростехнадзора",
          "Вокруг зданий должна предусматриваться вспаханная полоса земли шириной не менее 3 м",
        ],
        correctAnswers: [
          "Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов",
          "Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия",
        ],
      },
      {
        code: "20108058",
        text: "Чем определяется количество насосов и компрессоров, используемых для перемещения химически опасных веществ в технологическом процессе?",
        answers: [
          "Необходимостью и условиями обеспечения непрерывности технологического процесса",
          "Критическими параметрами технологического процесса",
          "Физико-химическими свойствами перемещаемых продуктов",
          "Техническими характеристиками используемых насосов (компрессоров)",
        ],
        correctAnswers: [
          "Необходимостью и условиями обеспечения непрерывности технологического процесса",
        ],
      },
      {
        code: "20108059",
        text: "Где допускается размещать насосы оборотного водоснабжения в обоснованных в проектной документации случаях? Укажите все правильные ответы.",
        answers: [
          "В машинном отделении",
          "В аппаратном отделении",
          "В бытовых и вспомогательных помещениях",
          "На открытых площадках вне машинного (аппаратного) отделения",
        ],
        correctAnswers: ["В машинном отделении", "В аппаратном отделении"],
      },
      {
        code: "20108060",
        text: "Какими приборами и средствами автоматизации оснащаются сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Выберите 2 правильных варианта ответа.",
        answers: [
          "Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации",
          "Блокировками отключения компрессора при превышении предельно допустимого значения уровня",
          "Приборами контроля температуры и плотности отделяемой жидкой фазы",
          "Приборами измерения перепада давления на сепараторе",
        ],
        correctAnswers: [
          "Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации",
          "Блокировками отключения компрессора при превышении предельно допустимого значения уровня",
        ],
      },
      {
        code: "20108061",
        text: "Каким образом осуществляется контроль за содержанием кислорода в горючем газе во всасывающих линиях компрессоров, работающих под разрежением? Выберите 2 правильных варианта ответа.",
        answers: [
          "Проектной организацией определяются места размещения пробоотборников и способы контроля за содержанием кислорода в горючем газе",
          "Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения",
          "Систематический контроль содержания кислорода в горючем газе проводится не реже 2 раз в смену",
          "Инертный газ при остановке компрессора закачивается во всасывающие линии насоса",
        ],
        correctAnswers: [
          "Проектной организацией определяются места размещения пробоотборников и способы контроля за содержанием кислорода в горючем газе",
          "Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения",
        ],
      },
      {
        code: "20108062",
        text: "Чем должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей? Выберите 2 правильных варианта ответа.",
        answers: [
          "Системами измерения плотности и вязкости перекачиваемых сред",
          "Средствами контроля температуры перемещаемой жидкости",
          "Средствами предупредительной сигнализации при достижении опасных значений параметров в приемных и расходных емкостях",
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
        correctAnswers: [
          "Средствами предупредительной сигнализации при достижении опасных значений параметров в приемных и расходных емкостях",
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
      },
      {
        code: "20108063",
        text: "Чем должны оснащаться колонны ректификации горючих жидкостей? Выберите 2 правильных варианта ответа.",
        answers: [
          "Средствами контроля за плотностью поступающих на разделение продукта и флегмы",
          "Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы",
          "Средствами сигнализации об опасных отклонениях значений параметров, определяющих взрывобезопасность процесса",
          "Средствами контроля за компонентным составом поступающего на разделение продукта",
        ],
        correctAnswers: [
          "Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы",
          "Средствами сигнализации об опасных отклонениях значений параметров, определяющих взрывобезопасность процесса",
        ],
      },
      {
        code: "20108064",
        text: "Что применяется при непрерывных процессах смешивания веществ, взаимодействие которых может привести к развитию экзотермических реакций, для исключения их неуправляемого течения? Выберите 2 правильных варианта ответа.",
        answers: [
          "Определение безопасных объемных скоростей дозирования смешиваемых веществ",
          "Разработка методов отвода тепла",
          "Разработка методов приостановки реакции путем увеличения подачи одного из смешиваемых веществ",
          "Эффективное разделение этих продуктов и возможность образования застойных зон",
        ],
        correctAnswers: [
          "Определение безопасных объемных скоростей дозирования смешиваемых веществ",
          "Разработка методов отвода тепла",
        ],
      },
      {
        code: "20108065",
        text: "В каком случае допускается объединение выбросов химически опасных веществ, содержащих вещества, способные при смешивании образовывать более опасные по воздействиям химические соединения?",
        answers: [
          "В случае расчета количества выбросов, допустимых условиями безопасной остановки технологического процесса",
          "В случае применения специальных систем аварийного освобождения",
          "В случае разработки необходимых мер безопасности, предусмотренных документацией производственного объекта",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20108066",
        text: "На какой стадии предусматриваются средства контроля и регулирования уровня разделения фаз горючих паров (газов) и жидкостей?",
        answers: [
          "На стадии ведения строительства",
          "На стадии проектирования процесса",
          "На стадии завершения производственного процесса",
          "На стадии ввода в эксплуатацию",
        ],
        correctAnswers: ["На стадии проектирования процесса"],
      },
      {
        code: "20108067",
        text: "Куда следует направлять сбрасываемые химически опасные вещества?",
        answers: [
          "В промышленную канализацию",
          "В открытую систему общезаводской факельной установки",
          "В закрытые системы для дальнейшей утилизации",
          "В специальные металлические емкости",
        ],
        correctAnswers: ["В закрытые системы для дальнейшей утилизации"],
      },
      {
        code: "20108068",
        text: "Чем должно оснащаться оборудование для измельчения и смешивания измельченных твердых горючих продуктов для обеспечения эксплуатационной безопасности в отношении риска взрыва?",
        answers: [
          "Сигнализацией об отклонении давления подаваемого инертного газа от регламентированных значений",
          "Средствами контроля за фракционным составом измельченных твердых горючих продуктов",
          "Автоматическими блокировками, не допускающими пуск в работу оборудования без предварительной продувки инертным газом в течение 5 минут",
          "Средствами контроля за температурой измельченных твердых горючих продуктов",
        ],
        correctAnswers: [
          "Сигнализацией об отклонении давления подаваемого инертного газа от регламентированных значений",
        ],
      },
      {
        code: "20108069",
        text: "Чем должно быть оснащено оборудование для разделения суспензий и фильтрации?",
        answers: [
          "Оборудование должно оснащаться приборами, контролирующими концентрацию химически опасных смесей в рабочей зоне",
          "Оборудование должно быть оснащено блокировками, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды",
          "Оборудование должно быть оснащено устройствами, предотвращающими возникновение взрывоопасной смеси",
          "Оборудование должно быть оснащено устройствами, предотвращающими образование химически опасных смесей в самом оборудовании",
        ],
        correctAnswers: [
          "Оборудование должно быть оснащено блокировками, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды",
        ],
      },
      {
        code: "20108070",
        text: "Что относится к технологическим трубопроводам?",
        answers: [
          "Трубопроводы, предназначенные для транспортирования различных веществ, необходимых для ведения технологического процесса",
          "Трубопроводы, предназначенные для внутренних водостоков",
          "Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования",
        ],
        correctAnswers: [
          "Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования",
        ],
      },
      {
        code: "20108071",
        text: "Кто устанавливает назначенный срок службы для технологических трубопроводов?",
        answers: [
          "Разработчик документации",
          "Уполномоченный орган по сертификации",
          "Организация-изготовитель",
          "Центр метрологии и стандартизации",
        ],
        correctAnswers: ["Разработчик документации"],
      },
      {
        code: "20108072",
        text: "Какие дополнительные требования установлены при использовании технологического оборудования и трубопроводов, в которых обращаются коррозионно-активные вещества?",
        answers: [
          "Запрещается использовать неметаллические покрытия для защиты технологического оборудования",
          "Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии",
          "Контроль за технологическим оборудованием и трубопроводами, контактирующими с коррозионно-активными веществами, должен осуществляться не реже чем 1 раз в месяц",
          "Технологическое оборудование и трубопроводы должны изготавливаться из коррозионностойких неметаллических материалов",
        ],
        correctAnswers: [
          "Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии",
        ],
      },
      {
        code: "20108073",
        text: "Кто должен установить расчетный срок службы объектов систем холодоснабжения для технологических трубопроводов, который отражается в документации трубопроводов, вносится в паспорт трубопроводов и учитывается при организации и осуществлении деятельности на опасном производственном объекте?",
        answers: [
          "Проектно-монтажная организация",
          "Разработчик документации",
          "Территориальный орган Ростехнадзора",
          "Экспертная организация по промышленной безопасности",
        ],
        correctAnswers: ["Разработчик документации"],
      },
      {
        code: "20108074",
        text: "В каком случае допускается предусматривать отдельные отделители жидкости, соединенные трубопроводами с циркуляционными (защитными) ресиверами, не совмещающими функции отделителя жидкости, для отделения жидкой фазы из перемещаемой парожидкостной смеси в системах холодоснабжения?",
        answers: [
          "Допускается в случае использования аппаратов вертикального типа",
          "Допускается в обоснованных в проектной документации случаях",
          "Допускается в случае применения аппаратов горизонтального типа",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "Допускается в обоснованных в проектной документации случаях",
        ],
      },
      {
        code: "20108075",
        text: "Какой должна быть ширина центрального прохода для обслуживания оборудования у вновь строящихся и реконструируемых систем холодоснабжения?",
        answers: [
          "Не более 1,2 м",
          "Не менее 1,5 м",
          "Не менее 1,0 м",
          "Не более 0,8 м",
        ],
        correctAnswers: ["Не менее 1,5 м"],
      },
      {
        code: "20108076",
        text: "Какие условия должны соблюдаться при перемещении по трубопроводам застывающих продуктов и расплавов, способных кристаллизоваться?",
        answers: [
          "Перемещения должны осуществляться по обогреваемым трубопроводам",
          "Температура в трубопроводе должна быть не ниже +25 °С",
          "Перемещения по трубопроводам продуктов и расплавов, способных кристаллизоваться, не допускаются",
          "Вид обогрева и теплоносителя выбирают с учетом количества перемещаемых химических продуктов",
        ],
        correctAnswers: [
          "Перемещения должны осуществляться по обогреваемым трубопроводам",
        ],
      },
      {
        code: "20108077",
        text: "Каким должен быть диаметр стальных гильз из труб, используемых в местах прохода трубопроводов через стены или перекрытия здания?",
        answers: [
          "Внутренний диаметр гильз должен быть на 3 мм больше наружного диаметра трубопроводов",
          "Внутренний диаметр гильз с теплоизоляцией должен быть на 3 - 5 мм больше наружного диаметра трубопроводов",
          "Внутренний диаметр гильз с учетом тепловой изоляции должен быть на 5 - 10 мм больше наружного диаметра трубопроводов",
          "Внутренний диаметр гильз должен быть на 10 - 20 мм больше наружного диаметра трубопроводов (с учетом тепловой изоляции)",
        ],
        correctAnswers: [
          "Внутренний диаметр гильз должен быть на 10 - 20 мм больше наружного диаметра трубопроводов (с учетом тепловой изоляции)",
        ],
      },
      {
        code: "20108078",
        text: "Каким должен быть радиус кривизны отвода при изготовлении отводов способом гиба на специальных станках?",
        answers: [
          "Не менее трех диаметров трубы",
          "Не менее двух диаметров трубы",
          "Равен диаметру трубы",
          "Не менее четырех диаметров трубы",
        ],
        correctAnswers: ["Не менее трех диаметров трубы"],
      },
      {
        code: "20108079",
        text: "Что в химико-технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и их развития?",
        answers: [
          "Средства защиты от превышения давления, огневые предохранители",
          "Пожарные извещатели, аудиоусилители и дополнительные источники питания",
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления",
          "Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления",
        ],
      },
      {
        code: "20108080",
        text: "Что в технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?",
        answers: [
          "Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы",
          "Запорная арматура, средства защиты от превышения давления, огнепреградители",
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления",
          "Пожарные извещатели, аудиосистемы, устройства видеоконтроля",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления",
        ],
      },
      {
        code: "20108081",
        text: "Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей? Выберите 2 правильных варианта ответа.",
        answers: [
          "Системами регулирования заданного соотношения топлива, воздуха и водяного пара",
          "Блокировками, прекращающими поступление газообразного топлива и воздуха при снижении их давления ниже установленных параметров (автономно), а также при прекращении электро- (пневмо-) снабжения контрольно-измерительных приборов и средств автоматики",
          "Средствами ручного управления подачей водяного пара в топочное пространство и в змеевики при прогаре труб нагревательных печей",
          "Средствами контроля за температурой в топочном пространстве",
        ],
        correctAnswers: [
          "Системами регулирования заданного соотношения топлива, воздуха и водяного пара",
          "Блокировками, прекращающими поступление газообразного топлива и воздуха при снижении их давления ниже установленных параметров (автономно), а также при прекращении электро- (пневмо-) снабжения контрольно-измерительных приборов и средств автоматики",
        ],
      },
      {
        code: "20108082",
        text: "Чем обеспечивается противоаварийная автоматическая защита нагреваемых элементов (змеевиков) нагревательных печей? Выберите 2 правильных варианта ответа.",
        answers: [
          "Блокировками по отключению подачи топлива при превышении значения температуры нагреваемого жидкого продукта",
          "Средствами контроля и сигнализации за температурой нагреваемого жидкого продукта",
          "Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков",
          "Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции",
        ],
        correctAnswers: [
          "Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков",
          "Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции",
        ],
      },
      {
        code: "20108083",
        text: "Какой должна быть температура наружных поверхностей оборудования и кожухов теплоизоляционных покрытий в местах, доступных для обслуживающего персонала?",
        answers: [
          "Не более 45 °С внутри помещений и 60 °С на наружных установках",
          "Не более 40 °С внутри помещений и 70 °С на наружных установках",
          "Не более 35 °С внутри помещений и 65 °С на наружных установках",
          "Не более 50 °С внутри помещений и 55 °С на наружных установках",
        ],
        correctAnswers: [
          "Не более 45 °С внутри помещений и 60 °С на наружных установках",
        ],
      },
      {
        code: "20108084",
        text: "Что должно обеспечивать размещение технологического оборудования, трубопроводной арматуры в производственных зданиях и на открытых площадках? Выберите 2 правильных варианта ответа.",
        answers: [
          "Уменьшение взрывоопасности объекта путем равномерного распределения технологических блоков I категории взрывоопасности",
          "Возможность проведения ремонтных работ",
          "Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий",
          "Использование строительных конструкций зданий и сооружений в несущих элементах технологического оборудования",
        ],
        correctAnswers: [
          "Возможность проведения ремонтных работ",
          "Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий",
        ],
      },
      {
        code: "20108085",
        text: "С учетом каких критериев выбираются насосы и компрессоры, используемые для перемещения газов, легковоспламеняющихся и горючих жидкостей, согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств? Выберите 2 правильных варианта ответа.",
        answers: [
          "С учетом протяженности и способа прокладки трубопроводов",
          "С учетом физико-химических свойств перемещаемых продуктов",
          "С учетом регламентированных параметров технологического процесса",
          "С учетом диаметра труб и толщины стенок трубопроводов",
        ],
        correctAnswers: [
          "С учетом физико-химических свойств перемещаемых продуктов",
          "С учетом регламентированных параметров технологического процесса",
        ],
      },
      {
        code: "20108086",
        text: "Каким требованиям должны соответствовать насосы и компрессоры технологических блоков взрывопожароопасных производств, остановка которых при падении напряжения или кратковременном отключении электроэнергии может привести к отклонениям технологических параметров процесса до критических значений и развитию аварий?",
        answers: [
          "Насосы и компрессоры должны выбираться с учетом возможности их повторного автоматического пуска и оснащаться системами самозапуска электродвигателей",
          "Время срабатывания системы самозапуска должно соответствовать времени выхода параметров технологического процесса за предельно допустимые значения",
          "Насосы и компрессоры должны оснащаться маховиками для облегчения повторного самозапуска электродвигателей",
          "Насосы и компрессоры должны выбираться с учетом исключения возможности их повторного автоматического пуска",
        ],
        correctAnswers: [
          "Насосы и компрессоры должны выбираться с учетом возможности их повторного автоматического пуска и оснащаться системами самозапуска электродвигателей",
        ],
      },
      {
        code: "20108087",
        text: "Где допускается размещение фланцевых соединений на трубопроводах с пожаровзрывоопасными, токсичными и едкими веществами?",
        answers: [
          "В местах установки арматуры или подсоединения трубопроводов к аппаратам",
          "Над местами, предназначенными для прохода людей",
          "Над рабочими площадками",
          "В местах ввода в технологические здания и сооружения",
        ],
        correctAnswers: [
          "В местах установки арматуры или подсоединения трубопроводов к аппаратам",
        ],
      },
      {
        code: "20108088",
        text: "Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?",
        answers: [
          "Руководителем технологического процесса",
          "Разработчиком процесса",
          "Организацией, эксплуатирующей химико-технологическое производство",
          "Экспертной организацией",
        ],
        correctAnswers: ["Разработчиком процесса"],
      },
      {
        code: "20108089",
        text: "С учетом чего должна проектироваться, изготавливаться и эксплуатироваться система транспорта сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей посредством насосов?",
        answers: [
          "С учетом возможности проведения эффективной и безопасной очистки системы",
          "С учетом физико-химических свойств перемещаемых горючих веществ",
          "С учетом анализа эксплуатационных отказов для того, чтобы предотвратить возможность возникновения аварийных режимов",
        ],
        correctAnswers: [
          "С учетом анализа эксплуатационных отказов для того, чтобы предотвратить возможность возникновения аварийных режимов",
        ],
      },
      {
        code: "20108090",
        text: "На чем основаны оптимальные методы и средства противоаварийной автоматической защиты?",
        answers: [
          "На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления",
          "На обучающих программных продуктах, применяемых для моделирования аварийных ситуаций, утвержденных управлением Ростехнадзора",
          "На алгоритмах, разработанных по сценариям всех возможных аварий",
          "На сценариях возможных аварийных ситуаций и способах перевода объекта в безопасное состояние",
        ],
        correctAnswers: [
          "На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления",
        ],
      },
      {
        code: "20108091",
        text: "Что должны обеспечивать системы противоаварийной автоматической защиты и управления технологическими процессами? Выберите 2 правильных варианта ответа.",
        answers: [
          "Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса",
          "Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления",
          "Автоматический возврат технологического объекта в рабочее состояние после устранения причины срабатывания системы противоаварийной автоматической защиты",
          "Время срабатывания систем защиты должно соответствовать времени, необходимому для перехода параметра от предупредительного до предельно допустимого значения",
        ],
        correctAnswers: [
          "Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса",
          "Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления",
        ],
      },
      {
        code: "20108092",
        text: "Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития? Выберите 2 правильных варианта ответа.",
        answers: [
          "Запорную и запорно-регулирующую арматуру",
          "Клапаны, отсекающие и другие отключающие устройства",
          "Устройство автоматики разгрузки при коротких замыканиях",
          "Тормозные устройства на сетевых насосных агрегатах",
        ],
        correctAnswers: [
          "Запорную и запорно-регулирующую арматуру",
          "Клапаны, отсекающие и другие отключающие устройства",
        ],
      },
      {
        code: "20108093",
        text: "Какие требования предъявляются к пневматическим системам контроля, управления и противоаварийной автоматической защиты?",
        answers: [
          "Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги",
          "Качество сжатого воздуха необходимо проверять не реже 1 раза в месяц",
          "Для пневматических систем контроля, управления и противоаварийной защиты предусматриваются единые установки и единые сети сжатого воздуха",
          "Использование инертного газа для питания систем контрольно-измерительных приборов и устройств автоматического регулирования должно определяться спецификой объекта",
        ],
        correctAnswers: [
          "Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги",
        ],
      },
      {
        code: "20108094",
        text: "В течение какого времени буферные емкости (реципиенты) должны обеспечивать питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров?",
        answers: [
          "Время должно быть подтверждено расчетом, но не менее 20 минут",
          "Время должно быть подтверждено расчетом, но не менее 35 минут",
          "Время должно быть подтверждено расчетом, но не более 45 минут",
          "В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
        correctAnswers: [
          "В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20108095",
        text: "Что должно быть учтено при проектировании программного обеспечения противоаварийной защиты технологической аппаратуры реакционных процессов?",
        answers: [
          "Использование средств ручного регулирования для контроля опасных параметров во всех системах управления реакционными процессами",
          "Риски срабатывания автоматических систем противоаварийной защиты",
          "Автоматический запуск систем противоаварийной защиты после срабатывания",
          "Работа сигнализации, срабатывающей при выходе параметров за допустимые значения",
        ],
        correctAnswers: [
          "Риски срабатывания автоматических систем противоаварийной защиты",
        ],
      },
      {
        code: "20108096",
        text: "Исходя из чего осуществляется проектирование системы противоаварийной автоматической защиты и выбор ее элементов?",
        answers: [
          "Исходя из условий обеспечения работы системы в процессе обслуживания в течение года",
          "Исходя из условий обеспечения работы системы в процессе эксплуатации в течение 3 лет",
          "Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта",
          "Исходя из условий обеспечения работы системы только в процессе ремонта",
        ],
        correctAnswers: [
          "Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта",
        ],
      },
      {
        code: "20108097",
        text: "Какое минимальное количество датчиков должно устанавливаться на химически опасных производственных объектах I и II классов опасности для осуществления контроля за текущими показателями параметров, определяющими химическую опасность технологических процессов ХОПО?",
        answers: [
          "Не менее 5 независимых датчиков с раздельными точками отбора",
          "Не менее 2 независимых датчиков с раздельными точками отбора",
          "Не менее 3 независимых датчиков с раздельными точками отбора",
          "Количество датчиков требованиями безопасности не регламентируется",
        ],
        correctAnswers: [
          "Не менее 2 независимых датчиков с раздельными точками отбора",
        ],
      },
      {
        code: "20108098",
        text: "При достижении какой концентрации обращающихся веществ в воздухе анализаторных помещений должно происходить автоматическое включение аварийной вентиляции?",
        answers: [
          "При 20 % НКПР",
          "При 18 % НКПР",
          "При 15 % НКПР",
          "При 10 % НКПР",
        ],
        correctAnswers: ["При 20 % НКПР"],
      },
      {
        code: "20108099",
        text: "В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?",
        answers: [
          "В течение времени, указанного в проектной документации",
          "В течение 24 часов",
          "В течение 8 часов",
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
        correctAnswers: [
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
      },
      {
        code: "20108100",
        text: "В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечить способность функционирования средств противоаварийной защиты?",
        answers: [
          "В течение 12 часов",
          "В течение 8 часов",
          "В течение 2 часов",
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
        correctAnswers: [
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
      },
      {
        code: "20108101",
        text: "Каким требованиям должны соответствовать системы общеобменной и аварийной вытяжной вентиляции? Выберите 2 правильных варианта ответа.",
        answers: [
          "Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания",
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
          "Воздухозабор для приточных систем вентиляции необходимо предусматривать из любых возможных мест при всех режимах работы производства",
          "Общеобменная вентиляция должна включаться при срабатывании установленных в помещении сигнализаторов довзрывных концентраций",
        ],
        correctAnswers: [
          "Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания",
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
        ],
      },
      {
        code: "20108102",
        text: "В каком случае системы аварийной вентиляции должны включаться автоматически? Выберите 2 правильных варианта ответа.",
        answers: [
          "При срабатывании сигнализаторов довзрывных концентраций",
          "При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
          "При поступлении сигнала от датчиков повышения температуры",
          "При поступлении сигнала от датчиков контроля за содержанием взрывопожароопасной пыли в воздухе помещения",
        ],
        correctAnswers: [
          "При срабатывании сигнализаторов довзрывных концентраций",
          "При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
        ],
      },
      {
        code: "20108103",
        text: "По какой категории надежности должно осуществляться электроснабжение химически опасных производственных объектов?",
        answers: [
          "По I или II категории надежности",
          "По II или III категории надежности",
          "Категория надежности устанавливается разработчиком проекта в зависимости от применяемой технологии",
          "Только по I категории надежности",
        ],
        correctAnswers: ["По I или II категории надежности"],
      },
      {
        code: "20108104",
        text: "Каким из перечисленных способов не допускается прокладка кабелей по территории предприятий и установок?",
        answers: [
          "Только по территории технологических установок и производств",
          "Только в каналах, засыпанных песком, и траншеях",
          "Только на кабельных конструкциях технологических эстакад",
          "Допускаются все перечисленные способы прокладки",
        ],
        correctAnswers: ["Допускаются все перечисленные способы прокладки"],
      },
      {
        code: "20108105",
        text: "Что должно быть учтено в системах управления и защиты электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?",
        answers: [
          "Должна быть обеспечена селективность защит на устройствах электроснабжающей и электропотребляющей организаций",
          "Наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения",
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
          "Должна быть обеспечена возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой",
        ],
        correctAnswers: [
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
        ],
      },
      {
        code: "20108106",
        text: "Что должно быть учтено в системах управления и защит электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?",
        answers: [
          "Наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения",
          "Должна быть обеспечена возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой",
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
          "Должна быть обеспечена селективность защит на устройствах электроснабжающей и электропотребляющей организаций",
        ],
        correctAnswers: [
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
        ],
      },
      {
        code: "20108107",
        text: "Какие требования необходимо выполнять при прокладке кабелей по территории технологических установок? Выберите 2 правильных варианта ответа.",
        answers: [
          "Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение",
          "Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов",
          "Запрещается прокладка кабелей в каналах, засыпанных песком",
          "Провода и кабели с полиэтиленовой оболочкой должны прокладываться только на верхних ярусах технологических эстакад",
        ],
        correctAnswers: [
          "Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение",
          "Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов",
        ],
      },
      {
        code: "20108108",
        text: "В соответствии с какими требованиями должно выполняться проектирование систем водопровода и канализации взрывопожароопасных производств?",
        answers: [
          "Только в соответствии с требованиями технических регламентов",
          "Только в соответствии с требованиями законодательства о градостроительной деятельности",
          "Только в соответствии с требованиями Правил взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств",
          "В соответствии со всеми перечисленными требованиями",
        ],
        correctAnswers: ["В соответствии со всеми перечисленными требованиями"],
      },
      {
        code: "20108109",
        text: "Каков порядок сброса химически загрязненных стоков от отдельных технологических объектов в магистральную сеть канализации?",
        answers: [
          "Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть",
          "Порядок сброса стоков в магистральную сеть канализации устанавливается организацией",
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны быть оборудованы устройствами для улавливания аварийных стоков",
        ],
        correctAnswers: [
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
        ],
      },
      {
        code: "20108110",
        text: "Каких значений не должна превышать максимальная температура поверхностей нагрева систем отопления в помещениях, имеющих взрывопожароопасные зоны?",
        answers: [
          "80 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "85 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "90 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "95 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
        correctAnswers: [
          "80 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
      },
      {
        code: "20108111",
        text: 'Какие из перечисленных помещений могут быть отнесены к категории "Д" в соответствии с нормами технологических регламентов, если при принятых технологических и объемно-планировочных проектных решениях концентрация аммиака в воздухе камеры не превысит нижнего предела взрывоопасности при аварийном раскрытии?',
        answers: [
          "Только помещения производственных цехов - потребителей холода, в технологическом оборудовании которых обращается аммиак",
          "Только помещения для установки распределительных устройств, размещаемые вблизи от потребителей холода",
          "Только помещения камер с непосредственным охлаждением",
          "Все перечисленные помещения",
        ],
        correctAnswers: ["Все перечисленные помещения"],
      },
      {
        code: "20108112",
        text: "Какие расстояния устанавливаются между зданиями, в которых размещены машинные, аппаратные отделения, и другими сооружениями на площадке холодопотребляющей организации?",
        answers: [
          "Не менее 10 м",
          "Не менее 5 м",
          "Расстояния устанавливаются в соответствии с требованиями технических регламентов",
          "Расстояния определяются в соответствии с требованиями Правил безопасности химически опасных производственных объектов",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20108113",
        text: "В каком случае для постоянного обслуживания оборудования (арматуры) аммиачных холодильных установок должна быть устроена металлическая площадка с ограждением и лестницей?",
        answers: [
          "При обслуживании оборудования на уровне выше 1,8 м от пола",
          "При обслуживании оборудования на уровне 1,5 м от пола",
          "При обслуживании оборудования на уровне 1,2 м от пола",
          "При обслуживании оборудования на уровне 1,0 м от пола и выше",
        ],
        correctAnswers: [
          "При обслуживании оборудования на уровне выше 1,8 м от пола",
        ],
      },
      {
        code: "20108114",
        text: "Для постоянного обслуживания какого оборудования (арматуры) должна быть устроена металлическая площадка с ограждением и лестницей?",
        answers: [
          "Расположенного на уровне не ниже 1,5 м от пола",
          "Расположенного на уровне выше 1,8 м от пола",
          "Расположенного на уровне выше 2 м от пола",
          "Расположенного на уровне, указанном в проектной документации для каждого оборудования",
        ],
        correctAnswers: ["Расположенного на уровне выше 1,8 м от пола"],
      },
      {
        code: "20108115",
        text: "В какой документации должны быть обоснованы принятые решения по размещению и оснащению помещений, в которых размещено аммиачное оборудование и инженерные системы?",
        answers: [
          "В эксплуатационной документации",
          "В технических регламентах",
          "В проектной документации",
          "В планах производства работ",
        ],
        correctAnswers: ["В проектной документации"],
      },
      {
        code: "20108116",
        text: "В каком месте может размещаться оборудование, работающее на аммиаке?",
        answers: [
          "Только в помещении потребителей холода",
          "Только в специальном помещении - машинном или аппаратном отделении",
          "Только на открытой площадке",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20108117",
        text: "Каким образом допускается прокладка аммиачных трубопроводов?",
        answers: [
          "Через вентиляционные камеры",
          "В бытовых и подсобных помещениях",
          "В горизонтальных проходных или непроходных каналах",
          "Через лестничные клетки",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20108118",
        text: "Какое из перечисленных утверждений к прокладке аммиачных трубопроводов указано верно? Укажите все правильные ответы.",
        answers: [
          "Допускается прокладка аммиачных трубопроводов по глухим стенам",
          "Допускается прокладка аммиачных трубопроводов над частями зданий и сооружений, в которых размещено холодильное и технологическое оборудование с непосредственным охлаждением",
          "Допускается прокладка аммиачных трубопроводов над всеми частями зданий и сооружений",
          "Допускается прокладка аммиачных трубопроводов по наружным стенам производственной части здания с дверными и оконными проемами",
        ],
        correctAnswers: [
          "Допускается прокладка аммиачных трубопроводов по глухим стенам",
          "Допускается прокладка аммиачных трубопроводов над частями зданий и сооружений, в которых размещено холодильное и технологическое оборудование с непосредственным охлаждением",
        ],
      },
      {
        code: "20108119",
        text: "Какие устройства применяются в качестве предохранительных на аммиачных холодильных установках?",
        answers: [
          "Предохранительные клапаны прямого действия",
          "Только мембранные предохранительные устройства",
          "Только пружинные предохранительные клапаны",
          "Пружинные предохранительные клапаны и мембранные предохранительные устройства",
          "Предохранительные клапаны с мембранным чувствительным элементом",
        ],
        correctAnswers: [
          "Пружинные предохранительные клапаны и мембранные предохранительные устройства",
        ],
      },
      {
        code: "20108120",
        text: "В каком месте не допускается размещать запорную и регулирующую арматуру, устанавливаемые на аммиачных трубопроводах?",
        answers: [
          "Только в холодильных камерах",
          "Только над проходами для обслуживания оборудования",
          "Только над дверными проемами",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20108121",
        text: "В какой цвет должны быть окрашены участки аммиачных трубопроводов, на которые наносятся опознавательные кольца?",
        answers: [
          "В белый цвет",
          "В желтый цвет",
          "В красный цвет",
          "В черный цвет",
        ],
        correctAnswers: ["В желтый цвет"],
      },
      {
        code: "20108122",
        text: "На каких из перечисленных участках аммиачных трубопроводов должны быть нанесены три опознавательных кольца?",
        answers: [
          "На паровых линиях стороны высокого давления",
          "На паровых, парожидкостных линиях стороны низкого давления систем холодоснабжения",
          "На жидкостных линиях стороны высокого давления",
          "На жидкостных линиях стороны низкого давления систем холодоснабжения",
        ],
        correctAnswers: ["На жидкостных линиях стороны высокого давления"],
      },
      {
        code: "20108123",
        text: "Какое из перечисленных требований к системе вентиляции аммиачных холодильных установок указано верно? Укажите все правильные ответы.",
        answers: [
          "Машинные, аппаратные отделения, а также конденсаторные отделения и распредустройства, располагаемые в помещениях, должны быть оборудованы системами приточно-вытяжной и аварийной вытяжной механической вентиляции",
          "Удаляемый воздух может выбрасываться в атмосферу без очистки",
          "Располагаемые в помещениях конденсаторные отделения и распредустройства должны быть оборудованы только приточно-вытяжной вентиляцией",
          "Кратность воздухообмена должна определяться техническим регламентом",
        ],
        correctAnswers: [
          "Машинные, аппаратные отделения, а также конденсаторные отделения и распредустройства, располагаемые в помещениях, должны быть оборудованы системами приточно-вытяжной и аварийной вытяжной механической вентиляции",
          "Удаляемый воздух может выбрасываться в атмосферу без очистки",
        ],
      },
      {
        code: "20108124",
        text: "Какие материалы применяются для изготовления, монтажа и ремонта технологического оборудования и трубопроводов для производств, использующих неорганические кислоты и щелочи?",
        answers: [
          "Полимерные материалы",
          "Композитные материалы",
          "Материалы, обеспечивающие коррозионную стойкость к рабочей среде",
          "Стальные нержавеющие материалы",
        ],
        correctAnswers: [
          "Материалы, обеспечивающие коррозионную стойкость к рабочей среде",
        ],
      },
      {
        code: "20108125",
        text: "Какие требования предъявляются к трубопроводам для транспортировки кислот и щелочей, прокладываемым по эстакадам? Выберите 2 правильных варианта ответа.",
        answers: [
          "Трубопроводы должны быть защищены от падающих предметов",
          "Трубопроводы должны быть защищены от возможных ударов со стороны транспортных средств",
          "Трубопроводы для транспортировки кислот и щелочей должны размещаться под легкосбрасываемым навесом",
          "При многоярусной прокладке трубопроводы кислот и щелочей следует располагать на верхних ярусах",
        ],
        correctAnswers: [
          "Трубопроводы должны быть защищены от падающих предметов",
          "Трубопроводы должны быть защищены от возможных ударов со стороны транспортных средств",
        ],
      },
      {
        code: "20108126",
        text: "Где допускается прокладка трубопроводов неорганических жидких кислот и (или) щелочей при условии, что трубопроводы должны быть заключены в специальные желоба или короба (коллекторы) с отводом утечек кислот и щелочей в безопасные места, определяемые проектом?",
        answers: [
          "Через бытовые и подсобные помещения",
          "По наружным стенам зданий, не связанных с обращением кислот и щелочей",
          "В местах пересечения железных и автомобильных дорог",
          "Через административные помещения",
        ],
        correctAnswers: ["В местах пересечения железных и автомобильных дорог"],
      },
      {
        code: "20108127",
        text: "Какой ширины предусматривается охранная зона межзаводского трубопровода кислот или щелочей, прокладываемого вне территории предприятия?",
        answers: [
          "Не менее 1,5 м с каждой его стороны",
          "Не менее 2 м с каждой его стороны",
          "Не менее 1,8 м с каждой его стороны",
          "Не менее 1,2 м с каждой его стороны",
        ],
        correctAnswers: ["Не менее 2 м с каждой его стороны"],
      },
      {
        code: "20108128",
        text: "Каким образом определяется минимально допустимое расстояние от складов кислот и щелочей до взрывоопасных объектов?",
        answers: [
          "Расстояние определяется в соответствии с нормами пожарной безопасности",
          "Расстояние определяется в соответствии с требованиями строительных норм и правил и с учетом расчетного радиуса опасной зоны",
          "Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения, должно обеспечивать устойчивость зданий складов к воздействию данных факторов",
          "Расстояние устанавливается в соответствии с технологическим регламентом на производство работ",
        ],
        correctAnswers: [
          "Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения, должно обеспечивать устойчивость зданий складов к воздействию данных факторов",
        ],
      },
      {
        code: "20108129",
        text: "Что необходимо предусматривать на складах, пунктах слива-налива, расположенных на открытых площадках, где в условиях эксплуатации возможно поступление в воздух рабочей зоны паров кислот и щелочей, для обеспечения требований безопасности?",
        answers: [
          "Только включение световой и звуковой сигнализации при превышении показателей концентрации в рабочей зоне помещения управления, на складах, в пунктах слива-налива, расположенных на открытых площадках",
          "Только регистрацию приборами всех случаев загазованности",
          "Только автоматический контроль с сигнализацией превышения предельно допустимых концентраций",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20108130",
        text: "Какой должна быть высота защитного ограждения каждой группы резервуаров по отношению к уровню расчетного объема разлившейся жидкости?",
        answers: [
          "На 0,2 м выше",
          "На 0,15 м выше",
          "На 0,1 м выше",
          "На 0,05 м выше",
        ],
        correctAnswers: ["На 0,2 м выше"],
      },
      {
        code: "20108131",
        text: "Каким требованиям должны соответствовать вновь проектируемые склады кислот, в которых возможно образование первичного кислотного облака? Выберите 2 правильных варианта ответа.",
        answers: [
          "Склады должны располагаться в более высоких местах по отношению к другим зданиям и сооружениям",
          "Склады должны располагаться с наветренной стороны преобладающих направлений ветров относительно места расположения ближайших населенных пунктов",
          "На территории складов должен быть установлен указатель направления ветра, видимый из любой точки территории склада",
          "На территории складов должен быть обеспечен автоматический контроль за уровнем загазованности и сигнализация об аварийных утечках",
        ],
        correctAnswers: [
          "На территории складов должен быть установлен указатель направления ветра, видимый из любой точки территории склада",
          "На территории складов должен быть обеспечен автоматический контроль за уровнем загазованности и сигнализация об аварийных утечках",
        ],
      },
      {
        code: "20108132",
        text: "Куда допускается сброс неорганических жидких кислот и (или) щелочей от предохранительных клапанов?",
        answers: [
          "В ливневую канализацию",
          "В специальные емкости",
          "В рабочую зону",
          "Допускается сброс во все перечисленные места в случаях, обоснованных техническим регламентом",
        ],
        correctAnswers: ["В специальные емкости"],
      },
      {
        code: "20108133",
        text: "Где должны быть установлены манометры (мановакуумметры) в целях обеспечения безопасности ведения технологических процессов в системах холодоснабжения на холодильном оборудовании и машинах?",
        answers: [
          "Только на компрессоре для наблюдения за рабочими давлениями всасывания, нагнетания",
          "Только на жидкостных и оттаивательных коллекторах распределительных аммиачных устройств, соединенных трубопроводами с оборудованием холодильных камер",
          "Только в картере поршневых компрессоров, не имеющих уравнивания между всасыванием и картером",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20108134",
        text: "В каком случае допускается оснащать сосуды холодильных систем одним предохранительным клапаном?",
        answers: [
          "Если это указано в технологической документации",
          "Если геометрический объем сосуда не превышает 0,3 м3",
          "Если предохранительное устройство рассчитано на полную пропускную способность",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "Если геометрический объем сосуда не превышает 0,3 м3",
        ],
      },
      {
        code: "20108135",
        text: "Каким должен быть внутренний диаметр стальных гильз из труб, используемых для прокладки трубопроводов через стены или перекрытия здания?",
        answers: [
          "На 3 мм больше наружного диаметра трубопроводов (с учетом тепловой изоляции)",
          "На 3 - 5 мм больше наружного диаметра трубопроводов (с учетом тепловой изоляции)",
          "На 5 - 10 мм больше наружного диаметра трубопроводов (с учетом тепловой изоляции)",
          "На 10 - 20 мм больше наружного диаметра трубопроводов (с учетом тепловой изоляции)",
        ],
        correctAnswers: [
          "На 10 - 20 мм больше наружного диаметра трубопроводов (с учетом тепловой изоляции)",
        ],
      },
      {
        code: "20108136",
        text: "Какой условный диаметр должен быть у дренажного клапана, устанавливаемого на всасывающих и нагнетательных аммиачных трубопроводах для отвода масла и конденсата?",
        answers: [
          "Выбирается из размера (диаметра) основного трубопровода с учетом возможной производительности по сливу",
          "Не менее 22 мм",
          "Не менее 17 мм",
          "Не менее 12 мм",
        ],
        correctAnswers: [
          "Выбирается из размера (диаметра) основного трубопровода с учетом возможной производительности по сливу",
        ],
      },
      {
        code: "20108137",
        text: "Какое из перечисленных требований к трубе, выпускающей пары аммиака в атмосферу через предохранительные устройства, указано верно?",
        answers: [
          "Устье трубы для выпуска аммиака должно быть направлено вниз, труба должна быть защищена от скопления атмосферных осадков",
          "Внутренний диаметр трубы, отводящей пары аммиака, по всей длине должен быть меньше внутреннего диаметра выходного патрубка предохранительного устройства",
          "Труба должна быть выведена на 3 м выше конька крыши наиболее высокого здания в радиусе 50 м, но не менее 6 м от уровня территории (земли) и не менее 3 м от площадок обслуживания, находящихся в радиусе 15 м",
          "Присоединение предохранительных устройств к общей отводящей трубе, поперечное сечение которой должно быть не менее 100 % суммы сечений для 1 - 2 отводящих труб и не менее 50 % суммы сечений отдельных отводящих труб, допускается, если число отводящих труб более 2",
        ],
        correctAnswers: [
          "Труба должна быть выведена на 3 м выше конька крыши наиболее высокого здания в радиусе 50 м, но не менее 6 м от уровня территории (земли) и не менее 3 м от площадок обслуживания, находящихся в радиусе 15 м",
        ],
      },
      {
        code: "20108138",
        text: "Какая предельно допустимая концентрация аммиака в воздухе рабочей зоны помещений и вне помещений, у мест установки датчиков соответствует I уровню контроля концентрации аммиака в воздухе?",
        answers: [
          "Равная 20 мг/м3",
          "Равная 40 мг/м3",
          "Равная 120 мг/м3",
          "Равная 80 мг/м3",
        ],
        correctAnswers: ["Равная 20 мг/м3"],
      },
      {
        code: "20108139",
        text: "Какими приборами могут быть оснащены сепараторы, устанавливаемые на всасывающей линии компрессора, вакуум-насоса, газодувки для отделения жидкой фазы из перемещаемой газовой среды?",
        answers: [
          "Только приборами контроля уровня",
          "Только сигнализацией по максимальному уровню",
          "Только средствами блокировки",
          "Всеми перечисленными",
        ],
        correctAnswers: ["Всеми перечисленными"],
      },
      {
        code: "20108140",
        text: "Какой должна быть скорость воздуха в воронках местных отсосов аспирационных воздуховодов?",
        answers: [
          "Не выше 1 м/с",
          "Не выше 2 м/с",
          "Не выше 3 м/с",
          "Не выше 4 м/с",
        ],
        correctAnswers: ["Не выше 2 м/с"],
      },
      {
        code: "20108141",
        text: "Какой минимальный уклон должны иметь внутрицеховые трубопроводы для фосфора?",
        answers: ["0,02", "0,1", "0,002", "0,05", "Все ответы неверны"],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20108142",
        text: "Какой минимальный уклон должны иметь внутрицеховые трубопроводы для пятисернистого фосфора?",
        answers: ["0,005", "0,02", "0,1", "0,002"],
        correctAnswers: ["0,1"],
      },
      {
        code: "20108143",
        text: "На сколько должны быть заглублены полуподземные резервуары и хранилища фосфора?",
        answers: [
          "На уровень, обеспечивающий вместимость не менее 50 % хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,2 м",
          "На уровень, обеспечивающий вместимость не менее 40 % хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,1 м",
          "На уровень, обеспечивающий вместимость не менее 30 % хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,1 м",
          "На уровень, обеспечивающий вместимость не менее 40 % хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,2 м",
        ],
        correctAnswers: [
          "На уровень, обеспечивающий вместимость не менее 50 % хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,2 м",
        ],
      },
      {
        code: "20108144",
        text: "При какой температуре поверхности аппаратов, находящихся в помещении, должны быть теплоизолированы несгораемыми материалами при производстве фосфора и его соединений?",
        answers: [
          "35 °C и выше",
          "45 °C и выше",
          "25 °C и выше",
          "40 °C и выше",
        ],
        correctAnswers: ["45 °C и выше"],
      },
      {
        code: "20108145",
        text: "Какая величина избыточного давления должна постоянно быть в системе электровозгонки фосфора?",
        answers: [
          "Не более 3 мм водяного столба",
          "Не более 5 мм водяного столба",
          "Не менее 3 мм водяного столба",
          "Не менее 5 мм водяного столба",
        ],
        correctAnswers: ["Не менее 3 мм водяного столба"],
      },
      {
        code: "20108146",
        text: "Какое требование к условиям проведения реакции синтеза, процесса охлаждения и размола пятисернистого фосфора указано верно?",
        answers: [
          "Должны проводиться в закрытой таре",
          "Должны проводиться в охлаждаемых аппаратах, снабженных устройствами для отвода тепла",
          "Должны проводиться в аппаратах, обогреваемых паром",
          "Должны проводиться в герметичных аппаратах в атмосфере инертного газа",
        ],
        correctAnswers: [
          "Должны проводиться в герметичных аппаратах в атмосфере инертного газа",
        ],
      },
      {
        code: "20108147",
        text: "На какой высоте должны быть ограждения в местах прохода людей и проезда транспорта под подвесными конвейерами и транспортерами при производстве фосфора и его соединений?",
        answers: [
          "Не более 2,2 м",
          "Не менее 3,4 м",
          "Не менее 2,2 м",
          "Не более 3,4 м",
        ],
        correctAnswers: ["Не менее 2,2 м"],
      },
      {
        code: "20108148",
        text: "Какая периодичность осмотра технического состояния трубок конденсаторов объектов производств растительных масел путем осмотра со вскрытием крышек?",
        answers: [
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в год",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "20108149",
        text: "В каком случае должна производиться чистка трубок конденсаторов объектов производств растительных масел?",
        answers: [
          "В случае наступления срока, установленного техническим регламентом",
          "В случае выполнения работ по графику, определенному руководителем организации",
          "В случае решения лица, ответственного за безопасное производство работ",
          "В установленных проектной документацией и документацией завода-изготовителя случаях",
        ],
        correctAnswers: [
          "В установленных проектной документацией и документацией завода-изготовителя случаях",
        ],
      },
      {
        code: "20108150",
        text: "Какие действия, осуществление которых необходимо при остановке чанного испарителя (тостера) объектов производств растительных масел, указаны неверно?",
        answers: [
          "Отключается подача глухого пара",
          "Проверяется целостность контура заземления",
          "Подается острый пар во все чаны перед открытием люков тостера",
          "Производится тщательная зачистка чанов от остатков шрота при разгрузке тостера",
        ],
        correctAnswers: ["Проверяется целостность контура заземления"],
      },
      {
        code: "20108151",
        text: "Где размещают лаковыпускные отделения в производстве лаков на конденсационных смолах?",
        answers: [
          "Только в изолированном помещении корпуса синтеза",
          "Только в отдельно стоящем корпусе",
          "Только на открытых площадках",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20108152",
        text: "В каком количестве допускается хранение в цехе суховальцованных паст для подколеровки эмалей?",
        answers: [
          "Не более 2 % сменной потребности",
          "Не более 3 % сменной потребности",
          "Не более 4 % сменной потребности",
          "Не более 5 % сменной потребности",
        ],
        correctAnswers: ["Не более 2 % сменной потребности"],
      },
      {
        code: "20108153",
        text: "На каком максимальном расстоянии от верха опоры должны быть расположены обслуживающие площадки высоких опор вращающихся печей и сушилок в лакокрасочном производстве?",
        answers: ["200 мм", "300 мм", "400 мм", "500 мм"],
        correctAnswers: ["300 мм"],
      },
      {
        code: "20108154",
        text: "Какое из перечисленных требований к образованию, хранению, транспортированию, уничтожению лаков и красок указано неверно?",
        answers: [
          "В помещениях, где возможно скапливание пыли, имеющиеся приводы аппаратов следует выполнять на одном валу с электродвигателем",
          "В отдельных случаях при установке типового оборудования разрешается применять клиноременные передачи с ремнями из электропроводящей резины",
          "В отдельных случаях допускается отвод продуктов сгорания в один боров от агрегатов, работающих на разных видах топлива",
          "Все перечисленные требования указаны верно",
        ],
        correctAnswers: [
          "В отдельных случаях допускается отвод продуктов сгорания в один боров от агрегатов, работающих на разных видах топлива",
        ],
      },
      {
        code: "20108155",
        text: "Чем следует оборудовать производственные помещения без постоянных рабочих мест согласно Правилам безопасности при производстве, хранении, транспортировании и применении хлора?",
        answers: [
          "Только световой сигнализацией превышения уровня загазованности хлором, установленной снаружи у входа в помещения",
          "Только аварийной или общеобменной вентиляционными системами",
          "Только передвижными вентиляционно-отопительными устройствами для обогрева помещений при производстве в них ремонтных работ",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20108156",
        text: "Каким документом обосновывается минимально необходимое количество жидкого хлора, которое должно храниться в организациях?",
        answers: [
          "Стандартом предприятия",
          "Планом производства работ",
          "Проектом",
          "Технологическим регламентом",
        ],
        correctAnswers: ["Проектом"],
      },
      {
        code: "20108157",
        text: "Какое из перечисленных требований при производстве жидкого хлора указано неверно?",
        answers: [
          "Воздух (азот) для разбавления абгазов, поступающих на вторую стадию сжижения хлора, необходимо осушать",
          "Объемная доля водорода в абгазах конденсации должна быть не более 4 %",
          "Попадание абгазов конденсации в приемники жидкого хлора допускается не выше допустимых значений",
          "Давление воздуха, подаваемого в систему сжижения, должно превышать давление подаваемого хлоргаза не менее чем на 0,1 МПа (1 кгс/см2)",
        ],
        correctAnswers: [
          "Попадание абгазов конденсации в приемники жидкого хлора допускается не выше допустимых значений",
        ],
      },
      {
        code: "20108158",
        text: "Чем следует оборудовать производственные помещения без постоянных рабочих мест согласно Правилам безопасности производств хлора и хлорсодержащих сред?",
        answers: [
          "Только аварийной или общеобменной вентиляционными системами",
          "Только передвижными вентиляционно-отопительными устройствами для обогрева помещений при производстве ремонтных работ в них",
          "Только световой сигнализацией превышения уровня загазованности хлором, установленной снаружи у входа",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20108159",
        text: "При какой концентрации хлора в воздухе допускается применение средств индивидуальной защиты органов дыхания фильтрующих?",
        answers: [
          "Не более 1,5 % по объему",
          "Не более 0,5 % по объему",
          "Не более 1,0 % по объему",
          "Не более 2,5 % по объему",
        ],
        correctAnswers: ["Не более 0,5 % по объему"],
      },
      {
        code: "20108160",
        text: "Какой принимается радиус опасной зоны для складов жидкого хлора?",
        answers: [
          "Определяется проектом",
          "Принимается равным 300 м",
          "Принимается равным 450 м",
          "Принимается в пределах глубины распространения хлорного облака с поражающей концентрацией (определяется расчетом)",
        ],
        correctAnswers: [
          "Принимается в пределах глубины распространения хлорного облака с поражающей концентрацией (определяется расчетом)",
        ],
      },
      {
        code: "20108161",
        text: "Каким принимается радиус опасной зоны для складов жидкого хлора?",
        answers: [
          "Радиус опасной зоны определяется проектом",
          "Радиус опасной зоны определяется расчетом, исходя из глубины распространения хлорного облака с поражающей концентрацией",
          "Радиус опасной зоны принимается равным 300 м",
          "Радиус опасной зоны принимается равным 450 м",
        ],
        correctAnswers: [
          "Радиус опасной зоны определяется расчетом, исходя из глубины распространения хлорного облака с поражающей концентрацией",
        ],
      },
      {
        code: "20108162",
        text: "Каким принимают радиус опасной зоны для складов жидкого хлора?",
        answers: [
          "В пределах распространения хлорного облака со средней концентрацией, но не более 1000 м",
          "В пределах глубины распространения хлорного облака с поражающей концентрацией (определяется расчетом)",
          "В пределах глубины распространения хлорного облака с минимальной концентрацией (определяется по факту распространения)",
          "В пределах распространения хлорного облака со средней концентрацией, но не менее 100 м",
        ],
        correctAnswers: [
          "В пределах глубины распространения хлорного облака с поражающей концентрацией (определяется расчетом)",
        ],
      },
      {
        code: "20108163",
        text: "Какую функцию не должен обеспечивать кустовой склад хлора?",
        answers: [
          "Выполнение заявок потребителей на отправку хлора",
          "Организацию сбора порожней тары и ее упорядоченный возврат организации-наполнителю",
          "Розлив хлора в контейнеры и баллоны",
          "Прием, хранение затаренного хлора с учетом повагонных поставок хлора",
        ],
        correctAnswers: ["Розлив хлора в контейнеры и баллоны"],
      },
      {
        code: "20108164",
        text: "Каким должно быть количество жидкого хлора, хранящегося в организациях-потребителях в стационарных емкостях и хлорной транспортной таре?",
        answers: [
          "Количество жидкого хлора должно быть согласовано с территориальным управлением Ростехнадзора",
          "Количество жидкого хлора должно быть максимальным для обеспечения производственного цикла",
          "Количество жидкого хлора не должно превышать месячного потребления его организацией",
          "Количество жидкого хлора должно быть минимально необходимым для обеспечения производственного цикла",
        ],
        correctAnswers: [
          "Количество жидкого хлора должно быть минимально необходимым для обеспечения производственного цикла",
        ],
      },
      {
        code: "20108165",
        text: "Какие требования установлены в Правилах безопасности производств хлора и хлорсодержащих сред для закрытых складов жидкого хлора?",
        answers: [
          "Склады хлора должны располагаться в соответствии со строительными нормами и правилами",
          "Склады хлора должны располагаться только в подземных сооружениях",
          "Склады хлора должны быть расположены в наземных и полузаглубленных одноэтажных зданиях или подземных сооружениях",
          "Склады хлора должны располагаться только в контейнерах-цистернах под навесом",
        ],
        correctAnswers: [
          "Склады хлора должны быть расположены в наземных и полузаглубленных одноэтажных зданиях или подземных сооружениях",
        ],
      },
      {
        code: "20108166",
        text: "Какие требования предъявляются к размещению сосудов с хлором на складах при вертикальной укладке?",
        answers: [
          "У стен следует размещать не более 2 рядов баллонов и 2 рядов контейнеров, в проходах соответственно - 4 и 3 ряда, необходимо обеспечить свободный доступ к блокировочным устройствам",
          "У стен следует размещать не более 2 рядов баллонов и 1 ряд контейнеров, в проходах соответственно - 4 и 2 ряда, необходимо исключить возможность их падения или перемещения и обеспечивать свободный доступ к запорным вентилям",
          "У стен следует размещать не более 4 рядов баллонов и 3 рядов контейнеров, размещение сосудов в проходах запрещено",
          "У стен следует размещать не более 3 рядов баллонов и 2 рядов контейнеров, в проходах соответственно - 4 и 3 ряда",
        ],
        correctAnswers: [
          "У стен следует размещать не более 2 рядов баллонов и 1 ряд контейнеров, в проходах соответственно - 4 и 2 ряда, необходимо исключить возможность их падения или перемещения и обеспечивать свободный доступ к запорным вентилям",
        ],
      },
      {
        code: "20108167",
        text: "В течение какого срока выдерживаются на складе вновь скомплектованные партии наполненных жидким хлором контейнеров или баллонов?",
        answers: [
          "Не менее двух суток",
          "Не менее трех суток",
          "Не менее одних суток",
          "Срок не регламентируется",
        ],
        correctAnswers: ["Не менее одних суток"],
      },
      {
        code: "20108168",
        text: "Каким должно быть расчетное давление сосудов, содержащих жидкий хлор?",
        answers: [
          "Не менее 1 МПа",
          "Менее 1,2 МПа",
          "Не менее 1,4 МПа",
          "Не менее 1,6 МПа",
        ],
        correctAnswers: ["Не менее 1,6 МПа"],
      },
      {
        code: "20108169",
        text: "Какую вентиляцию следует использовать для локализации аварийных ситуаций на складах химического гипохлорита натрия и в помещениях насосных?",
        answers: [
          "Приточно-вытяжную вентиляцию, которая должна иметь дополнительный вентилятор, используемый при выходе из строя рабочего оборудования",
          "Естественную вентиляцию производственных и складских помещений",
          "Общеобменную вытяжную вентиляцию, которая должна иметь резервный вентилятор, автоматически включающийся при выходе из строя рабочего агрегата",
          "Общеобменную приточную вентиляцию, которая должна иметь резервный вентилятор, автоматически включающийся при выходе из строя рабочего агрегата",
        ],
        correctAnswers: [
          "Общеобменную вытяжную вентиляцию, которая должна иметь резервный вентилятор, автоматически включающийся при выходе из строя рабочего агрегата",
        ],
      },
      {
        code: "20108170",
        text: 'Нахождение каких стационарных систем трубопроводов на пунктах слива-налива жидкого хлора не предусмотрено ФНП "Правила безопасности производств хлора и хлорсодержащих сред"?',
        answers: [
          "Трубопроводов пара и горячей воды для подогрева",
          "Трубопроводов для слива-налива жидкого хлора",
          "Трубопроводов для отвода газообразного хлора на потребление или поглощение",
          "Трубопроводов для подачи в вагон-цистерну сжатого воздуха (азота) или хлора для передавливания",
        ],
        correctAnswers: ["Трубопроводов пара и горячей воды для подогрева"],
      },
      {
        code: "20108171",
        text: "Какие требования установлены к наружной поверхности трубопроводов, работающих в среде хлора?",
        answers: [
          "Трубопроводы должны иметь антикоррозийное покрытие, иметь опознавательную окраску, предупреждающие знаки и маркировочные щитки",
          "Трубопроводы должны быть окрашены в зеленый цвет",
          'На трубопровод должна быть нанесена предупредительная надпись "Осторожно, яд!"',
          "Трубопроводы должны иметь гладкую поверхность и быть окрашены в сигнализирующий цвет",
        ],
        correctAnswers: [
          "Трубопроводы должны иметь антикоррозийное покрытие, иметь опознавательную окраску, предупреждающие знаки и маркировочные щитки",
        ],
      },
      {
        code: "20108172",
        text: "Какой должна быть величина прибавки на коррозию для толщины стенки трубопровода хлора дополнительно к расчетной?",
        answers: [
          "Не менее 1 мм",
          "Не менее 0,7 мм",
          "Не менее 0,5 мм",
          "Не менее 0,1 мм",
        ],
        correctAnswers: ["Не менее 1 мм"],
      },
      {
        code: "20108173",
        text: "Чему равно значение предельно допустимой концентрации хлора в воздухе рабочей зоны производственного помещения?",
        answers: ["1,0 мг/м3", "5,0 мг/м3", "10 мг/м3", "15 мг/м3"],
        correctAnswers: ["1,0 мг/м3"],
      },
      {
        code: "20108174",
        text: "Каким документом определяются порог чувствительности датчиков системы контроля утечек хлора, их количество и месторасположение?",
        answers: [
          "Технологическим регламентом",
          "Правилами безопасности производств хлора и хлорсодержащих сред",
          "Заключением метрологической лаборатории",
          "Проектом",
        ],
        correctAnswers: ["Проектом"],
      },
      {
        code: "20108175",
        text: "Каким образом допускается налив гипохлорита натрия в транспортные емкости?",
        answers: [
          "Только методом передавливания сжатым воздухом (азотом)",
          "Только самотеком из напорных емкостей",
          "Только при помощи насосов, предназначенных для перекачки гипохлорита натрия (калия)",
          "Всеми перечисленными способами",
        ],
        correctAnswers: ["Всеми перечисленными способами"],
      },
      {
        code: "20108176",
        text: "В каком из перечисленных случаев следует предусматривать установку обратных клапанов на насосах, используемых для перекачки гипохлорита натрия?",
        answers: [
          "Только в случае установки на всасывающих линиях при подключении нескольких насосов к общему всасывающему коллектору",
          "Только в случае установки на нагнетательных линиях дозирующих насосов",
          "Только в случае установки на нагнетательных линиях перекачивающих насосов",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20108177",
        text: "Какая устанавливается максимальная норма налива жидкого хлора в емкость (резервуар, танк, сборник, вагон-цистерна, контейнер-цистерна)?",
        answers: ["2,15 кг/дм3", "1,0 кг/дм3", "1,25 кг/дм3", "1,75 кг/дм3"],
        correctAnswers: ["1,25 кг/дм3"],
      },
      {
        code: "20108178",
        text: "Что необходимо предпринять в случае превышения установленной нормы заполнения тары хлором (1,25 кг/дм3)?",
        answers: [
          "Опорожнить контейнер (баллон) в присутствии работников наполнительной станции и представителя Ростехнадзора",
          "Отправить контейнер (баллон) обратно на наливную станцию",
          "Немедленно отправить переполненный контейнер (баллон) на опорожнение",
          "Сделать соответствующую запись в журнале приемки контейнеров",
        ],
        correctAnswers: [
          "Немедленно отправить переполненный контейнер (баллон) на опорожнение",
        ],
      },
      {
        code: "20108179",
        text: "Какие из перечисленных вагонов-цистерн, контейнеров-цистерн, контейнеров (бочек) и баллонов допускается использовать для транспортировки жидкого хлора?",
        answers: [
          "На которых отсутствуют надлежащая окраска и надписи",
          "На которых демонтированы сифонные трубки из баллонов",
          "На которых имеются механические повреждения или другие дефекты емкости",
          "На которых истек срок ревизии предохранительного клапана и мембраны (для вагонов-цистерн и контейнеров-цистерн)",
        ],
        correctAnswers: [
          "На которых демонтированы сифонные трубки из баллонов",
        ],
      },
      {
        code: "20108180",
        text: "Какое из перечисленных требований к вагонам-цистернам с жидким хлором при стоянке на станции вне поездов или сформированных составов указано неверно?",
        answers: [
          "Должны быть закреплены от движения башмаками",
          "Должны быть оборудованы световой и звуковой сигнализацией",
          "Должны быть закреплены от ухода ручными тормозами",
          "Должны быть сцеплены",
        ],
        correctAnswers: [
          "Должны быть оборудованы световой и звуковой сигнализацией",
        ],
      },
      {
        code: "20108181",
        text: "Каким образом следует пропускать через горку вагоны-цистерны с жидким хлором?",
        answers: [
          "Спускать с горки",
          "Только с маневровым локомотивом",
          "Производить маневры толчками",
          "На усмотрение перевозчика",
        ],
        correctAnswers: ["Только с маневровым локомотивом"],
      },
      {
        code: "20108182",
        text: "Проведение каких работ является обязательным при постановке вагона-цистерны на пункт слива-налива?",
        answers: [
          "Проверка наличия знаков опасности, надписей и трафаретов",
          "Надежное закрепление вагона-цистерны ручными тормозами или башмаками с обеих сторон и заземление",
          "Удаление загрязнений с наружной поверхности цистерны",
          "Проверка работоспособности крепительных устройств крышки люка",
        ],
        correctAnswers: [
          "Надежное закрепление вагона-цистерны ручными тормозами или башмаками с обеих сторон и заземление",
        ],
      },
      {
        code: "20108183",
        text: "В течение какого времени после наполнения жидким хлором осуществляется отстой вагонов-цистерн с проведением визуального осмотра и контролем утечек хлора?",
        answers: [
          "Не менее 12 часов",
          "Не менее 3 часов",
          "Не менее суток",
          "Не менее 6 часов",
        ],
        correctAnswers: ["Не менее суток"],
      },
      {
        code: "20108184",
        text: "Размещение какого количества одновременно отправляемых или поступающих вагонов-цистерн с жидким хлором должен обеспечивать отстойный путь (тупик)?",
        answers: [
          "Половины партии, но не более 5 штук",
          "Всей партии, но не более 14 штук",
          "Всей партии, но не более 10 штук",
          "Половины партии, но не более 7 штук",
        ],
        correctAnswers: ["Всей партии, но не более 10 штук"],
      },
      {
        code: "20108185",
        text: "Оснащение какими устройствами вагонов-цистерн и контейнеров-цистерн для перевозки жидкого хлора указано верно?",
        answers: [
          "Тремя предохранительными клапанами",
          "Двумя запорными клапанами для налива (слива) жидкого хлора с сифонными трубами и скоростными или внутренними отсечными клапанами, автоматически прекращающими выход жидкого хлора при разрыве трубопровода",
          "Тремя вентилями, предназначенными для выпуска газообразного хлора (абгазов) или подачи газа для передавливания жидкого хлора, соединенными с укороченными сифонами, исключающими переполнение вагона-цистерны сверх установленной нормы налива",
          "Двумя штуцерами с вентилем для съемного манометра",
        ],
        correctAnswers: [
          "Двумя запорными клапанами для налива (слива) жидкого хлора с сифонными трубами и скоростными или внутренними отсечными клапанами, автоматически прекращающими выход жидкого хлора при разрыве трубопровода",
        ],
      },
      {
        code: "20108186",
        text: "На кого следует возлагать выбор маршрута следования опасного груза с жидким хлором, а также условий его передвижения?",
        answers: [
          "На получателя груза",
          "На руководителя автотранспортной организации",
          "На водителя автотранспортного средства",
          "На грузоотправителя",
        ],
        correctAnswers: ["На руководителя автотранспортной организации"],
      },
      {
        code: "20108187",
        text: "Какое минимальное время пребывания под навесом заполненных хлором контейнеров или баллонов?",
        answers: [
          "Не менее пяти суток",
          "Не менее одних суток",
          "Не менее двух суток",
          "Не менее трех суток",
        ],
        correctAnswers: ["Не менее одних суток"],
      },
      {
        code: "20108188",
        text: "В течение какого срока выдерживаются на складе вновь скомплектованные партии наполненных контейнеров или баллонов жидким хлором?",
        answers: [
          "Не менее одних суток",
          "Не менее двух суток",
          "Не менее трех суток",
          "Срок не регламентируется",
        ],
        correctAnswers: ["Не менее одних суток"],
      },
      {
        code: "20108189",
        text: "Какие сроки установлены для хранения контейнеров и баллонов с хлором на перевалочных пунктах?",
        answers: [
          "Не более 12 часов",
          "Не более 2 суток",
          "Не более суток",
          "Не более 3 суток",
        ],
        correctAnswers: ["Не более суток"],
      },
      {
        code: "20108190",
        text: "Кто осуществляет окраску вновь изготовленной транспортной тары для жидкого хлора и нанесение на нее надписей?",
        answers: [
          "Эксплуатирующая организация",
          "Организация-изготовитель",
          "Организация, которой на праве собственности либо ином законном основании принадлежит хлорная тара",
          "Транспортная организация",
        ],
        correctAnswers: ["Организация-изготовитель"],
      },
      {
        code: "20108191",
        text: "В каком случае допускается перевозка сосудов с истекшим сроком технического освидетельствования, заполненных хлором?",
        answers: [
          "В случае заполнения сосудов хлором ниже установленного предела",
          "В случае согласования с территориальным органом Ростехнадзора",
          "В случае, если срок технического освидетельствования истек не более месяца назад",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20108192",
        text: "При выполнении какого условия допускается изменять маршрут перевозки жидкого хлора?",
        answers: [
          "С письменного разрешения руководителя транспортного подразделения эксплуатирующей организации",
          "Отклонение от маршрута перевозки категорически запрещается",
          "С разрешения ответственного лица, сопровождающего груз",
          "При указании такой возможности в инструкции по перевозке жидкого хлора автомобильным транспортом",
        ],
        correctAnswers: [
          "С разрешения ответственного лица, сопровождающего груз",
        ],
      },
      {
        code: "20108193",
        text: "В каком положении должен находиться баллон (без сифона) при отборе газообразного хлора?",
        answers: [
          "Баллон должен находиться только в наклонном положении вентилем вниз",
          "Баллон должен находиться только в горизонтальном положении",
          "Баллон должен находиться в вертикальном или наклонном положении",
          "Баллон должен находиться только в вертикальном положении",
        ],
        correctAnswers: [
          "Баллон должен находиться в вертикальном или наклонном положении",
        ],
      },
      {
        code: "20108194",
        text: "В каком положении должен находиться баллон (без сифона) при отборе из него газообразного хлора?",
        answers: [
          "В вертикальном положении вентилем вниз (угол наклона более 15°)",
          "В вертикальном или наклонном положении, при этом вентиль - в верхнем положении (угол наклона не более 15°)",
          "В наклонном положении вентилем вниз",
          "В горизонтальном положении",
        ],
        correctAnswers: [
          "В вертикальном или наклонном положении, при этом вентиль - в верхнем положении (угол наклона не более 15°)",
        ],
      },
      {
        code: "20108195",
        text: "Каким должно быть давление сжатого воздуха (азота) при передавливании жидкого хлора газообразным хлором?",
        answers: [
          "Давление сжатого воздуха (азота) не должно превышать 1,5 МПа",
          "Давление сжатого воздуха (азота) не должно превышать 2,2 МПа",
          "Давление сжатого воздуха (азота) не должно превышать 1,2 МПа",
          "Давление сжатого воздуха (азота) должно быть не менее чем на 0,05 МПа выше давления в сосуде, в который передавливается хлор",
        ],
        correctAnswers: [
          "Давление сжатого воздуха (азота) не должно превышать 1,2 МПа",
        ],
      },
      {
        code: "20108196",
        text: "Что следует применять для охлаждения контактных поверхностей передвижных шунтирующих устройств при производстве хлора методом электролиза?",
        answers: [
          "Обессоленную воду",
          "Азот",
          "Охлажденный воздух",
          "Техническую воду",
        ],
        correctAnswers: ["Обессоленную воду"],
      },
      {
        code: "20108197",
        text: "Какое содержание влаги должно быть в осушенном газе, используемом для технологических целей (передавливание хлора, продувка, разбавление при конденсации) в электролизерах?",
        answers: [
          "Должно соответствовать температуре точки росы не выше -40 °С",
          "Должно соответствовать температуре точки росы не выше -35 °С",
          "Должно соответствовать температуре точки росы не выше -30 °С",
          "Должно соответствовать температуре точки росы не выше -20 °С",
        ],
        correctAnswers: [
          "Должно соответствовать температуре точки росы не выше -40 °С",
        ],
      },
      {
        code: "20108198",
        text: "Каким должно быть сопротивление изоляции электроизолирующих устройств (вставок, изоляторов, подвесок) электролизеров?",
        answers: [
          "Не менее 0,5 МОм",
          "Не менее 0,4 МОм",
          "Не менее 0,3 МОм",
          "Не менее 0,1 МОм",
        ],
        correctAnswers: ["Не менее 0,5 МОм"],
      },
      {
        code: "20108199",
        text: "Какая максимальная объемная доля водорода в хлоре устанавливается в общем хлорном коллекторе при электролизе мембранным методом?",
        answers: ["0,3 %", "0,4 %", "0,2 %", "0,5 %"],
        correctAnswers: ["0,2 %"],
      },
      {
        code: "20108200",
        text: "При каких условиях допускается включение электролизера проточного действия при электрохимическом способе получения гипохлорита натрия?",
        answers: [
          "До заполнения электролитом",
          "После включения вентилятора, подающего воздух на разбавление и отдувку водорода",
          "После кислотной промывки электролизера",
          "После промывки электролизера от катодных карбонатных отложений",
        ],
        correctAnswers: [
          "После включения вентилятора, подающего воздух на разбавление и отдувку водорода",
        ],
      },
    ],

    20109: [
      {
        code: "20109000",
        text: "В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечить способность функционирования средств противоаварийной защиты?",
        answers: [
          "В течение 4 часов",
          "В течение времени, достаточного для исключения опасной ситуации",
          "В течение 10 часов",
          "В течение времени, обозначенного в проектной документации",
        ],
        correctAnswers: [
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
      },
      {
        code: "20109001",
        text: "Что необходимо предпринять организации, эксплуатирующей химически опасный производственный объект, в целях приведения его в соответствие требованиям Правил безопасности химически опасных производственных объектов?",
        answers: [
          "Провести реконструкцию химически опасного производственного объекта с последующим уведомлением территориального органа Ростехнадзора",
          "Провести экспертизу промышленной безопасности химически опасного производственного объекта",
          "Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь",
          "Немедленно сообщить в Ростехнадзор об отклонениях, выявленных в рамках проведения производственного контроля",
        ],
        correctAnswers: [
          "Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь",
        ],
      },
      {
        code: "20109002",
        text: "В каком документе указываются регламентированные значения параметров по ведению технологического процесса?",
        answers: [
          "В соответствующем техническом регламенте",
          "В технологическом регламенте на производство продукции",
          "В руководстве по безопасности производства",
        ],
        correctAnswers: [
          "В технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20109003",
        text: "Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?",
        answers: [
          "Рациональный выбор гидродинамических характеристик процесса и теплообменных характеристик, а также геометрических параметров аппаратов",
          "Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки",
          "Надежное энергообеспечение (устанавливается в исходных данных)",
          "Выбор значений параметров состояния технологической среды (состава, давления, температуры), снижающих ее химическую опасность",
        ],
        correctAnswers: [
          "Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки",
        ],
      },
      {
        code: "20109004",
        text: "Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах I и II классов опасности?",
        answers: [
          "Не более 120 секунд",
          "Время срабатывания устанавливается организацией - разработчиком проекта",
          "Не более 12 секунд",
          "Не более 300 секунд",
        ],
        correctAnswers: ["Не более 12 секунд"],
      },
      {
        code: "20109005",
        text: "Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах III класса опасности?",
        answers: [
          "Не более 300 секунд",
          "Не более 120 секунд",
          "Не менее 300 секунд",
          "Не более 12 секунд",
        ],
        correctAnswers: ["Не более 120 секунд"],
      },
      {
        code: "20109006",
        text: "Куда следует направлять сбрасываемые химически опасные вещества?",
        answers: [
          "На факельную установку для сжигания",
          "В закрытые системы для дальнейшей утилизации",
          "В специальные контейнеры для последующей переработки",
          "Место направления сбрасываемых химически опасных веществ устанавливается разработчиком проектной документации",
        ],
        correctAnswers: ["В закрытые системы для дальнейшей утилизации"],
      },
      {
        code: "20109007",
        text: "В каком случае допускается объединение выбросов химически опасных веществ, содержащих вещества, способные при смешивании образовывать более опасные по воздействиям химические соединения?",
        answers: [
          "Только в случае смешения выбросов в количестве, определяемом условиями безопасной остановки технологического процесса",
          "Только в случае разработки дополнительных мер, предусмотренных документацией на ХОПО",
          "Только в случае использования специализированных систем аварийного освобождения",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20109008",
        text: "В каком документе организация, эксплуатирующая химически опасные производственные объекты I, II и III классов опасности, должна предусматривать действия работников по предупреждению аварий, локализации и максимальному снижению тяжести последствий?",
        answers: [
          "В Плане мероприятий по локализации и ликвидации последствий аварий",
          "В технологическом или техническом регламенте",
          "Только в техническом регламенте",
          "В положении (инструкции) о производственном контроле",
        ],
        correctAnswers: [
          "В Плане мероприятий по локализации и ликвидации последствий аварий",
        ],
      },
      {
        code: "20109009",
        text: "Какие типы технологических регламентов предусматриваются в зависимости от степени освоенности производств и целей осуществляемых работ?",
        answers: [
          "Постоянные, временные, разовые и лабораторные",
          "Входящие в состав проектной документации (проектные) или пусковые (наладочные)",
          "Пересматриваемые с определенной периодичностью",
        ],
        correctAnswers: ["Постоянные, временные, разовые и лабораторные"],
      },
      {
        code: "20109010",
        text: "Для каких из перечисленных производств разрабатываются постоянные технологические регламенты?",
        answers: [
          "Для всех перечисленных производств",
          "Только для действующих химико-технологических производств, в технологию которых внесены принципиальные изменения",
          "Только для производств с новой технологией",
          "Только для новых в данной организации производств",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20109011",
        text: "Какие технологические регламенты разрабатываются при выпуске товарной продукции на опытных и опытно-промышленных установках (цехах), а также для опытных и опытно-промышленных работ, проводимых на действующих производствах?",
        answers: [
          "Временные (цеховые) технологические регламенты",
          "Разовые (опытные) технологические регламенты",
          "Постоянные (промышленные) технологические регламенты",
          "Лабораторные (исследовательские) технологические регламенты",
        ],
        correctAnswers: ["Разовые (опытные) технологические регламенты"],
      },
      {
        code: "20109012",
        text: "В каком случае допускается наработка товарной продукции по лабораторным регламентам (пусковым запискам, производственным методикам)?",
        answers: [
          "Ни в каком случае",
          "Объемом до 1200 кг/год",
          "Объемом до 1000 кг/год",
        ],
        correctAnswers: ["Объемом до 1000 кг/год"],
      },
      {
        code: "20109013",
        text: "Какой из перечисленных разделов не относится к постоянным, временным и разовым технологическим регламентам, связанным с необходимостью обеспечения промышленной безопасности технологических процессов?",
        answers: [
          "Общая характеристика производства",
          "Характеристика сырья, материалов, полупродуктов и энергоресурсов",
          "Материальный баланс",
          "Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств",
        ],
        correctAnswers: [
          "Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств",
        ],
      },
      {
        code: "20109014",
        text: 'Информацию о каких организациях должен содержать раздел технологического регламента "Общая характеристика производства"?',
        answers: [
          "О всех перечисленных организациях",
          "Об организации, выполнявшей функции генерального проектировщика",
          "Об организации - разработчике технологического процесса",
          "Об организации - разработчике технологической части проектной документации",
          "Об организации, выполнивший проектную документацию",
        ],
        correctAnswers: ["О всех перечисленных организациях"],
      },
      {
        code: "20109015",
        text: 'Как производится описание технологической схемы в разделе технологического регламента "Описание технологического процесса и схемы"?',
        answers: [
          "По стадиям технологического процесса, начиная с загрузки сырья в оборудование и заканчивая выгрузкой",
          "По стадиям технологического процесса, начиная с загрузки подготовленного сырья в технологическое оборудование",
          "По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта",
        ],
        correctAnswers: [
          "По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта",
        ],
      },
      {
        code: "20109016",
        text: 'Что необходимо указывать в описании процессов разделения химических продуктов (горючих или их смесей с негорючими) в разделе технологического регламента "Описание технологического процесса и схемы"?',
        answers: [
          "Показатели взрывоопасности и токсичные свойства веществ, участвующих на всех стадиях процесса",
          "Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса",
          "Степень разделения сред, а также показатели пожароопасности и токсичности",
        ],
        correctAnswers: [
          "Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса",
        ],
      },
      {
        code: "20109017",
        text: 'Какие разновидности материального баланса допускается составлять в разделе технологического регламента "Материальный баланс"?',
        answers: [
          "На единицу времени (час)",
          "На единицу выпускаемой продукции",
          "Все перечисленные",
          "На один производственный поток или на мощность производства в целом",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20109018",
        text: "На основе каких данных составляется материальный баланс для действующих производств?",
        answers: [
          "На основе достигнутых показателей работы производств в последний год перед составлением технологического регламента",
          "На основе данных, указанных в проектной документации с учетом внесенных в нее изменений, включения или исключения дополнительных операций",
          "На основе данных, указанных в проектной документации",
        ],
        correctAnswers: [
          "На основе достигнутых показателей работы производств в последний год перед составлением технологического регламента",
        ],
      },
      {
        code: "20109019",
        text: 'Какое из перечисленных требований не соответствует разделу технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          "Допускается разделять в отдельные таблицы перечень систем сигнализации и блокировок и (или) перечень аналитического контроля",
          "Все ответы неверны",
          "Средства автоматики, используемые по плану мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, должны быть определены особо",
          "Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается",
          "Периодичность испытаний запорной регулирующей арматуры, исполнительных механизмов, периодичность проверок приборов и испытаний других технических средств, участвующих в схемах контроля, управления и противоаварийной защиты технологических процессов, должны быть взаимоувязаны",
        ],
        correctAnswers: [
          "Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается",
        ],
      },
      {
        code: "20109020",
        text: "Кем разрабатываются все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?",
        answers: [
          "Организацией, эксплуатирующей химико-технологическое производство",
          "Специализированной организацией",
          "Организацией - разработчиком процесса",
          "Специальной комиссией под председательством инспектора территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "Организацией, эксплуатирующей химико-технологическое производство",
        ],
      },
      {
        code: "20109021",
        text: "На кого возлагается ответственность за полноту и качество разработки разделов технологического регламента производства продукции и контроль за обеспечением его исполнения?",
        answers: [
          "На организацию - разработчика процесса производства",
          "На руководителя организации (заместителя), эксплуатирующей химико-технологическое производство",
          "На специальную комиссию организации, эксплуатирующей химико-технологическое производство",
          "На технологическую службу организации, производства, отделения, установки",
        ],
        correctAnswers: [
          "На технологическую службу организации, производства, отделения, установки",
        ],
      },
      {
        code: "20109022",
        text: "Кто утверждает все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?",
        answers: [
          "Технический руководитель организации, эксплуатирующей химико-технологическое производство",
          "Начальник отдела технологического контроля организации, эксплуатирующей химико-технологическое производство",
          "Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство",
          "Специальная комиссия организации, эксплуатирующей химико-технологическое производство",
        ],
        correctAnswers: [
          "Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство",
        ],
      },
      {
        code: "20109023",
        text: 'Чьими подписями оформляется "Лист подписей постоянного (временного, разового, лабораторного) технологического регламента"? Укажите все правильные ответы.',
        answers: [
          "Начальника центральной лаборатории",
          "Заместителя руководителя службы охраны труда",
          "Руководителя организации (его заместителя)",
          "Начальника цеха",
          "Начальника производства",
        ],
        correctAnswers: ["Начальника цеха", "Начальника производства"],
      },
      {
        code: "20109024",
        text: 'Кто подписывается в технологическом регламенте под грифом "согласовано"? Укажите все правильные ответы.',
        answers: [
          "Главный метролог организации",
          "Главный механик и главный энергетик организации",
          "Начальник производства",
          "Начальник производственно-технического отдела организации",
        ],
        correctAnswers: [
          "Главный метролог организации",
          "Главный механик и главный энергетик организации",
        ],
      },
      {
        code: "20109025",
        text: "Какое количество копий технологических регламентов устанавливается требованиями Правил безопасности химически опасных производственных объектов?",
        answers: [
          "Не менее 3 копий",
          "Не менее 5 копий",
          "Все ответы неверны",
          "Не менее 12 копий",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20109026",
        text: "Какой устанавливается срок действия постоянного технологического регламента?",
        answers: [
          "Не более 15 лет",
          "Не более 10 лет",
          "Не более 2 лет",
          "Не более 7 лет",
        ],
        correctAnswers: ["Не более 10 лет"],
      },
      {
        code: "20109027",
        text: "Каким образом определяется срок действия временного технологического регламента при отсутствии установленных планами норм освоения производства?",
        answers: [
          "В соответствии с проектом",
          "Лицом, утверждающим технологический регламент",
          "Главным инженером (технологическим руководителем) организации",
          "Руководителем организации (его заместителем)",
        ],
        correctAnswers: ["Лицом, утверждающим технологический регламент"],
      },
      {
        code: "20109028",
        text: "Каким образом устанавливается и оформляется срок продления действия временного технологического регламента?",
        answers: [
          "Приказом специальной комиссии организации",
          "Приказом руководителя организации",
          "Приказом технологической службы организации",
          "Приказом главного инженера (технического директора) организации",
        ],
        correctAnswers: ["Приказом руководителя организации"],
      },
      {
        code: "20109029",
        text: "В каком из перечисленных случаев должен быть составлен временный технологический регламент на новый срок? Укажите все правильные ответы.",
        answers: [
          "Если срок освоения производства составляет более года",
          "Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса",
          "Если в тексте технологического регламента обнаружены исправления, подчистки и поправки",
          "Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей",
          "Если такое решение принято технологической службой организации",
        ],
        correctAnswers: [
          "Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса",
          "Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей",
        ],
      },
      {
        code: "20109030",
        text: "Какой устанавливается срок действия разовых (опытных) технологических регламентов, в соответствии с которыми проводится наработка опытной продукции в течение нескольких лет?",
        answers: [
          "Не более 2 лет",
          "Не более 4 лет",
          "Не более 5 лет",
          "Не более 7 лет",
        ],
        correctAnswers: ["Не более 5 лет"],
      },
      {
        code: "20109031",
        text: "Какой объем неразрушающего контроля сварных соединений технологических трубопроводов, транспортирующих токсичные и высокотоксичные вещества, предусмотрен в Правилах безопасности химически опасных производственных объектов?",
        answers: [
          "Объем определяется специалистами неразрушающего контроля",
          "Не менее 85 % длины сварного шва каждого сварного соединения",
          "Не менее 90 % длины сварного шва каждого сварного соединения",
          "Не менее 100 % длины сварного шва каждого сварного соединения",
        ],
        correctAnswers: [
          "Не менее 100 % длины сварного шва каждого сварного соединения",
        ],
      },
      {
        code: "20109032",
        text: "Что необходимо учитывать при размещении технологического оборудования, трубопроводной арматуры в производственных зданиях и на открытых площадках?",
        answers: [
          "Возможность быстрого демонтажа оборудования обслуживающим персоналом",
          "Возможность проведения ремонтных работ, визуального контроля и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий",
          "Наличие специального персонала, имеющего необходимые допуски, для очистки и обслуживания оборудования",
          "Минимизацию контакта технологического оборудования с коррозионно-активными веществами",
        ],
        correctAnswers: [
          "Возможность проведения ремонтных работ, визуального контроля и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий",
        ],
      },
      {
        code: "20109033",
        text: "На чем основаны оптимальные методы и средства противоаварийной автоматической защиты?",
        answers: [
          "На алгоритмах, разработанных по сценариям возникновения возможных аварий и их развития",
          "На методиках и программных продуктах, применяемых для моделирования аварийных ситуаций, согласованных Ростехнадзором",
          "На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления",
          "На сценариях возможных аварий и способах перевода опасного объекта в безопасное состояние",
        ],
        correctAnswers: [
          "На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления",
        ],
      },
      {
        code: "20109034",
        text: 'Какое из перечисленных требований соответствует нормам заполнения и хранения "Листа регистрации изменений и дополнений"?',
        answers: [
          "Регистрация изменений и дополнений выполняется главным инженером (техническим директором) организации",
          "Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента",
          "Запись в регистрационном листе выполняется чернилами черного цвета",
          "Лист регистрации изменений и дополнений должен прикладываться в начале регламента",
        ],
        correctAnswers: [
          "Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента",
        ],
      },
      {
        code: "20109035",
        text: 'Какой устанавливается срок действия "Накопительной ведомости"?',
        answers: ["6 месяцев", "12 месяцев", "2 месяца", "5 месяцев"],
        correctAnswers: ["6 месяцев"],
      },
      {
        code: "20109036",
        text: "Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?",
        answers: [
          "Категорией взрывоопасности технологических блоков",
          "Приведенной массой вещества, участвующего во взрыве",
          "Радиусом зон разрушения",
          "Энергией сгорания парогазовой фазы",
        ],
        correctAnswers: ["Категорией взрывоопасности технологических блоков"],
      },
      {
        code: "20109037",
        text: "Какой параметр является критерием установления категории взрывоопасности технологических блоков согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?",
        answers: [
          "Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему",
          "Скорость распространения горения веществ, обращающихся в процессе",
          "Температура самовоспламенения паров веществ, обращающихся в процессе",
          "Класс опасности веществ, обращающихся в процессе",
        ],
        correctAnswers: [
          "Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему",
        ],
      },
      {
        code: "20109038",
        text: "Какого показателя категорий взрывоопасности технологических блоков не существует?",
        answers: ["I", "II", "III", "IV"],
        correctAnswers: ["IV"],
      },
      {
        code: "20109039",
        text: "Какой следует принимать категорию взрывоопасности блоков, определяемую расчетом, если обращающиеся в технологическом блоке опасные вещества относятся к токсичным, высокотоксичным веществам?",
        answers: [
          "На 1 ниже",
          "I или II",
          "Соответствующей расчетам",
          "На 1 выше",
        ],
        correctAnswers: ["На 1 выше"],
      },
      {
        code: "20109040",
        text: "В соответствии с чем осуществляется ведение технологических процессов на опасном производственном объекте химической, нефтехимической промышленности?",
        answers: [
          "В соответствии с рекомендациями территориального органа Ростехнадзора",
          "В соответствии с технологическими регламентами на производство продукции",
          "В соответствии с распоряжениями технического руководителя эксплуатирующей организации",
          "В соответствии с заключением экспертизы промышленной безопасности (ЭПБ)",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами на производство продукции",
        ],
      },
      {
        code: "20109041",
        text: "Каким образом обеспечивается отработка персоналом практических навыков безопасного выполнения работ, предупреждения аварий и ликвидации их последствий на технологических объектах с блоками I и II категорий взрывоопасности?",
        answers: [
          "Посредством подготовки производственного персонала в собственных специализированных центрах теоретического обучения",
          "Посредством изучения наглядных пособий (плакатов) по охране труда",
          "Посредством допуска персонала к самостоятельной работе не ранее чем через 6 месяцев после стажировки на объекте и изучения всех необходимых инструкций",
          "Посредством прохождения курса подготовки с использованием современных технических средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)",
        ],
        correctAnswers: [
          "Посредством прохождения курса подготовки с использованием современных технических средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)",
        ],
      },
      {
        code: "20109042",
        text: "В какой документации приводятся способы и средства, исключающие выход параметров за установленные пределы?",
        answers: [
          "Только в исходных данных на проектирование, а также в технологическом регламенте на производство продукции",
          "Только в разработанном технологическом регламенте на производство продукции и в соответствующих инструкциях",
          "Только в соответствующей проектной документации",
          "В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции",
        ],
        correctAnswers: [
          "В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20109043",
        text: "Каким образом осуществляется управление подачей инертных сред на установку с технологическими блоками любой категории взрывоопасности там, где при отклонении от регламентированных значений параметров возможно образование взрывоопасных смесей?",
        answers: [
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Qв ≤ 10 допускается ручное или дистанционное управление",
          "Для установок с технологическими блоками I и II категорий взрывоопасности, предусматривается автоматическое управление подачей инертных сред, с технологическими блоками III категории - управление дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление",
          "Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, для установок с технологическими блоками II категории взрывоопасности - дистанционное, для установок с технологическими блоками III категории взрывоопасности - ручное",
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности предусматривается только автоматическое управление, а при Qв ≤ 10 допускается ручное управление",
        ],
        correctAnswers: [
          "Для установок с технологическими блоками I и II категорий взрывоопасности, предусматривается автоматическое управление подачей инертных сред, с технологическими блоками III категории - управление дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление",
        ],
      },
      {
        code: "20109044",
        text: "Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?",
        answers: [
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
          "Системами ручного регулирования",
          "Средствами обеспечения питания инертными газами систем контрольно-измерительных приборов и автоматики",
        ],
        correctAnswers: [
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
        ],
      },
      {
        code: "20109045",
        text: "Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?",
        answers: [
          "Время срабатывания определяется расчетом для технологических блоков I категории взрывоопасности и установлено для блоков II и III категорий",
          "Время срабатывания установлено для каждого из технологических блоков и зависит от категории взрывоопасности",
          "Время срабатывания определяется расчетом для технологических блоков II и III категорий взрывоопасности и установлено для блоков I категории",
          "Время срабатывания определяется расчетом",
        ],
        correctAnswers: ["Время срабатывания определяется расчетом"],
      },
      {
        code: "20109046",
        text: "Какими блокировками должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей?",
        answers: [
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой среды в его корпусе, достижении предельно допустимых значений в приемной емкости",
          "Блокировками, исключающими пуск или прекращающими работу насоса при отклонениях от опасных значений в расходной емкости",
          "Блокировками, исключающими пуск или прекращающими работу при отсутствии перемещаемой среды в расходной и приемной емкостях",
        ],
        correctAnswers: [
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
      },
      {
        code: "20109047",
        text: "Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?",
        answers: [
          "На установках с технологическими блоками I категории взрывоопасности давление теплоносителя (хладагента) должно быть выше давления нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками II и III категорий взрывоопасности - не регламентируется",
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
          "Соотношение давлений теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ устанавливается разработчиком процесса",
          "На установках с технологическими блоками I и II категорий взрывоопасности давление теплоносителя (хладагента) не должно быть выше давления нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками III категории взрывоопасности - не регламентируется",
        ],
        correctAnswers: [
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
        ],
      },
      {
        code: "20109048",
        text: "Кто определяет выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?",
        answers: [
          "Разработчик процесса",
          "Разработчик проектной документации",
          "Организация-заказчик в задании на проектирование",
        ],
        correctAnswers: ["Разработчик процесса"],
      },
      {
        code: "20109049",
        text: "Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся жидкости и горючие жидкости на сливоналивных эстакадах?",
        answers: [
          "Управление должно быть организовано только по месту",
          "Должно быть организовано управление и по месту, и дистанционно (из безопасного места)",
          "Управление должно быть организовано только дистанционно (из безопасного места)",
        ],
        correctAnswers: [
          "Должно быть организовано управление и по месту, и дистанционно (из безопасного места)",
        ],
      },
      {
        code: "20109050",
        text: "Какие сведения являются основополагающими при выборе технологического оборудования для обеспечения технологических процессов?",
        answers: [
          "Расчетные данные, которым должны соответствовать параметры оборудования, и требования действующих нормативных актов",
          "Требования действующих нормативных актов, расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование",
          "Расчетные данные, которым должны соответствовать параметры промышленного оборудования, а также показатели надежности",
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
        correctAnswers: [
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
      },
      {
        code: "20109051",
        text: "Каким образом должны подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств для оборудования (аппаратов и трубопроводов) до начала их применения на опасном производственном объекте?",
        answers: [
          "Посредством проведения экспертизы промышленной безопасности, а также опытных работ, выполняемых разработчиком данного оборудования",
          "Посредством получения заключения экспертной организации и последующего проведения экспертизы промышленной безопасности",
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
        ],
        correctAnswers: [
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
        ],
      },
      {
        code: "20109052",
        text: "Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?",
        answers: [
          "Оборудование должно быть демонтировано, если оно расположено в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих технологических систем",
          "Оборудование должно быть демонтировано, если оно расположено в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно может быть изолировано от действующих технологических систем",
          "Оборудование должно быть изолировано от действующих технологических систем, а нанесенное на нем обозначение номера по технологической документации должно быть закрашено на схеме",
          "Оборудование должно быть демонтировано во всех случаях",
        ],
        correctAnswers: [
          "Оборудование должно быть демонтировано, если оно расположено в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих технологических систем",
        ],
      },
      {
        code: "20109053",
        text: "В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?",
        answers: [
          "При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений предельно допустимых уровней в расходной и приемной емкостях",
          "При наличии сигнализации по критическому уровню в расходной емкости и предельно допустимому уровню в приемной емкости",
          "При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации",
          "При наличии блокировок, срабатывающих автоматически при превышении значений допустимых уровней в расходной и приемной емкостях",
        ],
        correctAnswers: [
          "При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации",
        ],
      },
      {
        code: "20109054",
        text: "Что в технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?",
        answers: [
          "Все устройства, задействованные в системе противоаварийной автоматической защиты, включая исполнительные механизмы и автоматические системы подавления взрыва",
          "Запорная арматура, средства защиты от превышения давления, огнепреградители, а также автоматические системы подавления взрыва",
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
          "Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва, а также системы для аварийного опорожнения аппаратов",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20109055",
        text: "Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Средства автоматики должны быть обозначены по месту их установки и указываются в технологическом регламенте на производство продукции и инструкциях",
          "Средства автоматики должны быть обозначены на всех мнемосхемах",
          "Требования к обозначению средств автоматики должны определяться при разработке плана мероприятий по локализации и ликвидации последствий аварий",
          "Средства автоматики должны быть указаны только по месту их установки",
        ],
        correctAnswers: [
          "Средства автоматики должны быть обозначены по месту их установки и указываются в технологическом регламенте на производство продукции и инструкциях",
        ],
      },
      {
        code: "20109056",
        text: "Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания противоаварийной автоматической защиты?",
        answers: [
          "Выполняется обслуживающим персоналом по инструкции",
          "Средствами автоматического управления по действующим программам после производства обслуживающим персоналом всех необходимых по инструкции переключений",
          "Средствами автоматического управления по действующим программам",
          "Средствами автоматического управления по действующим программам после устранения причин, приведших к срабатыванию противоаварийной автоматической защиты",
        ],
        correctAnswers: ["Выполняется обслуживающим персоналом по инструкции"],
      },
      {
        code: "20109057",
        text: "Каким образом обеспечивается надежность обеспечения средств управления и противоаварийной автоматической защиты сжатым воздухом?",
        answers: [
          "Установкой буферных емкостей, обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты в течение 1 часа",
          "Незамедлительным переключением сети воздуха контрольно-измерительных приборов и автоматики на заводскую сеть сжатого воздуха через осушитель",
          "Установкой резервного компрессора с автоматическим его включением при остановке рабочего",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
        correctAnswers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20109058",
        text: "Что должно быть учтено в системах управления и защиты электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?",
        answers: [
          "Автоматический ввод резерва должен быть между каждым из 3 самостоятельных источников электроснабжения",
          "Должна быть обеспечена возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой",
          "Должна быть обеспечена селективность защит на устройствах электроснабжающей и электропотребляющей организаций",
          "Линии электроснабжения не должны оборудоваться устройствами автоматической частотной разгрузки",
        ],
        correctAnswers: [
          "Линии электроснабжения не должны оборудоваться устройствами автоматической частотной разгрузки",
        ],
      },
      {
        code: "20109059",
        text: 'Какое требование к системам вентиляции не соответствует ФНП "Общие правила взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств"?',
        answers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
          "Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания",
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
          "Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства",
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: "20109060",
        text: "Какая система отопления предусматривается в помещениях, имеющих взрывоопасные зоны?",
        answers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
          "Система водяного отопления",
          "Система парового отопления",
        ],
        correctAnswers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
        ],
      },
      {
        code: "20109061",
        text: "Какая максимальная температура поверхностей нагрева систем отопления должна быть в помещениях, имеющих взрывоопасные зоны?",
        answers: [
          "Максимальная температура не должна превышать 90 % температуры самовоспламенения вещества, имеющего самую высокую температуру самовоспламенения из обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 80 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 75 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 75 % температуры самовоспламенения вещества, имеющего самую высокую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
        correctAnswers: [
          "Максимальная температура не должна превышать 80 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
      },
      {
        code: "20109062",
        text: "Какие требования предъявляются к системам канализации технологических объектов при сбросе химически загрязненных стоков в магистральную сеть канализации?",
        answers: [
          "Системы канализации технологических объектов должны осуществлять сброс стоков в магистральную сеть в порядке, установленном в организации",
          "Системы канализации технологических объектов должны исключать аварийные выбросы стоков в магистральную сеть",
          "Системы канализации технологических объектов в обязательном порядке должны быть оборудованы звуковой и световой сигнализацией",
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
        ],
        correctAnswers: [
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
        ],
      },
      {
        code: "20109063",
        text: "В каких местах не допускается размещать фланцевые соединения трубопроводов с пожаровзрывоопасными, токсичными и едкими веществами?",
        answers: [
          "В местах, доступных для визуального наблюдения",
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
          "Над автомобильными дорогами",
          "На трубопроводах, идущих по стенам производственных зданий",
        ],
        correctAnswers: [
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
        ],
      },
      {
        code: "20109064",
        text: "На каких трубопроводах следует применять арматуру под приварку для повышения надежности и плотности соединений?",
        answers: [
          "На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении",
          "На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 1,6 МПа",
          "На трубопроводах технологических блоков II категории взрывоопасности с давлением среды более 1,6 МПа, температурой, равной температуре кипения среды, при регламентированном давлении",
          "На трубопроводах технологических блоков III категории взрывоопасности с температурой, равной температуре кипения при регламентированном давлении",
        ],
        correctAnswers: [
          "На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении",
        ],
      },
      {
        code: "20109065",
        text: "Какое количество тормозных башмаков для закрепления различных типов цистерн должно быть установлено для закрепления железнодорожных цистерн при операциях слива, налива растворителя и масла?",
        answers: [
          "Определяется расчетом и указывается в инструкции по проведению сливоналивных работ",
          "Не менее 3 на каждой цистерне",
          "Не менее 2 на каждой цистерне",
          "От 2 до 6 в зависимости от типа цистерны",
        ],
        correctAnswers: [
          "Определяется расчетом и указывается в инструкции по проведению сливоналивных работ",
        ],
      },
      {
        code: "20109066",
        text: "В течение какого минимального времени буферные емкости (реципиенты) должны обеспечивать питание сжатым воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров?",
        answers: ["2 часа", "1 час", "15 минут", "30 минут"],
        correctAnswers: ["1 час"],
      },
      {
        code: "20109067",
        text: "Какие требования безопасности предъявляются на период регенерации адсорбента к эксплуатации блока разделения воздуха при наличии в воздухораспределительной установке только одного адсорбера на потоке кубовой жидкости?",
        answers: [
          "Блок разделения воздуха необходимо останавливать не менее чем за 15 минут до начала проведения высокотемпературной регенерации адсорбента и предварительного проведения замеров концентрации ацетилена в жидком кислороде. Не допускать работу таких установок через обводную линию",
          "Блок разделения воздуха необходимо останавливать не менее чем за 10 минут до начала проведения высокотемпературной регенерации адсорбента. Не допускать работу таких установок через обводную линию",
          "Блок разделения воздуха необходимо останавливать не менее чем за 15 минут до начала проведения высокотемпературной регенерации адсорбента. Не допускать работу таких установок через обводную линию",
          "Блок разделения воздуха необходимо останавливать. Не допускать работу таких установок через обводную линию",
        ],
        correctAnswers: [
          "Блок разделения воздуха необходимо останавливать. Не допускать работу таких установок через обводную линию",
        ],
      },
      {
        code: "20109068",
        text: "Что необходимо применять для смазки цилиндров поршневых кислородных компрессоров?",
        answers: [
          "Только масла, рекомендованные разработчиками компрессора",
          "Только умягченную воду, полученную из заводской системы пароснабжения",
          "Дистиллят, полученный из воды питьевого качества",
          "Только конденсат, полученный из заводской системы пароснабжения",
        ],
        correctAnswers: ["Дистиллят, полученный из воды питьевого качества"],
      },
      {
        code: "20109069",
        text: "С какими параметрами рабочего давления и вместимости вместе с реципиентами необходимо оснащать кислородопроводы автоматически действующей системой защиты, прекращающей поступление кислорода из реципиентов в трубопровод при нарушении его целостности?",
        answers: [
          "С рабочим давлением более 2,0 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 200 м³",
          "С рабочим давлением более 1,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 160 м³",
          "С рабочим давлением более 1,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 200 м³",
          "С рабочим давлением более 2,0 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 160 м³",
        ],
        correctAnswers: [
          "С рабочим давлением более 1,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 200 м³",
        ],
      },
      {
        code: "20109070",
        text: "С какой пропускной способностью трубопроводы сброса продуктов разделения воздуха от предохранительных клапанов и других защитных устройств необходимо выводить за пределы здания?",
        answers: [
          "С пропускной способностью более 25 м³/ч",
          "С пропускной способностью более 60 м³/ч",
          "С пропускной способностью более 75 м³/ч",
          "С пропускной способностью более 100 м³/ч",
        ],
        correctAnswers: ["С пропускной способностью более 100 м³/ч"],
      },
      {
        code: "20109071",
        text: "Как должен производиться отогрев смерзшейся изоляции для обеспечения доступа к адсорберам?",
        answers: [
          "Непосредственно открытым пламенем",
          "Непосредственно за счет тепла, подводимого к отогреваемым аппаратам",
          "Непосредственно сухим подогретым воздухом",
          "Непосредственно подогретой водой",
        ],
        correctAnswers: ["Непосредственно сухим подогретым воздухом"],
      },
      {
        code: "20109072",
        text: "Как необходимо выполнять ремонтные работы внутри трубопроводов и арматуры теплого и холодного концов регенераторов в период остановки блоков без слива жидкости?",
        answers: [
          "По наряду-допуску",
          "По распоряжению руководителя смены",
          "В выходные дни по распоряжению руководителя организации",
          "По графикам, разработанным в соответствии со сроками, установленными техническими условиями разработчика оборудования",
        ],
        correctAnswers: ["По наряду-допуску"],
      },
      {
        code: "20109073",
        text: "Как должны быть установлены все металлические конструкции, расположенные в пределах площадок, на которых размещены сосуды и сливоналивные устройства жидких продуктов разделения воздуха?",
        answers: [
          "На бетонные фундаменты с отметкой верха, превышающей отметку площадки не более чем на 0,3 м",
          "На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,3 м",
          "На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,12 м",
          "На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,2 м",
        ],
        correctAnswers: [
          "На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,2 м",
        ],
      },
      {
        code: "20109074",
        text: "Как должна производиться инструментальная проверка эффективности работы вентиляционных систем?",
        answers: [
          "Не реже одного раза в год, а также после каждого капитального ремонта или реконструкции этих систем. Акты проверки утверждает технический руководитель организации",
          "Только после каждого капитального ремонта вентиляционных систем с оформлением акта проверки",
          "Только после реконструкции вентиляционных систем",
          "Не реже одного раза в полгода с оформлением акта проверки",
        ],
        correctAnswers: [
          "Не реже одного раза в год, а также после каждого капитального ремонта или реконструкции этих систем. Акты проверки утверждает технический руководитель организации",
        ],
      },
      {
        code: "20109075",
        text: "Какая арматура применяется на трубопроводах жидкого и газообразного аммиака?",
        answers: [
          "Стальная арматура и фасонные части, а также допускается применение арматуры и фитингов с деталями из цинка",
          "Стальная арматура и фасонные части, а также допускается применение запорной арматуры из высокопрочного чугуна",
          "Стальная арматура и фасонные части, а также арматура и фитинги с деталями из меди",
          "Стальная арматура и фасонные части",
        ],
        correctAnswers: ["Стальная арматура и фасонные части"],
      },
      {
        code: "20109076",
        text: "Куда допускается сброс неорганических жидких кислот и (или) щелочей от предохранительных клапанов?",
        answers: [
          "В обоснованных случаях допускается в любое из перечисленных мест",
          "В рабочую зону или в окружающую среду",
          "Только в рабочую зону",
          "В специальные емкости",
        ],
        correctAnswers: ["В специальные емкости"],
      },
      {
        code: "20109077",
        text: "Какие материалы применяются для изготовления, монтажа и ремонта технологического оборудования и трубопроводов для производств, использующих неорганические кислоты и щелочи?",
        answers: [
          "Только полимерные материалы",
          "Только нержавеющая сталь",
          "Материалы, обеспечивающие коррозионную стойкость к рабочей среде",
          "Только композитные материалы",
        ],
        correctAnswers: [
          "Материалы, обеспечивающие коррозионную стойкость к рабочей среде",
        ],
      },
      {
        code: "20109078",
        text: "Каким должен быть радиус кривизны отвода при изготовлении отводов способом гиба на специальных станках?",
        answers: [
          "Должен быть равен диаметру трубы",
          "Не менее 4 диаметров трубы",
          "Все ответы неверны",
          "Не менее 2 диаметров трубы",
          "Не менее 3 диаметров трубы",
        ],
        correctAnswers: ["Не менее 3 диаметров трубы"],
      },
      {
        code: "20109079",
        text: "Какой ширины предусматривается охранная зона межзаводского трубопровода кислот или щелочей, прокладываемого вне территории предприятия?",
        answers: [
          "Не менее 6 м с каждой его стороны",
          "Не менее 2 м с каждой его стороны",
          "Не менее 3 м с каждой его стороны",
          "Не менее 0,5 м с каждой его стороны",
        ],
        correctAnswers: ["Не менее 2 м с каждой его стороны"],
      },
      {
        code: "20109080",
        text: "Где допускается прокладка трубопроводов неорганических жидких кислот и (или) щелочей при условии, что трубопроводы должны быть заключены в специальные желоба или короба (коллекторы) с отводом утечек кислот и щелочей в безопасные места, определяемые проектом?",
        answers: [
          "Через административные и бытовые помещения",
          "В местах пересечения железных и автомобильных дорог",
          "По наружным стенам зданий, не связанных с обращением кислот и щелочей",
          "Через вспомогательные и подсобные помещения",
        ],
        correctAnswers: ["В местах пересечения железных и автомобильных дорог"],
      },
      {
        code: "20109081",
        text: "Кто устанавливает сроки проведения ревизии трубопроводов, запорной арматуры и предохранительных клапанов для неорганических жидких кислот и (или) щелочей в зависимости от скорости коррозионно-эрозионного износа?",
        answers: [
          "Научно-исследовательская организация",
          "Предприятие - владелец трубопровода",
          "Территориальный орган Ростехнадзора",
          "Проектная организация",
        ],
        correctAnswers: ["Предприятие - владелец трубопровода"],
      },
      {
        code: "20109082",
        text: "Каким образом определяется минимально допустимое расстояние от складов кислот и щелочей до взрывоопасных объектов?",
        answers: [
          "Расстояние определяется с учетом требований строительных норм и правил и с учетом расчетного радиуса опасной зоны",
          "Расстояние определяется в соответствии с нормами пожарной безопасности",
          "Расстояние должно рассчитываться с учетом устойчивости здания склада к воздействию ударной волны",
          "Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения, должно обеспечивать устойчивость зданий складов к воздействию данных факторов",
        ],
        correctAnswers: [
          "Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения, должно обеспечивать устойчивость зданий складов к воздействию данных факторов",
        ],
      },
      {
        code: "20109083",
        text: "Каким образом устанавливается минимально допустимое расстояние от складов кислот и щелочей до взрывоопасных объектов?",
        answers: [
          "Расстояние должно определяться на основании проверки устойчивости здания склада к воздействию ударной волны",
          "Расстояние определяется в соответствии с расчетным радиусом опасной зоны",
          "Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения, должно обеспечивать устойчивость зданий складов к воздействию данных факторов",
          "Расстояние определяется по нормам пожарной безопасности с учетом требований строительных норм и правил",
        ],
        correctAnswers: [
          "Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения, должно обеспечивать устойчивость зданий складов к воздействию данных факторов",
        ],
      },
      {
        code: "20109084",
        text: "Чему соответствует вместимость поддонов, которыми оснащают емкостное оборудование для использования кислот и (или) щелочей объемом 1000 л и более?",
        answers: [
          "50 % емкости всех расположенных в поддоне или на площадке с бортиками аппаратов и (или) емкостей",
          "80 % емкости всех расположенных в поддоне или на площадке с бортиками аппаратов и (или) емкостей",
          "Объему всего расположенного в поддонах оборудования",
          "Объему, достаточному для содержания одного аппарата максимальной емкости в случае его аварийного разрушения",
        ],
        correctAnswers: [
          "Объему, достаточному для содержания одного аппарата максимальной емкости в случае его аварийного разрушения",
        ],
      },
      {
        code: "20109085",
        text: "Какие меры безопасности должны соблюдаться при хранении и перекачке фосфора и фосфорного шлама?",
        answers: [
          'Паропроводы, подводящие острый пар для разогрева фосфора и поддержания его в расплавленном состоянии, должны быть оснащены устройствами ("воздушками") для предотвращения образования вакуума и попадания фосфора в паропровод',
          "Все перечисленные",
          "Температура фосфора и фосфорного шлама не должна быть выше 80 °С",
          "Паропроводы, подводящие острый пар для разогрева фосфора и поддержания его в расплавленном состоянии, должны быть оснащены приборами контроля давления пара",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20109086",
        text: "Что должно быть учтено при расчете толщины стенок сосудов, работающих под давлением в среде хлора?",
        answers: [
          "Расчетный срок эксплуатации, расчетное давление и прибавка на стенки для компенсации коррозии не менее 1 мм и не менее 2 мм для компенсации коррозии на штуцерах",
          "Только расчетный срок службы",
          "Только расчетное давление и дополнительная прибавка на стенки для компенсации коррозии на всех элементах сосуда по исходным данным разработчика процесса",
          "Только дополнительная прибавка на стенки для компенсации коррозии на всех элементах сосуда по исходным данным разработчика процесса",
        ],
        correctAnswers: [
          "Расчетный срок эксплуатации, расчетное давление и прибавка на стенки для компенсации коррозии не менее 1 мм и не менее 2 мм для компенсации коррозии на штуцерах",
        ],
      },
      {
        code: "20109087",
        text: "Каким должно быть расчетное давление сосудов, содержащих жидкий хлор?",
        answers: [
          "Не менее 0,7 МПа",
          "Не менее 1,1 МПа",
          "Не менее 1,6 МПа",
          "Не менее 1,3 МПа",
        ],
        correctAnswers: ["Не менее 1,6 МПа"],
      },
      {
        code: "20109088",
        text: "Каким принимают расчетное давление для трубопровода жидкого хлора?",
        answers: [
          "Не ниже 1,1 МПа",
          "Не ниже 1,5 МПа",
          "Не ниже 1,6 МПа",
          "Не ниже 1,3 МПа",
        ],
        correctAnswers: ["Не ниже 1,6 МПа"],
      },
      {
        code: "20109089",
        text: "Какие требования установлены к наружной поверхности трубопроводов, работающих в среде хлора?",
        answers: [
          "Трубопроводы должны иметь антикоррозийное покрытие, иметь опознавательную окраску, предупреждающие знаки и маркировочные щитки",
          'На трубопровод должна быть нанесена предупредительная надпись "Осторожно, ядовитое вещество!"',
          "Трубопроводы должны иметь ровную поверхность и иметь соответствующую окраску",
          "Трубопроводы должны быть окрашены в синий или голубой цвет",
        ],
        correctAnswers: [
          "Трубопроводы должны иметь антикоррозийное покрытие, иметь опознавательную окраску, предупреждающие знаки и маркировочные щитки",
        ],
      },
      {
        code: "20109090",
        text: "Каким документом определяются порог чувствительности датчиков системы контроля утечек хлора, их количество и месторасположение?",
        answers: [
          "Проектом",
          "Эксплуатационно-технической документацией",
          "Разработанным технологическим регламентом",
          "Правилами безопасности производства хлора",
        ],
        correctAnswers: ["Проектом"],
      },
      {
        code: "20109091",
        text: "Каким должно быть количество жидкого хлора, хранящегося в организациях-потребителях в стационарных емкостях и хлорной транспортной таре?",
        answers: [
          "Количество жидкого хлора, хранящегося в организациях-потребителях, должно быть согласовано с территориальным управлением Ростехнадзора",
          "Количество жидкого хлора должно быть максимально необходимым для обеспечения всего производственного цикла",
          "Количество жидкого хлора должно быть минимально необходимым для обеспечения производственного цикла",
          "Количество жидкого хлора не должно превышать 30-суточного потребления его в организации",
        ],
        correctAnswers: [
          "Количество жидкого хлора должно быть минимально необходимым для обеспечения производственного цикла",
        ],
      },
      {
        code: "20109092",
        text: "Каким способом хранение жидкого хлора в резервуарах (танках, контейнерах-цистернах) не осуществляется?",
        answers: [
          "При температуре плавления жидкого хлора",
          "При температуре кипения жидкого хлора при атмосферном давлении (изотермический способ хранения)",
          "При температуре окружающей среды",
          "В захоложенном состоянии при температуре ниже температуры окружающей среды",
        ],
        correctAnswers: ["При температуре плавления жидкого хлора"],
      },
      {
        code: "20109093",
        text: "Каким принимают радиус опасной зоны для складов жидкого хлора?",
        answers: [
          "В пределах глубины распространения хлорного облака с поражающей концентрацией (определяется расчетом)",
          "В пределах глубины распространения хлорного облака со средней концентрацией (определяется по факту распространения)",
          "В пределах распространения хлорного облака со средней концентрацией, но не более 1500 м",
          "В пределах распространения хлорного облака с минимальной концентрацией, но не менее 200 м",
        ],
        correctAnswers: [
          "В пределах глубины распространения хлорного облака с поражающей концентрацией (определяется расчетом)",
        ],
      },
      {
        code: "20109094",
        text: "С какой целью склады хлора оборудуются сплошным глухим ограждением высотой не менее двух метров?",
        answers: [
          "Для защиты территории склада от ветровых нагрузок",
          "Для ограничения распространения газовой волны в начальный период аварийной ситуации и исключения доступа посторонних лиц на территорию склада",
          "Для исключения случаев хищения жидкого хлора работниками склада",
        ],
        correctAnswers: [
          "Для ограничения распространения газовой волны в начальный период аварийной ситуации и исключения доступа посторонних лиц на территорию склада",
        ],
      },
      {
        code: "20109095",
        text: "Чем следует оборудовать производственные помещения без постоянных рабочих мест согласно Правилам безопасности производств хлора и хлорсодержащих сред?",
        answers: [
          "Аварийной или общеобменными вентиляционными системами",
          "Снаружи у входа в помещение необходимо предусматривать световую сигнализацию превышения уровня загазованности хлором в помещении",
          "При производстве ремонтных работ обогрев этих помещений должен быть осуществлен передвижными вентиляционно-отопительными устройствами",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20109096",
        text: "Каким должно быть давление сжатого воздуха (азота) при передавливании жидкого хлора газообразным хлором?",
        answers: [
          "Давление сжатого воздуха (азота) не должно превышать 2,1 МПа",
          "Давление сжатого воздуха (азота) должно быть не менее чем на 0,1 МПа выше давления в сосуде, в который передавливается хлор",
          "Давление сжатого воздуха (азота) не должно превышать 1,6 МПа",
          "Давление сжатого воздуха (азота) не должно превышать 1,2 МПа",
        ],
        correctAnswers: [
          "Давление сжатого воздуха (азота) не должно превышать 1,2 МПа",
        ],
      },
      {
        code: "20109097",
        text: "Какую вентиляцию следует использовать для локализации аварийных ситуаций на складах химического гипохлорита натрия и в помещениях насосных?",
        answers: [
          "Общеобменную приточно-вытяжную вентиляцию, которая должна иметь 2 резервных вентилятора, автоматически включающихся при выходе из строя рабочего агрегата",
          "Общеобменную вытяжную вентиляцию, которая должна иметь резервный вентилятор, автоматически включающийся при выходе из строя рабочего агрегата",
          "Естественную вентиляцию без дополнительных резервных вентиляторов",
          "Общеобменную приточную вентиляцию, которая должна иметь резервный вентилятор, включающийся автоматически при выходе из строя рабочего агрегата",
        ],
        correctAnswers: [
          "Общеобменную вытяжную вентиляцию, которая должна иметь резервный вентилятор, автоматически включающийся при выходе из строя рабочего агрегата",
        ],
      },
      {
        code: "20109098",
        text: "В каком из перечисленных случаев следует предусматривать установку обратных клапанов на насосах, используемых для перекачки гипохлорита натрия?",
        answers: [
          "На всасывающих линиях при подключении нескольких насосов к общему всасывающему коллектору",
          "На нагнетательных линиях дозирующих насосов",
          "Во всех перечисленных случаях",
          "На нагнетательных линиях перекачивающих насосов",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20109099",
        text: "В каком случае допускается предусматривать отдельные отделители жидкости, соединенные трубопроводами с циркуляционными (защитными) ресиверами, не совмещающими функции отделителя жидкости, для отделения жидкой фазы из перемещаемой парожидкостной смеси в системах холодоснабжения?",
        answers: [
          "Допускается только для аппаратов горизонтального типа",
          "Не допускается ни в каком случае",
          "Допускается только для аппаратов вертикального типа",
          "Допускается в обоснованных в проектной документации случаях",
        ],
        correctAnswers: [
          "Допускается в обоснованных в проектной документации случаях",
        ],
      },
      {
        code: "20109100",
        text: "Как рассчитывается геометрический объем защитных ресиверов (Vз.р.) вертикального типа, совмещающих функцию отделителя жидкости аммиачных холодильных установок, для каждой температуры кипения аммиака?",
        answers: [
          "Vз.р. должны быть более суммарного геометрического объема Vс устройств охлаждения и технологических аппаратов (для рассматриваемой температуры кипения аммиака), умноженного на коэффициент 1,2",
          "Vз.р. должны быть более суммарного геометрического объема Vс устройств охлаждения и технологических аппаратов (для рассматриваемой температуры кипения аммиака), умноженного на коэффициент 0,5",
          "Vз.р. должны быть более суммарного геометрического объема Vс устройств охлаждения и технологических аппаратов (для рассматриваемой температуры кипения аммиака), умноженного на коэффициент 0,6",
          "Vз.р. должны быть более суммарного геометрического объема Vс устройств охлаждения и технологических аппаратов (для рассматриваемой температуры кипения аммиака), умноженного на коэффициент 0,3",
        ],
        correctAnswers: [
          "Vз.р. должны быть более суммарного геометрического объема Vс устройств охлаждения и технологических аппаратов (для рассматриваемой температуры кипения аммиака), умноженного на коэффициент 0,5",
        ],
      },
      {
        code: "20109101",
        text: "Как рассчитывается геометрический объем защитных ресиверов (Vз.р.) вертикального типа, совмещающих функцию отделителя жидкости аммиачных холодильных установок, для каждой температуры кипения аммиака?",
        answers: [
          "Vз.р. больше Vс x 0,7 м³",
          "Vз.р. больше Vс x 1,1 м³",
          "Vз.р. больше Vс x 0,5 м³",
          "Vз.р. больше Vс x 0,4 м³",
          "Все ответы неверны",
        ],
        correctAnswers: ["Vз.р. больше Vс x 0,5 м³"],
      },
      {
        code: "20109102",
        text: "Какую скорость паров аммиака допускается принимать в сечении паровой зоны вертикального сосуда или аппарата, исполняющего функции отделителя жидкости?",
        answers: [
          "Не регламентируется",
          "Не более 0,5 м/с",
          "Не более 1,0 м/с",
          "Не более 1,2 м/с",
        ],
        correctAnswers: ["Не более 0,5 м/с"],
      },
      {
        code: "20109103",
        text: "Какое допускается максимальное заполнение геометрического объема дренажного ресивера для аварийного (ремонтного) освобождения от жидкого аммиака охлаждающих устройств, аппаратов, сосудов и блоков?",
        answers: ["На 75 %", "На 60 %", "На 95 %", "На 80 %"],
        correctAnswers: ["На 80 %"],
      },
      {
        code: "20109104",
        text: "В каком случае допускается предусматривать линейный ресивер для холодильных машин с дозированной зарядкой аммиака?",
        answers: [
          "Допускается предусматривать во всех перечисленных случаях",
          "Все ответы неверны",
          "Только если это обосновано проектной документацией",
          "Только если линейный ресивер используется в качестве защитного,  дренажного или циркуляционного",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20109105",
        text: "В каком месте может размещаться оборудование, работающее на аммиаке?",
        answers: [
          "В специальном помещении - машинном или аппаратном отделении",
          "Во всех перечисленных местах",
          "В помещении потребителей холода",
          "На открытой площадке",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20109106",
        text: "Где допускается размещать насосы оборотного водоснабжения в обоснованных в проектной документации случаях? Укажите все правильные ответы.",
        answers: [
          "В машинном отделении",
          "Над машинным отделением",
          "В аппаратном отделении",
          "На открытой площадке",
        ],
        correctAnswers: ["В машинном отделении", "В аппаратном отделении"],
      },
      {
        code: "20109107",
        text: "Каким следует принимать расстояние в свету от аппаратов (сосудов), расположенных снаружи машинного (аппаратного) отделения?",
        answers: [
          "Не менее 1,2 м от стены здания",
          "Не менее 1,0 м от стены здания",
          "Не менее 0,5 м от стены здания",
          "Не менее 0,7 м от стены здания",
        ],
        correctAnswers: ["Не менее 1,0 м от стены здания"],
      },
      {
        code: "20109108",
        text: "В каком случае допускается размещать холодильное оборудование над площадками открытых насосных и компрессорных установок? Укажите все правильные ответы.",
        answers: [
          "Не допускается в любом случае",
          "В случае размещения холодильного оборудования над площадками на расстоянии, определенном соответствующей проектной документацией",
          "В случае применения герметичных (бессальниковых) насосов",
          "При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование",
        ],
        correctAnswers: [
          "В случае применения герметичных (бессальниковых) насосов",
          "При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование",
        ],
      },
      {
        code: "20109109",
        text: "Какой должна быть ширина центрального прохода для обслуживания оборудования у вновь строящихся и реконструируемых аммиачных систем холодоснабжения?",
        answers: ["Не менее 1,2 м", "Не менее 1,5 м", "Более 1 м", "Более 2 м"],
        correctAnswers: ["Не менее 1,5 м"],
      },
      {
        code: "20109110",
        text: "Для постоянного обслуживания какого оборудования (арматуры) должна быть устроена металлическая площадка с ограждением и лестницей?",
        answers: [
          "Расположенного на уровне выше 1,8 м от пола",
          "Расположенного на уровне не ниже 1,7 м от пола",
          "Расположенного на уровне выше 1,6 м от пола",
          "Расположенного на определенном в проекте уровне",
        ],
        correctAnswers: ["Расположенного на уровне выше 1,8 м от пола"],
      },
      {
        code: "20109111",
        text: "Какое из перечисленных требований к поддонам (приямкам) для сбора жидкого аммиака в случае разгерметизации сосуда указано верно? Укажите все правильные ответы.",
        answers: [
          "Приямки должны иметь не менее двух лестниц, а при глубине приямка более 2 м - выход непосредственно наружу",
          "Количество пролитого аммиака из циркуляционного ресивера должно определяться по минимально допустимому заполнению сосуда",
          "Расчетный уровень жидкого аммиака в случае аварийного вытекания хладагента из наиболее аммиакоемкого сосуда в поддон (приямок) должен быть ниже бортика поддона (края приямка)",
          "Глубина приямка должна быть не более 3,5 м",
          "Количество пролитого аммиака из защитного ресивера должно определяться по номинальному заполнению сосуда",
        ],
        correctAnswers: [
          "Приямки должны иметь не менее двух лестниц, а при глубине приямка более 2 м - выход непосредственно наружу",
          "Расчетный уровень жидкого аммиака в случае аварийного вытекания хладагента из наиболее аммиакоемкого сосуда в поддон (приямок) должен быть ниже бортика поддона (края приямка)",
        ],
      },
      {
        code: "20109112",
        text: "В каком случае не допускается применение гибких резиновых или пластмассовых шлангов?",
        answers: [
          "В качестве стационарных трубопроводов для отсоса паров или подачи жидкого аммиака",
          "Для проведения вспомогательных операций (освобождение трубопроводов, аппаратов, фильтров от остатков аммиака, масла)",
          "При выполнении операций слива аммиака (при заполнении системы) из цистерны",
        ],
        correctAnswers: [
          "В качестве стационарных трубопроводов для отсоса паров или подачи жидкого аммиака",
        ],
      },
      {
        code: "20109113",
        text: "Каким минимальным количеством эвакуационных выходов должны оснащаться помещения машинного и аппаратного отделения?",
        answers: ["3", "2", "Количество выходов определяется проектом", "1"],
        correctAnswers: ["2"],
      },
      {
        code: "20109114",
        text: "Какие устройства применяются в качестве предохранительных на аммиачных холодильных установках?",
        answers: [
          "Пружинные предохранительные клапаны и мембранные предохранительные устройства",
          "Только предохранительные клапаны прямого действия",
          "Все перечисленные",
          "Только предохранительный клапан с мембранным чувствительным элементом",
        ],
        correctAnswers: [
          "Пружинные предохранительные клапаны и мембранные предохранительные устройства",
        ],
      },
      {
        code: "20109115",
        text: "Что учитывается при определении пропускной способности предохранительных устройств для защиты от разрушений сосудов, аппаратов и технологического оборудования, содержащих жидкий аммиак?",
        answers: [
          "Площадь наружной поверхности аппарата и удельная теплота парообразования аммиака при давлении насыщения в 2,5 раза больше расчетного давления защищаемого сосуда (аппарата)",
          "Скорость теплового потока испаряющегося аммиака в случае пожара",
          "Плотность теплового потока через наружные стенки сосуда, площадь наружной поверхности аппарата и удельная теплота парообразования аммиака при давлении насыщения в 1,15 раза больше расчетного давления защищаемого аппарата",
          "Общий объем аммиака в сосуде, аппарате",
        ],
        correctAnswers: [
          "Плотность теплового потока через наружные стенки сосуда, площадь наружной поверхности аппарата и удельная теплота парообразования аммиака при давлении насыщения в 1,15 раза больше расчетного давления защищаемого аппарата",
        ],
      },
      {
        code: "20109116",
        text: "По указанию какого лица должны производиться снятие предохранительных клапанов на проверку, их установка и пломбирование?",
        answers: [
          "Только лица, ответственного за проведение ремонтных работ",
          "Представителя проектной организации, лица, ответственного за проведение ремонтных работ, или технического руководителя организации",
          "Лица, ответственного за исправное состояние и безопасную работу сосудов (аппаратов)",
          "Только технического руководителя организации",
        ],
        correctAnswers: [
          "Лица, ответственного за исправное состояние и безопасную работу сосудов (аппаратов)",
        ],
      },
      {
        code: "20109117",
        text: "С какой периодичностью предохранительные устройства компрессорных агрегатов должны проверяться на давление срабатывания?",
        answers: [
          "Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией",
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 4 года",
        ],
        correctAnswers: [
          "Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией",
        ],
      },
      {
        code: "20109118",
        text: "Какой должна быть степень защиты электроприборов и средств автоматического и дистанционного управления, располагающихся в помещениях с аммиачным оборудованием?",
        answers: [
          "Не ниже IP44",
          "Не ниже IP21",
          "Не ниже IP55",
          "Не ниже IP34",
        ],
        correctAnswers: ["Не ниже IP44"],
      },
      {
        code: "20109119",
        text: "О чем сигнализирует красная лампа световой сигнализации?",
        answers: [
          "Сигнал об опасном повышении уровня жидкого аммиака в сосуде (предупредительная сигнализация)",
          "Сигнал о понижении верхнего уровня жидкого аммиака в сосуде (предаварийная сигнализация)",
          "Сигнал об аварийном останове аппарата",
          "Сигнал о предельно допустимом уровне жидкого аммиака в сосуде (предаварийная сигнализация)",
        ],
        correctAnswers: [
          "Сигнал о предельно допустимом уровне жидкого аммиака в сосуде (предаварийная сигнализация)",
        ],
      },
      {
        code: "20109120",
        text: "В каком случае допускается использование ртутных термометров и ртутных устройств для измерения температуры в контрольных точках аммиачной холодильной системы?",
        answers: [
          "Не допускается ни в каком случае",
          "В случае применения дополнительной защиты от механических повреждений",
          "В случае обоснования такого решения проектной документацией",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20109121",
        text: "На какую массовую нагрузку должны быть рассчитаны специальные опоры или подвески, на которые монтируются аммиачные трубопроводы аммиачных холодильных установок?",
        answers: [
          "Только на массу трубопровода и массу хладагента, принятых с коэффициентом запаса 1,2",
          "Только на массу хладагента, принятую с коэффициентом запаса 2,2",
          "На собственную массу трубопровода, массу хладагента и тепловой изоляции, принятых с коэффициентом запаса 1,2",
          "Только на массу трубопровода и массу тепловой изоляции, принятых с коэффициентом запаса 1,5",
        ],
        correctAnswers: [
          "На собственную массу трубопровода, массу хладагента и тепловой изоляции, принятых с коэффициентом запаса 1,2",
        ],
      },
      {
        code: "20109122",
        text: "Какой документ необходимо оформлять при выполнении монтажных работ в помещении и на участках действующей холодильной системы, а также в условиях недействующих узлов, находящихся под аммиаком или не отсоединенных от остальной части системы?",
        answers: [
          "Распоряжение на производство работ",
          "Оформление документа на проведение указанных работ не требуется",
          "План производства работ, утвержденный техническим руководителем организации",
          "Наряд-допуск на указанные работы",
        ],
        correctAnswers: ["Наряд-допуск на указанные работы"],
      },
      {
        code: "20109123",
        text: "В случае какой остановки сосуды, аппараты и трубопроводы холодильных установок должны подвергаться техническому освидетельствованию?",
        answers: [
          "Длительность остановки оборудования не влияет на необходимость проведения технического освидетельствования",
          "В случае остановки на срок более месяца",
          "Все ответы неверны",
          "В случае остановки на срок более 6 месяцев",
          "В случае остановки на срок более 3 месяцев",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20109124",
        text: "В течение какого времени нахождения цистерн с жидким аммиаком на территории организации должно быть организовано наблюдение за ними?",
        answers: [
          "Наблюдение не требуется",
          "В течение всего времени нахождения",
          "Только во время приемки цистерны",
          "Только во время слива аммиака",
        ],
        correctAnswers: ["В течение всего времени нахождения"],
      },
      {
        code: "20109125",
        text: "Кто утверждает годовые и месячные графики ремонта холодильного оборудования?",
        answers: [
          "Начальник службы производственного контроля организации",
          "Руководитель организации",
          "Технический руководитель организации",
        ],
        correctAnswers: ["Технический руководитель организации"],
      },
      {
        code: "20109126",
        text: "На каких из перечисленных участков аммиачных трубопроводов должны быть нанесены три опознавательных кольца?",
        answers: [
          "На паровых линиях стороны высокого давления",
          "На жидкостных линиях стороны высокого давления",
          "На парожидкостных линиях стороны низкого давления систем холодоснабжения",
          "На жидкостных линиях стороны низкого давления систем холодоснабжения",
        ],
        correctAnswers: ["На жидкостных линиях стороны высокого давления"],
      },
      {
        code: "20109127",
        text: "В какой цвет должны быть окрашены участки аммиачных трубопроводов, на которые наносятся опознавательные кольца?",
        answers: [
          "В белый цвет",
          "В голубой цвет",
          "В желтый цвет",
          "В зеленый цвет",
        ],
        correctAnswers: ["В желтый цвет"],
      },
      {
        code: "20109128",
        text: "Кто допускается к выполнению сварочных работ на опасном производственном объекте?",
        answers: [
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
          "Специалисты, обладающие необходимыми умениями и ознакомленные с требованиями охраны труда при производстве сварочных работ",
          "Сварщики и специалисты сварочного производства старше 21 года, имеющие группу по электробезопасности не ниже III и прошедшие обучение мерам пожарной безопасности",
        ],
        correctAnswers: [
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
        ],
      },
      {
        code: "20109129",
        text: "К выполнению каких работ могут быть допущены сварщики и специалисты сварочного производства?",
        answers: [
          "К выполнению сварочных работ, указанных в действующих документах",
          "К сварочным работам, на которые они когда-либо были аттестованы независимо от того, действует ли соответствующее аттестационное удостоверение",
          "К выполнению любых сварочных работ при наличии каких-либо действующих аттестационных удостоверений по какому-либо способу сварки",
        ],
        correctAnswers: [
          "К выполнению сварочных работ, указанных в действующих документах",
        ],
      },
      {
        code: "20109130",
        text: "Что должно быть указано в технологических картах сварки?",
        answers: [
          "Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений",
          "Требования к применяемым сварочным технологиям, последовательность проводимых операций, технические приемы, особенности процесса сварки, обеспечивающие качество всех соединений",
          "Требования к сварочным материалам и сварочному оборудованию, режимы сварки, последовательность операций, методы контроля качества сварных соединений",
        ],
        correctAnswers: [
          "Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений",
        ],
      },
      {
        code: "20109131",
        text: 'Какие требования предъявляются ФНП "Требования к производству сварочных работ на опасных производственных объектах" к сварочному оборудованию и сварочным материалам, применяемым при выполнении сварочных работ?',
        answers: [
          "Оборудование и материалы должны обеспечивать максимально возможную производительность работ и взаимозаменяемость",
          "Оборудование и материалы должны быть взаимозаменяемы при использовании, а также просты в ремонте",
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
        ],
        correctAnswers: [
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "20109132",
        text: "Что обязано проверить и обеспечить лицо, осуществляющее руководство сварочными работами, перед выполнением сварочных работ?",
        answers: [
          "Только соответствие численного состава и квалификации персонала сварочного производства требованиям ПТД",
          "Выполнение всех подготовительных мероприятий",
          "Только соответствие сборочного и сварочного оборудования, применяемой технологии сварки требованиям ПТД",
          "Только соответствие основных и сварочных материалов требованиям ПТД",
        ],
        correctAnswers: ["Выполнение всех подготовительных мероприятий"],
      },
      {
        code: "20109133",
        text: "Какие функции обязано выполнить лицо, осуществляющее руководство сварочными работами, назначенное распорядительным документом организации или (и) должностной инструкцией которого предусмотрено руководство сварочными работами, перед выполнением сварочных работ?",
        answers: [
          "Только организовать проведение операционного контроля",
          "Все перечисленные функции",
          "Только ознакомить сварщиков с требованиями технологических карт сварки, а также с внесенными в них изменениями (при наличии) с подтверждением ознакомления подписями сварщиков в применяемых ими технологических картах сварки",
          "Только проверить и обеспечить соответствие численного состава и квалификации персонала сварочного производства, сборочного и сварочного оборудования, основных и сварочных материалов, применяемой технологии сварки требованиям ПТД",
        ],
        correctAnswers: ["Все перечисленные функции"],
      },
      {
        code: "20109134",
        text: "Какую проверку должен пройти сварщик, впервые приступающий к сварке, перед допуском к работе?",
        answers: [
          "Проверку путем выполнения и контроля допускного сварного соединения",
          "Проверку знания теоретических основ сварочного дела",
          "Проверку умения определять и устранять видимые и скрытые дефекты сварного соединения",
        ],
        correctAnswers: [
          "Проверку путем выполнения и контроля допускного сварного соединения",
        ],
      },
      {
        code: "20109135",
        text: "Какую проверку должен пройти сварщик, приступающий к сварке на конкретном объекте впервые или после перерыва в работе продолжительностью более установленного НД, независимо от наличия аттестационного удостоверения, до начала производства работ?",
        answers: [
          "Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте, с получением положительных результатов контроля их качества",
          "Проверку умения определять и устранять видимые и скрытые дефекты сварного соединения",
          "Проверку знания теоретических основ сварочного дела",
        ],
        correctAnswers: [
          "Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте, с получением положительных результатов контроля их качества",
        ],
      },
      {
        code: "20109136",
        text: "Чем должно быть укомплектовано место производства сварочных работ?",
        answers: [
          "На месте производства сварочных работ не должно быть ничего, кроме исправного сварочного оборудования",
          "Необходимым сварочным оборудованием и наглядными пособиями (плакатами, схемами) с правилами противопожарного режима на объекте",
          "Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД",
        ],
        correctAnswers: [
          "Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД",
        ],
      },
      {
        code: "20109137",
        text: "Каким образом допускается маркировать сварное соединение, выполненное несколькими сварщиками (бригадой сварщиков)?",
        answers: [
          "Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
          "Допускается применение клейма только того сварщика, который выполнил наибольший объем работ",
          "Допускается применение клейма любого из участвовавших в работе сварщиков (по указанию руководителя сварочных работ)",
        ],
        correctAnswers: [
          "Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
        ],
      },
      {
        code: "20109138",
        text: "Какая документация оформляется в процессе выполнения сварочных работ?",
        answers: [
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
          "Только журналы сварочных работ, паспорта, заключения по неразрушающему контролю",
          "Только акты, заключения и протоколы испытаний сварных соединений",
        ],
        correctAnswers: [
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "20109139",
        text: "В каком случае допускается проведение сварочных и резательных работ на объектах защиты, в конструкциях которых использованы горючие материалы?",
        answers: [
          "Только после согласования с Государственной противопожарной службой",
          "Допускается в случае, если конструкции ограждены сплошной перегородкой из негорючего материала",
          "Только в случае нахождения на месте работы первичных средств пожаротушения в достаточном объеме",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "Допускается в случае, если конструкции ограждены сплошной перегородкой из негорючего материала",
        ],
      },
      {
        code: "20109140",
        text: "Какие знаки вывешиваются рядом с местами хранения ила (выработанного карбида кальция)?",
        answers: [
          "Об опасности химического поражения",
          "О возможности отравления ядовитыми газами",
          "О запрете подходить на расстояние ближе 5 м",
          "О запрете курения и применения открытого огня в радиусе 10 м",
        ],
        correctAnswers: [
          "О запрете курения и применения открытого огня в радиусе 10 м",
        ],
      },
      {
        code: "20109141",
        text: "Какое из перечисленных требований при проведении газосварочных работ указано верно?",
        answers: [
          "Запрещается в местах хранения и вскрытия барабанов с карбидом кальция курение, пользование открытым огнем; допускается применение искрообразующего инструмента",
          "Запрещается хранение в одном помещении кислородных баллонов и баллонов с горючими газами",
          "Разрешается хранение в одном помещении кислородных баллонов и карбида кальция, красок, масел и жиров",
          "Разрешается  курение и применение открытого огня в радиусе более 5 метров от мест хранения известкового ила",
        ],
        correctAnswers: [
          "Запрещается хранение в одном помещении кислородных баллонов и баллонов с горючими газами",
        ],
      },
      {
        code: "20109142",
        text: "Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?",
        answers: [
          "Прошедшие медицинский осмотр в соответствии с требованиями законодательства Российской Федерации",
          "Не моложе 18 лет",
          "Прошедшие обучение приемам и методам проведения работ",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20109143",
        text: "К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?",
        answers: [
          "К группе работ очень высокой степени опасности",
          "К группе работ низкой степени опасности",
          "К I группе",
          "Ко II группе",
        ],
        correctAnswers: ["Ко II группе"],
      },
      {
        code: "20109144",
        text: "Кто определяет структурные подразделения, на которые возложены полномочия по согласованию перечня газоопасных работ?",
        answers: [
          "Руководитель эксплуатирующей организации",
          "Главный инженер (технический директор) эксплуатирующей организации",
          "Руководитель аварийно-спасательной службы совместно с руководителем эксплуатирующей организации",
          "Руководитель службы производственного контроля (лицо, ответственное за осуществление производственного контроля)",
        ],
        correctAnswers: ["Руководитель эксплуатирующей организации"],
      },
      {
        code: "20109145",
        text: "С какой периодичностью необходимо пересматривать и переутверждать перечень газоопасных работ?",
        answers: [
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 5 лет",
          "Не реже 1 раза в год",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "20109146",
        text: "Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в месячный срок",
          "Запрещается выполнять какие-либо работы, не включенные в утвержденный перечень газоопасных работ",
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
      {
        code: "20109147",
        text: "Что должен сделать руководитель структурного подразделения, где будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?",
        answers: [
          "Определить структурные подразделения организации, которым поручено взаимодействовать с бригадой, выполняющей газоопасные работы",
          "Провести обучение и инструктаж персонала, выполняющего газоопасные работы",
          "Обеспечить работников специализированным оборудованием (в том числе газоанализаторами), необходимым для проведения газоопасных работ",
          "Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы",
        ],
        correctAnswers: [
          "Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы",
        ],
      },
      {
        code: "20109148",
        text: "Что должен сделать руководитель структурного подразделения, на объекте которого будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?",
        answers: [
          "Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ, а также средствами индивидуальной и коллективной защиты",
          "Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы",
          "Провести инструктаж и целевое обучение персонала, проводящего газоопасные работы",
          "Определить структурное подразделение, с которым предстоит взаимодействовать бригаде, проводящей газоопасные работы",
        ],
        correctAnswers: [
          "Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы",
        ],
      },
      {
        code: "20109149",
        text: "Какая из перечисленных подготовительных работ к проведению газоопасных работ в пределах площади, где возможно поступление паров и газов опасных веществ, указана неверно?",
        answers: [
          "Обозначается (ограждается) место проведения газоопасной работы в пределах площади, где возможно поступление паров и газов опасных веществ",
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
          "По решению лица, ответственного за подготовку газоопасной работы, выставляются посты в целях исключения допуска посторонних лиц в опасную зону",
        ],
        correctAnswers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
        ],
      },
      {
        code: "20109150",
        text: "О чем опрашивает каждого исполнителя лицо, ответственное за проведение газоопасных работ?",
        answers: [
          "О самочувствии",
          "О наличии медицинских противопоказаний к работе",
          "О знании правил оказания первой медицинской помощи",
          "О знании персональных действий при пожаре",
        ],
        correctAnswers: ["О самочувствии"],
      },
      {
        code: "20109151",
        text: "При каких условиях разрешается входить в газоопасное место при проведении газоопасных работ?",
        answers: [
          "Только с разрешения лица, ответственного за осуществление производственного контроля, в средствах индивидуальной защиты органов дыхания",
          "Только с разрешения представителя газоспасательной службы при обеспечении принудительного воздухообмена в газоопасном месте",
          "Только с разрешения лица, ответственного за подготовку и проведение работ, с инструментом, исключающим возможность искрообразования",
          "Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны",
        ],
        correctAnswers: [
          "Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны",
        ],
      },
      {
        code: "20109152",
        text: "Какое количество наблюдающих должно быть, если существует необходимость выполнения газоопасных работ в емкости (аппарате) двумя работающими?",
        answers: [
          "Не менее 4",
          "Не менее 3",
          "Не менее 2",
          "Количество наблюдающих определяет ответственный за проведение газоопасных работ",
        ],
        correctAnswers: ["Не менее 2"],
      },
      {
        code: "20109153",
        text: "Кому лицо, ответственное за проведение газоопасных работ, передает наряд-допуск после его закрытия?",
        answers: [
          "Первый экземпляр - главному инженеру или руководителю службы производственного контроля, а второй -  в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)",
          "Оба экземпляра - в аварийно-спасательную службу организации",
          "Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)",
        ],
        correctAnswers: [
          "Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)",
        ],
      },
      {
        code: "20109154",
        text: "Какими документами определяется перечень постоянных мест проведения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?",
        answers: [
          "Организационно-распорядительными документами организации",
          "Федеральными нормами и правилами в области промышленной безопасности",
          "Технологическими регламентами",
          "Правилами противопожарного режима",
        ],
        correctAnswers: [
          "Организационно-распорядительными документами организации",
        ],
      },
      {
        code: "20109155",
        text: "Кто определяет структурные подразделения, на которые возлагается согласование наряда-допуска на выполнение огневых работ?",
        answers: [
          "Руководитель структурного подразделения (его заместитель)",
          "Руководитель эксплуатирующей организации",
          "Руководитель структурного подразделения (производства, цеха, отделения, установки, участка) совместно с руководителем службы производственного контроля",
        ],
        correctAnswers: ["Руководитель эксплуатирующей организации"],
      },
      {
        code: "20109156",
        text: "Кто осуществляет подготовку объекта к проведению на нем огневых работ?",
        answers: [
          "Правилами не регламентируется",
          "Работники, осуществляющие эксплуатацию объекта",
          "Работники подрядных организаций",
          "Работники, перечисленные в наряде-допуске",
        ],
        correctAnswers: ["Работники, осуществляющие эксплуатацию объекта"],
      },
      {
        code: "20109157",
        text: "Какие из перечисленных действий, производимых при подготовке объекта к огневым работам, указаны неверно?",
        answers: [
          "Электроприводы движущихся механизмов аппаратов, машин, которые находятся в зоне выполнения огневых работ, должны быть отключены от источников питания, отсоединены от этих механизмов видимым разрывом",
          "Аппараты, машины, емкости, трубопроводы и другое оборудование, на которых будут проводиться огневые работы, должны быть остановлены, освобождены от опасных веществ",
          "Аппараты, машины, и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ",
          'На пусковых устройствах должны быть вывешены плакаты "Не включать: работают люди!", которые снимаются по окончании работ по указанию лица, ответственного за проведение огневых работ',
        ],
        correctAnswers: [
          "Аппараты, машины, и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ",
        ],
      },
      {
        code: "20109158",
        text: "Какие плакаты вывешиваются на пусковых устройствах у аппаратов и в электрораспределительных устройствах при производстве газоопасных работ?",
        answers: [
          '"Высокое напряжение. Опасно для жизни!"',
          '"Не включать: не в фазе!"',
          '"Не включать: работа на линии!"',
          '"Не включать: работают люди!"',
        ],
        correctAnswers: ['"Не включать: работают люди!"'],
      },
      {
        code: "20109159",
        text: "При какой концентрации пожаровзрывоопасных веществ не допускается проведение огневых работ?",
        answers: [
          "При наличии пожаровзрывоопасных веществ выше 30 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения",
          "При наличии пожаровзрывоопасных веществ выше 20 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения",
          "При наличии пожаровзрывоопасных веществ выше 10 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения",
        ],
        correctAnswers: [
          "При наличии пожаровзрывоопасных веществ выше 20 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения",
        ],
      },
      {
        code: "20109160",
        text: "В соответствии с каким документом устанавливается периодичность контроля за состоянием воздушной среды?",
        answers: [
          "В соответствии с разработанным технологическим регламентом",
          "В соответствии с требованиями, установленными Правилами пожарной безопасности",
          "В соответствии с нарядом-допуском на выполнение огневых работ",
        ],
        correctAnswers: [
          "В соответствии с нарядом-допуском на выполнение огневых работ",
        ],
      },
      {
        code: "20109161",
        text: "В соответствии с каким документом устанавливается контроль за состоянием воздушной среды?",
        answers: [
          "В соответствии с разработанным технологическим регламентом",
          "В соответствии с нарядом-допуском на выполнение огневых работ",
          "В соответствии с требованиями, установленными в Правилах противопожарного режима",
        ],
        correctAnswers: [
          "В соответствии с нарядом-допуском на выполнение огневых работ",
        ],
      },
      {
        code: "20109162",
        text: "Что из перечисленного следует выполнить для проведения огневых работ внутри емкости (аппарата) при проведении газоопасных работ?",
        answers: [
          "Следует к наряду-допуску на проведение газоопасных работ приложить перечень всех мест выполнения огневых работ",
          "Следует получить письменное разрешение от лица, утвердившего наряд-допуск на проведение газоопасных работ, приложив его к наряду-допуску на проведение газоопасных работ",
          "Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)",
          "Следует оформить наряд-допуск на проведение огневых работ, закрыв при этом наряд-допуск на выполнение газоопасных работ",
        ],
        correctAnswers: [
          "Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)",
        ],
      },
      {
        code: "20109163",
        text: "Какие сведения не указываются в организационно-распорядительном документе для остановки на ремонт объекта или оборудования?",
        answers: [
          "Непосредственный руководитель работ, а также лица, ответственные за подготовку объекта в целом или оборудования к ремонту",
          "Сроки остановки, подготовки, ремонта и пуска объекта или оборудования, технических устройств, коммуникаций в эксплуатацию",
          "Наличие плана мероприятий по локализации аварий и ликвидации их последствий",
        ],
        correctAnswers: [
          "Наличие плана мероприятий по локализации аварий и ликвидации их последствий",
        ],
      },
      {
        code: "20109164",
        text: "Кто производит подключение к электросетям передвижных электроприемников подрядной организации и их отключение при проведении ремонтных работ?",
        answers: [
          "Электротехнический персонал эксплуатирующей организации",
          "Ремонтный персонал, назначенный руководителем эксплуатирующей организации и определенный в наряде-допуске",
          "Электротехнический персонал подрядной организации под надзором непосредственного руководителя работ",
        ],
        correctAnswers: [
          "Электротехнический персонал эксплуатирующей организации",
        ],
      },
      {
        code: "20109165",
        text: "Каким образом фиксируется прохождение инструктажа исполнителями ремонтных работ?",
        answers: [
          "Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске",
          "Руководитель службы производственного контроля делает запись в журнале проведения инструктажа структурного подразделения ремонтируемого объекта и отметку в наряде-допуске",
          "Руководитель структурного подразделения ремонтируемого объекта делает отметку в журнале проведения инструктажа и сообщает о проведении инструктажа руководителю бригады, выполняющей ремонтные работы",
        ],
        correctAnswers: [
          "Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске",
        ],
      },
      {
        code: "20109166",
        text: "Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в электронном виде?",
        answers: [
          "Не допускается",
          "Допускается при наличии разрешающих внутренних документов организации, определяющих порядок использования электронной подписи",
          "Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия",
        ],
        correctAnswers: [
          "Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия",
        ],
      },
      {
        code: "20109167",
        text: "Какие из перечисленных документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ, указаны неверно?",
        answers: [
          "Технологический регламент",
          "Инструкции по эксплуатации и безопасному проведению ремонтных работ",
          "Проект производства ремонтных работ",
          "Требования к проведению огневых и газоопасных работ",
        ],
        correctAnswers: ["Проект производства ремонтных работ"],
      },
    ],

    20110: [
      {
        code: "20110000",
        text: "Кто делает обоснование по применению эффективности и надежности мер и технических средств противоаварийной защиты, направленных на обеспечение взрывобезопасности отдельного блока и в целом всей технологической системы?",
        answers: [
          "Проектная организация",
          "Эксплуатирующая организация",
          "Экспертная организация",
          "Монтажно-наладочная организация",
        ],
        correctAnswers: ["Проектная организация"],
      },
      {
        code: "20110001",
        text: "Что является критерием взрывоопасности согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?",
        answers: [
          "Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом",
          "Класс опасности веществ, обращающихся в процессе",
          "Температура самовоспламенения паров, обращающихся в процессе веществ",
          "Скорость распространения горения веществ, обращающихся в процессе",
        ],
        correctAnswers: [
          "Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом",
        ],
      },
      {
        code: "20110002",
        text: "Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?",
        answers: [
          "Энергией сгорания парогазовой фазы",
          "Категорией взрывоопасности технологических блоков",
          "Приведенной массой вещества, участвующего во взрыве",
          "Радиусом зон разрушения",
        ],
        correctAnswers: ["Категорией взрывоопасности технологических блоков"],
      },
      {
        code: "20110003",
        text: "Какой категории взрывоопасности технологических блоков не существует?",
        answers: [
          "I категории",
          "II категории",
          "III категории",
          "IV категории",
        ],
        correctAnswers: ["IV категории"],
      },
      {
        code: "20110004",
        text: "На сколько категорий взрывоопасности подразделяются при проектировании технологические блоки взрывопожароопасных производств и объектов?",
        answers: [
          "На три категории",
          "На четыре категории",
          "На две категории",
          "На пять категорий",
        ],
        correctAnswers: ["На три категории"],
      },
      {
        code: "20110005",
        text: "Какой следует принимать категорию взрывоопасности блоков, определяемую расчетом, если обращающиеся в технологическом блоке опасные вещества относятся к токсичным, высокотоксичным веществам?",
        answers: [
          "На одну выше",
          "I категории",
          "II категории",
          "III категории",
        ],
        correctAnswers: ["На одну выше"],
      },
      {
        code: "20110006",
        text: "Какой порядок установлен для внесения изменений в технологическую схему, аппаратурное оформление, в системы контроля, связи, оповещения и противоаварийной автоматической защиты?",
        answers: [
          "Изменения вносятся после внесения изменений в проектную документацию или документацию на техническое перевооружение, согласованную с разработчиком проектной документации или с организацией, специализирующейся на проектировании аналогичных объектов, или при наличии положительного заключения экспертиз по проектной документации (документации)",
          "Изменения вносятся при наличии положительного заключения экспертизы промышленной безопасности и внесения его в реестр заключений экспертизы",
          "Изменения вносятся путем разработки обоснования безопасности опасного производственного объекта, получения на него положительного заключения экспертизы промышленной безопасности и внесения его в реестр заключений экспертизы",
        ],
        correctAnswers: [
          "Изменения вносятся после внесения изменений в проектную документацию или документацию на техническое перевооружение, согласованную с разработчиком проектной документации или с организацией, специализирующейся на проектировании аналогичных объектов, или при наличии положительного заключения экспертиз по проектной документации (документации)",
        ],
      },
      {
        code: "20110007",
        text: "В соответствии с чем осуществляется ведение технологических процессов на опасных производственных объектах нефтехимических и нефтегазоперерабатывающих производств?",
        answers: [
          "В соответствии с технологическими регламентами на производство продукции",
          "В соответствии с рекомендациями завода-изготовителя",
          "В соответствии с технологической инструкцией",
          "В соответствии с распоряжениями руководителя эксплуатирующей организации",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами на производство продукции",
        ],
      },
      {
        code: "20110008",
        text: "Какая из перечисленных мер должна предусматриваться для блоков технологической системы по максимальному снижению взрывоопасности?",
        answers: [
          "Предотвращение взрывов внутри технологического оборудования",
          "Использование вентиляционных систем для исключения возможности взрывов и пожаров в объеме производственных зданий, сооружений",
          "Периодический контроль за состоянием воздушной среды",
          "Использование эффективных систем пожаротушения в зависимости от особенностей технологического процесса",
        ],
        correctAnswers: [
          "Предотвращение взрывов внутри технологического оборудования",
        ],
      },
      {
        code: "20110009",
        text: "В какой документации приводятся способы и средства, исключающие выход параметров за установленные пределы?",
        answers: [
          "В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции",
          "В эксплуатационной документации",
          "Только в исходных данных на проектирование",
          "Только в документации на техническое перевооружение",
        ],
        correctAnswers: [
          "В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20110010",
        text: "Каким образом осуществляется управление подачей инертных сред на установку с технологическими блоками любой категории взрывоопасности там, где при отклонении от регламентированных значений параметров возможно образование взрывоопасных смесей?",
        answers: [
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту",
          "Для установок с технологическими блоками I, II и III категории взрывоопасности - автоматическое управление, а при Qв ≤ 10 - управление ручное, дистанционное",
          "Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, для установок с технологическими блоками II категории взрывоопасности - ручное, дистанционное, для установок с технологическими блоками III категории взрывоопасности допускается ручное по месту",
          "Для установок с технологическими блоками I, II и III категории взрывоопасности - автоматическое управление",
        ],
        correctAnswers: [
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту",
        ],
      },
      {
        code: "20110011",
        text: "Какое управление системами подачи инертных сред в технологические системы должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категории взрывоопасности?",
        answers: [
          "Дистанционное, неавтоматическое",
          "Ручное управление по месту",
          "Автоматическое",
        ],
        correctAnswers: ["Автоматическое"],
      },
      {
        code: "20110012",
        text: "В течение какого времени средства обеспечения энергоустойчивости технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?",
        answers: [
          "В течение времени, достаточного для исключения опасной ситуации",
          "В течение 24 часов",
          "В течение 12 часов",
          "В течение 8 часов",
        ],
        correctAnswers: [
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
      },
      {
        code: "20110013",
        text: "Каким документом определяется время срабатывания запорных и (или) отсекающих устройств при аварийной разгерметизации системы?",
        answers: [
          "Проектной документацией или документацией на техническое перевооружение",
          "Эксплуатационной документацией",
          "Нормативной документацией",
          "Планом ликвидации аварий",
        ],
        correctAnswers: [
          "Проектной документацией или документацией на техническое перевооружение",
        ],
      },
      {
        code: "20110014",
        text: "Какими приборами и средствами автоматизации оснащаются сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Выберите два правильных варианта ответа.",
        answers: [
          "Блокировками отключения компрессора при превышении предельно допустимого значения уровня",
          "Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации",
          "Приборами контроля перепада давления на сепараторе",
          "Приборами контроля температуры и плотности отделяемой жидкой фазы",
        ],
        correctAnswers: [
          "Блокировками отключения компрессора при превышении предельно допустимого значения уровня",
          "Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации",
        ],
      },
      {
        code: "20110015",
        text: "Чем должно оснащаться оборудование для измельчения и смешивания измельченных твердых горючих продуктов для обеспечения эксплуатационной безопасности в отношении риска взрыва?",
        answers: [
          "Сигнализацией об отклонении давления подаваемого инертного газа от регламентированных значений",
          "Датчиками контроля вибрации",
          "Автоматическими блокировками, не допускающими пуск в работу оборудования без предварительной продувки инертным газом в течение 7 минут",
          "Средствами контроля за температурой измельченных твердых горючих продуктов",
        ],
        correctAnswers: [
          "Сигнализацией об отклонении давления подаваемого инертного газа от регламентированных значений",
        ],
      },
      {
        code: "20110016",
        text: "Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Средства автоматики должны быть обозначены по месту их размещения в технологическом регламенте и инструкциях",
          "Средства автоматики должны быть обозначены только в технологическом регламенте",
          "Средства автоматики должны быть обозначены в заключении экспертизы промышленной безопасности",
          "Средства автоматики должны быть обозначены на мнемосхемах",
        ],
        correctAnswers: [
          "Средства автоматики должны быть обозначены по месту их размещения в технологическом регламенте и инструкциях",
        ],
      },
      {
        code: "20110017",
        text: "В течение какого времени буферные емкости (реципиенты) должны обеспечивать питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров?",
        answers: [
          "В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
          "Время должно быть подтверждено расчетом, но не менее 25 минут",
          "Время должно быть подтверждено расчетом, но не менее 30 минут",
          "Время должно быть подтверждено расчетом, но не менее 40 минут",
        ],
        correctAnswers: [
          "В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20110018",
        text: "Каким из перечисленных требований должны соответствовать помещения управления? Укажите все правильные ответы.",
        answers: [
          "Помещения должны иметь световую и звуковую сигнализацию о загазованности",
          "Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам",
          "Прокладка по полу кабелей должна осуществляться с использованием швеллеров и уголков",
          "Полы в помещениях должны быть с повышенной механической стойкостью",
        ],
        correctAnswers: [
          "Помещения должны иметь световую и звуковую сигнализацию о загазованности",
          "Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам",
        ],
      },
      {
        code: "20110019",
        text: "Какая система отопления должна применяться в помещениях, имеющих взрывоопасные зоны?",
        answers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
          "Система парового отопления",
          "Система водяного отопления",
        ],
        correctAnswers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
        ],
      },
      {
        code: "20110020",
        text: "Что из перечисленного является недопустимым на территории предприятия, имеющего в своем составе взрывопожароопасные производства?",
        answers: [
          "Только устройство открытых траншей, котлованов, приямков, в которых возможно скопление взрывопожароопасных паров и газов",
          "Только наличие природных оврагов, выемок, низин",
          "Только траншейная и наземная прокладка трасс трубопроводов со сжиженными горючими газами, легковоспламеняющимися и горючими жидкостями в искусственных или естественных углублениях",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20110021",
        text: "Кем определяются предельные значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывоопасных характеристик, физико-химических свойств транспортируемых веществ, свойств конструкционных материалов и характеристик технических устройств, применяемых для перемещения горючих продуктов?",
        answers: [
          "Руководителем службы производственного контроля",
          "Техническими специалистами организаций, эксплуатирующих объекты",
          "Разработчиком проекта",
          "Руководителем (заместителем руководителя) организации, эксплуатирующей объекты",
        ],
        correctAnswers: ["Разработчиком проекта"],
      },
      {
        code: "20110022",
        text: "Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества?",
        answers: [
          "Системами ручного (без применения вычислительной техники) регулирования",
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
          "Системами, обеспечивающими непрерывность технологического процесса",
        ],
        correctAnswers: [
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
        ],
      },
      {
        code: "20110023",
        text: "Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?",
        answers: [
          "Время срабатывания устанавливается для каждого технологического блока в соответствии с категорией взрывоопасности",
          "Время срабатывания определяется расчетом",
          "Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и устанавливается для блоков III категории",
          "Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и устанавливается для блоков I и II категорий",
        ],
        correctAnswers: ["Время срабатывания определяется расчетом"],
      },
      {
        code: "20110024",
        text: "Какими блокировками на отключение должны быть оснащены насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?",
        answers: [
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях",
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости в корпусе насоса, достижении опасных значений в приемной емкости",
          "Блокировками, исключающими пуск и (или) прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях",
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
        correctAnswers: [
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
      },
      {
        code: "20110025",
        text: "Кем определяется степень разделения материальных сред и меры взрывобезопасности на всех стадиях процесса?",
        answers: [
          "Разработчиком процесса",
          "Разработчиком проекта",
          "Степень разделения определяется заказчиком в задании на проектирование, а меры взрывобезопасности - разработчиком проекта",
        ],
        correctAnswers: ["Разработчиком процесса"],
      },
      {
        code: "20110026",
        text: "Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?",
        answers: [
          "На установках с технологическими блоками I категории взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками II и III категорий взрывоопасности - не регламентируется",
          "На установках с технологическими блоками I и II категорий взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками III категории взрывоопасности - не регламентируется",
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
          "Давление теплоносителя (хладагента) не должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
        ],
        correctAnswers: [
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
        ],
      },
      {
        code: "20110027",
        text: "Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?",
        answers: [
          "Заказчиком в задании на проектирование",
          "Разработчиком процесса",
          "Разработчиком проекта",
        ],
        correctAnswers: ["Разработчиком процесса"],
      },
      {
        code: "20110028",
        text: "Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся жидкости и горючие жидкости на сливоналивных эстакадах?",
        answers: [
          "Должно быть организовано управление по месту",
          "Должно быть организовано управление дистанционно (из безопасного места)",
          "Должно быть организовано управление по месту и дистанционно (из безопасного места)",
          "Определяется разработчиком проекта",
        ],
        correctAnswers: [
          "Должно быть организовано управление по месту и дистанционно (из безопасного места)",
        ],
      },
      {
        code: "20110029",
        text: "Какие сведения являются основополагающими для выбора оборудования при разработке технологических процессов?",
        answers: [
          "Расчетные данные, которым должны соответствовать параметры оборудования и показатели надежности",
          "Расчетные данные, которым должны соответствовать параметры оборудования и требования действующих нормативных документов",
          "Расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование и требования действующих нормативных документов",
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
        correctAnswers: [
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
      },
      {
        code: "20110030",
        text: "Как производителем должна подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств до начала их применения на опасном производственном объекте?",
        answers: [
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
          "Посредством заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования и экспертизы промышленной безопасности",
          "Посредством оценки научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования",
          "Посредством опытных работ, проводимых разработчиком данного оборудования, и экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
        ],
      },
      {
        code: "20110031",
        text: "Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?",
        answers: [
          "Оборудование должно быть изолировано от действующей системы, а нанесенное на нем обозначение номера по технологической схеме закрашено",
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем",
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно должно быть изолировано от действующих систем",
          "Оборудование должно быть демонтировано",
        ],
        correctAnswers: [
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем",
        ],
      },
      {
        code: "20110032",
        text: "Что из перечисленного должно быть учтено при выборе компрессоров и насосов для перемещения горючих, сжатых и сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?",
        answers: [
          "Только показатели надежности и конструктивные особенности с учетом критических параметров",
          "Только физико-химические свойства перемещаемых продуктов",
          "Только регламентированные параметры технологического процесса",
          "Должны быть учтены все перечисленные характеристики",
        ],
        correctAnswers: ["Должны быть учтены все перечисленные характеристики"],
      },
      {
        code: "20110033",
        text: "В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?",
        answers: [
          "При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости",
          "При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости",
          "При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях",
          "При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации",
        ],
        correctAnswers: [
          "При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации",
        ],
      },
      {
        code: "20110034",
        text: "В соответствии с чем должны определяться оптимальные методы создания системы противоаварийной защиты на стадии формирования требований при проектировании автоматизированной системы управления технологическим процессом?",
        answers: [
          "В соответствии со сценарием возможных аварийных ситуаций и способами перевода объекта в безопасное состояние",
          "В соответствии с алгоритмом, разработанным по сценариям развития возможных аварий",
          "В соответствии с методикой, применяемой для моделирования аварийных ситуаций, согласованной с Ростехнадзором",
          "В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности",
        ],
        correctAnswers: [
          "В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности",
        ],
      },
      {
        code: "20110035",
        text: "Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания противоаварийной автоматической защиты?",
        answers: [
          "Средствами автоматического управления объектом по действующим программам",
          "Выполняется обслуживающим персоналом по инструкции",
          "Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений",
          "Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию противоаварийной автоматической защиты",
        ],
        correctAnswers: ["Выполняется обслуживающим персоналом по инструкции"],
      },
      {
        code: "20110036",
        text: "Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?",
        answers: [
          "Средствами автоматического управления объектом по действующим программам",
          "Обслуживающим персоналом по инструкции",
          "Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений",
          "Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию противоаварийной автоматической защиты",
        ],
        correctAnswers: ["Обслуживающим персоналом по инструкции"],
      },
      {
        code: "20110037",
        text: "Каким образом обеспечивается надежность обеспечения средств управления и системы противоаварийной защиты сжатым воздухом?",
        answers: [
          "Установкой резервного компрессора с включением его автоматически при остановке рабочего",
          "Переключением сети воздуха контрольно-измерительных приборов и автоматики на заводскую сеть сжатого воздуха через осушитель",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты в течение 2 часов",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
        correctAnswers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20110038",
        text: "Каким образом обеспечивается надежность обеспечения средств управления и противоаварийной автоматической защиты сжатым воздухом?",
        answers: [
          "Установкой резервного компрессора с включением его автоматически при остановке рабочего",
          "Переключением сети воздуха контрольно-измерительных приборов и автоматики на заводскую сеть сжатого воздуха через осушитель",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты в течение 2 часов",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
        correctAnswers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20110039",
        text: "При каких условиях допускается отключение защит (единовременно не более одного параметра) для непрерывных процессов?",
        answers: [
          "При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами",
          "По письменному разрешению технического руководителя организации только в дневную смену при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, и в присутствии начальника производства",
          "В присутствии начальника производства и начальника службы контрольно-измерительных приборов и автоматики только в дневную смену при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ",
        ],
        correctAnswers: [
          "При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами",
        ],
      },
      {
        code: "20110040",
        text: "Что должно быть учтено в системах управления и защит электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?",
        answers: [
          "Наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения",
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
          "Обеспечение селективности защит на устройствах электроснабжающей и электропотребляющей организации",
          "Наличие возможности синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой",
        ],
        correctAnswers: [
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
        ],
      },
      {
        code: "20110041",
        text: 'Какое требование к системам вентиляции не соответствует ФНП "Общие правила взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств"?',
        answers: [
          "Системы аварийной вентиляции должны быть оснащены средствами их автоматического включения при срабатывании установленных в помещении сигнализаторов довзрывных концентраций или газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
          "Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства",
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: "20110042",
        text: "Какое требование к системам вентиляции указано неверно?",
        answers: [
          "Системы аварийной вентиляции должны быть оснащены средствами их автоматического включения при срабатывании установленных в помещении сигнализаторов довзрывных концентраций или газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
          "Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства",
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: "20110043",
        text: "Какая максимальная температура поверхностей нагрева систем отопления должна быть в помещениях, имеющих взрывоопасные зоны?",
        answers: [
          "Максимальная температура не должна превышать 80 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 85 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 90 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 95 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
        correctAnswers: [
          "Максимальная температура не должна превышать 80 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
      },
      {
        code: "20110044",
        text: "В соответствии с чем должно осуществляться размещение организации, имеющей в своем составе взрывоопасные технологические объекты, планировка ее территории, объемно-планировочные решения строительных объектов?",
        answers: [
          "В соответствии с требованиями законодательства о градостроительной деятельности",
          "В соответствии с рекомендациями территориальных органов Ростехнадзора",
          "В соответствии с эксплуатирующей документацией",
          "В соответствии с технологическими регламентами на производство продукции",
        ],
        correctAnswers: [
          "В соответствии с требованиями законодательства о градостроительной деятельности",
        ],
      },
      {
        code: "20110045",
        text: "Какие требования должны быть выполнены для вновь проектируемых взрывопожароопасных и химически опасных объектов? Выберите два правильных варианта ответа.",
        answers: [
          "Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов",
          "Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия",
          "Вокруг зданий на химически опасных объектах должна предусматриваться вспаханная полоса земли шириной не менее 5 м",
          "Окна зданий на химически опасных объектах должны быть оборудованы жалюзи из прочных материалов",
        ],
        correctAnswers: [
          "Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов",
          "Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия",
        ],
      },
      {
        code: "20110046",
        text: "Каков порядок сброса химически загрязненных стоков от отдельных технологических объектов в магистральную сеть канализации?",
        answers: [
          "Порядок сброса стоков в магистральную сеть канализации устанавливается организацией",
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны быть оборудованы устройствами для улавливания аварийных стоков",
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
          "Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть",
        ],
        correctAnswers: [
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
        ],
      },
      {
        code: "20110047",
        text: "В каких местах не допускается размещать фланцевые соединения трубопроводов с взрывопожароопасными, токсичными и едкими веществами?",
        answers: [
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
          "Над автодорогами",
          "На трубопроводах, идущих по стенам зданий",
          "На трубопроводах, проложенных по эстакадам",
        ],
        correctAnswers: [
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
        ],
      },
      {
        code: "20110048",
        text: "Что должно устанавливаться на трубопроводах для транспортирования взрывопожароопасных продуктов?",
        answers: [
          "Арматура в соответствии с проектной документацией",
          "Арматура с резиновым уплотнением в затворе",
          "Арматура с тканевым уплотнением в затворе",
          "Арматура с пластмассовым уплотнением в затворе",
        ],
        correctAnswers: ["Арматура в соответствии с проектной документацией"],
      },
      {
        code: "20110049",
        text: "В каких случаях на трубопроводах следует применять арматуру под приварку?",
        answers: [
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков любой категории взрывоопасности",
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении",
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I и II категорий взрывоопасности и температурой, равной температуре кипения при регламентированном давлении",
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков II категории взрывоопасности с давлением среды более 1,5 МПа",
        ],
        correctAnswers: [
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении",
        ],
      },
      {
        code: "20110050",
        text: "Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития?",
        answers: [
          "Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
          "Запорную арматуру, средства защиты от превышения давления, огнепреградители",
          "Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы",
          "Запорную арматуру, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов",
        ],
        correctAnswers: [
          "Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20110051",
        text: "Что в технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?",
        answers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
          "Запорная арматура, предохранительные устройства от превышения давления, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов",
          "Запорная арматура, средства защиты от превышения давления, огнепреградители",
          "Все устройства, задействованные в системе противоаварийной автоматической защиты, включая исполнительные механизмы",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20110052",
        text: "Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?",
        answers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
          "Запорная арматура, предохранительные устройства от превышения давления, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов",
          "Запорная арматура, средства защиты от превышения давления, огнепреградители",
          "Все устройства, задействованные в системе противоаварийной автоматической защиты, включая исполнительные механизмы",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20110053",
        text: "Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предотвращения аварий и предупреждения их развития?",
        answers: [
          "Все устройства, задействованные в системе противоаварийной автоматической защиты, включая исполнительные механизмы",
          "Запорная арматура, средства защиты от превышения давления, огнепреградители",
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
          "Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20110054",
        text: "На какие опасные производственные объекты не распространяются Правила промышленной безопасности складов нефти и нефтепродуктов?",
        answers: [
          "На объекты хранения нефтепродуктов с упругостью паров выше 93,3 кПа",
          "На товарно-сырьевые парки центральных пунктов сбора нефтяных месторождений",
          "На резервуарные парки и наливные станции магистральных нефтепроводов и нефтепродуктопроводов",
          "На склады горюче-смазочных материалов, входящие в состав промышленных предприятий и организаций",
        ],
        correctAnswers: [
          "На объекты хранения нефтепродуктов с упругостью паров выше 93,3 кПа",
        ],
      },
      {
        code: "20110055",
        text: "В каком случае допускается торможение цистерн башмаками, изготовленными из материала, дающего искрение, на участках слива-налива?",
        answers: [
          "Не допускается ни в каком случае",
          "Если цистерны имеют прокладки из неискрящего материала",
          "Если прием и отгрузка нефти и нефтепродуктов осуществляется через специально оборудованные сливоналивные устройства",
          "Если цистерны имеют прокладки, устойчивые к разрушению парами нефтепродуктов",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20110056",
        text: "В каких документах должны быть обоснованы технические решения по герметизации налива нефтепродуктов в железнодорожные цистерны?",
        answers: [
          "В проектной документации",
          "В технологической инструкции",
          "В нормативной документации",
          "В производственной инструкции по наливу нефти и нефтепродуктов",
        ],
        correctAnswers: ["В проектной документации"],
      },
      {
        code: "20110057",
        text: "Какие нефтепродукты допускается сливать через герметичные верхние сливные устройства?",
        answers: [
          "Светлые нефтепродукты",
          "Нефтепродукты с низкой вязкостью",
          "Авиационное топливо",
          "Высоковязкие нефтепродукты",
        ],
        correctAnswers: ["Светлые нефтепродукты"],
      },
      {
        code: "20110058",
        text: "Каким должно быть покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков, а также для смыва пролитых нефтепродуктов?",
        answers: ["Бетонным", "Металлическим", "Деревянным", "Глинобитным"],
        correctAnswers: ["Бетонным"],
      },
      {
        code: "20110059",
        text: "Какие минимальные уклоны для стока жидкости к приемным устройствам (лоткам, колодцам, приямкам) должно иметь покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков?",
        answers: ["2 %", "1 %", "3 %", "5 %"],
        correctAnswers: ["2 %"],
      },
      {
        code: "20110060",
        text: "Бортиками какой высоты должно ограждаться по периметру покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков?",
        answers: [
          "Не менее 0,2 м",
          "Не менее 0,4 м",
          "Не менее 0,5 м",
          "Не менее 0,6 м",
        ],
        correctAnswers: ["Не менее 0,2 м"],
      },
      {
        code: "20110061",
        text: "В каком из перечисленных случаев должны срабатывать быстродействующие отключающие системы (автоматические устройства) на сливоналивных эстакадах?",
        answers: [
          "Только при выдаче заданной нормы",
          "Только при нарушении целостности цепи заземления железнодорожной цистерны",
          "Только при достижении предельного уровня заполнения железнодорожной цистерны",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20110062",
        text: "Какой документацией обосновывается максимальная безопасная скорость налива нефти и нефтепродуктов на железнодорожных сливоналивных эстакадах?",
        answers: [
          "Проектной документацией",
          "Эксплуатационной документацией",
          "Технологической документацией",
          "Исполнительной документацией",
        ],
        correctAnswers: ["Проектной документацией"],
      },
      {
        code: "20110063",
        text: "Каким образом должно обеспечиваться ограничение максимальной скорости налива нефти и нефтепродуктов до безопасных пределов на железнодорожных сливоналивных эстакадах?",
        answers: [
          "Только регулированием расхода нефти и нефтепродуктов посредством запорно-регулирующей арматуры на линии подачи нефти или нефтепродукта к железнодорожной эстакаде",
          "Только перепуском части продукта во всасывающий трубопровод насоса",
          "Только установкой частотно-регулируемого электропривода насоса",
          "Любым из перечисленных способов",
        ],
        correctAnswers: ["Любым из перечисленных способов"],
      },
      {
        code: "20110064",
        text: "Каким образом не должен производиться разогрев застывающих и высоковязких нефтепродуктов в железнодорожных цистернах, сливоналивных устройствах?",
        answers: [
          "Горячей водой",
          "Паром",
          "Электроподогревом не выше 90 °C",
          "Нефтепродуктом, нагретым циркуляционным способом",
        ],
        correctAnswers: ["Горячей водой"],
      },
      {
        code: "20110065",
        text: "В каком случае не допускается применение электроподогрева при проведении сливоналивных операций нефтепродуктов?",
        answers: [
          "При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 61 °C",
          "При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 71 °C",
          "При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 81 °C",
          "При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 91 °C",
        ],
        correctAnswers: [
          "При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 61 °C",
        ],
      },
      {
        code: "20110066",
        text: "Какое из перечисленных требований к использованию переносных подогревателей на железнодорожных сливоналивных эстакадах указано неверно?",
        answers: [
          "При использовании переносных подогревателей допускается непосредственный непродолжительный контакт теплоносителя с нефтепродуктом",
          "При использовании переносных пароподогревателей давление пара в подогревателе должно соответствовать показателям, установленным в технической документации (паспорте) пароподогревателя",
          "Разогрев нефтепродуктов в железнодорожных цистернах переносными электрическими подогревателями должен производиться только с применением циркуляционного нагрева в выносном подогревателе (теплообменнике)",
          "При использовании переносных электрических подогревателей они должны быть оснащены блокировочными устройствами, отключающими их при снижении уровня жидкости над нагревательным устройством ниже 500 мм",
        ],
        correctAnswers: [
          "При использовании переносных подогревателей допускается непосредственный непродолжительный контакт теплоносителя с нефтепродуктом",
        ],
      },
      {
        code: "20110067",
        text: "На какую минимальную глубину от уровня верхней кромки подогревателя должны погружаться в нефтепродукт переносные паровые змеевики и переносные электрические подогреватели?",
        answers: ["500 мм", "300 мм", "600 мм", "800 мм"],
        correctAnswers: ["500 мм"],
      },
      {
        code: "20110068",
        text: "Какой длины должно быть наливное устройство во избежание налива нефти и нефтепродуктов свободно падающей струей?",
        answers: [
          "Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 200 мм",
          "Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 300 мм",
          "Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 500 мм",
        ],
        correctAnswers: [
          "Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 200 мм",
        ],
      },
      {
        code: "20110069",
        text: "При каком уровне загазованности воздушной среды должен автоматически прекращаться слив и налив нефти и светлых нефтепродуктов на сливоналивных железнодорожных эстакадах?",
        answers: [
          "50 % объемных от нижнего концентрационного предела распространения пламени",
          "20 % объемных от нижнего концентрационного предела распространения пламени",
          "30 % объемных от нижнего концентрационного предела распространения пламени",
        ],
        correctAnswers: [
          "50 % объемных от нижнего концентрационного предела распространения пламени",
        ],
      },
      {
        code: "20110070",
        text: "Для налива каких ЛВЖ сливоналивные устройства должны снабжаться устройствами отвода паров?",
        answers: [
          "С упругостью паров от 66,65 кПа",
          "С упругостью паров от 60,65 кПа",
          "С упругостью паров от 56,65 кПа",
          "С упругостью паров от 61,65 кПа",
        ],
        correctAnswers: ["С упругостью паров от 66,65 кПа"],
      },
      {
        code: "20110071",
        text: "Какое из перечисленных требований допускается к автомобильным сливоналивным станциям?",
        answers: [
          "Применение гибких шлангов для налива при обосновании в проектной документации",
          "Самопроизвольное движение сливоналивных устройств при осуществлении операций налива легковоспламеняющихся и горючих жидкостей",
          "Верхний налив авиационных топлив",
          "Запуск двигателей автомобильных цистерн, находящихся на площадке, в случаях пролива нефтепродукта",
        ],
        correctAnswers: [
          "Применение гибких шлангов для налива при обосновании в проектной документации",
        ],
      },
      {
        code: "20110072",
        text: "При каких условиях допускается налив нефтепродуктов в автомобильные цистерны с применением гибких шлангов?",
        answers: [
          "При обосновании в проектной документации",
          "При разработке обоснования безопасности и реализации компенсирующих мероприятий",
          "При обосновании в нормативной документации",
        ],
        correctAnswers: ["При обосновании в проектной документации"],
      },
      {
        code: "20110073",
        text: "При каком минимальном превышении концентрации паров нефтепродуктов на площадках сливоналивных станций и пунктов слива-налива должны быть установлены блокировки по прекращению операций слива-налива и сигнализация, оповещающая о запрете запуска двигателей автомобилей?",
        answers: [
          "При превышении концентрации паров более 20 %",
          "При превышении концентрации паров более 30 %",
          "При превышении концентрации паров более 50 %",
        ],
        correctAnswers: ["При превышении концентрации паров более 20 %"],
      },
      {
        code: "20110074",
        text: "В соответствии с какими нормами и требованиями должны быть обустроены сливоналивные причалы для осуществления операций с нефтью и нефтепродуктами?",
        answers: [
          "В соответствии с нормами технологического проектирования морских и речных портов и требованиями законодательства в области промышленной безопасности",
          "В соответствии с нормами морского регистра и требованиями законодательства по перевозке опасных грузов",
          "В соответствии с требованиями градостроительного законодательства",
        ],
        correctAnswers: [
          "В соответствии с нормами технологического проектирования морских и речных портов и требованиями законодательства в области промышленной безопасности",
        ],
      },
      {
        code: "20110075",
        text: "В какой документации устанавливаются места установки приборов, их количество и параметры контроля процесса перекачки нефти и нефтепродукта по трубопроводу у насосной станции и стендеров?",
        answers: [
          "В проектной документации",
          "В нормативной документации",
          "В эксплуатационной документации",
          "В технологической документации",
        ],
        correctAnswers: ["В проектной документации"],
      },
      {
        code: "20110076",
        text: "Каким способом срабатывает система аварийного разъединения стендеров для предотвращения пролива нефтепродуктов?",
        answers: [
          "Только автоматически, когда стендер достигает обусловленного граничного положения",
          "Только вручную посредством управления гидравлическими клапанами в случае прекращения подачи электроэнергии на терминал",
          "Только дистанционно с учетом нажатия кнопки на центральном пульте управления",
          "Всеми перечисленными способами",
        ],
        correctAnswers: ["Всеми перечисленными способами"],
      },
      {
        code: "20110077",
        text: "В каком случае автоматические предохранительные клапаны должны быть установлены на причале, чтобы исключить возможное повышение давления потоком нефти и нефтепродукта?",
        answers: [
          "При расположении береговых насосов более чем в 100 м от причала",
          "При расположении береговых насосов более чем в 20 м от причала",
          "При расположении береговых насосов более чем в 50 м от причала",
          "При расположении береговых насосов более чем в 10 м от причала",
        ],
        correctAnswers: [
          "При расположении береговых насосов более чем в 100 м от причала",
        ],
      },
      {
        code: "20110078",
        text: "В каком случае автоматические предохранительные клапаны должны быть установлены на причале, чтобы исключить возможное повышение давления потоком нефти и нефтепродукта?",
        answers: [
          "При расположении береговых насосов более чем в 100 м от причала",
          "При расположении береговых насосов более чем в 20 м от причала",
          "При расположении береговых насосов более чем в 50 м от причала",
          "При расположении береговых насосов более чем в 10 м от причала",
        ],
        correctAnswers: [
          "При расположении береговых насосов более чем в 100 м от причала",
        ],
      },
      {
        code: "20110079",
        text: "Что не должно учитываться при выборе шлангов для обеспечения безопасности грузовых (сливоналивных) операций?",
        answers: [
          "Требуемая скорость перемещения нефтепродукта",
          "Физико-химические свойства перемещаемой среды",
          "Давление перемещаемой среды",
          "Температура перемещаемой среды",
        ],
        correctAnswers: ["Требуемая скорость перемещения нефтепродукта"],
      },
      {
        code: "20110080",
        text: "Какое из нижеуказанных свойств, показателей или условий не влияет на выбор типа резервуара для хранения нефти и нефтепродуктов?",
        answers: [
          "Годовое число циклов заполнений-опорожнений резервуара",
          "Показатели взрывоопасности хранимых нефтепродуктов",
          "Физико-химические свойства хранимых нефтепродуктов",
        ],
        correctAnswers: [
          "Годовое число циклов заполнений-опорожнений резервуара",
        ],
      },
      {
        code: "20110081",
        text: "Какое из перечисленных требований к резервуарам для хранения нефти и нефтепродуктов указано верно?",
        answers: [
          "Полный комплект устанавливаемых на резервуаре устройств и оборудования и схема их расположения обосновываются в проектной документации",
          "Производительность наполнения (опорожнения) резервуаров может превышать суммарную пропускную способность установленных на резервуаре дыхательных и предохранительных устройств при условии, что это обусловлено в проектной документации",
          "При оснащении резервуарных парков газоуравнительной системой допускается объединять ею резервуары с авиационными и автомобильными бензинами",
          "Резервуары должны быть оборудованы газоуравнительной системой и системой улавливания и рекуперации паров",
        ],
        correctAnswers: [
          "Полный комплект устанавливаемых на резервуаре устройств и оборудования и схема их расположения обосновываются в проектной документации",
        ],
      },
      {
        code: "20110082",
        text: "Какое значение не должна превышать скорость движения понтона (плавающей крыши) для резервуаров емкостью до 30 000 м³?",
        answers: ["6 м/ч", "4 м/ч", "8 м/ч", "2 м/ч"],
        correctAnswers: ["6 м/ч"],
      },
      {
        code: "20110083",
        text: "Какая допускается скорость понтона (плавающей крыши) резервуаров при сдвиге?",
        answers: [
          "Не более 2,5 м/ч",
          "Не более 1,5 м/ч",
          "Не более 2,0 м/ч",
          "Не более 3,5 м/ч",
        ],
        correctAnswers: ["Не более 2,5 м/ч"],
      },
      {
        code: "20110084",
        text: "Что из перечисленного допускается в отношении резервуарных парков?",
        answers: [
          "Ручной отбор проб светлых нефтепродуктов через люк на крыше резервуара",
          "Ручной отбор проб светлых нефтепродуктов во время закачки или откачки продукта",
          "Ручной отбор проб светлых нефтепродуктов во время грозы",
          "Все перечисленное не допускается",
        ],
        correctAnswers: ["Все перечисленное не допускается"],
      },
      {
        code: "20110085",
        text: "В каком случае допускается ручной отбор проб светлых нефтепродуктов через люк на крыше резервуара?",
        answers: [
          "Не допускается ни в каком случае",
          "Если отбор проб проводится для определения качества нефти и нефтепродуктов, хранящихся в резервуарах",
          "Если отбор проб проводится для вновь строящихся и реконструируемых резервуаров",
          "Если отбор проб проводится для коммерческого учета нефти и нефтепродуктов, хранящихся в резервуарах",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20110086",
        text: "Какие требования предъявляются к температуре подогрева мазута в резервуарах?",
        answers: [
          "Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 90 °C",
          "Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 20 °C и не должна превышать 80 °C",
          "Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 120 °C",
        ],
        correctAnswers: [
          "Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 90 °C",
        ],
      },
      {
        code: "20110087",
        text: "Какое утверждение, относящееся к резервуарным паркам для нефти и нефтепродуктов, указано неверно?",
        answers: [
          "Транзитная прокладка электрокабельных линий производится внутри обвалования резервуаров",
          "Запорные устройства, установленные непосредственно у резервуара, должны дублироваться установкой запорных устройств на технологических трубопроводах вне обвалования",
          "Применение арматуры с дистанционным управлением (электро-, пневмо- или гидроприводной) определяется условиями технологического процесса перекачки с обоснованием в проектной документации",
          "Для вновь проектируемых резервуаров управление приводами запорной арматуры должно быть дистанционным из помещения управления (операторной) и по месту ее установки",
        ],
        correctAnswers: [
          "Транзитная прокладка электрокабельных линий производится внутри обвалования резервуаров",
        ],
      },
      {
        code: "20110088",
        text: "На каком минимальном расстоянии от резервуаров устанавливаются прожекторные мачты вне обвалования или ограждающих стен?",
        answers: ["10 м", "15 м", "5 м", "8 м", "12 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "20110089",
        text: "До какого момента при заполнении порожнего резервуара должны подаваться нефть или нефтепродукты со скоростью не более 1 м/с?",
        answers: [
          "До момента заполнения приемного патрубка или до всплытия понтона (плавающей крыши)",
          "До момента заполнения резервуара до верхней отметки",
          "До момента срабатывания блокировки ограничения скорости заполнения",
        ],
        correctAnswers: [
          "До момента заполнения приемного патрубка или до всплытия понтона (плавающей крыши)",
        ],
      },
      {
        code: "20110090",
        text: "Где допускается осуществлять затаривание и расфасовку нефтепродуктов (масел, смазок) в бочки и мелкую тару?",
        answers: [
          "В пунктах разлива и фасовки нефтепродуктов",
          "В складских помещениях, предназначенных для хранения нефтепродуктов в таре",
          "На открытых площадках",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["В пунктах разлива и фасовки нефтепродуктов"],
      },
      {
        code: "20110091",
        text: "На каком расстоянии от сплошной (без проемов) стены помещения пунктов разлива и фасовки размещаются раздаточные резервуары с нефтепродуктами единичной вместимостью до 25 м³ включительно при общей вместимости до 200 м³ в зависимости от вида отпускаемых нефтепродуктов?",
        answers: ["2 м", "1 м", "3 м", "1,5 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "20110092",
        text: "Из какого материала должны применяться трубопроводы для транспортировки нефти и нефтепродуктов?",
        answers: [
          "Из стали",
          "Из фторопласта",
          "Из полиэтилена",
          "Из стекла",
          "Из винипласта",
        ],
        correctAnswers: ["Из стали"],
      },
      {
        code: "20110093",
        text: "Что используется в качестве теплоносителей на технологических трубопроводах складов нефти и нефтепродуктов?",
        answers: [
          "Только пар",
          "Только промтеплофикационная вода",
          "Только электрообогрев",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20110094",
        text: "За счет чего должны компенсироваться температурные деформации трубопроводов для транспортировки мазута?",
        answers: [
          "За счет поворотов и изгибов трассы трубопроводов (самокомпенсация) или установки специальных компенсирующих устройств (П-образных компенсаторов)",
          "За счет установки на технологических трубопроводах для транспортировки мазута линзовых компенсаторов",
          "За счет установки на технологических трубопроводах для транспортировки мазута сальниковых компенсаторов",
        ],
        correctAnswers: [
          "За счет поворотов и изгибов трассы трубопроводов (самокомпенсация) или установки специальных компенсирующих устройств (П-образных компенсаторов)",
        ],
      },
      {
        code: "20110095",
        text: "Применение каких компенсаторов на технологических трубопроводах допускается для транспортировки мазута?",
        answers: [
          "Все ответы неверны",
          "Только линзовых компенсаторов",
          "Только сальниковых компенсаторов",
          "Только волнистых компенсаторов",
          "Допускается применение всех перечисленных компенсаторов",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20110096",
        text: "Какая запорная арматура, установленная на технологических трубопроводах, должна иметь механический привод (электро-, пневмо- или гидропривод) с дистанционным управлением и ручным дублированием?",
        answers: [
          "Установленная на технологических трубопроводах с условным диаметром более 400 мм",
          "Установленная на технологических трубопроводах с условным диаметром более 300 мм",
          "Установленная на технологических трубопроводах с условным диаметром более 200 мм",
        ],
        correctAnswers: [
          "Установленная на технологических трубопроводах с условным диаметром более 400 мм",
        ],
      },
      {
        code: "20110097",
        text: "На какое минимальное расстояние должны не доходить защитные боковые ограждения открытых насосных станций до пола и покрытия (перекрытия) насосной станции?",
        answers: ["На 0,3 м", "На 1,0 м", "На 0,5 м", "На 0,2 м"],
        correctAnswers: ["На 0,3 м"],
      },
      {
        code: "20110098",
        text: "В каком случае допускается применение на складах нефти и нефтепродуктов поршневых насосов для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей при малых объемных скоростях подачи, в том числе в системах дозирования?",
        answers: [
          "Допускается при обосновании принятого технического решения в проектной документации",
          "Допускается при согласовании с организацией-изготовителем",
          "Допускается при согласовании с федеральным органом исполнительной власти, уполномоченным в области промышленной безопасности",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "Допускается при обосновании принятого технического решения в проектной документации",
        ],
      },
      {
        code: "20110099",
        text: "В соответствии с требованиями какой документации насосные агрегаты должны оснащаться системами автоматизации, обеспечивающими их безопасную эксплуатацию? Укажите все правильные ответы.",
        answers: [
          "Технической документации организации-изготовителя",
          "Проектной документации",
          "Технической документации завода-изготовителя",
          "Эксплуатационной документации",
        ],
        correctAnswers: [
          "Технической документации организации-изготовителя",
          "Проектной документации",
        ],
      },
      {
        code: "20110100",
        text: "При каком достижении горючих газов и паров нефтепродуктов осуществляется включение аварийной вентиляции в помещениях насосных станций нефти и нефтепродуктов?",
        answers: [
          "50 % объемных от НКПРП",
          "20 % объемных от НКПРП",
          "30 % объемных от НКПРП",
          "40 % объемных от НКПРП",
        ],
        correctAnswers: ["50 % объемных от НКПРП"],
      },
      {
        code: "20110101",
        text: "Кем утверждается перечень уставок срабатывания блокировок и сигнализации для осуществления технологических операций, предусмотренных проектной документацией?",
        answers: [
          "Организацией, эксплуатирующей опасные производственные объекты складов нефти и нефтепродуктов",
          "Организацией, осуществляющей техническое обслуживание и ремонт автоматизированных систем управления на опасных производственных объектах складов нефти и нефтепродуктов",
          "Организацией - разработчиком проектной документации или организацией, специализирующейся на проектировании объектов складов нефти и нефтепродуктов",
          "Организацией - разработчиком технологического процесса по согласованию с разработчиком проектной документации",
        ],
        correctAnswers: [
          "Организацией, эксплуатирующей опасные производственные объекты складов нефти и нефтепродуктов",
        ],
      },
      {
        code: "20110102",
        text: "При каком условии допускается использовать приборы, отработавшие назначенный срок службы, в системах автоматизации, связи и оповещания на опасных производственных объектах складов нефти и нефтепродуктов?",
        answers: [
          "При утверждении внутренней документацией организации",
          "При наличии в проектной документации",
          "При наличии в технологической документации",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20110103",
        text: "Какой документацией определяется электроснабжение электроприемников по категории надежности опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: [
          "Проектной документацией (документацией на техническое перевооружение)",
          "Эксплуатационной документацией",
          "Технологичекской документацией",
          "Документацией завода-изготовителя",
        ],
        correctAnswers: [
          "Проектной документацией (документацией на техническое перевооружение)",
        ],
      },
      {
        code: "20110104",
        text: "В каком случае допускается на опасных производственных объектах складов нефти и нефтепродуктов прокладка кабельных трасс и технологических трубопроводов на общих строительных конструкциях?",
        answers: [
          "Допускается при обосновании принятых технических решений в проектной документации (документации на техническое перевооружение)",
          "Допускается при условии разработки эксплуатирующей организацией компенсационных мер обеспечения требований пожарной и промышленной безопасности",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "Допускается при обосновании принятых технических решений в проектной документации (документации на техническое перевооружение)",
        ],
      },
      {
        code: "20110105",
        text: "Какой электрифицированный транспорт во взрывозащищенном исполнении допускается применять на территории опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: [
          "Только электропогрузчики",
          "Только самоходные аккумуляторные тележки (электрокары)",
          "Только тягачи",
          "Любой из перечисленных видов транспорта",
        ],
        correctAnswers: ["Любой из перечисленных видов транспорта"],
      },
      {
        code: "20110106",
        text: "Что из перечисленного не требует защиты от прямых ударов молнии?",
        answers: [
          "Насосные установки (станции) нефти и нефтепродуктов и пространство над ними",
          "Пространство над срезом горловины цистерн с легковоспламеняющимися жидкостями при открытом наливе продукта на наливной эстакаде, ограниченное цилиндром высотой 2,5 м и радиусом 5 м",
          "Дыхательная арматура резервуаров с легковоспламеняющимися жидкостями и пространство над ней",
        ],
        correctAnswers: [
          "Насосные установки (станции) нефти и нефтепродуктов и пространство над ними",
        ],
      },
      {
        code: "20110107",
        text: "Какие переходные сопротивления должны быть в соединениях элементов трубопроводов или других протяженных металлических предметов для защиты зданий (сооружений) и электрооборудования от вторичных проявлений молнии?",
        answers: [
          "Не более 0,03 Ом на каждый контакт",
          "Не более 0,05 Ом на каждый контакт",
          "Не более 0,5 Ом на каждый контакт",
          "Не более 0,1 Ом на каждый контакт",
        ],
        correctAnswers: ["Не более 0,03 Ом на каждый контакт"],
      },
      {
        code: "20110108",
        text: "Какое из перечисленных требований к молниезащите и защите от статического электричества указано верно?",
        answers: [
          "Заземленное металлическое оборудование, покрытое лакокрасочными материалами, считается электростатически заземленным, если сопротивление любой точки его внутренней и внешней поверхности относительно магистрали заземления не превышает 10 Ом",
          "Измерение указанного сопротивления должно проводиться при относительной влажности окружающего воздуха не выше 70%",
          "Площадь соприкосновения измерительного электрода с поверхностью оборудования не должна превышать 30 см²",
          "При измерениях электрод должен располагаться в точках поверхности оборудования, наименее удаленных от точек контакта поверхности с заземленными металлическими элементами",
        ],
        correctAnswers: [
          "Заземленное металлическое оборудование, покрытое лакокрасочными материалами, считается электростатически заземленным, если сопротивление любой точки его внутренней и внешней поверхности относительно магистрали заземления не превышает 10 Ом",
        ],
      },
      {
        code: "20110109",
        text: "В каком случае разрешается выполнение болтовых соединений молниеприемников с токоотводами и токоотводов с заземлителями с переходным сопротивлением не более 0,05 Ом?",
        answers: [
          "Разрешается при недопустимости проведения огневых работ и при условии обязательного ежегодного контроля сопротивления перед началом грозового периода",
          "Разрешается при согласовании с федеральным органом исполнительной власти, уполномоченным в области пожарной безопасности",
          "Разрешается при согласовании с федеральным органом исполнительной власти, уполномоченным в области промышленной безопасности",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "Разрешается при недопустимости проведения огневых работ и при условии обязательного ежегодного контроля сопротивления перед началом грозового периода",
        ],
      },
      {
        code: "20110110",
        text: "С какой периодичностью заземлители, токоотводы подвергаются периодическому контролю?",
        answers: [
          "Один раз в 5 лет",
          "Один раз в год",
          "Один раз в 2 года",
          "Один раз в 3 года",
        ],
        correctAnswers: ["Один раз в 5 лет"],
      },
      {
        code: "20110111",
        text: "Какие из перечисленных конструкций подлежат заземлению для защиты от проявлений статического электричества?",
        answers: [
          "Только металлические оголовки и патрубки гибких шлангов для слива и налива нефти и нефтепродуктов",
          "Только металлические воздуховоды и кожухи изоляции вентиляционных систем во взрывоопасных помещениях через каждые 40 - 50 м",
          "Только металлические конструкции автоналивных устройств",
          "Все перечисленные конструкции",
        ],
        correctAnswers: ["Все перечисленные конструкции"],
      },
      {
        code: "20110112",
        text: "Каким должно быть максимальное сопротивление заземляющего устройства, предназначенного только для защиты от статического электричества?",
        answers: ["100 Ом", "150 Ом", "120 Ом", "80 Ом"],
        correctAnswers: ["100 Ом"],
      },
      {
        code: "20110113",
        text: "Из какого материала следует выполнять соединение между собой неподвижных металлических конструкций (резервуары, трубопроводы), а также присоединение их к заземлителям? Укажите все правильные ответы.",
        answers: [
          "Из полосовой стали сечением не менее 48 мм²",
          "Из круглой стали диаметром более 6 мм",
          "Из стальной проволоки диаметром не менее 5 мм",
          "Из стальной ленты сечением не менее 24 мм²",
        ],
        correctAnswers: [
          "Из полосовой стали сечением не менее 48 мм²",
          "Из круглой стали диаметром более 6 мм",
        ],
      },
      {
        code: "20110114",
        text: "На каком минимальном расстоянии необходимо находиться от молниеотводов во время грозы?",
        answers: ["4 м", "3 м", "10 м", "5 м"],
        correctAnswers: ["4 м"],
      },
      {
        code: "20110115",
        text: "В каком случае допускается установка оборудования приточных систем вентиляции обычного исполнения в помещениях взрывоопасных категорий опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: [
          "Допускается, если на воздуховодах при выходе из венткамеры предусмотрены взрывозащитные обратные клапаны",
          "Допускается, если есть согласование с федеральным органом исполнительной власти, уполномоченным в области промышленной безопасности",
          "В любом случае",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "Допускается, если на воздуховодах при выходе из венткамеры предусмотрены взрывозащитные обратные клапаны",
        ],
      },
      {
        code: "20110116",
        text: "При достижении какой концентрации горючих газов и паров нефтепродуктов предусматривается автоматическое включение аварийной вентиляции?",
        answers: [
          "20 % объемных от НКПРП",
          "30 % объемных от НКПРП",
          "40 % объемных от НКПРП",
          "50 % объемных от НКПРП",
        ],
        correctAnswers: ["20 % объемных от НКПРП"],
      },
      {
        code: "20110117",
        text: "Какие насосные станции производственной канализации должны оснащаться датчиками загазованности с выводом сигнала на пульт помещения управления?",
        answers: [
          "Заглубленные более чем на 0,5 м",
          "Заглубленные не менее чем на 1 м",
          "В соответствии с нормативной документацией",
          "Все перечисленные",
        ],
        correctAnswers: ["Заглубленные более чем на 0,5 м"],
      },
      {
        code: "20110118",
        text: "В каком случае допускается сброс химически загрязненных, технологических, смывных и других сточных вод без предварительной очистки?",
        answers: [
          "В случае, когда в эксплуатирующей организации имеются собственные очистные сооружения и магистральная сеть, предназначенная для приема таких стоков",
          "В случае, когда это утверждено инспектором Ростехнадзора",
          "В случае, когда это утверждено внутренней документацией эксплуатирующей организации",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "В случае, когда в эксплуатирующей организации имеются собственные очистные сооружения и магистральная сеть, предназначенная для приема таких стоков",
        ],
      },
      {
        code: "20110119",
        text: "Какая из перечисленных систем канализации не предусматривается на площадках опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: ["Аварийная", "Производственная", "Дождевая"],
        correctAnswers: ["Аварийная"],
      },
      {
        code: "20110120",
        text: "Что из перечисленного должно отводиться в производственную канализацию на объектах складов нефти и нефтепродуктов?",
        answers: [
          "Подтоварные воды от отстоя нефти и нефтепродуктов",
          "Сточные воды от зачистки и пропарки резервуаров для нефти и нефтепродуктов",
          "Нефтешлам",
          "Все перечисленное",
        ],
        correctAnswers: ["Подтоварные воды от отстоя нефти и нефтепродуктов"],
      },
      {
        code: "20110121",
        text: "В каком случае допускается сбрасывать взрывопожароопасные и пожароопасные нефтепродукты в канализацию?",
        answers: [
          "Не допускается ни в каком случае",
          "Если они очищены",
          "Если произошла авария",
          "Если есть разрешение, выданное руководителем Росприроднадзора",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20110122",
        text: "Какая высота столба жидкости в гидравлическом затворе, установленном на колодце, за пределами обвалования?",
        answers: [
          "Не менее 0,25 м",
          "Не менее 0,1 м",
          "Не менее 0,5 м",
          "Не менее 1,0 м",
        ],
        correctAnswers: ["Не менее 0,25 м"],
      },
      {
        code: "20110123",
        text: "Какая должна быть максимальная температура производственных сточных вод при сбросе в канализацию опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: ["40 °C", "30 °C", "60 °C"],
        correctAnswers: ["40 °C"],
      },
      {
        code: "20110124",
        text: "На каком расстоянии от нефтеловушек необходимо устраивать на канализационной сети колодцы с гидравлическим затвором?",
        answers: [
          "Не менее 10 м до и после нефтеловушек",
          "Не менее 5 м до и после нефтеловушек",
          "Не менее 15 м до и после нефтеловушек",
        ],
        correctAnswers: ["Не менее 10 м до и после нефтеловушек"],
      },
      {
        code: "20110125",
        text: "В течение какого времени после ввода резервуара в эксплуатацию необходимо ежегодно проводить нивелирование окрайки днища в абсолютных отметках?",
        answers: [
          "В течение четырех лет",
          "В течение пяти лет",
          "В течение шести лет",
          "В течение года",
        ],
        correctAnswers: ["В течение четырех лет"],
      },
      {
        code: "20110126",
        text: "Какова периодичность зачистки металлических резервуаров для хранения нефтепродуктов?",
        answers: [
          "Не менее 2 раз в год - для авиационного топлива и не менее 1 раза в 2 года - для остальных светлых нефтепродуктов и масел",
          "Не менее 1 раза в год - для авиационного топлива и не менее 1 раза в 3 года - для остальных светлых нефтепродуктов и масел",
          "Не менее 1 раза в год - для любых нефтепродуктов",
        ],
        correctAnswers: [
          "Не менее 2 раз в год - для авиационного топлива и не менее 1 раза в 2 года - для остальных светлых нефтепродуктов и масел",
        ],
      },
      {
        code: "20110127",
        text: "Какой документ должен быть оформлен для проведения земляных работ на территории опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: [
          "Наряд-допуск",
          "Акт-допуск",
          "Разрешение",
          "Проект производства работ",
        ],
        correctAnswers: ["Наряд-допуск"],
      },
      {
        code: "20110128",
        text: "В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
          "В целях обеспечения готовности подразделений муниципальной пожарной охраны при возникновении аварии",
          "В целях обеспечения соответствия объекта требованиям промышленной безопасности",
          "В целях обеспечения готовности персонала при возникновении аварии",
        ],
        correctAnswers: [
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
        ],
      },
      {
        code: "20110129",
        text: "Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?",
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          "Для всех ОПО без исключения",
          "Только для ОПО I и II классов опасности",
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "20110130",
        text: "Каким образом необходимо разработать план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах в случае, если 2 и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?",
        answers: [
          "Допускается разработка единого плана мероприятий для 2 и более объектов",
          "Допускается разработка единого плана мероприятий для объектов, расположенных на одном участке, и нескольких планов для объектов на смежных участках",
          "Необходимо разработать планы мероприятий для каждого объекта отдельно",
          "Допускается разработка единого плана мероприятий только для объектов, находящихся на расстоянии менее 100 м друг от друга",
        ],
        correctAnswers: [
          "Допускается разработка единого плана мероприятий для 2 и более объектов",
        ],
      },
      {
        code: "20110131",
        text: "Сколько необходимо разработать планов мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, в случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?",
        answers: [
          "Разрабатывается единый план мероприятий для двух и более объектов",
          "Разрабатывается единый план мероприятий для объектов, расположенных на расстоянии менее 100 м друг от друга",
          "Для каждого объекта разрабатывается свой план мероприятий",
          "Разрабатывается единый план мероприятий для объектов, расположенных на расстоянии менее 50 м друг от друга",
        ],
        correctAnswers: [
          "Разрабатывается единый план мероприятий для двух и более объектов",
        ],
      },
      {
        code: "20110132",
        text: "На какой срок составляется план мероприятий по локализации и ликвидации последствий аварий для объектов, на которых ведутся горные работы в подземных условиях?",
        answers: ["На 6 месяцев", "На 1 год", "На 1 квартал", "На 2 года"],
        correctAnswers: ["На 6 месяцев"],
      },
      {
        code: "20110133",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены на опасных производственных объектах III класса опасности (за исключением объектов, на которых ведутся горные работы)?",
        answers: ["5 лет", "1 год", "3 года", "7 лет"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20110134",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов II класса опасности (за исключением объектов, на которых ведутся горные работы)?",
        answers: ["5 лет", "1 год", "3 года", "7 лет"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20110135",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов I класса опасности (за исключением объектов, на которых ведутся горные работы)?",
        answers: ["5 лет", "1 год", "3 года", "7 лет"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20110136",
        text: "В каком из перечисленных случаев план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах должен быть пересмотрен?",
        answers: [
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
          "Не позднее 45 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
          "Не позднее 90 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
          "Не позднее 60 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
        ],
        correctAnswers: [
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
        ],
      },
      {
        code: "20110137",
        text: "Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?",
        answers: [
          "Наименьший срок, предусмотренный для этих объектов",
          "Наибольший срок, предусмотренный для этих объектов",
          "3 года",
          "5 лет",
        ],
        correctAnswers: ["Наименьший срок, предусмотренный для этих объектов"],
      },
      {
        code: "20110138",
        text: "В какой срок пересматривается план мероприятий по локализации и ликвидации последствий аварий до истечения срока действия предыдущего плана мероприятий?",
        answers: [
          "Не менее чем за 15 календарных дней",
          "Не менее чем за 7 календарных дней",
          "Не менее чем за 10 календарных дней",
          "Не менее чем за 30 календарных дней",
        ],
        correctAnswers: ["Не менее чем за 15 календарных дней"],
      },
      {
        code: "20110139",
        text: "Кем утверждаются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
          "Уполномоченным представителем территориального управления Ростехнадзора",
          "Техническими специалистами организаций, эксплуатирующих объекты",
          "Специалистами военизированной газоспасательной службы",
        ],
        correctAnswers: [
          "Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
        ],
      },
      {
        code: "20110140",
        text: "С кем согласовываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
          "С руководителем Ростехнадзора",
          "С представителем Министерства энергетики",
        ],
        correctAnswers: [
          "С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
        ],
      },
      {
        code: "20110141",
        text: "Что из перечисленного должно быть предусмотрено планом мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Только порядок обеспечения постоянной готовности сил и средств к локализации и ликвидации последствий аварий на объекте с указанием организаций, которые несут ответственность за поддержание этих сил и средств в надлежащей степени готовности",
          "Только сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий, а также источники (места) их возникновения",
          "Только первоочередные действия при получении сигнала об авариях на объекте",
          "Только действия производственного персонала и аварийно-спасательных служб (формирований) по локализации и ликвидации аварий",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20110142",
        text: "Что из перечисленного не входит в общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Порядок действий в случае аварий на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
          "Характеристика объекта, в отношении которого разрабатывается план мероприятий",
          "Мероприятия, направленные на обеспечение безопасности населения",
          "Количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте и их соответствие задачам по локализации и ликвидации последствий аварий",
        ],
        correctAnswers: [
          "Порядок действий в случае аварий на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
        ],
      },
      {
        code: "20110143",
        text: "Что определяется в специальном разделе плана мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Порядок действий в случае аварий на объекте",
          "Порядок обеспечения постоянной готовности сил и средств к локализации и ликвидации последствий аварий на объекте с указанием организаций, которые несут ответственность за поддержание этих сил и средств в надлежащей степени готовности",
          "Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах",
          "Характеристика объекта, в отношении которого разрабатывается план мероприятий",
        ],
        correctAnswers: ["Порядок действий в случае аварий на объекте"],
      },
      {
        code: "20110144",
        text: "Кто утверждает план мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Руководитель (заместитель руководителя) организации, эксплуатирующей объект, либо руководитель обособленного подразделения юридического лица (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
          "Главный инженер организации",
          "Главный механик",
          "Сотрудник, ответственный за организацию и осуществление производственного контроля",
        ],
        correctAnswers: [
          "Руководитель (заместитель руководителя) организации, эксплуатирующей объект, либо руководитель обособленного подразделения юридического лица (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
        ],
      },
      {
        code: "20110145",
        text: "Каким документом определяется порядок организации и производства работ на одном объекте несколькими подразделениями одной организации, эксплуатирующей опасный производственный объект?",
        answers: [
          "Регламентом об организации безопасного производства работ, утвержденным руководителем этой организации или уполномоченным им лицом",
          "Положением о производственном контроле",
          "Актом-допуском",
          "Нарядом-допуском",
          "Производственным заданием, выданным руководителем организации",
        ],
        correctAnswers: [
          "Регламентом об организации безопасного производства работ, утвержденным руководителем этой организации или уполномоченным им лицом",
        ],
      },
      {
        code: "20110146",
        text: "Кем утверждается перечень работ, осуществляемых по наряду-допуску?",
        answers: [
          "Руководителем организации или уполномоченным им лицом",
          "Руководителем территориального органа Ростехнадзора",
          "Исполнителем работ",
          "Техническим руководителем организации",
          "Руководителем службы производственного контроля",
        ],
        correctAnswers: [
          "Руководителем организации или уполномоченным им лицом",
        ],
      },
      {
        code: "20110147",
        text: "Каким проверкам подвергается подводный трубопровод после капитального ремонта?",
        answers: [
          "Проверкам на прочность и герметичность",
          "Проверкам методами неразрушающего контроля",
          "Проверкам методами разрушающего контроля",
        ],
        correctAnswers: ["Проверкам на прочность и герметичность"],
      },
      {
        code: "20110148",
        text: "Что должно предусматриваться в проектной документации на консервацию или ликвидацию опасного производственного объекта?",
        answers: [
          "Должны предусматриваться все перечисленные условия",
          "Только мероприятия по предупреждению аварий",
          "Только мероприятия по локализации и ликвидации последствий аварий по завершении консервации объекта",
        ],
        correctAnswers: ["Должны предусматриваться все перечисленные условия"],
      },
      {
        code: "20110149",
        text: "Какие требования предусматриваются в проектной документации взрывопожароопасного производства к оборудованию, средствам контрольно-измерительных приборов и автоматики, устройствам освещения, сигнализации и связи?",
        answers: [
          "Оборудование, средства контрольно-измерительных приборов и автоматики, устройства освещения, сигнализации и связи в проектной документации должны быть предусмотрены во взрывозащищенном исполнении и иметь уровень защиты, соответствующий классу взрывоопасной зоны, и вид взрывозащиты, соответствующий категориям и группам взрывоопасных смесей",
          "Для оборудования, средств контрольно-измерительных приборов и автоматики, устройств освещения, сигнализации и связи в проектной документации взрывопожароопасного производства должны быть предусмотрены меры защиты от статического электричества",
          "Для оборудования, средств контрольно-измерительных приборов и автоматики, устройств освещения, сигнализации и связи в проектной документации должны быть предусмотрены дополнительные меры противоаварийной защиты",
        ],
        correctAnswers: [
          "Оборудование, средства контрольно-измерительных приборов и автоматики, устройства освещения, сигнализации и связи в проектной документации должны быть предусмотрены во взрывозащищенном исполнении и иметь уровень защиты, соответствующий классу взрывоопасной зоны, и вид взрывозащиты, соответствующий категориям и группам взрывоопасных смесей",
        ],
      },
      {
        code: "20110150",
        text: "Какие требования предъявляются к техническим устройствам, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями?",
        answers: [
          "Технические устройства, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями, должны пройти приемо-сдаточные испытания, результаты которых оформляются актом эксплуатирующей организации",
          "Документация на технические устройства, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями, должна быть согласована с надзорными органами",
          "Документация на технические устройства, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями, должна быть согласована с заводом-изготовителем",
        ],
        correctAnswers: [
          "Технические устройства, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями, должны пройти приемо-сдаточные испытания, результаты которых оформляются актом эксплуатирующей организации",
        ],
      },
      {
        code: "20110151",
        text: "Разрешается ли последовательное соединение между собой заземляющих устройств разных зданий, сооружений, установок с помощью одного заземляющего проводника?",
        answers: [
          "Разрешается при наличии письменного разрешения, подписанного главным инженером организации",
          "Разрешается при наличии письменного разрешения, подписанного главным энергетиком организации",
          "Разрешается в исключительных случаях по согласованию с территориальным органом Ростехнадзора",
          "Не разрешается",
        ],
        correctAnswers: ["Не разрешается"],
      },
      {
        code: "20110152",
        text: "Кто допускается к руководству и ведению работ по ремонту и реконструкции скважин?",
        answers: [
          "Лица, имеющие профессиональное образование, соответствующее занимаемой должности, и аттестованные в области промышленной безопасности",
          "Работники, имеющие опыт работ по ремонту и реконструкции скважин",
          "Лица, имеющие профессиональное образование по специальности",
          "Работники, прошедшие проверку знаний в области промышленной безопасности",
        ],
        correctAnswers: [
          "Лица, имеющие профессиональное образование, соответствующее занимаемой должности, и аттестованные в области промышленной безопасности",
        ],
      },
      {
        code: "20110153",
        text: "Какие требования предъявляются к работникам, осуществляющим непосредственное руководство и выполнение работ по бурению, освоению, ремонту, реконструкции, консервации и ликвидации скважин, ведению геофизических и ПВР на скважинах?",
        answers: [
          "Должны раз в 2 года дополнительно проходить проверку знаний по контролю и управлению скважиной при ГНВП",
          "Должны иметь разрешение от надзорных органов на право выполнения работ",
          "Должны проходить периодическую аттестацию в области промышленной безопасности 1 раз в 3 года",
        ],
        correctAnswers: [
          "Должны раз в 2 года дополнительно проходить проверку знаний по контролю и управлению скважиной при ГНВП",
        ],
      },
      {
        code: "20110154",
        text: "При каких условиях рабочие бригады допускаются к выполнению специальных работ (передвижке буровой установки, монтажу мобильных буровых установок, ремонтным работам повышенной сложности)?",
        answers: [
          "При прохождении дополнительного обучения и получении допуска к самостоятельной работе по основной и совмещаемой профессиям",
          "При прохождении обучения и сдаче соответствующего экзамена",
          "При прохождении стажировки и проверке знаний по специальности и охране труда",
          "При прохождении стажировки и получении письменного разрешения на допуск к работам у руководителя организации",
        ],
        correctAnswers: [
          "При прохождении дополнительного обучения и получении допуска к самостоятельной работе по основной и совмещаемой профессиям",
        ],
      },
      {
        code: "20110155",
        text: "Какие требования предъявляются к условиям закладки скважин, предназначенных для поисков, разведки, эксплуатации месторождений нефти, газа и газового конденсата?",
        answers: [
          "Скважины должны закладываться за пределами границ зоны санитарной охраны источников водоснабжения и водопроводов питьевого назначения, охранных зон линий электропередачи, магистральных нефтегазопроводов, водозаборных, других промышленных и гражданских объектов",
          "Скважины должны закладываться в пределах горного отвода и зоны производственной застройки",
          "Скважины должны закладываться в пределах земельного отвода и зоны магистральных нефтегазопроводов",
        ],
        correctAnswers: [
          "Скважины должны закладываться за пределами границ зоны санитарной охраны источников водоснабжения и водопроводов питьевого назначения, охранных зон линий электропередачи, магистральных нефтегазопроводов, водозаборных, других промышленных и гражданских объектов",
        ],
      },
      {
        code: "20110156",
        text: "Каким основным документом регламентируется производство буровых работ?",
        answers: [
          "Рабочим проектом на производство буровых работ",
          "Технологической инструкцией",
          "Технологической схемой разработки месторождений",
        ],
        correctAnswers: ["Рабочим проектом на производство буровых работ"],
      },
      {
        code: "20110157",
        text: "Кем осуществляется надзор за ходом производства буровых работ, качеством выполнения этих работ, уровнем технологических процессов и операций, качеством используемых материалов и технических средств, соблюдением безопасных условий труда?",
        answers: [
          "Пользователем недр (заказчиком), организацией, осуществляющей производство буровых работ, и другими субъектами хозяйственной деятельности, уполномоченными пользователем недр",
          "Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "Контролирующей организацией",
          "Проектной организацией",
        ],
        correctAnswers: [
          "Пользователем недр (заказчиком), организацией, осуществляющей производство буровых работ, и другими субъектами хозяйственной деятельности, уполномоченными пользователем недр",
        ],
      },
      {
        code: "20110158",
        text: "Что должны обеспечивать конструкция и схема колонной устьевой обвязки, фонтанной арматуры?",
        answers: [
          "Оптимальные режимы работы скважины, возможность герметизации трубного, затрубного и межтрубных пространств, возможность выполнения технологических операций в скважине, глубинные исследования, отбор проб и контроль устьевого давления и температуры",
          "Возможность обеспечения правильной центровки обсадных колонн в скважине",
          "Безопасный отбор проб и контроль устьевого давления и температуры, проведение работ при глушении скважины",
          "Возможность безопасного проведения технологических операций на скважине и глубинных исследований",
        ],
        correctAnswers: [
          "Оптимальные режимы работы скважины, возможность герметизации трубного, затрубного и межтрубных пространств, возможность выполнения технологических операций в скважине, глубинные исследования, отбор проб и контроль устьевого давления и температуры",
        ],
      },
      {
        code: "20110159",
        text: "На какое давление следует производить опрессовку фонтанной арматуры в собранном виде до установки на устье?",
        answers: [
          "На рабочее давление, предусмотренное паспортом, с выдержкой под внутренним давлением 30 минут",
          "На максимальное давление с выдержкой под внутренним давлением 30 минут",
          "На давление, превышающее рабочее на 25 %",
          "На давление, превышающее на 15 % давление опрессовки эксплуатационной колонны",
        ],
        correctAnswers: [
          "На рабочее давление, предусмотренное паспортом, с выдержкой под внутренним давлением 30 минут",
        ],
      },
      {
        code: "20110160",
        text: "Какие фонтанные скважины должны оснащаться внутрискважинным оборудованием (пакер и клапан-отсекатель, циркуляционный клапан, станция управления и др.)?",
        answers: [
          "Фонтанные скважины с дебитом 400 т/сут нефти или 500 тыс. м³/сут газа и более, расположенные на расстоянии менее 500 м от населенного пункта",
          "Фонтанные скважины с дебитом 300 т/сут нефти или 400 тыс. м³/сут газа и более, расположенные на расстоянии менее 600 м от населенного пункта",
          "Фонтанные скважины с дебитом 200 т/сут нефти или 300 тыс. м³/сут газа и более, расположенные на расстоянии менее 550 м от населенного пункта",
          "Фонтанные скважины с дебитом 350 т/сут нефти или 450 тыс. м³/сут газа и более, расположенные на расстоянии менее 650 м от населенного пункта",
        ],
        correctAnswers: [
          "Фонтанные скважины с дебитом 400 т/сут нефти или 500 тыс. м³/сут газа и более, расположенные на расстоянии менее 500 м от населенного пункта",
        ],
      },
      {
        code: "20110161",
        text: "На основании чего проводится периодическая проверка внутрискважинного клапана-отсекателя на срабатывание в процессе его эксплуатации?",
        answers: [
          "На основании инструкции завода-изготовителя",
          "На основании технологической инструкции",
          "На основании распоряжения руководителя эксплуатирующей организации",
          "На основании требований Ростехнадзора",
        ],
        correctAnswers: ["На основании инструкции завода-изготовителя"],
      },
      {
        code: "20110162",
        text: "Что необходимо устанавливать на выкидных линиях и манифольдах скважин, работающих с температурой рабочего тела 80 °С и более?",
        answers: [
          "Температурные компенсаторы",
          "Запорную арматуру с учетом ожидаемой температуры",
          "Демпферы",
          "Датчики температуры, извещающие о повышении или понижении температуры",
          "Аппараты воздушного охлаждения",
        ],
        correctAnswers: ["Температурные компенсаторы"],
      },
      {
        code: "20110163",
        text: "На какое давление испытывается манифольд после монтажа и соединения его с отводами фонтанной арматуры и трубной головки?",
        answers: [
          "На рабочее давление",
          "На максимальное давление",
          "На пробное давление, равное 1,25 рабочего давления",
          "На давление, превышающее рабочее на 15 %",
        ],
        correctAnswers: ["На рабочее давление"],
      },
      {
        code: "20110164",
        text: "Кем утверждается проект и план перевода скважины на газлифтную эксплуатацию?",
        answers: [
          "Техническим руководителем организации",
          "Руководителем Ростехнадзора",
          "Руководителем проектной организации",
          "Мастером участка добычи",
        ],
        correctAnswers: ["Техническим руководителем организации"],
      },
      {
        code: "20110165",
        text: "Какие меры должны приниматься в случае производства на скважине работ, требующих давлений, превышающих давления опрессовки обсадной колонны?",
        answers: [
          "На устье устанавливается специальная арматура, а эксплуатационная колонна защищается установкой пакера",
          "На устье устанавливается обратный клапан, эксплуатационная колонна защищается установкой клапана-отсекателя",
          "На устье устанавливается предохранитель, эксплуатационная колонна защищается установкой специального наголовника",
          "Все перечисленные меры",
        ],
        correctAnswers: [
          "На устье устанавливается специальная арматура, а эксплуатационная колонна защищается установкой пакера",
        ],
      },
      {
        code: "20110166",
        text: "Кто должен обслуживать электрооборудование установки?",
        answers: [
          "Электротехнический персонал, имеющий соответствующую квалификацию и допуск к работе",
          "Электротехнический персонал с группой по электробезопасности не ниже III",
          "Электротехнический персонал с группой по электробезопасности не ниже IV",
        ],
        correctAnswers: [
          "Электротехнический персонал, имеющий соответствующую квалификацию и допуск к работе",
        ],
      },
      {
        code: "20110167",
        text: "Кем должна выполняться подготовка замкнутого пространства аппарата (резервуара) для проведения работы внутри него?",
        answers: [
          "Работниками под руководством инженерно-технического работника, хорошо осведомленного о возможных опасностях",
          "Технологическим персоналом, прошедшим инструктаж",
          "Персоналом специализированной организации",
          "Специально подготовленной бригадой подрядной организации",
        ],
        correctAnswers: [
          "Работниками под руководством инженерно-технического работника, хорошо осведомленного о возможных опасностях",
        ],
      },
      {
        code: "20110168",
        text: "Какие сведения должны включаться в наряд-допуск на выполнение работ в замкнутом пространстве аппарата (резервуара)?",
        answers: [
          "Только периодичность и результаты отбора проб воздушной среды в замкнутом пространстве",
          "Только сведения о необходимых средствах индивидуальной защиты, составе бригады",
          "Только меры безопасности, принимаемые в замкнутом пространстве",
          "Только потребность в спасательных средствах и специальном инструменте",
          "В наряд-допуск должны включаться все перечисленные сведения",
        ],
        correctAnswers: [
          "В наряд-допуск должны включаться все перечисленные сведения",
        ],
      },
      {
        code: "20110169",
        text: "Чем оснащаются оборудование и емкости во избежание накопления статического электричества?",
        answers: [
          "Заземлением",
          "Двухжильным кабелем в металлической оплетке с применением разъема с экранирующим контуром",
          "Предохранительными клапанами",
          "Вентиляционными каналами",
          "Дыхательными трубками с отводом газов в безопасную зону",
        ],
        correctAnswers: ["Заземлением"],
      },
      {
        code: "20110170",
        text: "Должна ли продолжать работу механическая вентиляционная система после того, как замкнутое пространство очищено и проветрено?",
        answers: [
          "Должна, чтобы исключить случайное попадание в него вредных примесей, а также для удаления загрязняющих веществ или тепла, возникающих в результате выполняемых работ",
          "Должна в течение 2 часов после начала проветривания",
          "Должна в течение часа после начала проветривания",
          "Должна в течение 3 часов после начала проветривания",
        ],
        correctAnswers: [
          "Должна, чтобы исключить случайное попадание в него вредных примесей, а также для удаления загрязняющих веществ или тепла, возникающих в результате выполняемых работ",
        ],
      },
      {
        code: "20110171",
        text: "Что должно сделать лицо, ответственное за проведение работ, непосредственно перед допуском работников в замкнутое пространство?",
        answers: [
          "Только провести опрос каждого исполнителя о самочувствии",
          "Только повторно проинструктировать весь состав бригады о безопасных методах работы",
          "Только проверить знание каждым работником своих функций и обязанностей",
          "Только проверить качество и соответствие данным условиям работы средств индивидуальной защиты, спасательного снаряжения и инструментов",
          "Выполнить все перечисленные действия",
        ],
        correctAnswers: ["Выполнить все перечисленные действия"],
      },
      {
        code: "20110172",
        text: "Что должны осуществлять находящиеся снаружи наблюдающие?",
        answers: [
          "Только следить за правильным положением шланга шлангового противогаза и заборного патрубка",
          "Только держать в готовности дыхательные аппараты",
          "Только поддерживать постоянную связь с работниками, работающими в замкнутом пространстве",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20110173",
        text: "Какие меры необходимо предпринять при обнаружении в замкнутом пространстве опасной концентрации паров ЛВЖ или газов?",
        answers: [
          "Немедленно прекратить работы",
          "Продолжить работы, проветрив замкнутое пространство путем открытия люков с противоположных сторон замкнутого пространства",
          "Продолжить работы, проветрив замкнутое пространство с помощью механической системы принудительной вентиляции",
          "Продолжить работы, предварительно проинформировав руководителя работ",
        ],
        correctAnswers: ["Немедленно прекратить работы"],
      },
      {
        code: "20110174",
        text: "Куда должен производиться сброс нефти и нефтепродуктов из аппаратов, резервуаров и оборудования при их подготовке?",
        answers: [
          "В специальные (аварийные) емкости",
          "В закрытую дренажную систему",
          "В производственную канализацию",
          "В бытовую канализацию",
          "В открытую дренажную систему",
        ],
        correctAnswers: ["В специальные (аварийные) емкости"],
      },
      {
        code: "20110175",
        text: "Какой должна быть температура внутри резервуаров во время пропаривания?",
        answers: [
          "Не выше 60 °С",
          "Не выше 70 °С",
          "Не выше 80 °С",
          "Не выше 90 °С",
        ],
        correctAnswers: ["Не выше 60 °С"],
      },
      {
        code: "20110176",
        text: "Какое средство защиты должны использовать работники, выполняющие работы по очистке резервуаров и аппаратов от грязи и отложений?",
        answers: [
          "Шланговый противогаз",
          "Дыхательный аппарат автономного действия",
          "Фильтрующий противогаз",
          "Респиратор",
        ],
        correctAnswers: ["Шланговый противогаз"],
      },
      {
        code: "20110177",
        text: "Чем должно производиться отвертывание и завертывание гаек на фланцевых соединениях люков аппаратов, резервуаров (емкостей), трубопроводов и арматуры?",
        answers: [
          "Гайковертами с пневматическим или гидравлическим приводом",
          "Гаечным ключом без рычага",
          "Торцовым ключом с рычагом длиной 0,7 м",
          "Накидным ключом с рычагом длиной 0,7 м",
          "Гаечным ключом с рычагом длиной 0,7 м",
        ],
        correctAnswers: [
          "Гайковертами с пневматическим или гидравлическим приводом",
        ],
      },
      {
        code: "20110178",
        text: "При каких обстоятельствах ремонтные работы должны быть немедленно прекращены?",
        answers: [
          "При появлении газа, а также при аварии на соседней установке или объекте",
          "При отсутствии ответственного за проведение ремонтных работ",
          "При отключении штатного освещения",
        ],
        correctAnswers: [
          "При появлении газа, а также при аварии на соседней установке или объекте",
        ],
      },
      {
        code: "20110179",
        text: "Где должна производиться запись о проведенном ремонте оборудования?",
        answers: [
          "В паспорте оборудования",
          "В руководстве по эксплуатации",
          "В журнале проведения вводного инструктажа",
          "В журнале учета приема-сдачи смен",
        ],
        correctAnswers: ["В паспорте оборудования"],
      },
      {
        code: "20110180",
        text: "Какие квалификационные требования предъявляются к рабочим, осуществляющим ремонт электродегидраторов?",
        answers: [
          "Наличие допуска к работам на электроустановках напряжением выше 1000 В",
          "Наличие группы по электробезопасности, соответствующей требованиям действующих нормативных документов в области электробезопасности",
          "Наличие специальной подготовки в части обслуживания и ремонта электрообезвоживающей и обессоливающей установок",
        ],
        correctAnswers: [
          "Наличие допуска к работам на электроустановках напряжением выше 1000 В",
        ],
      },
      {
        code: "20110181",
        text: "Сколько человек допускается к работе в замкнутом пространстве, если по условиям работы нет необходимости в большем количестве работников?",
        answers: [
          "Один человек",
          "Два человека",
          "Три человека",
          "Двое работающих и один наблюдающий",
        ],
        correctAnswers: ["Один человек"],
      },
      {
        code: "20110182",
        text: "Какое минимальное количество наблюдающих должно находиться снаружи при работе в замкнутом пространстве?",
        answers: [
          "Не менее двух наблюдающих",
          "Один наблюдающих и руководитель работ",
          "Два наблюдающих и руководитель работ",
          "Три наблюдающих",
        ],
        correctAnswers: ["Не менее двух наблюдающих"],
      },
      {
        code: "20110183",
        text: "Какие светильники должны применяться для освещения внутри аппаратов и резервуаров?",
        answers: [
          "Стационарные светильники во взрывозащищенном исполнении с лампами напряжением не выше 42 В",
          "Переносные светильники во взрывозащищенном исполнении с лампами напряжением не выше 12 В",
          "Переносные светильники во взрывозащищенном исполнении с лампами напряжением не выше 24 В",
          "Стационарные светильники во взрывозащищенном исполнении с лампами напряжением не выше 50 В",
        ],
        correctAnswers: [
          "Переносные светильники во взрывозащищенном исполнении с лампами напряжением не выше 12 В",
        ],
      },
      {
        code: "20110184",
        text: "Какие меры должны быть приняты при очистке теплообменника или конденсатора механическим способом?",
        answers: [
          'Необходимо с противоположной стороны сделать ограждение и вывесить предупреждающую надпись "Опасная зона!"',
          'Необходимо с противоположной стороны сделать ограждение и вывесить предупреждающую надпись "Осторожно! Оборудование в работе"',
          'Необходимо за 10 м до теплообменника или конденсатора выставить заграждение и вывесить предупреждающую надпись "Стой! Напряжение"',
          "Необходимо на месте работы поставить наблюдающего, назначенного начальником участка",
        ],
        correctAnswers: [
          'Необходимо с противоположной стороны сделать ограждение и вывесить предупреждающую надпись "Опасная зона!"',
        ],
      },
      {
        code: "20110185",
        text: "При наличии какого документа разрешается приступать к проведению ремонтных работ аппаратов, резервуаров и оборудования?",
        answers: [
          "При наличии наряда-допуска с указанием ответственных лиц за подготовку и проведение ремонтных работ",
          "При наличии акта-допуска",
          "При наличии учетного листа с указанием состава ремонтной бригады",
          "При наличии разрешения, подписанного руководителем организации",
        ],
        correctAnswers: [
          "При наличии наряда-допуска с указанием ответственных лиц за подготовку и проведение ремонтных работ",
        ],
      },
      {
        code: "20110186",
        text: "Кем должны производиться работы по вскрытию и ремонту любого электрооборудования и освещения?",
        answers: [
          "Электротехническим персоналом",
          "Электротехнологическим персоналом",
          "Ремонтным персоналом",
          "Оперативным персоналом",
        ],
        correctAnswers: ["Электротехническим персоналом"],
      },
      {
        code: "20110187",
        text: "Каким образом должен быть подготовлен к ремонту технологический трубопровод?",
        answers: [
          "Перед проведением ремонтных работ необходимо трубопровод освободить от транспортируемого продукта, продуть паром",
          "Перед проведением ремонтных работ необходимо продуть трубопровод сжатым воздухом",
          "Перед проведением ремонтных работ необходимо продуть трубопровод кислородом",
        ],
        correctAnswers: [
          "Перед проведением ремонтных работ необходимо трубопровод освободить от транспортируемого продукта, продуть паром",
        ],
      },
      {
        code: "20110188",
        text: "Какие виды работ относятся к реконструкции нефтяных и газовых скважин в соответствии с Правилами безопасности в нефтяной и газовой промышленности?",
        answers: [
          "Комплекс работ по восстановлению работоспособности скважин, связанный с изменением их конструкции",
          "Комплекс работ по восстановлению работоспособности скважин, утраченной в результате аварии или инцидента",
          "Комплекс работ по изоляции пластов",
          "Комплекс работ по оптимизации режима эксплуатации нефтяных и газовых скважин",
        ],
        correctAnswers: [
          "Комплекс работ по восстановлению работоспособности скважин, связанный с изменением их конструкции",
        ],
      },
      {
        code: "20110189",
        text: "Что не входит в перечень плана работ по текущему, капитальному ремонту и реконструкции скважин?",
        answers: [
          "Состояние цементного камня за обсадной колонной",
          "Технические характеристики сальниковых уплотнений и давление на устье при опрессовке совместно с обсадными колоннами",
          "Перечень планируемых технологических операций",
          "Сведения о конструкции и состоянии скважины",
        ],
        correctAnswers: ["Состояние цементного камня за обсадной колонной"],
      },
      {
        code: "20110190",
        text: "В каком порядке производятся передача скважин для ремонта или реконструкции и приемка скважин после завершения ремонтных работ от заказчика подрядчику?",
        answers: [
          "Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ устанавливается эксплуатирующей организацией",
          "Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ устанавливается проектной организацией",
          "Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ устанавливается подрядной организацией",
        ],
        correctAnswers: [
          "Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ устанавливается эксплуатирующей организацией",
        ],
      },
      {
        code: "20110191",
        text: "В каком случае запрещается приступать к выполнению работ по бурению скважин?",
        answers: [
          "Если не проверена готовность трассы передвижения агрегатов (установок)",
          "Если отсутствует согласование с соответствующими организациями условий пересечения с линиями электропередачи, железнодорожных магистралей, магистральных трубопроводов и прочих природных и техногенных препятствий",
          "Если не заключены договоры на производство работ с подрядчиками (субподрядчиками)",
          "Во всех перечисленных случаях приступать к бурению скважины запрещено",
        ],
        correctAnswers: [
          "Во всех перечисленных случаях приступать к бурению скважины запрещено",
        ],
      },
      {
        code: "20110192",
        text: "В каких случаях при реконструкции и ремонте скважин на рабочей площадке проводится контроль состояния газовоздушной среды?",
        answers: [
          "Всегда при проведении работ по реконструкции и ремонту скважин на рабочей площадке проводится контроль состояния газовоздушной среды с регистрацией в журнале контроля",
          "Если работы ведутся на кустовой площадке",
          "Если возможно газонефтеводопроявление",
        ],
        correctAnswers: [
          "Всегда при проведении работ по реконструкции и ремонту скважин на рабочей площадке проводится контроль состояния газовоздушной среды с регистрацией в журнале контроля",
        ],
      },
      {
        code: "20110193",
        text: "Какие установлены требования к передвижению агрегатов по ремонту скважин и транспортированию оборудования на скважину?",
        answers: [
          "Запрещается передвижение оборудования при снегопадах, тумане, пылевых бурях при видимости менее 50 м и порывах ветра более 30 м/с",
          "Агрегаты по ремонту скважин должны передвигаться к скважине со скоростью не более 10 км/ч",
          "Маршруты передвижения агрегатов по ремонту скважин и транспортирования оборудования на скважину должны быть согласованы с Министерством транспорта Российской Федерации",
          "Все перечисленные требования",
        ],
        correctAnswers: [
          "Запрещается передвижение оборудования при снегопадах, тумане, пылевых бурях при видимости менее 50 м и порывах ветра более 30 м/с",
        ],
      },
      {
        code: "20110194",
        text: "С какими документами должна быть ознакомлена бригада, осуществляющая ремонт и реконструкцию нефтяных и газовых скважин, перед началом работ?",
        answers: [
          "С планом работ, ПЛА и возможными осложнениями и авариями",
          "С актом-допуском и нарядом-допуском",
          "С технологическими инструкциями",
        ],
        correctAnswers: [
          "С планом работ, ПЛА и возможными осложнениями и авариями",
        ],
      },
      {
        code: "20110195",
        text: "За какое время до начала подготовительных и монтажных работ по ремонту и реконструкции скважин бригаде должна выдаваться схема расположения подземных и наземных коммуникаций?",
        answers: [
          "Не менее чем за трое суток",
          "Не менее чем за двое суток",
          "Не менее чем за сутки",
          "В день начала производства работ",
        ],
        correctAnswers: ["Не менее чем за трое суток"],
      },
      {
        code: "20110196",
        text: "Какие требования предъявляются к установке агрегатов и оборудования для ремонта скважин?",
        answers: [
          "Агрегаты для ремонта скважин должны устанавливаться на передвижные или стационарные фундаменты",
          "Агрегаты для ремонта скважин должны быть в исправном состоянии и устанавливаться на расстоянии не менее 7 м от устья скважины",
          "Агрегаты для ремонта скважин должны быть выполнены во взрывозащищенном исполнении и устанавливаться на безопасносм расстоянии друг от друга",
        ],
        correctAnswers: [
          "Агрегаты для ремонта скважин должны устанавливаться на передвижные или стационарные фундаменты",
        ],
      },
      {
        code: "20110197",
        text: "При какой максимальной скорости ветра допускаются работы на высоте при монтаже и ремонте вышек (мачт) агрегатов, используемых для ремонта скважин?",
        answers: ["15 м/с", "10 м/с", "7 м/с", "12 м/с"],
        correctAnswers: ["15 м/с"],
      },
      {
        code: "20110198",
        text: "Кем должны осуществляться подключение переносных светильников и разводка кабелей в полевых условиях при проведении ремонтных работ?",
        answers: [
          "Двумя рабочими: электромонтером и рабочим бригады или двумя рабочими бригады, прошедшими соответствующий инструктаж, один из которых имеет квалификационную группу не ниже II",
          "Тремя рабочими, двое из которых относятся к электротехническому персоналу",
          "Электромонтером и двумя рабочими бригады, прошедшими соответствующий инструктаж и имеющими квалификационную группу не ниже II",
        ],
        correctAnswers: [
          "Двумя рабочими: электромонтером и рабочим бригады или двумя рабочими бригады, прошедшими соответствующий инструктаж, один из которых имеет квалификационную группу не ниже II",
        ],
      },
      {
        code: "20110199",
        text: "Что из перечисленного подлежит заземлению при ведении ремонтных работ?",
        answers: [
          "Только каркасы распределительных щитов станций управления",
          "Только емкости горюче-смазочных материалов",
          "Только корпусы генераторов передвижных электростанций",
          "Только емкости под раствор для глушения или долива скважины",
          "Только инструментальная тележка",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20110200",
        text: "При каких условиях должен осуществляться ремонт скважин на кусте с расположенными на поверхности грунта нефтегазопроводами?",
        answers: [
          "При условии отключения нефтегазопроводов со стороны скважин и замерного устройства и разрежения избыточного давления",
          "При условии полного опорожнения нефтегазопроводов",
          "При условии разборки нефтегазопроводов",
        ],
        correctAnswers: [
          "При условии отключения нефтегазопроводов со стороны скважин и замерного устройства и разрежения избыточного давления",
        ],
      },
      {
        code: "20110201",
        text: "Какой радиус имеет опасная зона, устанавливаемая вокруг устья скважины на время прострелочных работ?",
        answers: [
          "Не менее 10 м",
          "Не менее 15 м",
          "Не менее 5 м",
          "Не менее 7 м",
        ],
        correctAnswers: ["Не менее 10 м"],
      },
      {
        code: "20110202",
        text: "Кем устанавливается целесообразность и возможность использования ранее ликвидированных скважин путем их реконструкции?",
        answers: [
          "Комиссией, создаваемой организацией, эксплуатирующей ОПО",
          "Пользователем недр после согласования с территориальным органом Ростехнадзора",
          "Проектной организацией",
        ],
        correctAnswers: [
          "Комиссией, создаваемой организацией, эксплуатирующей ОПО",
        ],
      },
      {
        code: "20110203",
        text: "Какое из перечисленных требований предъявляется к оборудованию устья скважины, подлежащей реконструкции?",
        answers: [
          "Устье скважины вместе с ПВО должно быть опрессовано на давление, превышающее не менее чем на 10% возможное давление, возникающее при ликвидации ГНВП и открытых фонтанов, а также при опробовании и эксплуатации скважины, но не выше допустимого давления опрессовки эксплуатационной колонны",
          "Устье скважины вместе с ПВО должно быть опрессовано на давление, превышающее не менее чем на 5% возможное давление, возникающее при ликвидации ГНВП и открытых фонтанов, а также при опробовании и эксплуатации скважины, но не выше допустимого давления опрессовки эксплуатационной колонны",
          "Устье скважины вместе с ПВО должно быть опрессовано на давление, превышающее не менее чем на 7% возможное давление, возникающее при ликвидации ГНВП и открытых фонтанов, а также при опробовании и эксплуатации скважины, но не выше допустимого давления опрессовки эксплуатационной колонны",
        ],
        correctAnswers: [
          "Устье скважины вместе с ПВО должно быть опрессовано на давление, превышающее не менее чем на 10% возможное давление, возникающее при ликвидации ГНВП и открытых фонтанов, а также при опробовании и эксплуатации скважины, но не выше допустимого давления опрессовки эксплуатационной колонны",
        ],
      },
      {
        code: "20110204",
        text: "Чем регламентируются объем и периодичность геофизических работ в нефтяных и газовых скважинах?",
        answers: [
          "Геолого-техническим нарядом на производство буровых работ, планом проведения ремонтно-восстановительных работ и мероприятиями по контролю разработки пластов, состоянием и эксплуатацией скважин и скважинного оборудования",
          "Проектом разработки нефтяных и газовых месторождений",
          "Эксплуатационной документацией",
        ],
        correctAnswers: [
          "Геолого-техническим нарядом на производство буровых работ, планом проведения ремонтно-восстановительных работ и мероприятиями по контролю разработки пластов, состоянием и эксплуатацией скважин и скважинного оборудования",
        ],
      },
      {
        code: "20110205",
        text: "Какая мера безопасности должна быть соблюдена при проведении текущего или капитального ремонта скважины с возможным газонефтепроявлением?",
        answers: [
          "Устье скважины должно быть оснащено противовыбросовым оборудованием",
          "Скважина должна быть заглушена",
          "Должен быть установлен блок долива",
        ],
        correctAnswers: [
          "Устье скважины должно быть оснащено противовыбросовым оборудованием",
        ],
      },
      {
        code: "20110206",
        text: "На какой срок на стадии проектирования ОПО разрабатывается ТР?",
        answers: ["На 5 лет", "На год", "На 3 года", "На 2 года"],
        correctAnswers: ["На 5 лет"],
      },
      {
        code: "20110207",
        text: "Какие материалы применяются для изготовления, монтажа и ремонта технологического оборудования и трубопроводов для производств, использующих неорганические кислоты и щелочи?",
        answers: [
          "Материалы, обеспечивающие коррозионную стойкость к рабочей среде",
          "Полимерные материалы",
          "Композитные материалы",
          "Нержавеющая сталь",
        ],
        correctAnswers: [
          "Материалы, обеспечивающие коррозионную стойкость к рабочей среде",
        ],
      },
      {
        code: "20110208",
        text: "Какие требования предъявляются к трубопроводам для транспортировки кислот и щелочей, прокладываемым по эстакадам? Выберите два правильных варианта ответа.",
        answers: [
          "Трубопроводы должны быть защищены от падающих предметов",
          "Трубопроводы должны быть защищены от возможных ударов со стороны транспортных средств",
          "Трубопроводы для транспортировки кислот и щелочей следует располагать на самых верхних ярусах",
          "Трубопроводы для транспортировки кислот и щелочей необходимо изготавливать из композиционных материалов",
        ],
        correctAnswers: [
          "Трубопроводы должны быть защищены от падающих предметов",
          "Трубопроводы должны быть защищены от возможных ударов со стороны транспортных средств",
        ],
      },
      {
        code: "20110209",
        text: "Какой ширины предусматривается охранная зона межзаводского трубопровода кислот или щелочей, прокладываемого вне территории предприятия?",
        answers: [
          "Не менее 2 м с каждой его стороны",
          "Не менее 1 м с каждой его стороны",
          "Не менее 5 м с каждой его стороны",
          "Не менее 10 м с каждой его стороны",
        ],
        correctAnswers: ["Не менее 2 м с каждой его стороны"],
      },
      {
        code: "20110210",
        text: "Где допускается прокладка трубопроводов неорганических жидких кислот и (или) щелочей при условии, что трубопроводы должны быть заключены в специальные желоба или короба (коллекторы) с отводом утечек кислот и щелочей в безопасные места, определяемые проектом?",
        answers: [
          "В местах пересечения железных и автомобильных дорог",
          "По наружным стенам зданий, не связанных с обращением кислот и щелочей",
          "Через административные и бытовые помещения",
          "Через вспомогательные и подсобные помещения",
        ],
        correctAnswers: ["В местах пересечения железных и автомобильных дорог"],
      },
      {
        code: "20110211",
        text: "Что необходимо предусматривать на складах, пунктах слива-налива, расположенных на открытых площадках, где в условиях эксплуатации возможно поступление в воздух рабочей зоны паров кислот и щелочей, для обеспечения требований безопасности?",
        answers: [
          "Только автоматический контроль с сигнализацией превышения предельно допустимых концентраций",
          "Только включение светового и звукового сигналов при превышении предельно допустимых концентраций",
          "Только регистрацию всех случаев загазованности приборами",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20110212",
        text: "Каким образом определяется минимально допустимое расстояние от складов кислот и щелочей до взрывоопасных объектов?",
        answers: [
          "Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения, должно обеспечивать устойчивость зданий складов к воздействию данных факторов",
          "Расстояние устанавливается в соответствии с технологически регламентом на производство работ",
          "Расстояние определяется в соответствии с требованиями строительных норм и правил и с учетом расчетного радиуса опасной зоны",
          "Расстояние определяется в соответствии с требованиями законодательства о градостроительной деятельности",
        ],
        correctAnswers: [
          "Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения, должно обеспечивать устойчивость зданий складов к воздействию данных факторов",
        ],
      },
      {
        code: "20110213",
        text: "Каким должен быть радиус кривизны отвода при изготовлении отводов способом гиба на специальных станках?",
        answers: [
          "Не менее трех диаметров трубы",
          "Не менее диаметра трубы",
          "Не менее двух диаметров трубы",
          "Не менее четырех диаметров трубы",
        ],
        correctAnswers: ["Не менее трех диаметров трубы"],
      },
      {
        code: "20110214",
        text: "Каким требованиям должны соответствовать вновь проектируемые склады кислот, в которых возможно образование первичного кислотного облака? Выберите два правильных варианта ответа.",
        answers: [
          "На территории складов должен быть обеспечен автоматический контроль за уровнем загазованности и сигнализация об аварийных утечках",
          "На территории складов должен быть установлен указатель направления ветра, видимый из любой точки территории склада",
          "Склады должны располагаться с наветренной стороны преобладающих направлений ветров относительно места расположения ближайших населенных пунктов",
          "Склады должны располагаться в более высоких местах по отношению к другим зданиям и сооружениям",
        ],
        correctAnswers: [
          "На территории складов должен быть обеспечен автоматический контроль за уровнем загазованности и сигнализация об аварийных утечках",
          "На территории складов должен быть установлен указатель направления ветра, видимый из любой точки территории склада",
        ],
      },
      {
        code: "20110215",
        text: "Какой должна быть высота защитного ограждения каждой группы резервуаров относительно уровня расчетного объема разлившейся жидкости?",
        answers: [
          "На 0,2 м выше",
          "На 0,1 м выше",
          "На 0,15 м выше",
          "На 0,05 м выше",
        ],
        correctAnswers: ["На 0,2 м выше"],
      },
      {
        code: "20110216",
        text: "На каком максимальном расстоянии от верха опоры должны быть расположены обслуживающие площадки высоких опор вращающихся печей и сушилок в лакокрасочном производстве?",
        answers: ["300 мм", "200 мм", "400 мм", "500 мм"],
        correctAnswers: ["300 мм"],
      },
      {
        code: "20110217",
        text: "На какой высоте должны быть ограждения в местах прохода людей и проезда транспорта под подвесными конвейерами и транспортерами при производстве фосфора и его соединений?",
        answers: [
          "Не менее 2,2 м",
          "Не более 2,2 м",
          "Не более 3,4 м",
          "Не менее 3,4 м",
        ],
        correctAnswers: ["Не менее 2,2 м"],
      },
      {
        code: "20110218",
        text: "Где размещают лаковыпускные отделения в производстве лаков на конденсационных смолах?",
        answers: [
          "Только на открытых площадках",
          "Только в отдельно стоящем корпусе",
          "Только в изолированном помещении корпуса синтеза",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20110219",
        text: "Какие требования установлены к отделениям, в которых производят дробление пека, приготовление или разогрев электродной массы, и к оборудованию в этих отделениях?",
        answers: [
          "Отделения должны быть изолированы от остальных рабочих помещений, а оборудование должно быть герметизировано или надежно укрыто и снабжено средствами местного отсоса",
          "Отделения должны быть совмещены с остальными рабочими помещениями, оборудование должно быть снабжено средствами местного отсоса",
          "Отделения должны быть совмещены с остальными рабочими помещениями, оборудование должно быть герметичным",
          "Все перечисленные требования",
        ],
        correctAnswers: [
          "Отделения должны быть изолированы от остальных рабочих помещений, а оборудование должно быть герметизировано или надежно укрыто и снабжено средствами местного отсоса",
        ],
      },
      {
        code: "20110220",
        text: "В каком месте может размещаться оборудование, работающее на аммиаке?",
        answers: [
          "Только в помещении потребителей холода",
          "Только в машинном или аппаратном отделении",
          "Только на открытой площадке",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20110221",
        text: "Где допускается размещать насосы оборотного водоснабжения в обоснованных в проектной документации случаях? Укажите все правильные ответы.",
        answers: [
          "В аппаратном отделении",
          "В машинном отделении",
          "На открытой площадке",
          "В бытовом помещении",
        ],
        correctAnswers: ["В аппаратном отделении", "В машинном отделении"],
      },
      {
        code: "20110222",
        text: "Какой документ необходимо оформлять при выполнении монтажных работ в помещении и на участках действующей холодильной системы, а также в условиях недействующих узлов, находящихся под аммиаком или не отсоединенных от остальной части системы?",
        answers: [
          "Наряд-допуск на указанные работы",
          "План работ, утвержденный руководителем организации",
          "Оформление дополнительного документа на проведение указанных работ не требуется",
          "Внутреннее распоряжение о производстве указанных работ",
        ],
        correctAnswers: ["Наряд-допуск на указанные работы"],
      },
      {
        code: "20110223",
        text: "Какое из перечисленных требований к испытанию на прочность после монтажа до пуска в эксплуатацию сосуда (аппарата), трубопровода (его участка) указано верно?",
        answers: [
          "При испытании на прочность после монтажа до пуска в эксплуатацию испытываемый сосуд (аппарат), трубопровод (его участок) должны быть отсоединены от других сосудов, аппаратов и других трубопроводов с использованием металлических заглушек с прокладками, имеющих хвостовики, выступающие за пределы фланцев не менее 20 мм",
          "Толщина заглушки должна быть рассчитана на условия работы при давлении выше пробного в 1,25 раза",
          "Использование запорной арматуры для отключения испытываемого сосуда и трубопровода допускается в присутствии работника, ответственного за исправное состояние и безопасную работу сосудов",
          "При периодическом освидетельствовании и техническом диагностировании с использованием метода акустической эмиссии и аммиака в качестве нагружающей среды не допускается испытывать отдельные технологические линии блоком",
        ],
        correctAnswers: [
          "При испытании на прочность после монтажа до пуска в эксплуатацию испытываемый сосуд (аппарат), трубопровод (его участок) должны быть отсоединены от других сосудов, аппаратов и других трубопроводов с использованием металлических заглушек с прокладками, имеющих хвостовики, выступающие за пределы фланцев не менее 20 мм",
        ],
      },
      {
        code: "20110224",
        text: "Какое из обязательных требований к проектированию, техническому перевооружению или реконструкции действующих объектов производства растительных масел указано неверно?",
        answers: [
          "Определение мест расположения насосного и компрессорного оборудования",
          "Осуществление оценки взрывоопасности технологических блоков",
          "Рассчет радиуса зон по уровням опасности возможных разрушений и травмирования персонала",
          "Составление заключения об эффективности и надежности мер и средств защиты, их способности обеспечить взрывобезопасность технологического блока и технологического объекта в целом",
        ],
        correctAnswers: [
          "Определение мест расположения насосного и компрессорного оборудования",
        ],
      },
      {
        code: "20110225",
        text: "При каких условиях допускается проводить технологический взрывоопасный процесс в области критических значений температур на объектах, связанных с производством растительных масел?",
        answers: [
          "В случае принятия мер, исключающих или предотвращающих появление источников зажигания",
          "При наличии у персонала средств индивидуальной защиты",
          "При оформлении наряда-допуска",
          "Ни при каком условии",
        ],
        correctAnswers: [
          "В случае принятия мер, исключающих или предотвращающих появление источников зажигания",
        ],
      },
      {
        code: "20110226",
        text: "Для каких блоков объектов производств растительных масел допускается применение автоматических средств контроля параметров, значения которых определяют взрывоопасность процесса, и ручного регулирования?",
        answers: [
          "Для блоков, имеющих относительный энергетический потенциал Qв меньше 10",
          "Для блоков II категории взрывоопасности",
          "Для блоков I категории взрывоопасности",
          "Для блоков III категории взрывоопасности",
        ],
        correctAnswers: [
          "Для блоков, имеющих относительный энергетический потенциал Qв меньше 10",
        ],
      },
      {
        code: "20110227",
        text: "Какими документами должны сопровождаться технологические трубопроводы после монтажа и ремонта?",
        answers: [
          "Удостоверениями о качестве монтажа с соответствующими приложениями (например, сертификатами на трубы, фитинги, арматуру, опоры, сварочные материалы, копиями удостоверений сварщиков, документами по результатам контроля качества работ)",
          "Документами о стоимости материалов и работ по проведению монтажа и ремонта",
          "Лицензиями и другими учредительными документами организации, проводившей монтаж и ремонт",
          "Разрешениями организации, проводившей монтаж и ремонт на проведение этих видов работ",
        ],
        correctAnswers: [
          "Удостоверениями о качестве монтажа с соответствующими приложениями (например, сертификатами на трубы, фитинги, арматуру, опоры, сварочные материалы, копиями удостоверений сварщиков, документами по результатам контроля качества работ)",
        ],
      },
      {
        code: "20110228",
        text: "Как должны быть установлены все металлические конструкции, расположенные в пределах площадок, на которых размещены сосуды и сливоналивные устройства жидких продуктов разделения воздуха?",
        answers: [
          "На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,2 м",
          "На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,25 м",
          "На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,15 м",
          "На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,1 м",
        ],
        correctAnswers: [
          "На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,2 м",
        ],
      },
      {
        code: "20110229",
        text: "Какие требования устанавливаются Правилами безопасности химически опасных производственных объектов к ограждению и габаритам трапов ливневой канализации, приямков и подвалов, расположенных за пределами площадок с сосудами и сливоналивными устройствами жидких продуктов разделения воздуха на расстоянии менее 10,0 м?",
        answers: [
          "Должны иметь бетонное ограждение (порог) высотой не менее 0,2 м со стороны, обращенной к площадке, и выступать за габариты ограждаемых объектов не менее чем на 1,0 м",
          "Должны иметь бетонное ограждение (порог) высотой не менее 0,1 м со стороны, обращенной к площадке, и выступать за габариты ограждаемых объектов не менее чем на 1,0 м",
          "Должны иметь бетонное ограждение (порог) высотой не менее 0,2 м со стороны, обращенной к площадке, и выступать за габариты ограждаемых объектов не менее чем на 2,0 м",
          "Должны иметь бетонное ограждение (порог) высотой не менее 0,5 м со стороны, обращенной к площадке, и выступать за габариты ограждаемых объектов не менее чем на 2,0 м",
        ],
        correctAnswers: [
          "Должны иметь бетонное ограждение (порог) высотой не менее 0,2 м со стороны, обращенной к площадке, и выступать за габариты ограждаемых объектов не менее чем на 1,0 м",
        ],
      },
      {
        code: "20110230",
        text: "Какой высоты должно быть ограждение площадок с техническими устройствами при размещении площадок на территории, имеющей общее ограждение?",
        answers: [
          "Не менее 1,2 м",
          "Не менее 2,0 м",
          "Не менее 0,2 м",
          "Не менее 1,0 м",
        ],
        correctAnswers: ["Не менее 1,2 м"],
      },
      {
        code: "20110231",
        text: "Для каких реципиентов в месте их подключения к межцеховым кислородопроводам необходимо устанавливать отключающие задвижки с дистанционным управлением?",
        answers: [
          "Вместимостью более 200 м³ с рабочим давлением более 1,6 МПа",
          "Вместимостью более 100 м³ с рабочим давлением более 1,4 МПа",
          "Вместимостью более 10 м³ с рабочим давлением более 1,1 МПа",
          "Вместимостью более 20 м³ с рабочим давлением более 1,5 МПа",
        ],
        correctAnswers: [
          "Вместимостью более 200 м³ с рабочим давлением более 1,6 МПа",
        ],
      },
      {
        code: "20110232",
        text: "Из какого материала должны выполняться линии отбора кислорода на анализы независимо от параметров кислорода? Укажите все правильные ответы.",
        answers: [
          "Из медных сплавов",
          "Из коррозионностойкой стали",
          "Из чугуна",
          "Из сплавов алюминия",
        ],
        correctAnswers: ["Из медных сплавов", "Из коррозионностойкой стали"],
      },
      {
        code: "20110233",
        text: "Какой категории обеспечения надежности электроснабжения должны быть электроприемники, обеспечивающие непрерывность технологического процесса производства на объектах производства и потребления продуктов разделения воздуха?",
        answers: [
          "К I категории надежности",
          "К III категории надежности",
          "К II категории надежности",
          "Требования к категории надежности не установлены",
        ],
        correctAnswers: ["К I категории надежности"],
      },
      {
        code: "20110234",
        text: "Какие требования к техническим устройствам и инженерным коммуникациям не соответствуют требованиям Правил безопасности химически опасных производственных объектов?",
        answers: [
          "При нормативной (регламентной) утечке или сбросе продуктов разделения воздуха в помещение (в отдельных случаях, определенных проектной документацией (документацией)) не предусматривается их удаление системой приточно-вытяжной вентиляции",
          "При устройстве под блоками разделения воздуха подвального помещения в нем необходимо поддерживать температуру воздуха не ниже 5 °C, а также предусматривать систему вытяжной вентиляции",
          "При изменении технологического процесса или реконструкции производственного участка действующие на этом участке вентиляционные системы необходимо привести в соответствие с новыми производственными условиями",
          "Система продувок кислородных, азотных и аргонных технических устройств и коммуникаций должна исключать возможность поступления продуктов разделения воздуха в помещение",
        ],
        correctAnswers: [
          "При нормативной (регламентной) утечке или сбросе продуктов разделения воздуха в помещение (в отдельных случаях, определенных проектной документацией (документацией)) не предусматривается их удаление системой приточно-вытяжной вентиляции",
        ],
      },
      {
        code: "20110235",
        text: "Какие требования необходимо соблюдать при устройстве под блоками разделения воздуха подвального помещения?",
        answers: [
          "Поддерживать температуру воздуха не ниже 5 °С, а также предусматривать систему вытяжной вентиляции",
          "Поддерживать температуру воздуха не ниже 2 °С, а также предусматривать систему приточно-вытяжной вентиляции",
          "Поддерживать температуру воздуха не ниже 5 °С, а также предусматривать систему кондиционирования воздуха",
          "Поддерживать температуру воздуха не ниже 3 °С, а также предусматривать систему пожаротушения",
        ],
        correctAnswers: [
          "Поддерживать температуру воздуха не ниже 5 °С, а также предусматривать систему вытяжной вентиляции",
        ],
      },
      {
        code: "20110236",
        text: "Какие требования к сетям водопроводов и канализации и устройствам, подключаемым к сетям, не соответствуют требованиям Правил безопасности химически опасных производственных объектов?",
        answers: [
          "В случае крайней необходимости допускается располагать устройства, такие как фонтанчики, краны, раковины или ванны самопомощи, аварийные души в помещениях, в которых обращаются или хранятся вещества, которые при контакте с водой разлагаются с взрывом или воспламеняются, а также выделяют взрывоопасные или токсичные газы",
          "Осмотр и очистку канализационных сетей и колодцев необходимо производить по графикам и в соответствии с порядком проведения газоопасных работ",
          "В производственных помещениях, где возможно повышенное содержание кислорода, которое может привести к воспламенению одежды или находятся опасные вещества, которые могут вызвать химические ожоги, должны устанавливаться фонтанчики, краны, раковины или ванны самопомощи, аварийные души",
          "Соединение сетей хозяйственно-питьевого водопровода с сетями водопроводов, подающих воду технического качества, не допускается",
          "Фонтанчики, краны, раковины или ванны самопомощи, аварийные души должны располагаться в легкодоступных местах и подключаться к хозяйственно-питьевому водопроводу",
        ],
        correctAnswers: [
          "В случае крайней необходимости допускается располагать устройства, такие как фонтанчики, краны, раковины или ванны самопомощи, аварийные души в помещениях, в которых обращаются или хранятся вещества, которые при контакте с водой разлагаются с взрывом или воспламеняются, а также выделяют взрывоопасные или токсичные газы",
        ],
      },
      {
        code: "20110237",
        text: "Что не соответствует требованиям, предъявляемым к освещению в производственных и вспомогательных зданиях?",
        answers: [
          "Во взрывоопасных помещениях следует предусматривать системы освещения люминесцентными лампами или лампами накаливания открытого типа",
          "Естественное и искусственное освещение в производственных и вспомогательных зданиях и помещениях объектов производств и потребления ПРВ, включая аварийное освещение основных рабочих мест, должно соответствовать проектной документации",
          "Организациями, эксплуатирующими объекты производства и потребления продуктов разделения воздуха, должны осуществляться систематические проверки исправности сети аварийного освещения в порядке и с периодичностью, установленными эксплуатирующей организацией порядке и периодичностью",
          "Конструктивное исполнение переносных светильников следует выбирать с учетом характера среды и места их использования",
        ],
        correctAnswers: [
          "Во взрывоопасных помещениях следует предусматривать системы освещения люминесцентными лампами или лампами накаливания открытого типа",
        ],
      },
      {
        code: "20110238",
        text: "На какие наземные склады жидкого аммиака не распространяются Правила безопасности химически опасных производственных объектов?",
        answers: [
          "На склады аммиака в баллонах",
          "На перевалочные склады, расположенные на припортовых заводах или базах водного транспорта; прирельсовые склады, расположенные вне территории предприятий и предназначенные для приема жидкого аммиака из железнодорожных цистерн, хранения его в резервуарах и раздачи потребителям в автоцистерны",
          "На глубинные сельскохозяйственные склады, размещаемые на территории сельскохозяйственного района; раздаточные станции, расположенные в сельскохозяйственных районах, получающих аммиак из аммиакопровода",
          "На заводские склады, расположенные на территории организаций, производящих аммиак, использующих его в качестве сырья или вспомогательного материала для выпуска товарной продукции, применяющих аммиак в промышленных холодильных установках или для других целей",
          "На раздаточные станции, расположенные в сельскохозяйственных районах, получающих аммиак из аммиакопровода",
        ],
        correctAnswers: ["На склады аммиака в баллонах"],
      },
      {
        code: "20110239",
        text: "Какое устройство должно быть установлено на территории склада жидкого аммиака?",
        answers: [
          "Указатель направления ветра",
          "Тонометр",
          "Датчик контроля вибрации",
          "Гигрометр",
        ],
        correctAnswers: ["Указатель направления ветра"],
      },
      {
        code: "20110240",
        text: "Какая ширина верха земляного вала устанавливается для отдельно стоящего резервуара для хранения жидкого аммиака?",
        answers: [
          "Не менее 1 м",
          "Не менее 0,5 м",
          "Не менее 0,6 м",
          "Не менее 0,8 м",
        ],
        correctAnswers: ["Не менее 1 м"],
      },
      {
        code: "20110241",
        text: "Какой должна быть высота ограждения резервуаров для хранения жидкого аммиака?",
        answers: [
          "Не менее чем на 0,3 м выше расчетного уровня разлившегося жидкого аммиака, но не менее 1 м, а для изотермических резервуаров - не менее 1,5 м",
          "Не менее чем на 0,2 м выше расчетного уровня разлившегося жидкого аммиака, но не менее 0,5 м",
          "Не менее чем на 0,1 м выше расчетного уровня разлившегося жидкого аммиака, но не менее 0,5 м",
          "Не менее чем на 0,1 м выше расчетного уровня разлившегося жидкого аммиака, но не менее 1 м, а для изотермических резервуаров - не менее 1 м",
        ],
        correctAnswers: [
          "Не менее чем на 0,3 м выше расчетного уровня разлившегося жидкого аммиака, но не менее 1 м, а для изотермических резервуаров - не менее 1,5 м",
        ],
      },
      {
        code: "20110242",
        text: "Какое расстояние устанавливается внутри ограждения между лестницами, применяемыми для переходов через ограждения резервуаров для хранения жидкого аммиака?",
        answers: [
          "Не более 80 м",
          "Не более 95 м",
          "Не более 85 м",
          "Не более 90 м",
        ],
        correctAnswers: ["Не более 80 м"],
      },
      {
        code: "20110243",
        text: "На каком расстоянии со стороны зданий и открытых установок, примыкающих к ограждению резервуаров для хранения жидкого аммиака, допускается располагать объезд?",
        answers: [
          "Не более 39 м от ограждения резервуаров",
          "Не более 40 м от ограждения резервуаров",
          "Не более 45 м от ограждения резервуаров",
          "Не более 50 м от ограждения резервуаров",
        ],
        correctAnswers: ["Не более 39 м от ограждения резервуаров"],
      },
      {
        code: "20110244",
        text: "Какая ширина должна быть у автомобильных дорог для подъезда к складу жидкого аммиака и проезду по его территории к зданиям и сооружениям?",
        answers: [
          "Не менее 3,5 м",
          "Не менее 3 м",
          "Не менее 2,5 м",
          "Не менее 2 м",
        ],
        correctAnswers: ["Не менее 3,5 м"],
      },
      {
        code: "20110245",
        text: "Какие должны быть здания на территории склада жидкого аммиака по степени огнестойкости?",
        answers: [
          "Не ниже II степени огнестойкости",
          "Не ниже III степени огнестойкости",
          "Не ниже IV степени огнестойкости",
          "Не ниже V степени огнестойкости",
        ],
        correctAnswers: ["Не ниже II степени огнестойкости"],
      },
      {
        code: "20110246",
        text: "В течение какого срока службы устройства резервуаров для хранения аммиака должны обеспечивать надежную и безопасную эксплуатацию?",
        answers: [
          "В течение срока службы, указанного в паспорте организации-изготовителя",
          "В течение 10 лет",
          "В течение 15 лет",
          "В течение 20 лет",
        ],
        correctAnswers: [
          "В течение срока службы, указанного в паспорте организации-изготовителя",
        ],
      },
      {
        code: "20110247",
        text: "При какой вместимости резервуаров, работающих под избыточным внутренним давлением, допускается применение подогревательных устройств, размещаемых внутри или на наружной поверхности резервуаров?",
        answers: [
          "Не более 50 т",
          "Не более 60 т",
          "Не более 80 т",
          "Не более 100 т",
        ],
        correctAnswers: ["Не более 50 т"],
      },
      {
        code: "20110248",
        text: "В каком случае необходимо контролировать величину и равномерность осадки фундаментов шаровых резервуаров в эксплуатации?",
        answers: [
          "Только до гидравлического испытания резервуара",
          "Только после гидравлического испытания резервуара",
          "Только перед подачей в резервуар жидкого аммиака",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20110249",
        text: "Какой величины должно приниматься расчетное давление при проектировании резервуаров для хранения жидкого аммиака?",
        answers: [
          "Больше рабочего на 25 %, но не менее чем на 98,06 Па (10 мм вод. ст.)",
          "Больше рабочего на 20 %, но не менее чем на 98,06 Па (10 мм вод. ст.)",
          "Больше рабочего на 15 %, но не менее чем на 98,06 Па (10 мм вод. ст.)",
          "Больше рабочего на 10 %, но не менее чем на 98,06 Па (10 мм вод. ст.)",
        ],
        correctAnswers: [
          "Больше рабочего на 25 %, но не менее чем на 98,06 Па (10 мм вод. ст.)",
        ],
      },
      {
        code: "20110250",
        text: "Какая арматура применяется на трубопроводах жидкого и газообразного аммиака?",
        answers: [
          "Стальная арматура и фасонные части",
          "Чугунная арматура",
          "Арматура и фитинги с деталями из меди и ее сплавов",
          "Арматура и фитинги с деталями из цинка и его сплавов",
        ],
        correctAnswers: ["Стальная арматура и фасонные части"],
      },
      {
        code: "20110251",
        text: "Какие требования к предохранительным клапанам, устанавливаемым на резервуары жидкого аммиака, указаны неверно?",
        answers: [
          "Не допускается изготовление предохранительных и вакуумных клапанов для изотермических резервуаров из алюминиевых сплавов",
          "На наружных оболочках изотермических резервуаров с засыпной изоляцией разрешается не устанавливать предохранительные клапаны, если такие клапаны имеются на буферном сосуде (газгольдере) азота или на трубопроводе, который соединяет наружную оболочку с буферным сосудом",
          "Применение рычажно-грузовых предохранительных клапанов не допускается",
          "Резервуары с аммиаком необходимо оборудовать предохранительными клапанами",
        ],
        correctAnswers: [
          "Не допускается изготовление предохранительных и вакуумных клапанов для изотермических резервуаров из алюминиевых сплавов",
        ],
      },
      {
        code: "20110252",
        text: "Какие требования к тепловой изоляции резервуаров жидкого аммиака указаны неверно?",
        answers: [
          "При применении в качестве наружной изоляции материалов, подверженных горению, обеспечиваются меры, исключающие возможность воспламенения изоляции (орошение, защита негорючими покрытиями и т. п.), согласованные в установленном порядке",
          "Устройство теплоизоляции днища изотермического резервуара должно исключать попадание влаги, сплошное промерзание грунта под фундаментом от хранимого аммиака и деформацию элементов конструкции резервуара",
          "Тепловая изоляция должна выполняться из негорючих или трудносгораемых материалов",
          "Изоляция днища изотермического резервуара в зоне окраек должна быть сплошной, из прочных твердых материалов, которые должны лежать на бетонном фундаменте одностенного резервуара и на окрайках наружного днища двухстенного резервуара",
        ],
        correctAnswers: [
          "При применении в качестве наружной изоляции материалов, подверженных горению, обеспечиваются меры, исключающие возможность воспламенения изоляции (орошение, защита негорючими покрытиями и т. п.), согласованные в установленном порядке",
        ],
      },
      {
        code: "20110253",
        text: "При каких погодных условиях производится монтаж изоляции изотермических резервуаров жидкого аммиака?",
        answers: [
          "Только при температуре не ниже предусмотренной техническими условиями",
          "Только в теплое время года",
          "Только при отсутствии атмосферных осадков",
          "При соблюдении всех перечисленных погодных условий",
        ],
        correctAnswers: ["При соблюдении всех перечисленных погодных условий"],
      },
      {
        code: "20110254",
        text: "С какой периодичностью проводится термографирование наружной поверхности резервуара жидкого аммиака в целях выявления участков с нарушенной теплоизоляцией?",
        answers: [
          "Один раз в год",
          "Один раз в 6 месяцев",
          "Один раз в месяц",
          "Один раз в 3 месяца",
        ],
        correctAnswers: ["Один раз в год"],
      },
      {
        code: "20110255",
        text: "Что рекомендуется применять для изоляции внутренних стен и крыши изотермических резервуаров для хранения жидкого аммиака?",
        answers: [
          "Вспученный перлитовый песок",
          "Стеклянное волокно",
          "Минеральную вату",
          "Пенополиуретан",
        ],
        correctAnswers: ["Вспученный перлитовый песок"],
      },
      {
        code: "20110256",
        text: "Какие требования к системам противоаварийной защиты, обеспечивающим защиту резервуаров жидкого аммиака от переполнения, указаны неверно?",
        answers: [
          "Для резервуаров вместимостью до 10 м³ защита обеспечивается дублированием систем контроля параметров",
          "Для резервуаров вместимостью до 25 м³ защита обеспечивается дублированием систем контроля и сопоставлением технологически связанных параметров",
          "Для резервуаров вместимостью 50 м³ и более защита обеспечивается дублированием систем контроля, наличием систем самодиагностики и сопоставлением технологически связанных параметров",
          "Для резервуаров вместимостью до 50 м³ защита обеспечивается дублированием систем контроля и наличием систем самодиагностики с индикацией исправного состояния",
        ],
        correctAnswers: [
          "Для резервуаров вместимостью до 25 м³ защита обеспечивается дублированием систем контроля и сопоставлением технологически связанных параметров",
        ],
      },
    ],

    20111: [
      {
        code: "20111000",
        text: "Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?",
        answers: [
          "Любой из специалистов организации-заказчика (эксплуатирующей организации)",
          "Только специалист, обученный пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность",
          "Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ",
          "Только руководитель структурного подразделения, где будет проводиться газоопасная работа, или его уполномоченный заместитель",
        ],
        correctAnswers: [
          "Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ",
        ],
      },
      {
        code: "20111001",
        text: "Где регистрируются газоопасные работы II группы?",
        answers: [
          "В журнале регистрации целевого инструктажа",
          "В журнале регистрации нарядов-допусков на проведение газоопасных работ с присвоением очередного номера",
          "В журнале учета газоопасных работ, проводимых без наряда-допуска на проведение газоопасных работ",
          "В вахтовом журнале",
        ],
        correctAnswers: [
          "В журнале учета газоопасных работ, проводимых без наряда-допуска на проведение газоопасных работ",
        ],
      },
      {
        code: "20111002",
        text: "Кем из перечисленных должностных лиц согласовываются сроки проведения газоопасных работ на опасных производственных объектах подрядными организациями?",
        answers: [
          "Лицом, ответственным за осуществление производственного контроля",
          "Руководителем эксплуатирующей организации или его уполномоченным заместителем либо руководителем филиала или его уполномоченным заместителем с учетом особенностей использования электронной подписи",
          "Представителем Ростехнадзора",
          "Руководителем подрядной организации",
        ],
        correctAnswers: [
          "Руководителем эксплуатирующей организации или его уполномоченным заместителем либо руководителем филиала или его уполномоченным заместителем с учетом особенностей использования электронной подписи",
        ],
      },
      {
        code: "20111003",
        text: "Какая из перечисленных подготовительных работ к проведению газоопасных работ в пределах площади, где возможно поступление паров и газов опасных веществ, указана неверно?",
        answers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
          "Обозначается (ограждается) место в пределах площади, где возможно поступление паров и газов опасных веществ",
          "Выставляются посты в целях исключения допуска посторонних лиц в опасную зону по решению лица, ответственного за подготовку газоопасной работы",
        ],
        correctAnswers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
        ],
      },
      {
        code: "20111004",
        text: "О чем опрашивает каждого исполнителя лицо, ответственное за проведение газоопасных работ?",
        answers: [
          "О знании правил оказания первой помощи",
          "О самочувствии",
          "О знании действий при пожаре",
          "О наличии медицинских противопоказаний к работе",
        ],
        correctAnswers: ["О самочувствии"],
      },
      {
        code: "20111005",
        text: "При каких условиях разрешается входить в газоопасное место при проведении газоопасных работ?",
        answers: [
          "Только с разрешения лица, ответственного за осуществление производственного контроля, и в средствах защиты органов дыхания",
          "Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны",
          "Только с разрешения лиц, ответственных за подготовку и проведение работ, и с инструментом, исключающим возможность искрообразования",
          "Только с разрешения представителя газоспасательной службы и при обеспечении принудительного воздухообмена для безопасного ведения работ в газоопасном месте",
        ],
        correctAnswers: [
          "Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны",
        ],
      },
      {
        code: "20111006",
        text: "В каком из перечисленных документов должны быть определены место нахождения работающего и наблюдающего в процессе выполнения работы и их действия в случае возникновения ситуаций, связанных с ухудшением самочувствия работающего или наблюдающего при проведении газоопасных работ внутри емкости (аппарата)?",
        answers: [
          "В наряде-допуске на проведение газоопасных работ",
          "В журнале ведения технологического процесса",
          "В технологическом регламенте и производственных инструкциях по рабочим местам",
          "В специально разрабатываемой эксплуатирующей организацией инструкции",
          "В сменном журнале",
        ],
        correctAnswers: ["В наряде-допуске на проведение газоопасных работ"],
      },
      {
        code: "20111007",
        text: "Кто должен лично убедиться после окончания работ внутри емкости, что в емкости не остались люди, убран инструмент, материалы, не осталось посторонних предметов, и сделать об этом запись в наряде-допуске?",
        answers: [
          "Лицо, ответственное за осуществление производственного контроля",
          "Лицо, ответственное за проведение газоопасных работ",
          "Начальник цеха",
          "Главный инженер",
          "Начальник установки, на которой проводились газоопасные работы",
        ],
        correctAnswers: ["Лицо, ответственное за проведение газоопасных работ"],
      },
      {
        code: "20111008",
        text: "Какие информационные плакаты вывешиваются в зоне газоопасных работ на видном месте перед началом работ внутри емкостей и на все время их проведения?",
        answers: [
          '"Газоопасные работы", "Газ"',
          '"Работают люди!", "Опасно для жизни!"',
          '"Осторожно! Опасная зона", "Работают люди!"',
          '"Опасно для жизни!", "Внимание!"',
        ],
        correctAnswers: ['"Газоопасные работы", "Газ"'],
      },
      {
        code: "20111009",
        text: "Кому лицо, ответственное за подготовку газоопасных работ, должно сдать объект после окончания подготовительных работ?",
        answers: [
          "Лицу, ответственному за проведение газоопасной работы",
          "Руководителю эксплуатирующей организации",
          "Лицу, ответственному за безопасное ведение технологического процесса на объекте",
          "Начальнику цеха",
          "Пожарной службе эксплуатирующей организации",
        ],
        correctAnswers: [
          "Лицу, ответственному за проведение газоопасной работы",
        ],
      },
      {
        code: "20111010",
        text: "В присутствии кого должна начинаться газоопасная работа?",
        answers: [
          "В присутствии главного инженера и начальника службы охраны труда",
          "В присутствии лица, ответственного за проведение работ",
          "В присутствии начальника цеха и лица, ответственного за проведение подготовительных работ",
          "В присутствии технического руководителя организации и начальника смены",
        ],
        correctAnswers: [
          "В присутствии лица, ответственного за проведение работ",
        ],
      },
      {
        code: "20111011",
        text: "В присутствии кого проводится проверка исправности, устойчивости и надежности закрепления лестницы по месту работы при работах внутри емкости?",
        answers: [
          "В присутствии лица, ответственного за проведение газоопасных работ",
          "В присутствии рабочего, который будет спускаться в емкость, и наблюдающего",
          "В присутствии главного инженера",
          "В присутствии начальника цеха",
          "В присутствии представителя ГСС",
        ],
        correctAnswers: [
          "В присутствии лица, ответственного за проведение газоопасных работ",
        ],
      },
      {
        code: "20111012",
        text: "При какой максимальной температуре работа внутри емкостей (аппаратов) не допускается?",
        answers: ["40 °C", "50 °C", "55 °C", "45 °C"],
        correctAnswers: ["50 °C"],
      },
      {
        code: "20111013",
        text: "Что должны включать в себя мероприятия, обеспечивающие безопасность выполнения работ внутри аппаратов без средств индивидуальной защиты органов дыхания?",
        answers: [
          "Только непрерывный контроль состояния воздушной среды",
          "Только наличие у места проведения работ средств сигнализации и связи (световой, звуковой, радиотелефонной)",
          "Все перечисленное",
          "Только наличие у каждого работающего в емкости (аппарате) предохранительного пояса или страховочной привязи с закрепленной сигнально-спасательной веревкой",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20111014",
        text: "Какими средствами индивидуальной защиты в обязательном порядке должен быть оснащен рабочий, спускающийся в емкость?",
        answers: [
          "Всеми перечисленными средствами",
          "Предохранительным поясом или страховочной привязью с сигнально-спасательной веревкой",
          "Защитными очками и защитной каской",
          "Воздушными изолирующими аппаратами",
        ],
        correctAnswers: [
          "Предохранительным поясом или страховочной привязью с сигнально-спасательной веревкой",
        ],
      },
      {
        code: "20111015",
        text: "На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?",
        answers: [
          "На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 2 настоящих Правил",
          "На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности",
          "На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы",
          "На ведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на территории находящихся в эксплуатации опасных производственных объектов",
        ],
        correctAnswers: [
          "На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 2 настоящих Правил",
        ],
      },
      {
        code: "20111016",
        text: "Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года",
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
          "Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ",
          "С записью в вахтовом журнале",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
      {
        code: "20111017",
        text: "Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?",
        answers: [
          "Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск, на требуемый для окончания работ срок",
          "Руководитель структурного подразделения не более чем на 1 рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск, не более чем на 1 дневную смену",
        ],
        correctAnswers: [
          "Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
        ],
      },
      {
        code: "20111018",
        text: "В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?",
        answers: [
          "Не менее 3 месяцев со дня закрытия наряда-допуска",
          "Не менее 1 года со дня закрытия наряда-допуска",
          "Не менее 6 месяцев со дня закрытия наряда-допуска",
        ],
        correctAnswers: ["Не менее 6 месяцев со дня закрытия наряда-допуска"],
      },
      {
        code: "20111019",
        text: "На сколько групп подразделяются газоопасные работы в зависимости от степени опасности и на основании каких критериев устанавливается та или иная группа?",
        answers: [
          "На 2 группы, в зависимости от того, проводятся работы в закрытом или открытом пространстве",
          "На 3 группы, в зависимости от степени риска проводимых работ",
          "На 2 группы, в зависимости от того, проводятся газоопасные работы с оформлением наряда-допуска или без оформления",
        ],
        correctAnswers: [
          "На 2 группы, в зависимости от того, проводятся газоопасные работы с оформлением наряда-допуска или без оформления",
        ],
      },
      {
        code: "20111020",
        text: "Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?",
        answers: [
          "Все перечисленные требования",
          "Должны быть не моложе 18 лет",
          "Должны пройти медицинский осмотр в соответствии с требованиями законодательства Российской Федерации",
          "Должны пройти обучение приемам и методам проведения работ",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20111021",
        text: "К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?",
        answers: [
          "К I группе",
          "К II группе",
          "К группе работ средней степени опасности",
          "К группе работ высокой степени опасности",
        ],
        correctAnswers: ["К II группе"],
      },
      {
        code: "20111022",
        text: "Что должен сделать руководитель структурного подразделения, на объекте которого будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?",
        answers: [
          "Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ",
          "Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы",
          "Провести обучение и инструктаж персонала, который будет проводить газоопасные работы",
          "Определить структурные подразделения организации, с которыми будет взаимодействовать бригада исполнителей при проведении газоопасных работ",
        ],
        correctAnswers: [
          "Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы",
        ],
      },
      {
        code: "20111023",
        text: "Кому лицо, ответственное за проведение газоопасных работ, передает наряд-допуск после его закрытия?",
        answers: [
          "Представителю аварийно-спасательной службы организации",
          "Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)",
          "Главному инженеру, руководителю службы производственного контроля, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)",
          "Представителю службы промышленной безопасности",
        ],
        correctAnswers: [
          "Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)",
        ],
      },
      {
        code: "20111024",
        text: "Какие из обязательных мер безопасного ведения газоопасных работ, предусмотренных правилами, указаны неверно?",
        answers: [
          "Обеспечение контроля за состоянием воздушной среды",
          "Выполнение работ бригадой исполнителей не менее трех человек",
          "Обеспечение членов бригады СИЗ, спецодеждой, инструментом",
          "Исключение возможности присутствия на месте проведения газоопасной работы лиц, не занятых ее выполнением",
        ],
        correctAnswers: [
          "Выполнение работ бригадой исполнителей не менее трех человек",
        ],
      },
      {
        code: "20111025",
        text: "Какие плакаты вывешиваются на пусковых устройствах у аппаратов и в электрораспределительных устройствах при производстве газоопасных работ?",
        answers: [
          '"Высокое напряжение. Опасно для жизни!"',
          '"Не включать: не в фазе!"',
          '"Не включать: работа на линии!"',
          '"Не включать: работают люди!"',
        ],
        correctAnswers: ['"Не включать: работают люди!"'],
      },
      {
        code: "20111026",
        text: "Что должен сделать руководитель структурного подразделения, где будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?",
        answers: [
          "Определить структурные подразделения организации, с которыми будет взаимодействовать бригада исполнителей при проведении газоопасных работ",
          "Провести обучение и инструктаж персонала, который будет проводить газоопасные работы",
          "Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы",
          "Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ",
        ],
        correctAnswers: [
          "Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы",
        ],
      },
      {
        code: "20111027",
        text: "Какое количество наблюдающих должно быть, если существует необходимость выполнения газоопасных работ в емкости (аппарате) двумя работающими?",
        answers: ["Не менее двух", "Не менее четырех", "Один"],
        correctAnswers: ["Не менее двух"],
      },
      {
        code: "20111028",
        text: "Что из перечисленного должно быть приложено к наряду-допуску на проведение газоопасных работ при проведении работ в емкостях, а также работ, связанных с разгерметизацией технологического оборудования и трубопроводов, коммуникаций?",
        answers: [
          "Копии деклараций о соответствии или сертификаты соответствия применяемых при проведении газоопасных работ машин и механизмов",
          "Технологические планировки подразделения и паспорта технических устройств, применяемых на опасных производственных объектах",
          "Руководства по эксплуатации технологического оборудования и их ксерокопии",
          "Схемы(а) расположения запорной арматуры, освобождения от продукта, промывки, продувки, пропарки и мест установки заглушек, подписанные руководителем структурного подразделения или лицом, его замещающим",
        ],
        correctAnswers: [
          "Схемы(а) расположения запорной арматуры, освобождения от продукта, промывки, продувки, пропарки и мест установки заглушек, подписанные руководителем структурного подразделения или лицом, его замещающим",
        ],
      },
      {
        code: "20111029",
        text: "Что из перечисленного допускается при проведении газоопасных работ I группы?",
        answers: [
          "Совмещение газоопасных работ и огневых работ в одном помещении в случае возможного выделения в зону работ пожаровзрывоопасных веществ",
          "Совмещение газоопасных работ и огневых работ в непосредственной близости на открытой площадке в случае возможного выделения в зону работ пожаровзрывоопасных веществ",
          "Увеличение объема и характера работ, предусмотренных нарядом-допуском на проведение газоопасных работ",
          "Выполнение газоопасных работ бригадой исполнителей в составе двух человек",
        ],
        correctAnswers: [
          "Выполнение газоопасных работ бригадой исполнителей в составе двух человек",
        ],
      },
      {
        code: "20111030",
        text: "С кем согласовывается наряд-допуск на проведение газоопасных работ?",
        answers: [
          "С руководителем эксплуатирующей организации или его уполномоченным заместителем либо руководителем филиала (дочернего общества)",
          "С газоспасательной службой и службой охраны труда",
          "С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание",
          "С техническим директором",
        ],
        correctAnswers: [
          "С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание",
        ],
      },
      {
        code: "20111031",
        text: "Каким образом должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
          "Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ",
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года",
          "По устному распоряжению руководителя подразделения",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
      {
        code: "20111032",
        text: "Какой минимальный срок хранения установлен для журнала регистрации нарядов-допусков на проведение газоопасных работ?",
        answers: [
          "3 года со дня его окончания",
          "1 год со дня его окончания",
          "6 месяцев со дня его окончания",
          "3 месяца со дня его окончания",
        ],
        correctAnswers: ["6 месяцев со дня его окончания"],
      },
      {
        code: "20111033",
        text: "Какие из перечисленных требований безопасности предъявляются при работах внутри емкости?",
        answers: [
          "Если в действиях работающего внутри емкости наблюдаются отклонения от обычного поведения (признаки недомогания, попытка снять маску противогаза), к нему следует спустить медицинского работника для оказания первой помощи",
          "При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует прекратить, а работника из емкости эвакуировать",
          "Запрещается подача материалов в емкость во время проведения газоопасных работ",
          "Допускается подача необходимых инструментов и материалов в емкость любым удобным для работников способом",
        ],
        correctAnswers: [
          "При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует прекратить, а работника из емкости эвакуировать",
        ],
      },
      {
        code: "20111034",
        text: "С какой периодичностью необходимо пересматривать и переутверждать перечень газоопасных работ?",
        answers: [
          "Не реже одного раза в полгода",
          "Не реже одного раза в год",
          "Не реже одного раза в 2 года",
          "Не реже одного раза в 5 лет",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20111035",
        text: "Кто осуществляет подготовку объекта к проведению на нем газоопасной работы?",
        answers: [
          "Работники, список которых определяется внутренними документами организации",
          "Работники газоспасательной службы",
          "Работники, осуществляющие эксплуатацию объекта, совместно с работниками аварийно-спасательных подразделений",
          "Работники, осуществляющие эксплуатацию объекта",
          "Работники подрядной организации",
        ],
        correctAnswers: ["Работники, осуществляющие эксплуатацию объекта"],
      },
      {
        code: "20111036",
        text: "Что из перечисленного не входит в обязанности лица, ответственного за проведение газоопасных работ, по окончании работ внутри емкости (аппарата)?",
        answers: [
          "Произвести запись в журнале ведения технологического процесса (в вахтенном журнале, журнале приема-сдачи смен) и наряде-допуске на проведение газоопасных работ",
          "Поставить в известность работников, занятых ведением технологического процесса, об окончании газоопасных работ",
          "Произвести запись в наряде-допуске на проведение газоопасных работ об окончании газоопасных работ",
          "Проверить состояние фильтрующих противогазов у каждого работающего внутри емкости (аппарата)",
        ],
        correctAnswers: [
          "Проверить состояние фильтрующих противогазов у каждого работающего внутри емкости (аппарата)",
        ],
      },
      {
        code: "20111037",
        text: "Кто определяет структурные подразделения, на которые возложены полномочия по согласованию перечня газоопасных работ?",
        answers: [
          "Технический директор эксплуатирующей организации",
          "Руководитель эксплуатирующей организации совместно с руководителем аварийно-спасательной службы",
          "Руководитель службы производственного контроля или лицо, ответственное за осуществление производственного контроля",
          "Руководитель эксплуатирующей организации",
        ],
        correctAnswers: ["Руководитель эксплуатирующей организации"],
      },
      {
        code: "20111038",
        text: "До какой максимальной температуры должны быть охлаждены нагретые емкости перед допуском внутрь них людей?",
        answers: ["30 °C", "20 °C", "25 °C", "35 °C"],
        correctAnswers: ["30 °C"],
      },
      {
        code: "20111039",
        text: "Когда могут проводиться газоопасные работы, выполняемые по наряду-допуску?",
        answers: [
          "Во время грозы",
          "Только в темное время суток с соблюдением мероприятий по обеспечению безопасного проведения работ, учитывающих условия их выполнения в темное время суток",
          "В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа с уведомлением лиц, согласовавших наряд-допуск",
          "В дневное или в темное время суток с участием или в присутствии лица, ответственного за выполнение газоопасных работ",
        ],
        correctAnswers: [
          "В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа с уведомлением лиц, согласовавших наряд-допуск",
        ],
      },
      {
        code: "20111040",
        text: "Что допускается при оформлении наряда-допуска на проведение газоопасных работ?",
        answers: [
          "Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера",
          "Исправления в тексте наряда-допуска на проведение газоопасных работ",
          "Заполнение наряда-допуска на проведение газоопасных работ карандашом",
          "Подписи ответственных лиц с использованием факсимиле и их ксерокопии",
          "Заполнение наряда-допуска на проведение газоопасных работ под копирку",
        ],
        correctAnswers: [
          "Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера",
        ],
      },
      {
        code: "20111041",
        text: "Кем утверждается перечень газоопасных работ, проводимых на опасных производственных объектах?",
        answers: [
          "Руководителем эксплуатирующей организации совместно с руководителем аварийно-спасательной службы",
          "Руководителем службы производственного контроля или лицом, ответственным за осуществление производственного контроля",
          "Руководителем эксплуатирующей организации",
          "Главным инженером эксплуатирующей организации",
          "Техническим директором эксплуатирующей организации",
        ],
        correctAnswers: ["Руководителем эксплуатирующей организации"],
      },
      {
        code: "20111042",
        text: "Куда (кому) лицо, ответственное за проведение газоопасных работ, передает наряд-допуск после его закрытия?",
        answers: [
          "Главному инженеру, руководителю службы производственного контроля, а также в ГСС",
          "В ГСС и аварийно-спасательную службу организации",
          "Руководителю структурного подразделения или его заместителю, а также в в ПАСС(Ф)",
          "В службу охраны труда",
        ],
        correctAnswers: [
          "Руководителю структурного подразделения или его заместителю, а также в в ПАСС(Ф)",
        ],
      },
      {
        code: "20111043",
        text: "С кем необходимо согласовывать проведение работ в коллекторах, тоннелях, колодцах, приямках, траншеях и аналогичных сооружениях?",
        answers: [
          "С руководителями аварийно-спасательных служб",
          "С руководителями службы производственного контроля",
          "С руководителями службы охраны труда и санитарных служб",
          "С руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы",
        ],
        correctAnswers: [
          "С руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы",
        ],
      },
      {
        code: "20111044",
        text: "Что из перечисленного следует выполнить для проведения огневых работ внутри емкости (аппарата) при проведении газоопасных работ?",
        answers: [
          "Следует получить письменное разрешение лица, утвердившего наряд-допуск на проведение газоопасных работ, приложив его к наряду-допуску на проведение газоопасных работ",
          "Следует к наряду-допуску на проведение газоопасных работ приложить перечень мест выполнения огневых работ",
          "Следует оформить наряд-допуск на выполнение огневых работ, закрыв при этом наряд-допуск на проведение газоопасных работ",
          "Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)",
        ],
        correctAnswers: [
          "Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)",
        ],
      },
      {
        code: "20111045",
        text: "Какими документами определяется перечень постоянных мест проведения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?",
        answers: [
          "Правилами пожарной безопасности",
          "Технологическим регламентом",
          "Федеральными нормами и правилами в области промышленной безопасности",
          "Организационно-распорядительными документами руководителя",
        ],
        correctAnswers: [
          "Организационно-распорядительными документами руководителя",
        ],
      },
      {
        code: "20111046",
        text: "Какие требования установлены к люкам колодцев канализации, расположенным в зоне проведения огневых работ?",
        answers: [
          "Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце",
          "Крышки колодцев должны быть плотно закрыты, огорожены и отмечены хорошо видимыми опознавательными знаками",
          "Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 2 см в стальном или железобетонном кольце",
        ],
        correctAnswers: [
          "Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце",
        ],
      },
      {
        code: "20111047",
        text: "В соответствии с каким документом устанавливается контроль за состоянием воздушной среды?",
        answers: [
          "В соответствии с нарядом-допуском на выполнение огневых работ",
          "В соответствии с Правилами пожарной безопасности",
          "В соответствии с технологическим регламентом",
        ],
        correctAnswers: [
          "В соответствии с нарядом-допуском на выполнение огневых работ",
        ],
      },
      {
        code: "20111048",
        text: "Кто осуществляет подготовку объекта к проведению на нем огневых работ?",
        answers: [
          "Правилами не регламентируется",
          "Работники подрядной организации",
          "Работники, осуществляющие эксплуатацию объекта",
          "Работники, указанные в наряде-допуске",
        ],
        correctAnswers: ["Работники, осуществляющие эксплуатацию объекта"],
      },
      {
        code: "20111049",
        text: "При какой концентрации пожаровзрывоопасных веществ не допускается проведение огневых работ?",
        answers: [
          "При наличии пожаровзрывоопасных веществ выше 15 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения",
          "При наличии пожаровзрывоопасных веществ выше 20 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения",
          "При наличии пожаровзрывоопасных веществ выше 25 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения.",
        ],
        correctAnswers: [
          "При наличии пожаровзрывоопасных веществ выше 20 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения",
        ],
      },
      {
        code: "20111050",
        text: "Какие из перечисленных действий, производимых при подготовке объекта к огневым работам, указаны неверно?",
        answers: [
          "Аппараты, машины и другое оборудование, на которых будут проводиться огневые работы, должны быть остановлены, освобождены от опасных веществ",
          "Электроприводы движущихся механизмов, которые находятся в зоне выполнения огневых работ, должны быть отключены от источников питания, отсоединены от этих механизмов видимым разрывом",
          "Аппараты, машины и другое оборудование, только находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ",
          'На пусковых устройствах должны быть вывешены плакаты "Не включать: работают люди!"',
        ],
        correctAnswers: [
          "Аппараты, машины и другое оборудование, только находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ",
        ],
      },
      {
        code: "20111051",
        text: "Какое из перечисленных требований при назначении специалиста, ответственного за проведение огневых работ, указано неверно?",
        answers: [
          "Назначение организационно-распорядительными документами руководителя эксплуатирующей организации (филиала организации) или лица, его замещающего, ответственного за обеспечение пожарной безопасности",
          "Прохождение подготовки и аттестации по промышленной безопасности",
          "Прохождение обучения по пожарно-техническому минимуму в объеме знаний требований Правил противопожарного режима в Российской Федерации",
        ],
        correctAnswers: [
          "Прохождение подготовки и аттестации по промышленной безопасности",
        ],
      },
      {
        code: "20111052",
        text: "Кто определяет структурные подразделения, на которые возлагается согласование наряда-допуска на выполнение огневых работ?",
        answers: [
          "Руководитель структурного подразделения совместно с руководителем службы организации, на которую возложены функции обеспечения мер пожарной безопасности",
          "Руководитель структурного подразделения или лицо, его замещающее",
          "Руководитель эксплуатирующей организации",
          "Технический директор организации",
        ],
        correctAnswers: ["Руководитель эксплуатирующей организации"],
      },
      {
        code: "20111053",
        text: "Какое из перечисленных требований при назначении специалиста ответственным за выполнение огневых работ указано неверно?",
        answers: [
          "Прохождение обучения по пожарно-техническому минимуму в объеме знаний требований Правил противопожарного режима в Российской Федерации",
          "Прохождение подготовки и аттестации по промышленной безопасности",
          "Назначение организационно-распорядительными документами руководителя эксплуатирующей организации (филиала организации) или лица, его замещающего, ответственного за обеспечение пожарной безопасности",
        ],
        correctAnswers: [
          "Прохождение подготовки и аттестации по промышленной безопасности",
        ],
      },
      {
        code: "20111054",
        text: "В соответствии с каким документом устанавливается периодичность контроля за состоянием воздушной среды?",
        answers: [
          "В соответствии с требованиями, установленными в Правилах пожарной безопасности",
          "В соответствии с нарядом-допуском на выполнение огневых работ",
          "В соответствии с технологическим регламентом",
          "В соответствии с графиком",
        ],
        correctAnswers: [
          "В соответствии с нарядом-допуском на выполнение огневых работ",
        ],
      },
      {
        code: "20111055",
        text: "Какие из перечисленных требований к перечню постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты, указаны верно?",
        answers: [
          "Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество первичных средств пожаротушения, ответственные за противопожарное состояние указанных мест и безопасное проведение огневых работ лица",
          "Указываются места выполнения огневых работ, виды, количество средств индивидуальной защиты пожаротушения, лица, ответственные за производственный контроль",
          "Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество средств индивидуальной защиты, лица, ответственные за противопожарное состояние организации",
        ],
        correctAnswers: [
          "Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество первичных средств пожаротушения, ответственные за противопожарное состояние указанных мест и безопасное проведение огневых работ лица",
        ],
      },
      {
        code: "20111056",
        text: "В течение какого времени наряд-допуск на проведение огневых работ действителен?",
        answers: [
          "До окончания работ на конкретном месте",
          "В течение одной дневной смены",
          "По решению руководителя структурного подразделения, на объекте которого выполняются огневые работы",
          "В течении одной рабочей недели",
        ],
        correctAnswers: ["В течение одной дневной смены"],
      },
      {
        code: "20111057",
        text: "Какие требования должны выполняться при проведении земляных работ в ремонтной зоне?",
        answers: [
          "Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации",
          "Эксплуатирующая организация должна согласовать с подрядной организацией расстановку знаков, обозначающих границы земляных работ",
          "Подрядная организация согласовывает наряд-допуск на производство земляных работ со структурными подразделениями эксплуатирующей организации, на которые возложено согласование наряда-допуска на производство земляных работ внутренними документами эксплуатирующей организацией",
          "Эксплуатирующая организация должна согласовать с подрядной организацией расстановку техники",
        ],
        correctAnswers: [
          "Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации",
        ],
      },
      {
        code: "20111058",
        text: "Каким образом фиксируется прохождение инструктажа исполнителями ремонтных работ?",
        answers: [
          "Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске",
          "Руководитель службы производственного контроля делает записи в журнале проведения инструктажа и отметку в наряде-допуске",
          "Руководитель структурного подразделения ремонтируемого объекта делает отметку в журнале проведения инструктажа и информирует об этом руководителя бригады, которая будет выполнять ремонтные работы",
          "Исполнители ремонтных работ расписываются в вахтовом журнале ремонтируемого объекта",
        ],
        correctAnswers: [
          "Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске",
        ],
      },
      {
        code: "20111059",
        text: "Какие из перечисленных документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ, указаны неверно?",
        answers: [
          "Инструкции по эксплуатации и безопасному проведению ремонтных работ",
          "Требования к проведению огневых и газоопасных работ",
          "Проект производства ремонтных работ",
          "Технологический регламент",
        ],
        correctAnswers: ["Проект производства ремонтных работ"],
      },
      {
        code: "20111060",
        text: "Кем из перечисленных лиц может осуществляться общая координация ремонтных работ на объекте, где ремонтные работы производятся несколькими подрядными организациями и заказчиком?",
        answers: [
          "Любым из перечисленных лиц",
          "Лицом, назначенным руководителем эксплуатирующей организации (филиала организации)",
          "Лицом, назначенным уполномоченным заместителем руководителя эксплуатирующей организации (филиала организации)",
        ],
        correctAnswers: ["Любым из перечисленных лиц"],
      },
      {
        code: "20111061",
        text: "Какие сведения не указываются в организационно-распорядительном документе для остановки на ремонт объекта или оборудования?",
        answers: [
          "Непосредственный руководитель работ и лица, ответственные за подготовку к ремонтным работам",
          "Сроки остановки, подготовки, ремонта и пуска объекта или оборудования",
          "Наличие плана мероприятий по локализации аварий и ликвидации их последствий",
        ],
        correctAnswers: [
          "Наличие плана мероприятий по локализации аварий и ликвидации их последствий",
        ],
      },
      {
        code: "20111062",
        text: "Кто производит подключение к электросетям передвижных электроприемников подрядной организации и их отключение при проведении ремонтных работ?",
        answers: [
          "Электротехнический персонал подрядной организации в присутствии лица, ответственного за проведение ремонтных работ",
          "Персонал, назначенный руководителем эксплуатирующей организации и определенный в наряде-допуске",
          "Электротехнический персонал эксплуатирующей организации",
          "Электротехнологический персонал эксплуатирующей организации",
        ],
        correctAnswers: [
          "Электротехнический персонал эксплуатирующей организации",
        ],
      },
      {
        code: "20111063",
        text: "Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в электронном виде?",
        answers: [
          "Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи",
          "Не допускается",
          "Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия",
          "Допускается по решению руководителя эксплуатирующей организации",
        ],
        correctAnswers: [
          "Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия",
        ],
      },
      {
        code: "20111064",
        text: "Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в виде электронного документа?",
        answers: [
          "Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия",
          "Допускается по решению руководителя эксплуатирующей организации (филиала организации)",
          "Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи",
          "Не допускается",
        ],
        correctAnswers: [
          "Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия",
        ],
      },
      {
        code: "20111065",
        text: "Что должна выполнить подрядная организация до начала проведения ремонтных работ?",
        answers: [
          "Составить план подготовительных работ",
          "Организовать изготовление необходимых узлов и деталей для замены",
          "Разработать проект производства работ",
          "Приобрести необходимое оборудование, арматуру, запасные части, трубы, материалы согласно дефектной ведомости",
          "Провести медосмотр сотрудников",
        ],
        correctAnswers: ["Разработать проект производства работ"],
      },
    ],

    20112: [
      {
        code: "20112000",
        text: "Какие меры по обеспечению взрывобезопасности предусматриваются проектной организацией для каждого технологического блока с учетом его энергетического потенциала? Выберите 2 правильных варианта ответа.",
        answers: [
          "Уменьшение ущерба от возможной аварии на эксплуатируемом взрывопожароопасном объекте",
          "Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества",
          "Повышение квалификации обслуживающего персонала на курсах переподготовки и повышения квалификации",
          "Предупреждение взрывов и предотвращение травмирования производственного персонала",
        ],
        correctAnswers: [
          "Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества",
          "Предупреждение взрывов и предотвращение травмирования производственного персонала",
        ],
      },
      {
        code: "20112001",
        text: "На какой стадии предусматриваются средства контроля и регулирования уровня разделения фаз горючих паров (газов) и жидкостей?",
        answers: [
          "На стадии строительства производственного объекта",
          "На стадии ликвидации производственного объекта",
          "На стадии эксплуатации производственного объекта",
          "На стадии проектирования процесса",
        ],
        correctAnswers: ["На стадии проектирования процесса"],
      },
      {
        code: "20112002",
        text: "Где допускается размещение фланцевых соединений на трубопроводах с пожаровзрывоопасными, токсичными и едкими веществами?",
        answers: [
          "В местах ввода трубопродов в технологические здания и сооружения",
          "В местах установки арматуры или подсоединения трубопроводов к аппаратам",
          "Над местами, предназначенными для прохода людей",
          "Над рабочими площадками",
        ],
        correctAnswers: [
          "В местах установки арматуры или подсоединения трубопроводов к аппаратам",
        ],
      },
      {
        code: "20112003",
        text: "Что является критерием взрывоопасности согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?",
        answers: [
          "Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом",
          "Температура самовоспламенения паров обращающихся в рабочем процессе горючих и взрывоопасных веществ",
          "Класс опасности обращающихся в рабочем процессе горючих и взрывоопасных веществ",
          "Скорость распространения горения обращающихся в рабочем процессе горючих и взрывоопасных веществ",
        ],
        correctAnswers: [
          "Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом",
        ],
      },
      {
        code: "20112004",
        text: "Какой параметр является критерием установления категории взрывоопасности технологических блоков согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?",
        answers: [
          "Температура самовоспламенения паров обращающихся в рабочем процессе горючих и взрывоопасных веществ",
          "Скорость распространения горения обращающихся в рабочем процессе горючих и взрывоопасных веществ",
          "Класс опасности обращающихся в рабочем процессе горючих и взрывоопасных веществ",
          "Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему",
        ],
        correctAnswers: [
          "Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему",
        ],
      },
      {
        code: "20112005",
        text: "Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и др., нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?",
        answers: [
          "Разработчиком процесса",
          "Заказчиком",
          "Разработчиками рабочего процесса и проекта стадии П",
          "Разработчиком проекта стадии Р",
        ],
        correctAnswers: ["Разработчиком процесса"],
      },
      {
        code: "20112006",
        text: "На каких трубопроводах следует применять арматуру под приварку для повышения надежности и плотности соединений?",
        answers: [
          "На трубопроводах технологических блоков I категории взрывоопасности с давлением среды менее 2,5 МПа",
          "На трубопроводах технологических блоков II категории взрывоопасности с температурой, равной температуре кипения среды при регламентированном давлении",
          "На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды при регламентированном давлении",
          "На трубопроводах технологических блоков III категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды при регламентированном давлении",
        ],
        correctAnswers: [
          "На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды при регламентированном давлении",
        ],
      },
      {
        code: "20112007",
        text: "В соответствии с чем следует производить монтаж технологического оборудования и трубопроводов?",
        answers: [
          "В соответствии со всем перечисленным",
          "Только в соответствии с рабочей документацией",
          "Только в соответствии с проектной документацией",
        ],
        correctAnswers: ["В соответствии со всем перечисленным"],
      },
      {
        code: "20112008",
        text: "На сколько категорий взрывоопасности подразделяются при проектировании технологические блоки взрывопожароопасных производств и объектов?",
        answers: [
          "На четыре категории",
          "На две категории",
          "На три категории",
          "На пять категорий",
        ],
        correctAnswers: ["На три категории"],
      },
      {
        code: "20112009",
        text: "С учетом каких критериев выбираются насосы и компрессоры, используемые для перемещения газов, легковоспламеняющихся и горючих жидкостей, согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств? Выберите 2 правильных варианта ответа.",
        answers: [
          "С учетом протяженности и способа прокладки трубопроводов байпаса",
          "С учетом физико-химических свойств перемещаемых продуктов",
          "С учетом материалов, диаметра и толщины стенки трубопроводов",
          "С учетом регламентированных параметров технологического процесса",
        ],
        correctAnswers: [
          "С учетом физико-химических свойств перемещаемых продуктов",
          "С учетом регламентированных параметров технологического процесса",
        ],
      },
      {
        code: "20112010",
        text: "Чем должно оснащаться оборудование для измельчения и смешивания измельченных твердых горючих продуктов для обеспечения эксплуатационной безопасности в отношении риска взрыва?",
        answers: [
          "Средствами контроля за максимальной температурой измельченных твердых горючих продуктов",
          "Средствами контроля и измерения за фракционным составом измельченных твердых горючих продуктов",
          "Сигнализацией об отклонении давления подаваемого инертного газа от регламентированных значений",
          "Автоматическими блокировками, не допускающими пуск в работу оборудования без предварительной продувки инертным газом в течение 5 минут",
        ],
        correctAnswers: [
          "Сигнализацией об отклонении давления подаваемого инертного газа от регламентированных значений",
        ],
      },
      {
        code: "20112011",
        text: "Кем определяется степень разделения материальных сред и меры обеспечения взрывобезопасности на всех стадиях процесса?",
        answers: [
          "Разработчиком процесса",
          "Разработчиком проекта стадии П",
          "Степень разделения определяется заказчиком в задании на проектирование, а меры взрывобезопасности - разработчиком проекта",
          "Разработчиком процесса и проекта стадии Р",
        ],
        correctAnswers: ["Разработчиком процесса"],
      },
      {
        code: "20112012",
        text: "Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?",
        answers: [
          "Оборудование необходимо изолировать от действующей системы, а нанесенная на нем нумерация согласно технологической схеме - закрашено",
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем",
          "Оборудование необходимо демонтировать, если оно находится в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке его необходимо изолировать от действующих систем",
        ],
        correctAnswers: [
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем",
        ],
      },
      {
        code: "20112013",
        text: "Какие линии электроснабжения от внешних источников независимо от класса напряжения не должны оборудоваться устройствами автоматической частной разгрузки?",
        answers: [
          "Питающие потребителей любых категорий надежности электроснабжения",
          "Питающие потребителей II категории надежности электроснабжения",
          "Питающие потребителей особой группы I категории надежности электроснабжения",
          "Питающие потребителей III категории надежности электроснабжения",
        ],
        correctAnswers: [
          "Питающие потребителей особой группы I категории надежности электроснабжения",
        ],
      },
      {
        code: "20112014",
        text: "В массообменных процессах при отклонении технологических параметров от регламентированных значений возможно образование неустойчивых взрывоопасных соединений. Как в таком случае должно осуществляться регулирование этих параметров?",
        answers: [
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматически, а при Qв ≤ 10 допускается ручное дистанционное управление",
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматически, для установок III категории взрывоопасности допускается управление вручную при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений",
          "Для установок с технологическими блоками I категории взрывоопасности - автоматически, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту",
          "Для установок с технологическими блоками I категории взрывоопасности - автоматически, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту",
        ],
        correctAnswers: [
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматически, для установок III категории взрывоопасности допускается управление вручную при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений",
        ],
      },
      {
        code: "20112015",
        text: "На основании каких данных определяются допустимые значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывопожароопасных характеристик, физико-химических свойств?",
        answers: [
          "На основании общедоступных данных на проектирование из открытых литературных источников",
          "На основании задания на проектирование",
          "На основании расчетных данных на проектирование",
          "На основании справочных данных на проектирование из специальных источников",
        ],
        correctAnswers: ["На основании задания на проектирование"],
      },
      {
        code: "20112016",
        text: "Какие сведения являются основополагающими при выборе технологического оборудования для обеспечения технологических процессов?",
        answers: [
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
          "Расчетные данные для выбора параметров оборудования и показатели надежности",
          "Требования действующих нормативных документов и расчетные данные для выбора параметров оборудования",
          "Расчетные данные, которым должны соответствовать параметры оборудования, и требования действующих нормативных документов",
        ],
        correctAnswers: [
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
      },
      {
        code: "20112017",
        text: "Что применяется при непрерывных процессах смешивания веществ, взаимодействие которых может привести к развитию экзотермических реакций, для исключения их неуправляемого течения? Выберите 2 правильных варианта ответа.",
        answers: [
          "Приостановка реакции путем увеличения подачи одного из смешиваемых веществ, участвующих в данном процессе",
          "Определение безопасных объемных скоростей дозирования смешиваемых веществ",
          "Разделение веществ, участвующих в технологическом процессе, для создания возможности образования застойных зон",
          "Разработка методов отвода тепла",
        ],
        correctAnswers: [
          "Определение безопасных объемных скоростей дозирования смешиваемых веществ",
          "Разработка методов отвода тепла",
        ],
      },
      {
        code: "20112018",
        text: "Что относится к технологическим трубопроводам?",
        answers: [
          "Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования",
          "Трубопроводы для транспортирования веществ, необходимых для ведения технологического процесса или эксплуатации оборудования",
          "Трубопроводы для перемещения в пределах промышленного предприятия сырья, вспомогательных материалов, в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса",
        ],
        correctAnswers: [
          "Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования",
        ],
      },
      {
        code: "20112019",
        text: "Чем должны быть оборудованы аппараты со взрывопожароопасными веществами?",
        answers: [
          "Устройствами для подключения линий воды, пара, инертного газа",
          "Циклонами",
          "Виброситом",
          "Устройствами для подключения линий водорода и кислорода",
        ],
        correctAnswers: [
          "Устройствами для подключения линий воды, пара, инертного газа",
        ],
      },
      {
        code: "20112020",
        text: "Какой категории взрывоопасности технологических блоков не существует?",
        answers: [
          "IV категории",
          "III категории",
          "II категории",
          "I категории",
        ],
        correctAnswers: ["IV категории"],
      },
      {
        code: "20112021",
        text: "Какие требования необходимо выполнять при прокладке кабелей по территории технологических установок? Укажите все правильные ответы.",
        answers: [
          "Прокладку кабелей разрешается выполнять открыто: по эстакадам, в галереях и на кабельных конструкциях технологических эстакад",
          "Провода и кабели в полиэтиленовой оболочке разрешается прокладывать только на верхних ярусах технологических эстакад",
          "Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов",
          "Прокладка кабелей в каналах, засыпанных песком, и траншеях запрещается",
        ],
        correctAnswers: [
          "Прокладку кабелей разрешается выполнять открыто: по эстакадам, в галереях и на кабельных конструкциях технологических эстакад",
          "Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов",
        ],
      },
      {
        code: "20112022",
        text: "Как требуется размещать технологическое оборудование взрывопожароопасных производств?",
        answers: [
          "Только с учетом обеспечения безопасности обслуживания и эксплуатации технологического оборудования",
          "С учетом всех перечисленных требований",
          "Только с учетом возможности проведения ремонтных работ и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий",
          "Только с учетом возможности проведения визуального контроля за состоянием технологического оборудования, выполнения работ по обслуживанию, ремонту и замене",
        ],
        correctAnswers: ["С учетом всех перечисленных требований"],
      },
      {
        code: "20112023",
        text: "В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?",
        answers: [
          "При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и в расходной емкости и предельно допустимому верхнему уровню в приемной емкости",
          "В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования",
          "При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях",
          "При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости",
        ],
        correctAnswers: [
          "В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования",
        ],
      },
      {
        code: "20112024",
        text: "Какое из перечисленных положений нарушает требования, предъявляемые к прокладке трубопроводов на объектах нефтегазодобычи?",
        answers: [
          "При прокладке трубопроводов через строительные конструкции зданий и другие препятствия принимаются меры, исключающие возможность передачи дополнительных нагрузок на трубы",
          "Трубопроводы не должны иметь фланцевых или других разъемных соединений",
          "Материал фланцев, конструкция уплотнения применяются в соответствии с нормативными техническими документами, устанавливающими требования к технологическим трубопроводам с учетом условий эксплуатации",
          "Прокладка трубопроводов должна обеспечивать максимально возможную протяженность коммуникаций, исключать провисания и образование застойных зон",
        ],
        correctAnswers: [
          "Прокладка трубопроводов должна обеспечивать максимально возможную протяженность коммуникаций, исключать провисания и образование застойных зон",
        ],
      },
      {
        code: "20112025",
        text: "В соответствии с чем осуществляется ведение технологических процессов на опасном производственном объекте химической, нефтехимической промышленности?",
        answers: [
          "В соответствии с технологическими регламентами на производство продукции",
          "В соответствии с заключением экспертизы промышленной безопасности",
          "В соответствии с рекомендациями территориального управления Ростехнадзора",
          "В соответствии с распоряжениями руководителя местного муниципального органа",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами на производство продукции",
        ],
      },
      {
        code: "20112026",
        text: "Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?",
        answers: [
          "Приведенной массой вещества, участвующего во взрыве",
          "Радиусом зон разрушения",
          "Категорией взрывоопасности технологических блоков",
          "Энергией сгорания парогазовой фазы",
        ],
        correctAnswers: ["Категорией взрывоопасности технологических блоков"],
      },
      {
        code: "20112027",
        text: "В каких местах не допускается размещать фланцевые соединения трубопроводов с пожаровзрывоопасными, токсичными и едкими веществами?",
        answers: [
          "На трубопроводах, идущих по стенам зданий",
          "На трубопроводах, проложенных по эстакадам",
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
          "В местах, открытых и доступных для визуального наблюдения",
        ],
        correctAnswers: [
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
        ],
      },
      {
        code: "20112028",
        text: "Каким образом обеспечивается надежность обеспечения средств управления и системы противоаварийной защиты сжатым воздухом?",
        answers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
          "Переключением сети воздуха, предназначенной для контрольно-измерительных приборов и устройств автоматического регулирования, на заводскую сеть сжатого воздуха через осушитель",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа",
          "Установкой резервного компрессора с включением его автоматически при остановке рабочего компрессора",
        ],
        correctAnswers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20112029",
        text: "Чем должны оснащаться технологические системы для обеспечения безопасности ведения технологических процессов?",
        answers: [
          "Только средствами контроля за параметрами, определяющими взрывоопасность процесса",
          "Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ",
          "Только средствами противоаварийной защиты",
          "Только средствами автоматического регулирования",
        ],
        correctAnswers: [
          "Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ",
        ],
      },
      {
        code: "20112030",
        text: "Что устанавливается на линию подачи инертных газов (пар, азот, и другие среды) в процессах, при которых в результате отклонения от заданных технологических режимов возможно попадание взрывопожароопасных продуктов в нее?",
        answers: [
          "Гидроклапан",
          "Обратный клапан",
          "Предохранительный клапан",
          "Запорный клапан",
          "Редукционный клапан",
        ],
        correctAnswers: ["Обратный клапан"],
      },
      {
        code: "20112031",
        text: "Каким образом обеспечивается отработка персоналом практических навыков безопасного выполнения работ, предупреждения аварий и ликвидации их последствий на технологических объектах с блоками I и II категорий взрывоопасности?",
        answers: [
          "Посредством допуска персонала к самостоятельной работе не ранее чем через 6 месяцев после стажировки на объекте",
          "Посредством прохождения курса подготовки с использованием современных технических средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)",
          "Посредством изучения наглядных пособий (плакатов) по охране труда",
          "Посредством обучения производственного персонала в специализированных центрах обучения и подготовки",
        ],
        correctAnswers: [
          "Посредством прохождения курса подготовки с использованием современных технических средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)",
        ],
      },
      {
        code: "20112032",
        text: "Что не должна исключать энергетическая устойчивость технологической системы с учетом категории взрывоопасности входящих в нее блоков, особенностей технологического процесса?",
        answers: [
          "Возможность образования в системе взрывоопасной среды",
          "Возможность выбора рациональной схемы энергоснабжения, количества источников электропитания (основных и резервных), их надежность",
          "Возможность нарушения герметичности системы (разгерметизации уплотнений подвижных соединений, разрушения оборудования от превышения давления)",
        ],
        correctAnswers: [
          "Возможность выбора рациональной схемы энергоснабжения, количества источников электропитания (основных и резервных), их надежность",
        ],
      },
      {
        code: "20112033",
        text: "Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся и горючие жидкости на сливо-наливных эстакадах?",
        answers: [
          "Должно быть организовано управление по месту и дистанционно (из безопасного места)",
          "Должно быть организовано дистанционное управление",
          "Должно быть организовано управление по месту",
          "Должно быть организовано автоматизированное управление",
        ],
        correctAnswers: [
          "Должно быть организовано управление по месту и дистанционно (из безопасного места)",
        ],
      },
      {
        code: "20112034",
        text: "В соответствии с чем осуществляется ведение технологических процессов на опасных производственных объектах химических, нефтехимических и нефтегазоперерабатывающих производств?",
        answers: [
          "В соответствии с заключением экспертизы промышленной безопасности",
          "В соответствии с рекомендациями территориального управления Ростехнадзора",
          "В соответствии с технологическими регламентами на производство продукции",
          "В соответствии с распоряжениями руководителя местного муниципального органа",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами на производство продукции",
        ],
      },
      {
        code: "20112035",
        text: "Каким оборудованием оснащаются цистерны, предназначенные для перевозки по железным дорогам сжиженного углеводородного газа и легковоспламеняющихся жидкостей?",
        answers: [
          "Только средствами контроля уровня налива",
          "Арматурой, средствами контроля, сливоналивными, защитными и другими устройствами",
          "Только предохранительными клапанами и другой арматурой",
          "Только сливоналивными устройствами",
        ],
        correctAnswers: [
          "Арматурой, средствами контроля, сливоналивными, защитными и другими устройствами",
        ],
      },
      {
        code: "20112036",
        text: "Какие требования к эксплуатации поршневых компрессоров указаны неверно?",
        answers: [
          "Наличие нагара в клапанных коробках и трубопроводах поршневых компрессоров не допускается",
          "Необходимо контролировать работу маслоочищающих сальников. Попадание машинного масла в цилиндры не допускается",
          "Не допускается использовать для смазки поршневой группы компрессоров масло, извлеченное из масловлагоотделителей",
          "Допускается применять поршневые бескрейцкопфные компрессоры для подачи воздуха на разделение и для сжатия продуктов разделения воздуха",
          "Для смазки поршневой группы компрессоров, подающих воздух в воздухоразделительные установки, следует использовать масла, рекомендованные разработчиками компрессора",
        ],
        correctAnswers: [
          "Допускается применять поршневые бескрейцкопфные компрессоры для подачи воздуха на разделение и для сжатия продуктов разделения воздуха",
        ],
      },
      {
        code: "20112037",
        text: "Выполнение какого условия, относящегося к трубопроводам и компрессорам продуктов разделения воздуха, допускается?",
        answers: [
          "Возможность попадания кислорода во всасывающую линию при обкатке компрессоров",
          "Объединение дренажных трубопроводов с установкой приборов контроля",
          "Все перечисленные условия",
          "Установка дренажного устройства для слива сконденсированных паров масла в нижней точке трубопровода отвода масла",
        ],
        correctAnswers: [
          "Установка дренажного устройства для слива сконденсированных паров масла в нижней точке трубопровода отвода масла",
        ],
      },
      {
        code: "20112038",
        text: "Каким документом определяется время срабатывания запорных и (или) отсекающих устройств при аварийной разгерметизации системы?",
        answers: [
          "Паспортом технического устройства",
          "Инструкцией по эксплуатации",
          "Проектной документацией или документацией на техническое перевооружение",
          "Планом ликвидации аварий",
        ],
        correctAnswers: [
          "Проектной документацией или документацией на техническое перевооружение",
        ],
      },
      {
        code: "20112039",
        text: "Кто обосновывает достаточность аппаратурного резервирования и его тип?",
        answers: [
          "Разработчик проекта",
          "Организация - изготовитель систем противоаварийной автоматической защиты",
          "Разработчик технологического процесса",
          "Технический руководитель (главный инженер) эксплуатирующей организации",
        ],
        correctAnswers: ["Разработчик проекта"],
      },
      {
        code: "20112040",
        text: "Какая арматура устанавливается на трубопроводах для транспортирования взрывопожароопасных продуктов?",
        answers: [
          "Только с пластмассовым уплотнением в затворе",
          "Соответствующая проектной документации (документации на техническое перевооружение)",
          "Только с резиновым уплотнением в затворе",
          "Только с тканевым уплотнением в затворе",
        ],
        correctAnswers: [
          "Соответствующая проектной документации (документации на техническое перевооружение)",
        ],
      },
      {
        code: "20112041",
        text: "Какие требования необходимо выполнять при прокладке кабелей по территории технологических установок? Выберите 2 правильных варианта ответа.",
        answers: [
          "Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение",
          "Прокладка кабелей в каналах, засыпанных песком, и траншеях запрещается",
          "Провода и кабели с полиэтиленовой изоляцией или оболочкой из материалов, не распространяющих горение, должны прокладываться только на верхних ярусах технологических эстакад",
          "Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов",
        ],
        correctAnswers: [
          "Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение",
          "Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов",
        ],
      },
      {
        code: "20112042",
        text: "Каким документом обосновывается повышение категории взрывоопасности технологических блоков, определяемое количеством токсичных, высокотоксичных веществ, опасностью причинения ими вреда обслуживающему персоналу при вероятных сценариях развития аварийной ситуации?",
        answers: [
          "Проектной документацией",
          "Техническим регламентом",
          "Правилами безопасности",
          "Приказом Ростехнадзора",
        ],
        correctAnswers: ["Проектной документацией"],
      },
      {
        code: "20112043",
        text: "В каких случаях на трубопроводах следует применять арматуру под приварку?",
        answers: [
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков любой категории взрывоопасности",
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I и II категорий взрывоопасности и температурой, равной температуре кипения при регламентированном давлении",
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении",
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа",
        ],
        correctAnswers: [
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении",
        ],
      },
      {
        code: "20112044",
        text: "Какая система отопления должна применяться в помещениях, имеющих взрывоопасные зоны?",
        answers: [
          "Система парового отопления",
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
          "Система водяного отопления",
          "Система газового отопления",
        ],
        correctAnswers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
        ],
      },
      {
        code: "20112045",
        text: "Что должно быть учтено в системах управления и защиты электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?",
        answers: [
          "Наличие автоматического ввода резерва для каждого из трех самостоятельных источников электроснабжения",
          "Обеспечена возможность синхронизации третьего независимого источника предприятия-потребителя от системы электроснабжения",
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
          "Должна быть обеспечена селективность защит устройств электроснабжения и электропотребления организаций",
        ],
        correctAnswers: [
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
        ],
      },
      {
        code: "20112046",
        text: "Как производителем должна подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств до начала их применения на опасном производственном объекте?",
        answers: [
          "Заключением научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизы промышленной безопасности",
          "Оценкой научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования",
          "Результатами опытных работ, проводимых разработчиком данного оборудования, и экспертизы промышленной безопасности",
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
        ],
        correctAnswers: [
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
        ],
      },
      {
        code: "20112047",
        text: "Какая из перечисленных мер должна предусматриваться для блоков технологической системы по максимальному снижению взрывоопасности?",
        answers: [
          "Применение эффективных систем пожаротушения в зависимости от особенностей технологического процесса эффективных систем пожаротушения",
          "Применение вентиляционных систем для исключения возможности взрывов и пожаров в производственных зданиях, сооружениях",
          "Предотвращение взрывов внутри технологического оборудования",
          "Периодический контроль воздушной среды",
        ],
        correctAnswers: [
          "Предотвращение взрывов внутри технологического оборудования",
        ],
      },
      {
        code: "20112048",
        text: "Где разрешается использование железнодорожных цистерн с легковоспламеняющимися жидкостями, находящимися на железнодорожных путях, в качестве стационарных, складских (расходных) емкостей?",
        answers: [
          "На внутренних путях организации",
          "В местах проведения погрузочно-разгрузочных работ",
          "Нигде не разрешается",
          "На тупиковых железнодорожных ветках",
        ],
        correctAnswers: ["Нигде не разрешается"],
      },
      {
        code: "20112049",
        text: "Какой следует принимать категорию взрывоопасности блоков, определяемую расчетом, если обращающиеся в технологическом блоке опасные вещества относятся к токсичным, высокотоксичным веществам?",
        answers: [
          "III категории",
          "На одну выше",
          "II категории",
          "I категории",
        ],
        correctAnswers: ["На одну выше"],
      },
      {
        code: "20112050",
        text: "В соответствии с чем осуществляется ведение технологических процессов на опасных производственных объектах нефтехимических и нефтегазоперерабатывающих производств?",
        answers: [
          "В соответствии с распоряжениями технического руководителя (главного инженера) эксплуатирующей организации",
          "В соответствии с указаниями территориального органа Ростехнадзора",
          "В соответствии с технологическими регламентами на производство продукции",
          "В соответствии с заключением экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами на производство продукции",
        ],
      },
      {
        code: "20112051",
        text: "Что предусматривается во взрывоопасных помещениях и вне их перед входными дверями?",
        answers: [
          "Устройство световой и звуковой сигнализации загазованности воздушной среды",
          "Только световая сигнализация",
          "Информационный стенд о порядке действий персонала в аварийной ситуации",
          "Только звуковая сигнализация",
        ],
        correctAnswers: [
          "Устройство световой и звуковой сигнализации загазованности воздушной среды",
        ],
      },
      {
        code: "20112052",
        text: "В каком случае допускается размещать холодильное оборудование над площадками открытых насосных и компрессорных установок? Укажите все правильные ответы.",
        answers: [
          "В случае применения герметичных (бессальниковых) насосов",
          "Не допускается ни в каком случае",
          "В случае размещения холодильного оборудования над площадками открытых насосных и компрессорных установок на расстоянии, определенном проектной документацией",
          "При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование",
        ],
        correctAnswers: [
          "В случае применения герметичных (бессальниковых) насосов",
          "При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование",
        ],
      },
      {
        code: "20112053",
        text: "Кем определяется степень разделения материальных сред и меры взрывобезопасности на всех стадиях процесса?",
        answers: [
          "Эксплуатирующей организацией",
          "Разработчиком процесса",
          "Заказчиком в задании на проектирование",
          "Ростехнадзором",
        ],
        correctAnswers: ["Разработчиком процесса"],
      },
      {
        code: "20112054",
        text: "Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?",
        answers: [
          "На установках с технологическими блоками I категории взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками II и III категорий взрывоопасности соотношение давлений не регламентируется",
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
          "На установках с технологическими блоками I и II категорий взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками III категории взрывоопасности соотношение давлений не регламентируется",
          "Соотношение давлений негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ устанавливается разработчиком процесса",
        ],
        correctAnswers: [
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
        ],
      },
      {
        code: "20112055",
        text: "На каких кислородопроводах необходимо устанавливать переключающиеся фильтры перед их подключением к коллектору всасывания кислородных компрессоров?",
        answers: [
          "На кислородопроводах длиной более 300 м, изготовленных из сплавов алюминия",
          "На кислородопроводах длиной более 250 м, изготовленных из углеродистых сталей",
          "На кислородопроводах длиной более 150 м, изготовленных из углеродистых сталей",
          "На кислородопроводах длиной более 200 м, изготовленных из меди и сплавов на основе меди",
        ],
        correctAnswers: [
          "На кислородопроводах длиной более 250 м, изготовленных из углеродистых сталей",
        ],
      },
      {
        code: "20112056",
        text: "Запорная арматура из каких материалов должна применяться в технологических системах с блоками любой категории взрывоопасности?",
        answers: [
          "Из чугуна",
          "Из стале-алюминиевых сплавов",
          "Из неметаллических композитных материалов",
          "Из стали",
        ],
        correctAnswers: ["Из стали"],
      },
      {
        code: "20112057",
        text: "Кем определяются предельные значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывоопасных характеристик, физико-химических свойств транспортируемых веществ, свойств конструкционных материалов и характеристик технических устройств, применяемых для перемещения горючих продуктов?",
        answers: [
          "Разработчиком проекта",
          "Эксплуатирующей организацией",
          "Монтажной организацией",
          "Ростехнадзором",
        ],
        correctAnswers: ["Разработчиком проекта"],
      },
      {
        code: "20112058",
        text: "Кем определяются допустимые значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывопожароопасных характеристик, физико-химических свойств?",
        answers: [
          "Разработчиком проекта",
          "Эксплуатирующей организацией",
          "Монтажной организацией",
          "Ростехнадзором",
        ],
        correctAnswers: ["Разработчиком проекта"],
      },
      {
        code: "20112059",
        text: "Какие требования предъявляются к частоте продувки влагомаслоотделителей поршневых компрессоров при отсутствии автоматической продувки?",
        answers: [
          "Продувка должна производиться через каждые 40 минут",
          "Продувка должна производиться через каждые 20 минут",
          "Продувка должна производиться через каждые 60 минут",
          "Продувка должна производиться через каждые 30 минут",
        ],
        correctAnswers: ["Продувка должна производиться через каждые 30 минут"],
      },
      {
        code: "20112060",
        text: "Где должно осуществляться измельчение, смешивание измельченных твердых горючих продуктов для исключения образования в системе взрывоопасных смесей?",
        answers: [
          "В среде инертного газа",
          "В среде, насыщенной кислородом",
          "В среде, насыщенной углекислым газом",
          "В среде атмосферного воздуха",
        ],
        correctAnswers: ["В среде инертного газа"],
      },
      {
        code: "20112061",
        text: "Каким документом устанавливаются места расположения запорных и (или) отсекающих устройств?",
        answers: [
          "Проектной документацией или документацией на техническое перевооружение",
          "Планом мероприятий по локализации и ликвидации последствий аварий",
          "Проектом производства работ стадии П",
        ],
        correctAnswers: [
          "Проектной документацией или документацией на техническое перевооружение",
        ],
      },
      {
        code: "20112062",
        text: "Без чего не допускается прямое соединение канализации химически загрязненных стоков с хозяйственно-бытовой канализацией на взрывопожароопасных производствах?",
        answers: [
          "Без гидрозатвора",
          "Без фильтра-насадки",
          "Без регулятора стока",
          "Без обратного клапана",
        ],
        correctAnswers: ["Без гидрозатвора"],
      },
      {
        code: "20112063",
        text: "Какими приборами и средствами автоматизации оснащаются сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Выберите 2 правильных варианта ответа.",
        answers: [
          "Приборами контроля температуры и плотности отделяемой жидкой фазы рабочей среды",
          "Приборами измерения перепада давления на входе и выходе сепаратора",
          "Блокировками отключения компрессора при превышении предельно допустимого значения уровня",
          "Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации",
        ],
        correctAnswers: [
          "Блокировками отключения компрессора при превышении предельно допустимого значения уровня",
          "Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации",
        ],
      },
      {
        code: "20112064",
        text: "Чем должно быть оснащено оборудование для разделения суспензий?",
        answers: [
          "Фильтрами-насадками",
          "Обратным клапаном",
          "Блокировками, исключающими его пуск, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды",
          "Гидрозатвором и регулятором стока",
        ],
        correctAnswers: [
          "Блокировками, исключающими его пуск, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды",
        ],
      },
      {
        code: "20112065",
        text: "Чем из перечисленного оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?",
        answers: [
          "Системами ручного регулирования, применяемыми без средств вычислительной техники",
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
          "Эффективными быстродействующими системами, обеспечивающими непрерывность и надежность технологического процесса",
        ],
        correctAnswers: [
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
        ],
      },
      {
        code: "20112066",
        text: 'Какое требование к системам вентиляции не соответствует ФНП "Общие правила взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств"?',
        answers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
          "Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства",
          "Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания",
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: "20112067",
        text: "Что должно устанавливаться на трубопроводах для транспортирования взрывопожароопасных продуктов?",
        answers: [
          "Арматура с резиново-тканевым уплотнением в затворе",
          "Арматура с уплотнением в затворе",
          "Арматура в соответствии с проектной документацией",
          "Арматура с пластиковым уплотнением в затворе",
        ],
        correctAnswers: ["Арматура в соответствии с проектной документацией"],
      },
      {
        code: "20112068",
        text: "Каким из перечисленных требований должны соответствовать помещения управления? Укажите все правильные ответы.",
        answers: [
          "Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности",
          "Защита от механических повреждений проложенных по полу кабелей должна осуществляться с помощью металлических швеллеров и уголков",
          "Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам",
          "Полы в помещении должны быть нескользкими, повышенной прочности",
        ],
        correctAnswers: [
          "Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности",
          "Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам",
        ],
      },
      {
        code: "20112069",
        text: "Что используется для соединения оборудования и технологических трубопроводов со стационарными линиями во взрывопожароопасных технологических системах?",
        answers: [
          "Пластмассовые шланги",
          "Металлические трубы",
          "Резиновые шланги",
          "Съемные участки трубопроводов",
        ],
        correctAnswers: ["Съемные участки трубопроводов"],
      },
      {
        code: "20112070",
        text: "Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предотвращения их развития?",
        answers: [
          "Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные машины и механизмы",
          "Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва, а также системы для аварийного опорожнения аппаратов",
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
          "Запорная арматура, средства защиты от превышения давления, а также огнепреградители",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20112071",
        text: "Где приводятся конкретные значения уставок систем защиты по опасным параметрам?",
        answers: [
          "В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции",
          "В технологическом регламенте на производство выпускаемой продукции",
          "В проектной документации стадии Р",
        ],
        correctAnswers: [
          "В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20112072",
        text: "Каким требованиям должны соответствовать насосы и компрессоры технологических блоков взрывопожароопасных производств, остановка которых при падении напряжения или кратковременном отключении электроэнергии может привести к отклонениям технологических параметров процесса до критических значений и развитию аварий?",
        answers: [
          "Насосы и компрессоры должны выбираться с учетом возможности их повторного автоматического пуска",
          "Токи самозапуска электродвигателей не должны превышать номинальные более чем в два раза",
          "Насосы и компрессоры должны оснащаться маховиками для обеспечения повторного самозапуска электродвигателей",
          "Время срабатывания систем самозапуска должно определяться нагрузкой насосов и компрессоров, но не должно превышать 5 минут",
        ],
        correctAnswers: [
          "Насосы и компрессоры должны выбираться с учетом возможности их повторного автоматического пуска",
        ],
      },
      {
        code: "20112073",
        text: "Каким должно быть содержание масла в кислороде, поступающем в компрессоры и газодувки?",
        answers: [
          "Содержание масла не должно превышать 0,1 мг/м³",
          "Содержание масла не должно превышать 0,02 мг/м³",
          "Содержание масла не должно превышать 0,2 мг/м³",
          "Содержание масла не должно превышать 0,04 мг/м³",
        ],
        correctAnswers: ["Содержание масла не должно превышать 0,02 мг/м³"],
      },
      {
        code: "20112074",
        text: "Каким образом должны подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств для оборудования (аппаратов и трубопроводов) до начала их применения на опасном производственном объекте?",
        answers: [
          "Посредством результатов опытных работ, проводимых разработчиком данного оборудования, и заключения экспертизы промышленной безопасности",
          "Посредством результатов заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования",
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
          "Посредством результатов заключения экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
        ],
      },
      {
        code: "20112075",
        text: "Какие из перечисленных мер предусматриваются проектной документацией или документацией на техническое перевооружение для обеспечения взрывобезопасности технологической системы при пуске в работу и остановке оборудования? Укажите все правильные ответы.",
        answers: [
          "Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон",
          "Разработка режимов и порядка пуска и остановки оборудования",
          "Поддержание избыточного давления инертного газа в технологической системе после остановки оборудования",
          "Проведение контроля за содержанием горючих веществ в технологической системе после остановки работы технологического оборудования",
        ],
        correctAnswers: [
          "Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон",
          "Разработка режимов и порядка пуска и остановки оборудования",
        ],
      },
      {
        code: "20112076",
        text: "Где допускается расположение узла ввода теплоносителя?",
        answers: [
          "Только в производственных помещениях, в которых предусмотрено применение водяного или парового отопления",
          "Во всех указанных местах",
          "Только в самостоятельном помещении с отдельным входом с лестничной клетки или из невзрывопожароопасных производственных помещений",
          "Только в помещениях систем приточной вентиляции (в вентиляционной камере)",
        ],
        correctAnswers: ["Во всех указанных местах"],
      },
      {
        code: "20112077",
        text: "Какие технологические блоки относятся к первой категории взрывоопасности?",
        answers: [
          "Qв меньше 27, m, кг меньше 2000",
          "Qв больше 37, m, кг больше 5000",
          "Qв 27 - 37, m, кг 2000 - 5000",
        ],
        correctAnswers: ["Qв больше 37, m, кг больше 5000"],
      },
      {
        code: "20112078",
        text: "Чем определяется порядок испытаний, контроль за состоянием и эксплуатацией теплообменных устройств?",
        answers: [
          "Исходными данными на проектирование теплообменных устройств",
          "Технической документацией производителя",
          "Технологическим регламентом порядка испытаний, контроля за состоянием и эксплуатацией теплообменных устройств",
          "Проектной документацией теплообменных устройств",
        ],
        correctAnswers: ["Технической документацией производителя"],
      },
      {
        code: "20112079",
        text: "Где предусматривается установка постов управления и технических средств связи и оповещения для извещения об опасных выбросах химически опасных веществ на объектах, имеющих в своем составе блоки I категории взрывоопасности? Выберите 2 правильных варианта ответа.",
        answers: [
          "На ограждении по периметру объекта через каждые 50 м",
          "На контрольно-пропускном пункте химически опасного производственного объекта",
          "В помещении диспетчера предприятия",
          "На наружных установках",
        ],
        correctAnswers: [
          "В помещении диспетчера предприятия",
          "На наружных установках",
        ],
      },
      {
        code: "20112080",
        text: "Какое требование к системам вентиляции указано неверно?",
        answers: [
          "Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства",
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
          "Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания",
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: "20112081",
        text: "На каких кислородных центробежных компрессорах необходимо предусматривать стационарные устройства, позволяющие производить отбор проб для анализа газа, выходящего из лабиринтных уплотнений компрессора?",
        answers: [
          "С давлением нагнетания 0,6 МПа",
          "На одном из компрессоров с давлением нагнетания более 0,6 МПа",
          "На любом из компрессоров с давлением нагнетания 0,6 МПа",
          "На каждом с давлением нагнетания более 0,6 МПа",
        ],
        correctAnswers: ["На каждом с давлением нагнетания более 0,6 МПа"],
      },
      {
        code: "20112082",
        text: "Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?",
        answers: [
          "Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и установлено для блоков III категории",
          "Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и установлено для блоков I и II категорий",
          "Время срабатывания устанавливается для каждого технологического блока в соответствии с категорией взрывоопасности",
          "Время срабатывания определяется расчетом",
        ],
        correctAnswers: ["Время срабатывания определяется расчетом"],
      },
      {
        code: "20112083",
        text: "Что должно быть учтено при выборе компрессоров и насосов для перемещения горючих, сжатых и сжиженных газов, легковоспламеняющихся жидкостей и горючих жидкостей?",
        answers: [
          "Показатели надежности, конструктивные особенности с учетом физико-химических свойств перемещаемых продуктов и регламентированные параметры технологического процесса",
          "Показатели надежности, конструктивные особенности и параметры работы компрессоров и насосов",
          "Показатели надежности, конструктивные особенности компрессоров и насосов с учетом физико-химических свойств перемещаемых продуктов, исполнение по взрывозащите",
          "Показатели надежности и конструктивные особенности компрессоров и насосов",
        ],
        correctAnswers: [
          "Показатели надежности, конструктивные особенности с учетом физико-химических свойств перемещаемых продуктов и регламентированные параметры технологического процесса",
        ],
      },
      {
        code: "20112084",
        text: "В какой документации приводятся способы и средства, исключающие выход параметров за установленные пределы?",
        answers: [
          "Только в исходных данных на проектирование и технологическом регламенте на производство продукции",
          "Только в проектной документации",
          "В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции",
          "Только в технологическом регламенте на производство продукции",
        ],
        correctAnswers: [
          "В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20112085",
        text: "Какие из перечисленных мер по обеспечению взрывобезопасности предусматриваются проектной организацией для каждого технологического блока с учетом его энергетического потенциала? Укажите все правильные ответы.",
        answers: [
          "Предотвращение взрывов и травмирования производственного персонала",
          "Уменьшение ущерба от возможной аварии на взрывопожароопасном объекте",
          "Повышение квалификации обслуживающего персонала взрывопожароопасного объекта на курсах переподготовки и повышения квалификации",
          "Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества",
        ],
        correctAnswers: [
          "Предотвращение взрывов и травмирования производственного персонала",
          "Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества",
        ],
      },
      {
        code: "20112086",
        text: "Что из перечисленного является недопустимым на территории предприятия, имеющего в своем составе взрывопожароопасные производства?",
        answers: [
          "Только наличие природных оврагов, выемок, низин",
          "Только траншейная и наземная прокладка трасс трубопроводов со сжиженными горючими газами, легковоспламеняющимися и горючими жидкостями в искусственных или естественных углублениях",
          "Только устройство открытых траншей, котлованов, приямков, в которых возможно скопление взрывопожароопасных паров и газов",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20112087",
        text: "Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные газы, легковоспламеняющиеся жидкости и горючие жидкости на сливо-наливных эстакадах?",
        answers: [
          "Дистанционно из безопасного места",
          "По месту и дистанционно (из безопасного места)",
          "По месту вручную",
          "Автоматизированно",
        ],
        correctAnswers: ["По месту и дистанционно (из безопасного места)"],
      },
      {
        code: "20112088",
        text: "Каков порядок сброса химически загрязненных стоков от отдельных технологических объектов в магистральную сеть канализации?",
        answers: [
          "Системы канализации технологических объектов должны исключать залповые и аварийные сбросы загрязненных стоков в магистральную сеть",
          "Системы канализации технологических объектов должны быть оборудованы устройствами для улавливания аварийных стоков перед их сбросом в магистральную сеть",
          "Порядок сброса загрязненных стоков в магистральную сеть канализации устанавливается организацией",
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
        ],
        correctAnswers: [
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
        ],
      },
      {
        code: "20112089",
        text: "В каком из перечисленных случаев категорию взрывоопасности блоков, определяемую расчетом, следует рассматривать на одну выше?",
        answers: [
          "Только если обращающиеся в технологическом блоке вещества относятся к высокотоксичным веществам",
          "В любом из указанных случаев",
          "Только если обращающиеся в технологическом блоке вещества относятся к токсичным веществам",
        ],
        correctAnswers: ["В любом из указанных случаев"],
      },
      {
        code: "20112090",
        text: "В каких местах не допускается размещать фланцевые соединения трубопроводов с взрывопожароопасными, токсичными и едкими веществами?",
        answers: [
          "Над автомобильными дорогами",
          "На трубопроводах, расположенных на стенах зданий",
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
          "На трубопроводах, идущих по эстакадам",
        ],
        correctAnswers: [
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
        ],
      },
      {
        code: "20112091",
        text: "Кто определяет выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?",
        answers: [
          "Заказчик",
          "Разработчик процесса",
          "Эксплуатирующая организация",
          "Строительно-монтажное предприятие",
        ],
        correctAnswers: ["Разработчик процесса"],
      },
      {
        code: "20112092",
        text: "Какие условия должны выполняться для допуска к эксплуатации компрессорных установок?",
        answers: [
          "Наличие разрешения на применения технических устройств, выдаваемого территориальными органами Ростехнадзора",
          "Наличие положительного заключения экспертизы промышленной безопасности",
          "Все перечисленные условия",
          "Наличие и исправное состояние средств автоматизации, контроля и системы блокировок",
        ],
        correctAnswers: [
          "Наличие и исправное состояние средств автоматизации, контроля и системы блокировок",
        ],
      },
      {
        code: "20112093",
        text: "В соответствии с чем должно осуществляться размещение организации, имеющей в своем составе взрывоопасные технологические объекты, планировка ее территории, объемно-планировочные решения строительных объектов?",
        answers: [
          "В соответствии с требованиями Правил безопасности на производстве",
          "В соответствии с техническими регламентами производственного процесса",
          "В соответствии с требованиями законодательства о градостроительной деятельности",
          "В соответствии с требованиями органов Ростехнадзора",
        ],
        correctAnswers: [
          "В соответствии с требованиями законодательства о градостроительной деятельности",
        ],
      },
      {
        code: "20112094",
        text: "Какой порядок установлен для внесения изменений в технологическую схему, аппаратурное оформление, в системы контроля, связи, оповещения и противоаварийной автоматической защиты?",
        answers: [
          "Изменения вносятся после внесения изменений в проектную документацию или документацию на техническое перевооружение, согласованную с разработчиком проектной документации или с организацией, специализирующейся на проектировании аналогичных объектов, или при наличии положительного заключения экспертиз по проектной документации (документации)",
          "Изменения вносятся при наличии заключения экспертизы промышленной безопасности и внесения его в реестр заключений экспертизы",
          "Изменения вносятся путем разработки обоснования безопасности опасного производственного объекта, получения на него заключения экспертизы промышленной безопасности и внесения его в реестр заключений экспертизы",
        ],
        correctAnswers: [
          "Изменения вносятся после внесения изменений в проектную документацию или документацию на техническое перевооружение, согласованную с разработчиком проектной документации или с организацией, специализирующейся на проектировании аналогичных объектов, или при наличии положительного заключения экспертиз по проектной документации (документации)",
        ],
      },
      {
        code: "20112095",
        text: "Чем должны оснащаться сушильные установки, имеющие непосредственный контакт высушиваемого продукта с сушильным агентом?",
        answers: [
          "Световой и звуковой сигнализациями",
          "Сепараторами очистки",
          "Системами блокировок",
          "Устройствами очистки отработанного сушильного агента от пыли высушиваемого продукта и средствами контроля очистки",
        ],
        correctAnswers: [
          "Устройствами очистки отработанного сушильного агента от пыли высушиваемого продукта и средствами контроля очистки",
        ],
      },
      {
        code: "20112096",
        text: "С учетом чего должна проектироваться, изготавливаться и эксплуатироваться система транспорта сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей посредством насосов?",
        answers: [
          "С учетом химико-физического анализа проб горючих веществ на содержание кислорода",
          "С учетом возможности проведения эффективной и безопасной очистки технологической системы производства",
          "С учетом анализа эксплуатационных отказов для того, чтобы предотвратить возможность возникновения аварийных режимов",
        ],
        correctAnswers: [
          "С учетом анализа эксплуатационных отказов для того, чтобы предотвратить возможность возникновения аварийных режимов",
        ],
      },
      {
        code: "20112097",
        text: "Какое положение не соответствует установленным требованиям к электрообеспечению и электрооборудованию взрывоопасных технологических схем?",
        answers: [
          "Запрещается прокладка кабелей в каналах, засыпанных песком, и траншеях",
          "Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение",
          "Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов в соответствии с требованиями нормативно-технических документов по устройству электроустановок",
          "Прокладку кабелей по территории предприятий и установок разрешается выполнять открыто: по эстакадам, в галереях и на кабельных конструкциях технологических эстакад",
        ],
        correctAnswers: [
          "Запрещается прокладка кабелей в каналах, засыпанных песком, и траншеях",
        ],
      },
      {
        code: "20112098",
        text: "Чем определяется количество насосов и компрессоров, используемых для перемещения химически опасных веществ в технологическом процессе?",
        answers: [
          "Физическим и химическими свойствами перемещаемых продуктов",
          "Техническими характеристиками задействованных насосов и компрессоров",
          "Необходимостью и условиями обеспечения непрерывности технологического процесса",
          "Критическими параметрами технологического процесса на всем его протяжении",
        ],
        correctAnswers: [
          "Необходимостью и условиями обеспечения непрерывности технологического процесса",
        ],
      },
      {
        code: "20112099",
        text: "Оценку каких параметров необходимо произвести в проектной документации при разработке технологического процесса?",
        answers: [
          "Только оценку эффективности технических средств противоаварийной защиты, направленных на обеспечение взрывобезопасности технологических блоков и в целом всей технологической схемы",
          "Только оценку энергетического уровня каждого технологического блока и определение категории его взрывоопасности",
          "В проектной документации производится оценка всех перечисленных параметров",
          "Только оценку эффективности и надежности мер, обеспечивающих взрывобезопасность каждого технологического блока",
        ],
        correctAnswers: [
          "В проектной документации производится оценка всех перечисленных параметров",
        ],
      },
      {
        code: "20112100",
        text: "Арматура с каким классом герметичности затвора должна устанавливаться на трубопроводах для транспортирования взрывопожароопасных продуктов?",
        answers: [
          "СС",
          "Определяется в проектной документации (документации на техническое перевооружение)",
          "В",
          "А",
          "АА",
        ],
        correctAnswers: [
          "Определяется в проектной документации (документации на техническое перевооружение)",
        ],
      },
      {
        code: "20112101",
        text: "Каким образом определяются методы и сроки очистки водяных полостей холодильников и рубашек цилиндров компрессоров от отложений?",
        answers: [
          "Правилами безопасности химически опасных производственных объектов",
          "Специальным положением, разработанным техническим руководителем (главным инженером) эксплуатирующей организации",
          "Технологическим регламентом, технологическими инструкциями",
          "Методическим руководством, разработанным сторонней специализированной организацией",
        ],
        correctAnswers: [
          "Технологическим регламентом, технологическими инструкциями",
        ],
      },
      {
        code: "20112102",
        text: "Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?",
        answers: [
          "Разработчиком процесса",
          "Техническим руководителем (главным инженером) эксплуатирующей организации",
          "Заказчиком",
          "Монтажной организацией",
        ],
        correctAnswers: ["Разработчиком процесса"],
      },
      {
        code: "20112103",
        text: "Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Средства автоматики должны быть обозначены на мнемосхемах структуры блокировок",
          "Требования к обозначению определяются в процессе разработки планов мероприятий по локализации и ликвидации последствий аварий",
          "Средства автоматики должны быть обозначены только в технологическом регламенте на производство выпускаемой продукции",
          "Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте и инструкциях",
        ],
        correctAnswers: [
          "Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте и инструкциях",
        ],
      },
      {
        code: "20112104",
        text: "Какие требования предъявляются к системам канализации технологических объектов при сбросе химически загрязненных стоков в магистральную сеть канализации?",
        answers: [
          "Системы канализации технологических объектов должны быть оборудованы звуковой и световой аварийными сигнализациями",
          "Системы канализации технологических объектов должны осуществлять сброс стоков в магистральную сеть в порядке, установленном эксплуатирующей организацией",
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
          "Системы канализации эксплуатируемых технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть канализации",
        ],
        correctAnswers: [
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
        ],
      },
      {
        code: "20112105",
        text: "В каком документе указываются данные о сроке службы технологического оборудования и трубопроводной арматуры производителем?",
        answers: [
          "В проекте производства работ",
          "В техническом задании на изготовление технологического оборудования",
          "В технологическом регламенте на производство работ",
          "В паспортах оборудования и трубопроводной арматуры",
        ],
        correctAnswers: ["В паспортах оборудования и трубопроводной арматуры"],
      },
      {
        code: "20112106",
        text: "Что должно обеспечивать размещение технологического оборудования, трубопроводной арматуры в производственных зданиях и на открытых площадках? Выберите 2 правильных варианта ответа.",
        answers: [
          "Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий",
          "Возможность проведения ремонтных работ",
          "Использование строительных конструкций зданий и сооружений в несущих элементах технологического оборудования эксплуатируемого объекта",
          "Уменьшение взрывоопасности объекта путем равномерного распределения технологических блоков I категории взрывоопасности",
        ],
        correctAnswers: [
          "Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий",
          "Возможность проведения ремонтных работ",
        ],
      },
      {
        code: "20112107",
        text: "Чем должны быть оснащены колонны ректификации горючих жидкостей?",
        answers: [
          "Только средствами контроля уровня и температуры жидкости в кубовой части",
          "Только средствами сигнализации об опасных отклонениях значений параметров",
          "Только средствами ручного регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы",
          "Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы, а также средствами сигнализации об опасных отклонениях значений параметров, в том числе перепада давления между нижней и верхней частями колонны, определяющих взрывобезопасность процесса",
        ],
        correctAnswers: [
          "Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы, а также средствами сигнализации об опасных отклонениях значений параметров, в том числе перепада давления между нижней и верхней частями колонны, определяющих взрывобезопасность процесса",
        ],
      },
      {
        code: "20112108",
        text: "Для трубопроводов каких технологических блоков при подключении к коллектору в обоснованных случаях для повышения надежности предусматривается установка дублирующих отключающих устройств?",
        answers: [
          "Для технологических блоков III категории взрывоопасности",
          "Для технологических блоков II категории взрывоопасности",
          "Для технологических блоков I категории взрывоопасности",
          "Для технологических блоков VI категории взрывоопасности",
        ],
        correctAnswers: [
          "Для технологических блоков I категории взрывоопасности",
        ],
      },
      {
        code: "20112109",
        text: "Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся жидкости и горючие жидкости на сливо-наливных эстакадах?",
        answers: [
          "Должно быть организовано управление только по месту",
          "Должно быть организовано управление по месту и дистанционно (из безопасного места)",
          "Должно быть организовано управление только дистанционно (из безопасного места)",
        ],
        correctAnswers: [
          "Должно быть организовано управление по месту и дистанционно (из безопасного места)",
        ],
      },
      {
        code: "20112110",
        text: "С какими подразделениями должна быть оборудована система двусторонней громкоговорящей связи для объектов с технологическими блоками I категории взрывоопасности?",
        answers: [
          "Только с пожарной частью и сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов",
          "С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью и технологически связанными производственными участками",
          "Только с персоналом диспетчерских пунктов, газоспасательной службой и штабом гражданской обороны",
        ],
        correctAnswers: [
          "С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью и технологически связанными производственными участками",
        ],
      },
      {
        code: "20112111",
        text: "Каким образом предприятие должно обеспечить наработку навыков действий персонала в нештатных (аварийных) ситуациях на установках с технологическими блоками I и II категорий взрывоопасности?",
        answers: [
          "Посредством теоретического ознакомления персонала с описанием процессов производства",
          "Посредством обучения персонала на компьютерных тренажерах, включающих максимально приближенные к реальным динамические модели процессов и реальные средства управления",
          "Посредством ознакомления персонала с различными компьютерными тренажерами",
          "Допуском к самостоятельной работе не ранее чем через 6 месяцев после стажировки на объекте",
        ],
        correctAnswers: [
          "Посредством обучения персонала на компьютерных тренажерах, включающих максимально приближенные к реальным динамические модели процессов и реальные средства управления",
        ],
      },
      {
        code: "20112112",
        text: "Что из перечисленного должно быть учтено при выборе компрессоров и насосов для перемещения горючих, сжатых и сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?",
        answers: [
          "Должны быть учтены все перечисленные характеристики",
          "Только параметры технологического процесса",
          "Только физико-химические свойства перемещаемых продуктов",
          "Только показатели надежности и конструктивные особенности с учетом критических параметров",
        ],
        correctAnswers: ["Должны быть учтены все перечисленные характеристики"],
      },
      {
        code: "20112113",
        text: "Что необходимо предусматривать в проектной документации или документации на техническое перевооружение для максимального снижения выбросов горючих и взрывопожароопасных веществ при аварийной разгерметизации системы?",
        answers: [
          "Установку запорных и (или) отсекающих устройств с автоматическим управлением и временем срабатывания не более 120 секунд",
          "Установку запорных и (или) отсекающих устройств с временем срабатывания, устанавливаемым в проектной документации",
          "Установку запорных и (или) отсекающих устройств с автоматическим управлением и временем срабатывания не более 300 секунд",
        ],
        correctAnswers: [
          "Установку запорных и (или) отсекающих устройств с временем срабатывания, устанавливаемым в проектной документации",
        ],
      },
      {
        code: "20112114",
        text: "Чем оснащаются системы разделения газожидкостных смесей в целях обеспечения высокой эффективности разделения фаз?",
        answers: [
          "Гидрозатвором с дистанционным управлением",
          "Предохранительным клапаном",
          "Фазоразделителями",
          "Фильтрами с насадками",
        ],
        correctAnswers: ["Фазоразделителями"],
      },
      {
        code: "20112115",
        text: "Какие сведения являются основополагающими для выбора оборудования при разработке технологических процессов?",
        answers: [
          "Параметры оборудования и показатели надежности, соответствующие расчетным данным",
          "Параметры оборудования и требования действующих нормативных документов, соответствующие расчетным данным",
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
          "Параметры оборудования, задание на проектирование и требования действующих нормативных документов, соответствующие расчетным данным",
        ],
        correctAnswers: [
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
      },
      {
        code: "20112116",
        text: "Чем должны быть оснащены подводящие к смесителям коммуникации, проектируемые с целью обеспечения максимально возможного уровня эксплуатационной безопасности в отношении риска взрыва?",
        answers: [
          "Газоанализатором",
          "Обратными клапанами или другими устройствами, исключающими (при отклонениях от регламентированных параметров процесса) поступление обратным ходом в эти коммуникации подаваемых на смешивание горючих веществ, окислителей или смесей",
          "Датчиками давления",
          "Фильтрами со сменными насадками",
        ],
        correctAnswers: [
          "Обратными клапанами или другими устройствами, исключающими (при отклонениях от регламентированных параметров процесса) поступление обратным ходом в эти коммуникации подаваемых на смешивание горючих веществ, окислителей или смесей",
        ],
      },
      {
        code: "20112117",
        text: "Каким требованиям должны соответствовать помещения управления? Выберите 2 правильных варианта ответа.",
        answers: [
          "Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности",
          "Полы рабочего помещения должны быть холодными, с повышенной прочностью",
          "Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам",
          "Защита от механических повреждений проложенных по полу кабелей должна осуществляться с помощью металлических швеллеров и уголков",
        ],
        correctAnswers: [
          "Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности",
          "Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам",
        ],
      },
      {
        code: "20112118",
        text: "Где не допускается располагать колодцы на сетях канализации во взрывопожароопасных производствах?",
        answers: [
          "Только в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты",
          "Под эстакадами технологических трубопроводов и в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты",
          "Только под эстакадами технологических трубопроводов",
        ],
        correctAnswers: [
          "Под эстакадами технологических трубопроводов и в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты",
        ],
      },
      {
        code: "20112119",
        text: "В соответствии с чем осуществляется ведение технологических процессов на ОПО химических, нефтехимических и нефтегазоперерабатывающих производств?",
        answers: [
          "В соответствии с распоряжениями руководителя организации",
          "В соответствии с выводами экспертизы промышленной безопасности",
          "В соответствии с технологическими регламентами на производство продукции",
          "В соответствии с рекомендациями Ростехнадзора",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами на производство продукции",
        ],
      },
      {
        code: "20112120",
        text: "Какое из перечисленных положений не соответствует требованиям по размещению и устройству помещений управления взрывоопасных производств?",
        answers: [
          "В помещениях не должны размещаться оборудование и другие устройства, не связанные с системой управления технологическим процессом",
          "В отдельных случаях при соответствующем обосновании в проекте разрешается пристраивать помещения управления к зданиям",
          "Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами",
          "Помещения управления должны быть отдельно стоящими",
        ],
        correctAnswers: [
          "Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами",
        ],
      },
      {
        code: "20112121",
        text: "Каким требованиям должны соответствовать вновь проектируемые здания взрывопожароопасных объектов, в которых располагаются помещения управления (операторные), с постоянным пребыванием в них людей? Укажите все правильные ответы.",
        answers: [
          "Обеспечивать безопасность находящегося в них персонала",
          "Вокруг зданий должна предусматриваться вспаханная полоса земли шириной не менее 3 м",
          "Быть устойчивыми к воздействию ударной волны",
          "Окна зданий должны быть оборудованы жалюзи или ставнями из особо прочных материалов",
        ],
        correctAnswers: [
          "Обеспечивать безопасность находящегося в них персонала",
          "Быть устойчивыми к воздействию ударной волны",
        ],
      },
      {
        code: "20112122",
        text: "Каким требованиям должны соответствовать системы общеобменной и аварийной вытяжной вентиляции? Выберите 2 правильных варианта ответа.",
        answers: [
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
          "Общеобменная вентиляция должна включаться при срабатывании установленных в производственном помещении сигнализаторов довзрывных концентраций",
          "Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания",
          "Включение аварийной вытяжной вентиляции должно производиться при работающей общеобменной вентиляции в случае недостаточности воздухообмена в рабочем помещении",
        ],
        correctAnswers: [
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
          "Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания",
        ],
      },
      {
        code: "20112123",
        text: "Какие требования должны быть выполнены для вновь проектируемых взрывопожароопасных и химически опасных объектов? Выберите 2 правильных варианта ответа.",
        answers: [
          "Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия",
          "Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов",
          "Окна зданий должны быть оборудованы жалюзи и ставнями из особо прочных материалов",
          "Вокруг зданий должна предусматриваться вспаханная полоса земли шириной не менее 3 м",
        ],
        correctAnswers: [
          "Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия",
          "Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов",
        ],
      },
      {
        code: "20112124",
        text: "В соответствии с какими требованиями должно выполняться проектирование систем водопровода и канализации взрывопожароопасных производств?",
        answers: [
          "Только в соответствии с требованиями технических регламентов",
          "Только в соответствии с требованиями градостроительной деятельности",
          "Только в соответствии с требованиями Правил взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств",
          "В соответствии со всеми перечисленными требованиями",
        ],
        correctAnswers: ["В соответствии со всеми перечисленными требованиями"],
      },
      {
        code: "20112125",
        text: "В каком случае допускается подключение азотных компрессоров к ВРУ без газгольдеров?",
        answers: [
          "В соответствии с проектными решениями",
          "При наличии автоматических устройств и блокировок, исключающих увеличение отбора азота",
          "Не допускается ни в каком случае",
          "Если подключение осуществляется через обратный клапан",
        ],
        correctAnswers: [
          "При наличии автоматических устройств и блокировок, исключающих увеличение отбора азота",
        ],
      },
      {
        code: "20112126",
        text: "С какой периодичностью предохранительные устройства компрессорных агрегатов должны проверяться на давление срабатывания?",
        answers: [
          "Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией",
          "Не реже 1 раза в 5 лет",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 10 лет",
        ],
        correctAnswers: [
          "Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией",
        ],
      },
      {
        code: "20112127",
        text: "Для каких целей печи с открытым огневым процессом должны быть оборудованы паровой завесой?",
        answers: [
          "Для локализации пожара на печи при ее установке вне зданий",
          "Для изоляции печи от взрывоопасной среды при авариях на наружных установках или в зданиях печи",
          "Для тушения пожара на печи",
          "Для локализации пожаров на печи при ее установке внутри производственных помещений",
        ],
        correctAnswers: [
          "Для изоляции печи от взрывоопасной среды при авариях на наружных установках или в зданиях печи",
        ],
      },
      {
        code: "20112128",
        text: "Какими источниками информации следует руководствоваться при разработке технологических процессов для определения регламентированных значений параметров, определяющих взрывоопасность процесса, допустимые диапазоны их измерений, критические значения параметров?",
        answers: [
          "Технической литературой",
          "Заданием на проектирование, на основании данных о критических значениях параметров или их совокупности для участвующих в процессе веществ",
          "Данными, предоставляемыми научно-исследовательскими организациями",
          "Научной литературой",
        ],
        correctAnswers: [
          "Заданием на проектирование, на основании данных о критических значениях параметров или их совокупности для участвующих в процессе веществ",
        ],
      },
      {
        code: "20112129",
        text: "От какого вида электричества должны предусматриваться меры защиты при проектировании сливоналивных эстакад сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?",
        answers: [
          "От атмосферного и статического электричества",
          "Только от атмосферного электричества",
          "Только от статического электричества",
        ],
        correctAnswers: ["От атмосферного и статического электричества"],
      },
      {
        code: "20112130",
        text: "Что не соответствует установленным требованиям безопасности при обслуживании компрессора?",
        answers: [
          "Камеры, расположенные по ходу воздуха перед фильтром и после него, должны быть закрыты",
          "Камеры, расположенные по ходу воздуха перед фильтром и после него, должны быть снабжены знаками безопасности, запрещающими вход в камеры",
          "Работы в камере перед фильтром при работающем фильтре и компрессоре необходимо выполнять бригадой не менее чем из трех человек, один из которых является наблюдающим",
          "Во время работы воздушного центробежного компрессора и воздушного фильтра вход обслуживающего персонала в помещение камеры после воздушного фильтра (камера чистого воздуха) не допускается",
        ],
        correctAnswers: [
          "Работы в камере перед фильтром при работающем фильтре и компрессоре необходимо выполнять бригадой не менее чем из трех человек, один из которых является наблюдающим",
        ],
      },
      {
        code: "20112131",
        text: "Каким образом осуществляется регулирование массообменных процессов, в которых при отклонении технологических параметров от регламентированных значений возможно образование неустойчивых взрывоопасных соединений?",
        answers: [
          "Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту",
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Qв ≤ 10 допускается ручное дистанционное управление",
          "Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту",
          "Для установок с технологическими блоками I и II категорий взрывоопасности - при помощи средств автоматического регулирования, для установок III категории взрывоопасности предусматривается выполнение операций регулирования в ручном режиме (производственным персоналом) при обеспечении автоматического контроля указанных параметров процесса и сигнализации о превышении их допустимых значений",
        ],
        correctAnswers: [
          "Для установок с технологическими блоками I и II категорий взрывоопасности - при помощи средств автоматического регулирования, для установок III категории взрывоопасности предусматривается выполнение операций регулирования в ручном режиме (производственным персоналом) при обеспечении автоматического контроля указанных параметров процесса и сигнализации о превышении их допустимых значений",
        ],
      },
      {
        code: "20112132",
        text: "Какое управление системами подачи инертных сред в технологические системы должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категории взрывоопасности?",
        answers: [
          "Автоматическое",
          "Дистанционное (неавтоматическое)",
          "Ручное (по месту)",
        ],
        correctAnswers: ["Автоматическое"],
      },
      {
        code: "20112133",
        text: "С какими подразделениями должна быть обеспечена связь посредством системы двусторонней громкоговорящей связи на объектах с технологическими блоками I категории взрывоопасности?",
        answers: [
          "Только с пожарной частью и сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов",
          "С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов",
          "Только с персоналом диспетчерских пунктов, газоспасательной службой и штабом гражданской обороны",
        ],
        correctAnswers: [
          "С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов",
        ],
      },
      {
        code: "20112134",
        text: "Каким образом осуществляется управление подачей инертных сред на установку с технологическими блоками любой категории взрывоопасности там, где при отклонении от регламентированных значений параметров возможно образование взрывоопасных смесей?",
        answers: [
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту",
          "Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, для установок с технологическими блоками II категории взрывоопасности - ручное дистанционное, для установок с технологическими блоками III категории взрывоопасности допускается ручное управление по месту",
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Qв ≤ 10 - управление ручное дистанционное",
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление",
        ],
        correctAnswers: [
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту",
        ],
      },
      {
        code: "20112135",
        text: "Какая система отопления предусматривается в помещениях, имеющих взрывоопасные зоны?",
        answers: [
          "Только система парового отопления",
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
          "Только система водяного отопления",
          "Только система электрического отопления",
        ],
        correctAnswers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
        ],
      },
      {
        code: "20112136",
        text: "В какой документации должны быть приведены способы и средства, исключающие выход параметров за установленные пределы?",
        answers: [
          "В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции",
          "Только в технологическом регламенте на производство продукции",
          "Только в исходных данных на проектирование",
          "Только в проектной документации",
        ],
        correctAnswers: [
          "В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20112137",
        text: "Чем должны оснащаться колонны ректификации горючих жидкостей? Выберите 2 правильных варианта ответа.",
        answers: [
          "Средствами контроля за плотностью поступающих на разделение продукта и флегмы",
          "Средствами контроля за компонентным составом поступающего на разделение продукта",
          "Средствами сигнализации об опасных отклонениях значений параметров, определяющих взрывобезопасность процесса",
          "Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы",
        ],
        correctAnswers: [
          "Средствами сигнализации об опасных отклонениях значений параметров, определяющих взрывобезопасность процесса",
          "Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы",
        ],
      },
      {
        code: "20112138",
        text: "Где не располагается узел ввода теплоносителя на взрывопожароопасных производствах?",
        answers: [
          "В производственных помещениях, в которых предусмотрено применение водяного или парового отопления",
          "В электропомещениях и помещениях контрольно-измерительных приборов и автоматики",
          "В помещениях систем приточной вентиляции (в вентиляционной камере)",
          "В самостоятельном помещении с отдельным входом с лестничной клетки или из невзрывопожароопасных производственных помещений",
        ],
        correctAnswers: [
          "В электропомещениях и помещениях контрольно-измерительных приборов и автоматики",
        ],
      },
      {
        code: "20112139",
        text: "Какое управление подачей инертных сред в технологические установки должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категории взрывоопасности?",
        answers: [
          "Ручное (по месту)",
          "Дистанционное (неавтоматическое)",
          "Автоматическое",
        ],
        correctAnswers: ["Автоматическое"],
      },
      {
        code: "20112140",
        text: "В каких документах приводятся способы и средства, исключающие выход параметров за установленные пределы?",
        answers: [
          "Во всех перечисленных",
          "Только в технологическом регламенте на производство продукции",
          "Только в исходных данных на проектирование",
          "Только в проектной документации",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "20112141",
        text: "Исходя из чего осуществляется проектирование системы противоаварийной автоматической защиты и выбор ее элементов?",
        answers: [
          "Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта",
          "Исходя из условий обеспечения работы системы только в процессе обслуживания в течение 1 года",
          "Исходя из условий обеспечения работы системы только в процессе ремонта",
          "Исходя из условий обеспечения работы системы только в процессе эксплуатации в течение 5 лет",
        ],
        correctAnswers: [
          "Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта",
        ],
      },
      {
        code: "20112142",
        text: "Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?",
        answers: [
          "Эффективными и быстродействующими системами, обеспечивающими безопасность и непрерывность технологического процесса",
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
          "Системами ручного регулирования (без применения вычислительной техники)",
        ],
        correctAnswers: [
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
        ],
      },
      {
        code: "20112143",
        text: "Чем подтверждаются эффективность и надежность средств взрывозащиты и локализации пламени и других противоаварийных устройств, направленных на взрывозащищенность оборудования?",
        answers: [
          "Посредством опытных работ, проводимых разработчиком данного оборудования, и экспертизы промышленной безопасности",
          "Посредством получения заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизы промышленной безопасности",
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
          "Посредством оценки научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования",
        ],
        correctAnswers: [
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
        ],
      },
      {
        code: "20112144",
        text: "Кто делает обоснование по применению эффективности и надежности мер и технических средств противоаварийной защиты, направленных на обеспечение взрывобезопасности отдельного блока и в целом всей технологической системы?",
        answers: [
          "Проектная организация",
          "Монтажная организация",
          "Экспертная организация",
          "Эксплуатирующая производственный объект организация",
        ],
        correctAnswers: ["Проектная организация"],
      },
      {
        code: "20112145",
        text: "В зависимости от чего осуществляется выбор конструкции и конструкционных материалов, уплотнительных устройств для насосов и компрессоров?",
        answers: [
          "В зависимости от свойств перемещаемой среды",
          "В зависимости от протяженности трубопровода и его диаметра",
          "В зависимости от принятого вида взрывозащиты",
          "В зависимости от всех перечисленных факторов",
        ],
        correctAnswers: ["В зависимости от свойств перемещаемой среды"],
      },
      {
        code: "20112146",
        text: "С учетом каких параметров в каждом конкретном случае в проектной документации (документации на техническое перевооружение) обосновывается решение о типе арматуры и месте ее установки на линиях всасывания и нагнетания, а также способе ее отключения, в том числе дистанционном? Укажите все правильные ответы.",
        answers: [
          "С учетом характеристики транспортируемой среды",
          "С учетом материала, из которого изготовлен трубопровод",
          "С учетом диаметра и протяженности трубопровода",
          "С учетом толщины стенок трубопровода",
        ],
        correctAnswers: [
          "С учетом характеристики транспортируемой среды",
          "С учетом диаметра и протяженности трубопровода",
        ],
      },
      {
        code: "20112147",
        text: "Где разрешается использование железнодорожных цистерн с легковоспламеняющимися жидкостями, находящимися на железнодорожных путях, в качестве стационарных складских емкостей?",
        answers: [
          "В местах погрузочно-разгрузочных работ",
          "Нигде не разрешается",
          "На технологических путях эксплуатирующей организации",
          "На железнодорожных путях необщего пользования",
        ],
        correctAnswers: ["Нигде не разрешается"],
      },
      {
        code: "20112148",
        text: "Что необходимо применять для смазки цилиндров поршневых кислородных компрессоров?",
        answers: [
          "Воду, полученную из заводской системы пароснабжения",
          "Масла, рекомендованные производителями компрессора",
          "Дистиллят, полученный из воды питьевого качества",
          "Конденсат, полученный из заводской системы водоснабжения",
        ],
        correctAnswers: ["Дистиллят, полученный из воды питьевого качества"],
      },
      {
        code: "20112149",
        text: "Каким документам должны соответствовать объемно-планировочные решения, конструкция зданий, помещений и вспомогательных сооружений для систем контроля, управления, противоаварийной автоматической защиты и газового анализа, их размещение на территории опасного производственного объекта нефтехимических производств?",
        answers: [
          "Только требованиям технических регламентов",
          "Только требованиям Правил взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств",
          "Всем перечисленным документам",
          "Только требованиям законодательства о градостроительной деятельности",
          "Только требованиям проектной документации",
        ],
        correctAnswers: ["Всем перечисленным документам"],
      },
      {
        code: "20112150",
        text: "Какие требования должны быть выполнены на вновь проектируемых взрывопожароопасных и химически опасных производственных объектах?",
        answers: [
          "Только защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия",
          "Только бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов",
          "Должны быть обеспечены все указанные требования",
        ],
        correctAnswers: ["Должны быть обеспечены все указанные требования"],
      },
      {
        code: "20112151",
        text: "Какого показателя категорий взрывоопасности технологических блоков не существует?",
        answers: ["III", "IV", "II", "I"],
        correctAnswers: ["IV"],
      },
      {
        code: "20112152",
        text: "Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества?",
        answers: [
          "Системами ручного регулирования (c применением вычислительной техники)",
          "Эффективными быстродействующими системами, обеспечивающими непрерывность технологического процесса",
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
        ],
        correctAnswers: [
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
        ],
      },
      {
        code: "20112153",
        text: "В каком случае системы аварийной вентиляции должны включаться автоматически? Выберите 2 правильных варианта ответа.",
        answers: [
          "При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
          "При поступлении сигнала от датчиков повышения температуры в воздухе помещения взрывоопасного производства",
          "При срабатывании сигнализаторов довзрывных концентраций вредных веществ",
          "При поступлении сигнала от датчиков контроля за содержанием взрывопожароопасной пыли в воздухе помещения взрывоопасного производства",
        ],
        correctAnswers: [
          "При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
          "При срабатывании сигнализаторов довзрывных концентраций вредных веществ",
        ],
      },
      {
        code: "20112154",
        text: "Каким образом обеспечивается надежность обеспечения средств управления и систем противоаварийной защиты сжатым воздухом?",
        answers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа",
          "Переключением сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования на заводскую сеть сжатого воздуха через осушитель",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
          "Установкой резервного компрессора с включением его автоматически при остановке рабочего компрессора",
        ],
        correctAnswers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20112155",
        text: "Сколько в процентном отношении должна составлять температура поверхностей нагрева систем отопления в помещениях, имеющих взрывопожароопасные зоны?",
        answers: [
          "Не более 90 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Не более 95 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Не более 80 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Не более 85 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
        correctAnswers: [
          "Не более 80 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
      },
      {
        code: "20112156",
        text: "В течение какого времени средства обеспечения энергоустойчивости технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?",
        answers: [
          "В течение 2 часов",
          "В течение времени, достаточного для исключения опасной ситуации",
          "В течение 8 часов",
          "В течение 24 часов",
        ],
        correctAnswers: [
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
      },
      {
        code: "20112157",
        text: "В течение какого минимального времени буферные емкости (реципиенты) должны обеспечивать питание сжатым воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров?",
        answers: ["2 часов", "1 часа", "30 минут", "15 минут"],
        correctAnswers: ["1 часа"],
      },
      {
        code: "20112158",
        text: "Какую температуру должна иметь вода для охлаждения компрессора на входе и на выходе из рубашек цилиндров, если заводом-изготовителем не предусмотрены другие предельные значения?",
        answers: [
          "Не ниже 20 °C и не более 50 °C соответственно",
          "Не ниже 15 °C и не более 40 °C соответственно",
          "Не ниже 10 °C и не более 45 °C соответственно",
          "Не ниже 5 °C и не более 35 °C соответственно",
        ],
        correctAnswers: ["Не ниже 10 °C и не более 45 °C соответственно"],
      },
      {
        code: "20112159",
        text: "В течение какого времени буферные емкости (реципиенты) должны обеспечивать питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров?",
        answers: [
          "В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
          "Время должно быть подтверждено расчетом, но не менее 20 минут",
          "Время должно быть подтверждено расчетом, но не более 45 минут",
          "Время должно быть подтверждено расчетом, но не менее 35 минут",
        ],
        correctAnswers: [
          "В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20112160",
        text: "С какой периодичностью необходимо проверять исправность автоматических приборов защиты аммиачных компрессоров и сигнализаторов концентрации паров аммиака в воздухе помещений и наружных площадок?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в 10 дней",
          "Не реже 1 раза в 3 месяца",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20112161",
        text: "Какими из перечисленных приборов могут быть оснащены сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора?",
        answers: [
          "Только средствами блокировки",
          "Только сигнализацией по максимальному уровню",
          "Всеми перечисленными приборами",
          "Только приборами контроля уровня",
        ],
        correctAnswers: ["Всеми перечисленными приборами"],
      },
      {
        code: "20112162",
        text: "Каким образом обеспечивается надежность обеспечения средств управления и противоаварийной защиты сжатым воздухом?",
        answers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа",
          "Установкой резервного компрессора с включением его автоматически при остановке рабочего компрессора",
          "Переключением сети воздуха для обеспечения контрольно-измерительных приборов и автоматики (КИПиА) на заводскую сеть сжатого воздуха через осушитель",
        ],
        correctAnswers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20112163",
        text: "Каким требованиям должны соответствовать специальные системы аварийного освобождения технологических блоков от обращающихся продуктов? Выберите 2 правильных варианта ответа.",
        answers: [
          "Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий",
          "Обеспечивать минимально возможное время освобождения",
          "Специальные системы аварийного освобождения должны быть многофункциональны и при необходимости использоваться для других целей",
          "Переход из режима ожидания в рабочее состояние должен осуществляться в течение 30 с",
        ],
        correctAnswers: [
          "Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий",
          "Обеспечивать минимально возможное время освобождения",
        ],
      },
      {
        code: "20112164",
        text: "Чем должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей? Выберите 2 правильных варианта ответа.",
        answers: [
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
          "Системами измерения плотности и вязкости перекачиваемых сред на всасе насоса и его выходе",
          "Средствами контроля температуры окружающего воздуха и перемещаемой жидкости",
          "Средствами предупредительной сигнализации при достижении опасных значений параметров в приемных и расходных емкостях",
        ],
        correctAnswers: [
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
          "Средствами предупредительной сигнализации при достижении опасных значений параметров в приемных и расходных емкостях",
        ],
      },
      {
        code: "20112165",
        text: "Какие требования предъявляются к пневматическим системам контроля, управления и противоаварийной автоматической защиты?",
        answers: [
          "Качество сжатого воздуха должно проверяться не реже одного раза в квартал",
          "Использование инертного газа для питания систем контрольно-измерительных приборов и устройств автоматического регулирования определяется конкретными условиями и потребностями объекта",
          "Для пневматических систем контроля, управления и противоаварийной защиты предусматриваются единые установки и единые сети сжатого воздуха",
          "Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги",
        ],
        correctAnswers: [
          "Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги",
        ],
      },
      {
        code: "20112166",
        text: "Какие функции должна обеспечивать автоматизированная система управления технологическими процессами на базе средств вычислительной техники? Выберите 2 правильных варианта ответа.",
        answers: [
          "Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений",
          "Цикличный контроль за параметрами процесса, а также управление технологическим режимом для поддержания их регламентированных значений",
          "Периодический, не реже двух раз в смену, контроль за состоянием воздушной среды в пределах объекта",
          "Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации",
        ],
        correctAnswers: [
          "Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений",
          "Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации",
        ],
      },
      {
        code: "20112167",
        text: "Каких значений не должна превышать максимальная температура поверхностей нагрева систем отопления в помещениях, имеющих взрывопожароопасные зоны?",
        answers: [
          "85 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "80 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "95 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "90 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
        correctAnswers: [
          "80 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
      },
      {
        code: "20112168",
        text: "Каким образом осуществляется контроль за содержанием кислорода в горючем газе во всасывающих линиях компрессоров, работающих под разрежением? Укажите все правильные ответы.",
        answers: [
          "Места размещения пробоотборников и способы контроля определяются эксплуатирующей организацией",
          "Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения",
          "Периодический контроль за содержанием кислорода в горючем газе должен проводиться не реже двух раз в смену",
          "Определяются места размещения пробоотборников и способы контроля",
        ],
        correctAnswers: [
          "Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения",
          "Определяются места размещения пробоотборников и способы контроля",
        ],
      },
      {
        code: "20112169",
        text: "Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания противоаварийной автоматической защиты?",
        answers: [
          "Выполняется обслуживающим персоналом по инструкции",
          "Выполняется средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений",
          "Выполняется средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию противоаварийной автоматической защиты",
          "Выполняется средствами автоматического управления объектом по действующим программам",
        ],
        correctAnswers: ["Выполняется обслуживающим персоналом по инструкции"],
      },
      {
        code: "20112170",
        text: "Какое требование к первичному пуску компрессоров в работу после длительной остановки, ремонта, профилактики, а также после остановки компрессора при срабатывании приборов предаварийной защиты указано верно?",
        answers: [
          "При пуске компрессора с использованием встроенного байпаса нагнетательный вентиль компрессора должен быть закрыт, а клапан байпаса открыт, если это предусмотрено инструкцией организации-изготовителя",
          "Первичный пуск компрессора в работу необходимо выполнять вручную с закрытыми всасывающими клапанами в соответствии с инструкцией организации-изготовителя",
          "Перед пуском компрессора в работу следует убедиться, что все запорные клапаны на нагнетательном трубопроводе от компрессора до конденсатора открыты",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20112171",
        text: "В каком случае перегрев паров аммиака, всасываемых компрессором, должен быть не менее 10 К (°C)?",
        answers: [
          "Для одноступенчатых компрессоров высокого давления",
          "Для ступени высокого давления двухступенчатых компрессоров",
          "Для ступени низкого давления двухступенчатых компрессоров",
        ],
        correctAnswers: [
          "Для ступени низкого давления двухступенчатых компрессоров",
        ],
      },
      {
        code: "20112172",
        text: "При каком давлении во всасывающих коммуникациях компрессоров, сжимающих продукты разделения воздуха, данные компрессоры должны автоматически отключаться?",
        answers: [
          "При снижении давления ниже 1,5 кПа",
          "При снижении давления ниже 0,6 кПа",
          "При снижении давления ниже 1,0 кПа",
          "При снижении давления ниже 0,5 кПа",
        ],
        correctAnswers: ["При снижении давления ниже 0,5 кПа"],
      },
      {
        code: "20112173",
        text: "Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предотвращения аварий и предупреждения их развития?",
        answers: [
          "Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов",
          "Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы",
          "Запорная арматура, средства защиты от превышения давления, огнепреградители, исключая исполнительные машины и механизмы",
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20112174",
        text: "Какая максимальная температура нагнетания должна быть для поршневых компрессоров, если инструкцией организации-изготовителя не предусмотрено иное значение?",
        answers: ["135 °C", "160 °C", "90 °C", "185 °C"],
        correctAnswers: ["160 °C"],
      },
      {
        code: "20112175",
        text: "На каких кислородных центробежных компрессорах необходимо предусматривать его автоматическую остановку при снижении давления газа, подаваемого в лабиринтные уплотнения?",
        answers: [
          "С давлением нагнетания 0,5 МПа",
          "С давлением нагнетания свыше 0,6 МПа",
          "С давлением нагнетания свыше 1,6 МПа",
          "С давлением нагнетания 0,6 МПа",
        ],
        correctAnswers: ["С давлением нагнетания свыше 0,6 МПа"],
      },
      {
        code: "20112176",
        text: "Какими приборами могут быть оснащены сепараторы, устанавливаемые на всасывающей линии компрессора, вакуум-насоса, газодувки для отделения жидкой фазы из перемещаемой газовой среды?",
        answers: [
          "Только сигнализацией по максимальному уровню",
          "Всеми перечисленными",
          "Только приборами контроля уровня",
          "Только средствами блокировки",
        ],
        correctAnswers: ["Всеми перечисленными"],
      },
      {
        code: "20112177",
        text: "Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания противоаварийной защиты?",
        answers: [
          "Средствами автоматического управления объектом по действующим программам после устранения причин срабатывания противоаварийной защиты",
          "Средствами автоматического управления объектом по действующим программам после выполнения персоналом всех необходимых по инструкции переключений",
          "Средствами автоматического управления объектом по действующим программам управления",
          "Выполняется обслуживающим персоналом по инструкции",
        ],
        correctAnswers: ["Выполняется обслуживающим персоналом по инструкции"],
      },
      {
        code: "20112178",
        text: "Какими блокировками должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?",
        answers: [
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе, достижении опасных значений в приемной емкости",
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
          "Блокировками, исключающими пуск или прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях",
          "Блокировками, исключающими пуск или прекращающими работу при достижении опасных значений в расходной емкости",
        ],
        correctAnswers: [
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
      },
      {
        code: "20112179",
        text: "Каким образом осуществляется контроль за содержанием кислорода в горючем газе во всасывающих линиях компрессоров, работающих под разрежением? Выберите 2 правильных варианта ответа.",
        answers: [
          "Путем периодического мониторинга загазованности среды в рабочей зоне",
          "Контроль за содержанием кислорода в горючем газе должен проводиться не реже двух раз в смену",
          "Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения",
          "Проектной организацией определяются места размещения пробоотборников и способы контроля за содержанием кислорода в горючем газе",
        ],
        correctAnswers: [
          "Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения",
          "Проектной организацией определяются места размещения пробоотборников и способы контроля за содержанием кислорода в горючем газе",
        ],
      },
      {
        code: "20112180",
        text: "В соответствии с чем должны определяться оптимальные методы создания системы противоаварийной защиты на стадии формирования требований при проектировании автоматизированной системы управления технологическим процессом?",
        answers: [
          "В соответствии с методиками и программными продуктами, применяемыми для моделирования аварийных ситуаций, утвержденных (согласованных) контролирующими органами (Ростехнадзором)",
          "В соответствии с алгоритмами, разработанными по всем возможным сценариям развития аварий",
          "В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности",
          "В соответствии со сценариями возможных аварийных ситуаций, а также способах перевода объекта в безопасное состояние",
        ],
        correctAnswers: [
          "В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности",
        ],
      },
      {
        code: "20112181",
        text: "При достижении какой концентрации обращающихся веществ в воздухе анализаторных помещений, должно происходить автоматическое включение аварийной вентиляции?",
        answers: [
          "При 20 % НКПР",
          "При 18 % НКПР",
          "При 10 % НКПР",
          "При 15 % НКПР",
        ],
        correctAnswers: ["При 20 % НКПР"],
      },
      {
        code: "20112182",
        text: "Какие требования предъявляются к обозначению средств автоматики, используемых согласно Плану мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Средства автоматики должны быть обозначены на всех мнемосхемах структуры блокировок",
          "Все требования к обозначению определяются при разработке Плана мероприятий по локализации и ликвидации последствий аварий",
          "Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте на производство продукции и инструкциях",
          "Средства автоматики должны быть обозначены только в технологическом регламенте на производство выпускаемой продукции",
        ],
        correctAnswers: [
          "Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте на производство продукции и инструкциях",
        ],
      },
      {
        code: "20112183",
        text: "Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?",
        answers: [
          "Средствами автоматического управления объектом по действующим программам после выполнения обслуживающим персоналом всех необходимых по инструкции переключений",
          "Средствами автоматического управления объектом по действующим программам управления технологическим процессом",
          "Средствами автоматического управления объектом по действующим программам после устранения причин срабатывания системы противоаварийной защиты",
          "Обслуживающим персоналом по инструкции",
        ],
        correctAnswers: ["Обслуживающим персоналом по инструкции"],
      },
      {
        code: "20112184",
        text: "Какими блокировками на отключение должны быть оснащены насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?",
        answers: [
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
          "Блокировками, исключающими пуск и (или) прекращающими работу в случаях отклонений от опасных значений в расходной и приемной емкостях",
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой среды и приближении к опасным значениям рабочих параметров в расходной и приемной емкостях",
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости в насосе и достижении опасных значений в приемной емкости",
        ],
        correctAnswers: [
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
      },
      {
        code: "20112185",
        text: "Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития? Выберите 2 правильных варианта ответа.",
        answers: [
          "Системы активного подавления взрыва",
          "Средства, регистрирующие превышение давления в технологической системе",
          "Клапаны, отсекающие и другие отключающие устройства",
          "Запорную и запорно-регулирующую арматуру",
        ],
        correctAnswers: [
          "Клапаны, отсекающие и другие отключающие устройства",
          "Запорную и запорно-регулирующую арматуру",
        ],
      },
      {
        code: "20112186",
        text: "Что в химико-технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и их развития?",
        answers: [
          "Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва, а также системы аварийного опорожнения аппаратов",
          "Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы",
          "Запорная арматура, средства защиты от превышения давления и огнепреградители",
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие, а также другие отключающие устройства и предохранительные устройства от превышения давления",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие, а также другие отключающие устройства и предохранительные устройства от превышения давления",
        ],
      },
      {
        code: "20112187",
        text: "Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития?",
        answers: [
          "Запорную арматуру, средства защиты от превышения давления и огнепреградители",
          "Запорную арматуру, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва, а также системы аварийного опорожнения аппаратов",
          "Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
          "Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные машины и механизмы",
        ],
        correctAnswers: [
          "Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20112188",
        text: "Какие опасные производственные объекты должны оснащаться автоматическими и (или) автоматизированными системами управления, построенными на базе электронных средств контроля и автоматики, включая средства вычислительной техники?",
        answers: [
          "Имеющие в своем составе объекты с технологическими блоками только II категории взрывоопасности",
          "Имеющие в своем составе объекты с технологическими блоками I и II категорий взрывоопасности",
          "Имеющие в своем составе объекты с технологическими блоками только I категории взрывоопасности",
          "Имеющие в своем составе объекты с технологическими блоками только III категории взрывоопасности",
        ],
        correctAnswers: [
          "Имеющие в своем составе объекты с технологическими блоками I и II категорий взрывоопасности",
        ],
      },
      {
        code: "20112189",
        text: "При какой единичной производительности центробежных или поршневых компрессоров, работающих на один коллектор нагнетания кислорода, и давлении кислорода в трубопроводе нагнетания кислорода, устанавливают отключающую арматуру?",
        answers: [
          "Более 2100 м³/ч и свыше 1,8 МПа соответственно",
          "Более 2500 м³/ч и свыше 2,6 МПа соответственно",
          "Более 2200 м³/ч и свыше 2,0 МПа соответственно",
          "Более 2000 м³/ч и свыше 1,6 МПа соответственно",
        ],
        correctAnswers: ["Более 2000 м³/ч и свыше 1,6 МПа соответственно"],
      },
      {
        code: "20112190",
        text: "Какие из перечисленных функций должна обеспечивать автоматизированная система управления технологическими процессами на базе средств вычислительной техники? Укажите все правильные ответы.",
        answers: [
          "Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации",
          "Цикличный контроль за текущими параметрами процесса и управление режимом поддержания их регламентированных значений",
          "Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений",
          "Периодический, но не реже двух раз в смену, контроль за состоянием воздушной среды в пределах объекта",
        ],
        correctAnswers: [
          "Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации",
          "Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений",
        ],
      },
      {
        code: "20112191",
        text: "Что должно проектироваться и выбираться с учетом анализа возможных рисков образования взрывоопасных веществ вследствие взаимного проникновения и взаимодействия теплоносителя с технологической средой для того, чтобы предотвратить возможность возникновения аварийных ситуаций?",
        answers: [
          "Только теплообменное оборудование",
          "Только теплообменные процессы",
          "Только тип теплоносителя (хладагента)",
          "Теплообменные процессы и теплообменное оборудование",
        ],
        correctAnswers: ["Теплообменные процессы и теплообменное оборудование"],
      },
      {
        code: "20112192",
        text: "Что должны обеспечивать системы противоаварийной автоматической защиты и управления технологическими процессами? Выберите 2 правильных варианта ответа.",
        answers: [
          "Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса",
          "Время срабатывания систем защиты должно быть необходимым для перехода от предупредительного параметра до предельно допустимого значения",
          "Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления",
          "Возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной автоматической защиты должен выполняться автоматически после устранения причины срабатывания",
        ],
        correctAnswers: [
          "Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса",
          "Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления",
        ],
      },
      {
        code: "20112193",
        text: "Какими блокировками должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей?",
        answers: [
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе, а также достижении опасных значений в приемной емкости",
          "Блокировками, исключающими пуск или прекращающими работу насоса при отклонениях от опасных значений в расходной и приемной емкостях",
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях",
        ],
        correctAnswers: [
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
      },
      {
        code: "20112194",
        text: "Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?",
        answers: [
          "Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва, а также системы для аварийного опорожнения аппаратов",
          "Запорная арматура, средства защиты от превышения давления и огнепреградители",
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
          "Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные машины и механизмы",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20112195",
        text: "Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей? Выберите 2 правильных варианта ответа.",
        answers: [
          "Системой ручного управления подачей водяного пара в топочное пространство и змеевики при прогаре труб",
          "Блокировками, прекращающими поступление газообразного топлива и воздуха при снижении их давления ниже установленных параметров (автономно), а также при прекращении электро- (пневмо-) снабжения контрольно-измерительных приборов и средств автоматики",
          "Системой контроля за температурой в топочном пространстве",
          "Системами регулирования заданного соотношения топлива, воздуха и водяного пара",
        ],
        correctAnswers: [
          "Блокировками, прекращающими поступление газообразного топлива и воздуха при снижении их давления ниже установленных параметров (автономно), а также при прекращении электро- (пневмо-) снабжения контрольно-измерительных приборов и средств автоматики",
          "Системами регулирования заданного соотношения топлива, воздуха и водяного пара",
        ],
      },
      {
        code: "20112196",
        text: "Какими функциями должна обладать противоаварийная автоматическая защита нагреваемых элементов (змеевиков) нагревательных печей? Укажите все правильные ответы.",
        answers: [
          "Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков",
          "Средствами контроля и сигнализации за температурой нагреваемого жидкого рабочего продукта",
          "Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции",
          "Блокировками по отключению подачи топлива при превышении максимально допустимого значения температуры нагреваемого жидкого продукта",
        ],
        correctAnswers: [
          "Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков",
          "Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции",
        ],
      },
      {
        code: "20112197",
        text: "Какими приборами и средствами автоматизации должны оснащаться сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Укажите все правильные ответы.",
        answers: [
          "Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации",
          "Блокировками отключения компрессора при превышении предельно допустимого значения уровня",
          "Приборами контроля температуры и плотности отделяемой жидкой фазы рабочего продукта",
          "Приборами измерения перепада рабочего давления на сепараторе",
        ],
        correctAnswers: [
          "Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации",
          "Блокировками отключения компрессора при превышении предельно допустимого значения уровня",
        ],
      },
      {
        code: "20112198",
        text: "В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечить способность функционирования средств противоаварийной защиты?",
        answers: [
          "В течение времени, устанавливаемого в проектной документации",
          "В течение 8 часов",
          "В течение 6 часов",
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
        correctAnswers: [
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
      },
      {
        code: "20112199",
        text: "В каких случаях должны автоматически включаться системы аварийной вентиляции? Укажите все правильные ответы.",
        answers: [
          "При срабатывании сигнализаторов довзрывных концентраций вредных веществ",
          "При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
          "При поступлении сигнала от датчиков контроля за содержанием взрывопожароопасной пыли в воздухе производственного помещения",
          "При поступлении сигнала от датчиков повышения температуры воздуха возле технической линии",
        ],
        correctAnswers: [
          "При срабатывании сигнализаторов довзрывных концентраций вредных веществ",
          "При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
        ],
      },
      {
        code: "20112200",
        text: "Какое требование не соответствует предъявляемым требованиям к специальным системам аварийного освобождения при проектировании технологических схем для новых производств для аварийного освобождения технологических блоков от обращающихся продуктов?",
        answers: [
          "Специальные системы аварийного освобождения должны находиться в постоянной готовности и исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий",
          "Специальные системы аварийного освобождения должны находиться в постоянной готовности и оснащаться средствами контроля и управления",
          "Специальные системы аварийного освобождения должны находиться в постоянной готовности и обеспечивать минимально возможное время освобождения",
          "Специальные системы аварийного освобождения могут использоваться для других целей",
        ],
        correctAnswers: [
          "Специальные системы аварийного освобождения могут использоваться для других целей",
        ],
      },
      {
        code: "20112201",
        text: "Когда должно происходить автоматическое включение аварийной вентиляции, установленной в анализаторных помещениях?",
        answers: [
          "Если концентрация обращающихся веществ в воздухе помещения достигает 20 % НКПР",
          "Если концентрация обращающихся веществ в воздухе помещения достигает 15 % НКПР",
          "Если концентрация обращающихся веществ в воздухе помещения достигает 10 % НКПР",
          "Если концентрация обращающихся веществ в воздухе помещения достигает 18 % НКПР",
        ],
        correctAnswers: [
          "Если концентрация обращающихся веществ в воздухе помещения достигает 20 % НКПР",
        ],
      },
      {
        code: "20112202",
        text: "Каким образом обеспечивается надежность обеспечения средств управления и противоаварийной автоматической защиты сжатым воздухом?",
        answers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты в течение 1 часа",
          "Установкой резервного компрессора с включением его автоматически при остановке рабочего компрессора",
          "Переключением сети воздуха контрольно-измерительных приборов и автоматики на заводскую сеть сжатого воздуха через осушитель воздуха",
        ],
        correctAnswers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20112203",
        text: "Что должно быть учтено при проектировании программного обеспечения противоаварийной защиты технологической аппаратуры реакционных процессов?",
        answers: [
          "Указания технического руководителя (главного инженера) эксплуатирующей организации",
          "Требования организации - изготовителя реакционной аппаратуры для эксплуатируемого объекта",
          "Требования органов Ростехнадзора",
          "Риски срабатывания автоматических систем противоаварийной защиты",
        ],
        correctAnswers: [
          "Риски срабатывания автоматических систем противоаварийной защиты",
        ],
      },
      {
        code: "20112204",
        text: "Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития? Укажите все правильные ответы.",
        answers: [
          "Запорную и запорно-регулирующую арматуру",
          "Системы аварийной вытяжной вентиляции производственного помещения",
          "Клапаны, отсекающие и другие отключающие устройства",
          "Средства, регистрирующие повышение давления рабочей среды",
        ],
        correctAnswers: [
          "Запорную и запорно-регулирующую арматуру",
          "Клапаны, отсекающие и другие отключающие устройства",
        ],
      },
      {
        code: "20112205",
        text: "Какая максимальная температура поверхностей нагрева систем отопления должна быть в помещениях, имеющих взрывоопасные зоны?",
        answers: [
          "Максимальная температура не должна превышать 85 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 90 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 80 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 95 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
        correctAnswers: [
          "Максимальная температура не должна превышать 80 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
      },
      {
        code: "20112206",
        text: "В технологических блоках какой категории взрывоопасности должны быть предусмотрены технические средства, обеспечивающие в автоматическом режиме оповещение об обнаружении, локализации и ликвидации выбросов опасных веществ?",
        answers: [
          "Только в технологических блоках I категории взрывоопасности",
          "Только в технологических блоках II категории взрывоопасности",
          "Только в технологических блоках III категории взрывоопасности",
          "В технологических блоках всех категорий взрывоопасности",
        ],
        correctAnswers: [
          "В технологических блоках всех категорий взрывоопасности",
        ],
      },
      {
        code: "20112207",
        text: "Что должны обеспечивать системы противоаварийной автоматической защиты и управления технологическими процессами? Укажите все правильные ответы.",
        answers: [
          "Отключение систем в случае переключений на резервный или аварийный источник электропитания не позднее 0,5 с",
          "Возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной автоматической защиты должен выполняться вручную или автоматически после установления причины срабатывания",
          "Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса",
          "Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления",
        ],
        correctAnswers: [
          "Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса",
          "Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления",
        ],
      },
      {
        code: "20112208",
        text: "При достижении какого уровня заполнения газгольдера автоматически производится отключение компрессоров (газодувок), подключенных к газгольдеру?",
        answers: [
          "Предмаксимального",
          "Минимального",
          "Максимального",
          "Предминимального",
        ],
        correctAnswers: ["Минимального"],
      },
      {
        code: "20112209",
        text: "Как производится включение компрессора, отключенного из-за прекращения подачи охлаждающей воды?",
        answers: [
          "Только после его охлаждения и возобновления подачи воды",
          "Только после проведения отбора проб для анализа газа и последующего возобновления подачи воды",
          "Только после его охлаждения",
          "Только после возобновления подачи воды",
        ],
        correctAnswers: [
          "Только после его охлаждения и возобновления подачи воды",
        ],
      },
      {
        code: "20112210",
        text: "Какой должна быть температура наружных поверхностей оборудования и кожухов теплоизоляционных покрытий в местах, доступных для обслуживающего персонала?",
        answers: [
          "Не более 35 °С внутри помещений и 65 °С на наружных установках",
          "Не более 50 °С внутри помещений и 55 °С на наружных установках",
          "Не более 40 °С внутри помещений и 70 °С на наружных установках",
          "Не более 45 °С внутри помещений и 60 °С на наружных установках",
        ],
        correctAnswers: [
          "Не более 45 °С внутри помещений и 60 °С на наружных установках",
        ],
      },
      {
        code: "20112211",
        text: "Чем обеспечивается противоаварийная автоматическая защита нагреваемых элементов (змеевиков) нагревательных печей? Выберите 2 правильных варианта ответа.",
        answers: [
          "Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков",
          "Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции",
          "Средствами контроля за температурой нагреваемого жидкого продукта",
          "Блокировками по отключению подачи топлива при превышении критического значения температуры нагреваемого жидкого продукта",
        ],
        correctAnswers: [
          "Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков",
          "Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции",
        ],
      },
      {
        code: "20112212",
        text: "Что в технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?",
        answers: [
          "Все устройства, задействованные в системе противоаварийной автоматической защиты, включая исполнительные машины и механизмы",
          "Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва, а также системы для аварийного опорожнения аппаратов",
          "Запорная арматура, средства защиты от превышения давления и огнепреградители",
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20112213",
        text: "Какая аппаратура оснащается средствами автоматического контроля, регулирования и сигнализации уровня хладагента в теплообменных элементах?",
        answers: [
          "Аппаратура для ведения жидкофазных технологических процессов",
          "Реакционная аппаратура, в которой отвод избыточного тепла реакции при теплопередаче через стенку осуществляется за счет испарения охлаждающей жидкости",
          "Реакционные аппараты взрывоопасных технологических процессов с перемешивающими устройствами рабочей среды",
        ],
        correctAnswers: [
          "Реакционная аппаратура, в которой отвод избыточного тепла реакции при теплопередаче через стенку осуществляется за счет испарения охлаждающей жидкости",
        ],
      },
      {
        code: "20112214",
        text: "Чем должны оснащаться насосы и компрессоры технологических блоков взрывопожароопасных производств, остановка которых при падении напряжения или кратковременном отключении электроэнергии может привести к отклонениям технологических параметров процесса до критических значений и развитию аварий?",
        answers: [
          "Системами самозапуска электродвигателей",
          "Резервной автономной электрической подстанцией",
          "Устройствами для сбора и удаления жидкой рабочей фазы",
          "Автоматическими блокировками",
        ],
        correctAnswers: ["Системами самозапуска электродвигателей"],
      },
      {
        code: "20112215",
        text: "При каких условиях допускается отключение защит (единовременно не более одного параметра) для непрерывных процессов?",
        answers: [
          "При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами",
          "В присутствии начальника производства и начальника службы КИПиА (главного прибориста) только в дневную смену при наличии утвержденных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ",
          "По устному разрешению технического руководителя (главного инженера) организации только в дневную смену при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, и в присутствии начальника производства",
        ],
        correctAnswers: [
          "При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами",
        ],
      },
    ],

    20113: [
      {
        code: "20113000",
        text: "В течение какого минимального времени буферные емкости (реципиенты) должны обеспечивать питание сжатым воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров?",
        answers: ["30 минут", "15 минут", "1 часа", "2 часов"],
        correctAnswers: ["1 часа"],
      },
      {
        code: "20113001",
        text: "На каких кислородопроводах необходимо устанавливать переключающиеся фильтры перед их подключением к коллектору всасывания кислородных компрессоров?",
        answers: [
          "На кислородопроводах длиной более 150 м, изготовленных из углеродистых сталей",
          "На кислородопроводах длиной более 200 м, изготовленных из меди и сплавов на основе меди",
          "На кислородопроводах длиной более 250 м, изготовленных из углеродистых сталей",
          "На кислородопроводах длиной более 300 м, изготовленных из сплавов алюминия",
        ],
        correctAnswers: [
          "На кислородопроводах длиной более 250 м, изготовленных из углеродистых сталей",
        ],
      },
      {
        code: "20113002",
        text: "Чем определяется количество насосов и компрессоров, используемых для перемещения химически опасных веществ в технологическом процессе?",
        answers: [
          "Параметрами технологического процесса",
          "Химическими свойствами перемещаемых продуктов",
          "Необходимостью и условиями обеспечения непрерывности технологического процесса",
          "Характеристиками применяемых насосов и компрессоров",
        ],
        correctAnswers: [
          "Необходимостью и условиями обеспечения непрерывности технологического процесса",
        ],
      },
      {
        code: "20113003",
        text: "Каким образом осуществляется контроль за содержанием кислорода в горючем газе во всасывающих линиях компрессоров, работающих под разрежением? Выберите два правильных варианта ответа.",
        answers: [
          "Проектной организацией определяются места размещения пробоотборников и способы контроля за содержанием кислорода в горючем газе",
          "Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения",
          "При остановке компрессора во всасывающие линии насоса закачивается воздух",
          "Периодический контроль за содержанием кислорода в горючем газе должен проводиться не реже 1 раза в смену",
        ],
        correctAnswers: [
          "Проектной организацией определяются места размещения пробоотборников и способы контроля за содержанием кислорода в горючем газе",
          "Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения",
        ],
      },
      {
        code: "20113004",
        text: "Каким образом осуществляется контроль за содержанием кислорода в горючем газе во всасывающих линиях компрессоров, работающих под разрежением? Укажите 2 правильных варианта ответа.",
        answers: [
          "Периодический контроль за содержанием кислорода в горючем газе должен проводиться не реже 1 раза в смену",
          "При остановке компрессора во всасывающие линии насоса закачивается воздух",
          "Определяются места размещения пробоотборников и способы контроля",
          "Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения",
        ],
        correctAnswers: [
          "Определяются места размещения пробоотборников и способы контроля",
          "Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения",
        ],
      },
      {
        code: "20113005",
        text: "Каким образом определяются методы и сроки очистки водяных полостей холодильников и рубашек цилиндров компрессоров от отложений?",
        answers: [
          "Правилами безопасности химически опасных производственных объектов",
          "Технологическим регламентом, технологическими инструкциями",
          "Устанавливаются техническим руководителем эксплуатирующей организации",
          "Устанавливаются сторонней специализированной организацией",
        ],
        correctAnswers: [
          "Технологическим регламентом, технологическими инструкциями",
        ],
      },
      {
        code: "20113006",
        text: "Какие требования предъявляются к частоте продувки влагомаслоотделителей поршневых компрессоров при отсутствии автоматической продувки?",
        answers: [
          "Продувка должна производиться через каждые 60 минут",
          "Продувка должна производиться через каждые 40 минут",
          "Продувка должна производиться через каждые 20 минут",
          "Продувка должна производиться через каждые 30 минут",
        ],
        correctAnswers: ["Продувка должна производиться через каждые 30 минут"],
      },
      {
        code: "20113007",
        text: "Какая максимальная температура нагнетания должна быть для поршневых компрессоров, если инструкцией организации-изготовителя не предусмотрено иное значение?",
        answers: ["90 °C", "160 °C", "185 °C", "135 °C"],
        correctAnswers: ["160 °C"],
      },
      {
        code: "20113008",
        text: "Что из перечисленного должно быть учтено при выборе компрессоров и насосов для перемещения горючих, сжатых и сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?",
        answers: [
          "Только физико-химические свойства перемещаемых продуктов",
          "Только параметры технологического процесса",
          "Только показатели надежности и конструктивные особенности с учетом критических параметров",
          "Должны быть учтены все перечисленные характеристики",
        ],
        correctAnswers: ["Должны быть учтены все перечисленные характеристики"],
      },
      {
        code: "20113009",
        text: "С какой периодичностью необходимо проверять исправность автоматических приборов защиты аммиачных компрессоров и сигнализаторов концентрации паров аммиака в воздухе помещений и наружных площадок?",
        answers: [
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 10 дней",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20113010",
        text: "Какое требование к первичному пуску компрессоров в работу после длительной остановки, ремонта, профилактики, а также после остановки компрессора при срабатывании приборов предаварийной защиты указано верно?",
        answers: [
          "Первичный пуск компрессора в работу необходимо выполнять вручную с закрытыми всасывающими клапанами в соответствии с инструкцией организации-изготовителя",
          "При пуске компрессора с использованием встроенного байпаса нагнетательный вентиль компрессора должен быть закрыт, а клапан байпаса открыт, если это предусмотрено инструкцией организации-изготовителя",
          "Перед пуском компрессора в работу следует убедиться, что все запорные клапаны на нагнетательном трубопроводе от компрессора до конденсатора открыты",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20113011",
        text: "С учетом каких критериев выбираются насосы и компрессоры, используемые для перемещения газов, легковоспламеняющихся и горючих жидкостей, согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств? Выберите 2 правильных варианта ответа.",
        answers: [
          "С учетом регламентированных параметров технологического процесса",
          "С учетом диаметра и толщины стенки трубопроводов",
          "С учетом физико-химических свойств перемещаемых продуктов",
        ],
        correctAnswers: [
          "С учетом регламентированных параметров технологического процесса",
          "С учетом физико-химических свойств перемещаемых продуктов",
        ],
      },
      {
        code: "20113012",
        text: "Какие требования к эксплуатации поршневых компрессоров указаны неверно?",
        answers: [
          "Не допускается попадание машинного масла в цилиндры компрессора",
          "Необходимо использовать масла, рекомендованные разработчиками компрессора, для смазки поршневой группы компрессоров, подающих воздух в воздухоразделительные установки",
          "Не допускается наличие нагара в клапанных коробках и трубопроводах поршневых компрессоров",
          "Допускается применять поршневые бескрейцкопфные компрессоры для подачи воздуха на разделение и для сжатия продуктов разделения воздуха",
          "Не допускается использовать для смазки поршневой группы компрессоров масло, извлеченное из масловлагоотделителей",
        ],
        correctAnswers: [
          "Допускается применять поршневые бескрейцкопфные компрессоры для подачи воздуха на разделение и для сжатия продуктов разделения воздуха",
        ],
      },
      {
        code: "20113013",
        text: "Что не соответствует установленным требованиям безопасности при обслуживании компрессора?",
        answers: [
          "Работы в камере перед фильтром при работающем фильтре и компрессоре необходимо выполнять бригадой не менее чем из трех человек, один из которых является наблюдающим",
          "Камеры, расположенные по ходу воздуха перед фильтром и после него, должны быть снабжены знаками безопасности, запрещающими вход в камеры",
          "Во время работы воздушного центробежного компрессора и воздушного фильтра вход обслуживающего персонала в помещение камеры после воздушного фильтра (камера чистого воздуха) не допускается",
          "Камеры, расположенные по ходу воздуха перед фильтром и после него, должны быть закрыты",
        ],
        correctAnswers: [
          "Работы в камере перед фильтром при работающем фильтре и компрессоре необходимо выполнять бригадой не менее чем из трех человек, один из которых является наблюдающим",
        ],
      },
      {
        code: "20113014",
        text: "В зависимости от чего осуществляется выбор конструкции и конструкционных материалов, уплотнительных устройств для насосов и компрессоров?",
        answers: [
          "В зависимости от свойств перемещаемой среды",
          "В зависимости от вида взрывозащиты",
          "В зависимости от протяженности трубопровода",
          "В зависимости от всех перечисленных факторов",
        ],
        correctAnswers: ["В зависимости от свойств перемещаемой среды"],
      },
      {
        code: "20113015",
        text: "При достижении какого уровня заполнения газгольдера автоматически производится отключение компрессоров (газодувок), подключенных к газгольдеру?",
        answers: [
          "Максимального",
          "Минимального",
          "Установленного заводом-изготовителем",
          "При достижении 0,9 величины полного объема газгольдера",
        ],
        correctAnswers: ["Минимального"],
      },
      {
        code: "20113016",
        text: "В течение какого времени буферные емкости (реципиенты) должны обеспечивать питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров?",
        answers: [
          "Время должно быть подтверждено расчетом, но не менее 30 минут",
          "Время должно быть подтверждено расчетом, но не более 45 минут",
          "В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
          "Время должно быть подтверждено расчетом, но не менее 35 минут",
        ],
        correctAnswers: [
          "В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20113017",
        text: "Что должно быть учтено при выборе компрессоров и насосов для перемещения горючих, сжатых и сжиженных газов, легковоспламеняющихся жидкостей и горючих жидкостей?",
        answers: [
          "Показатели надежности, конструктивные особенности с учетом физико-химических свойств перемещаемых продуктов и регламентированные параметры технологического процесса",
          "Только показатели надежности и конструктивные особенности",
          "Показатели надежности, конструктивные особенности и параметры работы",
          "Показатели надежности, конструктивные особенности с учетом физико-химических свойств перемещаемых продуктов, исполнение по взрывозащите",
        ],
        correctAnswers: [
          "Показатели надежности, конструктивные особенности с учетом физико-химических свойств перемещаемых продуктов и регламентированные параметры технологического процесса",
        ],
      },
      {
        code: "20113018",
        text: "При каком давлении во всасывающих коммуникациях компрессоров, сжимающих продукты разделения воздуха, данные компрессоры должны автоматически отключаться?",
        answers: [
          "При снижении давления ниже 1,5 кПа",
          "При снижении давления ниже 1,0 кПа",
          "При снижении давления ниже 0,5 кПа",
          "При снижении давления ниже 0,6 кПа",
        ],
        correctAnswers: ["При снижении давления ниже 0,5 кПа"],
      },
      {
        code: "20113019",
        text: "На каких кислородных центробежных компрессорах необходимо предусматривать стационарные устройства, позволяющие производить отбор проб для анализа газа, выходящего из лабиринтных уплотнений компрессора?",
        answers: [
          "На любом из компрессоров с давлением нагнетания 0,6 МПа",
          "На каждом компрессоре с давлением нагнетания более 0,6 МПа",
          "На одном из компрессоров с давлением нагнетания более 0,6 МПа",
          "На каждом компрессоре с давлением нагнетания 0,5 МПа",
        ],
        correctAnswers: [
          "На каждом компрессоре с давлением нагнетания более 0,6 МПа",
        ],
      },
      {
        code: "20113020",
        text: "Каким образом обеспечивается надежность обеспечения средств управления и противоаварийной защиты сжатым воздухом?",
        answers: [
          "Установкой резервного компрессора с автоматическим включением при остановке рабочего",
          "Переключением через осушитель сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования на заводскую сеть сжатого воздуха",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение часа",
        ],
        correctAnswers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20113021",
        text: "В каком случае допускается подключение азотных компрессоров к вводно-распределительным устройствам (ВРУ) без газгольдеров?",
        answers: [
          "Если подключение производится через обратный клапан",
          "Если подключение производится в соответствии с проектной документацией",
          "При наличии автоматических устройств и блокировок, исключающих увеличение отбора азота",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "При наличии автоматических устройств и блокировок, исключающих увеличение отбора азота",
        ],
      },
      {
        code: "20113022",
        text: "В каком случае перегрев паров аммиака, всасываемых компрессором, должен быть не менее 10   °К (°C)?",
        answers: [
          "Для ступени низкого давления двухступенчатых компрессоров",
          "Для одноступенчатых компрессоров",
          "Для ступени высокого давления двухступенчатых компрессоров",
          "В любом случае",
        ],
        correctAnswers: [
          "Для ступени низкого давления двухступенчатых компрессоров",
        ],
      },
      {
        code: "20113023",
        text: "Что необходимо применять для смазки цилиндров поршневых кислородных компрессоров?",
        answers: [
          "Дистиллят, полученный из воды питьевого качества",
          "Конденсат из заводской системы пароснабжения",
          "Смазочные масла",
          "Умягченную воду из заводской системы пароснабжения",
        ],
        correctAnswers: ["Дистиллят, полученный из воды питьевого качества"],
      },
      {
        code: "20113024",
        text: "При какой единичной производительности центробежных или поршневых компрессоров, работающих на один коллектор нагнетания кислорода, и давлении кислорода в трубопроводе нагнетания кислорода, устанавливают отключающую арматуру?",
        answers: [
          "Более 2100 м3/ч и свыше 1,8 МПа соответственно",
          "Более 2000 м3/ч и свыше 1,6 МПа соответственно",
          "Более 2200 м3/ч и свыше 2,0 МПа соответственно",
          "Более 2500 м3/ч и свыше 2,6 МПа соответственно",
        ],
        correctAnswers: ["Более 2000 м3/ч и свыше 1,6 МПа соответственно"],
      },
      {
        code: "20113025",
        text: "Какие условия должны выполняться для допуска к эксплуатации компрессорных установок?",
        answers: [
          "Наличие разрешения на применения технических устройств, выдаваемого органами Ростехнадзора",
          "Наличие и исправное состояние средств автоматизации, контроля и системы блокировок",
          "Наличие положительного заключения экспертизы промышленной безопасности",
          "Наличие письменного распоряжения руководителя эксплуатирующей организации",
        ],
        correctAnswers: [
          "Наличие и исправное состояние средств автоматизации, контроля и системы блокировок",
        ],
      },
      {
        code: "20113026",
        text: "На каких кислородных центробежных компрессорах необходимо предусматривать его автоматическую остановку при снижении давления газа, подаваемого в лабиринтные уплотнения?",
        answers: [
          "С давлением нагнетания 0,5 МПа",
          "С давлением нагнетания свыше 0,6 МПа",
          "С давлением нагнетания 0,4 МПа",
          "С давлением нагнетания свыше 0,8 МПа",
        ],
        correctAnswers: ["С давлением нагнетания свыше 0,6 МПа"],
      },
      {
        code: "20113027",
        text: "Каким требованиям должны соответствовать насосы и компрессоры технологических блоков взрывопожароопасных производств, остановка которых при падении напряжения или кратковременном отключении электроэнергии может привести к отклонениям технологических параметров процесса до критических значений и развитию аварий?",
        answers: [
          "Насосы и компрессоры должны выбираться с учетом возможности их повторного автоматического пуска",
          "Время срабатывания системы самозапуска должно быть больше времени выхода параметров технологического процесса за предельно допустимые значения",
          "Токи самозапуска электродвигателей не должны превышать номинальные более чем в 3 раза",
          "Насосы и компрессоры должны оснащаться устройствами для облегчения повторного самозапуска электродвигателей",
        ],
        correctAnswers: [
          "Насосы и компрессоры должны выбираться с учетом возможности их повторного автоматического пуска",
        ],
      },
      {
        code: "20113028",
        text: "Какими из перечисленных приборов могут быть оснащены сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора?",
        answers: [
          "Только средствами блокировки",
          "Только сигнализацией по максимальному уровню",
          "Только приборами контроля уровня",
          "Всеми перечисленными приборами",
        ],
        correctAnswers: ["Всеми перечисленными приборами"],
      },
      {
        code: "20113029",
        text: "Чем должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей? Выберите 2 правильных варианта ответа.",
        answers: [
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
          "Средствами предупредительной сигнализации при достижении опасных значений параметров в приемных и расходных емкостях",
          "Средствами контроля температуры перемещаемой жидкости",
          "Системами измерения плотности и вязкости перекачиваемых сред",
        ],
        correctAnswers: [
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
          "Средствами предупредительной сигнализации при достижении опасных значений параметров в приемных и расходных емкостях",
        ],
      },
      {
        code: "20113030",
        text: "В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?",
        answers: [
          "В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования",
          "При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости",
          "При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости",
          "При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях",
        ],
        correctAnswers: [
          "В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования",
        ],
      },
      {
        code: "20113031",
        text: "Какими приборами и средствами автоматизации должны оснащаться сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Выберите 2 правильных варианта ответа.",
        answers: [
          "Блокировками отключения компрессора при превышении предельно допустимого значения уровня",
          "Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации",
          "Приборами контроля температуры и плотности отделяемой жидкой фазы",
          "Приборами измерения перепада давления на сепараторе",
        ],
        correctAnswers: [
          "Блокировками отключения компрессора при превышении предельно допустимого значения уровня",
          "Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации",
        ],
      },
      {
        code: "20113032",
        text: "Какими блокировками должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей?",
        answers: [
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
          "Блокировками, исключающими пуск или прекращающими работу насоса при отклонениях от опасных значений в расходной и приемной емкостях",
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе, достижении опасных значений в приемной емкости",
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях",
        ],
        correctAnswers: [
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
      },
      {
        code: "20113033",
        text: "Чем должны оснащаться насосы и компрессоры технологических блоков взрывопожароопасных производств, остановка которых при падении напряжения или кратковременном отключении электроэнергии может привести к отклонениям технологических параметров процесса до критических значений и развитию аварий?",
        answers: [
          "Устройствами для автоматического отключения",
          "Системами самозапуска электродвигателей",
          "Защитой от замыканий",
          "Датчиками контроля температуры перекачиваемой жидкости",
        ],
        correctAnswers: ["Системами самозапуска электродвигателей"],
      },
      {
        code: "20113034",
        text: "Какими приборами могут быть оснащены сепараторы, устанавливаемые на всасывающей линии компрессора, вакуум-насоса, газодувки для отделения жидкой фазы из перемещаемой газовой среды?",
        answers: [
          "Только приборами контроля уровня",
          "Только сигнализацией по максимальному уровню",
          "Только средствами блокировки",
          "Всеми перечисленными",
        ],
        correctAnswers: ["Всеми перечисленными"],
      },
      {
        code: "20113035",
        text: "Какими блокировками на отключение должны быть оснащены насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?",
        answers: [
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
          "Блокировками, исключающими пуск и (или) прекращающими работу насоса при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях",
          "Блокировками, исключающими пуск и (или) прекращающими работу насоса при отклонениях от опасных значений в расходной и приемной емкостях",
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости в корпусе насоса, достижении опасных значений в приемной емкости",
        ],
        correctAnswers: [
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
      },
      {
        code: "20113036",
        text: "Как производится включение компрессора, отключенного из-за прекращения подачи охлаждающей воды?",
        answers: [
          "Только после возобновления подачи воды",
          "Только после его охлаждения",
          "Не регламентируется",
          "Только после его охлаждения и возобновления подачи воды",
        ],
        correctAnswers: [
          "Только после его охлаждения и возобновления подачи воды",
        ],
      },
      {
        code: "20113037",
        text: "Какими блокировками должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?",
        answers: [
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
          "Блокировками, исключающими пуск или прекращающими работу насоса при отклонениях от опасных значений в расходной и приемной емкостях",
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе, достижении опасных значений в приемной емкости",
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях",
        ],
        correctAnswers: [
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
      },
      {
        code: "20113038",
        text: "Какую температуру должна иметь вода для охлаждения компрессора на входе и на выходе из рубашек цилиндров, если заводом-изготовителем не предусмотрены другие предельные значения?",
        answers: [
          "Не ниже 20 °C и не более 50 °C соответственно",
          "Не ниже 5 °C и не более 35 °C соответственно",
          "Не ниже 10 °C и не более 45 °C соответственно",
          "Не ниже 15 °C и не более 40 °C соответственно",
        ],
        correctAnswers: ["Не ниже 10 °C и не более 45 °C соответственно"],
      },
      {
        code: "20113039",
        text: "Каким должно быть содержание масла в кислороде, поступающем в компрессоры и газодувки?",
        answers: [
          "Содержание масла не должно превышать 0,1 мг/м3",
          "Содержание масла не должно превышать 0,04 мг/м3",
          "Содержание масла не должно превышать 0,02 мг/м3",
          "Содержание масла не должно превышать 0,2 мг/м3",
        ],
        correctAnswers: ["Содержание масла не должно превышать 0,02 мг/м3"],
      },
      {
        code: "20113040",
        text: "В каком случае допускается размещать холодильное оборудование над площадками открытых насосных и компрессорных установок? Выберите 2 правильных варианта ответа.",
        answers: [
          "В случае размещения холодильного оборудования над площадками на расстоянии, определенном проектной документацией",
          "В случае применения герметичных (бессальниковых) насосов",
          "При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "В случае применения герметичных (бессальниковых) насосов",
          "При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование",
        ],
      },
      {
        code: "20113041",
        text: "С учетом чего должна проектироваться, изготавливаться и эксплуатироваться система транспорта сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей посредством насосов?",
        answers: [
          "С учетом анализа эксплуатационных отказов для того, чтобы предотвратить возможность возникновения аварийных режимов",
          "С учетом возможности проведения очистки системы",
          "С учетом химического анализа веществ на содержание кислорода",
        ],
        correctAnswers: [
          "С учетом анализа эксплуатационных отказов для того, чтобы предотвратить возможность возникновения аварийных режимов",
        ],
      },
      {
        code: "20113042",
        text: "С какой периодичностью предохранительные устройства компрессорных агрегатов должны проверяться на давление срабатывания?",
        answers: [
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 10 лет",
          "Не реже 1 раза в 5 лет",
          "Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией",
        ],
        correctAnswers: [
          "Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией",
        ],
      },
      {
        code: "20113043",
        text: "Каким образом обеспечивается отработка персоналом практических навыков безопасного выполнения работ, предупреждения аварий и ликвидации их последствий на технологических объектах с блоками I и II категорий взрывоопасности?",
        answers: [
          "Посредством обучения в собственных специализированных центрах обучения и подготовки производственного персонала",
          "Посредством прохождения курса подготовки с использованием современных технических средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)",
          "Посредством изучения наглядных пособий (плакатов) по охране труда",
          "Посредством допуска персонала к самостоятельной работе не ранее чем через 6 месяцев после стажировки на объекте",
        ],
        correctAnswers: [
          "Посредством прохождения курса подготовки с использованием современных технических средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)",
        ],
      },
      {
        code: "20113044",
        text: "С какими подразделениями должна быть оборудована система двусторонней громкоговорящей связи для объектов с технологическими блоками I категории взрывоопасности?",
        answers: [
          "Только со складами и насосными горючих, сжиженных и вредных продуктов",
          "Только с персоналом диспетчерских пунктов, газоспасательной службой и штабом гражданской обороны",
          "С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью и технологически связанными производственными участками",
          "Только с пожарной частью и сливоналивными пунктами",
        ],
        correctAnswers: [
          "С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью и технологически связанными производственными участками",
        ],
      },
      {
        code: "20113045",
        text: "С учетом каких параметров в каждом конкретном случае в проектной документации (документации на техническое перевооружение) обосновывается решение о типе арматуры и месте ее установки на линиях всасывания и нагнетания, а также способе ее отключения, в том числе дистанционном? Выберите 2 правильных варианта ответа.",
        answers: [
          "С учетом диаметра и протяженности трубопровода",
          "С учетом марки стали трубопровода",
          "С учетом характеристики транспортируемой среды",
          "С учетом толщины стенки трубопровода",
        ],
        correctAnswers: [
          "С учетом диаметра и протяженности трубопровода",
          "С учетом характеристики транспортируемой среды",
        ],
      },
      {
        code: "20113046",
        text: "Где предусматривается установка постов управления и технических средств связи и оповещения для извещения об опасных выбросах химически опасных веществ на объектах, имеющих в своем составе блоки I категории взрывоопасности? Выберите 2 правильных варианта ответа.",
        answers: [
          "На наружных установках",
          "В помещении диспетчера предприятия",
          "На входе объекта",
          "Возле ограждения периметра объекта через каждые 50 м",
        ],
        correctAnswers: [
          "На наружных установках",
          "В помещении диспетчера предприятия",
        ],
      },
      {
        code: "20113047",
        text: "Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?",
        answers: [
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с взрывоопасными технологическими блоками",
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем",
          "Оборудование должно быть изолировано от действующей системы, а нанесенное на нем обозначение номера по технологической схеме удалено",
          "Оборудование должно быть демонтировано и складировано в безопасном месте",
        ],
        correctAnswers: [
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем",
        ],
      },
      {
        code: "20113048",
        text: "Какой параметр является критерием установления категории взрывоопасности технологических блоков согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?",
        answers: [
          "Скорость распространения горения",
          "Температура самовоспламенения паров",
          "Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему",
          "Класс опасности",
        ],
        correctAnswers: [
          "Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему",
        ],
      },
      {
        code: "20113049",
        text: "Что является критерием взрывоопасности согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?",
        answers: [
          "Температура самовоспламенения",
          "Класс опасности",
          "Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом",
          "Скорость распространения горения",
        ],
        correctAnswers: [
          "Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом",
        ],
      },
      {
        code: "20113050",
        text: "Какое управление подачей инертных сред в технологические установки должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категории взрывоопасности?",
        answers: [
          "Ручное",
          "Автоматическое",
          "Дистанционное",
          "Не регламентируется",
        ],
        correctAnswers: ["Автоматическое"],
      },
      {
        code: "20113051",
        text: "Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?",
        answers: [
          "Системами ручного регулирования",
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
          "Эффективными быстродействующими системами, обеспечивающими непрерывность технологического процесса",
          "Всем перечисленным",
        ],
        correctAnswers: [
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
        ],
      },
      {
        code: "20113052",
        text: "Каким образом осуществляется управление подачей инертных сред на установку с технологическими блоками любой категории взрывоопасности там, где при отклонении от регламентированных значений параметров возможно образование взрывоопасных смесей?",
        answers: [
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту",
          "Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, для установок с технологическими блоками II категории взрывоопасности - ручное дистанционное, для установок с технологическими блоками III категории взрывоопасности допускается ручное управление по месту",
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление",
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Qв ≤ 10 - управление ручное дистанционное",
        ],
        correctAnswers: [
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту",
        ],
      },
      {
        code: "20113053",
        text: "Где приводятся конкретные значения уставок систем защиты по опасным параметрам?",
        answers: [
          "Только в проектной документации",
          "В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции",
          "Только в технологическом регламенте на производство продукции",
        ],
        correctAnswers: [
          "В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20113054",
        text: "Какой следует принимать категорию взрывоопасности блоков, определяемую расчетом, если обращающиеся в технологическом блоке опасные вещества относятся к токсичным, высокотоксичным веществам?",
        answers: [
          "III категорией",
          "II категорией",
          "На одну выше",
          "I категорией",
        ],
        correctAnswers: ["На одну выше"],
      },
      {
        code: "20113055",
        text: "На сколько категорий взрывоопасности подразделяются при проектировании технологические блоки взрывопожароопасных производств и объектов?",
        answers: [
          "На 3 категории",
          "На 5 категорий",
          "На 4 категории",
          "На 2 категории",
        ],
        correctAnswers: ["На 3 категории"],
      },
      {
        code: "20113056",
        text: "Какие сведения являются основополагающими при выборе технологического оборудования для обеспечения технологических процессов?",
        answers: [
          "Требования действующих нормативных документов и расчетные данные",
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
          "Расчетные данные параметров оборудования",
          "Расчетные данные и показатели надежности",
        ],
        correctAnswers: [
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
      },
      {
        code: "20113057",
        text: "Какой категории взрывоопасности технологических блоков не существует?",
        answers: [
          "II категории",
          "I категории",
          "III категории",
          "IV категории",
        ],
        correctAnswers: ["IV категории"],
      },
      {
        code: "20113058",
        text: "Какое управление системами подачи инертных сред в технологические системы должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категорий взрывоопасности?",
        answers: [
          "Дистанционное",
          "Автоматическое",
          "Ручное",
          "Неавтоматическое",
        ],
        correctAnswers: ["Автоматическое"],
      },
      {
        code: "20113059",
        text: "В массообменных процессах при отклонении технологических параметров от регламентированных значений возможно образование неустойчивых взрывоопасных соединений. Как в таком случае должно осуществляться регулирование этих параметров?",
        answers: [
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматически, а при Qв ≤ 10 допускается ручное дистанционное управление",
          "Для установок с технологическими блоками I категории взрывоопасности - автоматически, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту",
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматически, для установок III категории взрывоопасности допускается управление вручную при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений",
          "Для установок с технологическими блоками I категории взрывоопасности - автоматически, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту",
        ],
        correctAnswers: [
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматически, для установок III категории взрывоопасности допускается управление вручную при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений",
        ],
      },
      {
        code: "20113060",
        text: "Чем из перечисленного оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?",
        answers: [
          "Системами ручного регулирования",
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
          "Эффективными быстродействующими системами, обеспечивающими непрерывность технологического процесса",
          "Всем перечисленным",
        ],
        correctAnswers: [
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
        ],
      },
      {
        code: "20113061",
        text: "Каким образом осуществляется регулирование массообменных процессов, в которых при отклонении технологических параметров от регламентированных значений возможно образование неустойчивых взрывоопасных соединений?",
        answers: [
          "Для установок с технологическими блоками I и II категорий взрывоопасности - при помощи средств автоматического регулирования, для установок III категории взрывоопасности предусматривается выполнение операций регулирования в ручном режиме (производственным персоналом) при обеспечении автоматического контроля указанных параметров процесса и сигнализации о превышении их допустимых значений",
          "Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту",
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при QВ ≤ 10 допускается ручное дистанционное управление",
          "Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту",
        ],
        correctAnswers: [
          "Для установок с технологическими блоками I и II категорий взрывоопасности - при помощи средств автоматического регулирования, для установок III категории взрывоопасности предусматривается выполнение операций регулирования в ручном режиме (производственным персоналом) при обеспечении автоматического контроля указанных параметров процесса и сигнализации о превышении их допустимых значений",
        ],
      },
      {
        code: "20113062",
        text: "Каким документом обосновывается повышение категории взрывоопасности технологических блоков, определяемое количеством токсичных, высокотоксичных веществ, опасностью причинения ими вреда обслуживающему персоналу при вероятных сценариях развития аварийной ситуации?",
        answers: [
          "Правилами безопасности",
          "Проектной документацией",
          "Техническим регламентом",
          "Производственной инструкцией",
        ],
        correctAnswers: ["Проектной документацией"],
      },
      {
        code: "20113063",
        text: "Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?",
        answers: [
          "Время срабатывания установлено для каждого технологического блока в соответствии с категорией взрывоопасности",
          "Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и устанавливается для блоков I и II категорий",
          "Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и устанавливается для блоков III категории",
          "Время срабатывания определяется расчетом",
        ],
        correctAnswers: ["Время срабатывания определяется расчетом"],
      },
      {
        code: "20113064",
        text: "Что не должна исключать энергетическая устойчивость технологической системы с учетом категории взрывоопасности входящих в нее блоков, особенностей технологического процесса?",
        answers: [
          "Возможность образования в системе взрывоопасной среды (за счет увеличения времени пребывания продуктов в реакционной зоне, нарушения соотношения поступающих в нее продуктов, развития неуправляемых процессов)",
          "Возможность нарушения герметичности системы (разгерметизации уплотнений подвижных соединений, разрушения оборудования от превышения давления)",
          "Возможность выбора рациональной схемы энергоснабжения, количества источников электропитания (основных и резервных), их надежность",
        ],
        correctAnswers: [
          "Возможность выбора рациональной схемы энергоснабжения, количества источников электропитания (основных и резервных), их надежность",
        ],
      },
      {
        code: "20113065",
        text: "Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?",
        answers: [
          "Энергией сгорания парогазовой фазы",
          "Приведенной массой вещества, участвующего во взрыве",
          "Радиусом зон разрушения",
          "Категорией взрывоопасности технологических блоков",
        ],
        correctAnswers: ["Категорией взрывоопасности технологических блоков"],
      },
      {
        code: "20113066",
        text: "В каком из перечисленных случаев категорию взрывоопасности блоков, определяемую расчетом, следует рассматривать на одну выше?",
        answers: [
          "Только если обращающиеся в технологическом блоке вещества относятся к высокотоксичным веществам",
          "Только если обращающиеся в технологическом блоке вещества относятся к токсичным веществам",
          "В любом из указанных случаев",
        ],
        correctAnswers: ["В любом из указанных случаев"],
      },
      {
        code: "20113067",
        text: "Запорная арматура из каких материалов должна применяться в технологических системах с блоками любой категории взрывоопасности?",
        answers: ["Из бронзы", "Из чугуна", "Из пластика", "Из стали"],
        correctAnswers: ["Из стали"],
      },
      {
        code: "20113068",
        text: "Кто делает обоснование по применению эффективности и надежности мер и технических средств противоаварийной защиты, направленных на обеспечение взрывобезопасности отдельного блока и в целом всей технологической системы?",
        answers: [
          "Организация, проводящая пуско-наладочные работы",
          "Организация, проводящая экспертизу промышленной безопасности опасных производственных объектов",
          "Проектная организация",
          "Эксплуатирующая организация",
        ],
        correctAnswers: ["Проектная организация"],
      },
      {
        code: "20113069",
        text: "Какие технологические блоки относятся к первой категории взрывоопасности?",
        answers: [
          "Qв меньше 27, m меньше 2000 кг",
          "Qв 27 - 37, m 2000 - 5000 кг",
          "Qв больше 37, m больше 5000 кг",
        ],
        correctAnswers: ["Qв больше 37, m больше 5000 кг"],
      },
      {
        code: "20113070",
        text: "Какие сведения являются основополагающими для выбора оборудования при разработке технологических процессов?",
        answers: [
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
          "Расчетные данные, которым должны соответствовать параметры оборудования, и требования нормативных документов в области промышленной безопасности",
          "Расчетные данные, которым должны соответствовать параметры оборудования, и показатели надежности",
          "Расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование и требования нормативных документов в области промышленной безопасности",
        ],
        correctAnswers: [
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
      },
      {
        code: "20113071",
        text: "В технологических блоках какой категории взрывоопасности должны быть предусмотрены технические средства, обеспечивающие в автоматическом режиме оповещение об обнаружении, локализации и ликвидации выбросов опасных веществ?",
        answers: [
          "Только в технологических блоках II категории взрывоопасности",
          "Только в технологических блоках I категории взрывоопасности",
          "Только в технологических блоках III категории взрывоопасности",
          "В технологических блоках всех категорий взрывоопасности",
        ],
        correctAnswers: [
          "В технологических блоках всех категорий взрывоопасности",
        ],
      },
      {
        code: "20113072",
        text: "Какие опасные производственные объекты должны оснащаться автоматическими и (или) автоматизированными системами управления, построенными на базе электронных средств контроля и автоматики, включая средства вычислительной техники?",
        answers: [
          "Имеющие в своем составе объекты с технологическими блоками I и II категорий взрывоопасности",
          "Имеющие в своем составе объекты с технологическими блоками только II категории взрывоопасности",
          "Имеющие в своем составе объекты с технологическими блоками только I категории взрывоопасности",
          "Имеющие в своем составе объекты с технологическими блоками только III категории взрывоопасности",
        ],
        correctAnswers: [
          "Имеющие в своем составе объекты с технологическими блоками I и II категорий взрывоопасности",
        ],
      },
      {
        code: "20113073",
        text: "Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества?",
        answers: [
          "Системами ручного регулирования",
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
          "Эффективными быстродействующими системами, обеспечивающими непрерывность технологического процесса",
          "Всем перечисленным",
        ],
        correctAnswers: [
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
        ],
      },
      {
        code: "20113074",
        text: "Какого показателя категорий взрывоопасности технологических блоков не существует?",
        answers: ["II", "III", "I", "IV"],
        correctAnswers: ["IV"],
      },
      {
        code: "20113075",
        text: "С какими подразделениями должна быть обеспечена связь посредством системы двусторонней громкоговорящей связи на объектах с технологическими блоками I категории взрывоопасности?",
        answers: [
          "Только с пожарной частью и сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов",
          "Только с персоналом диспетчерских пунктов, газоспасательной службой и штабом гражданской обороны",
          "С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов",
        ],
        correctAnswers: [
          "С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов",
        ],
      },
      {
        code: "20113076",
        text: "Для трубопроводов каких технологических блоков при подключении к коллектору в обоснованных случаях для повышения надежности предусматривается установка дублирующих отключающих устройств?",
        answers: [
          "Для технологических блоков I категории взрывоопасности",
          "Для технологических блоков II категории взрывоопасности",
          "Для технологических блоков III категории взрывоопасности",
        ],
        correctAnswers: [
          "Для технологических блоков I категории взрывоопасности",
        ],
      },
      {
        code: "20113077",
        text: "Что относится к технологическим трубопроводам?",
        answers: [
          "Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования",
          "Трубопроводы, предназначенные для транспортирования веществ, необходимых для ведения технологического процесса или эксплуатации оборудования",
          "Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия сырья, вспомогательных материалов, в том числе пара, воды, воздуха, газов, хладагентов, смазок, эмульсий, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования",
        ],
        correctAnswers: [
          "Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования",
        ],
      },
      {
        code: "20113078",
        text: "Кем определяются предельные значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывоопасных характеристик, физико-химических свойств транспортируемых веществ, свойств конструкционных материалов и характеристик технических устройств, применяемых для перемещения горючих продуктов?",
        answers: [
          "Разработчиком проекта",
          "Эксплуатирующей организацией",
          "Организацией-изготовителем",
          "Обслуживающей организацией",
        ],
        correctAnswers: ["Разработчиком проекта"],
      },
      {
        code: "20113079",
        text: "С учетом каких параметров в каждом конкретном случае в проектной документации (документации на техническое перевооружение) обосновывается решение о типе арматуры и месте ее установки на линиях всасывания и нагнетания, а также способе ее отключения, в том числе дистанционном? Выберите 2 правильных варианта ответа.",
        answers: [
          "С учетом диаметра и протяженности трубопровода",
          "С учетом марки стали трубопровода",
          "С учетом характеристики транспортируемой среды",
          "С учетом толщины стенки трубопровода",
        ],
        correctAnswers: [
          "С учетом диаметра и протяженности трубопровода",
          "С учетом характеристики транспортируемой среды",
        ],
      },
      {
        code: "20113080",
        text: "Каким документом устанавливаются места расположения запорных и (или) отсекающих устройств?",
        answers: [
          "Планом ликвидации последствий аварий",
          "Проектной документацией или документацией на техническое перевооружение",
          "Проектом производства работ на опасном производственном объекте",
          "Нарядом-допуском на выполнение работ",
        ],
        correctAnswers: [
          "Проектной документацией или документацией на техническое перевооружение",
        ],
      },
      {
        code: "20113081",
        text: "В каких местах не допускается размещать фланцевые соединения трубопроводов с взрывопожароопасными, токсичными и едкими веществами?",
        answers: [
          "На трубопроводах, проложенных над автодорогами",
          "На трубопроводах, проложенных под эстакадами",
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
          "На трубопроводах, идущих вдоль стен зданий",
        ],
        correctAnswers: [
          "Над местами, предназначенными для прохода людей, и рабочими площадками",
        ],
      },
      {
        code: "20113082",
        text: "Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные газы, легковоспламеняющиеся жидкости и горючие жидкости на сливо-наливных эстакадах?",
        answers: [
          "Только по месту",
          "Только дистанционно (из безопасного места)",
          "По месту и дистанционно (из безопасного места)",
          "Не регламентируется",
        ],
        correctAnswers: ["По месту и дистанционно (из безопасного места)"],
      },
      {
        code: "20113083",
        text: "В каком документе указываются данные о сроке службы технологического оборудования и трубопроводной арматуры производителем?",
        answers: [
          "В техническом задании на производство оборудования",
          "В паспортах оборудования и трубопроводной арматуры",
          "В технологическом регламенте оборудования",
          "В проектной документации оборудования",
        ],
        correctAnswers: ["В паспортах оборудования и трубопроводной арматуры"],
      },
      {
        code: "20113084",
        text: "Какая арматура устанавливается на трубопроводах для транспортирования взрывопожароопасных продуктов?",
        answers: [
          "Арматура с пластмассовым уплотнением в затворе",
          "Арматура с резиновым уплотнением в затворе",
          "Арматура с тканевым уплотнением в затворе",
          "Соответствующая проектной документации (документации на техническое перевооружение)",
        ],
        correctAnswers: [
          "Соответствующая проектной документации (документации на техническое перевооружение)",
        ],
      },
      {
        code: "20113085",
        text: "Кем определяются допустимые значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывопожароопасных характеристик, физико-химических свойств?",
        answers: [
          "Руководителем эксплуатирующей организации",
          "Техническим руководителем эксплуатирующей организации",
          "Разработчиком процесса",
          "Разработчиком проекта",
        ],
        correctAnswers: ["Разработчиком проекта"],
      },
      {
        code: "20113086",
        text: "Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?",
        answers: [
          "Разработчиком проекта",
          "Руководителем эксплуатирующей организации",
          "Заказчиком в задании на проектирование",
          "Разработчиком процесса",
          "Техническим руководителем эксплуатирующей организации",
        ],
        correctAnswers: ["Разработчиком процесса"],
      },
      {
        code: "20113087",
        text: "Какое из перечисленных положений нарушает требования, предъявляемые к прокладке трубопроводов на объектах нефтегазодобычи?",
        answers: [
          "Прокладка трубопроводов должна обеспечивать максимально возможную протяженность коммуникаций, исключать провисания и образование застойных зон",
          "При прокладке трубопроводов принимаются меры, исключающие возможность передачи дополнительных нагрузок на трубы",
          "Материал фланцев, конструкция уплотнения применяются в соответствии с нормативными техническими документами",
          "Трубопроводы не должны иметь фланцевых или других разъемных соединений",
        ],
        correctAnswers: [
          "Прокладка трубопроводов должна обеспечивать максимально возможную протяженность коммуникаций, исключать провисания и образование застойных зон",
        ],
      },
      {
        code: "20113088",
        text: "Что должно обеспечивать размещение технологического оборудования, трубопроводной арматуры в производственных зданиях и на открытых площадках? Выберите 2 правильных варианта ответа.",
        answers: [
          "Возможность проведения ремонтных работ",
          "Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий",
          "Использование строительных конструкций зданий и сооружений в несущих элементах технологического оборудования",
          "Уменьшение взрывоопасности объекта путем равномерного распределения технологических блоков I категории взрывоопасности",
        ],
        correctAnswers: [
          "Возможность проведения ремонтных работ",
          "Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий",
        ],
      },
      {
        code: "20113089",
        text: "Арматура с каким классом герметичности затвора должна устанавливаться на трубопроводах для транспортирования взрывопожароопасных продуктов?",
        answers: [
          "АА",
          "В",
          "А",
          "СС",
          "Определяется в проектной документации (документации на техническое перевооружение)",
        ],
        correctAnswers: [
          "Определяется в проектной документации (документации на техническое перевооружение)",
        ],
      },
      {
        code: "20113090",
        text: "В каких случаях на трубопроводах следует применять арматуру под приварку?",
        answers: [
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении",
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков II категории взрывоопасности с давлением среды более 2,5 МПа",
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности и температурой, превышающей температуру кипения при регламентированном давлении",
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков любой категории взрывоопасности",
        ],
        correctAnswers: [
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении",
        ],
      },
      {
        code: "20113091",
        text: "Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся жидкости и горючие жидкости на сливоналивных эстакадах?",
        answers: [
          "Должно быть организовано управление только дистанционно (из безопасного места)",
          "Должно быть организовано управление только по месту",
          "Должно быть организовано управление по месту и дистанционно (из безопасного места)",
        ],
        correctAnswers: [
          "Должно быть организовано управление по месту и дистанционно (из безопасного места)",
        ],
      },
      {
        code: "20113092",
        text: "В каких местах не допускается размещать фланцевые соединения трубопроводов с пожаровзрывоопасными, токсичными и едкими веществами?",
        answers: [
          "На трубопроводах, проложенных над автодорогами",
          "На трубопроводах, проложенных под эстакадами",
          "На трубопроводах, проложенных вдоль стен зданий",
          "Над местами, предназначенными для прохода людей и рабочими площадками",
        ],
        correctAnswers: [
          "Над местами, предназначенными для прохода людей и рабочими площадками",
        ],
      },
      {
        code: "20113093",
        text: "Где допускается размещение фланцевых соединений на трубопроводах с пожаровзрывоопасными, токсичными и едкими веществами?",
        answers: [
          "Над местами, предназначенными для прохода людей",
          "В местах ввода в технологические здания и сооружения",
          "В местах установки арматуры или подсоединения трубопроводов к аппаратам",
          "На тех участках, где по условиям технологии не требуется периодическая разборка для проведения чистки и ремонта трубопроводов",
        ],
        correctAnswers: [
          "В местах установки арматуры или подсоединения трубопроводов к аппаратам",
        ],
      },
      {
        code: "20113094",
        text: "Кто определяет выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?",
        answers: [
          "Разработчик процесса",
          "Заказчик",
          "Разработчик проекта",
          "Технический руководитель эксплуатирующей организации",
        ],
        correctAnswers: ["Разработчик процесса"],
      },
      {
        code: "20113095",
        text: "На каких трубопроводах следует применять арматуру под приварку для повышения надежности и плотности соединений?",
        answers: [
          "На трубопроводах технологических блоков II категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды, при регламентированном давлении",
          "На трубопроводах технологических блоков III категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды, при регламентированном давлении",
          "На трубопроводах технологических блоков I категории взрывоопасности с давлением среды менее 2,5 МПа, температурой, равной температуре кипения среды, при регламентированном давлении",
          "На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды при регламентированном давлении",
        ],
        correctAnswers: [
          "На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды при регламентированном давлении",
        ],
      },
      {
        code: "20113096",
        text: "Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся и горючие жидкости на сливо-наливных эстакадах?",
        answers: [
          "Должно быть организовано управление по месту",
          "Должно определяться разработчиком проекта",
          "Должно быть организовано управление дистанционно",
          "Должно быть организовано управление по месту и дистанционно (из безопасного места)",
        ],
        correctAnswers: [
          "Должно быть организовано управление по месту и дистанционно (из безопасного места)",
        ],
      },
      {
        code: "20113097",
        text: "В соответствии с чем следует производить монтаж технологического оборудования и трубопроводов?",
        answers: [
          "В соответствии с проектной документацией",
          "В соответствии с рабочей документацией",
          "В соответствии со всем перечисленным",
        ],
        correctAnswers: ["В соответствии со всем перечисленным"],
      },
      {
        code: "20113098",
        text: "Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и др. нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?",
        answers: [
          "Разработчиком проекта",
          "Руководителем эксплуатирующей организации",
          "Заказчиком в задании на проектирование",
          "Разработчиком процесса",
          "Техническим руководителем эксплуатирующей организации",
        ],
        correctAnswers: ["Разработчиком процесса"],
      },
      {
        code: "20113099",
        text: "Выполнение какого условия, относящегося к трубопроводам и компрессорам продуктов разделения воздуха, допускается?",
        answers: [
          "Установка дренажного устройства для слива сконденсированных паров масла в нижней точке трубопровода отвода масла",
          "Объединение дренажных трубопроводов",
          "Возможность попадания кислорода во всасывающую линию при обкатке компрессоров",
          "Всех перечисленных условий",
        ],
        correctAnswers: [
          "Установка дренажного устройства для слива сконденсированных паров масла в нижней точке трубопровода отвода масла",
        ],
      },
      {
        code: "20113100",
        text: "В каких случаях должны автоматически включаться системы аварийной вентиляции? Укажите 2 правильных варианта ответа.",
        answers: [
          "При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
          "При срабатывании сигнализаторов довзрывных концентраций вредных веществ",
          "При срабатывании сигнала о неисправной работе вентиляционной системы",
          "При неисправности заземления вентиляционного оборудования",
        ],
        correctAnswers: [
          "При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
          "При срабатывании сигнализаторов довзрывных концентраций вредных веществ",
        ],
      },
      {
        code: "20113101",
        text: "Какое требование к системам вентиляции указано неверно?",
        answers: [
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
          "Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания",
          "Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты «масляное или негорючей жидкостью заполнение оболочки» («о»)",
          "Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства",
        ],
        correctAnswers: [
          "Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты «масляное или негорючей жидкостью заполнение оболочки» («о»)",
        ],
      },
      {
        code: "20113102",
        text: "В каком случае системы аварийной вентиляции должны включаться автоматически? Выберите 2 правильных варианта ответа.",
        answers: [
          "При срабатывании сигнализаторов довзрывных концентраций вредных веществ",
          "При поступлении сигнала от датчиков контроля за наличием взрывопожароопасной пыли в воздухе",
          "При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
          "При поступлении сигнала от датчиков повышения температуры воздуха",
        ],
        correctAnswers: [
          "При срабатывании сигнализаторов довзрывных концентраций вредных веществ",
          "При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
        ],
      },
      {
        code: "20113103",
        text: "При достижении какой концентрации обращающихся веществ в воздухе анализаторных помещений, должно происходить автоматическое включение аварийной вентиляции?",
        answers: [
          "При 10% нижнего концентрационного предела распространения пламени (НКПР)",
          "При 18% НКПР",
          "При 20% НКПР",
          "При 15% НКПР",
        ],
        correctAnswers: ["При 20% НКПР"],
      },
      {
        code: "20113104",
        text: "Какая система отопления предусматривается в помещениях, имеющих взрывоопасные зоны?",
        answers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
          "Cистема парового отопления",
          "Cистема водяного отопления",
          "Система электрического отопления",
        ],
        correctAnswers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
        ],
      },
      {
        code: "20113105",
        text: "Какая максимальная температура поверхностей нагрева систем отопления должна быть в помещениях, имеющих взрывоопасные зоны?",
        answers: [
          "Максимальная температура не должна превышать 95% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 85% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 90% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
        correctAnswers: [
          "Максимальная температура не должна превышать 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
      },
      {
        code: "20113106",
        text: "Каким требованиям должны соответствовать системы общеобменной и аварийной вытяжной вентиляции? Выберите 2 правильных варианта ответа.",
        answers: [
          "Общеобменная вентиляция должна включаться при срабатывании установленных в помещении сигнализаторов довзрывных концентраций при превышении предельно допустимых концентраций вредных веществ",
          "Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания",
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
          "Включение аварийной вытяжной вентиляции должно производиться при работающей общеобменной вентиляции при неработающем отсосе",
        ],
        correctAnswers: [
          "Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания",
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
        ],
      },
      {
        code: "20113107",
        text: "Когда должно происходить автоматическое включение аварийной вентиляции, установленной в анализаторных помещениях?",
        answers: [
          "Если концентрация обращающихся веществ в воздухе помещения достигает 20% НКПР (нижний концентрационный предел распространения пламени)",
          "Если концентрация обращающихся веществ в воздухе помещения достигает 18% НКПР",
          "Если концентрация обращающихся веществ в воздухе помещения достигает 15% НКПР",
          "Если концентрация обращающихся веществ в воздухе помещения достигает 10% НКПР",
        ],
        correctAnswers: [
          "Если концентрация обращающихся веществ в воздухе помещения достигает 20% НКПР (нижний концентрационный предел распространения пламени)",
        ],
      },
      {
        code: "20113108",
        text: "Где допускается расположение узла ввода теплоносителя?",
        answers: [
          "Только в самостоятельном помещении с отдельным входом с лестничной клетки или из невзрывопожароопасных производственных помещений",
          "Только в помещениях систем приточной вентиляции (в вентиляционной камере)",
          "Только в производственных помещениях, в которых предусмотрено применение водяного или парового отопления",
          "Во всех указанных местах",
        ],
        correctAnswers: ["Во всех указанных местах"],
      },
      {
        code: "20113109",
        text: "Где не располагается узел ввода теплоносителя на взрывопожароопасных производствах?",
        answers: [
          "В электропомещениях и помещениях контрольно-измерительных приборов и автоматики",
          "В помещениях систем приточной вентиляции (в вентиляционной камере)",
          "В самостоятельном помещении с отдельным входом с лестничной клетки или из невзрывопожароопасных производственных помещений",
          "В производственных помещениях, в которых предусмотрено применение водяного или парового отопления",
        ],
        correctAnswers: [
          "В электропомещениях и помещениях контрольно-измерительных приборов и автоматики",
        ],
      },
      {
        code: "20113110",
        text: 'Какое требование к системам вентиляции не соответствует ФНП "Общие правила взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств"?',
        answers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
          "Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания",
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
          "Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства",
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: "20113111",
        text: "Какая система отопления должна применяться в помещениях, имеющих взрывоопасные зоны?",
        answers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
          "Система парового отопления",
          "Система водяного отопления",
          "Любая из перечисленных",
        ],
        correctAnswers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
        ],
      },
      {
        code: "20113112",
        text: "Чем должно оснащаться оборудование для измельчения и смешивания измельченных твердых горючих продуктов для обеспечения эксплуатационной безопасности в отношении риска взрыва?",
        answers: [
          "Сигнализацией об отклонении давления подаваемого инертного газа от регламентированных значений",
          "Средствами контроля за очагами самовозгорания и устройствами для их тушения",
          "Средствами контроля за температурой измельченных твердых горючих продуктов",
          "Средствами контроля за фракционным составом измельченных твердых горючих продуктов",
        ],
        correctAnswers: [
          "Сигнализацией об отклонении давления подаваемого инертного газа от регламентированных значений",
        ],
      },
      {
        code: "20113113",
        text: "Чем должны оснащаться сушильные установки, имеющие непосредственный контакт высушиваемого продукта с сушильным агентом?",
        answers: [
          "Устройствами очистки отработанного сушильного агента от пыли высушиваемого продукта и средствами контроля очистки",
          "Звуковой и световой сигнализацией",
          "Блокировками от переполнения",
          "Всем перечисленным",
        ],
        correctAnswers: [
          "Устройствами очистки отработанного сушильного агента от пыли высушиваемого продукта и средствами контроля очистки",
        ],
      },
      {
        code: "20113114",
        text: "Каким из перечисленных требований должны соответствовать помещения управления? Укажите 2 правильных варианта ответа.",
        answers: [
          "Защита от механических повреждений проложенных по полу кабелей должна осуществляться с помощью металлических швеллеров и уголков",
          "Помещения должны иметь световую и звуковую сигнализацию о загазованности",
          "Полы в помещении должны быть с повышенной механической стойкостью",
          "Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам",
        ],
        correctAnswers: [
          "Помещения должны иметь световую и звуковую сигнализацию о загазованности",
          "Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам",
        ],
      },
      {
        code: "20113115",
        text: "Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?",
        answers: [
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
          "Давление теплоносителя (хладагента) должно быть меньше давления нагреваемых (охлаждаемых) горючих веществ",
          "Давление теплоносителя (хладагента) должно быть равным давлению нагреваемых (охлаждаемых) горючих веществ",
          "Соотношение давлений негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ устанавливается разработчиком процесса",
        ],
        correctAnswers: [
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
        ],
      },
      {
        code: "20113116",
        text: "Чем должны быть оборудованы аппараты со взрывопожароопасными веществами?",
        answers: [
          "Устройствами для подключения линий воды, пара, инертного газа",
          "Устройствами для подключения линий кислорода",
          "Циклонами",
          "Виброситом",
        ],
        correctAnswers: [
          "Устройствами для подключения линий воды, пара, инертного газа",
        ],
      },
      {
        code: "20113117",
        text: "Что предусматривается во взрывоопасных помещениях и вне их перед входными дверями?",
        answers: [
          "Устройство световой и звуковой сигнализации загазованности воздушной среды",
          "Размещение электрических средств и элементов систем контроля",
          "Размещение систем управления и ПАЗ",
          "Установка средств связи и оповещения",
        ],
        correctAnswers: [
          "Устройство световой и звуковой сигнализации загазованности воздушной среды",
        ],
      },
      {
        code: "20113118",
        text: "Сколько в процентном отношении должна составлять температура поверхностей нагрева систем отопления в помещениях, имеющих взрывопожароопасные зоны?",
        answers: [
          "Не более 95% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Не более 85% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Не более 90% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Не более 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
        correctAnswers: [
          "Не более 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
      },
      {
        code: "20113119",
        text: "Где разрешается использование железнодорожных цистерн с легковоспламеняющимися жидкостями, находящимися на железнодорожных путях, в качестве стационарных складских емкостей?",
        answers: [
          "В железнодорожных тупиках",
          "На сливных эстакадах",
          "В местах проведения погрузочно-выгрузочных работ",
          "Нигде не разрешается",
        ],
        correctAnswers: ["Нигде не разрешается"],
      },
      {
        code: "20113120",
        text: "Чем оснащаются системы разделения газожидкостных смесей в целях обеспечения высокой эффективности разделения фаз?",
        answers: [
          "Обратным клапаном",
          "Запорным клапаном",
          "Предохранительным клапаном",
          "Регулирующим клапаном",
        ],
        correctAnswers: ["Предохранительным клапаном"],
      },
      {
        code: "20113121",
        text: "Какие требования предъявляются к системам канализации технологических объектов при сбросе химически загрязненных стоков в магистральную сеть канализации?",
        answers: [
          "Система канализации технологических объектов должна быть совмещена с общей канализацией",
          "Системы канализации технологических объектов должны осуществлять сброс стоков в магистральную сеть в порядке, установленном организацией",
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
          "Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть",
        ],
        correctAnswers: [
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
        ],
      },
      {
        code: "20113122",
        text: "Чем должно быть оснащено оборудование для разделения суспензий?",
        answers: [
          "Запорным устройством",
          "Обратным клапаном",
          "Регулятором",
          "Блокировками, исключающими его пуск, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды",
        ],
        correctAnswers: [
          "Блокировками, исключающими его пуск, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды",
        ],
      },
      {
        code: "20113123",
        text: "Без чего не допускается прямое соединение канализации химически загрязненных стоков с хозяйственно-бытовой канализацией на взрывопожароопасных производствах?",
        answers: [
          "Без обратного клапана",
          "Без регулятора",
          "Без запорного механизма",
          "Без гидрозатвора",
        ],
        correctAnswers: ["Без гидрозатвора"],
      },
      {
        code: "20113124",
        text: "В соответствии с какими требованиями должно выполняться проектирование систем водопровода и канализации взрывопожароопасных производств?",
        answers: [
          "Только в соответствии с требованиями технических регламентов",
          "Только в соответствии с требованиями градостроительной деятельности",
          "Только в соответствии с требованиями Правил взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств",
          "В соответствии со всеми перечисленными требованиями",
        ],
        correctAnswers: ["В соответствии со всеми перечисленными требованиями"],
      },
      {
        code: "20113125",
        text: "Каков порядок сброса химически загрязненных стоков от отдельных технологических объектов в магистральную сеть канализации?",
        answers: [
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
          "Системы канализации должны быть оборудованы устройствами для улавливания аварийных стоков",
          "Порядок сброса стоков в магистральную сеть канализации устанавливается эксплуатирующей организацией",
          "Системы канализации технологических объектов должны исключать залповые и аварийные сбросы",
        ],
        correctAnswers: [
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
        ],
      },
      {
        code: "20113126",
        text: "Где не допускается располагать колодцы на сетях канализации во взрывопожароопасных производствах?",
        answers: [
          "Только под эстакадами технологических трубопроводов",
          "Только в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты",
          "Под эстакадами технологических трубопроводов и в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты",
        ],
        correctAnswers: [
          "Под эстакадами технологических трубопроводов и в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты",
        ],
      },
      {
        code: "20113127",
        text: "Где разрешается использование железнодорожных цистерн с легковоспламеняющимися жидкостями, находящимися на железнодорожных путях, в качестве стационарных складских (расходных) емкостей?",
        answers: [
          "В железнодорожных тупиках",
          "На сливных эстакадах",
          "В местах проведения погрузочно-выгрузочных работ",
          "Нигде не разрешается",
        ],
        correctAnswers: ["Нигде не разрешается"],
      },
      {
        code: "20113128",
        text: "Какой должна быть температура наружных поверхностей оборудования и кожухов теплоизоляционных покрытий в местах, доступных для обслуживающего персонала?",
        answers: [
          "Не более 45 °С внутри помещений и 60 °С на наружных установках",
          "Не более 50 °С внутри помещений и 55 °С на наружных установках",
          "Не более 40 °С внутри помещений и 70 °С на наружных установках",
          "Не более 35 °С внутри помещений и 65 °С на наружных установках",
        ],
        correctAnswers: [
          "Не более 45 °С внутри помещений и 60 °С на наружных установках",
        ],
      },
      {
        code: "20113129",
        text: "Каким оборудованием оснащаются цистерны, предназначенные для перевозки по железным дорогам сжиженного углеводородного газа и легковоспламеняющихся жидкостей?",
        answers: [
          "Только предохранительными клапанами и другой арматурой",
          "Только сливоналивными устройствами",
          "Только средствами контроля уровня налива",
          "Арматурой, средствами контроля, сливоналивными, защитными и другими устройствами",
        ],
        correctAnswers: [
          "Арматурой, средствами контроля, сливоналивными, защитными и другими устройствами",
        ],
      },
      {
        code: "20113130",
        text: "На какой стадии предусматриваются средства контроля и регулирования уровня разделения фаз горючих паров (газов) и жидкостей?",
        answers: [
          "На стадии эксплуатации",
          "На стадии проектирования процесса",
          "На стадии ликвидации",
          "На стадии строительства",
        ],
        correctAnswers: ["На стадии проектирования процесса"],
      },
      {
        code: "20113131",
        text: "Каких значений не должна превышать максимальная температура поверхностей нагрева систем отопления в помещениях, имеющих взрывопожароопасные зоны?",
        answers: [
          "95% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "90% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "85% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
        correctAnswers: [
          "80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
      },
      {
        code: "20113132",
        text: "В соответствии с чем осуществляется ведение технологических процессов на ОПО химических, нефтехимических и нефтегазоперерабатывающих производств?",
        answers: [
          "В соответствии с распоряжениями и приказами руководителя эксплуатирующей организации",
          "В соответствии с рекомендациями и указаниями территориального управления Ростехнадзора",
          "В соответствии с заключением экспертизы промышленной безопасности",
          "В соответствии с технологическими регламентами на производство продукции",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами на производство продукции",
        ],
      },
      {
        code: "20113133",
        text: "Какие требования должны быть выполнены для вновь проектируемых взрывопожароопасных и химически опасных объектов? Выберите два правильных варианта ответа.",
        answers: [
          "Вокруг зданий должна предусматриваться защитная полоса шириной не менее 5 м",
          "Окна зданий должны быть оборудованы ставнями или жалюзи из металла",
          "Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия",
          "Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов",
        ],
        correctAnswers: [
          "Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия",
          "Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов",
        ],
      },
      {
        code: "20113134",
        text: "Чем определяется порядок испытаний, контроль за состоянием и эксплуатацией теплообменных устройств?",
        answers: [
          "Результатами анализа технологических процессов",
          "Технологической схемой производства",
          "Исходными данными на проектирование",
          "Технической документацией производителя",
        ],
        correctAnswers: ["Технической документацией производителя"],
      },
      {
        code: "20113135",
        text: "В соответствии с чем осуществляется ведение технологических процессов на опасных производственных объектах нефтехимических и нефтегазоперерабатывающих производств?",
        answers: [
          "В соответствии с заключением экспертизы промышленной безопасности",
          "В соответствии с приказами руководителя эксплуатирующей организации",
          "В соответствии с технологическими регламентами на производство продукции",
          "В соответствии с предписаниями территориального управления Ростехнадзора",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами на производство продукции",
        ],
      },
      {
        code: "20113136",
        text: "Каким требованиям должны соответствовать вновь проектируемые здания взрывопожароопасных объектов, в которых располагаются помещения управления (операторные), с постоянным пребыванием в них людей? Укажите 2 правильных варианта ответа.",
        answers: [
          "Вокруг зданий должна предусматриваться защитная полоса земли шириной не менее 1,5 м",
          "Быть устойчивыми к воздействию ударной волны",
          "Обеспечивать безопасность находящегося в них персонала",
          "Окна зданий должны быть оборудованы металлическими ставнями",
        ],
        correctAnswers: [
          "Быть устойчивыми к воздействию ударной волны",
          "Обеспечивать безопасность находящегося в них персонала",
        ],
      },
      {
        code: "20113137",
        text: "Какие требования должны быть выполнены на вновь проектируемых взрывопожароопасных и химически опасных производственных объектах?",
        answers: [
          "Только защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия",
          "Только бесперебойное функционирование автоматизированных систем контроля, управления, противоаварийной автоматической защиты (ПАЗ) для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов",
          "Должны быть обеспечены все указанные требования",
        ],
        correctAnswers: ["Должны быть обеспечены все указанные требования"],
      },
      {
        code: "20113138",
        text: "Какими источниками информации следует руководствоваться при разработке технологических процессов для определения регламентированных значений параметров, определяющих взрывоопасность процесса, допустимые диапазоны их измерений, критические значения параметров?",
        answers: [
          "Заданием на проектирование, на основании данных о критических значениях параметров или их совокупности для участвующих в процессе веществ",
          "Научно-технической литературой",
          "Техническими нормами и правилами",
          "Данными проведенных экспертиз",
        ],
        correctAnswers: [
          "Заданием на проектирование, на основании данных о критических значениях параметров или их совокупности для участвующих в процессе веществ",
        ],
      },
      {
        code: "20113139",
        text: "В соответствии с чем осуществляется ведение технологических процессов на опасном производственном объекте химической, нефтехимической промышленности?",
        answers: [
          "В соответствии с производственными инструкциями",
          "В соответствии с заключением экспертизы промышленной безопасности",
          "В соответствии с технологическими регламентами на производство продукции",
          "В соответствии с распоряжениями руководителя эксплуатирующей организации",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами на производство продукции",
        ],
      },
      {
        code: "20113140",
        text: "Какая аппаратура оснащается средствами автоматического контроля, регулирования и сигнализации уровня хладагента в теплообменных элементах?",
        answers: [
          "Реакционные аппараты взрывоопасных технологических процессов с перемешивающими устройствами",
          "Аппаратура для ведения жидкофазных процессов",
          "Реакционная аппаратура, в которой отвод избыточного тепла реакции при теплопередаче через стенку осуществляется за счет испарения охлаждающей жидкости",
          "Вся перечисленная аппаратура",
        ],
        correctAnswers: [
          "Реакционная аппаратура, в которой отвод избыточного тепла реакции при теплопередаче через стенку осуществляется за счет испарения охлаждающей жидкости",
        ],
      },
      {
        code: "20113141",
        text: "Какой порядок установлен для внесения изменений в технологическую схему, аппаратурное оформление, в системы контроля, связи, оповещения и противоаварийной автоматической защиты?",
        answers: [
          "Изменения вносятся при наличии положительного заключения экспертизы промышленной безопасности и внесения его в реестр заключений экспертизы",
          "Изменения вносятся после внесения изменений в проектную документацию или документацию на техническое перевооружение, согласованную с разработчиком проектной документации или с организацией, специализирующейся на проектировании аналогичных объектов, или при наличии положительного заключения экспертиз по проектной документации (документации)",
          "Изменения вносятся путем разработки обоснования безопасности опасного производственного объекта, получения на него положительного заключения экспертизы промышленной безопасности и внесения его в реестр заключений экспертизы",
        ],
        correctAnswers: [
          "Изменения вносятся после внесения изменений в проектную документацию или документацию на техническое перевооружение, согласованную с разработчиком проектной документации или с организацией, специализирующейся на проектировании аналогичных объектов, или при наличии положительного заключения экспертиз по проектной документации (документации)",
        ],
      },
      {
        code: "20113142",
        text: "В соответствии с чем осуществляется ведение технологических процессов на опасных производственных объектах химических, нефтехимических и нефтегазоперерабатывающих производств?",
        answers: [
          "В соответствии с распоряжениями и приказами руководителя эксплуатирующей организации",
          "В соответствии с рекомендациями и указаниями территориального управления Ростехнадзора",
          "В соответствии с заключением экспертизы промышленной безопасности",
          "В соответствии с технологическими регламентами на производство продукции",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами на производство продукции",
        ],
      },
      {
        code: "20113143",
        text: "Чем должны оснащаться технологические системы для обеспечения безопасности ведения технологических процессов?",
        answers: [
          "Только средствами автоматического регулирования",
          "Только средствами контроля за параметрами, определяющими взрывоопасность процесса",
          "Только средствами противоаварийной защиты",
          "Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе противоаварийной защиты (ПАЗ)",
        ],
        correctAnswers: [
          "Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе противоаварийной защиты (ПАЗ)",
        ],
      },
      {
        code: "20113144",
        text: "Какими приборами и средствами автоматизации оснащаются сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Выберите два правильных варианта ответа.",
        answers: [
          "Приборами контроля температуры отделяемой жидкой фазы",
          "Приборами контроля давления на сепараторе",
          "Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации",
          "Блокировками отключения компрессора при превышении предельно допустимого значения уровня",
        ],
        correctAnswers: [
          "Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации",
          "Блокировками отключения компрессора при превышении предельно допустимого значения уровня",
        ],
      },
      {
        code: "20113145",
        text: "Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предотвращения аварий и предупреждения их развития?",
        answers: [
          "Системы контроля, автоматического и дистанционного управления и регулирования технологическими процессами",
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
          "Системы связи и оповещения об аварийных ситуациях",
          "Средства автоматического непрерывного газового контроля и анализа с сигнализацией для контроля загазованности в рабочей зоне",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20113146",
        text: "Чем должны быть оснащены колонны ректификации горючих жидкостей?",
        answers: [
          "Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы, а также средствами сигнализации об опасных отклонениях значений параметров, в том числе перепада давления между нижней и верхней частями колонны, определяющих взрывобезопасность процесса",
          "Только средствами сигнализации об опасных отклонениях значений параметров",
          "Только средствами контроля уровня и температуры жидкости в кубовой части",
          "Только средствами ручного регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы",
        ],
        correctAnswers: [
          "Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы, а также средствами сигнализации об опасных отклонениях значений параметров, в том числе перепада давления между нижней и верхней частями колонны, определяющих взрывобезопасность процесса",
        ],
      },
      {
        code: "20113147",
        text: "Исходя из чего осуществляется проектирование системы противоаварийной автоматической защиты и выбор ее элементов?",
        answers: [
          "Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта",
          "Исходя из условий обеспечения работы системы только в процессе обслуживания в течение 15 лет",
          "Исходя из условий обеспечения работы системы только в процессе ремонта",
          "Исходя из условий обеспечения работы системы только в процессе эксплуатации в течение 10 лет",
        ],
        correctAnswers: [
          "Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта",
        ],
      },
      {
        code: "20113148",
        text: "Чем должны быть оснащены подводящие к смесителям коммуникации, проектируемые с целью обеспечения максимально возможного уровня эксплуатационной безопасности в отношении риска взрыва?",
        answers: [
          "Обратными клапанами или другими устройствами, исключающими (при отклонениях от регламентированных параметров процесса) поступление обратным ходом в эти коммуникации подаваемых на смешивание горючих веществ, окислителей или смесей",
          "Звуковой и световой сигнализацией",
          "Блокировками от переполнения горючими веществами, окислителями или смесями",
          "Газоанализаторами, работающими в автоматическом режиме",
        ],
        correctAnswers: [
          "Обратными клапанами или другими устройствами, исключающими (при отклонениях от регламентированных параметров процесса) поступление обратным ходом в эти коммуникации подаваемых на смешивание горючих веществ, окислителей или смесей",
        ],
      },
      {
        code: "20113149",
        text: "Что из перечисленного является недопустимым на территории предприятия, имеющего в своем составе взрывопожароопасные производства?",
        answers: [
          "Только наличие природных оврагов, выемок, низин",
          "Только траншейная и наземная прокладка трасс трубопроводов со сжиженными горючими газами, легковоспламеняющимися и горючими жидкостями в искусственных или естественных углублениях",
          "Только устройство открытых траншей, котлованов, приямков, в которых возможно скопление взрывопожароопасных паров и газов",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20113150",
        text: "Чем подтверждаются эффективность и надежность средств взрывозащиты и локализации пламени и других противоаварийных устройств, направленных на взрывозащищенность оборудования?",
        answers: [
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
          "Посредством опытных работ и экспертизы промышленной безопасности",
          "Посредством получения заключения научно-исследовательской организации и экспертизы промышленной безопасности",
          "Посредством оценки научно-исследовательской организации",
        ],
        correctAnswers: [
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
        ],
      },
      {
        code: "20113151",
        text: "Каким образом должны подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств для оборудования (аппаратов и трубопроводов) до начала их применения на опасном производственном объекте?",
        answers: [
          "Посредством получения заключения научно-исследовательской организации и экспертизы промышленной безопасности",
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
          "Посредством опытных работ и экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
        ],
      },
      {
        code: "20113152",
        text: "Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Средства автоматики должны быть обозначены в организационно-распорядительных документах организации",
          "Требования к обозначению определяются при разработке планов мероприятий по локализации и ликвидации последствий аварий",
          "Средства автоматики должны быть обозначены в акте пуска объекта в эксплуатацию",
          "Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте и инструкциях",
        ],
        correctAnswers: [
          "Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте и инструкциях",
        ],
      },
      {
        code: "20113153",
        text: "Какое требование не соответствует предъявляемым требованиям к специальным системам аварийного освобождения при проектировании технологических схем для новых производств для аварийного освобождения технологических блоков от обращающихся продуктов?",
        answers: [
          "Специальные системы аварийного освобождения должны находиться в постоянной готовности и исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий",
          "Специальные системы аварийного освобождения могут использоваться для других целей",
          "Специальные системы аварийного освобождения должны находиться в постоянной готовности и оснащаться средствами контроля и управления",
          "Специальные системы аварийного освобождения должны находиться в постоянной готовности и обеспечивать минимально возможное время освобождения",
        ],
        correctAnswers: [
          "Специальные системы аварийного освобождения могут использоваться для других целей",
        ],
      },
      {
        code: "20113154",
        text: "Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития? Укажите 2 правильных варианта ответа.",
        answers: [
          "Средства обнаружения возгорания",
          "Запорную и запорно-регулирующую арматуру",
          "Автоматические системы подавления пламени",
          "Клапаны, отсекающие и другие отключающие устройства",
        ],
        correctAnswers: [
          "Запорную и запорно-регулирующую арматуру",
          "Клапаны, отсекающие и другие отключающие устройства",
        ],
      },
      {
        code: "20113155",
        text: "Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?",
        answers: [
          "Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов",
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
          "Запорная арматура, средства защиты от превышения давления, огнепреградители",
          "Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20113156",
        text: "Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?",
        answers: [
          "Обслуживающим персоналом по инструкции",
          "Средствами автоматического управления объектом по действующим программам переключений",
          "Обслуживающим персоналом по программе переключений",
          "Средствами автоматического управления объектом по действующим программам после устранения неисправностей",
        ],
        correctAnswers: ["Обслуживающим персоналом по инструкции"],
      },
      {
        code: "20113157",
        text: "Что должно быть учтено при проектировании программного обеспечения противоаварийной защиты технологической аппаратуры реакционных процессов?",
        answers: [
          "Риски срабатывания автоматических систем противоаварийной защиты",
          "Требования территориальных органов Ростехнадзора",
          "Требования организации - изготовителя реакционной аппаратуры",
          "Указания технического руководителя организации",
        ],
        correctAnswers: [
          "Риски срабатывания автоматических систем противоаварийной защиты",
        ],
      },
      {
        code: "20113158",
        text: "Кем определяется степень разделения материальных сред и меры взрывобезопасности на всех стадиях процесса?",
        answers: [
          "Заказчиком проекта",
          "Разработчиком процесса",
          "Разработчиком проекта",
        ],
        correctAnswers: ["Разработчиком процесса"],
      },
      {
        code: "20113159",
        text: "Что должно быть учтено в системах управления и защиты электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?",
        answers: [
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
          "Должна быть обеспечена возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой",
          "Должно быть наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения",
          "Должна быть обеспечена селективность защит на устройствах электроснабжающей и электропотребляющей организаций",
        ],
        correctAnswers: [
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
        ],
      },
      {
        code: "20113160",
        text: "Какие линии электроснабжения от внешних источников независимо от класса напряжения не должны оборудоваться устройствами автоматической частной разгрузки?",
        answers: [
          "Питающие потребителей особой группы I категории надежности электроснабжения",
          "Питающие потребителей особой группы II категории надежности электроснабжения",
          "Питающие потребителей особой группы III категории надежности электроснабжения",
          "Питающие потребителей любых категорий надежности электроснабжения",
        ],
        correctAnswers: [
          "Питающие потребителей особой группы I категории надежности электроснабжения",
        ],
      },
      {
        code: "20113161",
        text: "Чем обеспечивается противоаварийная автоматическая защита нагреваемых элементов (змеевиков) нагревательных печей? Выберите 2 правильных варианта ответа.",
        answers: [
          "Средствами контроля и сигнализации за температурой нагреваемого жидкого продукта",
          "Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции",
          "Блокировками подачи топлива при превышении значения температуры нагреваемого жидкого продукта",
          "Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков",
        ],
        correctAnswers: [
          "Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции",
          "Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков",
        ],
      },
      {
        code: "20113162",
        text: "Какие из перечисленных мер по обеспечению взрывобезопасности предусматриваются проектной организацией для каждого технологического блока с учетом его энергетического потенциала? Выберите 2 правильных варианта ответа.",
        answers: [
          "Предотвращение взрывов и травмирования производственного персонала",
          "Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества",
          "Уменьшение ущерба от аварии на взрывопожароопасном объекте",
          "Повышение квалификации обслуживающего персонала на курсах переподготовки",
        ],
        correctAnswers: [
          "Предотвращение взрывов и травмирования производственного персонала",
          "Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества",
        ],
      },
      {
        code: "20113163",
        text: "Какими функциями должна обладать противоаварийная автоматическая защита нагреваемых элементов (змеевиков) нагревательных печей? Укажите 2 правильных варианта ответа.",
        answers: [
          "Средствами сигнализации и  контроля за температурой нагреваемого жидкого продукта",
          "Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков",
          "Блокировками по отключению подачи топлива при превышении предельно допустимого значения температуры нагреваемого жидкого продукта",
          "Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции",
        ],
        correctAnswers: [
          "Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков",
          "Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции",
        ],
      },
      {
        code: "20113164",
        text: "Что в химико-технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и их развития?",
        answers: [
          "Системы для аварийного опорожнения аппаратов",
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления",
          "Огнепреградители",
          "Все устройства, задействованные в системе противоаварийной защиты",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления",
        ],
      },
      {
        code: "20113165",
        text: "Какие меры по обеспечению взрывобезопасности предусматриваются проектной организацией для каждого технологического блока с учетом его энергетического потенциала? Выберите 2 правильных варианта ответа.",
        answers: [
          "Предупреждение взрывов и предотвращение травмирования производственного персонала",
          "Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества",
          "Уменьшение ущерба от аварии на взрывопожароопасном объекте",
          "Повышение квалификации обслуживающего персонала на курсах переподготовки",
        ],
        correctAnswers: [
          "Предупреждение взрывов и предотвращение травмирования производственного персонала",
          "Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества",
        ],
      },
      {
        code: "20113166",
        text: "При каких условиях допускается отключение защит (единовременно не более одного параметра) для непрерывных процессов?",
        answers: [
          "При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами",
          "По устному разрешению технического руководителя организации только в дневную смену при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, и в присутствии начальника производства",
          "В присутствии начальника производства и начальника службы КИПиА (главного прибориста) только в дневную смену при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ",
          "Ни при каких условиях",
        ],
        correctAnswers: [
          "При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами",
        ],
      },
      {
        code: "20113167",
        text: "Что должно устанавливаться на трубопроводах для транспортирования взрывопожароопасных продуктов?",
        answers: [
          "Арматура в соответствии с проектной документацией",
          "Арматура с тканевым уплотнением",
          "Арматура с резиновым уплотнением",
          "Арматура с пластиковым уплотнением",
        ],
        correctAnswers: ["Арматура в соответствии с проектной документацией"],
      },
      {
        code: "20113168",
        text: "Какие из перечисленных мер предусматриваются проектной документацией или документацией на техническое перевооружение для обеспечения взрывобезопасности технологической системы при пуске в работу и остановке оборудования? Выберите 2 правильных варианта ответа.",
        answers: [
          "Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон",
          "Разработка режимов и порядка пуска и остановки оборудования",
          "Проведение контроля за содержанием горючих веществ в технологической системе после остановки технологического оборудования",
          "Поддержание избыточного давления инертного газа в технологической системе в период остановки оборудования",
        ],
        correctAnswers: [
          "Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон",
          "Разработка режимов и порядка пуска и остановки оборудования",
        ],
      },
      {
        code: "20113169",
        text: "Что должны обеспечивать системы противоаварийной автоматической защиты и управления технологическими процессами? Выберите 2 правильных варианта ответа.",
        answers: [
          "Отключение систем в случае переключений на резервный или аварийный источник электропитания не позднее 0,5 с",
          "Возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной автоматической защиты (должен выполняться автоматически после устранения причины срабатывания)",
          "Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса",
          "Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления",
        ],
        correctAnswers: [
          "Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса",
          "Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления",
        ],
      },
      {
        code: "20113170",
        text: "Что в технологических системах относится к разряду противоаварийных устройств, используемых для предотвращения аварий и предупреждения их развития?",
        answers: [
          "Системы контроля, автоматического и дистанционного управления и регулирования технологическими процессами",
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
          "Системы связи и оповещения об аварийных ситуациях",
          "Средства автоматического непрерывного газового контроля и анализа с сигнализацией для контроля загазованности в рабочей зоне",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20113171",
        text: "Как производителем должна подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств до начала их применения на опасном производственном объекте?",
        answers: [
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
          "Посредством опытных работ и экспертизы промышленной безопасности",
          "Посредством оценки научно-исследовательской организации",
          "Посредством получения заключения научно-исследовательской организации и экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
        ],
      },
      {
        code: "20113172",
        text: "Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания противоаварийной защиты?",
        answers: [
          "Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию противоаварийной защиты",
          "Выполняется обслуживающим персоналом по инструкции",
          "Средствами автоматического управления объектом по действующим программам",
          "Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений",
        ],
        correctAnswers: ["Выполняется обслуживающим персоналом по инструкции"],
      },
      {
        code: "20113173",
        text: "Что должны обеспечивать системы противоаварийной автоматической защиты и управления технологическими процессами? Выберите 2 правильных варианта ответа.",
        answers: [
          "Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса",
          "Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления",
          "Время срабатывания систем защиты должно равняться времени, необходимому для перехода параметра от предупредительного до предельно допустимого значения",
          "Возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной автоматической защиты (должен выполняться автоматически после устранения причины срабатывания)",
        ],
        correctAnswers: [
          "Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса",
          "Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления",
        ],
      },
      {
        code: "20113174",
        text: "В соответствии с чем должны определяться оптимальные методы создания системы противоаварийной защиты на стадии формирования требований при проектировании автоматизированной системы управления технологическим процессом?",
        answers: [
          "В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности",
          "В соответствии с алгоритмами, разработанными по сценариям всех возможных аварий",
          "В соответствии со способами перевода объекта в безопасное состояние",
          "В соответствии с методиками и программными продуктами, применяемыми для моделирования аварийных ситуаций",
        ],
        correctAnswers: [
          "В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности",
        ],
      },
      {
        code: "20113175",
        text: "Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития?",
        answers: [
          "Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
          "Системы для аварийного опорожнения аппаратов",
          "Огнепреградители",
          "Все устройства, задействованные в системе противоаварийной защиты",
        ],
        correctAnswers: [
          "Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20113176",
        text: "Какие требования необходимо выполнять при прокладке кабелей по территории технологических установок? Укажите все правильные ответы.",
        answers: [
          "Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов",
          "Прокладка кабелей в каналах и траншеях разрешается только в бронированном исполнении",
          "Провода и кабели с полиэтиленовой изоляцией или оболочкой должны прокладываться на верхних ярусах технологических эстакад или под ними",
          "Прокладку кабелей разрешается выполнять открыто: по эстакадам, в галереях и на кабельных конструкциях технологических эстакад",
        ],
        correctAnswers: [
          "Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов",
          "Прокладку кабелей разрешается выполнять открыто: по эстакадам, в галереях и на кабельных конструкциях технологических эстакад",
        ],
      },
      {
        code: "20113177",
        text: "В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечить способность функционирования средств противоаварийной защиты?",
        answers: [
          "В течение 8 часов",
          "В течение 24 часов",
          "Время устанавливается в проектной документации",
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
        correctAnswers: [
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
      },
      {
        code: "20113178",
        text: "В какой документации приводятся способы и средства, исключающие выход параметров за установленные пределы?",
        answers: [
          "Только в проектной документации",
          "Только в технологическом регламенте на производство продукции",
          "Только в исходных данных на проектирование и технологическом регламенте на производство продукции",
          "В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции",
        ],
        correctAnswers: [
          "В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20113179",
        text: "В течение какого времени средства обеспечения энергоустойчивости технологической системы должны обеспечить способность функционирования средств противоаварийной защиты?",
        answers: [
          "В течение 8 часов",
          "В течение 24 часов",
          "В течение 2 часов",
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
        correctAnswers: [
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
      },
      {
        code: "20113180",
        text: "Какие из перечисленных функций должна обеспечивать автоматизированная система управления технологическими процессами на базе средств вычислительной техники? Выберите 2 правильных варианта ответа.",
        answers: [
          "Цикличный контроль за параметрами процесса и управление режимом для поддержания их регламентированных значений",
          "Периодический, не реже 2 раз в день, контроль за состоянием воздушной среды в пределах объекта",
          "Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации",
          "Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений",
        ],
        correctAnswers: [
          "Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации",
          "Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений",
        ],
      },
      {
        code: "20113181",
        text: "Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития? Выберите 2 правильных варианта ответа.",
        answers: [
          "Клапаны, отсекающие и другие отключающие устройства",
          "Запорную и запорно-регулирующую арматуру",
          "Средства, регистрирующие понижение давления",
          "Средства, регистрирующие превышение давления",
        ],
        correctAnswers: [
          "Клапаны, отсекающие и другие отключающие устройства",
          "Запорную и запорно-регулирующую арматуру",
        ],
      },
      {
        code: "20113182",
        text: "Какие требования необходимо выполнять при прокладке кабелей по территории технологических установок? Выберите 2 правильных варианта ответа.",
        answers: [
          "Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение",
          "Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов",
          "Прокладка кабелей в каналах и траншеях разрешается только в бронированном исполнении",
          "Провода и кабели с полиэтиленовой изоляцией или оболочкой должны прокладываться на верхних ярусах технологических эстакад или под ними",
        ],
        correctAnswers: [
          "Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение",
          "Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов",
        ],
      },
      {
        code: "20113183",
        text: "В какой документации должны быть приведены способы и средства, исключающие выход параметров за установленные пределы?",
        answers: [
          "В исходных данных на проектирование и технологическом регламенте",
          "В технологическом регламенте",
          "В проектной документации",
          "В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции",
        ],
        correctAnswers: [
          "В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20113184",
        text: "Что используется для соединения оборудования и технологических трубопроводов со стационарными линиями во взрывопожароопасных технологических системах?",
        answers: [
          "Резиновые гибкие шланги",
          "Пластмассовые гибкие шланги",
          "Съемные участки трубопроводов",
          "Металлорукава",
        ],
        correctAnswers: ["Съемные участки трубопроводов"],
      },
      {
        code: "20113185",
        text: "Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания противоаварийной автоматической защиты?",
        answers: [
          "Выполняется обслуживающим персоналом по действующим программам после устранения причин, приведших к срабатыванию противоаварийной автоматической защиты",
          "Выполняется средствами автоматического управления объектом по действующим программам после всех необходимых переключений",
          "Выполняется обслуживающим персоналом по инструкции",
          "Выполняется средствами автоматического управления объектом по действующим программам",
        ],
        correctAnswers: ["Выполняется обслуживающим персоналом по инструкции"],
      },
      {
        code: "20113186",
        text: "Где должно осуществляться измельчение, смешивание измельченных твердых горючих продуктов для исключения образования в системе взрывоопасных смесей?",
        answers: [
          "В естественной среде",
          "В среде с углекислым газом",
          "В среде с кислородом",
          "В среде инертного газа",
        ],
        correctAnswers: ["В среде инертного газа"],
      },
      {
        code: "20113187",
        text: "От какого вида электричества должны предусматриваться меры защиты при проектировании сливоналивных эстакад сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?",
        answers: [
          "Только от статического электричества",
          "Только от атмосферного электричества",
          "От атмосферного и статического электричества",
        ],
        correctAnswers: ["От атмосферного и статического электричества"],
      },
      {
        code: "20113188",
        text: "Для каких целей печи с открытым огневым процессом должны быть оборудованы паровой завесой?",
        answers: [
          "Для локализации пожара на печи при установке ее вне зданий",
          "Для локализации пожаров на печи при установке ее внутри помещений",
          "Для локализации пожаров при аварии на печи",
          "Для изоляции печи от взрывоопасной среды при авариях на наружных установках или в зданиях печи",
        ],
        correctAnswers: [
          "Для изоляции печи от взрывоопасной среды при авариях на наружных установках или в зданиях печи",
        ],
      },
      {
        code: "20113189",
        text: "Каким документам должны соответствовать объемно-планировочные решения, конструкция зданий, помещений и вспомогательных сооружений для систем контроля, управления, противоаварийной автоматической защиты и газового анализа, их размещение на территории опасного производственного объекта нефтехимических производств?",
        answers: [
          "Только проектной документации",
          "Только требованиям законодательства о градостроительной деятельности",
          "Только требованиям технических регламентов",
          "Только требованиям Правил взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств",
          "Всем перечисленным документам",
        ],
        correctAnswers: ["Всем перечисленным документам"],
      },
      {
        code: "20113190",
        text: "Как требуется размещать технологическое оборудование взрывопожароопасных производств?",
        answers: [
          "Только с учетом обеспечения безопасности обслуживания и эксплуатации",
          "Только с учетом возможности проведения визуального контроля за состоянием технологического оборудования и трубопроводов, выполнения работ по обслуживанию, ремонту и замене",
          "Только с учетом возможности проведения ремонтных работ и принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий",
          "С учетом всех перечисленных требований",
        ],
        correctAnswers: ["С учетом всех перечисленных требований"],
      },
      {
        code: "20113191",
        text: "Каким документом определяется время срабатывания запорных и (или) отсекающих устройств при аварийной разгерметизации системы?",
        answers: [
          "Проектной документацией или документацией на техническое перевооружение",
          "Инструкцией по эксплуатации",
          "Паспортом технического устройства",
          "Планом ликвидации последствий аварий",
        ],
        correctAnswers: [
          "Проектной документацией или документацией на техническое перевооружение",
        ],
      },
      {
        code: "20113192",
        text: "Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предотвращения их развития?",
        answers: [
          "Системы контроля, автоматического и дистанционного управления и регулирования технологическими процессами",
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
          "Системы связи и оповещения об аварийных ситуациях",
          "Средства автоматического непрерывного газового контроля и анализа с сигнализацией для контроля загазованности в рабочей зоне",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20113193",
        text: "Какие требования предъявляются к обозначению средств автоматики, используемых согласно Плану мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Средства автоматики должны быть обозначены по месту их установки и указываются в технологическом регламенте на производство продукции и инструкциях",
          "Требования должны определяться при разработке плана мероприятий по локализации и ликвидации последствий аварий",
          "Средства автоматики должны быть обозначены на мнемосхемах",
          "Средства автоматики должны быть обозначены в технологическом регламенте",
        ],
        correctAnswers: [
          "Средства автоматики должны быть обозначены по месту их установки и указываются в технологическом регламенте на производство продукции и инструкциях",
        ],
      },
      {
        code: "20113194",
        text: "Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей? Выберите 2 правильных варианта ответа.",
        answers: [
          "Системами регулирования заданного соотношения топлива, воздуха и водяного пара",
          "Блокировками, прекращающими поступление газообразного топлива и воздуха при снижении их давления ниже установленных параметров (автономно), а также при прекращении электро- (пневмо-) снабжения контрольно-измерительных приборов и средств автоматики",
          "Средствами ручного управления подачей водяного пара в топочное пространство и змеевики при прогаре труб",
          "Средствами контроля за температурой в топочном пространстве",
        ],
        correctAnswers: [
          "Системами регулирования заданного соотношения топлива, воздуха и водяного пара",
          "Блокировками, прекращающими поступление газообразного топлива и воздуха при снижении их давления ниже установленных параметров (автономно), а также при прекращении электро- (пневмо-) снабжения контрольно-измерительных приборов и средств автоматики",
        ],
      },
      {
        code: "20113195",
        text: "В каких документах приводятся способы и средства, исключающие выход параметров за установленные пределы?",
        answers: [
          "Только в проектной документации",
          "Только в технологическом регламенте на производство продукции",
          "Только в исходных данных на проектирование",
          "Во всех перечисленных",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "20113196",
        text: "Какое положение не соответствует установленным требованиям к электрообеспечению и электрооборудованию взрывоопасных технологических схем?",
        answers: [
          "Запрещается прокладка кабелей в каналах, засыпанных песком, и траншеях",
          "Прокладку кабелей по территории предприятий и установок разрешается выполнять открыто: по эстакадам, в галереях и на кабельных конструкциях технологических эстакад",
          "Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов в соответствии с требованиями нормативно-технических документов по устройству электроустановок",
          "Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение",
        ],
        correctAnswers: [
          "Запрещается прокладка кабелей в каналах, засыпанных песком, и траншеях",
        ],
      },
      {
        code: "20113197",
        text: "Какие функции должна обеспечивать автоматизированная система управления технологическими процессами на базе средств вычислительной техники? Выберите 2 правильных варианта ответа.",
        answers: [
          "Цикличный контроль за параметрами процесса и управление режимом для поддержания их регламентированных значений",
          "Периодический, не реже 2 раз в день, контроль за состоянием воздушной среды в пределах объекта",
          "Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации",
          "Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений",
        ],
        correctAnswers: [
          "Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации",
          "Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений",
        ],
      },
      {
        code: "20113198",
        text: "На основании каких данных определяются допустимые значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывопожароопасных характеристик, физико-химических свойств?",
        answers: [
          "На основании задания на проектирование",
          "На основании справочных данных на проектирование",
          "На основании аналогичных данных на проектирование",
          "На основании расчетных данных на проектирование",
        ],
        correctAnswers: ["На основании задания на проектирование"],
      },
      {
        code: "20113199",
        text: "Что применяется при непрерывных процессах смешивания веществ, взаимодействие которых может привести к развитию экзотермических реакций, для исключения их неуправляемого течения? Выберите два правильных варианта ответа.",
        answers: [
          "Эффективное разделение веществ, взаимодействие которых может привести к развитию неуправляемых экзотермических реакций",
          "Разработка методов отвода тепла",
          "Разработка методов приостановки реакции путем регулирования подачи одного из смешиваемых веществ",
          "Определение безопасных объемных скоростей дозирования смешиваемых веществ",
        ],
        correctAnswers: [
          "Разработка методов отвода тепла",
          "Определение безопасных объемных скоростей дозирования смешиваемых веществ",
        ],
      },
      {
        code: "20113200",
        text: "Чем должны оснащаться колонны ректификации горючих жидкостей? Выберите 2 правильных варианта ответа.",
        answers: [
          "Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы",
          "Средствами контроля за плотностью продукта и флегмы",
          "Средствами контроля за компонентным составом продукта",
          "Средствами сигнализации об опасных отклонениях значений параметров, определяющих взрывобезопасность процесса",
        ],
        correctAnswers: [
          "Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы",
          "Средствами сигнализации об опасных отклонениях значений параметров, определяющих взрывобезопасность процесса",
        ],
      },
      {
        code: "20113201",
        text: "Кем определяется степень разделения материальных сред и меры обеспечения взрывобезопасности на всех стадиях процесса?",
        answers: [
          "Разработчиком процесса и проекта",
          "Заказчиком проекта",
          "Разработчиком процесса",
          "Разработчиком проекта",
        ],
        correctAnswers: ["Разработчиком процесса"],
      },
      {
        code: "20113202",
        text: "Что устанавливается на линию подачи инертных газов (пар, азот, и другие среды) в процессах, при которых в результате отклонения от заданных технологических режимов возможно попадание взрывопожароопасных продуктов в нее?",
        answers: [
          "Обратный клапан",
          "Запорный клапан",
          "Предохранительный клапан",
          "Регулирующий клапан",
        ],
        correctAnswers: ["Обратный клапан"],
      },
      {
        code: "20113203",
        text: "Что необходимо предусматривать в проектной документации или документации на техническое перевооружение для максимального снижения выбросов горючих и взрывопожароопасных веществ при аварийной разгерметизации системы?",
        answers: [
          "Установку запорных и (или) отсекающих устройств с автоматическим управлением и временем срабатывания не более 300 секунд",
          "Установку запорных и (или) отсекающих устройств с автоматическим управлением и временем срабатывания не более 120 секунд",
          "Установку запорных и (или) отсекающих устройств с временем срабатывания, устанавливаемым в проектной документации",
        ],
        correctAnswers: [
          "Установку запорных и (или) отсекающих устройств с временем срабатывания, устанавливаемым в проектной документации",
        ],
      },
      {
        code: "20113204",
        text: "В соответствии с чем должно осуществляться размещение организации, имеющей в своем составе взрывоопасные технологические объекты, планировка ее территории, объемно-планировочные решения строительных объектов?",
        answers: [
          "В соответствии с предписаниями региональных органов Ростехнадзора",
          "В соответствии с требованиями Общих правил взрывобезопасности строительных объектов",
          "В соответствии с требованиями законодательства о градостроительной деятельности",
          "В соответствии с техническими регламентами и нормами",
        ],
        correctAnswers: [
          "В соответствии с требованиями законодательства о градостроительной деятельности",
        ],
      },
      {
        code: "20113205",
        text: "Оценку каких параметров необходимо произвести в проектной документации при разработке технологического процесса?",
        answers: [
          "Оценку эффективности технических средств противоаварийной защиты, направленных на обеспечение взрывобезопасности технологических блоков и в целом всей технологической схемы",
          "Оценку энергетического уровня каждого технологического блока и определение категории его взрывоопасности",
          "Оценку эффективности и надежности мер, обеспечивающих взрывобезопасность каждого технологического блока",
          "В проектной документации производится оценка всех перечисленных параметров",
        ],
        correctAnswers: [
          "В проектной документации производится оценка всех перечисленных параметров",
        ],
      },
      {
        code: "20113206",
        text: "Что должно проектироваться и выбираться с учетом анализа возможных рисков образования взрывоопасных веществ вследствие взаимного проникновения и взаимодействия теплоносителя с технологической средой для того, чтобы предотвратить возможность возникновения аварийных ситуаций?",
        answers: [
          "Только теплообменные процессы",
          "Только теплообменное оборудование",
          "Только тип теплоносителя (хладагента)",
          "Теплообменные процессы и теплообменное оборудование",
        ],
        correctAnswers: ["Теплообменные процессы и теплообменное оборудование"],
      },
      {
        code: "20113207",
        text: "Каким требованиям должны соответствовать специальные системы аварийного освобождения технологических блоков от обращающихся продуктов? Выберите 2 правильных варианта ответа.",
        answers: [
          "Обеспечивать минимально возможное время освобождения",
          "Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий",
          "Переходить из режима ожидания в рабочее состояние в течение 1 минуты",
          "Иметь небольшие габариты и вес",
        ],
        correctAnswers: [
          "Обеспечивать минимально возможное время освобождения",
          "Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий",
        ],
      },
      {
        code: "20113208",
        text: "Кто обосновывает достаточность аппаратурного резервирования и его тип?",
        answers: [
          "Разработчик проекта",
          "Разработчик технологического процесса и систем противоаварийной защиты",
          "Представитель изготовителя систем противоаварийной автоматической защиты",
          "Технический руководитель организации",
        ],
        correctAnswers: ["Разработчик проекта"],
      },
      {
        code: "20113209",
        text: "Какое из перечисленных положений не соответствует требованиям по размещению и устройству помещений управления взрывоопасных производств?",
        answers: [
          "В помещениях не должны размещаться оборудование и другие устройства, не связанные с системой управления технологическим процессом",
          "Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами",
          "В отдельных случаях при соответствующем обосновании в проекте разрешено пристраивать помещения управления к зданиям",
          "Помещения управления должны быть отдельно стоящими",
        ],
        correctAnswers: [
          "Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами",
        ],
      },
      {
        code: "20113210",
        text: "Какая из перечисленных мер должна предусматриваться для блоков технологической системы по максимальному снижению взрывоопасности?",
        answers: [
          "Предотвращение взрывов внутри технологического оборудования",
          "Использование в зависимости от особенностей технологического процесса эффективных систем пожаротушения",
          "Периодический контроль за выбросами горючих веществ в окружающую среду",
          "Использование вентиляционных систем для уменьшения возможности взрывов и пожаров в объеме производственных зданий, сооружений",
        ],
        correctAnswers: [
          "Предотвращение взрывов внутри технологического оборудования",
        ],
      },
      {
        code: "20113211",
        text: "Каким требованиям должны соответствовать помещения управления? Выберите 2 правильных варианта ответа.",
        answers: [
          "Помещения должны иметь световую и звуковую сигнализацию о загазованности",
          "Полы в помещении должны быть с повышенной механической стойкостью и кафельным покрытием",
          "Защита от механических повреждений проложенных по полу кабелей должна осуществляться с помощью металлических швеллеров и уголков",
          "Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам",
        ],
        correctAnswers: [
          "Помещения должны иметь световую и звуковую сигнализацию о загазованности",
          "Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам",
        ],
      },
      {
        code: "20113212",
        text: "Каким образом обеспечивается надежность обеспечения средств управления и системы противоаварийной защиты сжатым воздухом?",
        answers: [
          "Установкой резервного компрессора с автоматическим включением при остановке рабочего",
          "Переключением через осушитель сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования на заводскую сеть сжатого воздуха",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение часа",
        ],
        correctAnswers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20113213",
        text: "Какие требования предъявляются к пневматическим системам контроля, управления и противоаварийной автоматической защиты?",
        answers: [
          "Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги",
          "Качество сжатого воздуха должно проверяться не реже одного раза в месяц",
          "Использование инертного газа для питания систем контрольно-измерительных приборов и устройств автоматического регулирования должно определяться конкретными условиями и потребностями объекта",
          "Для пневматических систем контроля, управления и противоаварийной защиты должны предусматриваться единые установки и единые сети сжатого воздуха",
        ],
        correctAnswers: [
          "Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги",
        ],
      },
      {
        code: "20113214",
        text: "Каким образом обеспечивается надежность обеспечения средств управления и противоаварийной автоматической защиты сжатым воздухом?",
        answers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты в течение 30 минут",
          "Установкой резервного независимого компрессора с включением его автоматическим включением при остановке рабочего",
          "Переключением сети воздуха контрольно-измерительных приборов и автоматики на заводскую сеть сжатого воздуха через осушитель",
        ],
        correctAnswers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20113215",
        text: "Каким образом обеспечивается надежность обеспечения средств управления и систем противоаварийной защиты сжатым воздухом?",
        answers: [
          "Установкой дополнительного резервного компрессора с автоматическим включением при остановке рабочего",
          "Переключением через осушитель сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования на сеть сжатого воздуха организации",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
          "Установкой дополнительных буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение часа",
        ],
        correctAnswers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
    ],

    20114: [
      {
        code: "20114000",
        text: "По какой категории надежности должно осуществляться электроснабжение химически опасных производственных объектов?",
        answers: [
          "Допустимая категория надежности устанавливается разработчиком проекта в зависимости от применяемой технологии",
          "Только по I категории надежности",
          "По I или II категории надежности",
          "По II или III категории надежности",
        ],
        correctAnswers: ["По I или II категории надежности"],
      },
      {
        code: "20114001",
        text: "Что необходимо предусматривать на складах, пунктах слива-налива, расположенных на открытых площадках, где в условиях эксплуатации возможно поступление в воздух рабочей зоны паров кислот и щелочей, для обеспечения требований безопасности?",
        answers: [
          "Только автоматический контроль с сигнализацией превышения предельно допустимых концентраций",
          "Только регистрацию всех случаев загазованности приборами",
          "Только включение светового и звукового сигналов при превышении предельно допустимых концентраций",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20114002",
        text: "Каким образом определяется минимально допустимое расстояние от складов кислот и щелочей до взрывоопасных объектов?",
        answers: [
          "Минимально допустимые расстояния от складов кислот и щелочей до взрывоопасных объектов устанавливают в соответствии с требованиями законодательства в области технического регулирования",
          "Расстояние должно быть обусловлено только устойчивостью здания склада к воздействию ударной волны",
          "Минимально допустимые расстояния от складов кислот и щелочей до взрывоопасных объектов устанавливают в проектной документации",
          "Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения, должно обеспечивать устойчивость зданий складов к воздействию указанных факторов",
        ],
        correctAnswers: [
          "Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения, должно обеспечивать устойчивость зданий складов к воздействию указанных факторов",
        ],
      },
      {
        code: "20114003",
        text: "Чему соответствует вместимость поддонов, которыми оснащают емкостное оборудование для использования кислот и (или) щелочей объемом 1000 л и более?",
        answers: [
          "50% емкости всех расположенных в поддоне или на площадке с бортиками аппаратов и емкостей",
          "80% емкости всех расположенных в поддоне или на площадке с бортиками аппаратов и емкостей",
          "Объему всего расположенного в них оборудования",
          "Объему, достаточному для содержания одного аппарата максимальной емкости в случае его аварийного разрушения",
        ],
        correctAnswers: [
          "Объему, достаточному для содержания одного аппарата максимальной емкости в случае его аварийного разрушения",
        ],
      },
      {
        code: "20114004",
        text: "Чему соответствует вместимость поддонов, которыми оснащается емкостное оборудование для использования кислот и (или) щелочей объемом 1000 л и более?",
        answers: [
          "50% емкости всех расположенных в поддоне или на площадке с бортиками аппаратов и емкостей",
          "80% емкости всех расположенных в поддоне или на площадке с бортиками аппаратов и емкостей",
          "Объему всего расположенного в них оборудования",
          "Объему, достаточному для содержания одного аппарата максимальной емкости в случае его аварийного разрушения",
        ],
        correctAnswers: [
          "Объему, достаточному для содержания одного аппарата максимальной емкости в случае его аварийного разрушения",
        ],
      },
      {
        code: "20114005",
        text: "Как должны быть оборудованы места пересыпки и транспортирования пылящего химически опасного продукта в производстве пигментов?",
        answers: [
          "Места пересыпки и транспортирования должны располагаться на огражденных открытых площадках",
          "Пересыпка и транспортирование должны осуществляться в закрытых бункерах и конвейерах имеющих местные вытяжные устройства в местах пересыпки",
          "Места пересыпки и транспортирования должны быть герметизированы и снабжены укрытиями, присоединенными к аспирационным вентиляционным установкам",
          "Места пересыпки и транспортирования должны быть оборудованы с учетом местных особенностей в проекте",
        ],
        correctAnswers: [
          "Места пересыпки и транспортирования должны быть герметизированы и снабжены укрытиями, присоединенными к аспирационным вентиляционным установкам",
        ],
      },
      {
        code: "20114006",
        text: "Где размещают лаковыпускные отделения в производстве лаков на конденсационных смолах?",
        answers: [
          "Только в изолированном помещении корпуса синтеза",
          "Только в отдельно стоящем корпусе",
          "Только на открытых площадках",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20114007",
        text: "На какой высоте должны быть ограждения в местах прохода людей и проезда транспорта под подвесными конвейерами и транспортерами при производстве фосфора и его соединений?",
        answers: [
          "Не менее 2,2 м",
          "Не менее 3,4 м",
          "Не более 2,2 м",
          "Не более 3,4 м",
        ],
        correctAnswers: ["Не менее 2,2 м"],
      },
      {
        code: "20114008",
        text: "Какие меры безопасности должны соблюдаться при хранении и перекачке фосфора и фосфорного шлама?",
        answers: [
          "Температура фосфора и фосфорного шлама при хранении и перекачке не должна быть выше 80 °C",
          "Паропроводы, подводящие острый пар для разогрева фосфора и поддержания его в расплавленном состоянии, должны быть оснащены приборами контроля давления пара",
          'Паропроводы, подводящие острый пар для разогрева фосфора и поддержания его в расплавленном состоянии, должны быть снабжены устройствами ("воздушками") для предотвращения образования вакуума и попадания фосфора в паропровод',
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20114009",
        text: "Какой должна быть вместимость поддона, на который следует устанавливать производственные емкости с фосфором?",
        answers: [
          "Объемом не менее емкости резервуара с фосфором",
          "Объемом не менее вместимости одного наибольшего резервуара и слоя воды не более 100 мм",
          "Объемом не менее вместимости одного наибольшего резервуара и слоя воды не менее 200 мм",
          "Объемом, равным сумме объемов всех производственных емкостей с фосфором",
        ],
        correctAnswers: [
          "Объемом не менее вместимости одного наибольшего резервуара и слоя воды не менее 200 мм",
        ],
      },
      {
        code: "20114010",
        text: "Какие требования установлены к отделениям, в которых производят дробление пека, приготовление или разогрев электродной массы, и к оборудованию в этих отделениях?",
        answers: [
          "Отделения должны быть совмещены с остальными рабочими помещениями, оснащенными общеобменной вентиляцией, оборудование должно быть надежно укрыто",
          "Отделения должны быть совмещены с остальными рабочими помещениями, оборудование должно быть снабжено средствами местного отсоса",
          "Отделения должны быть изолированы от остальных рабочих помещений, к оборудованию специальных требований не установлено",
          "Отделения должны быть изолированы от остальных рабочих помещений, а оборудование должно быть герметизировано или надежно укрыто и снабжено средствами местного отсоса",
        ],
        correctAnswers: [
          "Отделения должны быть изолированы от остальных рабочих помещений, а оборудование должно быть герметизировано или надежно укрыто и снабжено средствами местного отсоса",
        ],
      },
      {
        code: "20114011",
        text: "На сколько должны быть заглублены полуподземные резервуары и хранилища фосфора?",
        answers: [
          "На уровень, обеспечивающий вместимость не менее 30% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,1 м",
          "На уровень, обеспечивающий вместимость не менее 40% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,1 м",
          "На уровень, обеспечивающий вместимость не менее 40% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,2 м",
          "На уровень, обеспечивающий вместимость не менее 50% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,2 м",
        ],
        correctAnswers: [
          "На уровень, обеспечивающий вместимость не менее 50% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,2 м",
        ],
      },
      {
        code: "20114012",
        text: "Какая минимальная ширина прохода должна быть предусмотрена на складе между штабелями мешков с серой?",
        answers: ["1 м", "2 м", "3 м", "4 м"],
        correctAnswers: ["1 м"],
      },
      {
        code: "20114013",
        text: "Какое из перечисленных требований к поддонам (приямкам) для сбора жидкого аммиака в случае разгерметизации сосуда указано верно? Укажите все правильные ответы.",
        answers: [
          "Приямки должны иметь не менее двух лестниц, а при глубине приямка более 2 м - выход непосредственно наружу",
          "Расчетный уровень жидкого аммиака в случае аварийного вытекания хладагента из наиболее аммиакоемкого сосуда в поддон (приямок) должен быть ниже бортика поддона (края приямка)",
          "Глубина приямка должна быть не более 3 м",
          "Количество пролитого аммиака из циркуляционного ресивера должно определяться по максимально допустимому заполнению сосуда",
          "Количество пролитого аммиака из защитного ресивера должно определяться по рабочему заполнению сосуда",
        ],
        correctAnswers: [
          "Приямки должны иметь не менее двух лестниц, а при глубине приямка более 2 м - выход непосредственно наружу",
          "Расчетный уровень жидкого аммиака в случае аварийного вытекания хладагента из наиболее аммиакоемкого сосуда в поддон (приямок) должен быть ниже бортика поддона (края приямка)",
        ],
      },
      {
        code: "20114014",
        text: "Какая должна быть степень защиты электроприборов и средств автоматического и дистанционного управления, располагающихся в помещениях с аммиачным оборудованием?",
        answers: [
          "Не ниже IP20",
          "Не ниже IP44",
          "Не ниже IP54",
          "Не ниже IP65",
        ],
        correctAnswers: ["Не ниже IP44"],
      },
      {
        code: "20114015",
        text: "Где должны быть установлены манометры (мановакуумметры) в целях обеспечения безопасности ведения технологических процессов в системах холодоснабжения на холодильном оборудовании и машинах?",
        answers: [
          "Только на компрессоре для наблюдения за рабочими давлениями всасывания, нагнетания",
          "Только в картере поршневых компрессоров, не имеющих уравнивания между всасыванием и картером",
          "Только на жидкостных и оттаивательных коллекторах распределительных аммиачных устройств, соединенных трубопроводами с оборудованием холодильных камер",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20114016",
        text: "Какое из перечисленных требований к манометрам, контролирующим давление при испытании на прочность сосудов (аппаратов), трубопроводов указано неверно?",
        answers: [
          "Давление при испытании должно контролироваться двумя манометрами, опломбированными и прошедшими поверку",
          "Один манометр должен устанавливаться у источника давления (воздушный компрессор, баллон с инертным газом), другой - на сосуде (аппарате) и трубопроводе в точке, наименее удаленной от воздушного компрессора",
          "Манометры должны быть с диаметром корпуса не менее 120 мм и шкалой на номинальное давление, равное 4/3 от измеряемого давления",
          "Манометры должны быть одинакового класса точности, не ниже 2,5",
        ],
        correctAnswers: [
          "Манометры должны быть одинакового класса точности, не ниже 2,5",
        ],
      },
      {
        code: "20114017",
        text: "Какое напряжение применяется для питания переносных светильников, работающих от аккумуляторных батарей, выполненных во взрывозащищенном исполнении, для внутреннего освещения аппаратов (резервуаров), заполненных растворителем, мисцеллой или парами растворителя?",
        answers: [
          "Не более 12 В",
          "Не более 24 В",
          "Не более 36 В",
          "Не более 42 В",
        ],
        correctAnswers: ["Не более 12 В"],
      },
      {
        code: "20114018",
        text: "Какие помещения объектов производств растительных масел относятся к неотапливаемым помещениям?",
        answers: [
          "Только здания складов",
          "Только здания элеваторов масличного сырья и шрота",
          "Только надсилосные и подсилосные этажи",
          "Все перечисленные помещения",
        ],
        correctAnswers: ["Все перечисленные помещения"],
      },
      {
        code: "20114019",
        text: "В каком случае допускается эксплуатация воздухоразделительных технических устройств, применяемых на опасных производственных объектах производств продуктов разделения воздуха?",
        answers: [
          "Если концентрация и состав взрывоопасных примесей в перерабатываемом воздухе не превышают предельно допустимые нормы",
          "Если концентрация и состав взрывоопасных примесей в перерабатываемом воздухе не превышают установленные технологическим процессом нормы",
          "Если концентрация и состав взрывоопасных примесей в перерабатываемом воздухе не превышают нормы, установленной разработчиком такого технического устройства, более чем на 10%",
          "Если концентрация и состав взрывоопасных примесей в перерабатываемом воздухе не превышают нормы, установленной разработчиком такого технического устройства",
        ],
        correctAnswers: [
          "Если концентрация и состав взрывоопасных примесей в перерабатываемом воздухе не превышают нормы, установленной разработчиком такого технического устройства",
        ],
      },
      {
        code: "20114020",
        text: "Каким документом определяется продолжительность эксплуатации воздухоразделительной установки между двумя полными отогревами установки?",
        answers: [
          "Технической документацией завода-изготовителя",
          "Заключением специализированной организации, осуществляющей экспертизу промышленной безопасности",
          "Техническим регламентом Таможенного союза",
          "Технологическим регламентом",
        ],
        correctAnswers: ["Технологическим регламентом"],
      },
      {
        code: "20114021",
        text: "Что необходимо применять для смазки цилиндров поршневых кислородных компрессоров?",
        answers: [
          "Дистиллят, полученный из воды питьевого качества",
          "Умягченную воду и конденсат, получаемые из заводской системы пароснабжения",
          "Воду питьевого качества",
          "Масло, извлеченное из масловлагоотделителей",
        ],
        correctAnswers: ["Дистиллят, полученный из воды питьевого качества"],
      },
      {
        code: "20114022",
        text: "Какую кислородную арматуру допускается размещать в помещениях щитов управления (щитовых)?",
        answers: [
          "Кислородную арматуру с давлением 0,6 МПа и выше",
          "Кислородную арматуру с давлением 1,6 МПа и выше",
          "Кислородную арматуру с давлением 2,6 МПа и выше",
          "Не допускается размещать кислородную арматуру (независимо от давления)",
        ],
        correctAnswers: [
          "Не допускается размещать кислородную арматуру (независимо от давления)",
        ],
      },
      {
        code: "20114023",
        text: "Как должны быть установлены все металлические конструкции, расположенные в пределах площадок, на которых размещены сосуды и сливоналивные устройства жидких продуктов разделения воздуха?",
        answers: [
          "На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,1 м",
          "На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,15 м",
          "На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,2 м",
          "На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,25 м",
        ],
        correctAnswers: [
          "На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,2 м",
        ],
      },
      {
        code: "20114024",
        text: "Кем должен быть утвержден перечень помещений и мест, в которых содержание кислорода по объемной доле может быть менее 19% или более 23% (в аварийной ситуации)?",
        answers: [
          "Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается только первым руководителем организации",
          "Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается территориальным органом Ростехнадзора",
          "Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается комиссией эксплуатирующей организации",
          "Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается техническим руководителем организации, эксплуатирующей объекты производства и потребления продуктов разделения воздуха",
        ],
        correctAnswers: [
          "Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается техническим руководителем организации, эксплуатирующей объекты производства и потребления продуктов разделения воздуха",
        ],
      },
      {
        code: "20114025",
        text: "Какой высоты должно быть ограждение реципиентов, расположенных вне зданий?",
        answers: [
          "Не менее 0,2 м",
          "Не менее 1,0 м",
          "Не менее 1,2 м",
          "Не менее 2,0 м",
        ],
        correctAnswers: ["Не менее 1,2 м"],
      },
      {
        code: "20114026",
        text: "Какие контрольно-измерительные приборы, применяемые для измерения параметров кислорода и газовых смесей, подлежат защите от жировых загрязнений?",
        answers: [
          "С объемной долей кислорода 19%",
          "С объемной долей кислорода менее 19%",
          "С объемной долей кислорода 23%",
          "С объемной долей кислорода более 23%",
        ],
        correctAnswers: ["С объемной долей кислорода более 23%"],
      },
      {
        code: "20114027",
        text: "В соответствии с каким документом выполняется защита от статического электричества технических устройств и коммуникаций газообразного кислорода и жидких продуктов разделения воздуха?",
        answers: [
          "В соответствии с технологическим регламентом",
          "В соответствии с нормативными техническими документами по устройству и эксплуатации электроустановок",
          "В соответствии с эксплуатационной документацией",
          "В соответствии с проектной документацией (документацией)",
        ],
        correctAnswers: [
          "В соответствии с проектной документацией (документацией)",
        ],
      },
      {
        code: "20114028",
        text: "Как должна производиться инструментальная проверка эффективности работы вентиляционных систем?",
        answers: [
          "Только после каждого капитального ремонта с оформлением акта проверки",
          "Только после реконструкции вентиляционных систем с оформлением акта проверки",
          "Не реже 1 раза в год, а также после каждого капитального ремонта или реконструкции этих систем. Акты проверки утверждает технический руководитель организации",
          "Не реже 1 раза в полгода с оформлением акта проверки",
        ],
        correctAnswers: [
          "Не реже 1 раза в год, а также после каждого капитального ремонта или реконструкции этих систем. Акты проверки утверждает технический руководитель организации",
        ],
      },
      {
        code: "20114029",
        text: "Какие требования к техническим устройствам и инженерным коммуникациям не соответствуют требованиям Правил безопасности химически опасных производственных объектов?",
        answers: [
          "При нормативной (регламентной) утечке или сбросе продуктов разделения воздуха в помещение (в отдельных случаях, определенных проектной документацией (документацией)) не предусматривается их удаление системой приточно-вытяжной вентиляции",
          "На все вентиляционные системы необходимо составлять соответствующие эксплуатационные документы (паспорта или формуляры)",
          "При изменении технологического процесса или реконструкции производственного участка действующие на этом участке вентиляционные системы должны быть приведены в соответствие с новыми производственными условиями",
          "Система продувок кислородных, азотных и аргонных технических устройств и коммуникаций должна исключать возможность поступления продуктов разделения воздуха в помещение",
        ],
        correctAnswers: [
          "При нормативной (регламентной) утечке или сбросе продуктов разделения воздуха в помещение (в отдельных случаях, определенных проектной документацией (документацией)) не предусматривается их удаление системой приточно-вытяжной вентиляции",
        ],
      },
      {
        code: "20114030",
        text: "Какие требования к сетям водопроводов и канализации и устройствам, подключаемым к сетям, не соответствуют требованиям Правил безопасности химически опасных производственных объектов?",
        answers: [
          "Соединение сетей хозяйственно-питьевого водопровода с сетями водопроводов, подающих воду технического качества, не допускается",
          "В производственных помещениях, где возможно повышенное содержание кислорода, которое может привести к воспламенению одежды, или наличие опасных веществ, которые могут вызвать химические ожоги, должны устанавливаться фонтанчики, краны, раковины или ванны самопомощи, аварийные души",
          "В случае крайней необходимости допускается располагать устройства, такие как фонтанчики, краны, раковины или ванны самопомощи, аварийные души, в помещениях, в которых обращаются или хранятся вещества, которые при контакте с водой разлагаются с взрывом или воспламеняются, а также выделяют взрывоопасные или токсичные газы",
          "Осмотр и очистка канализационных сетей и колодцев должны производиться по графикам и в соответствии с порядком проведения газоопасных работ",
        ],
        correctAnswers: [
          "В случае крайней необходимости допускается располагать устройства, такие как фонтанчики, краны, раковины или ванны самопомощи, аварийные души, в помещениях, в которых обращаются или хранятся вещества, которые при контакте с водой разлагаются с взрывом или воспламеняются, а также выделяют взрывоопасные или токсичные газы",
        ],
      },
      {
        code: "20114031",
        text: "Каким должен быть коэффициент заполнения резервуаров при хранении аммиака под избыточным давлением?",
        answers: [
          "Не более 0,85 от геометрического объема резервуара",
          "Не более 0,87 от геометрического объема резервуара",
          "Не более 0,91 от геометрического объема резервуара",
          "Не более 0,95 от геометрического объема резервуара",
        ],
        correctAnswers: ["Не более 0,85 от геометрического объема резервуара"],
      },
      {
        code: "20114032",
        text: "Какой должна быть высота ограждения резервуаров для хранения жидкого аммиака?",
        answers: [
          "Не менее расчетного уровня разлившегося жидкого аммиака",
          "Не менее чем на 0,2 м выше расчетного уровня разлившегося жидкого аммиака",
          "Не менее чем на 0,3 м выше расчетного уровня разлившегося жидкого аммиака, но не менее 1 м, а для изотермических резервуаров - не менее 1,5 м",
          "Не менее чем на 0,5 м выше расчетного уровня разлившегося жидкого аммиака",
        ],
        correctAnswers: [
          "Не менее чем на 0,3 м выше расчетного уровня разлившегося жидкого аммиака, но не менее 1 м, а для изотермических резервуаров - не менее 1,5 м",
        ],
      },
      {
        code: "20114033",
        text: "Какое из перечисленных требований не допускается на территории склада жидкого аммиака?",
        answers: [
          "Сопряжение двух наружных ограждений резервуаров для хранения жидкого аммиака",
          "Планирование территории в ограждении резервуаров для хранения жидкого аммиака с уклоном в сторону приямка",
          "Размещение сливоналивных устройств между расположенными рядом путями",
          "Прокладывание транзитных трубопроводов, не относящихся к резервуарам для хранения жидкого аммиака, и кабелей через огражденные территории резервуаров для хранения жидкого аммиака",
        ],
        correctAnswers: [
          "Прокладывание транзитных трубопроводов, не относящихся к резервуарам для хранения жидкого аммиака, и кабелей через огражденные территории резервуаров для хранения жидкого аммиака",
        ],
      },
      {
        code: "20114034",
        text: "Какие должны быть здания на территории склада жидкого аммиака по степени огнестойкости?",
        answers: [
          "Не ниже II степени огнестойкости",
          "Не ниже III степени огнестойкости",
          "Не ниже IV степени огнестойкости",
          "Не ниже V степени огнестойкости",
        ],
        correctAnswers: ["Не ниже II степени огнестойкости"],
      },
      {
        code: "20114035",
        text: "Какая максимальная объемная доля аммиака в межстенном пространстве резервуара во время эксплуатации?",
        answers: ["0,5%", "1%", "1,5%", "2%"],
        correctAnswers: ["0,5%"],
      },
      {
        code: "20114036",
        text: "Что должно быть учтено в системах управления и защиты электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?",
        answers: [
          "Должен быть предусмотрен автоматический ввод резерва между каждым из трех самостоятельных источников электроснабжения",
          "Должна быть обеспечена возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой",
          "Должна быть обеспечена селективность защит на устройствах электроснабжающей и электропотребляющей организаций",
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
        ],
        correctAnswers: [
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
        ],
      },
      {
        code: "20114037",
        text: "Какие требования необходимо выполнять при прокладке кабелей по территории технологических установок? Укажите все правильные ответы.",
        answers: [
          "Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов",
          "Прокладка кабелей в каналах и траншеях запрещается",
          "Прокладку кабелей разрешается выполнять открыто: по эстакадам, в галереях и на кабельных конструкциях технологических эстакад",
          "Провода и кабели с полиэтиленовой изоляцией или оболочкой должны прокладываться на верхних ярусах технологических эстакад",
        ],
        correctAnswers: [
          "Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов",
          "Прокладку кабелей разрешается выполнять открыто: по эстакадам, в галереях и на кабельных конструкциях технологических эстакад",
        ],
      },
      {
        code: "20114038",
        text: "Какое требование к системам вентиляции указано неверно?",
        answers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
          "Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания",
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
          "Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства",
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: "20114039",
        text: "В каких случаях должны автоматически включаться системы аварийной вентиляции? Укажите все правильные ответы.",
        answers: [
          "При срабатывании сигнализаторов довзрывных концентраций вредных веществ",
          "В случае остановки общеобменной вентиляции",
          "При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
          "При поступлении сигнала от датчиков повышения температуры или контроля за содержанием взрывопожароопасной пыли в воздухе помещения",
        ],
        correctAnswers: [
          "При срабатывании сигнализаторов довзрывных концентраций вредных веществ",
          "При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
        ],
      },
      {
        code: "20114040",
        text: "Какая система отопления должна применяться в помещениях, имеющих взрывоопасные зоны?",
        answers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
          "Система парового отопления",
          "Система водяного отопления",
          "Система электрического отопления",
        ],
        correctAnswers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
        ],
      },
      {
        code: "20114041",
        text: "Где допускается расположение узла ввода теплоносителя?",
        answers: [
          "Только в помещениях систем приточной вентиляции (в вентиляционной камере)",
          "Только в самостоятельном помещении с отдельным входом с лестничной клетки или из невзрывопожароопасных производственных помещений",
          "Только в производственных помещениях, в которых предусмотрено применение водяного или парового отопления",
          "Во всех указанных местах",
        ],
        correctAnswers: ["Во всех указанных местах"],
      },
      {
        code: "20114042",
        text: "В соответствии с какими требованиями должно выполняться проектирование систем водопровода и канализации взрывопожароопасных производств?",
        answers: [
          "Только в соответствии с требованиями технических регламентов",
          "Только в соответствии с требованиями законодательства о градостроительной деятельности",
          "Только в соответствии с требованиями Правил взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств",
          "В соответствии со всеми перечисленными требованиями",
        ],
        correctAnswers: ["В соответствии со всеми перечисленными требованиями"],
      },
      {
        code: "20114043",
        text: "Какие требования предъявляются к системам канализации технологических объектов при сбросе химически загрязненных стоков в магистральную сеть канализации?",
        answers: [
          "Системы канализации технологических объектов должны исключать аварийные и залповые сбросы стоков в магистральную сеть",
          "Системы канализации технологических объектов должны осуществлять сброс стоков в магистральную сеть в порядке, установленном  эксплуатирующей организацией",
          "Системы канализации технологических объектов должны быть оборудованы световой и  звуковой сигнализацией",
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
        ],
        correctAnswers: [
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
        ],
      },
      {
        code: "20114044",
        text: "Где не допускается располагать колодцы на сетях канализации во взрывопожароопасных производствах?",
        answers: [
          "Только под эстакадами технологических трубопроводов",
          "Только в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты",
          "Под эстакадами технологических трубопроводов и в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты",
        ],
        correctAnswers: [
          "Под эстакадами технологических трубопроводов и в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты",
        ],
      },
      {
        code: "20114045",
        text: "Без чего не допускается прямое соединение канализации химически загрязненных стоков с хозяйственно-бытовой канализацией на взрывопожароопасных производствах?",
        answers: [
          "Без специального фильтра",
          "Без предохранительного клапана",
          "Без регулируемого клапана",
          "Без гидрозатвора",
        ],
        correctAnswers: ["Без гидрозатвора"],
      },
      {
        code: "20114046",
        text: "В соответствии с чем должно осуществляться размещение организации, имеющей в своем составе взрывоопасные технологические объекты, планировка ее территории, объемно-планировочные решения строительных объектов?",
        answers: [
          "В соответствии с требованиями федеральных норм и правил в области промышленной безопасности - Общих правил взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств",
          "В соответствии с нормативными техническими документами и требованиями территориальных органов Ростехнадзора",
          "В соответствии с техническими регламентами",
          "В соответствии с требованиями законодательства о градостроительной деятельности",
        ],
        correctAnswers: [
          "В соответствии с требованиями законодательства о градостроительной деятельности",
        ],
      },
      {
        code: "20114047",
        text: "Что из перечисленного является недопустимым на территории предприятия, имеющего в своем составе взрывопожароопасные производства?",
        answers: [
          "Только наличие природных оврагов, выемок, низин",
          "Только устройство открытых траншей, котлованов, приямков, в которых возможно скопление взрывопожароопасных паров и газов",
          "Только траншейная и наземная прокладка трасс трубопроводов со сжиженными горючими газами, легковоспламеняющимися и горючими жидкостями в искусственных или естественных углублениях",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20114048",
        text: "Какие требования должны быть выполнены для вновь проектируемых взрывопожароопасных и химически опасных объектов? Выберите 2 правильных варианта ответа.",
        answers: [
          "Обеспечена защита персонала, постоянно находящегося в помещении управления (операторной), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия",
          "Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов",
          "Окна зданий должны быть оборудованы взрывозащитными жалюзи из специальных материалов",
          "Вокруг зданий должна предусматриваться минерализованная полоса земли шириной не менее 3 м",
        ],
        correctAnswers: [
          "Обеспечена защита персонала, постоянно находящегося в помещении управления (операторной), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия",
          "Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов",
        ],
      },
      {
        code: "20114049",
        text: "Каким образом предприятие должно обеспечить наработку навыков действий персонала в нештатных (аварийных) ситуациях на установках с технологическими блоками I и II категорий взрывоопасности?",
        answers: [
          "Допускать к самостоятельной работе не ранее чем через 6 месяцев после стажировки на объекте и прикрепления к опытному работнику",
          "Иметь компьютерные тренажеры, позволяющие приблизить процесс обучения к реальным условиям работы",
          "Иметь специализированные центры обучения и подготовки производственного персонала с помощью компьютерных программ",
          "Посредством обучения персонала на компьютерных тренажерах, включающих максимально приближенные к реальным динамические модели процессов и реальные средства управления",
        ],
        correctAnswers: [
          "Посредством обучения персонала на компьютерных тренажерах, включающих максимально приближенные к реальным динамические модели процессов и реальные средства управления",
        ],
      },
      {
        code: "20114050",
        text: "Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?",
        answers: [
          "Энергией сгорания парогазовой фазы, кДж",
          "Радиусом зон разрушения, м",
          "Приведенной массой вещества, участвующего во взрыве, кг",
          "Категорией взрывоопасности технологических блоков",
        ],
        correctAnswers: ["Категорией взрывоопасности технологических блоков"],
      },
      {
        code: "20114051",
        text: "На сколько категорий взрывоопасности подразделяются при проектировании технологические блоки взрывопожароопасных производств и объектов?",
        answers: [
          "На две категории",
          "На три категории",
          "На четыре категории",
          "На пять категорий",
        ],
        correctAnswers: ["На три категории"],
      },
      {
        code: "20114052",
        text: "В каком из перечисленных случаев категорию взрывоопасности блоков, определяемую расчетом, следует рассматривать на одну выше?",
        answers: [
          "Только если обращающиеся в технологическом блоке вещества относятся к токсичным веществам",
          "Только если обращающиеся в технологическом блоке вещества относятся к высокотоксичным веществам",
          "В любом из указанных случаев",
        ],
        correctAnswers: ["В любом из указанных случаев"],
      },
      {
        code: "20114053",
        text: "В соответствии с чем осуществляется ведение технологических процессов на опасных производственных объектах нефтехимических и нефтегазоперерабатывающих производств?",
        answers: [
          "В соответствии с  инструкциями и стандартами эксплуатирующей организации",
          "В соответствии с проектной документацией и рекомендациями Ростехнадзора",
          "В соответствии с заключением экспертизы промышленной безопасности и лабораторными технологическими регламентами (пусковыми записками, производственными методиками)",
          "В соответствии с технологическими регламентами на производство продукции",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами на производство продукции",
        ],
      },
      {
        code: "20114054",
        text: "Какая из перечисленных мер должна предусматриваться для блоков технологической системы по максимальному снижению взрывоопасности?",
        answers: [
          "Периодический контроль за состоянием воздушной среды",
          "Использование эффективных систем пожаротушения в зависимости от особенностей технологического процесса",
          "Использование вентиляционных систем для исключения возможности взрывов и пожаров в объеме производственных зданий, сооружений",
          "Предотвращение взрывов внутри технологического оборудования",
        ],
        correctAnswers: [
          "Предотвращение взрывов внутри технологического оборудования",
        ],
      },
      {
        code: "20114055",
        text: "В каких документах приводятся способы и средства, исключающие выход параметров за установленные пределы?",
        answers: [
          "В исходных данных на проектирование",
          "В проектной документации",
          "В технологическом регламенте на производство продукции",
          "Во всех перечисленных",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "20114056",
        text: "Каким образом осуществляется управление подачей инертных сред на установку с технологическими блоками любой категории взрывоопасности там, где при отклонении от регламентированных значений параметров возможно образование взрывоопасных смесей?",
        answers: [
          "Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, для установок с технологическими блоками II категории взрывоопасности - ручное дистанционное, для установок с технологическими блоками III категории взрывоопасности допускается ручное управление по месту",
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту",
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление",
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Qв ≤ 10 - управление ручное дистанционное",
        ],
        correctAnswers: [
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту",
        ],
      },
      {
        code: "20114057",
        text: "Какие из перечисленных мер предусматриваются проектной документацией или документацией на техническое перевооружение для обеспечения взрывобезопасности технологической системы при пуске в работу и остановке оборудования? Укажите все правильные ответы.",
        answers: [
          "Разработка режимов и порядка пуска и остановки оборудования",
          "Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон",
          "Поддержание избыточного давления инертного газа в технологической системе в период остановки оборудования",
          "Проведение контроля за содержанием горючих веществ в технологической системе после остановки технологического оборудования",
        ],
        correctAnswers: [
          "Разработка режимов и порядка пуска и остановки оборудования",
          "Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон",
        ],
      },
      {
        code: "20114058",
        text: "Чем должны оснащаться технологические системы для обеспечения безопасности ведения технологических процессов?",
        answers: [
          "Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе противоаварийной автоматической защиты (ПАЗ)",
          "Только средствами контроля за параметрами, определяющими взрывоопасность процесса",
          "Только средствами автоматического регулирования",
          "Только средствами противоаварийной защиты",
        ],
        correctAnswers: [
          "Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе противоаварийной автоматической защиты (ПАЗ)",
        ],
      },
      {
        code: "20114059",
        text: "В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечить способность функционирования средств противоаварийной защиты?",
        answers: [
          "В течение 8 часов",
          "В течение 24 часов",
          "В течение времени, предусмотренного проектной документацией",
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
        correctAnswers: [
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
      },
      {
        code: "20114060",
        text: "Какие меры по обеспечению взрывобезопасности предусматриваются проектной организацией для каждого технологического блока с учетом его энергетического потенциала? Выберите 2 правильных варианта ответа.",
        answers: [
          "Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества",
          "Повышение надежности оборудования  для уменьшения ущерба от аварии на взрывопожароопасном объекте",
          "Повышение квалификации обслуживающего персонала, необходимой для профессиональной деятельности, на курсах переподготовки",
          "Предупреждение взрывов и предотвращение травмирования производственного персонала",
        ],
        correctAnswers: [
          "Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества",
          "Предупреждение взрывов и предотвращение травмирования производственного персонала",
        ],
      },
      {
        code: "20114061",
        text: "Чем из перечисленного оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?",
        answers: [
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
          "Эффективными быстродействующими системами, обеспечивающими непрерывность технологического процесса",
          "Системами ручного (без применения вычислительной техники) регулирования",
          "Средствами взрывопредупреждения и защиты оборудования и трубопроводов от разрушений (мембранными предохранительными устройствами)",
        ],
        correctAnswers: [
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
        ],
      },
      {
        code: "20114062",
        text: "Что необходимо предусматривать в проектной документации или документации на техническое перевооружение для максимального снижения выбросов горючих и взрывопожароопасных веществ при аварийной разгерметизации системы?",
        answers: [
          "Установку запорных и (или) отсекающих устройств с временем срабатывания, устанавливаемым в проектной документации",
          "Установку запорных и (или) отсекающих устройств с автоматическим управлением и временем срабатывания не более 120 секунд",
          "Установку запорных и (или) отсекающих устройств с автоматическим управлением и временем срабатывания не более 300 секунд",
        ],
        correctAnswers: [
          "Установку запорных и (или) отсекающих устройств с временем срабатывания, устанавливаемым в проектной документации",
        ],
      },
      {
        code: "20114063",
        text: "Каким требованиям должны соответствовать специальные системы аварийного освобождения технологических блоков от обращающихся продуктов? Выберите 2 правильных варианта ответа.",
        answers: [
          "Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий",
          "Обеспечивать минимально возможное время освобождения",
          "Быть мобильными, иметь небольшой вес и габариты",
          "Переход в рабочее состояние из режима ожидания должен осуществляться в течение не более 30 с",
        ],
        correctAnswers: [
          "Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий",
          "Обеспечивать минимально возможное время освобождения",
        ],
      },
      {
        code: "20114064",
        text: "Что устанавливается на линию подачи инертных газов (пар, азот, и другие среды) в процессах, при которых в результате отклонения от заданных технологических режимов возможно попадание взрывопожароопасных продуктов в нее?",
        answers: [
          "Обратный клапан",
          "Предохранительный  запорный клапан",
          "Гидроклапан",
          "Регулирующий клапан",
          "Аварийный клапан",
        ],
        correctAnswers: ["Обратный клапан"],
      },
      {
        code: "20114065",
        text: "На основании каких данных определяются допустимые значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывопожароопасных характеристик, физико-химических свойств?",
        answers: [
          "На основании задания на проектирование",
          "На основании взрывопожароопасных характеристик",
          "На основании расчетных данных на проектирование",
          "На основании физико-химических свойств",
          "На основании характеристик процесса",
        ],
        correctAnswers: ["На основании задания на проектирование"],
      },
      {
        code: "20114066",
        text: "С учетом каких параметров в каждом конкретном случае в проектной документации (документации на техническое перевооружение) обосновывается решение о типе арматуры и месте ее установки на линиях всасывания и нагнетания, а также способе ее отключения, в том числе дистанционном? Укажите все правильные ответы.",
        answers: [
          "С учетом диаметра и протяженности трубопровода",
          "С учетом толщины стенки  и видов футеровки трубопровода",
          "С учетом характеристики транспортируемой среды",
          "С учетом марки стали, структуры и свойств материала трубопровода",
        ],
        correctAnswers: [
          "С учетом диаметра и протяженности трубопровода",
          "С учетом характеристики транспортируемой среды",
        ],
      },
      {
        code: "20114067",
        text: "В зависимости от чего осуществляется выбор конструкции и конструкционных материалов, уплотнительных устройств для насосов и компрессоров?",
        answers: [
          "В зависимости от вида взрывозащиты и механической прочности в расчете на полное давление взрыва",
          "В зависимости от конструктивных особенностей трубопровода и его протяженности",
          "В зависимости от свойств перемещаемой среды",
          "В зависимости от всех перечисленных факторов",
        ],
        correctAnswers: ["В зависимости от свойств перемещаемой среды"],
      },
      {
        code: "20114068",
        text: "Какие сведения являются основополагающими для выбора оборудования при разработке технологических процессов?",
        answers: [
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
          "Расчетные данные, которым должны соответствовать требования действующих нормативных документов и параметры оборудования",
          "Расчетные данные, которым должны соответствовать параметры  и показатели надежности оборудования",
          "Расчетные данные, которым должны соответствовать задание на проектирование, требования действующих нормативных документов и параметры оборудования",
        ],
        correctAnswers: [
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
      },
      {
        code: "20114069",
        text: "Как производителем должна подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств до начала их применения на опасном производственном объекте?",
        answers: [
          "Посредством получения заключения научно-исследовательской организации, специализирующейся в области экспертизы промышленной безопасности и разработки аналогичного оборудования",
          "Посредством получения результатов опытных работ, проводимых разработчиком данного оборудования, и экспертизы промышленной безопасности",
          "Посредством получения документального подтверждения оценки научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования",
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
        ],
        correctAnswers: [
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
        ],
      },
      {
        code: "20114070",
        text: "В соответствии с чем следует производить монтаж технологического оборудования и трубопроводов?",
        answers: [
          "В соответствии с проектной документацией",
          "В соответствии с рабочей документацией",
          "В соответствии со всем перечисленным",
        ],
        correctAnswers: ["В соответствии со всем перечисленным"],
      },
      {
        code: "20114071",
        text: "Как требуется размещать технологическое оборудование взрывопожароопасных производств?",
        answers: [
          "С учетом возможности проведения ремонтных работ и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий",
          "С учетом обеспечения безопасности обслуживания и эксплуатации",
          "С учетом возможности проведения визуального контроля за их состоянием, выполнения работ по обслуживанию, ремонту и замене",
          "С учетом всех перечисленных требований",
        ],
        correctAnswers: ["С учетом всех перечисленных требований"],
      },
      {
        code: "20114072",
        text: "Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?",
        answers: [
          "Оборудование должно быть демонтировано",
          "Оборудование должно быть изолировано от действующей системы, и нанесенное на нем обозначение номера по технологической схеме закрашено",
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно должно быть изолировано от действующих систем",
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем",
        ],
        correctAnswers: [
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем",
        ],
      },
      {
        code: "20114073",
        text: "Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития? Выберите 2 правильных варианта ответа.",
        answers: [
          "Запорную и запорно-регулирующую арматуру",
          "Клапаны, отсекающие и другие отключающие устройства",
          "Комплексные системы безопасности (подавления взрыва и пожара)",
          "Средства, регистрирующие превышение давления выше установленных норм",
        ],
        correctAnswers: [
          "Запорную и запорно-регулирующую арматуру",
          "Клапаны, отсекающие и другие отключающие устройства",
        ],
      },
      {
        code: "20114074",
        text: "Какие из перечисленных функций должна обеспечивать автоматизированная система управления технологическими процессами на базе средств вычислительной техники? Укажите все правильные ответы.",
        answers: [
          "Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации",
          "Периодический, не реже 2 раз в течение смены, контроль за состоянием воздушной среды в пределах объекта",
          "Цикличный, по утвержденному графику, контроль за параметрами процесса и управление режимами для поддержания их регламентированных значений",
          "Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений",
        ],
        correctAnswers: [
          "Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации",
          "Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений",
        ],
      },
      {
        code: "20114075",
        text: "Что должны обеспечивать системы противоаварийной автоматической защиты и управления технологическими процессами? Укажите все правильные ответы.",
        answers: [
          "Отключение систем в случае переключений на резервный или аварийный источник электропитания не позднее 0,5 с",
          "Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса",
          "Автоматический возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной автоматической защиты после устранения причины срабатывания",
          "Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления",
        ],
        correctAnswers: [
          "Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса",
          "Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления",
        ],
      },
      {
        code: "20114076",
        text: "Где приводятся конкретные значения уставок систем защиты по опасным параметрам?",
        answers: [
          "В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции",
          "Только в технологическом регламенте на производство продукции",
          "Только в проектной документации",
        ],
        correctAnswers: [
          "В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20114077",
        text: "Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания противоаварийной автоматической защиты?",
        answers: [
          "Выполняется средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых переключений согласно инструкции",
          "Выполняется средствами автоматического управления объектом по действующим программам",
          "Выполняется средствами автоматического управления объектом по действующим программам после устранения всех причин, приведших к срабатыванию противоаварийной автоматической защиты",
          "Выполняется обслуживающим персоналом по инструкции",
        ],
        correctAnswers: ["Выполняется обслуживающим персоналом по инструкции"],
      },
      {
        code: "20114078",
        text: "Кто обосновывает достаточность аппаратурного резервирования и его тип?",
        answers: [
          "Представитель организации-изготовителя систем противоаварийной автоматической защиты",
          "Технический руководитель организации",
          "Руководитель ремонтных работ",
          "Разработчик проекта",
          "Разработчик технологического процесса",
        ],
        correctAnswers: ["Разработчик проекта"],
      },
      {
        code: "20114079",
        text: "Какие требования предъявляются к пневматическим системам контроля, управления и противоаварийной автоматической защиты?",
        answers: [
          "Качество сжатого воздуха должно проверяться не реже 1 раза в квартал",
          "Для пневматических систем контроля, управления и противоаварийной защиты предусматриваются единые установки и единые сети сжатого воздуха",
          "Использование инертного газа для питания систем контрольно-измерительных приборов и устройств автоматического регулирования определяется условиями эксплуатации технологического оборудования",
          "Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги",
        ],
        correctAnswers: [
          "Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги",
        ],
      },
      {
        code: "20114080",
        text: "Каким образом обеспечивается надежность обеспечения средств управления и систем противоаварийной защиты сжатым воздухом?",
        answers: [
          "Установкой резервного компрессора с его автоматическим включением при остановке рабочего компрессора",
          "Путем переключения через осушитель заводской сети сжатого воздуха на сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение не менее 1 часа после остановки компрессоров",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
        correctAnswers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20114081",
        text: "Каким требованиям должны соответствовать помещения управления? Выберите 2 правильных варианта ответа.",
        answers: [
          "Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности",
          "Полы в помещении должны быть нескользкими, электропроводными и устойчивыми к механическим нагрузкам",
          "Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам",
          "Защита от механических повреждений проложенных по полу кабелей должна осуществляться с помощью металлических швеллеров и уголков",
        ],
        correctAnswers: [
          "Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности",
          "Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам",
        ],
      },
      {
        code: "20114082",
        text: "С какими подразделениями должна быть обеспечена связь посредством системы двусторонней громкоговорящей связи на объектах с технологическими блоками I категории взрывоопасности?",
        answers: [
          "Только с персоналом диспетчерских пунктов, газоспасательной службой и штабом гражданской обороны",
          "Только с пожарной частью и сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов",
          "С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов",
        ],
        correctAnswers: [
          "С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов",
        ],
      },
      {
        code: "20114083",
        text: "Где предусматривается установка постов управления и технических средств связи и оповещения для извещения об опасных выбросах химически опасных веществ на объектах, имеющих в своем составе блоки I категории взрывоопасности? Выберите 2 правильных варианта ответа.",
        answers: [
          "На контрольно-пропускном пункте объекта",
          "В помещении диспетчера предприятия",
          "На ограждении по периметру объекта через каждые 50 м",
          "На наружных установках",
        ],
        correctAnswers: [
          "В помещении диспетчера предприятия",
          "На наружных установках",
        ],
      },
      {
        code: "20114084",
        text: "При каких условиях допускается отключение защит (единовременно не более одного параметра) для непрерывных процессов?",
        answers: [
          "При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, только в дневную смену - в присутствии начальника производства и начальника службы КИПиА (главного прибориста)",
          "При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, только в дневную смену - по устному разрешению технического руководителя организации и в присутствии начальника производства",
          "При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами",
        ],
        correctAnswers: [
          "При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами",
        ],
      },
      {
        code: "20114085",
        text: "В каком документе указываются регламентированные значения параметров по ведению технологического процесса?",
        answers: [
          "В руководстве по эксплуатации оборудования",
          "В руководстве по безопасности",
          "В техническом регламенте",
          "В технологическом регламенте на производство продукции",
        ],
        correctAnswers: [
          "В технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20114086",
        text: "Кем разрабатываются исходные данные на разработку документации на химически опасных производственных объектах?",
        answers: [
          "Территориальными органами Ростехнадзора",
          "Организациями, эксплуатирующими химически опасные производственные объекты",
          "Лицензированными организациями, осуществляющими проведение экспертизы промышленной безопасности",
          "Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области",
        ],
        correctAnswers: [
          "Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области",
        ],
      },
      {
        code: "20114087",
        text: "Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?",
        answers: [
          "Рациональный подбор взаимодействующих компонентов, исходя из условия максимального снижения или исключения образования химически опасных смесей или продуктов",
          "Применение компонентов в фазовом состоянии, затрудняющем или исключающем образование химически опасной смеси",
          "Нарушение энергообеспечения",
          "Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки",
        ],
        correctAnswers: [
          "Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки",
        ],
      },
      {
        code: "20114088",
        text: "В каком случае допускается объединение выбросов химически опасных веществ, содержащих вещества, способные при смешивании образовывать более опасные по воздействиям химические соединения?",
        answers: [
          "В случае разработки необходимых мер, предусмотренных документацией на химически опасных производственных объектах",
          "В случае использования специальных систем аварийного освобождения",
          "Если выбросы рассчитываются в количествах, определяемых условиями безопасной остановки технологического процесса",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20114089",
        text: "В каком документе организация, эксплуатирующая химически опасные производственные объекты I, II и III классов опасности, должна предусматривать действия работников по предупреждению аварий, локализации и максимальному снижению тяжести последствий?",
        answers: [
          "В технологическом регламенте пожарной безопасности",
          "В техническом регламенте",
          "В стандарте предприятия",
          "В плане мероприятий по локализации и ликвидации последствий аварий",
        ],
        correctAnswers: [
          "В плане мероприятий по локализации и ликвидации последствий аварий",
        ],
      },
      {
        code: "20114090",
        text: "В соответствии с какими документами осуществляют ведение технологических процессов на химически опасных производственных объектах?",
        answers: [
          "В соответствии с проектной документацией химически опасного производственного объекта (ХОПО)",
          "В соответствии с положениями о промышленной безопасности ХОПО и производственном контроле ХОПО",
          "В соответствии с руководством по эксплуатации ХОПО и  местными инструкциями",
          "В соответствии с технологическими регламентами на производство продукции",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами на производство продукции",
        ],
      },
      {
        code: "20114091",
        text: "Для каких из перечисленных производств разрабатываются постоянные технологические регламенты?",
        answers: [
          "Для действующих химико-технологических производств, в технологию которых внесены принципиальные изменения",
          "Для производств с новой технологией",
          "Для новых в данной организации производств",
          "Для всех перечисленных производств",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20114092",
        text: "Какие технологические регламенты разрабатываются при выпуске товарной продукции на опытных и опытно-промышленных установках (цехах), а также для опытных и опытно-промышленных работ, проводимых на действующих производствах?",
        answers: [
          "Постоянные технологические регламенты",
          "Временные технологические регламенты",
          "Лабораторные технологические регламенты",
          "Разовые (опытные) технологические регламенты",
        ],
        correctAnswers: ["Разовые (опытные) технологические регламенты"],
      },
      {
        code: "20114093",
        text: "В каком случае допускается наработка товарной продукции по лабораторным регламентам (пусковым запискам, производственным методикам)?",
        answers: [
          "Объемом до 1000 кг/год",
          "Объемом до 1500 кг/год",
          "Объемом до 2000 кг/год",
          "Ни в каком случае",
        ],
        correctAnswers: ["Объемом до 1000 кг/год"],
      },
      {
        code: "20114094",
        text: 'Что необходимо указывать в описании процессов разделения химических продуктов (горючих или их смесей с негорючими) в разделе "Описание химико-технологического процесса и схемы"?',
        answers: [
          "Степень разделения сред, меры взрывобезопасности, показатели токсичности и пожароопасности",
          "Токсичные свойства всех веществ, участвующих в процессе на всех стадиях, их показатели взрывопожароопасности",
          "Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса",
          "Только меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса",
        ],
        correctAnswers: [
          "Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса",
        ],
      },
      {
        code: "20114095",
        text: "Как необходимо составлять описание схемы процесса при наличии нескольких аналогичных технологических ниток?",
        answers: [
          "Допускается делать описание схемы процесса по одной технологической нитке",
          "Допускается делать описание схемы процесса не менее чем по двум технологическим ниткам",
          "Необходимо представить описание всех технологических ниток с разбивкой их на группы",
          "Необходимо представить описание каждой технологической нитки",
        ],
        correctAnswers: [
          "Допускается делать описание схемы процесса по одной технологической нитке",
        ],
      },
      {
        code: "20114096",
        text: 'Какие разновидности материального баланса допускается составлять в разделе технологического регламента "Материальный баланс"?',
        answers: [
          "На единицу времени (час)",
          "На единицу выпускаемой продукции",
          "На один производственный поток или на мощность производства в целом",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20114097",
        text: "Кем разрабатываются все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?",
        answers: [
          "Организацией-разработчиком процесса",
          "Специализированной лицензированной организацией",
          "Комиссией организации, эксплуатирующей химико-технологическое производство, под председательством инспектора территориального органа Ростехнадзора",
          "Организацией, эксплуатирующей химико-технологическое производство",
        ],
        correctAnswers: [
          "Организацией, эксплуатирующей химико-технологическое производство",
        ],
      },
      {
        code: "20114098",
        text: "Чьими подписями оформляется лист подписей постоянного (временного, разового, лабораторного) технологического регламента? Укажите все правильные ответы.",
        answers: [
          "Начальника центральной лаборатории организации",
          "Начальника службы охраны труда и промышленной безопасности",
          "Заместителя руководителя организации по охране окружающей среды",
          "Начальника цеха",
          "Начальника производства",
        ],
        correctAnswers: ["Начальника цеха", "Начальника производства"],
      },
      {
        code: "20114099",
        text: "Какое количество копий технологических регламентов устанавливается требованиями Правил безопасности химически опасных производственных объектов?",
        answers: [
          "Не менее 6 копий",
          "Не менее 10 копий",
          "Не менее 15 копий",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20114100",
        text: "Каким образом определяется срок действия временного технологического регламента при отсутствии установленных планами норм освоения производства?",
        answers: [
          "Приказом руководителя эксплуатирующей организации",
          "Техническим руководителем эксплуатирующей организации",
          "Лицом, утверждающим технологический регламент",
          "В соответствии с Единой системой технологической подготовки производства",
          "В соответствии с отраслевыми Методическими рекомендациями по разработке технологического регламента с учетом времени, необходимого для составления постоянного регламента",
        ],
        correctAnswers: ["Лицом, утверждающим технологический регламент"],
      },
      {
        code: "20114101",
        text: "Каким образом устанавливается и оформляется срок продления действия временного технологического регламента?",
        answers: [
          "Приказом комиссии эксплуатирующей организации",
          "Приказом или распоряжением лица, ответственного за исправное состояние и эксплуатацию оборудования с согласованием технологической службой организации",
          "Приказом главного инженера организации (технического директора, директора по производству)",
          "Приказом руководителя организации",
        ],
        correctAnswers: ["Приказом руководителя организации"],
      },
      {
        code: "20114102",
        text: "Кто устанавливает назначенный срок службы для технологического оборудования, машин и трубопроводной арматуры?",
        answers: [
          "Орган по сертификации продукции",
          "Комиссия эксплуатирующей организации на основании заключения испытательной лаборатории",
          "Разработчик документации",
          "Организация-изготовитель",
        ],
        correctAnswers: ["Организация-изготовитель"],
      },
      {
        code: "20114103",
        text: "Какой объем неразрушающего контроля сварных соединений технологических трубопроводов, транспортирующих токсичные и высокотоксичные вещества, предусмотрен в Правилах безопасности химически опасных производственных объектов?",
        answers: [
          "Определяется специалистами неразрушающего контроля",
          "Не менее 50% длины сварного шва каждого сварного соединения",
          "Не менее 75% длины сварного шва каждого сварного соединения",
          "Не менее 100% длины сварного шва каждого сварного соединения",
        ],
        correctAnswers: [
          "Не менее 100% длины сварного шва каждого сварного соединения",
        ],
      },
      {
        code: "20114104",
        text: "Что необходимо учитывать при размещении технологического оборудования, трубопроводной арматуры в производственных зданиях и на открытых площадках?",
        answers: [
          "Возможность быстрого демонтажа оборудования и минимизацию контакта с коррозионно-активными веществами",
          "Соблюдение расстояний, предусмотренных требованиями пожарной безопасности",
          "Возможность осуществления визуального контроля за состоянием технологического оборудования и трубопроводной арматуры",
          "Возможность проведения ремонтных работ и принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий",
        ],
        correctAnswers: [
          "Возможность проведения ремонтных работ и принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий",
        ],
      },
      {
        code: "20114105",
        text: "В какой документации должны быть определены порядок контроля за степенью коррозионного износа оборудования и трубопроводов с использованием методов неразрушающего контроля, способы, периодичность и места проведения контрольных замеров?",
        answers: [
          "В технологических регламентах",
          "В нормативной документации в области промышленной безопасности",
          "В инструкциях по монтажу и эксплуатации оборудования",
          "В эксплуатационной документации организации-изготовителя",
        ],
        correctAnswers: [
          "В эксплуатационной документации организации-изготовителя",
        ],
      },
      {
        code: "20114106",
        text: "Что в химико-технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и их развития?",
        answers: [
          "Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы",
          "Запорная арматура, средства защиты от превышения давления, огнепреградители",
          "Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов",
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления",
        ],
      },
      {
        code: "20114107",
        text: "Какое минимальное количество датчиков должно устанавливаться на химически опасных производственных объектах I и II классов опасности для осуществления контроля за текущими показателями параметров, определяющими химическую опасность технологических процессов ХОПО?",
        answers: [
          "Правилами безопасности химически опасных производственных объектов не регламентируется",
          "Не более 2 независимых датчиков с раздельными точками отбора",
          "Не менее 5 независимых датчиков с раздельными точками отбора",
          "Не менее 2 независимых датчиков с раздельными точками отбора",
        ],
        correctAnswers: [
          "Не менее 2 независимых датчиков с раздельными точками отбора",
        ],
      },
      {
        code: "20114108",
        text: "Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?",
        answers: [
          "Средствами автоматического управления объектом по действующим программам после устранения всех неполадок, приведших к срабатыванию системы противоаварийной защиты",
          "Средствами автоматического управления объектом по действующим программам после выполнения обслуживающим персоналом всех необходимых переключений согласно инструкции",
          "Средствами автоматического управления объектом по действующим программам",
          "Обслуживающим персоналом по инструкции",
        ],
        correctAnswers: ["Обслуживающим персоналом по инструкции"],
      },
      {
        code: "20114109",
        text: "Какие материалы применяются для изготовления, монтажа и ремонта технологического оборудования и трубопроводов для производств, использующих неорганические кислоты и щелочи?",
        answers: [
          "Композитные материалы",
          "Полимерные материалы",
          "Любые виды нержавеющей стали",
          "Материалы, обеспечивающие их коррозионную стойкость к рабочей среде",
        ],
        correctAnswers: [
          "Материалы, обеспечивающие их коррозионную стойкость к рабочей среде",
        ],
      },
      {
        code: "20114110",
        text: "Каким должен быть радиус кривизны отвода при изготовлении отводов способом гиба на специальных станках?",
        answers: [
          "Соответствовать диаметру трубы",
          "Не менее двух диаметров трубы",
          "Не менее трех диаметров трубы",
          "Не менее четырех диаметров трубы",
        ],
        correctAnswers: ["Не менее трех диаметров трубы"],
      },
      {
        code: "20114111",
        text: "Где допускается прокладка трубопроводов неорганических жидких кислот и (или) щелочей при условии, что трубопроводы должны быть заключены в специальные желоба или короба (коллекторы) с отводом утечек кислот и щелочей в безопасные места, определяемые проектом?",
        answers: [
          "Через административные и бытовые помещения",
          "Через вспомогательные и подсобные помещения",
          "По наружным стенам зданий, не связанных с обращением кислот и щелочей",
          "В местах пересечения железных и автомобильных дорог",
        ],
        correctAnswers: ["В местах пересечения железных и автомобильных дорог"],
      },
      {
        code: "20114112",
        text: "Чем должно быть оснащено оборудование для разделения суспензий и фильтрации?",
        answers: [
          "Блокировками, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды",
          "Манометрами, обеспечивающими контроль давления в системе при подаче суспензий",
          "Средствами измерения, обеспечивающими прекращение подачи суспензий при отклонениях от допустимых параметров химически опасных смесей в атмосфере рабочей зоны помещения",
          "Автоблокировками, прекращающими подачу материалов при остановке основного оборудования",
        ],
        correctAnswers: [
          "Блокировками, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды",
        ],
      },
      {
        code: "20114113",
        text: "В каком количестве допускается хранение в цехе суховальцованных паст для подколеровки эмалей?",
        answers: [
          "Не более 2% сменной потребности",
          "Не более 3% сменной потребности",
          "Не более 4% сменной потребности",
          "Не более 5% сменной потребности",
        ],
        correctAnswers: ["Не более 2% сменной потребности"],
      },
      {
        code: "20114114",
        text: "Какие требования установлены к насосам для перекачки растворов коллоксилина?",
        answers: [
          "Для насосов, расположенных в помещениях, должно предусматриваться их дистанционное отключение",
          "Для насосов, расположенных в помещениях, должен быть обеспечен свободный подход для обслуживающего персонала",
          "Насосы должны быть тихоходными и соответствовать требованиям токсической безопасности",
          "Насосы должны соответствовать только требованиям токсической безопасности",
        ],
        correctAnswers: [
          "Насосы должны быть тихоходными и соответствовать требованиям токсической безопасности",
        ],
      },
      {
        code: "20114115",
        text: "Каким образом необходимо наполнять цистерны фосфором?",
        answers: [
          "Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 50 °C с таким расчетом, чтобы после заполнения цистерны над поверхностью фосфора был слой воды или незамерзающего раствора высотой не менее 300 мм и свободное пространство не менее 10% объема цистерны",
          "Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 25 °С. Слой воды или раствора должен быть высотой не менее 100 мм, свободное пространство цистерны - не менее 10%",
          "Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 50 °С. Слой воды или раствора должен быть высотой не менее 500 мм",
          "Перед наполнением цистерн их необходимо продуть инертным газом",
        ],
        correctAnswers: [
          "Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 50 °C с таким расчетом, чтобы после заполнения цистерны над поверхностью фосфора был слой воды или незамерзающего раствора высотой не менее 300 мм и свободное пространство не менее 10% объема цистерны",
        ],
      },
      {
        code: "20114116",
        text: "В каких условиях должна проводиться реакция получения фосфида цинка?",
        answers: [
          "Мерник для фосфора должен быть снабжен устройством, предотвращающим попадание воды в реактор",
          "Мерник для фосфора должен быть снабжен устройством, предотвращающим попадание воздуха в реактор",
          "Требуется включить муфель перед установкой реактора",
          "Реактор должен быть тщательно продут инертным газом в течение не более 3 минут перед дозировкой фосфора",
        ],
        correctAnswers: [
          "Мерник для фосфора должен быть снабжен устройством, предотвращающим попадание воды в реактор",
        ],
      },
      {
        code: "20114117",
        text: "Какая величина избыточного давления должна постоянно быть в системе электровозгонки фосфора?",
        answers: [
          "Не более 3 мм водяного столба",
          "Не более 5 мм водяного столба",
          "Не менее 5 мм водяного столба",
          "Не менее 3 мм водяного столба",
        ],
        correctAnswers: ["Не менее 3 мм водяного столба"],
      },
      {
        code: "20114118",
        text: "Какая устанавливается минимальная высота гидрозатвора в приемном баке при гидравлическом способе удаления пыли из электрофильтров при производстве фосфора и его соединений?",
        answers: [
          "100 мм с учетом конуса, образующегося при работе мешалки",
          "200 мм с учетом конуса, образующегося при работе мешалки",
          "300 мм с учетом конуса, образующегося при работе мешалки",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "200 мм с учетом конуса, образующегося при работе мешалки",
        ],
      },
      {
        code: "20114119",
        text: "В каких условиях должна проводиться реакция синтеза пятисернистого фосфора?",
        answers: [
          "Выбор необходимых и достаточных условий процесса определяется его разработчиком",
          "В аппаратах, обогреваемых горячим паром",
          "Реакция должна протекать при работающей мешалке",
          "В герметичных аппаратах в атмосфере инертного газа",
        ],
        correctAnswers: ["В герметичных аппаратах в атмосфере инертного газа"],
      },
      {
        code: "20114120",
        text: "Какой минимальный уклон должны иметь внутрицеховые трубопроводы для пятисернистого фосфора?",
        answers: ["0,005", "0,002", "0,02", "0,1"],
        correctAnswers: ["0,1"],
      },
      {
        code: "20114121",
        text: "Что должна обеспечивать система электрического управления механизмами поточно-транспортных систем при производстве фосфора и его соединений?",
        answers: [
          "Только электрическую блокировку всех механизмов от завала транспортируемых веществ с применением реле скорости для элеваторов и транспортеров",
          "Только предотвращение пуска механизмов при проведении ремонтных и профилактических работ с оборудованием",
          "Только аварийное отключение транспортеров с помощью троса, соединенного с выключателем",
          "Только предпусковую звуковую сигнализацию",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20114122",
        text: "В каком случае допускается предусматривать отдельные отделители жидкости, соединенные трубопроводами с циркуляционными (защитными) ресиверами, не совмещающими функции отделителя жидкости, для отделения жидкой фазы из перемещаемой парожидкостной смеси в системах  холодоснабжения?",
        answers: [
          "Допускается для вертикальных сосудов цилиндрической формы",
          "Допускается для сосудов горизонтального типа",
          "Допускается в обоснованных в проектной документации случаях",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "Допускается в обоснованных в проектной документации случаях",
        ],
      },
      {
        code: "20114123",
        text: "Как рассчитывается геометрический объем защитных ресиверов (Vз.р.) вертикального типа, совмещающих функцию отделителя жидкости аммиачных холодильных установок, для каждой температуры кипения аммиака?",
        answers: [
          "Vз.р. больше Vс х 0,2 м³",
          "Vз.р. больше Vс х 0,5 м³",
          "Vз.р. больше Vс х 0,9 м³",
          "Vз.р. больше Vс х 1,5 м³",
        ],
        correctAnswers: ["Vз.р. больше Vс х 0,5 м³"],
      },
      {
        code: "20114124",
        text: "Какое допускается максимальное заполнение геометрического объема дренажного ресивера для аварийного (ремонтного) освобождения от жидкого аммиака охлаждающих устройств, аппаратов, сосудов и блоков?",
        answers: ["На 50%", "На 70%", "На 80%", "На 90%"],
        correctAnswers: ["На 80%"],
      },
      {
        code: "20114125",
        text: "Где следует устанавливать блочные холодильные машины?",
        answers: [
          "На открытых площадках, если это позволяет установленное изготовителем исполнение этого оборудования с учетом требований норм охраны труда",
          "Место размещения определяется проектной документацией",
          "На открытых площадках с учетом климатических особенностей местности",
          "В помещении машинного (аппаратного) отделения",
        ],
        correctAnswers: ["В помещении машинного (аппаратного) отделения"],
      },
      {
        code: "20114126",
        text: "Каким следует принимать расстояние в свету от аппаратов (сосудов), расположенных снаружи машинного (аппаратного) отделения?",
        answers: [
          "Не менее 1,0 м от стены здания",
          "Не менее 1,5 м от стены здания",
          "Не менее 2,0 м от стены здания",
          "Не менее 2,5 м от стены здания",
        ],
        correctAnswers: ["Не менее 1,0 м от стены здания"],
      },
      {
        code: "20114127",
        text: "В каком случае допускается размещать холодильное оборудование над площадками открытых насосных и компрессорных установок? Укажите все правильные ответы.",
        answers: [
          "В случае применения герметичных (бессальниковых) насосов",
          "При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование",
          "В случае наличия обоснования в проектной документации для размещения холодильного оборудования над площадками с учетом особенностей местности и допустимых расстояний",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "В случае применения герметичных (бессальниковых) насосов",
          "При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование",
        ],
      },
      {
        code: "20114128",
        text: "Каким минимальным количеством эвакуационных выходов должны оснащаться помещения машинного и аппаратного отделения?",
        answers: [
          "Одним",
          "Двумя",
          "Тремя",
          "Количеством выходов, установленных проектной документацией",
        ],
        correctAnswers: ["Двумя"],
      },
      {
        code: "20114129",
        text: "В каком случае допускается оснащать сосуды холодильных систем одним предохранительным клапаном?",
        answers: [
          "Если геометрический объем сосуда не превышает 0,1 м³",
          "Если геометрический объем сосуда не превышает 0,2 м³",
          "Если геометрический объем сосуда не превышает 0,3 м³",
          "Если работа клапана рассчитана на полную пропускную способность",
        ],
        correctAnswers: [
          "Если геометрический объем сосуда не превышает 0,3 м³",
        ],
      },
      {
        code: "20114130",
        text: "С какой периодичностью предохранительные устройства компрессорных агрегатов должны проверяться на давление срабатывания?",
        answers: [
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 5 лет",
          "Не реже 1 раза в 10 лет",
          "Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией",
        ],
        correctAnswers: [
          "Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией",
        ],
      },
      {
        code: "20114131",
        text: "В каком случае допускается использование ртутных термометров и ртутных устройств для измерения температуры в контрольных точках аммиачной холодильной системы?",
        answers: [
          "В случае наличия обоснования в проектной документации",
          "В случае установки для них защиты от механических повреждений",
          "Не допускается ни в каком случае",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20114132",
        text: "С какой периодичностью необходимо проверять исправность защитных реле уровня на аппаратах (сосудах)?",
        answers: [
          "1 раз в месяц",
          "1 раз в 3 месяца",
          "1 раз в 6 месяцев",
          "1 раз в год",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20114133",
        text: "С какой периодичностью необходимо проверять исправность автоматических приборов защиты аммиачных компрессоров и сигнализаторов концентрации паров аммиака в воздухе помещений и наружных площадок?",
        answers: [
          "Не реже 1 раза в 10 дней",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20114134",
        text: "На какую массовую нагрузку должны быть рассчитаны специальные опоры или подвески, на которые монтируются аммиачные трубопроводы аммиачных холодильных установок?",
        answers: [
          "На массу трубопровода, принятую с коэффициентом запаса 1,1",
          "На собственную массу трубопровода, массу хладагента и тепловой изоляции, принятых с коэффициентом запаса 1,2",
          "На массу тепловой изоляции, принятую с коэффициентом запаса 1,5",
          "На массу хладагента, принятую с коэффициентом запаса 2,1",
        ],
        correctAnswers: [
          "На собственную массу трубопровода, массу хладагента и тепловой изоляции, принятых с коэффициентом запаса 1,2",
        ],
      },
      {
        code: "20114135",
        text: "На каком расстоянии от опор и подвесок следует располагать сварные стыки трубопроводов для труб диаметром менее 50 мм?",
        answers: [
          "Не менее 100 мм",
          "Не менее 150 мм",
          "Не менее 200 мм",
          "Не менее 250 мм",
        ],
        correctAnswers: ["Не менее 100 мм"],
      },
      {
        code: "20114136",
        text: "Какие сроки освидетельствования систем холодоснабжения с ограниченной зарядкой аммиаком (не более 50 кг), поставляемых комплектно организациями-изготовителями?",
        answers: [
          "Устанавливаются эксплуатационной документацией организаций-изготовителей",
          "Устанавливаются эксплуатирующей организацией - лицом, ответственным за исправное состояние и безопасную работу сосудов (аппаратов)",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в год",
        ],
        correctAnswers: [
          "Устанавливаются эксплуатационной документацией организаций-изготовителей",
        ],
      },
      {
        code: "20114137",
        text: "В течение какого времени и при каком давлении следует проводить вакуумирование холодильной установки перед пуском в эксплуатацию после пневматических испытаний?",
        answers: [
          "В течение 7 часов при остаточном давлении 0,05 МПа (0,5 кгс/см²)",
          "В течение 10 часов при остаточном давлении 0,015 МПа (0,15 кгс/см²)",
          "В течение 15 часов при остаточном давлении 0,03 МПа (0,3 кгс/см²)",
          "В течение 18 часов при остаточном давлении 0,01 МПа (0,1 кгс/см²)",
        ],
        correctAnswers: [
          "В течение 18 часов при остаточном давлении 0,01 МПа (0,1 кгс/см²)",
        ],
      },
      {
        code: "20114138",
        text: "Каким должен быть процент первичного заполнения жидким аммиаком внутреннего объема воздухоохладителей с верхней подачей аммиака?",
        answers: ["15%", "30%", "50%", "80%"],
        correctAnswers: ["50%"],
      },
      {
        code: "20114139",
        text: "В течение какого времени нахождения цистерн с жидким аммиаком на территории организации должно быть организовано наблюдение за ними?",
        answers: [
          "В течение периода приемки цистерны",
          "В течение периода слива аммиака",
          "В течение всего времени нахождения",
          "Наблюдение за цистернами с жидким аммиаком не требуется",
        ],
        correctAnswers: ["В течение всего времени нахождения"],
      },
      {
        code: "20114140",
        text: "Какие действия должны быть предприняты при обнаружении нарушений требований Правил безопасности химически опасных производственных объектов в отношении цистерн с жидким аммиаком?",
        answers: [
          "Требуется немедленно сообщить о случившемся в территориальный орган Ростехнадзора",
          "Необходимо немедленно слить аммиак",
          "Необходимо поставить в известность руководителя объекта и организовать круглосуточное наблюдение за цистернами до устранения нарушений",
          "Должен быть составлен акт и необходимо сообщить об этом организации-наполнителю",
        ],
        correctAnswers: [
          "Должен быть составлен акт и необходимо сообщить об этом организации-наполнителю",
        ],
      },
      {
        code: "20114141",
        text: "Какое действие необходимо предпринять в случае перерыва слива аммиака?",
        answers: [
          "Съемные участки трубопроводов должны быть отсоединены от цистерны",
          "Оставить цистерну присоединенной к системе на время перерыва в работе по сливу аммиака",
          "Предпринять действия по усмотрению лица, ответственного за производство работ по сливу аммиака",
          "Оставить цистерну присоединенной к системе под наблюдением работников, назначенных приказом по организации",
        ],
        correctAnswers: [
          "Съемные участки трубопроводов должны быть отсоединены от цистерны",
        ],
      },
      {
        code: "20114142",
        text: "В каком случае перегрев паров аммиака, всасываемых компрессором, должен быть не менее 10 °К (°C)?",
        answers: [
          "Для одноступенчатых компрессоров",
          "Для ступени высокого давления двухступенчатых компрессоров",
          "Для ступени низкого давления двухступенчатых компрессоров",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Для ступени низкого давления двухступенчатых компрессоров",
        ],
      },
      {
        code: "20114143",
        text: "Какая максимальная температура нагнетания должна быть для поршневых компрессоров, если инструкцией организации-изготовителя не предусмотрено иное значение?",
        answers: ["90 °C", "135 °C", "160 °C", "185 °C"],
        correctAnswers: ["160 °C"],
      },
      {
        code: "20114144",
        text: "Что из перечисленного не допускается при эксплуатации систем холодоснабжения? Укажите все правильные ответы.",
        answers: [
          "Эксплуатация винтовых компрессоров с впрыском жидкого аммиака, если это предусмотрено инструкцией организации-изготовителя",
          "Установка вспрыскивающих устройств, если это предусмотрено инструкцией организации-изготовителя",
          "Впрыск жидкого аммиака во всасывающий трубопровод (полость) поршневого компрессора",
          "Использование переносных приборов в местах регулярного контроля работы аммиачной холодильной установки",
        ],
        correctAnswers: [
          "Впрыск жидкого аммиака во всасывающий трубопровод (полость) поршневого компрессора",
          "Использование переносных приборов в местах регулярного контроля работы аммиачной холодильной установки",
        ],
      },
      {
        code: "20114145",
        text: "В каком случае допускается запускать аммиачный насос?",
        answers: [
          "В случае если закрыты клапаны на его входе и выходе",
          "В случае неполного заполнения насоса жидким хладагентом",
          "В случае отсутствия защитного кожуха муфты",
          "Во всех перечисленных случаях",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20114146",
        text: "С какой периодичностью необходимо проверять отходящую из конденсатора воду на присутствие аммиака?",
        answers: [
          "Не реже одного раза в неделю",
          "Не реже одного раза в месяц",
          "Не реже одного раза в три месяца",
          "Не реже одного раза в полгода",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20114147",
        text: "Какое условие при механической очистке труб конденсатора от водяного камня является неверным?",
        answers: [
          "Работа должна выполняться с оформлением наряда-допуска",
          "Работа должна выполняться под руководством технического руководителя организации",
          "Работа должна выполняться только после освобождения конденсатора от аммиака",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Работа должна выполняться под руководством технического руководителя организации",
        ],
      },
      {
        code: "20114148",
        text: "С какой периодичностью необходимо проверять промежуточный хладоноситель в системах охлаждения на присутствие аммиака?",
        answers: [
          "Не реже 1 раза в неделю",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в полгода",
          "Не реже 1 раза в год",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20114149",
        text: "В каком случае аппарат (сосуд) подлежит немедленной остановке?",
        answers: [
          "В случае неисправности указателя уровня жидкости",
          "В случае неисправности предохранительных клапанов",
          "В случае неисправности предусмотренных проектной документацией контрольно-измерительных приборов и средств автоматики",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20114150",
        text: "В каком положении должны быть опломбированы запорные клапаны на аммиачных газовых нагнетательных трубопроводах?",
        answers: [
          "В открытом",
          "В закрытом",
          "Не имеет значения",
          "Все ответы неверны",
        ],
        correctAnswers: ["В открытом"],
      },
      {
        code: "20114151",
        text: "При каких условиях допускается проводить технологический взрывоопасный процесс в области критических значений температур на объектах, связанных с производством растительных масел?",
        answers: [
          "В случае принятия мер, исключающих или предотвращающих появление источников зажигания",
          "При наличии разрешения руководителя объекта",
          "При наличии у персонала огнезащитных средств индивидуальной защиты",
          "Ни при каких условиях",
        ],
        correctAnswers: [
          "В случае принятия мер, исключающих или предотвращающих появление источников зажигания",
        ],
      },
      {
        code: "20114152",
        text: "Каким документом определяются выбор способа подачи флегматизатора, его количество для обеспечения эффективности продувки и исключения возможности образования застойных зон на объектах, связанных с производством растительных масел?",
        answers: [
          "Техническим регламентом",
          "Технологическим регламентом",
          "Инструкцией предприятия по безопасному производству работ",
          "Проектной документацией",
        ],
        correctAnswers: ["Проектной документацией"],
      },
      {
        code: "20114153",
        text: "Что должно предшествовать пуску транспортных систем при транспортировке семян, жмыхов и шротов?",
        answers: [
          "Включение звуковой и световой сигнализации",
          "Включение общеобменной вентиляции и ее работа не менее 5 минут",
          "Включение местной вентиляции",
          "Включение противозавальных блокировок",
        ],
        correctAnswers: ["Включение звуковой и световой сигнализации"],
      },
      {
        code: "20114154",
        text: "Какая должна быть высота решетчатого вертикального ограждения для бункеров и завальных ям объектов производств растительных масел?",
        answers: [
          "Не регламентируется",
          "Не менее чем на 0,5 м выше уровня загрузки масличного сырья",
          "Не менее чем на 1 м выше уровня загрузки масличного сырья",
          "Не менее чем на 1,5 м выше уровня загрузки масличного сырья",
        ],
        correctAnswers: [
          "Не менее чем на 0,5 м выше уровня загрузки масличного сырья",
        ],
      },
      {
        code: "20114155",
        text: "Какое из перечисленных требований к процессам транспортирования и хранения масличного сырья, жмыхов и шротов указано неверно?",
        answers: [
          "Элеваторы для хранения масличного сырья и шрота должны снабжаться передвижными лебедками с люльками для спуска людей внутрь ячейки силоса в случае необходимости. Установка внутри лестниц (скоб) не допускается",
          "Для вновь строящихся элеваторов для шрота должны предусматриваться устройства, обеспечивающие разрыхление слежавшегося шрота",
          "Температуру заложенных на хранение жмыха и шрота необходимо проверять по графику, утвержденному в эксплуатирующей организации, с помощью дистанционных стационарных термометров (термоподвесок)",
          "Пневмотранспорт и его элементы должны быть заземлены путем установки электропроводящих перемычек в местах фланцевых соединений элементов",
        ],
        correctAnswers: [
          "Элеваторы для хранения масличного сырья и шрота должны снабжаться передвижными лебедками с люльками для спуска людей внутрь ячейки силоса в случае необходимости. Установка внутри лестниц (скоб) не допускается",
        ],
      },
      {
        code: "20114156",
        text: "Какое количество тормозных башмаков для закрепления различных типов цистерн должно быть установлено для закрепления железнодорожных цистерн при операциях слива, налива растворителя и масла?",
        answers: [
          "Не менее 2 на каждой цистерне",
          "Не менее 4 на каждой цистерне",
          " От 4 до 8 в зависимости от типа цистерны",
          "Определяется расчетом владельца инфраструктуры и указывается в инструкции по проведению сливоналивных работ",
        ],
        correctAnswers: [
          "Определяется расчетом владельца инфраструктуры и указывается в инструкции по проведению сливоналивных работ",
        ],
      },
      {
        code: "20114157",
        text: "Из какого материала допускается изготовление наконечников резиновых шлангов, предназначенных для сливоналивных операций, на объектах производств растительных масел? Укажите все правильные ответы.",
        answers: [
          "Из коррозионностойкой нержавеющей стали",
          "Из бронзы",
          "Из меди",
          "Из конструкционной стали (сплавов титана)",
        ],
        correctAnswers: ["Из бронзы", "Из меди"],
      },
      {
        code: "20114158",
        text: "Какой свободный объем для демпфирования температурного расширения растворителя необходимо оставлять при заполнении резервуаров на объектах производств растительных масел?",
        answers: [
          "Не менее 10%",
          "Не более 10%",
          "Не менее 15%",
          "Устанавливается в зависимости от используемого растворителя",
        ],
        correctAnswers: ["Не менее 10%"],
      },
      {
        code: "20114159",
        text: "Что необходимо немедленно предпринять при внезапной остановке жаровни объектов производств растительных масел?",
        answers: [
          "Освободить жаровню от мезги, начиная с нижнего чана",
          "Прекратить подачу воды, проконтролировать температуру мезги во избежание самовозгорания",
          "Вызвать ответственного за безопасное производство работ",
          "Обесточить электродвигатели жаровни, электродвигатели инактиватора или пропарочно-увлажнительного винтового конвейера и всех транспортных элементов, питающих жаровню, прекратить подачу глухого пара в жаровню",
        ],
        correctAnswers: [
          "Обесточить электродвигатели жаровни, электродвигатели инактиватора или пропарочно-увлажнительного винтового конвейера и всех транспортных элементов, питающих жаровню, прекратить подачу глухого пара в жаровню",
        ],
      },
      {
        code: "20114160",
        text: "В каком случае необходимо выгрузить и очистить чаны жаровни после их охлаждения от остатков мезги?",
        answers: [
          "Если превышена температура мезги",
          "Если отсутствует контроль за процессом работы жаровни со стороны рабочего персонала",
          "Если длительность остановки жаровни превышает 1 час",
          "Если длительность остановки жаровни превышает 0,5 часа",
        ],
        correctAnswers: ["Если длительность остановки жаровни превышает 1 час"],
      },
      {
        code: "20114161",
        text: "Какие из указанных действий, которые должны быть предприняты при появлении металлического стука и скрежета в чанах жаровни, указаны неверно?",
        answers: [
          "Немедленно остановить жаровню",
          "Прекратить подачу пара",
          "Освободить жаровню от мезги",
          "Немедленно провести ревизию чанов",
        ],
        correctAnswers: ["Немедленно провести ревизию чанов"],
      },
      {
        code: "20114162",
        text: "При какой минимальной остановке шнекового пресса необходимо очистить питатель и пресс от мезги?",
        answers: [
          "На 5 минут",
          "На 10 минут",
          "На 30 минут",
          "При любой внезапной остановке",
        ],
        correctAnswers: ["На 10 минут"],
      },
      {
        code: "20114163",
        text: "При каком виде остановки аппараты и трубопроводы, содержащие растворитель или мисцеллу, должны продуваться азотом или водяным паром после опорожнения?",
        answers: [
          "При аварии",
          "При ремонте",
          "При профилактике",
          "При любых видах остановки",
        ],
        correctAnswers: ["При любых видах остановки"],
      },
      {
        code: "20114164",
        text: "Каким образом должны выполняться участки перекрытий под оборудованием экстракционного цеха во избежание растекания (розлива) растворителя (мисцеллы)?",
        answers: [
          "С проемами в виде поддонов с бортиками не менее 0,1 м",
          "Без проемов и ограждаться бортом высотой не менее 0,15 м",
          "С проемами в виде поддонов с бортиками не менее 0,2 м",
          "Без проемов и ограждаться бортом высотой не менее 0,25 м",
        ],
        correctAnswers: [
          "Без проемов и ограждаться бортом высотой не менее 0,15 м",
        ],
      },
      {
        code: "20114165",
        text: "Какие действия, осуществление которых необходимо при остановке чанного испарителя (тостера) объектов производств растительных масел, указаны неверно?",
        answers: [
          "Отключается подача глухого пара",
          "Перед открытием люков тостера подается острый пар во все чаны",
          "При разгрузке тостера производится тщательная зачистка чанов от остатков шрота",
          "Проверяется целостность контура заземления",
        ],
        correctAnswers: ["Проверяется целостность контура заземления"],
      },
      {
        code: "20114166",
        text: "Какой должна быть максимальная температура наружных поверхностей оборудования и (или) теплоизоляционных покрытий, расположенных в рабочей или обслуживаемой зоне помещений объектов производств растительных масел, при металлическом покрывном слое?",
        answers: ["45 °С", "50 °С", "55 °С", "60 °С"],
        correctAnswers: ["55 °С"],
      },
      {
        code: "20114167",
        text: "Какое из перечисленных требований к нориям производств растительных масел указано неверно?",
        answers: [
          "Нории должны быть оборудованы электроблокировкой, обеспечивающей автоматическое отключение электродвигателя при перегрузках нории",
          "Нории должны быть оснащены устройством, предотвращающим обратный ход ленты, цепи",
          "Ковши норий для шрота должны выполняться из нержавеющей стали",
          "Все перечисленные ответы верны",
        ],
        correctAnswers: [
          "Ковши норий для шрота должны выполняться из нержавеющей стали",
        ],
      },
      {
        code: "20114168",
        text: "Под каким разрежением должен находиться экстрактор многократного орошения?",
        answers: [
          "0,02 - 0,05 кПа (2 - 5 мм вод. ст.)",
          "0,05 - 0,10 кПа (5 - 10 мм вод. ст.)",
          "0,10 - 0,15 кПа (10 - 15 мм вод. ст.)",
          "0,15 - 0,30 кПа (15 - 30 мм вод. ст.)",
        ],
        correctAnswers: ["0,05 - 0,10 кПа (5 - 10 мм вод. ст.)"],
      },
      {
        code: "20114169",
        text: "Кем рассчитывается и подбирается гидрозатвор для каждого типа водоотделителя объектов производств растительных масел?",
        answers: [
          "Проектной организацией",
          "Главным механиком",
          "Эксплуатирующей организацией",
          "Территориальным органом Ростехнадзора",
        ],
        correctAnswers: ["Проектной организацией"],
      },
      {
        code: "20114170",
        text: "В каком случае разрешается установка запорного устройства на вытяжной трубе объектов производств растительных масел?",
        answers: [
          "Если вытяжная труба оборудована пароэжектором или вентилятором",
          "Если запорное устройство установлено на вытяжной трубе дефлегматора",
          "Если запорное устройство установлено на вытяжной трубе конденсатора",
          "Если запорное устройство установлено на вытяжной трубе абсорбера",
        ],
        correctAnswers: [
          "Если вытяжная труба оборудована пароэжектором или вентилятором",
        ],
      },
      {
        code: "20114171",
        text: "Какие требования к технологическим трубопроводам для растворителя, мисцеллы и паров растворителя указаны неверно?",
        answers: [
          "Технологические трубопроводы размещаются с учетом возможности проведения визуального контроля их состояния",
          "Технологические трубопроводы размещаются с учетом возможности выполнения работ по обслуживанию и ремонту",
          "На маховиках или рукоятках арматуры должны быть нанесены стрелки, указывающие направления их вращения",
          "Технологические трубопроводы не должны иметь цифровых обозначений, маркировка трубопроводов осуществляется различной окраской, выполненной в соответствии с государственным стандартом",
        ],
        correctAnswers: [
          "Технологические трубопроводы не должны иметь цифровых обозначений, маркировка трубопроводов осуществляется различной окраской, выполненной в соответствии с государственным стандартом",
        ],
      },
      {
        code: "20114172",
        text: "Какой клапан должен устанавливаться на трубопроводах, подводящих острый водяной пар в аппараты для отгонки растворителя, пропаривания, барботирования?",
        answers: [
          "Редукционный клапан",
          "Предохранительный клапан",
          "Обратный клапан",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20114173",
        text: "В каких местах не допускается размещать фланцевые соединения объектов производств растительных масел?",
        answers: [
          "В местах установки арматуры",
          "В местах подсоединения трубопроводов к аппаратам",
          "На участках, на которых по условиям технологии требуется периодическая разборка для проведения чистки и ремонта трубопроводов",
          "Над местами постоянного прохода людей к рабочим площадкам при обеспечении достаточной степени герметичности",
        ],
        correctAnswers: [
          "Над местами постоянного прохода людей к рабочим площадкам при обеспечении достаточной степени герметичности",
        ],
      },
      {
        code: "20114174",
        text: "Какая арматура должна применяться в экстракционном цехе объектов производств растительных масел?",
        answers: [
          "Стальная",
          "Из неметаллических конструкционных материалов",
          "Из коррозионно-стойких неметаллических материалов",
          "Из композиционных неметаллических материалов",
        ],
        correctAnswers: ["Стальная"],
      },
      {
        code: "20114175",
        text: "Какое из перечисленных утверждений, относящихся к трубопроводной арматуре объектов производств растительных масел, допускается?",
        answers: [
          "Изготовление лючков и задвижек на перепускных течках (экстрактор, испаритель растворителя из шрота) из нержавеющей стали",
          "Использование пробковых чугунных кранов в качестве дублирующей арматуры",
          "Использование пробковых чугунных кранов в качестве арматуры противоаварийного назначения",
          "Установка запорной арматуры с ручным управлением при использовании регулирующей арматуры с дистанционным управлением в качестве отсекающих устройств",
          "Все перечисленные утверждения",
        ],
        correctAnswers: [
          "Установка запорной арматуры с ручным управлением при использовании регулирующей арматуры с дистанционным управлением в качестве отсекающих устройств",
        ],
      },
      {
        code: "20114176",
        text: "С учетом какой документации должен осуществляться выбор систем контроля, управления и противоаварийной автоматической защиты, а также систем связи и оповещения об аварийных ситуациях объектов производств растительных масел?",
        answers: [
          "Правил безопасности химически опасных производственных объектов",
          "Действующей нормативно-технической документации",
          "Документации на химически опасные производственные объекты",
          "С учетом всей перечисленной документации",
        ],
        correctAnswers: ["С учетом всей перечисленной документации"],
      },
      {
        code: "20114177",
        text: "Какими устройствами не должны оснащаться насосы, применяемые для нагнетания легковоспламеняющихся и горючих жидкостей (масло растительное и минеральное, мисцелла, растворитель)?",
        answers: [
          "Устройствами, регулирующими частоту вращения вала",
          'Блокировками, исключающими пуск и работу насоса "всухую" или прекращающими работу насоса при падении давления перемещаемой жидкости в нагнетательном патрубке насоса ниже установленного регламентом или паспортными данными или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений (верхний и нижний уровни)',
          "Средствами предупредительной сигнализации о нарушении параметров работы, влияющих на безопасность",
          "Средствами местного и дистанционного отключения, расположенными в легкодоступных местах",
        ],
        correctAnswers: ["Устройствами, регулирующими частоту вращения вала"],
      },
      {
        code: "20114178",
        text: "Каким должно быть количество деблокирующих ключей в схемах противоаварийной защиты объектов производств масел?",
        answers: [
          "Необходимое количество ключей устанавливает разработчик проекта",
          "Минимальным",
          "Не менее 3",
          "Необходимое количество ключей устанавливает разработчик проекта, но оно должно быть не менее 2",
        ],
        correctAnswers: ["Минимальным"],
      },
      {
        code: "20114179",
        text: "В течение какого минимального времени буферные емкости (реципиенты) должны обеспечивать питание сжатым воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров?",
        answers: ["15 минут", "30 минут", "1 часа", "2 часов"],
        correctAnswers: ["1 часа"],
      },
      {
        code: "20114180",
        text: "Какое из перечисленного пылеулавливающего оборудования объектов производств растительных масел допускается размещать вне зданий?",
        answers: [
          "Пылеулавливающее оборудование для сухой очистки воздуха",
          "Пылеулавливающее оборудование для очистки воздуха от минеральной пыли после сепараторов",
          "Пылеулавливающее оборудование для очистки воздуха от масличной пыли после семеновеечных машин",
          "Пылеулавливающее оборудование для мокрой очистки пылевоздушной смеси",
        ],
        correctAnswers: [
          "Пылеулавливающее оборудование для мокрой очистки пылевоздушной смеси",
        ],
      },
      {
        code: "20114181",
        text: "С какой целью предусматривается подача пара в месте расположения градирен на объектах производств растительных масел?",
        answers: [
          "С целью продувки сопел",
          "С целью продувки трубопроводов",
          "С целью размораживания льда в зимнее время",
          "Для достижения всех перечисленных целей",
        ],
        correctAnswers: ["Для достижения всех перечисленных целей"],
      },
      {
        code: "20114182",
        text: "Что необходимо делать для обеспечения взрывобезопасной эксплуатации воздухоразделительных установок организации, эксплуатирующей объекты производства продуктов разделения воздуха?",
        answers: [
          "Допускать к работе на установках персонал, обученный безопасным методам проведения работ",
          "Проводить ревизию установок в соответствии с требованиями технологического регламента",
          "Производить контроль содержания взрывоопасных примесей в технологических потоках установки в соответствии с требованиями технологического регламента",
          "Обеспечивать периодический контроль количества сливаемого жидкого криогенного продукта",
        ],
        correctAnswers: [
          "Производить контроль содержания взрывоопасных примесей в технологических потоках установки в соответствии с требованиями технологического регламента",
        ],
      },
      {
        code: "20114183",
        text: "Какая концентрация взрывоопасных примесей допускается в жидком кислороде?",
        answers: [
          "Не превышающая предельно допустимой концентрации",
          "Не превышающая нормы, установленной технологическим процессом",
          "Не превышающая 19% нормы, установленной разработчиком воздухоразделительной установки",
          "Не превышающая пределов, установленных разработчиком воздухоразделительной установки для различных стадий технологического процесса",
        ],
        correctAnswers: [
          "Не превышающая пределов, установленных разработчиком воздухоразделительной установки для различных стадий технологического процесса",
        ],
      },
      {
        code: "20114184",
        text: "Что должна обеспечивать система контроля и автоматизации воздухоразделительной установки при нарушении работы систем и устройств установки и отклонении технологических параметров, определенных технологическим регламентом?",
        answers: [
          "Световую и звуковую сигнализации при нарушении работы систем и устройств установки и отклонении технологических параметров, определенных технологическим регламентом",
          "Включение резервных (дополнительных) модернизированных устройств, обеспечивающих безопасность дальнейшей работы установки",
          "Срабатывание звуковой сигнализации при отклонении технологических параметров, определенных технологическим регламентом",
          "Автоматическое отключение установки при отклонении технологических параметров, определенных технологическим регламентом",
        ],
        correctAnswers: [
          "Световую и звуковую сигнализации при нарушении работы систем и устройств установки и отклонении технологических параметров, определенных технологическим регламентом",
        ],
      },
      {
        code: "20114185",
        text: "Какими автоматизированными устройствами необходимо оснащать воздухоразделительные установки, вырабатывающие газообразный азот?",
        answers: [
          "Автоматизированными устройствами, допускающими подачу продукционного азота с содержанием кислорода, превышающим величины, определенные проектной документацией, не более чем на 10%",
          "Устройствами, не допускающими подачу продукционного азота с содержанием кислорода, превышающим величины, определенные проектной документацией, более чем на 10%",
          "Устройствами, обеспечивающими работу световой и звуковой сигнализации при подаче продукционного азота с содержанием кислорода, превышающим величины, определенные проектной документацией",
          "Автоматизированными устройствами, исключающими подачу продукционного азота с содержанием кислорода, превышающим величины, определенные проектной документацией (документацией)",
        ],
        correctAnswers: [
          "Автоматизированными устройствами, исключающими подачу продукционного азота с содержанием кислорода, превышающим величины, определенные проектной документацией (документацией)",
        ],
      },
      {
        code: "20114186",
        text: "Какими автоматическими устройствами необходимо оснащать системы азотно-водяного охлаждения воздухоразделительных установок?",
        answers: [
          "Исключающими подачу продукционного азота",
          "Отключающими подачу воды при повышении допустимого уровня воды в воздушном скруббере более чем на 5%",
          "Отключающими подачу воды при повышении допустимого уровня воды в воздушном скруббере более чем на 10%",
          "Отключающими подачу воды при повышении допустимого уровня воды в воздушном скруббере",
        ],
        correctAnswers: [
          "Отключающими подачу воды при повышении допустимого уровня воды в воздушном скруббере",
        ],
      },
      {
        code: "20114187",
        text: "Какое требование предъявляется к продолжительности непрерывной работы аппаратов воздухоразделительных установок, в которых испаряется жидкий кислород и отогрев которых при работающей установке предусмотрен технологическим процессом?",
        answers: [
          "Продолжительность непрерывной работы не должна превышать срока, установленного техническими условиями разработчика установки",
          "Продолжительность непрерывной работы не должна превышать срока, установленного документацией завода-изготовителя",
          "Продолжительность непрерывной работы не должна превышать сроков, определенных технологическим регламентом, а также должна учитывать результаты анализов на содержание углеводородов в жидком кислороде",
          "Продолжительность непрерывной работы не устанавливается при условии исправности аппаратов и проведения всех видов технических осмотров",
        ],
        correctAnswers: [
          "Продолжительность непрерывной работы не должна превышать сроков, определенных технологическим регламентом, а также должна учитывать результаты анализов на содержание углеводородов в жидком кислороде",
        ],
      },
      {
        code: "20114188",
        text: "Какие требования предъявляются к испарению жидких криогенных продуктов разделения воздуха, сливаемых из отдельных аппаратов воздухоразделительных установок перед их отогревом?",
        answers: [
          "Испарение производится в специальных испарителях медленного слива, предусмотренных проектной документацией отдельно для каждой установки",
          "Испарение производится в испарителях, предусмотренных проектной документацией (документацией) разработчика установки",
          "Испарение производится в устройствах для испарения и слива жидких продуктов разделения воздуха, предусмотренных проектной документацией (документацией) на установки",
          "Испарение производится в специальных испарителях быстрого слива, предусмотренных проектной документацией (документацией) отдельно для каждой установки",
        ],
        correctAnswers: [
          "Испарение производится в специальных испарителях быстрого слива, предусмотренных проектной документацией (документацией) отдельно для каждой установки",
        ],
      },
      {
        code: "20114189",
        text: "Какие требования предъявляются к температурному режиму работы регенераторов?",
        answers: [
          "Температурный режим должен обеспечивать работу регенераторов в оптимальном режиме",
          "Температурный режим должен поддерживаться автоматически для обеспечения минимальных отклонений от средних рекомендованных величин",
          "Температурный режим должен поддерживаться строго в пределах, оговоренных в технологической инструкции",
          "Температурный режим должен исключать возможность заноса углеводородов в блок разделения воздуха",
        ],
        correctAnswers: [
          "Температурный режим должен исключать возможность заноса углеводородов в блок разделения воздуха",
        ],
      },
      {
        code: "20114190",
        text: "Какие требования безопасности предъявляются к клапанным коробкам регенераторов?",
        answers: [
          "Допускается частичное попадание жидкого кислорода в клапанные коробки регенераторов",
          "Контроль за отсутствием жидкого кислорода в клапанных коробках кислородных регенераторов должен осуществляться в течение смены по графику продувкой через соответствующую арматуру",
          "Необходимо контролировать отсутствие жидкого кислорода в клапанных коробках продувкой по мере необходимости клапанных коробок через соответствующие продувочные вентили",
          "Не допускается попадание жидкого кислорода в клапанные коробки регенераторов. Отсутствие жидкого кислорода в клапанных коробках необходимо контролировать",
        ],
        correctAnswers: [
          "Не допускается попадание жидкого кислорода в клапанные коробки регенераторов. Отсутствие жидкого кислорода в клапанных коробках необходимо контролировать",
        ],
      },
      {
        code: "20114191",
        text: "Что должно контролироваться перед заполнением адсорбера адсорбентом?",
        answers: [
          "Влажность и насыпная масса адсорбента",
          "Температура адсорбента и концентрация двуокиси углерода в нем",
          "Соответствие адсорбента технической документации разработчика воздухоразделительной установки (цвет, температура и влажность адсорбента)",
          "Степень загрязнения перерабатываемого воздуха (концентрация и состав взрывоопасных примесей)",
        ],
        correctAnswers: ["Влажность и насыпная масса адсорбента"],
      },
      {
        code: "20114192",
        text: "Какие требования предъявляются к работе адсорбера воздухоразделительной установки?",
        answers: [
          "Адсорберы должны заполняться любым имеющимся в наличии адсорбентом",
          "Адсорберы должны заполняться только адсорбентом, прошедшим периодическую регенерацию в соответствии с технологической инструкцией",
          "Адсорберы должны заполняться только адсорбентом, предусмотренным технологической инструкцией",
          "Адсорберы должны заполняться только адсорбентом, предусмотренным технической документацией разработчика воздухоразделительной установки",
        ],
        correctAnswers: [
          "Адсорберы должны заполняться только адсорбентом, предусмотренным технической документацией разработчика воздухоразделительной установки",
        ],
      },
      {
        code: "20114193",
        text: "Как следует производить досыпку адсорбентов в адсорберы блоков комплексной очистки?",
        answers: [
          "Следует производить на неработающем блоке разделения воздуха перед началом смены",
          "Следует производить на работающем блоке разделения воздуха по наряду-допуску",
          "Следует производить при плановой остановке воздухоразделительной установки по приказу руководителя объекта",
          "Следует производить на неработающем блоке разделения воздуха при плановой остановке воздухоразделительной установки по наряду-допуску",
        ],
        correctAnswers: [
          "Следует производить на неработающем блоке разделения воздуха при плановой остановке воздухоразделительной установки по наряду-допуску",
        ],
      },
      {
        code: "20114194",
        text: "В каком случае замена адсорбента должна производиться немедленно?",
        answers: [
          "В случае, определенном документацией разработчика оборудования",
          "В случае если адсорбент загрязнен (замаслен)",
          "В случае если необходимо произвести пересеивание адсорбента",
          "Если при нормальном режиме регенерации и соблюдении рабочих параметров процесса очистки наблюдается фиксация превышения концентрации двуокиси углерода сверх допустимых норм",
        ],
        correctAnswers: [
          "Если при нормальном режиме регенерации и соблюдении рабочих параметров процесса очистки наблюдается фиксация превышения концентрации двуокиси углерода сверх допустимых норм",
        ],
      },
      {
        code: "20114195",
        text: "Каким документом определяются сроки проверки состояния адсорбента в блоке комплексной очистки при условии сохранения его работоспособности?",
        answers: [
          "Документацией разработчика оборудования",
          "Руководством по эксплуатации аппаратов блоков комплексной очистки (БКО)",
          "Технологической инструкцией",
          "Технологическим регламентом",
        ],
        correctAnswers: ["Технологическим регламентом"],
      },
      {
        code: "20114196",
        text: "Что необходимо проводить в целях обеспечения эффективной очистки технологических потоков?",
        answers: [
          "Периодическую замену адсорбента в соответствии с технологическим регламентом",
          "Периодическое просеивание адсорбента в соответствии с технологическим регламентом",
          "Периодическую досыпку адсорбента в соответствии с технологическим регламентом",
          "Периодическую регенерацию адсорбента в соответствии с технологическим регламентом",
        ],
        correctAnswers: [
          "Периодическую регенерацию адсорбента в соответствии с технологическим регламентом",
        ],
      },
      {
        code: "20114197",
        text: "В какие сроки производится замена адсорбента?",
        answers: [
          "В сроки, установленные технологическим регламентом",
          "В сроки, установленные разработчиком технической документации",
          "В сроки, установленные технологической картой процесса и местными инструкциями",
          "В сроки, установленные стандартом предприятия с учетом местных особенностей",
        ],
        correctAnswers: ["В сроки, установленные технологическим регламентом"],
      },
      {
        code: "20114198",
        text: "Какие требования безопасности предъявляются на период регенерации адсорбента к эксплуатации блока разделения воздуха при наличии в воздухоразделительной установке только одного адсорбера на потоке кубовой жидкости?",
        answers: [
          "Блок разделения воздуха необходимо останавливать не менее чем за 5 минут до начала проведения высокотемпературной регенерации адсорбента. Не допускать работу таких установок через обводную линию",
          "Блок разделения воздуха необходимо останавливать не менее чем за 10 минут до начала проведения высокотемпературной регенерации адсорбента. Не допускать работу таких установок через обводную линию",
          "Блок разделения воздуха необходимо останавливать не менее чем за 10 минут до начала проведения высокотемпературной регенерации адсорбента и предварительного проведения замеров концентрации ацетилена в жидком кислороде. Не допускать работу таких установок через обводную линию",
          "Блок разделения воздуха необходимо останавливать. Не допускать работу таких установок через обводную линию",
        ],
        correctAnswers: [
          "Блок разделения воздуха необходимо останавливать. Не допускать работу таких установок через обводную линию",
        ],
      },
      {
        code: "20114199",
        text: "Какие меры безопасности необходимо предпринять, если при забросе жидкости в турбодетандер или при понижении температуры газа на входе ниже температуры, указанной в технологической инструкции, не сработала автоматическая защита?",
        answers: [
          "Немедленно продуть трубопроводы до и после турбодетандера",
          "Немедленно отключить турбодетандер (прекратить подачу газа в турбодетандер) и продуть трубопроводы до турбодетандера",
          "Немедленно отключить турбодетандер (прекратить подачу газа в турбодетандер) и продуть трубопроводы после турбодетандера",
          "Немедленно отключить турбодетандер (прекратить подачу газа в турбодетандер) и продуть трубопроводы до и после турбодетандера",
        ],
        correctAnswers: [
          "Немедленно отключить турбодетандер (прекратить подачу газа в турбодетандер) и продуть трубопроводы до и после турбодетандера",
        ],
      },
      {
        code: "20114200",
        text: "Какие требования, установленные Правилами безопасности химически опасных производственных объектов, к адсорбенту в адсорбционных блоках осушки указаны неверно?",
        answers: [
          "Если адсорбент загрязнен (замаслен или пожелтел), его необходимо заменить",
          "В установках, в которых температура сжатия воздуха в любой ступени компрессора выше 433 °К (160 °C), замену адсорбента в блоке осушки производить 2 раза в год согласно технологическому регламенту",
          "Состояние адсорбента требуется проверять не реже 1 раза в год",
          "В установках, в которых температура сжатия воздуха в любой ступени компрессора выше 433 °К (160 °C), замену адсорбента в блоке осушки производить 1 раз в год согласно технологической инструкции",
        ],
        correctAnswers: [
          "В установках, в которых температура сжатия воздуха в любой ступени компрессора выше 433 °К (160 °C), замену адсорбента в блоке осушки производить 1 раз в год согласно технологической инструкции",
        ],
      },
      {
        code: "20114201",
        text: "Какие из перечисленных требований к сосудам газификаторов и других стационарных сосудов с жидкими продуктами разделения воздуха (ПРВ), установленных снаружи зданий потребителей, и в которых производится непосредственный слив жидких ПРВ из транспортных цистерн, указаны верно?",
        answers: [
          "Сосуды следует располагать около стен, не имеющих проемов на расстоянии не менее 2,0 м от габаритов сосуда",
          "Оконные проемы на расстоянии 3,0 м вверх от габаритов сосудов могут иметь открывающиеся элементы",
          "Оконные проемы на расстоянии 7,0 м в каждую сторону от габаритов сосудов не должны иметь открывающихся элементов",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20114202",
        text: "При каких условиях жидкий азот в качестве хладоагента может применяться без специальных мер по предупреждению загорания и взрыва?",
        answers: [
          "Если объемная доля кислорода в жидком азоте не превышает 30%",
          "Если объемная доля кислорода в жидком азоте не превышает 31%",
          "Если объемная доля кислорода в жидком азоте не превышает 32%",
          "Если объемная доля кислорода в жидком азоте не превышает 33%",
        ],
        correctAnswers: [
          "Если объемная доля кислорода в жидком азоте не превышает 30%",
        ],
      },
      {
        code: "20114203",
        text: "Какие требования к ваннам для охлаждения деталей жидким азотом, установленные Правилами безопасности химически опасных производственных объектов, указаны неверно?",
        answers: [
          "Подлежат периодическому обезжириванию",
          "Над ваннами необходимо предусматривать местные отсосы",
          "Подлежат отогреву при объемной доле кислорода в азоте более 30%",
          "Подлежат отогреву при объемной доле кислорода в азоте более 23%",
        ],
        correctAnswers: [
          "Подлежат отогреву при объемной доле кислорода в азоте более 23%",
        ],
      },
      {
        code: "20114204",
        text: "Какие требования безопасности к баллонам для постоянных потребителей небольших количеств продуктов разделения воздуха (газоанализаторы, хроматографы) указаны неверно?",
        answers: [
          "Баллоны следует размещать в металлических шкафах и закреплять. Шкафы с баллонами должны иметь запорные устройства",
          "Расстояние между каждой парой баллонов должно быть не менее 12,0 м на каждом уровне размещения баллонов",
          "У каждого места потребления допускается размещать не более двух баллонов вместимостью 40 л, заполненных продуктами разделения воздуха под давлением до 20 МПа",
          "У каждого места потребления допускается размещать не более двух баллонов вместимостью 50 л, заполненных продуктами разделения воздуха под давлением до 25 МПа",
        ],
        correctAnswers: [
          "У каждого места потребления допускается размещать не более двух баллонов вместимостью 50 л, заполненных продуктами разделения воздуха под давлением до 25 МПа",
        ],
      },
      {
        code: "20114205",
        text: "Что должно исключать остаточное давление в кислородных баллонах при их разрядке?",
        answers: [
          "Возможность перетекания кислорода в системы, не связанные с осуществляемым технологическим процессом",
          "Возможность перетекания кислорода в коммуникации, заполненные горючими газами",
          "Возможность перетекания кислорода в технические устройства, заполненные горючими газами",
          "Перетечку горючих газов из подключенной системы обратно в баллоны",
        ],
        correctAnswers: [
          "Перетечку горючих газов из подключенной системы обратно в баллоны",
        ],
      },
      {
        code: "20114206",
        text: "Какие требования, установленные Правилами безопасности химически опасных производственных объектов, к управлению оборудованием, предназначенным для переработки и хранения криптоноксеноновой смеси, криптона и ксенона, указаны неверно?",
        answers: [
          "Местное управление допускается при наличии защитного экрана между оборудованием и щитом управления",
          "Местное управление допускается при наличии ограждения вокруг оборудования на расстоянии 1 м от него",
          "Должно быть предусмотрено дистанционное управление",
          "Местное управление допускается при наличии защитного экрана между оборудованием и щитом управления высотой не менее 1 м",
        ],
        correctAnswers: [
          "Местное управление допускается при наличии защитного экрана между оборудованием и щитом управления высотой не менее 1 м",
        ],
      },
      {
        code: "20114207",
        text: "Какие требования, установленные Правилами безопасности химически опасных производственных объектов, к баллонам с криптоноксеноновой смесью, криптоном и ксеноном после их наполнения необходимо выполнить?",
        answers: [
          "Произвести взвешивание баллонов через сутки после их наполнения",
          "Произвести первичный отбор проб газа для анализа в суточный срок после наполнения баллонов",
          "Осуществить транспортировку баллонов к месту применения на тележках или другом транспорте через сутки после их наполнения",
          "Баллоны с криптоноксеноновой смесью, криптоном и ксеноном после наполнения выдержать не менее 14 суток в помещении наполнительной или на складе баллонов",
        ],
        correctAnswers: [
          "Баллоны с криптоноксеноновой смесью, криптоном и ксеноном после наполнения выдержать не менее 14 суток в помещении наполнительной или на складе баллонов",
        ],
      },
      {
        code: "20114208",
        text: "Куда производится сброс первичного криптонового концентрата и криптоноксеноновой смеси?",
        answers: [
          "Только в газгольдер первичного криптонового концентрата",
          "В специальный резервуар системы наполнения баллонов",
          "В хранилище жидкого первичного концентрата",
          "В газгольдер первичного криптонового концентрата либо на всас компрессора (если газгольдер отсутствует в схеме)",
        ],
        correctAnswers: [
          "В газгольдер первичного криптонового концентрата либо на всас компрессора (если газгольдер отсутствует в схеме)",
        ],
      },
      {
        code: "20114209",
        text: "Какие требования, установленные Правилами безопасности химически опасных производственных объектов, к технологическому процессу очистки сырого аргона указаны неверно?",
        answers: [
          "Установку очистки сырого аргона от кислорода необходимо оснащать блокировкой, прекращающей подачу водорода при повышении температуры в реакторе выше допустимой, а также при содержании кислорода в аргоне, поступающем на очистку, более нормативных величин",
          "В технологическом процессе должна быть предусмотрена система автоматического измерения содержания кислорода в сыром аргоне, поступающем в реактор",
          "Во время пуска установки допускается повышение объемной доли кислорода в аргоне не более 8% на входе в узел смешения реактора. При этом расход подаваемого водорода не должен превышать 2,5% от расхода поступающего на дистанционное управление. Местное управление допускается при наличии аргона",
          "Объемная доля водорода в техническом аргоне, поступающем в воздухоразделительные установки на очистку от азота, не должна превышать 8%",
        ],
        correctAnswers: [
          "Объемная доля водорода в техническом аргоне, поступающем в воздухоразделительные установки на очистку от азота, не должна превышать 8%",
        ],
      },
      {
        code: "20114210",
        text: "Какое из перечисленных требований к оборудованию и коммуникациям продуктов разделения воздуха указано неверно?",
        answers: [
          "Все сосуды, заполняемые жидкими криогенными продуктами разделения воздуха, необходимо оснащать указателями уровня заполнения",
          "Холодные участки низкотемпературного оборудования и коммуникаций, находящиеся в зоне обслуживания, подлежат изоляции или ограждению",
          "Хранение, газификация и транспортирование жидких (криогенных) продуктов разделения воздуха необходимо производить только в технических устройствах, специально предназначенных для данного продукта",
          "На участки трубопроводов жидких продуктов разделения воздуха, заключенные между двумя отключающими органами, должен быть нанесен штамп",
        ],
        correctAnswers: [
          "На участки трубопроводов жидких продуктов разделения воздуха, заключенные между двумя отключающими органами, должен быть нанесен штамп",
        ],
      },
      {
        code: "20114211",
        text: "Какие требования, предъявляемые Правилами безопасности химически опасных производственных объектов к транспортным сосудам для жидких продуктов разделения воздуха при нахождении их в гараже, указаны неверно?",
        answers: [
          "Транспортные сосуды в течение всего времени нахождения их в гараже должны подключаться к сбросным трубопроводам",
          "Сбросные трубопроводы, подключенные к транспортным сосудам, должны выводиться на 2,0 м выше конька крыши гаража",
          "Для каждого сосуда предусматривается отдельный сброс",
          "Транспортные сосуды для жидких продуктов разделения воздуха в течение всего времени нахождения их в гараже должны подключаться к сбросным трубопроводам, выведенным не более чем на 2,0 м выше конька крыши гаража",
        ],
        correctAnswers: [
          "Транспортные сосуды для жидких продуктов разделения воздуха в течение всего времени нахождения их в гараже должны подключаться к сбросным трубопроводам, выведенным не более чем на 2,0 м выше конька крыши гаража",
        ],
      },
      {
        code: "20114212",
        text: "Каким образом определяются методы и сроки очистки водяных полостей холодильников и рубашек цилиндров компрессоров от отложений?",
        answers: [
          "Технологическим регламентом, технологическими инструкциями",
          "Правилами безопасности химически опасных производственных объектов",
          "Технологическим процессом эксплуатирующей организации",
          "Проектной документацией и требованиям Правил безопасности химически опасных производственных объектов",
        ],
        correctAnswers: [
          "Технологическим регламентом, технологическими инструкциями",
        ],
      },
      {
        code: "20114213",
        text: "На каких кислородопроводах необходимо устанавливать переключающиеся фильтры перед их подключением к коллектору всасывания кислородных компрессоров?",
        answers: [
          "На кислородопроводах длиной более 300 м, изготовленных из углеродистых сталей",
          "На кислородопроводах длиной более 250 м, изготовленных из углеродистых сталей",
          "На кислородопроводах длиной более 200 м, изготовленных из углеродистых сталей",
          "На кислородопроводах длиной более 150 м, изготовленных из углеродистых сталей",
        ],
        correctAnswers: [
          "На кислородопроводах длиной более 250 м, изготовленных из углеродистых сталей",
        ],
      },
      {
        code: "20114214",
        text: "На каких кислородных центробежных компрессорах необходимо предусматривать стационарные устройства, позволяющие производить отбор проб для анализа газа, выходящего из лабиринтных уплотнений компрессора?",
        answers: [
          "На каждом с давлением нагнетания более 0,6 МПа",
          "С давлением нагнетания 0,6 МПа",
          "На одном из компрессоров с давлением нагнетания более 0,6 МПа",
          "На любом из компрессоров с давлением нагнетания 0,6 МПа",
        ],
        correctAnswers: ["На каждом с давлением нагнетания более 0,6 МПа"],
      },
      {
        code: "20114215",
        text: "С какими параметрами рабочего давления и вместимости вместе с реципиентами необходимо оснащать кислородопроводы автоматически действующей системой защиты, прекращающей поступление кислорода из реципиентов в трубопровод при нарушении его целостности?",
        answers: [
          "С рабочим давлением более 1,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 2000 м³",
          "С рабочим давлением более 1,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 200 м³",
          "С рабочим давлением 1,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 200 м³",
          "С рабочим давлением более 0,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 200 м³",
        ],
        correctAnswers: [
          "С рабочим давлением более 1,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 200 м³",
        ],
      },
      {
        code: "20114216",
        text: "С какой пропускной способностью трубопроводы сброса продуктов разделения воздуха от предохранительных клапанов и других защитных устройств необходимо выводить за пределы здания?",
        answers: [
          "С пропускной способностью более 10 м³/ч",
          "С пропускной способностью более 50 м³/ч",
          "С пропускной способностью 100 м³/ч",
          "С пропускной способностью более 100 м³/ч",
        ],
        correctAnswers: ["С пропускной способностью более 100 м³/ч"],
      },
      {
        code: "20114217",
        text: "Какую объемную долю кислорода в воздухе в местах возможного нахождения обслуживающего персонала и забора воздуха для вентиляции и технологических нужд должны обеспечить конструкция и размещение устройств сброса в атмосферу азота и кислорода?",
        answers: [
          "Не менее 17% и не более 22%",
          "Не менее 19%",
          "Не более 23%",
          "В пределах от 19% до 23%",
        ],
        correctAnswers: ["В пределах от 19% до 23%"],
      },
      {
        code: "20114218",
        text: "При какой концентрации масла в кислороде воздухоразделительная установка должна подвергаться обезжириванию?",
        answers: [
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной тремя последовательно проведенными анализами",
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм, подтвержденной тремя последовательно проведенными анализами",
          "При достижении концентрации масла в жидком кислороде 0,20 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами",
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами",
        ],
        correctAnswers: [
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами",
        ],
      },
      {
        code: "20114219",
        text: "Какие устанавливаются сроки обезжиривания воздухоразделительных установок, работающих по схеме высокого и среднего давлений с блоками комплексной очистки на цеолитах и турбодетандерами, а также по схеме низкого давления?",
        answers: [
          "1 раз в квартал",
          "1 раз в полгода",
          "1 раз в год",
          "Не регламентируются",
        ],
        correctAnswers: ["Не регламентируются"],
      },
      {
        code: "20114220",
        text: "Какое оборудование подлежит обезжириванию?",
        answers: [
          "Средства измерений общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа",
          "Средства измерений, работающие при давлении до 0,6 МПа, конструкция которых позволяет их устанавливать в положении, обеспечивающем свободное стекание масла с внутренних поверхностей, соприкасающихся с кислородом",
          "Арматура перед ее монтажом, предназначенная для работы с кислородом, обезжиривание которой было проведено на заводе-изготовителе (что подтверждено соответствующим клеймением или сопроводительными документами) и упаковка которой не нарушена",
          "Аппараты и коммуникации на потоке газообразного кислорода высокого давления",
        ],
        correctAnswers: [
          "Аппараты и коммуникации на потоке газообразного кислорода высокого давления",
        ],
      },
      {
        code: "20114221",
        text: "Какие из перечисленных требований должны соблюдаться при обезжиривании сосудов (емкостных аппаратов) способом протирки? Укажите все правильные ответы.",
        answers: [
          "Перед проведением работ по обезжириванию сосуд, бывший в эксплуатации, отогревается до температуры не ниже +20 °С",
          "Перед проведением работ по обезжириванию сосуд, бывший в эксплуатации, продувается инертным газом",
          "Начинать работы следует только при объемной доле кислорода в воздухе внутри сосуда не менее 19% и не более 23%",
          "Технический руководитель организации должен осмотреть место работы и убедиться, что сосуд отогрет и подготовлен к проведению работ",
        ],
        correctAnswers: [
          "Перед проведением работ по обезжириванию сосуд, бывший в эксплуатации, отогревается до температуры не ниже +20 °С",
          "Начинать работы следует только при объемной доле кислорода в воздухе внутри сосуда не менее 19% и не более 23%",
        ],
      },
      {
        code: "20114222",
        text: "Какие требования безопасности, установленные Правилами безопасности химически опасных производственных объектов, к проведению ремонта в отсеках клапанных коробок (обратноповоротных клапанов) регенераторов блока разделения воздуха указаны неверно?",
        answers: [
          "Вход в отсеки для ремонта клапанов разрешается только после снятия давления из аппаратов блока",
          "Вход в отсеки для ремонта клапанов разрешается только после местного отогрева отсеков клапанных коробок (обратноповоротных клапанов)",
          "Объемная доля кислорода внутри кожуха блока разделения воздуха должна быть 19% - 23%",
          "Вход в отсеки для ремонта клапанов разрешается без сброса избыточного давления из аппаратов блока по наряду-допуску",
        ],
        correctAnswers: [
          "Вход в отсеки для ремонта клапанов разрешается без сброса избыточного давления из аппаратов блока по наряду-допуску",
        ],
      },
      {
        code: "20114223",
        text: "Как должен производиться отогрев смерзшейся изоляции для обеспечения доступа к адсорберам?",
        answers: [
          "Непосредственно сухим подогретым воздухом",
          "Непосредственно горячей водой",
          "Открытым огнем",
          "За счет тепла, подводимого к отогреваемым аппаратам",
        ],
        correctAnswers: ["Непосредственно сухим подогретым воздухом"],
      },
      {
        code: "20114224",
        text: "Как необходимо выполнять ремонтные работы внутри трубопроводов и арматуры теплого и холодного концов регенераторов в период остановки блоков без слива жидкости?",
        answers: [
          "По наряду-допуску",
          "По устному разрешению главного механика",
          "Только в выходные дни по письменному разрешению технического руководителя организации",
          "Только в соответствии с графиком, утверждаемым техническим руководителем эксплуатирующей организации",
        ],
        correctAnswers: ["По наряду-допуску"],
      },
      {
        code: "20114225",
        text: "Какие из перечисленных требований при производстве работ по ремонту технических устройств и трубопроводов указаны верно? Укажите все правильные ответы.",
        answers: [
          "На весь период ремонта арматура на сбросе газа из блока в атмосферу должна быть закрыта",
          "Перед ремонтом электрозадвижка на входе воздуха в блок должна быть закрыта и поджата вручную",
          "Механизм переключения регенераторов (шальт-машина) на период ремонта клапанов (трубопроводов) необходимо остановить",
          "До выполнения работ на обеих трехходовых заслонках, связанных с доступом в подводящие к ним трубопроводы, устанавливается заглушка (по ходу газа) после обеих трехходовых заслонок",
        ],
        correctAnswers: [
          "Перед ремонтом электрозадвижка на входе воздуха в блок должна быть закрыта и поджата вручную",
          "Механизм переключения регенераторов (шальт-машина) на период ремонта клапанов (трубопроводов) необходимо остановить",
        ],
      },
      {
        code: "20114226",
        text: "Для каких реципиентов в месте их подключения к межцеховым кислородопроводам необходимо устанавливать отключающие задвижки с дистанционным управлением?",
        answers: [
          "Вместимостью более 10 м³ с рабочим давлением более 1,3 МПа",
          "Вместимостью более 20 м³ с рабочим давлением более 1,5 МПа",
          "Вместимостью 200 м³ с рабочим давлением 1,6 МПа",
          "Вместимостью более 200 м³ с рабочим давлением более 1,6 МПа",
        ],
        correctAnswers: [
          "Вместимостью более 200 м³ с рабочим давлением более 1,6 МПа",
        ],
      },
      {
        code: "20114227",
        text: "В каких системах кислородоснабжения операции по снижению и поддержанию давления кислорода, регулированию его расхода следует производить в кислородно-распределительных (регуляторных) пунктах?",
        answers: [
          "С расходами более 2000 м³/ч и давлением свыше 0,6 МПа",
          "С расходами свыше 4000 м³/ч и давлением от 0,4 до 2,0 МПа",
          "С расходами свыше 5000 м³/ч и давлением от 0,2 до 4,0 МПа",
          "С расходами свыше 6000 м³/ч и давлением от 0,6 до 4,0 МПа",
        ],
        correctAnswers: [
          "С расходами свыше 6000 м³/ч и давлением от 0,6 до 4,0 МПа",
        ],
      },
      {
        code: "20114228",
        text: "Какой максимальный коэффициент заполнения объема резервуара допускается при хранении жидкого аммиака под избыточным давлением?",
        answers: [
          "0,75 от геометрического объема резервуара",
          "0,8 от геометрического объема резервуара",
          "0,85 от геометрического объема резервуара",
          "0,9 от геометрического объема резервуара",
        ],
        correctAnswers: ["0,85 от геометрического объема резервуара"],
      },
      {
        code: "20114229",
        text: "Каким образом разрешается размещать штуцера на резервуарах для хранения жидкого аммиака?",
        answers: [
          "Штуцера для выдачи жидкого аммиака разрешается размещать в нижней части резервуаров, штуцера для выдачи жидкого дренажа - в верхней части резервуаров",
          "Штуцера для дренажа разрешается размещать в нижней части резервуаров, остальные штуцера - в верхней части резервуаров",
          "Штуцера для контрольно-измерительных приборов и автоматизации разрешается размещать в нижней части резервуаров, остальные штуцера - в верхней части резервуаров",
          "Штуцера для выдачи жидкого аммиака, дренажа, промывки и контрольно-измерительных приборов и автоматизации разрешается размещать в нижней части резервуаров, остальные штуцера - в верхней части резервуаров",
        ],
        correctAnswers: [
          "Штуцера для выдачи жидкого аммиака, дренажа, промывки и контрольно-измерительных приборов и автоматизации разрешается размещать в нижней части резервуаров, остальные штуцера - в верхней части резервуаров",
        ],
      },
      {
        code: "20114230",
        text: "Какой величины должно приниматься расчетное давление при проектировании резервуаров для хранения жидкого аммиака?",
        answers: [
          "Больше рабочего на 10%, но не менее чем на 98,06 Па (10 мм вод. ст.)",
          "Больше рабочего на 15%, но не менее чем на 98,06 Па (10 мм вод. ст.)",
          "Больше рабочего на 20%, но не менее чем на 98,06 Па (10 мм вод. ст.)",
          "Больше рабочего на 25%, но не менее чем на 98,06 Па (10 мм вод. ст.)",
        ],
        correctAnswers: [
          "Больше рабочего на 25%, но не менее чем на 98,06 Па (10 мм вод. ст.)",
        ],
      },
      {
        code: "20114231",
        text: "Как осуществляется охлаждение изотермического резервуара с аммиаком?",
        answers: [
          "Подачей в рубашку резервуара охлаждающей жидкости",
          "За счет возврата отводимых из резервуара паров аммиака сжиженных на холодильной установке",
          "Подачей охлаждающей жидкости в нижнюю часть резервуара через змеевик",
          "Установкой разбрызгивающего устройства, расположенного выше допустимого уровня аммиака",
        ],
        correctAnswers: [
          "Установкой разбрызгивающего устройства, расположенного выше допустимого уровня аммиака",
        ],
      },
      {
        code: "20114232",
        text: "Какие изотермические резервуары дополнительно рассчитываются на сейсмические нагрузки?",
        answers: [
          "Все изотермические резервуары рассчитываются на сейсмические нагрузки",
          "Двустенные с двумя самонесущими крышами",
          "Двустенные с подвесной крышей внутреннего резервуара",
          "Одностенные",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20114233",
        text: "Какая арматура применяется на трубопроводах жидкого и газообразного аммиака?",
        answers: [
          "Стальная арматура и фасонные части, а также запорно-регулирующая арматура из чугуна",
          "Стальная арматура и фасонные части, а также запорно-регулирующая арматура из меди и ее сплавов",
          "Стальная арматура и фасонные части, а также запорно-регулирующая арматура из цинка и его сплавов",
          "Стальная арматура и фасонные части",
        ],
        correctAnswers: ["Стальная арматура и фасонные части"],
      },
      {
        code: "20114234",
        text: "Какие требования к предохранительным клапанам, устанавливаемым на резервуары жидкого аммиака, указаны неверно?",
        answers: [
          "Применение рычажно-грузовых предохранительных клапанов не допускается",
          "Параллельно с рабочими предохранительными клапанами необходимо устанавливать резервные предохранительные клапаны",
          "На наружных оболочках изотермических резервуаров с засыпной изоляцией разрешается не устанавливать предохранительные клапаны, если такие клапаны имеются на буферном сосуде (газгольдере) азота или на трубопроводе, который соединяет наружную оболочку с буферным сосудом",
          "Не допускается изготовление предохранительных и вакуумных клапанов для изотермических резервуаров из алюминиевых сплавов",
        ],
        correctAnswers: [
          "Не допускается изготовление предохранительных и вакуумных клапанов для изотермических резервуаров из алюминиевых сплавов",
        ],
      },
      {
        code: "20114235",
        text: "В каком случае допускается применение резиновых и резинометаллических рукавов для слива (налива) цистерн жидкого аммиака?",
        answers: [
          "Ни в каком случае",
          "Если есть обоснования на применение рукавов во внутренней эксплуатационной документации",
          "Если рукава имеют внутренний диаметр 50 мм",
          "Если рукава рассчитаны на рабочее давление не менее 2 МПа",
        ],
        correctAnswers: [
          "Если рукава рассчитаны на рабочее давление не менее 2 МПа",
        ],
      },
      {
        code: "20114236",
        text: "Какую минимальную температуру следует принимать для сливных, наливных и эвакуационных насосов жидкого аммиака?",
        answers: ["- 20 °C", "- 28 °C", "- 34 °C", "- 35 °C"],
        correctAnswers: ["- 34 °C"],
      },
      {
        code: "20114237",
        text: "Что происходит с выбросами аммиака при продувках оборудования и трубопроводов, снижении в них давления, сливе (наливе) цистерн, а также со сбросами от предохранительных клапанов?",
        answers: [
          "Выбросы и сбросы утилизируются или направляются в факельную систему",
          "Выбросы и сбросы поступают в резервный резервуар",
          "Выбросы и сбросы поступают обратно в резервуар",
          "Выбросы и сбросы только утилизируются",
        ],
        correctAnswers: [
          "Выбросы и сбросы утилизируются или направляются в факельную систему",
        ],
      },
      {
        code: "20114238",
        text: "Какое из перечисленных требований соответствует нормам заполнения и хранения листа регистрации изменений и дополнений?",
        answers: [
          "Лист регистрации изменений и дополнений размещается в начале технологического регламента",
          "Регистрация изменений и дополнений выполняется главным инженером организации",
          "Запись в регистрационном листе выполняется чернилами любого цвета, кроме красного",
          "Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под подпись в подразделения, где находятся копии регламента",
        ],
        correctAnswers: [
          "Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под подпись в подразделения, где находятся копии регламента",
        ],
      },
      {
        code: "20114239",
        text: "Что необходимо сделать перед включением электропечи после ремонта, выполненного с ее разгерметизацией?",
        answers: [
          "Продуть все аппараты и газоходы инертным газом до содержания азота не более 2%",
          "Продуть все аппараты и газоходы инертным газом до содержания кислорода не более 2%",
          "Продуть все аппараты и газоходы инертным газом до содержания кислорода более 10%",
          "Провести осмотр, очистить все аппараты и газоходы",
        ],
        correctAnswers: [
          "Продуть все аппараты и газоходы инертным газом до содержания кислорода не более 2%",
        ],
      },
      {
        code: "20114240",
        text: "Какие требования установлены к люку-лазу, предназначенному для осмотра и ремонтных работ внутри силосных ячеек (бункеров, завальных ям)?",
        answers: [
          "Люк размером не менее 500 x 500 мм, оборудованный съемной прочной решеткой с размером ячеек не более 100 x 100 мм и герметичной крышкой",
          "Люк размером не менее 500 x 500 мм, оборудованный съемной прочной решеткой с размером ячеек не более 50 x 50 мм и герметичной крышкой",
          "Люк размером не менее 400 x 400 мм, оборудованный съемной прочной решеткой с размером ячеек не более 80 x 80 мм",
          "Люк размером не менее 600 x 600 мм, оборудованный съемной прочной решеткой с размером ячеек не более 150 x 150 мм и герметичной крышкой",
        ],
        correctAnswers: [
          "Люк размером не менее 500 x 500 мм, оборудованный съемной прочной решеткой с размером ячеек не более 100 x 100 мм и герметичной крышкой",
        ],
      },
      {
        code: "20114241",
        text: "Какова предельно допустимая величина концентрации взрывоопасной парогазовой фазы сигнализации средств автоматического газового анализа в производственных помещениях на открытых наружных установках?",
        answers: [
          "Не более 20% от нижнего концентрационного предела распространения пламени",
          "Не более 25% от нижнего концентрационного предела распространения пламени",
          "Не более 30% от нижнего концентрационного предела распространения пламени",
          "Не более 40% от нижнего концентрационного предела распространения пламени",
        ],
        correctAnswers: [
          "Не более 20% от нижнего концентрационного предела распространения пламени",
        ],
      },
      {
        code: "20114242",
        text: "В каком случае допускается постоянное пребывание людей в анализаторных помещениях объектов производств растительных масел?",
        answers: [
          "Если анализаторы имеют защиту от воспламенения и взрыва по газовым линиям",
          "Если анализаторные помещения имеют предохраняющие конструкции и аварийную вентиляцию",
          "Если ограничители расхода и давления на пробоотборных устройствах размещаются вне анализаторного помещения",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20114243",
        text: "Какое из перечисленных требований к обслуживанию и ремонту технологического оборудования и трубопроводов противоречит Правилам безопасности химически опасных производственных объектов?",
        answers: [
          "Перед началом ремонтных работ аппараты и технологические трубопроводы экстракционного цеха должны продуваться инертным газом",
          "Все аппараты экстракционного цеха должны отключаться от системы технологических трубопроводов с помощью стандартных пронумерованных заглушек с видимыми хвостовиками",
          "Теплообменные поверхности аппаратов (дистилляторов, конденсаторов, теплообменников) должны очищаться от нагара путем щелочения и промывки водой",
          "После завершения указанных операций с аппаратов снимаются крышки люков-лазов и проводятся операции проветривания",
        ],
        correctAnswers: [
          "Перед началом ремонтных работ аппараты и технологические трубопроводы экстракционного цеха должны продуваться инертным газом",
        ],
      },
      {
        code: "20114244",
        text: "Какие ремонтные работы выполняются по наряду-допуску?",
        answers: [
          "Только ремонтные работы на низкотемпературных технических устройствах без их полного отогрева",
          "Только ремонтные работы на оборудовании, отключенном от работающей воздухоразделительной установки",
          "Только ремонтные работы на участке трубопровода, отключенном от работающей воздухоразделительной установки",
          "Все перечисленные работы",
        ],
        correctAnswers: ["Все перечисленные работы"],
      },
      {
        code: "20114245",
        text: "Какие требования, установленные Правилами безопасности химически опасных производственных объектов, при производстве ремонта турбодетандеров или при замене фильтрующих элементов детандерных фильтров указаны неверно?",
        answers: [
          "Должно быть отключено электропитание",
          "На азотных турбодетандерах необходимо устанавливать заглушки на подаче и выходе газа из турбодетандера. Перед ремонтом эти турбодетандеры продуваются воздухом",
          "Должна быть закрыта арматура на входе воздуха в турбодетандер и на выходе из него",
          "На всех турбодетандерах необходимо устанавливать заглушки на подаче и выходе газа из турбодетандера",
        ],
        correctAnswers: [
          "На всех турбодетандерах необходимо устанавливать заглушки на подаче и выходе газа из турбодетандера",
        ],
      },
      {
        code: "20114246",
        text: "С какой периодичностью проводится ревизия и ремонт предохранительных клапанов резервуаров жидкого аммиака?",
        answers: [
          "Не реже 1 раза в 6 месяцев",
          "Ежегодно",
          "Не реже 1 раза в 3 года",
          "В сроки, установленные технологическим регламентом и эксплуатационной документацией",
        ],
        correctAnswers: [
          "В сроки, установленные технологическим регламентом и эксплуатационной документацией",
        ],
      },
      {
        code: "20114247",
        text: "С какой периодичностью проводится ревизия и ремонт предохранительных клапанов резервуаров жидкого аммиака со снятием их с мест установки, проверкой и настройкой на стенде?",
        answers: [
          "Ежегодно",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 6 месяцев",
          "В сроки, установленные технологическим регламентом и эксплуатационной документацией",
        ],
        correctAnswers: [
          "В сроки, установленные технологическим регламентом и эксплуатационной документацией",
        ],
      },
    ],

    20115: [
      {
        code: "20115000",
        text: 'Что является критерием взрывоопасности согласно Федеральным нормам и правилам "Общие правила взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств"?',
        answers: [
          "Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом",
          "Класс опасности обращающихся в процессе веществ",
          "Температура самовоспламенения паров обращающихся в процессе веществ",
          "Скорость распространения горения обращающихся в процессе веществ",
        ],
        correctAnswers: [
          "Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом",
        ],
      },
      {
        code: "20115001",
        text: "Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?",
        answers: [
          "Энергией сгорания парогазовой фазы (в кДж)",
          "Категорией взрывоопасности",
          "Приведенной массой вещества, участвующего во взрыве (в кг)",
          "Радиусом зон разрушения (в м)",
        ],
        correctAnswers: ["Категорией взрывоопасности"],
      },
      {
        code: "20115002",
        text: "Какой категории взрывоопасности технологических блоков не существует?",
        answers: [
          "I категории",
          "II категории",
          "III категории",
          "IV категории",
        ],
        correctAnswers: ["IV категории"],
      },
      {
        code: "20115003",
        text: "Какой следует принимать категорию взрывоопасности блоков, определяемую расчетом, если обращающиеся в технологическом блоке опасные вещества относятся к токсичным, высокотоксичным веществам?",
        answers: ["На 1 выше", "I категории", "II категории", "III категории"],
        correctAnswers: ["На 1 выше"],
      },
      {
        code: "20115004",
        text: "В соответствии с чем осуществляется ведение технологических процессов на опасном производственном объекте химической, нефтехимической промышленности?",
        answers: [
          "В соответствии с технологическими регламентами на производство продукции",
          "В соответствии с рекомендациями территориального управления Ростехнадзора",
          "В соответствии с заключением экспертизы промышленной безопасности",
          "В соответствии с распоряжениями руководителя эксплуатирующей организации",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами на производство продукции",
        ],
      },
      {
        code: "20115005",
        text: "Каким образом предприятие должно обеспечить наработку навыков действий персонала в нештатных (аварийных) ситуациях на установках с технологическими блоками I и II категорий взрывоопасности?",
        answers: [
          "Посредством допуска к самостоятельной работе не ранее чем через 6 месяцев после стажировки на объекте",
          "Посредством создания специализированных центров обучения и подготовки для производственного персонала",
          "Посредством обучения персонала на компьютерных тренажерах, включающих максимально приближенные к реальным динамические модели процессов и реальные средства управления",
          "Посредством приобретения компьютерных тренажеров, включающих приближенные к реальным динамические модели процессов и средств управления",
        ],
        correctAnswers: [
          "Посредством обучения персонала на компьютерных тренажерах, включающих максимально приближенные к реальным динамические модели процессов и реальные средства управления",
        ],
      },
      {
        code: "20115006",
        text: "Какими источниками информации следует руководствоваться при разработке технологических процессов для определения регламентированных значений параметров, определяющих взрывоопасность процесса, допустимые диапазоны их измерений, критические значения параметров?",
        answers: [
          "Справочной литературой",
          "Научно-технической литературой",
          "Данными, запрашиваемыми у научно-исследовательской организации",
          "Заданием на проектирование",
        ],
        correctAnswers: ["Заданием на проектирование"],
      },
      {
        code: "20115007",
        text: "В какой документации должны быть приведены способы и средства, исключающие выход параметров за установленные пределы?",
        answers: [
          "В исходных данных на проектирование, проектной документации, технологическом регламенте",
          "Только в исходных данных на проектирование и технологическом регламенте",
          "Только в проектной документации",
          "Только в технологическом регламенте",
        ],
        correctAnswers: [
          "В исходных данных на проектирование, проектной документации, технологическом регламенте",
        ],
      },
      {
        code: "20115008",
        text: "Каким образом осуществляется управление подачей инертных сред на установку с технологическими блоками любой категории взрывоопасности там, где при отклонении от регламентированных значений параметров возможно образование взрывопожароопасных смесей?",
        answers: [
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Q10 управление - ручное дистанционное управление",
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Q10 допускается ручное управление по месту",
          "Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, для установок с технологическими блоками II категории взрывоопасности ручное дистанционное, для установок с технологическими блоками III категории взрывоопасности допускается ручное по месту",
          "Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление",
        ],
        correctAnswers: [
          "Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Q10 допускается ручное управление по месту",
        ],
      },
      {
        code: "20115009",
        text: "Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?",
        answers: [
          "Системами ручного (без применения вычислительной техники) регулирования",
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
          "Эффективными быстродействующими системами, обеспечивающими непрерывность технологического процесса",
        ],
        correctAnswers: [
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
        ],
      },
      {
        code: "20115010",
        text: "Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?",
        answers: [
          "Время срабатывания установлено для каждого технологического блока в соответствии с категорией взрывоопасности",
          "Время срабатывания определяется расчетом",
          "Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и установлено для блоков III категории",
          "Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и установлено для блоков I и II категорий",
        ],
        correctAnswers: ["Время срабатывания определяется расчетом"],
      },
      {
        code: "20115011",
        text: "Какими блокировками на отключение должны быть оснащены насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?",
        answers: [
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях",
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости в корпусе насоса, достижении опасных значений в приемной емкости",
          "Блокировками, исключающими пуск и (или) прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях",
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
        correctAnswers: [
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
      },
      {
        code: "20115012",
        text: "Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?",
        answers: [
          "На установках с технологическими блоками I категории взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками II и III категорий взрывоопасности - не регламентируется",
          "На установках с технологическими блоками I и II категорий взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками III категории взрывоопасности - не регламентируется",
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
          "Давление теплоносителя (хладагента) не должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
        ],
        correctAnswers: [
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
        ],
      },
      {
        code: "20115013",
        text: "Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?",
        answers: [
          "Заказчиком в задании на проектирование",
          "Разработчиком процесса",
          "Разработчиком проекта",
        ],
        correctAnswers: ["Разработчиком процесса"],
      },
      {
        code: "20115014",
        text: "Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся и горючие жидкости на сливо-наливных эстакадах?",
        answers: [
          "Должно быть организовано управление по месту",
          "Должно быть организовано управление дистанционно",
          "Должно быть организовано управление по месту и дистанционно (из безопасного места)",
        ],
        correctAnswers: [
          "Должно быть организовано управление по месту и дистанционно (из безопасного места)",
        ],
      },
      {
        code: "20115015",
        text: "Какие сведения являются основополагающими для выбора оборудования при разработке технологических процессов?",
        answers: [
          "Расчетные данные, которым должны соответствовать параметры оборудования и показатели надежности",
          "Расчетные данные, которым должны соответствовать параметры оборудования и требования действующих нормативных документов",
          "Расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование и требования действующих нормативных документов",
          "Исходные данные на проектирование, требования действующих нормативных документов с учетом категории взрывоопасности технологических блоков",
        ],
        correctAnswers: [
          "Исходные данные на проектирование, требования действующих нормативных документов с учетом категории взрывоопасности технологических блоков",
        ],
      },
      {
        code: "20115016",
        text: "Что должно проводиться для подтверждения соответствия Ех-оборудования стандартам на определенный вид взрывозащиты?",
        answers: [
          "Испытания промышленных образцов оборудования на взрывозащищенность",
          "Оценка научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования",
          "Опытные работы, проводимые разработчиком данного оборудования, и экспертиза промышленной безопасности",
          "Оценка научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "Испытания промышленных образцов оборудования на взрывозащищенность",
        ],
      },
      {
        code: "20115017",
        text: "Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?",
        answers: [
          "Оборудование должно быть изолировано от действующей системы, и нанесенное на него обозначение номера по технологической схеме должно быть закрашено",
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем",
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно должно быть изолировано от действующих систем",
          "Оборудование должно быть демонтировано",
        ],
        correctAnswers: [
          "Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем",
        ],
      },
      {
        code: "20115018",
        text: "В зависимости от чего осуществляется выбор конструкции и конструкционных материалов, уплотнительных устройств для насосов и компрессоров?",
        answers: [
          "В зависимости от свойств перемещаемой среды и требований действующих нормативных правовых актов",
          "В зависимости от протяженности трубопровода и его конструктивных особенностей",
          "В зависимости от вида взрывозащиты",
          "В зависимости от всех перечисленных факторов",
        ],
        correctAnswers: [
          "В зависимости от свойств перемещаемой среды и требований действующих нормативных правовых актов",
        ],
      },
      {
        code: "20115019",
        text: "В каких случаях допускается применение для нагнетания легковоспламеняющихся и горючих жидкостей поршневых насосов?",
        answers: [
          "При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости",
          "При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости",
          "При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях",
          "В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования",
        ],
        correctAnswers: [
          "В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования",
        ],
      },
      {
        code: "20115020",
        text: "Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?",
        answers: [
          "Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы",
          "Запорная арматура, средства защиты от превышения давления, огнепреградители",
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
          "Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20115021",
        text: "Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте на производство продукции и инструкциях",
          "Средства автоматики должны быть обозначены на мнемосхемах",
          "Средства автоматики должны быть обозначены только в технологическом регламенте на производство продукции",
          "Требования к обозначению определяются при разработке плана мероприятий по локализации и ликвидации последствий аварий",
        ],
        correctAnswers: [
          "Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте на производство продукции и инструкциях",
        ],
      },
      {
        code: "20115022",
        text: "Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?",
        answers: [
          "Средствами автоматического управления объектом по действующим программам",
          "Обслуживающим персоналом по инструкции",
          "Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений",
          "Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию системы противоаварийной защиты",
        ],
        correctAnswers: ["Обслуживающим персоналом по инструкции"],
      },
      {
        code: "20115023",
        text: "Каким образом обеспечивается надежность обеспечения средств управления и противоаварийной защиты сжатым воздухом?",
        answers: [
          "Установкой резервного компрессора с включением его автоматически при остановке рабочего",
          "Переключением сети воздуха КИПиА на заводскую сеть сжатого воздуха через осушитель",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа",
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
        correctAnswers: [
          "Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом",
        ],
      },
      {
        code: "20115024",
        text: "При каких условиях допускается отключение защит (единовременно не более одного параметра) для непрерывных процессов?",
        answers: [
          "При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами",
          "По устному разрешению технического руководителя организации только в дневную смену при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, и в присутствии начальника производства",
          "В присутствии начальника производства и начальника службы КИПиА (главного прибориста) только в дневную смену при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ",
        ],
        correctAnswers: [
          "При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами",
        ],
      },
      {
        code: "20115025",
        text: "Что должно быть учтено в системах управления и защит электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?",
        answers: [
          "Наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения",
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
          "Обеспечение селективности защит на устройствах электроснабжающей и электропотребляющей организаций",
          "Наличие возможности синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой",
        ],
        correctAnswers: [
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
        ],
      },
      {
        code: "20115026",
        text: "Какое требование к системам вентиляции указано неверно?",
        answers: [
          "Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания",
          "Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе",
          "Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства",
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о").',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о").',
        ],
      },
      {
        code: "20115027",
        text: "Какая система отопления должна применяться в помещениях, имеющих взрывоопасные зоны?",
        answers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
          "Система водяного отопления",
          "Система парового отопления",
        ],
        correctAnswers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
        ],
      },
      {
        code: "20115028",
        text: "Какая максимальная температура поверхностей нагрева систем отопления должна быть в помещениях, имеющих взрывоопасные зоны?",
        answers: [
          "Максимальная температура не должна превышать 80 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 85 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 90 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
          "Максимальная температура не должна превышать 95 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
        correctAnswers: [
          "Максимальная температура не должна превышать 80 % температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ",
        ],
      },
      {
        code: "20115029",
        text: "Каков порядок сброса химически загрязненных стоков от отдельных технологических объектов в магистральную сеть канализации?",
        answers: [
          "Порядок сброса стоков в магистральную сеть канализации устанавливается организацией",
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны быть оборудованы устройствами для улавливания аварийных стоков",
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
          "Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть",
        ],
        correctAnswers: [
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
        ],
      },
      {
        code: "20115030",
        text: "Что необходимо предпринять организации, эксплуатирующей химически опасный производственный объект в целях приведения его в соответствие требованиям Федеральных норм и правил в области промышленной безопасности химически опасных производственных объектов?",
        answers: [
          "Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в документацию или разработку документации вновь",
          "Провести экспертизу промышленной безопасности",
          "Провести реконструкцию химически опасных производственных объектов",
          "Немедленно сообщить в Ростехнадзор о выявленных в рамках проведения производственного контроля несоответствиях Правилам безопасности химически опасных производственных объектов",
        ],
        correctAnswers: [
          "Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в документацию или разработку документации вновь",
        ],
      },
      {
        code: "20115031",
        text: "На какие виды работ распространяются Правила ведения газоопасных, огневых и ремонтных работ?",
        answers: [
          "На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах",
          "На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на выделенной и огражденной площадке на территории находящихся в эксплуатации опасных производственных объектов",
          "На ведение газоопасных, огневых и ремонтных работ на объектах электроэнергетики",
          "На ведение газоопасных, огневых и ремонтных работ на объектах атомной энергетики",
        ],
        correctAnswers: [
          "На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах",
        ],
      },
      {
        code: "20115032",
        text: "Кто разрабатывает перечень газоопасных работ?",
        answers: [
          "Каждое структурное подразделение эксплуатирующей организации",
          "Служба производственного контроля эксплуатирующей организации",
          "Газоспасательная служба",
          "Подразделения, которые обязаны готовить объекты к газоопасным работам",
        ],
        correctAnswers: [
          "Каждое структурное подразделение эксплуатирующей организации",
        ],
      },
      {
        code: "20115033",
        text: "Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года",
          "Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
      {
        code: "20115034",
        text: "Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?",
        answers: [
          "Руководитель структурного подразделения не более чем на 1 дневную рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск, на требуемый для окончания работ срок",
          "Руководитель структурного подразделения, не более чем на 1 рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск, не более чем на 1 дневную смену",
        ],
        correctAnswers: [
          "Руководитель структурного подразделения не более чем на 1 дневную рабочую смену",
        ],
      },
      {
        code: "20115035",
        text: "Кто утверждает наряд-допуск на проведение газоопасных работ?",
        answers: [
          "Руководитель эксплуатирующей организации",
          "Руководитель структурного подразделения",
          "Руководитель газоспасательной службы",
          "Руководитель службы производственного контроля",
        ],
        correctAnswers: ["Руководитель эксплуатирующей организации"],
      },
      {
        code: "20115036",
        text: "Кто осуществляет подготовку объекта к проведению на нем газоопасных и огневых работ?",
        answers: [
          "Работники, осуществляющие эксплуатацию объекта",
          "Работники газоспасательной службы",
          "Работники, осуществляющие эксплуатацию объекта, совместно с работниками аварийно-спасательных подразделений",
          "Работники, список которых определяется внутренними документами организации",
        ],
        correctAnswers: ["Работники, осуществляющие эксплуатацию объекта"],
      },
      {
        code: "20115037",
        text: "Какие противогазы или аппараты не допускается использовать для защиты органов дыхания работников внутри емкостей при проведении газоопасных работ?",
        answers: [
          "Фильтрующие противогазы",
          "Шланговые противогазы",
          "Кислородно-изолирующие противогазы",
          "Воздушные изолирующие аппараты",
        ],
        correctAnswers: ["Фильтрующие противогазы"],
      },
      {
        code: "20115038",
        text: "С кем необходимо согласовывать проведение работ в коллекторах, тоннелях, колодцах, приямках, траншеях и подобных им сооружениях?",
        answers: [
          "C руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы",
          "C руководителями службы производственного контроля",
          "C руководителями аварийно-спасательных служб",
          "C руководителями службы охраны труда и санитарными службами",
        ],
        correctAnswers: [
          "C руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы",
        ],
      },
      {
        code: "20115039",
        text: "К какой группе газоопасных работ относятся работы по установке (снятию) заглушек, и кто их проводит?",
        answers: [
          "К II группе, проводит эксплуатационный персонал",
          "К I группе, проводит бригада, определенная нарядом-допуском",
          "К I группе, проводит эксплуатационный персонал",
        ],
        correctAnswers: ["К II группе, проводит эксплуатационный персонал"],
      },
      {
        code: "20115040",
        text: "В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?",
        answers: [
          "Не менее 3 месяцев со дня закрытия наряда-допуска",
          "Не менее 1 года со дня закрытия наряда-допуска",
          "Не менее 6 месяцев со дня закрытия наряда-допуска",
        ],
        correctAnswers: ["Не менее 6 месяцев со дня закрытия наряда-допуска"],
      },
      {
        code: "20115041",
        text: "Допускается ли проведение огневых работ на действующих взрывопожароопасных производственных объектах?",
        answers: [
          "Допускается в исключительных случаях, когда отсутствует возможность их проведения в специально отведенных для этой цели постоянных местах",
          "Не допускается",
          "Допускается при соблюдении дополнительных требований безопасности",
          "Допускается при положительном заключении противопожарной службы",
        ],
        correctAnswers: [
          "Допускается в исключительных случаях, когда отсутствует возможность их проведения в специально отведенных для этой цели постоянных местах",
        ],
      },
      {
        code: "20115042",
        text: "Какими документами определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?",
        answers: [
          "Организационно-распорядительными документами руководителя",
          "Технологическим регламентом",
          "Федеральными нормами и правилами в области промышленной безопасности",
          "Правилами пожарной безопасности",
        ],
        correctAnswers: [
          "Организационно-распорядительными документами руководителя",
        ],
      },
      {
        code: "20115043",
        text: "Какие обязанности руководителя структурного подразделения, на объекте которого будут проводиться огневые работы, указаны неверно?",
        answers: [
          "Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ",
          "Назначение лиц, ответственных за подготовку и выполнение огневых работ",
          "Определение объема и содержания подготовительных работ и последовательности их выполнения",
          "Определение порядка контроля воздушной среды и выбор средств индивидуальной защиты",
        ],
        correctAnswers: [
          "Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ",
        ],
      },
      {
        code: "20115044",
        text: "Допускаются ли оформление и регистрация наряда-допуска на выполнение огневых работ в электронном виде?",
        answers: [
          "Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия",
          "Допускаются по решению руководителя эксплуатирующей организации",
          "Допускаются при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи",
          "Не допускаются",
        ],
        correctAnswers: [
          "Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия",
        ],
      },
      {
        code: "20115045",
        text: "При какой концентрации взрывопожароопасных веществ не допускается проведение огневых работ?",
        answers: [
          "При наличии взрывопожароопасных веществ выше 20 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения",
          "При наличии взрывопожароопасных веществ выше 15 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения",
          "При наличии взрывопожароопасных веществ выше 25 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения",
        ],
        correctAnswers: [
          "При наличии взрывопожароопасных веществ выше 20 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения",
        ],
      },
      {
        code: "20115046",
        text: "В течение какого времени должен быть обеспечен контроль (наблюдение) за местом наиболее возможного очага возникновения пожара работниками структурного подразделения, занятыми ведением технологического процесса?",
        answers: [
          "В течение четырех часов",
          "В течение суток",
          "В течение одного часа",
        ],
        correctAnswers: ["В течение четырех часов"],
      },
      {
        code: "20115047",
        text: "Кем определяются технические и организационные мероприятия, обеспечивающие безопасность ремонтных работ?",
        answers: [
          "Руководителем структурного подразделения ремонтируемого объекта совместно с непосредственным руководителем работ подрядной организации",
          "Руководителем эксплуатирующей организации, объект которой подлежит ремонту, совместно с руководителем структурного подразделения ремонтируемого объекта",
          "Непосредственным руководителем работ подрядной организации по согласованию с руководителем эксплуатирующей организации, объект которой подлежит ремонту",
        ],
        correctAnswers: [
          "Руководителем структурного подразделения ремонтируемого объекта совместно с непосредственным руководителем работ подрядной организации",
        ],
      },
      {
        code: "20115048",
        text: "При соблюдении какого требования выдается наряд-допуск на проведение ремонтных работ?",
        answers: [
          "После оформления акта сдачи-приемки объекта в ремонт",
          "После выполнения всех мероприятий, предусмотренных планом подготовительных работ",
          "После проверки выполнения всех мероприятий, предусмотренных планом подготовительных работ",
        ],
        correctAnswers: [
          "После оформления акта сдачи-приемки объекта в ремонт",
        ],
      },
      {
        code: "20115049",
        text: "Каким образом объект, ремонт которого закончен, принимается в эксплуатацию?",
        answers: [
          "По акту сдачи-приемки в эксплуатацию",
          "После закрытия наряда-допуска",
          "На основании положительного заключения экспертизы промышленной безопасности",
          "Совместным приказом руководителей эксплуатирующей и подрядной организаций",
        ],
        correctAnswers: ["По акту сдачи-приемки в эксплуатацию"],
      },
      {
        code: "20115050",
        text: "В соответствии с какими документами осуществляют ведение технологических процессов на химически опасных производственных объектах?",
        answers: [
          "В соответствии с руководствами (инструкциями) по эксплуатации технических устройств",
          "В соответствии с декларацией промышленной безопасности",
          "В соответствии с технологическими регламентами на производство продукции",
          "В соответствии с проектной документацией",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами на производство продукции",
        ],
      },
      {
        code: "20115051",
        text: "В каком случае допускается объединение выбросов химически опасных веществ, содержащих вещества, способные при смешивании образовывать более опасные по воздействиям химические соединения?",
        answers: [
          "Не допускается ни в каком случае",
          "Если выбросы рассчитываются в количествах определяемых условиями безопасной остановки технологического процесса",
          "В случае разработки необходимых мер, предусмотренных документацией на ХОПО",
          "В случае использования специальных систем аварийного освобождения",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20115052",
        text: "Каким из перечисленных способов не допускается прокладка кабелей по территории предприятий и установок?",
        answers: [
          "В галереях",
          "Все ответы неверны",
          "В каналах, засыпанных песком",
          "На кабельных конструкциях технологических эстакад",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20115053",
        text: "Кем разрабатываются исходные данные на разработку документации на химически опасных производственных объектах?",
        answers: [
          "Эксплуатирующими организациями",
          "Организациями, осуществляющими проведение экспертизы промышленной безопасности",
          "Ростехнадзором",
          "Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области",
        ],
        correctAnswers: [
          "Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области",
        ],
      },
      {
        code: "20115054",
        text: "На каких объектах ХОПО технические решения по обеспечению надежности контроля параметров, имеющих критические значения, обосновываются разработчиком документации на ХОПО? Укажите все правильные ответы.",
        answers: [
          "На всех объектах ХОПО",
          "На объектах ХОПО II класса",
          "На объектах ХОПО IV класса",
          "На объектах ХОПО III класса",
          "На объектах ХОПО I класса",
        ],
        correctAnswers: [
          "На объектах ХОПО IV класса",
          "На объектах ХОПО III класса",
        ],
      },
      {
        code: "20115055",
        text: "Какие требования предъявляются к обозначению средств автоматики, используемых согласно Плану мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Средства автоматики должны быть обозначены на мнемосхемах",
          "Средства автоматики должны быть обозначены только в технологическом регламенте на производство продукции",
          "Требования к обозначению определяются при разработке Плана мероприятий по локализации и ликвидации последствий аварий",
          "Средства автоматики должны быть обозначены по месту их установки и указываются в технологическом регламенте на производство продукции и инструкциях",
        ],
        correctAnswers: [
          "Средства автоматики должны быть обозначены по месту их установки и указываются в технологическом регламенте на производство продукции и инструкциях",
        ],
      },
      {
        code: "20115056",
        text: "Какие опасные производственные объекты должны оснащаться автоматическими и (или) автоматизированными системами управления, построенными на базе электронных средств контроля и автоматики, включая средства вычислительной техники?",
        answers: [
          "Имеющие в своем составе объекты с технологическими блоками только II категории взрывоопасности",
          "Имеющие в своем составе объекты с технологическими блоками I и II категорий взрывоопасности",
          "Имеющие в своем составе объекты с технологическими блоками только III категории взрывоопасности",
          "Имеющие в своем составе объекты с технологическими блоками только I категории взрывоопасности",
        ],
        correctAnswers: [
          "Имеющие в своем составе объекты с технологическими блоками I и II категорий взрывоопасности",
        ],
      },
      {
        code: "20115057",
        text: "Какие из перечисленных функций должна обеспечивать автоматизированная система управления технологическими процессами на базе средств вычислительной техники? Укажите все правильные ответы.",
        answers: [
          "Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации",
          "Периодический, не реже 2 раз в смену, контроль состояния воздушной среды в пределах объекта",
          "Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений",
          "Цикличный контроль параметров процесса и управление режимом для поддержания их регламентированных значений",
        ],
        correctAnswers: [
          "Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации",
          "Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений",
        ],
      },
      {
        code: "20115058",
        text: "Исходя из чего осуществляется проектирование системы противоаварийной автоматической защиты и выбор ее элементов?",
        answers: [
          "Исходя из условий обеспечения работы системы только в процессе эксплуатации в течение 5 лет",
          "Исходя из условий обеспечения работы системы только в процессе обслуживания в течение 1 года",
          "Исходя из условий обеспечения работы системы только в процессе ремонта",
          "Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта",
        ],
        correctAnswers: [
          "Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта",
        ],
      },
      {
        code: "20115059",
        text: "Где приводятся конкретные значения уставок систем защиты по опасным параметрам?",
        answers: [
          "В проектной документации и технологическом регламенте на производство продукции",
          "В технологическом регламенте на производство продукции",
          "В проектной документации",
        ],
        correctAnswers: [
          "В проектной документации и технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20115060",
        text: "Кто обосновывает достаточность аппаратурного резервирования и его тип?",
        answers: [
          "Разработчик проекта",
          "Технический руководитель организации",
          "Разработчик технологического процесса",
          "Представитель организации - изготовителя систем противоаварийной автоматической защиты",
          "Независимая экспертная организация",
        ],
        correctAnswers: ["Разработчик проекта"],
      },
      {
        code: "20115061",
        text: "Какие требования предъявляются к пневматическим системам контроля, управления и противоаварийной автоматической защиты?",
        answers: [
          "Использование инертного газа для питания систем контрольно-измерительных приборов и устройств автоматического регулирования должно определяться конкретными условиями и потребностями объекта",
          "Для пневматических систем контроля, управления и противоаварийной защиты должны предусматриваться единые установки и единые сети сжатого воздуха",
          "Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги",
          "Качество сжатого воздуха должно проверяться не реже одного раза в квартал",
        ],
        correctAnswers: [
          "Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги",
        ],
      },
      {
        code: "20115062",
        text: "Каким из перечисленных требований должны соответствовать помещения управления? Укажите все правильные ответы.",
        answers: [
          "Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности",
          "Защита от механических повреждений проложенных по полу кабелей должна осуществляться с помощью швеллеров и уголков",
          "Полы в помещении должны быть нескользкими, с повышенной механической стойкостью",
          "Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам",
        ],
        correctAnswers: [
          "Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности",
          "Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам",
        ],
      },
      {
        code: "20115063",
        text: "В технологических блоках какой категории взрывоопасности должны быть предусмотрены технические средства, обеспечивающие в автоматическом режиме оповещение об обнаружении, локализации и ликвидации выбросов опасных веществ?",
        answers: [
          "В технологических блоках всех категорий взрывоопасности",
          "Только в технологических блоках II категории взрывоопасности",
          "Только в технологических блоках III категории взрывоопасности",
          "Только в технологических блоках I категории взрывоопасности",
        ],
        correctAnswers: [
          "В технологических блоках всех категорий взрывоопасности",
        ],
      },
      {
        code: "20115064",
        text: "Где допускается расположение узла ввода теплоносителя?",
        answers: [
          "Только в помещениях систем приточной вентиляции (в вентиляционной камере)",
          "Во всех вышеуказанных местах",
          "Только в самостоятельном помещении с отдельным входом с лестничной клетки или из невзрывопожароопасных производственных помещений",
          "Только в производственных помещениях, в которых предусмотрено применение водяного или парового отопления",
        ],
        correctAnswers: ["Во всех вышеуказанных местах"],
      },
      {
        code: "20115065",
        text: "В каких случаях должны автоматически включаться системы аварийной вентиляции? Укажите все правильные ответы.",
        answers: [
          "При поступлении сигнала от датчиков контроля за содержанием взрывопожароопасной пыли в воздухе помещения",
          "При поступлении сигнала от датчиков повышения температуры",
          "При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
          "При срабатывании сигнализаторов довзрывных концентраций вредных веществ",
        ],
        correctAnswers: [
          "При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ",
          "При срабатывании сигнализаторов довзрывных концентраций вредных веществ",
        ],
      },
      {
        code: "20115066",
        text: "Без чего не допускается прямое соединение канализации химически загрязненных стоков с хозяйственно-бытовой канализацией на взрывопожароопасных производствах?",
        answers: [
          "Без предохранительного клапана",
          "Без фильтра",
          "Без регулируемого клапана",
          "Без гидрозатвора",
          "Без компенсатора",
        ],
        correctAnswers: ["Без гидрозатвора"],
      },
      {
        code: "20115067",
        text: "Где не допускается располагать колодцы на сетях канализации во взрывопожароопасных производствах?",
        answers: [
          "Под эстакадами технологических трубопроводов и в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты",
          "Только в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты",
          "Только под эстакадами технологических трубопроводов",
        ],
        correctAnswers: [
          "Под эстакадами технологических трубопроводов и в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты",
        ],
      },
      {
        code: "20115068",
        text: "При рытье котлованов и траншей на какую глубину следует принимать меры, препятствующие отвисанию и обвалу грунта (образование откосов, крепление стенок)?",
        answers: [
          "На глубину 0,7 м",
          "На глубину 0,5 м",
          "На глубину 0,6 м",
          "На глубину 0,9 м",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20115069",
        text: "Какие требования к подготовительным работам при проведении земляных работ указаны неверно?",
        answers: [
          "До начала проведения земляных работ исполнители земляных работ должны пройти инструктаж на рабочем месте о мерах промышленной безопасности",
          "Перед разработкой грунта на месте работ следует выставить предупредительные знаки",
          "Перед разработкой грунта место работ следует оградить по всему периметру",
          "Все требования указаны верно",
        ],
        correctAnswers: ["Все требования указаны верно"],
      },
      {
        code: "20115070",
        text: "Какие меры обеспечения безопасности при проведении ремонтных работ указаны неверно?",
        answers: [
          "При выполнении ремонтных работ не следует допускать загромождения проездов к пожарным гидрантам, а также к территории ремонтируемого объекта оборудованием, материалами, металлоломом",
          "При изменении обстановки в ремонтной зоне (возникновение парений, утечки газа или жидких продуктов) или на расположенном вблизи действующем объекте исполнители ремонтных работ должны доложить непосредственному руководителю работ или лицу, ответственному за подготовку объекта к ремонту. Приостанавливать работу допускается только с разрешения непосредственного руководителя работ",
          "Не допускается использовать металлические и железобетонные конструкции зданий, сооружений в качестве опор при подъеме и перемещении оборудования и его частей (узлов) без проверки расчетом прочности конструкций",
        ],
        correctAnswers: [
          "При изменении обстановки в ремонтной зоне (возникновение парений, утечки газа или жидких продуктов) или на расположенном вблизи действующем объекте исполнители ремонтных работ должны доложить непосредственному руководителю работ или лицу, ответственному за подготовку объекта к ремонту. Приостанавливать работу допускается только с разрешения непосредственного руководителя работ",
        ],
      },
      {
        code: "20115071",
        text: "Какие действия должны выполнить непосредственный руководитель работ подрядной организации совместно с руководителем структурного подразделения ремонтируемого объекта после окончания ремонтных работ?",
        answers: [
          "Закрыть наряд-допуск на проведение ремонтных работ и подписать его",
          "Все перечисленные",
          "Оформить акт сдачи-приемки объекта в эксплуатацию по форме, установленной внутренними документами эксплуатирующей организации",
          "Проверить полноту выполнения ремонтных работ в целях безопасного ввода в эксплуатацию объекта",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20115072",
        text: "В каком случае наряд-допуск на проведение ремонтных работ подлежит переоформлению, а ремонтные работы должны быть приостановлены?",
        answers: [
          "Только если нарушены меры, обеспечивающие безопасность проведения работ",
          "Только если изменены объемы и характер работы, влекущие за собой изменение схем отключения и условия работы",
          "Только если произведена замена непосредственного руководителя работ подрядной организации",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20115073",
        text: "Какие требования к проведению ремонтных работ указаны неверно?",
        answers: [
          "Инструктаж вновь введенным исполнителям ремонтных работ о мерах безопасности при их выполнении проводится бригадиром подрядной организации, прошедшим инструктаж перед началом проведения работ",
          "Ремонтные работы проводятся при отсутствии в ремонтной зоне опасных веществ",
          "Анализ состояния воздушной среды в ремонтной зоне должен проводиться по требованию непосредственного руководителя работ подрядной организации",
          "Инструктаж исполнителям ремонтных работ о мерах безопасности при их выполнении проводится непосредственным руководителем работ подрядной организации",
        ],
        correctAnswers: [
          "Инструктаж вновь введенным исполнителям ремонтных работ о мерах безопасности при их выполнении проводится бригадиром подрядной организации, прошедшим инструктаж перед началом проведения работ",
        ],
      },
      {
        code: "20115074",
        text: "Кем из перечисленных лиц подтверждается ежедневный допуск ремонтных бригад подрядной организации к выполнению ремонтных работ с продлением наряда-допуска?",
        answers: [
          "Всеми перечисленными лицами",
          "Только непосредственным руководителем работ подрядной организации",
          "Только лицом, ответственным за подготовку объекта",
          "Только руководителем структурного подразделения ремонтируемого объекта",
        ],
        correctAnswers: ["Всеми перечисленными лицами"],
      },
      {
        code: "20115075",
        text: "У кого из перечисленных лиц должен храниться экземпляр наряда-допуска на проведение ремонтных работ? Укажите все правильные ответы.",
        answers: [
          "У руководителя пожарной службы (при наличии) или другой службы эксплуатирующей организации, на которую возложены функции обеспечения мер пожарной безопасности",
          "У лица, ответственного за подготовку объекта в целом или оборудования, технических устройств, коммуникаций к ремонту, из числа специалистов, в ведении которого находятся работники, осуществляющие эксплуатацию объекта или оборудования",
          "У руководителя структурного подразделения ремонтируемого объекта, ответственного за допуск ремонтных бригад подрядной организации к выполнению ремонтных работ",
          "У непосредственного руководителя работ подрядной организации",
        ],
        correctAnswers: [
          "У руководителя структурного подразделения ремонтируемого объекта, ответственного за допуск ремонтных бригад подрядной организации к выполнению ремонтных работ",
          "У непосредственного руководителя работ подрядной организации",
        ],
      },
      {
        code: "20115076",
        text: "Какие требования к обязанностям непосредственного руководителя ремонтных работ указаны неверно?",
        answers: [
          "Должен знать безопасные методы и приемы ведения ремонтных работ",
          "Должен знать возможные опасности и характер их проявления при производстве ремонтных работ",
          "При выполнении ремонтных работ должен назначать ответственного за ходом работ из состава ремонтной бригады",
        ],
        correctAnswers: [
          "При выполнении ремонтных работ должен назначать ответственного за ходом работ из состава ремонтной бригады",
        ],
      },
      {
        code: "20115077",
        text: "В каких случаях при проведении ремонтных работ наряд-допуск подлежит переоформлению, а работы должны быть приостановлены?",
        answers: [
          "Только если произведена замена непосредственного руководителя работ подрядной организации",
          "Только если нарушены меры, обеспечивающие безопасность проведения работ",
          "Только если изменены объемы и характер работы, влекущие за собой изменение схем отключения и условия работы",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20115078",
        text: "Какие требования при проведении ремонтных работ на технологическом оборудовании, где возможно выделение в ремонтную зону опасных веществ, указаны неверно?",
        answers: [
          "Ремонтные работы следует проводить при наличии в ремонтной зоне не более 20 мг/м³ опасных веществ",
          "Следует проводить анализ состояния воздушной среды, результаты которого должны быть внесены в наряд-допуск",
          "Анализ состояния воздушной среды в ремонтной зоне должен проводиться по требованию непосредственного руководителя работ подрядной организации",
        ],
        correctAnswers: [
          "Ремонтные работы следует проводить при наличии в ремонтной зоне не более 20 мг/м³ опасных веществ",
        ],
      },
      {
        code: "20115079",
        text: "Кто из перечисленных лиц определяет технические и организационные мероприятия, обеспечивающие безопасность ведения газоопасных, огневых и ремонтных работ? Укажите все правильные ответы.",
        answers: [
          "Руководитель структурного подразделения ремонтируемого объекта",
          "Руководитель подрядной организации",
          "Непосредственный руководитель работ подрядной организации",
          "Технический руководитель ремонтируемого объекта",
        ],
        correctAnswers: [
          "Руководитель структурного подразделения ремонтируемого объекта",
          "Непосредственный руководитель работ подрядной организации",
        ],
      },
      {
        code: "20115080",
        text: "Кем из перечисленных лиц может осуществляться общая координация ремонтных работ на объекте, где ремонтные работы производятся несколькими подрядными организациями и заказчиком?",
        answers: [
          "Лицом, назначенным уполномоченным заместителем руководителя эксплуатирующей организации",
          "Любым из перечисленных лиц",
          "Лицом, назначенным руководителем эксплуатирующей организации",
          "Руководителем филиала (дочернего общества)",
        ],
        correctAnswers: ["Любым из перечисленных лиц"],
      },
      {
        code: "20115081",
        text: 'На сколько этапов разделяются ремонтные работы согласно Федеральным нормам и правилам в области промышленной безопасности "Правила безопасного ведения газоопасных, огневых и ремонтных работ"?',
        answers: ["Четыре", "Три", "Два", "Один"],
        correctAnswers: ["Два"],
      },
      {
        code: "20115082",
        text: 'Какие существуют виды ремонтных работ согласно Федеральным нормам и правилам в области промышленной безопасности "Правила безопасного ведения газоопасных, огневых и ремонтных работ"?',
        answers: [
          "Только внеплановые (неплановые) ремонтные работы",
          "Только плановые ремонтные работы",
          "Только аварийно-восстановительные ремонтные работы",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20115083",
        text: "Какие из перечисленных мероприятий при выполнении подготовительных работ к проведению огневых работ указаны неверно?",
        answers: [
          "При выполнении огневых работ в помещении следует предусмотреть меры защиты от разлета и попадания искр в проемы межэтажных перекрытий, а также лотков и приямков",
          "Сливные воронки, выходы из лотков и другие устройства, связанные с канализацией, в которых могут быть горючие газы и пары, должны быть герметизированы",
          "Место выполнения огневых работ должно быть обеспечено огнетушителем и другими первичными средствами пожаротушения, указанными в наряде-допуске на выполнение огневых работ",
          "В помещении в зоне выполнения огневых работ следует обеспечить автоматическое включение системы вентиляции при превышении уровня предельно допустимой концентрации вредных веществ",
        ],
        correctAnswers: [
          "В помещении в зоне выполнения огневых работ следует обеспечить автоматическое включение системы вентиляции при превышении уровня предельно допустимой концентрации вредных веществ",
        ],
      },
      {
        code: "20115084",
        text: "При наличии в зоне проведения огневых работ сгораемых конструкций, каким образом последние должны быть защищены от возгораний?",
        answers: [
          "Только пролиты водой",
          "Только очищены от взрывопожароопасных продуктов",
          "Всеми перечисленными способами",
          "Только закрыты металлическими или асбестовыми экранами",
        ],
        correctAnswers: ["Всеми перечисленными способами"],
      },
      {
        code: "20115085",
        text: "Что указывается на схеме места выполнения огневых работ?",
        answers: [
          "Все перечисленное",
          "Только места размещения сварочного и другого оборудования для проведения огневых работ, места установки предупредительных знаков",
          "Только место выполнения огневых работ и границы опасной зоны, места отбора проб воздуха",
          "Только место расположение автомобильной техники",
          "Только места расположения запорной арматуры и установки заглушек на технологическом оборудовании и трубопроводах",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20115086",
        text: "В каких случаях огневые работы могут проводиться в ночное время суток?",
        answers: [
          "В случаях, когда наряд-допуск подписан руководителем эксплуатирующей организации, на объекте которой будут проводиться огневые работы",
          "В случаях ликвидации или локализации возможных аварий",
          "Ни в каких случаях",
          "В случаях, когда наряд-допуск подписан руководителем структурного подразделения, на объекте которого будут проводиться огневые работы",
        ],
        correctAnswers: [
          "В случаях ликвидации или локализации возможных аварий",
        ],
      },
      {
        code: "20115087",
        text: "Какое из перечисленных должностных лиц имеет право ставить подписи в наряде-допуске на проведение газоопасных работ, подтверждающие его закрытие и выполнение работ в полном объеме?",
        answers: [
          "Лицо, ответственное за проведение газоопасных работ",
          "Руководитель структурного подразделения",
          "Все перечисленные",
          "Должностное лицо, ответственное за безопасное ведение технологического процесса на объекте",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20115088",
        text: "В каких местах допускается газоопасная работа без изолирующих средств защиты органов дыхания?",
        answers: [
          "Не допускается во всех перечисленных местах",
          "В колодцах",
          "В тоннелях",
          "В коллекторах",
        ],
        correctAnswers: ["Не допускается во всех перечисленных местах"],
      },
      {
        code: "20115089",
        text: "Какое минимальное количество наблюдающих должно быть, если существует необходимость выполнения газоопасных работ в емкости (аппарате) двумя работающими?",
        answers: [
          "Два",
          "Четыре",
          "Один",
          "Определяется ответственным за проведение газоопасных работ",
        ],
        correctAnswers: ["Два"],
      },
      {
        code: "20115090",
        text: "Какие действия требуется выполнить перед началом проведения газоопасных, огневых и ремонтных работ внутри емкостей при наличии в них перемешивающих устройств с электроприводом?",
        answers: [
          "Отключить электропривод от источников питания видимым разрывом",
          'Вывесить плакаты на емкостях "Газ", "Газоопасные работы"',
          'Вывесить плакаты у емкостей в электрораспределительных устройствах "Не включать: работают люди!"',
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20115091",
        text: "Что необходимо выполнить перед началом проведения газоопасных, огневых и ремонтных работ внутри емкостей (аппаратов) для оценки качества выполнения подготовительных мероприятий?",
        answers: [
          "Произвести анализ воздушной среды на содержание опасных веществ (паров, газов)",
          "Все перечисленное",
          'Вывесить предупреждающие плакаты "Газ", "Газоопасные работы"',
          "Произвести продувку инертным газом и воздухом с записью результатов в наряде-допуске",
        ],
        correctAnswers: [
          "Произвести анализ воздушной среды на содержание опасных веществ (паров, газов)",
        ],
      },
      {
        code: "20115092",
        text: "Какие действия допускается выполнять с емкостями (аппаратами), подлежащими вскрытию, осмотру, чистке или ремонту?",
        answers: [
          "Отключать от действующего оборудования, систем трубопроводов и коммуникаций с помощью заглушек",
          "Промывать и пропаривать",
          "Все перечисленные",
          "Продувать инертным газом или воздухом",
          "Освобождать от опасных веществ",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20115093",
        text: "Какие действия следует выполнить для обеспечения безопасного проведения подготовительных работ и газоопасных работ? Укажите все правильные ответы.",
        answers: [
          "Провести инструктаж работникам подрядных организаций об основных опасных факторах производства",
          "Лицу, ответственному за проведение газоопасной работы, проверить полноту и качество выполненных подготовительных работ после их окончания",
          "Предупредить работников, занятых ведением технологического процесса, о проводимых газоопасных работах с записью в журнале ведения технологического процесса (вахтенный журнал, журнал приема-сдачи смен)",
          "Во время проведения подготовительных работ выявить участки возможного выделения в зону работ взрывопожароопасных веществ с целью дальнейшего совмещения газоопасных и огневых работ в одном помещении",
        ],
        correctAnswers: [
          "Провести инструктаж работникам подрядных организаций об основных опасных факторах производства",
          "Предупредить работников, занятых ведением технологического процесса, о проводимых газоопасных работах с записью в журнале ведения технологического процесса (вахтенный журнал, журнал приема-сдачи смен)",
        ],
      },
      {
        code: "20115094",
        text: "Какие виды работ относятся к подготовительным для проведения газоопасных работ?",
        answers: [
          "Работы, связанные с подготовкой технологического оборудования и трубопроводов",
          "Работы, связанные с подготовкой коммуникаций",
          "Все перечисленные виды работ",
          "Работы, связанные с подготовкой емкостей",
        ],
        correctAnswers: ["Все перечисленные виды работ"],
      },
      {
        code: "20115095",
        text: "Что является обязательным требованием к специалистам и персоналу эксплуатирующих и подрядных организаций, выполняющих газоопасные и огневые работы на опасных производственных объектах?",
        answers: [
          "Наличие протокола проверки знаний правил работы в электроустановках",
          "Прохождение аттестации в области промышленной безопасности в объеме, соответствующем должностным обязанностям",
          "Все перечисленное",
          "Наличие протокола аттестации сварщика или специалиста сварочного производства",
        ],
        correctAnswers: [
          "Прохождение аттестации в области промышленной безопасности в объеме, соответствующем должностным обязанностям",
        ],
      },
      {
        code: "20115096",
        text: "В каком документе указываются регламентированные параметры технологического процесса?",
        answers: [
          "В техническом регламенте",
          "В технологическом регламенте",
          "В проектной документации",
          "В руководствах по безопасности",
        ],
        correctAnswers: ["В технологическом регламенте"],
      },
      {
        code: "20115097",
        text: "Какое допускается заполнение емкостей для хранения и транспортирования ЛВЖ и ГЖ?",
        answers: [
          "Не более чем на 85 % объема",
          "Не более чем на 95 % объема",
          "Не более чем на 90 % объема",
          "Не более чем на 75 % объема",
        ],
        correctAnswers: ["Не более чем на 85 % объема"],
      },
      {
        code: "20115098",
        text: "При какой максимальной скорости ветра допускается транспортирование ИВВ и составов на их основе?",
        answers: ["12 м/с", "15 м/с", "10 м/с", "7 м/с"],
        correctAnswers: ["12 м/с"],
      },
      {
        code: "20115099",
        text: "Чем не должны быть обеспечены помещения или площадки для проведения испытаний взрывчатых веществ и составов, порохов, твердых ракетных топлив, пиротехнических составов и снаряженных ими изделий?",
        answers: [
          "Предупреждающей сигнализацией о начале и окончании испытаний",
          "Телефонной, а также радио-, двухсторонней громкоговорящей или иным видом связи",
          "Укрытиями для обслуживающего персонала",
          "Аварийной сигнализацией",
        ],
        correctAnswers: ["Аварийной сигнализацией"],
      },
      {
        code: "20115100",
        text: "Какие должны быть проходы при размещении ВВ и изделий, их содержащих, в штабелях и на стеллажах?",
        answers: [
          "Для осмотра должны быть не менее 0,9 м, для погрузки и разгрузки - не менее 1,3 м",
          "Для осмотра должны быть не менее 0,5 м, для погрузки и разгрузки - не менее 1,2 м.",
          "Для осмотра должны быть не менее 0,7 м, для погрузки и разгрузки - не менее 1,5 м.",
          "Для осмотра должны быть не менее 1 м, для погрузки и разгрузки - не менее 2 м.",
        ],
        correctAnswers: [
          "Для осмотра должны быть не менее 0,7 м, для погрузки и разгрузки - не менее 1,5 м.",
        ],
      },
      {
        code: "20115101",
        text: "Какие из перечисленных действий допускаются во время работы кислотоотжимной центрифуги?",
        answers: [
          "Открывание крышки центрифуги",
          "Нахождение непосредственно у работающей центрифуги",
          "Все ответы неверны",
          "Превышение предусмотренного технологическим регламентом времени отжима на центрифуге",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20115102",
        text: "При каком условии допускается проводить проверку электрических характеристик взрывательных устройств в присутствии людей?",
        answers: [
          "Если количество ВВ не превышает 15 г в тротиловом эквиваленте и защитное устройство (бронекабина, бронемуфель) рассчитано на локализацию срабатывания изделия внутри защитного устройства",
          "Если количество ВВ не превышает 20 г в тротиловом эквиваленте и защитное устройство (бронекабина, бронемуфель) рассчитано на локализацию срабатывания изделия внутри защитного устройства",
          "Ни при каком условии",
          "Если количество ВВ не превышает 25 г в тротиловом эквиваленте и защитное устройство (бронекабина, бронемуфель) рассчитано на локализацию срабатывания изделия внутри защитного устройства",
        ],
        correctAnswers: [
          "Если количество ВВ не превышает 15 г в тротиловом эквиваленте и защитное устройство (бронекабина, бронемуфель) рассчитано на локализацию срабатывания изделия внутри защитного устройства",
        ],
      },
      {
        code: "20115103",
        text: "Какой должна быть скорость срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах III класса опасности?",
        answers: [
          "Не менее 12 секунд",
          "Не более 120 секунд",
          "Не менее 300 секунд",
          "Для блоков II категории взрывоопасности - 12 секунд, для блоков III категории - 120 секунд",
        ],
        correctAnswers: ["Не более 120 секунд"],
      },
      {
        code: "20115104",
        text: "Куда следует направлять сбрасываемые химически опасные вещества?",
        answers: [
          "На факельную установку",
          "В специальные контейнеры",
          "В закрытые системы для дальнейшей утилизации",
          "В централизованную систему водоотведения",
        ],
        correctAnswers: ["В закрытые системы для дальнейшей утилизации"],
      },
      {
        code: "20115105",
        text: "В каком документе организация, эксплуатирующая химически опасные производственные объекты I, II и III классов опасности, должна предусматривать действия персонала по предупреждению аварий, локализации и ликвидации их последствий?",
        answers: [
          "В плане по локализации аварийных ситуаций",
          "В плане мероприятий по локализации и ликвидации последствий аварий",
          "В Положении о производственном контроле",
          "В технологическом регламенте",
          "В инструкции по эксплуатации",
        ],
        correctAnswers: [
          "В плане мероприятий по локализации и ликвидации последствий аварий",
        ],
      },
      {
        code: "20115106",
        text: "Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?",
        answers: [
          "Рациональный подбор взаимодействующих компонентов исходя из условия максимального снижения или исключения образования химически опасных смесей или продуктов",
          "Применение компонентов в фазовом состоянии, затрудняющем или исключающем образование химически опасной смеси",
          "Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки",
          "Нарушение энергообеспечения",
        ],
        correctAnswers: [
          "Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки",
        ],
      },
      {
        code: "20115107",
        text: "В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечить способность функционирования средств противоаварийной защиты?",
        answers: [
          "В течение 24 часов",
          "В течение 8 часов",
          "Время устанавливается в проектной документации",
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
        correctAnswers: [
          "В течение времени, достаточного для исключения опасной ситуации",
        ],
      },
      {
        code: "20115108",
        text: "Какой должна быть скорость срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах I и II классов опасности?",
        answers: [
          "С автоматическим управлением - не более 12 секунд",
          "С автоматическим управлением - не более 120 секунд",
          "С автоматическим управлением - не более 300 секунд",
          "С автоматическим управлением -не более 350 секунд",
        ],
        correctAnswers: ["С автоматическим управлением - не более 12 секунд"],
      },
      {
        code: "20115109",
        text: "Для каких из перечисленных целей запрещается использовать специальные системы аварийного освобождения?",
        answers: [
          "Для обеспечения минимально возможного времени освобождения",
          "Для исключения образования химически опасных смесей в самих системах",
          "Для исключения развития аварий",
          "Все ответы неверны",
          "Для исключения образования химически опасных смесей в окружающей атмосфере",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20115110",
        text: "Какое из перечисленных требований к выполнению управляющих функций систем ПАЗ указано неверно?",
        answers: [
          "Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы",
          "В алгоритмах срабатывания защит следует предусматривать возможность включения блокировки команд управления оборудованием, технологически связанным с аппаратом, агрегатом или иным оборудованием, вызвавшим такое срабатывание",
          "Команды управления, сформированные алгоритмами защит (блокировок), должны иметь приоритет по отношению к любым другим командам управления технологическим оборудованием, в том числе к командам, формируемым оперативным персоналом АСУТП, если иное не оговорено в техническом задании на ее создание",
          "Системы ПАЗ должны реализовываться на принципах приоритетности защиты технологических процессов комплектно с одновременной защитой отдельных единиц оборудования",
        ],
        correctAnswers: [
          "Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы",
        ],
      },
      {
        code: "20115111",
        text: "В каком случае необходима подача световых сигналов для оповещения при проведении подрыва (поджигания) взрывчатых веществ и изделий на их основе?",
        answers: [
          "По требованию ответственного за проведение работ",
          "Во всех перечисленных случаях",
          "В темное время суток",
          "При скорости ветра более 12 м/с",
        ],
        correctAnswers: ["В темное время суток"],
      },
      {
        code: "20115112",
        text: "Какая допускается максимальная температура подаваемого пара в мастерские плавки тротила и намотки сгорающих гильз?",
        answers: ["115 °C", "165 °C", "150 °C", "135 °C"],
        correctAnswers: ["135 °C"],
      },
      {
        code: "20115113",
        text: "С какой периодичностью должен быть установлен визуальный или автоматический контроль температуры теплоносителя с записью показаний температуры в соответствующем журнале при сушке и полимеризации пиротехнических составов и изделий?",
        answers: [
          "Не реже одного раза в два часа",
          "Не реже одного раза в час",
          "Не реже двух раз в сутки",
          "Не реже одного раза в сутки",
        ],
        correctAnswers: ["Не реже одного раза в час"],
      },
      {
        code: "20115114",
        text: "Какую относительную влажность воздуха необходимо поддерживать в помещениях, связанных с переработкой сухих ВВ, если другое не предусмотрено проектной и технологической документацией?",
        answers: [
          "Не менее 75 %",
          "Не менее 65 %",
          "Не менее 40 %",
          "Не менее 55 %",
        ],
        correctAnswers: ["Не менее 65 %"],
      },
      {
        code: "20115115",
        text: "Какая из перечисленных сборок боеприпасов допускается в одном помещении?",
        answers: [
          "Все ответы неверны",
          "Одновременная сборка вкладных элементов различных категорий опасности",
          "Сборка окончательно снаряженных вкладных элементов кассетных боеприпасов и сборка кассетных боеприпасов",
          "Сборка кассетных боеприпасов по различным чертежам",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20115116",
        text: "Какое из перечисленных требований допускается при снаряжении, сборке и разборке боеприпасов с пороховыми реактивными двигателями, пороховых реактивных двигателей, газогенераторов и ракет? Выберите 2 варианта ответа.",
        answers: [
          "Проведение сборочных операций, включая операции вставки зарядов твердого ракетного топлива и воспламенителя, на потоке сборки, расположенном в одном помещении, если это предусмотрено технологической документацией",
          "Одновременное проведение работ по снаряжению и утилизации в одном помещении, если это предусмотрено технологической документацией",
          "Проведение сборочных операций на одном потоке, расположенном в одном помещении, включая операции вставки порохового заряда и воспламенителя, технологические операции по сборке комплектующих элементов, содержащих ВВ, пиротехнические составы и пороховые заряды, при соблюдении специальных мер безопасности, изложенных в технологической документации",
          "Операции подготовки зарядов ракетного твердого топлива и воспламенителей в одном помещении, если это предусмотрено технологической документацией",
        ],
        correctAnswers: [
          "Проведение сборочных операций, включая операции вставки зарядов твердого ракетного топлива и воспламенителя, на потоке сборки, расположенном в одном помещении, если это предусмотрено технологической документацией",
          "Проведение сборочных операций на одном потоке, расположенном в одном помещении, включая операции вставки порохового заряда и воспламенителя, технологические операции по сборке комплектующих элементов, содержащих ВВ, пиротехнические составы и пороховые заряды, при соблюдении специальных мер безопасности, изложенных в технологической документации",
        ],
      },
      {
        code: "20115117",
        text: "В соответствии с требованиями какой документации должна производиться подготовка материалов для снаряжения боеприпасов прессованием?",
        answers: [
          "В соответствии с требованиями технологической документации",
          "В соответствии с Основными требованиями безопасности для объектов производств боеприпасов и спецхимии",
          "В соответствии с требованиями конструкторской документации",
          "В соответствии с требованиями инструкции по охране труда",
        ],
        correctAnswers: [
          "В соответствии с требованиями технологической документации",
        ],
      },
      {
        code: "20115118",
        text: "В каком случае резку баллиститного порохового заряда допускается проводить только дистанционно автоматическим ножом при отсутствии людей?",
        answers: [
          "Если пороховой заряд диаметром 90 мм и более",
          "Если пороховой заряд диаметром 105 мм и более",
          "Во всех перечисленных случаях",
          "Если пороховой заряд диаметром 85 мм и более",
        ],
        correctAnswers: ["Если пороховой заряд диаметром 105 мм и более"],
      },
      {
        code: "20115119",
        text: "Какую вентиляцию должны иметь места загрузки и выгрузки порошкообразных ВВ?",
        answers: [
          "Местную вытяжную вентиляцию",
          "Аварийную вентиляцию",
          "Местную приточную вентиляцию",
          "Общеобменную вентиляцию",
          "Систему местного отсоса",
        ],
        correctAnswers: ["Местную вытяжную вентиляцию"],
      },
      {
        code: "20115120",
        text: "Какой из указанных факторов, которые необходимо учитывать при оценке риска аварий для объектов производств боеприпасов и спецхимии, указан неверно?",
        answers: [
          "Уровень подготовки персонала",
          "Действие блокировок, систем и средств автоматического контроля и регулирования",
          "Влияние степени быстродействия и эффективности систем ПАЗ",
          "Защитные мероприятия по эвакуации людей",
        ],
        correctAnswers: ["Уровень подготовки персонала"],
      },
      {
        code: "20115121",
        text: "Где разрешается проводить снаряжение средств воспламенения и взрывания?",
        answers: [
          "Только в отдельных зданиях",
          "В одном здании, но в отдельных помещениях, выгороженных стенами или перегородками",
          "В одном изолированном помещении с отдельным входом и выходом",
        ],
        correctAnswers: [
          "В одном здании, но в отдельных помещениях, выгороженных стенами или перегородками",
        ],
      },
      {
        code: "20115122",
        text: "Что из перечисленного допускается при производстве промышленных взрывчатых веществ?",
        answers: [
          "Патронирование аммонитов на автоматах типа АПК в кабинах",
          "Применение элеваторов для транспортирования аммонитов",
          "Применение в производстве промышленных ВВ непросеянных сыпучих компонентов",
          "Влажная уборка в помещении темперирования и дозировки алюминиевой пудры",
          "Применение ременной передачи для барабанов смешения",
          "Нахождение работников в кабине при работающем оборудовании",
        ],
        correctAnswers: [
          "Патронирование аммонитов на автоматах типа АПК в кабинах",
        ],
      },
      {
        code: "20115123",
        text: "В каком случае допускается вручную загружать в сушилки и выгружать из них ВВ?",
        answers: [
          "При условии обеспечения безопасности работников в случаях, предусмотренных технологическим регламентом, утвержденным руководителем организации (его заместителем), эксплуатирующей ОПО",
          "В исключительном случае, на усмотрение работника и в присутствии наблюдающего",
          "Ни в каком случае",
          "В случае выполнения данных работ не менее двумя работниками в присутствии наблюдающего",
        ],
        correctAnswers: [
          "При условии обеспечения безопасности работников в случаях, предусмотренных технологическим регламентом, утвержденным руководителем организации (его заместителем), эксплуатирующей ОПО",
        ],
      },
      {
        code: "20115124",
        text: "Какое количество боеприпасов, переданных на утилизацию, подвергается контролю на безопасность при транспортировании и в служебном обращении?",
        answers: [
          "100 % боеприпасов",
          "95 % боеприпасов",
          "90 % боеприпасов",
          "85 % боеприпасов",
        ],
        correctAnswers: ["100 % боеприпасов"],
      },
      {
        code: "20115125",
        text: "Просеивание каких из перечисленных взрывчатых веществ должно быть механизировано и проводиться под водой?",
        answers: [
          "Тетразена",
          "Всех перечисленных взрывчатых веществ",
          "Азида натрия",
          "Гремучей ртути",
          "ТНРС",
        ],
        correctAnswers: ["Гремучей ртути"],
      },
      {
        code: "20115126",
        text: "Кем определяется порядок сверления отверстий в изделиях из БРТТ?",
        answers: [
          "Научно-исследовательской организацией",
          "Экспертной организацией",
          "Организацией-разработчиком",
          "Ростехнадзором",
          "Проектной организацией",
        ],
        correctAnswers: ["Организацией-разработчиком"],
      },
      {
        code: "20115127",
        text: "Из какого материала должны быть изготовлены плавильные котлы аппаратов для приготовления взрывчатых составов, содержащих высокомощные взрывчатые вещества? Выберите 2 варианта ответа.",
        answers: [
          "Из низкоуглеродистой стали",
          "Из чугуна",
          "Из цветного металла",
          "Из нержавеющей стали",
        ],
        correctAnswers: ["Из цветного металла", "Из нержавеющей стали"],
      },
      {
        code: "20115128",
        text: "Какая должна быть температура наружных поверхностей оборудования и кожухов теплоизоляционных покрытий?",
        answers: [
          "В местах, доступных для обслуживания персонала - не более 40 °С внутри помещений и 55 °С на наружных установках",
          "В местах, доступных для обслуживания персонала - не более 55 °С внутри помещений и 60 °С на наружных установках",
          "В местах, доступных для обслуживания персонала - не более 45 °С внутри помещений и 60 °С на наружных установках",
          "В местах, доступных для обслуживания персонала - не более 50 °С внутри помещений и 65 °С на наружных установках",
        ],
        correctAnswers: [
          "В местах, доступных для обслуживания персонала - не более 45 °С внутри помещений и 60 °С на наружных установках",
        ],
      },
      {
        code: "20115129",
        text: "Чем должны быть оснащены нитрационные аппараты на особо опасных операциях?",
        answers: [
          "Приборами и датчиками автоматического и дистанционного контроля",
          "Системами противоаварийной защиты",
          "Аварийными спусками или устройствами для залива водой, управляемыми вручную по месту, автоматически или дистанционно с пульта управления",
          "Устройствами, предусмотренными в технологических регламентах",
          "Световой сигнализацией",
        ],
        correctAnswers: [
          "Аварийными спусками или устройствами для залива водой, управляемыми вручную по месту, автоматически или дистанционно с пульта управления",
        ],
      },
      {
        code: "20115130",
        text: "Какой должен быть уровень влажности измельченного нитрата целлюлозы, предназначенного для хранения?",
        answers: [
          "Должен быть определен технологическим регламентом, но не менее 35 %",
          "Должен быть определен конструкторской документацией, но не менее 15 %",
          "Должен быть определен технологическим регламентом, но не менее 25 %",
          "Должен быть определен Основными требованиями безопасности для объектов производств боеприпасов и спецхимии",
        ],
        correctAnswers: [
          "Должен быть определен технологическим регламентом, но не менее 25 %",
        ],
      },
      {
        code: "20115131",
        text: "С учетом каких исследований должна проводиться разработка технологических процессов?",
        answers: [
          "С учетом результатов анализа физико-химических и взрывчатых характеристик веществ, применяемых в технологических процессах, идентификации опасностей, оценки риска, разработки мер, направленных на снижение уровня риска",
          "С учетом результатов токсикологических исследований",
          "С учетом результатов расчета взрывоустойчивости объектов производств боеприпасов и спецхимии",
          "С учетом климатических условий",
        ],
        correctAnswers: [
          "С учетом результатов анализа физико-химических и взрывчатых характеристик веществ, применяемых в технологических процессах, идентификации опасностей, оценки риска, разработки мер, направленных на снижение уровня риска",
        ],
      },
      {
        code: "20115132",
        text: "В соответствии с чем должны осуществляться контроль и регулирование технологических процессов? Укажите все правильные ответы.",
        answers: [
          "В соответствии с технологическими регламентами",
          "В соответствии с Основными требованиями безопасности для объектов производств боеприпасов и спецхимии",
          "В соответствии с проектной, конструкторской документацией",
          "В соответствии с предписаниями инспектора Ростехнадзора",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами",
          "В соответствии с проектной, конструкторской документацией",
        ],
      },
      {
        code: "20115133",
        text: "Из какого материала допускается применять инструмент для всех работ с СТРТ?",
        answers: [
          "Только из текстолита",
          "Только из фторопласта",
          "Из всех перечисленных материалов",
          "Только из резины",
        ],
        correctAnswers: ["Из всех перечисленных материалов"],
      },
      {
        code: "20115134",
        text: "Какая относительная влажность воздуха должна поддерживаться в помещениях, где технологический процесс связан с пересыпанием или перетеканием электризующихся взрывопожароопасных веществ?",
        answers: [
          "55 %",
          "75 %",
          "Установленная техническими условиями на эти вещества и регламентом технологического процесса",
          "50 %",
        ],
        correctAnswers: [
          "Установленная техническими условиями на эти вещества и регламентом технологического процесса",
        ],
      },
      {
        code: "20115135",
        text: "В соответствии с чем должны проверяться змеевики нитраторов?",
        answers: [
          "Только в соответствии с графиками технического осмотра оборудования",
          "В соответствии со всем перечисленным",
          "Только в соответствии с инструкциями",
          "Только в соответствии с графиками планово-предупредительного ремонта",
        ],
        correctAnswers: ["В соответствии со всем перечисленным"],
      },
      {
        code: "20115136",
        text: "В каких местах не допускается размещать фланцевые соединения трубопроводов с взрывопожароопасными, токсичными и едкими веществами?",
        answers: [
          "Над местами, предназначенными для прохода людей и рабочими площадками",
          "Над автодорогами",
          "На трубопроводах, идущих по стенам зданий",
          "На трубопроводах, проложенных по эстакадам",
          "На трубопроводах, проложенных в приямках",
        ],
        correctAnswers: [
          "Над местами, предназначенными для прохода людей и рабочими площадками",
        ],
      },
      {
        code: "20115137",
        text: "В каких случаях на трубопроводах следует применять арматуру под приварку?",
        answers: [
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков любой категории взрывоопасности",
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении",
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I и II категорий взрывоопасности и температурой, равной температуре кипения при регламентированном давлении",
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа",
        ],
        correctAnswers: [
          "При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении",
        ],
      },
      {
        code: "20115138",
        text: "Кто устанавливает назначенный срок службы для технологического оборудования, машин и трубопроводной арматуры?",
        answers: [
          "Организация-изготовитель",
          "Орган по сертификации",
          "Орган по сертификации на основании заключения испытательной лаборатории",
          "Разработчик документации",
          "Ростехнадзор",
        ],
        correctAnswers: ["Организация-изготовитель"],
      },
      {
        code: "20115139",
        text: "Кто устанавливает назначенный срок службы для технологических трубопроводов?",
        answers: [
          "Организация-изготовитель",
          "Орган по сертификации",
          "Орган по сертификации на основании заключения испытательной лаборатории",
          "Разработчик документации",
          "Ростехнадзор",
        ],
        correctAnswers: ["Разработчик документации"],
      },
      {
        code: "20115140",
        text: "В каких нормативных правовых актах установлены требования к качеству изготовления технологического оборудования, машин, трубопроводов и трубопроводной арматуры?",
        answers: [
          "В правилах устройства и безопасной эксплуатации соответствующих видов оборудования",
          "В технических регламентах",
          "В паспортах оборудования, машин, трубопроводов и трубопроводной арматуры",
          "В ГОСТах",
          "В сертификатах",
        ],
        correctAnswers: ["В технических регламентах"],
      },
      {
        code: "20115141",
        text: "Какой объем неразрушающего контроля сварных соединений технологических трубопроводов, транспортирующих токсичные и высокотоксичные вещества, предусмотрен в Правилах?",
        answers: [
          "Не менее 50 % длины сварного шва каждого сварного соединения",
          "Не менее 75 % длины сварного шва каждого сварного соединения",
          "Не менее 100 % длины сварного шва каждого сварного соединения",
          "Определяется специалистами неразрушающего контроля",
        ],
        correctAnswers: [
          "Не менее 100 % длины сварного шва каждого сварного соединения",
        ],
      },
      {
        code: "20115142",
        text: "Что необходимо предусматривать в химико-технологических системах для эффективного проведения периодических работ по очистке оборудования?",
        answers: [
          "Наличие средств гидравлической, механической или химической очистки",
          "Наличие оросительных систем",
          "Наличие специального персонала для очистки оборудования, имеющего необходимые допуски",
          "Возможность изоляции соседнего оборудования",
        ],
        correctAnswers: [
          "Наличие средств гидравлической, механической или химической очистки",
        ],
      },
      {
        code: "20115143",
        text: "Что необходимо учитывать при размещении технологического оборудования в помещениях и на открытых площадках?",
        answers: [
          "Возможность быстрого демонтажа оборудования",
          "Возможность проведения ремонтных работ, визуального контроля и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий",
          "Соблюдение расстояний, предусмотренных требованиями пожарной безопасности",
          "Минимизацию контакта с коррозионно-активными веществами",
        ],
        correctAnswers: [
          "Возможность проведения ремонтных работ, визуального контроля и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий",
        ],
      },
      {
        code: "20115144",
        text: "Какие дополнительные требования установлены при использовании технологического оборудования и трубопроводов, в которых обращаются коррозионно-активные вещества?",
        answers: [
          "Технологическое оборудование и трубопроводы должны быть защищены металлическими коррозионно-стойкими покрытиями",
          "Запрещается для защиты технологического оборудования использовать неметаллические покрытия",
          "Контроль за технологическим оборудованием и трубопроводами, контактирующими с коррозионно-активными веществами, должен осуществляться не реже чем 1 раз в месяц",
          "Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии",
        ],
        correctAnswers: [
          "Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии",
        ],
      },
      {
        code: "20115145",
        text: "Чем определяется количество насосов и компрессоров, используемых для перемещения химически опасных веществ в технологическом процессе?",
        answers: [
          "Критическими параметрами технологического процесса",
          "Необходимостью и условиями обеспечения непрерывности технологического процесса",
          "Физико-химическими свойствами перемещаемых продуктов",
          "Техническими характеристиками применяемых насосов и компрессоров",
        ],
        correctAnswers: [
          "Необходимостью и условиями обеспечения непрерывности технологического процесса",
        ],
      },
      {
        code: "20115146",
        text: "Какие условия должны выполняться для допуска к эксплуатации компрессорных установок?",
        answers: [
          "Получение разрешения на применение технических устройств, выдаваемого органами Ростехнадзора",
          "Наличие положительного заключения экспертизы промышленной безопасности",
          'Наличие и исправное состояние средств автоматизации, контроля и системы блокировок, указанных в технической документации организации-изготовителя и предусмотренных конструкцией установки в соответствии с требованиями Технического регламента "О безопасности машин и оборудования"',
          "Все вышеперечисленное",
        ],
        correctAnswers: [
          'Наличие и исправное состояние средств автоматизации, контроля и системы блокировок, указанных в технической документации организации-изготовителя и предусмотренных конструкцией установки в соответствии с требованиями Технического регламента "О безопасности машин и оборудования"',
        ],
      },
      {
        code: "20115147",
        text: "Что в химико-технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и их развития?",
        answers: [
          "Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы",
          "Запорная арматура, средства защиты от превышения давления, огнепреградители",
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления",
          "Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов",
          "Расходомеры и фильтры",
        ],
        correctAnswers: [
          "Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления",
        ],
      },
      {
        code: "20115148",
        text: "Какое минимальное количество датчиков должно устанавливаться на химически опасных производственных объектах I и II классов опасности для осуществления контроля за текущими показателями параметров, определяющими химическую опасность процессов?",
        answers: [
          "Не менее 2 независимых датчиков с раздельными точками отбора",
          "Правилами не регламентируется",
          "Не менее 3 независимых датчиков с раздельными точками отбора",
          "Не менее 5 независимых датчиков с раздельными точками отбора",
        ],
        correctAnswers: [
          "Не менее 2 независимых датчиков с раздельными точками отбора",
        ],
      },
      {
        code: "20115149",
        text: "Какое время срабатывания системы защиты установлено в федеральных нормах и правилах?",
        answers: [
          "Не более 12 секунд",
          "Не более 120 секунд",
          "Время срабатывания системы защиты должно быть таким, чтобы исключалось опасное развитие возможной аварии",
          "Не более 60 секунд",
        ],
        correctAnswers: [
          "Время срабатывания системы защиты должно быть таким, чтобы исключалось опасное развитие возможной аварии",
        ],
      },
      {
        code: "20115150",
        text: "Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания противоаварийной защиты?",
        answers: [
          "Выполняется средствами автоматического управления объектом по действующим программам",
          "Выполняется обслуживающим персоналом по инструкции",
          "Выполняется средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений",
          "Выполняется средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию противоаварийной защиты",
        ],
        correctAnswers: ["Выполняется обслуживающим персоналом по инструкции"],
      },
      {
        code: "20115151",
        text: "По какой категории надежности должно осуществляться электроснабжение химически опасных производственных объектов?",
        answers: [
          "Электроснабжение химически опасных производственных объектов должно осуществляться по I или II категории надежности",
          "Электроснабжение химически опасных производственных объектов должно осуществляться только по I категории надежности",
          "Электроснабжение химически опасных производственных объектов должно осуществляться по II или III категории надежности",
        ],
        correctAnswers: [
          "Электроснабжение химически опасных производственных объектов должно осуществляться по I или II категории надежности",
        ],
      },
      {
        code: "20115152",
        text: "Что должно быть учтено в системах управления и защит электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?",
        answers: [
          "Должен быть обеспечен автоматический ввод резерва между каждым из трех самостоятельных источников электроснабжения",
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
          "Должна быть обеспечена селективность защит на устройствах электроснабжающей и электропотребляющей организаций",
          "Должна быть обеспечена возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой",
        ],
        correctAnswers: [
          "Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки",
        ],
      },
      {
        code: "20115153",
        text: 'Что такое "назначенный ресурс" технического устройства опасного производственного объекта, после которого может осуществляться ликвидация технического устройства?',
        answers: [
          "Продолжительность эксплуатации технического устройства, при котором его дальнейшая эксплуатация недопустима или нецелесообразна",
          "Наличие остаточных деформаций металлоконструкции технического устройства, при котором его дальнейшая эксплуатация недопустима",
          "Наличие трещин металлоконструкции технического устройства, при котором его дальнейшая эксплуатация недопустима",
          "Суммарная наработка, при достижении которой эксплуатация технического устройства должна быть прекращена независимо от его технического состояния",
          "Продолжительность эксплуатации в машино-часах или циклах, при достижении которой необходимо принять решение по его ликвидации",
        ],
        correctAnswers: [
          "Суммарная наработка, при достижении которой эксплуатация технического устройства должна быть прекращена независимо от его технического состояния",
        ],
      },
      {
        code: "20115154",
        text: 'Что такое "назначенный срок службы" технического устройства опасного производственного объекта, после которого может осуществляться ликвидация технического устройства?',
        answers: [
          "Календарная продолжительность эксплуатации технического устройства, при достижении которой эксплуатация должна быть прекращена независимо от его технического состояния",
          "Продолжительность эксплуатации технического устройства, при котором его дальнейшая эксплуатация недопустима или нецелесообразна",
          "Суммарная наработка, при достижении которой эксплуатация технического устройства должна быть прекращена независимо от его технического состояния",
          "Наличие трещин металлоконструкции технического устройства, при котором его дальнейшая эксплуатация недопустима",
          "Продолжительность эксплуатации в машино-часах или циклах, при достижении которой необходимо принять решение по его ликвидации",
        ],
        correctAnswers: [
          "Календарная продолжительность эксплуатации технического устройства, при достижении которой эксплуатация должна быть прекращена независимо от его технического состояния",
        ],
      },
      {
        code: "20115155",
        text: 'Что такое "предельное состояние" технического устройства?',
        answers: [
          "Продолжительность эксплуатации технического устройства, при котором его дальнейшая эксплуатация недопустима или нецелесообразна",
          "Состояние машины и (или) оборудования, при котором их дальнейшая эксплуатация недопустима или нецелесообразна либо восстановление их работоспособного состояния невозможно или нецелесообразно",
          "Наличие остаточных деформаций металлоконструкции технического устройства, при котором его дальнейшая эксплуатация недопустима",
          "Наличие трещин металлоконструкции технического устройства, при котором его дальнейшая эксплуатация недопустима",
          "Состояние машины и (или) оборудования, при котором их дальнейшая эксплуатация недопустима и их следует отправить в ремонт или в утилизацию (ликвидацию)",
        ],
        correctAnswers: [
          "Состояние машины и (или) оборудования, при котором их дальнейшая эксплуатация недопустима или нецелесообразна либо восстановление их работоспособного состояния невозможно или нецелесообразно",
        ],
      },
      {
        code: "20115156",
        text: "Каким из перечисленных ниже методов нельзя оценивать риск технического устройства на этапе его проектирования для идентифицированных видов опасностей?",
        answers: [
          "По нормативам, действующим в аналогичных отраслях промышленности",
          "Экспериментальным",
          "Экспертным",
          "По данным эксплуатации аналогичных машин и (или) оборудования",
          "Расчетным",
        ],
        correctAnswers: [
          "По нормативам, действующим в аналогичных отраслях промышленности",
        ],
      },
      {
        code: "20115157",
        text: "Какие из перечисленных ниже сведений не включают в руководство (инструкцию) по эксплуатации технического устройства?",
        answers: [
          "Сведения о квалификации обслуживающего персонала и удостоверениях, подтверждающих его квалификацию",
          "Указания по монтажу или сборке, наладке или регулировке, техническому обслуживанию и ремонту машины и (или) оборудования",
          "Указания по выводу из эксплуатации и утилизации",
          "Сведения о конструкции, принципе действия, характеристиках (свойствах) машин и/или оборудования",
          "Назначенные показатели (назначенный срок хранения, назначенный срок службы и (или) назначенный ресурс)",
        ],
        correctAnswers: [
          "Сведения о квалификации обслуживающего персонала и удостоверениях, подтверждающих его квалификацию",
        ],
      },
      {
        code: "20115158",
        text: "В каких случаях проводятся испытания технического устройства во время его изготовления?",
        answers: [
          "Если это предусмотрено Федеральными нормами и правилами по промышленной безопасности, распространяющимися на данный тип технических устройств",
          "Если на этом настаивает будущий владелец технического устройства",
          "Если монтаж технического устройства будет выполняться на месте его последующей эксплуатации",
          "Если это предусмотрено проектной (конструкторской) документацией",
          "Если изготовление технического устройства осуществляется за рубежом",
        ],
        correctAnswers: [
          "Если это предусмотрено проектной (конструкторской) документацией",
        ],
      },
      {
        code: "20115159",
        text: "Может ли руководство (инструкция) по эксплуатации технического устройства представляться его изготовителем на электронном носителе?",
        answers: [
          "Может, если техническое устройство не бытового назначения",
          "Не может",
          "Может, если это предусмотрено Федеральными нормами и правилами по промышленной безопасности, распространяющимися на данный тип технических устройств",
          "Не может, если на этом настаивает будущий владелец технического устройства",
          "Может, если комплект поставки технического устройства включает ее наличие и на бумажном носителе",
        ],
        correctAnswers: [
          "Может, если техническое устройство не бытового назначения",
        ],
      },
      {
        code: "20115160",
        text: "Какие из перечисленных ниже технических устройств подлежат оценке соответствия согласно требованиям ТР ТС 010/2011?",
        answers: [
          "Изготовленные для собственных нужд их изготовителей",
          "Комплектующие изделия к техническим устройствам",
          "Бывшие ранее в эксплуатации",
          "Технические устройства, выпускаемые в обращение на единой таможенной территории Таможенного союза",
          "Запасные части к техническим устройствам, используемые для ремонта (технического обслуживания)",
        ],
        correctAnswers: [
          "Технические устройства, выпускаемые в обращение на единой таможенной территории Таможенного союза",
        ],
      },
      {
        code: "20115161",
        text: "Что является единственным документом, подтверждающим соответствие технического устройства требованиям ТР ТС 010/2011?",
        answers: [
          "Декларация о соответствии",
          "Сертификат соответствия",
          "Сертификат или декларация о соответствии",
          "Заключение экспертизы промышленной безопасности",
          "Сертификат или декларация о соответствии и заключение экспертизы промышленной безопасности",
        ],
        correctAnswers: ["Сертификат или декларация о соответствии"],
      },
      {
        code: "20115162",
        text: "Какая из схем декларирования соответствия применяется для новых технических устройств, применяемых на опасном производственном объекте?",
        answers: ["5Д", "4Д", "3Д", "2Д", "1Д"],
        correctAnswers: ["5Д"],
      },
      {
        code: "20115163",
        text: "Каков срок действия декларации о соответствии требованиям ТС 010/2011 технического устройства, применяемого на опасном производственном объекте?",
        answers: [
          "2 года",
          "4 года",
          "Не более 3 лет",
          "Не более 5 лет",
          "В течение срока службы технического устройства",
        ],
        correctAnswers: ["Не более 5 лет"],
      },
      {
        code: "20115164",
        text: "Каков срок действия сертификата соответствия выпущенной партии технических устройств требованиям ТС 010/2011?",
        answers: [
          "Не устанавливается",
          "3 года",
          "4 года",
          "5 лет",
          "В течение срока службы технического устройства",
        ],
        correctAnswers: ["Не устанавливается"],
      },
      {
        code: "20115165",
        text: "Какому из приведенных ниже требований может не соответствовать орган аварийной остановки технического устройства?",
        answers: [
          "Останавливать техническое устройство быстро, не создавая опасности",
          "Быть ясно идентифицируемым и легко доступным",
          "Сопровождать остановку технического устройства включением звукового или светового сигнала",
          "Возвращаться в исходное положение, не приводя к пуску технического устройства",
          "Быть красного цвета, отличаться формой и размерами от других органов управления",
        ],
        correctAnswers: [
          "Сопровождать остановку технического устройства включением звукового или светового сигнала",
        ],
      },
      {
        code: "20115166",
        text: "В каких случаях на техническом устройстве должен включаться предупреждающий сигнал?",
        answers: [
          "В случаях, изложенных в Федеральных нормах и правилах по промышленной безопасности",
          "При каждом новом пуске технического устройства в работу",
          "В случаях, изложенных в руководстве по эксплуатации технического устройства",
          "При каждом новом пуске технического устройства в работу и его остановке",
          "В случаях, изложенных в проекте производства работ, в котором используется техническое устройство",
        ],
        correctAnswers: [
          "При каждом новом пуске технического устройства в работу",
        ],
      },
      {
        code: "20115167",
        text: "К каким последствиям не должно приводить временное отключение источника энергоснабжения технического устройства?",
        answers: [
          "Невыполнению уже выданной команды на остановку",
          "Падению и выбрасыванию подвижных частей технического устройства и закрепленных на нем предметов, заготовок, инструмента",
          "Снижению эффективности защитных устройств",
          "Самопроизвольному пуску технического устройства при восстановлении энергоснабжения",
          "Невыполнению хотя бы одной из перечисленных функций",
        ],
        correctAnswers: ["Невыполнению хотя бы одной из перечисленных функций"],
      },
      {
        code: "20115168",
        text: "Каким образом должны крепиться неподвижные защитные ограждения согласно требованиям ТР ТС 010/2011?",
        answers: [
          "Чтобы доступ в ограждаемую зону был возможен по мере необходимости",
          "Чтобы доступ в ограждаемую зону был возможен только с использованием инструментов",
          "Чтобы доступ в ограждаемую зону был возможен только после отключения блокировок",
          "Чтобы доступ в ограждаемую зону был возможен только после отключения энергоснабжения",
          "Чтобы доступ в ограждаемую зону был возможен только персоналу, имеющему оформленный допуск",
        ],
        correctAnswers: [
          "Чтобы доступ в ограждаемую зону был возможен только с использованием инструментов",
        ],
      },
      {
        code: "20115169",
        text: 'На какое оборудование распространяется ТР ТС 012/2011 "О безопасности оборудования для работы во взрывоопасных средах"?',
        answers: [
          "На оборудование, при эксплуатации которого опасность взрыва возникает только из-за наличия взрывоопасных веществ и нестойких химических соединений",
          "На оборудование для бытового и непроизводственного применения в условиях, когда взрывоопасная среда образуется вследствие непредвиденной утечки горючего газа",
          "Только на электрическое (электрооборудование), включая Ex-компонентные",
          "На электрическое (электрооборудование), включая Ex-компоненты, и неэлектрическое оборудование для работы во взрывоопасных средах",
        ],
        correctAnswers: [
          "На электрическое (электрооборудование), включая Ex-компоненты, и неэлектрическое оборудование для работы во взрывоопасных средах",
        ],
      },
      {
        code: "20115170",
        text: "Что является идентификационным признаком оборудования для работы во взрывоопасных средах?",
        answers: [
          "Только наличие маркировки взрывозащиты",
          "Только наличие сертификата взрывозащиты, выданного аккредитованным органом по сертификации ",
          "Наличие средств обеспечения взрывозащиты, указанных в технической документации изготовителя, и маркировки взрывозащиты, нанесенной на оборудование",
          "Только наличие клейма изготовителя",
        ],
        correctAnswers: [
          "Наличие средств обеспечения взрывозащиты, указанных в технической документации изготовителя, и маркировки взрывозащиты, нанесенной на оборудование",
        ],
      },
      {
        code: "20115171",
        text: 'Какое определение соответствует термину "аварийный режим" в соответствии с ТР ТС 012/2011 "О безопасности оборудования для работы во взрывоопасных средах"?',
        answers: [
          "Режим, при котором характеристики оборудования для работы во взрывоопасных средах выходят за пределы ограничений, указанных изготовителем в технической документации",
          "Режим, при котором отклонение от установленных характеристик оборудования может привести к развитию аварии",
          "Разрушение оборудования, сопровождающееся выбросом опасных веществ и отключением электроэнергии",
          "Возникновение риска воспламенения окружающей взрывоопасной среды, связанного с возможностью причинения вреда и (или) нанесения ущерба",
        ],
        correctAnswers: [
          "Режим, при котором характеристики оборудования для работы во взрывоопасных средах выходят за пределы ограничений, указанных изготовителем в технической документации",
        ],
      },
      {
        code: "20115172",
        text: 'Какое определение соответствует термину "взрывоопасная зона" в соответствии с ТР ТС 012/2011 "О безопасности оборудования для работы во взрывоопасных средах"?',
        answers: [
          "Зона, в которой произошло разрушение оборудования, сопровождающееся выбросом опасных веществ и отключением электроэнергии",
          "Зона с высоким риском воспламенения окружающей взрывоопасной среды, связанным с возможностью причинения вреда и (или) нанесения ущерба",
          "Замкнутое пространство, в котором образовалась взрывоопасная среда и требуется незамедлительное применение средств взрывозащиты",
          "Часть замкнутого или открытого пространства, в которой присутствует или может образоваться взрывоопасная среда в объеме, требующем специальных мер защиты при конструировании, изготовлении, монтаже и эксплуатации оборудования",
        ],
        correctAnswers: [
          "Часть замкнутого или открытого пространства, в которой присутствует или может образоваться взрывоопасная среда в объеме, требующем специальных мер защиты при конструировании, изготовлении, монтаже и эксплуатации оборудования",
        ],
      },
      {
        code: "20115173",
        text: 'Какое определение соответствует термину "Ex-компонент" в соответствии с ТР ТС 012/2011 "О безопасности оборудования для работы во взрывоопасных средах"?',
        answers: [
          "Взрывозащищенное техническое устройство, необходимое для безопасного функционирования оборудования во взрывоопасных средах, предназначенное для самостоятельного применения",
          "Устанавливаемое на оборудование взрывозащищенное техническое устройство, необходимое для безопасного функционирования оборудования во взрывоопасных средах, но не предназначенное для самостоятельного применения",
          "Любое техническое устройство, которое допускается для применения во взрывоопасных средах и имеет соответствующую маркировку",
        ],
        correctAnswers: [
          "Устанавливаемое на оборудование взрывозащищенное техническое устройство, необходимое для безопасного функционирования оборудования во взрывоопасных средах, но не предназначенное для самостоятельного применения",
        ],
      },
      {
        code: "20115174",
        text: 'Какой вид классификации оборудования для работы во взрывоопасных средах не устанавливает ТР ТС 012/2011 "О безопасности оборудования для работы во взрывоопасных средах"?',
        answers: [
          "Классификация оборудования по давлению взрывоопасной среды",
          "Классификация взрывоопасных зон",
          "Классификация оборудования по группам (в зависимости от области применения)",
          "Классификация оборудования по уровням и видам взрывозащиты",
        ],
        correctAnswers: [
          "Классификация оборудования по давлению взрывоопасной среды",
        ],
      },
      {
        code: "20115175",
        text: "При каких режимах работы должна обеспечиваться взрывобезопасность оборудования?",
        answers: [
          "Только при соблюдении условий, установленных в технической документации изготовителя",
          "Только в нормальных режимах работы",
          "В нормальных режимах работы и в пределах отклонений, установленных технической документацией изготовителя",
          "Взрывобезопасность должна быть обеспечена независимо от режимов работы",
        ],
        correctAnswers: [
          "В нормальных режимах работы и в пределах отклонений, установленных технической документацией изготовителя",
        ],
      },
      {
        code: "20115176",
        text: "Выполнение каких требований безопасности к разработке и изготовлению оборудования для работы во взрывоопасных средах не предусмотрено в ТР ТС 012/2011?",
        answers: [
          "Применение оборудования в нормальных условиях, избежание внешних воздействий, вибрации, загрязнений, грозовых и коммутационных перенапряжений и др.",
          "Оборудование должно обеспечивать взрывобезопасность при эксплуатации в течение всего предполагаемого (расчетного) срока службы",
          "Оборудование должно функционировать в фактических или прогнозируемых условиях окружающей среды",
          "Оборудование должно сохранять взрывобезопасность в изменяющихся условиях окружающей среды и при наличии внешних воздействий (влажность, вибрация, загрязнения, грозовые и коммутационные перенапряжения и др.) с учетом ограничений рабочих условий, установленных изготовителем",
        ],
        correctAnswers: [
          "Применение оборудования в нормальных условиях, избежание внешних воздействий, вибрации, загрязнений, грозовых и коммутационных перенапряжений и др.",
        ],
      },
      {
        code: "20115177",
        text: 'Какой должна быть температура поверхности оборудования и (или) его частей с уровнем взрывозащиты "особовзрывобезопасный" ("очень высокий") и "взрывобезопасный" ("высокий")?',
        answers: [
          "Температура определяется в технической документации с учетом характера принимаемых изготовителем мер по защите указанного оборудования",
          "Температура должна быть ниже температуры самовоспламенения окружающей взрывоопасной газовой среды и температуры самовоспламенения слоя пыли при эксплуатации",
          "Температура не должна быть выше максимальной температуры поверхности в нормальном режиме эксплуатации",
        ],
        correctAnswers: [
          "Температура должна быть ниже температуры самовоспламенения окружающей взрывоопасной газовой среды и температуры самовоспламенения слоя пыли при эксплуатации",
        ],
      },
      {
        code: "20115178",
        text: "Техническая документация, которая прилагается при поставке оборудования потребителю, не включает:",
        answers: [
          "Требования к персоналу",
          "Перечень критических отказов, возможных ошибок персонала (пользователя), приводящих к аварийным режимам оборудования, и действий, предотвращающих указанные ошибки",
          "Назначенные показатели срока службы и (или) назначенный ресурс",
          "Обоснование безопасности оборудования, предназначенного для работы во взрывоопасных средах",
        ],
        correctAnswers: [
          "Обоснование безопасности оборудования, предназначенного для работы во взрывоопасных средах",
        ],
      },
      {
        code: "20115179",
        text: "В какой форме осуществляется подтверждение соответствия оборудования требованиям технического регламента ТР ТС 012/2011?",
        answers: [
          "Обязательной сертификацией",
          "Обязательной сертификацией или декларированием соответствия",
          "Обязательной или добровольной сертификацией",
          "Экспертизой промышленной безопасности",
          "Промышленными испытаниями",
        ],
        correctAnswers: ["Обязательной сертификацией"],
      },
      {
        code: "20115180",
        text: "При соблюдении какого условия оборудование, работающее под избыточным давлением, выпускается в обращение на рынок?",
        answers: [
          "Оборудование должно иметь соответствующее разрешение на его обращение на рынке, выданное в порядке, предусмотренном Евразийской экономической комиссией",
          "Оборудование должно иметь документ о прохождении оценки (подтверждения) соответствия требованиям ТР ТС 032/2013",
          "Оборудование должно иметь разрешение на его применение, выданное Федеральной службой по экологическому, технологическому и атомному надзору",
          "Оборудование должно иметь документ о подтверждении соответствия требованиям государственных и международных стандартов, выданный в порядке, предусмотренном Росстандартом",
        ],
        correctAnswers: [
          "Оборудование должно иметь документ о прохождении оценки (подтверждения) соответствия требованиям ТР ТС 032/2013",
        ],
      },
      {
        code: "20115181",
        text: "Что из приведенного не подлежит обязательному включению в состав технической документации, прилагаемой к поставляемому и подлежащему установке оборудованию, работающему под избыточным давлением?",
        answers: [
          "Паспорта предохранительных устройств",
          "Паспорт сосуда",
          "Расчет на прочность оборудования",
          "Руководство (инструкция) по эксплуатации",
          "Чертежи, схемы, расчеты",
        ],
        correctAnswers: ["Паспорт сосуда"],
      },
      {
        code: "20115182",
        text: "Какой документ является основным для идентификации оборудования, работающего под избыточным давлением?",
        answers: [
          "Сертификат соответствия оборудования требованиям ТР ТС 032/2013",
          "Обоснование безопасности оборудования",
          "Паспорт оборудования",
          "Декларация соответствия оборудования требованиям промышленной безопасности",
        ],
        correctAnswers: ["Паспорт оборудования"],
      },
      {
        code: "20115183",
        text: "В каком документе даются указания по выводу из эксплуатации и утилизации оборудования, работающего под давлением?",
        answers: [
          "Руководстве (инструкции) по эксплуатации",
          "Заключении по результатам технического диагностирования оборудования",
          "Типовой инструкции по утилизации оборудования, работающего под давлением, используемого на опасном производственном объекте",
          "Документации эксплуатирующей организации, прошедшей экспертизу промышленной безопасности",
        ],
        correctAnswers: ["Руководстве (инструкции) по эксплуатации"],
      },
      {
        code: "20115184",
        text: "Какая информация из приведенной не включается в маркировку, наносимую на оборудование, работающее под давлением?",
        answers: [
          "Наименование и (или) обозначение оборудования",
          "Сведения о подтверждении соответствия требованиям ТР ТС 032/2013",
          "Параметры и характеристики, влияющие на безопасность",
          "Наименование материала, из которого изготовлено оборудование",
          "Вся приведенная информация включается в маркировку, наносимую на оборудование",
        ],
        correctAnswers: [
          "Сведения о подтверждении соответствия требованиям ТР ТС 032/2013",
        ],
      },
      {
        code: "20115185",
        text: "В какой из приведенных форм не проводится оценка соответствия оборудования, работающего под давлением, на которое распространяется действие ТР ТС 032/2013?",
        answers: [
          "Экспертизы промышленной безопасности",
          "Сертификации",
          "Декларирования соответствия",
          "Государственного контроля (надзора)",
        ],
        correctAnswers: ["Экспертизы промышленной безопасности"],
      },
      {
        code: "20115186",
        text: "Какой документ о подтверждении соответствия требованиям ТР ТС 032/2013 должно иметь оборудование, отнесенное к 2 категории опасности, доизготовление которого с применением неразъемных соединений осуществляется по месту эксплуатации?",
        answers: [
          "Сертификат",
          "Заключение экспертизы промышленной безопасности",
          "Декларация соответствия",
          "Разрешение на применение сосуда",
        ],
        correctAnswers: ["Декларация соответствия"],
      },
      {
        code: "20115187",
        text: "Какой документ о подтверждении соответствия требованиям ТР ТС 032/2013 должно иметь оборудование, отнесенное к 3 категории опасности?",
        answers: [
          "Сертификат",
          "Декларация соответствия",
          "Заключение экспертизы промышленной безопасности",
          "Разрешение на применение сосуда",
        ],
        correctAnswers: ["Сертификат"],
      },
      {
        code: "20115188",
        text: "Если сосуд работает с газообразной средой группы 1, то в каком случае этот сосуд оборудуется трубопроводами, отводящими рабочую среду от предохранительных клапанов в атмосферу?",
        answers: [
          "Если плотность отводимых газов по отношению к воздуху не превышает 0,8 ",
          "Если рабочее давление в сосуде не превышает 1,6 МПа и при выводе в атмосферу обеспечивается безопасное рассеивание рабочей среды",
          "Если рабочее давление в сосуде не превышает 1,2 МПа и при выводе в атмосферу обеспечивается безопасное рассеивание рабочей среды",
          "Если при отводе рабочей среды обеспечиваются безопасность обслуживающего сосуд персонала и нормы пожарной безопасности",
        ],
        correctAnswers: [
          "Если плотность отводимых газов по отношению к воздуху не превышает 0,8 ",
        ],
      },
      {
        code: "20115189",
        text: "В каком случае запрещается объединять сбросы от предохранительных клапанов сосудов?",
        answers: [
          "Если сбросы содержат вещества, способные при смешивании образовывать взрывоопасные смеси или нестабильные соединения",
          "Если сбросы направляются в открытые системы для дальнейшей утилизации или в системы организованного сжигания",
          "Если по крайней мере один из сосудов работает под давлением среды, отнесенной к группе 1",
        ],
        correctAnswers: [
          "Если сбросы содержат вещества, способные при смешивании образовывать взрывоопасные смеси или нестабильные соединения",
        ],
      },
      {
        code: "20115190",
        text: "В каком случае мембранные предохранительные устройства устанавливаются на сосудах и трубопроводах перед рычажно-грузовым или пружинным предохранительным клапаном?",
        answers: [
          "Если предохранительные клапаны не могут надежно работать вследствие вредного воздействия среды или возможных утечек через закрытый клапан рабочих сред, отнесенных к 1 группе",
          "Если необходимо увеличение пропускной способности систем сброса давления рычажно-грузового (пружинного) предохранительного клапана",
          "Если необходимо исключить влияние колебаний противодавления со стороны сбросной системы на надежность срабатывания рычажно-грузового или пружинного предохранительного клапана",
          "Установка мембранных предохранительных устройств перед рычажно-грузовыми и пружинными предохранительными клапанами не допускается",
        ],
        correctAnswers: [
          "Если предохранительные клапаны не могут надежно работать вследствие вредного воздействия среды или возможных утечек через закрытый клапан рабочих сред, отнесенных к 1 группе",
        ],
      },
      {
        code: "20115191",
        text: "Чем должны оснащаться указатели уровня жидкости на оборудовании, давление которого превышает 4,5 МПа?",
        answers: [
          "Двумя дублирующими указателями уровня жидкости непрямого действия",
          "Двумя дополнительными сниженными дистанционными указателями уровня жидкости",
          "Двумя последовательно расположенными комплектами запорных арматур для отключения указателей уровня жидкости",
          "Дополнительными звуковыми, световыми и другими сигнализаторами и блокировками по предельным уровням жидкости",
          "Отсекающими клапанами",
        ],
        correctAnswers: [
          "Двумя последовательно расположенными комплектами запорных арматур для отключения указателей уровня жидкости",
        ],
      },
      {
        code: "20115192",
        text: "В каком случае мембранные предохранительные устройства устанавливаются на сосудах и трубопроводах параллельно с рычажно-грузовым или пружинным предохранительным клапаном?",
        answers: [
          "Если необходимо увеличение пропускной способности систем сброса давления рычажно-грузового (пружинного) предохранительного клапана",
          "Если предохранительные клапаны не могут надежно работать вследствие вредного воздействия среды или возможных утечек через закрытый клапан рабочих сред, отнесенных к 1 группе",
          "Если необходимо исключить влияние колебаний противодавления со стороны сбросной системы на надежность срабатывания рычажно-грузового или пружинного предохранительного клапана",
          "Установка мембранных предохранительных устройств параллельно с рычажно-грузовыми или пружинными предохранительными клапанами не допускается",
        ],
        correctAnswers: [
          "Если необходимо увеличение пропускной способности систем сброса давления рычажно-грузового (пружинного) предохранительного клапана",
        ],
      },
      {
        code: "20115193",
        text: "На какой максимальный процент допускается превышение давления в сосуде от максимально допустимого рабочего давления при работающих предохранительных клапанах при условии, что это превышение предусмотрено руководством (инструкцией) по эксплуатации сосуда?",
        answers: ["15 %", "25 %", "20 %", "35 %"],
        correctAnswers: ["25 %"],
      },
      {
        code: "20115194",
        text: "Чему должна быть равна ширина смотровой щели указателя уровня жидкости в сосудах?",
        answers: [
          "Определяется проектом оборудования",
          "20 мм",
          "4 мм",
          "8 мм",
        ],
        correctAnswers: ["Определяется проектом оборудования"],
      },
      {
        code: "20115195",
        text: "В каком случае допускается определение толщин стенок трубопроводов иным способом, отличным от метода неразрушающего контроля?",
        answers: [
          "Допускается в местах, где применение неразрушающего контроля затруднено или невозможно",
          "Допускается в присутствии инспектора Ростехнадзора",
          "Допускается в соответствии с документацией на ХОПО",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "Допускается в местах, где применение неразрушающего контроля затруднено или невозможно",
        ],
      },
      {
        code: "20115196",
        text: "В какой документации должны быть определены порядок контроля за степенью коррозионного износа оборудования и трубопроводов с использованием методов неразрушающего контроля, способы, периодичность и места проведения контрольных замеров?",
        answers: [
          "В справочной литературе",
          "В технологических регламентах",
          "В эксплуатационной документации организации-изготовителя",
          "В руководствах по безопасности",
          "В технологических картах",
        ],
        correctAnswers: [
          "В эксплуатационной документации организации-изготовителя",
        ],
      },
      {
        code: "20115197",
        text: "В каких местах могут быть допущены фланцевые соединения трубопроводов?",
        answers: [
          "В местах установки арматуры",
          "Во всех перечисленных местах",
          "На участках, где по условиям технологии требуется периодическая разборка для проведения чистки и ремонта трубопроводов",
          "Трубопроводы не должны иметь фланцевых соединений",
          "В местах подсоединения трубопроводов к аппаратам",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20115198",
        text: "Кто проводит испытания оборудования, предусмотренные проектной документацией?",
        answers: [
          "Изготовитель",
          "Проектировщик",
          "Предприятие-владелец",
          "Поставщик",
          "Экспертная организация",
        ],
        correctAnswers: ["Изготовитель"],
      },
      {
        code: "20115199",
        text: 'На какие виды оборудования не распространяется требования Технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          "Cосуды, работающие под давлением, создающимся при взрыве внутри них в соответствии с технологическим процессом или при горении в режиме самораспространяющегося высокотемпературного синтеза",
          "Котлы, имеющие вместимость более 0,002 м, предназначенные для получения пара, избыточное давление которого свыше 0,05 МПа",
          "Котлы, имеющие вместимость более 0,002 м, предназначенные для получения горячей воды, температура которой свыше 110 °С",
          "Сосуды, предназначенные для газов, сжиженных газов, растворенных под давлением, и паров, используемые для рабочих сред группы 1 и имеющие максимально допустимое рабочее давление свыше 20 МПа, вместимость свыше 0,0001 м до 0,001 м включительно",
          "Сосуды, предназначенные для жидкостей, используемые для рабочих сред группы 2 и имеющие максимально допустимое рабочее давление свыше 100 МПа, вместимость свыше 0,0001 м до 0,01 м включительно",
        ],
        correctAnswers: [
          "Cосуды, работающие под давлением, создающимся при взрыве внутри них в соответствии с технологическим процессом или при горении в режиме самораспространяющегося высокотемпературного синтеза",
        ],
      },
      {
        code: "20115200",
        text: "Что должна включать маркировка, нанесенная на оборудование, предназначенное для работы во взрывоопасных средах?",
        answers: [
          "Обозначение типа оборудования",
          "Номер сертификата соответствия",
          "Все перечисленное",
          "Заводской номер",
          "Наименование изготовителя или его зарегистрированный товарный знак",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20115201",
        text: "В течение какого времени должна сохраняться маркировка взрывозащиты, нанесенная на поверхность оборудования или табличку?",
        answers: [
          "В течение 20 лет",
          "В течение 10 лет",
          "В течение 5 лет",
          "В течение всего срока службы этого оборудования",
        ],
        correctAnswers: ["В течение всего срока службы этого оборудования"],
      },
      {
        code: "20115202",
        text: 'Что означает термин "температура рабочей среды" согласно Техническому регламенту Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          "Максимальная (минимальная) температура стенки сосуда, при которой допускается эксплуатация оборудования",
          "Температура, равная 20°С, используемая при расчете на прочность стандартных сосудов (узлов, деталей, арматуры)",
          "Минимальная (максимальная) температура среды при нормальном протекании технологического процесса",
          "Температура, при которой определяются физико-механические характеристики, допускаемое напряжение материала и проводится расчет на прочность элементов оборудования",
        ],
        correctAnswers: [
          "Минимальная (максимальная) температура среды при нормальном протекании технологического процесса",
        ],
      },
      {
        code: "20115203",
        text: 'Какой максимальный срок действия декларации о соответствии серийно выпускаемого оборудования требованиям Технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" со дня ее регистрации в Едином реестре выданных сертификатов соответствия и зарегистрированных деклараций о соответствии?',
        answers: ["4 года", "3 года", "5 лет", "1 год"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20115204",
        text: 'Какой срок действия сертификата соответствия оборудования требованиям Технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" при использовании схемы 1с?',
        answers: [
          "1 год",
          "В течение назначенного срока службы или назначенного ресурса",
          "5 лет",
          "3 года",
        ],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20115205",
        text: "Какие факторы, представляющие собой основные виды опасности, должны учитываться с целью определения рисков для оборудования?",
        answers: [
          "Коррозия или иные виды износа поверхности элементов оборудования, прекращение действия вспомогательного оборудования, погасание факелов в топке при камерном сжигании топлива",
          "Наличие незащищенных подвижных элементов, вибрация, наличие взрывопожароопасных элементов",
          "Недопустимые отклонения параметров конструкции, сборочных единиц и устройств безопасности, влияющие на безопасность",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20115206",
        text: 'Какие требования к обоснованию безопасности согласно Техническому регламенту Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" указаны неверно?',
        answers: [
          "Обоснование безопасности оборудования готовится на этапе разработки (проектирования) оборудования",
          "В обосновании безопасности приводятся анализ рисков для оборудования, а также минимально необходимые меры по обеспечению безопасности",
          "Обоснование безопасности содержит сведения о проведенных испытаниях (измерениях), сертификаты (декларации) соответствия, а также документы, подтверждающие квалификацию специалистов и персонала изготовителя",
          "Оригинал обоснования безопасности оборудования хранится у разработчика (проектировщика)",
        ],
        correctAnswers: [
          "Обоснование безопасности содержит сведения о проведенных испытаниях (измерениях), сертификаты (декларации) соответствия, а также документы, подтверждающие квалификацию специалистов и персонала изготовителя",
        ],
      },
      {
        code: "20115207",
        text: "Какое минимальное количество редуцирующих устройств с манометром и предохранительным клапаном допускается устанавливать на общем подводящем присоединительном трубопроводе для группы сосудов, работающих при одном и том же давлении?",
        answers: [
          "Три",
          "Два",
          "Определяется заказчиком проектной документации",
          "Одно",
        ],
        correctAnswers: ["Одно"],
      },
      {
        code: "20115208",
        text: "По каким уровням взрывозащиты не классифицируется оборудование в зависимости от опасности стать источником воспламенения и условий его применения во взрывоопасных средах?",
        answers: [
          "Повышенная надежность против взрыва (повышенный)",
          "Низковзрывоопасный (умеренный)",
          "Особовзрывобезопасный (очень высокий)",
          "Взрывобезопасный (высокий)",
        ],
        correctAnswers: ["Низковзрывоопасный (умеренный)"],
      },
      {
        code: "20115209",
        text: "Арматура с каким классом герметичности затвора должна устанавливаться на трубопроводах для транспортирования взрывопожароопасных продуктов?",
        answers: ["В", "СС", "Определяется проектной документацией", "АА", "А"],
        correctAnswers: ["Определяется проектной документацией"],
      },
      {
        code: "20115210",
        text: "Где допускается размещение фланцевых соединений на трубопроводах с пожаровзрывоопасными, токсичными и едкими веществами?",
        answers: [
          "Над дверными и оконными проемами в случае применения спирально-навитых прокладок",
          "В местах ввода в технологические здания и сооружения",
          "Над местами, предназначенными для проезда транспорта на высоте не менее 5 м",
          "В местах установки арматуры или подсоединения трубопроводов к аппаратам",
        ],
        correctAnswers: [
          "В местах установки арматуры или подсоединения трубопроводов к аппаратам",
        ],
      },
      {
        code: "20115211",
        text: "Для трубопроводов каких технологических блоков при подключении к коллектору в обоснованных случаях для повышения надежности предусматривается установка дублирующих отключающих устройств?",
        answers: [
          "Для технологических блоков I категории взрывоопасности",
          "Для технологических блоков II категории взрывоопасности",
          "Для технологических блоков III категории взрывоопасности",
        ],
        correctAnswers: [
          "Для технологических блоков I категории взрывоопасности",
        ],
      },
      {
        code: "20115212",
        text: "Что предусматривается во взрывоопасных помещениях и вне их перед входными дверями?",
        answers: [
          "Устройство световой и звуковой сигнализации загазованности воздушной среды",
          "Только устройство звуковой сигнализации",
          "Только устройство световой сигнализации",
          "Устройство информационного стенда о действиях персонала в аварийной ситуации",
          "Предупреждающие таблички и знаки",
        ],
        correctAnswers: [
          "Устройство световой и звуковой сигнализации загазованности воздушной среды",
        ],
      },
    ],

    20116: [
      {
        code: "20116000",
        text: "Какой устанавливается срок действия постоянного технологического регламента?",
        answers: [
          "Не более 20 лет",
          "Не более 3 лет",
          "Не более 5 лет",
          "Не более 10 лет",
        ],
        correctAnswers: ["Не более 10 лет"],
      },
      {
        code: "20116001",
        text: "Какое из обязательных требований к проектированию, техническому перевооружению или реконструкции действующих объектов производства растительных масел указано неверно?",
        answers: [
          "Установление категории помещений, зданий и наружных установок по взрывопожарной и пожарной опасности",
          "Осуществление оценки взрывоопасности технологических блоков",
          "Определение мест расположения насосного и компрессорного оборудования",
          "Установление вида системы защиты автоматическими установками тушения и обнаружения пожара в соответствии с нормами пожарной безопасности",
        ],
        correctAnswers: [
          "Определение мест расположения насосного и компрессорного оборудования",
        ],
      },
      {
        code: "20116002",
        text: "Какое количество копий технологических регламентов устанавливается требованиями Правил безопасности химически опасных производственных объектов?",
        answers: [
          "Не менее 10 копий­",
          "Не менее 15 копий",
          "Не менее 6 копий",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20116003",
        text: "Для каких из перечисленных производств разрабатываются постоянные технологические регламенты?",
        answers: [
          "Для действующих химико-технологических производств, в технологию которых внесены принципиальные изменения",
          "Для новых производств в данной организации",
          "Для производств с новой технологией",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20116004",
        text: "Чьими подписями оформляется лист подписей постоянного (временного, разового, лабораторного) технологического регламента? Укажите все правильные ответы.",
        answers: [
          "Начальника цеха",
          "Начальника центральной лаборатории организации",
          "Заместителя руководителя организации по охране окружающей среды",
          "Главного метролога организации",
          "Начальника производства",
        ],
        correctAnswers: ["Начальника цеха", "Начальника производства"],
      },
      {
        code: "20116005",
        text: 'Какое из перечисленных требований не соответствует разделу технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          "Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается",
          "Периодичность испытаний запорной регулирующей арматуры, исполнительных механизмов, периодичность проверок приборов и испытаний других технических средств, участвующих в схемах контроля, управления и противоаварийной защиты технологических процессов, должны быть взаимоувязаны",
          "Средства автоматики, используемые по плану мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, должны быть определены особо",
          "Допускается разделять в отдельные таблицы перечень систем сигнализации и блокировок и (или) перечень аналитического контроля",
        ],
        correctAnswers: [
          "Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается",
        ],
      },
      {
        code: "20116006",
        text: "Какой устанавливается срок действия разовых (опытных) технологических регламентов, в соответствии с которыми проводится наработка опытной продукции в течение нескольких лет?",
        answers: [
          "Не более 10 лет",
          "Не более 5 лет",
          "Не более 3 лет",
          "Не более 1 года",
        ],
        correctAnswers: ["Не более 5 лет"],
      },
      {
        code: "20116007",
        text: "Кто утверждает все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?",
        answers: [
          "Технологический руководитель организации, эксплуатирующей химико-технологическое производство",
          "Начальник отдела технического контроля организации, эксплуатирующей химико-технологическое производство",
          "Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство",
          "Председатель специально созданной комиссии организации, эксплуатирующей химико-технологическое производство",
        ],
        correctAnswers: [
          "Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство",
        ],
      },
      {
        code: "20116008",
        text: "Каким образом определяется срок действия временного технологического регламента при отсутствии установленных планами норм освоения производства?",
        answers: [
          "Лицом, утверждающим технологический регламент",
          "Приказом руководителя организации",
          "Технологическим руководителем организации",
          "В соответствии с проектной документацией",
        ],
        correctAnswers: ["Лицом, утверждающим технологический регламент"],
      },
      {
        code: "20116009",
        text: "Какой из перечисленных разделов не относится к постоянным, временным и разовым технологическим регламентам, связанным с необходимостью обеспечения промышленной безопасности технологических процессов?",
        answers: [
          "Характеристика производимой продукции",
          "Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств",
          "Технологические схемы производства",
          "Возможные инциденты в работе и способы их ликвидации",
        ],
        correctAnswers: [
          "Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств",
        ],
      },
      {
        code: "20116010",
        text: "С кем согласовывается применение в производстве растительных масел новых веществ (растворителей, абсорбентов, а также маслосодержащих материалов)?",
        answers: [
          "С научно-исследовательской организацией",
          "С организацией - производителем данных веществ",
          "С представителем территориального органа Ростехнадзора",
          "Со специализированной организацией, разрабатывающей нормативно-техническую документацию на процесс, в котором это вещество применяется",
        ],
        correctAnswers: [
          "Со специализированной организацией, разрабатывающей нормативно-техническую документацию на процесс, в котором это вещество применяется",
        ],
      },
      {
        code: "20116011",
        text: 'Информацию о каких организациях должен содержать раздел технологического регламента "Общая характеристика производства"?',
        answers: [
          "Только об организации, выполнившей функции генерального проектировщика",
          "Только об организации - разработчике технологического процесса",
          "Только об организации, выполнившей проектную документацию",
          "Только об организации - разработчике технологической части проектной документации",
          "Обо всех перечисленных организациях",
        ],
        correctAnswers: ["Обо всех перечисленных организациях"],
      },
      {
        code: "20116012",
        text: "Кем разрабатываются все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?",
        answers: [
          "Комиссией под председательством инспектора территориального органа Ростехнадзора",
          "Организацией - разработчиком процесса",
          "Организацией, эксплуатирующей химико-технологическое производство",
          "Специализированной сторонней организацией",
        ],
        correctAnswers: [
          "Организацией, эксплуатирующей химико-технологическое производство",
        ],
      },
      {
        code: "20116013",
        text: "В каком случае допускается наработка товарной продукции по лабораторным регламентам (пусковым запискам, производственным методикам)?",
        answers: [
          "Объемом до 1500 кг/год",
          "Объемом до 2000 кг/год",
          "Объемом до 1000 кг/год",
          "Объемом до 800 кг/год",
        ],
        correctAnswers: ["Объемом до 1000 кг/год"],
      },
      {
        code: "20116014",
        text: "На кого возлагается ответственность за полноту и качество разработки разделов технологического регламента производства продукции и контроль за обеспечением его исполнения?",
        answers: [
          "На технологическую службу организации, производства, отделения, установки",
          "На подрядную организацию разработчика процесса",
          "На комиссию организации, эксплуатирующей химико-технологическое производство",
          "На руководителя организации, эксплуатирующей химико-технологическое производство",
        ],
        correctAnswers: [
          "На технологическую службу организации, производства, отделения, установки",
        ],
      },
      {
        code: "20116015",
        text: "Какие типы технологических регламентов предусматриваются в зависимости от степени освоенности производств и целей осуществляемых работ?",
        answers: [
          "Постоянные, временные и разовые и лабораторные",
          "Периодически пересматриваемые",
          "Входящие в состав проектной документации или пусковые",
        ],
        correctAnswers: ["Постоянные, временные и разовые и лабораторные"],
      },
      {
        code: "20116016",
        text: 'Какие разновидности материального баланса допускается составлять в разделе технологического регламента "Материальный баланс"?',
        answers: [
          "Только на единицу времени (час)",
          "Только на один производственный поток",
          "Только на единицу выпускаемой продукции",
          "Все перечисленные",
          "Только на мощность производства в целом",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20116017",
        text: 'Как производится описание технологической схемы в разделе технологического регламента "Описание технологического процесса и схемы"?',
        answers: [
          "По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта",
          "По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование",
          "По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование и кончая отгрузкой готового продукта",
        ],
        correctAnswers: [
          "По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта",
        ],
      },
      {
        code: "20116018",
        text: "Какие технологические регламенты разрабатываются при выпуске товарной продукции на опытных и опытно-промышленных установках (цехах), а также для опытных и опытно-промышленных работ, проводимых на действующих производствах?",
        answers: [
          "Разовые (опытные) технологические регламенты",
          "Постоянные технологические регламенты",
          "Временные технологические регламенты",
          "Лабораторные технологические регламенты",
        ],
        correctAnswers: ["Разовые (опытные) технологические регламенты"],
      },
      {
        code: "20116019",
        text: 'Какой устанавливается срок действия "Накопительной ведомости"?',
        answers: ["6 месяцев", "12 месяцев", "9 месяцев", "3 месяца"],
        correctAnswers: ["6 месяцев"],
      },
      {
        code: "20116020",
        text: "Каким образом устанавливается и оформляется срок продления действия временного технологического регламента?",
        answers: [
          "Приказом главного инженера организации (технического директора, директора по производству)",
          "Приказом технологической службы организации, производства, отделения, установки",
          "Приказом комиссии организации",
          "Приказом руководителя организации",
        ],
        correctAnswers: ["Приказом руководителя организации"],
      },
      {
        code: "20116021",
        text: "В каком из перечисленных случаев должен быть составлен временный технологический регламент на новый срок? Укажите все правильные ответы.",
        answers: [
          "Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей",
          "Если такое решение принято технологической службой организации, производства, отделения, установки",
          "Если в тексте технологического регламента обнаружены подчистки и поправки",
          "Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса",
        ],
        correctAnswers: [
          "Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей",
          "Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса",
        ],
      },
      {
        code: "20116022",
        text: "Какие требования к условиям устойчивой работы объектов производств масел указаны неверно?",
        answers: [
          "Обеспечение функционирования надежной системы профилактического обслуживания технологического оборудования",
          "Проведение своевременного диагностирования технического состояния технологического оборудования",
          "Проведение своевременной диспансеризации технического персонала",
          "Обеспечение производства системами контроля и регулирования технологических параметров в заданном диапазоне",
        ],
        correctAnswers: [
          "Проведение своевременной диспансеризации технического персонала",
        ],
      },
      {
        code: "20116023",
        text: 'Какое из перечисленных требований соответствует нормам заполнения и хранения "Листа регистрации изменений и дополнений"?',
        answers: [
          "Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под подпись в подразделения, где находятся копии регламента",
          "Лист регистрации изменений и дополнений размещается в начале технологического регламента",
          "Регистрация изменений и дополнений выполняется главным инженером организации (техническим директором, директором по производству)",
          "Запись в регистрационном листе выполняется чернилами красного цвета",
        ],
        correctAnswers: [
          "Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под подпись в подразделения, где находятся копии регламента",
        ],
      },
      {
        code: "20116024",
        text: "Какой параметр является критерием установления категории взрывоопасности технологических блоков согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?",
        answers: [
          "Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему",
          "Класс опасности обращающихся в процессе веществ",
          "Температура самовоспламенения паров обращающихся в процессе веществ",
          "Скорость распространения горения обращающихся в процессе веществ",
        ],
        correctAnswers: [
          "Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему",
        ],
      },
      {
        code: "20116025",
        text: "Каким образом обеспечивается отработка персоналом практических навыков безопасного выполнения работ, предупреждения аварий и ликвидации их последствий на технологических объектах с блоками I и II категорий взрывоопасности?",
        answers: [
          "Посредством допуска персонала к самостоятельной работе не ранее чем через 6 месяцев после стажировки на объекте",
          "Посредством наличия собственных специализированных центров обучения и подготовки производственного персонала",
          "Посредством прохождения курса подготовки с использованием современных средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)",
          "Посредством наглядных пособий (плакатов) по охране труда",
        ],
        correctAnswers: [
          "Посредством прохождения курса подготовки с использованием современных средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)",
        ],
      },
      {
        code: "20116026",
        text: "Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?",
        answers: [
          "Системами ручного (без применения вычислительной техники) регулирования",
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
          "Средствами обеспечения питания инертными газами систем контрольно-измерительных приборов и автоматики",
        ],
        correctAnswers: [
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
        ],
      },
      {
        code: "20116027",
        text: "Какими устройствами не должны оснащаться насосы, применяемые для нагнетания легковоспламеняющихся и горючих жидкостей (масло растительное и минеральное, мисцелла, растворитель)?",
        answers: [
          "Устройствами, регулирующими частоту вращения вала",
          'Блокировками, исключающими пуск и работу насоса "всухую" или прекращающими работу насоса при падении давления перемещаемой жидкости в нагнетательном патрубке насоса ниже установленного регламентом или паспортными данными или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений (верхний и нижний уровни)',
          "Средствами предупредительной сигнализации о нарушении параметров работы, влияющих на безопасность",
          "Средствами местного и дистанционного отключения, расположенными в легкодоступных местах",
        ],
        correctAnswers: ["Устройствами, регулирующими частоту вращения вала"],
      },
      {
        code: "20116028",
        text: "Какая арматура должна применяться в экстракционном цехе объектов производств растительных масел?",
        answers: [
          "Стальная",
          "Чугунная",
          "Из неметаллических конструкционных материалов",
          "Не регламентируется",
        ],
        correctAnswers: ["Стальная"],
      },
      {
        code: "20116029",
        text: "Какими блокировками должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?",
        answers: [
          "Блокировками, исключающими пуск или прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях",
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе, достижении опасных значений в приемной емкости",
          "Блокировками, исключающими пуск или прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях",
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
        correctAnswers: [
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
      },
      {
        code: "20116030",
        text: "Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся жидкости и горючие жидкости на сливоналивных эстакадах?",
        answers: [
          "Должно быть организовано только управление по месту",
          "Должно быть организовано только управление дистанционно (из безопасного места)",
          "Должно быть организовано управление и по месту, и дистанционно (из безопасного места)",
        ],
        correctAnswers: [
          "Должно быть организовано управление и по месту, и дистанционно (из безопасного места)",
        ],
      },
      {
        code: "20116031",
        text: "Какие сведения являются основополагающими для выбора оборудования при разработке технологических процессов?",
        answers: [
          "Расчетные данные, которым должны соответствовать параметры оборудования, и показатели надежности",
          "Расчетные данные, которым должны соответствовать параметры оборудования, и требования действующих нормативных документов",
          "Требования действующих нормативных документов, расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование",
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
        correctAnswers: [
          "Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков",
        ],
      },
      {
        code: "20116032",
        text: "Как производителем должна подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств до начала их применения на опасном производственном объекте?",
        answers: [
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
          "Посредством оценки научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования",
          "Посредством опытных работ, проводимых разработчиком данного оборудования, и экспертизой промышленной безопасности",
          "Посредством получения заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизой промышленной безопасности",
        ],
        correctAnswers: [
          "Посредством испытаний промышленных образцов оборудования на взрывозащищенность",
        ],
      },
      {
        code: "20116033",
        text: "Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?",
        answers: [
          "Оборудование должно быть изолировано от действующей технологической системы, и нанесенное на нем обозначение номера по технологической документации должно быть на схеме закрашено",
          "Оборудование должно быть демонтировано, если оно расположено в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих технологических систем",
          "Оборудование должно быть демонтировано, если оно расположено в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно должно быть изолировано от действующих технологических систем",
          "Оборудование должно быть демонтировано",
        ],
        correctAnswers: [
          "Оборудование должно быть демонтировано, если оно расположено в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих технологических систем",
        ],
      },
      {
        code: "20116034",
        text: "На каких трубопроводах следует применять арматуру под приварку для повышения надежности и плотности соединений?",
        answers: [
          "На трубопроводах технологических блоков III категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды при регламентированном давлении",
          "На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды при регламентированном давлении",
          "На трубопроводах технологических блоков II категории взрывоопасности с температурой, равной температуре кипения среды при регламентированном давлении",
          "На трубопроводах технологических блоков I категории взрывоопасности с давлением среды менее 2,5 МПа",
        ],
        correctAnswers: [
          "На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды при регламентированном давлении",
        ],
      },
      {
        code: "20116035",
        text: "Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?",
        answers: [
          "Категорией взрывоопасности технологических блоков",
          "Радиусом зон разрушения в метрах",
          "Энергией сгорания парогазовой фазы в килоджоулях",
          "Приведенной массой вещества, участвующего во взрыве, в килограммах",
        ],
        correctAnswers: ["Категорией взрывоопасности технологических блоков"],
      },
      {
        code: "20116036",
        text: "Каким документом обосновывается достаточность мер по максимальному снижению уровня взрывоопасности технологических блоков, предотвращению взрывов и загораний внутри технологического оборудования, производственных помещений и наружных установок объектов, связанных с производством растительных масел?",
        answers: [
          "Проектной документацией",
          "Декларацией промышленной безопасности",
          "Обоснованием безопасности опасного производственного объекта",
          "Технологическим регламентом",
        ],
        correctAnswers: ["Проектной документацией"],
      },
      {
        code: "20116037",
        text: "Кто устанавливает значения параметров процесса и допустимых диапазонов их изменения, исключающих возможность возникновения опасных отклонений, способных стать причиной аварийной ситуации или аварии на объектах, связанных с производством растительных масел?",
        answers: [
          "Разработчик процесса",
          "Проектная организация",
          "Главный технолог",
          "Технический руководитель эксплуатирующей организации",
        ],
        correctAnswers: ["Разработчик процесса"],
      },
      {
        code: "20116038",
        text: "Каким образом определяется порядок срабатывания систем блокировок технологического оборудования и насосов объектов производств растительных масел? Укажите все правильные ответы.",
        answers: [
          "Правилами безопасности химически опасных производственных объектов",
          "Обслуживающим персоналом на основании инструкции по эксплуатации",
          "Схемой блокировок, представленной в технологическом регламенте и (или) проектной документации",
          "Программой (алгоритмом) срабатывания системы противоаварийной защиты технологической установки",
        ],
        correctAnswers: [
          "Схемой блокировок, представленной в технологическом регламенте и (или) проектной документации",
          "Программой (алгоритмом) срабатывания системы противоаварийной защиты технологической установки",
        ],
      },
      {
        code: "20116039",
        text: "Какие предусматриваются меры к обеспечению взрывобезопасности технологических процессов в случае обоснованной в проектной документации необходимости проведения процесса в области критических значений температур?",
        answers: [
          "Только по обеспечению персонала индивидуальными средствами защиты",
          "Исключающие наличие или предотвращающие появление источников зажигания, способных воспламенить смесь паров растворителя с воздухом",
          "Только по обеспечению наличия наряда-допуска",
          "Обеспечивающие дополнительный контроль непосредственно руководителем подразделения незначительных превышений значений параметров процесса, способных создать опасные отклонения и стать причиной аварийной ситуации или аварии",
        ],
        correctAnswers: [
          "Исключающие наличие или предотвращающие появление источников зажигания, способных воспламенить смесь паров растворителя с воздухом",
        ],
      },
      {
        code: "20116040",
        text: "Для каких производств управление системами подачи флегматизирующих веществ на объектах, связанных с производством растительных масел, осуществляется дистанционно?",
        answers: [
          "Для производств с блоками I категории",
          "Для производств с блоками III категории",
          "Для производств со значением относительного энергетического потенциала Qв &lt; 10",
          "Для производств с блоками II категории",
        ],
        correctAnswers: ["Для производств с блоками III категории"],
      },
      {
        code: "20116041",
        text: "Для каких производств допускается ручное управление по месту системами подачи флегматизирующих веществ?",
        answers: [
          "Для производств с блоками III категории",
          "Для производств с блоками II категории",
          "Для производств со значением относительного энергетического потенциала Qв &lt; 10",
          "Для производств с блоками I категории",
        ],
        correctAnswers: [
          "Для производств со значением относительного энергетического потенциала Qв &lt; 10",
        ],
      },
      {
        code: "20116042",
        text: "Что предусматривается для максимального снижения выбросов в окружающую среду растворителя в виде паров и жидкой фазы при аварийной разгерметизации системы в технологических системах объектов производств растительных масел для технологических блоков I категории взрывоопасности?",
        answers: [
          "Установка автоматических быстродействующих запорных и (или) отсекающих устройств со временем срабатывания не более 12 с",
          "Установка запорных и (или) отсекающих устройств с дистанционным управлением и временем срабатывания не более 120 с",
          "Установка запорных устройств с ручным приводом, при этом предусматривается минимальное время приведения их в действие за счет рационального размещения (максимально допустимого приближения к рабочему месту оператора), но не более 300 с",
        ],
        correctAnswers: [
          "Установка автоматических быстродействующих запорных и (или) отсекающих устройств со временем срабатывания не более 12 с",
        ],
      },
      {
        code: "20116043",
        text: "Для каких блоков объектов производств растительных масел допускается применение автоматических средств контроля параметров, значения которых определяют взрывоопасность процесса, и ручного регулирования?",
        answers: [
          "Для блоков, имеющих относительный энергетический потенциал Qв &lt; 10",
          "Для блоков III категории",
          "Для блоков I категории",
          "Для блоков II категории",
        ],
        correctAnswers: [
          "Для блоков, имеющих относительный энергетический потенциал Qв &lt; 10",
        ],
      },
      {
        code: "20116044",
        text: "Каким документом определяется выбор способа подачи флегматизатора, его количества для обеспечения эффективности продувки и исключения возможности образования застойных зон на объектах, связанных с производством растительных масел?",
        answers: [
          "Технологическим регламентом",
          "Инструкцией по безопасному производству работ",
          "Техническим регламентом",
          "Проектной документацией",
        ],
        correctAnswers: ["Проектной документацией"],
      },
      {
        code: "20116045",
        text: "Чем должно быть обеспечено технологическое оборудование, в котором возможно образование взрывоопасных смесей на объектах, связанных с производством растительных масел?",
        answers: [
          "Средствами пожаротушения",
          "Системами подачи флегматизатора (азота)",
          "Защитными кожухами",
          "Предупреждающими ограждениями",
        ],
        correctAnswers: ["Системами подачи флегматизатора (азота)"],
      },
      {
        code: "20116046",
        text: "Какое требование установлено к газосигнализаторам довзрывных концентраций горючих газов в помещениях цеха экстракции, отгонки растворителя из шрота, дистилляции, насосных для перекачки растворителя объектов производств растительных масел?",
        answers: [
          "Газосигнализаторы с сигнализацией превышения 10 % уровня от нижнего концентрационного предела распространения пламени",
          "Газосигнализаторы с сигнализацией превышения 20 % уровня от нижнего концентрационного предела распространения пламени",
          "Газосигнализаторы с сигнализацией превышения 5 % уровня от нижнего концентрационного предела распространения пламени",
          "Газосигнализаторы с сигнализацией превышения 15 % уровня от нижнего концентрационного предела распространения пламени",
        ],
        correctAnswers: [
          "Газосигнализаторы с сигнализацией превышения 10 % уровня от нижнего концентрационного предела распространения пламени",
        ],
      },
      {
        code: "20116047",
        text: "При каком виде остановки аппараты и трубопроводы, содержащие растворитель или мисцеллу, должны продуваться азотом или водяным паром после опорожнения?",
        answers: [
          "При аварии",
          "При любых видах остановки",
          "При профилактике",
          "При ремонте",
        ],
        correctAnswers: ["При любых видах остановки"],
      },
      {
        code: "20116048",
        text: "В каких теплообменных аппаратах объектов производств растительных масел допускается возможность взаимного проникновения пара (воды) и нагреваемого (охлаждаемого) продукта?",
        answers: [
          "В конденсаторах",
          "В дистилляторах",
          "В десорберах",
          "В оборудовании, где это предусматривается технологией",
        ],
        correctAnswers: [
          "В оборудовании, где это предусматривается технологией",
        ],
      },
      {
        code: "20116049",
        text: "В соответствии с каким документом проводятся опытные работы, опробование нового оборудования, систем автоматизации в действующих взрывопожароопасных производствах с технологическими блоками II и III категорий взрывоопасности на объектах, связанных с производством растительных масел?",
        answers: [
          "В соответствии с разовым (опытным) технологическим регламентом",
          "В соответствии с технологической инструкцией",
          "В соответствии с правилами безопасности химически опасных производственных объектов",
          "В соответствии с декларацией промышленной безопасности",
        ],
        correctAnswers: [
          "В соответствии с разовым (опытным) технологическим регламентом",
        ],
      },
      {
        code: "20116050",
        text: "Кем определяются порядок, способы и периодичность уборки пыли в производственных помещениях объектов производств растительных масел?",
        answers: [
          "Техническим руководителем организации",
          "Руководителем проектной организации",
          "Руководителем специализированной организации",
          "Руководителем организации",
        ],
        correctAnswers: ["Руководителем организации"],
      },
      {
        code: "20116051",
        text: "Каким образом должны выполняться участки перекрытий под оборудованием экстракционного цеха во избежание растекания (разлива) растворителя (мисцеллы)?",
        answers: [
          "Без проемов и ограждаться бортом высотой не менее 0,15 м",
          "С проемами в виде поддонов с бортиками не менее 0,2 м",
          "Без проемов и ограждаться бортом высотой не менее 0,25 м",
          "С проемами в виде поддонов с бортиками не менее 0,1 м",
        ],
        correctAnswers: [
          "Без проемов и ограждаться бортом высотой не менее 0,15 м",
        ],
      },
      {
        code: "20116052",
        text: "При каких условиях допускается проводить технологический взрывоопасный процесс в области критических значений температур на объектах, связанных с производством растительных масел?",
        answers: [
          "В случае принятия мер, исключающих или предотвращающих появление источников зажигания",
          "При наличии у персонала средств индивидуальной защиты",
          "При наличии наряда-допуска",
          "Ни при каких условиях",
        ],
        correctAnswers: [
          "В случае принятия мер, исключающих или предотвращающих появление источников зажигания",
        ],
      },
      {
        code: "20116053",
        text: "Какое требование предъявляется к системам канализации технологических объектов при сбросе химически загрязненных стоков в магистральную сеть канализации?",
        answers: [
          "Системы канализации технологических объектов должны осуществлять сброс стоков в магистральную сеть в порядке, установленном организацией",
          "Системы канализации технологических объектов должны быть оборудованы звуковой и световой сигнализацией",
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
          "Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть",
        ],
        correctAnswers: [
          "Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов",
        ],
      },
      {
        code: "20116054",
        text: "Какое требование к подшипниковым узлам шнековых питателей, ленточных конвейеров, норий, скребковых конвейеров, валов, винтовых конвейеров и перемешивающих устройств экстракторов, тостеров объектов производств растительных масел указано верно?",
        answers: [
          "Должны находиться в зоне видимости оператора установки",
          "Должны смазываться перед началом смены",
          "Должны проходить ежесменный контроль состояния",
          "Должны выноситься из зоны, в которую возможно попадание и накопление перемещаемых продуктов, или должны иметь защиту от попадания в них этих продуктов",
        ],
        correctAnswers: [
          "Должны выноситься из зоны, в которую возможно попадание и накопление перемещаемых продуктов, или должны иметь защиту от попадания в них этих продуктов",
        ],
      },
      {
        code: "20116055",
        text: "Какое требование к газоходу, соединяющему тостер и мокрую шротоловушку, указано неверно?",
        answers: [
          "Должен быть оборудован показывающим манометром",
          "Должен иметь лючки в торцах для ревизии и очистки внутренней поверхности",
          "Должен иметь уклон в сторону мокрой шротоловушки не менее 2 см на 1 погонный метр трубы газохода",
          "Должен иметь подвод пара для пропаривания газохода и шротоловушки перед вскрытием их для осмотра или ремонта",
        ],
        correctAnswers: ["Должен быть оборудован показывающим манометром"],
      },
      {
        code: "20116056",
        text: "Какие устройства, оснащаемые фильтр для мисцеллы, указаны неверно?",
        answers: [
          "Предохранительный клапан с отводом мисцеллы в сборник нефильтрованной мисцеллы",
          "Штуцер с манометром для продувки фильтра инертным газом",
          "Смотровой фонарь на сливной трубе фильтрованной мисцеллы",
          "Вакуумметр на сливной трубе фильтрованной мисцеллы",
        ],
        correctAnswers: ["Вакуумметр на сливной трубе фильтрованной мисцеллы"],
      },
      {
        code: "20116057",
        text: "Какая должна быть высота решетчатого вертикального ограждения для бункеров и завальных ям объектов производств растительных масел?",
        answers: [
          "Не менее чем на 1,5 м выше уровня загрузки масличного сырья",
          "Не менее чем на 0,5 м выше уровня загрузки масличного сырья",
          "Не менее чем на 1 м выше уровня загрузки масличного сырья",
          "Не регламентируется",
        ],
        correctAnswers: [
          "Не менее чем на 0,5 м выше уровня загрузки масличного сырья",
        ],
      },
      {
        code: "20116058",
        text: "Что должно предшествовать пуску транспортных систем при транспортировке семян жмыхов и шротов?",
        answers: [
          "Включение звуковой и световой сигнализаций",
          "Включение вентиляции",
          "Отключение звуковой сигнализации при включенной световой",
          "Голосовое оповещение",
        ],
        correctAnswers: ["Включение звуковой и световой сигнализаций"],
      },
      {
        code: "20116059",
        text: "При какой минимальной глубине приямков, в которых размещено оборудование для транспортирования масличного сырья, они должны оборудоваться механической вентиляцией, закрываться крышками или ограждаться?",
        answers: ["0,3 м", "1 м", "0,5 м", "0,7 м"],
        correctAnswers: ["0,5 м"],
      },
      {
        code: "20116060",
        text: "Какое объемное содержание диоксида (двуокиси) углерода и кислорода допускается в подвальных и полуподвальных помещениях складов масличного сырья, галерей и туннелей, предназначенных для транспортирования маслосемян, а также приямков, в которых размещено оборудование для транспортирования масличного сырья в случае неисправности вентиляции?",
        answers: [
          "Содержание в воздухе СО2 не должно превышать 0,5 %, а О2 должно быть не менее 20 %",
          "Содержание в воздухе СО2 не должно превышать 2 %, а О2 должно быть не менее 25 %",
          "Содержание в воздухе СО2 не должно превышать 1,0 %, а О2 должно быть не менее 30 %",
          "Содержание в воздухе СО2 не должно превышать 1,5 %, а О2 должно быть не менее 20 %",
        ],
        correctAnswers: [
          "Содержание в воздухе СО2 не должно превышать 0,5 %, а О2 должно быть не менее 20 %",
        ],
      },
      {
        code: "20116061",
        text: "Кто определяет график контроля воздушной среды в подвальных и полуподвальных помещениях масличного сырья, галереях, туннелях и приямках, связанных с транспортированием масличного сырья?",
        answers: [
          "Начальник складского помещения",
          "Технический руководитель организации",
          "Ответственный за охрану труда",
          "Руководитель структурного подразделения",
        ],
        correctAnswers: ["Технический руководитель организации"],
      },
      {
        code: "20116062",
        text: "Что должно предшествовать пуску транспортных систем при транспортировке семян, жмыхов и шротов?",
        answers: [
          "Включение звуковой и световой сигнализаций",
          "Включение вентиляции",
          "Отключение звуковой сигнализации при включенной световой",
          "Голосовое оповещение",
        ],
        correctAnswers: ["Включение звуковой и световой сигнализаций"],
      },
      {
        code: "20116063",
        text: "Какие требования установлены к люку-лазу, предназначенному для осмотра и ремонтных работ внутри силосных ячеек (бункеров, завальных ям)?",
        answers: [
          "Люк размером не менее 500 x 500 мм, оборудованный съемной решеткой с размером ячеек не более 100 x 100 мм и герметичной крышкой",
          "Люк размером не менее 400 x 400 мм, оборудованный съемной прочной решеткой с размером ячеек не более 80 x 80 мм",
          "Люк размером не менее 500 x 500 мм, оборудованный съемной прочной решеткой с размером ячеек не более 50 x 50 мм и герметичной крышкой",
        ],
        correctAnswers: [
          "Люк размером не менее 500 x 500 мм, оборудованный съемной решеткой с размером ячеек не более 100 x 100 мм и герметичной крышкой",
        ],
      },
      {
        code: "20116064",
        text: "Какие размеры мостков с решетчатым настилом должны устраиваться в случае отбора проб масличного сырья или шрота вручную из напольных складов?",
        answers: [
          "Шириной не менее 0,5 м и перилами высотой не менее 0,7 м",
          "Шириной не менее 1 м и перилами высотой не менее 1 м",
          "Шириной не менее 0,7 м и перилами высотой не менее 0,9 м",
          "Шириной не менее 0,9 м и перилами высотой не менее 1,2 м",
        ],
        correctAnswers: [
          "Шириной не менее 0,7 м и перилами высотой не менее 0,9 м",
        ],
      },
      {
        code: "20116065",
        text: "Какое из перечисленных требований к процессам транспортирования и хранения масличного сырья, жмыхов и шротов указано неверно?",
        answers: [
          "Температуру заложенных на хранение жмыха и шрота необходимо проверять по графику, утвержденному в эксплуатирующей организации, с помощью дистанционных стационарных термометров (термоподвесок)",
          "Для вновь строящихся элеваторов для шрота должны предусматриваться устройства, обеспечивающие разрыхление слежавшегося шрота",
          "Элеваторы для хранения масличного сырья и шрота должны снабжаться передвижными лебедками с люльками для спуска людей внутрь ячейки силоса в случае необходимости. Установка внутри лестниц (скоб) не допускается",
          "Пневмотранспорт и его элементы должны быть заземлены путем установки электропроводящих перемычек в местах фланцевых соединений элементов",
        ],
        correctAnswers: [
          "Элеваторы для хранения масличного сырья и шрота должны снабжаться передвижными лебедками с люльками для спуска людей внутрь ячейки силоса в случае необходимости. Установка внутри лестниц (скоб) не допускается",
        ],
      },
      {
        code: "20116066",
        text: "Какая устанавливается максимальная температура масличного сырья в бункерах складов и силосных ячейках элеваторов объектов производств растительных масел?",
        answers: ["40 °С", "50 °С", "45 °С", "30 °С"],
        correctAnswers: ["40 °С"],
      },
      {
        code: "20116067",
        text: "Какие действия персонала предусмотрены в случае повышения температуры масличного сырья выше допустимой, указанной в технологическом регламенте объектов производств растительных масел?",
        answers: [
          "Сообщить главному технологу предприятия и вызвать пожарную охрану предприятия",
          "Следует применить активное вентилирование, произвести перекачку семян из одного силоса (бункера) в другой, с площадки на площадку",
          "Сообщить главному технологу предприятия и сделать запись в журнале эксплуатации и ремонта",
          "Вызвать пожарную охрану предприятия",
        ],
        correctAnswers: [
          "Следует применить активное вентилирование, произвести перекачку семян из одного силоса (бункера) в другой, с площадки на площадку",
        ],
      },
      {
        code: "20116068",
        text: "Какими типами предохранительных клапанов необходимо оборудовать шроторазгрузитель, микроциклоны и шротопровод объектов производств растительных масел?",
        answers: [
          "Пружинного типа",
          "Рычажно-грузового типа",
          "Магнитно-пружинного типа",
          "Мембранного типа",
        ],
        correctAnswers: ["Мембранного типа"],
      },
      {
        code: "20116069",
        text: "Какие требования установлены к входам в сырьевые и шротовые склады напольного типа?",
        answers: [
          "Ко всем входам и выходам должен быть обеспечен свободный доступ персонала",
          "Входы устраиваются через двери, сблокированные с электродвигателями конвейеров, обслуживающих выпускные самотеки, таким образом, чтобы при открытии дверей электродвигатель отключался",
          "Входы устраиваются через проемы, оснащенные световой и звуковой сигнализациями",
          "Входы устраиваются в соответствии с внутренней документацией, утвержденной руководителем организации",
        ],
        correctAnswers: [
          "Входы устраиваются через двери, сблокированные с электродвигателями конвейеров, обслуживающих выпускные самотеки, таким образом, чтобы при открытии дверей электродвигатель отключался",
        ],
      },
      {
        code: "20116070",
        text: "В каком из перечисленных случаев гибкие шланги не применяются при проведении операций слива, налива, транспортирования и хранения растворителя и масла?",
        answers: [
          "В случае проведения операций налива в железнодорожные цистерны и другое нестандартное оборудование и слива из них",
          "В случае выполнения вспомогательных операций",
          "В случае продувки аппаратов",
          "Во взрывопожароопасных производствах в качестве стационарных трубопроводов для транспортирования растворителя, мисцеллы",
        ],
        correctAnswers: [
          "Во взрывопожароопасных производствах в качестве стационарных трубопроводов для транспортирования растворителя, мисцеллы",
        ],
      },
      {
        code: "20116071",
        text: "Какое количество тормозных башмаков для закрепления различных типов цистерн должно быть установлено для закрепления железнодорожных цистерн при операциях слива, налива растворителя и масла?",
        answers: [
          "Не менее 6 башмаков на каждой цистерне",
          "Не менее 4 башмаков на каждой цистерне",
          "От 4 до 8 башмаков в зависимости от типа цистерны",
          "Определяется расчетом и указывается в инструкции по проведению сливоналивных работ",
        ],
        correctAnswers: [
          "Определяется расчетом и указывается в инструкции по проведению сливоналивных работ",
        ],
      },
      {
        code: "20116072",
        text: "Какие требования к использованию железнодорожных цистерн с растворителями на объектах производств растительных масел указаны неверно?",
        answers: [
          "Для слива растворителя из цистерн могут быть использованы сливоналивные механизированные стояки со стационарной площадкой, лестницей и откидным мостиком для обслуживания горловины цистерны",
          "Для слива растворителя из цистерн должны использоваться установки, предназначенные для нижнего слива-налива нефти и нефтепродуктов из железнодорожных цистерн",
          "Допускается использование железнодорожных цистерн с растворителями, находящихся на железнодорожных путях, в качестве стационарных складских (расходных) емкостей в качестве складских расходных емкостей",
          "Цистерны, находящиеся под сливом (наливом), должны быть заземлены",
        ],
        correctAnswers: [
          "Допускается использование железнодорожных цистерн с растворителями, находящихся на железнодорожных путях, в качестве стационарных складских (расходных) емкостей в качестве складских расходных емкостей",
        ],
      },
      {
        code: "20116073",
        text: "Какой свободный объем для демпфирования температурного расширения растворителя необходимо оставлять при заполнении резервуаров на объектах производств растительных масел?",
        answers: [
          "Не менее 10 %",
          "Не менее 15 %",
          "Не более 10 %",
          "Устанавливается в зависимости от растворителя",
        ],
        correctAnswers: ["Не менее 10 %"],
      },
      {
        code: "20116074",
        text: "За какими устройствами (системами) необходимо осуществлять постоянный контроль в процессе эксплуатации резервуаров хранилищ растворителя на объектах производств растительных масел?",
        answers: [
          "За системой рекуперации растворителя экстракционного цеха",
          "За исправностью дыхательных клапанов и огнепреградителей",
          "За средствами пожаротушения и сигнализацией",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "За исправностью дыхательных клапанов и огнепреградителей",
        ],
      },
      {
        code: "20116075",
        text: "С какой периодичностью должны проверяться огнепреградители при температуре ниже 0 °C на объектах производств растительных масел?",
        answers: [
          "Не реже 1 раза в 10 дней",
          "Не реже 1 раза в сутки",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 15 дней",
        ],
        correctAnswers: ["Не реже 1 раза в 10 дней"],
      },
      {
        code: "20116076",
        text: "Что необходимо немедленно предпринять при внезапной остановке жаровни объектов производств растительных масел?",
        answers: [
          "Прекратить подачу глухого пара в жаровню",
          "Выгрузить мезгу и очистить чаны",
          "Обесточить электродвигатели жаровни, электродвигатели инактиватора или пропарочно-увлажнительного винтового конвейера и всех транспортных элементов, питающих жаровню, прекратить подачу глухого пара в жаровню",
          "Сообщить ответственному за безопасное производство работ",
        ],
        correctAnswers: [
          "Обесточить электродвигатели жаровни, электродвигатели инактиватора или пропарочно-увлажнительного винтового конвейера и всех транспортных элементов, питающих жаровню, прекратить подачу глухого пара в жаровню",
        ],
      },
      {
        code: "20116077",
        text: "В каком случае необходимо выгрузить и очистить чаны жаровни после их охлаждения от остатков мезги?",
        answers: [
          "Если температура мезги превышает установленные нормы",
          "Если длительность остановки жаровни превышает 10 минут",
          "Если отсутствует контроля со стороны рабочего персонала",
          "Если длительность остановки жаровни превышает 1 час",
        ],
        correctAnswers: ["Если длительность остановки жаровни превышает 1 час"],
      },
      {
        code: "20116078",
        text: "При какой минимальной остановке шнекового пресса необходимо очистить питатель и пресс от мезги?",
        answers: [
          "На 10 минут",
          "На 5 минут",
          "На 30 минут",
          "При любой внезапной остановке",
        ],
        correctAnswers: ["На 10 минут"],
      },
      {
        code: "20116079",
        text: "Какие из указанных действий, которые должны быть предприняты при появлении металлического стука и скрежета в чанах жаровни, указаны неверно?",
        answers: [
          "Немедленно остановить жаровню",
          "Немедленно провести ревизию чанов",
          "Освободить жаровню от мезги",
          "Прекратить подачу пара",
        ],
        correctAnswers: ["Немедленно провести ревизию чанов"],
      },
      {
        code: "20116080",
        text: "Какие из указанных действий, которые должны быть предприняты при появлении металлического стука и скрежета в чанах жаровни, указаны верно? Укажите все правильные ответы.",
        answers: [
          "Сообщить ответственному за безопасное производство работ",
          "Прекратить подачу пара",
          "Установить контроль за температурой мезги",
          "Немедленно остановить жаровню",
          "Немедленно провести ревизию чанов",
        ],
        correctAnswers: [
          "Прекратить подачу пара",
          "Немедленно остановить жаровню",
        ],
      },
      {
        code: "20116081",
        text: "В каком количестве должно осуществляться хранение жмыха в прессовом цехе при внезапной остановке экстракционного цеха?",
        answers: [
          "Не превышающем рабочий объем жаровен",
          "Не превышающем 3-кратный рабочий объем жаровен",
          "Не превышающем 2-кратный рабочий объем жаровен",
          "По усмотрению лица, ответственного за безопасное производство работ",
        ],
        correctAnswers: ["Не превышающем рабочий объем жаровен"],
      },
      {
        code: "20116082",
        text: "Какое из перечисленных требований к процессам экстракции, отгонки растворителя из шрота и мисцеллы указано верно?",
        answers: [
          "Откачка экстракционного масла из цеховых емкостей должна производиться после проверки температуры вспышки",
          "Периодичность контроля за содержанием влаги в растворителе, растворителя в шроте, в масле и в сточной воде, сбрасываемой из бензоловушки, должна быть установлена в эксплуатационной инструкции, утвержденной руководителем организации",
          "Дублирующее управление задвижкой на магистрали подачи пара в экстракционный цех должно осуществляться внутри цеха",
          "Винтовые конвейеры, предназначенные для транспортирования шрота из тостера (шнекового испарителя) в пределах экстракционного цеха, во время работы должны быть открыты",
        ],
        correctAnswers: [
          "Откачка экстракционного масла из цеховых емкостей должна производиться после проверки температуры вспышки",
        ],
      },
      {
        code: "20116083",
        text: "Какие требования к экстракторам объектов производств растительных масел указаны неверно?",
        answers: [
          "Шнековые экстракторы оснащаются системами аварийного освобождения",
          "Шнековые экстракторы оснащаются приборами контроля и регулирования частоты вращения валов",
          "Ленточные экстракторы и экстракторы других типов, имеющие в своем составе конвейеры, оснащаются системами регулирования скорости движения ленты (конвейера) в зависимости от количества подаваемого материала",
          "Приводы экстракторов обеспечиваются системами защиты от превышения предельно допустимой нагрузки на валы, исключающими их поломку при запрессовках и заклинивании в случае попадания посторонних предметов",
        ],
        correctAnswers: [
          "Шнековые экстракторы оснащаются системами аварийного освобождения",
        ],
      },
      {
        code: "20116084",
        text: "Какое содержание кислорода допускается при анализе продувочного газа после продувки экстракционной линии?",
        answers: [
          "Не менее 10 %",
          "Не более 7 %",
          "Не более 10 %",
          "Требование к содержанию кислорода устанавливается в технологическом регламенте",
        ],
        correctAnswers: ["Не более 7 %"],
      },
      {
        code: "20116085",
        text: "Какой флегматизатор используется для тостера при продувке экстракционной линии?",
        answers: [
          "Двуокись углерода",
          "Водяной пар",
          "Оксид углерода",
          "Хлор - и бромзамещенные углеводороды",
        ],
        correctAnswers: ["Водяной пар"],
      },
      {
        code: "20116086",
        text: "Какие действия, осуществление которых необходимо при остановке чанного испарителя (тостера) объектов производств растительных масел, указаны неверно?",
        answers: [
          "При разгрузке тостера производится тщательная зачистка чанов от остатков шрота",
          "Перед открытием люков тостера подается острый пар во все чаны",
          "Проверяется целостность контура заземления",
          "Отключается подача глухого пара",
        ],
        correctAnswers: ["Проверяется целостность контура заземления"],
      },
      {
        code: "20116087",
        text: "Какие действия должны быть выполнены при остановке чанного испарителя (тостера) во избежание возможного обугливания в нем шрота и возникновения аварийной ситуации?",
        answers: [
          "Отключение подачи глухого пара",
          "Загрузка чанного испарителя непроэкстрагированным материалом (лепестком, крупкой)",
          "Подача острого пара во все чаны и загрузка чанного испарителя непроэкстрагированным материалом (лепестком, крупкой)",
        ],
        correctAnswers: ["Отключение подачи глухого пара"],
      },
      {
        code: "20116088",
        text: "В каком случае должна производиться чистка трубок конденсаторов объектов производств растительных масел?",
        answers: [
          "Если есть график, определенный руководителем организации",
          "Если есть решение лица, ответственного за безопасное производство работ",
          "В установленных проектной документацией и документацией завода-изготовителя случаях",
          "Если есть решение технического руководителя организации",
        ],
        correctAnswers: [
          "В установленных проектной документацией и документацией завода-изготовителя случаях",
        ],
      },
      {
        code: "20116089",
        text: "Какая периодичность осмотра технического состояния трубок конденсаторов объектов производств растительных масел путем осмотра со вскрытием крышек?",
        answers: [
          "Не реже 1 раза в год",
          "Определяется технологическим регламентом",
          "Не реже 1 раза в 1,5 года",
          "Не реже 1 раза в 2 года",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "20116090",
        text: "Какие требования установлены к блокировкам, устанавливаемым на электродвигателях технологического оборудования и транспортных элементах объектов производств растительных масел?",
        answers: [
          "При остановке технологического оборудования или транспортных элементов должна прекращаться подача всех материалов",
          "При остановке технологического оборудования или транспортных элементов должна срабатывать звуковая сигнализация",
          "При остановке технологического оборудования или транспортных элементов они должны все отключаться с одновременной подачей звукового и (или) светового сигнала",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "При остановке технологического оборудования или транспортных элементов они должны все отключаться с одновременной подачей звукового и (или) светового сигнала",
        ],
      },
      {
        code: "20116091",
        text: "Что не допускается при остановках экстракционной линии объектов производств растительных масел?",
        answers: [
          "Только выключение принудительной вентиляции цеха",
          "Только прекращение циркуляции охлаждающей воды, рассола или абсорбента в технологических аппаратах",
          "Только сброс воды из водоотделителя в бензоловушку без дополнительной обработки ее в шламовыпаривателе и охлаждения",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20116092",
        text: "К какой категории взрывопожароопасности относятся помещения, в которых размещаются емкости для промежуточного хранения экстракционного масла, объектов производств растительных масел?",
        answers: ["А", "Б", "В", "Г"],
        correctAnswers: ["А"],
      },
      {
        code: "20116093",
        text: "В каком случае температура наружных поверхностей оборудования и (или) теплоизоляционных покрытий, расположенных в рабочей или обслуживаемой зоне помещений объектов производств растительных масел, должна быть не более 35 °C? Укажите все правильные ответы.",
        answers: [
          "При температуре вспышки паров растворителя не выше 45 °C",
          "При температуре среды 100 °C и ниже",
          "При металлическом покрывном слое",
          "При температуре среды выше 100 °C",
        ],
        correctAnswers: [
          "При температуре вспышки паров растворителя не выше 45 °C",
          "При температуре среды 100 °C и ниже",
        ],
      },
      {
        code: "20116094",
        text: "Какой должна быть максимальная температура наружных поверхностей оборудования и (или) теплоизоляционных покрытий, расположенных в рабочей или обслуживаемой зоне помещений объектов производств растительных масел, при металлическом покрывном слое?",
        answers: ["55 °С", "45 °С", "60 °С", "50 °С"],
        correctAnswers: ["55 °С"],
      },
      {
        code: "20116095",
        text: "Какой должна быть температура наружных поверхностей оборудования и (или) теплоизоляционных покрытий, расположенных в рабочей или обслуживаемой зоне помещений при температуре среды выше 100 °C?",
        answers: [
          "Не более 45 °С",
          "Не более 100 °С",
          "Не более 55 °С",
          "60 °С",
        ],
        correctAnswers: ["Не более 45 °С"],
      },
      {
        code: "20116096",
        text: "Какие требования к тепловой изоляции аппаратов, технологических трубопроводов для растительных масел и мисцеллы объектов производств растительных масел указаны неверно?",
        answers: [
          "Для теплоизоляции используется непористый негорючий материал",
          "Необходимо предусматривать меры защиты от попаданий масла, мисцеллы, растворителя на изоляцию или под ее слой",
          "Для теплоизоляции используется трудносгораемый материал",
          "Теплоизоляционные изделия из минеральной ваты, базальтового или супертонкого стекла применяются только в обкладках со всех сторон из стеклянной или кремнеземной ткани и под металлическим покрывным слоем",
        ],
        correctAnswers: [
          "Для теплоизоляции используется трудносгораемый материал",
        ],
      },
      {
        code: "20116097",
        text: "Какое из перечисленных требований к устройству площадок и лестниц для обслуживания оборудования объектов производств растительных масел, требующего нахождения или перемещения лиц, работающих выше уровня пола указано верно? Укажите все правильные ответы.",
        answers: [
          "На высоте 0,5 м от пола (площадки) перила должны иметь дополнительное продольное ограждение",
          "Площадки, расположенные на высоте 0,8 м и более над уровнем пола, открытые приямки, а также открытые монтажные проемы должны иметь по периметру перила высотой не менее 1,0 м",
          "Вертикальные стойки перил должны иметь шаг не более 1,0 м",
          "Настилы площадок должны иметь сплошную бортовую полосу высотой не менее 0,5 м",
        ],
        correctAnswers: [
          "На высоте 0,5 м от пола (площадки) перила должны иметь дополнительное продольное ограждение",
          "Площадки, расположенные на высоте 0,8 м и более над уровнем пола, открытые приямки, а также открытые монтажные проемы должны иметь по периметру перила высотой не менее 1,0 м",
        ],
      },
      {
        code: "20116098",
        text: "Что должно быть предусмотрено для обслуживания оборудования объектов производств растительных масел, требующего нахождения или перемещения лиц, работающих выше уровня пола?",
        answers: [
          "Стационарные площадки и лестницы к ним",
          "Подъемники",
          "Правилами не регламентируется, определяется проектом",
          "Приставные лестницы",
        ],
        correctAnswers: ["Стационарные площадки и лестницы к ним"],
      },
      {
        code: "20116099",
        text: "Кто и в какой форме выдает распоряжение на подключение электрооборудования подвижных конвейеров к распределительной коробке и к заземляющим устройствам объектов производств растительных масел?",
        answers: [
          "Начальник цеха (участка) выдает письменное распоряжение",
          "Начальник цеха выдает устное распоряжение",
          "Технический руководитель предприятия выдает письменное разрешение начальнику цеха и непосредственным исполнителям",
        ],
        correctAnswers: [
          "Начальник цеха (участка) выдает письменное распоряжение",
        ],
      },
      {
        code: "20116100",
        text: "Чем должно быть снабжено емкостное оборудование экстракционного цеха для исключения возможности переполнения растворителем, мисцеллой, маслом, на случай выхода из строя автоматических устройств?",
        answers: [
          "Реле уровня жидкости",
          "Переливным трубопроводом, не имеющим запорных устройств и оснащенным смотровым фонарем",
          "Поплавковым клапаном для емкостного оборудования",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Переливным трубопроводом, не имеющим запорных устройств и оснащенным смотровым фонарем",
        ],
      },
      {
        code: "20116101",
        text: "Под каким разрежением должен находиться экстрактор многократного орошения?",
        answers: [
          "Под разрежением в пределах 0,02 - 0,05 кПа (2 - 5 мм вод. ст.)",
          "Под разрежением в пределах 0,05 - 0,10 кПа (5 - 10 мм вод. ст.)",
          "Под разрежением в пределах 0,10 - 0,15 кПа (10 - 15 мм вод. ст.)",
        ],
        correctAnswers: [
          "Под разрежением в пределах 0,05 - 0,10 кПа (5 - 10 мм вод. ст.)",
        ],
      },
      {
        code: "20116102",
        text: "Какие требования к технологическим трубопроводам для растворителя, мисцеллы и паров растворителя указаны неверно?",
        answers: [
          "Размещение технологических трубопроводов должно осуществляться с учетом возможности проведения визуального контроля их состояния",
          "Размещение технологических трубопроводов должно осуществляться с учетом возможности выполнения работ по обслуживанию и ремонту",
          "Технологические трубопроводы не должны иметь цифровых обозначений, маркировка трубопроводов осуществляется различной окраской, выполненной в соответствии с государственным стандартом",
          "На маховиках или рукоятках арматуры должны быть нанесены стрелки, указывающие направления их вращения",
        ],
        correctAnswers: [
          "Технологические трубопроводы не должны иметь цифровых обозначений, маркировка трубопроводов осуществляется различной окраской, выполненной в соответствии с государственным стандартом",
        ],
      },
      {
        code: "20116103",
        text: "Каким образом не допускается прокладывать трубопроводы растворителя и мисцеллы? Укажите все правильные ответы.",
        answers: [
          "Под землей в случае, когда эта технологическая необходимость обоснована проектной документацией",
          "Через строительные конструкции зданий в случае принятия мер, исключающих возможность передачи дополнительных нагрузок на трубы (к примеру, установка гильз, патронов) с заделкой из негорючих материалов",
          "Через бытовые, подсобные и административно-хозяйственные помещения",
          "Через распределительные электрические устройства, помещения щитов и пультов автоматизации, вентиляционные камеры",
        ],
        correctAnswers: [
          "Через бытовые, подсобные и административно-хозяйственные помещения",
          "Через распределительные электрические устройства, помещения щитов и пультов автоматизации, вентиляционные камеры",
        ],
      },
      {
        code: "20116104",
        text: "В каких местах не допускается размещать фланцевые соединения объектов производств растительных масел?",
        answers: [
          "В местах установки арматуры или подсоединения трубопроводов к аппаратам",
          "В местах, открытых и доступных для визуального наблюдения, обслуживания, разборки, ремонта и монтажа",
          "Над местами постоянного прохода людей к рабочим площадкам при обеспечении достаточной степени герметичности",
          "В местах, на которых по условиям технологии требуется периодическая разборка для проведения чистки и ремонта трубопроводов",
        ],
        correctAnswers: [
          "Над местами постоянного прохода людей к рабочим площадкам при обеспечении достаточной степени герметичности",
        ],
      },
      {
        code: "20116105",
        text: "Какое напряжение применяется для питания ручных светильников в помещениях, отнесенных к помещениям с повышенной опасностью и особо опасным, объектов производств растительных масел?",
        answers: [
          "Не выше 42 В",
          "Не выше 60 В",
          "Не выше 125 В",
          "Не выше 24 В",
        ],
        correctAnswers: ["Не выше 42 В"],
      },
      {
        code: "20116106",
        text: "Какая система отопления должна применяться в производственных помещениях объектов производств растительных масел?",
        answers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
          "Только система водяного отопления",
          "Только система парового отопления",
          "В зависимости от проектной документации",
        ],
        correctAnswers: [
          "Система воздушного отопления, совмещенная с приточной вентиляцией",
        ],
      },
      {
        code: "20116107",
        text: "Какое напряжение применяется для питания переносных светильников, работающих от аккумуляторных батарей, выполненных во взрывозащищенном исполнении, для внутреннего освещения аппаратов (резервуаров), заполненных растворителем, мисцеллой или парами растворителя?",
        answers: [
          "Не более 36 В",
          "Не более 42 В",
          "Не более 24 В",
          "Не более 12 В",
        ],
        correctAnswers: ["Не более 12 В"],
      },
      {
        code: "20116108",
        text: "С какой целью предусматривается подача пара в месте расположения градирен на объектах производств растительных масел?",
        answers: [
          "Только с целью продувки сопел",
          "Только с целью размораживания льда в зимнее время",
          "Только с целью продувки трубопроводов",
          "Для достижения всех перечисленных целей",
        ],
        correctAnswers: ["Для достижения всех перечисленных целей"],
      },
      {
        code: "20116109",
        text: "В каких документах должна быть установлена схема обработки бензожиросодержащих сточных вод?",
        answers: [
          "В технологическом регламенте и проектной документации",
          "В санитарном паспорте и в технологическом регламенте",
          "В производственной инструкции",
          "В положении об экологическом контроле и в проектной документации",
        ],
        correctAnswers: [
          "В технологическом регламенте и проектной документации",
        ],
      },
      {
        code: "20116110",
        text: "С какой периодичностью должно контролироваться содержание растворителя в сточных водах из бензоловушки?",
        answers: [
          "1 раз в 2 дня",
          "Ежесменно",
          "В соответствии с требованиями, установленными в технологическом регламенте",
          "С частотой, установленной в производственной инструкции",
        ],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20116111",
        text: "Какое из перечисленных требований к обслуживанию и ремонту технологического оборудования и трубопроводов противоречит Правилам безопасности химически опасных производственных объектов?",
        answers: [
          "Все аппараты экстракционного цеха должны отключаться от системы технологических трубопроводов с помощью стандартных пронумерованных заглушек с видимыми хвостовиками",
          "После завершения указанных операций с аппаратов необходимо снимать крышки люков-лазов и проводить операции проветривания",
          "Перед началом ремонтных работ аппараты и технологические трубопроводы экстракционного цеха должны продуваться инертным газом",
          "Теплообменные поверхности аппаратов (дистилляторов, конденсаторов, теплообменников) должны очищаться от нагара путем щелочения и промывки водой",
        ],
        correctAnswers: [
          "Перед началом ремонтных работ аппараты и технологические трубопроводы экстракционного цеха должны продуваться инертным газом",
        ],
      },
      {
        code: "20116112",
        text: "Каким образом допускается отогревать замерзшие трубопроводы с растворителем (мисцеллой)?",
        answers: [
          "Паром или водой",
          "С помощью кислородной горелки",
          "С применением теплоизолирующих материалов",
          "Любым из перечисленных способов",
        ],
        correctAnswers: ["Паром или водой"],
      },
      {
        code: "20116113",
        text: "Каким образом должна проводиться опрессовка системы с сильфонной арматурой?",
        answers: [
          "Давлением, не превышающим указанное в эксплуатационной документации на сильфонную арматуру, при температуре 20 °C, при этом арматура должна быть в открытом положении",
          "Сжатым воздухом, при нормальной температуре, при этом арматура должна быть в открытом положении",
          "Давлением, указанным в эксплуатационной документации, при нормальной температуре, при этом арматура должна быть закрыта",
          "Сжатым воздухом при температуре 10 °C, при этом арматура должна быть в открытом положении",
        ],
        correctAnswers: [
          "Давлением, не превышающим указанное в эксплуатационной документации на сильфонную арматуру, при температуре 20 °C, при этом арматура должна быть в открытом положении",
        ],
      },
      {
        code: "20116114",
        text: "Кем рассчитывается и подбирается гидрозатвор для каждого типа водоотделителя объектов производств растительных масел?",
        answers: [
          "Проектной организацией",
          "Эксплуатирующей организацией",
          "Научно-исследовательской организацией",
          "Подрядной организацией",
        ],
        correctAnswers: ["Проектной организацией"],
      },
      {
        code: "20116115",
        text: "В каком случае разрешается установка запорного устройства на вытяжной трубе объектов производств растительных масел?",
        answers: [
          "Если вытяжная труба оборудована пароэжектором или вентилятором",
          "Если запорное устройство устанавливается на вытяжной трубе дефлегматора",
          "Если запорное устройство устанавливается на вытяжной трубе конденсатора",
          "Если запорное устройство устанавливается на вытяжной трубе абсорбера",
        ],
        correctAnswers: [
          "Если вытяжная труба оборудована пароэжектором или вентилятором",
        ],
      },
      {
        code: "20116116",
        text: "Какое из перечисленных требований к нориям производств растительных масел указано неверно?",
        answers: [
          "Нории должны быть оснащены устройством, предотвращающим обратный ход ленты, цепи",
          "Нории должны быть оборудованы электроблокировкой, обеспечивающей автоматическое отключение электродвигателя при перегрузках",
          "Ковши норий для шрота должны выполняться из нержавеющей стали",
        ],
        correctAnswers: [
          "Ковши норий для шрота должны выполняться из нержавеющей стали",
        ],
      },
      {
        code: "20116117",
        text: "Какое из перечисленных утверждений, относящихся к трубопроводной арматуре объектов производств растительных масел, допускается?",
        answers: [
          "Использование пробковых чугунных кранов в качестве арматуры противоаварийного назначения",
          "Изготовление лючков и задвижек на перепускных течках (экстрактора, испарителя растворителя из шрота) из нержавеющей стали",
          "Установка запорной арматуры с ручным управлением при использовании регулирующей арматуры с дистанционным управлением в качестве отсекающих устройств",
          "Установка запорной арматуры с автоматическим управлением при использовании регулирующей арматуры в качестве арматуры противоаварийного назначения",
        ],
        correctAnswers: [
          "Установка запорной арматуры с ручным управлением при использовании регулирующей арматуры с дистанционным управлением в качестве отсекающих устройств",
        ],
      },
      {
        code: "20116118",
        text: "Какой характеристикой должна обеспечиваться необходимая степень герметичности разъемного соединения в течение межремонтного периода эксплуатации технологической схемы объектов производств растительных масел?",
        answers: [
          "Только монтажом фланцевых соединений",
          "Только материалом прокладок",
          "Только конструкцией уплотнения",
          "Всеми перечисленными",
        ],
        correctAnswers: ["Всеми перечисленными"],
      },
      {
        code: "20116119",
        text: "Из какого материала допускается изготовление наконечников резиновых шлангов, предназначенных для сливоналивных операций, на объектах производств растительных масел? Укажите все правильные ответы.",
        answers: ["Из титана", "Из меди", "Из бронзы", "Из нержавеющей стали"],
        correctAnswers: ["Из меди", "Из бронзы"],
      },
      {
        code: "20116120",
        text: "Какой клапан должен устанавливаться на трубопроводах, подводящих острый водяной пар в аппараты для отгонки растворителя, пропаривания, барботирования?",
        answers: [
          "Только редукционный клапан",
          "Только предохранительный клапан",
          "Только обратный клапан",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20116121",
        text: "В каком случае допускается сброс сточных вод, содержащих растворитель, в производственную канализацию?",
        answers: [
          "В случае, если это предусмотрено технологическим регламентом",
          "В случае, если это предусмотрено проектной документацией",
          "В случае, если сброс производится через гидравлические затворы",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20116122",
        text: "Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития?",
        answers: [
          "Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы",
          "Запорную арматуру, средства защиты от превышения давления, огнепреградители",
          "Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
          "Запорную арматуру, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов",
        ],
        correctAnswers: [
          "Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва",
        ],
      },
      {
        code: "20116123",
        text: "С учетом какой документации должны осуществляться выбор систем контроля, управления и противоаварийной автоматической защиты, а также систем связи и оповещения об аварийных ситуациях объектов производств растительных масел?",
        answers: [
          "Только c учетом правил безопасности химически опасных производственных объектов (ХОПО)",
          "Только c учетом документации на ХОПО",
          "Только c учетом действующей нормативно-технической документации",
          "С учетом всей перечисленной документации",
        ],
        correctAnswers: ["С учетом всей перечисленной документации"],
      },
      {
        code: "20116124",
        text: "Какие требования к системам автоматического контроля и управления технологическими процессами объектов производств растительных масел указаны неверно?",
        answers: [
          "Должны обеспечивать сигнализацию и регистрацию в реальном времени срабатывания средств системы противоаварийной защиты",
          "Должны обеспечивать постоянную связь оператора установки с механиком цеха",
          "Должны обеспечивать сигнализацию и регистрацию в реальном времени отклонений основных технологических параметров, в том числе определяющих безопасность ведения процесса",
          "Должны обеспечивать постоянный контроль за параметрами технологического процесса и управление режимом для поддержания их регламентированных значений",
        ],
        correctAnswers: [
          "Должны обеспечивать постоянную связь оператора установки с механиком цеха",
        ],
      },
      {
        code: "20116125",
        text: "Какие из функций, которые должна обеспечивать автоматизированная система управления технологическими процессами объектов производств растительных масел, указаны верно? Укажите все правильные ответы.",
        answers: [
          "Регистрация срабатывания и контроль за работоспособным состоянием средств противоаварийной защиты",
          "Постоянный анализ изменения параметров в сторону критических значений и прогнозирование возможной аварии",
          "Обеспечение связи оператора установки с начальником цеха и главным инженером",
          "Выдача информации о состоянии безопасности на объекте подразделениям, принимающим участие в ликвидации последствий аварий",
        ],
        correctAnswers: [
          "Регистрация срабатывания и контроль за работоспособным состоянием средств противоаварийной защиты",
          "Постоянный анализ изменения параметров в сторону критических значений и прогнозирование возможной аварии",
        ],
      },
      {
        code: "20116126",
        text: "Каким должно быть количество деблокирующих ключей в схемах противоаварийной защиты объектов производств масел?",
        answers: [
          "Минимальным",
          "Максимальным",
          "Не больше 8 ключей",
          "Не меньше 6 ключей",
        ],
        correctAnswers: ["Минимальным"],
      },
      {
        code: "20116127",
        text: "Каким документом определяются минимально и максимально допустимые уровни экстрагируемого материала в зависимости от типа экстрактора, вида экстрагируемого материала и конкретных условий?",
        answers: [
          "Проектной документацией и технологическим регламентом",
          "Правилами безопасности химически опасных производственных объектов",
          "Проектом систем противоаварийной защиты и автоматизированной системы управления технологическими процессами",
          "Стандартом организации",
        ],
        correctAnswers: [
          "Проектной документацией и технологическим регламентом",
        ],
      },
      {
        code: "20116128",
        text: "При каких параметрах экстракционной установки остановка цеха не требуется?",
        answers: [
          "При падении давления и температуры пара на коллекторах",
          "При падении давления воды в циркуляционной системе",
          "При падении давления сжатого воздуха ниже 0,2 МПа (2 кгс/см2) для питания систем контроля и управления системы противоаварийной защиты",
          "При повышении концентрации паров растворителя в воздушной среде цеха до 20 % от нижнего концентрационного предела распространения пламени",
        ],
        correctAnswers: [
          "При повышении концентрации паров растворителя в воздушной среде цеха до 20 % от нижнего концентрационного предела распространения пламени",
        ],
      },
      {
        code: "20116129",
        text: "При какой температуре шрота в четвертом чане чанного испарителя (тостера) отключается электропривод тостера или разгрузочного винтового конвейера тостера и все предшествующие транспортные элементы и оборудование, включая насосы растворителя?",
        answers: [
          "При падении температуры до 85 - 80 °С",
          "При температуре ниже 60 °С",
          "При падении температуры до 100 - 90 °С",
        ],
        correctAnswers: ["При падении температуры до 85 - 80 °С"],
      },
      {
        code: "20116130",
        text: "Какова предельно допустимая величина концентрации взрывоопасной парогазовой фазы сигнализации средств автоматического газового анализа в производственных помещениях на открытых наружных установках?",
        answers: [
          "Не более 30 % от нижнего концентрационного предела распространения пламени",
          "Не более 40 % от нижнего концентрационного предела распространения пламени",
          "Не более 20 % от нижнего концентрационного предела распространения пламени",
          "Не более 25 % от нижнего концентрационного предела распространения пламени",
        ],
        correctAnswers: [
          "Не более 20 % от нижнего концентрационного предела распространения пламени",
        ],
      },
      {
        code: "20116131",
        text: "Какими документами определяется и кем устанавливается периодичность проведения анализов загрязненности сжатого воздуха?",
        answers: [
          "Определяется проектом, устанавливается проектной организацией",
          "Определяется инструкцией по безопасности, устанавливается главным инженером",
          "Определяется нормативно-технической документацией, устанавливается организацией",
          "Определяется внутренней распорядительной документацией, устанавливается организацией",
        ],
        correctAnswers: [
          "Определяется нормативно-технической документацией, устанавливается организацией",
        ],
      },
      {
        code: "20116132",
        text: "Что используется для питания пневматических систем контроля, управления и противоаварийной защиты?",
        answers: ["Сжатый воздух", "Азот", "Инертный газ", "Неон"],
        correctAnswers: ["Сжатый воздух"],
      },
      {
        code: "20116133",
        text: "По какой категории надежности должно осуществляться электроснабжение электроприемников объектов производств растительных масел?",
        answers: [
          "Для блоков II, III категорий взрывопожароопасности электроснабжение должно предусматриваться не ниже 2 категории надежности, а электроприемников систем оборотного водоснабжения, аварийной вентиляции, аварийного освещения, обеспечения контрольно-измерительных приборов и автоматики сжатым воздухом, систем противопожарной защиты - не ниже 1 категории",
          "Электроснабжение должно осуществляться только по 1 категории надежности",
          "Электроснабжение должно осуществляться по категории надежности не ниже 2",
          "Допустимая категория надежности устанавливается разработчиком проекта в зависимости от применяемой технологии",
        ],
        correctAnswers: [
          "Для блоков II, III категорий взрывопожароопасности электроснабжение должно предусматриваться не ниже 2 категории надежности, а электроприемников систем оборотного водоснабжения, аварийной вентиляции, аварийного освещения, обеспечения контрольно-измерительных приборов и автоматики сжатым воздухом, систем противопожарной защиты - не ниже 1 категории",
        ],
      },
      {
        code: "20116134",
        text: "Какое допускается содержание пыли и паров растворителя в воздухе, подаваемом в системы приточной вентиляции, объектов производств растительных масел?",
        answers: [
          "Не должно превышать 35 % предельно допустимой концентрации их в воздухе рабочей зоны производственных помещений",
          "Не должно превышать 30 % предельно допустимой концентрации их в воздухе рабочей зоны производственных помещений",
          "Не допускается содержание пыли и паров в воздухе, подаваемом в системы приточной вентиляции",
          "Регулируется внутренними документами организации",
        ],
        correctAnswers: [
          "Не должно превышать 30 % предельно допустимой концентрации их в воздухе рабочей зоны производственных помещений",
        ],
      },
      {
        code: "20116135",
        text: "Кем утверждается периодичность замеров температуры хранящихся веществ (масличного сырья, шрота)?",
        answers: [
          "Производственной организацией",
          "Организацией, эксплуатирующей опасный производственный объект",
          "Организацией, специализирующейся на разработке подобной документации",
          "Комиссией организации, эксплуатирующей опасный производственный объект, под председательством инспектора территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "Организацией, эксплуатирующей опасный производственный объект",
        ],
      },
      {
        code: "20116136",
        text: "В каких случаях допускается помещения управления и анализаторные помещения пристраивать к зданиям с взрывоопасными зонами?",
        answers: [
          "При соответствующем обосновании проектной организацией",
          "При размещении в этих зданиях парового или водяного отопления",
          "При прохождении через помещения управления трубопроводов, воздуховодов, кабелей и т. п.",
          "При размещении в них оборудования и других устройств, не связанных с системой управления технологическим процессом",
        ],
        correctAnswers: [
          "При соответствующем обосновании проектной организацией",
        ],
      },
      {
        code: "20116137",
        text: "Какие из перечисленных требований, которым должны удовлетворять помещения управления объектов производств растительных масел, указаны неверно?",
        answers: [
          "Помещения должны иметь воздушное отопление и установки для кондиционирования воздуха",
          "Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и должен соответствовать требованиям к эксплуатации устанавливаемого оборудования и санитарным нормам",
          "Полы в помещениях управления должны быть холодными и электропроводными",
          "Средства или системы пожаротушения должны соответствовать требованиям нормативно-технической документации",
        ],
        correctAnswers: [
          "Полы в помещениях управления должны быть холодными и электропроводными",
        ],
      },
      {
        code: "20116138",
        text: "Какие из требований к анализаторным помещениям объектов производств растительных масел указаны неверно?",
        answers: [
          "Должны иметь предохраняющие конструкции",
          "Должна быть предусмотрена аварийная вентиляция, которая автоматически включается в случае, когда концентрация обращающихся веществ в воздухе помещения достигает 5 % нижнего концентрационного предела распространения пламени",
          "Объем анализаторного помещения и технические характеристики систем вентиляции определяются исходя из условий, при которых в помещении в течение 1 ч должна быть исключена возможность образования взрывоопасной концентрации анализируемых продуктов",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Должна быть предусмотрена аварийная вентиляция, которая автоматически включается в случае, когда концентрация обращающихся веществ в воздухе помещения достигает 5 % нижнего концентрационного предела распространения пламени",
        ],
      },
      {
        code: "20116139",
        text: "При какой минимальной концентрации паров растворителя в воздухе должна автоматически включаться аварийная вентиляция?",
        answers: [
          "10 % от нижнего концентрационного предела распространения пламени",
          "5 % от нижнего концентрационного предела распространения пламени",
          "8 % от нижнего концентрационного предела распространения пламени",
          "15 % от нижнего концентрационного предела распространения пламени",
        ],
        correctAnswers: [
          "10 % от нижнего концентрационного предела распространения пламени",
        ],
      },
      {
        code: "20116140",
        text: 'Кто подписывается в технологическом регламенте под грифом "согласовано"? Укажите все правильные ответы.',
        answers: [
          "Начальник производственно-технического (технического) отдела организации",
          "Начальник производства",
          "Главный метролог организации",
          "Главный механик и главный энергетик организации",
        ],
        correctAnswers: [
          "Главный метролог организации",
          "Главный механик и главный энергетик организации",
        ],
      },
      {
        code: "20116141",
        text: "Какое из перечисленных пылеулавливающих оборудований объектов производств растительных масел допускается размещать вне зданий?",
        answers: [
          "Пылеулавливающее оборудование для очистки воздуха от масличной пыли после семеновеечных машин",
          "Пылеулавливающее оборудование для очистки воздуха от минеральной пыли после сепараторов",
          "Пылеулавливающее оборудование для мокрой очистки пылевоздушной смеси",
          "Пылеулавливающее оборудование для сухой очистки воздуха",
        ],
        correctAnswers: [
          "Пылеулавливающее оборудование для мокрой очистки пылевоздушной смеси",
        ],
      },
      {
        code: "20116142",
        text: "В течение какого минимального времени буферные емкости (реципиенты) должны обеспечивать питание сжатым воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров?",
        answers: ["15 мин", "2 ч", "1 ч", "30 мин"],
        correctAnswers: ["1 ч"],
      },
      {
        code: "20116143",
        text: "Какие помещения объектов производств растительных масел относятся к неотапливаемым помещениям?",
        answers: [
          "Только здания элеваторов масличного сырья и шрота",
          "Только здания складов",
          "Только надсилосные и подсилосные этажи",
          "Все перечисленные помещения",
        ],
        correctAnswers: ["Все перечисленные помещения"],
      },
      {
        code: "20116144",
        text: "Какие параметры экстракционной установки, требующие остановки цеха, указаны верно?",
        answers: [
          "Падение давления и температуры пара на коллекторах",
          "Падение давления сжатого воздуха ниже 0,5 МПа (5 кгс/см²) для питания систем контроля и управления системы противоаварийной защиты",
          "Повышение концентрации паров растворителя в воздушной среде цеха до 30 % от нижнего концентрационного предела распространения пламени",
          "Повышение давления воды в циркуляционной системе",
        ],
        correctAnswers: ["Падение давления и температуры пара на коллекторах"],
      },
      {
        code: "20116145",
        text: "В каком случае допускается постоянное пребывание людей в анализаторных помещениях объектов производств растительных масел?",
        answers: [
          "Если ограничители расхода и давления на пробоотборных устройствах размещаются вне анализаторного помещения",
          "Если анализаторы имеют защиту от воспламенения и взрыва по газовым линиям",
          "Если анализаторные помещения имеют предохраняющие конструкции",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20116146",
        text: "Какие из перечисленных требований к датчикам и пробоотборным устройствам анализаторов объектов производств растительных масел указаны верно?",
        answers: [
          "Для экстракторов карусельного типа должен устанавливаться датчик на пары растворителя в загрузочном бункере экстрактора с уставками срабатывания 3 г/м³; 3-4 г/м³; 4 г/м³",
          "В случае отсутствия загрузочного бункера места установки, количество датчиков и уставки определяются техническим руководителем организации",
          "Для экстракторов карусельного типа должен устанавливаться датчик на пары растворителя в разгрузочном винтовом конвейере шрота с уставками срабатывания 5 г/м³; 5…10 г/м³; 10 г/м³",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
    ],

    20117: [
      {
        code: "20117000",
        text: "Какие требования предъявляются к специфическим техническим требованиям для отдельных технологических процессов, устанавливаемых стандартами и иными внутренними документами организаций?",
        answers: [
          "Не должны противоречить требованиям типовых отраслевых инструкций",
          "Не должны противоречить требованиям технического регламента",
          "Не должны противоречить требованиям Правил безопасности химически опасных производственных объектов",
          "Не должны противоречить требованиям технической (эксплуатационной) документации организации - изготовителя технологического оборудования, применяемого для отдельных технологических процессов",
        ],
        correctAnswers: [
          "Не должны противоречить требованиям Правил безопасности химически опасных производственных объектов",
        ],
      },
      {
        code: "20117001",
        text: "В каком случае допускается эксплуатация воздухоразделительных технических устройств, применяемых на опасных производственных объектах производств продуктов разделения воздуха?",
        answers: [
          "Если концентрация и состав взрывоопасных примесей в перерабатываемом воздухе не превышают 5 % нормы, установленной разработчиком такого технического устройства",
          "Если концентрация и состав взрывоопасных примесей в перерабатываемом воздухе не превышают нормы, установленной разработчиком такого технического устройства",
          "Если концентрация и состав взрывоопасных примесей в перерабатываемом воздухе не превышают нормы, установленной технологическим процессом",
          "Если концентрация и состав взрывоопасных примесей в перерабатываемом воздухе не превышают 3 % нормы, установленной разработчиком такого технического устройства",
        ],
        correctAnswers: [
          "Если концентрация и состав взрывоопасных примесей в перерабатываемом воздухе не превышают нормы, установленной разработчиком такого технического устройства",
        ],
      },
      {
        code: "20117002",
        text: "Что следует выполнить организации, эксплуатирующей объекты производства продуктов разделения воздуха, в случае, когда в процессе эксплуатации технического устройства степень загрязнения воздуха превысит допустимую норму?",
        answers: [
          "Следует остановить работу технического устройства",
          "Следует выполнить мероприятия по оснащению такого технического устройства дополнительными средствами очистки воздуха, обеспечивающими безопасность его дальнейшей работы",
          "Следует продолжить работу с проведением периодического мониторинга степени загрязнения перерабатываемого воздуха",
          "Следует заменить или самостоятельно модернизировать техническое устройство",
        ],
        correctAnswers: [
          "Следует выполнить мероприятия по оснащению такого технического устройства дополнительными средствами очистки воздуха, обеспечивающими безопасность его дальнейшей работы",
        ],
      },
      {
        code: "20117003",
        text: "Чем определяется размещение на объектах производства продуктов разделения воздуха мест воздухозабора относительно производств, являющихся источниками загрязнения, а также мест переработки (сжигания) бытовых и промышленных отходов и других возможных источников загрязнения воздуха?",
        answers: [
          "Требованиями федеральных норм и правил в области промышленной безопасности",
          "Требованиями технической документации заводов - изготовителей оборудования",
          "Нормами проектирования объектов и результатами мониторинга",
          "Требованиями распорядительного акта (приказа) организации",
        ],
        correctAnswers: [
          "Нормами проектирования объектов и результатами мониторинга",
        ],
      },
      {
        code: "20117004",
        text: "В соответствии с какими документами производится пуск объектов производства продуктов разделения воздуха (воздухоразделительных установок и криогенных комплексов)?",
        answers: [
          "В соответствии с технологическим регламентом, разработанным на основании требований технологического регламента и Правил безопасности химически опасных производственных объектов",
          "В соответствии с технической документацией разработчика технического устройства и с Правилами безопасности химически опасных производственных объектов",
          "Только в соответствии с технологическим регламентом, разработанным на основании требований проектной документации",
          'В соответствии с Федеральным законом от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов" и с технической документацией заводов - изготовителей оборудования',
        ],
        correctAnswers: [
          "В соответствии с технологическим регламентом, разработанным на основании требований технологического регламента и Правил безопасности химически опасных производственных объектов",
        ],
      },
      {
        code: "20117005",
        text: "Что необходимо делать для обеспечения взрывобезопасной эксплуатации воздухоразделительных установок организации, эксплуатирующей объекты производства продуктов разделения воздуха?",
        answers: [
          "Проводить регулярный осмотр состояния установок в соответствии с требованиями технологического регламента",
          "Допускать к эксплуатации установок обученный и аттестованный персонал",
          "Проводить испытания установок на герметичность",
          "Производить контроль содержания взрывоопасных примесей в технологических потоках установки в соответствии с требованиями технологического регламента",
        ],
        correctAnswers: [
          "Производить контроль содержания взрывоопасных примесей в технологических потоках установки в соответствии с требованиями технологического регламента",
        ],
      },
      {
        code: "20117006",
        text: "Какая концентрация взрывоопасных примесей допускается в жидком кислороде?",
        answers: [
          "Не превышающая норм, установленных производственными инструкциями",
          "Не превышающая пределов, установленных инструкцией по безопасному проведению взрывоопасных работ",
          "Не превышающая пределов, установленных разработчиком воздухоразделительной установки для различных стадий технологического процесса",
          "Не превышающая 17 % нормы, установленной разработчиком воздухоразделительной установки",
        ],
        correctAnswers: [
          "Не превышающая пределов, установленных разработчиком воздухоразделительной установки для различных стадий технологического процесса",
        ],
      },
      {
        code: "20117007",
        text: "Что должна обеспечивать система контроля и автоматики для обеспечения взрывобезопасности воздухоразделительной установки?",
        answers: [
          "Постоянный контроль количества взрывоопасных примесей в жидком кислороде",
          "Отключение установки при отклонении технологических параметров, определенных производственной инструкцией",
          "Постоянный контроль количества сливаемого жидкого криогенного продукта",
          "Автоматический отбор жидкого воздуха или жидкого кислорода для анализа",
        ],
        correctAnswers: [
          "Постоянный контроль количества сливаемого жидкого криогенного продукта",
        ],
      },
      {
        code: "20117008",
        text: "Каким документом определяется порядок отключения электроприводов арматуры на ремонт или ревизию на работающей воздухораспределительной установке?",
        answers: [
          "Инструкцией по эксплуатации электрооборудования и технологической инструкцией с учетом технической документации разработчика устройства",
          "Технологическим регламентом",
          "Инструкцией по проведению ремонта оборудования и руководством по эксплуатации на арматуру",
          "Локальной инструкцией по организации с учетом технологического процесса и руководством по эксплуатации на арматуру",
        ],
        correctAnswers: ["Технологическим регламентом"],
      },
      {
        code: "20117009",
        text: "Когда производится ремонт пневмоприводов арматуры?",
        answers: [
          "В перерывах между сменами",
          "В любое время в случае необходимости",
          "По графику в соответствии с руководством по эксплуатации на арматуру",
          "Только во время остановок",
        ],
        correctAnswers: ["Только во время остановок"],
      },
      {
        code: "20117010",
        text: "Какое основное требование предъявляется к осуществлению ремонта электроприводов арматуры подачи воздуха (азота) в турбодетандеры, блоки комплексной очистки, подачи воздуха в воздухоразделительные установки, выдачи продукционных кислорода и азота, а также арматуры азотно-водяного охлаждения и регулирующих клапанов?",
        answers: [
          "Ремонт осуществляется во время остановок арматуры",
          "В соответствии с руководством по эксплуатации на арматуру",
          "В соответствии с локальным нормативным актом (приказом или распоряжением) организации в зависимости от вида ремонта",
          "Ремонт осуществляется по графику",
        ],
        correctAnswers: [
          "В соответствии с руководством по эксплуатации на арматуру",
        ],
      },
      {
        code: "20117011",
        text: "Что должна обеспечивать система контроля и автоматизации воздухораспределительной установки при нарушении работы систем и устройств установки и отклонении технологических параметров, определенных технологическим регламентом?",
        answers: [
          "Звуковую сигнализацию при отклонении технологических параметров, определенных справочно-нормативной документацией",
          "Автоматическое отключение устройств установки при отклонении технологических параметров, определенных технологическим регламентом",
          "Световую и звуковую сигнализации при нарушении работы систем и устройств установки и отклонении технологических параметров, определенных технологическим регламентом",
          "Автоматическое включение резервных (дополнительных) модернизированных устройств, обеспечивающих безопасность дальнейшей работы систем и устройств, задействованных в технологическом процессе",
        ],
        correctAnswers: [
          "Световую и звуковую сигнализации при нарушении работы систем и устройств установки и отклонении технологических параметров, определенных технологическим регламентом",
        ],
      },
      {
        code: "20117012",
        text: "Какими автоматизированными устройствами необходимо оснащать воздухораспределительные установки, вырабатывающие газообразный азот?",
        answers: [
          "Устройствами, исключающими подачу продукционного азота с содержанием кислорода, превышающим величины, определенные проектной документацией (документацией) более чем на 5 %",
          "Устройствами, которые должны обеспечивать световую и звуковую сигнализации при подаче продукционного азота с содержанием кислорода, превышающим величины, определенные проектной документацией (документацией)",
          "Автоматизированными устройствами, допускающими подачу продукционного азота с содержанием кислорода, превышающим величины, определенные проектной документацией (документацией), не более чем на 10 %",
          "Автоматизированными устройствами, исключающими подачу продукционного азота с содержанием кислорода, превышающим величины, определенные проектной документацией (документацией)",
        ],
        correctAnswers: [
          "Автоматизированными устройствами, исключающими подачу продукционного азота с содержанием кислорода, превышающим величины, определенные проектной документацией (документацией)",
        ],
      },
      {
        code: "20117013",
        text: "Какими автоматическими устройствами необходимо оснащать системы азотно-водяного охлаждения воздухораспределительных установок?",
        answers: [
          "Отключающими подачу воды при повышении допустимого уровня воды в воздушном скруббере более чем на 5 %",
          "Отключающими подачу воды при повышении допустимого уровня воды в воздушном скруббере",
          "Отключающими подачу воды при понижении допустимого уровня воды в воздушном скруббере более чем на 5 %",
          "Исключающими подачу продукционного азота",
        ],
        correctAnswers: [
          "Отключающими подачу воды при повышении допустимого уровня воды в воздушном скруббере",
        ],
      },
      {
        code: "20117014",
        text: "Как необходимо производить слив жидких криогенных продуктов из аппаратов при кратковременных остановках воздухораспределительных установок по производственной необходимости (до 8 часов)?",
        answers: [
          "В порядке, предусмотренном стандартом организации",
          "В порядке, предусмотренном отраслевым стандартом",
          "В порядке, предусмотренном локальным нормативным актом организации",
          "В порядке, предусмотренном технологическим регламентом",
        ],
        correctAnswers: [
          "В порядке, предусмотренном технологическим регламентом",
        ],
      },
      {
        code: "20117015",
        text: "Какие требования безопасности необходимо выполнить при остановках воздухораспределительных установок продолжительностью более 8 часов?",
        answers: [
          "Кубовую жидкость необходимо слить из адсорберов, а адсорбент - заменить",
          "Жидкий кислород и кубовую жидкость из адсорберов необходимо слить, а адсорбент - подвергнуть регенерации",
          "Жидкий кислород и кубовую жидкость необходимо оставить в адсорберах, а их слив - не допускать",
          "Жидкий кислород необходимо слить, а кубовую жидкость - оставить в адсорберах",
        ],
        correctAnswers: [
          "Жидкий кислород и кубовую жидкость из адсорберов необходимо слить, а адсорбент - подвергнуть регенерации",
        ],
      },
      {
        code: "20117016",
        text: "С какой скоростью необходимо обеспечивать равномерное охлаждение тепломассообменных аппаратов в период остановки воздухораспределительной установки?",
        answers: [
          "Со скоростью, определенной в технологической инструкции",
          "Со скоростью, определенной в документации завода-изготовителя",
          "Со скоростью, определенной в инструкции по ремонту оборудования",
          "Со скоростью, определенной в справочно-нормативной документации",
        ],
        correctAnswers: [
          "Со скоростью, определенной в документации завода-изготовителя",
        ],
      },
      {
        code: "20117017",
        text: "Какие требования безопасности предъявляются к пуску воздухораспределительной установки при уровне жидкого кислорода (жидкого воздуха) в основных конденсаторах-испарителях меньше номинального?",
        answers: [
          "Пуск воздухораспределительной установки должен осуществляться в рабочем режиме",
          "Пуск воздухораспределительной установки должен осуществляться в режиме накопления жидкости",
          "Пуск воздухораспределительной установки должен осуществляться в аварийном режиме в присутствии ответственного работника, назначенного руководителем (заместителем руководителя) эксплуатирующей организации",
          "Пуск воздухораспределительной установки должен осуществляться в соответствии с картой технологического процесса",
        ],
        correctAnswers: [
          "Пуск воздухораспределительной установки должен осуществляться в режиме накопления жидкости",
        ],
      },
      {
        code: "20117018",
        text: "Каким документом определяется продолжительность эксплуатации воздухораспределительной установки между двумя полными отогревами установки?",
        answers: [
          "Инструкцией по эксплуатации воздухораспределительной установки",
          "Технологическим регламентом",
          "Положительным заключением экспертизы промышленной безопасности",
          "Техническими условиями завода - изготовителя воздухораспределительной установки",
        ],
        correctAnswers: ["Технологическим регламентом"],
      },
      {
        code: "20117019",
        text: "Какое требование предъявляется к продолжительности непрерывной работы аппаратов воздухораспределительных установок, в которых испаряется жидкий кислород и отогрев которых при работающей установке предусмотрен технологическим процессом?",
        answers: [
          "Не устанавливается при условии исправности аппаратов воздухораспределительных установок и проведения всех видов технических осмотров",
          "Не должна превышать сроков, установленных документацией завода-изготовителя",
          "Не должна превышать сроков, установленных техническими условиями организации, проектирующей установку",
          "Не должна превышать сроков, определенных технологическим регламентом, а также должна учитывать результаты анализов на содержание углеводородов в жидком кислороде",
        ],
        correctAnswers: [
          "Не должна превышать сроков, определенных технологическим регламентом, а также должна учитывать результаты анализов на содержание углеводородов в жидком кислороде",
        ],
      },
      {
        code: "20117020",
        text: "В какие временные интервалы в зависимости от температуры воздуха, выходящего из аппаратов, следует производить отогрев воздухораспределительных установок?",
        answers: [
          "До достижения температуры воздуха, выходящего из аппаратов в течение одного часа, до температуры, определенной технологическим регламентом",
          "До достижения температуры воздуха, выходящего из аппаратов в течение полутора часов, до температуры, определенной технологическим регламентом",
          "До достижения температуры воздуха, выходящего из аппаратов в течение двух часов, до температуры, определенной технологическим регламентом",
          "До достижения температуры воздуха, выходящего из аппаратов в течение получаса, до температуры, определенной технологическим регламентом",
        ],
        correctAnswers: [
          "До достижения температуры воздуха, выходящего из аппаратов в течение двух часов, до температуры, определенной технологическим регламентом",
        ],
      },
      {
        code: "20117021",
        text: "Какие требования предъявляются к испарению жидких криогенных продуктов разделения воздуха, сливаемых из отдельных аппаратов воздухораспределительных установок перед их отогревом?",
        answers: [
          "Испарение производится в специальных испарителях медленного слива, предусмотренных технологическим регламентом",
          "Испарение производится в специальных испарителях медленного слива, предусмотренных проектной документацией отдельно для каждой установки",
          "Испарение производится в устройствах для слива и испарения жидких продуктов разделения воздуха, предусмотренных проектной документацией (документацией) на установки",
          "Испарение производится в специальных испарителях быстрого слива, предусмотренных проектной документацией (документацией) отдельно для каждой установки",
        ],
        correctAnswers: [
          "Испарение производится в специальных испарителях быстрого слива, предусмотренных проектной документацией (документацией) отдельно для каждой установки",
        ],
      },
      {
        code: "20117022",
        text: "В каком случае допускается объединение трубопроводов для слива жидких продуктов из воздухоразделительных установок?",
        answers: [
          "Если производительность воздухоразделительных установок ниже средней",
          "Если на это имеется разрешение территориального органа Ростехнадзора",
          "Ни в каком случае",
          "Если это обосновывается в проектной документации (документации) в каждом конкретном случае",
        ],
        correctAnswers: [
          "Если это обосновывается в проектной документации (документации) в каждом конкретном случае",
        ],
      },
      {
        code: "20117023",
        text: "В соответствии с требованиями какого документа производится эксплуатация технических устройств, входящих в состав воздухоразделительных установок, криогенных комплексов и систем хранения жидких продуктов разделения воздуха?",
        answers: [
          "Инструкции по эксплуатации технических устройств",
          "Технологического регламента",
          "Документации завода - изготовителя технических устройств",
          "Отраслевого стандарта",
        ],
        correctAnswers: ["Технологического регламента"],
      },
      {
        code: "20117024",
        text: "Какие требования предъявляются к температурному режиму работы регенераторов?",
        answers: [
          "Температурный режим должен исключать возможность заноса углеводородов в блок разделения воздуха",
          "Температурный режим должен обеспечивать оптимальную работу регенераторов",
          "Температурный режим должен регулироваться автоматически для исключения колебания температуры за пределами заданного диапазона работы регенераторов",
          "Температурный режим должен поддерживаться в пределах, установленных технологической инструкцией",
        ],
        correctAnswers: [
          "Температурный режим должен исключать возможность заноса углеводородов в блок разделения воздуха",
        ],
      },
      {
        code: "20117025",
        text: "Какие требования безопасности предъявляются к клапанным коробкам регенераторов?",
        answers: [
          "Не допускается попадание жидкого кислорода в клапанные коробки регенераторов. Отсутствие жидкого кислорода в клапанных коробках необходимо контролировать",
          "Отсутствие жидкого кислорода в клапанных коробках необходимо контролировать периодической продувкой клапанных коробок через соответствующие продувочные вентили",
          "Допускается частичное попадание жидкого кислорода в клапанные коробки регенераторов. Максимальный предел присутствия жидкого кислорода в клапанных коробках следует устанавливать в технологической инструкции эксплуатирующей организации",
          "Контроль отсутствия жидкого кислорода в клапанных коробках кислородных регенераторов должен осуществляться ежесуточно продувкой через соответствующую арматуру",
        ],
        correctAnswers: [
          "Не допускается попадание жидкого кислорода в клапанные коробки регенераторов. Отсутствие жидкого кислорода в клапанных коробках необходимо контролировать",
        ],
      },
      {
        code: "20117026",
        text: "Какие требования предъявляются к работе адсорбера воздухораспределительной установки?",
        answers: [
          "Адсорберы должны своевременно заполняться любым адсорбентом, имеющимся в наличии, при условии соблюдения требований к его хранению и регенерации",
          "Адсорберы должны заполняться только адсорбентом, прошедшим периодическую регенерацию согласно технологической инструкции",
          "Адсорберы должны заполняться только адсорбентом, предусмотренным технической документацией разработчика воздухораспределительной установки",
          "Адсорберы должны заполняться только адсорбентом, предусмотренным требованиями технологической инструкции",
        ],
        correctAnswers: [
          "Адсорберы должны заполняться только адсорбентом, предусмотренным технической документацией разработчика воздухораспределительной установки",
        ],
      },
      {
        code: "20117027",
        text: "Что должно контролироваться перед заполнением адсорбера адсорбентом?",
        answers: [
          "Цвет адсорбента и концентрация в нем двуокиси углерода",
          "Концентрация и состав взрывоопасных примесей в перерабатываемом воздухе",
          "Температура, влажность и цвет адсорбента",
          "Влажность и насыпная масса адсорбента",
        ],
        correctAnswers: ["Влажность и насыпная масса адсорбента"],
      },
      {
        code: "20117028",
        text: "Какие требования безопасности следует выполнить перед осмотром внутренних узлов адсорберов блоков комплексной очистки или их ремонтом?",
        answers: [
          "Адсорбент следует выгрузить перед осмотром или ремонтом узлов адсорберов",
          "Должен быть обеспечен надежный и эффективный воздухообмен вентиляционной системы",
          "Адсорбент следует подвергнуть регенерации, а затем продуть воздухом во избежание отравлений выделяющимся азотом",
          "Должна быть обеспечена защита персонала от термических воздействий",
        ],
        correctAnswers: [
          "Адсорбент следует подвергнуть регенерации, а затем продуть воздухом во избежание отравлений выделяющимся азотом",
        ],
      },
      {
        code: "20117029",
        text: "Как следует производить досыпку адсорбентов в адсорберы блоков комплексной очистки?",
        answers: [
          "Следует производить автоматически на работающем блоке разделения воздуха",
          "Следует производить на неработающем блоке разделения воздуха при плановой остановке воздухораспределительной установки по наряду-допуску",
          "Следует производить на неработающем блоке разделения воздуха перед началом смены",
          "Следует производить при плановой остановке воздухоразделительной установки по письменному указанию начальника смены, под наблюдением работника, назначенного в установленном порядке",
        ],
        correctAnswers: [
          "Следует производить на неработающем блоке разделения воздуха при плановой остановке воздухораспределительной установки по наряду-допуску",
        ],
      },
      {
        code: "20117030",
        text: "Что необходимо обеспечивать при эксплуатации блоков комплексной очистки?",
        answers: [
          "Соблюдение технологической инструкции по регенерации адсорбента",
          "Соблюдение рабочих параметров процесса очистки воздуха и температурного режима регенерации и охлаждения адсорбента",
          "Контроль качества применяемого адсорбента",
          "Контроль степени загрязнения воздуха",
        ],
        correctAnswers: [
          "Соблюдение рабочих параметров процесса очистки воздуха и температурного режима регенерации и охлаждения адсорбента",
        ],
      },
      {
        code: "20117031",
        text: "Каким документом определяются сроки проверки состояния адсорбента в блоке комплексной очистки при условии сохранения его работоспособности?",
        answers: [
          "Свидетельством о поверке",
          "Руководством по эксплуатации блока комплексной очистки",
          "Технологическим регламентом",
          "Производственной инструкцией",
        ],
        correctAnswers: ["Технологическим регламентом"],
      },
      {
        code: "20117032",
        text: "В каком случае замена адсорбента должна производиться немедленно?",
        answers: [
          "Если случай определен документацией организации - разработчика оборудования",
          "Если обнаружено загрязнение (замасливание) адсорбента",
          "Если при нормальном режиме регенерации и соблюдении рабочих параметров процесса очистки наблюдается фиксация превышения концентрации двуокиси углерода сверх допустимых норм",
          "Если необходимо произвести пересеивание или досыпку адсорбента",
        ],
        correctAnswers: [
          "Если при нормальном режиме регенерации и соблюдении рабочих параметров процесса очистки наблюдается фиксация превышения концентрации двуокиси углерода сверх допустимых норм",
        ],
      },
      {
        code: "20117033",
        text: "Что необходимо проводить в целях обеспечения эффективной очистки технологических потоков?",
        answers: [
          "Периодическую регенерацию адсорбента в соответствии с технологическим регламентом",
          "Периодическую замену адсорбента в соответствии с технологическим регламентом",
          "Периодическую досыпку адсорбента в соответствии с технологической инструкцией",
          "Периодическое просеивание адсорбента в соответствии с технологическим регламентом",
        ],
        correctAnswers: [
          "Периодическую регенерацию адсорбента в соответствии с технологическим регламентом",
        ],
      },
      {
        code: "20117034",
        text: "В какие сроки производится замена адсорбента?",
        answers: [
          "В сроки, установленные производственной инструкцией разработчика установки",
          "В сроки, установленные технологическим регламентом",
          "В сроки, установленные проектной документацией",
          "В сроки, установленные технологической инструкцией",
        ],
        correctAnswers: ["В сроки, установленные технологическим регламентом"],
      },
      {
        code: "20117035",
        text: "Какие требования безопасности предъявляются на период регенерации адсорбента к эксплуатации блока разделения воздуха при наличии в воздухораспределительной установке только одного адсорбера на потоке кубовой жидкости?",
        answers: [
          "Блок разделения воздуха необходимо останавливать. Не допускать работу таких установок через обводную линию",
          "Блок разделения воздуха необходимо останавливать не менее чем за 10 минут до начала проведения высокотемпературной регенерации адсорбента. Не допускать работу таких установок через обводную линию",
          "Блок разделения воздуха необходимо останавливать не менее чем за 15 минут до начала проведения высокотемпературной регенерации адсорбента. Не допускать работу таких установок через обводную линию",
          "Блок разделения воздуха необходимо останавливать не менее чем за 5 минут до начала проведения высокотемпературной регенерации адсорбента и предварительного проведения замеров концентрации ацетилена в жидком кислороде. Не допускать работу таких установок через обводную линию",
        ],
        correctAnswers: [
          "Блок разделения воздуха необходимо останавливать. Не допускать работу таких установок через обводную линию",
        ],
      },
      {
        code: "20117036",
        text: "Какие технические требования необходимо обеспечивать конденсаторам-испарителям при работе воздухораспределительных установок?",
        answers: [
          "Проточность конденсаторов-испарителей в соответствии с технологическим регламентом",
          "Оптимальный расход воздуха, перерабатываемого установкой во избежание перехода конденсаторов-испарителей в режим работы без циркуляции",
          "Периодическую промывку жидким кислородом с размещением его остатков в отделителе жидкости",
          "Своевременное обслуживание в соответствии с требованиями производственной инструкции",
        ],
        correctAnswers: [
          "Проточность конденсаторов-испарителей в соответствии с технологическим регламентом",
        ],
      },
      {
        code: "20117037",
        text: "С какой периодичностью необходимо промывать витые конденсаторы-испарители с внутритрубным кипением кислорода в установках, не производящих криптоновый концентрат?",
        answers: [
          "С периодичностью, определяемой машинистом воздухоразделительной установки по результатам ежесменных осмотров, но не реже 1 раза в неделю",
          "С периодичностью, определяемой оператором воздухоразделительной установки по результатам ежесменных осмотров",
          "С периодичностью, установленной локальным нормативным актом (приказом, распоряжением), но не реже 1 раза в неделю",
          "С периодичностью, установленной технологическим регламентом",
        ],
        correctAnswers: [
          "С периодичностью, установленной технологическим регламентом",
        ],
      },
      {
        code: "20117038",
        text: "При каких условиях допускается отключение выносных конденсаторов для планового отогрева?",
        answers: [
          "При отсутствии в расположенных перед ними конденсаторах ацетилена в течение 8 часов",
          "При отсутствии в расположенных перед ними конденсаторах ацетилена в течение 12 часов",
          "При отсутствии в расположенных перед ними конденсаторах ацетилена в течение 3 часов",
          "При отсутствии в расположенных перед ними конденсаторах ацетилена в течение предыдущих суток",
        ],
        correctAnswers: [
          "При отсутствии в расположенных перед ними конденсаторах ацетилена в течение предыдущих суток",
        ],
      },
      {
        code: "20117039",
        text: "Какие требования безопасности предъявляются к эксплуатации криогенных турбодетандеров?",
        answers: [
          "Эксплуатация в соответствии с технологическим регламентом после настройки автоматической системы контроля и управления и системы противопожарной защиты",
          "Эксплуатация в соответствии с технологической инструкцией после настройки автоматической системы управления и системы противопожарной защиты",
          "Эксплуатация в соответствии с инструкцией по эксплуатации оборудования после регулировки системы противоаварийной защиты и настройки системы противопожарной защиты",
          "Эксплуатация в соответствии с технологическим регламентом после настройки автоматической системы контроля и управления и системы противоаварийной защиты",
        ],
        correctAnswers: [
          "Эксплуатация в соответствии с технологическим регламентом после настройки автоматической системы контроля и управления и системы противоаварийной защиты",
        ],
      },
      {
        code: "20117040",
        text: "Какие меры безопасности необходимо предпринять, если при забросе жидкости в турбодетандер или при понижении температуры газа на входе ниже температуры, указанной в технологической инструкции, не сработала автоматическая защита?",
        answers: [
          "Немедленно отключить турбодетандер (прекратить подачу газа в турбодетандер) и продуть трубопроводы до и после турбодетандера",
          "Немедленно отключить турбодетандер (прекратить подачу газа в турбодетандер) и продуть трубопроводы после турбодетандера",
          "Немедленно отключить турбодетандер (прекратить подачу газа в турбодетандер) и продуть трубопроводы до турбодетандера",
          "Немедленно обесточить турбодетандер, перекрыть трубопроводы до турбодетандера и сообщить об инциденте непосредственному руководителю",
        ],
        correctAnswers: [
          "Немедленно отключить турбодетандер (прекратить подачу газа в турбодетандер) и продуть трубопроводы до и после турбодетандера",
        ],
      },
      {
        code: "20117041",
        text: "Какие требования безопасности предъявляются к эксплуатации турбодетандера при обмерзании изоляционного кожуха и привода механизма регулирования производительности?",
        answers: [
          "При обнаружении роста обмерзания изоляционного кожуха и привода механизма регулирования производительности необходимо установить постоянное наблюдение за турбодетандером",
          "При появлении обмерзания изоляционного кожуха и привода механизма регулирования производительности необходимо установить постоянное наблюдение за турбодетандером",
          "Не допускается эксплуатация турбодетандера при росте обмерзания изоляционного кожуха и привода механизма регулирования производительности",
          "Не допускается эксплуатация турбодетандера при появлении прогрессирующего обмерзания изоляционного кожуха, фундамента или конструкций, изготовленных из черных металлов",
        ],
        correctAnswers: [
          "Не допускается эксплуатация турбодетандера при росте обмерзания изоляционного кожуха и привода механизма регулирования производительности",
        ],
      },
      {
        code: "20117042",
        text: "При каких условиях производится отключение мотор-генератора турбодетандера от электропитания?",
        answers: [
          "Только при закрытых отсечных клапанах",
          "Только при закрытой запорной арматуре на трубопроводе подачи газа в турбодетандер",
          "Только при закрытых отсечных клапанах и запорной арматуре на трубопроводе подачи газа в турбодетандер",
        ],
        correctAnswers: [
          "Только при закрытых отсечных клапанах и запорной арматуре на трубопроводе подачи газа в турбодетандер",
        ],
      },
      {
        code: "20117043",
        text: "В каких случаях следует немедленно прекратить подачу газа в турбодетандер?",
        answers: [
          "При пропадании напряжения на мотор-генераторе работающего турбодетандера",
          "При скачке напряжения на мотор-генераторе работающего турбодетандера",
          "При падении напряжения на мотор-генераторе работающего турбодетандера",
          "При низком напряжении на мотор-генераторе работающего турбодетандера",
        ],
        correctAnswers: [
          "При пропадании напряжения на мотор-генераторе работающего турбодетандера",
        ],
      },
      {
        code: "20117044",
        text: "В каких случаях работу насосов жидких продуктов разделения воздуха требуется остановить для ремонта?",
        answers: [
          "В случае появления обмерзания стояночных и динамических уплотнителей и сальников насосов при пропуске газа и если объемная доля кислорода в помещении размещения насосов повысилась до 23 % или уменьшилась до 19 %",
          "В любом случае появления обмерзания стояночных и динамических уплотнителей и сальников насосов при пропуске газа",
          "В случае появления обмерзания стояночных и динамических уплотнителей и сальников насосов при пропуске газа и если объемная доля кислорода в помещении размещения насосов повысилась до 22 % или уменьшилась до 19 %",
          "В случае повышения объемной доли кислорода в помещении размещения насосов до 23 % или уменьшении до 20 %",
        ],
        correctAnswers: [
          "В случае появления обмерзания стояночных и динамических уплотнителей и сальников насосов при пропуске газа и если объемная доля кислорода в помещении размещения насосов повысилась до 23 % или уменьшилась до 19 %",
        ],
      },
      {
        code: "20117045",
        text: "Какие требования, установленные Правилами безопасности химически опасных производственных объектов к адсорбенту в адсорбционных блоках осушки, указаны неверно?",
        answers: [
          "При загрязнении адсорбента (замасливании или пожелтении) его необходимо заменить",
          "Состояние адсорбента требуется проверять не реже одного раза в год",
          "В установках, в которых температура сжатия воздуха в любой ступени компрессора выше 433 °К (160 °С), замену адсорбента в блоке осушки производить два раза в год согласно технологическому регламенту",
          "В установках, в которых температура сжатия воздуха в любой ступени компрессора выше 433 °К (160 °С), замену адсорбента в блоке осушки производить один раз в год согласно технологической инструкции",
        ],
        correctAnswers: [
          "В установках, в которых температура сжатия воздуха в любой ступени компрессора выше 433 °К (160 °С), замену адсорбента в блоке осушки производить один раз в год согласно технологической инструкции",
        ],
      },
      {
        code: "20117046",
        text: "Какие требования предъявляются к очистке масла воздуха, выходящего из поршневого детандера?",
        answers: [
          "Очистка выполняется в детандерных фильтрах в соответствии с технологическим регламентом",
          "Очистка выполняется в детандерных фильтрах в соответствии с инструкцией по эксплуатации завода - изготовителя оборудования",
          "Очистка выполняется в основных детандерных фильтрах в соответствии с технологической инструкцией. При загрязнении контрольного фильтра должна быть проверена работа основных фильтров",
          "Очистка выполняется в основных детандерных фильтрах в соответствии с инструкцией по безопасному ведению технологического процесса очистки воздуха",
        ],
        correctAnswers: [
          "Очистка выполняется в детандерных фильтрах в соответствии с технологическим регламентом",
        ],
      },
      {
        code: "20117047",
        text: "Какие требования предъявляются к частоте продувки влагомаслоотделителей поршневых компрессоров при отсутствии автоматической продувки?",
        answers: [
          "Продувка должна производиться через каждые 20 минут",
          "Продувка должна производиться через каждые 30 минут",
          "Продувка должна производиться через каждые 40 минут",
          "Продувка должна производиться через каждые 60 минут",
        ],
        correctAnswers: ["Продувка должна производиться через каждые 30 минут"],
      },
      {
        code: "20117048",
        text: "Может ли быть продолжена работа воздухораспределительной установки с перлитовой изоляцией при обнаружении утечки газообразных или жидких продуктов разделения воздуха во внутриблочном пространстве установки?",
        answers: [
          "Работа установки может быть продолжена при небольших утечках с разрешения технического руководителя, при условии разработки мероприятий, обеспечивающих безопасность персонала, контроля за содержанием количества кислорода в воздухе рабочей зоны",
          "Работа установки может быть продолжена при немедленной замене прокладок в месте утечки",
          "Работа установки может быть продолжена при незначительной утечке и немедленном установлении мест утечек газообразных или жидких продуктов проверкой открытым пламенем",
          "Работа установки должна быть прекращена для устранения утечки",
        ],
        correctAnswers: [
          "Работа установки должна быть прекращена для устранения утечки",
        ],
      },
      {
        code: "20117049",
        text: "Требованиям каких документов должны соответствовать устройство и размещение оборудования с жидкими продуктами разделения воздуха?",
        answers: [
          'Должны соответствовать требованиям Федерального закона "О промышленной безопасности опасных производственных объектов" и требованиям, установленным органами местного самоуправления, на территории которых размещен опасный производственный объект с этим оборудованием',
          "Должны соответствовать проектной документации (документации) и требованиям Правил безопасности химически опасных производственных объектов",
          "Должны соответствовать требованиям Правил по проектированию производств продуктов разделения воздуха",
          "Должны соответствовать технической документации завода-изготовителя, требованиям правил пожарной безопасности, а также всех федеральных норм и правил в области промышленной безопасности, действующих на территории Российской Федерации",
        ],
        correctAnswers: [
          "Должны соответствовать проектной документации (документации) и требованиям Правил безопасности химически опасных производственных объектов",
        ],
      },
      {
        code: "20117050",
        text: "При каком условии и с какой суммарной вместимостью допускается размещать сосуды с жидкими продуктами разделения воздуха в производственных помещениях потребителей продуктов разделения воздуха?",
        answers: [
          "Суммарной вместимостью не более 30 м3 при условии, если указанные сосуды технологически связаны с техническими устройствами, расположенными в данном или соседнем производственном помещении",
          "Суммарной вместимостью не более 15 м3 при условии размещения сосудов в помещениях, отнесенных к категориям В3, В4 по взрывопожарной и пожарной опасности",
          "Суммарной вместимостью не более 10 м3 при условии размещения сосудов в помещениях, отнесенных к категории А по взрывопожарной и пожарной опасности",
          "Суммарной вместимостью не более 10 м3 при условии, если указанные сосуды технологически связаны с техническими устройствами, расположенными в данном производственном помещении",
        ],
        correctAnswers: [
          "Суммарной вместимостью не более 10 м3 при условии, если указанные сосуды технологически связаны с техническими устройствами, расположенными в данном производственном помещении",
        ],
      },
      {
        code: "20117051",
        text: "Какие требования предъявляются к сбросу газообразных продуктов разделения воздуха при наполнении сосудов, размещенных в помещениях?",
        answers: [
          "Сброс производится за пределы здания с соблюдением требований Правил безопасности химически опасных производственных объектов",
          "Сброс производится за пределы здания при пропускной способности трубопроводов не более 100 м3/ч",
          "Сброс производится за пределы здания при объединении трубопроводов сбросов от защитных устройств, установленных на разных технических устройствах или участках трубопровода",
          "Сброс производится за пределы здания при соблюдении объемной доли кислорода в воздухе в пределах от 17 % до 22 % в местах возможного нахождения обслуживающего персонала и забора воздуха для вентиляции и технологических нужд",
        ],
        correctAnswers: [
          "Сброс производится за пределы здания с соблюдением требований Правил безопасности химически опасных производственных объектов",
        ],
      },
      {
        code: "20117052",
        text: "Какие из перечисленных требований к сосудам газификаторов и другим стационарным сосудам с жидкими ПРВ, установленным снаружи зданий потребителей и в которых производится непосредственный слив жидких ПРВ из транспортных цистерн, указаны верно?",
        answers: [
          "Сосуды следует располагать около стен, не имеющих проемов на расстоянии не менее 2,2 м от габаритов сосуда",
          "Оконные проемы на расстоянии 7,5 м в каждую сторону от габаритов сосудов не должны иметь открывающихся элементов",
          "Оконные проемы на расстоянии 3,0 м вверх от габаритов сосудов могут иметь открывающиеся элементы",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20117053",
        text: "Какие криогенные сосуды (сосуды Дьюара), предназначенные для хранения или работы с жидкими продуктами разделения воздуха, следует оснащать предохранительными устройствами?",
        answers: [
          "Криогенные сосуды (сосуды Дьюара), предназначенные для хранения или работы с жидкими ПРВ, кроме сосудов вместимостью до 15 л",
          "Криогенные сосуды (сосуды Дьюара), предназначенные для хранения или работы с жидкими ПРВ вместимостью до 14 л",
          "Криогенные сосуды (сосуды Дьюара), предназначенные для хранения или работы с жидкими ПРВ, кроме сосудов вместимостью более 10 л",
          "Криогенные сосуды (сосуды Дьюара), предназначенные для хранения или работы с жидкими продуктами разделения воздуха, кроме сосудов вместимостью до 20 л",
        ],
        correctAnswers: [
          "Криогенные сосуды (сосуды Дьюара), предназначенные для хранения или работы с жидкими ПРВ, кроме сосудов вместимостью до 15 л",
        ],
      },
      {
        code: "20117054",
        text: "При каких условиях жидкий азот в качестве хладоагента может применяться без специальных мер по предупреждению загорания и взрыва?",
        answers: [
          "Если объемная доля кислорода в жидком азоте составляет 33 %",
          "Если объемная доля кислорода в жидком азоте не превышает 32 %",
          "Если объемная доля кислорода в жидком азоте составляет 31 %",
          "Если объемная доля кислорода в жидком азоте не превышает 30 %",
        ],
        correctAnswers: [
          "Если объемная доля кислорода в жидком азоте не превышает 30 %",
        ],
      },
      {
        code: "20117055",
        text: "Какие требования предъявляются к объемной доле кислорода в жидкости при осуществлении работы с жидким азотом в открытых ваннах при проведении замеров в паровой фазе над зеркалом жидкости?",
        answers: [
          "Объемная доля кислорода не должна превышать 10 %",
          "Объемная доля кислорода не должна превышать 19 %",
          "Объемная доля кислорода не должна превышать 23 %",
          "Объемная доля кислорода не должна превышать 30 %",
        ],
        correctAnswers: ["Объемная доля кислорода не должна превышать 10 %"],
      },
      {
        code: "20117056",
        text: "В какие временные интервалы отбираются анализы на содержание кислорода в жидкости при работах с жидким азотом в стационарных ваннах?",
        answers: [
          "Должны производиться каждый час автоматическим газоанализатором",
          "Должны производиться не реже 1 раза в смену автоматическим газоанализатором",
          "Должны производиться не реже 1 раза в 48 часов автоматическим газоанализатором",
          "Должны производиться непрерывно автоматическим газоанализатором",
        ],
        correctAnswers: [
          "Должны производиться непрерывно автоматическим газоанализатором",
        ],
      },
      {
        code: "20117057",
        text: "Какие требования безопасности предъявляются к деталям, подвергаемым охлаждению в ваннах с жидким азотом?",
        answers: [
          "Детали необходимо тщательно очистить от грязи и посторонних примесей",
          "Детали необходимо предварительно обезжирить и высушить",
          "Детали необходимо предварительно вымыть и просушить",
          "Детали необходимо предварительно обезжирить моющим раствором и по возможности обтереть",
        ],
        correctAnswers: [
          "Детали необходимо предварительно обезжирить и высушить",
        ],
      },
      {
        code: "20117058",
        text: "Какие требования к ваннам для охлаждения деталей жидким азотом, установленные Правилами безопасности химически опасных производственных объектов, указаны неверно?",
        answers: [
          "Подлежат отогреву при объемной доле кислорода в азоте более 30 %",
          "Подлежат отогреву при объемной доле кислорода в азоте более 23 %",
          "Подлежат периодическому обезжириванию",
          "Над ваннами необходимо предусматривать местные отсосы",
        ],
        correctAnswers: [
          "Подлежат отогреву при объемной доле кислорода в азоте более 23 %",
        ],
      },
      {
        code: "20117059",
        text: "Какие требования безопасности к баллонам для постоянных потребителей небольших количеств продуктов разделения воздуха (газоанализаторы, хроматографы) указаны неверно?",
        answers: [
          "Шкафы с баллонами должны иметь запорные устройства",
          "У каждого места потребления допускается размещать не более двух баллонов вместимостью 50 л, заполненных продуктами разделения воздуха под давлением до 25 МПа",
          "Расстояние между каждой парой баллонов должно быть не менее 12 м на каждом уровне размещения баллонов",
          "Баллоны следует размещать в металлических шкафах и закреплять",
        ],
        correctAnswers: [
          "У каждого места потребления допускается размещать не более двух баллонов вместимостью 50 л, заполненных продуктами разделения воздуха под давлением до 25 МПа",
        ],
      },
      {
        code: "20117060",
        text: "Что не соответствует требованиям безопасности при транспортировании, перемещении, погрузке и разгрузке баллонов с продуктами разделения воздуха?",
        answers: [
          "Перемещение баллонов грузоподъемными устройствами и транспортными средствами следует осуществлять в соответствии с технологической инструкцией",
          "При погрузке и разгрузке баллонов не допускается их сбрасывание, соударение",
          "Допускается транспортирование баллона с кислородом и баллона с горючим газом к рабочему месту на тележке",
          "Допускается транспортирование и крепление баллонов за колпак",
        ],
        correctAnswers: [
          "Допускается транспортирование и крепление баллонов за колпак",
        ],
      },
      {
        code: "20117061",
        text: "Каким должно быть остаточное давление в кислородном баллоне при его разрядке?",
        answers: [
          "Не ниже 0,05 МПа",
          "Не ниже 0,04 МПа",
          "Не ниже 0,03 МПа",
          "Не ниже 0,01 МПа",
        ],
        correctAnswers: ["Не ниже 0,05 МПа"],
      },
      {
        code: "20117062",
        text: "Что должно исключать остаточное давление в кислородных баллонах при их разрядке?",
        answers: [
          "Возможность перетекания кислорода в системы, заполненные горючими газами",
          "Возможность перетекания кислорода в системы, не связанные с осуществляемым технологическим процессом",
          "Перетечку горючих газов из подключенной системы обратно в баллоны",
          "Перетечку горючих газов из баллонов в подключенную систему",
        ],
        correctAnswers: [
          "Перетечку горючих газов из подключенной системы обратно в баллоны",
        ],
      },
      {
        code: "20117063",
        text: "Какое из требований безопасности к баллонам на объектах потребления продуктов разделения воздуха указано неверно?",
        answers: [
          "Не допускается выполнять ремонт баллонов",
          "Не допускается производить разборку и ремонт вентилей",
          "Не допускается транспортирование баллона с кислородом и баллона с горючим газом на тележке к рабочему месту",
          "Не допускается снижение давления в баллоне с кислородом ниже 0,05 МПа",
        ],
        correctAnswers: [
          "Не допускается транспортирование баллона с кислородом и баллона с горючим газом на тележке к рабочему месту",
        ],
      },
      {
        code: "20117064",
        text: "Какие требования, установленные Правилами безопасности химически опасных производственных объектов к отключающей арматуре для присоединения гибких трубопроводов (шлангам, рукавам) при периодическом отборе продуктов разделения воздуха из трубопроводов, указаны неверно?",
        answers: [
          "Отключающая арматура для присоединения гибких трубопроводов (шланги, рукава) должна размещаться в металлическом шкафу с отверстиями для вентиляции",
          "Шкаф с размещенной отключающей арматурой следует запирать после прекращения работ по периодическому отбору продуктов разделения воздуха из трубопроводов",
          "Сигнально-предупредительные надписи и опознавательная окраска шкафов выполняются в соответствии с проектной документацией (документацией)",
          "Сигнально-предупредительные надписи и опознавательная окраска шкафов выполняются в соответствии с рекомендациями разработчика",
        ],
        correctAnswers: [
          "Сигнально-предупредительные надписи и опознавательная окраска шкафов выполняются в соответствии с рекомендациями разработчика",
        ],
      },
      {
        code: "20117065",
        text: "Допускается ли сброс продуктов разделения воздуха в производственное помещение при продувке технических устройств и коммуникаций?",
        answers: [
          "Допускается в помещениях с постоянно действующей приточно-вытяжной вентиляцией",
          "Допускается в помещениях с работающей вентиляцией, обеспечивающей объемную долю кислорода в воздухе помещений не менее 19 % и не более 23 %",
          "Допускается в помещениях с естественной вентиляцией, если объем помещения в кубических метрах превышает объем сброса продуктов разделения воздуха в литрах не менее чем в 5 раз",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20117066",
        text: "В соответствии с каким документом осуществляется эксплуатация криогенных сосудов и технических устройств различного назначения, оснащенных сосудами Дьюара?",
        answers: [
          "Только в соответствии с технологической инструкцией, учитывающей требования проектной документации (документации)",
          "Только в соответствии с Правилами безопасности опасных химических объектов",
          "Только в соответствии с эксплуатационной документацией разработчика",
          "Со всеми перечисленными документами",
        ],
        correctAnswers: ["Со всеми перечисленными документами"],
      },
      {
        code: "20117067",
        text: "Какие требования устанавливаются Правилами безопасности химически опасных производственных объектов к оснащению помещений, в которых установлены технические устройства, предназначенные для переработки и хранения криптоноксеноновой смеси, криптона и ксенона?",
        answers: [
          "Помещения следует оснащать системой кондиционирования воздуха",
          "Помещения следует оснащать общеобменной системой вентиляции",
          "Помещения следует оснащать постоянно действующей системой приточно-вытяжной вентиляции",
          "Помещения следует оснащать местными отсосами и аварийной системой вентиляции",
        ],
        correctAnswers: [
          "Помещения следует оснащать постоянно действующей системой приточно-вытяжной вентиляции",
        ],
      },
      {
        code: "20117068",
        text: "Какие требования, установленные Правилами безопасности химически опасных производственных объектов к управлению оборудованием, предназначенным для переработки и хранения криптоноксеноновой смеси, криптона и ксенона, указаны неверно?",
        answers: [
          "Должно быть предусмотрено дистанционное управление",
          "Местное управление допускается при наличии защитного экрана между оборудованием и щитом управления",
          "Местное управление допускается при наличии ограждения вокруг оборудования на расстоянии 1 м от него",
          "Местное управление допускается при наличии защитного экрана между оборудованием и щитом управления высотой не менее 1 м",
        ],
        correctAnswers: [
          "Местное управление допускается при наличии защитного экрана между оборудованием и щитом управления высотой не менее 1 м",
        ],
      },
      {
        code: "20117069",
        text: "Какие требования, установленные Правилами безопасности химически опасных производственных объектов к баллонам с криптоноксеноновой смесью, криптоном и ксеноном после их наполнения, необходимо выполнить?",
        answers: [
          "Баллоны с криптоноксеноновой смесью, криптоном и ксеноном после наполнения выдержать не менее 14 суток в помещении наполнительной или в складе баллонов",
          "В суточный срок произвести первичный отбор проб газа для анализа после наполнения баллонов",
          "В суточный срок произвести взвешивание баллонов после их наполнения",
          "Баллоны с криптоноксеноновой смесью, криптоном и ксеноном после наполнения транспортировать к месту их применения на тележках или другом транспорте",
        ],
        correctAnswers: [
          "Баллоны с криптоноксеноновой смесью, криптоном и ксеноном после наполнения выдержать не менее 14 суток в помещении наполнительной или в складе баллонов",
        ],
      },
      {
        code: "20117070",
        text: "Куда производится сброс первичного криптонового концентрата и криптоноксеноновой смеси?",
        answers: [
          "Только в газгольдер первичного криптонового концентрата",
          "В хранилище жидкого первичного концентрата",
          "В газгольдер первичного криптонового концентрата либо на всас компрессора (если газгольдер отсутствует в схеме)",
          "В специальный резервуар транспортной системы",
        ],
        correctAnswers: [
          "В газгольдер первичного криптонового концентрата либо на всас компрессора (если газгольдер отсутствует в схеме)",
        ],
      },
      {
        code: "20117071",
        text: "Какие требования, установленные Правилами безопасности химически опасных производственных объектов к размещению реакторов установок очистки сырого аргона от кислорода методом каталитического гидрирования, указаны неверно?",
        answers: [
          "Реакторы установок очистки сырого аргона от кислорода методом каталитического гидрирования должны быть размещены вне здания",
          "В случаях, обоснованных в проектной документации, допускается установка реактора в отдельном помещении",
          "Установка реактора в отдельном помещении не допускается",
          "Устройство, размещение и эксплуатация установок получения аргона и установок очистки сырого аргона от кислорода методом каталитического гидрирования должны соответствовать проектной документации (документации), требованиям Правил безопасности химически опасных производственных объектов и технологического регламента",
        ],
        correctAnswers: [
          "Установка реактора в отдельном помещении не допускается",
        ],
      },
      {
        code: "20117072",
        text: "До какой величины допускается повышение объемной доли кислорода в аргоне на входе в узел смешения реактора во время пуска установки?",
        answers: [
          "Не более 2,5 %",
          "Не более 8 %",
          "Не более 19 %",
          "Не более 23 %",
        ],
        correctAnswers: ["Не более 8 %"],
      },
      {
        code: "20117073",
        text: "Какие требования, установленные Правилами безопасности химически опасных производственных объектов к технологическому процессу очистки сырого аргона, указаны неверно?",
        answers: [
          "Установку очистки сырого аргона от кислорода необходимо оснащать блокировкой, прекращающей подачу водорода при повышении температуры в реакторе выше допустимой, а также при содержании кислорода в аргоне, поступающем на очистку, более нормативных величин",
          "В технологическом процессе очистки сырого аргона от кислорода должна быть предусмотрена система автоматического измерения содержания кислорода в сыром аргоне, поступающем в реактор",
          "Во время пуска установки допускается повышение объемной доли кислорода в аргоне не более 8 % на входе в узел смешения реактора. При этом расход подаваемого водорода не должен превышать 2,5 % от расхода, поступающего на дистанционное управление",
          "Объемная доля водорода в техническом аргоне, поступающем в воздухоразделительные установки на очистку от азота, не должна превышать 8 %",
        ],
        correctAnswers: [
          "Объемная доля водорода в техническом аргоне, поступающем в воздухоразделительные установки на очистку от азота, не должна превышать 8 %",
        ],
      },
      {
        code: "20117074",
        text: "Какие требования, установленные Правилами безопасности химически опасных производственных объектов к установкам очистки сырого аргона от кислорода и водородным коммуникациям перед подачей водорода в них и после остановки, указаны неверно?",
        answers: [
          "Установки и водородные коммуникации необходимо продувать сырым аргоном или азотом с объемной долей кислорода не более 8 %. Окончание продувки должно быть определено анализом состава продувочного газа",
          "Установку очистки сырого аргона от кислорода необходимо оснащать блокировкой, прекращающей подачу водорода при повышении температуры в реакторе выше допустимой, а также при содержании кислорода в аргоне, поступающем на очистку, более нормативных величин, определенных технологическим регламентом",
          "Объемная доля кислорода в продувочном газе перед пуском не должна превышать 4 %, а водород после остановки должен отсутствовать",
          "Установки и водородные коммуникации необходимо продувать сырым аргоном или азотом с объемной долей кислорода не более 4 %. Окончание продувки должно быть определено анализом состава продувочного газа",
        ],
        correctAnswers: [
          "Установки и водородные коммуникации необходимо продувать сырым аргоном или азотом с объемной долей кислорода не более 8 %. Окончание продувки должно быть определено анализом состава продувочного газа",
        ],
      },
      {
        code: "20117075",
        text: "Какие требования, предъявляемые Правилами безопасности химически опасных производственных объектов к транспортным сосудам для жидких продуктов разделения воздуха при нахождении их в гараже, указаны неверно?",
        answers: [
          "Транспортные сосуды для жидких продуктов разделения воздуха в течение всего времени нахождения их в гараже должны подключаться к сбросным трубопроводам",
          "Сбросные трубопроводы, подключенные к транспортным сосудам, должны выводиться на 2,0 м выше конька крыши гаража",
          "Для каждого сосуда необходимо предусматривать отдельный сброс",
          "Транспортные сосуды для жидких продуктов разделения воздуха в течение всего времени нахождения их в гараже должны подключаться к сбросным трубопроводам, выведенным не более чем на 2,0 м выше конька крыши гаража",
        ],
        correctAnswers: [
          "Транспортные сосуды для жидких продуктов разделения воздуха в течение всего времени нахождения их в гараже должны подключаться к сбросным трубопроводам, выведенным не более чем на 2,0 м выше конька крыши гаража",
        ],
      },
      {
        code: "20117076",
        text: "Какое из перечисленных требований к оборудованию и коммуникациям продуктов разделения воздуха указано неверно?",
        answers: [
          "Холодные участки низкотемпературного оборудования и коммуникаций, находящиеся в зоне обслуживания, необходимо изолировать или ограждать",
          "Хранение, газификацию и транспортирование жидких (криогенных) продуктов разделения воздуха необходимо производить в технических устройствах, специально предназначенных для данных продуктов",
          "На участки трубопроводов жидких продуктов разделения воздуха, заключенные между двумя отключающими органами, должен быть нанесен штамп",
          "Все сосуды, заполняемые жидкими криогенными продуктами разделения воздуха, следует оснащать указателями уровня заполнения",
        ],
        correctAnswers: [
          "На участки трубопроводов жидких продуктов разделения воздуха, заключенные между двумя отключающими органами, должен быть нанесен штамп",
        ],
      },
      {
        code: "20117077",
        text: "Что должно быть предусмотрено на производстве продуктов разделения воздуха для безопасного слива и испарения небольшого количества жидких продуктов разделения воздуха после выполнения анализов жидких продуктов разделения воздуха и других работ?",
        answers: [
          "Предусмотрен отогрев до положительных температур слива жидких продуктов разделения воздуха из аппаратов технических устройств в испаритель, до и после каждого слива, в соответствии с проектной документацией",
          "Предусмотрено устройство для безопасного слива и испарения небольшого количества жидких продуктов разделения воздуха (до 10 литров) из переносных сосудов при отогревах воздухоразделительной установки малой производительности (30 - 300 м3/ч азота или кислорода при нормальных условиях)",
          "Предусмотрен трубопровод слива жидких продуктов разделения воздуха непосредственно в лаборатории с выводом вне помещения",
          "Предусмотрены дренажные коммуникации, обеспечивающие слив продуктов после выполнения анализов жидких продуктов разделения воздуха и других работ",
        ],
        correctAnswers: [
          "Предусмотрено устройство для безопасного слива и испарения небольшого количества жидких продуктов разделения воздуха (до 10 литров) из переносных сосудов при отогревах воздухоразделительной установки малой производительности (30 - 300 м3/ч азота или кислорода при нормальных условиях)",
        ],
      },
      {
        code: "20117078",
        text: "Каким должно быть содержание масла в кислороде, поступающем в компрессоры и газодувки?",
        answers: [
          "Содержание масла не должно превышать 0,02 мг/м3",
          "Содержание масла не должно превышать 0,04 мг/м3",
          "Содержание масла не должно превышать 0,1 мг/м3",
          "Содержание масла не должно превышать 0,2 мг/м3",
        ],
        correctAnswers: ["Содержание масла не должно превышать 0,02 мг/м3"],
      },
      {
        code: "20117079",
        text: "Что необходимо применять для смазки цилиндров поршневых кислородных компрессоров?",
        answers: [
          "Умягченную воду, полученную из заводской системы пароснабжения",
          "Конденсат, полученный из заводской системы пароснабжения",
          "Дистиллят, полученный из воды питьевого качества",
          "Масла, рекомендованные заводом - изготовителем компрессора",
        ],
        correctAnswers: ["Дистиллят, полученный из воды питьевого качества"],
      },
      {
        code: "20117080",
        text: "Какие требования к эксплуатации поршневых компрессоров указаны неверно?",
        answers: [
          "Допускается применять поршневые бескрейцкопфные компрессоры для подачи воздуха на разделение и для сжатия продуктов разделения воздуха",
          "Для смазки поршневой группы компрессоров, подающих воздух в воздухоразделительные установки, необходимо использовать масла, рекомендованные разработчиками компрессора",
          "Не допускается использовать для смазки поршневой группы компрессоров масло, извлеченное из масловлагоотделителей",
          "Работу маслоочищающих сальников требуется контролировать. Попадание машинного масла в цилиндры не допускается",
          "Наличие нагара в клапанных коробках и трубопроводах поршневых компрессоров не допускается",
        ],
        correctAnswers: [
          "Допускается применять поршневые бескрейцкопфные компрессоры для подачи воздуха на разделение и для сжатия продуктов разделения воздуха",
        ],
      },
      {
        code: "20117081",
        text: "Что не соответствует установленным требованиям безопасности при обслуживании компрессора?",
        answers: [
          "Во время работы воздушного центробежного компрессора и воздушного фильтра вход обслуживающего персонала в помещение камеры после воздушного фильтра (камеры чистого воздуха) не допускается",
          "Работы в камере перед фильтром при работающем фильтре и компрессоре необходимо выполнять бригадой не менее чем из трех человек, один из которых является наблюдающим",
          "Камеры, расположенные по ходу воздуха перед фильтром и после него, должны быть закрыты ",
          "Камеры, расположенные по ходу воздуха перед фильтром и после него, должны быть снабжены знаками безопасности, запрещающими вход в камеры",
        ],
        correctAnswers: [
          "Работы в камере перед фильтром при работающем фильтре и компрессоре необходимо выполнять бригадой не менее чем из трех человек, один из которых является наблюдающим",
        ],
      },
      {
        code: "20117082",
        text: "При какой единичной производительности центробежных или поршневых компрессоров, работающих на один коллектор нагнетания кислорода, и давлении кислорода в трубопроводе нагнетания кислорода устанавливают отключающую арматуру?",
        answers: [
          "Более 2500 м3/ч и свыше 2,6 МПа соответственно ",
          "Более 2200 м3/ч и свыше 2 МПа соответственно",
          "Более 2100 м3/ч и свыше 1,8 МПа соответственно ",
          "Более 2000 м3/ч и свыше 1,6 МПа соответственно ",
        ],
        correctAnswers: ["Более 2000 м3/ч и свыше 1,6 МПа соответственно "],
      },
      {
        code: "20117083",
        text: "На каких кислородных центробежных компрессорах необходимо предусматривать его автоматическую остановку при снижении давления газа, подаваемого в лабиринтные уплотнения?",
        answers: [
          "С давлением нагнетания 0,5 МПа",
          "С давлением нагнетания свыше 1,6 МПа",
          "С давлением нагнетания 0,6 МПа",
          "С давлением нагнетания свыше 0,6 МПа",
        ],
        correctAnswers: ["С давлением нагнетания свыше 0,6 МПа"],
      },
      {
        code: "20117084",
        text: "На каких кислородных центробежных компрессорах необходимо предусматривать стационарные устройства, позволяющие производить отбор проб для анализа газа, выходящего из лабиринтных уплотнений компрессора?",
        answers: [
          "На каждом с давлением нагнетания более 0,6 МПа",
          "С давлением нагнетания 0,6 МПа",
          "На одном из компрессоров с давлением нагнетания более 0,6 МПа",
          "На любом из компрессоров с давлением нагнетания 0,6 МПа",
        ],
        correctAnswers: ["На каждом с давлением нагнетания более 0,6 МПа"],
      },
      {
        code: "20117085",
        text: "При каком давлении во всасывающих коммуникациях компрессоров, сжимающих продукты разделения воздуха, данные компрессоры должны автоматически отключаться?",
        answers: [
          "При снижении давления ниже 1,5 кПа",
          "При снижении давления ниже 1,0 кПа",
          "При снижении давления ниже 0,6 кПа",
          "При снижении давления ниже 0,5 кПа",
        ],
        correctAnswers: ["При снижении давления ниже 0,5 кПа"],
      },
      {
        code: "20117086",
        text: "Как производится включение компрессора, отключенного из-за прекращения подачи охлаждающей воды?",
        answers: [
          "Только после его охлаждения и проведения отбора проб для анализа газа",
          "Только после возобновления подачи воды",
          "Только после его охлаждения и возобновления подачи воды",
          "Только после проведения отбора проб для анализа газа и последующего возобновления подачи воды",
        ],
        correctAnswers: [
          "Только после его охлаждения и возобновления подачи воды",
        ],
      },
      {
        code: "20117087",
        text: "С какими параметрами рабочего давления и вместимости вместе с реципиентами необходимо оснащать кислородопроводы автоматически действующей системой защиты, прекращающей поступление кислорода из реципиентов в трубопровод при нарушении его целостности?",
        answers: [
          "С рабочим давлением 1,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 200 м3",
          "С рабочим давлением более 1,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 200 м3",
          "С рабочим давлением более 0,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 200 м3",
          "С рабочим давлением более 1,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 2000 м3",
        ],
        correctAnswers: [
          "С рабочим давлением более 1,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 200 м3",
        ],
      },
      {
        code: "20117088",
        text: "Какими документами должны сопровождаться технологические трубопроводы после монтажа и ремонта?",
        answers: [
          "Документами о стоимости материалов (например, труб, фитингов, арматуры, опор, сварочных материалов) и работ по проведению монтажа и ремонта",
          "Удостоверениями о качестве монтажа с соответствующими приложениями (например, сертификатами на трубы, фитинги, арматуру, опоры, сварочные материалы, копиями удостоверений сварщиков, документами по результатам контроля качества работ)",
          "Лицензиями и другими учредительными документами организации, проводившей монтаж и ремонт",
          "Разрешениями организации, проводившей монтаж и ремонт, на проведение этих видов работ",
        ],
        correctAnswers: [
          "Удостоверениями о качестве монтажа с соответствующими приложениями (например, сертификатами на трубы, фитинги, арматуру, опоры, сварочные материалы, копиями удостоверений сварщиков, документами по результатам контроля качества работ)",
        ],
      },
      {
        code: "20117089",
        text: "Где необходимо устанавливать фильтры на кислородопроводах, изготовленных из углеродистых или низколегированных сталей, работающих под давлением более 1,6 МПа?",
        answers: [
          "На вводах трубопроводов кислорода в здание",
          "Только перед регулирующей арматурой",
          "По ходу кислорода перед регулирующей арматурой, запорной арматурой при длине трубопроводов более 250 м",
          "Перед регулирующей и запорной арматурой при длине трубопроводов более 350 м",
        ],
        correctAnswers: [
          "По ходу кислорода перед регулирующей арматурой, запорной арматурой при длине трубопроводов более 250 м",
        ],
      },
      {
        code: "20117090",
        text: "Какие из случаев, предусматривающих дистанционное управление арматурой технологических трубопроводов, указаны неверно?",
        answers: [
          "Если арматура включается в систему автоматического регулирования или управления",
          "Для включения резервных линий регулирования и резервных источников снабжения продуктов разделения воздуха",
          "На вводах трубопроводов кислорода и азота в здание и выходе из здания при расходах более 5000 м3/ч",
          "На трубопроводах кислорода при давлении менее 1,6 МПа и диаметром 100 мм и менее",
          "Если дистанционное управление арматурой необходимо по условиям безопасности",
        ],
        correctAnswers: [
          "На трубопроводах кислорода при давлении менее 1,6 МПа и диаметром 100 мм и менее",
        ],
      },
      {
        code: "20117091",
        text: "В каком случае следует устанавливать защитные экраны для защиты персонала при возгорании арматуры?",
        answers: [
          "Если дистанционно управляемая запорная и регулирующая арматура, установленная на трубопроводе кислорода с давлением выше 0,6 МПа, расположена на расстоянии менее 4,0 м от рабочих мест",
          "Если дистанционно управляемая запорная и регулирующая арматура, установленная на трубопроводе кислорода с давлением выше 1,6 МПа, расположена на расстоянии менее 3,0 м от рабочих мест",
          "Если дистанционно управляемая запорная и регулирующая арматура, установленная на трубопроводе кислорода с давлением выше 1,6 МПа, расположена на расстоянии менее 5,0 м от рабочих мест",
          "Если дистанционно управляемая запорная и регулирующая арматура, установленная на трубопроводе кислорода с давлением выше 0,6 МПа, расположена на расстоянии менее 3,0 м от рабочих мест",
        ],
        correctAnswers: [
          "Если дистанционно управляемая запорная и регулирующая арматура, установленная на трубопроводе кислорода с давлением выше 1,6 МПа, расположена на расстоянии менее 3,0 м от рабочих мест",
        ],
      },
      {
        code: "20117092",
        text: "Какое содержание масел в воде, используемой для гидроиспытаний кислородопроводов, установлено Правилами безопасности химически опасных производственных объектов?",
        answers: [
          "Не должно превышать 15,0 мг/л",
          "Не должно превышать 10,0 мг/л",
          "Не должно превышать 5,0 мг/л",
          "Не должно превышать 0,5 мг/л",
        ],
        correctAnswers: ["Не должно превышать 5,0 мг/л"],
      },
      {
        code: "20117093",
        text: "С какой пропускной способностью трубопроводы сброса продуктов разделения воздуха от предохранительных клапанов и других защитных устройств необходимо выводить за пределы здания?",
        answers: [
          "С пропускной способностью 100 м3/ч",
          "С пропускной способностью более 100 м3/ч",
          "С пропускной способностью более 50 м3/ч",
          "С пропускной способностью более 10 м3/ч",
        ],
        correctAnswers: ["С пропускной способностью более 100 м3/ч"],
      },
      {
        code: "20117094",
        text: "Какую объемную долю кислорода в воздухе в местах возможного нахождения обслуживающего персонала и забора воздуха для вентиляции и технологических нужд должны обеспечить конструкция и размещение устройств сброса в атмосферу азота и кислорода?",
        answers: [
          "В пределах от 19 % до 23 %",
          "Не менее 18 %",
          "Не более 24 %",
          "В пределах от 17 % до 22 %",
        ],
        correctAnswers: ["В пределах от 19 % до 23 %"],
      },
      {
        code: "20117095",
        text: "Какие требования, установленные Правилами безопасности химически опасных производственных объектов к отогреву трубопроводной арматуры, указаны неверно?",
        answers: [
          "Отогрев трубопроводной арматуры производится снаружи горячим воздухом",
          "Отогрев трубопроводной арматуры производится снаружи горячим паром",
          "Отогрев трубопроводной арматуры производится снаружи горячей водой",
          "Отогрев трубопроводной арматуры производится снаружи открытым пламенем",
        ],
        correctAnswers: [
          "Отогрев трубопроводной арматуры производится снаружи открытым пламенем",
        ],
      },
      {
        code: "20117096",
        text: "При какой концентрации масла в кислороде воздухоразделительная установка должна подвергаться обезжириванию?",
        answers: [
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной тремя последовательно проведенными анализами",
          "При достижении концентрации масла в жидком кислороде 0,20 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами",
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм, подтвержденной тремя последовательно проведенными анализами",
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами",
        ],
        correctAnswers: [
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами",
        ],
      },
      {
        code: "20117097",
        text: "При выполнении каких условий обезжиривание ВРУ в сборе после монтажа или капитального ремонта может не производиться? Укажите все правильные ответы.",
        answers: [
          "Если все детали, узлы трубопроводов и инструмент, применявшийся при работах, были предварительно очищены от консервирующей смазки и обезжирены",
          "Если работы проводились в условиях, исключающих загрязнение внутренних поверхностей оборудования жировыми веществами",
          "Обезжиривание необходимо производить в любом случае",
          "Если концентрация масла в жидком кислороде достигает 0,40 мг/дм и выше, что подтверждается 5 последовательно проведенными анализами",
        ],
        correctAnswers: [
          "Если все детали, узлы трубопроводов и инструмент, применявшийся при работах, были предварительно очищены от консервирующей смазки и обезжирены",
          "Если работы проводились в условиях, исключающих загрязнение внутренних поверхностей оборудования жировыми веществами",
        ],
      },
      {
        code: "20117098",
        text: "Какие аппараты и коммуникации не подлежат обезжириванию в процессе эксплуатации?",
        answers: [
          "Аппараты и коммуникации на потоке газообразного кислорода высокого давления",
          "Аппараты и коммуникации на потоке воздуха от поршневого детандера и от дожимающего поршневого компрессора",
          "Воздушные секции теплообменников",
          "Средства измерений общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40 % и давлением до 1,6 МПа",
        ],
        correctAnswers: [
          "Средства измерений общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40 % и давлением до 1,6 МПа",
        ],
      },
      {
        code: "20117099",
        text: "Какое оборудование подлежит обезжириванию?",
        answers: [
          "Средства измерений, работающие при давлении до 0,6 МПа, конструкция которых позволяет устанавливать их в положении, обеспечивающем свободное стекание масла с внутренних поверхностей, соприкасающихся с кислородом",
          "Средства измерений общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40 % и давлением до 1,6 МПа",
          "Аппараты и коммуникации на потоке газообразного кислорода высокого давления",
          "Арматура перед ее монтажом в целой упаковке, предназначенная для работы с кислородом, обезжиривание которой было проведено на заводе-изготовителе и подтверждено сопроводительными документами или соответствующим клеймением",
        ],
        correctAnswers: [
          "Аппараты и коммуникации на потоке газообразного кислорода высокого давления",
        ],
      },
      {
        code: "20117100",
        text: "Какие требования, установленные Правилами безопасности химически опасных производственных объектов, которые должны соблюдаться при обоснованной необходимости проведения обезжиривания растворителями, указаны неверно?",
        answers: [
          "Перелив растворителей из одного сосуда в другой допускается только закрытым способом при наличии у работников средств индивидуальной защиты",
          "Тару из-под растворителей необходимо плотно закрывать и хранить только в предназначенном для этого помещении или на открытом воздухе",
          "При неработающей вентиляции случайно пролитый растворитель должен быть немедленно убран с помощью сухих материалов (например, опилки, песок)",
          "Специальные помещения, в которых проводится обезжиривание и хранятся растворители, необходимо оснащать постоянно действующей приточно-вытяжной вентиляцией",
        ],
        correctAnswers: [
          "При неработающей вентиляции случайно пролитый растворитель должен быть немедленно убран с помощью сухих материалов (например, опилки, песок)",
        ],
      },
      {
        code: "20117101",
        text: "Какие требования безопасности, установленные Правилами безопасности химически опасных производственных объектов к работам с перлитовым песком, указаны неверно?",
        answers: [
          "Ремонтные работы в отсеках технических устройств, заполняемых перлитовым песком, а также в перлитохранилищах должны производиться после полного удаления перлитового песка",
          "Допускается проведение ремонтных работ без полного удаления перлитового песка при наличии соответствующих обоснований в проектной документации",
          "Допускается выгрузка перлитового песка из отсеков блока разделения воздуха в открытые сосуды или в помещение машинного зала",
          "Перегрузка перлитового песка из хранилищ в блоки разделения воздуха и обратно должна осуществляться с использованием системы пневмотранспорта, предусмотренной проектом",
        ],
        correctAnswers: [
          "Допускается выгрузка перлитового песка из отсеков блока разделения воздуха в открытые сосуды или в помещение машинного зала",
        ],
      },
      {
        code: "20117102",
        text: "Какие требования безопасности, установленные Правилами безопасности химически опасных производственных объектов к проведению ремонта в отсеках клапанных коробок (обратноповоротных клапанов) регенераторов блока разделения воздуха, указаны неверно?",
        answers: [
          "Вход в отсеки для ремонта клапанов разрешается только после снятия давления из аппаратов блока",
          "Вход в отсеки для ремонта клапанов разрешается только после местного отогрева отсеков клапанных коробок (обратноповоротных клапанов)",
          "Вход в отсеки для ремонта клапанов разрешается без сброса избыточного давления из аппаратов блока по наряду-допуску",
          "Объемная доля кислорода внутри кожуха блока разделения воздуха должна составлять 19 - 23 %",
        ],
        correctAnswers: [
          "Вход в отсеки для ремонта клапанов разрешается без сброса избыточного давления из аппаратов блока по наряду-допуску",
        ],
      },
      {
        code: "20117103",
        text: "Какой отличительный знак должен быть у инструмента, предназначенного для разборки и ремонта узлов, работающих в кислородной среде?",
        answers: [
          "Инструмент должен иметь маркировку на рукоятке в виде зеленого круга",
          "Инструмент должен иметь отличительную желтую полосу",
          "Инструмент должен иметь отличительную голубую полосу",
          "Инструмент должен иметь отличительную зеленую полосу",
        ],
        correctAnswers: [
          "Инструмент должен иметь отличительную голубую полосу",
        ],
      },
      {
        code: "20117104",
        text: "Какие ремонтные работы выполняются по наряду-допуску?",
        answers: [
          "Ремонтные работы на низкотемпературных технических устройствах без их полного отогрева",
          "Ремонтные работы на оборудовании, отключенном от работающей воздухоразделительной установки",
          "Ремонтные работы на участке трубопровода, отключенном от работающей воздухоразделительной установки",
          "Все перечисленные работы",
        ],
        correctAnswers: ["Все перечисленные работы"],
      },
      {
        code: "20117105",
        text: "Какие требования, установленные Правилами безопасности химически опасных производственных объектов при производстве ремонта турбодетандеров или при замене фильтрующих элементов детандерных фильтров, указаны неверно?",
        answers: [
          "Арматура должна быть закрыта на входе воздуха в турбодетандер и на выходе из него",
          "Электропитание должно быть отключено",
          "На азотных турбодетандерах необходимо устанавливать заглушки на подаче и выходе газа из турбодетандера. Перед ремонтом эти турбодетандеры необходимо продувать воздухом",
          "На всех турбодетандерах необходимо устанавливать заглушки на подаче и выходе газа из турбодетандера",
        ],
        correctAnswers: [
          "На всех турбодетандерах необходимо устанавливать заглушки на подаче и выходе газа из турбодетандера",
        ],
      },
      {
        code: "20117106",
        text: "Как должен производиться отогрев смерзшейся изоляции для обеспечения доступа к адсорберам?",
        answers: [
          "За счет тепла, подводимого к отогреваемым аппаратам",
          "Непосредственно сухим подогретым воздухом",
          "Непосредственно подогретой водой или водяным паром",
          "Открытым пламенем",
        ],
        correctAnswers: ["Непосредственно сухим подогретым воздухом"],
      },
      {
        code: "20117107",
        text: "Работы в каких помещениях и местах для объектов производства и потребления продуктов разделения воздуха не относятся к работам повышенной опасности?",
        answers: [
          "В подвальных помещениях низкотемпературных технических устройств (воздухоразделительных установках, криогенных комплексах, хранилищах жидких продуктов разделения воздуха)",
          "Во внутриблочном пространстве и отсеках низкотемпературных технических устройств",
          "В трубопроводах, клапанах, колодцах, закрытых траншеях, приямках газгольдеров",
          "На площадках с оборудованием, расположенным вне здания, и сооружениях вблизи сбросных трубопроводов или на расстоянии 15,0 м от систем азотно-водяного охлаждения",
        ],
        correctAnswers: [
          "На площадках с оборудованием, расположенным вне здания, и сооружениях вблизи сбросных трубопроводов или на расстоянии 15,0 м от систем азотно-водяного охлаждения",
        ],
      },
      {
        code: "20117108",
        text: "Как необходимо выполнять ремонтные работы внутри трубопроводов и арматуры теплого и холодного концов регенераторов в период остановки блоков без слива жидкости?",
        answers: [
          "По письменному разрешению руководителя смены",
          "По указанию руководителя организации, полученному устно или письменно",
          "По утвержденным графикам согласно срокам, установленным техническими условиями разработчика оборудования",
          "По наряду-допуску",
        ],
        correctAnswers: ["По наряду-допуску"],
      },
      {
        code: "20117109",
        text: "При выполнении какого условия допускается сброс продувочного газа (воздуха, азота), содержащего пары растворителя, в производственное помещение при обезжиривании технических устройств и трубопроводов?",
        answers: [
          "По окончании процесса поглощения паров растворителя",
          "В присутствии работника, ответственного за проведение работ по обезжириванию",
          "Не допускается ни в каком случае",
          "После очистки производственного помещения",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20117110",
        text: "Выполнение какого условия, относящегося к трубопроводам и компрессорам продуктов разделения воздуха, допускается?",
        answers: [
          "Объединение дренажных трубопроводов",
          "Попадание кислорода во всасывающую линию при обкатке компрессоров",
          "Установка дренажного устройства для слива сконденсированных паров масла в нижней точке трубопровода отвода масла",
          "Попадание паров масла в воздух, поступающий на всас воздушных компрессоров и вентиляционных систем",
        ],
        correctAnswers: [
          "Установка дренажного устройства для слива сконденсированных паров масла в нижней точке трубопровода отвода масла",
        ],
      },
      {
        code: "20117111",
        text: "В каком случае допускается одновременное проведение ремонта технических устройств и трубопроводов?",
        answers: [
          "Все ответы неверны",
          "В случае проведения ремонта трубопроводов и арматуры теплого и холодного концов регенераторов",
          "В случае проведения ремонта принудительных клапанов переключения регенераторов и трехходовых заслонок после регенераторов",
          "В случае проведения ремонта трубопроводов и арматуры холодного конца регенераторов и системы приказного воздуха переключения регенераторов или механизма переключения",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20117112",
        text: "Какое из перечисленных требований при производстве работ по ремонту технических устройств и трубопроводов указано верно? Укажите все правильные ответы.",
        answers: [
          "Перед ремонтом электрозадвижка на входе воздуха в блок должна быть закрыта и поджата вручную",
          "Механизм переключения регенераторов (шальт-машину) на период ремонта клапанов (трубопроводов) необходимо остановить",
          "До выполнения работ на обеих трехходовых заслонках, связанных с доступом в подводящие к ним трубопроводы, должна устанавливаться заглушка (по ходу газа) после обеих трехходовых заслонок",
          "На весь период ремонта арматура на сбросе газа из блока в атмосферу должна быть закрыта",
        ],
        correctAnswers: [
          "Перед ремонтом электрозадвижка на входе воздуха в блок должна быть закрыта и поджата вручную",
          "Механизм переключения регенераторов (шальт-машину) на период ремонта клапанов (трубопроводов) необходимо остановить",
        ],
      },
      {
        code: "20117113",
        text: "При какой остановке эксплуатации кислородопровод давлением 0,6 МПа и выше должен быть продут воздухом или азотом со скоростью на выходе не менее 40 м/с?",
        answers: [
          "Более 3 недель",
          "Более 2 недель",
          "Более недели",
          "Более месяца",
        ],
        correctAnswers: ["Более месяца"],
      },
      {
        code: "20117114",
        text: "Из какого материала должны выполняться линии отбора кислорода на анализы независимо от параметров кислорода? Укажите все правильные ответы.",
        answers: [
          "Из медных сплавов",
          "Из чугунных сплавов",
          "Из коррозионно-стойкой стали",
          "Из сплавов алюминия",
        ],
        correctAnswers: ["Из медных сплавов", "Из коррозионно-стойкой стали"],
      },
      {
        code: "20117115",
        text: "Каким образом определяются методы и сроки очистки водяных полостей холодильников и рубашек цилиндров компрессоров от отложений?",
        answers: [
          "Лицензированной сторонней организацией",
          "Технологическим регламентом, технологическими инструкциями",
          "Производственными инструкциями и распоряжением технического руководителя эксплуатирующей организации",
          "Требованиями Правил безопасности химически опасных производственных объектов",
        ],
        correctAnswers: [
          "Технологическим регламентом, технологическими инструкциями",
        ],
      },
      {
        code: "20117116",
        text: "На каких кислородопроводах необходимо устанавливать переключающиеся фильтры перед их подключением к коллектору всасывания кислородных компрессоров?",
        answers: [
          "На кислородопроводах длиной более 300 м, изготовленных из сплавов алюминия",
          "На кислородопроводах длиной более 250 м, изготовленных из углеродистых сталей",
          "На кислородопроводах длиной более 150 м, изготовленных из углеродистых сталей",
          "На кислородопроводах длиной более 200 м, изготовленных из сплавов на основе меди",
        ],
        correctAnswers: [
          "На кислородопроводах длиной более 250 м, изготовленных из углеродистых сталей",
        ],
      },
      {
        code: "20117117",
        text: "Какое из перечисленных требований должно соблюдаться при обезжиривании сосудов (емкостных аппаратов) способом протирки? Укажите все правильные ответы.",
        answers: [
          "Перед проведением работ по обезжириванию сосуд, бывший в эксплуатации, должен быть продут инертным газом",
          "Перед проведением работ по обезжириванию сосуд, бывший в эксплуатации, отогревается до температуры не ниже +20 °С",
          "Технический руководитель организации должен провести осмотр места производства работ и убедиться, что сосуд пропарен и подготовлен к проведению работ",
          "Начинать работы следует только при объемной доле кислорода в воздухе внутри сосуда не менее 19 % и не более 23 %",
        ],
        correctAnswers: [
          "Перед проведением работ по обезжириванию сосуд, бывший в эксплуатации, отогревается до температуры не ниже +20 °С",
          "Начинать работы следует только при объемной доле кислорода в воздухе внутри сосуда не менее 19 % и не более 23 %",
        ],
      },
      {
        code: "20117118",
        text: "Кем утверждается акт перевода технического устройства, используемого для работы с одним ПРВ, на работу с другим продуктом, подтверждающий выполнение всех необходимых для этого требований?",
        answers: [
          "Техническим руководителем организации, эксплуатирующей объекты потребления и производства ПРВ",
          "Представителем сторонней специализированной организации",
          "Представителем территориального органа Ростехнадзора",
          "Представителем проектной организации",
        ],
        correctAnswers: [
          "Техническим руководителем организации, эксплуатирующей объекты потребления и производства ПРВ",
        ],
      },
      {
        code: "20117119",
        text: "Какие устанавливаются сроки обезжиривания ВРУ, работающих по схеме высокого и среднего давлений с блоками комплексной очистки на цеолитах и турбодетандерами, а также по схеме низкого давления?",
        answers: [
          "Не регламентируются",
          "1 раз в год",
          "1 раз в полгода",
          "1 раз в месяц",
        ],
        correctAnswers: ["Не регламентируются"],
      },
      {
        code: "20117120",
        text: "Какая минимальная продолжительность продувки кислородопровода давлением 0,6 МПа и выше перед началом эксплуатации воздухом или азотом со скоростью на выходе не менее 40 м/с?",
        answers: ["2 часа", "3 часа", "1 час", "15 минут"],
        correctAnswers: ["2 часа"],
      },
      {
        code: "20117121",
        text: "В каком случае допускается подключение азотных компрессоров к ВРУ без газгольдеров?",
        answers: [
          "При подключении через обратный клапан",
          "При наличии автоматических устройств и блокировок, исключающих увеличение отбора азота",
          "При наличии такого подключения в проектной документации",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "При наличии автоматических устройств и блокировок, исключающих увеличение отбора азота",
        ],
      },
      {
        code: "20117122",
        text: "Какую кислородную арматуру допускается размещать в помещениях щитов управления (щитовая)?",
        answers: [
          "Кислородную аппаратуру с давлением 0,6 МПа и выше",
          "Кислородную арматуру с давлением не выше 1,6 МПа",
          "Кислородную арматуру с давлением 2,6 МПа и выше",
          "Не допускается размещать кислородную арматуру (независимо от давления)",
        ],
        correctAnswers: [
          "Не допускается размещать кислородную арматуру (независимо от давления)",
        ],
      },
      {
        code: "20117123",
        text: "Как должны быть установлены все металлические конструкции, расположенные в пределах площадок, на которых размещены сосуды и сливоналивные устройства жидких продуктов разделения воздуха?",
        answers: [
          "На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,1 м",
          "На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,2 м",
          "На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,3 м",
          "На бетонные фундаменты с отметкой верха, превышающей отметку площадки не более чем на 0,15 м",
        ],
        correctAnswers: [
          "На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,2 м",
        ],
      },
      {
        code: "20117124",
        text: "Какие требования устанавливаются Правилами безопасности химически опасных производственных объектов к ограждению и габаритам трапов ливневой канализации, приямков и подвалов, расположенных за пределами площадок с сосудами и сливоналивными устройствами жидких продуктов разделения воздуха на расстоянии менее 10,0 м?",
        answers: [
          "Должны иметь бетонное ограждение (порог) высотой не менее 0,1 м со стороны, обращенной к площадке, и выступать за габариты ограждаемых объектов не менее чем на 1,0 м",
          "Должны иметь бетонное ограждение (порог) высотой не менее 0,2 м со стороны, обращенной к площадке, и выступать за габариты ограждаемых объектов не менее чем на 0,1 м",
          "Должны иметь бетонное ограждение (порог) высотой не менее 0,2 м со стороны, обращенной к площадке, и выступать за габариты ограждаемых объектов не менее чем на 1,0 м",
          "Должны иметь бетонное ограждение (порог) высотой не менее 0,1 м со стороны, обращенной к площадке, и выступать за габариты ограждаемых объектов не менее чем на 0,5 м",
        ],
        correctAnswers: [
          "Должны иметь бетонное ограждение (порог) высотой не менее 0,2 м со стороны, обращенной к площадке, и выступать за габариты ограждаемых объектов не менее чем на 1,0 м",
        ],
      },
      {
        code: "20117125",
        text: "Какой высоты должно быть ограждение площадок с техническими устройствами при размещении площадок на территории, имеющей общее ограждение?",
        answers: [
          "Не менее 0,2 м",
          "Не менее 1,2 м",
          "Не менее 1,0 м",
          "Не менее 2,0 м",
        ],
        correctAnswers: ["Не менее 1,2 м"],
      },
      {
        code: "20117126",
        text: "Кем должны разрабатываться схемы движения транспортных средств и пешеходов по территории производства объекта производства и потребления продуктов разделения воздуха (ПРВ)?",
        answers: [
          "Органами исполнительной власти в области обеспечения безопасности дорожного движения",
          "Территориальными органами Ростехнадзора",
          "Проектной организацией",
          "Организацией, эксплуатирующей объекты производства и потребления ПРВ",
        ],
        correctAnswers: [
          "Организацией, эксплуатирующей объекты производства и потребления ПРВ",
        ],
      },
      {
        code: "20117127",
        text: "Кем должен быть утвержден перечень помещений и мест, в которых содержание кислорода по объемной доле может быть менее 19 % или более 23 % (в аварийной ситуации)?",
        answers: [
          "Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается руководителем организации",
          "Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается руководителем территориального органа Ростехнадзора",
          "Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается техническим руководителем организации",
          "Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается руководителем территориального отдела Роспотребнадзора",
        ],
        correctAnswers: [
          "Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается техническим руководителем организации",
        ],
      },
      {
        code: "20117128",
        text: "Какому уровню заполнения соответствует показание дистанционного указателя степени заполнения газгольдера с сигнализацией по уровню заполнения 20 % полного объема?",
        answers: [
          "Минимальному",
          "Предминимальному",
          "Предмаксимальному",
          "Максимальному",
        ],
        correctAnswers: ["Предминимальному"],
      },
      {
        code: "20117129",
        text: "При достижении какого уровня заполнения газгольдера автоматически производится отключение компрессоров (газодувок), подключенных к газгольдеру?",
        answers: [
          "Максимального",
          "Предмаксимального",
          "Предминимального",
          "Минимального",
        ],
        correctAnswers: ["Минимального"],
      },
      {
        code: "20117130",
        text: "Для каких реципиентов в месте их подключения к межцеховым кислородопроводам необходимо устанавливать отключающие задвижки с дистанционным управлением?",
        answers: [
          "Вместимостью более 10 м3 с рабочим давлением более 1,3 МПа",
          "Вместимостью более 20 м3 с рабочим давлением более 1,5 МПа",
          "Вместимостью не более 200 м3 с рабочим давлением не более 1,6 МПа",
          "Вместимостью более 200 м3 с рабочим давлением более 1,6 МПа",
        ],
        correctAnswers: [
          "Вместимостью более 200 м3 с рабочим давлением более 1,6 МПа",
        ],
      },
      {
        code: "20117131",
        text: "Какой высоты должно быть ограждение реципиентов, расположенных вне зданий?",
        answers: [
          "Не менее 0,2 м",
          "Не менее 1,0 м",
          "Не менее 1,2 м",
          "Не менее 2,0 м",
        ],
        correctAnswers: ["Не менее 1,2 м"],
      },
      {
        code: "20117132",
        text: "В каких системах кислородоснабжения операции по снижению и поддержанию давления кислорода, регулированию его расхода следует производить в кислородно-распределительных (регуляторных) пунктах?",
        answers: [
          "С расходами свыше 2000 м3/ч и давлением свыше 0,6 МПа",
          "С расходами свыше 4000 м3/ч и давлением от 0,4 МПа до 2,0 МПа",
          "С расходами свыше 5000 м3/ч и давлением от 0,2 МПа до 4,0 МПа ",
          "С расходами свыше 6000 м3/ч и давлением от 0,6 МПа до 4,0 МПа",
        ],
        correctAnswers: [
          "С расходами свыше 6000 м3/ч и давлением от 0,6 МПа до 4,0 МПа",
        ],
      },
      {
        code: "20117133",
        text: "Какие условия, установленные требованиями Правил безопасности химически опасных производственных объектов к размещению кислородно-распределительных (регуляторных) пунктов в отдельно стоящих зданиях объектов производства и потребления продуктов разделения воздуха, указаны неверно?",
        answers: [
          "Размещать все линии регулирования с отсечным и регулирующим клапанами в одном помещении",
          "Оснащать системой вентиляции каждое помещение, в котором размещается линия регулирования",
          "Предусматривать автоматическое управление регулирующими и отсечными клапанами и дистанционное управление отключающими электрозадвижками",
          "Помещение управления кислородно-распределительных (регуляторных) пунктов не должно иметь непосредственного прохода (входа) в помещение линий регулирования. Проход в помещение линий регулирования кислородно-распределительных (регуляторных) пунктов должен осуществляться только через наружные входы (выходы), размещаемые на противоположных концах каждого помещения",
        ],
        correctAnswers: [
          "Размещать все линии регулирования с отсечным и регулирующим клапанами в одном помещении",
        ],
      },
      {
        code: "20117134",
        text: "На каком расстоянии на каждом кислородопроводе перед кислородно-распределительными (регуляторными) пунктами необходимо устанавливать отключающую задвижку с дистанционным управлением?",
        answers: [
          "Не менее 7,0 м и не более 85,0 м",
          "Не менее 10,0 м и не более 50,0 м",
          "Не менее 50,0 м и не более 100,0 м",
          "Не менее 85,0 м и не более 150,0 м",
        ],
        correctAnswers: ["Не менее 10,0 м и не более 50,0 м"],
      },
      {
        code: "20117135",
        text: "При какой температуре кислорода после регулятора давления срабатывает блокировка, прекращающая подачу кислорода для линии регулирования кислородно-распределительных (регуляторных) пунктов?",
        answers: [
          "Свыше плюс 70 °С",
          "Свыше плюс 80 °С",
          "Свыше плюс 90 °С",
          "Свыше плюс 100 °С",
        ],
        correctAnswers: ["Свыше плюс 100 °С"],
      },
      {
        code: "20117136",
        text: "На каких кислородопроводах перед кислородно-распределительными (регуляторными) пунктами необходимо устанавливать фильтры?",
        answers: [
          "Работающих под давлением более 1,2 МПа",
          "Работающих под давлением более 1,4 МПа",
          "Работающих под давлением более 1,5 МПа",
          "Работающих под давлением более 1,6 МПа",
        ],
        correctAnswers: ["Работающих под давлением более 1,6 МПа"],
      },
      {
        code: "20117137",
        text: "Какие требования к стационарным обводным линиям предусматриваются Правилами безопасности химически опасных производственных объектов?",
        answers: [
          "Должны размещаться на расстоянии не менее 10,0 м от помещений кислородно-распределительных (регуляторных) пунктов и иметь съемные патрубки и заглушки",
          "Должны размещаться на расстоянии не менее 50,0 м от помещений кислородно-распределительных (регуляторных) пунктов и иметь съемные патрубки и заглушки",
          "Должны размещаться на расстоянии не менее 100,0 м от помещений кислородно-распределительных (регуляторных) пунктов и иметь съемные патрубки и заглушки",
          "Должны иметь съемные патрубки и заглушки, при этом данные обводные линии следует размещать за пределами помещений кислородно-распределительных (регуляторных) пунктов",
        ],
        correctAnswers: [
          "Должны иметь съемные патрубки и заглушки, при этом данные обводные линии следует размещать за пределами помещений кислородно-распределительных (регуляторных) пунктов",
        ],
      },
      {
        code: "20117138",
        text: "В соответствии с требованиями какого документа осуществляется освидетельствование и эксплуатация баллонов с продуктами разделения воздуха?",
        answers: [
          "В соответствии с требованиями, установленными документацией на оборудование",
          "В соответствии с требованиями федеральных норм и правил в области промышленной безопасности, устанавливающих правила промышленной безопасности опасных производственных объектов, на которых используется оборудование, работающее под избыточным давлением",
          'В соответствии с требованиями Технического регламента Таможенного союза "О безопасности машин и оборудования"',
        ],
        correctAnswers: [
          "В соответствии с требованиями федеральных норм и правил в области промышленной безопасности, устанавливающих правила промышленной безопасности опасных производственных объектов, на которых используется оборудование, работающее под избыточным давлением",
        ],
      },
      {
        code: "20117139",
        text: "Что не соответствует требованиям, установленным Правилами безопасности химически опасных производственных объектов к транспортированию и хранению баллонов с продуктами разделения воздуха?",
        answers: [
          "Транспортирование баллонов с продуктами разделения воздуха производится в вертикальном положении в контейнерах",
          "Работы по погрузке и выгрузке баллонов с продуктами разделения воздуха осуществляются только вручную",
          "Работы по погрузке и выгрузке контейнеров необходимо механизировать",
          "Наполненные и пустые баллоны необходимо хранить в контейнерах или клетках",
        ],
        correctAnswers: [
          "Работы по погрузке и выгрузке баллонов с продуктами разделения воздуха осуществляются только вручную",
        ],
      },
      {
        code: "20117140",
        text: "Какие требования безопасности необходимо предусматривать в помещениях при наполнении баллонов?",
        answers: [
          "В помещении наполнения баллонов должен вестись автоматический контроль воздушной среды",
          "В среде кислорода не допускается использование прокладочных и уплотнительных материалов органического происхождения",
          "Не допускается работа в помещениях наполнения баллонов при объемной доле кислорода в воздухе менее 19 % и более 23 %",
          "Между наполнительной и помещением, из которого производится управление оборудованием, подающим сжатые продукты разделения воздуха на наполнение баллонов, необходимо предусматривать двухстороннюю связь",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20117141",
        text: "В каком случае помещение управления соседних кислородно-распределительных (регуляторных) пунктов необходимо удалять от помещения линии регулирования на расстояние не менее 15,0 м? Укажите все правильные ответы.",
        answers: [
          "При подводе кислорода по трубопроводам диаметром 300 мм и более к КРП, размещенному в отдельно стоящем здании",
          "При подводе кислорода по трубопроводам диаметром 300 мм и более к КРП, размещенному в помещении, пристроенном к другим зданиям",
          "При подводе кислорода по трубопроводам диаметром 100 мм и более к КРП, размещенному в отдельно стоящем здании или в помещении, пристроенном к другим зданиям",
          "При подводе кислорода по трубопроводам диаметром 200 мм и более к КРП, размещенному в отдельно стоящем здании или в помещении, пристроенном к другим зданиям",
        ],
        correctAnswers: [
          "При подводе кислорода по трубопроводам диаметром 300 мм и более к КРП, размещенному в отдельно стоящем здании",
          "При подводе кислорода по трубопроводам диаметром 300 мм и более к КРП, размещенному в помещении, пристроенном к другим зданиям",
        ],
      },
      {
        code: "20117142",
        text: "Какое должно быть расстояние между помещениями соседних кислородно-распределительных (регуляторных) пунктов?",
        answers: [
          "Не менее 3,0 м",
          "Не менее 4,0 м",
          "Не менее 6,0 м",
          "Не менее 10,0 м",
        ],
        correctAnswers: ["Не менее 6,0 м"],
      },
      {
        code: "20117143",
        text: "Какие контрольно-измерительные приборы, применяемые для измерения параметров кислорода и газовых смесей, подлежат защите от жировых загрязнений?",
        answers: [
          "С объемной долей кислорода менее 19 %",
          "С объемной долей кислорода 19 %",
          "С объемной долей кислорода 23 %",
          "С объемной долей кислорода более 23 %",
        ],
        correctAnswers: ["С объемной долей кислорода более 23 %"],
      },
      {
        code: "20117144",
        text: "Какие требования, установленные Правилами безопасности химически опасных производственных объектов к работе оборудования, контрольно-техническим приборам, средствам автоматизации и системам сигнализации и защиты, указаны неверно?",
        answers: [
          "Не допускается работа оборудования при неисправных системах сигнализации и защиты",
          "Работа с отключенными системами автоматического регулирования допускается только в том случае, если это предусматривается технологическими регламентами",
          "Не допускается применение неисправных контрольно-технических приборов и средств автоматизации",
          "Допускается применение контрольно-технических приборов и средств автоматизации, не соответствующих требованиям законодательства Российской Федерации об обеспечении единства изменений, включая требования к проведению поверки и калибровки при просрочке поверки приборов не более чем на 1 месяц",
          "Не допускается работа оборудования при отключенных системах сигнализации и защиты",
        ],
        correctAnswers: [
          "Допускается применение контрольно-технических приборов и средств автоматизации, не соответствующих требованиям законодательства Российской Федерации об обеспечении единства изменений, включая требования к проведению поверки и калибровки при просрочке поверки приборов не более чем на 1 месяц",
        ],
      },
      {
        code: "20117145",
        text: "Где должно регистрироваться отключение сигнализации или блокировки для ревизии аппаратуры и калибровки газоанализаторов?",
        answers: [
          "В журнале приема-передачи смены",
          "В наряде-допуске",
          "В журнале технического обслуживания и ремонта оборудования",
          "В технологическом журнале",
        ],
        correctAnswers: ["В технологическом журнале"],
      },
      {
        code: "20117146",
        text: "Какие требования устанавливаются Правилами безопасности химически опасных производственных объектов к хранению баллонов в помещении хроматографии?",
        answers: [
          "Не допускается хранить более двух баллонов с эталонными и поверочными газовыми смесями. Вместимость каждого баллона не должна быть более 40 л, а давление - более 20 МПа",
          "Не допускается хранить более трех баллонов с эталонными и поверочными газовыми смесями. Вместимость каждого баллона не должна быть более 20 л, а давление - более 40 МПа",
          "Не допускается хранить более двух баллонов с эталонными и поверочными газовыми смесями. Вместимость каждого баллона не должна быть более 40 л, а давление - более 40 МПа",
          "Не допускается хранить более четырех баллонов с эталонными и поверочными газовыми смесями. Вместимость каждого баллона не должна быть более 40 л, а давление - более 20 МПа",
        ],
        correctAnswers: [
          "Не допускается хранить более двух баллонов с эталонными и поверочными газовыми смесями. Вместимость каждого баллона не должна быть более 40 л, а давление - более 20 МПа",
        ],
      },
      {
        code: "20117147",
        text: "Какой категории обеспечения надежности электроснабжения должны быть электроприемники, обеспечивающие технологические процессы на объектах производства и потребления продуктов разделения воздуха?",
        answers: [
          "I категории надежности",
          "II категории надежности",
          "III категории надежности",
          "Правилами не установлены требования к категории надежности",
        ],
        correctAnswers: ["II категории надежности"],
      },
      {
        code: "20117148",
        text: "Какой категории обеспечения надежности электроснабжения должны быть электроприемники, обеспечивающие непрерывность технологического процесса производства на объектах производства и потребления продуктов разделения воздуха?",
        answers: [
          "I категории надежности",
          "II категории надежности",
          "III категории надежности",
          "Правилами не установлены требования к категории надежности",
        ],
        correctAnswers: ["I категории надежности"],
      },
      {
        code: "20117149",
        text: "Как должна производиться инструментальная проверка эффективности работы вентиляционных систем?",
        answers: [
          "Не реже одного раза в полгода. Акты проверки утверждает главный инженер",
          "Только после каждого капитального ремонта. Акты проверки утверждает технический руководитель организации",
          "Только после реконструкции вентиляционных систем. Акты проверки утверждает главный инженер",
          "Не реже одного раза в год, а также после каждого капитального ремонта или реконструкции этих систем. Акты проверки утверждает технический руководитель организации",
        ],
        correctAnswers: [
          "Не реже одного раза в год, а также после каждого капитального ремонта или реконструкции этих систем. Акты проверки утверждает технический руководитель организации",
        ],
      },
      {
        code: "20117150",
        text: "Какие требования необходимо соблюдать при устройстве под блоками разделения воздуха подвального помещения?",
        answers: [
          "Поддерживать температуру воздуха не ниже 4 °С, а также предусматривать систему приточно-вытяжной вентиляции",
          "Поддерживать температуру воздуха не ниже 15 °С, а также предусматривать систему пожаротушения",
          "Поддерживать температуру воздуха не ниже 12 °С, а также предусматривать систему кондиционирования воздуха",
          "Поддерживать температуру воздуха не ниже 5 °С, а также предусматривать систему вытяжной вентиляции",
        ],
        correctAnswers: [
          "Поддерживать температуру воздуха не ниже 5 °С, а также предусматривать систему вытяжной вентиляции",
        ],
      },
      {
        code: "20117151",
        text: "Какие требования к техническим устройствам и инженерным коммуникациям не соответствуют требованиям Правил безопасности химически опасных производственных объектов?",
        answers: [
          "Система продувок кислородных, азотных и аргонных технических устройств и коммуникаций должна исключать возможность поступления продуктов разделения воздуха в помещение",
          "При нормативной (регламентной) утечке или сбросе продуктов разделения воздуха в помещение (в отдельных случаях, определенных проектной документацией (документацией)) не предусматривается их удаление системой приточно-вытяжной вентиляции",
          "При изменении технологического процесса или реконструкции производственного участка действующие на этом участке вентиляционные системы необходимо приводить в соответствие с новыми производственными условиями",
          "На все вентиляционные системы необходимо составлять соответствующие эксплуатационные документы (паспорта или формуляры)",
        ],
        correctAnswers: [
          "При нормативной (регламентной) утечке или сбросе продуктов разделения воздуха в помещение (в отдельных случаях, определенных проектной документацией (документацией)) не предусматривается их удаление системой приточно-вытяжной вентиляции",
        ],
      },
      {
        code: "20117152",
        text: "Какие требования к сетям водопроводов и канализации и устройствам, подключаемым к сетям, не соответствуют требованиям Правил безопасности химически опасных производственных объектов?",
        answers: [
          "Запрещается соединение сетей хозяйственно-питьевого водопровода с сетями водопроводов, подающих воду технического качества",
          "В производственных помещениях, где возможно повышенное содержание кислорода, которое может привести к воспламенению одежды, или наличие опасных веществ, которые могут вызвать химические ожоги, должны устанавливаться фонтанчики, краны, раковины или ванны самопомощи, аварийные души",
          "Устройства (фонтанчики, краны, раковины или ванны самопомощи, аварийные души) должны располагаться в легкодоступных местах и подключаться к хозяйственно-питьевому водопроводу",
          "Осмотр и очистку канализационных сетей и колодцев необходимо производить по графикам в соответствии с порядком проведения газоопасных работ",
          "В случае крайней необходимости допускается располагать устройства, такие как фонтанчики, краны, раковины или ванны самопомощи, аварийные души в помещениях, в которых обращаются или хранятся вещества, которые при контакте с водой разлагаются с взрывом или воспламеняются, а также выделяют взрывоопасные или токсичные газы",
        ],
        correctAnswers: [
          "В случае крайней необходимости допускается располагать устройства, такие как фонтанчики, краны, раковины или ванны самопомощи, аварийные души в помещениях, в которых обращаются или хранятся вещества, которые при контакте с водой разлагаются с взрывом или воспламеняются, а также выделяют взрывоопасные или токсичные газы",
        ],
      },
      {
        code: "20117153",
        text: "Что не соответствует требованиям, предъявляемым к освещению в производственных и вспомогательных зданиях?",
        answers: [
          "Естественное и искусственное освещение в производственных и вспомогательных зданиях и помещениях объектов производств и потребления продуктов разделения воздуха, включая аварийное освещение основных рабочих мест, должно соответствовать проектной документации (документации) и требованиям Правил безопасности химически опасных производственных объектов",
          "Организациями, эксплуатирующими объекты производства и потребления продуктов разделения воздуха, должны осуществляться систематические проверки исправности сети аварийного освещения в порядке и с периодичностью, установленной эксплуатирующей организацией",
          "Не допускается загромождать световые проемы помещений",
          "Во взрывоопасных помещениях следует предусматривать системы освещения люминесцентными лампами или лампами накаливания открытого типа",
        ],
        correctAnswers: [
          "Во взрывоопасных помещениях следует предусматривать системы освещения люминесцентными лампами или лампами накаливания открытого типа",
        ],
      },
      {
        code: "20117154",
        text: "В соответствии с каким документом выполняется защита от статического электричества технических устройств и коммуникаций газообразного кислорода и жидких продуктов разделения воздуха?",
        answers: [
          "В соответствии с письменным распоряжением эксплуатирующей организации",
          "В соответствии с проектной документацией (документацией)",
          "В соответствии с производственными инструкциями эксплуатирующей организации",
          "В соответствии с требованиями технологического регламента",
        ],
        correctAnswers: [
          "В соответствии с проектной документацией (документацией)",
        ],
      },
      {
        code: "20117155",
        text: "Какую объемную долю кислорода в воздухе должна обеспечивать постоянно действующая приточно-вытяжная вентиляция в помещениях, объем которых в метрах кубических превышает объем жидкости, находящейся в сосудах, в литрах менее чем в 7 раз?",
        answers: [
          "Не менее 20 % и не более 24 %",
          "Не менее 19 % и не более 23 %",
          "Не менее 15 % и не более 19 %",
          "Не менее 23 % и не более 27 %",
        ],
        correctAnswers: ["Не менее 19 % и не более 23 %"],
      },
    ],

    20118: [
      {
        code: "20118000",
        text: "Что из перечисленного следует выполнить для проведения огневых работ внутри емкости (аппарата) при проведении газоопасных работ?",
        answers: [
          "Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)",
          "Следует к наряду-допуску оформить акт осмотра места проведения работ комиссией по охране труда",
          "Следует к наряду-допуску оформить график проведения проверок места выполнения работ",
          "Следует к наряду-допуску приложить копии документов, подтверждающих факт проведения стажировки работников по данному виду работ",
        ],
        correctAnswers: [
          "Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)",
        ],
      },
      {
        code: "20118001",
        text: "Какие сведения не указываются в организационно-распорядительном документе для остановки на ремонт объекта или оборудования?",
        answers: [
          "Сроки остановки, подготовки, ремонта и пуска объекта или оборудования",
          "Сведения о непосредственном руководителе работ и лицах, ответственных за подготовку к ремонтным работам",
          "Наличие плана мероприятий по локализации аварий и ликвидации их последствий",
        ],
        correctAnswers: [
          "Наличие плана мероприятий по локализации аварий и ликвидации их последствий",
        ],
      },
      {
        code: "20118002",
        text: "Кем из перечисленных лиц может осуществляться общая координация ремонтных работ на объекте, где ремонтные работы производятся несколькими подрядными организациями и заказчиком?",
        answers: [
          "Любым из перечисленных лиц",
          "Лицом, назначенным руководителем эксплуатирующей организации (филиала организации)",
          "Лицом, назначенным уполномоченным заместителем руководителя эксплуатирующей организации (филиала организации)",
        ],
        correctAnswers: ["Любым из перечисленных лиц"],
      },
      {
        code: "20118003",
        text: "Что должна выполнить подрядная организация до начала проведения ремонтных работ?",
        answers: [
          "Разработать проект производства работ",
          "Разработать проект производства аварийно-восстановительных работ",
          "Составить сметную документацию",
          "Передать заказчику перечень оборудования, трубопроводов, коммуникаций, разрешенных к использованию подрядной организацией",
        ],
        correctAnswers: ["Разработать проект производства работ"],
      },
      {
        code: "20118004",
        text: "Кто производит подключение к электросетям передвижных электроприемников подрядной организации и их отключение при проведении ремонтных работ?",
        answers: [
          "Электротехнический персонал эксплуатирующей организации",
          "Электротехнический персонал владельца электросетевого хозяйства по заявке персонала эксплуатирующей организации",
          "Электротехнологический персонал подрядной организации",
        ],
        correctAnswers: [
          "Электротехнический персонал эксплуатирующей организации",
        ],
      },
      {
        code: "20118005",
        text: "К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?",
        answers: [
          "К II группе",
          "К группе работ с повышенной опасностью",
          "К III группе",
          "К I группе",
        ],
        correctAnswers: ["К II группе"],
      },
      {
        code: "20118006",
        text: "Каким образом фиксируется прохождение инструктажа исполнителями ремонтных работ?",
        answers: [
          "Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске",
          "Ответственный за организацию ремонтных работ фиксирует проведение инструктажа только в наряде-допуске",
          "Специалист по охране труда проводит инструктаж всем исполнителям и делает отметку в наряде-допуске",
        ],
        correctAnswers: [
          "Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске",
        ],
      },
      {
        code: "20118007",
        text: "Какие требования должны выполняться при проведении земляных работ в ремонтной зоне?",
        answers: [
          "Подрядная организация согласовывает наряд-допуск на производство земляных работ со структурными подразделениями эксплуатирующей организации, на которые возложено согласование наряда-допуска на производство земляных работ внутренними документами эксплуатирующей организацией",
          "Подрядная организация своими силами должна оформить наряд-допуск и передать копию в эксплуатирующую организацию",
          "Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации",
        ],
        correctAnswers: [
          "Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации",
        ],
      },
      {
        code: "20118008",
        text: "Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в виде электронного документа?",
        answers: [
          "Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия",
          "Допускается в случае описания порядка оформления электронного документа в локальных нормативных актах",
          "Допускается в случае согласования порядка оформления и регистрации наряда-допуска территориальными органами Ростехнадзора",
          "Не допускается",
        ],
        correctAnswers: [
          "Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия",
        ],
      },
      {
        code: "20118009",
        text: "Какие из перечисленных документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ, указаны неверно?",
        answers: [
          "Проект производства ремонтных работ",
          "Требования к проведению огневых и газоопасных работ",
          "Инструкции по эксплуатации и безопасному проведению ремонтных работ",
          "Технологический регламент",
        ],
        correctAnswers: ["Проект производства ремонтных работ"],
      },
      {
        code: "20118010",
        text: "Кто определяет структурные подразделения, на которые возлагается согласование наряда-допуска на выполнение огневых работ?",
        answers: [
          "Комиссия по организации и проведению производственного контроля за соблюдением требований промышленной безопасности",
          "Руководитель эксплуатирующей организации",
          "Технический руководитель организации",
        ],
        correctAnswers: ["Руководитель эксплуатирующей организации"],
      },
      {
        code: "20118011",
        text: "С какой периодичностью необходимо пересматривать и переутверждать перечень газоопасных работ?",
        answers: [
          "Не реже одного раза в год",
          "Не реже одного раза в полгода",
          "Не реже одного раза в 2 года",
          "Не реже одного раза в 5 лет",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20118012",
        text: "Кем утверждается перечень газоопасных работ, проводимых на опасных производственных объектах?",
        answers: [
          "Руководителем эксплуатирующей организации",
          "Руководителем службы охраны труда",
          "Техническим руководителем организации",
          "Руководителем структурного подразделения, ответственным за организацию газоопасных работ",
        ],
        correctAnswers: ["Руководителем эксплуатирующей организации"],
      },
      {
        code: "20118013",
        text: "Каким образом должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "Работы выполняются с письменного разрешения ответственного за проведение производственного контроля",
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением при очередном пересмотре перечня газоопасных работ",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
      {
        code: "20118014",
        text: "Когда могут проводиться газоопасные работы, выполняемые по наряду-допуску?",
        answers: [
          "В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск",
          "В рабочие и выходные дни с разрешения руководителя организации",
          "Только в выходные дни в присутствии лица, ответственного за выполнение газоопасных работ",
          "В дневную и ночную рабочие смены после письменного уведомления работника, ответственного за проведение производственного контроля",
        ],
        correctAnswers: [
          "В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск",
        ],
      },
      {
        code: "20118015",
        text: "Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?",
        answers: [
          "Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ",
          "Любой из специалистов, участвующих в проведении газоопасных работ",
          "Работник, ответственный за проведение производственного контроля",
          "Работники подрядной организации",
        ],
        correctAnswers: [
          "Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ",
        ],
      },
      {
        code: "20118016",
        text: "Что должен сделать руководитель структурного подразделения, на объекте которого будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?",
        answers: [
          "Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы",
          "Определить количество человек, выполняющих газоопасные работы",
          "Провести стажировку работников",
          "Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ",
        ],
        correctAnswers: [
          "Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы",
        ],
      },
      {
        code: "20118017",
        text: "С кем согласовывается наряд-допуск на проведение газоопасных работ?",
        answers: [
          "Только со службой охраны труда",
          "С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание",
          "С газоспасательной службой",
        ],
        correctAnswers: [
          "С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание",
        ],
      },
      {
        code: "20118018",
        text: "Что допускается при оформлении наряда-допуска на проведение газоопасных работ?",
        answers: [
          "Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера",
          "Заполнение наряда-допуска на проведение газоопасных работ карандашом",
          "Использование корректора для внесения исправлений при заполнении наряда-допуска на проведение газоопасных работ",
          "Подписи ответственных лиц с использованием факсимиле и их ксерокопии",
        ],
        correctAnswers: [
          "Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера",
        ],
      },
      {
        code: "20118019",
        text: "Что из перечисленного должно быть приложено к наряду-допуску на проведение газоопасных работ при проведении работ в емкостях, а также работ, связанных с разгерметизацией технологического оборудования и трубопроводов, коммуникаций?",
        answers: [
          "Технологическая карта выполнения работ в емкостях",
          "Инструкции по применению средств индивидуальной защиты",
          "Схемы(а) расположения запорной арматуры, освобождения от продукта, промывки, продувки, пропарки и мест установки заглушек, подписанные руководителем структурного подразделения или лицом, его замещающим",
          "Паспорта технических устройств, на которых проводятся газоопасные работы",
        ],
        correctAnswers: [
          "Схемы(а) расположения запорной арматуры, освобождения от продукта, промывки, продувки, пропарки и мест установки заглушек, подписанные руководителем структурного подразделения или лицом, его замещающим",
        ],
      },
      {
        code: "20118020",
        text: "Кто осуществляет подготовку объекта к проведению на нем газоопасной работы?",
        answers: [
          "Работники, осуществляющие эксплуатацию объекта",
          "Специализированная подрядная организация",
          "Работники, назначение приказом по предприятию",
          "Работники газоспасательной службы",
        ],
        correctAnswers: ["Работники, осуществляющие эксплуатацию объекта"],
      },
      {
        code: "20118021",
        text: "Какая из перечисленных подготовительных работ к проведению газоопасных работ в пределах площади, где возможно поступление паров и газов опасных веществ, указана неверно?",
        answers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
          "Выставляются посты в целях исключения допуска посторонних лиц в опасную зону по решению лица, ответственного за подготовку газоопасной работы",
          "Обозначается (ограждается) место в пределах площади, где возможно поступление паров и газов опасных веществ",
        ],
        correctAnswers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
        ],
      },
      {
        code: "20118022",
        text: "Кому лицо, ответственное за подготовку газоопасных работ, должно сдать объект после окончания подготовительных работ?",
        answers: [
          "Лицу, ответственному за проведение газоопасной работы",
          "Начальнику смены",
          "Непосредственному руководителю",
          "Работнику, ответственному за организацию производственного контроля",
          "Специалисту по пожарной безопасности",
        ],
        correctAnswers: [
          "Лицу, ответственному за проведение газоопасной работы",
        ],
      },
      {
        code: "20118023",
        text: "Что из перечисленного допускается при проведении газоопасных работ I группы?",
        answers: [
          "Увеличивать объем и характер работ, предусмотренных нарядом-допуском на проведение газоопасных работ",
          "Выполнение газоопасных работ бригадой исполнителей в составе двух человек",
          "Совмещение газоопасных работ и огневых работ в непосредственной близости на открытой площадке в случае возможного выделения в зону работ пожаровзрывоопасных веществ",
          "Совмещение газоопасных работ и огневых работ в одном помещении в случае возможного выделения в зону работ пожаровзрывоопасных веществ",
        ],
        correctAnswers: [
          "Выполнение газоопасных работ бригадой исполнителей в составе двух человек",
        ],
      },
      {
        code: "20118024",
        text: "О чем опрашивает каждого исполнителя лицо, ответственное за проведение газоопасных работ?",
        answers: [
          "О знании порядка выполнения работ",
          "О знании порядка применения первичных средств пожаротушения",
          "О знании порядка оказания первой помощи",
          "О самочувствии",
        ],
        correctAnswers: ["О самочувствии"],
      },
      {
        code: "20118025",
        text: "При каких условиях разрешается входить в газоопасное место при проведении газоопасных работ?",
        answers: [
          "Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны",
          "Только с разрешения представителя газоспасательной службы и после проверки места проведения газоопасных работ газоанализатором",
          "После осмотра места проведения газоопасных работ начальником смены и специалистом по пожарной безопасности",
          "Только с разрешения специалиста по пожарной безопасности и в средствах индивидуальной защиты",
        ],
        correctAnswers: [
          "Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны",
        ],
      },
      {
        code: "20118026",
        text: "В присутствии кого должна начинаться газоопасная работа?",
        answers: [
          "В присутствии лица, ответственного за проведение работ",
          "В присутствии технического директора",
          "В присутствии ответственного за охрану труда в подразделении",
          "В присутствии начальника смены",
        ],
        correctAnswers: [
          "В присутствии лица, ответственного за проведение работ",
        ],
      },
      {
        code: "20118027",
        text: "Какие информационные плакаты вывешиваются в зоне газоопасных работ на видном месте перед началом работ внутри емкостей и на все время их проведения?",
        answers: [
          '"Газоопасные работы", "Газ"',
          '"Работают люди!", "Внимание!"',
          '"Осторожно! Опасная зона", "Опасно для жизни!"',
          '"Внимание!", "Опасно для жизни!"',
        ],
        correctAnswers: ['"Газоопасные работы", "Газ"'],
      },
      {
        code: "20118028",
        text: "При какой максимальной температуре работа внутри емкостей (аппаратов) не допускается?",
        answers: ["40 °C", "45 °C", "50 °C", "55 °C"],
        correctAnswers: ["50 °C"],
      },
      {
        code: "20118029",
        text: "До какой максимальной температуры должны быть охлаждены нагретые емкости перед допуском внутрь в них людей?",
        answers: ["20 °C", "35 °C", "25 °C", "30 °C"],
        correctAnswers: ["30 °C"],
      },
      {
        code: "20118030",
        text: "Что из перечисленного не входит в обязанности лица, ответственного за проведение газоопасных работ, по окончании работ внутри емкости (аппарата)?",
        answers: [
          "Поставить в известность работников, занятых ведением технологического процесса, об окончании газоопасных работ",
          "Произвести запись в наряде-допуске на проведение газоопасных работ об окончании газоопасных работ",
          "Проверить состояние фильтрующих противогазов у каждого работающего внутри емкости (аппарата)",
          "Произвести запись в журнале ведения технологического процесса (вахтенный журнал, журнал приема-сдачи смен) и наряде-допуске на проведение газоопасных работ",
        ],
        correctAnswers: [
          "Проверить состояние фильтрующих противогазов у каждого работающего внутри емкости (аппарата)",
        ],
      },
      {
        code: "20118031",
        text: "Какими средствами индивидуальной защиты в обязательном порядке должен быть оснащен рабочий, спускающийся в емкость?",
        answers: [
          "Предохранительным поясом или страховочной привязью с сигнально-спасательной веревкой",
          "Ботинками с защитным металлическим подноском и каской",
          "Защитной одеждой из синтетических тканей",
          "Всеми перечисленными средствами",
        ],
        correctAnswers: [
          "Предохранительным поясом или страховочной привязью с сигнально-спасательной веревкой",
        ],
      },
      {
        code: "20118032",
        text: "Какое количество наблюдающих должно быть, если существует необходимость выполнения газоопасных работ в емкости (аппарате) двумя работающими?",
        answers: [
          "Не менее одного",
          "Не менее трех",
          "Не менее двух",
          "Не менее четырех",
          "Определяется локальными нормативными актами",
        ],
        correctAnswers: ["Не менее двух"],
      },
      {
        code: "20118033",
        text: "В каком из перечисленных документов должны быть определены место нахождения работающего и наблюдающего в процессе выполнения работы и их действия в случае возникновения ситуаций, связанных с ухудшением самочувствия работающего или наблюдающего при проведении газоопасных работ внутри емкости (аппарата)?",
        answers: [
          "В инструкции по охране труда при проведении газоопасных работ внутри емкости (аппарата)",
          "В наряде-допуске на проведение газоопасных работ",
          "В журнале учета газоопасных работ",
          "В журнале учета инструктажей перед выполнением работ",
        ],
        correctAnswers: ["В наряде-допуске на проведение газоопасных работ"],
      },
      {
        code: "20118034",
        text: "Что должны включать в себя мероприятия, обеспечивающие безопасность выполнения работ внутри аппаратов без средств индивидуальной защиты органов дыхания?",
        answers: [
          "Непрерывный контроль состояния воздушной среды",
          "Наличие у каждого работающего в емкости (аппарате) предохранительного пояса или страховочной привязи с закрепленной сигнально-спасательной веревкой",
          "Все перечисленное",
          "Наличие у места проведения работ средств сигнализации и связи (световой, звуковой, радиотелефонной)",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20118035",
        text: "В присутствии кого проводится проверка исправности, устойчивости и надежности закрепления лестницы по месту работы при работах внутри емкости?",
        answers: [
          "В присутствии лица, ответственного за проведение газоопасных работ",
          "В присутствии инженера по оборудованию",
          "В присутствии специалиста по промышленной безопасности",
          "В присутствии начальника смены",
        ],
        correctAnswers: [
          "В присутствии лица, ответственного за проведение газоопасных работ",
        ],
      },
      {
        code: "20118036",
        text: "Какие из перечисленных требований безопасности предъявляются при работах внутри емкости?",
        answers: [
          "При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует прекратить, а работника из емкости эвакуировать",
          "Запрещается передавать инструменты и материалы после спуска работников внутрь емкости",
          "Разрешается применение фильтрующих противогазов внутри емкости",
          "Шланговые или кислородно-изолирующие противогазы должны использоваться только при работе в емкости в одиночку",
        ],
        correctAnswers: [
          "При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует прекратить, а работника из емкости эвакуировать",
        ],
      },
      {
        code: "20118037",
        text: "С кем необходимо согласовывать проведение работ в коллекторах, тоннелях, колодцах, приямках, траншеях и аналогичных сооружениях?",
        answers: [
          "Со специалистом по охране труда",
          "Со специалистом по промышленной безопасности",
          "С руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы",
          "Со службой внутреннего инспекционного контроля",
        ],
        correctAnswers: [
          "С руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы",
        ],
      },
      {
        code: "20118038",
        text: "Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?",
        answers: [
          "Должны пройти медицинский осмотр в соответствии с требованиями законодательства Российской Федерации",
          "Должны быть не моложе 18 лет",
          "Все перечисленные требования",
          "Должны  пройти обучение приемам и методам проведения работ",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20118039",
        text: "Кто должен лично убедиться после окончания работ внутри емкости, что в емкости не остались люди, убран инструмент, материалы, не осталось посторонних предметов, и сделать об этом запись в наряде-допуске?",
        answers: [
          "Лицо, ответственное за проведение газоопасных работ",
          "Начальник смены",
          "Непосредственный руководитель работников, участвовавших в проведении газоопасных работ",
          "Лицо, ответственное за проведение производственного контроля",
        ],
        correctAnswers: ["Лицо, ответственное за проведение газоопасных работ"],
      },
      {
        code: "20118040",
        text: "Куда (кому) лицо, ответственное за проведение газоопасных работ, передает наряд-допуск после его закрытия?",
        answers: [
          "Руководителю структурного подразделения или его заместителю, а также в газоспасательную службу (ГСС)",
          "Руководителю структурного подразделения и специалисту по охране труда",
          "Специалисту по промышленной безопасности и в газоспасательную службу (ГСС)",
        ],
        correctAnswers: [
          "Руководителю структурного подразделения или его заместителю, а также в газоспасательную службу (ГСС)",
        ],
      },
      {
        code: "20118041",
        text: "Кому лицо, ответственное за проведение газоопасных работ, передает наряд-допуск после его закрытия?",
        answers: [
          "Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)",
          "Руководителю службы производственного контроля, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)",
          "Аварийно-спасательной службе организации",
        ],
        correctAnswers: [
          "Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)",
        ],
      },
      {
        code: "20118042",
        text: "Какими документами определяется перечень постоянных мест проведения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?",
        answers: [
          "Правилами пожарной безопасности",
          "Инструкцией по пожарной безопасности",
          "Технологией работ",
          "Организационно-распорядительными документами организации",
        ],
        correctAnswers: [
          "Организационно-распорядительными документами организации",
        ],
      },
      {
        code: "20118043",
        text: "В течение какого времени наряд-допуск на проведение огневых работ действителен?",
        answers: [
          "В течение одной дневной смены",
          "До завершения проведения огневых работ",
          "По решению оперативного руководителя",
        ],
        correctAnswers: ["В течение одной дневной смены"],
      },
      {
        code: "20118044",
        text: "Какое из перечисленных требований при назначении специалиста, ответственного за проведение огневых работ, указано неверно?",
        answers: [
          "Прохождение подготовки и аттестации по промышленной безопасности",
          "Назначение организационно-распорядительными документами руководителя эксплуатирующей организации (филиала организации) или лица, его замещающего, ответственного за обеспечение пожарной безопасности",
          "Прохождение обучения по пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность",
        ],
        correctAnswers: [
          "Прохождение подготовки и аттестации по промышленной безопасности",
        ],
      },
      {
        code: "20118045",
        text: "Какие из перечисленных действий, производимых при подготовке объекта к огневым работам, указаны неверно?",
        answers: [
          "Аппараты, машины и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ",
          "Аппараты, машины и другое оборудование, на которых будут проводиться огневые работы, должны быть остановлены, освобождены от опасных веществ",
          "Электроприводы движущихся механизмов, которые находятся в зоне выполнения огневых работ, должны быть отключены от источников питания, отсоединены от этих механизмов видимым разрывом",
          'На пусковых устройствах должны быть вывешены плакаты "Не включать: работают люди!"',
        ],
        correctAnswers: [
          "Аппараты, машины и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ",
        ],
      },
      {
        code: "20118046",
        text: "Какие плакаты вывешиваются на пусковых устройствах у аппаратов и в электрораспределительных устройствах при производстве газоопасных работ?",
        answers: [
          '"Высокое напряжение. Опасно для жизни!"',
          '"Не включать: работают люди!"',
          '"Не открывать: работают люди!"',
          '"Стой! Опасно для жизни!"',
        ],
        correctAnswers: ['"Не включать: работают люди!"'],
      },
      {
        code: "20118047",
        text: "Какие требования установлены к люкам колодцев канализации, расположенным в зоне проведения огневых работ?",
        answers: [
          "Крышки колодцев должны быть плотно закрыты и окрашены в красный цвет",
          "Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце",
          "Крышки колодцев должны быть плотно закрыты и прикрыты железобетонным колпаком",
        ],
        correctAnswers: [
          "Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце",
        ],
      },
      {
        code: "20118048",
        text: "При какой концентрации пожаровзрывоопасных веществ не допускается проведение огневых работ?",
        answers: [
          "При наличии пожаровзрывоопасных веществ выше 10 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения",
          "При наличии пожаровзрывоопасных веществ выше 15 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения",
          "При наличии пожаровзрывоопасных веществ выше 20 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения",
        ],
        correctAnswers: [
          "При наличии пожаровзрывоопасных веществ выше 20 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения",
        ],
      },
      {
        code: "20118049",
        text: "В соответствии с каким документом устанавливается контроль за состоянием воздушной среды?",
        answers: [
          "В соответствии с нарядом-допуском на выполнение огневых работ",
          "В соответствии с технологической картой",
          "В соответствии с инструкцией по пожарной безопасности",
        ],
        correctAnswers: [
          "В соответствии с нарядом-допуском на выполнение огневых работ",
        ],
      },
      {
        code: "20118050",
        text: "Кем должен быть утвержден перечень нормативно-технической документации, устанавливающей порядок и условия безопасного ведения производственного процесса, для каждого рабочего места организации?",
        answers: [
          "Техническим руководством организации",
          "Руководителем организации",
          "Ответственным за проведение производственного контроля",
        ],
        correctAnswers: ["Техническим руководством организации"],
      },
      {
        code: "20118051",
        text: "С какой периодичностью нормативно-техническая документация, устанавливающая порядок и условия безопасного ведения производственного процесса, подлежит пересмотру?",
        answers: [
          "Каждый год",
          "Каждые 3 года",
          "Каждые 4 года",
          "Каждые 5 лет",
        ],
        correctAnswers: ["Каждые 3 года"],
      },
      {
        code: "20118052",
        text: "Кем определяются порядок и сроки приведения организаций в соответствии с требованиями Правил промышленной безопасности резиновых производств?",
        answers: [
          "Руководителем территориального органа Ростехнадзора",
          "Руководителем организации",
          "Независимой экспертной организацией",
          "Главным инженером",
        ],
        correctAnswers: ["Руководителем организации"],
      },
      {
        code: "20118053",
        text: "Какие производственные подразделения (цехи, участки, отделения) следует располагать в отдельных производственных помещениях при проектировании?",
        answers: [
          "Очистки пресс-форм щелочными растворами",
          "Приготовления паст на основе токсичных ингредиентов 1 и 2 классов опасности, эбонитовой пыли, приготовления резиновых смесей на вальцах, фактиса, поверхностно активных веществ, щелочных и кислотных растворов",
          "Вулканизации изделий на основе фтористых и силиконовых каучуков с применением перекиси дикумила пероксимона",
          "Все перечисленные производственные подразделения",
          "Подготовки нитрит-нитратных солей, арматуры (латунирование, фосфатирование и т. п.)",
        ],
        correctAnswers: ["Все перечисленные производственные подразделения"],
      },
      {
        code: "20118054",
        text: "С помощью каких несгораемых переходов могут сообщаться производственные подразделения подготовки сырья и приготовления резиновых смесей, склады сырья и готовой продукции с основными производственными корпусами?",
        answers: [
          "Длиной не менее 6 м",
          "Длиной не менее 7 м",
          "Длиной не менее 5 м",
          "Длиной не менее 8 м",
        ],
        correctAnswers: ["Длиной не менее 6 м"],
      },
      {
        code: "20118055",
        text: "В каком случае допускается водяное и паровое отопление в помещениях приготовления клеев, маканых изделий из клеев, шпредингования тканей, приготовления эбонитовой пыли?",
        answers: [
          "Только в случаях, если температура поверхностей нагревательных приборов и трубопроводов не превысит 95% температуры самовоспламенения продуктов, которые могут находиться в помещении",
          "Только в случаях, если температура поверхностей нагревательных приборов и трубопроводов не превысит 90% температуры самовоспламенения продуктов, которые могут находиться в помещении",
          "Только в случаях, если температура поверхностей нагревательных приборов и трубопроводов не превысит 80% температуры самовоспламенения продуктов, которые могут находиться в помещении",
        ],
        correctAnswers: [
          "Только в случаях, если температура поверхностей нагревательных приборов и трубопроводов не превысит 80% температуры самовоспламенения продуктов, которые могут находиться в помещении",
        ],
      },
      {
        code: "20118056",
        text: "С какой периодичностью необходимо удалять шлак с поверхности расплава солей, во избежание его загорания?",
        answers: [
          "Не реже 2 раз в смену",
          "Не реже 3 раз в неделю",
          "Не реже раза в смену",
        ],
        correctAnswers: ["Не реже 2 раз в смену"],
      },
      {
        code: "20118057",
        text: "Какой допускается максимальный зазор между ограничительными стрелками и валками во избежание захвата рукавицы и руки рабочего при эксплуатации основного технологического оборудования?",
        answers: ["1,5 - 2 мм", "2 - 4 мм", "4 мм", "2,5 - 3,5 мм"],
        correctAnswers: ["1,5 - 2 мм"],
      },
      {
        code: "20118058",
        text: "Какое из перечисленных требований к каландрам указано верно? Выберите два правильных варианта ответа.",
        answers: [
          "Каландры должны иметь устройства для аварийной их остановки. Эти устройства необходимо располагать с передней и задней сторон каландров",
          "Обрезка резиновой кромки с обрезиненной ткани (корда) должна быть механизирована",
          "В схеме управления каландром должна быть предусмотрена световая сигнализация, предшествующая пуску каландра",
          "Питание каландра резиновой смесью должно быть только автоматическим",
          "Допускается вырезка образцов обрезиненной ткани (корда), расправка складок на ткани (корде) на рабочей скорости",
        ],
        correctAnswers: [
          "Каландры должны иметь устройства для аварийной их остановки. Эти устройства необходимо располагать с передней и задней сторон каландров",
          "Обрезка резиновой кромки с обрезиненной ткани (корда) должна быть механизирована",
        ],
      },
      {
        code: "20118059",
        text: "В каком случае необходимо предусматривать устойчивую площадку с ограждением и сплошными ступеньками для обслуживания загрузочной воронки на червячной машине?",
        answers: [
          "При расположении загрузочной воронки выше 1500 мм от пола",
          "При расположении загрузочной воронки выше 850 мм от пола",
          "При расположении загрузочной воронки выше 700 мм от пола",
          "При расположении загрузочной воронки выше 1200 мм от пола",
        ],
        correctAnswers: [
          "При расположении загрузочной воронки выше 1500 мм от пола",
        ],
      },
      {
        code: "20118060",
        text: "При какой минимальной температуре резиновой смеси, подающейся в червячную машину, рабочий должен работать в рукавицах?",
        answers: ["60 °C", "70 °C", "50 °C", "80 °C"],
        correctAnswers: ["60 °C"],
      },
      {
        code: "20118061",
        text: "Включение каких механизмов должны исключать блокирующие устройства диагонально-резательных агрегатов?",
        answers: [
          "Привода отборочного конвейера агрегата при перемещении каретки",
          "Агрегата при снятом ограждении ножа",
          "Привода перемещения каретки при движении отборочного конвейера агрегата",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20118062",
        text: "Какая устанавливается максимальная температура нагретых поверхностей вулканизационного оборудования, трубопроводов и ограждений на рабочих местах?",
        answers: ["45 °C", "35 °C", "55 °C", "65 °C"],
        correctAnswers: ["45 °C"],
      },
      {
        code: "20118063",
        text: "Какое из перечисленных устройств не должны в обязательном порядке иметь многопозиционные вулканизаторы покрышек?",
        answers: [
          "Защитный козырек",
          "Штанговые аварийные выключатели для остановки движения перезарядчиков, расположенные с двух сторон перезарядчика (со стороны площадки обслуживания вулканизатора и со стороны стойки МИМов)",
          "Световой сигнал об отсутствии давления в диафрагме или паровой камере",
          "Индикатор утечки теплоносителя из диафрагмы, установленной на верхней полуформе",
        ],
        correctAnswers: ["Защитный козырек"],
      },
      {
        code: "20118064",
        text: "В каком случае должна предусматриваться локальная система пожаротушения для тушения возможного загорания резиновой смеси в камере смешения резиносмесителя?",
        answers: [
          "В камере смешения резиносмесителя емкостью более 100 л",
          "В камере резиносмесителя любого объема при смешивании резиновой смеси низкой температуры воспламенения",
          "Если это установлено проектной документацией",
        ],
        correctAnswers: [
          "В камере смешения резиносмесителя емкостью более 100 л",
        ],
      },
      {
        code: "20118065",
        text: "В каком случае допускается подача растворителей в смесители клея и герметиков с использованием переносных сосудов?",
        answers: [
          "Не допускается ни в каком случае",
          "Если работы выполняются не менее чем двумя работниками",
          "В случае недостаточной производительности счетчиков-дозаторов",
          "При наличии обоснования проектной документацией",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20118066",
        text: "Навеска какого количества покрышек разрешается на подвеску конвейера сушильной камеры при шиноремонтном производстве?",
        answers: [
          "Трех покрышек",
          "Двух покрышек",
          "Четырех покрышек",
          "Только одной покрышки",
        ],
        correctAnswers: ["Только одной покрышки"],
      },
      {
        code: "20118067",
        text: "С какой периодичностью следует чистить светильники, установленные в подготовительных и других цехах, на участках с большим выделением пыли?",
        answers: [
          "1 раз в месяц",
          "1 раз в 3 месяца",
          "1 раз в 6 месяцев",
          "1 раз в год",
        ],
        correctAnswers: ["1 раз в месяц"],
      },
      {
        code: "20118068",
        text: "В каком исполнении следует применять электрическое оборудование (пускатели, электрощиты, лампы освещения и т. п.) на участке шероховки покрышек?",
        answers: [
          "Не ниже IP20",
          "Не ниже IP65",
          "Не ниже IP54",
          "Не ниже IP24",
        ],
        correctAnswers: ["Не ниже IP54"],
      },
      {
        code: "20118069",
        text: "В каком случае работа по гуммированию внутренних поверхностей аппаратов и емкостей может не прекращаться?",
        answers: [
          "При проливе клея и растворителей внутри аппарата или емкости",
          "Во время грозы, если аппарат или емкость находятся в здании",
          "При производстве огневых работ",
          "При остановке вентилятора, обеспечивающего воздухообмен в аппарате, емкости",
        ],
        correctAnswers: [
          "Во время грозы, если аппарат или емкость находятся в здании",
        ],
      },
      {
        code: "20118070",
        text: "Электроосвещение каким напряжением должна иметь охладительная установка протекторного агрегата?",
        answers: [
          "Не выше 60 В",
          "Не выше 12 В",
          "Не выше 36 В",
          "Не выше 48 В",
        ],
        correctAnswers: ["Не выше 36 В"],
      },
      {
        code: "20118071",
        text: "При какой максимальной температуре формовых и неформовых изделий следует выгружать изделия из термостатов?",
        answers: ["45 °C", "35 °C", "55 °C", "40 °C"],
        correctAnswers: ["45 °C"],
      },
      {
        code: "20118072",
        text: "Каким количеством работников должна осуществляться разборка дорна после сборки кранцев?",
        answers: ["Двумя", "Тремя", "Одним", "Определяется руководителем"],
        correctAnswers: ["Двумя"],
      },
      {
        code: "20118073",
        text: "Какое из перечисленных требований допускается при производстве формовых и неформовых изделий?",
        answers: [
          "Хранить хладагент в местах его потребления",
          "Производить шероховку и т. п. работы внутри изделия механизированным инструментом (дрелями) с пневмоприводом",
          "Изготавливать заготовки сырой резины для формовых резинотехнических изделий ручным способом",
          "Применять сухой кварцевый песок под давлением для очистки арматуры от окислов",
        ],
        correctAnswers: [
          "Производить шероховку и т. п. работы внутри изделия механизированным инструментом (дрелями) с пневмоприводом",
        ],
      },
      {
        code: "20118074",
        text: "Каким количеством работников следует производить перемещение рукавов с дорнами в накопитель и на бинтовку при длине дорнов более 4 м на комбинат-машине?",
        answers: ["Двумя", "Одним", "Тремя", "Определяется руководителем"],
        correctAnswers: ["Двумя"],
      },
      {
        code: "20118075",
        text: "В каких средствах индивидуальной защиты необходимо выполнять все работы с соляной и серной кислотами при производстве эбонитовых изделий и эбонитовой пыли?",
        answers: [
          "В резиновом фартуке",
          "В защитных очках",
          "В резиновой обуви",
          "В противокислотных перчатках",
          "Во всех перечисленных",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "20118076",
        text: "С какой периодичностью необходимо проверять надежность крепления подвижного электрода камеры сушки при сушке латексных губчатых изделий токами высокой частоты?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в неделю",
          "Не реже одного раза в смену",
          "Не реже двух раз в неделю",
        ],
        correctAnswers: ["Не реже одного раза в смену"],
      },
      {
        code: "20118077",
        text: "Каким должно быть расстояние от открытых площадок, на которых производится хранение утильных и изношенных покрышек, до зданий и сооружений?",
        answers: [
          "Не менее 30 м",
          "Не менее 50 м",
          "Не менее 10 м",
          "Не менее 100 м",
        ],
        correctAnswers: ["Не менее 30 м"],
      },
      {
        code: "20118078",
        text: "Что из перечисленного должно подаваться по трубопроводам при производстве регенерата?",
        answers: [
          "Подача сырья в вальцы",
          "Подача сырья в девулканизаторы",
          "Подача сырья в вибрационные сита",
          "Подача мягчителей в смеситель",
        ],
        correctAnswers: ["Подача мягчителей в смеситель"],
      },
      {
        code: "20118079",
        text: "Что из перечисленного должно подаваться с использованием конвейеров при приготовлении пропиточного состава, пропитки и термообработки корда (ткани)?",
        answers: [
          "Подача жидких компонентов пропиточного состава в мерники",
          "Подача жидких компонентов пропиточного состава в дозаторы",
          "Подача жидких компонентов пропиточного состава в реакторы",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20118080",
        text: "Переносные лампы с каким напряжением необходимо использовать для освещения при чистке реактора при приготовлении пропиточного состава, пропитки и термообработки корда (ткани)?",
        answers: [
          "Не выше 12 В",
          "Не выше 36 В",
          "Не выше 24 В",
          "Не выше 48 В",
        ],
        correctAnswers: ["Не выше 12 В"],
      },
      {
        code: "20118081",
        text: "На каком оборудовании следует производить осмотр уплотнительной прокладки не реже одного раза в смену?",
        answers: [
          "На форматорах-вулканизаторах",
          "На всем перечисленном оборудовании",
          "На индивидуальных вулканизаторах",
          "На автоклавах-прессах",
        ],
        correctAnswers: ["На всем перечисленном оборудовании"],
      },
      {
        code: "20118082",
        text: "В каком из перечисленных случаев допускается эксплуатация вулканизационных прессов?",
        answers: [
          "При наличии в них пропусков масла",
          "При наличии в них пропусков сжатого воздуха",
          "При наличии в них пропусков пара",
          "Во всех перечисленных случаях эксплуатация не допускается",
        ],
        correctAnswers: [
          "Во всех перечисленных случаях эксплуатация не допускается",
        ],
      },
      {
        code: "20118083",
        text: "С какой периодичностью предохранительные клапаны, установленные на автоклавах вулканизации эбонитовых изделий, должны проходить ревизию с проверкой их установочного давления на стенде?",
        answers: [
          "Не реже одного раза в квартал",
          "Не реже одного раза в месяц",
          "Не реже одного раза в год",
          "Не реже одного раза в 6 месяцев",
        ],
        correctAnswers: ["Не реже одного раза в квартал"],
      },
      {
        code: "20118084",
        text: "За какое время до начала работы технологического оборудования следует включать местные вытяжные вентиляционные установки, не сблокированные с технологическим оборудованием?",
        answers: [
          "За 3 - 5 минут",
          "За 10 - 15 минут",
          "За 7 - 10 минут",
          "За 15 - 20 минут",
        ],
        correctAnswers: ["За 3 - 5 минут"],
      },
      {
        code: "20118085",
        text: "Через какое время следует выключать приточные и общеобменные вытяжные установки после окончания работы цеха?",
        answers: [
          "Через 10 - 12 минут",
          "Через 15- 20 минут",
          "Через 2 - 5 минут",
          "Через 5 - 7 минут",
        ],
        correctAnswers: ["Через 10 - 12 минут"],
      },
      {
        code: "20118086",
        text: "Какие баллоны со сжатым инертным газом, установленные в металлических шкафах, разрешается располагать в производственных помещениях?",
        answers: [
          "Емкостью до 100 л каждый",
          "Емкостью до 200 л каждый",
          "Емкостью до 250 л каждый",
          "Емкостью до 150 л каждый",
        ],
        correctAnswers: ["Емкостью до 100 л каждый"],
      },
      {
        code: "20118087",
        text: "В каком случае допускается транспортировка жидкости путем передавливания сжатым воздухом?",
        answers: [
          "Растительных масел и жиров, нагретых от 30 °C до 50 °C",
          "При температуре горючих жидкостей, нагретых выше 80 °C",
          "При температуре легковоспламеняющихся жидкостей, нагретых выше 30 °C",
        ],
        correctAnswers: [
          "Растительных масел и жиров, нагретых от 30 °C до 50 °C",
        ],
      },
      {
        code: "20118088",
        text: "В каком количестве разрешается хранение смазочных масел в шкафах или ящиках из несгораемых материалов с плотно закрывающимися крышками в производственных помещениях?",
        answers: [
          "Не более 20 л",
          "Не более 30 л",
          "Не более 40 л",
          "Не более 50 л",
        ],
        correctAnswers: ["Не более 20 л"],
      },
      {
        code: "20118089",
        text: "На какое расстояние допускается приближение рук работающего к перечисленным механизмам?",
        answers: [
          "150 мм к пуансону при пробивке отверстий в ободной ленте",
          "180 мм к зазору протягивающих роликов дублера при выходе сердечника конвейерной ленты",
          "200 мм к бобине в процессе перемотки бинта, ножу при отборе резиновой кромки или профилировании заготовок клиновых ремней, отборочному валку при заправке в него кромки сердечника конвейерной ленты",
        ],
        correctAnswers: [
          "150 мм к пуансону при пробивке отверстий в ободной ленте",
        ],
      },
      {
        code: "20118090",
        text: "Что из перечисленного должно быть предусмотрено планом мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Только первоочередные действия при получении сигнала об авариях на объекте",
          "Только порядок обеспечения постоянной готовности сил и средств к локализации и ликвидации последствий аварий на объекте с указанием организаций, которые несут ответственность за поддержание этих сил и средств в надлежащей степени готовности",
          "Только организация материально-технического, инженерного и финансового обеспечения операций по локализации и ликвидации аварий на объекте",
          "Только система взаимного обмена информацией между организациями - участниками локализации и ликвидации последствий аварий на объекте",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20118091",
        text: "Что из перечисленного не является обязательной составляющей плана мероприятий по локализации и ликвидации последствий аварий на ОПО?",
        answers: [
          "Действия производственного персонала и аварийно-спасательных служб (формирований) по локализации и ликвидации аварийных ситуаций",
          "Количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте, и их соответствие задачам по локализации и ликвидации последствий аварий",
          "Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий",
          "Результаты расчета риска возникновения аварий на опасном производственном объекте",
        ],
        correctAnswers: [
          "Результаты расчета риска возникновения аварий на опасном производственном объекте",
        ],
      },
      {
        code: "20118092",
        text: "Что определяется в специальном разделе плана мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий, а также источники (места) их возникновения",
          "Порядок действий в случае аварий на объекте",
          "Организация взаимодействия сил и средств",
          "Мероприятия, направленные на обеспечение безопасности населения (в случае если в результате аварий на объекте может возникнуть угроза безопасности населения)",
        ],
        correctAnswers: ["Порядок действий в случае аварий на объекте"],
      },
      {
        code: "20118093",
        text: "В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "В целях повышения устойчивости развития организации",
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
          "В целях обеспечения взаимодействия между пожарной охраной и другими службами, участвующими в локализации и ликвидации последствий аварии",
          "В целях обеспечения соответствия объекта требованиям законодательства",
        ],
        correctAnswers: [
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
        ],
      },
      {
        code: "20118094",
        text: "Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?",
        answers: [
          "Только для опасных производственных объектов I класса опасности",
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          "Только для опасных производственных объектов I и II классов опасности",
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "20118095",
        text: "Каким образом необходимо разработать план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах в случае, если 2 и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?",
        answers: [
          "Допускается разработка единого плана мероприятий для 2 и более объектов",
          "Разрабатывается один план для одной организации, независимо от расположения объектов",
          "Разрабатывается один план при условии что объекты находятся на территории одного субъекта Российской Федерации",
          "Необходимо разработать планы мероприятий для каждого объекта отдельно",
        ],
        correctAnswers: [
          "Допускается разработка единого плана мероприятий для 2 и более объектов",
        ],
      },
      {
        code: "20118096",
        text: "Кто утверждает план мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Технический руководитель предприятия",
          "Руководитель территориального органа Ростехнадзора",
          "Специалист по гражданской обороне и чрезвычайным ситуациям",
          "Специалист по промышленной безопасности",
        ],
        correctAnswers: ["Технический руководитель предприятия"],
      },
      {
        code: "20118097",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов I класса опасности (за исключением объектов, на которых ведутся горные работы)?",
        answers: ["1 год", "2 года", "3 года", "5 лет"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20118098",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов II класса опасности (за исключением объектов, на которых ведутся горные работы)?",
        answers: ["1 год", "2 года", "3 года", "5 лет"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20118099",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены на опасных производственных объектах III класса опасности (за исключением объектов, на которых ведутся горные работы)?",
        answers: ["1 год", "2 года", "3 года", "5 лет"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20118100",
        text: "Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?",
        answers: [
          "Наименьший срок, предусмотренный для этих объектов",
          "5 лет",
          "2 года",
          "Срок устанавливается территориальным органом Ростехнадзора",
        ],
        correctAnswers: ["Наименьший срок, предусмотренный для этих объектов"],
      },
      {
        code: "20118101",
        text: "В какой срок пересматривается план мероприятий по локализации и ликвидации последствий аварий до истечения срока действия предыдущего плана мероприятий?",
        answers: [
          "Не менее чем за 15 календарных дней",
          "Не менее чем за 30 календарных дней",
          "Не менее чем за 3 месяца",
          "Не менее чем за 10 рабочих дней",
        ],
        correctAnswers: ["Не менее чем за 15 календарных дней"],
      },
      {
        code: "20118102",
        text: "В каком из перечисленных случаев пересматриваются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.",
        answers: [
          "Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий",
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
          "Не позднее 10 рабочих дней после изменения сведений, содержащихся в общих или специальных разделах плана мероприятий",
          "Не позднее 15 календарных дней после внесения изменений в системы управления технологическими процессами на объекте",
        ],
        correctAnswers: [
          "Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий",
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
        ],
      },
      {
        code: "20118103",
        text: "Кем утверждаются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
          "Инспектором территориального органа Ростехнадзора",
          "Главным пожарным инспектором МЧС России",
          "Специалистом по промышленной безопасности",
        ],
        correctAnswers: [
          "Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
        ],
      },
      {
        code: "20118104",
        text: "Кем должны согласовываться планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Руководителями территориальных управлений Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "Руководителями территориальных органов Ростехнадзора",
          "Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
          "Руководителями территориальных органов Росприроднадзора",
        ],
        correctAnswers: [
          "Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
        ],
      },
      {
        code: "20118105",
        text: "С какой целью не разрабатывается план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "С целью выявления достаточности принятых мер по предупреждению аварий на объекте",
          "С целью планирования действий персонала опасного производственного объекта и специализированных служб на различных уровнях развития ситуаций",
          "С целью определения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
          "С целью разработки декларации промышленной безопасности",
        ],
        correctAnswers: [
          "С целью разработки декларации промышленной безопасности",
        ],
      },
      {
        code: "20118106",
        text: "Что в том числе должно входить в состав приложений к плану мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Действия ответственного руководителя и работников по локализации и ликвидации аварий и их последствий",
          "Заключение экспертизы промышленной безопасности",
          "Сведения о квалификации работников, ответственных за организацию мероприятий по локализации и ликвидации последствий аварий",
          "Описание аварий, произошедших на аналогичных опасных производственных объектах за последние 5 лет",
        ],
        correctAnswers: [
          "Действия ответственного руководителя и работников по локализации и ликвидации аварий и их последствий",
        ],
      },
      {
        code: "20118107",
        text: "Что из перечисленного не рекомендуется включать в список оповещения об аварии?",
        answers: [
          "Фамилию, имя и отчество",
          "Адрес проживания",
          "Перечень должностных лиц с указанием наименования подразделений, организаций",
          "Должность оповещаемого лица",
          "Номера контактных телефонов",
        ],
        correctAnswers: ["Адрес проживания"],
      },
      {
        code: "20118108",
        text: "С какой целью разрабатывается план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "С целью выявления достаточности принятых мер по предупреждению аварий на объекте",
          "С целью планирования действий персонала опасного производственного объекта и специализированных служб на различных уровнях развития ситуаций",
          "С целью обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
    ],
  },
  79: {
    20201: [
      {
        code: "20201000",
        text: "В каком порядке осуществляется допуск подрядных организаций на опасные производственные объекты нефтегазодобывающих производств?",
        answers: [
          "В соответствии с Положением о порядке допуска и организации безопасного производства работ, утвержденным организацией, эксплуатирующей опасные производственные объекты нефтегазодобывающих производств",
          "В соответствии с приказом, утвержденным руководителем организации, эксплуатирующей опасные производственные объекты нефтегазодобывающих производств",
          "В соответствии с производственной инструкцией, устанавливающей требования к организации работ утвержденной организацией, эксплуатирующей опасные производственные объекты нефтегазодобывающих производств",
          "В соответствии с производственным заданием, выданным руководителем организации, эксплуатирующей опасные производственные объекты",
        ],
        correctAnswers: [
          "В соответствии с Положением о порядке допуска и организации безопасного производства работ, утвержденным организацией, эксплуатирующей опасные производственные объекты нефтегазодобывающих производств",
        ],
      },
      {
        code: "20201001",
        text: "Кто утверждает перечень работ, осуществляемых по наряду-допуску, порядок оформления нарядов-допусков, перечни должностей специалистов, имеющих право руководить этими работами?",
        answers: [
          "Руководитель вышестоящей организации",
          "Представитель территориального органа Ростехнадзора",
          "Руководитель организации или уполномоченное им лицо",
          "Руководитель регионального центра МЧС России",
          "Технический руководитель (главный инженер) организации",
        ],
        correctAnswers: ["Руководитель организации или уполномоченное им лицо"],
      },
      {
        code: "20201002",
        text: "Требования какого документа обеспечивают безопасность технологических процессов на объектах добычи, сбора и подготовки нефти, газа и газового конденсата?",
        answers: [
          "Производственной инструкции по эксплуатации оборудования",
          "Проектной документации на эксплуатацию опасного производственного объекта",
          "Федеральных норм и правил в области промышленной безопасности",
          "Технологического регламента (ТР) на каждый технологический процесс опасного производственного объекта",
        ],
        correctAnswers: [
          "Технологического регламента (ТР) на каждый технологический процесс опасного производственного объекта",
        ],
      },
      {
        code: "20201003",
        text: "Какие требования предъявляются к техническим устройствам, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями?",
        answers: [
          "Документация на технические устройства, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями, должна быть согласована с разработчиком этого оборудования",
          "Документация на технические устройства, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями, должна быть согласована с надзорными органами",
          "Технические устройства, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями, должны пройти приемо-сдаточные испытания, результаты которых оформляются актом эксплуатирующей организации",
        ],
        correctAnswers: [
          "Технические устройства, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями, должны пройти приемо-сдаточные испытания, результаты которых оформляются актом эксплуатирующей организации",
        ],
      },
      {
        code: "20201004",
        text: "Кем определяются критерии предельных состояний и критерии вывода из эксплуатации оборудования, инструментов, контрольно-измерительных приборов?",
        answers: [
          "Определяются изготовителем и вносятся в эксплуатационную документацию изготовителя или разработчика технического устройства",
          "Определяются территориальным органом Ростехнадзора на основании экспертизы промышленной безопасности",
          "Определяются эксплуатирующей организацией на основании их испытаний",
        ],
        correctAnswers: [
          "Определяются изготовителем и вносятся в эксплуатационную документацию изготовителя или разработчика технического устройства",
        ],
      },
      {
        code: "20201005",
        text: "Какими светильниками должны быть обеспечены опасные производственные объекты нефтегазодобывающих производств?",
        answers: [
          "Стационарными светильниками напряжением 12 В во взрывозащищенном исполнении",
          "Стационарными светильниками напряжением 36 В во взрывозащищенном исполнении",
          "Переносными светильниками, для питания которых должно применяться напряжение не выше 50 В, - в особо опасных помещениях, а в наружных установках - не выше 12 В",
          "Переносными светильниками напряжением 24 В во взрывозащищенном исполнении",
        ],
        correctAnswers: [
          "Переносными светильниками, для питания которых должно применяться напряжение не выше 50 В, - в особо опасных помещениях, а в наружных установках - не выше 12 В",
        ],
      },
      {
        code: "20201006",
        text: "Разрешается ли последовательно включать в заземляющее устройство несколько заземляемых объектов?",
        answers: [
          "Разрешается по согласованию с главным энергетиком организации",
          "Разрешается",
          "Разрешается в исключительных случаях и по согласованию с территориальным органом Ростехнадзора",
          "Запрещается",
        ],
        correctAnswers: ["Запрещается"],
      },
      {
        code: "20201007",
        text: "Какие требования предъявляются к работникам, осуществляющим непосредственное руководство и выполнение работ по бурению, освоению, ремонту, реконструкции, консервации и ликвидации скважин, ведению геофизических и ПВР на скважинах?",
        answers: [
          "Должны ежегодно проходить аттестацию в области промышленной безопасности",
          "Должны иметь разрешение от надзорных органов на право руководства работами",
          "Должны раз в 2 года дополнительно проходить проверку знаний по контролю и управлению скважиной при ГНВП",
        ],
        correctAnswers: [
          "Должны раз в 2 года дополнительно проходить проверку знаний по контролю и управлению скважиной при ГНВП",
        ],
      },
      {
        code: "20201008",
        text: "На основании какого документа разрешается проведение работ в замкнутом пространстве?",
        answers: [
          "На основании письменного разрешения руководителя работ",
          "На основании наряда-допуска",
          "На основании утвержденного плана работ",
        ],
        correctAnswers: ["На основании наряда-допуска"],
      },
      {
        code: "20201009",
        text: "Кто проводит отбор проб воздуха в замкнутом пространстве перед допуском лиц, осуществляющих работу?",
        answers: [
          "Руководитель работ",
          "Персонал, уполномоченный руководителем работ",
          "Работники, имеющие допуск и обученные в этих целях",
        ],
        correctAnswers: ["Работники, имеющие допуск и обученные в этих целях"],
      },
      {
        code: "20201010",
        text: "Сколько человек может работать в замкнутом пространстве одновременно?",
        answers: ["Два", "Три", "Один"],
        correctAnswers: ["Один"],
      },
      {
        code: "20201011",
        text: "Какой персонал имеет право на проведение ремонта электрооборудования и освещения?",
        answers: [
          "Персонал, получивший целевой инструктаж",
          "Персонал, назначенный руководителем работ",
          "Электротехнический персонал",
        ],
        correctAnswers: ["Электротехнический персонал"],
      },
      {
        code: "20201012",
        text: "Какой персонал имеет право на проведение ремонта электрообезвоживающей и обессоливающей установок?",
        answers: [
          "Электротехнический персонал",
          "Персонал, получивший целевой инструктаж",
          "Электротехнический персонал, допущенный к работам на электроустановках напряжением выше 1000 В",
        ],
        correctAnswers: [
          "Электротехнический персонал, допущенный к работам на электроустановках напряжением выше 1000 В",
        ],
      },
      {
        code: "20201013",
        text: "Какими организациями разрабатываются и утверждаются технологические регламенты на работы по добыче, сбору и подготовке нефти, газа и газового конденсата?",
        answers: [
          "Разрабатываются проектной организацией на стадии проектирования и строительства, а также реконструкции. ТР на ОПО, находящийся в эксплуатации, может разрабатываться эксплуатирующей организацией",
          "Разрабатываются специализированными организациями, а утверждаются территориальным органом Ростехнадзора",
          "Разрабатываются и утверждаются компанией-оператором",
          "Разрабатываются проектной организацией, а утверждаются эксплуатирующей организацией",
          "Разрабатываются проектной организацией, а утверждаются территориальным органом Ростехнадзора",
        ],
        correctAnswers: [
          "Разрабатываются проектной организацией на стадии проектирования и строительства, а также реконструкции. ТР на ОПО, находящийся в эксплуатации, может разрабатываться эксплуатирующей организацией",
        ],
      },
      {
        code: "20201014",
        text: "Что должно быть предусмотрено в оперативной части ПЛА?",
        answers: [
          "Только мероприятия по спасению людей и ликвидации аварии",
          "Только все виды возможных аварий на данном объекте",
          "Только способы оповещения об аварии (например, сирена, световая сигнализация, громкоговорящая связь, телефон), пути выхода людей из опасных мест и участков в зависимости от характера аварии, действия технического персонала, режимы работы вентиляции при возникновении аварии, необходимость и последовательность выключения электроэнергии, ограничение допуска персонала в аварийную зону",
          "Только места нахождения средств для спасения людей и ликвидации аварий",
          "Только действия газоспасателей, пожарных и других подразделений",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20201015",
        text: "Кто утверждает ПЛА?",
        answers: [
          "Руководитель службы охраны труда",
          "Технический руководитель предприятия",
          "Главный механик",
          "Сотрудник, ответственный за организацию и осуществление производственного контроля",
        ],
        correctAnswers: ["Технический руководитель предприятия"],
      },
      {
        code: "20201016",
        text: "Кто утверждает план мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Технический руководитель предприятия",
          "Руководитель службы охраны труда",
          "Сотрудник, ответственный за организацию и осуществление производственного контроля",
          "Главный механик",
        ],
        correctAnswers: ["Технический руководитель предприятия"],
      },
      {
        code: "20201017",
        text: "Из каких разделов состоит план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Только из общих разделов",
          "Только из специальных разделов",
          "Из общих и специальных разделов",
          "Из общих разделов, введения и приложений",
        ],
        correctAnswers: ["Из общих и специальных разделов"],
      },
      {
        code: "20201018",
        text: "Какую информацию включает в себя общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Только сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий, а также источники (места) их возникновения",
          "Только действия производственного персонала и аварийно-спасательных служб (формирований) по локализации и ликвидации аварий",
          "Только количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте, и их соответствие задачам по локализации и ликвидации последствий аварий",
          "Всю перечисленную информацию",
        ],
        correctAnswers: ["Всю перечисленную информацию"],
      },
      {
        code: "20201019",
        text: "Что определяется в специальном разделе плана мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Порядок действий в случае аварий на объекте",
          "Первоочередные действия при получении сигнала об авариях на объекте",
          "Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий",
          "Состав и дислокация сил и средств",
        ],
        correctAnswers: ["Порядок действий в случае аварий на объекте"],
      },
      {
        code: "20201020",
        text: "В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
          "В целях определения действий подразделений организации при возникновении аварии",
          "В целях обеспечения соответствия объекта требованиям промышленной безопасности",
          "В целях определения действий персонала при ликвидации последствий аварий",
        ],
        correctAnswers: [
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
        ],
      },
      {
        code: "20201021",
        text: "Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?",
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          "Только для ОПО I класса опасности",
          "Только для ОПО II класса опасности",
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "20201022",
        text: "Каким образом необходимо разработать план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах в случае, если 2 и более объекта, эксплуатируемые одной организацией, расположены на одном земельном участке или на смежных земельных участках?",
        answers: [
          "Допускается разработка единого плана мероприятий только для объектов, находящихся на расстоянии менее 50 м друг от друга",
          "Разрабатываются планы мероприятий на каждый объект",
          "Допускается разработка единого плана мероприятий для 2 и более объектов",
          "Разрабатывается один план мероприятий для объектов, расположенных на одном участке, и несколько - для объектов на смежных участках",
        ],
        correctAnswers: [
          "Допускается разработка единого плана мероприятий для 2 и более объектов",
        ],
      },
      {
        code: "20201023",
        text: "Сколько необходимо разработать планов мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, в случае если 2 и более объекта, эксплуатируемые одной организацией, расположены на одном земельном участке или на смежных земельных участках?",
        answers: [
          "Разрабатывается единый план мероприятий для двух и более объектов",
          "Разрабатываются планы мероприятий для каждого объекта",
          "Разрабатывается единый план мероприятий только для объектов, находящихся на расстоянии менее 50 м друг от друга",
          "Разрабатывается один план мероприятий для объектов, расположенных на одном участке, и несколько - для объектов на смежных участках",
        ],
        correctAnswers: [
          "Разрабатывается единый план мероприятий для двух и более объектов",
        ],
      },
      {
        code: "20201024",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены на опасных производственных объектах III класса опасности (за исключением объектов, на которых ведутся горные работы)?",
        answers: ["1 год", "2 года", "3 года", "5 лет"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20201025",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов I класса опасности (за исключением объектов, на которых ведутся горные работы)?",
        answers: ["1 год", "3 года", "5 лет", "2 года"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20201026",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов II класса опасности (за исключением объектов, на которых ведутся горные работы)?",
        answers: ["1 год", "5 лет", "3 года", "2 года"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20201027",
        text: "Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?",
        answers: [
          "Наименьший срок, предусмотренный для этих объектов",
          "Наибольший срок, предусмотренный для этих объектов",
          "5 лет",
          "3 года",
        ],
        correctAnswers: ["Наименьший срок, предусмотренный для этих объектов"],
      },
      {
        code: "20201028",
        text: "В каком из перечисленных случаев план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах должен быть пересмотрен?",
        answers: [
          "Не позднее 30 рабочих дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
          "Не позднее 45 рабочих дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
          "Не позднее 45 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
        ],
        correctAnswers: [
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
        ],
      },
      {
        code: "20201029",
        text: "Кто утверждает планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два варианта ответа.",
        answers: [
          "Руководители обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
          "Руководитель (заместители руководителей) организаций, эксплуатирующих объекты",
          "Руководители профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований",
          "Руководитель территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "Руководители обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
          "Руководитель (заместители руководителей) организаций, эксплуатирующих объекты",
        ],
      },
      {
        code: "20201030",
        text: "Кем должны согласовываться планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
          "Руководителями территориальных органов Федеральной службы по экологическому, технологическому и атомному надзору",
          "Руководителями территориальных органов Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "Всеми перечисленными лицами",
        ],
        correctAnswers: [
          "Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
        ],
      },
      {
        code: "20201031",
        text: "На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?",
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          "На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах горнорудной и металлургической промышленности",
          "На ведение газоопасных, огневых и ремонтных работ на линейных объектах магистрального трубопроводного транспорта нефти, газа и газового конденсата, нефтепродуктопроводов",
          "На ведение газоопасных, огневых и ремонтных работ на стационарных автомобильных газозаправочных станциях, автомобильных газонаполнительных компрессорных станциях и криогенных автозаправочных станциях газомоторного топлива",
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "20201032",
        text: "Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
          "По распоряжению на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ",
          "Выполнение работ, не включенных в утвержденный перечень газоопасных работ, не допускается",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
      {
        code: "20201033",
        text: "Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?",
        answers: [
          "Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск на необходимый для окончания работ срок",
          "Руководитель структурного подразделения не более чем на 2 рабочих смены",
          "Лицо, зарегистрировавшее наряд-допуск не более чем на 3 дневных смены",
        ],
        correctAnswers: [
          "Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
        ],
      },
      {
        code: "20201034",
        text: "В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?",
        answers: [
          "Не менее 3 месяцев со дня закрытия наряда-допуска",
          "Не менее 2 месяцев со дня закрытия наряда-допуска",
          "Не менее 6 месяцев со дня закрытия наряда-допуска",
        ],
        correctAnswers: ["Не менее 6 месяцев со дня закрытия наряда-допуска"],
      },
      {
        code: "20201035",
        text: "Каким документом определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?",
        answers: [
          "Организационно-распорядительными документами руководителя",
          "Технологическим регламентом",
          "Федеральным законом в области промышленной безопасности",
          "Правилами пожарной безопасности",
        ],
        correctAnswers: [
          "Организационно-распорядительными документами руководителя",
        ],
      },
      {
        code: "20201036",
        text: "Какие технологические блоки относятся к первой категории взрывоопасности?",
        answers: [
          "Qв - 27 - 37, m - 2000 - 5000 кг",
          "Qв - меньше 27, m - меньше 2000 кг",
          "Qв - больше 37, m - больше 5000 кг",
        ],
        correctAnswers: ["Qв - больше 37, m - больше 5000 кг"],
      },
      {
        code: "20201037",
        text: "Оценку каких параметров необходимо произвести в проектной документации при разработке технологического процесса?",
        answers: [
          "Только оценку эффективности технических средств противоаварийной защиты, обеспечивающих взрывобезопасность технологических блоков и в целом всей технологической схемы",
          "Только оценку энергетического уровня каждого технологического блока и определение категории его взрывоопасности",
          "Только оценку эффективности и надежности мер, направленных на обеспечение взрывобезопасности каждого технологического блока",
          "В проектной документации производится оценка всех перечисленных параметров",
        ],
        correctAnswers: [
          "В проектной документации производится оценка всех перечисленных параметров",
        ],
      },
      {
        code: "20201038",
        text: "Каким документом обосновывается повышение категории взрывоопасности технологических блоков, определяемое количеством токсичных, высокотоксичных веществ, опасностью причинения ими вреда обслуживающему персоналу при вероятных сценариях развития аварийной ситуации?",
        answers: [
          "Проектной документацией",
          "Техническим регламентом",
          "Инструкцией по эксплуатации",
        ],
        correctAnswers: ["Проектной документацией"],
      },
      {
        code: "20201039",
        text: "В каком из перечисленных случаев категорию взрывоопасности блоков, определяемую расчетом, следует рассматривать на одну выше?",
        answers: [
          "Только если обращающиеся в технологическом блоке вещества относятся к токсичным веществам",
          "Только если обращающиеся в технологическом блоке вещества относятся к высокотоксичным веществам",
          "В любом из указанных случаев",
        ],
        correctAnswers: ["В любом из указанных случаев"],
      },
      {
        code: "20201040",
        text: "Какая из перечисленных мер должна предусматриваться для блоков технологической системы по максимальному снижению взрывоопасности?",
        answers: [
          "Предотвращение взрывов внутри технологического оборудования",
          "Использование вентиляционных систем для исключения возможности взрывов и пожаров",
          "Использование эффективных систем пожаротушения",
          "Все перечисленные меры",
        ],
        correctAnswers: [
          "Предотвращение взрывов внутри технологического оборудования",
        ],
      },
      {
        code: "20201041",
        text: "Какими источниками информации следует руководствоваться при разработке технологических процессов для определения регламентированных значений параметров, определяющих взрывоопасность процесса, допустимые диапазоны их измерений, критические значения параметров?",
        answers: [
          "Заданием на проектирование на основании данных о критических значениях параметров или их совокупности для участвующих в процессе веществ",
          "Справочной литературой",
          "Нормативно-технической документацией",
          "Всеми перечисленными источниками информации",
        ],
        correctAnswers: [
          "Заданием на проектирование на основании данных о критических значениях параметров или их совокупности для участвующих в процессе веществ",
        ],
      },
      {
        code: "20201042",
        text: "В каких документах приводятся способы и средства, исключающие выход параметров за установленные пределы?",
        answers: [
          "Только в проектной документации",
          "Только в исходных данных на проектирование",
          "Только в технологическом регламенте на производство продукции",
          "Во всех перечисленных",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "20201043",
        text: "В какой документации должны быть приведены способы и средства, исключающие выход параметров за установленные пределы?",
        answers: [
          "В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции",
          "Только в технологическом регламенте",
          "Только в исходных данных на проектирование",
          "Только в проектной документации",
        ],
        correctAnswers: [
          "В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции",
        ],
      },
      {
        code: "20201044",
        text: "Какое управление системами подачи инертных сред в технологические системы должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категории взрывоопасности?",
        answers: [
          "Ручное управление по месту",
          "Автоматическое",
          "Дистанционное, неавтоматическое",
        ],
        correctAnswers: ["Автоматическое"],
      },
      {
        code: "20201045",
        text: "Какое управление подачей инертных сред в технологические установки должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категории взрывоопасности?",
        answers: [
          "Автоматическое",
          "Ручное управление по месту",
          "Дистанционное, неавтоматическое",
        ],
        correctAnswers: ["Автоматическое"],
      },
      {
        code: "20201046",
        text: "Какие из перечисленных мер предусматриваются проектной документацией или документацией на техническое перевооружение для обеспечения взрывобезопасности технологической системы при пуске в работу и остановке оборудования? Укажите все правильные ответы.",
        answers: [
          "Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон",
          "Разработка режимов и порядка пуска и остановки оборудования",
          "Поддержание рабочего давления инертного газа в технологической системе в период остановки оборудования",
          "Проведение контроля за содержанием горючих веществ в технологической системе после остановки технологического оборудования",
        ],
        correctAnswers: [
          "Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон",
          "Разработка режимов и порядка пуска и остановки оборудования",
        ],
      },
      {
        code: "20201047",
        text: "Чем должны оснащаться технологические системы для обеспечения безопасности ведения технологических процессов?",
        answers: [
          "Только средствами противоаварийной защиты",
          "Только средствами автоматического регулирования",
          "Только средствами контроля за параметрами, определяющими взрывоопасность процесса",
          "Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ",
        ],
        correctAnswers: [
          "Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ",
        ],
      },
      {
        code: "20201048",
        text: "Что не должна исключать энергетическая устойчивость технологической системы с учетом категории взрывоопасности входящих в нее блоков, особенностей технологического процесса?",
        answers: [
          "Возможность образования в системе взрывоопасной среды (за счет увеличения времени пребывания продуктов в реакционной зоне, нарушения соотношения поступающих в нее продуктов, развития неуправляемых процессов)",
          "Возможность выбора рациональной схемы энергоснабжения, количества источников электропитания (основных и резервных), их надежность",
          "Возможность нарушения герметичности системы (разгерметизации уплотнений подвижных соединений, разрушения оборудования от превышения давления)",
        ],
        correctAnswers: [
          "Возможность выбора рациональной схемы энергоснабжения, количества источников электропитания (основных и резервных), их надежность",
        ],
      },
      {
        code: "20201049",
        text: "Какие из перечисленных мер по обеспечению взрывобезопасности предусматриваются проектной организацией для каждого технологического блока с учетом его энергетического потенциала? Укажите все правильные ответы.",
        answers: [
          "Предотвращение взрывов и травмирования производственного персонала",
          "Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества",
          "Повышение квалификации обслуживающего персонала на курсах переподготовки",
          "Уменьшение ущерба от аварии на взрывопожароопасном объекте",
        ],
        correctAnswers: [
          "Предотвращение взрывов и травмирования производственного персонала",
          "Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества",
        ],
      },
      {
        code: "20201050",
        text: "Чем из перечисленного оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?",
        answers: [
          "Эффективными системами, обеспечивающими непрерывность технологического процесса",
          "Системами ручного регулирования (без применения вычислительной техники)",
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
        ],
        correctAnswers: [
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
        ],
      },
      {
        code: "20201051",
        text: "Каким документом устанавливаются места расположения запорных и (или) отсекающих устройств?",
        answers: [
          "Планом производства работ",
          "Только проектной документацией",
          "Проектной документацией или документацией на техническое перевооружение",
        ],
        correctAnswers: [
          "Проектной документацией или документацией на техническое перевооружение",
        ],
      },
      {
        code: "20201052",
        text: "Каким документом определяется время срабатывания запорных и (или) отсекающих устройств при аварийной разгерметизации системы?",
        answers: [
          "Проектной документацией или документацией на техническое перевооружение",
          "Паспортом технического устройства",
          "Только проектной документацией",
          "Эксплуатационной документацией",
        ],
        correctAnswers: [
          "Проектной документацией или документацией на техническое перевооружение",
        ],
      },
      {
        code: "20201053",
        text: "Каким требованиям должны соответствовать специальные системы аварийного освобождения технологических блоков от обращающихся продуктов? Выберите 2 правильных варианта ответа.",
        answers: [
          "Быть мобильными, иметь небольшие габариты и вес",
          "Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий",
          "Обеспечивать минимально возможное время освобождения",
          "Осуществлять переход из режима ожидания в рабочее состояние в течение 30 секунд",
        ],
        correctAnswers: [
          "Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий",
          "Обеспечивать минимально возможное время освобождения",
        ],
      },
      {
        code: "20201054",
        text: "На основании каких данных определяются допустимые значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывопожароопасных характеристик, физико-химических свойств?",
        answers: [
          "На основании задания на проектирование",
          "На основании справочных данных",
          "На основании геодезической съемки",
          "На основании расчетных данных",
        ],
        correctAnswers: ["На основании задания на проектирование"],
      },
      {
        code: "20201055",
        text: "С учетом каких параметров в каждом конкретном случае в проектной документации (документации на техническое перевооружение) обосновывается решение о типе арматуры и месте ее установки на линиях всасывания и нагнетания, а также способе ее отключения, в том числе дистанционном? Укажите все правильные ответы.",
        answers: [
          "С учетом характеристики транспортируемой среды",
          "С учетом диаметра и протяженности трубопровода",
          "С учетом толщины стенки трубопровода",
          "С учетом марки стали трубопровода",
        ],
        correctAnswers: [
          "С учетом характеристики транспортируемой среды",
          "С учетом диаметра и протяженности трубопровода",
        ],
      },
      {
        code: "20201056",
        text: "В зависимости от чего осуществляется выбор конструкции и конструкционных материалов, уплотнительных устройств для насосов и компрессоров?",
        answers: [
          "В зависимости от протяженности трубопровода",
          "В зависимости от свойств перемещаемой среды",
          "В зависимости от конструктивных особенностей трубопровода",
          "В зависимости от всех перечисленных факторов",
        ],
        correctAnswers: ["В зависимости от свойств перемещаемой среды"],
      },
      {
        code: "20201057",
        text: "Какими приборами и средствами автоматизации должны оснащаться сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Укажите все правильные ответы.",
        answers: [
          "Приборами контроля температуры и плотности отделяемой жидкой фазы",
          "Приборами измерения перепада давления на сепараторе",
          "Блокировками отключения компрессора при превышении предельно допустимого значения уровня",
          "Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации",
        ],
        correctAnswers: [
          "Блокировками отключения компрессора при превышении предельно допустимого значения уровня",
          "Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации",
        ],
      },
      {
        code: "20201058",
        text: "Кем определяется степень разделения материальных сред и меры обеспечения взрывобезопасности на всех стадиях процесса?",
        answers: [
          "Разработчиками процесса и проекта",
          "Разработчиком процесса",
          "Разработчиком проекта",
          "Степень разделения определяется заказчиком в задании на проектирование, а меры взрывобезопасности - разработчиком проекта",
        ],
        correctAnswers: ["Разработчиком процесса"],
      },
      {
        code: "20201059",
        text: "Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?",
        answers: [
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
          "Давление теплоносителя (хладагента) должно быть равным давлению нагреваемых (охлаждаемых) горючих веществ",
          "Давление теплоносителя (хладагента) должно быть меньше давления нагреваемых (охлаждаемых) горючих веществ",
          "Соотношение давлений негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ устанавливается разработчиком процесса",
        ],
        correctAnswers: [
          "Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ",
        ],
      },
      {
        code: "20201060",
        text: "Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и др., нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?",
        answers: [
          "Заказчиком в задании на проектирование",
          "Разработчиками процесса и проекта",
          "Разработчиком процесса",
          "Разработчиком проекта",
        ],
        correctAnswers: ["Разработчиком процесса"],
      },
      {
        code: "20201061",
        text: "Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные газы, легковоспламеняющиеся жидкости и горючие жидкости на сливоналивных эстакадах?",
        answers: [
          "Только по месту",
          "Только дистанционно (из безопасного места)",
          "По месту и дистанционно (из безопасного места)",
          "Определяется разработчиком проекта",
        ],
        correctAnswers: ["По месту и дистанционно (из безопасного места)"],
      },
      {
        code: "20201062",
        text: "Какое из перечисленных положений нарушает требования, предъявляемые к прокладке трубопроводов на объектах нефтегазодобычи?",
        answers: [
          "Трубопроводы не должны иметь фланцевых или других разъемных соединений",
          "Материал фланцев, конструкция уплотнения применяются в соответствии с нормативными техническими документами, устанавливающими требования к технологическим трубопроводам с учетом условий эксплуатации",
          "Прокладка трубопроводов должна обеспечивать максимально возможную протяженность коммуникаций, исключать провисания и образование застойных зон",
          "При прокладке трубопроводов через строительные конструкции зданий и другие препятствия принимаются меры, исключающие возможность передачи дополнительных нагрузок на трубы",
        ],
        correctAnswers: [
          "Прокладка трубопроводов должна обеспечивать максимально возможную протяженность коммуникаций, исключать провисания и образование застойных зон",
        ],
      },
      {
        code: "20201063",
        text: "Какое из перечисленных положений не соответствует требованиям по размещению и устройству помещений управления взрывоопасных производств?",
        answers: [
          "Помещения управления должны быть отдельно стоящими",
          "В помещениях не должны размещаться оборудование и другие устройства, не связанные с системой управления технологическим процессом",
          "Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами",
          "В отдельных случаях при соответствующем обосновании в проекте разрешается пристраивать помещения управления к зданиям",
        ],
        correctAnswers: [
          "Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами",
        ],
      },
      {
        code: "20201064",
        text: "В технологических блоках какой категории взрывоопасности должны быть предусмотрены технические средства, обеспечивающие в автоматическом режиме оповещение об обнаружении, локализации и ликвидации выбросов опасных веществ?",
        answers: [
          "Только в технологических блоках I категории взрывоопасности",
          "Только в технологических блоках II категории взрывоопасности",
          "Только в технологических блоках III категории взрывоопасности",
          "В технологических блоках всех категорий взрывоопасности",
        ],
        correctAnswers: [
          "В технологических блоках всех категорий взрывоопасности",
        ],
      },
      {
        code: "20201065",
        text: "Какое положение не соответствует установленным требованиям к электрообеспечению и электрооборудованию взрывоопасных технологических схем?",
        answers: [
          "Прокладку кабелей по территории предприятий и установок разрешается выполнять открыто по эстакадам, в галереях и на кабельных конструкциях технологических эстакад",
          "Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов в соответствии с требованиями нормативно-технических документов по устройству электроустановок",
          "Запрещается прокладка кабелей в каналах, засыпанных песком, и траншеях",
          "Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение",
        ],
        correctAnswers: [
          "Запрещается прокладка кабелей в каналах, засыпанных песком, и траншеях",
        ],
      },
      {
        code: "20201066",
        text: "Где допускается расположение узла ввода теплоносителя?",
        answers: [
          "Только в самостоятельном помещении с отдельным входом с лестничной клетки или из невзрывопожароопасных производственных помещений",
          "Только в помещениях систем приточной вентиляции (в вентиляционной камере)",
          "Только в производственных помещениях, в которых предусматривается применение водяного или парового отопления",
          "Во всех указанных местах",
        ],
        correctAnswers: ["Во всех указанных местах"],
      },
      {
        code: "20201067",
        text: "Что из перечисленного является недопустимым на территории предприятия, имеющего в своем составе взрывопожароопасные производства?",
        answers: [
          "Только наличие природных оврагов, выемок, низин",
          "Только траншейная и наземная прокладка трасс трубопроводов со сжиженными горючими газами, легковоспламеняющимися и горючими жидкостями в искусственных или естественных углублениях",
          "Только устройство открытых траншей, котлованов, приямков, в которых возможно скопление взрывопожароопасных паров и газов",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20201068",
        text: "Какие требования должны быть выполнены на вновь проектируемых взрывопожароопасных и химически опасных производственных объектах?",
        answers: [
          "Только бесперебойное функционирование автоматизированных систем контроля, управления, противоаварийной автоматической защиты (ПАЗ) для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов",
          "Только защита персонала, постоянно находящегося в помещении управления (операторных), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия",
          "Должны быть обеспечены все указанные требования",
        ],
        correctAnswers: ["Должны быть обеспечены все указанные требования"],
      },
      {
        code: "20201069",
        text: "На какое давление следует производить опрессовку фонтанной арматуры в собранном виде до и после установки на устье?",
        answers: [
          "До установки на устье - на рабочее давление, указанное в паспорте, после установки - на давление опрессовки эксплуатационной колонны",
          "До установки на устье - на пробное давление, превышающее на 25 % давление опрессовки эксплуатационной колонны, после установки - на давление на 10 % выше давления опрессовки эксплуатационной колонны",
          "До установки на устье - на давление опрессовки эксплуатационной колонны, указанное в паспорте, после установки - на расчетное давление",
        ],
        correctAnswers: [
          "До установки на устье - на рабочее давление, указанное в паспорте, после установки - на давление опрессовки эксплуатационной колонны",
        ],
      },
      {
        code: "20201070",
        text: "Чем завод-изготовитель должен оснащать фонтанную арматуру?",
        answers: [
          "Устройством, обеспечивающим установку и снятие манометра при наличии давления в арматуре",
          "Регулируемыми дросселями с ручным управлением",
          "Обратными и шаровыми клапанами с ручным управлением, трехходовым краном для замены манометров",
          "Дросселями с ручным, а по требованию заказчика - с дистанционным управлением, запорной арматурой с дистанционным и/или ручным управлением",
          "Запорной арматурой с дистанционным управлением",
        ],
        correctAnswers: [
          "Дросселями с ручным, а по требованию заказчика - с дистанционным управлением, запорной арматурой с дистанционным и/или ручным управлением",
        ],
      },
      {
        code: "20201071",
        text: "Какие фонтанные скважины должны оснащаться внутрискважинным оборудованием (пакер и клапан-отсекатель, циркуляционный клапан, станция управления)?",
        answers: [
          "Фонтанные скважины с дебитом 350 т/сут нефти и более",
          "Фонтанные скважины с дебитом 300 т/сут нефти или 400 тыс. м³/сут газа и более, расположенные на расстоянии менее 700 м от населенного пункта",
          "Фонтанные скважины, расположенные на расстоянии менее 1000 м от населенного пункта",
          "Фонтанные скважины с дебитом 400 т/сут нефти или 500 тыс. м³/сут газа и более, расположенные на расстоянии менее 500 м от населенного пункта",
        ],
        correctAnswers: [
          "Фонтанные скважины с дебитом 400 т/сут нефти или 500 тыс. м³/сут газа и более, расположенные на расстоянии менее 500 м от населенного пункта",
        ],
      },
      {
        code: "20201072",
        text: "На основании чего проводится периодическая проверка клапана-отсекателя на срабатывание в процессе его эксплуатации?",
        answers: [
          "На основании распоряжения главного механика организации",
          "На основании инструкции завода-изготовителя",
          "На основании приказа технического руководителя организации",
          "На основании требований Ростехнадзора",
        ],
        correctAnswers: ["На основании инструкции завода-изготовителя"],
      },
      {
        code: "20201073",
        text: "Что необходимо устанавливать на выкидных линиях и манифольдах скважин, работающих с температурой рабочего тела 80 °С и более?",
        answers: [
          "Демпферы",
          "Аппараты воздушного охлаждения",
          "Датчики температуры",
          "Запорную арматуру с учетом ожидаемой температуры",
          "Температурные компенсаторы",
        ],
        correctAnswers: ["Температурные компенсаторы"],
      },
      {
        code: "20201074",
        text: "Разрешается ли устранение неисправностей, замена быстроизнашивающихся и сменных деталей фонтанной арматуры под давлением?",
        answers: [
          "Разрешается",
          "Разрешается только в отдельных случаях при аварийных ситуациях работниками штатных или внештатных аварийно-спасательных формирований",
          "Разрешается, если давление снижено до атмосферного",
          "Разрешается при наличии приказа о проведении опасных работ в присутствии лица, ответственного за их выполнение",
          "Запрещается",
        ],
        correctAnswers: [
          "Разрешается только в отдельных случаях при аварийных ситуациях работниками штатных или внештатных аварийно-спасательных формирований",
        ],
      },
      {
        code: "20201075",
        text: "На какое давление опрессовывается манифольд после монтажа и соединения его с отводами фонтанной арматуры и трубной головки?",
        answers: [
          "На давление, превышающее рабочее на 25 %",
          "На рабочее давление",
          "На пробное давление",
          "На максимальное давление",
        ],
        correctAnswers: ["На рабочее давление"],
      },
      {
        code: "20201076",
        text: "Какие требования предъявляются к станции управления фонтанной арматурой газлифтной скважины?",
        answers: [
          "Станция устанавливается на расстоянии 30 - 35 м от устья скважины",
          "Станция должна размещаться в специальном помещении",
          "Станция должна быть надежно укреплена и заземлена",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20201077",
        text: "Кем утверждается проект и план перевода скважины на газлифтную эксплуатацию?",
        answers: [
          "Руководителем проектной организации",
          "Руководителем организации",
          "Инспектором Ростехнадзора",
          "Мастером добычи участка",
          "Техническим руководителем организации",
        ],
        correctAnswers: ["Техническим руководителем организации"],
      },
      {
        code: "20201078",
        text: "Какие способы соединения труб используются для обвязки скважины и аппаратуры, а также для газопроводов при фонтанной и газлифтной эксплуатации скважин?",
        answers: [
          "Сварные соединения, а также фланцевые - только в местах установки задвижек и другой арматуры",
          "Только сварные соединения",
          "Соединения на хомутах и сварные соединения",
          "Фланцевые и резьбовые соединения",
          "Резьбовые соединения с последующей изоляцией",
        ],
        correctAnswers: [
          "Сварные соединения, а также фланцевые - только в местах установки задвижек и другой арматуры",
        ],
      },
      {
        code: "20201079",
        text: "Что необходимо сделать с газораспределительными трубопроводами после их монтажа?",
        answers: [
          "Продуть азотом и опрессовать жидкостью на давление, превышающее на 20 % максимальное расчетное",
          "Продуть сжатым воздухом и опрессовать жидкостью на давление, превышающее на 25 % максимальное рабочее",
          "Продуть инертным газом и провести пневматическое испытание на давление, превышающее на 30 % расчетное",
          "Продуть кислородом и опрессовать жидкостью на давление, превышающее на 10 % максимальное рабочее",
        ],
        correctAnswers: [
          "Продуть сжатым воздухом и опрессовать жидкостью на давление, превышающее на 25 % максимальное рабочее",
        ],
      },
      {
        code: "20201080",
        text: "Что должна предусматривать подготовка рабочего агента (газа) при газлифтной эксплуатации?",
        answers: [
          "Ввод ингибитора",
          "Очистку от примесей",
          "Осушку от водяных паров до точки росы минус 10 °С для южных районов и минус 20 °С для средних и северных широт",
          "Фильтрацию и удаление твердых взвешенных частиц",
        ],
        correctAnswers: [
          "Осушку от водяных паров до точки росы минус 10 °С для южных районов и минус 20 °С для средних и северных широт",
        ],
      },
      {
        code: "20201081",
        text: "Каким образом необходимо производить ликвидацию гидратных пробок в газопроводах?",
        answers: [
          "Снизить давление в газопроводе до рабочего и продуть его паром",
          "Снизить давление в газопроводе до расчетного и продуть его инертным газом",
          "Снизить давление в газопроводе до атмосферного и произвести подогрев участков газопровода паром",
          "Не снижая давления осуществить подогрев участков газопровода",
        ],
        correctAnswers: [
          "Снизить давление в газопроводе до атмосферного и произвести подогрев участков газопровода паром",
        ],
      },
      {
        code: "20201082",
        text: "Как часто следует производить осмотр всех внутриплощадочных технологических трубопроводов, сепараторов, емкостей, запорно-регулирующей арматуры в процессе работы компрессорной станции газлифтной системы?",
        answers: ["Ежемесячно", "Ежесменно", "Еженедельно", "Ежедекадно"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20201083",
        text: "Чем должно быть оборудовано устье скважины при эксплуатации ее штанговыми насосами?",
        answers: [
          "Запорной арматурой и сальниковым устройством для герметизации штока",
          "Только запорной арматурой",
          "Перфорационной задвижкой",
          "Запорной арматурой и обратным клапаном",
          "Шаровым клапаном и сальниковым устройством для герметизации штока",
        ],
        correctAnswers: [
          "Запорной арматурой и сальниковым устройством для герметизации штока",
        ],
      },
      {
        code: "20201084",
        text: 'В каких случаях при отключении на пусковом устройстве электродвигателя периодически работающей скважины с автоматическим, дистанционным или ручным пуском вывешивается плакат "Не включать, работают люди!"?',
        answers: [
          "Ни в каких из перечисленных случаях",
          "Перед началом ремонтных работ и осмотром оборудования",
          "При техническом освидетельствовании станка-качалки",
          "Перед началом прострелочно-взрывных работ и геофизическом исследовании скважины",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: [
          "Перед началом ремонтных работ и осмотром оборудования",
        ],
      },
      {
        code: "20201085",
        text: "Какие плакаты должны быть постоянно укреплены на пусковом устройстве и вблизи него на скважинах с автоматическим и дистанционным управлением станков-качалок?",
        answers: [
          '"Внимание! Пуск автоматический"',
          '"Не включать! Работают люди"',
          '"Испытание! Опасно для жизни"',
          '"Посторонним вход запрещен!"',
        ],
        correctAnswers: ['"Внимание! Пуск автоматический"'],
      },
      {
        code: "20201086",
        text: "Какие узлы и устройства станка-качалки должны иметь ограждения и быть окрашены?",
        answers: [
          "Вращающиеся узлы и детали",
          "Весь станок-качалка",
          "Только площадка для обслуживания электропривода и площадка для обслуживания пускового устройства",
          "Кривошипно-шатунный механизм, площадка для обслуживания электропривода и площадка для обслуживания пускового устройства",
          "Только кривошипно-шатунный механизм и пусковое устройство",
        ],
        correctAnswers: [
          "Кривошипно-шатунный механизм, площадка для обслуживания электропривода и площадка для обслуживания пускового устройства",
        ],
      },
      {
        code: "20201087",
        text: "Куда должны иметь выход системы замера дебита, контроля пуска, остановки скважин?",
        answers: [
          "На пульт групповой замерной установки",
          "На диспетчерский пункт",
          "На пульт насосной станции",
          "На центральный пульт нефтегазодобывающего управления",
        ],
        correctAnswers: ["На диспетчерский пункт"],
      },
      {
        code: "20201088",
        text: "Что должно устанавливаться для обслуживания тормоза станка-качалки?",
        answers: [
          "Площадка с ограждением",
          "Эстакада",
          "Лестница туннельного типа",
          "Съемное ограждение с креплением, предотвращающим несанкционированный доступ к тормозу",
          "Все перечисленное",
        ],
        correctAnswers: ["Площадка с ограждением"],
      },
      {
        code: "20201089",
        text: "Какие требования предъявляются к заземлению кондуктора (технической колонны) и рамы станка-качалки?",
        answers: [
          "Кондуктор и рама станка-качалки должны быть связаны не менее чем двумя заземляющими стальными проводниками, приваренными в разных местах к кондуктору и раме",
          "В качестве заземляющих проводников может применяться сталь любых профилей. Сечение прямоугольного проводника должно быть не менее 48 мм², толщина стенок угловой стали - не менее 4 мм, диаметр круглых заземлителей - 10 мм",
          "Заземляющие проводники, соединяющие раму с качалкой, должны заглубляться в землю не менее чем на 0,5 м",
          "Соединения заземляющих проводников должны быть доступны для осмотра",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20201090",
        text: "Как должен прокладываться силовой кабель от станции управления к устью скважины при ее эксплуатации погружным электронасосом?",
        answers: [
          "На эстакаде или на специальных стойках-опорах",
          "В заглубленных лотках",
          "Только на специальных стойках-опорах",
          "На бетонных подкладках или на специальных стойках",
          "Только на эстакаде",
        ],
        correctAnswers: ["На эстакаде или на специальных стойках-опорах"],
      },
      {
        code: "20201091",
        text: "Каким образом допускается подвешивать кабельный ролик на мачте подъемного агрегата?",
        answers: [
          "Только при помощи специальной цепи",
          "Только при помощи специальной канатной подвески",
          "На стальном канате диаметром 12 мм с 4 зажимами",
          "При помощи цепи или на специальной канатной подвеске и страховаться тросом диаметром не менее 8 мм",
          "На стальной комплектной вилке",
        ],
        correctAnswers: [
          "При помощи цепи или на специальной канатной подвеске и страховаться тросом диаметром не менее 8 мм",
        ],
      },
      {
        code: "20201092",
        text: "Где следует размещать силовой кабель электронасоса при свинчивании и развинчивании труб?",
        answers: [
          "Внутри мачты агрегата для ремонта",
          "За пределами рабочей зоны",
          "Силовой кабель следует крепить к оттяжкам агрегата",
          "В пределах рабочей зоны",
        ],
        correctAnswers: ["За пределами рабочей зоны"],
      },
      {
        code: "20201093",
        text: "Какова максимальная скорость спуска (подъема) погружного электронасоса в вертикальную скважину?",
        answers: ["0,25 м/с", "0,30 м/с", "0,15 м/с", "Не регламентируется"],
        correctAnswers: ["0,25 м/с"],
      },
      {
        code: "20201094",
        text: "Каким требованиям должно отвечать помещение технологического блока установки гидропоршневых и струйных насосов?",
        answers: [
          "Помещение должно иметь постоянную принудительную вентиляцию, обеспечивающую четырехкратный воздухообмен по полному внутреннему объему помещения в течение часа, температуру в блоках не ниже 1 °С, уровень шума не более 95 дБ, скорость вибрации не более 5 мм/с",
          "Помещение должно иметь постоянную принудительную вентиляцию, обеспечивающую восьмикратный воздухообмен по полному внутреннему объему помещения в течение часа, температуру в блоках не ниже 5 °С, уровень шума не более 80 дБ, скорость вибрации не более 2 мм/с",
          "Помещение должно иметь постоянную принудительную вентиляцию, обеспечивающую двукратный воздухообмен по полному внутреннему объему помещения в течение часа, температуру в блоках не ниже 3 °С, уровень шума не более 90 дБ, скорость вибрации не более 3 мм/с",
        ],
        correctAnswers: [
          "Помещение должно иметь постоянную принудительную вентиляцию, обеспечивающую восьмикратный воздухообмен по полному внутреннему объему помещения в течение часа, температуру в блоках не ниже 5 °С, уровень шума не более 80 дБ, скорость вибрации не более 2 мм/с",
        ],
      },
      {
        code: "20201095",
        text: "Что необходимо сделать с эксплуатационной колонной перед спуском в нее пакера?",
        answers: [
          "Прошаблонировать, при необходимости прорайбировать, промыть до забоя, опрессовать",
          "Определить остаточную прочность эксплуатационной колонны",
          "Провести комплекс геофизических исследований",
          "Все перечисленное",
        ],
        correctAnswers: [
          "Прошаблонировать, при необходимости прорайбировать, промыть до забоя, опрессовать",
        ],
      },
      {
        code: "20201096",
        text: "Каким образом необходимо производить монтаж и демонтаж лубрикатора?",
        answers: [
          "С использованием гидросъемника при открытой центральной задвижке",
          "С использованием мачты при закрытой центральной задвижке",
          "С использованием мачты и канатной техники",
          "С использованием мачты при открытой центральной задвижке",
          "С использованием гидродомкрата расчетной грузоподъемности при закрытой центральной задвижке",
        ],
        correctAnswers: [
          "С использованием мачты при закрытой центральной задвижке",
        ],
      },
      {
        code: "20201097",
        text: "Какими контрольно-измерительными приборами должна быть оборудована каждая нагнетательная линия установки гидропоршневых и струйных насосов?",
        answers: [
          "Регулятором давления и температуры",
          "Только манометром",
          "Манометром и регулятором давления рабочей жидкости",
          "Манометром и регулятором расхода рабочей жидкости",
        ],
        correctAnswers: ["Манометром и регулятором расхода рабочей жидкости"],
      },
      {
        code: "20201098",
        text: "С какой периодичностью необходимо проверять исправность системы автоматики и предохранительных устройств гидропоршневых и струйных насосов?",
        answers: [
          "Исправность системы автоматики и предохранительных устройств проверяется в сроки, установленные инструкцией по эксплуатации",
          "Не реже одного раза в месяц",
          "Не реже одного раза в неделю",
          "Исправность системы автоматики и предохранительных устройств проверяется в сроки, установленные правилами Ростехнадзора",
        ],
        correctAnswers: [
          "Исправность системы автоматики и предохранительных устройств проверяется в сроки, установленные инструкцией по эксплуатации",
        ],
      },
      {
        code: "20201099",
        text: "До какой величины должно быть снижено давление в нагнетательном трубопроводе при остановке силового насоса?",
        answers: [
          "До значения, не превышающего 0,5 рабочего давления",
          "До атмосферного давления",
          "До минимального значения избыточного давления, указанного в инструкции",
          "Давление не должно снижаться",
        ],
        correctAnswers: ["До атмосферного давления"],
      },
      {
        code: "20201100",
        text: "С какой периодичностью и в каком объеме проводятся исследования эксплуатационных скважин на нефтегазодобывающих объектах?",
        answers: [
          "В соответствии с инструкцией по исследованию скважин, утвержденной Минприроды России",
          "Каждые полгода в полном объеме и ежемесячно в объеме, необходимом геологической службе организации",
          "В соответствии с утвержденным планом работ, разработанным в соответствии с проектной документацией разработки данного месторождения",
        ],
        correctAnswers: [
          "В соответствии с утвержденным планом работ, разработанным в соответствии с проектной документацией разработки данного месторождения",
        ],
      },
      {
        code: "20201101",
        text: "Разрешается ли исследование разведочных и эксплуатационных скважин в случае отсутствия утилизации жидкого продукта?",
        answers: [
          "Запрещается во всех случаях",
          "Разрешается",
          "Разрешается по согласованию с противофонтанной службой",
          "Разрешается по решению территориального органа Ростехнадзора",
        ],
        correctAnswers: ["Запрещается во всех случаях"],
      },
      {
        code: "20201102",
        text: "Что из нижеперечисленного должно быть указано в плане производства работ по нагнетанию в скважину газа, пара, химических и других агентов?",
        answers: [
          "Только порядок подготовительных работ и схема размещения оборудования",
          "Только технология проведения процесса",
          "Только меры безопасности",
          "Только ответственный руководитель работ",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20201103",
        text: "Какое устройство должно быть установлено на нагнетательной линии у устья скважины при закачке в нее химреагентов, пара, горячей воды?",
        answers: [
          "Обратный клапан",
          "Предохранительный клапан",
          "Автоматически регулируемая задвижка",
          "Шаровой кран",
        ],
        correctAnswers: ["Обратный клапан"],
      },
      {
        code: "20201104",
        text: "На какое значение давления должна быть опрессована нагнетательная система после сборки до начала закачки?",
        answers: [
          "На ожидаемое давление закачки",
          "На расчетное давление",
          "На полуторакратное рабочее давление",
          "На максимальное давление",
          "На двукратное рабочее давление",
        ],
        correctAnswers: ["На полуторакратное рабочее давление"],
      },
      {
        code: "20201105",
        text: "Какие меры безопасности должны быть выполнены обслуживающим персоналом при производстве гидравлических испытаний нагнетательной системы?",
        answers: [
          "Обслуживающий персонал должен быть удален за пределы опасной зоны. Ликвидация утечек под давлением в системе запрещается",
          "Обслуживающий персонал должен занять места в укрытии",
          "Обслуживающий персонал должен быть отведен на расстояние не менее 50 м от рабочей зоны",
          "Обслуживающий персонал должен быть удален за пределы рабочей зоны. Возвращение допускается только для ликвидации пропусков",
        ],
        correctAnswers: [
          "Обслуживающий персонал должен быть удален за пределы опасной зоны. Ликвидация утечек под давлением в системе запрещается",
        ],
      },
      {
        code: "20201106",
        text: "В чем должен убедиться руководитель работ перед началом технологического процесса на скважине с применением передвижных агрегатов?",
        answers: [
          "В наличии средств световой сигнализации",
          "В наличии двусторонней переговорной связи",
          "В наличии средств звуковой сигнализации",
          "В наличии средств контроля давления и температуры",
          "Во всем перечисленном",
        ],
        correctAnswers: ["В наличии двусторонней переговорной связи"],
      },
      {
        code: "20201107",
        text: "Какой радиус опасной зоны должен быть установлен вокруг скважины и применяемого оборудования на период тепловой и комплексной обработки?",
        answers: [
          "Не менее 10 м",
          "Не менее 20 м",
          "Не менее 30 м",
          "Не менее 50 м",
        ],
        correctAnswers: ["Не менее 50 м"],
      },
      {
        code: "20201108",
        text: "Какие требования предъявляются к размещению передвижных насосных установок у устья скважины?",
        answers: [
          "Передвижные насосные установки необходимо располагать не менее чем за 10 м от устья скважины",
          "Расстояние между передвижными насосными установками должно быть не менее 1 м",
          "Вспомогательные установки (компрессор, парогенераторная установка и др.) должны располагаться на расстоянии не менее 25 м от устья скважины",
          "Агрегаты следует устанавливать кабинами от устья скважины",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20201109",
        text: "За счет чего должна исключаться возможность образования взрывоопасных смесей внутри аппаратов и трубопроводов?",
        answers: [
          "За счет технологических режимов ведения работ и конструктивного исполнения агрегатов и установок",
          "За счет производственного контроля и применения сертификационного оборудования в организации",
          "За счет использования пожаро- и взрывозащищенного оборудования",
          "За счет всего перечисленного",
        ],
        correctAnswers: [
          "За счет технологических режимов ведения работ и конструктивного исполнения агрегатов и установок",
        ],
      },
      {
        code: "20201110",
        text: "Какие требования предъявляются к выкидной линии от предохранительного устройства насоса?",
        answers: [
          "Выкидная линия должна быть жестко закреплена и выведена в сбросную емкость для сбора жидкости или на прием насоса",
          "Выкидная линия должна быть оборудована обратным клапаном и выведена в сбросную емкость для сбора жидкости",
          "Выкидная линия должна быть оборудована предохранительным клапаном",
          "Выкидная линия должна иметь надежную запорную арматуру",
          "Выкидная линия должна быть оборудована манометром",
        ],
        correctAnswers: [
          "Выкидная линия должна быть жестко закреплена и выведена в сбросную емкость для сбора жидкости или на прием насоса",
        ],
      },
      {
        code: "20201111",
        text: "Допускаются ли вибрация и гидравлические удары в нагнетательных коммуникациях?",
        answers: [
          "Не допускаются",
          "Допускаются в пределах, установленных в планах работ",
          "Допускаются при наличии в системе компенсаторов",
          "Допускаются",
        ],
        correctAnswers: [
          "Допускаются в пределах, установленных в планах работ",
        ],
      },
      {
        code: "20201112",
        text: "Что из нижеперечисленного должно находиться на месте производства работ по закачке агрессивных химреагентов в скважину?",
        answers: [
          "Аварийный запас средств индивидуальной защиты, запас технической воды и нейтрализующие элементы для раствора",
          "Аварийные средства пожаротушения и запас технической воды",
          "Специальная аппаратура для оповещения о разгерметизации запорной арматуры",
          "Аварийный запас спецодежды, спецобуви и других средств индивидуальной защиты, запас чистой пресной воды и нейтрализующие компоненты для раствора",
          "Аварийный запас воды и нейтрализующие компоненты для раствора",
        ],
        correctAnswers: [
          "Аварийный запас спецодежды, спецобуви и других средств индивидуальной защиты, запас чистой пресной воды и нейтрализующие компоненты для раствора",
        ],
      },
      {
        code: "20201113",
        text: "Каким образом следует поступать с остатками химреагентов?",
        answers: [
          "Собирать и доставлять в специально отведенное место, оборудованное для утилизации или уничтожения",
          "Утилизировать на месте производства работ при наличии нейтрализующих веществ",
          "Помещать в специальные могильники на глубину не менее 3 м, но выше уровня грунтовых вод",
          "Любым из перечисленных способов",
        ],
        correctAnswers: [
          "Собирать и доставлять в специально отведенное место, оборудованное для утилизации или уничтожения",
        ],
      },
      {
        code: "20201114",
        text: "Какими техническими средствами должна быть обеспечена бригада для определения концентрации паров серной кислоты и серного ангидрида?",
        answers: [
          "Индивидуальными счетчиками концентрации паров",
          "Газоанализаторами",
          "Газосигнализаторами",
          "Газоиндикаторами",
        ],
        correctAnswers: ["Газоанализаторами"],
      },
      {
        code: "20201115",
        text: "В какой момент следует производить загрузку термореактора магнием?",
        answers: [
          "Не ранее чем за час до спуска термореактора в скважину",
          "Непосредственно перед спуском термореактора в скважину",
          "Не позднее чем за 2 часа до спуска термореактора в скважину",
          "За день до начала работ в лаборатории газоспасательной службы",
        ],
        correctAnswers: [
          "Непосредственно перед спуском термореактора в скважину",
        ],
      },
      {
        code: "20201116",
        text: "На каком расстоянии от скважины или участка нагнетательного трубопровода запрещается находиться при их продувке?",
        answers: ["Ближе 35 м", "Ближе 30 м", "Ближе 25 м", "Ближе 20 м"],
        correctAnswers: ["Ближе 20 м"],
      },
      {
        code: "20201117",
        text: "При какой предельно допустимой концентрации содержания диоксида углерода в воздухе закрытого помещения работы в нем должны быть прекращены?",
        answers: [
          "0,1 (объемных) %",
          "0,2 (объемных) %",
          "0,3 (объемных) %",
          "0,4 (объемных) %",
          "0,5 (объемных) %",
        ],
        correctAnswers: ["0,5 (объемных) %"],
      },
      {
        code: "20201118",
        text: "Чем должны быть оснащены парогенераторные и водонагревательные установки?",
        answers: [
          "Средствами сигнализации для обнаружения нарушения технологического процесса",
          "Системами автоматического оповещения в случаях нарушения технологического процесса и пенного пожаротушения",
          "Системами регистрации параметров и локализации возгорания в случаях нарушения технологического процесса и пенного пожаротушения",
          "Приборами контроля и регулирования процессов приготовления и закачки теплоносителя, средствами по прекращению подачи топливного газа",
          "Контрольно-измерительной аппаратурой для регулирования расхода",
        ],
        correctAnswers: [
          "Приборами контроля и регулирования процессов приготовления и закачки теплоносителя, средствами по прекращению подачи топливного газа",
        ],
      },
      {
        code: "20201119",
        text: "Какое минимальное расстояние должно быть между парораспределительным пунктом и устьем нагнетательной скважины?",
        answers: ["10 м", "15 м", "20 м", "25 м"],
        correctAnswers: ["25 м"],
      },
      {
        code: "20201120",
        text: "Каким образом должно осуществляться управление запорной арматурой скважины, оборудованной под нагнетание пара или горячей воды?",
        answers: [
          "Только дистанционно",
          "Вручную с использованием средств защиты",
          "Дистанционно, но с возможностью ручного управления с применением средств защиты",
        ],
        correctAnswers: ["Только дистанционно"],
      },
      {
        code: "20201121",
        text: "При каких отклонениях должна срабатывать автоматическая защита, прекращающая подачу топлива в парогенератор?",
        answers: [
          "Только при повышении давления в теплопроводе выше допустимого",
          "Только при понижении давления в теплопроводе ниже допустимого",
          "Только при прекращении подачи воды",
          "При изменении давления в теплопроводе ниже или выше допустимого, а также при прекращении подачи воды",
        ],
        correctAnswers: [
          "При изменении давления в теплопроводе ниже или выше допустимого, а также при прекращении подачи воды",
        ],
      },
      {
        code: "20201122",
        text: "В каком положении должна находиться задвижка на отводе от затрубного пространства при закачке теплоносителя (с установкой пакера)?",
        answers: [
          "В закрытом",
          "В полуоткрытом",
          "В открытом",
          "Не регламентируется",
        ],
        correctAnswers: ["В открытом"],
      },
      {
        code: "20201123",
        text: "На каком минимальном расстоянии от емкости с горячим нефтепродуктом должна располагаться установка для подогрева?",
        answers: ["15 м", "10 м", "25 м", "20 м"],
        correctAnswers: ["25 м"],
      },
      {
        code: "20201124",
        text: "На каком расстоянии и каким образом следует устанавливать емкость с горячим нефтепродуктом для обработки скважины?",
        answers: [
          "На расстоянии не более 5 м от устья скважины со стороны подъездных путей",
          "На расстоянии не менее 30 м от обваловки скважины с видимой стороны устья",
          "На расстоянии не менее 10 м от устья скважины с подветренной стороны",
          "На расстоянии не более 15 м от устья скважины с наветренной стороны",
        ],
        correctAnswers: [
          "На расстоянии не менее 10 м от устья скважины с подветренной стороны",
        ],
      },
      {
        code: "20201125",
        text: "Какие требования предъявляются к спуску забойного электронагревателя в скважину?",
        answers: [
          "Операции должны быть механизированы, с использованием специального лубрикатора при герметизированном устье",
          "Операции должны выполняться дистанционно с использованием специального герметизатора при герметизированном устье",
          "Операции должны быть автоматизированы, должны выполняться использованием специального обратного клапана",
          "Операции должны выполняться дистанционно",
        ],
        correctAnswers: [
          "Операции должны быть механизированы, с использованием специального лубрикатора при герметизированном устье",
        ],
      },
      {
        code: "20201126",
        text: "При каких условиях допускается подключать сетевой кабель к пусковому оборудованию электронагревателя?",
        answers: [
          "Только после подключения кабель-троса к трансформатору",
          "Только после заземления электрооборудования",
          "Только после проведения всех подготовительных работ в скважине и на устье",
          "Только после удаления людей",
          "После выполнения всех условий",
        ],
        correctAnswers: ["После выполнения всех условий"],
      },
      {
        code: "20201127",
        text: "Когда пороховые генераторы (аккумуляторы) давления должны устанавливаться в спускаемую гирлянду зарядов?",
        answers: [
          "После установки перфорационной задвижки",
          "Перед началом работ",
          "Перед вводом в лубрикатор",
          "После открытия устьевой задвижки",
          "После извлечения устьевого пакера",
        ],
        correctAnswers: ["Перед вводом в лубрикатор"],
      },
      {
        code: "20201128",
        text: "Какие требования предъявляются к месту хранения ящиков с пороховыми зарядами?",
        answers: [
          "Ящики должны храниться в специальном помещении на расстоянии не менее 30 м от устья скважины",
          "Ящики должны храниться во взрывозащищенной камере, запираемой на замок, расположенной на расстоянии не менее 25 м от устья скважины",
          "Ящики должны храниться на открытом воздухе под охраной ответственного лица на расстоянии не менее 10 м от устья скважины",
          "Ящики должны храниться в запираемом на замок помещении, расположенном на расстоянии не менее 50 м от устья скважины",
        ],
        correctAnswers: [
          "Ящики должны храниться в запираемом на замок помещении, расположенном на расстоянии не менее 50 м от устья скважины",
        ],
      },
      {
        code: "20201129",
        text: "В каком положении должна находиться центральная задвижка при установке гирлянды порохового заряда в лубрикатор?",
        answers: [
          "В открытом",
          "В полузакрытом",
          "В закрытом",
          "Не регламентируется",
        ],
        correctAnswers: ["В закрытом"],
      },
      {
        code: "20201130",
        text: "Какие требования предъявляются ФНП к системе контроля состояния воздушной среды для закрытых помещений объектов сбора, подготовки и транспортировки нефти, газа и конденсата?",
        answers: [
          "В системе контроля состояния воздушной среды должно быть предусмотрено дублирование датчиков аварийного включения оборудования и системы приточно-вытяжной вентиляции",
          "Система должна иметь блокировку от включения оборудования, все помещения должны иметь постоянно действующую противопожарную систему",
          "Система должна быть сблокирована с системой выключения оборудования, включая перекрытие клапанов, все помещения должны иметь постоянно действующую систему оповещения и сигнализации",
          "Система должна быть сблокирована с системой звуковой и световой аварийной сигнализации, все помещения должны иметь постоянно действующую систему приточно-вытяжной вентиляции",
          "Система должна иметь блокировку от выключения оборудования, включая перекрытие запорной арматуры, все помещения должны иметь постоянно действующую систему вытяжной вентиляции",
        ],
        correctAnswers: [
          "Система должна быть сблокирована с системой звуковой и световой аварийной сигнализации, все помещения должны иметь постоянно действующую систему приточно-вытяжной вентиляции",
        ],
      },
      {
        code: "20201131",
        text: "Какие документы должны быть на объектах сбора и подготовки нефти и газа (центральный пункт сбора (далее - ЦПС), установка подготовки нефти (далее - УПН), установка комплексной подготовки газа (далее - УКПГ), насосных и компрессорных станциях (дожимная насосная станция (далее - ДНС), компрессорная станция (далее - КС)?",
        answers: [
          "Технические паспорта и документация на оборудование насосных и компрессорных станций",
          "Технологическая схема, утвержденная техническим руководителем организации, с указанием номеров задвижек, аппаратов, направлений потоков, полностью соответствующих их нумерации в проектной технологической схеме",
          "Приказ о назначении лица, ответственного за проведение работ",
          "План аварийно-спасательных мероприятий",
          "Все перечисленные документы",
        ],
        correctAnswers: [
          "Технологическая схема, утвержденная техническим руководителем организации, с указанием номеров задвижек, аппаратов, направлений потоков, полностью соответствующих их нумерации в проектной технологической схеме",
        ],
      },
      {
        code: "20201132",
        text: "В каких случаях персонал должен быть обеспечен необходимыми средствами индивидуальной защиты?",
        answers: [
          "При наличии в продукции, технологических аппаратах, резервуарах и других емкостях сероводорода или возможности образования вредных веществ при пожарах, взрывах, нарушении герметичности емкостей и других аварийных ситуациях",
          "В случаях обнаружения вредных веществ и примесей в продукции",
          "Если возможно образование вредных веществ при смешении продукции",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: [
          "При наличии в продукции, технологических аппаратах, резервуарах и других емкостях сероводорода или возможности образования вредных веществ при пожарах, взрывах, нарушении герметичности емкостей и других аварийных ситуациях",
        ],
      },
      {
        code: "20201133",
        text: "В соответствии с какими документами должна устанавливаться скорость изменения технологических параметров?",
        answers: [
          "В соответствии с технологическим регламентом и заводскими инструкциями по эксплуатации оборудования",
          "Только в соответствии с технологическим регламентом",
          "Только в соответствии с заводскими инструкциями по эксплуатации оборудования",
          "В соответствии с Правилами безопасности в нефтяной и газовой промышленности",
          "В соответствии с планами проведения работ",
        ],
        correctAnswers: [
          "В соответствии с технологическим регламентом и заводскими инструкциями по эксплуатации оборудования",
        ],
      },
      {
        code: "20201134",
        text: "Что необходимо предпринять в случае обнаружения загазованности воздуха рабочей зоны?",
        answers: [
          "Незамедлительно подать сигнал тревоги и предупредить ответственного руководителя",
          "Незамедлительно предупредить обслуживающий персонал и покинуть загазованный участок",
          "Незамедлительно покинуть загазованный участок и сообщить непосредственному руководителю",
          "Незамедлительно предупредить обслуживающий персонал о возможной опасности",
          "Незамедлительно предупредить обслуживающий персонал близлежащих установок о возможной опасности, оградить загазованный участок и принять меры по устранению источника загазованности",
        ],
        correctAnswers: [
          "Незамедлительно предупредить обслуживающий персонал близлежащих установок о возможной опасности, оградить загазованный участок и принять меры по устранению источника загазованности",
        ],
      },
      {
        code: "20201135",
        text: "Кем должно обслуживаться электрооборудование установки?",
        answers: [
          "Работниками, имеющими соответствующую квалификацию и допуск к работе",
          "Эксплуатационным персоналом, прошедшим инструктаж",
          "Рабочим персоналом, обслуживающим установку, в присутствии представителя электротехнического персонала",
          "Звеном старшего оператора в присутствии мастера, имеющего соответствующую группу по электробезопасности",
          "Персоналом подрядной организации",
        ],
        correctAnswers: [
          "Работниками, имеющими соответствующую квалификацию и допуск к работе",
        ],
      },
      {
        code: "20201136",
        text: "С какой периодичностью проводят проверку исправности предохранительной, регулирующей и запорной арматуры и как оформляют результаты проверки?",
        answers: [
          "Ежесменно с занесением результатов проверок в вахтовый журнал или соответствующую базу данных",
          "Ежесуточно с занесением результатов проверок в вахтовый журнал",
          "По графику, утвержденному эксплуатирующей организацией",
          "Еженедельно с занесением результатов проверок в соответствующую базу данных",
        ],
        correctAnswers: [
          "По графику, утвержденному эксплуатирующей организацией",
        ],
      },
      {
        code: "20201137",
        text: "Какие требования предъявляются к электрическим датчикам систем контроля и управления технологическим процессом?",
        answers: [
          "Должны быть во взрывозащищенном исполнении",
          "Должны быть рассчитаны на применение в условиях вибрации",
          "Должны быть рассчитаны на применение в условиях газовых гидратов",
          "Все вышеперечисленные требования",
        ],
        correctAnswers: ["Все вышеперечисленные требования"],
      },
      {
        code: "20201138",
        text: "С какой целью насосы, перекачивающие сернистую нефть, должны быть заполнены перекачиваемой жидкостью?",
        answers: [
          "С целью постоянной готовности к пуску",
          "С целью избегания образования пирофорных отложений",
          "С целью предотвращения попадания воды в насос",
          "С целью равномерной подачи жидкости",
        ],
        correctAnswers: ["С целью избегания образования пирофорных отложений"],
      },
      {
        code: "20201139",
        text: "Каким проверкам должны подвергаться разъемные соединения компрессоров и их газопроводов?",
        answers: [
          "На герметичность",
          "На коррозионный износ",
          "На целостность уплотнений",
          "На отсутствие дефектов",
        ],
        correctAnswers: ["На герметичность"],
      },
      {
        code: "20201140",
        text: "В соответствии с каким документом проводится перед пуском проверка исправности комплекса УПН?",
        answers: [
          "В соответствии с планом, утвержденным техническим руководителем организации",
          "В соответствии с технологическим регламентом",
          "В соответствии с Правилами безопасности в нефтяной и газовой промышленности",
          "В соответствии с приказом, изданным техническим руководителем организации",
        ],
        correctAnswers: [
          "В соответствии с планом, утвержденным техническим руководителем организации",
        ],
      },
      {
        code: "20201141",
        text: "Какие работники должны обслуживать электрическую часть электрообессоливающей установки УПН?",
        answers: [
          "Работники, имеющие допуск на проведение работ с электроустановками напряжением выше 1000 В",
          "Рабочий персонал, обслуживающий установку",
          "Звено старшего оператора в присутствии мастера, имеющего группу IV по электробезопасности",
          "Персонал подрядной организации",
        ],
        correctAnswers: [
          "Работники, имеющие допуск на проведение работ с электроустановками напряжением выше 1000 В",
        ],
      },
      {
        code: "20201142",
        text: "Какое устройство должен иметь электродегидратор?",
        answers: [
          "Сигнализирующее о понижении уровня продукта в аппарате",
          "Сигнализирующее о превышении давления",
          "Регулирующее уровень продукта в аппарате",
          "Отключающее подачу продукта в аппарат при отключении электроэнергии",
          "Отключающее напряжение при понижении уровня продукта в аппарате",
        ],
        correctAnswers: [
          "Отключающее напряжение при понижении уровня продукта в аппарате",
        ],
      },
      {
        code: "20201143",
        text: "Что необходимо сделать после заполнения электродегидратора продуктом перед подачей напряжения?",
        answers: [
          "Проверить герметичность соединений",
          "Удалить скопившиеся в электродегидраторе газы и пары",
          "Проверить заземляющие устройства",
          "Все перечисленное",
        ],
        correctAnswers: [
          "Удалить скопившиеся в электродегидраторе газы и пары",
        ],
      },
      {
        code: "20201144",
        text: "Кем осуществляется подача напряжения на электрообессоливающую установку УПН?",
        answers: [
          "Дежурным работником по указанию начальника установки или лица, его заменяющего",
          "Начальником установки или лицом, его заменяющим",
          "Главным энергетиком",
          "Главным инженером",
        ],
        correctAnswers: [
          "Дежурным работником по указанию начальника установки или лица, его заменяющего",
        ],
      },
      {
        code: "20201145",
        text: "Какие требования предъявляются к процессу дренирования воды из электродегидраторов и отстойников?",
        answers: [
          "Процесс должен быть автоматизированным и осуществляться закрытым способом",
          "Процесс должен быть автоматизированным и осуществляться открытым способом",
          "Процесс должен быть с ручным управлением и осуществляться закрытым способом",
          "Процесс должен быть с ручным управлением и осуществляться открытым способом",
        ],
        correctAnswers: [
          "Процесс должен быть автоматизированным и осуществляться закрытым способом",
        ],
      },
      {
        code: "20201146",
        text: "Какими документами следует руководствоваться при эксплуатации УКПГ, газосборных пунктов, головных сооружений?",
        answers: [
          "Инструкциями завода-изготовителя",
          "Эксплуатационным руководством, утвержденным техническим директором организации",
          "Технологическими регламентами",
          "Планом производства работ",
          "Всеми перечисленными документами",
        ],
        correctAnswers: ["Технологическими регламентами"],
      },
      {
        code: "20201147",
        text: "Какие способы используются для ликвидации гидратных пробок в газопроводах, арматуре, оборудовании и приборах УКПГ?",
        answers: [
          "Введение ингибиторов гидратообразования, теплоносителей (пара, горячей воды), понижение давления в системе",
          "Введение специальных добавок, повышение давления в системе",
          "Введение поверхностно-активных веществ, понижение давления в системе",
          "Введение окислителей, повышение давления в системе",
        ],
        correctAnswers: [
          "Введение ингибиторов гидратообразования, теплоносителей (пара, горячей воды), понижение давления в системе",
        ],
      },
      {
        code: "20201148",
        text: "С какой периодичностью проводится проверка на герметичность игольчатых вентилей пробоотборников?",
        answers: [
          "Не реже одного раза в пять лет",
          "Не реже одного раза в два года",
          "Не реже одного раза в шесть месяцев",
          "Не реже одного раза в год",
        ],
        correctAnswers: ["Не реже одного раза в шесть месяцев"],
      },
      {
        code: "20201149",
        text: "Какие меры безопасности должны быть предусмотрены для насосов (группы насосов), перекачивающих горючие продукты?",
        answers: [
          "Ручное управление и установка на линии нагнетания насоса предохранительного клапана",
          "Дистанционное отключение и установка на линиях входа и нагнетания запорных или отсекающих устройств",
          "Дистанционное отключение и установка на линии нагнетания предохранительного клапана",
          "Ручное управление и установка на линиях входа и нагнетания обратных клапанов",
        ],
        correctAnswers: [
          "Дистанционное отключение и установка на линиях входа и нагнетания запорных или отсекающих устройств",
        ],
      },
      {
        code: "20201150",
        text: "Чем должен быть оборудован насос, подающий масло на торцевые уплотнения?",
        answers: [
          "Блокировочным устройством, включающим резервный масляный насос при падении давления масла",
          "Контактным манометром, включающим предупредительный сигнал о падении давления масла",
          "Дифференциальным преобразователем давления, подающим сигнал о падении давления масла на диспетчерский пульт",
          "Предохранительным устройством, предотвращающим превышение давления масла сверх установленного заводом-изготовителем",
        ],
        correctAnswers: [
          "Блокировочным устройством, включающим резервный масляный насос при падении давления масла",
        ],
      },
      {
        code: "20201151",
        text: "Какой клапан должен быть установлен на напорном (нагнетательном) трубопроводе центробежного насоса?",
        answers: [
          "Обратный и предохранительный клапаны",
          "Обратный клапан",
          "Шаровой и обратный клапаны",
          "Предохранительный клапан",
        ],
        correctAnswers: ["Обратный клапан"],
      },
      {
        code: "20201152",
        text: "Куда должен отводиться сбрасываемый продукт при освобождении и продувке насосов?",
        answers: [
          "За пределы помещений: жидкий - по трубопроводам в дренажную систему, а пары и газы - в сепаратор",
          "За пределы помещений: жидкий - по трубопроводам в специально предназначенную емкость, а пары и газы - на факел или свечу",
          "За пределы помещений: жидкий - по трубопроводам в закрытую дренажную систему, а пары и газы - на факел или свечу",
          "В пределах помещения: жидкий - по трубопроводам в специально предназначенную емкость, а пары и газы - в сепаратор",
          "За пределы помещений: жидкий - через скруббер в предназначенную емкость, а пары и газы - на факел или свечу",
        ],
        correctAnswers: [
          "За пределы помещений: жидкий - по трубопроводам в специально предназначенную емкость, а пары и газы - на факел или свечу",
        ],
      },
      {
        code: "20201153",
        text: "Что следует использовать для отключения резервного насоса от всасывающих и напорных коллекторов?",
        answers: [
          "Задвижки",
          "Заглушки",
          "Обратный клапан",
          "Заглушки и задвижку",
          "Обратный клапан и заглушку",
        ],
        correctAnswers: ["Задвижки"],
      },
      {
        code: "20201154",
        text: "В каком положении должны находиться задвижки на всасывающем и нагнетательном продуктопроводах при необходимости сдвига поршня парового насоса с мертвого положения вручную?",
        answers: [
          "На всасывающем продуктопроводе - в закрытом, на нагнетательном - в открытом",
          "На всасывающем продуктопроводе - в открытом, на нагнетательном - в закрытом",
          "На всасывающем и нагнетательном продуктопроводах - в закрытом положении",
          "На всасывающем и нагнетательном продуктопроводах - в открытом положении",
        ],
        correctAnswers: [
          "На всасывающем и нагнетательном продуктопроводах - в закрытом положении",
        ],
      },
      {
        code: "20201155",
        text: "Какими документами устанавливаются сроки по проверке на герметичность соединений компрессоров и их газопроводов?",
        answers: [
          "Планом работ, утвержденным главным инженером организации",
          "Инструкциями завода-изготовителя и технологическим регламентом",
          "Инструкциями, утвержденными территориальным органом Ростехнадзора",
          "Приказом, утвержденным техническим руководителем организации",
        ],
        correctAnswers: [
          "Инструкциями завода-изготовителя и технологическим регламентом",
        ],
      },
      {
        code: "20201156",
        text: "Какими устройствами необходимо оборудовать отделители жидкости (сепараторы)?",
        answers: [
          "Термопарой и расходомером",
          "Индикатором уровня жидкости в сепараторе и манометром",
          "Световой и звуковой сигнализацией, блокировкой, производящей остановку компрессора при достижении предельно допустимого уровня жидкости в сепараторе",
          "Всеми перечисленными устройствами",
        ],
        correctAnswers: [
          "Световой и звуковой сигнализацией, блокировкой, производящей остановку компрессора при достижении предельно допустимого уровня жидкости в сепараторе",
        ],
      },
      {
        code: "20201157",
        text: "Что и с какой целью необходимо осмотреть после каждой остановки компрессора?",
        answers: [
          "Недоступные к осмотру во время его работы движущиеся детали и убедиться в их целостности",
          "Недоступные к осмотру во время его работы движущиеся детали и убедиться в отсутствии превышения допустимых температур нагрева",
          "Недоступные к осмотру во время его работы движущиеся детали и проверить уровень масла",
          "Недоступные к осмотру во время его работы движущиеся детали и проверить их крепление",
          "Недоступные к осмотру во время его работы движущиеся детали и проверить их ограждения",
        ],
        correctAnswers: [
          "Недоступные к осмотру во время его работы движущиеся детали и убедиться в отсутствии превышения допустимых температур нагрева",
        ],
      },
      {
        code: "20201158",
        text: "Кто дает разрешение на пуск компрессора после ревизии, ремонта и длительного вынужденного отключения (кроме резервного)?",
        answers: [
          "Руководитель объекта (мастер, начальник компрессорной станции) или механик",
          "Главный энергетик организации",
          "Технический руководитель организации",
          "Главный инженер или главный механик компрессорной станции",
        ],
        correctAnswers: [
          "Руководитель объекта (мастер, начальник компрессорной станции) или механик",
        ],
      },
      {
        code: "20201159",
        text: "Откуда должен производиться забор воздуха компрессором?",
        answers: [
          "Из безопасной зоны на расстоянии 50 м от помещения компрессорной",
          "Из зоны (вне помещения компрессорной), не содержащей примеси горючих газов и пыли",
          "Из помещения компрессорной",
          "Не регламентируется",
        ],
        correctAnswers: [
          "Из зоны (вне помещения компрессорной), не содержащей примеси горючих газов и пыли",
        ],
      },
      {
        code: "20201160",
        text: "Чем должны оснащаться воздухопроводы при работе нескольких компрессоров в общую сеть?",
        answers: [
          "Запорной арматурой",
          "Обратным клапаном и отсекающей задвижкой или вентилем",
          "Обратным и предохранительным клапанами",
          "Только отсекающей задвижкой",
          "Только обратным клапаном",
        ],
        correctAnswers: [
          "Обратным клапаном и отсекающей задвижкой или вентилем",
        ],
      },
      {
        code: "20201161",
        text: "Чем должны фиксироваться колеса железнодорожных цистерн при сливе и наливе?",
        answers: ["Тормозом", "Башмаками", "Подложками"],
        correctAnswers: ["Башмаками"],
      },
      {
        code: "20201162",
        text: "На каком расстоянии от эстакады разрешается выполнение огневых работ во время подачи железнодорожных цистерн и слива и налива горючих продуктов?",
        answers: [
          "На расстоянии более 25 м",
          "На расстоянии 50 м и более",
          "На расстоянии более 10 м",
          "На расстоянии 100 м и более",
        ],
        correctAnswers: ["На расстоянии 100 м и более"],
      },
      {
        code: "20201163",
        text: "На какое расстояние от эстакады должен быть удален локомотив перед началом операции по сливу и наливу железнодорожных цистерн?",
        answers: [
          "На расстояние не менее 30 м",
          "На расстояние не менее 50 м",
          "На расстояние не менее 80 м",
          "На расстояние не менее 100 м",
        ],
        correctAnswers: ["На расстояние не менее 100 м"],
      },
      {
        code: "20201164",
        text: "С какой периодичностью рукава для слива и налива сжиженных газов, ЛВЖ и ГЖ должны подвергаться гидравлическому испытанию на прочность?",
        answers: [
          "Не реже одного раза в три месяца",
          "Не реже одного раза в два года",
          "Не реже одного раза в год",
          "Не реже одного раза в шесть месяцев",
        ],
        correctAnswers: ["Не реже одного раза в три месяца"],
      },
      {
        code: "20201165",
        text: "Разрешается ли применение гибких шлангов в качестве стационарных трубопроводов?",
        answers: [
          "Запрещается",
          "Разрешается",
          "Разрешается при согласовании с Ростехнадзором",
          "Разрешается в технически обоснованных случаях и при соответствии качества шлангов условиям эксплуатации",
          "Разрешается, если это предусмотрено регламентом работ",
        ],
        correctAnswers: ["Запрещается"],
      },
      {
        code: "20201166",
        text: "При каком остаточном давлении паров продукта не допускается налив в цистерны?",
        answers: [
          "При давлении менее 0,05 МПа",
          "При давлении менее 0,5 МПа",
          "При давлении менее 0,1 МПа",
          "При давлении менее 0,07 МПа",
        ],
        correctAnswers: ["При давлении менее 0,05 МПа"],
      },
      {
        code: "20201167",
        text: "Какие условия должны выполняться при пересечении подземных промысловых трубопроводов с автомобильными и железными дорогами?",
        answers: [
          "Должны быть установлены предупредительные знаки",
          "Трубопроводы должны быть уложены в защитные кожухи из стальных или железобетонных труб",
          "Высота перехода над дорогами должна быть не менее 10 м",
          "Все перечисленные условия",
        ],
        correctAnswers: [
          "Трубопроводы должны быть уложены в защитные кожухи из стальных или железобетонных труб",
        ],
      },
      {
        code: "20201168",
        text: "Какова функция запорных устройств на выкидных трубопроводах, непосредственно соединенных со скважинами?",
        answers: [
          "Образование вихрей и создание условий для выпадения солей и механических примесей",
          "Регулирование давления на выкиде фонтанной скважины",
          "Перекрытие потока жидкости из скважины при аварийной разгерметизации нефтегазоконденсатопровода",
          "Изменение дросселированием расхода пластовой жидкости или газа",
        ],
        correctAnswers: [
          "Перекрытие потока жидкости из скважины при аварийной разгерметизации нефтегазоконденсатопровода",
        ],
      },
      {
        code: "20201169",
        text: "Какие процедуры производятся обслуживающим персоналом перед вводом в эксплуатацию участка трубопровода или всего трубопровода?",
        answers: [
          "Очистка полости, испытание на прочность и герметичность",
          "Продувка и неразрушающий контроль",
          "Осмотр наружной поверхности",
          "Испытание на коррозионную стойкость",
          "Все перечисленные процедуры",
        ],
        correctAnswers: [
          "Очистка полости, испытание на прочность и герметичность",
        ],
      },
      {
        code: "20201170",
        text: "Какие меры безопасности должны быть выполнены перед началом продувки и испытанием трубопровода газом или воздухом?",
        answers: [
          "Необходимо определить опасные зоны и выставить наблюдающих",
          "Необходимо обозначить знаками опасные зоны, в которых запрещено находиться людям",
          "Необходимо установить видеокамеры в опасных зонах",
          "Необходимо удалить персонал в безопасную зону",
          "Необходимо установить заградительные сооружения вдоль трассы",
        ],
        correctAnswers: [
          "Необходимо обозначить знаками опасные зоны, в которых запрещено находиться людям",
        ],
      },
      {
        code: "20201171",
        text: "Как должен проводиться подвод инертного газа или пара к трубопроводам для продувки?",
        answers: [
          "С помощью съемных участков трубопроводов или гибких шлангов с установкой запорной арматуры с обеих сторон съемного участка",
          "С помощью съемных участков трубопроводов или гибких шлангов с установкой запорной арматуры с одной стороны съемного участка",
          "С помощью шлангов, имеющих металлическую оплетку, предотвращающую перегиб шланга",
          "С помощью гибкого шланга с установкой на его конце заглушки",
          "С помощью гибкого шланга с установкой на его конце обратного клапана",
        ],
        correctAnswers: [
          "С помощью съемных участков трубопроводов или гибких шлангов с установкой запорной арматуры с обеих сторон съемного участка",
        ],
      },
      {
        code: "20201172",
        text: "Допускается ли на территории охранной зоны нефтегазопроводов устройство колодцев, котлованов и других заглублений?",
        answers: [
          "Не допускается, кроме углублений, выполняемых при ремонте или реконструкции по плану производства работ эксплуатирующей организации",
          "Допускается",
          "Допускается, если они предусмотрены проектной организацией",
          "Допускается, если их обустройство согласовано с территориальным органом Ростехнадзора",
        ],
        correctAnswers: [
          "Не допускается, кроме углублений, выполняемых при ремонте или реконструкции по плану производства работ эксплуатирующей организации",
        ],
      },
      {
        code: "20201173",
        text: "Что запрещается выполнять обходчикам при профилактических осмотрах нефтегазопроводов?",
        answers: [
          "Подходить к станции катодной защиты на расстояние ближе чем на 10 м",
          "Спускаться в колодцы и другие углубления на территории охранной зоны",
          "Выходить из операторской без средств индивидуальной защиты органов дыхания",
          "Передвигаться в одиночку по территории",
        ],
        correctAnswers: [
          "Спускаться в колодцы и другие углубления на территории охранной зоны",
        ],
      },
      {
        code: "20201174",
        text: "Какие меры необходимо принять при обнаружении участков изоляции, пропитанной горючим веществом?",
        answers: [
          "Снять пропитанную изоляцию, подвести водяной пар",
          "Разместить рядом с участком огнетушитель",
          "Обтереть изоляцию ветошью",
          "Оградить и вывесить предупредительные плакаты",
        ],
        correctAnswers: ["Снять пропитанную изоляцию, подвести водяной пар"],
      },
      {
        code: "20201175",
        text: "Что необходимо устанавливать вдоль трассы подземного технологического трубопровода сжиженного газа?",
        answers: [
          "Опознавательные знаки через 50 м на прямых участках трубопровода и на каждом его повороте",
          "Опознавательные знаки через 25 м на прямых участках трубопровода",
          "Опознавательные знаки через 100 м на прямых участках трубопровода и на каждом его повороте",
        ],
        correctAnswers: [
          "Опознавательные знаки через 50 м на прямых участках трубопровода и на каждом его повороте",
        ],
      },
      {
        code: "20201176",
        text: "Какими клапанами должен быть оснащен каждый резервуар?",
        answers: [
          "Предохранительными и шаровыми",
          "Только дыхательными",
          "Дыхательными и предохранительными",
          "Только предохранительными",
        ],
        correctAnswers: ["Дыхательными и предохранительными"],
      },
      {
        code: "20201177",
        text: "Какие требования предъявляются к сплошным земляным валам, которыми должен ограждаться каждый одностенный резервуар?",
        answers: [
          "Высота вала должна быть не менее 1 м",
          "Вал должен быть рассчитан на гидростатическое давление разлившейся из резервуара жидкости",
          "Расстояние между валом и емкостью должно быть не менее 5 м",
          "Поверхность вала должна быть оборудована непроницаемым покрытием",
          "Все перечисленные требования",
        ],
        correctAnswers: [
          "Вал должен быть рассчитан на гидростатическое давление разлившейся из резервуара жидкости",
        ],
      },
      {
        code: "20201178",
        text: "Какие требования предъявляются к территориям резервуарных парков и площадкам внутри обвалования?",
        answers: [
          "Территория и площадки должны быть чистыми и иметь ров, предотвращающий распространение пожара",
          "Территория и площадки должны быть чистыми и иметь дренажный ров, предотвращающий распространение непреднамеренного разлива нефтепродукта",
          "Территория и площадки должны быть чистыми и не иметь в зоне резервуарных парков зеленых насаждений",
          "Территория и площадки должны быть чистыми и очищенными от сухой травы",
          "Территория и площадки должны быть чистыми, очищенными от земли, пропитанной продуктами хранения, и сухой травы",
        ],
        correctAnswers: [
          "Территория и площадки должны быть чистыми, очищенными от земли, пропитанной продуктами хранения, и сухой травы",
        ],
      },
      {
        code: "20201179",
        text: "Какими светильниками разрешается пользоваться на территории резервуарного парка?",
        answers: [
          "Светильниками напряжением не выше 42 В",
          "Взрывозащищенными переносными светильниками (аккумуляторными и батарейными)",
          "Стационарными светодиодными светильниками напряжением 24 В",
          "Переносными светильниками напряжением 12 В",
        ],
        correctAnswers: [
          "Взрывозащищенными переносными светильниками (аккумуляторными и батарейными)",
        ],
      },
      {
        code: "20201180",
        text: "В каких случаях разрешается одновременное автоматическое переключение задвижек в резервуарном парке?",
        answers: [
          "Только при максимальном уровне продукта в резервуаре",
          "Только при отказе в работе одной из задвижек",
          "Только при условии защиты трубопроводов от превышения давления",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: [
          "Только при условии защиты трубопроводов от превышения давления",
        ],
      },
      {
        code: "20201181",
        text: "Какое устройство должно быть предусмотрено для парового змеевика, расположенного внутри резервуара?",
        answers: [
          "Устройство для предотвращения замерзания в зимнее время при прекращении подачи пара",
          "Устройство для спуска из него конденсата",
          "Устройство, сигнализирующее о превышении температуры выше допустимых норм",
          "Устройство, сигнализирующее о прекращении подачи пара",
        ],
        correctAnswers: ["Устройство для спуска из него конденсата"],
      },
      {
        code: "20201182",
        text: "Каким образом могут производиться измерения уровня нефти и нефтепродукта и отбор проб в резервуарах с избыточным давлением в газовом пространстве до 200 мм вод. ст.?",
        answers: [
          "Вручную через поршневой клапан",
          "Вручную через открытый замерный люк",
          "Вручную через шаровой клапан",
          "Вручную через дренажный кран",
        ],
        correctAnswers: ["Вручную через открытый замерный люк"],
      },
      {
        code: "20201183",
        text: "Чем должен быть снабжен замерный люк на резервуарах?",
        answers: [
          "Герметичной крышкой с запорным устройством",
          "Герметичной крышкой с педалью для открывания ногой",
          "Герметичной крышкой с приспособлением для открывания рукой",
          "Герметичной крышкой с уплотнительной прокладкой",
          "Фиксатором",
        ],
        correctAnswers: ["Герметичной крышкой с педалью для открывания ногой"],
      },
      {
        code: "20201184",
        text: "С какой стороны следует становиться при открывании замерного люка, измерении уровня, отборе проб, а также при дренировании резервуаров?",
        answers: [
          "С подветренной стороны",
          "С наветренной стороны",
          "Над замерным люком",
          "Не регламентируется",
        ],
        correctAnswers: ["С наветренной стороны"],
      },
      {
        code: "20201185",
        text: "Кто имеет право на проведение диагностики резервуаров?",
        answers: [
          "Специализированная организация",
          "Организация - собственник резервуара",
          "Ростехнадзор",
          "Любая подрядная организация, выигравшая тендер",
        ],
        correctAnswers: ["Специализированная организация"],
      },
      {
        code: "20201186",
        text: "Кто обеспечивает ликвидацию скважин, не подлежащих использованию, а также сохранность скважин, которые могут быть использованы при разработке месторождения и (или) в иных хозяйственных целях?",
        answers: [
          "Пользователь недр",
          "Пользователь недр по согласованию с Ростехнадзором",
          "Специализированная организация",
        ],
        correctAnswers: ["Пользователь недр"],
      },
      {
        code: "20201187",
        text: "Какие разделы входят в состав документации на ликвидацию скважин?",
        answers: [
          "Общая пояснительная записка, включающая обоснование критериев и варианта ликвидации и (или) консервации скважин, вариант ликвидации и (или) консервации (в зависимости от этапа бурения или эксплуатации скважин); технологические и технические решения по ликвидации и (или) консервации скважин, оборудования ствола скважин и устья; порядок организации работ по ликвидации и (или) консервации скважин; мероприятия по безопасному пользованию недрами, безопасности жизни и здоровью населения, охраны окружающей среды, зданий и сооружений",
          "Общая пояснительная записка, включающая обоснование критериев и варианта ликвидации и (или) консервации скважин, вариант ликвидации и (или) консервации (в зависимости от этапа бурения или эксплуатации скважин); технологические и технические решения по ликвидации и (или) консервации скважин, оборудования ствола скважин и устья; порядок организации работ по ликвидации и (или) консервации скважин",
          "Технологические и технические решения по ликвидации скважин, оборудования ствола скважин и устья; порядок организации работ по ликвидации скважин; мероприятия по безопасному пользованию недрами, безопасности жизни и здоровья населения, охране - окружающей среды, зданий и сооружений; экспертиза промышленной безопасности ликвидации скважин, оборудования ствола скважин и устья",
        ],
        correctAnswers: [
          "Общая пояснительная записка, включающая обоснование критериев и варианта ликвидации и (или) консервации скважин, вариант ликвидации и (или) консервации (в зависимости от этапа бурения или эксплуатации скважин); технологические и технические решения по ликвидации и (или) консервации скважин, оборудования ствола скважин и устья; порядок организации работ по ликвидации и (или) консервации скважин; мероприятия по безопасному пользованию недрами, безопасности жизни и здоровью населения, охраны окружающей среды, зданий и сооружений",
        ],
      },
      {
        code: "20201188",
        text: "В каком случае ликвидация и консервация скважин считаются завершенными?",
        answers: [
          "После подписания акта ликвидации или консервации организацией, эксплуатирующей ОПО и территориальным органом Ростехнадзора",
          "После окончания всех работ, предусмотренных проектом",
          "После принятия работ комиссией, в которую входят представители пользователя недр и субъекта федерации, в котором расположен объект",
        ],
        correctAnswers: [
          "После подписания акта ликвидации или консервации организацией, эксплуатирующей ОПО и территориальным органом Ростехнадзора",
        ],
      },
      {
        code: "20201189",
        text: "Кто осуществляет контроль за состоянием устьев ликвидированных и законсервированных скважин?",
        answers: [
          "Пользователь недрами или уполномоченный им представитель в соответствии с действующими лицензиями на пользование недрами",
          "Территориальный орган Ростехнадзора",
          "Минприроды России",
        ],
        correctAnswers: [
          "Пользователь недрами или уполномоченный им представитель в соответствии с действующими лицензиями на пользование недрами",
        ],
      },
      {
        code: "20201190",
        text: "На какие категории подразделяются скважины, подлежащие ликвидации?",
        answers: [
          "I - скважины, выполнившие свое назначение; II - скважины, ликвидируемые по геологическим причинам; III - скважины, ликвидируемые по техническим причинам; IV - скважины, ликвидируемые по технологическим, экологическим и другим причинам",
          "I - скважины, ликвидируемые по геологическим причинам; II - скважины, ликвидируемые по техническим причинам; III - скважины, ликвидируемые по технологическим, экономическим и другим причинам",
          "I - скважины, выполнившие свое назначение; II - скважины, ликвидируемые по техническим причинам; III - скважины, ликвидируемые по причине нерентабельности (низкодебитные)",
        ],
        correctAnswers: [
          "I - скважины, выполнившие свое назначение; II - скважины, ликвидируемые по геологическим причинам; III - скважины, ликвидируемые по техническим причинам; IV - скважины, ликвидируемые по технологическим, экологическим и другим причинам",
        ],
      },
      {
        code: "20201191",
        text: "Допускается ли ликвидация скважин с негерметичными обсадными колоннами, заколонными перетоками, грифонами?",
        answers: [
          "Не регламентируется",
          "Допускается только после их устранения",
          "Допускается в любом случае",
        ],
        correctAnswers: ["Допускается только после их устранения"],
      },
      {
        code: "20201192",
        text: "Чем заполняется ствол скважины между цементными мостами и выше последнего моста при их ликвидации?",
        answers: [
          "Заполняется нейтральной жидкостью",
          "Заполняется нейтральной незамерзающей жидкостью",
          "Заполняется любой жидкостью",
        ],
        correctAnswers: ["Заполняется нейтральной жидкостью"],
      },
      {
        code: "20201193",
        text: "С какой части ствола скважины ликвидируются межпластовые перетоки (далее - МПП) и межколонные давления (далее - МКД) пластовых флюидов в процессе проведения работ по ликвидации?",
        answers: [
          "Начиная с верхней части ствола скважины",
          "Начиная с нижней части ствола скважины",
          "Не регламентируется",
        ],
        correctAnswers: ["Начиная с нижней части ствола скважины"],
      },
      {
        code: "20201194",
        text: "При каких условиях проводится установка флюидоупорных экранов, покрышек и цементных мостов, направленных на ликвидацию МКД, ликвидацию и предупреждение возникновения МПП, восстановление герметичности геологического разреза?",
        answers: [
          "Проводится под избыточным давлением",
          "Проводится только после согласования этих работ с природоохранными организациями",
          "При всех перечисленных условиях",
        ],
        correctAnswers: ["Проводится под избыточным давлением"],
      },
      {
        code: "20201195",
        text: "Каким документом подтверждается выполнение работ по ликвидации скважин?",
        answers: [
          "Протоколом, составленным по факту проведения работ",
          "Техническим отчетом с результатами исследований по проверке надежности этих работ и выводами о непригодности скважины к ее дальнейшей безопасной эксплуатации",
          "Актом на ликвидацию скважины",
        ],
        correctAnswers: ["Актом на ликвидацию скважины"],
      },
      {
        code: "20201196",
        text: "В каком случае создаются изоляционные экраны в подошвенных водоупорах и ниже интервала залегания многолетнемерзлых пород в процессе ликвидации скважин?",
        answers: [
          "При наличии в разрезе осадочного чехла месторождения зоны слабоминерализованных и питьевых верхних вод или многолетнемерзлых пород",
          "Изоляционные экраны создаются во всех проблемных геологических зонах",
          "Создание изоляционных экранов не требуется",
        ],
        correctAnswers: [
          "При наличии в разрезе осадочного чехла месторождения зоны слабоминерализованных и питьевых верхних вод или многолетнемерзлых пород",
        ],
      },
      {
        code: "20201197",
        text: "Какие данные указываются на металлической таблице, устанавливаемой на устье скважины при ее ликвидации?",
        answers: [
          "Данные о технических характеристиках скважины",
          "Номер скважины, дата ее ликвидации, месторождение (площадь), организация - пользователь недр",
          "Все перечисленные данные",
        ],
        correctAnswers: [
          "Номер скважины, дата ее ликвидации, месторождение (площадь), организация - пользователь недр",
        ],
      },
      {
        code: "20201198",
        text: "Как оборудуются скважины, расположенные на землях, используемых для сельскохозяйственных целей, и на землях непромышленных категорий при их ликвидации?",
        answers: [
          "Устья скважины углубляются не менее чем на 0,8 м от поверхности, оборудуются заглушкой, установленной на кондукторе (технической колонне) и таблицей с указанием номера скважины, месторождения (площади), организации - пользователя недр и даты ее ликвидации",
          "Устья скважины углубляются не менее чем на 0,5 м от поверхности, оборудуются заглушкой, установленной на кондукторе (технической колонне), и таблицей с указанием номера скважины, месторождения (площади), организации - пользователя недр и даты ее ликвидации",
          "Устья скважины углубляются не менее чем на 0,3 м от поверхности, оборудуются заглушкой, установленной на кондукторе (технической колонне), и таблицей с указанием номера скважины, месторождения (площади)",
        ],
        correctAnswers: [
          "Устья скважины углубляются не менее чем на 0,8 м от поверхности, оборудуются заглушкой, установленной на кондукторе (технической колонне) и таблицей с указанием номера скважины, месторождения (площади), организации - пользователя недр и даты ее ликвидации",
        ],
      },
      {
        code: "20201199",
        text: "На какие глубины устанавливаются цементные мосты по скважинам, ликвидированным по III категории, а также скважинам всех категорий, пробуренным в пределах внешнего контура нефтегазоносности и максимального размера искусственной залежи газохранилища?",
        answers: [
          "В интервале на 10 м ниже и выше мощности всех продуктивных горизонтов, продуктивность которых установлена в процессе бурения скважин, разработки месторождения, эксплуатации хранилища",
          "В интервале на 15 м ниже и выше мощности всех продуктивных горизонтов, продуктивность которых установлена в процессе бурения скважин, разработки месторождения, эксплуатации хранилища",
          "В интервале на 20 м ниже и выше мощности всех продуктивных горизонтов, продуктивность которых установлена в процессе бурения скважин, разработки месторождения, эксплуатации хранилища",
        ],
        correctAnswers: [
          "В интервале на 20 м ниже и выше мощности всех продуктивных горизонтов, продуктивность которых установлена в процессе бурения скважин, разработки месторождения, эксплуатации хранилища",
        ],
      },
      {
        code: "20201200",
        text: "С какой глубины ниже дна реки извлекаются колонна, кондуктор и направление при расположении скважин на затапливаемой территории и в русле больших (судоходных) рек?",
        answers: ["С глубины 5 м", "С глубины 15 м", "С глубины 10 м"],
        correctAnswers: ["С глубины 10 м"],
      },
      {
        code: "20201201",
        text: "Как производится ликвидация скважины без эксплуатационной колонны в зависимости от горно-геологических условий вскрытого разреза?",
        answers: [
          "Путем установки в открытом стволе цементных мостов в интервалах залегания высоконапорных минерализованных вод с коэффициентом аномальности больше 1.1 и низкопродуктивных горизонтов не имеющих промышленного значения залежей углеводородов",
          "Путем установки цементных мостов во всех продуктивных горизонтах",
          "Путем установки в открытом стволе цементных мостов в интервалах залегания горизонтов с аномальным пластовым давлением",
        ],
        correctAnswers: [
          "Путем установки в открытом стволе цементных мостов в интервалах залегания высоконапорных минерализованных вод с коэффициентом аномальности больше 1.1 и низкопродуктивных горизонтов не имеющих промышленного значения залежей углеводородов",
        ],
      },
      {
        code: "20201202",
        text: "Какой высоты должен быть цементный мост, который устанавливается над кровлей верхнего пласта с минерализованной водой, а также на границе залегания пластов с пресными и минерализованными водами (если они не перекрыты технической колонной) при ликвидации скважин без эксплуатационной колонны?",
        answers: ["50 м", "70 м", "100 м"],
        correctAnswers: ["50 м"],
      },
      {
        code: "20201203",
        text: "Как проверяется наличие мостов при ликвидации скважин без эксплуатационной колонны?",
        answers: [
          "Методом гидравлической опрессовки",
          "Разгрузкой бурильного инструмента или насосно-компрессорных труб с усилием, не превышающим предельно допустимую удельную нагрузку на цементный камень",
          "Путем проведения исследовательских работ",
        ],
        correctAnswers: [
          "Разгрузкой бурильного инструмента или насосно-компрессорных труб с усилием, не превышающим предельно допустимую удельную нагрузку на цементный камень",
        ],
      },
      {
        code: "20201204",
        text: "Какие действия необходимо предпринять при аварии с колонной бурильных труб, когда ее верхняя часть осталась в интервале ствола, перекрытого технической колонной или кондуктором?",
        answers: [
          "Производится извлечение части бурильной колонны, находящейся выше башмака технической колонны или кондуктора, цементирование под давлением с установкой цементного моста на уровне не менее 50 м над башмаком технической колонны",
          "Производится извлечение части бурильной колонны и установка цементных мостов в интервалах залегания пресных и минерализованных вод и продуктивных горизонтов",
          "Производится извлечение части бурильной колонны, находящейся выше башмака технической колонны или кондуктора, цементирование под давлением с установкой цементного моста на уровне не менее 100 м над башмаком технической колонны. Оставшаяся часть технической колонны заполняется глинистым раствором. Верхняя часть колонны заполняется нейтральной незамерзающей жидкостью",
        ],
        correctAnswers: [
          "Производится извлечение части бурильной колонны, находящейся выше башмака технической колонны или кондуктора, цементирование под давлением с установкой цементного моста на уровне не менее 100 м над башмаком технической колонны. Оставшаяся часть технической колонны заполняется глинистым раствором. Верхняя часть колонны заполняется нейтральной незамерзающей жидкостью",
        ],
      },
      {
        code: "20201205",
        text: "Что необходимо предпринять при ликвидации скважин с нарушенной колонной из-за аварии или коррозии эксплуатационной колонны вследствие длительных сроков эксплуатации?",
        answers: [
          "Провести исследование по определению наличия и качества цементного камня за колонной, цементирование в интервалах его отсутствия и установку цементного моста в интервале на 20 м выше и ниже части колонны, подверженной коррозии или нарушениям из-за аварии, с последующим испытанием оставшейся части колонны и моста снижением уровня или заменой на жидкость меньшей плотности. Верхняя часть ствола заполняется нейтральной незамерзающей жидкостью",
          "Провести исследование по определению наличия и качества цементного камня за колонной, цементирование в интервалах его отсутствия и установку цементного моста в интервале на 10 м выше и ниже части колонны, подверженной коррозии или нарушениям из-за аварии",
          "Выявить интервалы нарушения цементного камня за колонной и произвести установку цементных мостов во всех интервалах нарушения",
        ],
        correctAnswers: [
          "Провести исследование по определению наличия и качества цементного камня за колонной, цементирование в интервалах его отсутствия и установку цементного моста в интервале на 20 м выше и ниже части колонны, подверженной коррозии или нарушениям из-за аварии, с последующим испытанием оставшейся части колонны и моста снижением уровня или заменой на жидкость меньшей плотности. Верхняя часть ствола заполняется нейтральной незамерзающей жидкостью",
        ],
      },
      {
        code: "20201206",
        text: "В каких случаях допускается оборудование устья ликвидированных скважин без установки тумбы?",
        answers: [
          "При нахождении скважины на сельскохозяйственных землях",
          "Ни в каких случаях",
          "При нахождении скважины на территории подземного газового хранилища",
        ],
        correctAnswers: [
          "При нахождении скважины на территории подземного газового хранилища",
        ],
      },
      {
        code: "20201207",
        text: "Допускается ли принимать консервационные цементные мосты в качестве ликвидационных?",
        answers: [
          "Допускается",
          "Допускается по скважинам, вскрывшим малодебитные, низконапорные пласты с коэффициентом аномалии давления менее 1,1, принимать консервационные цементные мосты в качестве ликвидационных и при условии, что мост перекрывает весь интервал перфорации и не менее чем на 50 м выше его",
          "Не допускается",
        ],
        correctAnswers: [
          "Допускается по скважинам, вскрывшим малодебитные, низконапорные пласты с коэффициентом аномалии давления менее 1,1, принимать консервационные цементные мосты в качестве ликвидационных и при условии, что мост перекрывает весь интервал перфорации и не менее чем на 50 м выше его",
        ],
      },
      {
        code: "20201208",
        text: "Как необходимо производить установку цементного моста при ликвидации скважины с аварийным оборудованием в стволе скважины?",
        answers: [
          "Под давлением в интервалах перфорации и с перекрытием головы оставшегося инструмента на 30 м",
          "Под давлением в интервалах перфорации и с перекрытием головы оставшегося инструмента на 15 м",
          "Под давлением в интервалах перфорации и с перекрытием головы оставшегося инструмента на 20 м",
        ],
        correctAnswers: [
          "Под давлением в интервалах перфорации и с перекрытием головы оставшегося инструмента на 20 м",
        ],
      },
      {
        code: "20201209",
        text: "Каким давлением проводится опрессовка межколонного пространства после установки верхнего моста при ликвидации скважин со спущенной эксплуатационной колонной?",
        answers: ["3 МПа", "5 МПа", "7 МПа"],
        correctAnswers: ["5 МПа"],
      },
      {
        code: "20201210",
        text: "Что необходимо предпринять при ликвидации скважин в результате аварии с внутрискважинным оборудованием (категория III-а) и невозможности его извлечения?",
        answers: [
          "Необходимо произвести торпедирование или отворот неприхваченной части инструмента",
          "Необходимо произвести установку цементного моста под давлением с перекрытием головы оставшегося инструмента на 50 м",
          "Необходимо произвести установку цементного моста в интервалах продуктивности",
        ],
        correctAnswers: [
          "Необходимо произвести торпедирование или отворот неприхваченной части инструмента",
        ],
      },
      {
        code: "20201211",
        text: "Представители каких служб входят в состав комиссии, созданной пользователем недр (или его представителем) для оформления комплекта документов на ликвидацию скважины?",
        answers: [
          "Представители геологической службы, главного инженера, службы промышленной и экологической безопасности, представители территориального органа Ростехнадзора",
          "Представители геологической службы, службы бурения, главного инженера, службы промышленной и экологической безопасности, дополнительно в комиссию могут привлекаться необходимые специалисты (геолог, экономист, главный бухгалтер)",
          "Представители службы бурения, главного инженера, службы промышленной и экологической безопасности, дополнительно в комиссию могут привлекаться необходимые специалисты (геолог, экономист, главный бухгалтер), представители территориального органа Росприроднадзора",
        ],
        correctAnswers: [
          "Представители геологической службы, службы бурения, главного инженера, службы промышленной и экологической безопасности, дополнительно в комиссию могут привлекаться необходимые специалисты (геолог, экономист, главный бухгалтер)",
        ],
      },
      {
        code: "20201212",
        text: "Что является основанием для подготовки плана изоляционно-ликвидационных работ на конкретную скважину?",
        answers: [
          "Результаты исследовательских работ на данной скважине",
          "Требование Ростехнадзора",
          "Решение комиссии о ликвидации группы скважин (скважины)",
        ],
        correctAnswers: [
          "Решение комиссии о ликвидации группы скважин (скважины)",
        ],
      },
      {
        code: "20201213",
        text: "Какие организации осуществляют учет актов о ликвидации скважин?",
        answers: [
          "Территориальные органы Ростехнадзора",
          "МЧС России",
          "Территориальные органы Росприроднадзора",
        ],
        correctAnswers: ["Территориальные органы Ростехнадзора"],
      },
      {
        code: "20201214",
        text: "Где хранятся все материалы по ликвидированной скважине, включая подписанный сторонами акт на ликвидацию?",
        answers: [
          "У пользователя недр",
          "В территориальных органах Росприроднадзора",
          "В территориальных органах Ростехнадзора",
        ],
        correctAnswers: ["У пользователя недр"],
      },
      {
        code: "20201215",
        text: "Кто осуществляет ежегодный контроль за состоянием устьев ликвидированных скважин?",
        answers: [
          "Пользователь недр",
          "Территориальные органы Росприроднадзора",
          "Минприроды России",
        ],
        correctAnswers: ["Пользователь недр"],
      },
      {
        code: "20201216",
        text: "В соответствии с какими требованиями осуществляется оборудование устья и ствола, срок консервации, порядок контроля за техническим состоянием законсервированных скважин?",
        answers: [
          "Только в соответствии с документацией, разработанной пользователями недр",
          "В соответствии с требованиями федеральных норм и правил в области промышленной безопасности и документации, разработанной пользователями недр или их уполномоченными представителями исходя из конкретных горно-геологических условий",
          "В соответствии с требованиями проектной документации на консервацию скважин",
        ],
        correctAnswers: [
          "В соответствии с требованиями федеральных норм и правил в области промышленной безопасности и документации, разработанной пользователями недр или их уполномоченными представителями исходя из конкретных горно-геологических условий",
        ],
      },
      {
        code: "20201217",
        text: "Какие мероприятия необходимо провести при обнаружении недостатков (устьевое давление, межколонные проявления, грифоны и др.) в ходе проверок (или в других случаях) законсервированных скважин?",
        answers: [
          "Скважина должна быть ликвидирована",
          "Скважина должна быть выведена из консервации. Пользователь недр обязан выяснить причины недостатков, разработать и реализовать мероприятия по их устранению",
          "Скважина должна быть выведена из консервации и поставлена на капитальный ремонт",
        ],
        correctAnswers: [
          "Скважина должна быть выведена из консервации. Пользователь недр обязан выяснить причины недостатков, разработать и реализовать мероприятия по их устранению",
        ],
      },
      {
        code: "20201218",
        text: "На какой срок возможна временная приостановка скважин (без консервации) в связи с экономическими причинами (до строительства системы сбора и подготовки добываемой жидкости, отсутствие спроса на сырье, нерентабельность эксплуатации)?",
        answers: [
          "На срок до 6 месяцев с последующим продлением при условии выполнения мероприятий по безопасному пользованию недрами, безопасности жизни и здоровья населения, охране окружающей среды на срок приостановки скважин",
          "На срок до 12 месяцев с последующим продлением при условии выполнения мероприятий по безопасному пользованию недрами, безопасности жизни и здоровья населения, охране окружающей среды на срок приостановки скважин",
          "На срок до 18 месяцев с последующим продлением при условии выполнения мероприятий по безопасному пользованию недрами, безопасности жизни и здоровья населения, охране окружающей среды на срок приостановки скважин",
        ],
        correctAnswers: [
          "На срок до 6 месяцев с последующим продлением при условии выполнения мероприятий по безопасному пользованию недрами, безопасности жизни и здоровья населения, охране окружающей среды на срок приостановки скважин",
        ],
      },
      {
        code: "20201219",
        text: "В каких из перечисленных случаев производится консервация скважин в процессе бурения?",
        answers: [
          "В случае недостаточной изученности геологического разреза скважин",
          "В случае экономической целесообразности",
          "В случае несоответствия фактических геолого-технических условий проектным",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: [
          "В случае несоответствия фактических геолого-технических условий проектным",
        ],
      },
      {
        code: "20201220",
        text: "Какой установлен срок консервации скважин после эксплуатации без установки консервационного моста над интервалом перфорации?",
        answers: ["3 года", "5 лет", "1 год", "7 лет"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20201221",
        text: "Что необходимо предпринять в скважинах, эксплуатирующих два и более горизонта с разными пластовыми давлениями?",
        answers: [
          "Следует отрабатывать горизонты последовательно",
          "Следует провести необходимые разобщения этих горизонтов",
          "Следует изолировать малопродуктивный горизонт",
        ],
        correctAnswers: [
          "Следует провести необходимые разобщения этих горизонтов",
        ],
      },
      {
        code: "20201222",
        text: "Какие дополнительные требования предъявляются при ликвидации скважин на месторождениях с высоким содержанием сернистого водорода (более 6 %)?",
        answers: [
          "Продуктивный пласт должен перекрываться цементным мостом по всей его мощности и на 70 м выше кровли",
          "Продуктивный пласт должен перекрываться цементным мостом по всей его мощности и на 50 м выше кровли",
          "Продуктивный пласт должен перекрываться цементным мостом по всей его мощности и на 100 м выше кровли",
        ],
        correctAnswers: [
          "Продуктивный пласт должен перекрываться цементным мостом по всей его мощности и на 100 м выше кровли",
        ],
      },
    ],

    20202: [
      {
        code: "20202000",
        text: "Что должно быть предусмотрено в оперативной части ПЛА?",
        answers: [
          "Только мероприятия по спасению людей и ликвидации аварии",
          "Только распределение обязанностей между отдельными лицами, участвующими в ликвидации аварии",
          "Только места нахождения средств для спасения людей и ликвидации аварий",
          "Только действия газоспасателей, пожарных и других подразделений",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20202001",
        text: "В какие сроки необходимо пересматривать ПЛА?",
        answers: [
          "В сроки, установленные Правительством Российской Федерации",
          "ПЛА пересмотру не подлежит",
          "Каждый раз, когда изменяется технология и условия работы",
          "1 раз в 5 лет",
        ],
        correctAnswers: [
          "В сроки, установленные Правительством Российской Федерации",
        ],
      },
      {
        code: "20202002",
        text: "Кто утверждает ПЛА?",
        answers: [
          "Главный инженер предприятия",
          "Технический руководитель предприятия",
          "Специалист по охране труда и промышленной безопасности",
          "Работник, ответственный за организацию и осуществление производственного контроля",
        ],
        correctAnswers: ["Технический руководитель предприятия"],
      },
      {
        code: "20202003",
        text: "В каком порядке осуществляется допуск подрядных организаций на опасные производственные объекты нефтегазодобывающих производств?",
        answers: [
          "В соответствии с положением о порядке допуска и организации безопасного производства работ, утвержденным организацией, эксплуатирующей опасные производственные объекты нефтегазодобывающих производств",
          "В соответствии с графиком взаимодействия, согласованным с заинтересованными организациями",
          "В соответствии с инструкцией, устанавливающей требования к организации работ, утвержденной организацией, эксплуатирующей опасные производственные объекты нефтегазодобывающих производств",
          "В соответствии с производственным заданием, выданным руководителем организации, эксплуатирующей опасные производственные объекты нефтегазодобывающих производств, или лицом, его заменяющим",
        ],
        correctAnswers: [
          "В соответствии с положением о порядке допуска и организации безопасного производства работ, утвержденным организацией, эксплуатирующей опасные производственные объекты нефтегазодобывающих производств",
        ],
      },
      {
        code: "20202004",
        text: "Кто утверждает перечень работ, осуществляемых по наряду-допуску, порядок оформления нарядов-допусков, перечни должностей специалистов, имеющих право руководить этими работами?",
        answers: [
          "Ответственный руководитель вышестоящей организации",
          "Руководитель территориального органа Ростехнадзора",
          "Руководитель организации или уполномоченное им лицо",
          "Руководитель структурного подразделения",
          "Ответственный исполнитель работ",
        ],
        correctAnswers: ["Руководитель организации или уполномоченное им лицо"],
      },
      {
        code: "20202005",
        text: "На основании какого документа осуществляются работы повышенной опасности на опасных производственных объектах?",
        answers: [
          "На основании разрешения",
          "На основании руководства по эксплуатации оборудования",
          "На основании наряда-допуска",
          "На основании положения об организации безопасного производства работ",
        ],
        correctAnswers: ["На основании наряда-допуска"],
      },
      {
        code: "20202006",
        text: "Требования какого документа обеспечивают безопасность технологических процессов на объектах добычи, сбора и подготовки нефти, газа и газового конденсата?",
        answers: [
          "Руководства по эксплуатации оборудования",
          "Проектной документации на эксплуатацию опасного производственного объекта",
          "Федеральных норм и правил в области промышленной безопасности",
          "ТР на каждый технологический процесс опасного производственного объекта",
        ],
        correctAnswers: [
          "ТР на каждый технологический процесс опасного производственного объекта",
        ],
      },
      {
        code: "20202007",
        text: "Какими организациями разрабатываются и утверждаются ТР на работы по добыче, сбору и подготовке нефти, газа и газового конденсата?",
        answers: [
          "Разрабатываются проектной организацией на стадии проектирования и строительства, а также реконструкции. ТР на ОПО, находящийся в эксплуатации, может разрабатываться эксплуатирующей организацией",
          "Разрабатываются и утверждаются специализированными организациями",
          "Разрабатываются и утверждаются эксплуатирующей организацией",
          "Разрабатываются проектной организацией, а утверждаются эксплуатирующей организацией",
          "Разрабатываются проектной организацией, а утверждаются территориальными органами Ростехнадзора",
        ],
        correctAnswers: [
          "Разрабатываются проектной организацией на стадии проектирования и строительства, а также реконструкции. ТР на ОПО, находящийся в эксплуатации, может разрабатываться эксплуатирующей организацией",
        ],
      },
      {
        code: "20202008",
        text: "Какие требования предъявляются к техническим устройствам, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями?",
        answers: [
          "Документация на технические устройства, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями, должна быть согласована с разработчиком этого оборудования",
          "Документация на технические устройства, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями, должна быть согласована с надзорными органами",
          "Технические устройства, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями, должны пройти приемо-сдаточные испытания, результаты которых оформляются актом эксплуатирующей организации",
        ],
        correctAnswers: [
          "Технические устройства, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями, должны пройти приемо-сдаточные испытания, результаты которых оформляются актом эксплуатирующей организации",
        ],
      },
      {
        code: "20202009",
        text: "Кем определяются критерии предельных состояний и критерии вывода из эксплуатации оборудования, инструментов, контрольно-измерительных приборов?",
        answers: [
          "Определяются изготовителем и вносятся в эксплуатационную документацию изготовителя или разработчика технического устройства",
          "Определяются Ростехнадзором или его территориальным органом на основании экспертизы промышленной безопасности",
          "Определяются эксплуатирующей организацией или ее структурным подразделением на основании диагностирования",
        ],
        correctAnswers: [
          "Определяются изготовителем и вносятся в эксплуатационную документацию изготовителя или разработчика технического устройства",
        ],
      },
      {
        code: "20202010",
        text: "Какими светильниками должны быть обеспечены опасные производственные объекты нефтегазодобывающих производств?",
        answers: [
          "Стационарными светильниками напряжением 12 В во взрывозащищенном исполнении",
          "Стационарными светильниками напряжением 36 В во взрывозащищенном исполнении",
          "Переносными светильниками, для питания которых должно применяться напряжение не выше 50 В, - в особо опасных помещениях, а в наружных установках - не выше 12 В",
          "Переносными светильниками напряжением 42 В во взрывозащищенном исполнении",
        ],
        correctAnswers: [
          "Переносными светильниками, для питания которых должно применяться напряжение не выше 50 В, - в особо опасных помещениях, а в наружных установках - не выше 12 В",
        ],
      },
      {
        code: "20202011",
        text: "Разрешается ли последовательно включать в заземляющее устройство несколько заземляемых объектов?",
        answers: [
          "Разрешается по согласованию с главным энергетиком организации",
          "Разрешается",
          "Разрешается в исключительных случаях и по согласованию с территориальным органом Ростехнадзора",
          "Запрещается",
        ],
        correctAnswers: ["Запрещается"],
      },
      {
        code: "20202012",
        text: "Какие виды ремонта нефтяных и газовых скважин определены Правилами безопасности в нефтяной и газовой промышленности?",
        answers: [
          "Внеплановый и текущий ремонт нефтяных и газовых скважин",
          "Ремонтные работы по восстановлению работоспособности скважин",
          "Ремонтные работы по переводу скважин на другие горизонты",
          "Текущий и капитальный ремонт нефтяных и газовых скважин",
        ],
        correctAnswers: [
          "Текущий и капитальный ремонт нефтяных и газовых скважин",
        ],
      },
      {
        code: "20202013",
        text: "Какие виды работ относятся к реконструкции нефтяных и газовых скважин в соответствии с Правилами безопасности в нефтяной и газовой промышленности?",
        answers: [
          "Работы по восстановлению работоспособности скважин, утраченной в результате аварии или инцидента",
          "Работы по изоляции пластов",
          "Работы по оптимизации режима эксплуатации нефтяных и газовых скважин",
          "Комплекс работ по восстановлению работоспособности скважин, связанный с изменением их конструкции",
        ],
        correctAnswers: [
          "Комплекс работ по восстановлению работоспособности скважин, связанный с изменением их конструкции",
        ],
      },
      {
        code: "20202014",
        text: "В соответствии с каким документом осуществляются ремонтные работы на нефтяных и газовых скважинах?",
        answers: [
          "В соответствии с рабочей документацией на строительство и реконструкцию скважин",
          "На основании планов работ по текущему, капитальному ремонту и реконструкции нефтяных и газовых скважин, порядок разработки и условия согласования которых устанавливаются организацией, эксплуатирующей ОПО",
          "На основании плана работ по текущему, капитальному ремонту и реконструкции нефтяных и газовых скважин, разработанного подрядной организацией, осуществляющей ремонтные работы",
        ],
        correctAnswers: [
          "На основании планов работ по текущему, капитальному ремонту и реконструкции нефтяных и газовых скважин, порядок разработки и условия согласования которых устанавливаются организацией, эксплуатирующей ОПО",
        ],
      },
      {
        code: "20202015",
        text: "В каких случаях производится забуривание новых (боковых) стволов в обсаженных скважинах?",
        answers: [
          "Только в случае ликвидации аварий, инцидентов и осложнений, возникающих в процессе бурения, эксплуатации скважины или при проведении ремонтных работ",
          "Только в случае вскрытия дополнительных продуктивных мощностей путем проводки ответвлений (в том числе горизонтальных) из пробуренных стволов скважин",
          "Только в случае восстановления бездействующего фонда скважин, в том числе ранее ликвидированных по техническим или иным причинам, с целью вскрытия новым стволом участков с неизвлеченными запасами углеводородного сырья",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20202016",
        text: "В каком порядке производятся передача скважин для ремонта или реконструкции и приемка скважин после завершения ремонтных работ от заказчика подрядчику?",
        answers: [
          "Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ устанавливается эксплуатирующей организацией",
          "Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ устанавливается нормативно-технической документацией Ростехнадзора",
          "Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ устанавливается подрядной организацией и утверждается заказчиком",
        ],
        correctAnswers: [
          "Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ устанавливается эксплуатирующей организацией",
        ],
      },
      {
        code: "20202017",
        text: "В каких случаях при реконструкции и ремонте скважин на рабочей площадке проводится контроль состояния газовоздушной среды?",
        answers: [
          "Если возможно газонефтеводопроявление",
          "Если работы ведутся на кустовой площадке",
          "Всегда при проведении работ по реконструкции и ремонту скважин на рабочей площадке проводится контроль состояния газовоздушной среды с регистрацией в журнале контроля",
        ],
        correctAnswers: [
          "Всегда при проведении работ по реконструкции и ремонту скважин на рабочей площадке проводится контроль состояния газовоздушной среды с регистрацией в журнале контроля",
        ],
      },
      {
        code: "20202018",
        text: "С какими документами должна быть ознакомлена бригада, осуществляющая ремонт и реконструкцию нефтяных и газовых скважин, перед началом работ?",
        answers: [
          "С инструкциями по видам работ",
          "С нарядом-допуском",
          "С планом работ, ПЛА и возможными осложнениями и авариями",
        ],
        correctAnswers: [
          "С планом работ, ПЛА и возможными осложнениями и авариями",
        ],
      },
      {
        code: "20202019",
        text: "Какие требования определены Правилами безопасности в нефтяной и газовой промышленности к установке агрегатов для ремонта скважин, оборудования?",
        answers: [
          "Агрегаты для ремонта скважин, оборудование устанавливаются на расстоянии от устья скважины, предусмотренном в плане проведения работ",
          "Агрегаты для ремонта скважин, оборудование должны устанавливаться на специальный настил",
          "Агрегаты для ремонта скважин, оборудование должны устанавливаться на передвижные или стационарные фундаменты, выполненные в соответствии с инструкциями по эксплуатации или документацией по обустройству кустов скважин",
        ],
        correctAnswers: [
          "Агрегаты для ремонта скважин, оборудование должны устанавливаться на передвижные или стационарные фундаменты, выполненные в соответствии с инструкциями по эксплуатации или документацией по обустройству кустов скважин",
        ],
      },
      {
        code: "20202020",
        text: "Каким канатом должен быть обмотан промывочный шланг?",
        answers: [
          "Стальным мягким канатом диаметром не менее 8 мм с петлями через каждые 1 - 1,5 м по всей длине шланга",
          "Стальным мягким канатом диаметром не менее 5 мм с петлями через каждые 2 - 2,5 м по всей длине шланга",
          "Стальным мягким канатом диаметром не менее 4 мм с петлями через каждые 1,5 - 2 м по всей длине шланга",
          "Стальным мягким канатом диаметром не менее 7 мм с петлями через каждые 2,5 - 3 м по всей длине шланга",
        ],
        correctAnswers: [
          "Стальным мягким канатом диаметром не менее 8 мм с петлями через каждые 1 - 1,5 м по всей длине шланга",
        ],
      },
      {
        code: "20202021",
        text: "В каких случаях устье скважин на период ремонта оснащается противовыбросовым оборудованием?",
        answers: [
          "Во всех перечисленных случаях",
          "Только в случаях ведения работ на кустовых площадках",
          "В случаях возможных газонефтеводопроявлений на скважинах",
        ],
        correctAnswers: [
          "В случаях возможных газонефтеводопроявлений на скважинах",
        ],
      },
      {
        code: "20202022",
        text: "Кто должен руководить передвижением агрегатов по ремонту скважин и транспортированием оборудования на скважину?",
        answers: [
          "Любой специалист",
          "Ответственное лицо",
          "Руководитель организации",
          "Специалист по охране труда и промышленной безопасности",
        ],
        correctAnswers: ["Ответственное лицо"],
      },
      {
        code: "20202023",
        text: "С чем должны быть ознакомлены работники, принимающие участие в транспортировании оборудования?",
        answers: [
          "С краткосрочным прогнозом погоды",
          "С трассой передвижения, опасными участками и мерами безопасности при их преодолении",
          "С техническими характеристиками подвижного состава",
          "Со всем перечисленным",
        ],
        correctAnswers: [
          "С трассой передвижения, опасными участками и мерами безопасности при их преодолении",
        ],
      },
      {
        code: "20202024",
        text: "При каких погодных условиях запрещается передвижение оборудования на скважину?",
        answers: [
          "При снегопаде, тумане, пылевых бурях, порывах ветра более 10 м/с",
          "При снегопаде, тумане, пылевых бурях при видимости менее 50 м, порывах ветра более 30 м/с",
          "При тумане, пылевых бурях, порывах ветра более 15 м/с",
          "При снегопаде, тумане, пылевых бурях",
        ],
        correctAnswers: [
          "При снегопаде, тумане, пылевых бурях при видимости менее 50 м, порывах ветра более 30 м/с",
        ],
      },
      {
        code: "20202025",
        text: "Какие требования предъявляются к территориям при подготовке скважины к ремонту?",
        answers: [
          "Территория должна быть ограждена металлической сеткой высотой более 1,5 м",
          "По периметру территории должна быть установлена световая и звуковая сигнализация",
          "На территории должны быть установлены плакаты, содержащие сведения о ремонтной организации",
          "Территория должна быть спланирована, освобождена от посторонних предметов, подземные коммуникации четко обозначены, а газопроводы газлифтной скважины заключены в патрон",
        ],
        correctAnswers: [
          "Территория должна быть спланирована, освобождена от посторонних предметов, подземные коммуникации четко обозначены, а газопроводы газлифтной скважины заключены в патрон",
        ],
      },
      {
        code: "20202026",
        text: "Когда ремонтной бригаде должна выдаваться схема расположения подземных и наземных коммуникаций вокруг ремонтируемой скважины?",
        answers: [
          "Не менее чем за трое суток до начала производства работ",
          "Не менее чем за двое суток до начала производства работ",
          "Не менее чем за сутки до начала производства работ",
          "Непосредственно перед началом производства работ",
        ],
        correctAnswers: [
          "Не менее чем за трое суток до начала производства работ",
        ],
      },
      {
        code: "20202027",
        text: "На каком расстоянии от устья скважины можно располагать бытовые помещения?",
        answers: [
          "На расстоянии не менее высоты мачты агрегата плюс 10 м",
          "На расстоянии не менее высоты мачты агрегата плюс 7 м",
          "На расстоянии не менее высоты мачты агрегата плюс 3 м",
          "На расстоянии не менее высоты мачты агрегата плюс 5 м",
        ],
        correctAnswers: [
          "На расстоянии не менее высоты мачты агрегата плюс 10 м",
        ],
      },
      {
        code: "20202028",
        text: "Каким образом происходит передвижение транспортных средств на кустовых площадках?",
        answers: [
          "В соответствии с установленными маршрутами передвижения и под контролем ответственного руководителя работ",
          "В соответствии с графиком передвижения",
          "По имеющимся в наличии дорогам со скоростью не более 40 км/ч",
          "Не регламентируется",
        ],
        correctAnswers: [
          "В соответствии с установленными маршрутами передвижения и под контролем ответственного руководителя работ",
        ],
      },
      {
        code: "20202029",
        text: "При каких погодных условиях запрещается проводить работы на высоте?",
        answers: [
          "Только при грозе, ливне, снегопаде",
          "Только при скорости ветра более 15 м/с",
          "Только при гололедице",
          "При всех вышеперечисленных факторах",
        ],
        correctAnswers: ["При всех вышеперечисленных факторах"],
      },
      {
        code: "20202030",
        text: "Какое натяжение должны иметь оттяжки агрегатов по ремонту скважин (установок)?",
        answers: [
          "Не менее 300 - 350 кгс",
          "Не менее 400 - 500 кгс",
          "Не менее 250 - 350 кгс",
          "Не менее 100 - 250 кгс",
        ],
        correctAnswers: ["Не менее 400 - 500 кгс"],
      },
      {
        code: "20202031",
        text: "На какое давление должны быть опрессованы нагнетательные линии, собранные из труб с быстросъемными соединительными гайками и шарнирными коленями (угольниками)?",
        answers: [
          "На полуторакратное давление от максимального рабочего давления, предусмотренного планом работ",
          "На давление, равное максимальному рабочему давлению, предусмотренному планом работ",
          "На двукратное давление от максимального рабочего давления, предусмотренного планом работ",
          "На трехкратное давление от максимального рабочего давления, предусмотренного планом работ",
        ],
        correctAnswers: [
          "На полуторакратное давление от максимального рабочего давления, предусмотренного планом работ",
        ],
      },
      {
        code: "20202032",
        text: "Какой должна быть минимальная ширина настила приемных мостков?",
        answers: ["0,9 м", "1,0 м", "0,8 м", "0,5 м"],
        correctAnswers: ["1,0 м"],
      },
      {
        code: "20202033",
        text: "На сколько должен быть сработан деревянный настил мостков и рабочей площадки, чтобы его заменили?",
        answers: [
          "Более 10 % от первоначальной толщины",
          "Более 15 % от первоначальной толщины",
          "Более 5 % от первоначальной толщины",
          "Более 7 % от первоначальной толщины",
        ],
        correctAnswers: ["Более 15 % от первоначальной толщины"],
      },
      {
        code: "20202034",
        text: "Какие требования предъявляются к емкости для долива скважины при выполнении ремонтных работ?",
        answers: [
          "Емкость должна быть обвязана с устьем скважины с таким расчетом, чтобы обеспечивался постоянный долив жидкости в скважину самотеком или принудительно с использованием насоса",
          "Объем емкости долива должен быть не менее 4,5 м³",
          "Емкость должна быть оборудована уровнемером, имеющим градуировку с ценой деления 0,2 м³",
          "Емкость должна устанавливаться на расстоянии не менее 10 м от устья ремонтируемой скважины, в зоне видимости бурильщика капитального ремонта скважин (оператора текущего ремонта скважин)",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20202035",
        text: "Какое напряжение необходимо для энергообеспечения электрооборудования агрегатов для ремонта скважин?",
        answers: [
          "Не более 450 В",
          "Не более 500 В",
          "Не более 400 В",
          "Не более 600 В",
        ],
        correctAnswers: ["Не более 400 В"],
      },
      {
        code: "20202036",
        text: "Каким кабелем должно осуществляться подключение станции управления к нефтепромысловой сети напряжением 400 В или передвижной электростанции?",
        answers: [
          "Двухжильным кабелем в металлической оплетке с применением разъема с экранирующим контуром",
          "Трехжильным кабелем с двойной изоляцией и заземляющим контуром",
          "Гибким пятипроводным кабелем посредством четырехконтактного разъема с заземляющим контактом",
          "Бронированным трехжильным кабелем с заземляющим контактом",
        ],
        correctAnswers: [
          "Гибким пятипроводным кабелем посредством четырехконтактного разъема с заземляющим контактом",
        ],
      },
      {
        code: "20202037",
        text: "Каким должно быть минимальное расстояние между проложенными электрическими кабелями и трубопроводами?",
        answers: ["0,4 м", "0,5 м", "0,1 м", "0,2 м"],
        correctAnswers: ["0,5 м"],
      },
      {
        code: "20202038",
        text: "В каком случае допускается пересечение электрокабелем внутрипромысловых дорог?",
        answers: [
          "В трубах на глубине не менее 0,5 м от полотна дороги",
          "В трубах на глубине не менее 0,4 м от полотна дороги",
          "В трубах на глубине не менее 0,3 м от полотна дороги",
          "В трубах на глубине не менее 0,2 м от полотна дороги",
        ],
        correctAnswers: [
          "В трубах на глубине не менее 0,5 м от полотна дороги",
        ],
      },
      {
        code: "20202039",
        text: "Разрешается ли совместная прокладка электрических кабелей и трубопроводов?",
        answers: [
          "Разрешается по согласованию с органом Ростехнадзора",
          "Разрешается по согласованию с заказчиком проекта",
          "Запрещается",
          "Разрешается",
        ],
        correctAnswers: ["Запрещается"],
      },
      {
        code: "20202040",
        text: "Кто имеет право на подключение переносных светильников и разводку кабелей в полевых условиях?",
        answers: [
          "Только электромонтер",
          "Электромонтер и рабочий бригады или двое рабочих бригады, прошедшие соответствующий инструктаж, при условии, что один из них имеет квалификационную группу не ниже второй",
          "Рабочие бригады, прошедшие соответствующий инструктаж",
          "Правилами не регламентируется",
        ],
        correctAnswers: [
          "Электромонтер и рабочий бригады или двое рабочих бригады, прошедшие соответствующий инструктаж, при условии, что один из них имеет квалификационную группу не ниже второй",
        ],
      },
      {
        code: "20202041",
        text: "Что из нижеперечисленного подлежит заземлению при ведении ремонтных работ?",
        answers: [
          "Только корпусы генераторов передвижных электростанций",
          "Только каркасы распределительных щитов станций управления",
          "Только емкости под раствор для глушения или долива скважины",
          "Только передвижные агрегаты для ремонта скважин",
          "Все вышеперечисленное",
        ],
        correctAnswers: ["Все вышеперечисленное"],
      },
      {
        code: "20202042",
        text: "Кто утверждает состав комиссии, которая производит пуск смонтированной установки в работу?",
        answers: [
          "Экспертная организация",
          "Эксплуатирующая организация",
          "Представитель Ростехнадзора",
          "Нештатное аварийно-спасательное формирование",
        ],
        correctAnswers: ["Эксплуатирующая организация"],
      },
      {
        code: "20202043",
        text: "Что необходимо сделать с электрокабелями, попадающими в зону перемещения и монтажа оборудования ремонтных бригад и освоения, при работе на кустах скважин, оборудованных центробежными насосами?",
        answers: [
          "Электрокабели должны быть обесточены",
          "Электрокабели должны быть сняты с эстакад (стоек)",
          "Электрокабели должны быть закрыты кожухами, обеспечивающими сохранность изоляции и безопасность работников",
          "Необходимо провести все перечисленные мероприятия",
        ],
        correctAnswers: ["Необходимо провести все перечисленные мероприятия"],
      },
      {
        code: "20202044",
        text: "В каком исполнении должны быть агрегаты, применяемые во взрывопожароопасных зонах?",
        answers: [
          "Во взрывозащищенном",
          "В пылевлагонепроницаемом",
          "Во взрывонепроницаемом",
          "В брызгозащищенном",
        ],
        correctAnswers: ["Во взрывозащищенном"],
      },
      {
        code: "20202045",
        text: "Что не указывается на металлической табличке, укрепленной на мачте агрегата для ремонта скважин?",
        answers: [
          "Дата изготовления",
          "Заводской номер установки, номинальная грузоподъемность мачты",
          "Завод-изготовитель, дата проведения следующего технического освидетельствования агрегата",
          "Наименование предприятия - владельца агрегата",
        ],
        correctAnswers: ["Наименование предприятия - владельца агрегата"],
      },
      {
        code: "20202046",
        text: "Тросом какого диаметра должен быть застрахован ролик кабеля электрического центробежного насоса (далее - ЭЦН), подвешиваемого на мачте агрегата для ремонта скважин?",
        answers: ["3 - 5 мм", "5 - 7 мм", "8 - 10 мм", "10 - 12 мм"],
        correctAnswers: ["8 - 10 мм"],
      },
      {
        code: "20202047",
        text: "В соответствии с какими требованиями должна проводиться оценка технического состояния агрегатов для ремонта скважин?",
        answers: [
          "В соответствии с требованиями проектной организации",
          "В соответствии с требованиями завода-изготовителя",
          "В соответствии с требованиями нормативных технических документов",
          "В соответствии с требованиями территориального управления Ростехнадзора",
          "В соответствии с требованиями эксплуатирующей организации",
        ],
        correctAnswers: [
          "В соответствии с требованиями нормативных технических документов",
        ],
      },
      {
        code: "20202048",
        text: "Какие требования предъявляются к кронблоку агрегата для ремонта скважин?",
        answers: [
          "Кронблок должен иметь один ролик под канат диаметром 13 мм вспомогательной лебедки, два ролика под канат диаметром не менее 10 мм для подвески машинных ключей и приспособление для подвески гидравлического ключа",
          "Кронблок должен иметь два ролика под канат диаметром 10 мм вспомогательной лебедки, два ролика под канат диаметром 5 мм для подвески машинных ключей",
          "Кронблок должен иметь один ролик под канат диаметром 11 мм вспомогательной лебедки, один ролик под канат диаметром 7 мм для подвески машинных ключей",
          "Кронблок должен иметь приспособление для подвески гидравлического ключа",
        ],
        correctAnswers: [
          "Кронблок должен иметь один ролик под канат диаметром 13 мм вспомогательной лебедки, два ролика под канат диаметром не менее 10 мм для подвески машинных ключей и приспособление для подвески гидравлического ключа",
        ],
      },
      {
        code: "20202049",
        text: "Чем должны оснащаться передвижные насосные установки, предназначенные для работы на скважинах?",
        answers: [
          "Приборами, контролирующими основные параметры технологического процесса",
          "Запорными и предохранительными устройствами, приборами, контролирующими основные параметры технологического процесса, выведенные на пульт управления",
          "Запорными, предохранительными и регулирующими устройствами",
        ],
        correctAnswers: [
          "Запорными и предохранительными устройствами, приборами, контролирующими основные параметры технологического процесса, выведенные на пульт управления",
        ],
      },
      {
        code: "20202050",
        text: "Чем должны быть оборудованы и оснащены колтюбинговые установки с гибкими непрерывными трубами?",
        answers: [
          "Только комплектом устройств на устье скважины для спуска труб под давлением, рассчитанным на максимально возможное устьевое давление",
          "Только системой контроля и регистрации нагрузок, возникающих при спуско-подъемных операциях",
          "Только системой контроля и регистрации давления при прокачивании через гибкую трубу жидкостей в процессе технологических операций",
          "Всеми перечисленными устройствами",
        ],
        correctAnswers: ["Всеми перечисленными устройствами"],
      },
      {
        code: "20202051",
        text: "В соответствии с требованиями каких документов производится подготовка площадки, монтаж и эксплуатация колтюбинговых установок?",
        answers: [
          "В соответствии с техническими условиями",
          "В соответствии с техническими условиями и инструкцией по эксплуатации завода-изготовителя",
          "В соответствии с техническими условиями и инструкцией по производству работ, утвержденной Ростехнадзором",
          "В соответствии с инструкцией по производству работ, утвержденной Ростехнадзором",
        ],
        correctAnswers: [
          "В соответствии с техническими условиями и инструкцией по эксплуатации завода-изготовителя",
        ],
      },
      {
        code: "20202052",
        text: "Чем должны быть заглушены скважины, в продукции которых содержится сернистый водород, создающий угрозу сульфидно-коррозионного растрескивания металла обсадных труб, оборудования и лифтовых колонн?",
        answers: [
          "Жидкостью, содержащей нейтрализатор сернистого водорода",
          "Жидкостью, содержащей щелочи",
          "Жидкостью, содержащей кислоты",
          "Жидкостью, содержащей нефтепродукты",
        ],
        correctAnswers: [
          "Жидкостью, содержащей нейтрализатор сернистого водорода",
        ],
      },
      {
        code: "20202053",
        text: "С кем согласовывается схема установки и обвязки противовыбросового оборудования?",
        answers: [
          "С заводом-изготовителем и проектно-конструкторской организацией",
          "С проектной организацией и заказчиком",
          "С организацией, выполняющей ремонт скважины",
          "С заказчиком и ПАСФ",
        ],
        correctAnswers: ["С заказчиком и ПАСФ"],
      },
      {
        code: "20202054",
        text: "В каких случаях разрешается проводить текущий и капитальный ремонт скважин без их предварительного глушения?",
        answers: [
          "Ни в каких случаях",
          "В случаях, когда скважины расположены не на кустовых площадках",
          "В случаях, когда скважины оборудованы специальными устройствами, исключающими возможность ГНВП",
          "В случаях, когда скважины оборудованы клапаном-отсекателем и на месторождении с горно-геологическими условиями, исключающими возможность самопроизвольного поступления пластового флюида к устью скважины",
        ],
        correctAnswers: [
          "В случаях, когда скважины оборудованы клапаном-отсекателем и на месторождении с горно-геологическими условиями, исключающими возможность самопроизвольного поступления пластового флюида к устью скважины",
        ],
      },
      {
        code: "20202055",
        text: "При каких условиях запрещается проведение спуско-подъемных операций, а также ведение ремонтных работ, связанных с нагрузкой на мачту (вышку)?",
        answers: [
          "При условии, что нагрузка на мачту превышает предусмотренную в инструкции завода-изготовителя нагрузку",
          "При неполном составе вахты",
          "Без исправного индикатора веса",
        ],
        correctAnswers: ["Без исправного индикатора веса"],
      },
      {
        code: "20202056",
        text: "Каковы первоочередные действия бригады по ремонту скважин при обнаружении ГНВП?",
        answers: [
          "Покинуть рабочую площадку",
          "Подать сигнал тревоги и покинуть рабочую площадку",
          "Загерметизировать устье скважины и действовать в соответствии с ПЛА",
          "Действовать по инструкции, утвержденной пользователем недр",
        ],
        correctAnswers: [
          "Загерметизировать устье скважины и действовать в соответствии с ПЛА",
        ],
      },
      {
        code: "20202057",
        text: "При каких условиях проводится чистка песчаных пробок желонкой в скважинах, в продукции которых есть сероводород?",
        answers: [
          "При условии использования бригадой средств индивидуальной защиты",
          "При условии проведения чистки после замера концентрации сернистого водорода газоанализаторами на объекте",
          "Чистка не разрешается",
        ],
        correctAnswers: ["Чистка не разрешается"],
      },
      {
        code: "20202058",
        text: "Разрешается ли проводить забуривание бокового ствола в скважине при наличии перетоков в затрубном пространстве?",
        answers: [
          "Разрешается, если используется специальное оборудование",
          "Разрешается, если все перетоки, выявленные в ходе исследований, ликвидированы",
          "Разрешается, если составлен план работ, в котором указаны интервалы, где возможны перетоки",
        ],
        correctAnswers: [
          "Разрешается, если все перетоки, выявленные в ходе исследований, ликвидированы",
        ],
      },
      {
        code: "20202059",
        text: "Каковы размеры опасной зоны вокруг устья скважины, которая устанавливается на время прострелочных работ?",
        answers: ["5 м", "10 м", "7 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "20202060",
        text: "Какие дополнительные требования устанавливаются при передаче газлифтной скважины в ремонт?",
        answers: [
          "Устанавливается экранирующее устройство, обеспечивающее защиту устьевого оборудования",
          "Останавливаются соседние скважины, и составляется план работ",
          "Кроме плана работ по ремонту скважины, предоставляется план-схема газонефтепроводных коммуникаций и обвязки всех скважин куста с нанесенными размерами и порядком отключения газонагнетательных скважин",
        ],
        correctAnswers: [
          "Кроме плана работ по ремонту скважины, предоставляется план-схема газонефтепроводных коммуникаций и обвязки всех скважин куста с нанесенными размерами и порядком отключения газонагнетательных скважин",
        ],
      },
      {
        code: "20202061",
        text: "С кем должен быть согласован план работ по текущему ремонту скважин?",
        answers: [
          "С руководителем ремонтной организации",
          "С заказчиком",
          "С территориальным органом Ростехнадзора",
          "С противофонтанной службой",
        ],
        correctAnswers: ["С заказчиком"],
      },
      {
        code: "20202062",
        text: "Какая информация дополнительно включается в план при ведении работ, связанных с проводкой боковых стволов?",
        answers: [
          'Только интервал вырезки "окна" в эксплуатационной колонне',
          "Только компоновки колонны труб и низа бурильной колонны",
          "Только тип породоразрушающего инструмента и его привода",
          "Только режимы проходки бокового ствола и утилизации выбуренной породы",
          "Только крепление пробуренного ствола (спуск фильтра, технологическая оснастка, сочленение фильтра с эксплуатационной колонной и другие технологические операции)",
          "Все перечисленное дополнительно включается в план работ",
        ],
        correctAnswers: [
          "Все перечисленное дополнительно включается в план работ",
        ],
      },
      {
        code: "20202063",
        text: "В каких случаях ликвидированные скважины подлежат реконструкции?",
        answers: [
          "В случае, когда необходимо повысить добычу месторождения",
          "В случае, когда скважина была ликвидирована по техническим причинам",
          "В случае, когда комиссия, создаваемая организацией, эксплуатирующей ОПО, принимает решение по реконструкции скважин",
        ],
        correctAnswers: [
          "В случае, когда комиссия, создаваемая организацией, эксплуатирующей ОПО, принимает решение по реконструкции скважин",
        ],
      },
      {
        code: "20202064",
        text: "Какое из перечисленных требований предъявляется к оборудованию устья скважины, подлежащей реконструкции?",
        answers: [
          "Обвязка устья скважины, подлежащей реконструкции, должна позволять выполнение исследовательских работ",
          "Устье скважины, подлежащей реконструкции, должно быть оборудовано необходимыми контрольно-измерительными приборами",
          "Устье скважины вместе с ПВО должно быть опрессовано на давление, превышающее не менее чем на 10 % возможное давление, возникающее при ликвидации ГНВП и открытых фонтанов, а также при опробовании и эксплуатации скважины, но не выше допустимого давления опрессовки эксплуатационной колонны",
        ],
        correctAnswers: [
          "Устье скважины вместе с ПВО должно быть опрессовано на давление, превышающее не менее чем на 10 % возможное давление, возникающее при ликвидации ГНВП и открытых фонтанов, а также при опробовании и эксплуатации скважины, но не выше допустимого давления опрессовки эксплуатационной колонны",
        ],
      },
      {
        code: "20202065",
        text: "Какую информацию должен содержать план работ по проведению ремонта скважин?",
        answers: [
          "Только сведения о конструкции и состоянии скважины",
          "Только сведения о внутрискважинном оборудовании",
          "Только сведения о наличии давления в межколонных пространствах",
          "План должен содержать всю перечисленную информацию",
        ],
        correctAnswers: ["План должен содержать всю перечисленную информацию"],
      },
      {
        code: "20202066",
        text: "На каких скважинах разрешается проведение капитальных ремонтов без их предварительного глушения?",
        answers: [
          "На любых скважинах",
          "На скважинах, оборудованных клапанами-отсекателями",
          "На скважинах, оборудованных клапанами-отсекателями, и на месторождениях с горно-геологическими условиями, исключающими возможность самопроизвольного поступления пластового флюида к устью скважины",
          "На скважинах, расположенных на месторождениях с горно-геологическими условиями, исключающими возможность самопроизвольного поступления пластового флюида к устью скважины",
        ],
        correctAnswers: [
          "На скважинах, оборудованных клапанами-отсекателями, и на месторождениях с горно-геологическими условиями, исключающими возможность самопроизвольного поступления пластового флюида к устью скважины",
        ],
      },
      {
        code: "20202067",
        text: "Сколько часов следует выдерживать скважину, оборудованную забойным клапаном-отсекателем, в которой не предусмотрено проведение предварительного глушения, после остановки и стравливания давления до атмосферного?",
        answers: [
          "Не менее двух часов",
          "Не менее трех часов",
          "Не менее часа",
        ],
        correctAnswers: ["Не менее трех часов"],
      },
      {
        code: "20202068",
        text: "Разрешается ли проведение спуско-подъемных операций, а также ведение ремонтных работ, связанных с нагрузкой на мачту (вышку), независимо от глубины скважины без исправного индикатора веса?",
        answers: [
          "Запрещается",
          "Разрешается",
          "Разрешается только при согласовании с Ростехнадзором",
          "Разрешается только при письменном обосновании в проектной документации",
        ],
        correctAnswers: ["Запрещается"],
      },
      {
        code: "20202069",
        text: "Что может быть причиной возникновения и развития газонефтеводопроявлений из-за неуравновешенности пластового давления гидростатическим давлением столба раствора в стволе скважины?",
        answers: [
          "Только недостаточный оперативный контроль за текущими изменениями пластовых давлений вследствие законтурного заводнения и других факторов",
          "Только использование бурового раствора или жидкости глушения скважины с заниженной плотностью",
          "Только разгазирование раствора в призабойной части вследствие длительных простоев скважины без промывок",
          "Только снижение плотности бурового раствора при его химической обработке",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20202070",
        text: "Что может служить причиной возникновения открытых фонтанов?",
        answers: [
          "Только низкое качество монтажа противовыбросового оборудования, несоблюдение установленных условий его эксплуатации",
          "Только несоответствие размера плашек превентора диаметру спускаемых (поднимаемых) труб",
          "Только некачественное цементирование обсадных колонн",
          "Только отсутствие в компоновке бурильной колонны шарового крана или обратного клапана",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20202071",
        text: "Какие могут быть признаки возникновения и развития газонефтеводопроявлений?",
        answers: [
          "Снижение уровня столба раствора в скважине при технологических остановках или простоях",
          "Уменьшение объема (уровня) раствора в приемных емкостях при бурении или проведении спуско-подъемных операций",
          "Повышение плотности бурового раствора",
          "Уменьшение вращающего момента на роторе",
        ],
        correctAnswers: [
          "Снижение уровня столба раствора в скважине при технологических остановках или простоях",
        ],
      },
      {
        code: "20202072",
        text: "Сколько стадий (линий) защиты должен включать контроль за скважиной?",
        answers: ["3", "4", "2", "5"],
        correctAnswers: ["3"],
      },
      {
        code: "20202073",
        text: 'С какой периодичностью проводится переподготовка специалистов по курсу "Контроль скважины. Управление скважиной при газонефтеводопроявлении" в специализированных учебных центрах (комбинатах)?',
        answers: [
          "1 раз в 3 года",
          "1 раз в 2 года",
          "1 раз в год",
          "1 раз в 5 лет",
        ],
        correctAnswers: ["1 раз в 3 года"],
      },
      {
        code: "20202074",
        text: "Какие сведения не подлежат обязательному включению в план ликвидации аварий, составленный на каждую скважину с возможностью возникновения газонефтеводопроявлений или открытого фонтана?",
        answers: [
          "Организация материально-технического, инженерного и финансового обеспечения операций по ликвидации аварий на объекте",
          "Первоочередные действия производственного персонала при появлении признаков газонефтеводопроявлений, порядок проведения штатных операций по предупреждению развития аварии",
          "Режим работы вентиляции при возникновении газонефтеводопроявлений",
          "Виды возможных аварий на данном объекте, мероприятия по спасению людей, ответственных за выполнение этих мероприятий, и конкретных исполнителей, места нахождения средств для спасения людей и ликвидации аварий",
          "Распределение обязанностей между работниками, участвующими в ликвидации газонефтеводопроявлений",
        ],
        correctAnswers: [
          "Организация материально-технического, инженерного и финансового обеспечения операций по ликвидации аварий на объекте",
        ],
      },
      {
        code: "20202075",
        text: "Какие из перечисленных действий необходимо осуществлять для предупреждения газонефтеводопроявлений и обвалов стенок в процессе подъема колонны бурильных труб?",
        answers: [
          "Долив бурового раствора в скважину",
          "Поддержание в полной готовности оборудования, специальных приспособлений, инструментов и материалов на складах аварийного запаса предприятия",
          "Поддержание минимального гидростатического давления столба жидкости",
          "Применение прокачки порций вязкой или утяжеленной смывающей жидкости",
        ],
        correctAnswers: ["Долив бурового раствора в скважину"],
      },
      {
        code: "20202076",
        text: "Каким образом допускается заглушать скважину перед проведением текущих и капитальных ремонтов на месторождениях с горно-геологическими условиями, исключающими возможность самопроизвольного поступления пластового флюида к устью скважины?",
        answers: [
          "Выполнение ремонтов допускается без предварительного глушения скважин",
          "Жидкостью, содержащей нейтрализатор сероводорода",
          "Жидкостью повышенной вязкости",
          "Жидкостью необходимой плотности",
        ],
        correctAnswers: [
          "Выполнение ремонтов допускается без предварительного глушения скважин",
        ],
      },
      {
        code: "20202077",
        text: "До какого уровня должно быть снижено давление в трубном и затрубном пространствах скважины перед разборкой устьевой арматуры?",
        answers: [
          "До атмосферного",
          "До давления 0,1 кПа",
          "До давления 0,5 кПа",
          "До давления 0,2 кПа",
        ],
        correctAnswers: ["До атмосферного"],
      },
      {
        code: "20202078",
        text: "С кем согласовывается схема обвязки противовыбросового оборудования?",
        answers: [
          "С территориальными органами Ростехнадзора",
          "С профессиональной противофонтанной службой, обслуживающей данный объект",
          "С руководителем собственной профессиональной аварийно-спасательной службы (формирования)",
          "Со всеми перечисленными службами и лицами",
        ],
        correctAnswers: ["С территориальными органами Ростехнадзора"],
      },
      {
        code: "20202079",
        text: "Что должна обеспечить прочность технической колонны и установленного противовыбросового оборудования?",
        answers: [
          "Только противостояние воздействию давления гидростатического столба бурового раствора максимальной плотности",
          "Только противостояние воздействию максимальных сминающих нагрузок в случаях открытого фонтанирования или поглощения с падением уровня бурового раствора, а также в интервале пород, склонных к текучести",
          "Только герметизацию устья скважины в случаях газонефтеводопроявлений, выбросов и открытого фонтанирования с учетом дополнительного давления, необходимого для их ликвидации",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20202080",
        text: "Какова минимальная высота подъема тампонажного раствора над устройством ступенчатого цементирования или узлом соединения верхних секций обсадных колонн в нефтяных и газовых скважинах?",
        answers: [
          "50 и 250 м соответственно",
          "500 и 1000 м соответственно",
          "100 и 350 м соответственно",
          "150 и 500 м соответственно",
        ],
        correctAnswers: ["150 и 500 м соответственно"],
      },
      {
        code: "20202081",
        text: "Какое допускается отклонение плотности бурового раствора (освобожденного от газа), находящегося в циркуляции, от установленной проектом величины?",
        answers: [
          "Не более чем на 0,25 г/см³",
          "Не более чем на 0,02 г/см³",
          "Не более чем на 0,03 г/см³",
          "Не более чем на 0,05 г/см³",
        ],
        correctAnswers: ["Не более чем на 0,02 г/см³"],
      },
      {
        code: "20202082",
        text: "В каком месте устанавливается основной пульт для управления превенторами и гидравлическими задвижками?",
        answers: [
          "На расстоянии не менее 10 м от устья скважины в удобном и безопасном месте",
          "На задвижке перед дросселем",
          "Непосредственно возле пульта бурильщика",
          "В любом из перечисленных мест",
        ],
        correctAnswers: [
          "На расстоянии не менее 10 м от устья скважины в удобном и безопасном месте",
        ],
      },
      {
        code: "20202083",
        text: "Сколько должно быть шаровых кранов на буровой при вскрытии газовых пластов с аномально высоким давлением и сероводородсодержащих пластов?",
        answers: [
          "3",
          "2",
          "1",
          "2 шаровых крана, сдвоенных с обратными клапанами",
        ],
        correctAnswers: ["3"],
      },
      {
        code: "20202084",
        text: "Какие требования при строительстве скважин на континентальном шельфе с использованием плавучих буровых установок, полупогружных плавучих буровых установок, морских стационарных платформ и т. п. указаны неверно?",
        answers: [
          "Выкидные линии должны быть прочно прикреплены к элементам платформы, расстояние между опорами не должно превышать 10 м",
          "Линии глушения и дросселирования могут быть выполнены с поворотами",
          "Блок подводного противовыбросового оборудования должен быть испытан на рабочее давление",
          "Повороты линий глушения допускается выполнять из армированных резиновых шлангов высокого давления, изготовленных в соответствии с прочностной характеристикой превенторной установки, рассчитанной на максимальное давление, ожидаемое на устье",
        ],
        correctAnswers: [
          "Выкидные линии должны быть прочно прикреплены к элементам платформы, расстояние между опорами не должно превышать 10 м",
        ],
      },
      {
        code: "20202085",
        text: "При обеспечении какими условиями могут быть начаты работы по освоению и испытанию скважин?",
        answers: [
          "Высота подъема цементного раствора за эксплуатационной колонной и качество сформировавшейся крепи должны отвечать проекту и требованиям охраны недр",
          "Эксплуатационная колонна должна быть прошаблонирована, опрессована совместно с колонной головкой и превенторной установкой, должна оставаться герметичной при максимально ожидаемом давлении на устье скважины",
          "Устье с превенторной установкой, манифольдный блок и выкидные линии должны быть оборудованы и обвязаны в соответствии с утвержденной схемой",
          "Всеми перечисленными",
        ],
        correctAnswers: ["Всеми перечисленными"],
      },
      {
        code: "20202086",
        text: "Какой сигнал подается при появлении признаков поступления пластового флюида в скважину (перелив бурового раствора, увеличение его объема в емкостях, несоответствие расчетного и фактического объемов доливаемого (вытесняемого) раствора при СПО)?",
        answers: ['"Выброс"', '"Разгерметизация"', '"Опасно"', '"Внимание"'],
        correctAnswers: ['"Выброс"'],
      },
      {
        code: "20202087",
        text: "Какие скважины перед началом ремонтных работ подлежат обязательному глушению?",
        answers: [
          "Все скважины с пластовым давлением выше гидростатического и скважины, в которых (согласно выполненным расчетам) сохраняются условия фонтанирования или газонефтеводопроявлений при пластовых давлениях ниже гидростатического",
          "Скважины, оборудованные глубинными клапанами-отсекателями",
          "Скважины на месторождениях с горно-геологическими условиями, исключающими возможность самопроизвольного поступления пластового флюида к устью скважины",
          "Правилами не регламентируется",
        ],
        correctAnswers: [
          "Все скважины с пластовым давлением выше гидростатического и скважины, в которых (согласно выполненным расчетам) сохраняются условия фонтанирования или газонефтеводопроявлений при пластовых давлениях ниже гидростатического",
        ],
      },
      {
        code: "20202088",
        text: "Допускается ли проведение текущих ремонтов скважин без их предварительного глушения?",
        answers: [
          "Не допускается",
          "Допускается, если разработаны и согласованы с органами Ростехнадзора дополнительные меры безопасности",
          "Допускается на скважинах, оборудованных глубинными клапанами-отсекателями, и месторождениях с горно-геологическими условиями, исключающими возможность самопроизвольного поступления пластового флюида к устью скважины",
          "Допускается в любом случае",
        ],
        correctAnswers: [
          "Допускается на скважинах, оборудованных глубинными клапанами-отсекателями, и месторождениях с горно-геологическими условиями, исключающими возможность самопроизвольного поступления пластового флюида к устью скважины",
        ],
      },
      {
        code: "20202089",
        text: "Кто утверждает перечень скважин по месторождениям с горно-геологическими условиями, исключающими возможность самопроизвольного поступления пластового флюида к устью скважины?",
        answers: [
          "Противофонтанная служба",
          "Организация, эксплуатирующая ОПО (заказчик)",
          "Территориальный орган Ростехнадзора",
        ],
        correctAnswers: ["Организация, эксплуатирующая ОПО (заказчик)"],
      },
      {
        code: "20202090",
        text: "Какой мост должен быть установлен в обсадной колонне перед зарезкой нового ствола?",
        answers: ["Асбестовый", "Цементный", "Каменный", "Асфальтобетонный"],
        correctAnswers: ["Цементный"],
      },
      {
        code: "20202091",
        text: "На какое давление должен быть опрессован цементный мост совместно с обсадной колонной?",
        answers: [
          "На давление, превышающее не менее чем на 10 % давление при возникновении газонефтеводопроявлений или при эксплуатации",
          "На давление, превышающее не менее чем на 5 % давление при возникновении газонефтеводопроявлений или при эксплуатации",
          "На давление, превышающее не менее чем на 7 % давление при возникновении газонефтеводопроявлений или при эксплуатации",
          "На давление, превышающее не менее чем на 8 % давление при возникновении газонефтеводопроявлений или при эксплуатации",
        ],
        correctAnswers: [
          "На давление, превышающее не менее чем на 10 % давление при возникновении газонефтеводопроявлений или при эксплуатации",
        ],
      },
      {
        code: "20202092",
        text: "До какого уровня должно быть снижено давление в трубном и затрубном пространствах перед разборкой устьевой арматуры скважины?",
        answers: [
          "До номинального",
          "До минимального",
          "До атмосферного",
          "До пробного",
        ],
        correctAnswers: ["До атмосферного"],
      },
      {
        code: "20202093",
        text: "На какое давление должна быть опрессована скважина после установки противовыбросового оборудования?",
        answers: [
          "На максимально ожидаемое давление, но не выше давления опрессовки эксплуатационной колонны",
          "На давление, равное давлению опрессовки эксплуатационной колонны",
          "На максимально ожидаемое давление, но не ниже давления опрессовки эксплуатационной колонны",
          "На минимально ожидаемое давление, но не ниже давления опрессовки эксплуатационной колонны",
        ],
        correctAnswers: [
          "На максимально ожидаемое давление, но не выше давления опрессовки эксплуатационной колонны",
        ],
      },
      {
        code: "20202094",
        text: "Что должно быть установлено перед зарезкой бокового ствола в обсадной колонне?",
        answers: [
          "Цементный мост",
          "Заглушка",
          "Дополнительный (запасной) кабель",
          "Клапан-отсекатель",
        ],
        correctAnswers: ["Цементный мост"],
      },
      {
        code: "20202095",
        text: "Каким запасом жидкости должна быть обеспечена скважина для предотвращения и ликвидации возможных газонефтеводопроявлений?",
        answers: [
          "Непосредственно на скважине в блоке долива не менее 4,5 м³ и не менее двух объемов скважины, находящихся непосредственно на скважине или на узле приготовления раствора",
          "Не менее 1,5 объемов скважины, находящихся непосредственно на скважине или на узле приготовления раствора",
          "Непосредственно на скважине в блоке долива не менее 1,5 м³",
          "Непосредственно на скважине в блоке долива не менее 2,5 м³",
        ],
        correctAnswers: [
          "Непосредственно на скважине в блоке долива не менее 4,5 м³ и не менее двух объемов скважины, находящихся непосредственно на скважине или на узле приготовления раствора",
        ],
      },
      {
        code: "20202096",
        text: "Разрешается ли чистка песчаных пробок желонкой в фонтанных скважинах, в скважинах с возможными газонефтеводопроявлениями, а также в скважинах с наличием сернистого водорода?",
        answers: [
          "Разрешается",
          "Разрешается по согласованию с органами Ростехнадзора",
          "Допускается по письменному разрешению руководителя организации",
          "Запрещается",
        ],
        correctAnswers: ["Запрещается"],
      },
      {
        code: "20202097",
        text: "С какой периодичностью должны проводиться работы по ревизии клапана-отсекателя?",
        answers: [
          "Не реже 1 раза в 3 месяца",
          "Периодичность проведения должна определяться в соответствии с требованиями эксплуатационной документации",
          "Не реже 1 раза в год",
          "Не реже 1 раза в 18 месяцев",
        ],
        correctAnswers: [
          "Периодичность проведения должна определяться в соответствии с требованиями эксплуатационной документации",
        ],
      },
      {
        code: "20202098",
        text: "При каких погодных условиях запрещаются спуско-подъемные операции при ремонте скважин?",
        answers: [
          "При ветре со скоростью 15 м/с и более, во время ливня, сильного снегопада и тумана с видимостью менее 50 м",
          "При ветре со скоростью 10 м/с и более, во время ливня, сильного снегопада и тумана с видимостью менее 40 м",
          "При ветре со скоростью 5 м/с и более, во время ливня или сильного снегопада",
          "При ветре со скоростью 7 м/с и более, во время ливня, сильного снегопада и тумана с видимостью менее 30 м",
        ],
        correctAnswers: [
          "При ветре со скоростью 15 м/с и более, во время ливня, сильного снегопада и тумана с видимостью менее 50 м",
        ],
      },
      {
        code: "20202099",
        text: "Какой должна быть максимальная скорость подъема и спуска насосно-компрессорных труб с закрытым проходным сечением?",
        answers: ["0,35 м/с", "0,38 м/с", "0,28 м/с", "0,25 м/с"],
        correctAnswers: ["0,25 м/с"],
      },
      {
        code: "20202100",
        text: "Каким способом должна производиться намотка и размотка кабеля погружного центробежного электронасоса после его обесточивания?",
        answers: [
          "Ручным способом",
          "Полностью механизированным способом",
          "Комбинированным способом",
          "Любым удобным в данной ситуации способом",
        ],
        correctAnswers: ["Полностью механизированным способом"],
      },
      {
        code: "20202101",
        text: "Что необходимо сделать перед ремонтом скважины, оборудованной погружным центробежным электронасосом?",
        answers: [
          "Обесточить электрический кабель",
          "Открыть центральную задвижку и начать демонтаж адаптера",
          "Сделать натяжку насосно-компрессорной трубы и освободить устьевой пакер",
          "Провести проверку работоспособности оборудования",
        ],
        correctAnswers: ["Обесточить электрический кабель"],
      },
      {
        code: "20202102",
        text: "Разрешается ли нахождение людей между устьем скважины и барабаном с кабелем погружного насоса при спуске (подъеме) насоса?",
        answers: [
          "Разрешается, если это вызвано производственной необходимостью",
          "Запрещается",
          "Разрешается по согласованию с территориальным органом Ростехнадзора",
          "Разрешается в случае возникновения аварийной ситуации",
        ],
        correctAnswers: ["Запрещается"],
      },
      {
        code: "20202103",
        text: "Кто должен присутствовать при освоении скважины после завершения ремонтных работ?",
        answers: [
          "Представитель заказчика",
          "Представитель проектной организации",
          "Представитель территориального органа Ростехнадзора",
          "Представитель экспертной организации",
        ],
        correctAnswers: ["Представитель заказчика"],
      },
      {
        code: "20202104",
        text: 'Что необходимо предпринимать при подъеме лифтовых (бурильных) труб с сифоном (не снят клапан, "шламование" лифтовых колонн и другие возможные причины)?',
        answers: [
          "Производить постоянный долив скважины с поддержанием уровня жидкости на устье",
          "Делать технологические перерывы через каждые 10 труб",
          "Организовывать дежурство противофонтанной службы",
          "Прекращать подъем и принимать меры по ликвидации сифона",
        ],
        correctAnswers: [
          "Производить постоянный долив скважины с поддержанием уровня жидкости на устье",
        ],
      },
      {
        code: "20202105",
        text: "Каким должен быть минимальный радиус опасной зоны вокруг устья скважины на время прострелочных работ?",
        answers: ["5 м", "7 м", "10 м", "9 м", "8 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "20202106",
        text: "С кем согласовывается Положение по одновременному ведению работ на кусте?",
        answers: [
          "Согласовывается с Рострудом",
          "Согласовывается с Росприроднадзором",
          "Согласовывается с Ростехнадзором",
          "Согласовывается с ПАСФ",
        ],
        correctAnswers: ["Согласовывается с ПАСФ"],
      },
      {
        code: "20202107",
        text: "В каких случаях допускается ремонт скважин на кусте без остановки соседней скважины?",
        answers: [
          "Допускается при условии осуществления специальных мероприятий и использования технических средств, предусмотренных планом",
          "Ни в каких случаях",
          "Допускается по согласованию с органами Ростехнадзора",
          "Допускается по письменному разрешению главного инженера проекта",
        ],
        correctAnswers: [
          "Допускается при условии осуществления специальных мероприятий и использования технических средств, предусмотренных планом",
        ],
      },
      {
        code: "20202108",
        text: "Какое количество бригад может одновременно работать при ремонте скважин с одновременным бурением на кусте?",
        answers: [
          "1 бригада",
          "2 бригады",
          "Количество бригад не регламентируется",
          "3 бригады",
        ],
        correctAnswers: ["Количество бригад не регламентируется"],
      },
      {
        code: "20202109",
        text: "В каком случае при ремонте механизированных скважин на газлифтном кусте соседняя скважина должна быть остановлена, а при необходимости заглушена?",
        answers: [
          "Если расстояние между центрами устьев скважин 1,5 м и менее",
          "Если расстояние между центрами устьев скважин 3,0 м и более",
          "Если расстояние между центрами устьев скважин 2,0 м и менее",
          "Если расстояние между центрами устьев скважин 2,5 м и менее",
        ],
        correctAnswers: [
          "Если расстояние между центрами устьев скважин 1,5 м и менее",
        ],
      },
      {
        code: "20202110",
        text: "Какие требования предъявляются к конструкции экранирующего устройства?",
        answers: [
          "Должна исключать возможность образования непроветриваемых зон",
          "Должна обеспечивать свободный доступ к узлам управления арматуры скважины",
          "Должны выполняться все перечисленные требования",
        ],
        correctAnswers: ["Должны выполняться все перечисленные требования"],
      },
      {
        code: "20202111",
        text: "Какую информацию, кроме плана работ по ремонту скважины, необходимо предоставить при передаче газлифтной скважины в текущий, капитальный ремонт?",
        answers: [
          "Схему расположения подземных и надземных коммуникаций",
          "Положение по одновременному ведению работ на кусте скважины",
          "Схему установки и обвязки противовыбросового оборудования",
          "План-схему газонефтепроводных коммуникаций и обвязки всех скважин куста с нанесенными размерами и порядком отключения газонагнетательных скважин",
        ],
        correctAnswers: [
          "План-схему газонефтепроводных коммуникаций и обвязки всех скважин куста с нанесенными размерами и порядком отключения газонагнетательных скважин",
        ],
      },
      {
        code: "20202112",
        text: "Кто проводит отключение газопроводов и демонтаж газовой обвязки передаваемой в ремонт газлифтной скважины?",
        answers: [
          "Служба заказчика",
          "Специальная служба ремонтной организации",
          "Соответствующая служба пуско-наладочного управления",
          "Буровая бригада",
        ],
        correctAnswers: ["Служба заказчика"],
      },
      {
        code: "20202113",
        text: "Обязательно ли прекращение нагнетания газа в ремонтируемую скважину, а также в соседние скважины слева и справа перед расстановкой оборудования для подземного или капитального ремонта скважин?",
        answers: [
          "Обязательно",
          "Необязательно",
          "На усмотрение заказчика",
          "По решению подрядной организации",
        ],
        correctAnswers: ["Обязательно"],
      },
      {
        code: "20202114",
        text: "Кто выполняет все работы по остановке действующих скважин и их пуску в работу?",
        answers: [
          "Соответствующие службы заказчика",
          "Сервисная организация",
          "Специализированная служба подрядчика",
          "Противофонтанная служба",
        ],
        correctAnswers: ["Соответствующие службы заказчика"],
      },
      {
        code: "20202115",
        text: "В каком из перечисленных положений нарушены требования по безопасному проведению ремонтных работ?",
        answers: [
          "Перед началом ремонтных работ на рабочих местах должны быть вывешены плакаты и предупредительные надписи по безопасному ведению данных работ",
          "Ремонтные работы разрешается проводить после сдачи установки в ремонт по акту отдельного оборудования или технологических блоков",
          "К проведению ремонтных работ аппаратов, резервуаров и оборудования, где имеется или может возникнуть повышенная производственная опасность, разрешается приступать только после оформления наряда-допуска с указанием лиц, ответственных за подготовку и проведение ремонтных работ",
          "Для проведения ремонтных работ на высоте должны быть предусмотрены временные подмости и леса. Доски настилов должны неплотно прилегать одна к другой. Для устройства подмостей должны применяться доски толщиной не более 3 см",
        ],
        correctAnswers: [
          "Для проведения ремонтных работ на высоте должны быть предусмотрены временные подмости и леса. Доски настилов должны неплотно прилегать одна к другой. Для устройства подмостей должны применяться доски толщиной не более 3 см",
        ],
      },
      {
        code: "20202116",
        text: "Что из перечисленного разрешено использовать для промывки деталей оборудования?",
        answers: ["Лигроиновый растворитель", "Керосин", "Бензин", "Ацетон"],
        correctAnswers: ["Керосин"],
      },
      {
        code: "20202117",
        text: "Какой вид вентиляционной системы допустим во взрывоопасных помещениях во время проведения ремонта оборудования?",
        answers: [
          "Естественная вентиляция",
          "Механическая вентиляция",
          "Вытяжная вентиляция",
          "Приточно-вытяжная вентиляция",
        ],
        correctAnswers: ["Приточно-вытяжная вентиляция"],
      },
      {
        code: "20202118",
        text: "Каким образом должны быть устроены стеллажи передвижных или стационарных приемных мостков при ремонте скважин?",
        answers: [
          "Должны обеспечивать возможность укладки труб и штанг не более чем в 11 рядов",
          "Должны обеспечивать возможность укладки труб и штанг не более чем в 10 рядов",
          "Должны обеспечивать возможность укладки труб и штанг не более чем в 8 рядов",
          "Должны обеспечивать возможность укладки труб и штанг не более чем в 6 рядов",
        ],
        correctAnswers: [
          "Должны обеспечивать возможность укладки труб и штанг не более чем в 6 рядов",
        ],
      },
      {
        code: "20202119",
        text: "На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?",
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          "На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на выделенной и огражденной площадке на территории находящихся в эксплуатации опасных производственных объектов",
          "На ведение газоопасных, огневых и ремонтных работ на объектах электроэнергетики",
          "На ведение газоопасных, огневых и ремонтных работ на объектах атомной энергетики",
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "20202120",
        text: "Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года",
          "Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
      {
        code: "20202121",
        text: "Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?",
        answers: [
          "Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск, на необходимый для окончания работ срок",
          "Руководитель структурного подразделения не более чем на 1 рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск, не более чем на 1 дневную смену",
        ],
        correctAnswers: [
          "Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
        ],
      },
      {
        code: "20202122",
        text: "В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?",
        answers: [
          "Не менее 6 месяцев со дня закрытия наряда-допуска",
          "Не менее 1 месяца со дня закрытия наряда-допуска",
          "Не менее 3 месяцев со дня закрытия наряда-допуска",
        ],
        correctAnswers: ["Не менее 6 месяцев со дня закрытия наряда-допуска"],
      },
      {
        code: "20202123",
        text: "Каким документом определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?",
        answers: [
          "Организационно-распорядительными документами руководителя",
          "Технологическим регламентом",
          "Федеральными нормами и правилами в области промышленной безопасности",
          "Правилами пожарной безопасности",
        ],
        correctAnswers: [
          "Организационно-распорядительными документами руководителя",
        ],
      },
      {
        code: "20202124",
        text: "Чем должны владеть работники при проведении ремонтных работ на нефтяных и газовых скважинах?",
        answers: [
          "Приемами оказания первой помощи пострадавшим при несчастных случаях",
          "Приемами оказания врачебной помощи",
          "Знаниями основ технологии проведения буровых работ",
          "Знаниями об опасных свойствах углеводородов",
        ],
        correctAnswers: [
          "Приемами оказания первой помощи пострадавшим при несчастных случаях",
        ],
      },
      {
        code: "20202125",
        text: "Кто допускается круководству и ведению работ по ремонту и реконструкции скважин?",
        answers: [
          "Лица, имеющие опыт работ по ремонту и реконструкции скважин",
          "Лица, прошедшие проверку знаний в области промышленной безопасности, требования к профессиональному образованию не предъявляются",
          "Лица, имеющие профессиональное образование по специальности, требования к прохождению проверки знаний в области промышленной безопасности не предъявляются",
          "Лица, имеющие профессиональное образование, соответствующее занимаемой должности, и аттестованные в области промышленной безопасности",
        ],
        correctAnswers: [
          "Лица, имеющие профессиональное образование, соответствующее занимаемой должности, и аттестованные в области промышленной безопасности",
        ],
      },
      {
        code: "20202126",
        text: "С какой периодичностью работники, осуществляющие непосредственное руководство и выполнение работ по бурению, освоению, ремонту, реконструкции, консервации и ликвидации скважин, ведению геофизических и ПВР на скважинах, должны дополнительно проходить проверку знаний по контролю и управлению скважиной при ГНВП?",
        answers: [
          "Один раз в два года",
          "Один раз в три года",
          "Один раз в пять лет",
          "Один раз в четыре года",
        ],
        correctAnswers: ["Один раз в два года"],
      },
      {
        code: "20202127",
        text: "С чем из перечисленного должны быть ознакомлены работники, прибывшие на ОПО для работы?",
        answers: [
          "Только с перечнем опасных и вредных производственных факторов и признаками их проявления",
          "Только с алгоритмом действий при конкретных видах тревог",
          "Только с вопросами, входящими в объем вводного инструктажа",
          "Со всем перечисленным",
        ],
        correctAnswers: ["Со всем перечисленным"],
      },
      {
        code: "20202128",
        text: "Где фиксируются сведения о проведении вводного инструктажа?",
        answers: [
          "В актах",
          "В специальных журналах",
          "В протоколе о прохождении инструктажа",
          "В приказе по предприятию",
        ],
        correctAnswers: ["В специальных журналах"],
      },
      {
        code: "20202129",
        text: "Какие требования предъявляются к работникам комплексных бригад при необходимости выполнения работ, требующих совмещения профессий?",
        answers: [
          "Работники должны пройти дополнительное обучение по охране труда",
          "Работники должны получить допуск к ведению таких работ",
          "Работники должны пройти дополнительное обучение по экологической безопасности",
          "Работники должны пройти обучение и получить соответствующую квалификацию по видам выполняемых работ, а также иметь допуски к самостоятельной работе по совмещаемым профессиям",
        ],
        correctAnswers: [
          "Работники должны пройти обучение и получить соответствующую квалификацию по видам выполняемых работ, а также иметь допуски к самостоятельной работе по совмещаемым профессиям",
        ],
      },
    ],

    20203: [
      {
        code: "20203000",
        text: "Чем из перечисленного оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?",
        answers: [
          "Средствами взрывопредупреждения и мембранными предохранительными устройствами для защиты оборудования и трубопроводов от разрушений",
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
          "Противоаварийной автоматической защитой, обеспечивающей автоматическое регулирование процесса и безаварийную остановку производства по специальным программам, определяющим последовательность и время выполнения операций отключения при аварийных ситуациях",
        ],
        correctAnswers: [
          "Средствами контроля параметров, значения которых определяют взрывоопасность процесса",
        ],
      },
      {
        code: "20203001",
        text: "В каком исполнении должны быть агрегаты, применяемые во взрывопожароопасных зонах?",
        answers: [
          "Во влагонепроницаемом",
          "В брызгозащищенном",
          "Во взрывозащищенном",
          "В искрозащищенном",
        ],
        correctAnswers: ["Во взрывозащищенном"],
      },
      {
        code: "20203002",
        text: "Какие данные не указываются в рабочем проекте на бурение скважин?",
        answers: [
          "Типы нейтрализаторов, методы и технология нейтрализации сернистого водорода в буровом растворе, а также расход реагентов для этих целей на весь процесс бурения скважины",
          "Информация о мероприятиях по предупреждению и раннему обнаружению газонефтеводопроявлений",
          "Конструкции скважин, диаметры и глубины спуска эксплуатационных и лифтовых колонн",
          "Методы и периодичность проверки износа и контроля коррозионного состояния бурильных, ведущих, насосно-компрессорных труб и элементов трубных колонн",
          "Условия расчета обсадных и насосно-компрессорных (лифтовых) колонн исходя из порогового напряжения сталей труб, принимаемых не выше 0,75 от предела текучести",
        ],
        correctAnswers: [
          "Конструкции скважин, диаметры и глубины спуска эксплуатационных и лифтовых колонн",
        ],
      },
      {
        code: "20203003",
        text: "Какая санитарно-защитная зона устанавливается для установок сжижения природного газа, расположенных на газопроводах, месторождениях и газораспределительных станциях магистральных газопроводов, с объемом хранения сжиженного природного газа от 1000 м³, II класса опасности?",
        answers: ["300 м", "500 м", "100 м", "1000 м"],
        correctAnswers: ["500 м"],
      },
      {
        code: "20203004",
        text: "Каким документом обосновывается повышение категории взрывоопасности технологических блоков, определяемое количеством токсичных, высокотоксичных веществ, опасностью причинения ими вреда обслуживающему персоналу при вероятных сценариях развития аварийной ситуации?",
        answers: [
          "Проектной документацией",
          "Производственной инструкцией",
          "Техническим регламентом",
        ],
        correctAnswers: ["Проектной документацией"],
      },
      {
        code: "20203005",
        text: "Какова величина нормативной санитарно-защитной зоны для промышленных объектов по добыче природного газа с высоким содержанием сероводорода (более 1,5 - 3%) и меркаптанов?",
        answers: [
          "Не менее 8000 м",
          "Не менее 5000 м",
          "1000 м",
          "100 м",
          "300 м",
          "500 м",
        ],
        correctAnswers: ["Не менее 5000 м"],
      },
      {
        code: "20203006",
        text: "Какой рекомендуемый минимальный разрыв от трубопроводов для сжиженных углеводородных газов до городов и населенных пунктов при диаметре трубы от 150 до 300 мм?",
        answers: ["500 м", "350 м", "250 м", "150 м"],
        correctAnswers: ["250 м"],
      },
      {
        code: "20203007",
        text: "Какие из перечисленных мер предусматриваются проектной документацией или документацией на техническое перевооружение для обеспечения взрывобезопасности технологической системы при пуске в работу и остановке оборудования? Укажите все правильные ответы.",
        answers: [
          "Регламентация режимов и порядка пуска и остановки оборудования",
          "Контроль содержания горючих веществ в технологической системе после остановки технологического оборудования",
          "Поддержание избыточного давления инертного газа в системе во время остановки оборудования",
          "Разработка способов продувки оборудования инертными газами, исключающих образование застойных зон",
        ],
        correctAnswers: [
          "Регламентация режимов и порядка пуска и остановки оборудования",
          "Разработка способов продувки оборудования инертными газами, исключающих образование застойных зон",
        ],
      },
      {
        code: "20203008",
        text: "Какова величина нормативной санитарно-защитной зоны для промышленных объектов по добыче нефти при выбросе сероводорода до 0,5 т/сутки, а также с малым содержанием летучих углеводородов?",
        answers: ["100 м", "500 м", "300 м", "1000 м"],
        correctAnswers: ["300 м"],
      },
      {
        code: "20203009",
        text: "В каких документах приводятся способы и средства, исключающие выход параметров за установленные пределы?",
        answers: [
          "Только в исходных данных на проектирование",
          "Только в технологическом регламенте на производство продукции",
          "Только в проектной документации",
          "Во всех перечисленных",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "20203010",
        text: "Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?",
        answers: [
          "Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и установлено для блоков III категории",
          "Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности, а для блоков I и II категорий устанавливается проектной документацией",
          "Время срабатывания устанавливается для всех технологических блоков согласно их категориям взрывоопасности",
          "Время срабатывания определяется расчетом",
        ],
        correctAnswers: ["Время срабатывания определяется расчетом"],
      },
      {
        code: "20203011",
        text: "В каком исполнении должны быть технические устройства, применяемые во взрывопожароопасных зонах?",
        answers: [
          "Во взрывозащищенном",
          "В искрозащищенном",
          "В пылевлагонепроницаемом",
          "В пожаробезопасном",
        ],
        correctAnswers: ["Во взрывозащищенном"],
      },
      {
        code: "20203012",
        text: "Какое управление подачей инертных сред в технологические установки должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категории взрывоопасности?",
        answers: [
          "Дистанционное, неавтоматическое",
          "Ручное управление по месту",
          "Автоматическое",
        ],
        correctAnswers: ["Автоматическое"],
      },
      {
        code: "20203013",
        text: "В каком из перечисленных случаев категорию взрывоопасности блоков, определяемую расчетом, следует рассматривать на одну выше?",
        answers: [
          "Только если обращающиеся в технологическом блоке вещества относятся к высокотоксичным веществам",
          "Только если обращающиеся в технологическом блоке вещества относятся к токсичным веществам",
          "В любом из указанных случаев",
        ],
        correctAnswers: ["В любом из указанных случаев"],
      },
      {
        code: "20203014",
        text: "Какая санитарно-защитная зона устанавливается для объектов производств по переработке нефти, попутного нефтяного и природного газа I класса опасности?",
        answers: ["1000 м", "500 м", "300 м", "100 м"],
        correctAnswers: ["1000 м"],
      },
      {
        code: "20203015",
        text: "Оценку каких параметров необходимо произвести в проектной документации при разработке технологического процесса?",
        answers: [
          "Только эффективности технических средств противоаварийной защиты, направленных на обеспечение взрывобезопасности технологических блоков и в целом всей технологической схемы",
          "Только энергетического уровня каждого технологического блока",
          "В проектной документации производится оценка всех перечисленных параметров",
          "Только эффективности и надежности мер, обеспечивающих взрывобезопасность каждого технологического блока",
        ],
        correctAnswers: [
          "В проектной документации производится оценка всех перечисленных параметров",
        ],
      },
      {
        code: "20203016",
        text: "Каким требованиям должны соответствовать специальные системы аварийного освобождения технологических блоков от обращающихся продуктов? Выберите 2 правильных варианта ответа.",
        answers: [
          "Обеспечивать минимально возможное время освобождения",
          "Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий",
          "Быть мобильными, иметь небольшие габариты и вес",
          "Обеспечивать время перехода из режима ожидания в рабочее состояние не более 15 секунд",
        ],
        correctAnswers: [
          "Обеспечивать минимально возможное время освобождения",
          "Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий",
        ],
      },
      {
        code: "20203017",
        text: "На какой срок на стадии проектирования ОПО разрабатывается ТР?",
        answers: ["На 5 лет", "На 3 года", "На 2 года", "На 1 год"],
        correctAnswers: ["На 5 лет"],
      },
      {
        code: "20203018",
        text: "Какова величина нормативной санитарно-защитной зоны для промышленных объектов по добыче нефти при выбросе сероводорода от 0,5 до 1 т/сутки, а также с высоким содержанием летучих углеводородов?",
        answers: ["1000 м", "500 м", "300 м", "100 м"],
        correctAnswers: ["1000 м"],
      },
      {
        code: "20203019",
        text: "Что не должно быть отражено в проекте санитарно-защитной зоны?",
        answers: [
          "Мероприятия по защите населения от воздействия выбросов вредных химических примесей в атмосферный воздух и физического воздействия",
          "Мероприятия по ликвидации аварийных выбросов вредных примесей",
          "Размер и границы санитарно-защитной зоны",
          "Функциональное зонирование территории санитарно-защитной зоны и режим ее использования",
        ],
        correctAnswers: [
          "Мероприятия по ликвидации аварийных выбросов вредных примесей",
        ],
      },
      {
        code: "20203020",
        text: "Чем должны оснащаться технологические системы для обеспечения безопасности ведения технологических процессов?",
        answers: [
          "Только средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний",
          "Только средствами автоматического регулирования",
          "Только средствами противоаварийной защиты",
          "Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ",
        ],
        correctAnswers: [
          "Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ",
        ],
      },
      {
        code: "20203021",
        text: "На какие опасные производственные объекты не распространяются Правила промышленной безопасности складов нефти и нефтепродуктов?",
        answers: [
          "На наливные станции магистральных нефтепроводов и нефтепродуктопроводов",
          "На товарно-сырьевые парки центральных пунктов сбора нефтяных месторождений, нефтеперерабатывающих производств",
          "На склады горюче-смазочных материалов, входящие в состав промышленных предприятий и организаций, в том числе тепло- и электроэнергетики",
          "На объекты хранения нефтепродуктов с упругостью паров выше 93,3 кПа",
        ],
        correctAnswers: [
          "На объекты хранения нефтепродуктов с упругостью паров выше 93,3 кПа",
        ],
      },
      {
        code: "20203022",
        text: "Какая должна быть максимальная температура производственных сточных вод при сбросе в канализацию опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: ["40 °C", "60 °C", "34 °C"],
        correctAnswers: ["40 °C"],
      },
      {
        code: "20203023",
        text: "Какие насосные станции производственной канализации должны оснащаться датчиками загазованности с выводом сигнала на пульт помещения управления?",
        answers: [
          "Заглубленные более чем на 0,5 м",
          "Заглубленные не менее чем на 1 м",
          "В соответствии с проектной документацией",
          "Заглубленные не менее чем на 0,8 м",
        ],
        correctAnswers: ["Заглубленные более чем на 0,5 м"],
      },
      {
        code: "20203024",
        text: "Какая высота столба жидкости в гидравлическом затворе, установленном на колодце, за пределами обвалования?",
        answers: [
          "Не менее 0,25 м",
          "Не менее 0,75 м",
          "Не менее 0,5 м",
          "Не менее 1 м",
        ],
        correctAnswers: ["Не менее 0,25 м"],
      },
      {
        code: "20203025",
        text: "Что из перечисленного не требует защиты от прямых ударов молнии?",
        answers: [
          "Пространство над срезом горловины цистерн с легковоспламеняющимися жидкостями при открытом наливе продукта на наливной эстакаде, ограниченное цилиндром высотой 2,5 м и радиусом 5 м",
          "Дыхательная арматура резервуаров с легковоспламеняющимися жидкостями и пространство над ней",
          "Насосные установки (станции) нефти и нефтепродуктов и пространство над ними",
        ],
        correctAnswers: [
          "Насосные установки (станции) нефти и нефтепродуктов и пространство над ними",
        ],
      },
      {
        code: "20203026",
        text: "Какой документацией обосновывается максимальная безопасная скорость налива нефти и нефтепродуктов на железнодорожных сливоналивных эстакадах?",
        answers: [
          "Эксплуатационной документацией",
          "Инструкциями по охране труда при выполнении технологических процессов",
          "Производственными инструкциями",
          "Проектной документацией",
        ],
        correctAnswers: ["Проектной документацией"],
      },
      {
        code: "20203027",
        text: "При каком минимальном превышении концентрации паров нефтепродуктов на площадках сливоналивных станций и пунктов слива-налива должны быть установлены блокировки по прекращению операций слива-налива и сигнализация, оповещающая о запрете запуска двигателей автомобилей?",
        answers: [
          "При превышении концентрации паров более 50%",
          "При превышении концентрации паров более 20%",
          "При превышении концентрации паров более 30%",
        ],
        correctAnswers: ["При превышении концентрации паров более 20%"],
      },
      {
        code: "20203028",
        text: "Что из перечисленного должно отводиться в производственную канализацию на объектах складов нефти и нефтепродуктов?",
        answers: [
          "Пожароопасные нефтепродукты",
          "Сточные воды от зачистки и пропарки резервуаров для нефти и нефтепродуктов",
          "Нефтешламы",
          "Подтоварные воды от отстоя нефти и нефтепродуктов",
        ],
        correctAnswers: ["Подтоварные воды от отстоя нефти и нефтепродуктов"],
      },
      {
        code: "20203029",
        text: "Какое утверждение, относящееся к резервуарным паркам для нефти и нефтепродуктов, указано неверно?",
        answers: [
          "Запорные устройства, установленные непосредственно у резервуара, должны дублироваться установкой запорных устройств на технологических трубопроводах вне обвалования",
          "Применение арматуры с дистанционным управлением (электро-, пневмо- или гидроприводной) определяется условиями технологического процесса перекачки с обоснованием в проектной документации",
          "Транзитная прокладка электрокабельных линий производится внутри обвалования резервуаров",
          "Для вновь проектируемых резервуаров управление приводами запорной арматуры должно быть дистанционным из операторной и по месту ее установки",
        ],
        correctAnswers: [
          "Транзитная прокладка электрокабельных линий производится внутри обвалования резервуаров",
        ],
      },
      {
        code: "20203030",
        text: "Какие переходные сопротивления должны быть в соединениях элементов трубопроводов или других протяженных металлических предметов для защиты зданий (сооружений) и электрооборудования от вторичных проявлений молнии?",
        answers: [
          "Не более 0,5 Ом на каждый контакт",
          "Не более 0,03 Ом на каждый контакт",
          "Не более 0,05 Ом на каждый контакт",
          "Не более 0,1 Ом на каждый контакт",
        ],
        correctAnswers: ["Не более 0,03 Ом на каждый контакт"],
      },
      {
        code: "20203031",
        text: "Какое из нижеуказанных свойств, показателей или условий не влияет на выбор типа резервуара для хранения нефти и нефтепродуктов?",
        answers: [
          "Годовое число циклов заполнений-опорожнений резервуара",
          "Показатели взрывоопасности хранимых нефтепродуктов",
          "Физико-химические свойства хранимых нефтепродуктов",
        ],
        correctAnswers: [
          "Годовое число циклов заполнений-опорожнений резервуара",
        ],
      },
      {
        code: "20203032",
        text: "Какие из перечисленных конструкций подлежат заземлению для защиты от проявлений статического электричества?",
        answers: [
          "Только железнодорожные рельсы сливоналивных участков, электрически соединенные между собой, а также металлические конструкции сливоналивных эстакад с двух сторон по длине",
          "Только наземные трубопроводы через каждые 200 м и дополнительно на каждом ответвлении с присоединением каждого ответвления к заземлителю",
          "Только металлические воздуховоды и кожухи изоляции вентиляционных систем во взрывоопасных помещениях через каждые 40 - 50 м",
          "Все перечисленные конструкции",
        ],
        correctAnswers: ["Все перечисленные конструкции"],
      },
      {
        code: "20203033",
        text: "При достижении какой концентрации горючих газов и паров нефтепродуктов предусматривается автоматическое включение аварийной вентиляции?",
        answers: [
          "40% объемных от НКПРП",
          "30% объемных от НКПРП",
          "50% объемных от НКПРП",
          "20% объемных от НКПРП",
        ],
        correctAnswers: ["20% объемных от НКПРП"],
      },
      {
        code: "20203034",
        text: "Какие нефтепродукты допускается сливать через герметичные верхние сливные устройства?",
        answers: [
          "Сырье нефтяное для производства технического углерода",
          "Авиационное топливо",
          "Светлые нефтепродукты",
          "Дистиллятные масла",
        ],
        correctAnswers: ["Светлые нефтепродукты"],
      },
      {
        code: "20203035",
        text: "В каком случае допускается ручной отбор проб светлых нефтепродуктов через люк на крыше резервуара?",
        answers: [
          "Если необходимо взять пробы для определения качества нефтепродуктов",
          "Если работа проводится в светлое время суток",
          "Если работа проводится аттестованным персоналом",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20203036",
        text: "Бортиками какой высоты должно ограждаться по периметру покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков?",
        answers: [
          "Не менее 0,2 м",
          "Не менее 0,4 м",
          "Не менее 0,5 м",
          "Не менее 0,6 м",
        ],
        correctAnswers: ["Не менее 0,2 м"],
      },
      {
        code: "20203037",
        text: "Какая допускается скорость понтона (плавающей крыши) резервуаров при сдвиге?",
        answers: [
          "Не более 1,5 м/ч",
          "Не более 2,5 м/ч",
          "Не более 3,5 м/ч",
          "Не более 2 м/ч",
        ],
        correctAnswers: ["Не более 2,5 м/ч"],
      },
      {
        code: "20203038",
        text: "Какие из мер, направленных на недопущение гидравлического удара при перекачке нефтепродуктов из берегового трубопровода в танкер, указаны неверно?",
        answers: [
          "Регулирование линейной скорости потока",
          "Уменьшение времени закрытия регулирующего клапана",
          "Использование систем сброса давления в уравнительные резервуары для снижения воздействия возможного гидравлического удара",
        ],
        correctAnswers: ["Уменьшение времени закрытия регулирующего клапана"],
      },
      {
        code: "20203039",
        text: "Какое из перечисленных требований допускается к автомобильным сливоналивным станциям?",
        answers: [
          "Расстояние от конца наливной трубы до нижней образующей цистерны не должно превышать 300 мм",
          "Применение гибких шлангов для налива при обосновании в проектной документации",
          "Эксплуатация сливоналивных устройств при обнаружении нарушения целостности единого контура заземления не должна превышать 30 минут",
          "Нахождение водителей автомобильных цистерн, выполняющих операции слива-налива нефтепродуктов, в синтетической одежде",
        ],
        correctAnswers: [
          "Применение гибких шлангов для налива при обосновании в проектной документации",
        ],
      },
      {
        code: "20203040",
        text: "В соответствии с какими нормами и требованиями должны быть обустроены сливоналивные причалы для осуществления операций с нефтью и нефтепродуктами?",
        answers: [
          "В соответствии с нормами морского регистра и требованиями законодательства по перевозке опасных грузов",
          "В соответствии с нормами технологического проектирования морских и речных портов и требованиями законодательства в области промышленной безопасности",
          "В соответствии с нормами и требованиями Руководства по безопасности для нефтебаз и складов нефтепродуктов",
        ],
        correctAnswers: [
          "В соответствии с нормами технологического проектирования морских и речных портов и требованиями законодательства в области промышленной безопасности",
        ],
      },
      {
        code: "20203041",
        text: "До какого момента при заполнении порожнего резервуара должны подаваться нефть или нефтепродукты со скоростью не более 1 м/с?",
        answers: [
          "До момента срабатывания системы блокирования подачи жидкости в резервуар",
          "До момента заполнения резервуара до верхнего проектного уровня",
          "До момента заполнения приемного патрубка или до всплытия понтона (плавающей крыши)",
        ],
        correctAnswers: [
          "До момента заполнения приемного патрубка или до всплытия понтона (плавающей крыши)",
        ],
      },
      {
        code: "20203042",
        text: "Для налива каких ЛВЖ сливоналивные устройства должны снабжаться устройствами отвода паров?",
        answers: [
          "С упругостью паров от 56,65 кПа",
          "С упругостью паров от 66,65 кПа",
          "С упругостью паров от 60,65 кПа",
          "С упругостью паров от 61,65 кПа",
        ],
        correctAnswers: ["С упругостью паров от 66,65 кПа"],
      },
      {
        code: "20203043",
        text: "Какие минимальные уклоны для стока жидкости к приемным устройствам (лоткам, колодцам, приямкам) должно иметь покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков?",
        answers: ["1%", "2%", "3%", "5%"],
        correctAnswers: ["2%"],
      },
      {
        code: "20203044",
        text: "Какое значение не должна превышать скорость движения понтона (плавающей крыши) для резервуаров емкостью до 30 000 м³?",
        answers: ["6 м/ч", "8 м/ч", "4 м/ч", "2 м/ч"],
        correctAnswers: ["6 м/ч"],
      },
      {
        code: "20203045",
        text: "Какая из перечисленных систем канализации не предусматривается на площадках опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: ["Аварийная", "Производственная", "Дождевая"],
        correctAnswers: ["Аварийная"],
      },
      {
        code: "20203046",
        text: "Какие требования предъявляются к температуре подогрева мазута в резервуарах?",
        answers: [
          "Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 120 °C",
          "Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 20 °C и не должна превышать 80 °C",
          "Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 90 °C",
        ],
        correctAnswers: [
          "Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 90 °C",
        ],
      },
      {
        code: "20203047",
        text: "В каком случае допускается установка оборудования приточных систем вентиляции обычного исполнения в помещениях взрывоопасных категорий опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: [
          "Допускается, если проведено согласование с федеральным органом исполнительной власти, уполномоченным в области промышленной безопасности",
          "Данное требование не регламентируется нормативными документами",
          "Допускается, если на воздуховодах при выходе из венткамеры предусмотрены взрывозащищенные обратные клапаны",
          "Допускается, если работы выполняются под руководством технического руководителя организации, эксплуатирующей опасные производственные объекты",
        ],
        correctAnswers: [
          "Допускается, если на воздуховодах при выходе из венткамеры предусмотрены взрывозащищенные обратные клапаны",
        ],
      },
      {
        code: "20203048",
        text: "В каком из перечисленных случаев должны срабатывать быстродействующие отключающие системы (автоматические устройства) на сливоналивных эстакадах?",
        answers: [
          "Только если нарушается целостность цепи заземления железнодорожной цистерны",
          "Только если выдается заданная норма нефти и нефтепродуктов",
          "Только если достигается предельный уровень заполнения железнодорожной цистерны",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20203049",
        text: "В каком случае автоматические предохранительные клапаны должны быть установлены на причале, чтобы исключить возможное повышение давления потоком нефти и нефтепродукта?",
        answers: [
          "При расположении береговых насосов более чем в 50 м от стендера",
          "Если это предусмотрено проектной документацией",
          "В любом случае",
          "При расположении береговых насосов более чем в 100 м от стендера",
        ],
        correctAnswers: [
          "При расположении береговых насосов более чем в 100 м от стендера",
        ],
      },
      {
        code: "20203050",
        text: "В каком случае автоматические предохранительные клапаны должны быть установлены на причале, чтобы исключить возможное повышение давления потоком нефти и нефтепродукта?",
        answers: [
          "Автоматические предохранительные клапаны устанавливаются на причале в любом случае",
          "При расположении береговых насосов более чем в 100 м от стендера",
          "При расположении береговых насосов более чем в 50 м от стендера",
          "Если это предусмотрено проектной документацией",
        ],
        correctAnswers: [
          "При расположении береговых насосов более чем в 100 м от стендера",
        ],
      },
      {
        code: "20203051",
        text: "На каком расстоянии от нефтеловушек необходимо устраивать на канализационной сети колодцы с гидравлическим затвором?",
        answers: [
          "Не менее 10 м до нефтеловушек и не менее 5 м после них",
          "Не менее 15 м после нефтеловушек",
          "Не менее 10 м до и после нефтеловушек",
        ],
        correctAnswers: ["Не менее 10 м до и после нефтеловушек"],
      },
      {
        code: "20203052",
        text: "В какой документации устанавливаются места установки приборов, их количество и параметры контроля процесса перекачки нефти и нефтепродукта по трубопроводу у насосной станции и стендеров?",
        answers: [
          "В эксплуатационной документации",
          "В технологических картах",
          "В проектной документации",
          "В производственных инструкциях",
        ],
        correctAnswers: ["В проектной документации"],
      },
      {
        code: "20203053",
        text: "Каким должно быть покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков, а также для смыва пролитых нефтепродуктов?",
        answers: ["Деревянным", "Резиновым", "Асфальтовым", "Бетонным"],
        correctAnswers: ["Бетонным"],
      },
      {
        code: "20203054",
        text: "Что из перечисленного допускается в отношении резервуарных парков?",
        answers: [
          "Хранение нефти и нефтепродуктов в заглубленных и подземных резервуарах для вновь строящихся и реконструируемых опасных производственных объектов складов нефти и нефтепродуктов",
          "Наличие подтоварной воды меньше минимального уровня, обеспечиваемого устройством для дренажа воды, при хранении нефтепродуктов в резервуарах",
          "Ручной отбор проб светлых нефтепродуктов через люк на крыше резервуара",
          "Все перечисленное не допускается",
        ],
        correctAnswers: ["Все перечисленное не допускается"],
      },
      {
        code: "20203055",
        text: "При каком уровне загазованности воздушной среды должен автоматически прекращаться слив и налив нефти и светлых нефтепродуктов на сливоналивных железнодорожных эстакадах?",
        answers: [
          "20% объемных от нижнего концентрационного предела распространения пламени",
          "30% объемных от нижнего концентрационного предела распространения пламени",
          "50% объемных от нижнего концентрационного предела распространения пламени",
        ],
        correctAnswers: [
          "50% объемных от нижнего концентрационного предела распространения пламени",
        ],
      },
      {
        code: "20203056",
        text: "Где допускается осуществлять затаривание и расфасовку нефтепродуктов (масел, смазок) в бочки и мелкую тару?",
        answers: [
          "В складских помещениях для хранения нефтепродуктов в таре",
          "На производственных открытых площадках",
          "Не регламентируется",
          "В пунктах разлива и фасовки нефтепродуктов",
        ],
        correctAnswers: ["В пунктах разлива и фасовки нефтепродуктов"],
      },
      {
        code: "20203057",
        text: "Каким способом срабатывает система аварийного разъединения стендеров для предотвращения пролива нефтепродуктов?",
        answers: [
          "Только вручную посредством управления гидравлическими клапанами в случае прекращения подачи электроэнергии на терминал",
          "Только автоматически, когда стендер достигает обусловленного граничного положения",
          "Только дистанционно с учетом нажатия кнопки на центральном пульте управления",
          "Всеми перечисленными способами",
        ],
        correctAnswers: ["Всеми перечисленными способами"],
      },
      {
        code: "20203058",
        text: "Какой длины должно быть наливное устройство во избежание налива нефти и нефтепродуктов свободно падающей струей?",
        answers: [
          "Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 500 мм",
          "Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 300 мм",
          "Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 200 мм",
        ],
        correctAnswers: [
          "Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 200 мм",
        ],
      },
      {
        code: "20203059",
        text: "При каких условиях допускается налив нефтепродуктов в автомобильные цистерны с применением гибких шлангов?",
        answers: [
          "При обосновании в проектной документации",
          "При проведении работ под руководством технического руководителя организации - собственника автомобильных цистерн",
          "При разработке обоснования в плане компенсирующих мероприятий",
        ],
        correctAnswers: ["При обосновании в проектной документации"],
      },
      {
        code: "20203060",
        text: "Из какого материала следует выполнять соединение между собой неподвижных металлических конструкций (резервуары, трубопроводы), а также присоединение их к заземлителям? Укажите все правильные ответы.",
        answers: [
          "Из полосовой стали сечением не менее 48 мм²",
          "Из стальной ленты сечением не менее 24 мм²",
          "Из стальной проволоки диаметром не менее 5 мм",
          "Из круглой стали диаметром более 6 мм",
        ],
        correctAnswers: [
          "Из полосовой стали сечением не менее 48 мм²",
          "Из круглой стали диаметром более 6 мм",
        ],
      },
      {
        code: "20203061",
        text: "На каком расстоянии от сплошной (без проемов) стены помещения пунктов разлива и фасовки размещаются раздаточные резервуары с нефтепродуктами единичной вместимостью до 25 м³ включительно при общей вместимости до 200 м³ в зависимости от вида отпускаемых нефтепродуктов?",
        answers: ["1 м", "1,5 м", "3 м", "2 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "20203062",
        text: "В каком случае не допускается применение электроподогрева при проведении сливоналивных операций нефтепродуктов?",
        answers: [
          "При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 81 °C",
          "При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 61 °C",
          "При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 85 °C",
          "При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 71 °C",
        ],
        correctAnswers: [
          "При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 61 °C",
        ],
      },
      {
        code: "20203063",
        text: "Какое из перечисленных требований к молниезащите и защите от статического электричества указано верно?",
        answers: [
          "Измерение сопротивления любой точки внутренней и внешней поверхности металлического оборудования относительно магистрали заземления должно проводиться при относительной влажности окружающего воздуха не выше 50%",
          "При измерениях электрод должен располагаться в точках поверхности оборудования, наименее удаленных от точек контакта поверхности с заземленными металлическими элементами, деталями и арматурой",
          "Площадь соприкосновения поверхности оборудования с измерительным электродом должна быть не более 30 см²",
          "Заземленное металлическое оборудование, покрытое лакокрасочными материалами, считается электростатически заземленным, если сопротивление любой точки его внутренней и внешней поверхности относительно магистрали заземления не превышает 10 Ом",
        ],
        correctAnswers: [
          "Заземленное металлическое оборудование, покрытое лакокрасочными материалами, считается электростатически заземленным, если сопротивление любой точки его внутренней и внешней поверхности относительно магистрали заземления не превышает 10 Ом",
        ],
      },
      {
        code: "20203064",
        text: "Каким образом должно обеспечиваться ограничение максимальной скорости налива нефти и нефтепродуктов до безопасных пределов на железнодорожных сливоналивных эстакадах?",
        answers: [
          "Перепуском части продукта во всасывающий трубопровод насоса",
          "Установкой частотно-регулируемого электропривода насоса",
          "Посредством запорно-регулирующей арматуры на линии подачи нефти или нефтепродукта к железнодорожной эстакаде",
          "Любым из перечисленных способом",
        ],
        correctAnswers: ["Любым из перечисленных способом"],
      },
      {
        code: "20203065",
        text: "В каких документах должны быть обоснованы технические решения по герметизации налива нефтепродуктов в железнодорожные цистерны?",
        answers: [
          "В проектной документации",
          "В эксплуатационной документации",
          "В производственных инструкциях",
          "В нормативных правовых актах в области промышленной безопасности, охраны труда и экологии",
        ],
        correctAnswers: ["В проектной документации"],
      },
      {
        code: "20203066",
        text: "Что не должно учитываться при выборе шлангов для обеспечения безопасности грузовых (сливоналивных) операций?",
        answers: [
          "Размер судового трубопровода",
          "Требуемая скорость перемещения нефтепродукта",
          "Физико-химические свойства перемещаемой среды",
          "Параметры давления и температуры перемещаемой среды",
        ],
        correctAnswers: ["Требуемая скорость перемещения нефтепродукта"],
      },
      {
        code: "20203067",
        text: "На каком минимальном расстоянии от резервуаров устанавливаются прожекторные мачты вне обвалования или ограждающих стен?",
        answers: ["10 м", "15 м", "12 м", "8 м", "5 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "20203068",
        text: "На каком минимальном расстоянии необходимо находиться от молниеотводов во время грозы?",
        answers: ["3 м", "4 м", "5 м", "10 м"],
        correctAnswers: ["4 м"],
      },
      {
        code: "20203069",
        text: "На какую минимальную глубину от уровня верхней кромки подогревателя должны погружаться в нефтепродукт переносные паровые змеевики и переносные электрические подогреватели?",
        answers: ["300 мм", "500 мм", "600 мм", "800 мм"],
        correctAnswers: ["500 мм"],
      },
      {
        code: "20203070",
        text: "Какое из перечисленных требований к резервуарам для хранения нефти и нефтепродуктов указано верно?",
        answers: [
          'При обнаружении нарушения герметичности стального резервуара с защитной стенкой (типа "стакан в стакане") необходимо принять меры по незамедлительному устранению причины утечки продукта',
          "При оснащении резервуарных парков газоуравнительной системой допускается с помощью нее объединять резервуары с авиационными и автомобильными бензинами",
          "Производительность наполнения (опорожнения) резервуаров может превышать суммарную пропускную способность установленных на резервуаре дыхательных и предохранительных устройств, если это предусмотрено в проектной документации",
          "Полный комплект устанавливаемых на резервуаре устройств и оборудования и схема их расположения обосновываются в проектной документации",
        ],
        correctAnswers: [
          "Полный комплект устанавливаемых на резервуаре устройств и оборудования и схема их расположения обосновываются в проектной документации",
        ],
      },
      {
        code: "20203071",
        text: "Что должно предусматриваться в проектной документации на консервацию или ликвидацию опасного производственного объекта?",
        answers: [
          "Только мероприятия по локализации и ликвидации последствий аварий как в процессе консервации или ликвидации объекта, так и по завершении его консервации",
          "Только мероприятия по предупреждению аварий",
          "Должны предусматриваться все перечисленные условия",
        ],
        correctAnswers: ["Должны предусматриваться все перечисленные условия"],
      },
      {
        code: "20203072",
        text: "Допускается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений размещение инженерных сетей с токсичными жидкостями и газами под зданиями и сооружениями?",
        answers: [
          "Не регламентируется нормативными документами",
          "Допускается при условии прокладки их в герметичном стальном кожухе, длина которого превышает габариты помещения или сооружения не менее чем на 7 м",
          "Не допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "20203073",
        text: "Через какое расстояние эстакады для трубопроводов при обустройстве нефтяных, газовых и газоконденсатных месторождений должны быть электрически соединены с проходящими по ним трубопроводами и заземлены?",
        answers: [
          "Через 450 - 500 м, а также в начале и в конце",
          "Через 300 - 400 м, а также в начале и в конце",
          "Через 200 - 300 м, а также в начале и в конце",
          "Через 150 - 200 м, а также в начале и в конце",
        ],
        correctAnswers: ["Через 200 - 300 м, а также в начале и в конце"],
      },
      {
        code: "20203074",
        text: 'Допускается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений хранение токсичных жидкостей в резервуарах с "атмосферным дыханием"?',
        answers: [
          "Допускается при условии оборудования резервуаров сигнализатором предельного верхнего уровня заполнения, сблокированным с насосным оборудованием, и системой аварийного слива избытка жидкости в дренажную систему",
          "Допускается",
          "Не допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "20203075",
        text: "Разрешается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений последовательное соединение заземляющим проводником нескольких аппаратов или резервуаров?",
        answers: [
          "Допускается при условии, что общее сопротивление заземляющего проводника не более 20 Ом",
          "Допускается только для аппаратов или резервуаров, установленных в одном обваловании",
          "Допускается",
          "Не допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "20203076",
        text: "Допускается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений пересечение трубопроводов с токсичными жидкостями и газами с железнодорожными подъездными путями?",
        answers: [
          "Допускается при условии их прокладки по глухой стене не ниже II степени огнестойкости",
          "Допускается",
          "Не допускается, за исключением продуктопроводов к двусторонним сливоналивным железнодорожным эстакадам",
          "Не допускается",
        ],
        correctAnswers: [
          "Не допускается, за исключением продуктопроводов к двусторонним сливоналивным железнодорожным эстакадам",
        ],
      },
      {
        code: "20203077",
        text: "Разрешается ли прокладка заглубленных каналов и тоннелей при обустройстве нефтяных, газовых и газоконденсатных месторождений для размещения кабелей в помещениях и на территории наружных установок, имеющих источники возможного выделения в атмосферу вредных веществ плотностью по воздуху более 0,8, а также источники возможных проливов горючих жидкостей и жидкостей, содержащих сернистый водород?",
        answers: [
          "Запрещается",
          "Запрещается, за исключением каналов и тоннелей, подлежащих последующей засыпке",
          "Разрешается по согласованию с проектной организацией",
          "Разрешается",
        ],
        correctAnswers: [
          "Запрещается, за исключением каналов и тоннелей, подлежащих последующей засыпке",
        ],
      },
      {
        code: "20203078",
        text: "Допускается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений размещение инженерных сетей транзитных внутриплощадочных трубопроводов с токсичными жидкостями по стенам и кровлям зданий?",
        answers: [
          "Допускается при условии их прокладки по глухой стене I или II степени огнестойкости",
          "Допускается",
          "Не допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "20203079",
        text: "Что используется в качестве теплоносителей на технологических трубопроводах складов нефти и нефтепродуктов?",
        answers: [
          "Только пар",
          "Только электрообогрев",
          "Только промтеплофикационная вода",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20203080",
        text: "За счет чего должны компенсироваться температурные деформации трубопроводов для транспортировки мазута?",
        answers: [
          "За счет поворотов и изгибов трассы трубопроводов (самокомпенсация) или установки специальных компенсирующих устройств (П-образных компенсаторов)",
          "За счет установки линзовых компенсаторов на технологических трубопроводах для транспортировки мазута",
          "За счет установки волнистых компенсаторов на технологических трубопроводах для транспортировки мазута",
          "За счет установки сальниковых компенсаторов на технологических трубопроводах для транспортировки мазута",
        ],
        correctAnswers: [
          "За счет поворотов и изгибов трассы трубопроводов (самокомпенсация) или установки специальных компенсирующих устройств (П-образных компенсаторов)",
        ],
      },
      {
        code: "20203081",
        text: "Из какого материала должны применяться трубопроводы для транспортировки нефти и нефтепродуктов?",
        answers: [
          "Из стали",
          "Из полиэтилена",
          "Из фторопласта",
          "Из стекла",
          "Из винипласта",
        ],
        correctAnswers: ["Из стали"],
      },
      {
        code: "20203082",
        text: "Какая запорная арматура, установленная на технологических трубопроводах, должна иметь механический привод (электро-, пневмо- или гидропривод) с дистанционным управлением и ручным дублированием?",
        answers: [
          "Установленная на технологических трубопроводах с условным диаметром более 200 мм",
          "Установленная на технологических трубопроводах с условным диаметром более 300 мм",
          "Установленная на технологических трубопроводах с условным диаметром более 400 мм",
        ],
        correctAnswers: [
          "Установленная на технологических трубопроводах с условным диаметром более 400 мм",
        ],
      },
      {
        code: "20203083",
        text: "На какое минимальное расстояние должны не доходить защитные боковые ограждения открытых насосных станций до пола и покрытия (перекрытия) насосной станции?",
        answers: ["На 1 м", "На 0,5 м", "На 0,3 м", "На 1,3 м"],
        correctAnswers: ["На 0,3 м"],
      },
      {
        code: "20203084",
        text: "Какой документацией определяется электроснабжение электроприемников по категории надежности опасных производственных объектов складов нефти и нефтепродуктов?",
        answers: [
          "Производственными инструкциями",
          "Руководствами по безопасности при эксплуатации электроприемников",
          "Эксплуатационной документацией",
          "Проектной документацией (документацией на техническое перевооружение)",
        ],
        correctAnswers: [
          "Проектной документацией (документацией на техническое перевооружение)",
        ],
      },
      {
        code: "20203085",
        text: "При каком условии допускается использовать приборы, отработавшие назначенный срок службы, в системах автоматизации, связи и оповещения на опасных производственных объектах складов нефти и нефтепродуктов?",
        answers: [
          "При условии, что это предусмотрено эксплуатационной документацией",
          "При условии, что разработаны мероприятия безопасной эксплуатации таких приборов",
          "Не допускается ни в каком случае",
          "При условии, что это допускается согласно проектной документации",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20203086",
        text: "В соответствии с требованиями какой документации насосные агрегаты должны оснащаться системами автоматизации, обеспечивающими их безопасную эксплуатацию? Укажите все правильные ответы.",
        answers: [
          "Технической документации организации-изготовителя",
          "Производственных инструкций эксплуатирующей организации",
          "Эксплуатационной документации",
          "Проектной документации",
        ],
        correctAnswers: [
          "Технической документации организации-изготовителя",
          "Проектной документации",
        ],
      },
      {
        code: "20203087",
        text: "При каком достижении горючих газов и паров нефтепродуктов осуществляется включение аварийной вентиляции в помещениях насосных станций нефти и нефтепродуктов?",
        answers: [
          "50% объемных от НКПРП",
          "40% объемных от НКПРП",
          "30% объемных от НКПРП",
          "20% объемных от НКПРП",
        ],
        correctAnswers: ["50% объемных от НКПРП"],
      },
      {
        code: "20203088",
        text: "В каком случае допускается применение на складах нефти и нефтепродуктов поршневых насосов для нагнетания легковоспламеняющихся и горючих жидкостей при малых объемных скоростях подачи, в том числе в системах дозирования?",
        answers: [
          "Допускается при обосновании принятого технического решения в проектной документации",
          "Допускается при согласовании с территориальным органом Ростехнадзора",
          "Допускается, если это предусмотрено эксплуатационной документацией",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "Допускается при обосновании принятого технического решения в проектной документации",
        ],
      },
      {
        code: "20203089",
        text: "Какое отключение должны иметь насосные агрегаты, перекачивающие нефть и нефтепродукты?",
        answers: [
          "Только дистанционное отключение из операторной",
          "Дистанционное отключение из операторной и по месту",
          "Только отключение по месту",
        ],
        correctAnswers: ["Дистанционное отключение из операторной и по месту"],
      },
      {
        code: "20203090",
        text: "В каком случае допускается на опасных производственных объектах складов нефти и нефтепродуктов прокладка кабельных трасс и технологических трубопроводов на общих строительных конструкциях?",
        answers: [
          "Допускается при обосновании принятых технических решений в проектной документации (документации на техническое перевооружение)",
          "Допускается при условии наличия в эксплуатирующей организации плана компенсирующих мероприятий обеспечения требований пожарной и промышленной безопасности",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "Допускается при обосновании принятых технических решений в проектной документации (документации на техническое перевооружение)",
        ],
      },
      {
        code: "20203091",
        text: "Применение каких компенсаторов на технологических трубопроводах допускается для транспортировки мазута?",
        answers: [
          "Только линзовых компенсаторов",
          "Только волнистых компенсаторов",
          "Только сальниковых компенсаторов",
          "Допускается применение сальниковых, линзовых и волнистых компенсаторов",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20203092",
        text: "Какова периодичность зачистки металлических резервуаров для хранения нефтепродуктов?",
        answers: [
          "Не менее 1 раза в год - для авиационного бензина и не менее 1 раза в полугодие - для остальных светлых нефтепродуктов и масел",
          "Не менее 2-х раз в год для темных и светлых нефтепродуктов",
          "Не менее 2-х раз в год - для авиационного бензина и не менее 1 раза в 2 года - для остальных светлых нефтепродуктов и масел",
        ],
        correctAnswers: [
          "Не менее 2-х раз в год - для авиационного бензина и не менее 1 раза в 2 года - для остальных светлых нефтепродуктов и масел",
        ],
      },
      {
        code: "20203093",
        text: "В течение какого времени после ввода резервуара в эксплуатацию необходимо ежегодно проводить нивелирование окрайки днища в абсолютных отметках?",
        answers: [
          "В течение одного года",
          "В течение пяти лет",
          "В течение шести лет",
          "В течение четырех лет",
        ],
        correctAnswers: ["В течение четырех лет"],
      },
      {
        code: "20203094",
        text: "Что из перечисленного является недопустимым на территории предприятия, имеющего в своем составе взрывопожароопасные производства?",
        answers: [
          "Только наличие низин, природных оврагов и выемок",
          "Только устройство открытых траншей, котлованов, приямков",
          "Только траншейная и наземная прокладка трасс трубопроводов со сжиженными горючими газами, легковоспламеняющимися и горючими жидкостями в искусственных или естественных углублениях",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20203095",
        text: "Что относится к технологическим трубопроводам?",
        answers: [
          "Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования",
          "Трубопроводы, предназначенные для перемещения только в пределах промышленного предприятия сырья, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса",
          "Трубопроводы, представляющие собой собой конструкцию (сооружение), состоящую из труб, деталей и элементов трубопровода, включая трубопроводную арматуру, и предназначенные для транспортирования различных веществ, необходимых только для эксплуатации оборудования",
        ],
        correctAnswers: [
          "Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования",
        ],
      },
      {
        code: "20203096",
        text: "Какое из перечисленных положений нарушает требования, предъявляемые к прокладке трубопроводов на объектах нефтегазодобычи?",
        answers: [
          "При прокладке трубопроводов через строительные конструкции зданий и другие препятствия должны приниматься меры, исключающие возможность передачи дополнительных нагрузок на трубы",
          "Трубопроводы не должны иметь фланцевых или других разъемных соединений",
          "Прокладка трубопроводов должна обеспечивать максимально возможную протяженность коммуникаций, исключать провисания и образование застойных зон",
          "Материал фланцев, конструкция уплотнения применяются в соответствии с нормативными техническими документами, устанавливающими требования к технологическим трубопроводам с учетом условий эксплуатации",
        ],
        correctAnswers: [
          "Прокладка трубопроводов должна обеспечивать максимально возможную протяженность коммуникаций, исключать провисания и образование застойных зон",
        ],
      },
      {
        code: "20203097",
        text: "На основании каких данных определяются допустимые значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывопожароопасных характеристик, физико-химических свойств?",
        answers: [
          "На основании задания на проектирование",
          "На основании расчетных данных на проектирование",
          "На основании разрешения на проектирование",
          "На основании эксплуатационной документации",
        ],
        correctAnswers: ["На основании задания на проектирование"],
      },
      {
        code: "20203098",
        text: "Какие требования должны быть выполнены на вновь проектируемых взрывопожароопасных и химически опасных производственных объектах?",
        answers: [
          "Должна быть обеспечена только защита персонала, постоянно находящегося в помещении управления (операторных), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия",
          "Должны быть обеспечены все указанные требования",
          "Должно быть обеспечено только бесперебойное функционирование автоматизированных систем контроля, управления, противоаварийной автоматической защиты для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов",
        ],
        correctAnswers: ["Должны быть обеспечены все указанные требования"],
      },
      {
        code: "20203099",
        text: "В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?",
        answers: [
          "При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости",
          "При наличии автоматических блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости",
          "При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации",
          "При наличии автоматических блокировок, срабатывающих при превышении значений критических уровней в расходной и приемной емкостях",
        ],
        correctAnswers: [
          "При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации",
        ],
      },
      {
        code: "20203100",
        text: "Какое положение не соответствует установленным требованиям к электрообеспечению и электрооборудованию взрывоопасных технологических схем?",
        answers: [
          "Запрещается прокладка кабелей в каналах, засыпанных песком, и траншеях",
          "Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов в соответствии с требованиями нормативно-технических документов по устройству электроустановок",
          "Прокладку кабелей по территории предприятий и установок разрешается выполнять открыто: по эстакадам, в галереях и на кабельных конструкциях технологических эстакад",
          "Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение",
        ],
        correctAnswers: [
          "Запрещается прокладка кабелей в каналах, засыпанных песком, и траншеях",
        ],
      },
      {
        code: "20203101",
        text: "В технологических блоках какой категории взрывоопасности должны быть предусмотрены технические средства, обеспечивающие в автоматическом режиме оповещение об обнаружении, локализации и ликвидации выбросов опасных веществ?",
        answers: [
          "Только в технологических блоках I категории взрывоопасности",
          "Только в технологических блоках II категории взрывоопасности",
          "В технологических блоках всех категорий взрывоопасности",
          "Только в технологических блоках III категории взрывоопасности",
        ],
        correctAnswers: [
          "В технологических блоках всех категорий взрывоопасности",
        ],
      },
      {
        code: "20203102",
        text: "Какое из перечисленных положений не соответствует требованиям по размещению и устройству помещений управления взрывоопасных производств?",
        answers: [
          "В помещениях управления не должны размещаться оборудование и другие устройства, не связанные с системой управления технологическим процессом",
          "В отдельных случаях при соответствующем обосновании в проекте разрешается пристраивать помещения управления к зданиям",
          "Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами",
          "Помещения управления должны быть отдельно стоящими",
        ],
        correctAnswers: [
          "Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами",
        ],
      },
      {
        code: "20203103",
        text: "Где допускается расположение узла ввода теплоносителя?",
        answers: [
          "Только в самостоятельном помещении с отдельным входом с лестничной клетки или из невзрывопожароопасных производственных помещений",
          "Только в производственных помещениях, в которых предусматривается применение водяного или парового отопления",
          "Только в помещениях систем приточной вентиляции (в вентиляционной камере)",
          "Во всех указанных местах",
        ],
        correctAnswers: ["Во всех указанных местах"],
      },
      {
        code: "20203104",
        text: "Какими блокировками на отключение должны быть оснащены насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей?",
        answers: [
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости в корпусе насоса, достижении опасных значений в приемной емкости",
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
          "Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях",
          "Блокировками, исключающими пуск и (или) прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях",
        ],
        correctAnswers: [
          "Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений",
        ],
      },
      {
        code: "20203105",
        text: "В соответствии с каким документом должен быть разработан рабочий проект на производство буровых работ?",
        answers: [
          'В соответствии с Федеральными нормами и правилами в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности"',
          'В соответствии с РД 08-272-99 "Требования безопасности к буровому оборудованию для нефтяной и газовой промышленности"',
          "В соответствии с Правилами устройства и безопасной эксплуатации сосудов, работающих под давлением",
          'В соответствии с Федеральными нормами и правилами в области промышленной безопасности "Общие правила взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств"',
        ],
        correctAnswers: [
          'В соответствии с Федеральными нормами и правилами в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности"',
        ],
      },
      {
        code: "20203106",
        text: "Какие разделы входят в состав документации на ликвидацию скважин?",
        answers: [
          "Общая пояснительная записка, включающая обоснование критериев и варианта ликвидации скважин, вариант ликвидации (в зависимости от этапа бурения или эксплуатации скважин); технологические и технические решения по ликвидации скважин, оборудования ствола скважин и устья; порядок организации работ по ликвидации скважин; мероприятия по безопасному пользованию недрами, безопасности жизни и здоровья населения, охране окружающей среды, зданий и сооружений",
          "Общая пояснительная записка, включающая обоснование критериев и варианта ликвидации скважин, вариант ликвидации (в зависимости от этапа бурения или эксплуатации скважин); программа проведения первичного инструктажа на рабочем месте; технологические и технические решения по ликвидации скважин, оборудования ствола скважин и устья",
          "Разделы документации на ликвидацию скважин зависят от причин ликвидации скважин",
        ],
        correctAnswers: [
          "Общая пояснительная записка, включающая обоснование критериев и варианта ликвидации скважин, вариант ликвидации (в зависимости от этапа бурения или эксплуатации скважин); технологические и технические решения по ликвидации скважин, оборудования ствола скважин и устья; порядок организации работ по ликвидации скважин; мероприятия по безопасному пользованию недрами, безопасности жизни и здоровья населения, охране окружающей среды, зданий и сооружений",
        ],
      },
      {
        code: "20203107",
        text: "Что из нижеперечисленного не содержится в рабочем проекте на производство буровых работ?",
        answers: [
          "Ф. И. О. ответственного лица за производство буровых работ",
          "Географическая и климатическая характеристики района работ",
          "Данные об объеме исследования стратиграфического разреза в процессе бурения для уточнения пластовых давлений и состава флюида",
          "Обоснование плотности бурового раствора и диапазон колебаний других параметров промывочной жидкости",
        ],
        correctAnswers: [
          "Ф. И. О. ответственного лица за производство буровых работ",
        ],
      },
      {
        code: "20203108",
        text: "Какое наименьшее расстояние от устья нефтяных скважин со станками-качалками, устья нагнетательных скважин до общественных зданий?",
        answers: ["150 м", "200 м", "350 м", "250 м", "300 м"],
        correctAnswers: ["250 м"],
      },
      {
        code: "20203109",
        text: "На какие категории подразделяются скважины, подлежащие ликвидации?",
        answers: [
          "I - скважины, ликвидируемые по геологическим причинам; II - скважины, ликвидируемые по техническим причинам; III - скважины, ликвидируемые по технологическим, экономическим и другим причинам",
          "I - скважины, выполнившие свое назначение; II - скважины, ликвидируемые по техническим причинам; III - скважины, ликвидируемые по технологическим, экологическим и другим причинам; IV - скважины, ликвидируемые по причине нерентабельности (низкодебитные)",
          "I - скважины, выполнившие свое назначение; II - скважины, ликвидируемые по геологическим причинам; III - скважины, ликвидируемые по техническим причинам; IV - скважины, ликвидируемые по технологическим, экологическим и другим причинам",
        ],
        correctAnswers: [
          "I - скважины, выполнившие свое назначение; II - скважины, ликвидируемые по геологическим причинам; III - скважины, ликвидируемые по техническим причинам; IV - скважины, ликвидируемые по технологическим, экологическим и другим причинам",
        ],
      },
      {
        code: "20203110",
        text: "При каком уклоне сход с приемного моста на землю и вход на основание вышки должны быть оборудованы лестницей с перилами с одной стороны (наружной по отношению к настилу)?",
        answers: ["Более 20°", "Более 10°", "Более 15°", "Более 18°"],
        correctAnswers: ["Более 20°"],
      },
      {
        code: "20203111",
        text: "Каким условиям должны соответствовать расчеты на прочность и устойчивость при проектировании вышки и основания плавучих буровых установок?",
        answers: [
          "Должны быть проведены после проектирования подвышечного основания",
          "Должны быть проведены с учетом усилий, возникающих в условиях морского перехода",
          "Должны соответствовать проектной документации на строительство вышки",
          "Должны учитывать максимальную скорость ветра, для которой рассчитано сопротивление вышки или мачты, при наличии комплекта труб на подсвечнике",
        ],
        correctAnswers: [
          "Должны быть проведены с учетом усилий, возникающих в условиях морского перехода",
        ],
      },
      {
        code: "20203112",
        text: "Какие требования к органам систем управления бурового оборудования для нефтяной и газовой промышленности указаны неверно?",
        answers: [
          'Толкатели кнопок должны выполняться заподлицо с панелью; кнопка "Пуск" должна быть утоплена от 3 до 5 мм от поверхности, аварийная кнопка "Стоп" должна иметь грибовидную форму, увеличенный размер и выступать над панелью',
          "При размещении органов управления на одной панели, а связанных с ними индикаторов - на другой относительное расположение элементов на обеих панелях должно быть одинаковым",
          "У рукояток (кнопок) органов управления должны быть четкие и несмываемые надписи, указывающие назначение и направление вызываемых движений; при расстоянии надписей от оператора до 900 мм высота цифр должна быть 6 мм, при расстоянии более 900 мм - 8 мм",
          "При необходимости одновременного включения двух соседних переключателей обеими руками расстояние между ними должно быть не менее 55 мм, а при маневрировании в каждый момент только одним переключателем - не менее 15 мм",
          "Показывающие приборы и органы управления основным оборудованием (лебедкой, ротором и др.), необходимые для оперативного контроля и управления оборудованием, должны быть сосредоточены на пультах управления бурильщика",
        ],
        correctAnswers: [
          "При необходимости одновременного включения двух соседних переключателей обеими руками расстояние между ними должно быть не менее 55 мм, а при маневрировании в каждый момент только одним переключателем - не менее 15 мм",
        ],
      },
      {
        code: "20203113",
        text: "На какое давление должны быть рассчитаны уплотнения в гидравлической части насоса, корпусах предохранительного устройства и пневмокомпенсатора?",
        answers: [
          "На давление, равное 2,5-кратному максимальному рабочему давлению насоса",
          "На давление, равное 2-кратному максимальному рабочему давлению насоса",
          "На давление, равное 1,25-кратному максимальному рабочему давлению насоса",
          "На давление, равное 1,5-кратному максимальному рабочему давлению насоса",
        ],
        correctAnswers: [
          "На давление, равное 1,5-кратному максимальному рабочему давлению насоса",
        ],
      },
      {
        code: "20203114",
        text: "Буровые установки какой грузоподъемностью должны быть оснащены специальными кабинами для размещения в них рабочего места бурильщика?",
        answers: [
          "320 т и выше",
          "400 т и выше",
          "200 т и выше",
          "50 т и выше",
        ],
        correctAnswers: ["400 т и выше"],
      },
      {
        code: "20203115",
        text: "Какой должна быть присоединительная резьба ствола вертлюга?",
        answers: [
          "Правой",
          "Зависит от расчета рабочего давления пропускаемой жидкости",
          "Требование к резьбе ствола вертлюги не регламентируется нормативными документами",
          "Левой",
        ],
        correctAnswers: ["Левой"],
      },
      {
        code: "20203116",
        text: "На какую высоту укладки труб в штабель рассчитываются стеллажи приемного моста?",
        answers: [
          "Высотой не более 1000 мм",
          "Высотой не более 1250 мм",
          "Высотой не более 1750 мм",
          "Высотой не более 1500 мм",
        ],
        correctAnswers: ["Высотой не более 1250 мм"],
      },
      {
        code: "20203117",
        text: "Какое из нижеперечисленных положений не соответствует требованиям, предъявляемым к талевой системе?",
        answers: [
          "Конструкция крюка и талевого блока должна обеспечивать равномерное распределение нагрузки на подвешенные к нему штропы",
          "Резьбовое соединение ствола крюка с упорной гайкой должно иметь устройство, исключающее самопроизвольное отворачивание гайки",
          "Ствол крюка должен иметь устройство для принудительного стопорения вращения при технологической необходимости",
          "Конструкция крюка должна обеспечивать самопроизвольное выпадение элеваторных штропов из боковых рогов",
        ],
        correctAnswers: [
          "Конструкция крюка должна обеспечивать самопроизвольное выпадение элеваторных штропов из боковых рогов",
        ],
      },
      {
        code: "20203118",
        text: "Какие требования предъявляются к конструкции всасывающих линий буровых насосов?",
        answers: [
          "Всасывающие линии буровых насосов не должны иметь изгибов и поворотов, их диаметр должен быть не менее 200 мм, а длина - не более 5 м",
          "Всасывающие линии буровых насосов не должны иметь изгибов и поворотов, их диаметр должен быть не менее 150 мм, а длина - не более 6 м",
          "Всасывающие линии буровых насосов не должны иметь изгибов и поворотов, их диаметр должен быть не менее 180 мм, а длина - не более 5,5 м",
          "Всасывающие линии буровых насосов не должны иметь изгибов и поворотов, их диаметр должен быть не менее 130 мм, а длина - не более 6,5 м",
        ],
        correctAnswers: [
          "Всасывающие линии буровых насосов не должны иметь изгибов и поворотов, их диаметр должен быть не менее 200 мм, а длина - не более 5 м",
        ],
      },
      {
        code: "20203119",
        text: "На основе каких критериев осуществляется выбор буровых установок?",
        answers: [
          "Только на основе соответствия технических характеристик оборудования буровой установки ее классу",
          "Только на основе технических характеристик оборудования буровой установки и условий ее эксплуатации",
          "Только на основе допускаемой нагрузки на крюке",
          "По всем вышеперечисленным критериям",
        ],
        correctAnswers: ["По всем вышеперечисленным критериям"],
      },
      {
        code: "20203120",
        text: "Какой диаметр должны иметь всасывающие линии буровых насосов?",
        answers: [
          "Не менее 200 мм",
          "Не менее 150 мм",
          "Не менее 100 мм",
          "Не менее 250 мм",
        ],
        correctAnswers: ["Не менее 200 мм"],
      },
      {
        code: "20203121",
        text: "Каким условиям должны соответствовать расчеты на прочность и устойчивость при проектировании вышки с растяжками буровых установок?",
        answers: [
          "Только максимальному количеству и размеру труб при полном комплекте на подсвечнике",
          "Только максимальной скорости ветра при наличии полного комплекта труб на подсвечнике",
          "Только максимальной скорости ветра при условии отсутствия труб на подсвечнике",
          "Только максимальной статической нагрузке на крюке для определения типа оснастки талевой системы",
          "Всем вышеперечисленным условиям",
        ],
        correctAnswers: ["Всем вышеперечисленным условиям"],
      },
      {
        code: "20203122",
        text: "Каким условиям должны соответствовать расчеты на прочность и устойчивость при проектировании вышки без растяжек буровых установок?",
        answers: [
          "Только максимальной статической нагрузке на крюке",
          "Только максимальной скорости ветра, для которой рассчитано сопротивление вышки или мачты силе ветра при наличии полного комплекта труб на подсвечнике",
          "Только максимальной скорости ветра, для которой рассчитано сопротивление вышки или мачты силе ветра при отсутствии комплекта труб на подсвечнике",
          "Только максимальному количеству и типоразмеру свечей бурильных труб на подсвечнике",
          "Всем вышеперечисленным условиям",
        ],
        correctAnswers: ["Всем вышеперечисленным условиям"],
      },
      {
        code: "20203123",
        text: "Какие габариты должен иметь горизонтальный участок приемного моста, установленный у вышки со стороны ворот?",
        answers: [
          "Длина не менее 12 м и ширина не менее 1 м",
          "Длина не менее 14 м и ширина не менее 2 м",
          "Длина не менее 18 м и ширина не менее 3 м",
          "Длина не менее 16 м и ширина не менее 1,5 м",
        ],
        correctAnswers: ["Длина не менее 14 м и ширина не менее 2 м"],
      },
      {
        code: "20203124",
        text: "На какое давление должны быть рассчитаны уплотнительные элементы в гидравлической части вертлюга?",
        answers: [
          "На давление, равное 2,5-кратному максимальному рабочему давлению насоса",
          "На давление, равное 2-кратному максимальному рабочему давлению насоса",
          "На давление, равное 1,25-кратному максимальному рабочему давлению насоса",
          "На давление, равное 1,5-кратному максимальному рабочему давлению насоса",
        ],
        correctAnswers: [
          "На давление, равное 1,5-кратному максимальному рабочему давлению насоса",
        ],
      },
      {
        code: "20203125",
        text: "Какие требования к площадкам и лестницам бурового оборудования для нефтяной и газовой промышленности указаны верно?",
        answers: [
          "Не предназначенные для верхового рабочего площадки для обслуживания элементов оборудования на высоте от 1800 мм и более должны быть шириной не менее 450 мм с полезной площадью не менее 0,4 м²",
          "Для подъема на площадки, находящиеся на высоте от 250 до 750 мм, должны быть предусмотрены трапы и ступени, а на высоту более 750 мм - маршевые лестницы с перильными ограждениями",
          "Площадка верхового рабочего, выступающая во внутреннее пространство вышки или мачты, оборудованная козырьком, должна быть шириной не менее 550 мм с бортами не менее 100 мм",
          "Площадка верхового рабочего должна быть оснащена 3 стропами",
        ],
        correctAnswers: [
          "Для подъема на площадки, находящиеся на высоте от 250 до 750 мм, должны быть предусмотрены трапы и ступени, а на высоту более 750 мм - маршевые лестницы с перильными ограждениями",
        ],
      },
      {
        code: "20203126",
        text: "Каким условиям должны соответствовать расчеты на прочность и устойчивость при проектировании подвышечных оснований буровых установок?",
        answers: [
          "Только максимальной статической нагрузке на крюке",
          "Только максимальной статической нагрузке на ротор",
          "Только максимальной статической нагрузке на подсвечник",
          "Всем вышеперечисленным условиям",
        ],
        correctAnswers: ["Всем вышеперечисленным условиям"],
      },
      {
        code: "20203127",
        text: "Что должна предусматривать конструкция подвышечного основания бурового оборудования?",
        answers: [
          "Только возможность монтажа колонных головок и превенторной установки на устье скважины выше уровня земли без производства дополнительных работ с металлоконструкциями основания",
          "Только возможность демонтажа основания при установленной фонтанной арматуре или ее части",
          "Только возможность установки стола ротора на уровне пола буровой",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20203128",
        text: "Какие требования к нагнетательному трубопроводу буровых насосов указаны неверно?",
        answers: [
          "При нагнетательном трубопроводе должен быть предусмотрен отвод с запорным устройством для закачивания жидкости в затрубное пространство через крестовину превентора. Конструкции соединений нагнетательного трубопровода должны исключать возможность травмирования персонала в случае повреждения уплотнения",
          "Нагнетательный трубопровод должен иметь пусковое запорное устройство, позволяющее производить запуск насосов без нагрузки и их постепенный вывод на рабочий режим. На буровых установках с регулируемым приводом бурового насоса установка пусковых задвижек не обязательна",
          "Трубопроводы должны быть проложены с минимальным числом поворотов и изгибов. Поворот трубопровода не должен менять направление потока жидкости более чем на 60°",
          "Пусковые запорные устройства буровых насосов должны иметь дистанционное управление с контролем крайних положений их затворов с пульта управления. Конструкция манифольда должна обеспечивать быстрый слив бурового раствора из манифольда при остановке насоса за счет обеспечения оптимального угла наклона нагнетательного трубопровода",
        ],
        correctAnswers: [
          "Трубопроводы должны быть проложены с минимальным числом поворотов и изгибов. Поворот трубопровода не должен менять направление потока жидкости более чем на 60°",
        ],
      },
      {
        code: "20203129",
        text: "Какие блокировки должны быть предусмотрены в конструкции систем управления оборудованием буровых установок?",
        answers: [
          "Только ограничитель грузоподъемности лебедки",
          "Только ограничитель высоты подъема талевого блока",
          "Только блокировки подъема пневмоклиньев при вращающемся роторе и включения ротора при поднятых клиньях",
          "Только блокировка, исключающая включение барабана лебедки при выдвинутой стреле автомата спуско-подъема, а также выдвижение стрелы автомата при включенном барабане лебедки",
          "Все вышеперечисленные блокировки",
        ],
        correctAnswers: ["Все вышеперечисленные блокировки"],
      },
      {
        code: "20203130",
        text: "Какие требования к оборудованию циркуляционной системы бурового оборудования указаны верно?",
        answers: [
          "При наличии в открытой емкости для бурового раствора встроенного в нее циркуляционного желоба должен быть предусмотрен настил шириной не менее 750 мм с оборудованием одной из сторон перильным ограждением",
          "Емкости должны иметь люки для слива жидкости и обслуживания",
          "Размеры люка для обслуживания должны быть не менее 500 x 500 мм. Нижняя кромка сливного люка должна быть у самого дна емкости",
          "Углы поворота гидравлических перемешивающих устройств (гидромониторов) в горизонтальной и вертикальной плоскости не должны менять направление потока жидкости более чем на 60°",
        ],
        correctAnswers: [
          "Емкости должны иметь люки для слива жидкости и обслуживания",
        ],
      },
      {
        code: "20203131",
        text: "Какие размеры настила должны быть предусмотрены при наличии в открытой емкости для бурового раствора встроенного в нее циркуляционного желоба?",
        answers: [
          "Не менее 650 мм с перильным ограждением с двух сторон",
          "Не менее 750 мм с перильным ограждением с двух сторон",
          "Не менее 500 мм с перильным ограждением с одной стороны",
          "Не менее 700 мм с перильным ограждением с двух сторон",
        ],
        correctAnswers: [
          "Не менее 750 мм с перильным ограждением с двух сторон",
        ],
      },
      {
        code: "20203132",
        text: "Какие требования к буровым насосам указаны неверно?",
        answers: [
          "Пуск буровых насосов в работу должен осуществляться с пульта бурильщика, а регулирование их работы и остановка - с местного поста управления",
          "Всасывающие линии буровых насосов не должны иметь изгибов и поворотов, их диаметр должен быть не менее 200 мм, а длина - не более 5 м",
          "Конструкция пневмокомпенсатора должна позволять установку манометра для измерения давления в газовой полости и обеспечивать возможность сбрасывания давления до нуля",
          "Уплотнения в гидравлической части насоса, в корпусах предохранительного устройства и пневмокомпенсатора должны быть рассчитаны на давление, равное 1,5-кратному максимальному рабочему давлению насоса",
          "Конструкция элементов гидравлической части насоса должна исключать возможность травмирования обслуживающего персонала струей жидкости при повреждении уплотнений",
        ],
        correctAnswers: [
          "Пуск буровых насосов в работу должен осуществляться с пульта бурильщика, а регулирование их работы и остановка - с местного поста управления",
        ],
      },
      {
        code: "20203133",
        text: "При каком повышении давления в нагнетательном трубопроводе буровых установок должно быть предусмотрено автоматическое отключение приводов буровых насосов?",
        answers: [
          "При повышении давления на 5% выше допускаемого",
          "При повышении давления на 15% выше допускаемого",
          "При повышении давления на 10% выше допускаемого",
          "При повышении давления на 20% выше допускаемого",
        ],
        correctAnswers: ["При повышении давления на 10% выше допускаемого"],
      },
      {
        code: "20203134",
        text: "В каких случаях следует контролировать соответствие оборудования требованиям безопасности?",
        answers: [
          "Только если проводится испытание оборудования после модернизации и капитального ремонта",
          "Только если проводится монтаж оборудования и передача его в эксплуатацию",
          "Только если проводится экспертиза технического задания и конструкторской документации",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20203135",
        text: "Каким условиям должны соответствовать расчеты на прочность и устойчивость при проектировании вышки и основания буровых установок для работы в районе сейсмической активности?",
        answers: [
          "Условиям проектирования вышки",
          "Условиям проектирования подвышечного основания",
          "Условиям сейсмичности района",
          "Всем перечисленным условиям",
        ],
        correctAnswers: ["Условиям сейсмичности района"],
      },
      {
        code: "20203136",
        text: "На какое давление должны быть рассчитаны нагнетательный трубопровод и его элементы при рабочем давлении до 20 МПа?",
        answers: [
          "На давление, равное 1,4-кратному максимальному рабочему давлению",
          "На давление, равное 2-кратному максимальному рабочему давлению",
          "На давление, равное 1,5-кратному максимальному рабочему давлению",
          "На давление, равное 2,5-кратному максимальному рабочему давлению",
        ],
        correctAnswers: [
          "На давление, равное 1,5-кратному максимальному рабочему давлению",
        ],
      },
      {
        code: "20203137",
        text: "Какая величина зазора должна быть предусмотрена между кожухом и ребордами шкивов талевой системы?",
        answers: [
          "Не более 0,25 диаметра каната",
          "Не более 0,75 диаметра каната",
          "Не более 0,5 диаметра каната",
          "Не более 1 диаметра каната",
        ],
        correctAnswers: ["Не более 0,25 диаметра каната"],
      },
    ],
    20204: [
      {
        code: "20204000",
        text: "Каким документом определяется порядок организации и производства работ на одном объекте нескольких подразделений одной организации, эксплуатирующей ОПО?",
        answers: [
          "Регламентом об организации безопасного производства работ, утвержденным руководителем этой организации или уполномоченным им лицом",
          "Положением о производственном контроле организации",
          "Нарядом-допуском, оформленным техническим руководителем организации",
          "Производственным заданием, выданным руководителем организации или лицом, его замещающим",
          "Графиком взаимодействия, согласованным с вышестоящей организацией",
        ],
        correctAnswers: [
          "Регламентом об организации безопасного производства работ, утвержденным руководителем этой организации или уполномоченным им лицом",
        ],
      },
      {
        code: "20204001",
        text: "Кем утверждается перечень работ, осуществляемых по наряду-допуску, порядок оформления нарядов-допусков, перечни должностей специалистов, имеющих право выдавать и утверждать наряды-допуски?",
        answers: [
          "Руководителем организации или уполномоченным им лицом",
          "Техническим руководителем организации",
          "Специалистом по охране труда",
          "Представителем территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "Руководителем организации или уполномоченным им лицом",
        ],
      },
      {
        code: "20204002",
        text: "Что допускается в пределах территории буферной зоны?",
        answers: [
          "Функционирование спортивных сооружений, дошкольных, школьных, лечебно-профилактических и оздоровительных учреждений",
          "Остановка и стоянка транзитного пассажирского железнодорожного и любого автомобильного транспорта на дорогах общего пользования",
          "Строительство производственных и иных объектов, не связанных с разработкой месторождения",
          "Размещение в вахтовых поселках рабочих, работающих на месторождении, при условии выполнения всех проектных решений по обустройству месторождения",
        ],
        correctAnswers: [
          "Размещение в вахтовых поселках рабочих, работающих на месторождении, при условии выполнения всех проектных решений по обустройству месторождения",
        ],
      },
      {
        code: "20204003",
        text: "Кто должен обслуживать электрооборудование установки?",
        answers: [
          "Электротехнический персонал с группой по электробезопасности не ниже III",
          "Электротехнический персонал с группой по электробезопасности не ниже II",
          "Работники, имеющие соответствующую квалификацию и допуск к работе",
        ],
        correctAnswers: [
          "Работники, имеющие соответствующую квалификацию и допуск к работе",
        ],
      },
      {
        code: "20204004",
        text: "Разрешается ли последовательное соединение между собой заземляющих устройств разных зданий, сооружений, установок с помощью одного заземляющего проводника?",
        answers: [
          "Допускается",
          "Допускается при условии, что общее сопротивление заземляющего проводника не превышает 20 Ом",
          "Не допускается",
          "Не допускается, за исключением аппаратов или резервуаров, установленных в одном обваловании",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "20204005",
        text: "При каком условии рабочие бригады допускаются к выполнению специальных работ (передвижке буровой установки, монтажу мобильных буровых установок, ремонтным работам повышенной сложности)?",
        answers: [
          "При прохождении стажировки у опытных специалистов и проверке знаний по специальности и охране труда",
          "При прохождении дополнительного обучения и получении допуска к самостоятельной работе по основной и совмещаемой профессиям",
          "При прохождении стажировки у опытных специалистов и получении письменного разрешения на допуск к работам у технического руководителя предприятия",
          "При прохождении обучения, проводящегося по месту основной работы, и сдаче соответствующего экзамена",
        ],
        correctAnswers: [
          "При прохождении дополнительного обучения и получении допуска к самостоятельной работе по основной и совмещаемой профессиям",
        ],
      },
      {
        code: "20204006",
        text: "Какой класс взрывоопасной зоны представлен на рисунке?",
        answers: [
          "Класс взрывоопасности - зона 0",
          "Класс взрывоопасности - зона 1",
          "Класс взрывоопасности - зона 2",
        ],
        correctAnswers: ["Класс взрывоопасности - зона 1"],
      },
      {
        code: "20204007",
        text: "Какие сведения не подлежат обязательному включению в план ликвидации аварий, составленный на каждую скважину с возможностью возникновения газонефтеводопроявлений или открытого фонтана?",
        answers: [
          "Режим работы вентиляции при возникновении газонефтеводопроявлений",
          "Организация материально-технического, инженерного и финансового обеспечения операций по ликвидации аварий на объекте",
          "Первоочередные действия производственного персонала при появлении признаков газонефтеводопроявлений, порядок проведения штатных операций по предупреждению развития аварии",
          "Виды возможных аварий на данном объекте, мероприятия по спасению людей, ответственных за выполнение этих мероприятий, и конкретных исполнителей, места нахождения средств для спасения людей и ликвидации аварий",
          "Распределение обязанностей между работниками, участвующими в ликвидации газонефтеводопроявлений",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Организация материально-технического, инженерного и финансового обеспечения операций по ликвидации аварий на объекте",
        ],
      },
      {
        code: "20204008",
        text: "Кем определяются критерии предельных состояний и критерии вывода из эксплуатации оборудования, инструментов, контрольно-измерительных приборов?",
        answers: [
          "Определяются Ростехнадзором или его территориальным органом на основании экспертизы промышленной безопасности",
          "Определяются изготовителем и вносятся в эксплуатационную документацию изготовителя или разработчика технического устройства",
          "Определяются эксплуатирующей организацией или ее структурным подразделением на основании диагностирования",
        ],
        correctAnswers: [
          "Определяются изготовителем и вносятся в эксплуатационную документацию изготовителя или разработчика технического устройства",
        ],
      },
      {
        code: "20204009",
        text: "Какое напряжение должно применяться для питания переносных электрических светильников, используемых при работах в особо неблагоприятных условиях и наружных установках?",
        answers: ["Не выше 12 В", "Не выше 50 В", "Не выше 127 В"],
        correctAnswers: ["Не выше 12 В"],
      },
      {
        code: "20204010",
        text: "Что необходимо предпринять в случае обнаружения загазованности воздуха рабочей зоны?",
        answers: [
          "Незамедлительно предупредить работников близлежащих установок о возможной опасности, оградить загазованный участок и принять меры по устранению источника загазованности",
          "Незамедлительно предупредить обслуживающий персонал и покинуть загазованный участок",
          "Незамедлительно подать сигнал тревоги и предупредить ответственного руководителя",
          "Незамедлительно предупредить обслуживающий персонал о возможной опасности",
          "Незамедлительно покинуть загазованный участок и проинформировать о случившемся ответственного руководителя",
        ],
        correctAnswers: [
          "Незамедлительно предупредить работников близлежащих установок о возможной опасности, оградить загазованный участок и принять меры по устранению источника загазованности",
        ],
      },
      {
        code: "20204011",
        text: "Какими организациями разрабатываются и утверждаются ТР на работы по добыче, сбору и подготовке нефти, газа и газового конденсата?",
        answers: [
          "Разрабатываются проектной организацией на стадии проектирования и строительства, а также реконструкции. ТР на ОПО, находящийся в эксплуатации, может разрабатываться эксплуатирующей организацией и утверждается техническим руководителем эксплуатирующей организации",
          "Разрабатываются проектной организацией, а утверждаются территориальными органами Ростехнадзора",
          "Разрабатываются и утверждаются компанией-оператором",
          "Разрабатываются специализированными организациями, а утверждаются компанией-оператором",
          "Разрабатываются проектной организацией, а утверждаются подрядной организацией",
        ],
        correctAnswers: [
          "Разрабатываются проектной организацией на стадии проектирования и строительства, а также реконструкции. ТР на ОПО, находящийся в эксплуатации, может разрабатываться эксплуатирующей организацией и утверждается техническим руководителем эксплуатирующей организации",
        ],
      },
      {
        code: "20204012",
        text: "Кем осуществляется надзор за ходом строительства скважин, качеством выполнения работ, уровнем технологических процессов и операций, качеством используемых материалов и технических средств, соблюдением безопасных условий труда?",
        answers: [
          "Проектной организацией",
          "Региональным центром Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "Пользователем недр (заказчиком), организацией, осуществляющей производство буровых работ, и другими субъектами хозяйственной деятельности, уполномоченными пользователем недр",
          "Территориальным органом Ростехнадзора",
        ],
        correctAnswers: [
          "Пользователем недр (заказчиком), организацией, осуществляющей производство буровых работ, и другими субъектами хозяйственной деятельности, уполномоченными пользователем недр",
        ],
      },
      {
        code: "20204013",
        text: "При каких условиях допускается повторное использование рабочего проекта при бурении группы скважин на идентичных по геолого-техническим условиям площадях?",
        answers: [
          "При одинаковых проектных глубинах по стволу скважин",
          "При одинаковом назначении и конструкции скважин",
          "При отличии плотности бурового раствора от проектной в пределах ± 0,3 г/см3",
          "При идентичности горно-геологических условий проводки и условий природопользования",
          "При выполнении всех перечисленных условий",
        ],
        correctAnswers: ["При выполнении всех перечисленных условий"],
      },
      {
        code: "20204014",
        text: "Каким должно быть расстояние между группами скважин на кустовой площадке?",
        answers: [
          "Не менее 15 м",
          "Не менее 10 м",
          "Не менее 7 м",
          "Не менее 5 м",
        ],
        correctAnswers: ["Не менее 15 м"],
      },
      {
        code: "20204015",
        text: "Каким должно быть расстояние между кустами или кустовой площадкой и одиночной скважиной?",
        answers: [
          "Не менее 20 м",
          "Не менее 30 м",
          "Не менее 40 м",
          "Не менее 50 м",
        ],
        correctAnswers: ["Не менее 50 м"],
      },
      {
        code: "20204016",
        text: "Какое общее количество скважин в группе может быть размещено на кустовой площадке?",
        answers: [
          "Не более 3",
          "Не более 6",
          "Не более 8",
          "Общее количество скважин устанавливается заказчиком по согласованию с территориальным управлением Ростехнадзора",
        ],
        correctAnswers: ["Не более 8"],
      },
      {
        code: "20204017",
        text: "Какое расстояние должно быть между устьями скважин при их размещении на кустовых площадках вечномерзлых грунтов?",
        answers: [
          "Должно быть равно диаметру растепления пород вокруг устья скважины",
          "Должно быть не менее 50 м",
          "Должно быть не меньше 1,2 диаметра растепления пород вокруг устья скважин",
          "Должно быть не менее 100 м",
        ],
        correctAnswers: [
          "Должно быть не меньше 1,2 диаметра растепления пород вокруг устья скважин",
        ],
      },
      {
        code: "20204018",
        text: "Кто разрабатывает и утверждает документацию по организации безопасного производства работ на кустовой площадке?",
        answers: [
          "Представитель проектной организации",
          "Пользователь недр (заказчик) или его представитель",
          "Представитель организации, осуществляющей строительство нефтяных и газовых скважин",
          "Представитель территориального органа Ростехнадзора",
        ],
        correctAnswers: ["Пользователь недр (заказчик) или его представитель"],
      },
      {
        code: "20204019",
        text: "Каким образом происходит передвижение транспортных средств на кустовых площадках?",
        answers: [
          "В соответствии с установленными маршрутами передвижения и под контролем ответственного руководителя работ",
          "По дорогам со скоростью не более 40 км/ч",
          "В соответствии с графиком передвижения",
          "Правилами не регламентировано",
        ],
        correctAnswers: [
          "В соответствии с установленными маршрутами передвижения и под контролем ответственного руководителя работ",
        ],
      },
      {
        code: "20204020",
        text: "На каком расстоянии от устья бурящейся скважины должны быть расположены служебные и бытовые помещения?",
        answers: [
          "На расстоянии, равном высоте вышки плюс 10 м",
          "На расстоянии, равном высоте вышки плюс 5 м",
          "На расстоянии, равном высоте вышки",
          "На расстоянии 3 м от кустовой площадки",
        ],
        correctAnswers: ["На расстоянии, равном высоте вышки плюс 10 м"],
      },
      {
        code: "20204021",
        text: "В каком случае при освоении нефтяных месторождений в проектной документации должны быть предусмотрены дополнительные меры безопасности при испытании обсадных колонн на герметичность и обвязке устьев скважин противовыбросовым оборудованием?",
        answers: [
          "При освоении нефтяных месторождений с газовым фактором более 200 м3/т",
          "При освоении нефтяных месторождений, содержащих от 10 до 30 млн т нефти",
          "При освоении нефтяных месторождений с тектонически слабонарушенными структурами, продуктивные пласты которых характеризуются выдержанностью толщин и коллекторских свойств по площади и разрезу",
          "При освоении нефтяных месторождений, содержащих нефть с растворенным газом и свободный газ над нефтью, с газовым фактором более 100 м3/т",
        ],
        correctAnswers: [
          "При освоении нефтяных месторождений с газовым фактором более 200 м3/т",
        ],
      },
      {
        code: "20204022",
        text: "При каком содержании газа в буровом растворе производится отбор проб газовоздушной среды в процессе бурения на рабочей площадке буровой, в насосном блоке, блоках очистки бурового раствора и емкостной системы?",
        answers: [
          "Более 5%",
          "Более 4%",
          "Более 3%",
          "Более 2%",
          "Все ответы неверны",
        ],
        correctAnswers: ["Более 5%"],
      },
      {
        code: "20204023",
        text: "Что должна обеспечить прочность технической колонны и установленного противовыбросового оборудования?",
        answers: [
          "Противостояние воздействию давления гидростатического столба бурового раствора максимальной плотности",
          "Герметизацию устья скважины в случаях газонефтеводопроявлений, выбросов и открытого фонтанирования с учетом дополнительного давления, необходимого для их ликвидации",
          "Противостояние воздействию максимальных сминающих нагрузок в случаях открытого фонтанирования или поглощения с падением уровня бурового раствора, а также в интервале пород, склонных к текучести",
          "Все перечисленное",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20204024",
        text: "Каким документом устанавливаются периодичность и способы проверки состояния обсадных колонн по мере их износа и необходимые мероприятия по обеспечению безопасной проводки и эксплуатации скважин?",
        answers: [
          "Методическими указаниями проектной организации",
          "Рабочим проектом или иной документацией, содержащей аналогичные требования",
          "Регламентом, разработанным в соответствии с проектом",
          "Правилами безопасности в нефтяной и газовой промышленности",
        ],
        correctAnswers: [
          "Рабочим проектом или иной документацией, содержащей аналогичные требования",
        ],
      },
      {
        code: "20204025",
        text: "Что из перечисленного не учитывается при планировании площадки для монтажа буровой установки?",
        answers: [
          "Обеспечение движения сточных вод в систему их сбора и очистки",
          "Естественный уклон местности",
          "Роза ветров",
        ],
        correctAnswers: ["Роза ветров"],
      },
      {
        code: "20204026",
        text: "После выполнения какого условия работникам разрешается приступить к демонтажу буровой установки на электроприводе?",
        answers: [
          "После получения устного подтверждения главного энергетика организации об отключении установки от электросети",
          "После подачи звукового сигнала оператором буровой установки о снятии напряжения на установке",
          "После получения письменного подтверждения работника, ответственного за эксплуатацию электрооборудования, об отключении установки от электросети",
          "После получения информации от вышкомонтажника-электромонтера о снятии напряжения на буровой установке",
        ],
        correctAnswers: [
          "После получения письменного подтверждения работника, ответственного за эксплуатацию электрооборудования, об отключении установки от электросети",
        ],
      },
      {
        code: "20204027",
        text: "В каком случае оснащение буровых установок верхним приводом необязательно?",
        answers: [
          "В случае вскрытия пластов с ожидаемым содержанием в пластовом флюиде сероводорода свыше 6% (объемных)",
          "В случае набора угла с радиусом кривизны менее 30 м в наклонно-направленных скважинах",
          "В случае бурения скважин с глубиной менее 4500 м",
          "В случае бурения горизонтального участка ствола скважины длиной более 300 м в скважинах глубиной по вертикали более 3000 м",
        ],
        correctAnswers: ["В случае бурения скважин с глубиной менее 4500 м"],
      },
      {
        code: "20204028",
        text: "Откуда должен осуществляться пуск буровых насосов в работу?",
        answers: [
          "С пульта бурильщика",
          "С местного поста управления",
          "С пульта оператора, осуществляющего контроль технологического процесса",
          "Одновременно с пульта бурильщика и местного поста управления",
        ],
        correctAnswers: ["С местного поста управления"],
      },
      {
        code: "20204029",
        text: "При достижении какого значения нижнего предела воспламенения смеси воздуха с углеводородом должно быть обеспечено полное отключение оборудования и механизмов?",
        answers: [
          "При достижении 20% от нижнего предела воспламенения смеси воздуха с углеводородами",
          "При достижении 30% от нижнего предела воспламенения смеси воздуха с углеводородами",
          "При достижении 40% от нижнего предела воспламенения смеси воздуха с углеводородами",
          "При достижении 50% от нижнего предела воспламенения смеси воздуха с углеводородами",
        ],
        correctAnswers: [
          "При достижении 50% от нижнего предела воспламенения смеси воздуха с углеводородами",
        ],
      },
      {
        code: "20204030",
        text: "Должны ли буровые насосы оборудоваться компенсаторами давления? Если да, то какие требования при этом должны соблюдаться?",
        answers: [
          "Установка на буровых насосах компенсаторов давления необязательна в том случае, если проводятся мероприятия по обеспечению равномерности подачи промывочной жидкости",
          "Компенсаторы давления должны устанавливаться только на трех поршневых буровых насосах, при этом компенсаторы должны быть заполнены воздухом или инертным газом, с приспособлениями для контроля давления в компенсаторах",
          "На всех буровых насосах должны быть установлены компенсаторы давления, заполняемые воздухом или инертным газом, с конструкцией, предусматривающей установку манометра для измерения давления в газовой полости и обеспечивающей возможность сбрасывания давления до нуля",
          "На всех буровых насосах должны быть установлены гидрокомпенсаторы давления, заполняемые водой, с приспособлениями для контроля давления в компенсаторах",
        ],
        correctAnswers: [
          "На всех буровых насосах должны быть установлены компенсаторы давления, заполняемые воздухом или инертным газом, с конструкцией, предусматривающей установку манометра для измерения давления в газовой полости и обеспечивающей возможность сбрасывания давления до нуля",
        ],
      },
      {
        code: "20204031",
        text: "Что должно быть указано на корпусах оборудования, входящего в состав талевой системы (кронблок, талевый блок, крюк)?",
        answers: [
          "Дата изготовления",
          "Допускаемая грузоподъемность",
          "Материал изготовления",
          "Сроки следующего испытания",
        ],
        correctAnswers: ["Допускаемая грузоподъемность"],
      },
      {
        code: "20204032",
        text: "Каким давлением производится испытание пневматической системы буровой установки на месте производства работ (после монтажа, ремонта)?",
        answers: [
          "После проведения испытаний пневматической системы буровой установки на заводе-изготовителе испытания на месте производства работ не проводятся",
          "Давление испытания должно быть 1,15 рабочего давления",
          "Давление испытания должно быть 1,5 рабочего давления",
          "Давление испытания должно быть 1,25 рабочего давления, но не менее 3 кгс/см² (0,3 МПа)",
        ],
        correctAnswers: [
          "Давление испытания должно быть 1,25 рабочего давления, но не менее 3 кгс/см² (0,3 МПа)",
        ],
      },
      {
        code: "20204033",
        text: "При каком превышении давления должны срабатывать предохранительные устройства насоса?",
        answers: [
          "При давлении, превышающем на 3% рабочее давление насоса, соответствующее диаметру установленных цилиндровых втулок",
          "При давлении, превышающем на 20% рабочее давление насоса, соответствующее диаметру установленных цилиндровых втулок",
          "При давлении, превышающем на 15% рабочее давление насоса, соответствующее диаметру установленных цилиндровых втулок",
          "При давлении, превышающем на 10% рабочее давление насоса, соответствующее диаметру установленных цилиндровых втулок",
        ],
        correctAnswers: [
          "При давлении, превышающем на 10% рабочее давление насоса, соответствующее диаметру установленных цилиндровых втулок",
        ],
      },
      {
        code: "20204034",
        text: "Где должен быть установлен основной пульт для управления превенторами и гидравлическими задвижками?",
        answers: [
          "На расстоянии не менее 10 м от устья скважины в удобном и безопасном месте",
          "На расстоянии не менее 8 м от устья скважины в удобном и безопасном месте",
          "На расстоянии не менее 6 м от устья скважины в удобном и безопасном месте",
          "Основной пульт должен быть установлен непосредственно возле пульта бурильщика",
        ],
        correctAnswers: [
          "На расстоянии не менее 10 м от устья скважины в удобном и безопасном месте",
        ],
      },
      {
        code: "20204035",
        text: "Какие данные должны быть указаны на металлической табличке, укрепляемой на видном месте мачты агрегата по ремонту скважин?",
        answers: [
          "Наименование организации, владеющей данным агрегатом, и его заводской номер",
          "Грузоподъемность и дата очередного технического освидетельствования",
          "Грузоподъемность и дата последнего технического освидетельствования",
        ],
        correctAnswers: [
          "Грузоподъемность и дата очередного технического освидетельствования",
        ],
      },
      {
        code: "20204036",
        text: "Чем должны оснащаться передвижные насосные установки, предназначенные для работы на скважинах?",
        answers: [
          "Запорными и предохранительными устройствами, приборами, контролирующими основные параметры технологического процесса, выведенные на пульт управления",
          "Приборами, контролирующими основные параметры технологического процесса",
          "Запорными, предохранительными и регулирующими устройствами",
        ],
        correctAnswers: [
          "Запорными и предохранительными устройствами, приборами, контролирующими основные параметры технологического процесса, выведенные на пульт управления",
        ],
      },
      {
        code: "20204037",
        text: "Чем должны быть оборудованы и оснащены колтюбинговые установки с гибкими непрерывными трубами?",
        answers: [
          "Комплектом устройств на устье скважины для спуска труб под давлением, рассчитанным на максимально возможное устьевое давление",
          "Системой контроля и регистрации нагрузок, возникающих при спускоподъемных операциях",
          "Системой контроля и регистрации давления при прокачивании через гибкую трубу жидкостей в процессе технологических операций",
          "Всеми перечисленными устройствами",
        ],
        correctAnswers: ["Всеми перечисленными устройствами"],
      },
      {
        code: "20204038",
        text: "В соответствии с требованиями каких документов производится подготовка площадки, монтаж и эксплуатация колтюбинговых установок?",
        answers: [
          "В соответствии с техническими условиями и инструкцией по эксплуатации завода-изготовителя",
          "Только в соответствии с инструкцией по производству работ, утвержденной Ростехнадзором",
          "В соответствии с техническими условиями и инструкцией по производству работ, утвержденной Ростехнадзором",
          "Только в соответствии с техническими условиями",
        ],
        correctAnswers: [
          "В соответствии с техническими условиями и инструкцией по эксплуатации завода-изготовителя",
        ],
      },
      {
        code: "20204039",
        text: "Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?",
        answers: [
          "Лица, допущенные к выполнению газоопасных работ, должны пройти обучение приемам и методам проведения работ",
          "Лица, допущенные к выполнению газоопасных работ, должны пройти медицинский осмотр в соответствии с требованиями законодательства Российской Федерации",
          "Лица, допущенные к выполнению газоопасных работ, должны быть не моложе 18 лет",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20204040",
        text: "Кем из перечисленных должностных лиц согласовываются сроки проведения газоопасных работ на опасных производственных объектах подрядными организациями?",
        answers: [
          "Руководителем эксплуатирующей организации или его уполномоченным заместителем либо руководителем филиала или его уполномоченным заместителем с учетом особенностей использования электронной подписи",
          "Лицом, ответственным за осуществление производственного контроля",
          "Лицом, ответственным за подготовку газоопасных работ",
          "Представителем Ростехнадзора",
        ],
        correctAnswers: [
          "Руководителем эксплуатирующей организации или его уполномоченным заместителем либо руководителем филиала или его уполномоченным заместителем с учетом особенностей использования электронной подписи",
        ],
      },
      {
        code: "20204041",
        text: "В каких из перечисленных случаев должны проводиться газоопасные работы? Выберите два правильных варианта ответа.",
        answers: [
          "Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть автоматизированы",
          "Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть механизированы",
          "Когда газоопасные работы могут быть проведены без непосредственного участия людей",
        ],
        correctAnswers: [
          "Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть автоматизированы",
          "Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть механизированы",
        ],
      },
      {
        code: "20204042",
        text: "На сколько групп подразделяются газоопасные работы в зависимости от степени опасности и на основании каких критериев устанавливается та или иная группа?",
        answers: [
          "На 3 группы, в зависимости от степени риска проводимых работ",
          "На 2 группы, в зависимости от того, проводятся работы в закрытом или открытом пространстве",
          "На 2 группы, в зависимости от того, проводятся газоопасные работы с оформлением наряда-допуска или без оформления",
        ],
        correctAnswers: [
          "На 2 группы, в зависимости от того, проводятся газоопасные работы с оформлением наряда-допуска или без оформления",
        ],
      },
      {
        code: "20204043",
        text: "Какой минимальный срок хранения установлен для журнала регистрации нарядов-допусков на проведение газоопасных работ?",
        answers: [
          "3 месяца со дня его окончания",
          "6 месяцев со дня его окончания",
          "1 год со дня его окончания",
          "3 года со дня его окончания",
        ],
        correctAnswers: ["6 месяцев со дня его окончания"],
      },
      {
        code: "20204044",
        text: "Где регистрируются газоопасные работы II группы?",
        answers: [
          "В журнале учета газоопасных работ, проводимых без наряда-допуска на проведение газоопасных работ",
          "В журнале регистрации нарядов-допусков на проведение газоопасных работ с присвоением очередного номера",
          "В журнале учета выдачи нарядов-допусков на выполнение работ повышенной опасности",
          "В журнале регистрации целевого инструктажа",
        ],
        correctAnswers: [
          "В журнале учета газоопасных работ, проводимых без наряда-допуска на проведение газоопасных работ",
        ],
      },
      {
        code: "20204045",
        text: "Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?",
        answers: [
          "Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ",
          "Любой из специалистов организации-заказчика (эксплуатирующей организации)",
          "Только специалист, обученный пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность",
          "Только руководитель структурного подразделения, где будет проводиться газоопасная работа, или его уполномоченный заместитель",
        ],
        correctAnswers: [
          "Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ",
        ],
      },
      {
        code: "20204046",
        text: "На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?",
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ (включая земляные работы) на опасных производственных объектах, указанных в пункте 1 приложения №1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          "На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности",
          "На ведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на территории находящихся в эксплуатации опасных производственных объектов",
          "На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы",
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ (включая земляные работы) на опасных производственных объектах, указанных в пункте 1 приложения №1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "20204047",
        text: "Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года",
          "Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
      {
        code: "20204048",
        text: "Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?",
        answers: [
          "Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск, на необходимый для окончания работ срок",
          "Руководитель структурного подразделения не более чем на 1 рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск, не более чем на 1 дневную смену",
        ],
        correctAnswers: [
          "Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
        ],
      },
      {
        code: "20204049",
        text: "В каком исполнении должны быть агрегаты, применяемые во взрывопожароопасных зонах?",
        answers: [
          "Во взрывозащищенном",
          "В брызгозащищенном",
          "В пылевлагонепроницаемом",
          "Во взрывонепроницаемом",
        ],
        correctAnswers: ["Во взрывозащищенном"],
      },
      {
        code: "20204050",
        text: "В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?",
        answers: [
          "Не менее 3 месяцев со дня закрытия наряда-допуска",
          "Не менее 1 года со дня закрытия наряда-допуска",
          "Не менее 6 месяцев со дня закрытия наряда-допуска",
        ],
        correctAnswers: ["Не менее 6 месяцев со дня закрытия наряда-допуска"],
      },
      {
        code: "20204051",
        text: "Каким документом определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?",
        answers: [
          "Организационно-распорядительными документами руководителя",
          "Технологическим регламентом",
          "Федеральными нормами и правилами в области промышленной безопасности",
          "Правилами пожарной безопасности",
        ],
        correctAnswers: [
          "Организационно-распорядительными документами руководителя",
        ],
      },
      {
        code: "20204052",
        text: "На каком расстоянии друг от друга должны располагаться скважины на специальной площадке по одной прямой на оси куста, если иное не установлено проектом?",
        answers: ["3 м", "4 м", "5 м", "6 м", "Все ответы неверны"],
        correctAnswers: ["5 м"],
      },
      {
        code: "20204053",
        text: "Какое условие должно быть соблюдено при утверждении проектом меньших расстояний между устьями скважин, установленных Инструкцией по безопасности одновременного производства буровых работ, освоению и эксплуатации скважин на кусте?",
        answers: [
          "Отклонение согласовывается с соответствующим территориальным органом Ростехнадзора, а расстояние между устьями скважин должно быть не менее 2 м",
          "Отклонение согласовывается с руководителем работ по ликвидации аварии и руководителем горноспасательных работ, а расстояние между устьями скважин должно быть не менее 2 м",
          "Отклонение утверждается техническим руководителем, а расстояние между устьями скважин должно быть не менее 3 м",
          "Отклонение согласовывается с начальником аэрологической безопасности",
        ],
        correctAnswers: [
          "Отклонение согласовывается с соответствующим территориальным органом Ростехнадзора, а расстояние между устьями скважин должно быть не менее 2 м",
        ],
      },
      {
        code: "20204054",
        text: "В каком случае допускается консервация скважины без спуска насосно-компрессорных труб при одновременном производстве буровых работ, освоении и эксплуатации скважин на кусте?",
        answers: [
          "При непрерывном цикле работ на кусте по строительству скважин после окончания бурения очередной скважины и получения положительных результатов проверки качества цементирования, прочности и герметичности эксплуатационной колонны и устьевой обвязки",
          "При согласовании с территориальным органом Ростехнадзора",
          "В случае если это установлено в Положении о порядке организации одновременного ведения работ по бурению, освоению, вскрытию дополнительных продуктивных отложений, эксплуатации и ремонту скважин на кустовой площадке",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "При непрерывном цикле работ на кусте по строительству скважин после окончания бурения очередной скважины и получения положительных результатов проверки качества цементирования, прочности и герметичности эксплуатационной колонны и устьевой обвязки",
        ],
      },
      {
        code: "20204055",
        text: "В каком случае пользователь недр (заказчик) вправе вмешиваться в оперативно-хозяйственную деятельность подрядчика при одновременном производстве буровых работ, освоении и эксплуатации скважин на кусте?",
        answers: [
          "Не вправе ни в каком случае",
          "В любом случае по своему усмотрению",
          "В случаях, установленных Положением о порядке организации одновременного ведения работ по бурению, освоению, вскрытию дополнительных продуктивных отложений, эксплуатации и ремонту скважин на кустовой площадке",
        ],
        correctAnswers: ["Не вправе ни в каком случае"],
      },
      {
        code: "20204056",
        text: "Какие работы производят по наряду-допуску при одновременном производстве буровых работ, освоении и эксплуатации скважин на кусте?",
        answers: [
          "Рекультивацию территории куста, амбаров",
          "Обвязку и подключение скважин к действующим системам сбора продукции и поддержания пластового давления",
          "Передвижки вышечно-лебедочного блока, другого оборудования на новую позицию или скважину",
          "Все перечисленные",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20204057",
        text: "Кем производится выдача наряда-допуска при одновременном производстве буровых работ, освоении и эксплуатации скважин на кусте?",
        answers: [
          "Ответственным руководителем работ на кусте",
          "Техническим руководителем организации",
          "Руководителем эксплуатирующей организации",
          "Начальником службы охраны труда",
          "Все ответы неверны",
        ],
        correctAnswers: ["Ответственным руководителем работ на кусте"],
      },
      {
        code: "20204058",
        text: "Какое требование к организации работ на кустовой площадке при одновременном производстве буровых работ, освоении и эксплуатации скважин на кусте указано верно?",
        answers: [
          "Система водоснабжения кустовой площадки должна предусматривать возможность аварийного орошения устьевого оборудования действующих скважин на время, необходимое для подключения пожарных стволов к магистральному водопроводу или другим источникам водоснабжения",
          "Схема обвязки устьев скважин в процессе бурения, освоения, эксплуатации и ремонта разрабатывается техническим руководителем организации и утверждается территориальным органом Ростехнадзора",
          "Допускается подключение к системам поддержания пластового давления при использовании в них в качестве рабочего агента технической воды с добавлением химических реагентов",
          "Допускаются последовательное освоение, интенсификация притоков, дополнительное вскрытие продуктивных отложений, в том числе путем проводки горизонтальных ответвлений, ввод в эксплуатацию ранее пробуренных скважин, расположенных на расстоянии, обеспечивающем безопасный монтаж и эксплуатацию установок (агрегатов) для освоения и ремонта скважин в соответствии с инструкциями завода-изготовителя, но не менее 15 м от устья бурящейся скважины",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Система водоснабжения кустовой площадки должна предусматривать возможность аварийного орошения устьевого оборудования действующих скважин на время, необходимое для подключения пожарных стволов к магистральному водопроводу или другим источникам водоснабжения",
        ],
      },
      {
        code: "20204059",
        text: "В каком случае следует прекратить все работы на соседних объектах кустовых площадок?",
        answers: [
          "В случае передвижки вышечно-лебедочного блока на новую точку (позицию)",
          "В случае ведения сложных аварийных работ на скважине",
          "В случае испытания вышек",
          "Во всех вышеперечисленных случаях все работы на соседних объектах должны быть прекращены",
        ],
        correctAnswers: [
          "Во всех вышеперечисленных случаях все работы на соседних объектах должны быть прекращены",
        ],
      },
      {
        code: "20204060",
        text: "Какое из перечисленных утверждений является верным в случае затопления кустовой площадки паводковыми водами выше колонных фланцев при одновременном производстве буровых работ, освоении и эксплуатации скважин на кусте?",
        answers: [
          "Бурение и освоение скважин осуществляется по специальному плану, утвержденному пользователем недр (его представителем) и согласованному с соответствующим территориальным органом Ростехнадзора",
          "Ремонт скважин осуществляется по специальному плану, утвержденному пользователем недр (его представителем)",
          "Эксплуатация скважин не допускается",
          "Ремонт скважин не допускается",
          "Все ответы неверны",
        ],
        correctAnswers: ["Ремонт скважин не допускается"],
      },
      {
        code: "20204061",
        text: "Кем устанавливается готовность кустовой площадки к началу работ по строительству скважин?",
        answers: [
          "Комиссией, назначаемой заказчиком, с включением в состав комиссии представителей исполнителей работ, бурового предприятия и организации, осуществляющей эксплуатацию опасных производственных объектов",
          "Комиссией, назначаемой организацией, эксплуатирующей опасные производственные объекты",
          "Территориальным органом Ростехнадзора",
          "Эксплуатирующей организацией при согласовании с заказчиком",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Комиссией, назначаемой заказчиком, с включением в состав комиссии представителей исполнителей работ, бурового предприятия и организации, осуществляющей эксплуатацию опасных производственных объектов",
        ],
      },
      {
        code: "20204062",
        text: "Какая устанавливается опасная зона, из которой должны быть удалены люди, кроме работников, занятых непосредственно ликвидацией аварии, передвижкой вышечно-лебедочного блока, при испытании вышки?",
        answers: [
          "Радиусом, равным высоте вышки",
          "Радиусом, равным высоте вышки плюс 5 м",
          "Радиусом, равным высоте вышки плюс 10 м",
          "Радиусом, равным высоте вышки плюс 15 м",
          "Все ответы неверны",
        ],
        correctAnswers: ["Радиусом, равным высоте вышки плюс 10 м"],
      },
      {
        code: "20204063",
        text: 'Допускается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений хранение токсичных жидкостей в резервуарах с "атмосферным" дыханием?',
        answers: [
          "Допускается при условии оснащения резервуаров системой аварийного слива жидкости в дренажную систему",
          "Допускается при условии оснащения резервуаров сигнализацией предельного верхнего уровня заполнения резервуара, сблокированной с насосным оборудованием",
          "Допускается в любом случае",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20204064",
        text: "Что должна обеспечивать прочность кондукторов, технических колонн и установленного на них противовыбросового оборудования?",
        answers: [
          "Герметизацию устья скважины в случаях ГНВП, выбросов и открытого фонтанирования с учетом превышения дополнительного давления, необходимого для глушения скважины, не менее чем на 5%",
          "Сохранение целостности при воздействии гидростатического давления столба бурового раствора средней плотности",
          "Противостояние воздействию максимальных сжимающих нагрузок в случаях открытого фонтанирования или поглощения с падением уровня бурового раствора, а также в интервалах залегания склонных к текучести пород",
          "Все перечисленное",
        ],
        correctAnswers: [
          "Противостояние воздействию максимальных сжимающих нагрузок в случаях открытого фонтанирования или поглощения с падением уровня бурового раствора, а также в интервалах залегания склонных к текучести пород",
        ],
      },
      {
        code: "20204065",
        text: "Чем должно быть оборудовано устье скважины при эксплуатации ее штанговыми насосами?",
        answers: [
          "Запорной арматурой и сальниковым устройством для герметизации штока",
          "Устройством для сигнализации об утечках продукта",
          "Перфорационной задвижкой",
          "Запорной арматурой и обратным клапаном",
          "Шаровым клапаном и сальниковым устройством для герметизации штока",
        ],
        correctAnswers: [
          "Запорной арматурой и сальниковым устройством для герметизации штока",
        ],
      },
      {
        code: "20204066",
        text: "Какие плакаты должны быть постоянно укреплены на пусковом устройстве и вблизи него на скважинах с автоматическим и дистанционным управлением станков-качалок?",
        answers: [
          '"Внимание! Пуск автоматический"',
          '"Осторожно! Высокое напряжение"',
          '"Без команды не включать!"',
          '"Посторонним вход запрещен!"',
        ],
        correctAnswers: ['"Внимание! Пуск автоматический"'],
      },
      {
        code: "20204067",
        text: "Куда должны иметь выход системы замера дебита, контроля пуска, остановки скважин?",
        answers: [
          "На диспетчерский пункт",
          "На пульт насосной станции",
          "На пульт групповой замерной установки",
          "На центральный пульт нефтегазодобывающего управления (НГДУ)",
        ],
        correctAnswers: ["На диспетчерский пункт"],
      },
      {
        code: "20204068",
        text: "Какие показатели должны постоянно контролироваться в процессе проходки ствола скважины?",
        answers: [
          "Расход бурового раствора на входе и выходе из скважины и давление в манифольде буровых насосов",
          "Взаимное расположение стволов бурящейся и ранее пробуренных соседних скважин",
          "Азимут и зенитный угол ствола скважины",
          "Пространственное расположение ствола скважины и дифференциальное давление в системе скважина - пласт",
        ],
        correctAnswers: [
          "Расход бурового раствора на входе и выходе из скважины и давление в манифольде буровых насосов",
        ],
      },
      {
        code: "20204069",
        text: "Какие показатели должны контролироваться при бурении наклонно-направленных и горизонтальных скважин?",
        answers: [
          "Плотность, структурно-механические и реологические свойства бурового раствора и пространственное расположение ствола скважины",
          "Расход бурового раствора на входе и выходе из скважины, давление в манифольде буровых насосов и зенитный угол ствола скважины",
          "Азимут, зенитный угол ствола скважины, пространственное расположение ствола скважины, взаимное расположение стволов бурящейся и ранее пробуренных соседних скважин",
          "Крутящий момент на роторе при роторном способе бурения, давление в манифольде буровых насосов и азимут ствола скважины",
        ],
        correctAnswers: [
          "Азимут, зенитный угол ствола скважины, пространственное расположение ствола скважины, взаимное расположение стволов бурящейся и ранее пробуренных соседних скважин",
        ],
      },
      {
        code: "20204070",
        text: "В каком случае разрешается проводить спуско-подъемные операции?",
        answers: [
          "В случае неисправности спуско-подъемного оборудования и инструмента",
          "В случае скорости ветра, равной 15 м/с",
          "В случае отсутствия или неисправности ограничителя подъема талевого блока, ограничителя допускаемой нагрузки на крюке",
          "В случае неполного состава вахты для работ на конкретной установке",
          "В случае тумана и снегопада",
          "Во всех перечисленных случаях запрещается проводить спуско-подъемные операции",
        ],
        correctAnswers: ["В случае скорости ветра, равной 15 м/с"],
      },
      {
        code: "20204071",
        text: "Какое из приведенных действий допускается при проведении спуско-подъемных операций?",
        answers: [
          "Находиться в радиусе (зоне) действия автоматических и машинных ключей, рабочих и страховых канатов",
          "Открывать и закрывать элеватор только при полной остановке талевого блока",
          "Подавать бурильные свечи с подсвечника и устанавливать их без использования специальных приспособлений",
          "Пользоваться перевернутым элеватором",
        ],
        correctAnswers: [
          "Открывать и закрывать элеватор только при полной остановке талевого блока",
        ],
      },
      {
        code: "20204072",
        text: "С какой периодичностью буровая бригада должна проводить профилактический осмотр подъемного оборудования (лебедки, талевого блока, крюка, штропов, талевого каната, блокировок)?",
        answers: [
          "Каждую смену",
          "Каждый день",
          "Каждую неделю",
          "Каждый месяц",
        ],
        correctAnswers: ["Каждую смену"],
      },
      {
        code: "20204073",
        text: "Допускается ли отклонение от проектной величины плотности бурового раствора (освобожденного от газа), закачиваемого в скважину в процессе циркуляции?",
        answers: [
          "Допускается, но не более чем на 0,5 г/см³",
          "Допускается не более чем на 0,05 г/см³ только при закачивании отдельных порций утяжеленного раствора",
          "Допускается не более чем на 0,03 г/см³, кроме случаев ликвидации газонефтеводопроявлений и осложнений",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "Допускается не более чем на 0,03 г/см³, кроме случаев ликвидации газонефтеводопроявлений и осложнений",
        ],
      },
      {
        code: "20204074",
        text: "Чему должна быть равна расчетная продолжительность процесса цементирования обсадной колонны?",
        answers: [
          "Не должна превышать 95% времени начала загустевания тампонажного раствора по лабораторному анализу",
          "Не должна превышать 90% времени начала загустевания тампонажного раствора по лабораторному анализу",
          "Не должна превышать 75% времени начала загустевания тампонажного раствора по лабораторному анализу",
          "Должна быть равна времени загустевания тампонажного раствора",
        ],
        correctAnswers: [
          "Не должна превышать 75% времени начала загустевания тампонажного раствора по лабораторному анализу",
        ],
      },
      {
        code: "20204075",
        text: "Каким давлением необходимо опрессовывать цементировочную головку?",
        answers: [
          "Давлением, в 1,25 раза превышающим ожидаемое рабочее давление",
          "Давлением, в 1,5 раза превышающим максимальное расчетное рабочее давление",
          "Давлением, в 1,75 раза превышающим ожидаемое рабочее давление",
          "Давлением, в 2 раза превышающим максимальное расчетное рабочее давление",
        ],
        correctAnswers: [
          "Давлением, в 1,5 раза превышающим максимальное расчетное рабочее давление",
        ],
      },
      {
        code: "20204076",
        text: "С учетом каких параметров производятся выбор обсадных труб и расчет обсадных колонн на стадиях строительства и эксплуатации скважин?",
        answers: [
          "С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при частичном замещении раствора газожидкостной смесью, снижении уровня, а также осевых нагрузок на трубы",
          "С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при частичном замещении бурового раствора пластовым флюидом и агрессивности флюида",
          "С учетом минимальных ожидаемых наружных и внутренних давлений при полном замещении раствора пластовым флюидом, снижении уровня осевых нагрузок на трубы",
          "С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при полном замещении раствора пластовым флюидом или газожидкостной смесью, снижении уровня, а также осевых нагрузок на трубы и агрессивности флюида",
        ],
        correctAnswers: [
          "С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при полном замещении раствора пластовым флюидом или газожидкостной смесью, снижении уровня, а также осевых нагрузок на трубы и агрессивности флюида",
        ],
      },
      {
        code: "20204077",
        text: "Что является основной причиной возникновения газонефтеводопроявлений?",
        answers: [
          "Значительное повышение пластичности, снижение прочности пород, увлажнение термомеханического воздействия (колебаний противодавления и температуры массива)",
          "Упругое структурно-адсорбционное расширение пород стенок скважины, обусловленное их анизотропией, повышенной фильтрационной особенностью, всасыванием свободной воды и физико-механическим взаимодействием ее с частицами породы",
          "Поступление пластового флюида в ствол скважины вследствие превышения пластовым давлением забойного",
          "Образование толстых корок (отложение твердой фазы раствора) на стволе скважины при разрезе высокопроницаемых пород, интенсивно поглощающих жидкую фазу раствора",
        ],
        correctAnswers: [
          "Поступление пластового флюида в ствол скважины вследствие превышения пластовым давлением забойного",
        ],
      },
      {
        code: "20204078",
        text: "Какие действия включает в себя первая стадия защиты скважины при угрозе газонефтеводопроявления?",
        answers: [
          "Предотвращение поступления пластового флюида в скважину за счет использования гидростатического давления столба жидкости и противовыбросового оборудования",
          "Предотвращение притока пластового флюида в скважину за счет поддержания достаточного гидростатического давления столба жидкости",
          "Предотвращение поступления пластового флюида в скважину за счет снижения гидростатического давления столба раствора",
          "Ликвидация газонефтеводопроявлений стандартными методами",
        ],
        correctAnswers: [
          "Предотвращение притока пластового флюида в скважину за счет поддержания достаточного гидростатического давления столба жидкости",
        ],
      },
      {
        code: "20204079",
        text: "Какие из перечисленных действий необходимо осуществлять для предупреждения газонефтеводопроявлений и обвалов стенок в процессе подъема колонны бурильных труб?",
        answers: [
          "Долив бурового раствора в скважину",
          "Поддержание минимального гидростатического давления столба жидкости",
          "Поддержание в полной готовности оборудования, специальных приспособлений, инструментов и материалов на складах аварийного запаса предприятия",
          "Прокачка порций вязкой или утяжеленной смывающей жидкости",
          "Все ответы неверны",
        ],
        correctAnswers: ["Долив бурового раствора в скважину"],
      },
      {
        code: "20204080",
        text: "Что необходимо предпринять в процессе подъема колонны бурильных труб для предупреждения газонефтеводопроявлений?",
        answers: [
          "Следует производить долив бурового раствора в скважину",
          "Следует обеспечить большую скорость восходящего потока жидкости в кольцевом пространстве",
          "Следует ввести в промывочную жидкость смазывающие добавки",
          "Следует ввести промывочные жидкости, инертные по отношению к горным породам",
        ],
        correctAnswers: [
          "Следует производить долив бурового раствора в скважину",
        ],
      },
      {
        code: "20204081",
        text: "Какой должна быть высота подъема тампонажного раствора над кровлей продуктивных горизонтов, а также устройством ступенчатого цементирования или узлом соединения верхних секций обсадных колонн в нефтяных и газовых скважинах?",
        answers: [
          "Не менее 100 и 440 м соответственно",
          "Не менее 130 и 480 м соответственно",
          "Не менее 110 и 460 м соответственно",
          "Не менее 150 и 500 м соответственно",
        ],
        correctAnswers: ["Не менее 150 и 500 м соответственно"],
      },
      {
        code: "20204082",
        text: "Какова минимальная высота подъема тампонажного раствора над устройством ступенчатого цементирования или узлом соединения верхних секций обсадных колонн в газовых скважинах?",
        answers: ["180 м", "350 м", "500 м", "1000 м"],
        correctAnswers: ["500 м"],
      },
      {
        code: "20204083",
        text: "В каком случае запрещается производить спуск технических и эксплуатационных колонн в скважину?",
        answers: [
          "Если скважина осложнена поглощениями бурового раствора с одновременным флюидопроявлением",
          "Если скважина осложнена осыпями и обвалами",
          "Если скважина осложнена затяжками и посадками бурильной колонны",
          "Спуск технических и эксплуатационных колонн во всех перечисленных случаях запрещен до ликвидации осложнений",
        ],
        correctAnswers: [
          "Спуск технических и эксплуатационных колонн во всех перечисленных случаях запрещен до ликвидации осложнений",
        ],
      },
      {
        code: "20204084",
        text: "Исходя из каких требований производится выбор манометров для установки на блоках дросселирования и глушения?",
        answers: [
          "Манометры должны иметь верхний предел диапазона измерений, равный давлению совместной опрессовки обсадной колонны и противовыбросового оборудования",
          "Манометры должны иметь верхний предел диапазона измерений, равный двукратному давлению совместной опрессовки",
          "Манометры должны иметь верхний предел диапазона измерений, на 30% превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования",
          "Класс точности манометра должен быть не ниже 1,5, а верхний предел диапазона измерений не менее 40 МПа (400 кгс/см2)",
        ],
        correctAnswers: [
          "Манометры должны иметь верхний предел диапазона измерений, на 30% превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования",
        ],
      },
      {
        code: "20204085",
        text: "Какое количество шаровых кранов должно быть установлено при вскрытии газовых пластов с аномально высоким давлением, сероводородсодержащих горизонтов на буровой установке?",
        answers: [
          "Один, устанавливаемый между рабочей трубой и ее предохранительным переводником",
          "Два, один из которых устанавливается между рабочей трубой и вертлюгом, второй - между рабочей трубой и ее предохранительным переводником",
          "Два, один из которых устанавливается между рабочей трубой и предохранительным переводником, второй является запасным",
          "Три, один из которых устанавливается между рабочей трубой и вертлюгом, второй - между рабочей трубой и ее предохранительным переводником, третий является запасным",
        ],
        correctAnswers: [
          "Три, один из которых устанавливается между рабочей трубой и вертлюгом, второй - между рабочей трубой и ее предохранительным переводником, третий является запасным",
        ],
      },
      {
        code: "20204086",
        text: "Кем устанавливается периодичность проверки плашечных превенторов на закрытие и открытие?",
        answers: [
          "Буровым предприятием",
          "Пользователем недр",
          "Противофонтанной службой",
          "Проектной организацией",
        ],
        correctAnswers: ["Буровым предприятием"],
      },
      {
        code: "20204087",
        text: "Кто может быть допущен к работам на скважинах с возможными газонефтеводопроявлениями?",
        answers: [
          "Допускаются бурильщики, прошедшие производственное обучение, инструктаж, получившие допуск к самостоятельной работе",
          "Допускаются бурильщики, прошедшие специальное обучение, сдавшие экзамен по правилам безопасности в нефтегазодобывающей промышленности и имеющие соответствующее удостоверение",
          'Допускаются бурильщики и специалисты, прошедшие подготовку и проверку знаний по курсу "Контроль скважины. Управление скважиной при газонефтеводопроявлениях" в специализированных учебных центрах',
          "Допускаются бурильщики, обученные по специальной программе, аттестованные квалификационной комиссией организации",
        ],
        correctAnswers: [
          'Допускаются бурильщики и специалисты, прошедшие подготовку и проверку знаний по курсу "Контроль скважины. Управление скважиной при газонефтеводопроявлениях" в специализированных учебных центрах',
        ],
      },
      {
        code: "20204088",
        text: 'С какой периодичностью проводится переподготовка специалистов по курсу "Контроль скважины. Управление скважиной при газонефтеводопроявлении" в специализированных учебных центрах (комбинатах)?',
        answers: [
          "1 раз в год",
          "1 раз в 2 года",
          "1 раз в 3 года",
          "1 раз в 5 лет",
          "Все ответы неверны",
        ],
        correctAnswers: ["1 раз в 3 года"],
      },
      {
        code: "20204089",
        text: "Какие меры из перечисленных входят в комплекс работ по освоению скважин?",
        answers: [
          'Предупреждение прорыва пластовой воды и газа из газовой "шапки" и термогидрогазодинамические исследования по определению количественной и качественной характеристик пласта и его геологофизических параметров',
          "Сохранение, восстановление или повышение проницаемости призабойной зоны и предотвращение неконтролируемых газонефтеводопроявлений и открытых фонтанов",
          "Исключение закупорки пласта при вторичном вскрытии и сохранение скелета пласта в призабойной зоне",
          "В комплекс работ по освоению скважин входят все перечисленные меры",
        ],
        correctAnswers: [
          "В комплекс работ по освоению скважин входят все перечисленные меры",
        ],
      },
      {
        code: "20204090",
        text: "Какой документ является основным для производства буровых работ?",
        answers: [
          "Градостроительный кодекс Российской Федерации",
          "Проект обустройства месторождения углеводородов",
          "Рабочий проект на производство буровых работ",
        ],
        correctAnswers: ["Рабочий проект на производство буровых работ"],
      },
      {
        code: "20204091",
        text: "Рабочий проект на производство буровых работ разрабатывается на отдельную скважину или на группу скважин?",
        answers: [
          "Рабочий проект на производство буровых работ разрабатывается только на отдельную скважину",
          "Рабочий проект на производство буровых работ разрабатывается на бурение отдельной скважины или на группу скважин, расположенных на одной кустовой площадке или одном месторождении, площади",
          "Рабочий проект на производство буровых работ может разрабатываться на группу скважин, расположенных на одном кусте, если есть опыт бурения на данном месторождении",
        ],
        correctAnswers: [
          "Рабочий проект на производство буровых работ разрабатывается на бурение отдельной скважины или на группу скважин, расположенных на одной кустовой площадке или одном месторождении, площади",
        ],
      },
      {
        code: "20204092",
        text: "Кто принимает оперативные решения по отклонению от параметров, предусмотренных в рабочем проекте, при возникновении в процессе производства буровых работ осложнений (газонефтепроявление, поглощения, обвалы и др.)?",
        answers: [
          "Руководство эксплуатирующей организации (заказчик)",
          "Проектная организация",
          "Буровой подрядчик с последующим уведомлением заказчика",
        ],
        correctAnswers: [
          "Буровой подрядчик с последующим уведомлением заказчика",
        ],
      },
      {
        code: "20204093",
        text: "Какие требования предъявляются к условиям установки подвесного и герметизирующего устройства потайной колонны?",
        answers: [
          "Требования не предъявляются",
          "Требования к условиям установки подвесного и герметизирующего устройства потайной колонны (хвостовика) определяется расчетным путем при проектировании конструкции скважины",
          "Подвесное и герметизирующее устройство потайной колонны должно устанавливаться выше башмака предыдущей обсадной колонны не менее чем на 75 м для нефтяных скважин и 250 м для газовых скважин",
        ],
        correctAnswers: [
          "Подвесное и герметизирующее устройство потайной колонны должно устанавливаться выше башмака предыдущей обсадной колонны не менее чем на 75 м для нефтяных скважин и 250 м для газовых скважин",
        ],
      },
      {
        code: "20204094",
        text: "Кто устанавливает порядок организации, проведения планового ремонта и обслуживания бурового и энергетического оборудования?",
        answers: [
          "Организация-заказчик с учетом инструкций по эксплуатации, предоставляемых производителем продукции",
          "Буровая организация с учетом инструкций по эксплуатации, предоставляемых производителем продукции",
          "Служба главного энергетика совместно с буровой организацией",
        ],
        correctAnswers: [
          "Буровая организация с учетом инструкций по эксплуатации, предоставляемых производителем продукции",
        ],
      },
      {
        code: "20204095",
        text: "Кто устанавливает нормативные сроки наработки, виды инспекций и дефектоскопии для бурильных труб, ведущих, утяжеленных бурильных труб, переводников, опорно-центрирующих и других элементов бурильной колонны?",
        answers: [
          "Разработчики-проектанты в рабочем проекте производства буровых работ",
          "Эксплуатирующая организация по согласованию с надзорными органами",
          "Эксплуатирующая организация в соответствии с технической документацией завода-изготовителя",
        ],
        correctAnswers: [
          "Эксплуатирующая организация в соответствии с технической документацией завода-изготовителя",
        ],
      },
      {
        code: "20204096",
        text: "Какие ограничения предусмотрены для перфорации обсадных колонн при проведении ремонтно-изоляционных работ в процессе проводки ствола скважины?",
        answers: [
          "Ограничения не предусмотрены",
          "Запрещается перфорация обсадных колонн при проведении ремонтно-изоляционных работ в интервале проницаемых горизонтов",
          "Запрещается перфорация обсадных колонн при проведении ремонтно-изоляционных работ в интервале возможного разрыва пласта давлением газа, нефти (после вызова их притока) или столба бурового раствора",
        ],
        correctAnswers: [
          "Запрещается перфорация обсадных колонн при проведении ремонтно-изоляционных работ в интервале возможного разрыва пласта давлением газа, нефти (после вызова их притока) или столба бурового раствора",
        ],
      },
      {
        code: "20204097",
        text: "Консервация скважин в процессе бурения осуществляется в соответствии с инструкцией о порядке ликвидации, консервации скважин и оборудования, их устьев и стволов. Предусмотрены ли Правилами дополнительные работы при консервации скважин в процессе бурения, кроме требований инструкции?",
        answers: [
          "Да",
          "Нет",
          "Предусмотрены, но только в случае нестандартных ситуаций",
        ],
        correctAnswers: ["Да"],
      },
      {
        code: "20204098",
        text: "С какой периодичностью проводится профилактический осмотр подъемного оборудования (лебедки, талевого блока, крюка, крюкоблока, вертлюга, штропов, талевого каната и устройств для его крепления, элеваторов, спайдеров, предохранительных устройств, блокировок и другого оборудования)?",
        answers: [
          "Ежедневно",
          "Еженедельно",
          "Ежесменно с записью в журнале проверки оборудования",
        ],
        correctAnswers: ["Ежесменно с записью в журнале проверки оборудования"],
      },
      {
        code: "20204099",
        text: "Можно ли повышать плотность бурового раствора, находящегося в скважине?",
        answers: [
          "Повышать плотность бурового раствора, находящегося в скважине, путем закачивания отдельных порций утяжеленного раствора разрешается",
          "Повышать плотность бурового раствора, находящегося в скважине, путем закачивания отдельных порций утяжеленного раствора запрещается",
          "Правилами не регламентируется",
        ],
        correctAnswers: [
          "Повышать плотность бурового раствора, находящегося в скважине, путем закачивания отдельных порций утяжеленного раствора запрещается",
        ],
      },
      {
        code: "20204100",
        text: "Требованиям какого документа должны соответствовать свойства тампонажных материалов и формируемого из них цементного камня?",
        answers: [
          "Свойства тампонажных материалов и формируемого из них цементного камня должны соответствовать требованиям стандартов",
          "Свойства тампонажных материалов и формируемого из них цементного камня должны соответствовать требованиям инструкций завода-изготовителя",
          "Свойства тампонажных материалов и формируемого из них цементного камня должны соответствовать требованиям рабочего проекта",
        ],
        correctAnswers: [
          "Свойства тампонажных материалов и формируемого из них цементного камня должны соответствовать требованиям рабочего проекта",
        ],
      },
      {
        code: "20204101",
        text: "Необходим ли лабораторный анализ компонентов тампонажной смеси для условий предстоящего цементирования колонны?",
        answers: [
          "Лабораторному анализу подвергается 5% от одной из партий тампонажной смеси, поступившей на буровую установку. Предварительный лабораторный анализ для условий предстоящего цементирования не проводится",
          "В лабораторном анализе тампонажной смеси для условий предстоящего цементирования колонны нет необходимости",
          "Применение компонентов тампонажной смеси без проведения предварительного лабораторного анализа для условий предстоящего цементирования колонны запрещается",
        ],
        correctAnswers: [
          "Применение компонентов тампонажной смеси без проведения предварительного лабораторного анализа для условий предстоящего цементирования колонны запрещается",
        ],
      },
      {
        code: "20204102",
        text: "На какое давление должны опрессовываться нагнетательные трубопроводы для цементирования ствола скважины?",
        answers: [
          "В 1,25 раза превышающее максимальное расчетное рабочее давление при цементировании скважины",
          "В 1,5 раза превышающее ожидаемое рабочее давление при цементировании скважины",
          "На максимальное расчетное рабочее давление при цементировании скважины",
        ],
        correctAnswers: [
          "В 1,5 раза превышающее ожидаемое рабочее давление при цементировании скважины",
        ],
      },
      {
        code: "20204103",
        text: "Представители каких организаций включаются в обязательном порядке в комиссию при испытании колонны на герметичность?",
        answers: [
          "Представители заказчика и надзорных органов",
          "Представители буровой организации и природоохранных организаций",
          "Представитель заказчика",
        ],
        correctAnswers: ["Представитель заказчика"],
      },
      {
        code: "20204104",
        text: "Кем разрабатываются инструкции по монтажу и эксплуатации противовыбросового оборудования (далее - ПВО)?",
        answers: [
          "Инструкции по монтажу и эксплуатации ПВО разрабатываются заводом-изготовителем",
          "Инструкции по монтажу и эксплуатации ПВО разрабатываются заказчиком (пользователем недр) и согласовываются с Ростехнадзором",
          "Инструкции по монтажу и эксплуатации ПВО разрабатываются буровой организацией в соответствии с применяемым оборудованием, технологией ведения работ и инструкциями по монтажу, техническому обслуживанию, эксплуатации и ремонту изготовителей",
        ],
        correctAnswers: [
          "Инструкции по монтажу и эксплуатации ПВО разрабатываются буровой организацией в соответствии с применяемым оборудованием, технологией ведения работ и инструкциями по монтажу, техническому обслуживанию, эксплуатации и ремонту изготовителей",
        ],
      },
      {
        code: "20204105",
        text: "Каким канатом должен быть обмотан промывочный шланг?",
        answers: [
          "Стальным мягким канатом диаметром не менее 8 мм с петлями через каждые 1,0 - 1,5 м по всей длине шланга",
          "Стальным мягким канатом диаметром не менее 4 мм с петлями через каждые 2,5 м по всей длине шланга",
          "Стальным мягким канатом диаметром не менее 5 мм с петлями через каждые 2,5 м по всей длине шланга",
          "Стальным мягким канатом диаметром не менее 7 мм с петлями через каждые 2 м по всей длине шланга",
        ],
        correctAnswers: [
          "Стальным мягким канатом диаметром не менее 8 мм с петлями через каждые 1,0 - 1,5 м по всей длине шланга",
        ],
      },
      {
        code: "20204106",
        text: "На какое давление должна быть опрессована скважина после установки противовыбросового оборудования?",
        answers: [
          "На максимально ожидаемое давление, но не выше давления опрессовки эксплуатационной колонны",
          "На минимально ожидаемое давление, но не ниже давления опрессовки эксплуатационной колонны",
          "На максимально ожидаемое давление, но не ниже давления опрессовки эксплуатационной колонны",
          "На давление, равное давлению опрессовки эксплуатационной колонны",
        ],
        correctAnswers: [
          "На максимально ожидаемое давление, но не выше давления опрессовки эксплуатационной колонны",
        ],
      },
      {
        code: "20204107",
        text: "Кем производится пуск в работу смонтированной установки и оборудования?",
        answers: [
          "Комиссией, состав и порядок работы которой устанавливается эксплуатирующей организацией",
          "Двумя рабочими бригады, прошедшими соответствующий инструктаж, при условии, что один из них имеет квалификационную группу не ниже второй",
          "Двумя рабочими: электромонтером и специалистом по охране труда эксплуатирующей организации",
          "Двумя рабочими: электромонтером и рабочим бригады",
        ],
        correctAnswers: [
          "Комиссией, состав и порядок работы которой устанавливается эксплуатирующей организацией",
        ],
      },
      {
        code: "20204108",
        text: "Кто выбирает тип ПВО и колонной головки, схему установки и обвязки ПВО, блоков глушения и дросселирования?",
        answers: [
          "Буровая организация при согласовании с противофонтанной службой",
          "Заказчик при согласовании с Ростехнадзором",
          "Проектная организация при согласовании с заказчиком",
        ],
        correctAnswers: ["Проектная организация при согласовании с заказчиком"],
      },
      {
        code: "20204109",
        text: "Какой должна быть длина линий сбросов на факелы от блоков глушения и дросселирования?",
        answers: [
          "Не нормируется",
          "Для нефтяных скважин:  - с газовым фактором менее 200 м3/т - не менее 30 м  - с газовым фактором более 200 м3/т - не менее 100 м  Для всех поисково-оценочных и разведочных скважин - не менее 50 м",
          "Для нефтяных скважин: - с газовым фактором менее 300 м3/т - не менее 50 м - с газовым фактором более 300 м3/т - не менее 200 м",
        ],
        correctAnswers: [
          "Для нефтяных скважин:  - с газовым фактором менее 200 м3/т - не менее 30 м  - с газовым фактором более 200 м3/т - не менее 100 м  Для всех поисково-оценочных и разведочных скважин - не менее 50 м",
        ],
      },
      {
        code: "20204110",
        text: "Какой должна быть длина линий сбросов на факелы от блоков глушения и дросселирования для скважин, сооружаемых с насыпного основания и ограниченных площадок?",
        answers: [
          "Определяется проектной документацией",
          "Такая же, как для нефтяных скважин на равнине: - с газовым фактором менее 200 м3/т - не менее 30 м - с газовым фактором более 200 м3/т - не менее 100 м",
          "Устанавливается подрядчиком по согласованию с заказчиком",
        ],
        correctAnswers: [
          "Устанавливается подрядчиком по согласованию с заказчиком",
        ],
      },
      {
        code: "20204111",
        text: "Какое необходимое количество шаровых кранов на буровой установке при вскрытии коллекторов, насыщенных нефтью и газом?",
        answers: ["Один", "Два", "Три", "Четыре"],
        correctAnswers: ["Два"],
      },
      {
        code: "20204112",
        text: "Какое необходимое количество шаровых кранов на буровой при вскрытии газовых пластов с аномально высоким давлением сероводородсодержащих горизонтов?",
        answers: ["Один", "Два", "Три", "Четыре"],
        correctAnswers: ["Три"],
      },
      {
        code: "20204113",
        text: "Какая техническая документация должна быть на буровой установке на краны шаровые и клапаны обратные?",
        answers: [
          "Руководство по эксплуатации",
          "Инструкция с указанием технических характеристик кранов шаровых и клапанов обратных",
          "Технические паспорта и сведения о проведении дефектоскопии",
        ],
        correctAnswers: [
          "Технические паспорта и сведения о проведении дефектоскопии",
        ],
      },
      {
        code: "20204114",
        text: "С какой частотой проводится опрессовка кранов шаровых и клапанов обратных?",
        answers: [
          "1 раз в год и перед каждыми спецработами",
          "Перед установкой и далее 1 раз в 3 месяца",
          "1 раз в 6 месяцев",
        ],
        correctAnswers: ["1 раз в 6 месяцев"],
      },
      {
        code: "20204115",
        text: "С какой периодичностью превенторы должны проверяться на закрытие и открытие?",
        answers: [
          "Ежесменно перед началом смены",
          "1 раз в 3 месяца и перед началом спецработ",
          "Периодичность проверки устанавливается буровой организацией, но не реже 1 раза в месяц",
        ],
        correctAnswers: [
          "Периодичность проверки устанавливается буровой организацией, но не реже 1 раза в месяц",
        ],
      },
      {
        code: "20204116",
        text: "В каких случаях следует производить долив бурового раствора в скважину?",
        answers: [
          "При спуско-подъемных операциях",
          "В процессе подъема колонны бурильных труб для предупреждения газонефтеводопроявлений и обвалов стенок скважин",
          "При обнаружении газонефтеводопроявлений",
        ],
        correctAnswers: [
          "В процессе подъема колонны бурильных труб для предупреждения газонефтеводопроявлений и обвалов стенок скважин",
        ],
      },
      {
        code: "20204117",
        text: "В каких случаях производится контроль бурового раствора на газонасыщенность?",
        answers: [
          "При переливе или при увеличении количества бурового раствора",
          "Во всех случаях при работе с буровым раствором",
          "Перед и после вскрытия пластов с аномально высоким пластовым давлением",
          "При вскрытии газоносных горизонтов и дальнейшем углублении скважины",
        ],
        correctAnswers: [
          "При вскрытии газоносных горизонтов и дальнейшем углублении скважины",
        ],
      },
      {
        code: "20204118",
        text: "Каким документом определяются типы резьбовых соединений и резьбовых смазок, применяемых в интервалах интенсивного искривления ствола в конструкциях скважин?",
        answers: [
          "Стандартами буровой организации",
          "Техническими регламентами",
          "Рабочим проектом на производство буровых работ",
        ],
        correctAnswers: ["Рабочим проектом на производство буровых работ"],
      },
      {
        code: "20204119",
        text: "Каким документом устанавливаются требования к технологии и порядок проведения перфорации продуктивного пласта при сниженном уровне или в среде, отличающейся от установленных требований?",
        answers: [
          "Стандартами буровой организации, согласованными с противофонтанной службой (противофонтанной военизированной частью)",
          "Рабочим проектом на производство буровых работ",
          "Специальным планом, утвержденным организацией, эксплуатирующей ОПО (заказчиком), и согласованным ПАСФ",
        ],
        correctAnswers: [
          "Специальным планом, утвержденным организацией, эксплуатирующей ОПО (заказчиком), и согласованным ПАСФ",
        ],
      },
      {
        code: "20204120",
        text: "Каковы требования к производству работ по глубинным измерениям в скважинах с избыточным давлением на устье?",
        answers: [
          "Производятся в соответствии с требованиями заказчика",
          "Производятся с применением утяжеленного бурового раствора",
          "Производятся с применением лубрикатора, опрессованного на рабочее давление, установленное изготовителем, а после установки - на давление опрессовки колонны",
        ],
        correctAnswers: [
          "Производятся с применением лубрикатора, опрессованного на рабочее давление, установленное изготовителем, а после установки - на давление опрессовки колонны",
        ],
      },
      {
        code: "20204121",
        text: "Кем устанавливается порядок проведения работ на кустовых площадках при совмещении во времени различных по характеру работ (бурение, освоение, эксплуатация, монтаж нефтегазодобывающего оборудования и т.д.)?",
        answers: [
          "Министерством природных ресурсов и экологии Российской Федерации",
          "Ростехнадзором",
          "Пользователем недр (заказчиком) или его представителем",
          "Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
        ],
        correctAnswers: [
          "Пользователем недр (заказчиком) или его представителем",
        ],
      },
      {
        code: "20204122",
        text: "В каких случаях не устанавливается превенторная сборка при бурении скважин для разведки и добычи метана угольных месторождений (площадей, участков)?",
        answers: [
          "В случаях, предусмотренных в рабочем проекте",
          "В случае принятия такого решения буровой организацией",
          "В случае вскрытия изученного разреза с аномально низким пластовым давлением (АНПД), представленного обводненными угольными пластами",
        ],
        correctAnswers: [
          "В случае вскрытия изученного разреза с аномально низким пластовым давлением (АНПД), представленного обводненными угольными пластами",
        ],
      },
      {
        code: "20204123",
        text: "Какое допускается отклонение плотности бурового раствора (освобожденного от газа), находящегося в циркуляции, от установленной проектом величины?",
        answers: [
          "Не более чем на 0,25 г/см³",
          "Не более чем на 0,02 г/см³",
          "Не более чем на 0,03 г/см³",
          "Не более чем на 0,05 г/см³",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не более чем на 0,02 г/см³"],
      },
      {
        code: "20204124",
        text: "С кем согласовывается схема обвязки противовыбросового оборудования?",
        answers: [
          "С территориальными органами Ростехнадзора",
          "С профессиональной противофонтанной службой, обслуживающей данный объект",
          "С руководителем собственной профессиональной аварийно-спасательной службы (формирования)",
          "Со всеми перечисленными службами и лицами",
          "Все ответы неверны",
        ],
        correctAnswers: ["С территориальными органами Ростехнадзора"],
      },
      {
        code: "20204125",
        text: " В каком месте устанавливается основной пульт для управления превенторами и гидравлическими задвижками?",
        answers: [
          "На расстоянии не менее 10 м от устья скважины в удобном и безопасном месте",
          "Непосредственно возле пульта бурильщика",
          "На задвижке перед дросселем",
          "В любом из перечисленных мест",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "На расстоянии не менее 10 м от устья скважины в удобном и безопасном месте",
        ],
      },
      {
        code: "20204126",
        text: "Сколько должно быть шаровых кранов на буровой при вскрытии газовых пластов с аномально высоким давлением и сероводородсодержащих пластов?",
        answers: [
          "3",
          "2",
          "2 шаровых крана, сдвоенных с обратными клапанами",
          "1",
          "Все ответы неверны",
        ],
        correctAnswers: ["3"],
      },
      {
        code: "20204127",
        text: "При обеспечении какими условиями могут быть начаты работы по освоению и испытанию скважин?",
        answers: [
          "Эксплуатационная колонна прошаблонирована, опрессована совместно с колонной головкой и превенторной установкой, герметична при максимально ожидаемом давлении на устье скважины",
          "Устье с превенторной установкой, манифольдный блок и выкидные линии оборудованы и обвязаны в соответствии с утвержденной схемой",
          "Высота подъема цементного раствора за эксплуатационной колонной и качество сформировавшейся крепи отвечают проекту и требованиям охраны недр",
          "Всеми перечисленными",
        ],
        correctAnswers: ["Всеми перечисленными"],
      },
      {
        code: "20204128",
        text: "В соответствии с чем должно производиться освоение скважин в кусте, независимо от способа их последующей эксплуатации?",
        answers: [
          "В соответствии с планом работ, утвержденным техническим руководителем предприятия и согласованным с заказчиком",
          "В соответствии с проектной документацией",
          "В соответствии с планом работ, согласованным с территориальным органом Ростехнадзора",
          "В соответствии с Положением о порядке организации одновременного ведения работ по бурению, освоению, вскрытию дополнительных продуктивных отложений, эксплуатации и ремонту скважин на кустовой площадке",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "В соответствии с планом работ, утвержденным техническим руководителем предприятия и согласованным с заказчиком",
        ],
      },
      {
        code: "20204129",
        text: "Кем должны проводиться работы по ремонту скважин?",
        answers: [
          "Специализированной бригадой по плану, утвержденному техническим руководителем предприятия",
          "Буровыми организациями",
          "Эксплуатирующей организацией под контролем территориального органа Ростехнадзора",
          "Проектными организациями",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Специализированной бригадой по плану, утвержденному техническим руководителем предприятия",
        ],
      },
      {
        code: "20204130",
        text: "Что из перечисленного запрещается при освоении, эксплуатации и ремонте скважин?",
        answers: [
          "Одновременная работа двух специализированных бригад по ремонту или освоению скважин на одной кустовой площадке",
          "Установка специальной техники на трассах газопроводов газлифта",
          "Демонтаж буровой установки с кустовой площадки, транспортировка ее блоков и узлов при остановке скважин, находящихся в опасной зоне",
          "Ремонт скважин без остановки соседних скважин при условии разработки и реализации специальных мероприятий, исключающих возможность опасного воздействия на работающие скважины",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Установка специальной техники на трассах газопроводов газлифта",
        ],
      },
      {
        code: "20204131",
        text: "Какое требование при освоении, эксплуатации и ремонте скважин указано верно?",
        answers: [
          "Колонные фланцы на устьях скважин должны быть расположены на разных уровнях от поверхности кустовой площадки",
          "При освоении скважин с использованием инертных газов с помощью передвижного компрессора последний должен устанавливаться на расстоянии не менее 15 м от устья скважины",
          "Допускается использование временных схем сбора и транспорта нефти в соответствии с проектом",
          "С вводом в эксплуатацию первой скважины на кусте должен быть установлен порядок контроля загазованности воздушной среды всей территории кустовой площадки. Разработка графика, определение места отбора проб и порядок контроля осуществляются представителем пользователя недр (заказчиком). Реализация этого контроля возлагается на ответственного руководителя работ на кустовой площадке",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "С вводом в эксплуатацию первой скважины на кусте должен быть установлен порядок контроля загазованности воздушной среды всей территории кустовой площадки. Разработка графика, определение места отбора проб и порядок контроля осуществляются представителем пользователя недр (заказчиком). Реализация этого контроля возлагается на ответственного руководителя работ на кустовой площадке",
        ],
      },
      {
        code: "20204132",
        text: "Что может быть причиной возникновения и развития газонефтеводопроявлений из-за неуравновешенности пластового давления гидростатическим давлением столба раствора в стволе скважины?",
        answers: [
          "Разгазирование раствора в призабойной части вследствие длительных простоев скважины без промывок",
          "Недостаточный оперативный контроль за текущими изменениями пластовых давлений вследствие законтурного заводнения и других факторов",
          "Разрушение обратных клапанов бурильных или обсадных колонн в процессе их спуска",
          "Использование бурового раствора или жидкости глушения скважины с заниженной плотностью",
          "Все перечисленное",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20204133",
        text: "Что может служить причиной возникновения открытых фонтанов?",
        answers: [
          "Низкое качество монтажа противовыбросового оборудования, несоблюдение установленных условий его эксплуатации",
          "Некачественное цементирование обсадных колонн",
          "Отсутствие в компоновке бурильной колонны шарового крана или обратного клапана",
          "Несоответствие размера плашек превентора диаметру спускаемых (поднимаемых) труб. Срыв плашек превентора при расхаживании колонны труб",
          "Все перечисленное",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20204134",
        text: "Какие могут быть признаки возникновения и развития газонефтеводопроявлений?",
        answers: [
          "Снижение уровня столба раствора в скважине при технологических остановках или простоях",
          "Уменьшение объема (уровня) раствора в приемных емкостях при бурении или проведении спуско-подъемных операций",
          "Уменьшение расхода (скорости) выходящего из скважины потока бурового раствора",
          "Повышение плотности бурового раствора",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Снижение уровня столба раствора в скважине при технологических остановках или простоях",
        ],
      },
      {
        code: "20204135",
        text: "Сколько стадий (линий) защиты должен включать контроль за скважиной?",
        answers: ["2", "3", "4", "5", "Все ответы неверны"],
        correctAnswers: ["3"],
      },
      {
        code: "20204136",
        text: "Каким образом допускается заглушать скважину перед проведением текущих и капитальных ремонтов на месторождениях с горно-геологическими условиями, исключающими возможность самопроизвольного поступления пластового флюида к устью скважины?",
        answers: [
          "Выполнение ремонтов допускается без предварительного глушения скважин",
          "Используя жидкость повышенной вязкости",
          "Используя жидкость необходимой плотности",
          "Используя жидкость, содержащую нейтрализатор сероводорода",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Выполнение ремонтов допускается без предварительного глушения скважин",
        ],
      },
      {
        code: "20204137",
        text: "До какого уровня должно быть снижено давление в трубном и затрубном пространствах скважины перед разборкой устьевой арматуры?",
        answers: [
          "До атмосферного",
          "До 0,1 кПа",
          "До 0,2 кПа",
          "До 0,5 кПа",
          "Все ответы неверны",
        ],
        correctAnswers: ["До атмосферного"],
      },
      {
        code: "20204138",
        text: "Какие требования при строительстве скважин на континентальном шельфе с использованием плавучих буровых установок, полупогружных плавучих буровых установок, морских стационарных платформ и т.п. указаны неверно?",
        answers: [
          "Линии дросселирования могут быть выполнены с поворотами",
          "Повороты линий глушения допускается выполнять из армированных резиновых шлангов высокого давления, изготовленных в соответствии с прочностной характеристикой превенторной установки, рассчитанной на максимальное давление, ожидаемое на устье",
          "Выкидные линии должны быть прочно прикреплены к элементам платформы, расстояние между опорами не должно превышать 10 м",
          "Блок подводного противовыбросового оборудования должен быть испытан на рабочее давление",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Выкидные линии должны быть прочно прикреплены к элементам платформы, расстояние между опорами не должно превышать 10 м",
        ],
      },
      {
        code: "20204139",
        text: "Какой сигнал подается при появлении признаков поступления пластового флюида в скважину (перелив бурового раствора, увеличение его объема в емкостях, несоответствие расчетного и фактического объемов доливаемого (вытесняемого) раствора при СПО)?",
        answers: [
          '"Выброс"',
          '"Внимание"',
          '"Опасно"',
          '"Разгерметизация"',
          "Все ответы неверны",
        ],
        correctAnswers: ['"Выброс"'],
      },
    ],
    20205: [
      {
        code: "20205000",
        text: "На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?",
        answers: [
          "На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на выделенной территории находящихся в эксплуатации опасных производственных объектов",
          "На ведение газоопасных, огневых и ремонтных работ на объектах электроэнергетики",
          "На ведение газоопасных, огневых и ремонтных работ на объектах металлургической промышленности",
          "На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы",
        ],
        correctAnswers: [
          "На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы",
        ],
      },
      {
        code: "20205001",
        text: "Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?",
        answers: [
          "Прохождение медицинского осмотра в соответствии с требованиями законодательства Российской Федерации",
          "Возраст не менее 18 лет",
          "Прохождение обучения приемам и методам проведения работ",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20205002",
        text: "Что из перечисленного должно быть учтено при разработке документов (стандартов, инструкций), уточняющих и конкретизирующих требования к ведению газоопасных, огневых и ремонтных работ на опасных производственных объектах? Выберите два правильных варианта ответа.",
        answers: [
          "Технологическое оборудование, предназначенное для эксплуатации",
          "Взрывопожароопасные показатели обращающихся в технологическом процессе опасных веществ",
          "Безопасные методы и приемы ведения газоопасных, огневых и ремонтных работ",
          "Физико-химические свойства обращающихся в технологическом процессе опасных веществ",
        ],
        correctAnswers: [
          "Взрывопожароопасные показатели обращающихся в технологическом процессе опасных веществ",
          "Физико-химические свойства обращающихся в технологическом процессе опасных веществ",
        ],
      },
      {
        code: "20205003",
        text: "На сколько групп подразделяются газоопасные работы в зависимости от степени опасности и на основании каких критериев устанавливается та или иная группа?",
        answers: [
          "На 2: газоопасные работы, проводимые с оформлением наряда-допуска или без оформления",
          "На 3 - в зависимости от степени риска проводимых работ",
          "На 2 - в зависимости от того, проводятся работы механизированным или автоматизированным способом",
        ],
        correctAnswers: [
          "На 2: газоопасные работы, проводимые с оформлением наряда-допуска или без оформления",
        ],
      },
      {
        code: "20205004",
        text: "К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?",
        answers: [
          "К II группе",
          "К I группе",
          "К группе работ средней степени опасности",
          "К группе работ повышенной степени опасности",
        ],
        correctAnswers: ["К II группе"],
      },
      {
        code: "20205005",
        text: "Кто разрабатывает перечень газоопасных работ?",
        answers: [
          "Каждое структурное подразделение эксплуатирующей организации",
          "Служба производственного контроля эксплуатирующей организации",
          "Профессиональная аварийно-спасательная служба (формирование) (ПАСС(Ф)",
          "Специализированная подрядная организация",
        ],
        correctAnswers: [
          "Каждое структурное подразделение эксплуатирующей организации",
        ],
      },
      {
        code: "20205006",
        text: "Кто определяет структурные подразделения, на которые возложены полномочия по согласованию перечня газоопасных работ?",
        answers: [
          "Технический руководитель эксплуатирующей организации",
          "Руководитель службы производственного контроля или лицо, ответственное за осуществление производственного контроля",
          "Руководитель эксплуатирующей организации",
          "Руководитель профессиональной аварийно-спасательной службы",
        ],
        correctAnswers: ["Руководитель эксплуатирующей организации"],
      },
      {
        code: "20205007",
        text: "С какой периодичностью необходимо пересматривать и переутверждать перечень газоопасных работ?",
        answers: [
          "Не реже одного раза в год",
          "Не реже одного раза в 2 года",
          "Не реже одного раза в 3 года",
          "Не реже одного раза в 5 лет",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20205008",
        text: "Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
          "По распоряжению на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ",
          "Запрещается выполнять газоопасные работы, не включенные в утвержденный перечень газоопасных работ",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
      {
        code: "20205009",
        text: "Где регистрируются газоопасные работы II группы?",
        answers: [
          "В журнале учета газоопасных работ, проводимых без наряда-допуска на проведение газоопасных работ",
          "В журнале ведения технологического процесса (вахтенном журнале)",
          "В журнале регистрации нарядов-допусков на проведение газоопасных работ",
          "В журнале учета выдачи нарядов-допусков на выполнение работ повышенной опасности",
        ],
        correctAnswers: [
          "В журнале учета газоопасных работ, проводимых без наряда-допуска на проведение газоопасных работ",
        ],
      },
      {
        code: "20205010",
        text: "Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?",
        answers: [
          "Ответственный за проведение работ, на требуемый для окончания работ срок",
          "Руководитель структурного подразделения, не более чем на 1 рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск, не более чем на 1 дневную смену",
          "Руководитель структурного подразделения, или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
        ],
        correctAnswers: [
          "Руководитель структурного подразделения, или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
        ],
      },
      {
        code: "20205011",
        text: "Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?",
        answers: [
          "Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ",
          "Только специалист, обученный пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность",
          "Только руководитель структурного подразделения или лицо, его замещающее, на объекте которого проводится газоопасная работа",
          "Любой из специалистов организации-заказчика (эксплуатирующей организации)",
        ],
        correctAnswers: [
          "Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ",
        ],
      },
      {
        code: "20205012",
        text: "Что должен сделать руководитель структурного подразделения, где будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?",
        answers: [
          "Только определить место и характер выполняемой газоопасной работы",
          "Только разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения",
          "Только установить режим работы (продолжительность пребывания персонала в средствах защиты, перерывов в работе)",
          "Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы",
        ],
        correctAnswers: [
          "Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы",
        ],
      },
      {
        code: "20205013",
        text: "Кто утверждает наряд-допуск на проведение газоопасных работ?",
        answers: [
          "Руководитель эксплуатирующей организации",
          "Руководитель структурного подразделения",
          "Руководитель профессиональной аварийно-спасательной службы (формирования) (ПАСС(Ф)",
          "Руководитель службы производственного контроля",
        ],
        correctAnswers: ["Руководитель эксплуатирующей организации"],
      },
      {
        code: "20205014",
        text: "Какие виды работ относятся к подготовительным для проведения газоопасных работ?",
        answers: [
          "Только работы, связанные с подготовкой коммуникаций",
          "Только работы, связанные с подготовкой технологического оборудования и трубопроводов",
          "Только работы, связанные с подготовкой емкостей",
          "Все перечисленные виды работ",
        ],
        correctAnswers: ["Все перечисленные виды работ"],
      },
      {
        code: "20205015",
        text: "Кто осуществляет подготовку объекта к проведению на нем газоопасной работы и огневых работ?",
        answers: [
          "Работники, список которых определяется внутренними документами организации, обученные пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность",
          "Работники, осуществляющие эксплуатацию объекта",
          "Работники, осуществляющие эксплуатацию объекта, совместно с работниками профессиональных аварийно-спасательных формирований",
          "Работники газоспасательной службы",
        ],
        correctAnswers: ["Работники, осуществляющие эксплуатацию объекта"],
      },
      {
        code: "20205016",
        text: "Какая из перечисленных подготовительных работ к проведению газоопасных работ в пределах площади, где возможно поступление паров и газов опасных веществ, указана неверно?",
        answers: [
          "Обозначается (ограждается) место проведения газоопасной работы в пределах площади, где возможно поступление паров и газов опасных веществ",
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
          "Выставляются посты в целях исключения допуска посторонних лиц в опасную зону по решению лица, ответственного за подготовку газоопасной работы",
        ],
        correctAnswers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
        ],
      },
      {
        code: "20205017",
        text: "На содержание каких веществ проводится анализ воздушной среды для оценки качества выполнения подготовительных мероприятий перед началом проведения газоопасной работы с записью результатов в наряде-допуске?",
        answers: [
          "На содержание кислорода и опасных веществ, указанных в перечне газоопасных работ, согласно месту и характеру работы",
          "На содержание кислорода, пыли, бензапирена, фенола, а также взрывопожароопасных веществ",
          "На содержание кислорода, водорода, азота, а также вредных веществ",
        ],
        correctAnswers: [
          "На содержание кислорода и опасных веществ, указанных в перечне газоопасных работ, согласно месту и характеру работы",
        ],
      },
      {
        code: "20205018",
        text: "Какие действия следует выполнить для обеспечения безопасного проведения подготовительных работ и газоопасных работ? Укажите все правильные ответы.",
        answers: [
          "Провести инструктаж работникам подрядных организаций об основных опасных факторах производства",
          "Предупредить работников, занятых ведением технологического процесса, о проводимых газоопасных работах с записью в журнале ведения технологического процесса (вахтенный журнал, журнал приема-сдачи смен)",
          "Выявить участки возможного выделения в зону работ взрывопожароопасных веществ во время проведения подготовительных работ с целью дальнейшего совмещения газоопасных и огневых работ в одном помещении",
          "Проверить полноту и качество выполненных подготовительных работ после их окончания",
        ],
        correctAnswers: [
          "Провести инструктаж работникам подрядных организаций об основных опасных факторах производства",
          "Предупредить работников, занятых ведением технологического процесса, о проводимых газоопасных работах с записью в журнале ведения технологического процесса (вахтенный журнал, журнал приема-сдачи смен)",
        ],
      },
      {
        code: "20205019",
        text: "Кому лицо, ответственное за подготовку газоопасных работ, должно сдать объект после окончания подготовительных работ?",
        answers: [
          "Руководителю структурного подразделения",
          "Техническому руководителю эксплуатирующей организации",
          "Лицу, ответственному за проведение газоопасной работы",
          "Руководителю пожарной службы эксплуатирующей организации",
          "Лицу, ответственному за безопасное ведение технологического процесса на объекте",
        ],
        correctAnswers: [
          "Лицу, ответственному за проведение газоопасной работы",
        ],
      },
      {
        code: "20205020",
        text: "Что из перечисленного допускается при проведении газоопасных работ?",
        answers: [
          "Совмещение газоопасных и огневых работ в одном помещении в случае возможного выделения в зону работ взрывопожароопасных веществ",
          "Выполнение газоопасных работ I группы бригадой исполнителей в составе двух человек",
          "Увеличение объема и изменение характера работ, предусмотренных нарядом-допуском на проведение газоопасных работ",
          "Совмещение газоопасных и огневых работ в непосредственной близости на открытой площадке в случае возможного выделения в зону работ взрывопожароопасных веществ",
        ],
        correctAnswers: [
          "Выполнение газоопасных работ I группы бригадой исполнителей в составе двух человек",
        ],
      },
      {
        code: "20205021",
        text: "Какие из обязательных мер безопасного ведения газоопасных работ, предусмотренных правилами, указаны неверно?",
        answers: [
          "Выполнение работ I группы бригадой исполнителей не менее трех человек",
          "Обеспечение контроля за состоянием воздушной среды",
          "Исключение возможности присутствия на месте проведения газоопасной работы лиц, не занятых ее выполнением",
          "Обеспечение последовательности и режима выполнения газоопасной работы",
        ],
        correctAnswers: [
          "Выполнение работ I группы бригадой исполнителей не менее трех человек",
        ],
      },
      {
        code: "20205022",
        text: "Какие действия допускается выполнять с емкостями (аппаратами), подлежащими вскрытию, осмотру, чистке или ремонту?",
        answers: [
          "Только продувать инертным газом и воздухом",
          "Только отключать от действующего оборудования, систем трубопроводов и коммуникаций с помощью стандартных заглушек",
          "Только освобождать от опасных веществ",
          "Только промывать и пропаривать",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20205023",
        text: "Что необходимо выполнить перед началом проведения газоопасных, огневых и ремонтных работ внутри емкостей (аппаратов) для оценки качества выполнения подготовительных мероприятий?",
        answers: [
          "Произвести продувку инертным газом и воздухом с записью результатов в наряде-допуске",
          'Вывесить предупреждающие плакаты "Газ", "Газоопасные работы"',
          "Произвести анализ воздушной среды на содержание опасных веществ (паров, газов)",
          "Отключить емкости (аппараты) от действующего оборудования, систем трубопроводов и коммуникаций",
          "Все перечисленное",
        ],
        correctAnswers: [
          "Произвести анализ воздушной среды на содержание опасных веществ (паров, газов)",
        ],
      },
      {
        code: "20205024",
        text: "Какие действия требуется выполнить перед началом проведения газоопасных, огневых и ремонтных работ внутри емкостей при наличии в них перемешивающих устройств с электроприводом?",
        answers: [
          'Только вывесить плакаты на емкостях "Газ", "Газоопасные работы"',
          'Только вывесить плакаты у емкостей в электрораспределительных устройствах "Не включать! Работают люди"',
          "Только отключить электропривод от источников питания видимым разрывом",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20205025",
        text: "Какие противогазы или аппараты не допускается использовать для защиты органов дыхания работников внутри емкостей при проведении газоопасных работ?",
        answers: [
          "Шланговые противогазы",
          "Кислородно-изолирующие противогазы",
          "Воздушные изолирующие аппараты",
          "Фильтрующие противогазы",
        ],
        correctAnswers: ["Фильтрующие противогазы"],
      },
      {
        code: "20205026",
        text: "Какое количество наблюдающих должно быть, если существует необходимость выполнения газоопасных работ в емкости (аппарате) двумя работающими?",
        answers: [
          "Один",
          "Не менее двух",
          "Не регламентируется",
          "Определяется ответственным за проведение газоопасных работ",
          "Определяется руководителем структурного подразделения",
        ],
        correctAnswers: ["Не менее двух"],
      },
      {
        code: "20205027",
        text: "При каких условиях допускается работа внутри емкостей без средств защиты органов дыхания?",
        answers: [
          "При условии, что концентрация опасных веществ (паров, газов) превышает предельно допустимые концентрации в воздухе рабочей зоны не более чем на 5 %, а содержание кислорода не менее 19 % объемной доли (внутри емкостей (аппаратов)",
          "При условии, что концентрация опасных веществ (паров, газов) превышает предельно допустимые концентрации в воздухе рабочей зоны не более чем на 10 %, а содержание кислорода не менее 18 % объемной доли (внутри емкостей (аппаратов)",
          "При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 17 % объемной доли (внутри емкостей (аппаратов)",
          "При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 20 % объемной доли (внутри емкостей (аппаратов)",
        ],
        correctAnswers: [
          "При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 20 % объемной доли (внутри емкостей (аппаратов)",
        ],
      },
      {
        code: "20205028",
        text: "Что должны включать в себя мероприятия, обеспечивающие безопасность выполнения работ внутри аппаратов без средств индивидуальной защиты органов дыхания?",
        answers: [
          "Только наличие у места проведения работ средств сигнализации и связи (световой, звуковой, радиотелефонной)",
          "Только непрерывный контроль состояния воздушной среды",
          "Только непрерывную гарантированную подачу свежего воздуха в емкость (аппарат), обеспечивающую нормальный воздушный режим в аппарате",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20205029",
        text: "В каких местах допускается газоопасная работа без изолирующих средств защиты органов дыхания?",
        answers: [
          "В тоннелях",
          "В колодцах",
          "В коллекторах",
          "Не допускается во всех перечисленных местах",
        ],
        correctAnswers: ["Не допускается во всех перечисленных местах"],
      },
      {
        code: "20205030",
        text: "С кем необходимо согласовывать проведение работ в коллекторах, тоннелях, колодцах, приямках, траншеях и подобных им сооружениях?",
        answers: [
          "C руководителями профессиональных аварийно-спасательных служб",
          "C руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы",
          "C руководителями службы охраны труда и промышленной безопасности",
          "C руководителями службы производственного контроля",
        ],
        correctAnswers: [
          "C руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы",
        ],
      },
      {
        code: "20205031",
        text: "К какой группе газоопасных работ относятся работы по установке (снятию) заглушек и кто их проводит?",
        answers: [
          "К II группе, проводит эксплуатационный персонал",
          "К I группе, проводит бригада, определенная нарядом-допуском",
          "К I группе, проводит электротехнический персонал",
        ],
        correctAnswers: ["К II группе, проводит эксплуатационный персонал"],
      },
      {
        code: "20205032",
        text: "Какое из перечисленных лиц имеет право ставить подписи в наряде-допуске на проведение газоопасных работ, подтверждающие его закрытие и выполнение работ в полном объеме?",
        answers: [
          "Только руководитель структурного подразделения",
          "Только должностное лицо, ответственное за безопасное ведение технологического процесса на объекте",
          "Только лицо, ответственное за проведение газоопасных работ",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20205033",
        text: "Куда (кому) лицо, ответственное за проведение газоопасных работ передает наряд-допуск после его закрытия?",
        answers: [
          "Главному инженеру, руководителю службы производственного контроля, а также в ГСС",
          "В ГСС и аварийно-спасательную службу организации",
          "Руководителю структурного подразделения или его заместителю, а также в ГСС",
        ],
        correctAnswers: [
          "Руководителю структурного подразделения или его заместителю, а также в ГСС",
        ],
      },
      {
        code: "20205034",
        text: "В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?",
        answers: [
          "Не менее 3 месяцев со дня закрытия наряда-допуска",
          "Не менее 1 месяца со дня закрытия наряда-допуска",
          "Не менее 6 месяцев со дня закрытия наряда-допуска",
        ],
        correctAnswers: ["Не менее 6 месяцев со дня закрытия наряда-допуска"],
      },
      {
        code: "20205035",
        text: "Кто определяет структурные подразделения, на которые возлагается согласование наряда-допуска на выполнение огневых работ?",
        answers: [
          "Руководитель службы производственного контроля или лицо, ответственное за осуществление производственного контроля",
          "Руководитель службы организации, на которую возложены функции обеспечения мер пожарной безопасности",
          "Руководитель эксплуатирующей организации",
        ],
        correctAnswers: ["Руководитель эксплуатирующей организации"],
      },
      {
        code: "20205036",
        text: "Допускается ли проведение огневых работ на действующих взрывопожароопасных производственных объектах?",
        answers: [
          "Не допускается",
          "Допускается при соблюдении дополнительных мер безопасности",
          "Допускается при положительном заключении пожарной охраны или аварийно-спасательной службы (формирования)",
          "Допускается в исключительных случаях, когда отсутствует возможность их проведения в специально отведенных для этой цели постоянных местах",
        ],
        correctAnswers: [
          "Допускается в исключительных случаях, когда отсутствует возможность их проведения в специально отведенных для этой цели постоянных местах",
        ],
      },
      {
        code: "20205037",
        text: "В каких случаях огневые работы могут проводиться в ночное время суток?",
        answers: [
          "В случаях ликвидации или локализации возможных аварий",
          "В случаях когда наряд-допуск подписан руководителем эксплуатирующей организации, на объекте которой будут проводиться огневые работы",
          "В случаях когда наряд-допуск подписан руководителем структурного подразделения, на объекте которого будут проводиться огневые работы",
          "Во всех перечисленных случаях",
          "Ни в каких случаях",
        ],
        correctAnswers: [
          "В случаях ликвидации или локализации возможных аварий",
        ],
      },
      {
        code: "20205038",
        text: "Каким документом определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?",
        answers: [
          "Организационно-распорядительными документами руководителя",
          "Технологическим регламентом",
          "Планом производства работ",
          "Правилами пожарной безопасности",
        ],
        correctAnswers: [
          "Организационно-распорядительными документами руководителя",
        ],
      },
      {
        code: "20205039",
        text: "Какие из перечисленных требований к перечню постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты, указаны верно?",
        answers: [
          "Указываются места выполнения огневых работ, виды, количество средств индивидуальной защиты пожаротушения, лица, ответственные за производственный контроль",
          "Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество первичных средств пожаротушения, ответственные за противопожарное состояние указанных мест и безопасное проведение огневых работ лица",
          "Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество средств индивидуальной защиты, лица, ответственные за противопожарное состояние организации",
        ],
        correctAnswers: [
          "Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество первичных средств пожаротушения, ответственные за противопожарное состояние указанных мест и безопасное проведение огневых работ лица",
        ],
      },
      {
        code: "20205040",
        text: "В течение какого времени наряд-допуск на выполнение огневых работ действителен?",
        answers: [
          "В течение всего времени проведения работ на конкретном месте",
          "Не регламентируется",
          "В течение одной смены",
        ],
        correctAnswers: ["В течение одной смены"],
      },
      {
        code: "20205041",
        text: "Какое из перечисленных обязанностей руководителя структурного подразделения, на объекте которого будут проводиться огневые работы, указано неверно?",
        answers: [
          "Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ",
          "Назначение лиц, ответственных за подготовку и выполнение огневых работ",
          "Определение объема и содержания подготовительных работ и последовательности их выполнения",
          "Определение организационных и технических мер по обеспечению пожарной безопасности мест проведения огневых работ",
        ],
        correctAnswers: [
          "Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ",
        ],
      },
      {
        code: "20205042",
        text: "Какое из перечисленных требований при назначении специалиста ответственным за выполнение огневых работ указано неверно?",
        answers: [
          "Прохождение подготовки и аттестации по промышленной безопасности",
          "Прохождение обучения по пожарно-техническому минимуму в объеме знаний требований Правил противопожарного режима в Российской Федерации, утвержденных постановлением Правительства Российской Федерации",
          "Назначение организационно-распорядительными документами руководителя эксплуатирующей организации ответственным за обеспечение пожарной безопасности",
        ],
        correctAnswers: [
          "Прохождение подготовки и аттестации по промышленной безопасности",
        ],
      },
      {
        code: "20205043",
        text: "Что указывается на схеме места выполнения огневых работ?",
        answers: [
          "Только месторасположение автомобильной техники и вспомогательного оборудования",
          "Только места размещения сварочного и другого оборудования для проведения огневых работ, места установки предупредительных знаков",
          "Только места расположения запорной арматуры и установки заглушек на технологическом оборудовании и трубопроводах",
          "Только места размещения первичных средств пожаротушения и пути эвакуации",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20205044",
        text: "Допускаются ли оформление и регистрация наряда-допуска на выполнение огневых работ в электронном виде?",
        answers: [
          "Допускаются по решению руководителя эксплуатирующей организации",
          "Допускаются при наличии локальных документов организации, устанавливающих порядок использования электронной подписи",
          "Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия",
          "Не допускаются",
        ],
        correctAnswers: [
          "Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия",
        ],
      },
      {
        code: "20205045",
        text: "Кто осуществляет подготовку объекта к проведению на нем огневых работ?",
        answers: [
          "Работники пожарной охраны",
          "Работники, осуществляющие эксплуатацию объекта",
          "Не регламентируется",
          "Работники, осуществляющие эксплуатацию объекта, совместно с работниками профессиональных аварийно-спасательных формирований",
        ],
        correctAnswers: ["Работники, осуществляющие эксплуатацию объекта"],
      },
      {
        code: "20205046",
        text: "Какие из перечисленных действий, производимых при подготовке объекта к огневым работам, указаны неверно?",
        answers: [
          "Аппараты, машины и другое оборудование, на которых будут проводиться огневые работы, должны быть остановлены, освобождены от опасных веществ",
          "Электроприводы движущихся механизмов аппаратов, машин и другого оборудования, а также другие электроприемники, которые находятся в зоне выполнения огневых работ, должны быть отключены от источников питания, отсоединены от этих механизмов видимым разрывом",
          "Аппараты, машины, и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ",
          'На пусковых устройствах должны быть вывешены плакаты "Не включать! Работают люди"',
        ],
        correctAnswers: [
          "Аппараты, машины, и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ",
        ],
      },
      {
        code: "20205047",
        text: "Какие требования установлены к люкам колодцев канализации, расположенным в зоне проведения огневых работ?",
        answers: [
          "Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце",
          "Крышки колодцев должны быть плотно закрыты, огорожены и отмечены видимыми опознавательными знаками",
          "Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 5 см в стальном или железобетонном кольце",
        ],
        correctAnswers: [
          "Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце",
        ],
      },
      {
        code: "20205048",
        text: "Какие из перечисленных мероприятий при выполнении подготовительных работ к проведению огневых работ указаны неверно?",
        answers: [
          "Место выполнения огневых работ должно быть обеспечено огнетушителем и другими первичными средствами пожаротушения, указанными в наряде-допуске на выполнение огневых работ",
          "При выполнении огневых работ в помещении следует предусмотреть меры защиты от разлета и попадания искр в проемы межэтажных перекрытий, а также лотков и приямков",
          "В помещении в зоне выполнения огневых работ следует обеспечить автоматическое включение системы вентиляции при превышении уровня предельно допустимой концентрации вредных веществ",
          "Сливные воронки, выходы из лотков и другие устройства, связанные с канализацией, в которых могут быть горючие газы и пары, должны быть герметизированы",
          "Все перечисленные мероприятия",
        ],
        correctAnswers: [
          "В помещении в зоне выполнения огневых работ следует обеспечить автоматическое включение системы вентиляции при превышении уровня предельно допустимой концентрации вредных веществ",
        ],
      },
      {
        code: "20205049",
        text: "При какой концентрации взрывопожароопасных веществ не допускается проведение огневых работ?",
        answers: [
          "При наличии взрывопожароопасных веществ выше 15 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения",
          "При наличии взрывопожароопасных веществ выше 10 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения",
          "При наличии взрывопожароопасных веществ выше 20 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения",
        ],
        correctAnswers: [
          "При наличии взрывопожароопасных веществ выше 20 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения",
        ],
      },
      {
        code: "20205050",
        text: "В соответствии с каким документом устанавливается периодичность контроля за состоянием воздушной среды?",
        answers: [
          "В соответствии с нарядом-допуском на выполнение огневых работ",
          "В соответствии с технологическим регламентом",
          "В соответствии с Правилами пожарной безопасности",
        ],
        correctAnswers: [
          "В соответствии с нарядом-допуском на выполнение огневых работ",
        ],
      },
      {
        code: "20205051",
        text: 'Какие существуют виды ремонтных работ согласно Федеральным нормам и правилам в области промышленной безопасности "Правила безопасного ведения газоопасных, огневых и ремонтных работ"?',
        answers: [
          "Только плановые ремонтные работы",
          "Только внеплановые ремонтные работы",
          "Только аварийно-восстановительные ремонтные работы",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20205052",
        text: 'На сколько этапов разделяются ремонтные работы согласно Федеральным нормам и правилам в области промышленной безопасности "Правила безопасного ведения газоопасных, огневых и ремонтных работ"?',
        answers: ["Один", "Два", "Три", "Четыре", "Не регламентируется"],
        correctAnswers: ["Два"],
      },
      {
        code: "20205053",
        text: "Какие сведения не указываются в организационно-распорядительном документе для остановки на ремонт объекта или оборудования?",
        answers: [
          "Наличие плана мероприятий по локализации аварий и ликвидации их последствий",
          "Непосредственный руководитель работ и лица, ответственные за подготовку объекта в целом или оборудования, технических устройств, коммуникаций к ремонтным работам",
          "Сроки остановки, подготовки, ремонта и пуска объекта или оборудования, технических устройств, коммуникаций в эксплуатацию",
        ],
        correctAnswers: [
          "Наличие плана мероприятий по локализации аварий и ликвидации их последствий",
        ],
      },
      {
        code: "20205054",
        text: "Что должна выполнить подрядная организация до начала проведения ремонтных работ?",
        answers: [
          "Составить перечень оборудования, трубопроводов, коммуникаций, к которым предъявляются повышенные требования безопасности при эксплуатации",
          "Организовать изготовление необходимых узлов и деталей для замены",
          "Разработать проект производства работ",
          "Приобрести необходимое оборудование, арматуру, запасные части, трубы, материалы согласно дефектной ведомости",
        ],
        correctAnswers: ["Разработать проект производства работ"],
      },
      {
        code: "20205055",
        text: "Кто производит подключение к электросетям передвижных электроприемников подрядной организации и их отключение при проведении ремонтных работ?",
        answers: [
          "Электротехнический персонал эксплуатирующей организации",
          "Электротехнический персонал подрядной организации в присутствии лица, ответственного за проведение ремонтных работ",
          "Персонал, назначенный руководителем эксплуатирующей организации",
        ],
        correctAnswers: [
          "Электротехнический персонал эксплуатирующей организации",
        ],
      },
      {
        code: "20205056",
        text: "Кем определяются технические и организационные мероприятия, обеспечивающие безопасность ремонтных работ?",
        answers: [
          "Руководителем структурного подразделения ремонтируемого объекта совместно с непосредственным руководителем работ подрядной организации",
          "Руководителем эксплуатирующей организации, объект которой подлежит ремонту, совместно с руководителем структурного подразделения ремонтируемого объекта",
          "Непосредственным руководителем работ подрядной организации по согласованию с руководителем эксплуатирующей организации, объект которой подлежит ремонту",
        ],
        correctAnswers: [
          "Руководителем структурного подразделения ремонтируемого объекта совместно с непосредственным руководителем работ подрядной организации",
        ],
      },
      {
        code: "20205057",
        text: "При соблюдении какого требования выдается наряд-допуск на проведение ремонтных работ?",
        answers: [
          "После оформления акта сдачи-приемки объекта в ремонт",
          "После выполнения организационных и технических мероприятий, предусмотренных планом подготовительных работ",
          "Только после проверки выполнения технических мероприятий, предусмотренных планом подготовительных работ",
        ],
        correctAnswers: [
          "После оформления акта сдачи-приемки объекта в ремонт",
        ],
      },
      {
        code: "20205058",
        text: "Каким образом фиксируется прохождение инструктажа исполнителями ремонтных работ?",
        answers: [
          "Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске",
          "Руководитель структурного подразделения ремонтируемого объекта делает отметку в журнале проведения инструктажа и информирует об этом руководителя бригады, которая будет выполнять ремонтные работы",
          "Руководитель службы охраны труда и производственного контроля делает записи в журнале проведения инструктажа и отметку в наряде-допуске",
        ],
        correctAnswers: [
          "Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске",
        ],
      },
      {
        code: "20205059",
        text: "Какие требования должны выполняться при проведении земляных работ в ремонтной зоне?",
        answers: [
          "Подрядная организация должна согласовывать наряд-допуск на производство земляных работ со структурными подразделениями эксплуатирующей организации, на которые внутренними документами эксплуатирующей организации возложено согласование наряда-допуска на производство земляных работ",
          "Подрядная организация должна обозначить знаками-указателями границы земляных работ на месте их проведения",
          "Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации",
        ],
        correctAnswers: [
          "Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации",
        ],
      },
      {
        code: "20205060",
        text: "Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в электронном виде?",
        answers: [
          "Не допускается",
          "Допускается, только если обеспечены условия хранения наряда-допуска в течение полугода со дня его закрытия",
          "Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия",
          "Допускается при наличии локальных документов организации, устанавливающих порядок использования электронной подписи",
        ],
        correctAnswers: [
          "Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия",
        ],
      },
      {
        code: "20205061",
        text: "Какие из перечисленных документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ, указаны неверно?",
        answers: [
          "Технологический регламент",
          "Проект производства ремонтных работ",
          "Требования к проведению газоопасных и огневых работ",
          "Инструкции по эксплуатации и безопасному проведению ремонтных работ",
        ],
        correctAnswers: ["Проект производства ремонтных работ"],
      },
      {
        code: "20205062",
        text: "Каким образом объект, ремонт которого закончен, принимается в эксплуатацию?",
        answers: [
          "По акту сдачи-приемки в эксплуатацию",
          "На основании закрытого наряда-допуска",
          "На основании положительных результатов испытаний объекта",
          "На основании распорядительного документа руководителя эксплуатирующей организации",
        ],
        correctAnswers: ["По акту сдачи-приемки в эксплуатацию"],
      },
    ],
    20206: [
      {
        code: "20206000",
        text: "Каким образом должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "По акту-допуску на проведение разовых работ с записью в журнале регистрации актов-допусков",
          "По наряду-допуску на проведение газоопасных работ, действующему в течение рабочего дня",
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
      {
        code: "20206001",
        text: "В каких из перечисленных случаев должны проводиться газоопасные работы? Выберите два правильных варианта ответа.",
        answers: [
          "Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть механизированы",
          "Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть автоматизированы",
          "Когда газоопасные работы могут быть проведены без непосредственного участия людей",
        ],
        correctAnswers: [
          "Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть механизированы",
          "Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть автоматизированы",
        ],
      },
      {
        code: "20206002",
        text: "Какие из обязательных мер безопасного ведения газоопасных работ, предусмотренных правилами, указаны неверно?",
        answers: [
          "Обеспечение режима выполнения газоопасной работы",
          "Осуществление контроля за состоянием воздушной среды",
          "Выполнение работ I группы бригадой исполнителей не менее трех человек",
          "Обеспечение членов бригады инструментом, приспособлениями и вспомогательными материалами",
        ],
        correctAnswers: [
          "Выполнение работ I группы бригадой исполнителей не менее трех человек",
        ],
      },
      {
        code: "20206003",
        text: "Кто определяет структурные подразделения, на которые возложены полномочия по согласованию перечня газоопасных работ?",
        answers: [
          "Главный инженер организации, эксплуатирующей объект",
          "Технический руководитель исполнителя совместно с начальником аварийно-спасательной службы",
          "Руководитель эксплуатирующей организации",
          "Работник, ответственный за осуществление производственного контроля",
        ],
        correctAnswers: ["Руководитель эксплуатирующей организации"],
      },
      {
        code: "20206004",
        text: "Как организована система сброса с предохранительных клапанов технологического оборудования на ОПО МНГК?",
        answers: [
          "Сбросы с предохранительных клапанов технологического оборудования направляются в закрытую емкость, а газ - в атмосферу",
          "Сбросы с предохранительных клапанов технологического оборудования, а также из коммуникаций направляются в емкость (каплеотбойник), а газ - на факел или свечу рассеивания",
          "Сбросы с предохранительных клапанов технологического оборудования направляются в общую систему сточных вод, а газ утилизируется",
        ],
        correctAnswers: [
          "Сбросы с предохранительных клапанов технологического оборудования, а также из коммуникаций направляются в емкость (каплеотбойник), а газ - на факел или свечу рассеивания",
        ],
      },
      {
        code: "20206005",
        text: "К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?",
        answers: [
          "К II группе",
          "К I группе",
          "К группе работ средней степени опасности",
          "К группе работ высокой степени опасности",
        ],
        correctAnswers: ["К II группе"],
      },
      {
        code: "20206006",
        text: "Кто утверждает перечень работ, осуществляемых по наряду-допуску, порядок оформления нарядов-допусков, перечни должностей специалистов, имеющих право выдавать и утверждать наряды-допуски?",
        answers: [
          "Специалист по охране труда",
          "Руководитель организации или уполномоченное им лицо",
          "Руководитель технического подразделения (отдела)",
          "Представитель территориального органа Ростехнадзора",
        ],
        correctAnswers: ["Руководитель организации или уполномоченное им лицо"],
      },
      {
        code: "20206007",
        text: "Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?",
        answers: [
          "Лица, допущенные к выполнению газоопасных работ, должны быть не моложе 18 лет",
          "Лица, допускаемые к выполнению газоопасных работ, должны пройти медицинский осмотр в соответствии с требованиями законодательства Российской Федерации",
          "Лица, допущенные к выполнению газоопасных работ, должны пройти обучение приемам и методам проведения специальных работ",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20206008",
        text: "Кем из перечисленных должностных лиц согласовываются сроки проведения газоопасных работ на опасных производственных объектах подрядными организациями?",
        answers: [
          "Представителем надзорного органа",
          "Лицом, ответственным за осуществление производственного контроля",
          "Руководителем эксплуатирующей организации или его уполномоченным заместителем либо руководителем филиала или его уполномоченным заместителем с учетом особенностей использования электронной подписи",
          "Лицом, ответственным за подготовку газоопасных работ",
        ],
        correctAnswers: [
          "Руководителем эксплуатирующей организации или его уполномоченным заместителем либо руководителем филиала или его уполномоченным заместителем с учетом особенностей использования электронной подписи",
        ],
      },
      {
        code: "20206009",
        text: "Какой документ определяет технологию ведения процесса или отдельных его стадий (операций), режимы и рецептуру производства продукции, показатели качества продукции и безопасные условия работы?",
        answers: [
          "Правила ведения технологического процесса",
          "Инструкция по организации и производству работ",
          "Задание на безопасное производство продукции",
          "Технологический регламент",
          "Спецификация продукции",
        ],
        correctAnswers: ["Технологический регламент"],
      },
      {
        code: "20206010",
        text: "На какой срок на стадии проектирования ОПО разрабатывается ТР?",
        answers: ["На 1 год", "На 2 года", "На 3 года", "На 5 лет"],
        correctAnswers: ["На 5 лет"],
      },
      {
        code: "20206011",
        text: "Какой установлен срок действия технологического регламента на действующем ОПО при опробовании нового оборудования?",
        answers: ["1 год", "2 года", "3 года", "5 лет"],
        correctAnswers: ["2 года"],
      },
      {
        code: "20206012",
        text: "Какие данные не указываются в рабочем проекте на бурение скважин?",
        answers: [
          "Условия расчета обсадных и насосно-компрессорных (лифтовых) колонн исходя из порогового напряжения сталей труб, принимаемых не выше 0,75 от предела текучести",
          "Типы нейтрализаторов, методы и технология нейтрализации сернистого водорода в буровом растворе, а также расход реагентов для этих целей на весь процесс бурения скважины",
          "Методы и периодичность проверки износа и контроля коррозионного состояния бурильных, ведущих, насосно-компрессорных труб и элементов трубных колонн",
          "Мероприятия по предупреждению и раннему обнаружению газонефтеводопроявлений",
          "Конструкции скважин, диаметры и глубины спуска эксплуатационных и лифтовых колонн",
        ],
        correctAnswers: [
          "Конструкции скважин, диаметры и глубины спуска эксплуатационных и лифтовых колонн",
        ],
      },
      {
        code: "20206013",
        text: "Какой документ является основным на производство буровых работ согласно Правилам безопасности в нефтяной и газовой промышленности?",
        answers: [
          "Схема выполнения свайных работ",
          "Техническое задание на подземные работы",
          "Рабочий проект",
          "План производственных работ",
        ],
        correctAnswers: ["Рабочий проект"],
      },
      {
        code: "20206014",
        text: "Что должен сделать руководитель структурного подразделения, на объекте которого будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?",
        answers: [
          "Определить ответственных работников организации, с которыми будет взаимодействовать бригада исполнителей при проведении газоопасных работ",
          "Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ",
          "Провести обучение персонала, который будет проводить газоопасные работы",
          "Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы",
        ],
        correctAnswers: [
          "Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы",
        ],
      },
      {
        code: "20206015",
        text: "Какие карты обязана иметь эксплуатирующая организация ОПО МНГК?",
        answers: [
          "Климатические карты района",
          "Только карты подводных коммуникаций в районе ведения работ",
          "Только карты надводных коммуникаций в районе ведения работ",
          "Все карты подводных и надводных коммуникаций в районе ведения работ",
        ],
        correctAnswers: [
          "Все карты подводных и надводных коммуникаций в районе ведения работ",
        ],
      },
      {
        code: "20206016",
        text: "Какие из перечисленных работников ОПО МНГК не должны дополнительно проходить проверку знаний по контролю и управлению скважиной при ГНВП?",
        answers: [
          "Работники, осуществляющие выполнение работ по бурению, освоению, ремонту и реконструкции скважин",
          "Работники, осуществляющие непосредственное руководство ведением геофизических и прострелочно-взрывных работ на скважинах",
          "Работники, осуществляющие авторский надзор и научное сопровождение внедрения технологических процессов, технических устройств и инструмента",
          "Все перечисленные работники должны проходить проверку знаний по контролю и управлению скважиной при газонефтеводопроявлениях",
        ],
        correctAnswers: [
          "Работники, осуществляющие авторский надзор и научное сопровождение внедрения технологических процессов, технических устройств и инструмента",
        ],
      },
      {
        code: "20206017",
        text: "Кем устанавливается состав работников геофизической партии и буровой бригады, находящихся при ПВР в пределах опасной зоны?",
        answers: [
          "Только организацией - исполнителем прострелочно-взрывных работ",
          "Только организацией - исполнителем буровых работ",
          "Только эксплуатирующей организацией",
          "Совместно всеми перечисленными организациями",
        ],
        correctAnswers: ["Совместно всеми перечисленными организациями"],
      },
      {
        code: "20206018",
        text: "Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?",
        answers: [
          "Любой из специалистов организации-заказчика (эксплуатирующей организации)",
          "Только руководитель структурного подразделения, где будет проводиться газоопасная работа, или его уполномоченный заместитель",
          "Только специалист, обученный пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность",
          "Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ",
        ],
        correctAnswers: [
          "Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ",
        ],
      },
      {
        code: "20206019",
        text: "Кем составляется акт готовности скважины к промыслово-геофизическим работам? Укажите все правильные ответы.",
        answers: [
          "Организацией - исполнителем буровых работ",
          "Эксплуатирующей организацией",
          "Надзорным органом в области эксплуатации подземных сооружений",
          "Организацией, проводящей экспертизу промышленной безопасности",
          "Проектной организацией",
        ],
        correctAnswers: [
          "Организацией - исполнителем буровых работ",
          "Эксплуатирующей организацией",
        ],
      },
      {
        code: "20206020",
        text: "Куда представляется один экземпляр акта обследования дна на отсутствие навигационных опасностей, составленный эксплуатирующей организацией, при ликвидации и консервации морских нефтегазовых скважин?",
        answers: [
          "В специализированную лабораторию",
          "В территориальный орган Ростехнадзора",
          "В гидрографическую службу",
          "В проектную организацию",
        ],
        correctAnswers: ["В гидрографическую службу"],
      },
      {
        code: "20206021",
        text: "Кого должен оповестить представитель организации - исполнителя буровых работ в случае возникновения открытого фонтана?",
        answers: [
          "Только территориальный орган Ростехнадзора",
          "Только профессиональное аварийное спасательное формирование по предупреждению и ликвидации открытых нефтяных и газовых фонтанов",
          "Только руководство эксплуатирующей организации",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20206022",
        text: "Какая организация отвечает за безопасное ведение промыслово-геофизических работ на МСП, ПБУ, МЭ, ПТК и хранение ВМ?",
        answers: [
          "Организация - исполнитель буровых работ",
          "Эксплуатирующая организация",
          "Научно-исследовательская организация",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20206023",
        text: "Кто руководит всеми работами по герметизации и отсоединению от устья скважины при прямых признаках газонефтеводопроявления (ГНВП)?",
        answers: [
          "Машинист плавучей буровой установки (ПБУ)",
          "Капитан судна",
          "Руководитель бурового комплекса",
          "Начальник ПБУ",
        ],
        correctAnswers: ["Начальник ПБУ"],
      },
      {
        code: "20206024",
        text: "Каким количеством работников осуществляется обслуживание устьев морских скважин на МСП и ПТК?",
        answers: [
          "Одним работником",
          "Не менее чем двумя работниками",
          "Не менее чем пятью работниками",
          "Не менее чем тремя работниками",
        ],
        correctAnswers: ["Не менее чем двумя работниками"],
      },
      {
        code: "20206025",
        text: "Кто организует и проводит ремонтно-изоляционные работы на ликвидированных скважинах и проводит повторную ликвидацию при обнаружении ГНВП?",
        answers: [
          "Ремонтная организация",
          "Организация - исполнитель буровых работ",
          "Специализированная организация",
          "Пользователь недр или его представитель",
        ],
        correctAnswers: ["Пользователь недр или его представитель"],
      },
      {
        code: "20206026",
        text: "Какие данные не регистрируются и не контролируются на ПБУ с динамической системой позиционирования и (или) с якорной системой удержания?",
        answers: [
          "Скорость и направление ветра",
          "Высота волны",
          "Параметры бортовой, килевой и вертикальной качки",
          "Горизонтальное смещение",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20206027",
        text: "Что необходимо выполнить перед началом транспортирования оборудования на скважину?",
        answers: [
          "Подготовить и утвердить планы работ, обеспечить наличие необходимых приборов и систем контроля",
          "Согласовать с соответствующими организациями условия пересечения линий электропередачи, железнодорожных магистралей, магистральных трубопроводов",
          "Проверить готовность трассы передвижения агрегатов",
          "Заключить договоры на производство работ с подрядчиками (субподрядчиками)",
          "Необходимо выполнить все перечисленные условия",
        ],
        correctAnswers: ["Необходимо выполнить все перечисленные условия"],
      },
      {
        code: "20206028",
        text: "Каким образом выполняется присоединение заземляющих проводников к заземлителю и заземляемым конструкциям?",
        answers: [
          "Винтовыми клеммами",
          "Заклепочными соединениями",
          "Паяльными соединениями",
          "Сваркой",
        ],
        correctAnswers: ["Сваркой"],
      },
      {
        code: "20206029",
        text: "Допускается ли последовательное соединение между собой заземляющих устройств разных зданий, сооружений, установок с помощью одного заземляющего проводника?",
        answers: [
          "Допускается",
          "Допускается при условии, что общее сопротивление заземляющего проводника не превышает 20 Ом",
          "Не допускается ни в каком случае",
          "Не допускается, за исключением аппаратов или резервуаров, установленных в одном обваловании",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20206030",
        text: "Как часто проводится определение технического состояния заземляющего устройства?",
        answers: [
          "Один раз в месяц",
          "Один раз в 3 месяца",
          "Один раз в 6 месяцев",
          "Один раз в год",
        ],
        correctAnswers: ["Один раз в год"],
      },
      {
        code: "20206031",
        text: "Какие из перечисленных металлических частей и конструкций ОПО МНГК, которые не находятся под напряжением, но доступны для прикосновения в условиях эксплуатации, заземляются?",
        answers: [
          "Электрическое оборудование, питаемое током безопасного напряжения",
          "Цоколи патронов и крепежных элементов люминесцентных ламп, абажуров и отражателей",
          "Кожухи, прикрепленные к патронам, изготовленным из изоляционного материала",
          "Корпуса специально изолированных подшипников",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20206032",
        text: "Допускается ли не устанавливать молниеотводное устройство на возвышающихся над МСП, ПБУ, МЭ и ПТК конструкциях?",
        answers: [
          "Допускается не устанавливать молниеотводное устройство, если конструктивно предусмотрен надежный электрический контакт вышки, мачты с металлоконструкцией МСП, ПБУ, МЭ и ПТК",
          "Не допускается ни в каком случае",
          "Допускается для отдельно стоящего оборудования",
        ],
        correctAnswers: [
          "Допускается не устанавливать молниеотводное устройство, если конструктивно предусмотрен надежный электрический контакт вышки, мачты с металлоконструкцией МСП, ПБУ, МЭ и ПТК",
        ],
      },
      {
        code: "20206033",
        text: "Какие требования предъявляются для защиты от статического электричества одиночно установленных технических устройств (оборудование, емкость, аппарат, агрегат)?",
        answers: [
          "Одиночно установленное техническое устройство заземляется только самостоятельно",
          "Одиночно установленное техническое устройство заземляется самостоятельно или присоединяется к общей заземляющей магистрали ОПО МНГК, расположенной вблизи оборудования, при помощи отдельного заземляющего провода (шины)",
          "Требования не регламентируются",
        ],
        correctAnswers: [
          "Одиночно установленное техническое устройство заземляется самостоятельно или присоединяется к общей заземляющей магистрали ОПО МНГК, расположенной вблизи оборудования, при помощи отдельного заземляющего провода (шины)",
        ],
      },
      {
        code: "20206034",
        text: "Каким образом выполняются соединения между молниеприемником, токоотводом и заземлителем на ОПО МНГК?",
        answers: [
          "Только болтовыми зажимами из стали с защитным покрытием от коррозии",
          "Только болтовыми зажимами из меди (медных сплавов)",
          "Только сваркой",
          "Всеми перечисленными способами",
        ],
        correctAnswers: ["Всеми перечисленными способами"],
      },
      {
        code: "20206035",
        text: "В каких случаях необходимо проверять изоляцию электрооборудования и исправность устройства защитного заземления буровой установки или скважины?",
        answers: [
          "Перед проведением промыслово-геофизических работ",
          "Перед проведением ремонтных работ",
          "Перед началом проведения буровых работ",
        ],
        correctAnswers: ["Перед проведением промыслово-геофизических работ"],
      },
      {
        code: "20206036",
        text: "Кто имеет право на подключение переносных светильников и разводку кабелей в полевых условиях?",
        answers: [
          "Любой из работников бригады",
          "Электромонтер и рабочий бригады или двое рабочих бригады, прошедшие соответствующий инструктаж, при условии, что один из них имеет группу по электробезопасности не ниже II",
          "Ремонтный работник, прошедший соответствующий инструктаж",
          "Двое рабочих бригады, один из которых должен иметь группу по электробезопасности не ниже II",
        ],
        correctAnswers: [
          "Электромонтер и рабочий бригады или двое рабочих бригады, прошедшие соответствующий инструктаж, при условии, что один из них имеет группу по электробезопасности не ниже II",
        ],
      },
      {
        code: "20206037",
        text: "Какие из приведенных помещений (пространств) относятся к зоне 0 (участку, на котором взрывоопасная смесь присутствует постоянно или в течение длительных периодов времени)?",
        answers: [
          "Закрытые помещения, в которых установлены открытые технические устройства, аппараты, емкости или имеются выходы для паров нефти и легковоспламеняющихся газов",
          "Помещения насосных по перекачке нефти и производственных сточных вод с содержанием нефти свыше 150 мг/л",
          "Открытые пространства вокруг открытых технологических устройств, оборудования, аппаратов, содержащих нефть и нефтяные газы или легковоспламеняющиеся жидкости, ограниченные расстоянием 5 м во все стороны",
          "Полузакрытые пространства, в которых установлены технологические устройства, оборудование, аппараты",
        ],
        correctAnswers: [
          "Закрытые помещения, в которых установлены открытые технические устройства, аппараты, емкости или имеются выходы для паров нефти и легковоспламеняющихся газов",
        ],
      },
      {
        code: "20206038",
        text: "Какие из приведенных помещений (пространств) относятся к зоне 1 (участку, на котором может присутствовать взрывоопасная смесь в нормальном рабочем режиме)?",
        answers: [
          "Малярные помещения, кладовые красок, растворителей и других легковоспламеняющихся жидкостей",
          "Открытые пространства вокруг закрытых технологических устройств, оборудования, аппаратов, а также вокруг фонтанной арматуры, ограниченные расстоянием 3 м во все стороны",
          "Закрытые помещения насосных для сточных вод",
          "Помещения для хранения грузовых шлангов для перекачки легковоспламеняющихся жидкостей с температурой вспышки 60 °С и менее",
        ],
        correctAnswers: ["Закрытые помещения насосных для сточных вод"],
      },
      {
        code: "20206039",
        text: "Какие из приведенных помещений (пространств) относятся к зоне 2 (участку, на котором присутствие взрывоопасной смеси в нормальном рабочем режиме исключается на открытых площадках и в помещениях)?",
        answers: [
          "Пространство под ротором, ограниченное цилиндром радиусом 3 м от оси скважины, на всю высоту до низа при открытом подроторном пространстве вокруг фонтанной арматуры, ограниченное расстоянием 3 м во все стороны",
          "Помещения для хранения грузовых шлангов для перекачки легковоспламеняющихся жидкостей с температурой вспышки 60 °С и менее",
          "Помещения насосных по перекачке нефти и производственных сточных вод с содержанием нефти свыше 150 мг/л",
          "Помещения малярные, кладовые красок, растворителей и других легковоспламеняющихся жидкостей",
        ],
        correctAnswers: [
          "Пространство под ротором, ограниченное цилиндром радиусом 3 м от оси скважины, на всю высоту до низа при открытом подроторном пространстве вокруг фонтанной арматуры, ограниченное расстоянием 3 м во все стороны",
        ],
      },
      {
        code: "20206040",
        text: "При каком условии закрытое помещение МСП, ПБУ, МЭ и ПТК, непосредственно сообщающееся с помещением с зоной класса взрывоопасности 0, относится к помещениям с зоной класса взрывоопасности 1? Укажите все правильные ответы.",
        answers: [
          "Вентиляция обеспечивает избыточное давление по отношению к зоне 0",
          "Предусмотрена сигнализация на посту управления с постоянной вахтой, срабатывающая при прекращении действия вентиляции",
          "Доступ осуществляется через двойные самозакрывающиеся газонепроницаемые двери, образующие воздушный тамбур-шлюз, в котором создается подпор воздуха механической приточной вентиляцией",
        ],
        correctAnswers: [
          "Вентиляция обеспечивает избыточное давление по отношению к зоне 0",
          "Предусмотрена сигнализация на посту управления с постоянной вахтой, срабатывающая при прекращении действия вентиляции",
        ],
      },
      {
        code: "20206041",
        text: "Каким образом следует располагать здания и сооружения с производственными процессами, выделяющими в атмосферу вредные и (или) горючие вещества, при обустройстве нефтяных, газовых и газоконденсатных месторождений?",
        answers: [
          "За пределами санитарно-защитной зоны населенных пунктов, объединяя данные здания и сооружения со вспомогательными, складскими и санитарно-бытовыми помещениями",
          "За пределами аварийной зоны населенных пунктов",
          "На производственных площадках преимущественно с подветренной стороны от других зданий и сооружений с учетом розы ветров преобладающего направления",
          "За пределами прилегающих народно-хозяйственных объектов",
        ],
        correctAnswers: [
          "На производственных площадках преимущественно с подветренной стороны от других зданий и сооружений с учетом розы ветров преобладающего направления",
        ],
      },
      {
        code: "20206042",
        text: "Каким образом должно осуществляться управление энергетическими установками на ОПО МНГК?",
        answers: [
          "Управление энергетическими установками должно осуществляться только с центрального пульта управления",
          "Управление энергетическими установками должно осуществляться только с пультов, расположенных в помещениях каждой установки",
          "Управление энергетическими установками должно осуществляться как с центрального пульта управления, так и с пультов, расположенных в помещениях каждой установки",
          "Управление энергетическими установками должно осуществляться с пультов, расположенных в помещении жилого блока",
        ],
        correctAnswers: [
          "Управление энергетическими установками должно осуществляться как с центрального пульта управления, так и с пультов, расположенных в помещениях каждой установки",
        ],
      },
      {
        code: "20206043",
        text: "В каком из положений нарушены требования к энергетическим установкам?",
        answers: [
          "Пуск аварийной энергоустановки обеспечивается без потребления электроэнергии извне",
          "В качестве аварийной энергоустановки, как правило, должен применяться бензиновый генератор, оборудованный системой автоматического пуска",
          "Аварийная энергетическая установка размещается в отдельном помещении, исключающем ее повреждение при авариях в месте размещения основных и вспомогательных энергетических установок",
          "Управление энергетическими установками осуществляется как с центрального пульта управления, так и с пультов, расположенных в помещениях каждой установки",
        ],
        correctAnswers: [
          "В качестве аварийной энергоустановки, как правило, должен применяться бензиновый генератор, оборудованный системой автоматического пуска",
        ],
      },
      {
        code: "20206044",
        text: "Каким образом и с какой периодичностью должна проводиться очистка воздухопроводов энергетических установок от масляных наслоений?",
        answers: [
          "Очистку воздуховодов проводить не следует. Достаточно осуществлять их продувку сжатым воздухом каждый месяц",
          "Очистка воздухопроводов должна проводиться ежегодно паром или допущенным к применению моющим средством с последующей продувкой сжатым воздухом",
          "Очистка воздухопроводов должна проводиться не реже одного раза в шесть месяцев паром или допущенным к применению моющим средством с последующей продувкой сжатым воздухом",
          "Очистка воздухопроводов должна проводиться в случае, когда величина масляных наслоений превысит допускаемые значения. Очищаются поверхности продувкой паром с последующей продувкой сжатым воздухом",
        ],
        correctAnswers: [
          "Очистка воздухопроводов должна проводиться не реже одного раза в шесть месяцев паром или допущенным к применению моющим средством с последующей продувкой сжатым воздухом",
        ],
      },
      {
        code: "20206045",
        text: "В каком случае допускается применение устройств, изменяющих противодавление на выхлопе и разряжение на входе двигателя энергетической установки ОПО МНГК?",
        answers: [
          "Если устройства размещены вне взрывоопасных помещений и зон",
          "Если имеется письменное разрешение лица, ответственного за пожарную безопасность",
          "Ни в каком случае",
          "Согласованных с заводом-изготовителем",
        ],
        correctAnswers: ["Согласованных с заводом-изготовителем"],
      },
      {
        code: "20206046",
        text: "Какие требования предъявляются к выхлопным трубам энергетических установок?",
        answers: [
          "Выхлопные трубы разрешается размещать в помещении при условии расположения выхлопов не ниже 4 м над рабочей зоной персонала",
          "Выхлопные трубы должны выводиться из помещений наружу с учетом преобладающего направления ветра и оборудоваться глушителями-искрогасителями",
          "Выхлопные трубы допускается не выводить из помещения при условии соблюдения правил пожарной безопасности",
          "Выхлопные трубы допускается не выводить из помещения при наличии письменного разрешения Ростехнадзора",
        ],
        correctAnswers: [
          "Выхлопные трубы должны выводиться из помещений наружу с учетом преобладающего направления ветра и оборудоваться глушителями-искрогасителями",
        ],
      },
      {
        code: "20206047",
        text: "Каким требованиям должны соответствовать электрические сети на МСП, ПБУ, МЭ и ПТК?",
        answers: [
          "Должны быть изолированными, в том числе сети трехфазного переменного тока напряжением до 1000 В с изолированной нейтралью. Нейтраль электрических сетей напряжением свыше 1000 В заземляется через высокоомный резистор",
          "Должны быть изолированными, в том числе сети трехфазного переменного тока напряжением до 0,4 кВ с изолированной нейтралью. Нейтраль электрических сетей напряжением свыше 0,4 кВ заземляется через высокоомный резистор",
          "Должны быть изолированными, в том числе сети двухфазного переменного тока напряжением 220 В",
          "Должны быть изолированными, в том числе сети трехфазного переменного тока напряжением до 0,4 кВ с неизолированной нейтралью. Нейтраль электрических сетей напряжением свыше 1000 В заземляется через низкоомный резистор",
        ],
        correctAnswers: [
          "Должны быть изолированными, в том числе сети трехфазного переменного тока напряжением до 1000 В с изолированной нейтралью. Нейтраль электрических сетей напряжением свыше 1000 В заземляется через высокоомный резистор",
        ],
      },
      {
        code: "20206048",
        text: "В каком исполнении должны быть технические устройства, применяемые во взрывопожароопасных зонах?",
        answers: [
          "Во взрывозащищенном",
          "В пылевлагонепроницаемом",
          "Во взрывонепроницаемом",
          "В брызгозащищенном",
        ],
        correctAnswers: ["Во взрывозащищенном"],
      },
      {
        code: "20206049",
        text: "Для каких целей во взрывоопасных технологических процессах должны быть предусмотрены системы противоаварийной защиты, противопожарной защиты и газовой безопасности?",
        answers: [
          "Для срабатывания предупредительной сигнализации о возникновении аварийных ситуаций при отклонении от предусмотренных регламентом предельно допустимых параметров во всех режимах работы",
          "Для автоматического форсирования технологического процесса",
          "Для ликвидации аварийных ситуаций в автоматическом режиме",
          "Для обеспечения безопасной остановки или перевода процесса в безопасное состояние в случае критического отклонения от предусмотренных технологическим регламентом параметров",
        ],
        correctAnswers: [
          "Для обеспечения безопасной остановки или перевода процесса в безопасное состояние в случае критического отклонения от предусмотренных технологическим регламентом параметров",
        ],
      },
      {
        code: "20206050",
        text: "В каких зонах классов взрывоопасности помещений МСП, ПБУ, МЭ и ПТК допускается установка электрооборудования, не имеющего маркировки по взрывозащите?",
        answers: ["2", "0", "1", "Все ответы неверны"],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20206051",
        text: "Что должна обеспечивать лебедка при проведении спуско-подъемных операций?",
        answers: [
          "Вращение барабана с канатом на протяжении всей спуско-подъемной операции с фиксированной скоростью",
          "Вращение барабана с канатом в любых желаемых диапазонах скоростей и с фиксированной нагрузкой на канат (проволоку)",
          "Номинальную скорость вращения барабана с нагрузкой на крюке, превышающую расчетную на 10 %",
          "Аварийное торможение с помощью рабочего тормозного зажима",
        ],
        correctAnswers: [
          "Вращение барабана с канатом в любых желаемых диапазонах скоростей и с фиксированной нагрузкой на канат (проволоку)",
        ],
      },
      {
        code: "20206052",
        text: "На каком расстоянии проводится крепление шлангокабелей системы управления с помощью хомутов к линиям глушения и дросселирования на секциях райзера или к канатам коллектора при спуске секций райзера с ППВО?",
        answers: [
          "Через каждые 8 - 10 м",
          "Через каждые 4 - 6 м",
          "Через каждые 10 - 12 м",
          "Через каждые 6 - 8 м",
        ],
        correctAnswers: ["Через каждые 8 - 10 м"],
      },
      {
        code: "20206053",
        text: "Какие данные должны быть указаны на металлической табличке, укрепляемой на видном месте мачты агрегата по ремонту скважин?",
        answers: [
          "Грузоподъемность и дата последнего технического освидетельствования",
          "Грузоподъемность и дата очередного технического освидетельствования",
          "Наименование организации, владельца агрегата, заводской номер технического устройства",
        ],
        correctAnswers: [
          "Грузоподъемность и дата очередного технического освидетельствования",
        ],
      },
      {
        code: "20206054",
        text: "Чем должны быть оборудованы и оснащены колтюбинговые установки с гибкими трубами?",
        answers: [
          "Системами контроля и регистрации расхода промывочной жидкости",
          "Системой контроля наработки гибкой трубы",
          "Системой контроля и регистрации давления при прокачивании через гибкую трубу жидкостей",
          "Комплектом устройств на устье скважины для спуска труб под давлением",
          "Всеми вышеперечисленными устройствами",
        ],
        correctAnswers: ["Всеми вышеперечисленными устройствами"],
      },
      {
        code: "20206055",
        text: "Какие меры безопасности должны быть предусмотрены при проведении ремонтных работ или укладке кабеля после ремонта?",
        answers: [
          "Включение питания проводится ответственным дежурным по энергоснабжению после устного подтверждения руководителя работ и личного контроля окончания этих работ",
          "При осмотре, ремонтных работах или укладке кабеля после ремонта исключается случайная подача напряжения в укладываемый, осматриваемый или ремонтируемый кабель",
          "До начала ремонтных работ после подъема поврежденного кабеля на борт кабелеукладочного судна кабель отключается хотя бы с одной стороны и заземляется",
          'Одновременное проведение испытания и ремонтных работ различными бригадами в пределах одного присоединения допускается по одному наряду-заданию с указанием в строке "Отдельные указания" дополнительных мер, обеспечивающих безопасность работников',
        ],
        correctAnswers: [
          "При осмотре, ремонтных работах или укладке кабеля после ремонта исключается случайная подача напряжения в укладываемый, осматриваемый или ремонтируемый кабель",
        ],
      },
      {
        code: "20206056",
        text: "Вентиляционной системой какого типа должны быть оборудованы все закрытые помещения буровой установки, где возможно проникновение или возникновение воспламеняющихся смесей?",
        answers: [
          "Естественной",
          "Местной вытяжной",
          "Местной приточной",
          "Приточно-вытяжной вентиляцией с механическим побуждением",
        ],
        correctAnswers: [
          "Приточно-вытяжной вентиляцией с механическим побуждением",
        ],
      },
      {
        code: "20206057",
        text: "В каком случае должно обеспечиваться полное отключение оборудования и механизмов в закрытых помещениях буровой установки, где возможно возникновение или проникновение воспламеняющихся смесей?",
        answers: [
          "При достижении 20 % от нижнего предела воспламенения смеси воздуха с углеводородами",
          "При достижении 30 % от нижнего предела воспламенения смеси воздуха с углеводородами",
          "При достижении 40 % от нижнего предела воспламенения смеси воздуха с углеводородами",
          "При достижении 50 % от нижнего предела воспламенения смеси воздуха с углеводородами",
        ],
        correctAnswers: [
          "При достижении 50 % от нижнего предела воспламенения смеси воздуха с углеводородами",
        ],
      },
      {
        code: "20206058",
        text: "Какое напряжение должно применяться для питания переносных электрических светильников, используемых при работах в особо неблагоприятных условиях и наружных установках?",
        answers: ["Не выше 127 В", "Не выше 50 В", "Не выше 12 В"],
        correctAnswers: ["Не выше 12 В"],
      },
      {
        code: "20206059",
        text: "В каких случаях устье скважин на период ремонта оснащается противовыбросовым оборудованием?",
        answers: [
          "Только в случае ведения работ на кустовых площадках",
          "В случаях возможных газонефтеводопроявлениях на скважинах",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: [
          "В случаях возможных газонефтеводопроявлениях на скважинах",
        ],
      },
      {
        code: "20206060",
        text: "С каким уклоном допускается устанавливать опорную плиту (при ее наличии) на ровную поверхность дна моря при формировании подводного устья скважины?",
        answers: [
          "Не более 3°",
          "Не более 1°",
          "Не более 5°",
          "Уклон не допускается",
        ],
        correctAnswers: ["Не более 1°"],
      },
      {
        code: "20206061",
        text: "Какие требования к емкости для долива скважины указаны неверно?",
        answers: [
          "Емкость должна быть обвязана с устьем скважины с таким расчетом, чтобы обеспечивался постоянный долив жидкости в скважину самотеком или принудительно с использованием насоса",
          "Емкость должна быть стационарной или передвижной (автоцистерна любого типа)",
          "Емкость должна устанавливаться на расстоянии пяти метров от устья ремонтируемой скважины в зоне видимости бурильщика КРС (оператора ТРС)",
          "Емкость (автоцистерна) должна быть оборудована показывающим замерным устройством (уровнемером), имеющим градуировку с ценой деления 0,2 м3",
        ],
        correctAnswers: [
          "Емкость должна устанавливаться на расстоянии пяти метров от устья ремонтируемой скважины в зоне видимости бурильщика КРС (оператора ТРС)",
        ],
      },
      {
        code: "20206062",
        text: "Разрешается ли прокладка заглубленных каналов и тоннелей при обустройстве нефтяных, газовых и газоконденсатных месторождений для размещения кабелей в помещениях и на территории наружных установок, имеющих источники возможного выделения в атмосферу вредных веществ плотностью по воздуху более 0,8, а также источники возможных проливов горючих и серосодержащих жидкостей?",
        answers: [
          "Не допускается ни в каком случае",
          "Запрещается, за исключением каналов и тоннелей, подлежащих последующей засыпке",
          "Разрешается",
          "Разрешается по согласованию с проектной организацией",
        ],
        correctAnswers: [
          "Запрещается, за исключением каналов и тоннелей, подлежащих последующей засыпке",
        ],
      },
      {
        code: "20206063",
        text: 'Допускается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений хранение токсичных жидкостей в резервуарах с "атмосферным" дыханием?',
        answers: [
          "Не допускается ни в каком случае",
          "Допускается в любом случае",
          "Допускается при условии оснащения резервуаров системой аварийного слива жидкости в дренажную систему",
          "Допускается при условии оснащения резервуаров сигнализацией предельного верхнего уровня заполнения резервуара, сблокированной с насосным оборудованием",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20206064",
        text: "Какие требования предъявляются к хранению использованной ветоши и обтирочных материалов во взрывоопасных зонах?",
        answers: [
          "Места хранения использованной ветоши и обтирочных материалов правилами не регламентируются",
          "Места хранения использованной ветоши и обтирочных материалов определяются руководителями подразделений",
          "В зонах не допускается хранение использованной ветоши и обтирочных материалов",
        ],
        correctAnswers: [
          "В зонах не допускается хранение использованной ветоши и обтирочных материалов",
        ],
      },
      {
        code: "20206065",
        text: "Какая должна быть кратность воздухообмена в помещениях с взрывоопасными зонами?",
        answers: ["Не менее 6 в час", "Не менее 8 в час", "Не менее 10 в час"],
        correctAnswers: ["Не менее 6 в час"],
      },
      {
        code: "20206066",
        text: "Каким оборудованием допускается проводить геофизические работы?",
        answers: [
          "Имеющим подтверждение соответствия и свидетельство о поверке приборов и средств измерения, входящих в их состав",
          "Имеющим подтверждение соответствия и сертификат о калибровке приборов и средств измерения, входящих в их состав",
          "Имеющим свидетельство о поверке приборов и средств измерения, входящих в их состав",
        ],
        correctAnswers: [
          "Имеющим подтверждение соответствия и свидетельство о поверке приборов и средств измерения, входящих в их состав",
        ],
      },
      {
        code: "20206067",
        text: "В каких местах должны устанавливаться датчики контроля концентрационных пределов распространения пламени? Укажите все правильные ответы.",
        answers: [
          "У технологических аппаратов в местах возможных источников выделений паров и газов на высоте не более 1 м над источником для нефтяных газов и на высоте 1,5 м над полом для паров нефти",
          "Над открытой емкостью для бурового раствора на расстоянии не более 0,5 м над ее верхней кромкой",
          "У вибросита на расстоянии не более 1 м от него на высоте не более 0,5 м над ним",
          "В районе устья скважины на высоте не более 0,5 м над полом",
        ],
        correctAnswers: [
          "У вибросита на расстоянии не более 1 м от него на высоте не более 0,5 м над ним",
          "В районе устья скважины на высоте не более 0,5 м над полом",
        ],
      },
      {
        code: "20206068",
        text: "После чего производится сдача в работу смонтированной буровой установки?",
        answers: [
          "Только после проверки качества заземления оборудования и заземляющих устройств",
          "Только после испытания на герметичность нагнетательных трубопроводов, воздухопроводов",
          "Только после испытания систем управления оборудованием и блокировок",
          "Сдача в работу смонтированной буровой установки производится после проведения всех вышеперечисленных действий",
        ],
        correctAnswers: [
          "Сдача в работу смонтированной буровой установки производится после проведения всех вышеперечисленных действий",
        ],
      },
      {
        code: "20206069",
        text: "Какая из перечисленных надписей не указывается на каждой консервируемой скважине?",
        answers: [
          "Дата и срок введения скважины в эксплуатацию",
          "Наименование месторождения (площади)",
          "Номер скважины",
          "Наименование эксплуатирующей организации",
        ],
        correctAnswers: ["Дата и срок введения скважины в эксплуатацию"],
      },
      {
        code: "20206070",
        text: "В присутствии кого производятся промыслово-геофизические работы в скважинах? Укажите все правильные ответы.",
        answers: [
          "Представителей организации - исполнителя буровых работ",
          "Представителей эксплуатирующей организации",
          "Представителей проектной организации",
          "Представителей территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "Представителей организации - исполнителя буровых работ",
          "Представителей эксплуатирующей организации",
        ],
      },
      {
        code: "20206071",
        text: "При выполнении какого условия допускается размещение ПБУ на месте производства работ, постановка обслуживающих судов на якоря и производство работ в охранной зоне ЛЭП, кабелей связи, морских трубопроводов и других сооружений?",
        answers: [
          "При поступлении допустимой информации об опасных природных (гидрометеорологических и гелиогеофизических) явлениях",
          "При наличии письменного согласования с эксплуатирующей их организацией",
          "Не допускается ни при каком условии",
          "При наличии письменного разрешения местного органа Росприроднадзора",
        ],
        correctAnswers: [
          "При наличии письменного согласования с эксплуатирующей их организацией",
        ],
      },
      {
        code: "20206072",
        text: "С какой регулярностью проводятся повторная и последующие проверки подводного трубопровода?",
        answers: [
          "Повторная проверка подводного трубопровода проводится в срок не позднее одного года с начала эксплуатации, последующие проверки проводятся с определенной при проектировании периодичностью, но не реже чем раз в восемь лет",
          "Повторная проверка подводного трубопровода проводится в срок не позднее шести месяцев с начала эксплуатации, последующие проверки проводятся при необходимости",
          "Повторная проверка подводного трубопровода проводится только в случае экстренной необходимости, последующие проверки проводятся каждые пять лет",
        ],
        correctAnswers: [
          "Повторная проверка подводного трубопровода проводится в срок не позднее одного года с начала эксплуатации, последующие проверки проводятся с определенной при проектировании периодичностью, но не реже чем раз в восемь лет",
        ],
      },
      {
        code: "20206073",
        text: "Допускается ли пересечение технологических трубопроводов с газом, ЛВЖ, ГЖ, с трубопроводами негорючих веществ на ОПО МНГК?",
        answers: [
          "Допускается при наличии разрешения территориального органа Ростехнадзора",
          "Допускается, если трубопроводы негорючих веществ располагаются снизу",
          "Не допускается",
        ],
        correctAnswers: [
          "Допускается, если трубопроводы негорючих веществ располагаются снизу",
        ],
      },
      {
        code: "20206074",
        text: "Допускается ли отдача якорей судами в охранной зоне подводных трубопроводов?",
        answers: [
          "Не допускается ни при каких условиях",
          "Допускается только при выполнении подводно-технических работ и ремонте трубопровода при наличии письменного разрешения организации, эксплуатирующей трубопровод",
          "Допускается при письменном разрешении Федерального агентства водных ресурсов",
          "Допускается в любом случае",
        ],
        correctAnswers: [
          "Допускается только при выполнении подводно-технических работ и ремонте трубопровода при наличии письменного разрешения организации, эксплуатирующей трубопровод",
        ],
      },
      {
        code: "20206075",
        text: "Каким проверкам подвергается подводный трубопровод после капитального ремонта?",
        answers: [
          "Проверкам роботизированными подводными аппаратами",
          "Проверкам методами неразрушающего контроля",
          "Проверкам на прочность и герметичность",
        ],
        correctAnswers: ["Проверкам на прочность и герметичность"],
      },
      {
        code: "20206076",
        text: "Кем утверждается инструкция, предусматривающая порядок проведения испытания подводного трубопровода?",
        answers: [
          "Эксплуатирующей организацией",
          "Организацией, осуществляющей промыслово-геофизические работы",
          "Научно-техническим центром",
          "Строительной организацией",
        ],
        correctAnswers: ["Эксплуатирующей организацией"],
      },
      {
        code: "20206077",
        text: "Кем производится пуск в работу смонтированной установки и оборудования?",
        answers: [
          "Несколькими рабочими бригады, прошедшими соответствующий инструктаж",
          "Комиссией, состав и порядок работы которой устанавливается эксплуатирующей организацией",
          "Инженерно-техническим работником и специалистом по охране труда эксплуатирующей организации",
          "Главным инженером и ремонтным работником подрядной организации",
        ],
        correctAnswers: [
          "Комиссией, состав и порядок работы которой устанавливается эксплуатирующей организацией",
        ],
      },
      {
        code: "20206078",
        text: "Что из перечисленного не следует выполнять при консервации эксплуатационных скважин с надводным расположением устья?",
        answers: [
          "Снять штурвал контрольной задвижки",
          "Заглушить места установки манометров",
          "Отсоединить коммуникации арматуры",
          "Оборудовать фланцевыми заглушками внешние фланцы задвижек арматуры",
        ],
        correctAnswers: ["Снять штурвал контрольной задвижки"],
      },
      {
        code: "20206079",
        text: "Какое минимальное количество превенторов должно быть в составе противовыбросового оборудования (далее - ПВО)?",
        answers: ["Два", "Четыре", "Три", "Пять"],
        correctAnswers: ["Четыре"],
      },
      {
        code: "20206080",
        text: "Какое требование, предъявляемое к применению противовыбросового оборудования, указано неверно?",
        answers: [
          "На плавучей буровой установке с подводным расположением устья проводится опрессовка каждого превентора в сборке подводного противовыбросового оборудования на стенде на рабочее давление",
          "Универсальным превентором обеспечивается срезание наиболее прочной трубы, предполагаемой к спуску в скважину",
          "При газонефтеводопроявлениях разгазированная жидкость через штуцерную линию поступает в систему сепарации и дегазации",
          "Противовыбросовый манифольд вместе с линией от сепаратора бурового раствора на желоб оборудуется устройством для продувки",
        ],
        correctAnswers: [
          "Универсальным превентором обеспечивается срезание наиболее прочной трубы, предполагаемой к спуску в скважину",
        ],
      },
      {
        code: "20206081",
        text: "Кем осуществляется выбор типа противовыбросового оборудования и колонной головки?",
        answers: [
          "Организацией по производству буровых работ",
          "Пользователем недр",
          "Проектной организацией",
        ],
        correctAnswers: ["Проектной организацией"],
      },
      {
        code: "20206082",
        text: "Какие показатели должны постоянно контролироваться в процессе проходки ствола скважины?",
        answers: [
          "Расход бурового раствора на входе и выходе из скважины и давление в манифольде буровых насосов",
          "Взаимное расположение стволов бурящейся и ранее пробуренных соседних скважин",
          "Азимут и зенитный угол ствола скважины",
          "Пространственное расположение ствола скважины и дифференциальное давление в системе скважина - пласт",
        ],
        correctAnswers: [
          "Расход бурового раствора на входе и выходе из скважины и давление в манифольде буровых насосов",
        ],
      },
      {
        code: "20206083",
        text: "Какое должно быть расстояние между устьями скважин при расположении систем управления оборудования ПВО при бурении скважин и задвижками фонтанной арматуры эксплуатируемых скважин на одном ярусе при отсутствии обоснованных проектных решений о конкретных расстояниях?",
        answers: [
          "Не менее 2,4 м (для нефтяных)",
          "Не менее 3 м (для газовых и газоконденсатных)",
          "Не менее 5 м",
        ],
        correctAnswers: ["Не менее 5 м"],
      },
      {
        code: "20206084",
        text: "На кого возлагается общее руководство буровыми работами на ПБУ?",
        answers: [
          "На заместителя начальника по технологии бурения",
          "На начальника ОПО МНГК",
          "На капитана плавучей буровой установки (ПБУ)",
          "На главного инженера ПБУ",
        ],
        correctAnswers: ["На начальника ОПО МНГК"],
      },
      {
        code: "20206085",
        text: "Кем выдаются разрешения на выполнение отдельных технологических операций и применение ограничений эксплуатации бурового оборудования, а также указания о прекращении бурения и отсоединении бурового райзера по гидрометеорологическим условиям?",
        answers: [
          "Начальником ПБУ",
          "Вахтенным помощником капитана судна",
          "Капитаном судна",
          "Заместителем руководителя буровых работ",
        ],
        correctAnswers: ["Начальником ПБУ"],
      },
      {
        code: "20206086",
        text: "Какое судно должно постоянно находиться в период опробования скважины вблизи МСП, ПБУ, МЭ и ПТК?",
        answers: [
          "Пожарное судно",
          "Аварийно-спасательное судно",
          "Вспомогательное судно",
        ],
        correctAnswers: ["Аварийно-спасательное судно"],
      },
      {
        code: "20206087",
        text: "Какое из перечисленных действий перед проведением прострелочно-взрывных работ указано неверно?",
        answers: [
          "Оповестить радиостанции, находящиеся на связи, о прекращении работы радиостанции на морской стационарной платформе, плавучей буровой установке или морской эстакаде с приэстакадными площадками до специального разрешения",
          "Включить активную катодную защиту",
          "Отключить мобильные (носимые) радиостанции внутренней связи",
          "Прекратить все огневые работы",
        ],
        correctAnswers: ["Включить активную катодную защиту"],
      },
      {
        code: "20206088",
        text: "С какой периодичностью на скважинах, находящихся в консервации, проводятся проверки состояния оборудования и наличия избыточного давления на устье с составлением акта обследования?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в год",
          "Не реже двух раз в год",
          "Не реже одного раза в три года",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20206089",
        text: "В каком из перечисленных случаев должно проводиться забуривание новых (боковых) стволов в обсаженных скважинах?",
        answers: [
          "В случае ликвидации аварий, инцидентов и осложнений, возникших в процессе бурения",
          "В случае вскрытия дополнительных продуктивных мощностей путем проводки ответвлений",
          "В случае восстановления бездействующего фонда скважин",
          "В любом из перечисленных случаев",
        ],
        correctAnswers: ["В любом из перечисленных случаев"],
      },
      {
        code: "20206090",
        text: "Какие действия не следует проводить перед испытанием и освоением скважин ОПО МНГК?",
        answers: [
          "Проверять системы поджигания горелок и исправность дистанционного устройства по поджиганию факела",
          "Поджигать дежурную горелку факела",
          "Проверять комплектность и готовность средств пожаротушения",
          "Опрессовывать сепаратор с обвязкой",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20206091",
        text: "Какое из перечисленных требований при формировании подводного устья скважины указано верно? Укажите все правильные ответы.",
        answers: [
          "Перед началом бурения скважины руководитель эксплуатирующей организации утверждает акт готовности буровой установки и дает разрешение на пуск установки",
          "Проведение работ по формированию устья скважины без стабилизации (ориентации) плавучей буровой установки на точке бурения допускается только с участием представителя Ростехнадзора",
          "Установка опорной плиты (при ее наличии) на створках спайдерной площадки проводится так, чтобы центр плиты совпадал с осью спускаемого инструмента (центром ротора)",
          "Распоряжение ответственного лица о начале работ по формированию подводного устья скважины фиксируется в судовом и буровом журналах",
        ],
        correctAnswers: [
          "Установка опорной плиты (при ее наличии) на створках спайдерной площадки проводится так, чтобы центр плиты совпадал с осью спускаемого инструмента (центром ротора)",
          "Распоряжение ответственного лица о начале работ по формированию подводного устья скважины фиксируется в судовом и буровом журналах",
        ],
      },
      {
        code: "20206092",
        text: "Какие инструктажи необходимо провести до начала работ по формированию устья скважины?",
        answers: [
          "Руководитель буровых работ проводит инструктаж работников по основным технологическим особенностям работ, связанных с формированием подводного устья скважины, а также по безопасной эксплуатации буровой установки",
          "Руководитель буровых работ проводит инструктаж работников по контролю за газонефтеводопроявлениями",
          "Руководитель буровых работ проводит инструктаж персонала по технике безопасности по работам с электрооборудованием и грузоподъемными механизмами",
        ],
        correctAnswers: [
          "Руководитель буровых работ проводит инструктаж работников по основным технологическим особенностям работ, связанных с формированием подводного устья скважины, а также по безопасной эксплуатации буровой установки",
        ],
      },
      {
        code: "20206093",
        text: "Каким образом осуществляется визуальный контроль за соединениями бурового райзера, блока ППВО под водой?",
        answers: [
          "Визуальный контроль не осуществляется",
          "При помощи водолазов",
          "С помощью телеуправляемого подводного аппарата",
        ],
        correctAnswers: ["С помощью телеуправляемого подводного аппарата"],
      },
      {
        code: "20206094",
        text: "Какие меры безопасности и охраны окружающей среды необходимо произвести при сжигании продукции опробования скважины?",
        answers: [
          "Необходимо регулировать подачу воды и воздуха на факел для обеспечения бездымного сжигания продукции скважины",
          "Необходимо предусматривать наличие средств пожаротушения",
          "Меры безопасности и охраны окружающей среды не регламентируются",
        ],
        correctAnswers: [
          "Необходимо регулировать подачу воды и воздуха на факел для обеспечения бездымного сжигания продукции скважины",
        ],
      },
      {
        code: "20206095",
        text: "Каким требованиям должны соответствовать трубопроводы, проложенные от устья скважин до технологических установок?",
        answers: [
          "Должны быть проложены в один ярус",
          "Должны быть рассчитаны на полуторакратное рабочее давление",
          "В начале и конце трубопровода краской должен быть нанесен номер скважины и направление потока",
          "Должны соответствовать всем перечисленным требованиям",
        ],
        correctAnswers: [
          "Должны соответствовать всем перечисленным требованиям",
        ],
      },
      {
        code: "20206096",
        text: "Кто осматривает нефтеналивное судно, пришвартованное к нефтеналивному или перегрузочному комплексу, для определения возможности налива нефти?",
        answers: [
          "Представитель противопожарной службы субъектов Российской Федерации",
          "Ответственное лицо, назначенное начальником ОПО МНГК",
          "Представитель территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "Ответственное лицо, назначенное начальником ОПО МНГК",
        ],
      },
      {
        code: "20206097",
        text: "Какие работы не запрещается производить во время стоянки нефтеналивного судна у причала?",
        answers: [
          "Подход к нефтеналивному судну и швартовка судов и иных плавсредств, не связанных с операциями по наливу нефти",
          "Отогревание замерзших трубопроводов паром",
          "Налив нефтепродуктов при грозовых разрядах",
        ],
        correctAnswers: ["Отогревание замерзших трубопроводов паром"],
      },
      {
        code: "20206098",
        text: "Кто обеспечивает работников геофизической организации при нахождении на МСП, ПБУ, МЭ, ПТК спасательными средствами?",
        answers: [
          "Эксплуатирующая организация",
          "Руководство геофизической организации",
          "Персонал геофизической организации обеспечивается спасательными средствами на договорной основе с организацией заказчика",
        ],
        correctAnswers: ["Эксплуатирующая организация"],
      },
      {
        code: "20206099",
        text: "Какие действия необходимо предпринять, если взрывчатые материалы, завезенные на ОПО МНГК, использованы неполностью?",
        answers: ["Утилизировать", "По решению руководства", "Вывезти"],
        correctAnswers: ["Вывезти"],
      },
      {
        code: "20206100",
        text: "Что должно включаться в опасную зону на период прострелочно-взрывных работ на МСП, ПБУ и МЭ?",
        answers: [
          "Только буровая вышка",
          "Только трасса каротажного кабеля и каротажная лебедка",
          "Только место зарядки прострелочно-взрывных аппаратов и подготовки торпед",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20206101",
        text: "При применении каких материалов устанавливается герметичное оборудование с устройством для пневмотранспорта при проведении буровых работ?",
        answers: [
          "Только при применении глинопорошка",
          "Только при применении цемента",
          "Только при применении химреагентов",
          "При применении всех перечисленных материалов",
        ],
        correctAnswers: ["При применении всех перечисленных материалов"],
      },
      {
        code: "20206102",
        text: "Как оборудуется устье скважины при ликвидации скважин, пробуренных с ПБУ?",
        answers: [
          "На устье скважины устанавливается бетонная тумба с репером высотой не менее 5 м",
          "На устье скважины устанавливается репер",
          "Выступающая над дном моря обсадная колонна (в случае если при бурении скважины не использовались специальная система придонных подвесок и колонные головки) удаляется на уровень дна моря",
        ],
        correctAnswers: [
          "Выступающая над дном моря обсадная колонна (в случае если при бурении скважины не использовались специальная система придонных подвесок и колонные головки) удаляется на уровень дна моря",
        ],
      },
      {
        code: "20206103",
        text: "При каком условии допускается одновременное бурение двух скважин на ОПО МНГК? Укажите все правильные ответы.",
        answers: [
          "При спуске на одной из скважин кондуктора",
          "При смонтированном на устье скважины ПВО",
          "При условии записи в плане производства буровых работ",
          "При бурении скважин двумя буровыми установками, с соединенными циркуляционными системами",
        ],
        correctAnswers: [
          "При спуске на одной из скважин кондуктора",
          "При смонтированном на устье скважины ПВО",
        ],
      },
      {
        code: "20206104",
        text: "Какие из перечисленных расписаний по тревогам предусматриваются на ОПО МНГК?",
        answers: [
          "Только шлюпочная тревога",
          'Только тревога "Человек за бортом"',
          "Только общесудовая тревога",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20206105",
        text: "Кто постоянно контролирует положение ПБУ над скважиной, горизонтальные перемещения ПБУ и угол наклона бурового райзера при бурении и выполнении других технологических операций?",
        answers: [
          "Машинист буровой установки",
          "Начальник внешней связи",
          "Начальник плавучей буровой установки (ПБУ)",
          "Оператор системы позиционирования ПБУ",
        ],
        correctAnswers: ["Оператор системы позиционирования ПБУ"],
      },
      {
        code: "20206106",
        text: "Цементный мост какой высоты устанавливается во всех ликвидируемых скважинах в последней (наименьшей) обсадной колонне, связанной с устьем скважины, с расположением кровли цементного моста на уровне дна моря?",
        answers: [
          "Не менее 50 м",
          "Не менее 30 м",
          "Не менее 60 м",
          "Не менее 40 м",
        ],
        correctAnswers: ["Не менее 50 м"],
      },
      {
        code: "20206107",
        text: "В каком случае допускается выполнение спуска перфоратора или торпеды в скважину и непосредственно первый отстрел перфоратора или взрыв торпеды с наступлением темноты?",
        answers: [
          "При проведении работ с присутствием наблюдающего",
          "При обосновании промыслово-геофизических работ в проекте геологоразведочных работ",
          "При обеспечении достаточного освещения рабочих мест и опасной зоны",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "При обеспечении достаточного освещения рабочих мест и опасной зоны",
        ],
      },
      {
        code: "20206108",
        text: "С какой периодичностью и в какой период проводится обследование опорной части ОПО МНГК в целях определения воздействия на нее ледовых образований?",
        answers: [
          "Не реже двух раз в год в период отсутствия льда на водном пространстве и в период наличия льда на водном пространстве",
          "Один раз в два года вне зависимости от периода",
          "Один раз в год в период отсутствия льда на водном пространстве",
          "Не реже чем один раз в три года в период отсутствия льда на водном пространстве",
        ],
        correctAnswers: [
          "Не реже чем один раз в три года в период отсутствия льда на водном пространстве",
        ],
      },
      {
        code: "20206109",
        text: "В каком случае разрешается швартовка судов к ПБУ на ОПО МНГК?",
        answers: [
          "Во время постановки плавучей буровой установки (ПБУ) на точку бурения",
          "Если это оговорено в судовой документации",
          "Только при благоприятных метеоусловиях в специально отведенном месте с разрешения капитана ПБУ",
        ],
        correctAnswers: [
          "Только при благоприятных метеоусловиях в специально отведенном месте с разрешения капитана ПБУ",
        ],
      },
      {
        code: "20206110",
        text: "На каком максимальном расстоянии допускается расположение аварийно-спасательного судна от МСП, ПБУ, МЭ или ПТК, если эвакуация для ОПО МНГК организована с применением АСС?",
        answers: [
          "4 морских миль",
          "7 морских миль",
          "5 морских миль",
          "6 морских миль",
        ],
        correctAnswers: ["5 морских миль"],
      },
      {
        code: "20206111",
        text: "С какой периодичностью проводится проверка знаний по видам тревог во время учебных и тренировочных занятий на МСП, ПБУ, МЭ и ПТК?",
        answers: [
          "Не реже одного раза в неделю",
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в месяц",
        ],
        correctAnswers: ["Не реже одного раза в неделю"],
      },
      {
        code: "20206112",
        text: "Кем должны быть приняты законченные строительно-монтажные работы на ОПО МНГК или их составляющих?",
        answers: [
          "Комиссией подрядной строительной организации",
          "Комиссией территориального органа Ростехнадзора",
          "Комиссией, назначенной приказом эксплуатирующей организации",
        ],
        correctAnswers: [
          "Комиссией, назначенной приказом эксплуатирующей организации",
        ],
      },
      {
        code: "20206113",
        text: "В каком случае не допускается швартовка судов к ПБУ?",
        answers: [
          "В период постановки ПБУ на точку бурения",
          "Если получено разрешение капитана при благоприятных метеоусловиях",
          "Если швартовка выполняется в специально отведенном месте согласно инструкции по эксплуатации ПБУ",
          "Если это прописано в судовой документации",
        ],
        correctAnswers: ["В период постановки ПБУ на точку бурения"],
      },
      {
        code: "20206114",
        text: "На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?",
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          "На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на выделенной территории, находящихся в эксплуатации опасных производственных объектов",
          "На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности",
          "На ведение газоопасных, огневых и ремонтных работ на объектах тепло- и электроэнергетики",
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "20206115",
        text: "Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение месяца",
          "Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
      {
        code: "20206116",
        text: "Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?",
        answers: [
          "Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск, на необходимый для окончания работ срок",
          "Руководитель структурного подразделения, не более чем на 1 рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск, не более чем на 1 дневную смену",
        ],
        correctAnswers: [
          "Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
        ],
      },
      {
        code: "20206117",
        text: "В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?",
        answers: [
          "Не менее 1 месяца со дня закрытия наряда-допуска",
          "Не менее 1 года со дня закрытия наряда-допуска",
          "Не менее 6 месяцев со дня закрытия наряда-допуска",
        ],
        correctAnswers: ["Не менее 6 месяцев со дня закрытия наряда-допуска"],
      },
      {
        code: "20206118",
        text: "Каким документом определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?",
        answers: [
          "Организационно-распорядительными документами руководителя",
          "Технологическим регламентом",
          "Федеральными нормами и правилами в области промышленной безопасности",
          "Правилами пожарной безопасности",
        ],
        correctAnswers: [
          "Организационно-распорядительными документами руководителя",
        ],
      },
    ],
    20207: [
      {
        code: "20207000",
        text: "На какие магистральные трубопроводы не распространяются требования Правил безопасности для опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "На трубопроводы газового конденсата",
          "На трубопроводы широкой фракции легких углеводородов",
          "На трубопроводы сжиженных углеводородных газов",
          "На внутризаводские трубопроводы организаций, производящих и потребляющих жидкий аммиак",
          "На магистральные аммиакопроводы",
        ],
        correctAnswers: [
          "На внутризаводские трубопроводы организаций, производящих и потребляющих жидкий аммиак",
        ],
      },
      {
        code: "20207001",
        text: "В каком случае не применяются Правила безопасности для опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "При разработке технологических процессов, проектировании, строительстве, эксплуатации, реконструкции, техническом перевооружении, капитальном ремонте, консервации и ликвидации опасных производственных объектов магистральных трубопроводов",
          "При изготовлении, монтаже, наладке, обслуживании, диагностировании и ремонте технических устройств",
          "При проведении экспертизы промышленной безопасности документации на консервацию, ликвидацию, техническое перевооружение опасного производственного объекта",
          "При страховании гражданской ответственности владельцев опасных объектов",
        ],
        correctAnswers: [
          "При страховании гражданской ответственности владельцев опасных объектов",
        ],
      },
      {
        code: "20207002",
        text: "В соответствии с требованиями какого нормативно-правового документа обеспечивается пожарная безопасность опасных производственных объектов магистральных трубопроводов?",
        answers: [
          'Федерального закона от 22 июля 2008 г. N 123-ФЗ "Технический регламент о требованиях пожарной безопасности"',
          "Правил пожарной безопасности для опасных производственных объектов магистральных трубопроводов",
          'СП 36.13330.2012 "Свод правил. Магистральные трубопроводы"',
          'Федерального закона от 21 июля 1997 г. N 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
          "Внутренним инструкциям по пожарной безопасности",
        ],
        correctAnswers: [
          'Федерального закона от 22 июля 2008 г. N 123-ФЗ "Технический регламент о требованиях пожарной безопасности"',
        ],
      },
      {
        code: "20207003",
        text: "Что относится к опасным производственным объектам магистральных трубопроводов?",
        answers: [
          "Магистральные трубопроводы",
          "Объекты линейной части и площадочные сооружения",
          "Объекты добычи",
          "Объекты линейной части и вспомогательные объекты",
        ],
        correctAnswers: ["Объекты линейной части и площадочные сооружения"],
      },
      {
        code: "20207004",
        text: "Чем подтверждается соответствие комплектного оборудования и технических устройств на ОПО МТ при наличии технической документации изготовителя, а также при их соответствии требованиям технических регламентов и Правил безопасности для опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Сертификатом соответствия системы ГОСТ Р",
          "Документом соответствия требованиям технических регламентов или заключением экспертизы промышленной безопасности",
          "Сертификатом системы качества изготовителей",
          "Разрешением на применение технических устройств на опасных производственных объектах",
          "Техническим заданием",
          "Проектом",
        ],
        correctAnswers: [
          "Документом соответствия требованиям технических регламентов или заключением экспертизы промышленной безопасности",
        ],
      },
      {
        code: "20207005",
        text: "Что должно быть обеспечено на территории размещения линейных и площадочных сооружений опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Возможность строительства жилых зданий малой этажности",
          "Возможность строительства производственных сооружений",
          "Возможность проведения строительно-монтажных работ с использованием грузоподъемной и специальной техники, а также возможность размещения мест складирования оборудования и строительных материалов",
          "Возможность размещения складов взрывчатых веществ",
          "Возможность размещения бытовых помещений",
        ],
        correctAnswers: [
          "Возможность проведения строительно-монтажных работ с использованием грузоподъемной и специальной техники, а также возможность размещения мест складирования оборудования и строительных материалов",
        ],
      },
      {
        code: "20207006",
        text: "При каком расположении населенных пунктов и промышленных предприятий при прокладке нефтепроводов и нефтепродуктопроводов проектом должны быть предусмотрены технические решения, исключающие поступление транспортируемой по трубопроводу среды в зону застройки?",
        answers: [
          "Расположенных на отметках ниже этих трубопроводов на расстоянии от них менее 500 м при диаметре труб 700 мм и менее 1000 м при диаметре труб свыше 700 мм",
          "Расположенных на отметках ниже этих трубопроводов на расстоянии от них менее 600 м при диаметре труб 700 мм и менее 1100 м при диаметре труб свыше 700 мм",
          "Расположенных на отметках ниже этих трубопроводов на расстоянии от них менее 700 м при диаметре труб 700 мм и менее 1200 м при диаметре труб свыше 700 мм",
          "Расположенных на отметках ниже этих трубопроводов на расстоянии от них менее 800 м при диаметре труб 700 мм и менее 1300 м при диаметре труб свыше 700 мм",
        ],
        correctAnswers: [
          "Расположенных на отметках ниже этих трубопроводов на расстоянии от них менее 500 м при диаметре труб 700 мм и менее 1000 м при диаметре труб свыше 700 мм",
        ],
      },
      {
        code: "20207007",
        text: "В отношении каких из перечисленных участков ОПО МТ проектной документацией/документацией предусматриваются дополнительные меры, направленные на снижение риска аварий?",
        answers: [
          "Участков, проходящих по землям особо охраняемых природных территорий",
          "Всех перечисленных участков",
          "Переходов через автомобильные и железные дороги",
          "Участков, эксплуатируемых в природных условиях с повышенной опасностью экстремальных природных воздействий",
        ],
        correctAnswers: ["Всех перечисленных участков"],
      },
      {
        code: "20207008",
        text: "Какая из перечисленных специальных мер безопасности, снижающая риск аварии, не предусматривается проектной документацией/документацией для наиболее опасных участков объектов линейной части ОПО МТ?",
        answers: [
          "Увеличение диаметра трубопровода",
          "Увеличение глубины залегания трубопровода",
          "Увеличение толщины стенки трубопровода",
          "Устройство отводящих систем (каналов, канав)",
        ],
        correctAnswers: ["Увеличение диаметра трубопровода"],
      },
      {
        code: "20207009",
        text: "В какой документации определяются места установки и количество автоматических датчиков или пробоотборных устройств автоматических анализаторов на площадочных объектах ОПО МТ?",
        answers: [
          "В предписании Ростехнадзора",
          "В производственных инструкциях",
          "В технологическом регламенте",
          "В проектной документации/документации",
        ],
        correctAnswers: ["В проектной документации/документации"],
      },
      {
        code: "20207010",
        text: "Какой входной контроль должен быть организован на всех этапах выполнения работ по строительству, реконструкции, техническому перевооружению и капитальному ремонту ОПО МТ?",
        answers: [
          "Качества материалов и их наличие",
          "Технологических и технических операций",
          "Качества выполнения работ",
          "Конструкций, изделий, материалов, оборудования и технических устройств, качества выполнения работ и всех технологических операций",
          "Качества инструмента",
        ],
        correctAnswers: [
          "Конструкций, изделий, материалов, оборудования и технических устройств, качества выполнения работ и всех технологических операций",
        ],
      },
      {
        code: "20207011",
        text: "В какой документ заносятся результаты входного контроля конструкций, изделий, материалов, оборудования и технических устройств ОПО МТ?",
        answers: [
          "В паспорт оборудования",
          "В журнал входного контроля с оформлением акта проверки",
          "В протокол по итогам входного контроля",
          "Процедура занесения результатов входного контроля в какой-либо документ не регламентируется",
        ],
        correctAnswers: [
          "В журнал входного контроля с оформлением акта проверки",
        ],
      },
      {
        code: "20207012",
        text: "Каким образом определяются объем и методы неразрушающего контроля сварных соединений?",
        answers: [
          "Экспертной организацией",
          "Технической документацией на оборудование (паспорт, инструкция и т. д.)",
          "Организацией, осуществляющей ремонт опасных производственных объектов магистральных трубопроводов",
          "Проектной документацией / документацией",
        ],
        correctAnswers: ["Проектной документацией / документацией"],
      },
      {
        code: "20207013",
        text: "Каким образом определяются сроки и методы проведения работ по реконструкции, техническому перевооружению, капитальному ремонту и консервации опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "На основе проектной документации / документации, исходя из требований промышленной безопасности и условий обеспечения бесперебойной эксплуатации ОПО МТ",
          "Исходя из среды, транспортируемой по магистральным трубопроводам",
          "Исходя из конструктивных особенностей оборудования",
          "Исходя из природных условий в местах прокладки магистральных трубопроводов",
        ],
        correctAnswers: [
          "На основе проектной документации / документации, исходя из требований промышленной безопасности и условий обеспечения бесперебойной эксплуатации ОПО МТ",
        ],
      },
      {
        code: "20207014",
        text: "После чего следует начинать производство работ по реконструкции, техническому перевооружению и капитальному ремонту опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "После выполнения подготовительных мероприятий и письменного разрешения руководства эксплуатирующей организации на производство работ",
          "После выполнения подготовительных мероприятий, приемки объектов подрядчиком и письменного разрешения руководства эксплуатирующей организации на производство работ",
          "После приемки объектов подрядчиком",
          "После письменного разрешения руководства эксплуатирующей организации на производство работ",
          "После письменного приказа руководителя эксплуатирующей организации",
        ],
        correctAnswers: [
          "После выполнения подготовительных мероприятий, приемки объектов подрядчиком и письменного разрешения руководства эксплуатирующей организации на производство работ",
        ],
      },
      {
        code: "20207015",
        text: "Кого обязаны извещать о начале и сроках проведения работ по реконструкции, техническому перевооружению и капитальному ремонту линейных сооружений ОПО МТ производители работ перед началом выполнения этих работ?",
        answers: [
          "Организации (собственников), эксплуатирующие сооружения, проходящие в одном техническом коридоре с опасными производственными объектами магистральных трубопроводов",
          "Органы местного самоуправления",
          "Разработчика проектной документации",
          "Ростехнадзор",
        ],
        correctAnswers: [
          "Организации (собственников), эксплуатирующие сооружения, проходящие в одном техническом коридоре с опасными производственными объектами магистральных трубопроводов",
        ],
      },
      {
        code: "20207016",
        text: "Кого должны поставить в известность о начале и сроках работ по реконструкции, техническому перевооружению и капитальному ремонту линейных сооружений ОПО МТ производители работ перед началом выполнения данных работ?",
        answers: [
          "Проектную организацию",
          "Территориальные органы Ростехнадзора",
          "Местные органы муниципального управления",
          "Организации (собственников), эксплуатирующие сооружения, проходящие в одном техническом коридоре с ОПО МТ",
          "Подрядную организацию",
        ],
        correctAnswers: [
          "Организации (собственников), эксплуатирующие сооружения, проходящие в одном техническом коридоре с ОПО МТ",
        ],
      },
      {
        code: "20207017",
        text: "В течение какого времени заполнение линейных сооружений опасных производственных объектов магистральных трубопроводов углеводородами и его работу после заполнения считают комплексным опробованием линейного сооружения опасных производственных объектов магистральных трубопроводов?",
        answers: ["24 часов", "48 часов", "72 часов", "96 часов"],
        correctAnswers: ["72 часов"],
      },
      {
        code: "20207018",
        text: "Куда должны быть переданы материалы фактического положения трубопровода (исполнительная съемка) с привязкой охранных зон, входящих в его состав коммуникаций и объектов?",
        answers: [
          "В местные органы власти и управления",
          "В территориальные органы Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "В территориальные органы Ростехнадзора",
          "Заинтересованным федеральным органам исполнительной власти",
          "В проектную организацию",
        ],
        correctAnswers: ["В местные органы власти и управления"],
      },
      {
        code: "20207019",
        text: "За какое время до начала проведения в охранных зонах работ, требующих присутствия представителя предприятия трубопроводного транспорта, юридические или физические лица, имеющие намерение проводить работы, обязаны пригласить этого представителя на место производства работ?",
        answers: [
          "Не менее чем за 2 суток",
          "Не менее чем за 5 суток",
          "Не менее чем за 5 рабочих дней",
          "Нормативными документами не регламентируется",
        ],
        correctAnswers: ["Не менее чем за 5 суток"],
      },
      {
        code: "20207020",
        text: "Какие виды работ могут проводиться в охранных зонах трубопроводов без получения разрешения от предприятия трубопроводного транспорта?",
        answers: [
          "Строительные работы не ближе 20 м от оси трубопровода в каждую сторону",
          "Ремонтно-восстановительные и сельскохозяйственные работы",
          "Размещение автотранспорта на специально подготовленной площадке",
          "Прокладка подземного кабеля связи",
          "Огневые и газоопасные работы",
        ],
        correctAnswers: [
          "Ремонтно-восстановительные и сельскохозяйственные работы",
        ],
      },
      {
        code: "20207021",
        text: "Что из перечисленного не входит в мероприятия по предупреждению возможных аварий и обеспечению постоянной готовности к локализации и ликвидации последствий аварий на опасных производственных объектах магистральных трубопроводов?",
        answers: [
          "Формирование необходимых финансовых средств и материальных ресурсов для локализации и ликвидации последствий аварий",
          "Подготовка и аттестация руководителей и специалистов в области промышленной безопасности",
          "Контроль состояния технических устройств",
          "Оснащение средствами коллективной и индивидуальной защиты",
        ],
        correctAnswers: [
          "Подготовка и аттестация руководителей и специалистов в области промышленной безопасности",
        ],
      },
      {
        code: "20207022",
        text: "Что включает в себя планирование и осуществление мероприятий по предупреждению возможных аварий и обеспечению постоянной готовности к локализации и ликвидации последствий аварии на ОПО МТ?",
        answers: [
          "Разработку системы управления охраной труда",
          "Формирование необходимых финансовых средств и материальных ресурсов для локализации и ликвидации последствий аварий",
          "Аттестацию руководителей и специалистов в области промышленной безопасности",
          "Лицензирование и страхование",
        ],
        correctAnswers: [
          "Формирование необходимых финансовых средств и материальных ресурсов для локализации и ликвидации последствий аварий",
        ],
      },
      {
        code: "20207023",
        text: "К чему из перечисленного не относится анализ опасностей технологических процессов, количественный анализ риска и иные методы анализа риска аварий, связанных с выбросом транспортируемых углеводородов?",
        answers: [
          "К декларированию промышленной безопасности",
          "К классификации опасных производственных объектов по степени опасности для регистрации в государственном реестре",
          "К обоснованию безопасности опасных производственных объектов магистральных трубопроводов",
          "К системе управления промышленной безопасностью опасных производственных объектов магистральных трубопроводов",
        ],
        correctAnswers: [
          "К классификации опасных производственных объектов по степени опасности для регистрации в государственном реестре",
        ],
      },
      {
        code: "20207024",
        text: "В чем заключается основная задача анализа риска?",
        answers: [
          "В предоставлении должностным лицам, принимающим решения по обеспечению безопасности, сведений о наиболее опасных процессах, участках опасных производственных объектов магистральных трубопроводов",
          "В информировании федерального органа исполнительной власти в области промышленной безопасности о существующих рисках на опасных производственных объектах",
          "В определении сумм потенциального ущерба в случае возникновения аварии на опасных производственных объектах",
          "В информировании населения о существующих рисках на опасных производственных объектах",
        ],
        correctAnswers: [
          "В предоставлении должностным лицам, принимающим решения по обеспечению безопасности, сведений о наиболее опасных процессах, участках опасных производственных объектов магистральных трубопроводов",
        ],
      },
      {
        code: "20207025",
        text: "Что не относится к основным этапам процесса проведения количественного анализа риска аварии на опасных производственных объектах магистральных трубопроводов?",
        answers: [
          "Оценка риска методом НАZOP",
          "Идентификация опасностей аварий",
          "Количественная оценка риска аварий на опасных производственных объектах магистральных трубопроводов",
          "Разработка рекомендаций по снижению риска аварий",
        ],
        correctAnswers: ["Оценка риска методом НАZOP"],
      },
      {
        code: "20207026",
        text: "Какие из перечисленных степеней уровня риска аварии на ОПО МТ указаны верно?",
        answers: [
          "Предельная",
          "Критическая",
          "Чрезвычайно низкая",
          "Чрезвычайно высокая",
        ],
        correctAnswers: ["Чрезвычайно высокая"],
      },
      {
        code: "20207027",
        text: "В каком случае осуществляют технические мероприятия по консервации и ликвидации опасных производственных объектов магистральных трубопроводов           в соответствии с документацией на консервацию и ликвидацию опасных производственных объектов?",
        answers: [
          "После получения разрешения на консервацию и ликвидацию опасных производственных объектов, выдаваемого федеральным органом исполнительной власти в области промышленной безопасности",
          "После положительного заключения государственной экспертизы в соответствии с Градостроительным кодексом",
          "После проведения проверки опасных производственных объектов федеральным органом исполнительной власти в области промышленной безопасности",
          "После получения положительного заключения экспертизы промышленной безопасности на документацию по консервации и ликвидации",
        ],
        correctAnswers: [
          "После получения положительного заключения экспертизы промышленной безопасности на документацию по консервации и ликвидации",
        ],
      },
      {
        code: "20207028",
        text: "Кто устанавливает продолжительность периода, на который ОПО МТ выводят из эксплуатации, условия нахождения в резерве (консервация или периодическое включение в работу в целях поддержания работоспособного состояния ОПО МТ)?",
        answers: [
          "Проектная организация",
          "Федеральный орган исполнительной власти в области промышленной безопасности",
          "Экспертная организация",
          "Эксплуатирующая организация",
          "Подрядная организация, осуществляющая ремонт, реконструкцию",
        ],
        correctAnswers: ["Эксплуатирующая организация"],
      },
      {
        code: "20207029",
        text: "Какой документ разрабатывают для вывода опасных производственных объектов магистральных трубопроводов из консервации и ввода их в эксплуатацию?",
        answers: [
          "Технический регламент с указанием перечня работ",
          "График выполнения мероприятий по вводу опасных производственных объектов магистральных трубопроводов в эксплуатацию",
          "Рабочую программу с указанием перечня работ, порядка и сроков их выполнения",
        ],
        correctAnswers: [
          "Рабочую программу с указанием перечня работ, порядка и сроков их выполнения",
        ],
      },
      {
        code: "20207030",
        text: "Что, согласно требованиям нормативных правовых актов и нормативных технических документов, допускается не проводить при выводе из консервации опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Ревизию трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов",
          "Опробование трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов",
          "Техническое диагностирование трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов",
          "Испытания трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов",
        ],
        correctAnswers: [
          "Техническое диагностирование трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов",
        ],
      },
      {
        code: "20207031",
        text: "Кто составляет акт о вводе объекта в действие с приложением перечня выполненных работ при выводе ОПО МТ из консервации?",
        answers: [
          "Территориальный орган Ростехнадзора",
          "Экспертная организация",
          "Эксплуатирующая организация",
          "Проектная организация",
          "Подрядная организация",
        ],
        correctAnswers: ["Эксплуатирующая организация"],
      },
      {
        code: "20207032",
        text: "В течение какого времени после пробной эксплуатации опасных производственных объектов магистральных трубопроводов эксплуатирующая организация составляет акт о вводе объекта в действие с приложением перечня выполненных работ при выводе опасных производственных объектов магистральных трубопроводов из консервации?",
        answers: ["12 часов", "24 часов", "48 часов", "72 часов"],
        correctAnswers: ["72 часов"],
      },
      {
        code: "20207033",
        text: "Какие работы должны быть проведены перед началом осуществления работ по выводу из эксплуатации ОПО МТ и ОПО МАП, подлежащих ликвидации?",
        answers: [
          "Работы по освобождению трубопроводов и оборудования данных объектов от углеводородов или жидкого аммиака",
          "Работы по очистке трубопроводов",
          "Ревизия и проверка трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов",
          "Техническое диагностирование трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов",
        ],
        correctAnswers: [
          "Работы по освобождению трубопроводов и оборудования данных объектов от углеводородов или жидкого аммиака",
        ],
      },
      {
        code: "20207034",
        text: "Допускается ли выброс углеводородов в окружающую среду способами, не предусмотренными проектной документацией/документацией на ликвидацию ОПО МТ, при освобождении трубопроводов и оборудования перед началом осуществления работ по выводу из эксплуатации опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Не допускается",
          "Допускается только после согласования с федеральным органом исполнительной власти в области охраны окружающей среды",
          "Зависит от транспортируемого углеводорода",
          "Допускается вне акваторий и иных особо охраняемых территорий",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "20207035",
        text: "Каким требованиям должен соответствовать технологический регламент на эксплуатацию опасных производственных объектов магистральных трубопроводов, определяющий порядок организации надежного и безопасного ведения технологического процесса?",
        answers: [
          "Проектным решениям",
          "Действительным характеристикам трубопровода",
          "Требованиям законодательства Российской Федерации в области промышленной безопасности и нормативных технических документов",
          "Проектным решениям, условиям работы опасных производственных объектов магистральных трубопроводов, требованиям законодательства Российской Федерации в области промышленной безопасности и законодательства Российской Федерации о техническом регулировании",
        ],
        correctAnswers: [
          "Проектным решениям, условиям работы опасных производственных объектов магистральных трубопроводов, требованиям законодательства Российской Федерации в области промышленной безопасности и законодательства Российской Федерации о техническом регулировании",
        ],
      },
      {
        code: "20207036",
        text: "Что из перечисленного допускается не включать в технологический регламент на эксплуатацию опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Порядок контроля и управления технологическим процессом",
          "Анализ риска аварий на участке",
          "Порядок обнаружения утечек",
          "Паспортные характеристики технических устройств, применяемых на опасных производственных объектах магистральных трубопроводов",
          "Порядок приема, сдачи и учета перекачиваемых продуктов",
        ],
        correctAnswers: ["Анализ риска аварий на участке"],
      },
      {
        code: "20207037",
        text: "Когда должен быть разработан технологический регламент на эксплуатацию опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "После ввода в эксплуатацию опасных производственных объектов магистральных трубопроводов",
          "До начала строительства опасных производственных объектов магистральных трубопроводов",
          "В течение 10 рабочих дней после ввода опасных производственных объектов магистральных трубопроводов в эксплуатацию",
          "До прохождения экспертизы проектной документации",
          "Сроки разработки технологического регламента не определены",
        ],
        correctAnswers: [
          "После ввода в эксплуатацию опасных производственных объектов магистральных трубопроводов",
        ],
      },
      {
        code: "20207038",
        text: "В каких случаях требуется пересмотр технологического регламента на эксплуатацию магистральных трубопроводов?",
        answers: [
          "Изменения требований промышленной безопасности",
          "Изменения параметров ведения технологического процесса",
          "В случаях, предусмотренных законодательством Российской Федерации в области промышленной безопасности",
          "Во всех вышеперечисленных случаях",
        ],
        correctAnswers: ["Во всех вышеперечисленных случаях"],
      },
      {
        code: "20207039",
        text: "Что должно быть обеспечено для контроля состояния линейных участков, сооружений и объектов ОПО МТ и выявления факторов, создающих угрозу безопасности при их эксплуатации?",
        answers: [
          "Постоянный и периодический контроль (патрулирование) линейных сооружений, осуществляемый в соответствии с утвержденными графиками",
          "Видеонаблюдение",
          "Установка датчиков",
          "Установка системы блокировок и противоаварийной защиты",
        ],
        correctAnswers: [
          "Постоянный и периодический контроль (патрулирование) линейных сооружений, осуществляемый в соответствии с утвержденными графиками",
        ],
      },
      {
        code: "20207040",
        text: "Какие виды патрулирования используются для контроля состояния линейных участков, сооружений и объектов ОПО МТ?",
        answers: [
          "Пеший обход",
          "Проезд автотранспортом",
          "Авиапатрулирование",
          "Все перечисленные виды патрулирования",
        ],
        correctAnswers: ["Все перечисленные виды патрулирования"],
      },
      {
        code: "20207041",
        text: "Что допускается не учитывать при определении периодичности и методов патрулирования трассы линейных сооружений опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Техническое состояние трубопроводов",
          "Особенности участка прокладки трубопровода",
          "Природные факторы, влияющие на безопасность эксплуатации трубопровода",
          "Транспортируемые на опасных производственных объектах магистральных трубопроводов продукты",
        ],
        correctAnswers: [
          "Транспортируемые на опасных производственных объектах магистральных трубопроводов продукты",
        ],
      },
      {
        code: "20207042",
        text: "Чем должны быть определены объем и периодичность выполняемых работ при техническом обслуживании опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Проектной документацией и нормативными документами производителей оборудования",
          "Технологическим регламентом на эксплуатацию опасных производственных объектов магистральных трубопроводов",
          "Нормативно-техническими документами заводов-изготовителей к трубам, материалам и оборудованию",
          "Проектной документацией/документацией, технологическим регламентом на эксплуатацию ОПО МТ и ОПО МАП, нормативно-техническими документами заводов-изготовителей к трубам, материалам и оборудованию, а также результатами контроля технического состояния",
          "Федеральными нормами и правилами в области промышленной безопасности",
        ],
        correctAnswers: [
          "Проектной документацией/документацией, технологическим регламентом на эксплуатацию ОПО МТ и ОПО МАП, нормативно-техническими документами заводов-изготовителей к трубам, материалам и оборудованию, а также результатами контроля технического состояния",
        ],
      },
      {
        code: "20207043",
        text: "Какие условия должны быть обеспечены в процессе эксплуатации опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Контроль технического состояния опасных производственных объектов магистральных трубопроводов с применением необходимых методов технического диагностирования и меры по закреплению трубопровода на проектных отметках в случае его смещения",
          "Контроль технического состояния опасных производственных объектов магистральных трубопроводов с применением необходимых методов технического диагностирования и укомплектованность рабочих мест необходимой документацией, запасами материалов, запасными частями, инвентарем, средствами индивидуальной и коллективной защиты согласно установленным нормам",
          "Укомплектованность рабочих мест необходимой документацией, запасами материалов, запасными частями, инвентарем, средствами индивидуальной и коллективной защиты согласно установленным нормам и меры по закреплению трубопровода на проектных отметках в случае его смещения",
          "Сохранность опознавательных знаков трассы",
        ],
        correctAnswers: [
          "Контроль технического состояния опасных производственных объектов магистральных трубопроводов с применением необходимых методов технического диагностирования и меры по закреплению трубопровода на проектных отметках в случае его смещения",
        ],
      },
      {
        code: "20207044",
        text: "На каком расстоянии производится установка опознавательных знаков обозначения трассы магистрального трубопровода?",
        answers: [
          "В пределах прямой видимости",
          "В пределах прямой видимости, но не реже чем через 200 м",
          "В пределах прямой видимости, но не реже чем через 400 м и на углах поворота",
          "В пределах прямой видимости, но не реже чем через 500 м и на углах поворота",
          "Не регламентируется",
        ],
        correctAnswers: [
          "В пределах прямой видимости, но не реже чем через 500 м и на углах поворота",
        ],
      },
      {
        code: "20207045",
        text: "Какая информация не приводится на щите-указателе опознавательного знака обозначения трассы магистрального трубопровода?",
        answers: [
          "Местоположение оси трубопровода от основания знака",
          "Разрешенное рабочее давление трубопровода",
          "Привязка знака (км, пк) к трассе",
          "Размеры охранной зоны",
          "Телефоны и адреса диспетчерской и аварийной служб производственного подразделения предприятия трубопроводного транспорта, эксплуатирующего данный участок трубопровода",
        ],
        correctAnswers: ["Разрешенное рабочее давление трубопровода"],
      },
      {
        code: "20207046",
        text: "Каким образом должны быть обозначены трассы нефтепродуктопроводов в соответствии с правилами охраны магистральных трубопроводов?",
        answers: [
          "Трассы должны быть обозначены столбиками высотой 1 м от поверхности земли, устанавливаемыми в пределах прямой видимости, на углах поворота, водных и воздушных переходах, а также при пересечении трубопроводом шоссейных и железных дорог",
          "Трассы должны быть обозначены предупреждающими знаками в пределах прямой видимости, но не реже чем через 1,5 км, на углах поворота, водных и воздушных переходах, а также при пересечении трубопроводом шоссейных и железных дорог",
          "Трассы должны быть обозначены опознавательными знаками (со щитами-указателями) высотой 1,5 - 2 м от поверхности земли, устанавливаемыми в пределах прямой видимости, но не реже чем через 500 м и на углах поворота",
          "Трассы должны быть обозначены предупреждающими знаками высотой 2,5 м от поверхности земли в пределах видимости, на водных и воздушных переходах - столбиками высотой 1,5 - 2 м",
        ],
        correctAnswers: [
          "Трассы должны быть обозначены опознавательными знаками (со щитами-указателями) высотой 1,5 - 2 м от поверхности земли, устанавливаемыми в пределах прямой видимости, но не реже чем через 500 м и на углах поворота",
        ],
      },
      {
        code: "20207047",
        text: "Какой знак должен устанавливаться в местах пересечения магистральных нефтепроводов с автомобильными дорогами всех категорий?",
        answers: [
          "Предупреждающий знак",
          "Дорожный знак, запрещающий остановку транспорта",
          "Предписывающий знак",
          "Разрешающий знак",
        ],
        correctAnswers: ["Дорожный знак, запрещающий остановку транспорта"],
      },
      {
        code: "20207048",
        text: "В какое время суток работники, обслуживающие нефтепроводы, допускаются для проведения осмотров и ремонтных работ, если нефтепроводы проходят по территории запретных зон и специальных объектов?",
        answers: [
          "В ночное время",
          "В дневное время",
          "По усмотрению соответствующих организаций, выдающих пропуски",
          "В любое время суток",
        ],
        correctAnswers: ["В любое время суток"],
      },
      {
        code: "20207049",
        text: "За сколько суток до начала работ по устройству в пределах охранной зоны шурфов для проверки качества изоляции нефтепроводов и состояния средств их электрохимической защиты от коррозии необходимо уведомить землепользователя?",
        answers: [
          "Не менее чем за 3 суток",
          "Не менее чем за сутки",
          "Не менее чем за 10 суток",
          "Не менее чем за 5 суток",
        ],
        correctAnswers: ["Не менее чем за 5 суток"],
      },
      {
        code: "20207050",
        text: "Каким образом производятся работы, связанные с временным затоплением земель, находящихся в охранных зонах трубопроводов?",
        answers: [
          "По согласованию между землепользователем и Минприроды",
          "По согласованию между землепользователем, предприятием трубопроводного транспорта и Ростехнадзором",
          "По согласованию между землепользователем и предприятием трубопроводного транспорта",
          "Не регламентируется",
        ],
        correctAnswers: [
          "По согласованию между землепользователем и предприятием трубопроводного транспорта",
        ],
      },
      {
        code: "20207051",
        text: "Какие меры необходимо предпринять при обнаружении на месте производства работ в охранной зоне подземных коммуникаций и сооружений, не указанных в проектной документации? Укажите все правильные ответы.",
        answers: [
          "Немедленно вывести персонал и технические средства за пределы этой зоны",
          "Вызвать представителя эксплуатационной организации",
          "Вызвать аварийно-восстановительную бригаду",
          "Немедленно остановить работы",
        ],
        correctAnswers: [
          "Вызвать представителя эксплуатационной организации",
          "Немедленно остановить работы",
        ],
      },
      {
        code: "20207052",
        text: "Кому обязан немедленно сообщить о повреждении трубопровода производственный персонал, выполняющий осмотр или обслуживание инженерных коммуникаций и объектов, находящихся в районе прохождения трубопровода?",
        answers: [
          "Представителю Ростехнадзора",
          "Соответствующим органам власти и управления",
          "Владельцам этих объектов",
          "Диспетчерской или аварийной службе производственного подразделения, эксплуатирующего данный участок трубопровода",
        ],
        correctAnswers: [
          "Диспетчерской или аварийной службе производственного подразделения, эксплуатирующего данный участок трубопровода",
        ],
      },
      {
        code: "20207053",
        text: "В какой срок должны сообщать предприятия, эксплуатирующие инженерные коммуникации, проходящие в техническом коридоре, всем заинтересованным предприятиям вносимые изменения в схему объектов с точным указанием их взаиморасположения?",
        answers: [
          "В недельный срок",
          "В месячный срок",
          "В десятидневный срок",
          "В трехдневный срок",
        ],
        correctAnswers: ["В месячный срок"],
      },
      {
        code: "20207054",
        text: "В каких целях проводят периодическое техническое диагностирование опасных производственных объектов магистральных трубопроводов в процессе эксплуатации?",
        answers: [
          "В целях обеспечения безопасности",
          "В целях определения фактического технического состояния опасных производственных объектов магистральных трубопроводов",
          "В целях расчета допустимого давления",
          "В целях определения возможности дальнейшей эксплуатации на проектных технологических режимах",
          "Во всех перечисленных целях",
        ],
        correctAnswers: ["Во всех перечисленных целях"],
      },
      {
        code: "20207055",
        text: "С учетом каких факторов определяются сроки и методы технического диагностирования опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Опасности и технического состояния участков линейной части опасных производственных объектов магистральных трубопроводов",
          "Показателей эксплуатации (срок службы, ресурс), установленных проектной и/или нормативно-технической документацией",
          "Опасности и технического состояния сооружений и технических устройств площадочных сооружений опасных производственных объектов магистральных трубопроводов",
          "Всех вышеперечисленных факторов",
        ],
        correctAnswers: ["Всех вышеперечисленных факторов"],
      },
      {
        code: "20207056",
        text: "На основании каких данных определяют величину разрешенного (допустимого) рабочего давления?",
        answers: [
          "На основании результатов технического диагностирования и в соответствии с нормативно-технической документацией по эксплуатации опасных производственных объектов магистральных трубопроводов",
          "На основании решения эксплуатирующей организации, оформленного протоколом",
          "На основании решения Ростехнадзора",
          "На основании проектных решений",
          "На основании технического задания",
        ],
        correctAnswers: [
          "На основании результатов технического диагностирования и в соответствии с нормативно-технической документацией по эксплуатации опасных производственных объектов магистральных трубопроводов",
        ],
      },
      {
        code: "20207057",
        text: "Для каких объектов требуется оформление формуляра (паспорта) подтверждения величины разрешенного (допустимого) рабочего давления?",
        answers: [
          "Для объектов, вводимых в эксплуатацию по завершении реконструкции",
          "Для объектов, вводимых в эксплуатацию по завершении строительства",
          "Для действующих объектов, на которых проведено изменение величины разрешенного (допустимого) рабочего давления",
          "Для всех перечисленных объектов",
        ],
        correctAnswers: ["Для всех перечисленных объектов"],
      },
      {
        code: "20207058",
        text: "Какие сведения может не содержать формуляр (паспорт) подтверждения величины разрешенного (допустимого) рабочего давления на опасных производственных объектах магистральных трубопроводов?",
        answers: [
          "Об участке (номере участка) опасного производственного объекта магистрального трубопровода",
          "О сроках проведения следующего технического диагностирования",
          "О величине разрешенного давления",
          "О необходимости обеспечения объекта предохранительными устройствами для ограничения величины рабочего давления",
        ],
        correctAnswers: [
          "О сроках проведения следующего технического диагностирования",
        ],
      },
      {
        code: "20207059",
        text: "Где должен храниться формуляр (паспорт), на основании которого была установлена величина разрешенного (допустимого) рабочего давления?",
        answers: [
          "В архиве эксплуатирующей организации",
          "В архиве эксплуатирующей организации и органах местного самоуправления",
          "В Ростехнадзоре",
          "В проектной организации",
          "В техническом отделе эксплуатирующей организации",
        ],
        correctAnswers: ["В архиве эксплуатирующей организации"],
      },
      {
        code: "20207060",
        text: "В течение какого периода эксплуатирующая организация обязана проводить периодические обследования трубопроводов и оборудования ОПО МТ?",
        answers: [
          "В течение срока, установленного проектной документацией",
          "В течение 25 лет",
          "В течение всего жизненного цикла (до ликвидации ОПО МТ)",
          "В течение срока амортизации трубопроводов и оборудования",
          "В течение 20 лет",
        ],
        correctAnswers: [
          "В течение всего жизненного цикла (до ликвидации ОПО МТ)",
        ],
      },
      {
        code: "20207061",
        text: "Какие сведения допускается не учитывать при определении периодичности, полноты и порядка обследования, методов и средств контроля трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Данные о строительстве магистральных трубопроводов",
          "Информацию о техническом состоянии магистральных трубопроводов",
          "Информацию об условиях эксплуатации магистральных трубопроводов",
          "Сведения об устранении дефектов в результате планового капремонта",
          "Особенности района расположения (наличие охранных зон, наиболее опасных участков)",
          "Информацию о свойствах обращаемого продукта",
        ],
        correctAnswers: [
          "Сведения об устранении дефектов в результате планового капремонта",
        ],
      },
      {
        code: "20207062",
        text: "Проведение какого вида работ не предусматривается при техническом диагностировании линейной части опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Внутритрубной дефектоскопии путем пропуска внутритрубных средств диагностики",
          "Внешнего дефектоскопического обследования с применением методов неразрушающего контроля",
          "Гидравлических (пневматических) испытаний на прочность",
          "Оценки состояния изоляционных покрытий",
        ],
        correctAnswers: [
          "Гидравлических (пневматических) испытаний на прочность",
        ],
      },
      {
        code: "20207063",
        text: "Что должна включать оценка технического состояния оборудования площадочных сооружений опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Только наружное обследование в режиме эксплуатации",
          "Только полное техническое обследование в режиме выведения из эксплуатации (временного или длительного)",
          "Наружное обследование в режиме эксплуатации и полное техническое обследование в режиме выведения из эксплуатации (временного или длительного)",
          "Установку заглушек",
          "Опорожнение",
        ],
        correctAnswers: [
          "Наружное обследование в режиме эксплуатации и полное техническое обследование в режиме выведения из эксплуатации (временного или длительного)",
        ],
      },
      {
        code: "20207064",
        text: "Что из перечисленного не следует проводить перед обследованием оборудования площадочных сооружений ОПО МТ в связи с выводом его из эксплуатации?",
        answers: [
          "Опорожнение",
          "Очистку",
          "Дегазацию",
          "Опрессовку",
          "Установку заглушек",
        ],
        correctAnswers: ["Опрессовку"],
      },
      {
        code: "20207065",
        text: "На основании чего составляется график ремонта (включая капитальный ремонт) опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Результатов неразрушающего контроля",
          "Результатов технического обследования",
          "Результатов экспертизы промышленной безопасности",
          "Регулярных осмотров",
        ],
        correctAnswers: ["Результатов технического обследования"],
      },
      {
        code: "20207066",
        text: "Какой вид работ относится к частичному диагностированию стальных резервуаров?",
        answers: [
          "Вывод из эксплуатации и опорожнение резервуара",
          "Очистка и дегазация резервуара",
          "Обследование резервуара с наружной стороны без выведения из эксплуатации",
          "Внутренний осмотр",
        ],
        correctAnswers: [
          "Обследование резервуара с наружной стороны без выведения из эксплуатации",
        ],
      },
      {
        code: "20207067",
        text: "На кого возлагается организация проведения работ по техническому диагностированию сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов?",
        answers: [
          "На организацию по ремонту и обслуживанию резервуаров",
          "На экспертную организацию",
          "На владельца резервуаров",
          "На проектную организацию",
          "На завод - изготовитель резервуара",
        ],
        correctAnswers: ["На владельца резервуаров"],
      },
      {
        code: "20207068",
        text: "В отношении каких резервуаров диагностирование и заключение о техническом состоянии и о возможности дальнейшей эксплуатации сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов производятся специализированной организацией? Укажите все правильные ответы.",
        answers: [
          "Резервуаров, сооруженных не по типовым проектам",
          "Резервуаров, сооруженных по импортным поставкам",
          "Резервуаров со сроками эксплуатации, превышающими 10 лет",
          "В отношении всех перечисленных резервуаров",
          "Резервуаров со сроками эксплуатации, превышающими 20 лет",
        ],
        correctAnswers: [
          "Резервуаров, сооруженных не по типовым проектам",
          "Резервуаров, сооруженных по импортным поставкам",
        ],
      },
      {
        code: "20207069",
        text: "Какая должна быть точность определения толщин листовых конструкций и глубины коррозионных язв сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов приборами или средствами линейных измерений?",
        answers: [
          "Не менее ±0,1 мм",
          "Не менее ±0,2 мм",
          "Не менее ±0,5 мм",
          "Не менее ±0,3 мм",
        ],
        correctAnswers: ["Не менее ±0,1 мм"],
      },
      {
        code: "20207070",
        text: "Кто дает разрешение на производство полного технического обследования сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов?",
        answers: [
          "Инспектор Ростехнадзора",
          "Руководство предприятия - владельца резервуара",
          "Экспертная организация",
          "Проектная организация",
        ],
        correctAnswers: ["Руководство предприятия - владельца резервуара"],
      },
      {
        code: "20207071",
        text: "В каком случае допускается разрабатывать общую индивидуальную программу на группу сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов при проведении работ по техническому диагностированию?",
        answers: [
          "Только если группа резервуаров с одинаковыми сроками эксплуатации, работающих в одинаковых условиях",
          "Только если резервуары сооружены не по типовым проектам или по импортным поставкам",
          "Если это согласовано с территориальными органами Ростехнадзора",
          "Ни в каком случае, на каждый резервуар должна быть разработана индивидуальная программа",
          "Если специализированной организацией проведено диагностирование",
        ],
        correctAnswers: [
          "Только если группа резервуаров с одинаковыми сроками эксплуатации, работающих в одинаковых условиях",
        ],
      },
      {
        code: "20207072",
        text: "Кем разрабатываются индивидуальные программы обследования сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов при проведении работ по техническому диагностированию?",
        answers: [
          "Владельцем резервуаров",
          "Эксплуатирующей организацией",
          "Организацией по ремонту и обслуживанию резервуаров",
          "Организацией, выполняющей обследование",
          "Проектной организацией",
        ],
        correctAnswers: ["Организацией, выполняющей обследование"],
      },
      {
        code: "20207073",
        text: "Какая величина нормативного расчетного срока службы сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов принимается в случае, если нормативный расчетный срок службы отсутствует в нормативно-технической документации, паспорте или инструкции по эксплуатации?",
        answers: ["10 лет", "20 лет", "15 лет", "25 лет"],
        correctAnswers: ["20 лет"],
      },
      {
        code: "20207074",
        text: "Какие из перечисленных сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов должны подвергаться первоочередному техническому обследованию?",
        answers: [
          "Все перечисленные",
          "Изготовленные из кипящих сталей и сваренные электродами с меловой обмазкой",
          "В которых хранятся высококоррозионные по отношению к металлу несущих конструкций продукты",
          "Находящиеся в эксплуатации более 20 лет",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20207075",
        text: "С какой периодичностью проводится частичное наружное обследование сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов в пределах расчетного срока службы?",
        answers: [
          "Не реже одного раза в 5 лет",
          "Не реже одного раза в 10 лет",
          "Не реже одного раза в 3 года",
          "Не реже одного раза в год",
        ],
        correctAnswers: ["Не реже одного раза в 5 лет"],
      },
      {
        code: "20207076",
        text: "Какой из перечисленных этапов обследования не относится к частичному наружному обследованию сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов в пределах расчетного срока службы?",
        answers: [
          "Проверка состояния основания и отмостки",
          "Измерение толщины поясов стенки, выступающих окрайков днища и настила кровли",
          "Контроль методами дефектоскопии",
          "Измерение геометрической формы стенки и нивелирование наружного контура днища",
        ],
        correctAnswers: ["Контроль методами дефектоскопии"],
      },
      {
        code: "20207077",
        text: "С какой периодичностью проводится полное обследование сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов в пределах расчетного срока службы?",
        answers: [
          "Не реже одного раза в 10 лет",
          "Не реже одного раза в 3 года",
          "Не реже одного раза в 5 лет",
          "Не реже одного раза в 15 лет",
        ],
        correctAnswers: ["Не реже одного раза в 10 лет"],
      },
      {
        code: "20207078",
        text: "Какой из перечисленных этапов обследования не относится к полному обследованию сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов в пределах расчетного срока службы? Укажите все правильные ответы.",
        answers: [
          "Оценка физико-механических свойств и структуры металла",
          "Проверка состояния основания и отмостки",
          "Оценка механических свойств материала и его структуры (методами неразрушающего контроля или лабораторного исследования)",
          "Определение наиболее нагруженных, работающих в наиболее тяжелых и сложных условиях элементов резервуара",
          "Проверка состояния понтона (плавающей крыши)",
          "Анализ условий эксплуатации",
        ],
        correctAnswers: [
          "Оценка физико-механических свойств и структуры металла",
          "Оценка механических свойств материала и его структуры (методами неразрушающего контроля или лабораторного исследования)",
        ],
      },
      {
        code: "20207079",
        text: "С какой периодичностью проводится полное обследование сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов, отработавших расчетный срок службы?",
        answers: [
          "Не реже одного раза в 15 лет",
          "Не реже одного раза в 5 лет",
          "Не реже одного раза в 8 лет",
          "Не реже одного раза в 10 лет",
        ],
        correctAnswers: ["Не реже одного раза в 8 лет"],
      },
      {
        code: "20207080",
        text: "С какой периодичностью проводится частичное обследование сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов, отработавших расчетный срок службы?",
        answers: [
          "Не реже одного раза в 5 лет",
          "Не реже одного раза в 4 года",
          "Не реже одного раза в 8 лет",
          "Не реже одного раза в 2 года",
        ],
        correctAnswers: ["Не реже одного раза в 4 года"],
      },
      {
        code: "20207081",
        text: "Какая документация выдается в случае экономической или технической нецелесообразности ремонта сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов?",
        answers: [
          "Заключение об оценке остаточного ресурса",
          "Заключение о методах последующего контроля",
          "Заключение об исключении резервуара из эксплуатации",
        ],
        correctAnswers: ["Заключение об исключении резервуара из эксплуатации"],
      },
      {
        code: "20207082",
        text: "Какие из перечисленных участков конструкций сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов представляются наиболее предрасположенными к разрушению?",
        answers: [
          "Участки стенки, имеющие местные выпучины или вмятины и отклонения образующих от вертикали за пределами допусков",
          "Места стенки у нижнего уторного шва, соединяющего стенку с днищем",
          "Все перечисленные участки",
          "Места присоединения трубопроводов, передающих вибрационные нагрузки",
        ],
        correctAnswers: ["Все перечисленные участки"],
      },
      {
        code: "20207083",
        text: "С какого участка конструкции сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов рекомендуется начинать осмотр поверхности основного металла?",
        answers: [
          "С настила и несущих элементов кровли",
          "С мест переменного уровня нефтепродуктов",
          "С окрайки днища и нижней части первого пояса",
          "С наружной и внутренней части первого и второго поясов",
        ],
        correctAnswers: ["С окрайки днища и нижней части первого пояса"],
      },
      {
        code: "20207084",
        text: "Каким образом допускается проводить осмотр верхних поясов сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов?",
        answers: [
          "С помощью подзорной трубы",
          "С помощью бинокля",
          "С применением подвесной люльки",
          "С применением всего перечисленного",
        ],
        correctAnswers: ["С применением всего перечисленного"],
      },
      {
        code: "20207085",
        text: "Какого вида коррозионных повреждений резервуаров не существует?",
        answers: [
          "Равномерная коррозия",
          "Пятнистая коррозия",
          "Частичная коррозия",
          "Местная коррозия",
        ],
        correctAnswers: ["Частичная коррозия"],
      },
      {
        code: "20207086",
        text: "На каком расстоянии от сварных соединений четырех нижних поясов вертикальных цилиндрических резервуаров для нефти и нефтепродуктов подлежат визуальному осмотру прилегающие к ним зоны основного металла?",
        answers: [
          "Не менее 50 мм",
          "Не менее 20 мм",
          "Не менее 60 мм",
          "Не менее 30 мм",
        ],
        correctAnswers: ["Не менее 20 мм"],
      },
      {
        code: "20207087",
        text: "Каким должно быть минимальное расстояние между сварными швами окраек днища и вертикальными сварными швами первого пояса вертикальных цилиндрических резервуаров для нефти и нефтепродуктов?",
        answers: ["100 мм", "200 мм", "300 мм", "500 мм"],
        correctAnswers: ["200 мм"],
      },
      {
        code: "20207088",
        text: "Каким перечисленным условиям должны соответствовать толщиномеры при определении толщины металла сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов?",
        answers: [
          "Допускается применять толщиномеры любого типа",
          "Измерять толщину с точностью 0,1 мм",
          "Измерять толщину при температуре окружающего воздуха от -30 до +30 °С",
          "Измерять толщину в интервале 0,1 - 30,0 мм",
        ],
        correctAnswers: ["Измерять толщину с точностью 0,1 мм"],
      },
      {
        code: "20207089",
        text: "В каком случае допускается применение штангенциркуля при определении толщины металла сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов?",
        answers: [
          "В случае если объем резервуара не более 10 м³",
          "Ни в каком случае",
          "В доступных местах",
          "В местах, наиболее пораженных коррозией",
        ],
        correctAnswers: ["В доступных местах"],
      },
      {
        code: "20207090",
        text: "Какое из перечисленных требований при проведении натурного обследования сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов указано верно?",
        answers: [
          "При измерении толщины нескольких листов в пределах одного пояса или любого другого элемента резервуара за действительную толщину данного элемента принимается максимальная толщина отдельного листа",
          "Толщина листов днища и настила кровли измеряется по двум взаимно перпендикулярным диаметральным направлениям; проводится не менее трех измерений на каждом листе",
          "При измерении толщины листа в нескольких точках (не менее двух) за его действительную толщину принимается величина из всех измерений",
          "Измерения толщин следует проводить в местах, наименее пораженных коррозией",
        ],
        correctAnswers: [
          "Толщина листов днища и настила кровли измеряется по двум взаимно перпендикулярным диаметральным направлениям; проводится не менее трех измерений на каждом листе",
        ],
      },
      {
        code: "20207091",
        text: "Каким образом измеряется толщина нижних трех поясов сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов?",
        answers: [
          "Не менее чем по двум диаметрально противоположным образующим в четырех точках по высоте пояса",
          "Не менее чем по трем образующим в трех точках по высоте пояса",
          "Не менее чем по пяти образующим в двух точках по высоте пояса",
          "Не менее чем по четырем диаметрально противоположным образующим в трех точках по высоте пояса",
        ],
        correctAnswers: [
          "Не менее чем по четырем диаметрально противоположным образующим в трех точках по высоте пояса",
        ],
      },
      {
        code: "20207092",
        text: "Каким образом измеряется толщина листов днища и настила кровли сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов?",
        answers: [
          "По трем направлениям; проводится не менее трех измерений на каждом листе",
          "По четырем взаимно перпендикулярным диаметральным направлениям; проводится не менее двух измерений на каждом листе",
          "По шести взаимно перпендикулярным диаметральным направлениям; проводится не менее четырех измерений на каждом листе",
          "По двум взаимно перпендикулярным диаметральным направлениям; проводится не менее трех измерений на каждом листе",
        ],
        correctAnswers: [
          "По двум взаимно перпендикулярным диаметральным направлениям; проводится не менее трех измерений на каждом листе",
        ],
      },
      {
        code: "20207093",
        text: "Отверстие какого размера вырезают в кровле сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов, где имеется значительный коррозионный износ?",
        answers: ["400х400 мм", "500х500 мм", "700х700 мм", "600х600 мм"],
        correctAnswers: ["500х500 мм"],
      },
      {
        code: "20207094",
        text: "Какое из перечисленных требований при выявлении действительной геометрической формы сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов указано верно? Укажите все правильные ответы.",
        answers: [
          "Измерения рекомендуется проводить дважды: на заполненном и пустом резервуаре",
          "Неравномерность осадки основания определяется путем нивелирования наружного контура днища в точках, отстоящих друг от друга не более чем на 3 м",
          "Рекомендуется нумеровать стыки листов нижнего пояса против часовой стрелки, начиная от приемо-раздаточных патрубков",
          "Измерения отклонений от вертикали образующих стенки рекомендуется проводить либо с помощью отвеса путем прямых измерений, либо с помощью теодолита",
          "Измерения рекомендуется проводить один раз на заполненном резервуаре",
        ],
        correctAnswers: [
          "Измерения рекомендуется проводить дважды: на заполненном и пустом резервуаре",
          "Измерения отклонений от вертикали образующих стенки рекомендуется проводить либо с помощью отвеса путем прямых измерений, либо с помощью теодолита",
        ],
      },
      {
        code: "20207095",
        text: "Какие измерения проводятся для контроля геометрических размеров и формы понтона (плавающей крыши) сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов?",
        answers: [
          "Измерения отклонений от вертикали направляющих",
          "Измерения зазоров между наружной поверхностью бортового листа и стенкой резервуара",
          "Измерения отклонения бортового листа короба от вертикали",
          "Все перечисленные измерения",
        ],
        correctAnswers: ["Все перечисленные измерения"],
      },
      {
        code: "20207096",
        text: "Что из перечисленного не допускается при исследовании микроструктуры основного металла и сварных соединений вертикальных цилиндрических резервуаров для нефти и нефтепродуктов?",
        answers: [
          "Применение оптических приборов с увеличением ×100 и ×500",
          "Исследование микроструктуры на сколах и репликах",
          "Выполнение исследования на специально вырезанных и подготовленных образцах",
          "Сохранение зоны термического влияния при применении огневой резки для приготовления шлифа",
          "Вырезка образцов механическим способом",
        ],
        correctAnswers: [
          "Сохранение зоны термического влияния при применении огневой резки для приготовления шлифа",
        ],
      },
      {
        code: "20207097",
        text: "В каком случае допускается продление срока службы сварного вертикального цилиндрического резервуара для нефти и нефтепродуктов, когда при статических нагружениях отдельные элементы конструкции резервуара вследствие коррозионного износа, механических повреждений, снижения механических свойств металла не соответствуют расчетным эксплуатационным параметрам? Укажите все правильные ответы.",
        answers: [
          "Продление срока службы не допускается ни в каком случае",
          "При установлении пониженных эксплуатационных параметров",
          "После проведения комплексных мероприятий по ремонту и усилению металлических конструкций резервуара",
          "При согласовании с проектной организацией",
          "При установлении постоянного визуального контроля за сварным соединением",
        ],
        correctAnswers: [
          "При установлении пониженных эксплуатационных параметров",
          "После проведения комплексных мероприятий по ремонту и усилению металлических конструкций резервуара",
        ],
      },
      {
        code: "20207098",
        text: "Износ каких из перечисленных элементов сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов может превышать 30%?",
        answers: [
          "Прогонов",
          "Коробов понтона (плавающей крыши)",
          "Окрайков днищ",
          "Балок",
          "Листов кровли",
        ],
        correctAnswers: ["Листов кровли"],
      },
      {
        code: "20207099",
        text: "Во сколько раз могут быть увеличены предельные отклонения от вертикали образующих стенок сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов, находящихся в эксплуатации более 5 лет?",
        answers: ["В 1,5 раза", "В 1,8 раза", "В 1,3 раза", "В 1,1 раза"],
        correctAnswers: ["В 1,3 раза"],
      },
      {
        code: "20207100",
        text: "Во сколько раз могут быть увеличены предельные отклонения от вертикали образующих стенок сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов, находящихся в эксплуатации более 20 лет?",
        answers: ["В 3 раза", "В 2 раза", "В 2,5 раза", "В 1,3 раза"],
        correctAnswers: ["В 2 раза"],
      },
      {
        code: "20207101",
        text: "На сколько могут быть увеличены предельные отклонения от горизонтали наружного контура днища эксплуатируемых сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов, находящихся в эксплуатации более 20 лет?",
        answers: ["В 3,5 раза", "В 2,5 раза", "В 2 раза", "В 2,4 раза"],
        correctAnswers: ["В 2 раза"],
      },
      {
        code: "20207102",
        text: "Кем утверждается оформленное заключение по результатам технического диагностирования сварных вертикальных цилиндрических резервуаров для нефти и нефтепродуктов?",
        answers: [
          "Исполнителями организации, проводившей диагностирование",
          "Инспектором Ростехнадзора",
          "Руководителем организации, проводившей диагностирование",
          "Руководителем проектной организации",
        ],
        correctAnswers: [
          "Руководителем организации, проводившей диагностирование",
        ],
      },
      {
        code: "20207103",
        text: 'Какой из перечисленных терминов соответствует определению "проверка соответствия значений параметров объекта требованиям технической документации и определение на этой основе одного из заданных видов технического состояния в данный момент"?',
        answers: [
          "Техническое регулирование",
          "Диагностическое состояние",
          "Система технологического диагностирования",
          "Контроль технического состояния",
        ],
        correctAnswers: ["Контроль технического состояния"],
      },
      {
        code: "20207104",
        text: "Каким образом необходимо контролировать содержание горючих паров и газов в воздухе рабочей зоны или помещения в месте проведения ремонтных, газоопасных работ на ОПО МТ?",
        answers: [
          "Использованием системы блокировок и противоаварийной защиты",
          "Использованием передвижных мобильных систем",
          "Содержание горючих паров и газов в воздухе рабочей зоны не контролируется",
          "Приборами контроля с автоматической сигнализацией на месте отбора проб",
          "Использованием стационарных газоанализаторных установок",
        ],
        correctAnswers: [
          "Приборами контроля с автоматической сигнализацией на месте отбора проб",
        ],
      },
      {
        code: "20207105",
        text: "В каких случаях проводят контроль содержания горючих паров и газов в воздухе рабочей зоны или помещения при проведении ремонтных, газоопасных работ на опасных производственных объектах магистральных трубопроводов?",
        answers: [
          "Перед началом проведения работ",
          "Перед началом проведения работ и после каждого перерыва длительностью не менее одного часа",
          "Перед началом проведения работ и после каждого перерыва длительностью не менее двух часов",
          "Перед началом проведения работ и после каждого перерыва длительностью не менее трех часов",
        ],
        correctAnswers: [
          "Перед началом проведения работ и после каждого перерыва длительностью не менее одного часа",
        ],
      },
      {
        code: "20207106",
        text: "В каком случае допускаются ремонтные работы при превышении в воздухе рабочей зоны установленных значений предельно допустимых концентраций для транспортируемого продукта?",
        answers: [
          "При использовании средств индивидуальной защиты органов дыхания",
          "Ни в каком случае",
          "При выполнении работ специализированными организациями",
          "При согласовании с Ростехнадзором",
        ],
        correctAnswers: [
          "При использовании средств индивидуальной защиты органов дыхания",
        ],
      },
      {
        code: "20207107",
        text: "Какой должна быть концентрация горючих паров и газов в месте проведения сварочных и других огневых работ на опасных производственных объектах магистральных трубопроводов?",
        answers: [
          "Не превышающей 20% величины нижнего концентрационного предела распространения пламени",
          "Не превышающей 25% величины нижнего концентрационного предела распространения пламени",
          "Не превышающей 30% величины нижнего концентрационного предела распространения пламени",
          "Не превышающей 40% величины нижнего концентрационного предела распространения пламени",
        ],
        correctAnswers: [
          "Не превышающей 20% величины нижнего концентрационного предела распространения пламени",
        ],
      },
      {
        code: "20207108",
        text: "Каким испытаниям должны быть подвергнуты трубопроводы линейной части ОПО МТ по завершении строительства, реконструкции, технического перевооружения и капитального ремонта?",
        answers: [
          "На прочность и герметичность",
          "Только на прочность",
          "Только на герметичность",
          "Не регламентируется",
        ],
        correctAnswers: ["На прочность и герметичность"],
      },
      {
        code: "20207109",
        text: "Что может применяться в качестве жидкой рабочей среды при гидравлических испытаниях на прочность и проверке на герметичность магистральных трубопроводов, транспортирующих углеводороды?",
        answers: [
          "Вода",
          "Вода и другие негорючие жидкости",
          "Вода и другие негорючие жидкости, а также горючие жидкости при обосновании их применения в документации на проведение испытаний и уведомлении федерального органа исполнительной власти в области промышленной безопасности",
          'Вид жидкости в ФНП "Правила безопасности для опасных производственных объектов магистральных трубопроводов" не нормируется',
        ],
        correctAnswers: ["Вода и другие негорючие жидкости"],
      },
      {
        code: "20207110",
        text: "Какой вид газообразной рабочей среды может применяться при пневматических испытаниях на прочность и проверке на герметичность магистральных трубопроводов, транспортирующих углеводороды?",
        answers: [
          "Только воздух",
          "Воздух, инертные газы, природный газ",
          "Только воздух и инертные газы",
          "Вид газообразной рабочей среды в Правилах безопасности для опасных производственных объектов магистральных трубопроводов не нормируется",
          "Водяной пар",
        ],
        correctAnswers: ["Воздух, инертные газы, природный газ"],
      },
      {
        code: "20207111",
        text: "В каких случаях вместо гидравлических испытаний допускается проведение испытаний линейной части опасных производственных объектов магистральных трубопроводов на прочность и герметичность газообразными рабочими средами?",
        answers: [
          "Только при отрицательных температурах окружающей среды",
          "При невозможности обеспечения необходимого количества жидкой рабочей среды",
          "При отрицательных температурах окружающей среды или невозможности обеспечить необходимое количество жидкой рабочей среды",
          "Проведение пневматических испытаний на прочность и герметичность линейной части опасных производственных объектов магистральных трубопроводов запрещено",
          "Только при осадках в виде дождя и снега",
        ],
        correctAnswers: [
          "При отрицательных температурах окружающей среды или невозможности обеспечить необходимое количество жидкой рабочей среды",
        ],
      },
      {
        code: "20207112",
        text: "Какие действия следуют по завершении строительства, реконструкции, технического перевооружения и после испытания на прочность и проверки на герметичность опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Приступают к эксплуатации опасных производственных объектов магистральных трубопроводов в режиме опытного использования",
          "Осуществляют комплексное опробование опасных производственных объектов магистральных трубопроводов",
          "Приступают к эксплуатации опасных производственных объектов магистральных трубопроводов в штатном режиме",
          "Осуществляют пусконаладочные мероприятия",
        ],
        correctAnswers: [
          "Осуществляют комплексное опробование опасных производственных объектов магистральных трубопроводов",
        ],
      },
      {
        code: "20207113",
        text: "На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?",
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          "На ведение газоопасных, огневых и ремонтных работ на объектах стационарных автомобильных газозаправочных станций, автомобильных газонаполнительных компрессорных станциях и криогенных автозаправочных станциях газомоторного топлива",
          "На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности",
          "На ведение газоопасных, огневых и ремонтных работ на объектах тепло- и электроэнергетики",
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "20207114",
        text: "Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?",
        answers: [
          "Все перечисленные требования",
          "Прошедшие обучение приемам и методам проведения работ",
          "Прошедшие медицинский осмотр в соответствии с требованиями законодательства Российской Федерации",
          "Не моложе 18 лет",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20207115",
        text: "В каких из перечисленных случаев должны проводиться газоопасные работы? Выберите два правильных варианта ответа.",
        answers: [
          "Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть механизированы",
          "Когда газоопасные работы могут быть проведены без непосредственного участия людей",
          "Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть автоматизированы",
        ],
        correctAnswers: [
          "Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть механизированы",
          "Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть автоматизированы",
        ],
      },
      {
        code: "20207116",
        text: "К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?",
        answers: [
          "К II группе",
          "К I группе",
          "К группе неопасных работ",
          "К группе работ повышенной опасности",
        ],
        correctAnswers: ["К II группе"],
      },
      {
        code: "20207117",
        text: "Какая информация должна быть указана в перечне газоопасных работ? Выберите два правильных варианта ответа.",
        answers: [
          "Список лиц, ответственных за безопасную подготовку и проведение газоопасных работ подрядной организации",
          "Структурное подразделение организации (производство, цех, отделение, установка, участок)",
          "Категория исполнителей (персонал эксплуатирующей организации, газоспасательной службы), выполняющих указанные работы",
          "График проверки средств индивидуальной защиты",
        ],
        correctAnswers: [
          "Структурное подразделение организации (производство, цех, отделение, установка, участок)",
          "Категория исполнителей (персонал эксплуатирующей организации, газоспасательной службы), выполняющих указанные работы",
        ],
      },
      {
        code: "20207118",
        text: "Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года",
          "Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ",
          "Без наряда-допуска, по устному распоряжению",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
      {
        code: "20207119",
        text: "Когда могут проводиться газоопасные работы, выполняемые по наряду-допуску?",
        answers: [
          "В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск",
          "Во время грозы",
          "Только в темное время суток с соблюдением мероприятий по обеспечению безопасного проведения работ, учитывающих условия их выполнения в темное время суток",
          "В дневное или в темное время суток с участием или в присутствии лица, ответственного за выполнение газоопасных работ",
          "Не регламентируется",
        ],
        correctAnswers: [
          "В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск",
        ],
      },
      {
        code: "20207120",
        text: "Где регистрируются газоопасные работы II группы?",
        answers: [
          "В журнале регистрации нарядов-допусков на проведение газоопасных работ с присвоением очередного номера",
          "В журнале учета выдачи нарядов-допусков на выполнение работ повышенной опасности",
          "В журнале учета газоопасных работ, проводимых без наряда-допуска на проведение газоопасных работ",
          "В журнале регистрации целевого инструктажа",
          "В вахтовом журнале",
        ],
        correctAnswers: [
          "В журнале учета газоопасных работ, проводимых без наряда-допуска на проведение газоопасных работ",
        ],
      },
      {
        code: "20207121",
        text: "Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?",
        answers: [
          "Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск на требуемый для окончания работ срок",
          "Руководитель структурного подразделения не более чем на 3 рабочие смены",
          "Лицо, зарегистрировавшее наряд-допуск не более чем на 2 рабочие смены",
        ],
        correctAnswers: [
          "Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
        ],
      },
      {
        code: "20207122",
        text: "Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?",
        answers: [
          "Любой из специалистов организации-заказчика (эксплуатирующей организации)",
          "Только руководитель структурного подразделения, где будет проводиться газоопасная работа, или его уполномоченный заместитель",
          "Только специалист, обученный пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность",
          "Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ",
          "Все перечисленные лица",
        ],
        correctAnswers: [
          "Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ",
        ],
      },
      {
        code: "20207123",
        text: "В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?",
        answers: [
          "Не менее 3 месяцев со дня закрытия наряда допуска",
          "Не менее 1 года со дня закрытия наряда допуска",
          "Не менее 6 месяцев со дня закрытия наряда допуска",
        ],
        correctAnswers: ["Не менее 6 месяцев со дня закрытия наряда допуска"],
      },
      {
        code: "20207124",
        text: "Каким документом определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?",
        answers: [
          "Организационно-распорядительными документами руководителя",
          "Технологическим регламентом",
          "Федеральными нормами и правилами в области промышленной безопасности",
          "Правилами пожарной безопасности",
          "Всеми перечисленными документами",
        ],
        correctAnswers: [
          "Организационно-распорядительными документами руководителя",
        ],
      },
      {
        code: "20207125",
        text: "Что из перечисленного следует выполнить для проведения огневых работ внутри емкости (аппарата) при проведении газоопасных работ?",
        answers: [
          "Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)",
          "Следует оформить наряд-допуск на выполнение огневых работ, закрыв при этом наряд-допуск на проведение газоопасных работ",
          "Следует получить письменное разрешение лица, утвердившего наряд-допуск на проведение газоопасных работ, приложив его к наряду-допуску на проведение газоопасных работ",
          "Следует к наряду-допуску на проведение газоопасных работ приложить перечень мест выполнения огневых работ",
          "Следует к наряду-допуску на проведение огневых работ приложить схему места проведения огневых работ",
        ],
        correctAnswers: [
          "Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)",
        ],
      },
    ],
    20208: [
      {
        code: "20208000",
        text: "На какие магистральные трубопроводы не распространяются требования Правил безопасности для опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "На конденсатопроводы",
          "На трубопроводы широкой фракции легких углеводородов",
          "На внутризаводские трубопроводы организаций, производящих и потребляющих жидкий аммиак",
          "На магистральные трубопроводы и аммиакопроводы",
        ],
        correctAnswers: [
          "На внутризаводские трубопроводы организаций, производящих и потребляющих жидкий аммиак",
        ],
      },
      {
        code: "20208001",
        text: "В каком случае не применяются Правила безопасности для опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "В случае разработки технологических процессов, проектирования, строительства, эксплуатации, реконструкции, технического перевооружения, капитального ремонта, консервации и ликвидации ОПО МТ",
          "В случае изготовления, монтажа, наладки, обслуживания, диагностирования и ремонта технических устройств, применяемых на ОПО МТ",
          "В случае проведения экспертизы промышленной безопасности: документации на консервацию, ликвидацию, техническое перевооружение ОПО; технических устройств; зданий и сооружений; деклараций промышленной безопасности ОПО МТ; обоснований безопасности ОПО",
          "В случае проведения государственной экспертизы проектной документации в соответствии с законодательством о градостроительной деятельности",
        ],
        correctAnswers: [
          "В случае проведения государственной экспертизы проектной документации в соответствии с законодательством о градостроительной деятельности",
        ],
      },
      {
        code: "20208002",
        text: "В соответствии с требованиями какого нормативно-правового документа обеспечивается пожарная безопасность ОПО МТ?",
        answers: [
          'Федеральных норм и правил в области промышленной безопасности "Правила безопасности для опасных производственных объектов магистральных трубопроводов"',
          'Федерального закона от 22 июля 2008 г. N 123-ФЗ "Технический регламент о требованиях пожарной безопасности"',
          'Федерального закона от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
          'СП 36.13330.2012 "Свод правил. Магистральные трубопроводы"',
        ],
        correctAnswers: [
          'Федерального закона от 22 июля 2008 г. N 123-ФЗ "Технический регламент о требованиях пожарной безопасности"',
        ],
      },
      {
        code: "20208003",
        text: "Что относится к опасным производственным объектам магистральных трубопроводов?",
        answers: [
          "ОПО линейной части МТ",
          "Объекты линейной части и площадочные сооружения",
          "Объекты линейной части, площадочные сооружения и объекты добычи",
          "Объекты линейной части и объекты добычи",
        ],
        correctAnswers: ["Объекты линейной части и площадочные сооружения"],
      },
      {
        code: "20208004",
        text: "Чем подтверждается соответствие комплектного оборудования и технических устройств на ОПО МТ при наличии технической документации изготовителя, а также при их соответствии требованиям технических регламентов и Правил безопасности для опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Разрешением Ростехнадзора на применение технических устройств на ОПО",
          "Документом соответствия требованиям технических регламентов или заключением экспертизы промышленной безопасности",
          "Сертификатом соответствия техническим регламентам ЕАЭС",
          "Сертификатом соответствия системы ГОСТ Р",
        ],
        correctAnswers: [
          "Документом соответствия требованиям технических регламентов или заключением экспертизы промышленной безопасности",
        ],
      },
      {
        code: "20208005",
        text: "Что включает в себя планирование и осуществление мероприятий по предупреждению возможных аварий и обеспечению постоянной готовности к локализации и ликвидации последствий аварии на ОПО МТ?",
        answers: [
          "Формирование необходимых финансовых средств и материальных ресурсов для локализации и ликвидации последствий аварий",
          "Подготовку и аттестацию руководителей и специалистов в области промышленной безопасности",
          "Страхование ответственности за причинение вреда в случае возникновения аварии или инцидента на ОПО",
          "Разработку мер по снижению риска аварий на ОПО",
        ],
        correctAnswers: [
          "Формирование необходимых финансовых средств и материальных ресурсов для локализации и ликвидации последствий аварий",
        ],
      },
      {
        code: "20208006",
        text: "Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?",
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          "Для всех ОПО без исключения",
          "Только для ОПО I и II классов опасности",
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "20208007",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий на ОПО установлены для ОПО МТ III класса опасности?",
        answers: ["1 год", "2 года", "3 года", "5 лет"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20208008",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий на ОПО МТ II класса опасности?",
        answers: ["1 год", "2 года", "3 года", "5 лет"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20208009",
        text: "В какие сроки должен пересматриваться план мероприятий по локализации и ликвидации последствий аварий на ОПО при истечении срока действия предыдущего плана мероприятий?",
        answers: [
          "Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий",
          "Не менее чем за 7 календарных дней до истечения срока действия предыдущего плана мероприятий",
          "В течение 15 календарных дней после истечения срока действия предыдущего плана мероприятий",
          "В течение 7 календарных дней после истечения срока действия предыдущего плана мероприятий",
        ],
        correctAnswers: [
          "Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий",
        ],
      },
      {
        code: "20208010",
        text: "В течение какого срока после реконструкции или технического перевооружения объекта должен быть пересмотрен план мероприятий по локализации и ликвидации последствий аварий на ОПО?",
        answers: [
          "Не позднее 30 календарных дней",
          "Не позднее 45 календарных дней",
          "Не позднее 3 месяцев",
          "Не позднее 6 месяцев",
        ],
        correctAnswers: ["Не позднее 30 календарных дней"],
      },
      {
        code: "20208011",
        text: "К чему из перечисленного не относится анализ опасностей технологических процессов, количественный анализ риска и иные методы анализа риска аварий, связанных с выбросом транспортируемых углеводородов?",
        answers: [
          "К декларированию промышленной безопасности",
          "К декларированию пожарной безопасности",
          "К обоснованию безопасности ОПО МТ",
          "К системе управления промышленной безопасностью ОПО МТ",
        ],
        correctAnswers: ["К декларированию пожарной безопасности"],
      },
      {
        code: "20208012",
        text: "В чем заключается основная задача анализа риска?",
        answers: [
          "В предоставлении должностным лицам, принимающим решения по обеспечению безопасности, сведений о наиболее опасных процессах, участках ОПО МТ",
          "В информировании Ростехнадзора о существующих рисках на ОПО",
          "В определении сумм потенциального ущерба в случае возникновения аварии на ОПО",
          "В информировании населения о существующих рисках возникновения аварии на ОПО",
        ],
        correctAnswers: [
          "В предоставлении должностным лицам, принимающим решения по обеспечению безопасности, сведений о наиболее опасных процессах, участках ОПО МТ",
        ],
      },
      {
        code: "20208013",
        text: "Что не относится к основным этапам процесса проведения количественного анализа риска аварии на опасных производственных объектах магистральных трубопроводов?",
        answers: [
          "Оценка риска методом НАZOP",
          "Идентификация опасностей аварий",
          "Количественная оценка риска аварий на ОПО МТ",
          "Разработка рекомендаций по снижению риска аварий",
        ],
        correctAnswers: ["Оценка риска методом НАZOP"],
      },
      {
        code: "20208014",
        text: "Кто устанавливает продолжительность периода, на который ОПО МТ выводят из эксплуатации, условия нахождения в резерве (консервация или периодическое включение в работу в целях поддержания работоспособного состояния ОПО МТ)?",
        answers: [
          "Проектная организация",
          "Ростехнадзор",
          "Экспертная организация",
          "Эксплуатирующая организация",
        ],
        correctAnswers: ["Эксплуатирующая организация"],
      },
      {
        code: "20208015",
        text: "Что, согласно требованиям нормативных правовых актов и нормативных технических документов, не проводится при выводе из консервации опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Ревизия трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов",
          "Опробование трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов",
          "Техническое диагностирование трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов",
          "Испытания трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов",
        ],
        correctAnswers: [
          "Техническое диагностирование трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов",
        ],
      },
      {
        code: "20208016",
        text: "В течение какого времени после пробной эксплуатации опасных производственных объектов магистральных трубопроводов эксплуатирующая организация составляет акт о вводе объекта в действие с приложением перечня выполненных работ при выводе опасных производственных объектов магистральных трубопроводов из консервации?",
        answers: [
          "В течение 12 часов",
          "В течение 24 часов",
          "В течение 48 часов",
          "В течение 72 часов",
        ],
        correctAnswers: ["В течение 72 часов"],
      },
      {
        code: "20208017",
        text: "Какие работы должны быть проведены перед началом осуществления работ по выводу из эксплуатации ОПО МТ и ОПО МАП, подлежащих ликвидации?",
        answers: [
          "Работы по освобождению трубопроводов и оборудования данных объектов от углеводородов или жидкого аммиака",
          "Работы по очистке трубопроводов ОПО МТ и ОПО МАП",
          "Ревизия и проверка трубопроводов и оборудования ОПО МТ и ОПО МАП",
          "Техническое диагностирование трубопроводов и оборудования ОПО МТ и ОПО МАП",
        ],
        correctAnswers: [
          "Работы по освобождению трубопроводов и оборудования данных объектов от углеводородов или жидкого аммиака",
        ],
      },
      {
        code: "20208018",
        text: "Что должно быть обеспечено на территории размещения линейных и площадочных сооружений опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Возможность размещения предприятий и складов взрывчатых веществ",
          "Возможность размещения объектов энергетического и водооборотного хозяйства",
          "Возможность проведения строительно-монтажных работ с использованием грузоподъемной и специальной техники, а также возможность размещения мест складирования оборудования и строительных материалов",
          "Возможность строительства производственных зданий и сооружений",
        ],
        correctAnswers: [
          "Возможность проведения строительно-монтажных работ с использованием грузоподъемной и специальной техники, а также возможность размещения мест складирования оборудования и строительных материалов",
        ],
      },
      {
        code: "20208019",
        text: "Что из перечисленного не входит в мероприятия по предупреждению возможных аварий и обеспечению постоянной готовности к локализации и ликвидации последствий аварий на опасных производственных объектах магистральных трубопроводов?",
        answers: [
          "Контроль состояния технических устройств",
          "Оснащение системами и средствами наблюдения, оповещения, связи",
          "Подготовка и аттестация руководителей и специалистов в области промышленной безопасности",
          "Формирование необходимых финансовых средств и материальных ресурсов для локализации и ликвидации последствий аварий",
        ],
        correctAnswers: [
          "Подготовка и аттестация руководителей и специалистов в области промышленной безопасности",
        ],
      },
      {
        code: "20208020",
        text: "Что обязана предпринять эксплуатирующая организация ОПО МТ в случае угрозы аварии и возможного возникновения риска поражения для населения?",
        answers: [
          "Ограничить доступ работников на территорию места аварии или инцидента",
          "Продолжить эксплуатацию ОПО МТ в штатном режиме",
          "Все необходимые меры по обследованию ОПО МТ, при необходимости ограничивать режим работы или приостанавливать эксплуатацию ОПО МТ",
        ],
        correctAnswers: [
          "Все необходимые меры по обследованию ОПО МТ, при необходимости ограничивать режим работы или приостанавливать эксплуатацию ОПО МТ",
        ],
      },
      {
        code: "20208021",
        text: "В соответствии с требованиями какого документа разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на ОПО МТ?",
        answers: [
          'Постановления Правительства Российской Федерации от 15 сентября 2020 г. N 1437 "Об утверждении Положения о разработке планов мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах"',
          'Постановления Правительства РФ от 31.12.2020 N 2451 "Об утверждении Правил организации мероприятий по предупреждению и ликвидации разливов нефти и нефтепродуктов на территории Российской Федерации, за исключением внутренних морских вод Российской Федерации и территориального моря Российской Федерации"',
          'Федерального закона от 21.07.1997 N 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
        ],
        correctAnswers: [
          'Постановления Правительства Российской Федерации от 15 сентября 2020 г. N 1437 "Об утверждении Положения о разработке планов мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах"',
        ],
      },
      {
        code: "20208022",
        text: "Что из перечисленного должен предусматривать план мероприятий по локализации и ликвидации последствий аварий на ОПО?",
        answers: [
          "Только количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте, и их соответствие задачам по локализации и ликвидации последствий аварий",
          "Только первоочередные действия при получении сигнала об авариях на объекте",
          "Только сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий, а также источники их возникновения",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20208023",
        text: "В каком случае осуществляются технические мероприятия по консервации и ликвидации опасных производственных объектов магистральных трубопроводов в соответствии с документацией на консервацию и ликвидацию опасных производственных объектов?",
        answers: [
          "После оформления акта на консервацию и ликвидацию ОПО",
          "После получения разрешения на консервацию и ликвидацию ОПО",
          "После проведения проверки ОПО Ростехнадзором",
          "После получения положительного заключения экспертизы промышленной безопасности на документацию по консервации и ликвидации ОПО",
        ],
        correctAnswers: [
          "После получения положительного заключения экспертизы промышленной безопасности на документацию по консервации и ликвидации ОПО",
        ],
      },
      {
        code: "20208024",
        text: "Из каких разделов состоит план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Только из общих разделов",
          "Только из специальных разделов",
          "Из основного и общих разделов",
          "Из общих и специальных разделов",
        ],
        correctAnswers: ["Из общих и специальных разделов"],
      },
      {
        code: "20208025",
        text: "В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "В целях координации действий персонала при возникновении аварии на опасных производственных объектах",
          "В целях обучения персонала действиям при возникновении аварии на опасных производственных объектах",
          "В целях обеспечения соответствия объекта требованиям промышленной безопасности",
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
        ],
        correctAnswers: [
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
        ],
      },
      {
        code: "20208026",
        text: "В каком случае организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий для нескольких опасных производственных объектов?",
        answers: [
          "В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
          "В случае если 4 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке",
          "В случае если 3 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
          "В любом случае на каждый объект должен быть разработан отдельный план",
        ],
        correctAnswers: [
          "В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
        ],
      },
      {
        code: "20208027",
        text: "Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?",
        answers: [
          "1 год",
          "5 лет",
          "Наибольший срок, предусмотренный для этих объектов",
          "Наименьший срок, предусмотренный для этих объектов",
        ],
        correctAnswers: ["Наименьший срок, предусмотренный для этих объектов"],
      },
      {
        code: "20208028",
        text: "Кем утверждаются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Только руководителями (заместителями руководителей) организаций, эксплуатирующих объекты",
          "Только руководителями структурных подразделений организаций, эксплуатирующих объекты",
          "Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
          "Специалистами поднадзорных организаций совместно со специалистами надзорных органов",
        ],
        correctAnswers: [
          "Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
        ],
      },
      {
        code: "20208029",
        text: "Кем согласовываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Руководителями муниципальных образований, на территории которых расположен объект",
          "Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
          "Руководителями территориальных органов МЧС России",
          "Руководителями территориальных органов Ростехнадзора",
        ],
        correctAnswers: [
          "Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
        ],
      },
      {
        code: "20208030",
        text: "Какой документ разрабатывают для вывода опасных производственных объектов магистральных трубопроводов из консервации и ввода их в эксплуатацию?",
        answers: [
          "Рабочую программу с указанием перечня работ, порядка и сроков их выполнения",
          "Технический регламент с указанием перечня работ",
          "План мероприятий по выводу опасных производственных объектов магистральных трубопроводов из консервации и ввода их в эксплуатацию",
          "График выполнения работ по вводу опасных производственных объектов магистральных трубопроводов в эксплуатацию",
        ],
        correctAnswers: [
          "Рабочую программу с указанием перечня работ, порядка и сроков их выполнения",
        ],
      },
      {
        code: "20208031",
        text: "Какие из перечисленных степеней уровня риска аварии на ОПО МТ указаны верно?",
        answers: [
          "Предельная",
          "Чрезвычайно высокая",
          "Критическая",
          "Чрезвычайно низкая",
        ],
        correctAnswers: ["Чрезвычайно высокая"],
      },
      {
        code: "20208032",
        text: "Какая из перечисленных специальных мер безопасности, снижающая риск аварии, не предусматривается проектной документацией (документацией) для наиболее опасных участков объектов линейной части ОПО МТ?",
        answers: [
          "Увеличение глубины залегания трубопровода",
          "Увеличение толщины стенки трубопровода",
          "Устройство отводящих систем (каналов, канав)",
          "Увеличение диаметра трубопровода",
        ],
        correctAnswers: ["Увеличение диаметра трубопровода"],
      },
      {
        code: "20208033",
        text: "В отношении каких из перечисленных участков ОПО МТ проектной документацией (документацией) предусматриваются дополнительные меры, направленные на снижение риска аварий?",
        answers: [
          "Только участков, эксплуатируемых в природных условиях с повышенной опасностью экстремальных природных воздействий",
          "Только участков, проходящих по землям особо охраняемых природных территорий",
          "Только переходов через автомобильные и железные дороги",
          "Всех перечисленных участков",
        ],
        correctAnswers: ["Всех перечисленных участков"],
      },
      {
        code: "20208034",
        text: "При каком расположении населенных пунктов и промышленных предприятий при прокладке нефтепроводов и нефтепродуктопроводов проектом должны быть предусмотрены технические решения, исключающие поступление транспортируемой по трубопроводу среды в зону застройки?",
        answers: [
          "Расположенных на отметках ниже этих трубопроводов на расстоянии от них менее 600 м при диаметре труб 700 мм и менее 1100 м при диаметре труб свыше 700 мм",
          "Расположенных на отметках ниже этих трубопроводов на расстоянии от них менее 500 м при диаметре труб 700 мм и менее 1000 м при диаметре труб свыше 700 мм",
          "Расположенных на отметках ниже этих трубопроводов на расстоянии от них менее 800 м при диаметре труб 700 мм и менее 1300 м при диаметре труб свыше 700 мм",
          "Расположенных на отметках ниже этих трубопроводов на расстоянии от них менее 700 м при диаметре труб 700 мм и менее 1200 м при диаметре труб свыше 700 мм",
        ],
        correctAnswers: [
          "Расположенных на отметках ниже этих трубопроводов на расстоянии от них менее 500 м при диаметре труб 700 мм и менее 1000 м при диаметре труб свыше 700 мм",
        ],
      },
      {
        code: "20208035",
        text: "Каким требованиям должен соответствовать технологический регламент на эксплуатацию опасных производственных объектов магистральных трубопроводов, определяющий порядок организации надежного и безопасного ведения технологического процесса?",
        answers: [
          "Проектным решениям",
          "Условиям работы опасных производственных объектов магистральных трубопроводов",
          "Требованиям законодательства Российской Федерации в области промышленной безопасности и нормативных технических документов",
          "Проектным решениям, условиям работы ОПО МТ, требованиям законодательства Российской Федерации в области промышленной безопасности и законодательства Российской Федерации о техническом регулировании",
        ],
        correctAnswers: [
          "Проектным решениям, условиям работы ОПО МТ, требованиям законодательства Российской Федерации в области промышленной безопасности и законодательства Российской Федерации о техническом регулировании",
        ],
      },
      {
        code: "20208036",
        text: "Что из перечисленного допускается не включать в технологический регламент на эксплуатацию опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Технологические режимы процесса транспортирования продукта и схемы",
          "Штатное расписание",
          "Порядок приема, сдачи и учета перекачиваемых продуктов",
          "Нормы расхода основных видов сырья, материалов и энергоресурсов",
        ],
        correctAnswers: ["Штатное расписание"],
      },
      {
        code: "20208037",
        text: "Когда должен быть разработан технологический регламент на эксплуатацию опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "До ввода опасных производственных объектов магистральных трубопроводов в эксплуатацию",
          "До начала строительства опасных производственных объектов магистральных трубопроводов",
          "В течение месяца после завершения строительства опасных производственных объектов магистральных трубопроводов",
          "После ввода опасных производственных объектов магистральных трубопроводов в эксплуатацию",
        ],
        correctAnswers: [
          "После ввода опасных производственных объектов магистральных трубопроводов в эксплуатацию",
        ],
      },
      {
        code: "20208038",
        text: "Какой документ не содержит требований к объему и периодичности работ, выполняемых при техническом обслуживании ОПО МТ?",
        answers: [
          "Проектная документация",
          "Технический регламент на эксплуатацию ОПО МТ",
          "Нормативно-технические документы заводов-изготовителей к трубам, материалам и оборудованию",
          "Техническое задание",
        ],
        correctAnswers: ["Техническое задание"],
      },
      {
        code: "20208039",
        text: "Чем должны быть определены объем и периодичность выполняемых работ при техническом обслуживании ОПО МТ?",
        answers: [
          "Только техническим регламентом на эксплуатацию ОПО МТ",
          "Только нормативно-техническими документами заводов-изготовителей к трубам, материалам и оборудованию",
          "Только проектной документацией и результатами контроля технического состояния",
          "Проектной документацией/документацией, технологическим регламентом на эксплуатацию ОПО МТ, нормативно-техническими документами заводов-изготовителей к трубам, материалам и оборудованию, а также результатами контроля технического состояния",
        ],
        correctAnswers: [
          "Проектной документацией/документацией, технологическим регламентом на эксплуатацию ОПО МТ, нормативно-техническими документами заводов-изготовителей к трубам, материалам и оборудованию, а также результатами контроля технического состояния",
        ],
      },
      {
        code: "20208040",
        text: "В какую документацию должны быть своевременно внесены все конструктивные изменения линейных сооружений ОПО МТ?",
        answers: [
          "Только в проектную документацию",
          "Только в эксплуатационную документацию",
          "Только в исполнительную документацию",
          "В эксплуатационную и исполнительную документацию",
        ],
        correctAnswers: ["В эксплуатационную и исполнительную документацию"],
      },
      {
        code: "20208041",
        text: "Что допускается не учитывать при определении периодичности и методов патрулирования трассы линейных сооружений опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Техническое состояние трубопроводов",
          "Особенности участка прокладки трубопровода",
          "Природные факторы, влияющие на безопасность эксплуатации трубопровода",
          "Транспортируемые на опасных производственных объектах магистральных трубопроводов продукты",
        ],
        correctAnswers: [
          "Транспортируемые на опасных производственных объектах магистральных трубопроводов продукты",
        ],
      },
      {
        code: "20208042",
        text: "В каких целях проводят периодическое техническое диагностирование опасных производственных объектов магистральных трубопроводов в процессе эксплуатации?",
        answers: [
          "Только в целях обеспечения безопасности",
          "Только в целях определения фактического технического состояния ОПО МТ",
          "Только в целях расчета допустимого давления",
          "Только в целях определения возможности дальнейшей безопасной эксплуатации на проектных технологических режимах",
          "Во всех перечисленных целях",
        ],
        correctAnswers: ["Во всех перечисленных целях"],
      },
      {
        code: "20208043",
        text: "Что определяют на основании результатов технического диагностирования ОПО МТ?",
        answers: [
          "Величину разрешенного (допустимого) рабочего давления в соответствии с нормативно-технической документацией по эксплуатации ОПО МТ",
          "Величину максимального рабочего давления в соответствии с нормативно-технической документацией по эксплуатации ОПО МТ",
          "Величину минимального рабочего давления в соответствии с нормативно-технической документацией по эксплуатации ОПО МТ",
        ],
        correctAnswers: [
          "Величину разрешенного (допустимого) рабочего давления в соответствии с нормативно-технической документацией по эксплуатации ОПО МТ",
        ],
      },
      {
        code: "20208044",
        text: "В течение какого периода эксплуатирующая организация обязана проводить периодические обследования трубопроводов и оборудования ОПО МТ?",
        answers: [
          "В течение 10 лет",
          "В течение 25 лет",
          "В течение всего жизненного цикла (до ликвидации ОПО МТ)",
          "В течение 15 лет",
        ],
        correctAnswers: [
          "В течение всего жизненного цикла (до ликвидации ОПО МТ)",
        ],
      },
      {
        code: "20208045",
        text: "Что должна включать оценка технического состояния оборудования площадочных сооружений опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Только наружное обследование в режиме эксплуатации",
          "Только полное техническое обследование в режиме выведения (временного или длительного) из эксплуатации",
          "Наружное обследование в режиме эксплуатации и полное техническое обследование в режиме выведения из эксплуатации (временного или длительного)",
        ],
        correctAnswers: [
          "Наружное обследование в режиме эксплуатации и полное техническое обследование в режиме выведения из эксплуатации (временного или длительного)",
        ],
      },
      {
        code: "20208046",
        text: "В каких случаях проводят контроль содержания горючих паров и газов в воздухе рабочей зоны или помещения при проведении ремонтных, газоопасных работ на опасных производственных объектах магистральных трубопроводов?",
        answers: [
          "Перед началом проведения работ и после каждого перерыва длительностью не менее одного часа",
          "Перед началом проведения работ и после каждого перерыва длительностью не менее двух часов",
          "После каждого перерыва длительностью не менее трех часов",
          "Перед началом проведения работ",
        ],
        correctAnswers: [
          "Перед началом проведения работ и после каждого перерыва длительностью не менее одного часа",
        ],
      },
      {
        code: "20208047",
        text: "В каком случае ремонтные работы на ОПО МТ проводят в средствах индивидуальной защиты органов дыхания?",
        answers: [
          "При срабатывании системы автоматической сигнализации",
          "При превышении в воздухе рабочей зоны установленных значений предельно допустимых концентраций для транспортируемого продукта",
          "При осуществлении ремонтных работ одним рабочим",
          "При скорости ветра более 12 м/с",
        ],
        correctAnswers: [
          "При превышении в воздухе рабочей зоны установленных значений предельно допустимых концентраций для транспортируемого продукта",
        ],
      },
      {
        code: "20208048",
        text: "Какие виды патрулирования используются для контроля состояния линейных участков, сооружений и объектов ОПО МТ?",
        answers: [
          "Только объезд автотранспортом",
          "Только авиапатрулирование",
          "Только пеший обход",
          "Все перечисленные виды патрулирования",
        ],
        correctAnswers: ["Все перечисленные виды патрулирования"],
      },
      {
        code: "20208049",
        text: "Проведение какого вида работ не предусматривается при техническом диагностировании линейной части опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Оценки состояния изоляционных покрытий",
          "Внутритрубной дефектоскопии путем пропуска внутритрубных инспекционных приборов",
          "Внешнего обследования оборудования и участков МТ, не подлежащих ВТД с применением методов неразрушающего контроля",
          "Гидравлических (пневматических) испытаний на прочность",
        ],
        correctAnswers: [
          "Гидравлических (пневматических) испытаний на прочность",
        ],
      },
      {
        code: "20208050",
        text: "Какой документ подтверждает величину разрешенного (допустимого) рабочего давления на основании результатов технического диагностирования ОПО МТ?",
        answers: [
          "Формуляр подтверждения величины разрешенного (допустимого) рабочего давления",
          "Акт технического диагностирования",
          "Паспорт технического устройства (отметка в паспорте)",
          "Заключение экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "Формуляр подтверждения величины разрешенного (допустимого) рабочего давления",
        ],
      },
      {
        code: "20208051",
        text: "В каком случае оформление формуляра (паспорта) подтверждения величины разрешенного (допустимого) рабочего давления не требуется?",
        answers: [
          "Для объектов, вводимых в эксплуатацию по завершении реконструкции",
          "Для действующих объектов, на которых проведено изменение величины разрешенного (допустимого) рабочего давления",
          "Для объектов, вводимых в эксплуатацию по завершении строительства",
          "Для всех эксплуатируемых ОПО МТ ежегодно",
        ],
        correctAnswers: ["Для всех эксплуатируемых ОПО МТ ежегодно"],
      },
      {
        code: "20208052",
        text: "Когда оформляют формуляр (паспорт) подтверждения величины разрешенного (допустимого) рабочего давления на ОПО МТ?",
        answers: [
          "До пуска ОПО МТ в эксплуатацию",
          "После пуска ОПО МТ в эксплуатацию",
          "В процессе эксплуатации ОПО МТ, но не позднее 10 дней с момента его пуска",
        ],
        correctAnswers: ["До пуска ОПО МТ в эксплуатацию"],
      },
      {
        code: "20208053",
        text: "Какие сведения может не содержать формуляр (паспорт) подтверждения величины разрешенного (допустимого) рабочего давления на ОПО МТ?",
        answers: [
          "О необходимости обеспечения ОПО МТ предохранительными устройствами для ограничения величины рабочего (допустимого) давления",
          "О величине разрешенного давления",
          "Об участке (номере участка) ОПО МТ",
          "О сроках проведения следующего технического диагностирования",
        ],
        correctAnswers: [
          "О сроках проведения следующего технического диагностирования",
        ],
      },
      {
        code: "20208054",
        text: "Где должен храниться формуляр (паспорт), на основании которого была установлена величина разрешенного (допустимого) рабочего давления?",
        answers: [
          "В архиве подрядной организации",
          "В архиве проектной организации",
          "В архиве эксплуатирующей организации",
        ],
        correctAnswers: ["В архиве эксплуатирующей организации"],
      },
      {
        code: "20208055",
        text: "Какие сведения допускается не учитывать при определении периодичности, полноты и порядка обследования, методов и средств контроля трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Информацию об условиях эксплуатации магистральных трубопроводов",
          "Информацию о техническом состоянии магистральных трубопроводов",
          "Сведения об устранении дефектов в результате планового капремонта",
          "Данные о строительстве магистральных трубопроводов",
        ],
        correctAnswers: [
          "Сведения об устранении дефектов в результате планового капремонта",
        ],
      },
      {
        code: "20208056",
        text: "Что из перечисленного не следует проводить перед обследованием оборудования площадочных сооружений ОПО МТ в связи с выводом его из эксплуатации?",
        answers: [
          "Очистку",
          "Дегазацию и установку заглушек",
          "Опорожнение",
          "Опрессовку",
        ],
        correctAnswers: ["Опрессовку"],
      },
      {
        code: "20208057",
        text: "Что может применяться в качестве жидкой рабочей среды при гидравлических испытаниях на прочность и проверке на герметичность магистральных трубопроводов, транспортирующих углеводороды?",
        answers: [
          "Только вода",
          "Горючие жидкости при обосновании их применения в документации на проведение испытаний и уведомлении Ростехнадзора",
          "Вода и другие негорючие жидкости",
          "Вид жидкости в Правилах безопасности для опасных производственных объектов магистральных трубопроводов не нормируется",
        ],
        correctAnswers: ["Вода и другие негорючие жидкости"],
      },
      {
        code: "20208058",
        text: "Какой вид газообразной рабочей среды может применяться при пневматических испытаниях на прочность и проверке на герметичность магистральных трубопроводов, транспортирующих углеводороды?",
        answers: [
          "Только воздух",
          "Воздух, инертные газы, природный газ",
          "Воздух и инертные газы",
          "Вид газообразной рабочей среды не нормируется",
        ],
        correctAnswers: ["Воздух, инертные газы, природный газ"],
      },
      {
        code: "20208059",
        text: "В какой документации определяются места установки и количество автоматических датчиков или пробоотборных устройств автоматических анализаторов на площадочных объектах ОПО МТ?",
        answers: [
          "В технологическом регламенте",
          "В проектной документации (документации)",
          "В производственных инструкциях",
          "В предписаниях Ростехнадзора",
        ],
        correctAnswers: ["В проектной документации (документации)"],
      },
      {
        code: "20208060",
        text: "Какой входной контроль должен быть организован на всех этапах выполнения работ по строительству, реконструкции, техническому перевооружению и капитальному ремонту ОПО МТ?",
        answers: [
          "Только конструкций, изделий, материалов, оборудования и технических устройств",
          "Только технологических операций",
          "Только качества выполнения работ",
          "Конструкций, изделий, материалов, оборудования и технических устройств, качества выполнения работ и всех технологических операций",
        ],
        correctAnswers: [
          "Конструкций, изделий, материалов, оборудования и технических устройств, качества выполнения работ и всех технологических операций",
        ],
      },
      {
        code: "20208061",
        text: "В какой документ заносятся результаты входного контроля конструкций, изделий, материалов, оборудования и технических устройств ОПО МТ?",
        answers: [
          "В паспорт оборудования",
          "В журнал входного контроля с оформлением акта проверки",
          "В протокол по итогам входного контроля",
          "Занесение результатов входного контроля в какой-либо документ не регламентируется",
        ],
        correctAnswers: [
          "В журнал входного контроля с оформлением акта проверки",
        ],
      },
      {
        code: "20208062",
        text: "Каким образом определяются объем и методы неразрушающего контроля сварных соединений?",
        answers: [
          "Проектной документацией (документацией)",
          "Технологической картой",
          "Технической документацией на оборудование",
          "Распоряжением эксплуатирующей организации",
        ],
        correctAnswers: ["Проектной документацией (документацией)"],
      },
      {
        code: "20208063",
        text: "На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?",
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          "На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на выделенной территории, находящихся в эксплуатации опасных производственных объектов, доступ на площадку к которым ограничен сплошными ограждающими конструкциями",
          "На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности",
          "На ведение газоопасных, огневых и ремонтных работ на объектах бурения и добычи нефти",
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "20208064",
        text: "Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение месяца",
          "Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
      {
        code: "20208065",
        text: "Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?",
        answers: [
          "Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск, на срок, требуемый для окончания работ",
          "Руководитель структурного подразделения на срок, требуемый для окончания работ",
          "Лицо, зарегистрировавшее наряд-допуск, не более чем на 1 рабочую смену",
        ],
        correctAnswers: [
          "Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
        ],
      },
      {
        code: "20208066",
        text: "В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?",
        answers: [
          "Не менее 3 месяцев со дня закрытия наряда-допуска",
          "Не менее 1 месяца со дня закрытия наряда-допуска",
          "Не менее 6 месяцев со дня закрытия наряда-допуска",
        ],
        correctAnswers: ["Не менее 6 месяцев со дня закрытия наряда-допуска"],
      },
      {
        code: "20208067",
        text: "Каким документом определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?",
        answers: [
          "Организационно-распорядительными документами руководителя",
          "Технологическим регламентом",
          "Инструкциями по охране труда и промышленной безопасности",
          "Правилами пожарной безопасности",
        ],
        correctAnswers: [
          "Организационно-распорядительными документами руководителя",
        ],
      },
      {
        code: "20208068",
        text: "После чего следует начинать производство работ по реконструкции, техническому перевооружению и капитальному ремонту ОПО МТ?",
        answers: [
          "Только после выполнения подготовительных мероприятий и письменного разрешения руководства эксплуатирующей организации на производство работ",
          "После выполнения подготовительных мероприятий, приемки объектов подрядчиком и письменного разрешения руководства эксплуатирующей организации на производство работ",
          "Только после приемки объектов подрядчиком",
          "Только после письменного разрешения руководства эксплуатирующей организации на производство работ",
        ],
        correctAnswers: [
          "После выполнения подготовительных мероприятий, приемки объектов подрядчиком и письменного разрешения руководства эксплуатирующей организации на производство работ",
        ],
      },
      {
        code: "20208069",
        text: "В каких случаях вместо гидравлических испытаний допускается проведение испытаний линейной части ОПО МТ на прочность и герметичность газообразными рабочими средами?",
        answers: [
          "Только при отрицательных температурах окружающей среды",
          "Только при невозможности обеспечения необходимого количества жидкой рабочей среды",
          "При отрицательных температурах окружающей среды или невозможности обеспечить необходимое количество жидкой рабочей среды",
          "В любых случаях проведение пневматических испытаний на прочность и герметичность линейной части ОПО МТ запрещается",
        ],
        correctAnswers: [
          "При отрицательных температурах окружающей среды или невозможности обеспечить необходимое количество жидкой рабочей среды",
        ],
      },
      {
        code: "20208070",
        text: "Какие действия следуют по завершении строительства, реконструкции, технического перевооружения и после испытания на прочность и проверки на герметичность ОПО МТ?",
        answers: [
          "Приступают к эксплуатации ОПО МТ в режиме опытного использования",
          "Осуществляют комплексное опробование ОПО МТ",
          "Приступают к эксплуатации ОПО МТ в штатном режиме",
        ],
        correctAnswers: ["Осуществляют комплексное опробование ОПО МТ"],
      },
      {
        code: "20208071",
        text: "В течение какого времени заполнение линейных сооружений ОПО МТ углеводородами и его работу после заполнения считают комплексным опробованием линейного сооружения ОПО МТ?",
        answers: [
          "В течение 24 часов",
          "В течение 48 часов",
          "В течение 72 часов",
          "В течение 96 часов",
        ],
        correctAnswers: ["В течение 72 часов"],
      },
      {
        code: "20208072",
        text: "Какая информация должна быть указана в перечне газоопасных работ? Выберите два правильных варианта ответа.",
        answers: [
          "Структурное подразделение организации (производство, цех, отделение, установка, участок)",
          "Список лиц, ответственных за подготовку и проведение газоопасных работ эксплуатирующей организации",
          "Категория исполнителей (персонал эксплуатирующей организации, газоспасательной службы), выполняющих указанные работы",
          "Список лиц, ответственных за безопасное проведение газоопасных работ подрядной организации",
        ],
        correctAnswers: [
          "Структурное подразделение организации (производство, цех, отделение, установка, участок)",
          "Категория исполнителей (персонал эксплуатирующей организации, газоспасательной службы), выполняющих указанные работы",
        ],
      },
      {
        code: "20208073",
        text: "В каких из перечисленных случаев должны проводиться газоопасные работы? Выберите два правильных варианта ответа.",
        answers: [
          "Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть механизированы",
          "Когда газоопасные работы могут быть проведены без непосредственного участия людей",
          "Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть автоматизированы",
        ],
        correctAnswers: [
          "Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть механизированы",
          "Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть автоматизированы",
        ],
      },
      {
        code: "20208074",
        text: "К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?",
        answers: [
          "К I группе",
          "К II группе",
          "К группе работ высокой степени опасности",
          "К группе работ средней степени опасности",
        ],
        correctAnswers: ["К II группе"],
      },
      {
        code: "20208075",
        text: "Кого должны поставить в известность о начале и сроках работ по реконструкции, техническому перевооружению и капитальному ремонту линейных сооружений ОПО МТ производители работ перед началом выполнения данных работ?",
        answers: [
          "Организации (собственников), эксплуатирующие сооружения, проходящие в одном техническом коридоре с ОПО МТ",
          "Территориальный орган Ростехнадзора",
          "Проектную организацию",
          "Местные органы муниципального управления",
        ],
        correctAnswers: [
          "Организации (собственников), эксплуатирующие сооружения, проходящие в одном техническом коридоре с ОПО МТ",
        ],
      },
      {
        code: "20208076",
        text: "Что необходимо контролировать в месте проведения ремонтных, газоопасных работ на ОПО МТ с использованием приборов контроля с автоматической сигнализацией?",
        answers: [
          "Влажность",
          "Температуру",
          "Содержание горючих паров и газов в воздухе рабочей зоны или помещения",
          "Атмосферное давление",
        ],
        correctAnswers: [
          "Содержание горючих паров и газов в воздухе рабочей зоны или помещения",
        ],
      },
      {
        code: "20208077",
        text: "Какой должна быть концентрация горючих паров и газов в месте проведения сварочных и других огневых работ на опасных производственных объектах магистральных трубопроводов?",
        answers: [
          "Не превышающая 20% величины нижнего концентрационного предела распространения пламени",
          "Не превышающая 25% величины нижнего концентрационного предела распространения пламени",
          "Не превышающая 30% величины нижнего концентрационного предела распространения пламени",
          "Не превышающая 35% величины нижнего концентрационного предела распространения пламени",
        ],
        correctAnswers: [
          "Не превышающая 20% величины нижнего концентрационного предела распространения пламени",
        ],
      },
      {
        code: "20208078",
        text: "Кого обязаны извещать о начале и сроках проведения работ по реконструкции, техническому перевооружению и капитальному ремонту линейных сооружений ОПО МТ производители работ перед началом выполнения этих работ?",
        answers: [
          "Организации (собственников), эксплуатирующие сооружения, проходящие в одном техническом коридоре с ОПО МТ",
          "Проектные организации",
          "Ростехнадзор",
        ],
        correctAnswers: [
          "Организации (собственников), эксплуатирующие сооружения, проходящие в одном техническом коридоре с ОПО МТ",
        ],
      },
      {
        code: "20208079",
        text: "Каким испытаниям должны быть подвергнуты трубопроводы линейной части ОПО МТ по завершении строительства, реконструкции, технического перевооружения и капитального ремонта?",
        answers: [
          "На износ и твердость",
          "На прочность и герметичность",
          "На деформацию",
          "Требования к испытаниям не регламентируются",
        ],
        correctAnswers: ["На прочность и герметичность"],
      },
      {
        code: "20208080",
        text: "Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?",
        answers: [
          "Лица, допущенные к выполнению газоопасных работ, должны быть не моложе 18 лет",
          "Лица, допущенные к выполнению газоопасных работ, должны проходить медицинский осмотр в соответствии с требованиями законодательством Российской Федерации",
          "Лица, допущенные к выполнению газоопасных работ, должны проходить обучение приемам и методам проведения работ",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20208081",
        text: "Когда могут проводиться газоопасные работы, выполняемые по наряду-допуску?",
        answers: [
          "В ночное время суток с участием лица, ответственного за выполнение газоопасных работ",
          "В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа с уведомлением лиц, согласовавших наряд-допуск",
          "Во время грозы с соблюдением мер по обеспечению безопасного проведения работ",
          "В дневное или темное время суток в присутствии лица, ответственного за выполнение газоопасных работ",
        ],
        correctAnswers: [
          "В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа с уведомлением лиц, согласовавших наряд-допуск",
        ],
      },
      {
        code: "20208082",
        text: "Где регистрируются газоопасные работы II группы?",
        answers: [
          "В журнале учета газоопасных работ, проводимых без наряда-допуска на проведение газоопасных работ",
          "В журнале регистрации нарядов-допусков на проведение газоопасных работ",
          "В журнале регистрации целевого инструктажа по проведению газоопасных работ",
          "В журнале учета выдачи нарядов-допусков на выполнение работ повышенной опасности",
        ],
        correctAnswers: [
          "В журнале учета газоопасных работ, проводимых без наряда-допуска на проведение газоопасных работ",
        ],
      },
      {
        code: "20208083",
        text: "Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?",
        answers: [
          "Только руководитель структурного подразделения, где будет проводиться газоопасная работа, или его уполномоченный заместитель",
          "Только специалист, обученный пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность",
          "Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ",
          "Любой из специалистов эксплуатирующей организации",
        ],
        correctAnswers: [
          "Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ",
        ],
      },
    ],
    20209: [
      {
        code: "20209000",
        text: "Какой документ, определяющий порядок организации надежного и безопасного ведения технологического процесса, разрабатывается после ввода в эксплуатацию ОПО МАП?",
        answers: [
          "Технологический регламент на эксплуатацию",
          "Паспорт безопасности",
          "Заключение экспертизы промышленной безопасности",
          "Экологический паспорт",
        ],
        correctAnswers: ["Технологический регламент на эксплуатацию"],
      },
      {
        code: "20209001",
        text: "Наличие каких из указанных разделов технологического регламента на эксплуатацию ОПО МАП не предусмотрено Правилами безопасности для опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Анализ опасности ОПО МАП",
          "Технические характеристики ОПО МАП, оборудования площадочных сооружений и свойства перекачиваемого продукта",
          "Порядок обнаружения утечек",
          "Паспортные характеристики технических устройств, применяемых на ОПО МАП",
        ],
        correctAnswers: ["Анализ опасности ОПО МАП"],
      },
      {
        code: "20209002",
        text: "Какие случаи пересмотра технологических регламентов указаны неверно?",
        answers: [
          "Изменение проектной документации",
          "Внесение изменений в документацию системы управления промышленной безопасностью",
          "Изменение требований промышленной безопасности",
          "Изменение параметров технологического процесса",
        ],
        correctAnswers: [
          "Внесение изменений в документацию системы управления промышленной безопасностью",
        ],
      },
      {
        code: "20209003",
        text: "В какие сроки эксплуатирующая организация обязана пересматривать инструкции на рабочих местах?",
        answers: [
          "1 раз в 3 года",
          "1 раз в год",
          "1 раз в 5 лет",
          "1 раз в 2 года",
        ],
        correctAnswers: ["1 раз в 5 лет"],
      },
      {
        code: "20209004",
        text: "С какой периодичностью осуществляется проверка продольного профиля линейной части МАП?",
        answers: [
          "1 раз в год",
          "1 раз в 2 года",
          "1 раз в 3 года",
          "При проведении плановых обследований в соответствии с проектной документацией (документацией)",
        ],
        correctAnswers: [
          "При проведении плановых обследований в соответствии с проектной документацией (документацией)",
        ],
      },
      {
        code: "20209005",
        text: "Кто утверждает оптимальные маршруты следования персонала и техники к объектам и участкам ОПО МАП, разрабатываемые в территориальных подразделениях?",
        answers: [
          "Руководитель подразделения",
          "Главный механик подразделения",
          "Главный инженер подразделения",
          "Руководитель, ответственный за локализацию и ликвидацию аварии",
        ],
        correctAnswers: ["Руководитель подразделения"],
      },
      {
        code: "20209006",
        text: "Допускается ли остановка работы станций электрохимической защиты от коррозии МАП? Если да, то в каких целях и в каком порядке?",
        answers: [
          "Допускается для проведения ремонтов в порядке, установленном инструкциями",
          "Не допускается, система электрохимической защиты должна работать непрерывно",
          "Допускается в порядке, установленном Правилами безопасности для опасных производственных объектов магистральных трубопроводов транспортирования жидкого аммиака",
          "Допускается по согласованию с территориальными органами Ростехнадзора",
        ],
        correctAnswers: [
          "Допускается для проведения ремонтов в порядке, установленном инструкциями",
        ],
      },
      {
        code: "20209007",
        text: "Какие документы должны быть оформлены на установки системы электрохимической защиты?",
        answers: ["Инструкции", "Паспорта", "Декларации соответствия"],
        correctAnswers: ["Паспорта"],
      },
      {
        code: "20209008",
        text: "Какая минимальная длина труб и кабеля связи в аварийном запасе должна быть предусмотрена эксплуатирующей организацией?",
        answers: [
          "0,1 % от протяженности трубопроводной части и кабеля",
          "0,2 % от протяженности трубопроводной части и кабеля",
          "0,3 % от протяженности трубопроводной части и кабеля",
          "0,5 % от протяженности трубопроводной части и кабеля",
        ],
        correctAnswers: [
          "0,2 % от протяженности трубопроводной части и кабеля",
        ],
      },
      {
        code: "20209009",
        text: "Какое из указанных видов обследований площадочных объектов ОПО МАП указано неверно?",
        answers: [
          "Полное техническое обследование в режиме выведения из эксплуатации",
          "Оценка фактического состояния строительных конструкций",
          "Наружное обследование в режиме эксплуатации",
        ],
        correctAnswers: [
          "Оценка фактического состояния строительных конструкций",
        ],
      },
      {
        code: "20209010",
        text: "На сколько часов работы должно быть предусмотрено резервное питание центрального пункта управления в случае отключения от основного источника электропитания?",
        answers: ["На 2 часа", "На 3 часа", "На 4 часа", "На 5 часов"],
        correctAnswers: ["На 4 часа"],
      },
      {
        code: "20209011",
        text: "Каким требованиям должны соответствовать средства индивидуальной защиты персонала?",
        answers: [
          'Требованиям технического регламента "О безопасности средств индивидуальной защиты"',
          "Требованиям трудового законодательства",
          "Требованиям Правил безопасности для опасных производственных объектов магистральных трубопроводов транспортирования жидкого аммиака",
          "Требованиям, установленным службой производственного контроля организации",
        ],
        correctAnswers: [
          'Требованиям технического регламента "О безопасности средств индивидуальной защиты"',
        ],
      },
      {
        code: "20209012",
        text: "На основании каких документов могут проводиться мероприятия по консервации и ликвидации ОПО МАП?",
        answers: [
          "На основании документов, согласованных с Ростехнадзором",
          "На основании документации, на которую получено положительное заключение экспертизы промышленной безопасности",
          "На основании технологических карт",
        ],
        correctAnswers: [
          "На основании документации, на которую получено положительное заключение экспертизы промышленной безопасности",
        ],
      },
      {
        code: "20209013",
        text: "Выполнение каких действий по выводу ОПО МАП из консервации и ввода в эксплуатацию не предусмотрены Правилами безопасности для опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "Получение разрешения Ростехнадзора",
          "Разработка рабочей программы мероприятий с указанием перечня работ, порядка и сроков их выполнения",
          "Ревизия, проверка, опробование и испытание трубопроводов и оборудования",
          "Составление акта о вводе объекта в действие",
        ],
        correctAnswers: ["Получение разрешения Ростехнадзора"],
      },
      {
        code: "20209014",
        text: "На каком максимальном расстоянии от оси аммиакопровода расположены населенные пункты, жители которых должны быть ознакомлены с опасными свойствами аммиака и простейшими методами защиты от него?",
        answers: ["До 1 км", "До 2 км", "До 2,5 км", "До 3,5 км"],
        correctAnswers: ["До 2,5 км"],
      },
      {
        code: "20209015",
        text: "Для реализации каких механизмов правового регулирования не предусмотрена необходимость проведения анализа опасностей технологических процессов, количественный анализ риска и иные методы анализа риска аварий, связанных с выбросом транспортируемых углеводородов?",
        answers: [
          "Риск-менеджмента",
          "Производственного контроля",
          "Обоснования безопасности",
          "Декларирования промышленной безопасности",
        ],
        correctAnswers: ["Производственного контроля"],
      },
      {
        code: "20209016",
        text: "Что не учитывается при проведении количественного анализа риска ОПО МАП?",
        answers: [
          "Квалификация производственного персонала",
          "Давление в трубопроводе",
          "Метеоусловия",
          "Параметры истечения аммиака",
          "Рельеф местности",
        ],
        correctAnswers: ["Квалификация производственного персонала"],
      },
      {
        code: "20209017",
        text: "Какое безопасное расстояние от поселения городского типа до оси аммиакопровода с номинальным диаметром свыше 300 мм установлено в Правилах безопасности для опасных производственных объектов магистральных трубопроводов?",
        answers: ["200 м", "400 м", "6000 м", "1000 м"],
        correctAnswers: ["1000 м"],
      },
      {
        code: "20209018",
        text: "Какое безопасное расстояние от отдельно стоящих жилых домов до оси аммиакопровода с номинальным диаметром свыше 150 до 300 мм установлено в Правилах безопасности для опасных производственных объектов магистральных трубопроводов?",
        answers: ["100 м", "200 м", "300 м", "1000 м"],
        correctAnswers: ["200 м"],
      },
      {
        code: "20209019",
        text: "На каком расстоянии от оси аммиакопровода без письменного разрешения предприятия трубопроводного транспорта запрещается возводить любые постройки и сооружения, строить коллективные сады с жилыми домами, устраивать массовые спортивные соревнования, соревнования с участием зрителей, купания, массовый отдых людей, любительское рыболовство, расположение временных полевых жилищ и станов любого назначения, загоны для скота?",
        answers: [
          "500 м от оси трубопровода с каждой стороны",
          "700 м от оси трубопровода с каждой стороны",
          "1000 м от оси трубопровода с каждой стороны",
          "1500 м от оси трубопровода с каждой стороны",
        ],
        correctAnswers: ["1000 м от оси трубопровода с каждой стороны"],
      },
      {
        code: "20209020",
        text: "Какие виды работ могут проводиться в охранных зонах трубопроводов без получения разрешения от предприятия трубопроводного транспорта?",
        answers: [
          "Строительные работы не менее чем за 100 м от оси трубопровода в каждую сторону",
          "Ремонтно-восстановительные и полевые сельскохозяйственные работы",
          "Размещение автотранспорта на специально подготовленной площадке",
          "Оборудование временного полевого стана",
        ],
        correctAnswers: [
          "Ремонтно-восстановительные и полевые сельскохозяйственные работы",
        ],
      },
      {
        code: "20209021",
        text: "На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?",
        answers: [
          "На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах",
          "На ведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на выделенной и огражденной площадке на территории находящихся в эксплуатации опасных производственных объектов",
          "На ведение газоопасных, огневых и ремонтных работ на объектах электроэнергетики",
          "На ведение газоопасных, огневых и ремонтных работ на объектах атомной энергетики",
        ],
        correctAnswers: [
          "На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах",
        ],
      },
      {
        code: "20209022",
        text: "На основе чего осуществляется определение нагрузок и воздействий, возникающих на этапах строительства, эксплуатации, реконструкции, при техническом перевооружении, капитальном ремонте, консервации, ликвидации ОПО МАП?",
        answers: [
          "На основе результатов инженерных изысканий, получивших положительное заключение экспертизы в порядке, установленном законодательством Российской Федерации",
          "На основе результатов анализа опасностей технологических процессов и анализа риска аварий",
          "На основе проектной документации",
          "На основе технических решений, принимаемых при разработке проектной документации",
        ],
        correctAnswers: [
          "На основе результатов инженерных изысканий, получивших положительное заключение экспертизы в порядке, установленном законодательством Российской Федерации",
        ],
      },
      {
        code: "20209023",
        text: "В каких документах определен порядок и последовательность ликвидации разлива жидкого аммиака? Выберите два правильных варианта ответа.",
        answers: [
          "В положении о производственном контроле",
          "В эксплуатационной документации",
          "В плане мероприятий по локализации и ликвидации последствий аварий",
          "В технологическом регламенте",
        ],
        correctAnswers: [
          "В плане мероприятий по локализации и ликвидации последствий аварий",
          "В технологическом регламенте",
        ],
      },
      {
        code: "20209024",
        text: "Насосные станции ОПО МАП, размещенные на каком расстоянии от зданий и сооружений, должны располагаться на более низких отметках по отношению к этим объектам?",
        answers: [
          "Менее 3500 м",
          "Менее 3000 м",
          "Менее 2500 м",
          "Менее 2000 м",
        ],
        correctAnswers: ["Менее 2000 м"],
      },
      {
        code: "20209025",
        text: "С какой периодичностью должна проводиться информационная работа с населением близлежащих населенных пунктов по правилам поведения в зоне безопасных расстояний МАП и действиям в случае обнаружения запаха аммиака?",
        answers: [
          "1 раз в 3 года",
          "2 раза в год",
          "Ежегодно",
          "1 раз в квартал",
        ],
        correctAnswers: ["Ежегодно"],
      },
      {
        code: "20209026",
        text: "Какая трубопроводная арматура, предназначенная для соединений с трубами сваркой встык, должна применяться для линейной части МАП? Выберите два правильных варианта ответа.",
        answers: [
          "Пластиковая трубопроводная арматура",
          "Литая трубопроводная арматура",
          "Чугунная трубопроводная арматура",
          "Кованая трубопроводная арматура",
        ],
        correctAnswers: [
          "Литая трубопроводная арматура",
          "Кованая трубопроводная арматура",
        ],
      },
      {
        code: "20209027",
        text: "Какой документацией определяется защита оборудования и трубопроводов площадочных сооружений от избыточного давления, в том числе при гидроударе?",
        answers: [
          "Проектной документацией (документацией)",
          "Эксплуатационной документацией",
          "Планом мероприятий по локализации и ликвидации последствий аварий",
          "Техническим регламентом",
        ],
        correctAnswers: ["Проектной документацией (документацией)"],
      },
      {
        code: "20209028",
        text: "На сколько групп подразделяются газоопасные работы в зависимости от степени опасности и на основании каких критериев устанавливается та или иная группа?",
        answers: [
          "На 3 группы, в зависимости от степени риска проводимых работ",
          "На 2 группы, в зависимости от того, проводятся работы в закрытом или открытом пространстве",
          "На 2 группы, в зависимости от того, проводятся газоопасные работы с оформлением наряда-допуска или без оформления",
          "На 4 группы, в зависимости от степени риска проводимых работ",
        ],
        correctAnswers: [
          "На 2 группы, в зависимости от того, проводятся газоопасные работы с оформлением наряда-допуска или без оформления",
        ],
      },
      {
        code: "20209029",
        text: "Какие меры необходимо предпринять при обнаружении на месте производства работ в охранной зоне подземных коммуникаций и сооружений, не указанных в проектной документации? Выберите два правильных варианта ответа.",
        answers: [
          "Вызвать аварийно-спасательную бригаду",
          "Немедленно остановить работы",
          "Немедленно вывести персонал и технические средства за пределы охранной зоны",
          "Вызвать представителя эксплуатационной организации",
        ],
        correctAnswers: [
          "Немедленно остановить работы",
          "Вызвать представителя эксплуатационной организации",
        ],
      },
      {
        code: "20209030",
        text: "В каких случаях не применяются Правила безопасности для опасных производственных объектов магистральных трубопроводов?",
        answers: [
          "При проведении экспертизы промышленной безопасности внутризаводских трубопроводов организаций, производящих и потребляющих жидкий аммиак",
          "При разработке технологических процессов, проектировании, строительстве, эксплуатации, реконструкции ОПО МАП",
          "При диагностировании и ремонте технических устройств, применяемых на ОПО МАП",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: [
          "При проведении экспертизы промышленной безопасности внутризаводских трубопроводов организаций, производящих и потребляющих жидкий аммиак",
        ],
      },
      {
        code: "20209031",
        text: "Что не относится к подготовительным операциям перед обследованием оборудования с выводом его из эксплуатации?",
        answers: [
          "Дегазация",
          "Дефектоскопия",
          "Опорожнение",
          "Очистка",
          "Установка заглушек",
        ],
        correctAnswers: ["Дефектоскопия"],
      },
      {
        code: "20209032",
        text: "Какая должна быть ударная вязкость на образцах Менаже размером 5 х 10 х 55 мм при температуре минус 40 °С для толщины стенки менее 10 мм?",
        answers: ["4 кгс/см²", "5 кгс/см²", "3 кгс/см²", "2 кгс/см²"],
        correctAnswers: ["4 кгс/см²"],
      },
      {
        code: "20209033",
        text: "Какой должна быть минимальная глубина заложения магистрального трубопровода при переходах судоходных рек, каналов и других водных препятствий от отметки дна, не подверженного переформированию, до верха МАП?",
        answers: ["1,2 м", "1,4 м", "1,0 м", "0,8 м"],
        correctAnswers: ["1,4 м"],
      },
      {
        code: "20209034",
        text: "Какие металлы допускается использовать во всех конструктивных элементах МАП, работающих в присутствии жидкого аммиака?",
        answers: ["Цинк", "Медь", "Алюминий", "Серебро", "Все ответы неверны"],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20209035",
        text: "Кого должны поставить в известность о начале и сроках проведения работ по реконструкции, техническому перевооружению и капитальному ремонту линейных сооружений ОПО МАП производители работ?",
        answers: [
          "Территориальный орган Ростехнадзора",
          "Проектную организацию",
          "Организацию, осуществляющую экспертизу промышленной безопасности",
          "Организации (собственников), эксплуатирующие сооружения, проходящие в одном техническом коридоре с ОПО МАП",
        ],
        correctAnswers: [
          "Организации (собственников), эксплуатирующие сооружения, проходящие в одном техническом коридоре с ОПО МАП",
        ],
      },
      {
        code: "20209036",
        text: "Работа в течение какого времени после заполнения линейных сооружений ОПО МАП жидким аммиаком считается комплексным опробованием линейного сооружения ОПО МТ?",
        answers: [
          "В течение 96 ч",
          "В течение 72 ч",
          "В течение 48 ч",
          "В течение 120 ч",
        ],
        correctAnswers: ["В течение 72 ч"],
      },
      {
        code: "20209037",
        text: "Какие наиболее опасные сценарии аварий, при которых возможны максимальные размеры зоны воздействия (поражения), определяются для прогнозирования наиболее масштабного химического заражения на ОПО МАП?",
        answers: [
          "Метеорологические условия: класс устойчивости атмосферы - F, скорость ветра на высоте 10 м - 1 м/с",
          "Сценарий с полным разрушением емкости (технологической, складской, транспортной и иных), содержащей опасные вещества в максимальном количестве",
          'Сценарий "гильотинного" разрыва трубопровода с максимальным расходом при максимальной длительности выброса',
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20209038",
        text: "Какое выбирается минимальное количество маршрутов подъезда к месту аварии на МАП в периоды паводков при отсутствии точных сведений о состоянии сезонных переправ?",
        answers: ["3", "2", "4", "Определяется исходя из текущей обстановки"],
        correctAnswers: ["2"],
      },
      {
        code: "20209039",
        text: "Кому обязан немедленно сообщить о повреждении трубопровода производственный персонал, выполняющий осмотр или обслуживание инженерных коммуникаций и объектов, находящихся в районе прохождения трубопровода?",
        answers: [
          "Предприятиям - владельцам этих объектов",
          "Диспетчерской или аварийной службе производственного подразделения, эксплуатирующего данный участок трубопровода",
          "Аварийно-восстановительному подразделению",
          "Соответствующим органам власти и управления",
        ],
        correctAnswers: [
          "Диспетчерской или аварийной службе производственного подразделения, эксплуатирующего данный участок трубопровода",
        ],
      },
      {
        code: "20209040",
        text: "Какой должна быть минимальная глубина заложения магистрального трубопровода на болотах или торфяных грунтах, подлежащих осушению?",
        answers: ["1,2 м", "1,4 м", "1,7 м", "1,0 м"],
        correctAnswers: ["1,7 м"],
      },
      {
        code: "20209041",
        text: "На кого возлагается планирование и осуществление мероприятий по предупреждению возможных аварий и обеспечению постоянной готовности к локализации и ликвидации последствий аварии на ОПО МАП?",
        answers: [
          "На территориальный орган Ростехнадзора",
          "На проектную организацию",
          "На специализированную организацию",
          "На эксплуатирующую организацию",
        ],
        correctAnswers: ["На эксплуатирующую организацию"],
      },
      {
        code: "20209042",
        text: "Какие требования, предусмотренные Правилами безопасности для опасных производственных объектов магистральных трубопроводов к секциям магистральных трубопроводов, указаны верно? Выберите два правильных варианта ответа.",
        answers: [
          "Длина каждой секции должна быть не более 5 км при условном диаметре трубопровода до 450 мм включительно",
          "Длина каждой секции должна быть не более 10 км при условном диаметре трубопровода до 350 мм включительно",
          "Длина каждой секции должна быть не более 15 км при условном диаметре трубопровода до 350 мм включительно",
          "Длина каждой секции должна быть не более 10 км при условном диаметре трубопровода до 500 мм включительно",
        ],
        correctAnswers: [
          "Длина каждой секции должна быть не более 15 км при условном диаметре трубопровода до 350 мм включительно",
          "Длина каждой секции должна быть не более 10 км при условном диаметре трубопровода до 500 мм включительно",
        ],
      },
      {
        code: "20209043",
        text: "Что не требуется в обязательном порядке предусматривать на отдельно стоящих раздаточных станциях, кроме технологического оборудования, позволяющего вести процесс выдачи жидкого аммиака, в целях обеспечения безопасности технологического процесса?",
        answers: [
          "Аварийный душ и фонтанчик для промывания глаз",
          "Факельную установку",
          "Дренажную емкость для приема дренажей",
          "Систему автоматического газового пожаротушения",
        ],
        correctAnswers: ["Систему автоматического газового пожаротушения"],
      },
      {
        code: "20209044",
        text: "Какой из перечисленных участков ОПО МАП не определяется проектной документацией как наиболее опасный участок?",
        answers: [
          "Переход через естественные водные объекты",
          "Участок, проходящий по землям особо охраняемых природных территорий",
          "Участок, расположенный вблизи автомобильных дорог",
          "Переход через искусственные водные объекты",
        ],
        correctAnswers: ["Участок, расположенный вблизи автомобильных дорог"],
      },
      {
        code: "20209045",
        text: "Оповещение населения каких населенных пунктов обязана обеспечить эксплуатирующая организация при возникновении аварий с применением средств локальной системы оповещения?",
        answers: [
          "Расположенных на расстоянии 2,5 км от оси МАП",
          "Расположенных на расстоянии 3,5 км от оси МАП",
          "Расположенных на расстоянии 5 км от оси МАП",
          "Расположенных на расстоянии 7 км от оси МАП",
        ],
        correctAnswers: ["Расположенных на расстоянии 2,5 км от оси МАП"],
      },
      {
        code: "20209046",
        text: "Кто должен устанавливать продолжительность периода, на который ОПО МАП выводят из эксплуатации?",
        answers: [
          "Проектная организация",
          "Cпециализированная организация",
          "Эксплуатирующая организация",
          "Организация, проводящая экспертизу промышленной безопасности",
        ],
        correctAnswers: ["Эксплуатирующая организация"],
      },
      {
        code: "20209047",
        text: "В какой срок должны сообщать предприятия, эксплуатирующие инженерные коммуникации, проходящие в техническом коридоре, всем заинтересованным предприятиям вносимые изменения в схему объектов с точным указанием их взаиморасположения?",
        answers: [
          "В месячный срок",
          "В двухнедельный срок",
          "В недельный срок",
          "В трехмесячный срок",
        ],
        correctAnswers: ["В месячный срок"],
      },
      {
        code: "20209048",
        text: "Каким документом определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?",
        answers: [
          "Правилами в области пожарной безопасности",
          "Технологическим регламентом",
          "Федеральными правилами в области промышленной безопасности",
          "Организационно-распорядительными документами руководителя",
        ],
        correctAnswers: [
          "Организационно-распорядительными документами руководителя",
        ],
      },
      {
        code: "20209049",
        text: "Какие факторы следует учитывать при определении периодичности, мест и методов контроля, применяемых при техническом диагностировании?",
        answers: [
          "Расчетный срок службы технических устройств и сооружений",
          "Сведения о неисправностях, проведенных ремонтах",
          "Данные о режимах работы системы электрохимической защиты",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20209050",
        text: "Каким образом следует предусматривать пересечения МАП с другими трубопроводами и кабелями?",
        answers: [
          "Ниже этих трубопроводов и кабелей",
          "Выше этих трубопроводов и кабелей",
          "Определяется проектной документацией",
          "Не регламентируется",
        ],
        correctAnswers: ["Ниже этих трубопроводов и кабелей"],
      },
      {
        code: "20209051",
        text: "Какую функцию должна обеспечивать автоматизированная система управления технологическими процессами объектов магистрального аммиакопровода?",
        answers: [
          "Cигнализацию состояния оборудования и параметров режима перекачки",
          "Ручное или местное автоматическое управление работой насосных станций в электромодулях",
          "Выдерживание заданных параметров перекачки жидкого аммиака по МАП согласно технологическому регламенту",
          "Все перечисленные функции",
        ],
        correctAnswers: ["Все перечисленные функции"],
      },
      {
        code: "20209052",
        text: "В каких местах допускается размещение промежуточных насосных станций ОПО МАП?",
        answers: [
          "В местах расположения гидротехнических сооружений",
          "Перед переходами через реки, каналы и водоемы, используемые для хозяйственно-питьевого водоснабжения",
          "В местах расположения мостов с движением транспорта",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20209053",
        text: "Кто утверждает перечень аварийного запаса средств индивидуальной защиты?",
        answers: [
          "Технический руководитель организации",
          "Руководитель подразделения аварийно-спасательного формирования",
          "Руководитель организации",
          "Специалист по промышленной безопасности",
        ],
        correctAnswers: ["Технический руководитель организации"],
      },
      {
        code: "20209054",
        text: "К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?",
        answers: ["К I группе", "К II группе", "К III группе"],
        correctAnswers: ["К II группе"],
      },
      {
        code: "20209055",
        text: "На каких участках должна быть предусмотрена система дренажной защиты?",
        answers: [
          "На всех участках распространения блуждающих токов",
          "На участках, где возможно скопление паводковых вод",
          "На заболоченных участках вдоль трубопровода",
        ],
        correctAnswers: ["На всех участках распространения блуждающих токов"],
      },
      {
        code: "20209056",
        text: "Какие трубы должны применяться для МАП? Выберите два правильных варианта ответа.",
        answers: [
          "Горячекатаные бесшовные с содержанием углерода не более 0,2 %",
          "Сварные трубы из спокойной стали с содержанием меди не более 0,3 %",
          "Горячекатаные бесшовные с содержанием углерода не более 0,5 %",
          "Сварные трубы из спокойной стали с содержанием меди не более 0,6 %",
        ],
        correctAnswers: [
          "Горячекатаные бесшовные с содержанием углерода не более 0,2 %",
          "Сварные трубы из спокойной стали с содержанием меди не более 0,3 %",
        ],
      },
      {
        code: "20209057",
        text: "Какой может быть минимальная глубина заложения МПА в скальных грунтах, выходящих на поверхность дна на судоходных реках, считая от верха забалластированного трубопровода?",
        answers: ["1,4 м", "1,0 м", "0,5 м", "0,8 м"],
        correctAnswers: ["0,8 м"],
      },
      {
        code: "20209058",
        text: "Каким образом производятся работы, связанные с временным затоплением земель, находящихся в охранных зонах трубопроводов?",
        answers: [
          "По согласованию между землепользователем, предприятием трубопроводного транспорта и Ростехнадзором",
          "По согласованию между землепользователем и Ростехнадзором",
          "По согласованию между землепользователем и предприятием трубопроводного транспорта",
        ],
        correctAnswers: [
          "По согласованию между землепользователем и предприятием трубопроводного транспорта",
        ],
      },
      {
        code: "20209059",
        text: "Каким способом следует предусматривать прокладку МПА через крупные глубоководные, судоходные реки, водохранилища, при сложных грунтовых условиях дна пересекаемых водных преград, на мостовых переходах и пересечениях подрабатываемых территорий?",
        answers: [
          'Способом "труба в трубе"',
          "Способом прокола",
          "Способом продавливания",
          "Любым из перечисленных способов",
        ],
        correctAnswers: ['Способом "труба в трубе"'],
      },
      {
        code: "20209060",
        text: "Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?",
        answers: [
          "Не моложе 18 лет",
          "Прошедшие медицинский осмотр в соответствии с требованиями законодательства Российской Федерации",
          "Прошедшие обучение приемам и методам проведения работ",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20209061",
        text: "Какие факторы следует учитывать при выборе трассы МАП и размещении объектов линейной части и площадочных объектов?",
        answers: [
          "Природно-климатические особенности территории строительства, гидрогеологические свойства грунтов",
          "Наличие близко расположенных производственных объектов и населенных пунктов, которые могут оказывать негативное влияние на безопасность ОПО МАП",
          "Специфические свойства жидкого аммиака",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20209062",
        text: "Какие данные должна иметь запорная арматура на трубопроводе транспортирования жидкого аммиака?",
        answers: [
          "Указатель направления потока",
          'Указатели "Открыто" и "Закрыто"',
          "Обозначение присвоенного ей номера",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20209063",
        text: "Где устанавливаются требования к обозначению мест пересечений с водными объектами, железными и автомобильными дорогами?",
        answers: [
          "В технической документации",
          "В проектной документации",
          "В инструкциях, разработанных организацией, эксплуатирующей ОПО МАП",
          "В нормативных документах, регламентирующих эксплуатацию ОПО МАП",
        ],
        correctAnswers: [
          "В инструкциях, разработанных организацией, эксплуатирующей ОПО МАП",
        ],
      },
      {
        code: "20209064",
        text: "Кто определяет структурные подразделения, на которые возложены полномочия по согласованию перечня газоопасных работ?",
        answers: [
          "Руководитель службы производственного контроля",
          "Руководитель эксплуатирующей организации",
          "Руководитель эксплуатирующей организации совместно с руководителем аварийно-спасательной службы",
          "Главный инженер эксплуатирующей организации",
        ],
        correctAnswers: ["Руководитель эксплуатирующей организации"],
      },
      {
        code: "20209065",
        text: "Расположение каких из перечисленных объектов, зданий и сооружений допускается в зонах безопасных расстояний от оси подземных трубопроводов ОПО МАП?",
        answers: [
          "Очистные сооружения, водопроводные и канализационные насосные станции с постоянным присутствием обслуживающего персонала",
          "Железные дороги общей сети и автомобильные дороги общего пользования категории I",
          "Мачты (башни) и сооружения многоканальной радиорелейной линии связи",
          "Автозаправочные станции, наливные станции и железнодорожные эстакады",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20209066",
        text: "Какая из перечисленных документаций должна храниться в эксплуатирующей ОПО МАП организации?",
        answers: [
          "Эксплуатационная",
          "Исполнительная",
          "Проектная",
          "Вся перечисленная",
        ],
        correctAnswers: ["Вся перечисленная"],
      },
      {
        code: "20209067",
        text: "Какие требования к эксплуатирующей организации по предупреждению и ликвидации аварий на ОПО МАП указаны неверно?",
        answers: [
          "Планирование и осуществление мероприятий по предупреждению возможных аварий и обеспечению постоянной готовности к локализации и ликвидации последствий аварии на ОПО МАП возлагается на эксплуатирующую организацию",
          "В эксплуатационных подразделениях должны быть созданы и укомплектованы службы аварийно-восстановительных бригад и нештатные аварийно-спасательные формирования",
          "Разработка организационных и технических мероприятий для безопасной эксплуатации ОПО МАП и подготовка персонала к действиям по локализации и ликвидации последствий аварий возлагается на эксплуатирующую организацию",
          "Все перечисленное указано верно",
        ],
        correctAnswers: ["Все перечисленное указано верно"],
      },
      {
        code: "20209068",
        text: "Какая информация не должна быть обозначена на карте территориальных подразделений эксплуатирующей организации ОПО МАП?",
        answers: [
          "Маршруты следования персонала и техники к объектам и участкам ОПО МАП",
          "Границы административных районов и областей",
          "Дислокация всех наземных сооружений МАП (главный пост секционирования, обратный клапан, сателлитный пост секционирования, необслуживаемый усилительный пункт, раздаточная станция, насосная станция)",
          "Границы обслуживания участка МАП территориальным подразделением",
        ],
        correctAnswers: [
          "Маршруты следования персонала и техники к объектам и участкам ОПО МАП",
        ],
      },
      {
        code: "20209069",
        text: "Какие действия должны быть организованы на всех этапах выполнения работ по строительству, реконструкции, техническому перевооружению и капитальному ремонту ОПО МАП?",
        answers: [
          "Контроль качества выполнения работ",
          "Контроль качества выполнения всех технологических операций",
          "Входной контроль конструкций, изделий, материалов, оборудования и технических устройств",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20209070",
        text: "Какие обозначения не должна иметь в обязательном порядке разделительная запорная арматура на трубопроводе МАП?",
        answers: [
          "Указатель направления потока",
          'Указатели "Открыто" и "Закрыто"',
          "Обозначение присвоенного ей номера",
          "Границы обслуживания участка МАП территориальным подразделением",
        ],
        correctAnswers: [
          "Границы обслуживания участка МАП территориальным подразделением",
        ],
      },
      {
        code: "20209071",
        text: "Кто утверждает паспорта линейных участков, подводных и мостовых переходов аммиакопровода?",
        answers: [
          "Руководитель эксплуатирующей организации",
          "Технический руководитель территориального подразделения МАП",
          "Технический руководитель эксплуатирующей организации",
          "Руководитель технологической службы",
        ],
        correctAnswers: [
          "Технический руководитель территориального подразделения МАП",
        ],
      },
      {
        code: "20209072",
        text: "На каком расстоянии производится установка опознавательных знаков обозначения трассы магистрального трубопровода?",
        answers: [
          "В пределах прямой видимости, но не реже чем через 300 м",
          "В пределах прямой видимости, но не реже чем через 400 м",
          "В пределах прямой видимости, но не реже чем через 500 м и на углах поворота",
          "В пределах прямой видимости, но не реже чем через 200 м",
        ],
        correctAnswers: [
          "В пределах прямой видимости, но не реже чем через 500 м и на углах поворота",
        ],
      },
      {
        code: "20209073",
        text: "Какое количество запасных коробок к каждому фильтрующему противогазу должен иметь эксплуатационный персонал, выполняющий работы по осмотру и обслуживанию ОПО МАП, исходя из особенностей технологического процесса?",
        answers: ["1", "Не менее 3", "Не менее 2", "Не регламентируется"],
        correctAnswers: ["Не менее 2"],
      },
      {
        code: "20209074",
        text: "На основании какой документации определяется возможность очистки полости трубопроводов после строительства, реконструкции, технического перевооружения и капитального ремонта ОПО МАП? Выберите два правильных варианта ответа.",
        answers: [
          "Технологического регламента",
          "Паспорта безопасности",
          "Проектной документации (документации)",
          "Заключения экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "Технологического регламента",
          "Проектной документации (документации)",
        ],
      },
      {
        code: "20209075",
        text: "Куда должны быть переданы материалы фактического положения трубопровода (исполнительная съемка) с привязкой охранных зон, входящих в его состав коммуникаций и объектов?",
        answers: [
          "В территориальные органы Ростехнадзора",
          "В территориальные органы Министерства Российской Федерации по делам ГО",
          "Заинтересованным органам исполнительной власти",
          "В местные органы власти и управления",
        ],
        correctAnswers: ["В местные органы власти и управления"],
      },
      {
        code: "20209076",
        text: "Какой документацией определяются объем и периодичность выполняемых работ при техническом обслуживании и ремонте ОПО МАП?",
        answers: [
          "Технологическим регламентом",
          "Проектной документацией",
          "Нормативно-техническими документами заводов-изготовителей",
          "Всеми перечисленными документами",
        ],
        correctAnswers: ["Всеми перечисленными документами"],
      },
      {
        code: "20209077",
        text: "Кем должны быть разработаны и утверждены схемы и карты трассы МАП, маршрутные карты с обозначением кратчайших путей подъездов к основным объектам трассы в эксплуатационных подразделениях МАП?",
        answers: [
          "Техническим руководителем организации",
          "Руководителем, ответственным за проведение работ по локализации и ликвидации последствий аварий",
          "Руководителем организации",
          "Комиссией эксплуатирующей организации",
        ],
        correctAnswers: ["Техническим руководителем организации"],
      },
      {
        code: "20209078",
        text: "Какие требования промышленной безопасности при проектировании площадочных сооружений ОПО МАП указаны верно?",
        answers: [
          "Насосные станции перекачки аммиака должны иметь безопасные системы сброса аммиака с предклапанных, дренажных и продувочных линий, обоснованные в проектной документации (документации)",
          "Проектной документацией (документацией) для насосных и газоперекачивающих агрегатов насосных и компрессорных станций должны быть предусмотрены технические решения, учитывающие компенсацию температурных, динамических и вибрационных нагрузок",
          "Для контроля загазованности воздушной среды во взрывоопасных зонах производственных помещений, а также на открытых площадках сливо-наливных эстакад, на открытых площадках стендеров причальных сооружений должны быть предусмотрены средства автоматического дистанционного непрерывного газового контроля с сигнализацией, срабатывающей при достижении предельно допустимых величин и выдающей сигналы в систему управления соответствующим технологическим процессом, реализующую соответствующие противоаварийные автоматические защиты",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20209079",
        text: "Какое значение составляет смертельная токсодоза аммиака?",
        answers: [
          "15,0 мг x мин/л",
          "50,0 мг x мин/л",
          "100,0 мг x мин/л",
          "150,0 мг x мин/л",
        ],
        correctAnswers: ["150,0 мг x мин/л"],
      },
      {
        code: "20209080",
        text: "Какой минимальный срок хранения установлен для журнала регистрации нарядов-допусков на проведение газоопасных работ?",
        answers: [
          "6 месяцев со дня его окончания",
          "3 года со дня его окончания",
          "3 месяца со дня его окончания",
          "1 год со дня его окончания",
        ],
        correctAnswers: ["6 месяцев со дня его окончания"],
      },
      {
        code: "20209081",
        text: "В каком случае допускается выброс жидкого аммиака в окружающую среду при освобождении трубопроводов перед началом осуществления работ по выводу из эксплуатации ОПО МАП, подлежащих ликвидации?",
        answers: [
          "В случае письменного согласования с Росприроднадзором",
          "В случае письменного согласования с Роснедрами",
          "В случае установления данного требования Ростехнадзором",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20209082",
        text: "Чье письменное разрешение необходимо для производства работ по реконструкции, техническому перевооружению и капитальному ремонту ОПО МАП?",
        answers: [
          "Территориального органа Ростехнадзора",
          "Организации, осуществляющей экспертизу промышленной безопасности",
          "Эксплуатирующей организации",
          "Проектной организации",
        ],
        correctAnswers: ["Эксплуатирующей организации"],
      },
      {
        code: "20209083",
        text: "Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?",
        answers: [
          "Только специалист, обученный пожарно-техническому минимуму в объеме знания требований нормативных правовых актов, регламентирующих пожарную безопасность",
          "Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ",
          "Только руководитель структурного подразделения, где будет проводиться газоопасная работа, или его уполномоченный заместитель",
          "Любой из специалистов организации-заказчика (эксплуатирующей организации)",
        ],
        correctAnswers: [
          "Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ",
        ],
      },
      {
        code: "20209084",
        text: "Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года",
          "Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ",
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
    ],
    20210: [
      {
        code: "20210000",
        text: 'На что направлены требования, установленные ФНП "Правила безопасности опасных производственных объектов подземных хранилищ газа"?',
        answers: [
          "На обеспечение промышленной безопасности, предупреждение аварий на ОПО ПХГ",
          "На организацию производственного контроля за соблюдением требований промышленной безопасности на ОПО ПХГ",
          "На предупреждение случаев производственного травматизма на ОПО ПХГ",
        ],
        correctAnswers: [
          "На обеспечение промышленной безопасности, предупреждение аварий на ОПО ПХГ",
        ],
      },
      {
        code: "20210001",
        text: "Кем из перечисленных должностных лиц согласовываются сроки проведения газоопасных работ на опасных производственных объектах подрядными организациями?",
        answers: [
          "Лицом, ответственным за осуществление производственного контроля с учетом особенностей использования электронной подписи",
          "Руководителем эксплуатирующей организации или его уполномоченным заместителем, либо руководителем филиала (дочернего общества) эксплуатирующей организации с учетом особенностей использования электронной подписи",
          "Лицом, ответственным за проведение газоопасных работ",
          "Представителем Росприроднадзора",
        ],
        correctAnswers: [
          "Руководителем эксплуатирующей организации или его уполномоченным заместителем, либо руководителем филиала (дочернего общества) эксплуатирующей организации с учетом особенностей использования электронной подписи",
        ],
      },
      {
        code: "20210002",
        text: 'Для каких работ применяются ФНП "Правила безопасности опасных производственных объектов подземных хранилищ газа"?',
        answers: [
          "Для эксплуатации, технического перевооружения, капитального ремонта, консервации и ликвидации ОПО ПХГ",
          "Для проведения экспертизы промышленной безопасности документации на техническое перевооружение, консервацию, ликвидацию ОПО ПХГ",
          "Для разработки технологических процессов, связанных с проектированием, строительством, реконструкцией ОПО ПХГ",
          "Для проведения экспертизы промышленной безопасности технических устройств, зданий и сооружений, деклараций промышленной безопасности ОПО ПХГ",
          "Для всех вышеперечисленных работ",
        ],
        correctAnswers: ["Для всех вышеперечисленных работ"],
      },
      {
        code: "20210003",
        text: "В соответствии с каким документом обеспечивается пожарная безопасность ОПО ПХГ?",
        answers: [
          "В соответствии с Правилами безопасности опасных производственных объектов подземных хранилищ газа",
          "В соответствии с Правилами охраны внутрипромысловых трубопроводов",
          'В соответствии с Техническим регламентом о требованиях пожарной безопасности, с постановлением правительства "О противопожарном режиме" и иными нормативными правовыми актами, устанавливающими противопожарные требования, относящиеся к ОПО ПХГ',
          "В соответствии с Правилами безопасности для опасных производственных объектов внутрипромысловых трубопроводов",
        ],
        correctAnswers: [
          'В соответствии с Техническим регламентом о требованиях пожарной безопасности, с постановлением правительства "О противопожарном режиме" и иными нормативными правовыми актами, устанавливающими противопожарные требования, относящиеся к ОПО ПХГ',
        ],
      },
      {
        code: "20210004",
        text: "Какие из указанных стадий технологического проекта эксплуатации ОПО ПХГ указаны неверно?",
        answers: [
          "Опытно-промышленная эксплуатация",
          "Вывод на циклическую эксплуатацию",
          "Вывод из эксплуатации",
        ],
        correctAnswers: ["Вывод из эксплуатации"],
      },
      {
        code: "20210005",
        text: "Что следует предусматривать в технологическом проекте на создание и эксплуатацию ОПО ПХГ?",
        answers: [
          "Оценку месячной производительности эксплуатационных скважин",
          "Мероприятия по контролю герметичности объекта хранения в процессе строительства и эксплуатации ОПО ПХГ",
          "Обоснование суточных темпов отбора газа",
          "Оценку готовности объекта к локализации и ликвидации чрезвычайных ситуаций",
        ],
        correctAnswers: [
          "Мероприятия по контролю герметичности объекта хранения в процессе строительства и эксплуатации ОПО ПХГ",
        ],
      },
      {
        code: "20210006",
        text: "Что является основным документом на производство буровых работ на ОПО ПХГ?",
        answers: [
          "Сметная документация",
          "Рабочий проект производства буровых работ",
          "Технологическая документация",
        ],
        correctAnswers: ["Рабочий проект производства буровых работ"],
      },
      {
        code: "20210007",
        text: "Что следует обеспечивать при вскрытии пласта-коллектора объекта эксплуатации?",
        answers: [
          "Непрерывное воздействие на фильтрационные свойства пласта-коллектора",
          "Максимальное воздействие на емкостные свойства пласта-коллектора",
          "Нет правильных ответов",
          "Минимальное воздействие на фильтрационно-емкостные свойства пласта-коллектора",
        ],
        correctAnswers: [
          "Минимальное воздействие на фильтрационно-емкостные свойства пласта-коллектора",
        ],
      },
      {
        code: "20210008",
        text: "Куда вносятся сведения о результатах периодических диагностических обследований, проведенных ремонтах, техническом перевооружении, реконструкциях трубопроводов и скважин?",
        answers: [
          "В эксплуатационные формуляры трубопровода и дела (паспорта скважин)",
          "В эксплуатационный журнал",
          "В технологические карты",
          "В ремонтный журнал",
        ],
        correctAnswers: [
          "В эксплуатационные формуляры трубопровода и дела (паспорта скважин)",
        ],
      },
      {
        code: "20210009",
        text: "Кем должен осуществляться контроль за ходом производства буровых работ, качеством выполнения указанных работ, технологических процессов и операций, качеством используемых материалов и технических средств, соблюдением безопасных условий труда? Укажите все правильные ответы.",
        answers: [
          "Организацией, осуществляющей производство буровых работ",
          "Любым субъектом хозяйственной деятельности",
          "Росприроднадзором",
          "Не регламентируется",
          "Пользователем недр",
        ],
        correctAnswers: [
          "Организацией, осуществляющей производство буровых работ",
          "Пользователем недр",
        ],
      },
      {
        code: "20210010",
        text: "На каком минимальном расстоянии от уровня земной поверхности (шахты) должен быть расположен низ колонной головки для обеспечения свободного доступа к замерным узлам межколонных пространств после окончания бурения, реконструкции или ремонта скважин, связанного с переоборудованием устья?",
        answers: ["400 мм", "300 мм", "500 мм", "200 мм"],
        correctAnswers: ["300 мм"],
      },
      {
        code: "20210011",
        text: "Какое устанавливается максимальное отличие по длине ствола между наиболее и наименее протяженной скважиной?",
        answers: ["1500 м", "400 м", "2000 м", "2500"],
        correctAnswers: ["2000 м"],
      },
      {
        code: "20210012",
        text: "Кого вправе привлекать эксплуатирующая организация в процессе эксплуатации ОПО к авторскому надзору за эксплуатацией ОПО?",
        answers: [
          "Научно-исследовательскую организацию проблем промышленной безопасности",
          "Проектную организацию",
          "Все ответы неверны",
          "Автора технологического проекта",
        ],
        correctAnswers: ["Автора технологического проекта"],
      },
      {
        code: "20210013",
        text: "С какой периодичностью обратная арматура технологической обвязки компрессорных станций подлежит вскрытию и внутреннему осмотру в соответствии с проектной документацией/документацией, документацией изготовителя?",
        answers: [
          "Не реже одного раза в три года",
          "Не реже одного раза в год",
          "Не реже одного раза в квартал",
          "Не реже одного раза в полгода",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20210014",
        text: "Какая устанавливается периодичность проверок состояния скважин, законсервированных в процессе эксплуатации, если в них не установлены цементные мосты?",
        answers: [
          "Все ответы неверны",
          "Не реже одного раза в 5 лет",
          "Не реже одного раза в 3 года",
          "Любая на основании согласования с противофонтанной службой",
          "Не реже одного раза в 2 года",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20210015",
        text: "С какой периодичностью осуществляется учет, контроль за состоянием устьев скважин, ликвидированных в процессе эксплуатации?",
        answers: [
          "Все ответы неверны",
          "Не реже одного раза в три года",
          "Не реже одного раза в год",
          "Не реже одного раза в два года",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20210016",
        text: "С какой периодичностью осуществляется учет, контроль за состоянием устьев скважин, ликвидированных после окончания бурения?",
        answers: [
          "Не реже одного раза в два года",
          "Не реже одного раза в три года",
          "Не реже одного раза в год",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не реже одного раза в два года"],
      },
      {
        code: "20210017",
        text: "Что предусматривается при вскрытии слабосцементированного пласта-коллектора объекта эксплуатации?",
        answers: [
          "Мероприятия по укреплению призабойной зоны скважины",
          "Режим работы пескосмесительных агрегатов",
          "Проведение исследовательских работ",
          "Замеры относительной плотности газа",
        ],
        correctAnswers: ["Мероприятия по укреплению призабойной зоны скважины"],
      },
      {
        code: "20210018",
        text: "Какая информация должна быть указана в перечне газоопасных работ? Выберите два правильных варианта ответа.",
        answers: [
          "Список лиц, ответственных за подготовку и проведение газоопасных работ подрядной организации",
          "Список лиц, ответственных за безопасное проведение газоопасных работ  эксплуатирующей организации",
          "Структурное подразделение организации (производство, цех, отделение, установка, участок)",
          "Категория исполнителей (персонал эксплуатирующей организации, газоспасательной службы, профессиональной аварийно-спасательной службы), выполняющих указанные работы",
        ],
        correctAnswers: [
          "Структурное подразделение организации (производство, цех, отделение, установка, участок)",
          "Категория исполнителей (персонал эксплуатирующей организации, газоспасательной службы, профессиональной аварийно-спасательной службы), выполняющих указанные работы",
        ],
      },
      {
        code: "20210019",
        text: "Что проводится после спуска и цементирования каждой обсадной колонны?",
        answers: [
          "Контроль состояния обсадных труб, цементного камня и положения элементов оснастки",
          "Замер относительной влажности газа",
          "Замер плотности газа",
          "Замер давления газа",
        ],
        correctAnswers: [
          "Контроль состояния обсадных труб, цементного камня и положения элементов оснастки",
        ],
      },
      {
        code: "20210020",
        text: "В какие документы вносятся сведения о проведенных ремонтах, освидетельствованиях, диагностических обследованиях?",
        answers: [
          "Не регламентировано",
          "Нет правильного ответа",
          "В технические паспорта (эксплуатационные формуляры) технических устройств",
        ],
        correctAnswers: [
          "В технические паспорта (эксплуатационные формуляры) технических устройств",
        ],
      },
      {
        code: "20210021",
        text: "В каких документах указывается срок безопасной эксплуатации скважин?",
        answers: [
          "В проектной документации",
          "В документации, разработанной автором технологического проекта",
          "В заключении экспертизы промышленной безопасности",
          "Во всех перечисленных",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "20210022",
        text: "Какой документ разрабатывается на бурение отдельной скважины (индивидуальный)?",
        answers: [
          "Нет правильного ответа",
          "Технологическая документация",
          "Рабочий проект",
        ],
        correctAnswers: ["Рабочий проект"],
      },
      {
        code: "20210023",
        text: "Какой документ разрабатывается на бурение группы скважин, расположенных на одной кустовой площадке или одном ПХГ, площади (групповой)?",
        answers: [
          "Нет правильного ответа",
          "Технологическая документация",
          "Рабочий проект",
        ],
        correctAnswers: ["Рабочий проект"],
      },
      {
        code: "20210024",
        text: "Каким документом оформляется повторное использование рабочего проекта?",
        answers: [
          "Протоколом комиссии, создаваемой пользователем недр (заказчиком), согласованным с проектной организацией",
          "Технологическим проектом, разработанным специализированной организацией",
          "Повторное использование проекта не допускается",
        ],
        correctAnswers: [
          "Протоколом комиссии, создаваемой пользователем недр (заказчиком), согласованным с проектной организацией",
        ],
      },
      {
        code: "20210025",
        text: "Что должно учитываться в рабочем проекте?",
        answers: [
          "Горно-геологические условия бурения скважин на ближайших площадях с аналогичными условиями",
          "Способ бурения скважин на ближайших площадях с аналогичными условиями",
          "Опыт буровых работ на скважинах данной и ближайших площадей с аналогичными условиями",
        ],
        correctAnswers: [
          "Опыт буровых работ на скважинах данной и ближайших площадей с аналогичными условиями",
        ],
      },
      {
        code: "20210026",
        text: "Какое условие не является обязательным для установления режима эксплуатации ПХГ?",
        answers: [
          "Предупреждение преждевременного износа скважинного оборудования вследствие наличия в продукции скважин механических примесей и коррозионно-активных компонентов",
          "Сохранение фильтрационно-емкостных свойств и производительности объекта хранения",
          "Установление автоматического газоанализатора на наземном оборудовании ПХГ",
          "Предупреждение нарушения герметичности объекта хранения",
        ],
        correctAnswers: [
          "Установление автоматического газоанализатора на наземном оборудовании ПХГ",
        ],
      },
      {
        code: "20210027",
        text: "В каких из перечисленных случаев должны проводиться газоопасные работы? Выберите два правильных варианта ответов.",
        answers: [
          "Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри емкости проводятся без наблюдающего",
          "Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть автоматизированы",
          "Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть механизированы",
        ],
        correctAnswers: [
          "Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть автоматизированы",
          "Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть механизированы",
        ],
      },
      {
        code: "20210028",
        text: "С какой периодичностью должны производиться замеры суммарных объемов количества закачиваемого и отбираемого газа про эксплуатации ПХГ?",
        answers: [
          "Ежесменно, после окончания работы",
          "Ежесуточно",
          "Не регламентируется",
          "Раз в 12 часов",
        ],
        correctAnswers: ["Ежесуточно"],
      },
      {
        code: "20210029",
        text: "Что не включает в себя контроль технического состояния при эксплуатации скважин?",
        answers: [
          "Диагностику целостности и износа обсадных колонн и насосно-компрессорных труб геофизическими методами",
          "Контроль заколонных перетоков и техногенных скоплений газа геофизическими методами",
          "Осмотр задвижек и обвязки устья",
          "Диагностирование технического состояния фонтанных арматур и колонных головок",
          "Замер межколонных давлений",
        ],
        correctAnswers: ["Осмотр задвижек и обвязки устья"],
      },
      {
        code: "20210030",
        text: "Какой мониторинг должен осуществляться на ОПО ПХГ?",
        answers: [
          "Мониторинг контрольных горизонтов",
          "Мониторинг критически важных объектов",
          "Объектный мониторинг",
          "Мониторинг опасных объектов",
        ],
        correctAnswers: ["Объектный мониторинг"],
      },
      {
        code: "20210031",
        text: "Кем проводятся все работы при необходимости повторной ликвидации скважин?",
        answers: [
          "Все ответы неверны",
          "Пользователем недр или уполномоченным им представителем",
          "Организацией, осуществляющей проведение экспертизы промышленной безопасности при участии противофонтанной службы",
          "Организацией, осуществляющей проведение разведочных работ",
        ],
        correctAnswers: [
          "Пользователем недр или уполномоченным им представителем",
        ],
      },
      {
        code: "20210032",
        text: 'В каком случае допускается эксплуатация труб и трубопроводной арматуры в положениях между "открыто" и "закрыто"?',
        answers: [
          "По решению лица, ответственного за производственный контроль",
          "Если такая возможность предусмотрена изготовителем",
          "Если такая необходимость утверждена локальным документом организации",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Если такая возможность предусмотрена изготовителем"],
      },
      {
        code: "20210033",
        text: "Какое из перечисленных действий допускается при эксплуатации трубопроводов и ТПА?",
        answers: [
          "Производить работы по устранению дефектов, подтяжку уплотнения, резьбовых соединений трубной обвязки и фитингов, находящихся под давлением",
          "Использовать ТПА в качестве опор",
          "Дросселировать газ при частично открытом затворе запорной арматуры, установленной на обводных и выпускных газопроводах, если это не предусмотрено изготовителем",
          "Соединять сброс газа из предохранительной арматуры разных потребителей на одну свечу рассеивания",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20210034",
        text: "При каких признаках предельных состояний допускается эксплуатация скважин с межколонным давлением? Укажите все правильные ответы.",
        answers: [
          "Расход межколонного флюида из межколонного пространства при установившемся режиме стравливания более 1000 м³/сут для газовой фазы или 1 м³/сут для жидкой фазы",
          "Присутствие в составе межколонного флюида диоксида углерода при парциальном давлении, равном или превышающем 0,2 МПа",
          "Превышающее значение 70% от давления гидроразрыва пласта на уровне башмака внешней колонны данного межколонного пространства",
          "Присутствие сероводорода в межколонных пространствах между промежуточными колоннами или между промежуточной колонной и кондуктором на месторождениях с содержанием сероводорода в добываемой продукции больше 4% объема",
        ],
        correctAnswers: [
          "Превышающее значение 70% от давления гидроразрыва пласта на уровне башмака внешней колонны данного межколонного пространства",
          "Присутствие сероводорода в межколонных пространствах между промежуточными колоннами или между промежуточной колонной и кондуктором на месторождениях с содержанием сероводорода в добываемой продукции больше 4% объема",
        ],
      },
      {
        code: "20210035",
        text: "Какую информацию не должен содержать знак, установленный при надземной прокладке трубопроводов?",
        answers: [
          "Размер охранной зоны",
          "Дату установки",
          "Местоположение оси трубопровода",
          "Номер телефона эксплуатирующей организации",
        ],
        correctAnswers: ["Дату установки"],
      },
      {
        code: "20210036",
        text: "На каком этапе может производится консервация скважин?",
        answers: [
          "Все ответы неверны",
          "В процессе бурения",
          "На всех перечисленных этапах",
          "После окончания бурения",
          "В процессе эксплуатации",
        ],
        correctAnswers: ["На всех перечисленных этапах"],
      },
      {
        code: "20210037",
        text: "В каком случае может осуществляться временная приостановка скважин без консервации?",
        answers: [
          "В случае согласования приостановки скважин с проектной организацией",
          "Если срок приостановки не превышает 12 месяцев",
          "В случае согласования приостановки скважин с противофонтанной службой",
          "Не может осуществляться ни в каком случае",
        ],
        correctAnswers: ["Если срок приостановки не превышает 12 месяцев"],
      },
      {
        code: "20210038",
        text: "В каком случае пользователь недр не обязан разрабатывать и реализовывать дополнительные меры безопасности, исключающие риск аварий, или ликвидировать законсервированную скважину?",
        answers: [
          "По результатам экспертизы промышленной безопасности",
          "По результатам государственного экологического контроля",
          "По требованию противофонтанной службы",
          "По результатам производственного контроля",
        ],
        correctAnswers: ["По требованию противофонтанной службы"],
      },
      {
        code: "20210039",
        text: "Какое из перечисленных требований при консервации скважин указано верно? Укажите все правильные ответы.",
        answers: [
          "Продление срока консервации скважин устанавливается территориальным органом Росприроднадзора",
          "Срок консервации скважин после эксплуатации без установки консервационного моста над интервалом перфорации составляет 5 лет",
          "Срок консервации скважин после эксплуатации с установкой консервационного моста над интервалом перфорации составляет 7 лет",
          "Срок нахождения в бездействии скважины перед консервацией не учитывается",
        ],
        correctAnswers: [
          "Срок консервации скважин после эксплуатации без установки консервационного моста над интервалом перфорации составляет 5 лет",
          "Срок нахождения в бездействии скважины перед консервацией не учитывается",
        ],
      },
      {
        code: "20210040",
        text: "Кто проводит анализ опасностей технологических процессов с определением отклонений технологических параметров от проектных (регламентных) значений с анализом причин, последствий этих отклонений и мер безопасности в целях идентификации опасностей, обоснования технических мер предупреждения аварий и инцидентов?",
        answers: [
          "Только специалисты строительных организаций",
          "Группа специалистов, в состав которой входят представители проектных, строительных, эксплуатирующих организаций",
          "Только специалисты эксплуатирующих организаций",
          "Только специалисты проектных организаций",
        ],
        correctAnswers: [
          "Группа специалистов, в состав которой входят представители проектных, строительных, эксплуатирующих организаций",
        ],
      },
      {
        code: "20210041",
        text: "Какие устанавливаются максимальные сроки проведения ревизии трубопроводов ОПО?",
        answers: ["8 лет", "3 года", "5 лет", "Все ответы неверны"],
        correctAnswers: ["8 лет"],
      },
      {
        code: "20210042",
        text: "В течение какого периода времени должна быть проведена первичная ревизия вновь построенного трубопровода ОПО?",
        answers: [
          "В течение первых восемнадцати месяцев эксплуатации трубопровода",
          "В течение первых двух лет эксплуатации трубопровода",
          "В течение первого месяца эксплуатации трубопровода",
          "В течение первого года эксплуатации трубопровода",
        ],
        correctAnswers: ["В течение первого года эксплуатации трубопровода"],
      },
      {
        code: "20210043",
        text: "Что из перечисленного не является объектом мониторинга при эксплуатации ОПО ПХГ?",
        answers: [
          "Объект хранения газа",
          "Эксплуатационные, специальные скважины в пределах горного отвода ОПО ПХГ",
          "Технологическое оборудование наземного комплекса ОПО ПХГ",
          "Контрольные горизонты",
        ],
        correctAnswers: [
          "Технологическое оборудование наземного комплекса ОПО ПХГ",
        ],
      },
      {
        code: "20210044",
        text: "Какие из перечисленных параметров контролируются в рамках объектного мониторинга ОПО ПХГ?",
        answers: [
          "Только давление, температура в технологической линии (скважина - газосборный пункт (компрессорная станция) - газопровод подключения), межколонное давление и межколонный расход газа по скважинам, поверхностные газопроявления на хранилище",
          "Только общий объем газа, активный объем газа (в том числе долгосрочный резерв), буферный объем газа, объем закачки (отбора) газа, а также объем пластовой жидкости, добываемой при отборе газа",
          "Все перечисленные параметры, включая содержание растворенного газа, химический состав, давление насыщения растворенного газа в пластовой воде объекта хранения и контрольных горизонтов",
          "Только затраты газа на собственные технические (технологические) нужды, суточная производительность эксплуатационных скважин (для вновь проектируемых ОПО ПХГ) и ОПО ПХГ в целом, а также газоводяной контакт",
          "Только газонасыщенный поровый объем хранилища, компонентный состав газа, точка росы, соответствие качества подготовленного к транспорту газа установленным требованиям, давление в объекте хранения, уровни и давление в контрольных горизонтах, а также газонасыщенность объекта хранения и контрольных горизонтов",
        ],
        correctAnswers: [
          "Все перечисленные параметры, включая содержание растворенного газа, химический состав, давление насыщения растворенного газа в пластовой воде объекта хранения и контрольных горизонтов",
        ],
      },
      {
        code: "20210045",
        text: "Как проводится контроль за расходом закачиваемого (отбираемого) газа при наличии нескольких газосборных пунктов?",
        answers: [
          "Контроль проводится на любом из пунктов (по выбору эксплуатирующей организации)",
          "Контроль проводится на каждом пункте",
          "Контроль проводится только на самом дальнем пункте",
          "Нет правильного ответа",
        ],
        correctAnswers: ["Контроль проводится на каждом пункте"],
      },
      {
        code: "20210046",
        text: "Что из перечисленного включает в себя баланс газа в ПХГ?",
        answers: [
          "Только оценку затрат газа на собственные технические (технологические) нужды",
          "Только расчет объема закачанного (отобранного) газа за сутки, месяц, сезон с учетом собственных технических нужд",
          "Только расчет текущего буферного, активного и общего объемов газа ПХГ",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20210047",
        text: "Как ведется баланс газа, если на ОПО ПХГ эксплуатируется несколько объектов хранения?",
        answers: [
          "Только в целом по ОПО ПХГ, без оценки затрат газа на собственные нужды",
          "Только отдельно по каждому объекту хранения без расчета текущего буферного объема газа",
          "Как в целом по ОПО ПХГ, так и по каждому объекту хранения отдельно",
          "Нет правильного ответа",
        ],
        correctAnswers: [
          "Как в целом по ОПО ПХГ, так и по каждому объекту хранения отдельно",
        ],
      },
      {
        code: "20210048",
        text: "Как осуществляется учет и замер количества извлекаемой пластовой жидкости при эксплуатации ОПО ПХГ?",
        answers: [
          "Только в целом по ОПО ПХГ без учета объема промстоков",
          "Только по каждому объекту хранения газа без учета его химического состава",
          "Как в целом по ОПО ПХГ, так и по каждому объекту хранения газа",
          "Не регламентируется",
        ],
        correctAnswers: [
          "Как в целом по ОПО ПХГ, так и по каждому объекту хранения газа",
        ],
      },
      {
        code: "20210049",
        text: "Как осуществляется контроль за динамикой давлений в объекте хранения и контрольных горизонтах при эксплуатации ОПО ПХГ?",
        answers: [
          "Путем замера пластового давления, уровней пластовой воды в контрольных скважинах",
          "Путем замера пластового давления, уровней пластовой воды во всех скважинах",
          "Путем замера только пластового давления во всех скважинах",
          "Нет правильного ответа",
        ],
        correctAnswers: [
          "Путем замера пластового давления, уровней пластовой воды в контрольных скважинах",
        ],
      },
      {
        code: "20210050",
        text: "Как осуществляется контроль за динамикой давлений, если в составе ОПО ПХГ эксплуатируется несколько контрольных пластов?",
        answers: [
          "По дальнему пласту",
          "По каждому пласту отдельно",
          "По одному контрольному пласту",
          "Нет правильного ответа",
        ],
        correctAnswers: ["По каждому пласту отдельно"],
      },
      {
        code: "20210051",
        text: "Какой технологический параметр не контролируется при закачке промышленных стоков на ОПО ПХГ?",
        answers: [
          "Давление нагнетания",
          "Объем промстоков",
          "Содержание БПК и ХПК",
          "Химический состав",
        ],
        correctAnswers: ["Содержание БПК и ХПК"],
      },
      {
        code: "20210052",
        text: "Где следует устанавливать башмак обсадной колонны, перекрывающий породы, склонные к текучести?",
        answers: [
          "Ниже их подошвы",
          "Нет правильного ответа",
          "Выше их подошвы",
          "Ниже их подошвы или в плотных пропластках",
          "Не регламентируется",
        ],
        correctAnswers: ["Ниже их подошвы или в плотных пропластках"],
      },
      {
        code: "20210053",
        text: "Что не производится на этапе создания ПХГ?",
        answers: [
          "Обеспечение возможности выхода хранилища на максимальные показатели",
          "Уточнение и совершенствование геологической и технологической моделей эксплуатации",
          "Развитие и дополнение базы данных текущими данными эксплуатации",
          "Оценка возможности выхода хранилища на проектные показатели и обеспечение его безопасной циклической эксплуатации",
        ],
        correctAnswers: [
          "Обеспечение возможности выхода хранилища на максимальные показатели",
        ],
      },
      {
        code: "20210054",
        text: "В соответствии с каким документом осуществляется эксплуатация ПХГ?",
        answers: [
          "В соответствии с анализом эксплуатации ПХГ",
          "В соответствии с дополнением в технологический проект ПХГ",
          "В соответствии с технологическим проектом",
        ],
        correctAnswers: ["В соответствии с технологическим проектом"],
      },
      {
        code: "20210055",
        text: "Допускается ли при эксплуатации ПХГ для сглаживания пиковых нагрузок кратковременное превышение до 20 % фактического суточного отбора газа над отбором, предусмотренным технологическим проектом?",
        answers: [
          "Допускается при согласовании режима с территориальным органом Росприроднадзора",
          "Допускается при согласовании режима с организацией, ведущей авторский надзор",
          "Не регламентируется",
        ],
        correctAnswers: [
          "Допускается при согласовании режима с организацией, ведущей авторский надзор",
        ],
      },
      {
        code: "20210056",
        text: "На сколько должна превышать температура вспышки раствора на углеводородной основе максимально ожидаемую температуру раствора на устье скважины?",
        answers: ["На 40 °C", "На 50 °C", "На 45 °C"],
        correctAnswers: ["На 50 °C"],
      },
      {
        code: "20210057",
        text: "Что должно обеспечиваться в процессе цементирования?",
        answers: [
          "Максимально возможная репрессия на продуктивные горизонты",
          "Непрерывная приборная регистрация параметров, характеризующих этот процесс",
          "Ускоренный режим спуска обсадных колонн",
        ],
        correctAnswers: [
          "Непрерывная приборная регистрация параметров, характеризующих этот процесс",
        ],
      },
      {
        code: "20210058",
        text: "Кем разрабатывается технический проект на производство перфорационных работ?",
        answers: [
          "Организацией-пользователем недр",
          "Буровой организацией",
          "Геофизической организацией",
        ],
        correctAnswers: ["Геофизической организацией"],
      },
      {
        code: "20210059",
        text: "С какой частотой устанавливаются столбики со щитами-указателями?",
        answers: [
          "Не реже чем через 1500 м",
          "Не реже чем через 500 м",
          "Не реже чем через 800 м",
          "Не реже чем через 1000 м",
        ],
        correctAnswers: ["Не реже чем через 1000 м"],
      },
      {
        code: "20210060",
        text: "Где устанавливаются опознавательно-предупредительные знаки при надземной прокладке трубопроводов?",
        answers: [
          "Непосредственно на трубопроводе в доступном и видном месте",
          "Не регламентируется",
          "На опорах трубопровода",
        ],
        correctAnswers: ["На опорах трубопровода"],
      },
      {
        code: "20210061",
        text: "С какой периодичностью производится осмотр трассы, охранной зоны трубопроводов и участков трубопроводов надземного исполнения?",
        answers: [
          "Не реже одного раз в месяц",
          "Не реже двух раз в месяц",
          "Не реже двух раз в квартал",
        ],
        correctAnswers: ["Не реже двух раз в месяц"],
      },
      {
        code: "20210062",
        text: "В каком документе фиксируются результаты осмотров трассы, охранной зоны трубопроводов и участков трубопроводов надземного исполнения?",
        answers: [
          "В специальном журнале в произвольной форме",
          "В журнале наружного осмотра трубопровода",
          "В паспорте трубопровода",
        ],
        correctAnswers: ["В журнале наружного осмотра трубопровода"],
      },
      {
        code: "20210063",
        text: "С какой периодичностью проводится проверка отсутствия электрического контакта между участком трубопровода и защитным кожухом?",
        answers: [
          "Не реже одного раза в год",
          "Не реже одного раза в квартал",
          "Не реже одного раза в месяц",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20210064",
        text: "В соответствии с какими документами наносятся обозначения на производственные объекты ОПО ПХГ?",
        answers: [
          "В соответствии с технологическими схемами",
          "В соответствии с техническими проектами",
          "В соответствии с рабочими регламентами",
        ],
        correctAnswers: ["В соответствии с технологическими схемами"],
      },
      {
        code: "20210065",
        text: "Что из перечисленного является основным технологическим документом и определяет технологию ведения процесса или его отдельных стадий?",
        answers: [
          "Технологический регламент",
          "Техническая карта",
          "Нет правильного ответа",
        ],
        correctAnswers: ["Технологический регламент"],
      },
      {
        code: "20210066",
        text: "Каков срок действия регламента для ОПО ПХГ?",
        answers: ["5 лет", "3 года", "7 лет"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20210067",
        text: "Что должно обеспечиваться на всех участках трубопровода?",
        answers: [
          "Возможность подъезда к трубопроводу для выполнения только аварийных работ",
          "Возможность подхода к любой точке трубопровода для выполнения только профилактических и ремонтных работ",
          "Возможность подъезда к любой точке трубопровода для выполнения профилактических, ремонтных и аварийных работ",
        ],
        correctAnswers: [
          "Возможность подъезда к любой точке трубопровода для выполнения профилактических, ремонтных и аварийных работ",
        ],
      },
      {
        code: "20210068",
        text: "Контроль каких параметров должен осуществляться при размыве резервуаров?",
        answers: [
          "Давления и температуры в линиях закачки и выхода рассола",
          "Плотности и химического состава рассола в линиях закачки и выхода (последнее - для бесшахтных резервуаров в каменной соли)",
          "Уровня границы раздела фаз в выработке-емкости",
          "Формы и размеров выработки-емкости",
          "Всех вышеперечисленных",
        ],
        correctAnswers: ["Всех вышеперечисленных"],
      },
      {
        code: "20210069",
        text: "Допускается ли повторное использование внешней подвесной колонны при пакерном способе эксплуатации после ее извлечения?",
        answers: [
          "Допускается если это  предусмотрено проектной документацией",
          "Не допускается, кроме случаев, предусмотренных проектной документацией",
          "Не допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "20210070",
        text: "Какой коэффициент запаса используется при проведении испытаний на герметичность подвесных колонн?",
        answers: ["1,1", "1,15", "1,05"],
        correctAnswers: ["1,05"],
      },
      {
        code: "20210071",
        text: "Какой коэффициент запаса используется при проведении испытаний на герметичность резервуара?",
        answers: ["1,1", "1,15", "1,05"],
        correctAnswers: ["1,05"],
      },
      {
        code: "20210072",
        text: "Что должно быть предусмотрено при эксплуатации подземных резервуаров по рассольной схеме в случаях невозможности утилизации или реализации рассола?",
        answers: [
          "Обвалования",
          "Дополнительные резервуары",
          "Рассолохранилище",
        ],
        correctAnswers: ["Рассолохранилище"],
      },
      {
        code: "20210073",
        text: "Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года",
          "Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
      {
        code: "20210074",
        text: "Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?",
        answers: [
          "Руководитель структурного подразделения не более чем на 1 дневную рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск на 1 дневную рабочую смену",
          "Руководитель структурного подразделения на требуемый для окончания работ срок",
          "Продление наряда-допуска не предусмотрено",
        ],
        correctAnswers: [
          "Руководитель структурного подразделения не более чем на 1 дневную рабочую смену",
        ],
      },
      {
        code: "20210075",
        text: "Что должна обеспечивать конструкция устья скважины, колонных головок, герметизирующих устройств?",
        answers: [
          "Испытание обсадных колонн и межколонных пространств на герметичность",
          "Возможность аварийного глушения скважины",
          "Все вышеперечисленное",
          "Контроль за возможными флюидопроявлениями за обсадными колоннами",
          "Подвеску с расчетным натяжением промежуточных и эксплуатационных колонн с учетом компенсации температурных деформаций на всех стадиях работы скважины (колонны), а также подвеску колонны бурильных труб на противовыбросовом оборудовании, контроль за возможными флюидопроявлениями за обсадными колоннами",
        ],
        correctAnswers: ["Все вышеперечисленное"],
      },
      {
        code: "20210076",
        text: "В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?",
        answers: [
          "Не менее 3 месяцев со дня закрытия наряда допуска",
          "Не менее 6 месяцев со дня закрытия наряда допуска",
          "Не менее 1 года со дня закрытия наряда допуска",
        ],
        correctAnswers: ["Не менее 6 месяцев со дня закрытия наряда допуска"],
      },
      {
        code: "20210077",
        text: "Каким документом определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?",
        answers: [
          "Организационно-распорядительными документами руководителя",
          "Технологическим регламентом о требованиях пожарной безопасности",
          "Федеральными нормами и правилами в области промышленной безопасности",
          "Правилами противопожарного режима",
        ],
        correctAnswers: [
          "Организационно-распорядительными документами руководителя",
        ],
      },
      {
        code: "20210078",
        text: "К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?",
        answers: [
          "К группе работ средней степени опасности",
          "К I группе",
          "К II группе",
          "К группе работ высокой степени опасности",
        ],
        correctAnswers: ["К II группе"],
      },
      {
        code: "20210079",
        text: "В какое состояние должны быть приведены объекты хранения, здания и сооружения ОПО ПХГ при консервации и ликвидации?",
        answers: [
          "В состояние, обеспечивающее безопасность населения и охрану окружающей среды",
          "Нет правильного ответа",
          "В состояние, обеспечивающее только безопасность окружающей среды",
          "В состояние, обеспечивающее только безопасность населения",
        ],
        correctAnswers: [
          "В состояние, обеспечивающее безопасность населения и охрану окружающей среды",
        ],
      },
      {
        code: "20210080",
        text: "Какой может быть документация на ликвидацию и консервацию скважин?",
        answers: [
          "Зональной и групповой",
          "Индивидуальной и групповой",
          "Индивидуальной, групповой и зональной",
        ],
        correctAnswers: ["Индивидуальной, групповой и зональной"],
      },
      {
        code: "20210081",
        text: "Какие разделы включаются в состав документации на консервацию и ликвидацию ОПО ПХГ?",
        answers: [
          "Только мероприятия по максимально возможному отбору газа из объекта хранения с обеспечением требований безопасности населения, охраны недр и окружающей среды",
          "Только общая пояснительная записка, включающая обоснование критериев и варианта ликвидации скважин, вариант ликвидации (в зависимости от этапа бурения или эксплуатации скважин)",
          "Только мероприятия по охране окружающей среды, мероприятия по рекультивации нарушенных земель, мероприятия по охране зданий и сооружений, мероприятия по безопасности жизни и здоровья населения, мероприятия по охране недр",
          "Только решения о целесообразности использования подземных резервуаров для иных целей, способы консервации (ликвидации) ОПО ПХГ и его частей, порядок и график проведения работ",
          "Все перечисленные, включая мероприятия по предотвращению загрязнения питьевых водоносных горизонтов",
        ],
        correctAnswers: [
          "Все перечисленные, включая мероприятия по предотвращению загрязнения питьевых водоносных горизонтов",
        ],
      },
      {
        code: "20210082",
        text: "Какие исследования не проводятся при консервации и ликвидации ОПО ПХГ в процессе контроля за состоянием объекта хранения и контрольных горизонтов?",
        answers: [
          "Промысловые",
          "Геофизические",
          "Гидрохимические",
          "Аналитические",
        ],
        correctAnswers: ["Аналитические"],
      },
      {
        code: "20210083",
        text: "Что должно быть обеспечено при консервации ОПО ПХГ?",
        answers: [
          "Контроль за герметичностью только объекта хранения на протяжении всего периода консервации",
          "Контроль за герметичностью только скважин и их устьев на протяжении 7 лет",
          "Контроль за герметичностью объекта хранения, скважин и их устьев на протяжении всего периода консервации",
          "Контроль за герметичностью объекта хранения, скважин и их устьев на протяжении 5 лет",
        ],
        correctAnswers: [
          "Контроль за герметичностью объекта хранения, скважин и их устьев на протяжении всего периода консервации",
        ],
      },
      {
        code: "20210084",
        text: "Где хранятся все материалы по ликвидированной скважине, включая подписанный сторонами акт о ликвидации?",
        answers: [
          "В эксплуатирующей организации",
          "В территориальном органе Росприроднадзора",
          "У пользователя недр",
        ],
        correctAnswers: ["У пользователя недр"],
      },
      {
        code: "20210085",
        text: "Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?",
        answers: [
          "Все перечисленные требования",
          "Не моложе 18 лет",
          "Прошедшие обучение приемам и методам проведения работ",
          "Прошедшие медицинский осмотр в соответствии с требованиями законодательством Российской Федерации",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20210086",
        text: "Когда могут проводиться газоопасные работы, выполняемые по наряду-допуску?",
        answers: [
          "В дневное время суток с участием или в присутствии лица, ответственного за выполнение газоопасных работ",
          "Нет правильного ответа",
          "Только в темное время суток с соблюдением мероприятий по обеспечению безопасного проведения работ",
          "В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск",
        ],
        correctAnswers: [
          "В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск",
        ],
      },
      {
        code: "20210087",
        text: "Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?",
        answers: [
          "Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы, и приемы ведения газоопасных работ",
          "Любой из специалистов эксплуатирующей организации",
          "Только специалист, обученный пожарно-техническому минимуму",
          "Нет правильного ответа",
        ],
        correctAnswers: [
          "Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы, и приемы ведения газоопасных работ",
        ],
      },
      {
        code: "20210088",
        text: "Кем согласовывается акт консервации скважин подземных хранилищ газа в процессе бурения?",
        answers: [
          "Пользователем недр и противофонтанной службой (противофонтанной военизированной частью)",
          "Пользователем недр и эксплуатирующей организацией",
          "Пользователем недр и Росприроднадзором",
          "Нет правильного ответа",
        ],
        correctAnswers: [
          "Пользователем недр и противофонтанной службой (противофонтанной военизированной частью)",
        ],
      },
      {
        code: "20210089",
        text: "С какой периодичностью необходимо проводить замер давления и расхода газа между обсадными колоннами при максимальном (приближенном к максимальному) давлении в подземном хранилище газа по скважинам, на которых проектом предусмотрена обвязка обсадных колонн и наличие замерных устройств?",
        answers: [
          "Не реже одного раза в месяц",
          "Все ответы неверны",
          "Не реже одного раза в год",
          "Не реже одного раза в три месяца",
          "Не реже одного раза в полгода",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20210090",
        text: "Какое отклонение глубин спуска обсадных колонн от указанных в рабочем проекте по вертикали допускается при разработке рабочего проекта группы скважин?",
        answers: [
          "Отклонение не допускается",
          "Не более 300 м",
          "Не более 500 м",
          "Все ответы неверны",
          "Не более 400 м",
        ],
        correctAnswers: ["Не более 400 м"],
      },
      {
        code: "20210091",
        text: "Кто осуществляет контроль за состоянием устьев ликвидированных и законсервированных скважин?",
        answers: [
          "Проектная организация",
          "Росприроднадзор",
          "Все ответы неверны",
          "Пользователь недр или уполномоченный им представитель",
        ],
        correctAnswers: [
          "Пользователь недр или уполномоченный им представитель",
        ],
      },
    ],
  },
  80: {
    20301: [
      {
        code: "20301000",
        text: "С какой периодичностью должны проверяться на работоспособность вентиляционные системы и состояние ванн травления?",
        answers: ["Ежедневно", "Еженедельно", "Ежемесячно", "Ежеквартально"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "20301001",
        text: "Какие работы производятся на опасных производственных объектов (далее – ОПО) горно-металлургических производств? Выберите правильный вариант ответа.",
        answers: [
          "Только постоянные работы, выполняемые в соответствии с документированной процедурой (инструкцией, картой, регламентом), принятой в организации",
          "Только работы, связанные с аварийными ситуациями",
          "Только работы повышенной опасности (сложные, разовые, уникальные)",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20301002",
        text: "При наличии каких дефектов ковшей их эксплуатация не допускается?",
        answers: [
          "Только ковши, имеющие качку цапф в теле ковша",
          "Только ковши, потерявшие форму вследствие деформации",
          "Только ковши с поврежденной футеровкой",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20301003",
        text: "Что должно быть исключено в коммуникациях системы транспортирования взрывопожароопасных веществ и материалов?",
        answers: [
          "Образование взрывоопасной среды",
          "Возможность разгерметизации системы",
          "Попадание воздуха",
        ],
        correctAnswers: ["Образование взрывоопасной среды"],
      },
      {
        code: "20301004",
        text: "Какие требования установлены к процессу сливания расплава металла из ковшей и миксеров?",
        answers: [
          "Сливание расплава металла из ковшей и миксеров должно быть механизировано",
          "Пол рабочей площадки в местах установки ковшей должен быть сухим",
          "Ось сливного отверстия ковша, установленного под слив, должна совпадать с осью заливочного ковша",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20301005",
        text: "Что должны иметь закрома и ямы?",
        answers: [
          "Пешеходные проходы шириной 1,0 м по всему периметру",
          "Пешеходные проходы шириной 0,7 м по всему периметру",
          "Со всех сторон должны иметь ограждение",
        ],
        correctAnswers: ["Со всех сторон должны иметь ограждение"],
      },
      {
        code: "20301006",
        text: "Какие требования установлены к кабине завалочной машины?",
        answers: [
          "Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака",
          "Должна быть удобной в эксплуатации",
          "Конструкция кабины завалочной машины должна быть полностью герметичной",
        ],
        correctAnswers: [
          "Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака",
        ],
      },
      {
        code: "20301007",
        text: "Как должны быть расположены пульты управления агрегатами?",
        answers: [
          "В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке",
          "На расстоянии не менее 7 м от агрегатов",
          "На расстоянии не более 2 м от агрегатов",
        ],
        correctAnswers: [
          "В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке",
        ],
      },
      {
        code: "20301008",
        text: "Какие требования к производственным помещениям, оборудованию участков приготовления травильных растворов и травления металла указаны неверно?",
        answers: [
          "После работы у ванн травления с химически опасными веществами, используемыми в виде добавок для технологических растворов, вещества должны быть удалены из рабочей зоны на склад",
          "Вентиляционные системы, состояние ванн травления ежедневно должны проверяться на работоспособность",
          "Периодичность проведения уборки помещений устанавливается в зависимости от условий их эксплуатации",
          "Допускается уборка, вызывающая распыление вредных веществ",
        ],
        correctAnswers: [
          "Допускается уборка, вызывающая распыление вредных веществ",
        ],
      },
      {
        code: "20301009",
        text: "При каких условиях запрещается эксплуатация плавильных агрегатов?",
        answers: [
          "При разгерметизации системы водяного охлаждения этих агрегатов",
          "При незначительном повышении давления воды в системе водоохлаждения",
          "При незначительном падении давления воды в системе водоохлаждения",
        ],
        correctAnswers: [
          "При разгерметизации системы водяного охлаждения этих агрегатов",
        ],
      },
      {
        code: "20301010",
        text: "Какое из перечисленных требований к применению жетон-бирочной системы указано неверно?",
        answers: [
          "Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями",
          "Жетон-бирочная система применяется при выводе ТУ на продолжительную остановку",
          "Жетон-бирки после разборки электрической схемы ТУ передаются от технологического персонала ремонтному персоналу с отметкой всех этапов в журнале выдачи и приема жетон-бирок",
          "Жетон-бирочная система применяется для предотвращения травм и аварий в результате ошибочного или несанкционированного включения ТУ, а также в целях согласования действий технологического и ремонтного персонала",
        ],
        correctAnswers: [
          "Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями",
        ],
      },
      {
        code: "20301011",
        text: "Что должно быть разработано для обеспечения безопасности при освоении новых производств, технологических процессов и технических устройств?",
        answers: [
          "Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования",
          "Декларация пожарной безопасности",
          "План ликвидации и консервации промышленного объекта",
        ],
        correctAnswers: [
          "Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования",
        ],
      },
      {
        code: "20301012",
        text: "Какие требования установлены к фурмам для продувки жидкого металла газами?",
        answers: [
          "Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями",
          "Должны иметь цилиндрическую форму",
          "Должны храниться перед установкой в специальном помещении не менее 2 суток",
        ],
        correctAnswers: [
          "Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями",
        ],
      },
      {
        code: "20301013",
        text: "Чем должны быть оборудованы места пересечения железнодорожных путей для подачи составов ковшей с жидким чугуном с путями для подачи шихтовых материалов?",
        answers: [
          "Должны быть оборудованы автоматической сигнализацией",
          "Должны быть оборудованы постами с дежурными стрелочного поста",
          "Должны быть оборудованы автоматическими шлагбаумами",
        ],
        correctAnswers: [
          "Должны быть оборудованы автоматической сигнализацией",
        ],
      },
      {
        code: "20301014",
        text: "Какие требования установлены к загружаемым в печи руде, ферросплавам и другим материалам?",
        answers: [
          "Должны быть предварительно просушены или прокалены",
          "Должны быть выдержаны в помещении не менее 3 суток",
          "Должны быть выдержаны в помещении не менее суток",
        ],
        correctAnswers: ["Должны быть предварительно просушены или прокалены"],
      },
      {
        code: "20301015",
        text: "Какие из перечисленных требований к наряду-допуску на работы повышенной опасности указаны неверно? Выберите два правильных ответа.",
        answers: [
          "После окончания работы наряд-допуск остается у производителя (руководителя) работ",
          "Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ",
          "При перерыве в работе в течение одной смены (обеденный перерыв, перерыв по условиям производства работ) наряд-допуск остается у производителя (руководителя) работ",
          "При допуске бригады к работе допускающий к работе вручает первый экземпляр наряда-допуска производителю работ, а второй экземпляр наряда-допуска хранится вместе с нарядами, действующими на текущий момент",
        ],
        correctAnswers: [
          "После окончания работы наряд-допуск остается у производителя (руководителя) работ",
          "Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ",
        ],
      },
      {
        code: "20301016",
        text: "В каких из перечисленных случаев допускается продолжать работы, проводимые по наряду-допуску, на опасных производственных объектах?",
        answers: [
          "Если изменился состав бригады",
          "Если появилась угроза жизни и здоровью работников",
          "Если подан аварийный сигнал",
          "Если характер и объемы работ изменены в такой степени, что требуется изменение схемы отключения технических устройств и порядка выполнения работ",
        ],
        correctAnswers: ["Если изменился состав бригады"],
      },
      {
        code: "20301017",
        text: "Какие из перечисленных требований к акту-допуску подрядных организаций на опасный производственный объект горно-металлургической промышленности указаны неверно?",
        answers: [
          "Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)",
          "На один объект, площадку, территорию должен оформляться один акт-допуск",
          "При организации работ с оформлением акта-допуска на объекты или территорию эксплуатирующей организации должны предусматриваться совместные мероприятия, обеспечивающие безопасность работников и объектов организаций",
          "Акт-допуск должен оформляться письменно",
        ],
        correctAnswers: [
          "Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)",
        ],
      },
      {
        code: "20301018",
        text: "Какие действия предпринимаются при необходимости ведения работ после истечения срока действия акта-допуска подрядных организаций на опасный производственный объект горно-металлургической промышленности?",
        answers: [
          "Составляется новый акт-допуск на следующий срок",
          "Все работы продолжаются без оформления акта-допуска",
          "Продлевается текущий акт-допуск на срок не более 10 рабочих смен",
        ],
        correctAnswers: ["Составляется новый акт-допуск на следующий срок"],
      },
      {
        code: "20301019",
        text: "Кто определяет (назначает) работников, выполняющих мероприятия по безопасности труда, указанных в наряде-допуске (ответственных исполнителей)?",
        answers: [
          "Выдающий наряд-допуск",
          "Производитель (руководитель) работ",
          "Работник, ответственный за осуществление производственного контроля в организации",
          "Технический руководитель организации",
        ],
        correctAnswers: ["Выдающий наряд-допуск"],
      },
      {
        code: "20301020",
        text: "Как должна устраняться пробуксовка ленты конвейера?",
        answers: [
          "Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты",
          "Должна устраняться увеличением трения между тянущим барабаном и лентой с использованием специальных материалов",
          "Должна устраняться с помощью ручной регулировки",
        ],
        correctAnswers: [
          "Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты",
        ],
      },
      {
        code: "20301021",
        text: "На что должна быть проверена система пневмотранспорта перед вводом в эксплуатацию?",
        answers: [
          "На плотность под рабочим давлением",
          "На прочность соединений путем проведения гидравлических испытаний",
          "На отсутствие загрязнений",
        ],
        correctAnswers: ["На плотность под рабочим давлением"],
      },
      {
        code: "20301022",
        text: "Что не допускается к загрузке в агрегаты, содержащие расплавленный металл или шлак?",
        answers: [
          "Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции",
          "Загрузка в печи предварительно просушенной руды, ферросплавов и других материалов",
          "Загрузка шихты, не прошедшей пиротехнического контроля",
        ],
        correctAnswers: [
          "Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции",
        ],
      },
      {
        code: "20301023",
        text: "Что должно быть предусмотрено при проектировании конвейеров для подачи материалов? Выберите правильный вариант ответа.",
        answers: [
          "Только предохраняющие устройства, отключающие приводы при перегрузке",
          "Только ограждения приводных, натяжных и отклоняющих барабанов, натяжных устройств, канатов и блоков натяжных устройств",
          "Только исключение падения материалов во время транспортировки",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20301024",
        text: "В течение какого времени хранится журнал регистрации нарядов-допусков со дня внесения последней записи?",
        answers: [
          "В течение 6 месяцев",
          "В течение месяца",
          "В течение 3 месяцев",
          "В течение года",
        ],
        correctAnswers: ["В течение 6 месяцев"],
      },
      {
        code: "20301025",
        text: "Кем могут проводиться работы повышенной опасности на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "Как работниками эксплуатирующей организации, так и работниками подрядных организаций",
          "Только работниками эксплуатирующей организации",
          "Только работниками подрядных организаций",
        ],
        correctAnswers: [
          "Как работниками эксплуатирующей организации, так и работниками подрядных организаций",
        ],
      },
      {
        code: "20301026",
        text: "В какой зоне не допускается нахождение людей?",
        answers: [
          "В зоне погрузки грейферными или магнитными кранами",
          "На расстоянии 15 м от зоны работы грейферного крана в случае, если кран работает внутри производственного здания",
          "На расстоянии 20 м от зоны работы магнитного крана в случае, если кран работает внутри производственного здания",
          "Во всех перечисленных зонах",
        ],
        correctAnswers: ["В зоне погрузки грейферными или магнитными кранами"],
      },
      {
        code: "20301027",
        text: "Какие действия должны быть выполнены при утрате наряда-допуска на работы повышенной опасности?",
        answers: [
          "Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново",
          "Работы должны быть прекращены. Для продолжения работ должна быть внесена запись в журнал регистрации нарядов-допусков на работы повышенной опасности",
          "Работы могут быть продолжены, оформление нового наряд-допуска не требуется",
          "Решение о дальнейшей работе должен принять работник, выдавший наряд-допуск",
        ],
        correctAnswers: [
          "Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново",
        ],
      },
      {
        code: "20301028",
        text: "В каком количестве экземпляров составляется акт-допуск подрядных организаций на опасный производственный объект горно-металлургической промышленности?",
        answers: [
          "В двух экземплярах",
          "В трех экземплярах",
          "В четырех экземплярах",
          "В одном экземпляре",
        ],
        correctAnswers: ["В двух экземплярах"],
      },
      {
        code: "20301029",
        text: "Какое количество производителей (руководителей) работ назначаются на все время действия наряда-допуска на работы повышенной опасности при производстве работ в несколько смен?",
        answers: [
          "Несколько производителей (руководителей) работ в соответствии с количеством смен",
          "Количество производителей (руководителей) работ должен определять работник, выдающий наряд-допуск",
          "Один производитель (руководитель) работ",
        ],
        correctAnswers: [
          "Несколько производителей (руководителей) работ в соответствии с количеством смен",
        ],
      },
      {
        code: "20301030",
        text: "С кем согласовывается план организации и проведения газоопасной работы?",
        answers: [
          "Только с начальником газоспасательной службы",
          "Только со службой производственного контроля",
          "Только с ответственным представителем подразделения или организации (главным энергетиком)",
          "Со всеми перечисленными",
        ],
        correctAnswers: ["Со всеми перечисленными"],
      },
      {
        code: "20301031",
        text: "Что должно иметься на шлаковом отвале?",
        answers: [
          "Телефонная или радиосвязь с диспетчерской службой производства",
          "Система спутникового мониторинга",
          "Двусторонняя громкоговорящая связь с диспетчерской службой",
        ],
        correctAnswers: [
          "Телефонная или радиосвязь с диспетчерской службой производства",
        ],
      },
      {
        code: "20301032",
        text: "Какие требования установлены к хранению стержней, шаров, футеровки, запасных деталей и приспособлений?",
        answers: [
          "Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре",
          "Должно быть предусмотрено в специальном помещении",
          "Должно быть предусмотрено на площадках, обеспечивающих свободный подход",
        ],
        correctAnswers: [
          "Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре",
        ],
      },
      {
        code: "20301033",
        text: "Какие требования установлены к установке расходных баков с мазутом?",
        answers: [
          "Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением",
          "Должны быть установлены на расстоянии не менее 10 м от печей",
          "Должны быть установлены на расстоянии не менее 15 м от печей и должны быть защищены паровой завесой",
        ],
        correctAnswers: [
          "Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением",
        ],
      },
      {
        code: "20301034",
        text: "На какие группы подразделяются газоопасные места на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "На I и II группы газоопасности",
          "На I, II, III и IV группы газоопасности",
          "На I, II и III группы газоопасности",
        ],
        correctAnswers: ["На I и II группы газоопасности"],
      },
      {
        code: "20301035",
        text: "С кем согласовывается инструкция, определяющая порядок подготовки и безопасного проведения газоопасных работ на опасных производственных объектах горно-металлургической промышленности? Выберите два правильных ответа.",
        answers: [
          "Со службой производственного контроля",
          "С ГСС",
          "С руководителем организации",
          "С территориальным органом Ростехнадзора",
        ],
        correctAnswers: ["Со службой производственного контроля", "С ГСС"],
      },
      {
        code: "20301036",
        text: "Чему подлежат производственные емкости (ковши, шлаковые чаши, совки, кюбели, тара, корзины) после изготовления или ремонта?",
        answers: [
          "Проверке состояния",
          "Фотофиксации",
          "Гидравлическим испытаниям",
        ],
        correctAnswers: ["Проверке состояния"],
      },
      {
        code: "20301037",
        text: "Какое требование к устройству внутренних производственных помещений, находящихся на производственной площадке, включающей технологию с расплавами металлов, имеющей опасность аварии или травмирования работников, указано неверно?",
        answers: [
          "Помещения пультов управления должны иметь вытяжную вентиляцию",
          "Помещения должны иметь не менее 2 выходов, расположенных с учетом аварийной эвакуации работников; двери помещения должны открываться наружу и не должны иметь внутренних запоров",
          "Окна закрытых пультов управления, в которые возможно попадание брызг расплава, должны быть оборудованы в соответствии с проектом",
          "Помещения должны иметь защиту от воздействия теплового излучения",
        ],
        correctAnswers: [
          "Помещения пультов управления должны иметь вытяжную вентиляцию",
        ],
      },
      {
        code: "20301038",
        text: "Какие требования установлены к инструментам, приспособлениям и оснастке, используемым для ведения технологии или обслуживания оборудования?",
        answers: [
          "Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними",
          "Должны находиться в свободном доступе",
          "Должны предназначаться для выполнения любых работ",
        ],
        correctAnswers: [
          "Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними",
        ],
      },
      {
        code: "20301039",
        text: "Каким составом должны выполняться газоопасные работы I и II группы газоопасности с образованием взрывопожароопасных смесей?",
        answers: [
          "Не менее двух человек под наблюдением газоспасателя или члена ДГСД",
          "Не менее трех человек под наблюдением газоспасателя или члена ДГСД",
          "Не менее четырех человек под наблюдением газоспасателя или члена ДГСД",
          "Одним человеком под наблюдением газоспасателя или члена ДГСД",
        ],
        correctAnswers: [
          "Не менее двух человек под наблюдением газоспасателя или члена ДГСД",
        ],
      },
      {
        code: "20301040",
        text: "Какие шпалы должны применяться на железнодорожных путях шлакового отвала?",
        answers: [
          "Огнестойкие шпалы",
          "Деревянные шпалы",
          "Деревянные шпалы, пропитанные креозотом",
        ],
        correctAnswers: ["Огнестойкие шпалы"],
      },
      {
        code: "20301041",
        text: "На какой максимальный срок выдается наряд-допуск на работы повышенной опасности?",
        answers: [
          "На 30 календарных дней",
          "На 45 календарных дней",
          "На 20 календарных дней",
          "На 15 календарных дней",
        ],
        correctAnswers: ["На 30 календарных дней"],
      },
      {
        code: "20301042",
        text: "От чего должны быть защищены составные части производственного оборудования, в том числе энергетические трубопроводы, рукава подачи природного газа, кислорода, мазута, воздуха, масла, воды, троллеи для питания электрического оборудования тележек, перевозящих расплавы металлов, и электрические кабели?",
        answers: [
          "Только от тепловых воздействий",
          "Только от механических воздействий",
          "Только от возможного попадания на них расплава",
          "От всего перечисленного",
        ],
        correctAnswers: ["От всего перечисленного"],
      },
      {
        code: "20301043",
        text: "Что должна обеспечить система светозвуковой сигнализации?",
        answers: [
          "Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств",
          "Оповещение эксплуатационного персонала о начале и окончании смены",
          "Оповещение эксплуатационного персонала о приближении технологического транспорта",
        ],
        correctAnswers: [
          "Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств",
        ],
      },
      {
        code: "20301044",
        text: "Какие требования предъявляются к устройству и эксплуатации печей для обжига шихтовых материалов и концентратов?",
        answers: [
          "Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции",
          "Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 15%",
          "Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 20%",
        ],
        correctAnswers: [
          "Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции",
        ],
      },
      {
        code: "20301045",
        text: "В течение какого времени хранятся закрытые (подписанные) наряды-допуски на работы повышенной опасности?",
        answers: [
          "В течение одного месяца",
          "В течение шести месяцев",
          "В течение трех месяцев",
          "В течение года",
        ],
        correctAnswers: ["В течение одного месяца"],
      },
      {
        code: "20301046",
        text: "После чего разрешается производить слив расплава в ковши, вышедшие из ремонта?",
        answers: [
          "Только после тщательного их просушивания и разогрева",
          "Только после их увлажнения и охлаждения",
          "Только после выдержки в течение 1 смены при температуре выше 40 °С",
        ],
        correctAnswers: [
          "Только после тщательного их просушивания и разогрева",
        ],
      },
      {
        code: "20301047",
        text: "Какой стаж работы в газовом хозяйстве должны иметь лица, назначаемые ответственными руководителями и ответственными исполнителями газоопасных работ?",
        answers: [
          "Не менее одного года",
          "Не менее шести месяцев",
          "Не менее трех лет",
          "Не менее пяти лет",
        ],
        correctAnswers: ["Не менее одного года"],
      },
      {
        code: "20301048",
        text: "Какие требования установлены к системам освещения во взрывоопасных помещениях?",
        answers: [
          "Должны предусматриваться системы освещения во взрывобезопасном исполнении",
          "Должны предусматриваться системы освещения, работающие от напряжения 42 В",
          "Должны предусматриваться системы освещения, работающие от напряжения 24 В",
        ],
        correctAnswers: [
          "Должны предусматриваться системы освещения во взрывобезопасном исполнении",
        ],
      },
      {
        code: "20301049",
        text: "С кем согласовывается перечень работ повышенной опасности на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля",
          "С Ростехнадзором",
          "С руководителем газоспасательной службы",
          "С главным инженером",
        ],
        correctAnswers: [
          "Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля",
        ],
      },
      {
        code: "20301050",
        text: "Чем должны быть оборудованы шлаковозы?",
        answers: [
          "Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением",
          "Механизмами кантования (поворота) чаши с ручным гидравлическим приводом",
          "Механизмами кантования (поворота) чаши с ручным пневматическим приводом и автоматическим управлением",
        ],
        correctAnswers: [
          "Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением",
        ],
      },
      {
        code: "20301051",
        text: "Какие требования установлены к месту расстановки ковшей, предназначенных для расплава металла?",
        answers: [
          "Должны расставляться на горизонтальных площадках в соответствии с проектом и с учетом технологии производства",
          "Должны расставляться на свободных площадках в любом месте пролета производственных цехов",
          "Должны расставляться в соответствии с планом производства работ",
        ],
        correctAnswers: [
          "Должны расставляться на горизонтальных площадках в соответствии с проектом и с учетом технологии производства",
        ],
      },
      {
        code: "20301052",
        text: "При какой температуре должны храниться на специально оборудованном складе компоненты полиуретанового клея и растворители?",
        answers: [
          "Не ниже 0°C",
          "Не ниже 10 °C",
          "Не ниже 15 °C",
          "Не ниже 25 °C",
        ],
        correctAnswers: ["Не ниже 10 °C"],
      },
      {
        code: "20301053",
        text: "Какой допускается зазор между колошниковой площадкой, вагранкой и шахтой подъемника?",
        answers: [
          "Не должен превышать 20 мм",
          "Не должен превышать 30 мм",
          "Не должен превышать 50 мм",
          "Не должен превышать 70 мм",
        ],
        correctAnswers: ["Не должен превышать 50 мм"],
      },
      {
        code: "20301054",
        text: "Какая допускается максимальная температура воды в рубашке водяного охлаждения фурменного и плавильного поясов вагранки?",
        answers: ["40 °C", "60 °C", "80 °C", "100 °C"],
        correctAnswers: ["80 °C"],
      },
      {
        code: "20301055",
        text: "В каком случае взятие пробы смеси может производиться во время работы бегунов?",
        answers: [
          "В случае применения механических приспособлений",
          "В случае отбора проб ручным способом",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["В случае применения механических приспособлений"],
      },
      {
        code: "20301056",
        text: "Какая должна быть температура угольной пыли в бункерах для хранения угольной пыли?",
        answers: [
          "Не более 50 °C",
          "Не более 60 °C",
          "Не более 70 °C",
          "Не более 80 °C",
        ],
        correctAnswers: ["Не более 70 °C"],
      },
      {
        code: "20301057",
        text: "Какой должен быть запас угольной пыли в бункере для хранения угольной пыли?",
        answers: [
          "Не должен превышать сменной потребности",
          "Не должен превышать суточной потребности",
          "Не должен превышать недельной потребности",
          "Не должен превышать месячной потребности",
        ],
        correctAnswers: ["Не должен превышать суточной потребности"],
      },
      {
        code: "20301058",
        text: "Каким образом должна определяться продолжительность нахождения залитых металлом форм в зоне активной вентиляции?",
        answers: [
          "Технологической инструкцией",
          "Технологической картой",
          "Проектом производства работ",
          "Приказом организации",
        ],
        correctAnswers: ["Технологической инструкцией"],
      },
      {
        code: "20301059",
        text: "В каком месте допускается хранение спирта и эфирно-альдегидной фракции в помещениях, в которых проводят гидролиз этилсиликата?",
        answers: [
          "В несгораемом металлическом ящике",
          "В таре выполненой из горючих материалов",
          "В любой таре без следов масла и грязи",
        ],
        correctAnswers: ["В несгораемом металлическом ящике"],
      },
      {
        code: "20301060",
        text: "Что из перечисленного относят к отходам литейного производства? Выберите правильный вариант ответа.",
        answers: [
          "Только отработанные формовочные и стержневые смеси",
          "Только брак форм и стержней, просыпи, литейные шлаки, абразивную и галтовочную пыль",
          "Только огнеупорные материалы, керамику и шламы мокрых пылеочистных вентиляционных систем",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20301061",
        text: "В каком случае допускается размещение в одном помещении с фольгопрокатным оборудованием взрывопожароопасных отделений промывки, окраски и приготовления краски?",
        answers: [
          "Допускается в случае наличия в помещении потивопожарного оборудования",
          "Допускается в исключительных случаях",
          "Не допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "20301062",
        text: "Какое из перечисленных положений нарушает требования промышленной безопасности в прокатном производстве?",
        answers: [
          "Перевалка валков должна производиться в соответствии с технологической инструкцией с использованием штатных перевалочных механизмов и приспособлений.",
          "Предохранительное ограждение перед ножами ножниц должно иметь блокировку, исключающую работу ножниц при поднятом ограждении",
          "Допускается размещение в одном помещении с фольгопрокатным оборудованием взрывопожароопасных отделений промывки, окраски и приготовления краски.",
          "Смазка полотна валков должна быть централизованной",
        ],
        correctAnswers: [
          "Допускается размещение в одном помещении с фольгопрокатным оборудованием взрывопожароопасных отделений промывки, окраски и приготовления краски.",
        ],
      },
      {
        code: "20301063",
        text: "Какое из перечисленных требований безопасности в прокатном производстве указано неверно? Выберите правильный вариант ответа.",
        answers: [
          "Работы по погрузке и уборке обрези должны выполняться в соответствии с требованиями технологической инструкции.",
          "Во время передвижения вагонов для установки под сбросной желоб должны подаваться звуковые сигналы.",
          "Приготовление и хранение красок для маркировки металла должно производиться в отдельном изолированном помещении",
          "Погружать влажные корзины с металлом необходимо в щелочную ванну",
        ],
        correctAnswers: [
          "Погружать влажные корзины с металлом необходимо в щелочную ванну",
        ],
      },
      {
        code: "20301064",
        text: "Ограждение какой высотой должны иметь ванны горячего цинкования, расположенные на уровне пола (настила рабочей площадки), по всему периметру?",
        answers: [
          "Не менее 0,8 м",
          "Не менее 1,0 м",
          "Не менее 1,1 м",
          "Не менее 2,1 м",
        ],
        correctAnswers: ["Не менее 1,1 м"],
      },
      {
        code: "20301065",
        text: "С помощью чего допускается производить подъем и перемещение ЛКМ?",
        answers: [
          "Только электропогрузчиков",
          "Только автотранспорта и перемещение с использованием мостового крана",
          "Только специальных тележек",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20301066",
        text: "Какие этикетки или бирки должна иметь тара, в которой находятся полимеры, ЛКМ?",
        answers: [
          "С наименованием",
          "С обозначением содержащихся материалов",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20301067",
        text: "В каком количестве должны храниться обтирочные материалы, пропитанные лаком и растворителями, в закрытой таре?",
        answers: [
          "Не превышающем их суточное потребление",
          "Не превышающем их недельное потребление",
          "Не превышающем их месячное потребление",
          "Не превышающем их двухмесячное потребление",
        ],
        correctAnswers: ["Не превышающем их суточное потребление"],
      },
      {
        code: "20301068",
        text: "Какой предел огнестойкости должен быть у стен, разделяющих взрывоопасные помещения на водородных станциях?",
        answers: ["2,5 часа", "1,5 часа", "1 час", "2 часа"],
        correctAnswers: ["2,5 часа"],
      },
      {
        code: "20301069",
        text: "Какие требования из перечисленных должны предусматриваться для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты? Выберите правильный вариант ответа.",
        answers: [
          "Дистанционное отключение и установка на линиях всасывания и нагнетания запорных или отсекающих устройств",
          "Средства, предотвращающие развитие реакций",
          "Технологическая сигнализация и устройство видеонаблюдения",
        ],
        correctAnswers: [
          "Дистанционное отключение и установка на линиях всасывания и нагнетания запорных или отсекающих устройств",
        ],
      },
      {
        code: "20301070",
        text: "Какие из перечисленных ограждающих конструкций не относятся к легкосбрасываемым?",
        answers: [
          "Конструкции асбоцементных, алюминиевых и стальных листов с легким утеплением",
          "Оконные переплеты заполнены обычным оконным стеклом толщиной 3, 4 и 5 мм, площадью не менее соответственно 0,8, 1 и 1,5 м2",
          "Фонарные переплеты",
          "Стекло с армированием",
        ],
        correctAnswers: ["Стекло с армированием"],
      },
      {
        code: "20301071",
        text: "На какой высоте от пола должны быть расположены окна в помещениях отделений наполненных баллонов, наполнительных и разрядных рамп?",
        answers: [
          "Не менее 1,5 м",
          "Не менее 0,5 м",
          "Не менее 1,2 м",
          "Не менее 1,0 м",
        ],
        correctAnswers: ["Не менее 1,5 м"],
      },
      {
        code: "20301072",
        text: "Какой должна быть ширина свободного прохода между щитом управления вентилями и ближайшими стенами наполнительной?",
        answers: [
          "Не менее 0,5 м",
          "Не менее 0,7 м",
          "Не менее 0,8 м",
          "Не менее 0,9 м",
        ],
        correctAnswers: ["Не менее 0,8 м"],
      },
      {
        code: "20301073",
        text: "Стальными листами какой толщины должны отделяться от рабочей зоны баллоны с водородом при их горизонтальном расположении и размещении внутри помещения?",
        answers: [
          "Не менее 16 мм",
          "Не менее 9 мм",
          "Не менее 5 мм",
          "Не менее 10 мм",
        ],
        correctAnswers: ["Не менее 16 мм"],
      },
      {
        code: "20301074",
        text: "Какой технической документацией должен быть оснащен каждый компрессорный агрегат или установка, или группа однородных компрессорных установок?",
        answers: [
          "Только паспортом (формуляром) на компрессорную установку и инструкцией (руководством) по эксплуатации (безопасному обслуживанию) компрессорной установки",
          "Только схемой трубопроводов (сжатого воздуха или газа, воды, масла) с указанием мест установок задвижек, вентилей, влагомаслоотделителей, промежуточных и концевых холодильников, воздухосборников, контрольно-измерительных приборов",
          "Только журналом учета работы компрессора, графиком ремонтов компрессорной установки, паспортами-сертификатами компрессорного масла и результатами его лабораторного анализа",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20301075",
        text: "В соответствии с требованиями какой документации должны производиться проектирование, монтаж и эксплуатация водородопроводов, а также вспомогательных трубопроводов обвязки участков комплекса получения и потребления водорода?",
        answers: [
          "Строительных норм и правил",
          "Нормативно-технической документации для технологических стальных трубопроводов",
          "Правила безопасности процессов получения или применения металлов",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20301076",
        text: "С какой периодичностью должна производиться проверка технического состояния вентиляционных каналов в зданиях (помещениях) с установленным газоиспользующим оборудованием и пылеуборка, и дезинфекция вентиляционных каналов?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раз в 3 месяца",
          "Не реже 2 раз в год",
          "Не реже 1 раза в год",
        ],
        correctAnswers: ["Не реже 2 раз в год"],
      },
      {
        code: "20301077",
        text: "Где не допускается размещение операторных помещений?",
        answers: [
          "В отдельных щитовых помещениях (встроенных или пристроенных)",
          "Смежных помещениях с взрывоопасными зонами",
          "В производственных помещениях",
          "В отдельно стоящих зданиях",
        ],
        correctAnswers: ["В производственных помещениях"],
      },
      {
        code: "20301078",
        text: "Чем должны защищаться стальные канаты и цепи грузоподъемных устройств, предназначенные для перемещения ковшей с расплавленным металлом, а также траверсы самих ковшей?",
        answers: [
          "Должны защищаться кожухами от воздействия лучистого тепла",
          "Должны смазываться специальным материалом, защищающим от воздействия лучистого тепла",
          "Должны покрываться огнестойкими составами для защиты от воздействия тепловой нагрузки",
        ],
        correctAnswers: [
          "Должны защищаться кожухами от воздействия лучистого тепла",
        ],
      },
      {
        code: "20301079",
        text: "В каких случаях не допускается вторичное использование ковша для заливки или разливки металла?",
        answers: [
          "Без предварительной замены стопора и стакана",
          "Без предварительной замены футеровки",
          "Без предварительной замены стопора",
        ],
        correctAnswers: ["Без предварительной замены стопора и стакана"],
      },
      {
        code: "20301080",
        text: "Какое из перечисленных требований к изготовлению форм и стержней указано неверно?",
        answers: [
          "Этажерки для сушки стержней должны быть испытаны методом радиографического исследования",
          "Покрытие поверхности форм и стержней противопригарными красками, выделяющими вредные вещества, должно проводиться под вытяжкой в соответствии с технологической инструкцией",
          "Очистка плит формовочных машин от остатков формовочной смеси должна проводиться механизированными устройствами и приспособлениями с локализацией пылеудаления",
          "Прочность крепления лопастей колеса метательной головки должна проверяться во избежание вылета лопастей при ослаблении крепления",
        ],
        correctAnswers: [
          "Этажерки для сушки стержней должны быть испытаны методом радиографического исследования",
        ],
      },
      {
        code: "20301081",
        text: "На основании чего должны определяться утилизация, нейтрализация, складирование или захоронение отходов литейных производств?",
        answers: [
          "Проекта",
          "Правил безопасности процессов получения или применения металлов",
          "Эксплуатационной документации",
          "Технологической инструкции",
        ],
        correctAnswers: ["Проекта"],
      },
      {
        code: "20301082",
        text: "Каким документом определяется максимальная величина давления газа (аргона), необходимая для открывания донных продувочных фурм и ковшевых пробок?",
        answers: [
          "Технологической инструкцией",
          "Проектом",
          "Инструкцией о мерах пожарной безопасности при проведении огневых работ",
          "Правилами безопасности процессов получения или применения металлов",
        ],
        correctAnswers: ["Технологической инструкцией"],
      },
      {
        code: "20301083",
        text: "Что из перечисленного допускается при осуществлении процессов литейного производства?",
        answers: [
          "Подвергать кольцо и цапфы ковша отжигу",
          "Использовать вторично ковш для заливки и разливки металла без предварительной замены стопора и стакана",
          "Сваривать отдельные части колец и цапф",
          "Все перечисленное",
        ],
        correctAnswers: ["Подвергать кольцо и цапфы ковша отжигу"],
      },
      {
        code: "20301084",
        text: "Каким гидравлическим давлением должны испытываться на прочность и плотность трубки системы охлаждения индуктора?",
        answers: [
          "На 1,5 величины рабочего давления охлаждающей воды",
          "На 1,25 величины рабочего давления охлаждающей воды",
          "На 1,15 величины рабочего давления охлаждающей воды",
        ],
        correctAnswers: ["На 1,5 величины рабочего давления охлаждающей воды"],
      },
      {
        code: "20301085",
        text: "Какое из перечисленных требований при производстве глинозема указано неверно?",
        answers: [
          "Допускается открывание люков на топках и газораспределительных камерах во время работы печей кипящего слоя в присутствии наблюдающего",
          "Приводной механизм вращения печей спекания и кальцинации должен быть оборудован резервным двигателем с независимым источником питания",
          "Шуровка материала в печах должна производиться при разрежении в печи, исключающем выбросы пламени, газов и материала в рабочую зону",
          "При производстве работ на миксерах, печах и хлораторах с применением грузоподъемных механизмов, а также при заливке, перемешивании расплава и удалении шлама напряжение с электродов должно быть снято",
        ],
        correctAnswers: [
          "Допускается открывание люков на топках и газораспределительных камерах во время работы печей кипящего слоя в присутствии наблюдающего",
        ],
      },
      {
        code: "20301086",
        text: "С какой периодичностью кольца и цапфы ковша после изготовления должны проверяться методом неразрушающего контроля?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в полгода",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 2 года",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "20301087",
        text: "В каких случаях не допускается отключение системы охлаждения кристаллизатора?",
        answers: [
          "До выгрузки слитка из камеры",
          "Если внезапно остановился процесс",
          "Если снизилась температура",
        ],
        correctAnswers: ["До выгрузки слитка из камеры"],
      },
      {
        code: "20301088",
        text: "Какое из перечисленных требований к смесеприготовлению указано неверно?",
        answers: [
          "При варке сульфитной щелочи в цехе варочные банки должны помещаться в вытяжных шкафах с параметрами вытяжки согласно требованиям эксплуатирующей организации",
          "В бункерах для хранения угольной пыли должна контролироваться температура внутри бункера. Температура пыли не должна превышать 70 °C",
          "Взятие пробы смеси во время работы бегунов должно производиться механическим приспособлением",
          "Материалы, используемые для приготовления формовочных и стержневых смесей, должны иметь сертификаты качества",
        ],
        correctAnswers: [
          "При варке сульфитной щелочи в цехе варочные банки должны помещаться в вытяжных шкафах с параметрами вытяжки согласно требованиям эксплуатирующей организации",
        ],
      },
      {
        code: "20301089",
        text: "Какие требования установлены к состоянию вакуум-ковшей, тиглей, коробов, кристаллизаторов, изложниц и других емкостей для расплава перед заливкой в них расплава в электролитическом производстве магния?",
        answers: [
          "Должны быть очищены от мусора",
          "Должны быть выдержаны в отапливаемом помещении не менее суток",
          "Должны быть выдержаны в помещении не менее 3 суток",
        ],
        correctAnswers: ["Должны быть очищены от мусора"],
      },
      {
        code: "20301090",
        text: "Когда должны выполняться работы по очистке пространства под печью, а также приямков от шлака и мусора?",
        answers: [
          "Работы должны выполняться только в начале плавления шихты до образования значительного количества жидкого металла",
          "Работы должны выполняться только до начала плавления шихты",
          "Работы должны выполняться после выпуска металла",
        ],
        correctAnswers: [
          "Работы должны выполняться только в начале плавления шихты до образования значительного количества жидкого металла",
        ],
      },
      {
        code: "20301091",
        text: "Чему из перечисленного должны соответствовать средства автоматизации, производственной сигнализации и связи, блокировочные устройства? Выберите все правильные ответы.",
        answers: [
          'Требованиям "Правил безопасности процессов получения или применения металлов"',
          "Производственной документации",
          'Требованиям "Правил безопасности в нефтяной и газовой промышленности"',
          'Требованиям Технического регламента Таможенного союза "О безопасности оборудования для работы во взрывоопасных средах"',
        ],
        correctAnswers: [
          'Требованиям "Правил безопасности процессов получения или применения металлов"',
          "Производственной документации",
        ],
      },
      {
        code: "20301092",
        text: "При каком условии должно проводиться покрытие поверхности форм и стержней противопригарными красками, выделяющими вредные вещества?",
        answers: [
          "Под вытяжкой в соответствии с технологической инструкцией",
          "При условии проветривания помещения",
          "При наличии системы кондиционирования воздуха",
          "При наличии естественной вентиляции",
        ],
        correctAnswers: [
          "Под вытяжкой в соответствии с технологической инструкцией",
        ],
      },
      {
        code: "20301093",
        text: "В течение какого времени должны подаваться звуковые и световые сигналы перед пуском в работу технического устройства, узлы которого или все устройство перемещаются в процессе работы?",
        answers: [
          "Не менее 10 секунд",
          "Не менее 5 секунд",
          "Не менее 30 секунд",
          "Не менее 50 секунд",
        ],
        correctAnswers: ["Не менее 10 секунд"],
      },
      {
        code: "20301094",
        text: "Как часто должна проверяться надежность крепления головок электродов?",
        answers: [
          "Правилами не регламентируется",
          "1 раз в сутки",
          "1 раз в 3 дня",
        ],
        correctAnswers: ["Правилами не регламентируется"],
      },
      {
        code: "20301095",
        text: "Что должно быть у каждого плавильного агрегата с выпуском металла через летку?",
        answers: [
          "Должны быть две штанги длиной не менее 1,5 м и запасные пробки для закрывания леток",
          "Должны быть три штанги длиной не менее 2,0 м и запасные пробки для закрывания леток",
          "Должны быть две штанги длиной не менее 1,0 м и запасные пробки для закрывания леток",
        ],
        correctAnswers: [
          "Должны быть две штанги длиной не менее 1,5 м и запасные пробки для закрывания леток",
        ],
      },
      {
        code: "20301096",
        text: "Каким образом должно осуществляться хранение сыпучих материалов?",
        answers: [
          "В закрытых коробах, подключенных к системе вытяжной вентиляции",
          "В герметичной таре",
          "В специальных цеховых кладовых",
          "В несгораемом, плотно закрывающемся металлическом ящике",
        ],
        correctAnswers: [
          "В закрытых коробах, подключенных к системе вытяжной вентиляции",
        ],
      },
      {
        code: "20301097",
        text: "Чем определяется высота наполнения ковша металлом и общее количество присадок, обеспечивающее безопасное выполнение технологических операций?",
        answers: [
          "Производственной документацией",
          "Проектной документацией",
          'Требованиями "Правил безопасности процессов получения или применения металлов"',
          "Нормативной документацией",
        ],
        correctAnswers: ["Производственной документацией"],
      },
      {
        code: "20301098",
        text: "Чем должна обеспечиваться безопасность производственных процессов нанесения металлопокрытий?",
        answers: [
          "Только автоматизацией производственных процессов и герметизацией технологического оборудования, являющегося источником вредных и (или) опасных производственных факторов",
          "Только применением безопасных способов хранения и транспортирования исходных и вспомогательных веществ и материалов, заготовок и готовой продукции",
          "Только комплексной механизацией и автоматизацией ручного труда, дистанционным управлением производственными процессами и операциями",
          "Только использованием блокировочных устройств, средств световой и звуковой сигнализации, аварийного отключения производственного оборудования при нарушениях производственных процессов",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20301099",
        text: "Какие установлены требования к хранению материалов для приготовления формовочных смесей?",
        answers: [
          "Должны храниться в отдельных помещениях, размещаемых вне пределов производственных участков и отделений",
          "Должны храниться в специально отведенных местах цеха",
          "Должны храниться в отдельных помещениях производственных участков в герметичной таре",
        ],
        correctAnswers: [
          "Должны храниться в отдельных помещениях, размещаемых вне пределов производственных участков и отделений",
        ],
      },
      {
        code: "20301100",
        text: "В каком положении должна быть крышка завалочного окна во время работы газокислородной горелки?",
        answers: [
          "Крышка должна быть закрыта",
          "Крышка должна быть открыта",
          "Крышка должна быть снята",
        ],
        correctAnswers: ["Крышка должна быть закрыта"],
      },
      {
        code: "20301101",
        text: "При каком давлении должен срабатывать предохранительный клапан вакуумной камеры печи?",
        answers: [
          "При давлении 0,01 МПа (0,1 кгс/см²)",
          "При давлении 0,05 МПа (0,5 кгс/см²)",
          "При давлении 0,02 МПа (0,2 кгс/см²)",
        ],
        correctAnswers: ["При давлении 0,01 МПа (0,1 кгс/см²)"],
      },
      {
        code: "20301102",
        text: 'Какой напорный бак должен быть установлен в печи "Аусмелт" на случай отключения электроснабжения?',
        answers: [
          "Обеспечивающий подачу воды в течение 15 минут с момента отказа системы циркуляционного водяного охлаждения",
          "Обеспечивающий подачу воды в течение 30 минут с момента отказа системы циркуляционного водяного охлаждения",
          "Обеспечивающий подачу воды в течение 10 минут с момента отказа системы циркуляционного водяного охлаждения",
          "Обеспечивающий подачу воды в течение 5 минут с момента отказа системы циркуляционного водяного охлаждения",
        ],
        correctAnswers: [
          "Обеспечивающий подачу воды в течение 15 минут с момента отказа системы циркуляционного водяного охлаждения",
        ],
      },
      {
        code: "20301103",
        text: 'Какому гидравлическому испытанию должны подвергаться водоохлаждаемые элементы печи "Аусмелт" перед их установкой?',
        answers: [
          "На 1,5 величины рабочего давления охлаждающей жидкости",
          "На величину рабочего давления охлаждающей жидкости",
          "На 1,25 величины рабочего давления охлаждающей жидкости",
          "На 2,5 величины рабочего давления охлаждающей жидкости",
        ],
        correctAnswers: [
          "На 1,5 величины рабочего давления охлаждающей жидкости",
        ],
      },
      {
        code: "20301104",
        text: 'В каком из перечисленных случаев запрещено возобновлять подачу воды к любому из водоохлаждаемых компонентов печи "Аусмелт"?',
        answers: [
          "Если температура этих компонентов превышает 150 °C",
          "Если температура этих компонентов превышает 100 °C",
          "Если температура этих компонентов превышает 50 °C",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Если температура этих компонентов превышает 150 °C"],
      },
      {
        code: "20301105",
        text: 'До какой минимальной температуры должна быть охлаждена печь "Аусмелт" для возобновления подачи охлаждающей жидкости при перегреве печи и неисправности водоохлаждающих элементов?',
        answers: ["150 °C", "200 °C", "100 °C", "220 °C"],
        correctAnswers: ["150 °C"],
      },
      {
        code: "20301106",
        text: 'Подача шихты с какой влажностью запрещается в печь "Аусмелт"?',
        answers: [
          "Более 8% по массе",
          "Более 7% по массе",
          "Более 5% по массе",
          "Более 3% по массе",
        ],
        correctAnswers: ["Более 8% по массе"],
      },
      {
        code: "20301107",
        text: "Какому давлению гидравлического испытания должны подвергаться водоохлаждаемые элементы печей перед их установкой?",
        answers: [
          "На 1,5 величины рабочего давления охлаждающей воды",
          "На 2,0 величины рабочего давления охлаждающей воды",
          "На величину рабочего давления охлаждающей воды",
        ],
        correctAnswers: ["На 1,5 величины рабочего давления охлаждающей воды"],
      },
      {
        code: "20301108",
        text: "Какие требования установлены к алюминиевой стружке, принимаемой на хранение?",
        answers: [
          "Должна быть сухой, без следов масла и грязи",
          "Должна быть упакована в герметичные емкости",
          "Должна быть одинакового размера",
        ],
        correctAnswers: ["Должна быть сухой, без следов масла и грязи"],
      },
      {
        code: "20301109",
        text: "Как должны производиться очистка боровов и ремонтные работы внутри них?",
        answers: [
          "Должны производиться по наряду-допуску при полной остановке печи",
          "Должны производиться по наряду-допуску при температуре внутри борова не выше 45 °C",
          "Должны производиться по наряду-допуску при температуре внутри борова не выше 50 °C",
        ],
        correctAnswers: [
          "Должны производиться по наряду-допуску при полной остановке печи",
        ],
      },
      {
        code: "20301110",
        text: "Какие требования установлены к материалам, используемым для приготовления формовочных и стержневых смесей?",
        answers: [
          "Должны иметь сертификаты качества",
          "Должны иметь товарно-транспортные накладные",
          "Должны иметь данные о поставщике",
        ],
        correctAnswers: ["Должны иметь сертификаты качества"],
      },
      {
        code: "20301111",
        text: "Какие требования к цинкованию металлов и полимерным покрытиям указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Только нанесение защитных покрытий на листовой металл (лужение, оцинкование) следует производить на установках непрерывного действия",
          "Только все операции по транспортированию и обработке проката при нанесении защитных покрытий, а также вспомогательные операции (смена анодов в ваннах электролитического лужения и оцинкования, загрузка металла в ванну с расплавом, приготовление, подача и очистка растворов) должны быть механизированы и выполняться в соответствии с технологическими инструкциями",
          "Только оборудование для нанесения защитных покрытий (полимерных материалов) должно иметь местные отсосы",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20301112",
        text: "Какие требования к цинкованию металлов указаны неверно?",
        answers: [
          "Срок замены оборудования и футеровки ванн для цинкования определяется эксплуатирующей организацией по результатам проведения экспертизы промышленной безопасности ванн для цинкования",
          "Все вращающие механизмы, ванны с расплавом цинка, а также движущаяся полоса должны иметь стационарные защитные ограждения",
          "Срок эксплуатации ванн цинкования определяется проектной документацией завода-изготовителя",
          "Порядок загрузки блоков цинка в ванну цинкования определяется проектом",
          "Электропроводка на ваннах цинкования должна быть защищена от возможного повреждения их горячим металлом",
        ],
        correctAnswers: [
          "Срок замены оборудования и футеровки ванн для цинкования определяется эксплуатирующей организацией по результатам проведения экспертизы промышленной безопасности ванн для цинкования",
        ],
      },
      {
        code: "20301113",
        text: "В каком случае допускается попадание аэрозолей противопригарных красок в воздух рабочей зоны во время покрытия поверхностных форм и стержней противопригарными веществами?",
        answers: [
          "Если обслуживающий персонал использует соответствующие средства индивидуальной защиты",
          "Если есть аварийная вентиляция",
          "Если есть вытяжная вентиляция",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20301114",
        text: "Какие требования к внепечной обработке жидкого металла указаны неверно?",
        answers: [
          "Процесс выдувания порошкообразного материала из пневмонагнетателя и тракта подачи должен осуществляться вручную в соответствии с производственной документацией",
          "Ввод раскислителей и легирующих материалов в ковш с жидким металлом под вакуумом должен осуществляться через вакуумный шлюз, конструкция которого должна обеспечивать сохранение вакуума в системе",
          "Агрегаты внепечной обработки жидкого металла должны быть оборудованы системой управления, обеспечивающей функционирование механизмов в безопасных режимах и автоматическое отключение агрегатов при отклонении контролируемых параметров от заданных",
          "Высота наполнения ковша металлом и общее количество присадок, обеспечивающее безопасное выполнение технологических операций, должны определяться производственной документацией",
        ],
        correctAnswers: [
          "Процесс выдувания порошкообразного материала из пневмонагнетателя и тракта подачи должен осуществляться вручную в соответствии с производственной документацией",
        ],
      },
      {
        code: "20301115",
        text: "Какие требования установлены к ведению взрывных работ, хранению, транспортированию взрывчатых материалов и эксплуатации броневых ям?",
        answers: [
          "Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах",
          'Работы должны проводиться в соответствии с "Правилами безопасности процессов получения или применения металлов"',
          "Работы должны производиться цеховым обслуживающим персоналом",
        ],
        correctAnswers: [
          "Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах",
        ],
      },
      {
        code: "20301116",
        text: "На какое время смесеприготовительные машины и средства транспортирования смесей должны быть остановлены, а электрические схемы разобраны?",
        answers: [
          "Только на время внутренних осмотров",
          "Только на время ремонта",
          "Только на время чистки",
          "Только на время смазки",
          "На время проведения всех перечисленных работ",
        ],
        correctAnswers: ["На время проведения всех перечисленных работ"],
      },
      {
        code: "20301117",
        text: "Чем должны быть оборудованы ванны с кислотой для травления оборудования?",
        answers: [
          "Местными отсосами",
          "Укрытиями",
          "Системой подачи аргона",
          "Всем перечисленным",
        ],
        correctAnswers: ["Местными отсосами"],
      },
      {
        code: "20301118",
        text: "Каким требованиям должна соответствовать эксплуатация сушильных устройств, работающих на газе, а также сушильных устройств с электроподогревом?",
        answers: [
          "Требованиям технологической инструкции",
          'Требованиям Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
          'Требованиям "Правил безопасности процессов получения или применения металлов"',
          "Требованиям наряда-допуска",
        ],
        correctAnswers: ["Требованиям технологической инструкции"],
      },
      {
        code: "20301119",
        text: "Какие требования установлены к снабжению литейных производств щелочью?",
        answers: [
          "Должны снабжаться сульфитной щелочью",
          "Должны снабжаться щелочью в виде порошка",
          "Должны снабжаться кристаллизованной щелочью в металлических бочках",
        ],
        correctAnswers: ["Должны снабжаться сульфитной щелочью"],
      },
      {
        code: "20301120",
        text: "Каким количеством работников должно осуществляться выполнение операций на установке по нанесению полимерных покрытий?",
        answers: [
          "Не менее чем 2 работниками",
          "Не менее чем 3 работниками",
          "Не менее чем 4 работниками",
          "Не менее чем 5 работниками",
        ],
        correctAnswers: ["Не менее чем 2 работниками"],
      },
      {
        code: "20301121",
        text: "Какое из перечисленных требований к дуговым электропечам указано верно? Выберите два варианта ответа.",
        answers: [
          "Перед сменой электродов нарезная часть металлического ниппеля должна быть полностью (до конца нарезки) ввернута в электрод",
          "Для установки газокислородной горелки в завалочное окно электропечи в крышке окна должно быть устроено специальное отверстие, соответствующее размерам электрода",
          "Газокислородные горелки должны быть оборудованы запорной арматурой, а также приборами, контролирующими расход и давление газа, кислорода и охлаждающей воды",
          "Горелка перед включением должна быть продута инертным газом, после чего должен подаваться газ",
        ],
        correctAnswers: [
          "Перед сменой электродов нарезная часть металлического ниппеля должна быть полностью (до конца нарезки) ввернута в электрод",
          "Газокислородные горелки должны быть оборудованы запорной арматурой, а также приборами, контролирующими расход и давление газа, кислорода и охлаждающей воды",
        ],
      },
      {
        code: "20301122",
        text: "После чего выполняется включение электропечи (для просушки или плавки металла)?",
        answers: [
          "В соответствии с методическими рекомендациями после осмотра главным инженером",
          "В соответствии с технологической инструкцией после осмотра дежурным электриком",
          "В соответствии с памяткой после осмотра лица, ответсвенного за безопасную эксплуатацию электропечи",
        ],
        correctAnswers: [
          "В соответствии с технологической инструкцией после осмотра дежурным электриком",
        ],
      },
      {
        code: "20301123",
        text: "Какое из перечисленных требований к газокислородным горелкам дуговых электропечей указано неверно?",
        answers: [
          "Для контроля за положением горелки на каретках должны быть установлены специальные указатели",
          "Газокислородные горелки должны быть оборудованы запорной арматурой, а также приборами, контролирующими расход и давление газа, кислорода и охлаждающей воды",
          "Для установки газокислородной горелки в завалочное окно электропечи в крышке окна должно быть устроено специальное отверстие, соответствующее размерам горелки.",
          "Во время работы газокислородной горелки крышка завалочного окна должна быть открыта",
        ],
        correctAnswers: [
          "Во время работы газокислородной горелки крышка завалочного окна должна быть открыта",
        ],
      },
      {
        code: "20301124",
        text: "На какую величину должны подвергаться гидравлическому испытанию водоохлаждаемые элементы дуговых электропечей перед их установкой?",
        answers: [
          "На 1,2 величины рабочего давления охлаждающей воды",
          "На 1,5 величины рабочего давления охлаждающей воды",
          "На 1,8 величины рабочего давления охлаждающей воды",
          "На 2,5 величины рабочего давления охлаждающей воды",
        ],
        correctAnswers: ["На 1,5 величины рабочего давления охлаждающей воды"],
      },
      {
        code: "20301125",
        text: "Какой должна быть температура воды, отходящей от водоохлаждаемых элементов дуговых электропечей?",
        answers: [
          "Ниже температуры выпадения осадков временной жесткости",
          "Выше температуры выпадения осадков временной жесткости",
          "Выше температуры выпадения осадков временной жесткости на 10°С",
          "Выше температуры выпадения осадков временной жесткости на 15°С",
        ],
        correctAnswers: [
          "Ниже температуры выпадения осадков временной жесткости",
        ],
      },
      {
        code: "20301126",
        text: "Каким образом проводится охлаждение кожуха пода или стенок дуговой электропечи при обнаружении перегрева этих мест во время плавки?",
        answers: [
          "Сжатым воздухом до полной остановки печи для ликвидации перегрева",
          "Охлаждение перегретых поверхностей водой",
          "Только отключением печи",
        ],
        correctAnswers: [
          "Сжатым воздухом до полной остановки печи для ликвидации перегрева",
        ],
      },
      {
        code: "20301127",
        text: "Какие работы на всех электропечах (за исключением тигельных печей сопротивления) должны производиться только после снятия напряжения с нагревательных элементов? Выберите правильный вариант ответа.",
        answers: [
          "Только загрузка шихты и подшихтовка",
          "Только введение присадок и перемешивание расплавленного металла",
          "Только снятие шлака и взятие проб",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20301128",
        text: "При каком условии допускаются осмотр и ремонт оборудования, расположенного под открытой индукционной печью, находящейся в поднятом положении?",
        answers: [
          "При условии дополнительного крепления поднятой печи с помощью специальных упоров",
          "Если печь удерживается лебедкой",
          "Если печь оборудована ловителями",
          "Запрещен при любых условиях",
        ],
        correctAnswers: [
          "При условии дополнительного крепления поднятой печи с помощью специальных упоров",
        ],
      },
      {
        code: "20301129",
        text: "Какое из перечисленных требований при эксплуатации открытых индукционных печей указано верно? Выберите 2 варианта ответа.",
        answers: [
          "Трубки системы охлаждения индуктора должны быть испытаны на прочность и плотность гидравлическим давлением не менее 0,5 величины рабочего давления охлаждающей воды.",
          "Контроль за непрерывным поступлением охлаждающей воды в индуктор печи должен производиться только визуально",
          "Кабели, подводящие ток к индуктору печи, должны быть изолированы и ограждены",
          "Каркас индукционной печи должен быть изолирован от витков обмотки индуктора",
        ],
        correctAnswers: [
          "Кабели, подводящие ток к индуктору печи, должны быть изолированы и ограждены",
          "Каркас индукционной печи должен быть изолирован от витков обмотки индуктора",
        ],
      },
      {
        code: "20301130",
        text: "Чем из перечисленного должны оснащаться топливные баки пламенных печей, работающих на жидком топливе?",
        answers: [
          "Только указателем уровня топлива",
          "Только спускным краном с трубопроводом, выведенным в аварийный подземный резервуар",
          "Только трубопроводом для сообщения с атмосферой (воздушник)",
          "Только переливной трубкой, выведенной в аварийный подземный резервуар",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20301131",
        text: "Каким образом должен определяться объем аварийного резервуара пламенных печей, работающих на жидком топливе?",
        answers: [
          "Равен объему массы в расходных баках",
          "Проектом с учетом общей вместимости расходных баков, установленных в помещении",
          "Равен 1/2 объема массы в расходных баках",
          "Равен 1/5 объема массы в расходных баках",
        ],
        correctAnswers: [
          "Проектом с учетом общей вместимости расходных баков, установленных в помещении",
        ],
      },
      {
        code: "20301132",
        text: "Какое количество запорных вентилей должно быть на топливопроводе каждой пламенной печи, работающей на жидком или газовом топливе?",
        answers: [
          "1 запорныхй вентиль",
          "2 запорных вентиля",
          "3 запорных вентиля",
          "4 запорных вентиля",
        ],
        correctAnswers: ["2 запорных вентиля"],
      },
      {
        code: "20301133",
        text: "Чем должен производиться подогрев мазута в баках топлива пламенных печей? Выберите 2 варианта ответа.",
        answers: [
          "Паром",
          "Открытым огнем",
          "Газовой горелкой",
          "Горячей водой",
        ],
        correctAnswers: ["Паром", "Горячей водой"],
      },
      {
        code: "20301134",
        text: "Какой системой вентиляции должны быть оборудованы пламенные печи, работающие на жидком и твердом топливе?",
        answers: [
          "Вытяжной системой вентиляции",
          "Приточной системой вентиляции",
          "Вытяжной и приточной системой вентиляции",
        ],
        correctAnswers: ["Вытяжной системой вентиляции"],
      },
      {
        code: "20301135",
        text: "На какое значение нижняя отметка борова в футеровке должна быть выше нижней отметки загрузочного окна во избежание попадания расплавленного металла в боров пламенной печи?",
        answers: [
          "Не менее чем на 50 мм",
          "Не менее чем на 100 мм",
          "Не менее чем на 120 мм",
          "Не менее чем на 150 мм",
        ],
        correctAnswers: ["Не менее чем на 100 мм"],
      },
      {
        code: "20301136",
        text: "Какое из перечисленных требований не допускается при эксплуатации вакуумно-дуговых печей? Выберите правильный вариант ответа.",
        answers: [
          "Только в случае зависания слитка в кристаллизаторе выдавливание его штоком не допускается",
          "Только использование открытого огня при осмотре внутренних частей печи",
          "Только полное сплавление электрода",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20301137",
        text: "Чем должен устанавливаться порядок запуска и отключения плазмотронов?",
        answers: [
          "Инструкциями предприятия",
          "Методическими рекомендациями",
          "Памятками",
          "Приказом",
        ],
        correctAnswers: ["Инструкциями предприятия"],
      },
      {
        code: "20301138",
        text: "Какая допускается максимальная температура воздуха внутри печи для производства ремонтных работ?",
        answers: [
          "Не должна превышать 30 °C",
          "Не должна превышать 40 °C",
          "Не должна превышать 50 °C",
          "Не должна превышать 60 °C",
        ],
        correctAnswers: ["Не должна превышать 40 °C"],
      },
      {
        code: "20301139",
        text: 'Какие действия необходимо предпринять при подозрении на вспенивание (переокисление) в ванне печи "Аусмелт"? Выберите правильный вариант ответа.',
        answers: [
          "Только вывести работников в безопасную зону",
          "Только прекратить шихтоподачу и дутье фурмы",
          "Только подать уголь",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20301140",
        text: 'В какое положение должна выводиться фурма из-под дутья при помощи лебедки, оборудованной системой автоматического аварийного вывода фурмы, при производстве меди методом пирометаллургической переработки в печи "Аусмелт"?',
        answers: [
          "Левое положение",
          "Правое положенеи",
          "Верхнее положение",
          "Нижнее положение",
        ],
        correctAnswers: ["Верхнее положение"],
      },
      {
        code: "20301141",
        text: 'Какие из перечисленных требований при производстве меди методом пирометаллургической переработки в печи "Аусмелт" указаны неверно? Выберите правильный вариант ответа.',
        answers: [
          "Запуск системы подачи охлаждающей воды должен производиться до нагрева печи",
          "Водоохлаждаемые элементы перед их установкой должны подвергаться гидравлическому испытанию на 1,5 величины рабочего давления охлаждающей жидкости",
          "Эксплуатация печи при нарушении подачи охлаждающей воды к блокам шпуров и неисправной сигнализации запрещается",
          "На случай отключения электроснабжения должен быть установлен напорный бак, обеспечивающий подачу воды в течение 30 минут с момента отказа системы циркуляционного водяного охлаждения",
        ],
        correctAnswers: [
          "На случай отключения электроснабжения должен быть установлен напорный бак, обеспечивающий подачу воды в течение 30 минут с момента отказа системы циркуляционного водяного охлаждения",
        ],
      },
      {
        code: "20301142",
        text: "Чем производится отогрев смерзшейся изоляции для обеспечения доступа к адсорберам?",
        answers: [
          "Открытым пламенем",
          "Влажным паром",
          "Горячей водой",
          "Сухим подогретым воздухом",
        ],
        correctAnswers: ["Сухим подогретым воздухом"],
      },
      {
        code: "20301143",
        text: 'В соответствии с какой документацией должен проводиться разогрев печи "Аусмелт" после капитального ремонта?',
        answers: [
          "В соответствии с технологической инструкцией",
          "В соответствии с паспортом оборудования",
          "В соответствии с методическими рекомендациями",
          "В соответствии с с технологической картой",
        ],
        correctAnswers: ["В соответствии с технологической инструкцией"],
      },
      {
        code: "20301144",
        text: 'Для чего печь "Аусмелт" оборудуется резервной газовоздушной горелкой?',
        answers: [
          "Для удаления воздуха",
          "Для достижения поддержания температурных параметров огнеупорной кладки, расплава, а также параметров котла-утилизатора",
          "Для снижения давления",
        ],
        correctAnswers: [
          "Для достижения поддержания температурных параметров огнеупорной кладки, расплава, а также параметров котла-утилизатора",
        ],
      },
      {
        code: "20301145",
        text: "Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей?",
        answers: [
          "Только системами регулирования заданного соотношения топлива, воздуха и водяного пара",
          "Только средствами сигнализации о прекращении поступления топлива, а также воздуха при его принудительной подаче в топочное пространство",
          "Только падением давления нагреваемого продукта на выходе из печи ниже регламентированного значения",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20301146",
        text: "В каком месте может быть установлен запорный вентиль на топливопроводе каждой пламенной печи, работающей на жидком или газовом топливе?",
        answers: [
          "За капитальной стеной или на расстоянии 15 м от печи",
          "Перед капитальной стеной",
          "На расстоянии 10 м от печи",
          "На расстоянии 5 м от печи",
        ],
        correctAnswers: [
          "За капитальной стеной или на расстоянии 15 м от печи",
        ],
      },
      {
        code: "20301147",
        text: "Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных вне помещений?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20301148",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии до 0,1 мм/г?",
        answers: ["Один раз в 4 года", "Один раз в 2 года", "Один раз в 5 лет"],
        correctAnswers: ["Один раз в 4 года"],
      },
      {
        code: "20301149",
        text: "До розжига газопотребляющего агрегата чем должен быть продут газопровод перед коллектором?",
        answers: ["Газом", "Сжатым воздухом", "Водяным паром"],
        correctAnswers: ["Газом"],
      },
      {
        code: "20301150",
        text: "Какие требования установлены к инструментам и приспособлениям, используемым во взрывопожароопасных зонах и помещениях?",
        answers: [
          "Не должны давать искры при работе с ними",
          "Должны быть изготовлены из стали",
          "Должны быть изготовлены из железа или его сплавов",
        ],
        correctAnswers: ["Не должны давать искры при работе с ними"],
      },
      {
        code: "20301151",
        text: "Как часто должна производиться нивелировка действующих газопроводов?",
        answers: ["Один раз в 5 лет", "Один раз в 3 года", "Один раз в год"],
        correctAnswers: ["Один раз в 5 лет"],
      },
      {
        code: "20301152",
        text: "Как часто должно проверяться исправное действие автоблокировки и сигнализации?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в квартал",
          "Не реже 1 раза в полгода",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20301153",
        text: "При каких показателях газа разрешено применять жидкостные манометры?",
        answers: [
          "При давлении до 0,03 МПа",
          "При давлении до 0,04 МПа",
          "При давлении до 0,05 МПа",
        ],
        correctAnswers: ["При давлении до 0,03 МПа"],
      },
      {
        code: "20301154",
        text: "С какой периодичностью следует очищать фильтры?",
        answers: [
          "В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
          "В первый раз - через 20 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
          "В первый раз - через 15 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
        ],
        correctAnswers: [
          "В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
        ],
      },
      {
        code: "20301155",
        text: "Что необходимо устанавливать в производственных помещениях, где возможны воспламенение одежды или химические ожоги у работников?",
        answers: [
          "Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
          "Брезентовые покрывала",
          "Емкости с песком",
        ],
        correctAnswers: [
          "Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
      },
      {
        code: "20301156",
        text: "Как часто должна проводиться проверка правильности показаний приборов, перечень которых утверждается руководителем производства?",
        answers: [
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в год",
        ],
        correctAnswers: ["Не реже 1 раза в 3 месяца"],
      },
      {
        code: "20301157",
        text: "Какая допускается максимальная температура газа в газопроводах в случае подачи в них пара для пропарки, продувки и отогрева?",
        answers: ["85 °C", "90 °C", "100 °C"],
        correctAnswers: ["85 °C"],
      },
      {
        code: "20301158",
        text: "Какие требования установлены к расположению контрольно-измерительных приборов и автоматики (далее – КИПиА)?",
        answers: [
          "Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования",
          "Приборы должны устанавливаться на расстоянии не менее 5 м от оборудования",
          "Приборы должны устанавливаться в непосредственной близости к оборудованию",
        ],
        correctAnswers: [
          "Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования",
        ],
      },
      {
        code: "20301159",
        text: "После установления каких показателей температуры разрешается проводить ремонтные работы внутри нагретых технических устройств, а также низкотемпературных технических устройств?",
        answers: [
          "После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания",
          "После снижения температуры воздуха до 45 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания",
          "После снижения температуры воздуха до 50 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания",
        ],
        correctAnswers: [
          "После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания",
        ],
      },
      {
        code: "20301160",
        text: "Как часто должна производиться проверка конденсатоотводчиков и пароспутников, обогревающих трубопроводы кислорода, в зимнее время?",
        answers: ["Ежедневно", "Еженедельно", "Ежемесячно"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "20301161",
        text: "Какое количество баллонов с эталонными и поверочными газовыми смесями разрешается хранить в помещении хроматографии?",
        answers: ["Не более двух", "Не более одного", "Не более четырех"],
        correctAnswers: ["Не более двух"],
      },
      {
        code: "20301162",
        text: "Какие требования установлены к проведению ремонтных работ внутри нагретых технических устройств?",
        answers: [
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C",
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 55 °C",
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 50 °C",
        ],
        correctAnswers: [
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C",
        ],
      },
      {
        code: "20301163",
        text: "Разрешено ли использовать продувочные свечи для выпуска в атмосферу избыточного газа?",
        answers: [
          "Только в аварийных случаях",
          "Разрешено при увеличении давления газа на 10%",
          "Запрещено",
        ],
        correctAnswers: ["Только в аварийных случаях"],
      },
      {
        code: "20301164",
        text: "Из каких материалов не могут быть изготовлены разделители (заглушки) или другие изделия, помещаемые внутрь кислородопровода при его обезжиривании?",
        answers: [
          "Из поролона или других органических материалов",
          "Из медного сплава",
          "Из алюминия",
        ],
        correctAnswers: ["Из поролона или других органических материалов"],
      },
      {
        code: "20301165",
        text: "Из каких материалов выполняются линии отбора кислорода на анализ?",
        answers: [
          "Из коррозионно-стойкой стали или медных сплавов",
          "Из чугуна",
          "Из алюминия",
        ],
        correctAnswers: ["Из коррозионно-стойкой стали или медных сплавов"],
      },
      {
        code: "20301166",
        text: "Как часто должен производиться осмотр трубопроводов кислорода?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20301167",
        text: "Каким должно быть расстояние от устройства забора воздуха и камер всасывания работающих воздушных компрессоров воздухоразделительных установок (далее - ВРУ) при выполнении ремонтных работ, работ по газовой сварке и резке металла, чтобы для их выполнения было необходимо наличие письменного разрешения руководителя производства (цеха) и наряда-допуска?",
        answers: ["Менее 100 м", "Менее 200 м", "Менее 150 м", "Менее 250 м"],
        correctAnswers: ["Менее 100 м"],
      },
      {
        code: "20301168",
        text: "Из каких сплавов металлов запрещается установка арматуры на кислородопроводах?",
        answers: ["Из сплавов титана", "Из алюминиевых сплавов", "Из бронзы"],
        correctAnswers: ["Из сплавов титана"],
      },
      {
        code: "20301169",
        text: "На что должен проверяться выполненный сварной шов после врезки в действующий газопровод?",
        answers: ["На плотность", "На прочность", "На герметичность"],
        correctAnswers: ["На плотность"],
      },
      {
        code: "20301170",
        text: "Каким образом должен проводиться отогрев трубопроводной арматуры?",
        answers: [
          "Горячим воздухом, паром или горячей водой",
          "Газовой горелкой",
          "Паяльной лампой",
        ],
        correctAnswers: ["Горячим воздухом, паром или горячей водой"],
      },
      {
        code: "20301171",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии 0,1 - 0,5 мм/г?",
        answers: ["Один раз в 2 года", "Один раз в год", "Один раз в 3 года"],
        correctAnswers: ["Один раз в 2 года"],
      },
      {
        code: "20301172",
        text: "Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях?",
        answers: [
          "Не реже одного раза в неделю",
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в месяц",
        ],
        correctAnswers: ["Не реже одного раза в неделю"],
      },
      {
        code: "20301173",
        text: "Как часто должны проходить поверку контрольно-измерительные приборы в процессе эксплуатации?",
        answers: [
          "Не реже одного раза в год",
          "Не реже одного раза в 3 года",
          "Не реже одного раза в 6 месяцев",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20301174",
        text: "Каково минимально допустимое давление газа непосредственно перед потребителем (после регулирующих органов)?",
        answers: ["0,5 кПа", "0,3 кПа", "0,4 кПа"],
        correctAnswers: ["0,5 кПа"],
      },
      {
        code: "20301175",
        text: "Какие требования установлены к оборудованию производственных помещений, в которых возможны воспламенения одежды или химические ожоги?",
        answers: [
          "Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
          "Должны быть устроены ящики с песком",
          "Должны быть в наличии огнетушители",
        ],
        correctAnswers: [
          "Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
      },
      {
        code: "20301176",
        text: "Куда должны заноситься записи о проведении технического обслуживания, выявленных дефектах и нарушениях газопроводов?",
        answers: [
          "В эксплуатационный журнал",
          "В журнал технического обслуживания и ремонта оборудования",
          "В эксплуатационный паспорт газопровода",
        ],
        correctAnswers: ["В эксплуатационный журнал"],
      },
      {
        code: "20301177",
        text: "При каких условиях допускается перенос сроков проведения капитальных ремонтов основного технологического оборудования?",
        answers: [
          "При наличии положительного заключения экспертного обследования",
          "При наличии разрешения завода-изготовителя",
          "При наличии письменного разрешения технического руководителя",
        ],
        correctAnswers: [
          "При наличии положительного заключения экспертного обследования",
        ],
      },
      {
        code: "20301178",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии более 0,5 мм/г?",
        answers: [
          "Один раз в год",
          "Один раз в 6 месяцев",
          "Один раз в 2 года",
        ],
        correctAnswers: ["Один раз в год"],
      },
      {
        code: "20301179",
        text: "Какой должна быть объемная доля кислорода в воздухе производственных помещений производства продуктов разделения воздуха (далее - ПРВ)?",
        answers: [
          "Не менее 19% и не более 23%",
          "Не менее 18% и не более 24%",
          "Не менее 21% и не более 25%",
        ],
        correctAnswers: ["Не менее 19% и не более 23%"],
      },
      {
        code: "20301180",
        text: "В какие сроки в помещениях категории А должна осуществляться проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в 2 месяца",
          "Не реже одного раза в 3 месяца",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20301181",
        text: "В каких случаях запрещается газовая резка и сварка на действующих газопроводах?",
        answers: [
          "На газопроводах, находящихся под разрежением",
          "На газопроводах, находящихся под давлением",
          "На газопроводах, продутых сжатым воздухом",
        ],
        correctAnswers: ["На газопроводах, находящихся под разрежением"],
      },
      {
        code: "20301182",
        text: "Какой установлен порядок хранения обтирочных материалов?",
        answers: [
          "В чистых металлических ящиках с крышками",
          "В специально отведенных местах цеха",
          "На специально оборудованной площадке",
        ],
        correctAnswers: ["В чистых металлических ящиках с крышками"],
      },
      {
        code: "20301183",
        text: "Каким образом в эксплуатирующей организации, имеющей газовое хозяйство, производящей и потребляющей горючие газы, должен быть организован контроль за обеспечением безопасной эксплуатации газового хозяйства?",
        answers: [
          "Только созданием газовой службы",
          "Только назначением ответственных работников",
          "Только заключением договора на оказание услуг по техническому обслуживанию и ремонту газового хозяйства",
          "Всеми перечисленными способами",
        ],
        correctAnswers: ["Всеми перечисленными способами"],
      },
      {
        code: "20301184",
        text: "Из каких газов состоят промышленные газы объектов металлургии, возникающие (используемые) в процессе металлургического производства?",
        answers: [
          "Только из природного газа",
          "Только из доменного, коксового, конвертерного, ферросплавного и реформерного газа",
          "Только из газов, возникающих (используемых) в процессе металлургического производства, а также смесей газов",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20301185",
        text: "Какое допускается максимальное содержание оксида углерода в газах, поступающих в газоочистное устройство вагранки, при отводе газов с полным дожиганием?",
        answers: [
          "Не должно превышать 0,5%",
          "Не должно превышать 0,8%",
          "Не должно превышать 1%",
          "Не должно превышать 2%",
        ],
        correctAnswers: ["Не должно превышать 1%"],
      },
      {
        code: "20301186",
        text: "Понижение давления до какого значения должны обеспечивать противовзрывные клапаны аппаратов системы пылеочистки и очистки отходящих ваграночных газов?",
        answers: [
          "До 5 кПа (0,05 кгс/см2)",
          "До 3 кПа (0,03 кгс/см2)",
          "До 2 кПа (0,02 кгс/см2)",
          "До 1 кПа (0,01 кгс/см2)",
        ],
        correctAnswers: ["До 5 кПа (0,05 кгс/см2)"],
      },
      {
        code: "20301187",
        text: "Какое должно быть расстояние между ресиверами одного газа?",
        answers: [
          "Не менее 1,5 м в свету",
          "Не менее 2 м в свету",
          "Не менее 2,5 м в свету",
          "Не менее 3 м в свету",
        ],
        correctAnswers: ["Не менее 1,5 м в свету"],
      },
      {
        code: "20301188",
        text: "Какие требования к ресиверам для водорода (сосудам) указаны неверно?",
        answers: [
          'На ресиверах должны быть поясняющие надписи: "Водород. Взрывоопасно"',
          "Расстояние от ресиверов (сосудов) с водородом до ограждения - не менее 1,0 м",
          "Ресиверы для водорода (сосуды) размещаются на открытых площадках, имеющих по периметру ограждение легкого типа высотой не менее 1,2 м из несгораемого материала",
          "Все перечисленные",
        ],
        correctAnswers: [
          "Расстояние от ресиверов (сосудов) с водородом до ограждения - не менее 1,0 м",
        ],
      },
      {
        code: "20301189",
        text: "Какое из перечисленных требований к потреблению газообразного кислорода и других продуктов разделения воздуха указано неверно?",
        answers: [
          "На кислородопроводах допускается установка арматуры из сплавов на основе титана",
          "Не допускается устанавливать и применять кислородное оборудование возле замасляного или прожированного оборудования, территории",
          "Кислородная арматура для присоединения рукавов должна быть размещена в металлическом шкафу с отверстиями для проветривания.",
          "Использование ПРВ по каждому производству, участку или объекту должно осуществляться по технологическим инструкциям",
        ],
        correctAnswers: [
          "На кислородопроводах допускается установка арматуры из сплавов на основе титана",
        ],
      },
      {
        code: "20301190",
        text: "Какое значение не должно превышать содержание сероводорода в коксовом газе в заводских сетях действующих предприятий?",
        answers: ["2 г/нм3", "2,5 г/нм3", "3 г/нм3", "4 г/нм3"],
        correctAnswers: ["4 г/нм3"],
      },
      {
        code: "20301191",
        text: "Какое значение не должно превышать содержание сероводорода в коксовом газе при газоплазменной обработке металла в закрытых помещениях, а также нафталина летом и зимой?",
        answers: [
          "Содержание сероводорода в коксовом газе не должно превышать 0,01 г/нм3, нафталина - 0,03 г/нм3 (летом) и 0,1 г/нм3 (зимой)",
          "Содержание сероводорода в коксовом газе не должно превышать 0,02 г/нм3, нафталина - 0,05 г/нм3 (летом) и 0,1 г/нм3 (зимой)",
          "Содержание сероводорода в коксовом газе не должно превышать 0,04 г/нм3, нафталина - 0,06 г/нм3 (летом) и 0,2 г/нм3 (зимой)",
          "Содержание сероводорода в коксовом газе не должно превышать 0,03 г/нм3, нафталина - 0,08 г/нм3 (летом) и 0,3 г/нм3 (зимой)",
        ],
        correctAnswers: [
          "Содержание сероводорода в коксовом газе не должно превышать 0,02 г/нм3, нафталина - 0,05 г/нм3 (летом) и 0,1 г/нм3 (зимой)",
        ],
      },
      {
        code: "20301192",
        text: "Какое расчетное давление газа соответствует газопроводам среднего давления?",
        answers: [
          "Свыше 1,2 МПа",
          "До 0,005 МПа включительно",
          "Свыше 0,005 до 0,3 МПа включительно",
          "Свыше 0,03 до 0,6 МПа включительно",
        ],
        correctAnswers: ["Свыше 0,005 до 0,3 МПа включительно"],
      },
      {
        code: "20301193",
        text: "В каком случае все технологическое оборудование должно продуваться инертным газом? Выберите 2 варианта ответа.",
        answers: [
          "После остановки более чем на 2 часа",
          "Перед пуском",
          "Если оно в период остановки находилось под избыточным давлением водорода",
          "После остановки более чем на 1 час",
        ],
        correctAnswers: ["После остановки более чем на 2 часа", "Перед пуском"],
      },
      {
        code: "20301194",
        text: "Как должны сооружаться газопроводы на территории предприятия?",
        answers: [
          "Должны сооружаться надземными",
          "Должны сооружаться подземными",
          "Не регламентируеться",
        ],
        correctAnswers: ["Должны сооружаться надземными"],
      },
      {
        code: "20301195",
        text: "Через какое расстояние должны заземляться наружные газопроводы?",
        answers: [
          "Через каждые 50 м",
          "Через каждые 150 м",
          "Через каждые 250 м",
          "Через каждые 300 м",
        ],
        correctAnswers: ["Через каждые 250 м"],
      },
      {
        code: "20301196",
        text: "При каком максимальном содержании сероводорода в газе допускается установка бронзовых кранов или задвижек с бронзовыми кольцами на газопроводах?",
        answers: ["10 мг/м3", "15 мг/м3", "20 мг/м3", "25 мг/м3"],
        correctAnswers: ["20 мг/м3"],
      },
      {
        code: "20301197",
        text: "Какое из перечисленных требований к задвижкам и заглушкам, устанавливаемым на газопроводах, указано неверно?",
        answers: [
          "Задвижки и краны, устанавливаемые на газопроводах и устройствах, должны иметь указатель открытого и закрытого положения",
          "Установка внутри зданий и цехов на газопроводах листовых задвижек любого типа без дисковых задвижек перед ними не допускается",
          "Листовые задвижки на газопроводах диаметром более 300 мм должны быть оснащены механизированным приводом",
          "Перекрывающие листы листовых задвижек и заглушек диаметром до 4 м должны изготовляться из целого листа",
        ],
        correctAnswers: [
          "Перекрывающие листы листовых задвижек и заглушек диаметром до 4 м должны изготовляться из целого листа",
        ],
      },
      {
        code: "20301198",
        text: "Какая информация должна быть выбита на хвостовике каждой съемной заглушки газопровода?",
        answers: [
          "Только номер сертификата на заглушку",
          "Только номер заглушки, марка материала",
          "Только условное давление Pу и условный диаметр Ду",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20301199",
        text: "Какая должна быть минимальная высота водяного затвора конденсатоотводчиков межцеховых газопроводов, работающих под избыточным давлением?",
        answers: [
          "На 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст.",
          "На 750 мм вод. ст. больше расчетного разрежения в газопроводе",
          "На 200 мм вод. ст. больше расчетного разрежения в газопроводе",
          "На 100 мм вод. ст. больше расчетного разрежения в газопроводе",
        ],
        correctAnswers: [
          "На 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст.",
        ],
      },
      {
        code: "20301200",
        text: "Какое из перечисленных требований к отводу конденсата из цеховых газопроводов указано неверно?",
        answers: [
          "Допускается сброс конденсата через горелки печей и другого оборудования потребителя газа",
          "Конструкция конденсатоотводчиков, установленных в зданиях цехов, должна исключать возможность попадания газов в помещения",
          "Участки труб, отводящих конденсат, а также сами конденсатоотводчики, если цех не отапливается, должны быть утеплены",
          "Сброс конденсата из цеховых газопроводов следует производить через конденсатоотводчики",
        ],
        correctAnswers: [
          "Допускается сброс конденсата через горелки печей и другого оборудования потребителя газа",
        ],
      },
      {
        code: "20301201",
        text: "Какое из перечисленных требований к стационарным газоразборным постам указано неверно?",
        answers: [
          "При отсутствии рабочего, пользующегося газоразборным постом, шкаф должен быть закрыт на замок",
          "Газоразборные посты, шкафы должны иметь опознавательную окраску",
          "Расстояние между шкафами газоразборных постов для кислорода и горючего газа должно быть не менее 100 мм",
          "Стационарные газоразборные посты должны быть размещены в металлических шкафах с отверстиями для вентиляции",
        ],
        correctAnswers: [
          "Расстояние между шкафами газоразборных постов для кислорода и горючего газа должно быть не менее 100 мм",
        ],
      },
      {
        code: "20301202",
        text: "Какой должен быть нижний предел давления для I разряда газов-заменителей ацетилена перед газопламенной аппаратурой?",
        answers: ["1,0 кПа", "2,0 кПа", "3,0 кПа", "4,0 кПа"],
        correctAnswers: ["3,0 кПа"],
      },
      {
        code: "20301203",
        text: "При каком увеличении содержания кислорода в газе в напорном коллекторе газоотсасывающей станции отвод газа от электропечи должен быть немедленно прекращен?",
        answers: ["1%", "2%", "3%", "4%"],
        correctAnswers: ["2%"],
      },
      {
        code: "20301204",
        text: "Каким уплотнительным устройством, препятствующим выбиванию газов в цех, должно оборудоваться отверстие в охладителе конвертерных газов?",
        answers: [
          "Только аэродинамической завесой",
          "Только воздушной завесой",
          "Только азотной завесой",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20301205",
        text: "Какое допускается максимальное содержание оксида углерода в газах, поступающих в электрофильтр, при отводе газов с полным дожиганием?",
        answers: ["1%", "2%", "3%", "4%"],
        correctAnswers: ["1%"],
      },
      {
        code: "20301206",
        text: "Содержание каких из перечисленных газов измеряется в продуктах неполного сгорания за дымососом при отводе газов без дожигания? Выберите 2 варианта ответа.",
        answers: [
          "Только CO",
          "Только CO2",
          "Только O2 и H2",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20301207",
        text: "С какой периодичностью газопроводы сероочистки природного газа подвергаются пневматическому испытанию?",
        answers: [
          "1 раз в 4 года",
          "1 раз в год",
          "1 раз в 3 года",
          "1 раза в 5 лет",
        ],
        correctAnswers: ["1 раз в 4 года"],
      },
      {
        code: "20301208",
        text: "Какое из перечисленных требований к установке сероочистки природного газа указано верно?",
        answers: [
          "Установка сероочистки природного газа для получения реформерного газа должна быть снабжена быстродействующими отсекающими клапанами на входе газа в установку, срабатывающими только при снижении",
          "На пульте управления сероочистки на видном месте должна быть вывешена схема расположения аппаратов и трубопроводов.",
          "Два раз в 2 года газопроводы сероочистки подвергаются пневматическому испытанию",
          "Содержание CH4 в двух последовательно отобранных пробах должно быть не более 1,5%",
        ],
        correctAnswers: [
          "На пульте управления сероочистки на видном месте должна быть вывешена схема расположения аппаратов и трубопроводов.",
        ],
      },
      {
        code: "20301209",
        text: "Какое из перечисленных устройств должно быть установлено на нагнетательном газопроводе для отключения компрессора от коллектора высокого давления?",
        answers: [
          "Только обратный клапан",
          "Только два запорных вентиля",
          "Только свеча с условным проходом не менее 6 мм, имеющая прямое сообщение с атмосферой",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20301210",
        text: "С какой периодичностью должен производиться осмотр газопроводов, газовых установок и газового оборудования комиссией на производстве?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раз в 3 года",
          "Не реже 1 раза в 2 года",
          "Не реже 2 раз в год",
        ],
        correctAnswers: ["Не реже 2 раз в год"],
      },
      {
        code: "20301211",
        text: "Кто из перечисленных лиц не должен входить в состав постоянно действующей комиссии для проведения осмотров объектов газового хозяйства?",
        answers: [
          "Начальник цеха - ответственный за производственный контроль",
          "Работник - ответственный за безопасную эксплуатацию и техническое состояние газового хозяйства цеха",
          "Работники, ответственные за безопасную эксплуатацию и техническое состояние соответствующих технических устройств, сооружений и оборудования",
          "Представитель Ростехнадзора",
        ],
        correctAnswers: ["Представитель Ростехнадзора"],
      },
      {
        code: "20301212",
        text: "С какой периодичностью должна производиться ревизия с разборкой регулятора давления, предохранительных клапанов, фильтров газопроводов, газовых установок и газового оборудования?",
        answers: [
          "Не менее 1 раза в год",
          "Не реже 1 раза в 10 дней",
          "Не менее 1 раза в 6 месяцев",
          "Не менее 1 раза в 3 месяца",
        ],
        correctAnswers: ["Не менее 1 раза в год"],
      },
      {
        code: "20301213",
        text: "До каких пор должна осуществляться продувка азотом перед пуском в работу установки по производству реформерного газа для производства металлизованного горячевосстановленного железа?",
        answers: [
          "Пока на участке продувки кислорода не будет более 0,5%",
          "Пока на участке продувки кислорода не будет более 1%",
          "Пока на участке продувки кислорода не будет более 2%",
          "Пока на участке продувки кислорода не будет более 3%",
        ],
        correctAnswers: [
          "Пока на участке продувки кислорода не будет более 1%",
        ],
      },
      {
        code: "20301214",
        text: "Какое из перечисленных требований к установке по производству реформерного газа указано неверно?",
        answers: [
          "Перед розжигом горелок реформера должна быть произведена продувка его топочного пространства",
          "Для регулирования давления реформерного газа после холодильника и сброса его на свечу должны быть установлены регулирующие клапаны, работающие в ручном или автоматическом режиме",
          "Установка по производству реформерного газа для производства металлизованного горячевосстановленного железа должна иметь подвод азота для его продувки перед пуском в работу",
          "Продувка азотом должна осуществляться до тех пор, пока на участке продувки кислорода будет не более 2% кислорода",
        ],
        correctAnswers: [
          "Продувка азотом должна осуществляться до тех пор, пока на участке продувки кислорода будет не более 2% кислорода",
        ],
      },
      {
        code: "20301215",
        text: "Кто утверждает инструкцию по перекачке сероуглерода из транспортной емкости в емкость хранения?",
        answers: [
          "Главный инженер предприятия",
          "Начальник цеха",
          "Руководитель организации",
          "Представитель Ростехнадзора",
        ],
        correctAnswers: ["Главный инженер предприятия"],
      },
      {
        code: "20301216",
        text: "Кем производится испытание газопроводов и газовых установок после окончания строительно-монтажных и сварочных работ (включая ремонтные работы)?",
        answers: [
          "Строительно-монтажной организацией с участием представителя предприятия",
          "Проектной организацией",
          "Работниками предприятия-заказчика, с участием представителя Ростехнадзора",
          "Спецальной комиссией предприятия-заказчика",
        ],
        correctAnswers: [
          "Строительно-монтажной организацией с участием представителя предприятия",
        ],
      },
      {
        code: "20301217",
        text: "Какое из перечисленных требований к манометрам, используемым при проведении испытаний газопроводов и газовых установок, указано верно?",
        answers: [
          "При проведении испытаний газопроводов и газовых установок давление должно измеряться одним манометрами, прошедшим поверку и опломбирование",
          "Класс точности манометров должен быть не ниже 1 с диаметром корпуса не менее 120 мм",
          "Для проведения испытания на герметичность и прочность следует фиксировать падение давления в газопроводе манометрами классов точности 0,4 и 0,15, а также жидкостными манометрам",
          "При применении манометров без указания класса точности их погрешность допускается превышать порог измерения",
        ],
        correctAnswers: [
          "Для проведения испытания на герметичность и прочность следует фиксировать падение давления в газопроводе манометрами классов точности 0,4 и 0,15, а также жидкостными манометрам",
        ],
      },
      {
        code: "20301218",
        text: "Какое устанавливается минимальное расстояние от испытываемого газопровода большого диаметра, расположенных вне помещений, до границ охраняемой зоны во время проведения пневматических испытаний на прочность?",
        answers: [
          "Не менее 25 м",
          "Не менее 20 м",
          "Не менее 15 м",
          "Не менее 10 м",
        ],
        correctAnswers: ["Не менее 25 м"],
      },
      {
        code: "20301219",
        text: "Каким давлением производится дополнительное испытание на герметичность межцеховых и внутрицеховых газопроводов?",
        answers: [
          "Испытание производится давлением, равным рабочему",
          "Испытание производится давлением, в 1,5 раза больше рабочего давления",
          "Испытание производится давлением, в 1,8 раза больше рабочего давления",
          "Испытание производится давлением, в 2,5 раза больше рабочего давления",
        ],
        correctAnswers: ["Испытание производится давлением, равным рабочему"],
      },
      {
        code: "20301220",
        text: "Какая установлена продолжительность дополнительных испытаний на герметичность для вновь сооружаемых газопроводов?",
        answers: [
          "Не менее 24 часов",
          "Не менее 20 часов",
          "Не менее 10 часов",
          "Не менее 15 часов",
        ],
        correctAnswers: ["Не менее 24 часов"],
      },
      {
        code: "20301221",
        text: "Какая устанавливается продолжительность периодических испытаний на герметичность для газопроводов, а также испытаний после ремонта, связанного со сваркой и разборкой газопровода?",
        answers: [
          "Не менее 4 часов",
          "Не менее 3 часов",
          "Не менее 2 часов",
          "Не менее 1 часа",
        ],
        correctAnswers: ["Не менее 4 часов"],
      },
      {
        code: "20301222",
        text: "При какой скорости падения давления за время дополнительного пневматического испытания на герметичность внутрицеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?",
        answers: [
          "Не более 0,5% в час",
          "Не более 1% в час",
          "Не более 0,1% в час",
          "Не более 0,25% в час",
        ],
        correctAnswers: ["Не более 0,1% в час"],
      },
      {
        code: "20301223",
        text: "При какой скорости падения давления за время дополнительного пневматического испытания на герметичность межцеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?",
        answers: [
          "Не более 1% в час",
          "Не более 2% в час",
          "Не более 0,35% в час",
          "Не более 0,2% в час",
        ],
        correctAnswers: ["Не более 0,2% в час"],
      },
      {
        code: "20301224",
        text: "У кого должен находиться агрегатный (ремонтный) журнал на отдельных объектах газового цеха, а также в цехах, в составе которых имеются объекты газового хозяйства?",
        answers: [
          "Работника, ответственного за техническое состояние объекта газового хозяйства",
          "Начальника цеха",
          "Главного инженера",
          "Руководителя организации",
        ],
        correctAnswers: [
          "Работника, ответственного за техническое состояние объекта газового хозяйства",
        ],
      },
      {
        code: "20301225",
        text: "При каком избыточном давлении газа в газопроводах разрешается производить электросварочные работы на газопроводах при ремонтах?",
        answers: [
          "Не более 0.3 кПа",
          "Не более 0.4 кПа",
          "Не более 0.5 кПа",
          "Не более 0.6 кПа",
        ],
        correctAnswers: ["Не более 0.3 кПа"],
      },
      {
        code: "20301226",
        text: "Какие действия должны быть произведены перед продувкой газопровода? Выберите правильный вариант ответа.",
        answers: [
          "Задвижки должны быть установлены в нужное положение",
          "Водяные затворы залиты водой",
          "Все люки, лазы и свечи плотно закрыты, за исключением той свечи, через которую будет продуваться продувка",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20301227",
        text: "В каком радиусе у мест выброса газовоздушной смеси в атмосферу должно быть приостановлено движение всех видов транспорта, запрещено применение открытого огня и производство сварочных работ в период продувки газопровода?",
        answers: ["10 м", "30 м", "50 м", "100 м"],
        correctAnswers: ["50 м"],
      },
      {
        code: "20301228",
        text: "В каком случае допускается использовать продувочные свечи газопроводов для выпуска в атмосферу избыточного газа?",
        answers: ["Ни в каком случае", "В любом случае", "В аварийном"],
        correctAnswers: ["В аварийном"],
      },
      {
        code: "20301229",
        text: "Какие сроки проведения ревизии газопроводов должны быть при скорости коррозии более 0,5 мм/г?",
        answers: [
          "1 раз в год",
          "1 раз в 2 года",
          "1 раз в 4 года",
          "1 раз в 5 лет",
        ],
        correctAnswers: ["1 раз в год"],
      },
      {
        code: "20301230",
        text: "При какой скорости коррозии срок проведения ревизии газопроводов должен быть не реже одного раза в 2 года?",
        answers: [
          "Более 0,5 мм/г",
          "0,1 - 0,5 мм/г",
          "до 0,1 мм/г",
          "до 0,01 мм/г",
        ],
        correctAnswers: ["0,1 - 0,5 мм/г"],
      },
      {
        code: "20301231",
        text: "Какой допускается максимальный подъем температуры газа в газопроводах?",
        answers: ["85 °C", "80 °C", "75 °C", "70 °C"],
        correctAnswers: ["85 °C"],
      },
      {
        code: "20301232",
        text: "Кто осуществляет выбор типа быстродействующего отсекающего клапана на подводе газа к каждому агрегату с дутьевыми горелками?",
        answers: [
          "Организация, проектирующая систему автоматики печи",
          "Организация, эксплуатирующая объект",
          "Организация, эксплуатирующая систему автоматики объекта",
          "Сервисные службы изготовителя",
        ],
        correctAnswers: ["Организация, проектирующая систему автоматики печи"],
      },
      {
        code: "20301233",
        text: "Какое из перечисленных требований при эксплуатации газового оборудования газопотребляющих агрегатов указано верно?",
        answers: [
          "Розжиг газопотребляющего агрегата должен проводиться в соответствии с технологической инструкцией или инструкцией по пуску и остановке агрегата",
          "Продувочные свечи после отключения газопровода должны оставаться в закрытом положении",
          "Продувка газопровода и коллектора агрегата должна производиться при открытых задвижках на горелках",
          "Подачу газа без поднесения запальника, факела или другого воспламеняющего средства допускается производить (кроме котлов), если температура кладки в разогретой зоне розжига факела превышает 300 °C",
        ],
        correctAnswers: [
          "Розжиг газопотребляющего агрегата должен проводиться в соответствии с технологической инструкцией или инструкцией по пуску и остановке агрегата",
        ],
      },
      {
        code: "20301234",
        text: "С какой периодичностью должна производиться режимная наладка газоиспользующего оборудования?",
        answers: [
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в год",
          "Не реже 2 раз в год",
          "Не реже 1 раза в 4 года",
        ],
        correctAnswers: ["Не реже 1 раза в 3 года"],
      },
      {
        code: "20301235",
        text: "В каком случае допускается режимную наладку газоиспользующего оборудования проводить не реже одного раза в четыре года?",
        answers: [
          "При стабильной работе газоиспользующего оборудования",
          "В любом случае",
          "После капитального ремонта газоиспользующего оборудования или внесения конструктивных изменений, влияющих на эффективность использования газа",
          "В случае систематических отклонений контролируемых параметров работы газоиспользующего оборудования от режимных карт",
        ],
        correctAnswers: [
          "При стабильной работе газоиспользующего оборудования",
        ],
      },
      {
        code: "20301236",
        text: "С какой периодичностью должны производиться проверка технического состояния промышленных дымоотводящих устройств (газоходов газоиспользующего оборудования, дымоходов и дымовых труб) и их прочистка?",
        answers: [
          "Один раз в год",
          "Не реже раз в три месяца",
          "Не реже двух раз в год",
        ],
        correctAnswers: ["Не реже двух раз в год"],
      },
      {
        code: "20301237",
        text: "С какой периодичностью должен производиться текущий ремонт оборудования с разборкой регуляторов давления, предохранительных клапанов и фильтров газорегуляторных пунктов (далее- ГРП) и газорегуляторных установок (далее – ГРУ), если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания?",
        answers: [
          "Не менее 1 раза в месяц",
          "Не менее 1 раза в 3 месяца",
          "Не менее 1 раза в год",
          "Не менее 1 раза в 6 месяцев",
        ],
        correctAnswers: ["Не менее 1 раза в год"],
      },
      {
        code: "20301238",
        text: "При каком давлении должна производиться настройка срабатывания предохранительно-запорных клапанов при их наличии в ГРП (ГРУ)?",
        answers: [
          "При давлении на 5% больше расчетного",
          "При давлении на 15% больше расчетного",
          "При давлении на 25% больше расчетного",
          "При давлении на 30% больше расчетного",
        ],
        correctAnswers: ["При давлении на 15% больше расчетного"],
      },
      {
        code: "20301239",
        text: "В каком случае допускается подача потребителям газа по обводной линии (байпасу) ГРП и ГРУ?",
        answers: [
          "В случае если было проведено испытание сбросного предохранительного клапана или манометра",
          "Ни в каком случае",
          "В любом случае",
          "На время ревизии или ремонта оборудования при условии постоянного нахождения в ГРП или у ГРУ дежурного",
        ],
        correctAnswers: [
          "На время ревизии или ремонта оборудования при условии постоянного нахождения в ГРП или у ГРУ дежурного",
        ],
      },
      {
        code: "20301240",
        text: "Какие виды работ должны выполняться не реже одного раза в 6 месяцев при эксплуатации ГРП и ГРУ?",
        answers: [
          "Техническое обслуживание",
          "Текущий ремонт",
          "Проверка срабатывания предохранительных запорных и сбросных клапанов",
          "Капитальный ремонт при замене оборудования",
        ],
        correctAnswers: ["Техническое обслуживание"],
      },
      {
        code: "20301241",
        text: "Какие из перечисленных видов работ не должны выполняться при техническом обслуживании технологического оборудования ГРП и ГРУ? Выберите 2 варианта ответа.",
        answers: [
          "Устранение утечек газа из разъемных соединений технических устройств",
          "Осмотр фильтра и (при необходимости) очистка фильтрующего элемента",
          "Проверка перепада давления",
          "Проверка соответствия режимной карте давления газа на выходе из ГРП и ГРУ",
        ],
        correctAnswers: [
          "Проверка перепада давления",
          "Проверка соответствия режимной карте давления газа на выходе из ГРП и ГРУ",
        ],
      },
      {
        code: "20301242",
        text: "В какие сроки должен проводиться капитальный ремонт ГРП и ГРУ?",
        answers: [
          "Не менее 1 раза в год",
          "Не менее 1 раза в 2 года",
          "В сроки, установленные эксплуатирующей организацией",
          "В сроки, установленные изготовителем",
        ],
        correctAnswers: ["В сроки, установленные изготовителем"],
      },
      {
        code: "20301243",
        text: "Чем должны быть продуты воздухопровод и камера плазменной печи перед зажиганием газовых горелок?",
        answers: ["Кислородом", "Инертным газом", "Воздухом"],
        correctAnswers: ["Воздухом"],
      },
      {
        code: "20301244",
        text: "Чем должна производиться продувка импульсных газовых проводок?",
        answers: ["Паром", "Газом", "Сжатым воздухом", "Всем перечисленным"],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20301245",
        text: "С какой периодичностью должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях, и вне помещений?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в 2 месяца",
          "Не реже 2 раза в пол года",
          "Не реже одного раза в 3 месяца",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20301246",
        text: "Какие действия необходимо осуществить перед ремонтом клапанов (трубопроводов) теплого и холодного концов регенераторов в период остановки ВРУ? Выберите правильный вариант ответа.",
        answers: [
          "Проверка объемной доли кислорода в воздухе",
          'Установка предупреждающего знака безопасности "Не закрывать, работают люди!"',
          "Электрические схемы приводов арматуры должны быть разобраны",
          "Систему автоматического управления регенераторов перевести на ручной режим",
        ],
        correctAnswers: ["Проверка объемной доли кислорода в воздухе"],
      },
      {
        code: "20301247",
        text: "При каком условии ВРУ должна подвергаться обезжириванию?",
        answers: [
          "При достижении концентрации масла в жидком кислороде 0,50 мг/дм",
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше",
          "При достижении концентрации масла в жидком кислороде 0,70 мг/дм и выше",
          "При достижении концентрации масла в жидком кислороде 1,0 мг/дм и выше",
        ],
        correctAnswers: [
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше",
        ],
      },
      {
        code: "20301248",
        text: "Какие установлены сроки обезжиривания ВРУ, работающих по схеме высокого и среднего давлений с блоками комплексной очистки на цеолитах и турбодетандерами, а также по схеме низкого давления?",
        answers: [
          "1 раз в месяц",
          "1 раз в полгода",
          "1 раз в год",
          "Не регламентируются",
        ],
        correctAnswers: ["Не регламентируются"],
      },
      {
        code: "20301249",
        text: "Что из перечисленного не подлежит обезжириванию в процессе эксплуатации ВРУ?",
        answers: [
          "Колонны высокого и низкого давления",
          "Регенераторы(при переработке воздуха, сжимаемого компрессорами со смазываемыми цилиндрами)",
          "Аппараты и коммуникации на потоке жидкого кислорода",
          "Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа",
        ],
        correctAnswers: [
          "Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа",
        ],
      },
      {
        code: "20301250",
        text: "В каком случае арматура, предназначенная для работы с кислородом, не подлежит обезжириванию перед монтажом?",
        answers: [
          "В случае, если детали контактировали с кислородом",
          "В случае, если детали были подвергнуты охлаждению жидким азотом",
          "В любом случае подлежит обезжириванию",
          "В случае, если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка",
        ],
        correctAnswers: [
          "В случае, если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка",
        ],
      },
      {
        code: "20301251",
        text: "Какое из перечисленных требований при необходимости проведения обезжиривания технических устройств и трубопроводов ПРВ растворителями указано неверно?",
        answers: [
          "Доступ в помещение, где хранятся растворители, разрешается только лицам, допущенным к работе с ними",
          "Тару из-под растворителей необходимо плотно закрывать и хранить только в предназначенном для этого помещении или на открытом воздухе",
          "Проливы растворителей на пол не допускаются; случайно пролитый растворитель должен быть немедленно убран с помощью сухих материалов",
          "Перелив растворителей из одного сосуда в другой допускается любым удобным способом при наличии у работающих СИЗ",
        ],
        correctAnswers: [
          "Перелив растворителей из одного сосуда в другой допускается любым удобным способом при наличии у работающих СИЗ",
        ],
      },
      {
        code: "20301252",
        text: "До какой максимальной температуры допускается нагрев поверхностей в помещениях, где производится обезжиривание кислородного оборудования пожаровзрывоопасными растворителями?",
        answers: ["Выше 80 °C", "Выше 100 °C", "Выше 120 °C", "Выше 150 °C"],
        correctAnswers: ["Выше 120 °C"],
      },
      {
        code: "20301253",
        text: " До какой температуры должен отогреваться сосуд, бывший в эксплуатации, перед проведением работ по обезжириванию способом протирки и чем он должен продуваться?",
        answers: [
          "Не выше 10 °C",
          "Не выше 15 °C",
          "Не выше 20 °C",
          "Не ниже 30 °C",
        ],
        correctAnswers: ["Не выше 20 °C"],
      },
      {
        code: "20301254",
        text: "При каком содержании водорода в воздухе производственного помещения по сигналу от автоматического газоанализатора технологическое оборудование этого помещения должно быть остановлено?",
        answers: [
          "Выше 25% нижнего предела взрываемости (1% об.)",
          "Выше 10% нижнего предела взрываемости (1% об.)",
          "Выше 15% нижнего предела взрываемости (1% об.)",
          "Выше 5% нижнего предела взрываемости (1% об.)",
        ],
        correctAnswers: ["Выше 25% нижнего предела взрываемости (1% об.)"],
      },
      {
        code: "20301255",
        text: "При каком содержании газов должна срабатывать световая и звуковая сигнализации, установленные с автоматическими газоанализаторами в помещениях категории А, где обращается водород?",
        answers: [
          "Не более 10% нижнего предела взрываемости",
          "Не более 15% нижнего предела взрываемости",
          "Не более 12% нижнего предела взрываемости",
          "Не более 13% нижнего предела взрываемости",
        ],
        correctAnswers: ["Не более 10% нижнего предела взрываемости"],
      },
      {
        code: "20301256",
        text: "Какие из перечисленных ремонтных работ допускается производить одновременно при ремонте арматуры, расположенной на теплом и холодном концах регенераторов в период остановки ВРУ?",
        answers: [
          "Принудительных клапанов переключения регенераторов",
          "Трехходовых заслонок после регенераторов",
          'Системы "приказного" воздуха переключения регенераторов, или механизма переключения',
          "Ничего из перечисленного",
        ],
        correctAnswers: [
          'Системы "приказного" воздуха переключения регенераторов, или механизма переключения',
        ],
      },
      {
        code: "20301257",
        text: "С каким интервалом должна проверяться объемная доля кислорода в месте проведения ремонтных работ во время нахождения персонала внутри трубопроводов, опорных обечаек регенераторов, в отсеке обратноповоротных клапанов или арматуры?",
        answers: [
          "Не менее 10 минут",
          "Не менее 15 минут",
          "Не менее 25 минут",
          "Не менее 30 минут",
        ],
        correctAnswers: ["Не менее 10 минут"],
      },
      {
        code: "20301258",
        text: "Какое минимальное остаточное давление должны иметь баллоны, подготовленные для наполнения водородом?",
        answers: [
          "Не менее 0,05 МПа",
          "Не менее 0,02 МПа",
          "Не менее 0,01 МПа",
          "Не менее 0,015 МПа",
        ],
        correctAnswers: ["Не менее 0,05 МПа"],
      },
      {
        code: "20301259",
        text: "При какой чистоте водорода должен производиться пуск компрессора для наполнения баллонов?",
        answers: [
          "Не менее 99,7%",
          "Не менее 96,5%",
          "Не менее 98,0%",
          "Не менее 97,7%",
        ],
        correctAnswers: ["Не менее 99,7%"],
      },
      {
        code: "20301260",
        text: "На какой ступени сжатия водородные компрессоры должны иметь предохранительные клапаны?",
        answers: [
          "На первой ступени сжатия",
          "На последней ступени сжатия",
          "На всех ступенях сжатия",
        ],
        correctAnswers: ["На всех ступенях сжатия"],
      },
      {
        code: "20301261",
        text: "Какие требования к компрессорным установкам указаны неверно?",
        answers: [
          "Изготовление, монтаж, наладка, ремонт, испытания и эксплуатация компрессорных установок должны проводиться специализированными в этой области организациями",
          "Компрессор и его электродвигатель должны устанавливаться на фундаментах связанных с конструкциями здания",
          "Для уменьшения динамических нагрузок компрессоры должны устанавливаться на виброизолирующих фундаментах или с устройством амортизаторов (антивибраторов) либо должны приниматься другие меры для уменьшения вибраций",
          "Качество изготовления компрессорных установок должно соответствовать требованиям нормативно-технической документации и документации организации-изготовителя",
        ],
        correctAnswers: [
          "Компрессор и его электродвигатель должны устанавливаться на фундаментах связанных с конструкциями здания",
        ],
      },
      {
        code: "20301262",
        text: "Какие требования к компрессорным установкам указаны верно?",
        answers: [
          "Компрессор и его электродвигатель должны устанавливаться на фундаментах связанных с конструкциями здания",
          "Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь",
          "Допускается хранение ЛВЖ в помещении машинного зала компрессорной установки",
          "Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи.",
        ],
        correctAnswers: [
          "Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи.",
        ],
      },
      {
        code: "20301263",
        text: "В течение какого времени выдерживают испытательное давление в трубопроводе при испытании на прочность, после чего снижают до рабочего давления, при котором производят тщательный осмотр сварных швов (испытание на плотность)?",
        answers: [
          "В течение 10 минут",
          "В течении 5 минут",
          "В течении 30 минут",
          "В течении 15 минут",
        ],
        correctAnswers: ["В течение 10 минут"],
      },
      {
        code: "20301264",
        text: "Какому классу должна соответствовать герметичность арматуры с металлическим уплотнением в затворе, применяемая для установки на трубопроводах водорода?",
        answers: ["Классу А", "Классу Б", "Классу В", "Классу Г"],
        correctAnswers: ["Классу В"],
      },
      {
        code: "20301265",
        text: "Какому классу должна соответствовать герметичность затвора запорной арматуры со средой водорода?",
        answers: ["Классу А", "Классу Б", "Классу В", "Классу Г"],
        correctAnswers: ["Классу А"],
      },
      {
        code: "20301266",
        text: "Какую арматуру не допускается применять на водородопроводах?",
        answers: ["Из серого чугуна", "Из стали", "Из алюминия", "Из меди"],
        correctAnswers: ["Из серого чугуна"],
      },
      {
        code: "20301267",
        text: "Какие требования к монтажу и эксплуатации водородопроводов указаны неверно?",
        answers: [
          "При прокладке трубопроводов водорода следует, использовать бесшовные стальные трубы или трубопроводы из нержавеющей стали, соединенные с применением сварки",
          "Допускается подземная и канальная прокладка трубопроводов водорода",
          "Проектирование, монтаж и эксплуатация водородопроводов, должны производиться в соответствии с требованиями строительных норм и правил, а также нормативно-технической документации для технологических стальных трубопроводов и настоящих Правил",
          "Толщина стенок трубопроводов выбирается при проектировании исходя из расчетного срока эксплуатации, с учетом расчетного давления и прибавки на коррозию",
        ],
        correctAnswers: [
          "Допускается подземная и канальная прокладка трубопроводов водорода",
        ],
      },
      {
        code: "20301268",
        text: "Какая продолжительность пневматических испытаний установлена Правилами безопасности процессов получения или применения металлов после проведения плановых или аварийных остановок водородопроводов?",
        answers: [
          "Продолжительность испытания 1 ч",
          "Продолжительность испытания 2 ч",
          "Продолжительность испытания 3 ч",
          "Продолжительность испытания 4 ч",
        ],
        correctAnswers: ["Продолжительность испытания 1 ч"],
      },
      {
        code: "20301269",
        text: "Через какое время после ввода объекта в эксплуатацию должна проводиться первая выборочная ревизия водородопроводов на вновь осваиваемых производствах?",
        answers: [
          "Не позднее чем через год",
          "Не позднее чем через 2 года",
          "Не позднее чем через 3 года",
          "Не позднее чем через 4 года",
        ],
        correctAnswers: ["Не позднее чем через 4 года"],
      },
      {
        code: "20301270",
        text: "С какой периодичностью должны проводиться испытания на плотность и прочность холодных участков (с температурой до 200 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?",
        answers: [
          "1 раз в 8 лет",
          "1 раз в 5 лет",
          "1 раз в 4 года",
          "1 раз в 2 года",
        ],
        correctAnswers: ["1 раз в 8 лет"],
      },
      {
        code: "20301271",
        text: "С какой периодичностью должны проводиться испытания на плотность и прочность горячих участков (с температурой 200 - 400 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?",
        answers: [
          "1 раз в 2 года",
          "1 раз в 8 лет",
          "1 раз в 5 лет",
          "1 раз в 3 года",
        ],
        correctAnswers: ["1 раз в 5 лет"],
      },
      {
        code: "20301272",
        text: "В каких местах допускается прокладка водородопроводов?",
        answers: [
          "В пределах предприятий преимущественно над поверхностью земли, на стойках и эстакадах",
          "По наружным стенам производственных зданий",
          "Через бытовые, подсобные административно-хозяйственные, складские помещения",
          "Через производственные помещения, не связанные с потреблением водорода",
        ],
        correctAnswers: [
          "В пределах предприятий преимущественно над поверхностью земли, на стойках и эстакадах",
        ],
      },
      {
        code: "20301273",
        text: "Через какие помещения допускается прокладка водородопроводов?",
        answers: [
          "По наружным стенам производственных зданий",
          "В пределах предприятий преимущественно над поверхностью земли, на стойках и эстакадах",
          "Через бытовые, подсобные административно-хозяйственные, складские помещения",
          "Через производственные помещения, не связанные с потреблением водорода",
        ],
        correctAnswers: [
          "В пределах предприятий преимущественно над поверхностью земли, на стойках и эстакадах",
        ],
      },
      {
        code: "20301274",
        text: "Какие требования к размещению запорной арматуры на водородопроводах указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Запорная арматура и отсекающие устройства с дистанционным управлением должны устанавливаться вне здания на расстоянии не менее 3,0 м и не более 50,0 м от места ввода в здание или ближайшего аппарата, стоящего вне здания",
          "В отдельных случаях допускается применение запорной арматуры из ковкого и высокопрочного чугуна при давлении не более 1,0 МПа и температуре от 0 °C до 50 °C, работающей в условиях, не подверженных вибрациям и резко переменного температурного режима",
          "Арматуру из серого чугуна применять на водородопроводах не допускается",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20301275",
        text: "На каких минимальных расстояниях (по горизонтали) до групповых газобаллонных установок допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?",
        answers: ["До 10 м", "До 5,0 м", "До 3,0 м", "До 1,5 м"],
        correctAnswers: ["До 10 м"],
      },
      {
        code: "20301276",
        text: "На каких минимальных расстояниях (по горизонтали) до отдельных баллонов с кислородом и горючими газами допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?",
        answers: ["До 1,0 м", "До 2,0 м", "До 5,0 м", "До 8,0 м"],
        correctAnswers: ["До 5,0 м"],
      },
      {
        code: "20301277",
        text: "В каком положении должны находиться баллоны со сжиженным газом во время работы?",
        answers: [
          "В вертикальном положении",
          "В наклонном положении",
          "В наклонном положении с вентилем, направленным вверх",
          "В вертикальном положении с вентилем, направленным вверх",
        ],
        correctAnswers: ["В вертикальном положении"],
      },
      {
        code: "20301278",
        text: "Какое действие должно быть осуществлено в первую очередь при зажигании ручной горелки или резака?",
        answers: [
          "Продуть рукав для удаления воздуха",
          "Зажечь горючую смесь газов",
          "Открыть вентиль горючего газа",
          "Немного приоткрыть вентиль кислорода",
        ],
        correctAnswers: ["Немного приоткрыть вентиль кислорода"],
      },
      {
        code: "20301279",
        text: "Какое из перечисленных действий допускается при эксплуатации оборудования для газопламенной обработки металлов?",
        answers: [
          "Продувать рукав для горючих газов кислородом и кислородный рукав горючим газом",
          " Взаимозаменять рукава для горючих газов и кислородный рукав при работе",
          "При питании передвижного рабочего поста сжиженным газом от баллона отбирать газ из баллона при снижении в нем рабочего давления ниже требуемого",
          "Устанавливать баллоны со сжатым газом в наклонном положении с вентилем, направленным вверх",
        ],
        correctAnswers: [
          "Устанавливать баллоны со сжатым газом в наклонном положении с вентилем, направленным вверх",
        ],
      },
      {
        code: "20301280",
        text: "Какое из перечисленных требований при эксплуатации взрывопожароопасных объектов указано неверно?",
        answers: [
          "В помещениях категории A проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией должна производиться в сроки, предусмотренные заводской инструкцией, но не реже одного раза в 3 месяца",
          "На каждой двери помещения категории A должны быть указаны категория помещения и класс взрывоопасной зоны",
          "Работы по ремонту электрооборудования в помещениях категории A должны выполняться после обесточивания электросети",
          "В помещениях категории A хранение обтирочных и смазочных материалов допускается в количестве, не превышающем суточной потребности, в металлических, плотно закрывающихся шкафах или ящиках",
        ],
        correctAnswers: [
          "В помещениях категории A проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией должна производиться в сроки, предусмотренные заводской инструкцией, но не реже одного раза в 3 месяца",
        ],
      },
      {
        code: "20301281",
        text: "Какая должна быть минимальная высота забора по периметру территории всего комплекса производства водорода?",
        answers: [
          "Не менее 2,0 м",
          "Не менее 2,5 м",
          "Не менее 2,8 м",
          "Не менее 3,0 м",
        ],
        correctAnswers: ["Не менее 2,0 м"],
      },
      {
        code: "20301282",
        text: "Какие требования к вентиляции помещения, где возможно выделение водорода, указаны верно? Выберите 2 варианта ответа.",
        answers: [
          "В помещениях устанавливается естественная вентиляция из верхней зоны через дефлекторы в объеме не менее однократного в час",
          "В помещениях предусматриваются проемы, закрытые в необходимых случаях решетками",
          "В помещениях устанавливаются аварийная вытяжная система",
          "В помещениях устанавливаются механические приточно-вытяжные общеобменные вентиляции с кратностью воздухообмена не менее 6 в час",
        ],
        correctAnswers: [
          "В помещениях устанавливается естественная вентиляция из верхней зоны через дефлекторы в объеме не менее однократного в час",
          "В помещениях предусматриваются проемы, закрытые в необходимых случаях решетками",
        ],
      },
    ],
    20302: [
      {
        code: "20302000",
        text: "Что должно быть разработано для обеспечения безопасности при освоении новых производств, технологических процессов и технических устройств?",
        answers: [
          "Инструкции по эксплуатации оборудования",
          "Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования",
          "Должностные и производственные инструкции",
        ],
        correctAnswers: [
          "Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования",
        ],
      },
      {
        code: "20302001",
        text: "При каких условиях запрещается эксплуатация плавильных агрегатов?",
        answers: [
          "При незначительном падении давления воды в системе водяного охлаждения",
          "При разгерметизации системы водяного охлаждения этих агрегатов",
          "При незначительном повышении давления воды в системе водяного охлаждения",
        ],
        correctAnswers: [
          "При разгерметизации системы водяного охлаждения этих агрегатов",
        ],
      },
      {
        code: "20302002",
        text: "Что не допускается к загрузке в агрегаты, содержащие расплавленный металл или шлак?",
        answers: [
          "Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции",
          "Загрузка шихты, не прошедшей пиротехнический контроль",
          "Загрузка пакетированного металлолома",
        ],
        correctAnswers: [
          "Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции",
        ],
      },
      {
        code: "20302003",
        text: "Какие требования установлены к загружаемым в печи руде, ферросплавам и другим материалам?",
        answers: [
          "Должны быть выдержаны в помещении не менее смены",
          "Должны быть выдержаны в помещении в течение суток",
          "Должны быть предварительно просушены или прокалены",
        ],
        correctAnswers: ["Должны быть предварительно просушены или прокалены"],
      },
      {
        code: "20302004",
        text: "В какой зоне не допускается нахождение людей?",
        answers: [
          "На расстоянии 15 метров от зоны работы магнитного крана в случае, если кран работает внутри производственного здания",
          "В зоне погрузки грейферными или магнитными кранами",
          "На расстоянии 20 метров от зоны работы грейферного крана в случае, если кран работает внутри производственного здания",
          "Во всех перечисленных зонах",
        ],
        correctAnswers: ["В зоне погрузки грейферными или магнитными кранами"],
      },
      {
        code: "20302005",
        text: "С какой периодичностью должны проверяться на работоспособность вентиляционные системы и состояние ванн травления?",
        answers: ["Ежемесячно", "Ежедневно", "Еженедельно", "Ежедекадно"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "20302006",
        text: "Какие требования к производственным помещениям, оборудованию участков приготовления травильных растворов и травления металла указаны неверно?",
        answers: [
          "Периодичность проведения уборки помещений устанавливается в зависимости от условий их эксплуатации",
          "Не допускается уборка, вызывающая распыление вредных веществ",
          "После работы у ванн травления с химически опасными веществами, используемыми в виде добавок для технологических растворов, вещества должны быть удалены из рабочей зоны на склад",
          "Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность",
        ],
        correctAnswers: [
          "Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность",
        ],
      },
      {
        code: "20302007",
        text: "Какие требования установлены к процессу сливания расплава металла из ковшей и миксеров?",
        answers: [
          "Ось сливного отверстия ковша, установленного под слив, должна совпадать с осью заливочного ковша",
          "Сливание расплава металла из ковшей и миксеров должно быть механизировано",
          "Пол рабочей площадки в местах установки ковшей должен быть сухим",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20302008",
        text: "После чего разрешается производить слив расплава в ковши, вышедшие из ремонта?",
        answers: [
          "Только после тщательного их просушивания и разогрева",
          "Только после смазки смазочным материалом",
          "Только после их увлажнения и охлаждения",
        ],
        correctAnswers: [
          "Только после тщательного их просушивания и разогрева",
        ],
      },
      {
        code: "20302009",
        text: "Какие требования установлены к месту расстановки ковшей, предназначенных для расплава металла?",
        answers: [
          "Должны расставляться только на горизонтальных площадках в любом месте пролета цеха",
          "Должны расставляться только на свободных площадках пролета цеха",
          "Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства",
        ],
        correctAnswers: [
          "Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства",
        ],
      },
      {
        code: "20302010",
        text: "Какие требования установлены к состоянию вакуум-ковшей, тиглей, коробов, кристаллизаторов, изложниц и других емкостей для расплава перед заливкой в них расплава в электролитическом производстве магния?",
        answers: [
          "Должны быть предварительно охлаждены",
          "Должны быть очищены от мусора",
          "Должны быть выдержаны в помещении в течение суток",
        ],
        correctAnswers: ["Должны быть очищены от мусора"],
      },
      {
        code: "20302011",
        text: "Какие требования установлены к фурмам для продувки жидкого металла газами?",
        answers: [
          "Должны храниться в специальном помещении не менее 2 суток перед их установкой",
          "Должны быть защищены специальными экранами от нагревания теплоизлучением",
          "Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями",
        ],
        correctAnswers: [
          "Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями",
        ],
      },
      {
        code: "20302012",
        text: "От чего должны быть защищены составные части производственного оборудования, в том числе энергетические трубопроводы, рукава подачи природного газа, кислорода, мазута, воздуха, масла, воды, троллеи для питания электрического оборудования тележек, перевозящих расплавы металлов, и электрические кабели?",
        answers: [
          "Только от возможного попадания на них расплава",
          "Только от тепловых воздействий",
          "Только от механических воздействий",
          "От всего перечисленного",
        ],
        correctAnswers: ["От всего перечисленного"],
      },
      {
        code: "20302013",
        text: "Какие требования установлены к инструментам, приспособлениям и оснастке, используемым для ведения технологии или обслуживания оборудования?",
        answers: [
          "Должны находиться в исправном состоянии",
          "Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними",
          "Должны быть предназначены для выполнения любых работ",
        ],
        correctAnswers: [
          "Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними",
        ],
      },
      {
        code: "20302014",
        text: "Какие требования установлены к инструментам и приспособлениям, используемым во взрывопожароопасных зонах и помещениях?",
        answers: [
          "Не должны давать искры при работе с ними",
          "Должны быть изготовлены из легированной стали",
          "Должны быть изготовлены из чугуна",
        ],
        correctAnswers: ["Не должны давать искры при работе с ними"],
      },
      {
        code: "20302015",
        text: "Какие требования установлены к установке расходных баков с мазутом?",
        answers: [
          "Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением",
          "Должны быть только установлены на расстоянии не менее 3 м от печей",
          "Должны быть установлены на расстоянии не менее 2 м от печей и должны быть защищены паровой завесой",
        ],
        correctAnswers: [
          "Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением",
        ],
      },
      {
        code: "20302016",
        text: "Чему подлежат производственные емкости (ковши, шлаковые чаши, совки, кюбели, тара, корзины) после изготовления или ремонта?",
        answers: [
          "Проверке состояния",
          "Механическим испытаниям",
          "Техническому освидетельствованию",
        ],
        correctAnswers: ["Проверке состояния"],
      },
      {
        code: "20302017",
        text: "Какое требование к устройству внутренних производственных помещений, находящихся на производственной площадке, включающей технологию с расплавами металлов, имеющей опасность аварии или травмирования работников, указано неверно?",
        answers: [
          "Помещения пультов управления должны иметь вытяжную вентиляцию",
          "Производственные помещения должны иметь защиту от воздействия теплового излучения",
          "Окна пультов управления, в которые возможно попадание брызг расплава, должны оборудоваться в соответствии с проектом",
          "Производственные помещения должны иметь не менее 2 выходов, расположенных с учетом аварийной эвакуации работников; исполнением дверей помещения с открыванием наружу и не иметь внутренних запоров",
        ],
        correctAnswers: [
          "Помещения пультов управления должны иметь вытяжную вентиляцию",
        ],
      },
      {
        code: "20302018",
        text: "Как должны быть расположены пульты управления агрегатами?",
        answers: [
          "В непосредственной близости от агрегатов",
          "На расстоянии не менее 5 м от агрегатов",
          "В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке",
        ],
        correctAnswers: [
          "В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке",
        ],
      },
      {
        code: "20302019",
        text: "Что должны иметь закрома и ямы?",
        answers: [
          "Должны иметь вдоль периметра пешеходные проходы шириной 0,8 м",
          "Должны иметь вдоль периметра пешеходные проходы шириной 1,0 м",
          "Со всех сторон должны иметь ограждение",
        ],
        correctAnswers: ["Со всех сторон должны иметь ограждение"],
      },
      {
        code: "20302020",
        text: "Что должно быть предусмотрено при проектировании конвейеров для подачи материалов? Выберете правильный вариант ответа.",
        answers: [
          "Только ограждения приводных, натяжных и отклоняющих барабанов, натяжных устройств, канатов и блоков натяжных устройств, ременных, червячных, муфтовых и других передач",
          "Только предохраняющие устройства, отключающие приводы при перегрузке",
          "Только исключение падения транспортируемых материалов",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20302021",
        text: "Как должна устраняться пробуксовка ленты конвейера?",
        answers: [
          "Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты",
          "Должна устраняться при помощи ручной регулировки",
          "Должна устраняться увеличением трения между тянущим барабаном и лентой с использованием специальных материалов",
        ],
        correctAnswers: [
          "Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты",
        ],
      },
      {
        code: "20302022",
        text: "Что должно быть исключено в коммуникациях системы транспортирования взрывопожароопасных веществ и материалов?",
        answers: [
          "Образование взрывоопасной среды",
          "Попадание воздушной среды",
          "Возможность разгерметизации системы",
        ],
        correctAnswers: ["Образование взрывоопасной среды"],
      },
      {
        code: "20302023",
        text: "На что должна быть проверена система пневмотранспорта перед вводом в эксплуатацию?",
        answers: [
          "На плотность под рабочим давлением",
          "На прочность под рабочим давлением",
          "На герметичность при рабочем давлении",
        ],
        correctAnswers: ["На плотность под рабочим давлением"],
      },
      {
        code: "20302024",
        text: "Чем должны быть оборудованы шлаковозы?",
        answers: [
          "Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением",
          "Механизмами кантования (поворота) чаши с ручным пневматическим приводом и автоматическим управлением",
          "Механизмами кантования (поворота) чаши с ручным гидравлическим приводом",
        ],
        correctAnswers: [
          "Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением",
        ],
      },
      {
        code: "20302025",
        text: "Что должно иметься на шлаковом отвале?",
        answers: [
          "Двусторонняя громкоговорящая связь с диспетчерской службой",
          "Телефонная или радиосвязь с диспетчерской службой производства",
          "Мобильная связь с диспетчерской службой предприятия",
        ],
        correctAnswers: [
          "Телефонная или радиосвязь с диспетчерской службой производства",
        ],
      },
      {
        code: "20302026",
        text: "Какие шпалы должны применяться на железнодорожных путях шлакового отвала?",
        answers: [
          "Деревянные шпалы",
          "Деревянные шпалы, пропитанные креозотом",
          "Огнестойкие шпалы",
        ],
        correctAnswers: ["Огнестойкие шпалы"],
      },
      {
        code: "20302027",
        text: "Какие требования установлены к кабине завалочной машины?",
        answers: [
          "Должна быть удобной в эксплуатации",
          "Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака",
          "Конструкция завалочной машины должна обеспечивать устойчивость при выполнении операций",
        ],
        correctAnswers: [
          "Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака",
        ],
      },
      {
        code: "20302028",
        text: "Чем должны быть оборудованы места пересечения железнодорожных путей для подачи составов ковшей с жидким чугуном с путями для подачи шихтовых материалов?",
        answers: [
          "Должны быть оборудованы постами со стрелочниками",
          "Должны быть оборудованы светофорами",
          "Должны быть оборудованы автоматической сигнализацией",
        ],
        correctAnswers: [
          "Должны быть оборудованы автоматической сигнализацией",
        ],
      },
      {
        code: "20302029",
        text: "Какие требования установлены к оборудованию производственных помещений, в которых возможны воспламенения одежды или химические ожоги?",
        answers: [
          "Должны быть в наличии огнетушители",
          "Должны быть установлены ящики с песком",
          "Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
        correctAnswers: [
          "Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
      },
      {
        code: "20302030",
        text: "Какие требования установлены к системам освещения во взрывоопасных помещениях?",
        answers: [
          "Должны предусматриваться системы освещения во взрывобезопасном исполнении",
          "Должны предусматриваться системы освещения, работающие от напряжения 30 В",
          "Должны предусматриваться системы освещения, работающие от напряжения не более 24 В",
        ],
        correctAnswers: [
          "Должны предусматриваться системы освещения во взрывобезопасном исполнении",
        ],
      },
      {
        code: "20302031",
        text: "Что должна обеспечить система светозвуковой сигнализации?",
        answers: [
          "Оповещение эксплуатационного персонала о начале и окончании смены",
          "Оповещение эксплуатационного персонала о приближении технологического транспорта",
          "Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств",
        ],
        correctAnswers: [
          "Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств",
        ],
      },
      {
        code: "20302032",
        text: "Какие требования установлены к расположению КИПиА?",
        answers: [
          "Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования",
          "Приборы должны устанавливаться в непосредственной близости к оборудованию",
          "Приборы должны устанавливаться на расстоянии не менее 5 м от оборудования",
        ],
        correctAnswers: [
          "Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования",
        ],
      },
      {
        code: "20302033",
        text: "Какие требования установлены к проверке и испытанию приборов во взрывопожароопасных и пожароопасных помещениях?",
        answers: [
          "Проверка должна производиться в условиях, исключающие искрообразование",
          "Проверка должна осуществляться только в специально выделенном для этого помещении",
          "Проверка может производиться непосредственно во взрывопожароопасных и пожароопасных помещениях",
        ],
        correctAnswers: [
          "Проверка должна производиться в условиях, исключающие искрообразование",
        ],
      },
      {
        code: "20302034",
        text: "Какие требования предъявляются к устройству и эксплуатации печей для обжига шихтовых материалов и концентратов?",
        answers: [
          "Допускается эксплуатация печей при остаточном разрежении предельно допустимого значения на 5%",
          "Допускается эксплуатация печей при остаточном разрежении предельно допустимого значения на 15%",
          "Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции",
        ],
        correctAnswers: [
          "Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции",
        ],
      },
      {
        code: "20302035",
        text: "Какие требования установлены к хранению стержней, шаров, футеровки, запасных деталей и приспособлений?",
        answers: [
          "Должно быть предусмотрено в специально отведенных местах на стеллажах или в таре",
          "Должно быть предусмотрено на свободных площадках, обеспечивающих свободный проход",
          "Не регламентируется",
        ],
        correctAnswers: [
          "Должно быть предусмотрено в специально отведенных местах на стеллажах или в таре",
        ],
      },
      {
        code: "20302036",
        text: "Какие требования установлены к проведению ремонтных работ внутри нагретых технических устройств?",
        answers: [
          "Проведение ремонтных работ разрешается после их продувки и снижения температуры воздуха в них до 50 °C",
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 45 °C",
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C",
        ],
        correctAnswers: [
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C",
        ],
      },
      {
        code: "20302037",
        text: "При наличии каких дефектов ковшей их эксплуатация не допускается?",
        answers: [
          "При наличии трещин в стенках и местах крепления цапф",
          "При потере формы ковша вследствие деформации",
          "При поврежденной футеровке",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20302038",
        text: 'Какие из перечисленных печей должны быть оборудованы системами автоматического контроля и регулирования соотношения "шихта - кислород" и отключения дутья при прекращении подачи шихты, а также при отключении подачи природного газа, мазута, пылеугольного топлива, подаваемых на восстановление сернистого ангидрида в технологических газах? Выберите правильный вариант ответа.',
        answers: [
          "Только печи взвешенной плавки",
          "Только печи кислородно-факельной плавки",
          "Только печи кислородно-взвешенной циклонной плавки с электротермическим окончанием",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20302039",
        text: "Сколько электроизоляционных разрывов должны иметь металлические каркасы желобов, расположенные поперек цеха производства меди?",
        answers: [
          "Между каждыми тремя ваннами",
          "Между каждыми двумя ваннами",
          "Между каждыми двумя рядами ванн",
        ],
        correctAnswers: ["Между каждыми двумя ваннами"],
      },
      {
        code: "20302040",
        text: "Какие из перечисленных действий должны производиться только после снятия электропитания в цехе производства меди? Выберите правильный вариант ответа.",
        answers: [
          "Только подключение электролизных ванн к ошиновке",
          "Только отключение электролизных ванн от ошиновке",
          "Только замена электродов на регенеративных ваннах",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20302041",
        text: "Какие из перечисленных требований к эксплуатации электролизных цехов указаны неверно?",
        answers: [
          "В электролизных цехах и отделениях должен быть предусмотрен контроль величины сопротивления изоляции технических устройств",
          "Схема контроля и периодичность замеров должны определяться проектом",
          "Электролизные цехи и отделения должны быть оборудованы дистанционными средствами аварийного отключения электропитания серии ванн с пульта управления цеха (отделения)",
          "Электролизные ванны (серии) должны быть пронумерованы",
        ],
        correctAnswers: [
          "Схема контроля и периодичность замеров должны определяться проектом",
        ],
      },
      {
        code: "20302042",
        text: "Какое оборудование из перечисленного должно быть защищено от статического электричества?",
        answers: [
          "Только оборудования для сушки, рассева, перегрузки и затаривания никелевых, медных и кобальтовых порошков",
          "Только оборудования для сушки, рассева, пересыпки и затаривания порошков драгоценных металлов",
          "Только оборудования для систем аспирации",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20302043",
        text: "Какой должна быть высота стоп анодных остатков, укладываемых на транспортные тележки?",
        answers: [
          "Не более 1,0 м",
          "Не более 1,5 м",
          "Не более 2,0 м",
          "Не более 3,0 м",
        ],
        correctAnswers: ["Не более 1,0 м"],
      },
      {
        code: "20302044",
        text: "Какие из перечисленных ограждающих конструкций не относятся к легкосбрасываемым?",
        answers: [
          "Конструкции асбоцементных, алюминиевых и стальных листов",
          "Оконные переплеты заполнены обычным оконным стеклом толщиной 3, 4 и 5 мм, площадью не менее соответственно 0,8, 1 и 1,5 м2",
          "Фонарные переплеты",
          "Стекло с армированием",
        ],
        correctAnswers: ["Стекло с армированием"],
      },
      {
        code: "20302045",
        text: "Какой должна быть ширина свободного прохода между щитом управления вентилями и ближайшими стенами наполнительной?",
        answers: [
          "Не менее 0,8 м",
          "Не менее 0,5 м",
          "Не менее 0,4 м",
          "Не менее 0,7 м",
        ],
        correctAnswers: ["Не менее 0,8 м"],
      },
      {
        code: "20302046",
        text: "До каких пор должна осуществляться продувка азотом перед пуском в работу установки по производству реформерного газа для производства металлизованного горячевосстановленного железа?",
        answers: [
          "Пока на участке продувки кислорода будет не более 1%",
          "Пока на участке продувки кислорода будет не более 2%",
          "Пока на участке продувки кислорода будет не более 1,5%",
          "Пока на участке продувки кислорода будет не более 3%",
        ],
        correctAnswers: [
          "Пока на участке продувки кислорода будет не более 1%",
        ],
      },
      {
        code: "20302047",
        text: "Какое из перечисленных требований к установке по производству реформерного газа указано неверно?",
        answers: [
          "Продувка азотом должна осуществляться до тех пор, пока на участке продувки кислорода будет не более 3% кислорода",
          "Установка по производству реформерного газа для производства металлизованного горячевосстановленного железа должна иметь подвод азота для его продувки перед пуском в работу",
          "Для регулирования давления реформерного газа после холодильника и сброса его на свечу должны быть установлены регулирующие клапаны, работающие в ручном или автоматическом режиме",
          "Перед розжигом горелок реформера должна быть произведена продувка его топочного пространства",
        ],
        correctAnswers: [
          "Продувка азотом должна осуществляться до тех пор, пока на участке продувки кислорода будет не более 3% кислорода",
        ],
      },
      {
        code: "20302048",
        text: "Где не допускается размещение операторных помещений?",
        answers: [
          "В отдельных щитовых помещениях (встроенных или пристроенных)",
          "В смежных помещениях с взрывоопасными зонами",
          "В отдельно стоящих зданиях",
          "В производственных помещениях",
        ],
        correctAnswers: ["В производственных помещениях"],
      },
      {
        code: "20302049",
        text: "Какие требования к монтажу и эксплуатации водородопроводов указаны неверно?",
        answers: [
          "При прокладке трубопроводов водорода следует использовать бесшовные стальные трубы или трубопроводы из нержавеющей стали, соединенные с применением сварки",
          "Допускается подземная и канальная прокладка трубопроводов водорода",
          "Проектирование, монтаж и эксплуатация водородопроводов должны производиться в соответствии с требованиями строительных норм и правил, а также нормативно-технической документации для технологических стальных трубопроводов и Правил безопасности процессов получения или применения металлов",
          "Толщина стенок трубопроводов выбирается при проектировании исходя из расчетного срока эксплуатации, с учетом расчетного давления и прибавки на коррозию",
        ],
        correctAnswers: [
          "Допускается подземная и канальная прокладка трубопроводов водорода",
        ],
      },
      {
        code: "20302050",
        text: "Какая продолжительность пневматических испытаний установлена Правилами безопасности процессов получения или применения металлов после проведения плановых или аварийных остановок водородопроводов?",
        answers: ["1 час", "1,5 часа", "2 часа", "3 часа"],
        correctAnswers: ["1 час"],
      },
      {
        code: "20302051",
        text: "Через какое время после ввода объекта в эксплуатацию должна проводиться первая выборочная ревизия водородопроводов на вновь осваиваемых производствах?",
        answers: [
          "Не позднее чем через 1 год",
          "Не позднее чем через 4 года",
          "Не позднее чем через 3 года",
          "Не позднее чем через 2 года",
        ],
        correctAnswers: ["Не позднее чем через 4 года"],
      },
      {
        code: "20302052",
        text: "На каких минимальных расстояниях (по горизонтали) до групповых газобаллонных установок допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?",
        answers: ["10 м", "5,0 м", "3,0 м", "1,5 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "20302053",
        text: "На каких минимальных расстояниях (по горизонтали) до отдельных баллонов с кислородом и горючими газами допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?",
        answers: ["10 м", "3,0 м", "1,5 м", "5,0 м"],
        correctAnswers: ["5,0 м"],
      },
      {
        code: "20302054",
        text: "Какое действие должно быть осуществлено в первую очередь при зажигании ручной горелки или резака?",
        answers: [
          "Немного приоткрыть вентиль кислорода",
          "Открыть вентиль горючего газа",
          "Зажечь горючую смесь газов",
          "Кратковременно продуть рукав для удаления воздуха",
        ],
        correctAnswers: ["Немного приоткрыть вентиль кислорода"],
      },
      {
        code: "20302055",
        text: "Какое из перечисленных действий допускается при эксплуатации оборудования для газопламенной обработки металлов? Выберите правильный вариант ответа.",
        answers: [
          "Продувать рукав для горючих газов кислородом и кислородный рукав горючим газом",
          "Взаимозаменять рукава при работе",
          "При питании передвижного рабочего поста сжиженным газом от баллона отбирать газ из баллона при снижении в нем рабочего давления ниже требуемого",
          "Устанавливать баллоны со сжатыми газами в наклонном положении с вентилем, направленным вверх",
        ],
        correctAnswers: [
          "Устанавливать баллоны со сжатыми газами в наклонном положении с вентилем, направленным вверх",
        ],
      },
      {
        code: "20302056",
        text: "С какой периодичностью должен производиться текущий ремонт оборудования с разборкой регуляторов давления, предохранительных клапанов и фильтров ГРП и ГРУ, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания?",
        answers: [
          "Не менее 1 раза в год",
          "Не менее 1 раза в 2 года",
          "Не менее 2 раз в 5 лет",
          "Не менее 1 раза в 3 года",
        ],
        correctAnswers: ["Не менее 1 раза в год"],
      },
      {
        code: "20302057",
        text: " При каком давлении должна производиться настройка срабатывания предохранительно-запорных клапанов при их наличии в ГРП (ГРУ)?",
        answers: [
          "При давлении на 15% больше расчетного",
          "При давлении на 10% больше расчетного",
          "При давлении на 20% больше расчетного",
          "При давлении на 5% больше расчетного",
        ],
        correctAnswers: ["При давлении на 15% больше расчетного"],
      },
      {
        code: "20302058",
        text: "В каком случае допускается подача потребителям газа по обводной линии (байпасу) ГРП и ГРУ?",
        answers: [
          "На время ревизии или ремонта оборудования при условии постоянного нахождения в ГРП или у ГРУ дежурного",
          "В любом случае",
          "Ни в каком случае",
          "Если было проведено испытание сбросного предохранительного клапана или манометра",
        ],
        correctAnswers: [
          "На время ревизии или ремонта оборудования при условии постоянного нахождения в ГРП или у ГРУ дежурного",
        ],
      },
      {
        code: "20302059",
        text: "Какие виды работ должны выполняться не реже одного раза в 6 месяцев при эксплуатации ГРП и ГРУ?",
        answers: [
          "Техническое обслуживание",
          "Текущий ремонт",
          "Проверка срабатывания предохранительных запорных и сбросных клапанов",
          "Капитальный ремонт при замене оборудования",
        ],
        correctAnswers: ["Техническое обслуживание"],
      },
      {
        code: "20302060",
        text: "Какие из перечисленных видов работ не должны выполняться при техническом обслуживании технологического оборудования ГРП и ГРУ? Выберите 2 варианта ответа.",
        answers: [
          "Устранение утечек газа из разъемных соединений технических устройств",
          "Осмотр фильтра и (при необходимости) очистка фильтрующего элемента",
          "Замена изношенных деталей технических устройств",
          "Устранение повреждений газопроводов обвязки технологического оборудования",
        ],
        correctAnswers: [
          "Замена изношенных деталей технических устройств",
          "Устранение повреждений газопроводов обвязки технологического оборудования",
        ],
      },
      {
        code: "20302061",
        text: "В какие сроки должен проводиться капитальный ремонт ГРП и ГРУ?",
        answers: [
          "Установленные изготовителем",
          "Установленные эксплуатирующей организацией",
          "Установленные инструкцией",
        ],
        correctAnswers: ["Установленные изготовителем"],
      },
      {
        code: "20302062",
        text: "Чем должна производиться продувка импульсных газовых проводок?",
        answers: ["Паром", "Сжатым воздухом", "Газом", "Всем перечисленным"],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20302063",
        text: "Как часто должна проверяться надежность крепления головок электродов?",
        answers: ["Правилами не регламентируется", "Ежедневно", "Еженедельно"],
        correctAnswers: ["Правилами не регламентируется"],
      },
      {
        code: "20302064",
        text: "В каком положении должна быть крышка завалочного окна во время работы газокислородной горелки?",
        answers: [
          "Крышка должна быть закрыта",
          "Крышка должна быть приоткрыта для наблюдения за работой горелки",
          "Крышка должна быть снята",
        ],
        correctAnswers: ["Крышка должна быть закрыта"],
      },
      {
        code: "20302065",
        text: "Когда должны выполняться работы по очистке пространства под печью, а также приямков от шлака и мусора?",
        answers: [
          "Работы должны выполняться только до начала плавления шихты",
          "Работы должны выполняться после выпуска металла",
          "Работы должны выполняться только в начале плавления шихты до образования значительного количества жидкого металла",
        ],
        correctAnswers: [
          "Работы должны выполняться только в начале плавления шихты до образования значительного количества жидкого металла",
        ],
      },
      {
        code: "20302066",
        text: "Какому давлению гидравлического испытания должны подвергаться водоохлаждаемые элементы печей перед их установкой?",
        answers: [
          "На 2,5 величины рабочего давления охлаждающей воды",
          "На 2,0 величины рабочего давления охлаждающей воды",
          "На 1,5 величины рабочего давления охлаждающей воды",
        ],
        correctAnswers: ["На 1,5 величины рабочего давления охлаждающей воды"],
      },
      {
        code: "20302067",
        text: "Каким гидравлическим давлением должны испытываться на прочность и плотность трубки системы охлаждения индуктора?",
        answers: [
          "На 1,5 величины рабочего давления охлаждающей воды",
          "На 2,5 величины рабочего давления охлаждающей воды",
          "На 2,0 величины рабочего давления охлаждающей воды",
        ],
        correctAnswers: ["На 1,5 величины рабочего давления охлаждающей воды"],
      },
      {
        code: "20302068",
        text: "При каком давлении должен срабатывать предохранительный клапан вакуумной камеры печи?",
        answers: [
          "При давлении 0,01 МПа (0,1 кгс/см²)",
          "При давлении 0,03 МПа (0,3 кгс/см²)",
          "При давлении 0,05 МПа (0,5 кгс/см²)",
        ],
        correctAnswers: ["При давлении 0,01 МПа (0,1 кгс/см²)"],
      },
      {
        code: "20302069",
        text: "Как должны производиться очистка боровов и ремонтные работы внутри них?",
        answers: [
          "По наряду-допуску при температуре воздуха внутри борова не выше 50 °С",
          "По распоряжению при температуре воздуха внутри борова не выше 45 °С",
          "По наряду-допуску при полной остановке печи",
        ],
        correctAnswers: ["По наряду-допуску при полной остановке печи"],
      },
      {
        code: "20302070",
        text: "Какие требования установлены к снабжению литейных производств щелочью?",
        answers: [
          "Должны снабжаться сульфитной щелочью",
          "Должны снабжаться щелочью в виде порошка",
          "Должны снабжаться кристаллизованной щелочью в металлических бочках",
        ],
        correctAnswers: ["Должны снабжаться сульфитной щелочью"],
      },
      {
        code: "20302071",
        text: "Чем должны защищаться стальные канаты и цепи грузоподъемных устройств, предназначенные для перемещения ковшей с расплавленным металлом, а также траверсы самих ковшей?",
        answers: [
          "Должны защищаться специальным смазочным материалом, защищающим от воздействия лучистого тепла",
          "Должны покрываться огнестойкими составами для защиты от воздействия тепловой нагрузки",
          "Должны защищаться кожухами от воздействия лучистого тепла",
        ],
        correctAnswers: [
          "Должны защищаться кожухами от воздействия лучистого тепла",
        ],
      },
      {
        code: "20302072",
        text: "В каких случаях не допускается вторичное использование ковша для заливки или разливки металла?",
        answers: [
          "Без предварительной замены стопора и стакана",
          "Без предварительной замены футеровки",
          "Только без предварительной замены стопора",
        ],
        correctAnswers: ["Без предварительной замены стопора и стакана"],
      },
      {
        code: "20302073",
        text: "Что должно быть у каждого плавильного агрегата с выпуском металла через летку?",
        answers: [
          "Две штанги длиной не менее 1 м и запасные пробки для закрывания леток",
          "Две штанги длиной не менее 1,5 м и запасные пробки для закрывания леток",
          "Три штанги длиной не менее 2 м и запасные пробки для закрывания леток",
        ],
        correctAnswers: [
          "Две штанги длиной не менее 1,5 м и запасные пробки для закрывания леток",
        ],
      },
      {
        code: "20302074",
        text: "Какие требования установлены к ведению взрывных работ, хранению, транспортированию взрывчатых материалов и эксплуатации броневых ям?",
        answers: [
          "Работы должны производиться в соответствии с технической документацией, согласованной с территориальным органом Ростехнадзора",
          "Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах",
          "Работы должны производиться специально обученным цеховым обслуживающим персоналом",
        ],
        correctAnswers: [
          "Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах",
        ],
      },
      {
        code: "20302075",
        text: "Какое из перечисленных требований при производстве глинозема указано неверно?",
        answers: [
          "Приводной механизм вращения печей спекания и кальцинации должен быть оборудован резервным двигателем с независимым источником питания",
          "На газоходах отходящих газов печей спекания и кальцинации должны быть установлены автоматические газоанализаторы для контроля содержания в них окиси углерода",
          'Допускается открывание люков на топках и газораспределительных камерах во время работы печей "кипящего слоя" в присутствии наблюдающего',
          "При производстве работ на миксерах, печах и хлораторах с применением грузоподъемных механизмов, а также при заливке, перемешивании расплава и удалении шлама напряжение с электродов должно быть снято",
        ],
        correctAnswers: [
          'Допускается открывание люков на топках и газораспределительных камерах во время работы печей "кипящего слоя" в присутствии наблюдающего',
        ],
      },
      {
        code: "20302076",
        text: "Какое из перечисленных требований безопасности при производстве никеля, меди и кобальта, медных порошков, медного и никелевого купороса и антисептика указаны верно?",
        answers: [
          "На печах стационарного типа должен предусматриваться и поддерживаться в рабочем состоянии резервный шпур для выпуска расплава",
          "Все операции по замене шпуровых плит, рам и текущий ремонт шпуровой кладки должны производиться под наблюдением лица, назначенного распоряжением по цеху ответственным за проведение этих работ",
          "Операции закрывания штейновых и шлаковых шпуров, сифонных отверстий, леток и шлаковых окоп плавильных печей должны быть механизированы",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20302077",
        text: "Какое из перечисленных требований безопасности при производстве никеля, меди и кобальта, медных порошков, медного и никелевого купороса и антисептика указано неверно?",
        answers: [
          "Допускается разгрузка горячих корок из ковшей на сырые площадки",
          "Температура печной кладки должна контролироваться. Места установки термопар определяются проектом",
          "Для удаления корок из ковшей, чаш и погрузки их с помощью мостового крана в цехе должно быть отведено специальное место",
          "Все перечисленные",
        ],
        correctAnswers: [
          "Допускается разгрузка горячих корок из ковшей на сырые площадки",
        ],
      },
      {
        code: "20302078",
        text: "Какое требование к площадкам обслуживания электропечей при производстве никеля, меди и кобальта, медных порошков, медного и никелевого купороса, антисептика указано неверно?",
        answers: [
          "На площадках обслуживания электропечей должна быть предусмотрена световая сигнализация, предупреждающая персонал о том, что агрегат находится под напряжением",
          "Световая сигнализация должна быть сблокирована с высоковольтным выключателем",
          "Перед включением печи в работу должен подаваться предупредительный звуковой сигнал",
          "Все перечисленные требования указаны верно",
        ],
        correctAnswers: ["Все перечисленные требования указаны верно"],
      },
      {
        code: "20302079",
        text: "Какое количество ступеней изоляции от заземлителя должен иметь крюк крана или другого подъемного устройства, используемого для загрузки электродной массы без отключения печи?",
        answers: [
          "Не менее двух последовательных ступеней",
          "Одну ступень",
          "Не более двух последовательных ступеней",
          "Не регламентируется",
        ],
        correctAnswers: ["Не менее двух последовательных ступеней"],
      },
      {
        code: "20302080",
        text: "Какой допускается максимальный износ цапф конвертеров во всех измерениях в случае применения подшипников скольжения?",
        answers: [
          "12% их первоначальных размеров",
          "10% их первоначальных размеров",
          "15% их первоначальных размеров",
          "8% их первоначальных размеров",
        ],
        correctAnswers: ["10% их первоначальных размеров"],
      },
      {
        code: "20302081",
        text: "Какая допускается максимальная скорость движения транспорта для перевозки шлака на сливных путях, переходах, неохраняемых переездах и в местах маневрирования составов?",
        answers: ["5 км/ч", "7 км/ч", "10 км/ч", "12 км/ч"],
        correctAnswers: ["5 км/ч"],
      },
      {
        code: "20302082",
        text: "Каким документом должно определяться количество одновременно подтягиваемых вагонеток при работе на электрошпиле?",
        answers: [
          "Планом производства работ",
          "Техническим регламентом",
          "Технологической инструкцией",
          "Правилами безопасности процессов получения или применения металлов",
        ],
        correctAnswers: ["Технологической инструкцией"],
      },
      {
        code: "20302083",
        text: "Что из перечисленного должно предусматриваться технологической инструкцией?",
        answers: [
          "Только порядок эксплуатации на автоклавных установках",
          "Только порядок подачи воздуха на окисление паромазутной смеси или газа на восстановление",
          "Только порядок и сроки проверки исправности арматуры, контрольно-измерительных приборов и предохранительных устройств",
          "Только порядок загрузки, перемешивания и выгрузки материала из камерных печей, а также порядок осмотра и ремонта печей",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20302084",
        text: "При каком содержании водорода в воздухе производственных помещений (по объему) технологическое оборудование, работающее в этом помещении, должно быть остановлено?",
        answers: ["Более 1%", "Более 0,8%", "Более 0,5%", "Более 0,3%"],
        correctAnswers: ["Более 1%"],
      },
      {
        code: "20302085",
        text: "Каким давлением необходимо испытывать на плотность технологические аппараты, работающие под давлением водорода ниже 0,07 МПа (0,7 кгс/см²), после капитального ремонта и вновь установленные перед пуском в эксплуатацию?",
        answers: [
          "Составляющим 1,20 Pраб, но не более 0,2 МПа (2 кгс/см²)",
          "Составляющим 1,25 Pраб, но не более 0,1 МПа (1 кгс/см²)",
          "Составляющим 1,10 Pраб, но не более 0,3 МПа (3 кгс/см²)",
          "Составляющим 2,0 Pраб, но не более 0,4 МПа (4 кгс/см²)",
        ],
        correctAnswers: [
          "Составляющим 1,25 Pраб, но не более 0,1 МПа (1 кгс/см²)",
        ],
      },
      {
        code: "20302086",
        text: "Какие требования безопасности при производстве никеля, меди и кобальта, медных порошков, медного и никелевого купороса, антисептика указаны верно?",
        answers: [
          "Персонал, работающий в помещениях сушки, прокалки и восстановления порошка в атмосфере водорода (восстановителе), должен иметь переносные газоанализаторы для контроля содержания водорода в атмосфере помещений",
          "После проведения испытаний на плотность окисью углерода технические устройства и трубопроводы перед пуском в работу должны заполняться азотом",
          "Реакционные печи восстановления закиси никеля и газоходы должны быть снабжены взрывными предохранительными устройствами",
          "При производстве никелевого порошка карбонильным способом технические устройства и трубопроводы перед подачей в них токсичных и взрывоопасных веществ должны быть продуты сжатым воздухом",
        ],
        correctAnswers: [
          "Реакционные печи восстановления закиси никеля и газоходы должны быть снабжены взрывными предохранительными устройствами",
        ],
      },
      {
        code: "20302087",
        text: "Какие из перечисленных мест должны быть оборудованы аспирационными установками?",
        answers: [
          "Только места пересыпки измельченного медного порошка",
          "Только места пересыпки измельченного кобальтового порошка",
          "Только места пересыпки измельченного никелевого порошка",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20302088",
        text: "Какие технические устройства должны быть защищены от статического электричества?",
        answers: [
          "Только технические устройства для сушки и рассева медных порошков",
          "Только технические устройства для перегрузки и затаривания никелевых порошков",
          "Только технические устройства для перегрузки и затаривания медных и кобальтовых порошков",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20302089",
        text: "Какие из перечисленных действий при осуществлении процесса электролиза никеля, меди и кобальта указаны верно?",
        answers: [
          "Подключение и отключение электролизных ванн к ошиновке, замена электродов на регенеративных ваннах производятся только после снятия электропитания",
          "Обслуживание регенеративных ванн производится с использованием неэлектропроводного инструмента",
          "Регенеративные ванны оборудуются вытяжной системой или поверхность электролита в них покрывается защитным слоем",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20302090",
        text: 'Какой напорный бак должен быть установлен в печи "Аусмелт" на случай отключения электроснабжения?',
        answers: [
          "Обеспечивающий подачу воды в течение 5 минут с момента отказа системы циркуляционного водяного охлаждения",
          "Обеспечивающий подачу воды в течение 10 минут с момента отказа системы циркуляционного водяного охлаждения",
          "Обеспечивающий подачу воды в течение 15 минут с момента отказа системы циркуляционного водяного охлаждения",
          "Обеспечивающий подачу воды в течение 25 минут с момента отказа системы циркуляционного водяного охлаждения",
        ],
        correctAnswers: [
          "Обеспечивающий подачу воды в течение 15 минут с момента отказа системы циркуляционного водяного охлаждения",
        ],
      },
      {
        code: "20302091",
        text: 'Какому гидравлическому испытанию должны подвергаться водоохлаждаемые элементы печи "Аусмелт" перед их установкой?',
        answers: [
          "На 1,2 величины рабочего давления охлаждающей жидкости",
          "На 1,5 величины рабочего давления охлаждающей жидкости",
          "На 1,8 величины рабочего давления охлаждающей жидкости",
          "На 2,0 величины рабочего давления охлаждающей жидкости",
        ],
        correctAnswers: [
          "На 1,5 величины рабочего давления охлаждающей жидкости",
        ],
      },
      {
        code: "20302092",
        text: 'В каком из перечисленных случаев запрещено возобновлять подачу воды к любому из водоохлаждаемых компонентов печи "Аусмелт"?',
        answers: [
          "Если температура этих компонентов превышает 120 °C",
          "Если температура этих компонентов превышает 100 °C",
          "Если температура этих компонентов превышает 150 °C",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Если температура этих компонентов превышает 150 °C"],
      },
      {
        code: "20302093",
        text: 'До какой минимальной температуры должна быть охлаждена печь "Аусмелт" для возобновления подачи охлаждающей жидкости при перегреве печи и неисправности водоохлаждающих элементов?',
        answers: ["150 °C", "120 °C", "200 °C", "180 °C", "210 °C"],
        correctAnswers: ["150 °C"],
      },
      {
        code: "20302094",
        text: 'Подача шихты с какой влажностью запрещается в печь "Аусмелт"?',
        answers: [
          "Более 8% по массе",
          "Более 7% по массе",
          "Более 6% по массе",
          "Более 5% по массе",
        ],
        correctAnswers: ["Более 8% по массе"],
      },
      {
        code: "20302095",
        text: "Чем должны быть оборудованы ванны с кислотой для травления оборудования?",
        answers: [
          "Системой подачи аргона",
          "Местными отсосами",
          "Укрытиями",
          "Всем перечисленным",
        ],
        correctAnswers: ["Местными отсосами"],
      },
      {
        code: "20302096",
        text: "Какие требования безопасности при производстве никеля и меди указаны неверно?",
        answers: [
          "Для удаления корок из ковшей, чаш и погрузки их с помощью мостового крана в цехе должно быть отведено специальное место",
          "Операции закрывания штейновых и шлаковых шпуров, сифонных отверстий, леток и шлаковых окоп плавильных печей должны быть механизированы",
          "На печах стационарного типа должен предусматриваться и поддерживаться в рабочем состоянии резервный шпур для выпуска расплава",
          "Места установки термопар определяются техническим регламентом",
        ],
        correctAnswers: [
          "Места установки термопар определяются техническим регламентом",
        ],
      },
      {
        code: "20302097",
        text: "Какие требования безопасности при производстве никеля и меди указаны верно? Выберите правильный вариант   ответа",
        answers: [
          "Допускается разгрузка горячих корок из ковшей на сырые площадки",
          "Замер уровня расплава вручную должен производиться при отключенной печи, при этом не допускаются заливка расплава в печь и выдача продуктов плавки",
          "Скорость движения транспорта для перевозки шлака на сливных путях, переходах, неохраняемых переездах и в местах маневрирования составов не должна превышать 8 км/ч",
          "Не допускается слив шлака без отцепки локомотива от шлаковозного состава при наличии между шлаковозом и локомотивом железнодорожной платформы прикрытия",
        ],
        correctAnswers: [
          "Замер уровня расплава вручную должен производиться при отключенной печи, при этом не допускаются заливка расплава в печь и выдача продуктов плавки",
        ],
      },
      {
        code: "20302098",
        text: "Какие требования безопасности при производстве меди и никеля указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Исправность арматуры, контрольно-измерительных приборов и предохранительных устройств должна периодически проверяться в порядке и в сроки, предусмотренные паспортом устройства",
          "Порядок подачи воздуха на окисление паромазутной смеси или газа на восстановление должен определяться технологической инструкцией",
          "В случае применения подшипников скольжения износ цапф конвертеров во всех измерениях не должен превышать 8% их первоначальных размеров",
          "Крюк крана или другого подъемного устройства, используемого для загрузки электродной массы без отключения печи, должен иметь не более двух последовательных ступеней изоляции от заземлителя",
        ],
        correctAnswers: [
          "Порядок подачи воздуха на окисление паромазутной смеси или газа на восстановление должен определяться технологической инструкцией",
        ],
      },
      {
        code: "20302099",
        text: " Какие требования к эксплуатации автоклавов на медно-никелевом производстве указаны верно?",
        answers: [
          "Для смазки оборудования, непосредственно связанного с работой автоклава, необходимо применять смазочные материалы стойкие и пожаробезопасные в кислородно-воздушной смеси",
          "Давление водорода на входе в печь (автоклав) должно поддерживаться в пределах, установленных технологическим регламентом",
          "Водород в продувочном газе после остановки должен отсутствовать, а содержание кислорода в продувочном газе перед пуском не должно превышать 10% (по объему)",
        ],
        correctAnswers: [
          "Для смазки оборудования, непосредственно связанного с работой автоклава, необходимо применять смазочные материалы стойкие и пожаробезопасные в кислородно-воздушной смеси",
        ],
      },
      {
        code: "20302100",
        text: "Чем могут продуваться перед пуском и после остановки печь, автоклав, трубопроводы и свечи дожигания водорода? Выберите 2 варианта ответа.",
        answers: ["Азотом", "Влажным паром", "Сжатым воздухом", "Кислородом"],
        correctAnswers: ["Азотом", "Влажным паром"],
      },
      {
        code: "20302101",
        text: "Какие из перечисленных требований к эксплуатации электролизных цехов указаны верно? Выберите 2 варианта ответа.",
        answers: [
          "Высота стоп анодных остатков, укладываемых на транспортные тележки, не должна превышать 3 м",
          "Подвальные этажи цехов электролиза должны иметь освещение в соответствии с инструкцией",
          "Электролизные цехи и отделения должны быть оборудованы дистанционными средствами аварийного отключения электропитания серии ванн с пульта управления цеха (отделения)",
          "Номера установленных электролизных ванн должны соответствовать порядковым номерам ванн на схеме (плане) размещения оборудования",
        ],
        correctAnswers: [
          "Электролизные цехи и отделения должны быть оборудованы дистанционными средствами аварийного отключения электропитания серии ванн с пульта управления цеха (отделения)",
          "Номера установленных электролизных ванн должны соответствовать порядковым номерам ванн на схеме (плане) размещения оборудования",
        ],
      },
      {
        code: "20302102",
        text: "Какие требования к работе с тетракарбонилом никеля указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Работы с жидким тетракарбонилом никеля должны выполняться в соответствии с технологическим регламентом, с использованием СИЗ",
          "Транспортирование тетракарбонила никеля и отходов производства, содержащих тетракарбонил никеля, для нейтрализации (уничтожения) должно осуществляться в специальных, герметично закрываемых емкостях",
          " Слив тетракарбонила никеля может производиться в любые емкости",
        ],
        correctAnswers: [
          "Транспортирование тетракарбонила никеля и отходов производства, содержащих тетракарбонил никеля, для нейтрализации (уничтожения) должно осуществляться в специальных, герметично закрываемых емкостях",
        ],
      },
      {
        code: "20302103",
        text: "Что должно предусматриваться для тушения тлеющей пыли и подавления горения пыли в бункерах? Выберите 2 варианта ответа.",
        answers: [
          "Подвод азота",
          "Ручное включение вентиляторов",
          "Подвод насыщенного пара",
          "Аварийная система вентиляции",
        ],
        correctAnswers: ["Подвод азота", "Подвод насыщенного пара"],
      },
      {
        code: "20302104",
        text: "Чем должен быть оборудован барабан-сепаратор на производстве никеля и меди?",
        answers: [
          "Только предохранительными клапанами",
          "Только сигнализаторами предельных уровней воды и ее давления",
          "Только линией периодической продувки",
          "Только пробоотборником пара и воды",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20302105",
        text: "Какие действия необходимо выполнить перед снятием шунтов, устанавливаемых в ванной на производстве медных порошков?",
        answers: [
          "Закончить загрузку",
          "Проверить правильности расстановки электродов",
          "Заполнить ванну электролитом",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20302106",
        text: "При каком минимальном содержании водорода в воздухе производственных помещений технологическое оборудование, работающее в этом помещении, должно быть остановлено?",
        answers: [
          "1% (по объему)",
          "5% (по объему)",
          "2% (по объему)",
          "1,5% (по объему)",
        ],
        correctAnswers: ["1% (по объему)"],
      },
      {
        code: "20302107",
        text: "В каких случаях следует немедленно перевести конвертер в нерабочее положение и принять меры к устранению неполадок?",
        answers: [
          "В случае прекращения подачи энергии на один из двигателей поворота, прогаре фурм или кожуха конвертера",
          "В случае неисправности систем световой и звуковой сигнализации",
          'В случае выключения светового табло "Заливка расплава" и предупредительного сигнала',
          "Во всех перечисленных случаях",
        ],
        correctAnswers: [
          "В случае прекращения подачи энергии на один из двигателей поворота, прогаре фурм или кожуха конвертера",
        ],
      },
      {
        code: "20302108",
        text: "Какие из перечисленных требований при производстве медного и никелевого купороса указаны неверно?",
        answers: [
          "Технологические аппараты для переработки растворов при атмосферном давлении должны быть оборудованы переливными трубами, связанными с резервными емкостями, или снабжены сигнализирующими устройствами, предупреждающими о переполнении аппаратов",
          "Смотровые люки и лазы на крышках аппаратуры должны быть снабжены решетками или плотно закрывающимися крышками",
          "Эксплуатация аппаратов с открытыми люками, а также производство ремонтных и профилактических работ на действующем оборудовании допускаются в редких случаях",
          "Контроль за содержанием мышьяковистого водорода в воздухе указанного помещения должен осуществляться автоматическими газоанализаторами с устройством световой и звуковой сигнализации",
        ],
        correctAnswers: [
          "Эксплуатация аппаратов с открытыми люками, а также производство ремонтных и профилактических работ на действующем оборудовании допускаются в редких случаях",
        ],
      },
      {
        code: "20302109",
        text: "Какие из перечисленных требований при производстве медного и никелевого купороса указаны неверно?",
        answers: [
          "Глубокое обезмеживание маточных растворов методом электролиза должно выполняться в отдельном помещении, оснащенном системой приточно-вытяжной вентиляции",
          "Контроль за содержанием мышьяковистого водорода в воздухе указанного помещения должен осуществляться автоматическими газоанализаторами с устройством световой и звуковой сигнализации",
          "При отсутствии на производстве автоматических газоанализаторов контроль за содержанием в воздухе рабочей зоны мышьяковистого водорода должен осуществляться ручным газоанализатором",
          "В случае если содержание мышьяковистого водорода в воздухе производственного помещения превышает ПДК, обслуживающий персонал должен быть выведен из помещения, а помещение проветрено",
        ],
        correctAnswers: [
          "При отсутствии на производстве автоматических газоанализаторов контроль за содержанием в воздухе рабочей зоны мышьяковистого водорода должен осуществляться ручным газоанализатором",
        ],
      },
      {
        code: "20302110",
        text: "Какие требования к электролизным ваннам указаны неверно?",
        answers: [
          'Подачу необходимого дополнительного количества воды для пополнения электролизных ванн и аппаратов водой, имеющей температуру 100 - 120 °C, следует производить небольшой струей через специальный штуцер в крышке или под "зеркало" раствора',
          "При работе на электролизных ваннах должны соблюдаться меры предосторожности, исключающие замыкания двух рядом стоящих блоков ванн",
          "Электролизные ванны и шинопроводы должны быть изолированы от земли, а сборные баки для электролита заземлены",
          "Подключение и отключение электролизных ванн к ошиновке, замена электродов на регенеративных ваннах должны производиться только после снятия электропитания",
        ],
        correctAnswers: [
          'Подачу необходимого дополнительного количества воды для пополнения электролизных ванн и аппаратов водой, имеющей температуру 100 - 120 °C, следует производить небольшой струей через специальный штуцер в крышке или под "зеркало" раствора',
        ],
      },
      {
        code: "20302111",
        text: "Какие требования к металлическим коммуникациям систем пароводоснабжения, сжатого воздуха и вентиляции в залах электролиза указаны неверно?",
        answers: [
          "Металлические коммуникации систем пароводоснабжения, сжатого воздуха и вентиляции в залах электролиза должны быть размещены на высоте не менее 1,5 м от рабочих площадок",
          "Металлические коммуникации систем пароводоснабжения, сжатого воздуха и вентиляции в залах электролиза должны быть изолированы от земли или ограждены",
          "Металлические коммуникации систем пароводоснабжения, сжатого воздуха и вентиляции в залах электролиза должны иметь электроизоляционные разрывы по длине цеха, а также на входе в здание и выходе из него",
          "Все перечисленные",
        ],
        correctAnswers: [
          "Металлические коммуникации систем пароводоснабжения, сжатого воздуха и вентиляции в залах электролиза должны быть размещены на высоте не менее 1,5 м от рабочих площадок",
        ],
      },
      {
        code: "20302112",
        text: "Каким документом должны определяться схема контроля и периодичность замеров величины сопротивления изоляции технических устройств в электролизных цехах и отделениях?",
        answers: [
          "Технологической инструкцией",
          "Паспортом оборудования",
          "Внутренним регламентом",
        ],
        correctAnswers: ["Технологической инструкцией"],
      },
      {
        code: "20302113",
        text: "Какие требования к электролизным цехам указаны верно? Выберите правильный вариант ответа",
        answers: [
          "Металлические каркасы желобов должны быть изолированы от заземлителя и иметь электроизоляционные разрывы, расположенные вдоль ванн между каждыми двумя рядами ванн",
          "Перемычки (шунты) для отключения ванн должны быть рассчитаны на допустимую силу тока и храниться на специальных стеллажах",
          "Подвальные этажи цехов электролиза должны иметь освещение в соответствии с техническим регламентом",
          "Все перечисленные",
        ],
        correctAnswers: [
          "Перемычки (шунты) для отключения ванн должны быть рассчитаны на допустимую силу тока и храниться на специальных стеллажах",
        ],
      },
      {
        code: "20302114",
        text: 'Какие из перечисленных требований при производстве меди методом пирометаллургической переработки в печи "Аусмелт" указаны неверно?',
        answers: [
          "На случай отключения электроснабжения должен быть установлен напорный бак, обеспечивающий подачу воды в течение 30 минут с момента отказа системы циркуляционного водяного охлаждения",
          "Эксплуатация печи при нарушении подачи охлаждающей воды к блокам шпуров и неисправной сигнализации запрещается",
          "Водоохлаждаемые элементы перед их установкой должны подвергаться гидравлическому испытанию на 1,5 величины рабочего давления охлаждающей жидкости",
          "Запуск системы подачи охлаждающей воды должен производиться до нагрева печи",
        ],
        correctAnswers: [
          "На случай отключения электроснабжения должен быть установлен напорный бак, обеспечивающий подачу воды в течение 30 минут с момента отказа системы циркуляционного водяного охлаждения",
        ],
      },
      {
        code: "20302115",
        text: 'В какое положение должна выводиться фурма из-под дутья при помощи лебедки, оборудованной системой автоматического аварийного вывода фурмы, при производстве меди методом пирометаллургической переработки в печи "Аусмелт"?',
        answers: [
          "В верхнее положение",
          "В правое положение",
          "В левое положение",
          "В нижнее положение",
        ],
        correctAnswers: ["В верхнее положение"],
      },
      {
        code: "20302116",
        text: 'Какие действия необходимо предпринять при подозрении на вспенивание (переокисление) в ванне печи "Аусмелт"?',
        answers: [
          "Вывести работников в безопасную зону",
          "Прекратить шихтоподачу и дутье фурмы",
          "Подать уголь",
          "Все перечисленные действия",
        ],
        correctAnswers: ["Все перечисленные действия"],
      },
      {
        code: "20302117",
        text: 'Для чего печь "Аусмелт" оборудуется резервной газовоздушной горелкой?',
        answers: [
          "Для достижения поддержания температурных параметров огнеупорной кладки",
          "Для достижения поддержания температурных параметров расплава",
          "Для достижения поддержания температурных параметров котла-утилизатора",
          "Для всех перечисленных параметров",
        ],
        correctAnswers: ["Для всех перечисленных параметров"],
      },
      {
        code: "20302118",
        text: 'В соответствии с какой документацией должен проводиться разогрев печи "Аусмелт" после капитального ремонта?',
        answers: [
          "Технологической инструкцией",
          "Паспортом оборудования",
          "Внутренними распорядительными документами организации",
          "Техническим регламентом",
        ],
        correctAnswers: ["Технологической инструкцией"],
      },
      {
        code: "20302119",
        text: "Чем производится отогрев смерзшейся изоляции для обеспечения доступа к адсорберам?",
        answers: [
          "Сухим подогретым воздухом",
          "Горячей водой",
          "Паром",
          "Газовоздушной горелкой",
        ],
        correctAnswers: ["Сухим подогретым воздухом"],
      },
      {
        code: "20302120",
        text: "Какая должна быть минимальная высота забора по периметру территории всего комплекса производства водорода?",
        answers: ["2,0 м", "1,0 м", "1,5 м", "1,2 м"],
        correctAnswers: ["2,0 м"],
      },
      {
        code: "20302121",
        text: "С каким интервалом должна проверяться объемная доля кислорода в месте проведения ремонтных работ во время нахождения персонала внутри трубопроводов, опорных обечаек регенераторов, в отсеке обратноповоротных клапанов или арматуры?",
        answers: [
          "Не менее чем 10 минут",
          "Не менее чем 5 минут",
          "Не менее чем 8 минут",
          "Не менее чем 7 минут",
        ],
        correctAnswers: ["Не менее чем 10 минут"],
      },
      {
        code: "20302122",
        text: "Какое должно быть расстояние между ресиверами одного газа?",
        answers: [
          "Не менее 1,5 м в свету",
          "Не менее 1,0 м в свету",
          "Не менее 0,5 м в свету",
          "Не менее 1,2 м в свету",
        ],
        correctAnswers: ["Не менее 1,5 м в свету"],
      },
      {
        code: "20302123",
        text: "Какие требования к ресиверам для водорода (сосудам) указаны неверно?",
        answers: [
          "Расстояние от ресиверов (сосудов) с водородом до ограждения должно быть 1,0 м",
          "Ресиверы для водорода (сосуды) размещаются на открытых площадках, имеющих по периметру ограждение легкого типа высотой не менее 1,2 м из несгораемого материала",
          'На ресиверах должны быть поясняющие надписи: "Водород. Взрывоопасно"',
          "Все перечисленные",
        ],
        correctAnswers: [
          "Расстояние от ресиверов (сосудов) с водородом до ограждения должно быть 1,0 м",
        ],
      },
      {
        code: "20302124",
        text: "Какое допускается максимальное содержание оксида углерода в газах, поступающих в электрофильтр, при отводе газов с полным дожиганием?",
        answers: ["2%", "1%", "1,5%", "4%"],
        correctAnswers: ["1%"],
      },
      {
        code: "20302125",
        text: "На какой ступени сжатия водородные компрессоры должны иметь предохранительные клапаны?",
        answers: [
          "На всех ступенях",
          "На любой ступени",
          "Одноступенчатой",
          "Двухступенчатой",
        ],
        correctAnswers: ["На всех ступенях"],
      },
      {
        code: "20302126",
        text: "Какой технической документацией должен быть оснащен каждый компрессорный агрегат или установка, или группа однородных компрессорных установок?",
        answers: [
          "Паспортом (формуляром) на компрессорную установку",
          "Схемой трубопроводов",
          "Журналом учета работы компрессора",
          "Всей перечисленной",
        ],
        correctAnswers: ["Всей перечисленной"],
      },
      {
        code: "20302127",
        text: "Как часто должно проверяться исправное действие автоблокировки и сигнализации?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в квартал",
          "Не реже одного раза в полгода",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20302128",
        text: "Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в неделю",
          "Не реже одного раза в 10 дней",
        ],
        correctAnswers: ["Не реже одного раза в неделю"],
      },
      {
        code: "20302129",
        text: "Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных вне помещений?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в квартал",
          "Не реже одного раза в полгода",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20302130",
        text: "В каких случаях запрещается газовая резка и сварка на действующих газопроводах?",
        answers: [
          "На газопроводах, находящихся под разрежением",
          "После продувки газопроводов сжатым воздухом",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["На газопроводах, находящихся под разрежением"],
      },
      {
        code: "20302131",
        text: "На что должен проверяться выполненный сварной шов после врезки в действующий газопровод?",
        answers: ["На герметичность", "На плотность", "На прочность"],
        correctAnswers: ["На плотность"],
      },
      {
        code: "20302132",
        text: "Каково минимально допустимое давление газа непосредственно перед потребителем (после регулирующих органов)?",
        answers: ["0,2 кПа", "0,3 кПа", "0,5 кПа"],
        correctAnswers: ["0,5 кПа"],
      },
      {
        code: "20302133",
        text: "Разрешено ли использовать продувочные свечи для выпуска в атмосферу избыточного газа?",
        answers: [
          "Только в аварийных случаях",
          "Не допускается",
          "Разрешается при увеличении давления газа до 5%",
        ],
        correctAnswers: ["Только в аварийных случаях"],
      },
      {
        code: "20302134",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии более 0,5 мм/г?",
        answers: ["Один раз в год", "Один раз в 2 года", "Один раз в 4 года"],
        correctAnswers: ["Один раз в год"],
      },
      {
        code: "20302135",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии 0,1 - 0,5 мм/г?",
        answers: [
          "Один раз в 4 года",
          "Один раз в 3 года",
          "Один раз в 2 года",
        ],
        correctAnswers: ["Один раз в 2 года"],
      },
      {
        code: "20302136",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии до 0,1 мм/г?",
        answers: ["Один раз в год", "Один раз в 4 года", "Один раз в 2 года"],
        correctAnswers: ["Один раз в 4 года"],
      },
      {
        code: "20302137",
        text: "Как часто должна производиться нивелировка действующих газопроводов?",
        answers: [
          "Ежегодно, на протяжении двух лет после окончания строительства",
          "Один раз в 3 года",
          "Один раз в год",
          "Ежегодно, на протяжении четырех лет после окончания строительства",
        ],
        correctAnswers: [
          "Ежегодно, на протяжении двух лет после окончания строительства",
        ],
      },
      {
        code: "20302138",
        text: "До розжига газопотребляющего агрегата чем должен быть продут газопровод перед коллектором?",
        answers: ["Газом", "Кислородом", "Азотом"],
        correctAnswers: ["Газом"],
      },
      {
        code: "20302139",
        text: "В какие сроки в помещениях категории А должна осуществляться проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в 2 месяца",
          "Не реже одного раза в 3 месяца",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20302140",
        text: "Как часто должны проходить поверку контрольно-измерительные приборы в процессе эксплуатации?",
        answers: [
          "Не реже одного раза в год",
          "Не реже одного раза в 1,5 года",
          "Не реже одного раза в 2 года",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20302141",
        text: "При каких показателях газа разрешено применять жидкостные манометры?",
        answers: [
          "При давлении до 0,04 МПа",
          "При давлении до 0,03 МПа",
          "При давлении до 0,05 МПа",
        ],
        correctAnswers: ["При давлении до 0,03 МПа"],
      },
      {
        code: "20302142",
        text: "Какая допускается максимальная температура газа в газопроводах в случае подачи в них пара для пропарки, продувки и отогрева?",
        answers: ["85 °C", "95 °C", "105 °C"],
        correctAnswers: ["85 °C"],
      },
      {
        code: "20302143",
        text: "Куда должны заноситься записи о проведении технического обслуживания, выявленных дефектах и нарушениях газопроводов?",
        answers: [
          "В эксплуатационный журнал",
          "В паспорт оборудования",
          "В специальный журнал по устранению дефектов",
        ],
        correctAnswers: ["В эксплуатационный журнал"],
      },
      {
        code: "20302144",
        text: "Какое допускается максимальное содержание водорода в продувочном газе после остановки продувки автоклава?",
        answers: ["Содержание водорода не допускается", "5%", "7%", "6%"],
        correctAnswers: ["Содержание водорода не допускается"],
      },
      {
        code: "20302145",
        text: "Какое допускается максимальное содержание кислорода в продувочном газе перед пуском автоклава?",
        answers: ["Содержание кислорода не допускается", "7%", "6%", "4%"],
        correctAnswers: ["4%"],
      },
      {
        code: "20302146",
        text: "Какой должна быть концентрация водорода перед его пуском в печь (автоклав)?",
        answers: [
          "Не менее 95% (по объему)",
          "Не менее 90% (по объему)",
          "Не менее 93% (по объему)",
          "Не менее 87% (по объему)",
        ],
        correctAnswers: ["Не менее 95% (по объему)"],
      },
      {
        code: "20302147",
        text: "Чем должны быть оборудованы коллекторы кислородно-воздушной смеси, подаваемой в печь Ванюкова?",
        answers: [
          "Только взрывными клапанами",
          "Только отсекающими устройствами",
          "Только автоматическими газоанализаторами",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20302148",
        text: "Какие требования безопасности при расчистке горловины конвертера, анодной печи с помощью мостового крана указаны неверно? Выберите правильный вариант ответа.",
        answers: [
          "Для срыва настилы с горловины допускается использовать механизм поворота (привода) конвертера, анодной печи",
          "При расчистке горловины конвертера, анодной печи с помощью мостового крана должны применяться специальные приспособления с предохранительными устройствами ограничения нагрузки",
          "Конструкция приспособлений должна обеспечивать механическую связь с крюком крана при разрушении предохранительного элемента",
          "При загрузке анодных печей оборотными материалами в первую очередь должны загружаться легковесные материалы, затем анодные остатки, скрап электролитного производства, другие материалы в соответствии с технологической инструкцией",
        ],
        correctAnswers: [
          "Для срыва настилы с горловины допускается использовать механизм поворота (привода) конвертера, анодной печи",
        ],
      },
      {
        code: "20302149",
        text: "Какое оборудование из перечисленного перед пуском и после остановки должно быть продуто азотом или влажным паром? Выберите правильный вариант ответа.",
        answers: [
          "Только печь",
          "Только автоклав",
          "Только трубопроводы",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20302150",
        text: "Какой минимальный состав бригады должен производить работы в газоопасных местах, например, при разгерметизации технических устройств или коммуникаций?",
        answers: ["2 человека", "3 человека", "4 человека"],
        correctAnswers: ["2 человека"],
      },
      {
        code: "20302151",
        text: "Какие из перечисленных требований к эксплуатации шахтных печей указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Места установки термопар определяются проектом",
          "Места приема расплава должны быть сухими",
          "Колошниковая площадка шахтных печей должна иметь не менее двух выходов",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20302152",
        text: "Какое минимальное количество выходов должна иметь колошниковая площадка шахтных печей?",
        answers: ["2 выхода", "3 выхода", "4 выхода", "1 выход"],
        correctAnswers: ["2 выхода"],
      },
      {
        code: "20302153",
        text: "Каким документом должны определяться места установки термопар для контроля температуры кладки шахтной печи?",
        answers: [
          "Проектом",
          "Техническим регламентом",
          "Производственной инструкцией",
          "Паспортом оборудования",
        ],
        correctAnswers: ["Проектом"],
      },
      {
        code: "20302154",
        text: "При каких условиях должен производиться замер уровня расплава вручную в шахтной печи? Выберите 2 варианта ответа.",
        answers: [
          "При отключенной печи",
          "При отсутствии выдачи продуктов плавки",
          "При подаче предупредительного сигнала",
          "При вывешивании предупредительных знаков",
        ],
        correctAnswers: [
          "При отключенной печи",
          "При отсутствии выдачи продуктов плавки",
        ],
      },
      {
        code: "20302155",
        text: "Что должно выполняться перед заливкой расплава в конвертер? Выберите 2 варианта ответа.",
        answers: [
          "Подача предупредительного сигнала",
          'Включение светового табло "Заливка расплава"',
          "Очистка емкости для расплава от мусора",
          "Вывод всех работников из зоны",
        ],
        correctAnswers: [
          "Подача предупредительного сигнала",
          'Включение светового табло "Заливка расплава"',
        ],
      },
      {
        code: "20302156",
        text: "Какие требования к площадке конвертерщика-фурмовщика указаны неверно?",
        answers: [
          "Площадка конвертерщика-фурмовщика должна иметь один выход, расположенный с противоположной стороны",
          "Над площадкой конвертерщика-фурмовщика должно быть предусмотрено защитное ограждение, предохраняющее рабочего от падающих твердых частиц, брызг расплава из горловины и напыльника конвертера",
          "Привод поворота конвертера должен иметь не менее двух двигателей",
          "При прекращении подачи энергии на один из двигателей поворота, прогаре фурм или кожуха конвертера следует немедленно перевести конвертер в нерабочее положение и принять меры к устранению неполадок",
        ],
        correctAnswers: [
          "Площадка конвертерщика-фурмовщика должна иметь один выход, расположенный с противоположной стороны",
        ],
      },
      {
        code: "20302157",
        text: "Какое минимальное количество двигателей должен иметь привод поворота конвертера?",
        answers: [
          "2 двигателя",
          "3 двигателя",
          "1 двигатель",
          "Не регламентируется",
        ],
        correctAnswers: ["2 двигателя"],
      },
      {
        code: "20302158",
        text: "В каком случае арматура, предназначенная для работы с кислородом, не подлежит обезжириванию перед монтажом?",
        answers: [
          "В случае если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка",
          "В любом случае подлежит обезжириванию",
          "В случае если детали были подвергнуты охлаждению жидким азотом",
          "В случае если детали контактировали с кислородом",
        ],
        correctAnswers: [
          "В случае если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка",
        ],
      },
      {
        code: "20302159",
        text: "Какое расчетное давление газа соответствует газопроводам среднего давления?",
        answers: [
          "Свыше 0,1 до 0,3 МПа включительно",
          "Свыше 0,3 до 1,2 МПа включительно",
          "До 0,1 МПа включительно",
          "Свыше 1,2 МПа",
        ],
        correctAnswers: ["Свыше 0,1 до 0,3 МПа включительно"],
      },
      {
        code: "20302160",
        text: "Как должны сооружаться газопроводы на территории предприятия?",
        answers: [
          "Надземными",
          "Подземными",
          "В помещениях, где расположены газопотребляющие агрегаты",
        ],
        correctAnswers: ["Надземными"],
      },
      {
        code: "20302161",
        text: "Через какое расстояние должны заземляться наружные газопроводы?",
        answers: [
          "Через каждые 250 м",
          "Через каждые 150 м",
          "Через каждые 50 м",
          "Через каждые 350 м",
        ],
        correctAnswers: ["Через каждые 250 м"],
      },
      {
        code: "20302162",
        text: "Какой должен быть нижний предел давления для I разряда газов-заменителей ацетилена перед газопламенной аппаратурой?",
        answers: ["3,0 кПа", "2,0 кПа", "1,5 кПа", "0,5 кПа"],
        correctAnswers: ["3,0 кПа"],
      },
      {
        code: "20302163",
        text: "При каком увеличении содержания кислорода в газе в напорном коллекторе газоотсасывающей станции отвод газа от электропечи должен быть немедленно прекращен?",
        answers: ["2%", "4%", "1,5%", "3%"],
        correctAnswers: ["2%"],
      },
      {
        code: "20302164",
        text: "Каким уплотнительным устройством, препятствующим выбиванию газов в цех, должно оборудоваться отверстие в охладителе конвертерных газов?",
        answers: [
          "Аэродинамической завесой",
          "Воздушной завесой",
          "Азотной завесой",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20302165",
        text: "С какой периодичностью газопроводы сероочистки природного газа подвергаются пневматическому испытанию?",
        answers: [
          "1 раз в 4 года",
          "2 раза в год",
          "1 раз в год",
          "2 раза в 5 лет",
        ],
        correctAnswers: ["1 раз в 4 года"],
      },
      {
        code: "20302166",
        text: "Какое из перечисленных требований к установке сероочистки природного газа указано верно?",
        answers: [
          "Установка сероочистки природного газа для получения реформерного газа должна быть снабжена быстродействующими отсекающими клапанами на входе газа в установку, срабатывающими только при снижении давления газа от установленных проектом значений",
          "На пульте управления сероочистки на видном месте должна быть вывешена схема расположения аппаратов и трубопроводов",
          "Два раз в 2 года газопроводы сероочистки подвергаются пневматическому испытанию",
          "Содержание CH4 в двух последовательно отобранных пробах должно быть не более 1,5%",
        ],
        correctAnswers: [
          "На пульте управления сероочистки на видном месте должна быть вывешена схема расположения аппаратов и трубопроводов",
        ],
      },
      {
        code: "20302167",
        text: "Какие устройства должны быть установлены на нагнетательном газопроводе для отключения компрессора от коллектора высокого давления? Выберите правильный вариант ответа.",
        answers: [
          "Обратный клапан и два запорных вентиля",
          "Только запорный вентиль",
          "Только обратный клапан",
        ],
        correctAnswers: ["Обратный клапан и два запорных вентиля"],
      },
      {
        code: "20302168",
        text: "С какой периодичностью должен производиться осмотр газопроводов, газовых установок и газового оборудования комиссией на производстве?",
        answers: [
          "Не реже 2 раз в год",
          "Не реже 1 раза в 2 года",
          "Не реже 3 раз в год",
          "Не реже 2 раз в 2 года",
        ],
        correctAnswers: ["Не реже 2 раз в год"],
      },
      {
        code: "20302169",
        text: "Кто из перечисленных лиц не должен входить в состав постоянно действующей комиссии для проведения осмотров объектов газового хозяйства?",
        answers: [
          "Начальник цеха",
          "Работник, ответственный за безопасную эксплуатацию и техническое состояние газового хозяйства цеха",
          "Работники, ответственные за безопасную эксплуатацию и техническое состояние соответствующих технических устройств, сооружений и оборудования",
          "Руководитель объекта",
        ],
        correctAnswers: ["Руководитель объекта"],
      },
      {
        code: "20302170",
        text: "С какой периодичностью должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях, и вне помещений?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в 2 месяца",
          "Не реже 2 раза в пол года",
          "Не реже одного раза в 3 месяца",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20302171",
        text: "С какой периодичностью должна производиться ревизия с разборкой регулятора давления, предохранительных клапанов, фильтров газопроводов, газовых установок и газового оборудования?",
        answers: [
          "Не менее 1 раза в год",
          "Не менее 2 раз в 3 года",
          "Не менее 3 в 5 лет",
          "Не менее 1 раза в 3 месяца",
        ],
        correctAnswers: ["Не менее 1 раза в год"],
      },
      {
        code: "20302172",
        text: "Какое из перечисленных требований к манометрам, используемым при проведении испытаний газопроводов и газовых установок, указано верно?",
        answers: [
          "При проведении испытаний газопроводов и газовых установок давление должно измеряться одним манометрами, прошедшим поверку и опломбирование",
          "Класс точности манометров должен быть не ниже 1 с диаметром корпуса не менее 120 мм",
          "Для проведения испытания на герметичность и прочность следует фиксировать падение давления в газопроводе манометрами классов точности 0,4 и 0,15, а также жидкостными манометрам",
          "При применении манометров без указания класса точности их погрешность допускается превышать порог измерения",
        ],
        correctAnswers: [
          "Для проведения испытания на герметичность и прочность следует фиксировать падение давления в газопроводе манометрами классов точности 0,4 и 0,15, а также жидкостными манометрам",
        ],
      },
      {
        code: "20302173",
        text: "В течение какого времени выдерживают испытательное давление в трубопроводе при испытании на прочность, после чего снижают до рабочего давления, при котором производят тщательный осмотр сварных швов (испытание на плотность)?",
        answers: [
          "В течение 10 минут",
          "В течение 5 минут",
          "В течение 30 минут",
          "В течение 20 минут",
        ],
        correctAnswers: ["В течение 10 минут"],
      },
      {
        code: "20302174",
        text: "Какое устанавливается минимальное расстояние от испытываемого газопровода большого диаметра, расположенных вне помещений, до границ охраняемой зоны во время проведения пневматических испытаний на прочность?",
        answers: ["50 м", "20 м", "15 м", "10 м"],
        correctAnswers: ["50 м"],
      },
      {
        code: "20302175",
        text: "Каким давлением производится дополнительное испытание на герметичность межцеховых и внутрицеховых газопроводов?",
        answers: [
          "Равным рабочему",
          "Соответствующим с требованиями производственной документации",
          "Превышающим рабочее в 1,5 раза",
        ],
        correctAnswers: ["Равным рабочему"],
      },
      {
        code: "20302176",
        text: "Какая установлена продолжительность дополнительных испытаний на герметичность для вновь сооружаемых газопроводов?",
        answers: [
          "Не менее 24 часов",
          "Не менее 20 часов",
          "Не менее 10 часов",
          "Не менее 15 часов",
        ],
        correctAnswers: ["Не менее 24 часов"],
      },
      {
        code: "20302177",
        text: "Какая устанавливается продолжительность периодических испытаний на герметичность для газопроводов, а также испытаний после ремонта, связанного со сваркой и разборкой газопровода?",
        answers: [
          "Не менее 4 часов",
          "Не менее 3 часов",
          "Не менее 2 часов",
          "Не менее 1 часа",
        ],
        correctAnswers: ["Не менее 4 часов"],
      },
      {
        code: "20302178",
        text: "При какой скорости падения давления за время дополнительного пневматического испытания на герметичность внутрицеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?",
        answers: [
          "Не более 0,5% в час",
          "Не более 1% в час",
          "Не более 0,1% в час",
          "Не более 0,25% в час",
        ],
        correctAnswers: ["Не более 0,1% в час"],
      },
      {
        code: "20302179",
        text: "При какой скорости падения давления за время дополнительного пневматического испытания на герметичность межцеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?",
        answers: [
          "Не более 1% в час",
          "Не более 2% в час",
          "Не более 0,35% в час",
          "Не более 0,2% в час",
        ],
        correctAnswers: ["Не более 0,2% в час"],
      },
      {
        code: "20302180",
        text: "Какому классу должна соответствовать герметичность арматуры с металлическим уплотнением в затворе, применяемая для установки на трубопроводах водорода?",
        answers: ["Классу А", "Классу Б", "Классу В", "Классу Г"],
        correctAnswers: ["Классу В"],
      },
      {
        code: "20302181",
        text: "Какому классу должна соответствовать герметичность затвора запорной арматуры со средой водорода?",
        answers: ["Классу А", "Классу Б", "Классу В", "Классу Г"],
        correctAnswers: ["Классу А"],
      },
      {
        code: "20302182",
        text: "Какую арматуру не допускается применять на водородопроводах?",
        answers: ["Из серого чугуна", "Из стали", "Из алюминия", "Из меди"],
        correctAnswers: ["Из серого чугуна"],
      },
      {
        code: "20302183",
        text: "Какие сроки проведения ревизии газопроводов должны быть при скорости коррозии более 0,5 мм/г?",
        answers: [
          "Один раз в год",
          "Один раз в 2 года",
          "Один раз в 4 года",
          "Один раз в 5 лет",
        ],
        correctAnswers: ["Один раз в год"],
      },
      {
        code: "20302184",
        text: "При какой скорости коррозии срок проведения ревизии газопроводов должен быть не реже одного раза в 2 года?",
        answers: [
          "При скорости более 0,5 мм/г",
          "При скорости 0,1 - 0,5 мм/г",
          "При скорости менее 0,1 мм/г",
        ],
        correctAnswers: ["При скорости 0,1 - 0,5 мм/г"],
      },
      {
        code: "20302185",
        text: "Какой допускается максимальный подъем температуры газа в газопроводах?",
        answers: ["85 °C", "90 °C", "75 °C", "80°C"],
        correctAnswers: ["85 °C"],
      },
      {
        code: "20302186",
        text: "В каком случае допускается подача газа без поднесения запальника, факела или другого воспламеняющего средства к горелке газопровода?",
        answers: [
          "Если температура кладки в разогретой зоне розжига факела превышает 800 °C",
          "Если температура кладки в разогретой зоне розжига факела превышает 850 °C",
          "Если температура кладки в разогретой зоне розжига факела превышает 900 °C",
          "Если температура кладки в разогретой зоне розжига факела превышает 815 °C",
        ],
        correctAnswers: [
          "Если температура кладки в разогретой зоне розжига факела превышает 800 °C",
        ],
      },
      {
        code: "20302187",
        text: "Кто осуществляет выбор типа быстродействующего отсекающего клапана на подводе газа к каждому агрегату с дутьевыми горелками?",
        answers: [
          "Организация, проектирующая систему автоматики печи",
          "Организация, эксплуатирующая объект",
          "Руководителем организации",
          "Сервисными службами изготовителя",
        ],
        correctAnswers: ["Организация, проектирующая систему автоматики печи"],
      },
      {
        code: "20302188",
        text: "Какое из перечисленных требований при эксплуатации газового оборудования газопотребляющих агрегатов указано верно?",
        answers: [
          "Розжиг газопотребляющего агрегата должен проводиться в соответствии с технологической инструкцией или инструкцией по пуску и остановке агрегата",
          "Проверка технического состояния промышленных дымоотводящих устройств (газоходов газоиспользующего оборудования, дымоходов и дымовых труб) и их прочистка должны производиться один раз в год",
          "Пылеуборка и дезинфекция вентиляционных каналов должны проводиться один раз в три года",
        ],
        correctAnswers: [
          "Розжиг газопотребляющего агрегата должен проводиться в соответствии с технологической инструкцией или инструкцией по пуску и остановке агрегата",
        ],
      },
      {
        code: "20302189",
        text: "С какой периодичностью должна производиться режимная наладка газоиспользующего оборудования?",
        answers: [
          "Не реже одного раза в 3 года",
          "Не реже одного раза в год",
          "Не реже двух раз в год",
          "Не реже одного раза в 4 года",
        ],
        correctAnswers: ["Не реже одного раза в 3 года"],
      },
      {
        code: "20302190",
        text: "В каком случае допускается режимную наладку газоиспользующего оборудования проводить не реже одного раза в четыре года?",
        answers: [
          "В случае стабильной работы газоиспользующего оборудования",
          "В любом случае",
          "После капитального ремонта газоиспользующего оборудования или внесения конструктивных изменений, влияющих на эффективность использования газа",
          "В случае систематических отклонений контролируемых параметров работы газоиспользующего оборудования от режимных карт",
        ],
        correctAnswers: [
          "В случае стабильной работы газоиспользующего оборудования",
        ],
      },
      {
        code: "20302191",
        text: "С какой периодичностью должна производиться проверка технического состояния вентиляционных каналов в зданиях (помещениях) с установленным газоиспользующим оборудованием и пылеуборка, и дезинфекция вентиляционных каналов?",
        answers: [
          "С установленным газоиспользующим оборудованием – не реже одного раза в 3 года, а пылеуборка и дезинфекция вентиляционных каналов – не реже двух раз в пять лет",
          "С установленным газоиспользующим оборудованием – не реже двух раз в 3 года, а пылеуборка и дезинфекция вентиляционных каналов – не реже одного раза в пять лет",
          "С установленным газоиспользующим оборудованием – не реже двух раз в год, а пылеуборка и дезинфекция вентиляционных каналов – не реже одного раза в три года",
          "С установленным газоиспользующим оборудованием – не реже одного раза в год, а пылеуборка и дезинфекция вентиляционных каналов – не реже двух раз в четыре года",
        ],
        correctAnswers: [
          "С установленным газоиспользующим оборудованием – не реже двух раз в год, а пылеуборка и дезинфекция вентиляционных каналов – не реже одного раза в три года",
        ],
      },
      {
        code: "20302192",
        text: "С какой периодичностью должны проводиться испытания на плотность и прочность холодных участков (с температурой до 200 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?",
        answers: [
          "Один раз в 8 лет",
          "Один раз в 5 лет",
          "Один раз в 3 года",
          "Два раза в 6 лет",
        ],
        correctAnswers: ["Один раз в 8 лет"],
      },
      {
        code: "20302193",
        text: "С какой периодичностью должны проводиться испытания на плотность и прочность горячих участков (с температурой 200 - 400 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?",
        answers: [
          "Два раза в 6 лет",
          "Один раз в 8 лет",
          "Один раз в 5 лет",
          "Один раз в 3 года",
        ],
        correctAnswers: ["Один раз в 5 лет"],
      },
      {
        code: "20302194",
        text: "В каких местах допускается прокладка водородопроводов?",
        answers: [
          "В пределах предприятий преимущественно над поверхностью земли, на стойках и эстакадах",
          "По наружным стенам производственных зданий",
          "Через бытовые, подсобные административно-хозяйственные, складские помещения",
          "Через производственные помещения, не связанные с потреблением водорода",
        ],
        correctAnswers: [
          "В пределах предприятий преимущественно над поверхностью земли, на стойках и эстакадах",
        ],
      },
      {
        code: "20302195",
        text: "Через какие помещения допускается прокладка водородопроводов?",
        answers: [
          "Прокладка водопровода через помещения не допускается",
          "Через бытовые, подсобные административно-хозяйственные, складские помещения",
          "Через производственные помещения, не связанные с потреблением водорода",
          "Через электрораспределительные, трансформаторные помещения",
        ],
        correctAnswers: [
          "Прокладка водопровода через помещения не допускается",
        ],
      },
      {
        code: "20302196",
        text: "Какие требования к размещению запорной арматуры на водородопроводах указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Запорная арматура и отсекающие устройства с дистанционным управлением должны устанавливаться вне здания на расстоянии не менее 3,0 м и не более 50,0 м от места ввода в здание или ближайшего аппарата, стоящего вне здания",
          "В отдельных случаях допускается применение запорной арматуры из ковкого и высокопрочного чугуна при давлении не более 1,0 МПа и температуре от 0 °C до 50 °C, работающей в условиях, не подверженных вибрациям и резко переменного температурного режима",
          "Арматуру из серого чугуна применять на водородопроводах не допускается",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20302197",
        text: "Какое из перечисленных требований при эксплуатации взрывопожароопасных объектов указано неверно?",
        answers: [
          "В помещениях категории A проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией должна производиться в сроки, предусмотренные заводской инструкцией, но не реже одного раза в 3 месяца",
          "На каждой двери помещения категории A должны быть указаны категория помещения и класс взрывоопасной зоны",
          "Работы по ремонту электрооборудования в помещениях категории A должны выполняться после обесточивания электросети",
          "В помещениях категории A хранение обтирочных и смазочных материалов допускается в количестве, не превышающем суточной потребности, в металлических, плотно закрывающихся шкафах или ящиках",
        ],
        correctAnswers: [
          "В помещениях категории A проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией должна производиться в сроки, предусмотренные заводской инструкцией, но не реже одного раза в 3 месяца",
        ],
      },
      {
        code: "20302198",
        text: "Какой должна быть объемная доля кислорода в воздухе производственных помещений производства ПРВ?",
        answers: [
          "Не менее 19% и не более 23%",
          "Не менее 18% и не более 24%",
          "Не менее 17% и не более 25%",
        ],
        correctAnswers: ["Не менее 19% и не более 23%"],
      },
      {
        code: "20302199",
        text: "Что необходимо устанавливать в производственных помещениях, где возможны воспламенение одежды или химические ожоги у работников?",
        answers: [
          "Огнетушители",
          "Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
          "Ящик с асбестовым полотном",
        ],
        correctAnswers: [
          "Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
      },
      {
        code: "20302200",
        text: "После установления каких показателей температуры разрешается проводить ремонтные работы внутри нагретых технических устройств, а также низкотемпературных технических устройств?",
        answers: [
          "После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания",
          "После снижения температуры воздуха до 45 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания",
          "После снижения температуры воздуха до 50 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания",
        ],
        correctAnswers: [
          "После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания",
        ],
      },
      {
        code: "20302201",
        text: "При каких условиях допускается перенос сроков проведения капитальных ремонтов основного технологического оборудования?",
        answers: [
          "При наличии положительного заключения экспертного обследования",
          "При наличии разрешения завода-изготовителя",
          "При наличии письменного распоряжения руководителя производства (цеха)",
        ],
        correctAnswers: [
          "При наличии положительного заключения экспертного обследования",
        ],
      },
      {
        code: "20302202",
        text: "Каким образом должен проводиться отогрев трубопроводной арматуры?",
        answers: [
          "Горячей водно-масляной эмульсией",
          "Горячим воздухом, паром или горячей водой",
          "Паяльной лампой",
        ],
        correctAnswers: ["Горячим воздухом, паром или горячей водой"],
      },
      {
        code: "20302203",
        text: "Каким должно быть расстояние от устройства забора воздуха и камер всасывания работающих воздушных компрессоров ВРУ при выполнении ремонтных работ, работ по газовой сварке и резке металла, чтобы для их выполнения было необходимо наличие письменного разрешения руководителя производства (цеха) и наряда-допуска?",
        answers: ["Менее 100 м", "Менее 110 м", "Менее 120 м", "Менее 130 м"],
        correctAnswers: ["Менее 100 м"],
      },
      {
        code: "20302204",
        text: "С какой периодичностью следует очищать фильтры?",
        answers: [
          "В первый раз - через 15 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
          "В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
          "В первый раз - через 20 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
        ],
        correctAnswers: [
          "В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
        ],
      },
      {
        code: "20302205",
        text: "Как часто должен производиться осмотр трубопроводов кислорода?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в 2 месяца",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20302206",
        text: "Как часто должна производиться проверка конденсатоотводчиков и пароспутников, обогревающих трубопроводы кислорода в зимнее время?",
        answers: ["Ежедневно", "Еженедельно", "Ежедекадно"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "20302207",
        text: "Как часто должна проводиться проверка правильности показаний приборов, перечень которых утверждается руководителем производства?",
        answers: [
          "Не реже одного раза в 12 месяцев",
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в 3 месяца",
        ],
        correctAnswers: ["Не реже одного раза в 3 месяца"],
      },
      {
        code: "20302208",
        text: "Какое количество баллонов с эталонными и поверочными газовыми смесями разрешается хранить в помещении хроматографии?",
        answers: ["Не более двух", "Не более трех", "Не более четырех"],
        correctAnswers: ["Не более двух"],
      },
      {
        code: "20302209",
        text: "Из каких материалов выполняются линии отбора кислорода на анализ?",
        answers: [
          "Из углеродистой стали",
          "Из коррозионно-стойкой стали или медных сплавов",
          "Из чугуна",
        ],
        correctAnswers: ["Из коррозионно-стойкой стали или медных сплавов"],
      },
      {
        code: "20302210",
        text: "Какой установлен порядок хранения обтирочных материалов?",
        answers: [
          "В чистых металлических ящиках с крышками",
          "В специальной герметичной таре",
          "В чистых деревянных ящиках, закрытых на замок",
        ],
        correctAnswers: ["В чистых металлических ящиках с крышками"],
      },
      {
        code: "20302211",
        text: "Из каких материалов не могут быть изготовлены разделители (заглушки) или другие изделия, помещаемые внутрь кислородопровода при его обезжиривании?",
        answers: [
          "Из поролона или других органических материалов",
          "Из латуни",
          "Из меди",
        ],
        correctAnswers: ["Из поролона или других органических материалов"],
      },
      {
        code: "20302212",
        text: "Из каких сплавов металлов запрещается установка арматуры на кислородопроводах?",
        answers: ["Из чугуна", "Из стали", "Из сплавов титана"],
        correctAnswers: ["Из сплавов титана"],
      },
      {
        code: "20302213",
        text: "Какие действия необходимо осуществить перед ремонтом клапанов (трубопроводов) теплого и холодного концов регенераторов в период остановки ВРУ? Выберите правильный вариант ответа.",
        answers: [
          "Систему автоматического управления регенераторов следует перевести на ручной режим",
          "С помощью дистанционного управления закрыть или открыть соответствующие клапаны",
          'Установить предупреждающий знак безопасности "Не включать, работают люди!"',
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20302214",
        text: "При каком условии ВРУ должна подвергаться обезжириванию?",
        answers: [
          "При достижении концентрации масла в жидком кислороде 0,50 мг/дм",
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше",
          "При достижении концентрации масла в жидком кислороде 0,70 мг/дм и выше",
          "При достижении концентрации масла в жидком кислороде 1,0 мг/дм",
        ],
        correctAnswers: [
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше",
        ],
      },
      {
        code: "20302215",
        text: "Какие установлены сроки обезжиривания ВРУ, работающих по схеме высокого и среднего давлений с блоками комплексной очистки на цеолитах и турбодетандерами, а также по схеме низкого давления?",
        answers: [
          "Не регламентируются",
          "Не реже 1 раза в 3 месяца",
          "2 раза в 1 год",
          "1 раз в месяц",
        ],
        correctAnswers: ["Не регламентируются"],
      },
      {
        code: "20302216",
        text: "Что из перечисленного не подлежит обезжириванию в процессе эксплуатации ВРУ?",
        answers: [
          "Колонны высокого и низкого давления",
          "Регенераторы (при переработке воздуха, сжимаемого компрессорами со смазываемыми цилиндрами)",
          "Аппараты и коммуникации на потоке жидкого кислорода",
          "Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа",
        ],
        correctAnswers: [
          "Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа",
        ],
      },
      {
        code: "20302217",
        text: "Какое из перечисленных требований при необходимости проведения обезжиривания технических устройств и трубопроводов ПРВ растворителями указано неверно?",
        answers: [
          "Осмотр технологических трубопроводов и технических устройств ПРВ должен производиться комиссией на производстве один раз в год",
          "О работах, проведенных во исполнение мероприятий, указанных в распоряжении, необходимо делать записи в агрегатном журнале и отметку в паспорте (по принадлежности)",
          "Трубопроводы слива жидких ПРВ из аппаратов технических устройств в испаритель необходимо отогревать до положительных температур до и после каждого слива в соответствии с технологической инструкцией",
          "Результаты осмотра должны оформляться актами, на основании которых издается распоряжение по подразделению с указанием необходимых мероприятий по устранению выявленных дефектов и работам при выполнении планово-предупредительных ремонтов",
        ],
        correctAnswers: [
          "Осмотр технологических трубопроводов и технических устройств ПРВ должен производиться комиссией на производстве один раз в год",
        ],
      },
      {
        code: "20302218",
        text: "До какой максимальной температуры допускается нагрев поверхностей в помещениях, где производится обезжиривание кислородного оборудования пожаровзрывоопасными растворителями?",
        answers: ["120 °C", "130 °C", "150 °C", "170 °C"],
        correctAnswers: ["120 °C"],
      },
      {
        code: "20302219",
        text: "До какой температуры должен отогреваться сосуд, бывший в эксплуатации, перед проведением работ по обезжириванию способом протирки и чем он должен продуваться?",
        answers: [
          "Не ниже 15 °C и продувается азотом",
          "Не ниже 20 °C и продувается воздухом",
          "Не ниже 20 °C и продувается азотом",
          "Не ниже 10 °C и продувается кислород",
        ],
        correctAnswers: ["Не ниже 20 °C и продувается воздухом"],
      },
      {
        code: "20302220",
        text: "Какое из перечисленных требований к потреблению газообразного кислорода и других продуктов разделения воздуха указано неверно?",
        answers: [
          "На кислородопроводах допускается установка арматуры из сплавов на основе титана",
          "Не допускается устанавливать и применять кислородное оборудование возле замасляного или прожированного оборудования, территории",
          "Использование продуктов разделения воздуха не по назначению не допускается",
          "Использование продуктов разделения воздуха по каждому производству, участку или объекту должно осуществляться по технологическим инструкциям",
        ],
        correctAnswers: [
          "На кислородопроводах допускается установка арматуры из сплавов на основе титана",
        ],
      },
      {
        code: "20302221",
        text: "Какое значение не должно превышать содержание сероводорода в коксовом газе в заводских сетях действующих предприятий?",
        answers: ["5 г/нм3", "4 г/нм3", "6 г/нм3", "5,5 г/нм3"],
        correctAnswers: ["4 г/нм3"],
      },
      {
        code: "20302222",
        text: "Какое значение не должно превышать содержание сероводорода в коксовом газе при газоплазменной обработке металла в закрытых помещениях, а также нафталина летом и зимой?",
        answers: [
          "Сероводорода – 0,05 г/нм3, нафталина – 0,06 г/нм3 (летом) и 0,15 г/нм3 (зимой)",
          "Сероводорода – 0,02 г/нм3, нафталина – 0,05 г/нм3 (летом) и 0,1 г/нм3 (зимой)",
          "Сероводорода – 0,08 г/нм3, нафталина – 0,07 г/нм3 (летом) и 0,2 г/нм3 (зимой)",
          "Сероводорода –  0,2 г/нм3, нафталина – 0,15 г/нм3 (летом) и 0,16 г/нм3 (зимой)",
        ],
        correctAnswers: [
          "Сероводорода – 0,02 г/нм3, нафталина – 0,05 г/нм3 (летом) и 0,1 г/нм3 (зимой)",
        ],
      },
      {
        code: "20302223",
        text: "Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей?",
        answers: [
          "Системами регулирования заданного соотношения топлива, воздуха и водяного пара",
          "Средствами сигнализации о прекращении поступления топлива, а также воздуха при его принудительной подаче в топочное пространство",
          "Падением давления нагреваемого продукта на выходе из печи ниже регламентированного значения",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20302224",
        text: "Какие требования из перечисленных должны предусматриваться для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты? Выберите правильный вариант ответа.",
        answers: [
          "Дистанционное отключение",
          "Установка на линиях всасывания запорных или отсекающих устройств",
          "Установка на линиях нагнетания запорных или отсекающих устройств",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20302225",
        text: "В каком случае все технологическое оборудование должно продуваться инертным газом? Выберете все правильные варианты ответа.",
        answers: [
          "В случае остановки оборудования более чем на 2 часа",
          "В случае остановки оборудования на 1 час",
          "В случае пуска оборудования",
          "В случае установки оборудования",
        ],
        correctAnswers: [
          "В случае остановки оборудования более чем на 2 часа",
          "В случае пуска оборудования",
        ],
      },
      {
        code: "20302226",
        text: "При каком содержании водорода в воздухе производственного помещения по сигналу от автоматического газоанализатора технологическое оборудование этого помещения должно быть остановлено?",
        answers: [
          "Выше 25% нижнего предела взрываемости",
          "Выше 10% нижнего предела взрываемости",
          "Выше 15% нижнего предела взрываемости",
          "Выше 5% нижнего предела взрываемости",
        ],
        correctAnswers: ["Выше 25% нижнего предела взрываемости"],
      },
      {
        code: "20302227",
        text: "При каком содержании газов должна срабатывать световая и звуковая сигнализации, установленные с автоматическими газоанализаторами в помещениях категории А, где обращается водород?",
        answers: [
          "Не более 10% нижнего предела взрываемости",
          "Не более 15% нижнего предела взрываемости",
          "Не более 12% нижнего предела взрываемости",
          "Не более 13% нижнего предела взрываемости",
        ],
        correctAnswers: ["Не более 10% нижнего предела взрываемости"],
      },
      {
        code: "20302228",
        text: "Какие требования к вентиляции помещения, где возможно выделение водорода, указаны верно? Выберите 2 варианта ответа.",
        answers: [
          "В помещениях должна быть установлена естественная вентиляция из верхней зоны через дефлекторы в объеме не менее однократного часа",
          "В площадках должны быть предусмотрены проемы, закрытые в необходимых случаях решетками",
          "В помещениях должна быть установлена аварийная вытяжная система",
          "В помещениях должны быть установлены механические приточно-вытяжные общеобменные вентиляции с кратностью воздухообмена не менее 6 в час",
        ],
        correctAnswers: [
          "В помещениях должна быть установлена естественная вентиляция из верхней зоны через дефлекторы в объеме не менее однократного часа",
          "В площадках должны быть предусмотрены проемы, закрытые в необходимых случаях решетками",
        ],
      },
      {
        code: "20302229",
        text: "При каком максимальном содержании сероводорода в газе допускается установка бронзовых кранов или задвижек с бронзовыми кольцами на газопроводах?",
        answers: ["20 мг/м3", "30 мг/м3", "25 мг/м3", "23 мг/м3"],
        correctAnswers: ["20 мг/м3"],
      },
      {
        code: "20302230",
        text: "Какое из перечисленных требований к задвижкам и заглушкам, устанавливаемым на газопроводах, указано неверно?",
        answers: [
          "Перекрывающие листы заглушек и листовых задвижек должны быть рассчитаны на соответствующее давление газа с учетом диаметра газопровода, при этом толщина их должна быть не менее 2 мм",
          "Для плотного отключения отдельных участков газопроводов, газопотребляющих агрегатов и газовых аппаратов от действующих газопроводов после дисковых задвижек (по ходу газа) должны устанавливаться листовые задвижки или заглушки",
          "Съемные заглушки, устанавливаемые на трубопроводах, должны быть изготовлены согласно проектной документации и иметь паспорт (сертификат) изготовителя",
          "Паспорта (сертификаты) на съемные заглушки должны храниться у лица, ответственного за техническое состояние данного объекта газового хозяйства, или в журнале учета установки-снятия заглушек",
        ],
        correctAnswers: [
          "Перекрывающие листы заглушек и листовых задвижек должны быть рассчитаны на соответствующее давление газа с учетом диаметра газопровода, при этом толщина их должна быть не менее 2 мм",
        ],
      },
      {
        code: "20302231",
        text: "Какая информация должна быть выбита на хвостовике каждой съемной заглушки газопровода?",
        answers: [
          "Только номер сертификата",
          "Только номер заглушки",
          "Только марка материала",
          "Только условное давление Pу и условный диаметр Ду",
          "Вся перечисленная информация",
        ],
        correctAnswers: ["Вся перечисленная информация"],
      },
      {
        code: "20302232",
        text: "Какая должна быть минимальная высота водяного затвора конденсатоотводчиков межцеховых газопроводов, работающих под избыточным давлением?",
        answers: [
          "На 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст",
          "На 300 мм вод. ст. больше расчетного давления газа, но не менее 1000 мм вод. ст",
          "На 350 мм вод. ст. больше расчетного давления газа, но не менее 1500 мм вод. ст",
          "На 400 мм вод. ст. больше расчетного давления газа, но не менее 800 мм вод. ст",
        ],
        correctAnswers: [
          "На 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст",
        ],
      },
      {
        code: "20302233",
        text: "Какое из перечисленных требований к отводу конденсата из цеховых газопроводов указано неверно? Выберите правильный вариант ответа.",
        answers: [
          "Сброс конденсата через горелки печей и другого оборудования потребителя газа допускается в исключительных случаях",
          "Конструкция конденсатоотводчиков, установленных в зданиях цехов, должна исключать возможность попадания газов в помещения",
          "Участки труб, отводящих конденсат, а также сами конденсатоотводчики, если цех не отапливается, должны быть утеплены",
          "Сброс конденсата из цеховых газопроводов следует производить через конденсатоотводчики",
        ],
        correctAnswers: [
          "Сброс конденсата через горелки печей и другого оборудования потребителя газа допускается в исключительных случаях",
        ],
      },
      {
        code: "20302234",
        text: "Какое из перечисленных требований к стационарным газоразборным постам указано неверно?",
        answers: [
          "При отсутствии рабочего, пользующегося газоразборным постом, шкаф должен быть закрыт на замок",
          "Газоразборные посты, шкафы должны иметь опознавательную окраску",
          "Расстояние между шкафами газоразборных постов для кислорода и горючего газа должно быть не менее 100 мм",
          "Стационарные газоразборные посты должны быть размещены в металлических шкафах с отверстиями для вентиляции",
        ],
        correctAnswers: [
          "Расстояние между шкафами газоразборных постов для кислорода и горючего газа должно быть не менее 100 мм",
        ],
      },
      {
        code: "20302235",
        text: "Содержание каких из перечисленных газов измеряется в продуктах неполного сгорания за дымососом при отводе газов без дожигания? Выберите 2 варианта ответа",
        answers: ["CO", "CO﻿2", "O2", "H2"],
        correctAnswers: ["CO", "O2"],
      },
      {
        code: "20302236",
        text: "Какое минимальное остаточное давление должны иметь баллоны, подготовленные для наполнения водородом?",
        answers: ["0,05 МПа", "0,02 МПа", "0,01 МПа", "0,015 МПа"],
        correctAnswers: ["0,05 МПа"],
      },
      {
        code: "20302237",
        text: "На какой высоте от пола должны быть расположены окна в помещениях отделений наполненных баллонов, наполнительных и разрядных рамп?",
        answers: [
          "Не менее 1,5 м",
          "Не менее 0,5 м",
          "Не менее 1,2 м",
          "Не менее 1,0 м",
        ],
        correctAnswers: ["Не менее 1,5 м"],
      },
      {
        code: "20302238",
        text: "Стальными листами какой толщины должны отделяться от рабочей зоны баллоны с водородом при их горизонтальном расположении и размещении внутри помещения?",
        answers: [
          "Не менее 16 мм",
          "Не менее 9 мм",
          "Не менее 5 мм",
          "Не менее 10 мм",
        ],
        correctAnswers: ["Не менее 16 мм"],
      },
      {
        code: "20302239",
        text: "При какой чистоте водорода должен производиться пуск компрессора для наполнения баллонов?",
        answers: [
          "Не менее 99,7%",
          "Не менее 96,5%",
          "Не менее 98,0%",
          "Не менее 97,7%",
        ],
        correctAnswers: ["Не менее 99,7%"],
      },
      {
        code: "20302240",
        text: "Какие требования к компрессорным установкам указаны неверно? Выберите правильный вариант ответа.",
        answers: [
          "Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь",
          "Компрессор и его электродвигатель должны устанавливаться на фундаментах связанных с конструкциями здания",
          "Качество изготовления компрессорных установок должно соответствовать только требованиям нормативно-технической документации",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20302241",
        text: "Какие требования к компрессорным установкам указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "В помещении компрессорной установки следует предусматривать специальные места для хранения в закрытом виде обтирочных материалов, инструмента, прокладок, а также для хранения недельного запаса масла",
          "Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи",
          "Не допускается хранение ЛВЖ в помещении машинного зала компрессорной установки",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20302242",
        text: "При каком избыточном давлении газа в газопроводах разрешается производить электросварочные работы на газопроводах при ремонтах?",
        answers: [
          "Не более 0,3 кПа",
          "Не более 0,4 кПа",
          "Не более 0,5 кПа",
          "Не более 0,6 кПа",
        ],
        correctAnswers: ["Не более 0,3 кПа"],
      },
      {
        code: "20302243",
        text: "Какие действия должны быть произведены перед продувкой газопровода? Выберите правильный вариант ответа.",
        answers: [
          "Задвижки должны быть установлены в нужное положение",
          "Водяные затворы должны быть залиты водой",
          "Все люки, лазы и свечи должны быть плотно закрыты, за исключением той свечи, через которую будет производится продувка",
          "Все перечисленные действия",
        ],
        correctAnswers: ["Все перечисленные действия"],
      },
      {
        code: "20302244",
        text: "В каком радиусе у мест выброса газовоздушной смеси в атмосферу должно быть приостановлено движение всех видов транспорта, запрещено применение открытого огня и производство сварочных работ в период продувки газопровода?",
        answers: [
          "В радиусе 50 м",
          "В радиусе 40 м",
          "В радиусе 35 м",
          "В радиусе 22 м",
        ],
        correctAnswers: ["В радиусе 50 м"],
      },
      {
        code: "20302245",
        text: "В каком случае допускается использовать продувочные свечи газопроводов для выпуска в атмосферу избыточного газа?",
        answers: [
          "Не допускается ни в каком случае",
          "В любом случае",
          "В аварийном",
        ],
        correctAnswers: ["В аварийном"],
      },
      {
        code: "20302246",
        text: "С какой периодичностью должны производиться проверка технического состояния промышленных дымоотводящих устройств (газоходов газоиспользующего оборудования, дымоходов и дымовых труб) и их прочистка?",
        answers: [
          "Не реже одного раза в три года",
          "Не реже двух раз в год",
          "Не реже одного раза в год",
          "Не реже двух раз в три года",
        ],
        correctAnswers: ["Не реже двух раз в год"],
      },
      {
        code: "20302247",
        text: "В каком положении должны находиться баллоны со сжиженным газом во время работы?",
        answers: [
          "В вертикальном",
          "В наклонном",
          "В наклонном, направленным вверх с вентилем",
        ],
        correctAnswers: ["В вертикальном"],
      },
      {
        code: "20302248",
        text: "Какие работы производятся на ОПО горно-металлургических производств? Выберите правильный вариант ответа.",
        answers: [
          "Только постоянные работы, выполняемые в соответствии с документированной процедурой (инструкцией, картой, регламентом), принятой в организации",
          "Только работы повышенной опасности (сложные, разовые, уникальные)",
          "Только работы, связанные с аварийными ситуациями",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20302249",
        text: "Какие из перечисленных требований к акту-допуску подрядных организаций на опасный производственный объект горно-металлургической промышленности указаны неверно?",
        answers: [
          "Акт-допуск должен оформляться письменно",
          "На один объект, площадку, территорию должен оформляться один акт-допуск",
          "Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)",
          "Не допускается оформлять акты-допуски, характеризующиеся одинаковыми координатами рабочих зон",
        ],
        correctAnswers: [
          "Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)",
        ],
      },
      {
        code: "20302250",
        text: "Какие действия предпринимаются при необходимости ведения работ после истечения срока действия акта-допуска подрядных организаций на опасный производственный объект горно-металлургической промышленности?",
        answers: [
          "Составляется новый акт-допуск на следующий срок",
          "Продлевается текущий акт-допуск",
          "Работы продолжаются без оформления акта-допуска",
        ],
        correctAnswers: ["Составляется новый акт-допуск на следующий срок"],
      },
      {
        code: "20302251",
        text: "В каком количестве экземпляров составляется акт-допуск подрядных организаций на опасный производственный объект горно-металлургической промышленности?",
        answers: [
          "В одном экземпляре",
          "В двух экземплярах",
          "В трех экземплярах",
          "В четырех экземплярах",
        ],
        correctAnswers: ["В двух экземплярах"],
      },
      {
        code: "20302252",
        text: "С кем согласовывается перечень работ повышенной опасности на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "С руководителем профессиональной аварийно-спасательной службы",
          "С территориальным органом Ростехнадзора",
          "Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля",
          "С техническим руководителем (главным инженером) организации",
        ],
        correctAnswers: [
          "Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля",
        ],
      },
      {
        code: "20302253",
        text: "Кем могут проводиться работы повышенной опасности на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "Как работниками эксплуатирующей организации, так и работниками подрядных организаций",
          "Только работниками подрядных организаций",
          "Только работниками эксплуатирующей организации",
        ],
        correctAnswers: [
          "Как работниками эксплуатирующей организации, так и работниками подрядных организаций",
        ],
      },
      {
        code: "20302254",
        text: "В течение какого времени хранится журнал регистрации нарядов-допусков со дня внесения последней записи?",
        answers: [
          "В течение 6 месяцев",
          "В течение месяца",
          "В течение 5 месяцев",
          "В течение 3 месяцев",
        ],
        correctAnswers: ["В течение 6 месяцев"],
      },
      {
        code: "20302255",
        text: "На какой максимальный срок выдается наряд-допуск на работы повышенной опасности?",
        answers: [
          "На 20 календарных дней",
          "На 30 календарных дней",
          "На 15 календарных дней",
          "На 45 календарных дней",
        ],
        correctAnswers: ["На 30 календарных дней"],
      },
      {
        code: "20302256",
        text: "Кто определяет (назначает) работников, выполняющих мероприятия по безопасности труда, указанных в наряде-допуске (ответственных исполнителей)?",
        answers: [
          "Лицо, ответственное за осуществление производственного контроля",
          "Выдающий наряд-допуск",
          "Производитель (руководитель) работ",
          "Допускающий к работе",
        ],
        correctAnswers: ["Выдающий наряд-допуск"],
      },
      {
        code: "20302257",
        text: "Какие из перечисленных требований к наряду-допуску на работы повышенной опасности указаны неверно? Выберите два правильных ответа.",
        answers: [
          "После окончания работы наряд-допуск остается у производителя (руководителя) работ",
          "При перерыве в работе в течение одной смены (обеденном перерыве, перерыве по условиям производства работ) наряд-допуск остается у производителя (руководителя) работ",
          "Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ",
          "При допуске бригады к работе допускающий к работе вручает первый экземпляр наряда-допуска производителю работ, а второй экземпляр наряда-допуска хранится вместе с нарядами, действующими на этот момент времени",
        ],
        correctAnswers: [
          "После окончания работы наряд-допуск остается у производителя (руководителя) работ",
          "Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ",
        ],
      },
      {
        code: "20302258",
        text: "Какое количество производителей (руководителей) работ назначаются на все время действия наряда-допуска на работы повышенной опасности при производстве работ в несколько смен?",
        answers: [
          "Несколько производителей (руководителей) работ в соответствии с количеством смен",
          "Один производитель (руководитель) работ",
          "Количество производителей (руководителей) работ определяет выдающий наряд-допуск",
          "Не регламентируется",
        ],
        correctAnswers: [
          "Несколько производителей (руководителей) работ в соответствии с количеством смен",
        ],
      },
      {
        code: "20302259",
        text: "В течение какого времени хранятся закрытые (подписанные) наряды-допуски на работы повышенной опасности?",
        answers: [
          "В течение 20 дней",
          "В течение 10 дней",
          "В течение одного месяца",
          "В течение трех месяцев",
        ],
        correctAnswers: ["В течение одного месяца"],
      },
      {
        code: "20302260",
        text: "Какие действия должны быть выполнены при утрате наряда-допуска на работы повышенной опасности?",
        answers: [
          "Работы должны быть продолжены. Оформление нового наряда-допуска не требуется",
          "Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново",
          "Работы должны быть прекращены. Для продолжения работ должна быть внесена запись в журнал регистрации нарядов-допусков на работы повышенной опасности",
          "Решение о продолжении или прекращении работы определяет выдающий наряд-допуск",
        ],
        correctAnswers: [
          "Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново",
        ],
      },
      {
        code: "20302261",
        text: "В каких из перечисленных случаях допускается продолжать работы, проводимые по наряду-допуску, на опасных производственных объектах?",
        answers: [
          "Если изменился состав бригады",
          "Если выявлено нарушение условий отключения технических устройств",
          "Если характер и объемы работ изменены в такой степени, что требуется изменение схемы отключения технического устройства и порядка выполнения работ",
          "Если обнаружено несоответствие фактического состояния производства работ требованиям безопасности и охраны труда",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Если изменился состав бригады"],
      },
      {
        code: "20302262",
        text: "На какие группы подразделяются газоопасные места на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "На I и II группы газоопасности",
          "На I, II и III группы газоопасности",
          "На I, II, III и IV группы газоопасности",
        ],
        correctAnswers: ["На I и II группы газоопасности"],
      },
      {
        code: "20302263",
        text: "С кем согласовывается инструкция, определяющая порядок подготовки и безопасного проведения газоопасных работ на опасных производственных объектах горно-металлургической промышленности? Выберите два правильных ответа.",
        answers: [
          "С газоспасательной службой (ГСС)",
          "Со службой производственного контроля",
          "С территориальным органом Ростехнадзора",
          "С главным энергетиком",
        ],
        correctAnswers: [
          "С газоспасательной службой (ГСС)",
          "Со службой производственного контроля",
        ],
      },
      {
        code: "20302264",
        text: "Каким составом должны выполняться газоопасные работы I и II группы газоопасности с образованием взрывопожароопасных смесей?",
        answers: [
          "Не менее двух человек под наблюдением газоспасателя или члена добровольной газоспасательной дружины",
          "Одним человеком под наблюдением газоспасателя или члена добровольной газоспасательной дружины",
          "Не менее трех человек",
          "Не менее двух человек",
        ],
        correctAnswers: [
          "Не менее двух человек под наблюдением газоспасателя или члена добровольной газоспасательной дружины",
        ],
      },
      {
        code: "20302265",
        text: "С кем согласовывается план организации и проведения газоопасной работы?",
        answers: [
          "Только с начальником газоспасательной службы",
          "Только со службой производственного контроля",
          "Только с ответственным представителем подразделения или организации (главным энергетиком)",
          "Со всеми перечисленными",
        ],
        correctAnswers: ["Со всеми перечисленными"],
      },
      {
        code: "20302266",
        text: "Какой стаж работы в газовом хозяйстве должны иметь лица, назначаемые ответственными руководителями и ответственными исполнителями газоопасных работ?",
        answers: [
          "Не менее одного года",
          "Полгода",
          "Два года",
          "Не регламентируется",
        ],
        correctAnswers: ["Не менее одного года"],
      },
      {
        code: "20302267",
        text: "Какое из перечисленных требований к применению жетон-бирочной системы указано неверно?",
        answers: [
          "Жетон-бирочная система применяется при выводе технического устройства (ТУ) на продолжительную остановку",
          "Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями",
          'Ответственный специалист, разобравший схему, принимает меры, исключающие ошибочное или самопроизвольное включение пусковых устройств, на пусковых устройствах вывешивает предупредительные плакаты "Не включать! Работают люди", проверяет отсутствие энергетического потенциала',
          "Жетон-бирки после разборки электрической схемы ТУ передаются от технологического персонала ремонтному персоналу с отметкой всех этапов в журнале выдачи и приема жетон-бирок",
        ],
        correctAnswers: [
          "Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями",
        ],
      },
      {
        code: "20302268",
        text: "Какие из перечисленных ремонтных работ допускается производить одновременно при ремонте арматуры, расположенной на теплом и холодном концах регенераторов в период остановки ВРУ?",
        answers: [
          "Принудительных клапанов переключения регенераторов",
          'Системы "приказного" воздуха переключения регенераторов, или механизма переключения',
          "Никакие из перечисленных",
          "Трехходовых заслонок после регенераторов",
        ],
        correctAnswers: ["Никакие из перечисленных"],
      },
      {
        code: "20302269",
        text: "Какой предел огнестойкости должен быть у стен, разделяющих взрывоопасные помещения на водородных станциях?",
        answers: ["2,5 часа", "1,5 часа", "1 час", "2 часа"],
        correctAnswers: ["2,5 часа"],
      },
      {
        code: "20302270",
        text: "Кто утверждает инструкцию по перекачке сероуглерода из транспортной емкости в емкость хранения?",
        answers: [
          "Главный инженер предприятия",
          "Главный механик",
          "Руководитель организации",
        ],
        correctAnswers: ["Главный инженер предприятия"],
      },
      {
        code: "20302271",
        text: "Кем производится испытание газопроводов и газовых установок после окончания строительно-монтажных и сварочных работ (включая ремонтные работы)?",
        answers: [
          "Строительно-монтажной организацией",
          "Руководителем организации",
          "Главным инженером",
          "Главным механиком",
        ],
        correctAnswers: ["Строительно-монтажной организацией"],
      },
      {
        code: "20302272",
        text: "У кого должен находиться агрегатный (ремонтный) журнал на отдельных объектах газового цеха, а также в цехах, в составе которых имеются объекты газового хозяйства?",
        answers: [
          "У работника, ответственного за техническое состояние объекта газового хозяйства",
          "У начальника цеха",
          "У главного инженера",
        ],
        correctAnswers: [
          "У работника, ответственного за техническое состояние объекта газового хозяйства",
        ],
      },
      {
        code: "20302273",
        text: "В соответствии с требованиями какой документации должны производиться проектирование, монтаж и эксплуатация водородопроводов, а также вспомогательных трубопроводов обвязки участков комплекса получения и потребления водорода?",
        answers: [
          "Строительных норм и правил",
          "Нормативно-технической документации для технологических стальных трубопроводов",
          "Правил безопасности процессов получения или применения металлов",
          "Всей перечисленной документации",
        ],
        correctAnswers: ["Всей перечисленной документации"],
      },
    ],
    20303: [
      {
        code: "20303000",
        text: "Кто определяет (назначает) работников, выполняющих мероприятия по безопасности труда, указанных в наряде-допуске (ответственных исполнителей)?",
        answers: [
          "Начальник участка",
          "Технический руководитель организации",
          "Выдающий наряд-допуск",
          "Работник, ответственный за осуществление производственного контроля",
        ],
        correctAnswers: ["Выдающий наряд-допуск"],
      },
      {
        code: "20303001",
        text: "С кем согласовывается инструкция, определяющая порядок подготовки и безопасного проведения газоопасных работ на опасных производственных объектах горно-металлургической промышленности? Выберите два правильных ответа.",
        answers: [
          "Со службой производственного контроля",
          "С территориальным органом Ростехнадзора",
          "С главным инженером",
          "С ГСС",
        ],
        correctAnswers: ["Со службой производственного контроля", "С ГСС"],
      },
      {
        code: "20303002",
        text: "При каких условиях запрещается эксплуатация плавильных агрегатов?",
        answers: [
          "При незначительном повышении давления воды в системе охлаждения",
          "При разгерметизации системы водяного охлаждения этих агрегатов",
          "При незначительном повышении температуры воды в системе водоохлаждения",
        ],
        correctAnswers: [
          "При разгерметизации системы водяного охлаждения этих агрегатов",
        ],
      },
      {
        code: "20303003",
        text: "Какие из перечисленных требований к акту-допуску подрядных организаций на опасный производственный объект горно-металлургической промышленности указаны неверно?",
        answers: [
          "На один объект, площадку, территорию должен оформляться один акт-допуск",
          "Акт-допуск должен оформляться письменно",
          "Не допускается оформлять акты-допуски, характеризующиеся одинаковыми координатами рабочих зон",
          "Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)",
        ],
        correctAnswers: [
          "Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)",
        ],
      },
      {
        code: "20303004",
        text: "Какие требования установлены к процессу сливания расплава металла из ковшей и миксеров? Выберите правильный вариант ответа.",
        answers: [
          "Сливание расплава металла из ковшей и миксеров должно быть механизировано",
          "Ось сливного отверстия ковша, установленного под слив, должна совпадать с осью заливочного ковша",
          "Пол рабочей площадки в местах установки ковшей должен быть сухим",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20303005",
        text: "Какое требование к устройству внутренних производственных помещений, находящихся на производственной площадке, включающей технологию с расплавами металлов, имеющей опасность аварии или травмирования работников, указано неверно?",
        answers: [
          "Помещения должны иметь не менее двух выходов, расположенных с учетом аварийной эвакуации работников; двери помещения должны открываться наружу и не должны иметь внутренних запоров",
          "Помещения должны иметь защиту от воздействия теплового излучения",
          "Окна пультов управления, в которые возможно попадание брызг расплава, должны оборудоваться в соответствии с проектом",
          "Помещения пультов управления должны иметь вытяжную вентиляцию",
        ],
        correctAnswers: [
          "Помещения пультов управления должны иметь вытяжную вентиляцию",
        ],
      },
      {
        code: "20303006",
        text: "С кем согласовывается перечень работ повышенной опасности на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "С главным инженером",
          "С Ростехнадзором",
          "С руководителем газоспасательной службы",
          "Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля",
        ],
        correctAnswers: [
          "Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля",
        ],
      },
      {
        code: "20303007",
        text: "В течение какого времени хранятся закрытые (подписанные) наряды-допуски на работы повышенной опасности?",
        answers: [
          "В течение года",
          "В течение трех месяцев",
          "В течение одного месяца",
          "В течение шести месяцев",
        ],
        correctAnswers: ["В течение одного месяца"],
      },
      {
        code: "20303008",
        text: "С какой периодичностью должны проверяться на работоспособность вентиляционные системы и состояние ванн травления?",
        answers: ["Еженедельно", "Ежедневно", "Ежемесячно", "Ежеквартально"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "20303009",
        text: "Какие требования к производственным помещениям, оборудованию участков приготовления травильных растворов и травления металла указаны неверно? Выберите правильный вариант ответа.",
        answers: [
          "После работы у ванн травления с химически опасными веществами, используемыми в виде добавок для технологических растворов, вещества должны быть удалены из рабочей зоны на склад",
          "Все перечисленные",
          "Не допускается уборка, вызывающая распыление вредных веществ",
          "Периодичность проведения уборки помещений устанавливается в зависимости от условий их эксплуатации",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20303010",
        text: "Как должна устраняться пробуксовка ленты конвейера?",
        answers: [
          "Подсыпанием канифоли и других материалов",
          "Ручной регулировкой",
          "Соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты",
        ],
        correctAnswers: [
          "Соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты",
        ],
      },
      {
        code: "20303011",
        text: "Что не допускается к загрузке в агрегаты, содержащие расплавленный металл или шлак? Выберите правильный вариант ответа.",
        answers: [
          "Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции",
          "Не допускается все перечисленное",
          "Погрузка полых предметов в мульды, совки, короба, бадьи, ковши для дальнейшего использования их в качестве шихты для плавильных агрегатов",
        ],
        correctAnswers: ["Не допускается все перечисленное"],
      },
      {
        code: "20303012",
        text: "В каких из перечисленных случаях допускается продолжать работы, проводимые по наряду-допуску, на опасных производственных объектах?",
        answers: [
          "Если изменился состав бригады",
          "Если появилась угроза жизни и здоровью работников",
          "Если обнаружено несоответствие фактического состояния производства работ требованиям безопасности и охраны труда",
          "Если подан аварийный сигнал",
        ],
        correctAnswers: ["Если изменился состав бригады"],
      },
      {
        code: "20303013",
        text: "Какое количество производителей (руководителей) работ назначается на все время действия наряда-допуска на работы повышенной опасности при производстве работ в несколько смен?",
        answers: [
          "Количество производителей (руководителей) работ определяет работник, выдающий наряд-допуск",
          "Несколько производителей (руководителей) работ в соответствии с количеством смен",
          "Один производитель (руководитель) работ",
        ],
        correctAnswers: [
          "Несколько производителей (руководителей) работ в соответствии с количеством смен",
        ],
      },
      {
        code: "20303014",
        text: "Какие действия предпринимаются при необходимости ведения работ после истечения срока действия акта-допуска подрядных организаций на опасный производственный объект горно-металлургической промышленности?",
        answers: [
          "Работы продолжаются без оформления акта-допуска",
          "Составляется новый акт-допуск на следующий срок",
          "Продлевается акт-допуск, изначально оформленный на этот вид работ",
        ],
        correctAnswers: ["Составляется новый акт-допуск на следующий срок"],
      },
      {
        code: "20303015",
        text: "Какие работы производятся на ОПО горно-металлургических производств? Выберите правильный вариант ответа.",
        answers: [
          "Все перечисленные работы",
          "Только постоянные работы, выполняемые в соответствии с документированной процедурой (инструкцией, картой, регламентом), принятой в организации",
          "Только работы, связанные с аварийными ситуациями",
          "Только работы повышенной опасности (сложные, разовые, уникальные)",
        ],
        correctAnswers: ["Все перечисленные работы"],
      },
      {
        code: "20303016",
        text: "Какие требования предъявляются к устройству и эксплуатации печей для обжига шихтовых материалов и концентратов?",
        answers: [
          "Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 5%",
          "Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 10%",
          "Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции",
        ],
        correctAnswers: [
          "Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции",
        ],
      },
      {
        code: "20303017",
        text: "В течение какого времени хранится журнал регистрации нарядов-допусков со дня внесения последней записи?",
        answers: [
          "В течение 3 месяцев",
          "В течение года",
          "В течение месяца",
          "В течение 6 месяцев",
        ],
        correctAnswers: ["В течение 6 месяцев"],
      },
      {
        code: "20303018",
        text: "Что должно быть предусмотрено при проектировании конвейеров для подачи материалов? Выберите правильный вариант ответа.",
        answers: [
          "Все перечисленное",
          "Только ограждения приводных, натяжных и отклоняющих барабанов, натяжных устройств, канатов и блоков натяжных устройств, ременных, червячных, муфтовых и других передач",
          "Только исключение падения транспортируемых материалов",
          "Только предохраняющие устройства, отключающие приводы при перегрузке",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20303019",
        text: "Какие требования установлены к инструментам, приспособлениям и оснастке, используемым для ведения технологии или обслуживания оборудования?",
        answers: [
          "Должны быть предназначены для выполнения любых работ",
          "Должны находиться в свободном доступе",
          "Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними",
        ],
        correctAnswers: [
          "Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними",
        ],
      },
      {
        code: "20303020",
        text: "Как должны быть расположены пульты управления агрегатами?",
        answers: [
          "В непосредственной близости от агрегатов",
          "В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке",
          "На расстоянии не менее 5 м от агрегатов",
        ],
        correctAnswers: [
          "В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке",
        ],
      },
      {
        code: "20303021",
        text: "Какие требования установлены к проверке и испытанию приборов во взрывопожароопасных и пожароопасных помещениях?",
        answers: [
          "Должны производиться в условиях, исключающих искрообразование",
          "Должны осуществляться только в специально выделенном для этого помещении",
          "Должны производиться только в светлое время суток",
        ],
        correctAnswers: [
          "Должны производиться в условиях, исключающих искрообразование",
        ],
      },
      {
        code: "20303022",
        text: "В какой зоне не допускается нахождение людей?",
        answers: [
          "На расстоянии 15 м от зоны работы магнитного крана в случае, если кран работает внутри производственного здания",
          "На расстоянии 20 м от зоны работы грейферного крана в случае, если кран работает внутри производственного здания",
          "Во всех перечисленных зонах",
          "В зоне погрузки грейферными или магнитными кранами",
        ],
        correctAnswers: ["В зоне погрузки грейферными или магнитными кранами"],
      },
      {
        code: "20303023",
        text: "Что должно быть исключено в коммуникациях системы транспортирования взрывопожароопасных веществ и материалов?",
        answers: [
          "Разгерметизация системы",
          "Попадание воздуха",
          "Образование взрывоопасной среды",
        ],
        correctAnswers: ["Образование взрывоопасной среды"],
      },
      {
        code: "20303024",
        text: "Какие требования установлены к установке расходных баков с мазутом?",
        answers: [
          "Должны быть установлены на расстоянии не менее 10 м от печей и должны быть защищены паровой завесой",
          "Должны быть установлены на расстоянии не менее 15 м от печей",
          "Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением",
        ],
        correctAnswers: [
          "Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением",
        ],
      },
      {
        code: "20303025",
        text: "В каком количестве экземпляров составляется акт-допуск подрядных организаций на опасный производственный объект горно-металлургической промышленности?",
        answers: [
          "В четырех экземплярах",
          "В трех экземплярах",
          "В двух экземплярах",
          "В одном экземпляре",
        ],
        correctAnswers: ["В двух экземплярах"],
      },
      {
        code: "20303026",
        text: "Что должны иметь закрома и ямы?",
        answers: [
          "Должны иметь по периметру пешеходные проходы шириной 1,0 м",
          "Со всех сторон должны иметь ограждение",
          "Должны иметь по периметру пешеходные проходы шириной 0,8 м",
        ],
        correctAnswers: ["Со всех сторон должны иметь ограждение"],
      },
      {
        code: "20303027",
        text: "От чего должны быть защищены составные части производственного оборудования, в том числе энергетические трубопроводы, рукава подачи природного газа, кислорода, мазута, воздуха, масла, воды, троллеи для питания электрического оборудования тележек, перевозящих расплавы металлов, и электрические кабели?",
        answers: [
          "Только от механических воздействий",
          "От всего перечисленного",
          "Только от тепловых воздействий",
          "Только от возможного попадания на них расплава",
        ],
        correctAnswers: ["От всего перечисленного"],
      },
      {
        code: "20303028",
        text: "На какой максимальный срок выдается наряд-допуск на работы повышенной опасности?",
        answers: [
          "На 15 календарных дней",
          "На 20 рабочих дней",
          "На 30 календарных дней",
          "На 45 календарных дней",
        ],
        correctAnswers: ["На 30 календарных дней"],
      },
      {
        code: "20303029",
        text: "Чем должны быть оборудованы места пересечения железнодорожных путей для подачи составов ковшей с жидким чугуном с путями для подачи шихтовых материалов?",
        answers: [
          "Должны быть оборудованы постами с дежурными стрелочного поста",
          "Должны быть оборудованы светофорами",
          "Должны быть оборудованы автоматической сигнализацией",
        ],
        correctAnswers: [
          "Должны быть оборудованы автоматической сигнализацией",
        ],
      },
      {
        code: "20303030",
        text: "Что должно быть разработано для обеспечения безопасности при освоении новых производств, технологических процессов и технических устройств?",
        answers: [
          "Производственные инструкции",
          "Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования",
          "Инструкции по проведению пусконаладочных работ",
        ],
        correctAnswers: [
          "Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования",
        ],
      },
      {
        code: "20303031",
        text: "Чем должны быть оборудованы шлаковозы?",
        answers: [
          "Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением",
          "Механизмами кантования (поворота) чаши с ручным пневматическим приводом и автоматическим управлением",
          "Механизмами кантования (поворота) чаши с ручным гидравлическим приводом",
        ],
        correctAnswers: [
          "Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением",
        ],
      },
      {
        code: "20303032",
        text: "Какие требования установлены к системам освещения во взрывоопасных помещениях?",
        answers: [
          "Должны предусматриваться системы освещения, работающие от напряжения 36 В",
          "Должны предусматриваться системы освещения, работающие от напряжения 42 В",
          "Должны предусматриваться системы освещения во взрывобезопасном исполнении",
        ],
        correctAnswers: [
          "Должны предусматриваться системы освещения во взрывобезопасном исполнении",
        ],
      },
      {
        code: "20303033",
        text: "Что должна обеспечить система светозвуковой сигнализации?",
        answers: [
          "Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств",
          "Оповещение эксплуатационного персонала о признаках пожара",
          "Оповещение эксплуатационного персонала о приближении технологического транспорта",
        ],
        correctAnswers: [
          "Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств",
        ],
      },
      {
        code: "20303034",
        text: "Какие требования установлены к месту расстановки ковшей, предназначенных для расплава металла?",
        answers: [
          "Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства",
          "Должны расставляться на свободных площадках пролета цеха",
          "Должны расставляться только на горизонтальных площадках в любом месте пролета цеха",
        ],
        correctAnswers: [
          "Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства",
        ],
      },
      {
        code: "20303035",
        text: "Какие из перечисленных требований к наряду-допуску на работы повышенной опасности указаны неверно? Выберите два правильных ответа.",
        answers: [
          "Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ",
          "После окончания работы наряд-допуск остается у производителя (руководителя) работ",
          "При перерыве в работе в течение одной смены (обеденный перерыв, перерыв по условиям производства работ) наряд-допуск остается у производителя (руководителя) работ",
          "При допуске бригады к работе допускающий к работе вручает первый экземпляр наряда-допуска производителю работ, а второй экземпляр наряда-допуска хранится вместе с нарядами, действующими на этот момент времени",
        ],
        correctAnswers: [
          "Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ",
          "После окончания работы наряд-допуск остается у производителя (руководителя) работ",
        ],
      },
      {
        code: "20303036",
        text: "Какие требования установлены к фурмам для продувки жидкого металла газами?",
        answers: [
          "Должны быть защищены специальными экранами от нагревания теплоизлучением",
          "Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями",
          "Должны иметь цилиндрическую форму",
        ],
        correctAnswers: [
          "Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями",
        ],
      },
      {
        code: "20303037",
        text: "После чего разрешается производить слив расплава в ковши, вышедшие из ремонта?",
        answers: [
          "Только после их увлажнения и охлаждения",
          "Только после тщательной их очистки и просушивания",
          "Только после тщательного их просушивания и разогрева",
        ],
        correctAnswers: [
          "Только после тщательного их просушивания и разогрева",
        ],
      },
      {
        code: "20303038",
        text: "На что должна быть проверена система пневмотранспорта перед вводом в эксплуатацию?",
        answers: [
          "На наличие дефектов и ненадежных соединений путем проведения гидроиспытаний",
          "На плотность под рабочим давлением",
          "На отсутствие загрязнений",
        ],
        correctAnswers: ["На плотность под рабочим давлением"],
      },
      {
        code: "20303039",
        text: "Какие требования установлены к хранению стержней, шаров, футеровки, запасных деталей и приспособлений?",
        answers: [
          "Должно быть предусмотрено на поддонах в производственном помещении",
          "Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре",
          "Должно быть предусмотрено в закрытых шкафах",
        ],
        correctAnswers: [
          "Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре",
        ],
      },
      {
        code: "20303040",
        text: "Какие требования установлены к кабине завалочной машины?",
        answers: [
          "Должна иметь шумоизоляцию и защищать машиниста от вредных производственных факторов",
          "Должна иметь тонированные стекла и защищать машиниста от ультрафиолетового излучения",
          "Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака",
        ],
        correctAnswers: [
          "Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака",
        ],
      },
      {
        code: "20303041",
        text: "Какие шпалы должны применяться на железнодорожных путях шлакового отвала?",
        answers: [
          "Деревянные шпалы, пропитанные креозотом",
          "Деревянные шпалы, пропитанные каменноугольным маслом",
          "Огнестойкие шпалы",
        ],
        correctAnswers: ["Огнестойкие шпалы"],
      },
      {
        code: "20303042",
        text: "Какие действия должны быть выполнены при утрате наряда-допуска на работы повышенной опасности?",
        answers: [
          "Работы могут быть продолжены с соблюдением требований безопасности. В течение смены следует оформить новый наряд-допуск",
          "Работы могут быть продолжены. Оформление нового наряда-допуска не требуется",
          "Действия должен определить работник, выдающий наряд-допуск",
          "Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново",
        ],
        correctAnswers: [
          "Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново",
        ],
      },
      {
        code: "20303043",
        text: "Чему подлежат производственные емкости (ковши, шлаковые чаши, совки, кюбели, тара, корзины) после изготовления или ремонта?",
        answers: [
          "Техническому освидетельствованию",
          "Механическим испытаниям",
          "Проверке состояния",
        ],
        correctAnswers: ["Проверке состояния"],
      },
      {
        code: "20303044",
        text: "Кем могут проводиться работы повышенной опасности на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "Только работниками эксплуатирующей организации",
          "Только работниками подрядных организаций",
          "Как работниками эксплуатирующей организации, так и работниками подрядных организаций",
        ],
        correctAnswers: [
          "Как работниками эксплуатирующей организации, так и работниками подрядных организаций",
        ],
      },
      {
        code: "20303045",
        text: "Какие требования установлены к расположению КИПиА?",
        answers: [
          "Приборы должны быть выполнены во взрывозащищенном исполнении",
          "Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования",
          "Приборы должны устанавливаться на высоте не менее 3 м от оборудования",
        ],
        correctAnswers: [
          "Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования",
        ],
      },
      {
        code: "20303046",
        text: "Какие требования установлены к загружаемым в печи руде, ферросплавам и другим материалам?",
        answers: [
          "Должны быть увлажнены",
          "Должны быть выдержаны в сухом отапливаемом помещении не менее суток",
          "Должны быть предварительно просушены или прокалены",
        ],
        correctAnswers: ["Должны быть предварительно просушены или прокалены"],
      },
      {
        code: "20303047",
        text: "Что должно иметься на шлаковом отвале?",
        answers: [
          "Двусторонняя громкоговорящая связь с диспетчерской службой",
          "Телефонная или радиосвязь с диспетчерской службой производства",
          "Мобильная связь с диспетчерской службой предприятия",
        ],
        correctAnswers: [
          "Телефонная или радиосвязь с диспетчерской службой производства",
        ],
      },
      {
        code: "20303048",
        text: "При наличии каких дефектов ковшей их эксплуатация не допускается?",
        answers: [
          "При наличии раковин, трещин в стенках и в местах крепления цапф",
          "При потере формы ковша вследствие деформации",
          "При качке цапф в теле ковша",
          "При поврежденной футеровке ковша",
          "При наличии любого из перечисленных дефектов",
        ],
        correctAnswers: ["При наличии любого из перечисленных дефектов"],
      },
      {
        code: "20303049",
        text: "Какие из перечисленных требований, предъявляемых к коксовым цехам, указаны верно?",
        answers: [
          "Входные двери кабин контакторных панелей коксовых машин должны быть постоянно закрыты на замок и иметь блокировку или сигнализацию об их открывании, выведенную в кабину машиниста",
          "При работе двух электровозов на одну рампу порядок их передвижения должен определяться проектной документацией",
          "Уборка пыли должна производиться еженедельно",
          "Все перечисленные требования указаны верно",
          "Допускается спуск кусков недотушенного кокса с рампы на конвейерную ленту",
        ],
        correctAnswers: [
          "Входные двери кабин контакторных панелей коксовых машин должны быть постоянно закрыты на замок и иметь блокировку или сигнализацию об их открывании, выведенную в кабину машиниста",
        ],
      },
      {
        code: "20303050",
        text: "Какая проверка должна производиться для предупреждения утечки отопительного газа в обслуживающие туннели и борова печей при обогреве коксовым газом?",
        answers: [
          "Только герметичности крышек клапанов для воздуха обезграфичивающего устройства",
          "Только герметичности стопорных и кантовочных кранов",
          "Только плотности соединения арматуры с кладкой",
          "Проверка всего перечисленного",
        ],
        correctAnswers: ["Проверка всего перечисленного"],
      },
      {
        code: "20303051",
        text: "За какое время до выдачи кокса допускается открывание стояков при эксплуатации пекококсовых печей?",
        answers: [
          "Не ранее чем за 40 минут",
          "Не ранее чем за 20 минут",
          "Не ранее чем за 10 минут",
          "Не ранее чем за 30 минут",
        ],
        correctAnswers: ["Не ранее чем за 20 минут"],
      },
      {
        code: "20303052",
        text: "Какое из перечисленных требований при чистке и ремонте отопительной арматуры, регенераторов и газораспределительных каналов в кладке коксовых печей указано неверно?",
        answers: [
          "При обогреве доменным газом чистка газовоздушных клапанов и кантовочных кранов для доменного газа должна производиться после предварительного закрытия регулировочного (запорного) клапана, при этом чистка клапанов должна осуществляться только при работе их на нисходящем потоке",
          "При обогреве коксовым газом чистка, ревизия и ремонт арматуры на участке от распределительного газопровода до ввода в отопительный простенок, чистка и ремонт корнюров и дюзовых каналов (в печах с нижним подводом коксового газа), а также замена диафрагм и регулирующих стержней должны производиться только после предварительного закрытия стопорного крана и отключения реверсивного крана от кантовочного механизма",
          "Во время кантовки чистка кантовочного и стопорного кранов при обогреве коксовым и доменным газами должна производиться с помощью специальной манжетной пробки только после отсоединения ведущего рычага от кантовочного крана",
        ],
        correctAnswers: [
          "Во время кантовки чистка кантовочного и стопорного кранов при обогреве коксовым и доменным газами должна производиться с помощью специальной манжетной пробки только после отсоединения ведущего рычага от кантовочного крана",
        ],
      },
      {
        code: "20303053",
        text: "Как часто должна проверяться надежность крепления головок электродов?",
        answers: [
          "1 раз в смену",
          "Правилами не регламентируется",
          "2 раза в сутки",
        ],
        correctAnswers: ["Правилами не регламентируется"],
      },
      {
        code: "20303054",
        text: "Кем должен утверждаться акт комиссионного обследования всех коммуникаций (трубопроводов и арматуры) коксохимических производств?",
        answers: [
          "Главным энергетиком",
          "Специалистом по качеству",
          "Главным технологом",
          "Техническим руководителем организации",
        ],
        correctAnswers: ["Техническим руководителем организации"],
      },
      {
        code: "20303055",
        text: "Какие из перечисленных работ должны осуществляться по наряду-допуску на проведение работ в газоопасных местах? Выберите правильный вариант ответа.",
        answers: [
          "Все перечисленные работы",
          "Только работы по очистке емкостей от пека",
          "Только работы по очистке емкостей от пековой смолы и дистиллята",
          "Только работы по обслуживанию и ремонту пекококсовых печей и участков погрузки пека",
        ],
        correctAnswers: ["Все перечисленные работы"],
      },
      {
        code: "20303056",
        text: "В каких местах не допускается находиться во время работы коксовых машин? Выберите правильный вариант ответа.",
        answers: [
          "Только на верхних площадках углезагрузочного вагона в момент его передвижения и загрузки печей",
          "Только на лестницах и площадках электровоза во время его движения",
          "Только на крыше двересъемной машины во время ее передвижения, выдачи кокса и при наличии напряжения на троллеях",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20303057",
        text: "В каких случаях не допускается вторичное использование ковша для заливки или разливки металла?",
        answers: [
          "Без предварительной замены колец и цапфы",
          "Без предварительной замены футеровки",
          "Без предварительной замены стопора и стакана",
        ],
        correctAnswers: ["Без предварительной замены стопора и стакана"],
      },
      {
        code: "20303058",
        text: "Чем должны защищаться стальные канаты и цепи грузоподъемных устройств, предназначенные для перемещения ковшей с расплавленным металлом, а также траверсы самих ковшей?",
        answers: [
          "Должны защищаться кожухами от воздействия лучистого тепла",
          "Должны покрываться огнеупорными материалами для защиты от воздействия тепловой нагрузки",
          "Должны защищаться специальными смазочными материалами от воздействия лучистого тепла",
        ],
        correctAnswers: [
          "Должны защищаться кожухами от воздействия лучистого тепла",
        ],
      },
      {
        code: "20303059",
        text: "Какому давлению гидравлического испытания должны подвергаться водоохлаждаемые элементы печей перед их установкой?",
        answers: [
          "На 1,5 величины рабочего давления охлаждающей воды",
          "На 2,0 величины рабочего давления охлаждающей воды",
          "На 1,25 величины рабочего давления охлаждающей воды",
        ],
        correctAnswers: ["На 1,5 величины рабочего давления охлаждающей воды"],
      },
      {
        code: "20303060",
        text: "Каким гидравлическим давлением должны испытываться на прочность и плотность трубки системы охлаждения индуктора?",
        answers: [
          "Не менее 2,0 величины рабочего давления охлаждающей воды",
          "Не менее 1,5 величины рабочего давления охлаждающей воды",
          "Не менее 1,25 величины рабочего давления охлаждающей воды",
        ],
        correctAnswers: [
          "Не менее 1,5 величины рабочего давления охлаждающей воды",
        ],
      },
      {
        code: "20303061",
        text: "Какие требования установлены к алюминиевой стружке, принимаемой на хранение?",
        answers: [
          "Должна быть мелкой",
          "Должна быть сухой, без следов масла и грязи",
          "Должна быть в брикетах",
        ],
        correctAnswers: ["Должна быть сухой, без следов масла и грязи"],
      },
      {
        code: "20303062",
        text: "Как должны производиться очистка боровов и ремонтные работы внутри них?",
        answers: [
          "Должны производиться по наряду-допуску при температуре внутри борова не выше 45 °C",
          "Должны производиться по наряду-допуску при температуре внутри борова не выше 50 °C",
          "Должны производиться по наряду-допуску при полной остановке печи",
        ],
        correctAnswers: [
          "Должны производиться по наряду-допуску при полной остановке печи",
        ],
      },
      {
        code: "20303063",
        text: "Какие требования предъявляются к корзинам, загружаемым в щелочные ванны?",
        answers: [
          "Должны быть наполнены промасленным металлом",
          "Должны быть наполнены горячим металлом",
          "Должны быть не влажными",
        ],
        correctAnswers: ["Должны быть не влажными"],
      },
      {
        code: "20303064",
        text: "При каком давлении должен срабатывать предохранительный клапан вакуумной камеры печи?",
        answers: [
          "При давлении 0,05 МПа (0,5 кгс/см²)",
          "При давлении 0,02 МПа (0,2 кгс/см²)",
          "При давлении 0,01 МПа (0,1 кгс/см²)",
        ],
        correctAnswers: ["При давлении 0,01 МПа (0,1 кгс/см²)"],
      },
      {
        code: "20303065",
        text: "С какой периодичностью все коммуникации (трубопроводы и арматура) коксохимических производств должны подвергаться комиссионному обследованию?",
        answers: [
          "1 раз в 5 лет",
          "1 раз в 2 года",
          "1 раз в 3 года",
          "Ежегодно",
        ],
        correctAnswers: ["Ежегодно"],
      },
      {
        code: "20303066",
        text: "С какой периодичностью должна производиться проверка состояния взрывных клапанов газовых сушильных установок обслуживающим персоналом?",
        answers: ["Ежедекадно", "Ежемесячно", "Ежесменно", "Еженедельно"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20303067",
        text: "Что должно быть у каждого плавильного агрегата с выпуском металла через летку?",
        answers: [
          "Должны быть три штанги длиной не менее 2,5 м и запасные пробки для закрывания леток",
          "Должны быть две штанги длиной не менее 2 м и запасные пробки для закрывания леток",
          "Должны быть две штанги длиной не менее 1,5 м и запасные пробки для закрывания леток",
        ],
        correctAnswers: [
          "Должны быть две штанги длиной не менее 1,5 м и запасные пробки для закрывания леток",
        ],
      },
      {
        code: "20303068",
        text: "Какие требования установлены к материалам, используемым для приготовления формовочных и стержневых смесей?",
        answers: [
          "Должны иметь вакуумную упаковку",
          "Должны иметь сертификаты качества",
          "Должны быть отечественного производства",
        ],
        correctAnswers: ["Должны иметь сертификаты качества"],
      },
      {
        code: "20303069",
        text: "В каком положении должна быть крышка завалочного окна во время работы газокислородной горелки?",
        answers: [
          "Крышка должна быть открыта для наблюдения за работой горелки",
          "Крышка должна быть закрыта",
          "Крышка должна быть приоткрыта на 1/2",
        ],
        correctAnswers: ["Крышка должна быть закрыта"],
      },
      {
        code: "20303070",
        text: "При каком максимальном содержании водорода в циркулирующем газе допускается работа котлоагрегатов УСТК?",
        answers: ["12%", "8%", "10%", "5%"],
        correctAnswers: ["8%"],
      },
      {
        code: "20303071",
        text: "Какие требования установлены к снабжению литейных производств щелочью?",
        answers: [
          "Должны снабжаться кристаллизованной щелочью в металлических бочках",
          "Должны снабжаться сульфитной щелочью",
          "Должны снабжаться щелочью в виде порошка",
        ],
        correctAnswers: ["Должны снабжаться сульфитной щелочью"],
      },
      {
        code: "20303072",
        text: "Какая предельная величина заполнения тары пиридиновым продуктом?",
        answers: [
          "Не более 85%",
          "Не более 80%",
          "Не более 90%",
          "Не более 95%",
        ],
        correctAnswers: ["Не более 90%"],
      },
      {
        code: "20303073",
        text: "Какие установлены требования к хранению материалов для приготовления формовочных смесей?",
        answers: [
          "Должны храниться в отдельных помещениях, размещаемых вне пределов производственных участков и отделений",
          "Должны храниться в специально отведенных местах цеха",
          "Должны храниться в отдельных помещениях производственных участков в герметичной таре",
        ],
        correctAnswers: [
          "Должны храниться в отдельных помещениях, размещаемых вне пределов производственных участков и отделений",
        ],
      },
      {
        code: "20303074",
        text: "Какие требования установлены к ведению взрывных работ, хранению, транспортированию взрывчатых материалов и эксплуатации броневых ям?",
        answers: [
          "Работы должны производиться цеховым обслуживающим персоналом",
          "Работы допускается производить взрывниками в процессе эксплуатации оборудования цеха",
          "Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах",
        ],
        correctAnswers: [
          "Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах",
        ],
      },
      {
        code: "20303075",
        text: "Где должен находиться работник при ручной подправке металла на ножницах?",
        answers: ["Сбоку от ножниц", "Спереди ножниц", "Сзади ножниц"],
        correctAnswers: ["Сбоку от ножниц"],
      },
      {
        code: "20303076",
        text: "Какие действия из перечисленных допускаются при эксплуатации обесфеноливающего скруббера? Выберите правильный вариант ответа.",
        answers: [
          "Все перечисленные действия",
          "Подача холодной воды или холодных фенолятов в работающий скруббер",
          "Закрытие крана на гидрозатворе скруббера",
          "Включение вентилятора при закрытом дроссельном клапане",
        ],
        correctAnswers: [
          "Включение вентилятора при закрытом дроссельном клапане",
        ],
      },
      {
        code: "20303077",
        text: "Когда должны выполняться работы по очистке пространства под печью, а также приямков от шлака и мусора?",
        answers: [
          "Только в начале плавления шихты до образования значительного количества жидкого металла",
          "Только до начала плавления шихты",
          "После выпуска металла",
        ],
        correctAnswers: [
          "Только в начале плавления шихты до образования значительного количества жидкого металла",
        ],
      },
      {
        code: "20303078",
        text: "Как должны подготавливаться растворы кислот и щелочей?",
        answers: [
          "Вода должна заливаться небольшой струей в кислоту или щелочь",
          "Кислота или щелочь должны заливаться небольшой струей в холодную воду",
          "Последовательность смешения кислоты или щелочи и воды не имеет значения",
        ],
        correctAnswers: [
          "Кислота или щелочь должны заливаться небольшой струей в холодную воду",
        ],
      },
      {
        code: "20303079",
        text: "Каким образом не допускается производить выдачу пека из кубов и пекотушителей при отсутствии самотека?",
        answers: [
          "Сжатым воздухом",
          "Допускается всеми перечисленными способами",
          "Под давлением пара",
          "Откачивающим насосом",
        ],
        correctAnswers: ["Сжатым воздухом"],
      },
      {
        code: "20303080",
        text: "Какие требования установлены к состоянию вакуум-ковшей, тиглей, коробов, кристаллизаторов, изложниц и других емкостей для расплава перед заливкой в них расплава в электролитическом производстве магния?",
        answers: [
          "Должны быть прогреты до температуры не менее 45 °С",
          "Должны быть очищены от мусора",
          "Должны быть выдержаны в отапливаемом помещении не менее суток",
        ],
        correctAnswers: ["Должны быть очищены от мусора"],
      },
      {
        code: "20303081",
        text: "Чем следует производить тушение горящего угля? Выберите все правильные варианты ответа.",
        answers: [
          "Песком",
          "Углекислотными огнетушителями",
          "Распыленной водой",
          "Паром",
        ],
        correctAnswers: ["Распыленной водой", "Паром"],
      },
      {
        code: "20303082",
        text: "Чем должны быть продуты трубопроводы в коксохимическом производстве? Выберите 2 варианта ответа.",
        answers: [
          "Инертными газами",
          "Сжатым воздухом",
          "Углекислым газом",
          "Водяным паром",
        ],
        correctAnswers: ["Инертными газами", "Водяным паром"],
      },
      {
        code: "20303083",
        text: "С какой периодичностью должна производиться ревизия предохранительных клапанов в коксохимическом производстве?",
        answers: [
          "Не реже 1 раза в 5 лет",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 10 лет",
          "Не реже 1 раза в год",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "20303084",
        text: "В течение какого времени должна храниться диаграмма испытаний предохранительного клапана для взрывоопасных и агрессивных опасных сред?",
        answers: [
          "В течение 3 лет",
          "В течение 6 месяцев",
          "В течение года",
          "В течение 2 лет",
        ],
        correctAnswers: ["В течение 3 лет"],
      },
      {
        code: "20303085",
        text: "Согласно какой документации должны производиться включение в работу, продувка распределительных газопроводов коксовых печей коксового или смешанного (коксовый и доменный) газов?",
        answers: [
          "Согласно технологической инструкции",
          "Согласно инструкции предприятия изготовителя",
          "Согласно производственной инструкции",
          "Согласно распорядительной документации эксплуатирующей организации",
        ],
        correctAnswers: ["Согласно технологической инструкции"],
      },
      {
        code: "20303086",
        text: "Ведение каких из перечисленных работ допускается в обслуживающих туннелях и по всему газовому тракту коксового блока печей?",
        answers: ["Огневых работ", "Очистных работ", "Аварийных работ"],
        correctAnswers: ["Очистных работ"],
      },
      {
        code: "20303087",
        text: "В каком случае разрешается перевод обогрева коксовых батарей с коксового газа на доменный?",
        answers: [
          "Разрешается при остановке батареи на обслуживание",
          "Запрещается в любом случае",
          "Разрешается только в дневное время суток",
          "Разрешается в любом случае",
        ],
        correctAnswers: ["Разрешается только в дневное время суток"],
      },
      {
        code: "20303088",
        text: "Какие действия следует немедленно предпринимать во всех случаях отсутствия тяги дымовой трубы? Выберите правильный вариант ответа.",
        answers: [
          "Немедленно прекратить обогрев коксовых печей",
          "Вывести обслуживающий персонал из помещений батареи",
          "Принять срочные меры к усилению вентиляции обслуживающих туннелей и других примыкающих к ним помещений",
          "Все перечисленные действия",
        ],
        correctAnswers: ["Все перечисленные действия"],
      },
      {
        code: "20303089",
        text: "В соответствии с чем осуществляется порядок использования реагентов на углеобогатительных производствах?",
        answers: [
          "В соответствии с инструкцией по эксплуатации оборудования",
          "В соответствии с технологической инструкцией",
          "В соответствии с производственной инструкцией",
        ],
        correctAnswers: ["В соответствии с технологической инструкцией"],
      },
      {
        code: "20303090",
        text: "С какой периодичностью должна производиться уборка угольной пыли с оборудования и в помещениях углеподготовки?",
        answers: ["Ежесменно", "Ежедневно", "Ежемесячно", "Еженедельно"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20303091",
        text: "При каком условии должны производиться ремонт и ручная очистка путей тушильного вагона? Выберите правильный вариант ответа.",
        answers: [
          "Должны производиться только во время остановки выдачи кокса",
          "Должны производиться с обязательным снятием напряжения с троллей электровоза",
          "Должны производиться под наблюдением лиц, ответственных за проведение работ",
          "При всех перечисленных условиях",
        ],
        correctAnswers: ["При всех перечисленных условиях"],
      },
      {
        code: "20303092",
        text: "В какое время суток должны производиться очистка и ремонт оросительной системы башни тушения?",
        answers: [
          "Только в ночное время",
          "Только в дневное время",
          "В любое время",
        ],
        correctAnswers: ["Только в дневное время"],
      },
      {
        code: "20303093",
        text: "Какие из перечисленных работ относятся к работам повышенной опасности? Выберите правильный вариант ответа.",
        answers: [
          "Только работы в закрытых резервуарах, цистернах, тоннелях, ямах и дымоходах, где возможно отравление и удушье",
          "Только работы внутри горячей печи",
          "Только работы в замкнутых пространствах",
          "Все перечисленные работы",
        ],
        correctAnswers: ["Все перечисленные работы"],
      },
      {
        code: "20303094",
        text: "При каком содержании кислорода в парогазовой смеси допускается эксплуатация газовых сушильных установок?",
        answers: [
          "Более 0,5% по объему",
          "Более 0,8% по объему",
          "Более 1,0% по объему",
          "Ниже норм, предусмотренных технологической инструкцией",
        ],
        correctAnswers: [
          "Ниже норм, предусмотренных технологической инструкцией",
        ],
      },
      {
        code: "20303095",
        text: "При каком максимальном содержании кислорода в циркуляционном газе допускается эксплуатация УСТК? Выберите все правильные варианты ответа.",
        answers: ["1%", "1,8%", "1,3%", "1,5%"],
        correctAnswers: ["1%"],
      },
      {
        code: "20303096",
        text: "Что из перечисленного допускается при непрерывной работе загрузочного и разгрузочного устройств сухого тушения кокса? Выберите правильный вариант ответа.",
        answers: [
          "Вскрывать и производить переуплотнение загрузочного и разгрузочного устройств только после остановки и продувки инертным газом",
          "Работать и находиться вблизи разгрузочных устройств при отключенной вентиляции",
          "Производить ревизию и ремонты коксо- и пылеразгрузочных устройств",
          "Все перечисленное",
        ],
        correctAnswers: [
          "Вскрывать и производить переуплотнение загрузочного и разгрузочного устройств только после остановки и продувки инертным газом",
        ],
      },
      {
        code: "20303097",
        text: "Что необходимо подавать в циркулирующий газ для предотвращения образования взрывоопасного состава в УСТК? Выберите 2 варианта ответа.",
        answers: [
          "Азот",
          "Воздух",
          "Пар, содержание кислорода в котором не должно превышать 3,5%",
          "Углекислый газ",
        ],
        correctAnswers: [
          "Азот",
          "Пар, содержание кислорода в котором не должно превышать 3,5%",
        ],
      },
      {
        code: "20303098",
        text: "На содержание какого газа необходимо проводить анализ состава циркулирующего газа с помощью автоматических газоанализаторов в УСТК?",
        answers: [
          "Только СО",
          "Только H2",
          "Только O2",
          "Только CH4",
          "Всех перечисленных газов",
        ],
        correctAnswers: ["Всех перечисленных газов"],
      },
      {
        code: "20303099",
        text: "С какой периодичностью необходимо производить анализ газа при аварийном выходе из строя газоанализаторов в УСТК?",
        answers: [
          "Не реже 2 раз в день",
          "Не реже 2 раз в смену",
          "Не реже 1 раза в смену",
          "Не реже 1 раза в день",
        ],
        correctAnswers: ["Не реже 2 раз в смену"],
      },
      {
        code: "20303100",
        text: "В каком из перечисленных случаях должен подаваться азот при постоянном снижении циркуляции во всасывающий короб дымососа и в камеру тушения через короба разгрузочного устройства УСТК?",
        answers: [
          "Только при обнаружении течи в котле",
          "Только при увеличении содержания водорода и метана до максимально допустимых величин, установленных инструкцией",
          "Только при нарушении герметичности или поломки, требующих остановки камеры",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20303101",
        text: "С какой периодичностью должен производиться контроль за отсутствием электрического потенциала за вторым электроизолированным стыком рельсов въездных железнодорожных путей при их эксплуатации?",
        answers: [
          "2 раза в год",
          "1 раз в 2 года",
          "1 раз в год",
          "1 раз в 3 года",
        ],
        correctAnswers: ["2 раза в год"],
      },
      {
        code: "20303102",
        text: "С какой периодичностью обслуживающий персонал должен проводить визуальный осмотр работающего оборудования и трубопроводов химического цеха?",
        answers: ["Ежедневно", "Еженедельно", "Ежесменно", "Ежемеячно"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20303103",
        text: "Какое из перечисленных требований к химическим цехам указано неверно?",
        answers: [
          "Слив из железнодорожных цистерн кислоты и щелочи и передача их в хранилища и напорные баки должны осуществляться с помощью перекачивающих насосов без создания избыточного давления в цистернах",
          "На аппаратах и трубопроводах для кислотных растворов в качестве прокладочного материала должны применяться кислотостойкие материалы",
          "Ввод трубопроводов для подачи ЛВЖ в емкости должен располагаться выше уровня сливного трубопровода",
          "Трубопроводы для заполнения и опорожнения емкостей с ЛВЖ должны прокладываться на специальных опорах и закрепляться",
        ],
        correctAnswers: [
          "Ввод трубопроводов для подачи ЛВЖ в емкости должен располагаться выше уровня сливного трубопровода",
        ],
      },
      {
        code: "20303104",
        text: "Транспортирование и перемешивание каких легковоспламеняющихся продуктов с помощью сжатого воздуха не допускается? Выберите правильный вариант ответа.",
        answers: [
          "Только сырого бензола",
          "Только продуктов ректификации",
          "Только пиридиновых оснований",
          "Всех перечисленных легковоспламеняющихся продуктов",
        ],
        correctAnswers: ["Всех перечисленных легковоспламеняющихся продуктов"],
      },
      {
        code: "20303105",
        text: "С какими цехами машинное отделение цехов улавливания химических продуктов должно иметь прямую телефонную связь? Выберите 2 варианта ответа.",
        answers: [
          "С коксовым цехом",
          "С газоповысительной станцией (цехом потребления газа)",
          "С пекоксовым цехом",
          "С химическим цехом",
        ],
        correctAnswers: [
          "С коксовым цехом",
          "С газоповысительной станцией (цехом потребления газа)",
        ],
      },
      {
        code: "20303106",
        text: "Кого обязан предупредить обслуживающий персонал машинного отделения о пуске и остановке нагнетателя коксового газа?",
        answers: [
          "Только диспетчера производства",
          "Только начальников смены коксового цеха",
          "Только начальников смены парокотельной и газоповысительной станции цеха - потребителя газа",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20303107",
        text: "Чем осуществляется прогрев нагнетателей коксового газа перед их пуском?",
        answers: [
          "Паром",
          "Коксовым газом",
          "Электрическими тенами",
          "Горячей водой",
        ],
        correctAnswers: ["Паром"],
      },
      {
        code: "20303108",
        text: "Какие из перечисленных действий не осуществляются в случае прорыва газа в помещение либо наружу через неплотности газопроводов и аппаратов цеха улавливания химических продуктов коксования?",
        answers: [
          "Снижение давления газа путем уменьшения отсоса",
          "Отключение участков с нарушенной герметичностью",
          "Усиление естественной вентиляции помещения",
          "Отключение аварийной вытяжной вентиляции",
        ],
        correctAnswers: ["Отключение аварийной вытяжной вентиляции"],
      },
      {
        code: "20303109",
        text: "Какое допускается максимальное содержание кислорода в коксовом газе?",
        answers: ["1,5%", "2,0%", "1,0%", "1,8%"],
        correctAnswers: ["1,0%"],
      },
      {
        code: "20303110",
        text: "Что из перечисленного допускается при производстве работ в цехе улавливания химических продуктов коксования?",
        answers: [
          "Останавливать обесфеноливающий скруббер на ремонт в соответствии с технологической инструкцией",
          "Включать вентилятор при открытом дроссельном клапане во время эксплуатации обесфеноливающего скруббера",
          "Подавать холодную воду или холодные феноляты в работающий скруббер",
          "Закрывать кран на гидрозатворе скруббера во время его эксплуатации",
        ],
        correctAnswers: [
          "Останавливать обесфеноливающий скруббер на ремонт в соответствии с технологической инструкцией",
        ],
      },
      {
        code: "20303111",
        text: "С какой периодичностью необходимо пропаривать трубы от смотровых фонарей и диссоциаторов в отделениях концентрированной аммиачной воды?",
        answers: [
          "Не реже 1 раза в смену",
          "Не реже 1 раза в 2 смены",
          "Не реже 1 раза в неделю",
          "Не реже 1 раза в декаду",
        ],
        correctAnswers: ["Не реже 1 раза в смену"],
      },
      {
        code: "20303112",
        text: "Какие из перечисленных работ необходимо производить не реже одного раза в смену в цехах улавливания химических продуктов коксования?",
        answers: [
          "Пропаривать трубы от смотровых фонарей и диссоциаторов в отделениях концентрированной аммиачной воды",
          "Проверять стоки из аппаратуры и газопроводов в гидрозатворы, конденсатоотводчики и производить пропарку линий стоков в гидрозатворы и из них",
          "Запускать нагнетатель коксового газа после полной остановки машинного отделения",
          "Разжигать горелки в трубчатых печах",
        ],
        correctAnswers: [
          "Пропаривать трубы от смотровых фонарей и диссоциаторов в отделениях концентрированной аммиачной воды",
          "Проверять стоки из аппаратуры и газопроводов в гидрозатворы, конденсатоотводчики и производить пропарку линий стоков в гидрозатворы и из них",
        ],
      },
      {
        code: "20303113",
        text: "В присутствии кого должен осуществляться розжиг горелок в трубчатых печах?",
        answers: [
          "Начальника смены",
          "Мастера смены",
          "Специалиста-технолога",
          "Руководителя структурного производственного подразделения",
        ],
        correctAnswers: ["Начальника смены"],
      },
      {
        code: "20303114",
        text: "Что из перечисленного допускается в смолоперерабатывающих цехах?",
        answers: [
          "Производить замер уровня пека в цистерне деревянной линейкой длиной не менее 3 м",
          "Приближать к барабану нож, срезающий кристаллы во время работы барабанного кристаллизатора (охладителя)",
          "Включать в работу вакуум-фильтры при заполненной нафталиновой фракцией ванне",
          "Загружать пека в напорные баки и смесители для приготовления лака и препарированной смолы при наличии в них воды и масла",
        ],
        correctAnswers: [
          "Производить замер уровня пека в цистерне деревянной линейкой длиной не менее 3 м",
        ],
      },
      {
        code: "20303115",
        text: "При каком условии допускается производить замер уровня пека в цистерне деревянной линейкой?",
        answers: [
          "При длине линейки не менее 3 м",
          "При длине линейки не менее 2 м",
          "При длине линейки не менее 1 м",
        ],
        correctAnswers: ["При длине линейки не менее 3 м"],
      },
      {
        code: "20303116",
        text: "Какое из перечисленных требований при выполнении работ в цехе (отделении) кристаллического нафталина указано неверно?",
        answers: [
          "Не допускается использование сжатого воздуха для транспортирования нафталина, а также продувка нафталиновых трубопроводов",
          "Колеса тележек, используемые в цехах нафталина, должны быть изготовлены из железа",
          "Погрузка прессованного нафталина в железнодорожные вагоны должна быть механизирована",
          "Для защиты от статического электричества при погрузке жидкого нафталина в железнодорожные и автомобильные цистерны должны быть заземлены корпус цистерны, погрузочный трубопровод и съемный погрузочный патрубок",
          "Перевозки жидкого нафталина должны осуществляться спецтранспортом",
        ],
        correctAnswers: [
          "Колеса тележек, используемые в цехах нафталина, должны быть изготовлены из железа",
        ],
      },
      {
        code: "20303117",
        text: "В каких резервуарах необходимо хранить бензолсодержащие технологические продукты?",
        answers: [
          "В герметичных стальных резервуарах",
          "В железобетонных резервуарах",
          "В организованных в природных пустотах резервуарах",
          "В герметичных неметаллических резервуарах",
        ],
        correctAnswers: ["В герметичных стальных резервуарах"],
      },
      {
        code: "20303118",
        text: "При каком условии допускается входить в закрытый склад сырого бензола и продуктов его переработки и производить в нем какие-либо работы?",
        answers: [
          "Под наблюдением газоспасателя",
          "Под наблюдением работника газовой службы",
          "Под наблюдением работника пожарной охраны",
          "Под наблюдением специалиста по охране труда",
        ],
        correctAnswers: ["Под наблюдением газоспасателя"],
      },
      {
        code: "20303119",
        text: "Как должны производиться работы в случае повышения концентрации вредных веществ выше допустимой нормы в местах мойки тары, а также разливки пиридиновых и хинолиновых продуктов?",
        answers: [
          "Не менее чем двумя работниками в изолирующих аппаратах под наблюдением газоспасателя",
          "Не менее чем двумя работниками в фильтрующих аппаратах под наблюдением газоспасателя",
          "Одним работником в изолирующем аппарате под наблюдением газоспасателя",
          "Не менее чем двумя работниками в изолирующих аппаратах под наблюдением работника газовой службы",
        ],
        correctAnswers: [
          "Не менее чем двумя работниками в изолирующих аппаратах под наблюдением газоспасателя",
        ],
      },
      {
        code: "20303120",
        text: "Какие надписи должны быть на бочках, заполненных пиридиновыми и хинолиновыми продуктами? Выберите 2 варианта ответа.",
        answers: ["Ядовито", "Огнеопасно", "Взрывоопасно", "Газоопасно"],
        correctAnswers: ["Ядовито", "Огнеопасно"],
      },
      {
        code: "20303121",
        text: "Каким образом необходимо нейтрализовать (связать в сульфат пиридина) пиридиновые основания перед началом уборки при их случайном разливе?",
        answers: [
          "15 - 20%-ным раствором серной кислоты",
          "15 - 20%-ным раствором соды",
          "15 - 20%-ным раствором соли",
          "15 - 20%-ным раствором соляной кислоты",
        ],
        correctAnswers: ["15 - 20%-ным раствором серной кислоты"],
      },
      {
        code: "20303122",
        text: "Каким составом должны выполняться газоопасные работы I и II группы газоопасности с образованием взрывопожароопасных смесей?",
        answers: [
          "Одним человеком под наблюдением газоспасателя или члена ДГСД",
          "Не менее трех человек под наблюдением газоспасателя или члена ДГСД",
          "Не менее двух человек под наблюдением газоспасателя или члена ДГСД",
          "Не менее трех человек",
        ],
        correctAnswers: [
          "Не менее двух человек под наблюдением газоспасателя или члена ДГСД",
        ],
      },
      {
        code: "20303123",
        text: "До розжига газопотребляющего агрегата чем должен быть продут газопровод перед коллектором?",
        answers: ["Сжатым воздухом", "Газом", "Водяным паром"],
        correctAnswers: ["Газом"],
      },
      {
        code: "20303124",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии 0,1 - 0,5 мм/г?",
        answers: ["Один раз в 1,5 года", "Один раз в 2 года", "Один раз в год"],
        correctAnswers: ["Один раз в 2 года"],
      },
      {
        code: "20303125",
        text: "Как часто должна производиться нивелировка действующих газопроводов?",
        answers: [
          "Ежегодно, на протяжении двух лет после окончания строительства",
          "Один раз в год",
          "Один раз в 3 года",
          "Ежегодно, на протяжении четырех лет после окончания строительства",
        ],
        correctAnswers: [
          "Ежегодно, на протяжении двух лет после окончания строительства",
        ],
      },
      {
        code: "20303126",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии до 0,1 мм/г?",
        answers: ["Один раз в 4 года", "Один раз в 2 года", "Один раз в 5 лет"],
        correctAnswers: ["Один раз в 4 года"],
      },
      {
        code: "20303127",
        text: "Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных вне помещений?",
        answers: [
          "Не реже одного раза в квартал",
          "Не реже одного раза в месяц",
          "Не реже одного раза в полугодие",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20303128",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии более 0,5 мм/г?",
        answers: [
          "Один раз в полугодие",
          "Один раз в 2 года",
          "Один раз в год",
        ],
        correctAnswers: ["Один раз в год"],
      },
      {
        code: "20303129",
        text: "Какой стаж работы в газовом хозяйстве должны иметь лица, назначаемые ответственными руководителями и ответственными исполнителями газоопасных работ?",
        answers: [
          "Не менее пяти лет",
          "Не менее трех лет",
          "Не менее одного года",
          "Не менее двух лет",
        ],
        correctAnswers: ["Не менее одного года"],
      },
      {
        code: "20303130",
        text: "В какие сроки в помещениях категории А должна осуществляться проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией?",
        answers: [
          "Не реже одного раза в 2 месяца",
          "Не реже одного раза в месяц",
          "Не реже одного раза в 3 месяца",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20303131",
        text: "Каково минимально допустимое давление газа непосредственно перед потребителем (после регулирующих органов)?",
        answers: ["0,4 кПа", "0,3 кПа", "0,5 кПа"],
        correctAnswers: ["0,5 кПа"],
      },
      {
        code: "20303132",
        text: "Что из перечисленного не требуется обрабатывать известковым молоком для снижения возможности загорания коксового газа до начала выполнения работ?",
        answers: [
          "Поверхности фланцев",
          "Поверхности стен и пола газового цеха",
          "Необходимо обрабатывать все перечисленное",
          "Поверхности заглушек",
          "Поверхности газопроводов",
        ],
        correctAnswers: ["Поверхности стен и пола газового цеха"],
      },
      {
        code: "20303133",
        text: "Разрешено ли использовать продувочные свечи для выпуска в атмосферу избыточного газа?",
        answers: [
          "Разрешено при увеличении давления газа до 10%",
          "Запрещено",
          "Только в аварийных случаях",
        ],
        correctAnswers: ["Только в аварийных случаях"],
      },
      {
        code: "20303134",
        text: "В каких случаях запрещается газовая резка и сварка на действующих газопроводах?",
        answers: [
          "На газопроводах, находящихся под избыточным давлением",
          "На газопроводах после их продувки",
          "На газопроводах, находящихся под разрежением",
        ],
        correctAnswers: ["На газопроводах, находящихся под разрежением"],
      },
      {
        code: "20303135",
        text: "Какая допускается максимальная температура газа в газопроводах в случае подачи в них пара для пропарки, продувки и отогрева?",
        answers: ["90 °C", "85 °C", "100 °C"],
        correctAnswers: ["85 °C"],
      },
      {
        code: "20303136",
        text: "На какие группы подразделяются газоопасные места на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "На I, II и III группы газоопасности",
          "На I, II, III и IV группы газоопасности",
          "На I и II группы газоопасности",
        ],
        correctAnswers: ["На I и II группы газоопасности"],
      },
      {
        code: "20303137",
        text: "Куда должны заноситься записи о проведении технического обслуживания, выявленных дефектах и нарушениях газопроводов?",
        answers: [
          "В журнал технического обслуживания и ремонта оборудования",
          "В эксплуатационный журнал",
          "В паспорт оборудования",
        ],
        correctAnswers: ["В эксплуатационный журнал"],
      },
      {
        code: "20303138",
        text: "Как часто должно проверяться исправное действие автоблокировки и сигнализации?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в квартал",
          "Не реже одного раза в полугодие",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20303139",
        text: "Какое из перечисленных требований к применению жетон-бирочной системы указано неверно?",
        answers: [
          "Жетон-бирочная система применяется при выводе технических устройств (ТУ) на продолжительную остановку",
          "Жетон-бирки после разборки электрической схемы ТУ передаются от технологического персонала ремонтному персоналу с отметкой всех этапов в журнале выдачи и приема жетон-бирок",
          "Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями",
          'Ответственный специалист, разобравший схему, принимает меры, исключающие ошибочное или самопроизвольное включение пусковых устройств, на пусковых устройствах вывешивает предупредительные плакаты "Не включать! Работают люди", проверяет отсутствие энергетического потенциала',
        ],
        correctAnswers: [
          "Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями",
        ],
      },
      {
        code: "20303140",
        text: "Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в неделю",
          "Не реже одного раза в 3 месяца",
        ],
        correctAnswers: ["Не реже одного раза в неделю"],
      },
      {
        code: "20303141",
        text: "На что должен проверяться выполненный сварной шов после врезки в действующий газопровод?",
        answers: ["На плотность", "На прочность", "На герметичность"],
        correctAnswers: ["На плотность"],
      },
      {
        code: "20303142",
        text: "Как часто должны проходить поверку контрольно-измерительные приборы в процессе эксплуатации?",
        answers: [
          "Не реже одного раза в год",
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в 2 года",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20303143",
        text: "С кем согласовывается план организации и проведения газоопасной работы?",
        answers: [
          "Только с начальником газоспасательной службы",
          "Со всеми перечисленными",
          "Только со службой производственного контроля",
          "Только с ответственным представителем подразделения или организации (главным энергетиком)",
        ],
        correctAnswers: ["Со всеми перечисленными"],
      },
      {
        code: "20303144",
        text: "Какие из перечисленных данных записываются в агрегатный (ремонтный) журнал на объектах кислородного цеха и в цехах, в составе которых имеются объекты потребления ПРВ? Выберите правильный вариант ответа.",
        answers: [
          "Только данные о выполненных ремонтах",
          "Только данные о проведении ревизии приборов агрегатов",
          "Только данные о выполнении настройки приборов агрегатов",
          "Все перечисленные данные",
        ],
        correctAnswers: ["Все перечисленные данные"],
      },
      {
        code: "20303145",
        text: "В чьем ведении должен находиться агрегатный (ремонтный) журнал на объектах кислородного цеха и в цехах, в составе которых имеются объекты потребления ПРВ?",
        answers: [
          "В ведении лица, ответственного за исправное техническое состояние объекта газового хозяйства",
          "В ведении дежурного персонала, который должен вести в журнале регулярные записи",
          "В ведении ремонтного персонала, который должен вести в журнале регулярные записи",
          "В ведении главного механика",
        ],
        correctAnswers: [
          "В ведении лица, ответственного за исправное техническое состояние объекта газового хозяйства",
        ],
      },
      {
        code: "20303146",
        text: "С какой периодичностью должен производиться осмотр технологических трубопроводов и технических устройств ПРВ комиссией на производстве?",
        answers: [
          "Не реже 2 раз в год",
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 3 года",
        ],
        correctAnswers: ["Не реже 2 раз в год"],
      },
      {
        code: "20303147",
        text: "Что из перечисленного должен включать осмотр технологических трубопроводов и технических устройств ПРВ, производимый комиссией на производстве? Выберите правильный вариант ответа.",
        answers: [
          "Только проверку состояния и исправности действия затворов, свечей, конденсатоотводчиков, компенсаторов, регуляторов, пылеспускных устройств, запорной арматуры, горелочных устройств и устройств для продувки и сигнализации",
          "Только проверку плотности люков и свечей, затворов; швов и фланцевых соединений трубопроводов и аппаратов, находящихся внутри зданий",
          "Только проверку состояния трубопроводов, положения трубопроводной арматуры, колонн, их опорных седел и башмаков, анкерных болтов и надземной части фундаментов",
          "Только проверку выполнения требований производственных инструкций по безопасной эксплуатации оборудования ПРВ, а также ведения агрегатного и эксплуатационного журналов",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20303148",
        text: "В каком случае допускается попеременное использование технических устройств и коммуникаций, работающих с кислородом, для работы с воздухом, азотом, аргоном и другими газами?",
        answers: [
          "Не допускается ни в каком случае",
          "Только в случаях, предусмотренных технологическим процессом",
          "Только в случае пропаривания и продувки оборудования после работы с кислородом",
          "Только в случае промывки оборудования после работы с кислородом",
        ],
        correctAnswers: [
          "Только в случаях, предусмотренных технологическим процессом",
        ],
      },
      {
        code: "20303149",
        text: "В каком случае сторонние лица имеют право находиться на объектах ПРВ?",
        answers: [
          "В сопровождении специалиста данного производства",
          "В сопровождении главного механика",
          "В сопровождении дежурного электромеханика",
          "В сопровождении представителя газоспасательной службы",
        ],
        correctAnswers: ["В сопровождении специалиста данного производства"],
      },
      {
        code: "20303150",
        text: "На какие поверхности не допускается слив жидких ПРВ? Выберите правильный вариант ответа.",
        answers: [
          "Только на пол в производственном помещении",
          "Только на грунт",
          "Только на асфальтовое покрытие",
          "На все перечисленные поверхности",
        ],
        correctAnswers: ["На все перечисленные поверхности"],
      },
      {
        code: "20303151",
        text: "В течение какого времени не допускается приближаться к открытому пламени (курить) или к раскаленным предметам по окончании работы с жидким кислородом или работы в местах с повышенным содержанием кислорода?",
        answers: [
          "В течение 30 минут",
          "В течение 40 минут",
          "В течение 50 минут",
          "В течение 60 минут",
        ],
        correctAnswers: ["В течение 30 минут"],
      },
      {
        code: "20303152",
        text: "В течение какого времени должна проветриваться одежда, в которой выполнялись работы с жидким кислородом или работы в местах с повышенным содержанием кислорода?",
        answers: [
          "В течение 30 минут",
          "В течение 40 минут",
          "В течение 50 минут",
          "В течение 60 минут",
        ],
        correctAnswers: ["В течение 30 минут"],
      },
      {
        code: "20303153",
        text: "Какие из перечисленных работ допускается производить в помещении для стоянки автомобилей с цистернами для жидких криогенных ПРВ? Выберите правильный вариант ответа.",
        answers: [
          "Только работы без применения сварки и открытого огня",
          "Только работы по обслуживанию и ремонту автомобилей, не требующих смотровых канав",
          "Только работы по ремонту установленных на автомобилях сосудов, насосов, испарителей, трубопроводов и арматуры, работающих с ПРВ",
          "Все перечисленные виды работ",
        ],
        correctAnswers: ["Все перечисленные виды работ"],
      },
      {
        code: "20303154",
        text: "При каких остановках воздухоразделительной установки жидкий кислород и кубовую жидкость из адсорберов необходимо слить, а адсорбент регенерировать?",
        answers: [
          "При остановках воздухоразделительной установки продолжительностью более чем на 8 часов",
          "При остановках воздухоразделительной установки продолжительностью более чем на 6 часов",
          "При остановках воздухоразделительной установки продолжительностью более чем на 4 часа",
          "При остановках воздухоразделительной установки продолжительностью более чем на 2 часа",
        ],
        correctAnswers: [
          "При остановках воздухоразделительной установки продолжительностью более чем на 8 часов",
        ],
      },
      {
        code: "20303155",
        text: "В каком случае допускается продление срока эксплуатации воздухоразделительной установки между двумя полными отогревами? Выберите правильный вариант ответа.",
        answers: [
          "Только по согласованию с разработчиком установки",
          "Только по согласованию с главным механиком",
          "Только по согласованию с главным инженером",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Только по согласованию с разработчиком установки"],
      },
      {
        code: "20303156",
        text: "Какое значение не должно превышать содержание сероводорода в коксовом газе в заводских сетях действующих предприятий?",
        answers: ["4 г/нм³", "0,5 г/нм³", "1,5 г/нм³", "2,5 г/нм³"],
        correctAnswers: ["4 г/нм³"],
      },
      {
        code: "20303157",
        text: "Какое значение не должно превышать содержание сероводорода в коксовом газе при газоплазменной обработке металла в закрытых помещениях, а также нафталина летом и зимой?",
        answers: [
          "При газоплазменной обработке металла в закрытых помещениях содержание сероводорода в коксовом газе не должно превышать 0,02 г/нм³, нафталина - 0,05 г/нм³ (летом) и 0,1 г/нм³ (зимой)",
          "При газоплазменной обработке металла в закрытых помещениях содержание сероводорода в коксовом газе не должно превышать 0,02 г/нм³, нафталина - 0,03 г/нм³ (летом) и 0,1 г/нм³ (зимой)",
          "При газоплазменной обработке металла в закрытых помещениях содержание сероводорода в коксовом газе не должно превышать 0,01 г/нм³, нафталина - 0,05 г/нм³ (летом) и 0,1 г/нм³ (зимой)",
          "При газоплазменной обработке металла в закрытых помещениях содержание сероводорода в коксовом газе не должно превышать 0,02 г/нм³, нафталина - 0,05 г/нм³ (летом) и 0,05 г/нм³ (зимой)",
        ],
        correctAnswers: [
          "При газоплазменной обработке металла в закрытых помещениях содержание сероводорода в коксовом газе не должно превышать 0,02 г/нм³, нафталина - 0,05 г/нм³ (летом) и 0,1 г/нм³ (зимой)",
        ],
      },
      {
        code: "20303158",
        text: "Какая информация выбивается на хвостовике заглушки, применяемой для отключения технических устройств и трубопроводов ПРВ? Выберите 2 варианта ответа.",
        answers: [
          "Диаметр газопровода, на который ее можно устанавливать",
          "Давление газа, на который она рассчитана",
          "Наименование газа, применяемого в газопроводе",
          "Допустимая скорость перемещения газа в газопроводе",
        ],
        correctAnswers: [
          "Диаметр газопровода, на который ее можно устанавливать",
          "Давление газа, на который она рассчитана",
        ],
      },
      {
        code: "20303159",
        text: "Какое расчетное давление газа соответствует газопроводам среднего давления?",
        answers: [
          "Свыше 0,1 до 0,3 МПа включительно",
          "Свыше 0,3 до 1,2 МПа включительно",
          "До 0,1 МПа включительно",
          "До 1,6 МПа",
        ],
        correctAnswers: ["Свыше 0,1 до 0,3 МПа включительно"],
      },
      {
        code: "20303160",
        text: "Как должны сооружаться газопроводы на территории предприятия?",
        answers: [
          "Надземной прокладкой",
          "Бестраншейной (подземной) прокладкой",
          'Методом "труба в трубе"',
          "Скрытой прокладкой",
        ],
        correctAnswers: ["Надземной прокладкой"],
      },
      {
        code: "20303161",
        text: "Через какое расстояние должны заземляться наружные газопроводы?",
        answers: [
          "Через каждые 250 м",
          "Через каждые 200 м",
          "Через каждые 150 м",
          "Через каждые 300 м",
        ],
        correctAnswers: ["Через каждые 250 м"],
      },
      {
        code: "20303162",
        text: "При каком максимальном содержании сероводорода в газе допускается установка бронзовых кранов или задвижек с бронзовыми кольцами на газопроводах?",
        answers: ["20 мг/м³", "15 мг/м³", "25 мг/м³", "30 мг/м³"],
        correctAnswers: ["20 мг/м³"],
      },
      {
        code: "20303163",
        text: "Какое из перечисленных требований к задвижкам и заглушкам, устанавливаемым на газопроводах, указано неверно?",
        answers: [
          "Для плотного отключения отдельных участков газопроводов, газопотребляющих агрегатов и газовых аппаратов от действующих газопроводов после дисковых задвижек (по ходу газа) должны устанавливаться листовые задвижки или заглушки",
          "Установка внутри зданий и цехов на газопроводах листовых задвижек любого типа без дисковых задвижек перед ними не допускается",
          "Листовые задвижки на газопроводах диаметром более 300 мм должны быть оснащены механизированным приводом",
          "Перекрывающие листы листовых задвижек и заглушек диаметром до 3 м должны изготовляться из целого листа",
        ],
        correctAnswers: [
          "Перекрывающие листы листовых задвижек и заглушек диаметром до 3 м должны изготовляться из целого листа",
        ],
      },
      {
        code: "20303164",
        text: "Какая должна быть толщина перекрывающих листов заглушек и листовых задвижек газопроводов?",
        answers: [
          "Не менее 4 мм",
          "Не менее 3 мм",
          "Не менее 2 мм",
          "Не менее 1 мм",
        ],
        correctAnswers: ["Не менее 4 мм"],
      },
      {
        code: "20303165",
        text: "Какая информация должна быть выбита на хвостовике каждой съемной заглушки газопровода?",
        answers: [
          "Только номер сертификата на заглушку",
          "Только номер заглушки",
          "Только марка материала",
          "Только условное давление и условный диаметр",
          "Вся перечисленная информация",
        ],
        correctAnswers: ["Вся перечисленная информация"],
      },
      {
        code: "20303166",
        text: "Какая должна быть минимальная высота водяного затвора конденсатоотводчиков межцеховых газопроводов, работающих под избыточным давлением?",
        answers: [
          "Должна быть на 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст.",
          "Должна быть на 400 мм вод. ст. больше расчетного давления газа, но не менее 1000 мм вод. ст.",
          "Должна быть на 300 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст.",
          "Должна быть на 500 мм вод. ст. больше расчетного давления газа, но не менее 1500 мм вод. ст.",
        ],
        correctAnswers: [
          "Должна быть на 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст.",
        ],
      },
      {
        code: "20303167",
        text: "В каком случае допускается осуществлять обогрев конденсатоотводчиков межцеховых газопроводов зимой путем ввода острого пара внутрь сосуда?",
        answers: [
          "Только в случае аварии",
          "Только в случае получения письменного разрешения главного инженера",
          "Только в случае получения письменного разрешения главного механика",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Только в случае аварии"],
      },
      {
        code: "20303168",
        text: "Какое из перечисленных требований к отводу конденсата из цеховых газопроводов указано неверно?",
        answers: [
          "Сброс конденсата из цеховых газопроводов следует производить через конденсатоотводчики",
          "Сброс конденсата из цеховых газопроводов следует производить через горелки печей и другого оборудования потребителя газа",
          "Конструкция конденсатоотводчиков, установленных в зданиях цехов, должна исключать возможность попадания газов в помещения",
          "Участки труб, отводящих конденсат, а также сами конденсатоотводчики, если цех не отапливается, должны быть утеплены",
        ],
        correctAnswers: [
          "Сброс конденсата из цеховых газопроводов следует производить через горелки печей и другого оборудования потребителя газа",
        ],
      },
      {
        code: "20303169",
        text: "Какое из перечисленных требований к стационарным газоразборным постам указано неверно?",
        answers: [
          "Стационарные газоразборные посты должны быть размещены в металлических шкафах с отверстиями для вентиляции",
          "Расстояние между шкафами газоразборных постов для кислорода и горючего газа должно быть не менее 150 мм",
          "Дверцы шкафов во время работы должны быть закрыты",
          "При отсутствии рабочего, пользующегося газоразборным постом, шкаф должен быть закрыт на замок",
          "Газоразборные посты, шкафы должны иметь опознавательную окраску",
        ],
        correctAnswers: ["Дверцы шкафов во время работы должны быть закрыты"],
      },
      {
        code: "20303170",
        text: "Какой должен быть нижний предел давления для I разряда газов-заменителей ацетилена перед газопламенной аппаратурой?",
        answers: [
          "Должен быть не ниже указанного в характеристике горелки или резака, но не ниже 3,0 кПа",
          "Должен быть не ниже указанного в характеристике горелки или резака, но не ниже 2,5 кПа",
          "Должен быть не ниже указанного в характеристике горелки или резака, но не ниже 2,0 кПа",
          "Должен быть не ниже указанного в характеристике горелки или резака, но не ниже 1,5 кПа",
        ],
        correctAnswers: [
          "Должен быть не ниже указанного в характеристике горелки или резака, но не ниже 3,0 кПа",
        ],
      },
      {
        code: "20303171",
        text: "При каком увеличении содержания кислорода в газе в напорном коллекторе газоотсасывающей станции отвод газа от электропечи должен быть немедленно прекращен?",
        answers: [
          "При увеличении содержания кислорода в газе до 2% (по объему)",
          "При увеличении содержания кислорода в газе до 1,5% (по объему)",
          "При увеличении содержания кислорода в газе до 1% (по объему)",
          "При увеличении содержания кислорода в газе до 0,5% (по объему)",
        ],
        correctAnswers: [
          "При увеличении содержания кислорода в газе до 2% (по объему)",
        ],
      },
      {
        code: "20303172",
        text: "Каким уплотнительным устройством, препятствующим выбиванию газов в цех, должно оборудоваться отверстие в охладителе конвертерных газов?",
        answers: [
          "Только аэродинамической завесой",
          "Только воздушной завесой",
          "Только азотной завесой",
          "Любым из перечисленных уплотнительным устройством",
        ],
        correctAnswers: ["Любым из перечисленных уплотнительным устройством"],
      },
      {
        code: "20303173",
        text: "Какое допускается максимальное содержание оксида углерода в газах, поступающих в электрофильтр, при отводе газов с полным дожиганием?",
        answers: ["1%", "2%", "3%", "5%"],
        correctAnswers: ["1%"],
      },
      {
        code: "20303174",
        text: "Содержание каких из перечисленных газов измеряется в продуктах неполного сгорания за дымососом при отводе газов без дожигания? Выберите 2 варианта ответа.",
        answers: ["CO", "O₂", "CO₂", "H₂"],
        correctAnswers: ["CO", "O₂"],
      },
      {
        code: "20303175",
        text: "С какой периодичностью газопроводы сероочистки природного газа подвергаются пневматическому испытанию?",
        answers: [
          "1 раз в 4 года",
          "1 раз в 2 года",
          "1 раз в 5 лет",
          "1 раз в год",
        ],
        correctAnswers: ["1 раз в 4 года"],
      },
      {
        code: "20303176",
        text: "Какое из перечисленных требований к установке сероочистки природного газа указано верно?",
        answers: [
          "Установка сероочистки природного газа для получения реформерного газа должна быть снабжена быстродействующими отсекающими клапанами на входе газа в установку, срабатывающими при снижении или повышении давления газа от установленных проектом значений",
          "Установка сероочистки должна иметь подвод азота и пара",
          "На пульте управления сероочистки на видном месте должна быть вывешена схема расположения аппаратов и трубопроводов",
          "Установка сероочистки должна работать в автоматическом режиме и снабжаться блокировками, отключающими установку при отклонении параметров от заданных",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20303177",
        text: "До каких пор должна осуществляться продувка азотом перед пуском в работу установки по производству реформерного газа для производства металлизованного горячевосстановленного железа?",
        answers: [
          "До тех пор, пока на участке продувки кислорода будет не более 1% кислорода",
          "До тех пор, пока на участке продувки кислорода будет не более 3% кислорода",
          "До тех пор, пока на участке продувки кислорода будет не более 5% кислорода",
          "До тех пор, пока на участке продувки кислорода будет не более 8% кислорода",
        ],
        correctAnswers: [
          "До тех пор, пока на участке продувки кислорода будет не более 1% кислорода",
        ],
      },
      {
        code: "20303178",
        text: "Какое из перечисленных требований к установке по производству реформерного газа указано неверно?",
        answers: [
          "Для регулирования давления реформерного газа после холодильника и сброса его на свечу должны быть установлены регулирующие клапаны, работающие в ручном или автоматическом режиме",
          "При остановке агрегатов по производству реформерного газа подача воздуха и природного газа на горелки должна быть открыта",
          "Перед розжигом горелок реформера должна быть произведена продувка его топочного пространства",
          "Не допускается продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами",
        ],
        correctAnswers: [
          "При остановке агрегатов по производству реформерного газа подача воздуха и природного газа на горелки должна быть открыта",
        ],
      },
      {
        code: "20303179",
        text: "Кто утверждает инструкцию по перекачке сероуглерода из транспортной емкости в емкость хранения?",
        answers: [
          "Главный инженер предприятия",
          "Главный механик предприятия",
          "Генеральный директор предприятия",
          "Специалист, ответственный за промышленную безопасность на предприятии",
        ],
        correctAnswers: ["Главный инженер предприятия"],
      },
      {
        code: "20303180",
        text: "Чему подвергаются все газопроводы и газовые установки после окончания строительно-монтажных и сварочных работ (включая ремонтные работы) и оформления документов, подтверждающих качество выполненных работ?",
        answers: [
          "Только наружному осмотру",
          "Только испытанию на прочность",
          "Только испытанию на плотность",
          "Всем перечисленным испытаниям",
        ],
        correctAnswers: ["Всем перечисленным испытаниям"],
      },
      {
        code: "20303181",
        text: "Кем производится испытание газопроводов и газовых установок после окончания строительно-монтажных и сварочных работ (включая ремонтные работы)?",
        answers: [
          "Строительно-монтажной организацией с участием представителя предприятия",
          "Ремонтным персоналам предприятия под руководством главного механика",
          "Техническим руководством предприятия",
          "Проектной организацией с участием представителя предприятия",
        ],
        correctAnswers: [
          "Строительно-монтажной организацией с участием представителя предприятия",
        ],
      },
      {
        code: "20303182",
        text: "Какое из перечисленных требований к манометрам, используемым при проведении испытаний газопроводов и газовых установок, указано верно?",
        answers: [
          "При подаче потребителям газа по обводной линии (байпасу) ГРП и ГРУ манометры должны устанавливаться на байпасе только до регулирующей задвижки и быть хорошо видимыми обслуживающему персоналу",
          "При подаче потребителям газа по обводной линии (байпасу) ГРП и ГРУ манометры должны устанавливаться на байпасе только после регулирующей задвижки и быть хорошо видимыми обслуживающему персоналу",
          "При снятии для ремонта сбросного предохранительного клапана или манометра вместо них должны устанавливаться испытанные клапан или манометр",
          "Допускается работа ГРП и ГРУ без установки испытанных клапана или манометра на байпасе",
        ],
        correctAnswers: [
          "При снятии для ремонта сбросного предохранительного клапана или манометра вместо них должны устанавливаться испытанные клапан или манометр",
        ],
      },
      {
        code: "20303183",
        text: "В течение какого времени выдерживают испытательное давление в трубопроводе при испытании на прочность, после чего снижают до рабочего давления, при котором производят тщательный осмотр сварных швов (испытание на плотность)?",
        answers: [
          "В течение 10 минут",
          "В течение 5 минут",
          "В течение 15 минут",
          "В течение 3 минут",
        ],
        correctAnswers: ["В течение 10 минут"],
      },
      {
        code: "20303184",
        text: "Какое устанавливается минимальное расстояние от испытываемого газопровода большого диаметра, расположенных вне помещений, до границ охраняемой зоны во время проведения пневматических испытаний на прочность?",
        answers: ["50 м", "40 м", "25 м", "10 м"],
        correctAnswers: ["50 м"],
      },
      {
        code: "20303185",
        text: "Каким давлением производится дополнительное испытание на герметичность межцеховых и внутрицеховых газопроводов?",
        answers: [
          "Давлением, равным рабочему",
          "Давлением, равным атмосферному",
          "Давлением, равным остаточному",
          "Давлением, равным номинальному",
        ],
        correctAnswers: ["Давлением, равным рабочему"],
      },
      {
        code: "20303186",
        text: "Какая установлена продолжительность дополнительных испытаний на герметичность для вновь сооружаемых газопроводов?",
        answers: [
          "Не менее 24 часов",
          "Не менее 18 часов",
          "Не менее 12 часов",
          "Не менее 8 часов",
        ],
        correctAnswers: ["Не менее 24 часов"],
      },
      {
        code: "20303187",
        text: "Какая устанавливается продолжительность периодических испытаний на герметичность для газопроводов, а также испытаний после ремонта, связанного со сваркой и разборкой газопровода?",
        answers: [
          "Не менее 4 часов",
          "Не менее 8 часов",
          "Не менее 12 часов",
          "Не менее 2 часов",
        ],
        correctAnswers: ["Не менее 4 часов"],
      },
      {
        code: "20303188",
        text: "При какой скорости падения давления за время дополнительного пневматического испытания на герметичность внутрицеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?",
        answers: [
          "Не более 0,1% в час",
          "Не более 0,2% в час",
          "Не более 0,3% в час",
          "Не более 0,5% в час",
        ],
        correctAnswers: ["Не более 0,1% в час"],
      },
      {
        code: "20303189",
        text: "При какой скорости падения давления за время дополнительного пневматического испытания на герметичность межцеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?",
        answers: [
          "Не более 0,2% в час",
          "Не более 0,3% в час",
          "Не более 0,5% в час",
          "Не более 0,7% в час",
        ],
        correctAnswers: ["Не более 0,2% в час"],
      },
      {
        code: "20303190",
        text: "Какой документацией устанавливается объем функций газового цеха?",
        answers: [
          "Распорядительной документацией эксплуатирующей организации",
          "Эксплуатационной документацией",
          "Проектной документацией",
          "Ремонтной документацией эксплуатирующей организации",
        ],
        correctAnswers: [
          "Распорядительной документацией эксплуатирующей организации",
        ],
      },
      {
        code: "20303191",
        text: "У кого должен находиться агрегатный (ремонтный) журнал на отдельных объектах газового цеха, а также в цехах, в составе которых имеются объекты газового хозяйства?",
        answers: [
          "У работника, ответственного за техническое состояние данного объекта газового хозяйства",
          "У главного механика",
          "У начальника объекта газового хозяйства",
          "У главного инженера",
        ],
        correctAnswers: [
          "У работника, ответственного за техническое состояние данного объекта газового хозяйства",
        ],
      },
      {
        code: "20303192",
        text: "С какой периодичностью должна производиться ревизия с разборкой регулятора давления, предохранительных клапанов, фильтров газопроводов, газовых установок и газового оборудования?",
        answers: [
          "Не менее 1 раза в год",
          "Не менее 1 раза в 2 года",
          "Не менее 1 раза в 3 года",
          "Не менее 1 раза в 5 лет",
        ],
        correctAnswers: ["Не менее 1 раза в год"],
      },
      {
        code: "20303193",
        text: "С какой периодичностью должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях, и вне помещений?",
        answers: [
          "Не реже 1 раза в неделю в помещениях, а вне помещений - не реже 1 раза в месяц",
          "Не реже 1 раза в 2 недели в помещениях, а вне помещений - не реже 1 раза в 2 месяца",
          "Не реже 1 раза в месяц в помещениях, а вне помещений - не реже 1 раза в 3 месяца",
          "Не реже 1 раза в декаду в помещениях, а вне помещений - не реже 1 раза в 2 месяца",
        ],
        correctAnswers: [
          "Не реже 1 раза в неделю в помещениях, а вне помещений - не реже 1 раза в месяц",
        ],
      },
      {
        code: "20303194",
        text: "Кто из перечисленных лиц не должен входить в состав постоянно действующей комиссии для проведения осмотров объектов газового хозяйства?",
        answers: [
          "Начальник цеха",
          "Работник, ответственный за безопасную эксплуатацию и техническое состояние газового хозяйства цеха",
          "Работники, ответственные за безопасную эксплуатацию и техническое состояние соответствующих технических устройств, сооружений и оборудования",
          "Ремонтный персонал цеха",
        ],
        correctAnswers: ["Ремонтный персонал цеха"],
      },
      {
        code: "20303195",
        text: "С какой периодичностью должен производиться осмотр газопроводов, газовых установок и газового оборудования комиссией на производстве?",
        answers: [
          "Не реже 2 раз в год",
          "Не реже 1 раза в год",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раз в 5 лет",
        ],
        correctAnswers: ["Не реже 2 раз в год"],
      },
      {
        code: "20303196",
        text: "Через какое время должны наноситься защитные лакокрасочные покрытия на наружные поверхности межцеховых и цеховых газопроводов и газовых аппаратов?",
        answers: [
          "Через каждые 5 лет",
          "Через каждые 7 лет",
          "Через каждые 2 года",
          "Через каждые 3 года",
        ],
        correctAnswers: ["Через каждые 5 лет"],
      },
      {
        code: "20303197",
        text: "Через какое время должны наноситься защитные лакокрасочные покрытия на фланцы и болты межцеховых и цеховых газопроводов и газовых аппаратов?",
        answers: [
          "Через каждые 5 лет",
          "Через каждые 7 лет",
          "Через каждые 2 года",
          "Через каждые 3 года",
        ],
        correctAnswers: ["Через каждые 3 года"],
      },
      {
        code: "20303198",
        text: "При каком избыточном давлении газа в газопроводах разрешается производить электросварочные работы на газопроводах при ремонтах?",
        answers: [
          "Не более 0,3 кПа",
          "Не более 0,5 кПа",
          "Не более 0,7 кПа",
          "Не более 0,9 кПа",
        ],
        correctAnswers: ["Не более 0,3 кПа"],
      },
      {
        code: "20303199",
        text: "Какие действия должны быть произведены перед продувкой газопровода? Выберите правильный вариант ответа.",
        answers: [
          "Только установлены задвижки в нужное положение",
          "Только плотно закрыты все люки, лазы и свечи, за исключением той свечи, через которую будет производиться продувка",
          "Только залиты водяные затворы водой",
          "Должны быть произведены все перечисленные действия",
        ],
        correctAnswers: ["Должны быть произведены все перечисленные действия"],
      },
      {
        code: "20303200",
        text: "В каком радиусе у мест выброса газовоздушной смеси в атмосферу должно быть приостановлено движение всех видов транспорта, запрещено применение открытого огня и производство сварочных работ в период продувки газопровода?",
        answers: [
          "В радиусе 50 м",
          "В радиусе 40 м",
          "В радиусе 30 м",
          "В радиусе 25 м",
        ],
        correctAnswers: ["В радиусе 50 м"],
      },
      {
        code: "20303201",
        text: "В каком случае допускается использовать продувочные свечи газопроводов для выпуска в атмосферу избыточного газа?",
        answers: [
          "Только в аварийном случае",
          "Только при наличии письменного разрешения главного инженера",
          "В любом случае",
        ],
        correctAnswers: ["Только в аварийном случае"],
      },
      {
        code: "20303202",
        text: "Какие сроки проведения ревизии газопроводов должны быть при скорости коррозии более 0,5 мм/г?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 4 года",
          "Не реже 1 раза в 5 лет",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "20303203",
        text: "При какой скорости коррозии срок проведения ревизии газопроводов должен быть не реже одного раза в 2 года?",
        answers: [
          "При скорости коррозии 0,1 - 0,5 мм/г",
          "При скорости коррозии более 0,5 мм/г",
          "При скорости коррозии до 0,1 мм/г",
        ],
        correctAnswers: ["При скорости коррозии 0,1 - 0,5 мм/г"],
      },
      {
        code: "20303204",
        text: "Какой допускается максимальный подъем температуры газа в газопроводах?",
        answers: ["85 °C", "87 °C", "95 °C", "90 °C"],
        correctAnswers: ["85 °C"],
      },
      {
        code: "20303205",
        text: "В каком случае допускается подача газа без поднесения запальника, факела или другого воспламеняющего средства к горелке газопровода?",
        answers: [
          "Если температура кладки в разогретой зоне розжига факела превышает 800 °C",
          "Если температура кладки в разогретой зоне розжига факела превышает 750 °C",
          "Если температура кладки в разогретой зоне розжига факела превышает 700 °C",
          "Если температура кладки в разогретой зоне розжига факела превышает 650 °C",
        ],
        correctAnswers: [
          "Если температура кладки в разогретой зоне розжига факела превышает 800 °C",
        ],
      },
      {
        code: "20303206",
        text: "Кто осуществляет выбор типа быстродействующего отсекающего клапана на подводе газа к каждому агрегату с дутьевыми горелками?",
        answers: [
          "Организация, проектирующая систему автоматики печи",
          "Специализированная организация",
          "Организация, эксплуатирующая систему автоматики печи",
        ],
        correctAnswers: ["Организация, проектирующая систему автоматики печи"],
      },
      {
        code: "20303207",
        text: "Какое из перечисленных требований при эксплуатации газового оборудования газопотребляющих агрегатов указано верно?",
        answers: [
          "При выводе газоиспользующего оборудования в резерв или при ремонте должны быть приняты меры по консервации поверхности нагрева в соответствии с документацией изготовителя",
          "На хвостовике заглушки, выступающем за пределы фланцев, должно быть выбито клеймо с указанием диаметра газопровода, на который ее можно устанавливать, и давления газа, на который она рассчитана",
          "Газоиспользующее оборудование при выводе из эксплуатации на срок более трех суток для проведения ремонтных работ или при переводе его в режим резерва должно отключаться с установкой заглушек на газопроводах",
          "Остановка газоиспользующего оборудования для ремонта во всех случаях, за исключением аварийного останова, должна производиться после получения письменного разрешения ответственного за безопасную эксплуатацию газоиспользующего оборудования",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20303208",
        text: "С какой периодичностью должна производиться режимная наладка газоиспользующего оборудования?",
        answers: [
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 4 года",
          "Не реже 1 раза в 5 лет",
          "Не реже 1 раза в 6 лет",
        ],
        correctAnswers: ["Не реже 1 раза в 3 года"],
      },
      {
        code: "20303209",
        text: "В каком случае допускается режимную наладку газоиспользующего оборудования проводить не реже одного раза в четыре года?",
        answers: [
          "Только при стабильной работе газоиспользующего оборудования",
          "Только при нарушении в работе газоиспользующего оборудования не более 2 раз в год",
          "Только при нарушении в работе газоиспользующего оборудования не более 1 раза в год",
          "В любом случае",
        ],
        correctAnswers: [
          "Только при стабильной работе газоиспользующего оборудования",
        ],
      },
      {
        code: "20303210",
        text: "С какой периодичностью должно проводиться обслуживание систем контроля загазованности и уровня содержания окиси углерода в помещениях с установленным газоиспользующим оборудованием специализированными организациями или сервисными службами изготовителя?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 5 лет",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "20303211",
        text: "С какой периодичностью должна производиться проверка технического состояния вентиляционных каналов в зданиях (помещениях) с установленным газоиспользующим оборудованием и пылеуборка, и дезинфекция вентиляционных каналов?",
        answers: [
          "Проверка технического состояния вентиляционных каналов - не реже 2 раз в год, пылеуборка и дезинфекция - не реже 1 раза в 3 года",
          "Проверка технического состояния вентиляционных каналов - не реже 1 раза в год, пылеуборка и дезинфекция - не реже 1 раза в 3 года",
          "Проверка технического состояния вентиляционных каналов - не реже 1 раза в 2 года, пылеуборка и дезинфекция - не реже 1 раза в 5 лет",
          "Проверка технического состояния вентиляционных каналов - не реже 2 раз в год, пылеуборка и дезинфекция - не реже 1 раза в 5 лет",
        ],
        correctAnswers: [
          "Проверка технического состояния вентиляционных каналов - не реже 2 раз в год, пылеуборка и дезинфекция - не реже 1 раза в 3 года",
        ],
      },
      {
        code: "20303212",
        text: "С какой периодичностью должны производиться проверка технического состояния промышленных дымоотводящих устройств (газоходов газоиспользующего оборудования, дымоходов и дымовых труб) и их прочистка?",
        answers: [
          "Не реже 2 раз в год",
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 3 года",
        ],
        correctAnswers: ["Не реже 2 раз в год"],
      },
      {
        code: "20303213",
        text: "На каких минимальных расстояниях (по горизонтали) до отдельных баллонов с кислородом и горючими газами допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?",
        answers: ["5 м", "10 м", "3 м", "1,5 м"],
        correctAnswers: ["5 м"],
      },
      {
        code: "20303214",
        text: "В каком положении должны находиться баллоны со сжиженным газом во время работы?",
        answers: [
          "В наклонном положении с вентилем, направленным вверх",
          "В вертикальном положении",
          "В горизонтальном положении",
          "В любом положении",
        ],
        correctAnswers: ["В вертикальном положении"],
      },
      {
        code: "20303215",
        text: "На каких минимальных расстояниях (по горизонтали) до групповых газобаллонных установок допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?",
        answers: ["10 м", "5 м", "3 м", "1,5 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "20303216",
        text: "Какое действие должно быть осуществлено в первую очередь при зажигании ручной горелки или резака?",
        answers: [
          "Приоткрывание вентиля кислорода",
          "Открытие вентиля горючего газа",
          "Кратковременная продувка рукава для удаления воздуха",
          "Зажигание горючей смеси газов",
        ],
        correctAnswers: ["Приоткрывание вентиля кислорода"],
      },
      {
        code: "20303217",
        text: "Какое из перечисленных действий допускается при эксплуатации оборудования для газопламенной обработки металлов? Выберите правильный вариант ответа.",
        answers: [
          "Нагревание баллонов со сжиженным газом прямыми солнечными лучами или другими источниками тепла сверх температуры, указанной производителем",
          "Отбор газа из баллона при снижении в нем рабочего давления ниже требуемого при питании передвижного рабочего поста сжиженным газом от баллона",
          "Продувка рукава для горючих газов кислородом и кислородного рукава горючим газом, а также взаимозамена рукавов при работе",
          "Ограждение металлическими щитами или ширмами из несгораемых материалов источник газоснабжения при невозможности направления пламени горелки (резака) в сторону, противоположную источнику газоснабжения",
        ],
        correctAnswers: [
          "Ограждение металлическими щитами или ширмами из несгораемых материалов источник газоснабжения при невозможности направления пламени горелки (резака) в сторону, противоположную источнику газоснабжения",
        ],
      },
      {
        code: "20303218",
        text: "Какое из перечисленных требований при эксплуатации взрывопожароопасных объектов указано неверно?",
        answers: [
          "В помещениях категории A проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией должна производиться в сроки, предусмотренные заводской инструкцией, но не реже 1 раза в месяц",
          "В помещениях категории A хранение обтирочных и смазочных материалов допускается в количестве, не превышающем месячной потребности, в металлических, плотно закрывающихся шкафах или ящиках",
          "На каждой двери помещения категории A должны быть указаны категория помещения и класс взрывоопасной зоны",
          "Работы по ремонту электрооборудования в помещениях категории A должны выполняться после обесточивания электросети, используя, при необходимости, переносные аккумуляторные взрывобезопасные светильники",
        ],
        correctAnswers: [
          "В помещениях категории A хранение обтирочных и смазочных материалов допускается в количестве, не превышающем месячной потребности, в металлических, плотно закрывающихся шкафах или ящиках",
        ],
      },
      {
        code: "20303219",
        text: "С какой периодичностью должен производиться текущий ремонт оборудования с разборкой регуляторов давления, предохранительных клапанов и фильтров ГРП и ГРУ, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания?",
        answers: [
          "Не менее 1 раза в год",
          "Не менее 1 раза в 2 года",
          "Не менее 1 раза в 3 года",
          "Не менее 1 раза в 5 лет",
        ],
        correctAnswers: ["Не менее 1 раза в год"],
      },
      {
        code: "20303220",
        text: "В каком случае должна производиться проверка настройки и срабатывания предохранительных запорных и сбросных клапанов ГРП и ГРУ?",
        answers: [
          "После каждой ревизии и ремонта предохранительных запорных и сбросных клапанов",
          "Перед каждой ревизией и ремонтом предохранительных запорных и сбросных клапанов",
          "Во время каждой ревизии и ремонта предохранительных запорных и сбросных клапанов",
        ],
        correctAnswers: [
          "После каждой ревизии и ремонта предохранительных запорных и сбросных клапанов",
        ],
      },
      {
        code: "20303221",
        text: "При каком давлении должна производиться настройка срабатывания предохранительно-запорных клапанов при их наличии в ГРП (ГРУ)?",
        answers: [
          "При давлении на 15% больше расчетного",
          "При давлении на 10% больше расчетного",
          "При давлении на 15% больше пробного",
          "При давлении на 15% больше атмосферного",
        ],
        correctAnswers: ["При давлении на 15% больше расчетного"],
      },
      {
        code: "20303222",
        text: "В каком случае допускается подача потребителям газа по обводной линии (байпасу) ГРП и ГРУ?",
        answers: [
          "На время ревизии или ремонта оборудования",
          "При снятии для ремонта сбросного предохранительного клапана",
          "При снятии для ремонта манометра",
          "Подача газа по байпасу не допускается",
        ],
        correctAnswers: ["На время ревизии или ремонта оборудования"],
      },
      {
        code: "20303223",
        text: "Какое допускается максимальное значение пульсации давления газа на выходе из ГРП и ГРУ?",
        answers: [
          "10% заданного рабочего давления",
          "15% заданного рабочего давления",
          "15% заданного пробного давления",
          "20% заданного пробного давления",
        ],
        correctAnswers: ["10% заданного рабочего давления"],
      },
      {
        code: "20303224",
        text: "Какие виды работ должны выполняться не реже одного раза в 6 месяцев при эксплуатации ГРП и ГРУ?",
        answers: [
          "Проверка параметров срабатывания предохранительных запорных и сбросных клапанов",
          "Техническое обслуживание",
          "Текущий ремонт",
          "Капитальный ремонт",
        ],
        correctAnswers: ["Техническое обслуживание"],
      },
      {
        code: "20303225",
        text: "Какие из перечисленных видов работ не должны выполняться при техническом обслуживании технологического оборудования ГРП и ГРУ? Выберите 2 варианта ответа.",
        answers: [
          "Устранение повреждений газопроводов обвязки технологического оборудования",
          "Восстановление окраски шкафов пунктов редуцирования газа, ограждений, газопроводов обвязки и технических устройств",
          "Смазка подвижных элементов запорной арматуры (без разборки)",
          "Очистка помещения и технических устройств пунктов редуцирования газа от загрязнений (при необходимости)",
          "Устранение выявленных дефектов и неисправностей",
        ],
        correctAnswers: [
          "Устранение повреждений газопроводов обвязки технологического оборудования",
          "Восстановление окраски шкафов пунктов редуцирования газа, ограждений, газопроводов обвязки и технических устройств",
        ],
      },
      {
        code: "20303226",
        text: "В какие сроки должен проводиться капитальный ремонт ГРП и ГРУ?",
        answers: [
          "В сроки, установленные изготовителем",
          "В сроки, установленные эксплуатирующей организацией",
          "Через каждые 3 года после ввода в эксплуатацию",
          "Через каждые 5 лет после ввода в эксплуатацию",
        ],
        correctAnswers: ["В сроки, установленные изготовителем"],
      },
      {
        code: "20303227",
        text: "Каким составом должны осуществлять эксплуатацию не полностью автоматизированных ГПС, ГКС и ГТРС?",
        answers: [
          "Не менее 2 человек в смену",
          "1 человек в смену",
          "Не более 2 человек в смену",
          "Не регламентируется",
        ],
        correctAnswers: ["Не менее 2 человек в смену"],
      },
      {
        code: "20303228",
        text: "Чем должна производиться продувка импульсных газовых проводок?",
        answers: [
          "Только паром",
          "Только сжатым воздухом",
          "Только газом",
          "Любым из перечисленных способов",
        ],
        correctAnswers: ["Любым из перечисленных способов"],
      },
      {
        code: "20303229",
        text: "Что необходимо устанавливать в производственных помещениях, где возможны воспламенение одежды или химические ожоги у работников?",
        answers: [
          "Пожарные краны, гидранты",
          "Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
          "Емкости с песком, огнетушители",
        ],
        correctAnswers: [
          "Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
      },
      {
        code: "20303230",
        text: "После установления каких показателей температуры разрешается проводить ремонтные работы внутри нагретых технических устройств, а также низкотемпературных технических устройств?",
        answers: [
          "После снижения температуры воздуха до 45 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания",
          "После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания",
          "После снижения температуры воздуха до 50 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания",
        ],
        correctAnswers: [
          "После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания",
        ],
      },
      {
        code: "20303231",
        text: "Какой установлен порядок хранения обтирочных материалов?",
        answers: [
          "В чистых металлических ящиках с крышками",
          "В чистых пластмассовых ящиках с крышками",
          "В специально выделенном помещении",
        ],
        correctAnswers: ["В чистых металлических ящиках с крышками"],
      },
      {
        code: "20303232",
        text: "Как часто должна проводиться проверка правильности показаний приборов, перечень которых утверждается руководителем производства?",
        answers: [
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в месяц",
          "Не реже одного раза в 2 месяца",
        ],
        correctAnswers: ["Не реже одного раза в 3 месяца"],
      },
      {
        code: "20303233",
        text: "Как часто должен производиться осмотр трубопроводов кислорода?",
        answers: [
          "Не реже одного раза в полгода",
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в месяц",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20303234",
        text: "Какие требования установлены к проведению ремонтных работ внутри нагретых технических устройств?",
        answers: [
          "Проведение ремонтных работ разрешается после их продувки и снижения температуры воздуха в них до 50 °C",
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 55 °C",
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C",
        ],
        correctAnswers: [
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C",
        ],
      },
      {
        code: "20303235",
        text: "С какой периодичностью следует очищать фильтры?",
        answers: [
          "В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
          "В первый раз - через 20 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
          "В первый раз - через 15 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
        ],
        correctAnswers: [
          "В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
        ],
      },
      {
        code: "20303236",
        text: "Какие требования установлены к оборудованию производственных помещений, в которых возможны воспламенения одежды или химические ожоги?",
        answers: [
          "Должны быть устроены пожарные краны и ящики с песком",
          "Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
          "Должен быть устроен медицинский кабинет",
        ],
        correctAnswers: [
          "Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
      },
      {
        code: "20303237",
        text: "Из каких материалов не могут быть изготовлены разделители (заглушки) или другие изделия, помещаемые внутри кислородопровода при его обезжиривании?",
        answers: [
          "Из медного сплава",
          "Из поролона или других органических материалов",
          "Из олова",
        ],
        correctAnswers: ["Из поролона или других органических материалов"],
      },
      {
        code: "20303238",
        text: "Из каких сплавов металлов запрещается установка арматуры на кислородопроводах?",
        answers: ["Из сплавов титана", "Из алюминиевых сплавов", "Из бронзы"],
        correctAnswers: ["Из сплавов титана"],
      },
      {
        code: "20303239",
        text: "Какой должна быть объемная доля кислорода в воздухе производственных помещений производства ПРВ?",
        answers: [
          "Не менее 19% и не более 23%",
          "Не менее 18% и не более 23%",
          "Не менее 21% и не более 25%",
        ],
        correctAnswers: ["Не менее 19% и не более 23%"],
      },
      {
        code: "20303240",
        text: "Каким образом должен проводиться отогрев трубопроводной арматуры?",
        answers: [
          "Горячим воздухом, паром или горячей водой",
          "Газовой горелкой",
          "Теплым песком",
        ],
        correctAnswers: ["Горячим воздухом, паром или горячей водой"],
      },
      {
        code: "20303241",
        text: "Как часто должна производиться проверка конденсатоотводчиков и пароспутников, обогревающих трубопроводы кислорода в зимнее время?",
        answers: ["По четным числам месяца", "Ежедневно", "Еженедельно"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "20303242",
        text: "Из каких материалов выполняются линии отбора кислорода на анализ?",
        answers: [
          "Из чугуна",
          "Из алюминия",
          "Из коррозионно-стойкой стали или медных сплавов",
        ],
        correctAnswers: ["Из коррозионно-стойкой стали или медных сплавов"],
      },
      {
        code: "20303243",
        text: "Какие требования установлены к инструментам и приспособлениям, используемым во взрывопожароопасных зонах и помещениях?",
        answers: [
          "Должны быть изготовлены из железа или его сплавов",
          "Должны быть изготовлены из стали",
          "Не должны давать искры при работе",
        ],
        correctAnswers: ["Не должны давать искры при работе"],
      },
      {
        code: "20303244",
        text: "При каких условиях допускается перенос сроков проведения капитальных ремонтов основного технологического оборудования?",
        answers: [
          "При наличии положительного заключения экспертного обследования",
          "При наличии письменного распоряжения руководителя эксплуатирующей организации",
          "При наличии письменного разрешения Ростехнадзора",
        ],
        correctAnswers: [
          "При наличии положительного заключения экспертного обследования",
        ],
      },
      {
        code: "20303245",
        text: "Каким должно быть расстояние от устройства забора воздуха и камер всасывания работающих воздушных компрессоров воздухоразделительных установок (далее - ВРУ) при выполнении ремонтных работ, работ по газовой сварке и резке металла, чтобы для их выполнения было необходимо наличие письменного разрешения руководителя производства (цеха) и наряда-допуска?",
        answers: ["Менее 50 м", "Менее 75 м", "Менее 100 м"],
        correctAnswers: ["Менее 100 м"],
      },
      {
        code: "20303246",
        text: "Кто является ответственным за хранение технических паспортов на каждый объект производства/потребления ПРВ?",
        answers: [
          "Лицо, ответственное за безопасную эксплуатацию объекта",
          "Лицо, ответственное за исправное техническое состояние данного объекта",
          "Руководитель объекта",
          "Главный инженер",
        ],
        correctAnswers: [
          "Лицо, ответственное за безопасную эксплуатацию объекта",
        ],
      },
      {
        code: "20303247",
        text: "Кем должен быть составлен технический паспорт, содержащий основные технические характеристики, на каждый объект производства/потребления ПРВ?",
        answers: [
          "Организацией (заказчиком)",
          "Техническим руководителем организации",
          "Ростехнадзором",
          "Главным механиком",
        ],
        correctAnswers: ["Организацией (заказчиком)"],
      },
      {
        code: "20303248",
        text: "Когда должна производиться замена адсорбента воздухоразделительной установки ПВР? Выберите правильный вариант ответа.",
        answers: [
          "Только в сроки, установленные технологической инструкцией",
          "Только при значительном изменении его первоначального цвета",
          "Только при систематическом обнаружении ацетилена в жидком кислороде с концентрацией выше допустимой и не устраняемой после проведения высокотемпературной регенерации адсорбента",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20303249",
        text: "Какое из перечисленных требований при отогреве воздухоразделительных устройств ПРВ указано неверно?",
        answers: [
          "Отогрев аппаратов воздухоразделительной установки должен производиться до достижения температуры воздуха, выходящего из аппаратов в течение 2 часов",
          "Отогрев аппаратов воздухоразделительной установки должен производиться до температуры, определенной технологической инструкцией",
          "Отогрев аппаратов воздухоразделительной установки должен производиться до достижения температуры воздуха, выходящего из аппаратов в течение часа",
        ],
        correctAnswers: [
          "Отогрев аппаратов воздухоразделительной установки должен производиться до достижения температуры воздуха, выходящего из аппаратов в течение часа",
        ],
      },
      {
        code: "20303250",
        text: "Чем необходимо регулярно промывать витые конденсаторы-испарители с внутритрубным кипением кислорода в установках, не производящих криптоновый концентрат?",
        answers: [
          "Жидким кислородом",
          "Жидким азотом",
          "Жидким водородом",
          "Жидким аргоном",
        ],
        correctAnswers: ["Жидким кислородом"],
      },
      {
        code: "20303251",
        text: "В каком случае должно осуществляться отключение выносных конденсаторов для планового отогрева?",
        answers: [
          "Только при отсутствии в расположенных перед ними конденсаторах ацетилена в течение предыдущих суток",
          "Только при наличии в расположенных перед ними конденсаторах ацетилена в течение предыдущих суток",
          "Только при отсутствии в расположенных перед ними конденсаторах ацетилена в течение предыдущих 12 часов",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: [
          "Только при отсутствии в расположенных перед ними конденсаторах ацетилена в течение предыдущих суток",
        ],
      },
      {
        code: "20303252",
        text: "Через какие промежутки времени производится продувка влагомаслоотделителей поршневых компрессоров при отсутствии автоматической продувки?",
        answers: [
          "Через каждые 30 минут",
          "Через каждые 40 минут",
          "Через каждые 50 минут",
          "Через каждые 60 минут",
        ],
        correctAnswers: ["Через каждые 30 минут"],
      },
      {
        code: "20303253",
        text: "В каком случае допускается обмерзание опорной рамы насоса при работе поршневых и центробежных насосов жидких ПРВ?",
        answers: [
          "Только в случае обмерзания мест у выводных штуцеров",
          "Только в случае обмерзания стояночных и динамических уплотнителей",
          "Только в случае обмерзания сальников насосов",
          "Обмерзание опорной рамы насоса не допускается",
        ],
        correctAnswers: ["Только в случае обмерзания мест у выводных штуцеров"],
      },
      {
        code: "20303254",
        text: "Что из перечисленного допускается при эксплуатации компрессоров ПВР?",
        answers: [
          "Использовать масла в воздушных фильтрах при очистке воздуха, поступающего на сжатие в центробежные, осецентробежные, осевые и поршневые воздушные компрессоры, работающие без смазки цилиндров",
          "Использовать для смазки цилиндров поршневых кислородных компрессоров умягченную воду и конденсат, получаемые из заводской системы пароснабжения",
          "Использовать для смазки цилиндров поршневых кислородных компрессоров дистиллят, полученный из воды питьевого качества",
        ],
        correctAnswers: [
          "Использовать для смазки цилиндров поршневых кислородных компрессоров дистиллят, полученный из воды питьевого качества",
        ],
      },
      {
        code: "20303255",
        text: "Какой бригадой необходимо выполнять работы в камере перед фильтром во время работы воздушного центробежного компрессора и воздушного фильтра?",
        answers: [
          "Бригадой не менее чем из 2 человек",
          "Бригадой не более чем из 3 человек",
          "Бригадой не менее чем из 4 человек",
          "Не регламентируется",
        ],
        correctAnswers: ["Бригадой не менее чем из 2 человек"],
      },
      {
        code: "20303256",
        text: "Какой бригадой необходимо выполнять работы в помещение камеры после воздушного фильтра во время работы воздушного центробежного компрессора и воздушного фильтра?",
        answers: [
          "Бригадой не менее чем из 2 человек",
          "Бригадой не более чем из 3 человек",
          "Бригадой не менее чем из 4 человек",
          "Вход обслуживающего персонала в помещение камеры после воздушного фильтра (камера чистого воздуха) не допускается",
        ],
        correctAnswers: [
          "Вход обслуживающего персонала в помещение камеры после воздушного фильтра (камера чистого воздуха) не допускается",
        ],
      },
      {
        code: "20303257",
        text: "В каком случае после каждого компрессора необходимо устанавливать обратный клапан и запорные органы (отключающую арматуру) с дистанционным управлением электропривода для отключения компрессора от коллектора и сброса кислорода в атмосферу?",
        answers: [
          "При работе на 1 коллектор нагнетания кислорода 2 и более центробежных компрессоров или 2 и более поршневых компрессоров (единичной производительностью более 2000 м³/ч) и давлением кислорода в трубопроводе нагнетания кислорода свыше 1,6 МПа (избыточное давление)",
          "При работе на 1 коллектор нагнетания кислорода 1 и более центробежных компрессоров или 1 и более поршневых компрессоров (единичной производительностью более 2000 м³/ч) и давлением кислорода в трубопроводе нагнетания кислорода свыше 1,6 МПа (избыточное давление)",
          "При работе на 1 коллектор нагнетания кислорода 2 и более центробежных компрессоров или 2 и более поршневых компрессоров (единичной производительностью более 1000 м³/ч) и давлением кислорода в трубопроводе нагнетания кислорода свыше 1,6 МПа (избыточное давление)",
        ],
        correctAnswers: [
          "При работе на 1 коллектор нагнетания кислорода 2 и более центробежных компрессоров или 2 и более поршневых компрессоров (единичной производительностью более 2000 м³/ч) и давлением кислорода в трубопроводе нагнетания кислорода свыше 1,6 МПа (избыточное давление)",
        ],
      },
      {
        code: "20303258",
        text: "На каком центробежном кислородном компрессоре необходимо предусматривать стационарные устройства, позволяющие производить отбор проб для анализа газа, выходящего из лабиринтных уплотнений компрессора?",
        answers: [
          "На каждом центробежном кислородном компрессоре с давлением нагнетания более 0,6 МПа",
          "На каждом центробежном кислородном компрессоре с давлением нагнетания более 0,3 МПа",
          "На каждом центробежном кислородном компрессоре с давлением нагнетания более 0,4 МПа",
          "На каждом центробежном кислородном компрессоре с давлением нагнетания более 0,2 МПа",
        ],
        correctAnswers: [
          "На каждом центробежном кислородном компрессоре с давлением нагнетания более 0,6 МПа",
        ],
      },
      {
        code: "20303259",
        text: "Чем должен быть продут маслобак компрессора перед пуском центробежного кислородного компрессора? Выберите 2 варианта ответа.",
        answers: [
          "Сухим азотом",
          "Сухим воздухом",
          "Сухим природным газом",
          "Сухим аргоном",
        ],
        correctAnswers: ["Сухим азотом", "Сухим воздухом"],
      },
      {
        code: "20303260",
        text: "В каком случае оборудование, использовавшееся для работы с одним продуктом разделения воздуха, допускается для работы с другим продуктом?",
        answers: [
          "Только если это согласовано с разработчиком оборудования",
          "Только если это согласовано с главным инженером эксплуатирующей организации",
          "Только если это согласовано с главным механиком эксплуатирующей организации",
          "Только если оборудование пропарено и продуто инертным газом",
          "Допускается в любом случае",
        ],
        correctAnswers: [
          "Только если это согласовано с разработчиком оборудования",
        ],
      },
      {
        code: "20303261",
        text: "Какие надписи должны наноситься на кожухах стационарных хранилищ и на транспортных сосудах (цистерны) жидких ПРВ? Выберите 2 варианта ответа.",
        answers: [
          "Надписи с наименованием хранимого продукта",
          "Отличительные полосы",
          "Надписи с допустимым уровнем заполнения хранимого продукта",
          "Надписи о пожароопасности хранимого продукта",
        ],
        correctAnswers: [
          "Надписи с наименованием хранимого продукта",
          "Отличительные полосы",
        ],
      },
      {
        code: "20303262",
        text: "Какое требование предъявляется к сбросным трубопроводам, к которым должны подключаться транспортные сосуды для жидких ПРВ в течение всего времени нахождения их в гараже?",
        answers: [
          "Трубопроводы должны быть выведены на 2 м выше конька крыши гаража",
          "Трубопроводы должны быть выведены на 3 м выше конька крыши гаража",
          "Трубопроводы должны быть выведены на 1 м выше конька крыши гаража",
          "Трубопроводы должны быть выведены на 4 м выше конька крыши гаража",
        ],
        correctAnswers: [
          "Трубопроводы должны быть выведены на 2 м выше конька крыши гаража",
        ],
      },
      {
        code: "20303263",
        text: "В каких системах кислородоснабжения операции по снижению и поддержанию давления кислорода, регулированию его расхода следует производить в КРП?",
        answers: [
          "В системах кислородоснабжения с расходами свыше 6000 м³/ч и давлением от 0,6 до 4,0 МПа",
          "В системах кислородоснабжения с расходами свыше 4000 м³/ч и давлением от 0,6 до 4,0 МПа",
          "В системах кислородоснабжения с расходами свыше 2000 м³/ч и давлением от 0,6 до 4,0 МПа",
          "В системах кислородоснабжения с расходами свыше 6000 м³/ч и давлением от 0,3 до 2,0 МПа",
        ],
        correctAnswers: [
          "В системах кислородоснабжения с расходами свыше 6000 м³/ч и давлением от 0,6 до 4,0 МПа",
        ],
      },
      {
        code: "20303264",
        text: "На каком расстоянии на каждом кислородопроводе перед КРП необходимо устанавливать отключающую задвижку с дистанционным управлением?",
        answers: [
          "На расстоянии не менее 10 м и не более 50 м",
          "На расстоянии не менее 7 м и не более 50 м",
          "На расстоянии не менее 5 м и не более 50 м",
          "На расстоянии не менее 10 м и не более 60 м",
        ],
        correctAnswers: ["На расстоянии не менее 10 м и не более 50 м"],
      },
      {
        code: "20303265",
        text: "На каких кислородопроводах перед КРП необходимо устанавливать фильтры?",
        answers: [
          "На кислородопроводах, работающих под давлением более 1,6 МПа",
          "На кислородопроводах, работающих под давлением более 1,2 МПа",
          "На кислородопроводах, работающих под давлением более 1,4 МПа",
          "На кислородопроводах, работающих под давлением более 0,8 МПа",
        ],
        correctAnswers: [
          "На кислородопроводах, работающих под давлением более 1,6 МПа",
        ],
      },
      {
        code: "20303266",
        text: "Кто утверждает график проведения технического обслуживания (осмотра, ревизии, ремонта) технических устройств КРП?",
        answers: [
          "Технический руководитель организации",
          "Генеральный директор",
          "Специалист, ответственный за безопасную эксплуатацию оборудования, работающего под давлением",
          "Специалист, ответственный за производственный контроль",
        ],
        correctAnswers: ["Технический руководитель организации"],
      },
      {
        code: "20303267",
        text: "С какой периодичностью следует производить текущий ремонт оборудования КРП?",
        answers: [
          "Не менее 2 раз в год",
          "Не менее 1 раза в год",
          "Не менее 1 раза в 2 года",
          "Не менее 1 раза в 3 года",
        ],
        correctAnswers: ["Не менее 2 раз в год"],
      },
      {
        code: "20303268",
        text: "Какое значение является предминимальной степенью заполнения мокрых и сухих стальных газгольдеров постоянного давления для ПРВ с сигнализацией по уровню заполнения?",
        answers: [
          "10% полного объема",
          "20% полного объема",
          "80% полного объема",
          "90% полного объема",
        ],
        correctAnswers: ["20% полного объема"],
      },
      {
        code: "20303269",
        text: "К какому уровню заполнения мокрых и сухих стальных газгольдеров постоянного давления для ПРВ относится значение 80% полного объема?",
        answers: [
          "К минимальному",
          "К предминимальному",
          "К предмаксимальному",
          "К максимальному",
        ],
        correctAnswers: ["К предмаксимальному"],
      },
      {
        code: "20303270",
        text: "В какие тона окрашивается наружная поверхность стальных газгольдеров и реципиентов ПРВ, расположенных вне здания?",
        answers: ["В светлые тона", "В темные тона", "В любые тона"],
        correctAnswers: ["В светлые тона"],
      },
      {
        code: "20303271",
        text: "Для каких реципиентов ПРВ в месте их подключения к межцеховым кислородопроводам необходимо устанавливать отключающие задвижки с дистанционным управлением?",
        answers: [
          "Для реципиентов вместимостью более 200 м³ с рабочим давлением более 1,6 МПа",
          "Для реципиентов вместимостью более 100 м³ с рабочим давлением более 1,6 МПа",
          "Для реципиентов вместимостью более 200 м³ с рабочим давлением более 1,2 МПа",
          "Для реципиентов вместимостью более 200 м³ с рабочим давлением более 1,4 МПа",
        ],
        correctAnswers: [
          "Для реципиентов вместимостью более 200 м³ с рабочим давлением более 1,6 МПа",
        ],
      },
      {
        code: "20303272",
        text: "Требования какого документа необходимо соблюдать при устройстве, размещении и эксплуатации технических устройств по переработке или очистке криптоноксенонового концентрата, по получению криптоноксеноновых смесей, криптона и ксенона?",
        answers: [
          "Проекта",
          "Технологической инструкции",
          "Правил безопасности процессов получения или применения металлов",
          "Всех перечисленных документов",
        ],
        correctAnswers: ["Всех перечисленных документов"],
      },
      {
        code: "20303273",
        text: "С какой периодичностью должна производиться проверка герметичности соединений коммуникаций и технических устройств по переработке криптоноксенонового концентрата, смеси криптона и ксенона в процессе эксплуатации?",
        answers: [
          "Не реже 1 раза в неделю",
          "Не реже 1 раза в декаду",
          "Не реже 1 раза в 2 недели",
          "Не реже 1 раза в месяц",
        ],
        correctAnswers: ["Не реже 1 раза в неделю"],
      },
      {
        code: "20303274",
        text: "Какое максимальное значение не должно превышать падение давления в системе за 4 часа при начальном давлении, равном рабочему, при испытаниях на плотность соединений коммуникаций и технических устройств по переработке криптоноксенонового концентрата, смеси криптона и ксенона?",
        answers: ["0,01 МПа", "0,05 МПа", "0,1 МПа", "0,5 МПа"],
        correctAnswers: ["0,01 МПа"],
      },
      {
        code: "20303275",
        text: "Чем следует продувать установки очистки сырого аргона от кислорода и водородные коммуникации перед подачей водорода в них и после остановки? Выберите 2 варианта ответа.",
        answers: [
          "Сырым аргоном с объемной долей кислорода не более 4%",
          "Сырым азотом с объемной долей кислорода не более 4%",
          "Сухим азотом с объемной долей кислорода не более 4%",
          "Сырым азотом с объемной долей кислорода не более 8%",
        ],
        correctAnswers: [
          "Сырым аргоном с объемной долей кислорода не более 4%",
          "Сырым азотом с объемной долей кислорода не более 4%",
        ],
      },
      {
        code: "20303276",
        text: "Какая должна быть объемная доля кислорода в продувочном газе перед подачей водорода в установки очистки сырого аргона от кислорода и водородные коммуникации?",
        answers: ["Не более 4%", "Не более 8%", "Не более 10%", "Не более 12%"],
        correctAnswers: ["Не более 4%"],
      },
      {
        code: "20303277",
        text: "При каком расположении дистанционно управляемой запорной и регулирующей арматуры, установленной на трубопроводе кислорода с давлением выше 1,6 МПа, для защиты персонала при возгорании арматуры следует устанавливать защитные экраны?",
        answers: [
          "При расположении на расстоянии менее 3 м от рабочих мест",
          "При расположении на расстоянии менее 4 м от рабочих мест",
          "При расположении на расстоянии менее 5 м от рабочих мест",
          "При расположении на расстоянии менее 3,5 м от рабочих мест",
        ],
        correctAnswers: [
          "При расположении на расстоянии менее 3 м от рабочих мест",
        ],
      },
      {
        code: "20303278",
        text: "При каком уменьшении толщины стенки осматриваемого трубопровода газообразных продуктов разделения воздуха срок последующего измерения должен сокращаться вдвое?",
        answers: [
          "Более чем на 0,4 мм в год",
          "Более чем на 0,3 мм в год",
          "Более чем на 0,2 мм в год",
          "Более чем на 0,1 мм в год",
        ],
        correctAnswers: ["Более чем на 0,4 мм в год"],
      },
      {
        code: "20303279",
        text: "Какое максимальное значение не должно превышать содержание масел в воде, используемой для гидроиспытаний кислородопроводов?",
        answers: ["5,0 мг/л", "4,0 мг/л", "3,0 мг/л", "2,0 мг/л"],
        correctAnswers: ["5,0 мг/л"],
      },
      {
        code: "20303280",
        text: "Какое максимальное значение не должно превышать содержание масел в воздухе или азоте, используемом для пневмоиспытаний и продувки кислородопроводов?",
        answers: ["10,0 мг/м³", "8,0 мг/м³", "5,0 мг/м³", "12,0 мг/м³"],
        correctAnswers: ["10,0 мг/м³"],
      },
      {
        code: "20303281",
        text: "При каком перерыве в эксплуатации кислородопровод давлением 0,6 МПа и выше должен быть продут воздухом или азотом со скоростью на выходе не менее 40 м/с?",
        answers: [
          "При перерыве более месяца",
          "При перерыве более 3 недель",
          "При перерыве более 2 недель",
          "При перерыве более недели",
        ],
        correctAnswers: ["При перерыве более месяца"],
      },
      {
        code: "20303282",
        text: "Какая продолжительность продувки воздухом или азотом перед началом эксплуатации кислородопровода?",
        answers: [
          "Не менее 2 часов",
          "Не менее 1,5 часов",
          "Не менее часа",
          "Не менее 0,5 часа",
        ],
        correctAnswers: ["Не менее 2 часов"],
      },
      {
        code: "20303283",
        text: "С какой периодичностью проверяются показания дифманометров-уровнемеров с занесением результатов проверки в технологический журнал?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 2 месяца",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20303284",
        text: "С какой периодичностью необходимо проверять работоспособность автоматических блокирующих и регулирующих систем и систем сигнализации производств ПРВ?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 2 месяца",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20303285",
        text: "На сколько должен превышать объем помещения (в метрах кубических) объем жидкости, находящейся в сосудах (в литрах), для того, чтобы в помещении с естественной вентиляцией допускалась работа с открытыми сосудами жидких ПРВ?",
        answers: [
          "Не менее чем в 7 раз",
          "Не менее чем в 5 раз",
          "Не менее чем в 2 раза",
          "Не менее чем в 3 раза",
        ],
        correctAnswers: ["Не менее чем в 7 раз"],
      },
      {
        code: "20303286",
        text: "При какой объемной доле кислорода в жидком азоте он может применяться в качестве хладагента без специальных мер по предупреждению загорания и взрыва?",
        answers: [
          "При объемной доле не более 30%",
          "При объемной доле не более 35%",
          "При объемной доле не более 40%",
          "При объемной доле не более 50%",
        ],
        correctAnswers: ["При объемной доле не более 30%"],
      },
      {
        code: "20303287",
        text: "Какие криогенные сосуды (сосуды Дьюара), предназначенные для хранения или работы с жидкими ПРВ, не следует оснащать предохранительными устройствами?",
        answers: [
          "Сосуды вместимостью до 15 л",
          "Сосуды вместимостью до 25 л",
          "Сосуды вместимостью до 20 л",
          "Сосуды вместимостью до 30 л",
        ],
        correctAnswers: ["Сосуды вместимостью до 15 л"],
      },
      {
        code: "20303288",
        text: "Какие действия необходимо произвести с деталями, подвергаемыми охлаждению в ваннах с жидким азотом? Выберите 2 варианта ответа.",
        answers: [
          "Обезжирить",
          "Высушить",
          "Очистить",
          "Смазать техническим маслом",
        ],
        correctAnswers: ["Обезжирить", "Высушить"],
      },
      {
        code: "20303289",
        text: "Какое из перечисленных требований предъявляется к размещению баллонов у каждого места потребления для постоянных потребителей небольших количеств ПРВ?",
        answers: [
          "У каждого места потребления необходимо размещать не более 2 баллонов вместимостью 40 л, заполненных ПРВ под давлением до 20 МПа",
          "Расстояние между каждой парой баллонов должно быть не менее 12 м на каждом уровне размещения баллонов",
          "Баллоны должны размещаться и закрепляться в металлических шкафах",
          "Шкафы с баллонами должны запираться на замок",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20303290",
        text: "Какое должно быть минимальное расстояние между каждой парой баллонов на каждом уровне размещения баллонов у мест потребления для постоянных потребителей небольших количеств ПРВ?",
        answers: [
          "12 м на каждом уровне размещения баллонов",
          "10 м на каждом уровне размещения баллонов",
          "8 м на каждом уровне размещения баллонов",
          "5 м на каждом уровне размещения баллонов",
        ],
        correctAnswers: ["12 м на каждом уровне размещения баллонов"],
      },
      {
        code: "20303291",
        text: "Какие центробежные кислородные компрессоры должны ограждаться защитными экранами для защиты обслуживающего персонала, на отметке обслуживания?",
        answers: [
          "С давлением нагнетания 0,6 МПа и выше",
          "С давлением нагнетания 0,4 МПа и выше",
          "С давлением нагнетания 0,2 МПа и выше",
          "С давлением нагнетания 0,1 МПа и выше",
        ],
        correctAnswers: ["С давлением нагнетания 0,6 МПа и выше"],
      },
      {
        code: "20303292",
        text: "Что из перечисленного необходимо производить перед вскрытием устройств или трубопроводов, заполненных жидкими или газообразными продуктами разделения воздуха? Выберите правильный вариант ответа.",
        answers: [
          "Только снизить до атмосферного давление в устройствах и трубопроводах, подлежащих ремонту и слить жидкие ПРВ из сосудов и трубопроводов",
          "Только отогреть отключенное техническое устройство и трубопроводы в соответствии с требованиями Правил безопасности процессов получения или применения металлов",
          "Только продуть устройство воздухом до содержания объемной доли кислорода 19 - 23% в отходящем газе",
          'Только отключить электропитание приводов технических устройств и арматуры, на пусковых устройствах или рубильниках установить предупреждающий знак безопасности с поясняющей надписью: "Не включать, работают люди!"',
          "Только отключить ремонтируемые технические устройства или участки трубопровода заглушками от всех технических устройств и трубопроводов, находящихся в работе или под давлением",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20303293",
        text: "С какой периодичностью должен проводиться анализ воздуха рабочей зоны в первые сутки проведения ремонтных работ внутри кожуха блока разделения воздуха и далее?",
        answers: [
          "Через каждые 30 минут",
          "Через каждые 60 минут",
          "Не реже 2 раз в смену",
          "Не реже 1 раза в смену",
        ],
        correctAnswers: ["Через каждые 30 минут"],
      },
      {
        code: "20303294",
        text: "Какую отличительную полосу должен иметь инструмент, предназначенный для разборки и ремонта узлов, работающих в кислородной среде ПРВ?",
        answers: [
          "Голубую полосу",
          "Красную полосу",
          "Белую полосу",
          "Оранжевую полосу",
        ],
        correctAnswers: ["Голубую полосу"],
      },
      {
        code: "20303295",
        text: "Чем должна выполняться продувка блока разделения воздуха при производстве его ремонта после обезжиривания аппаратов и коммуникаций блока?",
        answers: ["Воздухом", "Кислородом", "Азотом", "Углекислым газом"],
        correctAnswers: ["Воздухом"],
      },
      {
        code: "20303296",
        text: "Чем должны быть продуты азотные турбодетандеры перед ремонтом?",
        answers: ["Воздухом", "Кислородом", "Азотом", "Углекислым газом"],
        correctAnswers: ["Воздухом"],
      },
      {
        code: "20303297",
        text: "Какие из перечисленных ремонтных работ допускается производить одновременно при ремонте арматуры, расположенной на теплом и холодном концах регенераторов в период остановки ВРУ?",
        answers: [
          "Только ремонт трубопроводов и арматуры теплого и холодного концов регенераторов",
          'Только ремонт трубопроводов и арматуры теплого или холодного конца регенераторов и системы "приказного" воздуха переключения регенераторов, или механизма переключения',
          "Только ремонт принудительных клапанов переключения регенераторов и трехходовых заслонок после регенераторов",
          "Не допускается производить одновременно все из перечисленных ремонтных работ",
        ],
        correctAnswers: [
          "Не допускается производить одновременно все из перечисленных ремонтных работ",
        ],
      },
      {
        code: "20303298",
        text: "С каким интервалом должна проверяться объемная доля кислорода в месте проведения ремонтных работ во время нахождения персонала внутри трубопроводов, опорных обечаек регенераторов, в отсеке обратноповоротных клапанов или арматуры?",
        answers: [
          "С интервалом не менее чем 10 минут",
          "С интервалом не менее чем 8 минут",
          "С интервалом не менее чем 5 минут",
          "С интервалом не менее чем 2 минуты",
        ],
        correctAnswers: ["С интервалом не менее чем 10 минут"],
      },
      {
        code: "20303299",
        text: "Чем производится отогрев смерзшейся изоляции для обеспечения доступа к адсорберам?",
        answers: [
          "Сухим подогретым воздухом",
          "Теплом, подводимым к отогреваемым аппаратам",
          "Открытым пламенем",
          "Теплой водой",
        ],
        correctAnswers: ["Сухим подогретым воздухом"],
      },
      {
        code: "20303300",
        text: "Какие действия необходимо осуществить перед ремонтом клапанов (трубопроводов) теплого и холодного концов регенераторов в период остановки ВРУ? Выберите правильный вариант ответа.",
        answers: [
          "Только перевести на ручной режим систему автоматического управления регенераторов",
          "Только закрыть или открыть соответствующие клапаны  с помощью дистанционного управления",
          'Только установить предупреждающий знак безопасности "Не включать, работают люди!"',
          "Все перечисленные действия",
        ],
        correctAnswers: ["Все перечисленные действия"],
      },
      {
        code: "20303301",
        text: "При каком условии ВРУ должна подвергаться обезжириванию?",
        answers: [
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами",
          "При достижении концентрации масла в жидком кислороде 0,20 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами",
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной тремя последовательно проведенными анализами",
          "При достижении концентрации масла в жидком кислороде 0,30 мг/дм и выше, подтвержденной тремя последовательно проведенными анализами, должна подвергаться обезжириванию",
        ],
        correctAnswers: [
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами",
        ],
      },
      {
        code: "20303302",
        text: "Что из перечисленного не подлежит обезжириванию в процессе эксплуатации ВРУ? Выберите правильный вариант ответа.",
        answers: [
          "Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа",
          "Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода более 40% и давлением выше 1,6 МПа",
          "Аппараты и коммуникации на потоке газообразного кислорода высокого давления",
          "Регенераторы (при переработке воздуха, сжимаемого компрессорами со смазываемыми цилиндрами)",
        ],
        correctAnswers: [
          "Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа",
        ],
      },
      {
        code: "20303303",
        text: "В каком случае арматура, предназначенная для работы с кислородом, не подлежит обезжириванию перед монтажом?",
        answers: [
          "Если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка",
          "Арматура, предназначенная для работы с кислородом, должна обезжириваться в любом случае",
          "Если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и нарушена упаковка",
        ],
        correctAnswers: [
          "Если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка",
        ],
      },
      {
        code: "20303304",
        text: "Какое из перечисленных требований при необходимости проведения обезжиривания технических устройств и трубопроводов ПРВ растворителями указано неверно?",
        answers: [
          "При обезжиривании технических устройств бензином и другими взрывопожароопасными растворителями необходимо соблюдать требования противопожарной безопасности",
          "Отработанные растворители следует сливать в специальные закрытые сосуды и направлять на регенерацию и утилизацию",
          "Порядок слива и утилизации водных моющих растворов определяется проектом",
          "Отработанные растворители следует сливать в канализацию",
        ],
        correctAnswers: [
          "Отработанные растворители следует сливать в канализацию",
        ],
      },
      {
        code: "20303305",
        text: "До какой температуры должен отогреваться сосуд, бывший в эксплуатации, перед проведением работ по обезжириванию способом протирки и чем он должен продуваться?",
        answers: [
          "До температуры не ниже 20 °C и продуваться воздухом",
          "До температуры не ниже 15 °C и продуваться воздухом",
          "До температуры не ниже 20 °C и продуваться азотом",
          "До температуры не ниже 10 °C и продуваться кислородом",
        ],
        correctAnswers: ["До температуры не ниже 20 °C и продуваться воздухом"],
      },
      {
        code: "20303306",
        text: "До какой максимальной температуры допускается нагрев поверхностей в помещениях, где производится обезжиривание кислородного оборудования пожаровзрывоопасными растворителями?",
        answers: ["120 °C", "130 °C", "140 °C", "150 °C"],
        correctAnswers: ["120 °C"],
      },
      {
        code: "20303307",
        text: "Какое из перечисленных требований к потреблению газообразного кислорода и других продуктов разделения воздуха указано неверно?",
        answers: [
          "Использование ПРВ по каждому производству, участку или объекту должно осуществляться по технологическим инструкциям",
          "Для ведения надзора за безопасной эксплуатацией технических устройств и коммуникаций, связанных с потреблением ПРВ, назначаются ответственные лица из числа специалистов",
          "На кислородопроводах допускается установка арматуры из сплавов на основе титана",
          "Не допускается устанавливать и применять кислородное оборудование возле замасляного или прожированного оборудования, территории",
        ],
        correctAnswers: [
          "На кислородопроводах допускается установка арматуры из сплавов на основе титана",
        ],
      },
      {
        code: "20303308",
        text: "Какие установлены сроки обезжиривания ВРУ, работающих по схеме высокого и среднего давлений с блоками комплексной очистки на цеолитах и турбодетандерами, а также по схеме низкого давления?",
        answers: [
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами",
          "Обезжиривание производиться в сроки, определенные разработчиком ВРУ",
          "Не реже 1 раза в год",
          "Сроки обезжиривания не регламентируются",
        ],
        correctAnswers: ["Сроки обезжиривания не регламентируются"],
      },
      {
        code: "20303309",
        text: "Какое должно быть расстояние между ресиверами одного газа?",
        answers: [
          "Не менее 1,5 м в свету",
          "Не менее 1,3 м в свету",
          "Не менее 1,0 м в свету",
          "Не менее 0,5 м в свету",
        ],
        correctAnswers: ["Не менее 1,5 м в свету"],
      },
      {
        code: "20303310",
        text: "Какой предел огнестойкости должен быть у стен, разделяющих взрывоопасные помещения на водородных станциях?",
        answers: ["2,5 часа", "2,0 часа", "1,5 часа", "1,0 час"],
        correctAnswers: ["2,5 часа"],
      },
      {
        code: "20303311",
        text: "Какая должна быть минимальная высота забора по периметру территории всего комплекса производства водорода?",
        answers: ["2 м", "1,5 м", "1 м", "3 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "20303312",
        text: "Какие требования к ресиверам для водорода (сосудам) указаны неверно?",
        answers: [
          "Ресиверы для водорода (сосуды) размещаются на открытых площадках, имеющих по периметру ограждение легкого типа высотой не менее 1,2 м из несгораемого материала",
          'На ограждении должны быть вывешены предупреждающие знаки безопасности: "Курить запрещается", "Посторонним вход воспрещен", на ресиверах должны быть поясняющие надписи: "Водород. Взрывоопасно"',
          "Расстояние от ресиверов (сосудов) с водородом до ограждения - не менее 1,0 м",
          "Расстояние между ресиверами одного газа должно быть не менее 1,5 м в свету и обеспечивать удобство их обслуживания",
        ],
        correctAnswers: [
          "Расстояние от ресиверов (сосудов) с водородом до ограждения - не менее 1,0 м",
        ],
      },
      {
        code: "20303313",
        text: "Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей?",
        answers: [
          "Только системами регулирования заданного соотношения топлива, воздуха и водяного пара",
          "Только блокировками, прекращающими поступление газообразного топлива и воздуха при снижении их давления ниже установленных параметров (автономно), а также при прекращении электро- и пневмоснабжения КИПиА",
          "Только средствами сигнализации о прекращении поступления топлива, а также воздуха при его принудительной подаче в топочное пространство",
          "Только средствами автоматической подачи водяного пара или инертного газа в топочное пространство и в змеевики при прогаре труб, характеризующимися падением давления нагреваемого продукта на выходе из печи ниже регламентированного значения",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20303314",
        text: "Какие требования из перечисленных должны предусматриваться для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты? Выберите правильный вариант ответа.",
        answers: [
          "Только возможность дистанционного отключения",
          "Только установка на линиях всасывания запорных или отсекающих устройств",
          "Только установка на линиях нагнетания запорных или отсекающих устройств",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20303315",
        text: "В каком случае все технологическое оборудование должно продуваться инертным газом? Выберите все правильные варианты ответа.",
        answers: [
          "При остановке оборудования более чем на 2 часа",
          "Перед пуском оборудования, если оно в период остановки не находилось под избыточным давлением водорода",
          "При остановке оборудования на 1 час",
          "Перед пуском оборудования, если оно в период остановки находилось под избыточным давлением водорода",
        ],
        correctAnswers: [
          "При остановке оборудования более чем на 2 часа",
          "Перед пуском оборудования, если оно в период остановки не находилось под избыточным давлением водорода",
        ],
      },
      {
        code: "20303316",
        text: "При каком содержании водорода в воздухе производственного помещения по сигналу от автоматического газоанализатора технологическое оборудование этого помещения должно быть остановлено?",
        answers: [
          "При содержании выше 25% нижнего предела взрываемости",
          "При содержании выше 20% нижнего предела взрываемости",
          "При содержании выше 17% нижнего предела взрываемости",
          "При содержании выше 23% нижнего предела взрываемости",
        ],
        correctAnswers: [
          "При содержании выше 25% нижнего предела взрываемости",
        ],
      },
      {
        code: "20303317",
        text: "При каком содержании газов должна срабатывать световая и звуковая сигнализации, установленные с автоматическими газоанализаторами в помещениях категории А, где обращается водород?",
        answers: [
          "При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости, кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости",
          "При содержании водорода в воздухе помещения не более 12% нижнего предела взрываемости, кислорода менее 20% и более 25%, угарного газа более 18 мг/м³, метана не более 12% нижнего предела взрываемости",
          "При содержании водорода в воздухе помещения не более 14% нижнего предела взрываемости, кислорода менее 21% и более 24%, угарного газа более 16 мг/м³, метана не более 14% нижнего предела взрываемости",
        ],
        correctAnswers: [
          "При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости, кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости",
        ],
      },
      {
        code: "20303318",
        text: "Какие требования к вентиляции помещения, где возможно выделение водорода, указаны верно? Выберите 2 варианта ответа.",
        answers: [
          "Помещения, где возможно выделение водорода, оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час",
          "При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час",
          "В случае аварии дополнительно к постоянно работающей общеобменной вентиляции производства водорода должна автоматически включаться аварийная приточная система",
          "При обосновании допускается устройство механической приточной вентиляции с кратностью воздухообмена не менее 4 в час",
        ],
        correctAnswers: [
          "Помещения, где возможно выделение водорода, оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час",
          "При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час",
        ],
      },
      {
        code: "20303319",
        text: "Какие из перечисленных ограждающих конструкций не относятся к легкосбрасываемым?",
        answers: [
          "Окна (когда оконные переплеты заполнены обычным оконным стеклом толщиной 3 мм, площадью не менее 0,8 м²)",
          "Окна (когда оконные переплеты заполнены обычным оконным стеклом толщиной 4 мм, площадью не менее 1 м²)",
          "Окна (когда оконные переплеты заполнены обычным оконным стеклом толщиной 5 мм, площадью не менее 1,5 м²)",
          "Окна (когда оконные переплеты заполнены обычным оконным стеклом толщиной 6 мм, площадью не менее 2,5 м²)",
        ],
        correctAnswers: [
          "Окна (когда оконные переплеты заполнены обычным оконным стеклом толщиной 6 мм, площадью не менее 2,5 м²)",
        ],
      },
      {
        code: "20303320",
        text: "Какое минимальное остаточное давление должны иметь баллоны, подготовленные для наполнения водородом?",
        answers: ["0,05 МПа", "0,04 МПа", "0,03 МПа", "0,02 МПа"],
        correctAnswers: ["0,05 МПа"],
      },
      {
        code: "20303321",
        text: "На какой высоте от пола должны быть расположены окна в помещениях отделений наполненных баллонов, наполнительных и разрядных рамп?",
        answers: [
          "На высоте не менее 1,5 м",
          "На высоте не менее 1,2 м",
          "На высоте не менее 1,0 м",
          "На высоте не менее 0,8 м",
        ],
        correctAnswers: ["На высоте не менее 1,5 м"],
      },
      {
        code: "20303322",
        text: "Какой должна быть ширина свободного прохода между щитом управления вентилями и ближайшими стенами наполнительной?",
        answers: [
          "Не менее 0,8 м",
          "Не менее 0,6 м",
          "Не менее 0,7 м",
          "Не менее 0,5 м",
        ],
        correctAnswers: ["Не менее 0,8 м"],
      },
      {
        code: "20303323",
        text: "Стальными листами какой толщины должны отделяться от рабочей зоны баллоны с водородом при их горизонтальном расположении и размещении внутри помещения?",
        answers: [
          "Не менее 16 мм",
          "Не менее 12 мм",
          "Не менее 10 мм",
          "Не менее 8 мм",
        ],
        correctAnswers: ["Не менее 16 мм"],
      },
      {
        code: "20303324",
        text: "При какой чистоте водорода должен производиться пуск компрессора для наполнения баллонов?",
        answers: [
          "При чистоте не менее 99,7%",
          "При чистоте не менее 99,1%",
          "При чистоте не менее 98,7%",
          "При чистоте не менее 95,7%",
        ],
        correctAnswers: ["При чистоте не менее 99,7%"],
      },
      {
        code: "20303325",
        text: "Какие устройства должны быть установлены на нагнетательном газопроводе для отключения компрессора от коллектора высокого давления?",
        answers: [
          "Только обратный клапан",
          "Только 2 запорных вентиля",
          "Только свеча с условным проходом не менее 6 мм, имеющая прямое сообщение с атмосферой",
          "Все перечисленные устройства",
        ],
        correctAnswers: ["Все перечисленные устройства"],
      },
      {
        code: "20303326",
        text: "На какой ступени сжатия водородные компрессоры должны иметь предохранительные клапаны?",
        answers: [
          "На первой ступени сжатия",
          "На второй ступени сжатия",
          "На всех ступенях сжатия",
        ],
        correctAnswers: ["На всех ступенях сжатия"],
      },
      {
        code: "20303327",
        text: "Какой технической документацией должен быть оснащен каждый компрессорный агрегат или установка, или группа однородных компрессорных установок?",
        answers: [
          "Только паспортом (формуляром) на компрессорную установку",
          "Только схемой трубопроводов (сжатого воздуха или газа, воды, масла) с указанием мест установок задвижек, вентилей, влагомаслоотделителей, промежуточных и концевых холодильников, воздухосборников, контрольно-измерительных приборов; схемы вывешиваются на видном месте",
          "Только инструкцией (руководством) по эксплуатации (безопасному обслуживанию) компрессорной установки",
          "Только журналом учета работы компрессора, паспортами-сертификатами компрессорного масла и результатами его лабораторного анализа, графиком ремонтов компрессорной установки",
          "Всей перечисленной технической документацией",
        ],
        correctAnswers: ["Всей перечисленной технической документацией"],
      },
      {
        code: "20303328",
        text: "Какие требования к компрессорным установкам указаны неверно?",
        answers: [
          "Компрессор и его электродвигатель должны устанавливаться на фундаментах, не связанных с конструкциями здания",
          "Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь",
          "В помещении компрессорной установки следует предусматривать специальные места для хранения в закрытом виде обтирочных материалов, инструмента, прокладок, а также для хранения недельного запаса масла",
          "Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи",
        ],
        correctAnswers: [
          "Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь",
        ],
      },
      {
        code: "20303329",
        text: "Какие требования к компрессорным установкам указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Компрессор и его электродвигатель должны устанавливаться на фундаментах, не связанных с конструкциями здания",
          "Двери и окна помещения, где расположены компрессорные установки, должны открываться наружу",
          "В помещении компрессорной установки следует предусматривать специальные места для хранения в закрытом виде обтирочных материалов, инструмента, прокладок, а также для хранения недельного запаса масла",
          "Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20303330",
        text: "Какому классу должна соответствовать герметичность арматуры с металлическим уплотнением в затворе, применяемая для установки на трубопроводах водорода?",
        answers: ["Классу A", "Классу В", "Классу С", "Классу D"],
        correctAnswers: ["Классу В"],
      },
      {
        code: "20303331",
        text: "Какому классу должна соответствовать герметичность затвора запорной арматуры со средой водорода?",
        answers: ["Классу A", "Классу В", "Классу С", "Классу D"],
        correctAnswers: ["Классу A"],
      },
      {
        code: "20303332",
        text: "Какую арматуру не допускается применять на водородопроводах?",
        answers: [
          "Арматуру из серого чугуна",
          "Арматуру из стали",
          "Арматуру из ковкого и высокопрочного чугуна",
        ],
        correctAnswers: ["Арматуру из серого чугуна"],
      },
      {
        code: "20303333",
        text: "В соответствии с требованиями какой документации должны производиться проектирование, монтаж и эксплуатация водородопроводов, а также вспомогательных трубопроводов обвязки участков комплекса получения и потребления водорода?",
        answers: [
          "Только в соответствии с требованиями строительными нормами и правилами",
          "Только в соответствии с требованиями нормативно-технической документацией для технологических стальных трубопроводов",
          "Только в соответствии с требованиями Правил безопасности процессов получения или применения металлов",
          "В соответствии с требованиями всей перечисленной документации",
        ],
        correctAnswers: [
          "В соответствии с требованиями всей перечисленной документации",
        ],
      },
      {
        code: "20303334",
        text: "Где не допускается размещение операторных помещений?",
        answers: [
          "Только над помещениями с взрывоопасными зонами любого класса",
          "Только под душевыми и санузлами",
          "Только под производственными помещениями с мокрым технологическим процессом",
          "Только под приточными вентиляционными камерами",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20303335",
        text: "Какие требования к монтажу и эксплуатации водородопроводов указаны неверно?",
        answers: [
          "При прокладке трубопроводов водорода следует, как правило, использовать бесшовные стальные трубы или трубопроводы из нержавеющей стали, соединенные с применением сварки",
          "Прокладка газопроводов в местах прохода людей должна предусматриваться на высоте не менее 1,8 м от пола до низа газопровода",
          "На трубопроводах водорода, транспортирующих газ в другие здания, к компрессорным, ресиверам и цехам-потребителям, на выходе из здания должны устанавливаться запорная арматура и устройства, исключающие возможность обратного пропуска газа",
          "При прокладке цеховых трубопроводов водорода должна быть предусмотрена самокомпенсация",
        ],
        correctAnswers: [
          "Прокладка газопроводов в местах прохода людей должна предусматриваться на высоте не менее 1,8 м от пола до низа газопровода",
        ],
      },
      {
        code: "20303336",
        text: "Какая продолжительность пневматических испытаний установлена Правилами безопасности процессов получения или применения металлов после проведения плановых или аварийных остановок водородопроводов?",
        answers: ["1 час", "2 часа", "1,5 часа", "0,5 часа"],
        correctAnswers: ["1 час"],
      },
      {
        code: "20303337",
        text: "Через какое время после ввода объекта в эксплуатацию должна проводиться первая выборочная ревизия водородопроводов на вновь осваиваемых производствах?",
        answers: [
          "Не позднее чем через 4 года после ввода объекта в эксплуатацию",
          "Не позднее чем через 5 лет после ввода объекта в эксплуатацию",
          "Не позднее чем через 7 лет после ввода объекта в эксплуатацию",
          "Не позднее чем через 10 лет после ввода объекта в эксплуатацию",
        ],
        correctAnswers: [
          "Не позднее чем через 4 года после ввода объекта в эксплуатацию",
        ],
      },
      {
        code: "20303338",
        text: "С какой периодичностью должны проводиться испытания на плотность и прочность холодных участков (с температурой до 200 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?",
        answers: [
          "1 раз в 8 лет",
          "1 раз в 9 лет",
          "1 раз в 11 лет",
          "1 раз в 10 лет",
        ],
        correctAnswers: ["1 раз в 8 лет"],
      },
      {
        code: "20303339",
        text: "С какой периодичностью должны проводиться испытания на плотность и прочность горячих участков (с температурой 200 - 400 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?",
        answers: [
          "1 раз в 8 лет",
          "1 раз в 7 лет",
          "1 раз в 5 лет",
          "1 раз в 10 лет",
        ],
        correctAnswers: ["1 раз в 5 лет"],
      },
      {
        code: "20303340",
        text: "В каких местах допускается прокладка водородопроводов?",
        answers: [
          "Над поверхностью земли, на стойках и эстакадах",
          "Через производственные помещения, не связанные с потреблением водорода",
          "По стенам зданий на участках со сплошным остеклением и по легкосбрасываемым ограждающим конструкциям",
        ],
        correctAnswers: ["Над поверхностью земли, на стойках и эстакадах"],
      },
      {
        code: "20303341",
        text: "Через какие помещения допускается прокладка водородопроводов?",
        answers: [
          "Через производственные помещения, связанные с потреблением водорода",
          "Через электрораспределительные, трансформаторные помещения, вентиляционные камеры",
          "Через бытовые, подсобные и административно-хозяйственные помещения",
          "Через помещения КИП",
        ],
        correctAnswers: [
          "Через производственные помещения, связанные с потреблением водорода",
        ],
      },
      {
        code: "20303342",
        text: "Какие требования к размещению запорной арматуры на водородопроводах указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Арматуру, работающую в среде водорода, следует выбирать либо специальную для водорода, либо стальную для взрывоопасных сред",
          "Запорная и другая арматура должна устанавливаться в местах, удобных для обслуживания",
          "Арматура должна быть закреплена так, чтобы в результате температурных деформаций газопроводов в них не создавались изгибающие напряжения",
          "Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения",
          "Арматура не должна размещаться над дверными проемами и технологическими проходами",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
    ],
    20304: [
      {
        code: "20304000",
        text: "Что должно быть разработано для обеспечения безопасности при освоении новых производств, технологических процессов и технических устройств?",
        answers: [
          "Инструкции по эксплуатации нового оборудования",
          "Инструкции по проведению пусконаладочных работ",
          "Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования",
        ],
        correctAnswers: [
          "Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования",
        ],
      },
      {
        code: "20304001",
        text: "При каких условиях запрещается эксплуатация плавильных агрегатов?",
        answers: [
          "При разгерметизации системы водяного охлаждения этих агрегатов",
          "При незначительном падении давления воды в системе водоохлаждения",
          "При незначительном повышении давления воды в системе охлаждения",
        ],
        correctAnswers: [
          "При разгерметизации системы водяного охлаждения этих агрегатов",
        ],
      },
      {
        code: "20304002",
        text: "Какие требования установлены к фурмам для продувки жидкого металла газами?",
        answers: [
          "Должны быть защищены специальными экранами от нагревания теплоизлучением",
          "Должны перед установкой храниться не менее 5 суток в специальном помещении",
          "Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями",
        ],
        correctAnswers: [
          "Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями",
        ],
      },
      {
        code: "20304003",
        text: "От чего должны быть защищены составные части производственного оборудования, в том числе энергетические трубопроводы, рукава подачи природного газа, кислорода, мазута, воздуха, масла, воды, троллеи для питания электрического оборудования тележек, перевозящих расплавы металлов, и электрические кабели?",
        answers: [
          "Только от тепловых воздействий",
          "Только от механических воздействий",
          "Только от возможного попадания на них расплава",
          "От всего перечисленного",
        ],
        correctAnswers: ["От всего перечисленного"],
      },
      {
        code: "20304004",
        text: "Какие требования установлены к установке расходных баков с мазутом?",
        answers: [
          "Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением",
          "Должны быть установлены на расстоянии не менее 3 м от печей",
          "Должны быть установлены на расстоянии не менее 4 м от печей и должны быть защищены паровой завесой",
        ],
        correctAnswers: [
          "Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением",
        ],
      },
      {
        code: "20304005",
        text: "Какие требования установлены к загружаемым в печи руде, ферросплавам и другим материалам?",
        answers: [
          "Должны быть выдержаны в помещении не менее 12 часов",
          "Должны быть предварительно просушены или прокалены",
          "Должны быть выдержаны в помещении не менее 48 часов",
        ],
        correctAnswers: ["Должны быть предварительно просушены или прокалены"],
      },
      {
        code: "20304006",
        text: "Что не допускается к загрузке в агрегаты, содержащие расплавленный металл или шлак?",
        answers: [
          "Загрузка шихты, не прошедшей пиротехнический контроль",
          "Загрузка пакетированного металлолома",
          "Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции",
        ],
        correctAnswers: [
          "Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции",
        ],
      },
      {
        code: "20304007",
        text: "Какие требования установлены к инструментам, приспособлениям и оснастке, используемым для ведения технологии или обслуживания оборудования?",
        answers: [
          "Должны находиться на каждом рабочем месте",
          "Должны быть предназначены для выполнения любых работ",
          "Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними",
        ],
        correctAnswers: [
          "Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними",
        ],
      },
      {
        code: "20304008",
        text: "Какое требование к устройству внутренних производственных помещений, находящихся на производственной площадке, включающей технологию с расплавами металлов, имеющей опасность аварии или травмирования работников, указано неверно?",
        answers: [
          "Внутренние производственные помещения должны иметь не менее двух выходов, расположенных с учетом аварийной эвакуации работников; двери помещения должны открываться наружу и не иметь внутренних запоров",
          "Помещения пультов управления должны иметь вытяжную вентиляцию",
          "Пульты управления электропечами должны быть расположены так, чтобы была исключена возможность ослепляющего действия электрической дуги на операторов",
          "Внутренние производственные помещения должны иметь защиту от воздействия теплового излучения",
        ],
        correctAnswers: [
          "Помещения пультов управления должны иметь вытяжную вентиляцию",
        ],
      },
      {
        code: "20304009",
        text: "Как должны быть расположены пульты управления агрегатами?",
        answers: [
          "В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке",
          "На расстоянии не менее 3 м от агрегатов",
          "В непосредственной близости от агрегатов быть оборудованы защитными экранами",
        ],
        correctAnswers: [
          "В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке",
        ],
      },
      {
        code: "20304010",
        text: "Что должны иметь закрома и ямы?",
        answers: [
          "Должны иметь вдоль периметра пешеходные проходы шириной 0,9 м",
          "Со всех сторон должны иметь ограждение",
          "Должны иметь вдоль периметра пешеходные проходы шириной 1,2 м",
        ],
        correctAnswers: ["Со всех сторон должны иметь ограждение"],
      },
      {
        code: "20304011",
        text: "Что должно быть предусмотрено при проектировании конвейеров для подачи материалов? Выберите правильный вариант ответа.",
        answers: [
          "Только предохраняющие устройства, отключающие приводы при перегрузке",
          "Только ограждения приводных, натяжных и отклоняющих барабанов, натяжных устройств, канатов и блоков натяжных устройств, ременных, червячных, муфтовых и других передач",
          "Только исключение падения транспортируемых материалов",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20304012",
        text: "Как должна устраняться пробуксовка ленты конвейера?",
        answers: [
          "Должна устраняться при помощи ручной регулировки",
          "Должна устраняться увеличением трения между тянущим барабаном и лентой с использованием канифоли или других материалов",
          "Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты",
        ],
        correctAnswers: [
          "Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты",
        ],
      },
      {
        code: "20304013",
        text: "Что должно быть исключено в коммуникациях системы транспортирования взрывопожароопасных веществ и материалов?",
        answers: [
          "Возможность разгерметизации системы транспортирования",
          "Попадание в коммуникации воздуха",
          "Образование взрывоопасной среды",
        ],
        correctAnswers: ["Образование взрывоопасной среды"],
      },
      {
        code: "20304014",
        text: "На что должна быть проверена система пневмотранспорта перед вводом в эксплуатацию?",
        answers: [
          "На коррозионную стойкость",
          "На плотность под рабочим давлением",
          "На надежность",
        ],
        correctAnswers: ["На плотность под рабочим давлением"],
      },
      {
        code: "20304015",
        text: "Чем должны быть оборудованы шлаковозы?",
        answers: [
          "Механизмами кантования (поворота) чаши с ручным гидравлическим приводом",
          "Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением",
          "Механизмами кантования (поворота) чаши с ручным пневматическим приводом и автоматическим управлением",
        ],
        correctAnswers: [
          "Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением",
        ],
      },
      {
        code: "20304016",
        text: "Что должно иметься на шлаковом отвале?",
        answers: [
          "Телефонная или радиосвязь с диспетчерской службой производства",
          "Двусторонняя громкоговорящая связь с диспетчерской службой",
          "Мобильная связь с диспетчерской службой предприятия",
        ],
        correctAnswers: [
          "Телефонная или радиосвязь с диспетчерской службой производства",
        ],
      },
      {
        code: "20304017",
        text: "Какие шпалы должны применяться на железнодорожных путях шлакового отвала?",
        answers: [
          "Деревянные шпалы, пропитанные каменноугольным маслом",
          "Огнестойкие шпалы",
          "Деревянные шпалы, пропитанные элемсентом",
        ],
        correctAnswers: ["Огнестойкие шпалы"],
      },
      {
        code: "20304018",
        text: "Какие требования установлены к кабине завалочной машины?",
        answers: [
          "Должна обеспечивать устойчивое выполнение операций",
          "Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака",
          "Должна быть удобной в эксплуатации",
        ],
        correctAnswers: [
          "Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака",
        ],
      },
      {
        code: "20304019",
        text: "Чему подлежат производственные емкости (ковши, шлаковые чаши, совки, кюбели, тара, корзины) после изготовления или ремонта?",
        answers: ["Освидетельствованию", "Проверке состояния", "Экспертизе"],
        correctAnswers: ["Проверке состояния"],
      },
      {
        code: "20304020",
        text: "Чем должны быть оборудованы места пересечения железнодорожных путей для подачи составов ковшей с жидким чугуном с путями для подачи шихтовых материалов?",
        answers: [
          "Должны быть оборудованы светофорами",
          "Должны быть оборудованы постами со стрелочниками",
          "Должны быть оборудованы автоматической сигнализацией",
        ],
        correctAnswers: [
          "Должны быть оборудованы автоматической сигнализацией",
        ],
      },
      {
        code: "20304021",
        text: "Какие требования установлены к месту расстановки ковшей, предназначенных для расплава металла?",
        answers: [
          "Должны расставляться на горизонтальных площадках в любом месте пролета цеха",
          "Должны расставляться на свободных площадках пролета цеха",
          "Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства",
        ],
        correctAnswers: [
          "Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства",
        ],
      },
      {
        code: "20304022",
        text: "Как часто цапфы ковшей должны проверяться методом неразрушающего контроля?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 2 года",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "20304023",
        text: "Какие требования установлены к процессу сливания расплава металла из ковшей и миксеров? Выберите правильный вариант ответа.",
        answers: [
          "Сливание шлака из ковшей и миксеров должно быть механизировано",
          "Ось сливного отверстия ковша, установленного под слив, должна совпадать с осью заливочного ковша",
          "Пол рабочей площадки в местах установки ковшей должен быть сухим",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20304024",
        text: "После чего разрешается производить слив расплава в ковши, вышедшие из ремонта?",
        answers: [
          "Только после тщательного их просушивания и разогрева",
          "Только после охлаждения и увлажнения ковшей",
          "После всего перечисленного",
        ],
        correctAnswers: [
          "Только после тщательного их просушивания и разогрева",
        ],
      },
      {
        code: "20304025",
        text: "Какие требования установлены к оборудованию производственных помещений, в которых возможны воспламенения одежды или химические ожоги?",
        answers: [
          "Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
          "Должны быть в наличии огнетушители в достаточном количестве",
          "Должны быть оборудованы автоматическими установками пожаротушения",
        ],
        correctAnswers: [
          "Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
      },
      {
        code: "20304026",
        text: "В какой зоне не допускается нахождение людей?",
        answers: [
          "В радиусе 20 м от зоны работы электромостовых кранов",
          "На расстоянии 15 м от зоны работы магнитного крана в случае, если кран работает внутри производственного здания",
          "В зоне погрузки грейферными или магнитными кранами",
          "Только в радиусе 15 м от зоны работы любого грузоподъемного сооружения",
        ],
        correctAnswers: ["В зоне погрузки грейферными или магнитными кранами"],
      },
      {
        code: "20304027",
        text: "С какой периодичностью должны проверяться на работоспособность вентиляционные системы и состояние ванн травления?",
        answers: ["Ежедневно", "Ежемесячно", "Еженедельно", "Ежеквартально"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "20304028",
        text: "Какие требования к производственным помещениям, оборудованию участков приготовления травильных растворов и травления металла указаны неверно?",
        answers: [
          "Периодичность проведения уборки помещений устанавливается в зависимости от условий их эксплуатации",
          "Допускается уборка, вызывающая распыление вредных веществ",
          "После работы у ванн травления с химически опасными веществами, используемыми в виде добавок для технологических растворов, вещества должны быть удалены из рабочей зоны на склад",
          "Все перечисленные",
        ],
        correctAnswers: [
          "Допускается уборка, вызывающая распыление вредных веществ",
        ],
      },
      {
        code: "20304029",
        text: "Какие требования установлены к системам освещения во взрывоопасных помещениях?",
        answers: [
          "Должны предусматриваться системы освещения, работающие от напряжения не более 12 В",
          "Должны предусматриваться системы освещения во взрывобезопасном исполнении",
          "Должны предусматриваться системы освещения люминесцентными лампами низкого давления",
        ],
        correctAnswers: [
          "Должны предусматриваться системы освещения во взрывобезопасном исполнении",
        ],
      },
      {
        code: "20304030",
        text: "Что должна обеспечить система светозвуковой сигнализации?",
        answers: [
          "Оповещение эксплуатационного персонала о неисправности технических устройств",
          "Оповещение эксплуатационного персонала о критичном приближении технологического транспорта",
          "Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств",
        ],
        correctAnswers: [
          "Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств",
        ],
      },
      {
        code: "20304031",
        text: "Какие требования установлены к расположению КИПиА?",
        answers: [
          "Приборы должны устанавливаться на расстоянии не менее 3 м от оборудования",
          "Приборы должны устанавливаться на расстоянии не менее 1 м от оборудования",
          "Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования",
        ],
        correctAnswers: [
          "Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования",
        ],
      },
      {
        code: "20304032",
        text: "Какие требования установлены к проверке и испытанию приборов во взрывопожароопасных и пожароопасных помещениях?",
        answers: [
          "Запрещается производить проверку и испытание приборов непосредственно во врывопожароопасных и пожароопасных помещениях",
          "Могут производиться с применением мер безопасности",
          "Должны производиться в условиях, исключающих искрообразование",
        ],
        correctAnswers: [
          "Должны производиться в условиях, исключающих искрообразование",
        ],
      },
      {
        code: "20304033",
        text: "Какие требования предъявляются к устройству и эксплуатации печей для обжига шихтовых материалов и концентратов?",
        answers: [
          "Допускается эксплуатация печей при остаточном разрежении предельно допустимого значения на 5 %",
          "Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции",
          "Запрещается эксплуатация печей при остаточном разрежении выше величины, указанной в технологической инструкции",
        ],
        correctAnswers: [
          "Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции",
        ],
      },
      {
        code: "20304034",
        text: "Какие требования установлены к хранению стержней, шаров, футеровки, запасных деталей и приспособлений?",
        answers: [
          "Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре",
          "Должно быть предусмотрено на любых свободных площадках, обеспечивающих свободный проход",
          "Должно быть предусмотрено только в отдельно стоящих специальных помещениях",
        ],
        correctAnswers: [
          "Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре",
        ],
      },
      {
        code: "20304035",
        text: "Какие требования установлены к проведению ремонтных работ внутри нагретых технических устройств?",
        answers: [
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °С",
          "Проведение ремонтных работ разрешается после продувки и снижения температуры воздуха внутри технических устройств до 45 °C",
          "Проведение ремонтных работ разрешается после снижения температуры воздуха внутри технических устройств до 50 °C и их проветривания",
        ],
        correctAnswers: [
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °С",
        ],
      },
      {
        code: "20304036",
        text: "Какие требования установлены к ведению взрывных работ, хранению, транспортированию взрывчатых материалов и эксплуатации броневых ям?",
        answers: [
          "Работы могут производиться взрывниками в процессе эксплуатации оборудования цеха",
          "Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах",
          "Работы должны производиться специально обученным цеховым обслуживающим персоналом",
        ],
        correctAnswers: [
          "Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах",
        ],
      },
      {
        code: "20304037",
        text: "При наличии каких дефектов ковшей их эксплуатация не допускается?",
        answers: [
          "Ковшей, имеющих раковины, трещины в стенках и в местах крепления цапф",
          "Ковшей, потерявших форму вследствие деформации",
          "Ковшей, имеющих качку цапф в теле ковша",
          " Ковшей с поврежденной футеровкой",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20304038",
        text: 'Каким образом должен проводиться процесс обезвоживания карналлита во вращающихся печах и печах "кипящего слоя"?',
        answers: [
          "Под давлением в соответствии с технологической инструкцией",
          "Под давлением в соответствии с проектной документацией",
          "Под разрежением в соответствии с технологической инструкцией",
          "Под разрежением в соответствии с проектной документацией",
        ],
        correctAnswers: [
          "Под разрежением в соответствии с технологической инструкцией",
        ],
      },
      {
        code: "20304039",
        text: "В каких случаях при производстве глинозема с применением грузоподъемных механизмов напряжение с электродов должно быть снято?",
        answers: [
          "Только при удалении шлама",
          "Только при заливке и перемешивании расплава",
          "Только при производстве работ на миксерах, печах и хлораторах",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20304040",
        text: "Какие требования безопасности при ведении технологических процессов электролитического производства алюминия и магния указаны неверно?",
        answers: [
          "Механизмы управления фрамугами должны быть работоспособными и покрыты электроизоляционным материалом",
          "Конструкция фрамуг, створок и фонарей электролизных корпусов должна исключать попадание внутрь атмосферных осадков",
          "Течи воды в корпуса должны устраняться",
          "Все перечисленные требования указаны верно",
        ],
        correctAnswers: ["Все перечисленные требования указаны верно"],
      },
      {
        code: "20304041",
        text: "Какое допускается максимальное сопротивление заземляющих устройств крановых путей в корпусах электролиза?",
        answers: ["8 Ом", "6 Ом", "4 Ом", "10 Ом"],
        correctAnswers: ["4 Ом"],
      },
      {
        code: "20304042",
        text: "С какой периодичностью необходимо выполнять проверку сопротивления изоляции крюков мостовых кранов и штоков механизма захвата штыревых кранов, а также захвата механизма перестановки обожженных анодов и колонны кабины на комплексных анодных кранах с низкоопущенной кабиной?",
        answers: ["Еженедельно", "Ежемесячно", "Ежеквартально", "Ежесменно"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20304043",
        text: "Каким количеством ступеней изоляции должны быть электроизолированы от земли и строительных конструкций кожухи электролизеров и внутрицеховые шинопроводы?",
        answers: [
          "Не менее чем 2 ступенями изоляции",
          "Не более чем 2 ступенями изоляции",
          "Не более чем 1 ступенью изоляции",
          "Количество ступеней определяется технологической инструкцией",
        ],
        correctAnswers: ["Не менее чем 2 ступенями изоляции"],
      },
      {
        code: "20304044",
        text: "Какие требования безопасности при электролитическом производстве и рафинировании алюминия указаны неверно?",
        answers: [
          "Рельсы напольных машин для обслуживания электролизеров должны иметь электроизоляционные вставки между соседними в ряду электролизерами. Сопротивление изоляции вставок должно быть не менее 500 кОм",
          "Крышки проемов между электролизерами в перекрытии второго этажа, а также перекрытия проемов реконструируемых электролизеров должны быть электроизолированы от кожухов соседних электролизеров",
          'Проверка сопротивления электроизоляции ошиновки и конструкций электролизера от "земли" должна проводиться после монтажа и капитального ремонта',
          "Сопротивление изоляции устройств и конструктивных элементов должно быть не меньше величин, указанных в проекте, разработанном для данного производства",
        ],
        correctAnswers: [
          "Рельсы напольных машин для обслуживания электролизеров должны иметь электроизоляционные вставки между соседними в ряду электролизерами. Сопротивление изоляции вставок должно быть не менее 500 кОм",
        ],
      },
      {
        code: "20304045",
        text: "С какой периодичностью должно проверяться состояние электроизоляции ошиновки и конструкций электролизера?",
        answers: [
          "Не реже1 раза в месяц",
          "Не реже 1 раза в квартал",
          "Не реже 1 раза в год",
          "Не реже 2 раз в год",
        ],
        correctAnswers: ["Не реже1 раза в месяц"],
      },
      {
        code: "20304046",
        text: "На каком расстоянии от анода не допускается пребывание людей при извлечении и подъеме штыря из анодного гнезда в течение первых двух минут с начала подъема?",
        answers: ["Ближе 15 м", "Ближе 10 м", "Ближе 8 м", "Ближе 6 м"],
        correctAnswers: ["Ближе 6 м"],
      },
      {
        code: "20304047",
        text: "Чем должны быть оборудованы ванны с кислотой для травления оборудования?",
        answers: [
          "Укрытиями",
          "Системой подачи аргона",
          "Местными отсосами",
          "Всем перечисленным",
        ],
        correctAnswers: ["Местными отсосами"],
      },
      {
        code: "20304048",
        text: "Какие требования установлены к состоянию вакуум-ковшей, тиглей, коробов, кристаллизаторов, изложниц и других емкостей для расплава перед заливкой в них расплава в электролитическом производстве магния?",
        answers: [
          "Должны быть выдержаны в помещении не менее 12 часов",
          "Должны быть очищены от мусора",
          "Должны быть выдержаны в помещении не менее 24 часов",
        ],
        correctAnswers: ["Должны быть очищены от мусора"],
      },
      {
        code: "20304049",
        text: "Какие из перечисленных требований безопасности при производстве глинозема указаны неверно?",
        answers: [
          'Не допускается открывание люков на топках и газораспределительных камерах во время работы печей "кипящего слоя"',
          "Барабаны для разливки флюсов должны быть предварительно очищены от мусора, посторонних предметов и просушены",
          "Ковши для транспортирования расплавленного карналлита по открытым коридорам и проездам должны быть оборудованы закрывающимися крышками",
          "Допускается перевозка расплава в коробах",
        ],
        correctAnswers: ["Допускается перевозка расплава в коробах"],
      },
      {
        code: "20304050",
        text: "Какая допускается температура пека при транспортировании его по трубопроводам в процессе производства анодной массы и обожженных анодов?",
        answers: [
          "Не должна превышать 50% температуры самовоспламенения",
          "Не должна превышать 60% температуры самовоспламенения",
          "Не должна превышать 70% температуры самовоспламенения",
          "Не должна превышать 80% температуры самовоспламенения",
        ],
        correctAnswers: [
          "Не должна превышать 80% температуры самовоспламенения",
        ],
      },
      {
        code: "20304051",
        text: "Что из перечисленного должно быть заземлено в обязательном порядке при производстве анодной массы и обожженных анодов? Выберите правильный вариант ответа.",
        answers: [
          "Термоцистерны",
          "Сливные трубы",
          "Пекоприемники",
          "Трубопроводы для перекачки пека",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20304052",
        text: "Что из перечисленного допускается при производстве анодной массы и обожженных анодов?",
        answers: [
          "Применение резиновых шлангов для транспортирования жидких пеков",
          "Использование сжатого воздуха для передавливания жидкого пека",
          "Применение открытого огня и курение на складах пека и в зонах его слива из термоцистерн",
          "Очищать аспирационные укрытия и вытяжные воздуховоды от смолистых отложений и угольной пыли",
        ],
        correctAnswers: [
          "Очищать аспирационные укрытия и вытяжные воздуховоды от смолистых отложений и угольной пыли",
        ],
      },
      {
        code: "20304053",
        text: "Через какое время должны закрываться крышки и люки термоцистерн после слива пека при производстве анодной массы и обожженных анодов?",
        answers: [
          "Не ранее 0,5 часа после полного их опорожнения",
          "Не ранее 1 часа после полного их опорожнения",
          "Не ранее 1,5 часов после полного их опорожнения",
          "Не ранее 2 часов после полного их опорожнения",
        ],
        correctAnswers: ["Не ранее 1 часа после полного их опорожнения"],
      },
      {
        code: "20304054",
        text: "Какие из перечисленных устройств (конструкций) должны быть соединены заземляющими проводами с металлоконструкциями, на которых они установлены при ведении технологических процессов производства алюминия? Выберите правильный вариант ответа.",
        answers: [
          "Шкафы пусковой аппаратуры электродвигателей строительных конструкций, установленные на строительные конструкции корпусов",
          "Шкафы пусковой аппаратуры электродвигателей строительных конструкций, установленные на строительные конструкции пола",
          "Корпуса электродвигателей, установленные на электролизерах",
          "Все перечисленные",
        ],
        correctAnswers: [
          "Корпуса электродвигателей, установленные на электролизерах",
        ],
      },
      {
        code: "20304055",
        text: "Чему должно быть равно сопротивление каждой ступени электроизоляции установок электролиза при ведении технологических процессов производства алюминия?",
        answers: [
          "Не менее 50 Ом на каждый вольт максимального напряжения технологического тока, измеренного на выходе из преобразовательной подстанции",
          "Не менее 300 Ом на каждый вольт максимального напряжения технологического тока, измеренного на выходе из преобразовательной подстанции",
          "Не менее 400 Ом на каждый вольт максимального напряжения технологического тока, измеренного на выходе из преобразовательной подстанции",
          "Не менее 500 Ом на каждый вольт максимального напряжения технологического тока, измеренного на выходе из преобразовательной подстанции",
        ],
        correctAnswers: [
          "Не менее 500 Ом на каждый вольт максимального напряжения технологического тока, измеренного на выходе из преобразовательной подстанции",
        ],
      },
      {
        code: "20304056",
        text: "Какие помещения должны быть соединены крытыми коридорами и галереями для транспорта материалов, расплавов и передвижения людей по установленным маршрутам? Выберите правильный вариант ответа.",
        answers: [
          "Электролизные корпуса",
          "Литейные корпуса",
          "Блоки вспомогательных отделений",
          "Бытовые помещения",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20304057",
        text: "Какие действия могут быть предприняты перед проведением операции по перестановке штырей на электролизерах с верхним токопроводом к самообжигающемуся аноду согласно Правилам безопасности процессов получения или применения металлов? Выберите 2 варианта ответа.",
        answers: [
          "Должны быть выставлены знаки, запрещающие вход в опасную зону",
          "Должна быть установлена световая проекция знака стоп",
          "При извлечении и подъеме штыря из анодного гнезда в течение первых двух минут с начала подъема допускается пребывание людей на аноде, анодной площадке и на расстоянии ближе 6 м от анода",
          "Во время ликвидации анодного эффекта на электролизере допускается проводить на нем другие работы",
        ],
        correctAnswers: [
          "Должны быть выставлены знаки, запрещающие вход в опасную зону",
          "Должна быть установлена световая проекция знака стоп",
        ],
      },
      {
        code: "20304058",
        text: "Каким должно быть сопротивление изоляции элементов напольных рельсовых машин на производстве алюминия?",
        answers: [
          "Не менее 0,5 МОм",
          "Не менее 1,0 МОм",
          "Не менее 1,5 МОм",
          "Не менее величин, указанных в проекте, разработанном для данного производства",
        ],
        correctAnswers: [
          "Не менее величин, указанных в проекте, разработанном для данного производства",
        ],
      },
      {
        code: "20304059",
        text: "Какими устройствами из перечисленных не должны быть оснащены электролизеры в обязательном порядке?",
        answers: [
          "Устройствами для дожигания оксида углерода",
          "Устройствами для дожигания смолистых веществ",
          "Устройствами для дожигания сероуглерода",
          "Устройствами для дожигания бензпирена",
        ],
        correctAnswers: ["Устройствами для дожигания сероуглерода"],
      },
      {
        code: "20304060",
        text: "Какие требования к установкам для очистки технологических газов при производстве алюминия указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "В помещениях дымососных станций должны быть установлены автоматические газоанализаторы, сблокированные с автоматическими аварийными системами вытяжной вентиляции, включающимися при образовании в воздухе рабочей зоны концентраций вредных веществ, превышающих ПДК",
          "Все емкостные аппараты очистки газов, содержащие взрывоопасные пыли и газы, должны быть оборудованы предохранительными клапанами",
          "Электролизеры должны быть оснащены устройствами для дожигания оксида углерода, смолистых веществ и бензпирена",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20304061",
        text: "Как часто должно проверяться исправное действие автоблокировки и сигнализации?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в квартал",
          "Не реже 1 раза в полгода",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20304062",
        text: "Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях?",
        answers: [
          "Не реже 1 раза в неделю",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в квартал",
        ],
        correctAnswers: ["Не реже 1 раза в неделю"],
      },
      {
        code: "20304063",
        text: "Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных вне помещений?",
        answers: [
          "Не реже одного раза в квартал",
          "Не реже одного раза в месяц",
          "Не реже одного раза в год",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20304064",
        text: "В каких случаях запрещается газовая резка и сварка на действующих газопроводах?",
        answers: [
          "На газопроводах, находящихся под разрежением",
          "На газопроводах после продувки сжатым воздухом",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["На газопроводах, находящихся под разрежением"],
      },
      {
        code: "20304065",
        text: "На что должен проверяться выполненный сварной шов после врезки в действующий газопровод?",
        answers: [
          "На плотность",
          "На герметичность",
          "На наличие наружных дефектов (трещин, пор)",
        ],
        correctAnswers: ["На плотность"],
      },
      {
        code: "20304066",
        text: "Разрешено ли использовать продувочные свечи для выпуска в атмосферу избыточного газа?",
        answers: [
          "Только в аварийных случаях",
          "Запрещено",
          "Разрешено при увеличении давления газа до 10 %",
        ],
        correctAnswers: ["Только в аварийных случаях"],
      },
      {
        code: "20304067",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии до 0,1 мм/г?",
        answers: ["1 раз в 2 года", "1 раз в 4 года", "1 раз в год"],
        correctAnswers: ["1 раз в 4 года"],
      },
      {
        code: "20304068",
        text: "Какая допускается максимальная температура газа в газопроводах в случае подачи в них пара для пропарки, продувки и отогрева?",
        answers: ["85 °С", "90 °С", "100 °С"],
        correctAnswers: ["85 °С"],
      },
      {
        code: "20304069",
        text: "Как часто должна производиться нивелировка действующих газопроводов?",
        answers: ["1 раз в 5 лет", "1 раз в год", "1 раз в 3 года"],
        correctAnswers: ["1 раз в 5 лет"],
      },
      {
        code: "20304070",
        text: "Куда должны заноситься записи о проведении технического обслуживания, выявленных дефектах и нарушениях газопроводов?",
        answers: [
          "В технический паспорт оборудования",
          "В эксплуатационный журнал",
          "В специальный журнал по учету дефектов и срокам их устранения",
        ],
        correctAnswers: ["В эксплуатационный журнал"],
      },
      {
        code: "20304071",
        text: "До розжига газопотребляющего агрегата чем должен быть продут газопровод перед коллектором?",
        answers: ["Техническим воздухом", "Сжатым воздухом", "Газом"],
        correctAnswers: ["Газом"],
      },
      {
        code: "20304072",
        text: "В какие сроки в помещениях категории А должна осуществляться проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20304073",
        text: "Как часто должны проходить поверку контрольно-измерительные приборы в процессе эксплуатации?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 3 года",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "20304074",
        text: "При каких показателях газа разрешено применять жидкостные манометры?",
        answers: [
          "При давлении до 0,05 МПа",
          "При давлении до 0,04 МПа",
          "При давлении до 0,03 МПа",
        ],
        correctAnswers: ["При давлении до 0,03 МПа"],
      },
      {
        code: "20304075",
        text: "Какой предел огнестойкости должен быть у стен, разделяющих взрывоопасные помещения на водородных станциях?",
        answers: ["0,5 часа", "1,5 часа", "2,0 часа", "2,5 часа"],
        correctAnswers: ["2,5 часа"],
      },
      {
        code: "20304076",
        text: "Какая должна быть минимальная высота забора по периметру территории всего комплекса производства водорода?",
        answers: ["1,8 м", "2,0 м", "2,5 м", "3,0 м"],
        correctAnswers: ["2,0 м"],
      },
      {
        code: "20304077",
        text: "Какое должно быть расстояние между ресиверами одного газа?",
        answers: [
          "Не менее 1,0 м в свету и обеспечивать удобство их обслуживания",
          "Не менее 1,2 м в свету и обеспечивать удобство их обслуживания",
          "Не менее 1,5 м в свету и обеспечивать удобство их обслуживания",
          "Не менее 1,8 м в свету и обеспечивать удобство их обслуживания",
        ],
        correctAnswers: [
          "Не менее 1,5 м в свету и обеспечивать удобство их обслуживания",
        ],
      },
      {
        code: "20304078",
        text: "Какие требования к ресиверам для водорода (сосудам) указаны неверно?",
        answers: [
          "Ресиверы для водорода (сосуды) должны размещаться на открытых площадках, имеющих по периметру ограждение легкого типа высотой не менее 1,2 м из несгораемого материала",
          'На ограждении должны быть вывешены предупреждающие знаки безопасности: "Курить запрещается", "Посторонним вход воспрещен"',
          'На ресиверах должны быть поясняющие надписи: "Водород. Взрывоопасно"',
          "Расстояние от ресиверов (сосудов) с водородом до ограждения должно быть не менее 1,2 м",
        ],
        correctAnswers: [
          "Расстояние от ресиверов (сосудов) с водородом до ограждения должно быть не менее 1,2 м",
        ],
      },
      {
        code: "20304079",
        text: "Какое значение не должно превышать содержание сероводорода в коксовом газе в заводских сетях действующих предприятий?",
        answers: ["0,02 г/нм3", "0,5 г/нм3", "1 г/нм3", "4 г/нм3"],
        correctAnswers: ["4 г/нм3"],
      },
      {
        code: "20304080",
        text: "Какое значение не должно превышать содержание сероводорода в коксовом газе при газоплазменной обработке металла в закрытых помещениях, а также нафталина летом и зимой?",
        answers: [
          "Содержание сероводорода в коксовом газе не должно превышать 0,01 г/нм3, нафталина - 0,03 г/нм3 (летом) и 0,1 г/нм3 (зимой)",
          "Содержание сероводорода в коксовом газе не должно превышать 0,02 г/нм3, нафталина - 0,05 г/нм3 (летом) и 0,1 г/нм3 (зимой)",
          "Содержание сероводорода в коксовом газе не должно превышать 0,04 г/нм3, нафталина - 0,06 г/нм3 (летом) и 0,2 г/нм3 (зимой)",
        ],
        correctAnswers: [
          "Содержание сероводорода в коксовом газе не должно превышать 0,02 г/нм3, нафталина - 0,05 г/нм3 (летом) и 0,1 г/нм3 (зимой)",
        ],
      },
      {
        code: "20304081",
        text: "Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей?",
        answers: [
          "Системами регулирования заданного соотношения топлива, воздуха и водяного пара",
          "Средствами ручного управления подачей водяного пара в топочное пространство и змеевики при прогаре труб",
          "Средствами контроля за температурой в топочном пространстве",
        ],
        correctAnswers: [
          "Системами регулирования заданного соотношения топлива, воздуха и водяного пара",
        ],
      },
      {
        code: "20304082",
        text: "Какое расчетное давление газа соответствует газопроводам среднего давления?",
        answers: ["0,005 МПа", "0,1 МПа", "0,3 МПа", "0,6 МПа", "1,2 МПа"],
        correctAnswers: ["0,3 МПа"],
      },
      {
        code: "20304083",
        text: "Какие требования из перечисленных должны предусматриваться для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты? Выберите правильный вариант ответа.",
        answers: [
          "Должны предусматриваться их дистанционное отключение и установка на линиях всасывания и нагнетания запорных или отсекающих устройств",
          "Только должны предусматриваться их дистанционное отключение",
          "Только должны предусматриваться установка на линиях всасывания и нагнетания запорных или отсекающих устройств",
        ],
        correctAnswers: [
          "Должны предусматриваться их дистанционное отключение и установка на линиях всасывания и нагнетания запорных или отсекающих устройств",
        ],
      },
      {
        code: "20304084",
        text: "В каком случае все технологическое оборудование должно продуваться инертным газом? Выберите 2 варианта ответа.",
        answers: [
          "После остановки более чем на 2 часа",
          "Перед пуском",
          "Если оно в период остановки находилось под избыточным давлением водорода",
          "После остановки более чем на 1 час",
        ],
        correctAnswers: ["После остановки более чем на 2 часа", "Перед пуском"],
      },
      {
        code: "20304085",
        text: "При каком содержании водорода в воздухе производственного помещения по сигналу от автоматического газоанализатора технологическое оборудование этого помещения должно быть остановлено?",
        answers: [
          "Выше 10% нижнего предела взрываемости",
          "Выше 15% высшего предела взрываемости",
          "Выше 20% высшего предела взрываемости",
          "Выше 25% нижнего предела взрываемости",
        ],
        correctAnswers: ["Выше 25% нижнего предела взрываемости"],
      },
      {
        code: "20304086",
        text: "При каком содержании газов должна срабатывать световая и звуковая сигнализации, установленные с автоматическими газоанализаторами в помещениях категории А, где обращается водород?",
        answers: [
          "При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.)",
          "При содержании кислорода менее 19% и более 23%",
          "При содержании угарного газа более 20 мг/м3",
          "При содержании метана не более 10%",
          "Все перечисленное верно",
        ],
        correctAnswers: ["Все перечисленное верно"],
      },
      {
        code: "20304087",
        text: "Какие требования к вентиляции помещения, где возможно выделение водорода, указаны верно? Выберите 2 варианта ответа.",
        answers: [
          "Оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час",
          "При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час",
          "При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 4 в час",
          "При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 2 в час",
        ],
        correctAnswers: [
          "Оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час",
          "При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час",
        ],
      },
      {
        code: "20304088",
        text: "Какие из перечисленных ограждающих конструкций не относятся к легкосбрасываемым?",
        answers: [
          "Окна с оконным стеклом толщиной 3, 4 и 5 мм, площадью не менее соответственно 0,8, 1 и 1,5 м2",
          "Конструкции из асбоцементных, алюминиевых и стальных листов с легким утеплением",
          "Фонарные переплеты с металлическими решетками",
          "Противопожарные двери",
        ],
        correctAnswers: ["Противопожарные двери"],
      },
      {
        code: "20304089",
        text: "Как должны сооружаться газопроводы на территории предприятия?",
        answers: [
          "Должны сооружаться надземными",
          "Должны сооружаться в каналах",
          "Должны сооружаться подземными",
          "Должны сооружаться в футлярах",
        ],
        correctAnswers: ["Должны сооружаться надземными"],
      },
      {
        code: "20304090",
        text: "Через какое расстояние должны заземляться наружные газопроводы?",
        answers: [
          "Через каждые 50 м",
          "Через каждые 150 м",
          "Через каждые 250 м",
          "Через каждые 500 м",
        ],
        correctAnswers: ["Через каждые 250 м"],
      },
      {
        code: "20304091",
        text: "При каком максимальном содержании сероводорода в газе допускается установка бронзовых кранов или задвижек с бронзовыми кольцами на газопроводах?",
        answers: ["10 мг/м3", "15 мг/м3", "20 мг/м3", "25 мг/м3"],
        correctAnswers: ["20 мг/м3"],
      },
      {
        code: "20304092",
        text: "Какое из перечисленных требований к задвижкам и заглушкам, устанавливаемым на газопроводах, указано неверно?",
        answers: [
          "Задвижки и краны, устанавливаемые на газопроводах и устройствах, должны иметь указатель открытого и закрытого положения",
          "Установка внутри зданий и цехов на газопроводах листовых задвижек любого типа без дисковых задвижек перед ними не допускается",
          "Листовые задвижки на газопроводах диаметром более 300 мм должны быть оснащены механизированным приводом",
          "Перекрывающие листы листовых задвижек и заглушек диаметром до 4 м должны изготовляться из целого листа",
        ],
        correctAnswers: [
          "Перекрывающие листы листовых задвижек и заглушек диаметром до 4 м должны изготовляться из целого листа",
        ],
      },
      {
        code: "20304093",
        text: "Какая информация должна быть выбита на хвостовике каждой съемной заглушки газопровода?",
        answers: [
          "Номер сертификата на заглушку",
          "Номер заглушки, марка материала",
          "Условное давление Pу и условный диаметр Ду",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20304094",
        text: "Какая должна быть минимальная высота водяного затвора конденсатоотводчиков межцеховых газопроводов, работающих под избыточным давлением?",
        answers: [
          "2000 мм вод. ст.",
          "1500 мм вод. ст.",
          "1000 мм вод. ст.",
          "500 мм вод. ст.",
        ],
        correctAnswers: ["2000 мм вод. ст."],
      },
      {
        code: "20304095",
        text: "Какое из перечисленных требований к отводу конденсата из цеховых газопроводов указано неверно?",
        answers: [
          "Сброс конденсата из цеховых газопроводов следует производить через конденсатоотводчики",
          "Допускается сброс конденсата через горелки печей и другого оборудования потребителя газа",
          "Конструкция конденсатоотводчиков, установленных в зданиях цехов, должна исключать возможность попадания газов в помещения",
          "Участки труб, отводящих конденсат, а также сами конденсатоотводчики, если цех не отапливается, должны быть утеплены",
        ],
        correctAnswers: [
          "Допускается сброс конденсата через горелки печей и другого оборудования потребителя газа",
        ],
      },
      {
        code: "20304096",
        text: "Какое из перечисленных требований к стационарным газоразборным постам указано неверно?",
        answers: [
          "Стационарные газоразборные посты должны быть размещены в металлических шкафах с отверстиями для вентиляции",
          "Расстояние между шкафами газоразборных постов для кислорода и горючего газа должно быть не менее 150 мм",
          "Дверцы шкафов во время работы должны быть закрыты",
          "При отсутствии рабочего, пользующегося газоразборным постом, шкаф должен быть закрыт на замок",
          "Газоразборные посты, шкафы должны иметь опознавательную окраску",
        ],
        correctAnswers: ["Дверцы шкафов во время работы должны быть закрыты"],
      },
      {
        code: "20304097",
        text: "Какой должен быть нижний предел давления для I разряда газов-заменителей ацетилена перед газопламенной аппаратурой?",
        answers: [
          "Не ниже указанного в характеристике горелки или резака, но не ниже 1,0 кПа",
          "Не ниже указанного в характеристике горелки или резака, но не ниже 2,0 кПа",
          "Не ниже указанного в характеристике горелки или резака, но не ниже 3,0 кПа",
          "Не ниже указанного в характеристике горелки или резака, но не ниже 4,0 кПа",
        ],
        correctAnswers: [
          "Не ниже указанного в характеристике горелки или резака, но не ниже 3,0 кПа",
        ],
      },
      {
        code: "20304098",
        text: "При каком увеличении содержания кислорода в газе в напорном коллекторе газоотсасывающей станции отвод газа от электропечи должен быть немедленно прекращен?",
        answers: ["До 1,0%", "До 1,5%", "До 2,0%", "До 2,5%"],
        correctAnswers: ["До 2,0%"],
      },
      {
        code: "20304099",
        text: "Каким уплотнительным устройством, препятствующим выбиванию газов в цех, должно оборудоваться отверстие в охладителе конвертерных газов?",
        answers: [
          "Аэродинамической завесой",
          "Воздушной завесой",
          "Азотной завесой",
          "Всем вышеперечисленным",
        ],
        correctAnswers: ["Всем вышеперечисленным"],
      },
      {
        code: "20304100",
        text: "Какое допускается максимальное содержание оксида углерода в газах, поступающих в электрофильтр, при отводе газов с полным дожиганием?",
        answers: [
          "Не должно превышать 4%",
          "Не должно превышать 3%",
          "Не должно превышать 2%",
          "Не должно превышать 1%",
        ],
        correctAnswers: ["Не должно превышать 1%"],
      },
      {
        code: "20304101",
        text: "Содержание каких из перечисленных газов измеряется в продуктах неполного сгорания за дымососом при отводе газов без дожигания? Выберите 2 варианта ответа.",
        answers: ["CO", "CO2", "O2", "H2"],
        correctAnswers: ["CO", "O2"],
      },
      {
        code: "20304102",
        text: "С какой периодичностью газопроводы сероочистки природного газа подвергаются пневматическому испытанию?",
        answers: [
          "1 раз в год",
          "1 раз в 2 года",
          "1 раз в 3 года",
          "1 раз в 4 года",
        ],
        correctAnswers: ["1 раз в 4 года"],
      },
      {
        code: "20304103",
        text: "Какое из перечисленных требований к установке сероочистки природного газа указано верно?",
        answers: [
          "Установка сероочистки природного газа для получения реформерного газа должна быть снабжена быстродействующими отсекающими клапанами на входе газа в установку, срабатывающими при снижении или повышении давления газа от установленных проектом значений",
          "Содержание CH4 в двух последовательно отобранных пробах должно быть не более 2%",
          "Установка сероочистки должна работать в ручном режиме и снабжаться блокировками, отключающими установку при отклонении параметров от заданных",
          "Перед пуском после ремонта аппараты и газопроводы сероочистки должны быть испытаны на плотность и прочность, продуты азотом до уровня содержания O2 не более 3% в двух последовательно отобранных пробах",
        ],
        correctAnswers: [
          "Установка сероочистки природного газа для получения реформерного газа должна быть снабжена быстродействующими отсекающими клапанами на входе газа в установку, срабатывающими при снижении или повышении давления газа от установленных проектом значений",
        ],
      },
      {
        code: "20304104",
        text: "Какое минимальное остаточное давление должны иметь баллоны, подготовленные для наполнения водородом?",
        answers: ["0,01 МПа", "0,03 МПа", "0,05 МПа", "0,1 МПа"],
        correctAnswers: ["0,05 МПа"],
      },
      {
        code: "20304105",
        text: "На какой высоте от пола должны быть расположены окна в помещениях отделений наполненных баллонов, наполнительных и разрядных рамп?",
        answers: [
          "Не менее 1,1 м от пола",
          "Не менее 1,3 м от пола",
          "Не менее 1,4 м от пола",
          "Не менее 1,5 м от пола",
        ],
        correctAnswers: ["Не менее 1,5 м от пола"],
      },
      {
        code: "20304106",
        text: "Какой должна быть ширина свободного прохода между щитом управления вентилями и ближайшими стенами наполнительной?",
        answers: [
          "Не менее 0,6 м",
          "Не менее 0,8 м",
          "Не менее 1,0 м",
          "Не менее 1,2 м",
        ],
        correctAnswers: ["Не менее 0,8 м"],
      },
      {
        code: "20304107",
        text: "Стальными листами какой толщины должны отделяться от рабочей зоны баллоны с водородом при их горизонтальном расположении и размещении внутри помещения?",
        answers: [
          "Не менее 12 мм",
          "Не менее 14 мм",
          "Не менее 16 мм",
          "Не менее 18 мм",
        ],
        correctAnswers: ["Не менее 16 мм"],
      },
      {
        code: "20304108",
        text: "При какой чистоте водорода должен производиться пуск компрессора для наполнения баллонов?",
        answers: [
          "Не менее 99,0%",
          "Не менее 99,5%",
          "Не менее 99,7%",
          "Не менее 99,9%",
        ],
        correctAnswers: ["Не менее 99,7%"],
      },
      {
        code: "20304109",
        text: "Какие устройства должны быть установлены на нагнетательном газопроводе для отключения компрессора от коллектора высокого давления?",
        answers: [
          "Обратный клапан",
          "Два запорных вентиля",
          "Свеча с условным проходом не менее 6 мм, имеющая прямое сообщение с атмосферой",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20304110",
        text: "На какой ступени сжатия водородные компрессоры должны иметь предохранительные клапаны?",
        answers: [
          "На первой ступени",
          "На последней ступени",
          "На всех ступенях",
        ],
        correctAnswers: ["На всех ступенях"],
      },
      {
        code: "20304111",
        text: "Через какое время должны наноситься защитные лакокрасочные покрытия на фланцы и болты межцеховых и цеховых газопроводов и газовых аппаратов?",
        answers: [
          "Через каждые 2 года",
          "Через каждые 3 года",
          "Через каждые 5 лет",
          "Через каждые 7 лет",
        ],
        correctAnswers: ["Через каждые 3 года"],
      },
      {
        code: "20304112",
        text: "Какой технической документацией должен быть оснащен каждый компрессорный агрегат или установка, или группа однородных компрессорных установок?",
        answers: [
          "Паспортом (формуляром) на компрессорную установку",
          "Инструкцией (руководством) по эксплуатации (безопасному обслуживанию) компрессорной установки",
          "Схемой трубопроводов (сжатого воздуха или газа, воды, масла) с указанием мест установок задвижек, вентилей, влагомаслоотделителей, промежуточных и концевых холодильников, воздухосборников, контрольно-измерительных приборов",
          "Журналом учета работы компрессора",
          "Всей перечисленной",
        ],
        correctAnswers: ["Всей перечисленной"],
      },
      {
        code: "20304113",
        text: "Какие требования к компрессорным установкам указаны неверно?",
        answers: [
          "Изготовление, монтаж, наладка, ремонт, испытания и эксплуатация компрессорных установок должны проводиться специализированными в этой области организациями",
          "Качество изготовления компрессорных установок должно соответствовать требованиям нормативно-технической документации и документации организации-изготовителя",
          "Двери и окна помещения, где расположены компрессорные установки, должны открываться наружу",
          "Компрессор и его электродвигатель должны устанавливаться на фундаментах, связанных с конструкциями здания",
        ],
        correctAnswers: [
          "Компрессор и его электродвигатель должны устанавливаться на фундаментах, связанных с конструкциями здания",
        ],
      },
      {
        code: "20304114",
        text: "Какие требования к компрессорным установкам указаны верно?",
        answers: [
          "Изготовление, монтаж, наладка, ремонт, испытания и эксплуатация компрессорных установок должны проводиться специализированными в этой области организациями",
          "Качество изготовления компрессорных установок должно соответствовать требованиям нормативно-технической документации и документации организации-изготовителя",
          "Для уменьшения динамических нагрузок компрессоры должны устанавливаться на виброизолирующих фундаментах или с устройством амортизаторов (антивибраторов) либо должны приниматься другие меры для уменьшения вибраций",
          "Все перечисленное верно",
        ],
        correctAnswers: ["Все перечисленное верно"],
      },
      {
        code: "20304115",
        text: "Через какое время должны наноситься защитные лакокрасочные покрытия на наружные поверхности межцеховых и цеховых газопроводов и газовых аппаратов?",
        answers: [
          "Через каждые 2 года",
          "Через каждые 3 года",
          "Через каждые 5 лет",
          "Через каждые 7 лет",
        ],
        correctAnswers: ["Через каждые 5 лет"],
      },
      {
        code: "20304116",
        text: "С какой периодичностью должен производиться осмотр газопроводов, газовых установок и газового оборудования комиссией на производстве?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 2 раз в год",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в квартал",
        ],
        correctAnswers: ["Не реже 2 раз в год"],
      },
      {
        code: "20304117",
        text: "Кто из перечисленных лиц не должен входить в состав постоянно действующей комиссии для проведения осмотров объектов газового хозяйства?",
        answers: [
          "Начальник цеха - ответственный за производственный контроль",
          "Работник - ответственный за безопасную эксплуатацию и техническое состояние газового хозяйства цеха",
          "Работники, ответственные за безопасную эксплуатацию и техническое состояние соответствующих технических устройств, сооружений и оборудования",
          "Технический руководитель организации - главный инженер",
        ],
        correctAnswers: [
          "Технический руководитель организации - главный инженер",
        ],
      },
      {
        code: "20304118",
        text: "С какой периодичностью должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях, и вне помещений?",
        answers: [
          "Не реже 1 раза в неделю, а вне помещений - не реже 1 раза в полгода",
          "Не реже 1 раза в неделю, а вне помещений - не реже 1 раза в квартал",
          "Не реже 1 раза в неделю, а вне помещений - не реже 1 раза в месяц",
        ],
        correctAnswers: [
          "Не реже 1 раза в неделю, а вне помещений - не реже 1 раза в месяц",
        ],
      },
      {
        code: "20304119",
        text: "С какой периодичностью должна производиться ревизия с разборкой регулятора давления, предохранительных клапанов, фильтров газопроводов, газовых установок и газового оборудования?",
        answers: [
          "Не менее 1 раза в полгода",
          "Не менее 1 раза в год",
          "Не менее 1 раза в 3 года",
          "Не менее 1 раза в 5 лет",
        ],
        correctAnswers: ["Не менее 1 раза в год"],
      },
      {
        code: "20304120",
        text: "До каких пор должна осуществляться продувка азотом перед пуском в работу установки по производству реформерного газа для производства металлизованного горячевосстановленного железа?",
        answers: [
          "Пока на участке продувки кислорода будет не более 1% кислорода",
          "Пока на участке продувки кислорода будет не более 2% кислорода",
          "Пока на участке продувки кислорода будет не более 3% кислорода",
          "Пока на участке продувки кислорода будет не более 4% кислорода",
        ],
        correctAnswers: [
          "Пока на участке продувки кислорода будет не более 1% кислорода",
        ],
      },
      {
        code: "20304121",
        text: "Какое из перечисленных требований к установке по производству реформерного газа указано неверно?",
        answers: [
          "Должна иметь подвод азота для его продувки перед пуском в работу",
          "Перед розжигом горелок реформера должна быть произведена продувка его топочного пространства",
          "Не допускается продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами",
          "При остановке агрегатов по производству реформерного газа подача воздуха и природного газа на горелки должна быть открыта",
        ],
        correctAnswers: [
          "При остановке агрегатов по производству реформерного газа подача воздуха и природного газа на горелки должна быть открыта",
        ],
      },
      {
        code: "20304122",
        text: "Кто утверждает инструкцию по перекачке сероуглерода из транспортной емкости в емкость хранения?",
        answers: [
          "Руководитель предприятия",
          "Главный технолог предприятия",
          "Главный инженер предприятия",
        ],
        correctAnswers: ["Главный инженер предприятия"],
      },
      {
        code: "20304123",
        text: "Кем производится испытание газопроводов и газовых установок после окончания строительно-монтажных и сварочных работ (включая ремонтные работы)?",
        answers: [
          "Строительно-монтажной организацией",
          "Комиссией предприятия-заказчика",
          "Комиссией предприятия-заказчика, с участием представителя Ростехнадзора",
          "Строительно-монтажной организацией с участием представителя предприятия",
        ],
        correctAnswers: [
          "Строительно-монтажной организацией с участием представителя предприятия",
        ],
      },
      {
        code: "20304124",
        text: "Какое из перечисленных требований к манометрам, используемым при проведении испытаний газопроводов и газовых установок, указано верно?",
        answers: [
          "Должны быть прошедшими поверку и опломбированными",
          "Класс точности манометров должен быть не ниже 1,5",
          "Диаметр корпуса должен быть не менее 160 мм и шкалой на номинальное давление не менее 4/3 и не более 5/3 от величины измеряемого",
          "Все перечисленное верно",
        ],
        correctAnswers: ["Все перечисленное верно"],
      },
      {
        code: "20304125",
        text: "В течение какого времени выдерживают испытательное давление в трубопроводе при испытании на прочность, после чего снижают до рабочего давления, при котором производят тщательный осмотр сварных швов (испытание на плотность)?",
        answers: [
          "В течение 30 минут",
          "В течение 15 минут",
          "В течение 10 минут",
          "В течение 5 минут",
        ],
        correctAnswers: ["В течение 10 минут"],
      },
      {
        code: "20304126",
        text: "Какое устанавливается минимальное расстояние от испытываемого газопровода большого диаметра, расположенных вне помещений, до границ охраняемой зоны во время проведения пневматических испытаний на прочность?",
        answers: ["10 м", "25 м", "50 м", "100 м"],
        correctAnswers: ["50 м"],
      },
      {
        code: "20304127",
        text: "Каким давлением производится дополнительное испытание на герметичность межцеховых и внутрицеховых газопроводов?",
        answers: [
          "Давлением, превышающим рабочее в 5/3 раза",
          "Давлением, превышающим рабочее в 1,5 раза",
          "Давлением, превышающим рабочее в 1,25 раза",
          "Давлением, равным рабочему",
        ],
        correctAnswers: ["Давлением, равным рабочему"],
      },
      {
        code: "20304128",
        text: "Какая установлена продолжительность дополнительных испытаний на герметичность для вновь сооружаемых газопроводов?",
        answers: [
          "Не менее 4 часов",
          "Не менее 12 часов",
          "Не менее 24 часов",
          "Не менее 48 часов",
        ],
        correctAnswers: ["Не менее 24 часов"],
      },
      {
        code: "20304129",
        text: "Какая устанавливается продолжительность периодических испытаний на герметичность для газопроводов, а также испытаний после ремонта, связанного со сваркой и разборкой газопровода?",
        answers: [
          "Устанавливается техническим руководством предприятия, но должна быть не менее 4 часов",
          "Устанавливается техническим руководством предприятия, но должна быть не менее 8 часов",
          "Устанавливается техническим руководством предприятия, но должна быть не менее 12 часов",
          "Устанавливается техническим руководством предприятия, но должна быть не менее 24 часов",
        ],
        correctAnswers: [
          "Устанавливается техническим руководством предприятия, но должна быть не менее 4 часов",
        ],
      },
      {
        code: "20304130",
        text: "При какой скорости падения давления за время дополнительного пневматического испытания на герметичность внутрицеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?",
        answers: [
          "Не более 0,1% в час",
          "Не более 0,2% в час",
          "Не более 0,3% в час",
        ],
        correctAnswers: ["Не более 0,1% в час"],
      },
      {
        code: "20304131",
        text: "При какой скорости падения давления за время дополнительного пневматического испытания на герметичность межцеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?",
        answers: [
          "Не более 0,1% в час",
          "Не более 0,2% в час",
          "Не более 0,3% в час",
        ],
        correctAnswers: ["Не более 0,2% в час"],
      },
      {
        code: "20304132",
        text: "У кого должен находиться агрегатный (ремонтный) журнал на отдельных объектах газового цеха, а также в цехах, в составе которых имеются объекты газового хозяйства?",
        answers: [
          "У работника, ответственного за производственный контроль",
          "У начальника цеха",
          "У ответственного за безопасное производство работ данного объекта газового хозяйства",
          "У работника, ответственного за техническое состояние данного объекта газового хозяйства",
        ],
        correctAnswers: [
          "У работника, ответственного за техническое состояние данного объекта газового хозяйства",
        ],
      },
      {
        code: "20304133",
        text: "При каком избыточном давлении газа в газопроводах разрешается производить электросварочные работы на газопроводах при ремонтах?",
        answers: [
          "Не более 0,3 кПа",
          "Не более 0,5 кПа",
          "Не более 3 кПа",
          "Не более 5 кПа",
        ],
        correctAnswers: ["Не более 0,3 кПа"],
      },
      {
        code: "20304134",
        text: "Какие действия должны быть произведены перед продувкой газопровода? Выберите правильный вариант ответа.",
        answers: [
          "Допускается продувка с выпуском газовоздушной смеси в топки печей, котлов и других агрегатов",
          "Допускается поджигание газа, выпускаемого при продувке",
          "По окончании продувки содержание кислорода в газе не должно превышать 2% в двух последовательно отобранных пробах",
          "Задвижки должны быть установлены в нужное положение, водяные затворы залиты водой, а все люки, лазы и свечи плотно закрыты, за исключением той свечи, через которую будет производиться продувка",
        ],
        correctAnswers: [
          "Задвижки должны быть установлены в нужное положение, водяные затворы залиты водой, а все люки, лазы и свечи плотно закрыты, за исключением той свечи, через которую будет производиться продувка",
        ],
      },
      {
        code: "20304135",
        text: "В каком радиусе у мест выброса газовоздушной смеси в атмосферу должно быть приостановлено движение всех видов транспорта, запрещено применение открытого огня и производство сварочных работ в период продувки газопровода?",
        answers: [
          "В радиусе 15 м",
          "В радиусе 25 м",
          "В радиусе 50 м",
          "В радиусе 75 м",
        ],
        correctAnswers: ["В радиусе 50 м"],
      },
      {
        code: "20304136",
        text: "Какому классу должна соответствовать герметичность арматуры с металлическим уплотнением в затворе, применяемая для установки на трубопроводах водорода?",
        answers: ["Классу A", "Классу В", "Классу С", "Классу D"],
        correctAnswers: ["Классу В"],
      },
      {
        code: "20304137",
        text: "Какому классу должна соответствовать герметичность затвора запорной арматуры со средой водорода?",
        answers: ["Классу A", "Классу В", "Классу С", "Классу D"],
        correctAnswers: ["Классу A"],
      },
      {
        code: "20304138",
        text: "Какую арматуру не допускается применять на водородопроводах?",
        answers: [
          "Арматуру из серого чугуна",
          "Стальную арматуру для взрывоопасных сред",
          "Специальную арматуру для водорода",
          "Арматуру из ковкого и высокопрочного чугуна",
        ],
        correctAnswers: ["Арматуру из серого чугуна"],
      },
      {
        code: "20304139",
        text: "Каким должно быть минимально допустимое давление газа непосредственно перед потребителем (после регулирующих приборов)?",
        answers: ["0,5 кПа", "0,4 кПа", "0,3 кПа"],
        correctAnswers: ["0,5 кПа"],
      },
      {
        code: "20304140",
        text: "В соответствии с требованиями какой документации должны производиться проектирование, монтаж и эксплуатация водородопроводов, а также вспомогательных трубопроводов обвязки участков комплекса получения и потребления водорода?",
        answers: [
          "Строительных норм и правил",
          "Нормативно-технической документации для технологических стальных трубопроводов",
          "Правил безопасности процессов получения или применения металлов",
          "Всей перечисленной документации",
        ],
        correctAnswers: ["Всей перечисленной документации"],
      },
      {
        code: "20304141",
        text: "В каком случае допускается использовать продувочные свечи газопроводов для выпуска в атмосферу избыточного газа?",
        answers: [
          "Всегда",
          "В аварийных случаях",
          "По распоряжению главного инженера",
        ],
        correctAnswers: ["В аварийных случаях"],
      },
      {
        code: "20304142",
        text: "Какие сроки проведения ревизии газопроводов должны быть при скорости коррозии более 0,5 мм/г?",
        answers: ["1 раз в год", "1 раз в 2 года", "1 раз в 4 года"],
        correctAnswers: ["1 раз в год"],
      },
      {
        code: "20304143",
        text: "При какой скорости коррозии срок проведения ревизии газопроводов должен быть не реже одного раза в 2 года?",
        answers: ["До 0,1 мм/г", "0,1 - 0,5 мм/г", "Более 0,5 мм/г"],
        correctAnswers: ["0,1 - 0,5 мм/г"],
      },
      {
        code: "20304144",
        text: "Какой допускается максимальный подъем температуры газа в газопроводах?",
        answers: ["65 °C", "75 °C", "85 °C", "90 °C"],
        correctAnswers: ["85 °C"],
      },
      {
        code: "20304145",
        text: "В каком случае допускается подача газа без поднесения запальника, факела или другого воспламеняющего средства к горелке газопровода?",
        answers: [
          "Если температура кладки в разогретой зоне розжига факела превышает 500 °C",
          "Если температура кладки в разогретой зоне розжига факела превышает 600 °C",
          "Если температура кладки в разогретой зоне розжига факела превышает 700 °C",
          "Если температура кладки в разогретой зоне розжига факела превышает 800 °C",
        ],
        correctAnswers: [
          "Если температура кладки в разогретой зоне розжига факела превышает 800 °C",
        ],
      },
      {
        code: "20304146",
        text: "Кто осуществляет выбор типа быстродействующего отсекающего клапана на подводе газа к каждому агрегату с дутьевыми горелками?",
        answers: [
          "Организация, эксплуатирующая систему автоматики печи",
          "Специализированная организация",
          "Организация, проектирующая систему автоматики печи",
        ],
        correctAnswers: ["Организация, проектирующая систему автоматики печи"],
      },
      {
        code: "20304147",
        text: "Какое из перечисленных требований при эксплуатации газового оборудования газопотребляющих агрегатов указано верно?",
        answers: [
          "Розжиг газопотребляющего агрегата должен проводиться в соответствии с технологической инструкцией или инструкцией по пуску и остановке агрегата",
          "Продувка газопровода и коллектора агрегата должна производиться при открытых задвижках на горелках",
          "Отключающее устройство на газопроводе перед горелкой разрешается открывать до поднесения к горелке запальника, факела или другого средства, воспламеняющего газ",
          "Подачу газа в котлах без поднесения запальника, факела или другого воспламеняющего средства допускается производить, если температура кладки в разогретой зоне розжига факела превышает 800 °C",
        ],
        correctAnswers: [
          "Розжиг газопотребляющего агрегата должен проводиться в соответствии с технологической инструкцией или инструкцией по пуску и остановке агрегата",
        ],
      },
      {
        code: "20304148",
        text: "С какой периодичностью должна производиться режимная наладка газоиспользующего оборудования?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 4 года",
        ],
        correctAnswers: ["Не реже 1 раза в 3 года"],
      },
      {
        code: "20304149",
        text: "В каком случае допускается режимную наладку газоиспользующего оборудования проводить не реже одного раза в четыре года?",
        answers: [
          "Всегда",
          "При режимной наладке газоиспользующего оборудования",
          "При стабильной работе газоиспользующего оборудования",
        ],
        correctAnswers: [
          "При стабильной работе газоиспользующего оборудования",
        ],
      },
      {
        code: "20304150",
        text: "С какой периодичностью должна производиться проверка технического состояния вентиляционных каналов в зданиях (помещениях) с установленным газоиспользующим оборудованием и пылеуборка, и дезинфекция вентиляционных каналов?",
        answers: [
          "Не реже 1 раза в год. Пылеуборка и дезинфекция вентиляционных каналов должна проводиться не реже 1 раза в 3 месяца",
          "Не реже 2 раз в год. Пылеуборка и дезинфекция вентиляционных каналов должна проводиться не реже 1 раза в год",
          "Не реже 1 раза в год. Пылеуборка и дезинфекция вентиляционных каналов должна проводиться не реже 1 раза в 2 года",
          "Не реже 2 раз в год. Пылеуборка и дезинфекция вентиляционных каналов должна проводиться не реже 1 раза в 3 года",
        ],
        correctAnswers: [
          "Не реже 2 раз в год. Пылеуборка и дезинфекция вентиляционных каналов должна проводиться не реже 1 раза в 3 года",
        ],
      },
      {
        code: "20304151",
        text: "С какой периодичностью должны производиться проверка технического состояния промышленных дымоотводящих устройств (газоходов газоиспользующего оборудования, дымоходов и дымовых труб) и их прочистка?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 2 раз в год",
          "Не реже 1 раза в квартал",
          "Не реже 1 раза в месяц",
        ],
        correctAnswers: ["Не реже 2 раз в год"],
      },
      {
        code: "20304152",
        text: "Где не допускается размещение операторных помещений?",
        answers: [
          "Над помещениями с взрывоопасными зонами любого класса",
          "Под душевыми, санузлами, под производственными помещениями с мокрым технологическим процессом",
          "Под приточными вентиляционными камерами",
          "Все перечисленное верно",
        ],
        correctAnswers: ["Все перечисленное верно"],
      },
      {
        code: "20304153",
        text: "Какие требования к монтажу и эксплуатации водородопроводов указаны неверно?",
        answers: [
          "Проектирование, монтаж и эксплуатация должны производиться в соответствии с требованиями строительных норм и правил",
          "Проектирование, монтаж и эксплуатация должны производиться в соответствии с требованиями нормативно-технической документации для технологических стальных трубопроводов",
          "При прокладке трубопроводов водорода следует, как правило, использовать полиэтиленовые трубы",
        ],
        correctAnswers: [
          "При прокладке трубопроводов водорода следует, как правило, использовать полиэтиленовые трубы",
        ],
      },
      {
        code: "20304154",
        text: "Какая продолжительность пневматических испытаний установлена Правилами безопасности процессов получения или применения металлов после проведения плановых или аварийных остановок водородопроводов?",
        answers: ["0, 5 часа", "1 час", "1,5 часа", "8 часов"],
        correctAnswers: ["1 час"],
      },
      {
        code: "20304155",
        text: "Через какое время после ввода объекта в эксплуатацию должна проводиться первая выборочная ревизия водородопроводов на вновь осваиваемых производствах?",
        answers: [
          "Не позднее чем через год после ввода объекта в эксплуатацию",
          "Не позднее чем через 2 года после ввода объекта в эксплуатацию",
          "Не позднее чем через 3 года после ввода объекта в эксплуатацию",
          "Не позднее чем через 4 года после ввода объекта в эксплуатацию",
        ],
        correctAnswers: [
          "Не позднее чем через 4 года после ввода объекта в эксплуатацию",
        ],
      },
      {
        code: "20304156",
        text: "С какой периодичностью должны проводиться испытания на плотность и прочность холодных участков (с температурой до 200 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?",
        answers: [
          "1 раз в год",
          " 1 раз в 3 года",
          "1 раз в 5 лет",
          "1 раз в 8 лет",
        ],
        correctAnswers: ["1 раз в 8 лет"],
      },
      {
        code: "20304157",
        text: "С какой периодичностью должны проводиться испытания на плотность и прочность горячих участков (с температурой 200 - 400 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?",
        answers: [
          "1 раз в год",
          "1 раз в 3 года",
          "1 раз в 5 лет",
          "1 раз в 8 лет",
        ],
        correctAnswers: ["1 раз в 5 лет"],
      },
      {
        code: "20304158",
        text: "В каких местах допускается прокладка водородопроводов?",
        answers: [
          "В пределах предприятий должны прокладываться преимущественно над поверхностью земли",
          "В пределах предприятий должны прокладываться на стойках",
          "В пределах предприятий должны прокладываться на эстакадах",
          "Все перечисленное верно",
        ],
        correctAnswers: ["Все перечисленное верно"],
      },
      {
        code: "20304159",
        text: "Через какие помещения допускается прокладка водородопроводов?",
        answers: [
          "Через бытовые, подсобные, административно-хозяйственные, складские помещения",
          "Через электромашинные, электрораспределительные, трансформаторные помещения и помещения КИП",
          "Через вентиляционные камеры",
          "Через лестничные клетки, пути эвакуации",
          "Через производственные помещения, связанные с потреблением водорода",
        ],
        correctAnswers: [
          "Через производственные помещения, связанные с потреблением водорода",
        ],
      },
      {
        code: "20304160",
        text: "Какие требования к размещению запорной арматуры на водородопроводах указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Должна устанавливаться в местах, удобных для обслуживания",
          "Должна быть закреплена так, чтобы в результате температурных деформаций газопроводов в них не создавались изгибающие напряжения",
          "Не должна размещаться над дверными проемами и технологическими проходами",
          "Все перечисленное верно",
        ],
        correctAnswers: ["Все перечисленное верно"],
      },
      {
        code: "20304161",
        text: "На каких минимальных расстояниях (по горизонтали) до групповых газобаллонных установок допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?",
        answers: ["1,5 м", "3 м", "5 м", "10 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "20304162",
        text: "На каких минимальных расстояниях (по горизонтали) до отдельных баллонов с кислородом и горючими газами допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?",
        answers: ["1,5 м", "3 м", "5 м", "10 м"],
        correctAnswers: ["5 м"],
      },
      {
        code: "20304163",
        text: "В каком положении должны находиться баллоны со сжиженным газом во время работы?",
        answers: [
          "В вертикальном положении",
          "В наклонном положении с вентилем, направленным вверх",
          "В горизонтальном положении",
        ],
        correctAnswers: ["В вертикальном положении"],
      },
      {
        code: "20304164",
        text: "Какое действие должно быть осуществлено в первую очередь при зажигании ручной горелки или резака?",
        answers: [
          "Должен быть немного приоткрыт вентиль кислорода",
          "Должен быть открыт вентиль горючего газа",
          "Должна быть кратковременная продувка рукава для удаления воздуха",
          "Должна быть зажжена горючая смесь газов",
        ],
        correctAnswers: ["Должен быть немного приоткрыт вентиль кислорода"],
      },
      {
        code: "20304165",
        text: "Какое из перечисленных действий допускается при эксплуатации оборудования для газопламенной обработки металлов? Выберите правильный вариант ответа.",
        answers: [
          "Не разрешается установка на одной тележке специальной конструкции баллона с горючим газом и баллона с кислородом",
          "Допускается нагревание баллона со сжиженным газом прямыми солнечными лучами или другими источниками тепла",
          "Длина рукава, как правило, должна превышать 30 м. Он должен состоять не более чем из 3 отдельных кусков, соединенных между собой специальными двусторонними ниппелями",
          "При питании передвижного рабочего поста сжиженным газом от баллона допускается отбирать газ из баллона при снижении в нем рабочего давления ниже требуемого",
          "При работе пламя горелки (резака) должно быть направлено в сторону, противоположную источнику газоснабжения",
        ],
        correctAnswers: [
          "При работе пламя горелки (резака) должно быть направлено в сторону, противоположную источнику газоснабжения",
        ],
      },
      {
        code: "20304166",
        text: "Какое из перечисленных требований при эксплуатации взрывопожароопасных объектов указано неверно?",
        answers: [
          "В помещениях категории В проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией должна производиться в сроки, предусмотренные заводской инструкцией, но не реже 1 раза в месяц",
          "На каждой двери помещения категории A должны быть указаны категория помещения и класс взрывоопасной зоны",
          "Работы по ремонту электрооборудования в помещениях категории A должны выполняться после обесточивания электросети",
          "В помещениях категории A хранение обтирочных и смазочных материалов допускается в количестве, не превышающем суточной потребности, в металлических, плотно закрывающихся шкафах или ящиках",
          "Хранение в помещении ГРП обтирочных и других горючих материалов не допускается",
        ],
        correctAnswers: [
          "В помещениях категории В проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией должна производиться в сроки, предусмотренные заводской инструкцией, но не реже 1 раза в месяц",
        ],
      },
      {
        code: "20304167",
        text: "С какой периодичностью должен производиться текущий ремонт оборудования с разборкой регуляторов давления, предохранительных клапанов и фильтров ГРП и ГРУ, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания?",
        answers: [
          "Не менее 1 раза в 3 года, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания",
          "Не менее 1 раза в 2 года, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания",
          "Не менее 1 раза в год, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания",
          "Не менее 1 раза в квартал, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания",
        ],
        correctAnswers: [
          "Не менее 1 раза в год, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания",
        ],
      },
      {
        code: "20304168",
        text: "При каком давлении должна производиться настройка срабатывания предохранительно-запорных клапанов при их наличии в ГРП (ГРУ)?",
        answers: [
          "На 5% больше расчетного",
          "На 10% больше расчетного",
          "На 15% больше расчетного",
          "На 20% больше расчетного",
        ],
        correctAnswers: ["На 15% больше расчетного"],
      },
      {
        code: "20304169",
        text: "В каком случае допускается подача потребителям газа по обводной линии (байпасу) ГРП и ГРУ?",
        answers: [
          "Только на время ревизии оборудования",
          "Только на время ремонта оборудования",
          "В любом случае",
          "На время ревизии или ремонта оборудования",
        ],
        correctAnswers: ["На время ревизии или ремонта оборудования"],
      },
      {
        code: "20304170",
        text: "Какие виды работ должны выполняться не реже одного раза в 6 месяцев при эксплуатации ГРП и ГРУ?",
        answers: [
          "Осмотр технического состояния (обход)",
          "Проверка параметров срабатывания предохранительных запорных и сбросных клапанов",
          "Техническое обслуживание",
          "Капитальный ремонт при замене оборудования, средств измерений, ремонта систем отопления, вентиляции, освещения, конструкций здания на основании дефектных ведомостей",
        ],
        correctAnswers: ["Техническое обслуживание"],
      },
      {
        code: "20304171",
        text: "Какие из перечисленных видов работ не должны выполняться при техническом обслуживании технологического оборудования ГРП и ГРУ? Выберите 2 варианта ответа.",
        answers: [
          "Устранение утечек газа из разъемных соединений технических устройств",
          "Осмотр фильтра и (при необходимости) очистка фильтрующего элемента",
          "Смазка подвижных элементов запорной арматуры (без разборки)",
          "Замена изношенных деталей технических устройств",
          "Восстановление окраски шкафов пунктов редуцирования газа, ограждений, газопроводов обвязки и технических устройств",
        ],
        correctAnswers: [
          "Замена изношенных деталей технических устройств",
          "Восстановление окраски шкафов пунктов редуцирования газа, ограждений, газопроводов обвязки и технических устройств",
        ],
      },
      {
        code: "20304172",
        text: "В какие сроки должен проводиться капитальный ремонт ГРП и ГРУ?",
        answers: [
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в год",
          "Не реже 1 раза в 3 года",
          "В сроки, установленные изготовителем",
        ],
        correctAnswers: ["В сроки, установленные изготовителем"],
      },
      {
        code: "20304173",
        text: "Чем должна производиться продувка импульсных газовых проводок?",
        answers: ["Паром", "Сжатым воздухом", "Газом", "Всем перечисленным"],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20304174",
        text: "Какой должна быть объемная доля кислорода в воздухе производственных помещений производства ПРВ?",
        answers: [
          "Не менее 21% и не более 25%",
          "Не менее 20% и не более 23%",
          "Не менее 19% и не более 23%",
        ],
        correctAnswers: ["Не менее 19% и не более 23%"],
      },
      {
        code: "20304175",
        text: "Что необходимо устанавливать в производственных помещениях, где возможны воспламенение одежды или химические ожоги у работников?",
        answers: [
          "Ящики с песком",
          "Брезентовые покрывала",
          "Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
        correctAnswers: [
          "Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
      },
      {
        code: "20304176",
        text: "Какие требования установлены к инструментам и приспособлениям, используемым во взрывопожароопасных зонах и помещениях?",
        answers: [
          "Не должны давать искры при работе с ними",
          "Должны быть изготовлены из стали",
          "Должны быть изготовлены из чугуна",
        ],
        correctAnswers: ["Не должны давать искры при работе с ними"],
      },
      {
        code: "20304177",
        text: "После установления каких показателей температуры разрешается проводить ремонтные работы внутри нагретых технических устройств, а также низкотемпературных технических устройств?",
        answers: [
          "После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания",
          "После снижения температуры воздуха до 45 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания",
          "После снижения температуры воздуха до 50 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (30 °C) и проветривания",
        ],
        correctAnswers: [
          "После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания",
        ],
      },
      {
        code: "20304178",
        text: "При каких условиях допускается перенос сроков проведения капитальных ремонтов основного технологического оборудования?",
        answers: [
          "При наличии положительного заключения экспертного обследования",
          "При наличии решения главного инженера предприятия",
          "При наличии разрешения Ростехнадзора",
        ],
        correctAnswers: [
          "При наличии положительного заключения экспертного обследования",
        ],
      },
      {
        code: "20304179",
        text: "Каким образом должен проводиться отогрев трубопроводной арматуры?",
        answers: [
          "Паяльной лампой",
          "Открытым пламенем",
          "Горячим воздухом, паром или горячей водой",
        ],
        correctAnswers: ["Горячим воздухом, паром или горячей водой"],
      },
      {
        code: "20304180",
        text: "С какой периодичностью следует очищать фильтры?",
        answers: [
          "В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
          "В первый раз - через 15 суток после ввода в эксплуатацию, далее - через каждые 3 месяца",
          "В первый раз - через 20 суток после ввода в эксплуатацию, далее - через каждые 9 месяцев",
        ],
        correctAnswers: [
          "В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
        ],
      },
      {
        code: "20304181",
        text: "Как часто должен производиться осмотр трубопроводов кислорода?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в квартал",
          "Не реже 1 раза в полгода",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20304182",
        text: "Как часто должна производиться проверка конденсатоотводчиков и пароспутников, обогревающих трубопроводы кислорода в зимнее время?",
        answers: ["Ежедневно", "Ежеквартально", "Еженедельно"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "20304183",
        text: "Как часто должна проводиться проверка правильности показаний приборов, перечень которых утверждается руководителем производства?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
        ],
        correctAnswers: ["Не реже 1 раза в 3 месяца"],
      },
      {
        code: "20304184",
        text: "Какой установлен порядок хранения обтирочных материалов?",
        answers: [
          "В чистых металлических ящиках с крышками",
          "В специальных пластиковых контейнерах с крышками",
          "В контейнерах, расположенных в специально выделенном помещении",
        ],
        correctAnswers: ["В чистых металлических ящиках с крышками"],
      },
      {
        code: "20304185",
        text: "Из каких материалов не могут быть изготовлены разделители (заглушки) или другие изделия, помещаемые внутрь кислородопровода при его обезжиривании?",
        answers: [
          "Из поролона или других органических материалов",
          "Из алюминия или магния",
          "Из меди или титана",
        ],
        correctAnswers: ["Из поролона или других органических материалов"],
      },
      {
        code: "20304186",
        text: "Из каких сплавов металлов запрещается установка арматуры на кислородопроводах?",
        answers: ["Из стали", "Из сплавов титана", "Из чугуна"],
        correctAnswers: ["Из сплавов титана"],
      },
      {
        code: "20304187",
        text: "Чем производится отогрев смерзшейся изоляции для обеспечения доступа к адсорберам?",
        answers: [
          "Непосредственно сухим подогретым воздухом",
          "Непосредственно подогретой водой",
          "Открытым пламенем",
        ],
        correctAnswers: ["Непосредственно сухим подогретым воздухом"],
      },
      {
        code: "20304188",
        text: "Какие из перечисленных ремонтных работ допускается производить одновременно при ремонте арматуры, расположенной на теплом и холодном концах регенераторов в период остановки ВРУ?",
        answers: [
          "Ремонтные работы внутри трубопроводов и арматуры теплого и холодного концов регенераторов в период остановки блоков без слива жидкости",
          "Ремонтные работы трубопроводов и арматуры теплого и холодного концов регенераторов",
          'Ремонтные работы трубопроводов и арматуры теплого или холодного конца регенераторов и системы "приказного" воздуха переключения регенераторов, или механизма переключения',
          "Ремонтные работы принудительных клапанов переключения регенераторов и трехходовых заслонок после регенераторов",
        ],
        correctAnswers: [
          "Ремонтные работы внутри трубопроводов и арматуры теплого и холодного концов регенераторов в период остановки блоков без слива жидкости",
        ],
      },
      {
        code: "20304189",
        text: "С каким интервалом должна проверяться объемная доля кислорода в месте проведения ремонтных работ во время нахождения персонала внутри трубопроводов, опорных обечаек регенераторов, в отсеке обратноповоротных клапанов или арматуры?",
        answers: [
          "С интервалом не менее чем 10 минут",
          "С интервалом не менее чем 15 минут",
          "С интервалом не менее чем 20 минут",
          "С интервалом не менее чем 30 минут",
        ],
        correctAnswers: ["С интервалом не менее чем 10 минут"],
      },
      {
        code: "20304190",
        text: "Какие действия необходимо осуществить перед ремонтом клапанов (трубопроводов) теплого и холодного концов регенераторов в период остановки ВРУ? Выберите правильный вариант ответа.",
        answers: [
          "Систему автоматического управления регенераторов следует перевести на ручной режим",
          "С помощью дистанционного управления закрыть или открыть соответствующие клапаны",
          'Установить предупреждающий знак безопасности "Не включать, работают люди!"',
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20304191",
        text: "При каком условии ВРУ должна подвергаться обезжириванию?",
        answers: [
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами",
          "При достижении концентрации масла в жидком кислороде 0,30 мг/дм и выше, подтвержденной четырьмя последовательно проведенными анализами",
          "При достижении концентрации масла в жидком кислороде 0,20 мг/дм и выше, подтвержденной тремя последовательно проведенными анализами",
        ],
        correctAnswers: [
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами",
        ],
      },
      {
        code: "20304192",
        text: "Какие установлены сроки обезжиривания ВРУ, работающих по схеме высокого и среднего давлений с блоками комплексной очистки на цеолитах и турбодетандерами, а также по схеме низкого давления?",
        answers: [
          "1 раз в месяц",
          "1 раз в полгода",
          "1 раз в год",
          "Не регламентируются",
        ],
        correctAnswers: ["Не регламентируются"],
      },
      {
        code: "20304193",
        text: "Что из перечисленного не подлежит обезжириванию в процессе эксплуатации ВРУ? Выберите правильный вариант ответа.",
        answers: [
          "Средства измерения, работающие при давлении до 0,6 МПа, конструкция которых позволяет их устанавливать в положении, обеспечивающем свободное стекание масла с внутренних поверхностей, соприкасающихся с кислородом",
          "Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20304194",
        text: "В каком случае арматура, предназначенная для работы с кислородом, не подлежит обезжириванию перед монтажом?",
        answers: [
          "Только если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами)",
          "Только если не нарушена упаковка",
          "Если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка",
        ],
        correctAnswers: [
          "Если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка",
        ],
      },
      {
        code: "20304195",
        text: "Какое из перечисленных требований при необходимости проведения обезжиривания технических устройств и трубопроводов ПРВ растворителями указано неверно?",
        answers: [
          "Доступ в помещение, где хранятся растворители, разрешается только лицам, допущенным к работе с ними",
          "Тару из-под растворителей необходимо плотно закрывать и хранить только в предназначенном для этого помещении или на открытом воздухе",
          "Проливы растворителей на пол не допускаются; случайно пролитый растворитель должен быть немедленно убран с помощью сухих материалов",
          "Перелив растворителей из одного сосуда в другой допускается любым удобным способом при наличии у работающих СИЗ",
        ],
        correctAnswers: [
          "Перелив растворителей из одного сосуда в другой допускается любым удобным способом при наличии у работающих СИЗ",
        ],
      },
      {
        code: "20304196",
        text: "До какой максимальной температуры допускается нагрев поверхностей в помещениях, где производится обезжиривание кислородного оборудования пожаровзрывоопасными растворителями?",
        answers: ["80 °C", "100 °C", "120 °C", "150 °C"],
        correctAnswers: ["120 °C"],
      },
      {
        code: "20304197",
        text: "До какой температуры должен отогреваться сосуд, бывший в эксплуатации, перед проведением работ по обезжириванию способом протирки и чем он должен продуваться?",
        answers: [
          "Не ниже 20 °C и продуваться воздухом",
          "Не выше 20 °C и продуваться инертным газом",
          "Не ниже 12 °C и продуваться паром",
          "Не выше 30 °C и продуваться продувочным газом. При этом водород в продувочном газе должен отсутствовать, а содержание кислорода в продувочном газе не должно превышать 1% (об.)",
        ],
        correctAnswers: ["Не ниже 20 °C и продуваться воздухом"],
      },
      {
        code: "20304198",
        text: "Какое из перечисленных требований к потреблению газообразного кислорода и других продуктов разделения воздуха указано неверно?",
        answers: [
          "Использование ПРВ по каждому производству, участку или объекту должно осуществляться по технологическим инструкциям",
          "На кислородопроводах не допускается установка арматуры из сплавов на основе титана",
          "Не допускается устанавливать и применять кислородное оборудование возле замасляного или прожированного оборудования, территории",
          "Кислородная арматура для присоединения рукавов должна быть размещена в металлическом шкафу с отверстиями для проветривания",
          "Все перечисленное верно",
        ],
        correctAnswers: ["Все перечисленное верно"],
      },
      {
        code: "20304199",
        text: "Какие работы производятся на ОПО горно-металлургических производств? Выберите правильный вариант ответа.",
        answers: [
          "Только работы, связанные с аварийными ситуациями",
          "Только работы повышенной опасности (сложные, разовые, уникальные)",
          "Только постоянные работы, выполняемые в соответствии с документированной процедурой (инструкцией, картой, регламентом), принятой в организации",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20304200",
        text: "Какие из перечисленных требований к акту-допуску подрядных организаций на опасный производственный объект горно-металлургической промышленности указаны неверно?",
        answers: [
          "На один объект, площадку, территорию оформляется один акт-допуск",
          "Акт-допуск должен оформляться письменно",
          "Не допускается оформлять акты-допуски, характеризующиеся одинаковыми координатами рабочих зон",
          "Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)",
        ],
        correctAnswers: [
          "Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)",
        ],
      },
      {
        code: "20304201",
        text: "Какие действия предпринимаются при необходимости ведения работ после истечения срока действия акта-допуска подрядных организаций на опасный производственный объект горно-металлургической промышленности?",
        answers: [
          "Работы продолжаются по текущему акту-допуску",
          "Составляется новый акт-допуск на следующий срок",
          "Продлевается срок действия текущего акта-допуска",
          "Работы продолжаются без акта-допуска",
        ],
        correctAnswers: ["Составляется новый акт-допуск на следующий срок"],
      },
      {
        code: "20304202",
        text: "В каком количестве экземпляров составляется акт-допуск подрядных организаций на опасный производственный объект горно-металлургической промышленности?",
        answers: [
          "В одном экземпляре",
          "В двух экземплярах",
          "В трех экземплярах",
          "В четырех экземплярах",
        ],
        correctAnswers: ["В двух экземплярах"],
      },
      {
        code: "20304203",
        text: "С кем согласовывается перечень работ повышенной опасности на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля",
          "С главным инженером организации",
          "С Ростехнадзором",
          "С МЧС России",
        ],
        correctAnswers: [
          "Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля",
        ],
      },
      {
        code: "20304204",
        text: "Кем могут проводиться работы повышенной опасности на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "Только работниками эксплуатирующей организации",
          "Только работниками подрядных организаций",
          "Как работниками эксплуатирующей организации, так и работниками подрядных организаций",
        ],
        correctAnswers: [
          "Как работниками эксплуатирующей организации, так и работниками подрядных организаций",
        ],
      },
      {
        code: "20304205",
        text: "В течение какого времени хранится журнал регистрации нарядов-допусков со дня внесения последней записи?",
        answers: [
          "В течение 1 месяца",
          "В течение 3 месяцев",
          "В течение 6 месяцев",
          "В течение 1 года",
        ],
        correctAnswers: ["В течение 6 месяцев"],
      },
      {
        code: "20304206",
        text: "На какой максимальный срок выдается наряд-допуск на работы повышенной опасности?",
        answers: [
          "На 15 календарных дней",
          "На 20 рабочих дней",
          "На 30 календарных дней",
          "На 45 календарных дней",
        ],
        correctAnswers: ["На 30 календарных дней"],
      },
      {
        code: "20304207",
        text: "Кто определяет (назначает) работников, выполняющих мероприятия по безопасности труда, указанных в наряде-допуске (ответственных исполнителей)?",
        answers: [
          "Ответственный за осуществление производственного контроля",
          "Руководитель работ",
          "Допускающий к работе",
          "Выдающий наряд-допуск",
          "Главный инженер организации",
        ],
        correctAnswers: ["Выдающий наряд-допуск"],
      },
      {
        code: "20304208",
        text: "Какие из перечисленных требований к наряду-допуску на работы повышенной опасности указаны неверно? Выберите два правильных ответа.",
        answers: [
          "При допуске бригады к работе допускающий к работе вручает первый экземпляр наряда-допуска производителю работ, а второй экземпляр наряда-допуска хранится вместе с нарядами, действующими на этот момент времени",
          "После окончания работы наряд-допуск остается у производителя (руководителя) работ",
          "При перерыве в работе в течение одной смены (обеденный перерыв, перерыв по условиям производства работ) наряд-допуск остается у производителя (руководителя) работ",
          "Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ",
        ],
        correctAnswers: [
          "После окончания работы наряд-допуск остается у производителя (руководителя) работ",
          "Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ",
        ],
      },
      {
        code: "20304209",
        text: "Какое количество производителей (руководителей) работ назначается на все время действия наряда-допуска на работы повышенной опасности при производстве работ в несколько смен?",
        answers: [
          "Количество производителей (руководителей) работ определяет выдающий наряд-допуск",
          "Один производитель (руководитель) работ",
          "Несколько производителей (руководителей) работ в соответствии с количеством смен",
          "Количество производителей (руководителей) работ определяет допускающий к работе",
        ],
        correctAnswers: [
          "Несколько производителей (руководителей) работ в соответствии с количеством смен",
        ],
      },
      {
        code: "20304210",
        text: "В течение какого времени хранятся закрытые (подписанные) наряды-допуски на работы повышенной опасности?",
        answers: [
          "В течение 1 месяца",
          "В течение 3 месяцев",
          "В течение 6 месяцев",
          "В течение года",
        ],
        correctAnswers: ["В течение 1 месяца"],
      },
      {
        code: "20304211",
        text: "Какие действия должны быть выполнены при утрате наряда-допуска на работы повышенной опасности?",
        answers: [
          "Должна быть внесена запись в журнал регистрации нарядов-допусков на работы повышенной опасности, затем работы могут быть продолжены",
          "Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново",
          "Решение о продолжении или прекращении работы определяет выдающий наряд-допуск",
          "Работы должны быть продолжены. Оформление нового наряда-допуска не требуется",
        ],
        correctAnswers: [
          "Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново",
        ],
      },
      {
        code: "20304212",
        text: "В каких из перечисленных случаях допускается продолжать работы, проводимые по наряду-допуску, на опасных производственных объектах?",
        answers: [
          "Если характер и объемы работ изменены в такой степени, что требуется изменение схемы отключения технических устройств и порядка выполнения работ",
          "Если изменился состав бригады",
          "Если обнаружено несоответствие фактического состояния производства работ требованиям безопасности и охраны труда",
          "Если выявлено нарушение условий отключения технических устройств",
        ],
        correctAnswers: ["Если изменился состав бригады"],
      },
      {
        code: "20304213",
        text: "На какие группы подразделяются газоопасные места на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "На I, II, III и IV группы газоопасности",
          "На I и II группы газоопасности",
          "На I, II и III группы газоопасности",
        ],
        correctAnswers: ["На I и II группы газоопасности"],
      },
      {
        code: "20304214",
        text: "С кем согласовывается инструкция, определяющая порядок подготовки и безопасного проведения газоопасных работ на опасных производственных объектах горно-металлургической промышленности? Выберите два правильных ответа.",
        answers: [
          "Со службой производственного контроля",
          "С главным инженером организации",
          "С ГСС (газоспасательной службой)",
          "С Ростехнадзором",
        ],
        correctAnswers: [
          "Со службой производственного контроля",
          "С ГСС (газоспасательной службой)",
        ],
      },
      {
        code: "20304215",
        text: "Каким составом должны выполняться газоопасные работы I и II группы газоопасности с образованием взрывопожароопасных смесей?",
        answers: [
          "Не более двух человек",
          "Не менее двух человек под наблюдением газоспасателя или члена ДГСД",
          "Один человек под наблюдением газоспасателя или члена ДГСД",
          "Только один человек",
        ],
        correctAnswers: [
          "Не менее двух человек под наблюдением газоспасателя или члена ДГСД",
        ],
      },
      {
        code: "20304216",
        text: "С кем согласовывается план организации и проведения газоопасной работы?",
        answers: [
          "Только с ответственным представителем подразделения или организации (главным энергетиком)",
          "Только со службой производственного контроля",
          "Только с начальником газоспасательной службы",
          "Со всеми перечисленными",
        ],
        correctAnswers: ["Со всеми перечисленными"],
      },
      {
        code: "20304217",
        text: "Какой стаж работы в газовом хозяйстве должны иметь лица, назначаемые ответственными руководителями и ответственными исполнителями газоопасных работ?",
        answers: [
          "Не менее 5 лет",
          "Не менее 3 лет",
          "Не менее 1 года",
          "Не менее 6 месяцев",
        ],
        correctAnswers: ["Не менее 1 года"],
      },
      {
        code: "20304218",
        text: "Какое из перечисленных требований к применению жетон-бирочной системы указано неверно?",
        answers: [
          'Ответственный специалист, разобравший схему, должен принимать меры, исключающие ошибочное или самопроизвольное включение пусковых устройств, на пусковых устройствах вывешивать предупредительные плакаты "Не включать! Работают люди", проверять отсутствие энергетического потенциала',
          "Жетон-бирочная система должна применяться при выводе технического устройства на продолжительную остановку",
          "Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями",
          "Жетон-бирки после разборки электрической схемы ТУ должны передаваться от технологического персонала ремонтному персоналу с отметкой всех этапов в журнале выдачи и приема жетон-бирок",
        ],
        correctAnswers: [
          "Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями",
        ],
      },
    ],
    20305: [
      {
        code: "20305000",
        text: "Что должно быть разработано для обеспечения безопасности при освоении новых производств, технологических процессов и технических устройств?",
        answers: [
          "Инструкции по эксплуатации",
          "Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования",
          "План производства работ",
        ],
        correctAnswers: [
          "Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования",
        ],
      },
      {
        code: "20305001",
        text: "При каких условиях запрещается эксплуатация плавильных агрегатов?",
        answers: [
          "При незначительном падении давления воды в системе водоохлаждения",
          "При разгерметизации системы водяного охлаждения этих агрегатов",
          "При незначительном повышении давления воды в системе охлаждения",
        ],
        correctAnswers: [
          "При разгерметизации системы водяного охлаждения этих агрегатов",
        ],
      },
      {
        code: "20305002",
        text: "От чего должны быть защищены составные части производственного оборудования, в том числе энергетические трубопроводы, рукава подачи природного газа, кислорода, мазута, воздуха, масла, воды, троллеи для питания электрического оборудования тележек, перевозящих расплавы металлов, и электрические кабели?",
        answers: [
          "Только от возможного попадания на них расплава",
          "Только от механических воздействий",
          "Только от тепловых воздействий",
          "От всего перечисленного",
        ],
        correctAnswers: ["От всего перечисленного"],
      },
      {
        code: "20305003",
        text: "Что не допускается к загрузке в агрегаты, содержащие расплавленный металл или шлак?",
        answers: [
          "Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции",
          "Загрузка просушенных и прокаленных материалов",
          "Загрузка шихты, не прошедшей пиротехнического контроля",
        ],
        correctAnswers: [
          "Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции",
        ],
      },
      {
        code: "20305004",
        text: "Какие требования установлены к инструментам, приспособлениям и оснастке, используемым для ведения технологии или обслуживания оборудования?",
        answers: [
          "Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними",
          "Должны быть предназначены для выполнения любых работ",
          "Выполнены из цветных металлов",
        ],
        correctAnswers: [
          "Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними",
        ],
      },
      {
        code: "20305005",
        text: "Какие требования установлены к загружаемым в печи руде, ферросплавам и другим материалам?",
        answers: [
          "Должны быть предварительно увлажнены и спрессованы",
          "Должны быть выдержаны в помещении не менее суток",
          "Должны быть предварительно просушены или прокалены",
        ],
        correctAnswers: ["Должны быть предварительно просушены или прокалены"],
      },
      {
        code: "20305006",
        text: "Какие требования установлены к фурмам для продувки жидкого металла газами?",
        answers: [
          "Требования не регламентированы",
          "Должны быть охлаждены и смазаны перед установкой",
          "Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями",
        ],
        correctAnswers: [
          "Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями",
        ],
      },
      {
        code: "20305007",
        text: "Какие требования установлены к установке расходных баков с мазутом?",
        answers: [
          "Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением",
          "Должны быть установлены на расстоянии не менее 15 м от печей",
          "Должны быть установлены на расстоянии не менее 10 м от печей и должны быть защищены завесой",
        ],
        correctAnswers: [
          "Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением",
        ],
      },
      {
        code: "20305008",
        text: "Какое требование к устройству внутренних производственных помещений, находящихся на производственной площадке, включающей технологию с расплавами металлов, имеющей опасность аварии или травмирования работников, указано неверно?",
        answers: [
          "Окна пультов управления, в которые возможно попадание брызг расплава, должны оборудоваться в соответствии с проектом",
          "Помещения должны иметь защиту от воздействия теплового излучения",
          "Помещения должны иметь не менее двух выходов, расположенных с учетом аварийной эвакуации работников, с дверями, открывающимися наружу, и не иметь внутренних запоров",
          "Помещения пультов управления должны иметь вытяжную вентиляцию",
        ],
        correctAnswers: [
          "Помещения пультов управления должны иметь вытяжную вентиляцию",
        ],
      },
      {
        code: "20305009",
        text: "Как должны быть расположены пульты управления агрегатами?",
        answers: [
          "В непосредственной близости от агрегатов",
          "В отдельном помещении",
          "В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке",
        ],
        correctAnswers: [
          "В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке",
        ],
      },
      {
        code: "20305010",
        text: "Что должны иметь закрома и ямы?",
        answers: [
          "Должны иметь по периметру пешеходные проходы шириной 0,75 м",
          "Должны иметь по периметру пешеходные трапы шириной 1,5 м",
          "Со всех сторон должны иметь ограждение",
        ],
        correctAnswers: ["Со всех сторон должны иметь ограждение"],
      },
      {
        code: "20305011",
        text: "Что должно быть предусмотрено при проектировании конвейеров для подачи материалов? Выберите правильный вариант ответа.",
        answers: [
          "Только установленное ограждение приводных, натяжных и отклоняющих барабанов, натяжных устройств, канатов и блоков натяжных устройств, ременных, червячных, муфтовых и других передач",
          "Только установка предохраняющих устройств, отключающих приводы при перегрузке",
          "Только исключение падения транспортируемых материалов",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20305012",
        text: "Как должна устраняться пробуксовка ленты конвейера?",
        answers: [
          "Должна устраняться при помощи ручной регулировки",
          "Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты",
          "Должна устраняться увеличением трения между тянущим барабаном и лентой с использованием специальных материалов",
        ],
        correctAnswers: [
          "Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты",
        ],
      },
      {
        code: "20305013",
        text: "Что должно быть исключено в коммуникациях системы транспортирования взрывопожароопасных веществ и материалов?",
        answers: [
          "Попадание воздушной среды",
          "Образование взрывоопасной среды",
          "Возможность разгерметизации системы",
        ],
        correctAnswers: ["Образование взрывоопасной среды"],
      },
      {
        code: "20305014",
        text: "На что должна быть проверена система пневмотранспорта перед вводом в эксплуатацию?",
        answers: [
          "Должна подвергаться гидроиспытаниям",
          "На плотность под рабочим давлением",
          "Должна подвергаться внешнему осмотру",
        ],
        correctAnswers: ["На плотность под рабочим давлением"],
      },
      {
        code: "20305015",
        text: "Чем должны быть оборудованы шлаковозы?",
        answers: [
          "Механизмами кантования (поворота) чаши с пневматическим приводом",
          "Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением",
          "Механизмами кантования (поворота) чаши с гидравлическим приводом",
        ],
        correctAnswers: [
          "Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением",
        ],
      },
      {
        code: "20305016",
        text: "Что должно иметься на шлаковом отвале?",
        answers: [
          "Двусторонняя громкоговорящая связь с диспетчерской службой",
          "Телефонная или радиосвязь с диспетчерской службой производства",
          "Мобильная связь с диспетчерской службой предприятия",
        ],
        correctAnswers: [
          "Телефонная или радиосвязь с диспетчерской службой производства",
        ],
      },
      {
        code: "20305017",
        text: "Какие шпалы должны применяться на железнодорожных путях шлакового отвала?",
        answers: [
          "Деревянные шпалы",
          "Деревянные шпалы, пропитанные креозотом",
          "Огнестойкие шпалы",
        ],
        correctAnswers: ["Огнестойкие шпалы"],
      },
      {
        code: "20305018",
        text: "Какие требования установлены к кабине завалочной машины?",
        answers: [
          "Должна быть выполнена герметично",
          "Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака",
          "Конструкция кабины завалочной машины должна обеспечивать устойчивость при выполнении операций",
        ],
        correctAnswers: [
          "Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака",
        ],
      },
      {
        code: "20305019",
        text: "Чему подлежат производственные емкости (ковши, шлаковые чаши, совки, кюбели, тара, корзины) после изготовления или ремонта?",
        answers: [
          "Механическим испытаниям",
          "Визуальному осмотру",
          "Проверке состояния",
        ],
        correctAnswers: ["Проверке состояния"],
      },
      {
        code: "20305020",
        text: "Чем должны быть оборудованы места пересечения железнодорожных путей для подачи составов ковшей с жидким чугуном с путями для подачи шихтовых материалов?",
        answers: [
          "Должны быть оборудованы шлагбаумами",
          "Должны быть оборудованы светофорами",
          "Должны быть оборудованы автоматической сигнализацией",
        ],
        correctAnswers: [
          "Должны быть оборудованы автоматической сигнализацией",
        ],
      },
      {
        code: "20305021",
        text: "Какие требования установлены к месту расстановки ковшей, предназначенных для расплава металла?",
        answers: [
          "Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства",
          "Должны расставляться на любых свободных площадках цеха",
          "Должны расставляться в зоне работы крана в цехе",
        ],
        correctAnswers: [
          "Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства",
        ],
      },
      {
        code: "20305022",
        text: "При наличии каких дефектов ковшей их эксплуатация не допускается?",
        answers: [
          "Только при наличии поврежденной футеровки",
          "Только при наличии качки цапф в теле ковша",
          "Только при наличии раковин, трещин в стенках и в местах крепления цапф",
          "При наличии всех перечисленных дефектов",
        ],
        correctAnswers: ["При наличии всех перечисленных дефектов"],
      },
      {
        code: "20305023",
        text: "Какие требования установлены к процессу сливания расплава металла из ковшей и миксеров? Выберите правильный вариант ответа.",
        answers: [
          "Ось сливного отверстия ковша, установленного под слив, должна совпадать с осью заливочного ковша",
          "Пол рабочей площадки в местах установки ковшей должен быть сухим",
          "Сливание расплава металла из ковшей и миксеров должно быть механизировано",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20305024",
        text: "После чего разрешается производить слив расплава в ковши, вышедшие из ремонта?",
        answers: [
          "Только после тщательного их просушивания и разогрева",
          "Только после охлаждения и увлажнения",
          "Только после смазки негорючим материалом",
        ],
        correctAnswers: [
          "Только после тщательного их просушивания и разогрева",
        ],
      },
      {
        code: "20305025",
        text: "В какой зоне не допускается нахождение людей?",
        answers: [
          "На расстоянии 15 метров от зоны работы магнитного крана в случае, если кран работает внутри производственного здания",
          "В зоне погрузки грейферными или магнитными кранами",
          "На расстоянии 15 метров от зоны работы грейферного крана в случае, если кран работает внутри производственного здания",
          "Во всех перечисленных зонах",
        ],
        correctAnswers: ["В зоне погрузки грейферными или магнитными кранами"],
      },
      {
        code: "20305026",
        text: "С какой периодичностью должны проверяться на работоспособность вентиляционные системы и состояние ванн травления?",
        answers: ["Ежедневно", "Еженедельно", "Ежемесячно", "Ежеквартально"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "20305027",
        text: "Какие требования к производственным помещениям, оборудованию участков приготовления травильных растворов и травления металла указаны неверно? Выберите правильный вариант ответа.",
        answers: [
          "Не допускается уборка, вызывающая распыление вредных веществ",
          "Периодичность проведения уборки одинакова для всех помещений и не зависит от условий их эксплуатации",
          "После работы у ванн травления с химически опасными веществами, используемыми в виде добавок для технологических растворов, вещества должны быть удалены из рабочей зоны на склад",
          "Вентиляционные системы, состояние ванн травления ежедневно должны проверяться на работоспособность",
        ],
        correctAnswers: [
          "Периодичность проведения уборки одинакова для всех помещений и не зависит от условий их эксплуатации",
        ],
      },
      {
        code: "20305028",
        text: "Какие требования установлены к системам освещения во взрывоопасных помещениях?",
        answers: [
          "Должны предусматриваться системы освещения во влагостойком исполнении",
          "Должны предусматриваться системы освещения во взрывобезопасном исполнении",
          "Должны предусматриваться системы освещения в безопасном исполнении",
        ],
        correctAnswers: [
          "Должны предусматриваться системы освещения во взрывобезопасном исполнении",
        ],
      },
      {
        code: "20305029",
        text: "Что должна обеспечить система светозвуковой сигнализации?",
        answers: [
          "Только оповещение эксплуатационного персонала о начале и окончании смены",
          "Только оповещение эксплуатационного персонала о приближении технологического транспорта",
          "Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств",
        ],
        correctAnswers: [
          "Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств",
        ],
      },
      {
        code: "20305030",
        text: "Какие требования установлены к расположению КИПиА?",
        answers: [
          "Приборы должны устанавливаться в непосредственной близости к оборудованию",
          "Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования",
          "Приборы должны устанавливаться на расстоянии не менее 5 м от оборудования",
        ],
        correctAnswers: [
          "Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования",
        ],
      },
      {
        code: "20305031",
        text: "Какие требования установлены к проверке и испытанию приборов во взрывопожароопасных и пожароопасных помещениях?",
        answers: [
          "Должны производиться непосредственно во взрывопожароопасных и пожароопасных помещениях",
          "Должны производиться в условиях, исключающих искрообразование",
          "Должны осуществляться только в специально выделенном для этого помещении",
        ],
        correctAnswers: [
          "Должны производиться в условиях, исключающих искрообразование",
        ],
      },
      {
        code: "20305032",
        text: "Какие требования предъявляются к устройству и эксплуатации печей для обжига шихтовых материалов и концентратов?",
        answers: [
          "Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 5%",
          "Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 10%",
          "Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции",
        ],
        correctAnswers: [
          "Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции",
        ],
      },
      {
        code: "20305033",
        text: "Какие требования установлены к хранению стержней, шаров, футеровки, запасных деталей и приспособлений?",
        answers: [
          "Должно быть предусмотрено на свободных площадках, обеспечивающих свободный проход",
          "Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре",
          "Должно быть предусмотрено в закрытом помещении",
        ],
        correctAnswers: [
          "Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре",
        ],
      },
      {
        code: "20305034",
        text: "Какие требования установлены к ведению взрывных работ, хранению, транспортированию взрывчатых материалов и эксплуатации броневых ям?",
        answers: [
          "Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах",
          "Работы должны производиться только после проверки материалов на радиацию",
          "Работы могут производиться взрывниками в процессе эксплуатации оборудования цеха",
        ],
        correctAnswers: [
          "Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах",
        ],
      },
      {
        code: "20305035",
        text: "Какие помещения должны быть соединены крытыми коридорами и галереями для транспорта материалов, расплавов и передвижения людей по установленным маршрутам?",
        answers: [
          "Только блоки вспомогательных отделений",
          "Только бытовые помещения",
          "Только электролизные и литейные корпуса",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20305036",
        text: "После чего должна выполняться заливка металла в кристаллизатор машины полунепрерывного литья?",
        answers: [
          "Только после пуска водного охлаждения и отсутствия влаги на поддоне кристаллизатора и литейном столе",
          "Только после включения системы вентиляции",
          "Только после проведения осмотра",
          "Только после разрешения ответственного за производство работ",
        ],
        correctAnswers: [
          "Только после пуска водного охлаждения и отсутствия влаги на поддоне кристаллизатора и литейном столе",
        ],
      },
      {
        code: "20305037",
        text: "Чем должно производиться тушение загоревшихся легких металлов и их сплавов? Выберите 2 варианта ответа.",
        answers: [
          "Пеной",
          "Флюсом",
          "Водой",
          "Углекислым газом",
          "Обезвоженным карналлитом",
        ],
        correctAnswers: ["Флюсом", "Обезвоженным карналлитом"],
      },
      {
        code: "20305038",
        text: "В какой таре должно производиться транспортирование бихромата калия к месту приготовления рабочего раствора?",
        answers: [
          "В закрытой таре",
          "В открытой таре",
          "В опломбированной таре",
          "Не регламентируется",
        ],
        correctAnswers: ["В закрытой таре"],
      },
      {
        code: "20305039",
        text: "Где указывается высота штабелей, в которые укладываются остывшие рулоны готовой продукции, при бесслитковом производстве катанки и ленты?",
        answers: [
          "В производственной инструкции",
          "В инструкции по охране труда",
          "В проектной документации",
          "В технологической карте",
        ],
        correctAnswers: ["В производственной инструкции"],
      },
      {
        code: "20305040",
        text: "Какие требования установлены к состоянию вакуум-ковшей, тиглей, коробов, кристаллизаторов, изложниц и других емкостей для расплава перед заливкой в них расплава в электролитическом производстве магния?",
        answers: [
          "Должны быть очищены от мусора",
          "Должны быть увлажнены",
          "Должны быть охлаждены",
        ],
        correctAnswers: ["Должны быть очищены от мусора"],
      },
      {
        code: "20305041",
        text: "Какому документу должны соответствовать электропечи для плавки шихтовых материалов, благородных металлов и сплавов?",
        answers: [
          "Проекту",
          "Паспорту на техническое устройство",
          "Эксплуатационной документации",
          "Технологической карте",
        ],
        correctAnswers: ["Проекту"],
      },
      {
        code: "20305042",
        text: "Под каким давлением водоохлаждаемые элементы плавильных печей перед монтажом должны испытываться на герметичность при производстве благородных металлов, сплавов и полуфабрикатов?",
        answers: [
          "В 1,5 раза больше рабочего давления воды",
          "В 0,7 раза больше рабочего давления воды",
          "В 2,0 раза больше рабочего давления воды",
          "В 2,5 раза больше рабочего давления воды",
        ],
        correctAnswers: ["В 1,5 раза больше рабочего давления воды"],
      },
      {
        code: "20305043",
        text: "В каком случае допускается загрузка шихты и проведение технологических работ с применением неизолированного металлического инструмента при производстве благородных металлов, сплавов и полуфабрикатов?",
        answers: [
          "При отключенной электропечи",
          "При работающей электропечи в присутствии наблюдающего",
          "Ни в каком случае",
          "При применении необходимых СИЗ",
        ],
        correctAnswers: ["При отключенной электропечи"],
      },
      {
        code: "20305044",
        text: "Какое испытательное напряжение должна выдержать в течение 1 минуты изоляция индуктора относительно корпуса электропечи при номинальном напряжении до 1000 В при производстве благородных металлов, сплавов и полуфабрикатов?",
        answers: [
          "В 2 раза больше номинального",
          "В 2,5 раза больше номинального",
          "В 1,2 раза больше номинального",
          "В 1,5 раза больше номинального",
        ],
        correctAnswers: ["В 2 раза больше номинального"],
      },
      {
        code: "20305045",
        text: "Какое испытательное напряжение должна выдержать в течение 1 минуты изоляция индуктора относительно корпуса электропечи при номинальном напряжении более 1000 В при производстве благородных металлов, сплавов и полуфабрикатов?",
        answers: [
          "В 1,3 раза больше номинального",
          "В 1,5 раза больше номинального",
          "В 1,8 раза больше номинального",
          "В 2 раза больше номинального",
        ],
        correctAnswers: ["В 1,3 раза больше номинального"],
      },
      {
        code: "20305046",
        text: "Какой персонал выявляет и устраняет причины отключения при автоматическом отключении высокочастотной установки в процессе работы при производстве благородных металлов, сплавов и полуфабрикатов?",
        answers: [
          "Электротехнический персонал",
          "Неэлектротехнический персонал",
          "Электротехнологический персонал",
          "Весь перечисленный персонал",
        ],
        correctAnswers: ["Электротехнический персонал"],
      },
      {
        code: "20305047",
        text: "Чем должна быть продута камера печи при обнаружении утечки водорода из камеры или при перегорании спирали при производстве благородных металлов, сплавов и полуфабрикатов? Выберите 2 варианта ответа.",
        answers: ["Азотом", "Инертным газом", "Воздухом", "Кислородом"],
        correctAnswers: ["Азотом", "Инертным газом"],
      },
      {
        code: "20305048",
        text: "Под каким давлением рубашки водоохлаждаемых изложниц и кристаллизаторов перед пуском их в эксплуатацию и после ремонта должны подвергаться гидравлическим испытаниям при производстве благородных металлов, сплавов и полуфабрикатов?",
        answers: [
          "В 1,5 раза выше максимального рабочего давления",
          "В 2,2 раза выше максимального рабочего давления",
          "В 1,2 раза выше максимального рабочего давления",
          "В 1,8 раза выше максимального рабочего давления",
        ],
        correctAnswers: ["В 1,5 раза выше максимального рабочего давления"],
      },
      {
        code: "20305049",
        text: "Чем должны быть оборудованы помещения, в которых размещены печи для отжига полуфабрикатов в среде водорода, при производстве благородных металлов, сплавов и полуфабрикатов?",
        answers: [
          "Только аварийной вытяжной вентиляцией",
          "Только аварийной сигнализацией",
          "Только автоматическими газоанализаторами",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20305050",
        text: "Какое должно быть содержание водорода, подаваемого в установки, при производстве благородных металлов, сплавов и полуфабрикатов?",
        answers: [
          "Не менее 95%",
          "Не менее 85%",
          "Не менее 75%",
          "Не менее 80%",
        ],
        correctAnswers: ["Не менее 95%"],
      },
      {
        code: "20305051",
        text: "Какие действия могут быть предприняты перед проведением операции по перестановке штырей на электролизерах с верхним токопроводом к самообжигающемуся аноду согласно Правилам безопасности процессов получения или применения металлов? Выберите 2 варианта ответа.",
        answers: [
          "Выставление знаков, запрещающих вход в опасную зону",
          "Назначение ответственного, контролирующего вход в опасную зону",
          "Установка световой проекции знака стоп",
          "Ограждение опасной зоны металлическими конструкциями",
        ],
        correctAnswers: [
          "Выставление знаков, запрещающих вход в опасную зону",
          "Установка световой проекции знака стоп",
        ],
      },
      {
        code: "20305052",
        text: "Каким должно быть сопротивление изоляции элементов напольных рельсовых машин на производстве алюминия?",
        answers: [
          "Не менее величин, указанных в производственной инструкции",
          "Не менее величин, указанных в технологической инструкции",
          "Не менее величин, указанных в техническом регламенте",
          "Не менее величин, указанных в проекте, разработанном для данного производства",
        ],
        correctAnswers: [
          "Не менее величин, указанных в проекте, разработанном для данного производства",
        ],
      },
      {
        code: "20305053",
        text: "С какой периодичностью проводится проверка электрического сопротивления трубопроводов подачи воды для охлаждения элементов электролизеров?",
        answers: [
          "Не реже одного раза в 10 дней",
          "Не реже одного раза в 2 недели",
          "Не реже одного раза в месяц",
          "Не реже одного раза в 3 месяца",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20305054",
        text: "Согласно какому документу должна проводиться проверка электрического сопротивления изоляции узлов и деталей электролизной установки?",
        answers: [
          "Согласно проекту",
          "Согласно инструкции по порядку пуска и остановки агрегатов",
          "Согласно техническому регламенту",
          "Согласно инструкции по эксплуатации",
        ],
        correctAnswers: ["Согласно проекту"],
      },
      {
        code: "20305055",
        text: "Какие из перечисленных требований на электролитическом производстве магния указаны неверно?",
        answers: [
          "Периодичность проверки оборудования, переносных трансформаторов и их частей, шинопроводов постоянного тока и строительных конструкций устанавливается проектом",
          "Перед заливкой расплава, извлечением металла и удалением электролита в подвал обслуживаемого электролизера должны подаваться световой и звуковой сигналы. Нахождение людей в подвале, в зоне обслуживаемого электролизера, не допускается",
          "Металлические инструменты, применяемые в корпусах электролиза, должны быть изготовлены из алюминия",
          "Не допускается подключение и отключение электролизеров к общему шинопроводу без снятия электрической нагрузки на серию",
        ],
        correctAnswers: [
          "Металлические инструменты, применяемые в корпусах электролиза, должны быть изготовлены из алюминия",
        ],
      },
      {
        code: "20305056",
        text: "Что из перечисленного должно производиться в соответствии с инструкциями по пуску и остановке агрегатов (технических устройств) на объекте электролитического производства магния? Выберите правильный вариант ответа.",
        answers: [
          "Только порядок пуска и остановки электролизеров",
          "Только проверку герметичности устройств для транспортирования анодного газа",
          "Только удаление возгонов солей из хлоропроводов",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20305057",
        text: "Какие из перечисленных требований на электролитическом производстве магния указаны неверно?",
        answers: [
          "Керамические хлоропроводы необходимо очищать от возгонов солей согласно требованиям производственной инструкции",
          "При чистке хлоропроводов и газоходов местного отсоса на работающем электролизере необходимо открывать не более двух очистных люков",
          "Все работы по подключению и отключению электролизеров на сушку и разогрев с помощью сухих переносных трансформаторов должны производиться электротехническим персоналом при снятом напряжении на трансформаторе",
          "Замена электродов на работающих электролизерах должна производиться по технологической инструкции",
        ],
        correctAnswers: [
          "При чистке хлоропроводов и газоходов местного отсоса на работающем электролизере необходимо открывать не более двух очистных люков",
        ],
      },
      {
        code: "20305058",
        text: "Какие из перечисленных требований при рафинировании и разливке металлов указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Заливка металла в кристаллизатор машины полунепрерывного литья должна выполняться перед пуском водного охлаждения",
          "Пуск хода платформы машины полунепрерывного литья разрешается при разных уровнях металла в кристаллизаторах",
          "Пуск гидравлической машины полунепрерывного литья допускается только при отсутствии подтекания масла в трубопроводах и отсутствии его в кессоне",
          "Хранение металлического лития, используемого для приготовления сплавов с алюминием, должно быть организовано в соответствии с распоряжением ответственного исполнителя работы",
        ],
        correctAnswers: [
          "Пуск гидравлической машины полунепрерывного литья допускается только при отсутствии подтекания масла в трубопроводах и отсутствии его в кессоне",
        ],
      },
      {
        code: "20305059",
        text: "С какой периодичностью должна проводиться проверка состояния форм и изложниц для разливки металлов?",
        answers: ["1 раз в 3 дня", "Еженедельно", "Ежесменно", "Ежемесячно"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20305060",
        text: "Какие из перечисленных требований при разливке металлов указаны неверно?",
        answers: [
          "Транспортирование расплавленного металла в ковшах (тиглях) вручную должно производиться по проходам с безопасной шириной, установленной проектом",
          "Уровень наполнения металлом литейных ковшей и тиглей для ручной разливки металла зависит от их объема и определяется производственной инструкцией",
          "Не допускается вывозить отходы металлического магния и его сплавов на свалку. Отходы должны быть утилизированы согласно проектной технологии в местах, специально отведенных для этих целей",
          "Тушение загоревшихся легких металлов и их сплавов должно производиться сухими порошковыми материалами: флюсом, хлоркалиевым электролитом или обезвоженным карналлитом",
        ],
        correctAnswers: [
          "Уровень наполнения металлом литейных ковшей и тиглей для ручной разливки металла зависит от их объема и определяется производственной инструкцией",
        ],
      },
      {
        code: "20305061",
        text: "В соответствии с каким документом должно быть организовано хранение металлического лития, используемого для приготовления сплавов с алюминием?",
        answers: [
          "В соответствии с проектом",
          "В соответствии с производственной инструкцией",
          "В соответствии с технологической инструкцией",
          "В соответствии с технической документацией завода-изготовителя",
        ],
        correctAnswers: [
          "В соответствии с технической документацией завода-изготовителя",
        ],
      },
      {
        code: "20305062",
        text: "Какие из перечисленных требований указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Не допускается применение флюса, хлоркалиевого электролита или обезвоженного карналлита для тушения горящего металла",
          "Допускается вывозить отходы металлического магния и его сплавов на свалку",
          "Высота штабелей чушек, слитков, вайербарсов не регламентируется",
          "Складские помещения для хранения готовой продукции должны быть сухими. В этих помещениях не допускается применение печного отопления, хранение легковоспламеняющихся материалов и химически активных веществ",
        ],
        correctAnswers: [
          "Складские помещения для хранения готовой продукции должны быть сухими. В этих помещениях не допускается применение печного отопления, хранение легковоспламеняющихся материалов и химически активных веществ",
        ],
      },
      {
        code: "20305063",
        text: "Что из перечисленного допускается в складских помещениях для хранения готовой продукции литейного производства?",
        answers: [
          "Применять печное отопление",
          "Устанавливать высоту штабелей чушек, слитков, вайербарсов в соответствии с загрузкой литейного цеха",
          "Хранить легковоспламеняющиеся материалы и химически активные вещества",
          "Ничего из перечисленного",
        ],
        correctAnswers: ["Ничего из перечисленного"],
      },
      {
        code: "20305064",
        text: "Какое должно быть содержание влаги в шихтовых материалах, загружаемых в руднотермическую печь, при производстве титанового шлака?",
        answers: [
          "Не должно превышать предела, предусмотренного инструкцией",
          "Должно устанавливаться ответственным за выполнение работ",
          "Не должно превышать предела, предусмотренного проектной документацией",
          "Не должно превышать предела, предусмотренного паспортом оборудования",
        ],
        correctAnswers: [
          "Не должно превышать предела, предусмотренного инструкцией",
        ],
      },
      {
        code: "20305065",
        text: "Какой предусмотрен размер ячеек предохранительных решеток бункеров при производстве титанового шлака?",
        answers: [
          "Не более 250 x 250 мм",
          "Не более 200 x 200 мм",
          "Не более 150 x 150 мм",
          "Не более 100 x 100 мм",
        ],
        correctAnswers: ["Не более 250 x 250 мм"],
      },
      {
        code: "20305066",
        text: "Какие из перечисленных видов работ не допускается производить при работающей дробилке при производстве титанового шлака? Выберите правильный вариант ответа.",
        answers: [
          "Только регулирование ширины щели дробилки",
          "Только подтягивание пружин, болтов",
          "Только проталкивание и шуровка материала",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20305067",
        text: "Как часто необходимо осматривать электрододержатели и электроды, а также системы токоподвода к печам при производстве титанового шлака?",
        answers: ["Ежесменно", "Еженедельно", "Ежемесячно"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20305068",
        text: "При какой длине трубки разрешается прожигать летку при наличии предохранительного ограждения (стационарного щита) при производстве титанового шлака?",
        answers: [
          "Не менее 2 м",
          "Не менее 4 м",
          "Не менее 6 м",
          "Не менее 8 м",
        ],
        correctAnswers: ["Не менее 2 м"],
      },
      {
        code: "20305069",
        text: "На каком минимальном расстоянии могут находиться люди от путей и натяжного троса при транспортировании тележек с изложницами или ковшами, наполненными металлом или шлаком, при производстве титанового шлака?",
        answers: ["1 м", "2 м", "3 м", "5 м"],
        correctAnswers: ["5 м"],
      },
      {
        code: "20305070",
        text: "Какое из перечисленных требований при производстве четыреххлористого титана указано неверно?",
        answers: [
          "При периодическом процессе получения низших хлоридов титана загрузка алюминиевой пудры в бак должна производиться только при наличии в баке четыреххлористого титана, при этом температура стенок бака и четыреххлористого титана не должна превышать 40 °C",
          "Общецеховые сборные газоходы должны быть оборудованы герметичными люками. Расстояние между люками - не более 10 м",
          "Не допускается вскрытие хлорирующих устройств и аппаратов систем конденсации, находящихся под давлением",
          "Слив расплава из хлоратора с последующим гидроудалением должен производиться в ванну, заполненную водой до уровня перелива, вода в ванну должна подаваться непрерывно",
        ],
        correctAnswers: [
          "Общецеховые сборные газоходы должны быть оборудованы герметичными люками. Расстояние между люками - не более 10 м",
        ],
      },
      {
        code: "20305071",
        text: "Какая допускается максимальная температура стенок бака и четыреххлористого титана при периодическом процессе получения низших хлоридов титана?",
        answers: ["30 °C", "40 °C", "50 °C", "60 °C"],
        correctAnswers: ["40 °C"],
      },
      {
        code: "20305072",
        text: "Что из перечисленного не применяется для тушения алюминиевой пудры?",
        answers: [
          "Песок",
          "Вода",
          "Асбестовые (базальтовые) одеяла",
          "Обезвоженный карналлит",
        ],
        correctAnswers: ["Вода"],
      },
      {
        code: "20305073",
        text: "На каком расстоянии должна устанавливаться тара с алюминиевой пудрой от отопительных систем?",
        answers: [
          "Не ближе 1 м",
          "Не ближе 0,8 м",
          "Не ближе 0,6 м",
          "Не ближе 0,4 м",
        ],
        correctAnswers: ["Не ближе 0,8 м"],
      },
      {
        code: "20305074",
        text: "Каким составом должны проводиться ремонт и чистка шахтных печей сопротивления?",
        answers: [
          "Не менее чем двумя рабочими, один из которых - наблюдающий",
          "Не менее чем тремя рабочими, один из которых - наблюдающий",
          "Не менее чем тремя рабочими, два из которых - наблюдающие",
          "Не менее чем четырьмя рабочими, один из которых - наблюдающий",
        ],
        correctAnswers: [
          "Не менее чем двумя рабочими, один из которых - наблюдающий",
        ],
      },
      {
        code: "20305075",
        text: "Чем производится удаление воды из рубашки охлаждения фланцев реторт и крышки перед демонтажем аппаратов вакуумной сепарации?",
        answers: [
          "Сжатым воздухом",
          "Азотом",
          "Водородом",
          "Водо-воздушной смесью",
        ],
        correctAnswers: ["Сжатым воздухом"],
      },
      {
        code: "20305076",
        text: "Чем определяется уровень заполнения ковша при транспортировании магния и хлористого магния?",
        answers: [
          "Инструкцией",
          "Техническим регламентом",
          "Проектной документацией",
          "Паспортом оборудования",
        ],
        correctAnswers: ["Инструкцией"],
      },
      {
        code: "20305077",
        text: "В каком случае должна производиться подача кислоты с концентрацией более 15% в травильные ванны?",
        answers: [
          "После предварительного осушения травильных ванн согласно производственной инструкции",
          "После предварительного наполнения травильных ванн водой согласно производственной инструкции",
          "После предварительной обработки травильных ванн известковым молоком согласно производственной инструкции",
          "После предварительной обработки травильных ванн пенообразующим раствором",
        ],
        correctAnswers: [
          "После предварительного наполнения травильных ванн водой согласно производственной инструкции",
        ],
      },
      {
        code: "20305078",
        text: "С какой периодичностью должна производиться влажная уборка рабочих мест, площадок и полов в местах рассева и магнитной сепарации титан пористых порошков?",
        answers: [
          "Не реже одного раза в смену",
          "Не реже одного раза в час",
          "Не реже двух раз в смену",
          "Влажная уборка рабочих мест, площадок и полов в местах рассева и магнитной сепарации титан пористых порошков не регламентирована",
        ],
        correctAnswers: ["Не реже одного раза в смену"],
      },
      {
        code: "20305079",
        text: "В каком случае напряжение с электродов должно быть снято при обезвоживании карналлита и производстве флюсов?",
        answers: [
          "Только при производстве работ на миксерах, печах СКН и хлораторах с применением грузоподъемных механизмов",
          "Только при заливке, перемешивании расплава",
          "Только при удалении шлама",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20305080",
        text: "Какое из перечисленных требований к электролитическому производству магния указано неверно?",
        answers: [
          "Проверка электрического сопротивления изоляции узлов и деталей должна проводиться согласно инструкции",
          "Металлические инструменты, применяемые в корпусах электролиза, должны быть изготовлены из немагнитного металла",
          "Перед заливкой расплава, извлечением металла и удалением электролита в подвал обслуживаемого электролизера должны подаваться световой и звуковой сигналы",
          "Замена электродов на работающих электролизерах должна производиться по технологической инструкции",
        ],
        correctAnswers: [
          "Проверка электрического сопротивления изоляции узлов и деталей должна проводиться согласно инструкции",
        ],
      },
      {
        code: "20305081",
        text: "Какая электроизоляция предусмотрена при проектировании и строительстве корпусов электролиза при производстве магния?",
        answers: [
          "Внутренних стен на высоту не менее 3 м, колонн - на высоту не менее 3,5 м от уровня рабочих площадок, фундаментов и опорных конструкций электролизеров, подземных каналов и междуэтажных перекрытий",
          "Внутренних стен на высоту не менее 3,5 м, колонн - на высоту не менее 4 м от уровня рабочих площадок, фундаментов и опорных конструкций электролизеров, подземных каналов и междуэтажных перекрытий",
          "Внутренних стен на высоту не менее 5 м, колонн - на высоту не менее 3,5 м от уровня рабочих площадок, фундаментов и опорных конструкций электролизеров, подземных каналов и междуэтажных перекрытий",
          "Внутренних стен на высоту не менее 5,5 м, колонн - на высоту не менее 4 м от уровня рабочих площадок, фундаментов и опорных конструкций электролизеров, подземных каналов и междуэтажных перекрытий",
        ],
        correctAnswers: [
          "Внутренних стен на высоту не менее 3 м, колонн - на высоту не менее 3,5 м от уровня рабочих площадок, фундаментов и опорных конструкций электролизеров, подземных каналов и междуэтажных перекрытий",
        ],
      },
      {
        code: "20305082",
        text: "Каким должно быть сопротивление изоляции внутренних стен и колонн электролиза при производстве магния?",
        answers: [
          "Не менее 0,5 МОм",
          "Не менее 0,7 МОм",
          "Не менее 0,9 МОм",
          "Не менее 1 МОм",
        ],
        correctAnswers: ["Не менее 0,5 МОм"],
      },
      {
        code: "20305083",
        text: "На каком расстоянии должны располагаться электрические распределительные устройства силовых и осветительных сетей с глухозаземленной нейтралью в электролизных корпусах производства магния от не огражденных шинопроводов и частей электролизеров, находящихся под напряжением постоянного тока?",
        answers: [
          "Не ближе 6 м",
          "Не ближе 8 м",
          "Не ближе 10 м",
          "Не ближе 12 м",
        ],
        correctAnswers: ["Не ближе 6 м"],
      },
      {
        code: "20305084",
        text: "Какое количество ступеней электроизоляции от строительных конструкций корпуса электролиза должны иметь металлические трубопроводы, защитные трубы, короба и бронированные кабели, располагаемые на высоте менее 3,5 м, при производстве магния?",
        answers: [
          "Одну ступень электроизоляции",
          "Две ступени электроизоляции",
          "Три ступени электроизоляции",
          "Четыре ступени электроизоляции",
        ],
        correctAnswers: ["Две ступени электроизоляции"],
      },
      {
        code: "20305085",
        text: "Какое из перечисленных требований при хранении магния и его сплавов указано неверно?",
        answers: [
          "Допускается применение печного отопления в складских помещениях",
          "Не допускается хранение легковоспламеняющихся материалов в складских помещениях",
          "Не допускается хранение химически активных веществ в складских помещениях",
          "Складские помещения для хранения готовой продукции должны быть сухими",
        ],
        correctAnswers: [
          "Допускается применение печного отопления в складских помещениях",
        ],
      },
      {
        code: "20305086",
        text: "Какие из перечисленных сведений не должна содержать сопроводительная документация на исходные взрывоопасные и легковоспламеняющиеся порошковые материалы?",
        answers: [
          "Нижний концентрационный предел взрывания и воспламенения",
          "Температуру воспламенения аэровзвеси и самовоспламенения в слое",
          "Максимальное давление взрыва",
          "Расчетная удельная теплота и температура горения",
        ],
        correctAnswers: ["Расчетная удельная теплота и температура горения"],
      },
      {
        code: "20305087",
        text: "Какие из перечисленных смесей не допускается применять при производстве твердых сплавов и тугоплавких металлов, а также электродов для наплавочных работ? Выберите правильный вариант ответа.",
        answers: [
          "Только смеси, процесс горения которых переходит во взрыв",
          "Только смеси, способные к самостоятельному горению и имеющие удельную теплоту процесса горения более 50 кДж/моль",
          "Только смеси, чувствительность которых к механическому воздействию (удару) составляет 19,6 Дж и менее, а активной составляющей - 9,8 Дж и менее",
          "Все перечисленные смеси",
        ],
        correctAnswers: ["Все перечисленные смеси"],
      },
      {
        code: "20305088",
        text: "С какой периодичностью должна производиться уборка производственных помещений участка получения низших хлоридов титана?",
        answers: [
          "Не реже одного раза в смену",
          "Не реже двух раз в смену",
          "Еженедельно",
          "Ежемесячно",
        ],
        correctAnswers: ["Не реже одного раза в смену"],
      },
      {
        code: "20305089",
        text: "Как часто должно проверяться исправное действие автоблокировки и сигнализации?",
        answers: [
          "Не реже одного раза в неделю",
          "Не реже одного раза в три месяца",
          "Не реже одного раза в месяц",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20305090",
        text: "Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в неделю",
          "Не реже одного раза в 2 месяца",
        ],
        correctAnswers: ["Не реже одного раза в неделю"],
      },
      {
        code: "20305091",
        text: "Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных вне помещений?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в неделю",
          "Не реже одного раза в квартал",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20305092",
        text: "В каких случаях запрещается газовая резка и сварка на действующих газопроводах?",
        answers: [
          "Во всех перечисленных случаях",
          "На газопроводах, находящихся под разрежением",
          "После продувки газопроводов воздухом",
        ],
        correctAnswers: ["На газопроводах, находящихся под разрежением"],
      },
      {
        code: "20305093",
        text: "На что должен проверяться выполненный сварной шов после врезки в действующий газопровод?",
        answers: ["На прочность", "На плотность", "На герметичность"],
        correctAnswers: ["На плотность"],
      },
      {
        code: "20305094",
        text: "Разрешено ли использовать продувочные свечи для выпуска в атмосферу избыточного газа?",
        answers: [
          "Не допускается",
          "Только в аварийных случаях",
          "Только при проведении пуско-наладочных работ",
        ],
        correctAnswers: ["Только в аварийных случаях"],
      },
      {
        code: "20305095",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии до 0,1 мм/г?",
        answers: ["Один раз в 2 года", "Один раз в 4 года", "Один раз в 5 лет"],
        correctAnswers: ["Один раз в 4 года"],
      },
      {
        code: "20305096",
        text: "Какая допускается максимальная температура газа в газопроводах в случае подачи в них пара для пропарки, продувки и отогрева?",
        answers: ["85 °C", "95 °C", "90 °C"],
        correctAnswers: ["85 °C"],
      },
      {
        code: "20305097",
        text: "Как часто должна производиться нивелировка действующих газопроводов?",
        answers: ["Один раз в 3 года", "Один раз в 5 лет", "Один раз в год"],
        correctAnswers: ["Один раз в 5 лет"],
      },
      {
        code: "20305098",
        text: "Куда должны заноситься записи о проведении технического обслуживания, выявленных дефектах и нарушениях газопроводов?",
        answers: [
          "В эксплуатационный журнал",
          "В акт технического обслуживания",
          "В ремонтный журнал",
        ],
        correctAnswers: ["В эксплуатационный журнал"],
      },
      {
        code: "20305099",
        text: "До розжига газопотребляющего агрегата чем должен быть продут газопровод перед коллектором?",
        answers: ["Воздухом", "Газом", "Азотом"],
        correctAnswers: ["Газом"],
      },
      {
        code: "20305100",
        text: "В какие сроки в помещениях категории А должна осуществляться проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией?",
        answers: [
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в 2 месяца",
          "Не реже одного раза в месяц",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20305101",
        text: "Как часто должны проходить поверку контрольно-измерительные приборы в процессе эксплуатации?",
        answers: [
          "Не реже одного раза в 2 года",
          "Не реже одного раза в год",
          "Не реже одного раза в 6 месяцев",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20305102",
        text: "Какие работы производятся на ОПО горно-металлургических производств? Выберите правильный вариант ответа.",
        answers: [
          "Только постоянные работы, выполняемые в соответствии с документированной процедурой (инструкцией, картой, регламентом), принятой в организации",
          "Только работы повышенной опасности (сложные, разовые, уникальные)",
          "Только работы, связанные с аварийными ситуациями",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20305103",
        text: "Какие из перечисленных требований к акту-допуску подрядных организаций на опасный производственный объект горно-металлургической промышленности указаны неверно?",
        answers: [
          "Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)",
          "Акт-допуск должен оформляться письменно",
          "На один объект, площадку, территорию оформляется один акт-допуск",
          "Не допускается оформлять акты-допуски, характеризующиеся одинаковыми координатами рабочих зон",
        ],
        correctAnswers: [
          "Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)",
        ],
      },
      {
        code: "20305104",
        text: "Какие действия предпринимаются при необходимости ведения работ после истечения срока действия акта-допуска подрядных организаций на опасный производственный объект горно-металлургической промышленности?",
        answers: [
          "Составляется новый акт-допуск на следующий срок",
          "Продлевается текущий акт-допуск",
          "Работы продолжаются без оформления",
          "Не регламентируется",
        ],
        correctAnswers: ["Составляется новый акт-допуск на следующий срок"],
      },
      {
        code: "20305105",
        text: "В каком количестве экземпляров составляется акт-допуск подрядных организаций на опасный производственный объект горно-металлургической промышленности?",
        answers: [
          "В двух экземплярах",
          "В одном экземпляре",
          "В трех экземплярах",
          "Не регламентировано",
        ],
        correctAnswers: ["В двух экземплярах"],
      },
      {
        code: "20305106",
        text: "С кем согласовывается перечень работ повышенной опасности на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля",
          "С главным инженером",
          "С главным технологом",
          "С главным механиком",
        ],
        correctAnswers: [
          "Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля",
        ],
      },
      {
        code: "20305107",
        text: "Кем могут проводиться работы повышенной опасности на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "Как работниками эксплуатирующей организации, так и работниками подрядных организаций",
          "Только работниками эксплуатирующей организации",
          "Только работниками подрядных организаций",
        ],
        correctAnswers: [
          "Как работниками эксплуатирующей организации, так и работниками подрядных организаций",
        ],
      },
      {
        code: "20305108",
        text: "В течение какого времени хранится журнал регистрации нарядов-допусков со дня внесения последней записи?",
        answers: [
          "В течение 6 месяцев",
          "В течение месяца",
          "В течение года",
          "В течение 3 месяцев",
        ],
        correctAnswers: ["В течение 6 месяцев"],
      },
      {
        code: "20305109",
        text: "На какой максимальный срок выдается наряд-допуск на работы повышенной опасности?",
        answers: [
          "На 30 календарных дней",
          "На 14 календарных дней",
          "На 45 календарных дней",
          "На 20 календарных дней",
        ],
        correctAnswers: ["На 30 календарных дней"],
      },
      {
        code: "20305110",
        text: "Кто определяет (назначает) работников, выполняющих мероприятия по безопасности труда, указанных в наряде-допуске (ответственных исполнителей)?",
        answers: [
          "Выдающий наряд-допуск",
          "Допускающий к работе",
          "Производитель (руководитель) работ",
          "Утверждающий наряд-допуск",
        ],
        correctAnswers: ["Выдающий наряд-допуск"],
      },
      {
        code: "20305111",
        text: "Какие из перечисленных требований к наряду-допуску на работы повышенной опасности указаны неверно? Выберите два правильных ответа.",
        answers: [
          "После окончания работы наряд-допуск остается у производителя (руководителя) работ",
          "Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ",
          "При допуске бригады к работе допускающий к работе вручает первый экземпляр наряда-допуска производителю работ, а второй экземпляр наряда-допуска хранится вместе с нарядами, действующими на этот момент времени",
          "При перерыве в работе в течение одной смены (обеденный перерыв, перерыв по условиям производства работ) наряд-допуск остается у производителя (руководителя) работ",
        ],
        correctAnswers: [
          "После окончания работы наряд-допуск остается у производителя (руководителя) работ",
          "Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ",
        ],
      },
      {
        code: "20305112",
        text: "Какое количество производителей (руководителей) работ назначаются на все время действия наряда-допуска на работы повышенной опасности при производстве работ в несколько смен?",
        answers: [
          "Несколько производителей (руководителей) работ в соответствии с количеством смен",
          "Один производитель (руководитель) работ",
          "Количество производителей (руководителей) работ определяет выдающий наряд-допуск",
          "Не регламентируется",
        ],
        correctAnswers: [
          "Несколько производителей (руководителей) работ в соответствии с количеством смен",
        ],
      },
      {
        code: "20305113",
        text: "В течение какого времени хранятся закрытые (подписанные) наряды-допуски на работы повышенной опасности?",
        answers: [
          "В течение одного месяца",
          "В течение трех месяцев",
          "В течение шести месяцев",
          "В течение года",
        ],
        correctAnswers: ["В течение одного месяца"],
      },
      {
        code: "20305114",
        text: "Какие действия должны быть выполнены при утрате наряда-допуска на работы повышенной опасности?",
        answers: [
          "Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново",
          "Работы не прекращаются, но вносится запись в журнал регистрации нарядов-допусков на работы повышенной опасности",
          "Работы должны быть продолжены. Оформление нового наряда-допуска не требуется",
          "Выписывается дубликат наряда, работы продолжаются",
        ],
        correctAnswers: [
          "Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново",
        ],
      },
      {
        code: "20305115",
        text: "В каких из перечисленных случаях допускается продолжать работы, проводимые по наряду-допуску, на опасных производственных объектах?",
        answers: [
          "Если изменился состав бригады",
          "Если обнаружено несоответствие фактического состояния производства работ требованиям безопасности и охраны труда",
          "Если подан аварийный сигнал",
          "Если характер и объемы работ изменены в такой степени, что требуется изменение схемы отключения ТУ",
        ],
        correctAnswers: ["Если изменился состав бригады"],
      },
      {
        code: "20305116",
        text: "На какие группы подразделяются газоопасные места на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "На I и II группы газоопасности",
          "На I, II и III группы газоопасности",
          "На I, II, III и IV группы газоопасности",
          "Не регламентируется",
        ],
        correctAnswers: ["На I и II группы газоопасности"],
      },
      {
        code: "20305117",
        text: "С кем согласовывается инструкция, определяющая порядок подготовки и безопасного проведения газоопасных работ на опасных производственных объектах горно-металлургической промышленности? Выберите два правильных ответа.",
        answers: [
          "Со службой производственного контроля",
          "С газоспасательной службой (ГСС)",
          "Со службой охраны труда",
          "С главным технологом",
        ],
        correctAnswers: [
          "Со службой производственного контроля",
          "С газоспасательной службой (ГСС)",
        ],
      },
      {
        code: "20305118",
        text: "Каким составом должны выполняться газоопасные работы I и II группы газоопасности с образованием взрывопожароопасных смесей?",
        answers: [
          "Не менее двух человек под наблюдением газоспасателя или члена ДГСД",
          "Одним человеком под наблюдением газоспасателя",
          "Не менее трех человек",
          "Не регламентируется",
        ],
        correctAnswers: [
          "Не менее двух человек под наблюдением газоспасателя или члена ДГСД",
        ],
      },
      {
        code: "20305119",
        text: "С кем согласовывается план организации и проведения газоопасной работы?",
        answers: [
          "Только с начальником газоспасательной службы",
          "Только с ответственным представителем подразделения или организации (главным энергетиком)",
          "Только со службой производственного контроля",
          "Со всеми перечисленными",
        ],
        correctAnswers: ["Со всеми перечисленными"],
      },
      {
        code: "20305120",
        text: "Какой стаж работы в газовом хозяйстве должны иметь лица, назначаемые ответственными руководителями и ответственными исполнителями газоопасных работ?",
        answers: [
          "Не менее одного года",
          "Не менее двух лет",
          "Не менее трех лет",
          "Не менее пяти лет",
        ],
        correctAnswers: ["Не менее одного года"],
      },
      {
        code: "20305121",
        text: "Какое из перечисленных требований к применению жетон-бирочной системы указано неверно?",
        answers: [
          "Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями",
          "Жетон-бирки после разборки электрической схемы ТУ передаются от технологического персонала ремонтному персоналу с отметкой всех этапов в журнале выдачи и приема жетон-бирок",
          "Жетон-бирочная система применяется при выводе ТУ на продолжительную остановку",
          'Ответственный специалист, разобравший схему, принимает меры, исключающие ошибочное или самопроизвольное включение пусковых устройств, на пусковых устройствах вывешивает предупредительные плакаты "Не включать! Работают люди", проверяет отсутствие энергетического потенциала',
        ],
        correctAnswers: [
          "Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями",
        ],
      },
      {
        code: "20305122",
        text: "Какое значение не должно превышать содержание сероводорода в коксовом газе в заводских сетях действующих предприятий?",
        answers: ["4 г/нм³", "5 г/нм³", "6 г/нм³", "7 г/нм³"],
        correctAnswers: ["4 г/нм³"],
      },
      {
        code: "20305123",
        text: "Какое значение не должно превышать содержание сероводорода в коксовом газе при газоплазменной обработке металла в закрытых помещениях, а также нафталина летом и зимой?",
        answers: [
          "Содержание сероводорода не должно превышать 0,4 г/нм³, нафталина - 0,6 г/нм³ (летом) и 0,2 г/нм³ (зимой)",
          "Содержание сероводорода не должно превышать 0,04 г/нм³, нафталина - 0,06 г/нм³ (летом) и 0,01 г/нм³ (зимой)",
          "Содержание сероводорода не должно превышать 0,2 г/нм³, нафталина - 0,005 г/нм³ (летом) и 0,01 г/нм³ (зимой)",
          "Содержание сероводорода не должно превышать 0,02 г/нм³, нафталина - 0,05 г/нм³ (летом) и 0,1 г/нм³ (зимой)",
        ],
        correctAnswers: [
          "Содержание сероводорода не должно превышать 0,02 г/нм³, нафталина - 0,05 г/нм³ (летом) и 0,1 г/нм³ (зимой)",
        ],
      },
      {
        code: "20305124",
        text: "Какое расчетное давление газа соответствует газопроводам среднего давления?",
        answers: [
          "Для газопроводов и газового оборудования промышленных газов - до 1,6 МПа, для газопроводов и газового оборудования природного газ - свыше 1,2 МПа",
          "Для газопроводов и газового оборудования промышленных газов - свыше 0,1 до 0,3 МПа включительно, для газопроводов и газового оборудования природного газ - свыше 0,005 до 0,3 МПа включительно",
          "Для газопроводов и газового оборудования промышленных газов - свыше 0,3 до 1,2 МПа включительно, для газопроводов и газового оборудования природного газ - свыше 0,6 до 1,2 МПа включительно",
          "Для газопроводов и газового оборудования промышленных газов - до 0,1 МПа включительно, для газопроводов и газового оборудования природного газ - до 0,005 МПа включительно",
        ],
        correctAnswers: [
          "Для газопроводов и газового оборудования промышленных газов - свыше 0,1 до 0,3 МПа включительно, для газопроводов и газового оборудования природного газ - свыше 0,005 до 0,3 МПа включительно",
        ],
      },
      {
        code: "20305125",
        text: "Как должны сооружаться газопроводы на территории предприятия?",
        answers: [
          "По технологии бесканальной прокладки",
          "По технологии подземного устройства в каналах",
          "Надземными",
          "Сооружение газопроводов на территории предприятия не регламентировано",
        ],
        correctAnswers: ["Надземными"],
      },
      {
        code: "20305126",
        text: "Через какое расстояние должны заземляться наружные газопроводы?",
        answers: [
          "Через каждые 150 м",
          "Через каждые 250 м",
          "Через каждые 350 м",
          "Через каждые 450 м",
        ],
        correctAnswers: ["Через каждые 250 м"],
      },
      {
        code: "20305127",
        text: "При каком максимальном содержании сероводорода в газе допускается установка бронзовых кранов или задвижек с бронзовыми кольцами на газопроводах?",
        answers: ["5 мг/м³", "10 мг/м³", "15 мг/м³", "20 мг/м³"],
        correctAnswers: ["20 мг/м³"],
      },
      {
        code: "20305128",
        text: "Какое из перечисленных требований к задвижкам и заглушкам, устанавливаемым на газопроводах, указано неверно?",
        answers: [
          "Листовые задвижки на газопроводах диаметром более 200 мм должны быть оснащены механизированным приводом",
          "Перекрывающие листы листовых задвижек и заглушек диаметром до 2 м должны изготовляться из целого листа",
          "Перекрывающие листы заглушек и листовых задвижек должны быть рассчитаны на соответствующее давление газа с учетом диаметра газопровода, при этом толщина их должна быть не менее 4 мм",
          "Съемные заглушки, устанавливаемые на трубопроводах, должны быть изготовлены согласно проектной документации и иметь паспорт (сертификат) изготовителя",
        ],
        correctAnswers: [
          "Листовые задвижки на газопроводах диаметром более 200 мм должны быть оснащены механизированным приводом",
        ],
      },
      {
        code: "20305129",
        text: "Какая информация должна быть выбита на хвостовике каждой съемной заглушки газопровода?",
        answers: [
          "Только номер сертификата на заглушку, номер заглушки",
          "Только марка материала",
          "Только условное давление Py и условный диаметр Ду",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20305130",
        text: "Какая должна быть минимальная высота водяного затвора конденсатоотводчиков межцеховых газопроводов, работающих под избыточным давлением?",
        answers: [
          "2000 мм вод. ст.",
          "2500 мм вод. ст.",
          "3000 мм вод. ст.",
          "3500 мм вод. ст.",
        ],
        correctAnswers: ["2000 мм вод. ст."],
      },
      {
        code: "20305131",
        text: "Какое из перечисленных требований к отводу конденсата из цеховых газопроводов указано неверно?",
        answers: [
          "Сброс конденсата из цеховых газопроводов следует производить через конденсатоотводчики",
          "Допускается сброс конденсата через горелки печей и другого оборудования потребителя газа",
          "Конструкция конденсатоотводчиков, установленных в зданиях цехов, должна исключать возможность попадания газов в помещения",
          "Участки труб, отводящих конденсат, а также сами конденсатоотводчики, если цех не отапливается, должны быть утеплены",
        ],
        correctAnswers: [
          "Допускается сброс конденсата через горелки печей и другого оборудования потребителя газа",
        ],
      },
      {
        code: "20305132",
        text: "Какое из перечисленных требований к стационарным газоразборным постам указано неверно?",
        answers: [
          "Расстояние между шкафами газоразборных постов для кислорода и горючего газа должно быть не менее 250 мм",
          "Дверцы шкафов во время работы должны быть открыты",
          "При отсутствии рабочего, пользующегося газоразборным постом, шкаф должен быть закрыт на замок",
          "Газоразборные посты, шкафы должны иметь опознавательную окраску",
        ],
        correctAnswers: [
          "Расстояние между шкафами газоразборных постов для кислорода и горючего газа должно быть не менее 250 мм",
        ],
      },
      {
        code: "20305133",
        text: "Какой должен быть нижний предел давления для I разряда газов-заменителей ацетилена перед газопламенной аппаратурой?",
        answers: ["3,0 кПа", "3,5 кПа", "5,0 кПа", "5,5 кПа"],
        correctAnswers: ["3,0 кПа"],
      },
      {
        code: "20305134",
        text: "При каком увеличении содержания кислорода в газе в напорном коллекторе газоотсасывающей станции отвод газа от электропечи должен быть немедленно прекращен?",
        answers: [
          "При достижении содержания кислорода 1%",
          "При достижении содержания кислорода 1,5%",
          "При достижении содержания кислорода 2%",
          "При достижении содержания кислорода 2,5%",
        ],
        correctAnswers: ["При достижении содержания кислорода 2%"],
      },
      {
        code: "20305135",
        text: "Каким уплотнительным устройством, препятствующим выбиванию газов в цех, должно оборудоваться отверстие в охладителе конвертерных газов?",
        answers: [
          "Аэродинамической завесой, воздушной или азотной",
          "Аэродинамической завесой, водородной или гелиевой",
          "Аэродинамической завесой, кислородной или ацетиленовой",
          "Аэродинамической завесой, водородной или кислородной",
        ],
        correctAnswers: ["Аэродинамической завесой, воздушной или азотной"],
      },
      {
        code: "20305136",
        text: "Какое допускается максимальное содержание оксида углерода в газах, поступающих в электрофильтр, при отводе газов с полным дожиганием?",
        answers: ["1%", "1,5%", "2%", "2,5%"],
        correctAnswers: ["1%"],
      },
      {
        code: "20305137",
        text: "Содержание каких из перечисленных газов измеряется в продуктах неполного сгорания за дымососом при отводе газов без дожигания? Выберите 2 варианта ответа.",
        answers: ["CO", "CO₂", "O₂", "H₂"],
        correctAnswers: ["CO", "O₂"],
      },
      {
        code: "20305138",
        text: "С какой периодичностью газопроводы сероочистки природного газа подвергаются пневматическому испытанию?",
        answers: [
          "Один раз в год",
          "Один раз в два года",
          "Один раз в три года",
          "Один раз в четыре года",
        ],
        correctAnswers: ["Один раз в четыре года"],
      },
      {
        code: "20305139",
        text: "Какое из перечисленных требований к установке сероочистки природного газа указано верно?",
        answers: [
          "Перед ремонтом аппаратов и газопроводов сероочистки они должны быть отглушены и провентилированы. Содержание CH₄ в двух последовательно отобранных пробах должно быть не более 0,5%",
          "Перед пуском после ремонта аппараты и газопроводы сероочистки должны быть испытаны на плотность и прочность, продуты азотом до уровня содержания O₂ не более 0,1% в двух последовательно отобранных пробах",
          "Газосбросная свеча установки сероочистки должна быть оборудована автоматическим запальным прибором, при включении которого последовательно загораются пилотные горелки, о чем на пульте управления должна указывать световая сигнализация",
          "При перерыве в работе горелок газонагревателя более 15 минут установить заглушку на газопроводе топливного газа",
        ],
        correctAnswers: [
          "Газосбросная свеча установки сероочистки должна быть оборудована автоматическим запальным прибором, при включении которого последовательно загораются пилотные горелки, о чем на пульте управления должна указывать световая сигнализация",
        ],
      },
      {
        code: "20305140",
        text: "Через какое время должны наноситься защитные лакокрасочные покрытия на фланцы и болты межцеховых и цеховых газопроводов и газовых аппаратов?",
        answers: [
          "Через каждые 2 года",
          "Через каждые 3 года",
          "Через каждые 4 года",
          "Через каждые 5 лет",
        ],
        correctAnswers: ["Через каждые 3 года"],
      },
      {
        code: "20305141",
        text: "Через какое время должны наноситься защитные лакокрасочные покрытия на наружные поверхности межцеховых и цеховых газопроводов и газовых аппаратов?",
        answers: [
          "Через каждые 2 года",
          "Через каждые 3 года",
          "Через каждые 4 года",
          "Через каждые 5 лет",
        ],
        correctAnswers: ["Через каждые 5 лет"],
      },
      {
        code: "20305142",
        text: "С какой периодичностью должен производиться осмотр газопроводов, газовых установок и газового оборудования комиссией на производстве?",
        answers: [
          "Не реже одного раза в год",
          "Не реже двух раз в год",
          "Не реже трех раз в год",
          "Не реже шести раз в год",
        ],
        correctAnswers: ["Не реже двух раз в год"],
      },
      {
        code: "20305143",
        text: "Кто из перечисленных лиц не должен входить в состав постоянно действующей комиссии для проведения осмотров объектов газового хозяйства?",
        answers: [
          "Ответственный за производственный контроль",
          "Руководитель организации",
          "Работники, ответственные за безопасную эксплуатацию и техническое состояние соответствующих технических устройств, сооружений и оборудования",
          "Работник - ответственный за безопасную эксплуатацию и техническое состояние газового хозяйства цеха",
        ],
        correctAnswers: ["Руководитель организации"],
      },
      {
        code: "20305144",
        text: "С какой периодичностью должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях, и вне помещений?",
        answers: [
          "В помещениях - не реже одного раза в неделю, а вне помещений - не реже одного раза в месяц",
          "В помещениях - не реже одного раза в месяц, а вне помещений - не реже одного раза в неделю",
          "В помещениях - не реже одного раза в 2 недели, а вне помещений - не реже одного раза в неделю",
          "В помещениях - не реже двух раз в месяц, а вне помещений - не реже трех раз в месяц",
        ],
        correctAnswers: [
          "В помещениях - не реже одного раза в неделю, а вне помещений - не реже одного раза в месяц",
        ],
      },
      {
        code: "20305145",
        text: "С какой периодичностью должна производиться ревизия с разборкой регулятора давления, предохранительных клапанов, фильтров газопроводов, газовых установок и газового оборудования?",
        answers: [
          "Не менее одного раза в год",
          "Не менее двух раз в год",
          "Не менее трех раз в год",
          "Не менее четырех раз в год",
        ],
        correctAnswers: ["Не менее одного раза в год"],
      },
      {
        code: "20305146",
        text: "До каких пор должна осуществляться продувка азотом перед пуском в работу установки по производству реформерного газа для производства металлизованного горячевосстановленного железа?",
        answers: [
          "До тех пор, пока на участке продувки кислорода будет не более 0,5% кислорода",
          "До тех пор, пока на участке продувки кислорода будет не более 1% кислорода",
          "До тех пор, пока на участке продувки кислорода будет не более 2% кислорода",
          "До тех пор, пока на участке продувки кислорода будет не более 2,5% кислорода",
        ],
        correctAnswers: [
          "До тех пор, пока на участке продувки кислорода будет не более 1% кислорода",
        ],
      },
      {
        code: "20305147",
        text: "Какое из перечисленных требований к установке по производству реформерного газа указано неверно?",
        answers: [
          "Для защиты от повышения давления газа выше проектного должны устанавливаться предохранительные сбросные клапаны",
          "Для регулирования давления реформерного газа после холодильника и сброса его на свечу должны быть установлены регулирующие клапаны, работающие в ручном или автоматическом режиме",
          "При остановке агрегатов по производству реформерного газа подача воздуха и природного газа на горелки должна быть открыта",
          "Перед розжигом горелок реформера должна быть произведена продувка его топочного пространства",
        ],
        correctAnswers: [
          "При остановке агрегатов по производству реформерного газа подача воздуха и природного газа на горелки должна быть открыта",
        ],
      },
      {
        code: "20305148",
        text: "Кто утверждает инструкцию по перекачке сероуглерода из транспортной емкости в емкость хранения?",
        answers: [
          "Главный инженер предприятия",
          "Руководитель организации",
          "Работник, ответственный за безопасную эксплуатацию и техническое состояние газового хозяйства цеха",
          "Начальник цеха",
        ],
        correctAnswers: ["Главный инженер предприятия"],
      },
      {
        code: "20305149",
        text: "Кем производится испытание газопроводов и газовых установок после окончания строительно-монтажных и сварочных работ (включая ремонтные работы)?",
        answers: [
          "Организацией - разработчиком проекта",
          "Эксплуатирующей организацией",
          "Строительно-монтажной организацией с участием представителя предприятия",
        ],
        correctAnswers: [
          "Строительно-монтажной организацией с участием представителя предприятия",
        ],
      },
      {
        code: "20305150",
        text: "Какое из перечисленных требований к манометрам, используемым при проведении испытаний газопроводов и газовых установок, указано верно?",
        answers: [
          "При проведении испытаний газопроводов и газовых установок давление должно измеряться одним манометрами, прошедшим поверку и опломбированным",
          "Класс точности манометров должен быть не ниже 1 с диаметром корпуса не менее 130 мм",
          "Для проведения испытания на герметичность и прочность следует фиксировать падение давления в газопроводе манометрами классов точности 0,4 и 0,15, а также жидкостными манометрам",
          "При применении манометров без указания класса точности их погрешность может превышать порог измерения",
        ],
        correctAnswers: [
          "Для проведения испытания на герметичность и прочность следует фиксировать падение давления в газопроводе манометрами классов точности 0,4 и 0,15, а также жидкостными манометрам",
        ],
      },
      {
        code: "20305151",
        text: "В течение какого времени выдерживают испытательное давление в трубопроводе при испытании на прочность, после чего снижают до рабочего давления, при котором производят тщательный осмотр сварных швов (испытание на плотность)?",
        answers: [
          "В течение 5 минут",
          "В течение 10 минут",
          "В течение 15 минут",
          "В течение 20 минут",
        ],
        correctAnswers: ["В течение 10 минут"],
      },
      {
        code: "20305152",
        text: "Какое устанавливается минимальное расстояние от испытываемого газопровода большого диаметра, расположенных вне помещений, до границ охраняемой зоны во время проведения пневматических испытаний на прочность?",
        answers: [
          "Не менее 50 м",
          "Не менее 75 м",
          "Не менее 100 м",
          "Не менее 150 м",
        ],
        correctAnswers: ["Не менее 50 м"],
      },
      {
        code: "20305153",
        text: "Каким давлением производится дополнительное испытание на герметичность межцеховых и внутрицеховых газопроводов?",
        answers: [
          "Давлением ниже рабочего",
          "Давлением, равным рабочему",
          "Давлением, превышающим рабочее",
          "Давлением, устанавливаемым ответственным за производство работ и зависящим от условий эксплуатации",
        ],
        correctAnswers: ["Давлением, равным рабочему"],
      },
      {
        code: "20305154",
        text: "Какая установлена продолжительность дополнительных испытаний на герметичность для вновь сооружаемых газопроводов?",
        answers: [
          "Не менее 24 часов",
          "Не менее 36 часов",
          "Не менее 48 часов",
          "Не менее 72 часов",
        ],
        correctAnswers: ["Не менее 24 часов"],
      },
      {
        code: "20305155",
        text: "Какая устанавливается продолжительность периодических испытаний на герметичность для газопроводов, а также испытаний после ремонта, связанного со сваркой и разборкой газопровода?",
        answers: [
          "Не менее 4 часов",
          "Не менее 8 часов",
          "Не менее 12 часов",
          "Не менее 14 часов",
        ],
        correctAnswers: ["Не менее 4 часов"],
      },
      {
        code: "20305156",
        text: "При какой скорости падения давления за время дополнительного пневматического испытания на герметичность внутрицеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?",
        answers: [
          "Не более 0,01% в час",
          "Не более 0,1% в час",
          "Не более 0,15% в час",
          "Не более 0,2% в час",
        ],
        correctAnswers: ["Не более 0,1% в час"],
      },
      {
        code: "20305157",
        text: "При какой скорости падения давления за время дополнительного пневматического испытания на герметичность межцеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?",
        answers: [
          "Не более 0,01% в час",
          "Не более 0,1% в час",
          "Не более 0,15% в час",
          "Не более 0,2% в час",
        ],
        correctAnswers: ["Не более 0,2% в час"],
      },
      {
        code: "20305158",
        text: "У кого должен находиться агрегатный (ремонтный) журнал на отдельных объектах газового цеха, а также в цехах, в составе которых имеются объекты газового хозяйства?",
        answers: [
          "У руководителя организации",
          "У работника, ответственного за производственный контроль",
          "У работника, ответственного за техническое состояние данного объекта газового хозяйства",
        ],
        correctAnswers: [
          "У работника, ответственного за техническое состояние данного объекта газового хозяйства",
        ],
      },
      {
        code: "20305159",
        text: "При каком избыточном давлении газа в газопроводах разрешается производить электросварочные работы на газопроводах при ремонтах?",
        answers: [
          "Не более 0,05 кПа",
          "Не более 0,1 кПа",
          "Не более 0,2 кПа",
          "Не более 0,3 кПа",
        ],
        correctAnswers: ["Не более 0,3 кПа"],
      },
      {
        code: "20305160",
        text: "Какие действия должны быть произведены перед продувкой газопровода? Выберите правильный вариант ответа.",
        answers: [
          "Установить задвижки в нужное положение",
          "Залить водяные затворы водой",
          "Плотно закрыть все люки, лазы и свечи, за исключением той свечи, через которую будет производиться продувка",
          "Все перечисленные действия",
        ],
        correctAnswers: ["Все перечисленные действия"],
      },
      {
        code: "20305161",
        text: "В каком радиусе у мест выброса газовоздушной смеси в атмосферу должно быть приостановлено движение всех видов транспорта, запрещено применение открытого огня и производство сварочных работ в период продувки газопровода?",
        answers: ["25 м", "50 м", "75 м", "100 м"],
        correctAnswers: ["50 м"],
      },
      {
        code: "20305162",
        text: "В каком случае допускается использовать продувочные свечи газопроводов для выпуска в атмосферу избыточного газа?",
        answers: [
          "Ни в каких производственных случаях",
          "В аварийных случаях",
          "Во всех производственных случаях",
        ],
        correctAnswers: ["В аварийных случаях"],
      },
      {
        code: "20305163",
        text: "Какие сроки проведения ревизии газопроводов должны быть при скорости коррозии более 0,5 мм/г?",
        answers: [
          "Не реже двух раз в год",
          "Не реже одного раза в год",
          "Не реже одного раза в 2 года",
          "Не реже одного раза в 4 года",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20305164",
        text: "При какой скорости коррозии срок проведения ревизии газопроводов должен быть не реже одного раза в 2 года?",
        answers: ["Более 0,5 мм/г", "0,1 - 0,5 мм/г", "До 0,1 мм/г"],
        correctAnswers: ["0,1 - 0,5 мм/г"],
      },
      {
        code: "20305165",
        text: "Какой допускается максимальный подъем температуры газа в газопроводах?",
        answers: ["75 °C", "85 °C", "95 °C", "105 °C"],
        correctAnswers: ["85 °C"],
      },
      {
        code: "20305166",
        text: "В каком случае допускается подача газа без поднесения запальника, факела или другого воспламеняющего средства к горелке газопровода?",
        answers: [
          "Если температура кладки в разогретой зоне розжига факела превышает 800 °C",
          "Если температура кладки в разогретой зоне розжига факела превышает 700 °C",
          "Если температура кладки в разогретой зоне розжига факела превышает 600 °C",
          "Если температура кладки в разогретой зоне розжига факела превышает 500 °C",
        ],
        correctAnswers: [
          "Если температура кладки в разогретой зоне розжига факела превышает 800 °C",
        ],
      },
      {
        code: "20305167",
        text: "Кто осуществляет выбор типа быстродействующего отсекающего клапана на подводе газа к каждому агрегату с дутьевыми горелками?",
        answers: [
          "Строительно-монтажная организация",
          "Организация, проектирующая систему автоматики печи",
          "Сервисные службы",
          "Эксплуатирующая организация",
        ],
        correctAnswers: ["Организация, проектирующая систему автоматики печи"],
      },
      {
        code: "20305168",
        text: "Какое из перечисленных требований при эксплуатации газового оборудования газопотребляющих агрегатов указано верно?",
        answers: [
          "Допускается устанавливать один клапан на общем подводе газа к группе газопотребляющих агрегатов (не более двух) при их суммарной тепловой мощности до 42,6 * 108 кДж/ч",
          "Если газ при подаче через первую горелку не загорится или, загоревшись в процессе регулирования, погаснет, необходимо увеличить подачу газа",
          "Газ и воздух при зажигании и регулировании горелок необходимо подавать постепенно и только при установившемся давлении",
          "При увеличении нагрузки на горелку следует сначала увеличить подачу воздуха, затем газа",
        ],
        correctAnswers: [
          "Газ и воздух при зажигании и регулировании горелок необходимо подавать постепенно и только при установившемся давлении",
        ],
      },
      {
        code: "20305169",
        text: "С какой периодичностью должна производиться режимная наладка газоиспользующего оборудования?",
        answers: [
          "Не реже одного раза в год",
          "Не реже одного раза в два года",
          "Не реже одного раза в три года",
          "Не реже одного раза в четыре года",
        ],
        correctAnswers: ["Не реже одного раза в три года"],
      },
      {
        code: "20305170",
        text: "В каком случае допускается режимную наладку газоиспользующего оборудования проводить не реже одного раза в четыре года?",
        answers: [
          " Только в первый год работы газоиспользующего оборудования",
          "Только при специальном указании главного инженера производства",
          "Только при стабильной работе газоиспользующего оборудования",
          "Ни в одном из перечисленных случаев",
        ],
        correctAnswers: [
          "Только при стабильной работе газоиспользующего оборудования",
        ],
      },
      {
        code: "20305171",
        text: "С какой периодичностью должна производиться проверка технического состояния вентиляционных каналов в зданиях (помещениях) с установленным газоиспользующим оборудованием и пылеуборка, и дезинфекция вентиляционных каналов?",
        answers: [
          "Проверка технического состояния вентиляционных каналов -не реже двух раз в год, пылеуборка и дезинфекция вентиляционных каналов - не реже одного раза в три года",
          "Проверка технического состояния вентиляционных каналов -не реже одного раза в 2 года, пылеуборка и дезинфекция вентиляционных каналов - не реже двух раз в год",
          "Проверка технического состояния вентиляционных каналов -не реже одного раз в 3 года, пылеуборка и дезинфекция вентиляционных каналов - не реже трех раз в год",
          "Проверка технического состояния вентиляционных каналов -не реже двух раз в шесть месяцев, пылеуборка и дезинфекция вентиляционных каналов - не реже двух раз в год",
        ],
        correctAnswers: [
          "Проверка технического состояния вентиляционных каналов -не реже двух раз в год, пылеуборка и дезинфекция вентиляционных каналов - не реже одного раза в три года",
        ],
      },
      {
        code: "20305172",
        text: "С какой периодичностью должны производиться проверка технического состояния промышленных дымоотводящих устройств (газоходов газоиспользующего оборудования, дымоходов и дымовых труб) и их прочистка?",
        answers: [
          "Не реже двух раз в год",
          "Не реже трех раз в год",
          "Не реже четырех раз в год",
          "Не реже пяти раз в год",
        ],
        correctAnswers: ["Не реже двух раз в год"],
      },
      {
        code: "20305173",
        text: "На каких минимальных расстояниях (по горизонтали) до групповых газобаллонных установок допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?",
        answers: ["1,5 м", "3 м", "5 м", "10 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "20305174",
        text: "На каких минимальных расстояниях (по горизонтали) до отдельных баллонов с кислородом и горючими газами допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?",
        answers: ["1,5 м", "3 м", "5 м", "10 м"],
        correctAnswers: ["5 м"],
      },
      {
        code: "20305175",
        text: "В каком положении должны находиться баллоны со сжиженным газом во время работы?",
        answers: [
          "В вертикальном положении",
          "В горизонтальном положении",
          "В наклонном положении с вентилем, направленным вверх",
        ],
        correctAnswers: ["В вертикальном положении"],
      },
      {
        code: "20305176",
        text: "Какое действие должно быть осуществлено в первую очередь при зажигании ручной горелки или резака?",
        answers: [
          "Кратковременная продувка рукава для удаления воздуха",
          "Открытие вентиля горючего газа",
          "Небольшое приоткрытие вентиля кислорода",
          "Зажжение горючей смеси газов",
        ],
        correctAnswers: ["Небольшое приоткрытие вентиля кислорода"],
      },
      {
        code: "20305177",
        text: "Какое из перечисленных действий допускается при эксплуатации оборудования для газопламенной обработки металлов? Выберите правильный вариант ответа.",
        answers: [
          "При питании передвижного рабочего поста сжиженным газом от баллона отбирать газ из баллона при снижении в нем рабочего давления ниже требуемого не допускается",
          "Длина рукава, как правило, не должна превышать 30 м. Он должен состоять не более чем из трех отдельных кусков, соединенных между собой специальными двусторонними ниппелями",
          "При работе пламя горелки (резака) должно быть направлено в сторону, противоположную источнику газоснабжения",
          "Все перечисленные действия",
        ],
        correctAnswers: ["Все перечисленные действия"],
      },
      {
        code: "20305178",
        text: "Какое из перечисленных требований при эксплуатации взрывопожароопасных объектов указано неверно?",
        answers: [
          "В помещениях категории A проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией должна производиться в сроки, предусмотренные заводской инструкцией, но не реже одного раза в неделю",
          "На каждой двери помещения категории A должны быть указаны категория помещения и класс взрывоопасной зоны",
          "Работы по ремонту электрооборудования в помещениях категории A должны выполняться после обесточивания электросети",
          "В помещениях категории A хранение обтирочных и смазочных материалов допускается в количестве, не превышающем суточной потребности, в металлических, плотно закрывающихся шкафах или ящиках",
        ],
        correctAnswers: [
          "В помещениях категории A проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией должна производиться в сроки, предусмотренные заводской инструкцией, но не реже одного раза в неделю",
        ],
      },
      {
        code: "20305179",
        text: "С какой периодичностью должен производиться текущий ремонт оборудования с разборкой регуляторов давления, предохранительных клапанов и фильтров ГРП и ГРУ, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания?",
        answers: [
          " Не менее одного раза в год",
          " Не менее двух раза в год",
          " Не менее трех раза в год",
          " Не менее четырех раза в год",
        ],
        correctAnswers: [" Не менее одного раза в год"],
      },
      {
        code: "20305180",
        text: "При каком давлении должна производиться настройка срабатывания предохранительно-запорных клапанов при их наличии в ГРП (ГРУ)?",
        answers: [
          "При давлении на 15% больше расчетного",
          "При давлении на 25% больше расчетного",
          "При давлении на 15% меньше расчетного",
          "При давлении на 25% меньше расчетного",
        ],
        correctAnswers: ["При давлении на 15% больше расчетного"],
      },
      {
        code: "20305181",
        text: "В каком случае допускается подача потребителям газа по обводной линии (байпасу) ГРП и ГРУ?",
        answers: [
          "Только на время ревизии оборудования",
          "Только на время ремонта оборудования",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20305182",
        text: "Какие виды работ должны выполняться не реже одного раза в 6 месяцев при эксплуатации ГРП и ГРУ?",
        answers: [
          "Проверка параметров срабатывания предохранительных запорных и сбросных клапанов",
          "Техническое обслуживание",
          "Текущий ремонт",
          "Капитальный ремонт при замене оборудования, средств измерений",
        ],
        correctAnswers: ["Техническое обслуживание"],
      },
      {
        code: "20305183",
        text: "Какие из перечисленных видов работ не должны выполняться при техническом обслуживании технологического оборудования ГРП и ГРУ? Выберите 2 варианта ответа.",
        answers: [
          "Восстановление окраски шкафов пунктов редуцирования газа, ограждений, газопроводов обвязки и технических устройств",
          "Проверка соответствия параметров настройки предохранительной и защитной арматуры режимной карте",
          "Замена изношенных деталей технических устройств",
          "Проверка работоспособности запорной арматуры",
        ],
        correctAnswers: [
          "Восстановление окраски шкафов пунктов редуцирования газа, ограждений, газопроводов обвязки и технических устройств",
          "Замена изношенных деталей технических устройств",
        ],
      },
      {
        code: "20305184",
        text: "В какие сроки должен проводиться капитальный ремонт ГРП и ГРУ?",
        answers: [
          "В сроки, установленные руководителем организации",
          "В сроки, установленные изготовителем",
          "В сроки, установленные работником, ответственным за выполнение работ",
          "В сроки, установленные в технологической инструкции",
        ],
        correctAnswers: ["В сроки, установленные изготовителем"],
      },
      {
        code: "20305185",
        text: "Чем должна производиться продувка импульсных газовых проводок?",
        answers: [
          "Только паром",
          "Только сжатым воздухом",
          "Только газом",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20305186",
        text: "Каким должно быть минимально допустимое давление газа непосредственно перед потребителем (после регулирующих приборов)?",
        answers: ["0,5 кПа", "0,7 кПа", "0,9 кПа", "1,5 кПа"],
        correctAnswers: ["0,5 кПа"],
      },
      {
        code: "20305187",
        text: "Какой предел огнестойкости должен быть у стен, разделяющих взрывоопасные помещения на водородных станциях?",
        answers: ["1 час", "1,5 часа", "2 часа", "2,5 часа"],
        correctAnswers: ["2,5 часа"],
      },
      {
        code: "20305188",
        text: "Какая должна быть минимальная высота забора по периметру территории всего комплекса производства водорода?",
        answers: ["1,5 м", "2 м", "2,5 м", "3 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "20305189",
        text: "Какое должно быть расстояние между ресиверами одного газа?",
        answers: [
          "Не менее 1,5 м в свету",
          "Не менее 2 м в свету",
          "Не менее 2,5 м в свету",
          "Не менее 3 м в свету",
        ],
        correctAnswers: ["Не менее 1,5 м в свету"],
      },
      {
        code: "20305190",
        text: "Какие требования к ресиверам для водорода (сосудам) указаны неверно?",
        answers: [
          "Расстояние от ресиверов (сосудов) с водородом до ограждения должно быть не менее 2 м",
          "Ресиверы для водорода (сосуды) размещаются на открытых площадках, имеющих по периметру ограждение легкого типа высотой не менее 1,2 м из несгораемого материала",
          'На ресиверах должны быть поясняющие надписи: "Водород. Взрывоопасно"',
          "Все перечисленные",
        ],
        correctAnswers: [
          "Расстояние от ресиверов (сосудов) с водородом до ограждения должно быть не менее 2 м",
        ],
      },
      {
        code: "20305191",
        text: "Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей?",
        answers: [
          "Только системами регулирования заданного соотношения топлива, воздуха и водяного пара",
          "Только средствами сигнализации о прекращении поступления топлива, а также воздуха при его принудительной подаче в топочное пространство",
          "Только падением давления нагреваемого продукта на выходе из печи ниже регламентированного значения",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20305192",
        text: "Какие требования из перечисленных должны предусматриваться для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты? Выберите правильный вариант ответа.",
        answers: [
          "Только дистанционное отключение насосов",
          "Только установка на линиях всасывания запорных или отсекающих устройств",
          "Только установка на линиях нагнетания запорных или отсекающих устройств",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20305193",
        text: "В каком случае все технологическое оборудование должно продуваться инертным газом? Выберите 2 варианта ответа.",
        answers: [
          "После остановки более чем на 2 часа",
          "Перед пуском",
          "После остановки более чем на час",
          "В случае, если технологическое оборудование в период остановки находилось под избыточным давлением водорода",
        ],
        correctAnswers: ["После остановки более чем на 2 часа", "Перед пуском"],
      },
      {
        code: "20305194",
        text: "При каком содержании водорода в воздухе производственного помещения по сигналу от автоматического газоанализатора технологическое оборудование этого помещения должно быть остановлено?",
        answers: [
          "Выше 45% нижнего предела взрываемости",
          "Выше 35% нижнего предела взрываемости",
          "Выше 25% нижнего предела взрываемости",
          "Выше 15% нижнего предела взрываемости",
        ],
        correctAnswers: ["Выше 25% нижнего предела взрываемости"],
      },
      {
        code: "20305195",
        text: "При каком содержании газов должна срабатывать световая и звуковая сигнализации, установленные с автоматическими газоанализаторами в помещениях категории А, где обращается водород?",
        answers: [
          "При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м3, метана не более 10% нижнего предела взрываемости",
          "При содержании водорода в воздухе помещения не более 9% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м3, метана не более 9% нижнего предела взрываемости",
          "При содержании водорода в воздухе помещения не более 7% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м3 , метана не более 7% нижнего предела взрываемости",
          "При содержании водорода в воздухе помещения не более 5% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м3 , метана не более 5% нижнего предела взрываемости",
        ],
        correctAnswers: [
          "При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м3, метана не более 10% нижнего предела взрываемости",
        ],
      },
      {
        code: "20305196",
        text: "Какие требования к вентиляции помещения, где возможно выделение водорода, указаны верно? Выберите 2 варианта ответа.",
        answers: [
          "Помещения, где возможно выделение водорода, оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час",
          "Для проветривания застойных участков в площадках должны предусматриваться проемы, закрытые в необходимых случаях решетками",
          "В помещениях устанавливаются аварийная вытяжная система",
          "В помещениях устанавливаются механические приточно-вытяжные общеобменные вентиляции с кратностью воздухообмена не менее 6 в час",
        ],
        correctAnswers: [
          "Помещения, где возможно выделение водорода, оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час",
          "Для проветривания застойных участков в площадках должны предусматриваться проемы, закрытые в необходимых случаях решетками",
        ],
      },
      {
        code: "20305197",
        text: "Какие из перечисленных ограждающих конструкций не относятся к легкосбрасываемым?",
        answers: [
          "Конструкции асбоцементных, алюминиевых и стальных листов",
          "Оконные переплеты заполнены обычным оконным стеклом толщиной 3, 4 и 5 мм, площадью не менее соответственно 0,8, 1 и 1,5 м2",
          "Фонарные переплеты",
          "Противопожарные двери",
        ],
        correctAnswers: ["Противопожарные двери"],
      },
      {
        code: "20305198",
        text: "Какое минимальное остаточное давление должны иметь баллоны, подготовленные для наполнения водородом?",
        answers: ["0,05 МПа", "0,07 МПа", "0,09 МПа", "0,5 МПа"],
        correctAnswers: ["0,05 МПа"],
      },
      {
        code: "20305199",
        text: "На какой высоте от пола должны быть расположены окна в помещениях отделений наполненных баллонов, наполнительных и разрядных рамп?",
        answers: [
          "Не менее 1,5 м",
          "Не менее 2,0 м",
          "Не менее 2,5 м",
          "Не менее 3,0 м",
        ],
        correctAnswers: ["Не менее 1,5 м"],
      },
      {
        code: "20305200",
        text: "Какой должна быть ширина свободного прохода между щитом управления вентилями и ближайшими стенами наполнительной?",
        answers: [
          "Не менее 0,8 м",
          "Не менее 1,0 м",
          "Не менее 1,2 м",
          "Не менее 1,8 м",
        ],
        correctAnswers: ["Не менее 0,8 м"],
      },
      {
        code: "20305201",
        text: "Стальными листами какой толщины должны отделяться от рабочей зоны баллоны с водородом при их горизонтальном расположении и размещении внутри помещения?",
        answers: [
          "Не менее 10 мм",
          "Не менее 12 мм",
          "Не менее 14 мм",
          "Не менее 16 мм",
        ],
        correctAnswers: ["Не менее 16 мм"],
      },
      {
        code: "20305202",
        text: "При какой чистоте водорода должен производиться пуск компрессора для наполнения баллонов?",
        answers: [
          "Не менее 99,7%",
          "Не менее 99,9%",
          "Не менее 97,9%",
          "Не менее 97,7%",
        ],
        correctAnswers: ["Не менее 99,7%"],
      },
      {
        code: "20305203",
        text: "Какие устройства должны быть установлены на нагнетательном газопроводе для отключения компрессора от коллектора высокого давления?",
        answers: [
          "Обратный клапан и два запорных вентиля, между которыми - свеча с условным проходом не менее 6 мм, имеющая прямое сообщение с атмосферой",
          "Только запорный вентиль",
          "Только обратный клапан",
        ],
        correctAnswers: [
          "Обратный клапан и два запорных вентиля, между которыми - свеча с условным проходом не менее 6 мм, имеющая прямое сообщение с атмосферой",
        ],
      },
      {
        code: "20305204",
        text: "На какой ступени сжатия водородные компрессоры должны иметь предохранительные клапаны?",
        answers: [
          "На всех ступенях сжатия",
          "Только на первой ступени сжатия",
          "Только на второй ступени сжатия",
        ],
        correctAnswers: ["На всех ступенях сжатия"],
      },
      {
        code: "20305205",
        text: "Какой технической документацией должен быть оснащен каждый компрессорный агрегат или установка, или группа однородных компрессорных установок?",
        answers: [
          "Паспортом (формуляром) на компрессорную установку",
          "Схемой трубопроводов",
          "Журналом учета работы компрессора",
          "Всей перечисленной документацией",
        ],
        correctAnswers: ["Всей перечисленной документацией"],
      },
      {
        code: "20305206",
        text: "Какие требования к компрессорным установкам указаны неверно?",
        answers: [
          "Качество изготовления компрессорных установок должно соответствовать требованиям нормативно-технической документации и документации организации-изготовителя.",
          "Компрессор и его электродвигатель должны устанавливаться на фундаментах, не связанных с конструкциями здания",
          "Для уменьшения динамических нагрузок компрессоры должны устанавливаться на виброизолирующих фундаментах или с устройством амортизаторов (антивибраторов) либо должны приниматься другие меры для уменьшения вибраций",
          "Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь",
        ],
        correctAnswers: [
          "Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь",
        ],
      },
      {
        code: "20305207",
        text: "Какие требования к компрессорным установкам указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "В помещении компрессорной установки следует предусматривать специальные места для хранения в закрытом виде обтирочных материалов, инструмента, прокладок, а также для хранения недельного запаса масла",
          "Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи",
          "Не допускается хранение ЛВЖ в помещении машинного зала компрессорной установки",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20305208",
        text: "Какому классу должна соответствовать герметичность арматуры с металлическим уплотнением в затворе, применяемая для установки на трубопроводах водорода?",
        answers: ["Классу А", "Классу Б", "Классу В", "Классу Г"],
        correctAnswers: ["Классу В"],
      },
      {
        code: "20305209",
        text: "Какому классу должна соответствовать герметичность затвора запорной арматуры со средой водорода?",
        answers: ["Классу А", "Классу Б", "Классу В", "Классу Г"],
        correctAnswers: ["Классу А"],
      },
      {
        code: "20305210",
        text: "Какую арматуру не допускается применять на водородопроводах?",
        answers: ["Из серого чугуна", "Из стали", "Из алюминия", "Из меди"],
        correctAnswers: ["Из серого чугуна"],
      },
      {
        code: "20305211",
        text: "В соответствии с требованиями какой документации должны производиться проектирование, монтаж и эксплуатация водородопроводов, а также вспомогательных трубопроводов обвязки участков комплекса получения и потребления водорода?",
        answers: [
          "Строительных норм и правил",
          "Нормативно-технической документации для технологических стальных трубопроводов",
          "Правил безопасности процессов получения или применения металлов",
          "Всей перечисленной документации",
        ],
        correctAnswers: ["Всей перечисленной документации"],
      },
      {
        code: "20305212",
        text: "Где не допускается размещение операторных помещений?",
        answers: [
          "В отдельных щитовых помещениях (встроенных или пристроенных), смежных с помещениями с взрывоопасными зонами",
          "Над помещениями с взрывоопасными зонами любого класса, а также под душевыми, санузлами, под производственными помещениями с мокрым технологическим процессом, под приточными вентиляционными камерами",
          "В отдельно стоящих зданиях",
        ],
        correctAnswers: [
          "Над помещениями с взрывоопасными зонами любого класса, а также под душевыми, санузлами, под производственными помещениями с мокрым технологическим процессом, под приточными вентиляционными камерами",
        ],
      },
      {
        code: "20305213",
        text: "Какие требования к монтажу и эксплуатации водородопроводов указаны неверно?",
        answers: [
          "Проектирование, монтаж и эксплуатация водородопроводов, должны производиться в соответствии с требованиями строительных норм и правил, а также нормативно-технической документации для технологических стальных трубопроводов и Правил безопасности процессов получения или применения металлов",
          "При прокладке трубопроводов водорода следует, как правило, использовать бесшовные стальные трубы или трубопроводы из нержавеющей стали, соединенные с применением сварки",
          "Водородопроводы в пределах предприятий должны прокладываться преимущественно над поверхностью земли, на стойках и эстакадах. Допускается в виде исключения совместная прокладка не более четырех водородопроводов",
          "Подземная и канальная прокладка трубопроводов водорода не допускается",
        ],
        correctAnswers: [
          "Водородопроводы в пределах предприятий должны прокладываться преимущественно над поверхностью земли, на стойках и эстакадах. Допускается в виде исключения совместная прокладка не более четырех водородопроводов",
        ],
      },
      {
        code: "20305214",
        text: "Какая продолжительность пневматических испытаний установлена Правилами безопасности процессов получения или применения металлов после проведения плановых или аварийных остановок водородопроводов?",
        answers: ["0,5 часа", "1 час", "1,5 часа", "2 часа"],
        correctAnswers: ["1 час"],
      },
      {
        code: "20305215",
        text: "Через какое время после ввода объекта в эксплуатацию должна проводиться первая выборочная ревизия водородопроводов на вновь осваиваемых производствах?",
        answers: [
          "Не позднее чем через 1 год",
          "Не позднее чем через 2 года",
          "Не позднее чем через 3 года",
          "Не позднее чем через 4 года",
        ],
        correctAnswers: ["Не позднее чем через 4 года"],
      },
      {
        code: "20305216",
        text: "С какой периодичностью должны проводиться испытания на плотность и прочность холодных участков (с температурой до 200 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?",
        answers: [
          "Один раз в 8 лет",
          "Один раз в 5 лет",
          "Один раз в 4 года",
          "Один раз в 2 года",
        ],
        correctAnswers: ["Один раз в 8 лет"],
      },
      {
        code: "20305217",
        text: "С какой периодичностью должны проводиться испытания на плотность и прочность горячих участков (с температурой 200 - 400 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?",
        answers: [
          "Один раз в 8 лет",
          "Один раз в 5 лет",
          "Один раз в 3 года",
          "Один раз в 2 года",
        ],
        correctAnswers: ["Один раз в 5 лет"],
      },
      {
        code: "20305218",
        text: "В каких местах допускается прокладка водородопроводов?",
        answers: [
          "В пределах предприятий преимущественно над поверхностью земли, на стойках и эстакадах",
          "Через бытовые, подсобные административно-хозяйственные, складские помещения",
          "Через производственные помещения, не связанные с потреблением водорода",
        ],
        correctAnswers: [
          "В пределах предприятий преимущественно над поверхностью земли, на стойках и эстакадах",
        ],
      },
      {
        code: "20305219",
        text: "Через какие помещения допускается прокладка водородопроводов?",
        answers: [
          "Через бытовые помещения",
          "Через подсобные административно-хозяйственные, складские помещения",
          "Через производственные помещения, связанные с потреблением водорода",
        ],
        correctAnswers: [
          "Через производственные помещения, связанные с потреблением водорода",
        ],
      },
      {
        code: "20305220",
        text: "Какие требования к размещению запорной арматуры на водородопроводах указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Запорная арматура и отсекающие устройства с дистанционным управлением должны устанавливаться вне здания на расстоянии не менее 3,0 м и не более 50,0 м от места ввода в здание или ближайшего аппарата, стоящего вне здания",
          "Арматуру, работающую в среде водорода, следует выбирать либо специальную для водорода, либо стальную для взрывоопасных сред",
          "В отдельных случаях допускается применение запорной арматуры из ковкого и высокопрочного чугуна при давлении не более 1,0 МПа и температуре от 0 °C до 50 °C, работающей в условиях, не подверженных вибрациям и резко переменного температурного режима",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20305221",
        text: "Какой должна быть объемная доля кислорода в воздухе производственных помещений производства ПРВ?",
        answers: [
          "Не менее 19% и не более 23%",
          "Не менее 18% и не более 23%",
          "Не менее 21% и не более 25%",
        ],
        correctAnswers: ["Не менее 19% и не более 23%"],
      },
      {
        code: "20305222",
        text: "Что необходимо устанавливать в производственных помещениях, где возможны воспламенение одежды или химические ожоги у работников?",
        answers: [
          "Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
          "Сатураторные установки",
          "Пункты хранения одежды",
        ],
        correctAnswers: [
          "Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
      },
      {
        code: "20305223",
        text: "Какие требования установлены к оборудованию производственных помещений, в которых возможны воспламенения одежды или химические ожоги?",
        answers: [
          "Должны быть обозначены места хранения аптечек",
          "Должны быть установлены сатураторные установки",
          "Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
        correctAnswers: [
          "Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
      },
      {
        code: "20305224",
        text: "Какие требования установлены к инструментам и приспособлениям, используемым во взрывопожароопасных зонах и помещениях?",
        answers: [
          "Должны быть изготовлены из прочной стали",
          "Не должны давать искры при работе с ними",
          "Инструмент должен быть в диэлектрическом исполнении",
        ],
        correctAnswers: ["Не должны давать искры при работе с ними"],
      },
      {
        code: "20305225",
        text: "После установления каких показателей температуры разрешается проводить ремонтные работы внутри нагретых технических устройств, а также низкотемпературных технических устройств?",
        answers: [
          "После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания",
          "После снижения температуры воздуха до 45 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания",
          "После снижения температуры воздуха до 50 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания",
        ],
        correctAnswers: [
          "После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания",
        ],
      },
      {
        code: "20305226",
        text: "Какие требования установлены к проведению ремонтных работ внутри нагретых технических устройств?",
        answers: [
          "Проведение ремонтных работ разрешается после их продувки и снижения температуры воздуха в них до 55 °C",
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 50 °C",
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C",
        ],
        correctAnswers: [
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C",
        ],
      },
      {
        code: "20305227",
        text: "При каких условиях допускается перенос сроков проведения капитальных ремонтов основного технологического оборудования?",
        answers: [
          "При наличии положительного заключения экспертного обследования",
          "При наличии разрешения завода-изготовителя",
          "По решению руководителя предприятия",
        ],
        correctAnswers: [
          "При наличии положительного заключения экспертного обследования",
        ],
      },
      {
        code: "20305228",
        text: "Каким образом должен проводиться отогрев трубопроводной арматуры?",
        answers: [
          "С применением открытого пламени",
          "Горячим воздухом, паром или горячей водой",
          "С применением электрических приборов",
        ],
        correctAnswers: ["Горячим воздухом, паром или горячей водой"],
      },
      {
        code: "20305229",
        text: "Как часто должен производиться осмотр трубопроводов кислорода?",
        answers: [
          "Не реже одного раза в 2 месяца",
          "Не реже одного раза в месяц",
          "Не реже одного раза в полгода",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20305230",
        text: "Как часто должен производиться проверка конденсатоотводчиков и пароспутников, обогревающих трубопроводы кислорода в зимнее время?",
        answers: ["Ежедневно", "Два раза в смену", "Один раз в неделю"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "20305231",
        text: "Как часто должна проводиться проверка правильности показаний приборов, перечень которых утверждается руководителем производства?",
        answers: [
          "Не реже одного раза в шесть месяцев",
          "Не реже одного раза в месяц",
          "Не реже одного раза в 3 месяца",
        ],
        correctAnswers: ["Не реже одного раза в 3 месяца"],
      },
      {
        code: "20305232",
        text: "Какой установлен порядок хранения обтирочных материалов?",
        answers: [
          "В чистых пластиковых контейнерах",
          "В чистых металлических ящиках с крышками",
          "В специально выделенном помещении",
        ],
        correctAnswers: ["В чистых металлических ящиках с крышками"],
      },
      {
        code: "20305233",
        text: "Из каких материалов не могут быть изготовлены разделители (заглушки) или другие изделия, помещаемые внутри кислородопровода при его обезжиривании?",
        answers: [
          "Из поролона или других органических материалов",
          "Из меди",
          "Из нержавеющей стали",
        ],
        correctAnswers: ["Из поролона или других органических материалов"],
      },
      {
        code: "20305234",
        text: "Из каких сплавов металлов запрещается установка арматуры на кислородопроводах?",
        answers: ["Из сплавов титана", "Из стали", "Из чугуна"],
        correctAnswers: ["Из сплавов титана"],
      },
      {
        code: "20305235",
        text: "Чем производится отогрев смерзшейся изоляции для обеспечения доступа к адсорберам?",
        answers: [
          "Сухим подогретым воздухом",
          "Водо-воздушной смесью",
          "Теплой водой",
          "Открытым пламенем",
        ],
        correctAnswers: ["Сухим подогретым воздухом"],
      },
      {
        code: "20305236",
        text: "Какие из перечисленных ремонтных работ допускается производить одновременно при ремонте арматуры, расположенной на теплом и холодном концах регенераторов в период остановки ВРУ?",
        answers: [
          "Ремонт трубопроводов и арматуры теплого и холодного концов регенераторов",
          'Ремонт трубопроводов и арматуры теплого или холодного конца регенераторов и системы "приказного" воздуха переключения регенераторов, или механизма переключения',
          "Ремонт принудительных клапанов переключения регенераторов и трехходовых заслонок после регенераторов",
          "Одновременное проведение ремонтных работ не допускается",
        ],
        correctAnswers: [
          "Одновременное проведение ремонтных работ не допускается",
        ],
      },
      {
        code: "20305237",
        text: "С каким интервалом должна проверяться объемная доля кислорода в месте проведения ремонтных работ во время нахождения персонала внутри трубопроводов, опорных обечаек регенераторов, в отсеке обратноповоротных клапанов или арматуры?",
        answers: [
          "Не менее чем 10 минут",
          "Не менее чем 15 минут",
          "Не менее чем 20 минут",
          "Не менее чем 25 минут",
        ],
        correctAnswers: ["Не менее чем 10 минут"],
      },
      {
        code: "20305238",
        text: "Какие действия необходимо осуществить перед ремонтом клапанов (трубопроводов) теплого и холодного концов регенераторов в период остановки ВРУ? Выберите правильный вариант ответа.",
        answers: [
          "Перевести на ручной режим систему автоматического управления регенераторов",
          "Закрыть или открыть соответствующие клапаны с помощью дистанционного управления",
          'Установить предупреждающий знак безопасности "Не включать, работают люди!"',
          "Все перечисленные действия",
        ],
        correctAnswers: ["Все перечисленные действия"],
      },
      {
        code: "20305239",
        text: "При каком условии ВРУ должна подвергаться обезжириванию?",
        answers: [
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше",
          "При достижении концентрации масла в жидком кислороде 0,30 мг/дм и выше",
          "При достижении концентрации масла в жидком кислороде 0,20 мг/дм и выше",
          "При достижении концентрации масла в жидком кислороде 0,10 мг/дм и выше",
        ],
        correctAnswers: [
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше",
        ],
      },
      {
        code: "20305240",
        text: "Какие установлены сроки обезжиривания ВРУ, работающих по схеме высокого и среднего давлений с блоками комплексной очистки на цеолитах и турбодетандерами, а также по схеме низкого давления?",
        answers: [
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в 2 месяца",
          "Не реже одного раза в месяц",
          "Сроки обезжиривания ВРУ не регламентируются",
        ],
        correctAnswers: ["Сроки обезжиривания ВРУ не регламентируются"],
      },
      {
        code: "20305241",
        text: "Что из перечисленного не подлежит обезжириванию в процессе эксплуатации ВРУ? Выберите правильный вариант ответа.",
        answers: [
          "Аппараты и коммуникации на потоке воздуха от поршневого детандера и от дожимающего поршневого компрессора",
          "Регенераторы (при переработке воздуха, сжимаемого компрессорами со смазываемыми цилиндрами)",
          "Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа",
          "Аппараты и коммуникации на потоке газообразного кислорода высокого давления",
        ],
        correctAnswers: [
          "Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа",
        ],
      },
      {
        code: "20305242",
        text: "В каком случае арматура, предназначенная для работы с кислородом, не подлежит обезжириванию перед монтажом?",
        answers: [
          "В случае, если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка",
          "В случае, если детали были подвергнуты охлаждению жидким азотом",
          "В случае, если арматура является вновь устанавливаемой",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: [
          "В случае, если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка",
        ],
      },
      {
        code: "20305243",
        text: "Какое из перечисленных требований при необходимости проведения обезжиривания технических устройств и трубопроводов ПРВ растворителями указано неверно?",
        answers: [
          "Доступ в помещение, где хранятся растворители, разрешается только лицам, допущенным к работе с ними",
          "Перелив растворителей из одного сосуда в другой допускается только открытым способом",
          "Тару из-под растворителей необходимо плотно закрывать и хранить только в предназначенном для этого помещении или на открытом воздухе",
          "Проливы растворителей на пол не допускаются; случайно пролитый растворитель должен быть немедленно убран с помощью сухих материалов",
        ],
        correctAnswers: [
          "Перелив растворителей из одного сосуда в другой допускается только открытым способом",
        ],
      },
      {
        code: "20305244",
        text: "До какой максимальной температуры допускается нагрев поверхностей в помещениях, где производится обезжиривание кислородного оборудования пожаровзрывоопасными растворителями?",
        answers: ["80 °C", "100 °C", "120 °C", "140 °C"],
        correctAnswers: ["120 °C"],
      },
      {
        code: "20305245",
        text: "До какой температуры должен отогреваться сосуд, бывший в эксплуатации, перед проведением работ по обезжириванию способом протирки и чем он должен продуваться?",
        answers: [
          "До температуры не ниже 20 °C и продуваться воздухом",
          "До температуры не ниже 40 °C и продуваться воздухом",
          "До температуры не ниже 20 °C и продуваться водородом",
          "До температуры не ниже 40 °C и продуваться азотом",
        ],
        correctAnswers: ["До температуры не ниже 20 °C и продуваться воздухом"],
      },
      {
        code: "20305246",
        text: "Какое из перечисленных требований к потреблению газообразного кислорода и других продуктов разделения воздуха указано неверно?",
        answers: [
          "Использование ПРВ по каждому производству, участку или объекту должно осуществляться по технологическим инструкциям",
          "Для ведения надзора за безопасной эксплуатацией технических устройств и коммуникаций, связанных с потреблением ПРВ, назначаются ответственные лица из числа специалистов",
          "На кислородопроводах не допускается установка арматуры из сплавов на основе титана",
          "Кислородная арматура для присоединения рукавов должна быть размещена в металлическом шкафу с отверстиями для проветривания. Дверцы шкафа при выполнении работы должны быть закрыты",
        ],
        correctAnswers: [
          "Кислородная арматура для присоединения рукавов должна быть размещена в металлическом шкафу с отверстиями для проветривания. Дверцы шкафа при выполнении работы должны быть закрыты",
        ],
      },
    ],
    20306: [
      {
        code: "20306000",
        text: "В какой зоне не допускается нахождение людей?",
        answers: [
          "На расстоянии 15 м от зоны работы магнитного крана в случае, если кран работает внутри производственного здания",
          "На расстоянии 20 м от зоны работы грейферного крана в случае, если кран работает внутри производственного здания",
          "В зоне погрузки грейферными или магнитными кранами",
          "В зоне погрузки только грейферными кранами",
        ],
        correctAnswers: ["В зоне погрузки грейферными или магнитными кранами"],
      },
      {
        code: "20306001",
        text: "Какие требования установлены к установке расходных баков с мазутом?",
        answers: [
          "Должны быть установлены на расстоянии не менее 15 м от печей и должны быть ограждены предупреждающими знаками",
          "Должны быть установлены на расстоянии не менее 10 м от печей и должны быть защищены паровой завесой",
          "Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением",
        ],
        correctAnswers: [
          "Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением",
        ],
      },
      {
        code: "20306002",
        text: "Какие требования установлены к загружаемым в печи руде, ферросплавам и другим материалам?",
        answers: [
          "Должны быть тщательно очищены и промыты",
          "Должны быть предварительно просушены или прокалены",
          "Должны быть выдержаны в помещении не менее 1 суток",
        ],
        correctAnswers: ["Должны быть предварительно просушены или прокалены"],
      },
      {
        code: "20306003",
        text: "Что не допускается к загрузке в агрегаты, содержащие расплавленный металл или шлак?",
        answers: [
          "Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено технологической инструкцией",
          "Загрузка шихты и материалов, не прошедших пиротехнического контроля",
          "Загрузка шихты и материалов с количеством влаги ниже, чем предусмотрено производственной инструкцией",
        ],
        correctAnswers: [
          "Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено технологической инструкцией",
        ],
      },
      {
        code: "20306004",
        text: "Каков допустимый износ цапф ковшей во всех измерениях?",
        answers: [
          "Не должен превышать 10% первоначальных размеров",
          "Не должен превышать 12% первоначальных размеров",
          "Не должен превышать 15% первоначальных размеров",
        ],
        correctAnswers: ["Не должен превышать 10% первоначальных размеров"],
      },
      {
        code: "20306005",
        text: "Как часто цапфы ковшей должны проверяться методом неразрушающего контроля?",
        answers: [
          "Не реже одного раза в полугодие",
          "Не реже одного раза в год",
          "Не реже одного раза в квартал",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20306006",
        text: "Что должно иметься на шлаковом отвале?",
        answers: [
          "Двусторонняя громкоговорящая связь с диспетчерской службой",
          "Телефонная или радиосвязь с диспетчерской службой производства",
          "Мобильная связь с диспетчерской службой предприятия",
        ],
        correctAnswers: [
          "Телефонная или радиосвязь с диспетчерской службой производства",
        ],
      },
      {
        code: "20306007",
        text: "Что должно быть исключено в коммуникациях системы транспортирования взрывопожароопасных веществ и материалов?",
        answers: [
          "Попадание влаги",
          "Вероятность разгерметизации системы",
          "Образование взрывоопасной среды",
        ],
        correctAnswers: ["Образование взрывоопасной среды"],
      },
      {
        code: "20306008",
        text: "Какие требования установлены к кабине завалочной машины?",
        answers: [
          "Должна быть удобной и безопасной в эксплуатации",
          "Должна быть шумоизолированной и иметь устойчивое положение",
          "Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака",
        ],
        correctAnswers: [
          "Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака",
        ],
      },
      {
        code: "20306009",
        text: "Что должно быть предусмотрено при проектировании конвейеров для подачи материалов? Выберите правильный вариант ответа.",
        answers: [
          "Только ограждения приводных, натяжных и отклоняющих барабанов, натяжных устройств, канатов и блоков натяжных устройств, ременных, червячных, муфтовых и других передач",
          "Только предохраняющие устройства, отключающие приводы при перегрузке",
          "Только исключение падения транспортируемых материалов",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20306010",
        text: "Какие требования установлены к фурмам для продувки жидкого металла газами?",
        answers: [
          "Должны быть подогреты в специальной печи до температуры, регламентированной производственной инструкцией, и должны иметь цилиндрическую форму",
          "Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями",
          "Должны быть пористыми и должны иметь специальный экран от нагревания теплоизлучением",
        ],
        correctAnswers: [
          "Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями",
        ],
      },
      {
        code: "20306011",
        text: "Какие требования установлены к инструментам, приспособлениям и оснастке, используемым для ведения технологии или обслуживания оборудования?",
        answers: [
          "Должны быть из цветных металлов, и должна быть возможность применять их для выполнения любых работ",
          "Должны находиться в свободном доступе и храниться в специально отведенном месте",
          "Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними",
        ],
        correctAnswers: [
          "Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними",
        ],
      },
      {
        code: "20306012",
        text: "При каких условиях запрещается эксплуатация плавильных агрегатов?",
        answers: [
          "При разгерметизации системы водяного охлаждения этих агрегатов",
          "При допустимом падении давления воды в системе охлаждения",
          "При минимальном повышении давления воды в системе охлаждения",
        ],
        correctAnswers: [
          "При разгерметизации системы водяного охлаждения этих агрегатов",
        ],
      },
      {
        code: "20306013",
        text: "Чем должны быть оборудованы шлаковозы?",
        answers: [
          "Механизмами кантования (поворота) чаши с ручным гидравлическим приводом и автоматическим управлением",
          "Механизмами кантования (поворота) чаши с ручным пневматическим приводом и механическим управлением",
          "Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением",
        ],
        correctAnswers: [
          "Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением",
        ],
      },
      {
        code: "20306014",
        text: "Какие шпалы должны применяться на железнодорожных путях шлакового отвала?",
        answers: [
          "Деревянные шпалы, пропитанные креозотом",
          "Любые деревянные шпалы",
          "Огнестойкие шпалы",
        ],
        correctAnswers: ["Огнестойкие шпалы"],
      },
      {
        code: "20306015",
        text: "Чем должны быть оборудованы места пересечения железнодорожных путей для подачи составов ковшей с жидким чугуном с путями для подачи шихтовых материалов?",
        answers: [
          "Должны быть оборудованы светофорами",
          "Должны быть оборудованы предупреждающими знаками",
          "Должны быть оборудованы автоматической сигнализацией",
        ],
        correctAnswers: [
          "Должны быть оборудованы автоматической сигнализацией",
        ],
      },
      {
        code: "20306016",
        text: "Какой запас прочности должны иметь цапфы ковшей, предназначенных для расплава металла?",
        answers: [
          "Не менее восьмикратного запаса прочности",
          "Не менее шестикратного запаса прочности",
          "Не менее пятикратного запаса прочности",
        ],
        correctAnswers: ["Не менее восьмикратного запаса прочности"],
      },
      {
        code: "20306017",
        text: "Что должны иметь закрома и ямы?",
        answers: [
          "Со всех сторон должны иметь ограждение",
          "Должны иметь по периметру пешеходные проходы шириной 0,8 м",
          "Должны иметь по периметру пешеходные проходы шириной 1 м",
        ],
        correctAnswers: ["Со всех сторон должны иметь ограждение"],
      },
      {
        code: "20306018",
        text: "Какое требование к устройству внутренних производственных помещений, находящихся на производственной площадке, включающей технологию с расплавами металлов, имеющей опасность аварии или травмирования работников, указано неверно?",
        answers: [
          "Окна пультов управления, в которые возможно попадание брызг расплава, должны оборудоваться в соответствии с проектом",
          "Помещения должны иметь не менее 2 выходов, расположенных с учетом аварийной эвакуации работников",
          "Помещения пультов управления должны иметь вытяжную вентиляцию",
          "Помещения должны иметь защиту от воздействия теплового излучения",
        ],
        correctAnswers: [
          "Помещения пультов управления должны иметь вытяжную вентиляцию",
        ],
      },
      {
        code: "20306019",
        text: "Как должна устраняться пробуксовка ленты конвейера?",
        answers: [
          "Должна устраняться увеличением трения между лентой и тянущим барабаном с использованием специальных материалов",
          "Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты",
          "Должна устраняться с помощью ручной регулировки",
        ],
        correctAnswers: [
          "Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты",
        ],
      },
      {
        code: "20306020",
        text: "Какие требования установлены к месту расстановки ковшей, предназначенных для расплава металла?",
        answers: [
          "Должны расставляться на горизонтальных площадках в любом месте пролета цеха в соответствии с проектной документацией",
          "Должны расставляться на свободных площадках пролета цеха в соответствии с производственной инструкцией",
          "Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства",
        ],
        correctAnswers: [
          "Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства",
        ],
      },
      {
        code: "20306021",
        text: "Какие требования установлены к процессу сливания расплава металла из ковшей и миксеров?Выберите правильный вариант ответа.",
        answers: [
          "Сливание расплава металла из ковшей и миксеров должно быть механизировано",
          "Работникам запрещается работать с технологической емкостью (ковшом) с расплавом металлов общим весом более 15 кг, держа ее в руках на весу",
          "Ось сливного отверстия ковша, установленного под слив, должна совпадать с осью заливочного ковша",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20306022",
        text: "Что должно быть разработано для обеспечения безопасности при освоении новых производств, технологических процессов и технических устройств?",
        answers: [
          "Технологические карты и дополнительные должностные инструкции",
          "Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования",
          "Инструкции по безопасному проведению пусконаладочных работ",
        ],
        correctAnswers: [
          "Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования",
        ],
      },
      {
        code: "20306023",
        text: "После чего разрешается производить слив расплава в ковши, вышедшие из ремонта?",
        answers: [
          "Только после тщательного их просушивания и разогрева",
          "Только после их увлажнения и охлаждения",
          "Только после нанесения на них смазочного материала",
        ],
        correctAnswers: [
          "Только после тщательного их просушивания и разогрева",
        ],
      },
      {
        code: "20306024",
        text: "Чему подлежат производственные емкости (ковши, шлаковые чаши, совки, кюбели, тара, корзины) после изготовления или ремонта?",
        answers: [
          "Проверке состояния",
          "Техническому освидетельствованию",
          "Механическим испытаниям",
        ],
        correctAnswers: ["Проверке состояния"],
      },
      {
        code: "20306025",
        text: "От чего должны быть защищены составные части производственного оборудования, в том числе энергетические трубопроводы, рукава подачи природного газа, кислорода, мазута, воздуха, масла, воды, троллеи для питания электрического оборудования тележек, перевозящих расплавы металлов, и электрические кабели?",
        answers: [
          "Только от возможного попадания на них расплава",
          "От всего перечисленного",
          "Только от механических воздействий",
          "Только от тепловых воздействий",
        ],
        correctAnswers: ["От всего перечисленного"],
      },
      {
        code: "20306026",
        text: "На что должна быть проверена система пневмотранспорта перед вводом в эксплуатацию?",
        answers: [
          "На прочность под избыточным давлением на 35% от рабочего",
          "На герметичность под избыточным давлением на 25% от рабочего",
          "На плотность под рабочим давлением",
        ],
        correctAnswers: ["На плотность под рабочим давлением"],
      },
      {
        code: "20306027",
        text: "Как должны быть расположены пульты управления агрегатами?",
        answers: [
          "В непосредственной близости от агрегатов",
          "На расстоянии не менее 5 м от агрегатов и обеспечивать полную видимость проводимых на площадке работ",
          "В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке",
        ],
        correctAnswers: [
          "В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке",
        ],
      },
      {
        code: "20306028",
        text: "При наличии каких дефектов ковшей их эксплуатация не допускается?",
        answers: [
          "Только ковшей, имеющих раковины, трещины в стенках и в местах крепления цапф",
          "Только ковшей, потерявших форму вследствие деформации, имеющих качку цапф в теле ковша",
          "Только ковшей с поврежденной футеровкой",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20306029",
        text: "При каком условии допускается осуществлять маневры со шлаковозными ковшами на постановочных путях? Выберите два варианта ответа.",
        answers: [
          "При согласовании между ковшевым шлаковозных ковшей (диспетчером) и составительской бригадой",
          "При наличии светового разрешающего сигнала",
          "При наличии звукового разрешающего сигнала",
          "При дистанционном управлении маневрами шлаковых ковшей",
        ],
        correctAnswers: [
          "При согласовании между ковшевым шлаковозных ковшей (диспетчером) и составительской бригадой",
          "При наличии светового разрешающего сигнала",
        ],
      },
      {
        code: "20306030",
        text: "В каком случае допускается повторный налив шлака в ковш с образовавшейся разделительной коркой?",
        answers: [
          "Допускается в любом случае",
          "Остывания корки до окружающей температуры",
          "Пробития корки",
          "Ни в каком случае",
        ],
        correctAnswers: ["Ни в каком случае"],
      },
      {
        code: "20306031",
        text: "Кем устанавливается скорость движения чугуновозов с жидким чугуном и шлаковозов с жидким шлаком на переездах, стрелках и в районе доменных печей?",
        answers: [
          "Изготовителем чугуновоза",
          "Эксплуатирующей организацией с учетом местных условий",
          "Территориальным отделением Ростехнадзора",
          "Ответственным за эксплуатацию чугуновоза",
        ],
        correctAnswers: [
          "Эксплуатирующей организацией с учетом местных условий",
        ],
      },
      {
        code: "20306032",
        text: "На какую величину с возвышением внешнего рельса со стороны слива по отношению к другому должны укладываться пути для шлака на отвале?",
        answers: [
          "На величину не более 200 мм",
          "На величину не более 180 мм",
          "На величину не более 150 мм",
          "На величину не более 250 мм",
        ],
        correctAnswers: ["На величину не более 150 мм"],
      },
      {
        code: "20306033",
        text: "На каком расстоянии от бровки отвала должна располагаться ось пути для шлака на отвале?",
        answers: [
          "Не менее 1,2 м",
          "Не менее 1,0 м",
          "Не менее 0,8 м",
          "Не менее 1,4 м",
        ],
        correctAnswers: ["Не менее 1,4 м"],
      },
      {
        code: "20306034",
        text: "Какая допускается максимальная скорость движения состава ковшей с жидким чугуном?",
        answers: ["10 км/ч", "15 км/ч", "5 км/ч", "20 км/ч"],
        correctAnswers: ["5 км/ч"],
      },
      {
        code: "20306035",
        text: "Какое из перечисленных требований допускается при транспортировании и погрузке шлака?",
        answers: [
          "Устанавливаемые под погрузку шлака думпкары или платформы должны быть расторможены",
          "Необходимо находиться на подвижном составе во время кантовки шлака",
          "Разрешается погрузка шлака в сырые думпкары или на сырые платформы",
          "Выбивка шлака должна производиться с помощью специальных приспособлений в отведенных для этого местах",
        ],
        correctAnswers: [
          "Выбивка шлака должна производиться с помощью специальных приспособлений в отведенных для этого местах",
        ],
      },
      {
        code: "20306036",
        text: "В каком случае допускается проезд локомотивов и вагонов в разливочном пролете напротив печи, из которой производится выпуск металла?",
        answers: [
          "По утвержденному техническим руководителем графику",
          "В перерывах между выпусками металла",
          "Согласно технологической инструкции",
          "Ни в каком случае",
        ],
        correctAnswers: ["Ни в каком случае"],
      },
      {
        code: "20306037",
        text: "С какой периодичностью должны проверяться на работоспособность вентиляционные системы и состояние ванн травления?",
        answers: ["Ежедневно", "Ежемесячно", "Ежеквартально", "Еженедельно"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "20306038",
        text: "Что должны обеспечивать конструкция и установка элементов фурменного прибора?",
        answers: [
          "Должны обеспечивать контроль разгара футеровки",
          "Должны обеспечивать герметичность",
          "Должны обеспечивать продув воздуха",
        ],
        correctAnswers: ["Должны обеспечивать герметичность"],
      },
      {
        code: "20306039",
        text: "Как должна производиться установка мульд на стеллажах?",
        answers: [
          "С наклоном в сторону завалочной машины",
          "По центру стеллажей",
          "Без свесов и перекосов",
        ],
        correctAnswers: ["Без свесов и перекосов"],
      },
      {
        code: "20306040",
        text: "Какие мероприятия должны быть выполнены при прогаре водоохлаждаемых элементов печи и попадании воды в зону плавления печи?",
        answers: [
          "Печь должна быть немедленно отключена",
          "Подача воды должна быть приостановлена",
          "Нагрузка на печь должна быть максимально снижена",
        ],
        correctAnswers: ["Печь должна быть немедленно отключена"],
      },
      {
        code: "20306041",
        text: "Какие требования к производственным помещениям, оборудованию участков приготовления травильных растворов и травления металла указаны неверно?",
        answers: [
          "Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность",
          "Периодичность проведения уборки помещений должна устанавливаться в зависимости от условий их эксплуатации",
          "После работы у ванн травления с химически опасными веществами, используемыми в виде добавок для технологических растворов, вещества должны быть удалены из рабочей зоны на склад",
        ],
        correctAnswers: [
          "Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность",
        ],
      },
      {
        code: "20306042",
        text: "Что должна обеспечить система светозвуковой сигнализации?",
        answers: [
          "Оповещение эксплуатационного персонала о времени начала и окончания смены",
          "Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств",
          "Оповещение эксплуатационного персонала о приближающемся технологическом транспорте",
        ],
        correctAnswers: [
          "Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств",
        ],
      },
      {
        code: "20306043",
        text: "Какие требования установлены к состоянию вакуум-ковшей, тиглей, коробов, кристаллизаторов, изложниц и других емкостей для расплава перед заливкой в них расплава в электролитическом производстве магния?",
        answers: [
          "Должны быть выдержаны в помещении не менее 12 часов",
          "Должны быть очищены от мусора",
          "Должны быть выдержаны в помещении не менее 24 часов",
        ],
        correctAnswers: ["Должны быть очищены от мусора"],
      },
      {
        code: "20306044",
        text: "Что обязаны проверить сталевар и ответственные лица перед включением печи на плавку?",
        answers: [
          "Исправность оборудования, футеровки и свода печи",
          "Исправность системы звукового оповещения о пожаре",
          "Исправность сети газопотребления",
        ],
        correctAnswers: ["Исправность оборудования, футеровки и свода печи"],
      },
      {
        code: "20306045",
        text: "Каким документом определяется максимальная величина давления газа (аргона), необходимая для открывания донных продувочных фурм и ковшевых пробок?",
        answers: [
          "Руководством по эксплуатации",
          "Технологической инструкцией",
          "Производственной инструкцией",
          "Инструкцией о мерах пожарной безопасности при проведении газоопасных работ",
        ],
        correctAnswers: ["Технологической инструкцией"],
      },
      {
        code: "20306046",
        text: "Какие требования установлены к скорости движения железнодорожного транспорта на шлаковом дворе?",
        answers: [
          "Скорость не должна превышать 10 км/ч",
          "Скорость не должна превышать 6 км/ч",
          "Скорость не должна превышать 5 км/ч",
        ],
        correctAnswers: ["Скорость не должна превышать 5 км/ч"],
      },
      {
        code: "20306047",
        text: "Что должно регулярно производиться в корпусах обезвоживания шлама?",
        answers: [
          "Гидросмыв шлама с рабочих площадок и строительных конструкций",
          "Механическое удаление шлама с рабочих площадок и строительных конструкций",
          "Влажная уборка рабочих площадок и строительных конструкций от пыли",
        ],
        correctAnswers: [
          "Гидросмыв шлама с рабочих площадок и строительных конструкций",
        ],
      },
      {
        code: "20306048",
        text: "Из каких материалов должны быть выполнены здания литейных дворов и поддоменников?",
        answers: [
          "Не регламентируется",
          "Из огнестойких материалов",
          "Из плит полипропилена",
        ],
        correctAnswers: ["Из огнестойких материалов"],
      },
      {
        code: "20306049",
        text: "Чем должна обеспечиваться безопасность производственных процессов нанесения металлопокрытий?",
        answers: [
          "Только использованием блокировочных устройств, средств световой и звуковой сигнализации, аварийного отключения производственного оборудования при нарушениях производственных процессов",
          "Только автоматизацией производственных процессов и герметизацией технологического оборудования, являющегося источником вредных и (или) опасных производственных факторов",
          "Только заменой производственных процессов и операций, связанных с наличием вредных и (или) опасных производственных факторов, процессами и операциями, при которых указанные факторы отсутствуют или имеют меньшую интенсивность",
          "Всем перечисленным",
          "Только применением безопасных способов хранения и транспортирования исходных и вспомогательных веществ и материалов, заготовок и готовой продукции",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20306050",
        text: "Как и кем должны проверяться состояние и исправность миксера?",
        answers: [
          "Должны ежесменно проверяться миксеровым с записью результатов осмотра в журнале",
          "Должны проверяться миксеровым каждые 3 смены",
          "Должны проверяться миксеровым 2 раза в смену",
        ],
        correctAnswers: [
          "Должны ежесменно проверяться миксеровым с записью результатов осмотра в журнале",
        ],
      },
      {
        code: "20306051",
        text: "Какие требования установлены к раздеванию слитков с помощью напольных машин или кранов?",
        answers: [
          "Необходимо осуществлять только после остывания слитков до температуры не выше 550 °C",
          "Необходимо осуществлять только в предусмотренном производственной инструкцией помещении после образования частичного затвердевания",
          "Необходимо осуществлять только после полного затвердевания слитков",
        ],
        correctAnswers: [
          "Необходимо осуществлять только после полного затвердевания слитков",
        ],
      },
      {
        code: "20306052",
        text: "Какие требования установлены к ведению взрывных работ, хранению, транспортированию взрывчатых материалов и эксплуатации броневых ям?",
        answers: [
          "Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах",
          "Работы должны производиться обслуживающим персоналом, прошедшим обучение и проверку знаний в специализированном учебном центре и не имеющим противопоказаний по результатам медицинского осмотра",
          "Работы могут производиться взрывниками в процессе эксплуатации оборудования цеха в соответствии с эксплуатационной документацией",
        ],
        correctAnswers: [
          "Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах",
        ],
      },
      {
        code: "20306053",
        text: "На каком расстоянии от кантуемых ковшей должен размещаться пульт управления на шлаковом отвале?",
        answers: [
          "На расстоянии не менее 5 м",
          "На расстоянии не менее 8 м",
          "На расстоянии не менее 10 м",
        ],
        correctAnswers: ["На расстоянии не менее 10 м"],
      },
      {
        code: "20306054",
        text: "Чем должны быть ограждены грануляционные бассейны?",
        answers: [
          "Перилами высотой не менее 1,1 м",
          "Сплошным ограждением высотой не менее 0,9 м",
          "Стальным сетчатым ограждением высотой не менее 0,8 м",
        ],
        correctAnswers: ["Перилами высотой не менее 1,1 м"],
      },
      {
        code: "20306055",
        text: "Какие требования установлены к расположению КИПиА?",
        answers: [
          "Приборы должны устанавливаться в непосредственной близости к оборудованию",
          "Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования",
          "Приборы должны устанавливаться на расстоянии не менее 5 м от оборудования",
        ],
        correctAnswers: [
          "Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования",
        ],
      },
      {
        code: "20306056",
        text: "Какие требования к цинкованию металлов и полимерным покрытиям указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Все операции по транспортированию и обработке проката при нанесении защитных покрытий, а также вспомогательные операции (смена анодов в ваннах электролитического лужения и оцинкования, загрузка металла в ванну с расплавом, приготовление, подача и очистка растворов) должны быть механизированы и должны выполняться в соответствии с технологическими инструкциями",
          "Нанесение защитных покрытий на листовой металл (лужение, оцинкование) следует производить на установках непрерывного действия",
          "Оборудование для нанесения защитных покрытий (полимерных материалов) должно иметь местные отсосы",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20306057",
        text: "Какие требования установлены к подвешиванию скипов?",
        answers: [
          "Скипы должны подвешиваться не менее чем на двух канатах, имеющих восьмикратный запас прочности каждый",
          "Скипы должны подвешиваться не менее чем на четырех канатах, имеющих семикратный запас прочности каждый",
          "Скипы должны подвешиваться не менее чем на двух канатах, имеющих шестикратный запас прочности каждый",
        ],
        correctAnswers: [
          "Скипы должны подвешиваться не менее чем на двух канатах, имеющих шестикратный запас прочности каждый",
        ],
      },
      {
        code: "20306058",
        text: "Какая допускается предельная температура колошникового газа в случае задержки загрузки печи?",
        answers: [
          "Не должна превышать 900 °C",
          "Не должна превышать 700 °C",
          "Не должна превышать 500 °C",
        ],
        correctAnswers: ["Не должна превышать 500 °C"],
      },
      {
        code: "20306059",
        text: "Какие из перечисленных требований, предъявляемых к мостикам для перехода через канавы и желоба при выпуске чугуна и шлака, указаны верно?",
        answers: [
          "Перила ограждения должны иметь сплошную обшивку по низу",
          "Мостики должны быть ограждены перилами",
          "Мостики должны быть теплоизолированы",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20306060",
        text: "С помощью каких устройств должно производиться удаление пыли из изложниц?",
        answers: [
          "С помощью продувочных устройств",
          "С помощью пылеотсасывающих устройств",
          "С помощью мокрого пылеулавливания",
        ],
        correctAnswers: ["С помощью пылеотсасывающих устройств"],
      },
      {
        code: "20306061",
        text: "В каких случаях не допускается завалка лома в конвертер?",
        answers: [
          "При наличии в нем жидкого шлака",
          "При наличии в нем остатков жидкого металла",
          "При наличии в нем твердой корки шлака",
        ],
        correctAnswers: ["При наличии в нем жидкого шлака"],
      },
      {
        code: "20306062",
        text: "В каких случаях не допускается отключение системы охлаждения кристаллизатора?",
        answers: [
          "До выгрузки слитка из камеры",
          "До окончания процесса разгрузки камеры",
          "До температуры охлаждения камеры не ниже 45 °С",
        ],
        correctAnswers: ["До выгрузки слитка из камеры"],
      },
      {
        code: "20306063",
        text: "В течение какого времени должны подаваться звуковые и световые сигналы перед пуском в работу технического устройства, узлы которого или все устройство перемещаются в процессе работы?",
        answers: [
          "Не менее 50 секунд",
          "Не менее 10 секунд",
          "Не менее 30 секунд",
          "Не менее 5 секунд",
        ],
        correctAnswers: ["Не менее 10 секунд"],
      },
      {
        code: "20306064",
        text: "Какие требования установлены к оснащению колошниковой площадки?",
        answers: [
          "Должна быть освещена в вечернее и ночное время, должна иметь аварийное освещение и сплошное ограждение высотой не менее 1 м",
          "Должна быть освещена в ночное время суток и должна иметь металлическое сетчатое ограждение высотой не менее 1,7 м",
          "Должна быть освещена в вечернее и ночное время и ограждена перилами высотой не менее 1,1 м со сплошной зашивкой стальными листами",
        ],
        correctAnswers: [
          "Должна быть освещена в вечернее и ночное время и ограждена перилами высотой не менее 1,1 м со сплошной зашивкой стальными листами",
        ],
      },
      {
        code: "20306065",
        text: "Как часто должно проверяться состояние системы охлаждения печей?",
        answers: ["Каждые 4 часа", "Каждые 36 часов", "Ежесменно"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20306066",
        text: "Как часто комиссионно должно проверяться техническое состояние воздухонагревателей и их арматура?",
        answers: [
          "Не реже 1 раза в квартал",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в полгода",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20306067",
        text: "Что должно быть выполнено во время завалки материалов и подвалки шихты?",
        answers: [
          "Должны быть реализованы мероприятия по безопасности работников",
          "Должен быть незамедлительно оповещен руководитель предприятия",
          "Должна быть остановлена печь и эвакуированы рабочие",
        ],
        correctAnswers: [
          "Должны быть реализованы мероприятия по безопасности работников",
        ],
      },
      {
        code: "20306068",
        text: "Как часто должны производиться проверки приборов измерения уровня засыпи печи?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в неделю",
          "Не реже 2 раз в месяц",
        ],
        correctAnswers: ["Не реже 2 раз в месяц"],
      },
      {
        code: "20306069",
        text: "Чем должны быть перекрыты приемные бункера грануляционных установок?",
        answers: [
          "Предохранительными решетками с ячейками не более 200 х 200 мм",
          "Предохранительными решетками с ячейками не более 250 х 250 мм",
          "Предохранительными решетками с ячейками не более 300 х 300 мм",
        ],
        correctAnswers: [
          "Предохранительными решетками с ячейками не более 200 х 200 мм",
        ],
      },
      {
        code: "20306070",
        text: "Как должен быть подготовлен газоотводящий тракт конвертера к ремонту?",
        answers: [
          "Должен быть тщательно очищен от загрязнений",
          "Должен быть надежно отключен от общих коллекторов и боровов",
          "Должен быть продут инертным газом",
        ],
        correctAnswers: [
          "Должен быть надежно отключен от общих коллекторов и боровов",
        ],
      },
      {
        code: "20306071",
        text: "Какие требования установлены к укладке слитков?",
        answers: [
          "Укладка должна производиться в специальные закрома, исключающие попадание прямых солнечных лучей",
          "Укладка должна производиться на специально выделенные в цехе площадки, исключающие попадание влаги",
          "Укладка должна производиться на специальные стеллажи, исключающие раскатывание слитков",
        ],
        correctAnswers: [
          "Укладка должна производиться на специальные стеллажи, исключающие раскатывание слитков",
        ],
      },
      {
        code: "20306072",
        text: "Какие требования к внепечной обработке жидкого металла указаны неверно?",
        answers: [
          "Процесс выдувания порошкообразного материала из пневмонагнетателя и тракта подачи должен осуществляться вручную в соответствии с производственной документацией",
          "Агрегаты внепечной обработки жидкого металла должны быть оборудованы системой управления, обеспечивающей функционирование механизмов в безопасных режимах и автоматическое отключение агрегатов при отклонении контролируемых параметров от заданных",
          "Механизмы перемещения сталевоза и передвижной платформы вакуумной камеры должны иметь блокировку, исключающую возможность включения механизма перемещения сталевоза при нахождении торца всасывающего патрубка вакуумной камеры на уровне или ниже верхней кромки сталеразливочного ковша, установленного на сталевозе",
          "Ввод раскислителей и легирующих материалов в ковш с жидким металлом под вакуумом должен осуществляться через вакуумный шлюз, конструкция которого должна обеспечивать сохранение вакуума в системе",
        ],
        correctAnswers: [
          "Процесс выдувания порошкообразного материала из пневмонагнетателя и тракта подачи должен осуществляться вручную в соответствии с производственной документацией",
        ],
      },
      {
        code: "20306073",
        text: "Что должно исключаться при присадке раскислителей в ковш?",
        answers: [
          "Разбрызгивание раскислителей",
          "Повреждение стопорного устройства",
          "Сбой в работе дозировочного устройства",
        ],
        correctAnswers: ["Повреждение стопорного устройства"],
      },
      {
        code: "20306074",
        text: "Что должно быть установлено в здании подъемника?",
        answers: [
          "Телефонная связь с диспетчерской службой",
          "Радиосвязь с техническим руководителем организации",
          "Прямая телефонная (радио-) связь с колошниковой площадкой и скиповой ямой",
        ],
        correctAnswers: [
          "Прямая телефонная (радио-) связь с колошниковой площадкой и скиповой ямой",
        ],
      },
      {
        code: "20306075",
        text: "Какие требования установлены к срокам осмотра баков (емкостей) для смазочных материалов и баков для варки смолы и к осмотру и чистке смотровых люков и вытяжных труб баков для варки смолы?",
        answers: [
          "Осмотр должен производиться не реже 1 раза в год, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно",
          "Осмотр должен производиться не реже 1 раза в полгода, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно",
          "Осмотр должен производиться не реже 1 раза в 2 года, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно",
        ],
        correctAnswers: [
          "Осмотр должен производиться не реже 1 раза в год, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно",
        ],
      },
      {
        code: "20306076",
        text: "В каком случае заправка мартеновских печей и двухванных сталеплавильных агрегатов не допускается?",
        answers: [
          "При повышении давления газа выше давления воздуха на величину, предусмотренную технологической инструкцией",
          "При открытом отверстии в площадке для спуска шлака",
          "При работе защитных блокировок в автоматическом режиме",
        ],
        correctAnswers: ["При открытом отверстии в площадке для спуска шлака"],
      },
      {
        code: "20306077",
        text: "С какой периодичностью должна проверяться герметичность сводов и стен регенераторов, работающих с подогревом газа в них?",
        answers: ["Каждые 48 часов", "Еженедельно", "Ежесменно"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20306078",
        text: "Какие требования установлены к системам освещения во взрывоопасных помещениях?",
        answers: [
          "Должны предусматриваться системы освещения, работающие от напряжения 127 В",
          "Должны предусматриваться системы освещения во взрывобезопасном исполнении",
          "Должны предусматриваться системы освещения, работающие от напряжения 24 В",
        ],
        correctAnswers: [
          "Должны предусматриваться системы освещения во взрывобезопасном исполнении",
        ],
      },
      {
        code: "20306079",
        text: "Какие ручки должен иметь металлический инструмент, применяемый при обслуживании индукционных печей?",
        answers: [
          "Деревянные ручки",
          "Электроизолированные ручки",
          "Медные ручки",
        ],
        correctAnswers: ["Электроизолированные ручки"],
      },
      {
        code: "20306080",
        text: "После выполнения каких мероприятий разрешается производить работы на своде мартеновской печи?",
        answers: [
          "Только после выпуска плавки и до заливки чугуна в печь",
          "Только после полной остановки печи",
          "Только после остановки печи на капитальный ремонт",
        ],
        correctAnswers: [
          "Только после выпуска плавки и до заливки чугуна в печь",
        ],
      },
      {
        code: "20306081",
        text: "В присутствии какого лица должна производиться присадка легковоспламеняющихся порошковых материалов, а также смесей на их основе в печь или ковш?",
        answers: [
          "В присутствии начальника цеха",
          "В присутствии ответственного лица, назначенного распоряжением по цеху",
          "В присутствии лица, ответственного за безопасную эксплуатацию печей",
        ],
        correctAnswers: [
          "В присутствии ответственного лица, назначенного распоряжением по цеху",
        ],
      },
      {
        code: "20306082",
        text: "Чем должен быть покрыт пол пульта управления электронно-лучевой печью?",
        answers: [
          "Электроизолирующим материалом, на который должно быть нанесено клеймо испытания",
          "Деревянным покрытием, пропитанным огнеустойчивым средством",
          "Противоскользящим покрытием из искробезопасного материала",
        ],
        correctAnswers: [
          "Электроизолирующим материалом, на который должно быть нанесено клеймо испытания",
        ],
      },
      {
        code: "20306083",
        text: "Как часто должна производиться проверка состояния механизма поворота конвертера?",
        answers: ["Еженедельно", "После каждого процесса плавки", "Ежесменно"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20306084",
        text: "Какие требования установлены к хранению стержней, шаров, футеровки, запасных деталей и приспособлений?",
        answers: [
          "Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре",
          "Должно быть предусмотрено отдельно стоящее специальное помещение",
          "Должно быть предусмотрено на закрытых площадках, обеспечивающих свободный проход персонала",
        ],
        correctAnswers: [
          "Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре",
        ],
      },
      {
        code: "20306085",
        text: "Какие требования установлены к проверке и испытанию приборов во взрывопожароопасных и пожароопасных помещениях?",
        answers: [
          "Должны производиться непосредственно во взрывопожароопасных помещениях",
          "Должны производиться только в специальном отдельно стоящем помещении",
          "Должны производиться в условиях, исключающих искрообразование",
        ],
        correctAnswers: [
          "Должны производиться в условиях, исключающих искрообразование",
        ],
      },
      {
        code: "20306086",
        text: "Какой запас прочности должны иметь канаты для подвески и подъема контргрузов?",
        answers: ["Пятикратный", "Восьмикратный", "Десятикратный"],
        correctAnswers: ["Восьмикратный"],
      },
      {
        code: "20306087",
        text: "В какой сталеплавильный агрегат завалка металлической стружки не допускается?",
        answers: [
          "В дуговую печь",
          "В двухванный агрегат",
          "В мартеновскую печь",
        ],
        correctAnswers: ["В двухванный агрегат"],
      },
      {
        code: "20306088",
        text: "Что должны обеспечивать системы охлаждения горна и лещади доменной печи?",
        answers: [
          "Должны обеспечивать перепад температуры не более 3 °С",
          "Должны обеспечивать перепад температуры не более 4 °С",
          "Должны обеспечивать перепад температуры не более 5 °С",
        ],
        correctAnswers: [
          "Должны обеспечивать перепад температуры не более 3 °С",
        ],
      },
      {
        code: "20306089",
        text: "После выполнения каких мероприятий должна производиться заливка чугуна в печь?",
        answers: [
          "Только после полного закрытия сталевыпускного отверстия, установки сталевыпускного желоба и шлаковых ковшей",
          "Только после полного закрытия сталевыпускного отверстия и отключения горелок",
          "Только после установки сталевыпускного желоба и шлаковых ковшей",
        ],
        correctAnswers: [
          "Только после полного закрытия сталевыпускного отверстия, установки сталевыпускного желоба и шлаковых ковшей",
        ],
      },
      {
        code: "20306090",
        text: "Чем определяется высота наполнения ковша металлом и общее количество присадок, обеспечивающее безопасное выполнение технологических операций?",
        answers: [
          "Проектной документацией",
          "Производственной документацией",
          'Требованиями ГОСТ 7566-2018 "Межгосударственный стандарт. Металлопродукция. Правила приемки, маркировка, упаковка, транспортирование и хранение"',
          "Правилами безопасности процессов получения или применения металлов",
        ],
        correctAnswers: ["Производственной документацией"],
      },
      {
        code: "20306091",
        text: "Чем должен быть покрыт пол рабочей площадки возле электропечи?",
        answers: [
          "Термостойким настилом",
          "Профилированным настилом",
          "Электроизолирующим настилом",
        ],
        correctAnswers: ["Электроизолирующим настилом"],
      },
      {
        code: "20306092",
        text: "Какие мероприятия должны быть выполнены при уходе металла из тигля?",
        answers: [
          "Печь должна быть отключена, и жидкий металл слит в изложницу",
          "Печь должна быть остановлена и заполнена инертным газом",
          "Печь должна быть заполнена инертным газом, и жидкий металл должен быть слит в миксер",
        ],
        correctAnswers: [
          "Печь должна быть отключена, и жидкий металл слит в изложницу",
        ],
      },
      {
        code: "20306093",
        text: "На каком расстоянии не допускается производство работ на путях от стоящих под наливом ковшей?",
        answers: [
          "На расстоянии ближе 15 м",
          "На расстоянии ближе 12 м",
          "На расстоянии ближе 10 м",
        ],
        correctAnswers: ["На расстоянии ближе 15 м"],
      },
      {
        code: "20306094",
        text: "С какой периодичностью должны производиться замеры аэродинамического сопротивления насадки воздухонагревателей?",
        answers: ["Ежемесячно", "Ежеквартально", "Ежегодно"],
        correctAnswers: ["Ежегодно"],
      },
      {
        code: "20306095",
        text: "Какое требование установлено к устройству выходных отверстий продувочных свечей на доменных печах?",
        answers: [
          "Должно быть выше верхних площадок колошника не менее чем на 4 м",
          "Должно быть выше верхних площадок колошника не менее чем на 2 м",
          "Должно быть выше верхних площадок колошника не менее чем на 3 м",
        ],
        correctAnswers: [
          "Должно быть выше верхних площадок колошника не менее чем на 4 м",
        ],
      },
      {
        code: "20306096",
        text: "При каких условиях должны производиться все работы по подготовке плавки?",
        answers: [
          "Только при условии окончания плавки",
          "Только при отключенной печи (установке)",
          "Только при проведении плавки",
        ],
        correctAnswers: ["Только при отключенной печи (установке)"],
      },
      {
        code: "20306097",
        text: "Какие требования установлены к выгрузке шихтовых материалов на рудном дворе?",
        answers: [
          "Выгрузка должна быть организована ручным способом",
          "Выгрузка смерзшихся шихтовых материалов из вагонов должна производиться только при отрицательных температурах",
          "Выгрузка должна быть механизирована",
        ],
        correctAnswers: ["Выгрузка должна быть механизирована"],
      },
      {
        code: "20306098",
        text: "Какие требования установлены к сливу шлака в шлаковую яму?",
        answers: [
          "Должен производиться равномерной струей",
          "Должен производиться со скоростью, предусмотренной производственной инструкцией",
          "Должен производиться кантовкой ковша на 90°",
        ],
        correctAnswers: ["Должен производиться равномерной струей"],
      },
      {
        code: "20306099",
        text: "Какие требования предъявляются к предохранительным решеткам бункера?",
        answers: [
          "Должны быть оборудованы предохранительными решетками с ячейками размером не более 300 х 400 мм",
          "Должны быть оборудованы предохранительными решетками с ячейками размером не более 300 х 300 мм",
          "Должны быть оборудованы предохранительными решетками с ячейками размером не более 400 х 400 мм",
        ],
        correctAnswers: [
          "Должны быть оборудованы предохранительными решетками с ячейками размером не более 300 х 300 мм",
        ],
      },
      {
        code: "20306100",
        text: "Что должно в обязательном порядке производиться при категорировании зданий и помещений?",
        answers: [
          "Расчет тротилового эквивалента",
          "Расчет скорости распространения ударной волны",
          "Расчет избыточного давления взрыва с учетом наиболее опасной модели аварийной ситуации",
        ],
        correctAnswers: [
          "Расчет избыточного давления взрыва с учетом наиболее опасной модели аварийной ситуации",
        ],
      },
      {
        code: "20306101",
        text: "В каком случае разрешено нахождение работников на колошниковой площадке? Выберите 2 варианта ответа.",
        answers: [
          "С оформлением разрешения работника, ответственного за производство работ",
          "С оформлением наряда-допуска на газоопасные работы",
          "При наличии у работника персонального газоанализатора",
          "При наличии ограждения перилами высотой не менее 0,8 м",
          "В случае отключения шахтной печи",
        ],
        correctAnswers: [
          "С оформлением наряда-допуска на газоопасные работы",
          "При наличии у работника персонального газоанализатора",
        ],
      },
      {
        code: "20306102",
        text: "Какие требования предъявляются к устройству и эксплуатации печей для обжига шихтовых материалов и концентратов?",
        answers: [
          "Допускается эксплуатация печей при остаточном разрежении ниже величины, указанной в проектной документации",
          "Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 5%",
          "Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции",
        ],
        correctAnswers: [
          "Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции",
        ],
      },
      {
        code: "20306103",
        text: "Что необходимо делать во время работы мельницы в помольном помещении?",
        answers: [
          "Закрывать все двери и включать световое предупредительное табло",
          "Закрывать все двери и включать приточно-вытяжную вентиляцию",
          "Включать световое предупредительное табло и звуковое оповещение о начале работы мельницы",
        ],
        correctAnswers: [
          "Закрывать все двери и включать световое предупредительное табло",
        ],
      },
      {
        code: "20306104",
        text: "Что должно устраиваться над постановочными путями чугуновозных и шлаковозных ковшей в пределах литейного двора в целях исключения попадания атмосферных осадков в ковши?",
        answers: [
          "Только металлические крыши",
          "Крыши или специальные навесы",
          "Только специальные навесы из полиэтиленовых материалов",
        ],
        correctAnswers: ["Крыши или специальные навесы"],
      },
      {
        code: "20306105",
        text: "Какие требования к цинкованию металлов указаны неверно?",
        answers: [
          "Электропроводка на ваннах цинкования должна быть защищена от возможного повреждения их горячим металлом",
          "Порядок загрузки блоков цинка в ванну цинкования определяется проектом. При необходимости разрабатываются дополнительные меры для безопасности персонала",
          "Срок эксплуатации ванн цинкования определяется проектной документацией завода-изготовителя",
          "Все перечисленные требования указаны верно",
          "Срок замены оборудования и футеровки ванн для цинкования определяется эксплуатирующей организацией по результатам проведения экспертизы промышленной безопасности ванн для цинкования",
        ],
        correctAnswers: [
          "Срок замены оборудования и футеровки ванн для цинкования определяется эксплуатирующей организацией по результатам проведения экспертизы промышленной безопасности ванн для цинкования",
        ],
      },
      {
        code: "20306106",
        text: "Какие требования установлены к конусным и бесконусным засыпным аппаратам?",
        answers: [
          "Засыпные аппараты должны быть надежными и рассчитаны на двукратное рабочее давление газа под колошником",
          "Засыпные аппараты должны быть герметичными и рассчитаны на рабочее давление газа под колошником",
          "Засыпные аппараты должны быть надежными и рассчитаны на полуторакратное рабочее давление газа под колошником",
        ],
        correctAnswers: [
          "Засыпные аппараты должны быть герметичными и рассчитаны на рабочее давление газа под колошником",
        ],
      },
      {
        code: "20306107",
        text: "Как должна производиться разделка сталевыпускного отверстия?",
        answers: [
          "Должна производиться только при наличии под желобом ковшей, а в разливочном пролете - состава с изложницами",
          "Должна производиться только в светлое время суток при наличии специального инструмента",
          "Должна производиться только с бортиков желоба под руководством мастера смены",
        ],
        correctAnswers: [
          "Должна производиться только при наличии под желобом ковшей, а в разливочном пролете - состава с изложницами",
        ],
      },
      {
        code: "20306108",
        text: "Какие требования установлены к сцепке и расцепке сталевозной тележки?",
        answers: [
          "Должны быть автоматическими",
          "Должны быть дистанционными",
          "Должны производиться сцепщиком при помощи специального приспособления",
        ],
        correctAnswers: ["Должны быть дистанционными"],
      },
      {
        code: "20306109",
        text: "Чему из перечисленного должны соответствовать средства автоматизации, производственной сигнализации и связи, блокировочные устройства? Выберите все правильные ответы.",
        answers: [
          "Требованиям Правил безопасности процессов получения или применения металлов",
          'Требованиям ГОСТ 7566-2018 "Межгосударственный стандарт. Металлопродукция. Правила приемки, маркировка, упаковка, транспортирование и хранение"',
          'Требованиям технического регламента Таможенного союза "О безопасности оборудования для работы во взрывоопасных средах"',
          "Производственной документации",
        ],
        correctAnswers: [
          "Требованиям Правил безопасности процессов получения или применения металлов",
          "Производственной документации",
        ],
      },
      {
        code: "20306110",
        text: "Чем должна быть продута горелка перед включением и подачей газа?",
        answers: ["Инертным газом", "Кислородом", "Сжатым воздухом"],
        correctAnswers: ["Кислородом"],
      },
      {
        code: "20306111",
        text: "Какие требования установлены к управлению стопорами и шиберными затворами ковшей?",
        answers: [
          "Управление должно быть автоматизированным",
          "Управление должно быть механическим",
          "Управление должно быть дистанционным",
        ],
        correctAnswers: ["Управление должно быть дистанционным"],
      },
      {
        code: "20306112",
        text: "Как должна производиться заливка чугуна в миксер?",
        answers: [
          "В центр окна равномерной струей с минимальной высоты",
          "В центр окна порционно с высоты, предусмотренной производственной инструкцией",
          "В верхнюю часть окна с помощью специальной воронки с минимальной высоты",
        ],
        correctAnswers: [
          "В центр окна равномерной струей с минимальной высоты",
        ],
      },
      {
        code: "20306113",
        text: "На каком расстоянии от скиповой ямы производится остановка вагона-весов с установкой заградительных устройств с обеих сторон скиповой ямы?",
        answers: [
          "Не ближе 20 м",
          "Не ближе 15 м",
          "Не ближе 10 м",
          "Не ближе 5 м",
        ],
        correctAnswers: ["Не ближе 10 м"],
      },
      {
        code: "20306114",
        text: "С какой периодичностью должны производиться замеры температуры кожухов воздухонагревателя?",
        answers: [
          "Систематически (не реже одного раза в квартал)",
          "Систематически (не реже одного раза в месяц)",
          "Систематически (не реже двух раз в месяц)",
        ],
        correctAnswers: ["Систематически (не реже одного раза в месяц)"],
      },
      {
        code: "20306115",
        text: "Каков предельно допустимый износ цапф конвертера во всех измерениях в случае применения подшипников скольжения?",
        answers: [
          "Не должен превышать 12% их первоначальных размеров",
          "Не должен превышать 15% их первоначальных размеров",
          "Не должен превышать 10% их первоначальных размеров",
        ],
        correctAnswers: ["Не должен превышать 10% их первоначальных размеров"],
      },
      {
        code: "20306116",
        text: "Какие требования установлены к диаметру лазов пылеуловителей?",
        answers: [
          "Диаметр не менее 600 мм",
          "Диаметр не менее 500 мм",
          "Диаметр не менее 400 мм",
        ],
        correctAnswers: ["Диаметр не менее 600 мм"],
      },
      {
        code: "20306117",
        text: "В каком случае должна быть обеспечена двусторонняя радиосвязь для машиниста с мастером (бригадиром) загрузки доменных печей?",
        answers: [
          "Для машиниста вагоноопрокидывателя",
          "Для машиниста перегрузочного крана",
          "Для машиниста трансферкара",
          "Для всех перечисленных",
        ],
        correctAnswers: ["Для всех перечисленных"],
      },
      {
        code: "20306118",
        text: "В соответствии с чем производятся подтяжка канатов скипов и выполнение других работ, связанных с кратковременным прекращением загрузки шихтовых материалов в печь?",
        answers: [
          "В соответствии с производственной документацией",
          "В соответствии с паспортом объекта",
          "В соответствии с нарядом-допуском",
        ],
        correctAnswers: ["В соответствии с производственной документацией"],
      },
      {
        code: "20306119",
        text: "В каком случае допускается работа подъемников мелочи кокса и агломерата при неисправных путевых выключателях, выключателях слабины каната, а также концевых выключателях крайних положений скипа? Выберите правильный вариант ответа.",
        answers: [
          "В случае наличия исправной и постоянно действующей световой сигнализации между бригадой и машинистом вагона-весов или машинистом шихтоподачи",
          "В случае ручной уборки просыпи мелочи кокса и агломерата с соблюдением требований безопасности",
          "В случае отключения скиповых подъемников (загрузочных транспортеров, питателей, грохотов и перекидного лотка, вагон-весов) с невозможностью включения приводов из внутренних пространств",
          "Работа подъемников мелочи кокса и агломерата при неисправных путевых выключателях, выключателях слабины каната, а также концевых выключателях крайних положений скипа не допускается",
        ],
        correctAnswers: [
          "Работа подъемников мелочи кокса и агломерата при неисправных путевых выключателях, выключателях слабины каната, а также концевых выключателях крайних положений скипа не допускается",
        ],
      },
      {
        code: "20306120",
        text: "Какое из перечисленных требований при загрузке шихтовых материалов указано неверно?",
        answers: [
          "В здании подъемника должна быть установлена прямая телефонная (радио) связь с колошниковой площадкой и скиповой ямой",
          "Все выходы, ведущие на шахту и колошник печи, должны оборудоваться дверями с замком, открывающимся ключом изнутри",
          "Вход на колошниковую площадку со стороны наклонного моста (за верхней площадкой для обслуживания шкивов) должен иметь дверь",
          "Скипы должны подвешиваться не менее чем на 2 канатах, имеющих шестикратный запас прочности каждый",
        ],
        correctAnswers: [
          "Все выходы, ведущие на шахту и колошник печи, должны оборудоваться дверями с замком, открывающимся ключом изнутри",
        ],
      },
      {
        code: "20306121",
        text: "Какое количество люков должно устраиваться на газовых затворах с цилиндрической вставкой для устойчивого горения газа при проверке засыпного материала?",
        answers: ["3 люка", "1 люк", "2 люка", "Не регламентируется"],
        correctAnswers: ["2 люка"],
      },
      {
        code: "20306122",
        text: "В соответствии с каким документом должно производиться опускание в печь застрявших на малом и большом конусах шихтовых материалов?",
        answers: [
          "В соответствии с производственной инструкцией",
          "В соответствии с технологической инструкцией",
          "В соответствии с паспортом объекта",
          "В соответствии с оргаизационно-распорядительным документом эксплуатирующей организации",
        ],
        correctAnswers: ["В соответствии с технологической инструкцией"],
      },
      {
        code: "20306123",
        text: "С какой периодичностью проверяется состояние внешнего оборудования колошника и засыпного аппарата с оформлением результатов проверок?",
        answers: ["Ежесменно", "Ежедневно", "Ежемесячно", "Ежеквартально"],
        correctAnswers: ["Ежеквартально"],
      },
      {
        code: "20306124",
        text: "Что должно подаваться в межконусное пространство загрузочного устройства доменной печи во избежание образования взрывоопасных смесей? Выберите 2 варианта ответа.",
        answers: ["Углекислый газ", "Воздух", "Пар", "Азот"],
        correctAnswers: ["Пар", "Азот"],
      },
      {
        code: "20306125",
        text: "При каком максимальном давлении в межконусном пространстве загрузочного устройства доменной печи осуществляется открытие малого конуса?",
        answers: ["0,020 МПа", "0,025 МПа", "0,015 МПа", "0,030 МПа"],
        correctAnswers: ["0,015 МПа"],
      },
      {
        code: "20306126",
        text: "В период выполнения ремонтных работ с каким сроком допускается загрузка шихты в печь при вышедшем из строя вращающемся распределителе шихты?",
        answers: [
          "Не более 5 часов",
          "Не более 6 часов",
          "Не более 4 часов",
          "Не более 10 часов",
        ],
        correctAnswers: ["Не более 4 часов"],
      },
      {
        code: "20306127",
        text: "С какой периодичностью отмечаются все изменения в состоянии кожуха и холодильников печи на схеме расположения холодильников, составляемой на каждую доменную печь?",
        answers: ["Ежесменно", "Ежемесячно", "Еженедельно", "Ежедневно"],
        correctAnswers: ["Ежемесячно"],
      },
      {
        code: "20306128",
        text: "В каком случае проводится внеочередное обследование кожухов доменных печей и воздухонагревателей?",
        answers: [
          "Только в случае выявления при эксплуатационных осмотрах необъяснимого роста повреждений и опасности общего разрушения кожуха",
          "Только в случае аварии в цехе, связанной с огневым (пожар) или механическим (обрушение конструкций) воздействием на кожух печи",
          "Только  в случае намечаемой реконструкции печи",
          "Только  в случае увеличения нормируемых проектных показателей и (или) климатических воздействий (рост районной сейсмики, рост рабочего давления, переход на цинкосодержащие руды)",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20306129",
        text: "Кому должен сообщить мастер доменной печи или газовщик о подвисании и осадке шихты?",
        answers: [
          "Только диспетчеру газового хозяйства",
          "Только машинисту воздуходувной машины",
          "Только персоналу загрузки",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20306130",
        text: "В каком из перечисленных случаев не допускается производить выпуск чугуна?",
        answers: [
          "При короткой летке",
          "По сырой летке",
          "При слабой летке",
          "При неисправном футляре",
        ],
        correctAnswers: ["По сырой летке"],
      },
      {
        code: "20306131",
        text: "В каком случае не допускается прожигание корки чугунной летки?",
        answers: [
          "При длине кислородной трубки менее 3 м",
          "При длине кислородной трубки менее 2 м",
          "При длине кислородной трубки менее 5 м",
          "При длине кислородной трубки менее 4м",
        ],
        correctAnswers: ["При длине кислородной трубки менее 2 м"],
      },
      {
        code: "20306132",
        text: "Какие независимые технологические линии должны иметь в своем составе установки придоменной грануляции шлака? Выберите два варианта ответа.",
        answers: [
          "Рабочую линию",
          "Сточную линию",
          "Резервную линию",
          "Линию подачи газа",
        ],
        correctAnswers: ["Рабочую линию", "Резервную линию"],
      },
      {
        code: "20306133",
        text: "Какое из перечисленных требований к отводу шлакового желоба на резервную линию установки придоменной грануляции шлака указано верно?",
        answers: [
          "Отвод шлакового желоба на длине не менее 2 м засыпан песком на 50 мм выше бортов желоба",
          "Отвод шлакового желоба на резервную линию должен быть перекрыт отсечным устройством",
          "Отвод шлакового желоба на резервную линию не должен быть перекрыт",
          "Отвод шлакового желоба на длине не менее 1.5 м засыпан песком на 100 мм выше бортов желоба",
        ],
        correctAnswers: [
          "Отвод шлакового желоба на резервную линию должен быть перекрыт отсечным устройством",
        ],
      },
      {
        code: "20306134",
        text: "До какой температуры должны быть охлаждены системы оборотного водоснабжения установок придоменной грануляции шлака для производства работ по их ремонту?",
        answers: [
          "Не выше 50 °C",
          "Не выше 70 °C",
          "Не выше 40 °C",
          "Не выше 60 °C",
        ],
        correctAnswers: ["Не выше 40 °C"],
      },
      {
        code: "20306135",
        text: "Какое из перечисленных требований допускается при грануляции шлака за пределами цеха? Выберите два варианта ответа.",
        answers: [
          "Грануляция шлака за пределами цеха должна производиться в специальных герметизированных установках или в грануляционных бассейнах",
          "Грануляционные бассейны должны быть ограждены перилами высотой не менее 1,5 м",
          "Грануляция шлака, содержащего чугун, не допускается",
          "Управление кантовкой шлаковых ковшей, подачей воды в грануляционные аппараты и пробивкой корки в ковшах должно быть ручным",
        ],
        correctAnswers: [
          "Грануляция шлака за пределами цеха должна производиться в специальных герметизированных установках или в грануляционных бассейнах",
          "Грануляция шлака, содержащего чугун, не допускается",
        ],
      },
      {
        code: "20306136",
        text: "Что из перечисленного допускается при разливке чугуна на разливочных машинах?",
        answers: [
          "Изменение положения перекидных желобов в автоматическом режиме",
          "Размещение под лентой разливочных машин запорной и регулирующей арматуры",
          "Устройство навеса над лентами разливочной машины на всем их протяжении",
          "Осушение рабочих площадок и постановочных железнодорожных путей у стендов",
        ],
        correctAnswers: [
          "Осушение рабочих площадок и постановочных железнодорожных путей у стендов",
        ],
      },
      {
        code: "20306137",
        text: "В каком случае допускается подъем ковша краном при зацепленном крюке для кантовки в сталеплавильном производстве?",
        answers: [
          "При образовании корки в ковше",
          "При очистке ковша от настылей и скрапа",
          "При пробивке или прожигании корки кислородом в ковше",
          "Ни в каком случае",
        ],
        correctAnswers: ["Ни в каком случае"],
      },
      {
        code: "20306138",
        text: "Какой сигнал должен быть подан на пост управления миксером о правильности установки ковша под сливным носком перед началом слива чугуна из миксера?",
        answers: [
          "Только звуковой",
          "Только световой",
          "Только по телефону",
          "Только радио",
          "Любой из перечисленных",
        ],
        correctAnswers: ["Любой из перечисленных"],
      },
      {
        code: "20306139",
        text: "Какое из перечисленных требований к отделению перелива чугуна указано неверно?",
        answers: [
          "До отцепки локомотива под колеса чугуновоза с обеих сторон должны быть установлены железнодорожные тормозные башмаки",
          "Слив чугуна должен производиться по краю ковша равномерной струей",
          "Во время слива чугуна в заливочный ковш нахождение работников в опасной зоне не допускается",
          "Сливать чугун из чугуновозного ковша с застывшими остатками чугуна в нем не допускается",
        ],
        correctAnswers: [
          "Слив чугуна должен производиться по краю ковша равномерной струей",
        ],
      },
      {
        code: "20306140",
        text: "Какое из перечисленных требований к завалке материалов в печи, конвертеры указано верно? Выберите правильный вариант ответа.",
        answers: [
          "Перед началом завалки должен производиться осмотр подины печи",
          "Завалка лома в конвертер при наличии в нем жидкого шлака не допускается",
          "Не допускается начинать завалку лома из совка, ближайшего к кабине машиниста завалочной машины",
          "Легковесный лом должен загружаться в конвертер в первую очередь",
          "Все требования верны",
        ],
        correctAnswers: ["Все требования верны"],
      },
      {
        code: "20306141",
        text: "Какие из перечисленных работ по доставке и заливке чугуна в мартеновские печи, двухванные сталеплавильные агрегаты, конвертеры не осуществляются в соответствии с требованиями технологической инструкции? Выберите правильный вариант ответа.",
        answers: [
          "Заливка чугуна в печь из ковшей с застывшей коркой не допускается",
          "Заливка чугуна в печь должна производиться только после полного закрытия сталевыпускного отверстия, установки сталевыпускного желоба и шлаковых ковшей",
          "Заливка чугуна должна производиться после расплавления шихты и образования шлака",
          "Работы на своде мартеновской печи разрешается производить только после выпуска плавки и до заливки чугуна в печь",
          "Все перечисленные работы",
        ],
        correctAnswers: [
          "Заливка чугуна должна производиться после расплавления шихты и образования шлака",
        ],
      },
      {
        code: "20306142",
        text: "В каком месте должна быть вывешена схема испарительного охлаждения мартеновской печи? Выберите правильный вариант ответа.",
        answers: [
          "Только в галерее барабанов-сепараторов",
          "Только в помещении дежурного персонала",
          "Только на посту управления печью",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20306143",
        text: "С чьего разрешения должна производиться уборка шлака и мусора из-под мартеновской печи в период завалки и прогрева шихты?",
        answers: [
          "Диспетчера",
          "Ответственного за бесопасное проведение работ",
          "Ответственного инженерно-технического работника",
          "Сталевара печи",
        ],
        correctAnswers: ["Сталевара печи"],
      },
      {
        code: "20306144",
        text: "В каком случае осуществляется доливка чугуна в мартеновские и двухванные сталеплавильные агрегаты?",
        answers: [
          "В любом случае",
          "Ни в каком случае",
          "В исключительном случае согласно технологической инструкции.",
        ],
        correctAnswers: [
          "В исключительном случае согласно технологической инструкции.",
        ],
      },
      {
        code: "20306145",
        text: "Какое из перечисленных требований при выдувке металла из ям подины указано неверно?",
        answers: [
          "Выдувка металла из ям подины должна производиться в сталевыпускное отверстие азотом, через смотровые отверстия в крышках завалочных окон",
          "До начала выдувки металла из ям перед сталевыпускным отверстием должен быть установлен экран, предотвращающий разбрызгивание металла и шлака",
          "Выдувка металла из ям должна производиться со специальной переносной площадки",
          "Все требования верны",
        ],
        correctAnswers: [
          "Выдувка металла из ям подины должна производиться в сталевыпускное отверстие азотом, через смотровые отверстия в крышках завалочных окон",
        ],
      },
      {
        code: "20306146",
        text: "До какой температуры должен быть нагрет верх насадок газового регенератора во избежание хлопков и взрывов при пуске газа в мартеновскую печь?",
        answers: [
          "До температуры 600 - 650 °C",
          "До температуры 500 - 550 °C",
          "До температуры 700 - 750 °C",
          "До температуры 300 - 350 °C",
        ],
        correctAnswers: ["До температуры 700 - 750 °C"],
      },
      {
        code: "20306147",
        text: "Какие действия должны быть выполнены при возникновении в мартеновской и двухванной сталеплавильной печи бурных реакций? Выберите правильный вариант ответа.",
        answers: [
          "Только уменьшена подача кислорода",
          "Только прекращена подача кислорода",
          "Только уменьшена тепловая нагрузка печи",
          "Все перечисленные действия",
        ],
        correctAnswers: ["Все перечисленные действия"],
      },
      {
        code: "20306148",
        text: "Какое из перечисленных требований при обслуживании электропечей указано верно? Выберите два варианта ответа.",
        answers: [
          "Пол рабочей площадки возле электропечи должен быть покрыт металлическим настилом",
          "Металлический инструмент, применяемый при обслуживании индукционных печей, должен иметь электроизолированные ручки",
          "При проведении на печи работ, связанных с применением неизолированного металлического инструмента, печь должна быть отключена",
          "Печь перед открыванием должна быть заполнена азотом",
        ],
        correctAnswers: [
          "Металлический инструмент, применяемый при обслуживании индукционных печей, должен иметь электроизолированные ручки",
          "При проведении на печи работ, связанных с применением неизолированного металлического инструмента, печь должна быть отключена",
        ],
      },
      {
        code: "20306149",
        text: "На основании какого документа должна определяться периодичность измерения напряженности и плотности потока энергии электромагнитных полей на рабочих местах при обслуживании электропечей?",
        answers: [
          "На основании технологической документации",
          "На основании производственной документации",
          "На основании паспорта объекта",
          "На основании организационно-распорядительного документа эксплуатирующей организации",
        ],
        correctAnswers: ["На основании производственной документации"],
      },
      {
        code: "20306150",
        text: "Чем должна заполняться электропечь в случае пробоя индуктора током и проникновения воды в вакуумную камеру? Выберите два варианта ответа.",
        answers: ["Кислородом", "Инертным газом", "Воздухом", "Азотом"],
        correctAnswers: ["Инертным газом", "Воздухом"],
      },
      {
        code: "20306151",
        text: "Какое из перечисленных требований допускается при обслуживании и эксплуатации электропечей?",
        answers: [
          "Вход обслуживающего персонала внутрь вакуумной камеры разрешается только после отключения плазмотрона",
          "При установке электрода в печь величина дуги должна превышать зазор между электродом и стенками кристаллизатора",
          "При прогаре водоохлаждаемых элементов печи и попадания воды в зону плавления печь должна быть немедленно отключена",
          "Порядок запуска и отключения плазмотронов устанавливается паспортом печи",
        ],
        correctAnswers: [
          "При прогаре водоохлаждаемых элементов печи и попадания воды в зону плавления печь должна быть немедленно отключена",
        ],
      },
      {
        code: "20306152",
        text: "В соответствии с чем должны проводить контроль состояния подшипников и проверку цапф конверторов?",
        answers: [
          "В соответствии с паспортом объекта",
          "В соответствии с технологической документацией",
          "В соответствии с производственной документацией",
          "В соответствии с организационно-распорядительным документом эксплуатирующей организации",
        ],
        correctAnswers: ["В соответствии с производственной документацией"],
      },
      {
        code: "20306153",
        text: "В каком месте конвертера допускается накопление настылей и скрапа в соответствии с технологической документацией?",
        answers: [
          "На кожухе",
          "На горловине",
          "На опорном кольце",
          "Ни в каком месте",
        ],
        correctAnswers: ["Ни в каком месте"],
      },
      {
        code: "20306154",
        text: "Давление какого газа в донных фурмах после заливки жидкого чугуна должно быть больше величины ферростатического давления жидкого металла в конвертере?",
        answers: [
          "Только аргона",
          "Только азота",
          "Только природного газа",
          "Только коксового газа",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20306155",
        text: "Какое из перечисленных требований допускается при эксплуатации устройства для отвода, охлаждения и очистки конвертерных газов?",
        answers: [
          "Работа конвертера при наличии течи в охладителе",
          "Производство работ под конвертером только во время очистки охладителя конвертерных газов",
          "Вскрытие люков, лазов, гидрозатворов, предохранительных клапанов газоотводящего тракта при работающем конвертере",
          "Гидросмыв шлама с рабочих площадок и строительных конструкций только при остановке конвертера",
        ],
        correctAnswers: ["Работа конвертера при наличии течи в охладителе"],
      },
      {
        code: "20306156",
        text: "Какое максимальное содержание кислорода должно быть во время плавки при содержании оксида углерода в газе за дымососом конвертера 10% и более?",
        answers: [
          "3% (по объему)",
          "5% (по объему)",
          "4% (по объему)",
          "2% (по объему)",
        ],
        correctAnswers: ["2% (по объему)"],
      },
      {
        code: "20306157",
        text: "Где должна производиться регистрация показаний постоянного быстродействующего замера содержания оксида углерода и кислорода в дымоходе за дымососом? Выберите два варианта ответа.",
        answers: [
          "На щите управления газоотводящего тракта",
          "На щите пульта управления конвертером",
          "На пульте диспетчерской службы",
          "На рабочей площадке конвертера",
        ],
        correctAnswers: [
          "На щите управления газоотводящего тракта",
          "На щите пульта управления конвертером",
        ],
      },
      {
        code: "20306158",
        text: "Какие из перечисленных характеристик не должны быть указаны в технической документации на исходные легковоспламеняющиеся порошковые материалы?",
        answers: [
          "Группа горючести",
          "Нормы расхода материалов",
          "Нижний концентрационный предел распространения пламени",
          "Температура воспламенения аэровзвеси",
        ],
        correctAnswers: ["Нормы расхода материалов"],
      },
      {
        code: "20306159",
        text: "Кем должна определяться удельная теплота и температура процесса горения легковоспламеняющейся смеси?",
        answers: [
          "Разработчиком",
          "Изготовителем",
          "Эксплуатирующей организацией",
        ],
        correctAnswers: ["Разработчиком"],
      },
      {
        code: "20306160",
        text: "Какими средствами необходимо тушить загоревшиеся легковоспламеняющиеся порошковые материалы и смеси?",
        answers: [
          "Водой",
          "Пенными огнетушителями",
          "Способами и средствами, рекомендованными изготовителями и специализированными организациями",
        ],
        correctAnswers: [
          "Способами и средствами, рекомендованными изготовителями и специализированными организациями",
        ],
      },
      {
        code: "20306161",
        text: "Кем должен утверждаться график отбора проб пыли для определения ее способности к самостоятельному горению и температуры самовоспламенения с поверхности оборудования и из аспирационных систем?",
        answers: [
          "Ответственным за безопасное производство работ",
          "Начальником участка",
          "Руководителем эксплуатирующей организации",
          "Техническим руководителем организации",
        ],
        correctAnswers: ["Техническим руководителем организации"],
      },
      {
        code: "20306162",
        text: "Какие из перечисленных легковоспламеняющихся смесей допускается применять при производстве стали?",
        answers: [
          "Смеси, способные к самостоятельному горению и имеющие удельную теплоту процесса горения более 50 кДж/моль",
          "Смеси, чувствительность которых к механическому воздействию (удару) составляет 19,6 Дж и менее, а активной составляющей - 9,8 Дж и менее",
          "Смеси, способные к самостоятельному горению без доступа воздуха",
          "Смеси, процесс горения которых переходит во взрыв",
        ],
        correctAnswers: [
          "Смеси, способные к самостоятельному горению без доступа воздуха",
        ],
      },
      {
        code: "20306163",
        text: "Какая информация должна быть указана на каждом стопоре перед загрузкой в сушила?",
        answers: [
          "Дата и время постановки стопора на сушку",
          "Дата производства стопора",
          "Данные ответственного работника, производящего сушку",
          "Только время постановки стопора на сушку",
        ],
        correctAnswers: ["Дата и время постановки стопора на сушку"],
      },
      {
        code: "20306164",
        text: "Кем должно быть проверено качество просушки желоба и стыка?",
        answers: [
          "Начальником участка",
          "Сталеваром печи",
          "Ответственным за безопасное производство работ",
          "Ответственным работником, назначенным приказом",
        ],
        correctAnswers: ["Сталеваром печи"],
      },
      {
        code: "20306165",
        text: "Какие действия необходимо осуществить в тех случаях, когда была допущена разливка стали напротив ремонтируемой мартеновской печи?",
        answers: [
          "Ремонтные работы в шлаковиках должны быть прекращены",
          "Работники дожны быть удалены в безопасное место",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20306166",
        text: "Какое из перечисленных требований допускается при выпуске, разливке и уборке стали?",
        answers: [
          "Накрывание крышками изложниц с кипящей сталью должно производиться до образовании ранта затвердевшего металла у стенок изложниц",
          "Съем крышек с изложниц должен производиться при полном застывании верха слитка",
          "По окончании разливки стали остатки жидкого шлака из сталеразливочного ковша должны оставаться в ковше",
          "Находиться работникам на бортах изложниц, наполненных жидким металлом",
        ],
        correctAnswers: [
          "Съем крышек с изложниц должен производиться при полном застывании верха слитка",
        ],
      },
      {
        code: "20306167",
        text: "При каком условии могут изменяться режимы работ и основные технологические параметры грануляционных установок?",
        answers: [
          "По утвержденному техническим руководителем графику",
          "По согласованию с проектной организацией",
          "По согласованию с экспертной организацией",
          "По согласованию с техническим руководителем эксплуатирующей организации",
        ],
        correctAnswers: ["По согласованию с проектной организацией"],
      },
      {
        code: "20306168",
        text: "В какие сроки в помещениях категории А должна осуществляться проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией?",
        answers: [
          "Не реже одного раза в 2 месяца",
          "Не реже одного раза в месяц",
          "Не реже одного раза в 3 месяца",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20306169",
        text: "Каково минимально допустимое давление газа непосредственно перед потребителем (после регулирующих органов)?",
        answers: ["0,3 кПа", "0,4 кПа", "0,5 кПа"],
        correctAnswers: ["0,5 кПа"],
      },
      {
        code: "20306170",
        text: "Каким образом должен проводиться отогрев трубопроводной арматуры?",
        answers: [
          "Электрическими приборами",
          "Горячим воздухом, паром или горячей водой",
          "С применением открытого пламени",
        ],
        correctAnswers: ["Горячим воздухом, паром или горячей водой"],
      },
      {
        code: "20306171",
        text: "Какой установлен порядок хранения обтирочных материалов?",
        answers: [
          "В деревянных открытых ящиках",
          "В специально выделенном помещении в пластиковых ящиках",
          "В чистых металлических ящиках с крышками",
        ],
        correctAnswers: ["В чистых металлических ящиках с крышками"],
      },
      {
        code: "20306172",
        text: "После установления каких показателей температуры разрешается проводить ремонтные работы внутри нагретых технических устройств, а также низкотемпературных технических устройств?",
        answers: [
          "После снижения температуры воздуха до 45 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания",
          "После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания",
          "После снижения температуры воздуха до 50 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания",
        ],
        correctAnswers: [
          "После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания",
        ],
      },
      {
        code: "20306173",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии 0,1 - 0,5 мм/г?",
        answers: [
          "Один раз в 3 года",
          "Один раз в 1,5 года",
          "Один раз в 2 года",
        ],
        correctAnswers: ["Один раз в 2 года"],
      },
      {
        code: "20306174",
        text: "Как часто должна производиться нивелировка действующих газопроводов?",
        answers: ["Один раз в 5 лет", "Один раз в 3 года", "Один раз в год"],
        correctAnswers: ["Один раз в 5 лет"],
      },
      {
        code: "20306175",
        text: "Куда должны заноситься записи о проведении технического обслуживания, выявленных дефектах и нарушениях газопроводов?",
        answers: [
          "В эксплуатационный журнал",
          "В паспорт газопроводов",
          "В журнал учета принятого в эксплуатацию газового оборудования",
        ],
        correctAnswers: ["В эксплуатационный журнал"],
      },
      {
        code: "20306176",
        text: "Из каких материалов выполняются линии отбора кислорода на анализ?",
        answers: [
          "Из чугунных сплавов",
          "Из коррозионно-стойкой стали или медных сплавов",
          "Из низкоуглеродистой стали",
        ],
        correctAnswers: ["Из коррозионно-стойкой стали или медных сплавов"],
      },
      {
        code: "20306177",
        text: "В каких случаях запрещается газовая резка и сварка на действующих газопроводах?",
        answers: [
          "На газопроводах, находящихся под разрежением",
          "На газопроводах, продутых инертным газом",
          "На газопроводах, находящихся под давлением",
        ],
        correctAnswers: ["На газопроводах, находящихся под разрежением"],
      },
      {
        code: "20306178",
        text: "Как часто должна производиться проверка конденсатоотводчиков и пароспутников, обогревающих трубопроводы кислорода в зимнее время?",
        answers: [
          "Ежедневно",
          "Не реже 1 раза в 12 часов",
          "Не реже 1 раза в 48 часов",
        ],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "20306179",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии более 0,5 мм/г?",
        answers: [
          "Один раз в квартал",
          "Один раз в год",
          "Один раз в полугодие",
        ],
        correctAnswers: ["Один раз в год"],
      },
      {
        code: "20306180",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии до 0,1 мм/г?",
        answers: ["Один раз в 4 года", "Один раз в 5 лет", "Один раз в 6 лет"],
        correctAnswers: ["Один раз в 4 года"],
      },
      {
        code: "20306181",
        text: "Как часто должно проверяться исправное действие автоблокировки и сигнализации?",
        answers: [
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в месяц",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20306182",
        text: "Как часто должен производиться осмотр трубопроводов кислорода?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в 6 месяцев",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20306183",
        text: "Из каких сплавов металлов запрещается установка арматуры на кислородопроводах?",
        answers: [
          "Из сплавов алюминия",
          "Их сплавов стали",
          "Из сплавов титана",
        ],
        correctAnswers: ["Из сплавов титана"],
      },
      {
        code: "20306184",
        text: "Как часто должна проводиться проверка правильности показаний приборов, перечень которых утверждается руководителем производства?",
        answers: [
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в 12 месяцев",
        ],
        correctAnswers: ["Не реже одного раза в 3 месяца"],
      },
      {
        code: "20306185",
        text: "Разрешено ли использовать продувочные свечи для выпуска в атмосферу избыточного газа?",
        answers: [
          "Разрешено при повышении давления газа на 10% от рабочего",
          "Не разрешено",
          "Только в аварийных случаях",
        ],
        correctAnswers: ["Только в аварийных случаях"],
      },
      {
        code: "20306186",
        text: "Какая допускается максимальная температура газа в газопроводах в случае подачи в них пара для пропарки, продувки и отогрева?",
        answers: ["100 °C", "90 °C", "85 °C"],
        correctAnswers: ["85 °C"],
      },
      {
        code: "20306187",
        text: "На что должен проверяться выполненный сварной шов после врезки в действующий газопровод?",
        answers: ["На герметичность", "На плотность", "На прочность"],
        correctAnswers: ["На плотность"],
      },
      {
        code: "20306188",
        text: "Что необходимо устанавливать в производственных помещениях, где возможны воспламенение одежды или химические ожоги у работников?",
        answers: [
          "Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
          "Ящики с песком, углекислотные или порошковые огнетушители",
          "Брезентовые или асбестовые покрывала",
        ],
        correctAnswers: [
          "Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
      },
      {
        code: "20306189",
        text: "Из каких материалов не могут быть изготовлены разделители (заглушки) или другие изделия, помещаемые внутри кислородопровода при его обезжиривании?",
        answers: [
          "Из поролона или других органических материалов",
          "Из цветных металлов",
          "Из бумажных и картонных материалов",
        ],
        correctAnswers: ["Из поролона или других органических материалов"],
      },
      {
        code: "20306190",
        text: "Какие требования установлены к оборудованию производственных помещений, в которых возможны воспламенения одежды или химические ожоги?",
        answers: [
          "Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
          "Должны быть в наличии закрытые ящики с песком и асбестовое покрывало",
          "Должны быть в наличии огнетушители и средства индивидуальной защиты органов дыхания и глаз",
        ],
        correctAnswers: [
          "Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
      },
      {
        code: "20306191",
        text: "Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях?",
        answers: [
          "Не реже одного раза в смену",
          "Не реже одного раза в месяц",
          "Не реже одного раза в неделю",
        ],
        correctAnswers: ["Не реже одного раза в неделю"],
      },
      {
        code: "20306192",
        text: "Какие требования установлены к инструментам и приспособлениям, используемым во взрывопожароопасных зонах и помещениях?",
        answers: [
          "Не должны давать искры при работе с ними",
          "Должны быть изготовлены из стали",
          "Не должны быть изготовлены из цветных металлов",
        ],
        correctAnswers: ["Не должны давать искры при работе с ними"],
      },
      {
        code: "20306193",
        text: "При каких условиях допускается перенос сроков проведения капитальных ремонтов основного технологического оборудования?",
        answers: [
          "При наличии разрешения завода-изготовителя",
          "При наличии письменного распоряжения технического руководителя предприятия",
          "При наличии положительного заключения экспертного обследования",
        ],
        correctAnswers: [
          "При наличии положительного заключения экспертного обследования",
        ],
      },
      {
        code: "20306194",
        text: "Как часто должны проходить поверку контрольно-измерительные приборы в процессе эксплуатации?",
        answers: [
          "Не реже одного раза в 2 года",
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в год",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20306195",
        text: "Какой должна быть объемная доля кислорода в воздухе производственных помещений производства ПРВ?",
        answers: [
          "Не менее 18% и не более 23%",
          "Не менее 17% и не более 25%",
          "Не менее 19% и не более 23%",
        ],
        correctAnswers: ["Не менее 19% и не более 23%"],
      },
      {
        code: "20306196",
        text: "Какие требования установлены к проведению ремонтных работ внутри нагретых технических устройств?",
        answers: [
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C",
          "Проведение ремонтных работ разрешается после их продувки инертным газом и снижения температуры воздуха в них до 50 °C",
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 55 °C",
        ],
        correctAnswers: [
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C",
        ],
      },
      {
        code: "20306197",
        text: "До розжига газопотребляющего агрегата чем должен быть продут газопровод перед коллектором?",
        answers: ["Водяным паром", "Газом", "Сжатым воздухом"],
        correctAnswers: ["Газом"],
      },
      {
        code: "20306198",
        text: "Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных вне помещений?",
        answers: [
          "Не реже одного раза в квартал",
          "Не реже одного раза в месяц",
          "Не реже одного раза в полгода",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20306199",
        text: "На сколько давление природного газа на входе в установку для подачи природного газа в смеси с кислородом в доменной печи (далее – УГКС) должно быть выше расчетного давления в фурменной зоне доменной печи?",
        answers: [
          "Не менее чем на 0,1 МПа",
          "Не менее чем на 0,2 МПа",
          "Не менее чем на 0,05 МПа",
          "Не менее чем на 0,08 МПа",
        ],
        correctAnswers: ["Не менее чем на 0,2 МПа"],
      },
      {
        code: "20306200",
        text: "Какое допускается максимальное давление кислорода при подаче в УГКС кислорода?",
        answers: ["2,5 МПа", "2,0 МПа", "1,6 МПа", "1,8 МПа"],
        correctAnswers: ["1,6 МПа"],
      },
      {
        code: "20306201",
        text: "Какое значение не должна превышать объемная доля кислорода в смеси с природным газом при использовании газокислородных смесей в доменном производстве?",
        answers: [
          "Не более 50%",
          "Не более 40%",
          "Не более 30%",
          "Не более 25%",
        ],
        correctAnswers: ["Не более 25%"],
      },
      {
        code: "20306202",
        text: "Подвод азота или пара под каким давлением следует предусматривать для продувки трубопроводов кислорода и газокислородной смеси в доменном производстве?",
        answers: [
          "Не менее чем на 0,05 МПа выше давления кислорода",
          "Не менее чем на 0,08 МПа выше давления кислорода",
          "Не менее чем на 0,1 МПа выше давления кислорода",
          "Не менее чем на 0,03 МПа выше давления кислорода",
        ],
        correctAnswers: ["Не менее чем на 0,1 МПа выше давления кислорода"],
      },
      {
        code: "20306203",
        text: "Какое максимальное значение не должна превышать объемная доля кислорода в азоте, подаваемом на продувку трубопроводов кислорода и газокислородной смеси, в доменном производстве?",
        answers: ["3%", "5%", "4%", "6%"],
        correctAnswers: ["3%"],
      },
      {
        code: "20306204",
        text: "Какое допускается максимальное содержание масла в азоте, подаваемом на продувку трубопроводов кислорода и газокислородной смеси, в доменном производстве?",
        answers: ["15 мг/м³", "20 мг/м³", "25 мг/м³", "10 мг/м³"],
        correctAnswers: ["10 мг/м³"],
      },
      {
        code: "20306205",
        text: "В каком случае разрешается эксплуатация азотопровода без обогрева в доменном производстве?",
        answers: [
          "В случае подачи азота в газообразном состоянии",
          "В случае подачи азота в жидком состоянии",
          "В случае подачи сухого азота",
          "В случае подачи азота в твердом состоянии",
        ],
        correctAnswers: ["В случае подачи сухого азота"],
      },
      {
        code: "20306206",
        text: "Где должны регистрироваться расходы природного газа и кислорода, подаваемых на смешение, в доменном производстве?",
        answers: [
          "В оперативном журнале работы доменной печи",
          "В оперативном журнале работы установки для подачи природного газа в смеси с кислородом в доменной печи",
          "В документации автоматизированной системы управления технологическим процессом",
          "В производственной документации диспетчерского пункта газового хозяйства",
        ],
        correctAnswers: ["В оперативном журнале работы доменной печи"],
      },
      {
        code: "20306207",
        text: "Какое количество выходов должен иметь поддоменник, не считая выхода на литейный двор?",
        answers: [
          "Не менее чем 4 выхода, не считая выхода на литейный двор",
          "Не менее чем 2 выхода, не считая выхода на литейный двор",
          "Не менее чем 3 выхода, не считая выхода на литейный двор",
          "Не менее чем 1 выход, не считая выхода на литейный двор",
        ],
        correctAnswers: [
          "Не менее чем 2 выхода, не считая выхода на литейный двор",
        ],
      },
      {
        code: "20306208",
        text: "Что из перечисленного отмечается в техническом паспорте каждого воздухонагревателя? Выберите правильный вариант ответа.",
        answers: [
          "Только результаты периодических осмотров",
          "Только все производимые ремонты с указанием их характера с приложением чертежей, по которым были произведены ремонты",
          "Только лица, выполнявшие сварочные работы, если эти работы выполнялись",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20306209",
        text: "Какое из перечисленных требований к воздухонагревателям в доменном производстве указано неверно?",
        answers: [
          "Не реже 1 раза в год комиссионно должно проверяться техническое состояние воздухонагревателей и их арматура",
          "Не реже 1 раза в 3 года должны производиться замеры аэродинамического сопротивления насадки воздухонагревателей",
          "Помещения КИПиА воздухонагревателей должны иметь принудительную приточно-вытяжную вентиляцию с подогревом подаваемого воздуха в зимнее время",
          "Конструкция подземных боровов воздухонагревателей должна исключать возможность попадания в них грунтовых вод",
        ],
        correctAnswers: [
          "Не реже 1 раза в год комиссионно должно проверяться техническое состояние воздухонагревателей и их арматура",
        ],
      },
      {
        code: "20306210",
        text: "При какой температуре поверхности воздухопроводов горячего дутья принимаются меры по устранению причин, приведших к их перегреву?",
        answers: [
          "Свыше 100 ­°С",
          "Свыше 120 ­°С",
          "Свыше 150 ­°С",
          "Свыше 200 ­°С",
        ],
        correctAnswers: ["Свыше 200 ­°С"],
      },
      {
        code: "20306211",
        text: "Чем должен производиться нагрев воздухонагревателей в доменном производстве?",
        answers: ["Паром", "Очищенным газом", "Пропаном", "Азотом"],
        correctAnswers: ["Очищенным газом"],
      },
      {
        code: "20306212",
        text: "Какой должна быть продолжительность проветривания воздухонагревателя перед повторным зажиганием газа и перед постановкой его на дутьевой режим?",
        answers: [
          "Не менее 5 минут",
          "Не менее 1 минуты",
          "Не менее 10 минут",
          "Не менее 3 минут",
        ],
        correctAnswers: ["Не менее 1 минуты"],
      },
      {
        code: "20306213",
        text: "Куда может осуществляться сброс горячего воздуха, оставшегося в воздухонагревателе при переводе с дутья на нагрев, в зависимости от конструкции (проекта)?",
        answers: [
          "В боров дымовой трубы",
          "В специальный глушитель",
          "В другой воздухонагреватель",
          "В любой из перечисленных",
        ],
        correctAnswers: ["В любой из перечисленных"],
      },
      {
        code: "20306214",
        text: "Какое допускается минимальное давление газа в газопроводах перед воздухонагревателями в доменном производстве?",
        answers: ["150 Па", "350 Па", "500 Па", "200 Па"],
        correctAnswers: ["500 Па"],
      },
      {
        code: "20306215",
        text: "Эксплуатация какого из перечисленного оборудования не допускается в доменном производстве при наличии трещин? Выберите правильный вариант ответа.",
        answers: [
          "Только пылеуловителей",
          "Только газоотводов",
          "Только газопроводов",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20306216",
        text: "Каким образом в эксплуатирующей организации, имеющей газовое хозяйство, производящей и потребляющей горючие газы, должен быть организован контроль за обеспечением безопасной эксплуатации газового хозяйства?",
        answers: [
          "Только путем создания газовой службы",
          "Только назначением ответственных работников",
          "Только заключением договора на оказание услуг по техническому обслуживанию и ремонту газового хозяйства с организацией",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20306217",
        text: "Из каких газов состоят промышленные газы объектов металлургии, возникающие (используемые) в процессе металлургического производства?",
        answers: [
          "Только из природного газа",
          "Только из доменного газа",
          "Только из коксового газа",
          "Только из конвертерного газа",
          "Всех перечисленных, а также реформерного и иных газов",
        ],
        correctAnswers: [
          "Всех перечисленных, а также реформерного и иных газов",
        ],
      },
      {
        code: "20306218",
        text: "Какой предел огнестойкости должен быть у стен, разделяющих взрывоопасные помещения на водородных станциях?",
        answers: ["1 час", "1,5 часа", "2 часа", "2,5 часа"],
        correctAnswers: ["2,5 часа"],
      },
      {
        code: "20306219",
        text: "Какая должна быть минимальная высота забора по периметру территории всего комплекса производства водорода?",
        answers: ["1,8 м", "2 м", "1,9 м", "1,7 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "20306220",
        text: "Какое должно быть расстояние между ресиверами одного газа?",
        answers: [
          "Не менее 1,3 м в свету",
          "Не менее 1,1 м в свету",
          "Не менее 1,5 м в свету",
          "Не менее 1,4 м в свету",
        ],
        correctAnswers: ["Не менее 1,5 м в свету"],
      },
      {
        code: "20306221",
        text: "Какие требования к ресиверам для водорода (сосудам) указаны неверно?",
        answers: [
          "Размещаются на открытых площадках, имеющих по периметру ограждение легкого типа высотой не менее 1,2 м из несгораемого материала",
          'Должны быть поясняющие надписи: "Водород. Взрывоопасно"',
          "Расстояние до ограждения должно быть не менее 1,5 м",
          'Должны быть надписи: "Курить запрещается"',
        ],
        correctAnswers: ['Должны быть надписи: "Курить запрещается"'],
      },
      {
        code: "20306222",
        text: "Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей?",
        answers: [
          "Только блокировками, прекращающими поступление газообразного топлива и воздуха при снижении их давления ниже установленных параметров",
          "Только системами регулирования заданного соотношения топлива, воздуха и водяного пара",
          "Только средствами контроля за уровнем тяги и автоматического прекращения подачи топливного газа в зону горения при остановке дымососа или недопустимом снижении разрежения в печи",
          "Всеми перечисленными средствами",
          "Только средствами автоматической подачи водяного пара или инертного газа в топочное пространство и в змеевики при прогаре труб",
          "Только средствами сигнализации о прекращении поступления топлива, а также воздуха при его принудительной подаче в топочное пространство",
        ],
        correctAnswers: ["Всеми перечисленными средствами"],
      },
      {
        code: "20306223",
        text: "Какие требования из перечисленных должны предусматриваться для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты? Выберите правильный вариант ответа.",
        answers: [
          "Дистанционное отключение",
          "Установка на линиях всасывания и нагнетания запорных или отсекающих устройств",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20306224",
        text: "В каком случае все технологическое оборудование должно продуваться инертным газом? Выберите 2 варианта ответа.",
        answers: [
          "Перед пуском",
          "После остановки более чем на 2 часа",
          "После капитального ремонта",
          "После остановки более чем на 1 час",
        ],
        correctAnswers: ["Перед пуском", "После остановки более чем на 2 часа"],
      },
      {
        code: "20306225",
        text: "При каком содержании водорода в воздухе производственного помещения по сигналу от автоматического газоанализатора технологическое оборудование этого помещения должно быть остановлено?",
        answers: [
          "Выше 28% нижнего предела взрываемости",
          "Выше 32% нижнего предела взрываемости",
          "Выше 30% нижнего предела взрываемости",
          "Выше 25% нижнего предела взрываемости",
        ],
        correctAnswers: ["Выше 25% нижнего предела взрываемости"],
      },
      {
        code: "20306226",
        text: "При каком содержании газов должна срабатывать световая и звуковая сигнализации, установленные с автоматическими газоанализаторами в помещениях категории А, где обращается водород?",
        answers: [
          "При содержании водорода не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости",
          "При содержании водорода не более 15% нижнего предела взрываемости (0,5% об.), кислорода менее 19% и более 23%, угарного газа более 25 мг/м³, метана не более 15% нижнего предела взрываемости",
          "При содержании водорода не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 20% и более 25%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости",
          "При содержании водорода не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 25%, угарного газа более 30 мг/м³, метана не более 15% нижнего предела взрываемости",
        ],
        correctAnswers: [
          "При содержании водорода не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости",
        ],
      },
      {
        code: "20306227",
        text: "Какие требования к вентиляции помещения, где возможно выделение водорода, указаны верно? Выберите 2 варианта ответа.",
        answers: [
          "Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час",
          "При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 2 в час",
          "При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 4 в час",
          "При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час",
        ],
        correctAnswers: [
          "Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час",
          "При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час",
        ],
      },
      {
        code: "20306228",
        text: "Какие из перечисленных ограждающих конструкций не относятся к легкосбрасываемым?",
        answers: [
          "Противопожарные двери",
          "Окна (когда оконные переплеты заполнены обычным оконным стеклом толщиной 3, 4 и 5 мм, площадью не менее соответственно 0,8, 1 и 1,5 м²)",
          "Конструкции из асбоцементных, алюминиевых и стальных листов с легким утеплением",
          "Фонарные переплеты",
        ],
        correctAnswers: ["Противопожарные двери"],
      },
      {
        code: "20306229",
        text: "Какое минимальное остаточное давление должны иметь баллоны, подготовленные для наполнения водородом?",
        answers: ["0,03 МПа", "0,05 МПа", "0,02 МПа", "0,04 МПа"],
        correctAnswers: ["0,05 МПа"],
      },
      {
        code: "20306230",
        text: "На какой высоте от пола должны быть расположены окна в помещениях отделений наполненных баллонов, наполнительных и разрядных рамп?",
        answers: [
          "Не менее 0,5 м от пола",
          "Не менее 1,5 м от пола",
          "Не менее 1 м от пола",
          "Не менее 0,8 м от пола",
        ],
        correctAnswers: ["Не менее 1,5 м от пола"],
      },
      {
        code: "20306231",
        text: "Какой должна быть ширина свободного прохода между щитом управления вентилями и ближайшими стенами наполнительной?",
        answers: [
          "Не менее 0,5 м",
          "Не менее 0,7 м",
          "Не менее 0,8 м",
          "Не менее 0,6 м",
        ],
        correctAnswers: ["Не менее 0,8 м"],
      },
      {
        code: "20306232",
        text: "Стальными листами какой толщины должны отделяться от рабочей зоны баллоны с водородом при их горизонтальном расположении и размещении внутри помещения?",
        answers: [
          "Не менее 5 мм",
          "Не менее 10 мм",
          "Не менее 12 мм",
          "Не менее 16 мм",
        ],
        correctAnswers: ["Не менее 16 мм"],
      },
      {
        code: "20306233",
        text: "При какой чистоте водорода должен производиться пуск компрессора для наполнения баллонов?",
        answers: [
          "Не менее 99,7%",
          "Не менее 99,5%",
          "Не менее 99,2%",
          "Не менее 99,1%",
        ],
        correctAnswers: ["Не менее 99,7%"],
      },
      {
        code: "20306234",
        text: "Какие устройства должны быть установлены на нагнетательном газопроводе для отключения компрессора от коллектора высокого давления?",
        answers: [
          "Только обратный клапан",
          "Только 2 запорных вентиля",
          "Только свеча с условным проходом не менее 6 мм, имеющая прямое сообщение с атмосферой",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20306235",
        text: "На какой ступени сжатия водородные компрессоры должны иметь предохранительные клапаны?",
        answers: [
          "На всех ступенях сжатия",
          "На средней ступени сжатия",
          "На последней ступени сжатия",
          "На первой ступени сжатия",
        ],
        correctAnswers: ["На всех ступенях сжатия"],
      },
      {
        code: "20306236",
        text: "Какой технической документацией должен быть оснащен каждый компрессорный агрегат или установка, или группа однородных компрессорных установок?",
        answers: [
          "Только паспортом (формуляром) на компрессорную установку",
          "Только схемой трубопроводов",
          "Только инструкцией (руководством) по эксплуатации (безопасному обслуживанию) компрессорной установки",
          "Только журналом учета работы компрессора",
          "Только паспортами-сертификатами компрессорного масла и результатами его лабораторного анализа",
          "Только графиком ремонтов компрессорной установки",
          "Всей перечисленной",
        ],
        correctAnswers: ["Всей перечисленной"],
      },
      {
        code: "20306237",
        text: "Какие требования к компрессорным установкам указаны неверно?",
        answers: [
          "Компрессор и его электродвигатель должны устанавливаться на фундаментах, не связанных с конструкциями здания",
          "Система противоаварийной защиты должна обеспечивать только звуковую и световую сигнализацию при понижении давления масла для смазки механизма движения ниже допустимой",
          "Двери и окна помещения, где расположены компрессорные установки, должны открываться наружу",
          "Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи",
        ],
        correctAnswers: [
          "Система противоаварийной защиты должна обеспечивать только звуковую и световую сигнализацию при понижении давления масла для смазки механизма движения ниже допустимой",
        ],
      },
      {
        code: "20306238",
        text: "Какие требования к компрессорным установкам указаны верно?",
        answers: [
          "Перед пуском каждого компрессора машинист обязан осмотреть установку, убедиться в ее исправности, проверить систему смазки и охлаждения и произвести пуск в соответствии с инструкцией",
          "Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи",
          "Двери и окна помещения, где расположены компрессорные установки, должны открываться наружу",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20306239",
        text: "С какой периодичностью должен производиться осмотр газопроводов, газовых установок и газового оборудования комиссией на производстве?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
          "Не реже 2 раз в год",
          "Не реже 1 раза в 3 года",
        ],
        correctAnswers: ["Не реже 2 раз в год"],
      },
      {
        code: "20306240",
        text: "Кто из перечисленных лиц не должен входить в состав постоянно действующей комиссии для проведения осмотров объектов газового хозяйства?",
        answers: [
          "Ответственный за производственный контроль",
          "Ответственный за безопасную эксплуатацию и техническое состояние газового хозяйства цеха",
          "Ответственные за безопасную эксплуатацию и техническое состояние соответствующих технических устройств, сооружений и оборудования",
          "Технический руководитель эксплуатирующей организации",
        ],
        correctAnswers: [
          "Технический руководитель эксплуатирующей организации",
        ],
      },
      {
        code: "20306241",
        text: "С какой периодичностью должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях, и вне помещений?",
        answers: [
          "В помещениях, должна проверяться не реже 1 раза в неделю, а вне помещений - не реже 1 раза в 2 месяца",
          "В помещениях, должна проверяться не реже 1 раза в неделю, а вне помещений - не реже 1 раза в месяц",
          "В помещениях, должна проверяться не реже 2 раз в месяц, а вне помещений - не реже 1 раза в месяц",
          "Не реже 1 раза в месяц проверяются все трубы",
        ],
        correctAnswers: [
          "В помещениях, должна проверяться не реже 1 раза в неделю, а вне помещений - не реже 1 раза в месяц",
        ],
      },
      {
        code: "20306242",
        text: "С какой периодичностью должна производиться ревизия с разборкой регулятора давления, предохранительных клапанов, фильтров газопроводов, газовых установок и газового оборудования?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 5 лет",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "20306243",
        text: "До каких пор должна осуществляться продувка азотом перед пуском в работу установки по производству реформерного газа для производства металлизованного горячевосстановленного железа?",
        answers: [
          "Пока на участке продувки кислорода будет не более 2% кислорода",
          "Пока на участке продувки кислорода будет не более 3% кислорода",
          "Пока на участке продувки кислорода будет не более 1% кислорода",
          "Пока на участке продувки кислорода будет не более 5% кислорода",
        ],
        correctAnswers: [
          "Пока на участке продувки кислорода будет не более 1% кислорода",
        ],
      },
      {
        code: "20306244",
        text: "Какое из перечисленных требований к установке по производству реформерного газа указано неверно?",
        answers: [
          "Перед розжигом горелок реформера должна быть произведена продувка его топочного пространства",
          "Для регулирования давления реформерного газа после холодильника и сброса его на свечу должны быть установлены регулирующие клапаны, работающие в ручном или автоматическом режиме",
          "При остановке агрегатов по производству реформерного газа подача воздуха и природного газа на горелки должна быть закрыта",
          "Допускается продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами",
        ],
        correctAnswers: [
          "Допускается продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами",
        ],
      },
      {
        code: "20306245",
        text: "Кто утверждает инструкцию по перекачке сероуглерода из транспортной емкости в емкость хранения?",
        answers: [
          "Главный инженер предприятия",
          "Ответственный за безопасное производство работ",
          "Главный технолог предприятия",
          "Руководитель предприятия",
        ],
        correctAnswers: ["Главный инженер предприятия"],
      },
      {
        code: "20306246",
        text: "Кем производится испытание газопроводов и газовых установок после окончания строительно-монтажных и сварочных работ (включая ремонтные работы)?",
        answers: [
          "Комиссией предприятия-заказчика, с участием представителя Ростехнадзора",
          "Строительно-монтажной организацией с участием представителя предприятия",
          "Комиссией эксплуатирующей организации с участием представителя подрядной организации",
          "Подрядной организацией, выполнившей строительно-монтажные и сварочные работы",
        ],
        correctAnswers: [
          "Строительно-монтажной организацией с участием представителя предприятия",
        ],
      },
      {
        code: "20306247",
        text: "Какое из перечисленных требований к манометрам, используемым при проведении испытаний газопроводов и газовых установок, указано верно?",
        answers: [
          "Диаметр корпуса манометра должен быть не менее 160 мм и шкалой на номинальное давление не менее 4/3 и не более 5/3 от величины измеряемого",
          "Давление должно измеряться двумя манометрами, прошедшими поверку и опломбированными",
          "Класс точности манометров должен быть не ниже 1,5",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20306248",
        text: "В течение какого времени выдерживают испытательное давление в трубопроводе при испытании на прочность, после чего снижают до рабочего давления, при котором производят тщательный осмотр сварных швов (испытание на плотность)?",
        answers: [
          "В течение 15 минут",
          "В течение 10 минут",
          "В течение 20 минут",
          "В течение 30 минут",
        ],
        correctAnswers: ["В течение 10 минут"],
      },
      {
        code: "20306249",
        text: "Какое устанавливается минимальное расстояние от испытываемого газопровода большого диаметра, расположенных вне помещений, до границ охраняемой зоны во время проведения пневматических испытаний на прочность?",
        answers: ["25 м", "30 м", "40 м", "50 м"],
        correctAnswers: ["50 м"],
      },
      {
        code: "20306250",
        text: "Каким давлением производится дополнительное испытание на герметичность межцеховых и внутрицеховых газопроводов?",
        answers: [
          "Давлением, равным 1,8 рабочего",
          "Давлением, равным 1,2 рабочего",
          "Давлением, равным рабочему",
          "Давлением, равным 1,5 рабочего",
        ],
        correctAnswers: ["Давлением, равным рабочему"],
      },
      {
        code: "20306251",
        text: "Какая установлена продолжительность дополнительных испытаний на герметичность для вновь сооружаемых газопроводов?",
        answers: [
          "Не менее 24 ч",
          "Не менее 10 ч",
          "Не менее 12 ч",
          "Не менее 6 ч",
        ],
        correctAnswers: ["Не менее 24 ч"],
      },
      {
        code: "20306252",
        text: "Какая устанавливается продолжительность периодических испытаний на герметичность для газопроводов, а также испытаний после ремонта, связанного со сваркой и разборкой газопровода?",
        answers: [
          "Не менее 1 ч",
          "Не менее 4 ч",
          "Не менее 2 ч",
          "Не менее 3 ч",
        ],
        correctAnswers: ["Не менее 4 ч"],
      },
      {
        code: "20306253",
        text: "При какой скорости падения давления за время дополнительного пневматического испытания на герметичность внутрицеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?",
        answers: [
          "Не более 0,5% в час",
          "Не более 0,3% в час",
          "Не более 0,1% в час",
          "Не более 0,2% в час",
        ],
        correctAnswers: ["Не более 0,1% в час"],
      },
      {
        code: "20306254",
        text: "При какой скорости падения давления за время дополнительного пневматического испытания на герметичность межцеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?",
        answers: [
          "Не более 0,5% в час",
          "Не более 0,3% в час",
          "Не более 0,1% в час",
          "Не более 0,2% в час",
        ],
        correctAnswers: ["Не более 0,2% в час"],
      },
      {
        code: "20306255",
        text: "У кого должен находиться агрегатный (ремонтный) журнал на отдельных объектах газового цеха, а также в цехах, в составе которых имеются объекты газового хозяйства?",
        answers: [
          "У начальника участка",
          "У работника, ответственного за безопасное производство работ",
          "У работника, ответственного за техническое состояние данного объекта газового хозяйства",
          "У технического руководителя предприятия",
        ],
        correctAnswers: [
          "У работника, ответственного за техническое состояние данного объекта газового хозяйства",
        ],
      },
      {
        code: "20306256",
        text: "При каком избыточном давлении газа в газопроводах разрешается производить электросварочные работы на газопроводах при ремонтах?",
        answers: [
          "Не более 0,2 кПа",
          "Не более 0,3 кПа",
          "Не менее 0,6 кПа",
          "Не менее 0,5 кПа",
        ],
        correctAnswers: ["Не более 0,3 кПа"],
      },
      {
        code: "20306257",
        text: "Какие действия должны быть произведены перед продувкой газопровода? Выберите правильный вариант ответа.",
        answers: [
          "Задвижки должны быть установлены в нужное положение",
          "Водяные затворы залиты водой",
          "Все люки, лазы и свечи плотно закрыты, за исключением той свечи, через которую будет производиться продувка",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20306258",
        text: "В каком радиусе у мест выброса газовоздушной смеси в атмосферу должно быть приостановлено движение всех видов транспорта, запрещено применение открытого огня и производство сварочных работ в период продувки газопровода?",
        answers: [
          "В радиусе 50 м",
          "В радиусе 30 м",
          "В радиусе 20 м",
          "В радиусе 40 м",
        ],
        correctAnswers: ["В радиусе 50 м"],
      },
      {
        code: "20306259",
        text: "Какому классу должна соответствовать герметичность арматуры с металлическим уплотнением в затворе, применяемая для установки на трубопроводах водорода?",
        answers: [
          "Классу герметичности B",
          "Классу герметичности А",
          "Классу герметичности С",
          "Классу герметичности D",
        ],
        correctAnswers: ["Классу герметичности B"],
      },
      {
        code: "20306260",
        text: "Какому классу должна соответствовать герметичность затвора запорной арматуры со средой водорода?",
        answers: [
          "Классу герметичности B",
          "Классу герметичности А",
          "Классу герметичности С",
          "Классу герметичности D",
        ],
        correctAnswers: ["Классу герметичности А"],
      },
      {
        code: "20306261",
        text: "Какую арматуру не допускается применять на водородопроводах?",
        answers: [
          "Из серого чугуна",
          "Стальную",
          "Из ковкого чугуна",
          "Из высокопрочного чугуна",
        ],
        correctAnswers: ["Из серого чугуна"],
      },
      {
        code: "20306262",
        text: "В соответствии с требованиями какой документации должны производиться проектирование, монтаж и эксплуатация водородопроводов, а также вспомогательных трубопроводов обвязки участков комплекса получения и потребления водорода?",
        answers: [
          "Только строительных норм и правил",
          "Только нормативно-технической документации для технологических стальных трубопроводов",
          "Только правил безопасности процессов получения или применения металлов",
          "Всей перечисленной",
        ],
        correctAnswers: ["Всей перечисленной"],
      },
      {
        code: "20306263",
        text: "В каком случае допускается использовать продувочные свечи газопроводов для выпуска в атмосферу избыточного газа?",
        answers: [
          "В аварийном случае",
          "По распоряжению главного инженера",
          "В любом случае",
          "Ни в каком случае",
        ],
        correctAnswers: ["В аварийном случае"],
      },
      {
        code: "20306264",
        text: "Какие сроки проведения ревизии газопроводов должны быть при скорости коррозии более 0,5 мм/г?",
        answers: [
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в год",
          "Не реже 1 раза в 4 года",
        ],
        correctAnswers: ["Не реже 1 раза в 4 года"],
      },
      {
        code: "20306265",
        text: "При какой скорости коррозии срок проведения ревизии газопроводов должен быть не реже одного раза в 2 года?",
        answers: ["До 0,5 мм/г", "0,1 - 0,5 мм/г", "До 0,1 мм/г"],
        correctAnswers: ["0,1 - 0,5 мм/г"],
      },
      {
        code: "20306266",
        text: "Какой допускается максимальный подъем температуры газа в газопроводах?",
        answers: ["95 °C", "100 °C", "90 °C", "85 °C"],
        correctAnswers: ["85 °C"],
      },
      {
        code: "20306267",
        text: "В каком случае допускается подача газа без поднесения запальника, факела или другого воспламеняющего средства к горелке газопровода?",
        answers: [
          "В случае , если температура кладки в разогретой зоне розжига факела превышает 500 °C",
          "В случае , если температура кладки в разогретой зоне розжига факела превышает 800 °C",
          "В случае , если температура кладки в разогретой зоне розжига факела превышает 600 °C",
          "В случае , если температура кладки в разогретой зоне розжига факела превышает  700 °C",
        ],
        correctAnswers: [
          "В случае , если температура кладки в разогретой зоне розжига факела превышает 800 °C",
        ],
      },
      {
        code: "20306268",
        text: "Кто осуществляет выбор типа быстродействующего отсекающего клапана на подводе газа к каждому агрегату с дутьевыми горелками?",
        answers: [
          "Организация, эксплуатирующая систему автоматики печи",
          "Специализированная организация",
          "Организация, проектирующая систему автоматики печи",
          "Любая из перечисленных",
        ],
        correctAnswers: ["Организация, проектирующая систему автоматики печи"],
      },
      {
        code: "20306269",
        text: "Какое из перечисленных требований при эксплуатации газового оборудования газопотребляющих агрегатов указано верно?",
        answers: [
          "Остановка газоиспользующего оборудования для ремонта во всех случаях, за исключением аварийного останова, должна производиться после получения письменного разрешения ответственного за безопасную эксплуатацию газоиспользующего оборудования",
          "Газоиспользующее оборудование при выводе из эксплуатации на срок более трех суток для проведения ремонтных работ или при переводе его в режим резерва должно отключаться с установкой заглушек на газопроводах",
          "На хвостовике заглушки, выступающем за пределы фланцев, должно быть выбито клеймо с указанием диаметра газопровода, на который ее можно устанавливать, и давления газа, на который она рассчитана",
          "При выводе газоиспользующего оборудования в резерв или при ремонте должны быть приняты меры по консервации поверхности нагрева в соответствии с документацией изготовителя",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20306270",
        text: "С какой периодичностью должна производиться режимная наладка газоиспользующего оборудования?",
        answers: [
          "Не реже 1 раза в 4 года",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
        ],
        correctAnswers: ["Не реже 1 раза в 3 года"],
      },
      {
        code: "20306271",
        text: "В каком случае допускается режимную наладку газоиспользующего оборудования проводить не реже одного раза в четыре года?",
        answers: [
          "При стабильной работе газоиспользующего оборудования",
          "При систематическом контроле выбросов загрязняющих веществ в атмосферу",
          "При нарушении работы выбросов загрязняющих веществ в атмосферу",
        ],
        correctAnswers: [
          "При стабильной работе газоиспользующего оборудования",
        ],
      },
      {
        code: "20306272",
        text: "С какой периодичностью должна производиться проверка технического состояния вентиляционных каналов в зданиях (помещениях) с установленным газоиспользующим оборудованием и пылеуборка, и дезинфекция вентиляционных каналов?",
        answers: [
          "Не реже 2 раз в год - проверка, не реже 1 раза в 3 года - пылеуборка и дезинфекция",
          "Не реже 1 раза в 3 года - проверка, не реже 1 раза в 2 года - пылеуборка и дезинфекция",
          "Не реже 1 раза в год - проверка, не реже 1 раза в 2 года - пылеуборка и дезинфекция",
          "Не реже 1 раза в 2 года - проверка, не реже 1 раза в 3 года - пылеуборка и дезинфекция",
        ],
        correctAnswers: [
          "Не реже 2 раз в год - проверка, не реже 1 раза в 3 года - пылеуборка и дезинфекция",
        ],
      },
      {
        code: "20306273",
        text: "С какой периодичностью должны производиться проверка технического состояния промышленных дымоотводящих устройств (газоходов газоиспользующего оборудования, дымоходов и дымовых труб) и их прочистка?",
        answers: [
          "Не реже 1 раза в 3 года",
          "Не реже 2 раз в год",
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
        ],
        correctAnswers: ["Не реже 2 раз в год"],
      },
      {
        code: "20306274",
        text: "Где не допускается размещение операторных помещений?",
        answers: [
          "В отдельных щитовых помещениях (встроенных или пристроенных), смежных с помещениями с взрывоопасными зонами",
          "В производственных помещениях",
          "В отдельно стоящих зданиях",
        ],
        correctAnswers: ["В производственных помещениях"],
      },
      {
        code: "20306275",
        text: "Какие требования к монтажу и эксплуатации водородопроводов указаны неверно?",
        answers: [
          "Производятся в соответствии с требованиями строительных норм и правил",
          "Производятся в соответствии с нормативно-технической документации для технологических стальных трубопроводов",
          "При прокладке трубопроводов водорода следует, как правило, использовать бесшовные стальные трубы или трубопроводы из нержавеющей стали, соединенные с применением сварки",
          "Прокладка трубопроводов должна быть подземной",
        ],
        correctAnswers: ["Прокладка трубопроводов должна быть подземной"],
      },
      {
        code: "20306276",
        text: "Какая продолжительность пневматических испытаний установлена Правилами безопасности процессов получения или применения металлов после проведения плановых или аварийных остановок водородопроводов?",
        answers: ["1 час", "6 часов", "3 часа", "8 часов"],
        correctAnswers: ["1 час"],
      },
      {
        code: "20306277",
        text: "Через какое время после ввода объекта в эксплуатацию должна проводиться первая выборочная ревизия водородопроводов на вновь осваиваемых производствах?",
        answers: [
          "Не позднее чем через 3 года",
          "Не позднее чем через 4 года",
          "Не позднее чем через 2 года",
          "Не позднее чем через год",
        ],
        correctAnswers: ["Не позднее чем через 4 года"],
      },
      {
        code: "20306278",
        text: "С какой периодичностью должны проводиться испытания на плотность и прочность холодных участков (с температурой до 200 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?",
        answers: [
          "1 раз в 5 лет",
          "1 раз в 3 года",
          "1 раз в 8 лет",
          "1 раз в 6 лет",
        ],
        correctAnswers: ["1 раз в 8 лет"],
      },
      {
        code: "20306279",
        text: "С какой периодичностью должны проводиться испытания на плотность и прочность горячих участков (с температурой 200 - 400 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?",
        answers: [
          "1 раз в 3 года",
          "1 раз в 6 лет",
          "1 раз в 8 лет",
          "1 раз в 5 лет",
        ],
        correctAnswers: ["1 раз в 5 лет"],
      },
      {
        code: "20306280",
        text: "В каких местах допускается прокладка водородопроводов?",
        answers: [
          "По стенам зданий на участках со сплошным остеклением и по легкосбрасываемым ограждающим конструкциям",
          "Над поверхностью земли, на стойках и эстакадах",
          "Через производственные помещения, не связанные с потреблением водорода",
        ],
        correctAnswers: ["Над поверхностью земли, на стойках и эстакадах"],
      },
      {
        code: "20306281",
        text: "Через какие помещения допускается прокладка водородопроводов? Выберите правильный вариант ответа.",
        answers: [
          "Через бытовые, подсобные, административно-хозяйственные, складские помещения",
          "Через производственные помещения, не связанные с потреблением водорода",
          "Через помещения КИП",
          "Через производственные помещения, связанные с потреблением водорода",
        ],
        correctAnswers: [
          "Через производственные помещения, связанные с потреблением водорода",
        ],
      },
      {
        code: "20306282",
        text: "Какие требования к размещению запорной арматуры на водородопроводах указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Запорная и другая арматура должна устанавливаться в местах, удобных для обслуживания",
          "Арматура должна быть закреплена так, чтобы в результате температурных деформаций газопроводов в них не создавались изгибающие напряжения",
          "Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20306283",
        text: "Какие из перечисленных требований к акту-допуску подрядных организаций на опасный производственный объект горно-металлургической промышленности указаны неверно?",
        answers: [
          "Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)",
          "На один объект, площадку, территорию должен оформляться один акт-допуск",
          "Не допускается оформлять акты-допуски, характеризующиеся одинаковыми координатами рабочих зон",
          "Акт-допуск должен оформляться письменно",
        ],
        correctAnswers: [
          "Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)",
        ],
      },
      {
        code: "20306284",
        text: "В каком количестве экземпляров составляется акт-допуск подрядных организаций на опасный производственный объект горно-металлургической промышленности?",
        answers: [
          "В одном экземпляре",
          "В трех экземплярах",
          "В двух экземплярах",
          "В четырех экземплярах",
        ],
        correctAnswers: ["В двух экземплярах"],
      },
      {
        code: "20306285",
        text: "Какие из перечисленных требований к наряду-допуску на работы повышенной опасности указаны неверно? Выберите два правильных ответа.",
        answers: [
          "При перерыве в работе в течение одной смены (обеденный перерыв, перерыв по условиям производства работ) наряд-допуск остается у производителя (руководителя) работ",
          "При допуске бригады к работе допускающий к работе вручает первый экземпляр наряда-допуска производителю работ, а второй экземпляр наряда-допуска хранится вместе с нарядами, действующими на этот момент времени",
          "Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ",
          "После окончания работы наряд-допуск остается у производителя (руководителя) работ",
        ],
        correctAnswers: [
          "Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ",
          "После окончания работы наряд-допуск остается у производителя (руководителя) работ",
        ],
      },
      {
        code: "20306286",
        text: "С кем согласовывается инструкция, определяющая порядок подготовки и безопасного проведения газоопасных работ на опасных производственных объектах горно-металлургической промышленности? Выберите два правильных ответа.",
        answers: [
          "С ГСС",
          "С командиром нештатного аварийно-спасательного формирования",
          "Со службой производственного контроля",
          "С территориальным органом Ростехнадзора",
        ],
        correctAnswers: ["С ГСС", "Со службой производственного контроля"],
      },
      {
        code: "20306287",
        text: "В каких из перечисленных случаях допускается продолжать работы, проводимые по наряду-допуску, на опасных производственных объектах?",
        answers: [
          "Если характер и объемы работ изменены в такой степени, что требуется изменение порядка выполнения работ и схемы отключения технических устройств",
          "Если изменился состав бригады",
          "Если выявлено нарушение условий отключения технических устройств",
          "Если обнаружено несоответствие фактического состояния производства работ требованиям безопасности и охраны труда",
        ],
        correctAnswers: ["Если изменился состав бригады"],
      },
      {
        code: "20306288",
        text: "С кем согласовывается перечень работ повышенной опасности на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "С нештатным аварийно-спасательным формированием",
          "Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля",
          "С территориальным органом Ростехнадзора",
          "С техническим руководителем организации",
        ],
        correctAnswers: [
          "Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля",
        ],
      },
      {
        code: "20306289",
        text: "На какие группы подразделяются газоопасные места на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "На I, II, III и IV группы газоопасности",
          "На I и II группы газоопасности",
          "На I, II и III группы газоопасности",
        ],
        correctAnswers: ["На I и II группы газоопасности"],
      },
      {
        code: "20306290",
        text: "На какой максимальный срок выдается наряд-допуск на работы повышенной опасности?",
        answers: [
          "На 30 календарных дней",
          "На 10 рабочих дней",
          "На 15 календарных дней",
          "На 45 календарных дней",
        ],
        correctAnswers: ["На 30 календарных дней"],
      },
      {
        code: "20306291",
        text: "С кем согласовывается план организации и проведения газоопасной работы?",
        answers: [
          "Только с ответственным представителем подразделения или организации (главным энергетиком)",
          "Со всеми перечисленными",
          "Только со службой производственного контроля",
          "Только с начальником газоспасательной службы",
        ],
        correctAnswers: ["Со всеми перечисленными"],
      },
      {
        code: "20306292",
        text: "Какие действия предпринимаются при необходимости ведения работ после истечения срока действия акта-допуска подрядных организаций на опасный производственный объект горно-металлургической промышленности?",
        answers: [
          "Работы продолжаются без оформления акта-допуска и только в светлое время суток",
          "Составляется новый акт-допуск на следующий срок",
          "Продлевается текущий акт-допуск",
        ],
        correctAnswers: ["Составляется новый акт-допуск на следующий срок"],
      },
      {
        code: "20306293",
        text: "Какое из перечисленных требований к применению жетон-бирочной системы указано неверно?",
        answers: [
          'Ответственный специалист, разобравший схему, принимает меры, исключающие ошибочное или самопроизвольное включение пусковых устройств, на пусковых устройствах вывешивает предупредительные плакаты "Не включать! Работают люди", проверяет отсутствие энергетического потенциала',
          "Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями",
          "Жетон-бирочная система применяется при выводе технических устройств (ТУ) на продолжительную остановку",
          "Жетон-бирки после разборки электрической схемы ТУ передаются от технологического персонала ремонтному персоналу с отметкой всех этапов в журнале выдачи и приема жетон-бирок",
        ],
        correctAnswers: [
          "Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями",
        ],
      },
      {
        code: "20306294",
        text: "Какие работы производятся на ОПО горно-металлургических производств? Выберите правильный вариант ответа.",
        answers: [
          "Только постоянные работы, выполняемые в соответствии с документированной процедурой (инструкцией, картой, регламентом), принятой в организации",
          "Все перечисленные",
          "Только работы, связанные с аварийными ситуациями",
          "Только работы повышенной опасности (сложные, разовые, уникальные)",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20306295",
        text: "Какие действия должны быть выполнены при утрате наряда-допуска на работы повышенной опасности?",
        answers: [
          "Работы должны быть остановлены. Для продолжения работ должна быть внесена запись в журнал регистрации нарядов-допусков на работы повышенной опасности",
          "Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново",
          "Работы должны быть продолжены. Оформление нового наряда-допуска не требуется",
          "Решение о продолжении или прекращении работ должен принять работник, выдавший наряд-допуск. Допуск к работе разрешается только после проведения повторного инструктажа",
        ],
        correctAnswers: [
          "Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново",
        ],
      },
      {
        code: "20306296",
        text: "Какой стаж работы в газовом хозяйстве должны иметь лица, назначаемые ответственными руководителями и ответственными исполнителями газоопасных работ?",
        answers: [
          "Не менее одного года",
          "Не менее двух лет",
          "Не менее пяти лет",
          "Не менее трех лет",
        ],
        correctAnswers: ["Не менее одного года"],
      },
      {
        code: "20306297",
        text: "В течение какого времени хранятся закрытые (подписанные) наряды-допуски на работы повышенной опасности?",
        answers: [
          "В течение одного месяца",
          "В течение трех месяцев",
          "В течение шести месяцев",
          "В течение двенадцати месяцев",
        ],
        correctAnswers: ["В течение одного месяца"],
      },
      {
        code: "20306298",
        text: "Кто определяет (назначает) работников, выполняющих мероприятия по безопасности труда, указанных в наряде-допуске (ответственных исполнителей)?",
        answers: [
          "Работник, ответственный за осуществление производственного контроля",
          "Технический руководитель организации",
          "Выдающий наряд-допуск",
          "Допускающий к работе",
        ],
        correctAnswers: ["Выдающий наряд-допуск"],
      },
      {
        code: "20306299",
        text: "Каким составом должны выполняться газоопасные работы I и II группы газоопасности с образованием взрывопожароопасных смесей?",
        answers: [
          "Не менее двух человек под наблюдением газоспасателя или члена ДГСД",
          "Не менее трех человек под наблюдением газоспасателя или члена добровольной газоспасательной дружины (ДГСД)",
          "Одним человеком под наблюдением газоспасателя или члена ДГСД",
          "Не менее трех человек под руководством руководителя ДГСД",
        ],
        correctAnswers: [
          "Не менее двух человек под наблюдением газоспасателя или члена ДГСД",
        ],
      },
      {
        code: "20306300",
        text: "Кем могут проводиться работы повышенной опасности на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "Только работниками эксплуатирующей организации",
          "Как работниками эксплуатирующей организации, так и работниками подрядных организаций",
          "Только работниками подрядных организаций",
        ],
        correctAnswers: [
          "Как работниками эксплуатирующей организации, так и работниками подрядных организаций",
        ],
      },
      {
        code: "20306301",
        text: "Какое количество производителей (руководителей) работ назначаются на все время действия наряда-допуска на работы повышенной опасности при производстве работ в несколько смен?",
        answers: [
          "Количество производителей (руководителей) работ должен определить работник, ответственный за выдачу наряда-допуска",
          "Один производитель (руководитель) работ по наряду-допуску, независимо от количества смен",
          "Несколько производителей (руководителей) работ в соответствии с количеством смен",
        ],
        correctAnswers: [
          "Несколько производителей (руководителей) работ в соответствии с количеством смен",
        ],
      },
      {
        code: "20306302",
        text: "В течение какого времени хранится журнал регистрации нарядов-допусков со дня внесения последней записи?",
        answers: [
          "В течение 6 месяцев",
          "В течение 1 месяца",
          "В течение 12 месяцев",
          "В течение 3 месяцев",
        ],
        correctAnswers: ["В течение 6 месяцев"],
      },
      {
        code: "20306303",
        text: "На каких минимальных расстояниях (по горизонтали) до групповых газобаллонных установок допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?",
        answers: ["10 м", "8 м", "5 м", "3 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "20306304",
        text: "На каких минимальных расстояниях (по горизонтали) до отдельных баллонов с кислородом и горючими газами допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?",
        answers: ["10 м", "5 м", "8 м", "3 м"],
        correctAnswers: ["5 м"],
      },
      {
        code: "20306305",
        text: "В каком положении должны находиться баллоны со сжиженным газом во время работы?",
        answers: [
          "В вертикальном положении",
          "В наклонном положении",
          "В горизонтальном положении",
          "Требования отсутствуют",
        ],
        correctAnswers: ["В вертикальном положении"],
      },
      {
        code: "20306306",
        text: "Какое действие должно быть осуществлено в первую очередь при зажигании ручной горелки или резака?",
        answers: [
          "Открыт вентиль горючего газа",
          "Зажжена горючая смесь газов",
          "Приоткрыт вентиль кислорода",
        ],
        correctAnswers: ["Приоткрыт вентиль кислорода"],
      },
      {
        code: "20306307",
        text: "Какое из перечисленных действий допускается при эксплуатации оборудования для газопламенной обработки металлов? Выберите правильный вариант ответа.",
        answers: [
          "Размещать стационарные газоразборные посты в металлических шкафах с отверстиями для вентиляции",
          "Открывать дверцы шкафов во время работы",
          "Закрывать шкаф на замок, при отсутствии рабочего, пользующегося газоразборным постом,",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20306308",
        text: "Какое из перечисленных требований при эксплуатации взрывопожароопасных объектов указано неверно?",
        answers: [
          "В помещениях категории A проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией должна производиться в сроки, предусмотренные заводской инструкцией, но не реже одного раза в смену",
          "На каждой двери помещения категории A должны быть указаны категория помещения и класс взрывоопасной зоны",
          "Работы по ремонту электрооборудования в помещениях категории A должны выполняться после обесточивания электросети",
          "В помещениях категории A хранение обтирочных и смазочных материалов допускается в количестве, не превышающем суточной потребности, в металлических, плотно закрывающихся шкафах или ящиках",
        ],
        correctAnswers: [
          "В помещениях категории A проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией должна производиться в сроки, предусмотренные заводской инструкцией, но не реже одного раза в смену",
        ],
      },
      {
        code: "20306309",
        text: "С какой периодичностью должен производиться текущий ремонт оборудования с разборкой регуляторов давления, предохранительных клапанов и фильтров ГРП и ГРУ, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания?",
        answers: [
          "Не менее 1 раза в 2 года",
          "Не менее 1 раза в год",
          "Не менее 1 раза в 3 года",
          "Не менее 1 раза в 5 лет",
        ],
        correctAnswers: ["Не менее 1 раза в год"],
      },
      {
        code: "20306310",
        text: "При каком давлении должна производиться настройка срабатывания предохранительно-запорных клапанов при их наличии в ГРП (ГРУ)?",
        answers: [
          "На 25% больше расчетного",
          "На 20% больше расчетного",
          "На 15% больше расчетного",
          "На 30% больше расчетного",
        ],
        correctAnswers: ["На 15% больше расчетного"],
      },
      {
        code: "20306311",
        text: "В каком случае допускается подача потребителям газа по обводной линии (байпасу) ГРП и ГРУ?",
        answers: [
          "На время ревизии",
          "На время ремонта оборудования",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20306312",
        text: "Какие виды работ должны выполняться не реже одного раза в 6 месяцев при эксплуатации ГРП и ГРУ?",
        answers: [
          "Проверка параметров срабатывания предохранительных запорных и сбросных клапанов",
          "Техническое обслуживание",
          "Текущий ремонт",
          "Капитальный ремонт",
        ],
        correctAnswers: ["Техническое обслуживание"],
      },
      {
        code: "20306313",
        text: "Какие из перечисленных видов работ не должны выполняться при техническом обслуживании технологического оборудования ГРП и ГРУ? Выберите 2 варианта ответа.",
        answers: [
          "Замена изношенных деталей технических устройств",
          "Смазка подвижных элементов запорной арматуры",
          "Восстановление окраски шкафов пунктов редуцирования газа, ограждений, газопроводов обвязки и технических устройств",
          "Очистка помещения и технических устройств пунктов редуцирования газа от загрязнений",
        ],
        correctAnswers: [
          "Замена изношенных деталей технических устройств",
          "Восстановление окраски шкафов пунктов редуцирования газа, ограждений, газопроводов обвязки и технических устройств",
        ],
      },
      {
        code: "20306314",
        text: "В какие сроки должен проводиться капитальный ремонт ГРП и ГРУ?",
        answers: [
          "В сроки, установленные техническим руководителем эксплуатирующей организации",
          "В сроки, установленные руководителем регионального отделения Ростехнадзора",
          "В сроки, установленные изготовителем",
        ],
        correctAnswers: ["В сроки, установленные изготовителем"],
      },
      {
        code: "20306315",
        text: "Чем должна производиться продувка импульсных газовых проводок?",
        answers: [
          "Только паром",
          "Только сжатым воздухом",
          "Только газом",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
    ],
    20307: [
      {
        code: "20307000",
        text: "Что должно быть разработано для обеспечения безопасности при освоении новых производств, технологических процессов и технических устройств?",
        answers: [
          "Инструкции по эксплуатации",
          "Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования",
          "Производственная инструкция",
        ],
        correctAnswers: [
          "Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования",
        ],
      },
      {
        code: "20307001",
        text: "При каких условиях запрещается эксплуатация плавильных агрегатов?",
        answers: [
          "При падении давления воды в системе водоохлаждения на 10 %",
          "При разгерметизации системы водяного охлаждения этих агрегатов",
          "При  повышении давления воды в системе охлаждения на 5 %",
        ],
        correctAnswers: [
          "При разгерметизации системы водяного охлаждения этих агрегатов",
        ],
      },
      {
        code: "20307002",
        text: "Какие требования установлены к фурмам для продувки жидкого металла газами?",
        answers: [
          "Должны быть очищены",
          "Перед установкой фурмы должны храниться в специальном помещении не менее суток",
          "Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями",
        ],
        correctAnswers: [
          "Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями",
        ],
      },
      {
        code: "20307003",
        text: "От чего должны быть защищены составные части производственного оборудования, в том числе энергетические трубопроводы, рукава подачи природного газа, кислорода, мазута, воздуха, масла, воды, троллеи для питания электрического оборудования тележек, перевозящих расплавы металлов, и электрические кабели?",
        answers: [
          "Только от механических воздействий",
          "Только от тепловых воздействий",
          "Только от возможного попадания на них расплава",
          "От всего перечисленного",
        ],
        correctAnswers: ["От всего перечисленного"],
      },
      {
        code: "20307004",
        text: "Какие требования установлены к установке расходных баков с мазутом?",
        answers: [
          "Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением",
          "Должны быть установлены на расстоянии не менее 3 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением",
          "Должны быть установлены на расстоянии не менее 1 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением",
        ],
        correctAnswers: [
          "Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением",
        ],
      },
      {
        code: "20307005",
        text: "Что не допускается к загрузке в агрегаты, содержащие расплавленный металл или шлак?",
        answers: [
          "Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции",
          "Загрузка шихты, не прошедшей пиротехнический контроль",
          "Загрузка пакетированного металлолома",
        ],
        correctAnswers: [
          "Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции",
        ],
      },
      {
        code: "20307006",
        text: "Какие требования установлены к загружаемым в печи руде, ферросплавам и другим материалам?",
        answers: [
          "Должны быть только просушены",
          "Должны быть выдержаны в помещении не менее суток",
          "Должны быть предварительно просушены или прокалены",
        ],
        correctAnswers: ["Должны быть предварительно просушены или прокалены"],
      },
      {
        code: "20307007",
        text: "Какие требования установлены к инструментам, приспособлениям и оснастке, используемым для ведения технологии или обслуживания оборудования?",
        answers: [
          "Должны быть во взрывозащищенном исполнении",
          "Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними",
          "Должны быть предназначены для выполнения любых работ",
        ],
        correctAnswers: [
          "Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними",
        ],
      },
      {
        code: "20307008",
        text: "Какое требование к устройству внутренних производственных помещений, находящихся на производственной площадке, включающей технологию с расплавами металлов, имеющей опасность аварии или травмирования работников, указано неверно?",
        answers: [
          "Помещения пультов управления должны иметь вытяжную вентиляцию",
          "Пульты управления агрегатов должны быть расположены в безопасном месте, при этом обеспечивать видимость агрегата и проводимых работ на площадке",
          "При расположении пультов управления в зоне облучения электродугой должны применяться защитные экраны",
          "Окна закрытых пультов управления должны быть застеклены специальными стеклами с теплоотражающими покрытиями и оборудованы съемными металлическими сетками или специальными защитными экранами",
        ],
        correctAnswers: [
          "Помещения пультов управления должны иметь вытяжную вентиляцию",
        ],
      },
      {
        code: "20307009",
        text: "Как должны быть расположены пульты управления агрегатами?",
        answers: [
          "На расстоянии не более 1 м от агрегатов",
          "На расстоянии не менее 3 м от агрегатов",
          "В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке",
        ],
        correctAnswers: [
          "В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке",
        ],
      },
      {
        code: "20307010",
        text: "Что должны иметь закрома и ямы?",
        answers: [
          "Должны иметь ограждение минимум с двух сторон",
          "Должны иметь по периметру пешеходные  проходы шириной 1,0 м",
          "Со всех сторон должны иметь ограждение",
        ],
        correctAnswers: ["Со всех сторон должны иметь ограждение"],
      },
      {
        code: "20307011",
        text: "Что должно быть предусмотрено при проектировании конвейеров для подачи материалов? Выберите правильный вариант ответа.",
        answers: [
          "Только исключение падения транспортируемых материалов",
          "Только предохраняющие устройства, отключающие приводы при перегрузке",
          "Только ограждения приводных, натяжных и отклоняющих барабанов, натяжных устройств, канатов и блоков натяжных устройств, ременных, червячных, муфтовых и других передач",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20307012",
        text: "Как должна устраняться пробуксовка ленты конвейера?",
        answers: [
          "Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты",
          "Должна устраняться при помощи ручной регулировки",
          "Должна устраняться увеличением трения между тянущим барабаном и лентой с использованием специальных материалов",
        ],
        correctAnswers: [
          "Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты",
        ],
      },
      {
        code: "20307013",
        text: "Что должно быть исключено в коммуникациях системы транспортирования взрывопожароопасных веществ и материалов?",
        answers: [
          "Образование взрывоопасной среды",
          "Попадание воздушной среды",
          "Возможность разгерметизации системы",
        ],
        correctAnswers: ["Образование взрывоопасной среды"],
      },
      {
        code: "20307014",
        text: "На что должна быть проверена система пневмотранспорта перед вводом в эксплуатацию?",
        answers: [
          "На плотность под рабочим давлением",
          "На плотность под избыточным давлением",
          "На плотность под испытательным давлением",
        ],
        correctAnswers: ["На плотность под рабочим давлением"],
      },
      {
        code: "20307015",
        text: "Чем должны быть оборудованы шлаковозы?",
        answers: [
          "Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением",
          "Механизмами кантования (поворота) чаши с ручным пневматическим приводом и автоматическим управлением",
          "Механизмами кантования (поворота) чаши с ручным гидравлическим приводом",
        ],
        correctAnswers: [
          "Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением",
        ],
      },
      {
        code: "20307016",
        text: "Что должно иметься на шлаковом отвале?",
        answers: [
          "Двусторонняя громкоговорящая связь с диспетчерской службой",
          "Телефонная или радиосвязь с диспетчерской службой производства",
          "Мобильная связь с диспетчерской службой предприятия",
        ],
        correctAnswers: [
          "Телефонная или радиосвязь с диспетчерской службой производства",
        ],
      },
      {
        code: "20307017",
        text: "Какие шпалы должны применяться на железнодорожных путях шлакового отвала?",
        answers: [
          "Деревянные шпалы",
          "Деревянные шпалы, пропитанные креозотом",
          "Огнестойкие шпалы",
        ],
        correctAnswers: ["Огнестойкие шпалы"],
      },
      {
        code: "20307018",
        text: "Какие требования установлены к кабине завалочной машины?",
        answers: [
          "Должна быть прочной, теплоизолированной и защищать машиниста от возможных брызг металла и шлака",
          "Конструкция завалочной машины должна обеспечить устойчивое выполнение операций",
          "Все перечисленные требования",
        ],
        correctAnswers: [
          "Должна быть прочной, теплоизолированной и защищать машиниста от возможных брызг металла и шлака",
        ],
      },
      {
        code: "20307019",
        text: "Чему подлежат производственные емкости (ковши, шлаковые чаши, совки, кюбели, тара, корзины) после изготовления или ремонта?",
        answers: [
          "Проверке состояния",
          "Механическим испытаниям",
          "Освидетельствованию",
        ],
        correctAnswers: ["Проверке состояния"],
      },
      {
        code: "20307020",
        text: "Чем должны быть оборудованы места пересечения железнодорожных путей для подачи составов ковшей с жидким чугуном с путями для подачи шихтовых материалов?",
        answers: [
          "Должны быть оборудованы постами со стрелочниками",
          "Должны быть оборудованы светофорами",
          "Должны быть оборудованы автоматической сигнализацией",
        ],
        correctAnswers: [
          "Должны быть оборудованы автоматической сигнализацией",
        ],
      },
      {
        code: "20307021",
        text: "Какие требования установлены к месту расстановки ковшей, предназначенных для расплава металла?",
        answers: [
          "Должны расставляться на свободных площадках пролета цеха",
          "Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства",
          "Должны расставляться на горизонтальных площадках в любом месте пролета цеха",
        ],
        correctAnswers: [
          "Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства",
        ],
      },
      {
        code: "20307022",
        text: "Какие требования установлены к процессу сливания расплава металла из ковшей и миксеров? Выберите правильный вариант ответа.",
        answers: [
          "Сливание расплава металла из ковшей и миксеров должно быть механизировано",
          "Ось сливного отверстия ковша, установленного под слив, должна совпадать с осью заливочного ковша",
          "Пол рабочей площадки в местах установки ковшей должен быть сухим",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20307023",
        text: "После чего разрешается производить слив расплава в ковши, вышедшие из ремонта?",
        answers: [
          "Только после тщательного их просушивания и разогрева",
          "Только после выдержки в течение суток при температуре 40 °С",
          "Только после тщательного их просушивания",
        ],
        correctAnswers: [
          "Только после тщательного их просушивания и разогрева",
        ],
      },
      {
        code: "20307024",
        text: "В какой зоне не допускается нахождение людей?",
        answers: [
          "На расстоянии 20 метров от зоны работы грейферного крана в случае, если кран работает внутри производственного здания",
          "В зоне погрузки грейферными или магнитными кранами",
          "На расстоянии 15 метров от зоны работы магнитного крана в случае, если кран работает внутри производственного здания",
          "Во всех перечисленных зонах",
        ],
        correctAnswers: ["В зоне погрузки грейферными или магнитными кранами"],
      },
      {
        code: "20307025",
        text: "С какой периодичностью должны проверяться на работоспособность вентиляционные системы и состояние ванн травления?",
        answers: ["Ежеквартально", "Ежедневно", "Ежедекадно", "Ежемесячно"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "20307026",
        text: "Какие требования к производственным помещениям, оборудованию участков приготовления травильных растворов и травления металла указаны неверно? Выберите правильный вариант ответа.",
        answers: [
          "Периодичность проведения уборки помещений устанавливается в зависимости от условий их эксплуатации",
          "Вентиляционные системы, состояние ванн травления ежедневно должны проверяться на работоспособность",
          "После работы у ванн травления с химически опасными веществами, используемыми в виде добавок для технологических растворов, вещества должны быть удалены из рабочей зоны на склад",
          "Допускается уборка, вызывающая распыление вредных веществ",
        ],
        correctAnswers: [
          "Допускается уборка, вызывающая распыление вредных веществ",
        ],
      },
      {
        code: "20307027",
        text: "Какие требования установлены к системам освещения во взрывоопасных помещениях?",
        answers: [
          "Должны предусматриваться системы освещения во взрывобезопасном исполнении",
          "Должны предусматриваться системы освещения напряжением 36 В",
          "Должны предусматриваться системы освещения напряжением 24 В",
        ],
        correctAnswers: [
          "Должны предусматриваться системы освещения во взрывобезопасном исполнении",
        ],
      },
      {
        code: "20307028",
        text: "Что должна обеспечить система светозвуковой сигнализации?",
        answers: [
          "Оповещение эксплуатационного персонала о начале и окончании смены",
          "Оповещение эксплуатационного персонала о приближении технологического транспорта",
          "Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств",
        ],
        correctAnswers: [
          "Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств",
        ],
      },
      {
        code: "20307029",
        text: "Какие требования установлены к расположению КИПиА?",
        answers: [
          "Приборы должны устанавливаться в непосредственной близости к оборудованию",
          "Приборы должны устанавливаться на расстоянии не менее 5 м от оборудования",
          "Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования",
        ],
        correctAnswers: [
          "Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования",
        ],
      },
      {
        code: "20307030",
        text: "Какие требования установлены к проверке и испытанию приборов во взрывопожароопасных и пожароопасных помещениях?",
        answers: [
          "Должны производиться в условиях, исключающих искрообразование",
          "Проведение проверки допускается непосредственно во взрывопожароопасных и пожароопасных помещениях",
          "Проверка должна осуществляться только в специально выделенном для этого помещении",
        ],
        correctAnswers: [
          "Должны производиться в условиях, исключающих искрообразование",
        ],
      },
      {
        code: "20307031",
        text: "Какие требования предъявляются к устройству и эксплуатации печей для обжига шихтовых материалов и концентратов?",
        answers: [
          "Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 5 %",
          "Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 10 %",
          "Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции",
        ],
        correctAnswers: [
          "Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции",
        ],
      },
      {
        code: "20307032",
        text: "Какие требования установлены к хранению стержней, шаров, футеровки, запасных деталей и приспособлений?",
        answers: [
          "Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре",
          "Должно быть предусмотрено на свободных площадках, обеспечивающих беспрепятственный проход",
          "Должно быть предусмотрено в специальном помещении",
        ],
        correctAnswers: [
          "Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре",
        ],
      },
      {
        code: "20307033",
        text: "При наличии каких дефектов ковшей их эксплуатация не допускается?",
        answers: [
          "Только при наличии качки цапф в теле ковша",
          "Только при потере формы ковша вследствие деформации",
          "Только при повреждении футеровки ковша",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20307034",
        text: "Где не допускается размещение операторных помещений?",
        answers: [
          "Над помещениями с взрывоопасными зонами любого класса",
          "Под душевыми, санузлами, под производственными помещениями с мокрым технологическим процессом",
          "Под приточными вентиляционными камерами",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20307035",
        text: "Какое из перечисленных требований при эксплуатации взрывопожароопасных объектов указано неверно?",
        answers: [
          "На каждой двери помещения категории A должны быть указаны категория помещения и класс взрывоопасной зоны",
          "Хранение в помещении ГРП обтирочных и других горючих материалов допускается в количестве, не превышающем суточной потребности, в металлических, плотно закрывающихся шкафах или ящиках",
          "В помещениях категории A проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией должна производиться в сроки, предусмотренные заводской инструкцией, но не реже одного раза в месяц",
          "В помещениях категории A хранение обтирочных и смазочных материалов допускается в количестве, не превышающем суточной потребности, в металлических, плотно закрывающихся шкафах или ящиках",
        ],
        correctAnswers: [
          "Хранение в помещении ГРП обтирочных и других горючих материалов допускается в количестве, не превышающем суточной потребности, в металлических, плотно закрывающихся шкафах или ящиках",
        ],
      },
      {
        code: "20307036",
        text: "Какие работы производятся на ОПО горно-металлургических производств? Выберите правильный вариант ответа.",
        answers: [
          "Только постоянные работы, выполняемые в соответствии с документированной процедурой (инструкцией, картой, регламентом), принятой в организации",
          "Только работы повышенной опасности (сложные, разовые, уникальные)",
          "Только работы, связанные с аварийными ситуациями",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20307037",
        text: "Какие из перечисленных требований к акту-допуску подрядных организаций на опасный производственный объект горно-металлургической промышленности указаны неверно?",
        answers: [
          "Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)",
          "Акт-допуск должен определять допуск и условия производства работ подрядчиком на территории заказчика с учетом согласованных организационных и технических мероприятий, обеспечивающих безопасность работ",
          "На один объект, площадку, территорию следует оформлять один акт-допуск",
          "Не допускается оформлять акты-допуски, характеризующиеся одинаковыми координатами рабочих зон",
        ],
        correctAnswers: [
          "Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)",
        ],
      },
      {
        code: "20307038",
        text: "Какие действия предпринимаются при необходимости ведения работ после истечения срока действия акта-допуска подрядных организаций на опасный производственный объект горно-металлургической промышленности?",
        answers: [
          "Составляется новый акт-допуск на следующий срок",
          "Акт-допуск продлевается",
          "Работы продолжаются без оформления",
        ],
        correctAnswers: ["Составляется новый акт-допуск на следующий срок"],
      },
      {
        code: "20307039",
        text: "В каком количестве экземпляров составляется акт-допуск подрядных организаций на опасный производственный объект горно-металлургической промышленности?",
        answers: [
          "В двух экземплярах",
          "В одном экземпляре",
          "В трех экземплярах",
          "В четырех экземплярах",
        ],
        correctAnswers: ["В двух экземплярах"],
      },
      {
        code: "20307040",
        text: "С кем согласовывается перечень работ повышенной опасности на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля",
          "С техническим руководителем организации (главным инженером)",
          "С территориальным органом Ростехнадзора",
          "С профессиональной аварийно-спасательной службой",
        ],
        correctAnswers: [
          "Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля",
        ],
      },
      {
        code: "20307041",
        text: "Кем могут проводиться работы повышенной опасности на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "Как работниками эксплуатирующей организации, так и работниками подрядных организаций",
          "Только работниками эксплуатирующей организации",
          "Только работниками подрядных организаций",
        ],
        correctAnswers: [
          "Как работниками эксплуатирующей организации, так и работниками подрядных организаций",
        ],
      },
      {
        code: "20307042",
        text: "В течение какого времени хранится журнал регистрации нарядов-допусков со дня внесения последней записи?",
        answers: [
          "В течение 6 месяцев",
          "В течение 3 месяцев",
          "В течение месяца",
          "В течение 12 месяцев",
        ],
        correctAnswers: ["В течение 6 месяцев"],
      },
      {
        code: "20307043",
        text: "На какой максимальный срок выдается наряд-допуск на работы повышенной опасности?",
        answers: [
          "На 30 календарных дней",
          "На 10 календарных дней",
          "На 15 календарных дней",
          "На 45 календарных дней",
        ],
        correctAnswers: ["На 30 календарных дней"],
      },
      {
        code: "20307044",
        text: "Кто определяет (назначает) работников, выполняющих мероприятия по безопасности труда, указанных в наряде-допуске (ответственных исполнителей)?",
        answers: [
          "Выдающий наряд-допуск",
          "Допускающий к работе",
          "Производитель (руководитель) работ",
          "Технический руководитель организации",
        ],
        correctAnswers: ["Выдающий наряд-допуск"],
      },
      {
        code: "20307045",
        text: "Какие из перечисленных требований к наряду-допуску на работы повышенной опасности указаны неверно? Выберите два правильных ответа.",
        answers: [
          "При допуске бригады к работе допускающий к работе вручает первый экземпляр наряда-допуска производителю работ, а второй экземпляр наряда-допуска хранится вместе с нарядами, действующими на этот момент времени",
          "При перерыве в работе в течение одной смены (обеденный перерыв, перерыв по условиям производства работ) наряд-допуск остается у производителя (руководителя) работ",
          "Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ",
          "После окончания работы наряд-допуск остается у производителя (руководителя) работ",
        ],
        correctAnswers: [
          "Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ",
          "После окончания работы наряд-допуск остается у производителя (руководителя) работ",
        ],
      },
      {
        code: "20307046",
        text: "Какое количество производителей (руководителей) работ назначается на все время действия наряда-допуска на работы повышенной опасности при производстве работ в несколько смен?",
        answers: [
          "Несколько производителей (руководителей) работ в соответствии с количеством смен",
          "Один производитель (руководитель) работ",
          "Не регламентируется",
          "В зависимости от вида работ",
        ],
        correctAnswers: [
          "Несколько производителей (руководителей) работ в соответствии с количеством смен",
        ],
      },
      {
        code: "20307047",
        text: "В течение какого времени хранятся закрытые (подписанные) наряды-допуски на работы повышенной опасности?",
        answers: [
          "В течение одного месяца",
          "В течение трех месяцев",
          "В течение шести месяцев",
          "В течение одного года",
        ],
        correctAnswers: ["В течение одного месяца"],
      },
      {
        code: "20307048",
        text: "Какие действия должны быть выполнены при утрате наряда-допуска на работы повышенной опасности?",
        answers: [
          "Работы могут быть продолжены с разрешения производителя (руководителя) работ",
          "Работы могут быть продолжены с разрешения выдающего наряд-допуск",
          "Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново",
          "Работы должны быть прекращены. На продолжение работ должен быть оформлен дубликат наряда-допуска",
        ],
        correctAnswers: [
          "Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново",
        ],
      },
      {
        code: "20307049",
        text: "В каких из перечисленных случаев допускается продолжать работы, проводимые по наряду-допуску, на опасных производственных объектах?",
        answers: [
          "Если изменился состав бригады",
          "Если обнаружено несоответствие фактического состояния производства работ требованиям безопасности и охраны труда",
          "Если характер и объемы работ изменены в такой степени, что требуется изменение схемы отключения технического устройства и порядка выполнения работ",
          "Если выявлено нарушение условий отключения технических устройств",
        ],
        correctAnswers: ["Если изменился состав бригады"],
      },
      {
        code: "20307050",
        text: "На какие группы подразделяются газоопасные места на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "На I и II группы газоопасности",
          "На I, II и III группы газоопасности",
          "На I, II, III и IV группы газоопасности",
        ],
        correctAnswers: ["На I и II группы газоопасности"],
      },
      {
        code: "20307051",
        text: "С кем согласовывается инструкция, определяющая порядок подготовки и безопасного проведения газоопасных работ на опасных производственных объектах горно-металлургической промышленности? Выберите два правильных ответа.",
        answers: [
          "С Ростехнадзором",
          "С профессиональной аварийно-спасательной службой",
          "С ГСС",
          "Со службой производственного контроля",
        ],
        correctAnswers: ["С ГСС", "Со службой производственного контроля"],
      },
      {
        code: "20307052",
        text: "Каким составом должны выполняться газоопасные работы I и II группы газоопасности с образованием взрывопожароопасных смесей?",
        answers: [
          "Не менее двух человек под наблюдением газоспасателя или члена ДГСД",
          "Не более двух человек",
          "Одним человеком под наблюдением газоспасателя",
          "Не менее трех человек",
        ],
        correctAnswers: [
          "Не менее двух человек под наблюдением газоспасателя или члена ДГСД",
        ],
      },
      {
        code: "20307053",
        text: "С кем согласовывается план организации и проведения газоопасной работы?",
        answers: [
          "Только со службой производственного контроля",
          "Только с начальником газоспасательной службы",
          "Только с ответственным представителем подразделения или организации (главным энергетиком)",
          "Со всеми перечисленными",
        ],
        correctAnswers: ["Со всеми перечисленными"],
      },
      {
        code: "20307054",
        text: "Какой стаж работы в газовом хозяйстве должны иметь лица, назначаемые ответственными руководителями и ответственными исполнителями газоопасных работ?",
        answers: [
          "Не менее одного года",
          "Не менее полугода",
          "Не менее двух лет",
          "Не менее трех лет",
        ],
        correctAnswers: ["Не менее одного года"],
      },
      {
        code: "20307055",
        text: "Какое из перечисленных требований к применению жетон-бирочной системы указано неверно?",
        answers: [
          "Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями",
          "Жетон-бирочная система применяется для предотвращения травм и аварий в результате ошибочного или несанкционированного включения технического устройства (ТУ), а также в целях согласования действий технологического и ремонтного персонала",
          "Жетон-бирочная система применяется при выводе ТУ на продолжительную остановку",
          "Жетон-бирки после разборки электрической схемы ТУ передаются от технологического персонала ремонтному персоналу с отметкой всех этапов в журнале выдачи и приема жетон-бирок",
        ],
        correctAnswers: [
          "Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями",
        ],
      },
      {
        code: "20307056",
        text: "Какой должен быть нижний предел давления для I разряда газов-заменителей ацетилена перед газопламенной аппаратурой?",
        answers: [
          "Не ниже указанного в характеристике горелки или резака, но не ниже 3,0 кПа",
          "Не ниже указанного в характеристике горелки или резака, но не ниже 2,0 кПа",
          "Не ниже указанного в характеристике горелки или резака, но не ниже 1,5 кПа",
          "Не ниже указанного в характеристике горелки или резака, но не ниже 2,5 кПа",
        ],
        correctAnswers: [
          "Не ниже указанного в характеристике горелки или резака, но не ниже 3,0 кПа",
        ],
      },
      {
        code: "20307057",
        text: "В каком случае допускается течь масла из гидравлического привода механизма перемещения электродов?",
        answers: [
          "В случае осуществления постоянного контроля",
          "В случае если это допускается проектной документацией",
          "В случае если это допускается производственной инструкцией",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20307058",
        text: "В соответствии с требованиями какой документации должны проводиться работы по перепуску и наращиванию самообжигающихся электродов рудовосстановительных ферросплавных печей, приварке тормозной ленты и загрузке электродной массы?",
        answers: [
          "Технологической инструкции",
          "Правил безопасности процессов получения или применения металлов",
          "Технологического регламента",
          "Всех перечисленных",
        ],
        correctAnswers: ["Технологической инструкции"],
      },
      {
        code: "20307059",
        text: "При каком содержании кислорода в колошниковом газе закрытых рудовосстановительных печей печь немедленно отключается?",
        answers: [
          "При увеличении до 1 %",
          "При увеличении до 2 %",
          "При увеличении до 1,5 %",
        ],
        correctAnswers: ["При увеличении до 2 %"],
      },
      {
        code: "20307060",
        text: "Каким гидравлическим давлением должны быть испытаны на прочность и плотность трубки индуктора?",
        answers: [
          "Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 1,5 раза",
          "Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 0,5 раза",
          "Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 1,25 раза",
          "Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 0,75 раза",
        ],
        correctAnswers: [
          "Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 1,5 раза",
        ],
      },
      {
        code: "20307061",
        text: "Какие из перечисленных смесей не допускается применять при производстве ферросплавов? Выберите правильный вариант ответа.",
        answers: [
          "Только смеси, процесс горения которых переходит во взрыв",
          "Только смеси, способные к самостоятельному горению и имеющие удельную теплоту процесса горения более 50 кДж/моль",
          "Только смеси, чувствительность которых к механическому воздействию (удару) составляет 19,6 Дж и менее, а активной составляющей - 9,8 Дж и менее",
          "Все перечисленные смеси",
        ],
        correctAnswers: ["Все перечисленные смеси"],
      },
      {
        code: "20307062",
        text: "Какие действия необходимо предпринять в случае вспенивания расплава?",
        answers: [
          "Уменьшить скорость заливки без остановки процесса заливки восстановителя и загрузки твердой шихты",
          "Заливка восстановителя и загрузка твердой шихты в расплав должны быть приостановлены с последующим уменьшением скорости заливки",
          "Снять пену специальным приспособлением, последующую заливку производить с уменьшенной скоростью",
        ],
        correctAnswers: [
          "Заливка восстановителя и загрузка твердой шихты в расплав должны быть приостановлены с последующим уменьшением скорости заливки",
        ],
      },
      {
        code: "20307063",
        text: "Какая должна быть температура в помещениях в холодное время года там, где осуществляются мокрые производственные процессы?",
        answers: ["Не ниже 16 °С", "Не ниже 18 °С", "Не ниже 20 °С"],
        correctAnswers: ["Не ниже 16 °С"],
      },
      {
        code: "20307064",
        text: "Каков порядок приготовления растворов серной кислоты?",
        answers: [
          "При приготовлении смеси кислот серную кислоту и воду следует заливать одновременно",
          "При приготовлении растворов серной кислоты сначала необходимо заливать кислоту, а затем воду",
          "При приготовлении растворов серной кислоты сначала необходимо заливать воду, а затем кислоту",
        ],
        correctAnswers: [
          "При приготовлении растворов серной кислоты сначала необходимо заливать воду, а затем кислоту",
        ],
      },
      {
        code: "20307065",
        text: "В каком случае допускается охлаждать слитки, ковши с остатками застывшего металла и изложницы в грануляционных баках при производстве ферросплавов?",
        answers: [
          "В баках для замачивания слитков, предусмотренных проектом",
          "В грануляционных баках",
          "Ни в каком случае",
          "В любом случае",
        ],
        correctAnswers: [
          "В баках для замачивания слитков, предусмотренных проектом",
        ],
      },
      {
        code: "20307066",
        text: "По чьей команде должны производиться установка и снятие с ковша кантователя?",
        answers: [
          "Ответственного за производственный контроль",
          "Машиниста разливочной машины",
          "Технического руководителя организации (главного инженера)",
          "Руководителя подразделения",
        ],
        correctAnswers: ["Машиниста разливочной машины"],
      },
      {
        code: "20307067",
        text: "Кем определяется номенклатура ферросплавов, для которых допустима грануляция?",
        answers: [
          "Заводом-производителем",
          "Эксплуатирующей организацией",
          "Ростехнадзором",
          "Проектной организацией",
        ],
        correctAnswers: ["Заводом-производителем"],
      },
      {
        code: "20307068",
        text: "Какие требования из приведенных должны выполняться при грануляции сплавов?",
        answers: [
          "Грануляция передельного силикомарганца и углеродистого феррохрома, ферросилиция 45 % разрешается при помощи электромостового крана только через носок ковша в приемник",
          "Грануляция передельного силикомарганца и углеродистого феррохрома, ферросилиция 45 % разрешается при помощи электромостового крана через приемник",
          "Грануляция передельного силикомарганца и углеродистого феррохрома, ферросилиция 45 % разрешается при помощи электромостового крана через стопор",
        ],
        correctAnswers: [
          "Грануляция передельного силикомарганца и углеродистого феррохрома, ферросилиция 45 % разрешается при помощи электромостового крана через приемник",
        ],
      },
      {
        code: "20307069",
        text: "На каком расстоянии не допускается нахождение персонала в процессе грануляции металла?",
        answers: ["В радиусе 10 м", "В радиусе 8 м", "В радиусе 12 м"],
        correctAnswers: ["В радиусе 8 м"],
      },
      {
        code: "20307070",
        text: "Какое требование предъявляется к установкам бутобоев во вновь строящихся и реконструируемых цехах? Выберите правильный вариант ответа.",
        answers: [
          "Установки бутобоев должны быть оборудованы защитными ограждениями, предотвращающими разлетание кусков металла",
          "Установки бутобоев должны быть заключены в звукоизолирующие камеры",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20307071",
        text: "Какое из перечисленных требований не распространяется на аспирационные установки дробильных агрегатов для силикокальция и модификаторов ферросилициймагния?",
        answers: [
          "Должны быть оснащены датчиками контроля содержания кислорода",
          "Должны быть выполнены во взрывозащищенном исполнении",
          "Должны быть оснащены предохранительными взрывными клапанами и свечами для сброса водорода",
          "Должны быть оснащены датчиками контроля содержания водорода",
        ],
        correctAnswers: [
          "Должны быть оснащены датчиками контроля содержания кислорода",
        ],
      },
      {
        code: "20307072",
        text: "Какой должен использоваться инструмент при загрузке карбида кальция в металлические барабаны?",
        answers: ["Медный, латунный", "Стальной", "Чугунный"],
        correctAnswers: ["Медный, латунный"],
      },
      {
        code: "20307073",
        text: "Какими средствами пожаротушения должны быть оборудованы помещения, где хранится карбид кальция?",
        answers: [
          "Порошковые огнетушители, сухой песок",
          "Углекислотные огнетушители",
          "Пенные огнетушители, кошма",
        ],
        correctAnswers: ["Порошковые огнетушители, сухой песок"],
      },
      {
        code: "20307074",
        text: "Светильники каким напряжением разрешается применять для освещения внутри вагона при складировании, шлакопереработке, транспортировании ферросплавов?",
        answers: ["До 220 В", "До 127 В", "До 50 В", "До 380 В"],
        correctAnswers: ["До 50 В"],
      },
      {
        code: "20307075",
        text: "Каким документом должны быть предусмотрены время вывалки шлака после выпуска или разливки его, а также порядок производства работ по очистке бункеров от слежавшегося шлака?",
        answers: [
          "Технологической инструкцией",
          "Правилами безопасности процессов получения или применения металлов",
          "Технологическим регламентом",
          "Не регламентируется",
        ],
        correctAnswers: ["Технологической инструкцией"],
      },
      {
        code: "20307076",
        text: "Как часто должна производиться уборка пыли с полов и оборудования в помещении бункеров, конвейерных галерей и отделений упаковки в производстве алюминиевых порошков?",
        answers: ["Один раз в смену", "Один раз в декаду", "Один раз в неделю"],
        correctAnswers: ["Один раз в смену"],
      },
      {
        code: "20307077",
        text: "Какие требования установлены к ведению взрывных работ, хранению, транспортированию взрывчатых материалов и эксплуатации броневых ям?",
        answers: [
          "Работы допускается производить взрывникам в процессе эксплуатации оборудования цеха",
          "Работы должны производиться специально обученным цеховым обслуживающим персоналом",
          "Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах",
        ],
        correctAnswers: [
          "Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах",
        ],
      },
      {
        code: "20307078",
        text: "На каком расстоянии от путей и натяжного троса допускается нахождение людей при транспортировании тележек с изложницами или ковшами, наполненными металлом или шлаком?",
        answers: ["Не ближе 5 м", "Не ближе 3 м", "Не ближе 2 м"],
        correctAnswers: ["Не ближе 5 м"],
      },
      {
        code: "20307079",
        text: "Чем должны быть оборудованы ванны с кислотой для травления оборудования?",
        answers: [
          "Защитным экраном",
          "Местными отсосами",
          "Укрытиями",
          "Всем перечисленным",
        ],
        correctAnswers: ["Местными отсосами"],
      },
      {
        code: "20307080",
        text: "Какие требования установлены к состоянию вакуум-ковшей, тиглей, коробов, кристаллизаторов, изложниц и других емкостей для расплава перед заливкой в них расплава в электролитическом производстве магния?",
        answers: [
          "Должны быть прогреты",
          "Должны быть очищены от мусора",
          "Должны быть просушены",
        ],
        correctAnswers: ["Должны быть очищены от мусора"],
      },
      {
        code: "20307081",
        text: "Какое допускается максимальное содержание водорода в ферросплавном газе в зависимости от условий производства?",
        answers: ["12%", "17%", "13%", "15%"],
        correctAnswers: ["12%"],
      },
      {
        code: "20307082",
        text: "Что из перечисленного допускается продувать сжатым воздухом при работе на доменном, ферросплавном и конвертерном газах и их смесях? Выберите правильный вариант ответа.",
        answers: [
          "Только газодувки",
          "Только нагнетатели и компрессоры",
          "Только газовые утилизационные безкомпрессорные турбины",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20307083",
        text: "Какие из перечисленных требований безопасности в ферросплавном производстве указаны неверно?",
        answers: [
          "Нарушения кладки обжиговых печей, сопровождающиеся выделением газов в помещение допускается не устранять, при условии что помещение оборудовано принудительной вытяжной вентиляцией",
          "Не допускается подавать на склад шихтовых материалов горячие прошлакованные оборотные отходы",
          "На электропечах должен осуществляться постоянный контроль за целостностью кожухов (отсутствие трещин, прогаров)",
          "Удаление обломков электродов должно производиться на отключенной печи",
        ],
        correctAnswers: [
          "Нарушения кладки обжиговых печей, сопровождающиеся выделением газов в помещение допускается не устранять, при условии что помещение оборудовано принудительной вытяжной вентиляцией",
        ],
      },
      {
        code: "20307084",
        text: "В каком месте разрешено прожигать и расшуровывать летку?",
        answers: [
          "На сухой площадке (подставке), выполненной из диэлектрического материала",
          "На открытой площадке с применением подставки из стальной сетки",
          "На закрытой площадке, покрытой металлическими листами",
          "На закрытой площадке, отсыпанной песком",
        ],
        correctAnswers: [
          "На сухой площадке (подставке), выполненной из диэлектрического материала",
        ],
      },
      {
        code: "20307085",
        text: "Какими документами должны устанавливаться и регламентироваться давление и температура колошникового газа в подсводовом пространстве закрытой рудовосстановительной печи? Выберите 2 варианта ответа.",
        answers: [
          "Давление и температура должны устанавливаться проектом в зависимости от конструкции электропечи, выплавляемого сплава",
          "Давление и температура должны регламентироваться технологической инструкцией",
          "Давление и температура должны устанавливаться инструкцией по эксплуатации электропечи",
          "Давление и температура должны регламентироваться правилами эксплуатации",
        ],
        correctAnswers: [
          "Давление и температура должны устанавливаться проектом в зависимости от конструкции электропечи, выплавляемого сплава",
          "Давление и температура должны регламентироваться технологической инструкцией",
        ],
      },
      {
        code: "20307086",
        text: "Из каких газов состоят промышленные газы объектов металлургии, возникающие (используемые) в процессе металлургического производства?",
        answers: [
          "Из природного и доменного",
          "Из коксового и конверторного",
          "Из ферросплавного и реформенного",
          "Из всех перечисленных и иных газов, а также их смесей",
        ],
        correctAnswers: [
          "Из всех перечисленных и иных газов, а также их смесей",
        ],
      },
      {
        code: "20307087",
        text: "Какие из перечисленных требований указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Течь масла из гидравлического привода механизма перемещения электродов не допускается",
          "Прожигать и расшуровывать летку разрешается только на сухой площадке (подставке), выполненной из стали",
          "Металлические инструменты при использовании их для работы в электропечи допускается не заземлять при условии выполнения работы в диэлектрических перчатках",
          "Кожух электропечи допускается не заземлять при условии что печь заземлена",
        ],
        correctAnswers: [
          "Течь масла из гидравлического привода механизма перемещения электродов не допускается",
        ],
      },
      {
        code: "20307088",
        text: "Какие из перечисленных требований указаны неверно? Выберите правильный вариант ответа.",
        answers: [
          "Металлические инструменты, применяемые в корпусах электролиза, должны быть изготовлены из ферромагнитных сплавов",
          "Не допускается устройство внутренних водостоков в зданиях преобразовательных подстанций, электролизных и литейных корпусах",
          "Обязательной проверке должно подвергаться электрическое сопротивление изоляции узлов и деталей, указанных в документации изготовителя",
          "Составные части производственного оборудования, в том числе трубопроводы, рукава и электрокабели, должны быть защищены от возможного попадания на них расплава",
        ],
        correctAnswers: [
          "Металлические инструменты, применяемые в корпусах электролиза, должны быть изготовлены из ферромагнитных сплавов",
        ],
      },
      {
        code: "20307089",
        text: "В каких случаях не допускается дробление карбида кальция?",
        answers: [
          "При наличии влаги на загрузочной площадке дробилки, в ее лотках и приемном конусе",
          "При одновременной работе дробилок и аспирационных установок",
          "При закрытых дверях в размольных помещениях",
        ],
        correctAnswers: [
          "При наличии влаги на загрузочной площадке дробилки, в ее лотках и приемном конусе",
        ],
      },
      {
        code: "20307090",
        text: "Какие требования на производстве ферросплавов указаны неверно?",
        answers: [
          "Металлические прутья, используемые для шуровки и разделки летки, должны быть охлажденными до 20 °C",
          "Кожух электропечи должен быть заземлен",
          "Нарушения кладки обжиговых печей, сопровождающиеся выделением газов в помещение, должны устраняться",
          "Полы на рабочих площадках ферросплавных печей у горна и на электродных площадках должны быть неэлектропроводными и сухими",
        ],
        correctAnswers: [
          "Металлические прутья, используемые для шуровки и разделки летки, должны быть охлажденными до 20 °C",
        ],
      },
      {
        code: "20307091",
        text: "Согласно требованиям какого документа, должна проводиться чистка горячих продуктов ферросплавного производства?",
        answers: [
          "Согласно требованиям технологической инструкции",
          "Согласно требованиям инструкции по эксплуатации",
          "Согласно требованиям технологической карты",
          "Согласно требованиям регламента по обслуживанию",
        ],
        correctAnswers: ["Согласно требованиям технологической инструкции"],
      },
      {
        code: "20307092",
        text: "Согласно какому документу должны осуществляться работы по очистке внутренних полостей воздушного сепаратора от зацементировавшегося шлака?",
        answers: [
          "Согласно наряду-допуску",
          "Согласно плану-графику",
          "Согласно карте технологического обслуживания",
          "Согласно инструкции по очистке внутренних полостей воздушного сепаратора от зацементировавшегося шлака",
        ],
        correctAnswers: ["Согласно наряду-допуску"],
      },
      {
        code: "20307093",
        text: "Освещением с каким напряжением должны пользоваться лица, выполняющие работу в воздушном сепараторе, при выполнении работ по очистке его внутренних полостей?",
        answers: [
          "Не выше 12 В",
          "Не выше 36 В",
          "Не выше 42 В",
          "Не выше 24 В",
        ],
        correctAnswers: ["Не выше 12 В"],
      },
      {
        code: "20307094",
        text: "Каким минимальным количеством лиц должно быть обеспечено постоянное наблюдение за работниками в воздушном сепараторе при выполнении работ по очистке его внутренних полостей?",
        answers: ["1", "3", "4", "2"],
        correctAnswers: ["2"],
      },
      {
        code: "20307095",
        text: "Какое допускается максимальное содержание водорода в ферросплавном газе в зависимости от условий производства ферросплавов?",
        answers: ["12%", "13%", "14%", "15%"],
        correctAnswers: ["12%"],
      },
      {
        code: "20307096",
        text: "Какое допускается максимальное содержание пыли в ферросплавном газе, поступающем в заводские сети?",
        answers: ["20 мг/нм³", "25 мг/нм³", "30 мг/нм³", "40 мг/нм³"],
        correctAnswers: ["20 мг/нм³"],
      },
      {
        code: "20307097",
        text: "Как часто должно проверяться исправное действие автоблокировки и сигнализации?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в квартал",
          "Не реже одного раза в полгода",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20307098",
        text: "Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в неделю",
          "Не реже одного раза в квартал",
        ],
        correctAnswers: ["Не реже одного раза в неделю"],
      },
      {
        code: "20307099",
        text: "Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных вне помещений?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в квартал",
          "Не реже одного раза в полгода",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20307100",
        text: "В каких случаях запрещается газовая резка и сварка на действующих газопроводах?",
        answers: [
          "На газопроводах, находящихся под разрежением",
          "После продувки газопроводов сжатым воздухом",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["На газопроводах, находящихся под разрежением"],
      },
      {
        code: "20307101",
        text: "На что должен проверяться выполненный сварной шов после врезки в действующий газопровод?",
        answers: ["На герметичность", "На плотность", "На прочность"],
        correctAnswers: ["На плотность"],
      },
      {
        code: "20307102",
        text: "Каково минимально допустимое давление газа непосредственно перед потребителем (после регулирующих органов)?",
        answers: ["0,1 кПа", "0,3 кПа", "0,5 кПа"],
        correctAnswers: ["0,5 кПа"],
      },
      {
        code: "20307103",
        text: "Разрешено ли использовать продувочные свечи для выпуска в атмосферу избыточного газа?",
        answers: [
          "Только в аварийных случаях",
          "Ни в каком случае",
          "Только при увеличении давления газа до 10 %",
        ],
        correctAnswers: ["Только в аварийных случаях"],
      },
      {
        code: "20307104",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии более 0,5 мм/г?",
        answers: ["Один раз в 3 года", "Один раз в год", "Один раз в 2 года"],
        correctAnswers: ["Один раз в год"],
      },
      {
        code: "20307105",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии 0,1 - 0,5 мм/г?",
        answers: ["Один раз в 3 года", "Один раз в 5 лет", "Один раз в 2 года"],
        correctAnswers: ["Один раз в 2 года"],
      },
      {
        code: "20307106",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии до 0,1 мм/г?",
        answers: ["Один раз в 7 лет", "Один раз в 4 года", "Один раз в 5 лет"],
        correctAnswers: ["Один раз в 4 года"],
      },
      {
        code: "20307107",
        text: "Какая допускается максимальная температура газа в газопроводах в случае подачи в них пара для пропарки, продувки и отогрева?",
        answers: ["95 °C", "90 °C", "85 °C"],
        correctAnswers: ["85 °C"],
      },
      {
        code: "20307108",
        text: "Как часто должна производиться нивелировка действующих газопроводов?",
        answers: ["Один раз в 5 лет", "Один раз в 3 года", "Один раз в 2 года"],
        correctAnswers: ["Один раз в 5 лет"],
      },
      {
        code: "20307109",
        text: "Куда должны заноситься записи о проведении технического обслуживания, выявленных дефектах и нарушениях газопроводов?",
        answers: [
          "В эксплуатационный журнал",
          "В паспорт оборудования",
          "В специальный журнал по устранению дефектов",
        ],
        correctAnswers: ["В эксплуатационный журнал"],
      },
      {
        code: "20307110",
        text: "До розжига газопотребляющего агрегата чем должен быть продут газопровод перед коллектором?",
        answers: ["Газом", "Сжатым воздухом", "Техническим воздухом"],
        correctAnswers: ["Газом"],
      },
      {
        code: "20307111",
        text: "В какие сроки в помещениях категории А должна осуществляться проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в 3 месяца",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20307112",
        text: "Как часто должны проходить поверку контрольно-измерительные приборы в процессе эксплуатации?",
        answers: [
          "Не реже одного раза в год",
          "Не реже одного раза в 5 лет",
          "Не реже одного раза в 2 года",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20307113",
        text: "При каких показателях газа разрешено применять жидкостные манометры?",
        answers: [
          "При давлении до 0,04 МПа",
          "При давлении до 0,03 МПа",
          "При давлении до 0,05 МПа",
        ],
        correctAnswers: ["При давлении до 0,03 МПа"],
      },
      {
        code: "20307114",
        text: "Чем производится отогрев смерзшейся изоляции для обеспечения доступа к адсорберам?",
        answers: [
          "Сухим подогретым воздухом",
          "Горячим паром",
          "Открытым пламенем",
        ],
        correctAnswers: ["Сухим подогретым воздухом"],
      },
      {
        code: "20307115",
        text: "Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей?",
        answers: [
          "Системами регулирования заданного соотношения топлива, воздуха и водяного пара",
          "Средствами сигнализации о прекращении поступления топлива, а также воздуха при его принудительной подаче в топочное пространство",
          "Средствами автоматической подачи водяного пара или инертного газа в топочное пространство и в змеевики при прогаре труб",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20307116",
        text: "Какое расчетное давление газа соответствует газопроводам среднего давления?",
        answers: [
          "Свыше 0,1 до 0,3 МПа включительно",
          "Свыше 0,3 до 0,6 МПа включительно",
          "Свыше 0,3 до 1,2 МПа включительно",
          "Свыше 0,005 до 0,3 МПа включительно",
        ],
        correctAnswers: ["Свыше 0,1 до 0,3 МПа включительно"],
      },
      {
        code: "20307117",
        text: "Какие из перечисленных ограждающих конструкций не относятся к легкосбрасываемым?",
        answers: [
          "Окна с оконными переплетами, заполненными обычным оконным стеклом толщиной 3, 4 и 5 мм, площадью не менее соответственно 0,8, 1 и 1,5 м²",
          "Конструкции из асбоцементных, алюминиевых и стальных листов с легким утеплением",
          "Фонарные переплеты",
          "Противопожарные двери",
        ],
        correctAnswers: ["Противопожарные двери"],
      },
      {
        code: "20307118",
        text: "Как должны сооружаться газопроводы на территории предприятия?",
        answers: [
          "Только надземным способом",
          "Только подземным способом",
          "Только в каналах",
          "Всеми перечисленными способами",
        ],
        correctAnswers: ["Только надземным способом"],
      },
      {
        code: "20307119",
        text: "Через какое расстояние должны заземляться наружные газопроводы?",
        answers: [
          "Через каждые 250 м",
          "Через каждые 350 м",
          "Через каждые 300 м",
          "Через каждые 400 м",
        ],
        correctAnswers: ["Через каждые 250 м"],
      },
      {
        code: "20307120",
        text: "При каком максимальном содержании сероводорода в газе допускается установка бронзовых кранов или задвижек с бронзовыми кольцами на газопроводах?",
        answers: ["20 мг/м³", "15 мг/м³", "18 мг/м³", "12 мг/м³"],
        correctAnswers: ["20 мг/м³"],
      },
      {
        code: "20307121",
        text: "Какое из перечисленных требований к задвижкам и заглушкам, устанавливаемым на газопроводах, указано неверно?",
        answers: [
          "Перекрывающие листы листовых задвижек и заглушек диаметром до 2 м должны изготовляться из целого листа",
          "Задвижки и краны, устанавливаемые на газопроводах и устройствах, должны иметь указатель открытого и закрытого положения",
          "Установка внутри зданий и цехов на газопроводах листовых задвижек любого типа без дисковых задвижек перед ними не допускается",
          "Листовые задвижки на газопроводах диаметром до 300 мм должны быть оснащены механизированным приводом",
        ],
        correctAnswers: [
          "Листовые задвижки на газопроводах диаметром до 300 мм должны быть оснащены механизированным приводом",
        ],
      },
      {
        code: "20307122",
        text: "Какая информация должна быть выбита на хвостовике каждой съемной заглушки газопровода?",
        answers: [
          "Только номер сертификата на заглушку",
          "Только номер заглушки и марка материала",
          "Только условное давление  Pу и условный диаметр Ду",
          "Вся перечисленная информация",
        ],
        correctAnswers: ["Вся перечисленная информация"],
      },
      {
        code: "20307123",
        text: "Какая должна быть минимальная высота водяного затвора конденсатоотводчиков межцеховых газопроводов, работающих под избыточным давлением?",
        answers: [
          "На 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст",
          "На 300 мм вод. ст. больше расчетного давления газа, но не менее 1800 мм вод. ст",
          "На 200 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст",
          "На 500 мм вод. ст. больше расчетного давления газа, но не менее 1500 мм вод. ст",
        ],
        correctAnswers: [
          "На 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст",
        ],
      },
      {
        code: "20307124",
        text: "Какое из перечисленных требований к отводу конденсата из цеховых газопроводов указано неверно?",
        answers: [
          "Сброс конденсата из цеховых газопроводов в случае, если цех не отапливается, допускается производить через горелки печей и другого оборудования потребителя газа",
          "Сброс конденсата из цеховых газопроводов следует производить через конденсатоотводчики",
          "Конструкция конденсатоотводчиков, установленных в зданиях цехов, должна исключать возможность попадания газов в помещения",
          "Участки труб, отводящих конденсат, а также сами конденсатоотводчики, если цех не отапливается, должны быть утеплены",
        ],
        correctAnswers: [
          "Сброс конденсата из цеховых газопроводов в случае, если цех не отапливается, допускается производить через горелки печей и другого оборудования потребителя газа",
        ],
      },
      {
        code: "20307125",
        text: "Какое из перечисленных требований к стационарным газоразборным постам указано неверно?",
        answers: [
          "Газоразборные посты, шкафы должны иметь опознавательную окраску",
          "Расстояние между шкафами газоразборных постов для кислорода и горючего газа должно быть не менее 100 мм",
          "При снабжении газоразборного поста газом от баллона на последнем должен быть установлен редуктор для снижения давления газа",
          "При отсутствии рабочего, пользующегося газоразборным постом, шкаф должен быть закрыт на замок",
        ],
        correctAnswers: [
          "Расстояние между шкафами газоразборных постов для кислорода и горючего газа должно быть не менее 100 мм",
        ],
      },
      {
        code: "20307126",
        text: "При каком увеличении содержания кислорода в газе в напорном коллекторе газоотсасывающей станции отвод газа от электропечи должен быть немедленно прекращен?",
        answers: [
          "При достижении содержания кислорода 2% по объему",
          "При достижении содержания кислорода 1,8% по объему",
          "При достижении содержания кислорода 1,7% по объему",
          "При достижении содержания кислорода 1,5% по объему",
        ],
        correctAnswers: ["При достижении содержания кислорода 2% по объему"],
      },
      {
        code: "20307127",
        text: "Каким уплотнительным устройством, препятствующим выбиванию газов в цех, должно оборудоваться отверстие в охладителе конвертерных газов?",
        answers: [
          "Газоуплотнительными клапанами",
          "Динамическим уплотнителем",
          "Оборотными ретортами",
          "Воздушной или азотной аэродинамической завесой",
        ],
        correctAnswers: ["Воздушной или азотной аэродинамической завесой"],
      },
      {
        code: "20307128",
        text: "Какое допускается максимальное содержание оксида углерода в газах, поступающих в электрофильтр, при отводе газов с полным дожиганием?",
        answers: ["1%", "2%", "1,5%", "3%"],
        correctAnswers: ["1%"],
      },
      {
        code: "20307129",
        text: "Содержание каких из перечисленных газов измеряется в продуктах неполного сгорания за дымососом при отводе газов без дожигания? Выберите 2 варианта ответа.",
        answers: ["CO", "O₂", "CO₂", "H₂"],
        correctAnswers: ["CO", "O₂"],
      },
      {
        code: "20307130",
        text: "С какой периодичностью газопроводы сероочистки природного газа подвергаются пневматическому испытанию?",
        answers: [
          "1 раз в 4 года",
          "1 раз в 5 лет",
          "1 раз в 3 года",
          "1 раз в 10 лет",
        ],
        correctAnswers: ["1 раз в 4 года"],
      },
      {
        code: "20307131",
        text: "Какое из перечисленных требований к установке сероочистки природного газа указано верно?",
        answers: [
          "Установка сероочистки природного газа для получения реформерного газа должна быть снабжена быстродействующими отсекающими клапанами на входе газа в установку, срабатывающими при снижении или повышении давления газа от установленных проектом значений",
          "Установка сероочистки должна иметь подвод азота и кислорода",
          "1 раз в 5 лет газопроводы сероочистки подвергаются пневматическому испытанию",
          "Перед ремонтом аппаратов и газопроводов сероочистки они должны быть отглушены и провентилированы. Содержание CH₄ в двух последовательно отобранных пробах должно быть не более 3 %.",
        ],
        correctAnswers: [
          "Установка сероочистки природного газа для получения реформерного газа должна быть снабжена быстродействующими отсекающими клапанами на входе газа в установку, срабатывающими при снижении или повышении давления газа от установленных проектом значений",
        ],
      },
      {
        code: "20307132",
        text: "На какой высоте от пола должны быть расположены окна в помещениях отделений наполненных баллонов, наполнительных и разрядных рамп?",
        answers: [
          "Не менее 1,5 м",
          "Не менее 1,3 м",
          "Не менее 1,2 м",
          "Не менее 1,1 м",
        ],
        correctAnswers: ["Не менее 1,5 м"],
      },
      {
        code: "20307133",
        text: "Какой должна быть ширина свободного прохода между щитом управления вентилями и ближайшими стенами наполнительной?",
        answers: [
          "Не менее 0,8 м",
          "Не менее 0,7 м",
          "Не менее 0,6 м",
          "Не менее 0,75 м",
        ],
        correctAnswers: ["Не менее 0,8 м"],
      },
      {
        code: "20307134",
        text: "Какие устройства должны быть установлены на нагнетательном газопроводе для отключения компрессора от коллектора высокого давления?",
        answers: [
          "Обратный клапан и два запорных вентиля, между которыми должна быть свеча с условным проходом не менее 6 мм, имеющая прямое сообщение с атмосферой",
          "Обратный клапан и 1 запорный вентиль",
          "Манометр и 2 запорных вентиля",
          "Байпас и 2 запорных вентиля",
        ],
        correctAnswers: [
          "Обратный клапан и два запорных вентиля, между которыми должна быть свеча с условным проходом не менее 6 мм, имеющая прямое сообщение с атмосферой",
        ],
      },
      {
        code: "20307135",
        text: "Через какое время должны наноситься защитные лакокрасочные покрытия на фланцы и болты межцеховых и цеховых газопроводов и газовых аппаратов?",
        answers: [
          "Через каждые 3 года",
          "Через каждые 5 лет",
          "Через каждые 2 года",
          "Ежегодно",
        ],
        correctAnswers: ["Через каждые 3 года"],
      },
      {
        code: "20307136",
        text: "Какой технической документацией должен быть оснащен каждый компрессорный агрегат или установка, или группа однородных компрессорных установок?",
        answers: [
          "Паспортом (формуляром) на компрессорную установку",
          "Инструкцией (руководством) по эксплуатации (безопасному обслуживанию) компрессорной установки",
          "Журналом учета работы компрессора",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20307137",
        text: "Какие требования к компрессорным установкам указаны неверно?",
        answers: [
          "Компрессор и его электродвигатель должны устанавливаться на фундаментах, связанных с конструкциями здания",
          "Качество изготовления компрессорных установок должно соответствовать требованиям нормативно-технической документации и документации организации-изготовителя",
          "Изготовление, монтаж, наладка, ремонт, испытания и эксплуатация компрессорных установок должны проводиться специализированными в этой области организациями",
          "Двери и окна помещения, где расположены компрессорные установки, должны открываться наружу",
        ],
        correctAnswers: [
          "Компрессор и его электродвигатель должны устанавливаться на фундаментах, связанных с конструкциями здания",
        ],
      },
      {
        code: "20307138",
        text: "Какие требования к компрессорным установкам указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Монтаж, наладка, ремонт, испытания и эксплуатация компрессорных установок должны проводиться организацией - владельцем компрессорной установки",
          "Компрессор и его электродвигатель должны устанавливаться на фундаментах, связанных с конструкциями здания",
          "Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь",
          "Качество изготовления компрессорных установок должно соответствовать требованиям нормативно-технической документации и документации организации-изготовителя",
        ],
        correctAnswers: [
          "Качество изготовления компрессорных установок должно соответствовать требованиям нормативно-технической документации и документации организации-изготовителя",
        ],
      },
      {
        code: "20307139",
        text: "Через какое время должны наноситься защитные лакокрасочные покрытия на наружные поверхности межцеховых и цеховых газопроводов и газовых аппаратов?",
        answers: [
          "Через каждые 5 лет",
          "Через каждые 7 лет",
          "Через каждые 4 года",
          "Через каждые 2 года",
        ],
        correctAnswers: ["Через каждые 5 лет"],
      },
      {
        code: "20307140",
        text: "С какой периодичностью должен производиться осмотр газопроводов, газовых установок и газового оборудования комиссией на производстве?",
        answers: [
          "Не реже 2 раз в год",
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 3 месяца",
        ],
        correctAnswers: ["Не реже 2 раз в год"],
      },
      {
        code: "20307141",
        text: "Кто из перечисленных лиц не должен входить в состав постоянно действующей комиссии для проведения осмотров объектов газового хозяйства?",
        answers: [
          "Начальник цеха - ответственный за производственный контроль",
          "Работник - ответственный за безопасную эксплуатацию и техническое состояние газового хозяйства цеха",
          "Работники, ответственные за безопасную эксплуатацию и техническое состояние соответствующих технических устройств, сооружений и оборудования",
          "Специалист по охране труда",
        ],
        correctAnswers: ["Специалист по охране труда"],
      },
      {
        code: "20307142",
        text: "С какой периодичностью должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях, и вне помещений?",
        answers: [
          "В помещениях плотность соединений должна проверяться не реже 1 раза в неделю, а вне помещений - не реже 1 раза в месяц",
          "В помещениях плотность соединений должна проверяться не реже 1 раза в месяц, а вне помещений - не реже 1 раза в 3 месяца",
          "В помещениях плотность соединений должна проверяться не реже 2 раз в месяц, а вне помещений - не реже 1 раза в месяц",
          "В помещениях плотность соединений должна проверяться не реже 4 раз в месяц, а вне помещений - не реже 2 раза в месяц",
        ],
        correctAnswers: [
          "В помещениях плотность соединений должна проверяться не реже 1 раза в неделю, а вне помещений - не реже 1 раза в месяц",
        ],
      },
      {
        code: "20307143",
        text: "С какой периодичностью должна производиться ревизия с разборкой регулятора давления, предохранительных клапанов, фильтров газопроводов, газовых установок и газового оборудования?",
        answers: [
          "Не менее 1 раза в год",
          "Не менее 1 раза в 6 месяцев",
          "Не менее 1 раза в 3 месяца",
          "Не менее 1 раза в 2 года",
        ],
        correctAnswers: ["Не менее 1 раза в год"],
      },
      {
        code: "20307144",
        text: "До каких пор должна осуществляться продувка азотом перед пуском в работу установки по производству реформерного газа для производства металлизованного горячевосстановленного железа?",
        answers: [
          "До тех пор, пока на участке продувки кислорода будет не более 1 % кислорода",
          "До тех пор, пока на участке продувки кислорода будет не более 1,5 % кислорода",
          "До тех пор, пока на участке продувки кислорода будет не более 2 % кислорода",
          "До тех пор, пока на участке продувки кислорода будет не более 3 % кислорода",
        ],
        correctAnswers: [
          "До тех пор, пока на участке продувки кислорода будет не более 1 % кислорода",
        ],
      },
      {
        code: "20307145",
        text: "Какое из перечисленных требований к установке по производству реформерного газа указано неверно?",
        answers: [
          "Допускается производить продувку воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами",
          "Установка по производству реформерного газа для производства металлизованного горячевосстановленного железа должна иметь подвод азота для его продувки перед пуском в работу",
          "Продувка азотом должна осуществляться до тех пор, пока на участке продувки кислорода будет не более 1 % кислорода",
          "Перед розжигом горелок реформера должна быть произведена продувка его топочного пространства",
        ],
        correctAnswers: [
          "Допускается производить продувку воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами",
        ],
      },
      {
        code: "20307146",
        text: "Кто утверждает инструкцию по перекачке сероуглерода из транспортной емкости в емкость хранения?",
        answers: [
          "Главный инженер предприятия",
          "Специалист по промышленной безопасности",
          "Начальник смены",
          "Генеральный директор",
        ],
        correctAnswers: ["Главный инженер предприятия"],
      },
      {
        code: "20307147",
        text: "Кем производится испытание газопроводов и газовых установок после окончания строительно-монтажных и сварочных работ (включая ремонтные работы)?",
        answers: [
          "Строительно-монтажной организацией с участием представителя предприятия",
          "Специализированной организацией",
          "Участком технического обслуживания и ремонта предприятия",
          "Газовой службой",
        ],
        correctAnswers: [
          "Строительно-монтажной организацией с участием представителя предприятия",
        ],
      },
      {
        code: "20307148",
        text: "Какое из перечисленных требований к манометрам, используемым при проведении испытаний газопроводов и газовых установок, указано верно?",
        answers: [
          "Манометры должны быть повереными и опломбированными",
          "Манометры должны иметь класс точности не ниже 1,5 и диаметр корпуса не менее 160 мм",
          "Шкала манометра на номинальное давление должна быть не менее 4/3 и не более 5/3 от величины измеряемого",
          "Все перечисленное верно",
        ],
        correctAnswers: ["Все перечисленное верно"],
      },
      {
        code: "20307149",
        text: "В течение какого времени выдерживают испытательное давление в трубопроводе при испытании на прочность, после чего снижают до рабочего давления, при котором производят тщательный осмотр сварных швов (испытание на плотность)?",
        answers: [
          "В течение 10 минут",
          "В течение 8 минут",
          "В течение 15 минут",
          "В течение 5 минут",
        ],
        correctAnswers: ["В течение 10 минут"],
      },
      {
        code: "20307150",
        text: "Какое устанавливается минимальное расстояние от испытываемого газопровода большого диаметра, расположенных вне помещений, до границ охраняемой зоны во время проведения пневматических испытаний на прочность?",
        answers: ["50 м", "25 м", "30 м", "40 м"],
        correctAnswers: ["50 м"],
      },
      {
        code: "20307151",
        text: "Каким давлением производится дополнительное испытание на герметичность межцеховых и внутрицеховых газопроводов?",
        answers: [
          "Давлением, равным рабочему",
          "Давлением, на 20% превышающем рабочее",
          "Давлением, на 25% превышающем рабочее",
          "Давлением, на 50% превышающем рабочее",
        ],
        correctAnswers: ["Давлением, равным рабочему"],
      },
      {
        code: "20307152",
        text: "Какая установлена продолжительность дополнительных испытаний на герметичность для вновь сооружаемых газопроводов?",
        answers: [
          "Не менее 24 часов",
          "Не менее 12 часов",
          "Не менее 10 часов",
          "Не менее 8 часов",
        ],
        correctAnswers: ["Не менее 24 часов"],
      },
      {
        code: "20307153",
        text: "Какая устанавливается продолжительность периодических испытаний на герметичность для газопроводов, а также испытаний после ремонта, связанного со сваркой и разборкой газопровода?",
        answers: [
          "Не менее 4 часов",
          "Не менее 3 часов",
          "Не менее 2 часов",
          "Не менее 1 часа",
        ],
        correctAnswers: ["Не менее 4 часов"],
      },
      {
        code: "20307154",
        text: "При какой скорости падения давления за время дополнительного пневматического испытания на герметичность внутрицеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?",
        answers: [
          "Не более 0,1% в час",
          "Не более 0,2% в час",
          "Не более 0,3% в час",
          "Не более 0,5% в час",
        ],
        correctAnswers: ["Не более 0,1% в час"],
      },
      {
        code: "20307155",
        text: "При какой скорости падения давления за время дополнительного пневматического испытания на герметичность межцеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?",
        answers: [
          "Не более 0,2% в час",
          "Не более 0,3% в час",
          "Не более 0,5% в час",
          "Не более 0,7% в час",
        ],
        correctAnswers: ["Не более 0,2% в час"],
      },
      {
        code: "20307156",
        text: "У кого должен находиться агрегатный (ремонтный) журнал на отдельных объектах газового цеха, а также в цехах, в составе которых имеются объекты газового хозяйства?",
        answers: [
          "У работника, ответственного за техническое состояние данного объекта газового хозяйства",
          "У начальника смены",
          "У главного инженера",
          "У специалиста по промышленной безопасности",
        ],
        correctAnswers: [
          "У работника, ответственного за техническое состояние данного объекта газового хозяйства",
        ],
      },
      {
        code: "20307157",
        text: "При каком избыточном давлении газа в газопроводах разрешается производить электросварочные работы на газопроводах при ремонтах?",
        answers: [
          "Не более 0,3 кПа",
          "Не более 0,5 кПа",
          "Не более 0,4 кПа",
          "Не более 0,7 кПа",
        ],
        correctAnswers: ["Не более 0,3 кПа"],
      },
      {
        code: "20307158",
        text: "Какие действия должны быть произведены перед продувкой газопровода? Выберите правильный вариант ответа.",
        answers: [
          "Задвижки должны быть установлены в нужное положение, водяные затворы залиты водой, а все люки, лазы и свечи плотно закрыты, за исключением той свечи, через которую будет производиться продувка",
          "Задвижки должны быть установлены в закрытое положение, водяные затворы залиты водой, а все люки, лазы и свечи плотно закрыты",
          "Задвижки должны быть установлены в нужное положение, водяные затворы освобождены от воды, а все люки, лазы и свечи плотно закрыты, за исключением той свечи, через которую будет производиться продувка",
          "Задвижки должны быть установлены в нужное положение, водяные затворы освобождены от воды, а все люки, лазы и свечи должны быть открыты",
        ],
        correctAnswers: [
          "Задвижки должны быть установлены в нужное положение, водяные затворы залиты водой, а все люки, лазы и свечи плотно закрыты, за исключением той свечи, через которую будет производиться продувка",
        ],
      },
      {
        code: "20307159",
        text: "В каком радиусе у мест выброса газовоздушной смеси в атмосферу должно быть приостановлено движение всех видов транспорта, запрещено применение открытого огня и производство сварочных работ в период продувки газопровода?",
        answers: [
          "В радиусе 50 м",
          "В радиусе 30 м",
          "В радиусе 20 м",
          "В радиусе 25 м",
        ],
        correctAnswers: ["В радиусе 50 м"],
      },
      {
        code: "20307160",
        text: "Какому классу должна соответствовать герметичность арматуры с металлическим уплотнением в затворе, применяемая для установки на трубопроводах водорода?",
        answers: ["Классу А", "Классу B", "Классу C", "Классу D"],
        correctAnswers: ["Классу B"],
      },
      {
        code: "20307161",
        text: "В каком случае допускается использовать продувочные свечи газопроводов для выпуска в атмосферу избыточного газа?",
        answers: [
          "В аварийном случае",
          "В случае проведения продувки газопровода",
          "В случае проведения дополнительного пневматического испытания на герметичность",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["В аварийном случае"],
      },
      {
        code: "20307162",
        text: "Какие сроки проведения ревизии газопроводов должны быть при скорости коррозии более 0,5 мм/г?",
        answers: [
          "1 раз в год",
          "1 раз в 2 года",
          "1 раз в 3 года",
          "1 раз в 4 года",
        ],
        correctAnswers: ["1 раз в год"],
      },
      {
        code: "20307163",
        text: "При какой скорости коррозии срок проведения ревизии газопроводов должен быть не реже одного раза в 2 года?",
        answers: [
          "При скорости коррозии 0,1 - 0,5 мм/г",
          "При скорости коррозии более 0,5 мм/г",
          "При скорости коррозии до 0,1 мм/г",
          "При скорости коррозии более 0,7 мм/г",
        ],
        correctAnswers: ["При скорости коррозии 0,1 - 0,5 мм/г"],
      },
      {
        code: "20307164",
        text: "Какой допускается максимальный подъем температуры газа в газопроводах?",
        answers: ["85 °C", "90 °C", "95 °C", "87 °C"],
        correctAnswers: ["85 °C"],
      },
      {
        code: "20307165",
        text: "В каком случае допускается подача газа без поднесения запальника, факела или другого воспламеняющего средства к горелке газопровода?",
        answers: [
          "В случае, если температура кладки в разогретой зоне розжига факела превышает 800 °C",
          "В случае, если подающее устройство оборудовано автоматическими системами гашения пламени",
          "В случае, если подающее устройство оборудовано системами автоматического пожаротушения",
          "В случае, если температура кладки в разогретой зоне розжига факела превышает 700 °C",
        ],
        correctAnswers: [
          "В случае, если температура кладки в разогретой зоне розжига факела превышает 800 °C",
        ],
      },
      {
        code: "20307166",
        text: "Кто осуществляет выбор типа быстродействующего отсекающего клапана на подводе газа к каждому агрегату с дутьевыми горелками?",
        answers: [
          "Организация, проектирующая систему автоматики печи",
          "Организация, проектирующая печь",
          "Организация, эксплуатирующая печь",
          "Организация, обслуживающая печь",
        ],
        correctAnswers: ["Организация, проектирующая систему автоматики печи"],
      },
      {
        code: "20307167",
        text: "Какое из перечисленных требований при эксплуатации газового оборудования газопотребляющих агрегатов указано верно?",
        answers: [
          "Продувочные свечи после отключения газопровода должны быть в закрытом положении",
          "Розжиг газопотребляющего агрегата должен проводиться в соответствии с инструкцией по эксплуатации агрегата",
          "Отключающее устройство на газопроводе перед горелкой необходимо открыть до поднесения к горелке запальника, факела или другого средства, воспламеняющего газ",
          "Агрегаты с температурой в рабочем пространстве ниже 800 °C должны быть оснащены средствами контроля факела каждой горелки с выносом светозвукового сигнала при потухании горелок на щит теплотехнического контроля",
        ],
        correctAnswers: [
          "Агрегаты с температурой в рабочем пространстве ниже 800 °C должны быть оснащены средствами контроля факела каждой горелки с выносом светозвукового сигнала при потухании горелок на щит теплотехнического контроля",
        ],
      },
      {
        code: "20307168",
        text: "С какой периодичностью должна производиться режимная наладка газоиспользующего оборудования?",
        answers: [
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 5 лет",
          "Не реже 1 раза в 4 года",
          "Не реже 1 раза в 10 лет",
        ],
        correctAnswers: ["Не реже 1 раза в 3 года"],
      },
      {
        code: "20307169",
        text: "В каком случае допускается режимную наладку газоиспользующего оборудования проводить не реже одного раза в четыре года?",
        answers: [
          "При стабильной работе газоиспользующего оборудования и организации систематического проведения контроля выбросов загрязняющих веществ в атмосферу",
          "В случае если срок срок эксплуатации оборудования составляет не более 15 лет",
          "В случае если срок срок эксплуатации оборудования составляет не более 10 лет",
          "В случае организации систематического контроля выбросов загрязняющих веществ в атмосферу не реже чем 1 раз в месяц",
        ],
        correctAnswers: [
          "При стабильной работе газоиспользующего оборудования и организации систематического проведения контроля выбросов загрязняющих веществ в атмосферу",
        ],
      },
      {
        code: "20307170",
        text: "С какой периодичностью должна производиться проверка технического состояния вентиляционных каналов в зданиях (помещениях) с установленным газоиспользующим оборудованием и пылеуборка, и дезинфекция вентиляционных каналов?",
        answers: [
          "Проверка технического состояния - не реже 2 раз в год, пылеуборка и дезинфекция - не реже 1 раза в 3 года",
          "Проверка технического состояния - не реже 1 раза в 2 года, пылеуборка и дезинфекция - не реже 1 раза в 3 года",
          "Проверка технического состояния - не реже 1 раза в 3 года, пылеуборка и дезинфекция - не реже 1 раза в 4 года",
          "Проверка технического состояния - не реже 1 раза в 5 лет, пылеуборка и дезинфекция - не реже 1 раза в 2 года",
        ],
        correctAnswers: [
          "Проверка технического состояния - не реже 2 раз в год, пылеуборка и дезинфекция - не реже 1 раза в 3 года",
        ],
      },
      {
        code: "20307171",
        text: "С какой периодичностью должны производиться проверка технического состояния промышленных дымоотводящих устройств (газоходов газоиспользующего оборудования, дымоходов и дымовых труб) и их прочистка?",
        answers: [
          "Не реже 2 раз в год",
          "Не реже 1 раза в год",
          "Не реже 1 раз в 2 года",
          "Не реже 1 раз в 3 года",
        ],
        correctAnswers: ["Не реже 2 раз в год"],
      },
      {
        code: "20307172",
        text: "Какая продолжительность пневматических испытаний установлена Правилами безопасности процессов получения или применения металлов после проведения плановых или аварийных остановок водородопроводов?",
        answers: ["1 час", "30 минут", "40 минут", "2 часа"],
        correctAnswers: ["1 час"],
      },
      {
        code: "20307173",
        text: "На каких минимальных расстояниях (по горизонтали) до групповых газобаллонных установок допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?",
        answers: ["10 м", "5 м", "3 м", "7 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "20307174",
        text: "На каких минимальных расстояниях (по горизонтали) до отдельных баллонов с кислородом и горючими газами допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?",
        answers: ["5 м", "10 м", "3 м", "1,5 м"],
        correctAnswers: ["5 м"],
      },
      {
        code: "20307175",
        text: "В каком положении должны находиться баллоны со сжиженным газом во время работы?",
        answers: [
          "В вертикальном положении",
          "В горизонтальном положении",
          "Не регламентируется",
          "В наклонном положении",
        ],
        correctAnswers: ["В вертикальном положении"],
      },
      {
        code: "20307176",
        text: "Какое действие должно быть осуществлено в первую очередь при зажигании ручной горелки или резака?",
        answers: [
          "Приоткрыть вентиль кислорода",
          "Открыть вентиль горючего газа",
          "Кратковременно продуть рукав для удаления воздуха",
          "Зажечь горючую смесь",
        ],
        correctAnswers: ["Приоткрыть вентиль кислорода"],
      },
      {
        code: "20307177",
        text: "Какое из перечисленных действий допускается при эксплуатации оборудования для газопламенной обработки металлов? Выберите правильный вариант ответа.",
        answers: [
          "При работе передвижных газоразборных постов, питаемых от отдельных баллонов, разрешается установка на одной тележке специальной конструкции баллона с горючим газом и баллона с кислородом",
          "Баллоны при перемещении на тележке можно не закреплять при условии перевозки в вертикальном положении",
          "Максимально допустимая температура баллона сжиженного газа должна соответствовать требованиям технологических карт",
          "При питании передвижного рабочего поста сжиженным газом от баллона допускается отбирать газ из баллона при снижении в нем рабочего давления ниже требуемого",
        ],
        correctAnswers: [
          "При работе передвижных газоразборных постов, питаемых от отдельных баллонов, разрешается установка на одной тележке специальной конструкции баллона с горючим газом и баллона с кислородом",
        ],
      },
      {
        code: "20307178",
        text: "С какой периодичностью должен производиться текущий ремонт оборудования с разборкой регуляторов давления, предохранительных клапанов и фильтров ГРП и ГРУ, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания?",
        answers: [
          "Не менее 1 раза в год, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания",
          "Не менее 1 раза в 2 года, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания",
          "Не менее 1 раза в 3 года",
          "Не менее 1 раза в 5 лет",
        ],
        correctAnswers: [
          "Не менее 1 раза в год, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания",
        ],
      },
      {
        code: "20307179",
        text: "При каком давлении должна производиться настройка срабатывания предохранительно-запорных клапанов при их наличии в ГРП (ГРУ)?",
        answers: [
          "При давлении на 15 % больше расчетного",
          "При давлении на 12 % больше расчетного",
          "При давлении на 10 % больше расчетного",
          "При давлении на 5 % больше расчетного",
        ],
        correctAnswers: ["При давлении на 15 % больше расчетного"],
      },
      {
        code: "20307180",
        text: "В каком случае допускается подача потребителям газа по обводной линии (байпасу) ГРП и ГРУ?",
        answers: [
          "На время ревизии или ремонта оборудования",
          "Только на время ревизии",
          "Только на время ремонта",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["На время ревизии или ремонта оборудования"],
      },
      {
        code: "20307181",
        text: "Какие виды работ должны выполняться не реже одного раза в 6 месяцев при эксплуатации ГРП и ГРУ?",
        answers: [
          "Техническое обслуживание",
          "Осмотр технического состояния (обход)",
          "Текущий ремонт",
          "Капитальный ремонт",
        ],
        correctAnswers: ["Техническое обслуживание"],
      },
      {
        code: "20307182",
        text: "Какие из перечисленных видов работ не должны выполняться при техническом обслуживании технологического оборудования ГРП и ГРУ? Выберите 2 варианта ответа.",
        answers: [
          "Устранение повреждений газопроводов обвязки технологического оборудования",
          "Восстановление окраски ограждений и технических устройств",
          "Проверка работоспособности средств измерений установкой стрелки на нулевое деление шкалы и (при необходимости) их замена",
          "Пмазка подвижных элементов запорной арматуры (без разборки)",
        ],
        correctAnswers: [
          "Устранение повреждений газопроводов обвязки технологического оборудования",
          "Восстановление окраски ограждений и технических устройств",
        ],
      },
      {
        code: "20307183",
        text: "В какие сроки должен проводиться капитальный ремонт ГРП и ГРУ?",
        answers: [
          "В сроки, установленные изготовителем",
          "В сроки, определяемые специализированной организацией, но не реже чем 1 раз в 5 лет",
          "В сроки, установленные эксплуатирующей организацией",
          "В сроки, определяемые специализированной организацией, но не реже чем 1 раз в 10 лет",
        ],
        correctAnswers: ["В сроки, установленные изготовителем"],
      },
      {
        code: "20307184",
        text: "Чем должна производиться продувка импульсных газовых проводок?",
        answers: ["Паром", "Сжатым воздухом", "Газом", "Всем перечисленным"],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20307185",
        text: "Какой должна быть объемная доля кислорода в воздухе производственных помещений производства ПРВ?",
        answers: [
          "Не менее 19 % и не более 23 %",
          "Не менее 18 % и не более 23 %",
          "Не менее 17 % и не более 23 %",
        ],
        correctAnswers: ["Не менее 19 % и не более 23 %"],
      },
      {
        code: "20307186",
        text: "Что необходимо устанавливать в производственных помещениях, где возможны воспламенение одежды или химические ожоги у работников?",
        answers: [
          "Воздушно-пенный огнетушитель",
          "Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
          "Брезентовые покрывала",
        ],
        correctAnswers: [
          "Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
      },
      {
        code: "20307187",
        text: "Какие требования установлены к оборудованию производственных помещений, в которых возможны воспламенения одежды или химические ожоги?",
        answers: [
          "Должны быть в наличии огнетушители",
          "Должны быть устроены ящики с песком",
          "Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
        correctAnswers: [
          "Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
      },
      {
        code: "20307188",
        text: "Какие требования установлены к инструментам и приспособлениям, используемым во взрывопожароопасных зонах и помещениях?",
        answers: [
          "Не должны давать искры при работе с ними",
          "Должны быть изготовлены из стали",
          "Должны быть изготовлены из чугуна",
        ],
        correctAnswers: ["Не должны давать искры при работе с ними"],
      },
      {
        code: "20307189",
        text: "Какие требования установлены к проведению ремонтных работ внутри нагретых технических устройств?",
        answers: [
          "Проведение ремонтных работ разрешается после их продувки и снижения температуры воздуха в них до 50 °C",
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 45 °C",
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C",
        ],
        correctAnswers: [
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C",
        ],
      },
      {
        code: "20307190",
        text: "После установления каких показателей температуры разрешается проводить ремонтные работы внутри нагретых технических устройств, а также низкотемпературных технических устройств?",
        answers: [
          "После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания",
          "После снижения температуры воздуха до 45 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания",
          "После снижения температуры воздуха до 50 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (22 °C) и проветривания",
        ],
        correctAnswers: [
          "После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания",
        ],
      },
      {
        code: "20307191",
        text: "При каких условиях допускается перенос сроков проведения капитальных ремонтов основного технологического оборудования?",
        answers: [
          "При наличии положительного заключения экспертного обследования",
          "При наличии разрешения завода-изготовителя",
          "При наличии разрешения территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "При наличии положительного заключения экспертного обследования",
        ],
      },
      {
        code: "20307192",
        text: "Каким образом должен проводиться отогрев трубопроводной арматуры?",
        answers: [
          "Газовой горелкой",
          "Горячим воздухом, паром или горячей водой",
          "Электрическими приборами",
        ],
        correctAnswers: ["Горячим воздухом, паром или горячей водой"],
      },
      {
        code: "20307193",
        text: "С какой периодичностью следует очищать фильтры?",
        answers: [
          "В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
          "В первый раз - через 15 суток после ввода в эксплуатацию, далее - через каждые 3 месяца",
          "В первый раз - через 20 суток после ввода в эксплуатацию, далее - через каждые 2 месяца",
        ],
        correctAnswers: [
          "В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
        ],
      },
      {
        code: "20307194",
        text: "Как часто должен производиться осмотр трубопроводов кислорода?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в квартал",
          "Не реже одного раза в полгода",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20307195",
        text: "Как часто должна производиться проверка конденсатоотводчиков и пароспутников, обогревающих трубопроводы кислорода в зимнее время?",
        answers: ["Ежедневно", "Ежедекадно", "Ежемесячно"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "20307196",
        text: "Как часто должна проводиться проверка правильности показаний приборов, перечень которых утверждается руководителем производства?",
        answers: [
          "Не реже одного раза в 2 месяца",
          "Не реже одного раза в месяц",
          "Не реже одного раза в 3 месяца",
        ],
        correctAnswers: ["Не реже одного раза в 3 месяца"],
      },
      {
        code: "20307197",
        text: "Из каких материалов выполняются линии отбора кислорода на анализ?",
        answers: [
          "Из углеродистой стали",
          "Из коррозионно-стойкой стали или медных сплавов",
          "Только из медных сплавов",
        ],
        correctAnswers: ["Из коррозионно-стойкой стали или медных сплавов"],
      },
      {
        code: "20307198",
        text: "Какой установлен порядок хранения обтирочных материалов?",
        answers: [
          "В чистых металлических ящиках с крышками",
          "На специально оборудованной площадке",
          "В специально выделенном помещении",
        ],
        correctAnswers: ["В чистых металлических ящиках с крышками"],
      },
      {
        code: "20307199",
        text: "Из каких материалов не могут быть изготовлены разделители (заглушки) или другие изделия, помещаемые внутри кислородопровода при его обезжиривании?",
        answers: [
          "Из поролона или других органических материалов",
          "Из меди",
          "Из бронзы",
        ],
        correctAnswers: ["Из поролона или других органических материалов"],
      },
      {
        code: "20307200",
        text: "Из каких сплавов металлов запрещается установка арматуры на кислородопроводах?",
        answers: ["Из меди", "Из стали", "Из сплавов титана"],
        correctAnswers: ["Из сплавов титана"],
      },
      {
        code: "20307201",
        text: "Какой предел огнестойкости должен быть у стен, разделяющих взрывоопасные помещения на водородных станциях?",
        answers: ["2,5 часа", "1,5 часа", "2 часа", "1 час"],
        correctAnswers: ["2,5 часа"],
      },
      {
        code: "20307202",
        text: "Какая должна быть минимальная высота забора по периметру территории всего комплекса производства водорода?",
        answers: ["2 м", "3 м", "1,5 м", "3,5 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "20307203",
        text: "С каким интервалом должна проверяться объемная доля кислорода в месте проведения ремонтных работ во время нахождения персонала внутри трубопроводов, опорных обечаек регенераторов, в отсеке обратноповоротных клапанов или арматуры?",
        answers: [
          "Не менее чем 10 минут",
          "Не менее чем 5 минут",
          "Не менее чем 7 минут",
          "Не менее чем 8 минут",
        ],
        correctAnswers: ["Не менее чем 10 минут"],
      },
      {
        code: "20307204",
        text: "Какое должно быть расстояние между ресиверами одного газа?",
        answers: [
          "Не менее 1,5 м в свету",
          "Не менее 1,3 м в свету",
          "Не менее 1 м в свету",
          "Не менее 1,1 м в свету",
        ],
        correctAnswers: ["Не менее 1,5 м в свету"],
      },
      {
        code: "20307205",
        text: "Какие требования к ресиверам для водорода (сосудам) указаны неверно?",
        answers: [
          "Расстояние от ресиверов (сосудов) с водородом до ограждения должно быть не менее 1,2 м",
          "Расстояние между ресиверами одного газа должно быть не менее 1,5 м в свету",
          "Открытые площадки для размещения ресиверов должны иметь ограждение легкого типа высотой не менее 1,2 м из несгораемого материала",
          'На ограждении площадки, на которой установлен ресивер должны быть вывешены предупреждающие знаки безопасности: "Курить запрещается", "Посторонним вход воспрещен", на ресиверах должны быть поясняющие надписи: "Водород. Взрывоопасно"',
        ],
        correctAnswers: [
          "Расстояние от ресиверов (сосудов) с водородом до ограждения должно быть не менее 1,2 м",
        ],
      },
      {
        code: "20307206",
        text: "Какие из перечисленных ремонтных работ допускается производить одновременно при ремонте арматуры, расположенной на теплом и холодном концах регенераторов в период остановки ВРУ?",
        answers: [
          "Ремонт трубопроводов и арматуры теплого и холодного концов регенераторов",
          'Ремонт трубопроводов и арматуры теплого или холодного конца регенераторов и системы "приказного" воздуха переключения регенераторов, или механизма переключения',
          "Ремонт принудительных клапанов переключения регенераторов и трехходовых заслонок после регенераторов",
          "Одновременное проведение ремонтных работ не допускается",
        ],
        correctAnswers: [
          "Одновременное проведение ремонтных работ не допускается",
        ],
      },
      {
        code: "20307207",
        text: "Какие действия необходимо осуществить перед ремонтом клапанов (трубопроводов) теплого и холодного концов регенераторов в период остановки ВРУ? Выберите правильный вариант ответа.",
        answers: [
          "Перевести на ручной режим систему автоматического управления регенераторов",
          "Закрыть или открыть соответствующие клапаны с помощью дистанционного управления",
          'Установить предупреждающий знак безопасности "Не включать, работают люди!"',
          "Все перечисленные едйствия",
        ],
        correctAnswers: ["Все перечисленные едйствия"],
      },
      {
        code: "20307208",
        text: "При каком условии ВРУ должна подвергаться обезжириванию?",
        answers: [
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами",
          "При достижении концентрации масла в жидком кислороде 0,30 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами",
          "При достижении концентрации масла в жидком кислороде 0,20 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами",
          "При достижении концентрации масла в жидком кислороде 0,25 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами",
        ],
        correctAnswers: [
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами",
        ],
      },
      {
        code: "20307209",
        text: "Какие установлены сроки обезжиривания ВРУ, работающих по схеме высокого и среднего давлений с блоками комплексной очистки на цеолитах и турбодетандерами, а также по схеме низкого давления?",
        answers: [
          "Не регламентируются",
          "1 раз в год",
          "1 раз в месяц",
          "1 раз в квартал",
        ],
        correctAnswers: ["Не регламентируются"],
      },
      {
        code: "20307210",
        text: "Что из перечисленного не подлежит обезжириванию в процессе эксплуатации ВРУ? Выберите правильный вариант ответа.",
        answers: [
          "Средства измерения общего назначения для работы в кислородосодержащей среде с объемной долей кислорода до 20%",
          "Колонны высокого и низкого давлений",
          "Регенераторы (при переработке воздуха, сжимаемого компрессорами со смазываемыми цилиндрами)",
          "Воздушные секции теплообменников",
        ],
        correctAnswers: [
          "Средства измерения общего назначения для работы в кислородосодержащей среде с объемной долей кислорода до 20%",
        ],
      },
      {
        code: "20307211",
        text: "В каком случае арматура, предназначенная для работы с кислородом, не подлежит обезжириванию перед монтажом?",
        answers: [
          "В случае, если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка",
          "В случае проведения технического обслуживания арматуры",
          "В случае проведения монтажа нового оборудования",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: [
          "В случае, если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка",
        ],
      },
      {
        code: "20307212",
        text: "Какое из перечисленных требований при необходимости проведения обезжиривания технических устройств и трубопроводов ПРВ растворителями указано неверно?",
        answers: [
          "Перелив растворителей из одного сосуда в другой допускается любым удобным способом при наличии у работающих СИЗ",
          "Доступ в помещение, где хранятся растворители, разрешается только лицам, допущенным к работе с ними",
          "Тару из-под растворителей необходимо плотно закрывать и хранить только в предназначенном для этого помещении или на открытом воздухе",
          "Проливы растворителей на пол не допускаются; случайно пролитый растворитель должен быть немедленно убран с помощью сухих материалов",
        ],
        correctAnswers: [
          "Перелив растворителей из одного сосуда в другой допускается любым удобным способом при наличии у работающих СИЗ",
        ],
      },
      {
        code: "20307213",
        text: "До какой максимальной температуры допускается нагрев поверхностей в помещениях, где производится обезжиривание кислородного оборудования пожаровзрывоопасными растворителями?",
        answers: ["120 °C", "150 °C", "160 °C", "130 °C"],
        correctAnswers: ["120 °C"],
      },
      {
        code: "20307214",
        text: "До какой температуры должен отогреваться сосуд, бывший в эксплуатации, перед проведением работ по обезжириванию способом протирки и чем он должен продуваться?",
        answers: [
          "Не ниже 20 °C",
          "Не ниже 18 °C",
          "Не ниже 15 °C",
          "Не ниже 10 °C",
        ],
        correctAnswers: ["Не ниже 20 °C"],
      },
      {
        code: "20307215",
        text: "Какое из перечисленных требований к потреблению газообразного кислорода и других продуктов разделения воздуха указано неверно?",
        answers: [
          "Кислородная арматура для присоединения рукавов должна быть размещена в герметичном металлическом шкафу",
          "Использование продуктов разделения воздуха по каждому производству, участку или объекту должно осуществляться по технологическим инструкциям",
          "На кислородопроводах не допускается установка арматуры из сплавов на основе титана",
          "Не допускается устанавливать и применять кислородное оборудование возле замасляного или прожированного оборудования, территории",
        ],
        correctAnswers: [
          "Кислородная арматура для присоединения рукавов должна быть размещена в герметичном металлическом шкафу",
        ],
      },
      {
        code: "20307216",
        text: "Какое значение не должно превышать содержание сероводорода в коксовом газе в заводских сетях действующих предприятий?",
        answers: ["4 г/нм³", "5 г/нм³", "6 г/нм³", "4,5 г/нм³"],
        correctAnswers: ["4 г/нм³"],
      },
      {
        code: "20307217",
        text: "Какое значение не должно превышать содержание сероводорода в коксовом газе при газоплазменной обработке металла в закрытых помещениях, а также нафталина летом и зимой?",
        answers: [
          "Содержание сероводорода в коксовом газе не должно превышать 0,02 г/нм³, нафталина - 0,05 г/нм³ (летом) и 0,1 г/нм³ (зимой)",
          "Содержание сероводорода в коксовом газе не должно превышать 0,07 г/нм³, нафталина - 0,2 г/нм³ (летом) и 0,2 г/нм³ (зимой)",
          "Содержание сероводорода в коксовом газе не должно превышать 0,03 г/нм³, нафталина - 0,09 г/нм³ (летом) и 0,1 г/нм³ (зимой)",
          "Содержание сероводорода в коксовом газе не должно превышать 0,04 г/нм³, нафталина - 0,04 г/нм³ (летом) и 0,1 г/нм³ (зимой)",
        ],
        correctAnswers: [
          "Содержание сероводорода в коксовом газе не должно превышать 0,02 г/нм³, нафталина - 0,05 г/нм³ (летом) и 0,1 г/нм³ (зимой)",
        ],
      },
      {
        code: "20307218",
        text: "Какие требования из перечисленных должны предусматриваться для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты? Выберите правильный вариант ответа.",
        answers: [
          "Должно предусматриваться дистанционное отключение и установка на линиях всасывания и нагнетания запорных или отсекающих устройств",
          "Должно предусматриваться дистанционное отключение и установка на линиях всасывания запорных устройств",
          "Должна предусматриваться установка отсекающих устройств только на линиях всасывания",
          "Должно предусматриваться только дистанционное отключение",
        ],
        correctAnswers: [
          "Должно предусматриваться дистанционное отключение и установка на линиях всасывания и нагнетания запорных или отсекающих устройств",
        ],
      },
      {
        code: "20307219",
        text: "В каком случае все технологическое оборудование должно продуваться инертным газом? Выберите 2 варианта ответа.",
        answers: [
          "После остановки более чем на 2 часа",
          "Перед пуском",
          "После остановки более чем на 1 час",
          "Перед пуском, при условии что в период остановки оборудование находилось под избыточным давлением кислорода",
        ],
        correctAnswers: ["После остановки более чем на 2 часа", "Перед пуском"],
      },
      {
        code: "20307220",
        text: "При каком содержании водорода в воздухе производственного помещения по сигналу от автоматического газоанализатора технологическое оборудование этого помещения должно быть остановлено?",
        answers: [
          "Выше 25 % нижнего предела взрываемости",
          "Выше 27 % нижнего предела взрываемости",
          "Выше 30 % нижнего предела взрываемости",
          "Выше 28 % нижнего предела взрываемости",
        ],
        correctAnswers: ["Выше 25 % нижнего предела взрываемости"],
      },
      {
        code: "20307221",
        text: "При каком содержании газов должна срабатывать световая и звуковая сигнализации, установленные с автоматическими газоанализаторами в помещениях категории А, где обращается водород?",
        answers: [
          "При содержании в воздухе помещения водорода не более 10 % нижнего предела взрываемости (0,4 % об.) и угарного газа более 20 мг/м³",
          "При содержании в воздухе помещения кислорода менее 19 % и более 23 % и метана не более 10 % нижнего предела взрываемости",
          "Все перечисленное верно",
        ],
        correctAnswers: ["Все перечисленное верно"],
      },
      {
        code: "20307222",
        text: "Какие требования к вентиляции помещения, где возможно выделение водорода, указаны верно? Выберите 2 варианта ответа.",
        answers: [
          "Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час",
          "При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час",
          "Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в 2 часа",
          "При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 5 в час",
        ],
        correctAnswers: [
          "Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час",
          "При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час",
        ],
      },
      {
        code: "20307223",
        text: "Стальными листами какой толщины должны отделяться от рабочей зоны баллоны с водородом при их горизонтальном расположении и размещении внутри помещения?",
        answers: [
          "Не менее 16 мм",
          "Не менее 15 мм",
          "Не менее 10 мм",
          "Не менее 12 мм",
        ],
        correctAnswers: ["Не менее 16 мм"],
      },
      {
        code: "20307224",
        text: "При какой чистоте водорода должен производиться пуск компрессора для наполнения баллонов?",
        answers: [
          "Не менее 99,7%",
          "Не менее 99%",
          "Не менее 99,5%",
          "Не менее 99,3%",
        ],
        correctAnswers: ["Не менее 99,7%"],
      },
      {
        code: "20307225",
        text: "На какой ступени сжатия водородные компрессоры должны иметь предохранительные клапаны?",
        answers: [
          "На всех ступенях сжатия",
          "На всех, кроме 1 ступени сжатия",
          "На последней ступени сжатия",
          "На 1 и 2 ступени сжатия",
        ],
        correctAnswers: ["На всех ступенях сжатия"],
      },
      {
        code: "20307226",
        text: "Какому классу должна соответствовать герметичность затвора запорной арматуры со средой водорода?",
        answers: ["Классу А", "Классу B", "Классу C", "Классу D"],
        correctAnswers: ["Классу А"],
      },
      {
        code: "20307227",
        text: "Какую арматуру не допускается применять на водородопроводах?",
        answers: [
          "Арматуру из серого чугуна",
          "Арматуру из легированной стали",
          "Арматуру из высокопрочного чугуна",
          "Арматуру из стали для взрывоопасных сред",
        ],
        correctAnswers: ["Арматуру из серого чугуна"],
      },
      {
        code: "20307228",
        text: "В соответствии с требованиями какой документации должны производиться проектирование, монтаж и эксплуатация водородопроводов, а также вспомогательных трубопроводов обвязки участков комплекса получения и потребления водорода?",
        answers: [
          "В соответствии с требованиями строительных норм и правил, а также нормативно-технической документации для технологических стальных трубопроводов и Правил безопасности процессов получения или применения металлов",
          "В соответствии с исполнительной документацией",
          "В соответствии с проектной документацией",
          "В соответствии с требованиями внутренних регламентов организации-заказчика",
        ],
        correctAnswers: [
          "В соответствии с требованиями строительных норм и правил, а также нормативно-технической документации для технологических стальных трубопроводов и Правил безопасности процессов получения или применения металлов",
        ],
      },
      {
        code: "20307229",
        text: "Какие требования к монтажу и эксплуатации водородопроводов указаны неверно?",
        answers: [
          "При прокладке трубопроводов водорода следует использовать бесшовные стальные трубы или трубопроводы из нержавеющей стали, соединенные фланцевым соединением",
          "Подземная и канальная прокладка трубопроводов водорода не допускается",
          "Прокладка газопроводов в местах прохода людей должна предусматриваться на высоте не менее 2,2 м от пола до низа газопровода",
          "Допускается совместная прокладка трубопроводов водорода с другими трубопроводами на общих опорах при условии обеспечения возможности осмотра и ремонта каждого из трубопроводов",
        ],
        correctAnswers: [
          "При прокладке трубопроводов водорода следует использовать бесшовные стальные трубы или трубопроводы из нержавеющей стали, соединенные фланцевым соединением",
        ],
      },
      {
        code: "20307230",
        text: "Через какое время после ввода объекта в эксплуатацию должна проводиться первая выборочная ревизия водородопроводов на вновь осваиваемых производствах?",
        answers: [
          "Не позднее чем через 4 года после ввода объекта в эксплуатацию",
          "Не позднее чем через 5 лет после ввода объекта в эксплуатацию",
          "Не позднее чем через 6 лет после ввода объекта в эксплуатацию",
          "Не позднее чем через 10 лет после ввода объекта в эксплуатацию",
        ],
        correctAnswers: [
          "Не позднее чем через 4 года после ввода объекта в эксплуатацию",
        ],
      },
      {
        code: "20307231",
        text: "С какой периодичностью должны проводиться испытания на плотность и прочность холодных участков (с температурой до 200 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?",
        answers: [
          "1 раз в 8 лет",
          "1 раз в 10 лет",
          "1 раз в 9 лет",
          "1 раз в 12 лет",
        ],
        correctAnswers: ["1 раз в 8 лет"],
      },
      {
        code: "20307232",
        text: "С какой периодичностью должны проводиться испытания на плотность и прочность горячих участков (с температурой 200 - 400 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?",
        answers: [
          "1 раз в 5 лет",
          "1 раз в 7 лет",
          "1 раз в 6 лет",
          "1 раз в 8 лет",
        ],
        correctAnswers: ["1 раз в 5 лет"],
      },
      {
        code: "20307233",
        text: "В каких местах допускается прокладка водородопроводов?",
        answers: [
          "В пределах предприятий над поверхностью земли",
          "В пределах предприятий на стойках",
          "В пределах предприятий на эстакадах",
          "Все перечисленное верно",
        ],
        correctAnswers: ["Все перечисленное верно"],
      },
      {
        code: "20307234",
        text: "Через какие помещения допускается прокладка водородопроводов?",
        answers: [
          "Через производственные помещения, связанные с потреблением водорода",
          "Через бытовые и подсобные помещения",
          "Через административно-хозяйственные помещения",
          "Через вентиляционные камеры",
        ],
        correctAnswers: [
          "Через производственные помещения, связанные с потреблением водорода",
        ],
      },
      {
        code: "20307235",
        text: "Какие требования к размещению запорной арматуры на водородопроводах указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Запорная и другая арматура должна устанавливаться в местах, удобных для обслуживания",
          "Ручной привод арматуры должен располагаться на высоте не более 2 м от уровня площадки или пола помещения.",
          "Для быстрого доступа арматура должна размещаться в технологических проходах",
          "Арматуру следует размещать над дверными проемами",
        ],
        correctAnswers: [
          "Запорная и другая арматура должна устанавливаться в местах, удобных для обслуживания",
        ],
      },
      {
        code: "20307236",
        text: "Какое минимальное остаточное давление должны иметь баллоны, подготовленные для наполнения водородом?",
        answers: ["0,05 МПа", "0,02 МПа", "0,01 МПа", "0,03 МПа"],
        correctAnswers: ["0,05 МПа"],
      },
    ],
    20308: [
      {
        code: "20308000",
        text: "Какие работы производятся на ОПО горно-металлургических производств? Выберите правильный вариант ответа.",
        answers: [
          "Все перечисленные",
          "Работы повышенной опасности (сложные, разовые, уникальные)",
          "Работы, связанные с аварийными ситуациями",
          "Постоянные работы, выполняемые в соответствии с документированной процедурой (инструкцией, картой, регламентом), принятой в организации",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20308001",
        text: "Какие из перечисленных требований к акту-допуску подрядных организаций на опасный производственный объект горно-металлургической промышленности указаны неверно?",
        answers: [
          "Акт-допуск должен оформляться письменно",
          "На один объект, площадку, территорию должен оформляться один акт-допуск",
          "Не допускается оформлять акты-допуски, характеризующиеся одинаковыми координатами рабочих зон",
          "Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)",
        ],
        correctAnswers: [
          "Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)",
        ],
      },
      {
        code: "20308002",
        text: "Какие действия предпринимаются при необходимости ведения работ после истечения срока действия акта-допуска подрядных организаций на опасный производственный объект горно-металлургической промышленности?",
        answers: [
          "Составляется новый акт-допуск на следующий срок",
          "Продлевается текущий акт-допуск",
          "Работы продолжаются без оформления нового акта-допуска",
        ],
        correctAnswers: ["Составляется новый акт-допуск на следующий срок"],
      },
      {
        code: "20308003",
        text: "В каком количестве экземпляров составляется акт-допуск подрядных организаций на опасный производственный объект горно-металлургической промышленности?",
        answers: [
          "В двух экземплярах",
          "В одном экземпляре",
          "В четырех экземплярах",
          "В трех экземплярах",
        ],
        correctAnswers: ["В двух экземплярах"],
      },
      {
        code: "20308004",
        text: "С кем согласовывается перечень работ повышенной опасности на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля",
          "С руководителем газоспасательной службы",
          "С техническим руководителем организации",
          "С Ростехнадзором",
        ],
        correctAnswers: [
          "Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля",
        ],
      },
      {
        code: "20308005",
        text: "Кем могут проводиться работы повышенной опасности на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "Как работниками эксплуатирующей организации, так и работниками подрядных организаций",
          "Только работниками подрядных организаций",
          "Только работниками эксплуатирующей организации",
          "Сотрудником отдела промышленной безопасности эксплуатирующей организации",
        ],
        correctAnswers: [
          "Как работниками эксплуатирующей организации, так и работниками подрядных организаций",
        ],
      },
      {
        code: "20308006",
        text: "В течение какого времени хранится журнал регистрации нарядов-допусков со дня внесения последней записи?",
        answers: [
          "В течение 6 месяцев",
          "В течение 3 месяцев",
          "В течение месяца",
          "В течение года",
        ],
        correctAnswers: ["В течение 6 месяцев"],
      },
      {
        code: "20308007",
        text: "На какой максимальный срок выдается наряд-допуск на работы повышенной опасности?",
        answers: [
          "На 20 рабочих дней",
          "На 45 календарных дней",
          "На 15 календарных дней",
          "На 30 календарных дней",
        ],
        correctAnswers: ["На 30 календарных дней"],
      },
      {
        code: "20308008",
        text: "Кто определяет (назначает) работников, выполняющих мероприятия по безопасности труда, указанных в наряде-допуске (ответственных исполнителей)?",
        answers: [
          "Выдающий наряд-допуск",
          "Лицо, ответственное за осуществление производственного контроля",
          "Технический руководитель организации",
          "Допускающий к работе",
        ],
        correctAnswers: ["Выдающий наряд-допуск"],
      },
      {
        code: "20308009",
        text: "Какие из перечисленных требований к наряду-допуску на работы повышенной опасности указаны неверно? Выберите два правильных ответа.",
        answers: [
          "При перерыве в работе в течение одной смены (обеденный перерыв, перерыв по условиям производства работ) наряд-допуск остается у производителя (руководителя) работ",
          "Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ",
          "После окончания работы наряд-допуск остается у производителя (руководителя) работ",
          "При допуске бригады к работе допускающий к работе вручает первый экземпляр наряда-допуска производителю работ, а второй экземпляр наряда-допуска хранится вместе с нарядами, действующими на этот момент времени",
        ],
        correctAnswers: [
          "Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ",
          "После окончания работы наряд-допуск остается у производителя (руководителя) работ",
        ],
      },
      {
        code: "20308010",
        text: "В течение какого времени хранятся закрытые (подписанные) наряды-допуски на работы повышенной опасности?",
        answers: [
          "В течение трех месяцев",
          "В течение шести месяцев",
          "В течение одного месяца",
          "В течение года",
        ],
        correctAnswers: ["В течение одного месяца"],
      },
      {
        code: "20308011",
        text: "Какое количество производителей (руководителей) работ назначаются на все время действия наряда-допуска на работы повышенной опасности при производстве работ в несколько смен?",
        answers: [
          "Количество производителей (руководителей) работ определяет выдающий наряд-допуск",
          "Один производитель (руководитель) работ",
          "Несколько производителей (руководителей) работ в соответствии с количеством смен",
        ],
        correctAnswers: [
          "Несколько производителей (руководителей) работ в соответствии с количеством смен",
        ],
      },
      {
        code: "20308012",
        text: "Какие действия должны быть выполнены при утрате наряда-допуска на работы повышенной опасности?",
        answers: [
          "Действия регламентируются выдающим наряд-допуск",
          "Работы должны быть продолжены. Оформление нового наряда-допуска не требуется",
          "Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново",
          "Работы должны быть прекращены. Для продолжения работ должна быть внесена запись в журнал регистрации нарядов-допусков на работы повышенной опасности",
        ],
        correctAnswers: [
          "Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново",
        ],
      },
      {
        code: "20308013",
        text: "В каких из перечисленных случаев допускается продолжать работы, проводимые по наряду-допуску, на опасных производственных объектах?",
        answers: [
          "Если выявлено нарушение условий отключения технических устройств",
          "Если изменился состав бригады",
          "Если обнаружено несоответствие фактического состояния производства работ требованиям безопасности и охраны труда",
          "Если характер и объемы работ изменены в такой степени, что требуется изменение схемы отключения ТУ и порядка выполнения работ",
        ],
        correctAnswers: ["Если изменился состав бригады"],
      },
      {
        code: "20308014",
        text: "Что должно быть разработано для обеспечения безопасности при освоении новых производств, технологических процессов и технических устройств?",
        answers: [
          "Инструкции по эксплуатации",
          "Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования",
          "Инструкция по проведению пусконаладочных работ",
          "Проект производства работ",
        ],
        correctAnswers: [
          "Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования",
        ],
      },
      {
        code: "20308015",
        text: "Что не допускается к загрузке в агрегаты, содержащие расплавленный металл или шлак?",
        answers: [
          "Загрузка шихты, не прошедшей пиротехнического контроля",
          "Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции",
          "Загрузка пакетированного металлолома",
          "Допускается все перечисленное",
        ],
        correctAnswers: [
          "Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции",
        ],
      },
      {
        code: "20308016",
        text: "При каких условиях запрещается эксплуатация плавильных агрегатов?",
        answers: [
          "При незначительном падении давления воды в системе водоохлаждения",
          "При разгерметизации системы водяного охлаждения этих агрегатов",
          "При незначительном повышении давления воды в системе охлаждения",
          "При незначительном повышении температуры воды в системе водоохлаждения",
        ],
        correctAnswers: [
          "При разгерметизации системы водяного охлаждения этих агрегатов",
        ],
      },
      {
        code: "20308017",
        text: "В какой зоне не допускается нахождение людей?",
        answers: [
          "В зоне работы электромостовых кранов",
          "В зоне погрузки грейферными или магнитными кранами",
          "В зоне работы кран-балок",
          "На расстоянии 20 м от зоны работы грейферного крана в случае, если кран работает внутри производственного здания",
          "Во всех перечисленных зонах",
        ],
        correctAnswers: ["В зоне погрузки грейферными или магнитными кранами"],
      },
      {
        code: "20308018",
        text: "Какие требования установлены к процессу сливания расплава металла из ковшей и миксеров? Выберите правильный вариант ответа.",
        answers: [
          "Пол рабочей площадки в местах установки ковшей должен быть сухим",
          "Все перечисленные требования",
          "Сливание расплава металла из ковшей и миксеров должно быть механизировано",
          "Ось сливного отверстия ковша, установленного под слив, должна совпадать с осью заливочного ковша",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20308019",
        text: "После чего разрешается производить слив расплава в ковши, вышедшие из ремонта?",
        answers: [
          "Только после тщательного их просушивания и разогрева",
          "После выдержки в течение суток при температуре 40 °С",
          "После выдержки в течение одной смены при температуре выше 40 °С",
          "Только после смазки смазочным материалом",
        ],
        correctAnswers: [
          "Только после тщательного их просушивания и разогрева",
        ],
      },
      {
        code: "20308020",
        text: "При наличии каких дефектов ковшей их эксплуатация не допускается?",
        answers: [
          "Только потеря ковшом формы вследствие деформации",
          "Только наличие качки цапф в теле ковша",
          "Все перечисленное",
          "Только наличие раковин, трещин в стенках и в местах крепления цапф",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20308021",
        text: "Какие требования установлены к месту расстановки ковшей, предназначенных для расплава металла?",
        answers: [
          "Должны расставляться на свободных площадках пролета цеха",
          "Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства",
          "Должны расставляться на горизонтальных площадках в любом месте пролета цеха",
        ],
        correctAnswers: [
          "Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства",
        ],
      },
      {
        code: "20308022",
        text: "Какие требования установлены к загружаемым в печи руде, ферросплавам и другим материалам?",
        answers: [
          "Должны быть выдержаны в помещении не менее смены",
          "Должны быть выдержаны в помещении не менее суток",
          "Должны быть предварительно просушены или прокалены",
        ],
        correctAnswers: ["Должны быть предварительно просушены или прокалены"],
      },
      {
        code: "20308023",
        text: "Какие требования установлены к состоянию вакуум-ковшей, тиглей, коробов, кристаллизаторов, изложниц и других емкостей для расплава перед заливкой в них расплава в электролитическом производстве магния?",
        answers: [
          "Должны быть очищены от мусора",
          "Должны быть выдержаны в помещении не менее суток",
          "Должны быть выдержаны в помещении не менее одной смены",
          "Должны быть обезжирены",
        ],
        correctAnswers: ["Должны быть очищены от мусора"],
      },
      {
        code: "20308024",
        text: "Какие требования установлены к фурмам для продувки жидкого металла газами?",
        answers: [
          "Перед установкой фурмы должны храниться в специальном помещении не менее 5 суток",
          "Перед установкой фурмы должны храниться в специальном помещении не менее 2 суток",
          "Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями",
        ],
        correctAnswers: [
          "Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями",
        ],
      },
      {
        code: "20308025",
        text: "Какие требования установлены к инструментам, приспособлениям и оснастке, используемым для ведения технологии или обслуживания оборудования?",
        answers: [
          "Должны находиться в исправном состоянии",
          "Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними",
          "Должны быть предназначены для выполнения любых работ",
        ],
        correctAnswers: [
          "Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними",
        ],
      },
      {
        code: "20308026",
        text: "Какие требования установлены к инструментам и приспособлениям, используемым во взрывопожароопасных зонах и помещениях?",
        answers: [
          "Не должны давать искры при работе с ними",
          "Должны быть изготовлены из стали",
          "Должны быть изготовлены из чугуна",
        ],
        correctAnswers: ["Не должны давать искры при работе с ними"],
      },
      {
        code: "20308027",
        text: "От чего должны быть защищены составные части производственного оборудования, в том числе энергетические трубопроводы, рукава подачи природного газа, кислорода, мазута, воздуха, масла, воды, троллеи для питания электрического оборудования тележек, перевозящих расплавы металлов, и электрические кабели?",
        answers: [
          "Только от возможного попадания на них расплава",
          "Только от тепловых воздействий",
          "От всего перечисленного",
          "Только от механических воздействий",
        ],
        correctAnswers: ["От всего перечисленного"],
      },
      {
        code: "20308028",
        text: "Какие требования установлены к установке расходных баков с мазутом?",
        answers: [
          "Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением",
          "Должны быть установлены на расстоянии не менее 15 м от печей",
          "Должны быть установлены на расстоянии не менее 10 м от печей и должны быть защищены паровой завесой",
        ],
        correctAnswers: [
          "Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением",
        ],
      },
      {
        code: "20308029",
        text: "Чему подлежат производственные емкости (ковши, шлаковые чаши, совки, кюбели, тара, корзины) после изготовления или ремонта?",
        answers: [
          "Освидетельствованию",
          "Проверке состояния",
          "Механическим испытаниям",
          "Чистке",
        ],
        correctAnswers: ["Проверке состояния"],
      },
      {
        code: "20308030",
        text: "Какое требование к устройству внутренних производственных помещений, находящихся на производственной площадке, включающей технологию с расплавами металлов, имеющей опасность аварии или травмирования работников, указано неверно?",
        answers: [
          "Помещения пультов управления должны иметь вытяжную вентиляцию",
          "Окна пультов управления, в которые возможно попадание брызг расплава, должны оборудоваться в соответствии с проектом",
          "Помещения должны иметь не менее 2 выходов, расположенных с учетом аварийной эвакуации работников",
          "Помещения должны иметь защиту от воздействия теплового излучения",
          "Двери помещения должны открываться наружу и не иметь внутренних запоров",
        ],
        correctAnswers: [
          "Помещения пультов управления должны иметь вытяжную вентиляцию",
        ],
      },
      {
        code: "20308031",
        text: "Как должны быть расположены пульты управления агрегатами?",
        answers: [
          "В непосредственной близости от агрегатов",
          "На расстоянии не менее 5 м от агрегатов",
          "В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке",
        ],
        correctAnswers: [
          "В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке",
        ],
      },
      {
        code: "20308032",
        text: "Что должны иметь закрома и ямы?",
        answers: [
          "Должны иметь вдоль периметра пешеходные проходы шириной 0,8 м",
          "Со всех сторон должны иметь ограждение",
          "Должны иметь вдоль периметра пешеходные проходы шириной 1,0 м",
        ],
        correctAnswers: ["Со всех сторон должны иметь ограждение"],
      },
      {
        code: "20308033",
        text: "Как должна устраняться пробуксовка ленты конвейера?",
        answers: [
          "Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты",
          "Должна устраняться при помощи ручной регулировки",
          "Должна устраняться увеличением трения между тянущим барабаном и лентой с использованием специальных материалов",
        ],
        correctAnswers: [
          "Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты",
        ],
      },
      {
        code: "20308034",
        text: "Что должно быть исключено в коммуникациях системы транспортирования взрывопожароопасных веществ и материалов?",
        answers: [
          "Образование взрывоопасной среды",
          "Попадание воздушной среды",
          "Возможность разгерметизации системы",
        ],
        correctAnswers: ["Образование взрывоопасной среды"],
      },
      {
        code: "20308035",
        text: "На что должна быть проверена система пневмотранспорта перед вводом в эксплуатацию?",
        answers: [
          "На плотность под рабочим давлением",
          "На прочность под рабочим давлением",
          "На целостность визуальным осмотром",
        ],
        correctAnswers: ["На плотность под рабочим давлением"],
      },
      {
        code: "20308036",
        text: "Чем должны быть оборудованы шлаковозы?",
        answers: [
          "Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением",
          "Механизмами кантования (поворота) чаши с ручным пневматическим приводом и автоматическим управлением",
          "Механизмами кантования (поворота) чаши с ручным гидравлическим приводом",
        ],
        correctAnswers: [
          "Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением",
        ],
      },
      {
        code: "20308037",
        text: "Что должно иметься на шлаковом отвале?",
        answers: [
          "Двусторонняя громкоговорящая связь с диспетчерской службой",
          "Телефонная или радиосвязь с диспетчерской службой производства",
          "Мобильная связь с диспетчерской службой предприятия",
        ],
        correctAnswers: [
          "Телефонная или радиосвязь с диспетчерской службой производства",
        ],
      },
      {
        code: "20308038",
        text: "Какие шпалы должны применяться на железнодорожных путях шлакового отвала?",
        answers: [
          "Деревянные шпалы",
          "Деревянные шпалы, пропитанные креозотом",
          "Огнестойкие шпалы",
        ],
        correctAnswers: ["Огнестойкие шпалы"],
      },
      {
        code: "20308039",
        text: "Какие требования установлены к кабине завалочной машины?",
        answers: [
          "Конструкция завалочной машины должна обеспечивать ее устойчивость при выполнении операций",
          "Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака",
          "Должна быть удобной в эксплуатации",
        ],
        correctAnswers: [
          "Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака",
        ],
      },
      {
        code: "20308040",
        text: "Чем должны быть оборудованы места пересечения железнодорожных путей для подачи составов ковшей с жидким чугуном с путями для подачи шихтовых материалов?",
        answers: [
          "Должны быть оборудованы светофорами",
          "Должны быть оборудованы постами со стрелочниками",
          "Должны быть оборудованы автоматической сигнализацией",
        ],
        correctAnswers: [
          "Должны быть оборудованы автоматической сигнализацией",
        ],
      },
      {
        code: "20308041",
        text: "Какие требования установлены к системам освещения во взрывоопасных помещениях?",
        answers: [
          "Должны предусматриваться системы освещения во взрывобезопасном исполнении",
          "Должны предусматриваться системы освещения, работающие от напряжения 12 В",
          "Должны предусматриваться системы освещения, работающие от напряжения 24 В",
          "Должны предусматриваться системы аварийного освещения",
        ],
        correctAnswers: [
          "Должны предусматриваться системы освещения во взрывобезопасном исполнении",
        ],
      },
      {
        code: "20308042",
        text: "Что должна обеспечить система светозвуковой сигнализации?",
        answers: [
          "Оповещение эксплуатационного персонала о приближении технологического транспорта",
          "Оповещение эксплуатационного персонала о начале и окончании смены",
          "Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств",
          "Оповещение о приостановке технологического процесса",
        ],
        correctAnswers: [
          "Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств",
        ],
      },
      {
        code: "20308043",
        text: "Какие требования установлены к расположению КИПиА?",
        answers: [
          "Приборы должны устанавливаться на расстоянии не менее 5 м от оборудования",
          "Приборы должны устанавливаться в непосредственной близости к оборудованию",
          "Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования",
          "Не регламентируется",
        ],
        correctAnswers: [
          "Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования",
        ],
      },
      {
        code: "20308044",
        text: "Какие требования установлены к проверке и испытанию приборов во взрывопожароопасных и пожароопасных помещениях?",
        answers: [
          "Проверка должна осуществляться только в специально выделенном для этого помещении",
          "Проверка может производиться непосредственно во взрывопожароопасных и пожароопасных помещениях",
          "Должны производиться в условиях, исключающих искрообразование",
          "Должны производиться в условиях, исключающих повышенную влажность",
        ],
        correctAnswers: [
          "Должны производиться в условиях, исключающих искрообразование",
        ],
      },
      {
        code: "20308045",
        text: "Какие требования предъявляются к устройству и эксплуатации печей для обжига шихтовых материалов и концентратов?",
        answers: [
          "Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 10%",
          "Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 5%",
          "Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции",
          "Эксплуатация возможна в любом случае",
        ],
        correctAnswers: [
          "Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции",
        ],
      },
      {
        code: "20308046",
        text: "Какие требования установлены к хранению стержней, шаров, футеровки, запасных деталей и приспособлений?",
        answers: [
          "Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре",
          "Должно быть предусмотрено на свободных площадках, обеспечивающих свободный проход",
          "Должно быть предусмотрено в специальном помещении",
          "Должно быть предусмотрено в специальном помещении за пределами ОПО",
        ],
        correctAnswers: [
          "Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре",
        ],
      },
      {
        code: "20308047",
        text: "Что должно быть предусмотрено при проектировании конвейеров для подачи материалов? Выберите правильный вариант ответа.",
        answers: [
          "Только предохраняющие устройства, отключающие приводы при перегрузке",
          "Все перечисленное",
          "Только ограждения приводных, натяжных и отклоняющих барабанов, натяжных устройств, канатов и блоков натяжных устройств, ременных, червячных, муфтовых и других передач",
          "Только исключение падения транспортируемых материалов",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20308048",
        text: "С какой периодичностью должны проверяться на работоспособность вентиляционные системы и состояние ванн травления?",
        answers: ["Ежедневно", "1 раз в квартал", "Еженедельно", "Ежемесячно"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "20308049",
        text: "Какие требования к производственным помещениям, оборудованию участков приготовления травильных растворов и травления металла указаны неверно?",
        answers: [
          "Периодичность проведения уборки помещений должна устанавливаться в зависимости от количества работников в этой зоне",
          "Все перечисленные",
          "После работы у ванн травления с химически опасными веществами, используемыми в виде добавок для технологических растворов, вещества должны храниться в канистрах в рабочей зоне",
          "Допускается уборка, вызывающая распыление вредных веществ",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20308050",
        text: "Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей?",
        answers: [
          "Системами регулирования заданного соотношения топлива, воздуха и водяного пара",
          "Падением давления нагреваемого продукта на выходе из печи ниже регламентированного значения",
          "Повышением температуры над перевальной стенкой",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20308051",
        text: "Какие из перечисленных ограждающих конструкций не относятся к легкосбрасываемым?",
        answers: [
          "Окна с оконными переплетами, заполненными обычным оконным стеклом толщиной 3, 4 и 5 мм, площадью не менее соответственно 0,8, 1 и 1,5 м²",
          "Конструкции из асбоцементных, алюминиевых и стальных листов с легким утеплением",
          "Фонарные переплеты",
          "Противопожарные двери",
        ],
        correctAnswers: ["Противопожарные двери"],
      },
      {
        code: "20308052",
        text: "Какое минимальное остаточное давление должны иметь баллоны, подготовленные для наполнения водородом?",
        answers: ["0,05 МПа", "0,15 МПа", "0,35 МПа", "0,01 МПа"],
        correctAnswers: ["0,05 МПа"],
      },
      {
        code: "20308053",
        text: "На какой высоте от пола должны быть расположены окна в помещениях отделений наполненных баллонов, наполнительных и разрядных рамп?",
        answers: [
          "Не менее 1,5 м",
          "Не менее 1,3 м",
          "Не менее 1,2 м",
          "Не менее 1,1 м",
        ],
        correctAnswers: ["Не менее 1,5 м"],
      },
      {
        code: "20308054",
        text: "Какой должна быть ширина свободного прохода между щитом управления вентилями и ближайшими стенами наполнительной?",
        answers: [
          "Не менее 0,8 м",
          "Не менее 0,7 м",
          "Не менее 0,6 м",
          "Не менее 0,75 м",
        ],
        correctAnswers: ["Не менее 0,8 м"],
      },
      {
        code: "20308055",
        text: "Стальными листами какой толщины должны отделяться от рабочей зоны баллоны с водородом при их горизонтальном расположении и размещении внутри помещения?",
        answers: [
          "Не менее 16 мм",
          "Не менее 10 мм",
          "Не менее 26 мм",
          "Не менее 20 мм",
        ],
        correctAnswers: ["Не менее 16 мм"],
      },
      {
        code: "20308056",
        text: "Какие требования к помещению операторной указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Стены, отделяющие помещения операторной от помещений с взрывоопасными зонами любого класса, должны быть покрыты огнеупорным материалом",
          "Полы в операторной должны быть покрыты огнеупорным составом",
          "Не допускается размещать помещение операторной над помещениями с взрывоопасными зонами любого класса",
          "Не допускается располагать операторные на втором этаже и выше",
        ],
        correctAnswers: [
          "Не допускается размещать помещение операторной над помещениями с взрывоопасными зонами любого класса",
        ],
      },
      {
        code: "20308057",
        text: "Какое минимальное количество выходов допускается в помещении операторной при площади свыше 150 м²?",
        answers: ["1", "2", "3", "4"],
        correctAnswers: ["2"],
      },
      {
        code: "20308058",
        text: "Какое минимальное количество выходов допускается в помещении операторной, расположенном на первом этаже?",
        answers: ["1", "2", "3", "4"],
        correctAnswers: ["1"],
      },
      {
        code: "20308059",
        text: "Где допускается размещение операторных помещений?",
        answers: [
          "В отдельных щитовых помещениях (встроенных или пристроенных), смежных с помещениями с взрывоопасными зонами и в отдельно стоящих зданиях",
          "В производственных зданиях",
          "Над помещениями с взрывоопасными зонами любого класса",
          "Под приточными вентиляционными камерами",
        ],
        correctAnswers: [
          "В отдельных щитовых помещениях (встроенных или пристроенных), смежных с помещениями с взрывоопасными зонами и в отдельно стоящих зданиях",
        ],
      },
      {
        code: "20308060",
        text: "Чему должны соответствовать параметры процесса эксплуатации и ремонта оборудования?",
        answers: [
          "Только требованиям установленного технологического регламента",
          "Только рекомендациям завода-изготовителя",
          "Только паспортным данным оборудования",
          "Всему перечисленному",
        ],
        correctAnswers: ["Всему перечисленному"],
      },
      {
        code: "20308061",
        text: "Какие производственные помещения должны быть оборудованы системами связи и сигнализации? Выберите правильный вариант ответа.",
        answers: [
          "Все производственные помещения, связанные с получением, хранением и применением водорода",
          "Все производственные помещения, связанные с переработкой ртути",
          "Все помещения, связанные с применением аргона",
          "Все помещения, связанные с хранением шлака",
        ],
        correctAnswers: [
          "Все производственные помещения, связанные с получением, хранением и применением водорода",
        ],
      },
      {
        code: "20308062",
        text: "Какая допускается максимальная погрешность (точность измерения) газоанализаторов водорода?",
        answers: [
          "+/- 0,2 % об.",
          "+/- 0,5 % об.",
          "+/- 0,7 % об.",
          "+/- 1,0 % об.",
        ],
        correctAnswers: ["+/- 0,2 % об."],
      },
      {
        code: "20308063",
        text: "В каких помещениях предупреждающий и аварийный сигналы должны подаваться у входа вне помещения?",
        answers: [
          "В помещениях с постоянным пребыванием обслуживающего персонала (операторной, пультовой)",
          "В помещениях для личных гигиенических процедур",
          "В коммуникационных помещениях",
          "В технико-эксплуатационных помещениях",
        ],
        correctAnswers: [
          "В помещениях с постоянным пребыванием обслуживающего персонала (операторной, пультовой)",
        ],
      },
      {
        code: "20308064",
        text: "Какие требования к пневматическим КИПиА указаны неверно?",
        answers: [
          "Должны предусматриваться специальные установки сжатого воздуха",
          "Должны предусматриваться отдельные сети сжатого воздуха",
          "Должны иметь буферные емкости",
          "Должны быть связаны с трубопроводами на технологические нужды",
        ],
        correctAnswers: [
          "Должны быть связаны с трубопроводами на технологические нужды",
        ],
      },
      {
        code: "20308065",
        text: "Чем допускается тушить пламя при возгорании водорода? Выберите два варианта ответа.",
        answers: ["Песком", "Кошмой", "Водой", "Огнетушителями"],
        correctAnswers: ["Песком", "Кошмой"],
      },
      {
        code: "20308066",
        text: "Что должно устраиваться над постановочными путями чугуновозных и шлаковозных ковшей в пределах литейного двора в целях исключения попадания атмосферных осадков в ковши?",
        answers: [
          "Специальные навесы на высоких опорах",
          "Крыши или специальные навесы",
          "Крыши из полимерных материалов",
          "Обслуживающие площадки",
        ],
        correctAnswers: ["Крыши или специальные навесы"],
      },
      {
        code: "20308067",
        text: "Какие требования установлены к выгрузке шихтовых материалов на рудном дворе?",
        answers: [
          "Выгрузка должна быть механизирована",
          "Выгрузка должна быть с помощью средств малой механизации",
          "Выгрузка может быть организована с применением ручного труда",
          "Выгрузка может быть организована только в дневное время",
        ],
        correctAnswers: ["Выгрузка должна быть механизирована"],
      },
      {
        code: "20308068",
        text: "Какие требования предъявляются к предохранительным решеткам бункера?",
        answers: [
          "Должны быть оборудованы предохранительными решетками с ячейками размером не более 400 х 400 мм",
          "Должны быть оборудованы предохранительными решетками с ячейками размером не более 300 х 400 мм",
          "Должны быть оборудованы предохранительными решетками с ячейками размером не более 300 х 300 мм",
        ],
        correctAnswers: [
          "Должны быть оборудованы предохранительными решетками с ячейками размером не более 300 х 300 мм",
        ],
      },
      {
        code: "20308069",
        text: "Что должно быть установлено в здании подъемника?",
        answers: [
          "Прямая телефонная (радио) связь с колошниковой площадкой и скиповой ямой",
          "Телефонная связь с диспетчерской службой",
          "Радиосвязь с колошниковой площадкой",
          "Радиосвязь с операторной",
        ],
        correctAnswers: [
          "Прямая телефонная (радио) связь с колошниковой площадкой и скиповой ямой",
        ],
      },
      {
        code: "20308070",
        text: "Какие требования установлены к подвешиванию скипов?",
        answers: [
          "Скипы должны подвешиваться не менее чем на четырех канатах, имеющих пятикратный запас прочности каждый",
          "Скипы должны подвешиваться не менее чем на двух канатах, имеющих десятикратный запас прочности каждый",
          "Скипы должны подвешиваться не менее чем на двух канатах, имеющих шестикратный запас прочности каждый",
        ],
        correctAnswers: [
          "Скипы должны подвешиваться не менее чем на двух канатах, имеющих шестикратный запас прочности каждый",
        ],
      },
      {
        code: "20308071",
        text: "Какие требования установлены к конусным и бесконусным засыпным аппаратам?",
        answers: [
          "Засыпные аппараты должны быть надежными и рассчитаны на полуторакратное рабочее давление газа под колошником",
          "Засыпные аппараты должны быть надежными и рассчитаны на двукратное рабочее давление газа под колошником",
          "Засыпные аппараты должны быть герметичными и рассчитаны на рабочее давление газа под колошником",
        ],
        correctAnswers: [
          "Засыпные аппараты должны быть герметичными и рассчитаны на рабочее давление газа под колошником",
        ],
      },
      {
        code: "20308072",
        text: "Какой запас прочности должны иметь канаты для подвески и подъема контргрузов?",
        answers: [
          "Десятикратный",
          "Пятикратный",
          "Восьмикратный",
          "Трехкратный",
        ],
        correctAnswers: ["Восьмикратный"],
      },
      {
        code: "20308073",
        text: "Какое требование установлено к устройству выходных отверстий продувочных свечей на доменных печах?",
        answers: [
          "Должно быть выше верхних площадок колошника не менее чем на 2 м",
          "Должно быть выше верхних площадок колошника не менее чем на 4 м",
          "Должно быть выше верхних площадок колошника не менее чем на 3 м",
        ],
        correctAnswers: [
          "Должно быть выше верхних площадок колошника не менее чем на 4 м",
        ],
      },
      {
        code: "20308074",
        text: "Какие требования установлены к оснащению колошниковой площадки?",
        answers: [
          "Должна быть освещена в вечернее и ночное время и иметь аварийное освещение и сплошное ограждение высотой не менее 1,0 м",
          "Должна быть освещена в вечернее и ночное время и ограждена перилами высотой не менее 1,1 м со сплошной зашивкой стальными листами",
        ],
        correctAnswers: [
          "Должна быть освещена в вечернее и ночное время и ограждена перилами высотой не менее 1,1 м со сплошной зашивкой стальными листами",
        ],
      },
      {
        code: "20308075",
        text: "Как часто должны производиться проверки приборов измерения уровня засыпи печи?",
        answers: [
          "Не реже двух раз в месяц",
          "Не реже одного раза в месяц",
          "Не реже одного раза в 20 суток",
        ],
        correctAnswers: ["Не реже двух раз в месяц"],
      },
      {
        code: "20308076",
        text: "Какая допускается предельная температура колошникового газа в случае задержки загрузки печи?",
        answers: [
          "Не должна превышать 500 °С",
          "Не должна превышать 700 °С",
          "Не должна превышать 800 °С",
          "Не должна превышать 900 °С",
        ],
        correctAnswers: ["Не должна превышать 500 °С"],
      },
      {
        code: "20308077",
        text: "Что должны обеспечивать конструкция и установка элементов фурменного прибора?",
        answers: [
          "Должны обеспечивать надежность",
          "Должны обеспечивать герметичность",
          "Должны обеспечивать плотность",
          "Должны обеспечивать доступность",
        ],
        correctAnswers: ["Должны обеспечивать герметичность"],
      },
      {
        code: "20308078",
        text: "Что должны обеспечивать системы охлаждения горна и лещади доменной печи?",
        answers: [
          "Должны обеспечивать перепад температуры не более 3 °С",
          "Должны обеспечивать перепад температуры не более 4 °С",
          "Должны обеспечивать перепад температуры не более 5 °С",
        ],
        correctAnswers: [
          "Должны обеспечивать перепад температуры не более 3 °С",
        ],
      },
      {
        code: "20308079",
        text: "Из каких материалов должны быть выполнены здания литейных дворов и поддоменников?",
        answers: [
          "Из обычных строительных материалов",
          "Из огнестойких материалов",
          "Из плит полипропилена",
          "Из газобетонных блоков",
        ],
        correctAnswers: ["Из огнестойких материалов"],
      },
      {
        code: "20308080",
        text: "Чем должны быть защищены опорные колонны печи здания литейного двора и поддоменника от возможного воздействия чугуна и шлака?",
        answers: [
          "Должны быть защищены огнеупорным материалом",
          "Должны быть защищены сетчатыми ограждениями",
          "Должны быть защищены пластиковыми плитами",
        ],
        correctAnswers: ["Должны быть защищены огнеупорным материалом"],
      },
      {
        code: "20308081",
        text: "С какой периодичностью должны производиться замеры температуры кожухов воздухонагревателя?",
        answers: [
          "Систематически (не реже одного раза в месяц)",
          "Систематически (не реже двух раз в месяц)",
          "Систематически (не реже одного раза в неделю)",
          "Систематически (не реже одного раза в сутки)",
        ],
        correctAnswers: ["Систематически (не реже одного раза в месяц)"],
      },
      {
        code: "20308082",
        text: "Как часто комиссионно должно проверяться техническое состояние воздухонагревателей и их арматура?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в 24 часа",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20308083",
        text: "С какой периодичностью должны производиться замеры аэродинамического сопротивления насадки воздухонагревателей?",
        answers: ["Каждый квартал", "Ежегодно", "Два раза в год", "Ежемесячно"],
        correctAnswers: ["Ежегодно"],
      },
      {
        code: "20308084",
        text: "Какие требования установлены к конструкции клапанов, перекрывающих поступление газа к горелкам?",
        answers: [
          "Конструкция должна обеспечивать класс герметичности затвора А",
          "Конструкция должна обеспечивать перекрытие газопровода в течение 12 секунд",
          "Конструкция должна обеспечивать плотное перекрытие газопровода",
          "Конструкция должна обеспечивать класс герметичности затвора В",
        ],
        correctAnswers: [
          "Конструкция должна обеспечивать плотное перекрытие газопровода",
        ],
      },
      {
        code: "20308085",
        text: "Какие требования установлены к диаметру лазов пылеуловителей?",
        answers: [
          "Диаметр не менее 600 мм",
          "Диаметр не менее 500 мм",
          "Диаметр не менее 400 мм",
        ],
        correctAnswers: ["Диаметр не менее 600 мм"],
      },
      {
        code: "20308086",
        text: "После чего должны производиться работы по ремонту пылевыпускного клапана?",
        answers: [
          "После перекрытия запорной арматуры",
          "После установки листовой заглушки над пылевыпускным клапаном с соблюдением мер безопасности",
          "После остановки работы печи",
          "После разрешения ответственного за проведение работ",
        ],
        correctAnswers: [
          "После установки листовой заглушки над пылевыпускным клапаном с соблюдением мер безопасности",
        ],
      },
      {
        code: "20308087",
        text: "Каким инструментом выполняется взятие проб жидкого чугуна на химический анализ?",
        answers: [
          "Инструментом, покрытым огнеупорным материалом",
          "Инструментом, изготовленным из огнестойких материалов",
          "Просушенным и подогретым инструментом",
          "Искробезопасным инструментом",
        ],
        correctAnswers: ["Просушенным и подогретым инструментом"],
      },
      {
        code: "20308088",
        text: "Каков предельно допустимый износ цапф конвертера во всех измерениях в случае применения подшипников скольжения?",
        answers: [
          "Не должен превышать 12% их первоначальных размеров",
          "Не должен превышать 10% их первоначальных размеров",
          "Не должен превышать 15% их первоначальных размеров",
        ],
        correctAnswers: ["Не должен превышать 10% их первоначальных размеров"],
      },
      {
        code: "20308089",
        text: "При каких условиях осуществляется пользование механизмами передвижения ковшей?",
        answers: [
          "Только при исправной световой сигнализации",
          "Только при исправной звуковой сигнализации",
          "Только при исправной звуковой и световой сигнализации",
          "Только при исправном аварийном освещении",
        ],
        correctAnswers: [
          "Только при исправной звуковой и световой сигнализации",
        ],
      },
      {
        code: "20308090",
        text: "На каком расстоянии не допускается производство работ на путях от стоящих под наливом ковшей?",
        answers: [
          "На расстоянии ближе 15 м",
          "На расстоянии ближе 10 м",
          "На расстоянии ближе 12 м",
        ],
        correctAnswers: ["На расстоянии ближе 15 м"],
      },
      {
        code: "20308091",
        text: "На каком расстоянии от кантуемых ковшей должен размещаться пульт управления на шлаковом отвале?",
        answers: [
          "На расстоянии не менее 10 м",
          "На расстоянии не менее 8 м",
          "На расстоянии не менее 5 м",
        ],
        correctAnswers: ["На расстоянии не менее 10 м"],
      },
      {
        code: "20308092",
        text: "Чем должны быть перекрыты приемные бункера грануляционных установок?",
        answers: [
          "Предохранительными решетками с ячейками не более 300 х 300 мм",
          "Предохранительными решетками с ячейками не более 250 х 250 мм",
          "Предохранительными решетками с ячейками не более 200 х 200 мм",
        ],
        correctAnswers: [
          "Предохранительными решетками с ячейками не более 200 х 200 мм",
        ],
      },
      {
        code: "20308093",
        text: "Где должна производиться грануляция шлака?",
        answers: [
          "В специальных герметизированных установках или в грануляционных бассейнах за пределами цеха",
          "В специальных грануляционных бассейнах, установленных на территории цеха",
          "В специальных грануляционных бассейнах, установленных на шлаковом отвале",
          "В специальных емкостях и резервуарах на территории цеха",
        ],
        correctAnswers: [
          "В специальных герметизированных установках или в грануляционных бассейнах за пределами цеха",
        ],
      },
      {
        code: "20308094",
        text: "Какой запас прочности должны иметь цапфы ковшей, предназначенных для расплава металла?",
        answers: [
          "Не менее пятикратного запаса прочности",
          "Не менее восьмикратного запаса прочности",
          "Не менее шестикратного запаса прочности",
          "Не менее трехкратного запаса прочности",
        ],
        correctAnswers: ["Не менее восьмикратного запаса прочности"],
      },
      {
        code: "20308095",
        text: "Чем должны быть ограждены грануляционные бассейны?",
        answers: [
          "Сплошным ограждением высотой не менее 0,9 м",
          "Перилами высотой не менее 1,1 м",
          "Стальными сетчатыми ограждениями высотой не менее 0,8 м",
          "Забором из металлического штакетника высотой не менее 2 м",
        ],
        correctAnswers: ["Перилами высотой не менее 1,1 м"],
      },
      {
        code: "20308096",
        text: "Как часто цапфы ковшей должны проверяться методом неразрушающего контроля?",
        answers: [
          "Не реже одного раза в три месяца",
          "Не реже одного раза в шесть месяцев",
          "Не реже одного раза в год",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20308097",
        text: "Чем должны быть оснащены доменные печи?",
        answers: [
          "Системой дистанционного контроля",
          "Автоматизированной системой контроля, управления и диагностики технологического процесса",
          "Системой диагностики технологического процесса",
          "Системой аварийной остановки",
        ],
        correctAnswers: [
          "Автоматизированной системой контроля, управления и диагностики технологического процесса",
        ],
      },
      {
        code: "20308098",
        text: "Как должна производиться установка мульд на стеллажах?",
        answers: [
          "С уклоном в сторону завалочной машины",
          "Без свесов и перекосов",
          "По центру стеллажа",
          "С уклоном в противоположную сторону от завалочной машины",
        ],
        correctAnswers: ["Без свесов и перекосов"],
      },
      {
        code: "20308099",
        text: "Как должна производиться заливка чугуна в миксер?",
        answers: [
          "В центр окна равномерной струей с минимальной высоты",
          "С помощью специальной воронки",
          "Медленно в центр окна",
          "Порционно, через равные промежутки времени",
        ],
        correctAnswers: [
          "В центр окна равномерной струей с минимальной высоты",
        ],
      },
      {
        code: "20308100",
        text: "Как и кем должны проверяться состояние и исправность миксера?",
        answers: [
          "Должны ежесуточно проверяться руководителем подразделения",
          "Должны проверяться два раза в смену старшим по смене",
          "Должны ежесменно проверяться миксеровым с записью результатов осмотра в журнале",
        ],
        correctAnswers: [
          "Должны ежесменно проверяться миксеровым с записью результатов осмотра в журнале",
        ],
      },
      {
        code: "20308101",
        text: "В какой сталеплавильный агрегат завалка металлической стружки не допускается?",
        answers: [
          "В двухванный",
          "В мартеновскую печь",
          "В электродуговую печь",
          "В конвертер",
        ],
        correctAnswers: ["В двухванный"],
      },
      {
        code: "20308102",
        text: "Что должно быть выполнено во время завалки материалов и подвалки шихты?",
        answers: [
          "Все работы на печи должны быть приостановлены, а работающие удалены в безопасное место",
          "Печь должна быть остановлена, а работающие должны находиться в укрытиях",
          "Должны быть реализованы мероприятия по безопасности работников",
        ],
        correctAnswers: [
          "Должны быть реализованы мероприятия по безопасности работников",
        ],
      },
      {
        code: "20308103",
        text: "В каких случаях не допускается завалка лома в конвертер?",
        answers: [
          "При наличии в нем остатков жидкого металла",
          "При наличии в нем жидкого шлака",
          "При наличии в нем твердой корки шлака",
        ],
        correctAnswers: ["При наличии в нем жидкого шлака"],
      },
      {
        code: "20308104",
        text: "После выполнения каких мероприятий должна производиться заливка чугуна в печь?",
        answers: [
          "Только после полного закрытия сталевыпускного отверстия, установки сталевыпускного желоба и шлаковых ковшей",
          "После полного закрытия сталевыпускного отверстия",
          "После отключения горелок",
          "После полной остановки печи",
        ],
        correctAnswers: [
          "Только после полного закрытия сталевыпускного отверстия, установки сталевыпускного желоба и шлаковых ковшей",
        ],
      },
      {
        code: "20308105",
        text: "После выполнения каких мероприятий разрешается производить работы на своде мартеновской печи?",
        answers: [
          "Только после полной остановки печи",
          "Только после остановки печи на горячий ремонт",
          "Только после выпуска плавки и до заливки чугуна в печь",
          "Только после остановки печи на горячий ремонт и оформления наряда-допуска",
        ],
        correctAnswers: [
          "Только после выпуска плавки и до заливки чугуна в печь",
        ],
      },
      {
        code: "20308106",
        text: "С какой периодичностью должна проверяться герметичность сводов и стен регенераторов, работающих с подогревом газа в них?",
        answers: [
          "Ежесменно",
          "Два раза в смену",
          "Ежесуточно",
          "Еженедельно",
          "Ежемесячно",
        ],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20308107",
        text: "Как часто должно проверяться состояние системы охлаждения печей?",
        answers: [
          "Ежесменно",
          "Два раза в смену",
          "Ежесуточно",
          "Еженедельно",
          "Через каждые три часа",
        ],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20308108",
        text: "В каком случае заправка мартеновских печей и двухванных сталеплавильных агрегатов не допускается?",
        answers: [
          "При отключенных защитных блокировках",
          "При неисправности оборудования",
          "При открытом отверстии в площадке для спуска шлака",
          "При сокращении интенсивности продувки до значений, установленных технологической инструкцией",
        ],
        correctAnswers: ["При открытом отверстии в площадке для спуска шлака"],
      },
      {
        code: "20308109",
        text: "Чем должна быть продута горелка перед включением и подачей газа?",
        answers: ["Кислородом", "Сжатым воздухом", "Азотом", "Паром"],
        correctAnswers: ["Кислородом"],
      },
      {
        code: "20308110",
        text: "Чем должен быть покрыт пол рабочей площадки возле электропечи?",
        answers: [
          "Термостойким настилом",
          "Электроизолирующим настилом",
          "Деревянным настилом",
        ],
        correctAnswers: ["Электроизолирующим настилом"],
      },
      {
        code: "20308111",
        text: "Какие ручки должен иметь металлический инструмент, применяемый при обслуживании индукционных печей?",
        answers: [
          "Алюминиевые ручки",
          "Деревянные ручки",
          "Электроизолированные ручки",
          "Омедненные ручки",
        ],
        correctAnswers: ["Электроизолированные ручки"],
      },
      {
        code: "20308112",
        text: "Какие мероприятия должны быть выполнены при уходе металла из тигля?",
        answers: [
          "Жидкий металл должен быть слит в изложницу",
          "Печь должна быть остановлена и остужена",
          "Печь должна быть отключена, и жидкий металл слит в изложницу",
          "Печь должна быть остановлена и продута воздухом",
        ],
        correctAnswers: [
          "Печь должна быть отключена, и жидкий металл слит в изложницу",
        ],
      },
      {
        code: "20308113",
        text: "Какие мероприятия должны быть выполнены при прогаре водоохлаждаемых элементов печи и попадании воды в зону плавления печи?",
        answers: [
          "Должна быть снижена нагрузка на печь",
          "Печь должна быть немедленно отключена",
          "Немедленно должна быть отключена подача воды",
          "Печь должна быть переведена на циркуляцию по топливу",
        ],
        correctAnswers: ["Печь должна быть немедленно отключена"],
      },
      {
        code: "20308114",
        text: "В каких случаях не допускается отключение системы охлаждения кристаллизатора?",
        answers: [
          "До охлаждения камеры до температуры 45 °С",
          "До выгрузки слитка из камеры",
          "До окончания процесса разгрузки камеры",
          "До полной остановки печи и установки заглушек на обвязочных трубопроводах",
        ],
        correctAnswers: ["До выгрузки слитка из камеры"],
      },
      {
        code: "20308115",
        text: "Чем должен быть покрыт пол пульта управления электронно-лучевой печью?",
        answers: [
          "Электроизолирующим материалом, на который должно быть нанесено клеймо испытания",
          "Нескользящим материалом",
          "Деревянным покрытием",
          "Огнеупорной плиткой",
        ],
        correctAnswers: [
          "Электроизолирующим материалом, на который должно быть нанесено клеймо испытания",
        ],
      },
      {
        code: "20308116",
        text: "При каких условиях должны производиться все работы по подготовке плавки?",
        answers: [
          "В период проведения плавки",
          "Только при отключенной печи (установке)",
          "После окончания плавки",
        ],
        correctAnswers: ["Только при отключенной печи (установке)"],
      },
      {
        code: "20308117",
        text: "Что обязаны проверить сталевар и ответственные лица перед включением печи на плавку?",
        answers: [
          "Исправность систем автоматизации",
          "Исправность системы газоснабжения",
          "Исправность оборудования, футеровки и свода печи",
          "Исправность систем водоснабжения",
        ],
        correctAnswers: ["Исправность оборудования, футеровки и свода печи"],
      },
      {
        code: "20308118",
        text: "Каков допустимый износ цапф ковшей во всех измерениях?",
        answers: [
          "Не более 10% их первоначальных размеров",
          "Не более 12% их первоначальных размеров",
          "Не более 15% их первоначальных размеров",
        ],
        correctAnswers: ["Не более 10% их первоначальных размеров"],
      },
      {
        code: "20308119",
        text: "Как часто должна производиться проверка состояния механизма поворота конвертера?",
        answers: [
          "Ежесменно",
          "Ежедневно",
          "После каждой плавки",
          "Перед каждой плавкой",
        ],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20308120",
        text: "Что должно регулярно производиться в корпусах обезвоживания шлама?",
        answers: [
          "Механическое удаление шлама с рабочих площадок и строительных конструкций",
          "Влажная уборка рабочих площадок и строительных конструкций от пыли",
          "Гидросмыв шлама с рабочих площадок и строительных конструкций",
        ],
        correctAnswers: [
          "Гидросмыв шлама с рабочих площадок и строительных конструкций",
        ],
      },
      {
        code: "20308121",
        text: "Как должен быть подготовлен газоотводящий тракт конвертера к ремонту?",
        answers: [
          "Должен быть надежно отключен от общих коллекторов и боровов",
          "Должен быть продут азотом",
          "Должен быть тщательно очищен от отложений",
          "Должен быть пропарен в течение 72 часов",
        ],
        correctAnswers: [
          "Должен быть надежно отключен от общих коллекторов и боровов",
        ],
      },
      {
        code: "20308122",
        text: "Что должно в обязательном порядке производиться при категорировании зданий и помещений?",
        answers: [
          "Расчет избыточного давления взрыва с учетом наиболее опасной модели аварийной ситуации",
          "Расчет тротилового эквивалента",
          "Оценка риска аварий",
          "Расчет максимального срока эксплуатации при критических параметрах",
        ],
        correctAnswers: [
          "Расчет избыточного давления взрыва с учетом наиболее опасной модели аварийной ситуации",
        ],
      },
      {
        code: "20308123",
        text: "Что необходимо делать во время работы мельницы в помольном помещении?",
        answers: [
          "Закрывать все двери и включать приточно-вытяжную вентиляцию",
          "Включать световое предупредительное табло",
          "Закрывать все двери и включать световое предупредительное табло",
          "Включать аспирационную систему",
        ],
        correctAnswers: [
          "Закрывать все двери и включать световое предупредительное табло",
        ],
      },
      {
        code: "20308124",
        text: "В присутствии какого лица должна производиться присадка легковоспламеняющихся порошковых материалов, а также смесей на их основе в печь или ковш?",
        answers: [
          "В присутствии сменного мастера",
          "В присутствии ответственного лица, назначенного распоряжением по цеху",
          "В присутствии бригадира",
          "В присутствии главного технолога",
        ],
        correctAnswers: [
          "В присутствии ответственного лица, назначенного распоряжением по цеху",
        ],
      },
      {
        code: "20308125",
        text: "Какие требования установлены к управлению стопорами и шиберными затворами ковшей?",
        answers: [
          "Управление должно быть радиоуправляемым",
          "Управление должно быть автоматизированным",
          "Управление должно быть дистанционным",
          "Управление должно быть местным и ручным",
        ],
        correctAnswers: ["Управление должно быть дистанционным"],
      },
      {
        code: "20308126",
        text: "С помощью каких устройств должно производиться удаление пыли из изложниц?",
        answers: [
          "С помощью продувочных устройств",
          "С помощью пылеотсасывающих устройств",
          "С помощью промывочных  средств",
          "С помощью пропаривающих устройств",
        ],
        correctAnswers: ["С помощью пылеотсасывающих устройств"],
      },
      {
        code: "20308127",
        text: "Как должна производиться разделка сталевыпускного отверстия?",
        answers: [
          "Должна производиться только при наличии под желобом ковшей, а в разливочном пролете - состава с изложницами",
          "Должна производиться только при наличии специального инструмента",
          "Должна производиться под руководством мастера смены",
          "Должна производиться специально подготовленным персоналом с применением вспомогательных инструментов",
        ],
        correctAnswers: [
          "Должна производиться только при наличии под желобом ковшей, а в разливочном пролете - состава с изложницами",
        ],
      },
      {
        code: "20308128",
        text: "Что должно исключаться при присадке раскислителей в ковш?",
        answers: [
          "Неисправность дозировочного устройства",
          "Повреждение стопорного устройства",
          "Распыление раскислителей",
          "Передозировка раскислителей",
        ],
        correctAnswers: ["Повреждение стопорного устройства"],
      },
      {
        code: "20308129",
        text: "Какие требования установлены к сцепке и расцепке сталевозной тележки?",
        answers: [
          "Должны быть дистанционными",
          "Должны осуществляться сцепщиком при помощи приспособления",
          "Должны быть автоматическими",
          "Должны быть механизированы",
        ],
        correctAnswers: ["Должны быть дистанционными"],
      },
      {
        code: "20308130",
        text: "Какие требования установлены к раздеванию слитков с помощью напольных машин или кранов?",
        answers: [
          "Необходимо осуществлять в специально выделенном помещении после образования твердой корки",
          "Необходимо осуществлять только после полного затвердевания слитков",
          "Необходимо осуществлять после остывания слитков до температуры не выше 500 °С",
          "Необходимо осуществлять после остывания слитков до температуры не выше 60 °С",
        ],
        correctAnswers: [
          "Необходимо осуществлять только после полного затвердевания слитков",
        ],
      },
      {
        code: "20308131",
        text: "Какие требования установлены к укладке слитков?",
        answers: [
          "Укладка должна производиться в специальные закрома",
          "Укладка должна производиться на специальные стеллажи, исключающие раскатывание слитков",
          "Укладка должна производиться на специально выделенные в цехе площадки",
          "Укладка должна производиться на площадки с бетонным основанием и обвалованием",
        ],
        correctAnswers: [
          "Укладка должна производиться на специальные стеллажи, исключающие раскатывание слитков",
        ],
      },
      {
        code: "20308132",
        text: "Какие требования установлены к срокам осмотра баков (емкостей) для смазочных материалов и баков для варки смолы и к осмотру и чистке смотровых люков и вытяжных труб баков для варки смолы?",
        answers: [
          "Осмотр должен производиться не реже двух раз в год, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно",
          "Осмотр должен производиться не реже одного раза в два года, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно",
          "Осмотр должен производиться не реже одного раза в год, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно",
        ],
        correctAnswers: [
          "Осмотр должен производиться не реже одного раза в год, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно",
        ],
      },
      {
        code: "20308133",
        text: "Какие требования установлены к скорости движения железнодорожного транспорта на шлаковом дворе?",
        answers: [
          "Скорость не должна превышать 10 км/ч",
          "Скорость не должна превышать 5 км/ч",
          "Скорость не должна превышать 6 км/ч",
        ],
        correctAnswers: ["Скорость не должна превышать 5 км/ч"],
      },
      {
        code: "20308134",
        text: "Какие требования установлены к сливу шлака в шлаковую яму?",
        answers: [
          "Должен производиться равномерной струей",
          "Должен производиться кантовкой ковша на 90°",
          "Должен производиться плотной струей",
          "Должен производиться тонкой струей",
        ],
        correctAnswers: ["Должен производиться равномерной струей"],
      },
      {
        code: "20308135",
        text: "Как часто должна проверяться надежность крепления головок электродов?",
        answers: [
          "Один раз в смену",
          "Правилами не регламентируется",
          "Один раз в сутки",
        ],
        correctAnswers: ["Правилами не регламентируется"],
      },
      {
        code: "20308136",
        text: "Каким гидравлическим давлением должны испытываться на прочность и плотность трубки системы охлаждения индуктора?",
        answers: [
          "На 1,5 величины рабочего давления охлаждающей воды",
          "На 2,0 величины рабочего давления охлаждающей воды",
          "На 1,25 величины рабочего давления охлаждающей воды",
        ],
        correctAnswers: ["На 1,5 величины рабочего давления охлаждающей воды"],
      },
      {
        code: "20308137",
        text: "При каком давлении должен срабатывать предохранительный клапан вакуумной камеры печи?",
        answers: [
          "При давлении 0,05 МПа (0,5 кгс/см²)",
          "При давлении 0,02 МПа (0,2 кгс/см²)",
          "При давлении 0,01 МПа (0,1 кгс/см²)",
        ],
        correctAnswers: ["При давлении 0,01 МПа (0,1 кгс/см²)"],
      },
      {
        code: "20308138",
        text: "Какие требования установлены к материалам, используемым для приготовления формовочных и стержневых смесей?",
        answers: [
          "Должны иметь накладные",
          "Должны иметь сертификаты качества",
          "Должны иметь данные о поставщике",
          "Должны иметь формуляры качества",
        ],
        correctAnswers: ["Должны иметь сертификаты качества"],
      },
      {
        code: "20308139",
        text: "Какие требования установлены к снабжению литейных производств щелочью?",
        answers: [
          "Должны снабжаться кристаллизованной щелочью в металлических бочках",
          "Должны снабжаться сульфитной щелочью",
          "Должны снабжаться щелочью в виде порошка",
          "Не регламентируются",
        ],
        correctAnswers: ["Должны снабжаться сульфитной щелочью"],
      },
      {
        code: "20308140",
        text: "Чем должны защищаться стальные канаты и цепи грузоподъемных устройств, предназначенные для перемещения ковшей с расплавленным металлом, а также траверсы самих ковшей?",
        answers: [
          "Должны защищаться кожухами от воздействия лучистого тепла",
          "Должны покрываться огнестойкими составами для защиты от воздействия тепловой нагрузки",
          "Должны защищаться специальным смазочным материалом, защищающим от воздействия лучистого тепла",
          "Должны защищаться тепловой изоляцией",
        ],
        correctAnswers: [
          "Должны защищаться кожухами от воздействия лучистого тепла",
        ],
      },
      {
        code: "20308141",
        text: "В каких случаях не допускается вторичное использование ковша для заливки или разливки металла?",
        answers: [
          "Без предварительной замены стопора",
          "Без предварительной замены стопора и стакана",
          "Без предварительной замены футеровки",
        ],
        correctAnswers: ["Без предварительной замены стопора и стакана"],
      },
      {
        code: "20308142",
        text: "Что должно быть у каждого плавильного агрегата с выпуском металла через летку?",
        answers: [
          "Должны быть две штанги длиной не менее 1,5 м и запасные пробки для закрывания леток",
          "Должны быть три штанги длиной не менее 2,5 м и запасные пробки для закрывания леток",
          "Должны быть две штанги длиной не менее 2 м и запасные пробки для закрывания леток",
        ],
        correctAnswers: [
          "Должны быть две штанги длиной не менее 1,5 м и запасные пробки для закрывания леток",
        ],
      },
      {
        code: "20308143",
        text: "Какие установлены требования к хранению материалов для приготовления формовочных смесей?",
        answers: [
          "Должны храниться в отдельных помещениях, размещаемых вне пределов производственных участков и отделений",
          "Должны храниться в специально отведенных местах цеха",
          "Должны храниться в отдельных помещениях в герметичной таре",
          "Должны храниться в смежных с производственными участками помещениях",
        ],
        correctAnswers: [
          "Должны храниться в отдельных помещениях, размещаемых вне пределов производственных участков и отделений",
        ],
      },
      {
        code: "20308144",
        text: "Где должен находиться работник при ручной подправке металла на ножницах?",
        answers: [
          "Сзади ножниц",
          "Спереди ножниц",
          "Сбоку от ножниц",
          "Ручная подправка металла не допускается",
          "Ручная подправка металла не регламентируется",
        ],
        correctAnswers: ["Сбоку от ножниц"],
      },
      {
        code: "20308145",
        text: "Какие требования предъявляются к корзинам, загружаемым в щелочные ванны?",
        answers: [
          "Должны быть не влажные",
          "Должны быть наполнены горячим металлом",
          "Должны быть наполнены промасленным металлом",
          "Должны быть пронумерованы",
          "Должны быть изготовлены из просечного металла",
        ],
        correctAnswers: ["Должны быть не влажные"],
      },
      {
        code: "20308146",
        text: "Как должны подготавливаться растворы кислот и щелочей?",
        answers: [
          "Вода должна заливаться небольшой струей в кислоту и щелочь",
          "Кислота и щелочь должны заливаться небольшой струей в холодную воду",
          "Кислота и щелочь должны заливаться небольшой струей в воду, нагретую до температуры 70 °С",
          "Кислота и щелочь должны смешиваться с водой в пропорции 1:1 в специальных емкостях для щелочных растворов и кислотосборниках",
        ],
        correctAnswers: [
          "Кислота и щелочь должны заливаться небольшой струей в холодную воду",
        ],
      },
      {
        code: "20308147",
        text: "Какие требования установлены к ведению взрывных работ, хранению, транспортированию взрывчатых материалов и эксплуатации броневых ям?",
        answers: [
          "Работы должны производиться специально обученным цеховым обслуживающим персоналом",
          "Работы могут производиться взрывниками в процессе эксплуатации оборудования цеха",
          "Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах",
        ],
        correctAnswers: [
          "Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах",
        ],
      },
      {
        code: "20308148",
        text: "Чему из перечисленного должны соответствовать средства автоматизации, производственной сигнализации и связи, блокировочные устройства? Выберите все правильные ответы.",
        answers: [
          "Должностным инструкциям",
          "Производственной документации",
          'Требованиям Технического регламента Таможенного союза "О безопасности оборудования для работы во взрывоопасных средах"',
          "Требованиям Правил безопасности процессов получения или применения металлов",
          "Требованиям правил по охране труда",
        ],
        correctAnswers: [
          "Производственной документации",
          "Требованиям Правил безопасности процессов получения или применения металлов",
        ],
      },
      {
        code: "20308149",
        text: "Чем определяется высота наполнения ковша металлом и общее количество присадок, обеспечивающее безопасное выполнение технологических операций?",
        answers: [
          "Проектной документацией",
          "Всеми перечисленными документами",
          "Правилами безопасности процессов получения или применения металлов",
          "Производственной документацией",
        ],
        correctAnswers: ["Производственной документацией"],
      },
      {
        code: "20308150",
        text: "Какие требования к внепечной обработке жидкого металла указаны неверно?",
        answers: [
          "Ввод раскислителей и легирующих материалов в ковш с жидким металлом под вакуумом должен осуществляться через вакуумный шлюз, конструкция которого должна обеспечивать сохранение вакуума в системе",
          "Агрегаты внепечной обработки жидкого металла должны быть оборудованы системой управления, обеспечивающей функционирование механизмов в безопасных режимах и автоматическое отключение агрегатов при отклонении контролируемых параметров от заданных",
          "Высота наполнения ковша металлом и общее количество присадок, обеспечивающее безопасное выполнение технологических операций, определяется производственной документацией",
          "Процесс выдувания порошкообразного материала из пневмонагнетателя и тракта подачи должен осуществляться вручную в соответствии с производственной документацией",
        ],
        correctAnswers: [
          "Процесс выдувания порошкообразного материала из пневмонагнетателя и тракта подачи должен осуществляться вручную в соответствии с производственной документацией",
        ],
      },
      {
        code: "20308151",
        text: "Каким документом определяется максимальная величина давления газа (аргона), необходимая для открывания донных продувочных фурм и ковшевых пробок?",
        answers: [
          "Технологической инструкцией",
          "Инструкцией по охране труда на предприятии",
          "Инструкцией о мерах пожарной безопасности при проведении огневых работ",
          "Должностной инструкцией",
          "Инструкцией по проведению газоопасных работ",
        ],
        correctAnswers: ["Технологической инструкцией"],
      },
      {
        code: "20308152",
        text: "В каком положении должна быть крышка завалочного окна во время работы газокислородной горелки?",
        answers: [
          "Крышка должна быть закрыта",
          "Крышка должна быть снята",
          "Крышка должна быть открыта для наблюдения за работой горелки",
          "Крышка должна быть опломбирована",
        ],
        correctAnswers: ["Крышка должна быть закрыта"],
      },
      {
        code: "20308153",
        text: "Когда должны выполняться работы по очистке пространства под печью, а также приямков от шлака и мусора?",
        answers: [
          "Работы должны выполняться только в начале плавления шихты до образования значительного количества жидкого металла",
          "Работы должны выполняться только до начала плавления шихты",
          "Работы должны выполняться после выпуска металла",
          "Работы должны выполняться ежесменно после каждого полного цикла плавления",
        ],
        correctAnswers: [
          "Работы должны выполняться только в начале плавления шихты до образования значительного количества жидкого металла",
        ],
      },
      {
        code: "20308154",
        text: "Какому давлению гидравлического испытания должны подвергаться водоохлаждаемые элементы печей перед их установкой?",
        answers: [
          "На 2,0 величины рабочего давления охлаждающей воды",
          "На 1,5 величины рабочего давления охлаждающей воды",
          "На 1,25 величины рабочего давления охлаждающей воды",
        ],
        correctAnswers: ["На 1,5 величины рабочего давления охлаждающей воды"],
      },
      {
        code: "20308155",
        text: "Чем должна обеспечиваться безопасность производственных процессов нанесения металлопокрытий?",
        answers: [
          "Всем перечисленным",
          "Автоматизацией производственных процессов и герметизацией технологического оборудования, являющегося источником вредных и (или) опасных производственных факторов",
          "Применением безопасных способов хранения и транспортирования исходных и вспомогательных веществ и материалов, заготовок и готовой продукции",
          "Заменой производственных процессов и операций, связанных с наличием вредных и (или) опасных производственных факторов, процессами и операциями, при которых указанные факторы отсутствуют или имеют меньшую интенсивность",
          "Применением средств индивидуальной и коллективной защиты работников",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20308156",
        text: "Какие требования к цинкованию металлов и полимерным покрытиям указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Все операции по транспортированию и обработке проката при нанесении защитных покрытий, а также вспомогательные операции (смена анодов в ваннах электролитического лужения и оцинкования, загрузка металла в ванну с расплавом, приготовление, подача и очистка растворов) должны быть механизированы и выполняться в соответствии с технологическими инструкциями",
          "Все перечисленные",
          "Нанесение защитных покрытий на листовой металл (лужение, оцинкование) следует производить на установках непрерывного действия",
          "Оборудование для нанесения защитных покрытий (полимерных материалов) должно иметь местные отсосы",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20308157",
        text: "В течение какого времени должны подаваться звуковые и световые сигналы перед пуском в работу технического устройства, узлы которого или все устройство перемещаются в процессе работы?",
        answers: [
          "Не менее 10 секунд",
          "Не менее 50 секунд",
          "Не менее 30 секунд",
          "Не менее 5 секунд",
        ],
        correctAnswers: ["Не менее 10 секунд"],
      },
      {
        code: "20308158",
        text: "Какие требования к цинкованию металлов указаны неверно?",
        answers: [
          "Все перечисленные требования указаны верно",
          "Порядок загрузки блоков цинка в ванну цинкования определяется проектом. При необходимости разрабатываются дополнительные меры для безопасности персонала",
          "Срок эксплуатации ванн цинкования определяется проектной документацией завода-изготовителя",
          "Срок замены оборудования и футеровки ванн для цинкования определяется эксплуатирующей организацией по результатам проведения экспертизы промышленной безопасности ванн для цинкования",
          "Электропроводка на ваннах цинкования должна быть защищена от возможного повреждения их горячим металлом",
        ],
        correctAnswers: [
          "Срок замены оборудования и футеровки ванн для цинкования определяется эксплуатирующей организацией по результатам проведения экспертизы промышленной безопасности ванн для цинкования",
        ],
      },
      {
        code: "20308159",
        text: "При каком содержании кислорода в колошниковом газе закрытых рудовосстановительных печей печь немедленно отключается?",
        answers: [
          "При увеличении до 3%",
          "При увеличении до 1%",
          "При увеличении до 2%",
        ],
        correctAnswers: ["При увеличении до 2%"],
      },
      {
        code: "20308160",
        text: "Каким гидравлическим давлением должны быть испытаны на прочность и плотность трубки индуктора?",
        answers: [
          "Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 2,5 раза",
          "Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 1,5 раза",
          "Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 2 раза",
          "Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 1,25 раза",
        ],
        correctAnswers: [
          "Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 1,5 раза",
        ],
      },
      {
        code: "20308161",
        text: "Какие действия необходимо предпринять в случае вспенивания расплава?",
        answers: [
          "Снять пену специальным приспособлением, последующую заливку производить с уменьшенной скоростью",
          "Уменьшить скорость заливки без остановки процесса заливки восстановителя и загрузки твердой шихты",
          "Заливка восстановителя и загрузка твердой шихты в расплав должны быть приостановлены с последующим уменьшением скорости заливки",
        ],
        correctAnswers: [
          "Заливка восстановителя и загрузка твердой шихты в расплав должны быть приостановлены с последующим уменьшением скорости заливки",
        ],
      },
      {
        code: "20308162",
        text: "Какая должна быть температура в помещениях в холодное время года, там, где осуществляются мокрые производственные процессы?",
        answers: ["Не ниже 18 °C", "Не ниже 20 °C", "Не ниже 16 °C"],
        correctAnswers: ["Не ниже 16 °C"],
      },
      {
        code: "20308163",
        text: "Какими средствами пожаротушения должны быть оборудованы помещения, где хранится карбид кальция?",
        answers: [
          "Пенные огнетушители и войлок",
          "Порошковые огнетушители, сухой песок",
          "Пенные огнетушители",
          "Углекислотные огнетушители, кошма",
          "Бочки с водой",
        ],
        correctAnswers: ["Порошковые огнетушители, сухой песок"],
      },
      {
        code: "20308164",
        text: "Чем должны быть оборудованы ванны с кислотой для травления оборудования?",
        answers: [
          "Системой подачи аргона",
          "Местными отсосами",
          "Всем перечисленным",
          "Укрытиями",
          "Системой нейтрализации стоков",
        ],
        correctAnswers: ["Местными отсосами"],
      },
      {
        code: "20308165",
        text: "На каком расстоянии от путей и натяжного троса допускается нахождение людей при транспортировании тележек с изложницами или ковшами, наполненными металлом или шлаком?",
        answers: ["Не ближе 3 м", "Не ближе 2 м", "Не ближе 5 м"],
        correctAnswers: ["Не ближе 5 м"],
      },
      {
        code: "20308166",
        text: "В каком случае должна быть обеспечена двусторонняя радиосвязь для машиниста с мастером (бригадиром) загрузки доменных печей?",
        answers: [
          "Только во время непосредственного проведения работ",
          "Только в случае возникновения аварийной ситуации",
          "Должна обеспечиваться непрерывно",
        ],
        correctAnswers: ["Должна обеспечиваться непрерывно"],
      },
      {
        code: "20308167",
        text: "В соответствии с чем производятся подтяжка канатов скипов и выполнение других работ, связанных с кратковременным прекращением загрузки шихтовых материалов в печь?",
        answers: [
          "В соответствии с производственной документацией",
          "В соответствии с технологической документацией",
          "В соответствии с исполнительной документацией",
          "В соответствии с нарядом-допуском",
        ],
        correctAnswers: ["В соответствии с производственной документацией"],
      },
      {
        code: "20308168",
        text: "В каком случае допускается работа подъемников мелочи кокса и агломерата при неисправных путевых выключателях, выключателях слабины каната, а также концевых выключателях крайних положений скипа? Выберите правильный вариант ответа.",
        answers: [
          "В случае, если большая часть обшивки мостов для подъема мелочи кокса и агломерата находится в исправном состоянии",
          "В случае постоянного контроля работы подъемника персоналом",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20308169",
        text: "В соответствии с каким документом должно производиться опускание в печь застрявших на малом и большом конусах шихтовых материалов?",
        answers: [
          "В соответствии с технологической инструкцией",
          "В соответствии с производственной инструкцией",
          "В соответствии с должностной инструкцией",
          "В соответствии с нарядом-допуском",
        ],
        correctAnswers: ["В соответствии с технологической инструкцией"],
      },
      {
        code: "20308170",
        text: "С какой периодичностью проверяется состояние внешнего оборудования колошника и засыпного аппарата с оформлением результатов проверок?",
        answers: ["Ежедневно", "Еженедельно", "Ежемесячно", "Ежеквартально"],
        correctAnswers: ["Ежеквартально"],
      },
      {
        code: "20308171",
        text: "В период выполнения ремонтных работ с каким сроком допускается загрузка шихты в печь при вышедшем из строя вращающемся распределителе шихты?",
        answers: [
          "Не более 1 часа",
          "Не более 2 часов",
          "Не более 4 часов",
          "Не более 6 часов",
        ],
        correctAnswers: ["Не более 4 часов"],
      },
      {
        code: "20308172",
        text: "Что должно подаваться в межконусное пространство загрузочного устройства доменной печи во избежание образования взрывоопасных смесей? Выберите 2 варианта ответа.",
        answers: ["Пар", "Водород", "Аргон", "Азот"],
        correctAnswers: ["Пар", "Азот"],
      },
      {
        code: "20308173",
        text: "При каком максимальном давлении в межконусном пространстве загрузочного устройства доменной печи осуществляется открытие малого конуса?",
        answers: ["0,01 МПа", "0,015 МПа", "0,025 МПа", "0,03 МПа"],
        correctAnswers: ["0,015 МПа"],
      },
      {
        code: "20308174",
        text: "С какой периодичностью отмечаются все изменения в состоянии кожуха и холодильников печи на схеме расположения холодильников, составляемой на каждую доменную печь?",
        answers: ["Ежедневно", "Еженедельно", "Ежемесячно", "Ежеквартально"],
        correctAnswers: ["Ежемесячно"],
      },
      {
        code: "20308175",
        text: "В каком случае проводится внеочередное обследование кожухов доменных печей и воздухонагревателей?",
        answers: [
          "В случае выявления при эксплуатационных осмотрах необъяснимого роста повреждений и опасности общего разрушения кожуха",
          "После проведения планового технического ремонта",
          "При намечаемом ремонте помещения цеха",
        ],
        correctAnswers: [
          "В случае выявления при эксплуатационных осмотрах необъяснимого роста повреждений и опасности общего разрушения кожуха",
        ],
      },
      {
        code: "20308176",
        text: "Кому должен сообщить мастер доменной печи или газовщик о подвисании и осадке шихты?",
        answers: [
          "Диспетчеру газового хозяйства",
          "Персоналу загрузки",
          "Машинисту воздуходувной машины",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20308177",
        text: "Какое количество выходов должен иметь поддоменник, не считая выхода на литейный двор?",
        answers: [
          "Не менее чем 1",
          "Не менее чем 2",
          "Не менее чем 3",
          "Не менее чем 4",
        ],
        correctAnswers: ["Не менее чем 2"],
      },
      {
        code: "20308178",
        text: "Эксплуатация какого из перечисленного оборудования не допускается в доменном производстве при наличии трещин? Выберите правильный вариант ответа.",
        answers: [
          "Воздухопроводы",
          "Воздухонагреватели",
          "Компрессоры",
          "Горелки",
        ],
        correctAnswers: ["Воздухопроводы"],
      },
      {
        code: "20308179",
        text: "В каком из перечисленных случаев не допускается производить выпуск чугуна?",
        answers: [
          "При отсутствии защитных ограждений",
          "При проведении планового осмотра",
          "При деформациях желоба",
          "При неисправной футеровке",
        ],
        correctAnswers: ["При неисправной футеровке"],
      },
      {
        code: "20308180",
        text: "В каком случае не допускается прожигание корки чугунной летки?",
        answers: [
          "При длине кислородной трубки менее 1 м",
          "При длине кислородной трубки менее 2 м",
          "При длине кислородной трубки менее 3 м",
          "При длине кислородной трубки менее 5 м",
        ],
        correctAnswers: ["При длине кислородной трубки менее 2 м"],
      },
      {
        code: "20308181",
        text: "При каком условии допускается осуществлять маневры со шлаковозными ковшами на постановочных путях? Выберите два варианта ответа.",
        answers: [
          "После согласования между ковшевым шлаковозных ковшей (диспетчером) и составительской бригадой",
          "При наличии светового разрешающего сигнала",
          "При наличии звукового разрешающего сигнала",
          "После согласования между ковшевым шлаковозных ковшей (диспетчером) и оператором",
        ],
        correctAnswers: [
          "После согласования между ковшевым шлаковозных ковшей (диспетчером) и составительской бригадой",
          "При наличии светового разрешающего сигнала",
        ],
      },
      {
        code: "20308182",
        text: "В каком случае допускается повторный налив шлака в ковш с образовавшейся разделительной коркой?",
        answers: [
          "В случае дополнительной проверки исправности ковша",
          "В случае отсутствия трещин на ковше",
          "В случае неполной загрузки ковша",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20308183",
        text: "Кем устанавливается скорость движения чугуновозов с жидким чугуном и шлаковозов с жидким шлаком на переездах, стрелках и в районе доменных печей?",
        answers: [
          "Эксплуатирующей организацией",
          "Заводом-изготовителем",
          "Начальником цеха",
          "Главным инженером предприятия",
        ],
        correctAnswers: ["Эксплуатирующей организацией"],
      },
      {
        code: "20308184",
        text: "На какую величину с возвышением внешнего рельса со стороны слива по отношению к другому должны укладываться пути для шлака на отвале?",
        answers: [
          "Не более 100 мм",
          "Не более 150 мм",
          "Не более 200 мм",
          "Не более 250 мм",
        ],
        correctAnswers: ["Не более 150 мм"],
      },
      {
        code: "20308185",
        text: "На каком расстоянии от бровки отвала должна располагаться ось пути для шлака на отвале?",
        answers: [
          "Не менее 0,9 м",
          "Не менее 1,2 м",
          "Не менее 1,4 м",
          "Не менее 1,8 м",
        ],
        correctAnswers: ["Не менее 1,4 м"],
      },
      {
        code: "20308186",
        text: "Какие независимые технологические линии должны иметь в своем составе установки придоменной грануляции шлака? Выберите два варианта ответа.",
        answers: ["Рабочую", "Резервную", "Байпасную", "Дублирующую"],
        correctAnswers: ["Рабочую", "Резервную"],
      },
      {
        code: "20308187",
        text: "Какое из перечисленных требований к отводу шлакового желоба на резервную линию установки придоменной грануляции шлака указано верно?",
        answers: [
          "Должен быть перекрыт отсечным устройством и на длине не менее 1 м засыпан песком на 100 мм выше бортов желоба",
          "Должен быть перекрыт отсечным устройством и на длине не менее 1,5 м засыпан песком на 100 мм выше бортов желоба",
          "Должен быть перекрыт отсечным устройством и на длине не менее 1,5 м засыпан песком на 200 мм выше бортов желоба",
          "Должен быть перекрыт отсечным устройством и на длине не менее 1 м засыпан песком на 300 мм выше бортов желоба",
        ],
        correctAnswers: [
          "Должен быть перекрыт отсечным устройством и на длине не менее 1 м засыпан песком на 100 мм выше бортов желоба",
        ],
      },
      {
        code: "20308188",
        text: "До какой температуры должны быть охлаждены системы оборотного водоснабжения установок придоменной грануляции шлака для производства работ по их ремонту?",
        answers: [
          "Не выше 10 °C",
          "Не выше 25 °C",
          "Не выше 40 °C",
          "Не выше 55 °C",
        ],
        correctAnswers: ["Не выше 40 °C"],
      },
      {
        code: "20308189",
        text: "Какое из перечисленных требований допускается при грануляции шлака за пределами цеха? Выберите два варианта ответа.",
        answers: [
          "Грануляция шлака за пределами цеха должна производиться в специальных герметизированных установках",
          "Грануляция шлака за пределами цеха должна производиться в грануляционных бассейнах",
          "Грануляция шлака за пределами цеха должна производиться в специальных огнеупорных установках",
          "Грануляция шлака за пределами цеха должна производиться в шламовых бассейнах",
        ],
        correctAnswers: [
          "Грануляция шлака за пределами цеха должна производиться в специальных герметизированных установках",
          "Грануляция шлака за пределами цеха должна производиться в грануляционных бассейнах",
        ],
      },
      {
        code: "20308190",
        text: "Что из перечисленного допускается при разливке чугуна на разливочных машинах?",
        answers: [
          "Нахождение людей в галереях, а также у чугуновозных ковшей во время проведения маневровых работ в здании разливочных машин",
          "Перелив чугуна в мульды",
          "Прожигание корки кислородом",
          "Кантовка ковшей со сплошной коркой чугуна или коркой, образовавшейся у носка ковша",
        ],
        correctAnswers: ["Прожигание корки кислородом"],
      },
      {
        code: "20308191",
        text: "В каком случае допускается подъем ковша краном при зацепленном крюке для кантовки в сталеплавильном производстве?",
        answers: [
          "В случае исправной работы крана",
          "В случае исправности крюка",
          "В случае, если установлена связь между миксеровым и машинистом миксерного крана",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20308192",
        text: "Какой сигнал должен быть подан на пост управления миксером о правильности установки ковша под сливным носком перед началом слива чугуна из миксера?",
        answers: [
          "Звуковой",
          "Световой",
          "По телефону, радио",
          "Любой из перечисленных",
        ],
        correctAnswers: ["Любой из перечисленных"],
      },
      {
        code: "20308193",
        text: "Какое из перечисленных требований к отделению перелива чугуна указано неверно?",
        answers: [
          "Слив чугуна должен производиться в центр ковша равномерной струей",
          "До отцепки локомотива под колеса чугуновоза с обеих сторон должны быть установлены железнодорожные тормозные башмаки",
          "Во время слива чугуна в заливочный ковш нахождение работников в опасной зоне не допускается",
          "Допускается сливать чугун из чугуновозного ковша с застывшими остатками чугуна в нем",
        ],
        correctAnswers: [
          "Допускается сливать чугун из чугуновозного ковша с застывшими остатками чугуна в нем",
        ],
      },
      {
        code: "20308194",
        text: "Какое из перечисленных требований к завалке материалов в печи, конвертеры указано верно? Выберите правильный вариант ответа.",
        answers: [
          "Все перечисленное",
          "Во время завалки материалов и подвалки шихты в печь для безопасности работников должны быть реализованы мероприятия по безопасности работников",
          "Перед началом завалки должен производиться осмотр подины печи",
          "Завалка в двухванный сталеплавильный агрегат металлической стружки не допускается",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20308195",
        text: "Какая допускается максимальная скорость движения состава ковшей с жидким чугуном?",
        answers: ["1 км/ч", "3 км/ч", "5 км/ч", "7 км/ч"],
        correctAnswers: ["5 км/ч"],
      },
      {
        code: "20308196",
        text: "Какие из перечисленных работ по доставке и заливке чугуна в мартеновские печи, двухванные сталеплавильные агрегаты, конвертеры не осуществляются в соответствии с требованиями технологической инструкции?",
        answers: [
          "Заливка чугуна в одну из ванн двухванного сталеплавильного агрегата",
          "Работы на своде мартеновской печи",
          "Замена кислородных фурм",
          "Работы на своде двухванного сталеплавильного агрегата",
        ],
        correctAnswers: ["Работы на своде мартеновской печи"],
      },
      {
        code: "20308197",
        text: "В каком месте должна быть вывешена схема испарительного охлаждения мартеновской печи?",
        answers: [
          "В галерее барабанов-сепараторов",
          "В помещении дежурного персонала",
          "На посту управления печью",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20308198",
        text: "С чьего разрешения должна производиться уборка шлака и мусора из-под мартеновской печи в период завалки и прогрева шихты?",
        answers: [
          "Диспетчера",
          "Оператора",
          "Сталевара печи",
          "Начальника цеха",
        ],
        correctAnswers: ["Сталевара печи"],
      },
      {
        code: "20308199",
        text: "В каком случае осуществляется доливка чугуна в мартеновские и двухванные сталеплавильные агрегаты?",
        answers: [
          "Только в исключительных случаях согласно технологической инструкции",
          "Только в исключительных случаях согласно производственной инструкции",
          "Только в исключительных случаях согласно должностной инструкции",
          "Только в исключительных случаях согласно наряду-допуску",
        ],
        correctAnswers: [
          "Только в исключительных случаях согласно технологической инструкции",
        ],
      },
      {
        code: "20308200",
        text: "Какое из перечисленных требований при обслуживании электропечей указано верно? Выберите два варианта ответа.",
        answers: [
          "Во вновь проектируемых цехах должна предусматриваться установка автоматизированных защитных экранов, обеспечивающих безопасность работников во время ведения плавки от выплесков расплава и производственного шума",
          "Для предупреждения обвалов металлошихты в жидкий металл должны приниматься меры по своевременному обрушению кусков шихты с откосов",
          "Во время работы газокислородной горелки крышка рабочего окна должна быть открыта",
          "В случае аварии в первую очередь должен быть отключен газ",
        ],
        correctAnswers: [
          "Во вновь проектируемых цехах должна предусматриваться установка автоматизированных защитных экранов, обеспечивающих безопасность работников во время ведения плавки от выплесков расплава и производственного шума",
          "Для предупреждения обвалов металлошихты в жидкий металл должны приниматься меры по своевременному обрушению кусков шихты с откосов",
        ],
      },
      {
        code: "20308201",
        text: "Какое из перечисленных требований допускается при транспортировании и погрузке шлака?",
        answers: [
          "Над постановочными путями чугуновозных и шлаковозных ковшей в пределах литейного двора должны устраиваться крыши или специальные навесы в целях исключения попадания атмосферных осадков в ковши",
          "Все варианты расположения железнодорожных путей под доменной печью для слива чугуна, шлака, подвоза и вывоза вспомогательных материалов и оборудования, вывоза скрапа, холодного шлака и для подачи заправочных материалов на литейные дворы должны соответствовать рабочей документации",
          "Шлаковозы должны быть оборудованы механизмами кантования (поворота) чаши с электрическим приводом и ручным управлением",
        ],
        correctAnswers: [
          "Над постановочными путями чугуновозных и шлаковозных ковшей в пределах литейного двора должны устраиваться крыши или специальные навесы в целях исключения попадания атмосферных осадков в ковши",
        ],
      },
      {
        code: "20308202",
        text: "На основании какого документа должна определяться периодичность измерения напряженности и плотности потока энергии электромагнитных полей на рабочих местах при обслуживании электропечей?",
        answers: [
          "Производственной документации",
          "Рабочей документации",
          "Исполнительной документации",
          "Эксплуатационной документации",
        ],
        correctAnswers: ["Производственной документации"],
      },
      {
        code: "20308203",
        text: "Какие требования безопасности при производстве ртути указаны неверно?",
        answers: [
          "Разлив ртути в сосуды должен быть автоматизирован и выполняться в специальном помещении в вытяжном шкафу",
          "Работы, связанные с применением ртути, должны проводиться в соответствии с производственной инструкцией",
          "Конструкция вытяжных шкафов, используемых для работы с ртутью и ее соединениями, разрабатывается проектной организацией",
          "Пары, образующиеся в процессе получения ртути и ее соединений, должны подвергаться конденсации с последующей утилизацией",
        ],
        correctAnswers: [
          "Работы, связанные с применением ртути, должны проводиться в соответствии с производственной инструкцией",
        ],
      },
      {
        code: "20308204",
        text: "Какие металлы допускается применять в помещениях, в которых происходит выделение в воздух паров ртути, в качестве конструкционных материалов строительных конструкций?",
        answers: ["Алюминий", "Медь", "Железо", "Титан"],
        correctAnswers: ["Титан"],
      },
      {
        code: "20308205",
        text: "Какое из перечисленных требований при выдувке металла из ям подины указано неверно?",
        answers: [
          "Выдувка металла из ям подины должна производиться в сталевыпускное отверстие сжатым воздухом или кислородом",
          "Выдувка металла из ям подины должна производиться в сталевыпускное отверстие азотом",
          "До начала выдувки металла из ям перед сталевыпускным отверстием должен быть установлен экран, предотвращающий разбрызгивание металла и шлака",
          "Выдувка металла из ям должна производиться со специальной переносной площадки или с состава, предназначенного для ремонта подин",
        ],
        correctAnswers: [
          "Выдувка металла из ям подины должна производиться в сталевыпускное отверстие азотом",
        ],
      },
      {
        code: "20308206",
        text: "При каком давлении должен срабатывать предохранительный клапан вакуумной камеры печи?",
        answers: [
          "0,01 МПа (0,1 кгс/см²)",
          "0,03 МПа (0,3 кгс/см²)",
          "0,05 МПа (0,5 кгс/см²)",
          "0,07 МПа (0,7 кгс/см²)",
        ],
        correctAnswers: ["0,01 МПа (0,1 кгс/см²)"],
      },
      {
        code: "20308207",
        text: "Как часто должно проверяться исправное действие автоблокировки и сигнализации?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в квартал",
          "Не реже одного раза в полгода",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20308208",
        text: "Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в неделю",
          "Не реже одного раза в 3 месяца",
        ],
        correctAnswers: ["Не реже одного раза в неделю"],
      },
      {
        code: "20308209",
        text: "Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных вне помещений?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в квартал",
          "Не реже одного раза в полгода",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20308210",
        text: "В каких случаях запрещается газовая резка и сварка на действующих газопроводах?",
        answers: [
          "На газопроводах, находящихся под разрежением",
          "После продувки газопроводов сжатым воздухом",
          "На газопроводах, находящихся под давлением",
          "После продувки газопроводов инертным газом",
        ],
        correctAnswers: ["На газопроводах, находящихся под разрежением"],
      },
      {
        code: "20308211",
        text: "На что должен проверяться выполненный сварной шов после врезки в действующий газопровод?",
        answers: [
          "На плотность",
          "На герметичность",
          "На прочность",
          "На чистоту и качество исполнения",
        ],
        correctAnswers: ["На плотность"],
      },
      {
        code: "20308212",
        text: "Каково минимально допустимое давление газа непосредственно перед потребителем (после регулирующих органов)?",
        answers: ["0,4 кПа", "0,3 кПа", "0,5 кПа"],
        correctAnswers: ["0,5 кПа"],
      },
      {
        code: "20308213",
        text: "Разрешено ли использовать продувочные свечи для выпуска в атмосферу избыточного газа?",
        answers: [
          "Только в аварийных случаях",
          "Не разрешено",
          "Разрешено при увеличении давления газа до 10%",
          "Разрешено только при испытании газопроводов",
        ],
        correctAnswers: ["Только в аварийных случаях"],
      },
      {
        code: "20308214",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии более 0,5 мм/г?",
        answers: ["Один раз в полгода", "Один раз в год", "Один раз в 2 года"],
        correctAnswers: ["Один раз в год"],
      },
      {
        code: "20308215",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии 0,1 - 0,5 мм/г?",
        answers: ["Один раз в 2 года", "Один раз в год", "Один раз в 1,5 года"],
        correctAnswers: ["Один раз в 2 года"],
      },
      {
        code: "20308216",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии до 0,1 мм/г?",
        answers: ["Один раз в 4 года", "Один раз в 2 года", "Один раз в 5 лет"],
        correctAnswers: ["Один раз в 4 года"],
      },
      {
        code: "20308217",
        text: "Какая допускается максимальная температура газа в газопроводах в случае подачи в них пара для пропарки, продувки и отогрева?",
        answers: ["100 °C", "90 °C", "85 °C"],
        correctAnswers: ["85 °C"],
      },
      {
        code: "20308218",
        text: "Как часто должна производиться нивелировка действующих газопроводов?",
        answers: ["Один раз в 5 лет", "Один раз в 3 года", "Ежегодно"],
        correctAnswers: ["Один раз в 5 лет"],
      },
      {
        code: "20308219",
        text: "До розжига газопотребляющего агрегата чем должен быть продут газопровод перед коллектором?",
        answers: ["Азотом", "Сжатым воздухом", "Газом", "Водяным паром"],
        correctAnswers: ["Газом"],
      },
      {
        code: "20308220",
        text: "В какие сроки в помещениях категории А должна осуществляться проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в 2 месяца",
          "Не реже одного раза в 3 месяца",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20308221",
        text: "Как часто должны проходить поверку контрольно-измерительные приборы в процессе эксплуатации?",
        answers: [
          "Не реже одного раза в год",
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в 2 года",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20308222",
        text: "При каких показателях газа разрешено применять жидкостные манометры?",
        answers: [
          "При давлении до 0,04 МПа",
          "При давлении до 0,05 МПа",
          "При давлении до 0,03 МПа",
        ],
        correctAnswers: ["При давлении до 0,03 МПа"],
      },
      {
        code: "20308223",
        text: "Куда должны заноситься записи о проведении технического обслуживания, выявленных дефектах и нарушениях газопроводов?",
        answers: [
          "В эксплуатационный журнал",
          "В специальный журнал по устранению дефектов",
          "В паспорт оборудования",
          "В вахтовый журнал",
        ],
        correctAnswers: ["В эксплуатационный журнал"],
      },
      {
        code: "20308224",
        text: "На какие группы подразделяются газоопасные места на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "На I и II группы газоопасности",
          "На I, II и III группы газоопасности",
          "На I, II, III и IV группы газоопасности",
        ],
        correctAnswers: ["На I и II группы газоопасности"],
      },
      {
        code: "20308225",
        text: "С кем согласовывается инструкция, определяющая порядок подготовки и безопасного проведения газоопасных работ на опасных производственных объектах горно-металлургической промышленности? Выберите два правильных ответа.",
        answers: [
          "Со службой производственного контроля",
          "С территориальным органом Ростехнадзора",
          "С главным энергетиком",
          "С ГСС",
          "С техническим директором",
        ],
        correctAnswers: ["Со службой производственного контроля", "С ГСС"],
      },
      {
        code: "20308226",
        text: "С кем согласовывается план организации и проведения газоопасной работы?",
        answers: [
          "С ответственным представителем подразделения или организации (главным энергетиком)",
          "Со службой производственного контроля",
          "С начальником ГСС",
          "Со всеми перечисленными",
        ],
        correctAnswers: ["Со всеми перечисленными"],
      },
      {
        code: "20308227",
        text: "Какое из перечисленных требований к применению жетон-бирочной системы указано неверно?",
        answers: [
          "Жетон-бирочная система применяется при выводе ТУ на продолжительную остановку",
          'Ответственный специалист, разобравший схему, принимает меры, исключающие ошибочное или самопроизвольное включение пусковых устройств, на пусковых устройствах вывешивает предупредительные плакаты "Не включать! Работают люди", проверяет отсутствие энергетического потенциала',
          "Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями",
          "Жетон-бирки после разборки электрической схемы ТУ передаются от технологического персонала ремонтному персоналу с отметкой всех этапов в журнале выдачи и приема жетон-бирок",
        ],
        correctAnswers: [
          "Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями",
        ],
      },
      {
        code: "20308228",
        text: "Каким составом должны выполняться газоопасные работы I и II группы газоопасности с образованием взрывопожароопасных смесей?",
        answers: [
          "Одним человеком под наблюдением газоспасателя или члена ДГСД",
          "Не менее трех человек под наблюдением газоспасателя или члена ДГСД",
          "Не менее двух человек под наблюдением газоспасателя или члена ДГСД",
          "Не менее трех человек",
        ],
        correctAnswers: [
          "Не менее двух человек под наблюдением газоспасателя или члена ДГСД",
        ],
      },
      {
        code: "20308229",
        text: "Какой стаж работы в газовом хозяйстве должны иметь лица, назначаемые ответственными руководителями и ответственными исполнителями газоопасных работ?",
        answers: [
          "Не менее одного года",
          "Не менее двух лет",
          "Не менее трех лет",
          "Не менее пяти лет",
        ],
        correctAnswers: ["Не менее одного года"],
      },
      {
        code: "20308230",
        text: "Какое количество люков должно устраиваться на газовых затворах с цилиндрической вставкой для устойчивого горения газа при проверке засыпного материала?",
        answers: ["3 люка", "1 люк", "2 люка", "Не регламентируется"],
        correctAnswers: ["2 люка"],
      },
      {
        code: "20308231",
        text: "На сколько давление природного газа на входе в УГКС должно быть выше расчетного давления в фурменной зоне доменной печи?",
        answers: [
          "Не менее чем на 0,1 МПа",
          "Не менее чем на 0,2 МПа",
          "Не менее чем на 0,05 МПа",
          "Не менее чем на 0,08 МПа",
        ],
        correctAnswers: ["Не менее чем на 0,2 МПа"],
      },
      {
        code: "20308232",
        text: "Какое допускается максимальное давление кислорода при подаче в УГКС кислорода?",
        answers: [
          "Не более 2,5 МПа",
          "Не более 2,0 МПа",
          "Не более 1,6 МПа",
          "Не более 1,8 МПа",
        ],
        correctAnswers: ["Не более 1,6 МПа"],
      },
      {
        code: "20308233",
        text: "Какое значение не должна превышать объемная доля кислорода в смеси с природным газом при использовании газокислородных смесей в доменном производстве?",
        answers: [
          "Не более 50%",
          "Не более 40%",
          "Не более 30%",
          "Не более 25%",
        ],
        correctAnswers: ["Не более 25%"],
      },
      {
        code: "20308234",
        text: "Подвод азота или пара под каким давлением следует предусматривать для продувки трубопроводов кислорода и газокислородной смеси в доменном производстве?",
        answers: [
          "Не менее чем на 0,05 МПа выше давления кислорода",
          "Не менее чем на 0,08 МПа выше давления кислорода",
          "Не менее чем на 0,1 МПа выше давления кислорода",
          "Не менее чем на 0,03 МПа выше давления кислорода",
        ],
        correctAnswers: ["Не менее чем на 0,1 МПа выше давления кислорода"],
      },
      {
        code: "20308235",
        text: "Какое максимальное значение не должна превышать объемная доля кислорода в азоте, подаваемом на продувку трубопроводов кислорода и газокислородной смеси, в доменном производстве?",
        answers: ["3%", "5%", "4%", "6%"],
        correctAnswers: ["3%"],
      },
      {
        code: "20308236",
        text: "Какое допускается максимальное содержание масла в азоте, подаваемом на продувку трубопроводов кислорода и газокислородной смеси, в доменном производстве?",
        answers: ["15 мг/м³", "20 мг/м³", "25 мг/м³", "10 мг/м³"],
        correctAnswers: ["10 мг/м³"],
      },
      {
        code: "20308237",
        text: "В каком случае разрешается эксплуатация азотопровода без обогрева в доменном производстве?",
        answers: [
          "В случае подачи азота в газообразном состоянии",
          "В случае подачи азота в жидком состоянии",
          "В случае подачи азота в сухом состоянии",
          "Не регламентируется",
        ],
        correctAnswers: ["В случае подачи азота в сухом состоянии"],
      },
      {
        code: "20308238",
        text: "Где должны регистрироваться расходы природного газа и кислорода, подаваемых на смешение, в доменном производстве?",
        answers: [
          "В оперативном журнале работы доменной печи",
          "В оперативном журнале работы установки для подачи природного газа в смеси с кислородом в доменной печи",
          "В документации автоматизированной системы управления технологическим процессом",
          "В производственной документации диспетчерского пункта газового хозяйства",
        ],
        correctAnswers: ["В оперативном журнале работы доменной печи"],
      },
      {
        code: "20308239",
        text: "Что из перечисленного отмечается в техническом паспорте каждого воздухонагревателя? Выберите правильный вариант ответа.",
        answers: [
          "Только результаты периодических осмотров",
          "Только все производимые ремонты с указанием их характера с приложением чертежей, по которым были произведены ремонты",
          "Только лица, выполнявшие сварочные работы",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20308240",
        text: "Какое из перечисленных требований к воздухонагревателям в доменном производстве указано неверно?",
        answers: [
          "Не реже 1 раза в год комиссионно должно проверяться техническое состояние воздухонагревателей и их арматура",
          "Не реже 1 раза в год должны производиться замеры аэродинамического сопротивления насадки воздухонагревателей",
          "Помещения КИПиА воздухонагревателей должны иметь принудительную приточно-вытяжную вентиляцию с подогревом подаваемого воздуха в зимнее время",
          "Конструкция подземных боровов воздухонагревателей должна исключать возможность попадания в них грунтовых вод",
        ],
        correctAnswers: [
          "Не реже 1 раза в год комиссионно должно проверяться техническое состояние воздухонагревателей и их арматура",
        ],
      },
      {
        code: "20308241",
        text: "При какой температуре поверхности воздухопроводов горячего дутья принимаются меры по устранению причин, приведших к их перегреву?",
        answers: [
          "Свыше 100 ­°С",
          "Свыше 120 ­°С",
          "Свыше 150 ­°С",
          "Свыше 200 ­°С",
        ],
        correctAnswers: ["Свыше 200 ­°С"],
      },
      {
        code: "20308242",
        text: "Чем должен производиться нагрев воздухонагревателей в доменном производстве?",
        answers: ["Паром", "Очищенным газом", "Пропаном", "Азотом"],
        correctAnswers: ["Очищенным газом"],
      },
      {
        code: "20308243",
        text: "Какой должна быть продолжительность проветривания воздухонагревателя перед повторным зажиганием газа и перед постановкой его на дутьевой режим?",
        answers: [
          "Не менее 5 минут",
          "Не менее 1 минуты",
          "Не менее 10 минут",
          "Не менее 3 минут",
        ],
        correctAnswers: ["Не менее 1 минуты"],
      },
      {
        code: "20308244",
        text: "Куда может осуществляться сброс горячего воздуха, оставшегося в воздухонагревателе при переводе с дутья на нагрев, в зависимости от конструкции (проекта)? Выберите правильный вариант ответа.",
        answers: [
          "В боров дымовой трубы",
          "В специальный глушитель",
          "В другой воздухонагреватель",
          "В любой из перечисленных в зависимости от конструкции (проекта)",
        ],
        correctAnswers: [
          "В любой из перечисленных в зависимости от конструкции (проекта)",
        ],
      },
      {
        code: "20308245",
        text: "Какое допускается минимальное давление газа в газопроводах перед воздухонагревателями в доменном производстве?",
        answers: ["150 Па", "350 Па", "500 Па", "200 Па"],
        correctAnswers: ["500 Па"],
      },
      {
        code: "20308246",
        text: "До какой температуры должен быть нагрет верх насадок газового регенератора во избежание хлопков и взрывов при пуске газа в мартеновскую печь?",
        answers: [
          "До температуры 600 - 650 °C",
          "До температуры 500 - 550 °C",
          "До температуры 700 - 750 °C",
          "До температуры 300 - 350 °C",
        ],
        correctAnswers: ["До температуры 700 - 750 °C"],
      },
      {
        code: "20308247",
        text: "Какие действия должны быть выполнены при возникновении в мартеновской и двухванной сталеплавильной печи бурных реакций? Выберите правильный вариант ответа.",
        answers: [
          "Только прекращена подача кислорода",
          "Только уменьшена тепловая нагрузка печи",
          "Все перечисленные действия",
        ],
        correctAnswers: ["Все перечисленные действия"],
      },
      {
        code: "20308248",
        text: "На какое давление должны быть рассчитаны специальные жесткие переходы и крепления для соединения кранов и стеклянных трубок приборов и аппаратуры, содержащих ртуть?",
        answers: [
          " На давление, превышающее на 5 - 10 % максимальное рабочее давление",
          " На давление, превышающее на 10 - 15 % максимальное рабочее давление",
          " На давление, превышающее на 15 - 20 % максимальное рабочее давление",
          " На давление, превышающее на 20 - 25 % максимальное рабочее давление",
        ],
        correctAnswers: [
          " На давление, превышающее на 10 - 15 % максимальное рабочее давление",
        ],
      },
      {
        code: "20308249",
        text: "В течение какого времени можно хранить ртуть в производственных помещениях, с работающей вытяжной системой, в герметично закрытых толстостенных стеклянных сосудах?",
        answers: [
          "Не более одних суток",
          "Не более двух суток",
          "Не более трех суток",
          "Не более четырех суток",
        ],
        correctAnswers: ["Не более одних суток"],
      },
      {
        code: "20308250",
        text: "Каким образом в эксплуатирующей организации, имеющей газовое хозяйство, производящей и потребляющей горючие газы, должен быть организован контроль за обеспечением безопасной эксплуатации газового хозяйства?",
        answers: [
          "Только путем создания газовой службы",
          "Только назначением ответственных работников",
          "Только заключением договора на оказание услуг по техническому обслуживанию и ремонту газового хозяйства с организацией",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20308251",
        text: "Какой предел огнестойкости должен быть у стен, разделяющих взрывоопасные помещения на водородных станциях?",
        answers: ["1 час", "1,5 часа", "2 часа", "2,5 часа"],
        correctAnswers: ["2,5 часа"],
      },
      {
        code: "20308252",
        text: "Из каких газов состоят промышленные газы объектов металлургии, возникающие (используемые) в процессе металлургического производства?",
        answers: [
          "Только из природного газа",
          "Только из доменного газа",
          "Только из коксового газа",
          "Только из конвертерного газа",
          "Всех перечисленных, а также реформерного и иных газов",
        ],
        correctAnswers: [
          "Всех перечисленных, а также реформерного и иных газов",
        ],
      },
      {
        code: "20308253",
        text: "Как часто должна производиться нивелировка действующих газопроводов?",
        answers: [
          "Один раз в год",
          "Один раз в 2 года",
          "Один раз в 4 года",
          "Один раз в 5 лет",
        ],
        correctAnswers: ["Один раз в 5 лет"],
      },
      {
        code: "20308254",
        text: "Какие требования установлены к проведению ремонтных работ внутри нагретых технических устройств?",
        answers: [
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 55 °C",
          "Проведение ремонтных работ разрешается после их продувки и снижения температуры воздуха в них до 50 °C",
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C",
        ],
        correctAnswers: [
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C",
        ],
      },
      {
        code: "20308255",
        text: "Какие требования установлены к оборудованию производственных помещений, в которых возможны воспламенения одежды или химические ожоги?",
        answers: [
          "Должны быть в наличии огнетушители",
          "Должны быть устроены ящики с песком",
          "Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
          "Должна быть установлена автоматическая система газотушения",
        ],
        correctAnswers: [
          "Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
      },
      {
        code: "20308256",
        text: "Какой должна быть объемная доля кислорода в воздухе производственных помещений производства продуктов разделения воздуха (далее – ПРВ)?",
        answers: [
          "Не менее 19% и не более 23%",
          "Не менее 18% и не более 23%",
          "Не менее 21% и не более 25%",
        ],
        correctAnswers: ["Не менее 19% и не более 23%"],
      },
      {
        code: "20308257",
        text: "Что необходимо устанавливать в производственных помещениях, где возможны воспламенение одежды или химические ожоги у работников?",
        answers: [
          "Емкости с песком, огнетушители",
          "Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
          "Брезентовые ширмы",
        ],
        correctAnswers: [
          "Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
      },
      {
        code: "20308258",
        text: "После установления каких показателей температуры разрешается проводить ремонтные работы внутри нагретых технических устройств, а также низкотемпературных технических устройств?",
        answers: [
          "После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания",
          "После снижения температуры воздуха до 45 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания",
          "После снижения температуры воздуха до 50 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания",
        ],
        correctAnswers: [
          "После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания",
        ],
      },
      {
        code: "20308259",
        text: "При каких условиях допускается перенос сроков проведения капитальных ремонтов основного технологического оборудования?",
        answers: [
          "При наличии положительного заключения экспертного обследования",
          "При наличии разрешения завода-изготовителя",
          "По решению руководителя предприятия",
          "При наличии разрешения проектной организации",
          "При наличии разрешения Ростехнадзора",
        ],
        correctAnswers: [
          "При наличии положительного заключения экспертного обследования",
        ],
      },
      {
        code: "20308260",
        text: "Каким образом должен проводиться отогрев трубопроводной арматуры?",
        answers: [
          "Открытым пламенем",
          "Горячим воздухом, паром или горячей водой",
          "Электрическими приборами",
          "Прокачкой легкими нефтепродуктами",
        ],
        correctAnswers: ["Горячим воздухом, паром или горячей водой"],
      },
      {
        code: "20308261",
        text: "Каким должно быть расстояние от устройства забора воздуха и камер всасывания работающих воздушных компрессоров ВРУ при выполнении ремонтных работ, работ по газовой сварке и резке металла, чтобы для их выполнения было необходимо наличие письменного разрешения руководителя производства (цеха) и наряда-допуска?",
        answers: ["Менее 100 м", "Менее 75 м", "Менее 50 м", "Менее 250 м"],
        correctAnswers: ["Менее 100 м"],
      },
      {
        code: "20308262",
        text: "Какой срок должны храниться данные самопишущих приборов?",
        answers: [
          "Не менее 3 месяцев",
          "Не менее 6 месяцев",
          "Не менее 12 месяцев",
        ],
        correctAnswers: ["Не менее 3 месяцев"],
      },
      {
        code: "20308263",
        text: "С какой периодичностью следует проверять состояние адсорбента в адсорбционных блоках осушки?",
        answers: ["Один раз в 3 месяца", "Один раз в год", "Два раза в год"],
        correctAnswers: ["Один раз в год"],
      },
      {
        code: "20308264",
        text: "Каково предельно допустимое содержание масла в кислороде, поступающем в компрессор?",
        answers: [
          "Не должно превышать 0,02 мг/м3",
          "Не должно превышать 0,03 мг/м3",
          "Не должно превышать 0,05 мг/м3",
        ],
        correctAnswers: ["Не должно превышать 0,02 мг/м3"],
      },
      {
        code: "20308265",
        text: "Какое должно быть давление во всасывающих коммуникациях компрессоров, сжимающих ПРВ?",
        answers: [
          "Должно быть не ниже 0,5 кПа (50 мм вод. ст.)",
          "Должно быть не ниже 0,4 кПа (40 мм вод. ст.)",
          "Должно быть не ниже 0,2 кПа (20 мм вод. ст.)",
        ],
        correctAnswers: ["Должно быть не ниже 0,5 кПа (50 мм вод. ст.)"],
      },
      {
        code: "20308266",
        text: "Какое должно быть содержание кислорода в воздухе помещения наполнения баллонов?",
        answers: [
          "При объемной доле кислорода в воздухе не менее 19% и не более 23%",
          "При объемной доле кислорода в воздухе не менее 18% и не более 24%",
          "При объемной доле кислорода в воздухе не менее 18% и не более 23%",
        ],
        correctAnswers: [
          "При объемной доле кислорода в воздухе не менее 19% и не более 23%",
        ],
      },
      {
        code: "20308267",
        text: "При какой предельной температуре кислорода после регулятора давления на линии регулирования прекращается его подача?",
        answers: [
          "При повышении более 100 °С",
          "При повышении более 120 °С",
          "При повышении более 150 °С",
        ],
        correctAnswers: ["При повышении более 100 °С"],
      },
      {
        code: "20308268",
        text: "Как часто должна производиться проверка настройки отсечных и предохранительных клапанов?",
        answers: [
          "Не реже одного раза в два месяца, а также после каждой ревизии и ремонта",
          "Не реже одного раза в месяц",
          "Не реже одного раза в три месяца, а также после каждой ревизии и ремонта",
        ],
        correctAnswers: [
          "Не реже одного раза в два месяца, а также после каждой ревизии и ремонта",
        ],
      },
      {
        code: "20308269",
        text: "При каком давлении должна производиться настройка срабатывания отсекающих клапанов кислородно-распределительного (регуляторного) пункта (далее - КРП)?",
        answers: [
          "При давлении на 20% более расчетного и температуре 100 °С",
          "При давлении на 15% более расчетного и температуре 100 °С",
          "При давлении на 25% более расчетного и температуре 100 °С",
        ],
        correctAnswers: [
          "При давлении на 15% более расчетного и температуре 100 °С",
        ],
      },
      {
        code: "20308270",
        text: "При каком давлении должна производиться настройка срабатывания отсекающих клапанов КРП?",
        answers: [
          "При давлении на 20% более расчетного и температуре 100 °С",
          "При давлении на 15% более расчетного и температуре 100 °С",
          "При давлении на 25% более расчетного и температуре 100 °С",
        ],
        correctAnswers: [
          "При давлении на 15% более расчетного и температуре 100 °С",
        ],
      },
      {
        code: "20308271",
        text: "Сколько суток после наполнения должны выдерживаться баллоны с криптоноксеноновой смесью и криптоном в наполнительной комнате или складе баллонов?",
        answers: [
          "Не менее 10 суток",
          "Не менее 14 суток",
          "Не менее 12 суток",
        ],
        correctAnswers: ["Не менее 14 суток"],
      },
      {
        code: "20308272",
        text: "Какая объемная доля водорода в техническом аргоне, поступающем на очистку в воздухоразделительную установку (далее - ВРУ)?",
        answers: [
          "Не должна превышать 3,0%",
          "Не должна превышать 4,0%",
          "Не должна превышать 2,5%",
        ],
        correctAnswers: ["Не должна превышать 2,5%"],
      },
      {
        code: "20308273",
        text: "При какой температуре возможно вскрытие реакторов каталитического гидрирования?",
        answers: [
          "После остывания их до температуры 40 °C",
          "После остывания их до температуры 45 °C",
          "После остывания их до температуры 50 °C",
        ],
        correctAnswers: ["После остывания их до температуры 40 °C"],
      },
      {
        code: "20308274",
        text: "С какой периодичностью следует очищать фильтры?",
        answers: [
          "В первый раз - через 15 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
          "В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
          "В первый раз - через 20 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
        ],
        correctAnswers: [
          "В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
        ],
      },
      {
        code: "20308275",
        text: "Как часто должен производиться осмотр трубопроводов кислорода?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в полгода",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20308276",
        text: "Как часто должна производиться проверка конденсатоотводчиков и пароспутников, обогревающих трубопроводы кислорода в зимнее время?",
        answers: ["Ежедневно", "Каждую смену", "Через каждые двое суток"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "20308277",
        text: "Как часто должна проводиться проверка правильности показаний приборов, перечень которых утверждается руководителем производства?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в два месяца",
        ],
        correctAnswers: ["Не реже одного раза в 3 месяца"],
      },
      {
        code: "20308278",
        text: "Какое количество баллонов с эталонными и поверочными газовыми смесями разрешается хранить в помещении хроматографии?",
        answers: ["Не более двух", "Не более одного", "Не более четырех"],
        correctAnswers: ["Не более двух"],
      },
      {
        code: "20308279",
        text: "Из каких материалов выполняются линии отбора кислорода на анализ?",
        answers: [
          "Из углеродистой стали",
          "Из коррозионностойкой стали или медных сплавов",
          "Из чугуна",
          "Из композитных материалов",
        ],
        correctAnswers: ["Из коррозионностойкой стали или медных сплавов"],
      },
      {
        code: "20308280",
        text: "Какой установлен порядок хранения обтирочных материалов?",
        answers: [
          "В чистых металлических ящиках с крышками",
          "На специально оборудованной площадке",
          "В специально выделенном помещении",
          "В деревянных ящиках",
        ],
        correctAnswers: ["В чистых металлических ящиках с крышками"],
      },
      {
        code: "20308281",
        text: "Из каких материалов не могут быть изготовлены разделители (заглушки) или другие изделия, помещаемые внутрь кислородопровода при его обезжиривании?",
        answers: [
          "Из поролона или других органических материалов",
          "Из цветных металлов",
          "Из композита",
          "Из фторопласта",
        ],
        correctAnswers: ["Из поролона или других органических материалов"],
      },
      {
        code: "20308282",
        text: "Из каких материалов не могут быть изготовлены разделители (заглушки) или другие изделия, помещаемые внутри кислородопровода при его обезжиривании?",
        answers: [
          "Из поролона или других органических материалов",
          "Из картона",
          "Из цветных металлов",
          "Из нержавеющей стали",
        ],
        correctAnswers: ["Из поролона или других органических материалов"],
      },
      {
        code: "20308283",
        text: "Из каких сплавов металлов запрещается установка арматуры на кислородопроводах?",
        answers: [
          "Из чугуна",
          "Из стали",
          "Из сплавов титана",
          "Из сплавов меди и латуни",
        ],
        correctAnswers: ["Из сплавов титана"],
      },
      {
        code: "20308284",
        text: "Какой должна быть объемная доля кислорода в воздухе производственных помещений производства ПРВ?",
        answers: [
          "Не менее 18% и не более 23%",
          "Не менее 19% и не более 23%",
          "Не менее 21% и не более 25%",
        ],
        correctAnswers: ["Не менее 19% и не более 23%"],
      },
      {
        code: "20308285",
        text: "Какая объемная доля водорода в техническом аргоне, поступающем на очистку в ВРУ?",
        answers: [
          "Не должна превышать 2,5%",
          "Не должна превышать 3,0%",
          "Не должна превышать 4,0%",
        ],
        correctAnswers: ["Не должна превышать 2,5%"],
      },
      {
        code: "20308286",
        text: "Какая должна быть минимальная высота забора по периметру территории всего комплекса производства водорода?",
        answers: ["1,8 м", "2 м", "1,5 м", "1,7 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "20308287",
        text: "Какое должно быть расстояние между ресиверами одного газа?",
        answers: [
          "Не менее 1,3 м в свету",
          "Не менее 1,1 м в свету",
          "Не менее 1,5 м в свету",
          "Не менее 1,4 м в свету",
        ],
        correctAnswers: ["Не менее 1,5 м в свету"],
      },
      {
        code: "20308288",
        text: "Какие требования к ресиверам для водорода (сосудам) указаны неверно?",
        answers: [
          "Ресиверы размещаются на открытых площадках, имеющих по периметру ограждение легкого типа высотой не менее 1,2 м из несгораемого материала",
          'На ресиверах должны быть поясняющие надписи: "Водород. Взрывоопасно"',
          "Расстояние от ресиверов (сосудов) с водородом до ограждения должно быть не менее 1,5 м",
          'На ресиверах должна быть надпись: "Курить запрещается"',
        ],
        correctAnswers: [
          'На ресиверах должна быть надпись: "Курить запрещается"',
        ],
      },
      {
        code: "20308289",
        text: "Какие требования из перечисленных должны предусматриваться для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты? Выберите правильный вариант ответа.",
        answers: [
          "Только дистанционное отключение",
          "Только установка на линиях всасывания и нагнетания запорных или отсекающих устройств",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20308290",
        text: "В каком случае все технологическое оборудование должно продуваться инертным газом? Выберите все правильные варианты ответа.",
        answers: [
          "Перед пуском",
          "После остановки более чем на 2 часа",
          "После капитального ремонта",
          "После остановки более чем на 1 час",
        ],
        correctAnswers: ["Перед пуском", "После остановки более чем на 2 часа"],
      },
      {
        code: "20308291",
        text: "При каком содержании водорода в воздухе производственного помещения по сигналу от автоматического газоанализатора технологическое оборудование этого помещения должно быть остановлено?",
        answers: [
          "Выше 28 % нижнего предела взрываемости",
          "Выше 32 % нижнего предела взрываемости",
          "Выше 30 % нижнего предела взрываемости",
          "Выше 25 % нижнего предела взрываемости",
        ],
        correctAnswers: ["Выше 25 % нижнего предела взрываемости"],
      },
      {
        code: "20308292",
        text: "При каком содержании газов должна срабатывать световая и звуковая сигнализации, установленные с автоматическими газоанализаторами в помещениях категории А, где обращается водород?",
        answers: [
          "При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости",
          "При содержании водорода в воздухе помещения не более 15% нижнего предела взрываемости (0,5% об.), кислорода менее 19% и более 23%, угарного газа более 25 мг/м³, метана не более 15% нижнего предела взрываемости",
          "При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 20% и более 25%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости",
          "При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 25%, угарного газа более 30 мг/м³, метана не более 15% нижнего предела взрываемости",
        ],
        correctAnswers: [
          "При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости",
        ],
      },
      {
        code: "20308293",
        text: " Какие требования к вентиляции помещения, где возможно выделение водорода, указаны верно? Выберите 2 варианта ответа.",
        answers: [
          "В случае аварии дополнительно к постоянно работающей общеобменной вентиляции производства водорода должна автоматически включаться аварийная вытяжная система",
          "При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 2 в час",
          "При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 4 в час",
          "При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час",
        ],
        correctAnswers: [
          "В случае аварии дополнительно к постоянно работающей общеобменной вентиляции производства водорода должна автоматически включаться аварийная вытяжная система",
          "При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час",
        ],
      },
      {
        code: "20308294",
        text: "При какой чистоте водорода должен производиться пуск компрессора для наполнения баллонов?",
        answers: [
          "Не менее 99,7%",
          "Не менее 99,5%",
          "Не менее 99,2%",
          "Не менее 99,1%",
        ],
        correctAnswers: ["Не менее 99,7%"],
      },
      {
        code: "20308295",
        text: "Какие устройства должны быть установлены на нагнетательном газопроводе для отключения компрессора от коллектора высокого давления?",
        answers: [
          "Только обратный клапан",
          "Только 2 запорных вентиля",
          "Только свеча с условным проходом не менее 6 мм",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20308296",
        text: "На какой ступени сжатия водородные компрессоры должны иметь предохранительные клапаны?",
        answers: [
          "На всех ступенях сжатия",
          "На средней ступени сжатия",
          "На последней ступени сжатия",
          "На первой ступени сжатия",
        ],
        correctAnswers: ["На всех ступенях сжатия"],
      },
      {
        code: "20308297",
        text: "Какой технической документацией должен быть оснащен каждый компрессорный агрегат или установка, или группа однородных компрессорных установок?",
        answers: [
          "Только паспортом (формуляром) на компрессорную установку",
          "Только схемой трубопроводов",
          "Только инструкцией (руководством) по эксплуатации (безопасному обслуживанию) компрессорной установки",
          "Только паспортами-сертификатами компрессорного масла и результатами его лабораторного анализа",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20308298",
        text: "Какие требования к компрессорным установкам указаны неверно?",
        answers: [
          "Компрессор и его электродвигатель должны устанавливаться на фундаментах, не связанных с конструкциями здания",
          "Отработанное масло может быть допущено к повторному использованию без его регенерации",
          "Двери и окна помещения, где расположены компрессорные установки, должны открываться наружу",
          "Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи",
        ],
        correctAnswers: [
          "Отработанное масло может быть допущено к повторному использованию без его регенерации",
        ],
      },
      {
        code: "20308299",
        text: "Какие требования к компрессорным установкам указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Перед пуском каждого компрессора машинист обязан осмотреть установку, убедиться в ее исправности, проверить систему смазки и охлаждения и произвести пуск в соответствии с инструкцией",
          "Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи",
          "Двери и окна помещения, где расположены компрессорные установки, должны открываться наружу",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20308300",
        text: "Какому классу должна соответствовать герметичность арматуры с металлическим уплотнением в затворе, применяемая для установки на трубопроводах водорода?",
        answers: [
          "Классу герметичности B",
          "Классу герметичности А",
          "Классу герметичности С",
          "Классу герметичности D",
        ],
        correctAnswers: ["Классу герметичности B"],
      },
      {
        code: "20308301",
        text: "Какому классу должна соответствовать герметичность затвора запорной арматуры со средой водорода?",
        answers: [
          "Классу герметичности B",
          "Классу герметичности А",
          "Классу герметичности С",
          "Классу герметичности D",
        ],
        correctAnswers: ["Классу герметичности А"],
      },
      {
        code: "20308302",
        text: "Какую арматуру не допускается применять на водородопроводах?",
        answers: [
          "Из серого чугуна",
          "Стальную",
          "Из ковкого чугуна",
          "Из высокопрочного чугуна",
        ],
        correctAnswers: ["Из серого чугуна"],
      },
      {
        code: "20308303",
        text: "В соответствии с требованиями какой документации должны производиться проектирование, монтаж и эксплуатация водородопроводов, а также вспомогательных трубопроводов обвязки участков комплекса получения и потребления водорода?",
        answers: [
          "Только строительных норм и правил",
          "Только нормативно-технической документации для технологических стальных трубопроводов",
          "Только правил безопасности процессов получения или применения металлов",
          "В соответствии со всей перечисленной документацией",
        ],
        correctAnswers: ["В соответствии со всей перечисленной документацией"],
      },
      {
        code: "20308304",
        text: "Где не допускается размещение операторных помещений?",
        answers: [
          "Над помещениями с взрывоопасными зонами любого класса",
          "Под душевыми, санузлами, под производственными помещениями с мокрым технологическим процессом",
          "Под приточными вентиляционными камерами",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20308305",
        text: "Какие требования к монтажу и эксплуатации водородопроводов указаны неверно?",
        answers: [
          "Монтаж и эксплуатация трубопроводов производятся в соответствии с требованиями строительных норм и правил",
          "Монтаж и эксплуатация трубопроводов производятся в соответствии с нормативно-технической документации для технологических стальных трубопроводов",
          "При прокладке трубопроводов водорода следует, как правило, использовать политиленовые трубы",
        ],
        correctAnswers: [
          "При прокладке трубопроводов водорода следует, как правило, использовать политиленовые трубы",
        ],
      },
      {
        code: "20308306",
        text: "Какая продолжительность пневматических испытаний установлена Правилами безопасности процессов получения или применения металлов после проведения плановых или аварийных остановок водородопроводов?",
        answers: ["1 час", "6 часов", "3 часа", "8 часов"],
        correctAnswers: ["1 час"],
      },
      {
        code: "20308307",
        text: "Через какое время после ввода объекта в эксплуатацию должна проводиться первая выборочная ревизия водородопроводов на вновь осваиваемых производствах?",
        answers: [
          "Не позднее чем через 3 года",
          "Не позднее чем через 4 года",
          "Не позднее чем через 2 года",
          "Не позднее чем через 1 год",
        ],
        correctAnswers: ["Не позднее чем через 4 года"],
      },
      {
        code: "20308308",
        text: "С какой периодичностью должны проводиться испытания на плотность и прочность холодных участков (с температурой до 200 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?",
        answers: [
          "1 раз в 5 лет",
          "1 раз в 3 года",
          "1 раз в 8 лет",
          "1 раз в 6 лет",
        ],
        correctAnswers: ["1 раз в 8 лет"],
      },
      {
        code: "20308309",
        text: "С какой периодичностью должны проводиться испытания на плотность и прочность горячих участков (с температурой 200 - 400 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?",
        answers: [
          "1 раз в 3 года",
          "1 раз в 6 лет",
          "1 раз в 8 лет",
          "1 раз в 5 лет",
        ],
        correctAnswers: ["1 раз в 5 лет"],
      },
      {
        code: "20308310",
        text: "В каких местах допускается прокладка водородопроводов?",
        answers: [
          "По стенам зданий на участках со сплошным остеклением и по легкосбрасываемым ограждающим конструкциям",
          "По наружным стенам производственных зданий",
          "Через производственные помещения, не связанные с потреблением водорода",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20308311",
        text: "Через какие помещения допускается прокладка водородопроводов?",
        answers: [
          "Через бытовые, подсобные, административно-хозяйственные, складские помещения",
          "Через производственные помещения, не связанные с потреблением водорода",
          "Через помещения КИП",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20308312",
        text: "Какие требования к размещению запорной арматуры на водородопроводах указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Запорная и другая арматура должна устанавливаться в местах, удобных для обслуживания",
          "Арматура должна быть закреплена так, чтобы в результате температурных деформаций газопроводов в них не создавались изгибающие напряжения",
          "Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20308313",
        text: "Какое допускается содержание кислорода в инертном газе, предназначенном для продувки водородной системы?",
        answers: [
          "Не более 1 %",
          "Не более 3 %",
          "Не более 5 %",
          "Не более 7 %",
        ],
        correctAnswers: ["Не более 1 %"],
      },
      {
        code: "20308314",
        text: "Какой должен быть общий объем инертного газа для нужд продувки водородной системы?",
        answers: [
          "Не менее 2 кратного объема продуваемой водородной системы",
          "Не менее 3 кратного объема продуваемой водородной системы",
          "Не менее 4 кратного объема продуваемой водородной системы",
          "Не регламентировано",
        ],
        correctAnswers: [
          "Не менее 4 кратного объема продуваемой водородной системы",
        ],
      },
      {
        code: "20308315",
        text: "Каким газом должны продуваться перед пуском и после остановки установки по производству водорода?",
        answers: [
          "Техническим азотом не ниже II сорта по национальному стандарту",
          "Техническим аргоном",
          "Кислородом",
          "Ксеноном",
        ],
        correctAnswers: [
          "Техническим азотом не ниже II сорта по национальному стандарту",
        ],
      },
      {
        code: "20308316",
        text: "Что должно предусматриваться для обеспечения требуемых режимов эксплуатации установки по производству водорода? Выберите правильный вариант ответа.",
        answers: [
          "Управление и контроль всех технологических параметров оборудования из операторной",
          "Дистанционное (аварийное) отключение установки по производству водорода",
          "Дистанционный контроль оборудования из операторной",
          "Автоматическое отключение установки по производству водорода при нарушении нормальной работы по технологическим параметрам",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20308317",
        text: "Какие из перечисленных требований при ведении работ на объектах производства водорода указаны неверно?",
        answers: [
          "Работники на объектах производства водорода должны быть обеспечены СИЗ, спецодеждой, спецобувью и другими средствами, которые должны отвечать требованиям соответствующих стандартов безопасности труда",
          "Открытые движущиеся части машин и механизмов, а также ременные, цепные и зубчатые передачи должны быть снабжены ограждениями",
          "Узлы, детали, приспособления и элементы оборудования, которые могут служить источником опасности для работающих, а также поверхности оградительных и защитных устройств должны быть окрашены в сигнальные цвета",
          "Разрешается входить на объекты с производством и обращением водорода в обуви с железными набойками или гвоздями, а также в одежде, способной накапливать заряды статического электричества",
        ],
        correctAnswers: [
          "Разрешается входить на объекты с производством и обращением водорода в обуви с железными набойками или гвоздями, а также в одежде, способной накапливать заряды статического электричества",
        ],
      },
      {
        code: "20308318",
        text: "Какой порядок действий персонала при пожаре на установке получения водорода указан верно?",
        answers: [
          "Необходимо прекратить подачу водорода к месту пожара и тушить пламя песком или кошмой",
          "Необходимо прекратить подачу водорода к месту пожара и тушить пламя водой",
          "Необходимо прекратить подачу водорода к месту пожара и тушить пламя с помощью порошкового огнетушителя",
        ],
        correctAnswers: [
          "Необходимо прекратить подачу водорода к месту пожара и тушить пламя песком или кошмой",
        ],
      },
      {
        code: "20308319",
        text: "Какие системы должны быть проверены в обязательном порядке перед началом пуска установок получения водорода? Выберите правильный вариант ответа.",
        answers: [
          "Система теплоснабжения",
          "Система водоснабжения",
          "Система воздухоснабжения",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20308320",
        text: "В соответствии с какой документацией должны производиться подготовка к пуску и пуск установок по производству водорода?",
        answers: [
          "В соответствии с технологическим регламентом",
          "В соответствии с инструкцией по эксплуатации",
          "В соответствии с проектом",
          "В соответствии со всей перечисленной документацией",
        ],
        correctAnswers: ["В соответствии со всей перечисленной документацией"],
      },
      {
        code: "20308321",
        text: "Каким образом не допускается определять утечки газов из соединений?",
        answers: [
          "С помощью мыльного раствора",
          "С помощью специальных течеискателей",
          "С использованием открытого пламени",
        ],
        correctAnswers: ["С использованием открытого пламени"],
      },
      {
        code: "20308322",
        text: "Когда могут производиться ремонтные работы с открытым огнем в помещении с обращением водорода водородной станции?",
        answers: [
          "Не ранее чем через 4 часа после отключения установки",
          "После проведения анализа воздуха на отсутствие водорода",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20308323",
        text: "На каких аппаратах, заполненных водородом, допускается производство ремонтных работ?",
        answers: [
          "На аппаратах, оборудованных дополнительной системой защиты",
          "На аппаратах, прошедших очередную техническую проверку",
          "Производство ремонтных работ не допускается",
        ],
        correctAnswers: ["Производство ремонтных работ не допускается"],
      },
      {
        code: "20308324",
        text: "В соответствии с каким документом работникам необходимо соблюдать требования безопасности при загрузке и выгрузке катализаторов и адсорбентов?",
        answers: [
          "В соответствии с технологическим регламентом",
          "В соответствии с технологической инструкцией",
          "В соответствии с производственной инструкцией",
          "В соответствии с производственным регламентом",
        ],
        correctAnswers: ["В соответствии с технологическим регламентом"],
      },
      {
        code: "20308325",
        text: "Какие требования к аварийной системе вентиляции в компрессорном помещении при получении водорода методом диссоциации аммиака указаны неверно?",
        answers: [
          "Все производственные помещения газозащитной станции по производству водорода оборудуются автоматической пожарной сигнализацией",
          "Сигнал о пожаре должен поступать на щит пожарной сигнализации, расположенный в помещении оператора и в помещении пожарного депо предприятия",
          "Помещения, в которых размещены диссоциаторы, должны иметь категорию А по взрывопожароопасности выше отметки 5,5 м от уровня пола",
          "Все стены, разделяющие помещения, должны быть выполнены из негорючих материалов с пределом огнестойкости не более 1 часа",
        ],
        correctAnswers: [
          "Все стены, разделяющие помещения, должны быть выполнены из негорючих материалов с пределом огнестойкости не более 1 часа",
        ],
      },
      {
        code: "20308326",
        text: "С каким минимальным количеством независимых источников электроснабжения должно быть выполнено электропитание оборудования производства водорода?",
        answers: ["2", "3", "4", "5"],
        correctAnswers: ["2"],
      },
      {
        code: "20308327",
        text: "Что должно быть предусмотрено автоматизированной системой управления процессом при получении водорода методом диссоциации аммиака? Выберите правильный вариант ответа.",
        answers: [
          "Светозвуковая сигнализация при достижении предельной концентрации водорода под кровлей помещения диссоциаторов и компрессорной водорода",
          "Сигнализация понижения или повышения температуры диссоциации",
          "Регулирование давления диссоциированного аммиака после компрессора",
          "Обеспечение равномерной подачи аммиака к нижнему диссоциатору",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20308328",
        text: "Какие требования для предотвращения взрывоопасных концентраций под кровлей помещения диссоциаторов и компрессорной водорода указаны неверно?",
        answers: [
          "Для предотвращения взрывоопасных концентраций под кровлей помещения диссоциаторов и компрессорной водорода должны быть установлены датчики довзрывных концентраций",
          "Количество и места расположения датчиков довзрывных концентраций должны определяться проектом",
          "Светозвуковая сигнализация должна иметь вывод на пульт оператора и в помещение пожарного депо предприятия",
          "Количество и места расположения датчиков довзрывных концентраций должны определяться техническим регламентом",
        ],
        correctAnswers: [
          "Количество и места расположения датчиков довзрывных концентраций должны определяться техническим регламентом",
        ],
      },
      {
        code: "20308329",
        text: "Какие требования к процессу получения водорода и диссоциированного аммиака указаны неверно?",
        answers: [
          "Процесс получения водорода и диссоциированного аммиака должен быть автоматизирован",
          "Аппаратура автоматического регулирования, контроля и сигнализации должна быть установлена на пульте управления, расположенном в отдельном помещении категории пожарной опасности А",
          "Регулировка и контроль процесса получения водорода и диссоциированного аммиака производится с помощью приборов",
          "Аппаратура автоматического регулирования, контроля и сигнализации должна быть установлена на пульте управления, расположенном в отдельном помещении категории пожарной опасности Г",
        ],
        correctAnswers: [
          "Аппаратура автоматического регулирования, контроля и сигнализации должна быть установлена на пульте управления, расположенном в отдельном помещении категории пожарной опасности А",
        ],
      },
      {
        code: "20308330",
        text: "С какой периодичностью оператору установки необходимо производить анализ на содержание аммиака в помещении диссоциаторов методами и приборами, установленными в технологическом регламенте (технологической инструкции)?",
        answers: [
          "Не реже одного раза в смену",
          "Не реже двух раз в смену",
          "Не реже трех раз в смену",
          "Не регламентировано",
        ],
        correctAnswers: ["Не реже одного раза в смену"],
      },
      {
        code: "20308331",
        text: "Какое испытательное давление должно приниматься для участка газопровода чистого газа от дроссельной группы до листовой задвижки, где P2 - расчетное избыточное давление (по проекту) газовой среды на участке, МПа?",
        answers: ["1,1P2", "1,25P2", "1,5P2", "1,75P2"],
        correctAnswers: ["1,25P2"],
      },
      {
        code: "20308332",
        text: "Как часто должна производиться проверка конденсатоотводчиков и пароспутников, обогревающих трубопроводы кислорода в зимнее время?",
        answers: ["Ежедневно", "Еженедельно", "Ежемесячно", "Ежегодно"],
        correctAnswers: ["Ежедневно"],
      },
    ],
    20309: [
      {
        code: "20309000",
        text: "Какие работы производятся на ОПО горно-металлургических производств? Выберите правильный вариант ответа.",
        answers: [
          "Только постоянные работы, выполняемые в соответствии с документированной процедурой (инструкцией, картой, регламентом), принятой в организации",
          "Только работы повышенной опасности (сложные, разовые, уникальные)",
          "Только работы, связанные с аварийными ситуациями",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20309001",
        text: "Кем могут проводиться работы повышенной опасности на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "Как работниками эксплуатирующей организации, так и работниками подрядных организаций",
          "Только работниками подрядных организаций",
          "Только работниками эксплуатирующей организации",
        ],
        correctAnswers: [
          "Как работниками эксплуатирующей организации, так и работниками подрядных организаций",
        ],
      },
      {
        code: "20309002",
        text: "С кем согласовывается перечень работ повышенной опасности на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "С управляющим территориального органа Ростехнадзора",
          "Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля",
          "С руководителем газоспасательной службы",
          "С главным инженером организации",
        ],
        correctAnswers: [
          "Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля",
        ],
      },
      {
        code: "20309003",
        text: "Какие из перечисленных требований к акту-допуску подрядных организаций на опасный производственный объект горно-металлургической промышленности указаны неверно?",
        answers: [
          "На один объект, площадку, территорию должен оформляться один акт-допуск",
          "Акт-допуск должен оформляться письменно",
          "Не допускается оформление актов-допусков, характеризующихся одинаковыми координатами рабочих зон",
          "Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)",
        ],
        correctAnswers: [
          "Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)",
        ],
      },
      {
        code: "20309004",
        text: "В каком количестве экземпляров составляется акт-допуск подрядных организаций на опасный производственный объект горно-металлургической промышленности?",
        answers: [
          "В двух экземплярах",
          "В одном экземпляре",
          "В трех экземплярах",
          "В четырех экземплярах",
        ],
        correctAnswers: ["В двух экземплярах"],
      },
      {
        code: "20309005",
        text: "Какие действия предпринимаются при необходимости ведения работ после истечения срока действия акта-допуска подрядных организаций на опасный производственный объект горно-металлургической промышленности?",
        answers: [
          "Продлевается текущий акт-допуск",
          "Составляется новый акт-допуск на следующий срок",
          "Работы продолжаются без оформления",
        ],
        correctAnswers: ["Составляется новый акт-допуск на следующий срок"],
      },
      {
        code: "20309006",
        text: "С кем согласовывается инструкция, определяющая порядок подготовки и безопасного проведения газоопасных работ на опасных производственных объектах горно-металлургической промышленности? Выберите два правильных ответа.",
        answers: [
          "С территориальным органом Ростехнадзора",
          "С маркшейдерской службой",
          "С ГСС",
          "Со службой производственного контроля",
        ],
        correctAnswers: ["С ГСС", "Со службой производственного контроля"],
      },
      {
        code: "20309007",
        text: "Какой стаж работы в газовом хозяйстве должны иметь лица, назначаемые ответственными руководителями и ответственными исполнителями газоопасных работ?",
        answers: [
          "Не менее пяти лет",
          "Не менее трех лет",
          "Не менее одного года",
          "Не менее двух лет",
        ],
        correctAnswers: ["Не менее одного года"],
      },
      {
        code: "20309008",
        text: "С кем согласовывается план организации и проведения газоопасной работы?",
        answers: [
          "Только с начальником газоспасательной службы",
          "Только с ответственным представителем подразделения или организации (главным энергетиком)",
          "Только со службой производственного контроля",
          "Со всеми перечисленными",
        ],
        correctAnswers: ["Со всеми перечисленными"],
      },
      {
        code: "20309009",
        text: "На какой максимальный срок выдается наряд-допуск на работы повышенной опасности?",
        answers: [
          "На 7 календарных дней",
          "На 30 календарных дней",
          "На 10 рабочих дней",
          "На 14 календарных дней",
        ],
        correctAnswers: ["На 30 календарных дней"],
      },
      {
        code: "20309010",
        text: "Кто определяет (назначает) работников, выполняющих мероприятия по безопасности труда, указанных в наряде-допуске (ответственных исполнителей)?",
        answers: [
          "Руководитель организации",
          "Начальник технического подразделения",
          "Выдающий наряд-допуск",
          "Руководитель бригады",
        ],
        correctAnswers: ["Выдающий наряд-допуск"],
      },
      {
        code: "20309011",
        text: "Какие из перечисленных требований к наряду-допуску на работы повышенной опасности указаны неверно? Выберите два правильных ответа.",
        answers: [
          "Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ",
          "После окончания работы наряд-допуск остается у производителя (руководителя) работ",
          "Допуск работников на рабочее место после обеденного перерыва в течение одной смены осуществляется производителем работ без оформления в наряде-допуске",
          "После окончания работы наряд-допуск сдается допускающему к работе или работнику, выдавшему наряд-допуск",
        ],
        correctAnswers: [
          "Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ",
          "После окончания работы наряд-допуск остается у производителя (руководителя) работ",
        ],
      },
      {
        code: "20309012",
        text: "Какое количество производителей (руководителей) работ назначаются на все время действия наряда-допуска на работы повышенной опасности при производстве работ в несколько смен?",
        answers: [
          "Не более двух производителей (руководителей) работ",
          "Количество производителей (руководителей) работ должен определять выдающий наряд-допуск",
          "Несколько производителей (руководителей) работ в соответствии с количеством смен",
          "Один производитель (руководитель) работ",
        ],
        correctAnswers: [
          "Несколько производителей (руководителей) работ в соответствии с количеством смен",
        ],
      },
      {
        code: "20309013",
        text: "В каких из перечисленных случаев допускается продолжать работы, проводимые по наряду-допуску, на опасных производственных объектах?",
        answers: [
          "Если изменился состав бригады",
          "Если обнаружено несоответствие фактического состояния производства работ требованиям безопасности и охраны труда",
          "Если выявлено разовое нарушение условий отключения технических устройств",
          "Если изменился характер, объем и порядок выполнения работ",
        ],
        correctAnswers: ["Если изменился состав бригады"],
      },
      {
        code: "20309014",
        text: "Какие действия должны быть выполнены при утрате наряда-допуска на работы повышенной опасности?",
        answers: [
          "Работы должны быть прекращены. Решение о возобновлении работ должна принять комиссия организации",
          "Решение о продолжении работ без наряда-допуска должен принять допускающий к работе",
          "Работы должны быть завершены без оформления нового наряда-допуска",
          "Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново",
        ],
        correctAnswers: [
          "Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново",
        ],
      },
      {
        code: "20309015",
        text: "В течение какого времени хранятся закрытые (подписанные) наряды-допуски на работы повышенной опасности?",
        answers: [
          "В течение одного месяца",
          "В течение двух месяцев",
          "В течение шести месяцев",
          "В течение года",
        ],
        correctAnswers: ["В течение одного месяца"],
      },
      {
        code: "20309016",
        text: "В течение какого времени хранится журнал регистрации нарядов-допусков со дня внесения последней записи?",
        answers: [
          "В течение года",
          "В течение 3 месяцев",
          "В течение 6 месяцев",
          "В течение 2 лет",
        ],
        correctAnswers: ["В течение 6 месяцев"],
      },
      {
        code: "20309017",
        text: "Каким составом должны выполняться газоопасные работы I и II группы газоопасности с образованием взрывопожароопасных смесей?",
        answers: [
          "Не менее трех человек под наблюдением члена добровольной газоспасательной дружины (ДГСД)",
          "Не менее двух человек под наблюдением газоспасателя или члена ДГСД",
          "Один работник под наблюдением газоспасателя",
          "Не менее пяти работников",
        ],
        correctAnswers: [
          "Не менее двух человек под наблюдением газоспасателя или члена ДГСД",
        ],
      },
      {
        code: "20309018",
        text: "На какие группы подразделяются газоопасные места на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "На I и II группы газоопасности",
          "На I, II и III группы газоопасности",
          "На I, II, III и IV группы газоопасности",
        ],
        correctAnswers: ["На I и II группы газоопасности"],
      },
      {
        code: "20309019",
        text: "Что из перечисленного должны включать технические и организационные меры по обеспечению промышленной безопасности металлургических производств при проектировании объектов металлургии? Выберите правильный вариант ответа.",
        answers: [
          "Только механизацию или автоматизацию управления технологическим оборудованием",
          "Только испытание систем на соответствие их проектным показателям",
          "Только контроль состояния металла и сварных соединений оборудования и трубопроводов",
          "Только проверку метрологических характеристик измерительных каналов на соответствие проектным требованиям",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20309020",
        text: "Что должно быть разработано для обеспечения безопасности при освоении новых производств, технологических процессов и технических устройств?",
        answers: [
          "Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования",
          "Инструкции по эксплуатации технических устройств",
          "Инструкция по проведению пусконаладочных работ",
        ],
        correctAnswers: [
          "Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования",
        ],
      },
      {
        code: "20309021",
        text: "В какой зоне не допускается нахождение людей?",
        answers: [
          "В зоне погрузки грейферными или магнитными кранами",
          "На расстоянии 15 м от зоны работы магнитного крана в производственном помещении",
          "На расстоянии менее 20 м от зоны работы грейферного крана",
          "Во всех перечисленных зонах",
        ],
        correctAnswers: ["В зоне погрузки грейферными или магнитными кранами"],
      },
      {
        code: "20309022",
        text: "Какие требования к производственным помещениям, оборудованию участков приготовления травильных растворов и травления металла указаны неверно?",
        answers: [
          "После работы у ванн травления с химически опасными веществами, используемыми в виде добавок для технологических растворов, вещества должны быть удалены из рабочей зоны на склад",
          "Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность",
          "Периодичность проведения уборки помещений устанавливается в зависимости от условий их эксплуатации. Не допускается уборка, вызывающая распыление вредных веществ",
        ],
        correctAnswers: [
          "Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность",
        ],
      },
      {
        code: "20309023",
        text: "Чем должна обеспечиваться безопасность производственных процессов нанесения металлопокрытий?",
        answers: [
          "Только применением безопасных способов хранения, транспортирования исходных и вспомогательных веществ и материалов, заготовок и готовой продукции",
          "Только заменой производственных процессов и операций с вредными и (или) опасными производственными факторами на процессы и операции с отсутствием данных факторов или имеющих меньшую интенсивность",
          "Только отсутствием (ограничением) непосредственного контакта работников с веществами, растворами, исходными материалами и отходами производства, оказывающими вредное воздействие на организм работников",
          "Только применением блокировочных устройств, средств световой и звуковой сигнализации, аварийного отключения производственного оборудования при нарушениях производственных процессов",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20309024",
        text: "Что должно устраиваться над постановочными путями чугуновозных и шлаковозных ковшей в пределах литейного двора в целях исключения попадания атмосферных осадков в ковши?",
        answers: [
          "Специальные навесы из оцинкованной стали на высоких опорах",
          "Крыши или специальные навесы",
          "Крыши из материалов с полимерным покрытием",
        ],
        correctAnswers: ["Крыши или специальные навесы"],
      },
      {
        code: "20309025",
        text: "При каких условиях запрещается эксплуатация плавильных агрегатов?",
        answers: [
          "При незначительном повышении давления воды в системе водоохлаждения",
          "При незначительном падении давления воды в системе водоохлаждения",
          "При разгерметизации системы водяного охлаждения этих агрегатов",
        ],
        correctAnswers: [
          "При разгерметизации системы водяного охлаждения этих агрегатов",
        ],
      },
      {
        code: "20309026",
        text: "После чего разрешается производить слив расплава в ковши, вышедшие из ремонта?",
        answers: [
          "Только после их охлаждения и смазки",
          "После выдержки в течение суток при температуре 40 °С",
          "Только после тщательного их просушивания и разогрева",
        ],
        correctAnswers: [
          "Только после тщательного их просушивания и разогрева",
        ],
      },
      {
        code: "20309027",
        text: "Какие требования установлены к месту расстановки ковшей, предназначенных для расплава металла?",
        answers: [
          "Допускается расставлять в любом месте на рабочих площадках",
          "Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства",
          "Должны расставляться на свободных площадках пролета цеха",
        ],
        correctAnswers: [
          "Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства",
        ],
      },
      {
        code: "20309028",
        text: "Какие требования установлены к состоянию вакуум-ковшей, тиглей, коробов, кристаллизаторов, изложниц и других емкостей для расплава перед заливкой в них расплава в электролитическом производстве магния?",
        answers: [
          "Должны быть покрыты огнеупорным материалом",
          "Должны быть выдержаны в помещении не менее одной смены",
          "Должны быть очищены от мусора",
        ],
        correctAnswers: ["Должны быть очищены от мусора"],
      },
      {
        code: "20309029",
        text: "Какие требования установлены к фурмам для продувки жидкого металла газами?",
        answers: [
          "Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями",
          "Должны иметь цилиндрическую форму",
          "Должны быть защищены специальными экранами от нагревания",
        ],
        correctAnswers: [
          "Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями",
        ],
      },
      {
        code: "20309030",
        text: "Что должны обеспечивать конструкция и установка элементов фурменного прибора?",
        answers: [
          "Должны обеспечивать надежность",
          "Должны обеспечивать герметичность",
          "Должны обеспечивать безопасность",
        ],
        correctAnswers: ["Должны обеспечивать герметичность"],
      },
      {
        code: "20309031",
        text: "Какие требования установлены к инструментам, приспособлениям и оснастке, используемым для ведения технологии или обслуживания оборудования?",
        answers: [
          "Должны быть предназначены для выполнения любых работ",
          "Должны быть изготовлены из ударопрочных материалов",
          "Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними",
        ],
        correctAnswers: [
          "Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними",
        ],
      },
      {
        code: "20309032",
        text: "Какие требования установлены к инструментам и приспособлениям, используемым во взрывопожароопасных зонах и помещениях?",
        answers: [
          "Не должны давать искры при работе с ними",
          "Должны быть изготовлены из стали",
          "Должны быть изготовлены из чугуна",
        ],
        correctAnswers: ["Не должны давать искры при работе с ними"],
      },
      {
        code: "20309033",
        text: "Какими средствами пожаротушения должны быть оборудованы помещения, где хранится карбид кальция?",
        answers: [
          "Специальные емкости для воды и песка",
          "Пенные огнетушители и кошма",
          "Порошковые огнетушители, сухой песок",
        ],
        correctAnswers: ["Порошковые огнетушители, сухой песок"],
      },
      {
        code: "20309034",
        text: "Какие требования установлены к установке расходных баков с мазутом?",
        answers: [
          "Должны быть установлены на расстоянии не менее 7 м от печей и защищены металлическим ограждением",
          "Должны быть установлены на расстоянии не менее 10 м от печей",
          "Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением",
        ],
        correctAnswers: [
          "Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением",
        ],
      },
      {
        code: "20309035",
        text: "Какое требование к устройству внутренних производственных помещений, находящихся на производственной площадке, включающей технологию с расплавами металлов, имеющей опасность аварии или травмирования работников, указано неверно?",
        answers: [
          "Внутренние помещения производственной площадки должны иметь защиту от воздействия теплового излучения",
          "Помещения пультов управления должны иметь вытяжную вентиляцию",
          "Закрытые пульты управления должны быть оборудованы съемными металлическими сетками или специальными защитными экранами на окнах",
          "Пульты управления агрегатов должны быть расположены на площадке в месте, обеспечивающем их безопасность",
        ],
        correctAnswers: [
          "Помещения пультов управления должны иметь вытяжную вентиляцию",
        ],
      },
      {
        code: "20309036",
        text: "Чем должен быть покрыт пол рабочей площадки возле электропечи?",
        answers: [
          "Электроизолирующим настилом",
          "Деревянным настилом с пропиткой",
          "Листовым термостойким материалом",
        ],
        correctAnswers: ["Электроизолирующим настилом"],
      },
      {
        code: "20309037",
        text: "Как должны быть расположены пульты управления агрегатами?",
        answers: [
          "В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке",
          "На расстоянии не менее 5 м от агрегатов",
          "В непосредственной близости от агрегатов",
        ],
        correctAnswers: [
          "В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке",
        ],
      },
      {
        code: "20309038",
        text: "Что должны иметь закрома и ямы?",
        answers: [
          "Должны иметь служебные проходы",
          "Должны иметь с двух сторон металлические решетки",
          "Со всех сторон должны иметь ограждение",
        ],
        correctAnswers: ["Со всех сторон должны иметь ограждение"],
      },
      {
        code: "20309039",
        text: "Как должна устраняться пробуксовка ленты конвейера?",
        answers: [
          "Должна устраняться при помощи ручной регулировки",
          "Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты",
          "Должна устраняться путем подсыпания канифоли для увеличения трения между лентой и тянущим барабаном",
        ],
        correctAnswers: [
          "Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты",
        ],
      },
      {
        code: "20309040",
        text: "Что должно быть исключено в коммуникациях системы транспортирования взрывопожароопасных веществ и материалов?",
        answers: [
          "Попадание воздушной среды",
          "Возможность разгерметизации системы",
          "Образование взрывоопасной среды",
        ],
        correctAnswers: ["Образование взрывоопасной среды"],
      },
      {
        code: "20309041",
        text: "На что должна быть проверена система пневмотранспорта перед вводом в эксплуатацию?",
        answers: [
          "На прочность движимых частей при проведении внешнего осмотра",
          "На герметичность путем проведения гидравлических испытаний",
          "На плотность под рабочим давлением",
        ],
        correctAnswers: ["На плотность под рабочим давлением"],
      },
      {
        code: "20309042",
        text: "Чем должны быть оборудованы шлаковозы?",
        answers: [
          "Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением",
          "Механизмами кантования (поворота) чаши с ручным гидравлическим приводом",
          "Механизмами кантования (поворота) чаши с пневматическим приводом и автоматическим управлением",
        ],
        correctAnswers: [
          "Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением",
        ],
      },
      {
        code: "20309043",
        text: "Что должно иметься на шлаковом отвале?",
        answers: [
          "Телефонная или радиосвязь с диспетчерской службой производства",
          "Приемопередающий радиоузел коротковолновой связи",
          "Световая сигнализирующая связь рабочих с аварийной службой",
        ],
        correctAnswers: [
          "Телефонная или радиосвязь с диспетчерской службой производства",
        ],
      },
      {
        code: "20309044",
        text: "Какие шпалы должны применяться на железнодорожных путях шлакового отвала?",
        answers: [
          "Деревянные шпалы, пропитанные креозотом",
          "Обрезные деревянные шпалы",
          "Огнестойкие шпалы",
        ],
        correctAnswers: ["Огнестойкие шпалы"],
      },
      {
        code: "20309045",
        text: "Какие требования установлены к кабине завалочной машины?",
        answers: [
          "Кабина завалочной машины должна иметь 4 фиксированных положения",
          "Должна оснащаться устройством принудительной вентиляции",
          "Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака",
        ],
        correctAnswers: [
          "Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака",
        ],
      },
      {
        code: "20309046",
        text: "Какие требования установлены к оборудованию производственных помещений, в которых возможны воспламенения одежды или химические ожоги?",
        answers: [
          "Должны предусматриваться санитарные посты",
          "Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
          "Должны быть обустроены пожарные щиты в каждом цеху",
        ],
        correctAnswers: [
          "Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
      },
      {
        code: "20309047",
        text: "Как часто должны проходить поверку контрольно-измерительные приборы в процессе эксплуатации?",
        answers: [
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в год",
          "Не реже одного раза в 6 месяцев",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20309048",
        text: "При каких показателях газа разрешено применять жидкостные манометры?",
        answers: [
          "При давлении до 0,06 МПа",
          "При давлении до 0,03 МПа",
          "При давлении до 0,04 МПа",
        ],
        correctAnswers: ["При давлении до 0,03 МПа"],
      },
      {
        code: "20309049",
        text: "Чему из перечисленного должны соответствовать средства автоматизации, производственной сигнализации и связи, блокировочные устройства? Выберите все правильные ответы.",
        answers: [
          "Производственной документации",
          'Требованиям "Правил безопасности процессов получения или применения металлов"',
          "Основным требованиям по обеспечению готовности к аварийным ситуациям",
          'Требованиям Технического регламента "О безопасности оборудования для работы во взрывоопасных средах"',
        ],
        correctAnswers: [
          "Производственной документации",
          'Требованиям "Правил безопасности процессов получения или применения металлов"',
        ],
      },
      {
        code: "20309050",
        text: "В течение какого времени должны подаваться звуковые и световые сигналы перед пуском в работу технического устройства, узлы которого или все устройство перемещаются в процессе работы?",
        answers: [
          "Не менее 20 секунд",
          "Не менее 5 секунд",
          "Не менее 30 секунд",
          "Не менее 10 секунд",
        ],
        correctAnswers: ["Не менее 10 секунд"],
      },
      {
        code: "20309051",
        text: "Как часто должно проверяться исправное действие автоблокировки и сигнализации?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в полгода",
          "Не реже одного раза в квартал",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20309052",
        text: "Какие требования установлены к системам освещения во взрывоопасных помещениях?",
        answers: [
          "Должны предусматриваться системы освещения во взрывобезопасном исполнении",
          "Должны предусматриваться комбинированные системы освещения, работающие от напряжения 42 В",
          "Должно предусматриваться общее локализованное освещение напряжением не выше 220 В",
        ],
        correctAnswers: [
          "Должны предусматриваться системы освещения во взрывобезопасном исполнении",
        ],
      },
      {
        code: "20309053",
        text: "Что должна обеспечить система светозвуковой сигнализации?",
        answers: [
          "Оповещение эксплуатационного персонала о начале и окончании смены",
          "Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств",
          "Оповещение эксплуатационного персонала о приближении технологического транспорта",
        ],
        correctAnswers: [
          "Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств",
        ],
      },
      {
        code: "20309054",
        text: "Какие требования установлены к расположению КИПиА?",
        answers: [
          "Приборы должны устанавливаться на расстоянии не менее 3 м от оборудования",
          "Приборы должны устанавливаться в непосредственной близости к оборудованию",
          "Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования",
        ],
        correctAnswers: [
          "Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования",
        ],
      },
      {
        code: "20309055",
        text: "Какие требования установлены к проверке и испытанию приборов во взрывопожароопасных и пожароопасных помещениях?",
        answers: [
          "Должны производиться в условиях, исключающих искрообразование",
          "Должны производиться только в специальной защитной одежде",
          "Должны производиться в специально выделенном для этого помещении",
        ],
        correctAnswers: [
          "Должны производиться в условиях, исключающих искрообразование",
        ],
      },
      {
        code: "20309056",
        text: "Какие требования установлены к оснащению колошниковой площадки?",
        answers: [
          "Должна быть освещена в вечернее и ночное время и ограждена перилами высотой не менее 1,1 м со сплошной зашивкой стальными листами",
          "Должна быть освещена в вечернее и ночное время, иметь аварийное освещение и съемное ограждение по периметру",
          "Должна быть освещена в темное время суток и иметь решетчатые ограждения высотой не менее 1 м",
        ],
        correctAnswers: [
          "Должна быть освещена в вечернее и ночное время и ограждена перилами высотой не менее 1,1 м со сплошной зашивкой стальными листами",
        ],
      },
      {
        code: "20309057",
        text: "Какой запас прочности должны иметь канаты для подвески и подъема контргрузов?",
        answers: ["Десятикратный", "Восьмикратный", "Четырехкратный"],
        correctAnswers: ["Восьмикратный"],
      },
      {
        code: "20309058",
        text: "Что должно быть установлено в здании подъемника?",
        answers: [
          "Телефонная связь с диспетчерской службой",
          "Прямая телефонная (радио-) связь с колошниковой площадкой и скиповой ямой",
          "Видеосвязь с колошниковой площадкой",
        ],
        correctAnswers: [
          "Прямая телефонная (радио-) связь с колошниковой площадкой и скиповой ямой",
        ],
      },
      {
        code: "20309059",
        text: "Какие требования установлены к подвешиванию скипов?",
        answers: [
          "Скипы должны подвешиваться не менее чем на двух канатах, имеющих шестикратный запас прочности каждый",
          "Скипы должны подвешиваться не менее чем на четырех канатах, имеющих четырехкратный запас прочности каждый",
          "Скипы должны подвешиваться не менее чем на двух канатах, имеющих десятикратный запас прочности каждый",
        ],
        correctAnswers: [
          "Скипы должны подвешиваться не менее чем на двух канатах, имеющих шестикратный запас прочности каждый",
        ],
      },
      {
        code: "20309060",
        text: "Чему подлежат производственные емкости (ковши, шлаковые чаши, совки, кюбели, тара, корзины) после изготовления или ремонта?",
        answers: [
          "Механическим испытаниям",
          "Техническому освидетельствованию",
          "Проверке состояния",
        ],
        correctAnswers: ["Проверке состояния"],
      },
      {
        code: "20309061",
        text: "Чем должны защищаться стальные канаты и цепи грузоподъемных устройств, предназначенные для перемещения ковшей с расплавленным металлом, а также траверсы самих ковшей?",
        answers: [
          "Должны защищаться кожухами от воздействия лучистого тепла",
          "Должны покрываться огнестойкими составами для защиты от тепловой нагрузки",
          "Должны защищаться теплоизолированными материалами от воздействия температур",
        ],
        correctAnswers: [
          "Должны защищаться кожухами от воздействия лучистого тепла",
        ],
      },
      {
        code: "20309062",
        text: "Что должно быть предусмотрено при проектировании конвейеров для подачи материалов? Выберите правильный вариант ответа.",
        answers: [
          "Только исключение падения транспортируемых материалов",
          "Только предохраняющие устройства, отключающие приводы при перегрузке",
          "Только ограждения приводных, натяжных и отклоняющих барабанов, натяжных устройств, канатов и блоков натяжных устройств, ременных, червячных и муфтовых передач",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20309063",
        text: "В каком случае при проектировании крюковых подвесок необходимо предусматривать стопорение гайки дополнительной фиксацией?",
        answers: [
          "С механизмом вращения крюка",
          "С использованием радиальных подшипников",
          "С применением укороченных крюковых подвесок",
          "С применением всех перечисленных устройств",
        ],
        correctAnswers: ["С механизмом вращения крюка"],
      },
      {
        code: "20309064",
        text: "Какие требования установлены к хранению стержней, шаров, футеровки, запасных деталей и приспособлений?",
        answers: [
          "Должно быть организовано в специальном помещении",
          "Должно производиться в любом месте на площадках, обеспечивающих свободный проход к ним",
          "Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре",
        ],
        correctAnswers: [
          "Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре",
        ],
      },
      {
        code: "20309065",
        text: "Какое из перечисленных требований к применению жетон-бирочной системы указано неверно?",
        answers: [
          "Жетон-бирки после разборки электрической схемы ТУ должны передаваться от технологического персонала ремонтным работникам с отметкой всех этапов в журнале",
          "Жетон-бирочная система должна применяться при выводе ТУ на продолжительную остановку",
          "Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями",
          "Жетон-бирочная система применяется для предотвращения травм и аварий в результате ошибочного или несанкционированного включения технических устройств (ТУ)",
        ],
        correctAnswers: [
          "Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями",
        ],
      },
      {
        code: "20309066",
        text: "Какие требования установлены к ведению взрывных работ, хранению, транспортированию взрывчатых материалов и эксплуатации броневых ям?",
        answers: [
          "Работы допускается производить без остановки основного технологического оборудования",
          "Работы должны производиться специально выделенным обслуживающим персоналом",
          "Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах",
        ],
        correctAnswers: [
          "Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах",
        ],
      },
      {
        code: "20309067",
        text: "При наличии каких дефектов ковшей их эксплуатация не допускается?",
        answers: [
          "Только при наличии раковин, трещин в стенках и в местах крепления цапф",
          "Только при наличии качек цапф в теле ковша",
          "Только при наличии повреждений футеровки",
          "При наличии всех перечисленных дефектов, а также не допускается эксплуатация ковшей, потерявших форму вследствие деформации",
        ],
        correctAnswers: [
          "При наличии всех перечисленных дефектов, а также не допускается эксплуатация ковшей, потерявших форму вследствие деформации",
        ],
      },
      {
        code: "20309068",
        text: "Чем должны быть оборудованы места пересечения железнодорожных путей для подачи составов ковшей с жидким чугуном с путями для подачи шихтовых материалов?",
        answers: [
          "Должны быть оборудованы схемами разъезда по направлению путей",
          "Должны быть оборудованы автоматической сигнализацией",
          "Должны быть оборудованы стрелочными постами",
        ],
        correctAnswers: [
          "Должны быть оборудованы автоматической сигнализацией",
        ],
      },
      {
        code: "20309069",
        text: "Какие требования предъявляются к устройству и эксплуатации печей для обжига шихтовых материалов и концентратов?",
        answers: [
          "Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции",
          "Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 5%",
          "Разрешается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 10%",
        ],
        correctAnswers: [
          "Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции",
        ],
      },
      {
        code: "20309070",
        text: "В каких местах осуществляется дозировка шихтовых материалов и смешивание их с алюминиевым порошком и селитрой во вновь строящихся и реконструируемых металлотермических цехах?",
        answers: [
          "Непосредственно возле плавильной печи",
          "В помещениях, где производится дробление и размол материалов",
          "В отдельных помещениях",
          "В помещениях хранения сыпучих материалов",
        ],
        correctAnswers: ["В отдельных помещениях"],
      },
      {
        code: "20309071",
        text: "Какие требования установлены к загружаемым в печи руде, ферросплавам и другим материалам?",
        answers: [
          "Должны быть предварительно просушены или прокалены",
          "Должны быть выдержаны в помещении не менее суток",
          "Должны быть разделены на части и увлажнены",
        ],
        correctAnswers: ["Должны быть предварительно просушены или прокалены"],
      },
      {
        code: "20309072",
        text: "Какие требования установлены к процессу сливания расплава металла из ковшей и миксеров? Выберите правильный вариант ответа.",
        answers: [
          "Сливание должно быть механизировано",
          "Пол рабочей площадки в местах установки ковшей должен быть сухим",
          "Ось сливного отверстия ковша, установленного под слив, должна совпадать с осью заливочного ковша",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20309073",
        text: "Что не допускается к загрузке в агрегаты, содержащие расплавленный металл или шлак?",
        answers: [
          "Загрузка пакетированного металлолома",
          "Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции",
          "Загрузка шихты, не прошедшей пиротехнического контроля",
        ],
        correctAnswers: [
          "Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции",
        ],
      },
      {
        code: "20309074",
        text: "Какие требования установлены к выгрузке шихтовых материалов на рудном дворе?",
        answers: [
          "Выгрузка должна быть механизирована",
          "Выгрузка должна осуществляться с применением стального инструмента",
          "Выгрузка может быть организована ручным способом",
        ],
        correctAnswers: ["Выгрузка должна быть механизирована"],
      },
      {
        code: "20309075",
        text: "Какие требования установлены к конусным и бесконусным засыпным аппаратам?",
        answers: [
          "Засыпные аппараты должны быть надежными и рассчитаны на двукратное рабочее давление газа под колошником",
          "Засыпные аппараты должны быть надежными и рассчитаны на полуторакратное рабочее давление газа под колошником",
          "Засыпные аппараты должны быть герметичными и рассчитаны на рабочее давление газа под колошником",
        ],
        correctAnswers: [
          "Засыпные аппараты должны быть герметичными и рассчитаны на рабочее давление газа под колошником",
        ],
      },
      {
        code: "20309076",
        text: "Какое требование установлено к устройству выходных отверстий продувочных свечей на доменных печах?",
        answers: [
          "Должно быть выше верхних площадок колошника не менее чем на 4 м",
          "Должно быть выше верхних площадок колошника не менее чем на 2 м",
          "Должно быть выше верхних площадок колошника на 3 м",
        ],
        correctAnswers: [
          "Должно быть выше верхних площадок колошника не менее чем на 4 м",
        ],
      },
      {
        code: "20309077",
        text: "Как часто должны производиться проверки приборов измерения уровня засыпи печи?",
        answers: [
          "Не реже одного раза в неделю",
          "Не реже двух раз в месяц",
          "Не реже одного раза в месяц",
        ],
        correctAnswers: ["Не реже двух раз в месяц"],
      },
      {
        code: "20309078",
        text: "Какая допускается предельная температура колошникового газа в случае задержки загрузки печи?",
        answers: [
          "Не должна превышать 500 °С",
          "Не должна превышать 600 °С",
          "Должна составлять 550 °С",
        ],
        correctAnswers: ["Не должна превышать 500 °С"],
      },
      {
        code: "20309079",
        text: "При каком содержании кислорода в колошниковом газе закрытых рудовосстановительных печей печь немедленно отключается?",
        answers: [
          "При увеличении до 1%",
          "При увеличении до 3%",
          "При увеличении до 2%",
        ],
        correctAnswers: ["При увеличении до 2%"],
      },
      {
        code: "20309080",
        text: "Что должны обеспечивать системы охлаждения горна и лещади доменной печи?",
        answers: [
          "Должны обеспечивать перепад температуры не более 3 °С",
          "Должны обеспечивать перепад температуры не более 5 °С",
          "Должны обеспечивать перепад температуры не более 4 °С",
        ],
        correctAnswers: [
          "Должны обеспечивать перепад температуры не более 3 °С",
        ],
      },
      {
        code: "20309081",
        text: "Из каких материалов должны быть выполнены здания литейных дворов и поддоменников?",
        answers: [
          "Из цементно-стружечных плит",
          "Из любых строительных материалов",
          "Из огнестойких материалов",
        ],
        correctAnswers: ["Из огнестойких материалов"],
      },
      {
        code: "20309082",
        text: "Чем должны быть защищены опорные колонны печи здания литейного двора и поддоменника от возможного воздействия чугуна и шлака?",
        answers: [
          "Должны быть защищены решетчатыми металлическими ограждениями",
          "Должны быть защищены огнеупорным материалом",
          "Должны быть защищены плитами из поликарбоната",
        ],
        correctAnswers: ["Должны быть защищены огнеупорным материалом"],
      },
      {
        code: "20309083",
        text: "С какой периодичностью должны производиться замеры температуры кожухов воздухонагревателя?",
        answers: [
          "Постоянно (не реже одного раза в неделю)",
          "Регулярно (не реже двух раз в месяц)",
          "Систематически (не реже одного раза в месяц)",
        ],
        correctAnswers: ["Систематически (не реже одного раза в месяц)"],
      },
      {
        code: "20309084",
        text: "Как часто комиссионно должно проверяться техническое состояние воздухонагревателей и их арматура?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в 3 месяца",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20309085",
        text: "С какой периодичностью должны производиться замеры аэродинамического сопротивления насадки воздухонагревателей?",
        answers: ["Ежеквартально", "Ежегодно", "Ежемесячно"],
        correctAnswers: ["Ежегодно"],
      },
      {
        code: "20309086",
        text: "Какие требования установлены к конструкции клапанов, перекрывающих поступление газа к горелкам?",
        answers: [
          'Конструкция должна обеспечивать класс герметичности запорной арматуры "А"',
          "Конструкция должна обеспечивать перекрытие газопровода в течение 10 секунд",
          "Конструкция должна обеспечивать плотное перекрытие газопровода",
        ],
        correctAnswers: [
          "Конструкция должна обеспечивать плотное перекрытие газопровода",
        ],
      },
      {
        code: "20309087",
        text: "Какие требования установлены к диаметру лазов пылеуловителей?",
        answers: [
          "Диаметр не менее 600 мм",
          "Диаметр не менее 400 мм",
          "Диаметр не менее 500 мм",
        ],
        correctAnswers: ["Диаметр не менее 600 мм"],
      },
      {
        code: "20309088",
        text: "После чего должны производиться работы по ремонту пылевыпускного клапана?",
        answers: [
          "После установки листовой заглушки над пылевыпускным клапаном с соблюдением мер безопасности",
          "После перекрытия запорной арматуры",
          "После остановки работы печи",
        ],
        correctAnswers: [
          "После установки листовой заглушки над пылевыпускным клапаном с соблюдением мер безопасности",
        ],
      },
      {
        code: "20309089",
        text: "Каким инструментом выполняется взятие проб жидкого чугуна на химический анализ?",
        answers: [
          "Инструментом, изготовленным из огнестойких материалов",
          "Просушенным и подогретым инструментом",
          "Инструментом, обладающим термостойкими качествами",
        ],
        correctAnswers: ["Просушенным и подогретым инструментом"],
      },
      {
        code: "20309090",
        text: "При каких условиях осуществляется пользование механизмами передвижения ковшей?",
        answers: [
          "Только после проверки изношенности механизмов",
          "Только при исправной звуковой и световой сигнализации",
          "Только при наличии сигнализации с автозапуском",
        ],
        correctAnswers: [
          "Только при исправной звуковой и световой сигнализации",
        ],
      },
      {
        code: "20309091",
        text: "На каком расстоянии не допускается производство работ на путях от стоящих под наливом ковшей?",
        answers: [
          "На расстоянии ближе 15 м",
          "На расстоянии ближе 12 м",
          "На расстоянии ближе 10 м",
        ],
        correctAnswers: ["На расстоянии ближе 15 м"],
      },
      {
        code: "20309092",
        text: "На каком расстоянии от кантуемых ковшей должен размещаться пульт управления на шлаковом отвале?",
        answers: [
          "На расстоянии не менее 5 м",
          "На расстоянии не менее 8 м",
          "На расстоянии не менее 10 м",
        ],
        correctAnswers: ["На расстоянии не менее 10 м"],
      },
      {
        code: "20309093",
        text: "Чем должны быть перекрыты приемные бункера грануляционных установок?",
        answers: [
          "Предохранительными решетками с ячейками не более 200 х 200 мм",
          "Предохранительными решетками с ячейками не более 230 х 230 мм",
          "Предохранительными решетками с ячейками не более 300 х 300 мм",
        ],
        correctAnswers: [
          "Предохранительными решетками с ячейками не более 200 х 200 мм",
        ],
      },
      {
        code: "20309094",
        text: "Где должна производиться грануляция шлака?",
        answers: [
          "В специальных грануляционных бассейнах, установленных на шлаковом отвале",
          "В специальных барабанных грануляционных системах, расположенных на территории цеха",
          "В специальных герметизированных установках или в грануляционных бассейнах за пределами цеха",
        ],
        correctAnswers: [
          "В специальных герметизированных установках или в грануляционных бассейнах за пределами цеха",
        ],
      },
      {
        code: "20309095",
        text: "Чем должны быть ограждены грануляционные бассейны?",
        answers: [
          "Сплошным ограждением высотой не менее 1 м",
          "Перилами высотой не менее 1,1 м",
          "Металлическими решетчатыми ограждениями высотой не менее 0,9 м",
        ],
        correctAnswers: ["Перилами высотой не менее 1,1 м"],
      },
      {
        code: "20309096",
        text: "Какие требования предъявляются к предохранительным решеткам бункера?",
        answers: [
          "Должны быть оборудованы предохранительными решетками с ячейками размером не более 300 х 300 мм",
          "Должны быть оборудованы предохранительными решетками с ячейками размером не более 400 х 400 мм",
          "Должны быть оборудованы предохранительными решетками с ячейками размером не более 200 х 300 мм",
        ],
        correctAnswers: [
          "Должны быть оборудованы предохранительными решетками с ячейками размером не более 300 х 300 мм",
        ],
      },
      {
        code: "20309097",
        text: "Чем должны быть оснащены доменные печи?",
        answers: [
          "Автоматизированной системой контроля, управления и диагностики технологического процесса",
          "Системой дистанционного управления",
          "Системой диагностики производственного процесса",
        ],
        correctAnswers: [
          "Автоматизированной системой контроля, управления и диагностики технологического процесса",
        ],
      },
      {
        code: "20309098",
        text: "Как должна производиться установка мульд на стеллажах?",
        answers: [
          "С уклоном в сторону завалочной машины",
          "Со смещением ближе к одной из сторон стеллажа",
          "Без свесов и перекосов",
        ],
        correctAnswers: ["Без свесов и перекосов"],
      },
      {
        code: "20309099",
        text: "Как должна производиться заливка чугуна в миксер?",
        answers: [
          "В центр окна равномерной струей с минимальной высоты",
          "С помощью специальной воронки",
          "Медленно с кратковременными перерывами",
        ],
        correctAnswers: [
          "В центр окна равномерной струей с минимальной высоты",
        ],
      },
      {
        code: "20309100",
        text: "Как и кем должны проверяться состояние и исправность миксера?",
        answers: [
          "Должны проверяться миксеровым перед каждым запуском работы миксера",
          "Должны проверяться два раза в смену исполнителем работ",
          "Должны ежесменно проверяться миксеровым с записью результатов осмотра в журнале",
        ],
        correctAnswers: [
          "Должны ежесменно проверяться миксеровым с записью результатов осмотра в журнале",
        ],
      },
      {
        code: "20309101",
        text: "В какой сталеплавильный агрегат завалка металлической стружки не допускается?",
        answers: [
          "В агрегат конверторного типа",
          "В двухванный агрегат",
          "В тигельную печь",
        ],
        correctAnswers: ["В двухванный агрегат"],
      },
      {
        code: "20309102",
        text: "Что должно быть выполнено во время завалки материалов и подвалки шихты?",
        answers: [
          "Должны быть выполнены требования выполнения производственного процесса согласно акту-наряду",
          "Печь должна быть остановлена, а работники удалены с производственной площадки",
          "Должны быть реализованы мероприятия по безопасности работников",
        ],
        correctAnswers: [
          "Должны быть реализованы мероприятия по безопасности работников",
        ],
      },
      {
        code: "20309103",
        text: "В каких случаях не допускается завалка лома в конвертер?",
        answers: [
          "При наличии в нем жидкого шлака",
          "При наличии в нем остатков жидкого металла",
          "При наличии в нем отделяемой шлаковой корки",
        ],
        correctAnswers: ["При наличии в нем жидкого шлака"],
      },
      {
        code: "20309104",
        text: "После выполнения каких мероприятий должна производиться заливка чугуна в печь?",
        answers: [
          "После подготовки и установки шлаковых ковшей",
          "Только после полного закрытия сталевыпускного отверстия, установки сталевыпускного желоба и шлаковых ковшей",
          "После отключения горелок",
        ],
        correctAnswers: [
          "Только после полного закрытия сталевыпускного отверстия, установки сталевыпускного желоба и шлаковых ковшей",
        ],
      },
      {
        code: "20309105",
        text: "После выполнения каких мероприятий разрешается производить работы на своде мартеновской печи?",
        answers: [
          "Только после выпуска плавки и до заливки чугуна в печь",
          "Только после полной остановки работы печи",
          "Только после остановки печи на текущий ремонт",
        ],
        correctAnswers: [
          "Только после выпуска плавки и до заливки чугуна в печь",
        ],
      },
      {
        code: "20309106",
        text: "С какой периодичностью должна проверяться герметичность сводов и стен регенераторов, работающих с подогревом газа в них?",
        answers: ["Два раза в сутки", "Ежесменно", "Еженедельно"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20309107",
        text: "Как часто должно проверяться состояние системы охлаждения печей?",
        answers: ["Еженедельно", "Ежеквартально", "Ежесменно"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20309108",
        text: "Какому давлению гидравлического испытания должны подвергаться водоохлаждаемые элементы печей перед их установкой?",
        answers: [
          "На 1,5 величины рабочего давления охлаждающей воды",
          "На 1,25 величины рабочего давления охлаждающей воды",
          "На 1,6 величины рабочего давления охлаждающей воды",
        ],
        correctAnswers: ["На 1,5 величины рабочего давления охлаждающей воды"],
      },
      {
        code: "20309109",
        text: "Каким гидравлическим давлением должны испытываться на прочность и плотность трубки системы охлаждения индуктора?",
        answers: [
          "На 1,15 величины рабочего давления охлаждающей воды",
          "На 1,5 величины рабочего давления охлаждающей воды",
          "На 1,25 величины рабочего давления охлаждающей воды",
        ],
        correctAnswers: ["На 1,5 величины рабочего давления охлаждающей воды"],
      },
      {
        code: "20309110",
        text: "Каким гидравлическим давлением должны быть испытаны на прочность и плотность трубки индуктора?",
        answers: [
          "Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 2 раза",
          "Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 1,5 раза",
          "Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 1,25 раза",
          "Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 2,5 раза",
        ],
        correctAnswers: [
          "Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 1,5 раза",
        ],
      },
      {
        code: "20309111",
        text: 'Какому гидравлическому испытанию должны подвергаться водоохлаждаемые элементы печи "Аусмелт" перед их установкой?',
        answers: [
          "На 1,15 величины рабочего давления охлаждающей жидкости",
          "На 1,25 величины рабочего давления охлаждающей жидкости",
          "На 1,5 величины рабочего давления охлаждающей жидкости",
          "На 1,6 величины рабочего давления охлаждающей жидкости",
        ],
        correctAnswers: [
          "На 1,5 величины рабочего давления охлаждающей жидкости",
        ],
      },
      {
        code: "20309112",
        text: 'Какой напорный бак должен быть установлен в печи "Аусмелт" на случай отключения электроснабжения?',
        answers: [
          "Обеспечивающий подачу воды в течение 10 минут с момента отказа системы циркуляционного водяного охлаждения",
          "Обеспечивающий подачу воды в течение 5 минут с момента отказа системы циркуляционного водяного охлаждения",
          "Обеспечивающий подачу воды в течение 25 минут с момента отказа системы циркуляционного водяного охлаждения",
          "Обеспечивающий подачу воды в течение 15 минут с момента отказа системы циркуляционного водяного охлаждения",
        ],
        correctAnswers: [
          "Обеспечивающий подачу воды в течение 15 минут с момента отказа системы циркуляционного водяного охлаждения",
        ],
      },
      {
        code: "20309113",
        text: 'Подача шихты с какой влажностью запрещается в печь "Аусмелт"?',
        answers: [
          "Более 5% по массе",
          "Более 7% по массе",
          "Более 8% по массе",
          "Более 3% по массе",
        ],
        correctAnswers: ["Более 8% по массе"],
      },
      {
        code: "20309114",
        text: 'До какой минимальной температуры должна быть охлаждена печь "Аусмелт" для возобновления подачи охлаждающей жидкости при перегреве печи и неисправности водоохлаждающих элементов?',
        answers: ["100 °C", "150 °C", "200 °C", "120 °C"],
        correctAnswers: ["150 °C"],
      },
      {
        code: "20309115",
        text: 'В каком из перечисленных случаев запрещено возобновлять подачу воды к любому из водоохлаждаемых компонентов печи "Аусмелт"?',
        answers: [
          "Если температура этих компонентов превышает 120 °C",
          "Если температура этих компонентов превышает 100 °C",
          "Если температура этих компонентов превышает 60 °C",
          "Если температура этих компонентов превышает 150 °C",
        ],
        correctAnswers: ["Если температура этих компонентов превышает 150 °C"],
      },
      {
        code: "20309116",
        text: "В каком случае заправка мартеновских печей и двухванных сталеплавильных агрегатов не допускается?",
        answers: [
          "При открытом отверстии в площадке для спуска шлака",
          "При неисправности запорного оборудования",
          "При отключенных блокировочных устройствах",
        ],
        correctAnswers: ["При открытом отверстии в площадке для спуска шлака"],
      },
      {
        code: "20309117",
        text: "Чем должна быть продута горелка перед включением и подачей газа?",
        answers: ["Сжатым воздухом", "Азотом", "Кислородом"],
        correctAnswers: ["Кислородом"],
      },
      {
        code: "20309118",
        text: "Какие ручки должен иметь металлический инструмент, применяемый при обслуживании индукционных печей?",
        answers: [
          "Электроизолированные ручки",
          "Деревянные ручки",
          "Стальные ручки",
        ],
        correctAnswers: ["Электроизолированные ручки"],
      },
      {
        code: "20309119",
        text: "Какие мероприятия должны быть выполнены при уходе металла из тигля?",
        answers: [
          "Жидкий металл должен быть слит для последующего застывания",
          "Работа печи должна быть остановлена на сутки",
          "Печь должна быть отключена, жидкий металл - слит в изложницу",
        ],
        correctAnswers: [
          "Печь должна быть отключена, жидкий металл - слит в изложницу",
        ],
      },
      {
        code: "20309120",
        text: "Какие мероприятия должны быть выполнены при прогаре водоохлаждаемых элементов печи и попадании воды в зону плавления печи?",
        answers: [
          "Должен быть снижен объем загрузки в печь",
          "Печь должна быть немедленно отключена",
          "Подача воды должна быть приостановлена",
        ],
        correctAnswers: ["Печь должна быть немедленно отключена"],
      },
      {
        code: "20309121",
        text: "В каких случаях не допускается отключение системы охлаждения кристаллизатора?",
        answers: [
          "До выгрузки слитка из камеры",
          "До охлаждения камеры до температуры 45 °С",
          "До окончания процесса разгрузки камеры",
        ],
        correctAnswers: ["До выгрузки слитка из камеры"],
      },
      {
        code: "20309122",
        text: "Чем должен быть покрыт пол пульта управления электронно-лучевой печью?",
        answers: [
          "Теплоизоляционным покрытием",
          "Электроизолирующим материалом, на который должно быть нанесено клеймо испытания",
          "Нескользящим материалом",
        ],
        correctAnswers: [
          "Электроизолирующим материалом, на который должно быть нанесено клеймо испытания",
        ],
      },
      {
        code: "20309123",
        text: "При каких условиях должны производиться все работы по подготовке плавки?",
        answers: [
          "При запуске печи на начальном этапе",
          "При достижении оптимальной температуры печи",
          "Только при отключенной печи (установке)",
        ],
        correctAnswers: ["Только при отключенной печи (установке)"],
      },
      {
        code: "20309124",
        text: "Что обязаны проверить сталевар и ответственные лица перед включением печи на плавку?",
        answers: [
          "Исправность систем автоматизации",
          "Исправность оборудования, футеровки и свода печи",
          "Исправность систем газоснабжения",
        ],
        correctAnswers: ["Исправность оборудования, футеровки и свода печи"],
      },
      {
        code: "20309125",
        text: "Что должно быть у каждого плавильного агрегата с выпуском металла через летку?",
        answers: [
          "Должны быть две штанги длиной не менее 1 м и запасные пробки для закрывания выпускного отверстия",
          "Должны быть две штанги длиной не менее 1,5 м и запасные пробки для закрывания леток",
          "Должны быть две штанги длиной не менее 1,2 м и инструмент для подрубки наружной глиняной пробки летки",
        ],
        correctAnswers: [
          "Должны быть две штанги длиной не менее 1,5 м и запасные пробки для закрывания леток",
        ],
      },
      {
        code: "20309126",
        text: "Каков предельно допустимый износ цапф конвертера во всех измерениях в случае применения подшипников скольжения?",
        answers: [
          "Не должен превышать 10% их первоначальных размеров",
          "Не должен превышать 12% их первоначальных размеров",
          "Не должен превышать 15% их первоначальных размеров",
        ],
        correctAnswers: ["Не должен превышать 10% их первоначальных размеров"],
      },
      {
        code: "20309127",
        text: "Как часто должна производиться проверка состояния механизма поворота конвертера?",
        answers: [
          "Два раза в смену",
          "Ежесменно",
          "Постоянно, перед каждой плавкой",
        ],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20309128",
        text: "Что должно регулярно производиться в корпусах обезвоживания шлама?",
        answers: [
          "Гидросмыв шлама с рабочих площадок и строительных конструкций",
          "Механическое удаление шлама с рабочих площадок и строительных конструкций",
          "Влажная уборка рабочих площадок и конструкций от пыли",
        ],
        correctAnswers: [
          "Гидросмыв шлама с рабочих площадок и строительных конструкций",
        ],
      },
      {
        code: "20309129",
        text: "Как должен быть подготовлен газоотводящий тракт конвертера к ремонту?",
        answers: [
          "Должен быть продут сжатым воздухом",
          "Должен быть надежно отключен от общих коллекторов и боровов",
          "Должен быть разгерметизирован",
        ],
        correctAnswers: [
          "Должен быть надежно отключен от общих коллекторов и боровов",
        ],
      },
      {
        code: "20309130",
        text: "Что должно в обязательном порядке производиться при категорировании зданий и помещений?",
        answers: [
          "Анализ опасностей и оценка риска аварий",
          "Расчет значений тротилового эквивалента мощности взрыва",
          "Расчет избыточного давления взрыва с учетом наиболее опасной модели аварийной ситуации",
        ],
        correctAnswers: [
          "Расчет избыточного давления взрыва с учетом наиболее опасной модели аварийной ситуации",
        ],
      },
      {
        code: "20309131",
        text: "Что необходимо делать во время работы мельницы в помольном помещении?",
        answers: [
          "Включать приточно-вытяжную вентиляцию при закрытых дверях",
          "Закрывать все двери и включать световое предупредительное табло",
          "Включать дополнительное рабочее освещение",
        ],
        correctAnswers: [
          "Закрывать все двери и включать световое предупредительное табло",
        ],
      },
      {
        code: "20309132",
        text: "В присутствии какого лица должна производиться присадка легковоспламеняющихся порошковых материалов, а также смесей на их основе в печь или ковш?",
        answers: [
          "В присутствии ответственного лица, назначенного распоряжением по цеху",
          "В присутствии сменного литейщика",
          "В присутствии начальника участка работ",
        ],
        correctAnswers: [
          "В присутствии ответственного лица, назначенного распоряжением по цеху",
        ],
      },
      {
        code: "20309133",
        text: "Какой запас прочности должны иметь цапфы ковшей, предназначенных для расплава металла?",
        answers: [
          "Не менее восьмикратного запаса прочности",
          "Не менее шестикратного запаса прочности",
          "Не менее четырехкратного запаса прочности",
        ],
        correctAnswers: ["Не менее восьмикратного запаса прочности"],
      },
      {
        code: "20309134",
        text: "Как часто цапфы ковшей должны проверяться методом неразрушающего контроля?",
        answers: [
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в месяц",
          "Не реже одного раза в год",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20309135",
        text: "Каков допустимый износ цапф ковшей во всех измерениях?",
        answers: [
          "Не должен превышать 15% первоначальных размеров",
          "Не должен превышать 12% первоначальных размеров",
          "Не должен превышать 10% первоначальных размеров",
        ],
        correctAnswers: ["Не должен превышать 10% первоначальных размеров"],
      },
      {
        code: "20309136",
        text: "Какие требования установлены к управлению стопорами и шиберными затворами ковшей?",
        answers: [
          "Управление должно быть автоматизированным",
          "Управление должно быть дистанционным",
          "Управление должно быть синхронным",
        ],
        correctAnswers: ["Управление должно быть дистанционным"],
      },
      {
        code: "20309137",
        text: "С помощью каких устройств должно производиться удаление пыли из изложниц?",
        answers: [
          "С помощью промывочного насоса",
          "С помощью продувочного оборудования",
          "С помощью пылеотсасывающих устройств",
        ],
        correctAnswers: ["С помощью пылеотсасывающих устройств"],
      },
      {
        code: "20309138",
        text: "Как должна производиться разделка сталевыпускного отверстия?",
        answers: [
          "Должна производиться под руководством начальника смены",
          "Должна производиться только при наличии под желобом ковшей, а в разливочном пролете - состава с изложницами",
          "Должна производиться только с использованием металлического инструмента",
        ],
        correctAnswers: [
          "Должна производиться только при наличии под желобом ковшей, а в разливочном пролете - состава с изложницами",
        ],
      },
      {
        code: "20309139",
        text: "Что должно исключаться при присадке раскислителей в ковш?",
        answers: [
          "Повреждение стопорного устройства",
          "Подача в ковш порошкообразных раскислителей",
          "Механизированная присадка",
        ],
        correctAnswers: ["Повреждение стопорного устройства"],
      },
      {
        code: "20309140",
        text: "Какие требования установлены к сцепке и расцепке сталевозной тележки?",
        answers: [
          "Должны производиться вручную с применением сцепных устройств",
          "Должны быть с автоматическим гидрораспределителем",
          "Должны быть дистанционными",
        ],
        correctAnswers: ["Должны быть дистанционными"],
      },
      {
        code: "20309141",
        text: "Какие требования установлены к раздеванию слитков с помощью напольных машин или кранов?",
        answers: [
          "Необходимо осуществлять только после полного затвердевания слитков",
          "Необходимо осуществлять после охлаждения слитков до температуры не выше 300 °С",
          "Необходимо осуществлять в специальном помещении после образования твердой корки",
        ],
        correctAnswers: [
          "Необходимо осуществлять только после полного затвердевания слитков",
        ],
      },
      {
        code: "20309142",
        text: "Какие требования установлены к укладке слитков?",
        answers: [
          "Укладка должна производиться в специальные закрома складских помещений",
          "Укладка должна производиться на выделенных площадках конвертерных цехов",
          "Укладка должна производиться на специальные стеллажи, исключающие раскатывание слитков",
        ],
        correctAnswers: [
          "Укладка должна производиться на специальные стеллажи, исключающие раскатывание слитков",
        ],
      },
      {
        code: "20309143",
        text: "Какие требования установлены к срокам осмотра баков (емкостей) для смазочных материалов и баков для варки смолы и к осмотру и чистке смотровых люков и вытяжных труб баков для варки смолы?",
        answers: [
          "Осмотр должен производиться не реже двух раз в месяц, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - еженедельно",
          "Осмотр должен производиться не реже одного раза в полугодие, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежесменно",
          "Осмотр должен производиться не реже одного раза в год, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно",
        ],
        correctAnswers: [
          "Осмотр должен производиться не реже одного раза в год, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно",
        ],
      },
      {
        code: "20309144",
        text: "Какие требования установлены к скорости движения железнодорожного транспорта на шлаковом дворе?",
        answers: [
          "Скорость не должна превышать 5 км/ч",
          "Скорость не должна превышать 8 км/ч",
          "Скорость не должна превышать 12 км/ч",
        ],
        correctAnswers: ["Скорость не должна превышать 5 км/ч"],
      },
      {
        code: "20309145",
        text: "Какие требования установлены к сливу шлака в шлаковую яму?",
        answers: [
          "Должен производиться свободно падающей струей",
          "Должен производиться равномерной струей",
          "Должен выполняться кантовкой одновременно нескольких ковшей",
        ],
        correctAnswers: ["Должен производиться равномерной струей"],
      },
      {
        code: "20309146",
        text: "Где должен находиться работник при ручной подправке металла на ножницах?",
        answers: ["Спереди ножниц", "Сзади ножниц", "Сбоку от ножниц"],
        correctAnswers: ["Сбоку от ножниц"],
      },
      {
        code: "20309147",
        text: "Какая должна быть температура в помещениях в холодное время года там, где осуществляются мокрые производственные процессы?",
        answers: ["Не ниже 20 °C", "Не ниже 16 °C", "Не ниже 18 °C"],
        correctAnswers: ["Не ниже 16 °C"],
      },
      {
        code: "20309148",
        text: "Чем должны быть оборудованы ванны с кислотой для травления оборудования?",
        answers: [
          "Средствами для защиты органов дыхания",
          "Магистральными фильтрами",
          "Местными отсосами",
          "Ручным насосом",
        ],
        correctAnswers: ["Местными отсосами"],
      },
      {
        code: "20309149",
        text: "Какие требования предъявляются к корзинам, загружаемым в щелочные ванны?",
        answers: [
          "Должны быть не влажные",
          "Должны быть изготовлены только из нержавеющей стали",
          "Должны быть оборудованы съемными подвесными приспособлениями",
        ],
        correctAnswers: ["Должны быть не влажные"],
      },
      {
        code: "20309150",
        text: "Как должны подготавливаться растворы кислот и щелочей?",
        answers: [
          "Вода комнатной температуры должна заливаться равномерной струей в кислоту и щелочь",
          "Кислота и щелочь должны заливаться небольшой струей в воду, нагретую до температуры 60 °С",
          "Кислота и щелочь должны заливаться небольшой струей в холодную воду",
        ],
        correctAnswers: [
          "Кислота и щелочь должны заливаться небольшой струей в холодную воду",
        ],
      },
      {
        code: "20309151",
        text: "Каков порядок приготовления растворов серной кислоты?",
        answers: [
          "При приготовлении растворов серной кислоты сначала необходимо заливать воду, а затем кислоту",
          "При приготовлении смеси кислот воду и серную кислоту следует заливать одновременно",
          "При приготовлении растворов серной кислоты сначала необходимо заливать кислоту, а затем добавлять воду",
        ],
        correctAnswers: [
          "При приготовлении растворов серной кислоты сначала необходимо заливать воду, а затем кислоту",
        ],
      },
      {
        code: "20309152",
        text: "Какое из перечисленных требований при производстве глинозема указано неверно?",
        answers: [
          "Приводной механизм вращения печей спекания и кальцинации оборудуется резервным двигателем с независимым источником питания",
          "Допускается открывание люков на топках и газораспределительных камерах во время работы печей кипящего слоя в присутствии наблюдающего",
          "Шуровка материала в печах производится при разрежении в печи, исключающем выбросы в рабочую зону",
          "При заливке, перемешивании расплава и удалении шлама напряжение с электродов на миксерах, печах и хлораторах должно быть снято",
        ],
        correctAnswers: [
          "Допускается открывание люков на топках и газораспределительных камерах во время работы печей кипящего слоя в присутствии наблюдающего",
        ],
      },
      {
        code: "20309153",
        text: "Какие требования безопасности при электролитическом производстве и рафинировании алюминия указаны неверно?",
        answers: [
          'Система электроизоляции в корпусах электролиза должна исключать наличие потенциала "земля" в ремонтных зонах напольных рельсовых машин',
          "Перекрытия проемов реконструируемых электролизеров должны быть электроизолированы от кожухов соседних электролизеров",
          "Рельсы напольных машин для обслуживания электролизеров должны иметь электроизоляционные вставки между соседними в ряду электролизерами. Сопротивление изоляции вставок должно быть не более 500 кОм",
          "Сопротивление изоляции элементов напольных рельсовых машин должно быть не менее величин, указанных в проекте, разработанном для данного производства",
        ],
        correctAnswers: [
          "Рельсы напольных машин для обслуживания электролизеров должны иметь электроизоляционные вставки между соседними в ряду электролизерами. Сопротивление изоляции вставок должно быть не более 500 кОм",
        ],
      },
      {
        code: "20309154",
        text: "В каком случае проводится внеочередное обследование кожухов доменных печей и воздухонагревателей?",
        answers: [
          "Только в случае выявления при эксплуатационных осмотрах необъяснимого роста повреждений и опасности общего разрушения кожуха",
          "Только после аварии в цехе, связанной с огневым (пожар) или механическим (обрушение конструкций) воздействием на кожух печи",
          "Только при намечаемой реконструкции печи",
          "Только при увеличении нормируемых проектных показателей и (или) климатических воздействий (рост районной сейсмики, рост рабочего давления, переход на цинкосодержащие руды)",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20309155",
        text: "Какое количество выходов должен иметь поддоменник, не считая выхода на литейный двор?",
        answers: [
          "Только 3 выхода",
          "1 выход",
          "Не менее чем 2 выхода",
          "Не более чем 2 выхода",
        ],
        correctAnswers: ["Не менее чем 2 выхода"],
      },
      {
        code: "20309156",
        text: "Какое из перечисленных требований к воздухонагревателям в доменном производстве указано неверно?",
        answers: [
          "Конструкция подземных боровов воздухонагревателей должна исключать возможность попадания в них грунтовых вод",
          "Нагрев воздухонагревателей должен производиться очищенным газом. При неисправности газового дросселя перевод воздухонагревателя на нагрев не допускается",
          "Продолжительность проветривания воздухонагревателя перед повторным зажиганием газа и перед постановкой его на дутьевой режим определяется расчетом и регламентируется технологической инструкцией, но должна быть не более одной минуты",
          "Воздухонагреватели должны иметь отдельную дымовую трубу",
        ],
        correctAnswers: [
          "Продолжительность проветривания воздухонагревателя перед повторным зажиганием газа и перед постановкой его на дутьевой режим определяется расчетом и регламентируется технологической инструкцией, но должна быть не более одной минуты",
        ],
      },
      {
        code: "20309157",
        text: "На какую величину с возвышением внешнего рельса со стороны слива по отношению к другому должны укладываться пути для шлака на отвале?",
        answers: [
          "Не более 150 мм",
          "Не более 160 мм",
          "Не более 170 мм",
          "Не более 180 мм",
        ],
        correctAnswers: ["Не более 150 мм"],
      },
      {
        code: "20309158",
        text: "На каком расстоянии от бровки отвала должна располагаться ось пути для шлака на отвале?",
        answers: [
          "Не менее 1,2 м",
          "Не менее 1,4 м",
          "Не менее 1,3 м",
          "Не менее 1,1 м",
        ],
        correctAnswers: ["Не менее 1,4 м"],
      },
      {
        code: "20309159",
        text: "Какие независимые технологические линии должны иметь в своем составе установки придоменной грануляции шлака? Выберите два варианта ответа.",
        answers: [
          "Рабочую независимую технологическую линию",
          "Резервную независимую технологическую линию",
          "Запасную независимую технологическую линию",
          "Дополнительную независимую технологическую линию",
        ],
        correctAnswers: [
          "Рабочую независимую технологическую линию",
          "Резервную независимую технологическую линию",
        ],
      },
      {
        code: "20309160",
        text: "Какое из перечисленных требований к отводу шлакового желоба на резервную линию установки придоменной грануляции шлака указано верно?",
        answers: [
          "Отвод шлакового желоба на резервную линию должен быть перекрыт отсечным устройством и на длине не менее 1 м засыпан песком на 100 мм выше бортов желоба",
          "Отвод шлакового желоба на резервную линию должен быть перекрыт отсечным устройством и на длине не более 1,5 м засыпан песком на 200 мм выше бортов желоба",
          "Отвод шлакового желоба на резервную линию должен быть перекрыт отсечным устройством и на длине не менее 0,5 м засыпан песком на 150 мм выше бортов желоба",
        ],
        correctAnswers: [
          "Отвод шлакового желоба на резервную линию должен быть перекрыт отсечным устройством и на длине не менее 1 м засыпан песком на 100 мм выше бортов желоба",
        ],
      },
      {
        code: "20309161",
        text: "До какой температуры должны быть охлаждены системы оборотного водоснабжения установок придоменной грануляции шлака для производства работ по их ремонту?",
        answers: [
          "Не выше 40 °C",
          "Не выше 42 °C",
          "Не выше 43 °C",
          "Не выше 45 °C",
        ],
        correctAnswers: ["Не выше 40 °C"],
      },
      {
        code: "20309162",
        text: "От чего должны быть защищены составные части производственного оборудования, в том числе энергетические трубопроводы, рукава подачи природного газа, кислорода, мазута, воздуха, масла, воды, троллеи для питания электрического оборудования тележек, перевозящих расплавы металлов, и электрические кабели?",
        answers: [
          "Только от теплового воздействия",
          "Только от возможности попадания расплава",
          "Только от механических воздействий",
          "От всего перечисленного",
        ],
        correctAnswers: ["От всего перечисленного"],
      },
      {
        code: "20309163",
        text: "С учетом какой минимальной скорости движения воздуха проектируются открытые проемы укрытия потенциально опасного оборудования?",
        answers: ["1,5 м/с", "2 м/с", "1 м/с", "0,5 м/с"],
        correctAnswers: ["1,5 м/с"],
      },
      {
        code: "20309164",
        text: "Чем проводится комплексное испытание (совместное испытание на прочность и герметичность) законченных строительством или реконструкцией наружных и внутренних (межцеховых и цеховых) газопроводов?",
        answers: ["Азотом", "Воздухом", "Жидкостью под давлением", "Нагревом"],
        correctAnswers: ["Воздухом"],
      },
      {
        code: "20309165",
        text: "Куда должны заноситься записи о проведении технического обслуживания, выявленных дефектах и нарушениях газопроводов?",
        answers: [
          "В эксплуатационный журнал",
          "В паспорт технического устройства",
          "В журнал дефектов и неполадок оборудования",
        ],
        correctAnswers: ["В эксплуатационный журнал"],
      },
      {
        code: "20309166",
        text: "Какая допускается максимальная температура газа в газопроводах в случае подачи в них пара для пропарки, продувки и отогрева?",
        answers: ["100 °С", "90 °С", "85 °С"],
        correctAnswers: ["85 °С"],
      },
      {
        code: "20309167",
        text: "Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях?",
        answers: [
          "Не реже одного раза в смену",
          "Не реже одного раза в неделю",
          "Не реже одного раза в месяц",
        ],
        correctAnswers: ["Не реже одного раза в неделю"],
      },
      {
        code: "20309168",
        text: "Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных вне помещений?",
        answers: [
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в месяц",
          "Не реже одного раза в 6 месяцев",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20309169",
        text: "В каких случаях запрещается газовая резка и сварка на действующих газопроводах?",
        answers: [
          "На газопроводах, находящихся в эксплуатации менее 5 лет",
          "На газопроводах, находящихся под давлением",
          "На газопроводах, находящихся под разрежением",
        ],
        correctAnswers: ["На газопроводах, находящихся под разрежением"],
      },
      {
        code: "20309170",
        text: "На что должен проверяться выполненный сварной шов после врезки в действующий газопровод?",
        answers: ["На плотность", "На герметичность", "На прочность"],
        correctAnswers: ["На плотность"],
      },
      {
        code: "20309171",
        text: "Каково минимально допустимое давление газа непосредственно перед потребителем (после регулирующих приборов)?",
        answers: ["0,3 кПа", "0,4 кПа", "0,5 кПа"],
        correctAnswers: ["0,5 кПа"],
      },
      {
        code: "20309172",
        text: "Разрешено ли использовать продувочные свечи для выпуска в атмосферу избыточного газа?",
        answers: [
          "Разрешено при увеличении давления газа до 10%",
          "Не разрешено",
          "Только в аварийных случаях",
        ],
        correctAnswers: ["Только в аварийных случаях"],
      },
      {
        code: "20309173",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии более 0,5 мм/г?",
        answers: [
          "Один раз в 2 года",
          "Один раз в год",
          "Один раз в полугодие",
        ],
        correctAnswers: ["Один раз в год"],
      },
      {
        code: "20309174",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии 0,1 - 0,5 мм/г?",
        answers: ["Один раз в 2 года", "Один раз в год", "Один раз в 4 года"],
        correctAnswers: ["Один раз в 2 года"],
      },
      {
        code: "20309175",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии до 0,1 мм/г?",
        answers: ["Один раз в год", "Один раз в 2 года", "Один раз в 4 года"],
        correctAnswers: ["Один раз в 4 года"],
      },
      {
        code: "20309176",
        text: "Как часто должна производится нивелировка действующих газопроводов?",
        answers: ["Один раз в 5 лет", "Один раз в 3 года", "Один раз в год"],
        correctAnswers: ["Один раз в 5 лет"],
      },
      {
        code: "20309177",
        text: "До розжига газопотребляющего агрегата чем должен быть продут газопровод перед коллектором?",
        answers: ["Водяным паром", "Газом", "Сжатым воздухом"],
        correctAnswers: ["Газом"],
      },
      {
        code: "20309178",
        text: "В какие сроки в помещениях категории А должна осуществляться проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией?",
        answers: [
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в месяц",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20309179",
        text: "Из каких газов состоят промышленные газы объектов металлургии, возникающие (используемые) в процессе металлургического производства?",
        answers: [
          "Только из природного и доменного газов",
          "Только из коксового и конвертерного газов",
          "Только из ферросплавного и реформерного газов",
          "Из всех перечисленных и иных газов, а также смесей газов",
        ],
        correctAnswers: [
          "Из всех перечисленных и иных газов, а также смесей газов",
        ],
      },
      {
        code: "20309180",
        text: "С какой периодичностью должен производиться осмотр газопроводов, газовых установок и газового оборудования комиссией на производстве?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 2 раз в год",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в квартал",
        ],
        correctAnswers: ["Не реже 2 раз в год"],
      },
      {
        code: "20309181",
        text: "Кто из перечисленных лиц не должен входить в состав постоянно действующей комиссии для проведения осмотров объектов газового хозяйства?",
        answers: [
          "Начальник цеха - ответственный за производственный контроль",
          "Работник - ответственный за безопасную эксплуатацию и техническое состояние газового хозяйства цеха",
          "Работники, ответственные за безопасную эксплуатацию и техническое состояние соответствующих технических устройств, сооружений и оборудования",
          "Ремонтный персонал цеха",
        ],
        correctAnswers: ["Ремонтный персонал цеха"],
      },
      {
        code: "20309182",
        text: "С какой периодичностью должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях, и вне помещений?",
        answers: [
          "Не реже 1 раза в неделю, а вне помещений - не реже 1 раза в полгода",
          "Не реже 1 раза в неделю, а вне помещений - не реже 1 раза в квартал",
          "Не реже 1 раза в неделю, а вне помещений - не реже 1 раза в месяц",
        ],
        correctAnswers: [
          "Не реже 1 раза в неделю, а вне помещений - не реже 1 раза в месяц",
        ],
      },
      {
        code: "20309183",
        text: "С какой периодичностью должна производиться ревизия с разборкой регулятора давления, предохранительных клапанов, фильтров газопроводов, газовых установок и газового оборудования?",
        answers: [
          "Не менее 1 раза в полгода",
          "Не менее 1 раза в год",
          "Не менее 1 раза в 3 года",
          "Не менее 1 раза в 5 лет",
        ],
        correctAnswers: ["Не менее 1 раза в год"],
      },
      {
        code: "20309184",
        text: "До каких пор должна осуществляться продувка азотом перед пуском в работу установки по производству реформерного газа для производства металлизованного горячевосстановленного железа?",
        answers: [
          "Пока на участке продувки кислорода будет не более 1% кислорода",
          "Пока на участке продувки кислорода будет не более 2% кислорода",
          "Пока на участке продувки кислорода будет не более 3% кислорода",
          "Пока на участке продувки кислорода будет не более 4% кислорода",
        ],
        correctAnswers: [
          "Пока на участке продувки кислорода будет не более 1% кислорода",
        ],
      },
      {
        code: "20309185",
        text: "Какое из перечисленных требований к установке по производству реформерного газа указано неверно?",
        answers: [
          "Должна иметь подвод азота для его продувки перед пуском в работу",
          "Перед розжигом горелок реформера должна быть произведена продувка его топочного пространства",
          "Не допускается продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами",
          "При остановке агрегатов по производству реформерного газа подача воздуха и природного газа на горелки должна быть открыта",
        ],
        correctAnswers: [
          "При остановке агрегатов по производству реформерного газа подача воздуха и природного газа на горелки должна быть открыта",
        ],
      },
      {
        code: "20309186",
        text: "Кто утверждает инструкцию по перекачке сероуглерода из транспортной емкости в емкость хранения?",
        answers: [
          "Руководитель предприятия",
          "Главный технолог предприятия",
          "Главный инженер предприятия",
        ],
        correctAnswers: ["Главный инженер предприятия"],
      },
      {
        code: "20309187",
        text: "Кем производится испытание газопроводов и газовых установок после окончания строительно-монтажных и сварочных работ (включая ремонтные работы)?",
        answers: [
          "Строительно-монтажной организацией",
          "Комиссией предприятия-заказчика",
          "Комиссией предприятия-заказчика, с участием представителя Ростехнадзора",
          "Строительно-монтажной организацией с участием представителя предприятия",
        ],
        correctAnswers: [
          "Строительно-монтажной организацией с участием представителя предприятия",
        ],
      },
      {
        code: "20309188",
        text: "Какое из перечисленных требований к манометрам, используемым при проведении испытаний газопроводов и газовых установок, указано верно?",
        answers: [
          "Должны быть прошедшими поверку и опломбированными",
          "Класс точности манометров должен быть не ниже 1,5",
          "Диаметр корпуса должен быть не менее 160 мм и шкалой на номинальное давление не менее 4/3 и не более 5/3 от величины измеряемого",
          "Все перечисленное верно",
        ],
        correctAnswers: ["Все перечисленное верно"],
      },
      {
        code: "20309189",
        text: "В течение какого времени выдерживают испытательное давление в трубопроводе при испытании на прочность, после чего снижают до рабочего давления, при котором производят тщательный осмотр сварных швов (испытание на плотность)?",
        answers: [
          "В течение 30 минут",
          "В течение 15 минут",
          "В течение 10 минут",
          "В течение 5 минут",
        ],
        correctAnswers: ["В течение 10 минут"],
      },
      {
        code: "20309190",
        text: "Какое устанавливается минимальное расстояние от испытываемого газопровода большого диаметра, расположенных вне помещений, до границ охраняемой зоны во время проведения пневматических испытаний на прочность?",
        answers: ["10 м", "25 м", "50 м", "100 м"],
        correctAnswers: ["50 м"],
      },
      {
        code: "20309191",
        text: "Каким давлением производится дополнительное испытание на герметичность межцеховых и внутрицеховых газопроводов?",
        answers: [
          "Давлением, превышающим рабочее в 5/3 раза",
          "Давлением, превышающим рабочее в 1,5 раза",
          "Давлением, превышающим рабочее в 1,25 раза",
          "Давлением, равным рабочему",
        ],
        correctAnswers: ["Давлением, равным рабочему"],
      },
      {
        code: "20309192",
        text: "Какая установлена продолжительность дополнительных испытаний на герметичность для вновь сооружаемых газопроводов?",
        answers: [
          "Не менее 4 часов",
          "Не менее 12 часов",
          "Не менее 24 часов",
          "Не менее 48 часов",
        ],
        correctAnswers: ["Не менее 24 часов"],
      },
      {
        code: "20309193",
        text: "Какая устанавливается продолжительность периодических испытаний на герметичность для газопроводов, а также испытаний после ремонта, связанного со сваркой и разборкой газопровода?",
        answers: [
          "Устанавливается техническим руководством предприятия, но должна быть не менее 4 часов",
          "Устанавливается техническим руководством предприятия, но должна быть не менее 8 часов",
          "Устанавливается техническим руководством предприятия, но должна быть не менее 12 часов",
          "Устанавливается техническим руководством предприятия, но должна быть не менее 24 часов",
        ],
        correctAnswers: [
          "Устанавливается техническим руководством предприятия, но должна быть не менее 4 часов",
        ],
      },
      {
        code: "20309194",
        text: "При какой скорости падения давления за время дополнительного пневматического испытания на герметичность внутрицеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?",
        answers: [
          "Не более 0,1% в час",
          "Не более 0,2% в час",
          "Не более 0,3% в час",
        ],
        correctAnswers: ["Не более 0,1% в час"],
      },
      {
        code: "20309195",
        text: "При какой скорости падения давления за время дополнительного пневматического испытания на герметичность межцеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?",
        answers: [
          "Не более 0,1% в час",
          "Не более 0,2% в час",
          "Не более 0,3% в час",
        ],
        correctAnswers: ["Не более 0,2% в час"],
      },
      {
        code: "20309196",
        text: "У кого должен находиться агрегатный (ремонтный) журнал на отдельных объектах газового цеха, а также в цехах, в составе которых имеются объекты газового хозяйства?",
        answers: [
          "У работника, ответственного за производственный контроль",
          "У начальника цеха",
          "У ответственного за безопасное производство работ данного объекта газового хозяйства",
          "У работника, ответственного за техническое состояние данного объекта газового хозяйства",
        ],
        correctAnswers: [
          "У работника, ответственного за техническое состояние данного объекта газового хозяйства",
        ],
      },
      {
        code: "20309197",
        text: "При каком избыточном давлении газа в газопроводах разрешается производить электросварочные работы на газопроводах при ремонтах?",
        answers: [
          "Не более 0,3 кПа",
          "Не более 0,5 кПа",
          "Не более 3 кПа",
          "Не более 5 кПа",
        ],
        correctAnswers: ["Не более 0,3 кПа"],
      },
      {
        code: "20309198",
        text: "Какие действия должны быть произведены перед продувкой газопровода? Выберите правильный вариант ответа.",
        answers: [
          "Допускается продувка с выпуском газовоздушной смеси в топки печей, котлов и других агрегатов",
          "Допускается поджигание газа, выпускаемого при продувке",
          "По окончании продувки содержание кислорода в газе не должно превышать 2% в двух последовательно отобранных пробах",
          "Задвижки должны быть установлены в нужное положение, водяные затворы залиты водой, а все люки, лазы и свечи плотно закрыты, за исключением той свечи, через которую будет производиться продувка",
        ],
        correctAnswers: [
          "Задвижки должны быть установлены в нужное положение, водяные затворы залиты водой, а все люки, лазы и свечи плотно закрыты, за исключением той свечи, через которую будет производиться продувка",
        ],
      },
      {
        code: "20309199",
        text: "В каком радиусе у мест выброса газовоздушной смеси в атмосферу должно быть приостановлено движение всех видов транспорта, запрещено применение открытого огня и производство сварочных работ в период продувки газопровода?",
        answers: [
          "В радиусе 15 м",
          "В радиусе 25 м",
          "В радиусе 50 м",
          "В радиусе 75 м",
        ],
        correctAnswers: ["В радиусе 50 м"],
      },
      {
        code: "20309200",
        text: "Каким должно быть минимально допустимое давление газа непосредственно перед потребителем (после регулирующих приборов)?",
        answers: ["0,5 кПа", "0,4 кПа", "0,3 кПа"],
        correctAnswers: ["0,5 кПа"],
      },
      {
        code: "20309201",
        text: "В каком случае допускается использовать продувочные свечи газопроводов для выпуска в атмосферу избыточного газа?",
        answers: [
          "Всегда",
          "В аварийных случаях",
          "По распоряжению главного инженера",
        ],
        correctAnswers: ["В аварийных случаях"],
      },
      {
        code: "20309202",
        text: "Какие сроки проведения ревизии газопроводов должны быть при скорости коррозии более 0,5 мм/г?",
        answers: ["1 раз в год", "1 раз в 2 года", "1 раз в 4 года"],
        correctAnswers: ["1 раз в год"],
      },
      {
        code: "20309203",
        text: "При какой скорости коррозии срок проведения ревизии газопроводов должен быть не реже одного раза в 2 года?",
        answers: ["До 0,1 мм/г", "0,1 - 0,5 мм/г", "Более 0,5 мм/г"],
        correctAnswers: ["0,1 - 0,5 мм/г"],
      },
      {
        code: "20309204",
        text: "Какой допускается максимальный подъем температуры газа в газопроводах?",
        answers: ["65 °C", "75 °C", "85 °C", "90 °C"],
        correctAnswers: ["85 °C"],
      },
      {
        code: "20309205",
        text: "В каком случае допускается подача газа без поднесения запальника, факела или другого воспламеняющего средства к горелке газопровода?",
        answers: [
          "Если температура кладки в разогретой зоне розжига факела превышает 500 °C",
          "Если температура кладки в разогретой зоне розжига факела превышает 600 °C",
          "Если температура кладки в разогретой зоне розжига факела превышает 700 °C",
          "Если температура кладки в разогретой зоне розжига факела превышает 800 °C",
        ],
        correctAnswers: [
          "Если температура кладки в разогретой зоне розжига факела превышает 800 °C",
        ],
      },
      {
        code: "20309206",
        text: "Кто осуществляет выбор типа быстродействующего отсекающего клапана на подводе газа к каждому агрегату с дутьевыми горелками?",
        answers: [
          "Организация, эксплуатирующая систему автоматики печи",
          "Специализированная организация",
          "Организация, проектирующая систему автоматики печи",
        ],
        correctAnswers: ["Организация, проектирующая систему автоматики печи"],
      },
      {
        code: "20309207",
        text: "Какое из перечисленных требований при эксплуатации газового оборудования газопотребляющих агрегатов указано верно?",
        answers: [
          "Розжиг газопотребляющего агрегата должен проводиться в соответствии с технологической инструкцией или инструкцией по пуску и остановке агрегата",
          "Продувка газопровода и коллектора агрегата должна производиться при открытых задвижках на горелках",
          "Отключающее устройство на газопроводе перед горелкой разрешается открывать до поднесения к горелке запальника, факела или другого средства, воспламеняющего газ",
          "Подачу газа в котлах без поднесения запальника, факела или другого воспламеняющего средства допускается производить, если температура кладки в разогретой зоне розжига факела превышает 800 °C",
        ],
        correctAnswers: [
          "Розжиг газопотребляющего агрегата должен проводиться в соответствии с технологической инструкцией или инструкцией по пуску и остановке агрегата",
        ],
      },
      {
        code: "20309208",
        text: "С какой периодичностью должна производиться режимная наладка газоиспользующего оборудования?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 4 года",
        ],
        correctAnswers: ["Не реже 1 раза в 3 года"],
      },
      {
        code: "20309209",
        text: "В каком случае допускается режимную наладку газоиспользующего оборудования проводить не реже одного раза в четыре года?",
        answers: [
          "Всегда",
          "При режимной наладке газоиспользующего оборудования",
          "При стабильной работе газоиспользующего оборудования",
        ],
        correctAnswers: [
          "При стабильной работе газоиспользующего оборудования",
        ],
      },
      {
        code: "20309210",
        text: "С какой периодичностью должна производиться проверка технического состояния вентиляционных каналов в зданиях (помещениях) с установленным газоиспользующим оборудованием и пылеуборка, и дезинфекция вентиляционных каналов?",
        answers: [
          "Не реже 1 раза в год. Пылеуборка и дезинфекция вентиляционных каналов должна проводиться не реже 1 раза в 3 месяца",
          "Не реже 2 раз в год. Пылеуборка и дезинфекция вентиляционных каналов должна проводиться не реже 1 раза в год",
          "Не реже 1 раза в год. Пылеуборка и дезинфекция вентиляционных каналов должна проводиться не реже 1 раза в 2 года",
          "Не реже 2 раз в год. Пылеуборка и дезинфекция вентиляционных каналов должна проводиться не реже 1 раза в 3 года",
        ],
        correctAnswers: [
          "Не реже 2 раз в год. Пылеуборка и дезинфекция вентиляционных каналов должна проводиться не реже 1 раза в 3 года",
        ],
      },
      {
        code: "20309211",
        text: "С какой периодичностью должны производиться проверка технического состояния промышленных дымоотводящих устройств (газоходов газоиспользующего оборудования, дымоходов и дымовых труб) и их прочистка?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 2 раз в год",
          "Не реже 1 раза в квартал",
          "Не реже 1 раза в месяц",
        ],
        correctAnswers: ["Не реже 2 раз в год"],
      },
      {
        code: "20309212",
        text: "На каких минимальных расстояниях (по горизонтали) до групповых газобаллонных установок допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?",
        answers: ["10 м", "5 м", "3 м", "1,5 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "20309213",
        text: "На каких минимальных расстояниях (по горизонтали) до отдельных баллонов с кислородом и горючими газами допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?",
        answers: ["5 м", "10 м", "3 м", "1,5 м"],
        correctAnswers: ["5 м"],
      },
      {
        code: "20309214",
        text: "В каком положении должны находиться баллоны со сжиженным газом во время работы?",
        answers: [
          "В наклонном положении с вентилем, направленным вверх",
          "В вертикальном положении",
          "В горизонтальном положении",
          "В любом положении",
        ],
        correctAnswers: ["В вертикальном положении"],
      },
      {
        code: "20309215",
        text: "Какое действие должно быть осуществлено в первую очередь при зажигании ручной горелки или резака?",
        answers: [
          "Приоткрывание вентиля кислорода",
          "Открытие вентиля горючего газа",
          "Кратковременная продувка рукава для удаления воздуха",
          "Зажигание горючей смеси газов",
        ],
        correctAnswers: ["Приоткрывание вентиля кислорода"],
      },
      {
        code: "20309216",
        text: "Какое из перечисленных действий допускается при эксплуатации оборудования для газопламенной обработки металлов? Выберите правильный вариант ответа.",
        answers: [
          "Нагревание баллонов со сжиженным газом прямыми солнечными лучами или другими источниками тепла сверх температуры, указанной производителем",
          "Отбор газа из баллона при снижении в нем рабочего давления ниже требуемого при питании передвижного рабочего поста сжиженным газом от баллона",
          "Продувка рукава для горючих газов кислородом и кислородного рукава горючим газом, а также взаимозамена рукавов при работе",
          "Направление пламени горелки (резака) в сторону, противоположную источнику газоснабжения",
        ],
        correctAnswers: [
          "Направление пламени горелки (резака) в сторону, противоположную источнику газоснабжения",
        ],
      },
      {
        code: "20309217",
        text: "Какое из перечисленных требований при эксплуатации взрывопожароопасных объектов указано неверно?",
        answers: [
          "В помещениях категории A проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией должна производиться в сроки, предусмотренные заводской инструкцией, но не реже 1 раза в месяц",
          "В помещениях категории A хранение обтирочных и смазочных материалов допускается в количестве, не превышающем месячной потребности, в металлических, плотно закрывающихся шкафах или ящиках",
          "На каждой двери помещения категории A должны быть указаны категория помещения и класс взрывоопасной зоны",
          "Работы по ремонту электрооборудования в помещениях категории A должны выполняться после обесточивания электросети, используя, при необходимости, переносные аккумуляторные взрывобезопасные светильники",
        ],
        correctAnswers: [
          "В помещениях категории A хранение обтирочных и смазочных материалов допускается в количестве, не превышающем месячной потребности, в металлических, плотно закрывающихся шкафах или ящиках",
        ],
      },
      {
        code: "20309218",
        text: "С какой периодичностью должен производиться текущий ремонт оборудования с разборкой регуляторов давления, предохранительных клапанов и фильтров ГРП и ГРУ, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания?",
        answers: [
          "Не менее 1 раза в год",
          "Не менее 1 раза в 2 года",
          "Не менее 1 раза в 3 года",
          "Не менее 1 раза в 5 лет",
        ],
        correctAnswers: ["Не менее 1 раза в год"],
      },
      {
        code: "20309219",
        text: "При каком давлении должна производиться настройка срабатывания предохранительно-запорных клапанов при их наличии в ГРП (ГРУ)?",
        answers: [
          "При давлении на 15 % больше расчетного",
          "При давлении на 10 % больше расчетного",
          "При давлении на 15 % больше пробного",
          "При давлении на 15 % больше атмосферного",
        ],
        correctAnswers: ["При давлении на 15 % больше расчетного"],
      },
      {
        code: "20309220",
        text: "В каком случае допускается подача потребителям газа по обводной линии (байпасу) ГРП и ГРУ?",
        answers: [
          "На время ревизии или ремонта оборудования",
          "При снятии для ремонта сбросного предохранительного клапана",
          "При снятии для ремонта манометра",
          "Подача газа по байпасу не допускается",
        ],
        correctAnswers: ["На время ревизии или ремонта оборудования"],
      },
      {
        code: "20309221",
        text: "Какие виды работ должны выполняться не реже одного раза в 6 месяцев при эксплуатации ГРП и ГРУ?",
        answers: [
          "Проверка параметров срабатывания предохранительных запорных и сбросных клапанов",
          "Техническое обслуживание",
          "Текущий ремонт",
          "Капитальный ремонт",
        ],
        correctAnswers: ["Техническое обслуживание"],
      },
      {
        code: "20309222",
        text: "Какие из перечисленных видов работ не должны выполняться при техническом обслуживании технологического оборудования ГРП и ГРУ? Выберите 2 варианта ответа.",
        answers: [
          "Устранение повреждений газопроводов обвязки технологического оборудования",
          "Восстановление окраски шкафов пунктов редуцирования газа, ограждений, газопроводов обвязки и технических устройств",
          "Смазка подвижных элементов запорной арматуры (без разборки)",
          "Очистка помещения и технических устройств пунктов редуцирования газа от загрязнений (при необходимости)",
          "Устранение выявленных дефектов и неисправностей",
        ],
        correctAnswers: [
          "Устранение повреждений газопроводов обвязки технологического оборудования",
          "Восстановление окраски шкафов пунктов редуцирования газа, ограждений, газопроводов обвязки и технических устройств",
        ],
      },
      {
        code: "20309223",
        text: "В какие сроки должен проводиться капитальный ремонт ГРП и ГРУ?",
        answers: [
          "В сроки, установленные изготовителем",
          "В сроки, установленные эксплуатирующей организацией",
          "Через каждые 3 года после ввода в эксплуатацию",
          "Через каждые 5 лет после ввода в эксплуатацию",
        ],
        correctAnswers: ["В сроки, установленные изготовителем"],
      },
      {
        code: "20309224",
        text: "Чем должна производиться продувка импульсных газовых проводок?",
        answers: [
          "Только паром",
          "Только сжатым воздухом",
          "Только газом",
          "Любым из перечисленных способом",
        ],
        correctAnswers: ["Любым из перечисленных способом"],
      },
      {
        code: "20309225",
        text: "Какой должна быть объемная доля кислорода в воздухе производственных помещений производства ПРВ?",
        answers: [
          "Не менее 19% и не более 23%",
          "Не менее 16% и не более 23%",
          "Не менее 21% и не более 25%",
        ],
        correctAnswers: ["Не менее 19% и не более 23%"],
      },
      {
        code: "20309226",
        text: "Что необходимо устанавливать в производственных помещениях, где возможны воспламенение одежды или химические ожоги у работников?",
        answers: [
          "Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
          "Емкости с песком, огнетушители",
          "Брезентовые покрывала",
        ],
        correctAnswers: [
          "Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
      },
      {
        code: "20309227",
        text: "Какие требования установлены к проведению ремонтных работ внутри нагретых технических устройств?",
        answers: [
          "Проведение ремонтных работ разрешается после разгерметизации и понижения температуры воздуха в них до 45 °С",
          "Проведение ремонтных работ разрешается после продувки и снижения температуры воздуха в них до 50 °С",
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °С",
        ],
        correctAnswers: [
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °С",
        ],
      },
      {
        code: "20309228",
        text: "После установления каких показателей температуры разрешается проводить ремонтные работы внутри нагретых технических устройств, а также низкотемпературных технических устройств?",
        answers: [
          "После снижения температуры воздуха до 45 °С, а низкотемпературных технических устройств - после отогрева до положительных температур (18 °С)",
          "После снижения температуры воздуха до 40 °С и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °С) и проветривания",
          "После снижения температуры воздуха до 50 °С, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °С)",
        ],
        correctAnswers: [
          "После снижения температуры воздуха до 40 °С и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °С) и проветривания",
        ],
      },
      {
        code: "20309229",
        text: "При каких условиях допускается перенос сроков проведения капитальных ремонтов основного технологического оборудования?",
        answers: [
          "По решению руководителя предприятия в связи с производственной необходимостью",
          "При наличии в паспорте оборудования отметки о продлении сроков эксплуатации",
          "При наличии положительного заключения экспертного обследования",
        ],
        correctAnswers: [
          "При наличии положительного заключения экспертного обследования",
        ],
      },
      {
        code: "20309230",
        text: "Каким образом должен проводиться отогрев трубопроводной арматуры?",
        answers: [
          "Горячим воздухом, паром или горячей водой",
          "Газовыми горелками",
          "Нагревательными электроприборами",
        ],
        correctAnswers: ["Горячим воздухом, паром или горячей водой"],
      },
      {
        code: "20309231",
        text: "Каким должно быть расстояние от устройства забора воздуха и камер всасывания работающих воздушных компрессоров ВРУ при выполнении ремонтных работ, работ по газовой сварке и резке металла, чтобы для их выполнения было необходимо наличие письменного разрешения руководителя производства (цеха) и наряда-допуска?",
        answers: ["Менее 100 м", "Менее 75 м", "Менее 150 м", "Менее 200 м"],
        correctAnswers: ["Менее 100 м"],
      },
      {
        code: "20309232",
        text: "Какой срок должны храниться данные самопишущих приборов?",
        answers: ["Не менее 3 месяцев", "Не менее 6 месяцев", "Не менее года"],
        correctAnswers: ["Не менее 3 месяцев"],
      },
      {
        code: "20309233",
        text: "С какой периодичностью следует проверять состояние адсорбента в адсорбционных блоках осушки?",
        answers: ["Один раз в месяц", "Один раз в год", "Два раза в год"],
        correctAnswers: ["Один раз в год"],
      },
      {
        code: "20309234",
        text: "Каково предельно допустимое содержание масла в кислороде, поступающем в компрессор?",
        answers: [
          "Не должно превышать 0,02 мг/м³",
          "Не должно превышать 0,03 мг/м³",
          "Не должно превышать 0,05 мг/м³",
        ],
        correctAnswers: ["Не должно превышать 0,02 мг/м³"],
      },
      {
        code: "20309235",
        text: "Какое должно быть давление во всасывающих коммуникациях компрессоров, сжимающих ПРВ?",
        answers: [
          "Должно быть не ниже 0,2 кПа (20 мм вод. ст.)",
          "Должно быть не ниже 0,4 кПа (40 мм вод. ст.)",
          "Должно быть не ниже 0,5 кПа (50 мм вод. ст.)",
        ],
        correctAnswers: ["Должно быть не ниже 0,5 кПа (50 мм вод. ст.)"],
      },
      {
        code: "20309236",
        text: "Какое должно быть содержание кислорода в воздухе помещения наполнения баллонов?",
        answers: [
          "При объемной доле кислорода в воздухе не менее 19% и не более 23%",
          "При объемной доле кислорода в воздухе не менее 18% и не более 24%",
          "При объемной доле кислорода в воздухе не менее 18% и не более 23%",
        ],
        correctAnswers: [
          "При объемной доле кислорода в воздухе не менее 19% и не более 23%",
        ],
      },
      {
        code: "20309237",
        text: "При какой предельной температуре кислорода после регулятора давления на линии регулирования прекращается его подача?",
        answers: [
          "При повышении более 100 °С",
          "При повышении более 150 °С",
          "При повышении более 120 °С",
        ],
        correctAnswers: ["При повышении более 100 °С"],
      },
      {
        code: "20309238",
        text: "При каком давлении должен срабатывать предохранительный клапан вакуумной камеры печи?",
        answers: [
          "При давлении 0,02 МПа (0,2 кгс/см²)",
          "При давлении 0,05 МПа (0,5 кгс/см²)",
          "При давлении 0,01 МПа (0,1 кгс/см²)",
        ],
        correctAnswers: ["При давлении 0,01 МПа (0,1 кгс/см²)"],
      },
      {
        code: "20309239",
        text: "При каком давлении должен срабатывать предохранительный клапан вакуумной камеры печи?",
        answers: [
          "При давлении 0,01 МПа (0,1 кгс/см²)",
          "При давлении 0,02 МПа (0,2 кгс/см²)",
          "При давлении 0,05 МПа (0,5 кгс/см²)",
        ],
        correctAnswers: ["При давлении 0,01 МПа (0,1 кгс/см²)"],
      },
      {
        code: "20309240",
        text: "При каком давлении должна производиться настройка срабатывания отсекающих клапанов КРП?",
        answers: [
          "При давлении на 15% более расчетного и температуре 100 °С",
          "При давлении на 20% более расчетного и температуре 100 °С",
          "При давлении на 25% более расчетного и температуре 110 °С",
        ],
        correctAnswers: [
          "При давлении на 15% более расчетного и температуре 100 °С",
        ],
      },
      {
        code: "20309241",
        text: "Как часто должна производиться проверка настройки отсечных и предохранительных клапанов?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в три месяца, а также после каждого текущего ремонта",
          "Не реже одного раза в два месяца, а также после каждой ревизии и ремонта",
        ],
        correctAnswers: [
          "Не реже одного раза в два месяца, а также после каждой ревизии и ремонта",
        ],
      },
      {
        code: "20309242",
        text: "Сколько суток после наполнения должны выдерживаться баллоны с криптоноксеноновой смесью и криптоном в наполнительной комнате или на складе баллонов?",
        answers: [
          "Не менее 10 суток",
          "Не менее 14 суток",
          "Не менее 12 суток",
        ],
        correctAnswers: ["Не менее 14 суток"],
      },
      {
        code: "20309243",
        text: "Какая объемная доля водорода в техническом аргоне, поступающем на очистку в ВРУ?",
        answers: [
          "Не должна превышать 3,0%",
          "Не должна превышать 4,0%",
          "Не должна превышать 2,5%",
        ],
        correctAnswers: ["Не должна превышать 2,5%"],
      },
      {
        code: "20309244",
        text: "При какой температуре возможно вскрытие реакторов каталитического гидрирования?",
        answers: [
          "После остывания их до температуры 40 °С",
          "После остывания их до температуры 45 °С",
          "После остывания их до температуры 50 °С",
        ],
        correctAnswers: ["После остывания их до температуры 40 °С"],
      },
      {
        code: "20309245",
        text: "Каким документом определяется максимальная величина давления газа (аргона), необходимая для открывания донных продувочных фурм и ковшевых пробок?",
        answers: [
          "Инструкцией по охране труда в литейном производстве",
          "Инструкцией о мерах пожарной безопасности при проведении огневых работ",
          "Технологической инструкцией",
          "Планом производства работ повышенной опасности",
        ],
        correctAnswers: ["Технологической инструкцией"],
      },
      {
        code: "20309246",
        text: "С какой периодичностью следует очищать фильтры?",
        answers: [
          "В первый раз - через 7 суток после ввода в эксплуатацию, далее - через каждые 3 месяца",
          "В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
          "В первый раз - через 14 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
        ],
        correctAnswers: [
          "В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
        ],
      },
      {
        code: "20309247",
        text: "Как часто должен производиться осмотр трубопроводов кислорода?",
        answers: [
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в месяц",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20309248",
        text: "Как часто должна производиться проверка конденсатоотводчиков и пароспутников, обогревающих трубопроводы кислорода в зимнее время?",
        answers: ["Ежедневно", "Еженедельно", "Ежемесячно"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "20309249",
        text: "Как часто должна проводиться проверка правильности показаний приборов, перечень которых утверждается руководителем производства?",
        answers: [
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в месяц",
          "Не реже одного раза в 3 месяца",
        ],
        correctAnswers: ["Не реже одного раза в 3 месяца"],
      },
      {
        code: "20309250",
        text: "Какое количество баллонов с эталонными и поверочными газовыми смесями разрешается хранить в помещении хроматографии?",
        answers: ["Не более двух", "Не более трех", "Не более четырех"],
        correctAnswers: ["Не более двух"],
      },
      {
        code: "20309251",
        text: "Из каких материалов выполняются линии отбора кислорода на анализ?",
        answers: [
          "Из коррозионно-стойкой стали или медных сплавов",
          "Из углеродистой стали",
          "Из серого чугуна",
        ],
        correctAnswers: ["Из коррозионно-стойкой стали или медных сплавов"],
      },
      {
        code: "20309252",
        text: "Какой установлен порядок хранения обтирочных материалов?",
        answers: [
          "В открытой таре в отдельном помещении",
          "В закрытых деревянных ящиках на производственной площадке",
          "В чистых металлических ящиках с крышками",
        ],
        correctAnswers: ["В чистых металлических ящиках с крышками"],
      },
      {
        code: "20309253",
        text: "Из каких сплавов металлов запрещается установка арматуры на кислородопроводах?",
        answers: ["Из чугуна", "Из сплавов титана", "Из стали"],
        correctAnswers: ["Из сплавов титана"],
      },
      {
        code: "20309254",
        text: "Какому классу должна соответствовать герметичность арматуры с металлическим уплотнением в затворе, применяемая для установки на трубопроводах водорода?",
        answers: ["Классу A", "Классу В", "Классу С", "Классу D"],
        correctAnswers: ["Классу В"],
      },
      {
        code: "20309255",
        text: "Какому классу должна соответствовать герметичность затвора запорной арматуры со средой водорода?",
        answers: ["Классу A", "Классу В", "Классу С", "Классу D"],
        correctAnswers: ["Классу A"],
      },
      {
        code: "20309256",
        text: "Какую арматуру не допускается применять на водородопроводах?",
        answers: [
          "Арматуру из серого чугуна",
          "Стальную арматуру для взрывоопасных сред",
          "Специальную арматуру для водорода",
          "Арматуру из ковкого и высокопрочного чугуна",
        ],
        correctAnswers: ["Арматуру из серого чугуна"],
      },
      {
        code: "20309257",
        text: "В соответствии с требованиями какой документации должны производиться проектирование, монтаж и эксплуатация водородопроводов, а также вспомогательных трубопроводов обвязки участков комплекса получения и потребления водорода?",
        answers: [
          "Строительных норм и правил",
          "Нормативно-технической документации для технологических стальных трубопроводов",
          "Правил безопасности процессов получения или применения металлов",
          "Всей перечисленной документации",
        ],
        correctAnswers: ["Всей перечисленной документации"],
      },
      {
        code: "20309258",
        text: "Какой предел огнестойкости должен быть у стен, разделяющих взрывоопасные помещения на водородных станциях?",
        answers: ["2,5 часа", "1,5 часа", "2 часа", "1 час"],
        correctAnswers: ["2,5 часа"],
      },
      {
        code: "20309259",
        text: "Какая должна быть минимальная высота забора по периметру территории всего комплекса производства водорода?",
        answers: ["2 м", "1 м", "1,5 м", "2,5 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "20309260",
        text: "Какое должно быть расстояние между ресиверами одного газа?",
        answers: [
          "Не менее 1,5 м",
          "Не менее 1,0 м",
          "Не менее 0,5 м",
          "Не менее 1,2 м",
        ],
        correctAnswers: ["Не менее 1,5 м"],
      },
      {
        code: "20309261",
        text: "Какие требования к ресиверам для водорода (сосудам) указаны неверно?",
        answers: [
          "Ресиверы для водорода (сосуды) размещаются на открытых площадках, имеющих по периметру ограждение легкого типа высотой не менее 1,2 м из несгораемого материала",
          'На ограждении открытых площадок, где размещаются ресиверы для водорода (сосуды), должны быть вывешены предупреждающие знаки безопасности: "Курить запрещается", "Посторонним вход воспрещен", на ресиверах должны быть поясняющие надписи: "Водород. Взрывоопасно"',
          "Расстояние от ресиверов (сосудов) с водородом до ограждения открытых площадок, где они размещаются, должно быть не менее 1,0 м",
        ],
        correctAnswers: [
          "Расстояние от ресиверов (сосудов) с водородом до ограждения открытых площадок, где они размещаются, должно быть не менее 1,0 м",
        ],
      },
      {
        code: "20309262",
        text: "Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей?",
        answers: [
          "Только системами регулирования заданного соотношения топлива, воздуха и водяного пара",
          "Только блокировками, прекращающими поступление газообразного топлива и воздуха при снижении их давления ниже установленных параметров (автономно), а также при прекращении электро- и пневмоснабжения КИПиА",
          "Только средствами сигнализации о прекращении поступления топлива, а также воздуха при его принудительной подаче в топочное пространство",
          "Только средствами контроля за уровнем тяги и автоматического прекращения подачи топливного газа в зону горения при остановке дымососа или недопустимом снижении разрежения в печи, а при компоновке печных агрегатов с котлами-утилизаторами системами по переводу на работу агрегатов без дымососов",
          "Только средствами автоматической подачи водяного пара или инертного газа в топочное пространство и в змеевики при прогаре труб, характеризующимися падением давления нагреваемого продукта на выходе из печи ниже регламентированного значения",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20309263",
        text: "Какие требования из перечисленных должны предусматриваться для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты? Выберите правильный вариант ответа.",
        answers: [
          "Только должна предусматриваться их дистанционное отключение",
          "Только должна предусматриваться их установка на линиях всасывания и нагнетания запорных или отсекающих устройств",
          "Должны предусматриваться их дистанционное отключение и установка на линиях всасывания и нагнетания запорных или отсекающих устройств",
        ],
        correctAnswers: [
          "Должны предусматриваться их дистанционное отключение и установка на линиях всасывания и нагнетания запорных или отсекающих устройств",
        ],
      },
      {
        code: "20309264",
        text: "В каком случае все технологическое оборудование должно продуваться инертным газом? Выберите 2 варианта ответа.",
        answers: [
          "После остановки более чем на 2 часа",
          "Перед пуском",
          "Если оно в период остановки находилось под избыточным давлением водорода",
          "После остановки более чем на 1 час",
        ],
        correctAnswers: ["После остановки более чем на 2 часа", "Перед пуском"],
      },
      {
        code: "20309265",
        text: "При каком содержании водорода в воздухе производственного помещения по сигналу от автоматического газоанализатора технологическое оборудование этого помещения должно быть остановлено?",
        answers: [
          "Выше 10% нижнего предела взрываемости",
          "Выше 15% высшего предела взрываемости",
          "Выше 20% высшего предела взрываемости",
          "Выше 25% нижнего предела взрываемости",
        ],
        correctAnswers: ["Выше 25% нижнего предела взрываемости"],
      },
      {
        code: "20309266",
        text: "При каком содержании газов должна срабатывать световая и звуковая сигнализации, установленные с автоматическими газоанализаторами в помещениях категории А, где обращается водород?",
        answers: [
          "При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости, кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости",
          "При содержании водорода в воздухе помещения не более 12% нижнего предела взрываемости, кислорода менее 20% и более 25%, угарного газа более 18 мг/м³, метана не более 12% нижнего предела взрываемости",
          "При содержании водорода в воздухе помещения не более 14% нижнего предела взрываемости, кислорода менее 21% и более 24%, угарного газа более 16 мг/м³, метана не более 14% нижнего предела взрываемости",
        ],
        correctAnswers: [
          "При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости, кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости",
        ],
      },
      {
        code: "20309267",
        text: "Какие требования к вентиляции помещения, где возможно выделение водорода, указаны верно? Выберите 2 варианта ответа.",
        answers: [
          "Оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час",
          "При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час",
          "В случае аварии дополнительно к постоянно работающей общеобменной вентиляции производства водорода должна автоматически включаться аварийная приточная система",
          "При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 2 в час",
        ],
        correctAnswers: [
          "Оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час",
          "При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час",
        ],
      },
      {
        code: "20309268",
        text: "Какие из перечисленных ограждающих конструкций не относятся к легкосбрасываемым?",
        answers: [
          "Окна с оконным стеклом толщиной 3, 4 и 5 мм, площадью не менее соответственно 0,8, 1 и 1,5 м2",
          "Конструкции из асбоцементных, алюминиевых и стальных листов с легким утеплением",
          "Фонарные переплеты с металлическими решетками",
          "Противопожарные двери",
        ],
        correctAnswers: ["Противопожарные двери"],
      },
      {
        code: "20309269",
        text: "Какое минимальное остаточное давление должны иметь баллоны, подготовленные для наполнения водородом?",
        answers: ["0,05 МПа", "0,04 МПа", "0,03 МПа", "0,02 МПа"],
        correctAnswers: ["0,05 МПа"],
      },
      {
        code: "20309270",
        text: "На какой высоте от пола должны быть расположены окна в помещениях отделений наполненных баллонов, наполнительных и разрядных рамп?",
        answers: [
          "На высоте не менее 1,5 м",
          "На высоте не менее 1,2 м",
          "На высоте не менее 1,0 м",
          "На высоте не менее 0,8 м",
        ],
        correctAnswers: ["На высоте не менее 1,5 м"],
      },
      {
        code: "20309271",
        text: "Какой должна быть ширина свободного прохода между щитом управления вентилями и ближайшими стенами наполнительной?",
        answers: [
          "Не менее 0,8 м",
          "Не менее 0,6 м",
          "Не менее 0,7 м",
          "Не менее 0,5 м",
        ],
        correctAnswers: ["Не менее 0,8 м"],
      },
      {
        code: "20309272",
        text: "Стальными листами какой толщины должны отделяться от рабочей зоны баллоны с водородом при их горизонтальном расположении и размещении внутри помещения?",
        answers: [
          "Не менее 16 мм",
          "Не менее 12 мм",
          "Не менее 10 мм",
          "Не менее 8 мм",
        ],
        correctAnswers: ["Не менее 16 мм"],
      },
      {
        code: "20309273",
        text: "При какой чистоте водорода должен производиться пуск компрессора для наполнения баллонов?",
        answers: [
          "При чистоте водорода не менее 99,7%",
          "При чистоте водорода не менее 99,1%",
          "При чистоте водорода не менее 98,7%",
          "При чистоте водорода не менее 95,7%",
        ],
        correctAnswers: ["При чистоте водорода не менее 99,7%"],
      },
      {
        code: "20309274",
        text: "Какие устройства должны быть установлены на нагнетательном газопроводе для отключения компрессора от коллектора высокого давления?",
        answers: [
          "Только обратный клапан",
          "Два запорных вентиля, между которыми должна быть свеча с условным проходом не менее 5 мм",
          "Обратный клапан и два запорных вентиля, между которыми должна быть свеча с условным проходом не менее 6 мм, имеющая прямое сообщение с атмосферой",
        ],
        correctAnswers: [
          "Обратный клапан и два запорных вентиля, между которыми должна быть свеча с условным проходом не менее 6 мм, имеющая прямое сообщение с атмосферой",
        ],
      },
      {
        code: "20309275",
        text: "На какой ступени сжатия водородные компрессоры должны иметь предохранительные клапаны?",
        answers: [
          "На первой ступени сжатия",
          "На второй ступени сжатия",
          "На всех ступенях сжатия",
        ],
        correctAnswers: ["На всех ступенях сжатия"],
      },
      {
        code: "20309276",
        text: "Какой технической документацией должен быть оснащен каждый компрессорный агрегат или установка, или группа однородных компрессорных установок?",
        answers: [
          "Только паспортом (формуляром) на компрессорную установку",
          "Только схемой трубопроводов (сжатого воздуха или газа, воды, масла) с указанием мест установок задвижек, вентилей, влагомаслоотделителей, промежуточных и концевых холодильников, воздухосборников, контрольно-измерительных приборов; схемы вывешиваются на видном месте",
          "Только инструкцией (руководством) по эксплуатации (безопасному обслуживанию) компрессорной установки",
          "Только журналом учета работы компрессора, паспортами-сертификатами компрессорного масла и результатами его лабораторного анализа, графиком ремонтов компрессорной установки",
          "Всей перечисленной документацией",
        ],
        correctAnswers: ["Всей перечисленной документацией"],
      },
      {
        code: "20309277",
        text: "Какие требования к компрессорным установкам указаны неверно?",
        answers: [
          "Компрессор и его электродвигатель должны устанавливаться на фундаментах, не связанных с конструкциями здания",
          "Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь",
          "В помещении компрессорной установки следует предусматривать специальные места для хранения в закрытом виде обтирочных материалов, инструмента, прокладок, а также для хранения недельного запаса масла",
          "Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи",
        ],
        correctAnswers: [
          "Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь",
        ],
      },
      {
        code: "20309278",
        text: "Какие требования к компрессорным установкам указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Компрессор и его электродвигатель должны устанавливаться на фундаментах, связанных с конструкциями здания",
          "Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь",
          "В помещении компрессорной установки следует предусматривать специальные места для хранения в открытом виде обтирочных материалов, инструмента, прокладок, а также для хранения недельного запаса масла",
          "Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи",
        ],
        correctAnswers: [
          "Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи",
        ],
      },
      {
        code: "20309279",
        text: "Где не допускается размещение операторных помещений?",
        answers: [
          "Только над помещениями с взрывоопасными зонами любого класса",
          "Только под душевыми, санузлами",
          "Только под производственными помещениями с мокрым технологическим процессом",
          "Только под приточными вентиляционными камерами",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20309280",
        text: "Какие требования к монтажу и эксплуатации водородопроводов указаны неверно?",
        answers: [
          "При прокладке трубопроводов водорода следует, как правило, использовать бесшовные стальные трубы или трубопроводы из нержавеющей стали, соединенные с применением сварки",
          "Прокладка газопроводов в местах прохода людей должна предусматриваться на высоте не менее 1,8 м от пола до низа газопровода",
          "На трубопроводах водорода, транспортирующих газ в другие здания, к компрессорным, ресиверам и цехам-потребителям, на выходе из здания должны устанавливаться запорная арматура и устройства, исключающие возможность обратного пропуска газа",
          "При прокладке цеховых трубопроводов водорода должна быть предусмотрена самокомпенсация",
        ],
        correctAnswers: [
          "Прокладка газопроводов в местах прохода людей должна предусматриваться на высоте не менее 1,8 м от пола до низа газопровода",
        ],
      },
      {
        code: "20309281",
        text: "Какая продолжительность пневматических испытаний установлена Правилами безопасности процессов получения или применения металлов после проведения плановых или аварийных остановок водородопроводов?",
        answers: ["1 час", "2 часа", "1,5 часа", "0,5 часа"],
        correctAnswers: ["1 час"],
      },
      {
        code: "20309282",
        text: "Через какое время после ввода объекта в эксплуатацию должна проводиться первая выборочная ревизия водородопроводов на вновь осваиваемых производствах?",
        answers: [
          "Не позднее чем через 4 года после ввода объекта в эксплуатацию",
          "Не позднее чем через 5 лет после ввода объекта в эксплуатацию",
          "Не позднее чем через 7 лет после ввода объекта в эксплуатацию",
          "Не позднее чем через 10 лет после ввода объекта в эксплуатацию",
        ],
        correctAnswers: [
          "Не позднее чем через 4 года после ввода объекта в эксплуатацию",
        ],
      },
      {
        code: "20309283",
        text: "С какой периодичностью должны проводиться испытания на плотность и прочность холодных участков (с температурой до 200 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?",
        answers: [
          "1 раз в 8 лет",
          "1 раз в 9 лет",
          "1 раз в 11 лет",
          "1 раз в 10 лет",
        ],
        correctAnswers: ["1 раз в 8 лет"],
      },
      {
        code: "20309284",
        text: "С какой периодичностью должны проводиться испытания на плотность и прочность горячих участков (с температурой 200 - 400 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?",
        answers: [
          "1 раз в 8 лет",
          "1 раз в 7 лет",
          "1 раз в 5 лет",
          "1 раз в 10 лет",
        ],
        correctAnswers: ["1 раз в 5 лет"],
      },
      {
        code: "20309285",
        text: "В каких местах допускается прокладка водородопроводов?",
        answers: [
          "Над поверхностью земли, на стойках и эстакадах",
          "Через производственные помещения, не связанные с потреблением водорода",
          "По стенам зданий на участках со сплошным остеклением и по легкосбрасываемым ограждающим конструкциям",
        ],
        correctAnswers: ["Над поверхностью земли, на стойках и эстакадах"],
      },
      {
        code: "20309286",
        text: "Через какие помещения допускается прокладка водородопроводов?",
        answers: [
          "Через производственные помещения, связанные с потреблением водорода",
          "Через электрораспределительные, трансформаторные помещения, вентиляционные камеры",
          "Через бытовые, подсобные и административно-хозяйственные помещения",
          "Через помещения КИП",
        ],
        correctAnswers: [
          "Через производственные помещения, связанные с потреблением водорода",
        ],
      },
      {
        code: "20309287",
        text: "Какие требования к размещению запорной арматуры на водородопроводах указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Ручной привод арматуры должен располагаться на высоте не более 3,5 м от уровня площадки или пола помещения",
          "В отдельных случаях допускается применение запорной арматуры из ковкого и высокопрочного чугуна при давлении не более 3,0 МПа и температуре от 10 °C до 40 °C, работающей в условиях, не подверженных вибрациям и резко переменному температурному режиму",
          "Арматура должна быть закреплена так, чтобы в результате температурных деформаций газопроводов в них не создавались изгибающие напряжения",
          "Арматура должна размещаться над дверными проемами и технологическими проходами",
        ],
        correctAnswers: [
          "Арматура должна быть закреплена так, чтобы в результате температурных деформаций газопроводов в них не создавались изгибающие напряжения",
        ],
      },
      {
        code: "20309288",
        text: "Какое допускается содержание кислорода в инертном газе, предназначенном для продувки водородной системы?",
        answers: [
          "Не должно превышать 1%",
          "Не должно превышать 2%",
          "Не должно превышать 3%",
          "Не должно превышать 5%",
        ],
        correctAnswers: ["Не должно превышать 1%"],
      },
      {
        code: "20309289",
        text: "Какой должен быть общий объем инертного газа для нужд продувки водородной системы?",
        answers: [
          "Должен быть не менее 4 кратного объема продуваемой водородной системы",
          "Должен быть не менее 3 кратного объема продуваемой водородной системы",
          "должен быть не менее 2 кратного объема продуваемой водородной системы",
        ],
        correctAnswers: [
          "Должен быть не менее 4 кратного объема продуваемой водородной системы",
        ],
      },
      {
        code: "20309290",
        text: "Каким газом должны продуваться перед пуском и после остановки установки по производству водорода?",
        answers: ["Азотом", "Кислородом", "Ксеноном", "Аргоном"],
        correctAnswers: ["Азотом"],
      },
      {
        code: "20309291",
        text: "Что должно предусматриваться для обеспечения требуемых режимов эксплуатации установки по производству водорода? Выберите правильный вариант ответа.",
        answers: [
          "Только управление и контроль всех технологических параметров оборудования из операторной",
          "Только дистанционный контроль оборудования из операторной",
          "Только автоматическое отключение установки по производству водорода при нарушении нормальной работы по технологическим параметрам",
          "Только дистанционное (аварийное) отключение установки по производству водорода",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20309292",
        text: "Какие требования к помещению операторной указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Операторные должны размещаться в производственных помещениях",
          "Полы в операторной должны быть диэлектрическими",
          "Помещение операторной при площади свыше 150 м² должно иметь один выход",
          "В помещении операторной, расположенном на первом этаже, должно быть не менее два выхода",
        ],
        correctAnswers: ["Полы в операторной должны быть диэлектрическими"],
      },
      {
        code: "20309293",
        text: "Какое минимальное количество выходов допускается в помещении операторной при площади свыше 150 м²?",
        answers: ["2", "1", "3"],
        correctAnswers: ["2"],
      },
      {
        code: "20309294",
        text: "Какое минимальное количество выходов допускается в помещении операторной, расположенном на первом этаже?",
        answers: ["1", "2", "3"],
        correctAnswers: ["1"],
      },
      {
        code: "20309295",
        text: "Где допускается размещение операторных помещений?",
        answers: [
          "В отдельных щитовых помещениях (встроенных или пристроенных)",
          "Над помещениями с взрывоопасными зонами любого класса",
          "Под производственными помещениями с мокрым технологическим процессом",
          "Под приточными вентиляционными камерами",
        ],
        correctAnswers: [
          "В отдельных щитовых помещениях (встроенных или пристроенных)",
        ],
      },
      {
        code: "20309296",
        text: "Чему должны соответствовать параметры процесса эксплуатации и ремонта оборудования?",
        answers: [
          "Должны соответствовать только требованиям установленного технологического регламента",
          "Должны соответствовать только рекомендациям завода-изготовителя",
          "Должны соответствовать только паспортным данным оборудования",
          "Всему перечисленному",
        ],
        correctAnswers: ["Всему перечисленному"],
      },
      {
        code: "20309297",
        text: "Какие производственные помещения должны быть оборудованы системами связи и сигнализации? Выберите правильный вариант ответа.",
        answers: [
          "Только помещения, связанные с получением водорода",
          "Только помещения, связанные с хранением водорода",
          "Только помещения, связанные с применением водорода",
          "Все производственные помещения, связанные с получением, хранением и применением водорода",
        ],
        correctAnswers: [
          "Все производственные помещения, связанные с получением, хранением и применением водорода",
        ],
      },
      {
        code: "20309298",
        text: "Какая допускается максимальная погрешность (точность измерения) газоанализаторов водорода?",
        answers: ["0,2% об", "0,3% об", "0,5% об"],
        correctAnswers: ["0,2% об"],
      },
      {
        code: "20309299",
        text: "В каких помещениях предупреждающий и аварийный сигналы должны подаваться у входа вне помещения?",
        answers: [
          "В пультовой",
          "Во всех помещениях с постоянным пребыванием обслуживающего персонала",
          "В операторной",
          "В помещении с периодическим пребыванием персонала",
        ],
        correctAnswers: ["В помещении с периодическим пребыванием персонала"],
      },
      {
        code: "20309300",
        text: "Какие требования к пневматическим КИПиА указаны неверно?",
        answers: [
          "Для пневматических КИПиА должны предусматриваться специальные установки",
          "Для пневматических КИПиА должны предусматриваться отдельные сети сжатого воздуха",
          "Воздухопроводы для КИПиА должны иметь буферные емкости, обеспечивающие запас сжатого воздуха для работы приборов",
          "Воздухопроводы для КИПиА должны быть связанными с трубопроводами на технологические нужды",
        ],
        correctAnswers: [
          "Воздухопроводы для КИПиА должны быть связанными с трубопроводами на технологические нужды",
        ],
      },
      {
        code: "20309301",
        text: "Чем допускается тушить пламя при возгорании водорода? Выберите два варианта ответа.",
        answers: ["Водой", "Огнетушителями", "Песком", "Кошмой"],
        correctAnswers: ["Песком", "Кошмой"],
      },
      {
        code: "20309302",
        text: "Какие из перечисленных требований при ведении работ на объектах производства водорода указаны неверно?",
        answers: [
          "Работники на объектах производства водорода должны быть обеспечены СИЗ, спецодеждой, спецобувью и другими средствами, которые должны отвечать требованиям соответствующих стандартов безопасности труда",
          "Открытые движущиеся части машин и механизмов, а также ременные, цепные и зубчатые передачи должны быть снабжены ограждениями, исключающими опасность травмирования людей этими частями и попадания в них посторонних предметов",
          "Узлы, детали, приспособления и элементы оборудования, которые могут служить источником опасности для работающих, а также поверхности оградительных и защитных устройств должны быть окрашены в сигнальные цвета",
          "В исключительных случаях допускается входить на объекты с производством и обращением водорода в одежде, способной накапливать заряды статического электричества",
        ],
        correctAnswers: [
          "В исключительных случаях допускается входить на объекты с производством и обращением водорода в одежде, способной накапливать заряды статического электричества",
        ],
      },
      {
        code: "20309303",
        text: "Какой порядок действий персонала при пожаре на установке получения водорода указан верно?",
        answers: [
          "При пожаре следует немедленно произвести аварийное отключение установки. Кроме того, в первую очередь следует вызвать пожарную команду, далее перекрыть подачу технологических газов на установку, снизить давление и выпустить водород в факельную систему для утилизации либо в обоснованных проектом случае в атмосферу",
          "При пожаре следует немедленно перекрыть подачу технологических газов на установку, снизить давление и выпустить водород в факельную систему для утилизации либо в обоснованных проектом случае в атмосферу. Далее следует вызвать пожарную команду",
          "При пожаре следует немедленно вызвать газоспасательную службу. Далее следует произвести аварийное отключение установки и покинуть место работы, поставив в известность непосредственного руководителя",
        ],
        correctAnswers: [
          "При пожаре следует немедленно произвести аварийное отключение установки. Кроме того, в первую очередь следует вызвать пожарную команду, далее перекрыть подачу технологических газов на установку, снизить давление и выпустить водород в факельную систему для утилизации либо в обоснованных проектом случае в атмосферу",
        ],
      },
      {
        code: "20309304",
        text: "Какие системы должны быть проверены в обязательном порядке перед началом пуска установок получения водорода? Выберите правильный вариант ответа.",
        answers: [
          "Только теплоснабжение, водоснабжение и электроснабжение",
          "Только воздухоснабжение",
          "Только системы охлаждения и снабжения инертными газами, системы отопления и вентиляции",
          "Все перечисленные системы инженерного обеспечения",
        ],
        correctAnswers: ["Все перечисленные системы инженерного обеспечения"],
      },
      {
        code: "20309305",
        text: "В соответствии с какой документацией должны производиться подготовка к пуску и пуск установок по производству водорода?",
        answers: [
          "В соответствии с производственной документацией",
          "В соответствии с конструкторской документацией",
          "Только в соответствии с инструкцией по эксплуатации",
          "В соответствии с технологическим регламентом, инструкцией по эксплуатации и проектом",
        ],
        correctAnswers: [
          "В соответствии с технологическим регламентом, инструкцией по эксплуатации и проектом",
        ],
      },
      {
        code: "20309306",
        text: "Каким образом не допускается определять утечки газов из соединений?",
        answers: [
          "С помощью открытого пламени",
          "С помощью специальных течеискателей",
          "С помощью мыльного раствора",
        ],
        correctAnswers: ["С помощью открытого пламени"],
      },
      {
        code: "20309307",
        text: "Когда могут производиться ремонтные работы с открытым огнем в помещении с обращением водорода водородной станции?",
        answers: [
          "Не ранее чем через 4 часа после отключения установки, проведения анализа воздуха на отсутствие водорода и при соблюдении мер безопасности при огневых работах",
          "Не ранее чем через 3 часа после отключения установки, проведения анализа воздуха на отсутствие водорода и при соблюдении мер безопасности при огневых работах",
          "Не ранее чем через 2 часа после отключения установки, проведения анализа воздуха на отсутствие водорода и при соблюдении мер безопасности при огневых работах",
        ],
        correctAnswers: [
          "Не ранее чем через 4 часа после отключения установки, проведения анализа воздуха на отсутствие водорода и при соблюдении мер безопасности при огневых работах",
        ],
      },
      {
        code: "20309308",
        text: "На каких аппаратах, заполненных водородом, допускается производство ремонтных работ?",
        answers: [
          "Производство ремонтных работ на аппаратах, заполненных водородом, не допускается",
          "На аппаратах, заполненных водородом не более чем на 20% от общего объема",
          "На аппаратах, установленных вне взрывопожароопасных помещений",
        ],
        correctAnswers: [
          "Производство ремонтных работ на аппаратах, заполненных водородом, не допускается",
        ],
      },
      {
        code: "20309309",
        text: "В соответствии с каким документом работникам необходимо соблюдать требования безопасности при загрузке и выгрузке катализаторов и адсорбентов?",
        answers: [
          "В соответствии с технологическим регламентом",
          "В соответствии с конструкторской документацией",
          "В соответствии с производственной документацией",
          "В соответствии с инструкцией по эксплуатации",
        ],
        correctAnswers: ["В соответствии с технологическим регламентом"],
      },
      {
        code: "20309310",
        text: "Какие требования к аварийной системе вентиляции в компрессорном помещении при получении водорода методом диссоциации аммиака указаны неверно?",
        answers: [
          "Аварийная система вентиляции должна обеспечивать в компрессорном помещении 8-кратный воздухообмен",
          "Аварийная система вентиляции должна включаться в работу автоматически при наличии в помещении газов, достигших 20% нижнего предела взрываемости",
          "Аварийная система вентиляции должна включаться в работу автоматически при 0,2 % содержания водорода в воздухе помещения",
        ],
        correctAnswers: [
          "Аварийная система вентиляции должна включаться в работу автоматически при 0,2 % содержания водорода в воздухе помещения",
        ],
      },
      {
        code: "20309311",
        text: "С каким минимальным количеством независимых источников электроснабжения должно быть выполнено электропитание оборудования производства водорода?",
        answers: ["1", "2", "3", "4"],
        correctAnswers: ["2"],
      },
      {
        code: "20309312",
        text: "Что должно быть предусмотрено автоматизированной системой управления процессом при получении водорода методом диссоциации аммиака? Выберите правильный вариант ответа.",
        answers: [
          "Только светозвуковая сигнализация при достижении предельной концентрации водорода под кровлей помещения диссоциаторов и компрессорной водорода",
          "Только регулирование теплового режима диссоциаторов",
          "Только сигнализация понижения или повышения температуры диссоциации",
          "Только обеспечение равномерной подачи аммиака к нижнему диссоциатору",
          "Только регулирование давления в коллекторе диссоциированного аммиак",
          "Только регулирование давления диссоциированного аммиака после компрессора",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20309313",
        text: "Какие требования для предотвращения взрывоопасных концентраций под кровлей помещения диссоциаторов и компрессорной водорода указаны неверно?",
        answers: [
          "Для предотвращения взрывоопасных концентраций должны быть установлены датчики довзрывных концентраций",
          "Количество и места расположения датчиков довзрывных концентраций должны определяться самостоятельно начальником газозащитной станции по производству водорода",
          "Светозвуковая сигнализация должна иметь вывод на пульт оператора и в помещение пожарного депо предприятия",
        ],
        correctAnswers: [
          "Количество и места расположения датчиков довзрывных концентраций должны определяться самостоятельно начальником газозащитной станции по производству водорода",
        ],
      },
      {
        code: "20309314",
        text: "Какие требования к процессу получения водорода и диссоциированного аммиака указаны неверно?",
        answers: [
          "Процесс получения водорода и диссоциированного аммиака должен быть автоматизирован",
          "Регулировка и контроль производится с помощью приборов",
          "Аппаратура автоматического регулирования, контроля и сигнализации должна быть установлена на пульте управления, расположенном в помещении, где размещены диссоциаторы",
        ],
        correctAnswers: [
          "Аппаратура автоматического регулирования, контроля и сигнализации должна быть установлена на пульте управления, расположенном в помещении, где размещены диссоциаторы",
        ],
      },
      {
        code: "20309315",
        text: "С какой периодичностью оператору установки необходимо производить анализ на содержание аммиака в помещении диссоциаторов методами и приборами, установленными в технологическом регламенте (технологической инструкции)?",
        answers: [
          "Не реже одного раза в смену",
          "Не реже двух раз в месяц",
          "Не реже одного раза в неделю",
          "Два раза в сутки",
        ],
        correctAnswers: ["Не реже одного раза в смену"],
      },
      {
        code: "20309316",
        text: "Какое испытательное давление должно приниматься для участка газопровода чистого газа от дроссельной группы до листовой задвижки, где P2 - расчетное избыточное давление (по проекту) газовой среды на участке, МПа?",
        answers: ["0,5Р2", "1,5P2", "1,25P2"],
        correctAnswers: ["1,25P2"],
      },
    ],
    20310: [
      {
        code: "20310000",
        text: "Что должно быть разработано для обеспечения безопасности при освоении новых производств, технологических процессов и технических устройств?",
        answers: [
          "Инструкции по эксплуатации",
          "Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования",
          "Инструкция по проведению пусконаладочных работ",
        ],
        correctAnswers: [
          "Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования",
        ],
      },
      {
        code: "20310001",
        text: "Что не допускается к загрузке в агрегаты, содержащие расплавленный металл или шлак?",
        answers: [
          "Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции",
          "Загрузка шихты, не прошедшей пиротехнического контроля",
          "Загрузка пакетированного металлолома",
          "Допускается все перечисленное",
        ],
        correctAnswers: [
          "Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции",
        ],
      },
      {
        code: "20310002",
        text: "При каких условиях запрещается эксплуатация плавильных агрегатов?",
        answers: [
          "При незначительном падении давления воды в системе водоохлаждения",
          "При разгерметизации системы водяного охлаждения этих агрегатов",
          "При незначительном повышении давления воды в системе охлаждения",
        ],
        correctAnswers: [
          "При разгерметизации системы водяного охлаждения этих агрегатов",
        ],
      },
      {
        code: "20310003",
        text: "В какой зоне не допускается нахождение людей?",
        answers: [
          "В зоне работы электромостовых кранов",
          "В зоне погрузки грейферными или магнитными кранами",
          "В зоне работы кран-балок",
          "Во всех перечисленных зонах",
        ],
        correctAnswers: ["В зоне погрузки грейферными или магнитными кранами"],
      },
      {
        code: "20310004",
        text: "После чего разрешается производить слив расплава в ковши, вышедшие из ремонта?",
        answers: [
          "Только после тщательного их просушивания и разогрева",
          "После выдержки в течение суток при температуре 40 °С",
          "После выдержки в течение одной смены при температуре свыше 40 °С",
        ],
        correctAnswers: [
          "Только после тщательного их просушивания и разогрева",
        ],
      },
      {
        code: "20310005",
        text: "При наличии каких дефектов ковшей их эксплуатация не допускается?",
        answers: [
          "Утеряна форма вследствие деформации",
          "Качаются цапфы в теле ковша",
          "Образовались раковины, трещины в стенках ковшей и в местах крепления цапф",
          "При наличии всех перечисленных дефектов",
        ],
        correctAnswers: ["При наличии всех перечисленных дефектов"],
      },
      {
        code: "20310006",
        text: "Какие требования установлены к загружаемым в печи руде, ферросплавам и другим материалам?",
        answers: [
          "Должны быть выдержаны в помещении не менее смены",
          "Должны быть выдержаны в помещении не менее суток",
          "Должны быть предварительно просушены или прокалены",
        ],
        correctAnswers: ["Должны быть предварительно просушены или прокалены"],
      },
      {
        code: "20310007",
        text: "Какие требования установлены к фурмам для продувки жидкого металла газами?",
        answers: [
          "Перед установкой фурмы должны храниться в специальном помещении не менее 2 суток",
          "Перед установкой фурмы должны храниться в специальном помещении не менее 5 суток",
          "Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями",
          "Фурмы должны иметь цилиндрическую форму",
          "Должны быть защищены специальными экранами от нагревания теплоизлучением",
        ],
        correctAnswers: [
          "Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями",
        ],
      },
      {
        code: "20310008",
        text: "Какие требования установлены к установке расходных баков с мазутом?",
        answers: [
          "Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением",
          "Должны быть установлены на расстоянии не менее 15 м от печей",
          "Должны быть установлены на расстоянии не менее 10 м от печей и должны быть защищены паровой завесой",
        ],
        correctAnswers: [
          "Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением",
        ],
      },
      {
        code: "20310009",
        text: "Как должны быть расположены пульты управления агрегатами?",
        answers: [
          "В непосредственной близости от агрегатов",
          "На расстоянии не менее 5 м от агрегатов",
          "В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке",
        ],
        correctAnswers: [
          "В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке",
        ],
      },
      {
        code: "20310010",
        text: "Что должны иметь закрома и ямы?",
        answers: [
          "Должны иметь вдоль периметра пешеходные проходы шириной 0,8 м",
          "Должны иметь вдоль периметра пешеходные проходы шириной 1,0 м",
          "Со всех сторон должны иметь ограждение",
        ],
        correctAnswers: ["Со всех сторон должны иметь ограждение"],
      },
      {
        code: "20310011",
        text: "Как должна устраняться пробуксовка ленты конвейера?",
        answers: [
          "Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты",
          "Должна устраняться при помощи ручной регулировки",
          "Должна устраняться увеличением трения между тянущим барабаном и лентой с использованием специальных материалов",
        ],
        correctAnswers: [
          "Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты",
        ],
      },
      {
        code: "20310012",
        text: "Что должно быть исключено в коммуникациях системы транспортирования взрывопожароопасных веществ и материалов?",
        answers: [
          "Образование взрывоопасной среды",
          "Попадание воздушной среды",
          "Возможность разгерметизации системы",
        ],
        correctAnswers: ["Образование взрывоопасной среды"],
      },
      {
        code: "20310013",
        text: "На что должна быть проверена система пневмотранспорта перед вводом в эксплуатацию?",
        answers: [
          "На плотность под рабочим давлением",
          "Должна подвергаться гидроиспытаниям",
          "Должен быть проведен внешний осмотр",
        ],
        correctAnswers: ["На плотность под рабочим давлением"],
      },
      {
        code: "20310014",
        text: "Чем должны быть оборудованы шлаковозы?",
        answers: [
          "Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением",
          "Механизмами кантования (поворота) чаши с ручным пневматическим приводом и автоматическим управлением",
          "Механизмами кантования (поворота) чаши с ручным гидравлическим приводом",
        ],
        correctAnswers: [
          "Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением",
        ],
      },
      {
        code: "20310015",
        text: "Что должно иметься на шлаковом отвале?",
        answers: [
          "Двусторонняя громкоговорящая связь с диспетчерской службой",
          "Телефонная или радиосвязь с диспетчерской службой производства",
          "Мобильная связь с диспетчерской службой предприятия",
        ],
        correctAnswers: [
          "Телефонная или радиосвязь с диспетчерской службой производства",
        ],
      },
      {
        code: "20310016",
        text: "Какие шпалы должны применяться на железнодорожных путях шлакового отвала?",
        answers: [
          "Деревянные шпалы",
          "Деревянные шпалы, пропитанные  креозотом",
          "Огнестойкие шпалы",
        ],
        correctAnswers: ["Огнестойкие шпалы"],
      },
      {
        code: "20310017",
        text: "Какие требования установлены к кабине завалочной машины?",
        answers: [
          "Должна быть удобной в эксплуатации",
          "Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака",
          "Конструкция завалочной машины должна обеспечить устойчивое выполнение операций",
          "Должна иметь металлическую сетку на смотровых стеклах",
        ],
        correctAnswers: [
          "Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака",
        ],
      },
      {
        code: "20310018",
        text: "Что должна обеспечить система светозвуковой сигнализации?",
        answers: [
          "Оповещение эксплуатационного персонала о начале и окончании смены",
          "Оповещение эксплуатационного персонала о приближении технологического транспорта",
          "Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств",
        ],
        correctAnswers: [
          "Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств",
        ],
      },
      {
        code: "20310019",
        text: "Какие требования установлены к расположению КИПиА?",
        answers: [
          "Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования",
          "Приборы должны устанавливаться в непосредственной близости к оборудованию",
          "Приборы должны устанавливаться на расстоянии не менее 5 м от оборудования",
        ],
        correctAnswers: [
          "Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования",
        ],
      },
      {
        code: "20310020",
        text: "Какие требования установлены к проверке и испытанию приборов во взрывопожароопасных и пожароопасных помещениях?",
        answers: [
          "Должны производиться в условиях, исключающих искрообразование",
          "Проверка может производиться непосредственно во  врывопожароопасных  и пожароопасных помещениях",
          "Проверка должна осуществляться только в специально выделенном для этого помещении",
        ],
        correctAnswers: [
          "Должны производиться в условиях, исключающих искрообразование",
        ],
      },
      {
        code: "20310021",
        text: "Какие требования предъявляются к устройству и эксплуатации печей для обжига шихтовых материалов и концентратов?",
        answers: [
          "Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 5%",
          "Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 10%",
          "Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции",
        ],
        correctAnswers: [
          "Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции",
        ],
      },
      {
        code: "20310022",
        text: "Какие требования установлены к хранению стержней, шаров, футеровки, запасных деталей и приспособлений?",
        answers: [
          "Должно быть предусмотрено в специально  отведенных выделенных местах на стеллажах или в таре",
          "Должно быть предусмотрено на свободных площадках, обеспечивающих свободный проход",
          "Должно быть предусмотрено в специальном помещении",
        ],
        correctAnswers: [
          "Должно быть предусмотрено в специально  отведенных выделенных местах на стеллажах или в таре",
        ],
      },
      {
        code: "20310023",
        text: "Куда должны заноситься записи о проведении технического обслуживания, выявленных дефектах и нарушениях газопроводов?",
        answers: [
          "В эксплуатационный журнал",
          "В паспорт оборудования",
          "В специальный журнал по устранению дефектов",
        ],
        correctAnswers: ["В эксплуатационный журнал"],
      },
      {
        code: "20310024",
        text: "Какие требования установлены к проведению ремонтных работ внутри нагретых технических устройств?",
        answers: [
          "Проведение ремонтных работ разрешается после их продувки и снижения температуры воздуха в них до 50 °C",
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 55 °C",
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C",
        ],
        correctAnswers: [
          "Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C",
        ],
      },
      {
        code: "20310025",
        text: "Какие требования установлены к процессу сливания расплава металла из ковшей и миксеров? Выберите правильный вариант ответа.",
        answers: [
          "Ось сливного отверстия ковша, установленного под слив, должна совпадать с осью заливочного ковша",
          "Пол рабочей площадки в местах установки ковшей должен быть сухим",
          "Сливание расплава металла из ковшей и миксеров должно быть механизировано",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20310026",
        text: "Какие требования установлены к инструментам, приспособлениям и оснастке, используемым для ведения технологии или обслуживания оборудования?",
        answers: [
          "Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними",
          "Должны быть предназначены для выполнения любых работ",
          "Должны быть выполнены из материалов, исключающих искрообразование",
        ],
        correctAnswers: [
          "Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними",
        ],
      },
      {
        code: "20310027",
        text: "Какие требования установлены к скорости движения железнодорожного транспорта на шлаковом дворе?",
        answers: [
          "Скорость не должна превышать 5 км/ч",
          "Скорость не должна превышать 7 км/ч",
          "Скорость не должна превышать 10 км/ч",
          "Скорость не должна превышать 15 км/ч",
        ],
        correctAnswers: ["Скорость не должна превышать 5 км/ч"],
      },
      {
        code: "20310028",
        text: "Какой запас прочности должны иметь канаты для подвески и подъема контргрузов?",
        answers: [
          "Десятикратный",
          "Восьмикратный",
          "Пятикратный",
          "Шестикратный",
        ],
        correctAnswers: ["Восьмикратный"],
      },
      {
        code: "20310029",
        text: "Какие требования к производственным помещениям, оборудованию участков приготовления травильных растворов и травления металла указаны неверно? Выберите правильный вариант ответа.",
        answers: [
          "Периодичность проведения уборки помещений устанавливается в зависимости от условий их эксплуатации",
          "Допускается уборка, вызывающая распыление вредных веществ",
          "После работы у ванн травления с химически опасными веществами, используемыми в виде добавок для технологических растворов, вещества должны быть удалены из рабочей зоны на склад",
          "Вентиляционные системы, состояние ванн травления ежедневно должны проверяться на работоспособность",
        ],
        correctAnswers: [
          "Допускается уборка, вызывающая распыление вредных веществ",
        ],
      },
      {
        code: "20310030",
        text: "Чему подлежат производственные емкости (ковши, шлаковые чаши, совки, кюбели, тара, корзины) после изготовления или ремонта?",
        answers: [
          "Освидетельствованию",
          "Проверке состояния",
          "Механическим испытаниям",
          "Регистрации",
        ],
        correctAnswers: ["Проверке состояния"],
      },
      {
        code: "20310031",
        text: "Кем могут проводиться работы повышенной опасности на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "Только работниками подрядных организаций",
          "Только работниками эксплуатирующей организации",
          "Как работниками эксплуатирующей организации, так и работниками подрядных организаций",
        ],
        correctAnswers: [
          "Как работниками эксплуатирующей организации, так и работниками подрядных организаций",
        ],
      },
      {
        code: "20310032",
        text: "В каком количестве экземпляров составляется акт-допуск подрядных организаций на опасный производственный объект горно-металлургической промышленности?",
        answers: [
          "В двух экземплярах",
          "В четырех экземплярах",
          "В одном экземпляре",
          "В трех экземплярах",
        ],
        correctAnswers: ["В двух экземплярах"],
      },
      {
        code: "20310033",
        text: "Какое из перечисленных требований к применению жетон-бирочной системы указано неверно?",
        answers: [
          'Ответственный специалист, разобравший схему, принимает меры, исключающие ошибочное или самопроизвольное включение пусковых устройств, на пусковых устройствах вывешивает предупредительные плакаты "Не включать! Работают люди", проверяет отсутствие энергетического потенциала',
          "Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями",
          "Жетон-бирочная система применяется при выводе ТУ на продолжительную остановку",
          "Жетон-бирки после разборки электрической схемы ТУ передаются от технологического персонала ремонтному персоналу с отметкой всех этапов в журнале выдачи и приема жетон-бирок",
        ],
        correctAnswers: [
          "Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями",
        ],
      },
      {
        code: "20310034",
        text: "Какие работы производятся на ОПО горно-металлургических производств? Выберите правильный вариант ответа.",
        answers: [
          "Работы, связанные с аварийными ситуациями",
          "Постоянные работы, выполняемые в соответствии с документированной процедурой (инструкцией, картой, регламентом), принятой в организации",
          "Работы повышенной опасности (сложные, разовые, уникальные)",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20310035",
        text: "Какие требования установлены к состоянию вакуум-ковшей, тиглей, коробов, кристаллизаторов, изложниц и других емкостей для расплава перед заливкой в них расплава в электролитическом производстве магния?",
        answers: [
          "Должны быть выдержаны в помещении не менее одной смены",
          "Должны быть выдержаны в помещении не менее суток",
          "Должны быть очищены от мусора",
          "Должны быть очищены от наледи",
        ],
        correctAnswers: ["Должны быть очищены от мусора"],
      },
      {
        code: "20310036",
        text: "Чем должны быть оборудованы места пересечения железнодорожных путей для подачи составов ковшей с жидким чугуном с путями для подачи шихтовых материалов?",
        answers: [
          "Должны быть оборудованы автоматической сигнализацией",
          "Должны быть оборудованы световой сигнализацией",
          "Должны быть оборудованы звуковой сигнализацией",
          "Должны быть оборудованы автоматическими шлагбаумами",
        ],
        correctAnswers: [
          "Должны быть оборудованы автоматической сигнализацией",
        ],
      },
      {
        code: "20310037",
        text: "Какие требования установлены к ведению взрывных работ, хранению, транспортированию взрывчатых материалов и эксплуатации броневых ям?",
        answers: [
          "Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах",
          "Работы могут производиться взрывниками в процессе эксплуатации оборудования цеха",
          "Работы должны производиться специально обученным цеховым обслуживающим персоналом",
        ],
        correctAnswers: [
          "Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах",
        ],
      },
      {
        code: "20310038",
        text: "Какие из перечисленных требований к акту-допуску подрядных организаций на опасный производственный объект горно-металлургической промышленности указаны неверно?",
        answers: [
          "Не допускается оформлять акты-допуски, характеризующиеся одинаковыми координатами рабочих зон",
          "Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)",
          "Акт-допуск должен оформляться письменно",
          "На один объект, площадку, территорию оформляется один акт-допуск",
        ],
        correctAnswers: [
          "Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)",
        ],
      },
      {
        code: "20310039",
        text: "Какие требования установлены к срокам осмотра баков (емкостей) для смазочных материалов и баков для варки смолы и к осмотру и чистке смотровых люков и вытяжных труб баков для варки смолы?",
        answers: [
          "Осмотр должен производиться не реже одного раза в три года, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно",
          "Осмотр должен производиться не реже одного раза в два года, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежесменно",
          "Осмотр должен производиться не реже одного раза в год, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно",
          "Осмотр должен производиться не реже одного раза в год, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежесменно",
        ],
        correctAnswers: [
          "Осмотр должен производиться не реже одного раза в год, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно",
        ],
      },
      {
        code: "20310040",
        text: "В течение какого времени должны подаваться звуковые и световые сигналы перед пуском в работу технического устройства, узлы которого или все устройство перемещаются в процессе работы?",
        answers: [
          "Не менее 15 секунд",
          "Не менее 20 секунд",
          "Не менее 5 секунд",
          "Не менее 10 секунд",
        ],
        correctAnswers: ["Не менее 10 секунд"],
      },
      {
        code: "20310041",
        text: "С какой периодичностью должны проверяться на работоспособность вентиляционные системы и состояние ванн травления?",
        answers: ["Еженедельно", "Ежедневно", "Ежемесячно", "Ежеквартально"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "20310042",
        text: "Как должны подготавливаться растворы кислот и щелочей?",
        answers: [
          "Кислота и щелочь должны заливаться небольшой струей в воду, нагретую до температуры 70 °C",
          "Кислота и щелочь должны заливаться небольшой струей в холодную воду",
          "Вода должна заливаться небольшой струей в кислоту и щелочь",
          "Не регламентируется",
        ],
        correctAnswers: [
          "Кислота и щелочь должны заливаться небольшой струей в холодную воду",
        ],
      },
      {
        code: "20310043",
        text: "Какие мероприятия должны быть выполнены при прогаре водоохлаждаемых элементов печи и попадании воды в зону плавления печи?",
        answers: [
          "Немедленно должна быть отключена подача воды",
          "Должна быть снижена нагрузка на печь",
          "Печь должна быть немедленно отключена",
        ],
        correctAnswers: ["Печь должна быть немедленно отключена"],
      },
      {
        code: "20310044",
        text: "Какие действия предпринимаются при необходимости ведения работ после истечения срока действия акта-допуска подрядных организаций на опасный производственный объект горно-металлургической промышленности?",
        answers: [
          "Составляется новый акт-допуск на следующий срок",
          "Продлевается текущий акт-допуск",
          "Работы продолжаются без оформления акта-допуска",
          "Работы продолжаются по письменному распоряжению начальника ОПО",
        ],
        correctAnswers: ["Составляется новый акт-допуск на следующий срок"],
      },
      {
        code: "20310045",
        text: "Что должно быть предусмотрено при проектировании конвейеров для подачи материалов? Выберите правильный вариант ответа.",
        answers: [
          "Предохраняющие устройства, отключающие приводы при перегрузке",
          "Исключение падения транспортируемых материалов",
          "Ограждения приводных, натяжных и отклоняющих барабанов, натяжных устройств, канатов и блоков натяжных устройств, ременных, червячных, муфтовых и других передач",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20310046",
        text: "Какие требования установлены к месту расстановки ковшей, предназначенных для расплава металла?",
        answers: [
          "Должны расставляться только на горизонтальных площадках в любом месте пролета цеха",
          "Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства",
          "Должны расставляться на свободных площадках пролета цеха",
        ],
        correctAnswers: [
          "Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства",
        ],
      },
      {
        code: "20310047",
        text: "Какое требование к устройству внутренних производственных помещений, находящихся на производственной площадке, включающей технологию с расплавами металлов, имеющей опасность аварии или травмирования работников, указано неверно?",
        answers: [
          "Окна пультов управления, в которые возможно попадание брызг расплава, должны оборудоваться в соответствии с проектом",
          "Помещения пультов управления должны иметь вытяжную вентиляцию",
          "Помещения должны иметь защиту от воздействия теплового излучения",
          "Помещения должны иметь не менее двух выходов, расположенных с учетом аварийной эвакуации работников; исполнением дверей помещения с открыванием наружу и не иметь внутренних запоров",
        ],
        correctAnswers: [
          "Помещения пультов управления должны иметь вытяжную вентиляцию",
        ],
      },
      {
        code: "20310048",
        text: "От чего должны быть защищены составные части производственного оборудования, в том числе энергетические трубопроводы, рукава подачи природного газа, кислорода, мазута, воздуха, масла, воды, троллеи для питания электрического оборудования тележек, перевозящих расплавы металлов, и электрические кабели?",
        answers: [
          "От тепловых воздействий",
          "От возможного попадания на них расплава",
          "От механических воздействий",
          "От всего перечисленного",
        ],
        correctAnswers: ["От всего перечисленного"],
      },
      {
        code: "20310049",
        text: 'Какой напорный бак должен быть установлен в печи "Аусмелт" на случай отключения электроснабжения?',
        answers: [
          "Обеспечивающий подачу воды в течение 15 минут с момента отказа системы циркуляционного водяного охлаждения",
          "Обеспечивающий подачу воды в течение 10 минут с момента отказа системы циркуляционного водяного охлаждения",
          "Обеспечивающий подачу воды в течение 5 минут с момента отказа системы циркуляционного водяного охлаждения",
          "Обеспечивающий подачу воды в течение 20 минут с момента отказа системы циркуляционного водяного охлаждения",
        ],
        correctAnswers: [
          "Обеспечивающий подачу воды в течение 15 минут с момента отказа системы циркуляционного водяного охлаждения",
        ],
      },
      {
        code: "20310050",
        text: 'До какой минимальной температуры должна быть охлаждена печь "Аусмелт" для возобновления подачи охлаждающей жидкости при перегреве печи и неисправности водоохлаждающих элементов?',
        answers: ["150 °C", "200 °C", "100 °C", "220 °C"],
        correctAnswers: ["150 °C"],
      },
      {
        code: "20310051",
        text: 'Какому гидравлическому испытанию должны подвергаться водоохлаждаемые элементы печи "Аусмелт" перед их установкой?',
        answers: [
          "На 1,5 величины рабочего давления охлаждающей жидкости",
          "На 2,0 величины рабочего давления охлаждающей жидкости",
          "На 2,5 величины рабочего давления охлаждающей жидкости",
          "На 0,5 величины рабочего давления охлаждающей жидкости",
        ],
        correctAnswers: [
          "На 1,5 величины рабочего давления охлаждающей жидкости",
        ],
      },
      {
        code: "20310052",
        text: "Каким гидравлическим давлением должны испытываться на прочность и плотность трубки системы охлаждения индуктора?",
        answers: [
          "На 1,5 величины рабочего давления охлаждающей воды",
          "На 2,0 величины рабочего давления охлаждающей воды",
          "На 1,75 величины рабочего давления охлаждающей воды",
          "На 1,25 величины рабочего давления охлаждающей воды",
        ],
        correctAnswers: ["На 1,5 величины рабочего давления охлаждающей воды"],
      },
      {
        code: "20310053",
        text: "Какие требования к вентиляции помещения, где возможно выделение водорода, указаны верно? Выберите 2 варианта ответа.",
        answers: [
          "Помещения должны быть оборудованы естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не более однократного в час",
          "При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 4 в час",
          "К постоянно работающей общеобменной вентиляции производства водорода в случае аварии дополнительно должна автоматически включаться аварийная вытяжная система",
          "Включение аварийной вентиляции должно быть связано с показаниями газоанализатора контроля атмосферы в помещениях",
        ],
        correctAnswers: [
          "К постоянно работающей общеобменной вентиляции производства водорода в случае аварии дополнительно должна автоматически включаться аварийная вытяжная система",
          "Включение аварийной вентиляции должно быть связано с показаниями газоанализатора контроля атмосферы в помещениях",
        ],
      },
      {
        code: "20310054",
        text: "Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей?",
        answers: [
          "Только системами регулирования заданного соотношения топлива, воздуха и водяного пара",
          "Только средствами сигнализации о прекращении поступления топлива, а также воздуха при его принудительной подаче в топочное пространство",
          "Только блокировками, прекращающими поступление газообразного топлива и воздуха при снижении их давления ниже установленных параметров (автономно), а также при прекращении электро- и пневмоснабжения КИПиА",
          "Только средствами автоматической подачи водяного пара или инертного газа в топочное пространство и в змеевики при прогаре труб",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20310055",
        text: "Где не допускается размещение операторных помещений?",
        answers: [
          "Только над помещениями с взрывоопасными зонами любого класса",
          "Только под душевыми, санузлами",
          "Только под производственными помещениями с мокрым технологическим процессом",
          "Только под приточными вентиляционными камерами",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20310056",
        text: "Где допускается размещение операторных помещений?",
        answers: [
          "В отдельных щитовых помещениях (встроенных или пристроенных), смежных с помещениями с взрывоопасными зонами и в отдельно стоящих зданиях",
          "Над помещениями с взрывоопасными зонами любого класса",
          "Под душевыми, санузлами",
          "Под производственными помещениями с мокрым технологическим процессом",
          "Под приточными вентиляционными камерами",
        ],
        correctAnswers: [
          "В отдельных щитовых помещениях (встроенных или пристроенных), смежных с помещениями с взрывоопасными зонами и в отдельно стоящих зданиях",
        ],
      },
      {
        code: "20310057",
        text: "Какие из перечисленных требований к стенам, отделяющим помещения операторной от помещений с взрывоопасными зонами любого класса, указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Стены, отделяющие помещения операторной от помещений с взрывоопасными зонами любого класса, должны быть выполнены из несгораемых материалов",
          "Стены, отделяющие помещения операторной от помещений с взрывоопасными зонами любого класса, должны иметь предел огнестойкости не менее 0,75 часа",
          "В стенах, отделяющих помещение операторной от помещений с взрывоопасными зонами классов В-1а и В-1б, допускается устраивать отверстия для ввода кабелей и труб электропроводки",
          "Все перечисленные требования указаны верно",
        ],
        correctAnswers: ["Все перечисленные требования указаны верно"],
      },
      {
        code: "20310058",
        text: "Какое минимальное количество выходов допускается в помещении операторной, расположенном на первом этаже?",
        answers: ["1 выход", "2 выхода", "3 выхода", "Не регламентируется"],
        correctAnswers: ["1 выход"],
      },
      {
        code: "20310059",
        text: "Какое минимальное количество выходов допускается в помещении операторной при площади свыше 150 м²?",
        answers: ["1 выход", "2 выхода", "3 выхода", "Не регламентируется"],
        correctAnswers: ["2 выхода"],
      },
      {
        code: "20310060",
        text: "Какие требования к помещению операторной указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Помещения операторной должны быть оборудованы сигнализацией для оповещения персонала о появлении отклонений от технологического процесса",
          "В помещении операторной, расположенном на первом этаже, допускается устройство одного выхода",
          "При расположении помещения операторной на втором этаже и выше выход допускается устраивать на лестничную клетку или в невзрывоопасный коридор",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20310061",
        text: "Какая электроизоляция предусмотрена при проектировании и строительстве корпусов электролиза при производстве магния?",
        answers: [
          "Электроизоляция их внутренних стен на высоту не менее 3 м, колонн - на высоту не менее 3,5 м от уровня рабочих площадок, фундаментов и опорных конструкций электролизеров",
          "Электроизоляция их внутренних стен на высоту не менее 1,5 м, колонн - на высоту не менее 2,5 м от уровня рабочих площадок, фундаментов и опорных конструкций электролизеров",
          "Электроизоляция их внутренних стен на высоту не менее 2,5 м, колонн - на высоту не менее 1,5 м от уровня рабочих площадок, фундаментов и опорных конструкций электролизеров",
        ],
        correctAnswers: [
          "Электроизоляция их внутренних стен на высоту не менее 3 м, колонн - на высоту не менее 3,5 м от уровня рабочих площадок, фундаментов и опорных конструкций электролизеров",
        ],
      },
      {
        code: "20310062",
        text: "Каким должно быть сопротивление изоляции внутренних стен и колонн электролиза при производстве магния?",
        answers: [
          "Не менее 0,5 МОм",
          "Не менее 0,1 МОм",
          "Не менее 0,3 МОм",
          "Не менее 0,4 МОм",
        ],
        correctAnswers: ["Не менее 0,5 МОм"],
      },
      {
        code: "20310063",
        text: "На каком расстоянии должны располагаться электрические распределительные устройства силовых и осветительных сетей с глухозаземленной нейтралью в электролизных корпусах производства магния от неогражденных шинопроводов и частей электролизеров, находящихся под напряжением постоянного тока?",
        answers: [
          "Не менее 6 м",
          "Не менее 5 м",
          "Не менее 3 м",
          "Не менее 1 м",
        ],
        correctAnswers: ["Не менее 6 м"],
      },
      {
        code: "20310064",
        text: "Какое количество ступеней электроизоляции от строительных конструкций корпуса электролиза должны иметь металлические трубопроводы, защитные трубы, короба и бронированные кабели, располагаемые на высоте менее 3,5 м, при производстве магния?",
        answers: ["2 ступени", "3 ступени", "4 ступени", "1 ступень"],
        correctAnswers: ["2 ступени"],
      },
      {
        code: "20310065",
        text: "Какая периодичность проведения ревизий технологических трубопроводов установлена для I и II категорий трубопровода при скорости коррозии 0,1-0,5 мм/год и транспортировке чрезвычайно, высоко и умеренно опасных веществ 1, 2, 3-го классов?",
        answers: [
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 5 лет",
          "Не реже 1 раза в 4 года",
        ],
        correctAnswers: ["Не реже 1 раза в 2 года"],
      },
      {
        code: "20310066",
        text: "Какое испытательное давление должно приниматься для участка газопровода чистого газа от дроссельной группы до листовой задвижки, где P2 - расчетное избыточное давление (по проекту) газовой среды на участке, МПа?",
        answers: ["1,25Р2", "1,5Р2", "1,1Р2", "1,35Р2"],
        correctAnswers: ["1,25Р2"],
      },
      {
        code: "20310067",
        text: "Как часто должно проверяться исправное действие автоблокировки и сигнализации?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в квартал",
          "Не реже одного раза в полгода",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20310068",
        text: "Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в неделю",
          "Не реже одного раза в 3 месяца",
        ],
        correctAnswers: ["Не реже одного раза в неделю"],
      },
      {
        code: "20310069",
        text: "Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных вне помещений?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в квартал",
          "Не реже одного раза в полгода",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20310070",
        text: "В каких случаях запрещается газовая резка и сварка на действующих газопроводах?",
        answers: [
          "На газопроводах, находящихся под разрежением",
          "После продувки газопроводов сжатым воздухом",
          "На газопроводах, находящихся под давлением",
        ],
        correctAnswers: ["На газопроводах, находящихся под разрежением"],
      },
      {
        code: "20310071",
        text: "На что должен проверяться выполненный сварной шов после врезки в действующий газопровод?",
        answers: ["На герметичность", "На плотность", "На прочность"],
        correctAnswers: ["На плотность"],
      },
      {
        code: "20310072",
        text: "Каково минимально допустимое давление газа непосредственноперед потребителем (после регулирующих органов)?",
        answers: ["0,4 кПа", "0,3 кПа", "0,5 кПа"],
        correctAnswers: ["0,5 кПа"],
      },
      {
        code: "20310073",
        text: "Разрешено ли использовать продувочные свечи для выпуска в атмосферу избыточного газа?",
        answers: [
          "Только в аварийных случаях",
          "Не разрешено",
          "Разрешено при увеличении давления газа до 5%",
          "Разрешено при увеличении давления газа до 10%",
        ],
        correctAnswers: ["Только в аварийных случаях"],
      },
      {
        code: "20310074",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии более 0,5 мм/г?",
        answers: ["Один раз в полгода", "Один раз в год", "Один раз в 2 года"],
        correctAnswers: ["Один раз в год"],
      },
      {
        code: "20310075",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии 0,1 - 0,5 мм/г?",
        answers: ["Один раз в 1,5 года", "Один раз в год", "Один раз в 2 года"],
        correctAnswers: ["Один раз в 2 года"],
      },
      {
        code: "20310076",
        text: "С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии до 0,1 мм/г?",
        answers: ["Один раз в 2 года", "Один раз в 4 года", "Один раз в 5 лет"],
        correctAnswers: ["Один раз в 4 года"],
      },
      {
        code: "20310077",
        text: "Как часто должна производиться нивелировка действующих газопроводов?",
        answers: ["Один раз в 5 лет", "Один раз в 3 года", "Ежегодно"],
        correctAnswers: ["Один раз в 5 лет"],
      },
      {
        code: "20310078",
        text: "До розжига газопотребляющего агрегата чем должен быть продут газопровод перед коллектором?",
        answers: ["Газом", "Сжатым воздухом", "Азотом"],
        correctAnswers: ["Газом"],
      },
      {
        code: "20310079",
        text: "В какие сроки в помещениях категории А должна осуществляться проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в 2 месяца",
          "Не реже одного раза в 3 месяца",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20310080",
        text: "При каких показателях газа разрешено применять жидкостные манометры?",
        answers: [
          "При давлении до 0,04 МПа",
          "При давлении до 0,03 МПа",
          "При давлении до 0,05 МПа",
        ],
        correctAnswers: ["При давлении до 0,03 МПа"],
      },
      {
        code: "20310081",
        text: "Какая допускается максимальная температура газа в газопроводах в случае подачи в них пара для пропарки, продувки и отогрева?",
        answers: ["100 °C", "85 °C", "90 °C", "95 °C"],
        correctAnswers: ["85 °C"],
      },
      {
        code: "20310082",
        text: "С кем согласовывается план организации и проведения газоопасной работы?",
        answers: [
          "С ответственным представителем подразделения или организации (главным энергетиком)",
          "Со службой производственного контроля",
          "С начальником ГСС",
          "Со всеми перечисленными",
        ],
        correctAnswers: ["Со всеми перечисленными"],
      },
      {
        code: "20310083",
        text: "Какие действия должны быть выполнены при утрате наряда-допуска на работы повышенной опасности?",
        answers: [
          "Работы должны быть прекращены, для продолжения работ должна быть внесена запись в журнал регистрации нарядов-допусков на работы повышенной опасности",
          "Работы должны быть продолжены, оформление нового наряда-допуска не требуется",
          "Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново",
        ],
        correctAnswers: [
          "Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново",
        ],
      },
      {
        code: "20310084",
        text: "Как часто должны проходить поверку контрольно-измерительные приборы в процессе эксплуатации?",
        answers: [
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в год",
          "Не реже одного раза в 2 года",
          "Не реже одного раза в 3 года",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20310085",
        text: "При каком содержании кислорода в колошниковом газе закрытых рудовосстановительных печей печь немедленно отключается?",
        answers: [
          "При увеличении до 5%",
          "При увеличении до 3%",
          "При увеличении до 1%",
          "При увеличении до 2%",
        ],
        correctAnswers: ["При увеличении до 2%"],
      },
      {
        code: "20310086",
        text: "Какое требование установлено к устройству выходных отверстий продувочных свечей на доменных печах?",
        answers: [
          "Должно быть выше верхних площадок колошника не менее чем на 4 м",
          "Должно быть выше верхних площадок колошника не менее чем на 2 м",
          "Должно быть выше верхних площадок колошника не менее чем на 3 м",
          "Должно быть выше верхних площадок колошника не менее чем на 1 м",
        ],
        correctAnswers: [
          "Должно быть выше верхних площадок колошника не менее чем на 4 м",
        ],
      },
      {
        code: "20310087",
        text: "С какой периодичностью должен производиться осмотр газопроводов, газовых установок и газового оборудования комиссией?",
        answers: [
          "Не реже одного раза в год",
          "Не реже двух раз в год",
          "Не реже одного раза в квартал",
          "Не реже одного раза в три года",
        ],
        correctAnswers: ["Не реже двух раз в год"],
      },
      {
        code: "20310088",
        text: "Какому давлению гидравлического испытания должны подвергаться водоохлаждаемые элементы печей перед их установкой?",
        answers: [
          "На 1,5 величины рабочего давления охлаждающей воды",
          "На 1,25 величины рабочего давления охлаждающей воды",
          "На 1,75 величины рабочего давления охлаждающей воды",
          "На 2,0 величины рабочего давления охлаждающей воды",
        ],
        correctAnswers: ["На 1,5 величины рабочего давления охлаждающей воды"],
      },
      {
        code: "20310089",
        text: "На какой максимальный срок выдается наряд-допуск на работы повышенной опасности?",
        answers: [
          "На 45 календарных дней",
          "На 15 рабочих дней",
          "На 30 календарных дней",
          "На 20 рабочих дней",
        ],
        correctAnswers: ["На 30 календарных дней"],
      },
      {
        code: "20310090",
        text: "Каким документом определяется максимальная величина давления газа (аргона), необходимая для открывания донных продувочных фурм и ковшевых пробок?",
        answers: [
          "Инструкцией о мерах пожарной безопасности при проведении огневых работ",
          "Технологической инструкцией",
          "Должностной инструкцией",
          "Инструкцией по охране труда",
        ],
        correctAnswers: ["Технологической инструкцией"],
      },
      {
        code: "20310091",
        text: "Какой стаж работы в газовом хозяйстве должны иметь лица, назначаемые ответственными руководителями и ответственными исполнителями газоопасных работ?",
        answers: [
          "Не менее одного года",
          "Не менее трех лет",
          "Не менее двух лет",
          "Не менее пяти лет",
        ],
        correctAnswers: ["Не менее одного года"],
      },
      {
        code: "20310092",
        text: "Как часто должна производиться нивелировка действующих газопроводов?",
        answers: ["Один раз в 5 лет", "Один раз в 3 года", "Ежегодно"],
        correctAnswers: ["Один раз в 5 лет"],
      },
      {
        code: "20310093",
        text: "Каково предельно допустимое содержание масла в кислороде, поступающем в компрессор?",
        answers: [
          "Не должно превышать 0,05 мг/м³",
          "Не должно превышать 0,03 мг/м³",
          "Не должно превышать 0,02 мг/м³",
        ],
        correctAnswers: ["Не должно превышать 0,02 мг/м³"],
      },
      {
        code: "20310094",
        text: "В каком случае установка листовой заглушки за отключающим запорным устройством не требуется при отключении участка газопровода или газового аппарата с последующим выполнением работ внутри него?",
        answers: [
          "Если отключающим устройством является листовая задвижка",
          "Если длительность работ составляет не более 30 минут",
          "Если это обусловлено проектной документацией",
          "Установка листовой заглушки требуется во всех случаях",
        ],
        correctAnswers: [
          "Если отключающим устройством является листовая задвижка",
        ],
      },
      {
        code: "20310095",
        text: "Чем должна быть продута горелка перед включением и подачей газа?",
        answers: ["Азотом", "Сжатым воздухом", "Кислородом"],
        correctAnswers: ["Кислородом"],
      },
      {
        code: "20310096",
        text: "Каким составом должны выполняться газоопасные работы I и II группы газоопасности с образованием взрывопожароопасных смесей?",
        answers: [
          "Одним человеком под наблюдением газоспасателя или члена ДГСД",
          "Не менее двух человек под наблюдением газоспасателя или члена ДГСД",
          "Не менее трех человек под наблюдением газоспасателя или члена ДГСД",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Не менее двух человек под наблюдением газоспасателя или члена ДГСД",
        ],
      },
      {
        code: "20310097",
        text: "С кем согласовывается инструкция, определяющая порядок подготовки и безопасного проведения газоопасных работ на опасных производственных объектах горно-металлургической промышленности? Выберите два правильных ответа.",
        answers: [
          "С ГСС",
          "Со службой производственного контроля",
          "С главным энергетиком",
          "С главным инженером",
        ],
        correctAnswers: ["С ГСС", "Со службой производственного контроля"],
      },
      {
        code: "20310098",
        text: "В течение какого времени хранится журнал регистрации нарядов-допусков со дня внесения последней записи?",
        answers: [
          "В течение 3 месяцев",
          "В течение 6 месяцев",
          "В течение года",
          "В течение месяца",
        ],
        correctAnswers: ["В течение 6 месяцев"],
      },
      {
        code: "20310099",
        text: "В каких из перечисленных случаях допускается продолжать работы, проводимые по наряду-допуску, на опасных   производственных объектах?",
        answers: [
          "Если изменился состав бригады",
          "Если подан аварийный сигнал",
          "Если обнаружено несоответствие фактического состояния производства работ требованиям безопасности",
          "Если появилась угроза жизни и здоровью работников",
        ],
        correctAnswers: ["Если изменился состав бригады"],
      },
      {
        code: "20310100",
        text: "Какое количество производителей (руководителей) работ назначаются на все время действия наряда-допуска на работы повышенной опасности при производстве работ в несколько смен?",
        answers: [
          "Количество производителей (руководителей) работ определяет выдающий наряд-допуск",
          "Один производитель (руководитель) работ",
          "Несколько производителей (руководителей) работ в соответствии с количеством смен",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Несколько производителей (руководителей) работ в соответствии с количеством смен",
        ],
      },
      {
        code: "20310101",
        text: "В течение какого времени хранятся закрытые (подписанные) наряды-допуски на работы повышенной опасности?",
        answers: [
          "В течение года",
          "В течение трех месяцев",
          "В течение одного месяца",
          "В течение шести месяцев",
        ],
        correctAnswers: ["В течение одного месяца"],
      },
      {
        code: "20310102",
        text: "На какие группы подразделяются газоопасные места на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "На I, II, III и IV группы газоопасности",
          "На I, II и III группы газоопасности",
          "На I и II группы газоопасности",
        ],
        correctAnswers: ["На I и II группы газоопасности"],
      },
      {
        code: "20310103",
        text: "Кто определяет (назначает) работников, выполняющих мероприятия по безопасности труда, указанных в наряде-допуске (ответственных исполнителей)?",
        answers: [
          "Выдающий наряд-допуск",
          "Лицо, ответственное за осуществление производственного контроля",
          "Допускающий к работе",
          "Технический руководитель организации",
        ],
        correctAnswers: ["Выдающий наряд-допуск"],
      },
      {
        code: "20310104",
        text: "Какие из перечисленных требований к наряду-допуску на работы повышенной опасности указаны неверно? Выберите два правильных ответа.",
        answers: [
          "При перерыве в работе в течение одной смены (обеденный перерыв, перерыв по условиям производства работ) наряд-допуск остается у производителя (руководителя) работ",
          "При допуске бригады к работе допускающий к работе вручает первый экземпляр наряда-допуска производителю работ, а второй экземпляр наряда-допуска хранится вместе с нарядами, действующими на этот момент времени",
          "После окончания работы наряд-допуск остается у производителя (руководителя) работ",
          "Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ",
        ],
        correctAnswers: [
          "После окончания работы наряд-допуск остается у производителя (руководителя) работ",
          "Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ",
        ],
      },
      {
        code: "20310105",
        text: "С кем согласовывается перечень работ повышенной опасности на опасных производственных объектах горно-металлургической промышленности?",
        answers: [
          "Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля",
          "С Ростехнадзором",
          "С техническим руководителем организации",
          "С руководителем газоспасательной службы",
        ],
        correctAnswers: [
          "Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля",
        ],
      },
      {
        code: "20310106",
        text: "Из каких газов состоят промышленные газы объектов металлургии, возникающие (используемые) в процессе металлургического производства?",
        answers: [
          "Только из природного и доменного газов",
          "Только из коксового и конвертерного газов",
          "Только из ферросплавного и реформенного газов",
          "Из всех перечисленных газов и иных газов, возникающих (используемых) в процессе металлургического производства, а также смесей газов",
        ],
        correctAnswers: [
          "Из всех перечисленных газов и иных газов, возникающих (используемых) в процессе металлургического производства, а также смесей газов",
        ],
      },
      {
        code: "20310107",
        text: "Какие требования к ресиверам для водорода (сосудам) указаны неверно?",
        answers: [
          "Ресиверы для водорода (сосуды) размещаются на открытых площадках, имеющих по периметру ограждение легкого типа высотой не менее 1 м из несгораемого материала",
          "Расстояние от ресиверов (сосудов) с водородом до ограждения - не менее 1,5 м",
          "Расстояние между ресиверами одного газа должно быть не менее 1,5 м в свету и обеспечивать удобство их обслуживания",
          'На ограждении ресивера должны быть вывешены предупреждающие знаки безопасности: "Курить запрещается", "Посторонним вход воспрещен", на ресиверах должны быть поясняющие надписи: "Водород. Взрывоопасно"',
        ],
        correctAnswers: [
          "Ресиверы для водорода (сосуды) размещаются на открытых площадках, имеющих по периметру ограждение легкого типа высотой не менее 1 м из несгораемого материала",
        ],
      },
      {
        code: "20310108",
        text: "Какое должно быть расстояние между ресиверами одного газа?",
        answers: [
          "Не менее 1,5 м в свету",
          "Не менее 1,0 м в свету",
          "Не менее 0,5 м в свету",
          "Не менее 0,8 м в свету",
        ],
        correctAnswers: ["Не менее 1,5 м в свету"],
      },
      {
        code: "20310109",
        text: "Какими устройствами должны быть оборудованы аппараты с взрывопожароопасными веществами?",
        answers: [
          "Устройствами для подключения линий воды, пара, инертного газа",
          "Вытяжной вентиляцией",
          "Защитными колпаками",
          "Устройствами подачи огнетушащего порошка",
        ],
        correctAnswers: [
          "Устройствами для подключения линий воды, пара, инертного газа",
        ],
      },
      {
        code: "20310110",
        text: "Какие требования из перечисленных должны предусматриваться для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты? Выберите правильный вариант ответа.",
        answers: [
          "Для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты, должно предусматриваться дистанционное отключение",
          "Для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты, должны устанавливаться на линиях нагнетания запорные или отсекающие устройства",
          "Для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты, должны устанавливаться на линиях всасывания запорные или отсекающие устройства",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20310111",
        text: "Какое расчетное давление газа соответствует газопроводам среднего давления?",
        answers: [
          "Для промышленных газов свыше 0,1 до 0,3 МПа, для природного газа свыше 0,005 до 0,3 МПа включительно",
          "Для промышленных газов свыше 0,005 до 0,3 МПа, для природного газа свыше 0,05 до 0,5 МПа включительно",
          "Для промышленных газов свыше 0,001 до 0,4 МПа, для природного газа свыше 0,003 до 0,3 МПа включительно",
          "Для промышленных газов свыше 0,1 до 0,5 МПа, для природного газа свыше 0,004 до 0,5 МПа включительно",
        ],
        correctAnswers: [
          "Для промышленных газов свыше 0,1 до 0,3 МПа, для природного газа свыше 0,005 до 0,3 МПа включительно",
        ],
      },
      {
        code: "20310112",
        text: "Как должны сооружаться газопроводы на территории предприятия?",
        answers: [
          "Должны сооружаться надземными",
          "Должны сооружаться наземными",
          "Должны сооружаться подземными",
        ],
        correctAnswers: ["Должны сооружаться надземными"],
      },
      {
        code: "20310113",
        text: "Через какое расстояние должны заземляться наружные газопроводы?",
        answers: [
          "Через каждые 250 м",
          "Через каждые 300 м",
          "Через каждые 500 м",
          "Через каждые 750 м",
        ],
        correctAnswers: ["Через каждые 250 м"],
      },
      {
        code: "20310114",
        text: "При каком максимальном содержании сероводорода в газе допускается установка бронзовых кранов или задвижек с бронзовыми кольцами на газопроводах?",
        answers: ["20 мг/м³", "10 мг/м³", "35 мг/м³", "25 мг/м³"],
        correctAnswers: ["20 мг/м³"],
      },
      {
        code: "20310115",
        text: "Какое из перечисленных требований к задвижкам и заглушкам, устанавливаемым на газопроводах, указано неверно?",
        answers: [
          "Задвижки и краны, устанавливаемые на газопроводах и устройствах, должны иметь указатель открытого и закрытого положения",
          "Для плотного отключения отдельных участков газопроводов, газопотребляющих агрегатов и газовых аппаратов от действующих газопроводов после дисковых задвижек (по ходу газа) должны устанавливаться листовые задвижки или заглушки",
          "Листовые задвижки на газопроводах диаметром более 300 мм должны быть оснащены механизированным приводом",
          "Перекрывающие листы листовых задвижек и заглушек диаметром до 5 м должны изготовляться из целого листа",
        ],
        correctAnswers: [
          "Перекрывающие листы листовых задвижек и заглушек диаметром до 5 м должны изготовляться из целого листа",
        ],
      },
      {
        code: "20310116",
        text: "Какая информация должна быть выбита на хвостовике каждой съемной заглушки газопровода?",
        answers: [
          "Только номер сертификата на заглушку",
          "Только номер заглушки",
          "Только марка материала",
          "Только условное давление и условный диаметр",
          "Вся перечисленная информация",
        ],
        correctAnswers: ["Вся перечисленная информация"],
      },
      {
        code: "20310117",
        text: "Какая должна быть минимальная высота водяного затвора конденсатоотводчиков межцеховых газопроводов, работающих под избыточным давлением?",
        answers: [
          "На 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст.",
          "На 300 мм вод. ст. больше расчетного давления газа, но не менее 1500 мм вод. ст.",
          "На 200 мм вод. ст. больше расчетного давления газа, но не менее 1750 мм вод. ст.",
          "На 150 мм вод. ст. больше расчетного давления газа, но не менее 1400 мм вод. ст.",
        ],
        correctAnswers: [
          "На 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст.",
        ],
      },
      {
        code: "20310118",
        text: "Какое из перечисленных требований к отводу конденсата из цеховых газопроводов указано неверно?",
        answers: [
          "Сброс конденсата из цеховых газопроводов следует производить через конденсатоотводчики",
          "Допускается сброс конденсата через горелки печей и другого оборудования потребителя",
          "Конструкция конденсатоотводчиков, установленных в зданиях цехов, должна исключать возможность попадания газов в помещения",
          "Участки труб, отводящих конденсат, а также сами конденсатоотводчики, если цех не отапливается, должны быть утеплены",
        ],
        correctAnswers: [
          "Допускается сброс конденсата через горелки печей и другого оборудования потребителя",
        ],
      },
      {
        code: "20310119",
        text: "Какое из перечисленных требований к стационарным газоразборным постам указано неверно?",
        answers: [
          "Стационарные газоразборные посты должны быть размещены в металлических шкафах с отверстиями для вентиляции",
          "Расстояние между шкафами газоразборных постов для кислорода и горючего газа должно быть не менее 150 мм",
          "Дверцы шкафов во время работы должны быть закрыты",
          "При снабжении газоразборного поста газом от баллона на последнем должен быть установлен редуктор для снижения давления газа",
        ],
        correctAnswers: ["Дверцы шкафов во время работы должны быть закрыты"],
      },
      {
        code: "20310120",
        text: "Какой должен быть нижний предел давления для I разряда газов-заменителей ацетилена перед газопламенной аппаратурой?",
        answers: [
          "Не ниже указанного в характеристике горелки или резака, но не менее 3,0 кПа",
          "Не ниже указанного в характеристике горелки или резака, но не менее 1,0 кПа",
          "Не ниже указанного в характеристике горелки или резака, но не менее 1,7 кПа",
          "Не ниже указанного в характеристике горелки или резака, но не менее 0,8 кПа",
        ],
        correctAnswers: [
          "Не ниже указанного в характеристике горелки или резака, но не менее 3,0 кПа",
        ],
      },
      {
        code: "20310121",
        text: "При каком увеличении содержания кислорода в газе в напорном коллекторе газоотсасывающей станции отвод газа от электропечи должен быть немедленно прекращен?",
        answers: [
          "При увеличении до 2%",
          "При увеличении до 0,5%",
          "При увеличении до 1,7%",
          "При увеличении до 0,8%",
        ],
        correctAnswers: ["При увеличении до 2%"],
      },
      {
        code: "20310122",
        text: "Каким уплотнительным устройством, препятствующим выбиванию газов в цех, должно оборудоваться отверстие в охладителе конвертерных газов?",
        answers: [
          "Только аэродинамической воздушной завесой",
          "Только аэродинамической азотной завесой",
          "Любым из перечисленных устройств",
        ],
        correctAnswers: ["Любым из перечисленных устройств"],
      },
      {
        code: "20310123",
        text: "Какое допускается максимальное содержание оксида углерода в газах, поступающих в электрофильтр, при отводе газов с полным дожиганием?",
        answers: ["1%", "2%", "0,8%", "5%"],
        correctAnswers: ["1%"],
      },
      {
        code: "20310124",
        text: "Содержание каких из перечисленных газов измеряется в продуктах неполного сгорания за дымососом при отводе газов без дожигания? Выберите 2 варианта ответа.",
        answers: ["СО", "О2", "СО2", "Н2"],
        correctAnswers: ["СО", "О2"],
      },
      {
        code: "20310125",
        text: "С какой периодичностью газопроводы сероочистки природного газа подвергаются пневматическому испытанию?",
        answers: [
          "1 раз в 4 года",
          "1 раз в 5 лет",
          "1 раз в 7 лет",
          "1 раз в 6 лет",
        ],
        correctAnswers: ["1 раз в 4 года"],
      },
      {
        code: "20310126",
        text: "Какое из перечисленных требований к установке сероочистки природного газа указано верно?",
        answers: [
          "Установка сероочистки должна работать в ручном режиме и снабжаться блокировками, отключающими установку при отклонении параметров от заданных",
          "Установка сероочистки природного газа для получения реформерного газа должна быть снабжена быстродействующими отсекающими клапанами на входе газа в установку, срабатывающими при снижении или повышении давления газа от установленных проектом значений",
          "1 раз в 5 лет газопроводы сероочистки подвергаются пневматическому испытанию",
          "Перед ремонтом аппаратов и газопроводов сероочистки они должны быть отглушены и провентилированы, а содержание CH₄ в 2 последовательно отобранных пробах должно быть не более 3 %",
        ],
        correctAnswers: [
          "Установка сероочистки природного газа для получения реформерного газа должна быть снабжена быстродействующими отсекающими клапанами на входе газа в установку, срабатывающими при снижении или повышении давления газа от установленных проектом значений",
        ],
      },
      {
        code: "20310127",
        text: "Какие устройства должны быть установлены на нагнетательном газопроводе для отключения компрессора от коллектора высокого давления?",
        answers: [
          "Обратный клапан и 2 запорных вентиля",
          "Штуцер с запорным органом",
          "Скоростной клапан",
        ],
        correctAnswers: ["Обратный клапан и 2 запорных вентиля"],
      },
      {
        code: "20310128",
        text: "Стальными листами какой толщины должны отделяться от рабочей зоны баллоны с водородом при их горизонтальном расположении и размещении внутри помещения?",
        answers: [
          "Не менее 16 мм",
          "Не менее 14 мм",
          "Не менее 8 мм",
          "Не менее 12 мм",
        ],
        correctAnswers: ["Не менее 16 мм"],
      },
      {
        code: "20310129",
        text: "Какой должна быть ширина свободного прохода между щитом управления вентилями и ближайшими стенами наполнительной?",
        answers: [
          "Не менее 0,8 м",
          "Не менее 0,5 м",
          "Не менее 0,7 м",
          "Не менее 0,6 м",
        ],
        correctAnswers: ["Не менее 0,8 м"],
      },
      {
        code: "20310130",
        text: "С какой периодичностью должен производиться осмотр газопроводов, газовых установок и газового оборудования комиссией на производстве?",
        answers: [
          "Не реже 2 раз в год",
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 3 года",
        ],
        correctAnswers: ["Не реже 2 раз в год"],
      },
      {
        code: "20310131",
        text: "Кто из перечисленных лиц не должен входить в состав постоянно действующей комиссии для проведения осмотров объектов газового хозяйства?",
        answers: [
          "Начальник цеха - ответственный за производственный контроль",
          "Работник - ответственный за безопасную эксплуатацию и техническое состояние газового хозяйства цеха",
          "Работники, ответственные за безопасную эксплуатацию и техническое состояние соответствующих технических устройств, сооружений и оборудования",
          "Главный инженер - председатель комиссии",
        ],
        correctAnswers: ["Главный инженер - председатель комиссии"],
      },
      {
        code: "20310132",
        text: "С какой периодичностью должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях, и вне помещений?",
        answers: [
          "Расположенных в помещениях - не реже 1 раза в неделю, расположенных вне помещений - не реже 1 раза в месяц",
          "Расположенных в помещениях - не реже 1 раза в месяц, расположенных вне помещений - не реже 1 раза в 3 месяца",
          "Расположенных в помещениях - не реже 1 раза в 3 месяца, расположенных вне помещений - не реже 1 раза в 6 месяцев",
          "Расположенных в помещениях - не реже 1 раза в 6 месяцев, расположенных вне помещений - не реже 1 раза в год",
        ],
        correctAnswers: [
          "Расположенных в помещениях - не реже 1 раза в неделю, расположенных вне помещений - не реже 1 раза в месяц",
        ],
      },
      {
        code: "20310133",
        text: "С какой периодичностью должна производиться ревизия с разборкой регулятора давления, предохранительных клапанов, фильтров газопроводов, газовых установок и газового оборудования?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 5 лет",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "20310134",
        text: "До каких пор должна осуществляться продувка азотом перед пуском в работу установки по производству реформерного газа для производства металлизованного горячевосстановленного железа?",
        answers: [
          "Пока на участке продувки кислорода будет не более 1% кислорода",
          "Пока на участке продувки кислорода будет не более 2% кислорода",
          "Пока на участке продувки кислорода будет не более 3% кислорода",
        ],
        correctAnswers: [
          "Пока на участке продувки кислорода будет не более 1% кислорода",
        ],
      },
      {
        code: "20310135",
        text: "Какое из перечисленных требований к установке по производству реформерного газа указано неверно?",
        answers: [
          "Допускается продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами",
          "Установка по производству реформерного газа для производства металлизованного горячевосстановленного железа должна иметь подвод азота для его продувки перед пуском в работу",
          "Для регулирования давления реформерного газа после холодильника и сброса его на свечу должны быть установлены регулирующие клапаны, работающие в ручном или автоматическом режиме",
          "При остановке агрегатов по производству реформерного газа подача воздуха и природного газа на горелки должна быть закрыта. На газопроводе природного газа должны быть установлены заглушки",
          "Перед розжигом горелок реформера должна быть произведена продувка его топочного пространства",
        ],
        correctAnswers: [
          "Допускается продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами",
        ],
      },
      {
        code: "20310136",
        text: "Кто утверждает инструкцию по перекачке сероуглерода из транспортной емкости в емкость хранения?",
        answers: [
          "Главный инженер предприятия",
          "Главный технолог предприятия",
          "Руководитель предприятия",
        ],
        correctAnswers: ["Главный инженер предприятия"],
      },
      {
        code: "20310137",
        text: "Кем производится испытание газопроводов и газовых установок после окончания строительно-монтажных и сварочных работ (включая ремонтные работы)?",
        answers: [
          "Строительно-монтажной организацией с участием представителя предприятия",
          "Комиссией предприятия-заказчика",
          "Комиссией предприятия-заказчика, с участием представителя Ростехнадзора",
          "Строительно-монтажной организацией",
        ],
        correctAnswers: [
          "Строительно-монтажной организацией с участием представителя предприятия",
        ],
      },
      {
        code: "20310138",
        text: "Какое из перечисленных требований к манометрам, используемым при проведении испытаний газопроводов и газовых установок, указано верно?",
        answers: [
          "Давление должно измеряться 2 манометрами, прошедшими поверку и опломбированными",
          "Класс точности манометров должен быть не ниже 1,5",
          "Диаметр корпуса манометра должен быть не менее 160 мм и шкалой на номинальное давление не менее 4/3 и не более 5/3 от величины измеряемого",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20310139",
        text: "В течение какого времени выдерживают испытательное давление в трубопроводе при испытании на прочность, после чего снижают до рабочего давления, при котором производят тщательный осмотр сварных швов (испытание на плотность)?",
        answers: [
          "В течение 5 минут",
          "В течение 10 минут",
          "В течение 15 минут",
        ],
        correctAnswers: ["В течение 10 минут"],
      },
      {
        code: "20310140",
        text: "Какое устанавливается минимальное расстояние от испытываемого газопровода большого диаметра, расположенных вне помещений, до границ охраняемой зоны во время проведения пневматических испытаний на прочность?",
        answers: ["Не менее 50 м", "Не менее 10 м", "Не менее 25 м"],
        correctAnswers: ["Не менее 50 м"],
      },
      {
        code: "20310141",
        text: "Каким давлением производится дополнительное испытание на герметичность межцеховых и внутрицеховых газопроводов?",
        answers: [
          "Испытание производится давлением, равным 1,8 рабочего",
          "Испытание производится давлением, равным 1,2 рабочего",
          "Испытание производится давлением, равным рабочему",
        ],
        correctAnswers: ["Испытание производится давлением, равным рабочему"],
      },
      {
        code: "20310142",
        text: "Какая установлена продолжительность дополнительных испытаний на герметичность для вновь сооружаемых газопроводов?",
        answers: [
          "Не менее 24 часов",
          "Не менее 36 часов",
          "Не менее 48 часов",
          "Не менее 72 часов",
        ],
        correctAnswers: ["Не менее 24 часов"],
      },
      {
        code: "20310143",
        text: "Какая устанавливается продолжительность периодических испытаний на герметичность для газопроводов, а также испытаний после ремонта, связанного со сваркой и разборкой газопровода?",
        answers: [
          "Не менее 4 часов",
          "Не менее 1 часа",
          "Не менее 2 часов",
          "Не менее 3 часов",
        ],
        correctAnswers: ["Не менее 4 часов"],
      },
      {
        code: "20310144",
        text: "При какой скорости падения давления за время дополнительного пневматического испытания на герметичность внутрицеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?",
        answers: [
          "Не более 0,1% в час",
          "Не более 0,3% в час",
          "Не более 0,6% в час",
          "Не более 0,8% в час",
        ],
        correctAnswers: ["Не более 0,1% в час"],
      },
      {
        code: "20310145",
        text: "При какой скорости падения давления за время дополнительного пневматического испытания на герметичность межцеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?",
        answers: [
          "Не более 0,2% в час",
          "Не более 0,3% в час",
          "Не более 0,5% в час",
          "Не более 0,7% в час",
        ],
        correctAnswers: ["Не более 0,2% в час"],
      },
      {
        code: "20310146",
        text: "У кого должен находиться агрегатный (ремонтный) журнал на отдельных объектах газового цеха, а также в цехах, в составе которых имеются объекты газового хозяйства?",
        answers: [
          "У ответственного за техническое состояние данного объекта газового хозяйства",
          "У начальника цеха",
          "У ответственного за безопасное производство работ данного объекта газового хозяйства",
        ],
        correctAnswers: [
          "У ответственного за техническое состояние данного объекта газового хозяйства",
        ],
      },
      {
        code: "20310147",
        text: "Какая должна быть величина прибавки на коррозию при проектировании трубопроводов из углеродистой стали, работающих в среде водорода?",
        answers: [
          "Должна быть принята с учетом скорости коррозии 0,1 - 0,5 мм/год",
          "Должна быть принята с учетом скорости коррозии 0,005 - 0,007 мм/год",
          "Должна быть принята с учетом скорости коррозии 0,5 - 0,8 мм/год",
          "Должна быть принята с учетом скорости коррозии 0,001 - 0,003 мм/год",
        ],
        correctAnswers: [
          "Должна быть принята с учетом скорости коррозии 0,1 - 0,5 мм/год",
        ],
      },
      {
        code: "20310148",
        text: "Какая должна быть величина прибавки на коррозию при проектировании трубопроводов из легированной стали, работающих в среде водорода?",
        answers: [
          "Не менее 0,5 мм",
          "Не менее 0,1 мм",
          "Не менее 0,3 мм",
          "Не менее 0,4 мм",
        ],
        correctAnswers: ["Не менее 0,5 мм"],
      },
      {
        code: "20310149",
        text: "При каком избыточном давлении газа в газопроводах разрешается производить электросварочные работы на газопроводах при ремонтах?",
        answers: [
          "Не более 0,3 кПа",
          "Не более 0,5 кПа",
          "Не более 1,0 кПа",
          "Не более 0,7 кПа",
        ],
        correctAnswers: ["Не более 0,3 кПа"],
      },
      {
        code: "20310150",
        text: "Какие действия должны быть произведены перед продувкой газопровода? Выберите правильный вариант ответа.",
        answers: [
          "Только установить задвижки в нужное положение",
          "Только залить водой водяные затворы",
          "Только плотно закрыть все люки, лазы и свечи (за исключением той свечи, через которую будет проводиться продувка)",
          "Все перечисленные действия",
        ],
        correctAnswers: ["Все перечисленные действия"],
      },
      {
        code: "20310151",
        text: "В каком радиусе у мест выброса газовоздушной смеси в атмосферу должно быть приостановлено движение всех видов транспорта, запрещено применение открытого огня и производство сварочных работ в период продувки газопровода?",
        answers: ["В радиусе 50 м", "В радиусе 10 м", "В радиусе 30 м"],
        correctAnswers: ["В радиусе 50 м"],
      },
      {
        code: "20310152",
        text: "В каком случае допускается использовать продувочные свечи газопроводов для выпуска в атмосферу избыточного газа?",
        answers: [
          "В аварийном случае",
          "По распоряжению главного инженера",
          "В любом вслуче",
        ],
        correctAnswers: ["В аварийном случае"],
      },
      {
        code: "20310153",
        text: "Какие сроки проведения ревизии газопроводов должны быть при скорости коррозии более 0,5 мм/г?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 5 лет",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "20310154",
        text: "При какой скорости коррозии срок проведения ревизии газопроводов должен быть не реже одного раза в 2 года?",
        answers: [
          "При скорости до 0,5 мм/г",
          "При скорости от 0,1 до 0,5 мм/г",
          "При скорости до 0,1 мм/г",
        ],
        correctAnswers: ["При скорости от 0,1 до 0,5 мм/г"],
      },
      {
        code: "20310155",
        text: "Какой допускается максимальный подъем температуры газа в газопроводах?",
        answers: ["85 °C", "80 °C", "75 °C"],
        correctAnswers: ["85 °C"],
      },
      {
        code: "20310156",
        text: "В каком случае допускается подача газа без поднесения запальника, факела или другого воспламеняющего средства к горелке газопровода?",
        answers: [
          "Если температура кладки в разогретой зоне розжига факела превышает 800 °C",
          "Если температура кладки в разогретой зоне розжига факела превышает 500 °C",
          "Если температура кладки в разогретой зоне розжига факела превышает 300 °C",
          "Если температура кладки в разогретой зоне розжига факела превышает 600 °C",
        ],
        correctAnswers: [
          "Если температура кладки в разогретой зоне розжига факела превышает 800 °C",
        ],
      },
      {
        code: "20310157",
        text: "Кто осуществляет выбор типа быстродействующего отсекающего клапана на подводе газа к каждому агрегату с дутьевыми горелками?",
        answers: [
          "Организация, проектирующая систему автоматики печи",
          "Специализированная организация",
          "Организация, эксплуатирующая систему автоматики печи",
        ],
        correctAnswers: ["Организация, проектирующая систему автоматики печи"],
      },
      {
        code: "20310158",
        text: "Какое из перечисленных требований при эксплуатации газового оборудования газопотребляющих агрегатов указано верно?",
        answers: [
          "При выводе газоиспользующего оборудования в резерв или при ремонте должны быть приняты меры по консервации поверхности нагрева в соответствии с документацией изготовителя",
          "На хвостовике заглушки, выступающем за пределы фланцев, должно быть выбито клеймо с указанием диаметра газопровода, на который ее можно устанавливать, и давления газа, на который она рассчитана",
          "Газоиспользующее оборудование при выводе из эксплуатации на срок более трех суток для проведения ремонтных работ или при переводе его в режим резерва должно отключаться с установкой заглушек на газопроводах",
          "Остановка газоиспользующего оборудования для ремонта во всех случаях, за исключением аварийного останова, должна производиться после получения письменного разрешения ответственного за безопасную эксплуатацию газоиспользующего оборудования",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20310159",
        text: "С какой периодичностью должна производиться режимная наладка газоиспользующего оборудования?",
        answers: [
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 4 года",
          "Не реже 1 раза в 5 лет",
        ],
        correctAnswers: ["Не реже 1 раза в 3 года"],
      },
      {
        code: "20310160",
        text: "В каком случае допускается режимную наладку газоиспользующего оборудования проводить не реже одного раза в четыре года?",
        answers: [
          "При стабильной работе газоиспользующего оборудования",
          "При нарушении в работе газоиспользующего оборудования",
          "В любом случае",
        ],
        correctAnswers: [
          "При стабильной работе газоиспользующего оборудования",
        ],
      },
      {
        code: "20310161",
        text: "С какой периодичностью должна производиться проверка технического состояния вентиляционных каналов в зданиях (помещениях) с установленным газоиспользующим оборудованием и пылеуборка, и дезинфекция вентиляционных каналов?",
        answers: [
          "Проверка технического состояния вентиляционных каналов - не реже 2 раз в год, пылеуборка и дезинфекция - не реже 1 раза в 3 года",
          "Проверка технического состояния вентиляционных каналов - не реже 1 раза в год, пылеуборка и дезинфекция - не реже 1 раза в 4 года",
          "Проверка технического состояния вентиляционных каналов - не реже 1 раз в 2 года, пылеуборка и дезинфекция - не реже 1 раза в 5 лет",
        ],
        correctAnswers: [
          "Проверка технического состояния вентиляционных каналов - не реже 2 раз в год, пылеуборка и дезинфекция - не реже 1 раза в 3 года",
        ],
      },
      {
        code: "20310162",
        text: "С какой периодичностью должны производиться проверка технического состояния промышленных дымоотводящих устройств (газоходов газоиспользующего оборудования, дымоходов и дымовых труб) и их прочистка?",
        answers: [
          "Не реже 2 раз в год",
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
        ],
        correctAnswers: ["Не реже 2 раз в год"],
      },
      {
        code: "20310163",
        text: "С какой периодичностью должен производиться текущий ремонт оборудования с разборкой регуляторов давления, предохранительных клапанов и фильтров ГРП и ГРУ, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 5 лет",
          "Не реже 1 раза в 3 года",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "20310164",
        text: "При каком давлении должна производиться настройка срабатывания предохранительно-запорных клапанов при их наличии в ГРП (ГРУ)?",
        answers: [
          "На 15% больше расчетного",
          "На 13% больше расчетного",
          "На 10% больше расчетного",
          "На 5% больше расчетного",
        ],
        correctAnswers: ["На 15% больше расчетного"],
      },
      {
        code: "20310165",
        text: "В каком случае допускается подача потребителям газа по обводной линии (байпасу) ГРП и ГРУ?",
        answers: [
          "На время ревизии или ремонта оборудования",
          "При наличии предохранительного запорного клапана",
          "Подача газа по байпасу не допускается",
        ],
        correctAnswers: ["На время ревизии или ремонта оборудования"],
      },
      {
        code: "20310166",
        text: "Какие виды работ должны выполняться не реже одного раза в 6 месяцев при эксплуатации ГРП и ГРУ?",
        answers: [
          "Техническое обслуживание",
          "Текущий ремонт",
          "Проверка параметров срабатывания предохранительных запорных и сбросных клапанов",
        ],
        correctAnswers: ["Техническое обслуживание"],
      },
      {
        code: "20310167",
        text: "Какие из перечисленных видов работ не должны выполняться при техническом обслуживании технологического оборудования ГРП и ГРУ? Выберите 2 варианта ответа.",
        answers: [
          "Замена изношенных деталей технических устройств",
          "Восстановление окраски шкафов пунктов редуцирования газа, ограждений, газопроводов обвязки и технических устройств",
          "Проверка работоспособности запорной арматуры",
          "Проверка соответствия параметров настройки предохранительной и защитной арматуры режимной карте",
          "Устранение утечек газа из разъемных соединений технических устройств",
        ],
        correctAnswers: [
          "Замена изношенных деталей технических устройств",
          "Восстановление окраски шкафов пунктов редуцирования газа, ограждений, газопроводов обвязки и технических устройств",
        ],
      },
      {
        code: "20310168",
        text: "В какие сроки должен проводиться капитальный ремонт ГРП и ГРУ?",
        answers: [
          "В сроки, установленные изготовителем",
          "В сроки, установленные эксплуатирующей организацией",
          "Через 3 года после ввода в эксплуатацию",
          "Через 5 лет после ввода в эксплуатацию",
        ],
        correctAnswers: ["В сроки, установленные изготовителем"],
      },
      {
        code: "20310169",
        text: "Чем должна производиться продувка импульсных газовых проводок?",
        answers: [
          "Только паром",
          "Только сжатым воздухом",
          "Только газом",
          "Любым из перечисленных способов",
        ],
        correctAnswers: ["Любым из перечисленных способов"],
      },
      {
        code: "20310170",
        text: "Какой должна быть объемная доля кислорода в воздухе производственных помещений производства ПРВ?",
        answers: [
          "Не менее 19% и не более 23%",
          "Не менее 18% и не более 23%",
          "Не менее 21% и не более 25%",
        ],
        correctAnswers: ["Не менее 19% и не более 23%"],
      },
      {
        code: "20310171",
        text: "Что необходимо устанавливать в производственных помещениях, где возможны воспламенение одежды или химические ожоги у работников?",
        answers: [
          "Емкости с песком, огнетушители",
          "Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
          "Брезентовые покрывала",
        ],
        correctAnswers: [
          "Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души",
        ],
      },
      {
        code: "20310172",
        text: "Какие требования установлены к инструментам и приспособлениям, используемым во взрывопожароопасных зонах и помещениях?",
        answers: [
          "Должны быть изготовлены из прочной стали",
          "Должны быть изготовлены из легированного чугуна",
          "Не должны давать искры при работе с ними",
        ],
        correctAnswers: ["Не должны давать искры при работе с ними"],
      },
      {
        code: "20310173",
        text: "После установления каких показателей температуры разрешается проводить ремонтные работы внутри нагретых технических устройств, а также низкотемпературных технических устройств?",
        answers: [
          "После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания",
          "После снижения температуры воздуха до 45 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания",
          "После снижения температуры воздуха до 50 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания",
        ],
        correctAnswers: [
          "После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания",
        ],
      },
      {
        code: "20310174",
        text: "При каких условиях допускается перенос сроков проведения капитальных ремонтов основного технологического оборудования?",
        answers: [
          "При наличии положительного заключения экспертного обследования",
          "При наличии разрешения завода-изготовителя",
          "По решению руководителя предприятия",
        ],
        correctAnswers: [
          "При наличии положительного заключения экспертного обследования",
        ],
      },
      {
        code: "20310175",
        text: "Каким образом должен проводиться отогрев трубопроводной арматуры?",
        answers: [
          "С применением открытого пламени",
          "Горячим воздухом, паром или горячей водой",
          "Электрическими приборами",
        ],
        correctAnswers: ["Горячим воздухом, паром или горячей водой"],
      },
      {
        code: "20310176",
        text: "Каким должно быть расстояние от устройства забора воздуха и камер всасывания работающих воздушных компрессоров ВРУ при выполнении ремонтных работ, работ по газовой сварке и резке металла, чтобы для их выполнения было необходимо наличие письменного разрешения руководителя производства (цеха) и наряда-допуска?",
        answers: ["Менее 100 м", "Менее 175 м", "Менее 150 м"],
        correctAnswers: ["Менее 100 м"],
      },
      {
        code: "20310177",
        text: "Какой срок должны храниться данные самопишущих приборов?",
        answers: [
          "Не менее 3 месяцев",
          "Не менее 6 месяцев",
          "Не менее 12 месяцев",
        ],
        correctAnswers: ["Не менее 3 месяцев"],
      },
      {
        code: "20310178",
        text: "С какой периодичностью следует проверять состояние адсорбента в адсорбционных блоках осушки?",
        answers: [
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в год",
          "Не реже 1 раза в 3 года",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "20310179",
        text: "Какие требования установлены к системам освещения во взрывоопасных помещениях?",
        answers: [
          "Должны предусматриваться системы освещения во взрывобезопасном исполнении",
          "Должны предусматриваться системы освещения, работающие от напряжения не более 24 В",
          "Должны предусматриваться системы освещения, работающие от напряжения не более 12 В",
          "Должны предусматриваться системы освещения, работающие от напряжения не более 36 В",
        ],
        correctAnswers: [
          "Должны предусматриваться системы освещения во взрывобезопасном исполнении",
        ],
      },
      {
        code: "20310180",
        text: "Какое должно быть давление во всасывающих коммуникациях компрессоров, сжимающих ПРВ?",
        answers: [
          "Должно быть не ниже 0,5 кПа (50 мм вод. ст.)",
          "Должно быть не ниже 0,4 кПа (40 мм вод. ст.)",
          "Должно быть не ниже 0,2 кПа (20 мм вод. ст.)",
        ],
        correctAnswers: ["Должно быть не ниже 0,5 кПа (50 мм вод. ст.)"],
      },
      {
        code: "20310181",
        text: "Какое должно быть содержание кислорода в воздухе помещения наполнения баллонов?",
        answers: [
          "При объемной доле кислорода в воздухе не менее 19% и не более 23%",
          "При объемной доле кислорода в воздухе не менее 18% и не более 24%",
          "При объемной доле кислорода в воздухе не менее 18% и не более 23%",
        ],
        correctAnswers: [
          "При объемной доле кислорода в воздухе не менее 19% и не более 23%",
        ],
      },
      {
        code: "20310182",
        text: "При какой предельной температуре кислорода после регулятора давления на линии регулирования прекращается его подача?",
        answers: [
          "При повышении более 100 °С",
          "При повышении более 120   °С",
          "При повышении более 150 °С",
        ],
        correctAnswers: ["При повышении более 100 °С"],
      },
      {
        code: "20310183",
        text: "Как часто должна производиться проверка настройки отсечных и предохранительных клапанов?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в два месяца, а также после каждой ревизии и ремонта",
          "Не реже одного раза в три месяца, а также после каждой ревизии и ремонта",
        ],
        correctAnswers: [
          "Не реже одного раза в два месяца, а также после каждой ревизии и ремонта",
        ],
      },
      {
        code: "20310184",
        text: "При каком давлении должна производиться настройка срабатывания отсекающих клапанов КРП?",
        answers: [
          "При давлении на 15% более расчетного",
          "При давлении на 20% более расчетного",
          "При давлении на 25% более расчетного",
        ],
        correctAnswers: ["При давлении на 15% более расчетного"],
      },
      {
        code: "20310185",
        text: "Сколько суток после наполнения должны выдерживаться баллоны с криптоноксеноновой смесью и криптоном в наполнительной комнате или складе баллонов?",
        answers: [
          "Не менее 14 суток",
          "Не менее 10 суток",
          "Не менее 12 суток",
        ],
        correctAnswers: ["Не менее 14 суток"],
      },
      {
        code: "20310186",
        text: "Какая объемная доля водорода в техническом аргоне, поступающем на очистку в ВРУ?",
        answers: [
          "Не должна превышать 2,5%",
          "Не должна превышать 3,0%",
          "Не должна превышать 4,0%",
        ],
        correctAnswers: ["Не должна превышать 2,5%"],
      },
      {
        code: "20310187",
        text: "При какой температуре возможно вскрытие реакторов каталитического гидрирования?",
        answers: [
          "После остывания их до температуры 40 °С",
          "После остывания их до температуры 45 °С",
          "После остывания их до температуры 50 °С",
        ],
        correctAnswers: ["После остывания их до температуры 40 °С"],
      },
      {
        code: "20310188",
        text: "С какой периодичностью следует очищать фильтры?",
        answers: [
          "В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
          "В первый раз - через 15 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
          "В первый раз - через 20 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
        ],
        correctAnswers: [
          "В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев",
        ],
      },
      {
        code: "20310189",
        text: "Как часто должен производиться осмотр трубопроводов кислорода?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в полгода",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20310190",
        text: "Как часто должна производиться проверка конденсатоотводчиков и пароспутников, обогревающих трубопроводы кислорода, в зимнее время?",
        answers: ["Ежедневно", "Каждую смену", "Через двое суток"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "20310191",
        text: "Как часто должна проводиться проверка правильности показаний приборов, перечень которых утверждается руководителем производства?",
        answers: [
          "Не реже одного раза в два месяца",
          "Не реже одного раза в месяц",
          "Не реже одного раза в 3 месяца",
        ],
        correctAnswers: ["Не реже одного раза в 3 месяца"],
      },
      {
        code: "20310192",
        text: "Какое количество баллонов с эталонными и поверочными газовыми смесями разрешается хранить в помещении хроматографии?",
        answers: ["Не более двух", "Не более одного", "Не более четырех"],
        correctAnswers: ["Не более двух"],
      },
      {
        code: "20310193",
        text: "Из каких материалов выполняются линии отбора кислорода на анализ?",
        answers: [
          "Из углеродистой стали",
          "Из коррозионно-стойкой стали или медных сплавов",
          "Из чугуна",
        ],
        correctAnswers: ["Из коррозионно-стойкой стали или медных сплавов"],
      },
      {
        code: "20310194",
        text: "Какой установлен порядок хранения обтирочных материалов?",
        answers: [
          "В чистых металлических ящиках с крышками",
          "На специально оборудованной площадке",
          "В специально выделенном помещении",
        ],
        correctAnswers: ["В чистых металлических ящиках с крышками"],
      },
      {
        code: "20310195",
        text: "Из каких материалов не могут быть изготовлены разделители (заглушки) или другие изделия, помещаемые внутри кислородопровода при его обезжиривании?",
        answers: [
          "Из поролона или других органических материалов",
          "Из картона",
          "Из цветных металлов",
        ],
        correctAnswers: ["Из поролона или других органических материалов"],
      },
      {
        code: "20310196",
        text: "Из каких сплавов металлов запрещается установка арматуры на кислородопроводах?",
        answers: ["Из чугуна", "Из стали", "Из сплавов титана"],
        correctAnswers: ["Из сплавов титана"],
      },
      {
        code: "20310197",
        text: "Чем должна обеспечиваться безопасность производственных процессов нанесения металлопокрытий?",
        answers: [
          "Автоматизацией производственных процессов и герметизацией технологического оборудования, являющегося источником вредных и (или) опасных производственных факторов",
          "Применением безопасных способов хранения и транспортирования исходных и вспомогательных веществ и материалов, заготовок и готовой продукции",
          "Применением средств индивидуальной и коллективной защиты работников",
          "Заменой производственных процессов и операций, связанных с наличием вредных и (или) опасных производственных факторов, процессами и операциями, при которых указанные факторы отсутствуют или имеют меньшую интенсивность",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20310198",
        text: "При каком давлении должен срабатывать предохранительный клапан вакуумной камеры печи?",
        answers: [
          "При давлении 0,05 МПа (0,5 кгс/см²)",
          "При давлении 0,02 МПа (0,2 кгс/см²)",
          "При давлении 0,01 МПа (0,1 кгс/см²)",
          "При давлении 0,03 МПа (0,3 кгс/см²)",
        ],
        correctAnswers: ["При давлении 0,01 МПа (0,1 кгс/см²)"],
      },
      {
        code: "20310199",
        text: "При каком давлении должна производиться настройка срабатывания отсекающих клапанов кислородно-распределительного (регуляторного) пункта (далее – КРП)?",
        answers: [
          "При давлении на 25% более расчетного",
          "При давлении на 20% более расчетного",
          "При давлении на 15% более расчетного",
        ],
        correctAnswers: ["При давлении на 15% более расчетного"],
      },
      {
        code: "20310200",
        text: "При каком давлении должен срабатывать предохранительный клапан вакуумной камеры печи?",
        answers: [
          "При давлении 0,05 МПа (0,5 кгс/см²)",
          "При давлении 0,02 МПа (0,2 кгс/см²)",
          "При давлении 0,01 МПа (0,1 кгс/см²)",
          "При давлении 0,03 МПа (0,3 кгс/см²)",
        ],
        correctAnswers: ["При давлении 0,01 МПа (0,1 кгс/см²)"],
      },
      {
        code: "20310201",
        text: "Какой должна быть объемная доля кислорода в воздухе производственных помещений производства продуктов разделения воздуха (далее – ПРВ)?",
        answers: [
          "Не менее 19% и не более 23%",
          "Не менее 18% и не более 23%",
          "Не менее 21% и не более 25%",
        ],
        correctAnswers: ["Не менее 19% и не более 23%"],
      },
      {
        code: "20310202",
        text: "Какая объемная доля водорода в техническом аргоне, поступающем на очистку в воздухоразделительную установку (далее – ВРУ)?",
        answers: [
          "Не должна превышать 2,5%",
          "Не должна превышать 3,0%",
          "Не должна превышать 4,0%",
        ],
        correctAnswers: ["Не должна превышать 2,5%"],
      },
      {
        code: "20310203",
        text: "При каком содержании водорода в воздухе производственного помещения по сигналу от автоматического газоанализатора технологическое оборудование этого помещения должно быть остановлено?",
        answers: [
          "При содержании более 1% по объему (25% нижнего предела взрываемости)",
          "При содержании более 0,8% по объему (20% нижнего предела взрываемости)",
          "При содержании более 0,5% по объему (12,5% нижнего предела взрываемости)",
          "При содержании более 0,3% по объему (7,5% нижнего предела взрываемости)",
        ],
        correctAnswers: [
          "При содержании более 1% по объему (25% нижнего предела взрываемости)",
        ],
      },
      {
        code: "20310204",
        text: "Какая должна быть минимальная высота забора по периметру территории всего комплекса производства водорода?",
        answers: ["2 м", "1,3 м", "0,8 м", "1,5 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "20310205",
        text: "Какая должна быть степень огнестойкости зданий с производством и обращением водорода?",
        answers: [
          "Не ниже II",
          "Не выше I",
          "Не ниже III",
          "Не регламентируется",
        ],
        correctAnswers: ["Не ниже II"],
      },
      {
        code: "20310206",
        text: "Какой предел огнестойкости должен быть у стен, разделяющих взрывоопасные помещения на водородных станциях?",
        answers: ["2,5 часа", "1,0 час", "1,5 часа", "2,0 часа"],
        correctAnswers: ["2,5 часа"],
      },
      {
        code: "20310207",
        text: "Чем производится отогрев смерзшейся изоляции для обеспечения доступа к адсорберам?",
        answers: [
          "Непосредственно сухим подогретым воздухом",
          "Непосредственно подогретой водой",
          "Открытым пламенем",
          "За счет тепла, подводимого к отогреваемым аппаратам",
        ],
        correctAnswers: ["Непосредственно сухим подогретым воздухом"],
      },
      {
        code: "20310208",
        text: "Какие из перечисленных ремонтных работ допускается производить одновременно при ремонте арматуры, расположенной на теплом и холодном концах регенераторов в период остановки ВРУ?",
        answers: [
          "Только ремонт трубопроводов и арматуры теплого и холодного концов регенераторов",
          'Только ремонт трубопроводов и арматуры теплого или холодного конца регенераторов и системы "приказного" воздуха переключения регенераторов, или механизма переключения',
          "Только ремонт принудительных клапанов переключения регенераторов и трехходовых заслонок после регенераторов",
          "Не допускается производить одновременно все перечисленные ремонтные работы",
        ],
        correctAnswers: [
          "Не допускается производить одновременно все перечисленные ремонтные работы",
        ],
      },
      {
        code: "20310209",
        text: "С каким интервалом должна проверяться объемная доля кислорода в месте проведения ремонтных работ во время нахождения персонала внутри трубопроводов, опорных обечаек регенераторов, в отсеке обратноповоротных клапанов или арматуры?",
        answers: [
          "С интервалом не менее чем 10 минут",
          "С интервалом не менее чем 5 минут",
          "С интервалом не менее чем 7 минут",
          "С интервалом не менее чем 3 минуты",
        ],
        correctAnswers: ["С интервалом не менее чем 10 минут"],
      },
      {
        code: "20310210",
        text: "Какие действия необходимо осуществить перед ремонтом клапанов (трубопроводов) теплого и холодного концов регенераторов в период остановки ВРУ? Выберите правильный вариант ответа.",
        answers: [
          "Только перевести на ручной режим систему управления регенераторов",
          'Только установить предупреждающий знак безопасности "Не включать, работают люди!"',
          "Только закрыть или открыть соответствующие клапаны с помощью дистанционного управления",
          "Все перечисленные действия",
        ],
        correctAnswers: ["Все перечисленные действия"],
      },
      {
        code: "20310211",
        text: "При каком условии ВРУ должна подвергаться обезжириванию?",
        answers: [
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной 5 последовательно проведенными анализами",
          "При достижении концентрации масла в жидком кислороде 0,30 мг/дм и выше, подтвержденной 3 последовательно проведенными анализами",
          "При достижении концентрации масла в жидком кислороде 0,35 мг/дм и выше, подтвержденной 5 последовательно проведенными анализами",
          "При достижении концентрации масла в жидком кислороде 0,25 мг/дм и выше, подтвержденной 3 последовательно проведенными анализами",
        ],
        correctAnswers: [
          "При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной 5 последовательно проведенными анализами",
        ],
      },
      {
        code: "20310212",
        text: "Какие установлены сроки обезжиривания ВРУ, работающих по схеме высокого и среднего давлений с блоками комплексной очистки на цеолитах и турбодетандерами, а также по схеме низкого давления?",
        answers: [
          "1 раз в месяц",
          "1 раз в неделю",
          "1 раз в квартал",
          "Сроки обезжиривания ВРУ не регламентируются",
        ],
        correctAnswers: ["Сроки обезжиривания ВРУ не регламентируются"],
      },
      {
        code: "20310213",
        text: "Что из перечисленного не подлежит обезжириванию в процессе эксплуатации ВРУ?",
        answers: [
          "Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40 % и давлением до 1,6 МПа",
          "Колонны высокого и низкого давлений",
          "Аппараты и коммуникации на потоке воздуха от поршневого детандера и от дожимающего поршневого компрессора",
          "Аппараты и коммуникации на потоке газообразного кислорода высокого давления",
        ],
        correctAnswers: [
          "Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40 % и давлением до 1,6 МПа",
        ],
      },
      {
        code: "20310214",
        text: "В каком случае арматура, предназначенная для работы с кислородом, не подлежит обезжириванию перед монтажом?",
        answers: [
          "Если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка",
          "Если монтаж осуществляется внутри компрессорного помещения",
          "Если это прописано в технологической инструкции",
        ],
        correctAnswers: [
          "Если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка",
        ],
      },
      {
        code: "20310215",
        text: "Какое из перечисленных требований при необходимости проведения обезжиривания технических устройств и трубопроводов ПРВ растворителями указано неверно?",
        answers: [
          "Перелив растворителей из одного сосуда в другой допускается любым удобным способом при наличии у работающих СИЗ",
          "Доступ в помещение, где хранятся растворители, разрешается только лицам, допущенным к работе с ними",
          "Тару из-под растворителей необходимо плотно закрывать и хранить только в предназначенном для этого помещении или на открытом воздухе",
          "Проливы растворителей на пол не допускаются, случайно пролитый растворитель должен быть немедленно убран с помощью сухих материалов",
        ],
        correctAnswers: [
          "Перелив растворителей из одного сосуда в другой допускается любым удобным способом при наличии у работающих СИЗ",
        ],
      },
      {
        code: "20310216",
        text: "До какой максимальной температуры допускается нагрев поверхностей в помещениях, где производится обезжиривание кислородного оборудования пожаровзрывоопасными растворителями?",
        answers: [
          "До температуры 120 °C",
          "До температуры 150 °C",
          "До температуры 100 °C",
          "До температуры 90 °C",
        ],
        correctAnswers: ["До температуры 120 °C"],
      },
      {
        code: "20310217",
        text: "До какой температуры должен отогреваться сосуд, бывший в эксплуатации, перед проведением работ по обезжириванию способом протирки и чем он должен продуваться?",
        answers: [
          "До температуры не ниже 20 °C и продувается воздухом",
          "До температуры не ниже 20 °C и продувается инертным газом",
          "До температуры не ниже 10 °C и продувается воздухом",
          "До температуры не ниже 15 °C и продувается кислородом воздуха",
        ],
        correctAnswers: ["До температуры не ниже 20 °C и продувается воздухом"],
      },
      {
        code: "20310218",
        text: "Какое из перечисленных требований к потреблению газообразного кислорода и других продуктов разделения воздуха указано неверно?",
        answers: [
          "Кислородная арматура для присоединения рукавов должна быть размещена в герметичном металлическом шкафу",
          "Использование ПРВ по каждому производству, участку или объекту должно осуществляться по технологическим инструкциям",
          "Для ведения надзора за безопасной эксплуатацией технических устройств и коммуникаций, связанных с потреблением ПРВ, назначаются ответственные лица из числа специалистов",
          "На кислородопроводах не допускается установка арматуры из сплавов на основе титана",
        ],
        correctAnswers: [
          "Кислородная арматура для присоединения рукавов должна быть размещена в герметичном металлическом шкафу",
        ],
      },
      {
        code: "20310219",
        text: "Какое значение не должно превышать содержание сероводорода в коксовом газе в заводских сетях действующих предприятий?",
        answers: ["4 г/нм³", "9 г/нм³", "7 г/нм³", "5 г/нм³"],
        correctAnswers: ["4 г/нм³"],
      },
      {
        code: "20310220",
        text: "Какое значение не должно превышать содержание сероводорода в коксовом газе при газоплазменной обработке металла в закрытых помещениях, а также нафталина летом и зимой?",
        answers: [
          "Содержание сероводорода в коксовом газе при газоплазменной обработке металла не должно превышать 0,02 г/нм³, нафталина - 0,05 г/нм³ (летом) и 0,1 г/нм³ (зимой)",
          "Содержание сероводорода в коксовом газе при газоплазменной обработке металла не должно превышать 0,03 г/нм³, нафталина - 0,06 г/нм³ (летом) и 0,1 г/нм³ (зимой)",
          "Содержание сероводорода в коксовом газе при газоплазменной обработке металла не должно превышать 0,04 г/нм³, нафталина - 0,07 г/нм³ (летом) и 0,2 г/нм³ (зимой)",
          "Содержание сероводорода в коксовом газе при газоплазменной обработке металла не должно превышать 0,05 г/нм³, нафталина - 0,09 г/нм³ (летом) и 0,4 г/нм³ (зимой)",
        ],
        correctAnswers: [
          "Содержание сероводорода в коксовом газе при газоплазменной обработке металла не должно превышать 0,02 г/нм³, нафталина - 0,05 г/нм³ (летом) и 0,1 г/нм³ (зимой)",
        ],
      },
      {
        code: "20310221",
        text: "В зданиях и помещениях категории А следует предусматривать наружные легкосбрасываемые ограждающие конструкции площадью, принимаемой в соответствии с расчетом. Какая площадь конструкций должна быть при отсутствии расчетных данных?",
        answers: [
          "Не менее 0,05 м² на 1 м³ объема помещений",
          "Не менее 0,03 м² на 1 м³ объема помещений",
          "Не менее 0,01 м² на 1 м³ объема помещений",
          "Не менее 0,02 м² на 1 м³ объема помещений",
        ],
        correctAnswers: ["Не менее 0,05 м² на 1 м³ объема помещений"],
      },
      {
        code: "20310222",
        text: "Какие из перечисленных ограждающих конструкций не относятся к легкосбрасываемым?",
        answers: [
          "Окна (когда оконные переплеты заполнены обычным оконным стеклом толщиной 3, 4 и 5 мм, площадью не менее соответственно 0,8, 1 и 1,5 м²)",
          "Конструкции из асбоцементных, алюминиевых и стальных листов с легким утеплением",
          "Фонарные переплеты",
          "Противопожарные двери",
        ],
        correctAnswers: ["Противопожарные двери"],
      },
      {
        code: "20310223",
        text: "При каком содержании газов должна срабатывать световая и звуковая сигнализации, установленные с автоматическими газоанализаторами в помещениях категории А, где обращается водород?",
        answers: [
          "При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.)",
          "При содержании кислорода менее 19% и более 23%",
          "При содержании угарного газа более 20 мг/м³",
          "При содержании метана не более 10% нижнего предела взрываемости",
          "Все перечисленное верно",
        ],
        correctAnswers: ["Все перечисленное верно"],
      },
      {
        code: "20310224",
        text: "В каком случае все технологическое оборудование должно продуваться инертным газом? Выберите 2 варианта ответа.",
        answers: [
          "После остановки более чем на 2 часа",
          "Перед пуском",
          "Перед вскрытием для осмотра",
          "Перед ремонтом хотя бы одного узла",
        ],
        correctAnswers: ["После остановки более чем на 2 часа", "Перед пуском"],
      },
      {
        code: "20310225",
        text: "Какие требования к компрессорным установкам указаны верно?",
        answers: [
          "Изготовление, монтаж, наладка, ремонт, испытания и эксплуатация компрессорных установок должны проводиться специализированными в этой области организациями",
          "Для уменьшения динамических нагрузок компрессоры должны устанавливаться на виброизолирующих фундаментах или с устройством амортизаторов (антивибраторов) либо должны приниматься другие меры для уменьшения вибраций",
          "Монтаж, наладка и эксплуатация компрессоров должны производиться в соответствии с инструкциями организаций-изготовителей",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20310226",
        text: "Какие требования к компрессорным установкам указаны неверно?",
        answers: [
          "Компрессор и его электродвигатель должны устанавливаться на фундаментах, связанных с конструкциями здания",
          "Двери и окна помещения, где расположены компрессорные установки, должны открываться наружу",
          "В помещении компрессорной установки следует предусматривать специальные места для хранения в закрытом виде обтирочных материалов, инструмента, прокладок, а также для хранения недельного запаса масла",
          "Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи",
        ],
        correctAnswers: [
          "Компрессор и его электродвигатель должны устанавливаться на фундаментах, связанных с конструкциями здания",
        ],
      },
      {
        code: "20310227",
        text: "Какой технической документацией должен быть оснащен каждый компрессорный агрегат или установка, или группа однородных компрессорных установок?",
        answers: [
          "Только паспортом (формуляром) на компрессорную установку; инструкцией (руководством) по эксплуатации (безопасному обслуживанию) компрессорной установки",
          "Только схемой трубопроводов (сжатого воздуха или газа, воды, масла) с указанием мест установок задвижек, вентилей, влагомаслоотделителей, промежуточных и концевых холодильников, воздухосборников, контрольно-измерительных приборов",
          "Только журналом учета работы компрессора, графиком ремонтов компрессорной установки",
          "Только паспортами-сертификатами компрессорного масла и результатами его лабораторного анализа",
          "Всей перечисленной документацией",
        ],
        correctAnswers: ["Всей перечисленной документацией"],
      },
      {
        code: "20310228",
        text: "На какой ступени сжатия водородные компрессоры должны иметь предохранительные клапаны?",
        answers: [
          "На первой ступени сжатия",
          "На последней ступени сжатия",
          "На всех ступенях сжатия",
        ],
        correctAnswers: ["На всех ступенях сжатия"],
      },
      {
        code: "20310229",
        text: "При какой чистоте водорода должен производиться пуск компрессора для наполнения баллонов?",
        answers: [
          "Не менее 99,7%",
          "Не менее 99,5%",
          "Не менее 99,2%",
          "Не менее 99,0%",
        ],
        correctAnswers: ["Не менее 99,7%"],
      },
      {
        code: "20310230",
        text: "При выявлении каких признаков компрессор, компримирующий водород, может продолжать работать?",
        answers: [
          "Только при наличии стуков и ударов (в компрессоре, двигателе)",
          "Только при неисправности контрольно-измерительных приборов, системы смазки компрессора",
          "Только при повышении температуры охлаждающей воды сверх допустимой",
          "Только при перегреве отдельных узлов и деталей, возгорании",
          "Не допускается работа при всех выявленных признаках",
        ],
        correctAnswers: ["Не допускается работа при всех выявленных признаках"],
      },
      {
        code: "20310231",
        text: "На какой высоте от пола должны быть расположены окна в помещениях отделений наполненных баллонов, наполнительных и разрядных рамп?",
        answers: [
          "На высоте не менее 1,5 м",
          "На высоте не менее 1,3 м",
          "На высоте не менее 0,8 м",
          "На высоте не менее 0,5 м",
        ],
        correctAnswers: ["На высоте не менее 1,5 м"],
      },
      {
        code: "20310232",
        text: "Какое минимальное остаточное давление должны иметь баллоны, подготовленные для наполнения водородом?",
        answers: ["0,05 МПа", "0,01 МПа", "0,03 МПа", "0,02 МПа"],
        correctAnswers: ["0,05 МПа"],
      },
      {
        code: "20310233",
        text: "В соответствии с требованиями какой документации должны производиться проектирование, монтаж и эксплуатация водородопроводов, а также вспомогательных трубопроводов обвязки участков комплекса получения и потребления водорода?",
        answers: [
          "Только в соответствии с требованиями строительных норм и правил",
          "Только в соответствии с требованиями нормативно-технической документации для технологических стальных трубопроводов",
          "Только в соответствии с требованиями Правил безопасности процессов получения или применения металлов",
          "В соответствии с требованиями всей перечисленной документации",
        ],
        correctAnswers: [
          "В соответствии с требованиями всей перечисленной документации",
        ],
      },
      {
        code: "20310234",
        text: "Каким способом допускается прокладка трубопроводов водорода в пределах предприятия? Выберите 2 варианта ответа.",
        answers: ["На стойках", "На эстакадах", "Под землей", "В каналах"],
        correctAnswers: ["На стойках", "На эстакадах"],
      },
      {
        code: "20310235",
        text: "На какой высоте должна предусматриваться прокладка газопроводов в местах прохода людей?",
        answers: [
          "На высоте не менее 2,2 м от пола до низа газопровода",
          "На высоте не менее 2,0 м от пола до низа газопровода",
          "На высоте не менее 2,2 м от пола до верха газопровода",
          "На высоте не менее 1,5 м от пола до верха газопровода",
        ],
        correctAnswers: [
          "На высоте не менее 2,2 м от пола до низа газопровода",
        ],
      },
      {
        code: "20310236",
        text: "В каких местах должна устанавливаться запорная арматура и отсекающие устройства с дистанционным управлением на вводах водородопроводов в производственные здания?",
        answers: [
          "Вне здания на расстоянии не менее 3,0 м и не более 50,0 м от места ввода в здание или ближайшего аппарата, стоящего вне здания",
          "Вне здания на расстоянии не менее 2,0 м и не более 60,0 м от места ввода в здание или ближайшего аппарата, стоящего вне здания",
          "Вне здания на расстоянии не менее 1,0 м и не более 65,0 м от места ввода в здание или ближайшего аппарата, стоящего вне здания",
          "Вне здания на расстоянии не менее 1,5 м и не более 70,0 м от места ввода в здание или ближайшего аппарата, стоящего вне здания",
        ],
        correctAnswers: [
          "Вне здания на расстоянии не менее 3,0 м и не более 50,0 м от места ввода в здание или ближайшего аппарата, стоящего вне здания",
        ],
      },
      {
        code: "20310237",
        text: "Какое должно быть время срабатывания отсекающих устройств с дистанционным управлением для максимального снижения выбросов водорода в окружающую среду при аварийной разгерметизации системы?",
        answers: [
          "Не более 120 секунд",
          "Не более 180 секунд",
          "Не более 240 секунд",
          "Не более 300 секунд",
        ],
        correctAnswers: ["Не более 120 секунд"],
      },
      {
        code: "20310238",
        text: "Какую арматуру не допускается применять на водородопроводах?",
        answers: [
          "Арматуру из серого чугуна",
          "Стальную арматуру",
          "Арматуру из ковкого и высокопрочного чугуна",
        ],
        correctAnswers: ["Арматуру из серого чугуна"],
      },
      {
        code: "20310239",
        text: "В каких случаях допускается применение арматуры из ковкого и высокопрочного чугуна на водородопроводах?",
        answers: [
          "При давлении не более 1,0 МПа и температуре от 0 °C до 50 °C",
          "При давлении не более 2,0 МПа и температуре от 10 °C до 40 °C",
          "При давлении не более 1,5 МПа и температуре от 5 °C до 30 °C",
          "При давлении не более 3,5 МПа и температуре от 15 °C до 45 °C",
        ],
        correctAnswers: [
          "При давлении не более 1,0 МПа и температуре от 0 °C до 50 °C",
        ],
      },
      {
        code: "20310240",
        text: "Какому классу должна соответствовать герметичность затвора запорной арматуры со средой водорода?",
        answers: ["Классу A", "Классу B", "Классу C", "Классу D"],
        correctAnswers: ["Классу A"],
      },
      {
        code: "20310241",
        text: "Какому классу должна соответствовать герметичность арматуры с металлическим уплотнением в затворе, применяемая для установки на трубопроводах водорода?",
        answers: ["Классу A", "Классу B", "Классу C", "Классу D"],
        correctAnswers: ["Классу B"],
      },
      {
        code: "20310242",
        text: "Какие требования к размещению запорной арматуры на водородопроводах указаны верно? Выберите правильный вариант ответа.",
        answers: [
          "Ручной привод арматуры должен располагаться на высоте не более 3,5 м от уровня площадки или пола помещения",
          "В отдельных случаях допускается применение запорной арматуры из ковкого и высокопрочного чугуна при давлении не более 3,0 МПа и температуре от 10 °C до 40 °C, работающей в условиях, не подверженных вибрациям и резко переменному температурному режиму",
          "Арматура должна быть закреплена так, чтобы в результате температурных деформаций газопроводов в них не создавались изгибающие напряжения",
          "Арматура должна размещаться над дверными проемами и технологическими проходами",
        ],
        correctAnswers: [
          "Арматура должна быть закреплена так, чтобы в результате температурных деформаций газопроводов в них не создавались изгибающие напряжения",
        ],
      },
      {
        code: "20310243",
        text: "Через какие помещения допускается прокладка водородопроводов?",
        answers: [
          "Через производственные помещения, связанные с потреблением водорода",
          "Через электрораспределительные, трансформаторные помещения, вентиляционные камеры",
          "Через бытовые, подсобные и административно-хозяйственные помещения",
          "Через помещения контрольно-измерительных приборов",
        ],
        correctAnswers: [
          "Через производственные помещения, связанные с потреблением водорода",
        ],
      },
      {
        code: "20310244",
        text: "В каких местах допускается прокладка водородопроводов?",
        answers: [
          "Над поверхностью земли, на стойках и эстакадах",
          "Через производственные помещения, не связанные с потреблением водорода",
          "По стенам зданий на участках со сплошным остеклением и по легкосбрасываемым ограждающим конструкциям",
        ],
        correctAnswers: ["Над поверхностью земли, на стойках и эстакадах"],
      },
      {
        code: "20310245",
        text: "С какой периодичностью должны проводиться испытания на плотность и прочность горячих участков (с температурой 200 - 400 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?",
        answers: [
          "1 раз в 5 лет",
          "1 раз в 6 лет",
          "1 раз в 7 лет",
          "1 раз в 8 лет",
        ],
        correctAnswers: ["1 раз в 5 лет"],
      },
      {
        code: "20310246",
        text: "С какой периодичностью должны проводиться испытания на плотность и прочность холодных участков (с температурой до 200 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?",
        answers: [
          "1 раз в 8 лет",
          "1 раз в 9 лет",
          "1 раз в 11 лет",
          "1 раз в 10 лет",
        ],
        correctAnswers: ["1 раз в 8 лет"],
      },
      {
        code: "20310247",
        text: "Какие требования к выборочной ревизии водородопроводов указаны неверно?",
        answers: [
          "Периодичность ревизии должна быть в пределах от 1 года до 8 лет",
          "Первая выборочная ревизия водородопроводов на вновь осваиваемых производствах должна производиться не позднее чем через 5 лет после ввода объекта в эксплуатацию",
          "Ревизия должна совмещаться с периодическими испытаниями на прочность и плотность",
          "Сроки проведения выборочной ревизии водородопроводов устанавливает руководство организации с учетом специфики производства, результатов наружного осмотра и предыдущей ревизии",
        ],
        correctAnswers: [
          "Первая выборочная ревизия водородопроводов на вновь осваиваемых производствах должна производиться не позднее чем через 5 лет после ввода объекта в эксплуатацию",
        ],
      },
      {
        code: "20310248",
        text: "Через какое время после ввода объекта в эксплуатацию должна проводиться первая выборочная ревизия водородопроводов на вновь осваиваемых производствах?",
        answers: [
          "Не позднее чем через 4 года после ввода объекта в эксплуатацию",
          "Не позднее чем через 5 лет после ввода объекта в эксплуатацию",
          "Не позднее чем через 7 лет после ввода объекта в эксплуатацию",
          "Не позднее чем через 10 лет после ввода объекта в эксплуатацию",
        ],
        correctAnswers: [
          "Не позднее чем через 4 года после ввода объекта в эксплуатацию",
        ],
      },
      {
        code: "20310249",
        text: "Какая продолжительность пневматических испытаний установлена Правилами безопасности процессов получения или применения металлов после проведения плановых или аварийных остановок водородопроводов?",
        answers: ["1 час", "2 часа", "1,5 часа", "3 часа"],
        correctAnswers: ["1 час"],
      },
      {
        code: "20310250",
        text: "Какие требования к монтажу и эксплуатации водородопроводов указаны неверно?",
        answers: [
          "При прокладке трубопроводов водорода следует, как правило, использовать бесшовные стальные трубы или трубопроводы из нержавеющей стали, соединенные с применением сварки",
          "Прокладка газопроводов в местах прохода людей должна предусматриваться на высоте не менее 1,3 м от пола до низа газопровода",
          "На трубопроводах водорода, транспортирующих газ в другие здания, к компрессорным, ресиверам и цехам-потребителям, на выходе из здания должны устанавливаться запорная арматура и устройства, исключающие возможность обратного пропуска газа",
          "При прокладке цеховых трубопроводов водорода должна быть предусмотрена самокомпенсация",
        ],
        correctAnswers: [
          "Прокладка газопроводов в местах прохода людей должна предусматриваться на высоте не менее 1,3 м от пола до низа газопровода",
        ],
      },
      {
        code: "20310251",
        text: "На каких минимальных расстояниях (по горизонтали) до групповых газобаллонных установок допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?",
        answers: ["10 м", "1,5 м", "5 м", "7,5 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "20310252",
        text: "На каких минимальных расстояниях (по горизонтали) до отдельных баллонов с кислородом и горючими газами допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?",
        answers: ["5 м", "10 м", "1,5 м", "7,5 м"],
        correctAnswers: ["5 м"],
      },
      {
        code: "20310253",
        text: "В каком положении должны находиться баллоны со сжиженным газом во время работы?",
        answers: ["В вертикальном", "В горизонтальном", "Под углом 45°"],
        correctAnswers: ["В вертикальном"],
      },
      {
        code: "20310254",
        text: "Какое действие должно быть осуществлено в первую очередь при зажигании ручной горелки или резака?",
        answers: [
          "Приоткрыть вентиль кислорода",
          "Открыть вентиль горючего газа",
          "Зажечь горючую смесь газов",
        ],
        correctAnswers: ["Приоткрыть вентиль кислорода"],
      },
      {
        code: "20310255",
        text: "Какое из перечисленных действий допускается при эксплуатации оборудования для газопламенной обработки металлов? Выберите правильный вариант ответа.",
        answers: [
          "Нагревание баллонов со сжиженным газом прямыми солнечными лучами или другими источниками тепла сверх температуры, указанной производителем",
          "Отбор газа из баллона при снижении в нем рабочего давления ниже требуемого при питании передвижного рабочего поста сжиженным газом от баллона",
          "Продувка рукава для горючих газов кислородом и кислородного рукава горючим газом, а также взаимозамена рукавов при работе",
          "Направление пламени горелки (резака) в сторону, противоположную источнику газоснабжения",
        ],
        correctAnswers: [
          "Направление пламени горелки (резака) в сторону, противоположную источнику газоснабжения",
        ],
      },
      {
        code: "20310256",
        text: "Какое из перечисленных требований при эксплуатации взрывопожароопасных объектов указано неверно?",
        answers: [
          "В помещениях категории A проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией должна производиться в сроки, предусмотренные заводской инструкцией, но не реже 1 раза в месяц",
          "В помещениях категории A хранение обтирочных и смазочных материалов допускается в количестве, не превышающем месячной потребности, в металлических, плотно закрывающихся шкафах или ящиках",
          "На каждой двери помещения категории A должны быть указаны категория помещения и класс взрывоопасной зоны",
          "Работы по ремонту электрооборудования в помещениях категории A должны выполняться после обесточивания электросети, с использованием, при необходимости, переносных аккумуляторных взрывобезопасных светильников",
        ],
        correctAnswers: [
          "В помещениях категории A хранение обтирочных и смазочных материалов допускается в количестве, не превышающем месячной потребности, в металлических, плотно закрывающихся шкафах или ящиках",
        ],
      },
      {
        code: "20310257",
        text: "Что должно предусматриваться для обеспечения требуемых режимов эксплуатации установки по производству водорода? Выберите правильный вариант ответа.",
        answers: [
          "Только управление и контроль всех технологических параметров оборудования из операторной",
          "Только дистанционное (аварийное) отключение установки по производству водорода",
          "Только дистанционный контроль оборудования из операторной",
          "Только автоматическое отключение установки по производству водорода при нарушении нормальной работы по технологическим параметрам",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20310258",
        text: "Каким газом должны продуваться перед пуском и после остановки установки по производству водорода?",
        answers: ["Азотом", "Кислородом воздуха", "Сжатым воздухом"],
        correctAnswers: ["Азотом"],
      },
      {
        code: "20310259",
        text: "Какой должен быть общий объем инертного газа для нужд продувки водородной системы?",
        answers: [
          "Не менее 4-кратного объема продуваемой водородной системы",
          "Не менее 2-кратного объема продуваемой водородной системы",
          "Не менее 3-кратного объема продуваемой водородной системы",
          "Равный объему продуваемой водородной системы",
        ],
        correctAnswers: [
          "Не менее 4-кратного объема продуваемой водородной системы",
        ],
      },
      {
        code: "20310260",
        text: "Какое допускается содержание кислорода в инертном газе, предназначенном для продувки водородной системы?",
        answers: ["Не более 1%", "Не более 3%", "Не более 5%", "Не более 7%"],
        correctAnswers: ["Не более 1%"],
      },
      {
        code: "20310261",
        text: "Какие требования по обеспечению производства инертным газом указаны неверно?",
        answers: [
          "Содержание кислорода в инертном газе не должно превышать 3%",
          "Давление продувочного инертного газа не должно превышать давления в продуваемой системе",
          "На каждом вводе азотопровода в здание должны быть установлены обратный клапан, запорный вентиль, а на каждом ответвлении (на вводе в каждое помещение) должны быть установлены манометр и запорный вентиль",
          "Во всех случаях в помещениях, связанных с обращением водорода, а также на ресиверных площадках должна быть предусмотрена стационарная разводка трубопроводов инертного газа на продувку и пожаротушение",
        ],
        correctAnswers: [
          "Содержание кислорода в инертном газе не должно превышать 3%",
        ],
      },
      {
        code: "20310262",
        text: "Какие требования к пневматическим КИПиА указаны неверно?",
        answers: [
          "Воздухопроводы для КИПиА должны быть связаны с трубопроводами на технологические нужды",
          "В помещениях с постоянным пребыванием обслуживающего персонала (операторной, пультовой) предупреждающий и аварийный сигналы должны подаваться на щит управления и у выхода внутри помещения",
          "Для пневматических КИПиА должны предусматриваться специальные установки и отдельные сети сжатого воздуха",
          "Средства измерения должны проходить периодическую поверку или калибровку",
        ],
        correctAnswers: [
          "Воздухопроводы для КИПиА должны быть связаны с трубопроводами на технологические нужды",
        ],
      },
      {
        code: "20310263",
        text: "В каких помещениях предупреждающий и аварийный сигналы должны подаваться у входа вне помещения?",
        answers: [
          "В помещениях с периодическим пребыванием персонала, где установлены датчики",
          "В отдельных щитовых помещениях (встроенных или пристроенных), смежных с помещениями с взрывоопасными зонами и в отдельно стоящих зданиях",
          "В помещениях с постоянным пребыванием обслуживающего персонала",
          "В помещениях наполнения, хранения и разрядки баллонов",
        ],
        correctAnswers: [
          "В помещениях с периодическим пребыванием персонала, где установлены датчики",
        ],
      },
      {
        code: "20310264",
        text: "Какая допускается максимальная погрешность (точность измерения) газоанализаторов водорода?",
        answers: [
          "±0,2% объемных",
          "±0,01% объемных",
          "±0,1% объемных",
          "±0,15% объемных",
        ],
        correctAnswers: ["±0,2% объемных"],
      },
      {
        code: "20310265",
        text: "Какие производственные помещения должны быть оборудованы системами связи и сигнализации?",
        answers: [
          "Только помещения, связанные с получением водорода",
          "Только помещения, связанные с хранением водорода",
          "Только помещения, связанные с применением водорода",
          "Все перечисленные помещения",
        ],
        correctAnswers: ["Все перечисленные помещения"],
      },
      {
        code: "20310266",
        text: "Чему должны соответствовать параметры процесса эксплуатации и ремонта оборудования?",
        answers: [
          "Только требованиям установленного технологического регламента",
          "Только рекомендациям завода-изготовителя",
          "Только паспортным данным оборудования",
          "Всему перечисленному",
        ],
        correctAnswers: ["Всему перечисленному"],
      },
      {
        code: "20310267",
        text: "В соответствии с каким документом работникам необходимо соблюдать требования безопасности при загрузке и выгрузке катализаторов и адсорбентов?",
        answers: [
          "В соответствии с технологическим регламентом",
          "В соответствии с техническими условиями завода-изготовителя",
          "В соответствии с требованиями инструкции, разработанной эксплуатирующей организацией",
        ],
        correctAnswers: ["В соответствии с технологическим регламентом"],
      },
      {
        code: "20310268",
        text: "На каких аппаратах, заполненных водородом, допускается производство ремонтных работ?",
        answers: [
          "На аппаратах, заполненных водородом, производство ремонтных работ запрещается",
          "На аппаратах, заполненных водородом менее чем на 50%",
          "На аппаратах, ремонт которых производится в соответствии с техническим регламентом",
        ],
        correctAnswers: [
          "На аппаратах, заполненных водородом, производство ремонтных работ запрещается",
        ],
      },
      {
        code: "20310269",
        text: "Когда могут производиться ремонтные работы с открытым огнем в помещении с обращением водорода водородной станции?",
        answers: [
          "Не ранее чем через 4 часа после отключения установки",
          "Не ранее чем через 2 часа после отключения установки",
          "Не ранее чем через 3 часа после отключения установки",
          "Не ранее чем через час после отключения установки",
        ],
        correctAnswers: [
          "Не ранее чем через 4 часа после отключения установки",
        ],
      },
      {
        code: "20310270",
        text: "Каким образом не допускается определять утечки газов из соединений?",
        answers: [
          "С использованием открытого огня",
          "С использованием мыльного раствора",
          "С использованием специальных течеискателей",
        ],
        correctAnswers: ["С использованием открытого огня"],
      },
      {
        code: "20310271",
        text: "В соответствии с какой документацией должны производиться подготовка к пуску и пуск установок по производству водорода?",
        answers: [
          "Только в соответствии с технологическим регламентом",
          "Только в соответствии с инструкцией по эксплуатации",
          "Только в соответствии с проектом",
          "В соответствии со всей перечисленной документацией",
        ],
        correctAnswers: ["В соответствии со всей перечисленной документацией"],
      },
      {
        code: "20310272",
        text: "Какие системы должны быть проверены в обязательном порядке перед началом пуска установок получения водорода? Выберите правильный вариант ответа.",
        answers: [
          "Только системы тепло- и водоснабжения",
          "Только системы электро- и воздухоснабжения",
          "Только системы охлаждения и снабжения инертными газами",
          "Только системы отопления и вентиляции",
          "Все перечисленные системы",
        ],
        correctAnswers: ["Все перечисленные системы"],
      },
      {
        code: "20310273",
        text: "Какой порядок действий персонала при пожаре на установке получения водорода указан верно?",
        answers: [
          "Немедленно произвести аварийное отключение установки, вызвать пожарную команду, при утечке газов - газоспасательную службу, перекрыть подачу технологических газов на установку, снизить давление и выпустить водород в факельную систему для утилизации",
          "Немедленно вызвать пожарную команду, произвести аварийное отключение установки, перекрыть подачу технологических газов на установку, снизить давление и выпустить водород в факельную систему для утилизации",
          "Немедленно перекрыть подачу технологических газов на установку, вызвать пожарную команду, газоспасательную службу, снизить давление и выпустить водород в факельную систему для утилизации",
        ],
        correctAnswers: [
          "Немедленно произвести аварийное отключение установки, вызвать пожарную команду, при утечке газов - газоспасательную службу, перекрыть подачу технологических газов на установку, снизить давление и выпустить водород в факельную систему для утилизации",
        ],
      },
      {
        code: "20310274",
        text: "Какие из перечисленных требований при ведении работ на объектах производства водорода указаны неверно?",
        answers: [
          "Работники на объектах производства водорода должны быть обеспечены СИЗ, спецодеждой, спецобувью и другими средствами, которые должны отвечать требованиям соответствующих стандартов безопасности труда",
          "Открытые движущиеся части машин и механизмов, а также ременные, цепные и зубчатые передачи должны быть снабжены ограждениями, исключающими опасность травмирования людей этими частями и попадания в них посторонних предметов",
          "Узлы, детали, приспособления и элементы оборудования, которые могут служить источником опасности для работающих, а также поверхности оградительных и защитных устройств должны быть окрашены в сигнальные цвета",
          "Допускается входить на объекты с производством и обращением водорода в обуви с железными набойками или гвоздями, а также в одежде, способной накапливать заряды статического электричества",
        ],
        correctAnswers: [
          "Допускается входить на объекты с производством и обращением водорода в обуви с железными набойками или гвоздями, а также в одежде, способной накапливать заряды статического электричества",
        ],
      },
      {
        code: "20310275",
        text: "Какие требования безопасности при получении водорода методом диссоциации аммиака указаны неверно?",
        answers: [
          "Помещения, в которых размещены диссоциаторы, должны иметь категорию А по взрывопожароопасности выше отметки 5,5 м от уровня пола",
          "Все стены, разделяющие помещения, должны быть выполнены из негорючих материалов с пределом огнестойкости более 2,5 часа",
          "Оператору установки необходимо не реже 1 раза в неделю производить анализ на содержание аммиака в помещении диссоциаторов методами и приборами, установленными в технологическом регламенте (технологической инструкции)",
          "Процесс получения водорода и диссоциированного аммиака должен быть автоматизирован",
        ],
        correctAnswers: [
          "Оператору установки необходимо не реже 1 раза в неделю производить анализ на содержание аммиака в помещении диссоциаторов методами и приборами, установленными в технологическом регламенте (технологической инструкции)",
        ],
      },
      {
        code: "20310276",
        text: "Какие требования к процессу получения водорода и диссоциированного аммиака указаны неверно?",
        answers: [
          "Процесс получения водорода и диссоциированного аммиака должен быть автоматизирован",
          "Регулировка и контроль производится с помощью приборов",
          "Аппаратура автоматического регулирования, контроля и сигнализации должна быть установлена на пульте управления, расположенном в отдельном помещении категории пожарной опасности Г",
          "Все перечисленные требования указаны верно",
        ],
        correctAnswers: ["Все перечисленные требования указаны верно"],
      },
      {
        code: "20310277",
        text: "Чем допускается тушить пламя при возгорании водорода? Выберите два варианта ответа.",
        answers: ["Песком", "Кошмой", "Водой", "Огнетушителем"],
        correctAnswers: ["Песком", "Кошмой"],
      },
      {
        code: "20310278",
        text: "С какой периодичностью оператору установки необходимо производить анализ на содержание аммиака в помещении диссоциаторов методами и приборами, установленными в технологическом регламенте (технологической инструкции)?",
        answers: [
          "Не реже 1 раза в смену",
          "Не реже 1 раза в 3 смены",
          "Не реже 1 раза в неделю",
          "Не реже 1 раза в месяц",
        ],
        correctAnswers: ["Не реже 1 раза в смену"],
      },
      {
        code: "20310279",
        text: "Какие требования для предотвращения взрывоопасных концентраций под кровлей помещения диссоциаторов и компрессорной водорода указаны неверно?",
        answers: [
          "Для предотвращения взрывоопасных концентраций под кровлей помещения диссоциаторов и компрессорной водорода должны быть установлены датчики довзрывных концентраций",
          "Количество и места расположения датчиков должны определяться технологическим регламентом",
          "Светозвуковая сигнализация должна иметь вывод на пульт оператора и в помещение пожарного депо предприятия",
        ],
        correctAnswers: [
          "Количество и места расположения датчиков должны определяться технологическим регламентом",
        ],
      },
      {
        code: "20310280",
        text: "Что должно быть предусмотрено автоматизированной системой управления процессом при получении водорода методом диссоциации аммиака? Выберите правильный вариант ответа.",
        answers: [
          "Только светозвуковая сигнализация при достижении предельной концентрации водорода под кровлей помещения диссоциаторов и компрессорной водорода",
          "Только сигнализация понижения или повышения температуры диссоциации",
          "Только обеспечение равномерной подачи аммиака к нижнему диссоциатору",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20310281",
        text: "С каким минимальным количеством независимых источников электроснабжения должно быть выполнено электропитание оборудования производства водорода?",
        answers: [
          "С 2 источниками",
          "С 1 источником",
          "С 3 источниками",
          "Не регламентируется",
        ],
        correctAnswers: ["С 2 источниками"],
      },
      {
        code: "20310282",
        text: "Какие требования к аварийной системе вентиляции в компрессорном помещении при получении водорода методом диссоциации аммиака указаны неверно? Выберите правильный вариант ответа.",
        answers: [
          "Аварийная система вентиляции должна обеспечивать в компрессорном помещении 4-кратный воздухообмен",
          "Аварийная система вентиляции должна включаться в работу автоматически при наличии в помещении газов, достигших 20% нижнего предела взрываемости",
          "Аварийная система вентиляции должна включаться в работу автоматически при наличии в помещении газов, достигших 0,8% содержания водорода в воздухе помещения",
        ],
        correctAnswers: [
          "Аварийная система вентиляции должна обеспечивать в компрессорном помещении 4-кратный воздухообмен",
        ],
      },
    ],
  },
  81: {
    20401: [
      {
        code: "20401000",
        text: "На какие предприятия (организации) не распространяются Правила безопасности при ведении горных работ и переработке твердых полезных ископаемых?",
        answers: [
          "На предприятия по переработке негорючих твердых полезных ископаемых",
          "На гидрометаллургические фабрики",
          "На объекты кучного выщелачивания",
          "На предприятия (организации) по переработке твердых горючих полезных ископаемых",
        ],
        correctAnswers: [
          "На предприятия (организации) по переработке твердых горючих полезных ископаемых",
        ],
      },
      {
        code: "20401001",
        text: "Кто должен при поступлении на работу проходить инструктаж по безопасным приемам выполнения работ?",
        answers: [
          "Только рабочие, выполняющие работы повышенной опасности",
          "Только студенты высших и средних горнотехнических учебных заведений перед прохождением производственной практики",
          "Только рабочие, занятые на работах, выполнение которых предусматривает совмещение профессий",
          "Рабочие, ведущие работы по переработке полезных ископаемых",
        ],
        correctAnswers: [
          "Рабочие, ведущие работы по переработке полезных ископаемых",
        ],
      },
      {
        code: "20401002",
        text: "С какой периодичностью рабочие, ведущие горные работы, должны проходить инструктаж по безопасным приемам выполнения работ и проверку знаний инструкций по профессиям?",
        answers: [
          "Инструктаж по безопасным приемам выполнения работ - не реже чем каждые 6 месяцев и проверку знаний инструкций по профессиям - не реже 1 раза в год",
          "Инструктаж по безопасным приемам выполнения работ - не реже 1 раза в год и проверку знаний инструкций по профессиям - не реже чем каждые 6 месяцев",
          "Инструктаж по безопасным приемам выполнения работ - не реже чем каждые 3 месяца и проверку знаний инструкций по профессиям - не реже 1 раза в год",
        ],
        correctAnswers: [
          "Инструктаж по безопасным приемам выполнения работ - не реже чем каждые 6 месяцев и проверку знаний инструкций по профессиям - не реже 1 раза в год",
        ],
      },
      {
        code: "20401003",
        text: "Какой инструктаж проводится для рабочих при изменении характера работы или в случае выявления грубых нарушений требований безопасного ведения работ?",
        answers: ["Целевой", "Внеплановый", "Повторный", "Первичный"],
        correctAnswers: ["Внеплановый"],
      },
      {
        code: "20401004",
        text: "Как часто должны проходить медицинский осмотр рабочие, выполняющие работы повышенной опасности, перечень которых установлен руководителем организации?",
        answers: [
          "Каждый раз перед началом смены",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в полугодие",
        ],
        correctAnswers: ["Каждый раз перед началом смены"],
      },
      {
        code: "20401005",
        text: "Кто допускается к обслуживанию и ремонту электроустановок?",
        answers: [
          "Персонал, прошедший обучение",
          "Лица, имеющие соответствующее образование",
          "Лица, прошедшие обучение и сдавшие экзамен на знание ими обслуживаемого оборудования",
          "Специально подготовленный электротехнический и электротехнологический персонал, прошедший обучение по безопасным методам работы и проверку знаний в квалификационной комиссии с присвоением соответствующей группы допуска по электробезопасности",
        ],
        correctAnswers: [
          "Специально подготовленный электротехнический и электротехнологический персонал, прошедший обучение по безопасным методам работы и проверку знаний в квалификационной комиссии с присвоением соответствующей группы допуска по электробезопасности",
        ],
      },
      {
        code: "20401006",
        text: "Разрешается ли совмещение профессий рабочими?",
        answers: [
          "Не разрешается",
          "Разрешается только при наличии у них соответствующей квалификации",
          "Разрешается только при прохождении дополнительного медицинского обследования",
          "Разрешается только при наличии у них соответствующей квалификации и прохождении обучения безопасным приемам труда и инструктажей по всем видам совмещенных работ",
        ],
        correctAnswers: [
          "Разрешается только при наличии у них соответствующей квалификации и прохождении обучения безопасным приемам труда и инструктажей по всем видам совмещенных работ",
        ],
      },
      {
        code: "20401007",
        text: "Кто допускается к техническому руководству работами на объектах ведения горных работ и переработки полезных ископаемых?",
        answers: [
          "Лица с высшим техническим образованием",
          "Лица, имеющие высшее или среднее специальное соответствующее образование",
          "Лица, прошедшие аттестацию в Центральной аттестационной комиссии Ростехнадзора",
          "Лица, имеющие право ответственного ведения работ",
        ],
        correctAnswers: [
          "Лица, имеющие высшее или среднее специальное соответствующее образование",
        ],
      },
      {
        code: "20401008",
        text: "На каком транспорте доставляются рабочие к месту работы?",
        answers: [
          "На специальном транспорте",
          "На попутном транспорте",
          "На такси",
          "На личном транспорте",
        ],
        correctAnswers: ["На специальном транспорте"],
      },
      {
        code: "20401009",
        text: "Как должно осуществляться передвижение людей по территории объектов переработки полезных ископаемых?",
        answers: [
          "По специально устроенным пешеходным дорожкам или по обочинам автодорог навстречу движущемуся автотранспорту по утвержденному маршруту",
          "Передвижение людей на объекте должно осуществляться только на автомобильном транспорте",
          "Правилами не регламентируется",
        ],
        correctAnswers: [
          "По специально устроенным пешеходным дорожкам или по обочинам автодорог навстречу движущемуся автотранспорту по утвержденному маршруту",
        ],
      },
      {
        code: "20401010",
        text: "Какой должна быть минимальная высота перил обслуживающих площадок, лестниц, мостиков, монтажных проемов, колодцев, канав?",
        answers: ["1,5 м", "1,2 м", "1,0 м", "1,1 м", "0,8 м", "0,7 м"],
        correctAnswers: ["1,1 м"],
      },
      {
        code: "20401011",
        text: "Какой угол наклона к рабочим площадкам и механизмам должны иметь постоянно эксплуатируемые лестницы?",
        answers: [
          "Не более 80°",
          "Не более 60°",
          "Не более 55°",
          "Не более 45°",
        ],
        correctAnswers: ["Не более 45°"],
      },
      {
        code: "20401012",
        text: "Каким должен быть угол наклона лестниц к рабочим площадкам и механизмам,   посещаемым 1 - 2 раза в смену, а также в зумпфах и колодцах?",
        answers: [
          "К посещаемым 1 - 2 раза в смену - не более 80°, в зумпфах и колодцах - до 80°",
          "К посещаемым 1 - 2 раза в смену - не более 70°, в зумпфах и колодцах - до 90°",
          "К посещаемым 1 - 2 раза в смену - не более 50°, в зумпфах и колодцах - до 75°",
          "К посещаемым 1 - 2 раза в смену - не более 60°, в зумпфах и колодцах - до 90°",
        ],
        correctAnswers: [
          "К посещаемым 1 - 2 раза в смену - не более 60°, в зумпфах и колодцах - до 90°",
        ],
      },
      {
        code: "20401013",
        text: "Какой должна быть ширина лестниц, высота ступеней, ширина ступеней лестниц к рабочим площадкам и механизмам поверхностного комплекса объектов горных работ и переработки полезных ископаемых?",
        answers: [
          "Ширина лестницы должна быть не менее 1,0 м, высота ступеней - не более 0,4 м, ширина ступеней - не менее 0,35 м",
          "Ширина лестницы должна быть не менее 0,7 м, высота ступеней - не более 0,3 м, ширина ступеней - не менее 0,25 м",
          "Ширина лестницы должна быть не менее 0,8 м, высота ступеней - не более 0,25 м, ширина ступеней не менее 0,3 м",
          "Ширина лестницы должна быть не менее 0,7 м, высота ступеней - не более 0,25 м, ширина ступеней - не менее 0,25 м",
        ],
        correctAnswers: [
          "Ширина лестницы должна быть не менее 0,7 м, высота ступеней - не более 0,3 м, ширина ступеней - не менее 0,25 м",
        ],
      },
      {
        code: "20401014",
        text: "Каким образом машинист должен воспринимать каждый неправильно поданный или непонятный сигнал?",
        answers: [
          'Как сигнал "Стоп"',
          'Как сигнал "Осторожно"',
          'Как сигнал "Поднять стрелу"',
          'Как сигнал "Опустить стрелу"',
        ],
        correctAnswers: ['Как сигнал "Стоп"'],
      },
      {
        code: "20401015",
        text: "С кем необходимо согласовывать планы и схемы развития горных работ?",
        answers: [
          "С Федеральной службой по экологическому, технологическому и атомному надзору",
          "С Федеральной службой по природопользованию",
          "С Федеральной службой государственной регистрации, кадастра и картографии",
          "С Федеральной службой по надзору в сфере природопользования",
        ],
        correctAnswers: [
          "С Федеральной службой по экологическому, технологическому и атомному надзору",
        ],
      },
      {
        code: "20401016",
        text: "В каком из перечисленных случаев (кроме аварийных случаев) допускается остановка объектов жизнеобеспечения (электростанций, водоотливов, калориферных установок и др.)?",
        answers: [
          "По разрешению главного механика организации, согласованному с руководителем организации",
          "По письменному разрешению руководителя подрядной организации, выполняющей работы на объекте",
          "По устному разрешению технического руководителя эксплуатирующей организации",
          "По письменному разрешению технического руководителя эксплуатирующей организации",
        ],
        correctAnswers: [
          "По письменному разрешению технического руководителя эксплуатирующей организации",
        ],
      },
      {
        code: "20401017",
        text: "Какая минимальная ширина допустима для переходных мостиков, устанавливаемых над монтажными проемами, приямками, зумпфами, колодцами, канавами, расположенных в зданиях и сооружениях?",
        answers: ["1,2 м", "1,1 м", "1,5 м", "1,0 м", "0,9 м"],
        correctAnswers: ["1,0 м"],
      },
      {
        code: "20401018",
        text: "На какой минимальной высоте от уровня пола должны быть размещены коммуникации: трубы, желоба над рабочими площадками?",
        answers: ["2,2 м", "2,1 м", "2,0 м", "1,8 м", "1,7 м"],
        correctAnswers: ["2,0 м"],
      },
      {
        code: "20401019",
        text: "Какое минимальное расстояние должно быть между машинами и аппаратами и от стен до габаритов оборудования на основных проходах и на рабочих проходах между машинами в зданиях и сооружениях поверхностного комплекса объектов ведения горных работ и переработки полезных ископаемых?",
        answers: [
          "На основных проходах - не менее 2,0 м, на рабочих проходах между машинами - не менее 1,0 м",
          "На основных проходах - не менее 1,5 м, на рабочих проходах между машинами - не менее 1,0 м",
          "На основных проходах - не менее 0,8 м, на рабочих проходах между машинами - не менее 0,8 м",
          "На основных проходах - не менее 1,0 м, на рабочих проходах между машинами - не менее 0,9 м",
        ],
        correctAnswers: [
          "На основных проходах - не менее 1,5 м, на рабочих проходах между машинами - не менее 1,0 м",
        ],
      },
      {
        code: "20401020",
        text: "Какое минимальное расстояние должно быть между машинами и аппаратами и от стен до габаритов оборудования на рабочих проходах между стеной и машинами, на проходах для обслуживания и ремонта в зданиях и сооружениях поверхностного комплекса объектов ведения горных работ и переработки полезных ископаемых?",
        answers: [
          "На рабочих проходах между стеной и машинами - не менее 0,8 м, на проходах для обслуживания и ремонта - не менее 0,5 м",
          "На рабочих проходах между стеной и машинами - не менее 0,7 м, на проходах для обслуживания и ремонта - не менее 0,5 м",
          "На рабочих проходах между стеной и машинами - не менее 0,7 м, на проходах для обслуживания и ремонта - не менее 0,6 м",
          "На рабочих проходах между стеной и машинами - не менее 0,6 м, на проходах для обслуживания и ремонта - не менее 1,0 м",
        ],
        correctAnswers: [
          "На рабочих проходах между стеной и машинами - не менее 0,7 м, на проходах для обслуживания и ремонта - не менее 0,6 м",
        ],
      },
      {
        code: "20401021",
        text: "В каких количествах на рабочих местах должны храниться горюче-смазочные и обтирочные материалы?",
        answers: [
          "В количествах, необходимых для производства работ",
          "В количествах не более суточной потребности в каждом из материалов",
          "В количествах не более трехсуточной потребности в каждом из материалов",
          "В количествах месячной потребности в каждом из материалов",
          "Хранение горюче-смазочных и обтирочных материалов на рабочих местах запрещается",
        ],
        correctAnswers: [
          "В количествах не более трехсуточной потребности в каждом из материалов",
        ],
      },
      {
        code: "20401022",
        text: "Как должны храниться горюче-смазочные и обтирочные материалы на рабочих местах?",
        answers: [
          "Хранение горюче-смазочных и легковоспламеняющихся материалов на рабочих местах запрещается",
          "Горюче-смазочные материалы на рабочих местах должны храниться в закрытых металлических емкостях, обтирочные материалы - в стеклянных емкостях",
          "Горюче-смазочные и обтирочные материалы на рабочих местах должны храниться в закрытых металлических емкостях",
          "Горюче-смазочные и обтирочные материалы на рабочих местах должны храниться в металлических емкостях в количествах недельной потребности",
        ],
        correctAnswers: [
          "Горюче-смазочные и обтирочные материалы на рабочих местах должны храниться в закрытых металлических емкостях",
        ],
      },
      {
        code: "20401023",
        text: "Каким должен быть порядок дистанционного запуска технологической цепи аппаратов оператором?",
        answers: [
          "Перед запуском должна быть проверена сигнализация об аварийной остановке оборудования, поданы звуковой предупредительный сигнал продолжительностью не менее 30 сек., выдержка времени не менее 10 сек., второй сигнал продолжительностью 30 сек. до начала запуска",
          "Перед запуском должно быть проверено отсутствие в опасной зоне людей, поданы звуковой предупредительный сигнал продолжительностью не менее 30 сек., выдержка времени не менее 30 сек., второй сигнал продолжительностью 10 сек. до начала запуска",
          "Перед запуском должны быть поданы два предупредительных звуковых сигнала с выдержкой времени между сигналами не менее 30 сек.",
          "Перед запуском должны быть поданы звуковой предупредительный сигнал продолжительностью не менее 10 сек. После первого сигнала должна предусматриваться выдержка времени не менее 30 сек., после чего должен подаваться второй сигнал продолжительностью 30 сек. до начала запуска первого технологического аппарата",
        ],
        correctAnswers: [
          "Перед запуском должны быть поданы звуковой предупредительный сигнал продолжительностью не менее 10 сек. После первого сигнала должна предусматриваться выдержка времени не менее 30 сек., после чего должен подаваться второй сигнал продолжительностью 30 сек. до начала запуска первого технологического аппарата",
        ],
      },
      {
        code: "20401024",
        text: "Какими документами необходимо руководствоваться при эксплуатации оборудования?",
        answers: [
          "Только техническими паспортами",
          "Только инструкциями",
          "Руководствами по эксплуатации, техническими паспортами (формулярами) и другой документацией заводов-изготовителей",
          "Только руководствами по эксплуатации",
        ],
        correctAnswers: [
          "Руководствами по эксплуатации, техническими паспортами (формулярами) и другой документацией заводов-изготовителей",
        ],
      },
      {
        code: "20401025",
        text: "Как должно производиться оповещение о прекращении работы вентиляторов в реагентном отделении?",
        answers: [
          "С помощью звуковой или световой сигнализации",
          "Сообщением по связи от диспетчера",
          "Поступлением сообщения на центральный пульт управления",
        ],
        correctAnswers: ["С помощью звуковой или световой сигнализации"],
      },
      {
        code: "20401026",
        text: "Разрешается ли продолжать эксплуатацию технологического оборудования, которое выделяет пыль и газы, при неисправных системах вентиляции?",
        answers: [
          "Разрешается, если это обусловлено производственной необходимостью",
          "Разрешается по указанию технического руководителя организации",
          "Запрещается",
          "Разрешается после уведомления представителя Ростехнадзора",
        ],
        correctAnswers: ["Запрещается"],
      },
      {
        code: "20401027",
        text: "Как необходимо проводить уборку пыли в производственных помещениях?",
        answers: [
          "Посредством механизированной пневмоуборки и/или гидроуборки",
          "Любыми подручными средствами и способами",
          "Посредством влажной уборки вручную",
        ],
        correctAnswers: [
          "Посредством механизированной пневмоуборки и/или гидроуборки",
        ],
      },
      {
        code: "20401028",
        text: "Кем утверждается акт о проведении опытно-промышленных испытаний (ОПИ)?",
        answers: [
          "Техническим руководителем организации",
          "Лицом, ответственным за проведение ОПИ",
          "Руководителем организации",
        ],
        correctAnswers: ["Техническим руководителем организации"],
      },
      {
        code: "20401029",
        text: "Кем осуществляется подготовка планов и схем развития горных работ?",
        answers: [
          "Пользователем недр или проектной организацией",
          "Только пользователем недр",
          "Пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ",
        ],
        correctAnswers: [
          "Пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ",
        ],
      },
      {
        code: "20401030",
        text: "На какой срок разрабатывается план развития горных работ по всем планируемым видам горных работ?",
        answers: ["На 1 год", "На 2 года", "На 3 года", "На 5 лет"],
        correctAnswers: ["На 1 год"],
      },
      {
        code: "20401031",
        text: "На какой срок составляется план горных работ по всем планируемым видам работ?",
        answers: ["На 1 год", "На 3 года", "На 5 лет"],
        correctAnswers: ["На 1 год"],
      },
      {
        code: "20401032",
        text: "По каким видам полезных ископаемых подготавливаются планы и схемы развития горных работ?",
        answers: [
          "Только по твердым полезным ископаемым",
          "Только по углеводородному сырью",
          "Только по  общераспространенным полезным ископаемым",
          "Только по минеральным, теплоэнергетическим, техническим и промышленным подземным водам",
          "По всем перечисленным",
        ],
        correctAnswers: ["По всем перечисленным"],
      },
      {
        code: "20401033",
        text: "Что определяется планами и схемами развития горных работ?",
        answers: [
          "Направления развития горных работ, условия безопасного недропользования, технические и технологические решения при эксплуатации объектов ведения горных работ, объемы добычи, первичной переработки полезных ископаемых, вскрышных пород, а также допустимые величины их отклонений от планируемых значений и (или) установленных в лицензии на пользование недрами, техническом проекте и иной проектной документации на выполнение работ, связанных с пользованием недрами",
          "Только направления развития горных работ, условия безопасного недропользования",
          "Только технические и технологические решения при эксплуатации объектов ведения горных работ",
          "Только объемы добычи, первичной переработки полезных ископаемых, вскрышных пород, а также допустимые величины их отклонений от планируемых значений и (или) установленных в лицензии на пользование недрами, техническом проекте и иной проектной документации на выполнение работ, связанных с пользованием недрами",
        ],
        correctAnswers: [
          "Направления развития горных работ, условия безопасного недропользования, технические и технологические решения при эксплуатации объектов ведения горных работ, объемы добычи, первичной переработки полезных ископаемых, вскрышных пород, а также допустимые величины их отклонений от планируемых значений и (или) установленных в лицензии на пользование недрами, техническом проекте и иной проектной документации на выполнение работ, связанных с пользованием недрами",
        ],
      },
      {
        code: "20401034",
        text: "Кем утверждаются план и схема развития горных работ?",
        answers: [
          "Техническим руководителем организации - пользователя недр",
          "Руководителем организации - пользователя недр",
          "Органом исполнительной власти субъекта Российской Федерации",
          "Органом государственного горного надзора",
        ],
        correctAnswers: ["Руководителем организации - пользователя недр"],
      },
      {
        code: "20401035",
        text: "Кем утверждается план и схема развития горных работ?",
        answers: [
          "Территориальным органом Ростехнадзора",
          "Руководителем организации - пользователя недр",
          "Главным инженером организации",
          "Главным техническим руководителем организации",
        ],
        correctAnswers: ["Руководителем организации - пользователя недр"],
      },
      {
        code: "20401036",
        text: "Какой документ составляется перед производством горных работ и утверждается техническим руководителем объекта?",
        answers: [
          "Мероприятия по подготовке объектов и сооружений к периоду весенне-осенних паводков",
          "Положение о производственном контроле",
          "Геологическая документация",
          "Проект и локальные проекты (паспорта)",
        ],
        correctAnswers: ["Проект и локальные проекты (паспорта)"],
      },
      {
        code: "20401037",
        text: "На какие перечисленные виды работ не составляются планы и схемы горных работ?",
        answers: [
          "На геологические работы",
          "На вскрышные работы",
          "На маркшейдерские работы",
          "На работы, связанные со вторичной переработкой минерального сырья",
          "На работы по добыче полезных ископаемых",
          "На рекультивационные работы",
        ],
        correctAnswers: [
          "На работы, связанные со вторичной переработкой минерального сырья",
        ],
      },
      {
        code: "20401038",
        text: "Каким образом проводится одновременная разработка месторождения подземным и открытым способами?",
        answers: [
          "Должны быть проведены совместные мероприятия по обеспечению безопасности работников на объектах ведения подземных и открытых горных работ, утвержденные техническим руководителем организации",
          "Должны быть проведены согласованные с аварийно-спасательной службой (формированием) мероприятия по совмещенной отработке месторождения",
          "Должен быть составлен согласованный график работ открытым и подземным способами",
        ],
        correctAnswers: [
          "Должны быть проведены совместные мероприятия по обеспечению безопасности работников на объектах ведения подземных и открытых горных работ, утвержденные техническим руководителем организации",
        ],
      },
      {
        code: "20401039",
        text: "Разрешается ли одновременное производство работ в наклонных выработках на различных отметках?",
        answers: [
          "Разрешается, если предусмотрены меры, исключающие падение работников",
          "Разрешается при условии, что работающие в забое лица защищены от опасности обрыва скипа не менее чем двумя прочными заграждениями",
          "Разрешается при условии, что работающие в забое лица защищены от опасности падения сверху вагонеток и других предметов не менее чем двумя прочными заграждениями",
          "Разрешается по согласованию с надзорными органами",
          "Запрещается",
        ],
        correctAnswers: ["Запрещается"],
      },
      {
        code: "20401040",
        text: "Сколько сигнальных устройств должна иметь подъемная установка при проходке и углублении стволов?",
        answers: [
          "1 сигнальное устройство",
          "Не менее 3 независимых сигнальных устройств",
          "Количество сигнальных устройств определяется горно-геологическими условиями ведения работ",
          "Не менее 2 независимых сигнальных устройств",
        ],
        correctAnswers: ["Не менее 2 независимых сигнальных устройств"],
      },
      {
        code: "20401041",
        text: "Кто ежесуточно должен осматривать крепь и армировку вертикальных и наклонных стволов шахт, служащих для подъема, спуска людей и грузов?",
        answers: [
          "Мастер",
          "Технический руководитель",
          "Начальник участка",
          "Специально назначенные лица",
        ],
        correctAnswers: ["Специально назначенные лица"],
      },
      {
        code: "20401042",
        text: "В какой срок пользователи недр письменно уведомляются органом горного надзора о времени и месте рассмотрения планов и (или) схем развития горных работ?",
        answers: [
          "Не позднее чем за 10 дней до установленной даты",
          "Не позднее чем за 7 дней до установленной даты",
          "Не позднее чем за 5 дней до установленной даты",
          "Не позднее чем за 20 дней до установленной даты",
        ],
        correctAnswers: ["Не позднее чем за 20 дней до установленной даты"],
      },
      {
        code: "20401043",
        text: "На основе чего составляются планы и схемы развития горных работ?",
        answers: [
          "Только на основе утвержденной в установленном порядке проектной документации и требований, регламентирующих выполнение работ, связанных с пользованием недрами",
          "Только на основе условий лицензий на пользование недрами и требований, регламентирующих выполнение работ, связанных с пользованием недрами",
          "Только на основе соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами",
          "На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами",
        ],
        correctAnswers: [
          "На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами",
        ],
      },
      {
        code: "20401044",
        text: "Какие параметры эксплуатации объектов ведения горных работ планами и схемами развития горных работ не определяются?",
        answers: [
          "Направления развития горных работ",
          "Условия безопасного недропользования, технические и технологические решения при эксплуатации объектов ведения горных работ",
          "Объемы добычи и первичной переработки полезных ископаемых",
          "Штатная численность сотрудников объекта ведения горных работ",
        ],
        correctAnswers: [
          "Штатная численность сотрудников объекта ведения горных работ",
        ],
      },
      {
        code: "20401045",
        text: "В какой срок планы и (или) схемы развития горных работ направляются пользователем недр в орган государственного горного надзора для рассмотрения?",
        answers: [
          "За 5 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
          "За 7 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
          "За 20 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
          "За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
        ],
        correctAnswers: [
          "За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
        ],
      },
      {
        code: "20401046",
        text: "В какой период, установленный Правилами подготовки, рассмотрения и согласования планов и схем развития горных работ по видам полезных ископаемых, осуществляется рассмотрение органами Ростехнадзора планов и схем развития горных работ?",
        answers: [
          "В период с 1 октября по 31 декабря года, предшествующего планируемому",
          "В период с 1 сентября по 15 декабря года, предшествующего планируемому",
          "В период с 1 октября по 15 декабря года, предшествующего планируемому",
          "В период с 20 сентября по 25 декабря года, предшествующего планируемому",
        ],
        correctAnswers: [
          "В период с 20 сентября по 25 декабря года, предшествующего планируемому",
        ],
      },
      {
        code: "20401047",
        text: "Что из перечисленного не является основанием для принятия решения об отказе в согласовании плана и (или) схемы развития горных работ?",
        answers: [
          "Отсутствие геологического и маркшейдерского обеспечения горных работ",
          "Выявление недостоверных сведений в представленных документах",
          "Отсутствие обоснования соблюдения условий безопасного недропользования",
          "Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями",
        ],
        correctAnswers: [
          "Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями",
        ],
      },
      {
        code: "20401048",
        text: 'Какой порядок одновременного ведения очистных работ на смежных этажах предусмотрен "Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          "При одновременном ведении очистных работ на смежных этажах забои нижнего этажа должны опережать забои верхнего этажа на расстояние не более 2 м",
          "При одновременном ведении очистных работ на смежных этажах забои нижнего этажа должны опережать забои верхнего этажа на расстояние не более 3 м",
          "При одновременном ведении очистных работ на смежных этажах забои нижнего этажа должны опережать забои верхнего этажа на безопасное расстояние, определенное проектом",
          "При одновременном ведении очистных работ на смежных этажах забои верхнего этажа должны опережать забои нижнего этажа на безопасное расстояние, определенное проектом",
        ],
        correctAnswers: [
          "При одновременном ведении очистных работ на смежных этажах забои верхнего этажа должны опережать забои нижнего этажа на безопасное расстояние, определенное проектом",
        ],
      },
      {
        code: "20401049",
        text: "При какой глубине вертикальных стволов при наличии лестниц в обоих стволах механический подъем в одной из них может отсутствовать?",
        answers: [
          "При глубине более 500 м",
          "При глубине до 40 м",
          "При глубине до 100 м",
          "При глубине до 70 м",
        ],
        correctAnswers: ["При глубине до 70 м"],
      },
      {
        code: "20401050",
        text: "Каким способом производится ликвидация зависаний, образовавшихся сводов в отбитой руде?",
        answers: [
          "Под надзором мастера",
          "Под надзором лиц, имеющих право руководства горными работами",
          "Любыми методами",
          "Взрыванием зарядов с применением детонирующего шнура, подаваемых на шестах, или другими безопасными способами",
        ],
        correctAnswers: [
          "Взрыванием зарядов с применением детонирующего шнура, подаваемых на шестах, или другими безопасными способами",
        ],
      },
      {
        code: "20401051",
        text: "По какому документу осуществляется выемка целиков?",
        answers: [
          "По паспорту буровзрывных работ",
          "По паспорту крепления и управления кровлей",
          "По технологической карте",
          "По проекту",
        ],
        correctAnswers: ["По проекту"],
      },
      {
        code: "20401052",
        text: "Каким должно быть содержание углекислого газа в рудничном воздухе на рабочих местах?",
        answers: [
          "Не должно превышать  0,3 %",
          "Не должно превышать  0,4 %",
          "Не должно превышать  0,75 %",
          "Не должно превышать  0,5 %",
        ],
        correctAnswers: ["Не должно превышать  0,5 %"],
      },
      {
        code: "20401053",
        text: "Каким образом на каждой шахте должно обеспечиваться проветривание двух отдельных выходов, обеспечивающих выход людей на поверхность?",
        answers: [
          "За счет естественной тяги",
          "За счет общешахтной депрессии",
          "Разным направлением вентиляционных струй",
        ],
        correctAnswers: ["Разным направлением вентиляционных струй"],
      },
      {
        code: "20401054",
        text: "Организации, эксплуатирующие объекты, на которых ведутся горные работы и переработка полезных ископаемых, обязаны осуществлять:.",
        answers: [
          "Геологическое обеспечение работ",
          "Геолого-маркшейдерское обеспечение работ",
          "Маркшейдерское обеспечение безопасного ведения горных работ",
        ],
        correctAnswers: [
          "Маркшейдерское обеспечение безопасного ведения горных работ",
        ],
      },
      {
        code: "20401055",
        text: "Кому должен сообщать работник при обнаружении опасности, угрожающей людям, производственным объектам?",
        answers: [
          "Главному механику производственного объекта",
          "Руководителю производственного объекта",
          "Только людям, которым угрожает опасность",
          "Техническому руководителю смены",
        ],
        correctAnswers: ["Техническому руководителю смены"],
      },
      {
        code: "20401056",
        text: "Сколько самостоятельных вентиляторных агрегатов должно входить в главную вентиляторную установку?",
        answers: [
          "1 самостоятельный вентиляторный агрегат, обеспечивающий проектную мощность",
          "2 самостоятельных вентиляторных агрегата, причем 1 из них должен быть резервный",
          "2 самостоятельных вентиляторных агрегата и дополнительно должен быть установлен резервный",
          "2 самостоятельных вентиляторных агрегата и дополнительно должны быть установлены 2 резервных",
        ],
        correctAnswers: [
          "2 самостоятельных вентиляторных агрегата, причем 1 из них должен быть резервный",
        ],
      },
      {
        code: "20401057",
        text: "Какая длина должна быть у замерных станций, оборудованных на местах замера количества воздуха?",
        answers: [
          "Не менее 1 м",
          "Не менее 2 м",
          "Не менее 3 м",
          "Не менее 4 м",
        ],
        correctAnswers: ["Не менее 4 м"],
      },
      {
        code: "20401058",
        text: "На содержание каких веществ должны анализироваться пробы воздуха, набираемые в непроветриваемой части затопленных выработок (при откачке)?",
        answers: [
          "Только CO, СО2",
          "Только СН4, H2S",
          "Только О2, СО2",
          "CO, CO2, CH4, H2S, O2 и H2",
        ],
        correctAnswers: ["CO, CO2, CH4, H2S, O2 и H2"],
      },
      {
        code: "20401059",
        text: "При каком расстоянии до места работ перевозка людей обязательна?",
        answers: [
          "При расстоянии более 0,5 км",
          "При расстоянии более 1 км",
          "При расстоянии более 1,5 км",
          "При расстоянии более 2 км",
        ],
        correctAnswers: ["При расстоянии более 1 км"],
      },
      {
        code: "20401060",
        text: "Какая разрешается скорость перевозки людей в подземных выработках?",
        answers: [
          "Должна ограничиваться качеством дорожного полотна",
          "Не должна превышать 10 км/ч",
          "Не должна превышать 20 км/ч",
          "Не должна превышать 30 км/ч",
        ],
        correctAnswers: ["Не должна превышать 20 км/ч"],
      },
      {
        code: "20401061",
        text: "Кто утверждает схемы откаточных путей, маршруты движения самоходной техники?",
        answers: [
          "Руководитель шахты",
          "Главный технолог",
          "Главный механик шахты",
          "Технический руководитель шахты",
        ],
        correctAnswers: ["Технический руководитель шахты"],
      },
      {
        code: "20401062",
        text: "Какие знаки применяются при движении самоходных машин по горным выработкам?",
        answers: [
          "Только запрещающие знаки в местах возможного подхода людей к выработкам",
          "Знаки, утвержденные руководителем шахты",
          "Знаки, предусмотренные проектом",
          "Типовые дорожные знаки, регламентирующие движение",
        ],
        correctAnswers: ["Типовые дорожные знаки, регламентирующие движение"],
      },
      {
        code: "20401063",
        text: "Какими средствами пожаротушения должны быть оборудованы магистральные и участковые конвейерные линии, оснащенные трудновоспламеняемыми лентами?",
        answers: [
          "Системами автоматического пожаротушения на приводных станциях",
          "Автоматической сигнализацией по всей длине конвейера с выводом к диспетчеру шахты",
          "Cредствами автоматического пожаротушения и сигнализации на приводных станциях с выводом информации диспетчеру",
          "Системами автоматического пожаротушения и автоматической пожарной сигнализации по всей длине конвейера с выводом к диспетчеру шахты",
        ],
        correctAnswers: [
          "Системами автоматического пожаротушения и автоматической пожарной сигнализации по всей длине конвейера с выводом к диспетчеру шахты",
        ],
      },
      {
        code: "20401064",
        text: "Разрешаются ли спуск и подъем людей одновременно с грузом?",
        answers: [
          "Разрешаются при двухклетевом подъеме",
          "Запрещаются при одноклетевом подъеме",
          "Не регламентируется",
          "Запрещаются как в одной клети при одноклетевом подъеме, так и в разных клетях при двухклетевом подъеме",
        ],
        correctAnswers: [
          "Запрещаются как в одной клети при одноклетевом подъеме, так и в разных клетях при двухклетевом подъеме",
        ],
      },
      {
        code: "20401065",
        text: "С какой периодичностью механик участка должен проводить осмотр канатов вспомогательных лебедок в наклонных выработках?",
        answers: ["1 раз в полгода", "Ежесменно", "Еженедельно", "Ежесуточно"],
        correctAnswers: ["Еженедельно"],
      },
      {
        code: "20401066",
        text: "Какой должна быть скорость движения вагона при работе самоходного вагона в комплексе с передвижными или стационарными бункер-перегружателями в момент подъезда к местам перегрузки?",
        answers: [
          " Не должна превышать 10 км/ч",
          " Не должна превышать 4 км/ч",
          " Не должна превышать 3 км/ч",
          " Не должна превышать 5 км/ч",
        ],
        correctAnswers: [" Не должна превышать 5 км/ч"],
      },
      {
        code: "20401067",
        text: "Какую температуру должен иметь воздух, поступающий в подземные горные выработки?",
        answers: [
          "Не менее 0 °C",
          "Не менее 1 °C",
          "Не менее 3 °C",
          "Не менее 2 °C",
        ],
        correctAnswers: ["Не менее 2 °C"],
      },
      {
        code: "20401068",
        text: "В течение какого срока должны пополняться материалы, израсходованные со складов на ликвидацию аварий?",
        answers: [
          "В течение месяца",
          "В течение 10 дней",
          "В течение недели",
          "В течение суток",
        ],
        correctAnswers: ["В течение суток"],
      },
      {
        code: "20401069",
        text: "Кем должны быть подписаны планы (схемы) развития горных работ, направляемые пользователем недр в электронном виде?",
        answers: [
          "Все ответы неверны",
          "Комиссией пользователя недр (подрядной организации)",
          "Техническим руководителем пользователя недр (подрядной организации)",
          "Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом",
        ],
        correctAnswers: [
          "Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом",
        ],
      },
      {
        code: "20401070",
        text: "Какие данные не должны указываться в штампе графических материалов планов развития горных работ?",
        answers: [
          "Наименования организации, объекта недропользования (участка недр)",
          "Название графического документа",
          "Дата составления",
          "Масштаб",
          "Подписи лиц (с указанием должности), ответственных за руководство геологическими и маркшейдерскими работами",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20401071",
        text: "Чем должны быть оборудованы рабочие площадки приемных и разгрузочных устройств и бункеров при применении железнодорожного транспорта?",
        answers: [
          "Звуковой и световой сигнализацией, предназначенной для оповещения обслуживающего персонала о прибытии железнодорожных составов",
          "Только ограждениями",
          "Ограждениями и шлагбаумами",
        ],
        correctAnswers: [
          "Звуковой и световой сигнализацией, предназначенной для оповещения обслуживающего персонала о прибытии железнодорожных составов",
        ],
      },
      {
        code: "20401072",
        text: "Какой должна быть высота ограждения загрузочного отверстия приемного бункера для ограничения движения задним ходом автомобилей?",
        answers: [
          "Не менее 0,5 диаметра колеса автомобиля",
          "Не менее 1,1 м",
          "Не менее 1,0 м",
          "Не менее 0,7 диаметра колеса автомобиля",
        ],
        correctAnswers: ["Не менее 0,5 диаметра колеса автомобиля"],
      },
      {
        code: "20401073",
        text: "Как необходимо подготовить бункеры и места перегрузки конвейерного транспорта для использования саморазгружающихся тележек или реверсивных конвейеров?",
        answers: [
          "Необходимо оборудовать настилы",
          "Загрузочные отверстия необходимо перекрыть решетками с отверстиями шириной не более 100 х 100 мм",
          "Загрузочные отверстия необходимо оснастить ограждениями высотой не менее 1,5 м",
          "Загрузочные отверстия необходимо перекрыть решетками с отверстиями шириной не более 0,2 х 0,2 м",
        ],
        correctAnswers: [
          "Загрузочные отверстия необходимо перекрыть решетками с отверстиями шириной не более 0,2 х 0,2 м",
        ],
      },
      {
        code: "20401074",
        text: "Какие документы требуются для проведения осмотра или ремонтных и очистных работ в приемных воронках питателей и в бункерах?",
        answers: [
          "Только наряд-допуск",
          "Письменное распоряжение руководителя работ",
          "Не регламентируется",
          "Наряд-допуск и проект производства работ",
        ],
        correctAnswers: ["Наряд-допуск и проект производства работ"],
      },
      {
        code: "20401075",
        text: "Какое минимальное количество рабочих должно быть в бригаде при работах в  бункере?",
        answers: [
          "Двое, один из которых должен находиться в надбункерной части",
          "Трое, двое из которых должны находиться в надбункерной части",
          "Трое, один из которых должен находиться в надбункерной части",
          "Пятеро, трое из которых должны находиться в надбункерной части",
        ],
        correctAnswers: [
          "Трое, двое из которых должны находиться в надбункерной части",
        ],
      },
      {
        code: "20401076",
        text: "Какое напряжение должно быть у переносных ламп, применяемых внутри бункера для освещения во время проведения ремонтных работ?",
        answers: [
          "Не выше 12 В",
          "Не выше 24 В",
          "Не выше 36 В",
          "Не выше 42 В",
        ],
        correctAnswers: ["Не выше 12 В"],
      },
      {
        code: "20401077",
        text: 'Каким документом следует руководствоваться при ликвидации зависаний горной массы над рабочим пространством дробилок, а также запуска аварийно остановленной дробилки под "завалом"?',
        answers: [
          "Технологическим регламентом, утвержденным техническим руководителем объекта, определяющим методы, последовательность операций и приемы безопасного выполнения работ по ликвидации зависания и запуску в работу дробилки",
          "Нарядом-допуском",
          "Приказом руководителя (технического руководителя) предприятия",
        ],
        correctAnswers: [
          "Технологическим регламентом, утвержденным техническим руководителем объекта, определяющим методы, последовательность операций и приемы безопасного выполнения работ по ликвидации зависания и запуску в работу дробилки",
        ],
      },
      {
        code: "20401078",
        text: "Какие защитные приспособления   должны быть предусмотрены  на грохотах и дробилках?",
        answers: [
          'Для конусных дробилок - глухие съемные ограждения, кроме дробилок крупного дробления 1 стадии, работающих "под завалом"; для щековых дробилок - глухие съемные ограждения со смотровыми окнами, исключающие возможность выброса кусков руды из зева дробилки',
          'Для конусных дробилок - решетчатые несъемные ограждения, кроме дробилок крупного дробления 1 стадии, работающих "под завалом"; для щековых дробилок - глухие съемные ограждения без смотровых окон',
          "Для конусных дробилок и грохотов - глухие съемные ограждения со смотровыми окнами; для щековых дробилок - глухие несъемные ограждения без смотровых окон",
        ],
        correctAnswers: [
          'Для конусных дробилок - глухие съемные ограждения, кроме дробилок крупного дробления 1 стадии, работающих "под завалом"; для щековых дробилок - глухие съемные ограждения со смотровыми окнами, исключающие возможность выброса кусков руды из зева дробилки',
        ],
      },
      {
        code: "20401079",
        text: "Какие требования безопасности устанавливаются Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых к ограждениям вагоноопрокидывателей, расположенных на рабочих площадках приемных устройств?",
        answers: [
          "Высота ограждений должна быть не менее 3 м, ширина ячейки решеток - не более 15 мм, зазоры между площадками и торцами ротора вагоноопрокидывателя - не более 60 мм",
          "Высота ограждений должна быть не менее 2 м, ширина ячейки решеток - не более 10 мм, зазоры между площадками и торцами ротора вагоноопрокидывателя - не более 60 мм",
          "Высота ограждений должна быть не менее 1,5 м, размеры ячейки решеток - не более 15 х 15 мм, зазоры между площадками и торцами ротора вагоноопрокидывателя - не более 85 мм",
          "Высота ограждений должна быть не более 1 м, ширина ячейки решеток - не более 25 мм, зазоры между площадками и торцами ротора вагоноопрокидывателя - не более 60 мм",
        ],
        correctAnswers: [
          "Высота ограждений должна быть не менее 2 м, ширина ячейки решеток - не более 10 мм, зазоры между площадками и торцами ротора вагоноопрокидывателя - не более 60 мм",
        ],
      },
      {
        code: "20401080",
        text: "На каком расстоянии от места ведения работ по кучному выщелачиванию должны быть расположены жилые помещения и пункты питания?",
        answers: [
          "Не менее 1 км",
          "Не менее 1,5 км",
          "Не менее 500 м",
          "Не менее 300 м",
        ],
        correctAnswers: ["Не менее 500 м"],
      },
      {
        code: "20401081",
        text: "Какие надписи должны иметь трубопроводы, емкости и оборудование с цианистыми растворами и кислотами?",
        answers: ['"ОСТОРОЖНО!"', '"ЯД"', '"ОПАСНО"', '"ВНИМАНИЕ"'],
        correctAnswers: ['"ЯД"'],
      },
      {
        code: "20401082",
        text: "Какие требования безопасности предъявляются к потенциально опасным местам на производственной площадке при превышении ПДК цианидов и кислот в воздухе рабочей зоны?",
        answers: [
          "Должны ограждаться металлической сеткой",
          "Должны орошаться",
          "Должны быть оборудованы автоматическими сигнализаторами, подающими звуковые и световые сигналы",
          "Необходимо регулярно отбирать пробы воздуха на наличие цианидов в воздухе",
        ],
        correctAnswers: [
          "Должны быть оборудованы автоматическими сигнализаторами, подающими звуковые и световые сигналы",
        ],
      },
      {
        code: "20401083",
        text: "Сколько рабочих должно находиться на поверхности кучи при проведении всех видов работ по кучному выщелачиванию и гидрометаллургических процессов?",
        answers: [
          "Один",
          "Минимум двое",
          "Минимум трое",
          "Количество рабочих не имеет значения",
        ],
        correctAnswers: ["Минимум двое"],
      },
      {
        code: "20401084",
        text: "Кем утверждается схема электроснабжения объектов в организации?",
        answers: [
          "Техническим руководителем организации",
          "Руководителем организации",
          "Главным энергетиком",
          "Инспектором Ростехнадзора",
        ],
        correctAnswers: ["Техническим руководителем организации"],
      },
      {
        code: "20401085",
        text: "Какие средства защиты необходимо применять при обслуживании электроустановок?",
        answers: [
          "Только электрозащитные средства",
          "Любые доступные средства защиты",
          "Электрозащитные средства и средства индивидуальной защиты",
          "Только средства индивидуальной защиты",
        ],
        correctAnswers: [
          "Электрозащитные средства и средства индивидуальной защиты",
        ],
      },
      {
        code: "20401086",
        text: "Какие требования предъявляются к лицам, допускаемым к обслуживанию и ремонту электроустановок?",
        answers: [
          "Прохождение обучения по безопасным методам работы и проверка знаний в квалификационной комиссии с присвоением соответствующей группы допуска по электробезопасности",
          "Только прохождение обучения по промышленной безопасности",
          "Только прохождение обучения по охране труда",
        ],
        correctAnswers: [
          "Прохождение обучения по безопасным методам работы и проверка знаний в квалификационной комиссии с присвоением соответствующей группы допуска по электробезопасности",
        ],
      },
      {
        code: "20401087",
        text: "С какой периодичностью главными специалистами, ответственными за безопасную эксплуатацию электроустановок, должны осматриваться все электрические машины, аппараты и трансформаторы?",
        answers: [
          "Ежесменно",
          "Еженедельно",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 3 месяца",
        ],
        correctAnswers: ["Не реже 1 раза в 3 месяца"],
      },
      {
        code: "20401088",
        text: "С какой периодичностью персонал, работающий на электроустановках, обязан производить наружный осмотр защитных заземлений?",
        answers: [
          "Ежесменно",
          "Еженедельно",
          "Ежедневно",
          "Не реже 1 раза в месяц",
        ],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20401089",
        text: "С какой периодичностью должно производиться измерение сопротивления изоляции?",
        answers: [
          "Ежесменно",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
        ],
        correctAnswers: ["Не реже 1 раза в 3 месяца"],
      },
      {
        code: "20401090",
        text: "Какое напряжение должно использоваться при установке светильников с лампами накаливания над полом ниже 2,5 м в помещениях с повышенной опасностью в случае невозможного использования светильников специальной конструкции?",
        answers: [
          "Не выше 50 В (с заземлением металлической арматуры)",
          "Не выше 110 В (с заземлением металлической арматуры)",
          "Не выше 220 В (с заземлением металлической арматуры)",
        ],
        correctAnswers: ["Не выше 50 В (с заземлением металлической арматуры)"],
      },
      {
        code: "20401091",
        text: "В каком из перечисленных случаев нарушено требование электробезопасности?",
        answers: [
          "При особо неблагоприятных условиях, когда опасность поражения электрическим током усугубляется теснотой, для питания ручных и переносных светильников должно применяться напряжение не выше 50 В",
          "Напряжение переносного электроинструмента должно быть не выше 220 В в помещениях без повышенной опасности и не выше 50 В в помещениях с повышенной опасностью и вне помещений",
          "Штепсельные соединения (розетки, вилки), применяемые на напряжение 12 и 50 В, по своему конструктивному исполнению должны отличаться от обычных штепсельных соединений, предназначенных для напряжений 127 и 220 В, и исключать возможность включения вилок на 12 и 50 В в штепсельные розетки на 127 и 220 В",
        ],
        correctAnswers: [
          "При особо неблагоприятных условиях, когда опасность поражения электрическим током усугубляется теснотой, для питания ручных и переносных светильников должно применяться напряжение не выше 50 В",
        ],
      },
      {
        code: "20401092",
        text: "Какое напряжение должно быть для питания аппаратуры сигнализации при наличии защиты от токов утечки?",
        answers: [
          "Не выше 42 В",
          "Не выше 127 В",
          "Не выше 220 В",
          "Не выше 380 В",
        ],
        correctAnswers: ["Не выше 220 В"],
      },
      {
        code: "20401093",
        text: "Когда проверяется исправность действия (срабатывания) реле утечки тока в передвижных электроустановках до 1000 В?",
        answers: [
          "Периодически, не реже 1 раза в неделю",
          "Периодически, не реже 1 раза в месяц",
          "Периодически, не реже 1 раза в 10 дней",
          "Перед началом работы в каждой смене",
        ],
        correctAnswers: ["Перед началом работы в каждой смене"],
      },
      {
        code: "20401094",
        text: "Где устанавливаются главные заземлители на шахте?",
        answers: [
          "У каждого стационарного или передвижного распределительного пункта",
          "В распределительной или трансформаторной подстанции",
          "В штрековых водоотводных канавах или в других местах, пригодных для этой цели",
          "Устройство главных заземлителей на шахте определяется проектом",
          "У каждого индивидуально установленного выключателя или распределительного устройства",
        ],
        correctAnswers: [
          "Устройство главных заземлителей на шахте определяется проектом",
        ],
      },
      {
        code: "20401095",
        text: "Какое количество главных заземлителей должно быть на шахте?",
        answers: [
          "Только 1 главный заземлитель",
          "Количество главных заземлителей определяется техническим руководителем шахты",
          "Не менее 3 главных заземлителей (2 основных и 1 резервный)",
          "Не менее 2 главных заземлителей (основного и резервного)",
        ],
        correctAnswers: [
          "Не менее 2 главных заземлителей (основного и резервного)",
        ],
      },
      {
        code: "20401096",
        text: "С какой периодичностью должен производиться осмотр всех заземляющих устройств на шахте?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в 3 месяца",
        ],
        correctAnswers: ["Не реже 1 раза в 3 месяца"],
      },
      {
        code: "20401097",
        text: "Каким должно быть расстояние от нижнего фазного провода линии до верхней точки автомобиля или груза при пересечении временных линий электропередачи с автомобильной дорогой при напряжении до 20 кВ?",
        answers: [
          "Не менее 0,5 м",
          "Не менее 1 м",
          "Не менее 1,5 м",
          "Не менее 2 м",
          "Не менее 2,5 м",
        ],
        correctAnswers: ["Не менее 2 м"],
      },
      {
        code: "20401098",
        text: 'Какое требование установлено к коробкам выводов электрических машин и пускорегулирующей аппаратуры "Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          "Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть закрыты специальной заглушкой",
          "Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть оборудованы звуковой и световой сигнализацией",
          "Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть защищены ограждениями",
          "Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть уплотнены и закрыты крышкой",
        ],
        correctAnswers: [
          "Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть уплотнены и закрыты крышкой",
        ],
      },
      {
        code: "20401099",
        text: "Что служит защитой электроприводов технологического оборудования при исчезновении или резком снижении напряжения питающей сети?",
        answers: [
          "Защита от колебаний нагрузки",
          "Защита от перегрузки",
          "Блокировка, исключающая самозапуск",
        ],
        correctAnswers: ["Блокировка, исключающая самозапуск"],
      },
      {
        code: "20401100",
        text: "На каких электроустановках запрещается оперативное обслуживание без применения специальных защитных средств?",
        answers: [
          "Напряжением выше 220 В",
          "Напряжением выше 380 В",
          "Напряжением выше 660 В",
          "Напряжением выше 1000 В",
        ],
        correctAnswers: ["Напряжением выше 1000 В"],
      },
      {
        code: "20401101",
        text: "Какая защита должна осуществляться при напряжении до 1140 В?",
        answers: [
          "Только защита трансформаторов и каждого отходящего от них присоединения от токов короткого замыкания - автоматическими выключателями с максимальной токовой защитой",
          "Только защита электродвигателей и питающих их кабелей от токов короткого замыкания - мгновенная или селективная",
          "Только защита электродвигателей и питающих их кабелей от включения напряжения при сниженном сопротивлении изоляции относительно земли",
          "Только защита электрической сети - от опасных утечек тока на землю автоматическими выключателями или одним отключающим аппаратом в комплексе с одним аппаратом защиты от утечек тока на всю электрически связанную сеть, подключенную к одному или группе параллельно работающих трансформаторов",
          "Все указанные виды защиты",
        ],
        correctAnswers: ["Все указанные виды защиты"],
      },
      {
        code: "20401102",
        text: "Сколько выходов должно быть в камерах подстанций длиной более 10 м?",
        answers: [
          "Не регламентируется",
          "1 выход",
          "2 выхода независимо от расположения",
          "2 выхода, расположенные в наиболее удаленных друг от друга частях камеры",
        ],
        correctAnswers: [
          "2 выхода, расположенные в наиболее удаленных друг от друга частях камеры",
        ],
      },
      {
        code: "20401103",
        text: "Какие блокировки должна иметь защитная аппаратура?",
        answers: [
          "От перебоев напряжения",
          "Против срабатывания защиты отключения магнитных пускателей",
          "Против подачи напряжения на линии и электроустановки с пониженным сопротивлением изоляции относительно земли и после срабатывания защиты максимального тока",
        ],
        correctAnswers: [
          "Против подачи напряжения на линии и электроустановки с пониженным сопротивлением изоляции относительно земли и после срабатывания защиты максимального тока",
        ],
      },
      {
        code: "20401104",
        text: "Какое напряжение должно применяться для питания передвижных приемников электроэнергии?",
        answers: [
          "Не выше 10 000 В",
          "Не выше 3300 В",
          "Не выше 220 В",
          "Не выше 50 В",
        ],
        correctAnswers: ["Не выше 3300 В"],
      },
      {
        code: "20401105",
        text: "На какой высоте должны быть расположены токоведущие части электроаппаратуры центральных насосных камер и камер центральных подземных подстанций от головки рельсов околоствольного двора?",
        answers: [
          "Не менее 1,5 м",
          "Не более 0,2 м",
          "Не более 0,5 м",
          "Не менее 1 м",
        ],
        correctAnswers: ["Не менее 1 м"],
      },
      {
        code: "20401106",
        text: "Кем устанавливаются размеры призмы обрушения (сползания) породы при отвалообразовании, в пределах которой не должны разгружаться автомобили и другие транспортные средства?",
        answers: [
          "Начальником участка (прорабом)",
          "Лицами сменного надзора",
          "Техническим руководителем",
          "Работниками маркшейдерской службы",
        ],
        correctAnswers: ["Работниками маркшейдерской службы"],
      },
      {
        code: "20401107",
        text: "Кто имеет право на проезд в многоместных кабинах автомобилей?",
        answers: [
          "Любой работник карьера",
          "Только представители надзорных органов",
          "Только лица, сопровождающие составы",
          "Лица, сопровождающие составы, а также лица сменного технического надзора и отдельные работники при наличии у них письменного разрешения технического руководителя",
        ],
        correctAnswers: [
          "Лица, сопровождающие составы, а также лица сменного технического надзора и отдельные работники при наличии у них письменного разрешения технического руководителя",
        ],
      },
      {
        code: "20401108",
        text: "По какому документу разрешается перегон горных, транспортных и дорожных машин?",
        answers: [
          "По проекту производства работ",
          "По наряду-допуску на производство работ",
          "По технологическим картам, утвержденным техническим руководителем организации",
        ],
        correctAnswers: [
          "По технологическим картам, утвержденным техническим руководителем организации",
        ],
      },
      {
        code: "20401109",
        text: "За какое время до момента прибытия железнодорожных составов подаются звуковые и световые сигналы?",
        answers: [
          "За 1 минуту",
          "За 1,5 - 2 минуты",
          "За 3 минуты",
          "За 45 секунд",
        ],
        correctAnswers: ["За 1,5 - 2 минуты"],
      },
      {
        code: "20401110",
        text: "Какие требования безопасности должны выполняться при разгрузке вагонов?",
        answers: [
          "Только запрещается разгрузка вагонов при неснятом напряжении в контактной сети, находящейся в надбункерном помещении",
          "Только запрещается нахождение людей на разгрузочной стороне приемного бункера в момент подачи и разгрузки",
          "Только запрещается разгружать неисправные вагоны, а также ремонтировать вагоны на разгрузочной площадке приемных устройств",
          "Необходимо соблюдать все перечисленные требования",
        ],
        correctAnswers: ["Необходимо соблюдать все перечисленные требования"],
      },
      {
        code: "20401111",
        text: "Кем осуществляется руководство подачей и передвижением железнодорожных составов в процессе погрузки (разгрузки)?",
        answers: [
          "Машинистом состава",
          "Начальником карьера",
          "Поездным диспетчером",
          "Машинистом экскаватора или оператором погрузочного устройства",
        ],
        correctAnswers: [
          "Машинистом экскаватора или оператором погрузочного устройства",
        ],
      },
      {
        code: "20401112",
        text: "Какие требования безопасности необходимо применять в местах прохода и проезда людей под ленточными конвейерами?",
        answers: [
          "Необходимо установить плакаты, предупреждающие о возможном поражении падающими с ленты кусками транспортируемого материала",
          "Необходимо установить защитные полки, предохраняющие от возможного поражения падающими с ленты кусками транспортируемого материала, а со стороны основного прохода для людей по всей длине конвейера ролики рабочей и холостой ветви ленты должны иметь ограждения, не блокируемые с приводом конвейера",
          "Необходимо только установить защитное ограждение, исключающее возможность прохода или проезда под ленточными конвейерами",
        ],
        correctAnswers: [
          "Необходимо установить защитные полки, предохраняющие от возможного поражения падающими с ленты кусками транспортируемого материала, а со стороны основного прохода для людей по всей длине конвейера ролики рабочей и холостой ветви ленты должны иметь ограждения, не блокируемые с приводом конвейера",
        ],
      },
      {
        code: "20401113",
        text: "Каким минимальным условиям обеспечения проходов для людей должна соответствовать ширина галерей и эстакад при ширине ленты свыше 1400 мм?",
        answers: [
          "500 мм с одной стороны конвейера и 700 мм с другой",
          "800 мм с обеих сторон конвейера",
          "1000 мм с обеих сторон конвейера",
          "700 мм с одной стороны и 750 мм с другой стороны конвейера",
        ],
        correctAnswers: ["800 мм с обеих сторон конвейера"],
      },
      {
        code: "20401114",
        text: "Какой должна быть минимальная ширина свободных проходов между пластинчатыми конвейерами?",
        answers: [
          "Не менее 0,75 м",
          "Не менее 1,1 м",
          "Не менее 1,2 м",
          "Не менее 1,5 м",
          "Не менее 1,8 м",
        ],
        correctAnswers: ["Не менее 1,2 м"],
      },
      {
        code: "20401115",
        text: "Какой должна быть максимальная скорость движения конвейерной ленты при ручной рудоразборке?",
        answers: [
          "Не более 0,3 м/с",
          "Не более 0,4 м/с",
          "Не более 0,5 м/с",
          "Не более 0,6 м/с",
          "Не более 0,7 м/с",
        ],
        correctAnswers: ["Не более 0,5 м/с"],
      },
      {
        code: "20401116",
        text: "Какие защитные приспособления необходимо предусматривать на элеваторах, транспортирующих мокрые продукты, во избежание разбрызгивания пульпы?",
        answers: [
          "Они должны иметь защитные ограждения только у мест загрузки и разгрузки элеваторов",
          "Они должны быть закрыты по всей длине предохранительными щитами или кожухами",
          "Они должны иметь плотное укрытие",
          "Никаких дополнительных приспособлений не предусматривается, рабочие должны быть одеты в специальные защитные костюмы",
        ],
        correctAnswers: [
          "Они должны быть закрыты по всей длине предохранительными щитами или кожухами",
        ],
      },
      {
        code: "20401117",
        text: "Какими устройствами безопасности должны быть оборудованы цепные элеваторы?",
        answers: [
          "Ограничителями хода",
          "Тормозными устройствами, исключающими обратный ход кольцевой цепи, и ловителями при ее разрыве",
          "Предохранительными устройствами",
          "Специальными устройствами блокировки и аварийных остановок",
        ],
        correctAnswers: [
          "Тормозными устройствами, исключающими обратный ход кольцевой цепи, и ловителями при ее разрыве",
        ],
      },
      {
        code: "20401118",
        text: "В каком из перечисленных случаев пересматриваются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите 2 варианта ответа.",
        answers: [
          "Не позднее 15 календарных дней после изменения сведений, содержащихся в общих или специальных разделах плана мероприятий",
          "Не позднее 40 календарных дней после внесения изменений в системы управления технологическими процессами на объекте",
          "Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий",
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
          "В случае назначения нового руководителя организации",
        ],
        correctAnswers: [
          "Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий",
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
        ],
      },
      {
        code: "20401119",
        text: "На какие сроки разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах для объектов, на которых ведутся открытые горные работы?",
        answers: ["На 6 месяцев", "На 1 год", "На 3 года", "На 5 лет"],
        correctAnswers: ["На 1 год"],
      },
      {
        code: "20401120",
        text: "В какой срок пересматривается план мероприятий по локализации и ликвидации последствий аварий до истечения срока действия предыдущего плана мероприятий?",
        answers: [
          "Не менее чем за 7 календарных дней",
          "Не менее чем за 10 календарных дней",
          "Не менее чем за 15 календарных дней",
          "Не менее чем за 30 календарных дней",
        ],
        correctAnswers: ["Не менее чем за 15 календарных дней"],
      },
      {
        code: "20401121",
        text: "В течение какого времени пересматривается план мероприятий по локализации и ликвидации последствий аварий после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства?",
        answers: [
          "Не позднее 10 дней календарных дней",
          "Не позднее 15 дней календарных дней",
          "Не позднее 30 календарных дней",
          "Не позднее 3 месяцев",
        ],
        correctAnswers: ["Не позднее 30 календарных дней"],
      },
      {
        code: "20401122",
        text: "Кто утверждает планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.",
        answers: [
          "Руководитель (заместители руководителей) организаций, эксплуатирующих объекты",
          "Руководители обособленных подразделений юридических лиц",
          "Руководители профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований",
          "Инспектор Ростехнадзора",
        ],
        correctAnswers: [
          "Руководитель (заместители руководителей) организаций, эксплуатирующих объекты",
          "Руководители обособленных подразделений юридических лиц",
        ],
      },
      {
        code: "20401123",
        text: "В каком случае эксплуатирующая организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах на несколько опасных объектов?",
        answers: [
          "В случае если 2 и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
          "В случае если это регламентировано внутренней документацией организации",
          "В случае если объекты зарегистрированы в государственном реестре опасных производственных объектов",
          "План мероприятий разрабатывается на каждый опасный объект отдельно",
        ],
        correctAnswers: [
          "В случае если 2 и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
        ],
      },
      {
        code: "20401124",
        text: "Какую информацию не включает в себя общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
          "Характеристику объектов, в отношении которых разрабатывается план мероприятий",
          "Cценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий, а также источники (места) их возникновения",
          "Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах",
        ],
        correctAnswers: [
          "Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
        ],
      },
      {
        code: "20401125",
        text: "Что из перечисленного должно быть предусмотрено планом мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Только организация материально-технического, инженерного и финансового обеспечения операций по локализации и ликвидации аварий на объекте",
          "Только порядок обеспечения постоянной готовности сил и средств к локализации и ликвидации последствий аварий на объекте с указанием организаций, которые несут ответственность за поддержание этих сил и средств в надлежащей степени готовности",
          "Только первоочередные действия при получении сигнала об авариях на объекте",
          "Только система взаимного обмена информацией между организациями - участниками локализации и ликвидации последствий аварий на объекте",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20401126",
        text: "Из каких разделов состоит план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Из частных разделов",
          "Из специализированных разделов",
          "Из разделов, предусмотренных требованиями федеральных норм и правил в области промышленной безопасности",
          "Из общих и специальных разделов",
        ],
        correctAnswers: ["Из общих и специальных разделов"],
      },
      {
        code: "20401127",
        text: "В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "В целях регламентации действий персонала при возникновении аварии",
          "В целях регламентации действий подразделений муниципальной пожарной охраны при возникновении аварии",
          "В целях обеспечения соответствия объекта требованиям промышленной безопасности",
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
        ],
        correctAnswers: [
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
        ],
      },
      {
        code: "20401128",
        text: "Каким образом необходимо разработать план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах в случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?",
        answers: [
          "Разрабатывается единый план мероприятий только для объектов, находящихся на расстоянии менее 100 м друг от друга",
          "Разрабатывается один план мероприятий для объектов, расположенных на одном участке, и несколько для объектов на смежных участках",
          "Разрабатываются планы мероприятий для каждого объекта отдельно",
          "Разрабатывается единый план мероприятий для 2 и более объектов",
        ],
        correctAnswers: [
          "Разрабатывается единый план мероприятий для 2 и более объектов",
        ],
      },
      {
        code: "20401129",
        text: "Кем утверждаются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Специалистами поднадзорных организаций совместно со специалистами надзорных органов",
          "Руководителем отдела промышленной безопасности",
          "Командиром профессиональной аварийно-спасательной службы (формирования)",
          "Руководителями структурных подразделений объекта",
          "Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
        ],
        correctAnswers: [
          "Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
        ],
      },
      {
        code: "20401130",
        text: "С кем согласовывается план мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "С Ростехнадзором",
          "С владельцами железнодорожного пути",
          "С Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий и Ростехнадзором",
          "С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
        ],
        correctAnswers: [
          "С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
        ],
      },
      {
        code: "20401131",
        text: "На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?",
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ (включая земляные работы) на опасных производственных объектах, на которых получаются, используются, перерабатываются, образуются, хранятся, транспортируются, уничтожаются опасные вещества, указанные в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          "На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на выделенной территории находящихся в эксплуатации опасных производственных объектов",
          "На ведение газоопасных, огневых и ремонтных работ на объектах тепло- и электроэнергетики",
          "На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности",
          "На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы",
          "На ведение газоопасных, огневых и ремонтных работ на объектах стационарных автомобильных газозаправочных станций, автомобильных газонаполнительных компрессорных станциях и криогенных автозаправочных станциях газомоторного топлива",
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ (включая земляные работы) на опасных производственных объектах, на которых получаются, используются, перерабатываются, образуются, хранятся, транспортируются, уничтожаются опасные вещества, указанные в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "20401132",
        text: "Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года",
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
          "Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ",
          "По приказу технического руководителя организации",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
      {
        code: "20401133",
        text: "Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?",
        answers: [
          "Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск на требуемый для окончания работ срок",
          "Руководитель структурного подразделения не более чем на 1 рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск на требуемый не более чем на 1 дневную смену срок",
        ],
        correctAnswers: [
          "Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену",
        ],
      },
      {
        code: "20401134",
        text: "В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?",
        answers: [
          "Не менее 1 месяца со дня его закрытия",
          "Не менее 3 месяцев со дня его закрытия",
          "Не менее 6 месяцев со дня его закрытия",
          "Не менее 1 года со дня его закрытия",
        ],
        correctAnswers: ["Не менее 6 месяцев со дня его закрытия"],
      },
      {
        code: "20401135",
        text: "В течение какого времени должно находиться под наблюдением место сварки и резки после окончания сварочных и газопламенных работ?",
        answers: [
          "Не менее часа",
          "Не менее 2 часов",
          "Не менее 3 часов",
          "Не менее 4 часов",
        ],
        correctAnswers: ["Не менее 2 часов"],
      },
    ],
    20402: [
      {
        code: "20402000",
        text: "Для кого предназначены Правила безопасности при строительстве подземных сооружений?",
        answers: [
          "Для рабочих",
          "Для руководителей и специалистов организаций",
          "Для студентов высших учебных заведений, проходящих производственную практику",
          "Для специалистов организаций и органов надзора",
        ],
        correctAnswers: ["Для руководителей и специалистов организаций"],
      },
      {
        code: "20402001",
        text: "В каком случае допускаются отступления от проектно-технической документации?",
        answers: [
          "После проведения экспертизы промышленной безопасности",
          "После получения разрешения Ростехнадзора",
          "После предварительного письменного согласования с организацией-разработчиком",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "После предварительного письменного согласования с организацией-разработчиком",
        ],
      },
      {
        code: "20402002",
        text: "В какой срок необходимо уведомить территориальный орган Ростехнадзора о начале работ по строительству подземных сооружений?",
        answers: [
          "В день начала работ",
          "За 15 дней до начала работ",
          "За 10 дней до начала работ",
          "За календарный месяц до начала работ",
        ],
        correctAnswers: ["За 15 дней до начала работ"],
      },
      {
        code: "20402003",
        text: "На основании каких документов проводятся работы в подземных условиях?",
        answers: [
          "На основании наряда-допуска на производство работ повышенной опасности",
          "На основании акта-допуска",
          "На основании письменных нарядов, выданных в соответствии с утвержденным руководителем организации положением о нарядной системе (работы должны фиксироваться в книге нарядов)",
          "На основании распоряжения о производстве работ",
        ],
        correctAnswers: [
          "На основании письменных нарядов, выданных в соответствии с утвержденным руководителем организации положением о нарядной системе (работы должны фиксироваться в книге нарядов)",
        ],
      },
      {
        code: "20402004",
        text: "По какому документу допускаются работы повышенной опасности?",
        answers: [
          "По наряду-допуску на производство работ",
          "По акту-допуску",
          "По письменным нарядам, выданным в соответствии с утвержденным руководителем организации положением о нарядной системе (работы должны фиксироваться в книге нарядов)",
          "По наряду на производство работ",
        ],
        correctAnswers: ["По наряду-допуску на производство работ"],
      },
      {
        code: "20402005",
        text: "В каком случае допускается разовое посещение подземного объекта лицом, не работающим постоянно на его строительстве?",
        answers: [
          "После проведения проверки знаний требований охраны труда и промышленной безопасности",
          "После получения разрешения руководителя работ",
          "После проведения инструктажа по технике безопасности с отметкой в журнале первичного инструктажа и в сопровождении лица технического надзора",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "После проведения инструктажа по технике безопасности с отметкой в журнале первичного инструктажа и в сопровождении лица технического надзора",
        ],
      },
      {
        code: "20402006",
        text: "При какой численности работников организации функции лица, ответственного за осуществление производственного контроля, возлагаются на руководителя службы производственного контроля?",
        answers: [
          "Менее 150 человек",
          "Более 500 человек",
          "От 150 до 500 человек",
          "От 200 до 300 человек",
        ],
        correctAnswers: ["Более 500 человек"],
      },
      {
        code: "20402007",
        text: "Какой стаж работы на строительстве подземных сооружений должен иметь работник, ответственный за осуществление производственного контроля?",
        answers: [
          "Не менее 3 лет",
          "Не менее 6 месяцев",
          "Не менее 1 года",
          "Не менее 2 лет",
        ],
        correctAnswers: ["Не менее 3 лет"],
      },
      {
        code: "20402008",
        text: "С какой периодичностью начальник участка обязан проверять состояние каждого рабочего места?",
        answers: [
          "Не менее 1 раза в сутки",
          "Не менее 1 раза в смену",
          "Не менее 1 раза в неделю",
          "Не менее 2 раз в месяц",
        ],
        correctAnswers: ["Не менее 1 раза в сутки"],
      },
      {
        code: "20402009",
        text: "С какой периодичностью горный мастер обязан проверять состояние каждого рабочего места?",
        answers: [
          "Не менее 1 раза в сутки",
          "Не менее 1 раза в смену",
          "Не менее 1 раза в неделю",
          "Не менее 2 раз в смену",
        ],
        correctAnswers: ["Не менее 2 раз в смену"],
      },
      {
        code: "20402010",
        text: "На кого возлагается общее руководство организацией и осуществлением производственного контроля?",
        answers: [
          "На технического руководителя",
          "На руководителя организации",
          "На специалиста по промышленной безопасности",
          "На ответственного за осуществление производственного контроля",
        ],
        correctAnswers: ["На руководителя организации"],
      },
      {
        code: "20402011",
        text: "Какие из перечисленных вновь смонтированных комплексов могут быть приняты в эксплуатацию комиссией организации без участия представителей органов государственного надзора?",
        answers: [
          "Главные вентиляционные и водоотливные установки",
          "Горнопроходческие комплексы диаметром менее 3,6 м",
          "Электровозная откатка",
          "Горнопроходческие комплексы диаметром 3,6 м и более",
        ],
        correctAnswers: ["Горнопроходческие комплексы диаметром менее 3,6 м"],
      },
      {
        code: "20402012",
        text: "Что должно проводиться при эксплуатации оборудования в соответствии с регламентом завода-изготовителя?",
        answers: [
          "Контрольные испытания",
          "Техническое освидетельствование",
          "Ежедневные обходы",
        ],
        correctAnswers: ["Техническое освидетельствование"],
      },
      {
        code: "20402013",
        text: "При каком условии допускается находиться на опасных рабочих местах при приведении их в безопасное состояние?",
        answers: [
          "При условии нахождения на опасных рабочих местах под руководством представителя технического надзора",
          "При условии оформления наряда-допуска с указанием в нем необходимых мер безопасности",
          "При условии проведения целевого инструктажа по охране труда",
          "Ни при каких условиях",
        ],
        correctAnswers: [
          "При условии оформления наряда-допуска с указанием в нем необходимых мер безопасности",
        ],
      },
      {
        code: "20402014",
        text: "При проведении работ на какой высоте должны применяться площадки со сплошным настилом, выполненным в соответствии с проектом производства работ?",
        answers: [
          "1 м от уровня пола",
          "1,1 м от уровня пола",
          "1,2 м от уровня пола",
          "1,3 м и более от уровня пола",
        ],
        correctAnswers: ["1,3 м и более от уровня пола"],
      },
      {
        code: "20402015",
        text: "В каком из перечисленных случаев запрещается производить работы без защитных настилов, козырьков или фартуков?",
        answers: [
          "В случае если работы ведутся в двух и более ярусах по одной вертикали",
          "В случае если работы ведутся более чем в одном ярусе по вертикали",
          "В любом случае",
        ],
        correctAnswers: [
          "В случае если работы ведутся в двух и более ярусах по одной вертикали",
        ],
      },
      {
        code: "20402016",
        text: "Каким документом регламентируется проведение кратковременных работ с приставных лестниц и стремянок?",
        answers: [
          "Нарядом-допуском",
          "Распоряжением о производстве работ",
          "Проектом производства работ или технологической картой",
        ],
        correctAnswers: [
          "Проектом производства работ или технологической картой",
        ],
      },
      {
        code: "20402017",
        text: "Что производится по окончании монтажа тоннелепроходческого комплекса?",
        answers: [
          "Опробование основных рабочих узлов, систем и технических устройств комплекса, включая устройства обеспечения безопасной эксплуатации",
          "Проходка первых 50 м тоннеля",
          "Приемка оборудования комплекса в эксплуатацию комиссией предприятия",
          "Приемка оборудования комплекса в эксплуатацию комиссией территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "Опробование основных рабочих узлов, систем и технических устройств комплекса, включая устройства обеспечения безопасной эксплуатации",
        ],
      },
      {
        code: "20402018",
        text: "По окончании каких мероприятий комиссия, назначенная руководителем организации, принимает решение о готовности оборудования тоннелепроходческого комплекса диаметром 3,6 м к эксплуатации?",
        answers: [
          "По окончании монтажа тоннелепроходческого комплекса",
          "По окончании проходки первых 50 м",
          "По окончании проходки первых 25 м без особых затруднений",
        ],
        correctAnswers: ["По окончании проходки первых 50 м"],
      },
      {
        code: "20402019",
        text: "С какой периодичностью механик участка должен проверять техническое состояние буровых установок?",
        answers: ["Ежесменно", "Еженедельно", "Ежемесячно", "Ежеквартально"],
        correctAnswers: ["Еженедельно"],
      },
      {
        code: "20402020",
        text: "Забором какой высоты должна ограждаться стройплощадка?",
        answers: [
          "Не менее 1,5 м",
          "Не менее 2 м",
          "Не менее \n      \n    1,7 м",
          "Не менее \n      \n    1 м",
        ],
        correctAnswers: ["Не менее 2 м"],
      },
      {
        code: "20402021",
        text: "Какие данные должны быть указаны на информационном щите строительной площадки?",
        answers: [
          "Наименование объекта, фамилии ответственных производителей работ, сроки начала и окончания работ",
          "Наименование объекта, генподрядчик, заказчик, сроки начала и окончания работ",
          "Наименование объекта, генподрядчик, заказчик, фамилии ответственных производителей работ, номера телефонов, сроки начала и окончания работ",
          "Наименование объекта, генподрядчик, фамилии ответственных производителей работ, сроки начала и окончания работ",
        ],
        correctAnswers: [
          "Наименование объекта, генподрядчик, заказчик, фамилии ответственных производителей работ, номера телефонов, сроки начала и окончания работ",
        ],
      },
      {
        code: "20402022",
        text: "Чем должны быть оборудованы на строительной площадке проходы для людей, проложенные по вязкой почве?",
        answers: [
          "Сплошным настилом шириной не менее 1 м",
          "Трапом шириной не менее 0,8 м с перилами высотой 1 м",
          "Решетчатым настилом шириной не менее 1,2 м",
          "Сплошным настилом шириной не менее 1,2 м с перилами высотой 1,1 м",
        ],
        correctAnswers: ["Сплошным настилом шириной не менее 1 м"],
      },
      {
        code: "20402023",
        text: "Как должны быть ограждены траншеи и котлованы на территории строительной площадки?",
        answers: [
          "Перилами высотой 1,1 м, в темное время суток на ограждения должны быть выставлены световые сигналы",
          "Перилами высотой 1,5 м, в темное время суток ограждения должны быть достаточно освещены",
          "Перилами высотой 1,2 м и бортовыми досками высотой не менее 15 см",
          "Ограждение не требуется",
        ],
        correctAnswers: [
          "Перилами высотой 1,1 м, в темное время суток на ограждения должны быть выставлены световые сигналы",
        ],
      },
      {
        code: "20402024",
        text: "Чем должны быть оборудованы места перехода через траншеи, трубопроводы на строительной площадке?",
        answers: [
          "Мостиками шириной не менее 0,8 м с перилами 1,1 м",
          "Мостиками шириной не менее 0,8 м с перилами 1,1 м и бортовыми досками высотой не менее 15 см",
          "Трапами шириной не менее 1 м с перилами 1,1 м и бортовыми досками высотой не менее 15 см",
          "Сходнями шириной не менее 1 м с перилами 1,1 м и световыми приборами для обозначения в темное время суток",
        ],
        correctAnswers: [
          "Мостиками шириной не менее 0,8 м с перилами 1,1 м и бортовыми досками высотой не менее 15 см",
        ],
      },
      {
        code: "20402025",
        text: "Кто является ответственным за безопасное выполнение земляных работ, связанных с разрытием и перекладкой коммуникаций?",
        answers: [
          "Технический руководитель объекта подземного строительства",
          "Начальник участка",
          "Специалист, назначенный приказом по организации",
          "Главный энергетик",
        ],
        correctAnswers: ["Специалист, назначенный приказом по организации"],
      },
      {
        code: "20402026",
        text: "На каком расстоянии от действующих подземных коммуникаций запрещается применять землеройную технику?",
        answers: [
          "Менее 1 м по горизонтали или 2 м по вертикали",
          "Менее 1,5 м по горизонтали или 1 м по вертикали",
          "Менее 2 м по горизонтали или 1,5 м по вертикали",
          "Менее 2 м по горизонтали или 1 м по вертикали",
        ],
        correctAnswers: ["Менее 2 м по горизонтали или 1 м по вертикали"],
      },
      {
        code: "20402027",
        text: "На каком расстоянии от откосов котлованов и траншей, разрабатываемых без крепления, в случае увлажнения или выветривания запрещается движение транспортных средств и механизмов?",
        answers: [
          "Менее расстояния, определенного расчетом, но не менее 1,5 м от верхнего края откоса котлована",
          "Менее 2 м от верхнего края откоса котлована",
          "Менее 1,5 м от верхнего края откоса котлована",
          "Менее 2 м от нижнего края откоса котлована",
        ],
        correctAnswers: ["Менее 2 м от верхнего края откоса котлована"],
      },
      {
        code: "20402028",
        text: "С какой периодичностью технический руководитель объекта подземного строительства обязан проводить осмотр состояния крепления при выполнении работ в котлованах и траншеях с креплением бортов?",
        answers: [
          "1 раз в неделю",
          "1 раз в квартал",
          "1 раз в месяц",
          "1 раз в сутки",
        ],
        correctAnswers: ["1 раз в месяц"],
      },
      {
        code: "20402029",
        text: "С какой периодичностью начальник участка обязан проводить осмотр состояния крепления при выполнении работ в котлованах и траншеях с креплением бортов?",
        answers: [
          "1 раз в неделю",
          "1 раз в квартал",
          "1 раз в месяц",
          "1 раз в сутки",
        ],
        correctAnswers: ["1 раз в неделю"],
      },
      {
        code: "20402030",
        text: "В каком случае допускается спуск людей в котлован по лестницам?",
        answers: [
          "В случае если глубина котлована менее 25 м при условии, что расстояние между лестницами не превышает 40 м",
          "В случае если глубина котлована  менее 30 м при условии, что расстояние между лестницами не превышает 40 м",
          "В случае если глубина котлована  менее 25 м при условии, что расстояние между лестницами не превышает 50 м",
          "В случае если глубина котлована  менее 30 м при условии, что расстояние между лестницами не превышает 50 м",
        ],
        correctAnswers: [
          "В случае если глубина котлована менее 25 м при условии, что расстояние между лестницами не превышает 40 м",
        ],
      },
      {
        code: "20402031",
        text: 'На какую глубину должно быть забетонировано устье траншеи до начала выполнения работ по ее разработке методом "стена в грунте"?',
        answers: [
          "На глубину не менее 0,5 м",
          "На глубину 2 м",
          "На глубину не менее 1,5 м",
          "На глубину 0,8 м",
        ],
        correctAnswers: ["На глубину не менее 1,5 м"],
      },
      {
        code: "20402032",
        text: "На каком расстоянии от бровки котлована разрешается складирование материалов и оборудования?",
        answers: [
          "На расстоянии, равном высоте складируемого оборудования или материалов",
          "На расстоянии, равном высоте складируемого оборудования или материалов плюс 1 м",
          "На расстоянии, равном высоте складируемого оборудования или материалов плюс 2 м",
          "На расстоянии в два раза меньше высоты складируемого оборудования или материалов",
        ],
        correctAnswers: [
          "На расстоянии, равном высоте складируемого оборудования или материалов плюс 1 м",
        ],
      },
      {
        code: "20402033",
        text: "Как производится разработка породы при проходке выработок?",
        answers: [
          "Начиная с верхней части забоя",
          "Начиная с нижней части забоя",
          "Согласно плану производства работ",
          "Согласно технологической карте",
        ],
        correctAnswers: ["Начиная с верхней части забоя"],
      },
      {
        code: "20402034",
        text: "Как осуществляется раскрытие тоннеля на полный профиль и монтаж обделки при укладке прорезных колец?",
        answers: [
          "Только в соответствии с планом производства работ",
          "Только под руководством лица технического надзора",
          "В соответствии с планом производства работ под руководством лица технического надзора",
          "В соответствии с заключением экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "В соответствии с планом производства работ под руководством лица технического надзора",
        ],
      },
      {
        code: "20402035",
        text: "С какого расстояния должны производиться горнопроходческие работы с соблюдением дополнительных мер безопасности по единому согласованному графику при проходке выработок встречными или сближающимися забоями без применения взрывных работ?",
        answers: [
          "Начиная с расстояния между ними менее 15 м",
          "Начиная с расстояния между ними менее 20 м",
          "Начиная с расстояния между ними менее полутора диаметров максимальной выработки",
        ],
        correctAnswers: [
          "Начиная с расстояния между ними менее полутора диаметров максимальной выработки",
        ],
      },
      {
        code: "20402036",
        text: "Какой провис сетки, подвешенной к анкерам и очищаемой от лежащей на ней породы, допускается при установке анкерной крепи в выработках, проводимых по породам, склонным к отслоению и обрушению?",
        answers: [
          "Не более 0,3 м",
          "Не более 0,25 м",
          "Не более 0,2 м",
          "Не более 0,5 м",
        ],
        correctAnswers: ["Не более 0,2 м"],
      },
      {
        code: "20402037",
        text: "При достижении бетоном какой прочности допускается раскрытие калотты на захватках свода, смежных с ранее забетонированными участками?",
        answers: [
          "Не ниже 70 % проектной",
          "Не ниже 75 % проектной",
          "Не ниже 80 % проектной",
          "Не ниже 85 % проектной",
        ],
        correctAnswers: ["Не ниже 70 % проектной"],
      },
      {
        code: "20402038",
        text: "При достижении бетоном какой прочности должна начинаться разработка боковых штросс при проходке способом опертого свода в неустойчивых породах?",
        answers: [
          "Не ниже 85 % проектной",
          "Не ниже 80 % проектной",
          "Не ниже 75 % проектной",
          "Не ниже 70 % проектной",
        ],
        correctAnswers: ["Не ниже 70 % проектной"],
      },
      {
        code: "20402039",
        text: "Какую высоту должен иметь свободный проход для людей на всем протяжении выработки?",
        answers: [
          "Не менее 1,6 м",
          "Не менее 1,7 м",
          "Не менее 1,8 м",
          "Не менее 1,5 м",
        ],
        correctAnswers: ["Не менее 1,8 м"],
      },
      {
        code: "20402040",
        text: "Каким должно быть расстояние откатки загруженных вагонеток в призабойной зоне с помощью погрузочных машин?",
        answers: [
          "Не должно превышать 25 м",
          "Не должно превышать 50 м",
          "Не должно превышать 30 м",
          "Не должно превышать 20 м",
        ],
        correctAnswers: ["Не должно превышать 25 м"],
      },
      {
        code: "20402041",
        text: "На каком расстоянии при монтаже и демонтаже сборной обделки должны быть расположены лебедки от поднимаемого груза?",
        answers: [
          "Не ближе 2,5 м по горизонтали",
          "Не ближе 3 м и не далее 15 м по горизонтали",
          "Не ближе 20 м по горизонтали",
          "Не ближе 5 м и не далее 15 м по горизонтали",
        ],
        correctAnswers: ["Не ближе 5 м и не далее 15 м по горизонтали"],
      },
      {
        code: "20402042",
        text: "В каком случае допускается отцеплять прицепное устройство от элемента обделки?",
        answers: [
          "В случае если элемент обделки скреплен не менее чем двумя болтами (один по фланцу и один по торцу) с соседним собранным кольцом",
          "В случае если элемент обделки скреплен не менее чем тремя болтами (два по фланцу и один по торцу) с соседним собранным кольцом",
          "В случае если элемент обделки скреплен одним болтом с соседним собранным кольцом",
        ],
        correctAnswers: [
          "В случае если элемент обделки скреплен не менее чем тремя болтами (два по фланцу и один по торцу) с соседним собранным кольцом",
        ],
      },
      {
        code: "20402043",
        text: "Кем должны быть осмотрены проходческие комбайны и перегружатель перед началом работ?",
        answers: [
          "Начальником участка",
          "Лицом технического надзора",
          "Машинистом комбайна",
          "Механиком участка",
        ],
        correctAnswers: ["Машинистом комбайна"],
      },
      {
        code: "20402044",
        text: "При каком условии разрешается осмотр забоя и его крепление при производстве работ проходческим комбайном?",
        answers: [
          'Если станция управления комбайном обесточена и вывешен плакат "Не включать - работают люди!"',
          'Если вывешен освещенный транспарант "Внимание! Опасная зона! Ведутся работы"',
          "Если комбайн отведен от забоя на 1,5 - 2 м и смещен к одному из боков выработки",
          "Если комбайн работает",
        ],
        correctAnswers: [
          "Если комбайн отведен от забоя на 1,5 - 2 м и смещен к одному из боков выработки",
        ],
      },
      {
        code: "20402045",
        text: "В каком случае разрешается производство работ при проходке стволов глубиной до 20 м с использованием в качестве средств подъема кранов?",
        answers: [
          "В случае производства работ без перекрытия устья ствола с бермой безопасности вокруг устья ствола не менее 1 м",
          "В случае производства работ без перекрытия устья ствола при устройстве сплошного прочного ограждения высотой не менее 1,5 м по периметру ствола с дверью для прохода людей",
          "В случае наличия бермы безопасности не менее 1 м, обделки ствола, возвышенной над уровнем спланированной поверхности не менее чем на 0,5 м",
          "В случае производства работ без перекрытия устья ствола с бермой безопасности вокруг устья ствола не менее 3 м",
        ],
        correctAnswers: [
          "В случае производства работ без перекрытия устья ствола при устройстве сплошного прочного ограждения высотой не менее 1,5 м по периметру ствола с дверью для прохода людей",
        ],
      },
      {
        code: "20402046",
        text: "Каким требованиям должны соответствовать лестничные отделения шахтных стволов?",
        answers: [
          "Лестницы должны быть установлены под углом не более 80° и выступать на 1 м над устьем выработки",
          "Лестницы через каждые 3 м должны быть скреплены металлическими стяжками",
          "Расстояние между лестничными площадками не должно превышать 5 м",
          "Расстояние от основания лестницы до крепи или обшивки лестничного отделения должно быть не менее 1 м",
        ],
        correctAnswers: [
          "Лестницы должны быть установлены под углом не более 80° и выступать на 1 м над устьем выработки",
        ],
      },
      {
        code: "20402047",
        text: "При какой глубине ствола на период проходки разрешается установка подвесных лестниц с закреплением их в крепи ствола и установкой дуг безопасности?",
        answers: [
          "Не более 20 м",
          "Не более 25 м",
          "Не более 15 м",
          "Не более 35 м",
        ],
        correctAnswers: ["Не более 15 м"],
      },
      {
        code: "20402048",
        text: "На какую величину над поверхностью земли должны возвышаться устья наклонных выработок?",
        answers: ["На 1 м", "На 0,5 м", "На 0,7 м", "На 1,5 м"],
        correctAnswers: ["На 0,5 м"],
      },
      {
        code: "20402049",
        text: "Какие заграждения должны быть установлены для защиты работающих в забое от опасности обрыва скипа или падения предметов при проходке наклонных выработок?",
        answers: [
          "Только заграждения в устье выработки",
          "Одно заграждение не дальше 25 м от места работы",
          "Два заграждения: одно - в устье выработки, другое - не дальше 20 м от места работы",
          "Стопорные и улавливающие устройства на скипе",
        ],
        correctAnswers: [
          "Два заграждения: одно - в устье выработки, другое - не дальше 20 м от места работы",
        ],
      },
      {
        code: "20402050",
        text: "С какой периодичностью механик участка должен осматривать выработки и их обустройство?",
        answers: [
          "Еженедельно",
          "Ежесменно до начала работ",
          "Ежедневно",
          "В соответствии с графиком, утвержденным техническим руководителем объекта подземного строительства",
        ],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "20402051",
        text: "В каком случае для спуска и подъема грузов допускается применение грузоподъемных кранов с лебедками?",
        answers: [
          "В случае проходки шахтных стволов глубиной до 40 м",
          "В случае проходки шахтных стволов глубиной до 50 м",
          "В случае проходки шахтных стволов глубиной до 60 м",
        ],
        correctAnswers: ["В случае проходки шахтных стволов глубиной до 40 м"],
      },
      {
        code: "20402052",
        text: "Какая площадь должна приходиться на одного человека при спуске и подъеме людей в бадьях?",
        answers: [
          "Не менее 0,4 м2 площади днища",
          "Не менее 0,35 м2 площади днища",
          "Не менее 0,25 м2 площади днища",
          "Не менее 0,2 м2 площади днища",
        ],
        correctAnswers: ["Не менее 0,25 м2 площади днища"],
      },
      {
        code: "20402053",
        text: "Как допускается поднимать больного в бадье на поверхность?",
        answers: [
          "Только одного",
          "Только со страховочным поясом, прикрепленным к дужке бадьи",
          "Только с сопровождающим",
          "Только прикрепленным к прицепному устройству, в сопровождении второго работника",
        ],
        correctAnswers: ["Только с сопровождающим"],
      },
      {
        code: "20402054",
        text: "На сколько бадья должна не догружаться до верхнего края при выдаче породы?",
        answers: ["На 200 мм", "На 100 мм", "На 150 мм", "На 250 мм"],
        correctAnswers: ["На 100 мм"],
      },
      {
        code: "20402055",
        text: "Каким требованиям должны отвечать двери c торцевых сторон клети, служащей для спуска и подъема людей?",
        answers: [
          "Двери должны открываться вовнутрь и запираться засовом, расположенным снаружи, верхняя кромка двери должна быть выше уровня пола не менее чем на 1200 мм, а нижняя - не более 150 мм",
          "Двери должны открываться наружу и запираться засовом, расположенным внутри, верхняя кромка двери должна быть выше уровня пола не менее чем на 1200 мм, а нижняя - не более 150 мм",
          "Двери должны открываться внутрь и запираться засовом, расположенным снаружи; верхняя кромка двери должна быть выше уровня пола не менее чем на 1500 мм, а нижняя - не более 200 мм",
          "Двери должны открываться наружу и запираться засовом, расположенным внутри, верхняя кромка двери должна быть выше уровня пола не менее чем на 1500 мм, а нижняя - не более 200 мм",
        ],
        correctAnswers: [
          "Двери должны открываться вовнутрь и запираться засовом, расположенным снаружи, верхняя кромка двери должна быть выше уровня пола не менее чем на 1200 мм, а нижняя - не более 150 мм",
        ],
      },
      {
        code: "20402056",
        text: "Какой должна быть площадь пола в клети на одного человека?",
        answers: [
          "Не менее 0,4 м2",
          "Не менее 0,35 м2",
          "Не менее 0,25 м2",
          "Не менее 0,2 м2",
        ],
        correctAnswers: ["Не менее 0,2 м2"],
      },
      {
        code: "20402057",
        text: "С какой периодичностью необходимо испытывать парашюты клетей для спуска и подъема людей в соответствии с требованиями Инструкции по испытанию шахтных парашютов подъемных установок?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в квартал",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в год",
        ],
        correctAnswers: ["Не реже 1 раза в 6 месяцев"],
      },
      {
        code: "20402058",
        text: "Барьером какой высоты должен быть огражден лесоспуск на поверхности?",
        answers: ["1 м", "1,2 м", "1,5 м", "2 м"],
        correctAnswers: ["1 м"],
      },
      {
        code: "20402059",
        text: "С какой периодичностью проводится проверка состояния постоянных шахтных копров?",
        answers: [
          "1 раз в год",
          "2 раза в год",
          "1 раз в 2 года",
          "1 раз в 3 года",
        ],
        correctAnswers: ["1 раз в год"],
      },
      {
        code: "20402060",
        text: "Как должен быть воспринят машинистом подъема каждый непонятный сигнал?",
        answers: [
          'Как сигнал "Вверх"',
          'Как сигнал "Вниз"',
          'Как сигнал "Стоп"',
          'Как сигнал "Осторожно"',
        ],
        correctAnswers: ['Как сигнал "Стоп"'],
      },
      {
        code: "20402061",
        text: "Какая навивка каната на барабан допускается на машинах шахтного подъема, используемых для подъема и спуска людей?",
        answers: ["Однослойная", "Двухслойная", "Многослойная", "Трехслойная"],
        correctAnswers: ["Однослойная"],
      },
      {
        code: "20402062",
        text: "Какое значение продолжительности холостого хода предохранительного тормоза установлено для подъемной машины?",
        answers: [
          "Не более 0,5 с",
          "Не более 0,8 с",
          "Не более 1 с",
          "Не более 1,5 с",
        ],
        correctAnswers: ["Не более 0,5 с"],
      },
      {
        code: "20402063",
        text: "С какой периодичностью механик участка должен проводить осмотр проходческих полков и лебедок?",
        answers: [
          "Ежесменно и перед началом каждой спуско-подъемной операции",
          "1 раз в день",
          "1 раз в неделю",
          "1 раз в месяц",
        ],
        correctAnswers: ["1 раз в неделю"],
      },
      {
        code: "20402064",
        text: "С какой периодичностью начальник участка в присутствии главного механика организации должен осматривать подъемные сосуды, подвесные и прицепные устройства и другие элементы подъемной установки?",
        answers: [
          "Ежесуточно",
          "Еженедельно",
          "Не реже 1 раза в 15 дней",
          "Не реже 1 раза в месяц",
        ],
        correctAnswers: ["Не реже 1 раза в 15 дней"],
      },
      {
        code: "20402065",
        text: "Какова периодичность проведения ревизии, наладки и испытания эксплуатируемых подъемных установок специализированной организацией?",
        answers: [
          "2 раза в год",
          "1 раз в год",
          "1 раз в 2 года",
          "1 раз в 5 лет",
        ],
        correctAnswers: ["1 раз в год"],
      },
      {
        code: "20402066",
        text: "Какие из перечисленных грузоподъемных машин должны подвергаться ежегодному комиссионному обследованию и дефектоскопии под председательством представителя специализированной наладочной организации?",
        answers: [
          "Грузопассажирские подъемные машины",
          "Постоянно эксплуатируемые подъемные установки",
          "Подъемные установки с истекшим сроком эксплуатации",
          "Грузовые подъемные машины",
        ],
        correctAnswers: ["Подъемные установки с истекшим сроком эксплуатации"],
      },
      {
        code: "20402067",
        text: "Какой запас прочности должны иметь канаты для спасательных лестниц, канаты для подвески полков, насосов, трубопроводов, проходческих агрегатов?",
        answers: ["9-кратный", "8-кратный", "7-кратный", "6-кратный"],
        correctAnswers: ["6-кратный"],
      },
      {
        code: "20402068",
        text: "С какой периодичностью должны испытываться подъемные канаты людских и грузолюдских подъемных установок?",
        answers: [
          "Через каждые 6 месяцев, считая со дня их навески",
          "Через каждые  6 месяцев, считая со дня их последнего испытания",
          "Через 12 месяцев, считая со дня их навески, а затем через каждые 6 месяцев",
          "Не реже 1 раза в год",
        ],
        correctAnswers: ["Через каждые 6 месяцев, считая со дня их навески"],
      },
      {
        code: "20402069",
        text: "С какой периодичностью необходимо проводить смазку подъемных канатов?",
        answers: [
          "Не реже 1 раза в сутки",
          "Не реже 1 раза в неделю",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в полгода",
        ],
        correctAnswers: ["Не реже 1 раза в неделю"],
      },
      {
        code: "20402070",
        text: "В каком случае при испытании перед навеской канаты, служащие для подъема и спуска людей, бракуются?",
        answers: [
          "Если суммарная площадь поперечного сечения проволок, не выдержавших испытания на разрыв и перегиб, составляет 6 %",
          "Если суммарная площадь поперечного сечения проволок, не выдержавших испытания на разрыв и перегиб, составляет 5 %",
          "Если запас их прочности ниже 3,5-кратного",
          "Если запас их прочности ниже 2-кратного",
        ],
        correctAnswers: [
          "Если суммарная площадь поперечного сечения проволок, не выдержавших испытания на разрыв и перегиб, составляет 6 %",
        ],
      },
      {
        code: "20402071",
        text: "Каков предельный срок эксплуатации прицепных устройств бадей?",
        answers: ["1 год", "2 года", "3 года", "5 лет"],
        correctAnswers: ["2 года"],
      },
      {
        code: "20402072",
        text: "С какой периодичностью лицо технического надзора должно осматривать подвижной состав и рельсовые пути?",
        answers: [
          "Не реже 1 раза в смену",
          "Не реже 1 раза в неделю",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в год",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20402073",
        text: "Какова максимальная скорость движения рельсового подвижного состава по горизонтальным выработкам при электровозной откатке?",
        answers: ["4 км/ч", "3,6 км/ч", "5,4 км/ч", "10 км/ч"],
        correctAnswers: ["10 км/ч"],
      },
      {
        code: "20402074",
        text: "На каком расстоянии от мест производства работ должны устанавливаться сигнальные знаки на откаточных путях?",
        answers: [
          "Не менее 40 м",
          "Не менее 60 м",
          "Не менее 80 м",
          "Не менее 10 м",
        ],
        correctAnswers: ["Не менее 80 м"],
      },
      {
        code: "20402075",
        text: "В каком случае осуществляется механизированная доставка людей к месту производства работ?",
        answers: [
          "В горизонтальных выработках при расстоянии до места производства работ свыше 1,5 км",
          "По наклонным выработкам при разнице конечных отметок свыше 60 м",
          "В горизонтальных выработках при расстоянии до места производства работ свыше 1 км",
          "По наклонным выработкам при разнице конечных отметок свыше 40 м",
        ],
        correctAnswers: [
          "По наклонным выработкам при разнице конечных отметок свыше 60 м",
        ],
      },
      {
        code: "20402076",
        text: "На выработках какой протяженности допускается укладка одного откаточного пути с устройством разъездов?",
        answers: [
          "Не более 200 м",
          "Не более 300 м",
          "Более 500 м",
          "Более 100 м",
        ],
        correctAnswers: ["Более 500 м"],
      },
      {
        code: "20402077",
        text: "В каком случае запрещается эксплуатация рельсовых путей?",
        answers: [
          "При расширении пути более 4 мм и сужении более 2 мм против нормально установленной ширины рельсовой колеи",
          "При износе головки рельса по вертикали более 10 мм - для рельсов типа Р-24",
          "При образовании зазоров между торцами рельсов не более 3 мм",
          "При износе головки рельса по вертикали более 12 мм - для рельсов типа Р-33",
        ],
        correctAnswers: [
          "При расширении пути более 4 мм и сужении более 2 мм против нормально установленной ширины рельсовой колеи",
        ],
      },
      {
        code: "20402078",
        text: "Каким должно быть расстояние между вагонетками при ручной откатке на путях с уклоном более 0,005?",
        answers: [
          "Не менее 10 м",
          "Не менее 20 м",
          "Не менее 30 м",
          "Не менее 15 м",
        ],
        correctAnswers: ["Не менее 30 м"],
      },
      {
        code: "20402079",
        text: "В каком случае ручная откатка рельсовых транспортных средств запрещается?",
        answers: [
          "В случае если их вес превышает 1,5 т",
          "В случае если необходимо приложить усилие более 15 кг",
          "В случае если уклон составляет более 0,01",
          "В случае если расстояние составляет более 50 м",
        ],
        correctAnswers: ["В случае если уклон составляет более 0,01"],
      },
      {
        code: "20402080",
        text: 'Где при формировании состава должна находиться блокотюбинговозка или "коза"?',
        answers: [
          "В голове состава",
          "В хвосте состава",
          "В середине состава",
          "За буферной вагонеткой",
        ],
        correctAnswers: ["За буферной вагонеткой"],
      },
      {
        code: "20402081",
        text: "В каком случае допускается нахождение локомотива в хвосте состава при электровозной откатке?",
        answers: [
          "При уклоне до 0,005 на расстоянии не более 500 м",
          "При сооружении тоннелей механизированными тоннелепроходческими комплексами",
          "При откатке в тоннелях диаметром в свету 4,0 м и более",
          "При нахождении в голове состава сигналиста с фонарем и свистком",
        ],
        correctAnswers: [
          "При сооружении тоннелей механизированными тоннелепроходческими комплексами",
        ],
      },
      {
        code: "20402082",
        text: "Каково максимальное значение тормозного пути состава при перевозке грузов на максимальном уклоне?",
        answers: ["40 м", "50 м", "60 м", "70 м"],
        correctAnswers: ["40 м"],
      },
      {
        code: "20402083",
        text: "Каким должно быть расстояние между разъединителями контактной сети?",
        answers: [
          "Не более 500 м",
          "Не более 350 м",
          "Не более 250 м",
          "Не более 400 м",
        ],
        correctAnswers: ["Не более 250 м"],
      },
      {
        code: "20402084",
        text: "В каких местах в выработках, где подвешен контактный провод, должны быть вывешены светящиеся надписи, предупреждающие, что провод под напряжением?",
        answers: [
          "Через каждые 500 м",
          "Через каждые 250 м и на пересечениях с другими выработками",
          "Только на пересечениях с другими выработками",
          "Через каждые 100 м и на пересечении с другими выработками",
        ],
        correctAnswers: [
          "Через каждые 100 м и на пересечении с другими выработками",
        ],
      },
      {
        code: "20402085",
        text: "Каким должно быть расстояние между вагонетками при откатке бесконечным канатом?",
        answers: [
          "Не менее 5 м",
          "Не менее 4 м",
          "Не менее 3 м",
          "Не менее 1 м",
        ],
        correctAnswers: ["Не менее 5 м"],
      },
      {
        code: "20402086",
        text: "С какой скоростью должны передвигаться самоходные машины с двигателями внутреннего сгорания в подземных выработках?",
        answers: [
          "Не более 50 км/ч",
          "Не более 40 км/ч",
          "Не более 30 км/ч",
          "Не более 20 км/ч",
        ],
        correctAnswers: ["Не более 20 км/ч"],
      },
      {
        code: "20402087",
        text: "При помощи чего производится буксировка неисправных самоходных машин в подземных выработках?",
        answers: [
          "При помощи жесткой сцепки длиной не более 1 м",
          "При помощи сцепки длиной не более 5 м",
          "При помощи сцепки длиной не более 1,2 м",
          "При помощи гибкой сцепки длиной 5 м",
        ],
        correctAnswers: ["При помощи жесткой сцепки длиной не более 1 м"],
      },
      {
        code: "20402088",
        text: "С какой периодичностью механик участка должен осуществлять контроль за техническим состоянием машин с двигателями внутреннего сгорания с записью в журнале?",
        answers: [
          "Не реже 1 раза в квартал",
          "Не реже 1 раза в полгода",
          "Не реже 1 раза в неделю",
          "Не реже 1 раза в месяц",
        ],
        correctAnswers: ["Не реже 1 раза в неделю"],
      },
      {
        code: "20402089",
        text: "После какой наработки двигателя или пробега должен проводиться планово-предупредительный ремонт машин с карбюраторным двигателем?",
        answers: [
          "После каждых 150 моточасов работы двигателя или 1500 км пробега",
          "После каждых 250 моточасов работы двигателя или 2500 км пробега",
          "После каждых 2500 моточасов работы двигателя или 25 000 км пробега",
          "После каждых 200 моточасов работы двигателя или 2000 км пробега",
        ],
        correctAnswers: [
          "После каждых 250 моточасов работы двигателя или 2500 км пробега",
        ],
      },
      {
        code: "20402090",
        text: "Каким должно быть содержание кислорода в воздухе в подземных выработках?",
        answers: [
          "Не менее 10 % по объему",
          "Не менее 15 % по объему",
          "Не менее 20 % по объему",
          "Не менее 17 % по объему",
        ],
        correctAnswers: ["Не менее 20 % по объему"],
      },
      {
        code: "20402091",
        text: "Каким должно приниматься количество воздуха на одного человека, считая по наибольшему количеству людей, одновременно работающих в смене?",
        answers: [
          "Не менее 4 м3/мин",
          "Не менее 5 м3/мин",
          "Не менее 6 м3/мин",
          "Не менее 2 м3/мин",
        ],
        correctAnswers: ["Не менее 6 м3/мин"],
      },
      {
        code: "20402092",
        text: "С кем должно быть согласовано проведение естественного проветривания подземных выработок мелкого заложения, имеющих не менее двух выходов и устойчивое направление вентиляционной струи?",
        answers: [
          "С территориальными органами Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "С территориальными органами Ростехнадзора",
          "С вышестоящей организацией",
          "С проектной организацией",
        ],
        correctAnswers: ["С территориальными органами Ростехнадзора"],
      },
      {
        code: "20402093",
        text: "На каком расстоянии от мест хранения и использования горючих и легковоспламеняющихся материалов должны располагаться воздухозаборы вентиляторных установок?",
        answers: [
          "Не ближе 25 м",
          "Не ближе 20 м",
          "Не ближе 18 м",
          "Не ближе 15 м",
        ],
        correctAnswers: ["Не ближе 25 м"],
      },
      {
        code: "20402094",
        text: "На каком расстоянии от ствола объекта подземного строительства должны располагаться воздухозаборы вентиляторных установок?",
        answers: [
          "Не ближе 5 м",
          "Не ближе 2 м",
          "Не ближе 15 м",
          "Не ближе 10 м",
        ],
        correctAnswers: ["Не ближе 15 м"],
      },
      {
        code: "20402095",
        text: "При какой продолжительности остановки главного вентилятора людей необходимо выводить на поверхность со всех рабочих мест?",
        answers: [
          "Более чем на 30 мин",
          "Более чем на 45 мин",
          "Более чем на 60 мин",
          "Более чем на 120 мин",
        ],
        correctAnswers: ["Более чем на 120 мин"],
      },
      {
        code: "20402096",
        text: "Каким должно быть количество воздуха, проходящее по выработкам после реверсирования?",
        answers: [
          "Не менее 30 % его количества в нормальном режиме проветривания",
          "Не менее 50 % его количества в нормальном режиме проветривания",
          "Не менее 60 % его количества в нормальном режиме проветривания",
          "Не менее 40 % его количества в нормальном режиме проветривания",
        ],
        correctAnswers: [
          "Не менее 60 % его количества в нормальном режиме проветривания",
        ],
      },
      {
        code: "20402097",
        text: "Где должна располагаться вентиляторная установка для проветривания при проходке ствола?",
        answers: [
          "На поверхности на расстоянии не менее 5 м от ствола",
          "На поверхности на расстоянии не менее 10 м от ствола",
          "В горных выработках",
          "В месте, определенном проектом",
        ],
        correctAnswers: [
          "На поверхности на расстоянии не менее 10 м от ствола",
        ],
      },
      {
        code: "20402098",
        text: "Какое отставание вентиляционных труб от забоя ствола допускается при его проходке?",
        answers: [
          "Не более 30 м",
          "Не более \n  15 м",
          "Не более 20 м",
          "Не более 25 м",
        ],
        correctAnswers: ["Не более \n  15 м"],
      },
      {
        code: "20402099",
        text: "Какое отставание вентиляционных труб от забоя допускается при проходке горизонтальных выработок при площади сечения забоя не более 16 м2?",
        answers: [
          "Не более 16 м",
          "Не более 10 м",
          "Не более 15 м",
          "Не более 12 м",
        ],
        correctAnswers: ["Не более 10 м"],
      },
      {
        code: "20402100",
        text: "За какое время рабочие агрегаты главной водоотливной установки должны обеспечивать откачку максимального суточного притока воды?",
        answers: ["За 22 ч", "За 20 ч", "За 21 ч", "За 24 ч"],
        correctAnswers: ["За 20 ч"],
      },
      {
        code: "20402101",
        text: "С какой периодичностью главный механик организации должен проводить осмотр главной водоотливной установки?",
        answers: [
          "Не реже 1 раза в две недели",
          "Не реже 1 раза в неделю",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в квартал",
        ],
        correctAnswers: ["Не реже 1 раза в неделю"],
      },
      {
        code: "20402102",
        text: "Какова периодичность проведения замеров притока шахтной воды и ее химического анализа?",
        answers: [
          "Не реже 1 раза в 1,5 года",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в год",
        ],
        correctAnswers: ["Не реже 1 раза в 6 месяцев"],
      },
      {
        code: "20402103",
        text: "С какой периодичностью главный энергетик организации должен проводить проверку исправности аппаратов устройств контроля изоляции и отключающих устройств?",
        answers: [
          "1 раз в день",
          "1 раз в неделю",
          "1 раз в месяц",
          "1 раз в квартал",
        ],
        correctAnswers: ["1 раз в месяц"],
      },
      {
        code: "20402104",
        text: "Какие электрические сети разрешается применять для контактной электровозной откатки?",
        answers: [
          "Трехфазные электрические сети переменного тока с изолированной нейтралью линейным напряжением до 1000 В включительно",
          "Электрические сети постоянного тока напряжением до 300 В включительно",
          "Электрические сети переменного тока напряжением до 42 В включительно",
          "Электрические сети переменного тока напряжением до 110 В включительно",
        ],
        correctAnswers: [
          "Электрические сети постоянного тока напряжением до 300 В включительно",
        ],
      },
      {
        code: "20402105",
        text: "Какого вида кабели должны применяться для прокладки по вертикальным и наклонным выработкам на период строительства?",
        answers: [
          "Бронированные кабели в свинцовой, алюминиевой или поливинилхлоридной оболочке",
          "Силовые и контрольные кабели с проволочной броней в свинцовой, алюминиевой или поливинилхлоридной оболочке с обедненной или нестекающей пропиткой изоляции",
          "Кабели с резиновой оболочкой",
          "Бронированные кабели в поливинилхлоридной оболочке",
        ],
        correctAnswers: [
          "Силовые и контрольные кабели с проволочной броней в свинцовой, алюминиевой или поливинилхлоридной оболочке с обедненной или нестекающей пропиткой изоляции",
        ],
      },
      {
        code: "20402106",
        text: "Какую защиту должны иметь трансформаторы подземных электрических сетей до 1000 В?",
        answers: [
          "Защиту от токов короткого замыкания",
          "Защиту от токов короткого замыкания, от перегрузки, нулевую",
          "Защиту от опасных токов утечки на землю",
          "Защиту от блуждающих токов",
        ],
        correctAnswers: ["Защиту от токов короткого замыкания"],
      },
      {
        code: "20402107",
        text: "В каком случае в камерах электроустановок, расположенных в подземных выработках, должно быть два выхода?",
        answers: [
          "В любом случае",
          "В случае если организовано постоянное дежурство бригады электромонтеров",
          "В случае если камера длиной более 10 м",
          "В случае если камера длиной более 5 м",
        ],
        correctAnswers: ["В случае если камера длиной более 10 м"],
      },
      {
        code: "20402108",
        text: "Какой ширины должны быть проходы вокруг распределительных устройств?",
        answers: [
          "Не менее 0,7 м",
          "Не менее 0,8 м",
          "Не менее 0,5 м",
          "Не менее 0,6 м",
        ],
        correctAnswers: ["Не менее 0,8 м"],
      },
      {
        code: "20402109",
        text: "В какой цвет окрашиваются заземляющие проводники?",
        answers: [
          "В белый цвет",
          "В желтый цвет",
          "В черный цвет",
          "В голубой цвет",
        ],
        correctAnswers: ["В черный цвет"],
      },
      {
        code: "20402110",
        text: "Какую группу по электробезопасности должен иметь работник, использующий ручной электроинструмент в сухих помещениях и выработках?",
        answers: [
          "Не ниже II группы",
          "Не ниже III группы",
          "Не ниже IV группы",
        ],
        correctAnswers: ["Не ниже II группы"],
      },
      {
        code: "20402111",
        text: "На какое напряжение должны быть рассчитаны ручные переносные светильники, используемые в подземных условиях?",
        answers: [
          "Не более 220 В",
          "Не более 42 В",
          "Не более 12 В",
          "На более 6 В",
        ],
        correctAnswers: ["Не более 12 В"],
      },
      {
        code: "20402112",
        text: "Для каких объектов должен разрабатываться план мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Для тоннелей протяженностью более 2 км",
          "Для выработок под реками или другими водными преградами",
          "Для подземных объектов, сооружаемых в неустойчивых грунтах",
          "Для каждого строящегося подземного объекта",
        ],
        correctAnswers: ["Для каждого строящегося подземного объекта"],
      },
      {
        code: "20402113",
        text: "В какие сроки  утверждается план мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Не позднее 30 дней до ввода его в действие",
          "В день ввода его в действие",
          "Не позднее 15 дней до ввода его в действие",
          "В течение дня после проведения учебной тревоги",
        ],
        correctAnswers: ["Не позднее 15 дней до ввода его в действие"],
      },
      {
        code: "20402114",
        text: "На какой срок составляется план мероприятий по локализации и ликвидации последствий аварий для объектов, на которых ведутся горные работы в подземных условиях?",
        answers: ["На 1 квартал", "На 6 месяцев", "На 1 год", "На 1,5 года"],
        correctAnswers: ["На 6 месяцев"],
      },
      {
        code: "20402115",
        text: "Кто утверждает план мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Руководитель (заместитель руководителя) организации, эксплуатирующей объект, либо руководитель обособленного подразделения юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
          "Руководитель отдела промышленной безопасности",
          "Командир профессиональной аварийно-спасательной службы (формирования)",
        ],
        correctAnswers: [
          "Руководитель (заместитель руководителя) организации, эксплуатирующей объект, либо руководитель обособленного подразделения юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
        ],
      },
      {
        code: "20402116",
        text: "Когда проводится ознакомление с планом мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "При проведении вводного инструктажа",
          "Только при проведении первичного инструктажа на рабочем месте",
          "Только при проведении внепланового инструктажа",
          "При проведении первичного, повторного, внепланового инструктажей",
        ],
        correctAnswers: [
          "При проведении первичного, повторного, внепланового инструктажей",
        ],
      },
      {
        code: "20402117",
        text: "Кто является ответственным за организацию учета лиц, спустившихся и вышедших из горных выработок?",
        answers: [
          "Руководитель организации",
          "Технический руководитель шахты",
          "Специалист по промышленной безопасности",
          "Начальник участка",
        ],
        correctAnswers: ["Руководитель организации"],
      },
      {
        code: "20402118",
        text: "Каким должно быть количество самоспасателей в местах группового хранения?",
        answers: [
          "Количество самоспасателей должно соответствовать численности работающих в рабочей смене",
          "Количество самоспасателей должно соответствовать численности работающих на объекте в наиболее многочисленной смене",
          "Количество самоспасателей должно превышать численность работающих на объекте в наиболее многочисленной смене не менее чем на 10 %",
          "Количество самоспасателей должно быть согласовано с командиром профессиональной аварийно-спасательной службы (формирования)",
        ],
        correctAnswers: [
          "Количество самоспасателей должно превышать численность работающих на объекте в наиболее многочисленной смене не менее чем на 10 %",
        ],
      },
      {
        code: "20402119",
        text: "С какой периодичностью должна проводиться проверка исправности самоспасателей, закрепленных индивидуально?",
        answers: [
          "1 раз в год",
          "Ежемесячно",
          "Ежеквартально",
          "1 раз в полгода",
        ],
        correctAnswers: ["Ежемесячно"],
      },
      {
        code: "20402120",
        text: "С какой периодичностью должна проводиться проверка состояния средств противоаварийной защиты?",
        answers: [
          "Не реже 1 раза в 1,5 года",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 \n      раза в год",
        ],
        correctAnswers: ["Не реже 1 раза в 6 месяцев"],
      },
      {
        code: "20402121",
        text: "Кто несет ответственность за своевременное и правильное составление плана мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Руководитель организации",
          "Технический руководитель организации и командир профессиональной аварийно-спасательной службы (формирования)",
          "Специалист по промышленной безопасности",
          "Начальник участка",
        ],
        correctAnswers: [
          "Технический руководитель организации и командир профессиональной аварийно-спасательной службы (формирования)",
        ],
      },
      {
        code: "20402122",
        text: "Каким должно быть расстояние от выхода из подземных выработок до места хранения пожароопасных веществ и материалов?",
        answers: [
          "Расстояние должно быть рассчитано с учетом господствующего направления ветра",
          "Не менее 50 м",
          "Не менее 25 м",
          "Не менее 10 м",
        ],
        correctAnswers: ["Не менее 50 м"],
      },
      {
        code: "20402123",
        text: "Какой радиус должен быть у границ опасной зоны при проведении взрывопожароопасных работ?",
        answers: [
          "Не менее 20 м",
          "Не менее 30 м",
          "Не менее 50 м",
          "Не менее 10 м",
        ],
        correctAnswers: ["Не менее 50 м"],
      },
      {
        code: "20402124",
        text: "В каких местах должны размещаться первичные средства пожаротушения  в горизонтальных выработках с огнестойкой обделкой?",
        answers: [
          "Не дальше 50 м от входа в камеры",
          "Через каждые 300 м",
          "Через каждые 500 м при наличии горючей крепи",
          "Не дальше 50 м от груди забоя",
        ],
        correctAnswers: ["Через каждые 300 м"],
      },
      {
        code: "20402125",
        text: "Чем должно быть укомплектовано каждое место размещения первичных средств пожаротушения?",
        answers: [
          "Одним огнетушителем",
          "Противопожарным полотном",
          "Лопатой и ящиком с песком вместимостью 0,1 м3",
          "Не менее чем двумя огнетушителями и ящиком с песком вместимостью 0,2 м3",
        ],
        correctAnswers: [
          "Не менее чем двумя огнетушителями и ящиком с песком вместимостью 0,2 м3",
        ],
      },
      {
        code: "20402126",
        text: "Какие из перечисленных документов должны прилагаться к плану мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Список участников ликвидации аварий и аксонометрическая схема проветривания горных выработок",
          "Обязанности дежурного по шахте",
          "Список лиц, участвующих в ликвидации аварий, и аксонометрическая схема проветривания горных выработок",
          "Распределение обязанностей между участвующими в ликвидации аварий, порядок их действий и список должностных лиц и учреждений, которые должны быть немедленно извещены об аварии",
        ],
        correctAnswers: [
          "Распределение обязанностей между участвующими в ликвидации аварий, порядок их действий и список должностных лиц и учреждений, которые должны быть немедленно извещены об аварии",
        ],
      },
      {
        code: "20402127",
        text: "Какие приложения следует приобщить к плану мероприятий по локализации и ликвидации последствий аварий при его согласовании и утверждении?",
        answers: [
          "Только акт о расчетном и фактическом времени выхода людей в изолирующих самоспасателях на свежую струю из наиболее отдаленных горных выработок шахты",
          "Только акт о состоянии запасных выходов из очистных забоев, участков и шахты и о пригодности их для выхода людей и прохода горноспасателей в респираторах",
          "Только акт проверки состояния насосных станций, водонепроницаемых перемычек и других средств по предотвращению затопления горных выработок",
          "Только акт проверки исправности действия реверсивных устройств на главных вентиляционных установках с пропуском реверсированной воздушной струи по горным выработкам",
          "Все перечисленные документы, включая акт проверки наличия и исправности противопожарных средств и оборудования",
        ],
        correctAnswers: [
          "Все перечисленные документы, включая акт проверки наличия и исправности противопожарных средств и оборудования",
        ],
      },
      {
        code: "20402128",
        text: "У кого должны храниться выписки из плана мероприятий по локализации и ликвидации последствий аварий, указывающие пути выхода с конкретного участка шахты?",
        answers: [
          "У заместителя технического руководителя объекта подземного строительства",
          "У горного диспетчера",
          "У начальника объекта подземного строительства",
          "У начальника соответствующего участка",
        ],
        correctAnswers: ["У начальника соответствующего участка"],
      },
      {
        code: "20402129",
        text: "Что из перечисленного должно быть предусмотрено планом мероприятий по локализации и ликвидации последствий аварий на ОПО?",
        answers: [
          "Только организация материально-технического, инженерного и финансового обеспечения операций по локализации и ликвидации аварий на объекте",
          "Только порядок обеспечения постоянной готовности сил и средств к локализации и ликвидации последствий аварий на объекте с указанием организаций, которые несут ответственность за поддержание этих сил и средств в установленной степени готовности",
          "Только первоочередные действия при получении сигнала об аварии на объекте",
          "Только система взаимного обмена информацией между организациями - участниками локализации и ликвидации последствий аварий на объекте",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20402130",
        text: "Как следует выводить людей из горных выработок, расположенных до очага пожара?",
        answers: [
          "Навстречу свежей струе воздуха к выходу на поверхность",
          "В изолирующих самоспасателях кратчайшим путем в выработки со свежей струей воздуха и далее на поверхность",
          "В изолирующих самоспасателях к выходу на поверхность",
        ],
        correctAnswers: [
          "Навстречу свежей струе воздуха к выходу на поверхность",
        ],
      },
      {
        code: "20402131",
        text: "Какой вентиляционный режим должен выбираться при авариях?",
        answers: [
          "Только нормальный вентиляционный режим",
          "Только реверсивный вентиляционный режим",
          "Вентиляционный режим с учетом жизнеобеспечения максимального количества людей, находящихся в горных выработках",
        ],
        correctAnswers: [
          "Вентиляционный режим с учетом жизнеобеспечения максимального количества людей, находящихся в горных выработках",
        ],
      },
      {
        code: "20402132",
        text: "С какой периодичностью должна проверяться исправность сигнализации оповещения об аварии с регистрацией результатов проверок?",
        answers: [
          "Не реже 1 раза в неделю",
          "Не реже 1 раза в 2 недели",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в квартал",
        ],
        correctAnswers: ["Не реже 1 раза в неделю"],
      },
      {
        code: "20402133",
        text: "На кого возлагается руководство работами по локализации и ликвидации последствий аварий?",
        answers: [
          "На технического руководителя (главного инженера)",
          "На начальника аварийно-спасательной службы",
          "На начальника участка",
        ],
        correctAnswers: ["На технического руководителя (главного инженера)"],
      },
      {
        code: "20402134",
        text: "На основании каких данных составляется оперативный план по локализации и ликвидации последствий аварий?",
        answers: [
          "Только на основании поступающей на командный пункт информации",
          "Только на основании анализа аварийной обстановки",
          "Только на основании прогноза развития аварии",
          "На основании всех перечисленных данных",
        ],
        correctAnswers: ["На основании всех перечисленных данных"],
      },
      {
        code: "20402135",
        text: "В каких случаях в подземных горных выработках, в которых имеется взрывоопасная среда при наличии очагов горения или существует угроза прорыва воды (пульпы), работы по поиску и спасению людей не проводятся или прекращаются?",
        answers: [
          "Только если рудничная атмосфера в выработках аварийного участка находилась в состоянии, непригодном для дыхания, в течение времени, равного десятикратному сроку времени защитного действия СИЗОД",
          "Только если в местах предполагаемого нахождения людей в течение не менее 24 часов температура воздуха составляла 100 °C и более",
          "Только если люди, находящиеся в аварийных горных выработках, признаны погибшими в установленном порядке",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20402136",
        text: "Чем оснащается КП (командный пункт)?",
        answers: [
          "Только аппаратурой телефонной и (или) радиосвязи с местами ведения работ по локализации и ликвидации последствий аварии",
          "Только электронно-вычислительными машинами",
          "Только множительной и копировальной техникой",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20402137",
        text: "Какими способами осуществляется тушение подземных пожаров?",
        answers: [
          "Только активным тушением",
          "Только изоляцией горных выработок, в которых действует пожар",
          "Только комбинированным способом",
          "Всеми перечисленными",
        ],
        correctAnswers: ["Всеми перечисленными"],
      },
      {
        code: "20402138",
        text: "Тушение каких рудников в подземных горных выработках запрещается производить водой?",
        answers: [
          "Угольных рудников",
          "Асбестовых рудников",
          "Соляных и калийных рудников",
        ],
        correctAnswers: ["Соляных и калийных рудников"],
      },
      {
        code: "20402139",
        text: "Из какого минимального количества человек должно состоять горноспасательное отделение или отделение вспомогательной горноспасательной команды при ведении горноспасательных работ в непригодной для дыхания атмосфере при работе на открытых горных выработках?",
        answers: [
          "Из 3 человек",
          "Из 2 человек",
          "Из 5 человек",
          "Из 6 человек",
        ],
        correctAnswers: ["Из 3 человек"],
      },
      {
        code: "20402140",
        text: "Какое из перечисленных требований к тушению пожаров в вертикальных горных выработках указано верно?",
        answers: [
          "Пожары в вертикальных горных выработках тушатся активным способом снизу вверх",
          "При тушении активным способом и локализации пожара в вертикальном стволе с исходящей вентиляционной струей или в надшахтном здании этого ствола допускается изменение направления вентиляционной струи",
          "Нахождение людей в вертикальных горных выработках во время тушения в них пожара допускается при условии выполнения мер, исключающих опрокидывание вентиляционной струи",
          "При тушении активным способом и локализации пожаров в вертикальных горных выработках с восходящей струей воздуха с применением воды, подаваемой с поверхности, выполняются меры, исключающие опрокидывание вентиляционной струи",
        ],
        correctAnswers: [
          "При тушении активным способом и локализации пожаров в вертикальных горных выработках с восходящей струей воздуха с применением воды, подаваемой с поверхности, выполняются меры, исключающие опрокидывание вентиляционной струи",
        ],
      },
      {
        code: "20402141",
        text: "Каково максимальное допустимое избыточное давление в кессоне при ведении горноспасательных работ в автономных изолирующих дыхательных аппаратах?",
        answers: ["0,2 МПа", "0,1 МПа", "0,3 МПа", "0,4 МПа"],
        correctAnswers: ["0,2 МПа"],
      },
      {
        code: "20402142",
        text: "Какую информацию не включает в себя общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
          "Характеристику объектов, в отношении которых разрабатывается план мероприятий",
          "Возможные сценарии возникновения и развития аварий на объектах, а также источники (места) возникновения аварий",
          "Характеристики аварийности, присущей объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах",
        ],
        correctAnswers: [
          "Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
        ],
      },
      {
        code: "20402143",
        text: "Какова максимальная продолжительность пребывания работников ПАСС(Ф) и членов вспомогательной горноспасательной команды в непригодной для дыхания атмосфере с применением дыхательных аппаратов?",
        answers: ["4 часа", "3 часа", "2 часа", "5 часов"],
        correctAnswers: ["4 часа"],
      },
      {
        code: "20402144",
        text: "Что из перечисленного входит в должностные обязанности руководителя горноспасательных работ (РГСР)?",
        answers: [
          "Только обеспечение прибытия горноспасательных отделений и других сил и средств ПАСС(Ф) для ведения горноспасательных работ в количестве и в сроки, предусмотренные ПЛА",
          "Только получение заданий от РЛА на проведение горноспасательных работ",
          "Только проведение анализа полученной от РЛА информации о происшедшей аварии",
          "Только организация ведения оперативного журнала ПАСС(Ф)",
          "Только организация ведения горноспасательных работ",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20402145",
        text: "В каком случае эксплуатирующая организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах на несколько опасных объектов?",
        answers: [
          "В случае если два и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
          "В случае если это регламентировано внутренней документацией организации",
          "В случае если объекты зарегистрированы в государственном реестре опасных производственных объектов",
          "Ни в каком случае, план мероприятий разрабатывается на каждый опасный объект отдельно",
        ],
        correctAnswers: [
          "В случае если два и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
        ],
      },
      {
        code: "20402146",
        text: "В каком случае инертизация рудничной атмосферы в изолируемом пространстве может быть начата до окончания возведения взрывоустойчивых изоляционных перемычек?",
        answers: [
          "В случае если объем подаваемого инертного газа превышает расход воздуха, поступающего на пожарный участок",
          "В случае если продолжительность подачи инертного газа превышает допустимую",
          "В случае если это определено оперативным планом",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "В случае если объем подаваемого инертного газа превышает расход воздуха, поступающего на пожарный участок",
        ],
      },
      {
        code: "20402147",
        text: "Каково номинальное время защитного действия автономных изолирующих дыхательных аппаратов (ДА) со сжатым воздухом на объектах открытых горных работ?",
        answers: [
          "Не менее одного часа",
          "Не менее 50 минут",
          "Не менее 45 минут",
          "Не менее 30 минут",
        ],
        correctAnswers: ["Не менее одного часа"],
      },
      {
        code: "20402148",
        text: "Кто определяет места возведения изоляционных перемычек, предназначенных для изоляции пожарного участка, их конструкцию, последовательность и сроки возведения?",
        answers: [
          "Руководитель работ по ликвидации аварии",
          "Руководитель горноспасательных работ",
          "Технический руководитель (главный инженер) ОПО",
          "Ответственный за пожарную безопасность ОПО",
          "Все варианты неверны",
        ],
        correctAnswers: ["Руководитель работ по ликвидации аварии"],
      },
      {
        code: "20402149",
        text: "При каком угле наклона наклонной горной выработки пожар в ней тушится активным способом дистанционно?",
        answers: [
          "При угле наклона более 20°",
          "При угле наклона более 15°",
          "При угле наклона более 10°",
          "При любом угле наклона наклонной горной выработки",
        ],
        correctAnswers: ["При угле наклона более 20°"],
      },
      {
        code: "20402150",
        text: "До какой минимальной температуры разрешается ведение горноспасательных работ в горных выработках с непригодной для дыхания рудничной атмосферой?",
        answers: [
          "До температуры минус 20 °C",
          "До температуры минус 15 °C",
          "До температуры минус 10 °C",
          "До температуры минус 8 °C",
        ],
        correctAnswers: ["До температуры минус 20 °C"],
      },
      {
        code: "20402151",
        text: "Какое из перечисленных действий допускается при ведении горноспасательных работ в подземных горных выработках в зоне высоких температур с непригодной для дыхания рудничной атмосферой?",
        answers: [
          "Только ведение горноспасательных работ в зоне высоких температур с непригодной для дыхания атмосферой при отсутствии связи горноспасательного отделения, ведущего горноспасательные работы, с руководителем горноспасательных работ или с горноспасательным отделением, находящимся на подземной горноспасательной базе, при условии, что эти работы связаны со спасением людей",
          "Только привлечение членов вспомогательной горноспасательной команды для ведения горноспасательных работ в зоне высоких температур с непригодной для дыхания атмосферой",
          "Только ведение горноспасательных работ, не связанных со спасением людей, в зоне высоких температур с непригодной для дыхания атмосферой без средств противотепловой индивидуальной защиты при температуре выше 50 °C",
          "Все перечисленные действия",
        ],
        correctAnswers: [
          "Только ведение горноспасательных работ в зоне высоких температур с непригодной для дыхания атмосферой при отсутствии связи горноспасательного отделения, ведущего горноспасательные работы, с руководителем горноспасательных работ или с горноспасательным отделением, находящимся на подземной горноспасательной базе, при условии, что эти работы связаны со спасением людей",
        ],
      },
      {
        code: "20402152",
        text: "Кто утверждает планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.",
        answers: [
          "Руководители (заместители руководителей) организаций, эксплуатирующих объекты",
          "Руководители обособленных подразделений юридических лиц",
          "Руководители профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований",
          "Инспектор Ростехнадзора",
        ],
        correctAnswers: [
          "Руководители (заместители руководителей) организаций, эксплуатирующих объекты",
          "Руководители обособленных подразделений юридических лиц",
        ],
      },
      {
        code: "20402153",
        text: "Какое из перечисленных требований к оперативным планам по локализации и ликвидации последствий аварий указано неверно?",
        answers: [
          "Оперативный план утверждает руководитель горноспасательных работ",
          "Оперативные планы разрабатываются до окончания ведения горноспасательных работ",
          "Последующие оперативные планы (N 2, 3 и т. д.) разрабатываются в случаях, когда мероприятия предыдущего оперативного плана реализованы или требуется их корректировка",
          "Оперативный план не должен содержать ссылок на пункты ранее разработанных оперативных планов",
        ],
        correctAnswers: [
          "Оперативный план утверждает руководитель горноспасательных работ",
        ],
      },
      {
        code: "20402154",
        text: "При каких условиях допускается ведение горноспасательных работ в тупиковой выработке?",
        answers: [
          "При условии тушения пожара в выработанном пространстве подачей огнетушащих веществ дистанционно, при этом люди должны находиться на расстоянии от места возникновения аварии, далее которого отсутствует вероятность воздействия опасных факторов аварии на человека",
          "При условии отсутствия информации о содержании в выработке горючих газов",
          "При условии отсутствия в выработке пострадавших",
          "При условии тушения пожара в непроветриваемых тупиковых горных выработках",
        ],
        correctAnswers: [
          "При условии тушения пожара в выработанном пространстве подачей огнетушащих веществ дистанционно, при этом люди должны находиться на расстоянии от места возникновения аварии, далее которого отсутствует вероятность воздействия опасных факторов аварии на человека",
        ],
      },
      {
        code: "20402155",
        text: "На кого возлагается персональная ответственность за наличие и качество материалов, находящихся в противопожарных подземных и поверхностных складах, в соответствии с планом ликвидации аварий при ведении работ в подземных условиях?",
        answers: [
          "На начальника шахты",
          "На технического руководителя эксплуатирующей организации",
          "На командира горноспасательного формирования",
          "На специально назначенное лицо технического надзора",
        ],
        correctAnswers: ["На начальника шахты"],
      },
      {
        code: "20402156",
        text: "Где должны находиться лица, на которых возлагается ответственность за руководство ликвидацией аварий в шахте в данную смену?",
        answers: [
          "На поверхности в командном пункте",
          "Непосредственно в шахте",
          "На ближайшем к шахтному стволу участке подземных работ",
          "Не регламентируется",
        ],
        correctAnswers: ["На поверхности в командном пункте"],
      },
      {
        code: "20402157",
        text: "Каким документом устанавливаются порядок и обстоятельства, при которых допускается отстранить руководителя работ по ликвидации аварии и руководителя горноспасательных работ и назначить другое должностное лицо?",
        answers: [
          "Распорядительным документом, утверждаемым руководителями ОПО и ПАСС(Ф)",
          "Распорядительным документом, утверждаемым представителем территориального органа Ростехнадзора",
          "Распорядительным документом, утверждаемым представителем Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "Инструкцией по локализации и ликвидации последствий аварий на опасных производственных объектах, на которых ведутся горные работы",
        ],
        correctAnswers: [
          "Распорядительным документом, утверждаемым руководителями ОПО и ПАСС(Ф)",
        ],
      },
      {
        code: "20402158",
        text: "Что предусматривается в плане ликвидации аварий при ведении работ в подземных условиях?",
        answers: [
          "Только мероприятия по спасению людей, пути вывода людей, застигнутых аварией, из зоны опасного воздействия, связанной с аварией",
          "Только мероприятия по ликвидации аварий и предупреждению их развития",
          "Только действия военизированных горноспасательных частей (ВГСЧ) и вспомогательных горноспасательных команд (ВГК) в начальной стадии возникновения аварий",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20402159",
        text: "Кто является ответственным руководителем работ по спасению людей и ликвидации аварий до момента прибытия главного инженера шахты?",
        answers: [
          "Горный диспетчер",
          "Командир горноспасательного формирования",
          "Начальник участка аэрологической безопасности",
          "Дежурный работник горноспасательного формирования",
        ],
        correctAnswers: ["Горный диспетчер"],
      },
      {
        code: "20402160",
        text: "Какая документация должна находиться на командном пункте?",
        answers: [
          "Только журналы ОПО и ПАСС(Ф)",
          "Только план ликвидации аварий",
          "Только оперативные планы, текстовая и графическая документация, разрабатываемая в период локализации и ликвидации последствий аварии",
          "Вся перечисленная документация",
        ],
        correctAnswers: ["Вся перечисленная документация"],
      },
      {
        code: "20402161",
        text: "Кто из перечисленных лиц обеспечивает подачу сжатого воздуха или воды к месту аварии в случаях, предусмотренных ПЛА?",
        answers: [
          "Руководитель энергомеханической службы ОПО",
          "Начальник службы (участка), обеспечивающей (обеспечивающего) аэрологическую безопасность подземных горных выработок",
          "Начальник участка, на котором произошла авария",
          "Cпециалист ОПО, ответственный за учет и выдачу индивидуальных головных светильников, самоспасателей, газоанализаторов",
        ],
        correctAnswers: ["Руководитель энергомеханической службы ОПО"],
      },
      {
        code: "20402162",
        text: "Какое из перечисленных мероприятий не проводится при ликвидации последствий горного удара, обрушения пород, оползня?",
        answers: [
          "Мониторинг концентрации индикаторных пожарных газов",
          "Определение возможности развития обрушения (оползня) и безопасных способов выполнения горноспасательных работ",
          "Организация работы по разборке обрушившейся горной массы и (или) проведение поисковых выработок из возможно большего числа мест",
          "Установление связи с застигнутыми аварией людьми, не имеющими возможности выйти из зоны аварии",
        ],
        correctAnswers: ["Мониторинг концентрации индикаторных пожарных газов"],
      },
      {
        code: "20402163",
        text: "Кем утверждается план ликвидации аварий при ведении работ в подземных условиях?",
        answers: [
          "Техническим руководителем эксплуатирующей организации (главным инженером организации)",
          "Руководителем эксплуатирующей организации",
          "Командиром горноспасательного формирования",
          "Начальником шахты",
        ],
        correctAnswers: [
          "Техническим руководителем эксплуатирующей организации (главным инженером организации)",
        ],
      },
      {
        code: "20402164",
        text: "В каком приоритетном порядке предварительно определяется вид происшедшей аварии при отсутствии информации, позволяющей достоверно определить ее вид, в подземных горных выработках?",
        answers: [
          "Взрыв, внезапный выброс, пожар, горный удар, обрушение",
          "Взрыв, пожар, внезапный выброс, горный удар, обрушение",
          "Взрыв, горный удар, обрушение, внезапный выброс, пожар",
          "Взрыв, горный удар, внезапный выброс, пожар, обрушение",
        ],
        correctAnswers: [
          "Взрыв, внезапный выброс, пожар, горный удар, обрушение",
        ],
      },
      {
        code: "20402165",
        text: "При каком виде аварии члены вспомогательной горноспасательной команды устанавливают связь с застигнутыми аварией людьми, организуют их спасение, предварительно усилив крепь, тем самым исключив возможные обрушения?",
        answers: [
          "При обрушении в горной выработке",
          "При пожаре в тупиковой выработке",
          "При взрыве метана и (или) угольной пыли",
          "При внезапном выбросе угля (породы)",
        ],
        correctAnswers: ["При обрушении в горной выработке"],
      },
      {
        code: "20402166",
        text: "Каким образом распределяется максимальная продолжительность (время) непрерывного пребывания работников ПАСС(Ф) в зоне высоких температур при передвижении по подземным горным выработкам с непригодной для дыхания атмосферой?",
        answers: [
          'Одна треть на передвижение в направлении "туда" и две трети - в направлении "обратно"',
          'Две трети на передвижение в направлении "туда" и одна треть - в направлении "обратно"',
          'Половина на передвижение в направлении "туда" и половина - в направлении "обратно"',
          "По решению технического руководителя (главного инженера) ОПО",
        ],
        correctAnswers: [
          'Одна треть на передвижение в направлении "туда" и две трети - в направлении "обратно"',
        ],
      },
      {
        code: "20402167",
        text: "В каком случае допускается нахождение людей, занятых тушением подземного пожара активным способом, в горных выработках с исходящей от пожара струей воздуха?",
        answers: [
          "Только когда пожар находится вблизи выработок со свежей струей воздуха",
          "Только в случае выполнения мер, предотвращающих обрушение (высыпание) горных пород",
          "Только в случае тушения пожара водой",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "Только когда пожар находится вблизи выработок со свежей струей воздуха",
        ],
      },
      {
        code: "20402168",
        text: "В каком из перечисленных случаев пересматриваются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.",
        answers: [
          "Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий",
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
          "Не позднее 40 дней после внесения изменений в системы управления технологическими процессами на объекте",
          "В случае назначения нового руководителя организации",
          "Не позднее 15 дней после внесения изменений в применяемые при осуществлении производственного контроля за соблюдением требований промышленной безопасности на объекте методики (методы) измерений или типы средств измерений",
        ],
        correctAnswers: [
          "Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий",
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
        ],
      },
      {
        code: "20402169",
        text: "Какова минимально допустимая численность горноспасательного отделения или отделения вспомогательной горноспасательной команды (ВГК) для ведения горноспасательных работ в непригодной для дыхания атмосфере при работе в подземных горных выработках?",
        answers: [
          "Пять человек",
          "Два человека",
          "Три человека",
          "Шесть человек",
        ],
        correctAnswers: ["Пять человек"],
      },
      {
        code: "20402170",
        text: "Кем в организации утверждается перечень опасных зон?",
        answers: [
          "Работником маркшейдерской службы",
          "Начальником службы производственного контроля",
          "Техническим руководителем шахты",
          "Руководителем организации",
        ],
        correctAnswers: ["Техническим руководителем шахты"],
      },
      {
        code: "20402171",
        text: "При каком расстоянии до опасной зоны участковый маркшейдер должен делать замеры и оповещения о размерах целика после каждой заходки главному инженеру и начальнику участка?",
        answers: ["7 метров", "10 метров", "12 метров", "5 метров"],
        correctAnswers: ["7 метров"],
      },
      {
        code: "20402172",
        text: "Кто выдает разрешение на производство разбивочных и основных маркшейдерских работ вблизи забоев подземных выработок?",
        answers: [
          "Главный маркшейдер",
          "Начальник участка",
          "Горный мастер",
          "Технический руководитель шахты",
        ],
        correctAnswers: ["Горный мастер"],
      },
      {
        code: "20402173",
        text: "На каком расстоянии должны быть расположены первичные средства пожаротушения?",
        answers: [
          "Не дальше 50 м от входа в камеры",
          "Через каждые 300 м",
          "При наличии горючей крепи - через каждые 300 м",
          "Не дальше 50 м от груди забоя",
        ],
        correctAnswers: ["Через каждые 300 м"],
      },
      {
        code: "20402174",
        text: "При каком значении прочности бетона допускается начинать разработку в устойчивых породах?",
        answers: [
          "Не менее 60 %",
          "Не менее 65 %",
          "Не менее 70 %",
          "Не менее 75 %",
        ],
        correctAnswers: ["Не менее 75 %"],
      },
      {
        code: "20402175",
        text: "Кем должна осуществляться проверка состояния предусмотренных планом ликвидации аварии материальных и технических средств для осуществления мероприятий по спасению людей и ликвидации аварий перед согласованием планов ликвидации аварий на новое полугодие? Укажите все правильные ответы.",
        answers: [
          "Работниками шахты",
          "Военизированной горноспасательной частью",
          "Руководителем аварийно-спасательного формирования",
          "Представителем Ростехнадзора",
        ],
        correctAnswers: [
          "Работниками шахты",
          "Военизированной горноспасательной частью",
        ],
      },
      {
        code: "20402176",
        text: "Какие надписи должен иметь титульный лист плана ликвидации аварии?",
        answers: [
          "Только надпись главного инженера организации (предприятия) с датой утверждения",
          "Только надпись командира ВГСЧ с указанием даты",
          "Только подпись главного инженера шахты",
          "Все ответы неверны",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20402177",
        text: "Кем прорабатывается план ликвидации аварий под расписку?",
        answers: [
          "Специализированной сторонней организацией",
          "Главным инженером шахты с должностными лицами (техническим надзором) шахты",
          "Главным инженером шахты с инспектором Ростехнадзора",
          "Главным инженером шахты с командным составом ВГСЧ",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Главным инженером шахты с должностными лицами (техническим надзором) шахты",
        ],
      },
      {
        code: "20402178",
        text: "Какая принимается скорость передвижения людей в изолирующих самоспасателях по загазованным горизонтальным выработкам высотой 1,8 - 2,0 м?",
        answers: ["30 м/мин", "40 м/мин", "50 м/мин", "60 м/мин"],
        correctAnswers: ["60 м/мин"],
      },
      {
        code: "20402179",
        text: "Какая принимается скорость передвижения людей в изолирующих самоспасателях по загазованным наклонным выработкам с углом наклона 10° при подъеме?",
        answers: ["45 м/мин", "40 м/мин", "35 м/мин", "30 м/мин"],
        correctAnswers: ["45 м/мин"],
      },
      {
        code: "20402180",
        text: "Кем подписывается оперативная часть плана ликвидации аварий в конце последней позиции с указанием даты составления плана?",
        answers: [
          "Главным инженером шахты",
          "Командиром ВГСЧ",
          "Начальником ПВС",
          "Все ответы неверны",
        ],
        correctAnswers: ["Главным инженером шахты"],
      },
      {
        code: "20402181",
        text: "Что из перечисленного наносится на аксонометрических схемах и погоризонтных планах вентиляционных горных выработок в виде кружков диаметром 13 мм?",
        answers: [
          "Позиции, соответствующие оперативной части плана ликвидации аварий",
          "Главные вентиляционные установки",
          "Места размещения самоспасателей, огнетушителей",
          "Места размещения телефонов",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Позиции, соответствующие оперативной части плана ликвидации аварий",
        ],
      },
      {
        code: "20402182",
        text: "Что из перечисленного должно предусматриваться при прорыве в горные выработки воды, пульпы, реагентов?",
        answers: [
          "Вывод всех людей из шахты на поверхность",
          "Вывод всех людей из угрожаемого участка",
          "Вывод всех людей из аварийного участка",
        ],
        correctAnswers: ["Вывод всех людей из шахты на поверхность"],
      },
      {
        code: "20402183",
        text: "При каких видах аварии необходимо предусматривать вызов служб МЧС России?",
        answers: [
          "При всех видах аварий, когда требуется оказание помощи людям",
          "При всех видах аварий, когда для ведения работ требуется соответствующее горноспасательное оборудование",
          "При землетрясениях",
          "При пожарах в надшахтных зданиях",
        ],
        correctAnswers: ["При землетрясениях"],
      },
      {
        code: "20402184",
        text: "Кем должен тщательно рассматриваться маршрут движения отделений ВГСЧ на вывод людей и ликвидацию аварий в каждом конкретном случае? Укажите все правильные ответы.",
        answers: [
          "Главным инженером шахты",
          "Командиром ВГСЧ",
          "Представителем МЧС России",
          "Представителем Ростехнадзора",
        ],
        correctAnswers: ["Главным инженером шахты", "Командиром ВГСЧ"],
      },
      {
        code: "20402185",
        text: "Что из перечисленного допускается не предусматривать в оперативной части соответствующего плана для ликвидации подземных пожаров в начальной стадии?",
        answers: [
          "Все ответы неверны",
          "Использование насосов и ставов труб",
          "Места установок временных перемычек",
          "Порядок и способы использования водоотливных, воздухопроводных и противопожарных (противопожарно-оросительных) трубопроводов",
          "Использование имеющегося на шахте оборудования для ликвидации возможных аварий",
        ],
        correctAnswers: ["Использование насосов и ставов труб"],
      },
      {
        code: "20402186",
        text: "Какое из перечисленных первоочередных мероприятий плана ликвидации аварий рекомендуется располагать первым в части аварий, связанных с загазованностью выработок?",
        answers: [
          "Расстановку постов безопасности",
          "Использование подземного транспорта",
          "Установление аварийного режима работы главных вентиляционных установок",
          "Вызов ВГСЧ",
        ],
        correctAnswers: [
          "Установление аварийного режима работы главных вентиляционных установок",
        ],
      },
      {
        code: "20402187",
        text: "Кем составляется акт проверки исправности противопожарных средств и оборудования плана ликвидации аварий?",
        answers: [
          "Комиссией в составе начальника шахты, начальника ПВС и инспектора Ростехнадзора",
          "Комиссией в составе начальника шахты, начальника ПВС, механика шахты и командира ВГСЧ, обслуживающей данную шахту",
          "Комиссией в составе главного инженера шахты, начальника ПВС и командира ВГСЧ, обслуживающей данную шахту",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Комиссией в составе начальника шахты, начальника ПВС, механика шахты и командира ВГСЧ, обслуживающей данную шахту",
        ],
      },
      {
        code: "20402188",
        text: "Чье решение является обязательным для выполнения в случае разногласия между командиром ВГСЧ и ответственным руководителем работ при организации работ по ликвидации аварии?",
        answers: [
          "Ответственного руководителя работ",
          "Командира ВГСЧ",
          "Руководителя организации",
          "Экспертной комиссии",
          "Все ответы неверны",
        ],
        correctAnswers: ["Ответственного руководителя работ"],
      },
      {
        code: "20402189",
        text: "Кто имеет право письменным приказом отстранить ответственного руководителя работ и принять руководство на себя или возложить его на другое лицо из числа должностных лиц шахты?",
        answers: [
          "Командир ВГСЧ",
          "Начальник ПВС",
          "Начальник шахты",
          "Главный инженер организации (предприятия)",
          "Все ответы неверны",
        ],
        correctAnswers: ["Главный инженер организации (предприятия)"],
      },
      {
        code: "20402190",
        text: "Какие участки относятся к наиболее нагруженным на месторождениях, склонных к горным ударам?",
        answers: [
          "Только опасные зоны, выявленные в результате регионального прогноза",
          "Только участки рудного массива и массива горных пород в зоне опорного давления от очистных работ",
          "Только зоны литологических разновидностей пород",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20402191",
        text: "Чему должно быть равно минимальное расстояние между ближайшими боками параллельных выработок и камер различного назначения на удароопасных участках до глубины 1000 м относительно наибольшего размера d поперечного сечения большей выработки?",
        answers: ["6dS", "5dS", "4dS", "3dS", "Все ответы неверны"],
        correctAnswers: ["4dS"],
      },
      {
        code: "20402192",
        text: "В течение какого срока технический руководитель объекта с инженерными службами рассматривает случаи микроударов, толчков, стреляния, интенсивного заколообразования и шелушения при первом их появлении в целях установления причин и разработки мер борьбы с ними?",
        answers: [
          "В течение суток",
          "В течение 5 суток",
          "В течение 15 суток",
          "В течение месяца",
          "Все ответы неверны",
        ],
        correctAnswers: ["В течение суток"],
      },
      {
        code: "20402193",
        text: "В каком направлении должны ориентироваться основные горизонтальные и наклонные выработки околоствольных дворов, включая камеры?",
        answers: [
          "В направлении действия максимального горизонтального напряжения нетронутого массива",
          "В направлении действия минимального горизонтального напряжения нетронутого массива",
          "Не регламентируется",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "В направлении действия максимального горизонтального напряжения нетронутого массива",
        ],
      },
      {
        code: "20402194",
        text: "Куда должен сообщать технический руководитель организации о каждом случае горного удара и микроудара?",
        answers: [
          "В службу технического надзора",
          "В территориальный орган Ростехнадзора",
          "В диспетчерскую службу",
          "Во все перечисленные службы",
          "Все ответы неверны",
        ],
        correctAnswers: ["В территориальный орган Ростехнадзора"],
      },
      {
        code: "20402195",
        text: "Кто на каждом объекте, отрабатывающем месторождения, склонном и опасном по горным ударам, производит учет всех случаев горных ударов, микроударов и толчков, стреляний, интенсивного заколообразования и шелушения, результатов определения категорий удароопасности выработок, примененных мер по предотвращению горных ударов с оценкой их эффективности?",
        answers: [
          "Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении",
          "Технический руководитель эксплуатирующей объект ведения горных работ организации",
          "Служба прогноза и предотвращения горных ударов или маркшейдерско-геологическая служба",
        ],
        correctAnswers: [
          "Служба прогноза и предотвращения горных ударов или маркшейдерско-геологическая служба",
        ],
      },
      {
        code: "20402196",
        text: "В каком случае допускается нахождение людей, занятых тушением подземного пожара активным способом, в горных выработках с исходящей от пожара струей воздуха?",
        answers: [
          "Когда пожар находится вблизи выработок со свежей струей воздуха",
          "Когда пожар находится вдали от выработок со свежей струей воздуха",
          "Не регламентируется",
        ],
        correctAnswers: [
          "Когда пожар находится вблизи выработок со свежей струей воздуха",
        ],
      },
      {
        code: "20402197",
        text: "Какой категории по степени опасности участков горного массива вокруг выработок не существует?",
        answers: ['"Опасно"', '"Неопасно"', '"Чрезвычайно опасно"'],
        correctAnswers: ['"Чрезвычайно опасно"'],
      },
      {
        code: "20402198",
        text: "Чем должно быть регламентировано приведение горных выработок в неудароопасное состояние?",
        answers: [
          "Указаниями научно-исследовательского института",
          "Локальным прогнозом удароопасности",
          "Проектом, утвержденным председателем Комиссии (техническим руководителем эксплуатирующей объект ведения горных работ организации)",
          "Техническими условиями, разработанными специализированной организацией",
        ],
        correctAnswers: [
          "Проектом, утвержденным председателем Комиссии (техническим руководителем эксплуатирующей объект ведения горных работ организации)",
        ],
      },
      {
        code: "20402199",
        text: "Каким должно быть расстояние между стенками стволов при строительстве подземного объекта на участках недр, склонных и опасных по горным ударам?",
        answers: [
          "Не менее 35 м",
          "Не менее 40 м",
          "Не менее 45 м",
          "Не менее 50 м",
        ],
        correctAnswers: ["Не менее 50 м"],
      },
      {
        code: "20402200",
        text: "Какое положение не соответствует требованиям безопасности при проходке стволов?",
        answers: [
          "Пересечение выработок должно осуществляться под прямым или близким к нему углом",
          "При проходке стволов допускается отставание постоянной крепи от забоя не более одного диаметра ствола вчерне",
          'Образующийся при сбойке целик должен быть приведен при категории "Опасно" в неопасное состояние по всей площади',
          "Сбойка двух выработок на участках, склонных и опасных по горным ударам, с расстояния между забоями 15 м должна вестись двумя забоями",
        ],
        correctAnswers: [
          "Сбойка двух выработок на участках, склонных и опасных по горным ударам, с расстояния между забоями 15 м должна вестись двумя забоями",
        ],
      },
      {
        code: "20402201",
        text: "Из каких разделов состоит план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Из частных разделов",
          "Из специальных разделов",
          "Из разделов, предусмотренных требованиями федеральных норм и правил в области промышленной безопасности",
          "Из общих и специальных разделов",
        ],
        correctAnswers: ["Из общих и специальных разделов"],
      },
      {
        code: "20402202",
        text: "Что необходимо соблюдать при проектировании вскрытия, подготовки, систем разработки на участках недр, склонных и опасных по горным ударам?",
        answers: [
          "Только разделение месторождения на шахтные поля и порядок их отработки должны обеспечивать планомерное извлечение запасов по возможности без образования целиков, острых углов и выступов фронта очистных работ",
          "Только использование опережающей отработки защитных залежей (слоев), в том числе бурение разгрузочных скважин большого диаметра, расположение выработок в защищенных зонах",
          "Только сокращение применения систем разработки с открытым очистным пространством",
          "Только снижение количества горных выработок, проводимых вблизи фронта очистных работ, особенно в зонах опорного давления",
          "Только ограничение встречных и догоняющих фронтов очистных работ",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20402203",
        text: "В течение какого времени после внесения изменений в системы управления технологическими процессами на объекте должен быть пересмотрен план мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Не позднее 30 календарных дней",
          "Не позднее 15 календарных дней",
          "Не позднее 30 рабочих дней",
          "Не позднее 15 рабочих дней",
        ],
        correctAnswers: ["Не позднее 30 календарных дней"],
      },
      {
        code: "20402204",
        text: 'Каким образом участки горных выработок категории "Опасно" приводят в неудароопасное состояние?',
        answers: [
          "Путем создания в краевой части массива пород защитной зоны шириной n + 2 м, но не менее 2 м",
          "Путем создания в краевой части массива пород защитной зоны шириной не менее 1 м",
          "Путем создания в краевой части массива пород защитной зоны шириной n + 1 м, но не более 2 м",
          "Путем создания в краевой части массива пород защитной зоны шириной не более 1 м",
        ],
        correctAnswers: [
          "Путем создания в краевой части массива пород защитной зоны шириной n + 2 м, но не менее 2 м",
        ],
      },
      {
        code: "20402205",
        text: "В каком случае месторождение (объект строительства подземных сооружений) будет относиться к опасному по горным ударам?",
        answers: [
          "Только если имело место стреляние пород, интенсивное заколообразование, происходили толчки или горные удары на соседнем руднике (шахте) в пределах того же рудного тела и в аналогичных геологических условиях",
          "Только если на месторождении имело место интенсивное заколообразование",
          'Только если прогнозом при определении степени удароопасности массива горных пород локальными инструментальными методами выявлена категория "Опасно" (удароопасная)',
          "В любом из перечисленных случаев",
        ],
        correctAnswers: ["В любом из перечисленных случаев"],
      },
      {
        code: "20402206",
        text: "При выполнении каких из перечисленных профилактических мероприятий должно осуществляться проведение и поддержание выработок на месторождениях, на которых происходили горные удары?",
        answers: [
          "Только при создании устойчивых форм",
          "Только при бурении разгрузочных щелей и скважин",
          "Только при контурном взрывании",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20402207",
        text: "В каких случаях должен производиться контроль удароопасности в выработках?",
        answers: [
          'Только в случае изменений горно-геологической обстановки во время ведения горных работ в местах, где ранее была установлена категория "Опасно"',
          'Только в случае изменений горнотехнической обстановки во время ведения горных работ в местах, где ранее была установлена категория "Опасно"',
          "Только в случае появления внешних признаков удароопасности",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20402208",
        text: "Что из перечисленного содержится в специальном разделе плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий",
          "Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
          "Характеристика объекта, в отношении которого разрабатывается план мероприятий",
          "Характеристики аварийности, присущей объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах",
        ],
        correctAnswers: [
          "Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
        ],
      },
      {
        code: "20402209",
        text: "В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
          "В целях регламентации действий персонала при возникновении аварии",
          "В целях обеспечения соответствия объекта требованиям промышленной безопасности",
          "В целях регламентации действий подразделений муниципальной пожарной охраны при возникновении аварии",
        ],
        correctAnswers: [
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
        ],
      },
      {
        code: "20402210",
        text: "Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?",
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          "Только для ОПО I и II классов опасности",
          "Для всех ОПО без исключения",
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "20402211",
        text: "В течение какого срока после реконструкции или технического перевооружения объекта должен быть пересмотрен план мероприятий по локализации и ликвидации последствий аварий на ОПО?",
        answers: [
          "В течение 3 месяцев",
          "В течение 6 месяцев",
          "Не позднее 45 календарных дней",
          "Не позднее 30 календарных дней",
        ],
        correctAnswers: ["Не позднее 30 календарных дней"],
      },
      {
        code: "20402212",
        text: "Кем согласовываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Представителями Ростехнадзора",
          "Руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
          "Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты",
          "Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
        ],
        correctAnswers: [
          "Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
        ],
      },
      {
        code: "20402213",
        text: "Кто разрабатывает указания по безопасному ведению горных работ на месторождении, склонном и опасном по горным ударам?",
        answers: [
          "Технический руководитель организации",
          "Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении",
          "Комиссия, состоящая из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
          "Представитель территориального управления Ростехнадзора",
        ],
        correctAnswers: [
          "Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении",
        ],
      },
      {
        code: "20402214",
        text: "Кем утверждается проект работ и мероприятий по ликвидации последствий горного удара?",
        answers: [
          "Руководителем организации",
          "Техническим руководителем объекта",
          "Специализированной организацией, выполняющей работы по проблеме горных ударов на данном месторождении",
          "Комиссией, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
        ],
        correctAnswers: ["Техническим руководителем объекта"],
      },
      {
        code: "20402215",
        text: "На какой основе осуществляется изменение параметров противоударных мероприятий и периодичности прогноза степени удароопасности, предусмотренных Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых?",
        answers: [
          "На основе специальных исследований и заключения специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
          "На основе специальных исследований и заключения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
          "Изменение параметров и периодичности не осуществляется ни в каком случае",
          "На основе заключения представителя территориального управления Ростехнадзора",
        ],
        correctAnswers: [
          "На основе специальных исследований и заключения специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
        ],
      },
      {
        code: "20402216",
        text: "Какими методами производится локальный прогноз удароопасности участков массива горных пород и руд, а также оценка эффективности мер предотвращения горных ударов?",
        answers: [
          "Только геомеханическими методами",
          "Только геофизическими методами",
          "Только визуальными наблюдениями за разрушением приконтурного массива выработок",
          "Всеми перечисленными",
        ],
        correctAnswers: ["Всеми перечисленными"],
      },
      {
        code: "20402217",
        text: "В каком случае допускаются отступления от последовательного продвигания фронта очистных работ в пределах как шахтного поля (участка), так и месторождения в целом при отработке месторождений, склонных и опасных по горным ударам?",
        answers: [
          "Если отступления определяются указаниями по безопасному ведению горных работ на месторождении, склонном и опасном по горным ударам",
          "Ни в каком случае",
          "Если отступления соответствуют требованиям Правил безопасности при ведении горных работ и переработке твердых полезных ископаемых",
          "С разрешения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
        ],
        correctAnswers: [
          "С разрешения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
        ],
      },
      {
        code: "20402218",
        text: "Разработку каких рудных тел необходимо производить преимущественно системой одностадийного этажного или подэтажного принудительного обрушения с отбойкой руды на зажатую среду?",
        answers: [
          "Крутопадающих мощных рудных тел",
          "Крутопадающих рудных тел средней мощности",
          "Горизонтальных рудных тел",
          "Наклонных рудных тел",
          "Все ответы неверны",
        ],
        correctAnswers: ["Крутопадающих мощных рудных тел"],
      },
      {
        code: "20402219",
        text: "В каком случае горные работы следует производить с применением комплекса мер по профилактике горных ударов, высыпаний, вывалов и обрушения пород (руд) на участках рудного массива или массива горных пород?",
        answers: [
          "Расположенных на удалении 5 - 10 м от плоскости сместителя тектонического нарушения",
          "Данный комплекс мер допускается не производить на участках рудного массива или массива горных пород",
          "Расположенных на любом удалении от плоскости сместителя тектонического нарушения",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Расположенных на удалении 5 - 10 м от плоскости сместителя тектонического нарушения",
        ],
      },
      {
        code: "20402220",
        text: "Каким из перечисленных прав не обладает комиссия по горным ударам?",
        answers: [
          "Останавливать горные работы, если нарушены требования правил и указаний, касающиеся вопросов безопасной отработки участков, склонных и опасных по горным ударам",
          "Приглашать на заседания комиссии представителей организаций, работающих по проблеме борьбы с горными ударами",
          "Получать от руководителей объектов необходимую документацию, в которой описываются меры по борьбе с горными ударами",
          "Штрафовать ответственных лиц в случае нарушения требований, касающихся вопросов безопасной отработки участков, склонных и опасных по горным ударам",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Штрафовать ответственных лиц в случае нарушения требований, касающихся вопросов безопасной отработки участков, склонных и опасных по горным ударам",
        ],
      },
      {
        code: "20402221",
        text: "Кем утверждается структура и количественный состав службы прогноза и предотвращения горных ударов?",
        answers: [
          "Комиссией по горным ударам",
          "Руководителем организации",
          "Техническим руководителем организации",
          "Главным геологом",
        ],
        correctAnswers: ["Комиссией по горным ударам"],
      },
      {
        code: "20402222",
        text: "Кто несет персональную ответственность за своевременное размещение в планах горных работ результатов регионального и локального прогноза удароопасности?",
        answers: [
          "Технический руководитель организации",
          "Главный геолог",
          "Главный маркшейдер",
          "Начальник службы прогноза и предотвращения горных ударов",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Начальник службы прогноза и предотвращения горных ударов",
        ],
      },
      {
        code: "20402223",
        text: "Какой практический стаж подземных работ должен иметь горный инженер для назначения на должность начальника службы прогноза и предотвращения горных ударов?",
        answers: [
          "Не менее двух лет",
          "Не менее трех лет",
          "Не менее четырех лет",
          "Не менее пяти лет",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не менее трех лет"],
      },
      {
        code: "20402224",
        text: "Какие из перечисленных задач выполняет служба прогноза и предотвращения горных ударов? Укажите все правильные ответы.",
        answers: [
          "Разработка решений по предотвращению горных ударов, проведение горно-экспериментальных работ, испытание и внедрение новых методов прогноза и способов предотвращения горных ударов",
          "Решение вопросов ежегодного подтверждения отнесения месторождения к склонным или опасным по горным ударам",
          "Проведение регионального прогноза удароопасности шахтных полей с целью выявления удароопасных участков и разработка мероприятий по безопасному ведению горных работ",
          'Безотлагательное отнесение месторождения к удароопасным в случае неожиданных проявлений горных ударов или установления категории "Опасно"',
        ],
        correctAnswers: [
          "Разработка решений по предотвращению горных ударов, проведение горно-экспериментальных работ, испытание и внедрение новых методов прогноза и способов предотвращения горных ударов",
          "Проведение регионального прогноза удароопасности шахтных полей с целью выявления удароопасных участков и разработка мероприятий по безопасному ведению горных работ",
        ],
      },
      {
        code: "20402225",
        text: 'Какие из перечисленных требований на участках категории "Опасно" указаны верно? Укажите все правильные ответы.',
        answers: [
          "При расположении стволов в породах, опасных по горным ударам, должны быть запроектированы и выполняться мероприятия, обеспечивающие снижение до неопасных значений концентраций напряжений в стенках ствола",
          "Мероприятия по предотвращению горных ударов должны проводиться после возведения в стволе постоянной крепи",
          'При проходке стволов на участках категории "Опасно" необходимо применять передовые разгрузочные щели с таким расчетом, чтобы плоскость щели располагалась перпендикулярно направлению действия максимальных горизонтальных напряжений',
          "В особо сложных условиях мероприятия по предотвращению горных ударов должны утверждаться представителем территориального управления Ростехнадзора",
        ],
        correctAnswers: [
          "При расположении стволов в породах, опасных по горным ударам, должны быть запроектированы и выполняться мероприятия, обеспечивающие снижение до неопасных значений концентраций напряжений в стенках ствола",
          'При проходке стволов на участках категории "Опасно" необходимо применять передовые разгрузочные щели с таким расчетом, чтобы плоскость щели располагалась перпендикулярно направлению действия максимальных горизонтальных напряжений',
        ],
      },
      {
        code: "20402226",
        text: "Кто осуществляет научно-методическое сопровождение деятельности cлужбы прогноза и предотвращения горных ударов?",
        answers: [
          "Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении",
          "Маркшейдерская служба",
          "Геологическая служба",
          "Технический руководитель объекта",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении",
        ],
      },
      {
        code: "20402227",
        text: "В каком разделе указаний по безопасному ведению горных работ на месторождениях, склонных и опасных по горным ударам, представляется характеристика горных ударов, других геодинамических явлений, отражающих специфику геологических условий и технологии отработки месторождения?",
        answers: [
          'В разделе "Введение"',
          'В разделе "Порядок вскрытия, подготовки к выемке и отработки выемочных участков, блоков"',
          'В разделе "Методы прогноза удароопасности участков массива горных пород и руд"',
          'В разделе "Общие положения"',
        ],
        correctAnswers: ['В разделе "Общие положения"'],
      },
      {
        code: "20402228",
        text: "На какие виды могут подразделяться прогнозы удароопасности участков массива горных пород? Укажите все правильные ответы.",
        answers: [
          "Региональный",
          "Локальный",
          "Местный",
          "Федеральный",
          "Все ответы неверны",
        ],
        correctAnswers: ["Региональный", "Локальный"],
      },
      {
        code: "20402229",
        text: "Через какое время после производства массовых взрывов разрешается допуск работников на рабочие места?",
        answers: [
          "Не ранее чем через 30 минут",
          "Не ранее чем через 60 минут",
          "Не ранее чем через 10 минут",
          "Не ранее чем через час",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не ранее чем через 30 минут"],
      },
    ],
    20403: [
      {
        code: "20403000",
        text: "Кто допускается к техническому руководству работами на объектах ведения открытых горных работ   и переработки полезных ископаемых?",
        answers: [
          "Лица, имеющие высшее техническое образование и аттестованные в области промышленной безопасности",
          "Лица, имеющие среднее образование и опыт работы в горном производстве не менее 3 лет",
          "Лица, имеющие высшее или среднее специальное соответствующее образование",
          "Лица, имеющие среднее техническое образование и опыт руководящей работы",
        ],
        correctAnswers: [
          "Лица, имеющие высшее или среднее специальное соответствующее образование",
        ],
      },
      {
        code: "20403001",
        text: "С какой периодичностью проводится проверка знаний инструкций по профессиям у рабочих?",
        answers: [
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "20403002",
        text: "Какой инструктаж проводится с работниками объекта при изменении характера работы или после допущенных грубых нарушений требований безопасного ведения работ?",
        answers: [
          "Первичный на рабочем месте",
          "Внеплановый",
          "Повторный",
          "Вводный",
          "Целевой",
        ],
        correctAnswers: ["Внеплановый"],
      },
      {
        code: "20403003",
        text: "Какая документация составляется перед производством горных работ и утверждается техническим руководителем объекта?",
        answers: [
          "План мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах и договоры с профессиональными аварийно-спасательными службами (формированиями)",
          "Положение о производственном контроле и геологический отчет",
          "Проект и локальные проекты производства работ (паспорта)",
          "Геологическая документация",
        ],
        correctAnswers: [
          "Проект и локальные проекты производства работ (паспорта)",
        ],
      },
      {
        code: "20403004",
        text: "Что устанавливается в лицензиях на пользование недрами, технических проектах и иной проектной документации на выполнение работ, связанных с пользованием участками недр?",
        answers: [
          "Только мероприятия, обеспечивающие безопасное пользование недрами",
          "Только мероприятия, обеспечивающие соблюдение требований по технологии ведения работ",
          "Только мероприятия, обеспечивающие соблюдение требований по прогнозированию и предупреждению опасных ситуаций с соответствующей корректировкой технических и технологических решений",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20403005",
        text: "В соответствии с каким документом осуществляется производство открытых горных работ в зонах ранее выполненных подземных работ и имеющих пустоты?",
        answers: [
          "В соответствии с планом мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах",
          "В соответствии со специальным проектом, согласованным с генеральным проектировщиком",
          "В соответствии с положением о производственном контроле",
          "В соответствии с планом организации строительства",
          "В соответствии с геологической документацией",
        ],
        correctAnswers: [
          "В соответствии со специальным проектом, согласованным с генеральным проектировщиком",
        ],
      },
      {
        code: "20403006",
        text: "Что должен содержать специальный проект, по которому осуществляются горные работы при комбинированной разработке месторождений полезных ископаемых, наряду с принятыми техническими решениями?",
        answers: [
          "Только анализ особенностей горно-геологических, горно-технических, гидрогеологических и технологических условий комбинированной разработки месторождения",
          "Только обоснование производственной (технической) возможности и технико-экономической целесообразности комбинированной разработки месторождения",
          "Только параметры карьерного и шахтного полей с запасами руды, намечаемые к отработке открытым и подземным способами",
          "Только границы зон влияния подземных разработок (опасных сдвижений, обрушения, воронкообразования)",
          "Только мероприятия по безопасному ведению горных работ, функции и ответственность инженерно-технических служб рудника",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20403007",
        text: "Кто утверждает планы мероприятий по противолавинной (противоселевой) защите объектов, содержащие соответствующие меры безопасности, при ведении горных работ в лавиноопасных и селеопасных районах (участках)?",
        answers: [
          "Руководитель оперативного штаба ликвидации чрезвычайных ситуаций территориального органа МЧС России",
          'Руководитель филиала ФГУП "Военизированная горноспасательная часть"',
          "Руководитель территориального органа МЧС России",
          "Руководитель объекта",
        ],
        correctAnswers: ["Руководитель объекта"],
      },
      {
        code: "20403008",
        text: "В каком из перечисленных случаев (кроме аварийных случаев) допускается остановка объектов жизнеобеспечения (электростанций, водоотливов, калориферных установок и др.)?",
        answers: [
          "По разрешению главного механика организации, согласованному с руководителем организации",
          "По письменному разрешению технического руководителя эксплуатирующей организации",
          "По соответствующему распоряжению руководителя организации",
        ],
        correctAnswers: [
          "По письменному разрешению технического руководителя эксплуатирующей организации",
        ],
      },
      {
        code: "20403009",
        text: "Какие требования к медицинскому обслуживанию на объектах ведения горных работ указаны неверно?",
        answers: [
          "Первая доврачебная помощь должна оказываться лицом, имеющим среднее профессиональное специальное медицинское образование (фельдшером, медицинской сестрой)",
          "В организациях с количеством работающих менее 500 человек медицинское обслуживание допускается осуществлять в ближайшем лечебном учреждении",
          "На объектах должен быть организован пункт первой доврачебной помощи, оборудованный средствами связи",
          "В санитарно-бытовых помещениях обязательно должны быть аптечки для оказания первой помощи",
          "На каждом участке должны быть аптечки для оказания первой помощи",
        ],
        correctAnswers: [
          "В организациях с количеством работающих менее 500 человек медицинское обслуживание допускается осуществлять в ближайшем лечебном учреждении",
        ],
      },
      {
        code: "20403010",
        text: "Обязаны ли организации, эксплуатирующие опасные производственные объекты, на которых ведутся горные работы, заключать договоры на обслуживание с профессиональными аварийно-спасательными формированиями (службами)?",
        answers: [
          "Заключение договоров обязательно",
          "Заключение договоров обязательно в случае, если время прибытия профессиональных аварийно-спасательных формирований (служб) на командный пункт (место аварии) не превышает 2 часа",
          "Заключение договоров обязательно в случае, если время прибытия профессиональных аварийно-спасательных формирований (служб) на командный пункт (место аварии) не превышает 4 часа",
          "Заключение договоров необязательно",
        ],
        correctAnswers: ["Заключение договоров обязательно"],
      },
      {
        code: "20403011",
        text: "В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "В целях обеспечения необходимой информацией профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, с которыми заключен договор на обслуживание объекта, в случае аварии на объекте",
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
          "В целях улучшения производственного контроля в организациях, эксплуатирующих опасные производственные объекты",
          "В целях предоставления отчетности в государственные контрольные органы",
        ],
        correctAnswers: [
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
        ],
      },
      {
        code: "20403012",
        text: "Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?",
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          "Для производственных объектов горнодобывающей промышленности",
          "Для всех производственных объектов",
          "Для любых ОПО",
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "20403013",
        text: "На какой срок разрабатывается план мероприятий по локализации и ликвидации последствий аварий для объектов, на которых ведутся открытые горные работы?",
        answers: ["На 6 месяцев", "На 1 год", "На 3 года", "На 5 лет"],
        correctAnswers: ["На 1 год"],
      },
      {
        code: "20403014",
        text: "В каком случае эксплуатирующая организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах на несколько опасных объектов?",
        answers: [
          "В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
          "В случае если объекты зарегистрированы в государственном реестре опасных производственных объектов",
          "В случае если это регламентировано внутренней документацией организации",
        ],
        correctAnswers: [
          "В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
        ],
      },
      {
        code: "20403015",
        text: "В каком случае организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий для нескольких опасных производственных объектов?",
        answers: [
          "В случае если 2 и более объектов, эксплуатируемые одной организацией, расположены на земельных участках со сходными геологическими и геоморфологическими условиями",
          "В случае если 2 и более объектов, эксплуатируемые одной организацией, расположены в пределах одной административно-территориальной единицы",
          "В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
        ],
      },
      {
        code: "20403016",
        text: "Что из перечисленного должно быть предусмотрено планом мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Только количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте, и их соответствие задачам по локализации и ликвидации последствий аварий",
          "Только организация материально-технического, инженерного и финансового обеспечения операций по локализации и ликвидации аварий на объекте",
          "Только действия производственного персонала и аварийно-спасательных служб (формирований) по локализации и ликвидации аварий",
          "Только первоочередные действия при получении сигнала об аварии на объекте",
          "Только организация управления, связи и оповещения при авариях на объекте",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20403017",
        text: "Из каких разделов состоит план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Из теоретических и практических разделов",
          "Из описательных и табличных разделов",
          "Из текстовых и графических разделов",
          "Из общих и специальных разделов",
        ],
        correctAnswers: ["Из общих и специальных разделов"],
      },
      {
        code: "20403018",
        text: "Какую информацию не включает в себя общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
          "Описание системы взаимного обмена информацией между организациями - участниками локализации и ликвидации последствий аварий на объекте",
          "Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах",
          "Возможные сценарии возникновения и развития аварий на объектах, а также источники (места) возникновения аварий",
          "Характеристику объектов, в отношении которых разрабатывается план мероприятий",
        ],
        correctAnswers: [
          "Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
        ],
      },
      {
        code: "20403019",
        text: "Кто утверждает планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.",
        answers: [
          "Руководители профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований",
          "Руководители (заместители руководителей) организаций, эксплуатирующих объекты",
          "Руководители обособленных подразделений юридических лиц",
          "Инспектор Ростехнадзора",
        ],
        correctAnswers: [
          "Руководители (заместители руководителей) организаций, эксплуатирующих объекты",
          "Руководители обособленных подразделений юридических лиц",
        ],
      },
      {
        code: "20403020",
        text: "Кто утверждает план мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Руководители (заместители руководителей) организаций, эксплуатирующих объекты, либо руководители обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
          "Руководители профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, с которыми заключен договор на обслуживание опасного производственного объекта",
          "Руководители территориальных органов Ростехнадзора",
          "Представители территориальных органов Роснедр",
        ],
        correctAnswers: [
          "Руководители (заместители руководителей) организаций, эксплуатирующих объекты, либо руководители обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
        ],
      },
      {
        code: "20403021",
        text: "С кем согласовываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
          "С представителями территориального органа Федерального агентства по недропользованию",
          "С представителями территориального органа Росприроднадзора",
          "С представителями территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
        ],
      },
      {
        code: "20403022",
        text: "В течение какого времени пересматривается план мероприятий по локализации и ликвидации последствий аварий после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства?",
        answers: [
          "Не позднее 10 календарных дней",
          "Не позднее 15 календарных дней",
          "Не позднее 30 календарных дней",
          "Не позднее 3 месяцев",
          "Не позднее 6 месяцев",
        ],
        correctAnswers: ["Не позднее 30 календарных дней"],
      },
      {
        code: "20403023",
        text: "В каком из перечисленных случаев пересматриваются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.",
        answers: [
          "При внесении изменений в методики измерений, применяемые при осуществлении производственного контроля за соблюдением требований промышленной безопасности на объекте",
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
          "Не позднее 40 дней после внесения изменений в системы управления технологическими процессами на объекте",
          "Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий",
          "В случае назначения нового руководителя организации",
        ],
        correctAnswers: [
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
          "Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий",
        ],
      },
      {
        code: "20403024",
        text: "На какой срок разрабатывается план развития горных работ по всем планируемым видам горных работ?",
        answers: ["На 1 год", "На 2 года", "На 3 года", "На 5 лет"],
        correctAnswers: ["На 1 год"],
      },
      {
        code: "20403025",
        text: "На какой срок составляется план развития горных работ по всем планируемым видам работ?",
        answers: ["На 1 год", "На 2 года", "На 3 года", "На 5 лет"],
        correctAnswers: ["На 1 год"],
      },
      {
        code: "20403026",
        text: "На основе чего составляются планы и схемы развития горных работ?",
        answers: [
          "На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами",
          "На основе нормативно-правовых актов Министерства природных ресурсов и экологии Российской Федерации",
          "На основе нормативно-правовых актов Федерального агентства по недропользованию",
          "На основе федеральных нормативно-правовых актов",
        ],
        correctAnswers: [
          "На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами",
        ],
      },
      {
        code: "20403027",
        text: "Что определяется планами и схемами развития горных работ?",
        answers: [
          "Направления развития горных работ, условия безопасного недропользования, технические и технологические решения при эксплуатации объектов ведения горных работ, объемы добычи, первичной переработки полезных ископаемых, вскрышных пород, а также допустимые величины их отклонений от планируемых значений и (или) установленных в проектной документации на выполнение работ, связанных с пользованием недрами",
          "Средства и методики осуществления производственного контроля за соблюдением требований промышленной безопасности\nна объекте",
          "Технические решения при консервации объектов ведения горных работ",
        ],
        correctAnswers: [
          "Направления развития горных работ, условия безопасного недропользования, технические и технологические решения при эксплуатации объектов ведения горных работ, объемы добычи, первичной переработки полезных ископаемых, вскрышных пород, а также допустимые величины их отклонений от планируемых значений и (или) установленных в проектной документации на выполнение работ, связанных с пользованием недрами",
        ],
      },
      {
        code: "20403028",
        text: "По каким видам полезных ископаемых подготавливаются планы и схемы развития горных работ?",
        answers: [
          "Только по общераспространенным полезным ископаемым",
          "Только по твердым полезным ископаемым",
          "Только по углеводородному сырью",
          "Только по подземным водам",
          "По всем перечисленным видам полезных ископаемых",
        ],
        correctAnswers: ["По всем перечисленным видам полезных ископаемых"],
      },
      {
        code: "20403029",
        text: "На какие перечисленные виды работ не составляются планы и схемы горных работ?",
        answers: [
          "На работы, связанные со вторичной переработкой минерального сырья",
          "На рекультивационные работы",
          "На маркшейдерские работы",
          "На геологические работы",
          "На вскрышные работы",
        ],
        correctAnswers: [
          "На работы, связанные со вторичной переработкой минерального сырья",
        ],
      },
      {
        code: "20403030",
        text: "Какие параметры эксплуатации объектов ведения горных работ планами и схемами развития горных работ не определяются?",
        answers: [
          "Объемы добычи, первичной переработки полезных ископаемых, вскрышных пород, а также допустимые величины их отклонений от планируемых значений",
          "Технические и технологические решения при эксплуатации объектов ведения горных работ",
          "Штатная численность сотрудников объекта ведения горных работ",
          "Условия безопасного недропользования",
          "Направления развития горных работ",
        ],
        correctAnswers: [
          "Штатная численность сотрудников объекта ведения горных работ",
        ],
      },
      {
        code: "20403031",
        text: "Кем осуществляется подготовка планов и схем развития горных работ?",
        answers: [
          "Пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ",
          "Территориальным органом Ростехнадзора",
          "Проектной организацией",
        ],
        correctAnswers: [
          "Пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ",
        ],
      },
      {
        code: "20403032",
        text: "Кем осуществляются подготовка и утверждение планов и схем развития горных работ?",
        answers: [
          "Подготовка - пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ, утверждение - руководителем организации - пользователя недр",
          "Представителем территориального органа Росприроднадзора",
          "Представителем территориального органа Ростехнадзора",
          "Руководителем территориального органа Роснедр",
        ],
        correctAnswers: [
          "Подготовка - пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ, утверждение - руководителем организации - пользователя недр",
        ],
      },
      {
        code: "20403033",
        text: "Кем утверждаются план и схема развития горных работ?",
        answers: [
          "Руководителем организации - пользователя недр",
          "Территориальным органом Росприроднадзора",
          "Территориальным органом Ростехнадзора",
          "Техническим руководителем организации",
        ],
        correctAnswers: ["Руководителем организации - пользователя недр"],
      },
      {
        code: "20403034",
        text: "Кем утверждаются план и схема развития горных работ?",
        answers: [
          "Органом исполнительной власти субъекта Российской Федерации",
          "Техническим руководителем организации - пользователя недр",
          "Руководителем организации - пользователя недр",
          "Органом государственного горного надзора",
        ],
        correctAnswers: ["Руководителем организации - пользователя недр"],
      },
      {
        code: "20403035",
        text: "Какие данные не должны указываться в штампе графических материалов планов развития горных работ?",
        answers: [
          "Наименование объекта недропользования (участка недр)",
          "Подписи составителей графических материалов",
          "Название графического документа и масштаб",
          "Дата составления графического документа",
          "Наименование организации",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20403036",
        text: "Что не отображается на планах поверхности при составлении планов и схем развития горных работ?",
        answers: [
          "Абсолютные отметки устьев стволов и скважин, околоствольных дворов (руддворов) и горизонтов",
          "Устья выходящих на поверхность законсервированных и ликвидированных горных выработок",
          "Объекты застройки площади залегания полезных ископаемых (горного отвода)",
          "Устья выходящих на поверхность действующих горных выработок",
          "Места образования провалов, оползней",
        ],
        correctAnswers: [
          "Абсолютные отметки устьев стволов и скважин, околоствольных дворов (руддворов) и горизонтов",
        ],
      },
      {
        code: "20403037",
        text: "В какой раздел плана (схемы) развития горных работ включаются сведения о соблюдении порядка ликвидации (консервации) горных выработок (скважин), участков, блоков, горизонтов на отработанных частях месторождений?",
        answers: [
          "В маркшейдерский план земной поверхности территории хозяйственной деятельности предприятия",
          "В пояснительную записку планов (схем)",
          "В геологические и структурные карты",
          "В схему вскрытия месторождения",
          "В характерные разрезы",
        ],
        correctAnswers: ["В пояснительную записку планов (схем)"],
      },
      {
        code: "20403038",
        text: "Что должны включать табличные материалы пояснительной записки планов (схем) по видам горных работ и видам полезных ископаемых?",
        answers: [
          "Только сведения о состоянии и движении вскрытых, подготовленных и готовых к выемке запасов полезных ископаемых по состоянию на начало планируемого периода и ожидаемого на конец этого периода (при добыче твердых полезных ископаемых)",
          "Только показатели объемов потерь минерального сырья при переработке твердых полезных ископаемых (при наличии перерабатывающих производств), а также состояние и движение отходов добычи и при переработке такого минерального сырья",
          "Только сведения об объемах проведения горных разведочных выработок, в том числе скважин эксплуатационной разведки",
          "Только основные технические показатели на предстоящий период (план) и ожидаемые на конец текущего года (факт)",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20403039",
        text: "С кем необходимо согласовывать планы и схемы развития горных работ?",
        answers: [
          "С Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "С Федеральной службой по надзору в сфере природопользования",
          "С Федеральным агентством по недропользованию",
          "С территориальными органами Ростехнадзора",
        ],
        correctAnswers: ["С территориальными органами Ростехнадзора"],
      },
      {
        code: "20403040",
        text: "Кем должны быть подписаны планы (схемы) развития горных работ, направляемые пользователем недр в электронном виде?",
        answers: [
          "Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом",
          "Представителем органа государственного горного надзора",
          "Руководителем органа государственного горного надзора",
          "Руководителем территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом",
        ],
      },
      {
        code: "20403041",
        text: "В какой срок планы и (или) схемы развития горных работ направляются пользователем недр в орган государственного горного надзора для рассмотрения?",
        answers: [
          "За 5 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
          "За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
          "За 30 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
          "За 60 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
        ],
        correctAnswers: [
          "За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
        ],
      },
      {
        code: "20403042",
        text: 'В какой период, установленный "Правилами подготовки, рассмотрения и согласования планов и схем развития горных работ по видам полезных ископаемых", осуществляется рассмотрение органами Ростехнадзора планов и схем развития горных работ?',
        answers: [
          "С 20 сентября года, предшествующего планируемому, по 1 марта планируемого года",
          "С 20 сентября по 25 декабря года, предшествующего планируемому",
          "С 20 июня по 30 октября года, предшествующего планируемому",
          "С 20 января по 20 марта планируемого года",
        ],
        correctAnswers: [
          "С 20 сентября по 25 декабря года, предшествующего планируемому",
        ],
      },
      {
        code: "20403043",
        text: "В какой срок пользователи недр письменно уведомляются органом горного надзора о времени и месте рассмотрения планов и (или) схем развития горных работ?",
        answers: [
          "Не позднее чем за 5 дней до установленной даты их рассмотрения",
          "Не позднее чем за 7 дней до установленной даты их рассмотрения",
          "Не позднее чем за 10 дней до установленной даты их рассмотрения",
          "Не позднее чем за 20 дней до установленной даты их рассмотрения",
        ],
        correctAnswers: [
          "Не позднее чем за 20 дней до установленной даты их рассмотрения",
        ],
      },
      {
        code: "20403044",
        text: "Что из перечисленного не является основанием для принятия решения об отказе в согласовании плана и (или) схемы развития горных работ?",
        answers: [
          "Несоблюдение требований, установленных федеральным законодательством, в части предупреждения и устранения вредного влияния горных работ на население, окружающую среду, здания и сооружения",
          "Отсутствие геологического и маркшейдерского обеспечения горных работ, необходимого для достоверного учета параметров горных разработок и прогнозирования опасных ситуаций",
          "Несоответствие планов и схем развития горных работ требованиям, установленным органом государственного горного надзора, к их подготовке, содержанию и оформлению",
          "Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями",
          "Выявление недостоверных сведений в планах и схемах развития горных работ",
          "Отсутствие обоснования соблюдения условий безопасного недропользования",
        ],
        correctAnswers: [
          "Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями",
        ],
      },
      {
        code: "20403045",
        text: "С какой периодичностью должны обновляться (пополняться) сводные планы горных работ, планы поверхности, в случаях если ситуация местности (объектовый состав) в границах горного отвода и (или) система наблюдений за состоянием горного отвода и расположенных в его границах горных выработок (скважин), зданий, сооружений и иных объектов не претерпели изменений?",
        answers: [
          "Каждый год",
          "Каждые 2 года",
          "По мере необходимости, но не реже 1 раза в 5 лет",
          "Каждые 6 лет",
        ],
        correctAnswers: ["По мере необходимости, но не реже 1 раза в 5 лет"],
      },
      {
        code: "20403046",
        text: "Каким образом организуется передвижение людей по территории объектов ведения горных работ?",
        answers: [
          "Передвижение разрешается только по специально устроенным пешеходным дорожкам или по обочинам автодорог навстречу направлению движения автотранспорта",
          "Передвижение разрешается по любым дорогам, в том числе по автодорогам по ходу движения автотранспорта",
          "Передвижение разрешается только вдоль автодорог и железных дорог",
          "Передвижение разрешается только в сопровождении горного мастера",
        ],
        correctAnswers: [
          "Передвижение разрешается только по специально устроенным пешеходным дорожкам или по обочинам автодорог навстречу направлению движения автотранспорта",
        ],
      },
      {
        code: "20403047",
        text: "Каким транспортом должна осуществляться доставка рабочих к месту работ?",
        answers: [
          "Любыми вагонами или любым автотранспортом",
          "Только специальным транспортом",
          "Только автотранспортом",
        ],
        correctAnswers: ["Только специальным транспортом"],
      },
      {
        code: "20403048",
        text: "Разрешается ли ведение горных работ вблизи затопленных выработок или поверхностных водоемов?",
        answers: [
          "Разрешается по проекту, предусматривающему возведение дамбы или иного гидротехнического сооружения для предотвращения прорыва воды",
          "Разрешается по проекту, предусматривающему оставление целиков для предотвращения прорыва воды",
          "Запрещается в любом случае",
        ],
        correctAnswers: [
          "Разрешается по проекту, предусматривающему оставление целиков для предотвращения прорыва воды",
        ],
      },
      {
        code: "20403049",
        text: "Какие сведения должны быть нанесены на маркшейдерские планы и разрезы?",
        answers: [
          "Только зоны обводненных горизонтов, положение зумпфов водоотливных установок с указанием отметок дна зумпфа, водоводов, изоляционных перемычек в дренажных подземных выработках",
          "Только границы поверхностного контура зоны обрушения, разведочные и контрольные наблюдательные скважины и репера",
          "Только все горные выработки, границы открытого очистного пространства на подземных работах, отработанные камеры",
          "Все перечисленные сведения, а также при планировании развития горных работ в карьере - границы погашения дренажных выработок на конец планируемого периода",
        ],
        correctAnswers: [
          "Все перечисленные сведения, а также при планировании развития горных работ в карьере - границы погашения дренажных выработок на конец планируемого периода",
        ],
      },
      {
        code: "20403050",
        text: "Что должно быть изучено в результате инженерно-геологических и гидрогеологических исследований?",
        answers: [
          "Только физические свойства складируемых пород, прочность их в куске",
          "Только сдвиговые характеристики отвальной массы",
          "Только строение основания",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20403051",
        text: "Какие мероприятия должна обеспечить организация, эксплуатирующая объект ведения открытых горных работ?",
        answers: [
          "Только изучение выявленных нарушений устойчивости бортов, уступов, откосов, документирование нарушений, установление характера нарушений, степени опасности и причин возникновения",
          "Только контроль за состоянием противодеформационных сооружений и выполнением мероприятий, предотвращающих нарушение устойчивости бортов, уступов, откосов",
          "Только сохранность объектов, расположенных на земной поверхности в границах горных отводов и на прилегающих к ним территориях",
          "Только контроль за соблюдением проектных параметров бортов и уступов карьера, разреза и откосов отвала",
          "Только проведение визуальных и инструментальных наблюдений за состоянием бортов, уступов и откосов",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20403052",
        text: "С учетом каких факторов проектом должна определяться высота уступа?",
        answers: [
          "Результатов исследований физико-механических свойств горных пород и горно-геологических условий их залегания, а также параметров применяемого оборудования",
          "Финансовых показателей организации и количества работников, эксплуатирующих оборудование на объекте",
          "Погодно-климатических факторов и результатов гидрологических исследований",
        ],
        correctAnswers: [
          "Результатов исследований физико-механических свойств горных пород и горно-геологических условий их залегания, а также параметров применяемого оборудования",
        ],
      },
      {
        code: "20403053",
        text: "Какими должны быть предельные углы откосов (углы устойчивости) временно консервируемых участков борта в процессе эксплуатации?",
        answers: [
          "Не должны превышать 60°",
          "Не должны превышать 70°",
          "Не должны превышать 80°",
          "Предельные углы откосов (углы устойчивости) устанавливаются проектом",
        ],
        correctAnswers: [
          "Предельные углы откосов (углы устойчивости) устанавливаются проектом",
        ],
      },
      {
        code: "20403054",
        text: 'Какие требования установлены "Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых" для безопасного выполнения работ по ручной оборке откосов уступов?',
        answers: [
          "Ручная оборка должна осуществляться с применением дополнительных средств индивидуальной защиты работников",
          "Ручная оборка должна осуществляться по наряду-допуску под наблюдением лица технического надзора",
          "Ручная оборка должна осуществляться с применением средств малой механизации",
          "Ручная оборка должна осуществляться только по приказу начальника карьера",
        ],
        correctAnswers: [
          "Ручная оборка должна осуществляться по наряду-допуску под наблюдением лица технического надзора",
        ],
      },
      {
        code: "20403055",
        text: "Что относится к основным факторам, которые учитываются при оценке устойчивости бортов и уступов карьеров, разрезов в массивах дисперсных горных пород?",
        answers: [
          "Набухание, снижение прочности и развитие локальных деформаций уступов и их групп",
          "Планируемая длительность ведения работ в карьере (разрезе)",
          "Технические характеристики используемой горной техники",
          "Планируемая глубина выработок",
        ],
        correctAnswers: [
          "Набухание, снижение прочности и развитие локальных деформаций уступов и их групп",
        ],
      },
      {
        code: "20403056",
        text: "На каких участках возможных деформаций выполняют закладку наблюдательных станций?",
        answers: [
          "Только на участках, обусловленных наличием слабых пластичных, обводненных или сильно трещиноватых пород в горном массиве, формирующих борт, уступ или отвал",
          "Только на участках, обусловленных наличием слабых контактов, поверхностей тектонических нарушений, имеющих наклон в сторону выработанного пространства и простирание, близкое к простиранию борта",
          "На всех перечисленных",
        ],
        correctAnswers: ["На всех перечисленных"],
      },
      {
        code: "20403057",
        text: "Что является коэффициентом запаса устойчивости при детерминированном подходе?",
        answers: [
          "Отношение действительного расчетного напряженного состояния породы к предельно возможному",
          "Отношение удерживающих и сдвигающих сил на сформировавшейся поверхности скольжения",
          "Отношение критической нагрузки к действующей на поверхность скольжения силе тяжести",
          "Допустимая вероятность развития деформаций",
        ],
        correctAnswers: [
          "Отношение удерживающих и сдвигающих сил на сформировавшейся поверхности скольжения",
        ],
      },
      {
        code: "20403058",
        text: "В какой документ должны включаться мероприятия при обнаружении признаков нарушения устойчивости или критических деформаций?",
        answers: [
          "В план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах",
          "В план развития горных работ на предстоящий календарный период",
          "В проект горных и маркшейдерских работ",
        ],
        correctAnswers: [
          "В план развития горных работ на предстоящий календарный период",
        ],
      },
      {
        code: "20403059",
        text: "На основании какого документа могут быть возобновлены открытые горные работы после их прекращения при обнаружении признаков сдвижения пород?",
        answers: [
          "Инструкции по безопасному ведению горных работ при комбинированной (совмещенной) разработке рудных и нерудных месторождений полезных ископаемых",
          "Плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах",
          "Специального проекта организации работ, утвержденного руководителем предприятия",
          "Плана и схемы развития горных работ",
        ],
        correctAnswers: [
          "Специального проекта организации работ, утвержденного руководителем предприятия",
        ],
      },
      {
        code: "20403060",
        text: "Какие визуальные и инструментальные наблюдения должны проводиться на объектах ведения открытых горных работ?",
        answers: [
          "За обводнением подземных горных выработок",
          "За состоянием бортов, уступов и откосов",
          "За устойчивостью крепи",
          "Метеорологические",
        ],
        correctAnswers: ["За состоянием бортов, уступов и откосов"],
      },
      {
        code: "20403061",
        text: "За чем должны проводиться визуальные и инструментальные наблюдения в процессе эксплуатации объектов ведения открытых горных работ для своевременного выявления опасных зон и прогнозирования опасных ситуаций?",
        answers: [
          "За изменениями температуры и влажности воздуха",
          "За содержанием в воздухе ядовитых веществ",
          "За устойчивостью бортов, уступов, откосов",
          "За устойчивостью крепи",
        ],
        correctAnswers: ["За устойчивостью бортов, уступов, откосов"],
      },
      {
        code: "20403062",
        text: "Кем и с какой периодичностью должно проводиться визуальное обследование состояния откосов на карьерах?",
        answers: [
          "Специалистами геологической и маркшейдерской служб (геолого-маркшейдерская служба) и специалистами группы по мониторингу не реже одного раза в месяц",
          "Специалистами группы по мониторингу не реже одного раза в 6 месяцев",
          "Специалистами службы охраны труда не реже одного раза в 2 месяца",
          "Начальниками смены не реже одного раза в неделю",
        ],
        correctAnswers: [
          "Специалистами геологической и маркшейдерской служб (геолого-маркшейдерская служба) и специалистами группы по мониторингу не реже одного раза в месяц",
        ],
      },
      {
        code: "20403063",
        text: "Какое из перечисленных требований к рудоспускам указано неверно?",
        answers: [
          "При выполнении работ по переносу устья рудоспуска из карьера и ведении ремонтных работ число капитальных действующих рудоспусков с карьера в подземные выработки должно быть не менее 2",
          "Действующие рудоспуски запрещается использовать в качестве дренажных выработок для перепуска технологических, грунтовых и паводковых вод из карьера",
          "Строительство и оборудование рудоспусков должно выполняться по специальному проекту, утвержденному техническим руководителем организации (предприятия)",
          "Каждый рудоспуск необходимо обустроить бетонными предохранительными барьерами высотой не менее 0,33 и шириной не менее 0,5 диаметра колеса автосамосвала",
          "При перепуске руд глинистых, обводненных, склонных к слеживанию и самовозгоранию допускается аккумулирование руды в рудоспуске",
          'В рудоспусках всегда должна оставаться рудная "подушка"',
        ],
        correctAnswers: [
          "При перепуске руд глинистых, обводненных, склонных к слеживанию и самовозгоранию допускается аккумулирование руды в рудоспуске",
        ],
      },
      {
        code: "20403064",
        text: "Каким образом доводятся до специалистов и горнорабочих дополнительные меры безопасности перед производством массового взрыва?",
        answers: [
          "Устно командиром профессиональной аварийно-спасательной службы (формирования) под подпись в журнале инструктажа",
          "Устно руководителем карьера под подпись в журнале взрывных работ",
          "Устно начальником отдела промышленной безопасности",
          "Приказом на каждый взрыв - под подпись",
        ],
        correctAnswers: ["Приказом на каждый взрыв - под подпись"],
      },
      {
        code: "20403065",
        text: "В каком из перечисленных случаев разрешается допуск людей в район взрыва при проведении открытых горных работ?",
        answers: [
          "После получения сообщения от аварийно-спасательной службы о снижении концентрации ядовитых продуктов взрыва в воздухе до предельно допустимых норм и выполнении всех перечисленных требований, но не ранее чем через 30 минут после взрыва",
          "При полном рассеивании пылевого облака и восстановлении видимости в карьере",
          "При завершении проверки состояния уступов",
        ],
        correctAnswers: [
          "После получения сообщения от аварийно-спасательной службы о снижении концентрации ядовитых продуктов взрыва в воздухе до предельно допустимых норм и выполнении всех перечисленных требований, но не ранее чем через 30 минут после взрыва",
        ],
      },
      {
        code: "20403066",
        text: "Когда могут быть допущены работники шахты на место проведенного массового взрыва?",
        answers: [
          "Не ранее чем через 10 минут после взрыва, рассеивания пылевого облака и полного восстановления видимости",
          "Не ранее чем через 20 минут после взрыва",
          "Не ранее чем через 1 час после взрыва",
          "После восстановления нормальной рудничной атмосферы, приведения выработок в безопасное состояние и проверки аварийно-спасательным формированием, но не ранее чем через 8 часов после взрыва",
        ],
        correctAnswers: [
          "После восстановления нормальной рудничной атмосферы, приведения выработок в безопасное состояние и проверки аварийно-спасательным формированием, но не ранее чем через 8 часов после взрыва",
        ],
      },
      {
        code: "20403067",
        text: "По прошествии какого времени после массового взрыва по разрушению целиков разрешается спуск в шахту представителей профессиональной аварийно-спасательной службы (формирования)?",
        answers: [
          "Через 20 минут",
          "Через 30 минут",
          "Через 1 час",
          "Через 2 часа",
        ],
        correctAnswers: ["Через 2 часа"],
      },
      {
        code: "20403068",
        text: "Кем утверждается паспорт буро-взрывных работ для каждого рабочего уступа - выемочного блока на основе маркшейдерской документации?",
        answers: [
          "Руководителем геолого-маркшейдерского отдела",
          "Заместителем директора по производству",
          "Техническим руководителем карьера",
          "Начальником смены",
        ],
        correctAnswers: ["Техническим руководителем карьера"],
      },
      {
        code: "20403069",
        text: "Какие требования предъявляются к установке бурового станка?",
        answers: [
          "Буровой станок должен быть установлен на безопасном расстоянии от верхней бровки уступа в соответствии с проектом, но не менее 2 м от бровки до ближайшей точки опоры станка",
          "Буровой станок должен быть установлен на спланированной площадке на расстоянии не менее 1,5 м от бровки до ближайшей точки опоры станка",
          "Буровой станок должен быть установлен на расстоянии не менее 1 м от верхней бровки уступа до ближайшего габарита станка",
        ],
        correctAnswers: [
          "Буровой станок должен быть установлен на безопасном расстоянии от верхней бровки уступа в соответствии с проектом, но не менее 2 м от бровки до ближайшей точки опоры станка",
        ],
      },
      {
        code: "20403070",
        text: "С каким диаметром устья скважины после окончания бурения должны быть перекрыты?",
        answers: [
          "Более 180 мм",
          "От 200 до 300 мм",
          "Более 250 мм",
          "Менее 300 мм",
        ],
        correctAnswers: ["Более 250 мм"],
      },
      {
        code: "20403071",
        text: "При наличии какого количества порванных проволок на длине шага свивки в подъемном канате бурового станка он подлежит замене?",
        answers: [
          "Более 2 % порванных проволок",
          "Более 5 % порванных проволок",
          "От 5 до 10 % порванных проволок",
          "Более 10 % порванных проволок",
        ],
        correctAnswers: ["Более 10 % порванных проволок"],
      },
      {
        code: "20403072",
        text: "В каких местах могут находиться участки для размещения отвалов?",
        answers: [
          "В местах, определенных после проведения инженерно-геологических и гидрогеологических изысканий",
          "На расстоянии более 2 м от площадей месторождений, подлежащих обработке открытым способом",
          "Только на участках месторождений, уклон которых не превышает 3°",
          "В местах, которые подвержены влиянию дождевых вод",
        ],
        correctAnswers: [
          "В местах, определенных после проведения инженерно-геологических и гидрогеологических изысканий",
        ],
      },
      {
        code: "20403073",
        text: "Кем утверждается проект ведения горных работ с промежуточными отвалами (складами)?",
        answers: [
          "Руководителем подразделения, разработавшего проект",
          "Руководителем территориального органа МЧС России",
          "Техническим руководителем организации",
          "Главным геологом организации",
          "Представителем Ростехнадзора",
        ],
        correctAnswers: ["Техническим руководителем организации"],
      },
      {
        code: "20403074",
        text: "Какие требования безопасности устанавливаются к организации движения автомобилей на отвалах и перегрузочных пунктах?",
        answers: [
          "На отвалах и перегрузочных пунктах должны устанавливаться схемы движения автомобилей. Зона разгрузки должна быть ограничена с обеих сторон знаками в виде изображения самосвала с поднятым кузовом с указателями направления разгрузки",
          "На автомобилях и на путях их движения должны устанавливаться предупреждающие знаки и проблесковая сигнализация",
          "Пути движения автомобилей должны быть двухполосными",
        ],
        correctAnswers: [
          "На отвалах и перегрузочных пунктах должны устанавливаться схемы движения автомобилей. Зона разгрузки должна быть ограничена с обеих сторон знаками в виде изображения самосвала с поднятым кузовом с указателями направления разгрузки",
        ],
      },
      {
        code: "20403075",
        text: "Какие из перечисленных факторов должны учитываться при проектировании, строительстве и эксплуатации горнорудных объектов с комбинированной (совмещенной) разработкой?",
        answers: [
          "Только воздействие массовых взрывов на карьере на состояние подземных горных выработок и воздействие подземных массовых взрывов вблизи границ карьера",
          "Только исключение прорывов в подземные горные выработки паводковых и ливневых вод",
          "Только подработанность массивов горных пород, слагающих борта карьера",
          "Только наличие подземных пустот в границах и под дном карьера",
          "Все перечисленные факторы",
        ],
        correctAnswers: ["Все перечисленные факторы"],
      },
      {
        code: "20403076",
        text: "Какие мероприятия должны осуществляться при одновременной разработке месторождений открытым и подземным способами по обеспечению безопасности работающих одновременно на открытых и подземных горных работах?",
        answers: [
          "Совместные мероприятия, включая: согласование планов и графиков ведения горных и взрывных работ; применение нагнетательной схемы проветривания подземных объектов; проверку представителями профессионального аварийно-спасательного формирования (службы) состояния атмосферы в подземных объектах после массовых взрывов на объектах открытых горных работ; предотвращение опасности прорыва воды в подземные горные выработки из объекта открытых горных работ",
          "Мероприятия по обеспечению бесперебойного энергоснабжения технологического оборудования на месторождениях",
          "Мероприятия по организации первичной доврачебной помощи работникам",
        ],
        correctAnswers: [
          "Совместные мероприятия, включая: согласование планов и графиков ведения горных и взрывных работ; применение нагнетательной схемы проветривания подземных объектов; проверку представителями профессионального аварийно-спасательного формирования (службы) состояния атмосферы в подземных объектах после массовых взрывов на объектах открытых горных работ; предотвращение опасности прорыва воды в подземные горные выработки из объекта открытых горных работ",
        ],
      },
      {
        code: "20403077",
        text: "Что запрещается при комбинированной разработке месторождений?",
        answers: [
          "Несвоевременная отработка и разрушение рудных целиков и потолочин в руднике от массовых взрывов",
          "Полная выемка рудных залежей из бортов карьера и предохранительных целиков",
          "Ограничение мощности массовых взрывов в карьере и подземных выработках",
          "Применение нагнетательного способа проветривания подземных выработок",
        ],
        correctAnswers: [
          "Несвоевременная отработка и разрушение рудных целиков и потолочин в руднике от массовых взрывов",
        ],
      },
      {
        code: "20403078",
        text: "В каком из перечисленных случаев нарушены требования к системам вентиляции при комбинированной (совмещенной) разработке месторождения?",
        answers: [
          "Если предусматривается нагнетательный способ общерудничной системы проветривания при наличии аэродинамических связей подземных выработок с карьерным пространством",
          "Если принимается только локальная вентиляция горных работ через вскрывающие выработки из карьера при вскрытии и отработке запасов руд, находящихся в бортах и под дном карьера",
          "Если вспомогательные нагнетательные вентиляторы максимально приближены к зонам аэродинамических связей с обеспечением подпора воздуха в подземных выработках",
          "Если способы проветривания рудников (шахт) определяются проектом с учетом образования избыточной депрессии в зоне влияния открытых работ",
        ],
        correctAnswers: [
          "Если принимается только локальная вентиляция горных работ через вскрывающие выработки из карьера при вскрытии и отработке запасов руд, находящихся в бортах и под дном карьера",
        ],
      },
      {
        code: "20403079",
        text: "В каком из перечисленных случаев нарушены требования к осушению и водоотливу при комбинированной (совмещенной) разработке месторождений с неблагоприятными гидрогеологическими условиями?",
        answers: [
          "Если по проекту, утвержденному руководителем предприятия и согласованному со специализированной организацией, производится частичная отработка барьерных целиков без предварительного отвода воды",
          "Если горные работы в пределах барьерного или предохранительного целика под водоемом (затопленным карьером) производятся после спуска воды из затопленных выработок",
          "Если используются действующие рудоспуски и вентиляционные восстающие в качестве дренажных выработок для перепуска грунтовых и паводковых вод из карьера",
          "Если вода из карьера в подземные выработки поступает самотеком через дренажные скважины с применением забивных или сквозных фильтров",
        ],
        correctAnswers: [
          "Если используются действующие рудоспуски и вентиляционные восстающие в качестве дренажных выработок для перепуска грунтовых и паводковых вод из карьера",
        ],
      },
      {
        code: "20403080",
        text: "Кем должны определяться границы опасных зон по прорывам воды и газов при комбинированной (совмещенной) разработке с неблагоприятными гидрогеологическими условиями, наличием затопленных горных выработок или других водных объектов, а также горных выработок, в которых возможны скопления ядовитых и горючих газов?",
        answers: [
          "Маркшейдерской и геологической службами предприятия с привлечением специализированных организаций",
          "Техническим руководителем предприятия",
          "Службой охраны труда предприятия",
          "Руководителем предприятия",
        ],
        correctAnswers: [
          "Маркшейдерской и геологической службами предприятия с привлечением специализированных организаций",
        ],
      },
      {
        code: "20403081",
        text: "Что относится к комбинированной разработке месторождений?",
        answers: [
          "Ведение открытых и подземных горных работ с совмещением в пространстве по вертикали или по горизонтали в пределах одного месторождения; ведение открытых и подземных горных работ в определенные периоды времени в пределах общего срока отработки запасов месторождения с одновременной или последовательной открыто-подземной или подземно-открытой разработкой",
          "Последовательное ведение подземных горных работ с совмещением в пространстве по вертикали или горизонтали в пределах одного месторождения",
          "Одновременное ведение открытых горных работ с совмещением в пространстве по вертикали в пределах одного месторождения",
          "Ведение открытых и подземных горных работ в пределах одного месторождения или соседних месторождений",
        ],
        correctAnswers: [
          "Ведение открытых и подземных горных работ с совмещением в пространстве по вертикали или по горизонтали в пределах одного месторождения; ведение открытых и подземных горных работ в определенные периоды времени в пределах общего срока отработки запасов месторождения с одновременной или последовательной открыто-подземной или подземно-открытой разработкой",
        ],
      },
      {
        code: "20403082",
        text: "Какими преимущественно системами разработки при совместной разработке месторождения следует производить подземные горные работы?",
        answers: [
          "Системами с обрушением руды и вмещающих пород с обеспечением выхода воронки обрушения на поверхность в кратчайшие сроки",
          "Системами с обрушением руды и вмещающих пород без выхода воронки обрушения на поверхность",
          "Преимущественно системами с поддержанием выработанного пространства",
        ],
        correctAnswers: [
          "Преимущественно системами с поддержанием выработанного пространства",
        ],
      },
      {
        code: "20403083",
        text: "Какой вид контроля должен осуществляться при добыче под действующими блоками карьера за оставленным целиком безопасности, рассчитанным проектной организацией?",
        answers: [
          "Маркшейдерский и оперативный контроль в соответствии с Инструкцией по производству маркшейдерских работ",
          "Административный контроль",
          "Производственный контроль",
          "Выборочный контроль",
        ],
        correctAnswers: [
          "Маркшейдерский и оперативный контроль в соответствии с Инструкцией по производству маркшейдерских работ",
        ],
      },
      {
        code: "20403084",
        text: "При проектировании, строительстве и эксплуатации горнодобывающих предприятий с комбинированной (совмещенной) разработкой месторождения должен учитываться ряд основных факторов, определяющих специфические условия и представляющих опасность при ведении горных работ. Относится ли к основным факторам вид полезного ископаемого разрабатываемого месторождения?",
        answers: [
          "Относится",
          "Относится только в случае ведения подземных горных работ",
          "Относится только в случае ведения открытых горных работ",
          "Не относится",
        ],
        correctAnswers: ["Не относится"],
      },
      {
        code: "20403085",
        text: "Чем обуславливаются основные факторы, представляющие опасность при комбинированной (совмещенной) разработке месторождений?",
        answers: [
          "Степенью взаимного влияния и технологической взаимосвязи открытого и подземного способов разработки",
          "Смежным расположением выработок на территории месторождения",
          "Однородностью разрабатываемых горных пород",
        ],
        correctAnswers: [
          "Степенью взаимного влияния и технологической взаимосвязи открытого и подземного способов разработки",
        ],
      },
      {
        code: "20403086",
        text: "Какое из перечисленных требований не допускается при комбинированной разработке месторождений?",
        answers: [
          "Фронт ведения горных работ при открытых работах следует располагать в направлении навстречу фронту развития подземных очистных работ",
          "Фронт ведения горных работ при подземных очистных работах следует располагать в направлении от массива к карьеру",
          "Добыча руды подземным способом из проектных контуров карьера запрещается",
          "Руду следует добывать подземным способом из проектных контуров карьера",
        ],
        correctAnswers: [
          "Руду следует добывать подземным способом из проектных контуров карьера",
        ],
      },
      {
        code: "20403087",
        text: "Какое допускается максимальное отклонение от нулевого кислородного баланса при производстве массовых взрывов при комбинированной (совмещенной) разработке рудных и нерудных месторождений полезных ископаемых?",
        answers: ["3 %", "5 %", "15 %", "25 %", "40 %"],
        correctAnswers: ["25 %"],
      },
      {
        code: "20403088",
        text: "Кем и в какие сроки проверяются исправность и комплектность горных машин, находящихся в эксплуатации?",
        answers: [
          "Ежесменно проверяются машинистом, еженедельно - механиком, энергетиком участка и ежемесячно - главным механиком, главным энергетиком или другими лицами, назначенными распорядительным документом",
          "Ежесменно проверяются машинистом, еженедельно - механиком, ежемесячно - энергетиком участка, 1 раз в 6 месяцев - главным механиком и главным энергетиком",
          "Еженедельно проверяются машинистом, 1 раз в 2 недели - энергетиком и механиком участка, не реже 1 раза в 2 месяца - главным механиком и главным энергетиком",
          "1 раз в 3 дня проверяются машинистом, механиком и энергетиком участка, 1 раз в 10 дней - главным механиком",
        ],
        correctAnswers: [
          "Ежесменно проверяются машинистом, еженедельно - механиком, энергетиком участка и ежемесячно - главным механиком, главным энергетиком или другими лицами, назначенными распорядительным документом",
        ],
      },
      {
        code: "20403089",
        text: "В течение какого времени должен даваться предпусковой предупредительный сигнал о запуске технологического оборудования?",
        answers: [
          "В течение 3 секунд",
          "В течение 6 секунд",
          "В течение 10 секунд",
          "В течение 15 секунд",
        ],
        correctAnswers: ["В течение 6 секунд"],
      },
      {
        code: "20403090",
        text: "Кто имеет право на проезд в многоместных кабинах автомобилей?",
        answers: [
          "Лица, сопровождающие составы, а также сменный надзор и отдельные работники при наличии у них письменного разрешения технического руководителя",
          "Любой работник карьера при наличии у него письменного разрешения технического руководителя",
          "Только водители и представители надзорных органов",
        ],
        correctAnswers: [
          "Лица, сопровождающие составы, а также сменный надзор и отдельные работники при наличии у них письменного разрешения технического руководителя",
        ],
      },
      {
        code: "20403091",
        text: "По какому документу разрешается перегон горных, транспортных и дорожных машин?",
        answers: [
          "По технологическим картам, утвержденным техническим руководителем",
          "По наряду-допуску на производство работ",
          "По проекту производства работ",
        ],
        correctAnswers: [
          "По технологическим картам, утвержденным техническим руководителем",
        ],
      },
      {
        code: "20403092",
        text: "Где должна находиться кабина экскаватора при работе?",
        answers: [
          "В любом месте не ближе чем на 1 м к откосу уступа",
          "В стороне, противоположной откосу уступа",
          "В стороне откоса уступа",
          "Над отвалом",
        ],
        correctAnswers: ["В стороне, противоположной откосу уступа"],
      },
      {
        code: "20403093",
        text: "С какой периодичностью механиком участка должны проводиться осмотры канатов подвески стрелы одноковшового экскаватора?",
        answers: [
          "Не реже 1 раза в смену",
          "Не реже 1 раза в 3 календарных дня",
          "Не реже 1 раза в неделю",
          "Не реже 1 раза в 10 календарных дней",
        ],
        correctAnswers: ["Не реже 1 раза в неделю"],
      },
      {
        code: "20403094",
        text: "Кто проводит осмотр забоя перед началом разработки новой заходки многоковшовыми экскаваторами?",
        answers: [
          "Лицо технического надзора",
          "Машинист экскаватора",
          "Начальник смены",
          "Горный мастер",
        ],
        correctAnswers: ["Лицо технического надзора"],
      },
      {
        code: "20403095",
        text: "При каких погодных условиях работа транспортно-отвального моста должна быть прекращена?",
        answers: [
          "Во время грозы, ливня, тумана и метели при видимости до 25 м, при влажном и сильном снегопаде",
          "Во время тумана при видимости до 50 м",
          "При температуре воздуха ниже 0 °С",
          "При скорости ветра более 8 м/с",
        ],
        correctAnswers: [
          "Во время грозы, ливня, тумана и метели при видимости до 25 м, при влажном и сильном снегопаде",
        ],
      },
      {
        code: "20403096",
        text: "Как машинисты горных машин, эксплуатируемых на объектах ведения открытых горных работ, должны предупреждать о начале движения задним ходом?",
        answers: [
          "Включением проблесковых маячков на кабине машины",
          "Звуковым прерывистым сигналом",
          "Сигнализацией рукой",
        ],
        correctAnswers: ["Звуковым прерывистым сигналом"],
      },
      {
        code: "20403097",
        text: "Какими из перечисленных устройств защиты должны быть снабжены кабины гусеничных и колесных погрузчиков, тракторов, предназначенных для эксплуатации на объекте ведения горных работ?",
        answers: [
          "Устройством защиты от токов короткого замыкания и устройством аварийной вытяжной вентиляции",
          "Устройством защиты оператора при опрокидывании машины и устройством защиты от падающих кусков горной массы сверху",
          "Устройством защиты от перегрузки электрооборудования и устройством защиты от прямых солнечных лучей",
        ],
        correctAnswers: [
          "Устройством защиты оператора при опрокидывании машины и устройством защиты от падающих кусков горной массы сверху",
        ],
      },
      {
        code: "20403098",
        text: "Каким должно быть расстояние от гидромониторной установки и другого забойного оборудования (скреперов, бульдозеров) до забоя при гидромеханизированном способе разработки глинистых, плотных и лессовидных пород, способных к обрушению глыбами?",
        answers: [
          "Не менее 0,5 высоты уступа",
          "Не менее высоты уступа",
          "Не менее 1,2 высоты уступа",
          "Не менее 2 высот уступа",
        ],
        correctAnswers: ["Не менее 1,2 высоты уступа"],
      },
      {
        code: "20403099",
        text: "Каким должно быть расстояние между двумя одновременно работающими гидромониторами?",
        answers: [
          "Больше дальности максимального полета струи любого из них",
          "Не менее максимального габарита (по длине) гидромонитора",
          "Не менее 10 м",
        ],
        correctAnswers: [
          "Больше дальности максимального полета струи любого из них",
        ],
      },
      {
        code: "20403100",
        text: "На каком расстоянии от высоковольтной линии электропередачи должен быть расположен гидромонитор?",
        answers: [
          "Не менее дальности полета струи",
          "Не менее полуторакратной дальности полета струи",
          "Не менее двукратной дальности полета струи",
          "Не менее трехкратной дальности полета струи",
        ],
        correctAnswers: ["Не менее двукратной дальности полета струи"],
      },
      {
        code: "20403101",
        text: "В чем должны храниться смазочные и обтирочные материалы?",
        answers: [
          "В контейнерах из металлических решеток",
          "В деревянных ящиках, имеющих крышки",
          "В закрытых металлических ящиках",
          "В пластиковых контейнерах",
        ],
        correctAnswers: ["В закрытых металлических ящиках"],
      },
      {
        code: "20403102",
        text: "Какой документ должен находиться на каждой единице горнотранспортного оборудования?",
        answers: [
          "Журнал приема и сдачи смен",
          "Журнал выдачи инструмента",
          "Книга учета нарядов",
          "Маршрутный лист",
        ],
        correctAnswers: ["Журнал приема и сдачи смен"],
      },
      {
        code: "20403103",
        text: "С какой периодичностью должна пополняться схема транспортных коммуникаций, нанесенная на план горных работ для контроля за вводом в эксплуатацию, эксплуатацией и ликвидацией железнодорожных путей и автодорог, при разработке полезных ископаемых?",
        answers: [
          "Не реже 1 раза в неделю",
          "Ежемесячно",
          "Ежеквартально",
          "Периодичность пересмотра схемы определяется техническим руководителем организации",
        ],
        correctAnswers: ["Ежемесячно"],
      },
      {
        code: "20403104",
        text: "Как должна производиться очистка стрелок и железнодорожных путей от снега и горной массы?",
        answers: [
          "Только автоматически, механизированным способом",
          "Только вручную бригадой в составе не менее четырех человек",
          "Только вручную бригадой в составе не менее трех человек, один из которых сигналист",
          "Автоматически механизированным способом или вручную не менее чем двумя работниками, один из которых сигналист",
        ],
        correctAnswers: [
          "Автоматически механизированным способом или вручную не менее чем двумя работниками, один из которых сигналист",
        ],
      },
      {
        code: "20403105",
        text: "Какой должна быть скорость движения поездов на железнодорожных путях объекта открытых горных работ?",
        answers: [
          "Не более 20 км/ч",
          "Не более 30 км/ч",
          "Не более 40 км/ч",
          "Скорость устанавливается внутренней инструкцией по эксплуатации железнодорожного транспорта организации в зависимости от применяемого подвижного состава, верхнего строения и профиля пути, а также местных условий",
        ],
        correctAnswers: [
          "Скорость устанавливается внутренней инструкцией по эксплуатации железнодорожного транспорта организации в зависимости от применяемого подвижного состава, верхнего строения и профиля пути, а также местных условий",
        ],
      },
      {
        code: "20403106",
        text: "Кем подаются разрешающие сигналы для производства подачи и передвижения железнодорожных составов в процессе погрузки (разгрузки) при ведении горных работ открытым способом?",
        answers: [
          "Машинистом экскаватора или оператором погрузочного устройства",
          "Поездным диспетчером",
          "Начальником карьера",
          "Машинистом состава",
        ],
        correctAnswers: [
          "Машинистом экскаватора или оператором погрузочного устройства",
        ],
      },
      {
        code: "20403107",
        text: "При какой неисправности запрещается использовать вагоны?",
        answers: [
          "Только при неисправности автосцепки поглощающего аппарата или тягового хомута автосцепного устройства",
          "Только при неисправности надрессорной балки или поперечной связи с изломом",
          "Только при неисправности колесной пары или буксы, требующей замены",
          "Только при неисправности кузова, а также запорного люка у полувагона",
          "При наличии любой неисправности из перечисленных",
        ],
        correctAnswers: ["При наличии любой неисправности из перечисленных"],
      },
      {
        code: "20403108",
        text: "Какой должна быть ширина проезжей дороги переездов через временные железнодорожные пути объекта ведения горных работ?",
        answers: [
          "От 4 до 12 м",
          "От 8 до 15 м",
          "От 10 до 20 м",
          "От 12 до 25 м",
          "Ширина проезжей дороги должна устанавливаться в зависимости от применяемых на объекте ведения горных работ самосвалов максимальной грузоподъемности",
        ],
        correctAnswers: [
          "Ширина проезжей дороги должна устанавливаться в зависимости от применяемых на объекте ведения горных работ самосвалов максимальной грузоподъемности",
        ],
      },
      {
        code: "20403109",
        text: "Какой должна быть ширина временных въездов в траншеи для автомобилей?",
        answers: [
          "Ширина (временных) въездов должна быть такой, чтобы вдоль них при движении транспорта оставался свободный проход шириной не менее 1,5 м с обеих сторон",
          "Ширина въездов должна превышать габарит автомобиля на 0,5 м с каждой стороны",
          "Ширина въездов определяется планом развития горных работ",
        ],
        correctAnswers: [
          "Ширина (временных) въездов должна быть такой, чтобы вдоль них при движении транспорта оставался свободный проход шириной не менее 1,5 м с обеих сторон",
        ],
      },
      {
        code: "20403110",
        text: "Что из перечисленного не входит в обязательную комплектацию карьерных автомобилей во время их нахождения на линии?",
        answers: [
          "Упоры (башмаки) для подкладывания под колеса",
          "Проблесковый маячок желтого цвета",
          "Знак аварийной остановки",
          "Медицинская аптечка",
        ],
        correctAnswers: ["Проблесковый маячок желтого цвета"],
      },
      {
        code: "20403111",
        text: "Как должна производиться погрузка горной массы в автомобили экскаватором?",
        answers: [
          "Погрузка в кузов должна производиться сбоку или сзади, перенос экскаваторного ковша над кабиной автомобиля запрещается",
          "Погрузка в кузов должна производиться сзади или спереди, погрузка со стороны боковых бортов запрещается",
          "Погрузка в кузов должна производиться сбоку или спереди",
        ],
        correctAnswers: [
          "Погрузка в кузов должна производиться сбоку или сзади, перенос экскаваторного ковша над кабиной автомобиля запрещается",
        ],
      },
      {
        code: "20403112",
        text: "Какой должна быть высота падения груза при погрузке горной массы в автомобиль?",
        answers: [
          "Не более 6 м",
          "Не более 5 м",
          "Не более 4 м",
          "Минимально возможной и во всех случаях не превышать 3 м",
        ],
        correctAnswers: [
          "Минимально возможной и во всех случаях не превышать 3 м",
        ],
      },
      {
        code: "20403113",
        text: "Где должны производиться шиномонтажные работы на карьерных автомобилях?",
        answers: [
          "В отдельных помещениях или на специальных участках, оснащенных необходимыми механизмами и ограждениями",
          "В ближайших к карьеру населенных пунктах, имеющих шиномонтажные мастерские",
          "В местах, расположенных не далее 100 м от автомобильных дорог",
          "На обочинах автомобильных дорог",
        ],
        correctAnswers: [
          "В отдельных помещениях или на специальных участках, оснащенных необходимыми механизмами и ограждениями",
        ],
      },
      {
        code: "20403114",
        text: "Какими должны быть проходы между ленточными конвейерами, передвигающимися по рельсам, и стенами здания или другим оборудованием?",
        answers: [
          "Не менее 0,5 м",
          "Не менее 0,75 м",
          "Не менее 0,8 м",
          "Не менее 1,0 м",
        ],
        correctAnswers: ["Не менее 1,0 м"],
      },
      {
        code: "20403115",
        text: "Какой должна быть скорость движения конвейерной ленты при ручной рудоразборке?",
        answers: [
          "Не более 0,5 м/с",
          "Не более 1 м/с",
          "Не более 1,5 м/с",
          "Не более 2 м/с",
        ],
        correctAnswers: ["Не более 0,5 м/с"],
      },
      {
        code: "20403116",
        text: "Как осуществляется устранение пробуксовки ленты конвейера?",
        answers: [
          "Устранение пробуксовки проводится после очистки барабанов и ленты и натяжки ленты натяжными устройствами",
          "Устранение пробуксовки проводится с помощью специального устройства при работающем конвейере",
          "Только вручную",
        ],
        correctAnswers: [
          "Устранение пробуксовки проводится после очистки барабанов и ленты и натяжки ленты натяжными устройствами",
        ],
      },
      {
        code: "20403117",
        text: "Кем должна быть утверждена схема электроснабжения, нанесенная на план горных работ?",
        answers: [
          "Ответственным за электрохозяйство организации",
          "Техническим руководителем организации",
          "Представителем Ростехнадзора",
          "Директором предприятия",
        ],
        correctAnswers: ["Техническим руководителем организации"],
      },
      {
        code: "20403118",
        text: "Каким должно быть расстояние от нижнего фазного провода линии до верхней точки автомобиля или груза при пересечении временных линий электропередачи с автомобильной дорогой при напряжении до 20 кВ?",
        answers: [
          "Не менее 0,5 м",
          "Не менее 1,0 м",
          "Не менее 1,5 м",
          "Не менее 2,0 м",
        ],
        correctAnswers: ["Не менее 2,0 м"],
      },
      {
        code: "20403119",
        text: "С какой периодичностью главными специалистами, ответственными за безопасную эксплуатацию электроустановок, должны проверяться все электрические машины, аппараты и трансформаторы?",
        answers: [
          "Еженедельно",
          "Не реже одного раза в месяц",
          "Не реже одного раза в три месяца",
          "Не реже одного раза в шесть месяцев",
        ],
        correctAnswers: ["Не реже одного раза в три месяца"],
      },
      {
        code: "20403120",
        text: "С какой периодичностью должна проверяться исправность действия (срабатывания) реле утечки тока в передвижных электроустановках до 1000 В?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 10 дней",
          "Не реже 1 раза в неделю",
          "Ежесменно перед началом работы",
        ],
        correctAnswers: ["Ежесменно перед началом работы"],
      },
      {
        code: "20403121",
        text: "С какой периодичностью должно производиться измерение сопротивления изоляции?",
        answers: [
          "Еженедельно",
          "Ежемесячно",
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в полгода",
        ],
        correctAnswers: ["Не реже одного раза в 3 месяца"],
      },
      {
        code: "20403122",
        text: "Как часто персонал, работающий на электроустановках, обязан производить осмотр защитных заземлений?",
        answers: [
          "Ежесменно",
          "1 раз в 3 дня",
          "Еженедельно",
          "1 раз в 2 недели",
        ],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20403123",
        text: "С какой периодичностью должно производиться измерение сопротивления общего заземляющего устройства передвижных электроустановок?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в год, а также после ремонта",
          "Периодичность всех осмотров устанавливается ответственным за электрохозяйство организации",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20403124",
        text: "Каким должно быть сопротивление общего заземляющего устройства открытых горных работ?",
        answers: ["Не более 4 Ом", "Не более 5 Ом", "Не более 6 Ом"],
        correctAnswers: ["Не более 4 Ом"],
      },
      {
        code: "20403125",
        text: "Сколько выходов должно быть в камерах подстанций длиной более 10 м?",
        answers: [
          "Один выход",
          "Два выхода, расположенных в наиболее удаленных друг от друга частях камеры",
          "Три выхода",
          "Не менее трех выходов",
        ],
        correctAnswers: [
          "Два выхода, расположенных в наиболее удаленных друг от друга частях камеры",
        ],
      },
      {
        code: "20403126",
        text: 'Какое требование установлено к коробкам выводов электрических машин и пускорегулирующей аппаратуры "Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          "Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть уплотнены и закрыты крышкой",
          "Коробки должны располагаться не выше 1,5 м от поверхности площадки",
          "Коробки должны быть окрашены в красный сигнальный цвет",
          "Крышки коробок должны быть прозрачными",
        ],
        correctAnswers: [
          "Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть уплотнены и закрыты крышкой",
        ],
      },
      {
        code: "20403127",
        text: "Что служит защитой электроприводов технологического оборудования при исчезновении или резком снижении напряжения питающей сети?",
        answers: [
          "Реле времени, обеспечивающее отложенный самозапуск механизмов после подачи напряжения питания",
          "Блокировка, исключающая самозапуск",
          "Световая сигнализация",
        ],
        correctAnswers: ["Блокировка, исключающая самозапуск"],
      },
      {
        code: "20403128",
        text: "Какие блокировки должна иметь защитная аппаратура?",
        answers: [
          "Против подачи напряжения на линии и электроустановки с пониженным сопротивлением изоляции относительно земли и после срабатывания защиты максимального тока",
          "Против подачи напряжения на электроустановки мощностью выше 1000 В",
          "Против скачков напряжения на линиях",
        ],
        correctAnswers: [
          "Против подачи напряжения на линии и электроустановки с пониженным сопротивлением изоляции относительно земли и после срабатывания защиты максимального тока",
        ],
      },
      {
        code: "20403129",
        text: "Какое напряжение необходимо для питания аппаратуры сигнализации?",
        answers: [
          "Не выше 60 В",
          "Не выше 220 В при наличии защиты от токов утечки",
          "Не выше 275 В",
          "Не выше 380 В при наличии защиты от токов утечки",
        ],
        correctAnswers: ["Не выше 220 В при наличии защиты от токов утечки"],
      },
      {
        code: "20403130",
        text: "На каких электроустановках запрещается оперативное обслуживание без применения специальных защитных средств?",
        answers: [
          "Определенных главным энергетиком",
          "Напряжением ниже 1000 В",
          "Напряжением выше 1000 В",
          "На любых",
        ],
        correctAnswers: ["Напряжением выше 1000 В"],
      },
      {
        code: "20403131",
        text: "Разрешается ли использовать электрозащитные средства по истечении срока их годности?",
        answers: [
          "Разрешается только в исключительных случаях",
          "Разрешается, если нет видимых повреждений",
          "Пользоваться защитными средствами с истекшим сроком годности запрещается",
        ],
        correctAnswers: [
          "Пользоваться защитными средствами с истекшим сроком годности запрещается",
        ],
      },
      {
        code: "20403132",
        text: "Каким требованиям должны соответствовать светильники с лампами накаливания, устанавливаемые в помещениях с повышенной опасностью ниже 2,5 м от пола?",
        answers: [
          "Должны быть переносными, и в них должно использоваться напряжение не выше 12 В",
          "Должны быть специальной конструкции либо быть рассчитаны на напряжение не выше 50 В с заземлением металлической арматуры",
          "В светильниках должно использоваться напряжение не выше 100 В, либо светильники должны иметь защитную решетку",
          "Должны быть во взрывозащищенном исполнении, и в них должно использоваться напряжение не выше 220 В",
        ],
        correctAnswers: [
          "Должны быть специальной конструкции либо быть рассчитаны на напряжение не выше 50 В с заземлением металлической арматуры",
        ],
      },
      {
        code: "20403133",
        text: "Как должна производиться замена ламп и светильников, расположенных ниже осветительных проводов на деревянных опорах?",
        answers: [
          "С применением дополнительных электрозащитных средств",
          "С применением деревянных подмостей и стремянок",
          "При напряжении осветительной сети не выше 50 В",
          "При снятом с контактного провода напряжении",
        ],
        correctAnswers: ["При снятом с контактного провода напряжении"],
      },
      {
        code: "20403134",
        text: "18Какое напряжение должно применяться для питания ручных переносных ламп, используемых на объектах открытых горных работ?",
        answers: [
          "Линейное напряжение - не выше 36 В переменного тока и до 50 В постоянного тока, а при тепловозной тяге - до 75 В постоянного тока",
          "Не выше 42 В переменного тока и до 75 В постоянного тока",
          "Не выше 42 В переменного тока и до 127 В постоянного тока",
          "Не выше 220 В переменного тока",
        ],
        correctAnswers: [
          "Линейное напряжение - не выше 36 В переменного тока и до 50 В постоянного тока, а при тепловозной тяге - до 75 В постоянного тока",
        ],
      },
      {
        code: "20403135",
        text: 'На какие виды работ распространяются "Правила безопасного ведения газоопасных, огневых и ремонтных работ"?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          "На ведение геологических и маркшейдерских работ",
          "На ведение всех видов горных работ",
          "На ведение буровых работ",
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "20403136",
        text: "Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в месячный срок",
          "По распоряжению директора с последующим их внесением в перечень газоопасных работ в пятнадцатидневный срок",
          "По наряду-допуску на проведение газоопасных работ",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
      {
        code: "20403137",
        text: "Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?",
        answers: [
          "Руководитель структурного подразделения или лицо, его замещающее, но не более чем на 1 дневную рабочую смену",
          "Заместитель директора по производству, но не более чем на 3 рабочие смены",
          "Начальник смены, но не более чем на 1 дневную рабочую смену",
          "Технический руководитель, но не более чем на 2 рабочие смены",
        ],
        correctAnswers: [
          "Руководитель структурного подразделения или лицо, его замещающее, но не более чем на 1 дневную рабочую смену",
        ],
      },
    ],
    20404: [
      {
        code: "20404000",
        text: "Кем утверждается акт о проведении опытно-промышленных испытаний?",
        answers: [
          "Техническим руководителем организации",
          "Лицом, ответственным за проведение ОПИ",
          "Руководителем организации",
        ],
        correctAnswers: ["Техническим руководителем организации"],
      },
      {
        code: "20404001",
        text: "Обязаны ли организации, эксплуатирующие опасные производственные объекты, на которых ведутся горные работы, заключать договоры на обслуживание с профессиональными аварийно-спасательными формированиями (службами)?",
        answers: [
          "Заключение договоров необязательно",
          "Заключение договоров обязательно",
          "Заключение договоров не требуется",
        ],
        correctAnswers: ["Заключение договоров обязательно"],
      },
      {
        code: "20404002",
        text: 'Когда рабочие, выполняющие работы повышенной опасности, перечень которых устанавливается руководителем организации, должны проходить обязательный медицинский осмотр (освидетельствование) на состояние алкогольного и наркотического опьянения согласно "Правилам безопасности при ведении горных работ и переработке твердых полезных ископаемых"? Выберите два правильных варианта ответа.',
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в полгода",
          "Обследование проводится только при поступлении на работу",
          "В начале рабочего дня (смены)",
          "В конце рабочего дня (смены) на основании решения руководителя объекта",
        ],
        correctAnswers: [
          "В начале рабочего дня (смены)",
          "В конце рабочего дня (смены) на основании решения руководителя объекта",
        ],
      },
      {
        code: "20404003",
        text: "Кто допускается к техническому руководству работами на объектах ведения горных работ и переработки полезных ископаемых?",
        answers: [
          "Лица, имеющие высшее образование",
          "Лица, имеющие высшее или среднее специальное соответствующее образование",
          "Лица, имеющие стаж работы на подземных объектах более 5 лет",
          "Лица, прошедшие проверку знаний в области охраны труда и промышленной безопасности",
        ],
        correctAnswers: [
          "Лица, имеющие высшее или среднее специальное соответствующее образование",
        ],
      },
      {
        code: "20404004",
        text: "Что из перечисленного входит в перечень совместных мероприятий по обеспечению безопасности при одновременной разработке месторождения подземным и открытым способами?",
        answers: [
          "Только согласование планов и графиков ведения горных и взрывных работ",
          "Только применение нагнетательной схемы проветривания подземных объектов",
          "Только проверка представителями профессионального аварийно-спасательного формирования (службы) состояния атмосферы в подземных объектах после массовых взрывов на объектах открытых горных работ",
          "Только предотвращение опасности прорыва воды в подземные горные выработки из объекта открытых горных работ",
          "Только наличие у лиц, входящих в состав сменного надзора, средств контроля за содержанием в атмосфере ядовитых продуктов взрыва",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20404005",
        text: "Где должны быть расположены выходы из мест ожидания?",
        answers: [
          "В непосредственной близости от ствола шахты",
          "В местах, определяемых расчетами",
          "В местах с благоприятными горно-геологическими условиями",
        ],
        correctAnswers: ["В непосредственной близости от ствола шахты"],
      },
      {
        code: "20404006",
        text: "Как машинист должен воспринимать каждый неправильно поданный или непонятный сигнал?",
        answers: [
          'Как сигнал "Вверх"',
          'Как сигнал "Вниз"',
          'Как сигнал "Стоп"',
          'Как сигнал "Назад"',
        ],
        correctAnswers: ['Как сигнал "Стоп"'],
      },
      {
        code: "20404007",
        text: "Какие из перечисленных условий рекомендуется включать в Регламенты технологических процессов по ведению очистных работ?",
        answers: [
          "По выбору систем разработки и способов выемки полезного ископаемого, начиная со стадии проектирования",
          "По параметрам систем разработки, обеспечивающим безопасность работ и сохранность подрабатываемых территорий и объектов",
          "По особенностям ведения работ в горно-геологических условиях конкретного месторождения и горнотехнических условиях шахты",
          "По способу управления горным давлением в очистном пространстве, включая паспорт крепления и проект организации работ",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20404008",
        text: "Кто должен выдать письменное разрешение для остановки технических устройств, обеспечивающих проветривание и кондиционирование горных выработок, водоснабжение, откачку воды, спуск и подъем персонала, работу МФСБ для выполнения ремонтных работ?",
        answers: [
          "Технический руководитель обособленного структурного подразделения",
          "Руководитель эксплуатирующей организации",
          "Руководитель Ростехнадзора",
          "Начальник службы аэрологической безопасности (АБ) предприятия",
        ],
        correctAnswers: [
          "Технический руководитель обособленного структурного подразделения",
        ],
      },
      {
        code: "20404009",
        text: "Каков максимально допустимый срок остановки производства горных работ, при котором эта остановка не требует безопасной организации возобновления горных работ?",
        answers: ["1 сутки", "2 суток", "3 суток", "4 суток"],
        correctAnswers: ["3 суток"],
      },
      {
        code: "20404010",
        text: "На какие из перечисленных видов производственных процессов рекомендуется разрабатывать регламенты технологических процессов при ведении горных работ подземным способом?",
        answers: [
          "Только на проходку шахтных стволов и подземных горных выработок",
          "Только на ведение очистных работ, закладочных работ",
          "Только на эксплуатацию технологического транспорта",
          "Только на защиту шахты от затопления и охрану объектов на дневной поверхности от вредного влияния горных работ, противопожарную защиту шахты",
          "На все перечисленные виды производственных процессов",
        ],
        correctAnswers: [
          "На все перечисленные виды производственных процессов",
        ],
      },
      {
        code: "20404011",
        text: "При отсутствии проектных регламентов кем могут разрабатываться регламенты на все основные и вспомогательные производственные процессы при ведении горных работ подземным способом?",
        answers: [
          "Эксплуатирующей организацией",
          "Организацией, проводящей экспертизу промышленной безопасности ОПО",
          "Специальной комиссией во главе с инспектором территориального органа Ростехнадзора",
          "Монтажно-наладочной организацией",
        ],
        correctAnswers: ["Эксплуатирующей организацией"],
      },
      {
        code: "20404012",
        text: "Какой вид лицензии необходимо иметь для права подготовки планов и схем развития горных работ?",
        answers: [
          "Лицензию на добычу полезного ископаемого",
          "Лицензию на геологическое изучение недр",
          "Лицензию на производство маркшейдерских работ",
          "Лицензию на детальное изучение (разведку) месторождений полезных ископаемых",
        ],
        correctAnswers: ["Лицензию на производство маркшейдерских работ"],
      },
      {
        code: "20404013",
        text: "По каким видам полезных ископаемых подготавливаются планы и схемы развития горных работ?",
        answers: [
          "Только по твердым полезным ископаемым",
          "Только по углеводородному сырью",
          "Только по общераспространенным полезным ископаемым",
          "Только по промышленным подземным водам",
          "По всем перечисленным",
        ],
        correctAnswers: ["По всем перечисленным"],
      },
      {
        code: "20404014",
        text: "Кем утверждается план и схема развития горных работ?",
        answers: [
          "Территориальным органом Ростехнадзора",
          "Руководителем организации - пользователя недр",
          "Главным техническим руководителем организации",
        ],
        correctAnswers: ["Руководителем организации - пользователя недр"],
      },
      {
        code: "20404015",
        text: "В какой срок пользователи недр письменно уведомляются органом горного надзора о времени и месте рассмотрения планов и (или) схем развития горных работ?",
        answers: [
          "Не позднее чем за 10 дней до установленной даты их рассмотрения",
          "Не позднее чем за 7 дней до установленной даты их рассмотрения",
          "Не позднее чем за 20 дней до установленной даты их рассмотрения",
          "Не позднее чем за 5 дней до установленной даты их рассмотрения",
        ],
        correctAnswers: [
          "Не позднее чем за 20 дней до установленной даты их рассмотрения",
        ],
      },
      {
        code: "20404016",
        text: "Каково минимально допустимое число запасных выходов на шахте, обеспечивающих выезд (выход) людей на поверхность и имеющих разное направление вентиляционных струй?",
        answers: ["2 выхода", "3 выхода", "4 выхода", "Не нормируется"],
        correctAnswers: ["2 выхода"],
      },
      {
        code: "20404017",
        text: "При какой глубине вертикальных стволов механический подъем в одном из них может отсутствовать, при условии наличия лестниц в обоих стволах?",
        answers: [
          "При глубине до 70 м",
          "При глубине до 80 м",
          "При глубине до 90 м",
          "При глубине до 100 м",
        ],
        correctAnswers: ["При глубине до 70 м"],
      },
      {
        code: "20404018",
        text: "Какова минимально допустимая ширина лестниц в вертикальных выработках?",
        answers: ["0,6 м", "0,4 м", "0,7 м", "1 м"],
        correctAnswers: ["0,4 м"],
      },
      {
        code: "20404019",
        text: "Куда запрещается устраивать выходы из восстающих выработок, оборудованных лестницами?",
        answers: [
          "Непосредственно на конвейерный штрек",
          "Непосредственно на околоствольный двор",
          "Непосредственно на откаточные выработки",
        ],
        correctAnswers: ["Непосредственно на откаточные выработки"],
      },
      {
        code: "20404020",
        text: "В соответствии с какими документами осуществляются проведение и крепление горных выработок?",
        answers: [
          "В соответствии с проектной документацией, технологическими регламентами и паспортами крепления и управления кровлей",
          "В соответствии с проектом разработки",
          "Только в соответствии с технологическим регламентом и паспортом крепления и управления кровлей",
        ],
        correctAnswers: [
          "В соответствии с проектной документацией, технологическими регламентами и паспортами крепления и управления кровлей",
        ],
      },
      {
        code: "20404021",
        text: "Какой должна быть ширина свободного прохода для людей в выработках с конвейерным транспортом?",
        answers: ["Не менее 0,5 м", "Не менее 0,6 м", "Не менее 0,7 м"],
        correctAnswers: ["Не менее 0,7 м"],
      },
      {
        code: "20404022",
        text: "Какой должна быть высота свободного прохода людей в горизонтальных горных выработках, где применяются рельсовые транспортные средства?",
        answers: ["Не менее 1,8 м", "Не менее 2,0 м", "Не менее 1,9 м"],
        correctAnswers: ["Не менее 2,0 м"],
      },
      {
        code: "20404023",
        text: "Какое расстояние должно быть между осями рельсовых путей в двухпутевых выработках?",
        answers: [
          "Расстояние между осями рельсовых путей в двухпутевых выработках должно быть такое, чтобы зазор между наиболее выступающими частями встречных транспортных средств был   не менее 0,2 м",
          "Расстояние между осями рельсовых путей в двухпутевых выработках должно быть такое, чтобы зазор между наиболее выступающими частями встречных транспортных средств был не менее 0,4 м",
          "Расстояние между осями рельсовых путей в двухпутевых выработках должно быть такое, чтобы зазор между наиболее выступающими частями встречных транспортных средств был не менее 0,1 м",
        ],
        correctAnswers: [
          "Расстояние между осями рельсовых путей в двухпутевых выработках должно быть такое, чтобы зазор между наиболее выступающими частями встречных транспортных средств был   не менее 0,2 м",
        ],
      },
      {
        code: "20404024",
        text: "Разрешается ли одновременное производство работ в наклонных выработках на различных отметках?",
        answers: [
          "Разрешается по письменному наряду",
          "Разрешается по согласованию с надзорными органами",
          "Запрещается",
        ],
        correctAnswers: ["Запрещается"],
      },
      {
        code: "20404025",
        text: 'Какое минимальное количество независимых сигнальных устройств должна иметь каждая подъемная установка при проходке и углублении стволов согласно "Правилам безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: ["Два", "Три", "Четыре"],
        correctAnswers: ["Два"],
      },
      {
        code: "20404026",
        text: "Кто ежесуточно должен осматривать крепь и армировку вертикальных и наклонных стволов шахт, служащих для подъема, спуска людей и грузов?",
        answers: [
          "Механик подъема",
          "Специально назначенные лица",
          "Лица технического надзора",
        ],
        correctAnswers: ["Специально назначенные лица"],
      },
      {
        code: "20404027",
        text: "В какие сроки проводится проверка маркшейдерской службой положения стенок шахтного ствола и проводников в нем?",
        answers: [
          "Срок устанавливается в проектной документации, но не реже 1 раза в год",
          "Срок устанавливается в зависимости от горно-геологических условий, но не реже 1 раза в 5 лет",
          "Срок устанавливается техническим руководителем эксплуатирующей организации для каждого ствола, но не реже 1 раза в 3 года",
        ],
        correctAnswers: [
          "Срок устанавливается техническим руководителем эксплуатирующей организации для каждого ствола, но не реже 1 раза в 3 года",
        ],
      },
      {
        code: "20404028",
        text: "С какой периодичностью необходимо проводить осмотр крепи и армировки вертикальных горных выработок?",
        answers: [
          "Не реже 1 раза в неделю",
          "Не реже 1 раза в день",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в год",
        ],
        correctAnswers: ["Не реже 1 раза в неделю"],
      },
      {
        code: "20404029",
        text: "Какова периодичность проверки состояния горных выработок, служащих запасными выходами?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в год",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20404030",
        text: "Чем из перечисленного оборудуются наклонные горные выработки, предназначенные для передвижения людей, при углах наклона от 31 до 45°?",
        answers: [
          "Лестницами с горизонтальными ступенями и перилами",
          "Сходнями со ступенями и перилами",
          "Трапами с перилами",
          "Трапами",
        ],
        correctAnswers: ["Лестницами с горизонтальными ступенями и перилами"],
      },
      {
        code: "20404031",
        text: "Каково максимально допустимое расстояние от конца вентиляционного трубопровода до забоя при проведении восстающих горных выработок?",
        answers: ["6 м", "8 м", "10 м", "15 м"],
        correctAnswers: ["6 м"],
      },
      {
        code: "20404032",
        text: "С кем необходимо согласовывать планы и схемы развития горных работ?",
        answers: [
          "С территориальными органами Ростехнадзора",
          "Со специализированными профессиональными аварийно-спасательными формированиями",
          "Со сторонними организациями, расположенными на территории горного отвода",
          "С органами исполнительной власти субъекта Российской Федерации, на территории которого эксплуатируется разрез",
        ],
        correctAnswers: ["С территориальными органами Ростехнадзора"],
      },
      {
        code: "20404033",
        text: "На какой срок разрабатывается план развития горных работ по всем планируемым видам горных работ?",
        answers: ["На 1 год", "На 3 года", "На 5 лет", "На 2 года"],
        correctAnswers: ["На 1 год"],
      },
      {
        code: "20404034",
        text: "На какой срок составляется план горных работ по всем планируемым видам горных работ?",
        answers: ["На 1 год", "На 5 лет", "На 3 года"],
        correctAnswers: ["На 1 год"],
      },
      {
        code: "20404035",
        text: "На какие перечисленные виды работ не составляются планы и схемы горных работ?",
        answers: [
          "На вскрышные работы",
          "На рекультивационные работы",
          "На работы, связанные со вторичной переработкой минерального сырья",
          "На маркшейдерские работы",
        ],
        correctAnswers: [
          "На работы, связанные со вторичной переработкой минерального сырья",
        ],
      },
      {
        code: "20404036",
        text: "Кем утверждаются план и схема развития горных работ?",
        answers: [
          "Органом государственного горного надзора",
          "Руководителем организации - пользователя недр",
          "Органом исполнительной власти субъекта Российской Федерации",
          "Техническим руководителем организации - пользователя недр",
        ],
        correctAnswers: ["Руководителем организации - пользователя недр"],
      },
      {
        code: "20404037",
        text: "На основе чего составляются планы и схемы развития горных работ?",
        answers: [
          "На основе локального плана проведения работ",
          "На основе технического регламента",
          "На основе рекомендаций территориальных комиссий по запасам полезных ископаемых Минприроды России",
          "На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами",
        ],
        correctAnswers: [
          "На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами",
        ],
      },
      {
        code: "20404038",
        text: "В какой срок планы и (или) схемы развития горных работ направляются пользователем недр в орган государственного горного надзора для рассмотрения?",
        answers: [
          "За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
          "За 10 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
          "За 7 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
          "За 5 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
        ],
        correctAnswers: [
          "За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
        ],
      },
      {
        code: "20404039",
        text: "В какой период, установленный Правилами подготовки, рассмотрения и согласования планов и схем развития горных работ по видам полезных ископаемых, осуществляется рассмотрение органами Ростехнадзора планов и схем развития горных работ?",
        answers: [
          "С 1 октября по 31 декабря года, предшествующего планируемому",
          "С 1 октября по 15 декабря года, предшествующего планируемому",
          "С 1 сентября по 15 декабря года, предшествующего планируемому",
          "С 20 сентября по 25 декабря года, предшествующего планируемому",
        ],
        correctAnswers: [
          "С 20 сентября по 25 декабря года, предшествующего планируемому",
        ],
      },
      {
        code: "20404040",
        text: "Что из перечисленного не является основанием для принятия решения об отказе в согласовании плана и (или) схемы развития горных работ?",
        answers: [
          "Выявление недостоверных сведений в представленных документах",
          "Отсутствие геологического и маркшейдерского обеспечения горных работ",
          "Отсутствие обоснования соблюдения условий безопасного недропользования",
          "Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями",
        ],
        correctAnswers: [
          "Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями",
        ],
      },
      {
        code: "20404041",
        text: "Кем должны быть подписаны планы (схемы) развития горных работ, направляемые пользователем недр в электронном виде?",
        answers: [
          "Комиссией пользователя недр (подрядной организации)",
          "Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом",
          "Техническим руководителем пользователя недр (подрядной организации)",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом",
        ],
      },
      {
        code: "20404042",
        text: "Какие данные не должны указываться в штампе графических материалов планов развития горных работ?",
        answers: [
          "Подписи лиц (с указанием должности), ответственных за руководство геологическими и маркшейдерскими работами",
          "Наименования организации, объекта недропользования (участка недр)",
          "Дата составления",
          "Все ответы неверны",
          "Название графического документа",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20404043",
        text: 'Какой порядок одновременного ведения очистных работ на смежных этажах предусмотрен "Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          "При одновременном ведении очистных работ на смежных этажах забои верхнего этажа должны опережать забои нижнего этажа на безопасное расстояние, определенное проектом",
          "При одновременном ведении очистных работ на смежных этажах забои нижнего этажа должны опережать забои верхнего этажа на безопасное расстояние, определенное проектом",
          "При одновременном ведении очистных работ на смежных этажах забои нижнего этажа должны опережать забои верхнего этажа на расстояние не более 3 м",
        ],
        correctAnswers: [
          "При одновременном ведении очистных работ на смежных этажах забои верхнего этажа должны опережать забои нижнего этажа на безопасное расстояние, определенное проектом",
        ],
      },
      {
        code: "20404044",
        text: "Какой должна быть высота свободного прохода на штреках (ортах) скреперования?",
        answers: [
          "На штреках (ортах) скреперования должен всегда сохраняться свободный проход по высоте   не менее 2/3 высоты выработки",
          "На штреках (ортах) скреперования должен всегда сохраняться свободный проход по высоте не менее 1,8 м",
          "На штреках (ортах) скреперования должен всегда сохраняться свободный проход по высоте, который определен проектом",
        ],
        correctAnswers: [
          "На штреках (ортах) скреперования должен всегда сохраняться свободный проход по высоте   не менее 2/3 высоты выработки",
        ],
      },
      {
        code: "20404045",
        text: "Каким способом производится ликвидация зависаний, образовавшихся сводов в отбитой руде?",
        answers: [
          "Ликвидация зависаний, образовавшихся сводов в отбитой руде (в очистном пространстве) должна производиться под надзором лиц, имеющих право руководить горными работами",
          "Взрыванием зарядов с применением детонирующего шнура, подаваемого на шестах из безопасного места",
          "Ликвидация зависаний, образовавшихся сводов в отбитой руде (в очистном пространстве) должна производиться любыми методами",
        ],
        correctAnswers: [
          "Взрыванием зарядов с применением детонирующего шнура, подаваемого на шестах из безопасного места",
        ],
      },
      {
        code: "20404046",
        text: "Разрешается ли применение системы разработки с обрушением руды и вмещающих пород при наличии в налегающих породах плывунов, неосушенных песков, карстов и т. д.?",
        answers: [
          "Разрешается по паспорту   буровзрывных работ",
          "Разрешается при согласовании с органами надзора",
          "Запрещается",
        ],
        correctAnswers: ["Запрещается"],
      },
      {
        code: "20404047",
        text: "Как производится посадка кровли?",
        answers: [
          "Посадка кровли производится под непосредственным руководством лица технического надзора в соответствии с проектом организации работ",
          "Посадка кровли производится в соответствии с проектом организации работ",
          "Посадка кровли производится под непосредственным руководством специалиста по производственному контролю",
        ],
        correctAnswers: [
          "Посадка кровли производится под непосредственным руководством лица технического надзора в соответствии с проектом организации работ",
        ],
      },
      {
        code: "20404048",
        text: "На какое расстояние при одновременной отработке нескольких подэтажей системой подэтажного обрушения каждый верхний подэтаж должен опережать нижний?",
        answers: [
          "На расстояние, устанавливаемое проектом, но не менее чем на длину, равную высоте одного подэтажа",
          "Не менее чем на расстояние, равное высоте двух этажей",
          "Расстояние определяется проектом",
        ],
        correctAnswers: [
          "На расстояние, устанавливаемое проектом, но не менее чем на длину, равную высоте одного подэтажа",
        ],
      },
      {
        code: "20404049",
        text: "По какому документу осуществляется выемка целиков?",
        answers: [
          "По паспорту буровзрывных работ",
          "По паспорту крепления и управления кровлей",
          "По проекту",
        ],
        correctAnswers: ["По проекту"],
      },
      {
        code: "20404050",
        text: 'Каким образом участки горных выработок категории "Опасно" приводят в неудароопасное состояние?',
        answers: [
          "Путем создания в краевой части массива пород защитной зоны шириной n+2 м, но не менее 2 м",
          "Путем создания в краевой части массива пород защитной зоны шириной не менее 1 м",
          "Путем создания в краевой части массива пород защитной зоны шириной n+1 м, но не более 2 м",
          "Путем создания в краевой части массива пород защитной зоны шириной не более 1 м",
        ],
        correctAnswers: [
          "Путем создания в краевой части массива пород защитной зоны шириной n+2 м, но не менее 2 м",
        ],
      },
      {
        code: "20404051",
        text: "Каким должно быть расстояние между стенками стволов при строительстве шахт на месторождениях, склонных и опасных по горным ударам?",
        answers: [
          "Не менее 50 м",
          "Не менее 45 м",
          "Не менее 40 м",
          "Не менее 35 м",
        ],
        correctAnswers: ["Не менее 50 м"],
      },
      {
        code: "20404052",
        text: "В каком случае нарушены требования безопасности при проходке стволов?",
        answers: [
          "Сбойка двух выработок на участках, склонных и опасных по горным ударам, с расстояния между забоями 15 м должна вестись двумя забоями",
          "Пересечение выработок должно осуществляться под прямым или близким к нему углом. Очередность проведения выработок регламентируется Указаниями",
          "При проходке стволов допускается отставание постоянной крепи от забоя на расстояние не более одного диаметра ствола вчерне",
          'Образующийся при сбойке целик должен быть приведен при категории "Опасно" в неопасное состояние по всей площади',
        ],
        correctAnswers: [
          "Сбойка двух выработок на участках, склонных и опасных по горным ударам, с расстояния между забоями 15 м должна вестись двумя забоями",
        ],
      },
      {
        code: "20404053",
        text: "Что запрещено при комбинированной разработке месторождений?",
        answers: [
          "Применение нагнетательного способа проветривания подземных выработок",
          "Ограничение мощности массовых взрывов в карьере и подземных выработках",
          "Применение систем разработки, исключающих сдвижение (разрушение) массива предохранительного целика",
          "Несвоевременная отработка и разрушение рудных целиков и потолочин в руднике от массовых взрывов",
        ],
        correctAnswers: [
          "Несвоевременная отработка и разрушение рудных целиков и потолочин в руднике от массовых взрывов",
        ],
      },
      {
        code: "20404054",
        text: "Какие из перечисленных факторов должны учитываться при проектировании, строительстве и эксплуатации горнорудных предприятий с комбинированной (совмещенной) разработкой?",
        answers: [
          "Только подработанность массивов горных пород, слагающих борта карьера",
          "Только наличие подземных пустот в границах и под дном карьера",
          "Только исключение прорывов в подземные горные выработки паводковых и ливневых вод",
          "Все перечисленные факторы",
        ],
        correctAnswers: ["Все перечисленные факторы"],
      },
      {
        code: "20404055",
        text: "Каким образом доводятся до специалистов и горнорабочих дополнительные меры безопасности перед производством массового взрыва?",
        answers: [
          "Приказом под подпись",
          "Начальником отдела промышленной безопасности в устной форме",
          "Командиром профессиональной аварийно-спасательной службы (формирования) под подпись в журнале инструктажа",
          "Руководителем карьера под подпись в журнале взрывных работ",
        ],
        correctAnswers: ["Приказом под подпись"],
      },
      {
        code: "20404056",
        text: "Через какой минимальный промежуток времени после массового взрыва по разрушению целиков разрешается спуск в шахту представителей профессиональной аварийно-спасательной службы (формирования)?",
        answers: ["Через час", "Через 2 часа", "Через 3 часа", "Через 4 часа"],
        correctAnswers: ["Через 2 часа"],
      },
      {
        code: "20404057",
        text: "Когда могут быть допущены работники шахты на место проведенного массового взрыва?",
        answers: [
          "Только после восстановления нормальной рудничной атмосферы и проверки начальником участка состояния действующих выработок",
          "Только после приведения выработок в безопасное состояние и проверки аварийно-спасательным формированием, но не ранее чем через 2 часа после взрыва",
          "После восстановления нормальной рудничной атмосферы, приведения выработок в безопасное состояние и проверки аварийно-спасательным формированием, но не ранее чем через 8 часов после взрыва",
        ],
        correctAnswers: [
          "После восстановления нормальной рудничной атмосферы, приведения выработок в безопасное состояние и проверки аварийно-спасательным формированием, но не ранее чем через 8 часов после взрыва",
        ],
      },
      {
        code: "20404058",
        text: "В каком случае нарушены требования к системам вентиляции при (совмещенной) разработке месторождения?",
        answers: [
          "Способы проветривания рудников (шахт) определяются проектом с учетом образования избыточной депрессии в зоне влияния открытых работ",
          "При комбинированной (совмещенной) разработке месторождения и наличии аэродинамических связей подземных выработок с карьерным пространством предусматривается нагнетательный способ общерудничной системы проветривания",
          "Вентиляция горных работ при вскрытии и отработке запасов руд, находящихся в бортах и под дном карьера, может быть принята только локальной через вскрывающие выработки из карьера",
          "Вспомогательные нагнетательные вентиляторы должны быть максимально приближены к зонам аэродинамических связей с обеспечением подпора воздуха в подземных выработках",
        ],
        correctAnswers: [
          "Вентиляция горных работ при вскрытии и отработке запасов руд, находящихся в бортах и под дном карьера, может быть принята только локальной через вскрывающие выработки из карьера",
        ],
      },
      {
        code: "20404059",
        text: "В каком случае нарушены требования к осушению и водоотливу при комбинированной (совмещенной) разработке месторождений с неблагоприятными гидрогеологическими условиями?",
        answers: [
          "Горные работы в пределах барьерного или предохранительного целика под водоемом (затопленным карьером) должны производиться только после спуска воды из затопленных выработок",
          "Допускается частичная отработка барьерных целиков без предварительного отвода воды по проекту, утвержденному руководителем предприятия и согласованному со специализированной организацией",
          "Вода из карьера в подземные выработки должна поступать самотеком через дренажные скважины с обязательным применением забивных или сквозных фильтров",
          "Допускается  использовать действующие рудоспуски и вентиляционные восстающие в качестве дренажных выработок для перепуска грунтовых и паводковых вод из карьера",
        ],
        correctAnswers: [
          "Допускается  использовать действующие рудоспуски и вентиляционные восстающие в качестве дренажных выработок для перепуска грунтовых и паводковых вод из карьера",
        ],
      },
      {
        code: "20404060",
        text: "Какие участки относятся к наиболее нагруженным на месторождениях, склонных к горным ударам?",
        answers: [
          "Участки рудного массива и массива горных пород в зоне опорного давления от очистных работ",
          "Сопряжения выработок и передовые выработки",
          "Участки рудного массива и массива горных пород с дизъюнктивными и пликативными геологическими нарушениями",
          "Опасные зоны, выявленные в результате регионального прогноза",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20404061",
        text: "Чему должно быть равно минимальное расстояние между ближайшими боками параллельных выработок и камер различного назначения на удароопасных участках до глубины 1000 м относительно наибольшего размера d поперечного сечения большей выработки?",
        answers: [
          "Не менее 3dS",
          "Не менее 4dS",
          "Не менее 2dS",
          "Не менее 5dS",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не менее 4dS"],
      },
      {
        code: "20404062",
        text: "В течение какого срока технический руководитель объекта с инженерными службами рассматривает случаи микроударов, толчков, стреляния, интенсивного заколообразования и шелушения при первом их появлении в целях установления причин и разработки мер борьбы с ними?",
        answers: [
          "В течение суток",
          "В течение 5 рабочих дней",
          "В течение месяца",
          "В течение 10 рабочих дней",
          "Все ответы неверны",
        ],
        correctAnswers: ["В течение суток"],
      },
      {
        code: "20404063",
        text: "В каком направлении должны ориентироваться основные горизонтальные и наклонные выработки околоствольных дворов, включая камеры?",
        answers: [
          "В направлении действия минимального горизонтального напряжения нетронутого массива",
          "В направлении действия максимального горизонтального напряжения нетронутого массива",
          "В направлении действия максимального вертикального напряжения нетронутого массива",
          "В направлении действия минимального вертикального напряжения нетронутого массива",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "В направлении действия максимального горизонтального напряжения нетронутого массива",
        ],
      },
      {
        code: "20404064",
        text: "Кто на каждом объекте, отрабатывающем месторождения, склонном и опасном по горным ударам, производит учет всех случаев горных ударов, микроударов и толчков, стреляний, интенсивного заколообразования и шелушения, результатов определения категорий удароопасности выработок, примененных мер по предотвращению горных ударов с оценкой их эффективности?",
        answers: [
          "Специалисты службы или маркшейдерско-геологической службы",
          "Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении",
          "Технический руководитель эксплуатирующей объект ведения горных работ организации",
        ],
        correctAnswers: [
          "Специалисты службы или маркшейдерско-геологической службы",
        ],
      },
      {
        code: "20404065",
        text: "Куда должен сообщать технический руководитель организации о каждом случае горного удара и микроудара?",
        answers: [
          "Все ответы неверны",
          "В территориальный орган Ростехнадзора",
          "В службу технического надзора",
          "Руководителю организации",
          "В диспетчерскую службу",
          "Во все перечисленные службы",
        ],
        correctAnswers: ["В территориальный орган Ростехнадзора"],
      },
      {
        code: "20404066",
        text: "На какие виды могут подразделяться прогнозы удароопасности участков массива горных пород? Укажите все правильные ответы.",
        answers: [
          "Федеральный прогноз",
          "Региональный прогноз",
          "Местный прогноз",
          "Локальный прогноз",
          "Все ответы неверны",
        ],
        correctAnswers: ["Региональный прогноз", "Локальный прогноз"],
      },
      {
        code: "20404067",
        text: "Что из перечисленного необходимо соблюдать при проектировании вскрытия, подготовки, систем разработки на месторождениях, склонных и опасных по горным ударам?",
        answers: [
          "Только сокращение применения систем разработки с открытым очистным пространством",
          "Только снижение количества горных выработок, проводимых вблизи фронта очистных работ, особенно в зонах опорного давления",
          "Только использование опережающей отработки защитных залежей (слоев), в том числе бурение разгрузочных скважин большого диаметра, расположение выработок в защищенных зонах",
          "Только разделение месторождения на шахтные поля и порядок их отработки, обеспечивающие планомерное извлечение запасов по возможности без образования целиков, острых углов и выступов фронта очистных работ",
          "Все перечисленное, включая преимущественное проведение горных выработок вне зоны влияния тектонических нарушений и по направлению наибольшего напряжения в массиве горных пород",
          "Только ограничение встречных и догоняющих фронтов очистных работ",
        ],
        correctAnswers: [
          "Все перечисленное, включая преимущественное проведение горных выработок вне зоны влияния тектонических нарушений и по направлению наибольшего напряжения в массиве горных пород",
        ],
      },
      {
        code: "20404068",
        text: "Какой категории по степени опасности участков горного массива вокруг выработок не существует?",
        answers: ["Опасно", "Неопасно", "Чрезвычайно опасно"],
        correctAnswers: ["Чрезвычайно опасно"],
      },
      {
        code: "20404069",
        text: "Чем должно быть регламентировано приведение горных выработок в неудароопасное состояние?",
        answers: [
          "Проектом, утвержденным председателем Комиссии (техническим руководителем эксплуатирующей объект ведения горных работ организации)",
          "Техническими условиями, разработанными специализированной организацией",
          "Локальным прогнозом удароопасности",
          "Указаниями научно-исследовательского института",
        ],
        correctAnswers: [
          "Проектом, утвержденным председателем Комиссии (техническим руководителем эксплуатирующей объект ведения горных работ организации)",
        ],
      },
      {
        code: "20404070",
        text: "В каком случае месторождение (объект строительства подземных сооружений) будет относиться к опасному по горным ударам?",
        answers: [
          'Только если прогнозом при определении степени удароопасности массива горных пород локальными инструментальными методами выявлена категория "Опасно" (удароопасная)',
          "Только если имело место стреляние пород, интенсивное заколообразование, происходили толчки или горные удары на соседнем руднике (шахте) в пределах того же рудного тела и в аналогичных геологических условиях",
          "Только если имели место микроудары и горные удары",
          "В любом из перечисленных случаев",
        ],
        correctAnswers: ["В любом из перечисленных случаев"],
      },
      {
        code: "20404071",
        text: "При выполнении каких из перечисленных профилактических мероприятий должно осуществляться проведение и поддержание выработок на месторождениях, на которых происходили горные удары?",
        answers: [
          "Только крепление выработок",
          "Только создание устойчивых форм",
          "Только бурение разгрузочных щелей и скважин",
          "Только контурное взрывание",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20404072",
        text: "В каких случаях должен производиться контроль удароопасности в выработках?",
        answers: [
          'При изменениях горнотехнической обстановки во время ведения горных работ в местах, где ранее была установлена категория "Опасно"',
          'При изменениях горно-геологической обстановки во время ведения горных работ в местах, где ранее была установлена категория "Опасно"',
          "Во всех перечисленных случаях",
          "При проявлении внешних признаков удароопасности",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20404073",
        text: "Кем утверждается проект работ и мероприятий по ликвидации последствий горного удара?",
        answers: [
          "Руководителем горноспасательных работ",
          "Техническим руководителем объекта",
          "Руководителем организации",
          "Специализированной организацией, выполняющей работы по проблеме горных ударов на данном месторождении",
        ],
        correctAnswers: ["Техническим руководителем объекта"],
      },
      {
        code: "20404074",
        text: "Кто осуществляет научно-методическое сопровождение деятельности cлужбы прогноза и предотвращения горных ударов?",
        answers: [
          "Комиссия по горным ударам",
          "Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении",
          "Территориальный орган Ростехнадзора",
          "Технический руководитель организации",
        ],
        correctAnswers: [
          "Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении",
        ],
      },
      {
        code: "20404075",
        text: "Через какое время после производства массовых взрывов разрешается допуск работников на рабочие места?",
        answers: [
          "Не ранее чем через 30 минут после взрыва",
          "Не ранее чем через 90 минут после взрыва",
          "Не ранее чем через 60 минут после взрыва",
          "Не ранее чем через 10 минут после взрыва",
        ],
        correctAnswers: ["Не ранее чем через 30 минут после взрыва"],
      },
      {
        code: "20404076",
        text: "В каком разделе указаний по безопасному ведению горных работ на месторождениях, склонных и опасных по горным ударам, представляется характеристика горных ударов, других геодинамических явлений, отражающих специфику геологических условий и технологии отработки месторождения?",
        answers: [
          "Общие положения",
          "Порядок вскрытия, подготовки к выемке и отработки выемочных участков, блоков",
          "Введение",
          "Все ответы неверны",
          "Методы прогноза удароопасности участков массива горных пород и руд",
        ],
        correctAnswers: ["Общие положения"],
      },
      {
        code: "20404077",
        text: "Какие из перечисленных задач выполняет служба прогноза и предотвращения горных ударов? Укажите все правильные ответы.",
        answers: [
          "Разработка решений по предотвращению горных ударов, проведение горно-экспериментальных работ, испытание и внедрение новых методов прогноза и способов предотвращения горных ударов",
          'Безотлагательное отнесение месторождения к удароопасным в случае неожиданных проявлений горных ударов или установления категории "Опасно"',
          "Решение вопросов ежегодного подтверждения отнесения месторождения к склонным или опасным по горным ударам",
          "Проведение регионального прогноза удароопасности шахтных полей с целью выявления удароопасных участков и разработка мероприятий по безопасному ведению горных работ",
        ],
        correctAnswers: [
          "Разработка решений по предотвращению горных ударов, проведение горно-экспериментальных работ, испытание и внедрение новых методов прогноза и способов предотвращения горных ударов",
          "Проведение регионального прогноза удароопасности шахтных полей с целью выявления удароопасных участков и разработка мероприятий по безопасному ведению горных работ",
        ],
      },
      {
        code: "20404078",
        text: "Какой практический стаж подземных работ должен иметь горный инженер для назначения на должность начальника службы прогноза и предотвращения горных ударов?",
        answers: [
          "Не менее 1 года",
          "Не менее 3 лет",
          "Не менее 2 лет",
          "Не менее 5 лет",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не менее 3 лет"],
      },
      {
        code: "20404079",
        text: "Кто несет персональную ответственность за своевременное размещение в планах горных работ результатов регионального и локального прогноза удароопасности?",
        answers: [
          "Председатель комиссии по горным ударам",
          "Технический руководитель организации",
          "Начальник службы прогноза и предотвращения горных ударов",
          "Руководитель организации",
        ],
        correctAnswers: [
          "Начальник службы прогноза и предотвращения горных ударов",
        ],
      },
      {
        code: "20404080",
        text: "Кем утверждается структура и количественный состав службы прогноза и предотвращения горных ударов?",
        answers: [
          "Экспертной организацией",
          "Комиссией по горным ударам",
          "Начальником службы прогноза и предотвращения горных ударов",
          "Техническим руководителем организации",
        ],
        correctAnswers: ["Комиссией по горным ударам"],
      },
      {
        code: "20404081",
        text: "Каким из перечисленных прав не обладает комиссия по горным ударам?",
        answers: [
          "Останавливать горные работы, если нарушены требования Правил и Указаний, касающиеся вопросов безопасной отработки участков, склонных и опасных по горным ударам",
          "Приглашать на заседания комиссии представителей организаций, работающих по проблеме борьбы с горными ударами",
          "Получать от руководителей объектов необходимую документацию по описанию мер по борьбе с горными ударами",
          "Штрафовать ответственных лиц в случае нарушения требований, касающихся вопросов безопасной отработки участков, склонных и опасных по горным ударам",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Штрафовать ответственных лиц в случае нарушения требований, касающихся вопросов безопасной отработки участков, склонных и опасных по горным ударам",
        ],
      },
      {
        code: "20404082",
        text: "В каком случае горные работы следует производить с применением комплекса мер по профилактике горных ударов, высыпаний, вывалов и обрушения пород (руд) на участках рудного массива или массива горных пород?",
        answers: [
          "Расположенных на любом удалении от плоскости сместителя тектонического нарушения",
          "Данный комплекс мер допускается не производить на участках рудного массива или массива горных пород",
          "Расположенных на удалении 5 - 10 м от плоскости сместителя тектонического нарушения",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Расположенных на удалении 5 - 10 м от плоскости сместителя тектонического нарушения",
        ],
      },
      {
        code: "20404083",
        text: "Разработку каких рудных тел необходимо производить преимущественно системой одностадийного этажного или подэтажного принудительного обрушения с отбойкой руды на зажатую среду?",
        answers: [
          "Крутопадающих мощных рудных тел",
          "Пологих рудных тел",
          "Наклонных рудных тел",
          "Горизонтальных рудных тел",
          "Все ответы неверны",
        ],
        correctAnswers: ["Крутопадающих мощных рудных тел"],
      },
      {
        code: "20404084",
        text: "В каком случае допускаются отступления от последовательного продвигания фронта очистных работ в пределах как шахтного поля (участка), так и месторождения в целом, при отработке месторождений, склонных и опасных по горным ударам?",
        answers: [
          "Если данные отступления определяются указаниями по безопасному ведению горных работ на месторождении, склонном и опасном по горным ударам",
          "Не допускаются ни в каком случае",
          "С разрешения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
        ],
        correctAnswers: [
          "С разрешения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
        ],
      },
      {
        code: "20404085",
        text: 'Какие из перечисленных требований на участках категории "Опасно" указаны верно? Укажите все правильные ответы.',
        answers: [
          'При проходке стволов на участках категории "Опасно" необходимо применять передовые разгрузочные щели с таким расчетом, чтобы плоскость щели располагалась перпендикулярно направлению действия максимальных горизонтальных напряжений',
          "При расположении стволов в породах, опасных по горным ударам, должны быть запроектированы и выполняться мероприятия, обеспечивающие снижение до неопасных значений концентраций напряжений в стенках ствола",
          "Мероприятия по предотвращению горных ударов должны проводиться после возведения в стволе постоянной крепи",
          "В особо сложных условиях мероприятия по предотвращению горных ударов должны утверждаться представителем территориального управления Ростехнадзора",
        ],
        correctAnswers: [
          'При проходке стволов на участках категории "Опасно" необходимо применять передовые разгрузочные щели с таким расчетом, чтобы плоскость щели располагалась перпендикулярно направлению действия максимальных горизонтальных напряжений',
          "При расположении стволов в породах, опасных по горным ударам, должны быть запроектированы и выполняться мероприятия, обеспечивающие снижение до неопасных значений концентраций напряжений в стенках ствола",
        ],
      },
      {
        code: "20404086",
        text: "Какими методами производится локальный прогноз удароопасности участков массива горных пород и руд, а также оценка эффективности мер предотвращения горных ударов?",
        answers: [
          "Геомеханическими методами",
          "Геофизическими методами",
          "Визуальными наблюдениями за разрушением приконтурного массива выработок",
          "Всеми перечисленными",
        ],
        correctAnswers: ["Всеми перечисленными"],
      },
      {
        code: "20404087",
        text: "На какой основе осуществляется изменение параметров противоударных мероприятий и периодичности прогноза степени удароопасности, предусмотренных Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых?",
        answers: [
          "На основе специальных исследований и заключения специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
          "Изменение параметров и периодичности не осуществляется ни в каком случае",
          "На основе специальных исследований и заключения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
          "На основе заключения представителя территориального управления Ростехнадзора",
        ],
        correctAnswers: [
          "На основе специальных исследований и заключения специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
        ],
      },
      {
        code: "20404088",
        text: "Кем утверждается документация по ведению горных работ?",
        answers: [
          "Специалистом, ответственным за осуществление производственного контроля",
          "Техническим руководителем обособленного структурного подразделения",
          "Главным механиком",
          "Главным инженером проекта",
        ],
        correctAnswers: [
          "Техническим руководителем обособленного структурного подразделения",
        ],
      },
      {
        code: "20404089",
        text: "Кто разрабатывает указания по безопасному ведению горных работ на месторождении, склонном и опасном по горным ударам?",
        answers: [
          "Технический руководитель организации",
          "Комиссия, состоящая из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
          "Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении",
          "Представитель территориального управления Ростехнадзора",
        ],
        correctAnswers: [
          "Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении",
        ],
      },
      {
        code: "20404090",
        text: "В каком случае допускается списание запасов полезных ископаемых при консервации опасных производственных объектов, связанных с пользованием недрами?",
        answers: [
          "При мокрой консервации",
          "Не допускается ни в каком случае",
          "Если это определяется в проекте консервации",
          "При сухой консервации",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20404091",
        text: "В соответствии с каким документом производится возобновление работ по эксплуатации опасного производственного объекта, связанного с пользованием недрами, находящегося на консервации?",
        answers: [
          "В соответствии с техническим проектом, согласованным с Ростехнадзором",
          "В соответствии с актом проверяющей комиссии",
          "В соответствии с техническим проектом, утвержденным проектной организацией",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "В соответствии с техническим проектом, согласованным с Ростехнадзором",
        ],
      },
      {
        code: "20404092",
        text: "С кем согласовывается состав комиссии по технической ликвидации или консервации опасного производственного объекта, связанного с пользованием недрами, и положение о ее деятельности?",
        answers: [
          "С Росприроднадзором",
          "С Минприроды России",
          "С Ростехнадзором",
          "Со всеми перечисленными органами",
        ],
        correctAnswers: ["Со всеми перечисленными органами"],
      },
      {
        code: "20404093",
        text: "Кем утверждается задание на проектирование ликвидации или консервации опасных производственных объектов, связанных с пользованием недрами?",
        answers: [
          "Росприроднадзором",
          "Все ответы неверны",
          "Комиссией по технической ликвидации или консервации объекта",
          "Ростехнадзором",
          "Организацией, принявшей решение о ликвидации или консервации объекта",
        ],
        correctAnswers: [
          "Организацией, принявшей решение о ликвидации или консервации объекта",
        ],
      },
      {
        code: "20404094",
        text: "При каком условии утверждается проект ликвидации или консервации опасных производственных объектов, связанных с пользованием недрами?",
        answers: [
          "При наличии согласования с Ростехнадзором",
          "При наличии экспертизы промышленной безопасности",
          "При наличии положительного заключения государственной экологической экспертизы",
          "При всех перечисленных условиях",
        ],
        correctAnswers: ["При всех перечисленных условиях"],
      },
      {
        code: "20404095",
        text: "Какое количество экземпляров проекта акта о ликвидации или консервации опасных производственных объектов, связанных с пользованием недрами, готовит пользователь недр?",
        answers: [
          "4 экземпляра",
          "5 экземпляров",
          "3 экземпляра",
          "2 экземпляра",
        ],
        correctAnswers: ["4 экземпляра"],
      },
      {
        code: "20404096",
        text: "Что должен содержать специальный проект, по которому осуществляются горные работы при комбинированной разработке месторождений полезных ископаемых, наряду с принятыми техническими решениями?",
        answers: [
          "Обоснование производственной (технической) возможности и технико-экономической целесообразности комбинированной разработки месторождения",
          "Параметры карьерного и шахтного полей с запасами руды, намечаемые к отработке открытым и подземным способами",
          "Функции и ответственность инженерно-технических служб рудника",
          "Все перечисленное",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20404097",
        text: "Какое из перечисленных требований не допускается при комбинированной разработке месторождений?",
        answers: [
          "Расположение фронта ведения горных работ в направлении навстречу фронту развития подземных очистных работ при открытых работах",
          "Расположение фронта ведения горных работ в направлении от массива к карьеру при подземных очистных работах",
          "Добыча руды подземным способом из проектных контуров карьера",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Добыча руды подземным способом из проектных контуров карьера",
        ],
      },
      {
        code: "20404098",
        text: "Какое из перечисленных требований к рудоспускам указано неверно?",
        answers: [
          "При выполнении работ по переносу устья рудоспуска из карьера и ведении ремонтных работ число капитальных действующих рудоспусков с карьера в подземные выработки должно быть не менее двух",
          "При перепуске руд глинистых, обводненных, склонных к слеживанию и самовозгоранию допускается аккумулирование руды в рудоспуске",
          "При отработке верхней части рудоспусков они должны быть заполнены до подошвы уступа",
          "Каждый рудоспуск необходимо обустроить бетонными предохранительными барьерами высотой не менее 0,33 и шириной не менее 0,5 диаметра колеса автосамосвала",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "При перепуске руд глинистых, обводненных, склонных к слеживанию и самовозгоранию допускается аккумулирование руды в рудоспуске",
        ],
      },
      {
        code: "20404099",
        text: "Каким должно быть содержание углекислого газа в рудничном воздухе на рабочих местах?",
        answers: ["Не более 0,75 %", "Не менее 1 %", "Не более 0,5 %"],
        correctAnswers: ["Не более 0,5 %"],
      },
      {
        code: "20404100",
        text: "Какую температуру должен иметь воздух, поступающий в подземные горные выработки?",
        answers: ["Не менее 1 оС", "Не менее 2 оС", "Не менее 0 оС"],
        correctAnswers: ["Не менее 2 оС"],
      },
      {
        code: "20404101",
        text: "Каким образом должны проветриваться камеры служебного назначения?",
        answers: [
          "За счет диффузии",
          "Комбинированным способом (за счет диффузии и подачи воздуха с исходящей струи)",
          "Обособленной струей свежего воздуха с выбросом отработанного воздуха непосредственно на исходящую струю участка (шахты)",
        ],
        correctAnswers: [
          "Обособленной струей свежего воздуха с выбросом отработанного воздуха непосредственно на исходящую струю участка (шахты)",
        ],
      },
      {
        code: "20404102",
        text: "Какой способ вентиляции должны иметь шахты?",
        answers: [
          "Искусственную вентиляцию",
          "Проветривание за счет естественной тяги",
          "Нагнетательную вентиляцию",
        ],
        correctAnswers: ["Искусственную вентиляцию"],
      },
      {
        code: "20404103",
        text: 'В каких случаях из перечисленных должен устанавливаться "газовый режим" в шахтах?',
        answers: [
          "Только при наличии соответствующих геологических данных - про­ектом разработки месторождения перед началом строительства",
          "Только при обнаружении горючих, ядовитых газов, при установлении опасности взрыва пыли в процессе ведения горных работ или эксплуатации объекта - немедленно техническим руководителем организации с изданием совместного приказа с территориальным органом федерального органа исполнительной власти, уполномоченного в области промышленной безопасности",
          "В любом из перечисленных случаев - в зависимости от конкретных условий. Может быть распространен на отдельные рабочие зоны (участки, блоки, панели, пласты, горизонты) или на шахту в целом",
        ],
        correctAnswers: [
          "В любом из перечисленных случаев - в зависимости от конкретных условий. Может быть распространен на отдельные рабочие зоны (участки, блоки, панели, пласты, горизонты) или на шахту в целом",
        ],
      },
      {
        code: "20404104",
        text: 'Может ли "газовый режим" распространяться на отдельные участки, блоки, пласты или на шахту в целом?',
        answers: [
          "Только на шахту в целом",
          "Может распространяться на отдельные рабочие зоны (участки, блоки и т. д.)",
          "Только на горные выработки всего месторождения",
        ],
        correctAnswers: [
          "Может распространяться на отдельные рабочие зоны (участки, блоки и т. д.)",
        ],
      },
      {
        code: "20404105",
        text: "На каком расстоянии от ствола на поверхности может быть установлена вентиляторная установка для проветривания при проходке ствола?",
        answers: ["Не менее 10 м", "Не менее 15 м", "Не менее 5 м"],
        correctAnswers: ["Не менее 15 м"],
      },
      {
        code: "20404106",
        text: "Сколько самостоятельных вентиляторных агрегатов должно входить в главную вентиляторную установку?",
        answers: [
          "Два самостоятельных вентиляторных агрегата, причем один из них должен быть резервный",
          "Два самостоятельных вентиляторных агрегата, и дополнительно должен быть установлен резервный",
          "Один самостоятельный вентиляторный агрегат, обеспечивающий проектную мощность",
        ],
        correctAnswers: [
          "Два самостоятельных вентиляторных агрегата, причем один из них должен быть резервный",
        ],
      },
      {
        code: "20404107",
        text: "В какие сроки проводится воздушно-депрессионная съемка?",
        answers: [
          "На всех шахтах - не реже 1 раза в 3 года, а на трудно проветриваемых шахтах - не реже 1 раза в год",
          "На всех шахтах - 1 раз в 5 лет",
          "На всех шахтах - при вводе новых горизонтов",
        ],
        correctAnswers: [
          "На всех шахтах - не реже 1 раза в 3 года, а на трудно проветриваемых шахтах - не реже 1 раза в год",
        ],
      },
      {
        code: "20404108",
        text: "Какая длина должна быть у замерных станций, оборудованных на местах замера количества воздуха?",
        answers: ["Не менее 2 м", "Не менее 4 м", "Не менее 1 м"],
        correctAnswers: ["Не менее 4 м"],
      },
      {
        code: "20404109",
        text: "Что из перечисленного не входит в мероприятия по разгазированию выработок?",
        answers: [
          "Снятие напряжения с электрооборудования и электрических сетей и устранение других источников воспламенения в выработках, по которым будет двигаться исходящая струя",
          "Выставление постов (на свежей струе) и запрещающих знаков в местах возможного подхода людей к выработкам, по которым двигается исходящая струя при разгазировании",
          "Выбор способа и порядка разгазирования",
          "Контроль за разгазированием выработки и за свежей струей воздуха, концентрация газов в месте слияния исходящей и свежей струй воздуха не должна превышать: горючих газов - 1 %, ядовитых газов - ПДК",
          "Обследование выработок после разгазирования перед допуском в них людей",
          "Возобновление работ в отперемыченных выработках, подключение электрооборудования и электрических сетей",
        ],
        correctAnswers: [
          "Возобновление работ в отперемыченных выработках, подключение электрооборудования и электрических сетей",
        ],
      },
      {
        code: "20404110",
        text: "В течение какого времени после окончания сварочных и газопламенных работ место сварки и резки должно находиться под наблюдением лица технического надзора или специально выделенного и проинструктированного лица?",
        answers: [
          "Не менее двух часов",
          "Не менее одного часа",
          "Не менее полутора часов",
          "Не менее получаса",
        ],
        correctAnswers: ["Не менее двух часов"],
      },
      {
        code: "20404111",
        text: "Как часто механиком (энергетиком) и начальником пылевентиляционной службы шахты должна проверяться исправность действия реверсивных устройств вентиляторной установки без опрокидывания струи?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 2 месяца",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20404112",
        text: "Какова периодичность проверки действия реверсивных устройств с пропуском опрокинутой воздушной струи в нерабочее время?",
        answers: [
          "1 раз в месяц",
          "1 раз в 3 месяца",
          "1 раз в 6 месяцев",
          "1 раз в год",
        ],
        correctAnswers: ["1 раз в 6 месяцев"],
      },
      {
        code: "20404113",
        text: "Каким образом необходимо составлять вентиляционные планы?",
        answers: [
          "1 раз в год в 2 экземплярах и пополнять ежемесячно",
          "1 раз в месяц в 3 экземплярах",
          "1 раз в полгода в 3 экземплярах и дополнять ежемесячно",
        ],
        correctAnswers: [
          "1 раз в полгода в 3 экземплярах и дополнять ежемесячно",
        ],
      },
      {
        code: "20404114",
        text: "У кого не должен храниться экземпляр вентиляционного плана?",
        answers: [
          "В профессиональном аварийно-спасательном формировании (аварийно-спасательной службе)",
          "У начальника пылевентиляционной службы шахты",
          "У технического руководителя шахты в комплекте плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах",
          "У механика (энергетика) шахты",
        ],
        correctAnswers: ["У механика (энергетика) шахты"],
      },
      {
        code: "20404115",
        text: "На содержание каких веществ должны быть исследованы пробы воздуха, набираемые в непроветриваемой части затопленных вертикальных и наклонных выработок (до начала откачки воды)?",
        answers: [
          "Только CO, СО2",
          "Только СН4, H2S",
          "Только О2, СО2",
          "CO, СО2, СН4, H2S, О2 и Н2",
        ],
        correctAnswers: ["CO, СО2, СН4, H2S, О2 и Н2"],
      },
      {
        code: "20404116",
        text: "При каком расстоянии до места работы для перевозки людей должны применяться специально оборудованные транспортные средства?",
        answers: [
          "При расстоянии до места работ более 300 м",
          "При расстоянии до места работ более 1 км",
          "При расстоянии до места работ более 500 м",
        ],
        correctAnswers: ["При расстоянии до места работ более 1 км"],
      },
      {
        code: "20404117",
        text: "Какая разрешенная скорость при перевозке людей в специальных пассажирских вагонетках по горизонтальным выработкам?",
        answers: [
          "Не превышающая 15 км/ч",
          "Не превышающая 20 км/ч",
          "Не превышающая 10 км/ч",
        ],
        correctAnswers: ["Не превышающая 20 км/ч"],
      },
      {
        code: "20404118",
        text: "Кто утверждает схемы откаточных путей, маршруты движения самоходного (нерельсового) оборудования по каждому горизонту?",
        answers: [
          "Руководитель шахты",
          "Технический руководитель шахты",
          "Главный технолог",
          "Начальник участка",
        ],
        correctAnswers: ["Технический руководитель шахты"],
      },
      {
        code: "20404119",
        text: "Кем утверждается схема расстановки знаков и указателей для обозначения свободного прохода для людей и проезжей части в откаточных выработках?",
        answers: [
          "Техническим руководителем шахты",
          "Начальником участка",
          "Руководителем организации",
        ],
        correctAnswers: ["Техническим руководителем шахты"],
      },
      {
        code: "20404120",
        text: 'С каким интервалом в выработках, в которых подвешен контактный провод, должны устанавливаться светящиеся надписи или информационные знаки "Берегись контактного провода"?',
        answers: [
          "На пересечениях с другими выработками и закруглениями и через каждые 200 м",
          "Только на пересечениях с другими выработками",
          "Через каждые 100 м",
        ],
        correctAnswers: [
          "На пересечениях с другими выработками и закруглениями и через каждые 200 м",
        ],
      },
      {
        code: "20404121",
        text: "Какими средствами пожаротушения должны быть оборудованы магистральные и участковые конвейерные линии, оснащенные трудновоспламеняющейся лентой?",
        answers: [
          "Системами автоматического пожаротушения и автоматической пожарной сигнализацией по всей длине конвейера с выводом к диспетчеру шахты",
          "Системами автоматического пожаротушения на приводных станциях",
          "Автоматической сигнализацией по всей длине конвейера с выводом к диспетчеру шахты",
        ],
        correctAnswers: [
          "Системами автоматического пожаротушения и автоматической пожарной сигнализацией по всей длине конвейера с выводом к диспетчеру шахты",
        ],
      },
      {
        code: "20404122",
        text: "Разрешаются ли спуск и подъем людей одновременно с грузом?",
        answers: [
          "Разрешаются при двухклетевом подъеме",
          "Запрещаются при одноклетевом подъеме",
          "Запрещаются как в одной клети при одноклетевом подъеме, так и в разных клетях при двухклетевом подъеме",
        ],
        correctAnswers: [
          "Запрещаются как в одной клети при одноклетевом подъеме, так и в разных клетях при двухклетевом подъеме",
        ],
      },
      {
        code: "20404123",
        text: "С какой периодичностью должна проводиться проверка состояния металлических и железобетонных копров?",
        answers: [
          "1 раз в год",
          "1 раз в 3 года",
          "2 раза в 5 лет",
          "1 раз в 2 года",
        ],
        correctAnswers: ["1 раз в год"],
      },
      {
        code: "20404124",
        text: "Какова периодичность испытания подъемных канатов людских и грузолюдских подъемных установок?",
        answers: [
          "Через каждые 6 месяцев, считая со дня их навески",
          "Через каждые 6 месяцев, считая со дня последнего испытания",
          "Через 12 месяцев, считая со дня навески, а затем через каждые шесть месяцев",
          "Не реже 1 раза в год",
        ],
        correctAnswers: ["Через каждые 6 месяцев, считая со дня их навески"],
      },
      {
        code: "20404125",
        text: "В каком случае шахтный канат должен быть снят и заменен другим при проведении повторного испытания?",
        answers: [
          "Если суммарная площадь проволок, не выдержавших испытания на разрыв и перегиб, достигает 25 % общей площади поперечного сечения всех проволок каната",
          "Если суммарная площадь поперечного сечения проволок, не выдержавших испытания на разрыв и перегиб, составляет 3 %",
          "Если запас их прочности ниже 3,5-кратного",
        ],
        correctAnswers: [
          "Если суммарная площадь проволок, не выдержавших испытания на разрыв и перегиб, достигает 25 % общей площади поперечного сечения всех проволок каната",
        ],
      },
      {
        code: "20404126",
        text: "С какой периодичностью механик участка должен проводить осмотр канатов маневровых лебедок вспомогательного транспорта?",
        answers: ["Ежесуточно", "Еженедельно", "Раз в полгода", "Ежемесячно"],
        correctAnswers: ["Еженедельно"],
      },
      {
        code: "20404127",
        text: "Сколько подвесок должны иметь клети грузолюдских и людских подъемных установок?",
        answers: [
          "Одну подвеску",
          "По расчету, утвержденному механиком подъема, одну или две",
          "Двойную независимую подвеску - рабочую и предохранительную",
        ],
        correctAnswers: [
          "Двойную независимую подвеску - рабочую и предохранительную",
        ],
      },
      {
        code: "20404128",
        text: 'Какой должна быть навивка каната на барабан согласно "Правилам безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          "Соответствующей назначению подъемной установки в соответствии с паспортом и руководством по эксплуатации завода-изготовителя",
          "Двухслойной",
          "Многослойной",
          "Трехслойной",
        ],
        correctAnswers: [
          "Соответствующей назначению подъемной установки в соответствии с паспортом и руководством по эксплуатации завода-изготовителя",
        ],
      },
      {
        code: "20404129",
        text: "В какие сроки должны проводиться ревизия, наладка и испытание эксплуатируемых подъемных установок (кроме грузовых лебедок, предназначенных для спуска-подъема оборудования и материалов)?",
        answers: ["1 раз в год", "1 раз в 2 года", "1 раз в 5 лет"],
        correctAnswers: ["1 раз в год"],
      },
      {
        code: "20404130",
        text: "Какой должна быть скорость движения вагона при работе самоходного вагона в комплексе с передвижными или стационарными бункер-перегружателями в момент подъезда к местам перегрузки?",
        answers: [
          "Не должна превышать 5 км/ч",
          "Не должна превышать 8 км/ч",
          "Не должна превышать 10 км/ч",
        ],
        correctAnswers: ["Не должна превышать 5 км/ч"],
      },
      {
        code: "20404131",
        text: "От каких действий должна предохранять блокировка, предусматриваемая в схеме управления автоматизированной подъемной установкой?",
        answers: [
          "Только от включения машины в сторону дальнейшего переподъема, напуска или натяжения каната после аварийной остановки",
          "Только от включения машины при отсутствии смазки",
          "Только от перехода с ручного управления на автоматическое и обратно без затормаживания машины предохранительным тормозом",
          "От всех перечисленных",
        ],
        correctAnswers: ["От всех перечисленных"],
      },
      {
        code: "20404132",
        text: "Какой должна быть длина переподъема для одноканатных подъемных установок со скоростью подъема свыше 3 м/с вертикальных и наклонных выработок (с углом наклона свыше 30°)?",
        answers: [
          "Не менее 6 м",
          "Не менее 4 м",
          "Не менее 3 м",
          "Не менее 2 м",
        ],
        correctAnswers: ["Не менее 6 м"],
      },
      {
        code: "20404133",
        text: "Каково максимально допустимое превышение расчетного значения ускорения подъемных вертикальных и наклонных (более 30°) установок, предназначенных для подъема и спуска людей?",
        answers: ["1 м/с²", "0,7 м/с²", "0,5 м/с²", "0,75 м/с²"],
        correctAnswers: ["1 м/с²"],
      },
      {
        code: "20404134",
        text: "Кем из должностных лиц проверяются канаты для перемещения забойного оборудования ежесменно перед началом работы?",
        answers: [
          "Машинистом подъемной машины или его помощником",
          "Работником обособленного структурного подразделения",
          "Главным механиком",
          "Работником подразделения профессионального аварийно-спасательного формирования",
          "Все ответы неверны",
        ],
        correctAnswers: ["Машинистом подъемной машины или его помощником"],
      },
      {
        code: "20404135",
        text: "Каков минимально допустимый зазор между стенками раструба проходческого полка и выступающими частями движущейся направляющей рамки бадьи?",
        answers: ["100 мм", "200 мм", "300 мм", "400 мм"],
        correctAnswers: ["100 мм"],
      },
      {
        code: "20404136",
        text: "В каком случае проводниковые канаты допускаются к эксплуатации?",
        answers: [
          "При наличии спайки обрыва наружной проволоки в канате закрытой конструкции, вышедшей из замка",
          "При износе наружных проволок более 50 % их высоты",
          "Если на 100 м длины каната закрытой конструкции обнаружено два обрыва наружных проволок",
          "Допускаются во всех перечисленных случаях",
        ],
        correctAnswers: [
          "При наличии спайки обрыва наружной проволоки в канате закрытой конструкции, вышедшей из замка",
        ],
      },
      {
        code: "20404137",
        text: "Чем укомплектовывается в резерве главная подъемная установка?",
        answers: [
          "Только испытанным канатом, прошедшим инструментальный контроль, а также подъемным сосудом с прицепным устройством",
          "Только электродвигателем или полным комплектом запасных частей к нему, а также компрессором с электродвигателем при отсутствии подвода от общешахтной пневмосистемы",
          "Только комплектом тормозных колодок, а также необходимым количеством запасных вкладышей или подшипников качения",
          "Только необходимым количеством быстроходных валов и быстроизнашивающихся элементов аппаратов контроля, управления и защиты, определяемым эксплуатационной документацией изготовителя",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20404138",
        text: "Что не входит в комплектацию вспомогательных подъемных установок?",
        answers: [
          "Подъемные сосуды вместимостью не более 2 человек",
          "Канаты длиной, соответствующей глубине ствола и запасом прочности не менее 9-кратного по отношению к максимальной расчетной статической нагрузке",
          "Подъемные сосуды вместимостью не менее 6 человек",
          "Сигнализация, позволяющая подавать сигналы из подъемного сосуда и с горизонтов, с которых предполагается выезд людей",
        ],
        correctAnswers: ["Подъемные сосуды вместимостью не более 2 человек"],
      },
      {
        code: "20404139",
        text: "Что из перечисленного допускается при перевозке людей и грузов по горизонтальным горным выработкам?",
        answers: [
          "Прицепка в конце пассажирского состава двух грузовых вагонеток для перевозки инструмента",
          "Прицепка грузовых вагонеток к пассажирским составам",
          "Перевозка людей на локомотивах",
          "Перевозка людей в необорудованных вагонетках",
        ],
        correctAnswers: [
          "Прицепка в конце пассажирского состава двух грузовых вагонеток для перевозки инструмента",
        ],
      },
      {
        code: "20404140",
        text: "От каких повреждений и неисправностей в схеме управления автоматизированной подъемной установкой предусматриваются основные виды защит, вызывающих предохранительное торможение машин?",
        answers: [
          "Только от чрезмерного износа тормозных колодок - срабатывающую при увеличении зазора между ободом барабана и тормозной колодкой более 2 мм",
          "Только от обратного хода машины - срабатывающую прежде, чем скорость движения сосуда при обратном ходе достигнет 1 м/с",
          "Только от увеличения вдвое против расчетного значения периода пуска, замедления или дотягивания",
          "Только от недопустимого нагрева подшипников подъемной машины и электродвигателя, в том числе от статора",
          "Только от повреждения электрической цепи или механического привода электрического ограничителя скорости",
          "От всех перечисленных",
        ],
        correctAnswers: ["От всех перечисленных"],
      },
      {
        code: "20404141",
        text: "С какой периодичностью проводится ревизия и наладка электрической части и аппаратуры автоматизированной подъемной установки после ввода ее в эксплуатацию?",
        answers: [
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в год",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 2 года",
        ],
        correctAnswers: ["Не реже 1 раза в 6 месяцев"],
      },
      {
        code: "20404142",
        text: "Где и в течение какого срока хранятся свидетельства об испытаниях каната?",
        answers: [
          "В обособленном структурном подразделении в течение всего срока службы каната",
          "На видимой стороне подъемной установки в течение всего ее срока службы",
          "На рабочем месте обслуживающего персонала подъемной установки в течение всего ее срока службы",
          "В Ростехнадзоре в течение 20 лет",
        ],
        correctAnswers: [
          "В обособленном структурном подразделении в течение всего срока службы каната",
        ],
      },
      {
        code: "20404143",
        text: "При какой скорости движения осматриваются поврежденные участки канатов, а также стыковые соединения резинотросовых канатов?",
        answers: [
          "При неподвижном канате",
          "При скорости движения канатов 0,3 м/с",
          "При скорости движения канатов 1 м/с",
          "На скорости движения канатов, обеспечивающей 100%-ный визуальный контроль за целостностью каната",
        ],
        correctAnswers: ["При неподвижном канате"],
      },
      {
        code: "20404144",
        text: "Какие виды канатов перед их навеской могут не испытываться на канатно-испытательных станциях?",
        answers: [
          "Канаты на грузовых наклонных подъемах с углом наклона менее 30°",
          "Подъемные канаты вертикальных и наклонных шахтных подъемов",
          "Тяговые канаты для монорельсовых дорог",
          "Тяговые и несущие канаты для подземных канатных дорог",
        ],
        correctAnswers: [
          "Канаты на грузовых наклонных подъемах с углом наклона менее 30°",
        ],
      },
      {
        code: "20404145",
        text: "Какое минимальное значение величины среднего замедления подъемной установки при спуске при предохранительном торможении в случае угла наклона горной выработки до 30°?",
        answers: ["0,75 м/с²", "1,5 м/с²", "1,75 м/с²", "0,70 м/с²"],
        correctAnswers: ["0,75 м/с²"],
      },
      {
        code: "20404146",
        text: "Какой запас прочности должны иметь тяговые канаты для перемещения забойного оборудования по отношению к номинальному усилию на их рабочих барабанах?",
        answers: [
          "Не менее 3-кратного",
          "Не менее 2-кратного",
          "Не менее 4-кратного",
          "Не менее 6-кратного",
        ],
        correctAnswers: ["Не менее 3-кратного"],
      },
      {
        code: "20404147",
        text: "Какой максимальный уклон должны иметь горизонтальные горные выработки, по которым проводится перевозка людей и грузов локомотивами при благоприятных горно-геологических условиях?",
        answers: ["0,1", "0,05", "0,5", "0,15", "Все ответы неверны"],
        correctAnswers: ["0,05"],
      },
      {
        code: "20404148",
        text: "На кого в организации возлагается ответственность за безопасную эксплуатацию электроустановок?",
        answers: [
          "Только на технического руководителя шахты",
          "Только на механика",
          "На лиц, ответственных по организации и структурным подразделениям",
          "На специалистов службы промышленной безопасности, имеющих соответствующую группу по электробезопасности",
        ],
        correctAnswers: [
          "На лиц, ответственных по организации и структурным подразделениям",
        ],
      },
      {
        code: "20404149",
        text: "На каких электроустановках запрещается оперативное обслуживание без применения специальных защитных средств?",
        answers: [
          "На электроустановках напряжением выше 220 В",
          "На электроустановках напряжением выше 380 В",
          "На электроустановках напряжением менее 1000 В",
          "На электроустановках напряжением выше 1000 В",
        ],
        correctAnswers: ["На электроустановках напряжением выше 1000 В"],
      },
      {
        code: "20404150",
        text: "Какое напряжение должно применяться для питания ручных электрических машин и инструментов в помещениях без повышенной опасности?",
        answers: [
          "Не выше 42 В",
          "Не выше 127 В",
          "Не выше 36 В",
          "Не выше 220 В",
        ],
        correctAnswers: ["Не выше 220 В"],
      },
      {
        code: "20404151",
        text: "Какая защита должна осуществляться при напряжении до 1140 В?",
        answers: [
          "Только защита трансформаторов и каждого отходящего от них присоединения от токов короткого замыкания - автоматическими выключателями с максимальной токовой защитой",
          "Только защита электродвигателей и питающих их кабелей от токов короткого замыкания - мгновенная или селективная в пределах до 0,2 с, а от токов перегрузки или от перегрева - нулевая",
          "Только защита от включения напряжения при сниженном сопротивлении изоляции относительно земли",
          "Только защита электрической сети от опасных токов утечки на землю - автоматическими выключателями в комплексе с одним аппаратом защиты на всю электрически связанную сеть (подключенную к одному или группе параллельно работающих трансформаторов)",
          "Все перечисленные виды защиты",
        ],
        correctAnswers: ["Все перечисленные виды защиты"],
      },
      {
        code: "20404152",
        text: "Сколько выходов должно быть в камерах подстанций длиной более 10 м?",
        answers: [
          "1 выход",
          "2 выхода, расположенные в наиболее удаленных друг от друга частях камеры",
          "2 выхода, независимо от расположения",
        ],
        correctAnswers: [
          "2 выхода, расположенные в наиболее удаленных друг от друга частях камеры",
        ],
      },
      {
        code: "20404153",
        text: "Какие блокировки должна иметь защитная аппаратура?",
        answers: [
          "От перебоев напряжения",
          "Против подачи напряжения на линии и электроустановки с пониженным сопротивлением изоляции относительно земли и после срабатывания защиты максимального тока",
          "Против срабатывания защиты отключения магнитных пускателей",
        ],
        correctAnswers: [
          "Против подачи напряжения на линии и электроустановки с пониженным сопротивлением изоляции относительно земли и после срабатывания защиты максимального тока",
        ],
      },
      {
        code: "20404154",
        text: "С какой периодичностью трансформаторное масло, применяемое в трансформаторах, установленных в подземных выработках, должно проверяться на электрическую прочность?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 9 месяцев",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в полтора года",
        ],
        correctAnswers: ["Не реже 1 раза в 6 месяцев"],
      },
      {
        code: "20404155",
        text: "Какое количество главных заземлителей должно быть на шахте?",
        answers: [
          "Не менее двух главных заземлителей (основного и резервного)",
          "Только один главный заземлитель",
          "Количество главных заземлителей определяется техническим руководителем шахты",
        ],
        correctAnswers: [
          "Не менее двух главных заземлителей (основного и резервного)",
        ],
      },
      {
        code: "20404156",
        text: "Где должны быть установлены местные заземлители?",
        answers: [
          "Только в распределительной или трансформаторной подстанции",
          "Только у стационарного или передвижного распределительного пункта",
          "Только у индивидуально установленного выключателя или распределительного устройства",
          "Только у отдельно установленных машин, за исключением случаев заземления на шину главного заземлителя",
          "Только у кабельной муфты, заводская конструкция которой предусматривает ее заземление",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20404157",
        text: "Как должно выполняться заземление тягового трансформатора, используемого для питания контактных сетей электрифицированных технологических железных дорог?",
        answers: [
          "За пределами контура защитного заземления подстанции",
          "В пределах контура защитного заземления подстанции",
          'Выполнение заземления тягового трансформатора "Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых" не регламентируется',
        ],
        correctAnswers: [
          "За пределами контура защитного заземления подстанции",
        ],
      },
      {
        code: "20404158",
        text: "На какой высоте должны быть расположены токоведущие части электроаппаратуры центральных насосных камер и камер центральных подземных подстанций от головки рельсов околоствольного двора?",
        answers: ["Не более 0,5 м", "Не более 0,2 м", "Не менее 1 м"],
        correctAnswers: ["Не менее 1 м"],
      },
      {
        code: "20404159",
        text: 'Какое требование установлено к коробкам выводов электрических машин и пускорегулирующей аппаратуры "Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          "Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть защищены ограждениями",
          "Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть уплотнены и закрыты крышкой",
          "Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть оборудованы звуковой и световой сигнализацией",
        ],
        correctAnswers: [
          "Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть уплотнены и закрыты крышкой",
        ],
      },
      {
        code: "20404160",
        text: "Что запрещается использовать при производстве огневых работ в подземных выработках и стволах (шурфах) шахт?",
        answers: [
          "Только газовую сварку с использованием пропан бутана",
          "Газовую сварку с использованием ацетилена, пропан бутана и других горючих углеводородов",
          "Только газовую сварку с использованием ацетилена",
        ],
        correctAnswers: [
          "Газовую сварку с использованием ацетилена, пропан бутана и других горючих углеводородов",
        ],
      },
      {
        code: "20404161",
        text: "Что из перечисленного должно находиться у места производства сварочных работ?",
        answers: [
          "Не менее 2 огнетушителей, пожарный ствол с рукавом или со шлангом, присоединенным к противопожарной магистрали, или емкость с запасом воды не менее 1 м3 и ящик с песком",
          "Один огнетушитель и лопата",
          "Ящик с песком и противопожарное полотно",
        ],
        correctAnswers: [
          "Не менее 2 огнетушителей, пожарный ствол с рукавом или со шлангом, присоединенным к противопожарной магистрали, или емкость с запасом воды не менее 1 м3 и ящик с песком",
        ],
      },
      {
        code: "20404162",
        text: "В каком случае разрешается производство огневых работ?",
        answers: [
          "Если в выработках, в которые могут попасть продукты горения, образующиеся при огневых работах, находятся люди",
          "Если огневые работы проводятся в подземных выработках негазовых шахт и в надшахтных зданиях",
          "Если огневые работы проводятся в вертикальных и наклонных выработках с деревянной крепью, имеющих выход на поверхность",
        ],
        correctAnswers: [
          "Если огневые работы проводятся в подземных выработках негазовых шахт и в надшахтных зданиях",
        ],
      },
      {
        code: "20404163",
        text: "На кого возлагается ответственность за состояние пожарной безопасности шахт?",
        answers: [
          "На руководителя службы промышленной безопасности",
          "На специалиста по пожарной безопасности",
          "На руководителя шахты",
        ],
        correctAnswers: ["На руководителя шахты"],
      },
      {
        code: "20404164",
        text: "На каком расстоянии от устья штольни шахты должны располагаться склады для хранения противопожарных материалов?",
        answers: [
          "Не ближе 150 м",
          "Не далее 100 м",
          "Не далее 300 м",
          "Правилами не регламентируется",
        ],
        correctAnswers: ["Не далее 100 м"],
      },
      {
        code: "20404165",
        text: "В течение какого срока должны пополняться материалы, израсходованные со складов на ликвидацию аварий?",
        answers: [
          "В течение суток",
          "В течение недели",
          "В течение десяти дней",
          "В течение месяца",
        ],
        correctAnswers: ["В течение суток"],
      },
      {
        code: "20404166",
        text: "Кто утверждает специальный план, в соответствии с которым производится вскрытие пожарного участка?",
        answers: [
          "Руководитель территориального органа Ростехнадзора",
          "Руководитель организации",
          "Технический руководитель организации",
        ],
        correctAnswers: ["Технический руководитель организации"],
      },
      {
        code: "20404167",
        text: "В течение какого времени при вводе новых или закрытии отработанных участков, изменении схемы вентиляции или путей выхода людей из шахты в план мероприятий по локализации и ликвидации последствий аварий должен внести изменения и согласовать их с командиром аварийно-спасательного формирования технический руководитель организации?",
        answers: [
          "В течение суток",
          "В течение трех дней",
          "В течение пяти дней",
          "В течение недели",
        ],
        correctAnswers: ["В течение суток"],
      },
      {
        code: "20404168",
        text: "Какие из перечисленных документов должны прилагаться к плану мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Только распределение обязанностей между отдельными лицами, участвующими в ликвидации аварий, и порядок их действий",
          "Обязанности дежурного по шахте и список должностных лиц и учреждений, которые должны быть немедленно извещены об аварии, и распределение обязанностей между ними",
          "Только список лиц, участвующих в ликвидации аварий, и аксонометрическая схема проветривания горных выработок",
          "Распределение обязанностей между участвующими в ликвидации аварий, порядок их действий и список должностных лиц и учреждений, которые должны быть немедленно извещены об аварии",
        ],
        correctAnswers: [
          "Распределение обязанностей между участвующими в ликвидации аварий, порядок их действий и список должностных лиц и учреждений, которые должны быть немедленно извещены об аварии",
        ],
      },
      {
        code: "20404169",
        text: "Какие приложения должны приобщаться к плану мероприятий по локализации и ликвидации последствий аварий при его согласовании и утверждении?",
        answers: [
          "Только акт о расчетном и фактическом времени выхода людей в изолирующих самоспасателях на свежую струю из наиболее отдаленных горных выработок шахты",
          "Только акт о состоянии запасных выходов из очистных забоев, участков и шахты и о пригодности их для выхода людей и прохода горноспасателей в респираторах",
          "Только акт проверки состояния насосных станций, водонепроницаемых перемычек и других средств по предотвращению затопления горных выработок",
          "Только акт проверки исправности действия реверсивных устройств на главных вентиляционных установках с пропуском реверсированной воздушной струи по горным выработкам",
          "Все перечисленные, включая  акт проверки наличия и исправности противопожарных средств и оборудования",
        ],
        correctAnswers: [
          "Все перечисленные, включая  акт проверки наличия и исправности противопожарных средств и оборудования",
        ],
      },
      {
        code: "20404170",
        text: "Где должны находиться выписки из плана мероприятий по локализации и ликвидации последствий аварий, относящиеся к конкретному участку, указывающие пути выхода людей из шахты?",
        answers: [
          "У заместителя технического руководителя шахты",
          "У горного диспетчера",
          "У руководителя шахты",
          "У начальника соответствующего участка",
        ],
        correctAnswers: ["У начальника соответствующего участка"],
      },
      {
        code: "20404171",
        text: "Какие из перечисленных данных должны указываться в оперативной части плана мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Только категории шахты (месторождения) по удароопасности",
          "Только категории шахты (месторождения) по выбросоопасности",
          "Только категория региона по природной и техногенной сейсмоопасности (по шкале Рихтера)",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20404172",
        text: "В каком случае нарушены требования безопасности при выводе людей из аварийных участков выработки?",
        answers: [
          "При определении путей движения людей, выходящих из аварийных участков по загазованным выработкам, следует учитывать только состояние и протяженность этих выработок и время выхода по ним людей",
          "Из горных выработок, расположенных до очага пожара, людей следует выводить навстречу свежей струе к выходу на поверхность",
          "Из горных выработок, расположенных за очагом пожара, людей следует выводить в изолирующих самоспасателях кратчайшим путем в выработки со свежей струей воздуха и далее на поверхность",
          'Места "до очага пожара" и "за очагом пожара" определяются по ходу движения вентиляционной струи при режиме вентиляции, предусмотренном для данной позиции планом ликвидации аварий',
        ],
        correctAnswers: [
          "При определении путей движения людей, выходящих из аварийных участков по загазованным выработкам, следует учитывать только состояние и протяженность этих выработок и время выхода по ним людей",
        ],
      },
      {
        code: "20404173",
        text: "Какой вентиляционный режим должен выбираться при авариях?",
        answers: [
          "Нормальный",
          "Реверсивный",
          "Исключающий проникновение продуктов горения в шахту",
          "С учетом жизнеобеспечения максимального количества людей, находящихся в горных выработках",
        ],
        correctAnswers: [
          "С учетом жизнеобеспечения максимального количества людей, находящихся в горных выработках",
        ],
      },
      {
        code: "20404174",
        text: "С какой периодичностью должна проверяться исправность сигнализации оповещения об аварии с регистрацией результатов проверок?",
        answers: [
          "Не реже 1 раза в неделю",
          "Не реже 1 раза в 2 недели",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в квартал",
        ],
        correctAnswers: ["Не реже 1 раза в неделю"],
      },
      {
        code: "20404175",
        text: "Где должны быть размещены пожарные краны пожарно-оросительного трубопровода в наклонных выработках, не имеющих пересечений и ответвлений?",
        answers: [
          "Через каждые 200 м",
          "Через каждые 50 м",
          "Через каждые 100 м",
        ],
        correctAnswers: ["Через каждые 100 м"],
      },
      {
        code: "20404176",
        text: "На какое расстояние от забоев подготовительных выработок должны отстоять концы участковых пожарно-оросительных трубопроводов?",
        answers: [
          "Не более чем на 40 м",
          "Не более чем на 70 м",
          "Не более чем на 90 м",
        ],
        correctAnswers: ["Не более чем на 40 м"],
      },
      {
        code: "20404177",
        text: "В какой цвет окрашивается весь шахтный пожарно-оросительный трубопровод?",
        answers: [
          "В опознавательный желтый цвет",
          "В опознавательный оранжевый цвет",
          "В опознавательный красный цвет",
        ],
        correctAnswers: ["В опознавательный красный цвет"],
      },
      {
        code: "20404178",
        text: "Где должны располагаться установки автоматического пожаротушения для камер с постоянным дежурством обслуживающего персонала?",
        answers: [
          "Снаружи камеры со стороны поступления свежей струи воздуха не дальше 10 м от входа в камеру",
          "У рабочего места дежурного персонала",
          "Внутри камеры на расстоянии 5 м от рабочего места обслуживающего персонала",
        ],
        correctAnswers: ["У рабочего места дежурного персонала"],
      },
      {
        code: "20404179",
        text: "Кому запрещается находиться на командном пункте, организованном на период ведения работ по локализации и ликвидации последствий аварии на опасном производственном объекте?",
        answers: [
          "Лицам, назначенным для ведения оперативных журналов и другой документации, связанной с ведением работ по локализации и ликвидации последствий аварии и горноспасательных работ",
          "Службам профессионального аварийно-спасательного формирования (группам инженерного обеспечения, аварийной контрольно-испытательной лаборатории, медицинской службе)",
          "Руководителю горноспасательных работ",
          "Руководителю работ по ликвидации аварии",
        ],
        correctAnswers: [
          "Службам профессионального аварийно-спасательного формирования (группам инженерного обеспечения, аварийной контрольно-испытательной лаборатории, медицинской службе)",
        ],
      },
      {
        code: "20404180",
        text: "В каких из перечисленных случаев руководитель работ по ликвидации аварии и руководитель горноспасательных работ должны разработать оперативный план по локализации и ликвидации последствий аварий? Выберите два правильных варианта ответа.",
        answers: [
          "В случае если после выполнения всех мероприятий, предусмотренных планом ликвидации аварии, требуется дальнейшее ведение горноспасательных работ",
          "В первоначальный момент возникновения аварии в горной выработке или сети горных выработок (здании, сооружении, участке территории опасного производственного объекта)",
          "В случае если при выполнении мероприятий, предусмотренных планом ликвидации аварии, произошло изменение обстановки в горной выработке или сети горных выработок (здании, сооружении, участке территории опасного производственного объекта), в которых произошла авария",
          "По окончании ведения горноспасательных работ, если после выполнения всех мероприятий, предусмотренных планом ликвидации аварии, требуется их корректировка",
        ],
        correctAnswers: [
          "В случае если после выполнения всех мероприятий, предусмотренных планом ликвидации аварии, требуется дальнейшее ведение горноспасательных работ",
          "В случае если при выполнении мероприятий, предусмотренных планом ликвидации аварии, произошло изменение обстановки в горной выработке или сети горных выработок (здании, сооружении, участке территории опасного производственного объекта), в которых произошла авария",
        ],
      },
      {
        code: "20404181",
        text: "В каком из перечисленных случаев разрабатываются последующие оперативные планы по локализации и ликвидации последствий аварий (N 2, 3 и т. д.)?",
        answers: [
          "По окончании ведения горноспасательных работ, если после выполнения всех мероприятий, предусмотренных предыдущим оперативным планом ликвидации аварии, требуется внести корректировку",
          "Когда мероприятия предыдущего оперативного плана не реализованы в полной мере и есть предпосылка возникновения аварии в горной выработке или сети горных выработок (здании, сооружении, участке территории опасного производственного объекта)",
          "Когда мероприятия предыдущего оперативного плана реализованы или требуется их корректировка",
        ],
        correctAnswers: [
          "Когда мероприятия предыдущего оперативного плана реализованы или требуется их корректировка",
        ],
      },
      {
        code: "20404182",
        text: "Какое количество отделений (горноспасательных или отделений вспомогательной горноспасательной команды) направляется по каждому маршруту до границ зон поражения при взрывах, определенных планом локализации и ликвидации последствий аварии при наличии признаков пожара и взрывоопасных концентраций рудничных газов?",
        answers: [
          "Не более 1 отделения",
          "Не менее 2 отделений",
          "Не менее 3 отделений",
          "Не более 2 отделений",
        ],
        correctAnswers: ["Не более 1 отделения"],
      },
      {
        code: "20404183",
        text: "В каком из перечисленных случаев подземная горноспасательная база не организуется?",
        answers: [
          "В случае если горноспасательные работы выполняются для ликвидации последствий прорыва воды (рассола) или обводненной горной массы при выполнении водолазных спусков и водолазных работ",
          "В случае если горноспасательные работы проводятся в горных выработках с непригодной для дыхания атмосферой",
          "В случае если все прибывшие на опасный производственный объект горноспасательные отделения привлечены к горноспасательным работам, определенным планом локализации и ликвидации аварии, связанным со спасением людей, застигнутых аварией",
        ],
        correctAnswers: [
          "В случае если все прибывшие на опасный производственный объект горноспасательные отделения привлечены к горноспасательным работам, определенным планом локализации и ликвидации аварии, связанным со спасением людей, застигнутых аварией",
        ],
      },
      {
        code: "20404184",
        text: "В каком из перечисленных случаев в подземных горных выработках приостанавливаются горноспасательные работы и организуется вывод из зоны аварии людей, задействованных в данных работах?",
        answers: [
          "При пожаре в местах ведения взрывных работ, в складах взрывчатых материалов и других местах их хранения, на транспортных средствах, перевозящих взрывчатые материалы после его ликвидации первичными средствами пожаротушения",
          "В случае если рудничная атмосфера на аварийном участке, в котором действует пожар, находится во взрывоопасном состоянии",
          "В случае если концентрация водорода в зарядных камерах составляет 0,05 %",
          "В случае если в зоне с температурой воздуха не более 25 °C при наличии непригодной для дыхания атмосферы температура воздуха в течение получаса повысилась на 1 °C и более",
        ],
        correctAnswers: [
          "В случае если рудничная атмосфера на аварийном участке, в котором действует пожар, находится во взрывоопасном состоянии",
        ],
      },
      {
        code: "20404185",
        text: "В каком из перечисленных случаев допускается продолжать работы по поиску и спасению людей в подземных горных выработках, в которых имеется взрывоопасная среда при наличии очагов горения или существует угроза прорыва воды (рассола, пульпы)?",
        answers: [
          "В случае если рудничная атмосфера в выработках аварийного участка находилась в состоянии, непригодном для дыхания, в течение времени, равного двукратному сроку времени защитного действия средств индивидуальной защиты органов дыхания",
          "В случае если в местах предполагаемого нахождения людей в течение не менее 24 часов температура воздуха составляла 100 °C и более",
          "В случае если в местах предполагаемого нахождения людей горная выработка затоплена водой и из нее отсутствует запасной выход",
          "В случае если люди, находящиеся в аварийных горных выработках, признаны погибшими",
        ],
        correctAnswers: [
          "В случае если рудничная атмосфера в выработках аварийного участка находилась в состоянии, непригодном для дыхания, в течение времени, равного двукратному сроку времени защитного действия средств индивидуальной защиты органов дыхания",
        ],
      },
      {
        code: "20404186",
        text: "Что из перечисленного проверяется комиссией при проведении проверок готовности противоаварийной защиты нефтяной шахты перед подготовкой и утверждением плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах (нефтяной шахте)?",
        answers: [
          "Обеспеченность нефтяной шахты средствами передвижения, их пригодность для транспортирования людей",
          "Определение времени полной выработки вентиляторов местного проветривания",
          "Соответствие времени выхода людей на свежую струю воздуха сроку защитного действия используемых самоспасателей",
          "Надежность принятых мер по своевременному опрокидыванию вентиляционных струй при пожаре",
        ],
        correctAnswers: [
          "Соответствие времени выхода людей на свежую струю воздуха сроку защитного действия используемых самоспасателей",
        ],
      },
      {
        code: "20404187",
        text: "На кого возлагается руководство работами по локализации и ликвидации последствий аварий?",
        answers: [
          "На технического руководителя (главного инженера) опасного производственного объекта",
          "На начальника аварийно-спасательной службы",
          "На начальника участка",
        ],
        correctAnswers: [
          "На технического руководителя (главного инженера) опасного производственного объекта",
        ],
      },
      {
        code: "20404188",
        text: "На основании каких данных составляется оперативный план по локализации и ликвидации последствий аварий?",
        answers: [
          "Только поступающей на КП информации",
          "Только анализа аварийной обстановки",
          "Только прогноза развития аварии",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20404189",
        text: "В каких случаях в подземных горных выработках, в которых имеется взрывоопасная среда при наличии очагов горения или существует угроза прорыва воды (рассола, пульпы), работы по поиску и спасению людей не проводятся или прекращаются?",
        answers: [
          "Только в случае если рудничная атмосфера в выработках аварийного участка находилась в состоянии, непригодном для дыхания, в течение времени, равного десятикратному сроку времени защитного действия средств индивидуальной защиты органов дыхания изолирующего типа",
          "Только в случае если в местах предполагаемого нахождения людей в течение не менее 24 часов температура воздуха составляла 100 °C и более",
          "Только в случае если в местах предполагаемого нахождения людей горная выработка затоплена водой и из нее отсутствует запасной выход",
          "Только в случае если люди, находящиеся в аварийных горных выработках, признаны погибшими",
          "Во всех перечисленных",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "20404190",
        text: "Чем оснащается КП (командный пункт)?",
        answers: [
          "Только аппаратурой телефонной и (или) радиосвязи с местами ведения работ по локализации и ликвидации последствий аварии",
          "Только электронно-вычислительными машинами",
          "Только множительной и копировальной техникой",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20404191",
        text: "Какими способами осуществляется тушение подземных пожаров?",
        answers: [
          "Только активным тушением",
          "Только изоляцией горных выработок, в которых действует пожар",
          "Только комбинированным способом",
          "Всеми перечисленными",
        ],
        correctAnswers: ["Всеми перечисленными"],
      },
      {
        code: "20404192",
        text: "В каких подземных горных выработках запрещается тушение пожара водой?",
        answers: [
          "Угольных рудников",
          "Асбестовых рудников",
          "Соляных и калийных рудников",
        ],
        correctAnswers: ["Соляных и калийных рудников"],
      },
      {
        code: "20404193",
        text: "Каким образом устанавливаются порядок и обстоятельства, при которых допускается отстранить руководителя работ по ликвидации аварии и руководителя горноспасательных работ и назначить другое должностное лицо?",
        answers: [
          "Распорядительным документом, утверждаемым руководителями ОПО и ПАСС(Ф) соответственно",
          "Распорядительным документом, утверждаемым представителем территориального органа Ростехнадзора",
          "Распорядительным документом, утверждаемым представителем Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "На основании Инструкции по локализации и ликвидации последствий аварий на опасных производственных объектах, на которых ведутся горные работы",
        ],
        correctAnswers: [
          "Распорядительным документом, утверждаемым руководителями ОПО и ПАСС(Ф) соответственно",
        ],
      },
      {
        code: "20404194",
        text: "Каково номинальное время защитного действия автономных изолирующих дыхательных аппаратов (ДА) со сжатым воздухом на объектах открытых горных работ?",
        answers: [
          "Не менее 1 часа",
          "Не менее 50 минут",
          "Не менее 45 минут",
          "Не менее 30 минут",
        ],
        correctAnswers: ["Не менее 1 часа"],
      },
      {
        code: "20404195",
        text: "Кто определяет места возведения изоляционных перемычек, предназначенных для изоляции пожарного участка, их конструкцию, последовательность и сроки возведения?",
        answers: [
          "Руководитель работ по ликвидации аварии",
          "Руководитель горноспасательных работ",
          "Технический руководитель (главный инженер) ОПО",
          "Лицо, ответственное за пожарную безопасность ОПО",
          "Все ответы неверны",
        ],
        correctAnswers: ["Руководитель работ по ликвидации аварии"],
      },
      {
        code: "20404196",
        text: "Кем проверяются исправность реверсивных, переключающих и герметизирующих устройств вентилятора главного проветривания (ВГП)?",
        answers: [
          "Главным механиком и руководителем службы аэрологической безопасности",
          "Главным маркшейдером",
          "Рабочим службы аэрологической безопасности",
          "Руководителем обособленного структурного подразделения",
        ],
        correctAnswers: [
          "Главным механиком и руководителем службы аэрологической безопасности",
        ],
      },
      {
        code: "20404197",
        text: "В каких из перечисленных случаев допускается эксплуатация рельсового пути?",
        answers: [
          "При износе головки рельсов типа Р-18 по вертикали 4 мм",
          "При касании ребордой колеса вагона головок болтов крепления рельса",
          "При повреждениях и дефектах рельсов, которые могут вызвать сход подвижного состава с рельсовых путей",
          "При отклонении рельсов от оси пути на стыках (излом) 80 мм на длине рельсового пути 5 м",
        ],
        correctAnswers: [
          "При износе головки рельсов типа Р-18 по вертикали 4 мм",
        ],
      },
      {
        code: "20404198",
        text: "В каких случаях допускается эксплуатация стрелочных переводов?",
        answers: [
          "При замыкании стрелок с зазором между прижатым острием пера и рамным рельсом 3 мм",
          "При разъединенных стрелочных тягах",
          "При отсутствии фиксации положения стрелочных переводов",
          "При открытых канавах стрелочных переводов",
          "При неплотно прилегающих к рамному рельсу и башмакам стрелочных перьях",
        ],
        correctAnswers: [
          "При замыкании стрелок с зазором между прижатым острием пера и рамным рельсом 3 мм",
        ],
      },
      {
        code: "20404199",
        text: "С какой периодичностью осуществляется осмотр и проверка аварийно-ремонтных и вспомогательных подъемных установок работником обособленного структурного подразделения?",
        answers: [
          "Не реже 1 раза в неделю",
          "Ежедневно",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 3 месяца",
        ],
        correctAnswers: ["Не реже 1 раза в неделю"],
      },
      {
        code: "20404200",
        text: "В каком случае эксплуатирующая организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах на несколько опасных объектов?",
        answers: [
          "В случае если 2 и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
          "В случае если это регламентировано внутренней документацией организации",
          "В случае если объекты зарегистрированы в государственном реестре опасных производственных объектов",
          "План мероприятий разрабатывается на каждый опасный объект отдельно",
        ],
        correctAnswers: [
          "В случае если 2 и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
        ],
      },
      {
        code: "20404201",
        text: "Что из перечисленного входит в должностные обязанности руководителя горноспасательных работ (РГСР)?",
        answers: [
          "Только обеспечение прибытия горноспасательных отделений и других сил и средств ПАСС(Ф) для ведения горноспасательных работ в количестве и в сроки, предусмотренные ПЛА",
          "Только получение заданий от руководителя работ по ликвидации аварии (РЛА) на проведение горноспасательных работ",
          "Только проведение анализа полученной от РЛА информации о происшедшей аварии",
          "Только организация ведения оперативного журнала ПАСС(Ф)",
          "Только организация ведения горноспасательных работ",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20404202",
        text: "Какое из перечисленных требований при тушении пожаров в вертикальных горных выработках указано верно?",
        answers: [
          "При тушении активным способом и локализации пожаров в вертикальных горных выработках с восходящей струей воздуха с применением воды, подаваемой с поверхности, выполняются меры, исключающие опрокидывание вентиляционной струи",
          "Пожары в вертикальных горных выработках тушатся активным способом снизу вверх",
          "Нахождение людей в вертикальных горных выработках во время тушения в них пожара допускается при условии выполнения мер, исключающих опрокидывание вентиляционной струи",
          "При тушении активным способом и локализации пожара в вертикальном стволе с исходящей вентиляционной струей или в надшахтном здании этого ствола возможно изменение направления вентиляционной струи",
        ],
        correctAnswers: [
          "При тушении активным способом и локализации пожаров в вертикальных горных выработках с восходящей струей воздуха с применением воды, подаваемой с поверхности, выполняются меры, исключающие опрокидывание вентиляционной струи",
        ],
      },
      {
        code: "20404203",
        text: "Какое максимальное допустимое избыточное давление в кессоне при ведении горноспасательных работ в автономных изолирующих дыхательных аппаратах?",
        answers: ["0,2 МПа", "0,1 МПа", "0,3 МПа", "0,4 МПа"],
        correctAnswers: ["0,2 МПа"],
      },
      {
        code: "20404204",
        text: "В каком случае инертизация рудничной атмосферы в изолируемом пространстве может быть начата до окончания возведения взрывоустойчивых изоляционных перемычек?",
        answers: [
          "В случае, если объем подаваемого инертного газа превышает расход воздуха, поступающего на пожарный участок",
          "В случае, если продолжительность подачи инертного газа превышает допустимую",
          "В случае, если это определено оперативным планом",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "В случае, если объем подаваемого инертного газа превышает расход воздуха, поступающего на пожарный участок",
        ],
      },
      {
        code: "20404205",
        text: "Какую информацию не включает в себя общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
          "Характеристику объектов, в отношении которых разрабатывается план мероприятий",
          "Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий, а также источники (места) их возникновения",
          "Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах",
        ],
        correctAnswers: [
          "Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
        ],
      },
      {
        code: "20404206",
        text: "Какая максимально допустимая продолжительность пребывания работников ПАСС(Ф) и членов вспомогательной горноспасательной команды (ВКГ) установлена для их пребывания в непригодной для дыхания атмосфере с применением дыхательных аппаратов?",
        answers: ["4 часа", "3 часа", "2 часа", "5 часов"],
        correctAnswers: ["4 часа"],
      },
      {
        code: "20404207",
        text: "Кто утверждает планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.",
        answers: [
          "Руководитель (заместители руководителей) организаций, эксплуатирующих объекты",
          "Руководители обособленных подразделений юридических лиц",
          "Руководители профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований",
          "Инспектор Ростехнадзора",
        ],
        correctAnswers: [
          "Руководитель (заместители руководителей) организаций, эксплуатирующих объекты",
          "Руководители обособленных подразделений юридических лиц",
        ],
      },
      {
        code: "20404208",
        text: "Какое из перечисленных требований к оперативным планам по локализации и ликвидации последствий аварий указаны неверно?",
        answers: [
          "Оперативный план утверждает руководитель горноспасательных работ",
          "Оперативные планы разрабатываются до окончания ведения горноспасательных работ",
          "Последующие оперативные планы (N 2, 3 и т. д.) разрабатываются в случаях, когда мероприятия предыдущего оперативного плана реализованы или требуется их корректировка",
          "Оперативный план не должен содержать ссылок на пункты ранее разработанных оперативных планов",
        ],
        correctAnswers: [
          "Оперативный план утверждает руководитель горноспасательных работ",
        ],
      },
      {
        code: "20404209",
        text: "При каких условиях допускается ведение горноспасательных работ в тупиковой выработке?",
        answers: [
          "При тушении пожара в выработанном пространстве подачей огнетушащих веществ дистанционно, при этом люди должны находиться на расстоянии от места возникновения аварии, далее которого отсутствует вероятность воздействия опасных факторов аварии на человека",
          "При отсутствии информации о содержании в ней горючих газов",
          "При отсутствии в выработке пострадавших",
          "При тушении пожара в непроветриваемых тупиковых горных выработках",
        ],
        correctAnswers: [
          "При тушении пожара в выработанном пространстве подачей огнетушащих веществ дистанционно, при этом люди должны находиться на расстоянии от места возникновения аварии, далее которого отсутствует вероятность воздействия опасных факторов аварии на человека",
        ],
      },
      {
        code: "20404210",
        text: "Какая максимальная температура воздуха допускается в добычных, подготовительных и других действующих выработках у мест, где работают люди при относительной влажности до 90 %?",
        answers: ["26 °C", "24 °C", "28 °C", "25 °C"],
        correctAnswers: ["26 °C"],
      },
      {
        code: "20404211",
        text: "На кого возлагается персональная ответственность за наличие и качество материалов, находящихся в противопожарных подземных и поверхностных складах в соответствии с планом ликвидации аварий при ведении работ в подземных условиях?",
        answers: [
          "На начальника шахты",
          "На технического руководителя эксплуатирующей организации",
          "На командира горноспасательного формирования",
          "На специально назначенное лицо технического надзора",
        ],
        correctAnswers: ["На начальника шахты"],
      },
      {
        code: "20404212",
        text: "Где должны находиться лица, на которых возлагается ответственность за руководство ликвидацией аварий в шахте в данную смену?",
        answers: [
          "На поверхности в командном пункте",
          "Непосредственно в шахте",
          "На ближайшем к шахтному стволу участке подземных работ",
          "Не регламентируется",
          "Все ответы неверны",
        ],
        correctAnswers: ["На поверхности в командном пункте"],
      },
      {
        code: "20404213",
        text: "Что предусматривается в плане ликвидации аварий при ведении работ в подземных условиях?",
        answers: [
          "Только мероприятия по спасению людей, пути вывода людей, застигнутых аварией, из зоны опасного воздействия, связанной с аварией",
          "Только мероприятия по ликвидации аварий и предупреждению их развития",
          "Только действия ВГСЧ и вспомогательных горноспасательных команд (ВГК) в начальной стадии возникновения аварий",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20404214",
        text: "Кто является ответственным руководителем работ по спасению людей и ликвидации аварий до момента прибытия главного инженера шахты?",
        answers: [
          "Горный диспетчер",
          "Командир горноспасательного формирования",
          "Начальник участка аэрологической безопасности",
          "Дежурный работник горноспасательного формирования",
          "Все ответы неверны",
        ],
        correctAnswers: ["Горный диспетчер"],
      },
      {
        code: "20404215",
        text: "Какая документация должна находиться на командном пункте?",
        answers: [
          "Только оперативные журналы ОПО и ПАСС(Ф)",
          "Только план ликвидации аварий (ПЛА)",
          "Только оперативные планы, текстовая и графическая документация, разрабатываемая в период локализации и ликвидации последствий аварии",
          "Вся перечисленная документация",
        ],
        correctAnswers: ["Вся перечисленная документация"],
      },
      {
        code: "20404216",
        text: "Кто из перечисленных лиц обеспечивает подачу сжатого воздуха или воды к месту аварии в случаях, предусмотренных планом ликвидации аварий?",
        answers: [
          "Руководитель энергомеханической службы опасного производственного объекта (ОПО)",
          "Начальник службы (участка), обеспечивающей (обеспечивающего) аэрологическую безопасность подземных горных выработок",
          "Начальник участка, на котором произошла авария",
          "Специалист ОПО, ответственный за учет и выдачу индивидуальных головных светильников, самоспасателей, газоанализаторов",
        ],
        correctAnswers: [
          "Руководитель энергомеханической службы опасного производственного объекта (ОПО)",
        ],
      },
      {
        code: "20404217",
        text: "Какое из перечисленных мероприятий не проводится при ликвидации последствий горного удара, обрушения пород, оползня?",
        answers: [
          "Мониторинг концентрации индикаторных пожарных газов",
          "Определение возможности развития обрушения (оползня) и безопасных способов выполнения горноспасательных работ",
          "Организация работы по разборке обрушившейся горной массы и (или) проведение поисковых выработок из возможно большего числа мест",
          "Установление связи с застигнутыми аварией людьми, не имеющими возможности выйти из зоны аварии",
        ],
        correctAnswers: ["Мониторинг концентрации индикаторных пожарных газов"],
      },
      {
        code: "20404218",
        text: "Кем утверждается план ликвидации аварий при ведении работ в подземных условиях?",
        answers: [
          "Техническим руководителем эксплуатирующей организации (главным инженером организации)",
          "Руководителем эксплуатирующей организации",
          "Командиром горноспасательного формирования",
          "Начальником шахты",
        ],
        correctAnswers: [
          "Техническим руководителем эксплуатирующей организации (главным инженером организации)",
        ],
      },
      {
        code: "20404219",
        text: "В каком приоритетном порядке предварительно определяется вид происшедшей аварии при отсутствии информации, позволяющей достоверно определить ее вид, в подземных горных выработках?",
        answers: [
          "Взрыв, внезапный выброс, пожар, горный удар, обрушение",
          "Взрыв, пожар, внезапный выброс, горный удар, обрушение",
          "Взрыв, горный удар, обрушение, внезапный выброс, пожар",
          "Взрыв, горный удар, внезапный выброс, пожар, обрушение",
        ],
        correctAnswers: [
          "Взрыв, внезапный выброс, пожар, горный удар, обрушение",
        ],
      },
      {
        code: "20404220",
        text: "При каком виде аварии члены вспомогательной горноспасательной команды устанавливают связь с застигнутыми аварией людьми, организуют их спасение, предварительно усилив крепь, тем самым исключив возможные обрушения?",
        answers: [
          "При обрушении в горной выработке",
          "При пожаре в тупиковой выработке",
          "При взрыве метана и (или) угольной пыли",
          "При внезапном выбросе угля (породы)",
          "Все ответы неверны",
        ],
        correctAnswers: ["При обрушении в горной выработке"],
      },
      {
        code: "20404221",
        text: "Каким образом распределяется максимальная продолжительность (время) непрерывного пребывания работников ПАСС(Ф) в зоне высоких температур при передвижении по подземным горным выработкам с непригодной для дыхания атмосферой?",
        answers: [
          'Одна треть на передвижение в направлении "туда" и две трети - в направлении "обратно"',
          'Две трети на передвижение в направлении "туда" и одна треть - в направлении "обратно"',
          'Половина на передвижение в направлении "туда" и половина - в направлении "обратно"',
          "По решению технического руководителя (главного инженера) ОПО",
        ],
        correctAnswers: [
          'Одна треть на передвижение в направлении "туда" и две трети - в направлении "обратно"',
        ],
      },
      {
        code: "20404222",
        text: "В каком случае допускается нахождение людей, занятых тушением подземного пожара активным способом, в горных выработках с исходящей от пожара струей воздуха?",
        answers: [
          "Только в случае, когда пожар находится вблизи выработок со свежей струей воздуха",
          "Только в случае выполнения мер, предотвращающих обрушение (высыпание) горных пород",
          "Только в случае тушения пожара водой",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "Только в случае, когда пожар находится вблизи выработок со свежей струей воздуха",
        ],
      },
      {
        code: "20404223",
        text: "Кем выполняется вскрытие перемычек и разгазирование изолированных горных выработок?",
        answers: [
          "Профессиональным аварийно-спасательным формированием",
          "Эксплуатирующей организацией",
          "Службой аэрологической безопасности",
          "Монтажной организацией",
        ],
        correctAnswers: [
          "Профессиональным аварийно-спасательным формированием",
        ],
      },
      {
        code: "20404224",
        text: "С какой периодичностью проводятся проверка износа рельсов и нивелирование профиля откаточных путей?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 2 раз в месяц",
          "Не реже 1 раза в месяц",
          "Периодичность устанавливается техническим руководителем обособленного структурного подразделения",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "20404225",
        text: "В каком из перечисленных случаев пересматриваются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.",
        answers: [
          "Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий",
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
          "Не позднее 40 дней после внесения изменений в системы управления технологическими процессами на объекте",
          "В случае назначения нового руководителя организации",
          "Не позднее 15 дней после внесения изменений в методики (методы) измерений или типы средств измерений, применяемые при осуществлении производственного контроля за соблюдением требований промышленной безопасности на объекте",
        ],
        correctAnswers: [
          "Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий",
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
        ],
      },
      {
        code: "20404226",
        text: "Из какого минимального количества человек должно состоять горноспасательное отделение или отделение вспомогательной горноспасательной команды при ведении горноспасательных работ в непригодной для дыхания рудничной атмосфере при работе на открытых горных выработках?",
        answers: [
          "Из 5 человек",
          "Из 2 человек",
          "Из 3 человек",
          "Из 6 человек",
          "Все ответы неверны",
        ],
        correctAnswers: ["Из 3 человек"],
      },
      {
        code: "20404227",
        text: "Какое из перечисленных действий допускается при ведении горноспасательных работ в подземных горных выработках в зоне высоких температур с непригодной для дыхания атмосферой?",
        answers: [
          "Только ведение горноспасательных работ в зоне высоких температур с непригодной для дыхания атмосферой при отсутствии связи горноспасательного отделения, ведущего горноспасательные работы, с руководителем горноспасательных работ или с горноспасательным отделением, находящимся на подземной горноспасательной базе, при условии, что эти работы связаны со спасением людей",
          "Только привлечение членов вспомогательной горноспасательной команды для ведения горноспасательных работ в зоне высоких температур с непригодной для дыхания атмосферой",
          "Только ведение горноспасательных работ, не связанных со спасением людей, в зоне высоких температур с непригодной для дыхания атмосферой без средств противотепловой индивидуальной защиты при температуре выше 50 °C",
          "Все перечисленные действия",
        ],
        correctAnswers: [
          "Только ведение горноспасательных работ в зоне высоких температур с непригодной для дыхания атмосферой при отсутствии связи горноспасательного отделения, ведущего горноспасательные работы, с руководителем горноспасательных работ или с горноспасательным отделением, находящимся на подземной горноспасательной базе, при условии, что эти работы связаны со спасением людей",
        ],
      },
      {
        code: "20404228",
        text: "До какой минимальной температуры разрешается ведение горноспасательных работ в горных выработках с непригодной для дыхания атмосферой?",
        answers: [
          "До температуры минус 20 °C",
          "До температуры минус 15 °C",
          "До температуры минус 10 °C",
          "До температуры минус 8 °C",
        ],
        correctAnswers: ["До температуры минус 20 °C"],
      },
      {
        code: "20404229",
        text: "При каком угле наклона наклонной горной выработки пожар в ней тушится активным способом дистанционно?",
        answers: [
          "При угле наклона более 20°",
          "При угле наклона более 15°",
          "При угле наклона более 10°",
          "При любомугле наклона наклонной горной выработки",
        ],
        correctAnswers: ["При угле наклона более 20°"],
      },
      {
        code: "20404230",
        text: "Кто осуществляет руководство работами по локализации и ликвидации последствий аварии?",
        answers: [
          "Руководитель ОПО",
          "Должностное лицо Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "Технический руководитель (главный инженер) ОПО",
          "Должностное лицо профессионального аварийно-спасательного формирования, обслуживающего ОПО, назначенное распорядительным документом руководителя профессионального аварийно-спасательного формирования",
        ],
        correctAnswers: ["Технический руководитель (главный инженер) ОПО"],
      },
      {
        code: "20404231",
        text: "С кем согласовываются планы мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "С Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "С Ростехнадзором и Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
          "С Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий и аварийно-спасательным формированием",
        ],
        correctAnswers: [
          "С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
        ],
      },
      {
        code: "20404232",
        text: "Какие требования установлены Инструкцией по локализации и ликвидации последствий аварий на опасных производственных объектах, на которых ведутся горные работы, к лицам, допускаемым к руководству работами по локализации и ликвидации последствий аварии и горноспасательными работами?",
        answers: [
          'Возраст не менее 21 года, отсутствие медицинских противопоказаний для данного вида работ, наличие высшего или среднего профессионального образования по направлению подготовки (специальности) "Прикладная геодезия", прохождение стажировки сроком не менее 3 месяцев',
          'Наличие высшего образования по направлению подготовки (специальности) "Техносферная безопасность"',
          "Наличие стажа работы не менее 1 года в организации, эксплуатирующей опасные производственные объекты, на которых ведутся горные работы",
          'Наличие высшего или среднего профессионального образования по направлению подготовки (специальности) "Горное дело" и прохождение обучения по программам подготовки руководителя работ по ликвидации аварий и руководителя горноспасательных работ соответственно',
        ],
        correctAnswers: [
          'Наличие высшего или среднего профессионального образования по направлению подготовки (специальности) "Горное дело" и прохождение обучения по программам подготовки руководителя работ по ликвидации аварий и руководителя горноспасательных работ соответственно',
        ],
      },
      {
        code: "20404233",
        text: "Кто принимает решения об окончании, приостановлении и возобновлении работ по локализации и ликвидации последствий аварий или горноспасательных работ?",
        answers: [
          "Руководитель горноспасательных работ (РГСР)",
          "Руководитель опасного производственного объекта (ОПО)",
          "Руководитель работ по ликвидации аварии (РЛА)",
          "Руководитель профессионального аварийно-спасательного формирования (ПАСС(Ф)",
        ],
        correctAnswers: ["Руководитель работ по ликвидации аварии (РЛА)"],
      },
      {
        code: "20404234",
        text: "Какова минимально допустимая численность горноспасательного отделения или отделения вспомогательной горноспасательной команды (ВГК) для ведения горноспасательных работ в непригодной для дыхания рудничной атмосфере при работе в подземных горных выработках?",
        answers: ["5 человек", "3 человека", "2 человека", "6 человек"],
        correctAnswers: ["5 человек"],
      },
      {
        code: "20404235",
        text: "Кто имеет право письменным приказом отстранить ответственного руководителя работ и принять руководство на себя или возложить его на другое лицо из числа должностных лиц шахты?",
        answers: [
          "Начальник шахты",
          "Командир ВГСЧ",
          "Главный энергетик шахты",
          "Главный инженер организации (предприятия)",
        ],
        correctAnswers: ["Главный инженер организации (предприятия)"],
      },
      {
        code: "20404236",
        text: "Чье решение является обязательным для выполнения в случае разногласия между командиром ВГСЧ и ответственным руководителем работ при организации работ по ликвидации аварии?",
        answers: [
          "Ответственного руководителя работ по ликвидации аварий",
          "Командира ВГСЧ",
          "Экспертной комиссии",
          "Вышестоящего руководства организации",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Ответственного руководителя работ по ликвидации аварий",
        ],
      },
      {
        code: "20404237",
        text: "Кем подписывается оперативная часть плана ликвидации аварий в конце последней позиции с указанием даты составления плана?",
        answers: [
          "Руководителем организации (предприятия)",
          "Главным инженером шахты",
          "Командиром ВГСЧ",
          "Начальником ПВС",
        ],
        correctAnswers: ["Главным инженером шахты"],
      },
      {
        code: "20404238",
        text: "Что из перечисленного наносится на аксонометрических схемах и погоризонтных планах вентиляционных горных выработок в виде кружков диаметром 13 мм?",
        answers: [
          "Позиции, соответствующие оперативной части плана ликвидации аварий",
          "Места размещения самоспасателей",
          "Места размещения огнетушителей",
          "Места размещения телефонов",
          "Главные вентиляционные установки",
        ],
        correctAnswers: [
          "Позиции, соответствующие оперативной части плана ликвидации аварий",
        ],
      },
      {
        code: "20404239",
        text: "Какая принимается скорость передвижения людей в изолирующих самоспасателях по загазованным наклонным выработкам с углом наклона 10° при подъеме?",
        answers: [
          "45 м/мин",
          "30 м/мин",
          "60 м/мин",
          "25 м/мин",
          "Все ответы неверны",
        ],
        correctAnswers: ["45 м/мин"],
      },
      {
        code: "20404240",
        text: "Какая принимается скорость передвижения людей в изолирующих самоспасателях по загазованным горизонтальным выработкам высотой 1,8 - 2,0 м?",
        answers: ["60 м/мин", "45 м/мин", "30 м/мин", "70 м/мин"],
        correctAnswers: ["60 м/мин"],
      },
      {
        code: "20404241",
        text: "Кем составляется акт проверки исправности противопожарных средств и оборудования плана ликвидации аварий?",
        answers: [
          "Комиссией в составе главного инженера шахты, начальника ПВС и командира ВГСЧ, обслуживающей данную шахту",
          "Комиссией в составе начальника шахты, начальника ПВС и инспектора Ростехнадзора",
          "Комиссией в составе начальника шахты, начальника ПВС, механика шахты и командира ВГСЧ, обслуживающей данную шахту",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Комиссией в составе начальника шахты, начальника ПВС, механика шахты и командира ВГСЧ, обслуживающей данную шахту",
        ],
      },
      {
        code: "20404242",
        text: "Какое из перечисленных первоочередных мероприятий плана ликвидации аварий рекомендуется располагать первым в части аварий, связанных с загазованностью выработок?",
        answers: [
          "Установление аварийного режима работы главных вентиляционных установок",
          "Расстановка постов безопасности",
          "Режим электроснабжения шахты",
          "Вызов ВГСЧ",
        ],
        correctAnswers: [
          "Установление аварийного режима работы главных вентиляционных установок",
        ],
      },
      {
        code: "20404243",
        text: "Что из перечисленного допускается не предусматривать в оперативной части соответствующего плана для ликвидации подземных пожаров в начальной стадии?",
        answers: [
          "Использование имеющегося на шахте оборудования для ликвидации возможных аварий",
          "Места установок временных перемычек",
          "Использование насосов и ставов труб",
          "Порядок и способы использования водоотливных, воздухопроводных и противопожарных (противопожарно-оросительных) трубопроводов",
        ],
        correctAnswers: ["Использование насосов и ставов труб"],
      },
      {
        code: "20404244",
        text: "Кем должен тщательно рассматриваться маршрут движения отделений ВГСЧ на вывод людей и ликвидацию аварий в каждом конкретном случае? Укажите все правильные ответы.",
        answers: [
          "Главным инженером шахты",
          "Руководителем подразделения противопожарной службы",
          "Командиром ВГСЧ",
          "Представителем Ростехнадзора",
          "Представителем МЧС России",
        ],
        correctAnswers: ["Главным инженером шахты", "Командиром ВГСЧ"],
      },
      {
        code: "20404245",
        text: "При каких видах аварии необходимо предусматривать вызов служб МЧС России?",
        answers: [
          "При пожарах в надшахтных зданиях и сооружениях",
          "При всех видах аварий, когда требуется оказание помощи людям",
          "При пожарах в стволах, шурфах и других выработках, имеющих выход на поверхность",
          "При землетрясениях",
        ],
        correctAnswers: ["При землетрясениях"],
      },
      {
        code: "20404246",
        text: "Что из перечисленного должно предусматриваться при прорыве в горные выработки воды, пульпы, реагентов?",
        answers: [
          "Вывод людей только из аварийного участка",
          "Вывод всех людей из шахты на поверхность",
          "Все ответы неверны",
          "Вывод людей только из аварийного и угрожаемого участков",
        ],
        correctAnswers: ["Вывод всех людей из шахты на поверхность"],
      },
      {
        code: "20404247",
        text: "Кем прорабатывается план ликвидации аварий под расписку?",
        answers: [
          "Специализированной сторонней организацией",
          "Главным инженером шахты с командным составом ВГСЧ",
          "Главным инженером шахты с должностными лицами (техническим надзором) шахты",
          "Главным инженером шахты с инспектором Ростехнадзора",
        ],
        correctAnswers: [
          "Главным инженером шахты с должностными лицами (техническим надзором) шахты",
        ],
      },
      {
        code: "20404248",
        text: "Какие надписи должен иметь титульный лист плана ликвидации аварии?",
        answers: [
          "Только утверждающую надпись главного инженера организации",
          "Только согласование с командиром ВГСЧ",
          "Только подпись главного инженера шахты",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20404249",
        text: "Кем должна осуществляться проверка состояния предусмотренных планом ликвидации аварии материальных и технических средств для осуществления мероприятий по спасению людей и ликвидации аварий перед согласованием планов ликвидации аварий на новое полугодие? Укажите все правильные ответы.",
        answers: [
          "Работниками шахты",
          "Военизированной горноспасательной частью (ВГСЧ)",
          "Инспектором территориального органа Ростехнадзора",
          "Руководителем аварийно-спасательного формирования",
        ],
        correctAnswers: [
          "Работниками шахты",
          "Военизированной горноспасательной частью (ВГСЧ)",
        ],
      },
      {
        code: "20404250",
        text: "На какие виды работ распространяются Правила ведения газоопасных, огневых и ремонтных работ?",
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          "На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на выделенной и огражденной площадке на территории находящихся в эксплуатации опасных производственных объектов",
          "На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности",
          "На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы",
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения N 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "20404251",
        text: "На сколько групп подразделяются газоопасные работы в зависимости от степени опасности и на основании каких критериев устанавливается та или иная группа?",
        answers: [
          "На 3 группы, в зависимости от степени риска проводимых работ",
          "На 2 группы, в зависимости от того, проводятся работы в закрытом или открытом пространстве",
          "На 2 группы, в зависимости от того, проводятся газоопасные работы с оформлением наряда-допуска или без оформления",
        ],
        correctAnswers: [
          "На 2 группы, в зависимости от того, проводятся газоопасные работы с оформлением наряда-допуска или без оформления",
        ],
      },
      {
        code: "20404252",
        text: "Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?",
        answers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года",
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
          "Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ",
        ],
        correctAnswers: [
          "По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок",
        ],
      },
      {
        code: "20404253",
        text: "Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?",
        answers: [
          "Не моложе 18 лет",
          "Прошедшие медицинский осмотр в соответствии с требованиями законодательством Российской Федерации",
          "Прошедшие обучение приемам и методам проведения работ",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20404254",
        text: "К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?",
        answers: [
          "К II группе",
          "К I группе",
          "К группе работ средней степени опасности",
          "К группе работ высокой степени опасности",
        ],
        correctAnswers: ["К II группе"],
      },
      {
        code: "20404255",
        text: "Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?",
        answers: [
          "Руководитель структурного подразделения или лицо, его замещающее, но не более чем на 1 дневную рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск, на требуемый для окончания работ срок",
          "Руководитель структурного подразделения не более чем на 1 рабочую смену",
          "Лицо, зарегистрировавшее наряд-допуск, не более чем на 2 дневные смены",
        ],
        correctAnswers: [
          "Руководитель структурного подразделения или лицо, его замещающее, но не более чем на 1 дневную рабочую смену",
        ],
      },
      {
        code: "20404256",
        text: "Что должен сделать руководитель структурного подразделения, на объекте которого будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?",
        answers: [
          "Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы",
          "Провести обучение и инструктаж персонала, который будет проводить газоопасные работы",
          "Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ",
          "Определить структурные подразделения организации, с которыми будет взаимодействовать бригада исполнителей при проведении газоопасных работ",
        ],
        correctAnswers: [
          "Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы",
        ],
      },
      {
        code: "20404257",
        text: "В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?",
        answers: [
          "Не менее 3 месяцев со дня закрытия наряда-допуска",
          "Не менее 1 года со дня закрытия наряда-допуска",
          "Не менее 6 месяцев со дня закрытия наряда-допуска",
        ],
        correctAnswers: ["Не менее 6 месяцев со дня закрытия наряда-допуска"],
      },
      {
        code: "20404258",
        text: "Каким документом определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?",
        answers: [
          "Организационно-распорядительными документами руководителя",
          "Технологическим регламентом",
          "Федеральными нормами и правилами в области промышленной безопасности",
          "Правилами пожарной безопасности",
        ],
        correctAnswers: [
          "Организационно-распорядительными документами руководителя",
        ],
      },
    ],
    20405: [
      {
        code: "20405000",
        text: "В какой срок необходимо уведомить территориальный орган Ростехнадзора о начале работ по строительству подземных сооружений?",
        answers: [
          "За один день до начала работ",
          "За 15 дней до начала работ",
          "За 10 дней до начала работ",
          "За 30 календарных дней до начала работ",
        ],
        correctAnswers: ["За 15 дней до начала работ"],
      },
      {
        code: "20405001",
        text: "Кем могут разрабатываться проектные регламенты на все основные и вспомогательные производственные процессы при ведении горных работ подземным способом при отсутствии проектных регламентов?",
        answers: [
          "Эксплуатирующей организацией",
          "Специальной комиссией во главе с инспектором территориального органа Ростехнадзора",
          "Организацией, проводящей экспертизу промышленной безопасности ОПО",
          "Монтажно-наладочной организацией",
        ],
        correctAnswers: ["Эксплуатирующей организацией"],
      },
      {
        code: "20405002",
        text: "Каков максимально допустимый срок остановки производства горных работ, при котором эта остановка не требует безопасной организации возобновления горных работ?",
        answers: ["3 суток", "1 сутки", "2 суток", "4 суток"],
        correctAnswers: ["3 суток"],
      },
      {
        code: "20405003",
        text: "На какие из перечисленных видов производственных процессов рекомендуется разрабатывать регламенты технологических процессов при ведении горных работ подземным способом?",
        answers: [
          "Только на проходку шахтных стволов и подземных горных выработок",
          "Только на ведение очистных работ, закладочных работ",
          "Только на эксплуатацию технологического транспорта",
          "Только на защиту шахты от затопления и охрану объектов на дневной поверхности от вредного влияния горных работ, противопожарную защиту шахты",
          "На все перечисленные виды производственных процессов",
        ],
        correctAnswers: [
          "На все перечисленные виды производственных процессов",
        ],
      },
      {
        code: "20405004",
        text: "Каким образом участки горных выработок категории «Опасно» приводят в неудароопасное состояние?",
        answers: [
          "Путем создания в краевой части массива пород защитной зоны шириной n+1 м, но не более 2 м",
          "Путем создания в краевой части массива пород защитной зоны шириной n+2 м, но не менее 2 м",
          "Путем создания в краевой части массива пород защитной зоны шириной не менее 1 м",
          "Путем создания в краевой части массива пород защитной зоны шириной не более 1 м",
        ],
        correctAnswers: [
          "Путем создания в краевой части массива пород защитной зоны шириной n+2 м, но не менее 2 м",
        ],
      },
      {
        code: "20405005",
        text: "Чем должно быть регламентировано приведение горных выработок в неудароопасное состояние?",
        answers: [
          "Проектом, утвержденным председателем Комиссии (техническим руководителем эксплуатирующей объект ведения горных работ организации)",
          "Указаниями научно-исследовательского института",
          "Техническими условиями, разработанными специализированной организацией",
          "Локальным прогнозом удароопасности",
        ],
        correctAnswers: [
          "Проектом, утвержденным председателем Комиссии (техническим руководителем эксплуатирующей объект ведения горных работ организации)",
        ],
      },
      {
        code: "20405006",
        text: "Каким должно быть расстояние между стенками стволов при строительстве объекта подземного строительства на участках недр, склонных и опасных по горным ударам?",
        answers: [
          "Не менее 50 м",
          "Не менее 45 м",
          "Не менее 35 м",
          "Не менее 25 м",
        ],
        correctAnswers: ["Не менее 50 м"],
      },
      {
        code: "20405007",
        text: "Какой категории по степени опасности участков горного массива вокруг выработок не существует?",
        answers: ['"Опасно"', '"Неопасно"', '"Чрезвычайно опасно"'],
        correctAnswers: ['"Чрезвычайно опасно"'],
      },
      {
        code: "20405008",
        text: "В каком случае нарушены требования безопасности при проходке стволов?",
        answers: [
          "Пересечение выработок должно осуществляться под прямым или близким к нему углом. Очередность проведения выработок регламентируется Указаниями",
          'Образующийся при сбойке целик должен быть приведен при категории "Опасно" в неопасное состояние по всей площади',
          "При проходке стволов допускается отставание постоянной крепи от забоя не более одного диаметра ствола вчерне",
          "Сбойка двух выработок на участках, склонных и опасных по горным ударам, с расстояния между забоями 20 м должна вестись двумя забоями",
        ],
        correctAnswers: [
          "Сбойка двух выработок на участках, склонных и опасных по горным ударам, с расстояния между забоями 20 м должна вестись двумя забоями",
        ],
      },
      {
        code: "20405009",
        text: "При выполнении каких из перечисленных профилактических мероприятий должно осуществляться проведение и поддержание выработок на месторождениях, на которых происходили горные удары?",
        answers: [
          "Крепление выработок, предотвращающее обрушение пород кровли и боков",
          "Бурение разгрузочных щелей и скважин",
          "Контурное взрывание",
          "Создание устойчивых форм",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20405010",
        text: "В каких случаях должен производиться контроль удароопасности в выработках?",
        answers: [
          "При проявлении внешних признаков удароопасности",
          "При изменениях горно-геологической обстановки во время ведения горных работ в местах, где ранее была установлена категория «Опасно»",
          'При изменениях горнотехнической обстановки во время ведения горных работ в местах, где ранее была установлена категория "Опасно"',
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20405011",
        text: "Кто на каждом объекте, отрабатывающем месторождения, склонном и опасном по горным ударам, производит учет всех случаев горных ударов, микроударов и толчков, стреляний, интенсивного заколообразования и шелушения, результатов определения категорий удароопасности выработок, примененных мер по предотвращению горных ударов с оценкой их эффективности?",
        answers: [
          "Служба прогноза и предотвращения горных ударов или маркшейдерско-геологическая служба",
          "Технический руководитель эксплуатирующей объект ведения горных работ организации",
          "Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении",
        ],
        correctAnswers: [
          "Служба прогноза и предотвращения горных ударов или маркшейдерско-геологическая служба",
        ],
      },
      {
        code: "20405012",
        text: "В каком разделе указаний по безопасному ведению горных работ на месторождениях, склонных и опасных по горным ударам, представляется характеристика горных ударов, других геодинамических явлений, отражающих специфику геологических условий и технологии отработки месторождения?",
        answers: [
          "Общие положения",
          "Введение",
          "Порядок вскрытия, подготовки к выемке и отработки выемочных участков, блоков",
          "Методы прогноза удароопасности участков массива горных пород и руд",
          "Приведение горных выработок в неудароопасное состояние",
        ],
        correctAnswers: ["Общие положения"],
      },
      {
        code: "20405013",
        text: "В каком случае горные работы следует производить с применением комплекса мер по профилактике горных ударов, высыпаний, вывалов и обрушения пород (руд) на участках рудного массива или массива горных пород?",
        answers: [
          "Если участок рудного массива удален на 5 - 10 м от плоскости смесителя тектонического нарушения",
          "Расположенных на любом удалении от плоскости сместителя тектонического нарушения",
          "Данный комплекс мер допускается не производить на участках рудного массива или массива горных пород",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Если участок рудного массива удален на 5 - 10 м от плоскости смесителя тектонического нарушения",
        ],
      },
      {
        code: "20405014",
        text: "Разработку каких рудных тел необходимо производить преимущественно системой одностадийного этажного или подэтажного принудительного обрушения с отбойкой руды на зажатую среду?",
        answers: [
          "Крутопадающих мощных рудных тел",
          "Наклонных рудных тел",
          "Горизонтальных рудных тел",
          "Все ответы неверны",
          "Пологих рудных тел",
        ],
        correctAnswers: ["Крутопадающих мощных рудных тел"],
      },
      {
        code: "20405015",
        text: "В каком случае допускаются отступления от последовательного продвигания фронта очистных работ в пределах как шахтного поля (участка), так и месторождения в целом, при отработке месторождений, склонных и опасных по горным ударам?",
        answers: [
          "С разрешения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
          "Если данные отступления определяются указаниями по безопасному ведению горных работ на месторождении, склонном и опасном по горным ударам",
          "В соответствии с требованиями Правил безопасности при ведении горных работ и переработке твердых полезных ископаемых",
          "Не допускаются ни в каком случае",
        ],
        correctAnswers: [
          "С разрешения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
        ],
      },
      {
        code: "20405016",
        text: 'Какие из перечисленных требований на участках категории "Опасно" указаны верно? Укажите все правильные ответы.',
        answers: [
          "Мероприятия по предотвращению горных ударов должны проводиться после возведения в стволе постоянной крепи",
          "При расположении стволов в породах, опасных по горным ударам, должны быть запроектированы и выполняться мероприятия, обеспечивающие снижение до неопасных значений концентраций напряжений в стенках ствола",
          'При проходке стволов на участках категории "Опасно" необходимо применять передовые разгрузочные щели с таким расчетом, чтобы плоскость щели располагалась перпендикулярно направлению действия максимальных горизонтальных напряжений',
          "В особо сложных условиях мероприятия по предотвращению горных ударов должны утверждаться представителем территориального управления Ростехнадзора",
        ],
        correctAnswers: [
          "При расположении стволов в породах, опасных по горным ударам, должны быть запроектированы и выполняться мероприятия, обеспечивающие снижение до неопасных значений концентраций напряжений в стенках ствола",
          'При проходке стволов на участках категории "Опасно" необходимо применять передовые разгрузочные щели с таким расчетом, чтобы плоскость щели располагалась перпендикулярно направлению действия максимальных горизонтальных напряжений',
        ],
      },
      {
        code: "20405017",
        text: "Какими методами производится локальный прогноз удароопасности участков массива горных пород и руд, а также оценка эффективности мер предотвращения горных ударов?",
        answers: [
          "Геомеханическими методами",
          "Геофизическими методами",
          "Всеми перечисленными",
          "Визуальными наблюдениями за разрушением приконтурного массива выработок",
        ],
        correctAnswers: ["Всеми перечисленными"],
      },
      {
        code: "20405018",
        text: "На какой основе осуществляется изменение параметров противоударных мероприятий и периодичности прогноза степени удароопасности, предусмотренных Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых?",
        answers: [
          "Изменение параметров и периодичности не осуществляется ни в каком случае",
          "На основе заключения представителя территориального управления Ростехнадзора",
          "На основе специальных исследований и заключения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
          "На основе специальных исследований и заключения специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
        ],
        correctAnswers: [
          "На основе специальных исследований и заключения специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
        ],
      },
      {
        code: "20405019",
        text: "Что необходимо соблюдать при проектировании вскрытия, подготовки, систем разработки на участках недр, склонных и опасных по горным ударам?",
        answers: [
          "Только осуществлять разделение месторождения на шахтные поля и порядок их отработки, чтобы обеспечивалось планомерное извлечение запасов без образования целиков, острых углов и выступов фронта очистных работ",
          "Только ограничивать встречные и догоняющие фронты очистных работ",
          "Только преимущественно проводить горные выработки по направлению наибольшего напряжения в массиве горных пород, соблюдая все перечисленные условия",
          "Только сокращать применение систем разработки с открытым очистным пространством",
        ],
        correctAnswers: [
          "Только преимущественно проводить горные выработки по направлению наибольшего напряжения в массиве горных пород, соблюдая все перечисленные условия",
        ],
      },
      {
        code: "20405020",
        text: "Кем утверждается акт о проведении опытно-промышленных испытаний?",
        answers: [
          "Техническим руководителем организации",
          "Руководителем организации",
          "Лицом, ответственным за проведение ОПИ",
        ],
        correctAnswers: ["Техническим руководителем организации"],
      },
      {
        code: "20405021",
        text: "В каком случае месторождение (объект строительства подземных сооружений) относится к опасному по горным ударам?",
        answers: [
          "Только если имели место микроудары и горные удары",
          'Только если прогнозом при определении степени удароопасности массива горных пород локальными инструментальными методами выявлена категория "Опасно" (удароопасная)',
          "Только если имело место стреляние пород, интенсивное заколообразование, происходили толчки или горные удары на соседнем руднике (шахте) в пределах того же рудного тела и в аналогичных геологических условиях",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20405022",
        text: "Кем утверждается документация по ведению горных работ?",
        answers: [
          "Техническим руководителем обособленного структурного подразделения",
          "Специалистом, ответственным за осуществление производственного контроля",
          "Главным механиком",
          "Главным инженером проекта",
        ],
        correctAnswers: [
          "Техническим руководителем обособленного структурного подразделения",
        ],
      },
      {
        code: "20405023",
        text: "Кем в организации утверждается перечень опасных зон?",
        answers: [
          "Главным маркшейдером",
          "Главным инженером организации",
          "Начальником службы производственного контроля",
          "Руководителем организации",
        ],
        correctAnswers: ["Главным инженером организации"],
      },
      {
        code: "20405024",
        text: "Какими службами организации с привлечением специализированных предприятий определяются границы опасных зон по прорывам воды и газов при комбинированной (совмещенной) разработке с неблагоприятными гидрогеологическими условиями, наличием затопленных горных выработок или других водных объектов, а также горных выработок, в которых возможны скопления ядовитых и горючих газов? Выберите два правильных варианта ответа.",
        answers: [
          "Маркшейдерской службой",
          "Геологической службой",
          "Технологической службой",
          "Службой аэрологической безопасности",
        ],
        correctAnswers: ["Маркшейдерской службой", "Геологической службой"],
      },
      {
        code: "20405025",
        text: "Что относится к комбинированной разработке месторождений?",
        answers: [
          "Ведение открытых и подземных горных работ с совмещением в пространстве по вертикали или горизонтали в пределах одного месторождения",
          "Одновременное ведение открытых и подземных горных работ с совмещением в пространстве по вертикали или по горизонтали в пределах одного месторождения",
          "Последовательное ведение открытых и подземных горных работ с совмещением в пространстве по вертикали или горизонтали в пределах одного месторождения",
        ],
        correctAnswers: [
          "Ведение открытых и подземных горных работ с совмещением в пространстве по вертикали или горизонтали в пределах одного месторождения",
        ],
      },
      {
        code: "20405026",
        text: "Какими преимущественно системами разработки при совместной разработке месторождения следует производить подземные горные работы?",
        answers: [
          "Только системами разработки с закладкой выработанного пространства",
          "Преимущественно системами с поддержанием выработанного пространства",
          "В каждом конкретном случае возможность применения той или иной системы разработки устанавливается проектной организацией по согласованию с Ростехнадзором",
          "Любыми системами разработки",
        ],
        correctAnswers: [
          "Преимущественно системами с поддержанием выработанного пространства",
        ],
      },
      {
        code: "20405027",
        text: "В каком случае нарушены требования к осушению и водоотливу при комбинированной (совмещенной) разработке месторождений с неблагоприятными гидрогеологическими условиями?",
        answers: [
          "Допускается использовать действующие рудоспуски и вентиляционные восстающие в качестве дренажных выработок для перепуска грунтовых и паводковых вод из карьера",
          "Допускается частичная отработка барьерных целиков без предварительного отвода воды по проекту, утвержденному руководителем предприятия и согласованному со специализированной организацией",
          "Вода из карьера в подземные выработки должна поступать самотеком через дренажные скважины с обязательным применением забивных или сквозных фильтров",
          "Горные работы в пределах барьерного или предохранительного целика под водоемом (затопленным карьером) должны производиться только после спуска воды из затопленных выработок",
        ],
        correctAnswers: [
          "Допускается использовать действующие рудоспуски и вентиляционные восстающие в качестве дренажных выработок для перепуска грунтовых и паводковых вод из карьера",
        ],
      },
      {
        code: "20405028",
        text: "За какое время рабочие агрегаты главной водоотливной установки должны обеспечивать откачку максимального суточного притока воды?",
        answers: ["За 24 часа", "За 22 часа", "За 20 часов", "За 21 час"],
        correctAnswers: ["За 20 часов"],
      },
      {
        code: "20405029",
        text: "Какие из перечисленных факторов должны учитываться при проектировании, строительстве и эксплуатации горнорудных объектов с комбинированной (совмещенной) разработкой?",
        answers: [
          "Только наличие подземных пустот в границах и под дном карьера",
          "Только исключение прорывов в подземные горные выработки паводковых и ливневых вод",
          "При ведении горных работ должны учитываться все перечисленные факторы",
          "Только подработанность массивов горных пород, слагающих борта карьера",
        ],
        correctAnswers: [
          "При ведении горных работ должны учитываться все перечисленные факторы",
        ],
      },
      {
        code: "20405030",
        text: "В каком случае нарушены требования к системам вентиляции при комбинированной (совмещенной) разработке месторождения?",
        answers: [
          "Вентиляция горных работ при вскрытии и отработке запасов руд, находящихся в бортах и под дном карьера, может быть принята только локальной через вскрывающие выработки из карьера",
          "Вспомогательные нагнетательные вентиляторы должны быть максимально приближены к зонам аэродинамических связей с обеспечением подпора воздуха в подземных выработках",
          "При комбинированной (совмещенной) разработке месторождения и наличии аэродинамических связей подземных выработок с карьерным пространством предусматривается нагнетательный способ общерудничной системы проветривания",
          "Способы проветривания рудников (шахт) определяются проектом с учетом образования избыточной депрессии в зоне влияния открытых работ",
        ],
        correctAnswers: [
          "Вентиляция горных работ при вскрытии и отработке запасов руд, находящихся в бортах и под дном карьера, может быть принята только локальной через вскрывающие выработки из карьера",
        ],
      },
      {
        code: "20405031",
        text: "Какой вид контроля должен осуществляться при добыче под действующими блоками карьера за оставленным целиком безопасности, рассчитанным проектной организацией?",
        answers: [
          "Маркшейдерский и оперативный контроль в соответствии с Инструкцией по производству маркшейдерских работ",
          "Только маркшейдерский контроль",
          "Визуальный осмотр в рамках производственного контроля",
          "Только оперативный контроль",
        ],
        correctAnswers: [
          "Маркшейдерский и оперативный контроль в соответствии с Инструкцией по производству маркшейдерских работ",
        ],
      },
      {
        code: "20405032",
        text: "При проектировании, строительстве и эксплуатации горнодобывающих предприятий с комбинированной (совмещенной) разработкой месторождения должен учитываться ряд основных факторов, определяющих специфические условия и представляющих опасность при ведении горных работ. Относится ли к основным факторам вид полезного ископаемого разрабатываемого месторождения?",
        answers: [
          "Относится, если имеется вероятность возникновения пожаров при совмещенной разработке месторождений полезных ископаемых, склонных к самовозгоранию",
          "Относится",
          "Не относится, если отсутствует склонность месторождения или массива горных пород или их части к горным ударам",
          "Не относится",
        ],
        correctAnswers: ["Не относится"],
      },
      {
        code: "20405033",
        text: "Чем обуславливаются основные факторы, представляющие опасность при комбинированной (совмещенной) разработке месторождений?",
        answers: [
          "Степенью взаимного влияния и технологической взаимосвязи открытого и подземного способов разработки",
          "Степенью взаимного влияния",
          "Технологической взаимностью открытого способа разработки",
        ],
        correctAnswers: [
          "Степенью взаимного влияния и технологической взаимосвязи открытого и подземного способов разработки",
        ],
      },
      {
        code: "20405034",
        text: "Что запрещено при комбинированной разработке месторождений?",
        answers: [
          "Несвоевременная отработка и разрушение рудных целиков и потолочин в руднике от массовых взрывов",
          "Ограничение мощности массовых взрывов в карьере и подземных выработках",
          "Применение систем разработки, исключающих сдвижение (разрушение) массива предохранительного целика",
          "Применение нагнетательного способа проветривания подземных выработок",
        ],
        correctAnswers: [
          "Несвоевременная отработка и разрушение рудных целиков и потолочин в руднике от массовых взрывов",
        ],
      },
      {
        code: "20405035",
        text: "Что должен содержать специальный проект, по которому осуществляются горные работы при комбинированной разработке месторождений полезных ископаемых, наряду с принятыми техническими решениями?",
        answers: [
          "Функции и ответственность инженерно-технических служб",
          "Обоснование производственной (технической) возможности и технико-экономической целесообразности комбинированной разработки месторождения",
          "Параметры карьерного и шахтного полей с запасами полезного ископаемого, намечаемые к отработке открытым и подземным способами",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20405036",
        text: "Какое из перечисленных требований не допускается при комбинированной разработке месторождений?",
        answers: [
          "Добывать руду подземным способом из проектных контуров карьера",
          "Располагать фронт ведения горных работ в направлении от массива к карьеру при подземных очистных работах",
          "Располагать фронт ведения горных работ в направлении навстречу фронту развития подземных очистных работ при открытых работах",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20405037",
        text: "Кто выдает разрешение на производство разбивочных и основных маркшейдерских работ вблизи забоев подземных выработок?",
        answers: [
          "Начальник участка",
          "Главный маркшейдер",
          "Горный мастер",
          "Технический руководитель шахты",
        ],
        correctAnswers: ["Горный мастер"],
      },
      {
        code: "20405038",
        text: "Что из перечисленного наносится на маркшейдерские планы и разрезы?",
        answers: [
          "Только все горные выработки",
          "Только зоны обводненных горизонтов",
          "Только границы открытого очистного пространства на подземных работах",
          "Только отработанные камеры, границы поверхностного контура зоны обрушения",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20405039",
        text: "Кем утверждается паспорт буро-взрывных работ для каждого рабочего уступа - выемочного блока на основе маркшейдерской документации?",
        answers: [
          "Техническим руководителем карьера",
          "Ответственным руководителем работ",
          "Все ответы неверны",
          "Специализированной организацией",
          "Руководителем организации",
        ],
        correctAnswers: ["Техническим руководителем карьера"],
      },
      {
        code: "20405040",
        text: "Какое из перечисленных требований к рудоспускам указано неверно?",
        answers: [
          "Все ответы неверны",
          "При перепуске руд глинистых, обводненных, склонных к слеживанию и самовозгоранию допускается аккумулирование руды в рудоспуске",
          "При отработке верхней части рудоспусков они должны быть заполнены до подошвы уступа",
          "При выполнении работ по переносу устья рудоспуска из карьера и ведении ремонтных работ число капитальных действующих рудоспусков с карьера в подземные выработки должно быть не менее двух",
          "Каждый рудоспуск необходимо обустроить бетонными предохранительными барьерами высотой не менее 0,33 и шириной не менее 0,5 диаметра колеса автосамосвала",
        ],
        correctAnswers: [
          "При перепуске руд глинистых, обводненных, склонных к слеживанию и самовозгоранию допускается аккумулирование руды в рудоспуске",
        ],
      },
      {
        code: "20405041",
        text: "Какое допускается максимальное отклонение от нулевого кислородного баланса при производстве массовых взрывов при комбинированной (совмещенной) разработке рудных и нерудных месторождений полезных ископаемых?",
        answers: [
          "Не превышающим 25 %",
          "Не превышающим 30 %",
          "Не превышающим 35 %",
          "Не превышающим 10 %",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не превышающим 25 %"],
      },
      {
        code: "20405042",
        text: "В соответствии с каким документом осуществляется производство открытых горных работ в зонах ранее выполненных подземных работ и имеющих пустоты?",
        answers: [
          "В соответствии со специальным проектом, согласованным с генеральным проектировщиком",
          "В соответствии с положением о производственном контроле",
          "В соответствии с планом организации строительства",
          "В соответствии с геологической документацией",
        ],
        correctAnswers: [
          "В соответствии со специальным проектом, согласованным с генеральным проектировщиком",
        ],
      },
      {
        code: "20405043",
        text: "Каков порядок допуска людей в район взрыва при проведении открытых горных работ?",
        answers: [
          "Только после проверки состояния уступов",
          "Не ранее чем через 30 минут после взрыва",
          "После получения сообщения от аварийно-спасательной службы о снижении концентрации ядовитых продуктов взрыва в воздухе до предельно допустимых норм и выполнении всех перечисленных требований",
          "Только после рассеивания пылевого облака",
          "Только после полного восстановления видимости",
        ],
        correctAnswers: [
          "После получения сообщения от аварийно-спасательной службы о снижении концентрации ядовитых продуктов взрыва в воздухе до предельно допустимых норм и выполнении всех перечисленных требований",
        ],
      },
      {
        code: "20405044",
        text: "Каким образом доводятся до специалистов и горнорабочих дополнительные меры безопасности перед производством массового взрыва?",
        answers: [
          "Командиром профессиональной аварийно-спасательной службы (формирования) - под подпись в журнале инструктажа",
          "Приказом - под роспись",
          "Начальником отдела промышленной безопасности",
          "Руководителем карьера - под подпись в журнале взрывных работ",
        ],
        correctAnswers: ["Приказом - под роспись"],
      },
      {
        code: "20405045",
        text: "Когда могут быть допущены работники шахты на место проведенного массового взрыва?",
        answers: [
          "После восстановления нормальной рудничной атмосферы, приведения выработок в безопасное состояние и проверки аварийно-спасательным формированием, но не ранее чем через 8 часов после взрыва",
          "Только после восстановления нормальной рудничной атмосферы и проверки начальником участка состояния действующих выработок",
          "Только после приведения выработок в безопасное состояние и проверки аварийно-спасательным формированием, но не ранее чем через 2 часа после взрыва",
        ],
        correctAnswers: [
          "После восстановления нормальной рудничной атмосферы, приведения выработок в безопасное состояние и проверки аварийно-спасательным формированием, но не ранее чем через 8 часов после взрыва",
        ],
      },
      {
        code: "20405046",
        text: "Какое должно быть количество воздуха, проходящее по выработкам после реверсирования?",
        answers: [
          "Не менее 60 % его количества в нормальном режиме проветривания",
          "Не менее 20 % его количества в нормальном режиме проветривания",
          "Не менее 30 % его количества в нормальном режиме проветривания",
          "Не менее 50 % его количества в нормальном режиме проветривания",
        ],
        correctAnswers: [
          "Не менее 60 % его количества в нормальном режиме проветривания",
        ],
      },
      {
        code: "20405047",
        text: "Каково минимально допустимое расстояние от зоны действующих подземных коммуникаций до рабочей зоны землеройной техники?",
        answers: [
          "1 м по горизонтали или 2 м по вертикали",
          "1,5 м по горизонтали или 1 м по вертикали",
          "2 м по горизонтали или 0,5 м по вертикали",
          "2 м по горизонтали или 1 м по вертикали",
        ],
        correctAnswers: ["2 м по горизонтали или 1 м по вертикали"],
      },
      {
        code: "20405048",
        text: "Каково минимально допустимое расстояние движущихся транспортных средств от верхнего откоса котлована (траншеи) в случае увлажнения или выветривания откосов котлована и траншей, разрабатываемых без крепления?",
        answers: ["1,5 м", "2 м", "3 м", "3,5 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "20405049",
        text: "При какой минимальной глубине котлована спуск и подъем в котлован по лестницам не допускается, при условии, что расстояние между лестницами не превышает 40 м?",
        answers: ["25 м", "30 м", "40 м", "Не нормируется"],
        correctAnswers: ["25 м"],
      },
      {
        code: "20405050",
        text: "Каково минимально допустимое расстояние от бровки котлованов до места складирования материалов и оборудования?",
        answers: [
          "Высота складируемого оборудования или материалов плюс 1 м",
          "Высота складируемого оборудования или материалов плюс 0,5 м",
          "Высота складируемого оборудования или материалов плюс 1,5 м",
          "Высота складируемого оборудования или материалов плюс 2 м",
        ],
        correctAnswers: [
          "Высота складируемого оборудования или материалов плюс 1 м",
        ],
      },
      {
        code: "20405051",
        text: "Что относится к основным факторам, которые учитываются при оценке устойчивости бортов и уступов карьеров, разрезов в массивах дисперсных горных пород?",
        answers: [
          "Отсутствие дренажа или недостаточная его эффективность",
          "Относительная деформация набухания под нагрузкой (для глинистых пород)",
          "Набухание, снижение прочности и развитие локальных деформаций уступов и их групп",
          "Несоответствие углов наклона бортов, откосов уступов и отвалов или несоответствие их высот геологическим условиям",
          "Все перечисленное",
        ],
        correctAnswers: [
          "Набухание, снижение прочности и развитие локальных деформаций уступов и их групп",
        ],
      },
      {
        code: "20405052",
        text: "Что должно быть изучено в результате инженерно-геологических и гидрогеологических исследований?",
        answers: [
          "Физические свойства складируемых пород, прочность их в куске, сдвиговые характеристики отвальной массы, строение основания (наклон основания, наличие прослоев слабых пород и их мощность)",
          "Сдвиговые характеристики отвальной массы, строение основания (наклон основания, наличие прослоев слабых пород и их мощность)",
          "Наличие водоносных горизонтов, возможность образования техногенных водоносных горизонтов в нижней части отвала и в породах основания",
          "Все перечисленные",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20405053",
        text: "Что является коэффициентом запаса устойчивости при детерминированном подходе?",
        answers: [
          "Допустимая вероятность развития деформаций, которые определяются проектной документацией с учетом размещения элементов инфраструктуры объекта недропользования",
          "Отношение удерживающих и сдвигающих сил на сформировавшейся поверхности скольжения",
          "Перенос действия этих сил во всех инженерных методах на потенциальную (наиболее напряженную) поверхность скольжения",
        ],
        correctAnswers: [
          "Отношение удерживающих и сдвигающих сил на сформировавшейся поверхности скольжения",
        ],
      },
      {
        code: "20405054",
        text: "В какой документ должны включаться мероприятия при обнаружении признаков нарушения устойчивости или критических деформаций?",
        answers: [
          "В план развития горных работ на предстоящий календарный период",
          "В отчет о проведенных работах",
          "В пояснительную записку с фотографией деформации",
          "В журнал осмотра",
        ],
        correctAnswers: [
          "В план развития горных работ на предстоящий календарный период",
        ],
      },
      {
        code: "20405055",
        text: "Какие мероприятия должна обеспечить организация, эксплуатирующая объект ведения открытых горных работ?",
        answers: [
          "Контроль за состоянием противодеформационных сооружений и выполнением мероприятий, предотвращающих нарушение устойчивости бортов, уступов, откосов",
          "Проведение визуальных и инструментальных наблюдений за состоянием бортов, уступов и откосов",
          "Контроль за соблюдением проектных параметров бортов и уступов карьера, разреза и откосов отвала",
          "Все перечисленные",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20405056",
        text: "Какие визуальные и инструментальные наблюдения должны проводиться объектах ведения открытых горных работ?",
        answers: [
          "За закарстованностью месторождения",
          "За проявлениями удароопасности горных пород, вмещающих полезное ископаемое",
          "За поверхностной эрозией песчано-глинистых рыхлых пород, слагающих борта карьеров",
          "За состоянием бортов, уступов и откосов",
        ],
        correctAnswers: ["За состоянием бортов, уступов и откосов"],
      },
      {
        code: "20405057",
        text: "Кем и с какой периодичностью должно проводиться визуальное обследование состояния откосов на карьерах?",
        answers: [
          "Не реже 1 раза в месяц специалистами геологической и маркшейдерской службами и специалистами группы по мониторингу",
          "Ежеквартально, главным инженером карьера",
          "Не реже 2 раз в месяц специалистами геологической службы",
          "Не реже 1 раза в квартал специалистами геологической и маркшейдерской службами и специалистами группы по мониторингу",
        ],
        correctAnswers: [
          "Не реже 1 раза в месяц специалистами геологической и маркшейдерской службами и специалистами группы по мониторингу",
        ],
      },
      {
        code: "20405058",
        text: "На каких участках возможных деформаций выполняют закладку наблюдательных станций?",
        answers: [
          "Слабых пластичных, обводненных или сильно трещиноватых пород в горном массиве, формирующих борт, уступ или отвал",
          "Слабых контактов, поверхностей тектонических нарушений, имеющих наклон в сторону выработанного пространства и простирание близкое к простиранию борта",
          "Все ответы неверны",
          "На всех перечисленных",
        ],
        correctAnswers: ["На всех перечисленных"],
      },
      {
        code: "20405059",
        text: "За чем должны проводиться визуальные и инструментальные наблюдения в процессе эксплуатации объектов ведения открытых горных работ для своевременного выявления опасных зон и прогнозирования опасных ситуаций?",
        answers: [
          "За устойчивостью бортов, уступов, откосов",
          "За поверхностной эрозией песчано-глинистых рыхлых пород, слагающих борта карьеров",
          "За проявлениями удароопасности горных пород, вмещающих полезное ископаемое",
          "За закарстованностью месторождения",
        ],
        correctAnswers: ["За устойчивостью бортов, уступов, откосов"],
      },
      {
        code: "20405060",
        text: 'На какую глубину должно быть забетонировано устье траншеи до начала выполнения работ по ее разработке при выполнении работ методом "стена в грунте"?',
        answers: [
          "Не менее 0,8 м",
          "Не менее 1,0 м",
          "Не менее 1,2 м",
          "Не менее 1,5 м",
        ],
        correctAnswers: ["Не менее 1,5 м"],
      },
      {
        code: "20405061",
        text: "Какое требование безопасности при строительстве сооружений подземным способом изложено неверно?",
        answers: [
          "В слабых и неустойчивых грунтах отставание крепи от забоя не допускается",
          "Раскрытие тоннеля на полный профиль и монтаж обделки при укладке прорезных колец должны осуществляться в соответствии с проектом производства работ ППР (под руководством лица технического надзора)",
          "В неустойчивых породах, требующих поддержания массива непосредственно за разработкой породы, проходка выработок должна производиться с применением специальных способов закрепления грунтов или механизированных проходческих комплексов с закрытым забоем",
          "Разработка породы при проходке выработок должна производиться во всех случаях, начиная с нижней части забоя",
        ],
        correctAnswers: [
          "Разработка породы при проходке выработок должна производиться во всех случаях, начиная с нижней части забоя",
        ],
      },
      {
        code: "20405062",
        text: "Начиная с какого расстояния между встречными или сближающимися забоями при проведении выработок без применения взрывных работ горнопроходческие работы должны производиться с соблюдением дополнительных мер безопасности по единому согласованному графику, утвержденному главными инженерами организаций, ведущих эти работы?",
        answers: [
          "Начиная с расстояния между ними менее одного диаметра (высоты) максимальной выработки",
          "Начиная с расстояния между ними менее полутора диаметров (высоты) максимальной выработки",
          "Начиная с расстояния между ними менее двух диаметров (высот) максимальной выработки",
          "Начиная с расстояния между ними менее трех диаметров (высот) максимальной выработки",
        ],
        correctAnswers: [
          "Начиная с расстояния между ними менее полутора диаметров (высоты) максимальной выработки",
        ],
      },
      {
        code: "20405063",
        text: "Какое максимально допустимое значение провиса сетки, подвешенной к анкерам и очищенной от лежащей на ней породы?",
        answers: ["10 см", "20 см", "15 см", "17 см"],
        correctAnswers: ["20 см"],
      },
      {
        code: "20405064",
        text: "Каково минимально допустимое значение прочности бетона на захватках свода, смежных с ранее забетонированными участками, для раскрытия калотты?",
        answers: [
          "70 % проектной",
          "75 % проектной",
          "80 % проектной",
          "85 % проектной",
        ],
        correctAnswers: ["70 % проектной"],
      },
      {
        code: "20405065",
        text: "Каково минимально допустимое значение проектной прочности бетона в своде для начала разработки ядра (средней штроссы) при проходке способом опертого свода в устойчивых породах?",
        answers: [
          "Не менее 75 %",
          "Не менее 70 %",
          "Не менее 65 %",
          "Не менее 60 %",
        ],
        correctAnswers: ["Не менее 75 %"],
      },
      {
        code: "20405066",
        text: "Каково максимально допустимое расстояние откатки загруженных вагонеток в призабойной зоне с помощью погрузочных машин, но не более пределов технологического комплекса укладчика или щита?",
        answers: ["25 м", "27 м", "30 м", "34 м"],
        correctAnswers: ["25 м"],
      },
      {
        code: "20405067",
        text: "На каком расстоянии от поднимаемого груза должны быть расположены лебедки при монтаже и демонтаже обделки?",
        answers: [
          "Не ближе 3 м по горизонтали от поднимаемого груза",
          "Не ближе 5 м и не далее 18 м по горизонтали от поднимаемого груза",
          "Не далее 20 м по горизонтали от поднимаемого груза",
          "Не ближе 5 м и не далее 15 м по горизонтали от поднимаемого груза",
        ],
        correctAnswers: [
          "Не ближе 5 м и не далее 15 м по горизонтали от поднимаемого груза",
        ],
      },
      {
        code: "20405068",
        text: "При каких условиях разрешается производство работ при проходке стволов глубиной до 20 м с использованием в качестве средств подъема стреловых, козловых и башенных кранов?",
        answers: [
          "Без перекрытия устья ствола и бермой безопасности вокруг устья ствола не менее 1 м",
          "Без перекрытия устья ствола при устройстве сплошного прочного ограждения высотой не менее 1,5 м по периметру ствола с дверью для прохода людей",
          "При наличии бермы безопасности не менее 1 м, а обделка ствола должна возвышаться над уровнем спланированной поверхности не менее чем на 0,5 м",
          "Без перекрытия устья ствола и бермой безопасности вокруг устья ствола не менее 3 м",
        ],
        correctAnswers: [
          "Без перекрытия устья ствола при устройстве сплошного прочного ограждения высотой не менее 1,5 м по периметру ствола с дверью для прохода людей",
        ],
      },
      {
        code: "20405069",
        text: "Каким требованиям должны соответствовать лестничные отделения шахтных стволов?",
        answers: [
          "Лестницы должны быть установлены под углом не более 80° и выступать на 1 м над устьем выработки",
          "Лестницы через каждые 3 м должны быть скреплены металлическими стяжками",
          "Расстояние между лестничными площадками должно быть не более 5 м",
          "Расстояние от основания лестницы до крепи или обшивки лестничного отделения должно быть не менее 1 м",
        ],
        correctAnswers: [
          "Лестницы должны быть установлены под углом не более 80° и выступать на 1 м над устьем выработки",
        ],
      },
      {
        code: "20405070",
        text: "При какой глубине проходки ствола разрешается установка подвесных лестниц?",
        answers: [
          "Не более 15 м, выход лестничного отделения на поверхность должен оборудоваться предохранительной будкой",
          "Не более 20 м с закреплением лестниц в крепи ствола, вход лестничного отделения должен оборудоваться предохранительной будкой",
          "Не более 25 м с закреплением лестниц в крепи ствола и установкой дуг безопасности",
          "Не более 30 м с закреплением лестниц в крепи ствола и установкой дуг безопасности",
        ],
        correctAnswers: [
          "Не более 15 м, выход лестничного отделения на поверхность должен оборудоваться предохранительной будкой",
        ],
      },
      {
        code: "20405071",
        text: "На каком расстоянии над поверхностью земли должны возвышаться устья наклонных выработок?",
        answers: ["На 1 м", "На 0,5 м", "На 0,7 м", "На 1,5 м"],
        correctAnswers: ["На 0,5 м"],
      },
      {
        code: "20405072",
        text: "Какие сооружения должны быть установлены для защиты работающих в забое от опасности обрыва скипа или падения предметов при проходке наклонных выработок?",
        answers: [
          "Заграждение в устье выработки",
          "Заграждения не дальше 25 м от места работы",
          "Устанавливаются 2 заграждения: одно - в устье выработки, другое - не дальше 20 м от места работы",
          "Стопорные и улавливающие устройства на скипе",
        ],
        correctAnswers: [
          "Устанавливаются 2 заграждения: одно - в устье выработки, другое - не дальше 20 м от места работы",
        ],
      },
      {
        code: "20405073",
        text: "Сколько запасных выходов должно быть на действующей шахте?",
        answers: [
          "На каждой действующей шахте должно быть не менее двух отдельных выходов, обеспечивающих выезд (выход) людей на поверхность и имеющих разное направление вентиляционных струй. Каждый горизонт, этаж (подэтаж), блок должны иметь не менее двух отдельных выходов на смежные горизонты или к стволам",
          "На шахте должно быть не менее двух отдельных выходов, обеспечивающих выезд (выход) людей на поверхность и имеющих одно направление вентиляционных струй",
          "На каждой действующей шахте должен быть отдельный выход, обеспечивающий выезд (выход) людей на поверхность",
        ],
        correctAnswers: [
          "На каждой действующей шахте должно быть не менее двух отдельных выходов, обеспечивающих выезд (выход) людей на поверхность и имеющих разное направление вентиляционных струй. Каждый горизонт, этаж (подэтаж), блок должны иметь не менее двух отдельных выходов на смежные горизонты или к стволам",
        ],
      },
      {
        code: "20405074",
        text: "При какой глубине вертикальных стволов механический подъем в одном из них может отсутствовать, при условии наличия лестниц в обоих стволах?",
        answers: [
          "При глубине до 70 м",
          "При глубине до 80 м",
          "При глубине до 90 м",
          "При глубине до 100 м",
        ],
        correctAnswers: ["При глубине до 70 м"],
      },
      {
        code: "20405075",
        text: "Какова минимально допустимая ширина лестниц в вертикальных выработках?",
        answers: ["0,6 м", "0,4 м", "0,7 м", "1 м"],
        correctAnswers: ["0,4 м"],
      },
      {
        code: "20405076",
        text: "Какова минимально допустимая ширина свободного прохода людей в выработках с конвейерным транспортом?",
        answers: ["0,4 м", "0,5 м", "0,6 м", "0,7 м"],
        correctAnswers: ["0,7 м"],
      },
      {
        code: "20405077",
        text: "Какова минимально допустимая ширина свободного прохода для людей в горизонтальных выработках, где применяются рельсовые транспортные средства?",
        answers: ["0,7 м", "0,6 м", "0,5 м", "0,45 м"],
        correctAnswers: ["0,7 м"],
      },
      {
        code: "20405078",
        text: "Чем из перечисленного оборудуются наклонные горные выработки, предназначенные для передвижения людей, при углах наклона этих выработок от 31 до 45°?",
        answers: [
          "Лестницами с горизонтальными ступенями и перилами",
          "Сходнями со ступенями и перилами",
          "Трапами с перилами",
          "Трапами",
        ],
        correctAnswers: ["Лестницами с горизонтальными ступенями и перилами"],
      },
      {
        code: "20405079",
        text: "В соответствии с какими документами осуществляется проведение и крепление горных выработок?",
        answers: [
          "Только с проектной документацией",
          "Только с ТР",
          "Только с документацией по ведению горных работ",
          "Со всеми перечисленными",
        ],
        correctAnswers: ["Со всеми перечисленными"],
      },
      {
        code: "20405080",
        text: "Каково минимально допустимое расстояние на основных проходах между машинами и аппаратами и от стен до габаритов оборудования в зданиях и сооружениях поверхностного комплекса объектов ведения горных работ и переработки полезных ископаемых?",
        answers: ["1,5 м", "2 м", "0,8 м", "1 м"],
        correctAnswers: ["1,5 м"],
      },
      {
        code: "20405081",
        text: "Каково минимально допустимое расстояние на рабочих проходах между машинами и аппаратами и от стен до габаритов оборудования в зданиях и сооружениях поверхностного комплекса объектов ведения горных работ и переработки полезных ископаемых?",
        answers: ["1,0 м", "0,9 м", "0,8 м", "1,3 м"],
        correctAnswers: ["1,0 м"],
      },
      {
        code: "20405082",
        text: "Каково минимально допустимое расстояние на рабочих проходах между стеной и машинами в зданиях и сооружениях поверхностного комплекса объектов ведения горных работ и переработки полезных ископаемых?",
        answers: ["0,8 м", "0,9 м", "0,7 м", "0,6 м"],
        correctAnswers: ["0,7 м"],
      },
      {
        code: "20405083",
        text: "Каково минимально допустимое расстояние на проходах для обслуживания и ремонта в зданиях и сооружениях поверхностного комплекса объектов ведения горных работ и переработки полезных ископаемых?",
        answers: ["0,5 м", "0,6 м", "1,0 м", "0,8 м"],
        correctAnswers: ["0,6 м"],
      },
      {
        code: "20405084",
        text: "Какова минимально допустимая ширина стационарных площадок для обслуживания запорной арматуры, расположенной на высоте более 1,5 м от уровня пола, не имеющей дистанционного управления?",
        answers: ["0,5 м", "0,6 м", "0,7 м", "0,8 м"],
        correctAnswers: ["0,8 м"],
      },
      {
        code: "20405085",
        text: "Каков максимально допустимый угол наклона постоянно эксплуатируемых лестниц к рабочим площадкам и механизмам поверхностного комплекса объектов горных работ и переработки полезных ископаемых?",
        answers: ["80°", "60°", "55°", "45°"],
        correctAnswers: ["45°"],
      },
      {
        code: "20405086",
        text: "Каков максимально допустимый угол наклона лестниц к рабочим площадкам и механизмам, посещаемым 1 - 2 раза в смену?",
        answers: ["80°", "70°", "50°", "60°"],
        correctAnswers: ["60°"],
      },
      {
        code: "20405087",
        text: "Каков максимально допустимый угол наклона лестниц в зумпфах и колодцах?",
        answers: ["80°", "90°", "75°"],
        correctAnswers: ["90°"],
      },
      {
        code: "20405088",
        text: "Какова минимально допустимая ширина лестниц к рабочим площадкам и механизмам поверхностного комплекса объектов горных работ и переработки полезных ископаемых?",
        answers: ["0,5 м", "0,6 м", "0,7 м", "0,8 м"],
        correctAnswers: ["0,7 м"],
      },
      {
        code: "20405089",
        text: "Какой должна быть минимальная ширина переходных мостиков?",
        answers: ["1,2 м", "1,1 м", "1,5 м", "1,0 м", "0,9 м"],
        correctAnswers: ["1,0 м"],
      },
      {
        code: "20405090",
        text: "Какова минимально допустимая высота от уровня пола при размещении труб, желобов и других коммуникаций в случае пересечения ими проходов и рабочих площадок?",
        answers: ["2,2 м", "2,1 м", "2,0 м", "1,8 м", "1,7 м"],
        correctAnswers: ["2,0 м"],
      },
      {
        code: "20405091",
        text: "Каково допустимое количество хранения на рабочих местах горюче-смазочных и обтирочных материалов?",
        answers: [
          "Не более трехсуточной потребности каждого материала",
          "Не более суточной потребности каждого материала",
          "Не более месячной потребности каждого материала",
          "Не нормируется",
        ],
        correctAnswers: ["Не более трехсуточной потребности каждого материала"],
      },
      {
        code: "20405092",
        text: "Как должны храниться горюче-смазочные и обтирочные материалы на рабочих местах?",
        answers: [
          "Должны храниться в деревянных коробах",
          "Должны храниться в закрытых металлических емкостях",
          "Должны храниться в пластиковой упаковке",
          "Должны храниться в стеклянных емкостях на стеллажах",
        ],
        correctAnswers: ["Должны храниться в закрытых металлических емкостях"],
      },
      {
        code: "20405093",
        text: "Какие требования безопасности при приемке руды и шихтовых материалов указаны неверно?",
        answers: [
          "Перед корпусом (отделением) приема руды устанавливается светофор, разрешающий или запрещающий подачу составов (самосвалов, скипов) на приемную площадку",
          "В отдельных случаях разгрузка осуществляется по разрешающим сигналам светофоров, сблокированных со шлагбаумом и установленных перед бункером",
          "Рабочие площадки приемных и разгрузочных устройств и бункеров оборудуются звуковой и световой сигнализацией для оповещения обслуживающего персонала о прибытии железнодорожных составов",
          "Сигналы подаются за 0,5 минуты до момента прибытия железнодорожных составов, начала работы скиповых подъемников и канатных дорог",
        ],
        correctAnswers: [
          "Сигналы подаются за 0,5 минуты до момента прибытия железнодорожных составов, начала работы скиповых подъемников и канатных дорог",
        ],
      },
      {
        code: "20405094",
        text: "Какова минимально допустимая высота ограждения загрузочного отверстия приемного бункера для ограничения движения задним ходом автомобилей?",
        answers: [
          "0,5 диаметра колеса автомобиля",
          "0,3 диаметра колеса автомобиля",
          "3/4 диаметра колеса автомобиля",
          "Не нормируется",
        ],
        correctAnswers: ["0,5 диаметра колеса автомобиля"],
      },
      {
        code: "20405095",
        text: "Какова максимально допустимая ширина отверстий в решетках, перекрывающих загрузочные отверстия на бункерах и в местах перегрузки конвейерного транспорта, при применении саморазгружающихся тележек или реверсивных конвейеров?",
        answers: [
          "0,1 х 0,1 м",
          "0,3 х 0,3 м",
          "0,2 х 0,2 м",
          "Не нормируется",
        ],
        correctAnswers: ["0,2 х 0,2 м"],
      },
      {
        code: "20405096",
        text: "Кто утверждает технологический регламент, определяющий методы, последовательность операций и приемы безопасного выполнения работ по ликвидации зависания и запуску в работу дробилки?",
        answers: [
          "Руководитель предприятия",
          "Технический руководитель предприятия",
          "Представитель Ростехнадзора",
          "Лицо технического надзора предприятия",
        ],
        correctAnswers: ["Технический руководитель предприятия"],
      },
      {
        code: "20405097",
        text: "Каков минимально допустимый запас прочности тяговых канатов для перемещения забойного оборудования по отношению к номинальному усилию на их рабочих барабанах?",
        answers: ["3-кратный", "2-кратный", "4-кратный", "6-кратный"],
        correctAnswers: ["3-кратный"],
      },
      {
        code: "20405098",
        text: "Какие виды канатов перед их навеской могут не испытываться на канатно-испытательных станциях?",
        answers: [
          "Канаты на грузовых наклонных подъемах с углом наклона менее 30°",
          "Подъемные канаты вертикальных и наклонных шахтных подъемов",
          "Тяговые канаты для монорельсовых дорог",
          "Тяговые и несущие канаты для подземных канатных дорог",
        ],
        correctAnswers: [
          "Канаты на грузовых наклонных подъемах с углом наклона менее 30°",
        ],
      },
      {
        code: "20405099",
        text: "Какова периодичность проведения осмотра крепи и армировки вертикальных горных выработок?",
        answers: [
          "Не реже одного раза в неделю",
          "Не реже одного раза в день",
          "Не реже одного раза в месяц",
          "Не реже одного раза в год",
        ],
        correctAnswers: ["Не реже одного раза в неделю"],
      },
      {
        code: "20405100",
        text: "При какой скорости движения осматриваются поврежденные участки канатов, а также стыковые соединения резинотросовых канатов?",
        answers: [
          "При неподвижном канате",
          "При скорости движения канатов 0,3 м/с",
          "При скорости движения канатов 1 м/с",
          "При скорости движения канатов, обеспечивающей 100%-й визуальный контроль за целостностью каната",
        ],
        correctAnswers: ["При неподвижном канате"],
      },
      {
        code: "20405101",
        text: "Какова периодичность проверки состояния горных выработок, служащих запасными выходами?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в три месяца",
          "Не реже одного раза в шесть месяцев",
          "Не реже одного раза в год",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20405102",
        text: "Где и в течение какого срока хранятся свидетельства об испытаниях каната?",
        answers: [
          "В обособленном структурном подразделении в течение всего срока службы каната",
          "На видимой стороне подъемной установки в течение всего ее срока службы",
          "На рабочем месте обслуживающего персонала подъемной установки в течение всего ее срока службы",
          "В Ростехнадзоре в течение 20 лет",
        ],
        correctAnswers: [
          "В обособленном структурном подразделении в течение всего срока службы каната",
        ],
      },
      {
        code: "20405103",
        text: "Кем из указанных лиц проверяются канаты для перемещения забойного оборудования ежесменно перед началом работы?",
        answers: [
          "Машинистом подъемной машины или его помощником",
          "Работником обособленного структурного подразделения",
          "Главным механиком",
          "Работником подразделения профессионального аварийно-спасательного формирования",
        ],
        correctAnswers: ["Машинистом подъемной машины или его помощником"],
      },
      {
        code: "20405104",
        text: "В каком случае проводниковые канаты допускаются к эксплуатации?",
        answers: [
          "При наличии спайки обрыва наружней проволоки в канате закрытой конструкции, вышедшей из замка",
          "При износе наружных проволок более 50 % их высоты",
          "Если на 100 м длины каната закрытой конструкции обнаружено два обрыва наружных проволок",
          "Допускаются во всех перечисленных случаях",
        ],
        correctAnswers: [
          "При наличии спайки обрыва наружней проволоки в канате закрытой конструкции, вышедшей из замка",
        ],
      },
      {
        code: "20405105",
        text: "Допускается ли применение грузоподъемных кранов с лебедками для проходки шахтных стволов с целью спуска и подъема грузов?",
        answers: [
          "Допускается при глубине до 40 м",
          "Не допускается",
          "Допускается в любом случае",
        ],
        correctAnswers: ["Допускается при глубине до 40 м"],
      },
      {
        code: "20405106",
        text: "Какая минимальная площадь днища должна приходиться на одного человека при спуске и подъеме людей в бадьях?",
        answers: [
          "0,40 м2 площади днища",
          "0,35 м2 площади днища",
          "0,25 м2 площади днища",
          "0,20 м2 площади днища",
        ],
        correctAnswers: ["0,25 м2 площади днища"],
      },
      {
        code: "20405107",
        text: "Какими дверями должны оборудоваться клети, служащие для спуска и подъема людей?",
        answers: [
          "Двери должны открываться вовнутрь и запираться засовом, расположенным снаружи, верхняя кромка двери должна быть выше уровня пола не менее чем на 1200 мм, а нижняя - не более 150 мм",
          "Двери должны открываться наружу и запираться засовом, расположенным внутри, верхняя кромка двери должна быть выше уровня пола не менее чем на 1200 мм, а нижняя - не более 150 мм",
          "Двери должны открываться вовнутрь и запираться засовом, расположенным снаружи, верхняя кромка двери должна быть выше уровня пола не менее чем на 1500 мм, а нижняя - не более 200 мм",
          "Двери должны открываться наружу и запираться засовом, расположенным внутри, верхняя кромка двери должна быть выше уровня пола не менее чем на 1500 мм, а нижняя - не более 200 мм",
        ],
        correctAnswers: [
          "Двери должны открываться вовнутрь и запираться засовом, расположенным снаружи, верхняя кромка двери должна быть выше уровня пола не менее чем на 1200 мм, а нижняя - не более 150 мм",
        ],
      },
      {
        code: "20405108",
        text: "Какой должна быть площадь пола в клети на одного человека?",
        answers: [
          "Не менее 0,40 м2",
          "Не менее 0,35 м2",
          "Не менее 0,25 м2",
          "Не менее 0,20 м2",
        ],
        correctAnswers: ["Не менее 0,20 м2"],
      },
      {
        code: "20405109",
        text: "Барьером на какую высоту должен быть огражден лесоспуск на поверхности?",
        answers: ["1 м", "1,2 м", "1,5 м", "2 м"],
        correctAnswers: ["1 м"],
      },
      {
        code: "20405110",
        text: "Какой должна быть продолжительность холостого хода предохранительного тормоза подъемной машины?",
        answers: [
          "Не более 0,5 с",
          "Не более 0,8 с",
          "Не более 1 с",
          "Не более 1,5 с",
        ],
        correctAnswers: ["Не более 0,5 с"],
      },
      {
        code: "20405111",
        text: "Какой запас прочности должны иметь канаты для спасательных лестниц, канаты для подвески полков, насосов, трубопроводов, проходческих агрегатов?",
        answers: [
          "9-кратный запас прочности",
          "8-кратный запас прочности",
          "7-кратный запас прочности",
          "6-кратный запас прочности",
        ],
        correctAnswers: ["6-кратный запас прочности"],
      },
      {
        code: "20405112",
        text: "Какой предельный срок эксплуатации устанавливается для прицепных устройств бадей?",
        answers: [
          "Не более 2 лет",
          "Не более 3 лет",
          "Не более 4 лет",
          "Не более 5 лет",
        ],
        correctAnswers: ["Не более 2 лет"],
      },
      {
        code: "20405113",
        text: "На каком расстоянии от места производства работ на откаточных путях должны быть установлены сигнальные знаки?",
        answers: [
          "Не менее 30 м от места производства работ",
          "Не менее 40 м от места производства работ",
          "Не менее 60 м от места производства работ",
          "Не менее 80 м от места производства работ",
        ],
        correctAnswers: ["Не менее 80 м от места производства работ"],
      },
      {
        code: "20405114",
        text: "Для выработок какой протяженностью допускается укладка одного откаточного пути с устройством разъездов?",
        answers: ["Более 200 м", "Более 300 м", "Более 400 м", "Более 500 м"],
        correctAnswers: ["Более 500 м"],
      },
      {
        code: "20405115",
        text: "В каком из перечисленных случаев запрещается эксплуатировать рельсовые пути?",
        answers: [
          "Только при расширении пути более 4 мм и сужении более 2 мм против нормально установленной ширины рельсовой колеи",
          "Только при износе головки рельса по вертикали более 8 мм для рельсов типа Р-24 и 10 мм - для рельсов типа Р-33",
          "Только при зазорах между торцами рельсов, превышающих 2 мм",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: [
          "Только при расширении пути более 4 мм и сужении более 2 мм против нормально установленной ширины рельсовой колеи",
        ],
      },
      {
        code: "20405116",
        text: "В каком из перечисленных случаев запрещается ручная откатка рельсовых транспортных средств?",
        answers: [
          "Если их вес превышает 1 т",
          "Если требуется приложить усилие более 15 кг",
          "На уклонах более 0,01",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["На уклонах более 0,01"],
      },
      {
        code: "20405117",
        text: "В каком из перечисленных случаев допускается нахождение локомотива в хвосте состава при электровозной откатке?",
        answers: [
          'Только при сооружении тоннелей механизированными тоннелепроходческими комплексами при условии разработки в разделе "Промышленная (техническая) безопасность" ТЭО (проекта) мероприятий, обеспечивающих безопасность работ',
          "Только в тоннелях диаметром в свету 3,0 м и менее по всей длине выработки",
          "В любом из перечисленных случаев, а также при уклонах до 0,005 на расстояние не более 200 м",
        ],
        correctAnswers: [
          "В любом из перечисленных случаев, а также при уклонах до 0,005 на расстояние не более 200 м",
        ],
      },
      {
        code: "20405118",
        text: "Каким должно быть расстояние между разъединителями контактной сети?",
        answers: [
          "Не более 500 м",
          "Не более 350 м",
          "Не более 300 м",
          "Не более 250 м",
        ],
        correctAnswers: ["Не более 250 м"],
      },
      {
        code: "20405119",
        text: "На каком расстоянии в выработках, где подвешен контактный провод, должны быть вывешены светящиеся надписи, предупреждающие, что провод под напряжением?",
        answers: [
          "Через каждые 500 м",
          "Через каждые 350 м, а также на пересечениях с другими выработками",
          "Через каждые 250 м",
          "Через каждые 100 м, а также на пересечениях с другими выработками",
        ],
        correctAnswers: [
          "Через каждые 100 м, а также на пересечениях с другими выработками",
        ],
      },
      {
        code: "20405120",
        text: "Какую длину должна иметь жесткая сцепка, применяемая для буксировки неисправных машин в подземных выработках?",
        answers: [
          "Не более 1 м",
          "Не более 1,5 м",
          "Не более 2 м",
          "Не более 2,5 м",
        ],
        correctAnswers: ["Не более 1 м"],
      },
      {
        code: "20405121",
        text: "Какие электрические сети разрешается применять для контактной электровозной откатки?",
        answers: [
          "Постоянного тока напряжением до 300 В включительно",
          "Трехфазные переменного тока с изолированной нейтралью линейным напряжением до 1000 В включительно",
          "Переменного тока напряжением до 1000 В включительно",
          "Переменного тока напряжением до 42 В включительно",
        ],
        correctAnswers: ["Постоянного тока напряжением до 300 В включительно"],
      },
      {
        code: "20405122",
        text: "Кабели какого вида и с какой изоляцией должны применяться для прокладки по вертикальным и наклонным выработкам?",
        answers: [
          "Бронированные кабели в свинцовой, алюминиевой или поливинилхлоридной оболочке",
          "Силовые и контрольные кабели с проволочной броней в свинцовой, алюминиевой или поливинилхлоридной оболочке с обедненной или нестекающей пропиткой изоляции",
          "Кабели с резиновой оболочкой",
          "Бронированные кабели в поливинилхлоридной оболочке",
        ],
        correctAnswers: [
          "Силовые и контрольные кабели с проволочной броней в свинцовой, алюминиевой или поливинилхлоридной оболочке с обедненной или нестекающей пропиткой изоляции",
        ],
      },
      {
        code: "20405123",
        text: "Какую защиту должны иметь трансформаторы подземных электрических сетей до 1000 В?",
        answers: [
          "От токов короткого замыкания, от перегрузки, нулевую",
          "От блуждающих токов",
          "От токов короткого замыкания",
          "От опасных токов утечки на землю",
        ],
        correctAnswers: ["От токов короткого замыкания"],
      },
      {
        code: "20405124",
        text: "Что из перечисленного определяется проектом и технологическим регламентом?",
        answers: [
          "Только порядок эксплуатации и обслуживания машин с двигателями внутреннего сгорания",
          "Только устройство гаражей, складов горюче-смазочных материалов",
          "Только устройство мастерских, пунктов мойки деталей горючей жидкостью, пунктов заправки машин и их временного отстоя",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20405125",
        text: "Какова максимально допустимая температура поверхности наиболее нагреваемой части двигателя внутреннего сгорания?",
        answers: ["70 °С", "85 °С", "115 °С", "150 °С"],
        correctAnswers: ["150 °С"],
      },
      {
        code: "20405126",
        text: "Какой максимальный уклон должны иметь горизонтальные горные выработки, по которым проводится перевозка людей и грузов локомотивами при благоприятных горногеологических условиях?",
        answers: ["5 %", "50 %", "15 %", "10 %", "Все ответы неверны"],
        correctAnswers: ["5 %"],
      },
      {
        code: "20405127",
        text: "Какое минимальное значение величины среднего замедления подъемной установки при спуске при предохранительном торможении в случае угла наклона горной выработки до 30°?",
        answers: ["0,75 м/с²", "1,5 м/с²", "1,75 м/с²", "0,70 м/с²"],
        correctAnswers: ["0,75 м/с²"],
      },
      {
        code: "20405128",
        text: "В каких из перечисленных случаев допускается эксплуатация рельсового пути?",
        answers: [
          "При износе головки рельсов типа Р-18 по вертикали 4 мм",
          "При касании ребордой колеса вагона головок болтов крепления рельса",
          "При повреждениях и дефектах рельсов, которые могут вызвать сход подвижного состава с рельсовых путей",
          "При отклонении рельсов от оси пути на стыках (излом) 80 мм на длине рельсового пути 5 м",
        ],
        correctAnswers: [
          "При износе головки рельсов типа Р-18 по вертикали 4 мм",
        ],
      },
      {
        code: "20405129",
        text: "В каких из перечисленных случаев допускается эксплуатация стрелочных переводов?",
        answers: [
          "При замыкании стрелок с зазором между прижатым острием пера и рамным рельсом 3 мм",
          "При разъединенных стрелочных тягах",
          "При отсутствии фиксации положения стрелочных переводов",
          "При открытых канавах стрелочных переводов",
          "При неплотно прилегающих к рамному рельсу и башмакам стрелочных перьях",
        ],
        correctAnswers: [
          "При замыкании стрелок с зазором между прижатым острием пера и рамным рельсом 3 мм",
        ],
      },
      {
        code: "20405130",
        text: "Какова минимально допустимая периодичность осмотра и проверки аварийно-ремонтных и вспомогательных подъемных установок работником обособленного структурного подразделения?",
        answers: [
          "Один раз в неделю",
          "Один раз в десять дней",
          "Один раз в месяц",
          "Один раз в 3 месяца",
        ],
        correctAnswers: ["Один раз в неделю"],
      },
      {
        code: "20405131",
        text: "Чем укомплектовывается в резерве главная подъемная установка?",
        answers: [
          "Только испытанным канатом, прошедшим инструментальный контроль, а также подъемным сосудом с прицепным устройством",
          "Только электродвигателем или полным комплектом запасных частей к нему, а также компрессором с электродвигателем при отсутствии подвода от общешахтной пневмосистемы",
          "Только комплектом тормозных колодок, а также необходимым количеством запасных вкладышей или подшипников качения",
          "Только необходимым количеством быстроходных валов и быстроизнашивающихся элементов аппаратов контроля, управления и защиты, определяемое эксплуатационной документацией изготовителя",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20405132",
        text: "Что не входит в комплектацию вспомогательных подъемных установок?",
        answers: [
          "Подъемные сосуды вместимостью не менее двух человек",
          "Канаты длиной, соответствующей глубине ствол, и запасом прочности не менее 9-кратного по отношению к максимальной расчетной статической нагрузке",
          "Подъемные сосуды вместимостью не менее шести человек",
          "Сигнализация, позволяющая подавать сигналы из подъемного сосуда и с горизонтов, с которых предполагается выезд людей",
        ],
        correctAnswers: ["Подъемные сосуды вместимостью не менее двух человек"],
      },
      {
        code: "20405133",
        text: "Что из перечисленного допускается при перевозке людей и грузов по горизонтальным горным выработкам?",
        answers: [
          "Прицепка в конце пассажирского состава двух грузовых вагонеток для перевозки инструмента",
          "Прицепка грузовых вагонеток к пассажирским составам",
          "Перевозка людей на локомотивах",
          "Перевозка людей в необорудованных вагонетках",
        ],
        correctAnswers: [
          "Прицепка в конце пассажирского состава двух грузовых вагонеток для перевозки инструмента",
        ],
      },
      {
        code: "20405134",
        text: "От каких повреждений и неисправностей в схеме управления автоматизированной подъемной установкой предусматриваются основные виды защит, вызывающих предохранительное торможение машин?",
        answers: [
          "Только от чрезмерного износа тормозных колодок, срабатывающая при увеличении зазора между ободом барабана и тормозной колодкой более 2 мм",
          "Только от обратного хода машины, срабатывающая прежде, чем скорость движения сосуда при обратном ходе достигнет 1 м/с",
          "Только от увеличения вдвое против расчетного значения периода пуска, замедления или дотягивания",
          "Только от недопустимого нагрева подшипников подъемной машины и электродвигателя, в том числе от статора",
          "Только от повреждения электрической цепи или механического привода электрического ограничителя скорости",
          "От всех перечисленных",
        ],
        correctAnswers: ["От всех перечисленных"],
      },
      {
        code: "20405135",
        text: "От каких действий должна предохранять блокировка, предусматриваемая в схеме управления автоматизированной подъемной установкой?",
        answers: [
          "Только включение машины в сторону дальнейшего переподъема, напуска или натяжения каната после аварийной остановки",
          "Только включение машины при отсутствии смазки",
          "Только переход с ручного управления на автоматическое и обратно без затормаживания машины предохранительным тормозом",
          "От всех перечисленных",
        ],
        correctAnswers: ["От всех перечисленных"],
      },
      {
        code: "20405136",
        text: "Какова максимально допустимая длина переподъема для одноканатных подъемных установок со скоростью подъема свыше 3 м/с вертикальных и наклонных выработок (с углом наклона свыше 30°)?",
        answers: [
          "Не менее 6 м",
          "Не менее 4 м",
          "Не менее 3 м",
          "Не менее 2 м",
        ],
        correctAnswers: ["Не менее 6 м"],
      },
      {
        code: "20405137",
        text: "Каково максимально допустимое превышение расчетного значения ускорения подъемных вертикальных и наклонных (более 30°) установок, предназначенных для подъема и спуска людей?",
        answers: ["1 м/с", "0,7 м/с", "0,5 м/с", "0,75 м/с"],
        correctAnswers: ["1 м/с"],
      },
      {
        code: "20405138",
        text: "С какой периодичностью проводится ревизия и наладка электрической части и аппаратуры автоматизированной подъемной установки после ввода ее в эксплуатацию?",
        answers: [
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в год",
          "Не реже одного раза в 3 года",
          "Не реже одного раза в 2 года",
        ],
        correctAnswers: ["Не реже одного раза в 6 месяцев"],
      },
      {
        code: "20405139",
        text: "Каков минимально допустимый зазор между стенками раструба проходческого полка и выступающими частями движущейся направляющей рамки бадьи?",
        answers: ["100 мм", "200 мм", "300 мм", "400 мм"],
        correctAnswers: ["100 мм"],
      },
      {
        code: "20405140",
        text: "Какова минимально допустимая периодичность проверки износа рельсов и нивелирования профиля откаточных путей?",
        answers: [
          "Один раз в месяц",
          "Один раз в 3 месяца",
          "Один раз в 6 месяцев",
          "Один раз в год",
        ],
        correctAnswers: ["Один раз в год"],
      },
      {
        code: "20405141",
        text: "Каким должен быть минимальный уровень содержания кислорода в подземных выработках?",
        answers: [
          "10 % по объему",
          "15 % по объему",
          "20 % по объему",
          "17 % по объему",
        ],
        correctAnswers: ["20 % по объему"],
      },
      {
        code: "20405142",
        text: "Каким должно быть количество воздуха, рассчитываемое по числу людей?",
        answers: [
          "Не менее 3 м3/мин",
          "Не менее 4 м3/мин",
          "Не менее 5 м3/мин",
          "Не менее 6 м3/мин",
        ],
        correctAnswers: ["Не менее 6 м3/мин"],
      },
      {
        code: "20405143",
        text: "Какие требования предъявляются к расположению на поверхности воздухозаборов вентиляторных установок?",
        answers: [
          "Должны устанавливаться не ближе 25 м от мест хранения и использования горючих и легковоспламеняющихся материалов",
          "Должны устанавливаться не ближе 10 м от ствола шахты",
          "Должны устанавливаться в зонах, не загрязненных пылью, дымом и не ближе 8 м от ствола",
          "Должны устанавливаться вне зоны загазованности на расстоянии более 15 м от проезжей части автомобильных дорог",
        ],
        correctAnswers: [
          "Должны устанавливаться не ближе 25 м от мест хранения и использования горючих и легковоспламеняющихся материалов",
        ],
      },
      {
        code: "20405144",
        text: "Каким образом должны быть расположены вентиляторные установки на поверхности при проходке ствола?",
        answers: [
          "На расстоянии не менее 6 м от ствола, отставание вентиляционных труб от забоя ствола должно определяться проектом и быть не менее 20 м",
          "На расстоянии не менее 10 м от ствола, отставание вентиляционных труб от забоя ствола должно определяться проектом и быть не более 18 м",
          "На расстоянии не менее 8 м от ствола, отставание вентиляционных труб от забоя ствола должно определяться проектом и быть не менее 15 м",
          "На расстоянии не менее 10 м от ствола, отставание вентиляционных труб от забоя ствола должно определяться проектом и быть не более 15 м",
        ],
        correctAnswers: [
          "На расстоянии не менее 10 м от ствола, отставание вентиляционных труб от забоя ствола должно определяться проектом и быть не более 15 м",
        ],
      },
      {
        code: "20405145",
        text: "Каким должно быть отставание вентиляционных труб от забоя при проходке ствола?",
        answers: [
          "Не более 15 м",
          "Не более 20 м",
          "Не более 25 м",
          "Не более 30 м",
        ],
        correctAnswers: ["Не более 15 м"],
      },
      {
        code: "20405146",
        text: "Каким должно быть отставание вентиляционных труб от забоя при проходке горизонтальных выработок при площади сечения забоя не более 16 м²?",
        answers: [
          "Не более 10 м",
          "Не более 15 м",
          "При площади сечения забоя не более 20 м2 не более 10 м, при площади сечения забоя более 20 м2 - не более 15 м",
        ],
        correctAnswers: ["Не более 10 м"],
      },
      {
        code: "20405147",
        text: "Что из перечисленного должно быть нанесено условными обозначениями на вентиляционные планы?",
        answers: [
          "Только движение вентиляционной струи воздуха: свежей - красными стрелками и отработанной - синими стрелками",
          "Только вентиляционные устройства: замерные станции с указанием их сечения, количества поступающего воздуха и его скорости, перемычки, кроссинги, вентиляционные двери",
          "Только коммуникации и средства пожаротушения, необходимые для ликвидации аварий",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20405148",
        text: "Какова периодичность составления вентиляционных планов?",
        answers: [
          "Один раз в полгода",
          "Один раз в месяц",
          "Один раз в квартал",
          "Один раз в год",
        ],
        correctAnswers: ["Один раз в полгода"],
      },
      {
        code: "20405149",
        text: "Кто осуществляет контроль состояния реверсивных, переключающих и герметизирующих устройств вентилятора главного проветривания (ВГП)?",
        answers: [
          "Главный механик обособленного структурного подразделения или лицо, им назначенное",
          "Технический руководитель (главный инженер) обособленного структурного подразделения",
          "Руководитель обособленного структурного подразделения",
          "Должностное лицо Ростехнадзора",
        ],
        correctAnswers: [
          "Главный механик обособленного структурного подразделения или лицо, им назначенное",
        ],
      },
      {
        code: "20405150",
        text: "Каково максимально допустимое расстояние от конца вентиляционного трубопровода до забоя при проведении восстающих горных выработок?",
        answers: ["6 м", "8 м", "10 м", "15 м"],
        correctAnswers: ["6 м"],
      },
      {
        code: "20405151",
        text: "Что требуется для остановки технических устройств, обеспечивающих проветривание и кондиционирование горных выработок, водоснабжение, откачку воды, спуск и подъем работников, работу МФСБ для выполнения ремонтных работ?",
        answers: [
          "Письменное разрешение технического руководителя обособленного структурного подразделения",
          "Письменное или устное распоряжение технического руководителя обособленного структурного подразделения",
          "Письменное разрешение руководителя эксплуатирующей организации",
          "Письменное разрешение технического руководителя обособленного структурного подразделения, согласованное с Ростехнадзором",
        ],
        correctAnswers: [
          "Письменное разрешение технического руководителя обособленного структурного подразделения",
        ],
      },
      {
        code: "20405152",
        text: "Какая максимальная температура воздуха допускается в добычных, подготовительных и других действующих выработках у мест, где работают люди при относительной влажности до 90 %?",
        answers: ["26 °C", "24 °C", "28 °C", "25 °C"],
        correctAnswers: ["26 °C"],
      },
      {
        code: "20405153",
        text: "Кем выполняется вскрытие перемычек и разгазирование изолированных горных выработок?",
        answers: [
          "Профессиональным аварийно-спасательным формированием (ПАСФ)",
          "Эксплуатирующей организацией",
          "Службой аэрологической безопасности",
          "Монтажной организацией",
        ],
        correctAnswers: [
          "Профессиональным аварийно-спасательным формированием (ПАСФ)",
        ],
      },
      {
        code: "20405154",
        text: "В каком из перечисленных случаев в камерах электроустановок, расположенных в подземных выработках, должно быть два выхода?",
        answers: [
          "Если камера длиной более 10 м",
          "Если камера длиной более 5 м",
          "Если камера длиной более 8 м",
          "Если камера длиной более 7,5 м",
        ],
        correctAnswers: ["Если камера длиной более 10 м"],
      },
      {
        code: "20405155",
        text: "Какова минимальная ширина прохода вокруг распределительных устройств в подземных центральных распределительных пунктах?",
        answers: ["0,5 м", "0,6 м", "0,7 м", "0,8 м"],
        correctAnswers: ["0,8 м"],
      },
      {
        code: "20405156",
        text: "В какой цвет должны быть окрашены заземляющие проводники?",
        answers: [
          "В белый цвет",
          "В серый цвет",
          "В черный цвет",
          "В синий цвет",
        ],
        correctAnswers: ["В черный цвет"],
      },
      {
        code: "20405157",
        text: "С какой периодичностью должен производиться осмотр всех заземляющих устройств на шахте?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
        ],
        correctAnswers: ["Не реже 1 раза в 3 месяца"],
      },
      {
        code: "20405158",
        text: "Каким должно быть сопротивление общего заземляющего устройства открытых горных работ?",
        answers: ["Не более 4 Ом", "Не менее 2 Ом", "Не более 5 Ом"],
        correctAnswers: ["Не более 4 Ом"],
      },
      {
        code: "20405159",
        text: "Какого сечения должны быть сборные шины заземления, выполненные из стали, к которым присоединяются местные заземлители?",
        answers: [
          "Не менее 25 мм2",
          "Не менее 50 мм2",
          "Не более 75 мм2",
          "Не более 100 мм2",
        ],
        correctAnswers: ["Не менее 50 мм2"],
      },
      {
        code: "20405160",
        text: "Какое сечение должны иметь стальные полосы, применяемые для выполнения заземляющего контура?",
        answers: [
          "Не менее 25 мм2",
          "Не менее 50 мм2",
          "Не менее 70 мм2",
          "Не менее 100 мм2",
        ],
        correctAnswers: ["Не менее 100 мм2"],
      },
      {
        code: "20405161",
        text: "Какие нормы сопротивления изоляции установлены для электродвигателей добычных и проходческих машин?",
        answers: ["Не ниже 1 МОм", "Не ниже 0,5 МОм", "Не ниже 10 МОм"],
        correctAnswers: ["Не ниже 0,5 МОм"],
      },
      {
        code: "20405162",
        text: "Что служит защитой электроприводов технологического оборудования при исчезновении или резком снижении напряжения питающей сети?",
        answers: [
          "Электрическая блокировка, исключающая самозапуск",
          "Защита от перегрузки",
          "Защита от колебаний нагрузки",
        ],
        correctAnswers: ["Электрическая блокировка, исключающая самозапуск"],
      },
      {
        code: "20405163",
        text: "Какое напряжение должно применяться для питания передвижных приемников электроэнергии?",
        answers: ["Не выше 10 000 В", "Не выше 7200 В", "Не выше 3300 В"],
        correctAnswers: ["Не выше 3300 В"],
      },
      {
        code: "20405164",
        text: "Каким требованиям электробезопасности должны соответствовать светильники с лампами накаливания, устанавливаемые в помещениях с повышенной опасностью ниже 2,5 м от пола?",
        answers: [
          "Должны обеспечивать освещенность не менее 300 лк",
          "Должны иметь защиту не ниже IP 44",
          "Должны быть специальной конструкции либо использовать напряжение не выше 50 В с заземлением металлической арматуры",
        ],
        correctAnswers: [
          "Должны быть специальной конструкции либо использовать напряжение не выше 50 В с заземлением металлической арматуры",
        ],
      },
      {
        code: "20405165",
        text: "Каково максимально допустимое напряжение для питания ручных светильников в помещениях с повышенной опасностью?",
        answers: ["12 В", "36 В", "50 В", "127 В"],
        correctAnswers: ["50 В"],
      },
      {
        code: "20405166",
        text: "Кто является ответственным за организацию учета лиц, спустившихся и вышедших из горных выработок?",
        answers: [
          "Руководитель объекта",
          "Технический руководитель шахты",
          "Специалист по промышленной безопасности",
          "Начальник участка",
        ],
        correctAnswers: ["Начальник участка"],
      },
      {
        code: "20405167",
        text: "Каким должно быть количество самоспасателей в местах группового хранения?",
        answers: [
          "Должно соответствовать численности работающих в рабочей смене",
          "Должно соответствовать численности работающих на объекте в наиболее многочисленной смене",
          "Должно превышать численность работающих на объекте в наиболее многочисленной смене не менее чем на 10 %",
          "Определяется по согласованию с командиром военизированного газоспасательного отряда",
        ],
        correctAnswers: [
          "Должно превышать численность работающих на объекте в наиболее многочисленной смене не менее чем на 10 %",
        ],
      },
      {
        code: "20405168",
        text: "Для каких объектов должен разрабатываться План мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "Только для тоннелей протяженностью более 2 км",
          "Только для выработок под реками или другими водными преградами",
          "Только для подземных объектов, сооружаемых в неустойчивых грунтах",
          "Для каждого строящегося подземного объекта",
        ],
        correctAnswers: ["Для каждого строящегося подземного объекта"],
      },
      {
        code: "20405169",
        text: "Каким должно быть расстояние от выхода из подземных выработок до места хранения пожароопасных веществ и материалов?",
        answers: [
          "Не менее 50 м",
          "Не менее 45 м",
          "Не менее 30 м",
          "Не менее 25 м",
        ],
        correctAnswers: ["Не менее 50 м"],
      },
      {
        code: "20405170",
        text: "Какой радиус опасной зоны должен быть установлен при производстве взрывопожароопасных работ?",
        answers: [
          "Не менее 20 м",
          "Не менее 30 м",
          "Не менее 40 м",
          "Не менее 50 м",
        ],
        correctAnswers: ["Не менее 50 м"],
      },
      {
        code: "20405171",
        text: "На каком расстоянии должны быть расположены первичные средства пожаротушения?",
        answers: [
          "Не дальше 50 м от входа в камеры",
          "В горизонтальных выработках с огнестойкой обделкой - через 300 м",
          "При наличии горючей крепи через 300 м",
          "Не дальше 50 м от груди забоя",
        ],
        correctAnswers: [
          "В горизонтальных выработках с огнестойкой обделкой - через 300 м",
        ],
      },
      {
        code: "20405172",
        text: "В каком из перечисленных положений требования по комплектации первичных средств пожаротушения указаны неверно?",
        answers: [
          "По каждому месту первичные средства пожаротушения должны быть укомплектованы не менее чем двумя огнетушителями и ящиком с песком вместимостью 0,1 м3",
          "В камерах и забоях ящики с песком должны комплектоваться совковой лопатой",
          "При отдельных электроустановках (на участках выработок с негорючей крепью) допускается размещать по одному огнетушителю и иметь ящик с песком вместимостью 0,1 м3",
        ],
        correctAnswers: [
          "По каждому месту первичные средства пожаротушения должны быть укомплектованы не менее чем двумя огнетушителями и ящиком с песком вместимостью 0,1 м3",
        ],
      },
      {
        code: "20405173",
        text: "Какие действия обязаны предпринять при пожарах люди, находящиеся на исходящей от источника пожара струе воздуха?",
        answers: [
          "Взять самоспасатели и выходить на поверхность",
          "Оставаться на месте, включившись в самоспасатели",
          "Включиться в самоспасатели, контролировать концентрацию окиси углерода, найти наиболее безопасное место и ждать прибытия горноспасательных отделений",
          "Включиться в самоспасатели и под руководством старшего выходить на поверхность по маршруту, предусмотренному планом мероприятий по локализации и ликвидации последствий аварий",
        ],
        correctAnswers: [
          "Включиться в самоспасатели и под руководством старшего выходить на поверхность по маршруту, предусмотренному планом мероприятий по локализации и ликвидации последствий аварий",
        ],
      },
      {
        code: "20405174",
        text: "По прошествии какого времени после массового взрыва по разрушению целиков разрешается спуск в шахту представителей профессиональной аварийно-спасательной службы (формирования)?",
        answers: ["Через 2 часа", "Через час", "Через 3 часа", "Через 4 часа"],
        correctAnswers: ["Через 2 часа"],
      },
      {
        code: "20405175",
        text: "По прошествии какого времени после взрыва может осуществляться допуск постов профессиональной аварийно-спасательной службы (формирования) в пределы опасной зоны?",
        answers: ["15 минут", "20 минут", "45 минут", "30 минут"],
        correctAnswers: ["15 минут"],
      },
      {
        code: "20405176",
        text: "Через какое время после производства массовых взрывов разрешается допуск работников на рабочие места?",
        answers: [
          "Не ранее чем через 30 минут",
          "Не ранее чем через 90 минут",
          "Не ранее чем через 10 минут",
          "Не ранее чем через 60 минут",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не ранее чем через 30 минут"],
      },
      {
        code: "20405177",
        text: "Какое из перечисленных действий допускается при ведении горноспасательных работ в подземных горных выработках в зоне высоких температур с непригодной для дыхания рудничной атмосферой?",
        answers: [
          "Только привлечение членов вспомогательной горноспасательной команды для ведения горноспасательных работ в зоне высоких температур с непригодной для дыхания рудничной атмосферой",
          "Только ведение горноспасательных работ, не связанных со спасением людей, в зоне высоких температур с непригодной для дыхания рудничной атмосферой без средств противотепловой индивидуальной защиты при температуре выше 50 °C",
          "Только ведение горноспасательных работ в зоне высоких температур с непригодной для дыхания рудничной атмосферой при отсутствии связи горноспасательного отделения, ведущего горноспасательные работы, с руководителем горноспасательных работ или с горноспасательным отделением, находящимся на подземной горноспасательной базе, при условии, что эти работы связаны со спасением людей",
          "Все перечисленные действия",
        ],
        correctAnswers: [
          "Только ведение горноспасательных работ в зоне высоких температур с непригодной для дыхания рудничной атмосферой при отсутствии связи горноспасательного отделения, ведущего горноспасательные работы, с руководителем горноспасательных работ или с горноспасательным отделением, находящимся на подземной горноспасательной базе, при условии, что эти работы связаны со спасением людей",
        ],
      },
      {
        code: "20405178",
        text: 'Что из перечисленного должен предусматривать раздел "Мероприятия по обеспечению пожарной безопасности" в проектной документации?',
        answers: [
          "Только организационные и технические мероприятия по предотвращению возможности пожара",
          "Только мероприятия по локализации и тушению пожара в начальной стадии его возникновения во всех технологических процессах при эксплуатации горно-шахтного оборудования",
          "Только мероприятия по локализации и тушению пожара в начальной стадии его возникновения во всех технологических процессах в случаях проведения ремонтов и в аварийных ситуациях",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20405179",
        text: "В какой цвет окрашивается шахтный пожарно-оросительный трубопровод?",
        answers: ["В желтый цвет", "В оранжевый цвет", "В красный цвет"],
        correctAnswers: ["В красный цвет"],
      },
      {
        code: "20405180",
        text: "Где должны располагаться установки автоматического пожаротушения для камер с постоянным дежурством обслуживающего персонала?",
        answers: [
          "Снаружи камеры со стороны поступления свежей струи воздуха не дальше 10 м от входа в камеру",
          "У рабочего места дежурного персонала",
          "Непосредственно у защищаемого оборудования",
        ],
        correctAnswers: ["У рабочего места дежурного персонала"],
      },
      {
        code: "20405181",
        text: "Каково максимально допустимое расстояние между сдвоенными закрывающимися по направлению движения свежей вентиляционной струи пожарными дверями на всех горизонтах вблизи околоствольных дворов и у устьев штолен, подающих свежий воздух?",
        answers: ["10 м", "15 м", "20 м", "5 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "20405182",
        text: "На кого возлагается руководство работами по локализации и ликвидации последствий аварий?",
        answers: [
          "На технического руководителя (главного инженера)",
          "На начальника аварийно-спасательной службы",
          "На начальника участка",
        ],
        correctAnswers: ["На технического руководителя (главного инженера)"],
      },
      {
        code: "20405183",
        text: "Кто имеет право письменным приказом отстранить ответственного руководителя работ и принять руководство на себя или возложить его на другое лицо из числа должностных лиц шахты?",
        answers: [
          "Главный энергетик шахты",
          "Все ответы неверны",
          "Главный инженер организации (предприятия)",
          "Командир ВГСЧ",
          "Начальник шахты",
        ],
        correctAnswers: ["Главный инженер организации (предприятия)"],
      },
      {
        code: "20405184",
        text: "Чье решение является обязательным для выполнения в случае разногласия между командиром ВГСЧ и ответственным руководителем работ при организации работ по ликвидации аварии?",
        answers: [
          "Ответственного руководителя работ по ликвидации аварий",
          "Вышестоящего руководства",
          "Экспертной комиссии",
          "Командира ВГСЧ",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Ответственного руководителя работ по ликвидации аварий",
        ],
      },
      {
        code: "20405185",
        text: "Кем подписывается оперативная часть плана ликвидации аварий в конце последней позиции с указанием даты составления плана?",
        answers: [
          "Главным инженером шахты",
          "Руководителем организации (предприятия)",
          "Руководителем спасательной службы",
          "Командиром ВГСЧ",
          "Все ответы неверны",
        ],
        correctAnswers: ["Главным инженером шахты"],
      },
      {
        code: "20405186",
        text: "На основании каких данных составляется оперативный план по локализации и ликвидации последствий аварий?",
        answers: [
          "Только на основании поступающей на командный пункт (КП) информации",
          "Только на основании анализа аварийной обстановки",
          "Только на основании прогноза развития аварии",
          "На основании всех перечисленных данных",
        ],
        correctAnswers: ["На основании всех перечисленных данных"],
      },
      {
        code: "20405187",
        text: "Что из перечисленного наносится на аксонометрических схемах и погоризонтных планах вентиляционных горных выработок в виде кружков диаметром 13 мм?",
        answers: [
          "Места размещения телефонов",
          "Все ответы неверны",
          "Позиции, соответствующие оперативной части плана ликвидации аварий",
          "Места размещения самоспасателей, огнетушителей",
          "Главные вентиляторные установки",
        ],
        correctAnswers: [
          "Позиции, соответствующие оперативной части плана ликвидации аварий",
        ],
      },
      {
        code: "20405188",
        text: "В каких случаях в подземных горных выработках, в которых имеется взрывоопасная среда при наличии очагов горения или существует угроза прорыва воды (пульпы), работы по поиску и спасению людей не проводятся или прекращаются?",
        answers: [
          "Только если рудничная атмосфера в выработках аварийного участка находилась в состоянии, непригодном для дыхания, в течение времени, равного десятикратному сроку времени защитного действия СИЗОД",
          "Только если в местах предполагаемого нахождения людей в течение не менее 24 часов температура воздуха составляла 100 °C и более",
          "Только если в местах предполагаемого нахождения людей горная выработка затоплена водой и из нее отсутствует запасной выход",
          "Только если люди, находящиеся в аварийных горных выработках, признаны погибшими в установленном порядке",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20405189",
        text: "Чем из перечисленного оснащается командный пункт (КП)?",
        answers: [
          "Только аппаратурой телефонной и (или) радиосвязи с местами ведения работ по локализации и ликвидации последствий аварии",
          "Только электронно-вычислительными машинами",
          "Только множительной и копировальной техникой",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20405190",
        text: "Какими способами осуществляется тушение подземных пожаров?",
        answers: [
          "Только способом активного тушения",
          "Только способом изоляции горных выработок, в которых действует пожар",
          "Только комбинированным способом",
          "Всеми перечисленными способами",
        ],
        correctAnswers: ["Всеми перечисленными способами"],
      },
      {
        code: "20405191",
        text: "В каких подземных горных выработках запрещается тушение пожара водой?",
        answers: [
          "В выработках угольных шахт",
          "В выработках асбестовых рудников",
          "В выработках соляных и калийных рудников",
        ],
        correctAnswers: ["В выработках соляных и калийных рудников"],
      },
      {
        code: "20405192",
        text: "В каком из перечисленных случаев пересматриваются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.",
        answers: [
          "Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий",
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
          "Не позднее 40 дней после внесения изменений в системы управления технологическими процессами на объекте",
          "В случае назначения нового руководителя организации",
          "Не позднее 15 дней после внесения изменений в методики (методы) измерений или типы средств измерений, применяемые при осуществлении производственного контроля за соблюдением требований промышленной безопасности на объекте",
        ],
        correctAnswers: [
          "Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий",
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
        ],
      },
      {
        code: "20405193",
        text: "Какова минимально допустимая численность горноспасательного отделения или отделения вспомогательной горноспасательной команды (ВГК) для ведения горноспасательных работ в непригодной для дыхания атмосфере при работе в подземных горных выработках?",
        answers: [
          "Пять человек",
          "Два человека",
          "Три человека",
          "Шесть человек",
        ],
        correctAnswers: ["Пять человек"],
      },
      {
        code: "20405194",
        text: "Какова минимально допустимая температура при ведении горноспасательных работ в горных выработках с непригодной для дыхания атмосферой?",
        answers: ["Минус 20 °C", "Минус 15 °C", "Минус 10 °C", "Минус 8 °C"],
        correctAnswers: ["Минус 20 °C"],
      },
      {
        code: "20405195",
        text: "При каком угле наклона наклонной горной выработки пожар в ней тушится только активным способом и только дистанционно?",
        answers: [
          "С углом наклона более 20°",
          "С углом наклона до 15°",
          "С углом наклона 10°",
          "В любой наклонной горной выработке",
        ],
        correctAnswers: ["С углом наклона более 20°"],
      },
      {
        code: "20405196",
        text: "Каким документом устанавливаются порядок и обстоятельства, при которых допускается отстранить руководителя работ по ликвидации аварии и руководителя горноспасательных работ и назначить другое должностное лицо?",
        answers: [
          "Распорядительными документами, утверждаемыми руководителями ОПО и ПАСС(Ф) соответственно",
          "Распорядительным документом, утверждаемым представителем территориального органа Ростехнадзора",
          "Распорядительным документом, утверждаемым представителем Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "Распорядительным документом на основании Инструкции по локализации и ликвидации последствий аварий на опасных производственных объектах, на которых ведутся горные работы",
        ],
        correctAnswers: [
          "Распорядительными документами, утверждаемыми руководителями ОПО и ПАСС(Ф) соответственно",
        ],
      },
      {
        code: "20405197",
        text: "Какая принимается скорость передвижения людей в изолирующих самоспасателях по загазованным наклонным выработкам с углом наклона 10° при подъеме?",
        answers: [
          "45 м/мин",
          "30 м/мин",
          "25 м/мин",
          "60 м/мин",
          "Все ответы неверны",
        ],
        correctAnswers: ["45 м/мин"],
      },
      {
        code: "20405198",
        text: "Какая принимается скорость передвижения людей в изолирующих самоспасателях по загазованным горизонтальным выработкам высотой 1,8 - 2,0 м?",
        answers: [
          "60 м/сек",
          "45 м/сек",
          "30 м/сек",
          "70 м/сек",
          "Все ответы неверны",
        ],
        correctAnswers: ["60 м/сек"],
      },
      {
        code: "20405199",
        text: "Кем составляется акт проверки исправности противопожарных средств и оборудования плана ликвидации аварий?",
        answers: [
          "Комиссией в составе начальника шахты, начальника ПВС, механика шахты и командира ВГСЧ, обслуживающей данную шахту",
          "Комиссией в составе главного инженера шахты, начальника ПВС и командира ВГСЧ, обслуживающей данную шахту",
          "Все ответы неверны",
          "Комиссией в составе начальника шахты, начальника ПВС и инспектора Ростехнадзора",
        ],
        correctAnswers: [
          "Комиссией в составе начальника шахты, начальника ПВС, механика шахты и командира ВГСЧ, обслуживающей данную шахту",
        ],
      },
      {
        code: "20405200",
        text: "Какое из перечисленных первоочередных мероприятий плана ликвидации аварий рекомендуется располагать первым в части аварий, связанных с загазованностью выработок?",
        answers: [
          "Установление аварийного режима работы главных вентиляционных установок",
          "Все ответы неверны",
          "Вызов ВГСЧ",
          "Режим электроснабжения шахты",
          "Расстановка постов безопасности",
        ],
        correctAnswers: [
          "Установление аварийного режима работы главных вентиляционных установок",
        ],
      },
      {
        code: "20405201",
        text: "Что из перечисленного допускается не предусматривать в оперативной части соответствующего плана для ликвидации подземных пожаров в начальной стадии?",
        answers: [
          "Места установки временных перемычек",
          "Использование насосов и ставов труб",
          "Порядок и способы использования водоотливных, воздухопроводных и противопожарных (противопожарно-оросительных) трубопроводов",
          "Использование имеющегося на шахте оборудования для ликвидации возможных аварий",
          "Все ответы неверны",
        ],
        correctAnswers: ["Использование насосов и ставов труб"],
      },
      {
        code: "20405202",
        text: "Кем должен тщательно рассматриваться маршрут движения отделений ВГСЧ на вывод людей и ликвидацию аварий в каждом конкретном случае? Укажите все правильные ответы.",
        answers: [
          "Руководителем подразделения противопожарной службы",
          "Представителем Ростехнадзора",
          "Представителем МЧС России",
          "Главным инженером",
          "Командиром ВГСЧ",
        ],
        correctAnswers: ["Главным инженером", "Командиром ВГСЧ"],
      },
      {
        code: "20405203",
        text: "При каких видах аварии необходимо предусматривать вызов служб МЧС России?",
        answers: [
          "Все ответы неверны",
          "При всех видах аварий, когда требуется оказание помощи людям, и для ведения работ, требующих соответствующего горноспасательного оборудования",
          "При пожарах в стволах, шурфах и других выработках, имеющих выход на поверхность",
          "При землетрясениях",
          "При пожарах в надшахтных зданиях и сооружениях",
        ],
        correctAnswers: ["При землетрясениях"],
      },
      {
        code: "20405204",
        text: "Что из перечисленного должно предусматриваться при прорыве в горные выработки воды, пульпы, реагентов?",
        answers: [
          "Вывод всех людей из шахты на поверхность",
          "Все ответы неверны",
          "Вывод людей только из аварийного участка",
          "Вывод людей только из аварийного и угрожаемого участка",
        ],
        correctAnswers: ["Вывод всех людей из шахты на поверхность"],
      },
      {
        code: "20405205",
        text: "Кем прорабатывается план ликвидации аварий под расписку?",
        answers: [
          "Главным инженером шахты с инспектором Ростехнадзора",
          "Специализированной сторонней организацией",
          "Все ответы неверны",
          "Главным инженером шахты с командным составом ВГСЧ",
          "Главным инженером шахты с должностными лицами (техническим надзором) шахты",
        ],
        correctAnswers: [
          "Главным инженером шахты с должностными лицами (техническим надзором) шахты",
        ],
      },
      {
        code: "20405206",
        text: "Какие надписи должен иметь титульный лист плана ликвидации аварии?",
        answers: [
          "Подпись главного инженера шахты",
          "Все ответы неверны",
          "Надпись главного инженера организации (предприятия) с датой утверждения",
          "Надпись командира ВГСЧ с указанием даты",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20405207",
        text: "Кем должна осуществляться проверка состояния предусмотренных планом ликвидации аварии материальных и технических средств для осуществления мероприятий по спасению людей и ликвидации аварий перед согласованием планов ликвидации аварий на новое полугодие? Укажите все правильные ответы.",
        answers: [
          "Инспектором территориального органа Ростехнадзора",
          "Работниками шахты",
          "Военизированной горноспасательной частью (ВГСЧ)",
          "Руководителем аварийно-спасательного формирования",
        ],
        correctAnswers: [
          "Работниками шахты",
          "Военизированной горноспасательной частью (ВГСЧ)",
        ],
      },
      {
        code: "20405208",
        text: "Каково номинальное время защитного действия автономных изолирующих дыхательных аппаратов (ДА) со сжатым воздухом на объектах открытых горных работ?",
        answers: [
          "Не менее одного часа",
          "Не менее 50 минут",
          "Не менее 45 минут",
          "Не менее 30 минут",
        ],
        correctAnswers: ["Не менее одного часа"],
      },
      {
        code: "20405209",
        text: "Кто определяет места возведения изоляционных перемычек (ИП), предназначенных для изоляции пожарного участка, их конструкцию, последовательность и сроки возведения?",
        answers: [
          "Руководитель ликвидации аварии (РЛА)",
          "Руководитель горноспасательных работ (РГСР)",
          "Руководитель опасного производственного объекта (ОПО)",
          "Лицо, ответственное за пожарную безопасность ОПО",
        ],
        correctAnswers: ["Руководитель ликвидации аварии (РЛА)"],
      },
      {
        code: "20405210",
        text: "В каком случае эксплуатирующая организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах на несколько опасных объектов?",
        answers: [
          "В случае если 2 и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
          "В случае если это регламентировано внутренней документацией организации",
          "В случае если объекты зарегистрированы в государственном реестре опасных производственных объектов",
          "План мероприятий разрабатывается на каждый опасный объект отдельно",
        ],
        correctAnswers: [
          "В случае если 2 и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках",
        ],
      },
      {
        code: "20405211",
        text: "Что из перечисленного входит в должностные обязанности руководителя горноспасательных работ (РГСР)?",
        answers: [
          "Только получение заданий от РЛА на проведение горноспасательных работ",
          "Только проведение анализа полученной от РЛА информации о происшедшей аварии",
          "Только организация ведения оперативного журнала ПАСС(Ф)",
          "Только организация ведения горноспасательных работ",
          "Только обеспечение прибытия горноспасательных отделений и других сил и средств ПАСС(Ф) для ведения горноспасательных работ в количестве и в сроки, предусмотренные ПЛА",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20405212",
        text: "Какова минимально допустимая численность горноспасательного отделения или отделения вспомогательной горноспасательной команды при ведении горноспасательных работ в непригодной для дыхания атмосфере при работе на открытых горных выработках?",
        answers: [
          "Три человека",
          "Два человека",
          "Пять человек",
          "Шесть человек",
        ],
        correctAnswers: ["Три человека"],
      },
      {
        code: "20405213",
        text: "Какое из перечисленных требований при тушении пожаров в вертикальных горных выработках указано верно?",
        answers: [
          "При тушении активным способом и локализации пожаров в вертикальных горных выработках с восходящей струей воздуха с применением воды, подаваемой с поверхности, выполняются меры, исключающие опрокидывание вентиляционной струи",
          "Пожары в вертикальных горных выработках тушатся активным способом снизу вверх",
          "Нахождение людей в вертикальных горных выработках во время тушения в них пожара допускается при условии выполнения мер, исключающих опрокидывание вентиляционной струи",
          "При тушении активным способом и локализации пожара в вертикальном стволе с исходящей вентиляционной струей или в надшахтном здании этого ствола возможно изменение направления вентиляционной струи",
        ],
        correctAnswers: [
          "При тушении активным способом и локализации пожаров в вертикальных горных выработках с восходящей струей воздуха с применением воды, подаваемой с поверхности, выполняются меры, исключающие опрокидывание вентиляционной струи",
        ],
      },
      {
        code: "20405214",
        text: "Какое максимальное допустимое избыточное давление в кессоне при ведении горноспасательных работ в автономных изолирующих дыхательных аппаратах?",
        answers: ["0,2 МПа", "0,1 МПа", "0,3 МПа", "0,4 МПа"],
        correctAnswers: ["0,2 МПа"],
      },
      {
        code: "20405215",
        text: "В каком случае инертизация рудничной атмосферы в изолируемом пространстве может быть начата до окончания возведения взрывоустойчивых изоляционных перемычек?",
        answers: [
          "В случае, если объем подаваемого инертного газа превышает расход воздуха, поступающего на пожарный участок",
          "В случае, если продолжительность подачи инертного газа превышает допустимую",
          "В случае, если это определено оперативным планом",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "В случае, если объем подаваемого инертного газа превышает расход воздуха, поступающего на пожарный участок",
        ],
      },
      {
        code: "20405216",
        text: "Какую информацию включает в себя общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Только характеристику объектов, в отношении которых разрабатывается план мероприятий",
          "Только возможные сценарии возникновения и развития аварий на объектах, а также источники (места) возникновения аварий",
          "Только характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах",
          "Всю перечисленную",
        ],
        correctAnswers: ["Всю перечисленную"],
      },
      {
        code: "20405217",
        text: "Какова максимально допустимая продолжительность пребывания работников ПАСС(Ф) и членов вспомогательной горноспасательной команды (ВКГ) установлена для их пребывания в непригодной для дыхания атмосфере с применением дыхательных аппаратов?",
        answers: ["Четыре часа", "Три часа", "Два часа", "Пять часов"],
        correctAnswers: ["Четыре часа"],
      },
      {
        code: "20405218",
        text: "Кто утверждает планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.",
        answers: [
          "Руководитель (заместители руководителей) организаций, эксплуатирующих объекты",
          "Руководители обособленных подразделений юридических лиц",
          "Руководители профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований",
          "Инспектор Ростехнадзора",
        ],
        correctAnswers: [
          "Руководитель (заместители руководителей) организаций, эксплуатирующих объекты",
          "Руководители обособленных подразделений юридических лиц",
        ],
      },
      {
        code: "20405219",
        text: "Какое из перечисленных требований к оперативным планам по локализации и ликвидации последствий аварий указано неверно?",
        answers: [
          "Оперативный план утверждает руководитель горноспасательных работ",
          "Оперативные планы разрабатываются до окончания ведения горноспасательных работ",
          "Последующие оперативные планы (N 2, 3 и т. д.) разрабатываются в случаях, когда мероприятия предыдущего оперативного плана реализованы или требуется их корректировка",
          "Оперативный план не должен содержать ссылок на пункты ранее разработанных оперативных планов",
        ],
        correctAnswers: [
          "Оперативный план утверждает руководитель горноспасательных работ",
        ],
      },
      {
        code: "20405220",
        text: "При каких условиях допускается ведение горноспасательных работ в тупиковой выработке?",
        answers: [
          "При тушении пожара в выработанном пространстве подачей огнетушащих веществ дистанционно, при этом люди должны находиться на расстоянии от места возникновения аварии, далее которого отсутствует вероятность воздействия опасных факторов аварии на человека, либо на поверхности",
          "При отсутствии информации о содержании в ней горючих газов",
          "При отсутствии в выработке пострадавших",
          "При тушении пожара в непроветриваемых тупиковых горных выработках",
        ],
        correctAnswers: [
          "При тушении пожара в выработанном пространстве подачей огнетушащих веществ дистанционно, при этом люди должны находиться на расстоянии от места возникновения аварии, далее которого отсутствует вероятность воздействия опасных факторов аварии на человека, либо на поверхности",
        ],
      },
      {
        code: "20405221",
        text: "Какая документация должна находиться на командном пункте?",
        answers: [
          "Только журналы опасного производственного объекта (ОПО) и профессиональной аварийно-спасательной службы (формирования) и ПАСС(Ф)",
          "Только план ликвидации аварий",
          "Только оперативные планы, текстовая и графическая документация, разрабатываемая в период локализации и ликвидации последствий аварии",
          "Вся перечисленная документация",
        ],
        correctAnswers: ["Вся перечисленная документация"],
      },
      {
        code: "20405222",
        text: "Кто из перечисленных лиц обеспечивает подачу сжатого воздуха или воды к месту аварии в случаях, предусмотренных планом ликвидации аварий?",
        answers: [
          "Руководитель энергомеханической службы ОПО",
          "Начальник службы (участка), обеспечивающей (обеспечивающего) аэрологическую безопасность подземных горных выработок",
          "Начальник участка, на котором произошла авария",
          "Специалист ОПО, ответственный за учет и выдачу индивидуальных головных светильников, самоспасателей, газоанализаторов",
        ],
        correctAnswers: ["Руководитель энергомеханической службы ОПО"],
      },
      {
        code: "20405223",
        text: "Какое из перечисленных мероприятий не проводится при ликвидации последствий горного удара, обрушения пород, оползня?",
        answers: [
          "Мониторинг концентрации индикаторных пожарных газов",
          "Определение возможности развития обрушения (оползня) и безопасных способов выполнения горноспасательных работ",
          "Организация работы по разборке обрушившейся горной массы и (или) проведение поисковых выработок из возможно большего числа мест",
          "Установление связи с застигнутыми аварией людьми, не имеющими возможности выйти из зоны аварии",
        ],
        correctAnswers: ["Мониторинг концентрации индикаторных пожарных газов"],
      },
      {
        code: "20405224",
        text: "В каком приоритетном порядке предварительно определяется вид происшедшей аварии при отсутствии информации, позволяющей достоверно определить ее вид, в подземных горных выработках?",
        answers: [
          "Взрыв, внезапный выброс, пожар, горный удар, обрушение",
          "Взрыв, пожар, внезапный выброс, горный удар, обрушение",
          "Взрыв, горный удар, обрушение, внезапный выброс, пожар",
          "Взрыв, горный удар, внезапный выброс, пожар, обрушение",
        ],
        correctAnswers: [
          "Взрыв, внезапный выброс, пожар, горный удар, обрушение",
        ],
      },
      {
        code: "20405225",
        text: "При каком виде аварии члены вспомогательной горноспасательной команды устанавливают связь с застигнутыми аварией людьми, организуют их спасение, предварительно усилив крепь, тем самым исключив возможные обрушения?",
        answers: [
          "При обрушении в горной выработке",
          "При пожаре в тупиковой выработке",
          "При взрыве метана и (или) угольной пыли",
          "При внезапном выбросе угля (породы)",
          "Все ответы неверны",
        ],
        correctAnswers: ["При обрушении в горной выработке"],
      },
      {
        code: "20405226",
        text: "Каким образом распределяется максимальная продолжительность (время) непрерывного пребывания работников ПАСС(Ф) в зоне высоких температур при передвижении по подземным горным выработкам с непригодной для дыхания атмосферой?",
        answers: [
          'Одна треть на передвижение в направлении "туда" и две трети - в направлении "обратно"',
          'Две трети на передвижение в направлении "туда" и одна треть - в направлении "обратно"',
          'Половина на передвижение в направлении "туда" и половина - в направлении "обратно"',
          "По решению технического руководителя (главного инженера) ОПО",
        ],
        correctAnswers: [
          'Одна треть на передвижение в направлении "туда" и две трети - в направлении "обратно"',
        ],
      },
      {
        code: "20405227",
        text: "Обязаны ли организации, эксплуатирующие опасные производственные объекты, на которых ведутся горные работы и переработка полезных ископаемых, заключать договоры на обслуживание с профессиональными аварийно-спасательными формированиями (службами)?",
        answers: [
          "Заключение договоров не требуется",
          "Заключение договоров необязательно",
          "Заключение договоров обязательно",
        ],
        correctAnswers: ["Заключение договоров обязательно"],
      },
      {
        code: "20405228",
        text: "В каком случае допускается нахождение людей, занятых тушением подземного пожара активным способом, в горных выработках с исходящей от пожара струей воздуха?",
        answers: [
          "Только в случае тушения пожара водой",
          "Не допускается ни в каком случае",
          "Только в случае, когда пожар находится вблизи выработок со свежей струей воздуха",
          "Только в случае выполнения мер, предотвращающих обрушение (высыпание) горных пород",
        ],
        correctAnswers: [
          "Только в случае, когда пожар находится вблизи выработок со свежей струей воздуха",
        ],
      },
    ],
  },
  82: {
    20501: [
      {
        code: "20501000",
        text: "На какую деятельность на опасных производственных объектах юридических лиц и индивидуальных предпринимателей распространяются Правила безопасности при разработке угольных месторождений открытым способом?",
        answers: [
          "Только на деятельность, связанную с разработкой угольных месторождений открытым способом (далее - угольный разрез)",
          "Только на проектирование, строительство и эксплуатацию угольного разреза",
          "Только на конструирование, изготовление, монтаж, эксплуатацию и ремонт технических устройств",
          "На все перечисленные виды деятельности",
        ],
        correctAnswers: ["На все перечисленные виды деятельности"],
      },
      {
        code: "20501001",
        text: "На что направлены требования Правил безопасности при разработке угольных месторождений открытым способом?",
        answers: [
          "Только на обеспечение промышленной безопасности",
          "Только на предупреждение аварий и инцидентов на угольных разрезах",
          "Только на обеспечение готовности к локализации и ликвидации последствий аварий",
          "На все перечисленное",
        ],
        correctAnswers: ["На все перечисленное"],
      },
      {
        code: "20501002",
        text: "Что из перечисленного является обязательными условиями принятия решения о консервации и ликвидации разреза? Выберите два правильных варианта ответов.",
        answers: [
          "Наличие проектной документации на консервацию и ликвидацию разреза",
          "Наличие положительного заключения экспертизы промышленной безопасности",
          "Наличие согласованного и утвержденного плана работ",
          "Наличие договоров с подрядными организациями",
        ],
        correctAnswers: [
          "Наличие проектной документации на консервацию и ликвидацию разреза",
          "Наличие положительного заключения экспертизы промышленной безопасности",
        ],
      },
      {
        code: "20501003",
        text: "Кто устанавливает сроки приведения действующего угольного разреза в соответствие с требованиями    Правил безопасности при разработке угольных месторождений открытым способом? Выберите все варианты ответов.",
        answers: [
          "Представитель органа местного самоуправления, на территории которого располагается разрез",
          "Представитель территориального органа Ростехнадзора",
          "Технический руководитель (главный инженер) угольного разреза",
          "Руководитель угольного разреза",
        ],
        correctAnswers: [
          "Технический руководитель (главный инженер) угольного разреза",
          "Руководитель угольного разреза",
        ],
      },
      {
        code: "20501004",
        text: "Кто разрабатывает мероприятия, обосновывающие и обеспечивающие безопасную эксплуатацию угольного разреза до приведения действующего угольного разреза в соответствие с требованиями Правил безопасности при разработке угольных месторождений открытым способом?",
        answers: [
          "Главный технолог угольного разреза",
          "Главный маркшейдер угольного разреза",
          "Технический руководитель (главный инженер) угольного разреза",
          "Руководитель угольного разреза",
        ],
        correctAnswers: [
          "Технический руководитель (главный инженер) угольного разреза",
        ],
      },
      {
        code: "20501005",
        text: "Куда должен быть направлен план реализации мероприятий, обосновывающих и обеспечивающих безопасную эксплуатацию угольного разреза в соответствии с требованиями    Правил безопасности при разработке угольных месторождений открытым способом, до их реализации?",
        answers: [
          "В органы местного самоуправления, на территории которого располагается разрез",
          "В территориальный орган Ростехнадзора",
          "В профессиональные аварийно-спасательные службы и (или) профессиональные аварийно-спасательные формирования (ПАСС(Ф)",
          "Руководителю угольного разреза",
        ],
        correctAnswers: ["В территориальный орган Ростехнадзора"],
      },
      {
        code: "20501006",
        text: "На какой срок разрабатывается план развития горных работ по всем планируемым видам горных работ?",
        answers: ["На 1 год", "На 2 года", "На 3 года", "На 5 лет"],
        correctAnswers: ["На 1 год"],
      },
      {
        code: "20501007",
        text: "С кем необходимо согласовывать планы и схемы развития горных работ?",
        answers: [
          "Со специализированными профессиональными аварийно-спасательными формированиями",
          "С территориальными органами Ростехнадзора",
          "С органами исполнительной власти субъекта Российской Федерации, на территории которого эксплуатируется разрез",
          "Со сторонними организациями, расположенными на территории горного отвода",
        ],
        correctAnswers: ["С территориальными органами Ростехнадзора"],
      },
      {
        code: "20501008",
        text: "Кто осуществляет руководство работами по локализации и ликвидации последствий аварии на разрезе?",
        answers: [
          "Технический руководитель (главный инженер) ОПО",
          "Руководитель горноспасательных работ (РГСР) - должностное лицо ПАСС(Ф), обслуживающего ОПО, назначенное распорядительным документом руководителя ПАСС(Ф)",
          "Руководитель угольного разреза",
          "Представитель территориального органа Ростехнадзора",
          "Должностное лицо ВГСЧ",
        ],
        correctAnswers: ["Технический руководитель (главный инженер) ОПО"],
      },
      {
        code: "20501009",
        text: "Какое из перечисленных требований к оперативным планам по локализации и ликвидации последствий аварий указано неверно?",
        answers: [
          "Оперативные планы не должны содержать ссылки на пункты ранее разработанных оперативных планов",
          "Оперативные планы должны разрабатываться на основании поступающей на контрольный пункт информации, анализа аварийной обстановки и прогноза развития аварии",
          "Последующие оперативные планы должны разрабатываться в случаях, когда мероприятия предыдущего оперативного плана реализованы",
          "Оперативный план утверждает руководитель горноспасательных работ",
        ],
        correctAnswers: [
          "Оперативный план утверждает руководитель горноспасательных работ",
        ],
      },
      {
        code: "20501010",
        text: "Какое из перечисленных действий должен выполнять руководитель работ по ликвидации аварии?",
        answers: [
          "Организацию ведения оперативного журнала профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований",
          "Организацию ведения горноспасательных работ",
          "Установление режима работы и отдыха работников профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований при ведении горноспасательных работ",
          "Определение общего количества и местонахождения работников, застигнутых аварией, в том числе оказавшихся в непригодной для дыхания атмосфере",
        ],
        correctAnswers: [
          "Определение общего количества и местонахождения работников, застигнутых аварией, в том числе оказавшихся в непригодной для дыхания атмосфере",
        ],
      },
      {
        code: "20501011",
        text: "В каком из перечисленных случаев руководитель горноспасательных работ не проводит организацию подземной горноспасательной базы?",
        answers: [
          "Если горноспасательные работы проводятся в горных выработках с непригодной для дыхания атмосферой",
          "Если горноспасательные работы выполняются для ликвидации последствий прорыва воды при выполнении водолазных спусков",
          "Если все прибывшие в организацию, эксплуатирующую ОПО, горноспасательные отделения привлечены к горноспасательным работам, определенным планом мероприятий по локализации и ликвидации последствий аварий, связанным со спасением людей, застигнутых аварией",
          "Если горноспасательные работы выполняются для ликвидации последствий прорыва обводненной горной массы при выполнении водолазных работ",
        ],
        correctAnswers: [
          "Если все прибывшие в организацию, эксплуатирующую ОПО, горноспасательные отделения привлечены к горноспасательным работам, определенным планом мероприятий по локализации и ликвидации последствий аварий, связанным со спасением людей, застигнутых аварией",
        ],
      },
      {
        code: "20501012",
        text: "В каком из перечисленных случаев в подземных горных выработках горноспасательные работы приостанавливаются, и организуется вывод из зоны аварии людей, задействованных в данных работах?",
        answers: [
          "Если концентрация метана у места тушения пожара на поступающей к очагу пожара или исходящей от пожара вентиляционной струе составляет 0,5 %",
          "Если концентрация сернистого газа в рудничной атмосфере в горных выработках составляет 1 %",
          "Если температура воздуха в горных выработках с непригодной для дыхания атмосферой в зоне высокой температуры повысилась на 1 °C в течение 5 минут",
          "Если рудничная атмосфера на аварийном участке, на котором действует пожар, находится во взрывобезопасном состоянии",
        ],
        correctAnswers: [
          "Если концентрация сернистого газа в рудничной атмосфере в горных выработках составляет 1 %",
        ],
      },
      {
        code: "20501013",
        text: "В каком из перечисленных случаев в подземных горных выработках, в которых имеется взрывоопасная среда при наличии очагов горения или существует угроза прорыва воды (пульпы), работы по поиску и спасению людей не проводятся или прекращаются?",
        answers: [
          "Если рудничная атмосфера в выработках аварийного участка находилась в состоянии, непригодном для дыхания, в течение времени, равного пятикратному сроку времени защитного действия средств индивидуальной защиты органов дыхания",
          "Если в местах предполагаемого нахождения людей в течение не менее 24 часов температура воздуха составляла 50 °C и более",
          "Если в местах предполагаемого нахождения людей горная выработка затоплена водой и из нее есть запасной выход",
          "Если люди, находящиеся в аварийных горных выработках, признаны погибшими в установленном порядке",
        ],
        correctAnswers: [
          "Если люди, находящиеся в аварийных горных выработках, признаны погибшими в установленном порядке",
        ],
      },
      {
        code: "20501014",
        text: "Каким образом устанавливаются порядок и обстоятельства, при которых допускается отстранить руководителя работ по ликвидации аварии и руководителя горноспасательных работ и назначить другое должностного лица?",
        answers: [
          "Распорядительным документом, утверждаемым руководителями ОПО и ПАСС(Ф) соответственно",
          "Распорядительным документом, утверждаемым представителем территориального органа Ростехнадзора",
          "Распорядительным документом, утверждаемым представителем МЧС России",
          "На основании Инструкции по локализации и ликвидации последствий аварий на опасных производственных объектах, на которых ведутся горные работы",
        ],
        correctAnswers: [
          "Распорядительным документом, утверждаемым руководителями ОПО и ПАСС(Ф) соответственно",
        ],
      },
      {
        code: "20501015",
        text: "Кто из перечисленных лиц обеспечивает подачу сжатого воздуха или воды к месту аварии в случаях, предусмотренных планом ликвидации аварий?",
        answers: [
          "Руководитель энергомеханической службы опасного производственного объекта (ОПО)",
          "Начальник службы (участка), обеспечивающей аэрологическую безопасность подземных горных выработок",
          "Начальник участка, на котором произошла авария",
          "Специалист ОПО, ответственный за учет и выдачу индивидуальных головных светильников, самоспасателей, газоанализаторов",
        ],
        correctAnswers: [
          "Руководитель энергомеханической службы опасного производственного объекта (ОПО)",
        ],
      },
      {
        code: "20501016",
        text: "При какой температуре запрещается ведение горноспасательных работ в горных выработках с непригодной для дыхания рудничной атмосферой?",
        answers: [
          "При температуре ниже -20 °C",
          "При температуре ниже -10 °C",
          "При температуре ниже -25 °C",
          "При температуре ниже -15 °C",
        ],
        correctAnswers: ["При температуре ниже -20 °C"],
      },
      {
        code: "20501017",
        text: "Какие из перечисленных обязанностей не должен выполнять руководитель горноспасательных работ? Укажите все правильные ответы.",
        answers: [
          "Организует разработку оперативных планов",
          "Организует ведение оперативного журнала ПАСС(Ф)",
          "Организует оказание первой и медицинской помощи пострадавшим",
          "Устанавливает режим работы и отдыха работников ПАСС(Ф) при ведении горноспасательных работ",
        ],
        correctAnswers: [
          "Организует разработку оперативных планов",
          "Организует оказание первой и медицинской помощи пострадавшим",
        ],
      },
      {
        code: "20501018",
        text: 'Какие задачи должна указывать эксплуатирующая организация в подразделе "Задачи эксплуатирующей организации в области ПБ" при разработке положения о единой системе управления промышленной безопасностью и охраной труда для организаций по добыче (переработке) угля (горючих сланцев)?',
        answers: [
          "Только задачи по выявлению опасных технологий, участков, рабочих мест и предупреждению несчастных случаев;  управлению основными производственными рисками",
          "Только задачи по обеспечению противоаварийной устойчивости и готовности к локализации и ликвидации последствий аварии",
          "Только задачи по финансированию мероприятий по обеспечению безопасной эксплуатации ОПО",
          "Все перечисленные задачи",
        ],
        correctAnswers: ["Все перечисленные задачи"],
      },
      {
        code: "20501019",
        text: "При каком виде аварии члены вспомогательной горноспасательной команды устанавливают связь с застигнутыми аварией людьми, организуют их спасение, предварительно усилив крепь, тем самым исключив возможные обрушения?",
        answers: [
          "При обрушении в горной выработке",
          "При пожаре в тупиковой выработке",
          "При взрыве метана и (или) угольной пыли",
          "При внезапном выбросе угля (породы)",
          "Все ответы неверны",
        ],
        correctAnswers: ["При обрушении в горной выработке"],
      },
      {
        code: "20501020",
        text: "С каким минимальным номинальным временем защитного действия допускается использование дыхательных аппаратов со сжатым воздухом на объектах открытых горных работ?",
        answers: ["1 час", "2 часа", "4 часа", "3 часа"],
        correctAnswers: ["1 час"],
      },
      {
        code: "20501021",
        text: "Какая устанавливается максимальная продолжительность пребывания работников ПАСС(Ф) и членов вспомогательной горноспасательной команды в непригодной для дыхания рудничной атмосфере с применением дыхательных аппаратов?",
        answers: ["4 часа", "5 часов", "2 часа", "3 часа"],
        correctAnswers: ["4 часа"],
      },
      {
        code: "20501022",
        text: "Какое из перечисленных требований к оперативным планам по локализации и ликвидации последствий аварий указано верно?",
        answers: [
          "Оперативный план должен содержать ссылки на пункты ранее разработанных оперативных планов",
          "Оперативный план разрабатывается на основании поступающей на контрольный пункт информации, анализа аварийной обстановки и прогноза развития аварии",
          "Последующий оперативный план должен разрабатываться в случаях, когда мероприятия предыдущего оперативного плана не реализованы",
          "Оперативный план должен разрабатываться после окончания ведения горноспасательных работ",
        ],
        correctAnswers: [
          "Оперативный план разрабатывается на основании поступающей на контрольный пункт информации, анализа аварийной обстановки и прогноза развития аварии",
        ],
      },
      {
        code: "20501023",
        text: "В каком масштабе должны составляться графические материалы для твердых полезных ископаемых?",
        answers: [
          "Не мельче 1 : 15000",
          "Не мельче 1 : 25000",
          "Не мельче 1 : 10000",
          "Не мельче 1 : 35000",
        ],
        correctAnswers: ["Не мельче 1 : 10000"],
      },
      {
        code: "20501024",
        text: "Кто осуществляет руководство работами по локализации и ликвидации последствий аварии?",
        answers: [
          "Технический руководитель (главный инженер) ОПО",
          "Руководитель горноспасательных работ (РГСР) - должностное лицо ПАСС(Ф), обслуживающей ОПО, назначенное распорядительным документом руководителя ПАСС(Ф)",
          "Руководитель угольного разреза",
          "Представитель территориального органа Ростехнадзора",
        ],
        correctAnswers: ["Технический руководитель (главный инженер) ОПО"],
      },
      {
        code: "20501025",
        text: "Какая документация должна находиться на командном пункте?",
        answers: [
          "Только оперативные журналы ОПО и ПАСС(Ф)",
          "Только план ликвидации аварий (ПЛА) и оперативные планы",
          "Только текстовая и графическая документация, разрабатываемая в период локализации и ликвидации последствий аварии",
          "Вся перечисленная документация",
        ],
        correctAnswers: ["Вся перечисленная документация"],
      },
      {
        code: "20501026",
        text: "На какие перечисленные виды работ не составляются планы и схемы горных работ?",
        answers: [
          "На вскрышные работы",
          "На рекультивационные работы",
          "На работы, связанные со вторичной переработкой минерального сырья",
          "На маркшейдерские работы",
        ],
        correctAnswers: [
          "На работы, связанные со вторичной переработкой минерального сырья",
        ],
      },
      {
        code: "20501027",
        text: "Кем утверждаются план и схема развития горных работ?",
        answers: [
          "Руководителем органа государственного горного надзора",
          "Руководителем организации - пользователя недр",
          "Руководителем органа исполнительной власти субъекта Российской Федерации",
          "Техническим руководителем организации - пользователя недр",
        ],
        correctAnswers: ["Руководителем организации - пользователя недр"],
      },
      {
        code: "20501028",
        text: "Какие разделы не включает в себя Положение о системе управления промышленной безопасностью и охраной труда (СУПБиОТ)?",
        answers: [
          '"Система управления промышленной безопасностью"',
          '"Система управления охраной труда"',
          '"Проведение расследований причин инцидентов"',
        ],
        correctAnswers: ['"Проведение расследований причин инцидентов"'],
      },
      {
        code: "20501029",
        text: "В каком приоритетном порядке предварительно определяется вид происшедшей аварии при отсутствии информации, позволяющей достоверно определить ее вид, в подземных горных выработках?",
        answers: [
          "Внезапный выброс, взрыв, пожар, горный удар, обрушение",
          "Горный удар, обрушение, взрыв, внезапный выброс, пожар",
          "Взрыв, внезапный выброс, пожар, горный удар, обрушение",
          "Пожар, взрыв, внезапный выброс, горный удар, обрушение",
        ],
        correctAnswers: [
          "Взрыв, внезапный выброс, пожар, горный удар, обрушение",
        ],
      },
      {
        code: "20501030",
        text: "Из какого минимального количества человек должно состоять горноспасательное отделение или отделение вспомогательной горноспасательной команды при ведении горноспасательных работ в непригодной для дыхания рудничной атмосфере при работе на открытых горных выработках?",
        answers: [
          "Из 5 человек",
          "Из 2 человек",
          "Из 3 человек",
          "Из 6 человек",
          "Все ответы неверны",
        ],
        correctAnswers: ["Из 3 человек"],
      },
      {
        code: "20501031",
        text: "В отношении каких видов горных работ планы и схемы развития горных работ не составляются?",
        answers: [
          "Вскрышных и подготовительных",
          "Работ по добыче полезных ископаемых и первичной переработке минерального сырья",
          "Рекультивационных и маркшейдерских",
          "Работ по обустройству транспортной структуры места проведения горных работ",
        ],
        correctAnswers: [
          "Работ по обустройству транспортной структуры места проведения горных работ",
        ],
      },
      {
        code: "20501032",
        text: "Что из перечисленного должны обеспечивать мероприятия по выполнению основных требований по безопасному ведению работ, связанных с пользованием недрами?",
        answers: [
          "Только защиту объектов ведения горных работ и месторождений полезных ископаемых от затопления, обводнения, взрывов, пожаров, горных ударов",
          "Только рекультивацию нарушенных горными работами земель (при разработке месторождений твердых полезных ископаемых)",
          "Только применение средств и технологий, повышающих безопасное ведение горных работ и уровень извлечения полезных ископаемых",
          "Все перечисленное, включая ликвидацию (консервацию) отработанных горных выработок, скважин, блоков, горизонтов, объектов обустройства и иных объектов, связанных с горным производством",
        ],
        correctAnswers: [
          "Все перечисленное, включая ликвидацию (консервацию) отработанных горных выработок, скважин, блоков, горизонтов, объектов обустройства и иных объектов, связанных с горным производством",
        ],
      },
      {
        code: "20501033",
        text: "Какие параметры эксплуатации объектов ведения горных работ планами и схемами развития горных работ не определяются?",
        answers: [
          "Направления развития горных работ",
          "Условия, технические и технологические решения при эксплуатации объектов ведения горных работ",
          "Объемы добычи и переработки полезных ископаемых",
          "Штатная численность сотрудников объекта ведения горных работ",
        ],
        correctAnswers: [
          "Штатная численность сотрудников объекта ведения горных работ",
        ],
      },
      {
        code: "20501034",
        text: "Какие мероприятия должны включаться в пояснительную записку планов развития горных работ?",
        answers: [
          "Консервация и (или) ликвидация горных выработок, в том числе скважин, рекультивация нарушенных горными работами земель (при разработке месторождений твердых полезных ископаемых при наличии)",
          "Организация наблюдений за состоянием горных отводов",
          "Достоверный учет количества добываемого минерального сырья в общем объеме горной массы, в том числе при маркшейдерском, весовом, метрологическом контроле (при разработке месторождений твердых полезных ископаемых)",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20501035",
        text: "Что должны отображать графические материалы?",
        answers: [
          "Только границы участка недр, установленные при предоставлении лицензии",
          "Только границы опасных зон и зон с особыми условиями использования территорий",
          "Только контуры горных выработок",
          "Только ситуацию местности на площади горного отвода",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20501036",
        text: "В какой срок пользователи недр представляют заявление о согласовании планов и схем развития горных работ в орган государственного горного надзора?",
        answers: [
          "До 1 августа текущего года (года, предшествующего планируемому)",
          "До 15 августа текущего года (года, предшествующего планируемому)",
          "До 1 сентября текущего года (года, предшествующего планируемому)",
          "До 15 сентября текущего года (года, предшествующего планируемому)",
        ],
        correctAnswers: [
          "До 1 сентября текущего года (года, предшествующего планируемому)",
        ],
      },
      {
        code: "20501037",
        text: "Из чего должны состоять графические материалы планов (схем) развития горных работ в зависимости от видов горных работ и видов полезных ископаемых?",
        answers: [
          "Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, схем вскрытия месторождения",
          "Из геологических и структурных карт, характерных разрезов, проекций, схем",
          "Из обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок",
          "Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок, геологических и структурных карт, характерных разрезов, проекций, схем",
        ],
        correctAnswers: [
          "Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок, геологических и структурных карт, характерных разрезов, проекций, схем",
        ],
      },
      {
        code: "20501038",
        text: "В каком виде подготавливаются схемы развития горных работ в отношении вскрышных, подготовительных, рекультивационных работ, а также работ по добыче полезных ископаемых и связанной с ней первичной переработке минерального сырья, содержащие графическую часть и пояснительную записку с табличными материалами?",
        answers: [
          "Только в виде схем эксплуатации объектов недропользования и (или) их отдельных участков",
          "Только в виде схем эксплуатации объектов первичной переработки (подготовки) минерального сырья (для месторождений твердых полезных ископаемых, включая общераспространенные)",
          "Только в виде схем эксплуатации объектов обустройства и схемы подготовки углеводородов (для месторождений углеводородного сырья)",
          "Только в виде схем эксплуатации объектов и водозаборных сооружений в отношении месторождений минеральных, теплоэнергетических (термальных), технических, промышленных вод (далее - подземные воды)",
          "В виде всех перечисленных схем",
        ],
        correctAnswers: ["В виде всех перечисленных схем"],
      },
      {
        code: "20501039",
        text: "Кем должны быть подписаны планы (схемы) развития горных работ, направляемые пользователем недр в электронном виде?",
        answers: [
          "Комиссией пользователя недр (подрядной организации)",
          "Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом",
          "Техническим руководителем пользователя недр (подрядной организации)",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом",
        ],
      },
      {
        code: "20501040",
        text: "Какие данные не должны указываться в штампе графических материалов планов развития горных работ?",
        answers: [
          "Подписи лиц (с указанием должности), ответственных за руководство геологическими и маркшейдерскими работами",
          "Наименования организации, объекта недропользования (участка недр)",
          "Дата составления",
          "Все ответы неверны",
          "Название графического документа",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20501041",
        text: "С какой периодичностью должно осматриваться каждое рабочее место?",
        answers: [
          "В течение смены - горным мастером, а в течение суток - начальником участка или его заместителем",
          "В течение суток - горным мастером, 1 раз в 2 дня - начальником участка или его заместителем",
          "1 раз в 2 дня - горным мастером, 1 раз в неделю - начальником участка или его заместителем",
          "1 раз в неделю - горным мастером, 1 раз в 2 недели - начальником участка или его заместителем",
        ],
        correctAnswers: [
          "В течение смены - горным мастером, а в течение суток - начальником участка или его заместителем",
        ],
      },
      {
        code: "20501042",
        text: "С какой периодичностью должно осматриваться каждое рабочее место начальником участка или его заместителем?",
        answers: [
          "В течение смены",
          "В течение суток",
          "1 раз в 2 дня",
          "1 раз в 2 недели",
        ],
        correctAnswers: ["В течение суток"],
      },
      {
        code: "20501043",
        text: "Каков максимальный угол наклона предусмотрен для лестниц, предназначенных для сообщения между уступами угольного разреза?",
        answers: ["75°", "70°", "65°", "60°", "55°"],
        correctAnswers: ["60°"],
      },
      {
        code: "20501044",
        text: "С каким максимальным углом наклона должны устраиваться съезды, предназначенные для сообщения между уступами угольного разреза?",
        answers: ["30°", "25°", "20°", "15°", "10°"],
        correctAnswers: ["20°"],
      },
      {
        code: "20501045",
        text: "Какой минимальной ширины должны быть маршевые лестницы, устраиваемые на высоте более 10 м для сообщения между уступами разреза?",
        answers: ["0,6 м", "0,7 м", "0,8 м", "0,9 м", "1,0 м"],
        correctAnswers: ["0,8 м"],
      },
      {
        code: "20501046",
        text: "Какое максимальное расстояние допускается между горизонтальными площадками маршевых лестниц, устраиваемых на высоте более 10 м для сообщения между уступами разреза?",
        answers: ["30 м", "20 м", "15 м", "10 м"],
        correctAnswers: ["15 м"],
      },
      {
        code: "20501047",
        text: "Какой должна быть минимальная ширина переходных мостиков через ленточные конвейеры?",
        answers: ["1,2 м", "1,0 м", "0,8 м", "0,7 м"],
        correctAnswers: ["0,8 м"],
      },
      {
        code: "20501048",
        text: "Какой должна быть минимальная высота перил переходных мостиков через ленточные конвейеры?",
        answers: ["1,2 м", "1,1 м", "0,8 м", "0,7 м"],
        correctAnswers: ["1,1 м"],
      },
      {
        code: "20501049",
        text: "Что должно входить в состав планов и схем развития горных работ?",
        answers: [
          "Графическая часть и пояснительная записка с табличными материалами",
          "Фотографии мест дальнейшего производства работ",
          "Список с указанием паспортных данных каждого работника",
          "Наряд-допуск на выполнение работ",
        ],
        correctAnswers: [
          "Графическая часть и пояснительная записка с табличными материалами",
        ],
      },
      {
        code: "20501050",
        text: "На основе чего составляются планы и схемы развития горных работ?",
        answers: [
          "На основе локального плана проведения работ",
          "На основе технического регламента",
          "На основе рекомендаций территориальных комиссий по запасам полезных ископаемых Минприроды России",
          "На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами",
        ],
        correctAnswers: [
          "На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами",
        ],
      },
      {
        code: "20501051",
        text: "В какой период осуществляется рассмотрение планов развития горных работ?",
        answers: [
          "С 10 января по 1 марта планируемого года",
          "С 1 по 30 декабря года, предшествующего планируемому",
          "С 20 января по 25 марта планируемого года",
          "С 20 сентября по 25 декабря года, предшествующего планируемому",
        ],
        correctAnswers: [
          "С 20 сентября по 25 декабря года, предшествующего планируемому",
        ],
      },
      {
        code: "20501052",
        text: "Кто из перечисленных лиц подписывает титульный лист плана (схемы) развития горных работ?",
        answers: [
          "Лица, ответственные за руководство горными работами пользователя недр",
          "Лица, ответственные за руководство маркшейдерскими работами",
          "Лица, ответственные за руководство горными, геологическими и маркшейдерскими работами пользователя недр и (или) привлекаемые пользователем недр на договорной основе организации для осуществления работ, связанных с пользованием недрами",
          "Лица, ответственные за руководство геологическими работами, и представитель Ростехнадзора",
        ],
        correctAnswers: [
          "Лица, ответственные за руководство горными, геологическими и маркшейдерскими работами пользователя недр и (или) привлекаемые пользователем недр на договорной основе организации для осуществления работ, связанных с пользованием недрами",
        ],
      },
      {
        code: "20501053",
        text: "В какой срок планы и схемы развития горных работ направляются пользователем недр в орган государственного горного надзора для рассмотрения?",
        answers: [
          "За 15 дней до назначенной в графике даты рассмотрения планов и схем развития горных работ",
          "За 5 дней до назначенной в графике даты рассмотрения планов и схем развития горных работ",
          "В течение 15 дней с назначенной в графике даты рассмотрения планов и схем развития горных работ",
          "В назначенный в графике день рассмотрения планов и схем развития горных работ",
        ],
        correctAnswers: [
          "За 15 дней до назначенной в графике даты рассмотрения планов и схем развития горных работ",
        ],
      },
      {
        code: "20501054",
        text: "Что из перечисленного не является основанием для принятия решения об отказе в согласовании плана или схемы развития горных работ?",
        answers: [
          "Отсутствие геологического и маркшейдерского обеспечения горных работ",
          "Выявление недостоверных сведений в представленных документах",
          "Наличие в представленной документации сведений о планировании производства работ подрядными организациями",
          "Отсутствие обоснования соблюдения условий безопасного недропользования",
        ],
        correctAnswers: [
          "Наличие в представленной документации сведений о планировании производства работ подрядными организациями",
        ],
      },
      {
        code: "20501055",
        text: "Какое из приведенных положений требований безопасности указаны неверно?",
        answers: [
          "На угольном разрезе запрещается нахождение людей в опасной зоне работающих механизмов",
          "При остановке работ на угольном разрезе запрещается нахождение на его территории лиц, не связанных с обеспечением его жизнедеятельности",
          "В случае невозможности произвести ликвидацию заколов или оборку уступа (борта) все работы в опасной зоне должны быть остановлены",
          "При невозможности вывода техники в случае угрозы ее повреждения, техника должна быть ограждена и установлены знаки, предупреждающие об опасности",
        ],
        correctAnswers: [
          "При невозможности вывода техники в случае угрозы ее повреждения, техника должна быть ограждена и установлены знаки, предупреждающие об опасности",
        ],
      },
      {
        code: "20501056",
        text: "С кем согласовываются графики проведения учебных тревог на угольном разрезе?",
        answers: [
          "С руководителем территориального органа Ростехнадзора",
          "С руководителем органа местного самоуправления, на территории которого располагается разрез",
          "С руководителем профессионального аварийно-спасательного формирования (ПАСС(Ф)), обслуживающего угольный разрез",
          "С руководителем профсоюзной организации угольного разреза",
        ],
        correctAnswers: [
          "С руководителем профессионального аварийно-спасательного формирования (ПАСС(Ф)), обслуживающего угольный разрез",
        ],
      },
      {
        code: "20501057",
        text: "В соответствии с каким документом должны осуществляться горные работы по проведению траншей, разработке уступов, отсыпке отвалов?",
        answers: [
          "В соответствии с локальными проектами производства работ (паспортами), утвержденными техническим руководителем разреза",
          "В соответствии с утвержденными планами производства работ, согласованными c органами исполнительной власти муниципального образования, на территории которого эксплуатируется разрез",
          "В соответствии с планами по разработке разрезов, согласованными с Ростехнадзором",
          "В соответствии с планами работ по разработке разрезов, согласованными с аварийно-спасательными формированиями",
          "В соответствии с утвержденной техническим руководителем (главным инженером) угольного разреза документацией на производство работ",
        ],
        correctAnswers: [
          "В соответствии с утвержденной техническим руководителем (главным инженером) угольного разреза документацией на производство работ",
        ],
      },
      {
        code: "20501058",
        text: "Что не учитывается проектом при определении высоты уступа разреза?",
        answers: [
          "Результаты исследований физико-механических свойств горных пород и полезного ископаемого",
          "Горно-геологические условия залегания горных пород и полезного ископаемого",
          "Параметры оборудования",
          "Квалификация персонала угольного разреза",
        ],
        correctAnswers: ["Квалификация персонала угольного разреза"],
      },
      {
        code: "20501059",
        text: "В каком из перечисленных случаев основным критерием определения безопасной высоты уступа являются расчеты с учетом траектории движения рабочего органа (ковша) экскаватора (погрузчика)?",
        answers: [
          "При применении гидравлических экскаваторов и погрузчиков",
          "При применении канатных экскаваторов",
          "При применении экскаваторов с удлиненным рабочим оборудованием",
          "При разработке уступа вручную",
          "При разработке пород с применением буровзрывных работ",
        ],
        correctAnswers: [
          "При применении гидравлических экскаваторов и погрузчиков",
        ],
      },
      {
        code: "20501060",
        text: "Что из перечисленного не должна превышать высота уступа при применении канатных экскаваторов? Выберите два правильных варианта ответов.",
        answers: [
          "Максимальную высоту черпания экскаватора",
          "Высоту траектории движения рабочего органа (ковша) экскаватора (погрузчика)",
          "Высоту или глубину черпания драглайна, многоковшовых цепных и роторных экскаваторов",
          "Полуторную высоту черпания экскаватора",
        ],
        correctAnswers: [
          "Максимальную высоту черпания экскаватора",
          "Высоту или глубину черпания драглайна, многоковшовых цепных и роторных экскаваторов",
        ],
      },
      {
        code: "20501061",
        text: "Что из перечисленного должна обеспечивать высота уступа для экскаваторов с удлиненным рабочим оборудованием?",
        answers: [
          "Видимость всех формируемых временно неработающих бортов",
          "Видимость транспортных сосудов из кабины машиниста экскаватора",
          "Видимость соседних рабочих уступов",
          "Возможность контроля ширины рабочих площадок и углов откоса уступов",
        ],
        correctAnswers: [
          "Видимость транспортных сосудов из кабины машиниста экскаватора",
        ],
      },
      {
        code: "20501062",
        text: "Какой должна быть максимальная высота уступа при разработке вручную рыхлых устойчивых плотных пород?",
        answers: ["3 м", "4 м", "5 м", "6 м", "7 м"],
        correctAnswers: ["6 м"],
      },
      {
        code: "20501063",
        text: "Какой должна быть максимальная высота уступа при разработке вручную рыхлых неустойчивых плотных пород?",
        answers: ["3 м", "4 м", "5 м", "6 м"],
        correctAnswers: ["3 м"],
      },
      {
        code: "20501064",
        text: "Что из перечисленного допускается при разработке пород с применением буровзрывных работ при условии, что высота забоя по развалу не превышает максимальную высоту черпания экскаватора?",
        answers: [
          "Только увеличение высоты уступа до полуторной высоты черпания экскаватора",
          "Только разделение развала по высоте на слои (подуступы)",
          "Только разработка мероприятий по безопасному обрушению козырьков и нависей",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20501065",
        text: "Каким должен быть максимальный угол откосов рабочих уступов при работе экскаваторов типа механической лопаты, драглайна и роторных экскаваторов?",
        answers: ["70°", "75°", "80°", "85°"],
        correctAnswers: ["80°"],
      },
      {
        code: "20501066",
        text: "В каком случае углы откосов рабочих уступов не должны превышать угла естественного откоса разрабатываемых пород? Выберите два правильных варианта ответа.",
        answers: [
          "При работе экскаваторов типа механической лопаты и гидравлических экскаваторов",
          "При работе многоковшовых цепных экскаваторов нижним черпанием",
          "При работе погрузчиков, драглайнов и роторных экскаваторов",
          "При разработке вручную рыхлых и сыпучих пород",
        ],
        correctAnswers: [
          "При работе многоковшовых цепных экскаваторов нижним черпанием",
          "При разработке вручную рыхлых и сыпучих пород",
        ],
      },
      {
        code: "20501067",
        text: "Каким образом устанавливаются предельные углы откосов уступов и бортов угольного разреза (углы устойчивого борта), в том числе временно консервируемых участков бортов?",
        answers: [
          "Техническим проектом разработки месторождения",
          "Планом мероприятий по локализации и ликвидации последствий аварий угольного разреза (ПЛА)",
          "Локальным проектом, утвержденным техническим руководителем (главным инженером) угольного разреза",
        ],
        correctAnswers: ["Техническим проектом разработки месторождения"],
      },
      {
        code: "20501068",
        text: "По каким данным могут быть скорректированы в процессе эксплуатации предельные углы откосов уступов и бортов угольного разреза (углы устойчивого борта), в том числе временно консервируемых участков бортов? Выберите два правильных варианта ответов.",
        answers: [
          "По данным геолого-маркшейдерской службы угольного разреза",
          "По результатам дополнительных изысканий инженерно-геологических условий массива борта угольного разреза и отвалам",
          "По требованиям дополнений к технической документации ведения горных работ на угольных разрезах, если это не выполнено в проекте на отработку месторождения",
          "По данным планов горных работ масштабов 1 : 5000 или 1 : 2000",
        ],
        correctAnswers: [
          "По данным геолого-маркшейдерской службы угольного разреза",
          "По результатам дополнительных изысканий инженерно-геологических условий массива борта угольного разреза и отвалам",
        ],
      },
      {
        code: "20501069",
        text: "Каким документом определяется ширина рабочих площадок с учетом их назначения, а также расположения на них горнотранспортного оборудования, транспортных коммуникаций, линий электроснабжения и связи?",
        answers: [
          "Локальным проектом, утвержденным техническим руководителем (главным инженером) угольного разреза",
          "Техническим проектом разработки месторождения и уточняется в документации на производство работ",
          "Планом мероприятий по локализации и ликвидации последствий аварий угольного разреза (ПЛА)",
        ],
        correctAnswers: [
          "Техническим проектом разработки месторождения и уточняется в документации на производство работ",
        ],
      },
      {
        code: "20501070",
        text: "Каким должно быть расстояние от нижней бровки уступа (развала горной массы) и от верхней бровки уступа до оси ближайшего железнодорожного пути?",
        answers: [
          "Не менее 2,5 м",
          "Не менее 2,2 м",
          "Не менее 1,8 м",
          "Не менее 1,5 м",
        ],
        correctAnswers: ["Не менее 2,5 м"],
      },
      {
        code: "20501071",
        text: "Какое из требований к ведению горных работ по проведению траншей, разработке уступов и отсыпке отвалов указано неверно?",
        answers: [
          "Формирование временно нерабочих бортов угольного разреза и возобновление горных работ на них должно производиться по проектам производства работ, предусматривающим необходимые меры безопасности",
          "Расстояние между нижними бровками откосов уступа отвала до оси железнодорожного пути или оси конвейера устанавливается проектом и должно быть не менее 6 м",
          "Расстояние между смежными бермами при погашении уступов и постановке их в предельное положение, ширина, конструкция и порядок обслуживания предохранительных берм должны определяться проектом с учетом обеспечения устойчивости конструкции борта угольного разреза, безопасной механизированной их очистки",
          "В процессе эксплуатации параметры уступов и предохранительных берм должны при необходимости уточняться по результатам исследований физико-механических свойств горных пород и должны быть не менее установленных проектом",
        ],
        correctAnswers: [
          "Расстояние между нижними бровками откосов уступа отвала до оси железнодорожного пути или оси конвейера устанавливается проектом и должно быть не менее 6 м",
        ],
      },
      {
        code: "20501072",
        text: "Какое из перечисленных требований безопасности предъявляется к выполнению работ по ручной оборке уступов от нависей и козырьков?",
        answers: [
          "Ручная оборка уступов ложна проводиться в дневное время с применением соответствующих мер безопасности под непосредственным наблюдением руководителя смены или бригадира",
          "Ручная оборка уступов не допускается",
          "Ручную оборку допускается проводить по наряду-допуску под непосредственным наблюдением руководителя смены или бригадира",
          "Ручную оборку допускается проводить при наличии распоряжения под непосредственным наблюдением руководителя смены или бригадира",
        ],
        correctAnswers: [
          "Ручную оборку допускается проводить по наряду-допуску под непосредственным наблюдением руководителя смены или бригадира",
        ],
      },
      {
        code: "20501073",
        text: "Какое минимальное расстояние по горизонтали должно быть между рабочими местами, расположенными на двух смежных по вертикали уступах, при ручной разработке?",
        answers: ["4 м", "6 м", "8 м", "10 м", "12 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "20501074",
        text: "Каким должно быть минимальное расстояние по горизонтали между рабочими местами или механизмами, расположенными на двух смежных по вертикали уступах, при экскаваторной разработке?",
        answers: [
          "Равным максимальному радиусу черпания",
          "Равным полуторной сумме максимальных радиусов черпания",
          "Равным двойной сумме максимальных радиусов черпания",
          "Равным утроенной сумме максимальных радиусов черпания",
        ],
        correctAnswers: [
          "Равным полуторной сумме максимальных радиусов черпания",
        ],
      },
      {
        code: "20501075",
        text: "Каким должно быть минимальное расстояние по горизонтали между экскаваторами, расположенными на одном горизонте?",
        answers: [
          "Равным сумме их наибольших радиусов действия",
          "Равным полуторной сумме их наибольших радиусов действия",
          "Равным двойной сумме их наибольших радиусов действия",
          "Равным утроенной сумме их наибольших радиусов действия",
        ],
        correctAnswers: ["Равным сумме их наибольших радиусов действия"],
      },
      {
        code: "20501076",
        text: "Каким должно быть минимальное расстояние по горизонтали между экскаваторами и драглайнами (с учетом величины заброса ковша) или драглайнами (с учетом величины заброса ковшей), расположенными на одном горизонте?",
        answers: [
          "Равным сумме их наибольших радиусов действия",
          "Равным полуторной сумме их наибольших радиусов действия",
          "Равным двойной сумме их наибольших радиусов действия",
          "Равным утроенной сумме их наибольших радиусов действия",
        ],
        correctAnswers: ["Равным сумме их наибольших радиусов действия"],
      },
      {
        code: "20501077",
        text: "По какому из перечисленных проектов следует проводить горные работы вблизи затопленных выработок или водоемов ниже зеркала воды при их максимальном наполнении?",
        answers: [
          "По техническому проекту разработки месторождения и нормативными требованиями к данным условиям разработки с учетом оставления целиков для предотвращения прорыва воды",
          "По проекту, предусматривающему сооружение вокруг       затопленных выработок или водоемов       обваловки",
          "По проекту, предусматривающему сооружение в пониженных местах дамб",
          "По проекту, предусматривающему установку откачивающих насосов достаточной производительности",
        ],
        correctAnswers: [
          "По техническому проекту разработки месторождения и нормативными требованиями к данным условиям разработки с учетом оставления целиков для предотвращения прорыва воды",
        ],
      },
      {
        code: "20501078",
        text: "Что из перечисленного должно быть обозначено предупредительными знаками, ограждениями или предохранительными валами?",
        answers: [
          "Только водоемы и затопленные выработки",
          "Только очаги самонагревания породных отвалов",
          "Только участки высокого борта и горные выработки с признаками деформации",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20501079",
        text: "Что из перечисленного используется для обозначения границ опасных зон на местности в процессе ведения горных работ на разрезе?",
        answers: [
          "Только предупредительные знаки",
          "Только ограждения",
          "Только предохранительные валы",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20501080",
        text: "Какие из перечисленных участков ведения открытых горных работ должны относиться к зонам, опасным по геомеханическим условиям?",
        answers: [
          "Только участок повышенной водообильности бортов и отвалов, сложенных мягкими связными и твердыми глинистыми, а также рыхлыми несвязными или слабосцементированными породами",
          "Только участок бортов карьера и откосов отвалов, на которых обнаружены признаки деформаций (трещины, заколы или просадки)",
          "Только участок борта, нагруженные отвалами, размещенными в пределах призмы возможного обрушения",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20501081",
        text: "Какая из перечисленных зон при ведении открытых горных работ не должна относиться к зонам, опасным по геомеханическим условиям?",
        answers: [
          "Горный массив с наклонным и пологим залеганием слоистости в сторону выработанного пространства при наличии в призме возможного обрушения тектонических трещин, секущих уступ, протяженностью более 0,25 - 0,30 высоты уступа или ослабленных поверхностей",
          "Участок повышенной водообильности бортов и отвалов, сложенных мягкими связными и твердыми глинистыми, а также рыхлыми несвязными или слабосцементированными породами",
          "Участок бортов карьера и откосов отвалов, на которых обнаружены признаки деформаций (трещины, заколы или просадки)",
          "Участок борта, нагруженный отвалами, размещенными в пределах призмы возможного обрушения",
          "Участок экзогенных пожаров",
        ],
        correctAnswers: ["Участок экзогенных пожаров"],
      },
      {
        code: "20501082",
        text: "На каком минимальном безопасном расстоянии от верхней бровки уступа, определяемом расчетами или проектом, до ближайшей точки опоры станка на спланированной площадке должен устанавливаться буровой станок?",
        answers: ["1 м", "1,5 м", "2 м", "3 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "20501083",
        text: "Под каким углом к бровке уступа должна быть продольная ось бурового станка при бурении первого ряда скважин?",
        answers: ["30°", "45°", "60°", "90°"],
        correctAnswers: ["90°"],
      },
      {
        code: "20501084",
        text: "Что не соответствует требованиям по выбору и контролю за состоянием подъемного каната бурового станка?",
        answers: [
          "Подъемный канат бурового станка должен рассчитываться на максимальную нагрузку и иметь трехкратный запас прочности",
          "Не менее одного раза в неделю механик участка или другое назначенное лицо должны проводить наружный осмотр каната и делать запись о результатах осмотра",
          "При наличии в подъемном канате более 10 % порванных проволок на длине шага свивки его следует заменить",
          "Выступающие концы проволок должны быть обрезаны",
        ],
        correctAnswers: [
          "Подъемный канат бурового станка должен рассчитываться на максимальную нагрузку и иметь трехкратный запас прочности",
        ],
      },
      {
        code: "20501085",
        text: "Какой должна быть минимальная ширина рабочей бермы при бурении шпуров перфораторами и электросверлами?",
        answers: ["3,0 м", "3,5 м", "4,0 м", "4,5 м"],
        correctAnswers: ["4,0 м"],
      },
      {
        code: "20501086",
        text: "Что из перечисленного влияет на результаты расчетов, устанавливающих проектные высоту отвала и отвальных ярусов, углы откоса и призмы возможного обрушения, скорость продвижения фронта отвальных работ?",
        answers: [
          "Только физико-механические свойства пород отвала и его основания",
          "Только способы отвалообразования",
          "Только рельеф местности и несущая способность нагруженных отвалов",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20501087",
        text: "Какое минимальное расстояние должно быть от оси железнодорожного пути до бровки плужного отвала после каждой передвижки путей при грузоподъемности думпкара до 60 т?",
        answers: ["1300 мм", "1400 мм", "1500 мм", "1600 мм", "1700 мм"],
        correctAnswers: ["1600 мм"],
      },
      {
        code: "20501088",
        text: "Какое минимальное расстояние должно быть от оси железнодорожного пути до бровки плужного отвала после каждой передвижки путей при грузоподъемности думпкара более 60 т?",
        answers: ["1500 мм", "1600 мм", "1700 мм", "1800 мм", "1900 мм"],
        correctAnswers: ["1800 мм"],
      },
      {
        code: "20501089",
        text: "Какое минимальное расстояние должно быть от оси железнодорожного пути до верхней бровки на отвалах, оборудованных одноковшовыми экскаваторами, в месте разгрузки думпкаров для нормальной колеи?",
        answers: ["1300 мм", "1400 мм", "1500 мм", "1600 мм", "1700 мм"],
        correctAnswers: ["1600 мм"],
      },
      {
        code: "20501090",
        text: "Какое минимальное расстояние должно быть от оси железнодорожного пути до верхней бровки на отвалах, оборудованных одноковшовыми экскаваторами, в месте разгрузки думпкаров для колеи 900 мм?",
        answers: ["1300 мм", "1400 мм", "1500 мм", "1600 мм", "1700 мм"],
        correctAnswers: ["1300 мм"],
      },
      {
        code: "20501091",
        text: "Какое превышение должен иметь внешний рельс разгрузочного пути по отношению к внутреннему?",
        answers: [
          "На 100 - 150 мм",
          "На 80 - 100 мм",
          "На 60 - 80 мм",
          "На 40 - 60 мм",
        ],
        correctAnswers: ["На 100 - 150 мм"],
      },
      {
        code: "20501092",
        text: "На какое минимальное расстояние от оси пути следует выносить указатели путевого заграждения, располагая их со стороны машиниста локомотива?",
        answers: ["На 1,5 м", "На 2,5 м", "На 3,5 м", "Не нормируется"],
        correctAnswers: ["На 2,5 м"],
      },
      {
        code: "20501093",
        text: "На какой высоте от оси пути следует устанавливать указатели путевого заграждения, располагая их со стороны машиниста локомотива?",
        answers: [
          "На высоте 1,5 м",
          "На высоте 2,0 м",
          "На высоте 2,5 м",
          "Не нормируется",
        ],
        correctAnswers: ["На высоте 1,5 м"],
      },
      {
        code: "20501094",
        text: "Что из перечисленного не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом?",
        answers: [
          "При разгрузке думпкаров люди должны находиться вне зоны развала горной массы. Вдоль железнодорожного пути в месте разгрузки состава с противоположной от приямка стороны должна быть спланирована площадка для обслуживающего состав персонала",
          "Для безопасной разгрузки думпкаров, груженных смерзающимися, налипающими породами и крупногабаритными кусками, должны быть разработаны мероприятия, утвержденные техническим руководителем (главным инженером) угольного разреза",
          "Опрокидывание кузовов думпкаров и возвращение их в транспортное положение после разгрузки должны производиться без помощи подставок, шпал, рельсов",
          "Очистка думпкаров вручную на приямках допускается в случаях неисправности механизмов по очистке при соблюдении специально разработанных мер безопасности, утвержденных техническим руководителем разреза",
        ],
        correctAnswers: [
          "Очистка думпкаров вручную на приямках допускается в случаях неисправности механизмов по очистке при соблюдении специально разработанных мер безопасности, утвержденных техническим руководителем разреза",
        ],
      },
      {
        code: "20501095",
        text: "Что из перечисленного не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом?",
        answers: [
          "Горнотранспортное оборудование должно разгружаться на отвале в местах, предусмотренных проектом, вне призмы обрушения (сползания) породы",
          "Размеры призмы обрушения породы устанавливаются визуально водителями автомобилей",
          "На отвалах должны устанавливаться схемы движения транспортных средств",
          "Зона разгрузки должна быть обозначена с обеих сторон знаками в виде изображения автосамосвала с поднятым кузовом с указателями направления разгрузки",
        ],
        correctAnswers: [
          "Размеры призмы обрушения породы устанавливаются визуально водителями автомобилей",
        ],
      },
      {
        code: "20501096",
        text: "В каком случае при работе на отвале нарушены требования Правил безопасности при разработке угольных месторождений открытым способом?",
        answers: [
          "Подача автосамосвала на разгрузку должна осуществляться задним ходом",
          "Работа бульдозера должна производиться перпендикулярно верхней бровке откоса площадки",
          "Движение бульдозера во время работы должно производиться только ножом вперед с одновременным формированием перед отвалом бульдозера предохранительного вала в соответствии с проектом",
          "При разгрузке автосамосвалов в пределах призмы обрушения при подработанном экскаватором откосе яруса допускается установка автосамосвалов перпендикулярно верхней бровке откоса",
        ],
        correctAnswers: [
          "При разгрузке автосамосвалов в пределах призмы обрушения при подработанном экскаватором откосе яруса допускается установка автосамосвалов перпендикулярно верхней бровке откоса",
        ],
      },
      {
        code: "20501097",
        text: "Какое требование Правил безопасности при разработке угольных месторождений открытым способом к проведению разгрузочных работ в одном секторе указано верно?",
        answers: [
          "Работа на отвале и перегрузочном пункте должна производиться в соответствии с проектом ведения работ и регулироваться знаками и аншлагами",
          "Расстояние между стоящими на разгрузке, проезжающими транспортными средствами и работающим бульдозером должно быть не менее 3 м",
          "Одновременная работа в одном секторе на отвале бульдозера и автосамосвалов, а на перегрузочном пункте - бульдозера, автосамосвала и экскаватора (погрузчика) должна проводиться по утвержденному регламенту",
          "Все перечисленные",
        ],
        correctAnswers: [
          "Работа на отвале и перегрузочном пункте должна производиться в соответствии с проектом ведения работ и регулироваться знаками и аншлагами",
        ],
      },
      {
        code: "20501098",
        text: "На каком минимальном расстоянии от работающих механизмов на территории складирования горной массы (пород), на разгрузочных площадках, перегрузочных пунктах (складах) должны находиться люди?",
        answers: [
          "На расстоянии 5,0 м",
          "На расстоянии 4,0 м",
          "На расстоянии 3,0 м",
          "Не нормируется",
        ],
        correctAnswers: ["На расстоянии 5,0 м"],
      },
      {
        code: "20501099",
        text: "Каким должно быть превышение гребня гидроотвала у верхового откоса над пляжем при выпуске пульпы на пляж для исключения перелива на гребень и низовой откос дамбы?",
        answers: [
          "Равным диаметру пульповыпуска",
          "Не менее диаметра пульповыпуска, но не менее 0,5 м",
          "Не менее диаметра пульповыпуска, но не менее 1 м",
          "Не менее диаметра пульповыпуска, но не менее 1,5 м",
        ],
        correctAnswers: ["Не менее диаметра пульповыпуска, но не менее 0,5 м"],
      },
      {
        code: "20501100",
        text: "Какой должна быть длина надводного пляжа в течение всего срока эксплуатации намывных гидроотвалов?",
        answers: [
          "Не менее 50 м",
          "Не менее 40 м",
          "Не менее 30 м",
          "Должна соответствовать проекту разработки месторождения",
          "Должна определяться опытным путем",
        ],
        correctAnswers: [
          "Должна соответствовать проекту разработки месторождения",
        ],
      },
      {
        code: "20501101",
        text: "Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом по организации обслуживания намыва гидроотвала?",
        answers: [
          "Участки намытого гидроотвала должны быть ограждены, и на них должны быть установлены предупредительные плакаты и знаки",
          "Для обслуживания намыва гидроотвала должны устраиваться мостики с перилами",
          "Для обеспечения безаварийной работы должны проводиться натурные наблюдения и инструментальный контроль с использованием контрольно-измерительной аппаратуры",
          "Подход к вымоинам, провалам или воронкам, образовавшимся на гидроотвале, а также хождение по льду пруда-отстойника должны осуществляться с особыми мерами предосторожности",
        ],
        correctAnswers: [
          "Подход к вымоинам, провалам или воронкам, образовавшимся на гидроотвале, а также хождение по льду пруда-отстойника должны осуществляться с особыми мерами предосторожности",
        ],
      },
      {
        code: "20501102",
        text: "Какое из перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом по профилактике эндогенных пожаров указано верно?",
        answers: [
          "На угольных разрезах, разрабатывающих пласты угля, склонные к самовозгоранию, должны выполняться мероприятия по своевременному обнаружению очагов самонагревания и самовозгорания угля, профилактике и тушению пожаров, утвержденные техническим руководителем (главным инженером) угольного разреза",
          "Способы обнаружения очагов пожаров и наблюдений за внешними признаками самонагревания угля определяются представителем пожарной части",
          "Способы замера температуры, концентрации СО         определяются инспектором Ростехнадзора",
          "Места установки датчиков определяются представителем подразделения специализированного профессионального аварийно-спасательного формирования",
          "При обнаружении признаков самонагревания угля должны быть приняты меры, предусмотренные планом по профилактике и тушению экзогенных пожаров на угольном разрезе",
        ],
        correctAnswers: [
          "На угольных разрезах, разрабатывающих пласты угля, склонные к самовозгоранию, должны выполняться мероприятия по своевременному обнаружению очагов самонагревания и самовозгорания угля, профилактике и тушению пожаров, утвержденные техническим руководителем (главным инженером) угольного разреза",
        ],
      },
      {
        code: "20501103",
        text: "Кто утверждает мероприятия по своевременному обнаружению очагов самонагревания и самовозгорания угля, профилактике и тушению пожаров на угольных разрезах, разрабатывающих пласты угля, склонные к самовозгоранию?",
        answers: [
          "Командование военизированной горноспасательной части",
          "Руководитель территориального органа Ростехнадзора",
          "Командир пожарной части, на территории обслуживания которой находится разрез",
          "Представитель органов исполнительной власти муниципального образования, на территории которого находится разрез",
          "Технический руководитель (главный инженер) угольного разреза",
        ],
        correctAnswers: [
          "Технический руководитель (главный инженер) угольного разреза",
        ],
      },
      {
        code: "20501104",
        text: "Что из перечисленного не подлежит профилактической обработке антипирогенами?",
        answers: [
          "Угольные уступы по рабочему борту, имеющие геологические включения или нарушения от взрывных работ, сроки обработки которых в процессе технологического цикла превышают продолжительность инкубационного периода их самовозгорания",
          "Угольные и породно-угольные скопления небольших объемов, оставляемые в разрезе в качестве автотракторных съездов",
          "Угольные и породно-угольные скопления небольших объемов, оставляемые в разрезе в качестве насыпей под временные железнодорожные пути и конвейерные линии",
          "Угольные и породно-угольные уступы в зоне геологических нарушений или в местах сосредоточения породно-угольных скоплений, сроки отгрузки которых не превышают инкубационного периода",
        ],
        correctAnswers: [
          "Угольные и породно-угольные уступы в зоне геологических нарушений или в местах сосредоточения породно-угольных скоплений, сроки отгрузки которых не превышают инкубационного периода",
        ],
      },
      {
        code: "20501105",
        text: "В какие периоды должны проводиться температурные съемки действующих негорящих породных отвалов?",
        answers: [
          "3 раза в год: в мае, июле и сентябре",
          "2 раза в год: в мае и сентябре",
          "1 раз в год: в сентябре",
          "3 раза в год: в мае, июне и сентябре",
        ],
        correctAnswers: ["3 раза в год: в мае, июле и сентябре"],
      },
      {
        code: "20501106",
        text: "В какие периоды должны проводиться температурные съемки на действующих горящих породных отвалах?",
        answers: [
          "3 раза в год: в мае, июле и сентябре",
          "2 раза в год: в мае и сентябре",
          "1 раз в год: в сентябре",
          "1 раз в год: в августе",
        ],
        correctAnswers: ["2 раза в год: в мае и сентябре"],
      },
      {
        code: "20501107",
        text: "Когда должны проводиться температурные съемки на недействующих горящих породных отвалах?",
        answers: [
          "1 раз в год: в сентябре",
          "2 раза в год: в июне и сентябре",
          "1 раз в год: в июле",
          "3 раза в год: в мае, июле и сентябре",
        ],
        correctAnswers: ["1 раз в год: в сентябре"],
      },
      {
        code: "20501108",
        text: "В каких из перечисленных случаев температурные съемки не проводятся?",
        answers: [
          "Только при грозах и ливневых осадках",
          "Только при обнаружении признаков деформации поверхности породного отвала",
          "Только при обнаружении выгоревших участков",
          "В любом из перечисленных случаев",
        ],
        correctAnswers: ["В любом из перечисленных случаев"],
      },
      {
        code: "20501109",
        text: "При какой максимальной температуре пород разбираемого слоя разрешается производить работы по тушению или разборке горящих породных отвалов?",
        answers: ["При 80 °С", "При 83 °С", "При 85 °С", "При 90 °С"],
        correctAnswers: ["При 80 °С"],
      },
      {
        code: "20501110",
        text: "Чем должно оформляться изменение теплового состояния отвала, перевод в категорию горящих или негорящих?",
        answers: [
          "Записью в журнале регистрации температуры породного отвала",
          "Записью в паспорте отвала",
          "Актом",
          "Протоколом",
        ],
        correctAnswers: ["Актом"],
      },
      {
        code: "20501111",
        text: "Каким составом бригады должны выполняться работы по тушению горящего породного отвала?",
        answers: [
          "Не менее чем 2 работниками",
          "Не менее чем 2 работниками под наблюдением ответственного руководителя",
          "3 работников и наблюдающим",
          "1 работником, наблюдающим и ответственным руководителем",
        ],
        correctAnswers: ["Не менее чем 2 работниками"],
      },
      {
        code: "20501112",
        text: "Чем регламентируются работы по разборке породных отвалов?",
        answers: [
          "Проектной документацией",
          "Техническими условиями",
          "Планом производства работ",
          "Типовой инструкцией",
        ],
        correctAnswers: ["Проектной документацией"],
      },
      {
        code: "20501113",
        text: "На каком расстоянии друг от друга должны находиться места отбора проб для определения инкубационного периода самовозгорания угля?",
        answers: [
          "На расстоянии 30 - 50 м",
          "На расстоянии 20 - 25 м",
          "На расстоянии 10 - 20 м",
          "На расстоянии 5 - 10 м",
        ],
        correctAnswers: ["На расстоянии 30 - 50 м"],
      },
      {
        code: "20501114",
        text: "Каков максимально допустимый период времени для отбора проб угля на каждом отрабатываемом пласте угля после его обнажения?",
        answers: ["24 часа", "20 часов", "12 часов", "5 часов"],
        correctAnswers: ["24 часа"],
      },
      {
        code: "20501115",
        text: "Каковы допустимые масса проб угля и размер кусков угля при отборе проб угля из горных выработок шахт и на разрезах?",
        answers: [
          "Пробы отбираются массой не менее 4 кг, размер кусков угля в пробе 30 - 50 мм",
          "Пробы отбираются массой не более 4 кг, размер кусков угля в пробе 80 - 100 мм",
          "Пробы отбираются массой не менее 2 кг, размер кусков угля в пробе 30 - 50 мм",
          "Пробы отбираются массой не более 2 кг, размер кусков угля в пробе 80 - 100 мм",
        ],
        correctAnswers: [
          "Пробы отбираются массой не менее 4 кг, размер кусков угля в пробе 30 - 50 мм",
        ],
      },
      {
        code: "20501116",
        text: "На каком расстоянии должны отбираться пробы угля из керна в угольных прослойках и пропластках углистого сланца мощностью более 0,2 м, расположенных в кровле пласта?",
        answers: [
          "На расстоянии однократной мощности пласта",
          "На расстоянии двукратной мощности пласта",
          "На расстоянии трехкратной мощности пласта",
          "На расстоянии четырехкратной мощности пласта",
        ],
        correctAnswers: ["На расстоянии трехкратной мощности пласта"],
      },
      {
        code: "20501117",
        text: "При каком выходе керна допускается производить отбор проб при колонковом бурении скважины для определения инкубационного периода самовозгорания угля?",
        answers: [
          "Не менее 35 %",
          "Не менее 50 %",
          "Не менее 60 %",
          "Не менее 80 %",
        ],
        correctAnswers: ["Не менее 80 %"],
      },
      {
        code: "20501118",
        text: "Кто из перечисленных лиц назначает комиссию по приемке в эксплуатацию строительно-дорожных машин, горнотранспортного и технологического оборудования после монтажа?",
        answers: [
          "Представитель исполнительной власти муниципального образования, на территории которого находится разрез",
          "Руководитель территориального органа Ростехнадзора",
          "Представитель государственной инспекции труда",
          "Командир специализированного профессионального аварийно-спасательного формирования",
          "Технический руководитель (главный инженер) угольного разреза",
        ],
        correctAnswers: [
          "Технический руководитель (главный инженер) угольного разреза",
        ],
      },
      {
        code: "20501119",
        text: "С какой периодичностью должна проверяться исправность и комплектность машин механиком, энергетиком участка?",
        answers: [
          "Еженедельно",
          "1 раз в 2 недели",
          "1 раз в месяц",
          "1 раз в квартал",
        ],
        correctAnswers: ["Еженедельно"],
      },
      {
        code: "20501120",
        text: "С какой периодичностью должна проверяться исправность и комплектность машин главным механиком, главным энергетиком разреза или другим назначаемым лицом?",
        answers: [
          "Ежемесячно",
          "1 раз в 2 месяца",
          "1 раз в квартал",
          "1 раз в полугодие",
        ],
        correctAnswers: ["Ежемесячно"],
      },
      {
        code: "20501121",
        text: "С какой периодичностью должна проверяться исправность и комплектность машин машинистом (оператором, водителем)?",
        answers: [
          "Ежемесячно",
          "1 раз в 2 месяца",
          "1 раз в квартал",
          "1 раз в полугодие",
          "Ежесменно",
        ],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20501122",
        text: "Что из перечисленного соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к эксплуатации и обслуживанию технологического оборудования, технических устройств, машин и механизмов, используемых на разрезе?",
        answers: [
          "Эксплуатация, обслуживание технологического оборудования, технических устройств, транспортирование и хранение машин и оборудования, их узлов и деталей, а также их монтаж и демонтаж должны производиться в соответствии с заводской документацией и технологическими картами (при наличии), утвержденными техническим руководителем (главным инженером) угольного разреза",
          "Технические устройства, в том числе иностранного производства, должны иметь паспорта, руководства (инструкции) по монтажу, эксплуатации, техническому обслуживанию и ремонту, оформленные на русском языке, с указанием метрической системы измерений",
          "Нормируемые заводами-изготовителями технические характеристики должны выдерживаться на протяжении всего периода эксплуатации оборудования",
          "Все перечисленные утверждения",
        ],
        correctAnswers: ["Все перечисленные утверждения"],
      },
      {
        code: "20501123",
        text: "Какое из перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом указано неверно?",
        answers: [
          "Движущиеся части оборудования, представляющие собой источник опасности для людей, должны быть ограждены, за исключением частей, ограждение которых невозможно из-за их функционального назначения",
          "Перед началом работы или движения машины (механизма) машинист обязан убедиться в безопасности членов бригады и находящихся поблизости лиц",
          "Предпусковой предупредительный сигнал должен быть звуковым. Продолжительность сигнала должна составлять не менее 3 секунд. Сигнал должен быть слышен по всей опасной зоне",
          "Перед началом движения машин, железнодорожных составов, транспортных средств, погрузочной техники обязательна подача звуковых или световых предупредительных сигналов, разработанных угольным разрезом, со значением которых должны быть ознакомлены все работающие. При этом сигналы должны быть слышны (видны) всем работникам в зоне действия машин (механизмов)",
          "Таблица предупредительных сигналов должна располагаться на работающем механизме или вблизи него. При неправильно поданном или непонятном предупредительном сигнале машины (механизмы) должны быть остановлены до устранения данного нарушения",
        ],
        correctAnswers: [
          "Предпусковой предупредительный сигнал должен быть звуковым. Продолжительность сигнала должна составлять не менее 3 секунд. Сигнал должен быть слышен по всей опасной зоне",
        ],
      },
      {
        code: "20501124",
        text: "Какие из перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом указаны верно?",
        answers: [
          "К управлению горнотранспортными машинами, связанному с оперативным включением и отключением электроустановок, допускаются машинисты и помощники машинистов только после их обучения, аттестации и присвоения им квалификационных групп по электробезопасности",
          "Оперативные переключения кабельных линий в пределах закрепленной за машинистом и помощником машиниста горнотранспортной машины и ее переключательного пункта проводятся по наряду-допуску",
          "При временном переводе машинистов и помощников машинистов на другое горное оборудование обслуживание данного оборудования допускается после их ознакомления со схемой электроснабжения эксплуатируемого оборудования под подпись",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20501125",
        text: "Что не соответствует требованиям к состоянию горнотранспортных и строительно-дорожных машин в нерабочее время?",
        answers: [
          "Машины должны быть выведены из забоя в безопасное место",
          "Ковш должен быть опущен на грунт",
          "При отсутствии экипажа кабина должна быть заперта",
          "С питающего кабеля напряжение не снимается",
        ],
        correctAnswers: ["С питающего кабеля напряжение не снимается"],
      },
      {
        code: "20501126",
        text: "Кому из перечисленных лиц разрешается проезд в многоместных кабинах транспортных средств, в железнодорожных составах и кабинах локомотивов?",
        answers: [
          "Только лицам, сопровождающим составы",
          "Только сменному надзору",
          "Только отдельным работникам при наличии у них письменного разрешения технического руководителя (главного инженера) угольного разреза",
          "Всем перечисленным лицам",
        ],
        correctAnswers: ["Всем перечисленным лицам"],
      },
      {
        code: "20501127",
        text: "Что не соответствует требованиям безопасности при эксплуатации горнотранспортных и строительно-дорожных машин?",
        answers: [
          "Работы с использованием горнотранспортных и строительно-дорожных машин должны вестись по документации производства работ, копия которого должна находиться в кабинах этих машин",
          "Копия проекта производства работ должны находиться только у горного мастера смены",
          "Запрещается присутствие посторонних лиц в кабине и на наружных площадках экскаватора и бурового станка при их работе, кроме руководителя смены и лиц, имеющих разрешение технического руководителя (главного инженера) угольного разреза",
          "В случае внезапного прекращения подачи электроэнергии персонал, обслуживающий механизмы, обязан остановить запуск электродвигателей и прекратить передвижение механизмов",
        ],
        correctAnswers: [
          "Копия проекта производства работ должны находиться только у горного мастера смены",
        ],
      },
      {
        code: "20501128",
        text: "Как должны производиться перегон горнотранспортных и строительно-дорожных машин (экскаваторов, буровых станков) и перевозки их на транспортных средствах на расстояние более 1 км, а также при необходимости отключения пересекаемых воздушных линий электропередачи (ВЛ)?",
        answers: [
          "В соответствии с планом мероприятий по локализации и ликвидации последствий аварий",
          "С обязательным уведомлением территориального органа Ростехнадзора, осуществляющего федеральный государственный надзор в области промышленной безопасности за угольным разрезом",
          "В соответствии с проектом производства работ или технологическими картами, утвержденными техническим руководителем (главным инженером) угольного разреза",
          "В соответствии с планом развития горных работ",
          "В соответствии с техническими инструкциями завода-изготовителя",
        ],
        correctAnswers: [
          "В соответствии с проектом производства работ или технологическими картами, утвержденными техническим руководителем (главным инженером) угольного разреза",
        ],
      },
      {
        code: "20501129",
        text: "Как должен располагаться привод ходовой тележки при передвижении гусеничного экскаватора по горизонтальному участку или на подъем и при спуске?",
        answers: [
          "При передвижении гусеничного экскаватора по горизонтальному участку или на подъем привод ходовой тележки должен находиться сзади, а при спусках с уклона - впереди",
          "При передвижении гусеничного экскаватора по горизонтальному участку или на подъем привод ходовой тележки должен находиться впереди, а при спусках с уклона - сзади",
          "При передвижении гусеничного экскаватора по горизонтальному участку или на подъем, а также при спуске привод ходовой тележки должен находиться сзади",
          "При передвижении гусеничного экскаватора по горизонтальному участку или на подъем, а также при спуске привод ходовой тележки должен находиться впереди",
        ],
        correctAnswers: [
          "При передвижении гусеничного экскаватора по горизонтальному участку или на подъем привод ходовой тележки должен находиться сзади, а при спусках с уклона - впереди",
        ],
      },
      {
        code: "20501130",
        text: "Какое из перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом к перегону экскаваторов указано неверно?",
        answers: [
          "Перегон должен осуществляться по трассе, расположенной вне призм обрушения",
          "Уклон трассы не должен превышать величины, допустимой по техническим характеристикам экскаватора, и ширина трассы должна быть достаточной для маневров",
          "Перегон экскаватора должен производиться по сигналам помощника машиниста или специально назначенного лица, при этом должна быть обеспечена постоянная видимость между ними и машинистом экскаватора",
          "Для шагающих экскаваторов не допускается передача сигналов от помощника машиниста к машинисту через третьего члена бригады",
        ],
        correctAnswers: [
          "Для шагающих экскаваторов не допускается передача сигналов от помощника машиниста к машинисту через третьего члена бригады",
        ],
      },
      {
        code: "20501131",
        text: "Каким должно быть расстояние между откосом уступа, отвала или транспортным средством и контргрузом экскаватора?",
        answers: [
          "Устанавливается паспортом забоя в зависимости от горно-геологических условий и типа оборудования, но в любом случае должно быть не менее 0,5 м",
          "Устанавливается паспортом забоя в зависимости от горно-геологических условий и типа оборудования, но в любом случае должно быть не менее 0,6 м",
          "Устанавливается паспортом забоя в зависимости от горно-геологических условий и типа оборудования, но в любом случае должно быть не менее 0,75 м",
          "Устанавливается паспортом забоя в зависимости от горно-геологических условий и типа оборудования, но в любом случае должно быть не менее 1,0 м",
        ],
        correctAnswers: [
          "Устанавливается паспортом забоя в зависимости от горно-геологических условий и типа оборудования, но в любом случае должно быть не менее 1,0 м",
        ],
      },
      {
        code: "20501132",
        text: "Кем осуществляется подача сигналов при погрузке экскаваторами в железнодорожные вагоны и разгрузке их на экскаваторных отвалах?",
        answers: [
          "Машинистом экскаватора в соответствии с сигналами, установленными при эксплуатации железнодорожного транспорта",
          "Машинистом поезда в соответствии с сигналами, установленными при эксплуатации угольного разреза",
          "Лицом, назначенным приказом технического руководителя (главного инженера) угольного разреза",
          "Лицом из числа поездной бригады, назначенным машинистом поезда",
        ],
        correctAnswers: [
          "Машинистом экскаватора в соответствии с сигналами, установленными при эксплуатации железнодорожного транспорта",
        ],
      },
      {
        code: "20501133",
        text: "Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к канатам подвески стрелы экскаваторов, их осмотру и допуску к эксплуатации?",
        answers: [
          "Канаты подлежат осмотру не реже 1 раза в месяц механиком участка",
          "На длине шага свивки допускается не более 15 % порванных проволок от их общего числа в канате",
          "Торчащие концы оборванных проволок должны быть отрезаны",
          "Подъемные, тяговые и напорные канаты должны осматриваться в сроки, установленные заводом-изготовителем",
        ],
        correctAnswers: [
          "Канаты подлежат осмотру не реже 1 раза в месяц механиком участка",
        ],
      },
      {
        code: "20501134",
        text: "Что из перечисленного не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к эксплуатации экскаваторов?",
        answers: [
          "В случае выявления угрозы подтопления, обрушения или оползания горных выработок во время работы экскаватора или при обнаружении отказавших зарядов ВМ машинист экскаватора обязан прекратить работу, отвести экскаватор в безопасное место и поставить в известность руководителя смены",
          "Для вывода экскаватора из забоя необходимо всегда иметь свободный проход. Негабаритные куски горной массы должны укладываться устойчиво не более чем в 2-3 слоя, не создавая препятствий для перемещения горнотранспортного оборудования на площадке",
          "При работе экскаватора на грунтах, не выдерживающих давления гусениц, должны приниматься меры, отражаемые в проекте, обеспечивающие его устойчивое положение",
          "Погрузка горной массы экскаватором в забоях с контактными сетями электрифицированного транспорта в думпкары или другие емкости допускается при условии осуществления мероприятий по безопасным методам работы, включая защиту от прикосновения рабочим органом (ковшом) к контактному проводу",
        ],
        correctAnswers: [
          "Для вывода экскаватора из забоя необходимо всегда иметь свободный проход. Негабаритные куски горной массы должны укладываться устойчиво не более чем в 2-3 слоя, не создавая препятствий для перемещения горнотранспортного оборудования на площадке",
        ],
      },
      {
        code: "20501135",
        text: "С какой периодичностью должны проверяться устройства контроля за изменением ширины рельсовых путей и их уклонов?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 2 месяца",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20501136",
        text: "Каким должно быть расстояние между концом отвальной консоли транспортно-отвального моста и гребнем отвала?",
        answers: [
          "Не менее 1,0 м",
          "Не менее 1,5 м",
          "Не менее 2,0 м",
          "Не менее 3,0 м",
        ],
        correctAnswers: ["Не менее 3,0 м"],
      },
      {
        code: "20501137",
        text: "Каким должно быть расстояние между концом консольного ленточного отвалообразователя с периодическим перемещением и гребнем отвала?",
        answers: [
          "Не менее 0,5 м",
          "Не менее 0,7 м",
          "Не менее 1,0 м",
          "Не менее 1,5 м",
        ],
        correctAnswers: ["Не менее 1,5 м"],
      },
      {
        code: "20501138",
        text: "Что из перечисленного не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к эксплуатации скреперов, бульдозеров и погрузчиков?",
        answers: [
          "На всю самоходную технику (грейдеры, скреперы, бульдозеры, погрузчики) должны иметься паспорта, руководства по эксплуатации, техническому обслуживанию и ремонту, выданные организацией-изготовителем, содержащие их основные технические и эксплуатационные характеристики",
          "На линию транспортные средства могут выпускаться только при условии, если все их агрегаты и узлы, обеспечивающие безопасность движения, а также безопасность других работ, предусмотренных технологией применения, находятся в технически исправном состоянии",
          "Во всех случаях при движении транспортного средства и самоходной техники задним ходом должен подаваться звуковой сигнал",
          "Запрещается движение самоходной техники (скреперов, бульдозеров, погрузчиков) по призме возможного обрушения уступа. При разгрузке скрепер должен передвигаться назад под откос",
        ],
        correctAnswers: [
          "Запрещается движение самоходной техники (скреперов, бульдозеров, погрузчиков) по призме возможного обрушения уступа. При разгрузке скрепер должен передвигаться назад под откос",
        ],
      },
      {
        code: "20501139",
        text: "Что из перечисленного соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к эксплуатации бульдозера, скрепера или погрузчика?",
        answers: [
          "Допускается работа техники поперек крутых склонов при углах, предусмотренных инструкцией завода-изготовителя",
          "Запрещается эксплуатация бульдозера (трактора) при отсутствии или неисправности блокировки, исключающей запуск двигателя при включенной коробке передач, или устройства для запуска двигателя из кабины",
          "Запрещается находиться под поднятым рабочим органом (ножом или ковшом) самоходной техники",
          "Для осмотра рабочего органа (ножа или ковша) снизу его необходимо опустить на надежные подкладки, а двигатель выключить",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20501140",
        text: "Каким должно быть расстояние от гидромониторной установки и другого забойного оборудования (скреперов, бульдозеров) до забоя при гидромеханизированном способе разработки глинистых, плотных и лессовидных пород, способных к обрушению глыбами?",
        answers: [
          "Не менее 1,2 высоты уступа",
          "Не менее высоты уступа",
          "Не менее 0,8 высоты уступа",
          "Не менее 0,4 высоты уступа",
        ],
        correctAnswers: ["Не менее 1,2 высоты уступа"],
      },
      {
        code: "20501141",
        text: "Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом перед началом работы гидромонитора?",
        answers: [
          "Из зоны действия струи гидромонитора должны быть удалены все люди",
          "С места работы гидромонитора должны быть удалены лица, не имеющие отношения к его работе",
          "Территория участка на расстоянии не менее дальности действия струи гидромонитора должна ограждаться предупредительными знаками",
          "Во время пуска выходное отверстие гидромонитора должно быть всегда направлено в безопасное для окружающих место",
        ],
        correctAnswers: [
          "Территория участка на расстоянии не менее дальности действия струи гидромонитора должна ограждаться предупредительными знаками",
        ],
      },
      {
        code: "20501142",
        text: "Что из перечисленного не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом с использованием гидромеханизированного способа разработки?",
        answers: [
          "Проведение в забое вспомогательных работ допускается только после осмотра забоя, ликвидации козырьков и нависей",
          "Углы откоса уступов отработанных угольных разрезов не должны превышать углов естественного откоса пород",
          "Зумпфы землесосов и гидроэлеваторов должны быть закрыты или ограждены в целях исключения падения людей в зумпф",
          "При работе гидромониторов навстречу друг другу работу одного из них следует остановить при сближении на расстояние дальности полета максимальной струи более мощного гидромонитора",
        ],
        correctAnswers: [
          "При работе гидромониторов навстречу друг другу работу одного из них следует остановить при сближении на расстояние дальности полета максимальной струи более мощного гидромонитора",
        ],
      },
      {
        code: "20501143",
        text: "На каком расстоянии от воздушных линий (ВЛ) должен быть расположен гидромонитор без разработки и осуществления мероприятий, обеспечивающих безопасность работ, утвержденных техническим руководителем (главным инженером) угольного разреза?",
        answers: [
          "На расстоянии не менее 1,25 дальности полета струи",
          "На расстоянии не менее 1,5 дальности полета струи",
          "На расстоянии не менее 1,75 дальности полета струи",
          "На расстоянии не менее двукратной дальности полета струи",
        ],
        correctAnswers: [
          "На расстоянии не менее двукратной дальности полета струи",
        ],
      },
      {
        code: "20501144",
        text: "На каком минимальном расстоянии от воздушных линий электропередачи (ЛЭП) и линий связи разрешается укладывать пульпопроводы и водоводы без согласования с владельцами коммуникаций и организациями, осуществляющими контроль за их безопасной эксплуатацией?",
        answers: [
          "На расстоянии 10 м",
          "На расстоянии 15 м",
          "На расстоянии 20 м",
          "На расстоянии 25 м",
        ],
        correctAnswers: ["На расстоянии 25 м"],
      },
      {
        code: "20501145",
        text: "Как должны быть устроены мостки для обслуживания трубопровода и водопроводных лотков, уложенных на эстакадах?",
        answers: [
          "Мостки должны быть шириной 0,5 м, огражденные перилами высотой 1,2 м и обшитые снизу на высоту не менее 0,2 м",
          "Мостки должны быть шириной 0,6 м, огражденные перилами высотой 1,0 м и обшитые снизу на высоту не менее 0,15 м",
          "Мостки должны быть шириной 0,4 м, огражденные перилами высотой 1,3 м и обшитые снизу на высоту не менее 0,25 м",
          "Мостки должны быть шириной 0,5 м, огражденные перилами высотой 1,1 м и обшитые снизу на высоту не менее 0,1 м",
        ],
        correctAnswers: [
          "Мостки должны быть шириной 0,5 м, огражденные перилами высотой 1,2 м и обшитые снизу на высоту не менее 0,2 м",
        ],
      },
      {
        code: "20501146",
        text: "Что из перечисленного соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к прокладке трубопроводов?",
        answers: [
          "Трубопроводы укладываются на выровненном основании на подкладках",
          "При прокладке по откосу уступа или борту разреза трубопровод должен быть закреплен анкерами не реже чем через каждые 35 - 40 м по высоте",
          "На каждом прямолинейном участке трубопровода необходимо не реже чем через каждые 750 м устанавливать компенсатор",
          "Все перечисленное",
        ],
        correctAnswers: [
          "Трубопроводы укладываются на выровненном основании на подкладках",
        ],
      },
      {
        code: "20501147",
        text: "Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом по созданию безопасных условий труда при эксплуатации водосбросных и водосборных сооружений?",
        answers: [
          "Помещения насосных и землесосных установок должны иметь средства связи с местом установки гидромониторов и быть оборудованы аварийной сигнализацией",
          "Во вновь строящихся помещениях между насосами и землесосными агрегатами, а также между ними и стенками помещения следует предусматривать проходы шириной не менее 0,75 м",
          "На землесосных установках пульповоды и водоводы должны быть оборудованы обратными клапанами",
          "Все водосбросные и водосборные сооружения гидроотвалов следует рассчитывать на максимально возможный приток. По окончании работ с использованием средств гидромеханизации должны быть осмотрены все водосбросные сооружения и составлены документы об их состоянии",
        ],
        correctAnswers: [
          "Во вновь строящихся помещениях между насосами и землесосными агрегатами, а также между ними и стенками помещения следует предусматривать проходы шириной не менее 0,75 м",
        ],
      },
      {
        code: "20501148",
        text: "Кто из перечисленных лиц назначает комиссию по оценке качества проведенного планового текущего и капитального ремонта?",
        answers: [
          "Представитель органов государственного надзора за техническим состоянием самоходных машин",
          "Представитель завода-изготовителя",
          "Представитель территориального органа Ростехнадзора",
          "Технический руководитель (главный инженер) угольного разреза",
        ],
        correctAnswers: [
          "Технический руководитель (главный инженер) угольного разреза",
        ],
      },
      {
        code: "20501149",
        text: "В каком случае нарушены требования Правил безопасности при разработке угольных месторождений открытым способом по организации ремонта технологического оборудования?",
        answers: [
          "Ремонт технологического оборудования должен проводиться в соответствии с графиками обслуживания и ремонта оборудования согласно документации завода-изготовителя. Годовые и месячные графики ремонтов утверждает руководитель угольного разреза",
          "На все виды ремонтов основного технологического оборудования в соответствии с действующим на предприятии положением о планово-предупредительных ремонтах должны быть разработаны инструкции (технологические карты, руководства, проекты организации работ)",
          "В технологических картах, руководствах, проектах организации работ указывается количество приспособлений и инструментов, определяются порядок и последовательность работ, обеспечивающих безопасность их проведения",
          "Работники, занятые на ремонте, должны быть ознакомлены с инструкциями, технологическими картами и проектами организации работ под подпись",
        ],
        correctAnswers: [
          "Ремонт технологического оборудования должен проводиться в соответствии с графиками обслуживания и ремонта оборудования согласно документации завода-изготовителя. Годовые и месячные графики ремонтов утверждает руководитель угольного разреза",
        ],
      },
      {
        code: "20501150",
        text: "Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к проведению ремонта технологического оборудования?",
        answers: [
          "При выполнении ремонтных работ подрядной организацией ответственные представители заказчика и подрядчика должны оформить на весь период выполнения работ наряд-допуск (акт-допуск, наряд-заказ), разработать и осуществить организационно-технические мероприятия, направленные на повышение безопасного ведения ремонтных работ",
          "Запрещается проведение ремонтных работ в непосредственной близости от открытых движущихся частей механических установок, а также вблизи электрических проводов и токоведущих частей, находящихся под напряжением, при отсутствии их надлежащего ограждения",
          "Ремонт и замену частей механизмов допускается проводить только после полной остановки машины, снятия давления в гидравлических и пневматических системах, блокировки пусковых аппаратов, приводящих в движение механизмы, на которых производятся ремонтные работы. Допускается при выполнении ремонтных работ подача электроэнергии по проекту организации работ",
          "Ремонты, связанные с изменением несущих металлоконструкций основного технологического оборудования, должны проводиться по документации, согласованной с территориальным органом Ростехнадзора, с составлением акта выполненных работ",
        ],
        correctAnswers: [
          "Ремонты, связанные с изменением несущих металлоконструкций основного технологического оборудования, должны проводиться по документации, согласованной с территориальным органом Ростехнадзора, с составлением акта выполненных работ",
        ],
      },
      {
        code: "20501151",
        text: "Какая организация должна согласовать проект на выполнение ремонтных работ, связанных с изменением несущих металлоконструкций основного технологического оборудования?",
        answers: [
          "Проектная организация",
          "Завод-изготовитель",
          "Территориальный орган Ростехнадзора",
          "Специализированная организация по диагностике оборудования",
        ],
        correctAnswers: ["Завод-изготовитель"],
      },
      {
        code: "20501152",
        text: "Кем утверждается документация, по которой должны производиться ремонты по восстановлению несущих металлоконструкций?",
        answers: [
          "Техническим руководителем завода-изготовителя",
          "Представителем территориального органа Ростехнадзора",
          "Техническим руководителем (главным инженером) угольного разреза",
          "Руководителем проектной организации",
        ],
        correctAnswers: [
          "Техническим руководителем (главным инженером) угольного разреза",
        ],
      },
      {
        code: "20501153",
        text: "На каком расстоянии от наружной грани головки крайнего рельса должны находиться грузы при высоте до 1200 мм?",
        answers: [
          "На расстоянии не менее 2,0 м",
          "На расстоянии не менее 1,75 м",
          "На расстоянии не менее 1,5 м",
          "На расстоянии не менее 1,25 м",
        ],
        correctAnswers: ["На расстоянии не менее 2,0 м"],
      },
      {
        code: "20501154",
        text: "На каком расстоянии от наружной грани головки крайнего рельса должны находиться грузы при высоте выше 1200 мм?",
        answers: [
          "На расстоянии не менее 2,5 м",
          "На расстоянии не менее 2,25 м",
          "На расстоянии не менее 2,0 м",
          "На расстоянии не менее 1,75 м",
        ],
        correctAnswers: ["На расстоянии не менее 2,5 м"],
      },
      {
        code: "20501155",
        text: "Как часто должна обновляться схема транспортных коммуникаций на угольных разрезах, нанесенная на план горных работ?",
        answers: [
          "1 раз в месяц",
          "1 раз в 2 месяца",
          "1 раз в квартал",
          "1 раз в полугодие",
          "Должна обновляться при изменении схемы",
        ],
        correctAnswers: ["Должна обновляться при изменении схемы"],
      },
      {
        code: "20501156",
        text: "Какой должна быть ширина проезжей дороги на переездах временных железнодорожных путей для автосамосвалов грузоподъемностью до 10 т при однополосном движении?",
        answers: [
          "Не менее 4,5 м",
          "Не менее 5,5 м",
          "Не менее 6,5 м",
          "Не менее 7,5 м",
        ],
        correctAnswers: ["Не менее 7,5 м"],
      },
      {
        code: "20501157",
        text: "Какой должна быть ширина проезжей дороги на переездах временных железнодорожных путей для автосамосвалов грузоподъемностью до 10 т при двухполосном движении?",
        answers: [
          "Не менее 7,5 м",
          "Не менее 8,0 м",
          "Не менее 9,0 м",
          "Не менее 10,0 м",
        ],
        correctAnswers: ["Не менее 10,0 м"],
      },
      {
        code: "20501158",
        text: "Какой должна быть ширина проезжей дороги на переездах временных железнодорожных путей для автосамосвалов грузоподъемностью более 10 т при однополосном и двухполосном движении соответственно?",
        answers: [
          "Соответственно не менее 7 м и не менее 16 м",
          "Соответственно не менее 8 м и не менее 18 м",
          "Соответственно не менее 9 м и не менее 20 м",
          "Соответственно не менее 10 м и не менее 22 м",
        ],
        correctAnswers: ["Соответственно не менее 10 м и не менее 22 м"],
      },
      {
        code: "20501159",
        text: "Что из перечисленного не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к устройству переездов на временных железнодорожных путях? Выберете все варианты ответов",
        answers: [
          "Перелом профиля устраивается на расстоянии 5 м от крайнего рельса",
          "Переезды должны иметь сплошной настил и угол пересечения с железной дорогой не менее 20°",
          "На расстоянии не менее длины тормозного пути в обе стороны от переезда должны быть установлены предупредительные знаки о подаче свистка машинистом локомотива",
          "Продольные уклоны дорог на подходах к переезду должны быть не менее 60 ‰",
        ],
        correctAnswers: [
          "Переезды должны иметь сплошной настил и угол пересечения с железной дорогой не менее 20°",
          "Продольные уклоны дорог на подходах к переезду должны быть не менее 60 ‰",
        ],
      },
      {
        code: "20501160",
        text: "Каким должно быть нормальное положение шлагбаумов автоматизированного и неавтоматизированного переездов?",
        answers: [
          "Открытое у автоматизированного и неавтоматизированного переездов",
          "Закрытое у автоматизированного и неавтоматизированного переездов",
          "Открытое у автоматизированного, и закрытое у неавтоматизированного переездов",
          "Закрытое у автоматизированного, и открытое у неавтоматизированного переездов",
        ],
        correctAnswers: [
          "Открытое у автоматизированного, и закрытое у неавтоматизированного переездов",
        ],
      },
      {
        code: "20501161",
        text: "Что из перечисленного не соответствует требованиям по обеспечению безопасности движения подвижного состава при ремонте сооружений и устройств?",
        answers: [
          "Запрещается приступать к работам до ограждения сигналами мест производства работ, опасных для следования подвижного состава",
          "Запрещается снимать сигналы, ограждающие места работ, до полного их окончания, а также до проверки состояния пути, контактной сети и соблюдения габарита",
          "Места производства работ, опасные для следования подвижного состава, должны ограждаться сигналами с обеих сторон только на однопутных участках. На двух- и многопутных участках дорог - только на участках, на которых ожидается следование поезда",
          "Перед началом путевых ремонтных работ руководитель обязан проинструктировать работников об условиях безопасного производства этих работ и указать места, куда работники должны уходить во время прохода поездов, а также предупредить дежурного по станции и согласовать с ним условия работы",
        ],
        correctAnswers: [
          "Места производства работ, опасные для следования подвижного состава, должны ограждаться сигналами с обеих сторон только на однопутных участках. На двух- и многопутных участках дорог - только на участках, на которых ожидается следование поезда",
        ],
      },
      {
        code: "20501162",
        text: "Что из перечисленного учитывается  инструкцией по эксплуатации железнодорожного транспорта угольного разреза при установлении скорости движения поездов на железнодорожных путях угольного разреза?",
        answers: [
          "Только применяемый подвижной состав",
          "Только верхнее строение и профиль пути",
          "Только местные условия",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20501163",
        text: "На каком расстоянии от конца рельсов устанавливаются предохранительные упоры на забойных и отвальных железнодорожных путях?",
        answers: [
          "На расстоянии не менее 4 м от конца рельсов",
          "На расстоянии не менее 6 м от конца рельсов",
          "На расстоянии не менее 8 м от конца рельсов",
          "На расстоянии не менее 10 м от конца рельсов",
        ],
        correctAnswers: ["На расстоянии не менее 10 м от конца рельсов"],
      },
      {
        code: "20501164",
        text: "Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к следованию поездов вагонами вперед?",
        answers: [
          "Следование поездов вагонами вперед разрешается при наличии вагонов с тормозной площадкой",
          "Следование поездов вагонами вперед разрешается при наличии на переднем вагоне исправной подножки, обращенной в сторону движения поезда, на которой должен находиться составитель (кондуктор)",
          "Допускается следование специализированных технологических поездов вагонами вперед без составителя (кондуктора), при обязательном наличии на переднем вагоне (думпкаре) соответствующих звуковых, а в темное время суток и световых сигналов",
          "Хозяйственный поезд, отправляемый на перегон в тупик погрузки и выгрузки вагонами вперед без составителя (кондуктора) и звуковых и световых сигналов, может состоять из вагонов (думпкаров) с числом осей не более 24",
        ],
        correctAnswers: [
          "Хозяйственный поезд, отправляемый на перегон в тупик погрузки и выгрузки вагонами вперед без составителя (кондуктора) и звуковых и световых сигналов, может состоять из вагонов (думпкаров) с числом осей не более 24",
        ],
      },
      {
        code: "20501165",
        text: "Какой должна быть высота подвески контактного провода на постоянных путях над головкой рельса на станциях и на перегонах соответственно?",
        answers: [
          "Соответственно не менее 6250 мм и не менее 5750 мм",
          "Соответственно не менее 5750 мм и не менее 5250 мм",
          "Соответственно не менее 5250 мм и не менее 4750 мм",
          "Соответственно не менее 4750 мм и не менее 4250 мм",
        ],
        correctAnswers: ["Соответственно не менее 6250 мм и не менее 5750 мм"],
      },
      {
        code: "20501166",
        text: "Какой должна быть высота подвески контактного провода над уровнем головки рельса на передвижных железнодорожных путях при боковой подвеске?",
        answers: [
          "Не менее 3800 мм",
          "Не менее 4000 мм",
          "Не менее 4200 мм",
          "Не менее 4400 мм",
        ],
        correctAnswers: ["Не менее 4400 мм"],
      },
      {
        code: "20501167",
        text: "Каким должно быть расстояние от оси крайнего железнодорожного пути до внутреннего края опор контактной сети на перегонах и железнодорожных станциях?",
        answers: [
          "Не менее 3100 мм",
          "Не менее 3000 мм",
          "Не менее 2900 мм",
          "Не менее 2800 мм",
        ],
        correctAnswers: ["Не менее 3100 мм"],
      },
      {
        code: "20501168",
        text: "При каком расстоянии металлических конструкций (мостов, путепроводов, светофоров и т. п.) от частей контактной сети, находящихся под напряжением, металлические опоры контактной сети и детали крепления изоляторов контактной сети на железобетонных и каменных искусственных сооружениях и железобетонных опорах, а также приводы секционных разъединителей, нерабочие анкеровочные ветки и грузы компенсаторов, установленные на деревянных опорах, должны быть заземлены?",
        answers: [
          "На расстоянии менее 5 м",
          "На расстоянии 5 м",
          "На расстоянии 5 м и более",
          "На расстоянии более 5 м",
        ],
        correctAnswers: ["На расстоянии менее 5 м"],
      },
      {
        code: "20501169",
        text: "На какую величину меньше высоты подвески контактного провода должна быть минимальная высота габаритных ворот около переездов с обеих сторон на всех пересечениях электрифицированных путей с автомобильными дорогами и пунктами, где проводятся погрузочно-разгрузочные работы?",
        answers: ["На 0,3 м", "На 0,4 м", "На 0,5 м", "На 0,6 м"],
        correctAnswers: ["На 0,5 м"],
      },
      {
        code: "20501170",
        text: "Какой минимальной ширины должен быть свободный проход с обеих сторон транспортного средства при его движении через временные въезды в траншеи?",
        answers: ["0,8 м", "1 м", "1,5 м", "2,0"],
        correctAnswers: ["1,5 м"],
      },
      {
        code: "20501171",
        text: "С каким максимальным уклоном должны устраиваться площадки при уклонах дорог длиной более 600 м и более 60 ‰?",
        answers: [
          "С уклоном 10 ‰",
          "С уклоном 15 ‰",
          "С уклоном 20 ‰",
          "С уклоном 25 ‰",
        ],
        correctAnswers: ["С уклоном 20 ‰"],
      },
      {
        code: "20501172",
        text: "Какой минимальной длины должны быть площадки при уклонах дорог длиной более 600 м и более 60 ‰?",
        answers: ["10 м", "25 м", "50 м", "100 м"],
        correctAnswers: ["50 м"],
      },
      {
        code: "20501173",
        text: "С каким максимальным интервалом должны устраиваться площадки при уклонах дорог длиной более 600 м и более 60 ‰?",
        answers: [
          "Через каждые 300 м длины затяжного уклона",
          "Через каждые 400 м длины затяжного уклона",
          "Через каждые 500 м длины затяжного уклона",
          "Через каждые 600 м длины затяжного уклона",
          "Через каждые 700 м длины затяжного уклона",
        ],
        correctAnswers: ["Через каждые 600 м длины затяжного уклона"],
      },
      {
        code: "20501174",
        text: "Какое из перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом к эксплуатации технологического автомобильного транспорта указано неверно?",
        answers: [
          "Допускается использование автомобилей, не имеющих технической документации, выданной заводом-изготовителем и содержащей его основные технические и эксплуатационные характеристики при условии, что все их агрегаты и узлы, обеспечивающие безопасность движения, находятся в технически исправном состоянии",
          "На линию автомобили могут выпускаться только при условии, если все их агрегаты и узлы, обеспечивающие безопасность движения, а также безопасность других работ, предусмотренных технологией применения транспортных средств, находятся в технически исправном состоянии",
          "Автомобили должны также иметь необходимый запас горючего и комплект инструмента, предусмотренный заводом-изготовителем",
        ],
        correctAnswers: [
          "Допускается использование автомобилей, не имеющих технической документации, выданной заводом-изготовителем и содержащей его основные технические и эксплуатационные характеристики при условии, что все их агрегаты и узлы, обеспечивающие безопасность движения, находятся в технически исправном состоянии",
        ],
      },
      {
        code: "20501175",
        text: "Что из перечисленного не соответствует условиям погрузки горной массы в автомобили экскаваторами?",
        answers: [
          "Ожидающий погрузки автомобиль должен находиться за пределами радиуса действия экскаватора (погрузчика) и становиться под погрузку только после звукового и светового сигналов машиниста экскаватора (погрузчика) или оператора погрузочного устройства",
          "Погрузка в кузов автомобиля должна производиться только сзади или сбоку, перенос экскаваторного рабочего органа (ковша) над кабиной автомобиля запрещается",
          "Высота падения груза должна быть минимально возможной и во всех случаях не превышать 3,5 м",
          "Нагруженный автомобиль может следовать к пункту разгрузки только после звукового и светового сигналов машиниста экскаватора (погрузчика)",
        ],
        correctAnswers: [
          "Высота падения груза должна быть минимально возможной и во всех случаях не превышать 3,5 м",
        ],
      },
      {
        code: "20501176",
        text: "Что из перечисленного разрешается при работе автомобиля на линии?",
        answers: [
          "Переезд кабелей, уложенных по почве и не огражденных специальными предохранительными устройствами",
          "Перевозка посторонних людей в кабине без разрешения администрации",
          "Выход из кабины автомобиля до полного подъема или опускания кузова",
          "Движение вдоль железнодорожных путей на расстоянии более 5 м от ближайшего рельса",
          "Нахождение персонала под поднятым, незастопоренным кузовом автосамосвала",
        ],
        correctAnswers: [
          "Движение вдоль железнодорожных путей на расстоянии более 5 м от ближайшего рельса",
        ],
      },
      {
        code: "20501177",
        text: "Какой минимальной ширины должен быть проход для людей в галереях и эстакадах вновь проектируемых угольных разрезов у конвейера при ширине ленты до 1400 мм?",
        answers: ["700 мм", "800 мм", "1000 мм", "1400 мм"],
        correctAnswers: ["800 мм"],
      },
      {
        code: "20501178",
        text: "До какой максимальной высоты над уровнем земли разрешается использование конвейеров, не оборудованных по всей длине с обеих сторон непрерывными боковыми площадками шириной не менее 0,3 м?",
        answers: [
          "До высоты 0,8 м",
          "До высоты 1,1 м",
          "До высоты 1,3 м",
          "До высоты 1,5 м",
          "До высоты 1,8 м",
        ],
        correctAnswers: ["До высоты 1,8 м"],
      },
      {
        code: "20501179",
        text: "С какой периодичностью следует проверять тормоза приводов конвейеров?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 2 месяца",
          "Не реже 1 раза в квартал",
          "Не реже 1 раза в полугодие",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20501180",
        text: "Какие их перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом указаны неверно? Выберите два правильных варианта ответа.",
        answers: [
          "Проемы бункеров должны ограждаться с нерабочих сторон перилами высотой не менее 1 м со сплошной обшивкой их по низу полосой на высоту 0,15 м",
          "Разгрузочные площадки для железнодорожного транспорта и автосамосвалов ограждаются перилами высотой не более 1 м в местах возможного прохода людей с обшивкой их по низу на высоту 0,15 м",
          "Рабочие площадки приемных и разгрузочных устройств и бункеров обязательно оборудуются звуковой сигнализацией, предназначенной для оповещения обслуживающего персонала о прибытии железнодорожного состава",
          "Сигналы о прибытии железнодорожного состава подаются за 1,5 - 2 минуты до момента прибытия транспортных средств.",
          "На приемных бункерах должен быть установлен светофор, разрешающий или запрещающий въезд железнодорожного состава или транспортного средства на площадку бункера под разгрузку",
        ],
        correctAnswers: [
          "Проемы бункеров должны ограждаться с нерабочих сторон перилами высотой не менее 1 м со сплошной обшивкой их по низу полосой на высоту 0,15 м",
          "Разгрузочные площадки для железнодорожного транспорта и автосамосвалов ограждаются перилами высотой не более 1 м в местах возможного прохода людей с обшивкой их по низу на высоту 0,15 м",
        ],
      },
      {
        code: "20501181",
        text: "Что из перечисленного не соответствует требованиям безопасности к расположению гибкого кабеля, питающего передвижные механизмы?",
        answers: [
          "Гибкие кабели должны подвешиваться с провисом",
          "Высота подвески кабеля должна исключать возможность его повреждения",
          "Допускается располагать на почве часть гибкого кабеля, питающего передвижные механизмы, на протяжении не более 25 м",
          "Расположение кабеля на почве должно исключать возможность его повреждения движущейся машиной",
        ],
        correctAnswers: [
          "Допускается располагать на почве часть гибкого кабеля, питающего передвижные механизмы, на протяжении не более 25 м",
        ],
      },
      {
        code: "20501182",
        text: "Каким должно быть минимальное расстояние между стоящими на разгрузке и проезжающими транспортными средствами?",
        answers: ["2 м", "3 м", "4 м", "5 м", "6 м"],
        correctAnswers: ["5 м"],
      },
      {
        code: "20501183",
        text: "Какие схемы электроснабжения должны быть в наличии на угольном разрезе?",
        answers: [
          "Только схема электроснабжения, нанесенная на план горных работ, утвержденная техническим руководителем (главным инженером) угольного разреза",
          "Только принципиальная однолинейная схема",
          "Только отдельная схема электроснабжения для сезонных электроустановок перед вводом их в работу",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20501184",
        text: "Что из перечисленного не соответствует требованиям организации безопасного обслуживания электроустановок и сетей?",
        answers: [
          "При изменении электроснабжения электроустановки все изменения необходимо внести в схему электроснабжения в течение 3 суток",
          "Для организации безопасного обслуживания электроустановок и сетей должны быть определены и оформлены распоряжениями технического руководителя (главного инженера) угольного разреза границы обслуживания электротехническим персоналом, назначены лица, ответственные за безопасную эксплуатацию электроустановок, по организации и структурным подразделениям",
          "Лица, ответственные за безопасную эксплуатацию электроустановок, должны быть обучены и аттестованы на знание правил безопасной эксплуатации электроустановок",
          "Лица, работающие в электроустановках и на линиях электропередач (ЛЭП) должны выполнять организационные и технические мероприятия, предусмотренные требованиями Инструкции по безопасной эксплуатации электроустановок в горнорудной промышленности",
        ],
        correctAnswers: [
          "При изменении электроснабжения электроустановки все изменения необходимо внести в схему электроснабжения в течение 3 суток",
        ],
      },
      {
        code: "20501185",
        text: "В каких случаях допускается применение сетей напряжением до 1 кВ с глухозаземленной нейтралью?",
        answers: [
          "Для питания стационарных потребителей, расположенных за пределами открытых горных работ",
          "Для питающихся от отдельных трансформаторов установок освещения стационарных перегрузочных пунктов и отвалов",
          "Для въездных (выездных) траншей",
          "Для осветительных установок и сетей СЦБ",
          "Во всех перечисленных",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "20501186",
        text: "В какие сроки должна проверяться исправность действия (срабатывания) реле утечки тока?",
        answers: [
          "Каждую смену перед началом работы",
          "1 раз в сутки",
          "1 раз в 3 дня",
          "1 раз в 10 дней",
        ],
        correctAnswers: ["Каждую смену перед началом работы"],
      },
      {
        code: "20501187",
        text: "С какой периодичностью должна производиться проверка реле утечки тока на время срабатывания для реле утечки внутри экскаватора?",
        answers: [
          "Не реже                       1 раза в 10 дней",
          "Не реже       1 раза в 2 недели",
          "Не реже       1 раза в месяц",
          "Не реже       1 раза в 6 месяцев",
        ],
        correctAnswers: ["Не реже                       1 раза в 10 дней"],
      },
      {
        code: "20501188",
        text: "Электроприводы каких установок не оборудуются электрической блокировкой, исключающей самозапуск механизмов после подачи напряжения питания?",
        answers: [
          "Электроприводы экскаваторов, буровых станков",
          "Электроприводы отвалообразователей, конвейеров",
          "Электроприводы насосов",
          "Электроприводы автоматизированных компрессорных установок, водоотливов",
        ],
        correctAnswers: [
          "Электроприводы автоматизированных компрессорных установок, водоотливов",
        ],
      },
      {
        code: "20501189",
        text: "Каков минимальный период проверки и контрольной наладки (испытания) основной селективной защиты от замыкания на землю на подстанциях, от которых получают питание передвижные карьерные электроустановки?",
        answers: ["3 месяца", "6 месяцев", "12 месяцев", "Не нормируется"],
        correctAnswers: ["6 месяцев"],
      },
      {
        code: "20501190",
        text: "Какие их перечисленных металлических частей электроустановок, нормально не находящиеся под напряжением, но которые могут в случае повреждения изоляции оказаться под ним, не подлежат заземлению?",
        answers: [
          "Корпуса электрических экскаваторов, буровых станков, насосов, конвейеров и других машин, станины и кожухи электрических машин, трансформаторов, выключателей",
          "Барьеры, металлические решетчатые и сплошные ограждения частей, находящихся под напряжением, металлические части, могущие оказаться под напряжением",
          "Металлические корпуса кабельных муфт, металлические оболочки кабелей и проводов, стальные трубы электропроводок",
          "Оборудование, установленное на заземленных металлических конструкциях, при этом на опорных поверхностях должны быть предусмотрены зачищенные и незакрашенные места для обеспечения электрического контакта",
        ],
        correctAnswers: [
          "Оборудование, установленное на заземленных металлических конструкциях, при этом на опорных поверхностях должны быть предусмотрены зачищенные и незакрашенные места для обеспечения электрического контакта",
        ],
      },
      {
        code: "20501191",
        text: "Каким должно быть максимальное сопротивление общего заземляющего устройства разреза?",
        answers: ["4 Ом", "6 Ом", "8 Ом", "10 Ом"],
        correctAnswers: ["4 Ом"],
      },
      {
        code: "20501192",
        text: "Какова минимальная периодичность измерения сопротивления общего заземляющего устройства передвижных электроустановок?",
        answers: [
          "Ежемесячно",
          "1 раз в квартал",
          "1 раз в 6 месяцев",
          "1 раз в год",
        ],
        correctAnswers: ["Ежемесячно"],
      },
      {
        code: "20501193",
        text: "В какие сроки проводится измерение сопротивления стационарных электроустановок?",
        answers: [
          "В периоды наибольшего высыхания (летом) и наибольшего промерзания (зимой) грунта",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в квартал",
          "Только после монтажа, реконструкции (переустройства) и ремонта заземляющих устройств",
        ],
        correctAnswers: [
          "В периоды наибольшего высыхания (летом) и наибольшего промерзания (зимой) грунта",
        ],
      },
      {
        code: "20501194",
        text: "При каком минимальном расстоянии по воздуху от подъемной или выдвижной части в любом ее положении, в том числе и при наибольшем допускаемом конструкцией подъеме или боковом вылете, до ближайшего провода воздушной линии электропередачи (ВЛ), находящейся под напряжением 35 кВ, допускается работа экскаваторов, погрузчиков, буровых станков?",
        answers: [
          "При расстоянии 1 м",
          "При расстоянии 2 м",
          "При расстоянии 3 м",
          "При расстоянии 4 м",
        ],
        correctAnswers: ["При расстоянии 4 м"],
      },
      {
        code: "20501195",
        text: "Каким должно быть расстояние от нижнего фазного провода воздушной линии электропередачи (ВЛ) напряжением 35 кВ до верхней точки машин или груза при пересечении (сближении) воздушной линии электропередачи (ВЛ) с автомобильными дорогами?",
        answers: [
          "Не менее 2,5 м",
          "Не менее 2,0 м",
          "Не менее 1,5 м",
          "Не менее 1,0 м",
        ],
        correctAnswers: ["Не менее 2,5 м"],
      },
      {
        code: "20501196",
        text: "Каким должно быть расстояние между передвижными опорами временных технологических воздушных линий электропередачи (ВЛ) напряжением до 35 кВ?",
        answers: [
          "Определяется по расчету с учетом климатических условий и обеспечения устойчивости опор и, как правило, не должно превышать 50 м",
          "Определяется по расчету с учетом климатических условий и обеспечения устойчивости опор и, как правило, не должно превышать 60 м",
          "Определяется по расчету с учетом климатических условий и обеспечения устойчивости опор и, как правило, не должно превышать 75 м",
          "Определяется по расчету с учетом климатических условий и обеспечения устойчивости опор и, как правило, не должно превышать 90 м",
        ],
        correctAnswers: [
          "Определяется по расчету с учетом климатических условий и обеспечения устойчивости опор и, как правило, не должно превышать 50 м",
        ],
      },
      {
        code: "20501197",
        text: "В каком случае выполняется одинарное крепление проводов?",
        answers: [
          "При пересечении воздушных линий электропередачи (ВЛ) с контактной сетью",
          "При пересечении воздушных линий электропередачи с воздушной линией электропередач (ВЛ с ВЛ)",
          "При пересечении воздушных линий электропередачи (ВЛ) с постоянными технологическими дорогами",
          "При пересечении воздушных линий электропередачи (ВЛ) с автомобильными дорогами, проложенными по уступам и отвалам",
        ],
        correctAnswers: [
          "При пересечении воздушных линий электропередачи (ВЛ) с автомобильными дорогами, проложенными по уступам и отвалам",
        ],
      },
      {
        code: "20501198",
        text: "В каком случае нарушены требования по прокладке и переноске (перетаскиванию) гибкого кабеля?",
        answers: [
          "Гибкие кабели, питающие передвижные машины, необходимо прокладывать так, чтобы исключалась возможность их повреждения, примерзания, завала породой, наезда на них транспортных средств и механизмов",
          "На обводненной площади кабель прокладывается по дну, для исключения самопроизвольного перемещения используются пригрузы",
          "Переноска (перетаскивание) гибкого кабеля должна производиться с помощью механизмов с применением приспособлений, обеспечивающих ограничение радиуса изгиба кабеля, или вручную",
          "При переноске (подноске) экскаваторного кабеля, находящегося под напряжением, обслуживающий персонал обязан пользоваться диэлектрическими перчатками или специальными устройствами с изолирующими рукоятками",
        ],
        correctAnswers: [
          "На обводненной площади кабель прокладывается по дну, для исключения самопроизвольного перемещения используются пригрузы",
        ],
      },
      {
        code: "20501199",
        text: "Какими должны быть размеры укрытий  (труб, коробов и т. п.),  применяемых в целях защиты от повреждений кабелей, в местах пересечения с железнодорожными путями и автомобильными дорогами?",
        answers: [
          "Должны превышать ширину железнодорожных путей или автомобильных дорог не менее чем на 1,2 м в каждую сторону",
          "Должны превышать ширину железнодорожных путей или автомобильных дорог не менее чем на 1,5 м в каждую сторону",
          "Должны превышать ширину железнодорожных путей или автомобильных дорог не менее чем на 1,7 м в каждую сторону",
          "Должны превышать ширину железнодорожных путей или автомобильных дорог не менее чем на 2 м в каждую сторону",
        ],
        correctAnswers: [
          "Должны превышать ширину железнодорожных путей или автомобильных дорог не менее чем на 2 м в каждую сторону",
        ],
      },
      {
        code: "20501200",
        text: "Какое максимальное количество соединений допускается в каждом пролете на один провод или трос?",
        answers: [
          "3 соединения",
          "4 соединения",
          "5 соединений",
          "6 соединений",
        ],
        correctAnswers: ["3 соединения"],
      },
      {
        code: "20501201",
        text: "Какая электрическая система должна применяться для осветительных сетей на разрезе, а также для стационарных световых точек на передвижных машинах, механизмах и агрегатах?",
        answers: [
          "Электрическая система с изолированной нейтралью при линейном напряжении не выше 220 В",
          "Электрическая система с заземленной нейтралью напряжением 380 В",
          "Электрическая система с изолированной нейтралью при линейном напряжении не выше 127 В",
          "Электрическая система с заземленной нейтралью напряжением 127 В",
        ],
        correctAnswers: [
          "Электрическая система с изолированной нейтралью при линейном напряжении не выше 220 В",
        ],
      },
      {
        code: "20501202",
        text: "Где разрешается применение напряжения 380/220 В в сети с заземленной нейтралью для освещения?",
        answers: [
          "Для осветительных сетей на угольном разрезе",
          "Для стационарных световых точек на передвижных машинах",
          "Для стационарных световых точек на механизмах и агрегатах",
          "Для освещения отвалов и автомобильных дорог вне угольного разреза при питании от отдельных трансформаторных подстанций",
        ],
        correctAnswers: [
          "Для освещения отвалов и автомобильных дорог вне угольного разреза при питании от отдельных трансформаторных подстанций",
        ],
      },
      {
        code: "20501203",
        text: "В каком случае требования по подвеске проводов электрического освещения и светильников на стационарных и передвижных опорах контактной сети постоянного тока напряжением до 1650 В включительно указаны неверно?",
        answers: [
          "Подвеска проводов электрического освещения и светильников допускается выше контактного провода на противоположной от него стороне опоры",
          "Расстояние от контактного провода до проводов освещения по вертикали должно быть не менее 1,5 м",
          "Изоляторы осветительной сети принимаются на напряжение 1650 В",
          "Расстояние от контактного провода до опоры при боковой подвеске должно быть не менее 0,5 м",
        ],
        correctAnswers: [
          "Расстояние от контактного провода до опоры при боковой подвеске должно быть не менее 0,5 м",
        ],
      },
      {
        code: "20501204",
        text: "Что не соответствует требованиям по обеспечению разрезов связью?",
        answers: [
          "Угольный разрез должен быть оборудован комплексом технических средств связи и сигнализации, обеспечивающих эффективное управление технологическими процессами и безопасностью работ, включающих два канала связи с подразделениями профессиональной аварийно-спасательной службы и (или) профессионального аварийно-спасательного формирования ПАСС(Ф), обслуживающим угольный разрез",
          "Для сигнальных устройств, кроме систем централизованных блокировок (СЦБ), питаемых напряжением не выше 24 В, допускается выполнение линий голыми проводами",
          "Установки связи должны обеспечиваться защитой от мешающего и опасного влияния линий высокого напряжения контактной сети, грозовых разрядов и блуждающих токов",
          "Питание устройств связи и сигнализации разреза, для исключения помех от осветительной сети, должно производиться только от специальных аккумуляторных батарей",
        ],
        correctAnswers: [
          "Питание устройств связи и сигнализации разреза, для исключения помех от осветительной сети, должно производиться только от специальных аккумуляторных батарей",
        ],
      },
      {
        code: "20501205",
        text: "Решение каких задач не преследуется при проведении работ по осушению территории производства работ?",
        answers: [
          "Обеспечение устойчивости откосов горных выработок и отвалов",
          "Снижение влажности полезных ископаемых и вскрышных пород",
          "Создание безопасных условий работы горно-транспортного оборудования",
          "Создание благоприятных условий для последующей рекультивации земель",
        ],
        correctAnswers: [
          "Создание благоприятных условий для последующей рекультивации земель",
        ],
      },
      {
        code: "20501206",
        text: "Какие технические решения должны предусматриваться в проекте для реализации мер по осушению территории производства работ?",
        answers: [
          "Только решения по понижению уровня подземных вод (при необходимости)",
          "Только решения по строительству сооружений для отвода воды за пределы зоны влияния дренажной системы",
          "Только решения по ограждению сооружений, горных выработок и отвалов от поверхностных вод и атмосферных осадков",
          "Все перечисленные решения",
        ],
        correctAnswers: ["Все перечисленные решения"],
      },
      {
        code: "20501207",
        text: "Что из перечисленного не соответствует требованиям к организации осушения разреза?",
        answers: [
          "Осушение месторождения должно производиться по проекту, утвержденному техническим руководителем (главным инженером) угольного разреза",
          "Каждый угольный разрез, не имеющий естественного стока поверхностных и почвенных вод, должен быть обеспечен водоотливом",
          "Устья стволов дренажных шахт, штолен, шурфов, буровых скважин и других выработок должны быть защищены от проникновения через них в горные выработки поверхностных вод",
          "При наличии на территории угольного разреза оползней поверхность оползневого массива должна быть ограждена нагорными канавами или предохранительными валами, защищающим массив от проникновения в него поверхностных и талых вод, снега, грязевых потоков",
          "Все перечисленное соответствует требованиям к организации осушения разреза",
        ],
        correctAnswers: [
          "Все перечисленное соответствует требованиям к организации осушения разреза",
        ],
      },
      {
        code: "20501208",
        text: "Что не соответствует требованиям к сбросу воды, удаляемой из разреза, а также сбросу воды, полученной в результате осушения месторождения?",
        answers: [
          "Вода, удаляемая из разреза, должна сбрасываться в ближайший водоток или в место, исключающее возможность ее обратного проникновения через трещины, провалы или водопроницаемые породы в выработки и заболачивание прилегающих территорий",
          "Сброс вод, полученных в результате осушения месторождения, должен производиться только после их осветления, а в необходимых случаях - после очистки от вредных примесей",
          "Устья дренажно-вентиляционных скважин должны быть обсажены перфорированными трубами, выступающими над подошвой уступа на высоту 1 м, трубы должны быть окрашены в яркий цвет и на них должны быть нанесены номера скважин, а устье трубы должно быть наглухо перекрыто приваренным металлическим листом",
        ],
        correctAnswers: [
          "Устья дренажно-вентиляционных скважин должны быть обсажены перфорированными трубами, выступающими над подошвой уступа на высоту 1 м, трубы должны быть окрашены в яркий цвет и на них должны быть нанесены номера скважин, а устье трубы должно быть наглухо перекрыто приваренным металлическим листом",
        ],
      },
      {
        code: "20501209",
        text: "Какие требования предъявляются к воздуху рабочей зоны разреза?",
        answers: [
          "Воздух рабочей зоны должен содержать по объему около 12 % кислорода и не более 2,0 % углекислого газа; содержание других вредных газов и пыли не должно превышать предельно допустимых концентраций (ПДК)",
          "Воздух рабочей зоны должен содержать по объему около 15 % кислорода и не более 1,0 % углекислого газа; содержание других вредных газов и пыли не должно превышать предельно допустимых концентраций (ПДК)",
          "Воздух рабочей зоны должен содержать по объему около 18 % кислорода и не более 0,8 % углекислого газа; содержание других вредных газов и пыли не должно превышать предельно допустимых концентраций (ПДК)",
          "Воздух рабочей зоны должен содержать по объему около 20 % кислорода и не более 0,5 % углекислого газа; содержание других вредных газов и пыли не должно превышать предельно допустимых концентраций (ПДК)",
        ],
        correctAnswers: [
          "Воздух рабочей зоны должен содержать по объему около 20 % кислорода и не более 0,5 % углекислого газа; содержание других вредных газов и пыли не должно превышать предельно допустимых концентраций (ПДК)",
        ],
      },
      {
        code: "20501210",
        text: "Какое из требований безопасности к определению мест и периодичности отбора проб воздуха рабочей зоны угольного разреза указано неверно?",
        answers: [
          "Места отбора проб пыли и вредных газов и их периодичность устанавливаются графиком, утвержденным техническим руководителем (главным инженером) угольного разреза",
          "Отбор проб пыли и вредных газов производится не реже 1 раза в 6 месяцев для угольных разрезов глубиной отработки менее 150 м",
          "Отбор проб пыли и вредных газов производится не реже 1 раза в 5 месяцев для угольных разрезов глубиной отработки более 150 м",
          "Отбор проб пыли и вредных газов производится после каждого изменения технологии работ",
        ],
        correctAnswers: [
          "Отбор проб пыли и вредных газов производится не реже 1 раза в 5 месяцев для угольных разрезов глубиной отработки более 150 м",
        ],
      },
      {
        code: "20501211",
        text: "Какими средствами индивидуальной защиты органов дыхания (СИЗОД) должен быть обеспечен обслуживающий персонал на рабочих местах, где концентрация пыли превышает установленные предельно допустимые концентрации (ПДК) содержание пыли и вредных газов?",
        answers: [
          "Респираторами",
          "Фильтрующими противогазами",
          "Изолирующими противогазами",
          "Шланговыми противогазами",
        ],
        correctAnswers: ["Изолирующими противогазами"],
      },
      {
        code: "20501212",
        text: "Какие меры принимаются для снижения пылеобразования при положительной температуре воздуха? Укажите все правильные ответы",
        answers: [
          "Отбитая при взрыве горная масса смачивается водой (растворами смачивающих веществ)",
          "На автомобильных дорогах проводится полив дорог водой с применением при необходимости связующих добавок",
          "При сдувании пыли с обнаженных поверхностей в разрезе и на отвале производятся обработка обнаженных поверхностей связующими растворами и их озеленение",
          "Все перечисленные меры",
        ],
        correctAnswers: [
          "На автомобильных дорогах проводится полив дорог водой с применением при необходимости связующих добавок",
          "При сдувании пыли с обнаженных поверхностей в разрезе и на отвале производятся обработка обнаженных поверхностей связующими растворами и их озеленение",
        ],
      },
      {
        code: "20501213",
        text: "Какие действия не соответствуют требованиям безопасности при разработке полезных ископаемых с повышенным радиационным фоном?",
        answers: [
          "На угольных разрезах, разрабатывающих сопутствующие полезные ископаемые с повышенным радиационным фоном, должен осуществляться радиационный контроль. Результаты замеров радиационного фона фиксируются в журнале учета радиационного фона",
          "Для устранения возможного пылеобразования и разноса радиоактивных аэрозолей с поверхности намывного откоса при эксплуатации гидроотвала его необходимо покрывать по мере намыва до проектных отметок распыляемыми аэрозолями до образования защитной пленки",
          "Для контроля уровня радиоактивности грунтовых вод должны быть предусмотрены пробоотборные (наблюдательные) скважины по периметру гидроотвала и по направлению потока грунтовых вод",
          "Местоположение и число пробоотборных (наблюдательных) скважин определяются в зависимости от гидрогеологических условий с таким расчетом, чтобы расстояние между скважинами было не менее 300 м. При этом 2 скважины должны быть за пределами санитарно-защитной зоны",
        ],
        correctAnswers: [
          "Для устранения возможного пылеобразования и разноса радиоактивных аэрозолей с поверхности намывного откоса при эксплуатации гидроотвала его необходимо покрывать по мере намыва до проектных отметок распыляемыми аэрозолями до образования защитной пленки",
        ],
      },
      {
        code: "20501214",
        text: "Каким должно быть расстояние между пробоотборными (наблюдательными) скважинами, располагаемыми по периметру гидроотвала и по направлению потока грунтовых вод для контроля уровня радиоактивности грунтовых вод?",
        answers: [
          "Не менее 300 м",
          "Не менее 400 м",
          "Не менее 500 м",
          "Не менее 800 м",
        ],
        correctAnswers: ["Не менее 300 м"],
      },
      {
        code: "20501215",
        text: "Чем из перечисленного в обязательном порядке должен быть оборудован пункт первой медицинской помощи на каждом угольном разрезе?",
        answers: [
          "Телефонной связью",
          "Системами видеонаблюдения",
          "Сатураторной установкой",
          "Не регламентируется",
        ],
        correctAnswers: ["Телефонной связью"],
      },
    ],
    20502: [
      {
        code: "20502000",
        text: "Какие виды работ осуществляются на обогатительной фабрике?",
        answers: [
          "Только прием, складирование, хранение угля",
          "Только механическое и физико-химическое обогащение угля",
          "Только брикетирование, сушка, погрузка (отгрузка) готовой продукции и отходов переработки и обогащения",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20502001",
        text: "Кто утверждает перечень работ повышенной опасности и список руководителей этих работ?",
        answers: [
          "Руководитель территориального органа Ростехнадзора",
          "Командир профессиональной аварийно-спасательной службы и (или) профессионального аварийно-спасательного формирования",
          "Руководитель фабрики",
          "Главный инженер фабрики",
        ],
        correctAnswers: ["Руководитель фабрики"],
      },
      {
        code: "20502002",
        text: 'Какие задачи должна указывать эксплуатирующая организация в подразделе "Задачи эксплуатирующей организации в области ПБ" при разработке положения о единой системе управления промышленной безопасностью и охраной труда для организаций по добыче (переработке) угля (горючих сланцев)?',
        answers: [
          "Управление основными производственными рисками",
          "Обеспечение противоаварийной устойчивости и готовности к локализации и ликвидации последствий аварии",
          "Выявление опасных технологий, участков, рабочих мест и предупреждение несчастных случаев",
          "Финансирование мероприятий по обеспечению безопасной эксплуатации опасных производственных объектов",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20502003",
        text: "Какие разделы не включает в себя Положение о системе управления промышленной безопасностью и охраной труда (СУПБиОТ)?",
        answers: [
          "Система управления охраной труда",
          "Проведение расследований причин инцидентов",
          "Система управления промышленной безопасностью",
          "Все перечисленные разделы",
        ],
        correctAnswers: ["Проведение расследований причин инцидентов"],
      },
      {
        code: "20502004",
        text: "Какова минимально допустимая численность работников для производства работ по сливу из железнодорожных цистерн химически токсичных веществ: антраценового и каменноугольного масел, флотационных реагентов и очистка от них железнодорожных цистерн и хранилищ?",
        answers: [
          "3 работника",
          "2 работника",
          "4 работника",
          "Не нормируется",
        ],
        correctAnswers: ["2 работника"],
      },
      {
        code: "20502005",
        text: "Какова минимально допустимая продолжительность вентиляции железнодорожной цистерны или емкости после обработки их паром или промывки горячей водой через верхний люк и охлаждения?",
        answers: ["5 минут", "15 минут", "30 минут", "40 минут"],
        correctAnswers: ["30 минут"],
      },
      {
        code: "20502006",
        text: "На каком минимальном расстоянии от хранилища при сливе и очистке цистерн разрешается пользоваться открытым огнем и курить?",
        answers: ["5 м", "10 м", "20 м", "35 м"],
        correctAnswers: ["20 м"],
      },
      {
        code: "20502007",
        text: "В какие помещения допускается вход работников во время работы приточно-вытяжных систем?",
        answers: [
          "Запрещается вход во все перечисленные и другие подсобные помещения",
          "В вентиляционные камеры",
          "В увлажнительные камеры",
          "В калориферные камеры",
        ],
        correctAnswers: [
          "Запрещается вход во все перечисленные и другие подсобные помещения",
        ],
      },
      {
        code: "20502008",
        text: "Какова предельно допустимая концентрация метана в производственных помещениях фабрики?",
        answers: ["1 %", "2,5 %", "1,5 %", "2 %"],
        correctAnswers: ["1 %"],
      },
      {
        code: "20502009",
        text: "Какова максимально допустимая концентрация взвешенной угольной пыли относительно нижнего предела взрывчатости (НПВ) угольной пыли, установленного для углей, перерабатываемых фабрикой (УОФ) (кроме фабрик, обогащающих антрациты)?",
        answers: ["15 %", "20 %", "30 %", "40 %"],
        correctAnswers: ["30 %"],
      },
      {
        code: "20502010",
        text: "С какой периодичностью проводят проверку эффективности работы приточно-вытяжных систем?",
        answers: [
          "Два раза в год",
          "Один раз в три года",
          "Один раз в два года",
          "Один раз в год",
        ],
        correctAnswers: ["Один раз в три года"],
      },
      {
        code: "20502011",
        text: "С какой периодичностью проводится определение содержания в пыли свободного диоксида кремния на фабриках?",
        answers: [
          "Не реже одного раза в год",
          "Не реже двух раз в год",
          "Не реже одного раза в два года",
          "Не реже одного раза в три года",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20502012",
        text: "Кто определяет периодичность и места проведения замеров воздуха на запыленность и загазованность производственных помещений фабрик?",
        answers: [
          "Начальник участка аэрологической безопасности",
          "Главный инженер фабрики",
          "Руководитель службы производственного контроля",
          "Начальник производства (цеха)",
        ],
        correctAnswers: ["Главный инженер фабрики"],
      },
      {
        code: "20502013",
        text: "Какое допускается максимальное содержание пыли и токсичных веществ в приточном воздухе, подаваемом системами принудительной вентиляции в помещения производственных и административно-бытовых зданий фабрик?",
        answers: [
          "30 % ПДК для воздуха рабочей зоны",
          "10 % ПДК для воздуха рабочей зоны",
          "15 % ПДК для воздуха рабочей зоны",
          "40 % ПДК для воздуха рабочей зоны",
        ],
        correctAnswers: ["30 % ПДК для воздуха рабочей зоны"],
      },
      {
        code: "20502014",
        text: "В каких помещениях следует предусматривать приточную вентиляцию с подачей наружного воздуха, обеспечивающую постоянный его подпор круглогодично? Укажите все правильные ответы.",
        answers: [
          'В помещениях категорий "Г" и "Д"',
          "В тамбур-шлюзах",
          'В помещениях машинных отделений лифтов зданий категорий "А" и "Б"',
          "В помещениях с выделением вредных газов и паров любого класса опасности",
        ],
        correctAnswers: [
          "В тамбур-шлюзах",
          'В помещениях машинных отделений лифтов зданий категорий "А" и "Б"',
        ],
      },
      {
        code: "20502015",
        text: "С какой периодичностью проводятся повторные испытания взрывчатости угольной пыли?",
        answers: [
          "Один раз в три года",
          "Один раз в два года",
          "Один раз в пять лет",
          "Один раз в год",
        ],
        correctAnswers: ["Один раз в три года"],
      },
      {
        code: "20502016",
        text: "При каком минимальном содержании оксида углерода в воздухе производственных помещений должны быть прекращены все работы и приняты меры по проветриванию загазованного производственного помещения?",
        answers: ["0,0017 %", "0,05 %", "1 %", "0,017 %"],
        correctAnswers: ["0,0017 %"],
      },
      {
        code: "20502017",
        text: "Каким способом должны применяться поверхностно-активные вещества с целью связывания осевшей пыли?",
        answers: [
          "Любым из перечисленных способов",
          "Комбинированным способом",
          "Механизированным способом",
          "Ручным способом",
        ],
        correctAnswers: ["Механизированным способом"],
      },
      {
        code: "20502018",
        text: "Из каких помещений допускается рециркуляция воздуха?",
        answers: [
          'Из помещений категорий "А" и "Б", которые оборудованы воздушными и воздушно-тепловыми завесами у наружных ворот и дверей',
          'Из 5-метровых зон вокруг оборудования, расположенного в помещениях категорий "В1" - "В4", "Г" и "Д", если в этих зонах могут образовываться взрывоопасные смеси из горючих газов, паров, аэрозолей с воздухом',
          "Из систем местных отсосов вредных веществ и взрывоопасных смесей с воздухом",
          "Из тамбур-шлюзов",
          "Из помещений, в которых максимальный расход наружного воздуха определяется массой выделяемых вредных веществ первого и второго классов опасности",
        ],
        correctAnswers: [
          'Из помещений категорий "А" и "Б", которые оборудованы воздушными и воздушно-тепловыми завесами у наружных ворот и дверей',
        ],
      },
      {
        code: "20502019",
        text: "С какой периодичностью должны очищаться стены в помещениях, не связанных с интенсивным пылением?",
        answers: [
          "Не реже четырех раз в месяц",
          "Не реже двух раз в месяц",
          "Не реже одного раза в месяц",
          "Один раз в смену",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20502020",
        text: "С какой периодичностью должен проводиться контроль запыленности и загазованности производственных помещений?",
        answers: [
          "Один раз в месяц",
          "Один раз в 2 месяца",
          "Один раз в квартал",
          "Один раз в год",
        ],
        correctAnswers: ["Один раз в месяц"],
      },
      {
        code: "20502021",
        text: "За сколько минут до пуска оборудования и после прекращения его работы должна включаться и выключаться аспирационная система технологического и транспортного оборудования?",
        answers: [
          "Соответственно за 3 минуты до пуска и через 3 минуты после прекращения работы",
          "Соответственно за 1 минуту до пуска и через 3 минуты после прекращения работы",
          "Соответственно за 2 минуты до пуска и через 2 минуты после прекращения работы",
          "Соответственно за 3 минуты до пуска и через 1 минуту после прекращения работы",
        ],
        correctAnswers: [
          "Соответственно за 3 минуты до пуска и через 3 минуты после прекращения работы",
        ],
      },
      {
        code: "20502022",
        text: "В какие сроки на фабриках, отнесенных к опасным по взрывам пыли и газа, должны быть направлены результаты испытаний взрывчатости угольной пыли?",
        answers: [
          "В 30-дневный срок с момента получения пробы на испытания",
          "В 10-дневный срок с момента получения пробы на испытания",
          "В 15-дневный срок с момента получения пробы на испытания",
          "В 40-дневный срок с момента получения пробы на испытания",
        ],
        correctAnswers: [
          "В 30-дневный срок с момента получения пробы на испытания",
        ],
      },
      {
        code: "20502023",
        text: "Что ежесменно контролируется на обогатительных фабриках, где установлен пылегазовый режим?",
        answers: [
          "Только содержание метана в воздухе помещений",
          "Только содержание пыли в воздухе помещений",
          "Только содержание CO и CO₂ в воздухе помещений",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20502024",
        text: "Какой длительности должен быть звуковой сигнал, подаваемый перед дистанционным пуском механизмов?",
        answers: [
          "Не менее 5 секунд",
          "Не менее 10 секунд",
          "Не менее 7 секунд",
          "Не менее 2 секунд",
        ],
        correctAnswers: ["Не менее 5 секунд"],
      },
      {
        code: "20502025",
        text: "Какой минимальный уклон должны иметь верхние горизонтальные плоскости зданий, сооружений и отдельных помещений фабрики, в которых предусмотрена уборка угольной пыли мокрым способом для обеспечения стока воды?",
        answers: ["5 %", "10 %", "15 %", "20 %"],
        correctAnswers: ["10 %"],
      },
      {
        code: "20502026",
        text: "Каким должен быть объем отсасываемого воздуха, необходимый для поддержания разрежения под зонтом пневматического сепаратора в производственных помещениях фабрики с пневматическими методами обогащения?",
        answers: [
          "На 5 - 10 % больше объема, нагнетаемого под деку пневматического сепаратора",
          "На 8 - 15 % больше объема, нагнетаемого под деку пневматического сепаратора",
          "На 10 - 20 % больше объема, нагнетаемого под деку пневматического сепаратора",
          "На 20 - 25 % больше объема, нагнетаемого под деку пневматического сепаратора",
        ],
        correctAnswers: [
          "На 20 - 25 % больше объема, нагнетаемого под деку пневматического сепаратора",
        ],
      },
      {
        code: "20502027",
        text: "Каково максимально допустимое процентное содержание кислорода в дымовых газах в пересчете на сухой газ должно поддерживаться в трактах сушильных установок для предотвращения взрыва при сушке сланцев?",
        answers: ["10 %", "16 %", "12 %", "20 %"],
        correctAnswers: ["16 %"],
      },
      {
        code: "20502028",
        text: "Какова максимально допустимая температура газов перед дымососами термических сушильных установок для каменных углей с выходом летучих веществ менее 35 %, антрацитов и полуантрацитов?",
        answers: ["90 °С", "110 °С", "120 °С", "130 °С"],
        correctAnswers: ["120 °С"],
      },
      {
        code: "20502029",
        text: "Какие термометры используются для измерения температуры газов перед дымососами термических сушильных установок?",
        answers: [
          "Газовые термометры",
          "Механические термометры",
          "Оптические термометры (пирометры)",
          "Электронные термометры",
        ],
        correctAnswers: ["Электронные термометры"],
      },
      {
        code: "20502030",
        text: "С какой периодичностью должен производиться отбор проб воздуха на запыленность и загазованность в зоне пребывания работников?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в 2 месяца",
          "Не реже одного раза в 4 месяца",
          "Не реже двух раз в 9 месяцев",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20502031",
        text: "Кто утверждает план ликвидации аварий (ПЛА) на обогатительной фабрике?",
        answers: [
          "Руководитель фабрики",
          "Руководитель подразделения ПАСС(Ф), обслуживающего фабрику",
          "Руководитель Ростехнадзора",
          "Главный инженер фабрики",
        ],
        correctAnswers: ["Главный инженер фабрики"],
      },
      {
        code: "20502032",
        text: "Не позднее какого срока должен быть согласован и утвержден план ликвидации аварий (ПЛА) до ввода его в действие?",
        answers: ["За 15 дней", "За 25 дней", "За 35 дней", "За 40 дней"],
        correctAnswers: ["За 15 дней"],
      },
      {
        code: "20502033",
        text: "На какой максимальный срок разрабатывается план ликвидации аварий (ПЛА) на обогатительной фабрике?",
        answers: [
          "ПЛА разрабатывается на 3 года",
          "ПЛА разрабатывается на 5 лет",
          "ПЛА разрабатывается на 2 года",
          "ПЛА разрабатывается на 12 месяцев",
        ],
        correctAnswers: ["ПЛА разрабатывается на 5 лет"],
      },
      {
        code: "20502034",
        text: "В какой максимальный срок должны быть внесены изменения в текстовую и графическую часть ПЛА при изменении технологии производства, а также путей вывода работников в безопасное место?",
        answers: [
          "В суточный срок",
          "В течение 3 дней",
          "В течение 5 дней",
          "В течение 7 дней",
        ],
        correctAnswers: ["В суточный срок"],
      },
      {
        code: "20502035",
        text: "Кто подготавливает заключение о противоаварийной готовности фабрики?",
        answers: [
          "Руководитель фабрики",
          "Главный инженер фабрики",
          "Работник профессиональных аварийно-спасательных служб и (или) профессиональных аварийно-спасательных формирований ПАСС(Ф), назначенный руководителем ПАСС(Ф)",
          "Главный энергетик фабрики",
        ],
        correctAnswers: [
          "Работник профессиональных аварийно-спасательных служб и (или) профессиональных аварийно-спасательных формирований ПАСС(Ф), назначенный руководителем ПАСС(Ф)",
        ],
      },
      {
        code: "20502036",
        text: "Что должен включать план ликвидации аварий (ПЛА)?",
        answers: [
          "Только оперативную часть",
          "Только распределение обязанностей между отдельными лицами, участвующими в ликвидации аварий, и порядок их действий",
          "Только правила поведения работников фабрики и подрядных организаций при аварии",
          "Только список должностных лиц и учреждений, которые должны быть немедленно извещены об аварии",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20502037",
        text: "Где должен находиться план ликвидации аварий (ПЛА) со всеми приложениями?",
        answers: [
          "Только у главного инженера",
          "Только в диспетчерской фабрики",
          "Только в ПАСС(Ф)",
          "У всех перечисленных лиц и во всех перечисленных местах",
        ],
        correctAnswers: [
          "У всех перечисленных лиц и во всех перечисленных местах",
        ],
      },
      {
        code: "20502038",
        text: "Кто из перечисленных лиц является руководителем работ по ликвидации аварий на обогатительной фабрике?",
        answers: [
          "Главный инженер фабрики",
          "Командир профессиональной аварийно-спасательной службы (формирования)",
          "Руководитель территориального органа Ростехнадзора",
          "Руководитель фабрики",
        ],
        correctAnswers: ["Главный инженер фабрики"],
      },
      {
        code: "20502039",
        text: "Какова минимальная периодичность проведения в организации учебных тревог под руководством главного инженера фабрики?",
        answers: [
          "Один раз в год",
          "Один раз в 4 месяца",
          "Один раз в 2 года",
          "Не нормируется",
        ],
        correctAnswers: ["Один раз в год"],
      },
      {
        code: "20502040",
        text: "Какова минимальная периодичность проведения в организации учений по плану ликвидации аварий (ПЛА) с руководителями и работниками фабрики и подрядных организаций?",
        answers: [
          "Один раз в 3 месяца",
          "Один раз в 9 месяцев",
          "Один раз в год",
          "Не нормируется",
        ],
        correctAnswers: ["Один раз в 3 месяца"],
      },
      {
        code: "20502041",
        text: "Кто осуществляет руководство проведением учебных тревог в организации?",
        answers: [
          "Главный инженер фабрики",
          "Командир профессиональной аварийно-спасательной службы",
          "Представитель территориального органа Ростехнадзора",
          "Руководитель организации",
        ],
        correctAnswers: ["Главный инженер фабрики"],
      },
      {
        code: "20502042",
        text: "Кто осуществляет руководство проведением учений по плану ликвидации аварий с работниками фабрики и подрядных организаций?",
        answers: [
          "Главный инженер фабрики",
          "Командир профессиональной аварийно-спасательной службы",
          "Представитель территориального органа Ростехнадзора",
          "Руководитель фабрики",
        ],
        correctAnswers: ["Главный инженер фабрики"],
      },
      {
        code: "20502043",
        text: "Какие производственные операции, связанные с применением открытого огня, искрообразованием и нагреванием до температуры, способной вызвать воспламенение материалов и конструкций, относятся к огневым работам?",
        answers: [
          "Только электросварочные, газосварочные и паяльные работы",
          "Только бензо- и керосинорезка",
          "Только механическая обработка металла",
          "Только работы с применением ацетиленовых генераторов",
          "Только работы с применением газопламенной обработки металлов с использованием кислорода и сжиженных (сжатых) газов",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20502044",
        text: "Кем определяется расположение постоянных мест проведения огневых работ?",
        answers: [
          "Мастером шахты (фабрики)",
          "Руководителем шахты (фабрики)",
          "Главным инженером шахты (фабрики)",
          "Командиром пожарной части, обслуживающей организацию",
        ],
        correctAnswers: ["Руководителем шахты (фабрики)"],
      },
      {
        code: "20502045",
        text: "Какие категории по пожарной и взрывопожарной опасности установлены для надшахтных зданий и сооружений шахт и фабрик для возможного оборудования в них постоянных мест проведения огневых работ? Выберите два правильных варианта ответа.",
        answers: [
          "Пониженная пожароопасность",
          "Пониженная взрывопожароопасность",
          "Взрывопожароопасность",
          "Повышенная взрывопожароопасность",
          "Умеренная пожароопасность",
        ],
        correctAnswers: [
          "Пониженная пожароопасность",
          "Умеренная пожароопасность",
        ],
      },
      {
        code: "20502046",
        text: "На каких объектах по категориям пожарной и взрывопожарной опасности оборудуются временные места проведения огневых работ?",
        answers: [
          "Только в горных выработках шахт, надшахтных зданиях и сооружениях шахт, имеющих пониженную пожароопасность",
          "Только в надшахтных зданиях и сооружениях шахт, имеющих умеренную пожароопасность",
          "В горных выработках шахт, надшахтных зданиях и сооружениях шахт (фабрик) независимо от их категории по пожарной и взрывопожарной опасности",
          "Только в надшахтных зданиях и сооружениях шахт с пониженной пожароопасностью",
        ],
        correctAnswers: [
          "В горных выработках шахт, надшахтных зданиях и сооружениях шахт (фабрик) независимо от их категории по пожарной и взрывопожарной опасности",
        ],
      },
      {
        code: "20502047",
        text: "Какие документы необходимы для проведения огневых работ на временных местах?",
        answers: [
          "Наряд-допуск на проведение огневых работ и инструкция по выполнению огневых работ",
          "Наряд-допуск на проведение огневых работ и план проведения огневых работ",
          "План проведения огневых работ и ведомость дефектов",
          "Распоряжение о проведении огневых работ и инструкция по выполнению огневых работ",
        ],
        correctAnswers: [
          "Наряд-допуск на проведение огневых работ и план проведения огневых работ",
        ],
      },
      {
        code: "20502048",
        text: "Кто утверждает перечень ИТР, имеющих право оформления нарядов-допусков?",
        answers: [
          "Командир пожарной части",
          "Лица, ответственные за безопасное выполнение работ на шахте (фабрике)",
          "Главный инженер шахты (фабрики)",
          "Руководитель шахты (фабрики)",
        ],
        correctAnswers: ["Руководитель шахты (фабрики)"],
      },
      {
        code: "20502049",
        text: "Кем оформляется наряд-допуск при проведении огневых работ работниками подрядных организаций?",
        answers: [
          "Техническим руководителем шахты (фабрики)",
          "ИТР подрядной организации или структурного подразделения, не входящего в состав шахты (фабрики)",
          "Руководителем структурного подразделения организации",
          "Лицом, ответственным за безопасное выполнение работ на шахте (фабрике)",
        ],
        correctAnswers: [
          "ИТР подрядной организации или структурного подразделения, не входящего в состав шахты (фабрики)",
        ],
      },
      {
        code: "20502050",
        text: "В скольких экземплярах оформляется наряд-допуск на проведение огневых работ на шахте (фабрике)?",
        answers: [
          "В одном экземпляре",
          "В двух экземплярах",
          "В четырех экземплярах",
          "В трех экземплярах",
        ],
        correctAnswers: ["В двух экземплярах"],
      },
      {
        code: "20502051",
        text: "Что необходимо сделать, если огневые работы не закончены в установленный в наряде-допуске срок?",
        answers: [
          "Главный инженер шахты (фабрики) продлевает срок проведения огневых работ до полного их выполнения",
          "Мастер структурного подразделения шахты (фабрики), где проводятся огневые работы, выписывает новый наряд-допуск в любом случае",
          "Руководитель структурного подразделения шахты (фабрики), где проводятся огневые работы, продлевает срок проведения огневых работ, но не более чем на две смены",
          "ИТР структурного подразделения шахты (фабрики), где проводятся огневые работы, продлевает срок проведения огневых работ, но не более чем на одну смену",
        ],
        correctAnswers: [
          "ИТР структурного подразделения шахты (фабрики), где проводятся огневые работы, продлевает срок проведения огневых работ, но не более чем на одну смену",
        ],
      },
      {
        code: "20502052",
        text: "В каком количестве и на какой срок выдается наряд-допуск на проведение огневых работ, связанных со строительством, реконструкцией, капитальным ремонтом, техническим перевооружением и с ремонтными работами, предусматривающими остановку производственного процесса и выполняемыми по графикам производства ремонтных работ?",
        answers: [
          "Один наряд-допуск на весь срок проведения работ",
          "По одному наряду-допуску на каждую смену проведения работ",
          "По одному наряду-допуску на каждый календарный месяц проведения работ",
          "По количеству временных мест проведения работ на каждую смену проведения работ",
        ],
        correctAnswers: ["Один наряд-допуск на весь срок проведения работ"],
      },
      {
        code: "20502053",
        text: "В каком случае для проведения огневых работ на временных местах допускается применение оборудования для кислородной резки металлов, работающего на жидком горючем (керосин, бензин)?",
        answers: [
          "Не допускается ни в каком случае",
          "При проведении работ в зданиях и сооружениях УОФ, имеющих пониженную пожароопасность и умеренную пожароопасность",
          "При технической невозможности проведения огневых работ с помощью электросварочного оборудования по решению главного инженера шахты (фабрики)",
          "Допускается в любом случае наравне с электросварочным оборудованием",
        ],
        correctAnswers: [
          "При технической невозможности проведения огневых работ с помощью электросварочного оборудования по решению главного инженера шахты (фабрики)",
        ],
      },
      {
        code: "20502054",
        text: "В каких случаях запрещается применение газовой сварки в горных выработках, во взрывопожароопасных и пожароопасных надшахтных зданиях и сооружениях шахт (фабрик)?",
        answers: [
          "Только в случаях использования ацетилена",
          "Только в случаях использования пропан-бутана",
          "Только в случаях использования каких-либо горючих углеводородов",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20502055",
        text: "Что запрещается к применению при проведении огневых работ на временных местах в любых случаях?",
        answers: [
          "Электросварочные установки",
          "Ацетиленовые генераторы",
          "Керосинорезы",
          "Сжиженные и сжатые газы",
          "Ацетилен в баллонах",
        ],
        correctAnswers: ["Ацетиленовые генераторы"],
      },
      {
        code: "20502056",
        text: "На каком минимальном расстоянии от места проведения огневых работ оборудование и механизмы не останавливаются при проведении огневых работ во взрывопожароопасных и пожароопасных надшахтных зданиях шахт (фабрик), в которых применяются аспирационные системы и (или) технические устройства, предназначенные для удаления запыленного воздуха из-под укрытий транспортно-технологического оборудования и рабочей зоны, или проводится мокрая уборка рабочих мест?",
        answers: ["15 метров", "20 метров", "35 метров", "Не нормируется"],
        correctAnswers: ["20 метров"],
      },
      {
        code: "20502057",
        text: "Что входит в технические решения по пылеулавливанию и пылеподавлению подземной части складов?",
        answers: [
          "Только аспирация",
          "Только гидрообеспыливание",
          "Только пылеулавливание",
          "Только вентиляция",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20502058",
        text: "При какой максимальной длине подземной тупиковой части на вновь проектируемых и реконструируемых фабриках в помещениях углеприемных ям подачи угля со склада запасной выход не предусматривается?",
        answers: ["15 м", "20 м", "25 м", "30 м"],
        correctAnswers: ["25 м"],
      },
      {
        code: "20502059",
        text: "При каком минимальном содержании метана в бункере запрещается проведение работ по его зачистке?",
        answers: ["0,5 %", "1,0 %", "1,5 %", "2,0 %"],
        correctAnswers: ["1,0 %"],
      },
      {
        code: "20502060",
        text: "Что из перечисленного запрещается при эксплуатации грохотов?",
        answers: [
          "Только пуск грохота при отсутствии или неисправности защитных ограждений и отсутствии крепления и натяжения просеивающих поверхностей",
          "Только ремонт, смазка подшипников, натяжение сит, закрепление болтов во время работы грохота",
          "Только регулировка работающего грохота посредством изменения параметров его движения",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20502061",
        text: "В каких ситуациях проводится отключение дробильной установки под нагрузкой?",
        answers: [
          "Только при внезапном появлении постороннего стука",
          "Только при поломке узлов дробилки или механизмов дробильной установки, необходимости предотвратить аварию или несчастный случай",
          "Только при необходимости предотвратить аварию или несчастный случай",
          "Во всех перечисленных",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "20502062",
        text: "Какова минимально допустимая численность работников для очистки внутренних поверхностей корпусов отсадочной машины от шлама и осевшего материала?",
        answers: [
          "Один работник",
          "Два работника",
          "Четыре работника",
          "Не нормируется",
        ],
        correctAnswers: ["Два работника"],
      },
      {
        code: "20502063",
        text: "Какова максимально допустимая общая (рабочая) влажность угля, подаваемого на пневматические сепараторы и пневматические отсадочные машины?",
        answers: ["8 %", "10 %", "5 %", "15 %"],
        correctAnswers: ["8 %"],
      },
      {
        code: "20502064",
        text: "С какой периодичностью должны очищаться отверстия дек пневматических отсадочных машин от угля и должна проверяться засыпка дек фарфоровыми шарами?",
        answers: [
          "Не реже двух раз в неделю",
          "Не реже одного раза в неделю",
          "Не реже трех раз в две недели",
          "Не реже одного раза в месяц",
        ],
        correctAnswers: ["Не реже двух раз в неделю"],
      },
      {
        code: "20502065",
        text: "Какова периодичность проверки исправности сигнализации заполнения реагентных бачков, устройств автоматического выключения двигателей насосов, подающих реагенты из реагентного отделения, вентиляции реагентных площадок?",
        answers: [
          "При сдаче-приемке смены",
          "Не реже двух раз в сутки",
          "Не реже одного раза в три дня",
          "Не реже трех раз в неделю",
        ],
        correctAnswers: ["При сдаче-приемке смены"],
      },
      {
        code: "20502066",
        text: "Какой должна быть ширина проходов вдоль камер флотационных машин?",
        answers: [
          "Не менее 1,0 м",
          "Не менее 0,9 м",
          "Не менее 0,2 м",
          "Не менее 0,5 м",
        ],
        correctAnswers: ["Не менее 1,0 м"],
      },
      {
        code: "20502067",
        text: "Какой должна быть высота ванны флотационной машины от обслуживающей площадки?",
        answers: [
          "Не менее 1,0 м",
          "Не менее 1,2 м",
          "Не менее 0,3 м",
          "Не менее 0,7 м",
        ],
        correctAnswers: ["Не менее 0,7 м"],
      },
      {
        code: "20502068",
        text: "При возникновении какой аварийной ситуации допускается отключение центрифуги под нагрузкой?",
        answers: [
          "Только при поломке узлов центрифуги",
          "Только при поломке или остановке аппаратов за центрифугой, следующих по технологической цепочке",
          "Только при необходимости предотвращения аварии или несчастного случая",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20502069",
        text: "Какие приборы применяются для контроля и обеспечения процесса фильтрования?",
        answers: [
          "Только вакуумметры на вакуум-насосах, ресиверах и распределительной головке",
          "Только манометры на распределительной головке",
          "Только приборы сигнализации уровня в ресиверах",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20502070",
        text: "Что необходимо проверить перед включением гипербарфильтра?",
        answers: [
          "Только состояние ограждений и отсутствие протеков на рабочих площадках",
          "Только отключение давления подвергающихся ремонтным работам системных узлов и проводок (гидравлика, сжатый воздух)",
          "Только закрытие люка гипербарфильтра на замок",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20502071",
        text: "В каком случае должны аварийно останавливаться в автоматическом режиме ленточный фильтр-пресс и вспомогательное оборудование?",
        answers: [
          "Только в случае снижения уровня пульпы в зумпфе до допустимого нижнего уровня",
          "Только в случае схода фильтровальной ленты",
          "Только в случае остановки привода фильтра-пресса",
          "Только в случае снижения давления воздуха от компрессора механизма натяжения фильтровальных сеток",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20502072",
        text: "С каким минимальным напором воды к разгрузочным отверстиям сгустителя должны подводиться водопроводные трубы для промывки отверстий при зашламовывании?",
        answers: ["5 Н/см2", "20 Н/см2", "15 Н/см2", "Не нормируется"],
        correctAnswers: ["20 Н/см2"],
      },
      {
        code: "20502073",
        text: "Кто утверждает инструкции по очистке от шлама радиальных, пирамидальных сгустителей и шламовых отстойников?",
        answers: [
          "Руководитель фабрики",
          "Мастер участка",
          "Главный инженер фабрики",
          "Представитель территориального органа Ростехнадзора",
        ],
        correctAnswers: ["Главный инженер фабрики"],
      },
      {
        code: "20502074",
        text: "Кто утверждает программу проведения режимных испытаний (РИ) сушильных установок (СУ)?",
        answers: [
          "Главный инженер фабрики",
          "Мастер участка",
          "Руководитель фабрики",
          "Главный механик",
        ],
        correctAnswers: ["Главный инженер фабрики"],
      },
      {
        code: "20502075",
        text: "В каких случаях должны проводиться режимные испытания (РИ) технологических схем обогащения и компоновок оборудования сушильных установок (СУ) (техническое диагностирование) фабрик?",
        answers: [
          "Только перед их вводом в эксплуатацию",
          "Только после реконструкции и (или) капитального ремонта",
          "Только при изменении сырьевой базы фабрики",
          "Только по графику не реже одного раза в два года в период эксплуатации",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20502076",
        text: "Кто утверждает комиссию по приему в эксплуатацию сушильных установок после их капитального ремонта?",
        answers: [
          "Главный инженер фабрики",
          "Представитель территориального органа Ростехнадзора",
          "Главный энергетик фабрики",
          "Главный механик фабрики",
        ],
        correctAnswers: ["Главный инженер фабрики"],
      },
      {
        code: "20502077",
        text: "Какие требования предъявляются к изготовлению быстроизнашивающихся участков сушильных установок (СУ)? Выберите два правильных варианта ответа.",
        answers: [
          "Быстроизнашивающиеся участки сушильных установок (СУ) должны изготавливаться из цветного металла",
          "Быстроизнашивающиеся участки сушильных установок (СУ) должны изготавливаться из чугуна",
          "Быстроизнашивающиеся участки сушильных установок (СУ) должны изготавливаться из нержавеющей стали",
          "Быстроизнашивающиеся участки сушильных установок (СУ) должны быть футерованы износостойкими материалами",
        ],
        correctAnswers: [
          "Быстроизнашивающиеся участки сушильных установок (СУ) должны изготавливаться из нержавеющей стали",
          "Быстроизнашивающиеся участки сушильных установок (СУ) должны быть футерованы износостойкими материалами",
        ],
      },
      {
        code: "20502078",
        text: "При каких скоростях газового потока в горизонтальных газоходах с углом наклона менее 45° допускается их эксплуатация?",
        answers: [
          "При обеспечении в них скорости газового потока не менее 20 м/с, а для вновь проектируемых - 25 м/с",
          "При обеспечении в них скорости газового потока не менее 15 м/с, а для вновь проектируемых - 20 м/с",
          "При обеспечении в них скорости газового потока не менее 12 м/с, а для вновь проектируемых - 18 м/с",
          "При обеспечении в них скорости газового потока не менее 10 м/с, а для вновь проектируемых - 15 м/с",
        ],
        correctAnswers: [
          "При обеспечении в них скорости газового потока не менее 20 м/с, а для вновь проектируемых - 25 м/с",
        ],
      },
      {
        code: "20502079",
        text: "Какова периодичность проверки исправности предохранительных клапанов работником, назначенным руководителем фабрики?",
        answers: [
          "Один раз в сутки",
          "Один раз в квартал",
          "Один раз в месяц",
          "Один раз в неделю",
        ],
        correctAnswers: ["Один раз в сутки"],
      },
      {
        code: "20502080",
        text: "При какой начальной температуре (на входе в аппарат) и температуре отработавших газов или воздуха (перед или после дымососа) при сушке антрацита и полуантрацита содержание в отработавших газах кислорода не ограничивается?",
        answers: [
          "При начальной температуре (на входе в аппарат) до 400 °C и температуре отработавших газов или воздуха (перед или после дымососа) до 70 °C",
          "При начальной температуре (на входе в аппарат) до 450 °C и температуре отработавших газов или воздуха (перед или после дымососа) до 75 °C",
          "При начальной температуре (на входе в аппарат) до 500 °C и температуре отработавших газов или воздуха (перед или после дымососа) до 80 °C",
          "Не регламентируется",
        ],
        correctAnswers: [
          "При начальной температуре (на входе в аппарат) до 400 °C и температуре отработавших газов или воздуха (перед или после дымососа) до 70 °C",
        ],
      },
      {
        code: "20502081",
        text: "В каких пределах устанавливают показания газоанализатора во время длительной остановки сушильного агрегата?",
        answers: [
          "(17 ± 0,1) %",
          "(19 ± 0,1) %",
          "(21 ± 0,1) %",
          "(23 ±0,1) %",
        ],
        correctAnswers: ["(21 ± 0,1) %"],
      },
      {
        code: "20502082",
        text: "Как должны быть окрашены все трубы, подводящие пар в СУ и отводящие конденсат от нее?",
        answers: [
          "В желтый цвет с зелеными кольцами (пар) и в красный цвет с синими кольцами (конденсат)",
          "В зеленый цвет с синими кольцами (пар) и в серый цвет с желтыми кольцами (конденсат)",
          "В красный цвет с желтыми кольцами (пар) и в зеленый цвет с синими кольцами (конденсат)",
          "В серый цвет с коричневыми кольцами (пар) и в желтый цвет с зелеными кольцами (конденсат)",
        ],
        correctAnswers: [
          "В красный цвет с желтыми кольцами (пар) и в зеленый цвет с синими кольцами (конденсат)",
        ],
      },
      {
        code: "20502083",
        text: "Какова минимально допустимая нагрузка, воспринимаемая поддерживающей решеткой или сеткой, на которые с внутренней стороны должны опираться предохранительные клапаны?",
        answers: ["20 кг", "80 кг", "100 кг", "150 кг"],
        correctAnswers: ["100 кг"],
      },
      {
        code: "20502084",
        text: "Каков минимально допустимый угол наклона к горизонту патрубков для отвода взрывных газов?",
        answers: ["30°", "45°", "60°", "Не нормируется"],
        correctAnswers: ["45°"],
      },
      {
        code: "20502085",
        text: "Каким устройством должны быть снабжены дымовые и растопочные трубы топок?",
        answers: [
          "Только клапаном (шибером) растопочной трубы, обеспечивающим герметичность перекрывания поперечного сечения трубы",
          "Только лазами для осмотра трубы, предусмотренными в нижней части дымовой трубы, фундаменте или подводящих газоходах, а в необходимых случаях - устройствами, обеспечивающими отвод конденсата и очистку от шлама",
          "Только перекрывающим клапаном (шибером), имеющим дистанционный привод, позволяющий вручную открыть клапан при внезапном отключении электроэнергии",
          "Всеми перечисленными устройствами",
        ],
        correctAnswers: ["Всеми перечисленными устройствами"],
      },
      {
        code: "20502086",
        text: "С какой периодичностью должна производиться проверка, а при необходимости и промывка диспергационных решеток, бака рециркуляции, турбинок, шламоотводящих труб мокрых пылеуловителей?",
        answers: [
          "Не реже одного раза в неделю",
          "Не реже пяти раз в две недели",
          "Не реже трех раз в месяц",
          "По мере необходимости",
        ],
        correctAnswers: ["Не реже одного раза в неделю"],
      },
      {
        code: "20502087",
        text: "Какой должна быть температура подшипников во время работы дымососа?",
        answers: [
          "Не должна превышать 60 °С",
          "Не должна превышать 65 °С",
          "Не должна превышать 70 °С",
          "Не должна превышать 80 °С",
        ],
        correctAnswers: ["Не должна превышать 60 °С"],
      },
      {
        code: "20502088",
        text: "Какова максимально допустимая температура газов перед дымососом и для установок без ограничения содержания кислорода в сушильных установках (СУ)?",
        answers: [
          "120 °С - перед дымососом, 70 °С - для установок без ограничения содержания кислорода",
          "115 °С - перед дымососом, 45 °С - для установок без ограничения содержания кислорода",
          "110 °С - перед дымососом, 80 °С - для установок без ограничения содержания кислорода",
          "105 °С - перед дымососом, 75 °С - для установок без ограничения содержания кислорода",
        ],
        correctAnswers: [
          "120 °С - перед дымососом, 70 °С - для установок без ограничения содержания кислорода",
        ],
      },
      {
        code: "20502089",
        text: "Какова минимальная периодичность уборки угольной пыли с оборудования в течение смены?",
        answers: ["1 раз", "3 раза", "5 раз", "Не регламентируется"],
        correctAnswers: ["1 раз"],
      },
      {
        code: "20502090",
        text: "Какова максимально допустимая температура нагрева подшипников прессов на брикетном производстве при отсутствии нормальной смазки во время их работы?",
        answers: ["30 °С", "80 °С", "45 °С", "35 °С"],
        correctAnswers: ["80 °С"],
      },
      {
        code: "20502091",
        text: "Какова максимально допустимая температура подаваемого на прессование угля на брикетном производстве, при которой допускается аварийная остановка пресса?",
        answers: ["30 °С", "50 °С", "100 °С", "120 °С"],
        correctAnswers: ["100 °С"],
      },
      {
        code: "20502092",
        text: "Какова максимально допустимая температура стенок матричного канала на брикетном производстве, при которой допускается аварийная остановка пресса?",
        answers: ["80 °С", "40 °С", "100 °С", "120 °С"],
        correctAnswers: ["120 °С"],
      },
      {
        code: "20502093",
        text: "В каких случаях остановка пресса производится в нормальном порядке?",
        answers: [
          "Только в случае если температура охлаждающей воды выше 90 - 100 °С",
          "Только в случае если температура масла в системе смазки подшипников выше 80 °С",
          "Только в случае резкого падения давления масла в системе смазки или отсутствия сигнала о наличии потока масла",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20502094",
        text: "Какова максимальная периодичность режимных испытаний (РИ) технологических схем обогащения и компоновок оборудования сушильных установок (СУ) фабрик?",
        answers: [
          "1 раз в год",
          "1 раз в 5 лет",
          "1 раз в 2 года",
          "Не регламентируется",
        ],
        correctAnswers: ["1 раз в 2 года"],
      },
      {
        code: "20502095",
        text: "При каком рабочем давлении пара проводятся испытания магистралей и механизмов парового, нефтебитумного и водяного хозяйства брикетного пресса после их монтажа?",
        answers: [
          "6 - 13 кг/см²",
          "5 - 10 кг/см²",
          "3 - 4 кг/см²",
          "14 - 18 кг/см²",
        ],
        correctAnswers: ["6 - 13 кг/см²"],
      },
      {
        code: "20502096",
        text: "При каком рабочем давлении воды проводятся испытания магистралей и механизмов парового, нефтебитумного и водяного хозяйства брикетного пресса после их монтажа?",
        answers: ["3 кг/см²", "8 кг/см²", "4 кг/см²", "2,5 кг/см²"],
        correctAnswers: ["4 кг/см²"],
      },
      {
        code: "20502097",
        text: "Какое сечение должно быть у стальной шины, предназначенной для заземления шины положительного полюса тока высокого напряжения выпрямительных подстанций?",
        answers: [
          "Не менее 100 мм²",
          "Не менее 70 мм²",
          "Не менее 85 мм²",
          "Не менее 65 мм²",
        ],
        correctAnswers: ["Не менее 100 мм²"],
      },
      {
        code: "20502098",
        text: "На какой высоте прокладываются открыто шины отрицательного полюса тока высокого напряжения в помещении выпрямительной подстанции?",
        answers: [
          "Не менее 2,5 м",
          "Не менее 2,4 м",
          "Не менее 2,3 м",
          "Не менее 2,2 м",
        ],
        correctAnswers: ["Не менее 2,5 м"],
      },
      {
        code: "20502099",
        text: "Какова минимально допустимая температура паровоздушной среды для включения электрофильтров в работу после получения сигналов о нормальном расходе пара?",
        answers: ["78 °С", "80 °С", "55 °С", "70 °С"],
        correctAnswers: ["78 °С"],
      },
      {
        code: "20502100",
        text: "С какой периодичностью осматриваются и протираются опорные изоляторы коронирующих электродов и верхние концевые муфты кабеля электрофильтров, опорные изоляторы в преобразовательной подстанции трансформаторов?",
        answers: [
          "Не реже двух раз в месяц",
          "Не реже одного раза в месяц",
          "Не реже трех раз в два месяца",
          "Не реже четырех раз в квартал",
        ],
        correctAnswers: ["Не реже двух раз в месяц"],
      },
      {
        code: "20502101",
        text: "Какое минимально допустимое время для измерения температуры в штабеле угля при его хранении?",
        answers: ["40 минут", "35 минут", "20 минут", "25 минут"],
        correctAnswers: ["20 минут"],
      },
      {
        code: "20502102",
        text: "При какой минимальной температуре нагрева угля должны приниматься меры для ликвидации очагов самовозгорания?",
        answers: ["30 °С", "35 °С", "45 °С", "50 °С", "60 °С"],
        correctAnswers: ["60 °С"],
      },
      {
        code: "20502103",
        text: "Какова максимально допустимая величина угла откоса складируемого материала при работе бульдозера на подъем?",
        answers: ["10°", "35°", "25°", "30°"],
        correctAnswers: ["25°"],
      },
      {
        code: "20502104",
        text: "Каков максимально допустимый уклон подъездных железнодорожных путей к бункеру при погрузке материала?",
        answers: ["10 промилле", "25 промилле", "15 промилле", "20 промилле"],
        correctAnswers: ["15 промилле"],
      },
      {
        code: "20502105",
        text: "На какие группы разделяются угли по склонности к окислению?",
        answers: [
          "Наиболее устойчивые к окислению, устойчивые, средней устойчивости к окислению, неустойчивые",
          "С повышенной устойчивостью к окислению, слабоокисляемые, средней устойчивости к окислению",
          "Неокисляемые, с устойчивостью к окислению",
          "С повышенной устойчивостью к окислению, устойчивые к окислению",
        ],
        correctAnswers: [
          "Наиболее устойчивые к окислению, устойчивые, средней устойчивости к окислению, неустойчивые",
        ],
      },
      {
        code: "20502106",
        text: "Какова минимально допустимая емкость резервных площадок от полезной емкости складов для освежения угля длительного хранения, охлаждения разогревшегося и складирования некондиционных углей?",
        answers: ["5 - 10 %", "11 - 15 %", "3 - 4 %", "2 - 3 %"],
        correctAnswers: ["5 - 10 %"],
      },
      {
        code: "20502107",
        text: "На каком максимальном расстоянии одной трубы от другой производится установка труб в штабель по верхнему основанию штабеля в шахматном порядке?",
        answers: ["7 м", "12 м", "30 м", "25 м"],
        correctAnswers: ["25 м"],
      },
      {
        code: "20502108",
        text: "С какой периодичностью проводят измерение температуры для углей I группы?",
        answers: [
          "Через каждые 10 дней",
          "Через каждые 5 дней",
          "Через каждые 3 дня",
          "Через каждые 7 дней",
        ],
        correctAnswers: ["Через каждые 10 дней"],
      },
      {
        code: "20502109",
        text: "При каких условиях необходимо немедленно принимать меры по ликвидации очага самовозгорания?",
        answers: [
          "При обнаружении в штабеле углей с температурой 60 °С и более или при повышении температуры со скоростью 5 °С в сутки",
          "При обнаружении в штабеле углей с температурой 50 °С или при повышении температуры со скоростью 3 °С в сутки",
          "При обнаружении в штабеле углей с температурой 40 °С или при повышении температуры со скоростью 2 °С в сутки",
          "При обнаружении в штабеле углей с температурой 30 °С или при повышении температуры со скоростью 1 °С в сутки",
        ],
        correctAnswers: [
          "При обнаружении в штабеле углей с температурой 60 °С и более или при повышении температуры со скоростью 5 °С в сутки",
        ],
      },
      {
        code: "20502110",
        text: "Какой ширины устанавливаются противопожарные разрывы и проезды от основания штабеля углей до сгораемых зданий и сооружений?",
        answers: [
          "Не менее 20 м",
          "Не менее 17 м",
          "Не менее 12 м",
          "Не менее 10 м",
        ],
        correctAnswers: ["Не менее 20 м"],
      },
      {
        code: "20502111",
        text: "Какой постоянный воздухообмен должен обеспечиваться в укрытых угольных складах напольного типа за счет естественной протяжки воздуха?",
        answers: [
          "8-кратный воздухообмен",
          "3-кратный воздухообмен",
          "10-кратный воздухообмен",
          "5-кратный воздухообмен",
        ],
        correctAnswers: ["8-кратный воздухообмен"],
      },
      {
        code: "20502112",
        text: "До какой температуры должны быть охлаждены брикеты перед погрузкой в вагоны?",
        answers: [
          "До температуры не более 45 °С",
          "До температуры не более 50 °С",
          "До температуры не более 60 °С",
          "До температуры не более 30 °С",
        ],
        correctAnswers: ["До температуры не более 45 °С"],
      },
      {
        code: "20502113",
        text: "Каким должно быть расстояние от головок крайних к штабелям рельсов железнодорожных путей и от бровки автодороги до подошвы штабеля топлива?",
        answers: [
          "Соответственно не ближе 2,5 м и не ближе 1,5 м",
          "Соответственно не ближе 1,5 м и не ближе 0,5 м",
          "Соответственно не ближе 2,2 м и не ближе 1,2 м",
          "Соответственно не ближе 1,8 м и не ближе 0,9 м",
        ],
        correctAnswers: ["Соответственно не ближе 2,5 м и не ближе 1,5 м"],
      },
      {
        code: "20502114",
        text: "Какой должна быть ширина проезжей части дороги для автосамосвалов грузоподъемностью до 10 т при однополосном движении?",
        answers: [
          "Не менее 7,5 м",
          "Не менее 5 м",
          "Не менее 3,5 м",
          "Не менее 4 м",
        ],
        correctAnswers: ["Не менее 7,5 м"],
      },
      {
        code: "20502115",
        text: "Какой должна быть ширина проезжей части дороги для автосамосвалов грузоподъемностью до 10 т при двухполосном движении?",
        answers: [
          "Не менее 10 м",
          "Не менее 20 м",
          "Не менее 15 м",
          "Не менее 3 м",
        ],
        correctAnswers: ["Не менее 10 м"],
      },
      {
        code: "20502116",
        text: "Каков минимально допустимый угол пересечения проезжей дороги с временными железнодорожными путями на переезде?",
        answers: ["20°", "30°", "45°", "55°"],
        correctAnswers: ["45°"],
      },
      {
        code: "20502117",
        text: "Каким должно быть нормальное положение автоматических и неавтоматических шлагбаумов переездов?",
        answers: [
          "Открытое у автоматического и неавтоматического шлагбаумов",
          "Закрытое у автоматического и открытое у неавтоматического шлагбаумов",
          "Открытое у автоматического и закрытое у неавтоматического шлагбаумов",
          "Не регламентируется",
        ],
        correctAnswers: [
          "Открытое у автоматического и закрытое у неавтоматического шлагбаумов",
        ],
      },
      {
        code: "20502118",
        text: "Каковы максимально допустимые уклоны дорог на подходах к переезду на временных железнодорожных путях?",
        answers: ["30 промилле", "40 промилле", "50 промилле", "60 промилле"],
        correctAnswers: ["50 промилле"],
      },
      {
        code: "20502119",
        text: "Что учитывается руководителем фабрики при установлении скорости движения поездов на железнодорожных путях?",
        answers: [
          "Только применяемый подвижной состав",
          "Только верхнее строение и профиль пути",
          "Только местные условия",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20502120",
        text: "Что из перечисленного не соответствует условиям погрузки горной массы в автомобили экскаваторами?",
        answers: [
          "Ожидающий погрузку автомобиль находится за пределами радиуса действия экскаваторного ковша и становится под погрузку только после разрешающего сигнала машиниста экскаватора",
          "Погрузка в кузов автомобиля производится только сзади или осуществляя перенос экскаваторного ковша над кабиной",
          "Высота падения груза должна быть минимально возможной и во всех случаях не превышать 5 м",
          "Запрещается односторонняя или сверхгабаритная загрузка, а также превышающая проектную грузоподъемность автомобиля",
        ],
        correctAnswers: [
          "Погрузка в кузов автомобиля производится только сзади или осуществляя перенос экскаваторного ковша над кабиной",
        ],
      },
      {
        code: "20502121",
        text: "Каково максимально допустимое расстояние при движении автомобиля задним ходом в пунктах погрузки?",
        answers: ["15 м", "50 м", "30 м", "Не нормируется"],
        correctAnswers: ["30 м"],
      },
      {
        code: "20502122",
        text: "Какова высота предохранительного вала разгрузочных площадок погрузочно-разгрузочных пунктов автомобильного транспорта?",
        answers: [
          "Не менее одного диаметра колеса самого маленького по грузоподъемности автомобиля",
          "Не менее одной трети диаметра колеса самого большого по грузоподъемности автомобиля",
          "Не менее половины диаметра колеса самого большого по грузоподъемности автомобиля",
          "Не нормируется",
        ],
        correctAnswers: [
          "Не менее половины диаметра колеса самого большого по грузоподъемности автомобиля",
        ],
      },
      {
        code: "20502123",
        text: "Какова минимально допустимая длина ограждений участков лент, набегающих на барабан?",
        answers: ["0,5 м", "0,8 м", "1 м", "1,5 м"],
        correctAnswers: ["1 м"],
      },
      {
        code: "20502124",
        text: "Какова минимально допустимая длительность звукового сигнала перед пуском конвейера?",
        answers: ["3 секунды", "5 секунд", "10 секунд", "Не нормируется"],
        correctAnswers: ["5 секунд"],
      },
      {
        code: "20502125",
        text: "На какую минимальную высоту должны ограждаться места подвески контргрузов и канаты натяжных устройств конвейеров сеткой с ячейками размером не более 50 x 50 мм?",
        answers: ["1,2 м", "1,5 м", "2 м", "2,5 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "20502126",
        text: "С какой скоростью должна перемещаться конвейерная лента для ручной выборки породы и посторонних предметов?",
        answers: [
          "Скорость движения не должна превышать 0,40 м/с",
          "Скорость движения не должна превышать 0,15 м/с",
          "Скорость движения не должна превышать 0,20 м/с",
          "Скорость движения не должна превышать 0,50 м/с",
        ],
        correctAnswers: ["Скорость движения не должна превышать 0,40 м/с"],
      },
      {
        code: "20502127",
        text: "На каком расстоянии друг от друга должны размещаться постоянные металлические мостики для перехода через конвейеры в производственных помещениях, в галереях и на эстакадах?",
        answers: [
          "Не более 50 м - в производственных помещениях и не более 100 м - в галереях и на эстакадах",
          "Не более 60 м - в производственных помещениях и не более 150 м - в галереях и на эстакадах",
          "Не более 80 м - в производственных помещениях и не более 170 м - в галереях и на эстакадах",
          "Не более 90 м - в производственных помещениях и не более 190 м - в галереях и на эстакадах",
        ],
        correctAnswers: [
          "Не более 50 м - в производственных помещениях и не более 100 м - в галереях и на эстакадах",
        ],
      },
      {
        code: "20502128",
        text: "Какой должна быть высота прохода вдоль конвейеров с постоянными рабочими местами, установленных в производственных помещениях?",
        answers: [
          "Не менее 2,1 м",
          "Не менее 2,5 м",
          "Не менее 0,8 м",
          "Не менее 1,5 м",
        ],
        correctAnswers: ["Не менее 2,1 м"],
      },
      {
        code: "20502129",
        text: "Как устраиваются трапы для безопасного движения людей по наклонным галереям с углом наклона более 7°?",
        answers: [
          "На полу должны укладываться трапы с планками, прикрепленными через 0,4 - 0,5 м, и устанавливаться металлические поручни вдоль става конвейера",
          "На полу должны укладываться трапы с планками, прикрепленными через 0,1 - 0,3 м, и устанавливаться сетчатые ограждения вдоль става конвейера",
          "На полу должны укладываться трапы с планками, прикрепленными через 0,2 м, и устанавливаться сплошные ограждения вдоль става конвейера",
          "На полу должны укладываться трапы с планками, прикрепленными через 0,5 м, и устанавливаться деревянные перила вдоль става конвейера",
        ],
        correctAnswers: [
          "На полу должны укладываться трапы с планками, прикрепленными через 0,4 - 0,5 м, и устанавливаться металлические поручни вдоль става конвейера",
        ],
      },
      {
        code: "20502130",
        text: "Какая минимальная ширина проходов должна быть для обслуживания конвейеров всех видов (кроме пластинчатых)?",
        answers: ["0,5 м", "0,7 м", "1 м", "1,5 м"],
        correctAnswers: ["0,7 м"],
      },
      {
        code: "20502131",
        text: "Каково максимально допустимое отсутствие поддерживающих роликов при эксплуатации ленточного конвейера?",
        answers: ["3 %", "10 %", "20 %", "Не нормируется"],
        correctAnswers: ["10 %"],
      },
      {
        code: "20502132",
        text: "В какой срок должны быть отражены на схеме все происшедшие изменения в электроснабжении за подписью работника, ответственного за электрохозяйство, с указанием его должности и даты внесения изменения?",
        answers: [
          "В суточный срок",
          "В пятидневный срок",
          "В трехдневный срок",
          "В семидневный срок",
        ],
        correctAnswers: ["В суточный срок"],
      },
      {
        code: "20502133",
        text: "Когда должна проверяться исправность действия (срабатывания) защиты?",
        answers: [
          "После каждого переключения электроустановки и во время технологических остановок, но не реже одного раза в 10 дней",
          "После каждого переключения электроустановки и во время технологических остановок, но не реже двух раз в шесть месяцев",
          "После каждого переключения электроустановки и во время технологических остановок, но не реже одного раза в пять месяцев",
          "После каждого переключения электроустановки и во время технологических остановок, но не реже одного раза в девять месяцев",
        ],
        correctAnswers: [
          "После каждого переключения электроустановки и во время технологических остановок, но не реже одного раза в 10 дней",
        ],
      },
      {
        code: "20502134",
        text: "С какой периодичностью необходимо производить проверку реле утечки тока в комплекте с автоматом на время отключения?",
        answers: [
          "Один раз в шесть месяцев, а также перед вводом защиты в работу после ее монтажа и в случае отказа",
          "Один раз в четыре месяца, а также после монтажа и в случае отказа",
          "Один раз в год, а также перед вводом защиты в работу",
          "Один раз в два года, а также перед вводом защиты в работу",
        ],
        correctAnswers: [
          "Один раз в шесть месяцев, а также перед вводом защиты в работу после ее монтажа и в случае отказа",
        ],
      },
      {
        code: "20502135",
        text: "С какой периодичностью на всех аппаратах защиты должны проводиться испытания максимальной токовой защиты?",
        answers: [
          "Не реже одного раза в год",
          "Не реже одного раза в два года",
          "Не реже одного раза в три года",
          "Не реже одного раза в пять лет",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20502136",
        text: "С какой периодичностью должен проводиться работником наружный осмотр всей заземляющей сети объекта?",
        answers: [
          "Не реже одного раза в месяц, а также после монтажа, реконструкции (переустройства) и ремонта заземляющих устройств",
          "Не реже одного раза в квартал, а также после монтажа, реконструкции (переустройства) заземляющих устройств",
          "Не реже одного раза в полугодие, а также после ремонта заземляющих устройств",
          "Не реже одного раза в год, а также после монтажа, реконструкции (переустройства) заземляющих устройств",
        ],
        correctAnswers: [
          "Не реже одного раза в месяц, а также после монтажа, реконструкции (переустройства) и ремонта заземляющих устройств",
        ],
      },
      {
        code: "20502137",
        text: "В какие сроки проводится измерение сопротивления стационарных электроустановок?",
        answers: [
          "Только в период наибольшего промерзания грунта зимой",
          "Только в период наибольшего высыхания грунта летом",
          "В периоды наибольшего высыхания грунта летом и наибольшего промерзания грунта зимой",
          "В грозовые периоды весной и летом",
        ],
        correctAnswers: [
          "В периоды наибольшего высыхания грунта летом и наибольшего промерзания грунта зимой",
        ],
      },
      {
        code: "20502138",
        text: "Какова максимально допустимая высота приставной лестницы?",
        answers: ["2 м", "5 м", "7 м", "15 м"],
        correctAnswers: ["5 м"],
      },
    ],
    20503: [
      {
        code: "20503000",
        text: "Кто распорядительным документом устанавливает порядок выдачи заданий (нарядов) на производство работ и порядок допуска работников к выполнению нарядов?",
        answers: [
          "Руководитель угледобывающей организации",
          "Главный инженер шахты",
          "Руководитель службы (участка) аэрологической безопасности (АБ)",
          "Руководитель Ростехнадзора",
        ],
        correctAnswers: ["Руководитель угледобывающей организации"],
      },
      {
        code: "20503001",
        text: "С какой периодичностью работники шахты должны проходить повторный инструктаж по безопасному ведению горных работ?",
        answers: [
          "Не реже чем каждые 6 месяцев",
          "Не реже чем каждые 9 месяцев",
          "Не реже одного раза в год",
          "Не реже одного раза в 3 года",
        ],
        correctAnswers: ["Не реже чем каждые 6 месяцев"],
      },
      {
        code: "20503002",
        text: "С какой периодичностью работники шахты должны проходить проверку знаний инструкций по профессиям?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 5 лет",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "20503003",
        text: "С какой периодичностью контролируется состояние горных выработок угольной шахты ИТР шахты?",
        answers: [
          "Еженедельно",
          "Через сутки",
          "Дважды в сутки",
          "Ежесуточно",
          "Все ответы неверны",
        ],
        correctAnswers: ["Ежесуточно"],
      },
      {
        code: "20503004",
        text: "Кто утверждает документацию на выполнение горных работ, связанных с проведением, креплением, поддержанием, ликвидацией горных выработок и ведением очистных работ?",
        answers: [
          "Руководитель шахты",
          "Главный механик шахты",
          "Начальник участка аэрологической безопасности",
          "Главный инженер шахты",
        ],
        correctAnswers: ["Главный инженер шахты"],
      },
      {
        code: "20503005",
        text: "Кто утверждает документацию по ведению горных работ, после внесения в нее дополнений, учитывающих происшедшие изменения горно-геологических и горнотехнических условий?",
        answers: [
          "Проектная организация",
          "Руководитель шахты",
          "Комиссия, назначенная распорядительным документом руководителя шахты",
          "Главный инженер шахты",
          "Все ответы неверны",
        ],
        correctAnswers: ["Главный инженер шахты"],
      },
      {
        code: "20503006",
        text: "С какой периодичностью проводятся инструктажи по промышленной безопасности и применению СИЗОД изолирующего типа в угледобывающей организации?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в месяц",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не реже 1 раза в 6 месяцев"],
      },
      {
        code: "20503007",
        text: "На какие виды работ составляются планы и схемы развития горных работ?",
        answers: [
          "Только на работы по добыче полезных ископаемых",
          "Только на работы, связанные с первичной переработкой минерального сырья",
          "Только на рекультивационные работы",
          "Только на подготовительные работы",
          "Только на маркшейдерские работы",
          "На все перечисленные виды работ",
        ],
        correctAnswers: ["На все перечисленные виды работ"],
      },
      {
        code: "20503008",
        text: "На какой срок составляется план развития горных работ по всем планируемым видам горных работ?",
        answers: ["На 6 месяцев", "На 1 год", "На 2 года", "На 3 года"],
        correctAnswers: ["На 1 год"],
      },
      {
        code: "20503009",
        text: "На какой максимальный срок составляется схема развития горных работ?",
        answers: ["На 1 год", "На 3 года", "На 5 лет", "На 7 лет"],
        correctAnswers: ["На 5 лет"],
      },
      {
        code: "20503010",
        text: "Кем утверждаются план и схема развития горных работ?",
        answers: [
          "Техническим руководителем организации - пользователя недр",
          "Руководителем организации - пользователя недр",
          "Органом государственного горного надзора",
          "Органом исполнительной власти субъекта Российской Федерации",
        ],
        correctAnswers: ["Руководителем организации - пользователя недр"],
      },
      {
        code: "20503011",
        text: "В какой срок пользователи недр представляют заявление о согласовании планов развития горных работ в орган государственного горного надзора?",
        answers: [
          "До 1 августа текущего года (года, предшествующего планируемому)",
          "До 15 августа текущего года (года, предшествующего планируемому)",
          "До 1 сентября текущего года (года, предшествующего планируемому)",
          "До 15 сентября текущего года (года, предшествующего планируемому)",
        ],
        correctAnswers: [
          "До 1 сентября текущего года (года, предшествующего планируемому)",
        ],
      },
      {
        code: "20503012",
        text: "Кем должны быть подписаны планы (схемы) развития горных работ, направляемые пользователем недр в электронном виде?",
        answers: [
          "Комиссией пользователя недр (подрядной организации)",
          "Техническим руководителем пользователя недр (подрядной организации)",
          "Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом",
        ],
      },
      {
        code: "20503013",
        text: "В каком виде подготавливаются схемы развития горных работ в отношении вскрышных, подготовительных, рекультивационных работ, а также работ по добыче полезных ископаемых и связанной с ней первичной переработкой минерального сырья, содержащие графическую часть и пояснительную записку с табличными материалами?",
        answers: [
          "Только в виде схем эксплуатации объектов недропользования и (или) их отдельных участков",
          "Только в виде схем эксплуатации объектов первичной переработки (подготовки) минерального сырья (для месторождений твердых полезных ископаемых, включая общераспространенные)",
          "Только в виде схем эксплуатации объектов обустройства и схемы подготовки углеводородов (для месторождений углеводородного сырья)",
          "Во всех перечисленных",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "20503014",
        text: "В какой период осуществляется рассмотрение планов развития горных работ?",
        answers: [
          "В период с 20 сентября по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
          "В период с 20 августа по 25 ноября года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
          "В период с 20 октября по 25 января года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
          "В период с 19 сентября по 30 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
        ],
        correctAnswers: [
          "В период с 20 сентября по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
        ],
      },
      {
        code: "20503015",
        text: "Из каких документов должны состоять графические материалы планов (схем) развития горных работ в зависимости от видов горных работ и видов полезных ископаемых?",
        answers: [
          "Из структурных карт, характерных разрезов, проекций, схем",
          "Из схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ)",
          "Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия",
          "Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем",
        ],
        correctAnswers: [
          "Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем",
        ],
      },
      {
        code: "20503016",
        text: "Какие данные не должны указываться в штампе графических материалов планов развития горных работ?",
        answers: [
          "Наименования организации, объекта недропользования (участка недр)",
          "Дата составления",
          "Подписи лиц (с указанием должности), ответственных за руководство геологическими и маркшейдерскими работами, и иных составителей графических материалов",
          "Название графического документа",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20503017",
        text: "Для кого предназначена Инструкция по порядку разработки планов ликвидации аварий на угольных шахтах, ознакомления, проведения учебных тревог и учений по ликвидации аварий, проведения плановой практической проверки аварийных вентиляционных режимов, предусмотренных планом ликвидации аварий?",
        answers: [
          "Для организаций, осуществляющих добычу угля подземным способом",
          "Для профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований",
          "Для надзорных и контролирующих органов",
          "Для всех перечисленных",
        ],
        correctAnswers: ["Для всех перечисленных"],
      },
      {
        code: "20503018",
        text: "На какой срок разрабатывается план ликвидации аварий (ПЛА) на угольных шахтах?",
        answers: [
          "На 6 месяцев",
          "На 10 месяцев",
          "На 1 год",
          "План составляется без ограничения срока действия, при возникновении изменений в план вносятся соответствующие исправления",
        ],
        correctAnswers: ["На 6 месяцев"],
      },
      {
        code: "20503019",
        text: "Сколько планов ликвидации аварий (ПЛА) разрабатывается для шахт, имеющих единую вентиляционную систему?",
        answers: [
          "Количество ПЛА определяет технический руководитель (главный инженер) угледобывающей организации",
          "В зависимости от количества шахт - на каждую отдельно",
          "Не менее двух ПЛА",
          "Один",
        ],
        correctAnswers: ["Один"],
      },
      {
        code: "20503020",
        text: "Когда ﻿должен быть утвержден план ликвидации аварий (ПЛА) на угольных шахтах?",
        answers: [
          "Не позднее чем за 15 календарных дней до ввода ПЛА в действие",
          "Не позднее чем за 21 календарный день до ввода ПЛА в действие",
          "Не позднее чем за 30 календарных дней до ввода ПЛА в действие",
          "Не позднее чем за 60 календарных дней до ввода ПЛА в действие",
        ],
        correctAnswers: [
          "Не позднее чем за 15 календарных дней до ввода ПЛА в действие",
        ],
      },
      {
        code: "20503021",
        text: "Кто из перечисленных специалистов представляет заключение о противоаварийной готовности шахты?",
        answers: [
          "Руководитель шахты",
          "Технический руководитель (главный инженер) шахты",
          "Работник профессиональных аварийно-спасательных служб (формирований) ПАСС(Ф), назначенный руководителем ПАСС(Ф)",
          "Представитель Ростехнадзора",
        ],
        correctAnswers: [
          "Работник профессиональных аварийно-спасательных служб (формирований) ПАСС(Ф), назначенный руководителем ПАСС(Ф)",
        ],
      },
      {
        code: "20503022",
        text: "В течение какого времени при изменении технологии производства, вводе новых и изоляции отработанных участков, изменении схемы вентиляции и путей выхода людей при аварии главным инженером шахты в ПЛА или в его отдельные позиции должны быть внесены соответствующие изменения?",
        answers: [
          "В течение месяца",
          "В течение недели",
          "В течение 3 рабочих дней",
          "В течение суток",
        ],
        correctAnswers: ["В течение суток"],
      },
      {
        code: "20503023",
        text: "Какие мероприятия проводятся на шахте перед каждым согласованием ПЛА?",
        answers: [
          "Проверка готовности членов вспомогательных горноспасательных команд (ВГК) шахты (не менее 10% списочного состава ВГК) и их расстановки, расстановки и состояния пунктов ВГК, организации деятельности ВГК и ее оснащенности",
          "Расчет устойчивости вентиляционных режимов в горных выработках при возникновении тепловой депрессии пожара",
          "Расчет времени загазирования забоев тупиковых выработок в случае остановки вентиляторов местного проветривания",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20503024",
        text: "Кто организует изучение ПЛА инженерно-техническими работниками шахты?",
        answers: [
          "Руководитель ПАСС(Ф)",
          "Руководитель работ по локализации и ликвидации последствий аварии",
          "Начальник участка (службы)",
          "Главный инженер шахты",
          "Все ответы неверны",
        ],
        correctAnswers: ["Главный инженер шахты"],
      },
      {
        code: "20503025",
        text: "У кого находятся оригиналы планов ликвидации аварий шахты со всеми приложениями?",
        answers: [
          "Один экземпляр находится у горного диспетчера шахты, другой в подразделении ПАСС(Ф), обслуживающем шахту",
          "Один экземпляр находится у горного диспетчера шахты, другой - у технического руководителя (главного инженера) шахты",
          "Один экземпляр находится у технического руководителя (главного инженера) шахты, другой - у начальника участка (службы)",
          "Один экземпляр находится у начальника участка (службы), другой - в подразделении ПАСС(Ф), обслуживающем шахту",
        ],
        correctAnswers: [
          "Один экземпляр находится у горного диспетчера шахты, другой в подразделении ПАСС(Ф), обслуживающем шахту",
        ],
      },
      {
        code: "20503026",
        text: "Какие мероприятия предусматриваются первыми в плане ликвидации аварий (ПЛА)?",
        answers: [
          "Мероприятия, направленные на спасение людей и уменьшение числа возможных жертв",
          "Мероприятия по аварийной подаче воды в шахту",
          "Мероприятия по проведению восстановительных работ в шахте",
          "Мероприятия по изменению режимов проветривания аварийного участка",
        ],
        correctAnswers: [
          "Мероприятия, направленные на спасение людей и уменьшение числа возможных жертв",
        ],
      },
      {
        code: "20503027",
        text: "Какие сведения запрещается включать в оперативную часть ПЛА?",
        answers: [
          "Маршруты движения отделений ПАСС(Ф) и последовательность выполняемых ими работ",
          "Мероприятия по спасению людей",
          "Направления вентиляционных струй",
          "Указания о восстановительных работах",
        ],
        correctAnswers: ["Указания о восстановительных работах"],
      },
      {
        code: "20503028",
        text: "При каких из перечисленных видов аварий в оперативной части планов ликвидации аварий (ПЛА) должны предусматриваться способы увеличения подачи воздуха на аварийные участки?",
        answers: [
          "Только при горном ударе",
          "Только при внезапном выбросе угля и газа",
          "Только при загазировании",
          "При всех перечисленных видах аварий",
        ],
        correctAnswers: ["При всех перечисленных видах аварий"],
      },
      {
        code: "20503029",
        text: "Какие действия, предусмотренные ПЛА, относительно режима энергоснабжения шахт при возникновении аварийных ситуаций указаны верно?",
        answers: [
          "При реверсивном режиме проветривания подача электроэнергии в шахту прекращается. Электроэнергия не отключается в выработках, по которым производится вывод людей из шахты с использованием механических средств, при условии, что концентрация метана в этих выработках менее 5%",
          "При виде аварии «взрыв» подача электроэнергии в шахту прекращается только при наличии возгорания",
          "При изменении направления движения вентиляционной струи в горных выработках решение о прекращении подачи электроэнергии принимает начальника участка (службы)",
          "При пожарах, внезапных выбросах угля и газа, загазированиях в ПЛА предусматривается отключение электроэнергии в аварийных выработках и по пути движения исходящей из них струи. В мероприятиях по отключению электроэнергии перечисляются все выработки с исходящей из аварийного участка вентиляционной струей и указывается способ отключения электроэнергии в этих выработках",
        ],
        correctAnswers: [
          "При пожарах, внезапных выбросах угля и газа, загазированиях в ПЛА предусматривается отключение электроэнергии в аварийных выработках и по пути движения исходящей из них струи. В мероприятиях по отключению электроэнергии перечисляются все выработки с исходящей из аварийного участка вентиляционной струей и указывается способ отключения электроэнергии в этих выработках",
        ],
      },
      {
        code: "20503030",
        text: "Кем производится оповещение об аварии на шахте в соответствии с ПЛА? Укажите все правильные ответы.",
        answers: [
          "Главным инженером шахты",
          "Руководителем работ по локализации и ликвидации последствий аварии (РЛА)",
          "Лицом, назначенным руководителем работ по локализации и ликвидации последствий аварии",
          "Руководителем профессиональной аварийно-спасательной службы (формирования)",
          "Работником профессиональной аварийно-спасательной службы (формирования)",
        ],
        correctAnswers: [
          "Руководителем работ по локализации и ликвидации последствий аварии (РЛА)",
          "Лицом, назначенным руководителем работ по локализации и ликвидации последствий аварии",
        ],
      },
      {
        code: "20503031",
        text: "Какие действия предусматриваются ПЛА для предупреждения развития аварии при пожаре?",
        answers: [
          "Включение водяных завес и водоразбрызгивателей на путях возможного развития пожара",
          "Мероприятия по предупреждению падения подъемных устройств в вертикальных и наклонных горных выработках при перегорании или обрыве канатов подъемных установок",
          "Закрытие противопожарных дверей и ляд в горных выработках",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20503032",
        text: "Кто осуществляет руководство работами по локализации и ликвидации последствий аварии?",
        answers: [
          "Руководитель ОПО",
          "Должностное лицо профессионального аварийно-спасательного формирования, обслуживающего ОПО, назначенное распорядительным документом руководителя профессионального аварийно-спасательного формирования",
          "Должностное лицо Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          " Технический руководитель (главный инженер) ОПО",
        ],
        correctAnswers: [" Технический руководитель (главный инженер) ОПО"],
      },
      {
        code: "20503033",
        text: "Какие из перечисленных обязанностей не должен выполнять руководитель горноспасательных работ? Укажите все правильные ответы.",
        answers: [
          "Обеспечивает прибытие горноспасательных отделений и других сил и средств ПАСС(Ф) для ведения горноспасательных работ в количестве и в сроки, предусмотренные ПЛА или оперативным планом",
          "Организует разработку оперативных планов",
          "Устанавливает режим работы и отдыха работников ПАСС(Ф)",
          "Организует оказание первой и медицинской помощи пострадавшим",
          "Организует ведение оперативного журнала ПАСС(Ф)",
        ],
        correctAnswers: [
          "Организует разработку оперативных планов",
          "Организует оказание первой и медицинской помощи пострадавшим",
        ],
      },
      {
        code: "20503034",
        text: "Каким образом устанавливаются порядок и обстоятельства, при которых допускается отстранить руководителя работ по ликвидации аварии и руководителя горноспасательных работ и назначить другое должностное лицо?",
        answers: [
          "На основании Инструкции по локализации и ликвидации последствий аварий на опасных производственных объектах, на которых ведутся горные работы",
          "Распорядительным документом, утверждаемым представителем территориального органа Ростехнадзора",
          "Распорядительным документом, утверждаемым представителем Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "Распорядительными документами, утверждаемыми руководителями ОПО и ПАСС(Ф) соответственно",
        ],
        correctAnswers: [
          "Распорядительными документами, утверждаемыми руководителями ОПО и ПАСС(Ф) соответственно",
        ],
      },
      {
        code: "20503035",
        text: "Какое из перечисленных требований к оперативным планам по локализации и ликвидации последствий аварий указаны неверно?",
        answers: [
          "Оперативный план не должен содержать ссылок на пункты ранее разработанных оперативных планов",
          "Последующие оперативные планы (№ 2, 3 и т.д.) разрабатываются в случаях, когда мероприятия предыдущего оперативного плана реализованы или требуется их корректировка",
          "Оперативные планы разрабатываются до окончания ведения горноспасательных работ",
          "Оперативный план утверждает руководитель горноспасательных работ",
        ],
        correctAnswers: [
          "Оперативный план утверждает руководитель горноспасательных работ",
        ],
      },
      {
        code: "20503036",
        text: "В каком приоритетном порядке предварительно определяется вид происшедшей аварии при отсутствии информации, позволяющей достоверно определить ее вид, в подземных горных выработках?",
        answers: [
          "Взрыв, горный удар, внезапный выброс, пожар, обрушение",
          "Взрыв, горный удар, обрушение, внезапный выброс, пожар",
          "Взрыв, пожар, внезапный выброс, горный удар, обрушение",
          "Взрыв, внезапный выброс, пожар, горный удар, обрушение",
        ],
        correctAnswers: [
          "Взрыв, внезапный выброс, пожар, горный удар, обрушение",
        ],
      },
      {
        code: "20503037",
        text: "Кто из перечисленных лиц обеспечивает подачу сжатого воздуха или воды к месту аварии в случаях, предусмотренных ПЛА?",
        answers: [
          "Руководитель энергомеханической службы ОПО",
          "Начальник участка, на котором произошла авария",
          "Специалист ОПО, ответственный за учет и выдачу индивидуальных головных светильников, самоспасателей, газоанализаторов",
          "Начальник службы (участка), обеспечивающей (обеспечивающего) аэрологическую безопасность подземных горных выработок",
        ],
        correctAnswers: ["Руководитель энергомеханической службы ОПО"],
      },
      {
        code: "20503038",
        text: "Из какого минимального количества человек должно состоять горноспасательное отделение или отделение вспомогательной горноспасательной команды при ведении горноспасательных работ в непригодной для дыхания рудничной атмосфере при работе в подземных горных выработках?",
        answers: [
          "Из 2 человек",
          "Из 3 человек",
          "Из 5 человек",
          "Из 6 человек",
          "Все ответы неверны",
        ],
        correctAnswers: ["Из 5 человек"],
      },
      {
        code: "20503039",
        text: "В каком из перечисленных случаев руководитель горноспасательных работ не проводит организацию подземной горноспасательной базы?",
        answers: [
          "Если горноспасательные работы проводятся в горных выработках с непригодной для дыхания рудничной атмосферой",
          "Если горноспасательные работы выполняются для ликвидации последствий прорыва воды при выполнении водолазных спусков",
          "Если все прибывшие на опасный производственный объект (ОПО) горноспасательные отделения привлечены к горноспасательным работам, определенным планом мероприятий по локализации и ликвидации последствий аварий (ПЛА), связанным со спасением людей, застигнутых аварией",
          "Если горноспасательные работы выполняются для ликвидации последствий прорыва обводненной горной массы при выполнении водолазных работ",
        ],
        correctAnswers: [
          "Если все прибывшие на опасный производственный объект (ОПО) горноспасательные отделения привлечены к горноспасательным работам, определенным планом мероприятий по локализации и ликвидации последствий аварий (ПЛА), связанным со спасением людей, застигнутых аварией",
        ],
      },
      {
        code: "20503040",
        text: "При какой минимальной концентрации сернистого газа в рудничной атмосфере в горных выработках горноспасательные работы приостанавливаются, и организуется вывод из зоны аварии людей, задействованных в данных работах?",
        answers: ["0,3 %", "0,5 %", "0,75 %", "1 %", "2 %"],
        correctAnswers: ["0,5 %"],
      },
      {
        code: "20503041",
        text: "При какой минимальной температуре в течение не менее 24 часов в местах предполагаемого нахождения людей работы по поиску и спасению людей не проводятся или прекращаются?",
        answers: ["40 °C", "60 °C", "80 °C", "100 °C"],
        correctAnswers: ["100 °C"],
      },
      {
        code: "20503042",
        text: "Какая документация должна находиться на командном пункте?",
        answers: [
          "План ликвидации аварий",
          "Журналы ОПО и ПАСС(Ф)",
          "Оперативные планы, текстовая и графическая документация, разрабатываемая в период локализации и ликвидации последствий аварии",
          "Вся перечисленная документация",
        ],
        correctAnswers: ["Вся перечисленная документация"],
      },
      {
        code: "20503043",
        text: "При каком виде аварии члены вспомогательной горноспасательной команды устанавливают связь с застигнутыми аварией людьми, организуют их спасение, предварительно усилив крепь, тем самым исключив возможные обрушения?",
        answers: [
          "При взрыве метана и (или) угольной пыли",
          "При пожаре в тупиковой выработке",
          "При обрушении в горной выработке",
          "При внезапном выбросе угля (породы)",
        ],
        correctAnswers: ["При обрушении в горной выработке"],
      },
      {
        code: "20503044",
        text: "С каким минимальным номинальным временем защитного действия допускается использование дыхательных аппаратов со сжатым воздухом на объектах открытых горных работ?",
        answers: ["4 часа", "3 часа", "2 часа", "1 час", "Все ответы неверны"],
        correctAnswers: ["1 час"],
      },
      {
        code: "20503045",
        text: "Какая устанавливается максимальная продолжительность пребывания работников ПАСС(Ф) и членов вспомогательной горноспасательной команды в непригодной для дыхания рудничной атмосфере с применением дыхательных аппаратов?",
        answers: [
          "2 часа",
          "3 часа",
          "4 часа",
          "5 часов",
          "Все ответы неверны",
        ],
        correctAnswers: ["4 часа"],
      },
      {
        code: "20503046",
        text: "Каким образом распределяется максимальная продолжительность (время) непрерывного пребывания работников ПАСС(Ф) в зоне высоких температур при передвижении по подземным горным выработкам с непригодной для дыхания рудничной атмосферой?",
        answers: [
          "По решению технического руководителя (главного инженера) ОПО",
          'Половина на передвижение в направлении "туда" и половина - в направлении "обратно"',
          'Две трети на передвижение в направлении "туда" и одна треть - в направлении "обратно"',
          'Одна треть на передвижение в направлении "туда" и две трети - в направлении "обратно"',
        ],
        correctAnswers: [
          'Одна треть на передвижение в направлении "туда" и две трети - в направлении "обратно"',
        ],
      },
      {
        code: "20503047",
        text: "Какое из перечисленных действий допускается при ведении горноспасательных работ в подземных горных выработках в зоне высоких температур с непригодной для дыхания рудничной атмосферой?",
        answers: [
          "Ведение горноспасательных работ, не связанных со спасением людей, в зоне высоких температур с непригодной для дыхания рудничной атмосферой без средств противотепловой индивидуальной защиты при температуре выше 50 °C",
          "Ведение горноспасательных работ в зоне высоких температур с непригодной для дыхания рудничной атмосферой при отсутствии связи горноспасательного отделения, ведущего горноспасательные работы, с руководителем горноспасательных работ или с горноспасательным отделением, находящимся на подземной горноспасательной базе, при условии, что эти работы связаны со спасением людей",
          "Привлечение членов вспомогательной горноспасательной команды для ведения горноспасательных работ в зоне высоких температур с непригодной для дыхания рудничной атмосферой",
          "Все перечисленные действия",
        ],
        correctAnswers: [
          "Ведение горноспасательных работ в зоне высоких температур с непригодной для дыхания рудничной атмосферой при отсутствии связи горноспасательного отделения, ведущего горноспасательные работы, с руководителем горноспасательных работ или с горноспасательным отделением, находящимся на подземной горноспасательной базе, при условии, что эти работы связаны со спасением людей",
        ],
      },
      {
        code: "20503048",
        text: "При какой температуре запрещается ведение горноспасательных работ в горных выработках с непригодной для дыхания рудничной атмосферой?",
        answers: [
          "Ниже минус 8 °C",
          "Ниже минус 10 °C",
          "Ниже минус 15 °C",
          "Ниже минус 20 °C",
        ],
        correctAnswers: ["Ниже минус 20 °C"],
      },
      {
        code: "20503049",
        text: "В каком случае допускается нахождение людей, занятых тушением подземного пожара активным способом, в горных выработках с исходящей от пожара струей воздуха?",
        answers: [
          "Только в случае тушения пожара водой",
          "Только в случае выполнения мер, предотвращающих обрушение (высыпание) горных пород",
          "Только в случае, когда пожар находится вблизи выработок со свежей струей воздуха",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "Только в случае, когда пожар находится вблизи выработок со свежей струей воздуха",
        ],
      },
      {
        code: "20503050",
        text: "В наклонной горной выработке с каким углом наклона пожар тушится активным способом дистанционно?",
        answers: [
          "В любом наклонной горной выработке",
          "С углом наклона более 10°",
          "С углом наклона более 15°",
          "С углом наклона более 20°",
        ],
        correctAnswers: ["С углом наклона более 20°"],
      },
      {
        code: "20503051",
        text: "Какое из перечисленных требований при тушении пожаров в вертикальных горных выработках указано верно?",
        answers: [
          "При тушении активным способом и локализации пожара в вертикальном стволе с исходящей вентиляционной струей или в надшахтном здании этого ствола возможно изменение направления вентиляционной струи",
          "Пожары в вертикальных горных выработках тушатся активным способом снизу вверх",
          "При тушении активным способом и локализации пожаров в вертикальных горных выработках с восходящей струей воздуха с применением воды, подаваемой с поверхности, выполняются меры, исключающие опрокидывание вентиляционной струи",
          "Нахождение людей в вертикальных горных выработках во время тушения в них пожара допускается при условии выполнения мер, исключающих опрокидывание вентиляционной струи",
        ],
        correctAnswers: [
          "При тушении активным способом и локализации пожаров в вертикальных горных выработках с восходящей струей воздуха с применением воды, подаваемой с поверхности, выполняются меры, исключающие опрокидывание вентиляционной струи",
        ],
      },
      {
        code: "20503052",
        text: "При каких условиях допускается ведение горноспасательных работ в тупиковой выработке?",
        answers: [
          "При отсутствии в выработке пострадавших",
          "При тушении пожара в непроветриваемых тупиковых горных выработках",
          "При отсутствии информации о содержании в ней горючих газов",
          "При тушении пожара в выработанном пространстве подачей огнетушащих веществ дистанционно, при этом люди должны находиться на расстоянии от места возникновения аварии, далее которого отсутствует вероятность воздействия опасных факторов аварии на человека",
        ],
        correctAnswers: [
          "При тушении пожара в выработанном пространстве подачей огнетушащих веществ дистанционно, при этом люди должны находиться на расстоянии от места возникновения аварии, далее которого отсутствует вероятность воздействия опасных факторов аварии на человека",
        ],
      },
      {
        code: "20503053",
        text: "Кто определяет места возведения изоляционных перемычек, предназначенных для изоляции пожарного участка, их конструкцию, последовательность и сроки возведения?",
        answers: [
          "Лицо, ответственное за пожарную безопасность ОПО",
          "Технический руководитель (главный инженер) ОПО",
          "Руководитель горноспасательных работ",
          "Руководитель работ по ликвидации аварии (РЛА)",
        ],
        correctAnswers: ["Руководитель работ по ликвидации аварии (РЛА)"],
      },
      {
        code: "20503054",
        text: "В каком случае инертизация рудничной атмосферы в изолируемом пространстве может быть начата до окончания возведения взрывоустойчивых изоляционных перемычек?",
        answers: [
          "В случае, если это определено оперативным планом",
          "В случае, если продолжительность подачи инертного газа превышает допустимую",
          "В случае, если объем подаваемого инертного газа превышает расход воздуха, поступающего на пожарный участок",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "В случае, если объем подаваемого инертного газа превышает расход воздуха, поступающего на пожарный участок",
        ],
      },
      {
        code: "20503055",
        text: "Какое из перечисленных мероприятий не проводится при ликвидации последствий горного удара, обрушения пород, оползня?",
        answers: [
          "Определение возможности развития обрушения (оползня) и безопасных способов выполнения горноспасательных работ",
          "Организация работы по разборке обрушившейся горной массы и (или) проведение поисковых выработок из возможно большего числа мест",
          "Установление связи с застигнутыми аварией людьми, не имеющими возможности выйти из зоны аварии",
          "Мониторинг концентрации индикаторных пожарных газов",
        ],
        correctAnswers: ["Мониторинг концентрации индикаторных пожарных газов"],
      },
      {
        code: "20503056",
        text: "Какое максимальное допустимое избыточное давление в кессоне при ведении горноспасательных работ в автономных изолирующих дыхательных аппаратах?",
        answers: [
          "0,4 МПа",
          "0,3 МПа",
          "0,2 МПа",
          "0,1 МПа",
          "Все ответы неверны",
        ],
        correctAnswers: ["0,2 МПа"],
      },
      {
        code: "20503057",
        text: "Кто назначается председателем комиссии по проведению учебной тревоги при проверке готовности организации к действиям по спасению людей, локализации и ликвидации последствий аварии?",
        answers: [
          "Руководитель организации",
          "Руководитель подразделения военизированной горноспасательной части",
          "Работник территориального органа Ростехнадзора",
          "Главный инженер шахты",
          "Все ответы неверны",
        ],
        correctAnswers: ["Главный инженер шахты"],
      },
      {
        code: "20503058",
        text: "Какие действия при проведении учебных тревог указаны верно?",
        answers: [
          "Местонахождение членов комиссии в горных выработках, в помещении горного диспетчера, в наземных зданиях и сооружениях шахты определяется горным диспетчером",
          "Производится остановка технологического процесса ведения горных работ",
          "Организуется прибытие на шахту подразделения ПАСС(Ф), обслуживающего шахту, в соответствии с мероприятиями и в порядке, предусмотренными ПЛА",
          "Все перечисленные",
        ],
        correctAnswers: [
          "Организуется прибытие на шахту подразделения ПАСС(Ф), обслуживающего шахту, в соответствии с мероприятиями и в порядке, предусмотренными ПЛА",
        ],
      },
      {
        code: "20503059",
        text: "Что проверяется при проведении учебной тревоги?",
        answers: [
          "Последовательность выполнения мероприятий по ликвидации аварии, предусмотренных ПЛА;",
          "Последовательность действий при оповещении руководителей и работников шахты в соответствии с ПЛА",
          "Возможность организовать подачу воды к месту тушения пожара с расчетными параметрами",
          "Последовательность и время оповещения людей, застигнутых аварией",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20503060",
        text: "Что должно быть проверено при проведении учебных тревог?",
        answers: [
          "Только возможность осуществления в организации мероприятий по спасению людей, локализации аварии и ликвидации ее последствий",
          "Только знание работников шахты своих действий при авариях и инцидентах",
          "Только состояние систем связи, оповещения и определения местоположения людей, застигнутых аварией",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20503061",
        text: "С какой периодичностью должны проводиться учебные тревоги по плану ликвидации аварий на шахте?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 5 лет",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "20503062",
        text: "На какой срок составляется график учебных тревог на шахте?",
        answers: [
          "На 3 месяца",
          "На 6 месяцев",
          "На 9 месяцев",
          "На календарный год",
        ],
        correctAnswers: ["На календарный год"],
      },
      {
        code: "20503063",
        text: "В какой срок должен составляться акт по результатам проведения учебной тревоги и учения по ПЛА?",
        answers: [
          "В двухнедельный срок",
          "В недельный срок",
          "В пятидневный срок",
          "Не нормируется",
        ],
        correctAnswers: ["В пятидневный срок"],
      },
      {
        code: "20503064",
        text: "Кто определяет перечень мест обнаружения ранних признаков возникновения пожаров?",
        answers: [
          "Специально назначенное лицо, проводившее обследование выработок",
          "Начальник участка аэрологической безопасности",
          "Руководитель организации",
          "Главный инженер шахты",
          "Все ответы неверны",
        ],
        correctAnswers: ["Главный инженер шахты"],
      },
      {
        code: "20503065",
        text: "Какие разделы не включает в себя Положение о системе управления промышленной безопасностью и охраной труда (СУПБиОТ)?",
        answers: [
          "Система управления промышленной безопасностью",
          "Система управления охраной труда",
          "Проведение расследований причин инцидентов",
          "Все ответы неверны",
        ],
        correctAnswers: ["Проведение расследований причин инцидентов"],
      },
      {
        code: "20503066",
        text: 'Какие задачи должна указывать эксплуатирующая организация в подразделе "Задачи эксплуатирующей организации в области ПБ" при разработке положения о единой системе управления промышленной безопасностью и охраной труда для организаций по добыче (переработке) угля (горючих сланцев)?',
        answers: [
          "Обеспечение противоаварийной устойчивости и готовности к локализации и ликвидации последствий аварии",
          "Управление основными производственными рисками",
          "Выявление опасных технологий, участков, рабочих мест и предупреждение несчастных случаев",
          "Финансирование мероприятий по обеспечению безопасной эксплуатации ОПО",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20503067",
        text: "Какое из перечисленных требований по ведению горных работ в угольных шахтах является верным?",
        answers: [
          "Горные работы должны выполнять не менее чем 3 работника",
          "Стаж работы по профессии одного из работников, выполняющего горные работы, должен быть не менее 6 месяцев",
          "Запрещается ведение горных работ без утвержденной руководителем шахты документации по ведению горных работ",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20503068",
        text: "При какой глубине двух центрально расположенных стволов с лестничными отделениями можно не оборудовать подъемную установку в одном из них?",
        answers: [
          "При глубине до 70 м",
          "При глубине до 100 м",
          "При глубине до 500 м",
          "Независимо от глубины",
        ],
        correctAnswers: ["При глубине до 70 м"],
      },
      {
        code: "20503069",
        text: "Какова минимально допустимая ширина части выработки, предназначенной для передвижения людей, в горизонтальных и наклонных горных выработках?",
        answers: ["0,7 м", "0,6 м", "0,5 м", "0,9 м"],
        correctAnswers: ["0,7 м"],
      },
      {
        code: "20503070",
        text: "Какова минимально допустимая высота части выработки, предназначенной для передвижения людей, в горизонтальных и наклонных горных выработках?",
        answers: ["1,6 м", "1,8 м", "2,2 м", "Не нормируется"],
        correctAnswers: ["1,8 м"],
      },
      {
        code: "20503071",
        text: "Какие из перечисленных расстояний, относящихся к лестницам в лестничных отделениях угольных шахт, указаны верно?",
        answers: [
          "Расстояние между крепью горной выработки и лестницей у ее основания должно быть не менее 0,4 м",
          "Лестницы должны выступать не менее чем на 2 м над горизонтальными полками",
          "В лестничных отделениях не более чем через 10 м устраивают горизонтальные полки",
          "Ширина лестниц должна быть не менее 0,4 м, а расстояние между ступенями - не более 0,4 м",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Ширина лестниц должна быть не менее 0,4 м, а расстояние между ступенями - не более 0,4 м",
        ],
      },
      {
        code: "20503072",
        text: "В каком случае допускается протягивать один предохранительный канат между крайними секциями щитового перекрытия при отработке мощных пластов?",
        answers: [
          "При мощности пласта менее 6 м",
          "Если данная мера обоснована документацией по ведению горных работ",
          "В случае постоянного присутствия наблюдающего персонала",
          "Не допускается ни в каком случае",
          "Все ответы неверны",
        ],
        correctAnswers: ["При мощности пласта менее 6 м"],
      },
      {
        code: "20503073",
        text: "Какова минимальная площадь поперечного сечения главных откаточных и вентиляционных горных выработок?",
        answers: ["9 м2", "6 м2", "4,5 м2", "3,7 м2"],
        correctAnswers: ["9 м2"],
      },
      {
        code: "20503074",
        text: "Каким должно быть опережение верхнего уступа относительно нижнего при проведении горных выработок уступами по мощным пластам?",
        answers: [
          "Не более 1,5 м",
          "От 1 м до 1,3 м",
          "Не более 2,5 м",
          "От 3 м до 3,1 м",
        ],
        correctAnswers: ["Не более 1,5 м"],
      },
      {
        code: "20503075",
        text: "Во время производства каких работ допускается нахождение людей в забое вертикальной горной выработки угольной шахты?",
        answers: [
          "Во время производства работ по креплению каната",
          "Во время производства работ по замене каната",
          "Во время производства работ по ликвидации в бетонопроводе участков затвердевшего бетона",
          "Во время производства работ по замене подъемного сосуда",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20503076",
        text: "Какова минимально допустимая высота ограждения проемов площадок размещения технологического оборудования в копрах?",
        answers: ["1400 мм", "1500 мм", "1600 мм", "1800 мм"],
        correctAnswers: ["1600 мм"],
      },
      {
        code: "20503077",
        text: "Кем утверждаются меры, обеспечивающие безопасность ведения горных работ, которые обязательны при очистных работах до первичной посадки основной кровли?",
        answers: [
          "Главным инженером шахты",
          "Представителем территориального органа Ростехнадзора",
          "Начальником участка или его заместителем",
          "Представителем специализированной аварийно-спасательной части",
        ],
        correctAnswers: ["Главным инженером шахты"],
      },
      {
        code: "20503078",
        text: "Каков максимально допустимый угол падения пласта при выемке надштрековых целиков у вентиляционных штреков одновременно с отработкой лав нижележащего этажа и при наличии оконтуривающих горных выработок?",
        answers: ["18°", "30°", "45°", "60°"],
        correctAnswers: ["30°"],
      },
      {
        code: "20503079",
        text: "Каким должно быть отставание лавы каждого нижележащего слоя от границы обрушенного или заложенного пространства лавы вышележащего слоя на пластах с углом падения более 30°?",
        answers: [
          "Не менее 20 м",
          "Не менее 15 м",
          "От 10 до 15 м",
          "Не нормируется",
        ],
        correctAnswers: ["Не менее 20 м"],
      },
      {
        code: "20503080",
        text: "После выполнения каких работ разрешается опускать щитовое перекрытие при разработке мощных пластов?",
        answers: [
          "После обрушения потолочины над щитом для создания предохранительной подушки высотой не менее мощности пласта",
          "После оборудования щитового перекрытия предохранительными средствами (канатами, трапами, решетками)",
          "После монтажа не менее одной секции следующего щитового перекрытия (за исключением последнего щитового столба на выемочном участке)",
          "После выполнения всех перечисленных работ",
          "Все ответы неверны",
        ],
        correctAnswers: ["После выполнения всех перечисленных работ"],
      },
      {
        code: "20503081",
        text: "На каких пластах угольных шахт допускается в качестве вентиляционных печей использовать скважины, пробуренные диаметром не менее 0,7 м?",
        answers: [
          "Не допускается ни на каких пластах",
          "Мощностью менее 7 м",
          "Мощностью менее 6 м",
          "Мощностью менее 5 м",
          "Все ответы неверны",
        ],
        correctAnswers: ["Мощностью менее 5 м"],
      },
      {
        code: "20503082",
        text: "В каком случае участковые станции напорного гидротранспорта располагают в нишах при гидравлическом способе добычи угля?",
        answers: [
          "Участковые станции напорного гидротранспорта располагают только в камерах",
          "При сроках службы до 1 года",
          "При сроках службы до 3 лет",
          "В случае обоснования в проектной документации",
          "Все ответы неверны",
        ],
        correctAnswers: ["При сроках службы до 1 года"],
      },
      {
        code: "20503083",
        text: "До какого максимального давления воды разрешается ручное управление гидромониторами при гидравлическом способе добычи угля?",
        answers: ["До 3 МПа", "До2,5 МПа", "До 2 МПа", "До1,5 МПа"],
        correctAnswers: ["До 3 МПа"],
      },
      {
        code: "20503084",
        text: "Каково максимально допустимое расстояние от гидромонитора до задвижки, вмонтированной в водоподводящий трубопровод?",
        answers: ["30 м", "50 м", "100 м", "150 м"],
        correctAnswers: ["50 м"],
      },
      {
        code: "20503085",
        text: "На каком минимальном расстоянии выше уровня почвы выемочной печи (штрека) должен быть расположен нижний уровень вентиляционной скважины на пластах средней мощности и мощных пластах?",
        answers: ["1,5 м", "1,0 м", "0,8 м", "0,5 м", "Все ответы неверны"],
        correctAnswers: ["0,5 м"],
      },
      {
        code: "20503086",
        text: "Кем организуется прогноз динамических явлений, проведение мер по предотвращению динамических явлений и контроль их эффективности?",
        answers: [
          "Главным инженером шахты",
          "Руководителем горнодобывающей организации",
          "Командиром аварийно-спасательной части, обслуживающей шахту",
          "Начальником участка или его заместителем",
        ],
        correctAnswers: ["Главным инженером шахты"],
      },
      {
        code: "20503087",
        text: "Кто принимает решение о возобновлении горных работ после приведения горного массива в неопасное состояние?",
        answers: [
          "Руководитель шахты",
          "Главный инженер шахты",
          "Представитель Ростехнадзора",
          "Командир профессиональных аварийно-спасательных служб (формирований) (ПАСС (Ф), обслуживающих шахту",
        ],
        correctAnswers: ["Главный инженер шахты"],
      },
      {
        code: "20503088",
        text: "С какой периодичностью ИТР структурного подразделения, в ведении которых находится горная выработка, должны осматривать крепь и армировку вертикальных и наклонных стволов?",
        answers: [
          "Не реже 1 раза в квартал",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в неделю",
          "Ежесуточно",
        ],
        correctAnswers: ["Ежесуточно"],
      },
      {
        code: "20503089",
        text: "С какой периодичностью осуществляют профильную съемку армировки и замер зазоров безопасности на угольных шахтах?",
        answers: [
          "Определяется главным инженером шахты",
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 3 года",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не реже 1 раза в 2 года"],
      },
      {
        code: "20503090",
        text: "В каких горных выработках угольных шахт запрещается проводить ремонтные работы одновременно более чем в одном месте?",
        answers: [
          "С углом наклона более 10°",
          "С углом наклона более 12°",
          "С углом наклона более 15°",
          "С углом наклона более 18°",
          "Все ответы неверны",
        ],
        correctAnswers: ["С углом наклона более 18°"],
      },
      {
        code: "20503091",
        text: "На какую максимальную величину выше места ремонта вертикальной горной выработки устраивается перекрытие, обеспечивающее защиту работникам, выполняющим работы по ремонту, от падающих предметов?",
        answers: ["На 2 м", "На 3 м", "На 5 м", "На 10 м"],
        correctAnswers: ["На 5 м"],
      },
      {
        code: "20503092",
        text: "Какова минимально допустимая высота стенок или металлической сетки, ограждающих с нерабочих сторон устья действующих и находящихся в проходке вертикальных и наклонных горных выработок, оборудованных подъемными установками?",
        answers: ["2,5 м", "2,2 м", "1,8 м", "1,5 м"],
        correctAnswers: ["2,5 м"],
      },
      {
        code: "20503093",
        text: 'Какие угольные пласты относятся к категории "особовыбросоопасные угольные пласты"?',
        answers: [
          "Угольные пласты (горные породы), угрожаемые по горным ударам, на которых в пределах данного шахтного поля произошел горный удар",
          "Угольные пласты (горные породы), угрожаемые по внезапным выбросам угля (породы) и газа, на которых в пределах данного шахтного поля произошел внезапный выброс угля (породы) и газа",
          'Угольные пласты (горные породы), угрожаемые по внезапным выбросам угля (породы) и газа, на которых в пределах данного шахтного поля при проведении прогноза выбросоопасности выявлена категория "опасно"',
          "Выбросоопасные угольные пласты (участки угольных пластов) в зонах активных по внезапным выбросам тектонических нарушений",
        ],
        correctAnswers: [
          "Выбросоопасные угольные пласты (участки угольных пластов) в зонах активных по внезапным выбросам тектонических нарушений",
        ],
      },
      {
        code: "20503094",
        text: "К какому виду прогноза динамических явлений относится прогноз по данным, полученным при ведении геолого-разведочных работ, и прогноз по непрерывным сейсмоакустическим наблюдениям?",
        answers: [
          "Прогноз перед вскрытием угольных пластов горными выработками",
          "Локальный прогноз",
          "Текущий прогноз",
          "Региональный прогноз",
          "Прогноз выбросоопасности и удароопасности пород",
        ],
        correctAnswers: ["Региональный прогноз"],
      },
      {
        code: "20503095",
        text: "В соответствии с чем должен проводиться мониторинг массива горных пород геофизическими методами?",
        answers: [
          "В соответствии с распорядительным документом руководителя угледобывающей организации",
          "В соответствии с Правилами безопасности в угольных шахтах",
          "В соответствии с Инструкцией по прогнозу динамических явлений и мониторингу массива горных пород при отработке угольных месторождений",
          "В соответствии с проектной документацией, утвержденной главным инженером",
        ],
        correctAnswers: [
          "В соответствии с проектной документацией, утвержденной главным инженером",
        ],
      },
      {
        code: "20503096",
        text: "Кем выполняются прогноз динамических явлений и контроль эффективности применения мер по предотвращению динамических явлений на шахтах, разрабатывающих угольные пласты, склонные к динамическим явлениям?",
        answers: [
          "Техническим руководителем (главным инженером) угледобывающей организации",
          "Профессионально аварийно-спасательной службой (формированием)",
          "Работниками специализированной организации",
          "Инженерно-техническими работниками (ИТР) службы прогноза динамических явлений (ДЯ)",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Инженерно-техническими работниками (ИТР) службы прогноза динамических явлений (ДЯ)",
        ],
      },
      {
        code: "20503097",
        text: "С какой периодичностью распорядительным документом руководителя угледобывающей организации угольные пласты и горные породы относятся к категориям по динамическим явлениям, утверждаются опасные зоны, и определяется комплекс мер по прогнозу и предотвращению динамических явлений?",
        answers: [
          "Раз в 5 лет",
          "Раз в 3 года",
          "Раз в полгода",
          "Ежегодно",
          "Все ответы неверны",
        ],
        correctAnswers: ["Ежегодно"],
      },
      {
        code: "20503098",
        text: "В течение какого времени должны расследоваться события, предшествующие динамическим явлениям при первом их проявлении?",
        answers: [
          "В течение 24 часов",
          "В течение 3 дней",
          "В течение 10 дней",
          "В течение недели",
        ],
        correctAnswers: ["В течение 24 часов"],
      },
      {
        code: "20503099",
        text: "Что относится к признакам внезапного выброса угля и газа?",
        answers: [
          "Только быстропротекающее разрушение призабойной части угольного пласта, образование в угольном пласте полости, ширина которой меньше ее глубины",
          "Только отброс угля от забоя на расстояние, превышающее протяженность его размещения под углом естественного откоса",
          "Только относительное газовыделение из разрушенного угля, которое превысило его природную газоносность",
          "Только воздушный толчок и звуковые эффекты, повреждение и (или) перемещение оборудования, наличие угольной пыли на откосе выброшенного угля и крепи горной выработки",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20503100",
        text: "Какие из событий предшествуют горному удару?",
        answers: [
          "Только повышенное горное давление на крепь горной выработки",
          "Только удары, трески, толчки в массиве горных пород различной силы и частоты",
          "Только стреляние отслоившихся кусков угля (породы)",
          "Только повышенный выход буровой мелочи и зажатие бурового инструмента",
          "Все перечисленные события",
        ],
        correctAnswers: ["Все перечисленные события"],
      },
      {
        code: "20503101",
        text: "На основе чего выполняется геодинамическое районирование?",
        answers: [
          "Анализа геологических, геофизических, геохимических и картографических материалов",
          "Дешифрирования космических и аэрофотоснимков, морфометрического анализа земной поверхности",
          "Геодезических, геофизических и геохимических полевых инструментальных наблюдений",
          "Всего перечисленного",
        ],
        correctAnswers: ["Всего перечисленного"],
      },
      {
        code: "20503102",
        text: "Кем определяются места расположения скважин и периодичность их бурения при проведении прогноза динамических явлений в месте вскрытия угольных пластов?",
        answers: [
          "Ростехнадзором",
          "Главным инженером шахты",
          "Персоналом службы прогноза динамических явлений",
          "ИТР геологической службы угледобывающей организации",
        ],
        correctAnswers: ["ИТР геологической службы угледобывающей организации"],
      },
      {
        code: "20503103",
        text: "В каких горных выработках проводится локальный прогноз удароопасности угольных пластов?",
        answers: [
          "В подготовительных горных выработках, проводимых по угольным пластам",
          "В горных выработках действующих выемочных участков",
          "В поддерживаемых горных выработках",
          "Во всех перечисленных горных выработках",
        ],
        correctAnswers: ["Во всех перечисленных горных выработках"],
      },
      {
        code: "20503104",
        text: "Какое утверждение, относящееся к порядку проведения локального прогноза удароопасности угольных пластов, указано неверно?",
        answers: [
          "На удароопасных угольных пластах локальный прогноз удароопасности проводится до начала ведения горных работ на участке горной выработки, подлежащем перекреплению",
          "На удароопасных угольных пластах локальный прогноз удароопасности проводится на участках горных выработок, подлежащих погашению",
          "Локальный прогноз в поддерживаемых выработках проводится в поддерживаемых горных выработках, пройденных вне зон опорного давления очистных выработок, в каждый борт горной выработки с интервалом не более 100 м, в горных выработках, охраняемых целиками угля, с интервалом не более 25 м",
          "Локальный прогноз удароопасности в поддерживаемых горных выработках, пройденных по угрожаемым и опасным по горным ударам угольным пластам, проводится спустя 3 года после окончания их проведения с периодичностью не реже 1 раза в 3 года",
        ],
        correctAnswers: [
          "Локальный прогноз удароопасности в поддерживаемых горных выработках, пройденных по угрожаемым и опасным по горным ударам угольным пластам, проводится спустя 3 года после окончания их проведения с периодичностью не реже 1 раза в 3 года",
        ],
      },
      {
        code: "20503105",
        text: "Какими изолирующимиперемычками (ИП) следует изолировать выработанное пространство выемочных участков газовых шахт, проветриваемых по схемам с изолированным отводом метана из выработанного пространства с помощью газоотсасывающих установок?",
        answers: [
          "Водоупорными изолирующими перемычками",
          "Взрывоустойчивыми изолирующими перемычками",
          "Динамически устойчивыми изолирующими перемычками",
          "Безврубовыми изолирующими перемычками",
        ],
        correctAnswers: ["Взрывоустойчивыми изолирующими перемычками"],
      },
      {
        code: "20503106",
        text: "Каков максимально допустимый срок для изоляции отработанных выемочных участков на пластах угля, не склонных к самовозгоранию после окончания демонтажных работ?",
        answers: ["3 суток", "5 суток", "10 суток", "15 суток"],
        correctAnswers: ["10 суток"],
      },
      {
        code: "20503107",
        text: "Какое требование устанавливается в случаях, когда изоляцию отработанного выемочного участка невозможно выполнить в срок, не превышающий инкубационный период самовозгорания угля?",
        answers: [
          "Следует обеспечить проветривание тупиковых горных выработок",
          "Обоснованный срок окончания работ по изоляции отработанного выемочного участка согласовывается с инспектором территориального органа Ростехнадзора",
          "Работы по изоляции следует вести с выполнением мер, исключающих возникновение аварии, утвержденных техническим руководителем (главным инженером) угледобывающей организации",
          "Технический руководитель (главный инженер) угледобывающей организации распорядительным документом устанавливает обоснованный срок окончания работ по изоляции отработанного выемочного участка, превышающий инкубационный период самовозгорания угля, и утверждает меры по профилактике и предупреждению самовозгорания угля в отработанной части выемочного участка при отсутствии подвигания очистного забоя",
        ],
        correctAnswers: [
          "Технический руководитель (главный инженер) угледобывающей организации распорядительным документом устанавливает обоснованный срок окончания работ по изоляции отработанного выемочного участка, превышающий инкубационный период самовозгорания угля, и утверждает меры по профилактике и предупреждению самовозгорания угля в отработанной части выемочного участка при отсутствии подвигания очистного забоя",
        ],
      },
      {
        code: "20503108",
        text: "Кто проверяет конструктивные решения и параметры изолирующих перемычек, которые невозможно будет проверить после возведения изолирующих перемычек, при приемке скрытых работ?",
        answers: [
          "Технический руководитель (главный инженер) угледобывающей организации",
          "Инспектор Ростехнадзора",
          "Специализированная организация",
          "Комиссия угледобывающей организации",
        ],
        correctAnswers: ["Комиссия угледобывающей организации"],
      },
      {
        code: "20503109",
        text: "В каком случае допускается возводить изолирующие перемычки в границах зон повышенного горного давления и зон влияния геологических нарушений в выработках, пройденных по угольным пластам?",
        answers: [
          "Не допускается ни в каком случае",
          "Если есть письменное распоряжение, утвержденное на основании проведенной приемке скрытых работ",
          "Если отсутствует гальваническая связь между изолированной частью выработки и действующими выработками",
          "Если выполнены меры, обеспечивающие сохранность изолирующих перемычек (ИП) в условиях повышенного горного давления (ПГД) или в условиях влияния геологических нарушений",
        ],
        correctAnswers: [
          "Если выполнены меры, обеспечивающие сохранность изолирующих перемычек (ИП) в условиях повышенного горного давления (ПГД) или в условиях влияния геологических нарушений",
        ],
      },
      {
        code: "20503110",
        text: "На каком минимальном расстоянии от отработанной части угольного пласта возводятся изолирующие перемычки в зоне разгрузки и вне зоны повышенного горного давления?",
        answers: ["7 м", "4 м", "2,5 м", "1,5 м", "Все ответы неверны"],
        correctAnswers: ["1,5 м"],
      },
      {
        code: "20503111",
        text: "При каком расстоянии от сопряжения горных выработок до возводимой изолирующей перемычки должно быть выполнено усиление крепи горной выработки на участке от изолирующей перемычки до сопряжения горных выработок?",
        answers: [
          "Не менее 10 м",
          "Не менее 7 м",
          "Не менее 6 м",
          "Менее 5 м",
          "Все ответы неверны",
        ],
        correctAnswers: ["Менее 5 м"],
      },
      {
        code: "20503112",
        text: "Какие меры должны быть выполнены при возведении изолирующих перемычек в зонах трещиноватости горных пород?",
        answers: [
          "Меры по снижению напора воды",
          "Меры по сохранности изолирующих перемычек при высоких температурах",
          "Меры по снижению сейсмических нагрузок",
          "Меры по снижению фильтрационных свойств угольного пласта и вмещающих пород",
        ],
        correctAnswers: [
          "Меры по снижению фильтрационных свойств угольного пласта и вмещающих пород",
        ],
      },
      {
        code: "20503113",
        text: "На каком расстоянии от изолирующей перемычки (ИП) следует размещать электрооборудование и электрические кабели?",
        answers: [
          "Не более 4 м",
          "Не более 3 м",
          "Не менее 3 м",
          "Не менее 5 м",
        ],
        correctAnswers: ["Не менее 5 м"],
      },
      {
        code: "20503114",
        text: "Каким требованиям должна соответствовать горная выработка , пройденная по пласту угля в обе стороны на расстояние не менее 5 м от изолирующей перемычки (ИП)?",
        answers: [
          "Должна бытьтолько закреплена негорючей крепью",
          "Должна быть только очищена на боках и в кровле от отслоившихся угля и породы",
          "Должна быть толькозакреплена дополнительной крепью",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20503115",
        text: "Каков максимально допустимый угол наклона наклонных горных выработок для извлечения крепи?",
        answers: ["18°", "30°", "40°", "60°"],
        correctAnswers: ["30°"],
      },
      {
        code: "20503116",
        text: "Где возводятся изолирующие перемычки для изоляции горных выработок, выходящих на земную поверхность?",
        answers: [
          "В изолируемой горной выработке у ее сопряжения с действующими горными выработками",
          "В горных выработках, примыкающих к изолируемой горной выработке",
          "В устье изолируемой горной выработки у земной поверхности",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20503117",
        text: "Какое из перечисленных действий не относится к работам по изоляции вертикальных выработок, имеющих выход на земную поверхность?",
        answers: [
          "Возведение изолирующей перемычки на балках или рельсах над засыпанной частью изолируемой выработки",
          "До возведения изолирующей перемычки следует выполнить работы по усилению крепи горной выработки ниже места возведения изолирующей перемычки",
          "Засыпка оставшейся части изолируемой выработки негорючими материалами до уровня земной поверхности",
          "Возведение изолирующей перемычки в горных выработках на расстоянии не менее 5 м от сопряжения с вертикальной горной выработкой",
        ],
        correctAnswers: [
          "До возведения изолирующей перемычки следует выполнить работы по усилению крепи горной выработки ниже места возведения изолирующей перемычки",
        ],
      },
      {
        code: "20503118",
        text: "Какое из перечисленных требований при изоляции скважин указано верно?",
        answers: [
          "На земной поверхности над устьем изолируемой скважины отсыпается слой глины высотой не менее 3 м",
          "Обсадная труба на земной поверхности должна быть закрыта пластмассовой заглушкой",
          "Верхний конец трубы для отвода рудничного воздуха из скважины в атмосферу должен находиться на 2 м выше уровня глины",
          "Вокруг обсадной трубы должна быть залита бетонная тумба диаметром на 0,5 м больше диаметра скважины",
        ],
        correctAnswers: [
          "Вокруг обсадной трубы должна быть залита бетонная тумба диаметром на 0,5 м больше диаметра скважины",
        ],
      },
      {
        code: "20503119",
        text: "С какой периодичностью главный инженер угледобывающей организации должен организовывать обследования земной поверхности в целях выявления на ней провалов и трещин, образовавшихся в результате ведения подземных горных работ, и контроля состояния ликвидированных провалов и трещин?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не менее 1 раза в год (весной)",
          "Не менее 2 раз в год (весной и осенью)",
          "Не менее 2 раз в год (летом и зимой)",
        ],
        correctAnswers: ["Не менее 2 раз в год (весной и осенью)"],
      },
      {
        code: "20503120",
        text: "Какое из перечисленных полномочий принадлежит главному инженеру шахты?",
        answers: [
          "Создание комиссии, осуществляющей контроль выполнения мер по изоляции неиспользуемых горных выработок и выработанных пространств",
          "Принятие решения о ведении очистных работ в выемочных столбах, над которыми на земной поверхности есть неликвидированные провалы и трещины",
          "Утверждение акта заиловочных работ",
          "Назначение лиц, которые должны контролировать соответствие работ, выполняемых при возведении изолирующих перемычек",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20503121",
        text: "Когда должны отбираться пробы для определения прочностных характеристик затвердевшего материала, используемого при возведении взрывоустойчивой изолирующей перемычки (ИП)?",
        answers: [
          "1 раз - до начала возведения изолирующей перемычки",
          "Не менее 2 раз за время возведения изолирующей перемычки - когда возведено от 40 до 60 % объема ИП, и перед окончанием возведения ИП",
          "Не менее 3 раз за время возведения ИП - в начале возведения, когда возведено от 40 до 60 % объема ИП, и перед окончанием возведения ИП",
        ],
        correctAnswers: [
          "Не менее 3 раз за время возведения ИП - в начале возведения, когда возведено от 40 до 60 % объема ИП, и перед окончанием возведения ИП",
        ],
      },
      {
        code: "20503122",
        text: "Какова максимально допустимая периодичность определения качества изоляции изолирующих перемычек (ИП) после первичной проверки качества изоляции при их возведении, для ИП, изолирующих неиспользуемые горные выработки и выработанное пространство на пластах угля, склонных к самовозгоранию?",
        answers: ["3 месяца", "6 месяцев", "9 месяцев", "1 год"],
        correctAnswers: ["1 год"],
      },
      {
        code: "20503123",
        text: "Каков максимально допустимый срок определения качества изоляции изолирующих перемычек (ИП) после их возведения, для ИП, изолирующих неиспользуемые горные выработки и выработанное пространство на пластах угля, склонных к самовозгоранию?",
        answers: [
          "5 календарных дней",
          "10 календарных дней",
          "15 календарных дней",
          "20 календарных дней",
        ],
        correctAnswers: ["10 календарных дней"],
      },
      {
        code: "20503124",
        text: "Какова максимально допустимая периодичность визуального контроля изолирующих перемычек (ИП)?",
        answers: [
          "Ежесуточно",
          "1 раз в смену",
          "1 раз в 10 дней",
          "Ежемесячно",
        ],
        correctAnswers: ["Ежесуточно"],
      },
      {
        code: "20503125",
        text: "С какой периодичностью руководителям структурных подразделений необходимо проводить проверку состава рудничного воздуха индивидуальными приборами контроля газов у изолирующих перемычек (ИП), изолирующих неиспользуемые горные выработки и выработанное пространство на пластах, склонных к самовозгоранию?",
        answers: [
          "Не реже 1 раза в 30 календарных дней",
          "Не реже 1 раза в 20 календарных дней",
          "Не реже 1 раза в 15 календарных дней",
          "Не реже 1 раза в 10 календарных дней",
        ],
        correctAnswers: ["Не реже 1 раза в 10 календарных дней"],
      },
      {
        code: "20503126",
        text: "Как часто ИТР шахты должны проводить проверку состава рудничной атмосферы у изолирующих перемычек (ИП) индивидуальными переносными приборами контроля газов, замер температуры вытекающей из-за ИП воды и температуру рудничной атмосферы за ИП?",
        answers: [
          "Не реже 4 раз в сутки",
          "Не реже 3 раз в сутки",
          "Не реже 2 раз в сутки",
          "Не реже 1 раза в сутки",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не реже 1 раза в сутки"],
      },
      {
        code: "20503127",
        text: "С какой периодичностью комиссия, осуществляющая контроль выполнения мер по изоляции неиспользуемых горных выработок и выработанных пространств, проводит визуальный контроль на земной поверхности изоляции устьев изолированных неиспользуемых горных выработок?",
        answers: [
          "Не менее 1 раза в квартал",
          "Не менее 1 раза в год",
          "Не менее 2 раз в год",
          "Не менее 4 раз в год",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не менее 2 раз в год"],
      },
      {
        code: "20503128",
        text: "С какой периодичностью главный инженер шахты разрабатывает график ремонта изолирующих перемычек (ИП) и улучшения качества изоляции неиспользуемых горных выработок и выработанных пространств?",
        answers: [
          "Только при возведении новой изолирующей перемычки",
          "Только 1 раз в 3 года",
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "20503129",
        text: "Для каких горно-геологических и горнотехнических условий в Инструкции по расчету и применению анкерной крепи на угольных шахтах не содержится порядок расчета анкерной крепи для горизонтальных и наклонных (до 35 градусов) подземных горных выработок и их сопряжений?",
        answers: [
          "При максимальной расчетной ширине выработки или сопряжения не более 12 м",
          "При отношении глубины проведения горной выработки или сопряжения от дневной поверхности Н, м к расчетному сопротивлению пород и угля в кровле на одноосное сжатие Rc, МПа не более 25",
          "При прочности на одноосное сжатие оставляемой угольной пачки в кровле горной выработки или сопряжения не менее 6 МПа",
          "При проведении горных выработок в зонах разрывных геологических нарушений, при наличии выветренных, пластичных и мерзлых вмещающих пород",
        ],
        correctAnswers: [
          "При проведении горных выработок в зонах разрывных геологических нарушений, при наличии выветренных, пластичных и мерзлых вмещающих пород",
        ],
      },
      {
        code: "20503130",
        text: "Каково максимально допустимое расстояние установки анкерной крепи от забоя горной выработки, при наличии в кровле горной выработки неустойчивых пород обрушающихся в проходческом забое, до установки анкерной крепи?",
        answers: ["0,5 м", "1 м", "2 м", "3 м"],
        correctAnswers: ["1 м"],
      },
      {
        code: "20503131",
        text: "С какой периодичностью и с каким интервалом проводят оценку несущей способности применяемых анкеров в анкерной крепи при проведении или восстановлении выработок?",
        answers: [
          "В начале и в конце каждой смены через каждые 100 м длины горной выработки",
          "Не реже 1 раза в месяц через каждые 200 м длины горной выработки",
          "Не реже 1 раза в 2 месяца через каждые 100 м длины горной выработки",
          "Не реже 1 раза в квартал через каждые 200 м длины горной выработки",
        ],
        correctAnswers: [
          "Не реже 1 раза в месяц через каждые 200 м длины горной выработки",
        ],
      },
      {
        code: "20503132",
        text: "В каком случае проводится оценка несущей способности анкеров при проведении (восстановлении) выработок?",
        answers: [
          "Только при изменении прочностных свойств и обводненности пород кровли горной выработки",
          "Только при изменениях конструкции анкерной крепи",
          "Только при применении новых видов или составов ампул",
          "Только при применении новой технологии установки анкерной крепи",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20503133",
        text: "Какова периодичность визуального контроля работоспособности анкерной крепи вне зоны влияния очистных работ?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 2 месяца",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в полгода",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20503134",
        text: "Через сколько лет эксплуатации анкерной крепи в выработках необходимо производить научно-исследовательские работы с оценкой несущей способности, коррозионного износа и работоспособности анкеров?",
        answers: ["Через 5 лет", "Через 8 лет", "Через 3 года", "Через 10 лет"],
        correctAnswers: ["Через 5 лет"],
      },
      {
        code: "20503135",
        text: "К какому классу по устойчивости относится неустойчивая кровля?",
        answers: ["К I классу", "К II классу", "К III классу"],
        correctAnswers: ["К I классу"],
      },
      {
        code: "20503136",
        text: "К какому классу по устойчивости относится кровля средней устойчивости?",
        answers: ["К I классу", "К II классу", "К III классу"],
        correctAnswers: ["К II классу"],
      },
      {
        code: "20503137",
        text: "К какому классу по устойчивости относится устойчивая кровля?",
        answers: ["К I классу", "К II классу", "К III классу"],
        correctAnswers: ["К III классу"],
      },
      {
        code: "20503138",
        text: "Какой должна быть требуемая минимальная плотность установки анкеров в классе неустойчивой кровли?",
        answers: [
          "Не меньше 1 анк/м2",
          "Не меньше 0,7 анк/м2",
          "Не меньше 0,5 анк/м2",
          "Не меньше 0,3 анк/м2",
        ],
        correctAnswers: ["Не меньше 1 анк/м2"],
      },
      {
        code: "20503139",
        text: "К какому типу кровли (по обрушаемости) будет относиться однородная кровля из слоистых преимущественно глинистых, песчано-глинистых и песчаных сланцев?",
        answers: [
          "К I типу кровли",
          "К II типу кровли",
          "К III типу кровли",
          "К IV типу кровли",
        ],
        correctAnswers: ["К I типу кровли"],
      },
      {
        code: "20503140",
        text: "К какому типу кровли по обрушаемости относится кровля при сопротивлении пород одноосному сжатию Rc⩽30 МПа?",
        answers: [
          "К I типу кровли",
          "К II типу кровли",
          "К III типу кровли",
          "К IV типу кровли",
        ],
        correctAnswers: ["К III типу кровли"],
      },
      {
        code: "20503141",
        text: "К какой категории интенсивности проявления горного давления относится горная выработка при расчетном смещении пород кровли от 50 до 200 мм?",
        answers: [
          "К I категории (небольшое горное давление)",
          "К II категории (горное давление средней интенсивности)",
          "К III категории (интенсивное горное давление)",
        ],
        correctAnswers: [
          "К II категории (горное давление средней интенсивности)",
        ],
      },
      {
        code: "20503142",
        text: "К какой категории интенсивности проявления горного давления относится горная выработка при расчетном смещении пород кровли свыше 200 мм?",
        answers: [
          "К I категории (небольшое горное давление)",
          "К II категории (горное давление средней интенсивности)",
          "К III категории (интенсивное горное давление)",
        ],
        correctAnswers: ["К III категории (интенсивное горное давление)"],
      },
      {
        code: "20503143",
        text: "В каких горных выработках применяются анкеры с закреплением стержней минеральной композицией?",
        answers: [
          "Только в горных выработках, пройденных по пластам весьма склонных к самовозгоранию",
          "Только в горных выработках с обводненными породами",
          "Только в горных выработках со сроком службы более 10 лет",
          "Во всех перечисленных",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "20503144",
        text: "Какую расчетную несущую способность по всей длине скважины должны обеспечивать анкеры из стержней винтового профиля из стали А400С, А500С №25?",
        answers: ["120 кН", "140 кН", "160 кН", "240 кН"],
        correctAnswers: ["240 кН"],
      },
      {
        code: "20503145",
        text: "На протяжении какого периода времени собирается информация, на основании которой определяются пороговые значения параметров сейсмических событий применяемого метода регионального прогноза для каждого пласта?",
        answers: [
          "Не менее года",
          "Не менее 3 месяцев",
          "Не менее 6 месяцев",
          "Не регламентируется",
        ],
        correctAnswers: ["Не менее 6 месяцев"],
      },
      {
        code: "20503146",
        text: "Через какое максимальное расстояние подвигания забоя подготовительной или очистной выработки пороговые значения прогностических параметров переутверждаются?",
        answers: ["100 м", "200 м", "300 м", "400 м", "Все ответы неверны"],
        correctAnswers: ["300 м"],
      },
      {
        code: "20503147",
        text: "Кем утверждается документация на изоляцию неиспользуемых горных выработок и выработанных пространств от действующих горных выработок и от земной поверхности?",
        answers: [
          "Комиссией угледобывающей организации",
          "Инспектором Ростехнадзора",
          "Руководителем угледобывающей организации",
          "Главным инженером шахты",
        ],
        correctAnswers: ["Главным инженером шахты"],
      },
      {
        code: "20503148",
        text: "Какая из перечисленных мер не проводится при вскрытии угольных пластов вертикальными стволами для предотвращения внезапных выбросов согласно Рекомендациям по безопасному ведению горных работ на склонных к динамическим явлениям пластах?",
        answers: [
          "Гидрорыхление угольного пласта",
          "Возведение каркасной крепи",
          "Бурение дренажных скважин",
          "Увлажнение угольного массива",
          "Все ответы неверны",
        ],
        correctAnswers: ["Увлажнение угольного массива"],
      },
      {
        code: "20503149",
        text: "Какие способы управления кровлей применяются на склонных к горным ударам угольных пластах?",
        answers: [
          "Частичное обрушение пород в выработанном пространстве",
          "Закладка выработанного пространства",
          "Полное обрушение пород в выработанном пространстве",
          "Все перечисленные способы",
        ],
        correctAnswers: ["Все перечисленные способы"],
      },
      {
        code: "20503150",
        text: "Каким методом проводится прогноз удароопасности в месте вскрытия склонного к горным ударам угольного пласта подготовительной выработкой?",
        answers: [
          "Методом регионального прогноза по непрерывным сейсмоакустическим наблюдениям",
          "Методом текущего прогноза по параметрам выбросоопасности",
          "Методом локального прогноза по выходу буровой мелочи",
          "На усмотрение специалиста геологической службы угледобывающей организации",
        ],
        correctAnswers: [
          "Методом локального прогноза по выходу буровой мелочи",
        ],
      },
      {
        code: "20503151",
        text: "Куда не требуется записывать безопасную глубину выемки угля, определенную специалистом службы прогноза динамических явлений после проведения прогноза динамических явлений и контроля эффективности мер по их предотвращению?",
        answers: [
          "В наряд-путевку",
          "В участковую книгу нарядов",
          "На аншлаги прогноза динамических явлений",
          "В общешахтную книгу нарядов",
          "Все ответы неверны",
        ],
        correctAnswers: ["В общешахтную книгу нарядов"],
      },
      {
        code: "20503152",
        text: "В течение какого времени с момента отработки защитного пласта склонные к горным ударам угольные пласты в пределах защищенных зон считаются неопасными по горным ударам?",
        answers: [
          "В течение 1 года",
          "В течение 3 лет",
          "В течение 5 лет",
          "В течение 10 лет",
          "Все ответы неверны",
        ],
        correctAnswers: ["В течение 5 лет"],
      },
      {
        code: "20503153",
        text: "Какие из перечисленных мер по предотвращению динамических явлений относятся к региональным мерам?",
        answers: [
          "Бурение разгрузочных скважин",
          "Камуфлетное взрывание",
          "Опережающая отработка защитных угольных пластов, в том числе локальная отработка защитного угольного пласта",
          "Все перечисленные меры",
        ],
        correctAnswers: [
          "Опережающая отработка защитных угольных пластов, в том числе локальная отработка защитного угольного пласта",
        ],
      },
      {
        code: "20503154",
        text: "Кто утверждает маркшейдерскую документацию по ведению горных работ в границах незащищенных зон и (или) опасных зон?",
        answers: [
          "Начальник службы аэрологической безопасности",
          "Главный механик угледобывающей организации",
          "Руководитель угледобывающей организации",
          "Технический руководитель (главный инженер) угледобывающей организации",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Технический руководитель (главный инженер) угледобывающей организации",
        ],
      },
      {
        code: "20503155",
        text: "В каком случае применяется поэтапное бурение разгрузочных скважин для предотвращения внезапных выбросов?",
        answers: [
          "В случае, если это предусмотрено проектной документацией",
          "В случае, если скважины бурятся в очистных выработках угольных пластов",
          "В случае интенсивного газовыделения",
          "Не применяется ни в каком случае",
        ],
        correctAnswers: ["В случае интенсивного газовыделения"],
      },
      {
        code: "20503156",
        text: "В течение какого времени расследуются события, предшествующие динамическим явлениям при первом их проявлении?",
        answers: [
          "В течение 2 недель",
          "В течение 7 суток",
          "В течение 3 суток",
          "В течение суток",
          "Все ответы неверны",
        ],
        correctAnswers: ["В течение суток"],
      },
      {
        code: "20503157",
        text: "В скважинах какой минимальной длины устанавливаются геофоны на выемочных участках для проведения текущего прогноза по акустической эмиссии?",
        answers: ["1 м", "2 м", "3 м", "4 м", "5 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "20503158",
        text: "Кем утверждаются пороговые значения прогностических параметров, установленные по результатам текущего прогноза выбросоопасности по параметрам искусственного акустического сигнала?",
        answers: [
          "Работником специализированной организации",
          "Работником службы прогноза динамических явлений",
          "Работником геологической службы угледобывающей организации",
          "Техническим руководителем (главным инженером) угледобывающей организации",
        ],
        correctAnswers: [
          "Техническим руководителем (главным инженером) угледобывающей организации",
        ],
      },
      {
        code: "20503159",
        text: "В соответствии с какой документацией проводится оценка геодинамического состояния массива горных пород?",
        answers: [
          "В соответствии с документацией по проведению разведочных и (или) контрольных наблюдений",
          "В соответствии с документацией по определению выбросоопасности по локальному прогнозу",
          "В соответствии с документацией по прогнозу по параметрам аэрогазового контроля",
          "В соответствии с документацией по проведению геофизического мониторинга",
          "В соответствии с документацией по расчету пороговых значений прогностических параметров",
        ],
        correctAnswers: [
          "В соответствии с документацией по проведению геофизического мониторинга",
        ],
      },
      {
        code: "20503160",
        text: "Какая из перечисленных характеристик соответствует мелкокусковатому типу нарушенности угля?",
        answers: [
          "Заметно выраженная слоистость, уголь в массиве монолитный, устойчивый, не осыпается. Разламывается на куски, ограниченные слоистостью и трещинами",
          "Слоистость и трещиноватость затушеваны. Состоит из тонкоперетертого угля (угольная мука). Неустойчив, осыпается интенсивно. Между пальцами раздавливается легко",
          "Слоистость и трещиноватость затушеваны. Основная масса состоит из мелких зерен угля, между которыми располагается землистый уголь (угольная мука). Достаточно спрессован, между пальцами раздавливается с трудом. Уголь слабоустойчив и склонен к осыпанию",
          "Слоистость и трещиноватость, как правило, затушеваны. Массив состоит из угловатых кусков угля разнообразной формы. Между кусками может встречаться угольная мелочь, зернистый и даже землистый уголь. Уголь слабоустойчив к механическому воздействию, но осыпается с трудом",
          "Слоистость и трещиноватость затушеваны. Уголь слагается из отдельных линз, поверхность их зеркально отполирована и несет на себе борозды и штрихи скольжения. При механическом воздействии превращается иногда в угольную мелочь",
        ],
        correctAnswers: [
          "Слоистость и трещиноватость, как правило, затушеваны. Массив состоит из угловатых кусков угля разнообразной формы. Между кусками может встречаться угольная мелочь, зернистый и даже землистый уголь. Уголь слабоустойчив к механическому воздействию, но осыпается с трудом",
        ],
      },
      {
        code: "20503161",
        text: "Каким (по объему) должно быть содержание кислорода в рудничной атмосфере горных выработок, в которых находится или может находиться человек?",
        answers: [
          "Не менее 12 %",
          "Не менее 15 %",
          "Не менее 18 %",
          "Не менее 20 %",
        ],
        correctAnswers: ["Не менее 20 %"],
      },
      {
        code: "20503162",
        text: "Каким должно быть содержание диоксида углерода в рудничном воздухе на рабочих местах, в исходящих струях выемочных участков и тупиковых выработок?",
        answers: ["0,5 %", "От 0,3 % до 0,75 %", "0,75 %", "От 0,5 % до 1,0 %"],
        correctAnswers: ["0,5 %"],
      },
      {
        code: "20503163",
        text: "Какие действия следует предпринять при выявлении несоответствия состава вредных газов в рудничной атмосфере действующих горных выработок требованиям, установленным Правилами безопасности в угольных шахтах?",
        answers: [
          "Люди из этих горных выработок должны выйти в горные выработки с пригодной для дыхания рудничной атмосферой или на поверхность и сообщить об этом горному диспетчеру шахты",
          "Работы должны быть прекращены, персонал должен оставаться на рабочих местах, дожидаясь дальнейших указаний от горного диспетчера шахты",
          "Работы должны быть временно приостановлены, а персонал должен незамедлительно покинуть горную выработку, никого не оповещая",
          "Работы должны быть временно приостановлены, а персонал должен быть оповещен о выявлении несоответствия горным диспетчером шахты",
        ],
        correctAnswers: [
          "Люди из этих горных выработок должны выйти в горные выработки с пригодной для дыхания рудничной атмосферой или на поверхность и сообщить об этом горному диспетчеру шахты",
        ],
      },
      {
        code: "20503164",
        text: "Какая максимальная скорость воздуха в стволах, предназначенных для спуска и подъема грузов и используемых при аварии для вывода людей?",
        answers: ["5 м/с", "8 м/с", "10 м/с", "15 м/с", "Все ответы неверны"],
        correctAnswers: ["10 м/с"],
      },
      {
        code: "20503165",
        text: "В каких горных выработках угольных шахт минимальная средняя по сечению скорость воздуха составляет 0,25 м/с?",
        answers: [
          "В тупиковых горных выработках, проводимых по пластам, опасным по внезапным выбросам угля (породы) и газа, опасным по суфлярным проявлениям",
          "В тупиковых горных выработках, проводимых по угольным пластам мощностью более 2 м, при разности между природной и остаточной метаноносностью пласта на участке их проведения 5 м³/т и выше",
          "В призабойном пространстве тупиковых горных выработок негазовых и газовых шахт",
          "В призабойном пространстве очистных выработок негазовых шахт и газовых шахт",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "В призабойном пространстве тупиковых горных выработок негазовых и газовых шахт",
        ],
      },
      {
        code: "20503166",
        text: "В соответствии с какой документацией проводится объединение угольных шахт с независимым проветриванием в одну вентиляционную систему?",
        answers: [
          "В соответствии с технологическим регламентом",
          "В соответствии с Правилами безопасности в угольных шахтах",
          "В соответствии с предписанием Ростехнадзора",
          "В соответствии с проектной документацией",
          "Все ответы неверны",
        ],
        correctAnswers: ["В соответствии с проектной документацией"],
      },
      {
        code: "20503167",
        text: "Какие перечисленные горные выработки и скважины угольных шахт не подлежат изоляции?",
        answers: [
          "Скважины, предназначенные для борьбы с внезапными выбросами угля (породы) и газа",
          "Горные выработки, используемые для отвода метана из выработанных пространств, со стороны действующих горных выработок",
          "Неиспользуемые горные выработки и скважины",
          "Отработанные выемочные участки (поля)",
          "Все перечисленные горные выработки и скважины подлежат изоляции",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Скважины, предназначенные для борьбы с внезапными выбросами угля (породы) и газа",
        ],
      },
      {
        code: "20503168",
        text: "Как характеризуется общее загазирование горных выработок?",
        answers: [
          "Наличием скопления метана с концентрацией 2 % и более в отдельных местах выработок, в том числе у буровых станков, комбайнов и врубовых машин, в открытых, не заложенных породой или другими материалами куполах, превышения нормы концентрации метана, зафиксированного одним датчиком системы аэрогазового контроля (далее - АГК) в действующих выработках шахты",
          "Наличием скопления метана в виде слоя в выработках на участках длиной свыше двух метров с концентрацией более 2 %",
          "Превышением нормы концентрации метана в сечении выработки, превышением нормы концентрации метана, зафиксированным двумя и более датчиками системы АГК в подготовительной выработке или в выработках выемочного участка",
        ],
        correctAnswers: [
          "Превышением нормы концентрации метана в сечении выработки, превышением нормы концентрации метана, зафиксированным двумя и более датчиками системы АГК в подготовительной выработке или в выработках выемочного участка",
        ],
      },
      {
        code: "20503169",
        text: "Как характеризуется местное загазирование горных выработок?",
        answers: [
          "Наличием скопления метана с концентрацией 2 % и более в отдельных местах выработок, в том числе у буровых станков, комбайнов и врубовых машин, в открытых, не заложенных породой или материалами куполах, превышения нормы концентрации метана, зафиксированного одним датчиком системы аэрогазового контроля (АГК) в действующих выработках шахты",
          "Наличием скопления метана в виде слоя в выработках на участках длиной свыше 2 м с концентрацией более 2 %",
          "Превышением нормы концентрации метана в сечении выработки, превышением нормы концентрации метана, зафиксированным двумя и более датчиками системы АГК в подготовительной выработке или в выработках выемочного участка",
        ],
        correctAnswers: [
          "Наличием скопления метана с концентрацией 2 % и более в отдельных местах выработок, в том числе у буровых станков, комбайнов и врубовых машин, в открытых, не заложенных породой или материалами куполах, превышения нормы концентрации метана, зафиксированного одним датчиком системы аэрогазового контроля (АГК) в действующих выработках шахты",
        ],
      },
      {
        code: "20503170",
        text: "Как характеризуется слоевое загазирование горных выработок?",
        answers: [
          "Наличием скопления метана с концентрацией 2 % и более в отдельных местах выработок, в том числе у буровых станков, комбайнов и врубовых машин, в открытых, не заложенных породой или другими материалами куполах, превышения нормы концентрации метана, зафиксированного одним датчиком системы аэрогазового контроля (АГК) в действующих выработках шахты",
          "Наличием скопления метана в виде слоя в выработках на участках длиной свыше 2 м с концентрацией более 2 %",
          "Превышением нормы концентрации метана в сечении выработки, превышением нормы концентрации метана, зафиксированным двумя и более датчиками системы АГК в подготовительной выработке или в выработках выемочного участка",
        ],
        correctAnswers: [
          "Наличием скопления метана в виде слоя в выработках на участках длиной свыше 2 м с концентрацией более 2 %",
        ],
      },
      {
        code: "20503171",
        text: "Кому должен сообщить горный диспетчер информацию о загазировании или нарушении проветривания?",
        answers: [
          "Главному инженеру шахты",
          "Начальнику участка аэрологической безопасности",
          "Начальнику технологического участка, в выработках которого произошло загазирование",
          "Всем перечисленным лицам",
        ],
        correctAnswers: ["Всем перечисленным лицам"],
      },
      {
        code: "20503172",
        text: "Кто назначает ИТР для выполнения мероприятий по безопасному разгазированию горных выработок и расследованию причин загазирования?",
        answers: [
          "Начальник участка аэрологической безопасности",
          "Руководитель шахты",
          "Инспектор Ростехнадзора",
          "Главный инженер шахты",
          "Все ответы неверны",
        ],
        correctAnswers: ["Главный инженер шахты"],
      },
      {
        code: "20503173",
        text: "Какова минимальная продолжительность ﻿аварийного загазирования горных выработок для расследования причин его возникновения в соответствии с порядком проведения технического расследования причин аварий, инцидентов и случаев утраты взрывчатых материалов промышленного назначения на объектах, поднадзорных Ростехнадзору?",
        answers: ["2 часа", "3,5 часа", "5 часов", "6 часов"],
        correctAnswers: ["6 часов"],
      },
      {
        code: "20503174",
        text: "При какой продолжительности аварийных загазирований горных выработок их расследование проводится под руководством начальника участка аэрологической безопасности (АБ) или его заместителя (помощника)?",
        answers: [
          "Более 2 часов, но менее 6 часов",
          "Более 1 часа",
          "Более 30 минут",
          "Менее 30 минут",
        ],
        correctAnswers: ["Менее 30 минут"],
      },
      {
        code: "20503175",
        text: "В течение какого времени должны расследоваться аварийные загазирования горных выработок продолжительностью менее 6 часов?",
        answers: [
          "В течение суток",
          "В течение первых 2 дней",
          "В течение первых 3 дней",
          "В течение недели",
        ],
        correctAnswers: ["В течение суток"],
      },
      {
        code: "20503176",
        text: "В течение какого времени должны храниться акты расследования загазирования горных выработок?",
        answers: [
          "В течение 1 года",
          "В течение 2 лет",
          "В течение 3 лет",
          "В течение 5 лет",
        ],
        correctAnswers: ["В течение 3 лет"],
      },
      {
        code: "20503177",
        text: "Какое из перечисленных требований к учету загазирований горных выработок указано неверно?",
        answers: [
          "Превышения концентрации метана в выработках выемочного участка принимаются к учету как одно загазирование",
          "Причины нарушения нормальной работы системы АГК и требований по ее эксплуатации заносятся в журнал эксплуатации системы аэрогазового контроля",
          "Случаи нарушения нормальной работы системы аэрогазового контроля и требований по ее эксплуатации расследуются специалистами шахты",
          "Случаи превышения концентрации метана, обусловленные выполнением работ по техническому и метрологическому обслуживанию системы аэрогазового контроля, принимаются к учету как загазирования горных выработок и расследуются",
        ],
        correctAnswers: [
          "Случаи превышения концентрации метана, обусловленные выполнением работ по техническому и метрологическому обслуживанию системы аэрогазового контроля, принимаются к учету как загазирования горных выработок и расследуются",
        ],
      },
      {
        code: "20503178",
        text: "Кем разрабатываются мероприятия по разгазированию горных выработок до начала проведения подготовительных выработок и отработки выемочных участков?",
        answers: [
          "Техническим руководителем (главным инженером) шахты",
          "Специализированной организацией",
          "Начальником проходческого (добычного) участка",
          "Начальником участка аэрологической безопасности (АБ)",
        ],
        correctAnswers: [
          "Начальником участка аэрологической безопасности (АБ)",
        ],
      },
      {
        code: "20503179",
        text: "В течение какого времени после реализации изменений схемы проветривания выработок или схемы энергоснабжения, предусмотренной паспортом, вносятся соответствующие дополнения в мероприятия по разгазированию горных выработок?",
        answers: [
          "По усмотрению начальника участка аэрологической безопасности",
          "В течение 7 суток",
          "В течение 3 суток",
          "В течение суток",
          "Все ответы неверны",
        ],
        correctAnswers: ["В течение суток"],
      },
      {
        code: "20503180",
        text: "У каких лиц находится по одному экземпляру мероприятий по разгазированию горных выработок? Укажите все правильные ответы.",
        answers: [
          "У начальника проходческого (добычного) участка",
          "У специалиста по промышленной безопасности",
          "У диспетчера шахты",
          "У начальника участка аэрологической безопасности (АБ)",
          "У руководителя шахты",
        ],
        correctAnswers: [
          "У диспетчера шахты",
          "У начальника участка аэрологической безопасности (АБ)",
        ],
      },
      {
        code: "20503181",
        text: "Под чьим руководством проводятся разгазирование очистных выработок, выработок выемочного участка, а также подготовительных выработок, не проветриваемых более 6 часов?",
        answers: [
          "Под руководством начальника участка аэрологической безопасности (заместителя, помощника)",
          "Под руководством начальника проходческого (добычного) участка или его заместителя (помощника)",
          "Под руководством главного инженера шахты",
          "Под руководством всех перечисленных лиц",
        ],
        correctAnswers: ["Под руководством главного инженера шахты"],
      },
      {
        code: "20503182",
        text: "При каком минимальном времени прекращения проветривания непроветриваемых подготовительных выработок длиной более 6 м в газовых шахтах при отсутствии информации о концентрации метана в них эти выработки относятся к загазированным?",
        answers: ["3 минуты", "5 минут", "15 минут", "30 минут"],
        correctAnswers: ["5 минут"],
      },
      {
        code: "20503183",
        text: "В каком случае разгазирование горных выработок проводится под руководством ИТР проходческого участка?",
        answers: [
          "При разгазировании подготовительных выработок, не проветриваемых менее 30 минут",
          "При разгазировании очистных выработок, выработок выемочного участка, а также подготовительных выработок, не проветриваемых более 30 минут, но менее 6 часов",
          "При одновременном или последовательном разгазировании нескольких очистных и (или) подготовительных выработок, не проветриваемых более 6 часов",
          "При ликвидации последствий внезапных выделений метана и суфляров",
        ],
        correctAnswers: [
          "При разгазировании подготовительных выработок, не проветриваемых менее 30 минут",
        ],
      },
      {
        code: "20503184",
        text: "С какой периодичностью устанавливается контрольное время загазирования подготовительной выработки, проветриваемой вентиляторами местного проветривания?",
        answers: [
          "Еженедельно",
          "Ежеквартально",
          "Ежемесячно",
          "Ежегодно",
          "Все ответы неверны",
        ],
        correctAnswers: ["Ежемесячно"],
      },
      {
        code: "20503185",
        text: "При каком контрольном времени загазирования не проводятся опытные загазирования в подготовительных выработках?",
        answers: [
          "Менее 40 минут",
          "Менее 30 минут",
          "Менее 25 минут",
          "Менее 20 минут",
        ],
        correctAnswers: ["Менее 20 минут"],
      },
      {
        code: "20503186",
        text: "При какой концентрации метана разрешается включение вентилятора местного проветривания (ВМП) в месте его установки при разгазировании подготовительных выработок?",
        answers: ["До 1 %", "До 0,5 %", "До 1,5 %", "До 2 %"],
        correctAnswers: ["До 0,5 %"],
      },
      {
        code: "20503187",
        text: "Какое из перечисленных требований к предупреждению и ликвидации загазирований в горных выработках указано верно?",
        answers: [
          "Результаты анализа причин загазирования горных выработок утверждаются руководителем шахты",
          "Мероприятия по предупреждению загазирований горных выработок разрабатываются техническим руководителем (главным инженером) шахты",
          "Порядок применения способов предупреждения и ликвидации загазирований утверждает главный инженер шахты",
          "Технический руководитель (главный инженер) шахты 1 раз в год производит анализ причин загазирования горных выработок",
        ],
        correctAnswers: [
          "Порядок применения способов предупреждения и ликвидации загазирований утверждает главный инженер шахты",
        ],
      },
      {
        code: "20503188",
        text: "Какой должна быть температура воздуха, поступающего в горные выработки шахты?",
        answers: [
          "Не ниже 2 оС",
          "Не ниже 1 оС",
          "Не ниже 0 оС",
          "Не ниже -1 оС",
        ],
        correctAnswers: ["Не ниже 2 оС"],
      },
      {
        code: "20503189",
        text: "С какой периодичностью на шахте проводится плановая воздушно-депрессионная съемка?",
        answers: [
          "Не реже 1 раза в 5 лет",
          "Не реже 1 раза в 4 года",
          "Не реже 1 раза в 3 года",
        ],
        correctAnswers: ["Не реже 1 раза в 3 года"],
      },
      {
        code: "20503190",
        text: "Каково максимально допустимое расстояние между смежными лавами при последовательном проветривании?",
        answers: ["100 м", "200 м", "300 м", "500 м"],
        correctAnswers: ["300 м"],
      },
      {
        code: "20503191",
        text: "Какова минимально допустимая ширина проемов, в которых устраивают двери для прохода людей?",
        answers: ["0,7 м", "1 м", "1,2 м", "Не нормируется"],
        correctAnswers: ["0,7 м"],
      },
      {
        code: "20503192",
        text: "Какова минимально допустимая высота проемов, в которых устраивают двери для прохода людей?",
        answers: ["1,6 м", "1,8 м", "2 м", "2,2 м"],
        correctAnswers: ["1,8 м"],
      },
      {
        code: "20503193",
        text: "Кто принимает решение по изменению направления движения и расхода воздуха в горных выработках угольных шахт?",
        answers: [
          "Руководитель шахты",
          "Главный инженер шахты",
          "Специалисты структурного подразделения, в ведении которых находится горная выработка",
          "Начальник участка аэрологической безопасности (АБ)",
          "Все ответы неверны",
        ],
        correctAnswers: ["Начальник участка аэрологической безопасности (АБ)"],
      },
      {
        code: "20503194",
        text: "Какова минимально допустимая высота ограждающих решеток перед колесом вентилятора в каналах вентиляторов главного проветривания (ВГП) и вспомогательных вентиляторных установок (ВВУ)?",
        answers: ["1,2 м", "1,5 м", "1,8 м", "2,1 м"],
        correctAnswers: ["1,5 м"],
      },
      {
        code: "20503195",
        text: "Какова минимально допустимая периодичность проверки исправности реверсивных, переключающих и герметизирующих устройств главным механиком шахты и начальником участка аэрологической безопасности (АБ)?",
        answers: [
          "1 раз в месяц",
          "1 раз в 2 месяца",
          "1 раз в квартал",
          "1 раз в полугодие",
        ],
        correctAnswers: ["1 раз в месяц"],
      },
      {
        code: "20503196",
        text: "С какой периодичностью и кем должно проверяться состояние вентиляторов главного проветривания (ВГП) и вспомогательных вентиляторных установок (ВВУ) на шахтах?",
        answers: [
          "Не реже 1 раза в сутки - обсуживающим персоналом и не менее 2 раз в месяц - главным механиком шахты и (или) ИТР шахты, им определенным",
          "Не реже 1 раза в сутки - горным мастером и не реже 1 раза в месяц - начальником участка или его заместителем",
          "Не реже 1 раза в трое суток - горным мастером и не реже 1 раза в месяц - представителем территориального органа Ростехнадзора",
          "Не реже 1 раза в сутки - работниками, специально назначенными главным механиком шахты, и не менее 1 раза в месяц - представителем аварийно-спасательной службы, обслуживающей организацию",
        ],
        correctAnswers: [
          "Не реже 1 раза в сутки - обсуживающим персоналом и не менее 2 раз в месяц - главным механиком шахты и (или) ИТР шахты, им определенным",
        ],
      },
      {
        code: "20503197",
        text: "С какой периодичностью должны производиться ревизия, наладка и аэродинамическое обследование вентиляторов главного проветривания (ВГП) и вспомогательных вентиляторных установок (ВВУ)?",
        answers: [
          "В соответствии с документацией организации-изготовителя и эксплуатационной документацией шахты не реже 1 раза в 2 года",
          "Не реже 1 раза в 3 года",
          "После случаев возникновения отказов в работе               вентиляторных установок",
          "В соответствии с графиком планово-предупредительного ремонта",
        ],
        correctAnswers: [
          "В соответствии с документацией организации-изготовителя и эксплуатационной документацией шахты не реже 1 раза в 2 года",
        ],
      },
      {
        code: "20503198",
        text: "Кем принимается решение об остановке вентиляторов главного проветривания (ВГП)?",
        answers: [
          "Командиром аварийно-спасательной части, обслуживающей шахту",
          "Руководителем специализированной организации по ремонту и наладке вентиляторных установок",
          "Начальником участка аэрологической безопасности (АБ) шахты",
          "Главным инженером шахты",
        ],
        correctAnswers: ["Главным инженером шахты"],
      },
      {
        code: "20503199",
        text: "Какие из перечисленных условий для обеспечения безопасности ведения горных работ должны быть соблюдены при применении вентиляторов местного проветривания (ВМП) с пневматическими двигателями для проветривания проводимых или погашаемых вентиляционных горных выработок, примыкающих к лаве?",
        answers: [
          "Только то, что ВМП должен быть расположен не менее 15 м от сопряжения с лавой, считая по ходу вентиляционной струи",
          "Только то, что длина тупиковой части выработки не должна превышать 30 м",
          "Только то, что содержание метана в исходящей из тупиковой части выработки струе не должно превышать 1 %",
          "Только то, что конструкция ВМП исключает возможность воспламенения метана при ударах и трении вращающихся частей о корпус вентилятора",
          "Все перечисленные условия",
        ],
        correctAnswers: ["Все перечисленные условия"],
      },
      {
        code: "20503200",
        text: "На каком расстоянии от конца вентиляционного трубопровода до забоя в газовых и негазовых шахтах организуется проветривание тупиковых горных выработок?",
        answers: [
          "В газовых шахтах не более 3 м, а в негазовых - 10 м",
          "В газовых шахтах не более 7 м, а в негазовых - 15 м",
          "В газовых шахтах не более 10 м, а в негазовых - 15 м",
          "В газовых шахтах не более 5 м, а в негазовых - 12 м",
          "Все ответы неверны",
        ],
        correctAnswers: ["В газовых шахтах не более 5 м, а в негазовых - 12 м"],
      },
      {
        code: "20503201",
        text: "Проветривание каких тупиковых горных выработок допускается за счет диффузии?",
        answers: [
          "Длиной до 5 м при условии, что горные работы в них не ведутся",
          "Длиной до 6 м при условии, что горные работы в них не ведутся",
          "Длиной до 7 м при условии, что горные работы в них не ведутся",
          "Длиной до 10 м при условии, что горные работы в них не ведутся",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Длиной до 6 м при условии, что горные работы в них не ведутся",
        ],
      },
      {
        code: "20503202",
        text: "На каком расстоянии от устья необходимо размещать вентиляторные установки для проветривания вертикальных горных выработок, проводимых с поверхности?",
        answers: [
          "Не менее 20 м",
          "От 15 м до 20 м",
          "Не менее 10 м",
          "От 10 м до 15 м",
        ],
        correctAnswers: ["Не менее 20 м"],
      },
      {
        code: "20503203",
        text: "На каком расстоянии от устья тупиковой горной выработки, проводимой по пластам, опасным по внезапным выбросам угля (породы) и газа, или по выбросоопасным породам необходимо устанавливать вентиляторы местного проветривания (ВМП) с электрическими двигателями для ее проветривания?",
        answers: [
          "Не менее 150 м",
          "От 130 до 150 м",
          "Не менее 100 м",
          "От 100 до 130 м",
        ],
        correctAnswers: ["Не менее 150 м"],
      },
      {
        code: "20503204",
        text: "В какой срок после начала проведения горных выработок, оконтуривающих выемочный участок, определяется фрикционная опасность горных пород для каждого выемочного участка?",
        answers: [
          "Не более 1 недели",
          "Не более 2 недель",
          "Не более 1 месяца",
          "Не более 2 месяцев",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не более 1 месяца"],
      },
      {
        code: "20503205",
        text: "Каким должно быть давление на форсунках (оросителях) выемочных и проходческих комбайнов на угольных шахтах?",
        answers: [
          "Не менее 0,5 МПа",
          "Не менее 1,0 МПа",
          "Не менее 1,5 МПа",
          "Определяется проектной документацией",
          "Все ответы неверны",
        ],
        correctAnswers: ["Определяется проектной документацией"],
      },
      {
        code: "20503206",
        text: "Какой способ проветривания должен применяться на негазовых и газовых шахтах при метанообильности шахты не более 10 м﻿3/т, при отработке верхних горизонтов и на шахтах, имеющих аэродинамическую связь горных выработок и выработанного пространства с поверхностью?",
        answers: [
          "Всасывающий способ",
          "Комбинированный способ",
          "Нагнетательный способ",
        ],
        correctAnswers: ["Нагнетательный способ"],
      },
      {
        code: "20503207",
        text: "Что необходимо предусматривать при выборе способов и схем проветривания на шахтах, разрабатывающих склонные к самовозгоранию пласты угля?",
        answers: [
          "Только снижение действующих напоров (депрессии) в районе очистных работ",
          "Только уменьшение величины и продолжительности притока воздуха в выработанное пространство",
          "Только надежное управление вентиляционными режимами в аварийной обстановке",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20503208",
        text: "Какие исходные сведения должен представить главный инженер шахты техническому руководителю (главному инженеру) угледобывающей организации для установления категории шахты по метану и (или) диоксиду углерода?",
        answers: [
          "Расчеты газообильностей выемочных участков, крыльев, панелей, блоков, горизонтов, шахтопластов и шахты",
          "Сведения о категории шахты по метану и (или) диоксиду углерода и ее абсолютная, и относительная газообильности в предыдущем году, опасность шахты по взрывчатости угольной пыли",
          "Сведения о суфлярных выделениях метана в выработках шахты, в том числе за предшествующий год, сведения о внезапных выбросах угля (породы) и газа",
          "Все перечисленные данные",
        ],
        correctAnswers: ["Все перечисленные данные"],
      },
      {
        code: "20503209",
        text: "Какие шахты     относятся к I категории шахт по газу (метану и (или) диоксиду углерода)?",
        answers: [
          "С относительной газообильностью до 5 м3/т ",
          "С относительной газообильностью от 5 до 10 м3/т ",
          "С относительной газообильностью от 10 до 15 м3/т ",
          "С относительной газообильностью 15 м3/т и более",
        ],
        correctAnswers: ["С относительной газообильностью до 5 м3/т "],
      },
      {
        code: "20503210",
        text: "При какой концентрации метана возле буровых станков и комбайнов допускается возобновление их работы после остановки?",
        answers: [
          "После снижения концентрации менее 2 %",
          "После снижения концентрации менее 1,5 %",
          "После снижения концентрации менее 1,2 %",
          "После снижения концентрации менее 1 %",
        ],
        correctAnswers: ["После снижения концентрации менее 1 %"],
      },
      {
        code: "20503211",
        text: "Какова максимально допустимая концентрация метана в трубопроводах для изолированного отвода метана и в газодренажных горных выработках?",
        answers: [
          "3,5 % по объему",
          "0,75 % по объему",
          "0,5 % по объему",
          "2 % по объему",
        ],
        correctAnswers: ["3,5 % по объему"],
      },
      {
        code: "20503212",
        text: "Какая максимально допустимая концентрация оксид углерода в рудничной атмосфере действующих горных выработках?",
        answers: [
          "2 мг/м3",
          "5 мг/м3",
          "10 мг/м3",
          "20 мг/м3",
          "Все ответы неверны",
        ],
        correctAnswers: ["20 мг/м3"],
      },
      {
        code: "20503213",
        text: "Максимально допустимая скорость воздуха в каких горных выработках угольных шахт составляет 4 м/с?",
        answers: [
          "В главных откаточных штреках",
          "В вентиляционных скважинах",
          "В очистных и тупиковых горных выработках",
          "В стволах, предназначенных только для спуска и подъема грузов",
          "Все ответы неверны",
        ],
        correctAnswers: ["В очистных и тупиковых горных выработках"],
      },
      {
        code: "20503214",
        text: "Какая максимально допустимая скорость воздуха в вентиляционных скважинах угольных шахт?",
        answers: [
          "Скорость воздуха не ограничена",
          "10 м/с",
          "15 м/с",
          "20 м/с",
          "Все ответы неверны",
        ],
        correctAnswers: ["Скорость воздуха не ограничена"],
      },
      {
        code: "20503215",
        text: "В каких случаях дегазация в угольных шахтах обязательна?",
        answers: [
          "Когда извлечение и утилизация шахтного метана экономически выгодны",
          "Когда концентрация метана в газопроводах и газодренажных выработках превышает 3,5 %",
          "Когда природная метаноносность пласта превышает 13 м³/т сухой беззольной массы и работами по вентиляции невозможно обеспечить содержание метана в исходящей струе очистной горной выработки в размере менее 1 %",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20503216",
        text: "Каким должно быть содержание метана в скважине при дегазации выработанных пространств ликвидированных шахт?",
        answers: [
          "Не менее 5 %",
          "Не менее 10 %",
          "Не менее 15 %",
          "Не менее 25 %",
        ],
        correctAnswers: ["Не менее 25 %"],
      },
      {
        code: "20503217",
        text: "Кем принимается решение о прекращении дегазации на выемочном участке или в подготовительной выработке?",
        answers: [
          "Любым из перечисленных лиц",
          "Руководителем профессиональной аварийно-спасательной службы (формирования)",
          "Руководителем работ по локализации и ликвидации последствий аварии",
          "Главным инженером шахты",
        ],
        correctAnswers: ["Главным инженером шахты"],
      },
      {
        code: "20503218",
        text: "В какие сроки производится замена вышедших из строя дегазационных станций (ДС) и дегазационных установок (ДУ), предназначенных для предварительной пластовой дегазации разрабатываемых пластов и выработанных пространств ранее отработанных выемочных столбов?",
        answers: [
          "Не нормируется",
          "В течение недели",
          "В течение 3 суток",
          "В течение 1 суток",
        ],
        correctAnswers: ["В течение 1 суток"],
      },
      {
        code: "20503219",
        text: "Какая минимальная высота ограды, которой обносится территория дегазационных станций (ДС) и дегазационных установок (ДУ) и территория наземных скважин, расположенных за территорией ДС и ДУ?",
        answers: ["1 м", "1,2 м", "1,5 м", "1,8 м", "Все ответы неверны"],
        correctAnswers: ["1,5 м"],
      },
      {
        code: "20503220",
        text: "Какой минимальный воздухообмен должно обеспечить аварийное проветривание помещений дегазационных станций и дегазационной установки за счет принудительной вентиляции в течение 1 часа при превышении допустимого уровня концентрации метана?",
        answers: [
          "Двукратный воздухообмен",
          "Трехкратный воздухообмен",
          "Четырехкратный воздухообмен",
          "Пятикратный воздухообмен",
        ],
        correctAnswers: ["Трехкратный воздухообмен"],
      },
      {
        code: "20503221",
        text: "Какой дегазационный газопровод считается выдержавшим испытание?",
        answers: [
          "Если снижение разрежения в нем за первые 120 минут после его перекрытия не превышает 15 мм рт. ст.",
          "Если снижение разрежения в нем за первые 90 минут после его перекрытия не превышает 20 мм рт. ст.",
          "Если снижение разрежения в нем за первые 60 минут после его перекрытия не превышает 15 мм рт. ст.",
          "Если снижение разрежения в нем за первые 30 минут после его перекрытия не превышает 10 мм рт. ст.",
        ],
        correctAnswers: [
          "Если снижение разрежения в нем за первые 30 минут после его перекрытия не превышает 10 мм рт. ст.",
        ],
      },
      {
        code: "20503222",
        text: "Какой должна быть минимальная освещенность помещений ДУ?",
        answers: ["10 лк", "20 лк", "25 лк", "30 лк"],
        correctAnswers: ["30 лк"],
      },
      {
        code: "20503223",
        text: "Какая минимальная толщина стенок должна быть у дегазационных газопроводов из стальных труб?",
        answers: ["1,5 мм", "2 мм", "2,5 мм", "3 мм", "3,5 мм"],
        correctAnswers: ["2,5 мм"],
      },
      {
        code: "20503224",
        text: "Какие требования к монтажу, оснащению и эксплуатации дегазационных газопроводов указаны неверно?",
        answers: [
          "Для уплотнения фланцевых соединений применяются прокладки из трудносгораемого материала, внутренний диаметр которых на 2 - 3 мм больше внутреннего диаметра трубы",
          "Трубы в дегазационном газопроводе испытываются на давление 1,2 МПа при его прокладке по горизонтальным или наклонным выработкам и 2,0 МПа - при прокладке по вертикальным выработкам",
          "Минимальный диаметр участкового трубопровода принимается не менее 150 мм, минимальный диаметр магистрального трубопровода - не менее 300 мм",
          "Трубы газопровода в скважинах и стволах соединяются сваркой. Для увеличения прочности на сварные швы накладываются пластины или бандажи длиной 150 - 200 мм",
        ],
        correctAnswers: [
          "Трубы в дегазационном газопроводе испытываются на давление 1,2 МПа при его прокладке по горизонтальным или наклонным выработкам и 2,0 МПа - при прокладке по вертикальным выработкам",
        ],
      },
      {
        code: "20503225",
        text: "Какие мероприятия выполняет угледобывающая организация в целях организации и осуществления работ по дегазации?",
        answers: [
          "Производит или организует бурение дегазационных скважин",
          "Производит замену вакуум-насосов и прокладку газопроводов",
          "Контролирует качество бурения и герметизации дегазационных скважин",
          "Осуществляет периодические измерения концентрации и дебита метана, разрежения на скважинах и газопроводах под контролем участка аэрологической безопасности",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20503226",
        text: "Какой минимальный стаж работы в газовых шахтах должно иметь лицо, назначенное для организации работ по дегазации шахт?",
        answers: ["5 лет", "3 года", "2 года", "1 год", "Все ответы неверны"],
        correctAnswers: ["1 год"],
      },
      {
        code: "20503227",
        text: "С какой периодичностью проводится проверка знаний требований Инструкции по аэрологической безопасности угольных шахт у руководителей и ИТР экзаменационными комиссиями?",
        answers: [
          "1 раз в 12 месяцев",
          "1 раз в 3 года",
          "1 раз в 5 лет",
          "Периодичность проверки знаний устанавливает главный инженер шахты",
          "Все ответы неверны",
        ],
        correctAnswers: ["1 раз в 5 лет"],
      },
      {
        code: "20503228",
        text: "При соблюдении каких условий по обеспечению промышленной безопасности допускается транспортирование метановоздушной смеси в дегазационных трубопроводах с содержанием метана менее 25 %?",
        answers: [
          "При установке электрооборудования на одной стороне с местом прокладки трубопровода",
          "При использовании открытого огня для размораживания газопровода в зимний период времени",
          "При назначении лиц, персонально ответственных за состояние выработок, в которых проложен дегазационный трубопровод",
          "При соблюдении всех перечисленных условий",
        ],
        correctAnswers: [
          "При назначении лиц, персонально ответственных за состояние выработок, в которых проложен дегазационный трубопровод",
        ],
      },
      {
        code: "20503229",
        text: "Где применяется система аэрогазового контроля (АГК) на угольных шахтах?",
        answers: [
          "Только в подземных выработках угольных шахт, опасных по газу и (или) пыли",
          "Только в наземных помещениях шахт",
          "Только в поверхностных технологических комплексах шахт, связанных с приемкой, хранением и погрузкой угля",
          "Во всех перечисленных областях",
        ],
        correctAnswers: ["Во всех перечисленных областях"],
      },
      {
        code: "20503230",
        text: "При каком максимальном содержании метана (% объемной доли) в исходящей струе воздуха из очистной или тупиковой выработки, камеры, выемочного участка, поддерживаемой выработки в них обеспечивается безопасное аэрогазовое состояние по метану?",
        answers: ["0,5 %", "0,75 %", "1 %", "2 %"],
        correctAnswers: ["1 %"],
      },
      {
        code: "20503231",
        text: "При каком максимальном содержании метана (% объемной доли) в камере подземной дегазационной станции обеспечивается безопасное аэрогазовое состояние по метану?",
        answers: ["0,5 %", "0,75 %", "1 %", "2 %"],
        correctAnswers: ["1 %"],
      },
      {
        code: "20503232",
        text: "При каком максимальном содержании метана (% объемной доли) в исходящей струе крыла, шахты обеспечивается безопасное аэрогазовое состояние по метану?",
        answers: ["0,5 %", "0,75 %", "1 %", "2 %"],
        correctAnswers: ["0,75 %"],
      },
      {
        code: "20503233",
        text: "В каком случае допускается производить отключение электроэнергии ниже предаварийных уставок, указанных в Инструкции по аэрологической безопасности угольных шахт?",
        answers: [
          "По решению начальника участка аэрологической безопасности",
          "По решению государственного метрологического контроля и надзора",
          "По решению главного инженера шахты",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["По решению главного инженера шахты"],
      },
      {
        code: "20503234",
        text: "При каком превышении содержания метана в рудничной атмосфере в газоотсасывающих трубопроводах подземных и поверхностных газоотсасывающих установок происходит автоматическое отключение электроэнергии, подаваемое на оборудование в контролируемых (защищаемых) выработках?",
        answers: [
          "0,75 % объемной доли",
          "1,0 % объемной доли",
          "2,0 % объемной доли",
          "3,5 % объемной доли",
        ],
        correctAnswers: ["3,5 % объемной доли"],
      },
      {
        code: "20503235",
        text: "Куда передает сведения угледобывающая организация о превышении пороговых значений концентрации метана (выше предаварийных уставок) и срабатывании систем защиты по каналам связи?",
        answers: [
          "В Росздравнадзор",
          "В МЧС России",
          "В Росгидромет",
          "В территориальный орган Ростехнадзора",
          "В Росприроднадзор",
        ],
        correctAnswers: ["В территориальный орган Ростехнадзора"],
      },
      {
        code: "20503236",
        text: "На каких участках горных выработок системой аэрогазового контроля (АГК) должен осуществляться контроль содержания оксида углерода?",
        answers: [
          "Только в тупиках вентиляционных выработок, погашаемых вслед за очистными забоями",
          "Только в исходящих струях шахт",
          "Только в исходящих струях смесительных камер",
          "Только в воздухопроводах газоотсасывающих установок",
          "На всех перечисленных участках выработок",
        ],
        correctAnswers: ["На всех перечисленных участках выработок"],
      },
      {
        code: "20503237",
        text: "Какое из перечисленных требований к контролю горных выработок, оборудованных ленточными конвейерными установками, указано неверно?",
        answers: [
          "Датчики оксида углерода устанавливаются на расстоянии не более 25 м от приводной, натяжной станций, мест перегрузки угля и изменения угла наклона конвейера в направлении движения вентиляционной струи",
          "В линейной части конвейера датчики оксида углерода размещаются с учетом скорости движения воздуха так, чтобы время движения воздуха между датчиками не превышало 10 минут",
          "Если в выработке с общей исходящей струей необходимо установить несколько датчиков, удаленных друг от друга на расстояние не более 100 м, то рекомендуется объединить точки контроля и использовать единственный датчик, который устанавливается в последней из объединяемых точек по ходу движения вентиляционной струи",
          "Датчики монтируются в нижней части выработки",
        ],
        correctAnswers: ["Датчики монтируются в нижней части выработки"],
      },
      {
        code: "20503238",
        text: "Кому сообщают работники шахты, находящиеся в горных выработках, оборудованных стационарными датчиками оксида углерода, о срабатывании местной сигнализации и об отказах датчиков?",
        answers: [
          "Начальнику шахты",
          "Командиру профессиональной аварийно-спасательной службы",
          "Техническому руководителю организации",
          "Горному диспетчеру или оператору АГК",
        ],
        correctAnswers: ["Горному диспетчеру или оператору АГК"],
      },
      {
        code: "20503239",
        text: "Какой из перечисленных газов является основным индикаторным газом, используемым при обнаружении начальных стадий возникновения пожаров?",
        answers: [
          "Диоксид углерода",
          "Метан",
          "Оксид углерода",
          "Все перечисленные газы",
        ],
        correctAnswers: ["Оксид углерода"],
      },
      {
        code: "20503240",
        text: "Кто имеет неограниченный доступ к просмотру хранящихся данных в устройстве долговременного хранения данных от основных измерительных каналов?",
        answers: [
          "Начальник участка аэрологической безопасности",
          "Работники шахты, назначенные приказом по организации",
          "Главный инженер шахты",
          "Работники территориального органа Ростехнадзора",
          "Все перечисленные",
        ],
        correctAnswers: ["Работники территориального органа Ростехнадзора"],
      },
      {
        code: "20503241",
        text: "Кем осуществляется обслуживание устройства долговременного хранения данных от основных измерительных каналов? Укажите все правильные ответы.",
        answers: [
          "Поставщиком оборудования",
          "Сервисными центрами",
          "Территориальным органом Ростехнадзора",
          "Эксплуатирующей организацией",
          "Проектной организацией",
        ],
        correctAnswers: ["Поставщиком оборудования", "Сервисными центрами"],
      },
      {
        code: "20503242",
        text: "Какое минимальное количество администраторов системы аэрогазового контроля, на которых возлагается персональная ответственность за функционирование наземного компьютерного комплекса, непрерывную работу регистратора, назначается приказом по шахте?",
        answers: ["Один", "Двое", "Трое", "Четверо", "Все ответы неверны"],
        correctAnswers: ["Двое"],
      },
      {
        code: "20503243",
        text: "С какой периодичностью проводится проверка точности показаний датчиков и срабатывания автоматической газовой защиты с помощью контрольных смесей?",
        answers: [
          "Ежедневно",
          "Еженедельно",
          "Ежеквартально",
          "Ежемесячно",
          "Все ответы неверны",
        ],
        correctAnswers: ["Ежемесячно"],
      },
      {
        code: "20503244",
        text: "Кто несет персональную ответственность за работоспособность и правильность настройки датчиков аэрогазового контроля и работоспособность автоматической газовой защиты?",
        answers: [
          "Горный диспетчер",
          "Электрослесарь по обслуживанию",
          "Главный инженер шахты",
          "Механик группы аэрогазового контроля (руководитель группы аэрогазового контроля или механик участка)",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Механик группы аэрогазового контроля (руководитель группы аэрогазового контроля или механик участка)",
        ],
      },
      {
        code: "20503245",
        text: "Кто из перечисленных лиц не входит в состав комиссии по приему системы аэрогазового контроля в эксплуатацию?",
        answers: [
          "Работник территориального органа Ростехнадзора",
          "Главный механик",
          "Администратор системы аэрогазового комплекса",
          "Главный инженер",
          "Все ответы неверны",
        ],
        correctAnswers: ["Администратор системы аэрогазового комплекса"],
      },
      {
        code: "20503246",
        text: "В каком случае вносятся исправления в документах об аэрогазовом режиме и работе системы аэрогазового контроля?",
        answers: [
          "В случае, если внесение исправлений обозначено в проекте аэрогазового контроля",
          "Внесение исправлений не допускается ни в каком случае",
          "Исправления вносятся только в документы, формируемые вручную",
          "Только по письменному указанию начальника (заместителя начальника) участка аэрологической безопасности",
        ],
        correctAnswers: [
          "Только по письменному указанию начальника (заместителя начальника) участка аэрологической безопасности",
        ],
      },
      {
        code: "20503247",
        text: "С какой периодичностью осматриваются устья ликвидированных горных выработок, имеющих выход на земную поверхность, комиссией, назначенной распорядительным документом руководителя шахты?",
        answers: [
          "1 раз в год",
          "Не менее 1 раза в год",
          "Не менее 2 раз в год",
        ],
        correctAnswers: ["Не менее 2 раз в год"],
      },
      {
        code: "20503248",
        text: "При какой максимальной концентрации метана на выходе из смесительной камеры обеспечивается безопасная эксплуатация выемочных участков с использованием схем проветривания с изолированным отводом метана из выработанного пространства?",
        answers: ["5 %", "3,5 %", "2 %", "1,5 %"],
        correctAnswers: ["2 %"],
      },
      {
        code: "20503249",
        text: "При какой максимальной концентрации метана в газодренажных выработках, имеющих сопряжения с действующими горными выработками, и регулируемую подачу свежего воздуха для подсвежения метановоздушной смеси, в газодренажных скважинах, в расположенных на поверхности газоотсасывающих трубопроводах и на поверхностных ГОУ обеспечивается безопасная эксплуатация выемочных участков с использованием схем проветривания с изолированным отводом метана из выработанного пространства?",
        answers: ["1,5 %", "2 %", "3,5 %", "5 %"],
        correctAnswers: ["3,5 %"],
      },
      {
        code: "20503250",
        text: "На каком основании производится выключение поверхностных газоотсасывающих установок для проведения профилактических осмотров и ремонтов?",
        answers: [
          "По письменному распоряжению руководителя организации",
          "По письменному распоряжению начальника участка аэрологической безопасности",
          "По письменному распоряжению территориального органа Ростехнадзора",
          "По письменному распоряжению главного инженера шахты с уведомлением не позднее чем за сутки, начальника участка аэрологической безопасности",
        ],
        correctAnswers: [
          "По письменному распоряжению главного инженера шахты с уведомлением не позднее чем за сутки, начальника участка аэрологической безопасности",
        ],
      },
      {
        code: "20503251",
        text: "Кем утверждается акт обследования газодренажных выработок угольных шахт?",
        answers: [
          "Лицом, проводившим обследование газодренажных выработок",
          "Начальником участка аэрологической безопасности",
          "Руководителем организации",
          "Главным инженером шахты",
        ],
        correctAnswers: ["Главным инженером шахты"],
      },
      {
        code: "20503252",
        text: "Какое из перечисленных требований к оборудованию, эксплуатации и контролю работы поверхностных газоотсасывающих установок указано верно?",
        answers: [
          "Для объектов поверхностной газоотсасывающей установки предусматривается I категория устройства молниезащиты",
          "Гибкие соединения, уплотнения, виброгасящие прокладки в газоотводящем тракте должны быть шунтированы не менее чем двумя гибкими проводниками сечением не менее 15 мм²",
          "Техническое обслуживание газоотсасывающих установок проводится в соответствии с инструкцией по эксплуатации газоотсасывающих установок, утвержденной начальником участка аэрологической безопасности",
          "Выхлопной патрубок газоотсасывающей установки должен располагаться вертикально и иметь высоту не менее 3 м от верхней кромки корпуса газоотсасывающей установки",
        ],
        correctAnswers: [
          "Выхлопной патрубок газоотсасывающей установки должен располагаться вертикально и иметь высоту не менее 3 м от верхней кромки корпуса газоотсасывающей установки",
        ],
      },
      {
        code: "20503253",
        text: "По какой категории электроснабжения должно осуществляться электроснабжение рабочего и резервного поверхностных газоотсасывающих агрегатов угольных шахт?",
        answers: [
          "По третьей категории электроснабжения",
          "По второй категории электроснабжения",
          "По первой категории электроснабжения",
        ],
        correctAnswers: ["По первой категории электроснабжения"],
      },
      {
        code: "20503254",
        text: "На какое расстояние поверхностные газоотсасывающие установки удаляются от негорящих отвалов?",
        answers: [
          "За пределы механической защитной зоны",
          "На 300 м",
          "На 100 м",
          "На 30 м",
          "Все ответы неверны",
        ],
        correctAnswers: ["За пределы механической защитной зоны"],
      },
      {
        code: "20503255",
        text: "С какой периодичностью ИТР участка аэрологической безопасности должен осуществлять контроль концентрации метана и режимов работы рабочего и резервного агрегатов?",
        answers: [
          "Не реже 1 раза в квартал",
          "Не реже 1 раза в месяц",
          "Не реже 2 раз в месяц",
          "Не реже 3 раз в месяц",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не реже 3 раз в месяц"],
      },
      {
        code: "20503256",
        text: "Какое требование должно соответствовать оборудованной камере (сбойке или нише), в которую монтируется подземная газоотсасывающая установка?",
        answers: [
          "Крепление камеры должно быть выполнено из негорючего материала",
          "Подход к установке должен быть обеспечен с обеих сторон",
          "Камера должна проветриваться свежей струей воздуха",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20503257",
        text: "Под чьим руководством должны проводиться профилактические осмотры и ремонты подземных газоотсасывающих установок в выходные дни или нерабочие смены?",
        answers: [
          "Под руководством специалиста участка аэрологической безопасности",
          "Под руководством горного диспетчера",
          "Под руководством главного инженера шахты",
          "Под руководством механика участка",
        ],
        correctAnswers: ["Под руководством механика участка"],
      },
      {
        code: "20503258",
        text: "Кто должен осуществлять контроль концентрации метана и оксида углерода на выходе из смесительной камеры не реже одного раза в смену?",
        answers: [
          "Дежурный машинист",
          "Механик участка",
          "ИТР участка аэрологической безопасности (АБ)",
          "ИТР добычного участка",
        ],
        correctAnswers: ["ИТР добычного участка"],
      },
      {
        code: "20503259",
        text: "Что должна обеспечивать аппаратура автоматического контроля состава и расхода воздуха в горных выработках (АГК)?",
        answers: [
          "Только непрерывное измерение состава и расхода воздуха в действующих горных выработках",
          "Только телепередачу информации на диспетчерский пункт шахты и ее регистрацию",
          "Только дистанционную сигнализацию о превышении допустимых норм содержания метана и выдачу команд на автоматическое отключение электрооборудования",
          "Только КВШ выемочного участка",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20503260",
        text: "В каком месте устанавливаются датчики стационарной аппаратуры аэрогазового контроля оксида углерода? Укажите все правильные ответы.",
        answers: [
          "В выработке с исходящей из очистного забоя струей воздуха, в верхней ее части, на расстоянии 10 - 20 м от забоя",
          "В газоотсасывающих трубопроводах подземных и поверхностных газоотсасывающих установок (ГОУ)",
          "В обособленно проветриваемых выработках выемочного участка на расстоянии 10 - 20 м от сопряжения с выработкой, по которой исходящая струя воздуха выдается за пределы выемочного участка",
          "В камерах газоотсасывающих установок над газоотсасывающими агрегатами",
          "В выработке выемочного участка с исходящей струей воздуха на расстоянии 10 - 20 м от сопряжения с выработкой, по которой исходящая струя выдается за пределы выемочного участка",
        ],
        correctAnswers: [
          "В выработке с исходящей из очистного забоя струей воздуха, в верхней ее части, на расстоянии 10 - 20 м от забоя",
          "В газоотсасывающих трубопроводах подземных и поверхностных газоотсасывающих установок (ГОУ)",
        ],
      },
      {
        code: "20503261",
        text: "В каком случае изменяется режим работы газоотсасывающих установок?",
        answers: [
          "Режим работы газоотсасывающих установок не изменяется",
          "В соответствии с инструкцией по эксплуатации применяемых газоотсасывающих установок, утвержденной техническим руководителем (главным инженером) шахты",
          "При возникновении аварийной ситуации в выработках выемочного участка",
          "При принятии такого решения ответственным руководителем ликвидации аварии",
        ],
        correctAnswers: [
          "При принятии такого решения ответственным руководителем ликвидации аварии",
        ],
      },
      {
        code: "20503262",
        text: "При какой продолжительности остановки вентиляторов главного проветривания (ВГП) или вспомогательной вентиляторной установки (ВВУ) при нарушении проветривания горных выработок люди из них выходят в соответствии с ПЛА в горные выработки со свежей струей воздуха?",
        answers: ["Более 30 минут", "20 минут", "15 минут", "10 минут"],
        correctAnswers: ["Более 30 минут"],
      },
      {
        code: "20503263",
        text: "При каком условии разрешается возобновление электроснабжения шахты после остановки вентиляторов главного проветривания (ВГП) или вспомогательной вентиляторной установки (ВВУ)?",
        answers: [
          "Только после восстановления проветривания горных выработок",
          "Только после замеров содержания метана ИТР структурных подразделений в местах производства работ, у электрических машин, аппаратов и на расстоянии не менее 20 м от мест их установки во всех прилегающих горных выработках",
          "При выполнении всех перечисленных условий",
        ],
        correctAnswers: ["При выполнении всех перечисленных условий"],
      },
      {
        code: "20503264",
        text: "Какие шахты относятся к опасным по нефтегазопроявлениям?",
        answers: [
          "Только шахты, в которых выделяются жидкие углеводороды",
          "Только шахты, в которых выделяются парообразные углеводороды",
          "Только шахты, в которых выделяются газообразные углеводороды (кроме метана), если их содержание превышает 10 % общего объема горючих газов",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20503265",
        text: "Какие пласты относятся к пластам, опасным по взрывам угольной пыли?",
        answers: [
          "Пласты с выходом летучих веществ угля 15% и более, а также пласты угля с меньшим выходом летучих веществ, взрывчатость пыли которых установлена при проведении лабораторных исследований и испытаний угольной пыли на взрывчатость",
          "Пласты с выходом летучих веществ угля 12 % и более, а также пласты угля с большим выходом летучих веществ, взрывчатость пыли которых установлена при проведении лабораторных исследований и испытаний угольной пыли на взрывчатость",
          "Пласты с выходом летучих веществ угля 10 % и более, а также пласты угля с большим выходом летучих веществ, взрывчатость пыли которых установлена при проведении лабораторных исследований и испытаний угольной пыли на взрывчатость",
          "Пласты с выходом летучих веществ угля 8 % и более, а также пласты угля с меньшим выходом летучих веществ, взрывчатость пыли которых установлена при проведении лабораторных исследований и испытаний угольной пыли на взрывчатость",
        ],
        correctAnswers: [
          "Пласты с выходом летучих веществ угля 15% и более, а также пласты угля с меньшим выходом летучих веществ, взрывчатость пыли которых установлена при проведении лабораторных исследований и испытаний угольной пыли на взрывчатость",
        ],
      },
      {
        code: "20503266",
        text: "Какой вид пылевзрывозащиты применяется в шахтах для локализации и предупреждения взрывов угольной пыли?",
        answers: [
          "Только гидропылевзрывозащита",
          "Только комбинированная пылевзрывозащита",
          "Только сланцевая пылевзрывозащита",
          "Возможно применение всех перечисленных видов пылевзрывозащиты",
        ],
        correctAnswers: [
          "Возможно применение всех перечисленных видов пылевзрывозащиты",
        ],
      },
      {
        code: "20503267",
        text: "Кем устанавливается порядок контроля средств взрывозащиты горных выработок и выполнение мероприятий по предупреждению взрывов угольной пыли?",
        answers: [
          "Главным инженером шахты",
          "Руководителем горнодобывающей организации",
          "Начальником проходческого (добычного) участка",
          "Специалистом по охране труда и промышленной безопасности",
        ],
        correctAnswers: ["Главным инженером шахты"],
      },
      {
        code: "20503268",
        text: "Кто при выявлении фактов нахождения горных выработок шахты в пылевзрывоопасном состоянии до возобновления горных работ в этих горных выработках принимает меры, обеспечивающие приведение их в пылевзрывобезопасное состояние?",
        answers: [
          "Начальник проходческого (добычного) участка",
          "Главный инженер шахты",
          "Главный механик шахты",
          "Руководитель горнодобывающей организации",
        ],
        correctAnswers: ["Главный инженер шахты"],
      },
      {
        code: "20503269",
        text: "С какой периодичностью следует контролировать по результатам лабораторного анализа проб отложившейся в горных выработках угольной пыли в местах интенсивного пылеотложения?",
        answers: [
          "Не реже 1 раза в неделю",
          "Не реже 1 раза в 3 месяца",
          "Не реже 2 раз в месяц",
          "Не реже 1 раза в месяц",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20503270",
        text: "Какие из перечисленных участков являются местами интенсивного пылеотложения при разработке пластов, опасных по взрывам угольной пыли?",
        answers: [
          "Подготовительные выработки, проводимые по углю и породе, на протяжении 50 м от их забоев",
          "В районе погрузочных пунктов и на протяжении 25 м от них по направлению вентиляционной струи",
          "Участки откаточных выработок на протяжении 25 м в обе стороны от опрокидывателей",
          "Все перечисленные",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20503271",
        text: "Кто определяет порядок измерения расхода воздуха в горных выработках шахты с указанием мест замеров и учет результатов этих замеров?",
        answers: [
          "Начальник проходческого (добычного) участка",
          "Руководитель горнодобывающей организации",
          "Главный механик шахты",
          "Главный инженер шахты",
        ],
        correctAnswers: ["Главный инженер шахты"],
      },
      {
        code: "20503272",
        text: "Кем утверждается схема установки в горных выработках средств локализации и предупреждения взрывов пылегазовоздушных смесей?",
        answers: [
          "С руководителем аварийно-спасательной службы (формирования)",
          "Руководителем (директором) шахты",
          "Начальником участка аэрологической безопасности",
          "Главным инженером шахты",
        ],
        correctAnswers: ["Главным инженером шахты"],
      },
      {
        code: "20503273",
        text: "В выработках какого сечения устанавливаются фиксированные полки сланцевого заслона?",
        answers: [
          "В выработках площадью сечения более 15 м2",
          "В выработках площадью сечения до 15 м2",
          "В выработках площадью сечения до 10 м2",
          "В выработках площадью сечения более 10 м2",
        ],
        correctAnswers: ["В выработках площадью сечения до 10 м2"],
      },
      {
        code: "20503274",
        text: "На каком расстоянии от вентиляционного става должны устанавливаться полки сланцевого заслона?",
        answers: [
          "Не более чем на 100 мм",
          "Не менее чем на 150 мм",
          "Не более чем на 200 мм",
          "Не менее чем на 230 мм",
        ],
        correctAnswers: ["Не более чем на 100 мм"],
      },
      {
        code: "20503275",
        text: "В каких наклонных подземных горных выработках устанавливаются сланцевые заслоны?",
        answers: [
          "Не регламентируется",
          "С углом наклона до 25°",
          "С углом наклона до 20°",
          "С углом наклона до 18°",
        ],
        correctAnswers: ["С углом наклона до 18°"],
      },
      {
        code: "20503276",
        text: "На каком расстоянии друг от друга устанавливаются полки с сосудами и несущие конструкции водяных карманов?",
        answers: [
          "Не менее 500 мм друг от друга",
          "Не более 400 мм друг от друга",
          "Не менее 350 мм друг от друга",
          "Не более 300 мм друг от друга",
        ],
        correctAnswers: ["Не менее 500 мм друг от друга"],
      },
      {
        code: "20503277",
        text: "Какие взрыволокализующие заслоны устанавливаются в выработках, оборудованных ленточными конвейерами, по которым транспортируется только порода?",
        answers: [
          "В выработках, оборудованных ленточными конвейерами, по которым транспортируется только порода, заслоны не устанавливаются",
          "Сланцевые или водяные заслоны",
          "Сланцевые заслоны",
          "Водяные заслоны",
        ],
        correctAnswers: [
          "В выработках, оборудованных ленточными конвейерами, по которым транспортируется только порода, заслоны не устанавливаются",
        ],
      },
      {
        code: "20503278",
        text: "С какой периодичностью должно контролироваться качество инертной пыли, применяемой в сланцевом заслоне?",
        answers: [
          "1 раз в квартал",
          "1 раз в 6 месяцев",
          "1 раз в год",
          "1 раз в 1,5 года",
        ],
        correctAnswers: ["1 раз в квартал"],
      },
      {
        code: "20503279",
        text: "С какой периодичностью проводится контроль состояния заслонов ИТР технологического участка?",
        answers: [
          "Раз в двое суток",
          "Не реже 3 раз за смену",
          "Еженедельно",
          "Ежесменно",
          "Все ответы неверны",
        ],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20503280",
        text: "Кем определяются конкретные места в горных выработках, в которых необходимо провести проверку состава рудничной атмосферы?",
        answers: [
          "Главным инженером шахты",
          "Работником военизированных горноспасательных частей, обслуживающих шахту",
          "Работником газоаналитической лаборатории",
          "ИТР участка аэрологической безопасности (АБ)",
        ],
        correctAnswers: ["ИТР участка аэрологической безопасности (АБ)"],
      },
      {
        code: "20503281",
        text: "С какой периодичностью работниками подразделений ПАСС(Ф)), обслуживающих шахту, или работниками испытательных лабораторий в присутствии ИТР участка аэрологической безопасности выполняется проверка состава рудничной атмосферы?",
        answers: [
          "1раз в неделю",
          "1 раз в квартал",
          "1 раз в месяц",
          "Ежесменно",
        ],
        correctAnswers: ["1 раз в квартал"],
      },
      {
        code: "20503282",
        text: "При какой минимальной температуре рудничной атмосферы в горных выработках проводятся замеры его относительной влажности?",
        answers: ["10 °C", "15 °C", "20 °C", "30 °C"],
        correctAnswers: ["20 °C"],
      },
      {
        code: "20503283",
        text: "С какой периодичностью проводится проверка состава рудничной атмосферы и замер ее расхода на шахтах, разрабатывающих пласты угля, склонного к самовозгоранию?",
        answers: [
          "1 раз в месяц",
          "2 раза в месяц",
          "3 раза в месяц",
          "1 раз в 3 месяца",
        ],
        correctAnswers: ["3 раза в месяц"],
      },
      {
        code: "20503284",
        text: "С какой периодичностью проводятся проверка состава рудничной атмосферы и замер ее расхода на газовых шахтах?",
        answers: [
          "1 раз в месяц",
          "2 раза в месяц",
          "3 раза в месяц",
          "1 раз в 3 месяца",
        ],
        correctAnswers: ["3 раза в месяц"],
      },
      {
        code: "20503285",
        text: "С какой периодичностью измеряется производительность вентиляторов местного проветривания?",
        answers: [
          "1 раз в месяц",
          "1 раз в 2 месяца",
          "1 раз в 3 месяца",
          "2 раза в год",
        ],
        correctAnswers: ["1 раз в месяц"],
      },
      {
        code: "20503286",
        text: "В каком из приведенных случаев проверка состава рудничной атмосферы должна проводиться 3 раза в месяц?",
        answers: [
          "После взрывных работ в стволах независимо от их глубины",
          "После взрывных работ в подготовительных выработках при их длине 300 м и более",
          "При проходке стволов, переведенных на газовый режим",
          "При изменении паспорта буровзрывных работ",
        ],
        correctAnswers: ["При проходке стволов, переведенных на газовый режим"],
      },
      {
        code: "20503287",
        text: "Через какое время после проведения взрывных работ отбираются первые пробы рудничной атмосферы при сотрясательном взрывании?",
        answers: [
          "Не ранее чем через 5 минут",
          "Не ранее чем через 15 минут",
          "Не ранее чем через 30 минут",
          "Не ранее чем через 45 минут",
        ],
        correctAnswers: ["Не ранее чем через 30 минут"],
      },
      {
        code: "20503288",
        text: "Каким образом определяется порядок контроля концентрации газов рудничной атмосферы индивидуальными приборами в горных выработках шахт?",
        answers: [
          "На основании распоряжения лабораторий, выполняющих отбор проб и проверку состава рудничной атмосферы",
          "На основании письменного распоряжения начальника аэрологической безопасности",
          "На основании проекта дегазации шахты",
          "Главным инженером шахты и Инструкцией по аэрологической безопасности угольных шахт",
        ],
        correctAnswers: [
          "Главным инженером шахты и Инструкцией по аэрологической безопасности угольных шахт",
        ],
      },
      {
        code: "20503289",
        text: "Куда заносятся результаты проверки состава рудничной атмосферы и расход воздуха?",
        answers: [
          "В план проверки состава рудничной атмосферы",
          "В журнал учета работы дегазационных скважин",
          "В книгу наблюдений за пожарными участками и проверки состояния изоляционных сооружений",
          "В вентиляционный журнал",
        ],
        correctAnswers: ["В вентиляционный журнал"],
      },
      {
        code: "20503290",
        text: "Кто составляет вентиляционный план угольной шахты?",
        answers: [
          "Главный инженер шахты",
          "Начальник участка аэрологической безопасности (АБ)",
          "Начальник проходческого (добычного) участка",
        ],
        correctAnswers: ["Начальник участка аэрологической безопасности (АБ)"],
      },
      {
        code: "20503291",
        text: "С какой периодичностью должна составляться пояснительная записка вентиляционного плана шахты?",
        answers: [
          "Ежемесячно",
          "Ежеквартально",
          "1 раз в полугодие",
          "Ежегодно при подготовке шахтой производственной программы развития горных работ",
        ],
        correctAnswers: [
          "Ежегодно при подготовке шахтой производственной программы развития горных работ",
        ],
      },
      {
        code: "20503292",
        text: "Какой цвет стрелки принят для обозначения направления движения свежей вентиляционной струи по горным выработкам на схеме вентиляции шахты?",
        answers: ["Красный", "Синий", "Зеленый", "Черный"],
        correctAnswers: ["Красный"],
      },
      {
        code: "20503293",
        text: "Какие сведения указываются на схеме вентиляции шахт?",
        answers: [
          "Расчетный и фактический расход воздуха, поступающего в шахту, на крылья, панели, блоки и горизонты",
          "Расчетный и фактический расход воздуха, поступающего на выемочные участки и в очистные забои",
          "Расчетные и фактические внешние утечки воздуха",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20503294",
        text: "В каких случаях проводится плановая практическая проверка аварийных вентиляционных режимов?",
        answers: [
          "Только при разработке планов ликвидации аварий (ПЛА)",
          "Только при изменениях схем проветривания шахты, крыла, горизонта",
          "Только при замене вентиляторов главного проветривания (ВГП)",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20503295",
        text: "С какой периодичностью проводится плановая практическая проверка аварийных вентиляционных режимов, предусмотренных планом ликвидации аварий?",
        answers: [
          "1 раз в год",
          "1 раз в 3 года",
          "2 раза в год в весенний и осенний периоды",
          "2 раза в год в летний и зимний периоды",
          "Все ответы неверны",
        ],
        correctAnswers: ["2 раза в год в летний и зимний периоды"],
      },
      {
        code: "20503296",
        text: "Кто осуществляет контроль состояния реверсивных, переключающих и герметизирующих устройств вентиляторов главного проветривания при проведении плановой практической проверки аварийных вентиляционных режимов, предусмотренных планом ликвидации аварий?",
        answers: [
          "Начальник службы аэрологической безопасности",
          "Главный инженер",
          "Специалист аварийно-спасательного формирования",
          "Главный механик шахты",
        ],
        correctAnswers: ["Главный механик шахты"],
      },
      {
        code: "20503297",
        text: "Кем утверждается план проведения плановой практической проверки аварийных вентиляционных режимов, предусмотренных планом ликвидации аварий?",
        answers: [
          "Главным механиком",
          "Инспектором территориального органа Ростехнадзора",
          "Командиром подразделения аварийно-спасательного формирования, обслуживающего шахту",
          "Главным инженером шахты",
        ],
        correctAnswers: ["Главным инженером шахты"],
      },
      {
        code: "20503298",
        text: "При какой концентрации метана в процессе проведения плановой практической проверки аварийных вентиляционных режимов одновременно с замерами должен проводиться отбор проб шахтного воздуха для проверки его состава?",
        answers: [
          "При концентрации метана 0,8 %",
          "При концентрации метана 1,2 %",
          "При концентрации метана 1,3 %",
          "При концентрации метана 1,7 %",
        ],
        correctAnswers: ["При концентрации метана 1,7 %"],
      },
      {
        code: "20503299",
        text: "Кто осуществляет контроль и надзор за соблюдением требований промышленной безопасности при проведении плановой практической проверки аварийных вентиляционных режимов, предусмотренных планом ликвидации аварий?",
        answers: [
          "Командир подразделения аварийно-спасательного формирования, обслуживающего шахту",
          "Главный инженер шахты",
          "Представители территориального органа Ростехнадзора",
          "Представители службы производственного контроля эксплуатирующей организации",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Представители службы производственного контроля эксплуатирующей организации",
        ],
      },
      {
        code: "20503300",
        text: "В течение какого времени схемы проветривания шахты в реверсивных и аварийных режимах хранятся на участке аэрологической безопасности?",
        answers: [
          "В течение 6 месяцев",
          "В течение 5 лет",
          "В течение 2 лет",
          "В течение года",
          "Все ответы неверны",
        ],
        correctAnswers: ["В течение года"],
      },
      {
        code: "20503301",
        text: "Кто утверждает акт плановой практической проверки аварийных вентиляционных режимов, предусмотренных планом ликвидации аварий (ПЛА)?",
        answers: [
          "Главный инженер шахты",
          "Начальник проходческого (добычного) участка",
          "Главный механик шахты",
          "Начальник участка аэрологической безопасности",
        ],
        correctAnswers: ["Главный инженер шахты"],
      },
      {
        code: "20503302",
        text: "Что из перечисленного не должна содержать документация по борьбе с пылью и пылевзрывозащите?",
        answers: [
          "Перечень и расчет параметров мероприятий по борьбе с пылью при всех процессах, сопровождающихся пылевыделением",
          "Обоснование выбора противопылевых мероприятий для подготовительных горных выработок и выемочных участков",
          "Определение возможности и эффективности проведения работ по увлажнению угольного пласта",
          "Параметры и режим работы технических устройств, используемых для борьбы с пылью и пылевзрывозащиты",
        ],
        correctAnswers: [
          "Параметры и режим работы технических устройств, используемых для борьбы с пылью и пылевзрывозащиты",
        ],
      },
      {
        code: "20503303",
        text: "С какой периодичностью технический руководитель (главный инженер) угледобывающей организации утверждает документацию по борьбе с пылью и пылевзрывозащите, содержащую расчет необходимого оборудования и материалов?",
        answers: [
          "Раз в три года",
          "Раз в пять лет",
          "Два раза в год",
          "Ежегодно",
          "Все ответы неверны",
        ],
        correctAnswers: ["Ежегодно"],
      },
      {
        code: "20503304",
        text: "Кто организаует выполнение мер по борьбе с пылью и пылевзрывозащите?",
        answers: [
          "Начальник участка аэрологической безопасности",
          "Руководитель угледобывающей организации",
          "Сторонняя организация",
          "Главный инженер шахты",
          "Все ответы неверны",
        ],
        correctAnswers: ["Главный инженер шахты"],
      },
      {
        code: "20503305",
        text: "В течение какого времени после после начала проведения горной выработки определяется технически достижимый уровень запыленности рудничной атмосферы для подготовительных выработок?",
        answers: [
          "В течение 35 рабочих дней",
          "В течение 20 рабочих дней",
          "В течение 15 рабочих дней",
          "В течение 10 рабочих дней",
          "Все ответы неверны",
        ],
        correctAnswers: ["В течение 10 рабочих дней"],
      },
      {
        code: "20503306",
        text: "Каким должно быть давление воды, подаваемой на взрывозащитное орошение?",
        answers: [
          "Не менее 1,5 МПа",
          "Не менее 1,2 МПа",
          "Не менее 1 МПа",
          "1 - 1,5 МПа",
        ],
        correctAnswers: ["Не менее 1,5 МПа"],
      },
      {
        code: "20503307",
        text: "При каком минимально допустимом значении пористости угля производится увлажнение угольного пласта?",
        answers: ["2 %", "5 %", "8 %", "Не нормируется"],
        correctAnswers: ["5 %"],
      },
      {
        code: "20503308",
        text: "Какой из перечисленных способов борьбы с пылью применяется в очистных забоях на пологих угольных пластах?",
        answers: [
          "Только увлажнение угольного пласта",
          "Только взрывозащитное орошение",
          "Только орошение в зоне разрушения и выгрузки угля или подача пены",
          "Только орошение при передвижке секций механизированных крепей",
          "Только установка завес в горных выработках",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20503309",
        text: "На каком расстоянии от лавы в горных выработках с исходящей струей воздуха устанавливаются обеспыливающие завесы?",
        answers: [
          "Не более 50 м",
          "Не более 60 м",
          "Не более 70 м",
          "Не более 80 м",
        ],
        correctAnswers: ["Не более 50 м"],
      },
      {
        code: "20503310",
        text: "Каково минимально допустимое давление жидкости в трубопроводе у водяной обеспыливающей завесы?",
        answers: ["0,3 МПа", "0,5 МПа", "1 МПа", "Не нормируется"],
        correctAnswers: ["0,5 МПа"],
      },
      {
        code: "20503311",
        text: "Какое минимальное давление жидкости в трубопроводе должно быть для туманообразующей завесы?",
        answers: ["0,5 МПа", "1 МПа", "1,5 МПа", "2 МПа"],
        correctAnswers: ["1 МПа"],
      },
      {
        code: "20503312",
        text: "Каково минимально допустимое давление жидкости в трубопроводе у лабиринтной завесы?",
        answers: ["0,5 МПа", "0,75 МПа", "1 МПа", "1,5 МПа"],
        correctAnswers: ["1 МПа"],
      },
      {
        code: "20503313",
        text: "Каково минимально допустимое содержании пыли в рудничном воздухе в горных выработках с исходящей струей воздуха после обеспыливающей завесы?",
        answers: ["150 мг/м3", "120 мг/м3", "100 мг/м3", "50 мг/м3"],
        correctAnswers: ["150 мг/м3"],
      },
      {
        code: "20503314",
        text: "Какое из перечисленных требований, относящихся к борьбе с пылью в подготовительных выработках, указано верно?",
        answers: [
          "На шахтах, опасных по газу и разрабатывающих пласты, опасные по взрывам пыли, при взрывных работах в подготовительных горных выработках за 20 - 30 минут перед взрыванием зарядов взрывчатых веществ проводится орошение (обмывка) забоя и выработки на расстоянии не менее 20 м от взрываемых зарядов",
          "Обеспыливающие завесы могут не перекрывать все сечение выработки",
          "Удельный расход воды или раствора смачивателя должен составлять 3 л/м² поверхности выработки",
          "Во время взрывных работ применяются водяные (водовоздушные) завесы, устанавливаемые в 10 м от забоя",
        ],
        correctAnswers: [
          "На шахтах, опасных по газу и разрабатывающих пласты, опасные по взрывам пыли, при взрывных работах в подготовительных горных выработках за 20 - 30 минут перед взрыванием зарядов взрывчатых веществ проводится орошение (обмывка) забоя и выработки на расстоянии не менее 20 м от взрываемых зарядов",
        ],
      },
      {
        code: "20503315",
        text: "При каком содержании пыли в рудничном воздухе в подготовительной горной выработке после применения обеспылевающей завесы запрещается ведение горных работ по проведению выработки?",
        answers: [
          "Не регламентируется",
          "Более 50 мг/м³",
          "Более 100 мг/м³",
          "Более 150 мг/м³",
          "Все ответы неверны",
        ],
        correctAnswers: ["Более 150 мг/м³"],
      },
      {
        code: "20503316",
        text: "Какой из перечисленных способов борьбы с пылью не применяется для предотвращения распространения пыли у опрокидывателей и стационарных погрузочных пунктов?",
        answers: [
          "Аспирационные укрытия технологического оборудования",
          "Орошение мест перегрузки горной массы",
          "Исключение свободного падения горной массы с большой высоты",
          "Системы очистки запыленной рудничной атмосферы",
        ],
        correctAnswers: [
          "Исключение свободного падения горной массы с большой высоты",
        ],
      },
      {
        code: "20503317",
        text: "Каким должно быть максимальное расстояние между туманообразующими завесами в горной выработке с исходящей вентиляционной струей при скорости воздуха менее 1 м/с?",
        answers: ["150 м", "120 м", "100 м", "80 м"],
        correctAnswers: ["80 м"],
      },
      {
        code: "20503318",
        text: "Как должны быть направлены факелы туманообразователей в первой туманообразующей завесе в горной выработке?",
        answers: [
          "При скорости воздуха более 2 м/с - по направлению движения вентиляционной струи, при скорости воздуха 2 м/с и менее - навстречу движению вентиляционной струи",
          "При скорости воздуха более 2 м/с - навстречу движению вентиляционной струи, при скорости воздуха 2 м/с и менее - по направлению движения вентиляционной струи",
          "При любой скорости воздуха - по направлению движения вентиляционной струи",
          "При любой скорости воздуха - навстречу движению вентиляционной струи",
        ],
        correctAnswers: [
          "При скорости воздуха более 2 м/с - навстречу движению вентиляционной струи, при скорости воздуха 2 м/с и менее - по направлению движения вентиляционной струи",
        ],
      },
      {
        code: "20503319",
        text: "Какое из перечисленных требований к установке обеспыливающих лабиринтных завес указано верно?",
        answers: [
          "В одной лабиринтной завесе устанавливаются не менее шести перегородок",
          "Перегородки устанавливаются на расстоянии не менее 1 м друг от друга",
          "Факелы форсунок должны обеспечивать орошение всей площади перегородки",
          "Перегородки устанавливаются в произвольном порядке",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Факелы форсунок должны обеспечивать орошение всей площади перегородки",
        ],
      },
      {
        code: "20503320",
        text: "С какой периодичностью проводится контроль выполнения работ по борьбе с пылью и пылевзрывозащите ИТР структурных подразделений?",
        answers: [
          "Дважды в месяц",
          "Еженедельно",
          "Дважды в неделю",
          "Ежесменно",
          "Все ответы неверны",
        ],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20503321",
        text: "Какое максимально возможное время срабатывания автоматической газовой защиты по метану для стационарных метанометров?",
        answers: ["20 с", "25 с", "30 с", "15 с", "Все ответы неверны"],
        correctAnswers: ["15 с"],
      },
      {
        code: "20503322",
        text: "Кто устанавливает проверку состава рудничной атмосферы во время аварии?",
        answers: [
          "Начальник газоаналитической лаборатории",
          "Главный инженер шахты",
          "Начальник участка аэрологической безопасности",
          "Руководитель работ по локализации и ликвидации последствий аварии",
        ],
        correctAnswers: [
          "Руководитель работ по локализации и ликвидации последствий аварии",
        ],
      },
      {
        code: "20503323",
        text: "Кто определяет порядок проверки состава рудничной атмосферы?",
        answers: [
          "Специально сформированная комиссия шахты",
          "Руководитель военизированной горноспасательной части, обслуживающей шахту",
          "Начальник участка аэрологической безопасности",
          "Главный инженер шахты",
          "Начальник газоаналитической лаборатории",
        ],
        correctAnswers: ["Главный инженер шахты"],
      },
      {
        code: "20503324",
        text: "При каком содержании взрывоопасных газов (метана) в рудничной атмосфере действующих горных выработок шахты не обязательно проведение дегазации при работающей вентиляции?",
        answers: ["До 5 %", "До 3 %", "До 2 %", "До 1 %", "Все ответы неверны"],
        correctAnswers: ["До 1 %"],
      },
      {
        code: "20503325",
        text: "Кем разрабатывается проектная документация, содержащая изменения технических решений по вентиляции на период строительства новой, реконструкции или подготовки горизонтов действующей шахты?",
        answers: [
          "Организацией, осуществляющей экспертизу промышленной безопасности объекта",
          "Эксплуатирующей организацией по согласованию с Ростехнадзором",
          "Эксплуатирующей организацией по согласованию с проектной организацией",
          "Проектной организацией",
          "Все ответы неверны",
        ],
        correctAnswers: ["Проектной организацией"],
      },
      {
        code: "20503326",
        text: "Кем выполняется перерасчет параметров проветривания выемочного участка угольных шахт?",
        answers: [
          "Главным механиком шахты",
          "Главным инженером шахты",
          "Лицом, ответственным за проведение работ на данном участке",
          "Начальником участка аэрологической безопасности",
        ],
        correctAnswers: ["Начальником участка аэрологической безопасности"],
      },
      {
        code: "20503327",
        text: 'При каком из перечисленных условий должен предусматриваться перерасчет количества воздуха по данным фактической метанообильности выемочного участка (очистной забой или выработанное пространство) при отработке выемочного участка в разделе "Проветривание выемочного участка" документации по ведению работ на выемочном участке?',
        answers: [
          "При превышении фактической метанообильности выемочного участка над проектной более чем на 3 % в течение суток",
          "При двукратном в течение смены превышении концентрации метана относительно установленных норм",
          "Еженедельно",
          "После первичной посадки основной кровли",
        ],
        correctAnswers: ["После первичной посадки основной кровли"],
      },
      {
        code: "20503328",
        text: "Какая из перечисленных обязанностей дежурного машиниста поверхностной газоотсасывающей установки указана верно?",
        answers: [
          "Осуществлять осмотр вентиляторов (без его остановки) и трубопроводов в случае письменного распоряжения горного диспетчера",
          "Проводить не реже 4 раз в сутки замеры содержания окиси углерода в отсасываемой метановоздушной смеси",
          "Сообщать о возникновении аварийной ситуации и обо всех замеченных недостатках в работе газоотсасывающей установки специалисту аэрологической безопасности",
          "Проводить 1 раз в смену замеры концентрации метана и депрессии рабочего агрегата",
        ],
        correctAnswers: [
          "Проводить не реже 4 раз в сутки замеры содержания окиси углерода в отсасываемой метановоздушной смеси",
        ],
      },
      {
        code: "20503329",
        text: "В каких местах не проводится контроль концентрации метана групповыми переносными приборами при наличии у рабочих, работающих в указанных местах, индивидуальных сигнализаторов метана, совмещенных с головным светильником и индивидуальных приборов контроля концентрации метана? Укажите все правильные ответы.",
        answers: [
          "У буровых станков при бурении скважин",
          "В призабойных пространствах подготовительных выработок",
          "В выработках с исходящей струей воздуха",
          "На электровозах",
        ],
        correctAnswers: [
          "В выработках с исходящей струей воздуха",
          "На электровозах",
        ],
      },
      {
        code: "20503330",
        text: "В каком из перечисленных мест негазовых шахт не осуществляется контроль концентрации метана, оксида углерода, диоксида углерода и кислорода групповыми переносными или индивидуальными приборами контроля?",
        answers: [
          "В призабойных пространствах подготовительных выработок",
          "У выемочных машин в случаях, если выемочные машины не оборудованы встроенными автоматическими приборами контроля концентрации метана",
          "В погашаемых тупиках вентиляционных выработок",
          "У буровых станков при бурении скважин",
        ],
        correctAnswers: ["У буровых станков при бурении скважин"],
      },
      {
        code: "20503331",
        text: "В каком месте горной выработки располагаются групповые приборы для контроля диоксида углерода при проверке состава рудничной атмосферы?",
        answers: [
          "Место расположения определяет главный инженер шахты",
          "В середине выработки",
          "У кровли выработок",
          "В нижней части выработки",
          "Все ответы неверны",
        ],
        correctAnswers: ["В нижней части выработки"],
      },
      {
        code: "20503332",
        text: "Кто на шахте утверждает периодичность проведения контроля содержания метана, оксида углерода, диоксида углерода и кислорода в рудничной атмосфере индивидуальными и групповыми переносными приборами?",
        answers: [
          "Главный технолог шахты",
          "Представитель Ростехнадзора",
          "Руководитель шахты",
          "Главный инженер шахты",
        ],
        correctAnswers: ["Главный инженер шахты"],
      },
      {
        code: "20503333",
        text: "Кем составляется перечень участковых выработок, опасных по слоевым скоплениям метана на газовых шахтах? Укажите все правильные ответы.",
        answers: [
          "Начальником газоаналитической лаборатории",
          "Руководителем военизированных горноспасательных частей, обслуживающих шахту",
          "Начальником участка аэрологической безопасности",
          "Геологом шахты",
          "Главным инженером шахты",
        ],
        correctAnswers: [
          "Начальником участка аэрологической безопасности",
          "Геологом шахты",
        ],
      },
      {
        code: "20503334",
        text: "Как часто выполняются замеры концентрации метана в соответствии с перечнем участков выработок, опасных по слоевым скоплениям метана ИТР технологических участков?",
        answers: [
          "Не реже 1 раза в сутки",
          "Не менее 1 раза в смену",
          "Не менее 2 раз в смену",
          "Не менее 3 раз в смену",
        ],
        correctAnswers: ["Не менее 3 раз в смену"],
      },
      {
        code: "20503335",
        text: "С какой периодичностью определяются абсолютная и относительная газообильности шахты?",
        answers: [
          "Ежесуточно",
          "Еженедельно",
          "Ежемесячно",
          "Ежеквартально",
          "Все ответы неверны",
        ],
        correctAnswers: ["Ежемесячно"],
      },
      {
        code: "20503336",
        text: "На протяжении какого срока хранятся расчеты газообильностей выемочных участков, крыльев, панелей, блоков, горизонтов, шахтопластов и шахты и приказы главного инженера шахты об установлении категорий шахты по метану и (или) диоксиду углерода?",
        answers: [
          "На протяжении 10 лет",
          "На протяжении 25 лет",
          "На протяжении 50 лет",
          "На протяжении всего срока эксплуатации шахты на участке аэрологической безопасности (АБ)",
        ],
        correctAnswers: [
          "На протяжении всего срока эксплуатации шахты на участке аэрологической безопасности (АБ)",
        ],
      },
      {
        code: "20503337",
        text: "В каком случае газовые шахты, разрабатывающие антрациты с объемным выходом летучих веществ менее 110 мл/т сухой беззольной массы и отнесенные к опасным по газу, по решению технического руководителя угледобывающей организации переводятся в категорию негазовых?",
        answers: [
          "Газовые шахты не переводятся в категорию негазовых техническим руководителем угледобывающей организации",
          "При условии письменного разрешения Ростехнадзора",
          "При условии проведения контроля состояния рудничной атмосферы и отсутствия выделения метана",
          "При условии, если в течение 3 лет применяемыми средствами контроля в них не обнаруживалось выделения метана",
        ],
        correctAnswers: [
          "При условии, если в течение 3 лет применяемыми средствами контроля в них не обнаруживалось выделения метана",
        ],
      },
      {
        code: "20503338",
        text: "На основании чего производится временная блокировка автоматического отключения электроснабжения при закорачивании вентиляционных струй воздуха в шлюзах?",
        answers: [
          "На основании эксплуатационной документации",
          "Письменного указания руководителя организации",
          "Письменного указания главного инженера шахты",
          "Письменного указания начальника участка аэрологической безопасности (АБ)",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Письменного указания начальника участка аэрологической безопасности (АБ)",
        ],
      },
      {
        code: "20503339",
        text: "Чем оборудуются газоотсасывающие установки?",
        answers: [
          "Стационарными метанометрами",
          "Датчиками депрессии для контроля работы вентиляторной установки и датчиками оксида углерода (датчиками индикаторных газов) для обнаружения признаков ранних стадий возникновения пожаров",
          "Средствами контроля расхода газовой смеси",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20503340",
        text: "Какое требование, предъявляемое к ДС дегазационных систем, указано верно?",
        answers: [
          "При концентрации метана выше предаварийной уставки - 0,5 % объемной доли, метанометр формирует команду на включение принудительного проветривания, звуковой и световой сигнализации",
          "Ежесуточную проверку метанометров и устройств контроля и управления, установленных в ДС, производит группа аэрогазового контроля",
          "ДС дегазационных систем оборудуются стационарными метанометрами, которые устанавливаются в машинном зале в нижней части помещений",
          "Совместно со стационарными метанометрами используются устройства контроля и управления и сигнализирующие устройства с соответствующим видом и уровнем взрывозащиты",
        ],
        correctAnswers: [
          "Совместно со стационарными метанометрами используются устройства контроля и управления и сигнализирующие устройства с соответствующим видом и уровнем взрывозащиты",
        ],
      },
      {
        code: "20503341",
        text: "С какой периодичностью рассматривается и утверждается раздел проекта системы аэрогазового контроля главным инженером шахты независимо от наличия корректировок?",
        answers: [
          "2 раза в год до 1 марта и 31 декабря следующего за текущим годом",
          "1 раз в год до 1 марта следующего за текущим годом",
          "1 раз в год до 1 января следующего за текущим годом",
          "При отсутствии корректировок раздел проекта аэрогазового контроля не рассматривается",
        ],
        correctAnswers: ["1 раз в год до 1 января следующего за текущим годом"],
      },
      {
        code: "20503342",
        text: "Где находятся журнал эксплуатации системы аэрогазового контроля, машинные (иные) носители с архивной информацией об аэрогазовом режиме?",
        answers: [
          "На рабочем месте дежурных электрослесарей",
          "На рабочем месте руководителя группы аэрогазового комплекса",
          "На рабочем месте начальника участка аэрологической безопасности",
          "На рабочем месте оператора аэрогазового контроля (АГК)",
          "На рабочем месте главного механика",
        ],
        correctAnswers: [
          "На рабочем месте оператора аэрогазового контроля (АГК)",
        ],
      },
      {
        code: "20503343",
        text: "Кто дает разрешение на перевозку людей по горизонтальным и наклонным горным выработкам угольных шахт с записью в путевом листе?",
        answers: [
          "Машинист локомотива",
          "Специалист (механик) структурного подразделения",
          "Главный инженер шахты",
          "Лицо сменного надзора",
          "Все ответы неверны",
        ],
        correctAnswers: ["Лицо сменного надзора"],
      },
      {
        code: "20503344",
        text: "Какова максимально допустимая скорость грузового состава с пассажирской вагонеткой при перевозке в ней людей?",
        answers: ["10 км/ч", "12 км/ч", "15 км/ч", "20 км/ч"],
        correctAnswers: ["12 км/ч"],
      },
      {
        code: "20503345",
        text: "С какой периодичностью проводятся испытания парашютов вагонеток, предназначенных для перевозки людей по наклонным выработкам, в соответствии с документацией организации-изготовителя?",
        answers: [
          "1 раз в 6 месяцев",
          "1 раз в 9 месяцев",
          "1 раз в 10 месяцев",
          "1 раз в 12 месяцев",
        ],
        correctAnswers: ["1 раз в 6 месяцев"],
      },
      {
        code: "20503346",
        text: "С какой периодичностью должна производиться ИТР (механиком) структурного подразделения проверка парашютных устройств включением ручного привода?",
        answers: [
          "Ежесуточно",
          "1 раз в неделю",
          "1 раз в 2 недели",
          "1 раз в месяц",
        ],
        correctAnswers: ["Ежесуточно"],
      },
      {
        code: "20503347",
        text: "Что из перечисленного допускается при перевозке людей по горизонтальным и наклонным горным выработкам угольных шахт?",
        answers: [
          "Перевозка людей в поездах со взрывчатыми, легковоспламеняющимися и едкими материалами",
          "Нахождение людей между вагонетками во время движения состава",
          "Перевозка людей в поездах с инструментами и запасными частями, выступающими за борт вагонеток",
          "Прицепка грузовых вагонеток к составам поездов, перевозящих людей",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20503348",
        text: "В каком случае допускается перевозка персонала по горизонтальным и наклонным горным выработкам на грузовых тележках?",
        answers: [
          "Если машинист находится в кабине управления, расположенной в голове поезда",
          "Если перевозится ремонтный персонал",
          "Если персонал использует необходимые средства индивидуальной защиты",
          "Не допускается ни в каком случае",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20503349",
        text: "Какая должна быть скорость движения составов монорельсовых дизельных дорог при перевозке длинномерных и крупногабаритных грузов по горизонтальным и наклонным горным выработкам угольных шахт?",
        answers: [
          "Не более 1 м/с",
          "Не более 2 м/с",
          "Не более 3 м/с",
          "Не более 4 м/с",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не более 1 м/с"],
      },
      {
        code: "20503350",
        text: "Каково минимально допустимое расстояние между находящимися на смежных платформах длинномерными материалами или оборудованием при доставке их в составах, предназначенных для этих целей вагонеток или платформ, сцепленных между собой жесткими сцепками?",
        answers: ["200 мм", "250 мм", "300 мм", "500 мм"],
        correctAnswers: ["300 мм"],
      },
      {
        code: "20503351",
        text: "Каково минимально допустимое расстояние между вагонетками при ручной подкатке на путях с уклоном до 5 %?",
        answers: ["10 м", "7 м", "6 м", "5 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "20503352",
        text: "В каком случае допускается выполнение грузовых рейсов техническими устройствами по подвесным монорельсовым дорогам в горных выработках с углами наклона свыше 18°?",
        answers: [
          "Не допускается ни в каком случае",
          "При условии оборудования конвейера ловителями ленты",
          "При условии оборудования конвейера устройствами контроля целостности тросов (для резинотросовых лент)",
          "Только при выключенном конвейере",
          "Все ответы неверны",
        ],
        correctAnswers: ["Только при выключенном конвейере"],
      },
      {
        code: "20503353",
        text: "Какое из перечисленных требований к лебедкам, применяемых для транспортирования материалов и оборудования по горизонтальным и наклонным горным выработкам, указано неверно?",
        answers: [
          "Отношение диаметра барабана (шкива) к диаметру каната - не менее 30",
          "Допускается многослойная навивка каната на барабан",
          "Скорость движения каната на среднем радиусе навивки не должна превышать 1,8 м/с",
          "Лебедки оборудованы двумя тормозами, один из которых воздействует на барабан (шкив)",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Отношение диаметра барабана (шкива) к диаметру каната - не менее 30",
        ],
      },
      {
        code: "20503354",
        text: "Каково максимально допустимое число людей, находящихся одновременно на каждом этаже клети из расчета на 1 м2 пола?",
        answers: [
          "3 работника",
          "4 работника",
          "5 работников",
          "Не нормируется",
        ],
        correctAnswers: ["5 работников"],
      },
      {
        code: "20503355",
        text: "Какие устанавливаются нормы замедления при торможении порожних клетей парашютами и клетей с максимальным числом людей?",
        answers: [
          "При торможении порожних клетей парашютами не должно превышать 30 м/c², при торможении клетей с максимальным числом людей - не менее 5 м/c²",
          "При торможении порожних клетей парашютами не должно превышать 70 м/c², при торможении клетей с максимальным числом людей - не менее 8 м/c²",
          "При торможении порожних клетей парашютами не должно превышать 100 м/c², при торможении клетей с максимальным числом людей - не менее 10 м/c²",
          "При торможении порожних клетей парашютами не должно превышать 50 м/c², при торможении клетей с максимальным числом людей - не менее 6 м/c²",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "При торможении порожних клетей парашютами не должно превышать 50 м/c², при торможении клетей с максимальным числом людей - не менее 6 м/c²",
        ],
      },
      {
        code: "20503356",
        text: "В каких местах допускается отсутствие парашютов на клетях для перемещения людей и противовесах людских и грузолюдских подъемных установок?",
        answers: [
          "На клетях и противовесах многоканатных подъемных установок с числом канатов два и более",
          "На противовесах действующих подъемных установок вертикальных стволов, если отделения клети и противовеса не отделены друг от друга перегородкой из рельсов или канатами",
          "На клетях и противовесах аварийно-ремонтных и грузовых подъемных установок",
          "Не допускается ни в каком случае",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "На клетях и противовесах аварийно-ремонтных и грузовых подъемных установок",
        ],
      },
      {
        code: "20503357",
        text: "С какой периодичностью следует проводить испытания парашютов на клетях для перемещения людей и противовесах людских и грузолюдских подъемных установок?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в год",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не реже 1 раза в 6 месяцев"],
      },
      {
        code: "20503358",
        text: "При выполнении какого из перечисленных условий допускается перемещение людей в бадьях без направляющих рамок при производстве аварийных работ?",
        answers: [
          "Направляющую рамку надежно закрепляют на разгрузочной площадке, а разгрузочные ляды закрыты",
          "Скорость движения бадьи по стволу не превышает 0,3 м/с",
          "Зазоры между кромкой бадьи и выступающими конструкциями элементов ствола не менее 400 мм",
          "Всех перечисленных",
          "Все ответы неверны",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20503359",
        text: "Какие радиусы закругления рельсовых путей и переводных кривых во вновь вводимых горных выработках устанавливаются для колеи 600 мм и 900 мм?",
        answers: [
          "Не менее 5 м и не менее 8 м соответственно",
          "Не менее 8 м и не менее 12 м соответственно",
          "Не менее 10 м и не менее 15 м соответственно",
          "Не менее 12 м и не менее 20 м соответственно",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не менее 12 м и не менее 20 м соответственно"],
      },
      {
        code: "20503360",
        text: "Какие радиусы закругления рельсовых путей и переводных кривых в действующих горных выработках устанавливаются для колеи 600 мм и 900 мм?",
        answers: [
          "Не менее 8 м и не менее 12 м соответственно",
          "Не менее 15 м и не менее 23 м соответственно",
          "Не менее 12 м и не менее 20 м соответственно",
          "Не менее 5 м и не менее 8 м соответственно",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не менее 8 м и не менее 12 м соответственно"],
      },
      {
        code: "20503361",
        text: "Каково максимально допустимое расширение рельсовой колеи на прямолинейных и криволинейных участках?",
        answers: [
          "15 мм - на прямолинейных участках и 10 мм - на криволинейных",
          "17 мм - на прямолинейных участках и 12 мм - на криволинейных",
          "20 мм - на прямолинейных участках и 15 мм - на криволинейных",
          "25 мм - на прямолинейных участках и 20 мм - на криволинейных",
        ],
        correctAnswers: [
          "15 мм - на прямолинейных участках и 10 мм - на криволинейных",
        ],
      },
      {
        code: "20503362",
        text: "В каком случае допускается эксплуатация стрелочных переводов рельсовых путей?",
        answers: [
          "При разъединенных стрелочных тягах",
          "При закрытых канавках для тяг приводов стрелочных переводов",
          "При замыкании стрелок с зазором более 4 мм между прижатым остряком (пером) и рамным рельсом",
          "При изогнутых в продольном и поперечном направлениях остряках (перьях)",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "При закрытых канавках для тяг приводов стрелочных переводов",
        ],
      },
      {
        code: "20503363",
        text: "Каково минимально допустимое свободное расстояние от наиболее выступающих частей механических и ручных приводов стрелочных переводов откаточных путей до кромки подвижного состава?",
        answers: ["0,7 м", "0,65 м", "0,6 м", "0,5 м"],
        correctAnswers: ["0,7 м"],
      },
      {
        code: "20503364",
        text: "С какой периодичностью должны проверяться путь, путевые устройства, водоотводные канавы, стрелочные переводы, путевые сигналы и знаки лицом, назначенным распорядительным документом руководителя шахты?",
        answers: [
          "Не менее 2 раз в месяц",
          "Не менее 1 раза в месяц",
          "Не менее 1 раза в квартал",
          "Не менее 2 раз в квартал",
        ],
        correctAnswers: ["Не менее 2 раз в месяц"],
      },
      {
        code: "20503365",
        text: "В какие сроки должен осматриваться каждый локомотив, находящийся в эксплуатации, начальником электровозного депо или механиком структурного подразделения шахтного транспорта?",
        answers: [
          "Еженедельно",
          "1 раз в 10 дней",
          "1 раз в 2 недели",
          "Ежесменно",
        ],
        correctAnswers: ["Еженедельно"],
      },
      {
        code: "20503366",
        text: "Какой должна быть высота подвески контактного провода от головки рельса в выработке околоствольного двора на участках передвижения людей до места посадки?",
        answers: [
          "Не менее 2,2 м",
          "Не менее 2,1 м",
          "Не менее 2 м",
          "Не менее 1,8 м",
        ],
        correctAnswers: ["Не менее 2,2 м"],
      },
      {
        code: "20503367",
        text: "Какое расстояние от контактного провода до верхняка крепи горной выработки на угольных шахтах?",
        answers: [
          "Не менее 0,5 м",
          "Не менее 0,4 м",
          "Не менее 0,3 м",
          "Не менее 0,2 м",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не менее 0,2 м"],
      },
      {
        code: "20503368",
        text: "Каково максимально допустимое расстояние между секционными выключателями контактной сети?",
        answers: ["500 м", "600 м", "700 м", "800 м"],
        correctAnswers: ["500 м"],
      },
      {
        code: "20503369",
        text: "Когда после окончания заряжания и прекращения газовыделения из аккумуляторов   разрешается закрывать  аккумуляторы и батарейный ящик?",
        answers: [
          "Не раньше, чем через час",
          "Не раньше, чем через полчаса",
          "Не раньше, чем через 20 минут",
          "Не раньше, чем через 10 минут",
        ],
        correctAnswers: ["Не раньше, чем через час"],
      },
      {
        code: "20503370",
        text: "Какова максимально допустимая скорость подъема и спуска людей по вертикальным выработкам, оборудованным клетями?",
        answers: ["10 м/с", "12 м/с", "15 м/с", "17 м/с", "20 м/с"],
        correctAnswers: ["12 м/с"],
      },
      {
        code: "20503371",
        text: "Какова величина среднего замедления подъемной установки при спуске расчетного груза при предохранительном торможении и углах наклона выработок до 30°?",
        answers: [
          "Не менее 0,75 м/с2",
          "Не менее 0,6 м/с2",
          "Не менее 0,5 м/с2",
          "Не менее 0,45 м/с2",
        ],
        correctAnswers: ["Не менее 0,75 м/с2"],
      },
      {
        code: "20503372",
        text: "Какое из перечисленных требований к шахтной подъемной установке указано неверно? Укажите все правильные ответы.",
        answers: [
          "На верхних горизонтах запрещается работа в людском и грузовом режимах без посадочных кулаков",
          "Допускается переход людей через подъемные отделения ствола",
          "Допускается отсутствие стационарной аварийно-ремонтной подъемной установки при наличии на вооружении отряда аварийно-спасательных частей, обслуживающего шахту, передвижной подъемной установки",
          "При наличии в одном стволе двух подъемных установок или одной подъемной установки и лестничного отделения дополнительная аварийно-ремонтная установка может отсутствовать",
          "При проходке стволов глубиной до 100 м лебедки для подвески аварийно-спасательных лестниц могут иметь ручной привод и быть оборудованы тормозами и храповичным остановом",
        ],
        correctAnswers: [
          "На верхних горизонтах запрещается работа в людском и грузовом режимах без посадочных кулаков",
          "Допускается переход людей через подъемные отделения ствола",
        ],
      },
      {
        code: "20503373",
        text: "Каким должен быть суммарный зазор между направляющими башмаками скольжения подъемного сосуда (противовеса) и проводниками при их установке на базовой отметке в лобовом и боковом направлениях для рельсовых и деревянных проводников?",
        answers: [
          "Соответственно 10 мм и 20 мм",
          "Соответственно 11 мм и 22 мм",
          "Соответственно 12 мм и 24 мм",
          "Соответственно 14 мм и 26 мм",
        ],
        correctAnswers: ["Соответственно 10 мм и 20 мм"],
      },
      {
        code: "20503374",
        text: "Каков максимально допустимый износ на сторону в лобовом и боковом направлениях у рельсовых проводников?",
        answers: ["8 мм", "7 мм", "6 мм", "5 мм"],
        correctAnswers: ["8 мм"],
      },
      {
        code: "20503375",
        text: "Какое максимальное время срабатывания предохранительного тормоза действующих подъемных машин независимо от типа привода тормоза только при спуске груза (противовеса)?",
        answers: [
          "0,5 с",
          "0,6 с",
          "0,7 с",
          "Не регламентируется",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20503376",
        text: "При использовании какого привода продолжительность холостого хода предохранительного тормоза действующих подъемных машин не должна превышать 0,6 с?",
        answers: [
          "Пневмопружинного привода",
          "Гидропружинного привода",
          "Пневмогрузового привода",
          "Гидрогрузового привода",
          "Все ответы неверны",
        ],
        correctAnswers: ["Гидрогрузового привода"],
      },
      {
        code: "20503377",
        text: "Какой должна быть навивка на барабаны машин на вертикальных и наклонных поверхностных, а также подземных с углом наклона более 60° грузолюдских и людских подъемах?",
        answers: [
          "Должна быть однослойной",
          "Должна быть двухслойной",
          "Должна быть трехслойной",
          "Должна быть многослойной",
        ],
        correctAnswers: ["Должна быть однослойной"],
      },
      {
        code: "20503378",
        text: "Какой должна быть высота реборды над верхним слоем навивки при наличии более одного слоя навивки каната на барабан?",
        answers: [
          "2,5 диаметра каната",
          "2 диаметра каната",
          "1,5 диаметра каната",
          "1 диаметр каната",
        ],
        correctAnswers: ["2,5 диаметра каната"],
      },
      {
        code: "20503379",
        text: "Какова периодичность проверки главным механиком (заместителем главного механика) шахты правильности работы предохранительного тормоза и защитных устройств подъемной установки?",
        answers: [
          "Не реже 1 раза в 15 дней",
          "Не реже 1 раза в 20 дней",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 1,5 месяца",
        ],
        correctAnswers: ["Не реже 1 раза в 15 дней"],
      },
      {
        code: "20503380",
        text: "Какова периодичность осмотра проходческих лебедок электрослесарем? Выберите два правильных варианта ответов.",
        answers: [
          "Ежесменно",
          "Перед каждой спуско-подъемной операцией",
          "1 раз в сутки",
          "1 раз в неделю",
          "1 раз в месяц",
        ],
        correctAnswers: [
          "Ежесменно",
          "Перед каждой спуско-подъемной операцией",
        ],
      },
      {
        code: "20503381",
        text: "Какова периодичность осмотра проходческих лебедок механиком структурного подразделения?",
        answers: [
          "Ежесменно",
          "Перед каждой спуско-подъемной операцией",
          "1 раз в сутки",
          "1 раз в неделю",
          "1 раз в месяц",
        ],
        correctAnswers: ["1 раз в неделю"],
      },
      {
        code: "20503382",
        text: "Какова периодичность осмотра проходческих лебедок главным механиком (заместителем главного механика) шахты?",
        answers: [
          "Ежесменно",
          "Перед каждой спуско-подъемной операцией",
          "1 раз в сутки",
          "1 раз в неделю",
          "1 раз в месяц",
        ],
        correctAnswers: ["1 раз в месяц"],
      },
      {
        code: "20503383",
        text: "Какое из перечисленнных лиц может быть назначено машинистом подъемных машин?",
        answers: [
          "Прошедшее специальное обучение, получившее соответствующее удостоверение и прошедшее трехмесячную стажировку",
          "Проработавшее не менее 1 года в угледобывающей организации",
          "С общим стажем работы на шахте не менее 1 года, прошедшее специальное обучение, получившее соответствующее удостоверение, прошедшее двухмесячную стажировку",
          "С общим стажем работы на шахте не менее 6 месяцев, прошедшее специальное обучение, получившее соответствующее удостоверение, прошедшее трехмесячную стажировку.",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "С общим стажем работы на шахте не менее 1 года, прошедшее специальное обучение, получившее соответствующее удостоверение, прошедшее двухмесячную стажировку",
        ],
      },
      {
        code: "20503384",
        text: "На какие случаи не распространяется запрет передавать сигнал из околоствольного двора непосредственно машинисту, минуя рукоятчика?",
        answers: [
          "На одноклетьевые подъемные установки с подачей сигнала из клети",
          "На установки с опрокидными клетями при подъеме только груза",
          "На скиповые подъемные установки",
          "На все перечисленные случаи",
          "Все ответы неверны",
        ],
        correctAnswers: ["На все перечисленные случаи"],
      },
      {
        code: "20503385",
        text: "При какой номинальной скорости ленты допускается перевозка людей ленточными конвейерами?",
        answers: [
          "Не более 1,5 м/с",
          "Не более 2 м/с",
          "Не более 2,5 м/с",
          "Не более 3,15 м/с",
        ],
        correctAnswers: ["Не более 3,15 м/с"],
      },
      {
        code: "20503386",
        text: "В каком случае допускается перевозка людей ленточными конвейерами в выработках с углами наклона до 18° при номинальной скорости ленты более 3,15 м/с?",
        answers: [
          "При ширине ленты более 1000 мм",
          "По нижней ветви ленты конвейера",
          "По верхней ветви ленты конвейера",
          "По специальным проектам, согласованным с Ростехнадзором в случае отсутствия других средств механизированной доставки людей к месту ведения работ",
        ],
        correctAnswers: [
          "По специальным проектам, согласованным с Ростехнадзором в случае отсутствия других средств механизированной доставки людей к месту ведения работ",
        ],
      },
      {
        code: "20503387",
        text: "Какое из перечисленных требований к станциям посадки и схода, расположенных на участках ленточных конвейеров, используемых для перевозки людей, указано верно?",
        answers: [
          "По всей длине посадочных площадок и за ними на расстоянии не менее 12 м над площадками и лентой (или над находящейся на ней горной массой) должно быть свободное пространство высотой не менее 1,5 м",
          "Станции посадки на верхнюю ветвь ленты должны иметь боковое ограждение высотой не менее 2 м, препятствующее случайному падению пассажира с площадок",
          "Станции посадки с нерабочей стороны должны иметь ограждения длиной не менее 5 м и высотой не менее 1 м, препятствующие случайному смещению пассажиров за пределы ленты при посадке",
          "Станции посадки должны оборудоваться площадками шириной не менее 0,6 м, расположенными сбоку конвейера или над конвейером",
        ],
        correctAnswers: [
          "Станции посадки должны оборудоваться площадками шириной не менее 0,6 м, расположенными сбоку конвейера или над конвейером",
        ],
      },
      {
        code: "20503388",
        text: "На каком максимальном расстоянии от станций посадки и схода, расположенных на участках ленточных конвейеров, используемых для перевозки людей, должны быть установлены переговорные устройства, обеспечивающие связь пассажиров с лицами, управляющими конвейером?",
        answers: ["12 м", "10 м", "5 м", "3 м", "Все ответы неверны"],
        correctAnswers: ["5 м"],
      },
      {
        code: "20503389",
        text: "Что должно быть установлено в начале площадки схода, расположенной на участках ленточных конвейеров, используемых для перевозки людей?",
        answers: [
          "Светильник красного цвета",
          "Датчик проезда для автоматического отключения конвейера",
          "Лунно-белый мигающий сигнал",
          "Светильник желтого цвета",
        ],
        correctAnswers: ["Светильник желтого цвета"],
      },
      {
        code: "20503390",
        text: "Каким образом должны соединяться конвейерные ленты при углах наклона выработки более 10° в соответствии с Инструкцией по безопасной перевозке людей ленточными конвейерами в подземных выработках угольных (сланцевых) шахт?",
        answers: [
          "Скобами",
          "Путем склеивания",
          "При помощи медных или алюминиевых соединителей",
          "Методами горячей или холодной вулканизации",
          "Все ответы неверны",
        ],
        correctAnswers: ["Методами горячей или холодной вулканизации"],
      },
      {
        code: "20503391",
        text: "Какая установлена максимальная длительность эксплуатации резинотканевых конвейерных лент с прочностью прокладок 3 кН/см?",
        answers: [
          "4,5 года",
          "3,5 года",
          "2,5 года",
          "1,5 года",
          "Все ответы неверны",
        ],
        correctAnswers: ["2,5 года"],
      },
      {
        code: "20503392",
        text: "На кого возлагается ответственность за безопасную перевозку людей ленточными конвейерами?",
        answers: [
          "На технического руководителя организации",
          "На горного диспетчера",
          "На лицо, обслуживающее конвейер",
          "На начальника участка, в ведении которого находится конвейер",
        ],
        correctAnswers: [
          "На начальника участка, в ведении которого находится конвейер",
        ],
      },
      {
        code: "20503393",
        text: "Как часто должны осматриваться крепление и освещение выработки, проходы для людей, конвейер, ленточное полотно, станции посадки и схода, сигнализация, устройства отключения и защиты горным мастером или лицом, обслуживающим конвейер?",
        answers: ["Ежесменно", "Ежесуточно", "Еженедельно", "Ежемесячно"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20503394",
        text: "При какой из перечисленных неисправностях допускается перевозка людей конвейерными лентами?",
        answers: [
          "При наличии поврежденных стыковых соединений",
          "При наличии продольных сквозных повреждений длиной более 100 мм",
          "При наличии износа или расслоения боковой кромки на 3 % от номинальной ширины ленты",
          "При наличии более 10 повреждений тросов на длине 1 м резинотросовой ленты",
        ],
        correctAnswers: [
          "При наличии износа или расслоения боковой кромки на 3 % от номинальной ширины ленты",
        ],
      },
      {
        code: "20503395",
        text: "Какова максимально допустимая масса груза, перевозимого одним человеком на конвейере?",
        answers: ["25 кг", "28 кг", "30 кг", "32 кг"],
        correctAnswers: ["25 кг"],
      },
      {
        code: "20503396",
        text: "Какова максимально допустимая длина груза, перевозимого работниками шахты на конвейере?",
        answers: ["1,8 м", "2 м", "2,2 м", "2,5 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "20503397",
        text: "В каком из перечисленных случаев допускается посадка с нависающей над ленточным полотном боковой площадки в положении лежа ногами по направлению движения ленточного полотна путем перекатывания на ленту?",
        answers: [
          "На ленту, движущуюся горизонтально",
          "На ленту, движущуюся вверх",
          "На ленту, движущуюся вниз",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["На ленту, движущуюся вниз"],
      },
      {
        code: "20503398",
        text: "При каком условии сход с ленточного конвейера с грузом может осуществляться только после остановки конвейера?",
        answers: [
          "Сход с ленточного конвейера при любых условиях осуществляться только после остановки конвейера",
          "Если груз перевозится в руках",
          "Если груз размещается в навешенных на сотрудника сумках",
          "Если груз массой более 10 кг",
          "Все ответы неверны",
        ],
        correctAnswers: ["Если груз массой более 10 кг"],
      },
      {
        code: "20503399",
        text: "В каком случае резервный испытанный шахтный канат перед навеской можно вторично не испытывать?",
        answers: [
          "Необходимо всегда вторично испытывать канат перед навеской",
          "По усмотрению старшего механика шахты",
          "Если число оборванных проволок не превышает 2 % общего числа проволок каната",
          "Если срок хранения его не превышает 12 месяцев",
          "Все ответы неверны",
        ],
        correctAnswers: ["Если срок хранения его не превышает 12 месяцев"],
      },
      {
        code: "20503400",
        text: "Какие из перечисленных шахтных канатов подъемных установок и проходческие канаты для подвески полков, испытанные перед навеской, испытывают повторно через каждые шесть месяцев? Укажите все правильные ответы.",
        answers: [
          "Для спасательных лестниц",
          "На грузовых подъемных установках",
          "Для проходческих люлек",
          "На грузолюдских подъемных установках",
          "На аварийно-ремонтных подъемных установках",
        ],
        correctAnswers: [
          "Для проходческих люлек",
          "На грузолюдских подъемных установках",
        ],
      },
      {
        code: "20503401",
        text: "В какие сроки испытывают повторно канаты вертикальных подъемов и наклонных людских, грузолюдских подъемов (с углом наклона свыше 60°), проверяемые канатными дефектоскопами?",
        answers: [
          "Через 6 месяцев после навески, а затем через каждые 3 месяца",
          "Через каждые 6 месяцев",
          "Через 12 месяцев после навески и затем через каждые 6 месяцев",
          "Через 18 месяцев после навески, а затем через каждые 6 месяцев",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Через 18 месяцев после навески, а затем через каждые 6 месяцев",
        ],
      },
      {
        code: "20503402",
        text: "Эксплуатация каких из перечисленных стальных прядевых канатов шахтных подъемных установок запрещается при наличии на каком-либо участке обрывов проволок, число которых на шаге свивки от общего их числа в канате достигает 5 %?",
        answers: [
          "Уравновешивающих канатов",
          "Отбойных канатов",
          "Канатов грузовых наклонных подъемов с углом наклона до 30°",
          "Канатов для подвески полков и механических грузчиков (грейферов)",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Канатов для подвески полков и механических грузчиков (грейферов)",
        ],
      },
      {
        code: "20503403",
        text: "Кто осматривает подвесные устройства проходческого оборудования и все узлы крепления канатов в стволе один раз в месяц?",
        answers: [
          "Механик проходки",
          "Дежурный слесарь",
          "Главный инженер шахты",
          "Главный механик шахты или организации, выполняющей работы по проведению наклонных или вертикальных горных выработок",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Главный механик шахты или организации, выполняющей работы по проведению наклонных или вертикальных горных выработок",
        ],
      },
      {
        code: "20503404",
        text: "Какую защиту устанавливают в электрической сети напряжением 1200 В, от которой питается передвижная участковая подземная подстанция (трансформатор), в тупиковой выработке?",
        answers: [
          "От двухфазных замыканий",
          "От трехфазных замыканий",
          "От однофазных замыканий на землю",
          "От двухфазных замыканий на землю",
          "Все ответы неверны",
        ],
        correctAnswers: ["От однофазных замыканий на землю"],
      },
      {
        code: "20503405",
        text: "Каково минимально допустимое расстояние от распредпункта до забоя тупиковой выработки?",
        answers: ["8 м", "10 м", "15 м", "20 м"],
        correctAnswers: ["20 м"],
      },
      {
        code: "20503406",
        text: "Какое из перечисленных требований безопасности указано неверно?",
        answers: [
          "Питание аппаратуры контроля расхода воздуха осуществляется от пускателя вентилятора",
          "Питание аппаратуры контроля содержания метана осуществляется с ввода общего выключателя",
          "Пускатель вентилятора местного проветривания (ВМП) подключают к вводу общего автоматического выключателя распределительного пункта участка с помощью отдельного автоматического выключателя, не имеющего нулевой защиты",
          "Для подсоединения аппаратуры контроля расхода воздуха и средств автоматизации в сети напряжением до 42 В применяются тройниковые муфты и аналогичные устройства",
        ],
        correctAnswers: [
          "Для подсоединения аппаратуры контроля расхода воздуха и средств автоматизации в сети напряжением до 42 В применяются тройниковые муфты и аналогичные устройства",
        ],
      },
      {
        code: "20503407",
        text: "Каково максимально допустимое содержание метана в горных выработках при котором допускается проверка изоляции и поиск повреждений в силовых кабелях, проложенных в этих выработках?",
        answers: ["1 %", "1,5 %", "2 %", "2,5 %"],
        correctAnswers: ["1 %"],
      },
      {
        code: "20503408",
        text: "Каково максимально допустимое напряжение для цепей дистанционного управления стационарными и передвижными машинами и механизмами?",
        answers: ["42 В", "60 В", "110 В", "220 В"],
        correctAnswers: ["42 В"],
      },
      {
        code: "20503409",
        text: "Каково максимально допустимое напряжение для ручных машин и инструментов?",
        answers: ["42 В", "60 В", "110 В", "220 В"],
        correctAnswers: ["220 В"],
      },
      {
        code: "20503410",
        text: "Какова максимально допустимая температура сжатого воздуха для компрессора сухого сжатия в подземных передвижных компрессорных установках?",
        answers: ["182 °С", "160 °С", "140 °С", "120 °С"],
        correctAnswers: ["182 °С"],
      },
      {
        code: "20503411",
        text: "Какова максимально допустимая температура сжатого воздуха для маслонаполненного компрессора сжатия в подземных передвижных компрессорных установках?",
        answers: ["100 °С", "125 °С", "157 °С", "182 °С"],
        correctAnswers: ["125 °С"],
      },
      {
        code: "20503412",
        text: "В каком случае разрешаются включение и работа подземной передвижной компрессорной установки?",
        answers: [
          "При засоренных воздушном и масляном фильтрах",
          "При отсутствии тепловой защиты",
          "При содержании метана в месте расположения установки более 1,0 %",
          "При течи масла",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20503413",
        text: "Где устанавливают аппаратуру аварийной связи и оповещения на угольных шахтах?",
        answers: [
          "У диспетчера",
          "У главного инженера шахты",
          "У абонентов",
          "У всех перечисленных",
          "Все ответы неверны",
        ],
        correctAnswers: ["У всех перечисленных"],
      },
      {
        code: "20503414",
        text: "В течение какого времени резервный автономный источник должен обеспечивать работу устройства связи с сетевым питанием при отключении напряжения питающей сети?",
        answers: [
          "Не менее 3 часов",
          "Не менее 2 часов",
          "Не менее 1 часа",
          "Не менее 0,5 часа",
        ],
        correctAnswers: ["Не менее 3 часов"],
      },
      {
        code: "20503415",
        text: "Какое напряжение допускается для питания подземных осветительных установок и для ручных переносных светильников, питаемых от искробезопасных источников, на угольных шахтах?",
        answers: [
          "Не выше 180 В и не выше 12 В соответственно",
          "Не выше 127 В и не выше 50 В соответственно",
          "Не выше 380 В и не выше 36 В соответственно",
          "Не выше 220 В и не выше 42 В соответственно",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не выше 220 В и не выше 42 В соответственно"],
      },
      {
        code: "20503416",
        text: "При каких условиях обеспечивается надежность болтового соединения заземляющих проводников?",
        answers: [
          "Только при диаметре зажима не менее 8 мм",
          "Только при зачищенной контактной поверхности и не менее площади шайбы для принятого болта",
          "Только при наличии в болтовом соединении пружинных шайб или контргаек",
          "При всех перечисленных",
        ],
        correctAnswers: ["При всех перечисленных"],
      },
      {
        code: "20503417",
        text: "Чем обеспечивают надежность болтового соединения заземляющих проводников в шахтах?",
        answers: [
          "Диаметр зажима должен быть не менее 8 мм",
          "Контактные поверхности - не менее площади шайбы для принятого болта и должны быть зачищены",
          "Болты и гайки снабжаются пружинными шайбами или контргайками",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "20503418",
        text: "Какими выполняют сборные заземляющие проводники (шины) при установке одного заземлителя на группу заземляемых объектов? Укажите все правильные ответы.",
        answers: [
          "Из свинца с минимальным сечением 70 мм²",
          "Из нержавеющей стали с минимальным сечением 35 мм²",
          "Из стали с минимальным сечением 50 мм²",
          "Из меди с минимальным сечением 25 мм²",
          "Из алюминия с минимальным сечением 25 мм²",
        ],
        correctAnswers: [
          "Из стали с минимальным сечением 50 мм²",
          "Из меди с минимальным сечением 25 мм²",
        ],
      },
      {
        code: "20503419",
        text: "Какими выполняют главные шахтные заземлители?",
        answers: [
          "Из медной полосы не менее 65 мм²",
          "Из медной полосы не менее 60 мм²",
          "Из стальной полосы сечением не менее 0,75 м²",
          "Из стальной полосы (троса) сечением не менее 100 мм2",
        ],
        correctAnswers: [
          "Из стальной полосы (троса) сечением не менее 100 мм2",
        ],
      },
      {
        code: "20503420",
        text: "Каково минимально допустимое сечение главных заземлителей из стальной полосы (троса)?",
        answers: ["50 мм2", "100 мм2", "120 мм2", "150 мм2"],
        correctAnswers: ["100 мм2"],
      },
      {
        code: "20503421",
        text: "Какова минимально допустимая площадь главных заземлителей из стальной полосы в зумпфе, водосборнике или специальном колодце?",
        answers: ["0,75 м2", "1 м2", "1,2 м2", "1,5 м2"],
        correctAnswers: ["0,75 м2"],
      },
      {
        code: "20503422",
        text: "Какова минимально допустимая толщина главных заземлителей из стальной полосы в зумпфе, водосборнике или специальном колодце?",
        answers: ["5 мм", "10 мм", "12 мм", "15 мм"],
        correctAnswers: ["5 мм"],
      },
      {
        code: "20503423",
        text: "Какова минимально допустимая длина главных заземлителей из стальной полосы в зумпфе, водосборнике или специальном колодце?",
        answers: ["1,5 м", "2 м", "2,5 м", "3 м"],
        correctAnswers: ["2,5 м"],
      },
      {
        code: "20503424",
        text: "Какова минимально допустимая глубина колодцев для размещения главных заземлителей?",
        answers: ["1,5 м", "2,5 м", "3,5 м", "4,5 м"],
        correctAnswers: ["3,5 м"],
      },
      {
        code: "20503425",
        text: "Кем проводится ежесменный осмотр всех шахтных заземляющих устройств в начале каждой смены? Укажите все правильные ответы.",
        answers: [
          "Ответственным за электробезопасность",
          "Начальником участка",
          "Лицами, обслуживающими электрооборудование",
          "Дежурными электрослесарями участка",
          "Специалистом по охране труда",
        ],
        correctAnswers: [
          "Лицами, обслуживающими электрооборудование",
          "Дежурными электрослесарями участка",
        ],
      },
      {
        code: "20503426",
        text: "С какой периодичностью производится наружный осмотр общей заземляющей сети шахты с измерением общего сопротивления заземляющей сети у каждого заземлителя?",
        answers: [
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в 10 месяцев",
          "Не реже 1 раза в 12 месяцев",
        ],
        correctAnswers: ["Не реже 1 раза в 3 месяца"],
      },
      {
        code: "20503427",
        text: "С какой периодичностью измеряется общее сопротивление заземляющей сети у каждого заземлителя?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в месяц",
        ],
        correctAnswers: ["Не реже 1 раза в 3 месяца"],
      },
      {
        code: "20503428",
        text: "С какой периодичностью проводится осмотр главных заземлителей, расположенных в зумпфе и водосборнике?",
        answers: [
          "Не реже 1 раза в 1,5 года",
          "Не реже 1 раза в 12 месяцев",
          "Не реже 1 раза в 10 месяцев",
          "Не реже 1 раза в 6 месяцев",
        ],
        correctAnswers: ["Не реже 1 раза в 6 месяцев"],
      },
      {
        code: "20503429",
        text: "Какова максимально допустимая концентрации метана в месте проведения измерений сопротивления заземления?",
        answers: ["1 %", "0,8 %", "0,5 %", "0,3 %"],
        correctAnswers: ["1 %"],
      },
      {
        code: "20503430",
        text: "С какой периодичностью ИТР шахты в порядке, утвержденном распорядительным документом руководителя шахты, должен выборочно контролировать головные светильники на шахте?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в квартал",
          "Не реже 1 раза в полугодие",
          "Не реже 1 раза в год",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20503431",
        text: "Кем утверждается схема размещения передвижных трансформаторных подстанций, устанавливаемых в выработках с исходящей струей воздуха, непосредственно примыкающих к очистным забоям пологих и наклонных пластов, опасных по внезапным выбросам, в участковых сетях угольных шахт напряжением до 1200 В?",
        answers: [
          "Инспектором Ростехнадзора",
          "Главным механиком шахты",
          "Начальником шахты",
          "Техническим руководителем шахты",
          "Специализированной бригадой",
        ],
        correctAnswers: ["Техническим руководителем шахты"],
      },
      {
        code: "20503432",
        text: "Какие действия допускаются при электроснабжении, выборе и проверке электрических аппаратов, кабелей и устройств релейной защиты в участковых сетях шахт напряжением до 1200 В?",
        answers: [
          "Электроснабжение участков с поверхности через скважины",
          "Подключение к одному комплектному распределительному устройству несколько передвижных подстанции или трансформаторов, питающих электроэнергией технологически связанные машины участка",
          "Электроснабжение участков от стационарных участковых подстанций",
          "Все перечисленные действия",
        ],
        correctAnswers: ["Все перечисленные действия"],
      },
      {
        code: "20503433",
        text: "Какое из перечисленных требований Инструкции по электроснабжению угольных шахт указано неверно?",
        answers: [
          "Электроснабжение участка осуществляется от передвижных трансформаторных подстанций, присоединяемых к распределительной сети с помощью комплектных распределительных устройств",
          "Допускается осуществлять электроснабжение участка осуществляется от стационарных участковых подстанций",
          "К одному комплектному распределительному устройству подключается несколько передвижных подстанций или трансформаторов, питающих электроэнергией технологически связанные машины участка",
          "Электроснабжение участков с поверхности через скважины не допускается",
        ],
        correctAnswers: [
          "Электроснабжение участков с поверхности через скважины не допускается",
        ],
      },
      {
        code: "20503434",
        text: "При помощи чего допускается присоединение к сети забойных машин?",
        answers: [
          "При помощи магнитных станций или переключателей, управляемых вручную",
          "При помощи специальных магнитных станций, управляемых дистанционно или вручную",
          "При помощи магнитных пускателей, управляемыми однокнопочными постами для включения и отключения забойных машин",
          "При помощи магнитных пускателей или специальных магнитных станций, управляемых дистанционно",
        ],
        correctAnswers: [
          "При помощи магнитных пускателей или специальных магнитных станций, управляемых дистанционно",
        ],
      },
      {
        code: "20503435",
        text: "Какое из перечисленных требований к электроснабжению электрических аппаратов, кабелей и устройств релейной защиты в участковых сетях шахт напряжением до 1200 В указано неверно?",
        answers: [
          "Схема управления забойными машинами и механизмами обеспечивает нулевую защиту",
          "В очистных забоях предусматривается возможность остановки конвейера с пульта управления комбайном и со специальных пультов",
          "Не применяются схемы управления вентиляторами местного проветривания, допускающие подачу напряжения на них одновременно с двух и более пультов управления",
          "Все перечисленные требования указаны верно",
        ],
        correctAnswers: [
          "Не применяются схемы управления вентиляторами местного проветривания, допускающие подачу напряжения на них одновременно с двух и более пультов управления",
        ],
      },
      {
        code: "20503436",
        text: "Какие действия разрешены Инструкцией по электроснабжению, выбору угольных шахт в участковых сетях шахт напряжением до 1200 В?",
        answers: [
          "Изменять заводскую конструкцию и схему электрооборудования, схемы аппаратуры управления, защиты и контроля, а также градуировку устройств защиты",
          "Включать электрическую сеть при повреждении кабелей и электрооборудования",
          "Проводить оперативное обслуживание и управлять электроустановками напряжением до 42 В, не защищенными аппаратами защиты от утечек тока, без диэлектрических перчаток",
          "Обслуживать и ремонтировать электрооборудование и сети без приборов и инструмента, предназначенных для этих целей",
          "Иметь под напряжением неиспользуемые электрические сети",
        ],
        correctAnswers: [
          "Проводить оперативное обслуживание и управлять электроустановками напряжением до 42 В, не защищенными аппаратами защиты от утечек тока, без диэлектрических перчаток",
        ],
      },
      {
        code: "20503437",
        text: "В каком случае применяются однокнопочные посты для управления магнитными пускателями в участковых сетях угольных шахт напряжением до 1200 В?",
        answers: [
          "Если магнитные пускатели с искробезопасными схемами управления",
          "Только в шахтах, неопасных по газу",
          "Если их применение установлено проектной документацией",
          "Если эти посты применяются для отключения",
        ],
        correctAnswers: ["Если эти посты применяются для отключения"],
      },
      {
        code: "20503438",
        text: "Как регламентируется Инструкцией по электроснабжению угольных шахт применение схем, допускающих пуск машин или подачу напряжения на них одновременно с двух и более пультов управления, в участковых сетях угольных шахт напряжением до 1200 В?",
        answers: [
          "Применение данных схем запрещается в любых случаях",
          "Применение данных схем разрешается без ограничений",
          "Применение данных схем возможно только по согласованию с разработчиком проекта",
          "Применение данных схем запрещается, кроме схем управления вентиляторами местного проветривания",
        ],
        correctAnswers: [
          "Применение данных схем запрещается, кроме схем управления вентиляторами местного проветривания",
        ],
      },
      {
        code: "20503439",
        text: "От каких явлений в подземных электрических сетях при напряжении до 1200 В осуществляется защита электродвигателей и питающих их кабелей?",
        answers: [
          "От токов короткого замыкания: мгновенная или селективная",
          "От включения напряжения при сниженном сопротивлении изоляции относительно земли",
          "От перегрузки, перегрева, опрокидывания и несостоявшегося пуска электродвигателей, работающих в режиме экстремальных перегрузок",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20503440",
        text: "На каком максимальном расстоянии допускается установка автоматического выключателя с аппаратом защиты от утечек тока под скважиной при питании подземных электроприемников с поверхности через скважины?",
        answers: [
          "20 м от скважины",
          "15 м от скважины",
          "10 м от скважины",
          "8 м от скважины",
        ],
        correctAnswers: ["10 м от скважины"],
      },
      {
        code: "20503441",
        text: "Какие предохранители разрешается применять на трансформаторах, находящихся на поверхности и питающих подземные электрические сети, снабженные защитой от утечек тока, в участковых сетях угольных шахт напряжением до 1200 В?",
        answers: [
          "Предохранители без патронов",
          "Пробивные предохранители",
          "Указанные главным механиком в письменном распоряжении по шахте",
          "Предохранители с калиброванными плавкими вставками",
          "Все ответы неверны",
        ],
        correctAnswers: ["Предохранители с калиброванными плавкими вставками"],
      },
      {
        code: "20503442",
        text: "Кем производится ревизия и проверка взрывобезопасности электрооборудования в участковых сетях угольных шахт напряжением до 1200 В?",
        answers: [
          "Только главным энергетиком (главным механиком) шахты или назначенным им лицом",
          "Дежурными электрослесарями участка",
          "Механиком участка или его заместителем",
          "Группой электрослесарей под контролем главного энергетика (главного механика) шахты или лица, им назначенного",
        ],
        correctAnswers: [
          "Группой электрослесарей под контролем главного энергетика (главного механика) шахты или лица, им назначенного",
        ],
      },
      {
        code: "20503443",
        text: "С какой периодичностью механик участка или его заместитель осматривают электрические машины, аппараты, трансформаторы, их взрывобезопасные оболочки, кабели, заземления?",
        answers: [
          "Ежесменно",
          "Еженедельно",
          "Ежемесячно",
          "1 раз в 3 месяца",
          "Все ответы неверны",
        ],
        correctAnswers: ["Еженедельно"],
      },
      {
        code: "20503444",
        text: "В какой документ главный энергетик (главный механик) шахты вносит запись осмотра электрических машин, аппаратов, трансформаторов, их взрывобезопасных оболочек, кабелей, заземления?",
        answers: [
          "В акт осмотра электрооборудования",
          "В журнал учета проверок электроснабжения шахты",
          "В книгу регистрации состояния электрооборудования и заземления",
          "В оперативный журнал участка",
        ],
        correctAnswers: [
          "В книгу регистрации состояния электрооборудования и заземления",
        ],
      },
      {
        code: "20503445",
        text: "Каково минимально допустимое сопротивление изоляции работающих в шахте электродвигателей угледобывающих и проходческих машин?",
        answers: ["0,5 МОм", "0,4 МОм", "0,3 МОм", "0,2 МОм"],
        correctAnswers: ["0,5 МОм"],
      },
      {
        code: "20503446",
        text: "Куда делается запись результатов проверки аппарата защиты от утечек тока на срабатывание перед началом каждой смены?",
        answers: [
          "В книгу регистрации состояния машин и оборудования",
          "В личный график осмотра аппарата защиты от утечек тока",
          "В акт осмотра электроустановок",
          "На доски контроля исправности аппарата защиты от утечек тока",
        ],
        correctAnswers: [
          "На доски контроля исправности аппарата защиты от утечек тока",
        ],
      },
      {
        code: "20503447",
        text: "С какой периодичностью проверяется общее время отключения сети напряжением 1200 В под действием аппарата защиты от утечек тока?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в год",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не реже 1 раза в 6 месяцев"],
      },
      {
        code: "20503448",
        text: "При каких условиях по разрешению главного энергетика допускается производить вблизи и на токоведущих частях, находящихся под напряжением, работы, когда исключена возможность их выполнения со снятым напряжением?",
        answers: [
          "При наличии наряда-допуска на производство работ с указанием мероприятий по электробезопасности, в том числе мер, исключающих непосредственное прикосновение к токоведущим частям искроопасных цепей напряжением выше 42 В",
          "При обеспечении непрерывного надзора за работающими",
          "При наличии в удостоверениях лиц, производящих работы, записи о допуске к проведению работ без снятия напряжения согласно квалификационной группе",
          "При всех перечисленных",
        ],
        correctAnswers: ["При всех перечисленных"],
      },
      {
        code: "20503449",
        text: "При каком содержании метана в процессе монтажа и ремонта электрооборудования в шахтах, опасных по газу, работы прекращают, а напряжение снимают?",
        answers: [
          "Более 0,2 %",
          "Более 0,5 %",
          "Более 0,8 %",
          "Более 1 %",
          "Все ответы неверны",
        ],
        correctAnswers: ["Более 1 %"],
      },
      {
        code: "20503450",
        text: "С кем согласуется наряд-допуск на производство работ в электроустановках напряжением до 1200 В, когда исключена возможность их выполнения со снятым напряжением, в шахтах, опасных по газу?",
        answers: [
          "С техническим руководителем шахты",
          "С начальником шахты",
          "С главным механиком шахты",
          "С начальником (заместителем) участка аэрологической безопасности (АБ)",
        ],
        correctAnswers: [
          "С начальником (заместителем) участка аэрологической безопасности (АБ)",
        ],
      },
      {
        code: "20503451",
        text: "С какой периодичностью должна проводиться проверка уставок максимальной токовой защиты шахтных аппаратов напряжением до 3300 В?",
        answers: [
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в 10 месяцев",
          "Не реже 1 раза в год",
          "Не реже 2 раз в 1,5 года",
        ],
        correctAnswers: ["Не реже 1 раза в 6 месяцев"],
      },
      {
        code: "20503452",
        text: "Каким должно быть время срабатывания защиты трансформаторов передвижных участковых понизительных подстанций (ПУПП) напряжением 3300 В и каждого отходящего от них присоединения от токов короткого замыкания?",
        answers: [
          "В пределах 0,2 с",
          "В пределах 0,5 с",
          "В пределах 0,8 с",
          "В пределах 1,5 с",
        ],
        correctAnswers: ["В пределах 0,2 с"],
      },
      {
        code: "20503453",
        text: "В каких местах предусматривается установка электрических аппаратов, обеспечивающих отключение токов короткого замыкания?",
        answers: [
          "Только на всех вводных, секционных, резервных и отходящих присоединениях центральной подземной подстанции (ЦПП)",
          "Только на всех вводных, секционных, резервных и отходящих присоединениях распределительной подземной подстанции (РПП)",
          "Только на ответвлениях от магистрали, а также в конце линий, питающих силовые трансформаторы или другие электроприемники, не имеющие встроенных разъединителей",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20503454",
        text: "На какое время допускается отключение разъединителей комплектных распределительных устройств (КРУ), если эта операция приводит к нарушению взрывозащиты остающихся под напряжением токоведущих частей разъединителей?",
        answers: [
          "Только на время производства работ по ремонту КРУ",
          "Только на время включения аварийного резерва электрооборудования",
          "Не более 1 часа",
          "Не более 5 часов",
        ],
        correctAnswers: ["Только на время производства работ по ремонту КРУ"],
      },
      {
        code: "20503455",
        text: "С какой задержкой времени выполняют защиту минимального напряжения на питающих линиях центральной подземной подстанции?",
        answers: ["2 с", "4 с", "5 с", "10 с", "Все ответы неверны"],
        correctAnswers: ["10 с"],
      },
      {
        code: "20503456",
        text: "С какой минимальной выдержкой времени должны срабатывать устройства автоматического повторного включения (АПВ) и автоматического включения резерва (АВР), если выдержка на включение выключателя не обеспечивается другими устройствами?",
        answers: [
          "Устройства АПВ и АВР срабатывают без выдержки времени",
          "0,5 с",
          "1 с",
          "2 с",
        ],
        correctAnswers: ["2 с"],
      },
      {
        code: "20503457",
        text: "Какую величину не должна превышать мощность короткого замыкания в подземной сети шахты?",
        answers: ["50 МВА", "75 МВА", "90 МВА", "100 МВА"],
        correctAnswers: ["100 МВА"],
      },
      {
        code: "20503458",
        text: "Каким образом осуществляется заземление агрегатированных машин очистного забоя напряжением 3300 В?",
        answers: [
          "Не менее чем четырьмя заземляющими жилами разных силовых кабелей",
          "Не менее чем тремя заземляющими жилами силовых кабелей",
          "Не менее чем двумя заземляющими жилами разных силовых кабелей",
          "Одной жилой заземления силового кабеля",
        ],
        correctAnswers: [
          "Не менее чем двумя заземляющими жилами разных силовых кабелей",
        ],
      },
      {
        code: "20503459",
        text: "Какие мероприятия должны выполняться при обнаружении порезов или признаков значительного абразивного износа на оболочке кабелей напряжением 3300 В?",
        answers: [
          "Замена всего кабеля",
          "Замена поврежденного участка с установкой линейного электрического соединителя (штепсельного разъема)",
          "Ремонт оболочки с применением вулканизации или установкой ремонтных муфт из композиционных материалов, имеющих соответствующие разрешительные документы на применение в шахтах",
          "Любые из перечисленных",
        ],
        correctAnswers: ["Любые из перечисленных"],
      },
      {
        code: "20503460",
        text: "В соответствии с какими документами производится сращивание отдельных длин кабеля на напряжение 3300 В?",
        answers: [
          "В соответствии с техническими регламентами",
          "В соответствии с внутренними инструкциями эксплуатирующей организации",
          "В соответствии с Инструкцией по электроснабжению угольных шахт",
          "В соответствии с инструкциями заводов-изготовителей",
          "Все ответы неверны",
        ],
        correctAnswers: ["В соответствии с инструкциями заводов-изготовителей"],
      },
      {
        code: "20503461",
        text: "Какое из перечисленных технических мероприятий при обслуживании электроустановок напряжением 3300 В указано верно?",
        answers: [
          "Оболочки взрывобезопасного электрооборудования, подключенного к цепи тестирования изоляции, остаются открытыми в течение всего времени проведения проверки",
          "Технологическому персоналу, закрепленному за участком, на котором электроснабжение машин очистного или проходческо-очистного комплекса осуществляется напряжением 3300 В, в порядке текущей эксплуатации с полным снятием напряжения не допускается выполнять ремонт контактных групп, служащих для присоединения отходящих кабелей",
          "Осмотр всех электроустановок напряжением 3300 В проводится визуально со снятием напряжения",
          "Работы по проведению высоковольтного тестирования производятся по наряду или распоряжению, а результаты тестирования заносятся в оперативный журнал",
        ],
        correctAnswers: [
          "Работы по проведению высоковольтного тестирования производятся по наряду или распоряжению, а результаты тестирования заносятся в оперативный журнал",
        ],
      },
      {
        code: "20503462",
        text: "Как часто дежурный электрослесарь участка производит осмотр рудничного взрывобезопасного электрооборудования?",
        answers: [
          "Ежемесячно",
          "Ежеквартально",
          "Еженедельно",
          "Ежесменно",
          "По просьбе лица, обслуживающего электрооборудование",
        ],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20503463",
        text: "Какое требование к пружинным шайбам, крепежным гайкам и болтам, другим крепежным элементам рудничного взрывобезопасного электрооборудования указано неверно?",
        answers: [
          "Запрещается эксплуатация электрооборудования при отсутствии шайб, болтов, крепежных элементов или неполной затяжке хотя бы одного болта или другого крепежного элемента",
          "Головки болтов выступают из охранных колец в соответствии с эксплуатационной документацией изготовителя",
          "Фланцы крышки и корпуса взрывонепроницаемой оболочки плотно прилегают по всему периметру",
          "Фланцы крышки и корпуса взрывонепроницаемой оболочки обеспечивают надежное уплотнение прокладками",
        ],
        correctAnswers: [
          "Головки болтов выступают из охранных колец в соответствии с эксплуатационной документацией изготовителя",
        ],
      },
      {
        code: "20503464",
        text: "Какое условие при проведении контроля ширины щели (зазора) в плоских взрывонепроницаемых соединениях рудничного взрывобезопасного электрооборудования указано верно?",
        answers: [
          "Щуп должен входить в проверяемую фланцевую щель",
          "Проверка производится не менее чем в пяти точках, расположенных равномерно по периметру соединения",
          "Для контроля щели (зазора) выбирается щуп толщиной, равной величине ширины щели (зазора), указанной в инструкции по эксплуатации электрооборудования (на чертеже средств взрывозащиты)",
          "Все перечисленное указано верно",
        ],
        correctAnswers: [
          "Для контроля щели (зазора) выбирается щуп толщиной, равной величине ширины щели (зазора), указанной в инструкции по эксплуатации электрооборудования (на чертеже средств взрывозащиты)",
        ],
      },
      {
        code: "20503465",
        text: "Каким образом проверяется высота защитного слоя в оболочке с кварцевым заполнением рудничного взрывобезопасного электрооборудования? Укажите все правильные ответы.",
        answers: [
          "Методом косвенных измерений",
          "Инструментальным методом",
          "Визуально",
          "Посредством смотрового окна",
          "Методом прямых измерений",
        ],
        correctAnswers: ["Визуально", "Посредством смотрового окна"],
      },
      {
        code: "20503466",
        text: "Кем проводится ежеквартальная ревизия рудничного взрывобезопасного электрооборудования?",
        answers: [
          "Механиком участка или его заместителем под контролем главного энергетика (главного механика) шахты или назначенного им лица",
          "Лицом, специально назначенным для проведения ежеквартальной ревизии",
          "Руководителем энергомеханической службы и главным энергетиком (главным механиком) шахты",
          "Группой электрослесарей с привлечением работников энергомеханической службы участка под контролем главного энергетика (главного механика) шахты или назначенного им лица",
        ],
        correctAnswers: [
          "Группой электрослесарей с привлечением работников энергомеханической службы участка под контролем главного энергетика (главного механика) шахты или назначенного им лица",
        ],
      },
      {
        code: "20503467",
        text: "Какое условие при нанесении противокоррозийной смазки на взрывозащитные поверхности рудничного взрывобезопасного электрооборудования во время проведения ежеквартальной ревизии указано неверно?",
        answers: [
          "Для удаления следов коррозии зачищать взрывозащитные поверхности необходимо неметаллическим скребком или очищающей жидкостью, не вызывающей коррозию",
          "Удалять излишки смазки с внутренней и внешней поверхностей электрооборудования требуется с помощью ветоши",
          "Перед нанесением смазки необходимо произвести визуальный осмотр подготовленной поверхности",
          "На глухие резьбовые отверстия смазка наносится равномерным слоем кистью",
        ],
        correctAnswers: [
          "На глухие резьбовые отверстия смазка наносится равномерным слоем кистью",
        ],
      },
      {
        code: "20503468",
        text: "Что применяется для уплотнения кабеля при проведении ежеквартальной ревизии рудничного взрывобезопасного электрооборудования?",
        answers: [
          "Обрезки оболочки гибких резиновых кабелей",
          "Изоляционная лента",
          "Сырая резина",
          "Уплотнительное кольцо",
          "Все ответы неверны",
        ],
        correctAnswers: ["Уплотнительное кольцо"],
      },
      {
        code: "20503469",
        text: 'Какие показатели проверяются при проведении ежеквартальной ревизии электрооборудования с видом взрывозащиты "масляное заполнение оболочки "о"?',
        answers: [
          "Высота слоя масла в оболочке, которая должна соответствовать данным завода-изготовителя",
          "Цвет масла и отсутствие его течи",
          "Температура верхнего слоя масла",
          "Все перечисленные показатели",
        ],
        correctAnswers: ["Все перечисленные показатели"],
      },
      {
        code: "20503470",
        text: 'Какие показатели проверяются при проведении ежеквартальной ревизии электрооборудования с защитой вида "е"?',
        answers: [
          "Разделение между искробезопасными и искроопасными цепями в соединительных коробках и во внутреннем монтаже",
          "Целостность заливки эпоксидным компаундом доступных блоков искрозащиты",
          "Заземление кабельных экранов",
          "Состояние вентиляторов электродвигателей",
          "Все ответы неверны",
        ],
        correctAnswers: ["Состояние вентиляторов электродвигателей"],
      },
      {
        code: "20503471",
        text: "С какой периодичностью производится ревизия ручных электросверл с их разборкой в механической мастерской?",
        answers: [
          "Не реже 1 раза в полгода",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 2 месяца",
          "Не реже 1 раза в месяц",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20503472",
        text: "Какая допускается максимальная предельная общая площадь повреждений защитного покрытия при ревизии электрооборудования, оболочка которого выполнена из алюминиевых сплавов и фрикционная искробезопасность которого обеспечивается защитным покрытием?",
        answers: [
          "10 % от общей площади оболочки оборудования",
          "15 % от общей площади оболочки оборудования",
          "20 % от общей площади оболочки оборудования",
          "25 % от общей площади оболочки оборудования",
          "Все ответы неверны",
        ],
        correctAnswers: ["15 % от общей площади оболочки оборудования"],
      },
      {
        code: "20503473",
        text: "Кто производит прием в эксплуатацию вновь смонтированного электрооборудования для проведения тупиковой выработки?",
        answers: [
          "Электротехнический персонал",
          "Представитель организации-изготовителя",
          "Инспектор территориального органа Ростехнадзора",
          "Комиссия, назначенная техническим руководителем шахты",
        ],
        correctAnswers: [
          "Комиссия, назначенная техническим руководителем шахты",
        ],
      },
      {
        code: "20503474",
        text: "Какие тупиковые выработки в газовых шахтах оборудуют резервными вентиляторами местного проветривания?",
        answers: [
          "Все тупиковые выработки независимо от их длины",
          "Длиной более 100 м",
          "Длиной менее 100 м",
          "Тупиковые выработки в газовых шахтах не оборудуются резервными вентиляторами местного проветривания",
          "Все ответы неверны",
        ],
        correctAnswers: ["Длиной более 100 м"],
      },
      {
        code: "20503475",
        text: "Как осуществляется включение и отключение группового аппарата при применении аппаратуры контроля расхода воздуха и содержания метана в тупиковой выработке? Укажите все правильные ответы.",
        answers: [
          "Автоматически",
          "Вручную по месту",
          "Дистанционно с помощью кнопочного поста",
          "Телемеханически",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Дистанционно с помощью кнопочного поста",
          "Телемеханически",
        ],
      },
      {
        code: "20503476",
        text: "Как осуществляется дистанционное управление группового аппарата в тупиковой выработке?",
        answers: [
          "По двухпроводной схеме",
          "По трехпроводной схеме",
          "По четырехпроводной схеме",
          "Не осуществляется дистанционное управление группового аппарата",
        ],
        correctAnswers: ["По трехпроводной схеме"],
      },
      {
        code: "20503477",
        text: "Какую квалификационную группу по электробезопасности должен иметь персонал, производящий управление групповыми аппаратами при напряжениях сети до 1200 В, в тупиковых выработках?",
        answers: [
          "Не ниже пятой",
          "Не ниже четвертой",
          "Не ниже третьей",
          "Не ниже второй",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не ниже второй"],
      },
      {
        code: "20503478",
        text: "На каком основании производят плановые остановки вентиляторов местного проветривания, в том числе и в связи с ремонтом электрооборудования, в тупиковых выработках?",
        answers: [
          "По решению остановочной комиссии",
          "По письменному распорядительному документу руководителя организации",
          "По письменному разрешению территориального органа Ростехнадзора",
          "По письменному разрешению технического руководителя шахты или лица, его замещающего",
        ],
        correctAnswers: [
          "По письменному разрешению технического руководителя шахты или лица, его замещающего",
        ],
      },
      {
        code: "20503479",
        text: "На каком максимальном расстоянии от забоя допускается установка датчиков контроля скорости (расхода) воздуха в тупиковых выработках, проводимых с применением взрывчатых материалов, в случае невозможности обеспечения защиты от механических повреждений?",
        answers: ["10 м", "15 м", "20 м", "30 м"],
        correctAnswers: ["30 м"],
      },
      {
        code: "20503480",
        text: "Плакат с какой надписью вывешивается на заблокированную в выключенном положении рукоятку разъединителя аппарата при нарушении проветривания тупиковой выработки?",
        answers: [
          '"Опасно для жизни!"',
          '"Не включать - ремонт!"',
          '"Не включать - работа на линии!"',
          '"Не включать - выработка загазована!"',
        ],
        correctAnswers: ['"Не включать - выработка загазована!"'],
      },
      {
        code: "20503481",
        text: "В соответствии с чем осуществляются монтаж и эксплуатация электрооборудования в рудничном нормальном исполнении и общего назначения в шахтах, опасных по газу и пыли?",
        answers: [
          "В соответствии с проектной документацией",
          "В соответствии с проектом, утвержденным руководителем организации",
          "В соответствии с проектом, утвержденным территориальным органом Ростехнадзора",
          "В соответствии с проектом, утвержденным техническим руководителем шахты",
        ],
        correctAnswers: [
          "В соответствии с проектом, утвержденным техническим руководителем шахты",
        ],
      },
      {
        code: "20503482",
        text: "С какой периодичностью проводится замер метана в местах установки электрооборудования в шахтах, опасных по газу и пыли?",
        answers: [
          "Ежесменно",
          "Еженедельно",
          "Периодичность устанавливается проектом, утвержденным территориальным органом Ростехнадзора",
          "Все ответы неверны",
        ],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20503483",
        text: "На каких из перечисленных шахтах устанавливаются датчики стационарных автоматических приборов контроля метана?",
        answers: [
          "На шахтах I категории",
          "На шахтах II категории",
          "На шахтах III категории",
          "На всех перечисленных шахтах, а также на шахтах IV (сверхкатегорийные) категории",
        ],
        correctAnswers: ["На шахтах III категории"],
      },
      {
        code: "20503484",
        text: "Куда направляют перечень пластов, склонных к самовозгоранию, после его утверждения? Укажите все правильные ответы.",
        answers: [
          "В орган местного самоуправления",
          "В государственный пожарный надзор",
          "В ПАСС(Ф), обслуживающее шахту",
          "В территориальный орган Ростехнадзора",
          "В Росгидромет",
        ],
        correctAnswers: [
          "В ПАСС(Ф), обслуживающее шахту",
          "В территориальный орган Ростехнадзора",
        ],
      },
      {
        code: "20503485",
        text: "С какой периодичностью угледобывающие организации определяют склонность отрабатываемых пластов к самовозгоранию?",
        answers: [
          "Периодичность проверки утверждает главный инженер шахты",
          "Не реже 1 раза в год",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 5 лет",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не реже 1 раза в 3 года"],
      },
      {
        code: "20503486",
        text: "На каком расстоянии в местах пересечения пластов угля, склонного к самовозгоранию, вскрывающие горные выработки должны быть обработаны в обе стороны герметизирующим инертным материалом, исключающим проникновение воздуха к угольному массиву?",
        answers: [
          "На расстоянии 5 м",
          "На расстоянии 4 м",
          "На расстоянии 3 м",
          "На расстоянии 2 м",
        ],
        correctAnswers: ["На расстоянии 5 м"],
      },
      {
        code: "20503487",
        text: "Какое из перечисленных требований к отработке пластов угля, склонных к самовозгоранию, указано неверно?",
        answers: [
          "При отработке пластов угля, склонных к самовозгоранию, оставляют в выработанном пространстве измельченный уголь",
          "Отработку крутых и крутонаклонных пластов угля, склонного к самовозгоранию, ведут отдельными выемочными блоками с оставлением между ними противопожарных целиков, прорезаемых только на уровне откаточного и вентиляционного горизонтов",
          "Места и размеры целиков угля должны быть определены техническим проектом и (или) проектной документацией",
          "Размер целика по простиранию равен мощности пласта, но не менее 6 м",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "При отработке пластов угля, склонных к самовозгоранию, оставляют в выработанном пространстве измельченный уголь",
        ],
      },
      {
        code: "20503488",
        text: "Кем определяется периодичность проведения визуального осмотра и инструментального контроля герметичности изолирующих сооружений?",
        answers: [
          "Главным инженером шахты",
          "Начальником службы охраны труда и промышленной безопасности",
          "Специалистом, ответственным за производство работ",
          "Руководителем горнодобывающей организации",
        ],
        correctAnswers: ["Главным инженером шахты"],
      },
      {
        code: "20503489",
        text: "В соответствии с какими документами проводятся локализация и тушение подземного пожара после его обнаружения?",
        answers: [
          "В соответствии с ПЛА и оперативными планами по локализации и ликвидации последствий аварий",
          "В соответствии с проектом тушения, согласованным с аварийно-спасательной частью",
          "В соответствии с проектом противопожарной защиты (ППЗ)",
          "В соответствии с ПЛА и ППЗ",
        ],
        correctAnswers: [
          "В соответствии с ПЛА и оперативными планами по локализации и ликвидации последствий аварий",
        ],
      },
      {
        code: "20503490",
        text: "Кто осуществляет перевод подземного пожара из действующего в потушенный на угольной шахте?",
        answers: [
          "Комиссия, созданная распорядительным документом руководителя шахты",
          "Главным инженером шахты",
          "Инспектором территориального органа Ростехнадзора",
          "Руководителем ПАСС(Ф)",
          "Все ответы неверны.",
        ],
        correctAnswers: [
          "Комиссия, созданная распорядительным документом руководителя шахты",
        ],
      },
      {
        code: "20503491",
        text: "В течение какого времени главный инженер шахты организует проверку состава, температуры, влажности рудничной атмосферы и расхода воздуха ИТР шахты и работниками ПАСС(Ф) после разгазирования вскрытых горных выработок?",
        answers: [
          "В течение суток",
          "В течение не менее 2 суток",
          "В течение не менее 3 суток",
          "В течение не менее 5 суток",
          "Все ответы неверны",
        ],
        correctAnswers: ["В течение не менее 3 суток"],
      },
      {
        code: "20503492",
        text: "В каком из перечисленных случаев подготовка пологих и наклонных пластов угля горными выработками по углю должна осуществляться с оставлением целиков не менее 40 м? Укажите все правильные ответы.",
        answers: [
          "Между горными выработками смежных столбов",
          "Между воздухопроводящей выработкой и монтажными и демонтажными камерами",
          "Между горными выработками с различным направлением воздушных струй",
          "Между воздухопроводящими выработками и атмосферой открытых горных работ и земной атмосферой",
          "Между горными выработками с сонаправленным движением струй",
        ],
        correctAnswers: [
          "Между горными выработками с различным направлением воздушных струй",
          "Между воздухопроводящими выработками и атмосферой открытых горных работ и земной атмосферой",
        ],
      },
      {
        code: "20503493",
        text: "С какой периодичностью главный инженер шахты организует проведение работ по определению герметичности изолирующих перемычек, перепадов давлений на изолирующих перемычках, учетек воздуха через выработанное пространство и распределения воздуха по шахтной сети горных выработок?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в квартал",
          "Не реже 1 раза в месяц",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не реже 1 раза в квартал"],
      },
      {
        code: "20503494",
        text: "Какова периодичность контроля эндогенной пожароопасности целиков у наклонных воздухоподающих выработок, пройденных по углю, на пластах бурого угля, геофизическими методами и (или) по температуре угля в бортах, кровле и почве?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 2 раз в месяц",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "20503495",
        text: "До какого минимального значения скорости фильтрации воздуха, определенной геофизическими методами, мероприятия по ее снижению на участках целиков угля не выполняются?",
        answers: ["0,001 м/с", "0,002 м/с", "0,003 м/с", "0,004 м/с"],
        correctAnswers: ["0,001 м/с"],
      },
      {
        code: "20503496",
        text: "В каких местах следует организовывать контроль эндогенной пожароопасности выемочных участков?",
        answers: [
          "На исходящей из лавы струе воздуха в 10 - 20 м от очистного забоя",
          "В трубопроводах подземных и поверхностных газоотсасывающих установок",
          "В смесительных камерах",
          "В контрольных скважинах, пробуренных в выработанное пространство",
          "Во всех перечисленных",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "20503497",
        text: "В течение какого времени следует отбирать пробы воздуха после обнаружения превышений фоновых значений концентраций индикаторных пожарных газов во всех контрольных точках выемочного участка?",
        answers: [
          "В течение первых суток - с периодичностью не менее 3 раз в сутки, в течение следующей недели - с периодичностью 2 раза в сутки",
          "В течение первых двух суток - с периодичностью не менее 2 раз в сутки, в течение следующей недели - с периодичностью 1 раз в сутки",
          "В течение первой недели - с периодичностью не менее 2 раз в сутки, в течение следующей недели - с периодичностью 1 раз в сутки",
          "В течение первых 12 часов через каждые 2 часа",
        ],
        correctAnswers: [
          "В течение первых двух суток - с периодичностью не менее 2 раз в сутки, в течение следующей недели - с периодичностью 1 раз в сутки",
        ],
      },
      {
        code: "20503498",
        text: "Какова минимальная периодичность отбора проб воздуха после обнаружения превышений фоновых значений концентраций индикаторных пожарных газов в течение первых двух суток?",
        answers: [
          "1 раз в сутки",
          "2 раза в сутки",
          "3 раза в сутки",
          "4 раза в сутки",
        ],
        correctAnswers: ["2 раза в сутки"],
      },
      {
        code: "20503499",
        text: "Какова минимальная периодичность отбора проб воздуха в течение следующей недели после отбора проб воздуха в первые двое суток после обнаружения превышений фоновых значений концентраций индикаторных пожарных газов?",
        answers: [
          "1 раз в сутки",
          "2 раза в сутки",
          "3 раза в сутки",
          "4 раза в сутки",
        ],
        correctAnswers: ["1 раз в сутки"],
      },
      {
        code: "20503500",
        text: "Какой документ оформляется в случае, если применяемые меры по ликвидации очага самонагревания угля не дают положительного эффекта?",
        answers: [
          "Акт ликвидации очага самонагревания угля",
          "Акт обнаружения признаков самонагревания угля в шахте (разрезе)",
          "Акт списания эндогенного пожара",
          "Акт регистрации эндогенного пожара",
          "Все ответы неверны",
        ],
        correctAnswers: ["Акт регистрации эндогенного пожара"],
      },
      {
        code: "20503501",
        text: "Кто утверждает акт ликвидации очага самонагревания угля?",
        answers: [
          "Руководитель геологической службы шахты (разреза)",
          "Территориальный орган Ростехнадзора",
          "Главный инженер шахты (разреза)",
          "Руководитель шахты (разреза)",
        ],
        correctAnswers: ["Руководитель шахты (разреза)"],
      },
      {
        code: "20503502",
        text: "Какие мероприятия должны проводиться для оценки состояния эндогенного и (или) экзогенного пожара?",
        answers: [
          "Только осмотр изолирующих перемычек (ИП)",
          "Только отбор проб и измерение температуры воздуха и воды",
          "Только измерение влагосодержания, давления и его перепада через контрольные скважины и изолирующие перемычки (ИП)",
          "Все перечисленные мероприятия",
        ],
        correctAnswers: ["Все перечисленные мероприятия"],
      },
      {
        code: "20503503",
        text: "Кем осуществляется отбор проб после изоляции эндогенного и (или) экзогенного пожарного участка?",
        answers: [
          "Специалистами участка аэрогазовой безопасности",
          "Специалистами службы прогноза динамических явлений",
          "Главным инженером шахты (разреза)",
          "Профессионально аварийно-спасательной службой (формированием) (ПАСС(Ф))",
        ],
        correctAnswers: [
          "Профессионально аварийно-спасательной службой (формированием) (ПАСС(Ф))",
        ],
      },
      {
        code: "20503504",
        text: "В течение какого времени должны заполняться водосборники главного водоотлива при максимальном притоке воды?",
        answers: [
          "Не менее чем за 4 часа",
          "Не менее чем за 3 часа",
          "Не менее чем за 2 часа",
          "Не менее чем за 1 час",
        ],
        correctAnswers: ["Не менее чем за 4 часа"],
      },
      {
        code: "20503505",
        text: "В течение какого времени должны заполняться водосборники участковых водоотливов при максимальном притоке воды?",
        answers: [
          "Не менее чем за 4 часа",
          "Не менее чем за 3 часа",
          "Не менее чем за 2 часа",
          "Не менее чем за 1 час",
        ],
        correctAnswers: ["Не менее чем за 2 часа"],
      },
      {
        code: "20503506",
        text: "В течение какого времени подача каждого насоса или группы одновременно работающих насосов, не считая резервных, должна обеспечивать откачку максимального суточного притока воды?",
        answers: [
          "Не более чем за 20 часов",
          "Не более чем за 21 час",
          "Не более чем за 22 часа",
          "Не более чем за 24 часа",
        ],
        correctAnswers: ["Не более чем за 20 часов"],
      },
      {
        code: "20503507",
        text: "С какой периодичностью и при каком давлении должны испытываться на     герметичность водоотливные трубопроводы главных водоотливных установок после их монтажа и при эксплуатации?",
        answers: [
          "1 раз в 5 лет при давлении, в 1,25 раза превышающем давление, создаваемое насосами при откачке воды",
          "1 раз в 6 лет при давлении, в 1,3 раза превышающем давление, создаваемое насосами при откачке воды",
          "1 раз в 7 лет при давлении, в 1,4 раза превышающем давление, создаваемое насосами при откачке воды",
          "1 раз в 8 лет при давлении, в 1,5 раза превышающем давление, создаваемое насосами при откачке воды",
        ],
        correctAnswers: [
          "1 раз в 5 лет при давлении, в 1,25 раза превышающем давление, создаваемое насосами при откачке воды",
        ],
      },
      {
        code: "20503508",
        text: "С какой периодичностью проводится инструментальная проверка работоспособности водоотливной установки?",
        answers: [
          "Не реже 1 раза в год",
          "1 раз в 1,5 года",
          "Не реже 1 раза в 2 года",
          "1 раз в 2,5 года",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "20503509",
        text: "В какой форме главный инженер шахты сообщает о затоплении горных выработок шахты главным инженерам смежных шахт?",
        answers: ["В письменной форме", "В устной форме", "В любой форме"],
        correctAnswers: ["В письменной форме"],
      },
      {
        code: "20503510",
        text: "Кого должны поставить в известность люди при появлении в горных выработках, проводимых в границах опасных зон, признаков возможного прорыва воды?",
        answers: [
          "Технического руководителя шахты",
          "Горного диспетчера",
          "Руководителя территориального органа Ростехнадзора",
          "Командира профессиональной аварийно-спасательной службы",
        ],
        correctAnswers: ["Горного диспетчера"],
      },
      {
        code: "20503511",
        text: "Каков максимально допустимый период времени для отбора проб угля на каждом отрабатываемом пласте угля после его обнажения?",
        answers: ["6 часов", "12 часов", "18 часов", "24 часа"],
        correctAnswers: ["24 часа"],
      },
      {
        code: "20503512",
        text: "В какие периоды должны проводиться температурные съемки действующих негорящих породных отвалов?",
        answers: [
          "1 раз в год: сентябрь",
          "2 раза в год: май и сентябрь",
          "3 раза в год: май, июль и сентябрь",
          "3 раза в год: май, июнь и сентябрь",
        ],
        correctAnswers: ["3 раза в год: май, июль и сентябрь"],
      },
      {
        code: "20503513",
        text: "В какие периоды должны проводиться температурные съемки на действующих горящих породных отвалах?",
        answers: [
          "1 раз в год: июнь",
          "1 раз в год: сентябрь",
          "2 раза в год: май и сентябрь",
          "3 раза в год: май, июль и сентябрь",
        ],
        correctAnswers: ["2 раза в год: май и сентябрь"],
      },
      {
        code: "20503514",
        text: "Когда должны проводиться температурные съемки на недействующих горящих породных отвалах?",
        answers: [
          "1 раз в год: сентябрь",
          "1 раз в год: июль",
          "2 раза в год: июнь и сентябрь",
          "3 раза в год: май, июль и сентябрь",
        ],
        correctAnswers: ["1 раз в год: сентябрь"],
      },
      {
        code: "20503515",
        text: "При какой максимальной температуре пород разбираемого слоя разрешается производить работы по тушению или разборке горящих породных отвалов?",
        answers: ["80 °С", "85 °С", "90 °С", "95 °С"],
        correctAnswers: ["80 °С"],
      },
      {
        code: "20503516",
        text: "В каком случае породный отвал относится к категории горящих?",
        answers: [
          "Если на нем имеется хотя бы один очаг горения с температурой пород на глубине до 3,0 м более 80 °С",
          "Если на нем имеется хотя бы один очаг горения с температурой пород на глубине до 2,0 м более 70 °С",
          "Если на нем имеется хотя бы один очаг горения с температурой пород на глубине до 2,5 м более 80 °С",
          "Если на нем имеется хотя бы один очаг горения с температурой пород на глубине до 3,5 м более 70 °С",
        ],
        correctAnswers: [
          "Если на нем имеется хотя бы один очаг горения с температурой пород на глубине до 2,5 м более 80 °С",
        ],
      },
      {
        code: "20503517",
        text: "Кем определяется расположение постоянных мест проведения огневых работ?",
        answers: [
          "Командиром профессиональной аварийно-спасательной службы",
          "Руководителем шахты (фабрики)",
          "Главным инженером шахты (фабрики)",
          "Командиром пожарной части, обслуживающей организацию",
        ],
        correctAnswers: ["Руководителем шахты (фабрики)"],
      },
      {
        code: "20503518",
        text: "Какие категории по пожарной и взрывопожарной опасности установлены для надшахтных зданий и сооружений шахт и фабрик для возможного оборудования в них постоянных мест проведения огневых работ? Выберите 2 варианта ответов.",
        answers: [
          "Пониженная пожароопасность",
          "Умеренная пожароопасность",
          "Взрывопожароопасность",
          "Повышенная взрывопожароопасность",
          "Пожароопасность",
        ],
        correctAnswers: [
          "Пониженная пожароопасность",
          "Умеренная пожароопасность",
        ],
      },
      {
        code: "20503519",
        text: "Какие документы необходимы для проведения огневых работ на временных местах?",
        answers: [
          "Только наряд-допуск на проведение огневых работ",
          "Наряд-допуск на проведение огневых работ и план проведения огневых работ",
          "Только план проведения огневых работ",
          "Только распоряжение на проведении огневых работ",
        ],
        correctAnswers: [
          "Наряд-допуск на проведение огневых работ и план проведения огневых работ",
        ],
      },
      {
        code: "20503520",
        text: "На каких объектах по категориям пожарной и взрывопожарной опасности оборудуются временные места проведения огневых работ?",
        answers: [
          "Только в надшахтных зданиях и сооружениях шахт (фабрик), имеющих пожароопасность и умеренную пожароопасность",
          "Только в надшахтных зданиях и сооружениях шахт (фабрик) с пониженной пожароопасностью",
          "Только в горных выработках шахт, надшахтных зданиях и сооружениях шахт (фабрик), имеющих пониженную пожароопасность и умеренную пожароопасность",
          "В горных выработках шахт, надшахтных зданиях и сооружениях шахт (фабрик) независимо от их категории по пожарной и взрывопожарной опасности",
        ],
        correctAnswers: [
          "В горных выработках шахт, надшахтных зданиях и сооружениях шахт (фабрик) независимо от их категории по пожарной и взрывопожарной опасности",
        ],
      },
      {
        code: "20503521",
        text: "Кто утверждает перечень ИТР, имеющих право оформления нарядов-допусков?",
        answers: [
          "Командир пожарной части, обслуживающей организацию",
          "Лица, ответственные за безопасное выполнение работ на шахте (фабрике)",
          "Главный инженер шахты (фабрики)",
          "Руководитель шахты (фабрики)",
        ],
        correctAnswers: ["Руководитель шахты (фабрики)"],
      },
      {
        code: "20503522",
        text: "Кем оформляется наряд-допуск при проведении огневых работ работниками подрядных организаций?",
        answers: [
          "Главным инженером шахты (фабрики)",
          "Лицом, ответственным за безопасное выполнение работ на шахте (фабрике)",
          "ИТР структурного подразделения шахты (фабрики)",
          "ИТР подрядной организации или структурного подразделения, не входящего в состав шахты (фабрики)",
        ],
        correctAnswers: [
          "ИТР подрядной организации или структурного подразделения, не входящего в состав шахты (фабрики)",
        ],
      },
      {
        code: "20503523",
        text: "В скольких экземплярах оформляется наряд-допуск на проведение огневых работ и на протяжении какого срока он подлежит хранению на шахте (фабрике)?",
        answers: [
          "Наряд-допуск оформляется одном экземпляре и хранится в течение шести месяцев",
          "Наряд-допуск оформляется в двух экземплярах и хранится в течение календарного года",
          "Наряд-допуск оформляется втрех экземплярах и хранится в течение двух лет",
          "Наряд-допуск оформляется в одном экземпляре и хранится в течение месяца",
        ],
        correctAnswers: [
          "Наряд-допуск оформляется в двух экземплярах и хранится в течение календарного года",
        ],
      },
      {
        code: "20503524",
        text: "Что необходимо сделать, если огневые работы не закончены в установленный в наряде-допуске срок?",
        answers: [
          "Главный инженер шахты (фабрики) продлевает срок проведения огневых работ до полного их выполнения",
          "ИТР структурного подразделения, где проводятся огневые работы, выписывает новый наряд-допуск в любом случае",
          "ИТР структурного подразделения, где проводятся огневые работы, продлевает срок проведения огневых работ, но не более чем на 2 смены",
          "ИТР структурного подразделения шахты (фабрики), где проводятся огневые работы, продлевает срок проведения огневых работ, но не более чем на 1 смену",
        ],
        correctAnswers: [
          "ИТР структурного подразделения шахты (фабрики), где проводятся огневые работы, продлевает срок проведения огневых работ, но не более чем на 1 смену",
        ],
      },
      {
        code: "20503525",
        text: "В каком количестве и на какой срок выдается наряд-допуск на проведение огневых работ, связанных со строительством, реконструкцией, капитальным ремонтом, техническим перевооружением и с ремонтными работами, предусматривающими остановку производственного процесса и выполняемыми по графикам производства ремонтных работ?",
        answers: [
          "Один наряд-допуск на весь срок проведения работ",
          "По одному наряду-допуску на каждую смену проведения работ",
          "По одному наряду-допуску на каждый календарный месяц проведения работ",
          "По количеству временных мест проведения работ на каждую смену проведения работ",
        ],
        correctAnswers: ["Один наряд-допуск на весь срок проведения работ"],
      },
      {
        code: "20503526",
        text: "В каких случаях запрещается применение газовой сварки в горных выработках, во взрывопожароопасных и пожароопасных надшахтных зданиях и сооружениях шахт (фабрик)?",
        answers: [
          "Только с использованием ацетилена",
          "Только с использованием пропан-бутана",
          "Только с использованием каких-либо горючих углеводородов",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20503527",
        text: "Что запрещается применять при проведении огневых работ на временных местах в любых случаях?",
        answers: [
          "Электросварочные установки",
          "Ацетиленовые генераторы",
          "Керосинорезы",
          "Сжиженные и сжатые газы",
          "Ацетилен в баллонах",
        ],
        correctAnswers: ["Ацетиленовые генераторы"],
      },
      {
        code: "20503528",
        text: "С какой периодичностью следует определять склонность пластов угля к самовозгоранию для всех разрабатываемых подземным (открытым) способом пластов угля?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 5 лет",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не реже 1 раза в 3 года"],
      },
      {
        code: "20503529",
        text: "В каком случае следует относить пласты угля к категории склонных к самовозгоранию по продолжительности инкубационного периода самовозгорания угля?",
        answers: [
          "При продолжительности инкубационного периода менее 40 суток включительно",
          "При продолжительности инкубационного периода от 41 до 80 суток включительно",
          "При продолжительности инкубационного периода более 80 суток включительно",
          "При продолжительности инкубационного периода менее 100 суток включительно",
        ],
        correctAnswers: [
          "При продолжительности инкубационного периода от 41 до 80 суток включительно",
        ],
      },
      {
        code: "20503530",
        text: "Кто утверждает ежегодно перечень отрабатываемых пластов угля с результатами оценки их склонности к самовозгоранию?",
        answers: [
          "Территориальный орган Ростехнадзора",
          "Специалист службы прогноза динамических явлений",
          "Профессионально аварийно-спасательная служба (формирование)",
          "Главный инженер шахты (разреза)",
          "Все ответы неверны",
        ],
        correctAnswers: ["Главный инженер шахты (разреза)"],
      },
      {
        code: "20503531",
        text: "С какой периодичностью утверждается план работ по профилактике самовозгорания склонных к самовозгоранию пластов угля, главным инженером шахты (разреза)?",
        answers: [
          "Дважды в год",
          "Ежегодно",
          "Ежеквартально",
          "Ежемесячно",
          "Все ответы неверны",
        ],
        correctAnswers: ["Ежеквартально"],
      },
      {
        code: "20503532",
        text: "Где должны храниться планы работ по профилактике самовозгорания склонных к самовозгоранию угля и отчеты об их выполнении?",
        answers: [
          "У главного инженера шахты (разреза)",
          "У руководителя службы прогноза динамических явлений шахты (разреза)",
          "У руководителя геологической службы шахты (разреза)",
          "На участке аэрологической безопасности (АБ)",
        ],
        correctAnswers: ["На участке аэрологической безопасности (АБ)"],
      },
      {
        code: "20503533",
        text: "Какое из перечисленных требований к бурению и оборудованию контрольных скважин при производстве контроля за тушением эндогенного и (или) экзогенного пожара, указано неверно?",
        answers: [
          "Верхняя труба обсадной колонны должна быть закрыта металлической заглушкой на резьбе и выходить из устья скважины на 0,5 - 1,0 м",
          "Спирали с шагом 0,75 м в количестве 5 штук на 1 м длины спирали",
          "Нижняя труба обсадной колонны на длине не менее 10 м должна иметь перфорацию отверстиями диаметром не более 10 мм, расположенными по спирали с шагом 0,75 м в количестве 5 штук на 1 м длины спирали",
          "Глубина контрольных скважин должна соответствовать Инструкции по предупреждению экзогенной и эндогенной пожароопасности на объектах ведения горных работ угольной промышленности",
          "Устье скважины вокруг колонны обсадных труб должно быть затампонировано песчано-цементной смесью",
        ],
        correctAnswers: [
          "Глубина контрольных скважин должна соответствовать Инструкции по предупреждению экзогенной и эндогенной пожароопасности на объектах ведения горных работ угольной промышленности",
        ],
      },
      {
        code: "20503534",
        text: "В течение какого времени после изоляции эндогенного и (или) экзогенного пожарного участка необходимо осуществлять отбор проб ежесуточно?",
        answers: [
          "В течение последующих 5 суток",
          "В течение последующих 10 суток",
          "В течение последующих 15 суток",
          "В течение последующих 25 суток",
          "Все ответы неверны",
        ],
        correctAnswers: ["В течение последующих 15 суток"],
      },
      {
        code: "20503535",
        text: "Кем проводится вскрытие эндогенных и (или) экзогенных пожарных участков?",
        answers: [
          "Специалистами участка аэрогазовой безопасности",
          "Главным инженером шахты (разреза)",
          "Специалистами службы прогноза динамических явлений",
          "Профессионально аварийно-спасательной службой (формированием)",
        ],
        correctAnswers: [
          "Профессионально аварийно-спасательной службой (формированием)",
        ],
      },
      {
        code: "20503536",
        text: "Через какое время после вскрытия потушенных эндогенных и (или) экзогенных пожарных участков следует проводить ремонтно-восстановительные работы?",
        answers: [
          "Не ранее чем через 10 дней",
          "Не ранее чем через неделю",
          "Не ранее чем через 5 суток",
          "Не ранее чем через 3 суток",
        ],
        correctAnswers: ["Не ранее чем через 3 суток"],
      },
      {
        code: "20503537",
        text: "Какая из перечисленных мер, исключающая рецидив эндогенного и (или) экзогенного пожара, прорыв газов, глины и пульпы в действующие выработки, указана верно?",
        answers: [
          "Ежесменный отбор проб воздуха для определения его состава в газоаналитической лаборатории",
          "Контроль за утечками воздуха через выработанное пространство - не реже одного раза в десять суток, в том числе с применением газов-трассеров",
          "Ведение горных работ не более чем в двух очистных забоях",
          "Увеличение размеров выемочных блоков",
        ],
        correctAnswers: [
          "Контроль за утечками воздуха через выработанное пространство - не реже одного раза в десять суток, в том числе с применением газов-трассеров",
        ],
      },
    ],
  },
  83: {
    20601: [
      {
        code: "20601000",
        text: "Какой из приведенных нормативных документов регулирует отношения, возникающие в связи с использованием и охраной недр на территории Российской Федерации?",
        answers: [
          'Федеральный закон "О континентальном шельфе Российской Федерации"',
          'Закон Российской Федерации "О недрах"',
          "Положение о государственном контроле за геологическим изучением, рациональным использованием и охраной недр",
          "Все приведенные документы",
        ],
        correctAnswers: ["Все приведенные документы"],
      },
      {
        code: "20601001",
        text: "Каким документом оформляется предоставление недр в пользование?",
        answers: [
          "Договором о предоставлении в пользование недр",
          "Лицензией на право пользования недрами",
          "Планом производства работ в недрах",
          "Свидетельством о праве собственности на землю",
        ],
        correctAnswers: ["Лицензией на право пользования недрами"],
      },
      {
        code: "20601002",
        text: "Какой документ составляется по результатам проведения инструментальных наблюдений и анализа материалов главным маркшейдером или руководителем группы по наблюдениям за сдвижением?",
        answers: [
          "Схема производства работ на земной поверхности",
          "Технический отчет, содержащий основные результаты наблюдений и рекомендаций по их практическому использованию для решения вопросов охраны объекта",
          "Пояснительная записка о проведении инструментальных наблюдений за сдвижением горных пород",
          "Акт заключительного анализа по результатам произведенных наблюдений",
        ],
        correctAnswers: [
          "Технический отчет, содержащий основные результаты наблюдений и рекомендаций по их практическому использованию для решения вопросов охраны объекта",
        ],
      },
      {
        code: "20601003",
        text: "В каких случаях должны быть определены и нанесены на маркшейдерскую документацию границы опасных зон по прорыву воды и газов?",
        answers: [
          "На шахтах, для которых территориальным органом Ростехнадзора установлено данное требование",
          "На шахтах, вблизи которых присутствуют водные объекты",
          "На шахтах, где имеются затопленные или другие водные (обводненные) объекты, а также выработки, в которых возможны скопления ядовитых и горючих газов",
        ],
        correctAnswers: [
          "На шахтах, где имеются затопленные или другие водные (обводненные) объекты, а также выработки, в которых возможны скопления ядовитых и горючих газов",
        ],
      },
      {
        code: "20601004",
        text: 'Какие объекты, в соответствии с Федеральным законом "О промышленной безопасности опасных производственных объектов", относятся к категории опасных производственных объектов при пользовании недрами?',
        answers: [
          "Только те, на которых производится добыча общераспространенных полезных ископаемых без осуществления взрывных работ",
          "Только те, на которых производится добыча общераспространенных полезных ископаемых открытым способом",
          "Только те, на которых ведутся горные работы (за исключением добычи общераспространенных полезных ископаемых и разработки россыпных месторождений полезных ископаемых, осуществляемых открытым способом без применения взрывных работ), работы по обогащению полезных ископаемых",
          "Только те, на которых ведутся работы по частичному обогащению полезных ископаемых",
        ],
        correctAnswers: [
          "Только те, на которых ведутся горные работы (за исключением добычи общераспространенных полезных ископаемых и разработки россыпных месторождений полезных ископаемых, осуществляемых открытым способом без применения взрывных работ), работы по обогащению полезных ископаемых",
        ],
      },
      {
        code: "20601005",
        text: 'Какой из видов деятельности не относится к видам деятельности в области промышленной безопасности согласно требованиям Федерального закона "О промышленной безопасности опасных производственных объектов"?',
        answers: [
          "Строительство опасного производственного объекта",
          "Текущий ремонт зданий и сооружений",
          "Ликвидация опасного производственного объекта",
          "Проектирование опасного производственного объекта",
        ],
        correctAnswers: ["Текущий ремонт зданий и сооружений"],
      },
      {
        code: "20601006",
        text: "Какой орган осуществляет лицензирование производства маркшейдерских работ?",
        answers: [
          "Росприроднадзор",
          "Ростехнадзор",
          "Роснедра",
          "Роскартография",
        ],
        correctAnswers: ["Ростехнадзор"],
      },
      {
        code: "20601007",
        text: "Какой нормативный документ определяет порядок лицензирования производства маркшейдерских работ?",
        answers: [
          'Постановление Правительства Российской Федерации от 12.10.2020 N 1661 "О лицензировании эксплуатации взрывопожароопасных и химически опасных производственных объектов I, II, III классов опасности"',
          'Постановление Правительства Российской Федерации от 16.09.2020 N 1467 "О лицензировании производства маркшейдерских работ"',
          'Федеральный закон Российской Федерации от 04.05.2001 N 99-ФЗ "О лицензировании отдельных видов деятельности"',
          'Постановление Правительства Российской Федерации от 15.09.2020 N 1435 "О лицензировании деятельности, связанной с обращением взрывчатых материалов промышленного назначения"',
        ],
        correctAnswers: [
          'Постановление Правительства Российской Федерации от 16.09.2020 N 1467 "О лицензировании производства маркшейдерских работ"',
        ],
      },
      {
        code: "20601008",
        text: "Какой срок действия лицензии установлен на производство маркшейдерских работ?",
        answers: ["Бессрочный", "3 года", "5 лет", "2 года"],
        correctAnswers: ["Бессрочный"],
      },
      {
        code: "20601009",
        text: "Каким образом направляются заявление о предоставлении лицензии и прилагаемые к нему документы соискателем лицензии в лицензирующий орган?",
        answers: [
          "Только лично заявителем в лицензирующий орган",
          "Только в форме электронного документа с помощью интернет-сервиса",
          "Подачей документов в лицензирующий орган представителем лицензиата, действующего по доверенности",
          "Представляются непосредственно в лицензирующий орган или направляются заказным почтовым отправлением с уведомлением о вручении или в форме электронных документов (пакета электронных документов), подписанных усиленной квалифицированной электронной подписью соискателя лицензии",
        ],
        correctAnswers: [
          "Представляются непосредственно в лицензирующий орган или направляются заказным почтовым отправлением с уведомлением о вручении или в форме электронных документов (пакета электронных документов), подписанных усиленной квалифицированной электронной подписью соискателя лицензии",
        ],
      },
      {
        code: "20601010",
        text: "Какие виды работ и услуг не включает в себя лицензируемая деятельность по производству маркшейдерских работ?",
        answers: [
          "Проведение горно-капитальных вскрышных работ",
          "Создание маркшейдерских опорных и съемочных сетей",
          "Учет и обоснование объемов горных разработок",
          "Cоздание маркшейдерских сетей для наблюдения за сдвижением земной поверхности",
          "Обоснование границ горных отводов",
        ],
        correctAnswers: ["Проведение горно-капитальных вскрышных работ"],
      },
      {
        code: "20601011",
        text: "Что относится к грубым нарушениям лицензионных требований при производстве маркшейдерских работ?",
        answers: [
          "Только допуск к выполнению работ, составляющих лицензируемую деятельность, лиц, не имеющих специальную подготовку и квалификацию",
          "Только отсутствие у лицензиата оборудования для обработки результатов и средств измерений, соответствующих классификации по точности и техническим требованиям (условиям) производства маркшейдерских работ",
          'Только отсутствие высшего образования по специальности "маркшейдерское дело", высшего технического образования и прохождения профессиональной переподготовки по специальности "маркшейдерское дело" у штатного сотрудника, занимающего  должность главного маркшейдера организации',
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20601012",
        text: "Кто определяет порядок продления срока безопасной эксплуатации технических устройств, оборудования и сооружений, эксплуатируемых на опасных производственных объектах (определение остаточного ресурса)?",
        answers: [
          "Министерство промышленности и торговли Российской Федерации",
          "Федеральная служба по экологическому, технологическому и атомному надзору",
          "Федеральный горный и промышленный надзор России",
          "Федеральное агентство по техническому регулированию и метрологии",
        ],
        correctAnswers: [
          "Федеральная служба по экологическому, технологическому и атомному надзору",
        ],
      },
      {
        code: "20601013",
        text: "С какой периодичностью индивидуальные предприниматели и работники юридического лица, осуществляющие производство маркшейдерских работ, должны проходить повышение квалификации?",
        answers: [
          "Ежегодно",
          "По мере необходимости, в связи с изменением технологического процесса",
          "Не реже одного раза в три года",
          "Один раз в пять лет",
          "Не реже двух раз в год",
        ],
        correctAnswers: ["Не реже одного раза в три года"],
      },
      {
        code: "20601014",
        text: "Кто на каждом объекте, отрабатывающем месторождения, склонные и опасные по горным ударам, производит учет всех случаев горных ударов, микроударов и толчков, стреляний, интенсивного заколообразования и шелушения, результатов определения категорий удароопасности выработок, примененных мер по предотвращению горных ударов с оценкой их эффективности?",
        answers: [
          "Технический руководитель организации, осуществляющей горные работы",
          "Работник управления государственной экспертизы",
          "Служба прогноза и предотвращения горных ударов или маркшейдерско-геологическая служба",
        ],
        correctAnswers: [
          "Служба прогноза и предотвращения горных ударов или маркшейдерско-геологическая служба",
        ],
      },
      {
        code: "20601015",
        text: "Обязаны ли организации, эксплуатирующие опасные производственные объекты, на которых ведутся горные работы и переработка полезных ископаемых, заключать договоры на обслуживание с профессиональными аварийно-спасательными формированиями (службами)?",
        answers: [
          "Не обязаны в случаях отсутствия предписания территориального надзорного органа",
          "Обязаны в любом случае",
          "На усмотрение руководителя организации, эксплуатирующей объект",
          "Обязаны, кроме случаев, установленных законодательством Российской Федерации, когда организации должны создавать собственные профессиональные аварийно-спасательные службы или профессиональные аварийно-спасательные формирования, а также нештатные аварийно-спасательные формирования из числа работников",
        ],
        correctAnswers: [
          "Обязаны, кроме случаев, установленных законодательством Российской Федерации, когда организации должны создавать собственные профессиональные аварийно-спасательные службы или профессиональные аварийно-спасательные формирования, а также нештатные аварийно-спасательные формирования из числа работников",
        ],
      },
      {
        code: "20601016",
        text: "В течение какого времени предприятия, организации и учреждения, объекты которых расположены на площадях залегания полезных ископаемых и подлежат подработке, обязаны по запросу предприятия по добыче полезных ископаемых представить объективные данные о состоянии зданий, сооружений и технологического оборудования, а также о выполненных строительных мерах охраны и допустимых деформациях?",
        answers: [
          "В течение месяца",
          "В течение 7 дней",
          "В течение 10 дней",
          "В течение 2 месяцев",
        ],
        correctAnswers: ["В течение месяца"],
      },
      {
        code: "20601017",
        text: "Кто включается в состав комиссии по комплексному обследованию состояния как объектов, подлежащих охране от вредного влияния горных разработок, так и программы ведения горных работ (горные меры)?",
        answers: [
          "Только представители органов Ростехнадзора",
          "Только представители организации, эксплуатирующей объект для добычи полезных ископаемых",
          "Только представители органов Роснедр",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20601018",
        text: 'Требуется ли маркшейдерское обеспечение буровзрывных работ при разработке месторождений полезных ископаемых в соответствии с Федеральными нормами и правилами в области промышленной безопасности "Правила безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          "Требуется только на момент получения разрешения на взрывные работы",
          "Требуется только при проведении детонационного взрыва при разработке месторождений полезных ископаемых",
          "Маркшейдерское обеспечение буровзрывных работ должно осуществляться в соответствии с установленными требованиями",
          "Не требуется",
        ],
        correctAnswers: [
          "Маркшейдерское обеспечение буровзрывных работ должно осуществляться в соответствии с установленными требованиями",
        ],
      },
      {
        code: "20601019",
        text: "Кто утверждает специальный план, в соответствии с которым производится вскрытие пожарного участка?",
        answers: [
          "Инспектор пожарного надзора",
          "Технический руководитель организации",
          "Руководитель территориального органа Ростехнадзора",
        ],
        correctAnswers: ["Технический руководитель организации"],
      },
      {
        code: "20601020",
        text: "Что из нижеперечисленного не содержится в рабочем проекте на производство буровых работ?",
        answers: [
          "Ф. И. О. ответственного лица за производство буровых работ",
          "Обоснование конструкции скважины",
          "Методы оценки состояния обсадных колонн, способы и периодичность их испытания на остаточную прочность",
          "Геолого-технический наряд на производство буровых работ",
        ],
        correctAnswers: [
          "Ф. И. О. ответственного лица за производство буровых работ",
        ],
      },
      {
        code: "20601021",
        text: "Что понимается под первичной геологической информацией о недрах?",
        answers: [
          "Только информация о недрах, полученная в процессе осуществления вида деятельности, связанного с захоронением радиоактивных отходов и токсичных веществ",
          "Только геофизическая, геохимическая и иная информация о недрах, полученная в процессе пользования недрами",
          "Только информация о недрах, полученная в процессе пользования недрами, связанного с геологическим изучением и добычей отдельных видов минерального сырья",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20601022",
        text: "В каком разделе указаний по безопасному ведению горных работ на месторождениях, склонных и опасных по горным ударам, представляется характеристика горных ударов, других геодинамических явлений, отражающих специфику геологических условий и технологии отработки месторождения?",
        answers: [
          "Общие положения",
          "Введение",
          "Проведение и поддержание горных выработок",
          "Порядок вскрытия, подготовки к выемке и отработки выемочных участков, блоков",
        ],
        correctAnswers: ["Общие положения"],
      },
      {
        code: "20601023",
        text: "Какие участки недр не относятся к участкам недр местного значения?",
        answers: [
          "Участки недр, содержащие подземные воды, используемые для целей питьевого, хозяйственно-бытового водоснабжения, технического водоснабжения садоводческих и огороднических некоммерческих товариществ",
          "Участки недр, содержащие подземные воды, которые используются для целей технологического обеспечения водой объектов промышленности и объем добычи которых составляет более 600 м³ в сутки",
          "Участки недр, используемые для строительства и эксплуатации подземных сооружений местного и регионального значения, не связанных с добычей полезных ископаемых",
          "Участки недр, содержащие общераспространенные полезные ископаемые",
        ],
        correctAnswers: [
          "Участки недр, содержащие подземные воды, которые используются для целей технологического обеспечения водой объектов промышленности и объем добычи которых составляет более 600 м³ в сутки",
        ],
      },
      {
        code: "20601024",
        text: "Какие участки недр относятся к участкам недр федерального значения?",
        answers: [
          "Только участки, расположенные на территории субъекта Российской Федерации и содержащие месторождения с запасами меди от 500 тыс. т",
          "Только участки, содержащие коренные (рудные) месторождения лития или металлов платиновой группы",
          "Только участки внутренних морских вод, континентального шельфа Российской Федерации",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20601025",
        text: "Недра могут предоставляться в пользование для:",
        answers: [
          "Только для геологического изучения, включающего поиски и оценку месторождений полезных ископаемых",
          "Только для разведки и добычи углеводородного сырья для размещения в пластах горных пород попутных вод",
          "Только для сбора минералогических, палеонтологических и других геологических коллекционных материалов",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20601026",
        text: "В границах геологического отвода проводить работы одновременно могут:",
        answers: [
          "Несколько пользователей недр",
          "Не более 2 пользователей недр",
          "2 пользователя недр, включая собственника участка",
        ],
        correctAnswers: ["Несколько пользователей недр"],
      },
      {
        code: "20601027",
        text: "Участки недр предоставляются в пользование:",
        answers: [
          "На определенный срок",
          "Без ограничения срока пользования",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20601028",
        text: "Какие из перечисленных работ на карьере должны вестись в соответствии с утвержденными техническим руководителем объекта локальными проектами (паспортами) и проектом производства работ с учетом инженерно-геологических условий?",
        answers: [
          "Исследования по определению возраста геологических образований",
          "Горные работы по проведению траншей, разработке уступов, дражных полигонов и отсыпке отвалов",
          "Топографо-геодезические работы",
          "Дренажные работы на заболоченных участках",
        ],
        correctAnswers: [
          "Горные работы по проведению траншей, разработке уступов, дражных полигонов и отсыпке отвалов",
        ],
      },
      {
        code: "20601029",
        text: "Что из перечисленного должны обеспечивать мероприятия по выполнению основных требований по безопасному ведению работ, связанных с пользованием недрами?",
        answers: [
          "Только соблюдение требований по безопасному ведению горных и взрывных работ",
          "Только восполнение количества вскрытых, подготовленных и готовых к выемке запасов полезных ископаемых",
          "Только проведение комплекса геологических, маркшейдерских и иных наблюдений за состоянием горного отвода",
          "Все перечисленное, включая ликвидацию (консервацию) отработанных горных выработок, скважин, блоков, горизонтов, объектов обустройства и иных объектов, связанных с горным производством",
        ],
        correctAnswers: [
          "Все перечисленное, включая ликвидацию (консервацию) отработанных горных выработок, скважин, блоков, горизонтов, объектов обустройства и иных объектов, связанных с горным производством",
        ],
      },
      {
        code: "20601030",
        text: "Кто утверждает документацию по ведению горных работ после внесения в нее дополнений, учитывающих происшедшие изменения горно-геологических и горнотехнических условий?",
        answers: [
          "Комиссия, назначенная приказом руководителя горнодобывающего предприятия",
          "Начальник маркшейдерской службы",
          "Главный инженер шахты",
          "Проектная организация",
        ],
        correctAnswers: ["Главный инженер шахты"],
      },
      {
        code: "20601031",
        text: "Что из перечисленного входит в перечень задач государственного надзора за безопасным ведением работ, связанных с пользованием недрами?",
        answers: [
          "Только предупреждение нарушений требований по безопасному ведению работ, связанных с пользованием недрами, лицами, осуществляющими работы на участках недр",
          "Только выявление и пресечение нарушений пользователями недр требований по безопасному ведению работ, связанных с пользованием недрами",
          "Все перечисленное входит в перечень задач государственного надзора за безопасным ведением работ",
        ],
        correctAnswers: [
          "Все перечисленное входит в перечень задач государственного надзора за безопасным ведением работ",
        ],
      },
      {
        code: "20601032",
        text: "Каким федеральным органом исполнительной власти осуществляется, в соответствии с законодательством Российской Федерации, государственный горный надзор?",
        answers: [
          "Федеральной службой по экологическому, технологическому и атомному надзору",
          "Министерством природных ресурсов и экологии Российской Федерации",
          "Федеральным агентством геодезии и картографии",
          "Федеральной службой по надзору в сфере природопользования",
        ],
        correctAnswers: [
          "Федеральной службой по экологическому, технологическому и атомному надзору",
        ],
      },
      {
        code: "20601033",
        text: "Что из перечисленного не является предметом проверок при осуществлении государственного горного надзора?",
        answers: [
          "Cоблюдение пользователями недр требований по организации контроля за состоянием рудничной атмосферы, содержанием в ней кислорода, вредных и взрывоопасных газов и пыли",
          "Проведение маркшейдерских наблюдений, необходимых для обеспечения нормального технологического цикла, достоверного учета горных работ и прогнозирования опасных ситуаций",
          "Правильность использования взрывчатых веществ и средств взрывания, их надлежащий учет, хранение и расходование на объектах, связанных с пользованием недрами",
          "Соблюдение пользователями недр требований промышленной безопасности на объектах, на которых производятся работы по транспортировке опасных веществ, а так же при монтаже, наладке и ремонте технических устройств, применяемых на этих объектах",
          "Проведение горноспасательных работ в части, касающейся состояния и готовности подразделений военизированных горноспасательных формирований к ликвидации аварий на обслуживаемых объектах, связанных с пользованием недрами",
        ],
        correctAnswers: [
          "Проведение горноспасательных работ в части, касающейся состояния и готовности подразделений военизированных горноспасательных формирований к ликвидации аварий на обслуживаемых объектах, связанных с пользованием недрами",
        ],
      },
      {
        code: "20601034",
        text: "В какой сфере Федеральная служба по экологическому, технологическому и атомному надзору не осуществляет контроль и надзор?",
        answers: [
          "В сфере безопасного ведения работ, связанных с пользованием недрами",
          "В сфере безопасности электрических и тепловых установок и сетей (кроме бытовых установок и сетей)",
          "В сфере безопасности при использовании атомной энергии (за исключением деятельности по разработке, изготовлению, испытанию, эксплуатации и утилизации ядерного оружия и ядерных энергетических установок военного назначения)",
          "В сфере промышленной безопасности",
          "В сфере безопасности судоходных и портовых гидротехнических сооружений",
          "В сфере безопасности производства, хранения и применения взрывчатых материалов промышленного назначения",
        ],
        correctAnswers: [
          "В сфере безопасности судоходных и портовых гидротехнических сооружений",
        ],
      },
      {
        code: "20601035",
        text: "Каким уполномоченным органом не является Федеральная служба по экологическому, технологическому и атомному надзору?",
        answers: [
          "Органом федерального государственного надзора в области промышленной безопасности",
          "Органом государственного горного надзора",
          "Органом федерального государственного строительного надзора",
          "Органом федерального государственного надзора в области использования атомной энергии",
          "Органом федерального государственного экологического контроля",
        ],
        correctAnswers: [
          "Органом федерального государственного экологического контроля",
        ],
      },
      {
        code: "20601036",
        text: "Какой федеральный орган исполнительной власти осуществляет регистрацию опасных производственных объектов и ведет реестр таких объектов?",
        answers: [
          "МЧС России",
          "Ростехнадзор",
          "Росприроднадзор",
          "Минэнерго России",
        ],
        correctAnswers: ["Ростехнадзор"],
      },
      {
        code: "20601037",
        text: "Какую из перечисленных функций по контролю и надзору не осуществляет Федеральная служба по экологическому, технологическому и атомному надзору?",
        answers: [
          "За представлением декларации о потреблении энергетических ресурсов, соблюдением требований к форме декларации и порядку ее представления",
          "За соблюдением требований технических регламентов в установленной сфере деятельности",
          "За соблюдением в пределах своей компетенции теплоснабжающими организациями и теплосетевыми организациями требований безопасности в сфере теплоснабжения",
          "За соблюдением требований законодательства Российской Федерации в области обращения с радиоактивными отходами",
          "За соблюдением требований законодательства Российской Федерации в области охраны окружающей среды",
        ],
        correctAnswers: [
          "За соблюдением требований законодательства Российской Федерации в области охраны окружающей среды",
        ],
      },
      {
        code: "20601038",
        text: "Каким нормативным правовым документом утверждено Положение о единой государственной системе предупреждения и ликвидации чрезвычайных ситуаций?",
        answers: [
          "Приказом Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "Постановлением Министерства природных ресурсов и экологии Российской Федерации",
          "Постановлением Правительства Российской Федерации",
        ],
        correctAnswers: ["Постановлением Правительства Российской Федерации"],
      },
      {
        code: "20601039",
        text: "На каких уровнях действует Единая государственная система предупреждения и ликвидации чрезвычайных ситуаций?",
        answers: [
          "На федеральном уровне и уровне субъектов Российской Федерации",
          "На федеральном и межрегиональном уровнях",
          "На региональном и муниципальном уровнях",
          "На федеральном, межрегиональном, региональном, муниципальном и объектовом уровнях",
        ],
        correctAnswers: [
          "На федеральном, межрегиональном, региональном, муниципальном и объектовом уровнях",
        ],
      },
      {
        code: "20601040",
        text: "Кем осуществляется ликвидация чрезвычайных ситуаций локального характера в соответствии с Положением о единой государственной системе предупреждения и ликвидации чрезвычайных ситуаций?",
        answers: [
          "Федеральными органами исполнительной власти",
          "Органами исполнительной власти субъектов Российской Федерации, оказавшихся в зоне чрезвычайной ситуации",
          "Организацией",
          "Органами местного самоуправления",
          "Территориальными органами МЧС России",
        ],
        correctAnswers: ["Организацией"],
      },
      {
        code: "20601041",
        text: "Что является основной задачей комиссий по предупреждению и ликвидации чрезвычайных ситуаций и обеспечению пожарной безопасности в соответствии с их компетенцией?",
        answers: [
          "Только рассмотрение вопросов о привлечении сил и средств гражданской обороны к организации и проведению мероприятий по предотвращению и ликвидации чрезвычайных ситуаций",
          "Только разработка предложений по реализации государственной политики в области предупреждения и ликвидации чрезвычайных ситуаций и обеспечения пожарной безопасности;",
          "Только рассмотрение вопросов об организации оповещения и информирования населения о чрезвычайных ситуациях",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20601042",
        text: "Кем осуществляется подготовка проектной документации на разработку месторождений полезных ископаемых?",
        answers: [
          "Только организациями, имеющими лицензию на право разработки проектной документации в области недропользования",
          "Только специалистами Росгеологии",
          "Только проектной организацией, привлекаемой для подготовки проектной документации",
          "Пользователями недр или проектной организацией, привлекаемой пользователем недр для подготовки проектной документации, на основании технического задания на проектирование",
        ],
        correctAnswers: [
          "Пользователями недр или проектной организацией, привлекаемой пользователем недр для подготовки проектной документации, на основании технического задания на проектирование",
        ],
      },
      {
        code: "20601043",
        text: "Каким органом определяются требования к структуре и оформлению проектной документации по видам полезных ископаемых и видам пользования недрами?",
        answers: [
          "Федеральной службой по надзору в сфере природопользования",
          "Министерством природных ресурсов и экологии Российской Федерации",
          "Федеральным агентством по недропользованию",
          "Федеральной службой по экологическому, технологическому и атомному надзору",
        ],
        correctAnswers: [
          "Министерством природных ресурсов и экологии Российской Федерации",
        ],
      },
      {
        code: "20601044",
        text: "Кто производит согласование проектной документации на разработку месторождений полезных ископаемых?",
        answers: [
          "Комиссия, создаваемая Минсельхозом России, состоящая из представителей Россельхознадзора и Минприроды России",
          "Комиссия, создаваемая Ростехнадзором, состоящая из представителей Ростехнадзора и Росприроднадзора",
          "Комиссия, создаваемая Роснедрами или его соответствующим территориальным органом, состоящая из представителей Минприроды России, Ростехнадзора и Росприроднадзора",
          "Комиссия, создаваемая Минприроды России, состоящая из представителей Роснедр, Рослесхоза",
        ],
        correctAnswers: [
          "Комиссия, создаваемая Роснедрами или его соответствующим территориальным органом, состоящая из представителей Минприроды России, Ростехнадзора и Росприроднадзора",
        ],
      },
      {
        code: "20601045",
        text: "В течение какого срока осуществляется рассмотрение проектной документации?",
        answers: [
          "В течение 14 рабочих дней",
          "В течение 25 рабочих дней",
          "В течение 7 рабочих дней",
          "В течение 20 рабочих дней",
        ],
        correctAnswers: ["В течение 25 рабочих дней"],
      },
      {
        code: "20601046",
        text: "На сколько дней может быть увеличен срок рассмотрения комиссией проектной документации по уникальным и крупным месторождениям полезных ископаемых?",
        answers: [
          "Не более чем на 10 рабочих дней",
          "Не более чем на 14 рабочих дней",
          "Срок рассмотрения не увеличивается",
          "Не более чем на 25 рабочих дней",
        ],
        correctAnswers: ["Не более чем на 25 рабочих дней"],
      },
      {
        code: "20601047",
        text: "В какой орган пользователь недр подает заявление и прилагаемые к нему документы для согласования проектной документации?",
        answers: [
          "В Федеральное агентство по техническому регулированию и метрологии",
          "В территориальный орган Федеральной службы по экологическому, технологическому и атомному надзору",
          "В Федеральное агентство по недропользованию (или его территориальный орган)",
          "В Министерство строительства и жилищно-коммунального хозяйства (или его подведомственные организации)",
        ],
        correctAnswers: [
          "В Федеральное агентство по недропользованию (или его территориальный орган)",
        ],
      },
      {
        code: "20601048",
        text: "Кем утверждается проектная документация на разработку месторождений полезных ископаемых?",
        answers: [
          "Роснедрами",
          "Пользователем недр",
          "Росприроднадзором",
          "Ростехнадзором",
        ],
        correctAnswers: ["Пользователем недр"],
      },
      {
        code: "20601049",
        text: "Какие документы могут не прилагаться к заявлению пользователем недр при подаче проектной документации на согласование?",
        answers: [
          "Копия предыдущего решения комиссии или уполномоченного органа",
          'Сведения о наличии в штате работника, имеющего высшее профессиональное образование по специальности "маркшейдерское дело" (имеющего высшее профессиональное образование и прошедшего профессиональную переподготовку с получением квалификации по указанной специальности), аттестованного в области промышленной безопасности',
          "Копия лицензии на пользование участком недр, в пределах которого находится месторождение полезного ископаемого, с приложениями к ней",
          "Копия заключения государственной экспертизы запасов полезных ископаемых и подземных вод, геологической информации о предоставляемых в пользование участках недр (за исключением запасов углеводородного сырья) - в случаях, предусмотренных законодательством Российской Федерации",
          "Проектная документация",
        ],
        correctAnswers: [
          'Сведения о наличии в штате работника, имеющего высшее профессиональное образование по специальности "маркшейдерское дело" (имеющего высшее профессиональное образование и прошедшего профессиональную переподготовку с получением квалификации по указанной специальности), аттестованного в области промышленной безопасности',
        ],
      },
      {
        code: "20601050",
        text: "Каким федеральным органом исполнительной власти оформляется горноотводная документация?",
        answers: [
          "Федеральным агентством по недропользованию",
          "Федеральной службой по надзору в сфере природопользования",
          "Федеральной службой государственной регистрации, кадастра и картографии",
          "Органом государственного горного надзора и уполномоченными органами исполнительной власти субъектов Российской Федерации",
        ],
        correctAnswers: [
          "Органом государственного горного надзора и уполномоченными органами исполнительной власти субъектов Российской Федерации",
        ],
      },
      {
        code: "20601051",
        text: "При каких условиях недропользователю устанавливаются уточненные границы горного отвода?",
        answers: [
          "В момент подготовки к выполнению работ по добыче полезных ископаемых после получения лицензии на право пользования недрами",
          "После предоставления лицензии на пользование недрами, разработки документов, подтверждающих согласование проектной документации, и получения положительного заключения государственной экспертизы",
          "Одновременно с получением лицензии на пользование недрами с целью разработки газовых и нефтяных месторождений",
          "В процессе разработки нефтяных и газовых месторождений по требованию органа, выдавшего лицензию на пользование недрами",
        ],
        correctAnswers: [
          "После предоставления лицензии на пользование недрами, разработки документов, подтверждающих согласование проектной документации, и получения положительного заключения государственной экспертизы",
        ],
      },
      {
        code: "20601052",
        text: "В какой системе координат должны быть определены уточненные границы горного отвода?",
        answers: [
          "В прямоугольной системе координат",
          "В географической системе координат (широта, долгота)",
          "В полярной системе координат (азимут, горизонтальное проложение)",
        ],
        correctAnswers: ["В прямоугольной системе координат"],
      },
      {
        code: "20601053",
        text: "Сколько экземпляров документации, удостоверяющей уточненные границы горного отвода, оформляется органом исполнительной власти субъекта Российской Федерации?",
        answers: [
          "3 экземпляра",
          "2 экземпляра",
          "5 экземпляров",
          "4 экземпляра",
        ],
        correctAnswers: ["3 экземпляра"],
      },
      {
        code: "20601054",
        text: "На какой срок оформляется документация, удостоверяющая уточненные границы горного отвода?",
        answers: [
          "На 2 года",
          "На 1 год",
          "На срок действия лицензии на пользование недрами",
          "Срок не ограничен",
        ],
        correctAnswers: ["На срок действия лицензии на пользование недрами"],
      },
      {
        code: "20601055",
        text: "Кем подписывается проект горного отвода (пояснительная записка)?",
        answers: [
          "Лицами, ответственными за руководство геологическими, маркшейдерскими работами пользователя недр",
          "Владельцем участка, содержащего месторождения полезных ископаемых, предоставляемого в пользование",
          "Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода",
          "Руководителем сторонней организации, проектирующей горные работы",
        ],
        correctAnswers: [
          "Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода",
        ],
      },
      {
        code: "20601056",
        text: "В какой срок со дня поступления заявления и проекта горного отвода осуществляется оформление документации либо принятие мотивированного решения об отказе?",
        answers: [
          "Не позднее 14 дней",
          "Не позднее 30 дней",
          "Не позднее 25 дней",
          "Не позднее 7 дней",
        ],
        correctAnswers: ["Не позднее 25 дней"],
      },
      {
        code: "20601057",
        text: "В каком случае при необходимости внесения изменений в уточненные границы горного отвода документация не подлежит переоформлению?",
        answers: [
          "В случае переоформления лицензии на пользование недрами",
          "В случае изменения срока пользования участком недр, установленного в лицензии на пользование недрами",
          "В случае изменений технического проекта выполнения работ, связанных с пользованием недрами, не влияющих на безопасное состояние недр, земной поверхности и расположенных на ней объектов",
          "В случае выявления ошибок, несоответствий в лицензии на пользование недрами или в документации к ней",
        ],
        correctAnswers: [
          "В случае изменений технического проекта выполнения работ, связанных с пользованием недрами, не влияющих на безопасное состояние недр, земной поверхности и расположенных на ней объектов",
        ],
      },
      {
        code: "20601058",
        text: "В какой срок органом исполнительной власти субъекта Российской Федерации оформленная документация, удостоверяющая уточненные границы горного отвода, передается пользователю недр?",
        answers: [
          "Не позднее 7 дней со дня оформления",
          "Не позднее 10 дней со дня оформления",
          "Не позднее 5 дней со дня оформления",
          "Не позднее 14 дней со дня оформления",
        ],
        correctAnswers: ["Не позднее 5 дней со дня оформления"],
      },
      {
        code: "20601059",
        text: "Кем оформляется документация, удостоверяющая уточненные границы горного отвода, если участки недр местного значения расположены на территориях 2 и более субъектов Российской Федерации и если нормативными правовыми актами субъекта Российской Федерации не определен орган исполнительной власти субъекта Российской Федерации?",
        answers: [
          "Органами исполнительной власти субъектов Российской Федерации",
          "Органом государственного горного надзора",
          "Региональным органом государственного экологического надзора",
        ],
        correctAnswers: ["Органом государственного горного надзора"],
      },
      {
        code: "20601060",
        text: "Что устанавливают требования Инструкции о порядке утверждения мер охраны зданий, сооружений и природных объектов от вредного влияния горных разработок?",
        answers: [
          "Меры охраны, предусматривающие оставление предохранительных целиков под подрабатываемыми объектами",
          "Меры охраны, применяемые в порядке опытно-промышленных испытаний",
          "Меры, выбранные в соответствии с правилами охраны сооружений и природных объектов от вредного влияния подземных разработок на месторождениях с недостаточно изученным характером процесса сдвижения горных пород",
          "Порядок согласования и утверждения мер охраны зданий, сооружений и природных объектов от вредного влияния горных разработок, обусловленного сдвижением и деформацией горных пород и земной поверхности, получения разрешений на проходку подготовительных выработок в предохранительных целиках и на ведение в них очистных работ",
        ],
        correctAnswers: [
          "Порядок согласования и утверждения мер охраны зданий, сооружений и природных объектов от вредного влияния горных разработок, обусловленного сдвижением и деформацией горных пород и земной поверхности, получения разрешений на проходку подготовительных выработок в предохранительных целиках и на ведение в них очистных работ",
        ],
      },
      {
        code: "20601061",
        text: "За какой срок до начала подработки охраняемых объектов горные и строительные меры охраны от вредного влияния горных разработок должны быть согласованы и утверждены?",
        answers: [
          "За один месяц",
          "За пять месяцев",
          "За шесть месяцев",
          "За два месяца",
        ],
        correctAnswers: ["За шесть месяцев"],
      },
      {
        code: "20601062",
        text: "В течение какого срока передаются материалы по мерам охраны объектов от вредного влияния горных разработок в Ростехнадзор при несогласии заинтересованной организации с решением по ее возражению?",
        answers: [
          "В четырнадцатидневный срок",
          "В трехдневный срок",
          "В семидневный срок",
          "В десятидневный срок",
        ],
        correctAnswers: ["В десятидневный срок"],
      },
      {
        code: "20601063",
        text: "В какие сроки предприятие по добыче полезных ископаемых должно письменно уведомить (известить) организацию, эксплуатирующую подрабатываемый объект, или его владельца о начале ведения горных работ?",
        answers: [
          "Не позднее чем за 1 месяц до начала влияния горных работ на охраняемый объект",
          "Не позднее чем за 3 месяца до начала горных работ в зоне их влияния на охраняемый объект",
          "Не позднее чем за 6 месяцев до начала горных работ в зоне их влияния на охраняемый объект, а также о начале влияния горных работ на охраняемый объект",
          "Не менее чем за 6 месяцев до подхода зоны влияния очистных и подготовительных выработок к охраняемому объекту",
        ],
        correctAnswers: [
          "Не позднее чем за 6 месяцев до начала горных работ в зоне их влияния на охраняемый объект, а также о начале влияния горных работ на охраняемый объект",
        ],
      },
      {
        code: "20601064",
        text: "Что при ведении горных работ является объектами открытых горных работ?",
        answers: [
          "Карьеры, прииски, дражные полигоны, объекты кучного выщелачивания, породные, шлаковые отвалы и гидроотвалы",
          "Карьеры и объекты кучного выщелачивания",
          "Только объекты разработки горных отвалов",
          "Все объекты металлургических предприятий",
        ],
        correctAnswers: [
          "Карьеры, прииски, дражные полигоны, объекты кучного выщелачивания, породные, шлаковые отвалы и гидроотвалы",
        ],
      },
      {
        code: "20601065",
        text: "Какие из перечисленных мер охраны объектов допускаются, если другие меры охраны не могут гарантировать нормальную эксплуатацию охраняемого объекта или являются экономически нецелесообразными?",
        answers: [
          "Применение различных способов управления кровлей",
          "Выбор скорости подвигания очистного забоя",
          "Неполная выемка полезного ископаемого по площади",
          "Укрепление уступов, уменьшение углов откосов отвалов на карьерах",
          "Оставление предохранительного целика полезного ископаемого",
        ],
        correctAnswers: [
          "Оставление предохранительного целика полезного ископаемого",
        ],
      },
      {
        code: "20601066",
        text: "В какой орган пользователь недр должен ежемесячно представлять результаты наблюдений за процессами сдвижения горных пород и земной поверхности?",
        answers: [
          "В Росреестр",
          "В Роснедра",
          "В Ростехнадзор",
          "В Министерство природных ресурсов и экологии Российской Федерации",
        ],
        correctAnswers: ["В Ростехнадзор"],
      },
      {
        code: "20601067",
        text: "На какой максимальный срок составляются проекты пробной эксплуатации месторождения (залежи) и технологической схемы опытно-промышленной разработки залежей или участков залежей?",
        answers: ["1 год", "6 лет", "8 лет", "5 лет", "6 месяцев"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20601068",
        text: "В отношении каких действий проектная документация подлежит согласованию с комиссией при пользовании недрами для строительства и эксплуатации подземных сооружений, не связанных с добычей полезных ископаемых?",
        answers: [
          "Только в отношении строительства и эксплуатации различных хранилищ углеводородного сырья и продуктов его переработки в пластах горных пород",
          "Только в отношении размещения отходов производства и потребления",
          "Только в отношении захоронения токсичных, радиоактивных и других опасных отходов в глубоких горизонтах, обеспечивающих локализацию данных отходов",
          "Всего перечисленного",
        ],
        correctAnswers: ["Всего перечисленного"],
      },
      {
        code: "20601069",
        text: "На основе чего составляются планы и схемы развития горных работ?",
        answers: [
          "На основе плана производства горных работ",
          "На основе горно-графической документации",
          "На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами",
          "На основе инструкции по производству маркшейдерских работ",
        ],
        correctAnswers: [
          "На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами",
        ],
      },
      {
        code: "20601070",
        text: "Что должно входить в состав планов и схем развития горных работ?",
        answers: [
          "Информация о количественном составе рабочих горнодобывающего предприятия",
          "Графическая часть и пояснительная записка с табличными материалами",
          "Результаты расчетных нагрузок инженерно-геологических испытаний",
          "Санитарно-гигиенические показатели загрязнения почвы",
        ],
        correctAnswers: [
          "Графическая часть и пояснительная записка с табличными материалами",
        ],
      },
      {
        code: "20601071",
        text: "Что должны отображать графические материалы?",
        answers: [
          "Только границы участка недр, установленные при предоставлении лицензии на пользование недрами",
          "Только контуры запасов полезных ископаемых, числящихся на учете пользователя недр в государственном балансе запасов полезных ископаемых по состоянию на 1 января текущего года",
          "Только направления развития работ, связанных с пользованием недрами, в планируемом периоде (для месторождений твердых полезных ископаемых)",
          "Только ситуацию местности на площади горного отвода, обозначения линий вертикальных разрезов и проекций, поясняющие надписи, масштаб, координатную сетку",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20601072",
        text: "Из чего должны состоять графические материалы планов (схем) развития горных работ в зависимости от видов горных работ и видов полезных ископаемых?",
        answers: [
          "Из визуально-ландшафтного анализа в результате проведения геодезических работ",
          "Из эскизов участка месторождений полезных ископаемых",
          "Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем",
          "Из инженерно-технических схем",
        ],
        correctAnswers: [
          "Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем",
        ],
      },
      {
        code: "20601073",
        text: "Какие данные не должны указываться в штампе графических материалов планов развития горных работ?",
        answers: [
          "Основная надпись (штамп) с указанием наименования объекта недропользования (участка недр)",
          "Название, масштаб графического документа",
          "Подписи лиц (с указанием должности), ответственных за составление графических материалов",
          "Дата составления графических материалов",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20601074",
        text: "В каком масштабе должны составляться графические материалы для твердых полезных ископаемых?",
        answers: [
          "Не мельче 1 : 100 000",
          "Не мельче 1 : 25 000",
          "Не мельче 1 : 10 000",
          "Не мельче 1 : 50 000",
        ],
        correctAnswers: ["Не мельче 1 : 10 000"],
      },
      {
        code: "20601075",
        text: "В каком виде подготавливаются схемы развития горных работ в отношении вскрышных, подготовительных, рекультивационных работ, а также работ по добыче полезных ископаемых и связанной с ней первичной переработкой минерального сырья, содержащие графическую часть и пояснительную записку с табличными материалами?",
        answers: [
          "Только в виде схем эксплуатации объектов обустройства и схемы подготовки углеводородов (для месторождений углеводородного сырья)",
          "Только в виде схем эксплуатации объектов недропользования и их отдельных участков",
          "Только в виде схем эксплуатации объектов и водозаборных сооружений в отношении месторождений минеральных, теплоэнергетических (термальных), технических, промышленных вод",
          "Во всех перечисленных",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "20601076",
        text: "Кем устанавливается порядок подготовки, рассмотрения и согласования планов или схем развития горных работ по видам полезных ископаемых?",
        answers: [
          "Федеральной службой по надзору в сфере природопользования",
          "Правительством Российской Федерации",
          "Техническим руководителем маркшейдерской службы организации",
          "Руководителем организации (пользователя недр) либо лицом, исполняющим его обязанности",
        ],
        correctAnswers: ["Правительством Российской Федерации"],
      },
      {
        code: "20601077",
        text: "Кем утверждаются план и схема развития горных работ?",
        answers: [
          "Руководителем организации - пользователя недр",
          "Органом исполнительной власти субъекта Российской Федерации",
          "Федеральным государственным органом в области геодезии и картографии",
          "Органом государственного экологического контроля",
        ],
        correctAnswers: ["Руководителем организации - пользователя недр"],
      },
      {
        code: "20601078",
        text: "Какие мероприятия должны включаться в пояснительную записку планов развития горных работ?",
        answers: [
          "Только консервация и (или) ликвидация горных выработок, в том числе скважин, рекультивация нарушенных горными работами земель (при разработке месторождений твердых полезных ископаемых при их наличии)",
          "Только организация наблюдений за состоянием горных отводов",
          "Только достоверный учет количества добываемого минерального сырья в общем объеме горной массы, в том числе при маркшейдерском, весовом, метрологическом контроле (при разработке месторождений твердых полезных ископаемых)",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20601079",
        text: "Кем осуществляются подготовка и утверждение планов и схем развития горных работ?",
        answers: [
          "Подготовка - пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ, утверждение - руководителем организации - пользователя недр",
          "Подготовка - пользователем недр, утверждение - руководителем территориального органа государственного контроля за недропользованием",
          "Подготовка - организацией, привлекаемой пользователем недр, утверждение - руководителем территориального органа государственного горного надзора",
          "Подготовка - организацией, привлекаемой пользователем недр, утверждение - представителем местной администрации",
        ],
        correctAnswers: [
          "Подготовка - пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ, утверждение - руководителем организации - пользователя недр",
        ],
      },
      {
        code: "20601080",
        text: "Кто из перечисленных лиц подписывает титульный лист плана (схемы) развития горных работ?",
        answers: [
          "Представители территориального органа государственного горного надзора",
          "Лица, ответственные за организацию маркшейдерских работ",
          "Лица, ответственные за руководство горными, геологическими и маркшейдерскими работами пользователя недр, и (или) привлекаемые пользователем недр на договорной основе организации для осуществления работ, связанных с пользованием недрами",
          "Лица, ответственные за руководство геологическими работами",
        ],
        correctAnswers: [
          "Лица, ответственные за руководство горными, геологическими и маркшейдерскими работами пользователя недр, и (или) привлекаемые пользователем недр на договорной основе организации для осуществления работ, связанных с пользованием недрами",
        ],
      },
      {
        code: "20601081",
        text: "С кем необходимо согласовывать планы и схемы развития горных работ?",
        answers: [
          "С органами исполнительной власти субъекта Российской Федерации, в ведение которых входит участок горнодобычи",
          "Со сторонними организациями, расположенными на участке месторождений",
          "С территориальными органами Ростехнадзора",
          "С региональным подразделением Росприроднадзора",
        ],
        correctAnswers: ["С территориальными органами Ростехнадзора"],
      },
      {
        code: "20601082",
        text: "В какой срок планы и (или) схемы развития горных работ направляются пользователем недр в орган государственного горного надзора для рассмотрения?",
        answers: [
          "За 3 дня до назначенной даты рассмотрения планов и (или) схем развития горных работ",
          "В течение 10 дней с момента подготовки всей необходимой документации",
          "За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
          "За неделю до указанного дня рассмотрения планов и (или) схем развития горных работ",
        ],
        correctAnswers: [
          "За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
        ],
      },
      {
        code: "20601083",
        text: "В какой период осуществляется рассмотрение планов развития горных работ?",
        answers: [
          "В период с 1 по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
          "В период с 10 января по  31 марта текущего года в соответствии с графиком рассмотрения планов развития горных работ",
          "В период с 20 сентября по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
          "В период с 15 по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
        ],
        correctAnswers: [
          "В период с 20 сентября по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
        ],
      },
      {
        code: "20601084",
        text: "Кем должны быть подписаны планы (схемы) развития горных работ, направляемые пользователем недр в электронном виде?",
        answers: [
          "Специалистом управления геодезии и картографии",
          "Руководителем геологической проектной организации",
          "Техническим руководителем маркшейдерской службы",
          "Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом",
        ],
        correctAnswers: [
          "Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом",
        ],
      },
      {
        code: "20601085",
        text: "В отношении каких видов горных работ планы и схемы развития горных работ не составляются?",
        answers: [
          "Геологических работ",
          "Работ по обустройству транспортной структуры места проведения горных работ",
          "Работ, связанных с первичной переработкой минерального сырья",
          "Работ по добыче полезных ископаемых",
        ],
        correctAnswers: [
          "Работ по обустройству транспортной структуры места проведения горных работ",
        ],
      },
      {
        code: "20601086",
        text: "Какие параметры эксплуатации объектов ведения горных работ планами и схемами развития горных работ не определяются?",
        answers: [
          "Условия безопасного недропользования",
          "Штатная численность сотрудников объекта ведения горных работ",
          "Технологические решения при эксплуатации объектов ведения горных работ",
          "Объемы добычи полезных ископаемых",
        ],
        correctAnswers: [
          "Штатная численность сотрудников объекта ведения горных работ",
        ],
      },
      {
        code: "20601087",
        text: "На какой срок по решению пользователя недр составляется схема развития горных работ по одному или нескольким видам горных работ, предусмотренным планами и схемами развития горных работ?",
        answers: [
          "Не более 2 лет",
          "Не более 1 года",
          "Не более 5 лет",
          "Срок не ограничен",
        ],
        correctAnswers: ["Не более 5 лет"],
      },
      {
        code: "20601088",
        text: "В какой период, установленный Правилами подготовки, рассмотрения и согласования планов и схем развития горных работ по видам полезных ископаемых, осуществляется рассмотрение органами Ростехнадзора планов и схем развития горных работ?",
        answers: [
          "С 20 сентября по 25 декабря года, предшествующего планируемому",
          "С 1 по 31 декабря года, предшествующего планируемому",
          "С 10 января по 31 марта текущего года",
          "С 15 по 25 декабря года, предшествующего планируемому",
        ],
        correctAnswers: [
          "С 20 сентября по 25 декабря года, предшествующего планируемому",
        ],
      },
      {
        code: "20601089",
        text: "Что из перечисленного не является основанием для принятия решения об отказе в согласовании плана и (или) схемы развития горных работ?",
        answers: [
          "Несоблюдение требований законодательства в части предупреждения и устранения вредного влияния горных работ на население, окружающую среду, здания и сооружения",
          "Несоответствие планов и схем развития горных работ требованиям органов государственного горного надзора к их подготовке, содержанию и оформлению, а также выявление в них недостоверных сведений",
          "Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями",
          "Отсутствие геологического и маркшейдерского обеспечения горных работ, необходимого для достоверного учета параметров горных разработок и прогнозирования опасных ситуаций",
        ],
        correctAnswers: [
          "Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями",
        ],
      },
      {
        code: "20601090",
        text: "Из чего состоит проект буровзрывных (взрывных) работ (проект массового взрыва) для конкретных условий?",
        answers: [
          "Из распорядка проведения массового взрыва",
          "Из технического расчета со схемой расположения скважин и графическими материалами",
          "Из таблицы параметров взрывных работ",
          "Из всего перечисленного",
        ],
        correctAnswers: ["Из всего перечисленного"],
      },
      {
        code: "20601091",
        text: "В течение какого срока в организации хранятся проекты буровзрывных (взрывных) работ, паспорта, схемы, в соответствии с которыми осуществляются взрывные работы?",
        answers: [
          "Не менее 10 лет",
          "Не менее 5 лет",
          "Не менее года",
          "Не менее 2 лет",
        ],
        correctAnswers: ["Не менее года"],
      },
      {
        code: "20601092",
        text: "Какие из перечисленных требований к оформлению проектной документации на разработку месторождений углеводородного сырья указаны верно?",
        answers: [
          "Проектная документация выполняется на обеих сторонах листа бумаги формата АЗ",
          "Страницы текста проектной документации должны соответствовать формату листа A4, для таблиц допускается формат A3",
          "Проектная документация оформляется только в электронном виде",
          "Высота строчных букв должна составлять 14 мм",
        ],
        correctAnswers: [
          "Страницы текста проектной документации должны соответствовать формату листа A4, для таблиц допускается формат A3",
        ],
      },
      {
        code: "20601093",
        text: "Какие из перечисленных сведений должен содержать титульный лист проектной документации на разработку месторождений углеводородного сырья? Укажите все правильные ответы.",
        answers: [
          "Тип месторождения",
          "Наименование месторождения",
          "Номер страницы",
          "Полные реквизиты исполнителя проектной документации",
          "Наименование территориального надзорного органа",
        ],
        correctAnswers: ["Тип месторождения", "Наименование месторождения"],
      },
      {
        code: "20601094",
        text: "Какое из перечисленных требований к оформлению таблиц в проектной документации на разработку месторождений углеводородного сырья соответствует Требованиям к структуре и оформлению проектной документации на разработку месторождений углеводородного сырья?",
        answers: [
          "Таблицы, расположенные на отдельных листах, не входят в общую нумерацию страниц отчета",
          "Название таблицы должно размещаться над таблицей в центре",
          "Таблицы, за исключением таблиц приложений, нумеруются арабскими цифрами сквозной нумерацией",
          "Нумерация таблиц в пределах раздела не требуется",
        ],
        correctAnswers: [
          "Таблицы, за исключением таблиц приложений, нумеруются арабскими цифрами сквозной нумерацией",
        ],
      },
      {
        code: "20601095",
        text: "Каким образом допускается исправлять опечатки, описки и графические неточности, обнаруженные в процессе подготовки проектного документа на разработку месторождений углеводородного сырья?",
        answers: [
          "Подчисткой или закрашиванием белой краской и нанесением на том же месте исправленного текста (графики)",
          "Путем составления акта, прилагающегося к проектной документации",
          "Исправление ошибок не допускается ни в каком случае",
          "Внесением текста (графики) рядом с опечаткой, заверенного подписью лица, внесшего правки",
        ],
        correctAnswers: [
          "Подчисткой или закрашиванием белой краской и нанесением на том же месте исправленного текста (графики)",
        ],
      },
      {
        code: "20601096",
        text: "Какое из перечисленных требований к оформлению проектной документации на разработку месторождений углеводородного сырья указано неверно?",
        answers: [
          "Проектная документация должна содержать все данные, позволяющие производить анализ проектных решений без личного участия авторов",
          "Титульный лист проектного документа включается в общую нумерацию страниц отчета",
          "Если проектная документация состоит из 2 и более частей (томов), то каждая часть (том) должна иметь свой титульный лист",
          "Номер страницы на титульном листе проставляется арабскими цифрами",
        ],
        correctAnswers: [
          "Номер страницы на титульном листе проставляется арабскими цифрами",
        ],
      },
      {
        code: "20601097",
        text: "Какие из перечисленных требований к оформлению сведений об использованных источниках в проектной документации на разработку месторождений углеводородного сырья указаны верно?",
        answers: [
          "Ссылки об использованных источниках печатаются с абзацного отступа",
          "Сведения располагаются в порядке появления ссылок на источники в тексте отчета",
          "Ссылки на источники нумеруются арабскими цифрами без точки",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20601098",
        text: "Кем утверждается проведение горных работ в пределах предохранительных целиков, предусмотренное проектами на строительство, расширение и реконструкцию предприятий по добыче полезных ископаемых?",
        answers: [
          "Руководителем проектной организации",
          "Организацией, выполняющей геодезические работы",
          "Пользователем недр",
          "Ростехнадзором",
        ],
        correctAnswers: ["Пользователем недр"],
      },
      {
        code: "20601099",
        text: "Требованиями к структуре проектной документации по первичной переработке минерального сырья являются:",
        answers: [
          "Только проектная мощность предприятия по первичной переработке минерального сырья",
          "Только мероприятия по использованию отходов обогащения полезных ископаемых, их размещение, складирование и сохранение",
          "Только качественная характеристика минерального сырья, поступающего на первичную переработку, сведения о сырьевой базе и потребителях продуктов обогащения",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20601100",
        text: "В соответствии с какими документами должна устанавливаться скорость изменения технологических параметров?",
        answers: [
          "В соответствии со схемами развития горных работ",
          "В соответствии с технологическим регламентом и заводскими инструкциями по эксплуатации оборудования",
          "В соответствии с планами проведения работ, утвержденными пользователями недр",
          "В соответствии с нормами и правилами промышленной безопасности при ведении горных работ",
          "В соответствии с нарядами-допусками на производство работ",
        ],
        correctAnswers: [
          "В соответствии с технологическим регламентом и заводскими инструкциями по эксплуатации оборудования",
        ],
      },
      {
        code: "20601101",
        text: "Каким образом осуществляется пересылка документации, содержащей сведения, представляющие государственную тайну?",
        answers: [
          "Доставкой курьером по адресу организации",
          "Заказным письмом с уведомлением и описью вложения через почтовую службу",
          "В соответствии с законодательством Российской Федерации о государственной тайне",
          "Отправкой бандероли с объявленной ценностью, опломбированной печатью отправителя",
        ],
        correctAnswers: [
          "В соответствии с законодательством Российской Федерации о государственной тайне",
        ],
      },
      {
        code: "20601102",
        text: "Как часто должны проходить медицинский осмотр рабочие, выполняющие работы повышенной опасности, перечень которых установлен руководителем организации?",
        answers: [
          "Каждый раз перед началом смены",
          "Не реже одного раза в год",
          "Не реже одного раза в месяц",
          "Не реже одного раза в полугодие",
        ],
        correctAnswers: ["Каждый раз перед началом смены"],
      },
      {
        code: "20601103",
        text: "Организации, эксплуатирующие объекты, на которых ведутся горные работы и переработка полезных ископаемых, обязаны осуществлять:",
        answers: [
          "Геодезическое обеспечение горных работ",
          "Маркшейдерское обеспечение работ",
          "Инженерное обеспечение открытых горных работ",
        ],
        correctAnswers: ["Маркшейдерское обеспечение работ"],
      },
      {
        code: "20601104",
        text: "В каких случаях главный маркшейдер шахты определяет подрабатываемый участок земной поверхности, устанавливает порядок контроля образования провалов и организовывает контроль?",
        answers: [
          "При глубине залегания угольных пластов не более 100 м",
          "До начала осуществления гидравлической добычи угля",
          "До начала отработки угольных пластов",
          "При отработке свиты пластов подземным способом",
        ],
        correctAnswers: ["До начала отработки угольных пластов"],
      },
      {
        code: "20601105",
        text: "Какие наблюдения должны проводиться маркшейдерской службой шахты в период подготовки блока к выщелачиванию?",
        answers: [
          "Наблюдения за состоянием откосов, уступов и бортов карьера",
          "Наблюдения и контроль за состоянием подземных и поверхностных вод",
          "Наблюдения за состоянием горных отводов и обоснование их границ",
          "Систематические наблюдения за сдвижением и деформацией пород",
        ],
        correctAnswers: [
          "Систематические наблюдения за сдвижением и деформацией пород",
        ],
      },
      {
        code: "20601106",
        text: "С какой периодичностью служба главного маркшейдера шахты проводит проверку износа рельсов откаточных путей?",
        answers: [
          "Не реже одного раза в полугодие",
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в год",
          "Не реже одного раза в 2 года",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20601107",
        text: "При каком минимальном расстоянии до опасной зоны участковый маркшейдер должен делать замеры и оповещения о размерах целика после каждой заходки главному инженеру и начальнику участка?",
        answers: [
          "За 8,5 м до опасной зоны",
          "За 7 м до опасной зоны",
          "За 12 м до опасной зоны",
          "За 10 м до опасной зоны",
        ],
        correctAnswers: ["За 7 м до опасной зоны"],
      },
      {
        code: "20601108",
        text: "Разрешается ли одновременное производство работ в наклонных выработках на различных отметках?",
        answers: [
          "Разрешается по согласованию с надзорным органом",
          "Разрешается при отметке в наряде на производство работ",
          "Запрещается",
        ],
        correctAnswers: ["Запрещается"],
      },
      {
        code: "20601109",
        text: "При каком различии отметок участков протяженных горизонтальных и наклонных горных выработок расчетная глубина для них принимается равной максимальной глубине?",
        answers: ["До 20 %", "До 15 %", "Более 23 %", "Более 30 %"],
        correctAnswers: ["До 15 %"],
      },
      {
        code: "20601110",
        text: "От чего зависит надежность поддержания горных выработок в нижнем слое?",
        answers: [
          "От трещиноватости угля",
          "От мощности межслоевой угольной пачки, оставляемой между штреками верхнего и нижнего слоя",
          "От прочности угля",
          "От всего перечисленного",
        ],
        correctAnswers: ["От всего перечисленного"],
      },
      {
        code: "20601111",
        text: "При каком размере целика между встречными забоями работы должны проводиться только из одного забоя?",
        answers: ["1 м", "5 м", "3 м", "7 м"],
        correctAnswers: ["7 м"],
      },
      {
        code: "20601112",
        text: "За какой период времени до начала производства работ, связанных со строительством подземных сооружений, необходимо уведомить об этом органы Ростехнадзора?",
        answers: ["За 5 дней", "За 10 дней", "За 15 дней", "За 1 месяц"],
        correctAnswers: ["За 15 дней"],
      },
      {
        code: "20601113",
        text: "Какое из перечисленных требований для получения разрешения на выемку запасов из предохранительного целика в случае, когда надобность в охране объекта миновала, указано верно?",
        answers: [
          "Предприятие по добыче полезных ископаемых в течение 14 дней до начала очистных работ в предохранительном целике обязано представить в надзорный орган заявление о выдаче разрешения на ведение горных работ",
          "Заявление о выдаче разрешения с приложением проекта ведения горных работ в предохранительном целике, а также пояснительная записка к нему представляются в Министерство природных ресурсов и экологии Российской Федерации в 3-дневный срок от начала горных работ",
          "В пояснительной записке к проекту должна быть указана причина утраты надобности в охране объекта, приведены способы, сроки и порядок выемки предохранительного целика",
          "Заявление о выдаче разрешения на выемку запасов из предохранительного целика должно составляться в 3 экземплярах",
        ],
        correctAnswers: [
          "В пояснительной записке к проекту должна быть указана причина утраты надобности в охране объекта, приведены способы, сроки и порядок выемки предохранительного целика",
        ],
      },
      {
        code: "20601114",
        text: "Кем могут проводиться наблюдения за процессом сдвижения земной поверхности (основания сооружения) под влиянием горных работ и за охраняемыми поверхностными и подземными объектами?",
        answers: [
          "Только маркшейдерской службой предприятия",
          "Только специализированными организациями, привлекаемыми предприятиями по добыче полезных ископаемых",
          "Только предприятиями, эксплуатирующими охраняемые объекты",
          "Всеми перечисленными",
        ],
        correctAnswers: ["Всеми перечисленными"],
      },
      {
        code: "20601115",
        text: "Какое из перечисленных требований к отработке пластов угля, склонных к самовозгоранию, указано неверно?",
        answers: [
          "Места и размеры целиков угля должны быть определены техническим проектом и (или) проектной документацией",
          "Отработку крутых и крутонаклонных пластов угля, склонных к самовозгоранию, ведут отдельными выемочными блоками с оставлением между ними противопожарных целиков, прорезаемых только на уровне откаточного и вентиляционного горизонтов",
          "При отработке пластов угля, склонных к самовозгоранию, оставляют в выработанном пространстве измельченный уголь",
          "Размер целика по простиранию равен мощности пласта, но не менее 6 м",
        ],
        correctAnswers: [
          "При отработке пластов угля, склонных к самовозгоранию, оставляют в выработанном пространстве измельченный уголь",
        ],
      },
      {
        code: "20601116",
        text: "Какое из перечисленных требований к порядку согласования и утверждения мер охраны объектов от вредного влияния горных разработок указано верно?",
        answers: [
          "Одной из мер охраны объектов от вредного влияния горных разработок является выданное органом Ростехнадзора разрешение на застройку площадей залегания полезных ископаемых",
          "Меры охраны объектов, построенных ранее в пределах горных отводов без строительных мер защиты, утверждаются собственниками участков недр",
          "Меры охраны объектов, представляемые на согласование и утверждение, составляются в 4 экземплярах и содержат следующие материалы: пояснительную записку с обоснованием выбранных мер охраны",
          "Меры охраны, выбранные проектной организацией, подписываются главным геологом этого предприятия",
        ],
        correctAnswers: [
          "Меры охраны объектов, представляемые на согласование и утверждение, составляются в 4 экземплярах и содержат следующие материалы: пояснительную записку с обоснованием выбранных мер охраны",
        ],
      },
      {
        code: "20601117",
        text: "Кто принимает решение о разработке оперативных планов по локализации и ликвидации последствий аварий?",
        answers: [
          "Главный маркшейдер шахты",
          "Руководитель работ по ликвидации аварии",
          "Представитель территориального органа Ростехнадзора",
          "Руководитель горнодобывающей организации",
        ],
        correctAnswers: ["Руководитель работ по ликвидации аварии"],
      },
      {
        code: "20601118",
        text: "В течение какого времени главный инженер шахты организует проверку состава, температуры, влажности рудничной атмосферы и расхода воздуха ИТР шахты и работниками ПАСС(Ф) после разгазирования вскрытых горных выработок?",
        answers: [
          "В течение двух суток",
          "В течение 12 часов",
          "В течение суток",
          "В течение не менее трех суток",
        ],
        correctAnswers: ["В течение не менее трех суток"],
      },
      {
        code: "20601119",
        text: "Кому должен сообщать работник при обнаружении опасности, угрожающей людям, производственным объектам?",
        answers: [
          "Техническому руководителю смены",
          "Заместителю главного маркшейдера",
          "Главному инженеру шахты",
          "Руководителю предприятия",
        ],
        correctAnswers: ["Техническому руководителю смены"],
      },
      {
        code: "20601120",
        text: "Куда должны быть занесены машинистом погрузочной техники время обнаружения отказа, принятые меры безопасности, а также данные о том, кому сообщено об обнаружении отказа?",
        answers: [
          "В журнал регистрации отказов при взрывных работах",
          "В журнал ознакомления должностных лиц и рабочих, связанных с подготовкой и производством взрывных работ",
          "В журнал приема-сдачи смен",
          "В журнал замеров электрического сопротивления горных пород",
        ],
        correctAnswers: ["В журнал приема-сдачи смен"],
      },
      {
        code: "20601121",
        text: "В каком случае разрешаются включение и работа подземной передвижной компрессорной установки?",
        answers: [
          "При содержании метана более 1,0 % в месте расположения установки",
          "При неисправности регулятора производительности, предохранительных клапанов, манометров и термометров",
          "При отсутствии тепловой защиты",
          "При обратном вращении винтов компрессора",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20601122",
        text: "Разрешается ли ведение горных работ вблизи затопленных выработок или поверхностных водоемов?",
        answers: [
          "Разрешается по согласованию с собственником участка недр",
          "Разрешается по проекту, предусматривающему оставление целиков для предотвращения прорыва воды",
          "Не разрешается",
        ],
        correctAnswers: [
          "Разрешается по проекту, предусматривающему оставление целиков для предотвращения прорыва воды",
        ],
      },
      {
        code: "20601123",
        text: "На содержание каких веществ должны анализироваться пробы воздуха, набираемые в непроветриваемой части затопленных выработок (при откачке)?",
        answers: [
          "Только на CO, CO",
          "Только на  HS",
          "CO, CO , CH , HS, O и H",
          "Только на  O и H",
        ],
        correctAnswers: ["CO, CO , CH , HS, O и H"],
      },
      {
        code: "20601124",
        text: "Кому должны сообщить люди после выхода из горных выработок, проводимых в границах опасных зон, о признаках возможного прорыва воды?",
        answers: [
          "Главному инженеру маркшейдерской службы",
          "Горному диспетчеру",
          "Начальнику участка работ",
          "Руководителю организации",
          "Всем перечисленным лицам",
        ],
        correctAnswers: ["Горному диспетчеру"],
      },
      {
        code: "20601125",
        text: "В каком из перечисленных документов должны быть определены границы участков, опасных по прорыву воды (опасные зоны)?",
        answers: [
          "В топографических планах (картах)",
          "В геологических и структурных картах",
          "В техническом проекте и (или) документации по ведению горных работ на участках",
          "В планах и (или) схемах развития горных работ",
        ],
        correctAnswers: [
          "В техническом проекте и (или) документации по ведению горных работ на участках",
        ],
      },
      {
        code: "20601126",
        text: "Когда должны приниматься меры по обезвоживанию глины и выпуску воды при наличии в горных выработках подрабатываемого участка воды или жидкой глины?",
        answers: [
          "По завершении очистных работ",
          "До начала очистных работ",
          "Во время проведения очистных работ",
        ],
        correctAnswers: ["До начала очистных работ"],
      },
      {
        code: "20601127",
        text: "Чем и в зависимости от чего устанавливается скорость движения поездов на железнодорожных путях объекта открытых горных работ?",
        answers: [
          "Скорость движения устанавливается проектом в зависимости от дальности расстояния транспортировки вскрышных пород  и объема добытого полезного ископаемого",
          "Скорость движения устанавливается техническим регламентом в зависимости от местных условий и производительности горнодобывающего предприятия",
          "Скорость движения устанавливается внутренней инструкцией по эксплуатации железнодорожного транспорта организации в зависимости от применяемого подвижного состава, верхнего строения и профиля пути, а также местных условий",
        ],
        correctAnswers: [
          "Скорость движения устанавливается внутренней инструкцией по эксплуатации железнодорожного транспорта организации в зависимости от применяемого подвижного состава, верхнего строения и профиля пути, а также местных условий",
        ],
      },
      {
        code: "20601128",
        text: "Какой должна быть допустимая скорость движения автомобилей, автомобильных и тракторных поездов на технологических дорогах карьера?",
        answers: [
          "40 км/ч",
          "Не более 25 км/ч",
          "Скорость устанавливается техническим руководителем организации",
          "30 км/ч",
        ],
        correctAnswers: [
          "Скорость устанавливается техническим руководителем организации",
        ],
      },
      {
        code: "20601129",
        text: "Кем устанавливаются размеры призмы обрушения (сползания) породы при отвалообразовании, в пределах которой не должны разгружаться автомобили и другие транспортные средства?",
        answers: [
          "Работниками маркшейдерской службы",
          "Ответственным лицом по выпуску транспорта",
          "Техническим руководителем организации",
          "Главным инженером промплощадки",
        ],
        correctAnswers: ["Работниками маркшейдерской службы"],
      },
      {
        code: "20601130",
        text: "Прокладка каких труб и кабелей допускается в производственных зданиях (помещениях), где ведутся работы с окислителями или их растворами?",
        answers: [
          "Кабелей с открытой свинцовой оболочкой",
          "Бронированных кабелей с оцинкованной броней",
          "Пластмассовых импульсных труб",
          "Медных импульсных труб",
        ],
        correctAnswers: ["Пластмассовых импульсных труб"],
      },
      {
        code: "20601131",
        text: "С какой периодичностью проводится измерение параметров электризации в условиях производства?",
        answers: [
          "Не реже одного раза в год",
          "Не реже двух раз в год",
          "Не реже двух раз в месяц",
          "Не реже двух раз в полугодие",
        ],
        correctAnswers: ["Не реже двух раз в год"],
      },
      {
        code: "20601132",
        text: "Что из перечисленного не подлежит заземлению в шахтах?",
        answers: [
          "Пневматические вентиляторы",
          "Одиночные металлические воздухопроводы",
          "Трубопроводы, расположенные в горных выработках, где имеются электрические установки",
          "Металлическая крепь, пожарооросительный трубопровод, нетоковедущие рельсы, металлические устройства для подвески кабеля",
        ],
        correctAnswers: [
          "Металлическая крепь, пожарооросительный трубопровод, нетоковедущие рельсы, металлические устройства для подвески кабеля",
        ],
      },
      {
        code: "20601133",
        text: "Какие требования предъявляются к заземлению кондуктора (технической колонны) и рамы станка-качалки?",
        answers: [
          "Сечение прямоугольного проводника должно быть не менее 48 мм², толщина стенок угловой стали - не менее 4 мм, диаметр круглых заземлителей - не менее 10 мм",
          "В качестве заземляющих проводников может применяться сталь: круглая, полосовая, угловая или другого профиля",
          "Заземляющие проводники, соединяющие раму станка-качалки с кондуктором (технической колонной), должны быть заглублены в землю не менее чем на 0,5 м",
          "Кондуктор и рама станка-качалки должны быть связаны не менее чем двумя заземляющими стальными проводниками, приваренными в разных местах к кондуктору и раме",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20601134",
        text: "Какое требование относится к хранилищам, имеющим рампы и средства механизации погрузочно-разгрузочных работ?",
        answers: [
          "Необходимо оборудовать не менее трех тамбуров",
          "Устройство тамбуров не обязательно, но вход следует оборудовать не менее чем двумя двустворчатыми дверями",
          "Вход в каждый из тамбуров должен быть оборудован двумя двустворчатыми дверями",
          "Вторая дверь в тамбурах может быть решетчатой, деревянной или металлической",
        ],
        correctAnswers: [
          "Устройство тамбуров не обязательно, но вход следует оборудовать не менее чем двумя двустворчатыми дверями",
        ],
      },
      {
        code: "20601135",
        text: "Какое из перечисленных требований к поверхностным постоянным складам указано неверно?",
        answers: [
          "Склады должны иметь запретную зону шириной от ограждения не менее 50 м",
          "На границах запретной зоны постоянных складов должны устанавливаться предупредительные знаки",
          "Расстояния между отдельными хранилищами, между иными зданиями и сооружениями на территории склада, а также до объектов за территорией должны быть установлены техническим руководителем организации",
          "Хранилища должны располагаться так, чтобы обеспечивался свободный подъезд к каждому из них",
        ],
        correctAnswers: [
          "Расстояния между отдельными хранилищами, между иными зданиями и сооружениями на территории склада, а также до объектов за территорией должны быть установлены техническим руководителем организации",
        ],
      },
      {
        code: "20601136",
        text: "На какое расстояние при одновременной отработке нескольких подэтажей системой подэтажного обрушения каждый верхний подэтаж должен опережать нижний?",
        answers: [
          "На расстояние не менее чем на длину, равную высоте двух подэтажей",
          "На расстояние, установленное проектом и исключающее обрушение верхнего подэтажа на нижний",
          "На расстояние, устанавливаемое проектом, но не менее чем на длину, равную высоте одного подэтажа",
        ],
        correctAnswers: [
          "На расстояние, устанавливаемое проектом, но не менее чем на длину, равную высоте одного подэтажа",
        ],
      },
      {
        code: "20601137",
        text: "Разработку каких рудных тел необходимо производить преимущественно системой одностадийного этажного или подэтажного принудительного обрушения с отбойкой руды на зажатую среду?",
        answers: [
          "Маломощных рудных тел",
          "Крутопадающих мощных рудных тел",
          "Наклонных рудных тел",
          "Складчатых, пологих рудных тел",
        ],
        correctAnswers: ["Крутопадающих мощных рудных тел"],
      },
      {
        code: "20601138",
        text: "На каком расстоянии устанавливаются основной и вспомогательный пульты управления превенторами и гидравлическими задвижками?",
        answers: [
          "Основной пульт управления - на расстоянии не менее 2 м от устья скважины, вспомогательный - непосредственно возле превентора",
          "Основной пульт управления - на расстоянии не менее 1 м от устья скважины, вспомогательный - на расстоянии не менее 0, 5 м от пульта бурильщика",
          "Основной пульт управления - на расстоянии не менее 6 м от устья скважины в любом безопасном месте, вспомогательный - непосредственно возле бурового оборудования",
          "Основной пульт управления - на расстоянии не менее 10 м от устья скважины в удобном и безопасном месте, вспомогательный - непосредственно возле пульта бурильщика",
        ],
        correctAnswers: [
          "Основной пульт управления - на расстоянии не менее 10 м от устья скважины в удобном и безопасном месте, вспомогательный - непосредственно возле пульта бурильщика",
        ],
      },
      {
        code: "20601139",
        text: "В каком случае допускается использовать для освещения в углубленном складе индивидуальные рудничные аккумуляторные светильники при отсутствии стационарных источников электроэнергии?",
        answers: [
          "По решению работника склада при отсутствии других источников освещения",
          "При толще грунта менее 8 м над углубленным складом",
          "Не допускается ни в каком случае",
          "По разрешению руководителя (технического руководителя) организации",
        ],
        correctAnswers: [
          "По разрешению руководителя (технического руководителя) организации",
        ],
      },
      {
        code: "20601140",
        text: "К какой основной схеме подготовки выемочных участков относится проведение спаренных горных выработок в зоне и впереди зоны влияния очистных работ при подготовке выемочных столбов спаренными горными выработками?",
        answers: ["К третьей", "К первой", "Ко второй", "К четвертой"],
        correctAnswers: ["Ко второй"],
      },
      {
        code: "20601141",
        text: "Сколько должно быть выходов из очистной горной выработки с длинным очистным забоем и очистной горной выработки с коротким очистным забоем, тупиковая часть которой более 30 м, в оконтуривающие выемочный участок горные выработки?",
        answers: [
          "Правилами не регламентируется",
          "Не менее двух выходов",
          "Один выход",
          "Не более 1 - 2 выходов",
        ],
        correctAnswers: ["Не менее двух выходов"],
      },
      {
        code: "20601142",
        text: "В какой срок после начала проведения горных выработок, оконтуривающих выемочный участок, определяется фрикционная опасность горных пород для каждого выемочного участка?",
        answers: [
          "Не более одного месяца",
          "Не более одной недели",
          "В течение первых двух недель",
          "Не более двух месяцев",
        ],
        correctAnswers: ["Не более одного месяца"],
      },
      {
        code: "20601143",
        text: "С какой периодичностью вносятся записи в журнал по контролю за состоянием выработки и показаниями реперных станций вне зоны опорного давления?",
        answers: [
          "1 раз в смену",
          "1 раз в месяц",
          "2 раза в неделю",
          "1 раз в две недели",
        ],
        correctAnswers: ["1 раз в месяц"],
      },
      {
        code: "20601144",
        text: "Когда на руднике допускается проходка выработок без крепления?",
        answers: [
          "Допускается проходка только горизонтальных или наклонных подземных выработок",
          "Допускается проходка горных выработок в неустойчивых породах",
          "Допускается проходка горных выработок в устойчивых породах, а также в случае, если технологический срок службы выработки не превышает времени ее устойчивого состояния",
          "Допускается проходка горных выработок, если технологический срок службы выработки менее 5 лет",
        ],
        correctAnswers: [
          "Допускается проходка горных выработок в устойчивых породах, а также в случае, если технологический срок службы выработки не превышает времени ее устойчивого состояния",
        ],
      },
      {
        code: "20601145",
        text: "Кем осуществляется надзор за ходом производства буровых работ, качеством выполнения этих работ, уровнем технологических процессов и операций, качеством используемых материалов и технических средств, соблюдением безопасных условий труда?",
        answers: [
          "Проектной организацией",
          "Ответственным лицом надзорного органа",
          "Собственником недр",
          "Пользователем недр (заказчиком), организацией, осуществляющей производство буровых работ, и другими субъектами хозяйственной деятельности, уполномоченными пользователем недр",
        ],
        correctAnswers: [
          "Пользователем недр (заказчиком), организацией, осуществляющей производство буровых работ, и другими субъектами хозяйственной деятельности, уполномоченными пользователем недр",
        ],
      },
      {
        code: "20601146",
        text: "Какую площадь должен иметь размер земельного участка для производства буровых работ?",
        answers: [
          "Равную 1 000 м²",
          "Обеспечивающую соблюдение требований промышленной безопасности",
          "0,1 га",
          "Не превышающую 1 га",
        ],
        correctAnswers: [
          "Обеспечивающую соблюдение требований промышленной безопасности",
        ],
      },
      {
        code: "20601147",
        text: "Каким образом должно осуществляться передвижение людей по территории объектов ведения горных работ и переработке полезных ископаемых?",
        answers: [
          "Передвижение людей по территории объекта открытых горных работ допускается по специально устроенным пешеходным дорожкам или по обочинам автодорог навстречу направлению движения автотранспорта",
          "Передвижение людей по территории объекта открытых горных работ допускается только по специально выделенному маршруту",
          "Пути передвижения по территории объекта открытых горных работ определяют сами работающие на объекте, соблюдая меры безопасности движения",
          "Пешеходное движение по территории объекта открытых горных работ допускается только по направлению движения автотранспорта",
        ],
        correctAnswers: [
          "Передвижение людей по территории объекта открытых горных работ допускается по специально устроенным пешеходным дорожкам или по обочинам автодорог навстречу направлению движения автотранспорта",
        ],
      },
      {
        code: "20601148",
        text: "При каком условии допускается размещение отвалов на площадях месторождений, подлежащих отработке открытым способом?",
        answers: [
          "Допускается при условии, что земельный участок для ведения работ, связанных с пользованием недрами, находится в государственной или муниципальной собственности",
          "Допускается при указании места размещения отвалов в проекте документации для проведения работ по недропользованию",
          "Допускается по согласованию с органами Ростехнадзора",
          "Запрещается размещение отвалов на площадях месторождений, подлежащих разработке открытым способом",
        ],
        correctAnswers: [
          "Запрещается размещение отвалов на площадях месторождений, подлежащих разработке открытым способом",
        ],
      },
      {
        code: "20601149",
        text: "Каким образом проводится одновременная разработка месторождения подземным и открытым способами?",
        answers: [
          "Должен быть составлен согласованный с аварийно-спасательной службой график работ по разработке месторождения",
          "Должны быть проведены совместные мероприятия по обеспечению безопасности работающих на объектах ведения подземных и открытых горных работ, утвержденные техническим руководителем организации",
          "В составе сменного технического надзора должно быть не менее трех сотрудников, осуществляющих допуск работников к выполнению работ",
        ],
        correctAnswers: [
          "Должны быть проведены совместные мероприятия по обеспечению безопасности работающих на объектах ведения подземных и открытых горных работ, утвержденные техническим руководителем организации",
        ],
      },
      {
        code: "20601150",
        text: "В какие сроки изолируются отработанные участки?",
        answers: [
          "В течение одного месяца",
          "Определенные техническим проектом и (или) проектной документацией",
          "В течение двух рабочих недель",
          "В течение рабочей смены",
        ],
        correctAnswers: [
          "Определенные техническим проектом и (или) проектной документацией",
        ],
      },
      {
        code: "20601151",
        text: "Какова допустимая высота падения груза из ковша экскаватора в кузов автосамосвала?",
        answers: [
          "Не должна превышать 5 м",
          "Высота падения груза определяется паспортом автосамосвала и зависит от его грузоподъемности",
          "Высота падения груза должна быть минимально возможной и во всех случаях не должна превышать 3 м",
          "Должна соответствовать 3,5 м",
        ],
        correctAnswers: [
          "Высота падения груза должна быть минимально возможной и во всех случаях не должна превышать 3 м",
        ],
      },
      {
        code: "20601152",
        text: "Где устанавливается периодичность осмотров и инструментальных наблюдений за деформациями бортов, откосов, уступов и отвалов, а также объектов, попадающих в зоны влияния горных работ и расположенных на земной поверхности?",
        answers: [
          "В проекте производства маркшейдерских работ",
          "В журнале горной выработки",
          "В плане развития горных работ",
          "В журнале опытных гидрогеологических работ",
        ],
        correctAnswers: ["В проекте производства маркшейдерских работ"],
      },
      {
        code: "20601153",
        text: "Какая максимально допустимая концентрация оксида углерода в рудничной атмосфере действующих горных выработках?",
        answers: ["20 мг/м3", "10 мг/м3", "7 мг/м3", "30 мг/м3"],
        correctAnswers: ["20 мг/м3"],
      },
      {
        code: "20601154",
        text: "При какой предельно допустимой концентрации содержания диоксида углерода в воздухе закрытого помещения работы в нем должны быть прекращены?",
        answers: [
          "0,4 (объемных) %",
          "0,6 (объемных) %",
          "0,3 (объемных) %",
          "0,2 (объемных) %",
          "0,5 (объемных) %",
        ],
        correctAnswers: ["0,5 (объемных) %"],
      },
      {
        code: "20601155",
        text: "Сколько сигнальных устройств должна иметь подъемная установка при проходке и углублении стволов?",
        answers: [
          "Одно сигнальное устройство",
          "Количество сигнальных устройств устанавливается в зависимости от горно-геологических условий",
          "Не менее двух независимых сигнальных устройств",
        ],
        correctAnswers: ["Не менее двух независимых сигнальных устройств"],
      },
      {
        code: "20601156",
        text: "Кто осматривает подвесные устройства проходческого оборудования и все узлы крепления канатов в стволе один раз в месяц?",
        answers: [
          "Технический руководитель организации",
          "Главный механик шахты или организации, выполняющей работы по проведению наклонных или вертикальных горных выработок",
          "Начальник рудника (шахты)",
          "Механик по ремонту горного оборудования",
        ],
        correctAnswers: [
          "Главный механик шахты или организации, выполняющей работы по проведению наклонных или вертикальных горных выработок",
        ],
      },
      {
        code: "20601157",
        text: "С какой периодичностью ИТР структурного подразделения, в ведении которых находится горная выработка, должны осматривать крепь и армировку вертикальных и наклонных стволов?",
        answers: [
          "Не реже одного раза в квартал",
          "Не реже одного раза в неделю",
          "Ежемесячно",
          "Ежесуточно",
        ],
        correctAnswers: ["Ежесуточно"],
      },
      {
        code: "20601158",
        text: "При вертикальных стволах какой глубины может отсутствовать второй механизированный выход?",
        answers: [
          "Глубиной до 30 м",
          "Глубиной до 100 м",
          "Глубиной до 200 м",
          "Глубиной до 70 м",
        ],
        correctAnswers: ["Глубиной до 70 м"],
      },
      {
        code: "20601159",
        text: "Какая максимальная скорость воздуха в стволах, предназначенных для спуска и подъема грузов и используемых при аварии для вывода людей?",
        answers: ["10 м/с", "20 м/с", "12 м/с", "6 м/с"],
        correctAnswers: ["10 м/с"],
      },
      {
        code: "20601160",
        text: "Какие показатели должны постоянно контролироваться в процессе проходки ствола скважины?",
        answers: [
          "Время, затраченное на работы по проходке основного ствола скважин и техническую скорость бурения",
          "Показатели измерений кривизны скважины, проводимые в процессе бурения",
          "Расход бурового раствора на входе и выходе из скважины и давление в манифольде буровых насосов",
          "Промежуточные показатели расхода буровой смеси для проходки",
        ],
        correctAnswers: [
          "Расход бурового раствора на входе и выходе из скважины и давление в манифольде буровых насосов",
        ],
      },
      {
        code: "20601161",
        text: "В каких случаях производится забуривание новых (боковых) стволов в обсаженных скважинах?",
        answers: [
          "Только при ликвидации аварий, инцидентов и осложнений (смятии эксплуатационной колонны, заклинивании инструмента, незапланированном цементировании колонны бурильных или лифтовых труб), возникших в процессе бурения",
          "Только при вскрытии дополнительных продуктивных мощностей путем проводки ответвлений (в т. ч. горизонтальных) из пробуренных стволов скважин",
          "Только при восстановлении бездействующего фонда скважин (включая ранее ликвидированные) с учетом достаточной сохранности крепи скважины и  экономической целесообразности в целях вскрытия новым стволом участков с неизвлеченными запасами углеводородного сырья",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20601162",
        text: "Каким должно быть расстояние между стенками стволов при строительстве объекта подземного строительства на участках недр, склонных и опасных по горным ударам?",
        answers: [
          "Не более 37 м",
          "Не менее 70 м",
          "Не менее 50 м",
          "Не более 40 м",
        ],
        correctAnswers: ["Не менее 50 м"],
      },
      {
        code: "20601163",
        text: "В каком случае месторождение (объект строительства подземных сооружений) будет относиться к опасному по горным ударам?",
        answers: [
          "Только если на участках месторождений происходили толчки, микроудары, горные удары",
          "Только если при определении степени удароопасности массива горных пород локальными инструментальными методами выявлена удароопасная категория",
          "Только если на месторождениях имело место интенсивное заколообразование и стреляние пород",
          "В любом из перечисленных случаев",
        ],
        correctAnswers: ["В любом из перечисленных случаев"],
      },
      {
        code: "20601164",
        text: "Какими методами производится локальный прогноз удароопасности участков массива горных пород и руд, а также оценка эффективности мер предотвращения горных ударов?",
        answers: [
          "Геомеханическими методами",
          "Геофизическими методами",
          "Методом дискования керна или визуальными наблюдениями за разрушением приконтурного массива выработок",
          "Всеми перечисленными",
        ],
        correctAnswers: ["Всеми перечисленными"],
      },
      {
        code: "20601165",
        text: "Каким должно быть расстояние между стенками стволов при строительстве шахт на месторождениях, склонных и опасных по горным ударам?",
        answers: [
          "Не менее 35 м",
          "Не менее 50 м",
          "Не менее 40 м",
          "Не менее 45 м",
        ],
        correctAnswers: ["Не менее 50 м"],
      },
      {
        code: "20601166",
        text: 'Какие из перечисленных требований на участках категории "Опасно" указаны верно? Укажите все правильные ответы.',
        answers: [
          "При расположении стволов в породах, опасных по горным ударам, должны быть выполнены процедуры, обеспечивающие постоянные максимальные значения концентрации напряжений в стенках ствола",
          "Мероприятия по предотвращению горных ударов проводятся во время возведения в стволе постоянной крепи",
          "При расположении стволов в породах, опасных по горным ударам, должны быть запроектированы и выполняться мероприятия, обеспечивающие снижение до неопасных значений концентраций напряжений в стенках ствола",
          'При проходке стволов на участках категории "Опасно" необходимо применять передовые разгрузочные щели с таким расчетом, чтобы плоскость щели располагалась перпендикулярно направлению действия максимальных горизонтальных напряжений',
        ],
        correctAnswers: [
          "При расположении стволов в породах, опасных по горным ударам, должны быть запроектированы и выполняться мероприятия, обеспечивающие снижение до неопасных значений концентраций напряжений в стенках ствола",
          'При проходке стволов на участках категории "Опасно" необходимо применять передовые разгрузочные щели с таким расчетом, чтобы плоскость щели располагалась перпендикулярно направлению действия максимальных горизонтальных напряжений',
        ],
      },
      {
        code: "20601167",
        text: "В каких случаях должен производиться контроль удароопасности в выработках?",
        answers: [
          "В случаях появления внешних признаков удароопасности в местах ведения горных работ",
          'В случаях изменений горнотехнической обстановки во время ведения горных работ в местах, где ранее была установлена категория "Опасно"',
          "При  изменении горно-геологической обстановки во время ведения горных работ в местах, отнесенных к удароопасной категории",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20601168",
        text: "Чем должно быть регламентировано приведение горных выработок в неудароопасное состояние?",
        answers: [
          "Конструкторской документацией, утвержденной специализированной организацией",
          "Проектом, утвержденным председателем Комиссии (техническим руководителем эксплуатирующей объект ведения горных работ организации)",
          "Указаниями горно-геологической службы",
          "Автоматизированной системой контроля состояния массива горных пород и прогноза динамических явлений",
        ],
        correctAnswers: [
          "Проектом, утвержденным председателем Комиссии (техническим руководителем эксплуатирующей объект ведения горных работ организации)",
        ],
      },
      {
        code: "20601169",
        text: "При выполнении каких из перечисленных профилактических мероприятий должно осуществляться проведение и поддержание выработок на месторождениях, на которых происходили горные удары?",
        answers: [
          "Использование податливых типов крепи",
          "Крепление выработок постоянной крепью с элементами податливости в зонах влияния геологических нарушений",
          'Приведение выработок категории "Опасно" в неудароопасное состояние',
          "Бурение разгрузочных щелей и скважин",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20601170",
        text: "На какой основе осуществляется изменение параметров противоударных мероприятий и периодичности прогноза степени удароопасности, предусмотренных Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых?",
        answers: [
          "На основе регламента, регулирующего деятельность по горным отводам при добыче полезных ископаемых",
          "На основе заключения представителя территориального управления Росприроднадзора",
          "На основе специальных исследований и заключения специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
          "На основе произведенного главным инженером маркшейдерской службы анализа горнотехнических условий",
        ],
        correctAnswers: [
          "На основе специальных исследований и заключения специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
        ],
      },
      {
        code: "20601171",
        text: "В каком случае допускаются отступления от последовательного продвигания фронта очистных работ в пределах как шахтного поля (участка), так и месторождения в целом, при отработке месторождений, склонных и опасных по горным ударам?",
        answers: [
          "При соблюдении мер по безопасному производству очистных работ",
          "С разрешения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
          "В случае незначительных отступлений от требований Правил безопасности при ведении горных работ",
          "Не допускаются ни в каком случае",
        ],
        correctAnswers: [
          "С разрешения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
        ],
      },
      {
        code: "20601172",
        text: "С какой периодичностью должно производиться профилирование стенок шахтного ствола и проводников в нем и где отражаются результаты такого профилирования?",
        answers: [
          "С периодичностью один раз в год с отражением результатов в паспорте вертикального шахтного ствола",
          "Сроки профилирования устанавливаются техническим регламентом для каждого ствола, но не реже одного раза в месяц и отражаются в геолого-техническом наряде",
          "Сроки профилирования устанавливаются техническим руководителем эксплуатирующей организации для каждого ствола, но не реже одного раза в три года. Результаты профилирования отражаются в маркшейдерской документации",
          "Проверка положения стенок шахтного ствола и проводников производится один раз в два года с отражением результатов профилирования в маркшейдерской документации",
        ],
        correctAnswers: [
          "Сроки профилирования устанавливаются техническим руководителем эксплуатирующей организации для каждого ствола, но не реже одного раза в три года. Результаты профилирования отражаются в маркшейдерской документации",
        ],
      },
      {
        code: "20601173",
        text: "Какая информация дополнительно включается в план при ведении работ, связанных с проводкой боковых стволов?",
        answers: [
          "Режимы проходки бокового ствола и утилизации выбуренной породы",
          "Тип породоразрушающего инструмента и его привода",
          "Навигационное обеспечение траектории бокового ствола или горизонтального ответвления",
          "Компоновки колонны труб и низа бурильной колонны",
          'Технические средства и режимы работ по вырезке "окна"',
          "Все перечисленное дополнительно включается в план работ",
        ],
        correctAnswers: [
          "Все перечисленное дополнительно включается в план работ",
        ],
      },
      {
        code: "20601174",
        text: "После каких работ в стволах должна проводиться профилировка проводников?",
        answers: [
          "После процесса перемещения технических устройств в вертикальной горной выработке",
          "После пуска вновь навешенного или отремонтированного подъемного сосуда (противовеса)",
          "После ремонта, связанного с заменой армировки или проводников",
          "После работ по проходке и углубке ствола с армированием",
        ],
        correctAnswers: [
          "После ремонта, связанного с заменой армировки или проводников",
        ],
      },
      {
        code: "20601175",
        text: "На каком расстоянии от устья необходимо размещать вентиляторные установки для проветривания вертикальных горных выработок, проводимых с поверхности?",
        answers: [
          "От 2 до 5 м",
          "От 15 м до 20 м",
          "Не менее 20 м",
          "Не менее 12 м",
        ],
        correctAnswers: ["Не менее 20 м"],
      },
      {
        code: "20601176",
        text: "Как должны быть ограждены устья действующих и находящихся в проходке вертикальных и наклонных горных выработок, оборудованных подъемными установками?",
        answers: [
          "Должны быть ограждены с нерабочих сторон стенками или металлической сеткой высотой не менее 2,5 м, а с рабочих сторон иметь решетки или двери, оборудованные блокировкой, включающей сигнал «Стоп» у машиниста при открытых дверях",
          "Должны ограждаться с нерабочих сторон глухими стенками высотой не менее 2,3 м, а с рабочих сторон иметь автоматические ворота",
          "Должны быть ограждены с нерабочих сторон металлической сеткой высотой не менее 2 м, а с рабочих сторон иметь двери, оборудованные автоматически включаемой блокировкой",
          "Должны иметь металлические ограждения со всех сторон высотой 1, 5 м, оборудованные дверями с запорным механизмом",
        ],
        correctAnswers: [
          "Должны быть ограждены с нерабочих сторон стенками или металлической сеткой высотой не менее 2,5 м, а с рабочих сторон иметь решетки или двери, оборудованные блокировкой, включающей сигнал «Стоп» у машиниста при открытых дверях",
        ],
      },
      {
        code: "20601177",
        text: "На каком расстоянии от устьев стволов, шурфов, штолен, скважин устанавливаются расположенные на поверхности здания вентиляторов главного проветривания?",
        answers: [
          "От 3 до 5 м",
          "Не более 5 м",
          "Не более 10 м",
          "На расстоянии, установленном проектной документацией",
        ],
        correctAnswers: [
          "На расстоянии, установленном проектной документацией",
        ],
      },
      {
        code: "20601178",
        text: "На каком расстоянии от ствола может быть установлена вентиляторная установка?",
        answers: [
          "Не более 5 метров от ствола",
          "Не менее 15 метров от ствола",
          "Не менее 10 метров от ствола",
        ],
        correctAnswers: ["Не менее 15 метров от ствола"],
      },
      {
        code: "20601179",
        text: "В каком случае горные работы следует производить с применением комплекса мер по профилактике горных ударов, высыпаний, вывалов и обрушения пород (руд) на участках рудного массива или массива горных пород?",
        answers: [
          "При выявлении отдельных блоков, ограниченных крупными разрывными нарушениями в пределах месторождения",
          "Расположенных на удалении 5 - 10 м от плоскости сместителя тектонического нарушения",
          "При разработанных мероприятиях для каждого конкретного месторождения с учетом особенностей",
          "В случае отработки целиков с формированием защищенной зоны",
        ],
        correctAnswers: [
          "Расположенных на удалении 5 - 10 м от плоскости сместителя тектонического нарушения",
        ],
      },
      {
        code: "20601180",
        text: "Какую температуру должен иметь воздух, поступающий в подземные горные выработки?",
        answers: ["Не менее 0 °С", "Равную 1 °C", "Не менее 2 °C"],
        correctAnswers: ["Не менее 2 °C"],
      },
      {
        code: "20601181",
        text: "Каким должен быть свободный проход в наклонных горных выработках, предназначенных для передвижения людей?",
        answers: [
          "Шириной не менее 1 м и высотой 2 м",
          "Шириной не менее 0,7 м и высотой 1,8 м",
          "Шириной не менее 0,8 м и высотой 2,2 м",
          "Шириной не менее 1,5 м и высотой 2,5 м",
        ],
        correctAnswers: ["Шириной не менее 0,7 м и высотой 1,8 м"],
      },
      {
        code: "20601182",
        text: "Каким образом на каждой шахте должно обеспечиваться проветривание двух отдельных выходов, обеспечивающих выход людей на поверхность?",
        answers: [
          "За счет естественной тяги",
          "За счет вентиляционных устройств (дверей, перемычек)",
          "Разным направлением вентиляционных струй",
        ],
        correctAnswers: ["Разным направлением вентиляционных струй"],
      },
      {
        code: "20601183",
        text: "Каким должно быть содержание кислорода (по объему) в рудничной атмосфере горных выработок, в которых находится или может находиться человек?",
        answers: [
          "Не менее 12 %",
          "Не менее 15 %",
          "Не менее 20 %",
          "Не менее 10 %",
        ],
        correctAnswers: ["Не менее 20 %"],
      },
      {
        code: "20601184",
        text: "Что из перечисленного не указывается на аншлагах, устанавливаемых во всех местах измерения расхода воздуха в горных выработках шахты?",
        answers: [
          "Фамилия ответственного за проведение измерений",
          "Дата проведения измерения",
          "Площадь поперечного сечения горной выработки в месте проведения измерения",
          "Скорость воздушной струи",
          "Расчетный и фактический расходы воздуха",
        ],
        correctAnswers: ["Фамилия ответственного за проведение измерений"],
      },
      {
        code: "20601185",
        text: "Что необходимо соблюдать при проектировании вскрытия, подготовки, систем разработки на участках недр, склонных и опасных по горным ударам?",
        answers: [
          "Только преимущественно проводить горные выработки преимущественно вне зоны влияния тектонических нарушений и по направлению наибольшего напряжения в массиве горных пород",
          "Обеспечивать по возможности планомерное извлечение запасов без образования целиков, острых углов и выступов фронта очистных работ",
          "Только преимущественно проводить горные выработки по направлению наибольшего напряжения в массиве горных пород, соблюдая все перечисленные условия",
          "Снижать количество горных выработок, проводимых вблизи фронта очистных работ (в зонах опорного давления)",
        ],
        correctAnswers: [
          "Только преимущественно проводить горные выработки по направлению наибольшего напряжения в массиве горных пород, соблюдая все перечисленные условия",
        ],
      },
      {
        code: "20601186",
        text: "Разрешается ли применение системы разработки с обрушением руды и вмещающих пород при наличии в налегающих породах плывунов, неосушенных песков, карстов и т. д.?",
        answers: [
          "Разрешается при наличии записи в проектно-технической документации",
          "Разрешается при согласовании с территориальными надзорными органами",
          "Запрещается",
        ],
        correctAnswers: ["Запрещается"],
      },
      {
        code: "20601187",
        text: 'Какие требования установлены "Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых" для безопасного выполнения работ по ручной оборке откосов уступов?',
        answers: [
          "Ручная оборка должна осуществляться только в дневное рабочее время под наблюдением технического руководителя горнодобывающей организации",
          "Ручная оборка должна выполняться бригадой в составе не менее 3 рабочих",
          "Ручная оборка должна осуществляться по наряду-допуску под наблюдением лиц технического надзора",
        ],
        correctAnswers: [
          "Ручная оборка должна осуществляться по наряду-допуску под наблюдением лиц технического надзора",
        ],
      },
      {
        code: "20601188",
        text: "Какими должны быть предельные углы откосов (углы устойчивости) временно консервируемых участков борта в процессе эксплуатации?",
        answers: [
          "Предельные углы откосов (углы устойчивости) устанавливаются проектом",
          "Равными 40 - 50°",
          "Не должны превышать 90°",
        ],
        correctAnswers: [
          "Предельные углы откосов (углы устойчивости) устанавливаются проектом",
        ],
      },
      {
        code: "20601189",
        text: "С учетом каких факторов определяется высота уступа?",
        answers: [
          "Только с учетом результатов исследований физико-механических свойств горных пород",
          "Только с учетом горно-геологических условий залегания пород полезных ископаемых",
          "Только с учетом параметров применяемого оборудования",
          "С учетом всех перечисленных параметров",
        ],
        correctAnswers: ["С учетом всех перечисленных параметров"],
      },
      {
        code: "20601190",
        text: "Что необходимо соблюдать при погашении уступов и постановке их в предельное положение?",
        answers: [
          "Предельные углы откосов бортов объекта",
          "Общий угол откоса бортов, установленный проектом",
          "Угол сдвижения горных пород",
        ],
        correctAnswers: ["Общий угол откоса бортов, установленный проектом"],
      },
      {
        code: "20601191",
        text: "Какой величины допускается высота уступа и его угол откоса при разработке соляного пласта?",
        answers: [
          "Высота не должна быть меньше 5 м, а угол откоса не более 80°",
          "Высота не должна превышать 3 м, а угол откоса равен 65°",
          "Высота не должна превышать 6 м, а угол откоса не менее 80°",
          "Высота не должна превышать 8 м, а угол откоса не более 75°",
        ],
        correctAnswers: [
          "Высота не должна превышать 8 м, а угол откоса не более 75°",
        ],
      },
      {
        code: "20601192",
        text: "Какие два дополнительных основных требования должны выполняться при отработке сближенных калийно-магниевых и соляных пластов?",
        answers: [
          "Соосное расположение целиков с опережением фронта очистных работ по верхнему пласту не менее чем на 30 м",
          "Соосное расположение целиков с опережением фронта очистных работ по верхнему пласту не менее чем на 50 м",
          "Соосное расположение очистных камер, с опережением фронта очистных работ по верхнему пласту не менее чем на 50 м",
          "Соосное расположение очистных камер, с опережением фронта очистных работ по верхнему пласту не менее чем на 75 м",
        ],
        correctAnswers: [
          "Соосное расположение очистных камер, с опережением фронта очистных работ по верхнему пласту не менее чем на 50 м",
        ],
      },
      {
        code: "20601193",
        text: "Что организуют при вскрытии газоносных угольных пластов горными выработками?",
        answers: [
          "Ежесменный контроль содержания сероводорода в месте вскрытия",
          "Постоянный контроль содержания диоксида углерода в месте вскрытия",
          "Непрерывный контроль содержания метана в месте вскрытия",
          "Контроль содержания серного газа при вскрытии",
        ],
        correctAnswers: [
          "Непрерывный контроль содержания метана в месте вскрытия",
        ],
      },
      {
        code: "20601194",
        text: 'Как часто во всех действующих выработках должны производиться нивелирование откаточных путей и проверки соответствия зазоров требованиям Федеральных норм и правил в области промышленной безопасности "Правила безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          "Один раз в полугодие",
          "Один раз в год",
          "Один раз в три года",
          "Два раза в квартал",
        ],
        correctAnswers: ["Один раз в год"],
      },
      {
        code: "20601195",
        text: "К какой категории шахт по метану должна быть отнесена шахта, имеющая относительную газообильность 16 м³/т?",
        answers: [
          "К I категории",
          "Ко II категории",
          "К газовой",
          "К сверхкатегорным",
        ],
        correctAnswers: ["К сверхкатегорным"],
      },
      {
        code: "20601196",
        text: "С какой периодичностью маркшейдерская служба шахты или специализированная организация, имеющая на это право, выполняет полную проверку геометрической связи шахтного подъема и копра?",
        answers: [
          "Не реже одного раза в год",
          "Не реже одного раза в смену",
          "Не реже двух раз в год",
          "Не реже одного раза в три года",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20601197",
        text: "Под чьим руководством производится инструментальная проверка вертикальности копра, правильности установки направляющих шкивов по отношению к оси ствола и оси подъема?",
        answers: [
          "Под руководством представителя надзорной организации",
          "Под руководством технического руководителя объекта",
          "Под руководством главного маркшейдера шахты",
          "Под руководством начальника смены",
        ],
        correctAnswers: ["Под руководством главного маркшейдера шахты"],
      },
      {
        code: "20601198",
        text: "В каком случае нарушены требования безопасности при проходке стволов?",
        answers: [
          "При проходке шахтных стволов разработка забоя произведена заходками на глубину менее 1 м",
          "Сбойка двух выработок на участках, склонных и опасных по горным ударам, с расстояния между забоями 20 м должна вестись двумя забоями",
          "Нормативные скорости проходки изменены в зависимости от формы и размеров выработки",
          "При проходке шахтных стволов применен способ опускной крепи",
        ],
        correctAnswers: [
          "Сбойка двух выработок на участках, склонных и опасных по горным ударам, с расстояния между забоями 20 м должна вестись двумя забоями",
        ],
      },
      {
        code: "20601199",
        text: 'Укажите требования, соответствующие Федеральным нормам и правилам в области промышленной безопасности "Правила безопасности при ведении горных работ и переработке твердых полезных ископаемых" к организации надзора и проектному оформлению при посадке кровли в процессе очистной выемки.',
        answers: [
          "Посадка кровли должна выполняться бригадой в составе не менее 5 работников шахты под непосредственным руководством начальника смены",
          "Посадка кровли должна производиться под наблюдением руководителя маркшейдерской службы в соответствии с техническим проектом",
          "Посадка кровли должна производиться под непосредственным руководством лица технического надзора в соответствии с проектом организации работ",
          "Посадка кровли должна производиться под руководством лица технического надзора в соответствии со схемой развития горных работ",
        ],
        correctAnswers: [
          "Посадка кровли должна производиться под непосредственным руководством лица технического надзора в соответствии с проектом организации работ",
        ],
      },
      {
        code: "20601200",
        text: 'К какому классу относится "Неустойчивая кровля"?',
        answers: ["К I классу", "К III классу", "К II классу"],
        correctAnswers: ["К I классу"],
      },
      {
        code: "20601201",
        text: "Какая должна быть минимальная плотность установки анкеров в классе неустойчивой кровли?",
        answers: ["0,4 анк/м²", "0,3 анк/м²", "1 анк/м²", "0,7 анк/м²"],
        correctAnswers: ["1 анк/м²"],
      },
      {
        code: "20601202",
        text: 'К какому классу относится "Кровля средней устойчивости"?',
        answers: ["К I классу", "Ко II классу", "К III классу"],
        correctAnswers: ["Ко II классу"],
      },
      {
        code: "20601203",
        text: 'К какому классу относится "Устойчивая кровля"?',
        answers: ["К I классу", "Ко II классу", "К III классу"],
        correctAnswers: ["К III классу"],
      },
      {
        code: "20601204",
        text: "При каких горно-геологических и горнотехнических условиях угольных шахт не применяется порядок расчета, регламентированный Инструкцией по расчету и применению анкерной крепи на угольных шахтах?",
        answers: [
          "При прочности на одноосное сжатие оставляемой угольной пачки в кровле горной выработки или сопряжения не менее 6 МПа",
          "При поддержании горизонтальных и наклонных подземных горных выработок в полностью надработанном и подработанном массивах на расстояниях, больше ширины выработки и двенадцати мощностей пласта",
          "При максимальной расчетной ширине выработки или сопряжения не более 12 м",
          "При проведении горных выработок в зонах разрывных геологических нарушений, при наличии выветренных, пластичных и мерзлых вмещающих пород",
        ],
        correctAnswers: [
          "При проведении горных выработок в зонах разрывных геологических нарушений, при наличии выветренных, пластичных и мерзлых вмещающих пород",
        ],
      },
      {
        code: "20601205",
        text: "Чему равно значение коэффициента снижения сопротивления пород сжатию за счет воздействия влаги при сопротивлении пород одноосному сжатию в образце 50 МПа?",
        answers: ["0,7", "0,5", "0,8", "0,6"],
        correctAnswers: ["0,5"],
      },
      {
        code: "20601206",
        text: "В зависимости от каких характеристик проводится расчет параметров анкерной крепи и дополнительных средств ее усиления?",
        answers: [
          "Интенсивности горного давления",
          "Прочности, трещиноватости и устойчивости пород в кровле",
          "Способа и параметров охраны горных выработок от влияния очистных работ",
          "Всех перечисленных характеристик",
        ],
        correctAnswers: ["Всех перечисленных характеристик"],
      },
      {
        code: "20601207",
        text: "Какой должна быть требуемая минимальная плотность установки анкеров в классе неустойчивой кровли?",
        answers: [
          "Не меньше 1 анк/м2",
          "Не меньше 0,7 анк/м2",
          "Не больше 0,9 анк/м2",
          "Равной 0,3 анк/м2",
        ],
        correctAnswers: ["Не меньше 1 анк/м2"],
      },
      {
        code: "20601208",
        text: "На сколько классов устойчивости следует разделять непосредственную кровлю над горными выработками и сопряжениями при определении плотности установки анкеров, конструкции опор, затяжки, технологии крепления кровли при проходке, для всех типов кровли по обрушаемости?",
        answers: ["На пять", "На четыре", "На три", "На  два"],
        correctAnswers: ["На три"],
      },
      {
        code: "20601209",
        text: "К какому типу кровли (по обрушаемости) будет относиться однородная кровля из слоистых преимущественно глинистых, песчано-глинистых и песчаных сланцев?",
        answers: [
          "К III типу кровли",
          "К I типу кровли",
          "К II типу кровли",
          "К IV типу кровли",
        ],
        correctAnswers: ["К I типу кровли"],
      },
      {
        code: "20601210",
        text: "К какой категории интенсивности проявления горного давления будет относиться горная выработка при расчетном смещении пород кровли от 50 до 200 мм?",
        answers: [
          "К I категории (небольшое горное давление)",
          "К III категории (интенсивное горное давление)",
          "К II категории (горное давление средней интенсивности)",
        ],
        correctAnswers: [
          "К II категории (горное давление средней интенсивности)",
        ],
      },
      {
        code: "20601211",
        text: "К какой категории интенсивности проявления горного давления будет относиться горная выработка при расчетном смещении пород кровли свыше 200 мм?",
        answers: [
          "К II категории (горное давление средней интенсивности)",
          "К III категории (интенсивное горное давление)",
          "К I категории (небольшое горное давление)",
        ],
        correctAnswers: ["К III категории (интенсивное горное давление)"],
      },
      {
        code: "20601212",
        text: "К какому типу кровли по обрушаемости относится кровля при сопротивлении пород одноосному сжатию Rc ≤ 30 МПа?",
        answers: [
          "К I типу кровли",
          "К II типу кровли",
          "К III типу кровли",
          "К IV типу кровли",
        ],
        correctAnswers: ["К III типу кровли"],
      },
      {
        code: "20601213",
        text: "Какое определение характеризует смещение кровли более 200 мм, выделяемое при определении и выборе документации крепления в зависимости от расчетных смещений и от интенсивности проявлений горного давления?",
        answers: [
          "Горное давление повышенной интенсивности",
          "Интенсивное горное давление",
          "Горное давление средней интенсивности",
          "Небольшое горное давление",
        ],
        correctAnswers: ["Интенсивное горное давление"],
      },
      {
        code: "20601214",
        text: "Чему равно значение коэффициента разрыхления пород при вынимаемой мощности подрабатывающего пласта до 1 м и прочности пород непосредственной кровли подрабатывающего пласта до 30 МПа?",
        answers: ["1,35 - 1,40", "1,20 - 1,25", "1,15 - 1,20", "1,25 - 1,30"],
        correctAnswers: ["1,15 - 1,20"],
      },
      {
        code: "20601215",
        text: "Влияние каких профилактических мероприятий на геомеханическое состояние вмещающих пород не требуется учитывать при расчете параметров анкерной крепи горных выработок, проводимых по пластам, склонным к горным ударам и внезапным выбросам угля (породы) и газа?",
        answers: [
          "Выполнение гидрообработки угольного массива",
          "Региональная предварительная подработка и (или) надработка массива с последующим проведением в нем подготовительных горных выработок",
          "Бурение разгрузочных скважин в окружающем угольном массиве в период проведения горной выработки",
          "Контроль за скоростью вращения инструмента при бурении давлением на манометре насоса, выносом из скважины крупных обломков породы",
        ],
        correctAnswers: [
          "Контроль за скоростью вращения инструмента при бурении давлением на манометре насоса, выносом из скважины крупных обломков породы",
        ],
      },
      {
        code: "20601216",
        text: "Какие требования при проектировании паспортов крепления и поддержания подготовительных выработок анкерной крепью указаны неверно?",
        answers: [
          "Анкеры в кровле должны располагаться с отклонением крайних анкеров от нормали к напластованию на угол 15 - 20°",
          "Крайние анкеры в кровле следует располагать на расстоянии от боков не более 0,3 м",
          "Расстояние от кровли выработки до верхнего ряда анкеров должно быть не более 1,5 м",
          "Анкеры в боках следует располагать так, чтобы они пересекали наиболее слабые слои угля и пород (с отклонением от напластования на угол 15 - 30°)",
        ],
        correctAnswers: [
          "Расстояние от кровли выработки до верхнего ряда анкеров должно быть не более 1,5 м",
        ],
      },
      {
        code: "20601217",
        text: "С какой периодичностью должен проводиться визуальный контроль работоспособности анкерной крепи в зонах влияния очистных работ?",
        answers: [
          "Один раз в 3 месяца",
          "Еженедельно",
          "Ежесуточно",
          "Раз в месяц",
        ],
        correctAnswers: ["Ежесуточно"],
      },
      {
        code: "20601218",
        text: "По истечении какого срока эксплуатации анкерной крепи в выработках должна быть проведена научно-исследовательская работа с оценкой несущей способности анкеров, коррозионного износа и работоспособности анкерной крепи?",
        answers: ["7 лет", "10 лет", "12 лет", "5 лет"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20601219",
        text: "Какая должна быть минимальная длина анкеров первого уровня в случае применения двухуровневой схемы крепи при ширине выработки от 8 до 12 м для I типа кровли?",
        answers: ["2,2 м", "2,4 м", "1,8 м", "1,4 м"],
        correctAnswers: ["2,4 м"],
      },
      {
        code: "20601220",
        text: "Какая должна быть минимальная длина анкеров первого уровня в случае применения двухуровневой схемы крепи при ширине выработки от 8 до 12 м для II типа кровли?",
        answers: ["2,4 м", "1,6 м", "2,0 м", "1,2 м"],
        correctAnswers: ["2,0 м"],
      },
      {
        code: "20601221",
        text: "Какой следует принимать расчетную несущую способность анкера Nа для горных выработок и сопряжений со сроком службы свыше 40 лет и в обводненных породах?",
        answers: [
          "Следует принимать в соответствии с проектом производства работ, с учетом расчетных смещений кровли",
          "Следует принимать в соответствии с технической документацией, с учетом расчетной несущей способности анкерных стержней",
          'Следует принимать на 20 % меньше значений, регламентированных в ФНП "Инструкция по расчету и применению анкерной крепи на угольных шахтах"',
        ],
        correctAnswers: [
          'Следует принимать на 20 % меньше значений, регламентированных в ФНП "Инструкция по расчету и применению анкерной крепи на угольных шахтах"',
        ],
      },
      {
        code: "20601222",
        text: "До какой нагрузки нагружают анкерную крепь при испытании ее несущей способности?",
        answers: [
          "До 0,8Nа, где Nа - несущая способность анкера, кН",
          "До 0,6Nа, где Nа - несущая способность анкера, кН",
          "До 1Nа, где Nа - несущая способность анкера, кН",
          "До 1,3Nа, где Nа - несущая способность анкера, кН",
        ],
        correctAnswers: ["До 0,6Nа, где Nа - несущая способность анкера, кН"],
      },
      {
        code: "20601223",
        text: "Какую расчетную несущую способность по всей длине скважины должны обеспечивать анкеры из стержней диаметром 20 мм с метрической резьбой из стали Ст.5?",
        answers: ["60 кН", "85 кН", "130 кН", "100 кН"],
        correctAnswers: ["100 кН"],
      },
      {
        code: "20601224",
        text: "Какую расчетную несущую способность по всей длине скважины должны обеспечивать анкеры из стержней винтового профиля из стали А400С, А500С N 25?",
        answers: ["185 кН", "120 кН", "240 кН", "90 кН"],
        correctAnswers: ["240 кН"],
      },
      {
        code: "20601225",
        text: "С какой периодичностью и при выполнении каких условий должна проводиться оценка несущей способности применяемых анкеров при проведении (восстановлении) выработок?",
        answers: [
          "Оценка проводится с применением прибора контроля анкерной крепи в срок не реже одного раза в месяц через каждые 300 м длины горной выработки",
          "Оценка проводится при помощи штанговыдергивателя не реже одного раза в месяц через каждые 200 м длины горной выработки",
          "Оценка проводится при помощи штанговыдергивателя не реже одного раза в 6 месяцев через каждые 100 м длины горной выработки",
          "Оценка проводится при помощи средств мониторинга анкерной крепи не реже одного раза в 3 месяца через каждые 50 м длины горной выработки",
        ],
        correctAnswers: [
          "Оценка проводится при помощи штанговыдергивателя не реже одного раза в месяц через каждые 200 м длины горной выработки",
        ],
      },
      {
        code: "20601226",
        text: "Каким образом целесообразно располагать анкеры в кровле?",
        answers: [
          "С отклонением крайних анкеров от нормали к напластованию на угол 20 - 25° и на расстоянии их от боков не более 0,4 м",
          "С отклонением крайних анкеров от нормали к напластованию на угол 15 - 20° и на расстоянии их от боков не более 0,5 м",
          "С отклонением крайних анкеров от нормали к напластованию на угол 15 - 20° и на расстоянии их от боков не более 0,3 м",
        ],
        correctAnswers: [
          "С отклонением крайних анкеров от нормали к напластованию на угол 15 - 20° и на расстоянии их от боков не более 0,3 м",
        ],
      },
      {
        code: "20601227",
        text: "На каком расстоянии от забоя должна быть установлена анкерная крепь при условии наличия в кровле горной выработки неустойчивых пород, обрушающихся в проходческом забое?",
        answers: [
          "Не более 1,3 м",
          "Не менее 2 м",
          "Не более 1,5 м",
          "Не более 1 м",
        ],
        correctAnswers: ["Не более 1 м"],
      },
      {
        code: "20601228",
        text: "В каком случае допускается подвеска к анкерной крепи оборудования?",
        answers: [
          "Допускается в случае выполнения ремонтных работ",
          "Не допускается ни в каком случае",
          "Допускается подвеска горно-шахтного оборудования, предусмотренного паспортом крепления или специальным проектом",
          "Допускается в случае технической необходимости",
        ],
        correctAnswers: [
          "Допускается подвеска горно-шахтного оборудования, предусмотренного паспортом крепления или специальным проектом",
        ],
      },
      {
        code: "20601229",
        text: "В какой момент допускается проводить затяжку гаек анкера при возведении анкерной крепи?",
        answers: [
          "После определяемого визуально отвердения состава ампул",
          "Через час после полного твердения состава ампул",
          "Сразу после установки анкеров",
          "После окончания процесса твердения состава ампул, время которого указывается заводом-изготовителем",
        ],
        correctAnswers: [
          "После окончания процесса твердения состава ампул, время которого указывается заводом-изготовителем",
        ],
      },
      {
        code: "20601230",
        text: "В каких горных выработках применяются анкеры с закреплением стержней минеральной композицией?",
        answers: [
          "Только в горных выработках с обводненными породами",
          "Только в горных выработках, пройденных по пластам весьма склонных к самовозгоранию",
          "Только в горных выработках со сроком службы более 10 лет",
          "В любой из перечисленных горных выработках",
        ],
        correctAnswers: ["В любой из перечисленных горных выработках"],
      },
      {
        code: "20601231",
        text: "Что должно быть указано на корпусах оборудования, входящего в состав талевой системы (кронблок, талевый блок, крюк)?",
        answers: [
          "Длительность срока службы",
          "Количество проведенных испытаний",
          "Допускаемая грузоподъемность",
          "Тип талевой системы",
        ],
        correctAnswers: ["Допускаемая грузоподъемность"],
      },
      {
        code: "20601232",
        text: "Какой должна быть высота реборды над верхним слоем навивки при наличии более одного слоя навивки каната на барабан?",
        answers: [
          "1 диаметр каната",
          "2,5 диаметра каната",
          "1,5 диаметра каната",
          "2 диаметра каната",
        ],
        correctAnswers: ["2,5 диаметра каната"],
      },
      {
        code: "20601233",
        text: "Какой верхний предел диапазона измерений должны иметь манометры, устанавливаемые на блоках дросселирования и глушения?",
        answers: [
          "На 40 % превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования",
          "На 25 % превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования",
          "На 30 % превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования",
          "На 10 % превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования",
        ],
        correctAnswers: [
          "На 30 % превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования",
        ],
      },
      {
        code: "20601234",
        text: "Какой должна быть высота свободного прохода на штреках (ортах) скреперования?",
        answers: [
          "На штреках (ортах) скреперования должен всегда сохраняться свободный проход, высота которого указывается в проекте производства работ",
          "На штреках (ортах) скреперования всегда должен сохраняться свободный проход по высоте не менее 1,6 м",
          "На штреках (ортах) скреперования должен всегда сохраняться свободный проход по высоте не менее 2/3 высоты выработки",
        ],
        correctAnswers: [
          "На штреках (ортах) скреперования должен всегда сохраняться свободный проход по высоте не менее 2/3 высоты выработки",
        ],
      },
      {
        code: "20601235",
        text: "Какое из перечисленных требований по ведению горных работ в угольных шахтах является верным?",
        answers: [
          "Разрешается проведение горных работ в процессе утверждения главным инженером шахты документации по ведению горных работ",
          "Стаж работы по профессии одного из работников, выполняющего горные работы, должен быть не менее 6 месяцев",
          "Горные работы должны выполнять не менее трех работников, стаж по рабочей профессии которых должен составлять не менее 3 лет",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20601236",
        text: "С какой периодичностью осуществляют профильную съемку армировки и замер зазоров безопасности на угольных шахтах?",
        answers: [
          "Не реже одного раза в полугодие",
          "Не реже одного раза в месяц",
          "Не реже одного раза в два года",
          "Не реже двух раз в год",
        ],
        correctAnswers: ["Не реже одного раза в два года"],
      },
      {
        code: "20601237",
        text: "В соответствии с какой документацией проводится объединение угольных шахт с независимым проветриванием в одну вентиляционную систему?",
        answers: [
          "В соответствии с  Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых",
          "В сответствии с требованиями Федерального агентства по недропользованию",
          "В соответствии с  с технологическим регламентом на проведение горных работ",
          "В соответствии с проектной документацией",
        ],
        correctAnswers: ["В соответствии с проектной документацией"],
      },
      {
        code: "20601238",
        text: "С какой периодичностью контролируется состояние горных выработок угольной шахты ИТР шахты?",
        answers: [
          "Один раз в месяц",
          "Еженедельно",
          "Ежесуточно",
          "Два раза в смену",
        ],
        correctAnswers: ["Ежесуточно"],
      },
      {
        code: "20601239",
        text: "С какой периодичностью вносятся записи в журнал по контролю за состоянием выработки и показаниями реперных станций в зоне опорного давления?",
        answers: [
          "1 раз в полугодие",
          "1 раз в квартал",
          "1 раз в месяц",
          "1 раз в неделю",
        ],
        correctAnswers: ["1 раз в месяц"],
      },
      {
        code: "20601240",
        text: "Кто принимает решение по изменению направления движения и расхода воздуха в горных выработках угольных шахт?",
        answers: [
          "Начальник смены",
          "Начальник участка аэрологической безопасности",
          "Руководитель маркшейдерской службы",
          "Рабочие шахты",
        ],
        correctAnswers: ["Начальник участка аэрологической безопасности"],
      },
      {
        code: "20601241",
        text: "С какой периодичностью должен осуществляться визуальный контроль пылевых отложений ИТР технологического участка?",
        answers: [
          "Один раз в сутки",
          "Еженедельно",
          "Ежесменно",
          "Периодичность устанавливается на усмотрение главного маркшейдера шахты",
        ],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20601242",
        text: "С какой периодичностью следует контролировать по результатам лабораторного анализа проб отложившейся в горных выработках угольной пыли в местах интенсивного пылеотложения?",
        answers: [
          "Не реже одного раза в три месяца",
          "Не реже одного раза в месяц",
          "Не реже одного раза в неделю",
          "Не реже двух раз в месяц",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20601243",
        text: "Какие условия должны соблюдаться в забоях выработок, где имеется газовыделение или взрывчатая угольная пыль?",
        answers: [
          "Раздельное взрывание зарядов в шпурах при числе циклов не более одного по углю и одного по породе, должно осуществляться только по разрешению руководителя горнодобывающего предприятия",
          "В забоях выработок, где имеется газовыделение или взрывчатая угольная пыль, разрешается применять только непредохранительные электродетонаторы с заземлением",
          "При протяженности угольного забоя более 5 м разрешается его делить по длине на участки, и взрывание в каждом из них производить раздельно",
          "Общее максимальное время замедления электродетонаторов короткозамедленного действия с учетом разброса по времени срабатывания при применении взрывчатых веществ V и VI класса не должно превышать 420 миллисекунд",
        ],
        correctAnswers: [
          "При протяженности угольного забоя более 5 м разрешается его делить по длине на участки, и взрывание в каждом из них производить раздельно",
        ],
      },
      {
        code: "20601244",
        text: "Какое напряжение допускается для питания подземных осветительных установок и для ручных переносных светильников, питаемых от искробезопасных источников, на угольных шахтах?",
        answers: [
          "Не выше 127 В и не выше 12 В соответственно",
          "Не выше 380 В и не выше 50 В соответственно",
          "Не выше 120 В и не выше 36 В соответственно",
          "Не выше 220 В и не выше 42 В соответственно",
        ],
        correctAnswers: ["Не выше 220 В и не выше 42 В соответственно"],
      },
      {
        code: "20601245",
        text: "Какие из перечисленных расстояний, относящихся к лестницам в лестничных отделениях угольных шахт, указаны верно?",
        answers: [
          "Ширина лестниц должна быть не менее 0,4 м, а расстояние между ступенями - не более 0,4 м",
          "Расстояние между крепью горной выработки и лестницей у ее основания должно быть не менее 0,5 м",
          "В горизонтальных полках для свободного прохода должны быть установлены лазы шириной не менее 0,5 м и высотой не менее 0,6 м",
          "В лестничных отделениях через каждые 10 м должны устанавливаться горизонтальные полки",
        ],
        correctAnswers: [
          "Ширина лестниц должна быть не менее 0,4 м, а расстояние между ступенями - не более 0,4 м",
        ],
      },
      {
        code: "20601246",
        text: "Что из перечисленного не соответствует требованиям к гидравлическому способу добычи угля?",
        answers: [
          "Включение и выключение технологических насосов на технологических водоводах должно производиться только по разрешению горного диспетчера шахты",
          "Перед началом работы гидромонитора должны выводиться все люди из зоны действия его струи",
          "Открывание и закрывание задвижек на технологических водоводах должны производиться по распоряжению бригадира",
          "В действующем очистном забое работы при гидравлической и механогидравлической отбойке угля должны производить не менее двух работников",
        ],
        correctAnswers: [
          "Открывание и закрывание задвижек на технологических водоводах должны производиться по распоряжению бригадира",
        ],
      },
      {
        code: "20601247",
        text: "Какие меры из перечисленных входят в комплекс работ по освоению скважин?",
        answers: [
          'Только предупреждение прорыва пластовой воды и газа из газовой "шапки", предотвращение неконтролируемых газонефтеводопроявлений и открытых фонтанов',
          "Только термогидрогазодинамические исследования по определению количественной и качественной характеристики пласта и его геологофизических параметров",
          "Только сохранение скелета пласта в призабойной зоне, восстановление и повышение проницаемости данной зоны",
          "В комплекс работ по освоению скважин входят все перечисленные меры",
        ],
        correctAnswers: [
          "В комплекс работ по освоению скважин входят все перечисленные меры",
        ],
      },
      {
        code: "20601248",
        text: "Какой радиус опасной зоны должен быть установлен вокруг скважины и применяемого оборудования на период тепловой и комплексной обработки?",
        answers: [
          "Не менее 30 м",
          "Не менее 50 м",
          "Не менее 15 м",
          "Не менее 10 м",
        ],
        correctAnswers: ["Не менее 50 м"],
      },
      {
        code: "20601249",
        text: "Каким устанавливается радиус опасной зоны вокруг нагнетательной скважины на период инициирования внутрипластового горения?",
        answers: [
          "Не менее 25 м",
          "Не менее 10 м",
          "Не менее 5 м",
          "Не менее 20 м",
        ],
        correctAnswers: ["Не менее 25 м"],
      },
      {
        code: "20601250",
        text: "Какими лицами переносятся средства инициирования?",
        answers: [
          "Раздатчиками взрывчатых материалов 3 разряда",
          "Ответственными работниками маркшейдерской службы",
          "Взрывниками",
          "Всеми перечисленными лицами",
        ],
        correctAnswers: ["Взрывниками"],
      },
      {
        code: "20601251",
        text: "Какое из перечисленных требований при применении средств инициирования запрещается?",
        answers: [
          "Обматывать детонирующий шнур вокруг патрона взрывчатых веществ",
          "Расширять гнезда патронов (шашек) заводского изготовления, из которых изготовлены боевики из прессованных или литых взрывчатых веществ с инициированием от детонаторов",
          "Изготавливать боевики из прессованных или литых взрывчатых веществ с инициированием от детонаторов из патронов (шашек) с гнездами заводского изготовления",
          "Завязывать узлом или складываться вдвое конец детонирующего шнура при изготовлении промежуточных детонаторов из порошкообразных патронированных взрывчатых веществ",
        ],
        correctAnswers: [
          "Расширять гнезда патронов (шашек) заводского изготовления, из которых изготовлены боевики из прессованных или литых взрывчатых веществ с инициированием от детонаторов",
        ],
      },
      {
        code: "20601252",
        text: "Какой должна быть расчетная продолжительность процесса цементирования обсадной колонны?",
        answers: [
          "Не должна превышать 60 % времени от времени начала лабораторного анализа",
          "Не должна превышать 75 % времени начала загустевания тампонажного раствора",
          "Не должна превышать 70 % времени от начала проведения процесса цементирования",
          "Не должна превышать 85 % времени начала загустевания цементного раствора",
        ],
        correctAnswers: [
          "Не должна превышать 75 % времени начала загустевания тампонажного раствора",
        ],
      },
      {
        code: "20601253",
        text: "Где следует устанавливать башмак обсадной колонны, перекрывающий породы, склонные к текучести?",
        answers: [
          "На уровне кровли",
          "На первой трубе обсадной колонны для предупреждения смятия торца нижней колонны",
          "Ниже их подошвы или в плотных пропластках",
        ],
        correctAnswers: ["Ниже их подошвы или в плотных пропластках"],
      },
      {
        code: "20601254",
        text: "Какие требования предъявляются к условиям установки подвесного и герметизирующего устройства потайной колонны?",
        answers: [
          "Подвесное и герметизирующее устройство нецементируемого хвостовика, устанавливаемого в продуктивном пласте, должно располагаться ниже башмака предыдущей обсадной колонны",
          "Подвесное и герметизирующее устройство потайной колонны должно устанавливаться выше предыдущей обсадной колонны не менее чем на 75 м для нефтяных скважин и 250 м для газовых скважин",
          "Подвесное и герметизирующее устройство потайной колонны должно устанавливаться выше предыдущей обсадной колонны не менее чем на 150 м для всех видов скважин",
        ],
        correctAnswers: [
          "Подвесное и герметизирующее устройство потайной колонны должно устанавливаться выше предыдущей обсадной колонны не менее чем на 75 м для нефтяных скважин и 250 м для газовых скважин",
        ],
      },
      {
        code: "20601255",
        text: "Какие меры должны приниматься в случае производства на скважине работ, требующих давлений, превышающих давления опрессовки обсадной колонны?",
        answers: [
          "На устье устанавливается насосно-компрессорная труба, эксплуатационная колонна защищается специальным составом",
          "На устье устанавливается специальная арматура, а эксплуатационная колонна защищается установкой пакера",
          "На устье скважины устанавливается оборудование для герметизации",
          "Проведение данных работ запрещается технической документацией",
        ],
        correctAnswers: [
          "На устье устанавливается специальная арматура, а эксплуатационная колонна защищается установкой пакера",
        ],
      },
      {
        code: "20601256",
        text: "Какие ограничения предусмотрены для перфорации обсадных колонн при проведении ремонтно-изоляционных работ в процессе проводки ствола скважины?",
        answers: [
          "Запрещается интервал перфорации при проведении ремонтно-изоляционных работ перекрывать песчаной пробкой или цементным мостом",
          "Запрещается перфорация обсадных колонн при проведении ремонтно-изоляционных работ в интервале проницаемых горизонтов",
          "Запрещается перфорация обсадных колонн при проведении ремонтно-изоляционных работ в интервале возможного разрыва пласта давлением газа, нефти (после вызова их притока) или столба бурового раствора",
        ],
        correctAnswers: [
          "Запрещается перфорация обсадных колонн при проведении ремонтно-изоляционных работ в интервале возможного разрыва пласта давлением газа, нефти (после вызова их притока) или столба бурового раствора",
        ],
      },
      {
        code: "20601257",
        text: "Какая максимально допустимая скорость воздуха в вентиляционных скважинах угольных шахт?",
        answers: [
          "Скорость воздуха не ограничена",
          "15 м/с",
          "8 м/с",
          "12 м/с",
        ],
        correctAnswers: ["Скорость воздуха не ограничена"],
      },
      {
        code: "20601258",
        text: "Какие перечисленные горные выработки и скважины угольных шахт не подлежат изоляции?",
        answers: [
          "Горные выработки, используемые для отвода метана из выработанных пространств, со стороны действующих горных выработок",
          "Скважины, предназначенные для борьбы с внезапными выбросами угля (породы) и газа",
          "Неиспользуемые горные выработки и скважины",
          "Отработанные выемочные участки (поля)",
          "Все перечисленные горные выработки и скважины",
        ],
        correctAnswers: [
          "Скважины, предназначенные для борьбы с внезапными выбросами угля (породы) и газа",
        ],
      },
      {
        code: "20601259",
        text: "Кем утверждается проект и план перевода скважины на газлифтную эксплуатацию?",
        answers: [
          "Уполномоченным лицом надзорной организации",
          "Руководителем участка добычи",
          "Техническим руководителем организации",
          "Руководителем проектной организации",
        ],
        correctAnswers: ["Техническим руководителем организации"],
      },
      {
        code: "20601260",
        text: "Какие требования предъявляются к условиям закладки скважин, предназначенных для поисков, разведки, эксплуатации месторождений нефти, газа и газового конденсата?",
        answers: [
          "Скважины должны закладываться в пределах земельного отвода в зоне производственного назначения",
          "Скважины должны закладываться в границах зоны действия ограничений по условиям охраны территорий",
          "Скважины должны закладываться за пределами границ зоны санитарной охраны источников водоснабжения и водопроводов питьевого назначения, водоохранных зон, охранных зон линий электропередачи, магистральных нефтегазопроводов, водозаборных, других промышленных и гражданских объектов",
        ],
        correctAnswers: [
          "Скважины должны закладываться за пределами границ зоны санитарной охраны источников водоснабжения и водопроводов питьевого назначения, водоохранных зон, охранных зон линий электропередачи, магистральных нефтегазопроводов, водозаборных, других промышленных и гражданских объектов",
        ],
      },
      {
        code: "20601261",
        text: "Какие скважины после окончания бурения должны быть перекрыты?",
        answers: [
          "Скважины, диаметр устья которых более 250 мм",
          "Скважины, диаметр устья которых менее 200 мм",
          "Скважины, диаметр устья которых равен 180 мм",
          "Скважины, диаметр устья которых более 220 мм",
        ],
        correctAnswers: ["Скважины, диаметр устья которых более 250 мм"],
      },
      {
        code: "20601262",
        text: "С какой периодичностью и в каком объеме проводятся исследования эксплуатационных скважин на нефтегазодобывающих объектах?",
        answers: [
          "Ежемесячно, в соответствии с утвержденным планом работ по разработке месторождений",
          "В сроки и объемы, указанные в методических рекомендациях по проектированию разработки нефтяных и газонефтяных месторождений",
          "В соответствии с утвержденным планом работ, разработанным в соответствии с проектной документацией разработки данного месторождения",
        ],
        correctAnswers: [
          "В соответствии с утвержденным планом работ, разработанным в соответствии с проектной документацией разработки данного месторождения",
        ],
      },
      {
        code: "20601263",
        text: "Разрешается ли исследование разведочных и эксплуатационных скважин в случае отсутствия утилизации жидкого продукта?",
        answers: [
          "Разрешается без ограничений",
          "Разрешается по решению руководителя горнодобывающего предприятия",
          "Разрешается по согласованию с территориальным органом Ростехнадзора",
          "Запрещается во всех случаях",
        ],
        correctAnswers: [
          "Разрешается по согласованию с территориальным органом Ростехнадзора",
        ],
      },
      {
        code: "20601264",
        text: "Какое расстояние должно соблюдаться при креплении скважин от блок-манифольдов до агрегатов?",
        answers: [
          "Не менее 2 м",
          "Не менее 5 м",
          "Не менее 1,5 м",
          "Не менее 3 м",
        ],
        correctAnswers: ["Не менее 5 м"],
      },
      {
        code: "20601265",
        text: "Куда должны иметь выход системы замера дебита, контроля пуска, остановки скважин?",
        answers: [
          "На пульт насосного оборудования",
          "На пульт бурильщика",
          "На диспетчерский пункт",
          "На центральный пульт нефтегазодобывающего управления",
        ],
        correctAnswers: ["На диспетчерский пункт"],
      },
      {
        code: "20601266",
        text: "Какие фонтанные скважины должны оснащаться внутрискважинным оборудованием (пакер и клапан-отсекатель, циркуляционный клапан, станция управления)?",
        answers: [
          "Фонтанные скважины с дебитом от 300 т/сут нефти или 400 тыс.м³/сут газа и более, расположенные на расстоянии менее 1 км от населенного пункта",
          "Фонтанные скважины с дебитом от 350 т/сут нефти и более",
          "Фонтанные скважины расположенные на расстоянии менее 1,5 км от населенного пункта",
          "Фонтанные скважины с дебитом 400 т/сут нефти или 500 тыс.м³/сут газа и более, расположенные на расстоянии менее 500 м от населенного пункта",
        ],
        correctAnswers: [
          "Фонтанные скважины с дебитом 400 т/сут нефти или 500 тыс.м³/сут газа и более, расположенные на расстоянии менее 500 м от населенного пункта",
        ],
      },
      {
        code: "20601267",
        text: "Что должны обеспечивать конструкция и схема колонной устьевой обвязки, фонтанной арматуры?",
        answers: [
          "Контроль температуры в стволе во время проведения работ при глушении скважины",
          "Оптимальные режимы работы скважины, возможность герметизации трубного, затрубного и межтрубных пространств, возможность выполнения технологических операций в скважине, глубинные исследования, отбор проб и контроль устьевого давления и температуры",
          "Контроль режима работы при бурении и подземном ремонте скважин, а также возможность безопасного проведения работ внутри скважин",
          "Возможность обеспечения капитального ремонта объектов горнодобычи",
        ],
        correctAnswers: [
          "Оптимальные режимы работы скважины, возможность герметизации трубного, затрубного и межтрубных пространств, возможность выполнения технологических операций в скважине, глубинные исследования, отбор проб и контроль устьевого давления и температуры",
        ],
      },
      {
        code: "20601268",
        text: "Каким должно быть расстояние между устьями скважин при размещении кустовых площадок на вечномерзлых грунтах?",
        answers: [
          "Не менее 1,2 диаметра растепления пород вокруг устья скважин",
          "Не должно превышать диаметр зоны протаивания мерзлых пород, рассчитанной на период непрерывной эксплуатации скважин",
          "Соответствующим одному радиусу растепления пород вокруг устья скважин",
        ],
        correctAnswers: [
          "Не менее 1,2 диаметра растепления пород вокруг устья скважин",
        ],
      },
      {
        code: "20601269",
        text: "Какое оборудование должно быть установлено на скважинах, где ожидаемое давление на устье превышает 700 кгс/см² (70 МПа)?",
        answers: [
          "Заводской блок с тремя регулируемыми дросселями с ручным управлением",
          "Заводской блок с двумя регулируемыми дросселями (один с дистанционным управлением, второй - с ручным управлением)",
          "Заводской блок с двумя регулируемыми дросселями, снабженными  дистанционным управлением",
          "Заводской блок с тремя регулируемыми дросселями - два с дистанционным и один с ручным управлением",
        ],
        correctAnswers: [
          "Заводской блок с тремя регулируемыми дросселями - два с дистанционным и один с ручным управлением",
        ],
      },
      {
        code: "20601270",
        text: "Какое минимальное расстояние должно быть между парораспределительным пунктом и устьем нагнетательной скважины?",
        answers: ["20 м", "25 м", "10 м", "15 м"],
        correctAnswers: ["25 м"],
      },
      {
        code: "20601271",
        text: "Допускаются ли вибрация и гидравлические удары в нагнетательных коммуникациях?",
        answers: [
          "Допускаются при расчете гидравлического удара в нагнетательных трубопроводах с учетом влияния времени регулирования",
          "Допускаются в пределах установленных в планах работ",
          "Не допускаются",
          "Допускаются при нахождении работников в безопасной зоне",
        ],
        correctAnswers: ["Допускаются в пределах установленных в планах работ"],
      },
      {
        code: "20601272",
        text: "Каковы требования к производству работ по глубинным измерениям в скважинах с избыточным давлением на устье?",
        answers: [
          "Работы производятся с применением многокомпонентного состава утяжеленного бурового раствора",
          "Производятся измерительные работы в соответствии с условиями заказчика",
          "Производятся с применением лубрикатора, опрессованного на рабочее давление, установленное изготовителем, а после установки - на давление опрессовки колонны",
        ],
        correctAnswers: [
          "Производятся с применением лубрикатора, опрессованного на рабочее давление, установленное изготовителем, а после установки - на давление опрессовки колонны",
        ],
      },
      {
        code: "20601273",
        text: "Чем должно быть оборудовано устье скважины при эксплуатации ее штанговыми насосами?",
        answers: [
          "Универсальной перфорационной задвижкой",
          "Запорной арматурой и сальниковым устройством для герметизации штока",
          "Трехходовым клапаном и сальниковым устройством",
          "Запорной арматурой и обратным клапаном",
          "Устройством звуковой и световой сигнализации для контроля утечек",
        ],
        correctAnswers: [
          "Запорной арматурой и сальниковым устройством для герметизации штока",
        ],
      },
      {
        code: "20601274",
        text: "На каком расстоянии от устья скважины располагаются передвижные насосные установки и каким должно быть расстояние между ними?",
        answers: [
          "Не менее 20 м от устья скважины, расстояние между ними должно быть не менее 1 м",
          "Не менее 15 м от устья скважины, расстояние между ними должно быть не менее 1,5 м",
          "Не менее 10 м от устья скважины, расстояние между ними должно быть не менее 1 м",
          "Не менее 5 м от устья скважины, расстояние между ними должно быть не менее 2 м",
        ],
        correctAnswers: [
          "Не менее 10 м от устья скважины, расстояние между ними должно быть не менее 1 м",
        ],
      },
      {
        code: "20601275",
        text: "На каком расстоянии от устья скважины разрешается устанавливать компрессоры и парогенераторные установки?",
        answers: [
          "Не менее 20 м от устья скважины",
          "Не менее 15 м от устья скважины",
          "Не менее 25 м от устья скважины",
          "Не менее 10 м от устья скважины",
        ],
        correctAnswers: ["Не менее 25 м от устья скважины"],
      },
      {
        code: "20601276",
        text: "С какой периодичностью производится осмотр устья ликвидированных горных выработок, имеющих выход на земную поверхность, комиссией, назначенной распорядительным документом руководителя шахты?",
        answers: [
          "Не менее двух раз в год",
          "Не менее одного раза в год",
          "Не менее одного раза в три года",
          "Не менее одного раза в пять лет",
        ],
        correctAnswers: ["Не менее двух раз в год"],
      },
      {
        code: "20601277",
        text: "Какие прострелочно-взрывные аппараты должны подниматься над устьем скважины и опускаться с помощью грузоподъемных механизмов?",
        answers: [
          "Массой более 40 кг",
          "Массой более 20 кг или длиной более 1 м",
          "Массой более 35 кг или длиной более 1,2 м",
          "Массой более 30 кг или длиной равной 1 м",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20601278",
        text: "Кто должен осмотреть состояние кровли и стенок выработки и принять меры по приведению их в безопасное состояние перед началом работы по механизированному заряжанию шпуров, скважин или камер? Укажите все правильные ответы.",
        answers: [
          "Руководитель взрывных работ",
          "Взрывник",
          "Горный мастер",
          "Инженер-геотехник",
        ],
        correctAnswers: ["Руководитель взрывных работ", "Взрывник"],
      },
      {
        code: "20601279",
        text: "Что из нижеперечисленного должно быть указано в плане производства работ по нагнетанию в скважину газа, пара, химических и других агентов?",
        answers: [
          "Только порядок подготовительных работ и меры безопасности",
          "Только схема размещения оборудования",
          "Только технология проведения процесса",
          "Только ответственный руководитель работ",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20601280",
        text: "С учетом каких параметров производятся выбор обсадных труб и расчет обсадных колонн на стадиях строительства и эксплуатации скважин?",
        answers: [
          "С учетом возможного гидроразрыва пород или развитие интенсивного поглощения раствора",
          "С учетом возникновения давления гидростатического столба бурового раствора максимальной плотности",
          "С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при полном замещении раствора пластовым флюидом или газожидкостной смесью, снижении уровня, а также осевых нагрузок на трубы и агрессивности флюида",
          "С учетом минимальных гидростатических давлений составного столба бурового раствора и жидкости затворения цемента над пластовыми давлениями перекрываемых флюидосодержащих горизонтов",
        ],
        correctAnswers: [
          "С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при полном замещении раствора пластовым флюидом или газожидкостной смесью, снижении уровня, а также осевых нагрузок на трубы и агрессивности флюида",
        ],
      },
      {
        code: "20601281",
        text: "Какие способы соединений труб используются для обвязки скважины и аппаратуры, а также для газопроводов при фонтанной и газлифтной эксплуатации скважин?",
        answers: [
          "Фланцевые и резьбовые соединения",
          "Соединения с крепежными хомутами и сварочные соединения",
          "Муфтовые резьбовые соединения",
          "Сварные соединения, а также фланцевые - только в местах установки задвижек и другой арматуры",
          "Резьбовые соединения с изоляцией",
        ],
        correctAnswers: [
          "Сварные соединения, а также фланцевые - только в местах установки задвижек и другой арматуры",
        ],
      },
      {
        code: "20601282",
        text: "В каком порядке производятся передача скважин для ремонта или реконструкции и приемка скважин после завершения ремонтных работ от заказчика подрядчику?",
        answers: [
          "Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ устанавливается подрядной организацией и утверждается заказчиком",
          "Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ устанавливается эксплуатирующей организацией",
          "Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ устанавливается нормативно-технической документацией надзорной организации",
        ],
        correctAnswers: [
          "Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ устанавливается эксплуатирующей организацией",
        ],
      },
      {
        code: "20601283",
        text: "Каким документом устанавливаются периодичность и способы проверки состояния обсадных колонн по мере их износа и необходимые мероприятия по обеспечению безопасной проводки и эксплуатации скважин?",
        answers: [
          "Методическими указаниями, разработанными в соответствии с проектом производства работ",
          "Правилами безопасности при ведении горных работ  и переработке твердых полезных ископаемых",
          "Рабочим проектом или иной документацией, содержащей аналогичные требования",
          "Техническим регламентом, утвержденным территориальным органом Ростехнадзора",
        ],
        correctAnswers: [
          "Рабочим проектом или иной документацией, содержащей аналогичные требования",
        ],
      },
      {
        code: "20601284",
        text: "В каком случае запрещается приступать к спуску технических и эксплуатационных колонн в скважину?",
        answers: [
          "Только если в скважине имеются осложнения с затяжками и посадками бурильной колонны",
          "Только если скважина имеет осложнения, вызванные осыпями и обвалами, до ликвидации данных осложнений",
          "Только если скважина осложнена поглощением бурового раствора с одновременным флюидопроявлением",
          "В любом из перечисленных случаев",
        ],
        correctAnswers: ["В любом из перечисленных случаев"],
      },
      {
        code: "20601285",
        text: "Какие требования предъявляются к спуску забойного электронагревателя в скважину?",
        answers: [
          "Операции должны быть механизированы, с использованием специального лубрикатора, при герметизированном устье",
          "Все операции по спуску должны выполняться вручную, с использованием кабель-троса при открытом устье скважины при выполнении всех подготовительных работ",
          "Операции должны выполняться дистанционно, с использованием специального обратного клапана, при постоянном наблюдении за устьем",
          "Операции должны быть автоматизированы, с использованием превенторной установки, с фиксацией работ по монтажу и демонтажу установки",
        ],
        correctAnswers: [
          "Операции должны быть механизированы, с использованием специального лубрикатора, при герметизированном устье",
        ],
      },
      {
        code: "20601286",
        text: "При каких условиях допускается подключать сетевой кабель к пусковому оборудованию электронагревателя?",
        answers: [
          "Только после завершения всех подготовительных работ в скважине и на устье",
          "После заземления электрооборудования",
          "После подключения кабель-троса к трансформатору",
          "Только после удаления людей с участка проведения работ",
          "После выполнения всех условий",
        ],
        correctAnswers: ["После выполнения всех условий"],
      },
      {
        code: "20601287",
        text: "Какой должна быть длина линий сбросов на факелы от блоков глушения и дросселирования для нефтяных скважин с газовым фактором менее 200 м³/т?",
        answers: [
          "Не менее 15 м",
          "Не менее 30 м",
          "Не менее 10 м",
          "не менее 25 м",
        ],
        correctAnswers: ["Не менее 30 м"],
      },
      {
        code: "20601288",
        text: "Каким образом должно осуществляться управление запорной арматурой скважины, оборудованной под нагнетание пара или горячей воды?",
        answers: [
          "Ручным способом, с применением средств защиты",
          "Дистанционно и методом ручного управления с использованием защитных средств",
          "Только дистанционно",
        ],
        correctAnswers: ["Только дистанционно"],
      },
      {
        code: "20601289",
        text: "На каких пластах угольных шахт допускается в качестве вентиляционных печей использовать скважины, пробуренные диаметром не менее 0,7 м?",
        answers: [
          "Мощностью менее 6 м",
          "Мощностью менее 8 м",
          "Мощностью менее 5 м",
          "На всех мощных пластах",
        ],
        correctAnswers: ["Мощностью менее 5 м"],
      },
      {
        code: "20601290",
        text: "На каком минимальном расстоянии выше уровня почвы выемочной печи (штрека) должен быть расположен нижний уровень вентиляционной скважины на пластах средней мощности и мощных пластах?",
        answers: ["1,0 м", "0,5 м", "0,3 м", "0,8 м"],
        correctAnswers: ["0,5 м"],
      },
      {
        code: "20601291",
        text: "При каких углах падения пласта производится выемка надштрековых целиков у вентиляционных штреков одновременно с отработкой лав нижележащего этажа?",
        answers: ["До 30°", "До 40°", "До 35°"],
        correctAnswers: ["До 30°"],
      },
      {
        code: "20601292",
        text: "Каким должно быть отставание лавы каждого нижележащего слоя от границы обрушенного или заложенного пространства лавы вышележащего слоя на пластах с углом падения более 30°?",
        answers: [
          "Не менее 20 м",
          "Не менее 10 м",
          "От 10 до 15 м",
          "От 15 до 20 м",
        ],
        correctAnswers: ["Не менее 20 м"],
      },
      {
        code: "20601293",
        text: "Каким должно быть максимальное расстояние между смежными лавами при последовательном проветривании?",
        answers: ["250 м", "100 м", "300 м", "200 м"],
        correctAnswers: ["300 м"],
      },
      {
        code: "20601294",
        text: "После выполнения каких работ разрешается опускать щитовое перекрытие при разработке мощных пластов?",
        answers: [
          "Только после обрушения потолочины над щитом для создания предохранительной подушки высотой не менее мощности пласта",
          "Только после оборудования щитового перекрытия предохранительными средствами (канатами, трапами, решетками)",
          "Только после монтажа не менее одной секции следующего щитового перекрытия (за исключением последнего щитового столба на выемочном участке)",
          "После выполнения всех перечисленных работ",
        ],
        correctAnswers: ["После выполнения всех перечисленных работ"],
      },
      {
        code: "20601295",
        text: "В каком случае допускается протягивать один предохранительный канат между крайними секциями щитового перекрытия при отработке мощных пластов?",
        answers: [
          "Ни в каком случае не допускается",
          "При мощности пласта менее 6 м",
          "Если данная мера прописана в документации по ведению горных работ",
          "В случае постоянного присутствия наблюдающего персонала во время проведения работ",
        ],
        correctAnswers: ["При мощности пласта менее 6 м"],
      },
      {
        code: "20601296",
        text: "Какие из перечисленных участков являются местами интенсивного пылеотложения при разработке пластов, опасных по взрывам угольной пыли?",
        answers: [
          "Только места в районе погрузочных пунктов и на протяжении 25 м от них по направлению вентиляционной струи",
          "Только конвейерные выработки, по которым транспортируется уголь",
          "Только подготовительные выработки, проводимые по углю и породе, на протяжении 50 м от их забоев",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20601297",
        text: "Какой вид пылевзрывозащиты применяется в шахтах для локализации и предупреждения взрывов угольной пыли?",
        answers: [
          "Только гидропылевзрывозащита",
          "Только сланцевая пылевзрывозащита",
          "Только комбинированная пылевзрывозащита",
          "Возможно применение всех перечисленных видов пылевзрывозащиты",
        ],
        correctAnswers: [
          "Возможно применение всех перечисленных видов пылевзрывозащиты",
        ],
      },
      {
        code: "20601298",
        text: "Допускается ли проведение горных работ на участках категории «опасно» на склонных к динамическим явлениям пластах?",
        answers: [
          "Допускается при наличии разрешающего документа Федеральной службы по надзору в сфере природопользования",
          "Допускается при одновременном проведении горных работ по добыче и работ по предотвращению динамических явлений",
          "Запрещается, за исключением работ, проводимых для приведения горного массива в неопасное состояние",
          "Запрещается любой вид работ",
        ],
        correctAnswers: [
          "Запрещается, за исключением работ, проводимых для приведения горного массива в неопасное состояние",
        ],
      },
      {
        code: "20601299",
        text: "Каким должно быть давление на форсунках (оросителях) выемочных и проходческих комбайнов на угольных шахтах?",
        answers: [
          "Не менее 0,7 МПа",
          "Определяется проектной документацией",
          "Не менее 0,5 МПа",
          "Не менее 1 МПа",
        ],
        correctAnswers: ["Определяется проектной документацией"],
      },
      {
        code: "20601300",
        text: "Где устанавливают аппаратуру аварийной связи и оповещения на угольных шахтах?",
        answers: [
          "Только у главного инженера шахты",
          "Только у диспетчера",
          "Только у абонентов",
          "У всех перечисленных",
        ],
        correctAnswers: ["У всех перечисленных"],
      },
      {
        code: "20601301",
        text: "Кто осуществляет перевод подземного пожара из действующего в потушенный на угольной шахте?",
        answers: [
          "Руководитель маркшейдерской службы и начальник производственного участка",
          "Руководитель горноспасательных работ",
          "Ответственные лица подразделения главного управления МЧС",
          "Комиссия, созданная распорядительным документом руководителя шахты",
        ],
        correctAnswers: [
          "Комиссия, созданная распорядительным документом руководителя шахты",
        ],
      },
      {
        code: "20601302",
        text: "В каких горных выработках угольных шахт минимальная средняя по сечению скорость воздуха составляет 0,25 м/с?",
        answers: [
          "В призабойном пространстве тупиковых горных выработок негазовых и газовых шахт",
          "В тупиковых горных выработках газовых шахт при ведении горных работ по их проведению в зонах повышенного горного давления",
          "В призабойном пространстве очистных выработок негазовых шахт и газовых шахт",
          "В выработках негазовых шахт и в горных выработках шахт всех категорий по газу, проветриваемых за счет общешахтной депрессии",
        ],
        correctAnswers: [
          "В призабойном пространстве тупиковых горных выработок негазовых и газовых шахт",
        ],
      },
      {
        code: "20601303",
        text: "Максимально допустимая скорость воздуха в каких горных выработках угольных шахт составляет 4 м/с?",
        answers: [
          "В  главных откаточных и вентиляционных штреках",
          "В очистных и тупиковых горных выработках",
          "В вертикальных горных выработках",
          "В открытых горных выработках",
        ],
        correctAnswers: ["В очистных и тупиковых горных выработках"],
      },
      {
        code: "20601304",
        text: "Максимально допустимая концентрация какого газа в рудничной атмосфере действующих горных выработок угольных шахт составляет 2 мг/м³?",
        answers: [
          "Оксида углерода",
          "Сернистого ангидрида",
          "Диоксида азота",
          "Сероводорода",
        ],
        correctAnswers: ["Диоксида азота"],
      },
      {
        code: "20601305",
        text: "Разрешается ли ведение очистных работ более чем в двух смежных этажах согласно Правилам безопасности в угольных шахтах?",
        answers: [
          "Разрешается только в порядке, указанном в документации проведения горных работ",
          "Правилами не регламентируется",
          "Разрешается",
          "Запрещается",
        ],
        correctAnswers: ["Запрещается"],
      },
      {
        code: "20601306",
        text: 'Какой порядок одновременного ведения очистных работ на смежных этажах предусмотрен "Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          "При одновременном ведении очистных работ на смежных этажах забои нижнего этажа должны опережать забои верхнего этажа на расстояние не более 1 м",
          "При одновременном ведении очистных работ на смежных этажах забои верхнего этажа должны опережать забои нижнего этажа на безопасное расстояние, определенное проектом",
          "При одновременном ведении очистных работ на смежных этажах забои нижнего этажа должны опережать забои верхнего этажа на безопасное расстояние",
        ],
        correctAnswers: [
          "При одновременном ведении очистных работ на смежных этажах забои верхнего этажа должны опережать забои нижнего этажа на безопасное расстояние, определенное проектом",
        ],
      },
      {
        code: "20601307",
        text: "Какая максимальная концентрация метана в трубопроводах для изолированного отвода метана и в газодренажных горных выработках является допустимой?",
        answers: [
          "3,5 % по объему",
          "2,0 % по объему",
          "1,0 % по объему",
          "0,75 % по объему",
        ],
        correctAnswers: ["3,5 % по объему"],
      },
      {
        code: "20601308",
        text: "Чем осуществляются вскрытие и подготовка пластов угля, склонных к самовозгоранию?",
        answers: [
          "Горными выработками, пройденными по породам или по пластам угля, с применением мер, обеспечивающих безопасное ведение горных работ",
          "Полевыми выработками",
          "Горными выработками, предусмотренными техническим проектом на разработку месторождений",
          "Выработками, вид которых согласован с надзорными органами",
        ],
        correctAnswers: [
          "Горными выработками, пройденными по породам или по пластам угля, с применением мер, обеспечивающих безопасное ведение горных работ",
        ],
      },
      {
        code: "20601309",
        text: "Куда направляют перечень пластов, склонных к самовозгоранию, после его утверждения? Укажите все правильные ответы.",
        answers: [
          "В главное управление агентства геодезии и картографии",
          "В территориальное подразделение государственного пожарного надзора",
          "В территориальный орган местного самоуправления",
          "В территориальный орган Ростехнадзора",
          "В ПАСС(Ф), обслуживающее шахту",
        ],
        correctAnswers: [
          "В территориальный орган Ростехнадзора",
          "В ПАСС(Ф), обслуживающее шахту",
        ],
      },
      {
        code: "20601310",
        text: "С какой периодичностью угледобывающие организации определяют склонность отрабатываемых пластов к самовозгоранию?",
        answers: [
          "Не реже одного раза в три года",
          "Не реже одного раза в пять лет",
          "Не реже одного раза в год",
          "Периодичность проверки определяет главный инженер шахты",
        ],
        correctAnswers: ["Не реже одного раза в три года"],
      },
      {
        code: "20601311",
        text: "В каком случае разрешается ведение горных работ по добыче угля по каждому пласту для строящихся (реконструируемых) газовых шахт?",
        answers: [
          "При ведении горных работ по добыче угля по каждому пласту в нескольких уклонных полях",
          "Разрешается без ограничений",
          "При ведении горных работ по добыче угля по каждому пласту в одном уклонном поле",
          "При ведении горных работ по добыче угля по каждому пласту в двух уклонных полях",
        ],
        correctAnswers: [
          "При ведении горных работ по добыче угля по каждому пласту в одном уклонном поле",
        ],
      },
      {
        code: "20601312",
        text: "Кто из должностных лиц организует выявление провалов земной поверхности, образовавшихся при ведении горных работ, периодический контроль их состояния и выполнение мер по их ликвидации?",
        answers: [
          "Главный инженер шахты",
          "Технический руководитель организации",
          "Руководитель маркшейдерской службы",
          "Начальник участка аэрологической безопасности шахты",
        ],
        correctAnswers: ["Главный инженер шахты"],
      },
      {
        code: "20601313",
        text: "Кем утверждается проект работ и мероприятий по ликвидации последствий горного удара?",
        answers: [
          "Техническим руководителем объекта",
          "Руководителем горнодобывающей организации",
          "Председателем комиссии по ликвидации последствий горного удара",
          "Специализированной организацией, выполняющей работы по проблеме горных ударов",
        ],
        correctAnswers: ["Техническим руководителем объекта"],
      },
      {
        code: "20601314",
        text: "За какое время до начала подготовительных и монтажных работ по ремонту и реконструкции скважин бригаде должна выдаваться схема расположения подземных и наземных коммуникаций?",
        answers: [
          "Не менее чем за двое суток",
          "Не менее чем за сутки",
          "Не менее чем за трое суток",
          "В день начала выполнения работ",
        ],
        correctAnswers: ["Не менее чем за трое суток"],
      },
      {
        code: "20601315",
        text: "На какой минимальной высоте от уровня пола должны быть размещены коммуникации: трубы, желоба над рабочими площадками?",
        answers: ["1,8 м", "2,0 м", "1,5 м", "2,2 м", "1,7 м"],
        correctAnswers: ["2,0 м"],
      },
      {
        code: "20601316",
        text: "При каких условиях допускается повторное использование рабочего проекта при бурении группы скважин на идентичных по геолого-техническим условиям площадях?",
        answers: [
          "При идентичности горно-геологических условий площадей разработки и частков месторождений",
          "При отличии плотности бурового раствора от проектной в пределах ± 0,3 г/см³",
          "При одинаковых проектных глубинах по стволу скважин",
          "При одинаковом назначении и конструкции скважин",
          "При выполнении всех перечисленных условий",
        ],
        correctAnswers: ["При выполнении всех перечисленных условий"],
      },
      {
        code: "20601317",
        text: "Оценка каких параметров проводится при бурении скважин (шпуров)? Укажите все правильные ответы.",
        answers: [
          "Температуры нагрева корпуса буровой коронки",
          "Обводненности кровли горной выработки по скорости бурения и выходу буровой мелочи",
          "Прочностных свойств пород",
          "Несущей способности анкеров, применяемых при креплении горной выработки",
        ],
        correctAnswers: [
          "Обводненности кровли горной выработки по скорости бурения и выходу буровой мелочи",
          "Прочностных свойств пород",
        ],
      },
      {
        code: "20601318",
        text: "Какие показатели должны контролироваться при бурении наклонно-направленных и горизонтальных скважин?",
        answers: [
          "Крутящий момент на роторе при роторном способе бурения, давление в манифольде буровых насосов",
          "Расход бурового раствора на входе и выходе из скважины, показатели мощности и напора насосов",
          "Азимут, зенитный угол ствола скважины, пространственное расположение ствола скважины, взаимное расположение стволов бурящейся и ранее пробуренных соседних скважин",
          "Плотность и реологические показатели, структурно-механические свойства бурового раствора",
        ],
        correctAnswers: [
          "Азимут, зенитный угол ствола скважины, пространственное расположение ствола скважины, взаимное расположение стволов бурящейся и ранее пробуренных соседних скважин",
        ],
      },
      {
        code: "20601319",
        text: "В каких случаях не устанавливается превенторная сборка при бурении скважин для разведки и добычи метана угольных месторождений (площадей, участков)?",
        answers: [
          "По указанию руководителя подрядной организации, производящей буровые работы",
          "В случаях, предусмотренных в рабочем проекте на бурение",
          "В случае вскрытия изученного разреза с аномально низким пластовым давлением (далее – АНПД), представленного обводненными угольными пластами",
        ],
        correctAnswers: [
          "В случае вскрытия изученного разреза с аномально низким пластовым давлением (далее – АНПД), представленного обводненными угольными пластами",
        ],
      },
      {
        code: "20601320",
        text: "Какой документ является основным для производства буровых работ?",
        answers: [
          "Земельный кодекс Российской Федерации",
          "Технический проект обустройства месторождения углеводородов",
          "Рабочий проект на производство буровых работ",
        ],
        correctAnswers: ["Рабочий проект на производство буровых работ"],
      },
      {
        code: "20601321",
        text: "Какие требования предъявляются к установке бурового станка?",
        answers: [
          "Буровой станок должен быть установлен на специальной отведенной площадке на расстоянии 3 метров от верхней бровки уступа",
          "Буровой станок должен быть установлен на безопасном расстоянии от верхней бровки уступа в соответствии с проектом, но не менее 2 м от бровки до ближайшей точки опоры станка",
          "Буровой станок должен быть установлен на спланированной площадке на максимальном расстоянии от точки опоры станка до верхней бровки уступа",
        ],
        correctAnswers: [
          "Буровой станок должен быть установлен на безопасном расстоянии от верхней бровки уступа в соответствии с проектом, но не менее 2 м от бровки до ближайшей точки опоры станка",
        ],
      },
      {
        code: "20601322",
        text: "Чем должна определяться технология производства буровых работ в зонах распространения многолетнемерзлых пород?",
        answers: [
          "Условиями транспортировки к месту проведения работ",
          "Наличием рабочего персонала имеющего соответствующую квалификацию и опыт работы в данной зоне",
          "Мерзлотными и климатическими условиями данного региона",
          "Продолжительностью теплого периода года",
        ],
        correctAnswers: [
          "Мерзлотными и климатическими условиями данного региона",
        ],
      },
      {
        code: "20601323",
        text: "В каком случае буровые работы можно производить без применения дополнительных мер безопасности?",
        answers: [
          "В случае производства буровых работ в многолетнемерзлых грунтах",
          "Все буровые работы производятся только с применением дополнительных мер безопасности",
          "В случае производства буровых работ с кустовых площадок скважин",
          "В случае производства буровых работ на месторождениях с содержанием в нефти (газе) 3% об. сероводорода",
        ],
        correctAnswers: [
          "В случае производства буровых работ на месторождениях с содержанием в нефти (газе) 3% об. сероводорода",
        ],
      },
      {
        code: "20601324",
        text: "На какое минимальное расстояние от скважины должны быть убраны буровые установки, не имеющие приспособления для заряжания, в сложных горно-геологических условиях?",
        answers: ["5 м", "20 м", "15 м", "10 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "20601325",
        text: "Что из перечисленного не учитывается при планировании площадки для монтажа буровой установки?",
        answers: [
          "Обеспечение движения сточных вод в систему их сбора и очистки",
          "Естественный уклон местности",
          "Роза ветров",
        ],
        correctAnswers: ["Роза ветров"],
      },
      {
        code: "20601326",
        text: "В каком случае оснащение буровых установок верхним приводом не обязательно?",
        answers: [
          "В случае вскрытия пластов с ожидаемым содержанием в пластовом флюиде сернистого водорода свыше 6  % (объемных)",
          "В случае набора угла с радиусом кривизны 25 м в наклонно направленных скважинах",
          "В случае бурения скважин с глубиной по стволу менее 4500 м",
          "В случае бурения горизонтального участка ствола скважины длиной 400 м в скважинах глубиной по вертикали 3200 м",
        ],
        correctAnswers: [
          "В случае бурения скважин с глубиной по стволу менее 4500 м",
        ],
      },
      {
        code: "20601327",
        text: "В каком случае должно обеспечиваться полное отключение оборудования и механизмов в закрытых помещениях буровой установки, где возможны возникновение или проникновение воспламеняющихся смесей?",
        answers: [
          "При достижении 40 % от нижнего предела воспламенения смеси воздуха с углеводородами",
          "При достижении 30 % от нижнего предела воспламенения смеси воздуха с углеводородами",
          "При достижении 50 % от нижнего предела воспламенения смеси воздуха с углеводородами",
          "При достижении 20 % от нижнего предела воспламенения смеси воздуха с углеводородами",
        ],
        correctAnswers: [
          "При достижении 50 % от нижнего предела воспламенения смеси воздуха с углеводородами",
        ],
      },
      {
        code: "20601328",
        text: "Должны ли буровые насосы оборудоваться компенсаторами давления? Если да, то какие требования при этом должны соблюдаться?",
        answers: [
          "Компенсаторы давления должны устанавливаться только на трех поршневых буровых насосах, при этом компенсаторы должны быть заполнены воздухом или инертным газом, с приспособлениями для контроля давления в компенсаторах",
          "На всех буровых насосах должны быть установлены компенсаторы давления, заполняемые воздухом или инертным газом, с конструкцией, предусматривающей установку манометра для измерения давления в газовой полости и обеспечивающей возможность сбрасывания давления до нуля",
          "Установка компенсаторов давления на буровых насосах необязательна в том случае, если проводятся мероприятия по обеспечению равномерности подачи промывочной жидкости",
          "На всех буровых насосах должны быть установлены гидрокомпенсаторы давления, заполняемые водой, с приспособлениями для контроля давления в компенсаторах",
        ],
        correctAnswers: [
          "На всех буровых насосах должны быть установлены компенсаторы давления, заполняемые воздухом или инертным газом, с конструкцией, предусматривающей установку манометра для измерения давления в газовой полости и обеспечивающей возможность сбрасывания давления до нуля",
        ],
      },
      {
        code: "20601329",
        text: "Откуда должен осуществляться пуск буровых насосов в работу?",
        answers: [
          "С местного поста управления",
          "С пульта бурильщика",
          "С пульта оператора, контролирующего технологический процесс",
          "Одновременно с пульта бурильщика и пульта управления оператора",
        ],
        correctAnswers: ["С местного поста управления"],
      },
      {
        code: "20601330",
        text: "Что необходимо предпринять в процессе подъема колонны бурильных труб для предупреждения газонефтеводопроявлений?",
        answers: [
          "Необходимо ввести промывочные жидкости, инертные по отношению к горным породам",
          "Следует производить долив бурового раствора в скважину",
          "Следует ввести в промывочную жидкость смазывающие добавки",
          "Необходимо обеспечить большую скорость восходящего потока жидкости в кольцевом пространстве",
        ],
        correctAnswers: [
          "Следует производить долив бурового раствора в скважину",
        ],
      },
      {
        code: "20601331",
        text: "В каком документе следует предусматривать мероприятия по контролю герметичности объекта хранения в процессе строительства и эксплуатации опасных производственных объектов подземных хранилищ газа?",
        answers: [
          "В проекте на консервацию и ликвидацию опасных производственных объектов подземных хранилищ газа",
          "В технологическом проекте на создание и эксплуатацию опасных производственных объектов подземных хранилищ газа",
          "В проектной документации полигона захоронения промышленных стоков, образующихся при эксплуатации подземных хранилищ газа",
        ],
        correctAnswers: [
          "В технологическом проекте на создание и эксплуатацию опасных производственных объектов подземных хранилищ газа",
        ],
      },
      {
        code: "20601332",
        text: "Каким образом следует располагать здания и сооружения с производственными процессами, выделяющими в атмосферу вредные и (или) горючие вещества при обустройстве нефтяных, газовых и газоконденсатных месторождений?",
        answers: [
          "За границами прилегающих народно-хозяйственных объектов",
          "За пределами санитарно-защитной зоны населенных пунктов с наветренной стороны",
          "На производственных площадках преимущественно с подветренной стороны от других зданий и сооружений с учетом розы ветров преобладающего направления",
          "На максимально удаленном расстоянии от городов и других населенных пунктов",
        ],
        correctAnswers: [
          "На производственных площадках преимущественно с подветренной стороны от других зданий и сооружений с учетом розы ветров преобладающего направления",
        ],
      },
      {
        code: "20601333",
        text: "Допускается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений пересечение трубопроводов с токсичными жидкостями и газами с железнодорожными подъездными путями?",
        answers: [
          "Допускается если соблюдены все меры безопасности",
          "Не допускается, за исключением продуктопроводов к двусторонним сливоналивным железнодорожным эстакадам",
          "Допускается при условии прокладки их вдоль стены, перекрытия которой имеют максимальный предел огнестойкости",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "Не допускается, за исключением продуктопроводов к двусторонним сливоналивным железнодорожным эстакадам",
        ],
      },
      {
        code: "20601334",
        text: "Какие радиусы закругления рельсовых путей и переводных кривых во вновь вводимых горных выработках устанавливаются для колеи 600 мм и 900 мм?",
        answers: [
          "Не менее 10 м и не менее 15 м соответственно",
          "Не менее 8 м и не менее 12 м соответственно",
          "Не менее 12 м и не менее 20 м соответственно",
          "Не менее 5 м и не менее 8 м соответственно",
        ],
        correctAnswers: ["Не менее 12 м и не менее 20 м соответственно"],
      },
      {
        code: "20601335",
        text: "Какие радиусы закругления рельсовых путей и переводных кривых в действующих горных выработках устанавливаются для колеи 600 мм и 900 мм?",
        answers: [
          "Не менее 8 м и не менее 12 м соответственно",
          "Не менее 5 м и не менее 8 м соответственно",
          "Не менее 15 м и не менее 23 м соответственно",
          "Не менее 12 м и не менее 20 м соответственно",
        ],
        correctAnswers: ["Не менее 8 м и не менее 12 м соответственно"],
      },
      {
        code: "20601336",
        text: "В каком случае допускается эксплуатация стрелочных переводов рельсовых путей?",
        answers: [
          "При сбитых и изогнутых в продольном и поперечном направлениях остряках (перьях)",
          "При закрытых канавках для тяг приводов стрелочных переводов",
          "При отсутствии фиксации положения стрелочных переводов с помощью фиксаторов",
          "При разъединенных стрелочных тягах",
        ],
        correctAnswers: [
          "При закрытых канавках для тяг приводов стрелочных переводов",
        ],
      },
      {
        code: "20601337",
        text: "Каким должно быть свободное расстояние от наиболее выступающих частей механических и ручных приводов стрелочных переводов откаточных путей до кромки подвижного состава?",
        answers: [
          "Не менее 0,7 м",
          "Не менее 0,6 м",
          "От 0,5 м до 0,65 м",
          "От 0,65 м до 0,7 м",
        ],
        correctAnswers: ["Не менее 0,7 м"],
      },
      {
        code: "20601338",
        text: "Разрешается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений последовательное соединение заземляющим проводником нескольких аппаратов или резервуаров?",
        answers: [
          "Разрешается",
          "Не допускается, за исключением аппаратов или резервуаров, находящихся в одном обваловании",
          "Не допускается",
          "Допускается при условии, что общее сопротивление заземляющего проводника не превышает 20 Ом",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "20601339",
        text: "Разрешается ли прокладка заглубленных каналов и тоннелей при обустройстве нефтяных, газовых и газоконденсатных месторождений для размещения кабелей в помещениях и на территории наружных установок, имеющих источники возможного выделения в атмосферу вредных веществ плотностью по воздуху более 0,8, а также источники возможных проливов горючих жидкостей и жидкостей, содержащих сернистый водород?",
        answers: [
          "Запрещается",
          "Разрешается по согласованию с территориальным органом Ростехнадзора",
          "Разрешается, если позволяет схема коммуникаций",
          "Запрещается, за исключением каналов и тоннелей, подлежащих последующей засыпке",
        ],
        correctAnswers: [
          "Запрещается, за исключением каналов и тоннелей, подлежащих последующей засыпке",
        ],
      },
      {
        code: "20601340",
        text: "Какие требования предъявляются правилами к системе контроля состояния воздушной среды для закрытых помещений объектов сбора, подготовки и транспортировки нефти, газа и конденсата?",
        answers: [
          "Система должна иметь блокировку от выключения технического оборудования, включая перекрытие запорной арматуры, все помещения должны иметь принудительную систему вентиляции",
          "Система должна иметь блокировку перед включением оборудования, все помещения должны иметь постоянно действующую противопожарную систему",
          "Система должна быть сблокирована с системой звуковой и световой аварийной сигнализации, все помещения должны иметь постоянно действующую систему приточно-вытяжной вентиляции",
          "Система должна быть сблокирована с аварийной системой выключения оборудования, включая перекрытие клапанов всех запитанных помещений",
        ],
        correctAnswers: [
          "Система должна быть сблокирована с системой звуковой и световой аварийной сигнализации, все помещения должны иметь постоянно действующую систему приточно-вытяжной вентиляции",
        ],
      },
      {
        code: "20601341",
        text: "Какая документация должна быть размещена на объектах сбора и подготовки нефти и газа (ЦПС, УПН, УКПГ, ГП), насосных и компрессорных станциях (ДНС, КС)?",
        answers: [
          "Технические паспорта и документация на оборудование насосных и компрессорных станций",
          "Технологическая схема, утвержденная техническим руководителем организации, с указанием номеров задвижек, аппаратов, направлений потоков, полностью соответствующих их нумерации в проектной документации",
          "План аварийно-спасательных мероприятий, схема эвакуации работников",
          "Технические паспорта оборудования (приборов) и список ответственных лиц за проведение работ",
          "Приказ о назначении лиц, ответственных по запуску и отключению технологического оборудования",
        ],
        correctAnswers: [
          "Технологическая схема, утвержденная техническим руководителем организации, с указанием номеров задвижек, аппаратов, направлений потоков, полностью соответствующих их нумерации в проектной документации",
        ],
      },
      {
        code: "20601342",
        text: "Через какое расстояние эстакады для трубопроводов при обустройстве нефтяных, газовых и газоконденсатных месторождений должны быть электрически соединены с проходящими по ним трубопроводами и заземлены?",
        answers: [
          "Через 150 - 200 м, а также в начале и в конце",
          "Через 350 - 400 м, а также в начале и в конце",
          "Через 200 - 300 м, а также в начале и в конце",
          "Через каждые 100 м",
        ],
        correctAnswers: ["Через 200 - 300 м, а также в начале и в конце"],
      },
      {
        code: "20601343",
        text: "Что необходимо сделать с газораспределительными трубопроводами после их монтажа?",
        answers: [
          "Продуть азотом и опрессовать жидкостью на давление, превышающее на 15 % максимальное расчетное",
          "Продуть инертным газом и провести пневматическое испытание на давление, превышающее на 35 % расчетное",
          "Продуть кислородом и опрессовать жидкостью на давление, превышающее на 50 % максимальное рабочее",
          "Продуть сжатым воздухом и опрессовать жидкостью на давление, превышающее на 25 % максимальное рабочее",
        ],
        correctAnswers: [
          "Продуть сжатым воздухом и опрессовать жидкостью на давление, превышающее на 25 % максимальное рабочее",
        ],
      },
      {
        code: "20601344",
        text: "В каком случае установка автоматического клапана-отсекателя на выкидной линии газовых и газоконденсатных скважин не требуется?",
        answers: [
          "В случае наличия подземного клапана-отсекателя в составе комплекса подземного оборудования скважины",
          "В случае, если выполняется эксплуатационное бурение скважин",
          "В случае, если выполняется разведочное бурение",
          "В случае, если содержание сероводорода в природном газе или газоконденсате составляет не более 2 %",
        ],
        correctAnswers: [
          "В случае наличия подземного клапана-отсекателя в составе комплекса подземного оборудования скважины",
        ],
      },
      {
        code: "20601345",
        text: "В каком положении должна находиться задвижка на отводе от затрубного пространства при закачке теплоносителя (с установкой пакера)?",
        answers: ["В любом", "В закрытом", "В полуоткрытом", "В открытом"],
        correctAnswers: ["В открытом"],
      },
      {
        code: "20601346",
        text: 'Может ли "газовый режим" распространяться на отдельные участки, блоки, пласты или на шахту в целом?',
        answers: [
          "Только на шахту в целом",
          "Может распространяться на отдельные рабочие зоны (участки, блоки и т. д.)",
          "Только на горные выработки всего месторождения",
        ],
        correctAnswers: [
          "Может распространяться на отдельные рабочие зоны (участки, блоки и т. д.)",
        ],
      },
      {
        code: "20601347",
        text: "Куда должна передаваться информация о загазировании?",
        answers: [
          "Только в территориальный орган Ростехнадзора",
          "В территориальный орган Ростехнадзора и в Министерство Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "Только в Министерство Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
        ],
        correctAnswers: ["Только в территориальный орган Ростехнадзора"],
      },
      {
        code: "20601348",
        text: "Что из перечисленного не входит в мероприятия по разгазированию выработок?",
        answers: [
          "Выставление постов (на свежей струе) и запрещающих знаков в местах возможного подхода людей к выработкам, по которым двигается исходящая струя при разгазировании",
          "Снятие напряжения с электрооборудования и электрических сетей и устранение других источников воспламенения в выработках, по которым будет двигаться исходящая струя",
          "Обследование выработок после разгазирования перед допуском в них людей",
          "Возобновление работ в отперемыченных выработках, подключение электрооборудования и электрических сетей",
          "Контроль за разгазированием выработки и за свежей струей воздуха (концентрация газов в месте слияния исходящей и свежей струй воздуха не должна превышать: горючих газов - 1 %, ядовитых газов - ПДК)",
        ],
        correctAnswers: [
          "Возобновление работ в отперемыченных выработках, подключение электрооборудования и электрических сетей",
        ],
      },
      {
        code: "20601349",
        text: "Какие требования к оформлению проектной документации на разработку месторождений твердых полезных ископаемых, ликвидацию и консервацию горных выработок и первичную переработку минерального сырья указаны неверно?",
        answers: [
          "Объемы и детальность проработки отдельных разделов определяются авторами проектного документа в зависимости от сложности строения месторождений, количества эксплуатационных объектов и рассматриваемых вариантов их разработки, стадии проектирования",
          "Изменения, дополнения, корректировка проектной документации должны содержать только те разделы, которые подвергаются переработке в процессе разработки изменений",
          "В изменении, дополнении, корректировке к проектной документации не допускается делать ссылки на неизмененные разделы технического проекта либо помещать их в кратком изложении",
          "Проектная документация должна содержать все данные, позволяющие производить анализ проектных решений без личного участия авторов",
        ],
        correctAnswers: [
          "В изменении, дополнении, корректировке к проектной документации не допускается делать ссылки на неизмененные разделы технического проекта либо помещать их в кратком изложении",
        ],
      },
      {
        code: "20601350",
        text: "Какие сведения содержит титульный лист проектной документации на разработку месторождений твердых полезных ископаемых, ликвидацию и консервацию горных выработок и первичную переработку минерального сырья?",
        answers: [
          "Только название месторождения (участка)",
          "Только наименование пользователя недр",
          "Только наименование организации-исполнителя, грифы согласования и утверждения документации",
          "Только наименование, место и год составления проектной документации",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20601351",
        text: "Кто утверждает регламент технологического процесса производства и подготовки взрывчатых веществ и изменения в него?",
        answers: [
          "Ответственное лицо территориального органа Ростехнадзора",
          "Руководитель (технический руководитель) эксплуатирующей организации",
          "Работник организации, ответственный за пожарную безопасность",
          "Комиссия эксплуатирующей организации",
        ],
        correctAnswers: [
          "Руководитель (технический руководитель) эксплуатирующей организации",
        ],
      },
      {
        code: "20601352",
        text: "С какой периодичностью проводится пересмотр Регламента технологического процесса производства и подготовки взрывчатых веществ?",
        answers: [
          "Не реже 1 раза в 1 год",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 5 лет",
        ],
        correctAnswers: ["Не реже 1 раза в 5 лет"],
      },
      {
        code: "20601353",
        text: "Кто устанавливает маршруты транспортирования взрывчатых материалов от склада ВМ на места работ (в пределах опасного производственного объекта)?",
        answers: [
          "Руководитель (технический руководитель) организации, эксплуатирующей опасный производственный объект",
          "Руководитель группы открытых горных работ, ответственный за проведение взрывных работ",
          "Представитель территориального органа Ростехнадзора",
          "Руководитель взрывных работ организации, эксплуатирующей опасный производственный объект",
        ],
        correctAnswers: [
          "Руководитель (технический руководитель) организации, эксплуатирующей опасный производственный объект",
        ],
      },
      {
        code: "20601354",
        text: "Какое из перечисленных требований к транспортированию взрывчатых материалов указано верно? Укажите все правильные ответы.",
        answers: [
          "Спуск-подъем взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами должен проводиться вне очереди",
          "При спуске-подъеме взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами по наклонным выработкам в людских вагонетках на каждом сиденье должно находиться не более одного взрывника или подносчика",
          "Разрешается спускаться или подниматься в одной клети только одному взрывнику или подносчику с сумками с взрывчатыми материалами",
          "Транспортирование взрывчатых материалов по подземным выработкам должно осуществляться со скоростью не более 10 м/с",
        ],
        correctAnswers: [
          "Спуск-подъем взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами должен проводиться вне очереди",
          "При спуске-подъеме взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами по наклонным выработкам в людских вагонетках на каждом сиденье должно находиться не более одного взрывника или подносчика",
        ],
      },
      {
        code: "20601355",
        text: "Каким лицам право руководства взрывными работами предоставляется после дополнительного обучения по программе, согласованной с федеральным органом исполнительной власти в области промышленной безопасности?",
        answers: [
          'Лицам, окончившим высшие учебные заведения по специальности "Открытые горные работы"',
          'Лицам, окончившим высшие учебные заведения по специальности "Подземная разработка пластовых месторождений"',
          'Лицам, окончившим средние специальные учебные заведения по специальности "Шахтное строительство"',
          'Лицам, окончившим средние специальные учебные заведения по специальности "Подземная разработка месторождений полезных ископаемых"',
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20601356",
        text: "В течение какого времени должна проводиться стажировка взрывников перед допуском к самостоятельному производству взрывных работ, в том числе после обучения на новый вид взрывных работ?",
        answers: [
          "В течение 14 дней",
          "В течение трех месяцей",
          "В течение одного месяца",
          "В течение двух месяцев",
        ],
        correctAnswers: ["В течение одного месяца"],
      },
      {
        code: "20601357",
        text: "С какой периодичностью должны проверяться знания требований безопасности работниками, связанными с обращением со взрывчатыми материалами и имеющими Единую книжку взрывника (за исключением заведующих складами взрывчатых материалов, пунктами производства взрывчатых материалов и руководителей взрывных работ) специальной комиссией организации с участием представителя территориального органа Ростехнадзора?",
        answers: [
          " Не реже одного раза в год",
          " Не реже одного раза в три года",
          " Не реже одного раза в два года",
          " Не реже одного раза в пять лет",
        ],
        correctAnswers: [" Не реже одного раза в два года"],
      },
      {
        code: "20601358",
        text: "С какой периодичностью должна проверяться техническая исправность транспортных средств, используемых для доставки взрывчатых материалов, лицом, назначенным распорядительным документом организации?",
        answers: [
          "Еженедельно",
          "Два раза в неделю",
          "Два раза в смену",
          "Ежесменно",
        ],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20601359",
        text: "В каком случае разрешается доставка аммиачно-селитренных взрывчатых веществ к местам проведения взрывных работ в подземных выработках в ковшах погрузочно-доставочных машин от участковых пунктов хранения и мест выгрузки взрывчатых материалов?",
        answers: [
          "При условии загрузки ковша не более 2/3 по его высоте",
          "При условии загрузки ковша не более 1/2 по его высоте вместе со средствами инициирования",
          "Ни в каком случае",
          "При условии присутствия в транспорте сопровождающего персонала, ответственного за доставку взрывчатых материалов",
        ],
        correctAnswers: [
          "При условии загрузки ковша не более 2/3 по его высоте",
        ],
      },
      {
        code: "20601360",
        text: "Какие из перечисленных помещений разрешается размещать в зданиях пунктов производства и подготовки взрывчатых веществ, за исключением зданий, в которых непосредственно производятся или подготавливаются взрывчатые вещества? Выберите 2 варианта ответа.",
        answers: [
          "Места постоянного хранения тары, отведенные в рабочем помещении",
          "Служебные помещения для производства сварочных работ",
          "Помещения временного пребывания дежурных слесарей и электриков",
          "Слесарные мастерские для мелкого текущего ремонта",
        ],
        correctAnswers: [
          "Помещения временного пребывания дежурных слесарей и электриков",
          "Слесарные мастерские для мелкого текущего ремонта",
        ],
      },
      {
        code: "20601361",
        text: "Какая устанавливается максимальная суммарная загрузка здания, в котором производятся или подготавливаются взрывчатые вещества, с учетом взрывчатых веществ, находящихся в вагоне, смесительно-зарядной машине или другом транспортном средстве и накопительных емкостях?",
        answers: ["50 т", "60 т", "30 т", "40 т"],
        correctAnswers: ["60 т"],
      },
      {
        code: "20601362",
        text: "В каком месте необходимо располагать зарядную для аккумуляторных погрузчиков, а также постоянную стоянку зарядных машин (смесительно-зарядных машин, транспортно-зарядных машин)?",
        answers: [
          "Вблизи от здания производства взрывчатых веществ на территории пунктов производства и подготовки взрывчатых веществ",
          "Не ближе 120 м от здания подготовки и производства взрывчатых веществ",
          "За территорией пунктов производства и подготовки взрывчатых веществ на расстоянии не ближе 50 м от здания подготовки и (или) производства взрывчатых веществ",
          "Определяется схемой развития горных работ",
        ],
        correctAnswers: [
          "За территорией пунктов производства и подготовки взрывчатых веществ на расстоянии не ближе 50 м от здания подготовки и (или) производства взрывчатых веществ",
        ],
      },
      {
        code: "20601363",
        text: "На каком расстоянии от места заряжания скважин и стоянки зарядной машины и трубопровода запрещается производить какие-либо работы, непосредственно не связанные с заряжанием?",
        answers: [
          "Не ближе 40 м",
          "Не ближе 70 м",
          "Не ближе 50 м",
          "Не ближе 30 м",
        ],
        correctAnswers: ["Не ближе 50 м"],
      },
      {
        code: "20601364",
        text: "В течение какого срока допускается размещать зарядные машины, загруженные взрывчатыми веществами, на специально выделенной площадке на территории склада ВМ?",
        answers: [
          "Не более одной недели",
          "На срок не более трех суток",
          "На срок не более двух суток",
          "На срок не более одной смены",
        ],
        correctAnswers: ["На срок не более двух суток"],
      },
      {
        code: "20601365",
        text: "Каким документом должны определяться место погрузки-выгрузки, меры безопасности, а также порядок погрузки-разгрузки взрывчатых материалов в околоствольных дворах шахт, рудников, штолен и надшахтных зданиях?",
        answers: [
          "Технологическим регламентом на проведение погрузочно-разгрузочных работ взрывчатых материалов",
          "Распорядительным документом организации (шахты, рудника)",
          "Предписанием территориального органа Ростехнадзора",
          "Проектной документацией",
        ],
        correctAnswers: [
          "Распорядительным документом организации (шахты, рудника)",
        ],
      },
      {
        code: "20601366",
        text: "В каком случае транспортные пути для перевозок взрывчатых материалов на территории поверхностных пунктов производства (подготовки) должны располагаться на расстоянии не менее 3 м от зданий?",
        answers: [
          "В случае необходимого безопасного их расположения от имеющихся на территории открытых огневых топок и источников открытого огня",
          "В случае, если пути предназначены для подъезда к этим зданиям",
          "В случае требуемого их расположения от зданий, где имеются открытые огневые топки и источники открытого огня или где производятся работы с открытым огнем",
          "В случае необходимого их расположения от вспомогательных зданий, находящихся на территории пункта",
        ],
        correctAnswers: [
          "В случае, если пути предназначены для подъезда к этим зданиям",
        ],
      },
      {
        code: "20601367",
        text: "Какая допускается максимальная скорость движения железнодорожного подвижного состава с опасными грузами на территории пункта подготовки взрывчатых веществ?",
        answers: ["20 км/ч", "30 км/ч", "15 км/ч", "10 км/ч"],
        correctAnswers: ["15 км/ч"],
      },
      {
        code: "20601368",
        text: "Работа каких конвейеров, транспортирующих пожаровзрывоопасные вещества, допускается без устройства блокировочных устройств?",
        answers: [
          "Ленточных конвейеров",
          "Цепных конвейеров",
          "Винтовых конвейеров",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20601369",
        text: "Каким образом запрещается производить заряжание шпуров (скважин) и монтаж взрывной сети на высоте более 2 м?",
        answers: [
          "С использованием погрузочно-доставочной техники",
          "С полков, примыкающих к забою",
          "С лестниц",
          "С оборудованных подъемных площадок (помостов)",
        ],
        correctAnswers: ["С лестниц"],
      },
      {
        code: "20601370",
        text: "Какое из перечисленных требований к мерам защиты от статического электричества технологического оборудования при взрывных работах указано неверно?",
        answers: [
          "Места для присоединения заземляющих проводников и способ их крепления должны быть указаны в технической документации оборудования",
          "Допускается последовательное включение в заземляющую шину (провод) нескольких заземляющих аппаратов, агрегатов или трубопроводов",
          "В каждом производственном здании должна быть составлена схема (карта) заземления",
          "Допускается объединение заземляющих устройств для защиты от статического электричества с защитным заземлением электрооборудования",
        ],
        correctAnswers: [
          "Допускается последовательное включение в заземляющую шину (провод) нескольких заземляющих аппаратов, агрегатов или трубопроводов",
        ],
      },
      {
        code: "20601371",
        text: "Каким образом должны быть окрашены заземляющие проводники, предназначенные для защиты от статического электричества, в местах присоединения к технологическому оборудованию и внутреннему контуру заземления при взрывных работах?",
        answers: [
          "Одной поперечной полосой шириной 10 мм зеленого цвета",
          "Одной поперечной полосой шириной 15 мм красного цвета",
          "Двумя поперечными полосами шириной 15 мм красного цвета",
          "Одной поперечной полосой шириной 10 мм синего цвета",
        ],
        correctAnswers: [
          "Одной поперечной полосой шириной 15 мм красного цвета",
        ],
      },
      {
        code: "20601372",
        text: "Кем разрабатывается и согласовывается с командиром обслуживающего аварийно-спасательного формирования план по обслуживанию массового взрыва силами аварийно-спасательных формирований? Укажите все правильные ответы.",
        answers: [
          "Ответственным руководителем взрыва",
          "Техническим руководителем рудника, шахты, объекта строительства",
          "Главным инженером горнодобывающей организации",
          "Инспектором территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "Ответственным руководителем взрыва",
          "Техническим руководителем рудника, шахты, объекта строительства",
        ],
      },
      {
        code: "20601373",
        text: "Кем проверяется подготовленный к массовому взрыву подэтаж (блок, панель)?",
        answers: [
          "Горным инженером эксплуатирующей организации",
          "Руководителем рудника (шахты, объекта строительства)",
          "Руководителем взрывных работ",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20601374",
        text: "В каком случае допускается оставлять взрывчатые материалы на местах работ без постоянного надзора (охраны)?",
        answers: [
          "В случае, если взрывчатые материалы находятся в отдельных металлических ящиках и контейнерах (сейфах)",
          "В случае, если взрывчатые материалы защищены от атмосферных осадков",
          "В случаях, утвержденных распорядительным документом организации, ведущей взрывные работы",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20601375",
        text: "На каком минимальном расстоянии от места взрыва должно находиться место укрытия взрывников при пропуске угля и породы в восстающих выработках?",
        answers: ["100 м", "50 м", "150 м", "200 м"],
        correctAnswers: ["150 м"],
      },
      {
        code: "20601376",
        text: "Какое из перечисленных мест укрытия взрывников должно располагаться от места взрыва на расстоянии не менее 200 м?",
        answers: [
          "В наклонных, в том числе восстающих (более 10°), подготовительных выработках",
          "В горизонтальных и наклонных (до 10°) подготовительных выработках, при дроблении негабаритов наружными зарядами",
          "В очистных забоях камерного типа, а также при погашении угольных целиков",
          "В лавах (слоях) с углом залегания до 18° и более,  но не ближе 20 м от сопряжения с лавой (слоем) на штреке",
        ],
        correctAnswers: [
          "В очистных забоях камерного типа, а также при погашении угольных целиков",
        ],
      },
      {
        code: "20601377",
        text: "При каком условии разрешается выход взрывника из укрытия после взрыва при взрывании с применением электродетонаторов?",
        answers: [
          "Только после времени ожидания не менее 5 минут",
          "Только после проветривания",
          "Только после отсоединения электровзрывной сети от источника тока и замыкания ее накоротко",
          "При выполнении всех перечисленных условий",
        ],
        correctAnswers: ["При выполнении всех перечисленных условий"],
      },
      {
        code: "20601378",
        text: "Через какое время разрешается подход взрывника к месту взрыва, если взрыва не произошло, при взрывании электронными детонаторами, электродетонаторами и капсюлями-детонаторами?",
        answers: [
          "Не ранее чем через 5 минут",
          "Не ранее чем через 15 минут",
          "Не ранее чем через 20 минут",
          "Не ранее чем через 10 минут",
        ],
        correctAnswers: ["Не ранее чем через 15 минут"],
      },
      {
        code: "20601379",
        text: "Через какое время после производства массовых взрывов разрешается допуск работников на рабочие места?",
        answers: [
          "Не ранее чем через 60 минут",
          "Не ранее чем через 90 минут",
          "Не ранее чем через 30 минут",
          "Не ранее чем через 20 минут",
        ],
        correctAnswers: ["Не ранее чем через 30 минут"],
      },
      {
        code: "20601380",
        text: "Какое из перечисленных условий допуска работников на рабочие места после производства массовых взрывов указано верно?",
        answers: [
          "Частичная видимость при осмотре места (мест) взрыва и допуске работников",
          "Неполное (менее 60 %) рассеивание пылевого облака",
          "Допуск не ранее чем через 15 минут после взрыва",
          "Получение ответственным руководителем массового взрыва сообщения о снижении концентрации ядовитых продуктов взрыва в воздухе до установленных ПДК",
        ],
        correctAnswers: [
          "Получение ответственным руководителем массового взрыва сообщения о снижении концентрации ядовитых продуктов взрыва в воздухе до установленных ПДК",
        ],
      },
      {
        code: "20601381",
        text: "Где необходимо производить замер содержания углеводородных газов, паров жидких углеводородов перед заряжанием шпуров, а также перед взрыванием зарядов?",
        answers: [
          "Только в забое",
          "Только в месте нахождения взрывника",
          "Только в примыкающих к к забою выработках на протяжении 20 м",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "20601382",
        text: "В каком документе фиксируются отказы зарядов при взрывных работах?",
        answers: [
          "В Журнале осмотра технического состояния оборудования",
          "В сменном журнале",
          "В Журнале регистрации отказов при взрывных работах",
          "В наряде-допуске",
        ],
        correctAnswers: ["В Журнале регистрации отказов при взрывных работах"],
      },
      {
        code: "20601383",
        text: "Какие действия обязаны предпринять работники подземного рудника (шахты), обнаружившие отказавший заряд? Укажите все правильные ответы.",
        answers: [
          "Незамедлительно поставить в известность технического руководителя горнодобывающей организации",
          "Обозначить невзорвавшийся заряд",
          "Прекратить все работы",
          "Закрестить выработку",
        ],
        correctAnswers: ["Прекратить все работы", "Закрестить выработку"],
      },
      {
        code: "20601384",
        text: "Кем и с какой периодичностью осматриваются помещения и площадки, где проводится обработка металлов, рассчитанные на взрыв максимально допустимого заряда?",
        answers: [
          "Руководителем взрывных работ ежемесячно",
          "Техническим руководителем организации ежеквартально",
          "Инспектором территориального органа Ростехнадзора ежегодно",
          "Комиссией организации ежегодно",
        ],
        correctAnswers: ["Комиссией организации ежегодно"],
      },
      {
        code: "20601385",
        text: "Кем проверяется состояние зарядных устройств не реже одного раза в месяц?",
        answers: [
          "Бригадами, осуществляющими ремонтные работы",
          "Лицами, имеющими книжку взрывника и аттестованными в установленном порядке",
          "Комиссией организации, эксплуатирующей зарядное оборудование",
          "Техническим руководителем организации, эксплуатирующей зарядное оборудование",
        ],
        correctAnswers: [
          "Комиссией организации, эксплуатирующей зарядное оборудование",
        ],
      },
      {
        code: "20601386",
        text: "Какое из перечисленных требований к порядку присвоения и нанесения индивидуальных индексов электродетонаторов и капсюлей-детонаторов указано верно?",
        answers: [
          "Нанесение индивидуальных индексов на капсюли-детонаторы должно осуществляться после изготовления зажигательных трубок",
          "В случае появления трещин во внешней оболочке изделий они должны быть отремонтированы",
          "Нанесение индивидуальных индексов на электродетонаторы с помощью устройств обжимного типа должно производиться на 1,0 - 3,0 мм выше нижнего зига заводской обжимки гильзы (соединения капсюля-детонатора с электровоспламенителем)",
          "Работы по нанесению индивидуальных индексов средств инициирования необходимо проводить в помещении, отдельном от мест хранения взрывчатых материалов",
        ],
        correctAnswers: [
          "Работы по нанесению индивидуальных индексов средств инициирования необходимо проводить в помещении, отдельном от мест хранения взрывчатых материалов",
        ],
      },
      {
        code: "20601387",
        text: "Какой из перечисленных индексов, наносимый на гильзы электродетонаторов и капсюлей-детонаторов, обозначает номер взрывника?",
        answers: [
          "Два цифровых индекса",
          "Два буквенных индекса слева от цифровых",
          "Правый цифровой индекс и два буквенных индекса справа от цифровых",
          "Два буквенных индекса справа от цифровых",
        ],
        correctAnswers: ["Два буквенных индекса справа от цифровых"],
      },
      {
        code: "20601388",
        text: "Какие электродетонаторы разрешается применять в бутовых штреках с подрывкой кровли?",
        answers: [
          "Электродетонаторы мгновенного действия",
          "Электродетонаторы замедленного действия",
          "Электродетонаторы короткозамедленного действия",
          "Все перечисленные электродетонаторы",
        ],
        correctAnswers: ["Электродетонаторы мгновенного действия"],
      },
      {
        code: "20601389",
        text: "Какие взрывчатые вещества должны использоваться при разупрочнении труднообрушаемых пород любой крепости?",
        answers: [
          "Взрывчатые вещества, содержащие гексоген",
          "Взрывчатые вещества, не содержащие сенсибилизаторов, более чувствительных, чем тротил",
          "Взрывчатые вещества, содержащие нитроэфиры",
          "Любые взрывчатые вещества",
        ],
        correctAnswers: [
          "Взрывчатые вещества, не содержащие сенсибилизаторов, более чувствительных, чем тротил",
        ],
      },
      {
        code: "20601390",
        text: "Какому эквивалентному значению приравнивается 1 л диоксида азота при проверке вредных продуктов взрыва в выработке (забое)?",
        answers: [
          "5,5 л оксида углерода",
          "8,5 л оксида углерода",
          "6,5 л оксида углерода",
          "7,5 л оксида углерода",
        ],
        correctAnswers: ["6,5 л оксида углерода"],
      },
      {
        code: "20601391",
        text: "Каким образом очищается оборудование при наличии несмываемых остатков взрывчатых веществ по окончании заряжания? Укажите все правильные ответы.",
        answers: [
          "Паром",
          "Горячей водой",
          "Сжатым воздухом",
          "Металлическим инструментом",
        ],
        correctAnswers: ["Паром", "Горячей водой"],
      },
      {
        code: "20601392",
        text: "При какой температуре запрещается заряжание и взрывание зарядов в шпурах при ведении взрывных работ по металлу?",
        answers: [
          "Свыше 150 °C",
          "Свыше 200 °C",
          "Свыше 100 °C",
          "Свыше 80 °C",
        ],
        correctAnswers: ["Свыше 200 °C"],
      },
      {
        code: "20601393",
        text: "Какое количество зарядов разрешается одновременно заряжать и взрывать при температуре в шпуре ниже 80 °C при ведении взрывных работ по металлу?",
        answers: [
          "Не более семи зарядов",
          "Не более трех зарядов",
          "Не более пяти зарядов",
          "Не более одного заряда",
        ],
        correctAnswers: ["Не более пяти зарядов"],
      },
      {
        code: "20601394",
        text: "Каким образом должен упаковываться заряд при температуре более 80 °C в шпуре (скважине, рукаве) при ведении взрывных работ по металлу?",
        answers: [
          "В пенопластовый короб",
          "В плотную упаковочную бумагу",
          "В прорезиненную ветошь",
          "В термоизолирующую оболочку",
        ],
        correctAnswers: ["В термоизолирующую оболочку"],
      },
      {
        code: "20601395",
        text: "В каком случае допускается одновременное взрывание в обоих крыльях калотты?",
        answers: [
          "В случае если дано указание лица, ответственного за проведение взрывных работ",
          "В случае если другие работы приостановлены на время проведения взрывных работ",
          "По проекту буровзрывных (взрывных) работ, согласованному с руководителем организации, выполняющей работы по строительству тоннеля",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "20601396",
        text: "Кем должны проводиться заряжание и забойка при ведении взрывных работ по металлу?",
        answers: [
          "Одним взрывником в присутствии руководителя взрывных работ",
          "Тремя взрывниками в присутствии руководителя взрывных работ",
          "Двумя взрывниками в присутствии руководителя взрывных работ",
          "Несколькими взрывниками",
        ],
        correctAnswers: [
          "Двумя взрывниками в присутствии руководителя взрывных работ",
        ],
      },
      {
        code: "20601397",
        text: "Какой документ служит для отпуска взрывчатых материалов взрывникам для производства взрывных работ?",
        answers: ["Наряд-допуск", "Наряд-путевка", "Накладная", "Акт отгрузки"],
        correctAnswers: ["Наряд-путевка"],
      },
      {
        code: "20601398",
        text: "Для каких видов взрывных работ применяются предохранительные взрывчатые вещества VI класса?",
        answers: [
          "Для взрывного перебивания деревянных стоек при посадке кровли",
          "Для ликвидации зависаний горной массы в углеспускных выработках",
          "Для дробления негабаритов наружными зарядами",
          "Для верхней и смешанной подрывки пород с f = 4 и менее в вентиляционных штреках, проводимых вслед за лавой",
        ],
        correctAnswers: [
          "Для верхней и смешанной подрывки пород с f = 4 и менее в вентиляционных штреках, проводимых вслед за лавой",
        ],
      },
      {
        code: "20601399",
        text: "Какая допускается максимальная температура сжатого воздуха (при работе с автономным компрессором), а также нагрева узлов зарядных устройств, через которые проходят взрывчатые вещества?",
        answers: ["70 °C", "50 °C", "60 °C", "40 °C"],
        correctAnswers: ["60 °C"],
      },
      {
        code: "20601400",
        text: "Каким способом разрешается проводить взрывание камерных зарядов?",
        answers: [
          "С применением электродетонаторов",
          "С применением неэлектрических систем инициирования",
          "С применением детонирующего шнура",
          "Всеми перечисленными способами",
        ],
        correctAnswers: ["Всеми перечисленными способами"],
      },
      {
        code: "20601401",
        text: "Какое действие необходимо предпринять, если при проверке линии наименьшего сопротивления выявится возможность опасного разлета кусков горной массы или воздействия ударной воздушной волны при ликвидации отказавших камерных зарядов?",
        answers: [
          "Разборку забойки с последующим извлечением взрывчатых веществ",
          "Провести ликвидацию по специально разработанным проектам, утвержденным руководителем (техническим руководителем) организации, ведущей взрывные работы, или назначенным им лицом",
          "Разборку забойки с последующим вводом нового боевика и взрывание в обычном порядке",
          "Взрывание дополнительно опущенного накладного заряда",
        ],
        correctAnswers: [
          "Разборку забойки с последующим извлечением взрывчатых веществ",
        ],
      },
      {
        code: "20601402",
        text: "Где должен находиться руководитель сотрясательного взрывания в забое, замеряющий содержание метана, при продвижении к забою для осмотра его после сотрясательного взрывания?",
        answers: [
          "На расстоянии 3 м позади взрывника",
          "На расстоянии 3 м впереди взрывника",
          "На расстоянии 4,5 м позади взрывника",
          "На расстоянии 5 м впереди взрывника",
          "Не регламентируется",
        ],
        correctAnswers: ["На расстоянии 3 м впереди взрывника"],
      },
      {
        code: "20601403",
        text: "Каким образом следует проводить бурение шпуров по углю для сотрясательного взрывания?",
        answers: [
          "Ударным способом",
          "Ударно-вращательным способом",
          "Вращательным способом",
          "В соответствии с решением руководителя сотрясательного взрывания",
        ],
        correctAnswers: ["Вращательным способом"],
      },
      {
        code: "20601404",
        text: "На какую длину разрешается вынимать из шпуров отказавших зарядов забоечный материал для установления направления вспомогательных шпуров?",
        answers: [
          "На длину до 20 см от устья шпура",
          "На длину до 30 см от устья шпура",
          "На длину до 25 см от устья шпура",
          "На длину до 50 см от устья шпура",
        ],
        correctAnswers: ["На длину до 20 см от устья шпура"],
      },
      {
        code: "20601405",
        text: "Какой способ ликвидации отказавших скважинных зарядов указан неверно?",
        answers: [
          "Взрывание заряда в скважине, пробуренной параллельно на расстоянии не менее 3 м от скважины с отказавшим зарядом",
          "Взрывание отказавшего заряда в случае, если отказ произошел в результате нарушения целостности внешней взрывной сети (если линия наименьшего сопротивления отказавшего заряда не уменьшилась)",
          "Вымывание заряда из скважины при взрывании дымного пороха с применением детонирующего шнура",
          "Разборка породы в месте нахождения скважины с отказавшим зарядом с извлечением последнего вручную",
        ],
        correctAnswers: [
          "Вымывание заряда из скважины при взрывании дымного пороха с применением детонирующего шнура",
        ],
      },
      {
        code: "20601406",
        text: "Какое из перечисленных требований к уничтожению взрывчатых материалов указано верно?",
        answers: [
          "Допускается применение смерзшихся взрывчатых веществ, содержащих жидкие нитроэфиры 20%",
          "Взрывчатые материалы допускается сжигать в их таре в соответствии с руководством (инструкцией) по применению",
          "Уничтожение взрывчатых материалов должно выполняться взрывниками под контролем технического руководителя организации",
          "Запрещается подход к месту сжигания до полного прекращения горения костра с взрывчатыми материалами",
        ],
        correctAnswers: [
          "Запрещается подход к месту сжигания до полного прекращения горения костра с взрывчатыми материалами",
        ],
      },
      {
        code: "20601407",
        text: "Куда передается акт об уничтожении взрывчатых материалов?",
        answers: [
          "На склад взрывчатых материалов",
          "В территориальное подразделение Ростехнадзора",
          "В управление МЧС",
          "В отдел сбыта горнодобывающей организации",
        ],
        correctAnswers: ["На склад взрывчатых материалов"],
      },
      {
        code: "20601408",
        text: "Что из перечисленного целесообразно отражать в инструкции по ликвидации отказавших зарядов взрывчатых веществ при строительстве гидротехнических сооружений?",
        answers: [
          "Только порядок сбора, учета и уничтожения остатков взрывчатых материалов, извлеченных при ликвидации отказа, мероприятия по безопасности работ",
          "Только величину радиуса опасной зоны при ликвидации отказа, порядок ее обозначения на местности и в подземных выработках, а также ее охране, организацию работ по ликвидации отказов",
          "Только основные мероприятия по предупреждению отказавших зарядов, порядок обнаружения невзорвавшихся зарядов, методы ликвидации отказов для каждого вида взрывных работ",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20601409",
        text: "После чего начинаются загрузка бункера зарядного оборудования и непосредственно заряжание?",
        answers: [
          "После того, как руководитель взрывных работ убедится в том, что блок и зарядные устройства к этим работам подготовлены",
          "Поле указания диспетчера",
          "После того, как взрывник убедится в том, что блок и зарядные устройства к этим работам подготовлены",
          "После предупреждения всех лиц, участвующих в заряжании",
        ],
        correctAnswers: [
          "После того, как руководитель взрывных работ убедится в том, что блок и зарядные устройства к этим работам подготовлены",
        ],
      },
      {
        code: "20601410",
        text: "Какое из перечисленных требований к условиям заряжания, массе зарядов взрывчатых веществ и длине забойки указано верно?",
        answers: [
          "Запрещается применять шпуры глубиной менее 1 м в продуктивном пласте",
          "В шпурах глубиной от 1 до 1,5 м заряд должен занимать не более половины их длины",
          "При наличии в забое нескольких обнаженных поверхностей линия наименьшего сопротивления от любой точки заряда до ближайшей обнаженной поверхности должна быть не менее 0,5 м в продуктивном пласте и не менее 0,3 м по породе",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20601411",
        text: "С какой периодичностью проводится определение относительной влажности воздуха в забойном пространстве после внедрения пневматического заряжания?",
        answers: [
          "Не реже одного раза в полугодие",
          "Не реже одного раза в год",
          "Не реже одного раза в месяц",
          "Не реже одного раза в квартал",
        ],
        correctAnswers: ["Не реже одного раза в квартал"],
      },
      {
        code: "20601412",
        text: "Какое из перечисленных условий указано неверно при вскрытии пластов сотрясательным взрыванием с применением рассредоточенных (двухъярусных) зарядов взрывчатых веществ?",
        answers: [
          "Длина шпуров с рассредоточенными зарядами должна составлять не менее 3 м",
          "При использовании взрывчатых веществ II класса длина забойки между рассредоточенными зарядами должна быть не менее 1 м, а масса первого от устья шпура заряда взрывчатых веществ - не более 1 кг",
          "В шпурах с рассредоточенными зарядами замедление в донном заряде должно быть меньше, чем в первом заряде от устья",
          "Инициирование зарядов осуществляется электродетонаторами мгновенного и короткозамедленного действия",
        ],
        correctAnswers: [
          "В шпурах с рассредоточенными зарядами замедление в донном заряде должно быть меньше, чем в первом заряде от устья",
        ],
      },
      {
        code: "20601413",
        text: "Какое действие из перечисленных допускается при ведении взрывных работ?",
        answers: [
          "Переломы выходящих из зарядов концов детонирующего шнура",
          "Взрывание зарядов без забойки на шахтах (рудниках), опасных по газу и пыли",
          "Тянуть провода электронных детонаторов и электродетонаторов, введенные в боевики",
          "Применять рассредоточенные заряды в шахтах, опасных по газу или пыли, в породных забоях выработок, в которых отсутствует выделение горючих газов, во врубовых шпурах",
        ],
        correctAnswers: [
          "Применять рассредоточенные заряды в шахтах, опасных по газу или пыли, в породных забоях выработок, в которых отсутствует выделение горючих газов, во врубовых шпурах",
        ],
      },
      {
        code: "20601414",
        text: "Какое из перечисленных расстояний при ведении взрывных работ в подземных выработках указано верно?",
        answers: [
          "Запрещается ведение взрывных работ на расстоянии менее 50 м от склада взрывчатых материалов участкового пункта, раздаточной камеры",
          "Запрещается взрывание зарядов, если на расстоянии менее 50 м от места их заложения находятся неубранная отбитая горная масса, вагонетки или предметы, загромождающие выработку более чем на 1/5 площади ее поперечного сечения, при отсутствии свободных проходов",
          "Запрещается нахождение людей в раздаточной камере при взрывных работах, проводящихся на расстоянии ближе 100 м от них",
        ],
        correctAnswers: [
          "Запрещается нахождение людей в раздаточной камере при взрывных работах, проводящихся на расстоянии ближе 100 м от них",
        ],
      },
      {
        code: "20601415",
        text: "Какого вида взрыва, после производства которого требуется больше времени для проветривания и возобновления работ в руднике (шахте, участке), чем это предусмотрено графиком проветривания горных выработок при повседневной организации работ, не существует?",
        answers: [
          "Экспериментальные взрывы",
          "Технологические взрывы",
          "Пробные вызовы",
          "Специальные взрывы",
        ],
        correctAnswers: ["Пробные вызовы"],
      },
      {
        code: "20601416",
        text: "Какое из перечисленных требований запрещается при ведении взрывных работ на угольных шахтах и рудниках, опасных по газу и (или) пыли? Укажите все правильные ответы.",
        answers: [
          "Размещать в одном шпуре взрывчатые вещества различных классов",
          "Размещать в одном шпуре при сплошном заряде более одного патрона-боевика",
          "Устанавливать шпуры при взрывании по углю и породе на глубине 0,7 м",
          "Вводить в шпур одновременно заряд, состоящий из двух и более патронов взрывчатого вещества",
        ],
        correctAnswers: [
          "Размещать в одном шпуре взрывчатые вещества различных классов",
          "Размещать в одном шпуре при сплошном заряде более одного патрона-боевика",
        ],
      },
      {
        code: "20601417",
        text: "Кому должны быть сданы остатки взрывчатых материалов взрывниками по окончании взрывных работ?",
        answers: [
          "Лично техническому руководителю шахты",
          "Руководителю взрывных работ",
          "Лично лицу, ответственному за выдачу и приемку взрывчатых материалов (раздатчику, заведующему складом взрывчатых материалов)",
          "Кладовщику на склад",
        ],
        correctAnswers: [
          "Лично лицу, ответственному за выдачу и приемку взрывчатых материалов (раздатчику, заведующему складом взрывчатых материалов)",
        ],
      },
      {
        code: "20601418",
        text: "При каких условиях допускается проведение прострелочных или взрывных работ в скважинах?",
        answers: [
          "Во время туманов (при видимости более 50 м) при выполнении работ в закрытых помещениях буровых",
          "Во время грозы и сильных туманов",
          "Без применения лубрикаторов в сухих газирующих и поглощающих раствор скважинах",
          "Во время пурги, буранов",
        ],
        correctAnswers: [
          "Во время туманов (при видимости более 50 м) при выполнении работ в закрытых помещениях буровых",
        ],
      },
      {
        code: "20601419",
        text: "При какой минимальной концентрации метана руководитель сотрясательного взрывания в забое, замеряющий содержание метана, при продвижении к забою для осмотра его после сотрясательного взрывания, обязан немедленно возвратиться в выработку со свежей струей воздуха?",
        answers: [" 5 %", " 7 %", " 3 %", " 2 %"],
        correctAnswers: [" 2 %"],
      },
      {
        code: "20601420",
        text: "Какое количество приемов допускается при проведении взрывания по породе выработок, в которых отсутствует выделение метана, с применением электродетонаторов мгновенного, короткозамедленного и замедленного действия со временем замедления до 2 секунд?",
        answers: [
          "Не более трех приемов",
          "Не более одного приема",
          "Не более двух приемов",
          "Без ограничения количества приемов",
        ],
        correctAnswers: ["Без ограничения количества приемов"],
      },
      {
        code: "20601421",
        text: "Какое из перечисленных требований во время взрывания при образовании каналов, канав и котлованов указано верно?",
        answers: [
          "При формировании траншейных зарядов расстояние между экскаватором (траншеекопателем) и краном, укладывающим взрывчатые вещества в траншею, должно быть не менее 1,5 суммы радиусов разворота ковша экскаватора с вытянутой рукоятью и разворота крана",
          "В сложных горно-геологических условиях при взрывании неэлектрическими системами инициирования и детонирующим шнуром зарядов взрывчатых веществ группы D (кроме дымного пороха) допускается размещение удлиненных горизонтальных зарядов (траншейных, щелевых) непосредственно вслед за проведением горных выработок",
          "При заряжании линейных зарядов менее суток боевики укладываются в день производства взрыва",
          "Все перечисленные требования указаны верно",
        ],
        correctAnswers: [
          "В сложных горно-геологических условиях при взрывании неэлектрическими системами инициирования и детонирующим шнуром зарядов взрывчатых веществ группы D (кроме дымного пороха) допускается размещение удлиненных горизонтальных зарядов (траншейных, щелевых) непосредственно вслед за проведением горных выработок",
        ],
      },
      {
        code: "20601422",
        text: "Что необходимо сделать перед началом заряжания шпуров и скважин при ведении взрывных работ в подземных выработках?",
        answers: [
          "Только убрать ранее взорванную в забое горную массу",
          "Только вывести людей, не участвующих в выполнении взрывных работ, за пределы запретной (опасной) зоны, в места, определенные паспортом (проектом) буровзрывных (взрывных) работ",
          "Только обеспечить проветривание забоя",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20601423",
        text: "В каком случае должно проводиться сотрясательное взрывание при отработке пластов, опасных по внезапным выбросам угля, породы и газа? Укажите все правильные ответы.",
        answers: [
          "При проведении восстающих выработок с углом наклона 15°",
          "При вскрытии песчаников на глубине 300 м, если прогноз выбросоопасности перед вскрытием не осуществлялся",
          "При вскрытии угрожаемых угольных пластов, если прогнозом установлены опасные значения показателей выбросоопасности или прогноз перед вскрытием не проводился",
          "При вскрытии выбросоопасных угольных пластов мощностью более 0,1 м",
        ],
        correctAnswers: [
          "При вскрытии угрожаемых угольных пластов, если прогнозом установлены опасные значения показателей выбросоопасности или прогноз перед вскрытием не проводился",
          "При вскрытии выбросоопасных угольных пластов мощностью более 0,1 м",
        ],
      },
      {
        code: "20601424",
        text: "Какие требования предъявляются к состоянию выработки, в которой проводится сотрясательное взрывание, перед взрывными работами?",
        answers: [
          "Выработка должна быть освобождена на протяжении не менее 75 м от забоя от вагонеток и других предметов, загромождающих ее более чем на 1/3 площади поперечного сечения",
          "Выработка должна быть освобождена на протяжении не менее 50 м от забоя от вагонеток и других предметов, загромождающих ее более чем на 1/3 площади поперечного сечения",
          "Выработка должна быть освобождена на протяжении не менее 100 м от забоя от вагонеток и других предметов, загромождающих ее более чем на 1/3 площади поперечного сечения",
          "Выработка должна быть освобождена на протяжении не менее 90 м от забоя от вагонеток и других предметов, загромождающих ее более чем на 1/3 площади поперечного сечения",
        ],
        correctAnswers: [
          "Выработка должна быть освобождена на протяжении не менее 100 м от забоя от вагонеток и других предметов, загромождающих ее более чем на 1/3 площади поперечного сечения",
        ],
      },
      {
        code: "20601425",
        text: "Кому должен сообщить взрывник при возникновении аварийной ситуации в процессе заряжания?",
        answers: [
          "Руководителю взрывных работ",
          "Рабочему, привлекаемому в помощь взрывнику",
          "Техническому инженеру организации",
          "Диспетчеру",
        ],
        correctAnswers: ["Руководителю взрывных работ"],
      },
      {
        code: "20601426",
        text: "Какое из перечисленных условий допускается при заземлении и защите от образования статического электричества в пневмозарядных устройствах?",
        answers: [
          "Пневмозаряжание по одному трубопроводу взрывчатых веществ разных типов",
          "Пневматическое заряжание шпуров в подземных горных выработках при относительной влажности рудничного воздуха более 50 % зарядчиками с металлической зарядной трубкой или с электропроводящим зарядным трубопроводом длиной не более 5 м",
          "Применение полиэтиленовых и резиновых трубопроводов (шлангов) с удельным объемным электрическим сопротивлением не более 107 Ом x м.",
          "Пневмозаряжание гранулированными алюмо- и тротилсодержащими взрывчатыми веществами без предварительного их увлажнения",
        ],
        correctAnswers: [
          "Применение полиэтиленовых и резиновых трубопроводов (шлангов) с удельным объемным электрическим сопротивлением не более 107 Ом x м.",
        ],
      },
      {
        code: "20601427",
        text: "Что из перечисленного допускается в процессе пневмотранспортирования или пневмозаряжания?",
        answers: [
          "Одновременно производить в одном забое ручное формирование основного заряда с применением высокочувствительных к механическим воздействиям взрывчатых веществ и пневматическое заряжание",
          "Расстилать брезент на месте заряжания под восходящими скважинами",
          "Смешивать два и более типа взрывчатых веществ",
          "Производить одновременное заряжание взрывчатых веществ, содержащих в своем составе тротил или алюминиевую пудру",
        ],
        correctAnswers: [
          "Расстилать брезент на месте заряжания под восходящими скважинами",
        ],
      },
      {
        code: "20601428",
        text: "В каких случаях при взрывании скважинных зарядов на поверхности обязательно дублирование внутрискважинной сети?",
        answers: [
          "При взрывании скважинных зарядов независимо от их глубины",
          "При взрывании нескольких скважинных зарядов при глубине скважин более 10 м",
          "При глубине взрывных скважин более 15 м",
          "Необходимость дублирования определяется руководителем организации",
        ],
        correctAnswers: ["При глубине взрывных скважин более 15 м"],
      },
      {
        code: "20601429",
        text: "Не ниже какого класса допускается применение предохранительных взрывчатых веществ в нефтяных шахтах?",
        answers: ["IV класса", "III класса", "V класса", "VII класса"],
        correctAnswers: ["III класса"],
      },
      {
        code: "20601430",
        text: "Какое действие необходимо выполнять для уменьшения пыления и просыпи взрывчатых веществ?",
        answers: [
          "При заряжании центрировать конец зарядного трубопровода относительно оси скважины",
          "Выдерживать оптимальное расстояние от конца шланга до заряда",
          "При пневмозаряжании алюмо- и тротилсодержащими рассыпными гранулированными взрывчатыми веществами добавлять во взрывчатые вещества воду или смачивающий раствор",
          "Все перечисленные действия",
        ],
        correctAnswers: ["Все перечисленные действия"],
      },
      {
        code: "20601431",
        text: "В каких количествах разрешается размещать взрывчатые вещества непосредственно у зарядного оборудования?",
        answers: [
          "Не более 5-сменной производительности оборудования",
          "Не более 3-сменной производительности оборудования",
          "Не более 2-сменной производительности оборудования",
          "Не более сменной производительности оборудования",
        ],
        correctAnswers: ["Не более сменной производительности оборудования"],
      },
      {
        code: "20601432",
        text: "Какие из перечисленных мест хранения взрывчатых материалов должны быть приняты в эксплуатацию комиссией по оценке соответствия места хранения установленным требованиям и проектной документации?",
        answers: [
          "Места размещения взрывчатых материалов на местах производства взрывных работ",
          "Места сменного хранения, размещаемые вблизи мест ведения взрывных работ",
          "Передвижные склады",
          "Все ответы неверны",
          "Все перечисленные",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20601433",
        text: "Какие требования при вместимости складов и камер взрывчатых материалов указаны верно? Укажите все правильные ответы.",
        answers: [
          "Вместимость камеры в складах камерного типа не должна превышать 2000 кг взрывчатых веществ",
          "На угольных и сланцевых шахтах вместимость склада без учета емкости раздаточных камер не должна превышать семисуточного запаса взрывчатых веществ и пятнадцатисуточного запаса средств инициирования",
          "В складах ячейкового типа в каждой ячейке разрешается хранить не более 600 кг взрывчатых веществ",
          "Предельная вместимость отдельной раздаточной камеры в подземных выработках не должна превышать 4000 кг взрывчатых веществ и соответствующего количества средств инициирования",
        ],
        correctAnswers: [
          "Вместимость камеры в складах камерного типа не должна превышать 2000 кг взрывчатых веществ",
          "На угольных и сланцевых шахтах вместимость склада без учета емкости раздаточных камер не должна превышать семисуточного запаса взрывчатых веществ и пятнадцатисуточного запаса средств инициирования",
        ],
      },
      {
        code: "20601434",
        text: "Какие материалы допускается использовать для насыпки валов складов взрывчатых материалов?",
        answers: [
          "Щебень",
          "Камень",
          "Пластинчатые грунты",
          "Все перечисленные материалы",
        ],
        correctAnswers: ["Пластинчатые грунты"],
      },
      {
        code: "20601435",
        text: "В каких хранилищах взрывчатых материалов запрещается пользоваться открытым огнем?",
        answers: [
          "В хранилищах калиевой селитры",
          "В хранилищах аммиачной селитры",
          "В хранилищах натриевой селитры",
        ],
        correctAnswers: ["В хранилищах аммиачной селитры"],
      },
      {
        code: "20601436",
        text: "В каких местах при взрывных работах могут использоваться слежавшиеся порошкообразные взрывчатые вещества, содержащие гексоген или жидкие нитроэфиры, без размятия или измельчения?",
        answers: [
          "В шахтах (рудниках), не опасных по газу",
          "На земной поверхности",
          "В шахтах (рудниках), разрабатывающих пласты (рудные тела), не опасные по взрывам пыли",
          "В шахтах, отрабатывающих мощные месторождения",
        ],
        correctAnswers: ["На земной поверхности"],
      },
      {
        code: "20601437",
        text: "В каком случае обеспечивается защита хранилищ взрывчатых материалов от заноса высоких потенциалов при вводе в них электрических сетей освещения присоединением металлической брони и оболочки кабеля к заземлителю защиты от вторичных воздействий?",
        answers: [
          "При кабелях, присоединенных к воздушной линии, не требующих заземления",
          "При небронированных кабелях, не требующих присоединения к заземлителю",
          "При бронированных кабелях, проложенных в земле",
        ],
        correctAnswers: ["При бронированных кабелях, проложенных в земле"],
      },
      {
        code: "20601438",
        text: "Какие условия во временных складах взрывчатых материалов указаны верно? Укажите все правильные ответы.",
        answers: [
          "Устройство противопожарных водоемов (резервуаров, скважин) необязательно",
          "Устройство тамбуров не обязательно, двери могут быть одинарными",
          "Деревянные стены и крыши могут не покрываться огнезащитным составом",
          "Рабочее освещение внутри хранилищ не допускается осуществлять рудничными аккумуляторными светильниками",
        ],
        correctAnswers: [
          "Устройство противопожарных водоемов (резервуаров, скважин) необязательно",
          "Устройство тамбуров не обязательно, двери могут быть одинарными",
        ],
      },
      {
        code: "20601439",
        text: "Что из перечисленного может использоваться на участковых пунктах в качестве шкафов (контейнеров) для взрывчатых материалов? Укажите все правильные ответы.",
        answers: [
          "Сумки с жесткими ячейками, покрытыми внутри мягким материалом",
          "Ящики, изготовленные из гипсоволокнистых листов",
          "Шахтные вагонетки, оборудованные металлическими крышками",
          "Металлические сейфы, изготовленные из металлических листов толщиной не менее 2 мм",
        ],
        correctAnswers: [
          "Шахтные вагонетки, оборудованные металлическими крышками",
          "Металлические сейфы, изготовленные из металлических листов толщиной не менее 2 мм",
        ],
      },
      {
        code: "20601440",
        text: "В каких из перечисленных помещениях допускается хранение взрывчатых материалов?",
        answers: [
          "В здании подготовки взрывчатых материалов",
          "В тамбурах хранилищ",
          "В здании выдачи взрывчатых материалов",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20601441",
        text: "Какое совместное хранение взрывчатых материалов не допускается?",
        answers: [
          "Детонирующий шнур групп совместимости D и S со средства инициирования групп совместимости B и S",
          "Дымные (группа совместимости D) и бездымные (группа совместимости C) порохи",
          "Детонаторы для взрывных работ, сборки детонаторов для взрывных работ и капсюли-воспламенители (группа совместимости В) с первичным взрывчатым веществом",
          "Средства инициирования группы совместимости S и средства инициирования группы совместимости В",
        ],
        correctAnswers: [
          "Детонаторы для взрывных работ, сборки детонаторов для взрывных работ и капсюли-воспламенители (группа совместимости В) с первичным взрывчатым веществом",
        ],
      },
      {
        code: "20601442",
        text: "Какое из перечисленных требований к углубленным складам взрывчатых материалов указано верно?",
        answers: [
          "Наружная дверь выработок, ведущих к углубленному складу, должна быть решетчатой, а внутренняя - металлической или деревянной",
          "Перед устьем выработки, ведущей к углубленному складу, необходимо устраивать защитный вал высотой, превышающей высоту выработки на 1 м",
          "Склад должен иметь два выхода, если расстояние от входа в углубленный склад до ближайшей камеры хранения взрывчатых материалов более 10 м",
          "Устья выработок, ведущих к углубленному складу, должны быть оборудованы двойными дверями, открывающимися наружу",
        ],
        correctAnswers: [
          "Устья выработок, ведущих к углубленному складу, должны быть оборудованы двойными дверями, открывающимися наружу",
        ],
      },
      {
        code: "20601443",
        text: "С какой периодичностью места хранения селитры должны подвергаться очистке?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в год",
          "Не реже одного раза в две недели",
          "Не реже одного раза в квартал",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20601444",
        text: "В течение какого срока должны храниться в организации Книга учета прихода и расхода взрывчатых материалов и Книга учета выдачи и возврата взрывчатых материалов?",
        answers: [
          "В течение не менее одного года",
          "В течение не менее трех лет",
          "В течение не менее пяти лет",
          "В течение не менее двух лет",
        ],
        correctAnswers: ["В течение не менее трех лет"],
      },
      {
        code: "20601445",
        text: "Взрывчатые вещества каких составов разрешается производить на пунктах производства взрывчатых веществ?",
        answers: [
          "Только водосодержащие смеси-суспензии, эмульсии и эмульсионные взрывчатые вещества на основе раствора аммиачной селитры или ее раствора с добавками кальциевой или натриевой селитры или карбамида с порошкообразными и жидкими невзрывчатыми горючими",
          "Только смеси холодного смешения гранулированной аммиачной селитры с гранулированным или чешуированным тротилом",
          "Только смеси холодного смешения гранулированной аммиачной селитры с жидкими и твердыми нефтяными, порошкообразными или другого происхождения невзрывчатыми горючими",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20601446",
        text: "В каком случае оборудование и здание пунктов производства и подготовки взрывчатых веществ в целом должны быть полностью освобождены от взрывоопасных продуктов?",
        answers: [
          "В случае невозможности обеспечения круглосуточного наблюдения",
          "Ни в каком случае",
          "При остановке работы пункта производства на более чем 5 часов",
          "При остановке пункта на период более двух суток",
        ],
        correctAnswers: ["При остановке пункта на период более двух суток"],
      },
      {
        code: "20601447",
        text: "Каким должно быть расстояние между параллельно проводимыми выработками угольных и нефтяных шахт, при котором взрывание зарядов в каждом забое должно проводиться только после вывода людей из забоя параллельной выработки и выставления постов охраны, предусмотренных паспортами буровзрывных работ?",
        answers: [
          "30 м и менее",
          "20 м и менее",
          "15 м и менее",
          "25 м и менее",
        ],
        correctAnswers: ["15 м и менее"],
      },
      {
        code: "20601448",
        text: "Какой документ является базовым для разработки паспортов и проектов буровзрывных (взрывных) работ, в том числе и проектов массовых взрывов, за исключением специальных и экспериментальных массовых взрывов в подземных выработках, выполняемых в конкретных условиях?",
        answers: [
          "Согласованный с Ростехнадзором план проведения взрывных работ",
          "Типовой проект буровзрывных (взрывных) работ",
          "Руководство по проведению буровзрывных работ",
          "Утвержденная программа проведения взрывных работ",
        ],
        correctAnswers: ["Типовой проект буровзрывных (взрывных) работ"],
      },
      {
        code: "20601449",
        text: "При какой толщине породной пробки между забоем выработки и крутым пластом (пропластком) необходимо производить вскрытие и пересечение пластов при помощи буровзрывных работ?",
        answers: [
          "Не менее 1 м",
          "Не менее 2 м",
          "Не менее 4 м",
          "Не менее 3 м",
        ],
        correctAnswers: ["Не менее 2 м"],
      },
    ],
    20602: [
      {
        code: "20602000",
        text: "Куда должны быть занесены машинистом погрузочной техники время обнаружения отказа, принятые меры безопасности, а также данные о том, кому сообщено об обнаружении отказа?",
        answers: [
          "В журнал приема-сдачи смен",
          "В журнал регистрации отказов при взрывных работах",
          "В журнал регистрации ликвидированных отказов взрывных веществ",
          "На маркшейдерские планы",
        ],
        correctAnswers: ["В журнал приема-сдачи смен"],
      },
      {
        code: "20602001",
        text: "Кем подписываются акты о приемке геодезических пунктов?",
        answers: [
          "Главным маркшейдером строительно-монтажной организации",
          "Комиссией эксплуатирующей организации",
          "Руководителем маркшейдерской службы организации",
          "Руководителем эксплуатирующей организации",
        ],
        correctAnswers: ["Руководителем маркшейдерской службы организации"],
      },
      {
        code: "20602002",
        text: "Какой должна быть плотность высотной опорной геодезической сети при съемке в масштабе 1:5000?",
        answers: [
          "Не менее одного репера на 5 - 7 км²",
          "Не менее одного репера на 8 км²",
          "Не менее одного репера на 10 - 15 км²",
          "Не менее одного репера на 5 км²",
        ],
        correctAnswers: ["Не менее одного репера на 10 - 15 км²"],
      },
      {
        code: "20602003",
        text: "Прокладка каких труб и кабелей допускается в производственных зданиях (помещениях), где ведутся работы с окислителями или их растворами?",
        answers: [
          "Пластмассовых импульсных труб",
          "Медных командных труб",
          "Бронированных кабелей с оцинкованной броней",
          "Бронированных кабелей с открытой свинцовой оболочкой",
        ],
        correctAnswers: ["Пластмассовых импульсных труб"],
      },
      {
        code: "20602004",
        text: "Каким образом должны быть окрашены заземляющие проводники, предназначенные для защиты от статического электричества, в местах присоединения к технологическому оборудованию и внутреннему контуру заземления при взрывных работах?",
        answers: [
          "Одной поперечной полосой шириной 10 мм зеленого цвета",
          "Одной поперечной полосой шириной 10 мм синего цвета",
          "Одной поперечной полосой шириной 15 мм красного цвета",
          "Двумя поперечными полосами шириной 15 мм красного цвета",
        ],
        correctAnswers: [
          "Одной поперечной полосой шириной 15 мм красного цвета",
        ],
      },
      {
        code: "20602005",
        text: "Какое из перечисленных требований к мерам защиты от статического электричества технологического оборудования при взрывных работах указано неверно?",
        answers: [
          "Допускается последовательное включение в заземляющую шину (провод) нескольких заземляющих аппаратов, агрегатов или трубопроводов",
          "Допускается объединение заземляющих устройств для защиты от статического электричества с защитным заземлением электрооборудования",
          "В каждом производственном здании должна быть составлена схема (карта) заземления",
          "Места для присоединения заземляющих проводников и способ их крепления должны быть указаны в технической документации оборудования",
        ],
        correctAnswers: [
          "Допускается последовательное включение в заземляющую шину (провод) нескольких заземляющих аппаратов, агрегатов или трубопроводов",
        ],
      },
      {
        code: "20602006",
        text: "Кто утверждает регламент технологического процесса производства и подготовки взрывчатых веществ и изменения в него?",
        answers: [
          "Представитель территориального органа Ростехнадзора",
          "Ответственный за пожарную безопасность",
          "Руководитель (технический руководитель) эксплуатирующей организации",
          "Комиссия эксплуатирующей организации",
        ],
        correctAnswers: [
          "Руководитель (технический руководитель) эксплуатирующей организации",
        ],
      },
      {
        code: "20602007",
        text: "Какого формата должен быть план границ горного отвода, на котором должны отображаться: контуры предварительных и уточненных границ горного отвода, угловые точки границ горного отвода?",
        answers: ["А3", "А1", "А5", "А4"],
        correctAnswers: ["А4"],
      },
      {
        code: "20602008",
        text: "В каком документе фиксируются отказы зарядов при взрывных работах?",
        answers: [
          "В ведомости учета зарядов",
          "В акте выполненных работ",
          "В журнале регистрации отказов при взрывных работах",
          "В сменном журнале, наряде-путевке и журнале осмотра технического состояния и учета работы оборудования",
        ],
        correctAnswers: ["В журнале регистрации отказов при взрывных работах"],
      },
      {
        code: "20602009",
        text: "Что является базовым документом для разработки паспортов и проектов буровзрывных (взрывных) работ, в том числе и проектов массовых взрывов, за исключением специальных и экспериментальных массовых взрывов в подземных выработках, выполняемых в конкретных условиях?",
        answers: [
          "Программа проведения взрывных работ",
          "Типовой проект буровзрывных (взрывных) работ",
          "Руководство по проведению буровзрывных работ",
          "Правила безопасности при производстве, хранении и применении взрывчатых материалов промышленного назначения",
        ],
        correctAnswers: ["Типовой проект буровзрывных (взрывных) работ"],
      },
      {
        code: "20602010",
        text: "Не ниже какого класса допускается применение предохранительных взрывчатых веществ в нефтяных шахтах?",
        answers: ["II класса", "III класса", "IV класса", "V класса"],
        correctAnswers: ["III класса"],
      },
      {
        code: "20602011",
        text: "В течение какого срока в организации хранятся проекты буровзрывных (взрывных) работ, паспорта, схемы, в соответствии с которыми осуществляются взрывные работы?",
        answers: [
          "Не менее года",
          "Не менее месяца",
          "Не менее квартала",
          "Не менее полугода",
        ],
        correctAnswers: ["Не менее года"],
      },
      {
        code: "20602012",
        text: "Из чего состоит проект буровзрывных (взрывных) работ (проект массового взрыва) для конкретных условий?",
        answers: [
          "Из таблицы параметров взрывных работ",
          "Из распорядка проведения массового взрыва",
          "Из технического расчета со схемой расположения скважин и графическими материалами",
          "Из всего перечисленного",
        ],
        correctAnswers: ["Из всего перечисленного"],
      },
      {
        code: "20602013",
        text: "С какой периодичностью проводится пересмотр Регламента технологического процесса производства и подготовки взрывчатых веществ?",
        answers: [
          "Не реже 1 раза в 5 лет",
          "Не реже 1 раза в год",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 2 года",
        ],
        correctAnswers: ["Не реже 1 раза в 5 лет"],
      },
      {
        code: "20602014",
        text: "Куда передается акт об уничтожении взрывчатых материалов?",
        answers: [
          "На склад взрывчатых материалов",
          "В отдел пожарной безопасности",
          "Главному инженеру",
          "В территориальный орган Ростехнадзора",
        ],
        correctAnswers: ["На склад взрывчатых материалов"],
      },
      {
        code: "20602015",
        text: "Каким лицам право руководства взрывными работами предоставляется после дополнительного обучения по программе, согласованной с федеральным органом исполнительной власти в области промышленной безопасности?",
        answers: [
          'Лицам, окончившим высшие учебные заведения по специальности "Подземная разработка пластовых месторождений"',
          'Лицам, окончившим средние специальные учебные заведения по специальности "Шахтное строительство"',
          'Лицам, окончившим средние специальные учебные заведения по специальности "Подземная разработка месторождений полезных ископаемых"',
          "Все ответы неверны",
          'Лицам, окончившим высшие учебные заведения по специальности "Открытые горные работы"',
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20602016",
        text: "Какое из перечисленных условий допуска работников на рабочие места после производства массовых взрывов указано верно?",
        answers: [
          "Получение ответственным руководителем массового взрыва сообщения о снижении концентрации ядовитых продуктов взрыва в воздухе до установленных ПДК",
          "Осмотр мест (места) взрыва",
          "Частичное восстановление видимости",
          "Не ранее чем через 10 минут после взрыва",
          "Все перечисленные",
        ],
        correctAnswers: [
          "Получение ответственным руководителем массового взрыва сообщения о снижении концентрации ядовитых продуктов взрыва в воздухе до установленных ПДК",
        ],
      },
      {
        code: "20602017",
        text: "Какие из перечисленных мест хранения взрывчатых материалов должны быть приняты в эксплуатацию комиссией по оценке соответствия места хранения установленным требованиям и проектной документации?",
        answers: [
          "Места сменного хранения, размещаемые вблизи мест ведения взрывных работ",
          "Места размещения взрывчатых материалов на местах производства взрывных работ",
          "Все ответы неверны",
          "Передвижные склады",
          "Все перечисленные",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20602018",
        text: "Какие требования при вместимости складов и камер взрывчатых материалов указаны верно? Укажите все правильные ответы.",
        answers: [
          "Вместимость камеры в складах камерного типа не должна превышать 2000 кг взрывчатых веществ",
          "На угольных и сланцевых шахтах вместимость склада без учета емкости раздаточных камер не должна превышать семисуточного запаса взрывчатых веществ и пятнадцатисуточного запаса средств инициирования",
          "В складах ячейкового типа в каждой ячейке разрешается хранить не более 600 кг взрывчатых веществ",
          "Предельная вместимость отдельной раздаточной камеры в подземных выработках не должна превышать 4000 кг взрывчатых веществ и соответствующего количества средств инициирования",
        ],
        correctAnswers: [
          "Вместимость камеры в складах камерного типа не должна превышать 2000 кг взрывчатых веществ",
          "На угольных и сланцевых шахтах вместимость склада без учета емкости раздаточных камер не должна превышать семисуточного запаса взрывчатых веществ и пятнадцатисуточного запаса средств инициирования",
        ],
      },
      {
        code: "20602019",
        text: "Какая устанавливается максимальная суммарная загрузка здания, в котором производятся или подготавливаются взрывчатые вещества, с учетом взрывчатых веществ, находящихся в вагоне, смесительно-зарядной машине или другом транспортном средстве и накопительных емкостях?",
        answers: ["60 т", "70 т", "80 т", "90 т"],
        correctAnswers: ["60 т"],
      },
      {
        code: "20602020",
        text: "Для решения какого рода вопросов предназначены маркшейдерские работы и исполнительная маркшейдерская документация?",
        answers: [
          "Для решения вопросов проектирования и строительства подземных сооружений",
          "Для решения вопросов рационального использования подземного пространства городов",
          "Для решения вопросов охраны недр и природных объектов от вредного влияния подземного строительства",
          "Для решения всех перечисленных вопросов, а также для обеспечения безопасности строительно-монтажных работ",
        ],
        correctAnswers: [
          "Для решения всех перечисленных вопросов, а также для обеспечения безопасности строительно-монтажных работ",
        ],
      },
      {
        code: "20602021",
        text: "С какой периодичностью места хранения селитры должны подвергаться очистке?",
        answers: [
          "Не реже одного раза в квартал",
          "Не реже одного раза в год",
          "Не реже одного раза в месяц",
          "Не реже одного раза в полгода",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "20602022",
        text: "Что должны включать графические материалы проекта горного отвода при разработке месторождений полезных ископаемых, представленных комплексом рудных тел, а также месторождений угля? Укажите все правильные ответы.",
        answers: [
          "Гипсометрические планы рудных тел и пластов",
          "Погоризонтные планы рудных тел и пластов",
          "Геологическое строение участка недр",
          "Контуры запасов полезных ископаемых по категориям геологической изученности",
          "Тектонические нарушения",
        ],
        correctAnswers: [
          "Гипсометрические планы рудных тел и пластов",
          "Погоризонтные планы рудных тел и пластов",
        ],
      },
      {
        code: "20602023",
        text: "С какой периодичностью должна проверяться техническая исправность транспортных средств, используемых для доставки взрывчатых материалов, лицом, назначенным распорядительным документом организации?",
        answers: [
          "Ежесменно",
          "Трижды в смену",
          "Еженедельно",
          "Дважды в смену",
        ],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20602024",
        text: "В течение какого срока должны храниться в организации книга учета прихода и расхода взрывчатых материалов и книга учета выдачи и возврата взрывчатых материалов?",
        answers: [
          "В течение не менее двух лет",
          "В течение не менее одного года",
          "В течение не менее трех лет",
          "В течение не менее пяти лет",
        ],
        correctAnswers: ["В течение не менее трех лет"],
      },
      {
        code: "20602025",
        text: "Каким образом осуществляется пересылка документации, содержащей сведения, представляющие государственную тайну?",
        answers: [
          "В соответствии с законодательством Российской Федерации о государственной тайне",
          "Курьером любой курьерской фирмы",
          'Заказным почтовым отправлением через "Почту России"',
          "Курьером фирмы, подготовившей документацию",
        ],
        correctAnswers: [
          "В соответствии с законодательством Российской Федерации о государственной тайне",
        ],
      },
      {
        code: "20602026",
        text: "Кто должен осмотреть состояние кровли и стенок выработки и принять меры по приведению их в безопасное состояние перед началом работы по механизированному заряжанию шпуров, скважин или камер? Укажите все правильные ответы.",
        answers: [
          "Взрывник",
          "Руководитель взрывных работ",
          "Технический руководитель организации",
          "Рабочие, привлекаемые в помощь взрывникам",
        ],
        correctAnswers: ["Взрывник", "Руководитель взрывных работ"],
      },
      {
        code: "20602027",
        text: "Что из перечисленного может использоваться на участковых пунктах в качестве шкафов (контейнеров) для взрывчатых материалов? Укажите все правильные ответы.",
        answers: [
          "Сумки с жесткими ячейками, покрытыми внутри мягким материалом",
          "Шахтные вагонетки, оборудованные металлическими крышками",
          "Ящики, изготовленные из гипсоволокнистых листов",
          "Металлические сейфы, изготовленные из металлических листов толщиной не менее 2 мм",
        ],
        correctAnswers: [
          "Шахтные вагонетки, оборудованные металлическими крышками",
          "Металлические сейфы, изготовленные из металлических листов толщиной не менее 2 мм",
        ],
      },
      {
        code: "20602028",
        text: "Какие условия во временных складах взрывчатых материалов указаны верно? Укажите все правильные ответы.",
        answers: [
          "Устройство противопожарных водоемов (резервуаров, скважин) необязательно",
          "Устройство тамбуров не обязательно, двери могут быть одинарными",
          "Рабочее освещение внутри хранилищ не может осуществляться рудничными аккумуляторными светильниками",
          "Деревянные стены и крыши могут не покрываться огнезащитным составом",
        ],
        correctAnswers: [
          "Устройство противопожарных водоемов (резервуаров, скважин) необязательно",
          "Устройство тамбуров не обязательно, двери могут быть одинарными",
        ],
      },
      {
        code: "20602029",
        text: "Кем подписывается горноотводная документация?",
        answers: [
          "Уполномоченными должностными лицами территориального органа или структурного подразделения органа государственного горного надзора, в ведении которых находятся вопросы государственного горного надзора",
          "Главным маркшейдером",
          "Главным геологом",
          "Руководителем эксплуатирующей организации",
        ],
        correctAnswers: [
          "Уполномоченными должностными лицами территориального органа или структурного подразделения органа государственного горного надзора, в ведении которых находятся вопросы государственного горного надзора",
        ],
      },
      {
        code: "20602030",
        text: "Каким документом должны определяться место погрузки-выгрузки, меры безопасности, а также порядок погрузки-разгрузки взрывчатых материалов в околоствольных дворах шахт, рудников, штолен и надшахтных зданиях?",
        answers: [
          "Технологическим регламентом на проведение соответствующих работ",
          "Проектной документацией",
          "Предписанием территориального органа Ростехнадзора",
          "Распорядительным документом организации (шахты, рудника)",
        ],
        correctAnswers: [
          "Распорядительным документом организации (шахты, рудника)",
        ],
      },
      {
        code: "20602031",
        text: "Какому эквивалентному значению приравнивается 1 л диоксида азота при проверке вредных продуктов взрыва в выработке (забое)?",
        answers: [
          "8,5 л оксида углерода",
          "7,5 л оксида углерода",
          "6,5 л оксида углерода",
          "5,5 л оксида углерода",
        ],
        correctAnswers: ["6,5 л оксида углерода"],
      },
      {
        code: "20602032",
        text: "Кем могут выполняться отдельные виды геодезических и маркшейдерских работ?",
        answers: [
          "Территориальным органом Ростехнадзора",
          "Сторонней организацией, имеющей лицензию на право производства маркшейдерских работ, выданную Ростехнадзором",
          "Любой экспертной организацией по решению организации-заказчика",
          "Территориальным управлением Росреестра",
          "Органами местного самоуправления",
        ],
        correctAnswers: [
          "Сторонней организацией, имеющей лицензию на право производства маркшейдерских работ, выданную Ростехнадзором",
        ],
      },
      {
        code: "20602033",
        text: "На кого возлагается контроль за своевременным выполнением и качеством маркшейдерских работ?",
        answers: [
          "На главного маркшейдера организации",
          "На главного геолога организации",
          "На технического руководителя организации",
          "На комиссию, утвержденную руководителем организации",
        ],
        correctAnswers: ["На главного маркшейдера организации"],
      },
      {
        code: "20602034",
        text: "В течение какого времени должна проводиться стажировка взрывников перед допуском к самостоятельному производству взрывных работ, в том числе после обучения на новый вид взрывных работ?",
        answers: [
          "В течение 15 дней",
          "В течение трех месяцев",
          "В течение одного месяца",
          "В течение двух месяцев",
        ],
        correctAnswers: ["В течение одного месяца"],
      },
      {
        code: "20602035",
        text: 'К какому классу относится гидротехническое сооружение в соответствии с критериями, утвержденными Постановлением Правительства РФ "Об утверждении критериев классификации гидротехнических сооружений" в случае, если оно может быть отнесено к разным классам?',
        answers: [
          "К наиболее высокому из них",
          "К наиболее низкому из них",
          "Класс определяется Ростехнадзором по инициативе собственников гидротехнических сооружений и (или) эксплуатирующих организаций",
          "Класс определяется проектной организацией при проведении государственной экспертизы декларации безопасности гидротехнического сооружения",
        ],
        correctAnswers: ["К наиболее высокому из них"],
      },
      {
        code: "20602036",
        text: "С какой периодичностью должны проверяться знания требований безопасности работниками, связанными с обращением со взрывчатыми материалами и имеющими Единую книжку взрывника (за исключением заведующих складами взрывчатых материалов, пунктами производства взрывчатых материалов и руководителей взрывных работ) специальной комиссией организации с участием представителя территориального органа Ростехнадзора?",
        answers: [
          "Не реже одного раза в год",
          "Не реже одного раза в пять лет",
          "Не реже одного раза в три года",
          "Не реже одного раза в два года",
        ],
        correctAnswers: ["Не реже одного раза в два года"],
      },
      {
        code: "20602037",
        text: "Какое из перечисленных требований к транспортированию взрывчатых материалов указано верно? Укажите все правильные ответы.",
        answers: [
          "Спуск-подъем взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами должен проводиться вне очереди",
          "При спуске-подъеме взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами по наклонным выработкам в людских вагонетках на каждом сиденье должно находиться не более одного взрывника или подносчика",
          "Разрешается одновременно спускаться или подниматься в одной клети не более одного взрывника или подносчика с сумками с взрывчатыми материалами",
          "Транспортирование взрывчатых материалов по подземным выработкам должно осуществляться со скоростью не более 10 м/с",
        ],
        correctAnswers: [
          "Спуск-подъем взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами должен проводиться вне очереди",
          "При спуске-подъеме взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами по наклонным выработкам в людских вагонетках на каждом сиденье должно находиться не более одного взрывника или подносчика",
        ],
      },
      {
        code: "20602038",
        text: "Кем разрабатывается и согласовывается с командиром обслуживающего аварийно-спасательного формирования план по обслуживанию массового взрыва силами аварийно-спасательных формирований? Укажите все правильные ответы.",
        answers: [
          "Техническим руководителем рудника, шахты, объекта строительства",
          "Руководителем рудника, шахты, объекта строительства",
          "Ответственным руководителем взрыва",
          "Инспектором территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "Техническим руководителем рудника, шахты, объекта строительства",
          "Ответственным руководителем взрыва",
        ],
      },
      {
        code: "20602039",
        text: "С какой периодичностью проводится определение относительной влажности воздуха в забойном пространстве после внедрения пневматического заряжания?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в квартал",
          "Не реже одного раза в год",
          "Не реже одного раза в полгода",
        ],
        correctAnswers: ["Не реже одного раза в квартал"],
      },
      {
        code: "20602040",
        text: "В состав какой документации должен входить горноотводный акт и графические приложения к нему?",
        answers: [
          "Горноотводной документации",
          "Проектной документации",
          "Документации на техническое перевооружение, консервацию и ликвидацию",
          "Документации к обоснованию безопасности",
        ],
        correctAnswers: ["Горноотводной документации"],
      },
      {
        code: "20602041",
        text: "Какое из перечисленных требований при применении средств инициирования запрещается?",
        answers: [
          "Расширять гнезда патронов (шашек) заводского изготовления, из которых изготовлены боевики из прессованных или литых взрывчатых веществ с инициированием от детонаторов",
          "Обматывать детонирующий шнур вокруг патрона взрывчатых веществ",
          "При изготовлении промежуточных детонаторов из порошкообразных патронированных взрывчатых веществ с применением детонирующего шнура конец детонирующего шнура в патроне складывать вдвое",
          "При изготовлении промежуточных детонаторов из порошкообразных патронированных взрывчатых веществ с применением детонирующего шнура конец детонирующего шнура в патроне завязывать узлом",
        ],
        correctAnswers: [
          "Расширять гнезда патронов (шашек) заводского изготовления, из которых изготовлены боевики из прессованных или литых взрывчатых веществ с инициированием от детонаторов",
        ],
      },
      {
        code: "20602042",
        text: "Кем подписывается проект горного отвода (пояснительная записка)?",
        answers: [
          "Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода",
          "Руководителем проектной организации, имеющим право без доверенности действовать от имени пользователя недр",
          "Главным маркшейдером организации",
          "Техническим руководителем организации, испрашивающей горный отвод",
        ],
        correctAnswers: [
          "Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода",
        ],
      },
      {
        code: "20602043",
        text: "Каким федеральным органом исполнительной власти оформляется горноотводная документация?",
        answers: [
          "Органом государственного горного надзора и уполномоченными органами исполнительной власти субъектов Российской Федерации",
          "Федеральной службой по экологическому, технологическому и атомному надзору",
          "Федеральным агентством по недропользованию",
          "Министерством природных ресурсов и экологии Российской Федерации",
        ],
        correctAnswers: [
          "Органом государственного горного надзора и уполномоченными органами исполнительной власти субъектов Российской Федерации",
        ],
      },
      {
        code: "20602044",
        text: "К какому классу относятся плотины бетонные, железобетонные высотой менее 25 м и типом грунта основания А?",
        answers: ["I классу", "II классу", "III классу", "IV классу"],
        correctAnswers: ["IV классу"],
      },
      {
        code: "20602045",
        text: "К какому классу относятся плотины из грунтовых материалов высотой менее 15 м и типом грунта основания Б?",
        answers: ["I классу", "II классу", "III классу", "IV классу"],
        correctAnswers: ["IV классу"],
      },
      {
        code: "20602046",
        text: "Какие грунты относятся к классу А?",
        answers: [
          "Песчаные, крупнообломочные и глинистые в твердом и полутвердом состоянии",
          "Скальные",
          "Глинистые водонасыщенные в пластичном состоянии",
        ],
        correctAnswers: ["Скальные"],
      },
      {
        code: "20602047",
        text: "Какие грунты относятся к классу В?",
        answers: [
          "Песчаные, крупнообломочные и глинистые в твердом и полутвердом состоянии",
          "Скальные",
          "Глинистые водонасыщенные в пластичном состоянии",
        ],
        correctAnswers: ["Глинистые водонасыщенные в пластичном состоянии"],
      },
      {
        code: "20602048",
        text: "Кем оформляется документация, удостоверяющая уточненные границы горного отвода, если участки недр местного значения расположены на территориях 2 и более субъектов Российской Федерации и если нормативными правовыми актами субъекта Российской Федерации не определен орган исполнительной власти субъекта Российской Федерации?",
        answers: [
          "Органом государственного горного надзора",
          "Органами исполнительной власти каждого субъекта Российской Федерации",
          "Органом исполнительной власти любого из причастных к горному отводу субъекта Российской Федерации",
        ],
        correctAnswers: ["Органом государственного горного надзора"],
      },
      {
        code: "20602049",
        text: "В какой срок со дня поступления заявления осуществляется оформление документации, удостоверяющей уточненные границы горного отвода?",
        answers: [
          "Не более 10 дней",
          "Не более 15 дней",
          "Не более 25 дней",
          "Не более 30 дней",
        ],
        correctAnswers: ["Не более 25 дней"],
      },
      {
        code: "20602050",
        text: "В какой срок органом исполнительной власти субъекта Российской Федерации оформленная документация, удостоверяющая уточненные границы горного отвода, передается пользователю недр?",
        answers: [
          "Не позднее 7 дней со дня оформления",
          "Не позднее 5 дней со дня оформления",
          "Не позднее 10 дней со дня оформления",
          "Не позднее 14 дней со дня оформления",
        ],
        correctAnswers: ["Не позднее 5 дней со дня оформления"],
      },
      {
        code: "20602051",
        text: "Сколько экземпляров документации, удостоверяющей уточненные границы горного отвода, оформляется органом исполнительной власти субъекта Российской Федерации?",
        answers: [
          "2 экземпляра",
          "3 экземпляра",
          "4 экземпляра",
          "5 экземпляров",
        ],
        correctAnswers: ["3 экземпляра"],
      },
      {
        code: "20602052",
        text: "В каких случаях документация, удостоверяющая уточненные границы горного отвода, подлежит переоформлению?",
        answers: [
          "Только при изменении технического проекта выполнения работ, связанных с пользованием недрами",
          "Только при изменении геологической информации о недрах, наличии технологических потребностей, условий и факторов, влияющих на безопасное состояние недр, земной поверхности и расположенных на ней объектов",
          "Только при переоформлении лицензии на пользование недрами",
          "Во всех перечисленных случаях, а также при выявлении технических ошибок в лицензии на пользование недрами",
        ],
        correctAnswers: [
          "Во всех перечисленных случаях, а также при выявлении технических ошибок в лицензии на пользование недрами",
        ],
      },
      {
        code: "20602053",
        text: "Чем необходимо руководствоваться при подготовке обоснований границ горного отвода?",
        answers: [
          "Горный отвод может состоять из нескольких отдельных блоков недр (рассредоточенный горный отвод) в случаях, когда участки ведения работ по добыче полезных ископаемых представлены отдельными участками (рудными телами, пластами) одного месторождения и (или) месторождениями полезных ископаемых (эксплуатационными объектами), находящимися в границах одного участка недр, предоставленного на основании лицензии на пользование недрами, и (или) отдельными участками ведения работ (объектами, сооружениями), связанных с пользованием недрами, предусмотренными проектной документацией и (или) для месторождений твердых полезных ископаемых по решению пользователя недр схемами развития горных работ",
          "При использовании в качестве подземных хранилищ соляных пластов границы горного отвода устанавливаются по границам геометрического тела сферической или цилиндрической формы, увеличенным не менее чем на 3 м по вертикали и 20 м по горизонтали относительно контура камер выщелачивания",
          "Площадь проекции горного отвода на земную поверхность определяется в гектарах с точностью до целого числа",
          "Всем перечисленным",
        ],
        correctAnswers: [
          "Горный отвод может состоять из нескольких отдельных блоков недр (рассредоточенный горный отвод) в случаях, когда участки ведения работ по добыче полезных ископаемых представлены отдельными участками (рудными телами, пластами) одного месторождения и (или) месторождениями полезных ископаемых (эксплуатационными объектами), находящимися в границах одного участка недр, предоставленного на основании лицензии на пользование недрами, и (или) отдельными участками ведения работ (объектами, сооружениями), связанных с пользованием недрами, предусмотренными проектной документацией и (или) для месторождений твердых полезных ископаемых по решению пользователя недр схемами развития горных работ",
        ],
      },
      {
        code: "20602054",
        text: "В каких местах при взрывных работах могут использоваться слежавшиеся порошкообразные взрывчатые вещества, содержащие гексоген или жидкие нитроэфиры, без размятия или измельчения?",
        answers: [
          "Во всех перечисленных местах",
          "На земной поверхности",
          "В шахтах (рудниках), разрабатывающих пласты (рудные тела), не опасные по взрывам пыли",
          "В шахтах (рудниках), не опасных по газу",
        ],
        correctAnswers: ["На земной поверхности"],
      },
      {
        code: "20602055",
        text: "Какое действие из перечисленных допускается при ведении взрывных работ?",
        answers: [
          "Применять рассредоточенные заряды в шахтах, опасных по газу или пыли, в породных забоях выработок, в которых отсутствует выделение горючих газов, во врубовых шпурах",
          "Взрывать заряды без забойки на шахтах (рудниках), опасных по газу и пыли",
          "Тянуть провода электронных детонаторов и электродетонаторов, введенные в боевики",
          "Переламывать выходящие из зарядов концы детонирующего шнура",
        ],
        correctAnswers: [
          "Применять рассредоточенные заряды в шахтах, опасных по газу или пыли, в породных забоях выработок, в которых отсутствует выделение горючих газов, во врубовых шпурах",
        ],
      },
      {
        code: "20602056",
        text: "Какие материалы допускается использовать для насыпки валов складов взрывчатых материалов?",
        answers: [
          "Камень",
          "Пластичные грунты",
          "Все перечисленные материалы",
          "Щебень",
        ],
        correctAnswers: ["Пластичные грунты"],
      },
      {
        code: "20602057",
        text: "Каким образом очищается оборудование при наличии несмываемых остатков взрывчатых веществ по окончании заряжания? Укажите все правильные ответы.",
        answers: [
          "Горячей водой",
          "Паром",
          "Металлической щеткой",
          "Сжатым воздухом",
        ],
        correctAnswers: ["Горячей водой", "Паром"],
      },
      {
        code: "20602058",
        text: "Какое из перечисленных требований к углубленным складам взрывчатых материалов указано верно?",
        answers: [
          "Наружная дверь выработок, ведущих к углубленному складу, должна быть решетчатой, а внутренняя - металлической или деревянной",
          "Устья выработок, ведущих к углубленному складу, должны быть оборудованы двойными дверями, открывающимися наружу",
          "Перед устьем выработки, ведущей к углубленному складу, необходимо устраивать защитный вал высотой, превышающей высоту выработки на 1 м",
          "Если расстояние от входа в углубленный склад до ближайшей камеры хранения взрывчатых материалов более 10 м, склад должен иметь 2 выхода",
        ],
        correctAnswers: [
          "Устья выработок, ведущих к углубленному складу, должны быть оборудованы двойными дверями, открывающимися наружу",
        ],
      },
      {
        code: "20602059",
        text: "В каких хранилищах взрывчатых материалов запрещается пользоваться открытым огнем?",
        answers: [
          "В хранилищах аммиачной селитры",
          "В хранилищах кальциевой селитры",
          "В хранилищах натриевой селитры",
          "Во всех перечисленных хранилищах",
        ],
        correctAnswers: ["В хранилищах аммиачной селитры"],
      },
      {
        code: "20602060",
        text: "Какое из перечисленных требований к условиям заряжания, массе зарядов взрывчатых веществ и длине забойки указано верно?",
        answers: [
          "При наличии в забое нескольких обнаженных поверхностей линия наименьшего сопротивления от любой точки заряда до ближайшей обнаженной поверхности должна быть не менее 0,5 м в продуктивном пласте и не менее 0,3 м по породе",
          "В шпурах глубиной от 1 до 1,5 м заряд должен занимать не более половины их длины",
          "Все перечисленные требования",
          "В шпурах глубиной более 1,5 м заряд должен занимать не более 2/3 их длины",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20602061",
        text: "Кем проверяется подготовленный к массовому взрыву подэтаж (блок, панель)?",
        answers: [
          "Руководителем рудника (шахты, объекта строительства)",
          "Техническим руководителем рудника (шахты, объекта строительства)",
          "Руководителем массового взрыва",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20602062",
        text: "В каком случае обеспечивается защита хранилищ взрывчатых материалов от заноса высоких потенциалов при вводе в них электрических сетей освещения присоединением металлической брони и оболочки кабеля к заземлителю защиты от вторичных воздействий?",
        answers: [
          "При бронированных кабелях, проложенных в земле",
          "При кабелях, присоединенных к воздушной линии",
          "При небронированных кабелях",
        ],
        correctAnswers: ["При бронированных кабелях, проложенных в земле"],
      },
      {
        code: "20602063",
        text: "Для каких видов взрывных работ применяются предохранительные взрывчатые вещества VI класса?",
        answers: [
          "Для верхней и смешанной подрывки пород с f = 4 и менее в вентиляционных штреках, проводимых вслед за лавой",
          "Для ликвидации зависаний горной массы в углеспускных выработках",
          "Для взрывного перебивания деревянных стоек при посадке кровли",
          "Для дробления негабаритов наружными зарядами",
        ],
        correctAnswers: [
          "Для верхней и смешанной подрывки пород с f = 4 и менее в вентиляционных штреках, проводимых вслед за лавой",
        ],
      },
      {
        code: "20602064",
        text: "Взрывчатые вещества каких составов разрешается производить на пунктах производства взрывчатых веществ?",
        answers: [
          "Смеси холодного смешения гранулированной аммиачной селитры с гранулированным (чешуированным) тротилом, жидкими и твердыми нефтяными, порошкообразными или другого происхождения невзрывчатыми горючими",
          "Водосодержащие смеси-суспензии, эмульсии и эмульсионные взрывчатые вещества на основе раствора аммиачной селитры или ее раствора с добавками кальциевой или натриевой селитры или карбамида с порошкообразными и жидкими невзрывчатыми горючими",
          "Всех перечисленных",
          "Смеси холодного смешения гранулированной аммиачной селитры с жидкими и твердыми нефтяными, порошкообразными или другого происхождения невзрывчатыми горючими",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20602065",
        text: "На какое минимальное расстояние от скважины должны быть убраны буровые установки, не имеющие приспособления для заряжания, в сложных горно-геологических условиях?",
        answers: ["10 м", "5 м", "3 м", "7 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "20602066",
        text: "На каком расстоянии от отказавших шпуровых зарядов разрешается размещать вспомогательные шпуры для их ликвидации?",
        answers: [
          "Не ближе 15 см",
          "Не ближе 30 см",
          "Не ближе 20 см",
          "Не ближе 25 см",
        ],
        correctAnswers: ["Не ближе 30 см"],
      },
      {
        code: "20602067",
        text: "Каким должно быть расстояние между параллельно проводимыми выработками угольных и нефтяных шахт, при котором взрывание зарядов в каждом забое должно проводиться только после вывода людей из забоя параллельной выработки и выставления постов охраны, предусмотренных паспортами буровзрывных работ?",
        answers: [
          "20 м и менее",
          "30 м и менее",
          "15 м и менее",
          "25 м и менее",
        ],
        correctAnswers: ["15 м и менее"],
      },
      {
        code: "20602068",
        text: "При какой толщине породной пробки между забоем выработки и крутым пластом (пропластком) необходимо производить вскрытие и пересечение пластов при помощи буровзрывных работ?",
        answers: [
          "Не менее 3 м",
          "Не менее 1 м",
          "Не менее 2 м",
          "Не менее 4 м",
        ],
        correctAnswers: ["Не менее 2 м"],
      },
      {
        code: "20602069",
        text: "В каких случаях при взрывании скважинных зарядов на поверхности обязательно дублирование внутрискважинной сети?",
        answers: [
          "При глубине взрывных скважин более 15 м",
          "При глубине взрывных скважин более 5 м",
          "При использовании электронных систем инициирования",
          "При производстве взрывных работ в подземных выработках",
        ],
        correctAnswers: ["При глубине взрывных скважин более 15 м"],
      },
      {
        code: "20602070",
        text: "Каким образом следует проводить бурение шпуров по углю для сотрясательного взрывания?",
        answers: [
          "В соответствии с решением руководителя сотрясательного взрывания",
          "Ударновращательным способом",
          "Ударным способом",
          "Вращательным способом",
        ],
        correctAnswers: ["Вращательным способом"],
      },
      {
        code: "20602071",
        text: "Какое из перечисленных требований во время взрывания при образовании каналов, канав и котлованов указано верно?",
        answers: [
          "В сложных горно-геологических условиях при взрывании неэлектрическими системами инициирования и детонирующим шнуром зарядов взрывчатых веществ группы D (кроме дымного пороха) допускается размещение удлиненных горизонтальных зарядов (траншейных, щелевых) непосредственно вслед за проведением горных выработок",
          "При формировании траншейных зарядов расстояние между экскаватором (траншеекопателем) и краном, укладывающим взрывчатые материалы в траншею, должно быть не менее 1,5 суммы радиусов разворота ковша экскаватора с вытянутой рукоятью и разворота крана",
          "При заряжании линейных зарядов более 12 часов боевики укладываются в день производства взрыва",
          "Все перечисленные требования указаны верно",
        ],
        correctAnswers: [
          "В сложных горно-геологических условиях при взрывании неэлектрическими системами инициирования и детонирующим шнуром зарядов взрывчатых веществ группы D (кроме дымного пороха) допускается размещение удлиненных горизонтальных зарядов (траншейных, щелевых) непосредственно вслед за проведением горных выработок",
        ],
      },
      {
        code: "20602072",
        text: "Где должен находиться руководитель сотрясательного взрывания в забое, замеряющий содержание метана, при продвижении к забою для осмотра его после сотрясательного взрывания?",
        answers: [
          "На расстоянии 3 м впереди взрывника",
          "На расстоянии 5 м позади взрывника",
          "Не регламентируется",
          "На расстоянии 5 м впереди взрывника",
          "На расстоянии 3 м позади взрывника",
        ],
        correctAnswers: ["На расстоянии 3 м впереди взрывника"],
      },
      {
        code: "20602073",
        text: "В каких из перечисленных помещениях допускается хранение взрывчатых материалов?",
        answers: [
          "В здании выдачи взрывчатых материалов",
          "Все ответы неверны",
          "В тамбурах хранилищ",
          "В здании подготовки взрывчатых материалов",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20602074",
        text: "На каком минимальном расстоянии от места взрыва должно находиться место укрытия взрывников при пропуске угля и породы в восстающих выработках?",
        answers: ["150 м", "100 м", "70 м", "50 м"],
        correctAnswers: ["150 м"],
      },
      {
        code: "20602075",
        text: "Какое количество приемов допускается при проведении взрывания по породе выработок, в которых отсутствует выделение метана, с применением электродетонаторов мгновенного, короткозамедленного и замедленного действия со временем замедления до 2 секунд?",
        answers: [
          "Не более трех приемов",
          "Не более четырех приемов",
          "Без ограничения количества приемов",
          "Не более двух приемов",
        ],
        correctAnswers: ["Без ограничения количества приемов"],
      },
      {
        code: "20602076",
        text: "При какой температуре запрещается заряжание и взрывание зарядов в шпурах при ведении взрывных работ по металлу?",
        answers: ["Свыше 100 °C", "Свыше 200 °C", "Свыше 150 °C"],
        correctAnswers: ["Свыше 200 °C"],
      },
      {
        code: "20602077",
        text: "Какая допускается максимальная температура сжатого воздуха (при работе с автономным компрессором), а также нагрева узлов зарядных устройств, через которые проходят взрывчатые вещества?",
        answers: ["40 °C", "70 °C", "50 °C", "60 °C"],
        correctAnswers: ["60 °C"],
      },
      {
        code: "20602078",
        text: "Кто устанавливает маршруты транспортирования взрывчатых материалов от склада ВМ на места работ (в пределах опасного производственного объекта)?",
        answers: [
          "Представитель вспомогательной горно-спасательной команды или профессионального аварийно-спасательного формирования (службы)",
          "Руководитель взрывных работ организации, эксплуатирующей опасный производственный объект",
          "Представитель территориального органа Ростехнадзора",
          "Руководитель (технический руководитель) организации, эксплуатирующей опасный производственный объект",
        ],
        correctAnswers: [
          "Руководитель (технический руководитель) организации, эксплуатирующей опасный производственный объект",
        ],
      },
      {
        code: "20602079",
        text: "Какими лицами переносятся средства инициирования?",
        answers: [
          "Проходчиками",
          "Всеми перечисленными лицами",
          "Взрывниками",
          "Раздатчиками взрывных материалов",
        ],
        correctAnswers: ["Взрывниками"],
      },
      {
        code: "20602080",
        text: "Каким образом запрещается производить заряжание шпуров (скважин) и монтаж взрывной сети на высоте более 2 м?",
        answers: [
          "С лестниц",
          "С оборудованных подъемных площадок (помостов)",
          "С площадок подъемных механизмов",
          "С применением погрузочно-доставочной техники",
        ],
        correctAnswers: ["С лестниц"],
      },
      {
        code: "20602081",
        text: "Какие прострелочно-взрывные аппараты должны подниматься над устьем скважины и опускаться с помощью грузоподъемных механизмов?",
        answers: [
          "Массой более 30 кг или длиной более 1 м",
          "Массой более 40 кг или длиной более 0,5 м",
          "Массой более 40 кг или длиной более 1,5 м",
          "Все ответы неверны",
          "Массой более 20 кг или длиной более 1,7 м",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20602082",
        text: "На каком расстоянии от места заряжания скважин и стоянки зарядной машины и трубопровода запрещается производить какие-либо работы, непосредственно не связанные с заряжанием?",
        answers: [
          "Не ближе 50 м",
          "Не ближе 40 м",
          "Не ближе 30 м",
          "Не ближе 20 м",
        ],
        correctAnswers: ["Не ближе 50 м"],
      },
      {
        code: "20602083",
        text: "После чего начинаются загрузка бункера зарядного оборудования и непосредственно заряжание?",
        answers: [
          "Поcле сигнала диспетчера",
          "После того, как взрывник убедится в том, что блок и зарядные устройства к этим работам подготовлены",
          "После того, как руководитель взрывных работ убедится в том, что блок и зарядные устройства к этим работам подготовлены",
          "После предупреждения лиц, участвующих в заряжании",
        ],
        correctAnswers: [
          "После того, как руководитель взрывных работ убедится в том, что блок и зарядные устройства к этим работам подготовлены",
        ],
      },
      {
        code: "20602084",
        text: "Какие материалы передаются организации-заказчику при постороении маркшейдерских опорных геодезических сетей на поверхности сторонней организацией?",
        answers: [
          "Каталоги координат и высот пунктов",
          "Все перечисленные материалы",
          "Журналы измерений и ведомости вычислений",
          "Оригиналы планов",
        ],
        correctAnswers: ["Каталоги координат и высот пунктов"],
      },
      {
        code: "20602085",
        text: "Какие из перечисленных сооружений при строительстве коллекторных тоннелей относятся к группе А? Укажите все правильные ответы.",
        answers: [
          "Насосные станции",
          "Порталы тоннелей",
          "Строительные краны",
          "Подъемные машины (лебедки)",
          "Копры",
        ],
        correctAnswers: ["Насосные станции", "Порталы тоннелей"],
      },
      {
        code: "20602086",
        text: "Какие из перечисленных помещений разрешается размещать в зданиях пунктов производства и подготовки взрывчатых веществ, за исключением зданий, в которых непосредственно производятся или подготавливаются взрывчатые вещества? Выберите 2 варианта ответа.",
        answers: [
          "Помещения временного пребывания дежурных слесарей и электриков",
          "Слесарные мастерские для мелкого текущего ремонта",
          "Склады ГСМ",
          "Сварочные посты",
        ],
        correctAnswers: [
          "Помещения временного пребывания дежурных слесарей и электриков",
          "Слесарные мастерские для мелкого текущего ремонта",
        ],
      },
      {
        code: "20602087",
        text: "Какая допускается максимальная скорость движения железнодорожного подвижного состава с опасными грузами на территории пункта подготовки взрывчатых веществ?",
        answers: ["5 км/ч", "15 км/ч", "20 км/ч", "10 км/ч"],
        correctAnswers: ["15 км/ч"],
      },
      {
        code: "20602088",
        text: "Какое совместное хранение взрывчатых материалов не допускается?",
        answers: [
          "Детонаторы для взрывных работ, сборки детонаторов для взрывных работ и капсюли-воспламенители (группа совместимости В) с первичным взрывчатым веществом",
          "Зарядов кумулятивных группы совместимости S с зарядами кумулятивными группы совместимости D",
          "Детонирующего шнура групп совместимости D и S со средствами инициирования групп совместимости B и S",
          "Средств инициирования группы совместимости S и средств инициирования группы совместимости B",
        ],
        correctAnswers: [
          "Детонаторы для взрывных работ, сборки детонаторов для взрывных работ и капсюли-воспламенители (группа совместимости В) с первичным взрывчатым веществом",
        ],
      },
      {
        code: "20602089",
        text: "Какой способ ликвидации отказавших скважинных зарядов указан неверно?",
        answers: [
          "Вымывание заряда из скважины при взрывании дымного пороха с применением детонирующего шнура",
          "Взрывание заряда в скважине, пробуренной параллельно на расстоянии не менее 3 м от скважины с отказавшим зарядом",
          "Разборка породы в месте нахождения скважины с отказавшим зарядом с извлечением последнего вручную",
          "Взрывание отказавшего заряда в случае, если отказ произошел в результате нарушения целостности внешней взрывной сети (если линия наименьшего сопротивления отказавшего заряда не уменьшилась)",
        ],
        correctAnswers: [
          "Вымывание заряда из скважины при взрывании дымного пороха с применением детонирующего шнура",
        ],
      },
      {
        code: "20602090",
        text: "Что указывается на плане горного отвода?",
        answers: [
          "Площадь проекции горного отвода в гектарах на горизонтальную плоскость",
          "Линии вертикальных разрезов (профилей) и проекций",
          "Используемые системы координат и высот, масштаб плана, координатная сетка",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20602091",
        text: "В каком случае оборудование и здание пунктов производства и подготовки взрывчатых веществ в целом должны быть полностью освобождены от взрывоопасных продуктов?",
        answers: [
          "Ни в каком случае",
          "Если не может быть организовано постоянное наблюдение",
          "Перед нерабочей сменой",
          "При остановке пункта на период более двух суток",
        ],
        correctAnswers: ["При остановке пункта на период более двух суток"],
      },
      {
        code: "20602092",
        text: "Работа каких конвейеров, транспортирующих пожаровзрывоопасные вещества, допускается без устройства блокировочных устройств?",
        answers: [
          "Ленточные конвейеры",
          "Цепные конвейеры",
          "Винтовые конвейеры",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20602093",
        text: "Кем должны проводиться заряжание и забойка при ведении взрывных работ по металлу?",
        answers: [
          "Двумя взрывниками в присутствии руководителя взрывных работ",
          "Тремя взрывниками в присутствии руководителя взрывных работ",
          "Одним взрывником в присутствии руководителя взрывных работ",
          "Тремя взрывниками",
        ],
        correctAnswers: [
          "Двумя взрывниками в присутствии руководителя взрывных работ",
        ],
      },
      {
        code: "20602094",
        text: "При каком условии разрешается выход взрывника из укрытия после взрыва при взрывании с применением электродетонаторов?",
        answers: [
          "После отсоединения электровзрывной сети от источника тока и замыкания ее накоротко",
          "При выполнении всех перечисленных условий",
          "Не ранее чем через 5 минут",
          "После проветривания",
        ],
        correctAnswers: ["При выполнении всех перечисленных условий"],
      },
      {
        code: "20602095",
        text: "Какие условия должны соблюдаться в забоях выработок, где имеется газовыделение или взрывчатая угольная пыль?",
        answers: [
          "При протяженности угольного забоя более 5 м разрешается его делить по длине на участки, и взрывание в каждом из них производить раздельно",
          "В подготовительных выработках, проводимых по углю, и в комбайновых нишах очистных забоев без машинного вруба все заряды в угольном забое должны взрываться последовательно от нескольких импульсов тока взрывного прибора",
          "Общее максимальное время замедления электродетонаторов короткозамедленного действия с учетом разброса по времени срабатывания не должно превышать при применении взрывчатых веществ III класса - 320 миллисекунд",
          "Все перечисленные условия",
        ],
        correctAnswers: [
          "При протяженности угольного забоя более 5 м разрешается его делить по длине на участки, и взрывание в каждом из них производить раздельно",
        ],
      },
      {
        code: "20602096",
        text: "Какие взрывчатые вещества должны использоваться при разупрочнении труднообрушаемых пород любой крепости?",
        answers: [
          "Взрывчатые вещества, не содержащие сенсибилизаторов, более чувствительных, чем тротил",
          "Взрывчатые вещества, содержащие нитроэфиры",
          "Взрывчатые вещества, содержащие гексоген",
          "Любые взрывчатые вещества",
        ],
        correctAnswers: [
          "Взрывчатые вещества, не содержащие сенсибилизаторов, более чувствительных, чем тротил",
        ],
      },
      {
        code: "20602097",
        text: "Каким способом разрешается проводить взрывание камерных зарядов?",
        answers: [
          "С применением электродетонаторов",
          "С применением неэлектрических систем инициирования",
          "С применением детонирующего шнура",
          "Всеми перечисленными способами",
        ],
        correctAnswers: ["Всеми перечисленными способами"],
      },
      {
        code: "20602098",
        text: "Какое из перечисленных требований к уничтожению взрывчатых материалов указано верно?",
        answers: [
          "Запрещается подход к месту сжигания до полного прекращения горения костра с взрывчатыми материалами",
          "Допускается сжигать взрывчатые материалы в их таре",
          "Допускается применение смерзшихся взрывчатых веществ, содержащих жидкие нитроэфиры свыше 5%",
          "Взрывчатые материалы со следами экссудации должны выдаваться со склада для применения",
        ],
        correctAnswers: [
          "Запрещается подход к месту сжигания до полного прекращения горения костра с взрывчатыми материалами",
        ],
      },
      {
        code: "20602099",
        text: "В каком случае разрешается доставка аммиачно-селитренных взрывчатых веществ к местам проведения взрывных работ в подземных выработках в ковшах погрузочно-доставочных машин от участковых пунктов хранения и мест выгрузки взрывчатых материалов?",
        answers: [
          "При условии загрузки ковша не более 2/3 по его высоте",
          "При условии загрузки ковша не более 3/4 по его высоте",
          "Ни при каких условиях",
          "При условии руководства операцией лицом, назначенным ответственным за доставку (подъем, спуск) взрывчатых материалов",
        ],
        correctAnswers: [
          "При условии загрузки ковша не более 2/3 по его высоте",
        ],
      },
      {
        code: "20602100",
        text: "Какое из перечисленных требований к порядку присвоения и нанесения индивидуальных индексов электродетонаторов и капсюлей-детонаторов указано верно?",
        answers: [
          "В случае появления трещин во внешней оболочке изделий они должны быть отремонтированы",
          "Работы по нанесению индивидуальных индексов средств инициирования необходимо проводить в помещении, отдельном от мест хранения взрывчатых материалов",
          "Нанесение индивидуальных индексов на электродетонаторы с помощью устройств обжимного типа должно производиться на 1,0 - 3,0 мм выше нижнего зига заводской обжимки гильзы (соединения капсюля-детонатора с электровоспламенителем)",
          "Нанесение индивидуальных индексов на капсюли-детонаторы должно осуществляться после изготовления зажигательных трубок",
        ],
        correctAnswers: [
          "Работы по нанесению индивидуальных индексов средств инициирования необходимо проводить в помещении, отдельном от мест хранения взрывчатых материалов",
        ],
      },
      {
        code: "20602101",
        text: "Какой документ служит для отпуска взрывчатых материалов взрывникам для производства взрывных работ?",
        answers: [
          "Наряд-путевка",
          "Наряд-накладная",
          "Акт-допуск",
          "Распоряжение",
        ],
        correctAnswers: ["Наряд-путевка"],
      },
      {
        code: "20602102",
        text: "В каком случае транспортные пути для перевозок взрывчатых материалов на территории поверхностных пунктов производства (подготовки) должны располагаться на расстоянии не менее 3 м от зданий?",
        answers: [
          "От зданий, в которых изготавливаются или перерабатываются взрывчатые вещества",
          "От вспомогательных зданий, находящихся на территории пункт",
          "В случае, если пути предназначены для подъезда к этим зданиям",
          "От зданий, где имеются открытые огневые топки и источники открытого огня или где производятся работы с открытым огнем",
        ],
        correctAnswers: [
          "В случае, если пути предназначены для подъезда к этим зданиям",
        ],
      },
      {
        code: "20602103",
        text: "Какой из перечисленных индексов, наносимый на гильзы электродетонаторов и капсюлей-детонаторов, обозначает номер взрывника?",
        answers: [
          "Два буквенных индекса справа от цифровых",
          "Два цифровых индекса",
          "Два буквенных индекса слева от цифровых",
          "Три буквенных индекса справа от цифровых",
        ],
        correctAnswers: ["Два буквенных индекса справа от цифровых"],
      },
      {
        code: "20602104",
        text: "Кому должен сообщить взрывник при возникновении аварийной ситуации в процессе заряжания?",
        answers: [
          "Техническому руководителю организации",
          "Рабочему, привлекаемому в помощь взрывнику",
          "Руководителю взрывных работ",
          "Диспетчеру",
        ],
        correctAnswers: ["Руководителю взрывных работ"],
      },
      {
        code: "20602105",
        text: "Какое действие необходимо выполнять для уменьшения пыления и просыпи взрывчатых веществ?",
        answers: [
          "Выдерживать оптимальное расстояние от конца шланга до заряда",
          "При заряжании центрировать конец зарядного трубопровода относительно оси скважины",
          "При пневмозаряжании алюмо- и тротилсодержащими рассыпными гранулированными взрывчатыми веществами добавлять во взрывчатые вещества воду или смачивающий раствор",
          "Все перечисленные действия",
        ],
        correctAnswers: ["Все перечисленные действия"],
      },
      {
        code: "20602106",
        text: "Какое из перечисленных условий допускается при заземлении и защите от образования статического электричества в пневмозарядных устройствах?",
        answers: [
          "Пневмозаряжание по одному трубопроводу взрывчатых веществ разных типов",
          "Пневмозаряжание гранулированными алюмо- и тротилсодержащими взрывчатыми веществами без предварительного их увлажнения",
          "Производить пневматическое заряжание шпуров в подземных горных выработках при относительной влажности рудничного воздуха более 50 % зарядчиками с металлической зарядной трубкой или с электропроводящим зарядным трубопроводом длиной не более 5 м",
          "Применение полиэтиленовых и резиновых трубопроводов (шлангов) с удельным объемным электрическим сопротивлением не более 107 МОм x м",
        ],
        correctAnswers: [
          "Применение полиэтиленовых и резиновых трубопроводов (шлангов) с удельным объемным электрическим сопротивлением не более 107 МОм x м",
        ],
      },
      {
        code: "20602107",
        text: "В каком месте необходимо располагать зарядную для аккумуляторных погрузчиков, а также постоянную стоянку зарядных машин (смесительно-зарядных машин, транспортно-зарядных машин)?",
        answers: [
          "Определяется проектной документацией",
          "За территорией пунктов производства и подготовки взрывчатых веществ на расстоянии не ближе 50 м от здания подготовки и (или) производства взрывчатых веществ",
          "Не ближе 100 м от здания подготовки и (или) производства взрывчатых веществ",
          "На территории пунктов производства и подготовки взрывчатых веществ на расстоянии от здания подготовки и (или) производства взрывчатых веществ, определенном техническим руководителем организации",
        ],
        correctAnswers: [
          "За территорией пунктов производства и подготовки взрывчатых веществ на расстоянии не ближе 50 м от здания подготовки и (или) производства взрывчатых веществ",
        ],
      },
      {
        code: "20602108",
        text: "С какой периодичностью проводится измерение параметров электризации в условиях производства?",
        answers: [
          "Не реже двух раз в год",
          "Не реже одного раза в год",
          "Не реже одного раза в месяц",
          "Не реже двух раз в квартал",
        ],
        correctAnswers: ["Не реже двух раз в год"],
      },
      {
        code: "20602109",
        text: "Кому должны быть сданы остатки взрывчатых материалов взрывниками по окончании взрывных работ?",
        answers: [
          "Остатки взрывчатых материалов должны быть возвращены обратно на склад кладовщику",
          "Лично техническому руководителю шахты (рудника)",
          "Лично лицу, ответственному за выдачу и приемку взрывчатых материалов (раздатчику, заведующему складом взрывчатых материалов)",
          "Лично руководителю взрывных работ",
        ],
        correctAnswers: [
          "Лично лицу, ответственному за выдачу и приемку взрывчатых материалов (раздатчику, заведующему складом взрывчатых материалов)",
        ],
      },
      {
        code: "20602110",
        text: "Какое из перечисленных условий указано неверно при вскрытии пластов сотрясательным взрыванием с применением рассредоточенных (двухъярусных) зарядов взрывчатых веществ?",
        answers: [
          "В шпурах с рассредоточенными зарядами замедление в донном заряде должно быть меньше, чем в первом заряде от устья",
          "Инициирование зарядов осуществляется электродетонаторами мгновенного и короткозамедленного действия",
          "При использовании допущенных для соответствующих условий взрывчатых веществ III и IV классов длина забойки между рассредоточенными зарядами должна быть не менее 0,75 м, а масса первого от устья шпура заряда - не более 1,2 кг",
          "Длина шпуров с рассредоточенными зарядами должна составлять не менее 3 м",
        ],
        correctAnswers: [
          "В шпурах с рассредоточенными зарядами замедление в донном заряде должно быть меньше, чем в первом заряде от устья",
        ],
      },
      {
        code: "20602111",
        text: "В каком случае допускается оставлять взрывчатые материалы на местах работ без постоянного надзора (охраны)?",
        answers: [
          "Все ответы неверны",
          "Если взрывчатые материалы хранятся в отдельных металлических ящиках и контейнерах (сейфах)",
          "Если взрывчатые материалы защищены от атмосферных осадков",
          "В случаях, утвержденных распорядительным документом организации, ведущей взрывные работы",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20602112",
        text: "В какой системе координат должны быть определены уточненные границы горного отвода?",
        answers: [
          "В прямоугольной системе координат",
          "В полярной системе координат (азимут, горизонтальное проложение)",
          "В географической системе координат (ширина, долгота)",
        ],
        correctAnswers: ["В прямоугольной системе координат"],
      },
      {
        code: "20602113",
        text: "При каких условиях допускается проведение прострелочных или взрывных работ в скважинах?",
        answers: [
          "Во время пурги",
          "В сухих газирующих и поглощающих раствор скважинах без применения лубрикаторов",
          "Во время туманов (при видимости более 50 м) при выполнении работ в закрытых помещениях буровых",
          "Во время грозы",
        ],
        correctAnswers: [
          "Во время туманов (при видимости более 50 м) при выполнении работ в закрытых помещениях буровых",
        ],
      },
      {
        code: "20602114",
        text: "Кем проверяется состояние зарядных устройств не реже одного раза в месяц?",
        answers: [
          "Комиссией организации, эксплуатирующей зарядное оборудование",
          "Лицензированной подрядной организацией",
          "Работниками, эксплуатирующим зарядное оборудование",
          "Руководителем взрывных работ",
        ],
        correctAnswers: [
          "Комиссией организации, эксплуатирующей зарядное оборудование",
        ],
      },
      {
        code: "20602115",
        text: "В каких количествах разрешается размещать взрывчатые вещества непосредственно у зарядного оборудования?",
        answers: [
          "Не более пятисменной производительности оборудования",
          "Не более сменной производительности оборудования",
          "Не допускается ни в каких количествах",
          "Не более трехсменной производительности оборудования",
        ],
        correctAnswers: ["Не более сменной производительности оборудования"],
      },
      {
        code: "20602116",
        text: "Какие электродетонаторы разрешается применять в бутовых штреках с подрывкой кровли?",
        answers: [
          "Электродетонаторы замедленного действия",
          "Электродетонаторы мгновенного действия",
          "Все перечисленные электродетонаторы",
          "Электродетонаторы короткозамедленного действия",
        ],
        correctAnswers: ["Электродетонаторы мгновенного действия"],
      },
      {
        code: "20602117",
        text: "Какое из перечисленных требований запрещается при ведении взрывных работ на угольных шахтах и рудниках, опасных по газу и (или) пыли? Укажите все правильные ответы.",
        answers: [
          "Размещать в одном шпуре при сплошном заряде более одного патрона-боевика",
          "Размещать в одном шпуре взрывчатые вещества различных классов",
          "Одновременно вводить в шпур заряд, состоящий из двух патронов взрывчатого вещества и более",
          "Устанавливать шпуры при взрывании по углю и породе на глубине 0,6 м",
        ],
        correctAnswers: [
          "Размещать в одном шпуре при сплошном заряде более одного патрона-боевика",
          "Размещать в одном шпуре взрывчатые вещества различных классов",
        ],
      },
      {
        code: "20602118",
        text: "Каким образом должен упаковываться заряд при температуре более 80 °C в шпуре (скважине, рукаве) при ведении взрывных работ по металлу?",
        answers: [
          "В термоизолирующую оболочку",
          "В противоударную оболочку",
          "В целлофановую оболочку",
          "В водонепроницаемую оболочку",
        ],
        correctAnswers: ["В термоизолирующую оболочку"],
      },
      {
        code: "20602119",
        text: "Какое количество зарядов разрешается одновременно заряжать и взрывать при температуре в шпуре ниже 80 °C при ведении взрывных работ по металлу?",
        answers: [
          "Не более пяти зарядов",
          "Не более одного заряда",
          "Не более шести зарядов",
          "Не более семи зарядов",
        ],
        correctAnswers: ["Не более пяти зарядов"],
      },
      {
        code: "20602120",
        text: "В течение какого срока допускается размещать зарядные машины, загруженные взрывчатыми веществами, на специально выделенной площадке на территории склада ВМ?",
        answers: [
          "На срок не более двух суток",
          "На срок не более трех суток",
          "На срок не более суток",
          "Срок определяется приказом предприятия",
        ],
        correctAnswers: ["На срок не более двух суток"],
      },
      {
        code: "20602121",
        text: "Какое из перечисленных требований к поверхностным постоянным складам указано неверно?",
        answers: [
          "Расстояния между отдельными хранилищами, между иными зданиями и сооружениями на территории склада, а также до объектов за территорией должны быть установлены техническим руководителем организации",
          "Склады должны ограждаться и иметь запретную зону шириной от ограды не менее 50 м",
          "Склады должны иметь противопожарный водоем (резервуар, скважину, насосы, гидранты)",
          "Хранилища следует располагать так, чтобы обеспечивался свободный подход и подъезд к каждому из них",
        ],
        correctAnswers: [
          "Расстояния между отдельными хранилищами, между иными зданиями и сооружениями на территории склада, а также до объектов за территорией должны быть установлены техническим руководителем организации",
        ],
      },
      {
        code: "20602122",
        text: "Какое требование относится к хранилищам, имеющим рампы и средства механизации погрузочно-разгрузочных работ?",
        answers: [
          "Устройство тамбуров не обязательно, но вход следует оборудовать не менее чем двумя двустворчатыми дверями",
          "Необходимо оборудовать не менее двух тамбуров, вход через тамбуры следует оборудовать не менее чем двумя двустворчатыми решетчатыми дверями",
          "Необходимо оборудовать не менее одного тамбура",
          "Необходимо оборудовать не менее одного тамбура, вход через тамбур следует оборудовать металлической дверью",
        ],
        correctAnswers: [
          "Устройство тамбуров не обязательно, но вход следует оборудовать не менее чем двумя двустворчатыми дверями",
        ],
      },
      {
        code: "20602123",
        text: "В каком случае допускается использовать для освещения в углубленном складе индивидуальные рудничные аккумуляторные светильники при отсутствии стационарных источников электроэнергии?",
        answers: [
          "Если посты охраны располагаются как у входа в склад, так и у устья вентиляционных выработок и у запасного выхода",
          "Не допускается ни в каком случае",
          "По разрешению руководителя (технического руководителя) организации",
          "При толщине покрывающего слоя более 10 м",
        ],
        correctAnswers: [
          "По разрешению руководителя (технического руководителя) организации",
        ],
      },
      {
        code: "20602124",
        text: "Что из перечисленного допускается в процессе пневмотранспортирования или пневмозаряжания?",
        answers: [
          "Расстилать брезент на месте заряжания под восходящими скважинами",
          "Оставлять просыпь взрывчатых веществ на почве выработок",
          "Находиться в створе с заряжаемой скважиной (шпуром)",
          "Приступать к заряжанию в тупиковых выработках при отсутствии проветривания",
        ],
        correctAnswers: [
          "Расстилать брезент на месте заряжания под восходящими скважинами",
        ],
      },
      {
        code: "20602125",
        text: "Через какое время разрешается подход взрывника к месту взрыва, если взрыва не произошло, при взрывании электронными детонаторами, электродетонаторами и капсюлями-детонаторами?",
        answers: [
          "Не ранее чем через 15 минут",
          "Не ранее чем через 7 минут",
          "Не ранее чем через 10 минут",
          "По распоряжению руководителя взрывных работ",
        ],
        correctAnswers: ["Не ранее чем через 15 минут"],
      },
      {
        code: "20602126",
        text: "В каком случае должно проводиться сотрясательное взрывание при отработке пластов, опасных по внезапным выбросам угля, породы и газа? Укажите все правильные ответы.",
        answers: [
          "При вскрытии угрожаемых угольных пластов, если прогнозом установлены опасные значения показателей выбросоопасности или прогноз перед вскрытием не проводился",
          "При проведении восстающих выработок с углом наклона 15°",
          "При вскрытии песчаников на глубине 300 м, если прогноз выбросоопасности перед вскрытием не осуществлялся",
          "При вскрытии выбросоопасных угольных пластов мощностью более 0,1 м",
        ],
        correctAnswers: [
          "При вскрытии угрожаемых угольных пластов, если прогнозом установлены опасные значения показателей выбросоопасности или прогноз перед вскрытием не проводился",
          "При вскрытии выбросоопасных угольных пластов мощностью более 0,1 м",
        ],
      },
      {
        code: "20602127",
        text: "При какой минимальной концентрации метана руководитель сотрясательного взрывания в забое, замеряющий содержание метана, при продвижении к забою для осмотра его после сотрясательного взрывания, обязан немедленно возвратиться в выработку со свежей струей воздуха?",
        answers: ["2 %", "4 %", "7 %", "9 %"],
        correctAnswers: ["2 %"],
      },
      {
        code: "20602128",
        text: "Что из перечисленного целесообразно отражать в инструкции по ликвидации отказавших зарядов взрывчатых веществ при строительстве гидротехнических сооружений?",
        answers: [
          "Основные мероприятия по предупреждению отказавших зарядов, порядок обнаружения невзорвавшихся зарядов, методы ликвидации отказов для каждого вида взрывных работ",
          "Величину радиуса опасной зоны при ликвидации отказа, порядок ее обозначения на местности и в подземных выработках, а также ее охране, организацию работ по ликвидации отказов",
          "Порядок сбора, учета и уничтожения остатков взрывчатых материалов, извлеченных при ликвидации отказа, мероприятия по безопасности работ",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
    ],
    20603: [
      {
        code: "20603000",
        text: "Выберите правильный ответ из предложенных вариантов, в соответствии с которым сбой в работе горнорудного предприятия должен быть отнесен к инциденту.",
        answers: [
          "Разрушение сооружений (технических устройств), приведшее к приостановке работ объекта (участка) на срок более суток",
          "Разрушение крепи и армировки, обрушения горной массы в главных откаточных и вентиляционных выработках, приведшее к приостановке работ на срок более суток",
          "Локальные возгорания, не приведшие к выводу людей из горных выработок на поверхность, вызвавшие приостановку работ на срок менее суток",
          "Подвижки бортов карьеров, вызванные техногенными факторами и нарушениями принятой технологии работ и приведшие к приостановке работ на срок более суток",
        ],
        correctAnswers: [
          "Локальные возгорания, не приведшие к выводу людей из горных выработок на поверхность, вызвавшие приостановку работ на срок менее суток",
        ],
      },
      {
        code: "20603001",
        text: "К какой категории следует относить происшествие, если оно содержит признаки аварии и инцидента?",
        answers: [
          "К категории инцидента",
          "К категории аварии",
          "Решение о присвоении категории принимает технический руководитель организации",
          "Решение о присвоении категории принимает территориальный орган Ростехнадзора",
        ],
        correctAnswers: ["К категории аварии"],
      },
      {
        code: "20603002",
        text: "Какие виды происшествий относятся к категории аварий?",
        answers: [
          "Потопление драг, земснарядов, плавучих насосных станций",
          "Замораживание отдельных участков пульповодов и водоводов",
          "Пожары и возгорания в подземных горных выработках, не приведшие к выводу людей на поверхность либо к случаям травмирования",
          "Разрушение узлов и деталей участковых вентиляторных установок, центральных водоотливов, компрессорных установок, приведшее к их остановке",
          "Забивание посторонними предметами (заилование) водосбросных сооружений хвостохранилищ и шламохранилищ, приведшее к приостановке производственного процесса",
        ],
        correctAnswers: [
          "Потопление драг, земснарядов, плавучих насосных станций",
        ],
      },
      {
        code: "20603003",
        text: "Какие виды происшествий относятся к категории инцидентов?",
        answers: [
          "Разрушение узлов и деталей, приведшее к остановке работы грузовых, грузолюдских подъемных установок на срок более суток либо к случаям травмирования",
          "Разрушение узлов и деталей участковых вентиляторных установок, центральных водоотливов, компрессорных установок, приведшее к их остановке",
          "Затопление заглубленных пульпонасосных станций и насосных отделений обогатительных фабрик",
          "Пожары в надшахтных зданиях и производственных сооружениях в пределах горного (земельного) отвода",
          "Падение в стволы и вертикальные выработки технологического оборудования, механизмов, материалов",
        ],
        correctAnswers: [
          "Разрушение узлов и деталей участковых вентиляторных установок, центральных водоотливов, компрессорных установок, приведшее к их остановке",
        ],
      },
      {
        code: "20603004",
        text: "Кем оформляется документация, удостоверяющая уточненные границы горного отвода, если участки недр местного значения расположены на территориях 2 и более субъектов Российской Федерации и если нормативными правовыми актами субъекта Российской Федерации не определен орган исполнительной власти субъекта Российской Федерации?",
        answers: [
          "Органом государственного горного надзора",
          "Органами исполнительной власти каждого из субъектов Российской Федерации",
          "Органом исполнительной власти любого из причастных к горному отводу субъекта Российской Федерации",
        ],
        correctAnswers: ["Органом государственного горного надзора"],
      },
      {
        code: "20603005",
        text: "Каким образом должно осуществляться передвижение людей по территории объектов ведения горных работ и переработки полезных ископаемых?",
        answers: [
          "Передвижение людей по территории объекта открытых горных работ допускается по специально устроенным пешеходным дорожкам или по обочинам автодорог навстречу направлению движения автотранспорта",
          "Пути передвижения по территории объекта ведения открытых горных работ выбирают самостоятельно работающие на объекте, убеждаясь в отсутствии движущегося автотранспорта",
          "Передвижение людей по территории объекта ведения открытых горных работ допускается по маршрутам, обозначенным специальными указательными знаками",
          "Передвижение людей по территории объекта ведения открытых горных работ допускается в соответствии с указаниями ответственного за передвижение по территории объекта",
        ],
        correctAnswers: [
          "Передвижение людей по территории объекта открытых горных работ допускается по специально устроенным пешеходным дорожкам или по обочинам автодорог навстречу направлению движения автотранспорта",
        ],
      },
      {
        code: "20603006",
        text: "Какие мероприятия должны осуществляться при одновременной разработке месторождений открытым и подземным способами по обеспечению безопасности работающих одновременно на открытых и подземных горных работах?",
        answers: [
          "Совместные мероприятия, включая: согласование планов и графиков горных и взрывных работ; применение нагнетательной схемы проветривания подземных объектов; предотвращение опасности прорыва воды в подземные горные выработки из объекта открытых горных работ",
          "Совместные мероприятия, включая только: применение нагнетательной схемы проветривания подземных объектов; предотвращение опасности прорыва воды в подземные выработки из объекта ведения открытых горных работ",
          "Совместные мероприятия, включая только: согласование планов и графиков горных и взрывных работ; применение нагнетательной схемы проветривания подземных объектов",
        ],
        correctAnswers: [
          "Совместные мероприятия, включая: согласование планов и графиков горных и взрывных работ; применение нагнетательной схемы проветривания подземных объектов; предотвращение опасности прорыва воды в подземные горные выработки из объекта открытых горных работ",
        ],
      },
      {
        code: "20603007",
        text: "С какой периодичностью должны обновляться (пополняться) сводные планы горных работ, планы поверхности, в случаях если ситуация местности (объектовый состав) в границах горного отвода и (или) система наблюдений за состоянием горного отвода и расположенных в его границах горных выработок (скважин), зданий, сооружений и иных объектов не претерпели изменений?",
        answers: [
          "Не реже одного раза в 7 лет",
          "Не реже одного раза в 5 лет",
          "Не реже одного раза в 10 лет",
          "Не реже одного раза в 6 лет",
        ],
        correctAnswers: ["Не реже одного раза в 5 лет"],
      },
      {
        code: "20603008",
        text: "В какой раздел плана (схемы) развития горных работ включаются сведения о соблюдении порядка ликвидации (консервации) горных выработок (скважин), участков, блоков, горизонтов на отработанных частях месторождений?",
        answers: [
          "План поверхности",
          "Погоризонтный маркшейдерский план горных работ",
          "Пояснительная записка планов (схем)",
          "Сводный маркшейдерский план горных работ",
        ],
        correctAnswers: ["Пояснительная записка планов (схем)"],
      },
      {
        code: "20603009",
        text: "Какие мероприятия должны включаться в пояснительную записку планов развития горных работ?",
        answers: [
          "Только организация наблюдений за состоянием горных отводов",
          "Только достоверный учет количества добываемого минерального сырья в общем объеме горной массы, в том числе при маркшейдерском, весовом, метрологическом контроле (при разработке месторождений твердых полезных ископаемых)",
          "Только консервация и (или) ликвидация горных выработок, в том числе скважин, рекультивация нарушенных горными работами земель (при разработке месторождений твердых полезных ископаемых при наличии)",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20603010",
        text: "Что должно входить в состав планов и схем развития горных работ?",
        answers: [
          "Геодезическая съемка мест производства горных работ",
          "Морфометрический анализ земной поверхности",
          "Список работников с указанием паспортных данных и медицинского заключения",
          "Графическая часть и пояснительная записка с табличными материалами",
        ],
        correctAnswers: [
          "Графическая часть и пояснительная записка с табличными материалами",
        ],
      },
      {
        code: "20603011",
        text: "В каком виде подготавливаются схемы развития горных работ в отношении вскрышных, подготовительных, рекультивационных работ, а также работ по добыче полезных ископаемых и связанной с ней первичной переработкой минерального сырья, содержащие графическую часть и пояснительную записку с табличными материалами?",
        answers: [
          "Только в виде схем эксплуатации объектов обустройства и схемы подготовки углеводородов (для месторождений углеводородного сырья)",
          "Только в виде схем эксплуатации объектов и водозаборных сооружений в отношении месторождений минеральных, теплоэнергетических (термальных), технических, промышленных вод",
          "Только в виде схем эксплуатации объектов недропользования и (или) их отдельных участков",
          "Во всех перечисленных",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "20603012",
        text: "Что из перечисленного должны обеспечивать мероприятия по выполнению основных требований по безопасному ведению работ, связанных с пользованием недрами?",
        answers: [
          "Только определение опасных зон, охранных зон и их границ, порядка ведения работ вблизи таких зон и в этих зонах",
          "Только восполнение количества вскрытых, подготовленных и готовых к выемке запасов полезных ископаемых и их соответствие планируемому объему добычи полезных ископаемых (при разработке месторождений твердых полезных ископаемых)",
          "Только соблюдение требований по безопасному ведению горных и взрывных работ, включая меры по охране населения, зданий, сооружений и природных объектов, расположенных на земной поверхности в зоне вредного влияния горных разработок, подземных горных выработок, объектов и сооружений",
          "Все перечисленное, включая ликвидацию (консервацию) отработанных горных выработок, скважин, блоков, горизонтов, объектов обустройства и иных объектов, связанных с горным производством",
        ],
        correctAnswers: [
          "Все перечисленное, включая ликвидацию (консервацию) отработанных горных выработок, скважин, блоков, горизонтов, объектов обустройства и иных объектов, связанных с горным производством",
        ],
      },
      {
        code: "20603013",
        text: "Что устанавливается в лицензиях на пользование недрами, технических проектах и иной проектной документации на выполнение работ, связанных с пользованием участками недр?",
        answers: [
          "Только соблюдение требований по технологии ведения работ",
          "Только дополнительные мероприятия, обеспечивающие безопасное пользование недрами",
          "Только дополнительные мероприятия по прогнозированию и предупреждению опасных ситуаций с соответствующей корректировкой технических и технологических решений",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20603014",
        text: "Из чего должны состоять графические материалы планов (схем) развития горных работ в зависимости от видов горных работ и видов полезных ископаемых?",
        answers: [
          "Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем",
          "Только из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия",
          "Только из схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ)",
          "Только из геологических и структурных карт, характерных разрезов, проекций, схем",
        ],
        correctAnswers: [
          "Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем",
        ],
      },
      {
        code: "20603015",
        text: "Из каких документов должны состоять графические материалы планов (схем) развития горных работ в зависимости от видов горных работ и видов полезных ископаемых?",
        answers: [
          "Только из схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ)",
          "Только из геологических и структурных карт, характерных разрезов, проекций, схем",
          "Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем",
          "Только из погоризонтных маркшейдерских планов горных выработок (горных работ)",
        ],
        correctAnswers: [
          "Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем",
        ],
      },
      {
        code: "20603016",
        text: "Какие данные не должны указываться в штампе графических материалов планов развития горных работ?",
        answers: [
          "Наименования организации, объекта недропользования (участка недр)",
          "Дата составления, масштаб",
          "Подписи лиц (с указанием должности), ответственных за руководство геологическими и маркшейдерскими работами",
          "Все ответы неверны",
          "Название графического документа",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20603017",
        text: "Что не отображается на планах поверхности при составлении планов и схем развития горных работ?",
        answers: [
          "Объекты наземных и подземных коммуникаций (при наличии)",
          "Места образования провалов, оползней, прорыва плывунов",
          "Объекты застройки площади залегания полезных ископаемых (горного отвода) с выделением зданий и сооружений, подлежащих охране от вредного влияния горных работ",
          "Абсолютные отметки устьев стволов и скважин, околоствольных дворов (руддворов) и горизонтов",
          "Устья выходящих на поверхность действующих, законсервированных и ликвидированных горных выработок",
          "Отображается все перечисленное",
        ],
        correctAnswers: [
          "Абсолютные отметки устьев стволов и скважин, околоствольных дворов (руддворов) и горизонтов",
        ],
      },
      {
        code: "20603018",
        text: "Что должны включать табличные материалы пояснительной записки планов (схем) по видам горных работ и видам полезных ископаемых?",
        answers: [
          "Только сведения о состоянии и движении фонда скважин (при разработке месторождений углеводородного сырья)",
          "Только основные технические показатели на предстоящий период (план) и ожидаемые на конец текущего года (факт)",
          "Только сведения о состоянии и движении вскрытых, подготовленных и готовых к выемке запасов полезных ископаемых по состоянию на начало планируемого периода и ожидаемого на конец этого периода (при добыче твердых полезных ископаемых)",
          "Только сведения об объемах проведения горных разведочных выработок, в том числе скважин эксплуатационной разведки",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20603019",
        text: "Кто из перечисленных лиц подписывает титульный лист плана (схемы) развития горных работ?",
        answers: [
          "Лица, ответственные за руководство горными, геологическими и маркшейдерскими работами пользователя недр и (или) привлекаемые пользователем недр на договорной основе организации для осуществления работ, связанных с пользованием недрами",
          "Лица, ответственные за руководство геологическими работами, и представитель Ростехнадзора",
          "Только лица, ответственные за руководство маркшейдерскими работами",
          "Только лица, ответственные за руководство горными работами пользователя недр",
        ],
        correctAnswers: [
          "Лица, ответственные за руководство горными, геологическими и маркшейдерскими работами пользователя недр и (или) привлекаемые пользователем недр на договорной основе организации для осуществления работ, связанных с пользованием недрами",
        ],
      },
      {
        code: "20603020",
        text: "Чьи подписи ставятся на титульном листе пояснительной записки планов (схем) развития горных работ?",
        answers: [
          "Только лица, ответственного за руководство горными работами",
          "Только лица, ответственного за руководство маркшейдерскими работами",
          "Только пользователя недр и (или) привлекаемой пользователем недр на договорной основе организации для осуществления работ, связанных с пользованием недрами",
          "Только лица, ответственного за руководство геологическими работами",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20603021",
        text: "Кем должны быть подписаны планы (схемы) развития горных работ, направляемые пользователем недр в электронном виде?",
        answers: [
          "Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом",
          "Техническим руководителем пользователя недр (подрядной организации)",
          "Комиссией пользователя недр (подрядной организации)",
        ],
        correctAnswers: [
          "Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом",
        ],
      },
      {
        code: "20603022",
        text: "В какой период осуществляется рассмотрение планов развития горных работ?",
        answers: [
          "В период с 15 сентября по 15 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
          "В период с 15 октября по 30 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
          "В период с 20 сентября по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
          "В период с 20 августа по 20 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
        ],
        correctAnswers: [
          "В период с 20 сентября по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
        ],
      },
      {
        code: "20603023",
        text: "По каким видам полезных ископаемых подготавливаются планы и схемы развития горных работ?",
        answers: [
          "Только по твердым полезным ископаемым",
          "Только по углеводородному сырью",
          "Только по общераспространенным полезным ископаемым",
          "Только по промышленным подземным водам",
          "По всем перечисленным видам полезных ископаемых",
        ],
        correctAnswers: ["По всем перечисленным видам полезных ископаемых"],
      },
      {
        code: "20603024",
        text: "Что определяется планами и схемами развития горных работ?",
        answers: [
          "Направления развития горных работ, условия безопасного недропользования, технические и технологические решения при эксплуатации объектов ведения горных работ, объемы добычи и первичной переработки полезных ископаемых",
          "Только направления развития горных работ, технические и технологические решения при эксплуатации объектов ведения горных работ",
          "Только условия безопасного недропользования, объемы добычи и первичной переработки полезных ископаемых",
        ],
        correctAnswers: [
          "Направления развития горных работ, условия безопасного недропользования, технические и технологические решения при эксплуатации объектов ведения горных работ, объемы добычи и первичной переработки полезных ископаемых",
        ],
      },
      {
        code: "20603025",
        text: "На какие виды работ составляются планы и схемы развития горных работ?",
        answers: [
          "Только на подготовительные работы",
          "Только на геологические работы",
          "Только на маркшейдерские работы",
          "Только на работы по добыче полезных ископаемых и первичной переработке минерального сырья",
          "Только на рекультивационные работы",
          "На все перечисленные виды работ",
        ],
        correctAnswers: ["На все перечисленные виды работ"],
      },
      {
        code: "20603026",
        text: "На какие перечисленные виды работ не составляются планы и схемы горных работ?",
        answers: [
          "На работы по добыче полезных ископаемых",
          "На работы, связанные со вторичной переработкой минерального сырья",
          "На рекультивационные работы",
          "На подготовительные работы",
        ],
        correctAnswers: [
          "На работы, связанные со вторичной переработкой минерального сырья",
        ],
      },
      {
        code: "20603027",
        text: "На какой срок составляется план развития горных работ по всем планируемым видам горных работ?",
        answers: ["На 1 год", "На 2 года", "На 3 года", "На 5 лет"],
        correctAnswers: ["На 1 год"],
      },
      {
        code: "20603028",
        text: "На какой максимальный срок составляется схема развития горных работ?",
        answers: ["На 6 лет", "На 2 года", "На 1 год", "На 5 лет"],
        correctAnswers: ["На 5 лет"],
      },
      {
        code: "20603029",
        text: "На какой срок по решению пользователя недр составляется схема развития горных работ по одному или нескольким видам горных работ, предусмотренным планами и схемами развития горных работ?",
        answers: [
          "Не более 8 лет",
          "Не более 7 лет",
          "Не более 6 лет",
          "Не более 5 лет",
        ],
        correctAnswers: ["Не более 5 лет"],
      },
      {
        code: "20603030",
        text: "На основе чего составляются планы и схемы развития горных работ?",
        answers: [
          "На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами",
          "На основе технического регламента",
          "На основе статистических данных по запасам полезных ископаемых Минприроды России",
          "На основе локального плана проведения горных работ",
        ],
        correctAnswers: [
          "На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами",
        ],
      },
      {
        code: "20603031",
        text: "С кем необходимо согласовывать планы и схемы развития горных работ?",
        answers: [
          "С территориальными органами Ростехнадзора",
          "С территориальными органами управления природных ресурсов и охраны окружающей среды",
          "Со специализированными профессиональными аварийно-спасательными формированиями",
          "С органами исполнительной власти субъекта Российской Федерации, на территории которого эксплуатируется разрез",
        ],
        correctAnswers: ["С территориальными органами Ростехнадзора"],
      },
      {
        code: "20603032",
        text: "В какой срок органом исполнительной власти субъекта Российской Федерации оформленная документация, удостоверяющая уточненные границы горного отвода, передается пользователю недр?",
        answers: [
          "Не позднее 5 дней со дня оформления",
          "Не позднее 10 дней со дня оформления",
          "Не позднее 14 дней со дня оформления",
          "Не позднее 20 дней со дня оформления",
        ],
        correctAnswers: ["Не позднее 5 дней со дня оформления"],
      },
      {
        code: "20603033",
        text: "Сколько экземпляров документации, удостоверяющей уточненные границы горного отвода, оформляется органом исполнительной власти субъекта Российской Федерации?",
        answers: [
          "2 экземпляра",
          "3 экземпляра",
          "4 экземпляра",
          "5 экземпляров",
        ],
        correctAnswers: ["3 экземпляра"],
      },
      {
        code: "20603034",
        text: "Что должны отображать графические материалы?",
        answers: [
          "Только направления развития работ, связанных с пользованием недрами, в планируемом периоде (для месторождений твердых полезных ископаемых)",
          "Только контуры отвалов, складов добытых полезных ископаемых, объектов хранения отходов (хвостов) горных и перерабатывающих производств (при наличии)",
          "Только границы опасных зон и зон с особыми условиями использования территорий (при разработке месторождений твердых полезных ископаемых при наличии)",
          "Только уточненные границы горного отвода",
          "Только контуры запасов полезных ископаемых, числящихся на учете пользователя недр в государственном балансе запасов полезных ископаемых по состоянию на 1 января текущего года",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20603035",
        text: "В каком масштабе должны составляться графические материалы для твердых полезных ископаемых?",
        answers: [
          "Не мельче 1 : 20 000",
          "Не мельче 1 : 15 000",
          "Не мельче 1 : 25 000",
          "Не мельче 1 : 10 000",
          "Не мельче 1 : 30 000",
        ],
        correctAnswers: ["Не мельче 1 : 10 000"],
      },
      {
        code: "20603036",
        text: "Какие параметры эксплуатации объектов ведения горных работ планами и схемами развития горных работ не определяются?",
        answers: [
          "Условия безопасного недропользования",
          "Технические и технологические решения при эксплуатации объектов ведения горных работ",
          "Объемы добычи и первичной переработки полезных ископаемых",
          "Штатная численность сотрудников объекта ведения горных работ",
        ],
        correctAnswers: [
          "Штатная численность сотрудников объекта ведения горных работ",
        ],
      },
      {
        code: "20603037",
        text: "В отношении каких видов горных работ планы и схемы развития горных работ не составляются?",
        answers: [
          "Вскрышных и подготовительных работ",
          "Работ по обустройству транспортной структуры места проведения горных работ",
          "Геологических и маркшейдерских работ",
          "Работ по добыче полезных ископаемых и работ, связанных с первичной переработкой минерального сырья",
        ],
        correctAnswers: [
          "Работ по обустройству транспортной структуры места проведения горных работ",
        ],
      },
      {
        code: "20603038",
        text: "На какой срок по решению пользователя недр составляется схема развития горных работ по одному или нескольким видам горных работ?",
        answers: [
          "Не более 7 лет",
          "Не более 6 лет",
          "Не более 5 лет",
          "Срок не ограничен",
        ],
        correctAnswers: ["Не более 5 лет"],
      },
      {
        code: "20603039",
        text: "Кем осуществляются подготовка и утверждение планов и схем развития горных работ?",
        answers: [
          "Подготовка - пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ; утверждение - руководителем организации - пользователя недр",
          "Подготовка - пользователем недр; утверждение - руководителем территориального органа Ростехнадзора",
          "Подготовка - подрядной организацией, привлекаемой пользователем недр; утверждение - руководителем территориального органа Ростехнадзора",
          "Подготовка - подрядной организацией, привлекаемой пользователем недр; утверждение - представителем местной исполнительной власти субъекта Российской Федерации",
        ],
        correctAnswers: [
          "Подготовка - пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ; утверждение - руководителем организации - пользователя недр",
        ],
      },
      {
        code: "20603040",
        text: "Каким федеральным органом исполнительной власти оформляется горноотводная документация?",
        answers: [
          "Федеральным агентством по недропользованию",
          "Федеральным агентством в сфере природопользования",
          "Территориальным органом управления природных ресурсов и охраны окружающей среды",
          "Органом государственного горного надзора и уполномоченными органами исполнительной власти субъектов Российской Федерации",
        ],
        correctAnswers: [
          "Органом государственного горного надзора и уполномоченными органами исполнительной власти субъектов Российской Федерации",
        ],
      },
      {
        code: "20603041",
        text: "В какой срок пользователи недр письменно уведомляются органом горного надзора о времени и месте рассмотрения планов и (или) схем развития горных работ?",
        answers: [
          "Не позднее чем за 12 дней до установленной даты их рассмотрения",
          "Не позднее чем за 10 дней до установленной даты их рассмотрения",
          "Не позднее чем за 15 дней до установленной даты их рассмотрения",
          "Не позднее чем за 20 дней до установленной даты их рассмотрения",
        ],
        correctAnswers: [
          "Не позднее чем за 20 дней до установленной даты их рассмотрения",
        ],
      },
      {
        code: "20603042",
        text: "В какой срок планы и (или) схемы развития горных работ направляются пользователем недр в орган государственного горного надзора для рассмотрения?",
        answers: [
          "За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
          "За 7 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
          "В течение 5 дней с назначенной даты рассмотрения планов и (или) схем развития горных работ",
          "В назначенный день рассмотрения планов и (или) схем развития горных работ",
        ],
        correctAnswers: [
          "За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
        ],
      },
      {
        code: "20603043",
        text: "Что из перечисленного не является основанием для принятия решения об отказе в согласовании плана и (или) схемы развития горных работ?",
        answers: [
          "Отсутствие обоснования соблюдения условий безопасного недропользования",
          "Отсутствие геологического и маркшейдерского обеспечения горных работ",
          "Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями",
          "Несоответствие планов и схем развития горных работ требованиям, установленным органом государственного горного надзора, к их подготовке, содержанию и оформлению",
        ],
        correctAnswers: [
          "Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями",
        ],
      },
      {
        code: "20603044",
        text: "Кем утверждаются план и схема развития горных работ?",
        answers: [
          "Техническим руководителем организации - пользователя недр",
          "Руководителем территориального органа государственного горного надзора",
          "Руководителем организации - пользователя недр",
          "Руководителем местного органа исполнительной власти субъекта Российской Федерации",
        ],
        correctAnswers: ["Руководителем организации - пользователя недр"],
      },
      {
        code: "20603045",
        text: "На какой срок оформляется документация, удостоверяющая уточненные границы горного отвода?",
        answers: [
          "На 1 год",
          "На 5 лет",
          "На срок действия лицензии на пользование недрами",
          "Не регламентируется",
        ],
        correctAnswers: ["На срок действия лицензии на пользование недрами"],
      },
      {
        code: "20603046",
        text: "Каким образом осуществляется пересылка документации, содержащей сведения, представляющие государственную тайну?",
        answers: [
          "Курьером",
          'Заказным почтовым отправлением через АО "Почта России"',
          "В соответствии с законодательством Российской Федерации о государственной тайне",
          "Любым доступным способом",
        ],
        correctAnswers: [
          "В соответствии с законодательством Российской Федерации о государственной тайне",
        ],
      },
      {
        code: "20603047",
        text: "В какой срок со дня поступления заявления и проекта горного отвода осуществляется оформление документации либо принятие мотивированного решения об отказе?",
        answers: [
          "Не позднее 28 дней",
          "Не позднее 35 дней",
          "Не позднее 25 дней",
          "Не позднее 30 дней",
        ],
        correctAnswers: ["Не позднее 25 дней"],
      },
      {
        code: "20603048",
        text: "В каком случае при необходимости внесения изменений в уточненные границы горного отвода документация не подлежит переоформлению?",
        answers: [
          "В случае изменения срока пользования участком недр, установленного в лицензии на пользование недрами",
          "В случае изменения геологической информации о недрах, наличия технологических потребностей, условий и факторов, влияющих на безопасное состояние недр, земной поверхности и расположенных на ней объектов",
          "В случае изменений технического проекта выполнения работ, связанных с пользованием недрами, не влияющих на безопасное состояние недр, земной поверхности и расположенных на ней объектов",
          "В случае выявления технических ошибок в лицензии на пользование недрами и (или) в документации",
        ],
        correctAnswers: [
          "В случае изменений технического проекта выполнения работ, связанных с пользованием недрами, не влияющих на безопасное состояние недр, земной поверхности и расположенных на ней объектов",
        ],
      },
      {
        code: "20603049",
        text: "В какой срок пользователи недр представляют заявление о согласовании планов развития горных работ в орган государственного горного надзора?",
        answers: [
          "До 25 декабря текущего года (года, предшествующего планируемому)",
          "До 25 сентября текущего года (года, предшествующего планируемому)",
          "До 1 сентября текущего года (года, предшествующего планируемому)",
          "До 1 декабря текущего года (года, предшествующего планируемому)",
        ],
        correctAnswers: [
          "До 1 сентября текущего года (года, предшествующего планируемому)",
        ],
      },
      {
        code: "20603050",
        text: "В какой срок пользователи недр представляют заявление о согласовании планов и схем развития горных работ в орган государственного горного надзора?",
        answers: [
          "До 1 декабря текущего года",
          "До 1 сентября текущего года",
          "До 20 декабря текущего года",
          "До 20 сентября текущего года",
        ],
        correctAnswers: ["До 1 сентября текущего года"],
      },
      {
        code: "20603051",
        text: 'В какой период, установленный "Правилами подготовки, рассмотрения и согласования планов и схем развития горных работ по видам полезных ископаемых", осуществляется рассмотрение органами Ростехнадзора планов и схем развития горных работ?',
        answers: [
          "С 1 октября по 30 декабря года, предшествующего планируемому",
          "С 20 сентября по 25 декабря года, предшествующего планируемому",
          "С 1 сентября по 15 декабря года, предшествующего планируемому",
          "С 20 октября по 30 декабря года, предшествующего планируемому",
        ],
        correctAnswers: [
          "С 20 сентября по 25 декабря года, предшествующего планируемому",
        ],
      },
      {
        code: "20603052",
        text: "В состав какой документации должен входить горноотводный акт и графические приложения к нему?",
        answers: [
          "Документации на техническое перевооружение, консервацию и ликвидацию",
          "Горноотводной документации",
          "Документации к обоснованию безопасности",
          "Проектной документации",
        ],
        correctAnswers: ["Горноотводной документации"],
      },
      {
        code: "20603053",
        text: "В какой системе координат должны быть определены уточненные границы горного отвода?",
        answers: [
          "В прямоугольной системе координат",
          "В географической системе координат",
          "В референцной системе координат",
        ],
        correctAnswers: ["В прямоугольной системе координат"],
      },
      {
        code: "20603054",
        text: "Что должна содержать пояснительная записка проекта горного отвода в зависимости от видов полезных ископаемых и целей пользования недрами?",
        answers: [
          "Только сведения, характеризующие географическое и административное местоположение участка недр, предоставленного в пользование",
          "Только сведения о пользовании недрами (согласно проектной документации)",
          "Только сведения о смежных участках недр, предоставленных в пользование, зонах с особыми условиями использования территорий в границах предоставленного в пользование участка недр (при наличии)",
          "Только сведения об условиях в области безопасного пользования недрами, рационального использования и охраны недр, содержащихся в неотъемлемых составных частях лицензии на пользование недрами",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20603055",
        text: "Чем необходимо руководствоваться при подготовке обоснований границ горного отвода?",
        answers: [
          "Горный отвод может состоять из нескольких отдельных блоков недр (рассредоточенный горный отвод) в случаях, когда участки ведения работ по добыче полезных ископаемых представлены отдельными участками (рудными телами, пластами) одного месторождения и (или) месторождениями полезных ископаемых (эксплуатационными объектами), находящимися в границах одного участка недр, предоставленного на основании лицензии на пользование недрами, и (или) отдельными участками ведения работ (объектами, сооружениями), связанных с пользованием недрами, предусмотренными проектной документацией и (или) для месторождений твердых полезных ископаемых по решению пользователя недр схемами развития горных работ",
          "Площадь проекции горного отвода на земную поверхность определяется в гектарах с точностью до одной сотой",
          "При использовании в качестве подземных хранилищ соляных пластов границы горного отвода устанавливаются по границам геометрического тела сферической формы, увеличенным не менее чем на 3 м по вертикали и 20 м по горизонтали относительно контура камер выщелачивания",
          "Всем перечисленным",
        ],
        correctAnswers: [
          "Горный отвод может состоять из нескольких отдельных блоков недр (рассредоточенный горный отвод) в случаях, когда участки ведения работ по добыче полезных ископаемых представлены отдельными участками (рудными телами, пластами) одного месторождения и (или) месторождениями полезных ископаемых (эксплуатационными объектами), находящимися в границах одного участка недр, предоставленного на основании лицензии на пользование недрами, и (или) отдельными участками ведения работ (объектами, сооружениями), связанных с пользованием недрами, предусмотренными проектной документацией и (или) для месторождений твердых полезных ископаемых по решению пользователя недр схемами развития горных работ",
        ],
      },
      {
        code: "20603056",
        text: "При каких условиях недропользователю устанавливаются уточненные границы горного отвода?",
        answers: [
          "В ходе ликвидации, консервации скважин и оборудования их устьев и стволов",
          "После предоставления лицензии на пользование недрами, разработки документов, подтверждающих согласование проектной документации",
          "Одновременно с получением лицензии на пользование недрами с целью разработки газовых и нефтяных месторождений",
          "В процессе разработки месторождений нефти или газа по требованию органа, выдавшего лицензию на пользование недрами",
        ],
        correctAnswers: [
          "После предоставления лицензии на пользование недрами, разработки документов, подтверждающих согласование проектной документации",
        ],
      },
      {
        code: "20603057",
        text: "Какого формата должен быть план границ горного отвода, на котором должны отображаться: контуры предварительных и уточненных границ горного отвода, угловые точки границ горного отвода?",
        answers: ["А0", "А1", "А4", "А3"],
        correctAnswers: ["А4"],
      },
      {
        code: "20603058",
        text: "В каком документе в зависимости от видов полезных ископаемых и целей пользования недрами должны отображаться контуры месторождения, совмещенный (интегральный) контур поставленных на государственный баланс запасов полезных ископаемых?",
        answers: [
          "На графических материалах проекта горного отвода",
          "В пояснительной записке проекта горного отвода",
          "В приложении к лицензии на пользование недрами",
          "На плане горного отвода",
        ],
        correctAnswers: ["На плане горного отвода"],
      },
      {
        code: "20603059",
        text: "Что указывается на плане горного отвода?",
        answers: [
          "Только линии вертикальных разрезов (профилей) и проекций",
          "Только используемые системы координат и высот, масштаб плана, координатная сетка",
          "Только площадь проекции горного отвода в гектарах на горизонтальную плоскость",
          "Только условные обозначения и поясняющие надписи, дата пополнения (актуализации) плана",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20603060",
        text: "Что должны включать графические материалы проекта горного отвода при разработке месторождений полезных ископаемых, представленных комплексом рудных тел, а также месторождений угля? Укажите все правильные ответы.",
        answers: [
          "Гипсометрические планы рудных тел и пластов",
          "Геологическое строение участка недр",
          "Погоризонтные планы рудных тел и пластов",
          "Глубины залегания продуктивных свит и горизонтов",
          "Контуры границ горного отвода",
        ],
        correctAnswers: [
          "Гипсометрические планы рудных тел и пластов",
          "Погоризонтные планы рудных тел и пластов",
        ],
      },
      {
        code: "20603061",
        text: "Кем подписывается проект горного отвода (пояснительная записка)?",
        answers: [
          "Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода",
          "Главным геологом и главным маркшейдером организации пользователя недр и (или) привлекаемого пользователем недр для их подготовки юридического лица или индивидуального предпринимателя",
          "Только руководителем организации, разработавшей проект горного отвода",
          "Главным инженером проекта разработки месторождения полезного ископаемого, руководителем организации, разработавшей проект горного отвода",
        ],
        correctAnswers: [
          "Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода",
        ],
      },
      {
        code: "20603062",
        text: "Кем подписываются проект горного отвода (пояснительная записка)?",
        answers: [
          "Главным геологом и главным маркшейдером организации, ответственным специалистом-разработчиком проекта разработки месторождения полезного ископаемого, руководителем организации, разработавшей проект горного отвода",
          "Руководителем организации, испрашивающей горный отвод, и руководителем организации, разработавшей проект горного отвода",
          "Техническим руководителем организации, испрашивающей горный отвод, главным инженером проекта разработки месторождения полезного ископаемого, руководителем организации, разработавшей проект горного отвода",
          "Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода",
        ],
        correctAnswers: [
          "Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода",
        ],
      },
      {
        code: "20603063",
        text: "Кем подписывается горноотводная документация?",
        answers: [
          "Уполномоченными должностными лицами территориального органа или структурного подразделения органа государственного горного надзора, в ведении которых находятся вопросы государственного горного надзора",
          "Главным геологом",
          "Главным маркшейдером",
          "Руководителем эксплуатирующей организации",
          "Техническим руководителем (главным инженером) эксплуатирующей организации",
        ],
        correctAnswers: [
          "Уполномоченными должностными лицами территориального органа или структурного подразделения органа государственного горного надзора, в ведении которых находятся вопросы государственного горного надзора",
        ],
      },
      {
        code: "20603064",
        text: "Что должен содержать специальный проект, по которому осуществляются горные работы при комбинированной разработке месторождений полезных ископаемых, наряду с принятыми техническими решениями?",
        answers: [
          "Только обоснование производственной (технической) возможности и технико-экономической целесообразности комбинированной разработки месторождения",
          "Только параметры карьерного и шахтного полей с запасами руды, намечаемые к отработке открытым и подземным способами",
          "Только границы зон влияния подземных разработок (опасных сдвижений, обрушения, воронкообразования)",
          "Только анализ особенностей горно-геологических, горно-технических, гидрогеологических и технологических условий комбинированной разработки месторождения",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20603065",
        text: "Что относится к комбинированной разработке месторождений?",
        answers: [
          "Одновременное ведение открытых и подземных горных работ с совмещением в пространстве по вертикали или горизонтали в пределах одного месторождения",
          "Последовательное ведение открытых и подземных горных работ с совмещением в пространстве по вертикали или горизонтали в пределах одного месторождения",
          "Ведение открытых и подземных горных работ с совмещением в пространстве по вертикали или горизонтали в пределах одного месторождения",
        ],
        correctAnswers: [
          "Ведение открытых и подземных горных работ с совмещением в пространстве по вертикали или горизонтали в пределах одного месторождения",
        ],
      },
      {
        code: "20603066",
        text: "Чем обуславливаются основные факторы, представляющие опасность при комбинированной (совмещенной) разработке месторождений?",
        answers: [
          "Только степенью взаимного влияния открытого и подземного способов разработки",
          "Только технологической взаимосвязью открытого и подземного способов разработки",
          "Степенью взаимного влияния и технологической взаимосвязи открытого и подземного способов разработки",
        ],
        correctAnswers: [
          "Степенью взаимного влияния и технологической взаимосвязи открытого и подземного способов разработки",
        ],
      },
      {
        code: "20603067",
        text: "При проектировании, строительстве и эксплуатации горнодобывающих предприятий с комбинированной (совмещенной) разработкой месторождения должен учитываться ряд основных факторов, определяющих специфические условия и представляющих опасность при ведении горных работ. Относится ли к основным факторам вид полезного ископаемого разрабатываемого месторождения?",
        answers: [
          "Относится",
          "Не относится",
          "Относится, если имеется наличие аэродинамических связей между открытыми и подземными горными работами при комбинированной разработке месторождения",
          "Не относится, если отсутствует склонность месторождения или массива горных пород или их части к горным ударам",
        ],
        correctAnswers: ["Не относится"],
      },
      {
        code: "20603068",
        text: "Какой вид контроля должен осуществляться при добыче под действующими блоками карьера за оставленным целиком безопасности, рассчитанным проектной организацией?",
        answers: [
          "Только оперативный контроль",
          "Инструментальный и метрологический контроль",
          "Маркшейдерский и оперативный контроль в соответствии с Инструкцией по производству маркшейдерских работ",
          "Геотехнический контроль и визуальный осмотр в рамках производственного контроля",
        ],
        correctAnswers: [
          "Маркшейдерский и оперативный контроль в соответствии с Инструкцией по производству маркшейдерских работ",
        ],
      },
      {
        code: "20603069",
        text: "Какое из перечисленных требований не допускается при комбинированной разработке месторождений?",
        answers: [
          "Фронт ведения горных работ располагать в направлении от массива к карьеру при подземных очистных работах",
          "Фронт ведения горных работ располагать навстречу фронту развития подземных очистных работ при открытых работах",
          "Добыча руды подземным способом из проектных контуров карьера",
          "Все перечисленные требования",
        ],
        correctAnswers: [
          "Добыча руды подземным способом из проектных контуров карьера",
        ],
      },
      {
        code: "20603070",
        text: "Что запрещено при комбинированной разработке месторождений?",
        answers: [
          "Несвоевременная отработка и разрушение рудных целиков и потолочин в руднике от массовых взрывов",
          "Применение систем разработки, исключающих сдвижение (разрушение) массива предохранительного целика",
          "Исключение прорыва ливневых и подземных вод из карьера в подземные выработки",
          "Оставление предохранительного целика, обеспечивающего устойчивость массива и бортов карьера",
        ],
        correctAnswers: [
          "Несвоевременная отработка и разрушение рудных целиков и потолочин в руднике от массовых взрывов",
        ],
      },
      {
        code: "20603071",
        text: "Какое из перечисленных требований к рудоспускам указано неверно?",
        answers: [
          "Каждый рудоспуск необходимо обустроить бетонными предохранительными барьерами высотой не менее 0,33 и шириной не менее 0,5 диаметра колеса автосамосвала",
          "При перепуске руд глинистых, обводненных, склонных к слеживанию и самовозгоранию допускается аккумулирование руды в рудоспуске",
          "При выполнении работ по переносу устья рудоспуска из карьера и ведении ремонтных работ число капитальных действующих рудоспусков с карьера в подземные выработки должно быть не менее 2",
          "При отработке верхней части рудоспусков они должны быть заполнены до подошвы уступа",
        ],
        correctAnswers: [
          "При перепуске руд глинистых, обводненных, склонных к слеживанию и самовозгоранию допускается аккумулирование руды в рудоспуске",
        ],
      },
      {
        code: "20603072",
        text: "Какие из перечисленных факторов должны учитываться при проектировании, строительстве и эксплуатации горнорудных объектов с комбинированной (совмещенной) разработкой?",
        answers: [
          "Только воздействие массовых взрывов на карьере на состояние подземных горных выработок",
          "Только исключение прорывов в подземные горные выработки паводковых и ливневых вод",
          "Только наличие аэродинамических связей между карьером и подземным рудником",
          "При ведении горных работ должны учитываться все перечисленные факторы",
        ],
        correctAnswers: [
          "При ведении горных работ должны учитываться все перечисленные факторы",
        ],
      },
      {
        code: "20603073",
        text: "Какие горные выработки, границы открытого очистного пространства на подземных работах, отработанные камеры, границы поверхностного контура зоны обрушения должны быть нанесены на маркшейдерские планы и разрезы карьера?",
        answers: [
          "Только основные горные выработки, границы открытого очистного пространства на подземных работах, отработанные камеры, границы поверхностного контура зоны обрушения",
          "Перечень подземных объектов, которые должны быть нанесены на маркшейдерские планы и разрезы карьера, устанавливается организацией, ведущей комбинированную разработку месторождения по согласованию с органами Ростехнадзора",
          "Перечень подземных объектов, которые должны быть нанесены на маркшейдерские планы и разрезы карьера, устанавливается главным маркшейдером по согласованию с главным инженером организации, ведущей комбинированную разработку месторождения",
          "Должны быть нанесены все без исключения",
        ],
        correctAnswers: ["Должны быть нанесены все без исключения"],
      },
      {
        code: "20603074",
        text: "Какие сведения должны быть нанесены на маркшейдерские планы и разрезы?",
        answers: [
          "Только все горные выработки, границы открытого очистного пространства на подземных работах, отработанные камеры, границы поверхностного контура зоны обрушения, разведочные и контрольные наблюдательные скважины и реперы",
          "Только зоны обводненных горизонтов, положение зумпфов водоотливных установок с указанием отметок дна зумпфа, водоводов, изоляционных перемычек в дренажных подземных выработках, предотвращающих проникновение ядовитых газов из карьера в подземные выработки",
          "Все перечисленные сведения, а также при планировании развития горных работ в карьере - границы погашения дренажных выработок на конец планируемого периода",
        ],
        correctAnswers: [
          "Все перечисленные сведения, а также при планировании развития горных работ в карьере - границы погашения дренажных выработок на конец планируемого периода",
        ],
      },
      {
        code: "20603075",
        text: "В каком случае допускается работа горно-транспортного оборудования в зоне обрушения?",
        answers: [
          "Запрещается",
          "Допускается только после положительного заключения геолого-маркшейдерской службы предприятия",
          "Допускается только после усадки породы, опробования подошвы уступа путем контрольного бурения",
          "Допускается после усадки породы, опробования подошвы уступа путем контрольного бурения и положительного заключения геолого-маркшейдерской службы предприятия",
        ],
        correctAnswers: [
          "Допускается после усадки породы, опробования подошвы уступа путем контрольного бурения и положительного заключения геолого-маркшейдерской службы предприятия",
        ],
      },
      {
        code: "20603076",
        text: "Кем утверждается паспорт буровзрывных работ для каждого рабочего уступа - выемочного блока на основе маркшейдерской документации?",
        answers: [
          "Техническим руководителем карьера",
          "Руководителем территориального органа Ростехнадзора",
          "Главным маркшейдером",
          "Руководителем организации",
        ],
        correctAnswers: ["Техническим руководителем карьера"],
      },
      {
        code: "20603077",
        text: "На основании какого документа могут быть возобновлены открытые горные работы после их прекращения при обнаружении признаков сдвижения пород?",
        answers: [
          "Специального проекта организации работ, утвержденного руководителем предприятия",
          "Технического регламента",
          "Локального проекта организации работ, утвержденного командиром специализированного профессионального аварийно-спасательного формирования",
          "Распоряжения территориального органа государственного горного надзора",
        ],
        correctAnswers: [
          "Специального проекта организации работ, утвержденного руководителем предприятия",
        ],
      },
      {
        code: "20603078",
        text: "Какими преимущественно системами разработки при совместной разработке месторождения следует производить подземные горные работы?",
        answers: [
          "Любыми системами разработки",
          "Только системами разработки с закладкой выработанного пространства",
          "Преимущественно системами с поддержанием выработанного пространства",
          "В каждом конкретном случае возможность применения той или иной системы разработки устанавливается проектной организацией по согласованию с Ростехнадзором",
        ],
        correctAnswers: [
          "Преимущественно системами с поддержанием выработанного пространства",
        ],
      },
      {
        code: "20603079",
        text: "Какое допускается максимальное отклонение от нулевого кислородного баланса при производстве массовых взрывов при комбинированной (совмещенной) разработке рудных и нерудных месторождений полезных ископаемых?",
        answers: ["20%", "25%", "30%", "35%"],
        correctAnswers: ["25%"],
      },
      {
        code: "20603080",
        text: "По прошествии какого времени после взрыва может осуществляться допуск постов профессиональной аварийно-спасательной службы (формирования) в пределы опасной зоны?",
        answers: ["5 минут", "12 минут", "10 минут", "15 минут"],
        correctAnswers: ["15 минут"],
      },
      {
        code: "20603081",
        text: "По прошествии какого времени после массового взрыва по разрушению целиков разрешается спуск в шахту представителей профессиональной аварийно-спасательной службы (формирования)?",
        answers: [
          "Через 2 часа",
          "Через 30 минут",
          "Через час",
          "Через 1,5 часа",
        ],
        correctAnswers: ["Через 2 часа"],
      },
      {
        code: "20603082",
        text: "С учетом каких условий проектом должны определяться схемы и способы проветривания рудников (шахт) при наличии аэродинамических связей подземных горных выработок с карьерным пространством?",
        answers: [
          "С учетом образования избыточной депрессии в зоне влияния открытых горных работ",
          "С учетом обеспечения нейтральной депрессии в выработках, имеющих связь с дневной поверхностью",
          "С учетом применения комбинированного (нагнетательно-всасывающего) способа проветривания",
          "С учетом возникающей естественной тяги",
        ],
        correctAnswers: [
          "С учетом образования избыточной депрессии в зоне влияния открытых горных работ",
        ],
      },
      {
        code: "20603083",
        text: "В каком случае нарушены требования к системам вентиляции при комбинированной (совмещенной) разработке месторождения?",
        answers: [
          "Вентиляция горных работ при вскрытии и отработке запасов руд, находящихся в бортах и под дном карьера, может быть принята только локальной через вскрывающие выработки из карьера",
          "Вспомогательные нагнетательные вентиляторы должны быть максимально приближены к зонам аэродинамических связей с обеспечением подпора воздуха в подземных выработках",
          "Способы проветривания рудников (шахт) определяются проектом с учетом образования избыточной депрессии в зоне влияния открытых работ",
          "При комбинированной (совмещенной) разработке месторождения и наличии аэродинамических связей подземных выработок с карьерным пространством предусматривается нагнетательный способ общерудничной системы проветривания",
        ],
        correctAnswers: [
          "Вентиляция горных работ при вскрытии и отработке запасов руд, находящихся в бортах и под дном карьера, может быть принята только локальной через вскрывающие выработки из карьера",
        ],
      },
      {
        code: "20603084",
        text: "Кем должны определяться границы опасных зон по прорывам воды и газов при комбинированной (совмещенной) разработке с неблагоприятными гидрогеологическими условиями, наличием затопленных горных выработок или других водных объектов, а также горных выработок, в которых возможны скопления ядовитых и горючих газов?",
        answers: [
          "Только маркшейдерской службой предприятия",
          "Только геологической службой предприятия",
          "Маркшейдерской и геологической службой предприятия с привлечением представителей территориального органа Ростехнадзора",
          "Маркшейдерской и геологической службой предприятия с привлечением специализированных предприятий",
        ],
        correctAnswers: [
          "Маркшейдерской и геологической службой предприятия с привлечением специализированных предприятий",
        ],
      },
      {
        code: "20603085",
        text: "В каком случае нарушены требования к осушению и водоотливу при комбинированной (совмещенной) разработке месторождений с неблагоприятными гидрогеологическими условиями?",
        answers: [
          "Вода из карьера в подземные выработки должна поступать самотеком через дренажные скважины с обязательным применением забивных или сквозных фильтров",
          "Допускается частичная отработка предохранительных целиков без предварительного отвода воды из водоемов системами с закладкой по проекту, утвержденному руководителем предприятия и согласованному со специализированной организацией",
          "Допускается использовать действующие рудоспуски и вентиляционные восстающие в качестве дренажных выработок для перепуска грунтовых и паводковых вод из карьера",
          "Горные работы в пределах барьерного или предохранительного целика под водоемом (затопленным карьером) должны производиться только после спуска воды из затопленных выработок",
        ],
        correctAnswers: [
          "Допускается использовать действующие рудоспуски и вентиляционные восстающие в качестве дренажных выработок для перепуска грунтовых и паводковых вод из карьера",
        ],
      },
      {
        code: "20603086",
        text: "Обязаны ли организации, эксплуатирующие опасные производственные объекты, на которых ведутся горные работы и переработка полезных ископаемых, заключать договоры на обслуживание с профессиональными аварийно-спасательными формированиями (службами)?",
        answers: [
          "Обязаны, кроме случаев, установленных законодательством Российской Федерации, когда организации должны создавать собственные профессиональные аварийно-спасательные службы или профессиональные аварийно-спасательные формирования, а также нештатные аварийно-спасательные формирования из числа работников",
          "Не обязаны",
          "По усмотрению руководителя организации, эксплуатирующей опасный производственный объект, на котором ведутся горные работы и переработка полезных ископаемых",
          "Не обязаны, если на то нет предписания территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "Обязаны, кроме случаев, установленных законодательством Российской Федерации, когда организации должны создавать собственные профессиональные аварийно-спасательные службы или профессиональные аварийно-спасательные формирования, а также нештатные аварийно-спасательные формирования из числа работников",
        ],
      },
      {
        code: "20603087",
        text: "Какие из перечисленных работ на карьере должны вестись в соответствии с утвержденными техническим руководителем объекта локальными проектами (паспортами) и проектом производства работ с учетом инженерно-геологических условий?",
        answers: [
          "Только горные работы по разработке уступов и дражных полигонов",
          "Только горные работы по проведению траншей и разработке уступов",
          "Только горные работы по отсыпке отвалов и разработке уступов",
          "Горные работы по проведению траншей, разработке уступов, дражных полигонов и отсыпке отвалов",
        ],
        correctAnswers: [
          "Горные работы по проведению траншей, разработке уступов, дражных полигонов и отсыпке отвалов",
        ],
      },
      {
        code: "20603088",
        text: "Что необходимо соблюдать при погашении уступов и постановке их в предельное положение?",
        answers: [
          "Угол естественного откоса пород, слагающих уступы",
          "Угол внутреннего трения пород, слагающих уступы",
          "Общий угол откоса бортов, установленный проектом",
        ],
        correctAnswers: ["Общий угол откоса бортов, установленный проектом"],
      },
      {
        code: "20603089",
        text: 'Требуется ли маркшейдерское обеспечение буровзрывных работ при разработке месторождений полезных ископаемых в соответствии с Федеральными нормами и правилами в области промышленной безопасности "Правила безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          "Не требуется",
          "Требуется только при проведении массовых взрывов",
          "Требуется только при составлении проекта на массовый взрыв",
          "Маркшейдерское обеспечение буровзрывных работ должно осуществляться в соответствии с установленными требованиями",
        ],
        correctAnswers: [
          "Маркшейдерское обеспечение буровзрывных работ должно осуществляться в соответствии с установленными требованиями",
        ],
      },
      {
        code: "20603090",
        text: "Где запрещается размещение отвалов?",
        answers: [
          "Над отработанными подземным способом участками месторождения полезных ископаемых",
          "На косогорах",
          "На площадях месторождений, подлежащих отработке открытым способом",
        ],
        correctAnswers: [
          "На площадях месторождений, подлежащих отработке открытым способом",
        ],
      },
      {
        code: "20603091",
        text: "Кем устанавливаются размеры призмы обрушения (сползания) породы при отвалообразовании, в пределах которой не должны разгружаться автомобили и другие транспортные средства?",
        answers: [
          "Работниками проектной организацией при разработке проекта на отвал",
          "Техническим руководителем организации",
          "Работниками геологической службы",
          "Работниками маркшейдерской службы",
        ],
        correctAnswers: ["Работниками маркшейдерской службы"],
      },
      {
        code: "20603092",
        text: "Какой величины допускается высота уступа и его угол откоса при разработке соляного пласта?",
        answers: [
          "Высота не должна превышать 8 м, а угол откоса не менее 75°",
          "Высота не должна превышать 8 м, а угол откоса не менее 65°",
          "Высота не должна превышать 10 м, а угол откоса не менее 70°",
          "Высота не должна превышать 12 м, а угол откоса не менее 65°",
        ],
        correctAnswers: [
          "Высота не должна превышать 8 м, а угол откоса не менее 75°",
        ],
      },
      {
        code: "20603093",
        text: "Чем и в зависимости от чего устанавливается скорость движения поездов на железнодорожных путях объекта открытых горных работ?",
        answers: [
          "Скорость движения устанавливается специальным проектом организации в зависимости от производительности предприятия и местных условий",
          "Скорость движения устанавливается регламентом в зависимости от дальности перевозок вскрышных пород и добытого полезного ископаемого, с учетом верхнего строения пути",
          "Скорость движения устанавливается внутренней инструкцией по эксплуатации железнодорожного транспорта организации в зависимости от применяемого подвижного состава, верхнего строения и профиля пути, а также местных условий",
        ],
        correctAnswers: [
          "Скорость движения устанавливается внутренней инструкцией по эксплуатации железнодорожного транспорта организации в зависимости от применяемого подвижного состава, верхнего строения и профиля пути, а также местных условий",
        ],
      },
      {
        code: "20603094",
        text: "Какой должна быть допустимая скорость движения автомобилей, автомобильных и тракторных поездов на технологических дорогах карьера?",
        answers: [
          "Скорость устанавливается руководителем организации",
          "15 км/ч",
          "25 км/ч",
          "Скорость устанавливается техническим руководителем организации",
        ],
        correctAnswers: [
          "Скорость устанавливается техническим руководителем организации",
        ],
      },
      {
        code: "20603095",
        text: "За чем должны проводиться визуальные и инструментальные наблюдения в процессе эксплуатации объектов ведения открытых горных работ для своевременного выявления опасных зон и прогнозирования опасных ситуаций?",
        answers: [
          "За устойчивостью бортов, уступов, откосов",
          "За деформацией просадочности (для глинистых пород)",
          "За проявлениями удароопасности горных пород, вмещающих полезное ископаемое",
          "За закарстованностью месторождения",
        ],
        correctAnswers: ["За устойчивостью бортов, уступов, откосов"],
      },
      {
        code: "20603096",
        text: "Кем и с какой периодичностью должно проводиться визуальное обследование состояния откосов на карьерах?",
        answers: [
          "Главным инженером карьера не реже одного раза в полугодие",
          "Комиссией в составе главного инженера карьера и главного геолога карьера один раз в полугодие",
          "Специалистами геологической и маркшейдерской служб не реже одного раза в месяц",
          "Участковым маркшейдером или геологом ежеквартально",
        ],
        correctAnswers: [
          "Специалистами геологической и маркшейдерской служб не реже одного раза в месяц",
        ],
      },
      {
        code: "20603097",
        text: "С учетом каких факторов определяется высота уступа?",
        answers: [
          "Только с учетом результатов исследований физико-механических свойств горных пород",
          "Только с учетом параметров эксплуатируемого оборудования",
          "Только с учетом горно-геологических условий залегания горных пород",
          "С учетом всех перечисленных параметров",
        ],
        correctAnswers: ["С учетом всех перечисленных параметров"],
      },
      {
        code: "20603098",
        text: "Что является коэффициентом запаса устойчивости при детерминированном подходе?",
        answers: [
          "Отношение удерживающих и сдвигающих сил на сформировавшейся поверхности скольжения",
          "Допустимая вероятность развития деформаций, которые определяются проектной документацией с учетом размещения элементов инфраструктуры объекта недропользования",
          "Действие удерживающих и сдвигающих сил во всех инженерных методах переносится на потенциальную (наиболее напряженную) поверхность скольжения",
        ],
        correctAnswers: [
          "Отношение удерживающих и сдвигающих сил на сформировавшейся поверхности скольжения",
        ],
      },
      {
        code: "20603099",
        text: "Что должно быть изучено в результате инженерно-геологических и гидрогеологических исследований?",
        answers: [
          "Только физические свойства складируемых пород, прочность их в куске",
          "Только строение основания (наклон основания, наличие прослоев слабых пород и их мощность, наличие водоносных горизонтов, возможность образования техногенных водоносных горизонтов в нижней части отвала и в породах основания)",
          "Только сдвиговые характеристики отвальной массы",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20603100",
        text: "Какие мероприятия должна обеспечить организация, эксплуатирующая объект ведения открытых горных работ?",
        answers: [
          "Только контроль за соблюдением проектных параметров бортов и уступов карьера, разреза и откосов отвала",
          "Только изучение геологических, инженерно-геологических, гидрогеологических условий месторождения, структуры и условий залегания породных слоев, массива горных пород и пород основания отвалов",
          "Только сохранность объектов, расположенных на земной поверхности в границах горных отводов и на прилегающих к ним территориях",
          "Только выявление зон и участков возможного проявления разрушающих деформаций бортов, уступов и откосов",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20603101",
        text: "Какие визуальные и инструментальные наблюдения должны проводиться на объектах ведения открытых горных работ?",
        answers: [
          "За деформацией просадочности (для глинистых пород)",
          "За состоянием бортов, уступов и откосов",
          "За проявлениями удароопасности горных пород, вмещающих полезное ископаемое",
          "За закарстованностью месторождения",
        ],
        correctAnswers: ["За состоянием бортов, уступов и откосов"],
      },
      {
        code: "20603102",
        text: "В какой документ должны включаться мероприятия при обнаружении признаков нарушения устойчивости или критических деформаций?",
        answers: [
          "В журнал маркшейдерских указаний",
          "В схему деформирования",
          "В акт проведенных работ",
          "В планы развития горных работ на предстоящий календарный период",
        ],
        correctAnswers: [
          "В планы развития горных работ на предстоящий календарный период",
        ],
      },
      {
        code: "20603103",
        text: "На каких участках возможных деформаций выполняют закладку наблюдательных станций?",
        answers: [
          "Только на участках слабых контактов, поверхностей тектонических нарушений, имеющих наклон в сторону выработанного пространства и простирание близкое к простиранию борта",
          "Только на участках слабых пластичных, обводненных или сильно трещиноватых пород в горном массиве, формирующих борт, уступ или отвал",
          "На всех перечисленных",
        ],
        correctAnswers: ["На всех перечисленных"],
      },
      {
        code: "20603104",
        text: "Что при ведении горных работ является объектами открытых горных работ?",
        answers: [
          "Только карьеры, прииски, гидроотвалы",
          "Только объекты кучного выщелачивания",
          "Только породные, шлаковые отвалы, гидроотвалы",
          "Карьеры, прииски, дражные полигоны, объекты кучного выщелачивания, породные, шлаковые отвалы и гидроотвалы",
        ],
        correctAnswers: [
          "Карьеры, прииски, дражные полигоны, объекты кучного выщелачивания, породные, шлаковые отвалы и гидроотвалы",
        ],
      },
      {
        code: "20603105",
        text: "Какими должны быть предельные углы откосов (углы устойчивости) временно консервируемых участков борта в процессе эксплуатации?",
        answers: [
          "Предельные углы откосов (углы устойчивости) устанавливаются проектом",
          "Предельные углы откосов (углы устойчивости) не должны превышать 75°",
          "Предельные углы откосов (углы устойчивости) не должны превышать 60°",
        ],
        correctAnswers: [
          "Предельные углы откосов (углы устойчивости) устанавливаются проектом",
        ],
      },
      {
        code: "20603106",
        text: "Где устанавливается периодичность осмотров и инструментальных наблюдений за деформациями бортов, откосов, уступов и отвалов, а также объектов, попадающих в зоны влияния горных работ и расположенных на земной поверхности?",
        answers: [
          "В проекте производства маркшейдерских работ",
          "В журнале маркшейдерских указаний",
          "В плане развития горных работ",
          "В паспорте ведения горных работ",
        ],
        correctAnswers: ["В проекте производства маркшейдерских работ"],
      },
      {
        code: "20603107",
        text: 'Какие требования установлены "Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых" для безопасного выполнения работ по ручной оборке откосов уступов?',
        answers: [
          "Ручная оборка откосов запрещается",
          "Ручная оборка должна осуществляться по наряду-допуску под наблюдением лиц технического надзора",
          "Ручная оборка допускается при наличии распоряжения под непосредственным наблюдением специалистов маркшейдерской службы",
        ],
        correctAnswers: [
          "Ручная оборка должна осуществляться по наряду-допуску под наблюдением лиц технического надзора",
        ],
      },
      {
        code: "20603108",
        text: "Разрешается ли ведение горных работ вблизи затопленных выработок или поверхностных водоемов?",
        answers: [
          "Разрешается по проекту, предусматривающему оставление целиков для предотвращения прорыва воды",
          "Запрещается",
          "Разрешается по согласованию с территориальным органом государственного горного надзора",
        ],
        correctAnswers: [
          "Разрешается по проекту, предусматривающему оставление целиков для предотвращения прорыва воды",
        ],
      },
      {
        code: "20603109",
        text: "Какие требования предъявляются к установке бурового станка?",
        answers: [
          "Буровой станок должен быть установлен на безопасном расстоянии от верхней бровки уступа в соответствии с проектом, но не менее 2 м от бровки до ближайшей точки опоры станка",
          "Буровой станок должен быть установлен на спланированной площадке на расстоянии 1 метра от верхней бровки уступа",
          "Буровой станок должен быть установлен на спланированной площадке на расстоянии 1,5 метра от верхней бровки уступа",
        ],
        correctAnswers: [
          "Буровой станок должен быть установлен на безопасном расстоянии от верхней бровки уступа в соответствии с проектом, но не менее 2 м от бровки до ближайшей точки опоры станка",
        ],
      },
      {
        code: "20603110",
        text: "Какие скважины после окончания бурения должны быть перекрыты?",
        answers: [
          "Скважины, диаметр устья которых более 250 мм",
          "Скважины, диаметр устья которых более 200 мм",
          "Скважины, диаметр устья которых более 220 мм",
          "Скважины, диаметр устья которых более 180 мм",
        ],
        correctAnswers: ["Скважины, диаметр устья которых более 250 мм"],
      },
      {
        code: "20603111",
        text: "Какова допустимая высота падения груза из ковша экскаватора в кузов автосамосвала?",
        answers: [
          "Высота падения груза не должна превышать 5 м",
          "Высота падения груза не должна превышать 4 м",
          "Высота падения груза определяется паспортом в зависимости от грузоподъемности автосамосвала",
          "Высота падения груза должна быть минимально возможной и во всех случаях не должна превышать 3 м",
        ],
        correctAnswers: [
          "Высота падения груза должна быть минимально возможной и во всех случаях не должна превышать 3 м",
        ],
      },
      {
        code: "20603112",
        text: "Через какое время после производства массовых взрывов разрешается допуск работников на рабочие места?",
        answers: [
          "Не ранее чем через 30 минут",
          "Не ранее чем через 15 минут",
          "Не ранее чем через 20 минут",
          "Не ранее чем через 25 минут",
        ],
        correctAnswers: ["Не ранее чем через 30 минут"],
      },
      {
        code: "20603113",
        text: "Где должна располагаться промышленная площадка рассолопромысла?",
        answers: [
          "На балансовых запасах месторождения",
          "На забалансовых запасах",
          "Вне балансовых запасов месторождения либо на участках залегания некондиционных или пониженного качества запасов",
          "Вне контуров запасов",
        ],
        correctAnswers: [
          "Вне балансовых запасов месторождения либо на участках залегания некондиционных или пониженного качества запасов",
        ],
      },
      {
        code: "20603114",
        text: "Какие установлены требования к проектированию разработки крупных месторождений минеральных солей при размещении на них двух и более рассолопромыслов?",
        answers: [
          "Составляется комплексный проект освоения месторождения, предусматривающий рациональную раскройку месторождения на горные отводы, обеспечивающий наиболее полное извлечение из недр балансовых запасов",
          "Составляются поэтапные проекты по каждому рассолопромыслу с учетом границ горных отводов недропользователей",
          "Составляется технико-экономическое обоснование разработки месторождения, в котором определяется порядок отработки месторождения несколькими недропользователями",
        ],
        correctAnswers: [
          "Составляется комплексный проект освоения месторождения, предусматривающий рациональную раскройку месторождения на горные отводы, обеспечивающий наиболее полное извлечение из недр балансовых запасов",
        ],
      },
      {
        code: "20603115",
        text: "Согласно требованиям Инструкции допустимое отклонение ствола скважины подземного растворения не должно превышать 5° в пределах конуса средних отклонений. Что собой представляет конус средних отклонений?",
        answers: [
          "Конус, образующая которого составляет 5° с вертикалью, проходящей через устье скважины",
          "Конус, образующая которого составляет 3° с вертикалью, проходящей через устье скважины",
          "Конус, образующая которого составляет 1° с вертикалью, проходящей через устье скважины",
          "Конус, образующая которого составляет 2° с вертикалью, проходящей через устье скважины",
        ],
        correctAnswers: [
          "Конус, образующая которого составляет 1° с вертикалью, проходящей через устье скважины",
        ],
      },
      {
        code: "20603116",
        text: "В каких случаях могут применяться системы разработки сплошным растворением на месторождениях солей?",
        answers: [
          "В случаях, когда месторождения солей залегают на глубинах 100 м",
          "В случаях, когда месторождения солей залегают на глубинах 150 м и более",
          "Лишь в случаях, когда на месторождениях, где могут быть допущены деформации земной поверхности и связь искусственного рассольного горизонта с пресными водами",
          "Ни в каких случаях",
        ],
        correctAnswers: [
          "Лишь в случаях, когда на месторождениях, где могут быть допущены деформации земной поверхности и связь искусственного рассольного горизонта с пресными водами",
        ],
      },
      {
        code: "20603117",
        text: "Какие требования предъявляются к нерастворителю, применяемому при подземном выщелачивании солей?",
        answers: [
          "Должно быть только вещество, химически нейтральное к воде",
          "Должно быть только вещество, химически нейтральное к соли и рассолу",
          "Должно быть только вещество с относительной плотностью меньше единицы",
          "Должен удовлетворять всем указанным требованиям",
        ],
        correctAnswers: ["Должен удовлетворять всем указанным требованиям"],
      },
      {
        code: "20603118",
        text: "Когда производится гидролокационная съемка формы подземной камеры рассолопромысла?",
        answers: [
          "После полной отработки каждой ступени, но не реже одного раза в пять лет",
          "После полной отработки каждой ступени, но не реже одного раза в год",
          "После полной отработки каждой ступени, но не реже одного раза в два года",
          "После полной отработки каждой ступени, но не реже одного раза в три года",
        ],
        correctAnswers: [
          "После полной отработки каждой ступени, но не реже одного раза в год",
        ],
      },
      {
        code: "20603119",
        text: "С какой целью производят закладку наблюдательных станций на рассолопромыслах и проводят инструментальные наблюдения?",
        answers: [
          "Для ведения учета добычи и потерь полезного ископаемого",
          "Только для определения эффективности применяемых конструктивных и горных мер охраны объектов от вредного влияния подземных камер растворения",
          "Для определения параметров процесса сдвижения, установления взаимосвязи между деформациями в подрабатываемых объектах, определения эффективности применяемых конструктивных и горных мер охраны объектов от вредного влияния подземных камер растворения",
        ],
        correctAnswers: [
          "Для определения параметров процесса сдвижения, установления взаимосвязи между деформациями в подрабатываемых объектах, определения эффективности применяемых конструктивных и горных мер охраны объектов от вредного влияния подземных камер растворения",
        ],
      },
      {
        code: "20603120",
        text: "Обязательны ли наблюдения за сдвижением земной поверхности на всех рассолопромыслах?",
        answers: [
          "Наблюдения за сдвижением земной поверхности на рассолопромыслах проводятся только по предписанию территориального органа Ростехнадзора",
          "Наблюдения за сдвижением земной поверхности на рассолопромыслах необязательны",
          "Наблюдения за сдвижением земной поверхности на всех рассолопромыслах обязательны",
          "Наблюдения за сдвижением земной поверхности на рассолопромыслах проводятся на усмотрение технического руководителя организации",
        ],
        correctAnswers: [
          "Наблюдения за сдвижением земной поверхности на всех рассолопромыслах обязательны",
        ],
      },
      {
        code: "20603121",
        text: "Когда производятся закладка реперов и начальные наблюдения на них и на наблюдательных станциях за сдвижением земной поверхности на рассолопромыслах?",
        answers: [
          "После эксплуатации рассолодобывающих камер",
          "Во время эксплуатации рассолодобывающих камер",
          "До начала эксплуатации рассолодобывающих камер",
          "После отработки месторождения",
        ],
        correctAnswers: ["До начала эксплуатации рассолодобывающих камер"],
      },
      {
        code: "20603122",
        text: "Из чего должна состоять типовая наблюдательная станция?",
        answers: [
          "Не менее чем из двух профильных линий вкрест простирания и одной по простиранию пласта",
          "Из одной профильной линии простирания пласта",
          "Не менее чем из двух профильных линий вкрест простирания и двух по простиранию пласта",
          "Не менее чем из четырех профильных линий вкрест простирания и двух по простиранию пласта",
        ],
        correctAnswers: [
          "Не менее чем из двух профильных линий вкрест простирания и одной по простиранию пласта",
        ],
      },
      {
        code: "20603123",
        text: "Длина профильной линии вкрест простирания станции наблюдения определяется по формуле.    Какой угол (α) используется при косинусе?",
        answers: [
          "Угол наклона земной поверхности над рассолопромыслом",
          "Угол, под которым отклонена скважина от вертикали",
          "Азимут профильной линии",
          "Угол падения пласта",
        ],
        correctAnswers: ["Угол падения пласта"],
      },
      {
        code: "20603124",
        text: "До какого расстояния следует уменьшить величину между рабочими реперами при активизации процесса сдвижения земной поверхности на рассолопромысле?",
        answers: ["10 - 15 м", "20 - 30 м", "15 - 20 м", "25 - 35 м"],
        correctAnswers: ["15 - 20 м"],
      },
      {
        code: "20603125",
        text: "С какой периодичностью осуществляются наблюдения на типовых станциях при скорости оседания земной поверхности до 15 мм/год?",
        answers: [
          "Одно наблюдение в три года",
          "Одно наблюдение в два года",
          "Ежегодные наблюдения",
          "Два наблюдения в год",
        ],
        correctAnswers: ["Ежегодные наблюдения"],
      },
      {
        code: "20603126",
        text: "При какой максимальной температуре вмещающих пород допускается применять камерно-лавную систему для отработки участков россыпей I и II классов устойчивости при отсутствии в толще налегающих пород таликов и при отсутствии на поверхности шахтного поля незамерзающих водотоков и водоемов?",
        answers: ["0 °C", "-2 °C", "-5 °C", "+2 °C", "+5 °C"],
        correctAnswers: ["-2 °C"],
      },
      {
        code: "20603127",
        text: "При каких условиях допускается применение камерно-целиковой системы разработки?",
        answers: [
          "При отработке участков россыпей I и II классов устойчивости с температурой вмещающего горного массива минус 2 °С и выше",
          "При отработке участков россыпей I и II классов устойчивости с температурой вмещающего горного массива минус 5 °С и выше",
          "При отработке участков россыпей I и II классов устойчивости с температурой вмещающего горного массива минус 3 °С и мощности вынимаемого пласта не более 2 м",
          "При любых условиях при разработке многолетнемерзлых россыпей подземным способом",
        ],
        correctAnswers: [
          "При отработке участков россыпей I и II классов устойчивости с температурой вмещающего горного массива минус 2 °С и выше",
        ],
      },
      {
        code: "20603128",
        text: "Назовите основной принцип определения класса устойчивости пород кровли (предварительный и окончательный) при разработке многолетнемерзлых россыпей подземным способом.",
        answers: [
          "Определять однозначно по общей характеристике горно-геологических условий выемочного участка",
          "Определять однозначно по усредненной характеристике горно-геологических условий выемочного участка",
          "Определять однозначно по средневзвешенной характеристике горно-геологических условий выемочного участка или отдельной его части",
          "Определять однозначно по наихудшей характеристике горно-геологических условий выемочного участка или отдельной его части",
        ],
        correctAnswers: [
          "Определять однозначно по наихудшей характеристике горно-геологических условий выемочного участка или отдельной его части",
        ],
      },
      {
        code: "20603129",
        text: "В каком случае разрешается частичное извлечение стоечной крепи?",
        answers: [
          "Только после полной отработки",
          "Только после зачистки почвы камеры",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20603130",
        text: "Какие требования предъявляются к максимально допустимой площади обнажения кровли в сдвоенных смежных камерах при применении системы разработки с ленточными целиками?",
        answers: [
          "Не должна превышать 4500 и 3500 м² в породах I и II классов устойчивости",
          "Не должна превышать 4000 и 3000 м² в породах I и II классов устойчивости",
          "Не должна превышать 5000 и 4000 м² в породах I и II классов устойчивости",
          "Определяется паспортом крепления и управления кровлей",
        ],
        correctAnswers: [
          "Не должна превышать 4000 и 3000 м² в породах I и II классов устойчивости",
        ],
      },
      {
        code: "20603131",
        text: "Какое максимальное время зачистки почвы камеры?",
        answers: ["2 суток", "3 суток", "5 суток", "7 суток"],
        correctAnswers: ["2 суток"],
      },
      {
        code: "20603132",
        text: "Какой следует принимать максимальный допустимый пролет первых от границ шахтного поля очистных камер?",
        answers: ["16 м", "18 м", "20 м", "22 м", "24 м"],
        correctAnswers: ["20 м"],
      },
      {
        code: "20603133",
        text: "Какой должна приниматься ширина ленточного междукамерного целика в случае применения камерной системы разработки с внутрипанельным опорным целиком?",
        answers: [
          "Не менее 1 м независимо от вынимаемой мощности пласта песков",
          "Не менее 1,3 м независимо от вынимаемой мощности пласта песков",
          "Не менее 1,7 м независимо от вынимаемой мощности пласта песков",
          "Не менее 1,5 м независимо от вынимаемой мощности пласта песков",
        ],
        correctAnswers: [
          "Не менее 1,7 м независимо от вынимаемой мощности пласта песков",
        ],
      },
      {
        code: "20603134",
        text: "Какую минимальную ширину ленточных междукамерных целиков следует принимать независимо от вынимаемой мощности пласта песков?",
        answers: ["1,5 м", "1,6 м", "1,7 м", "1,8 м", "2,0 м"],
        correctAnswers: ["1,7 м"],
      },
      {
        code: "20603135",
        text: "Какое время отводится на отработку запасов камер с использованием самоходного оборудования с выемкой пласта песков на полную мощность (вынимаемая мощность не более 3 м)?",
        answers: [
          "Полное время отработки запасов камер, включая зачистку почвы при актировке, не должно превышать 30 суток",
          "Полное время отработки запасов камер, включая зачистку почвы при актировке, не должно превышать 35 суток",
          "Полное время отработки запасов камер, включая зачистку почвы при актировке, не должно превышать 20 суток",
          "Полное время отработки запасов камер, включая зачистку почвы при актировке, не должно превышать 25 суток",
        ],
        correctAnswers: [
          "Полное время отработки запасов камер, включая зачистку почвы при актировке, не должно превышать 20 суток",
        ],
      },
      {
        code: "20603136",
        text: "Какие требования к камерной системе разработки с применением самоходного оборудования указаны неверно?",
        answers: [
          "При смещении осей междукамерных целиков в смежных камерах более 3 м максимальное опережение забоев принимать равным пролету камеры",
          "Проходку просечек для оформления целиков в опережающей камере производить до завершения отработки запасов отстающей смежной камеры",
          "При соосном расположении междукамерных целиков зачистку почвы под актировку производить одновременно в обеих камерах после завершения отбойки в них песков",
          "При соосном расположении междукамерных целиков в смежных камерах до завершения зачистки почвы под актировку в обеих камерах допускаются работы только по созданию необходимого опережения вновь образуемых очистных забоев с подвиганием забоя опережающей камеры не более чем на 6 м",
        ],
        correctAnswers: [
          "При смещении осей междукамерных целиков в смежных камерах более 3 м максимальное опережение забоев принимать равным пролету камеры",
        ],
      },
      {
        code: "20603137",
        text: "Какой порядок отработки панелей предусматривается при применении столбовой системы разработки?",
        answers: [
          "Прямой порядок, от руддвора или транспортного штрека к границам горного отвода",
          "Отступающий порядок по направлению к главному транспортному штреку или околоствольному двору главного ствола",
          "Комбинированный способ отработки: прямым и обратным ходом",
          "Порядок отработки выбирается в проекте на рудник",
        ],
        correctAnswers: [
          "Отступающий порядок по направлению к главному транспортному штреку или околоствольному двору главного ствола",
        ],
      },
      {
        code: "20603138",
        text: "Допустимо ли при повторной разработке россыпей пользоваться оценкой устойчивости пород кровли, принятой при первичной разработке россыпи?",
        answers: [
          "Недопустимо",
          "Допустимо, если класс устойчивости пород кровли понизится на одну ступень",
          "Класс устойчивости пород кровли повторно разрабатываемой шахты определяется заново в соответствии с соответствующей Методикой",
          "Допустимо, если класс устойчивости пород кровли повысится на одну ступень",
        ],
        correctAnswers: [
          "Класс устойчивости пород кровли повторно разрабатываемой шахты определяется заново в соответствии с соответствующей Методикой",
        ],
      },
      {
        code: "20603139",
        text: "Бурение каких скважин предусматривают гидрогеологические разведочные работы, предшествующие извлечению отдельного целика вытянутой формы в ранее отработанном шахтном поле?",
        answers: [
          "Только контрольных скважин с обеих длинных сторон целика на расстоянии 10 - 15 м от его контура",
          "Только контрольных скважин со стороны торцов извлекаемого целика на расстоянии 5 - 10 м по оси целика",
          "Только контрольных скважин в места наиболее вероятного скопления воды при расположении этих мест от извлекаемого целика ближе 10 м",
          "Только контрольных скважин для разведки трассы проводимой к целику вскрывающей выработки - с поверхности по оси выработки с максимальным расстоянием между скважинами 50 м",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20603140",
        text: "В соответствии с какими требованиями необходимо осуществлять гидрогеологические разведочные работы при извлечении запасов, относительно равномерно распределенных по площади в ранее отработанном шахтном поле?",
        answers: [
          "Предусматривать бурение контрольных скважин в места наименее вероятного скопления воды",
          "Бурить контрольные шпуры при приближении забоя выработки к границе отрабатываемого целика на расстоянии 10 м",
          "Контрольные скважины у барьерных целиков располагать на расстоянии 15 - 20 м от торцов барьерных целиков по их оси",
          "Контрольные скважины бурить по осям панельных сборных штреков, количество скважин определять проектом",
        ],
        correctAnswers: [
          "Контрольные скважины бурить по осям панельных сборных штреков, количество скважин определять проектом",
        ],
      },
      {
        code: "20603141",
        text: "При какой температуре допускается ведение повторной подземной разработки россыпи?",
        answers: [
          "При температуре не выше минус 10 °С на всех участках шахтного поля",
          "При температуре не выше минус 20 °С во всех подземных горных выработках",
          "При температуре массива вмещающих пород на горизонте горных работ не выше минус 20 °С",
          "При температуре массива вмещающих пород на горизонте горных работ не выше минус 15 °С",
        ],
        correctAnswers: [
          "При температуре массива вмещающих пород на горизонте горных работ не выше минус 20 °С",
        ],
      },
      {
        code: "20603142",
        text: "Какой класс устойчивости массива многолетнемерзлых пород россыпных месторождений не входит в Классификацию, приведенную в приложении 3 к Инструкции по разработке многолетнемерзлых россыпей подземным способом (камерные и столбовые системы разработки) (РД 06-326-99)?",
        answers: [
          "Высокоустойчивые",
          "Устойчивые",
          "Среднеустойчивые",
          "Низкоустойчивые",
          "Весьма неустойчивые",
          "Неустойчивые",
        ],
        correctAnswers: ["Низкоустойчивые"],
      },
      {
        code: "20603143",
        text: 'Что в соответствии с Инструкцией по разработке многолетнемерзлых россыпей подземным способом определяется как "выработка небольшой ширины (10 - 30 м), ограниченная междукамерными целиками или боковым массивом и междукамерными целиками, выемка пласта песков в которой осуществляется по короткой стороне"?',
        answers: [
          "Камеры (лавы) смежные",
          "Камера-лава",
          "Камера очистная",
          "Камера",
        ],
        correctAnswers: ["Камера очистная"],
      },
      {
        code: "20603144",
        text: 'Что в соответствии с Инструкцией по разработке многолетнемерзлых россыпей подземным способом определяется как "выработка, проведенная по пласту или вне его и предназначенная для сбора и транспортировки отбитой горной массы из очистных забоев в пределах панели"?',
        answers: [
          "Панель односторонняя",
          "Панельный сборный штрек",
          "Панель двухсторонняя",
          "Панельный вентиляционный штрек",
        ],
        correctAnswers: ["Панельный сборный штрек"],
      },
      {
        code: "20603145",
        text: "Какие требования безопасности при выполнении работ по извлечению крепи указаны неверно?",
        answers: [
          "При извлечении крепи должно быть не менее трех выходов из отработанной очистной выработки в действующие штреки, два из которых, при необходимости, допускается загромождать оборудованием или материалами",
          "Запрещается извлекать поломанные, изогнутые и расколотые стойки, а также стойки с полностью смятой подкладкой",
          "Перед началом работ по извлечению крепи и в процессе их производства следует производить осмотр и остукивание кровли специальным ручным инструментом, а в случаях необходимости опустить отдельные отслоения пород кровли",
          "При первых признаках обрушения кровли (потрескивание стоек) лица, занятые на извлечении крепи, должны быть выведены в безопасные места",
        ],
        correctAnswers: [
          "При извлечении крепи должно быть не менее трех выходов из отработанной очистной выработки в действующие штреки, два из которых, при необходимости, допускается загромождать оборудованием или материалами",
        ],
      },
      {
        code: "20603146",
        text: "Когда на руднике допускается проходка выработок без крепления?",
        answers: [
          "Допускается проходка вспомогательных горных выработок, когда срок их службы не превышает 6 месяцев",
          "Допускается проходка вспомогательных горных выработок протяженностью не более 25 м",
          "Допускается проходка горных выработок только в устойчивых породах",
          "Допускается проходка горных выработок в устойчивых породах, а также в случае, если технологический срок службы выработки не превышает времени ее устойчивого состояния",
        ],
        correctAnswers: [
          "Допускается проходка горных выработок в устойчивых породах, а также в случае, если технологический срок службы выработки не превышает времени ее устойчивого состояния",
        ],
      },
      {
        code: "20603147",
        text: "При выполнении каких из перечисленных профилактических мероприятий должно осуществляться проведение и поддержание выработок на месторождениях, на которых происходили горные удары?",
        answers: [
          "Только крепления выработок, предотвращающего обрушение пород кровли и боков",
          "Только создания устойчивых форм",
          "Только бурения разгрузочных щелей и скважин",
          "Только контурного взрывания",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20603148",
        text: "В каких случаях должен производиться контроль удароопасности в выработках?",
        answers: [
          'Только в случае изменений горно-геологической обстановки во время ведения горных работ в местах, где ранее была установлена категория "Опасно"',
          "Только в случае появления внешних признаков удароопасности",
          'Только в случае изменений горнотехнической обстановки во время ведения горных работ в местах, где ранее была установлена категория "Опасно"',
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "20603149",
        text: "Разрешается ли одновременное производство работ в наклонных выработках на различных отметках?",
        answers: [
          "Запрещается",
          "Разрешается по письменному распоряжению технического руководителя шахты",
          "Разрешается по согласованию с территориальным органом Ростехнадзора",
        ],
        correctAnswers: ["Запрещается"],
      },
      {
        code: "20603150",
        text: "С какой периодичностью должно производиться профилирование стенок шахтного ствола и проводников в нем и где отражаются результаты такого профилирования?",
        answers: [
          "Раз в год с отражением результатов в паспорте ствола",
          "Раз в три года с отражением результатов в журнале осмотра подъемной установки",
          "Сроки профилирования устанавливаются техническим руководителем эксплуатирующей организации для каждого ствола, но не реже одного раза в три года. Результаты профилирования отражаются в маркшейдерской документации",
          "Сроки профилирования устанавливаются территориальным органом Ростехнадзора по представлению главного инженера организации для каждого ствола, но не реже одного раза в три года. Результаты профилирования отражаются в журнале осмотра крепи и состояния горных выработок",
        ],
        correctAnswers: [
          "Сроки профилирования устанавливаются техническим руководителем эксплуатирующей организации для каждого ствола, но не реже одного раза в три года. Результаты профилирования отражаются в маркшейдерской документации",
        ],
      },
      {
        code: "20603151",
        text: 'Укажите требования, соответствующие Федеральным нормам и правилам в области промышленной безопасности "Правила безопасности при ведении горных работ и переработке твердых полезных ископаемых" к организации надзора и проектному оформлению при посадке кровли в процессе очистной выемки.',
        answers: [
          "Посадка кровли должна производиться под непосредственным руководством технического руководителя шахты в соответствии со специальным проектом",
          "Посадка кровли должна производиться под руководством лица технического надзора в соответствии с техническим регламентом",
          "Посадка кровли должна производиться под непосредственным руководством лица технического надзора в соответствии с проектом организации работ",
          "Посадка кровли должна производиться под непосредственным руководством лица технического сменного надзора в соответствии со специальным проектом",
        ],
        correctAnswers: [
          "Посадка кровли должна производиться под непосредственным руководством лица технического надзора в соответствии с проектом организации работ",
        ],
      },
      {
        code: "20603152",
        text: "Какие два дополнительных основных требования должны выполняться при отработке сближенных калийно-магниевых и соляных пластов?",
        answers: [
          "Соосное расположение целиков с опережением фронта очистных работ по верхнему пласту не менее чем на 45 м",
          "Соосное расположение целиков с опережением фронта очистных работ по верхнему пласту не менее чем на 50 м",
          "Соосное расположение очистных камер с опережением фронта очистных работ по верхнему пласту не менее чем на 50 м",
          "Соосное расположение очистных камер с опережением фронта очистных работ по верхнему пласту не менее чем на 45 м",
        ],
        correctAnswers: [
          "Соосное расположение очистных камер с опережением фронта очистных работ по верхнему пласту не менее чем на 50 м",
        ],
      },
      {
        code: "20603153",
        text: "Какие наблюдения должны проводиться маркшейдерской службой шахты в период подготовки блока к выщелачиванию?",
        answers: [
          "Систематические наблюдения за фактическим контуром зоны очистной выемки",
          "Систематические наблюдения за необоснованной застройкой площадей под проектным контуром выщелачивания",
          "Систематические наблюдения за сдвижением и деформацией пород",
          "Систематические наблюдения за обнаружением пустот с целью последующей их ликвидации",
        ],
        correctAnswers: [
          "Систематические наблюдения за сдвижением и деформацией пород",
        ],
      },
      {
        code: "20603154",
        text: "В каких случаях должны быть определены и нанесены на маркшейдерскую документацию границы опасных зон по прорыву воды и газов?",
        answers: [
          "Необходимость определения и нанесения на маркшейдерскую документацию границы опасных зон по прорывам воды и газа устанавливается территориальным органом Ростехнадзора",
          "Только на шахтах, где имеются затопленные выработки или другие водные (обводненные) объекты",
          "На шахтах, где имеются затопленные или другие водные (обводненные) объекты, а также выработки, в которых возможны скопления ядовитых и горючих газов",
        ],
        correctAnswers: [
          "На шахтах, где имеются затопленные или другие водные (обводненные) объекты, а также выработки, в которых возможны скопления ядовитых и горючих газов",
        ],
      },
      {
        code: "20603155",
        text: "Какой контур затопленных выработок можно считать достоверным?",
        answers: [
          "Если он зафиксирован в горной графической документации, составленной без проведения маркшейдерских съемок",
          "Если он зафиксирован в горной графической документации по результатам маркшейдерских съемок, выполненных без остановки забоев выработок с соблюдением требований по производству маркшейдерских работ",
          "Если он зафиксирован в горной графической документации по результатам маркшейдерских съемок, выполненных после остановки забоев выработок с соблюдением требований инструкции по производству маркшейдерских работ; правильность нанесения контура выработок может быть проверена по материалам этих съемок",
        ],
        correctAnswers: [
          "Если он зафиксирован в горной графической документации по результатам маркшейдерских съемок, выполненных после остановки забоев выработок с соблюдением требований инструкции по производству маркшейдерских работ; правильность нанесения контура выработок может быть проверена по материалам этих съемок",
        ],
      },
      {
        code: "20603156",
        text: "Через какое минимальное время после предыдущей подработки разрешается повторная подработка затопленной выработки?",
        answers: [
          "3 месяца",
          "6 месяцев",
          "1 месяц",
          "5 месяцев",
          "12 месяцев",
        ],
        correctAnswers: ["6 месяцев"],
      },
      {
        code: "20603157",
        text: "Какой минимальный радиус опасной зоны должен быть у затопленных вертикальных технических скважин, обсаженных с цементацией затрубного пространства?",
        answers: ["15 м", "20 м", "25 м", "35 м", "10 м"],
        correctAnswers: ["15 м"],
      },
      {
        code: "20603158",
        text: "Кто выполняет классификацию разведочных скважин на затампонированные и незатампонированные в целях установления под ними опасных зон?",
        answers: [
          "Главный маркшейдер",
          "Главный геолог",
          "Технический руководитель (главный инженер) шахты",
          "Начальник участка",
        ],
        correctAnswers: ["Главный геолог"],
      },
      {
        code: "20603159",
        text: "Кто на действующих шахтах составляет проекты границ опасных по прорывам воды зон, а также проекты ведения горных и буровых работ в опасных зонах?",
        answers: [
          "Только геологическая служба шахты",
          "Только маркшейдерская служба шахты",
          "Только техническая служба шахты",
          "Маркшейдерская, геологическая и техническая службы шахты",
        ],
        correctAnswers: [
          "Маркшейдерская, геологическая и техническая службы шахты",
        ],
      },
      {
        code: "20603160",
        text: "Кто является ответственным за организацию составления проекта границ опасных зон у затопленных выработок на действующих шахтах?",
        answers: [
          "Технический руководитель шахты",
          "Главный геолог шахты",
          "Главный маркшейдер шахты",
          "Главный механик шахты",
        ],
        correctAnswers: ["Главный маркшейдер шахты"],
      },
      {
        code: "20603161",
        text: "Что включают графические материалы к проектам границы опасной зоны у затопленной выработки?",
        answers: [
          "Только геологические разрезы с указанием затопленных выработок, разведочных скважин, разрывных нарушений, литологического состава угленосной толщи, уровня воды в затопленных выработках",
          "Только чертежи по построению границ опасных зон (барьерных и предохранительных целиков)",
          "Только выкопировки из планов горных выработок с нанесением проектируемых границ опасных зон, пунктов съемочной сети, положения геологических разрезов",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20603162",
        text: "Кто выполняет проекты горных и буровых работ в опасных зонах, содержащие мероприятия по предотвращению прорывов воды из затопленных выработок?",
        answers: [
          "Служба профессионального аварийно-спасательного формирования",
          "Геологическая служба шахты",
          "Специализированная проектная организация",
          "Технологическая служба шахты",
          "Маркшейдерская служба шахты",
        ],
        correctAnswers: ["Технологическая служба шахты"],
      },
      {
        code: "20603163",
        text: "Кто разрабатывает паспорта крепления горных выработок, технологию и очередность ведения горных работ в опасной зоне и вблизи ее, меры безопасности работ при составлении проектов ведения горных работ в опасных зонах?",
        answers: [
          "Энергомеханическая служба",
          "Служба вентиляции и техники безопасности (ВТБ)",
          "Технологическая служба шахты",
          "Геологическая служба шахты",
          "Маркшейдерская служба шахты",
          "Специализированная проектная организация",
        ],
        correctAnswers: ["Технологическая служба шахты"],
      },
      {
        code: "20603164",
        text: "В каких случаях допускается частичная или полная выемка угля в целиках без спуска воды из затопленных выработок?",
        answers: [
          "При положительном заключении территориального органа Ростехнадзора",
          "По решению технического руководителя вышестоящей, по отношению к шахте, организации",
          "При постоянном дежурстве горноспасателей на рабочих местах",
          "При наличии заключения Научно-исследовательского института горной геомеханики и маркшейдерского дела, по согласованию с органами Ростехнадзора",
        ],
        correctAnswers: [
          "При наличии заключения Научно-исследовательского института горной геомеханики и маркшейдерского дела, по согласованию с органами Ростехнадзора",
        ],
      },
      {
        code: "20603165",
        text: "За какое расстояние до подхода забоя ствола к выработкам или опасным зонам следует предусматривать бурение опережающих скважин (шпуров) для контроля контура выработок и уровня воды в них?",
        answers: ["За 8 м", "За 10 м", "За 15 м", "За 20 м"],
        correctAnswers: ["За 10 м"],
      },
      {
        code: "20603166",
        text: "Кто является ответственным за выполнение всех работ в зоне, опасной по прорывам воды, и по обеспечению их безопасности в шахтах?",
        answers: [
          "Главный геолог шахты",
          "Главный механик шахты",
          "Главный маркшейдер шахты",
          "Технический руководитель шахты",
        ],
        correctAnswers: ["Технический руководитель шахты"],
      },
      {
        code: "20603167",
        text: "Чем определяется готовность к ведению работ в опасной зоне?",
        answers: [
          "Актом комиссии в составе заместителей технического руководителя по ТБ и по производству горных работ, главного механика, главного маркшейдера, главного геолога и начальника участка",
          "Актом комиссии в составе заместителей технического руководителя по ТБ и по производству горных работ, главного маркшейдера, главного геолога и начальника участка",
          "Актом комиссии в составе заместителей технического руководителя по ТБ и по производству горных работ, главного механика, главного геолога и начальника участка",
          "Актом комиссии в составе заместителей технического руководителя по ТБ и по производству горных работ, главного механика, главного маркшейдера и главного геолога",
        ],
        correctAnswers: [
          "Актом комиссии в составе заместителей технического руководителя по ТБ и по производству горных работ, главного механика, главного маркшейдера, главного геолога и начальника участка",
        ],
      },
      {
        code: "20603168",
        text: "На какое давление должна быть рассчитана герметизация устья и задвижек водоспускных и опережающих скважин?",
        answers: [
          "На давление, в 1,5 раза превышающее давление на устье скважины при вскрытии ею затопленной выработки с определенным уровнем (напором) воды",
          "На давление, рассчитанное по формуле в зависимости от притока (расхода) воды по скважине, диаметра скважины, напора над устьем скважины",
          "На давление более 2,0 МПа (20 кгс/см²)",
          "На давление более 0,5 МПа (5 кгс/см²)",
          "На давление, в 2 раза превышающее давление на устье скважины при вскрытии ею затопленной выработки с определенным уровнем (напором) воды",
        ],
        correctAnswers: [
          "На давление, в 1,5 раза превышающее давление на устье скважины при вскрытии ею затопленной выработки с определенным уровнем (напором) воды",
        ],
      },
      {
        code: "20603169",
        text: "На какую максимальную глубину могут быть пробурены в опасной зоне водоспускные и опережающие скважины без герметизации их устьев?",
        answers: ["2 м", "5 м", "10 м", "12 м"],
        correctAnswers: ["5 м"],
      },
      {
        code: "20603170",
        text: "Какие мероприятия по газовому режиму должны выполняться при бурении скважин на шахтах, опасных по газу?",
        answers: [
          "Посещение камер для бурения горным мастером службы вентиляции и техники безопасности не реже одного раза в смену",
          "Наличие у всех людей, работающих в камере для бурения, изолирующих самоспасателей",
          "Обеспечение буровой установки прибором непрерывного контроля содержания метана в атмосфере, прибором периодического замера углекислого газа и наличие двусторонней телефонной связи с горным диспетчером шахты",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20603171",
        text: "С какой периодичностью буровой мастер должен проверять исправность задвижек при подходе забоя скважины к затопленным выработкам?",
        answers: [
          "Ежемесячно",
          "Ежесменно",
          "Периодичность проверки устанавливает технический руководитель шахты",
          "Перед каждым спуском и подъемом бурильных труб",
        ],
        correctAnswers: ["Перед каждым спуском и подъемом бурильных труб"],
      },
      {
        code: "20603172",
        text: "Кто утверждает акт окончания работ по бурению водоспускной или опережающей скважины?",
        answers: [
          "Технический руководитель шахты",
          "Главный маркшейдер шахты",
          "Начальник участка",
          "Главный геолог шахты",
          "Горный мастер службы вентиляции и техники безопасности",
        ],
        correctAnswers: ["Технический руководитель шахты"],
      },
      {
        code: "20603173",
        text: "Кто утверждает заключение о результатах очередного цикла буровых работ в шахте?",
        answers: [
          "Руководитель территориального органа Ростехнадзора",
          "Технический руководитель шахты",
          "Главный геолог шахты",
          "Главный маркшейдер шахты",
        ],
        correctAnswers: ["Технический руководитель шахты"],
      },
      {
        code: "20603174",
        text: "С какой периодичностью необходимо выполнять замеры водопритоков в проводимые выработки при проведении горных и буровых работ в опасной по прорывам воды зоне в случаях отсутствия резкого увеличения водопритока?",
        answers: ["Еженедельно", "Ежедневно", "Каждый час", "Каждые 2 часа"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "20603175",
        text: "Какое допускается максимальное расстояние между опережающими скважинами (шпурами) при оценке возможного положения затопленной одиночной выработки или незатампонированной скважины?",
        answers: ["2 м", "4 м", "6 м", "8 м", "10 м"],
        correctAnswers: ["4 м"],
      },
      {
        code: "20603176",
        text: "Какой может быть максимальный конечный диаметр опережающих и водоспускных скважин при ожидаемом давлении воды в затопленных выработках менее 0,5 МПа?",
        answers: ["56 мм", "66 мм", "76 мм", "86 мм"],
        correctAnswers: ["76 мм"],
      },
      {
        code: "20603177",
        text: "При каком давлении воды в затопленных выработках должны быть предусмотрены мероприятия, обеспечивающие безопасность горных работ при возможном разрушении направляющих труб и задвижек опережающих и водоспускных скважин?",
        answers: [
          "Более 0,3 МПа (более 3 кгс/см²)",
          "Более 0,8 МПа (более 8 кгс/см²)",
          "Более 1 МПа (более 10 кгс/см²)",
          "Более 0,5 МПа (более 5 кгс/см²)",
        ],
        correctAnswers: ["Более 1 МПа (более 10 кгс/см²)"],
      },
      {
        code: "20603178",
        text: "Под каким давлением производится проверка на прочность и герметичность заделки направляющей трубы после схватывания цемента и разбуривания цементной пробки путем закачки воды в скважину?",
        answers: [
          "Под давлением, превышающим в 1,5 раза давление воды в затопленных выработках",
          "Под давлением, превышающим в 2 раза давление воды в затопленных выработках",
          "Под давлением более 0,5 МПа (5 кгс/см²)",
          "Под давлением более 2 МПа (20 кгс/см²)",
        ],
        correctAnswers: [
          "Под давлением, превышающим в 1,5 раза давление воды в затопленных выработках",
        ],
      },
      {
        code: "20603179",
        text: "Каким способом выполняют наблюдения за давлением воды в затопленных, затопляемых и осушаемых выработках?",
        answers: [
          "Только измерением уровня воды в скважинах, пробуренных на затопленные выработки с поверхности или из выше расположенных горных выработок",
          "Только измерением давления воды манометрами, установленными на устьях скважин, пробуренных на затопленные выработки из действующих выработок, расположенных ниже уровня воды в затопленных",
          "Только измерением давления воды манометрами, установленными на трубах, проложенных в изоляционных перемычках",
          "Только измерением уровней воды в горных выработках, имеющих гидравлическую связь с затопленными",
          "Любым из перечисленных способом",
        ],
        correctAnswers: ["Любым из перечисленных способом"],
      },
      {
        code: "20603180",
        text: "Каким должен быть минимальный диаметр наблюдательной скважины, необходимый для пропуска уровнемера или желонки?",
        answers: ["120 мм", "110 мм", "50 мм", "30 мм"],
        correctAnswers: ["50 мм"],
      },
      {
        code: "20603181",
        text: "Как выполняют замеры уровней воды в затопленных выработках и сопутствующие наблюдения маркшейдерская и геологическая службы шахты?",
        answers: [
          "Еженедельно, по специальной инструкции, утвержденной техническим руководителем шахты",
          "Ежесуточно, по графику, утвержденному техническим руководителем шахты",
          "По регламенту, установленному техническим руководителем шахты и увязанному с графиком работ по затоплению выработок или по спуску воды из них",
        ],
        correctAnswers: [
          "По регламенту, установленному техническим руководителем шахты и увязанному с графиком работ по затоплению выработок или по спуску воды из них",
        ],
      },
      {
        code: "20603182",
        text: 'Какой порядок одновременного ведения очистных работ на смежных этажах предусмотрен "Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          "При одновременном ведении очистных работ на смежных этажах забои нижнего этажа должны опережать забои верхнего этажа на расстояние не более 5 м",
          "При одновременном ведении очистных работ на смежных этажах забои верхнего этажа должны опережать забои нижнего этажа на безопасное расстояние, определенное проектом",
          "При одновременном ведении очистных работ на смежных этажах забои нижнего этажа должны опережать забои верхнего этажа на безопасное расстояние, определенное техническим регламентом",
        ],
        correctAnswers: [
          "При одновременном ведении очистных работ на смежных этажах забои верхнего этажа должны опережать забои нижнего этажа на безопасное расстояние, определенное проектом",
        ],
      },
      {
        code: "20603183",
        text: "На какой минимальной высоте должны быть размещены трубы, желоба и другие коммуникации в случае пересечения ими проходов и рабочих площадок?",
        answers: ["2,0 м", "1,7 м", "1,8 м", "2,1 м", "2,2 м"],
        correctAnswers: ["2,0 м"],
      },
      {
        code: "20603184",
        text: "Какой должна быть высота свободного прохода на штреках (ортах) скреперования?",
        answers: [
          "На штреках (ортах) скреперования должен всегда сохраняться свободный проход по высоте не менее 2/3 высоты выработки",
          "На штреках (ортах) скреперования должен всегда сохраняться свободный проход по высоте не менее 1/2 от высоты выработки",
          "На штреках (ортах) скреперования должен всегда сохраняться свободный проход по высоте, который определен проектом",
        ],
        correctAnswers: [
          "На штреках (ортах) скреперования должен всегда сохраняться свободный проход по высоте не менее 2/3 высоты выработки",
        ],
      },
      {
        code: "20603185",
        text: "При какой глубине вертикальных стволов при наличии лестниц в обоих стволах механический подъем в одной из них может отсутствовать?",
        answers: [
          "При глубине до 70 м",
          "При глубине до 75 м",
          "При глубине до 80 м",
          "При глубине до 85 м",
        ],
        correctAnswers: ["При глубине до 70 м"],
      },
      {
        code: "20603186",
        text: "На какое расстояние при одновременной отработке нескольких подэтажей системой подэтажного обрушения каждый верхний подэтаж должен опережать нижний?",
        answers: [
          "На расстояние, устанавливаемое проектом, но не менее чем на длину, равную высоте одного подэтажа",
          "На расстояние не менее чем на длину, равную высоте двух подэтажей",
          "Расстояние определяется специальным проектом",
        ],
        correctAnswers: [
          "На расстояние, устанавливаемое проектом, но не менее чем на длину, равную высоте одного подэтажа",
        ],
      },
      {
        code: "20603187",
        text: "Что необходимо соблюдать при проектировании вскрытия, подготовки, систем разработки на участках недр, склонных и опасных по горным ударам?",
        answers: [
          "Только преимущественно проводить горные выработки по направлению наибольшего напряжения в массиве горных пород, соблюдая все перечисленные условия",
          "Только осуществлять разделение месторождения на шахтные поля и порядок их отработки, чтобы обеспечивалось планомерное извлечение запасов без образования целиков, острых углов и выступов фронта очистных работ",
          "Только снижать количество горных выработок, проводимых вблизи фронта очистных работ, особенно в зонах опорного давления",
          "Только сокращать применение систем разработки с открытым очистным пространством",
        ],
        correctAnswers: [
          "Только преимущественно проводить горные выработки по направлению наибольшего напряжения в массиве горных пород, соблюдая все перечисленные условия",
        ],
      },
      {
        code: "20603188",
        text: "Какую температуру должен иметь воздух, поступающий в подземные горные выработки?",
        answers: ["Не менее 2 °C", "Не менее 1 °C", "Не менее 0 °C"],
        correctAnswers: ["Не менее 2 °C"],
      },
      {
        code: "20603189",
        text: "Каким образом на каждой шахте должно обеспечиваться проветривание двух отдельных выходов, обеспечивающих выход людей на поверхность?",
        answers: [
          "За счет естественной тяги",
          "За счет общешахтной депрессии",
          "Разным направлением вентиляционных струй",
        ],
        correctAnswers: ["Разным направлением вентиляционных струй"],
      },
      {
        code: "20603190",
        text: 'Может ли "газовый режим" распространяться на отдельные участки, блоки, пласты или на шахту в целом?',
        answers: [
          "Может распространяться только на шахту в целом",
          "Может распространяться на отдельные рабочие зоны (участки, блоки и т. д.)",
          "Может распространяться только на горные выработки всего месторождения",
        ],
        correctAnswers: [
          "Может распространяться на отдельные рабочие зоны (участки, блоки и т. д.)",
        ],
      },
      {
        code: "20603191",
        text: 'Как часто во всех действующих выработках должны производиться нивелирование откаточных путей и проверки соответствия зазоров требованиям Федеральных норм и правил в области промышленной безопасности "Правила безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          "Один раз в год",
          "Один раз в пять лет",
          "Один раз в два года",
          "Один раз в три года",
        ],
        correctAnswers: ["Один раз в год"],
      },
      {
        code: "20603192",
        text: "На каком расстоянии от ствола может быть установлена вентиляторная установка?",
        answers: [
          "Не менее 10 метров от ствола",
          "Не менее 12 метров от ствола",
          "Не менее 15 метров от ствола",
        ],
        correctAnswers: ["Не менее 15 метров от ствола"],
      },
      {
        code: "20603193",
        text: "Под чьим руководством производится инструментальная проверка вертикальности копра, правильности установки направляющих шкивов по отношению к оси ствола и оси подъема?",
        answers: [
          "Под руководством главного геолога шахты (рудника)",
          "Под руководством главного механика шахты (рудника)",
          "Под руководством главного маркшейдера шахты (рудника)",
          "Под руководством горного мастера службы вентиляции и техники безопасности",
        ],
        correctAnswers: [
          "Под руководством главного маркшейдера шахты (рудника)",
        ],
      },
      {
        code: "20603194",
        text: "Как часто должны проходить медицинский осмотр рабочие, выполняющие работы повышенной опасности, перечень которых установлен руководителем организации?",
        answers: [
          "Каждый раз перед началом смены",
          "1 раз в полугодие",
          "1 раз в месяц",
          "1 раз в неделю",
        ],
        correctAnswers: ["Каждый раз перед началом смены"],
      },
      {
        code: "20603195",
        text: "Кому должен сообщать работник при обнаружении опасности, угрожающей людям, производственным объектам?",
        answers: [
          "Главному маркшейдеру шахты",
          "Техническому руководителю смены",
          "Руководителю шахты",
          "Главному геологу шахты",
        ],
        correctAnswers: ["Техническому руководителю смены"],
      },
      {
        code: "20603196",
        text: "Что из перечисленного не входит в мероприятия по разгазированию выработок?",
        answers: [
          "Возобновление работ в отперемыченных выработках, подключение электрооборудования и электрических сетей",
          "Обследование выработок после разгазирования перед допуском в них людей",
          "Контроль за разгазированием выработки и за свежей струей воздуха, концентрация газов в месте слияния исходящей и свежей струй воздуха не должна превышать: горючих газов - 1 %, ядовитых газов - ПДК",
          "Снятие напряжения с электрооборудования и электрических сетей и устранение других источников воспламенения в выработках, по которым будет двигаться исходящая струя",
          "Выбор способа и порядка разгазирования",
          "Выставление постов (на свежей струе) и запрещающих знаков в местах возможного подхода людей к выработкам, по которым двигается исходящая струя при разгазировании",
        ],
        correctAnswers: [
          "Возобновление работ в отперемыченных выработках, подключение электрооборудования и электрических сетей",
        ],
      },
      {
        code: "20603197",
        text: "Кто утверждает специальный план, в соответствии с которым производится вскрытие пожарного участка?",
        answers: [
          "Технический руководитель организации",
          "Руководитель территориального органа Ростехнадзора",
          "Руководитель организации",
        ],
        correctAnswers: ["Технический руководитель организации"],
      },
      {
        code: "20603198",
        text: "На содержание каких веществ должны анализироваться пробы воздуха, набираемые в непроветриваемой части затопленных выработок (при откачке)?",
        answers: [
          "Только CH4, H2S",
          "CO, CO2, CH4, H2S, O2 и H2",
          "Только CO, O2",
          "Только СO2, H2",
        ],
        correctAnswers: ["CO, CO2, CH4, H2S, O2 и H2"],
      },
      {
        code: "20603199",
        text: "Сколько сигнальных устройств должна иметь подъемная установка при проходке и углублении стволов?",
        answers: [
          "Количество независимых сигнальных устройств определяется проектом ведения горных работ",
          "Не менее двух независимых сигнальных устройств",
          "Одно независимое сигнальное устройство",
        ],
        correctAnswers: ["Не менее двух независимых сигнальных устройств"],
      },
      {
        code: "20603200",
        text: "В каком случае месторождение (объект строительства подземных сооружений) будет относиться к опасному по горным ударам?",
        answers: [
          'Только если прогнозом при определении степени удароопасности массива горных пород локальными инструментальными методами выявлена категория "Опасно" (удароопасная)',
          "Только если имели место микроудары и горные удары",
          "Только если имело место стреляние пород, интенсивное заколообразование, происходили толчки или горные удары на соседнем руднике (шахте) в пределах того же рудного тела и в аналогичных геологических условиях",
          "В любом из перечисленных случаев",
        ],
        correctAnswers: ["В любом из перечисленных случаев"],
      },
      {
        code: "20603201",
        text: "Чем должно быть регламентировано приведение горных выработок в неудароопасное состояние?",
        answers: [
          "Проектом, утвержденным председателем Комиссии (техническим руководителем эксплуатирующей объект ведения горных работ организации)",
          "Указаниями территориального органа Ростехнадзора",
          "Техническими условиями, разработанными специализированной проектной организацией",
          "Локальным прогнозом удароопасности",
        ],
        correctAnswers: [
          "Проектом, утвержденным председателем Комиссии (техническим руководителем эксплуатирующей объект ведения горных работ организации)",
        ],
      },
      {
        code: "20603202",
        text: "В каком разделе указаний по безопасному ведению горных работ на месторождениях, склонных и опасных по горным ударам, представляется характеристика горных ударов, других геодинамических явлений, отражающих специфику геологических условий и технологии отработки месторождения?",
        answers: [
          "Проведение и поддержание горных выработок",
          "Порядок вскрытия, подготовки к выемке и отработки выемочных участков, блоков",
          "Общие положения",
          "Методы прогноза удароопасности участков массива горных пород и руд",
          "Приведение горных выработок в неудароопасное состояние",
        ],
        correctAnswers: ["Общие положения"],
      },
      {
        code: "20603203",
        text: "Кто на каждом объекте, отрабатывающем месторождения, склонном и опасном по горным ударам, производит учет всех случаев горных ударов, микроударов и толчков, стреляний, интенсивного заколообразования и шелушения, результатов определения категорий удароопасности выработок, примененных мер по предотвращению горных ударов с оценкой их эффективности?",
        answers: [
          "Технический руководитель организации",
          "Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении",
          "Служба прогноза и предотвращения горных ударов или маркшейдерско-геологическая служба",
        ],
        correctAnswers: [
          "Служба прогноза и предотвращения горных ударов или маркшейдерско-геологическая служба",
        ],
      },
      {
        code: "20603204",
        text: "Кем утверждается проект работ и мероприятий по ликвидации последствий горного удара?",
        answers: [
          "Руководителем организации",
          "Техническим руководителем объекта",
          "Специализированной организацией, выполняющей работы по проблеме горных ударов на данном месторождении",
          "Руководителем территориального органа Ростехнадзора",
          "Руководителем маркшейдерско-геологической службы",
        ],
        correctAnswers: ["Техническим руководителем объекта"],
      },
      {
        code: "20603205",
        text: 'На какой основе осуществляется изменение параметров противоударных мероприятий и периодичности прогноза степени удароопасности, предусмотренных "Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          "На основе специальных исследований и заключения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
          "На основе заключения представителя территориального органа Ростехнадзора",
          "На основе специальных исследований и заключения специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
          "На основе заключения технического руководителя объекта",
        ],
        correctAnswers: [
          "На основе специальных исследований и заключения специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
        ],
      },
      {
        code: "20603206",
        text: "Что из перечисленного необходимо соблюдать при проектировании вскрытия, подготовки, систем разработки на месторождениях, склонных и опасных по горным ударам?",
        answers: [
          "Только сокращение применения систем разработки с открытым очистным пространством",
          "Только разделение месторождения на шахтные поля и порядок их отработки, обеспечивающие планомерное извлечение запасов по возможности без образования целиков, острых углов и выступов фронта очистных работ",
          "Только ограничение встречных и догоняющих фронтов очистных работ",
          "Только снижение количества горных выработок, проводимых вблизи фронта очистных работ, особенно в зонах опорного давления",
          "Только использование опережающей отработки защитных залежей (слоев), в том числе бурение разгрузочных скважин большого диаметра, расположение выработок в защищенных зонах",
          "Все перечисленное, включая преимущественное проведение горных выработок вне зоны влияния тектонических нарушений и по направлению наибольшего напряжения в массиве горных пород",
        ],
        correctAnswers: [
          "Все перечисленное, включая преимущественное проведение горных выработок вне зоны влияния тектонических нарушений и по направлению наибольшего напряжения в массиве горных пород",
        ],
      },
      {
        code: "20603207",
        text: "Какими методами производится локальный прогноз удароопасности участков массива горных пород и руд, а также оценка эффективности мер предотвращения горных ударов?",
        answers: [
          "Только геофизическими методами",
          "Только геомеханическими методами",
          "Только визуальными наблюдениями за разрушением приконтурного массива выработок",
          "Всеми перечисленными",
        ],
        correctAnswers: ["Всеми перечисленными"],
      },
      {
        code: "20603208",
        text: 'Каким образом участки горных выработок категории "Опасно" приводят в неудароопасное состояние?',
        answers: [
          "Путем создания в краевой части массива пород защитной зоны шириной не более 1 м",
          "Путем создания в краевой части массива пород защитной зоны шириной n+2 м, но не менее 2 м",
          "Путем создания в краевой части массива пород защитной зоны шириной не менее 1 м",
          "Путем создания в краевой части массива пород защитной зоны шириной n+1 м, но не более 2 м",
        ],
        correctAnswers: [
          "Путем создания в краевой части массива пород защитной зоны шириной n+2 м, но не менее 2 м",
        ],
      },
      {
        code: "20603209",
        text: "Каким должно быть расстояние между стенками стволов при строительстве объекта подземного строительства на участках недр, склонных и опасных по горным ударам?",
        answers: [
          "Не менее 50 м",
          "Не менее 45 м",
          "Не менее 30 м",
          "Не менее 25 м",
        ],
        correctAnswers: ["Не менее 50 м"],
      },
      {
        code: "20603210",
        text: "Каким должно быть расстояние между стенками стволов при строительстве шахт на месторождениях, склонных и опасных по горным ударам?",
        answers: [
          "Не менее 50 м",
          "Не менее 45 м",
          "Не менее 30 м",
          "Не менее 25 м",
        ],
        correctAnswers: ["Не менее 50 м"],
      },
      {
        code: "20603211",
        text: "В каком случае нарушены требования безопасности при проходке стволов?",
        answers: [
          "При проходке стволов допускается отставание постоянной крепи от забоя не более диаметра ствола вчерне",
          "Проходка выработок непосредственно от ствола должна осуществляться после возведения временной или постоянной крепи в стволе",
          'Образующийся при сбойке целик должен быть приведен при категории "Опасно" в неопасное состояние по всей площади',
          "Сбойка двух выработок на участках, склонных и опасных по горным ударам, с расстояния между забоями 20 м должна вестись двумя забоями",
        ],
        correctAnswers: [
          "Сбойка двух выработок на участках, склонных и опасных по горным ударам, с расстояния между забоями 20 м должна вестись двумя забоями",
        ],
      },
      {
        code: "20603212",
        text: 'Какие из перечисленных требований на участках категории "Опасно" указаны верно? Укажите все правильные ответы.',
        answers: [
          'При проходке стволов на участках категории "Опасно" необходимо применять передовые разгрузочные щели с таким расчетом, чтобы плоскость щели располагалась перпендикулярно направлению действия максимальных горизонтальных напряжений',
          "При расположении стволов в породах, опасных по горным ударам, должны быть запроектированы и выполняться мероприятия, обеспечивающие снижение до неопасных значений концентраций напряжений в стенках ствола",
          "Мероприятия по предотвращению горных ударов должны проводиться после возведения в стволе постоянной крепи",
          "В особо сложных условиях мероприятия по предотвращению горных ударов должны утверждаться представителем территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          'При проходке стволов на участках категории "Опасно" необходимо применять передовые разгрузочные щели с таким расчетом, чтобы плоскость щели располагалась перпендикулярно направлению действия максимальных горизонтальных напряжений',
          "При расположении стволов в породах, опасных по горным ударам, должны быть запроектированы и выполняться мероприятия, обеспечивающие снижение до неопасных значений концентраций напряжений в стенках ствола",
        ],
      },
      {
        code: "20603213",
        text: "В каком случае допускаются отступления от последовательного продвигания фронта очистных работ в пределах как шахтного поля (участка), так и месторождения в целом, при отработке месторождений, склонных и опасных по горным ударам?",
        answers: [
          "В соответствии с требованиями Правил безопасности при ведении горных работ и переработке твердых полезных ископаемых",
          "С разрешения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
          "Если данные отступления определяются указаниями по безопасному ведению горных работ на месторождении, склонном и опасном по горным ударам",
          "Не допускаются ни в каком случае",
        ],
        correctAnswers: [
          "С разрешения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
        ],
      },
      {
        code: "20603214",
        text: "Разработку каких рудных тел необходимо производить преимущественно системой одностадийного этажного или подэтажного принудительного обрушения с отбойкой руды на зажатую среду?",
        answers: [
          "Крутопадающих мощных рудных тел",
          "Пологих рудных тел",
          "Горизонтальных рудных тел",
          "Наклонных рудных тел",
        ],
        correctAnswers: ["Крутопадающих мощных рудных тел"],
      },
      {
        code: "20603215",
        text: "В каком случае горные работы следует производить с применением комплекса мер по профилактике горных ударов, высыпаний, вывалов и обрушения пород (руд) на участках рудного массива или массива горных пород?",
        answers: [
          "Расположенных на любом удалении от плоскости сместителя тектонического нарушения",
          "Расположенных на удалении 15 - 20 м от плоскости сместителя тектонического нарушения",
          "Расположенных на удалении 5 - 10 м от плоскости сместителя тектонического нарушения",
          "Данный комплекс мер допускается не производить на участках рудного массива или массива горных пород",
        ],
        correctAnswers: [
          "Расположенных на удалении 5 - 10 м от плоскости сместителя тектонического нарушения",
        ],
      },
      {
        code: "20603216",
        text: 'Какие угольные пласты относятся к категории "особовыбросоопасные угольные пласты"?',
        answers: [
          "Выбросоопасные угольные пласты (участки угольных пластов) в зонах активных по внезапным выбросам тектонических нарушений",
          "Угольные пласты (горные породы), угрожаемые по горным ударам, на которых в пределах данного шахтного поля произошел горный удар",
          'Угольные пласты (горные породы), угрожаемые по внезапным выбросам угля (породы) и газа, на которых в пределах данного шахтного поля при проведении прогноза выбросоопасности выявлена категория "опасно"',
          "Угольные пласты (горные породы), угрожаемые по внезапным выбросам угля (породы) и газа, на которых в пределах данного шахтного поля произошел внезапный выброс угля (породы) и газа",
        ],
        correctAnswers: [
          "Выбросоопасные угольные пласты (участки угольных пластов) в зонах активных по внезапным выбросам тектонических нарушений",
        ],
      },
      {
        code: "20603217",
        text: "К какому виду прогноза динамических явлений относится прогноз по данным, полученным при ведении геолого-разведочных работ, и прогноз по непрерывным сейсмоакустическим наблюдениям?",
        answers: [
          "Локальный прогноз",
          "Региональный прогноз",
          "Прогноз перед вскрытием угольных пластов горными выработками",
          "Текущий прогноз",
          "Прогноз удароопасности и выбросоопасности пород",
        ],
        correctAnswers: ["Региональный прогноз"],
      },
      {
        code: "20603218",
        text: "В соответствии с чем должен проводиться мониторинг массива горных пород геофизическими методами?",
        answers: [
          "В соответствии с Инструкцией по прогнозу динамических явлений и мониторингу массива горных пород при отработке угольных месторождений",
          "В соответствии с распорядительным документом руководителя угледобывающей организации",
          "В соответствии с проектной документацией, утвержденной главным инженером",
          "В соответствии с Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых",
        ],
        correctAnswers: [
          "В соответствии с проектной документацией, утвержденной главным инженером",
        ],
      },
      {
        code: "20603219",
        text: "Кем выполняются прогноз динамических явлений и контроль эффективности применения мер по предотвращению динамических явлений на шахтах, разрабатывающих угольные пласты, склонные к динамическим явлениям?",
        answers: [
          "Инженерно-техническими работниками службы прогноза динамических явлений",
          "Работниками специализированной организации",
          "Работниками маркшейдерско-геологической службы",
          "Техническим руководителем (главным инженером) угледобывающей организации",
        ],
        correctAnswers: [
          "Инженерно-техническими работниками службы прогноза динамических явлений",
        ],
      },
      {
        code: "20603220",
        text: "С какой периодичностью распорядительным документом руководителя угледобывающей организации угольные пласты и горные породы относятся к категориям по динамическим явлениям, утверждаются опасные зоны и определяется комплекс мер по прогнозу и предотвращению динамических явлений?",
        answers: [
          "Ежегодно",
          "Ежемесячно",
          "Еженедельно",
          "Ежеквартально",
          "Ежедекадно",
        ],
        correctAnswers: ["Ежегодно"],
      },
      {
        code: "20603221",
        text: "В течение какого времени должны расследоваться события, предшествующие динамическим явлениям при первом их проявлении?",
        answers: [
          "В течение 10 дней",
          "В течение двадцати четырех часов",
          "В течение тридцати двух часов",
          "В течение 5 дней",
        ],
        correctAnswers: ["В течение двадцати четырех часов"],
      },
      {
        code: "20603222",
        text: "Что относится к признакам внезапного выброса угля и газа?",
        answers: [
          "Только воздушный толчок и звуковые эффекты, повреждение и (или) перемещение оборудования, наличие угольной пыли на откосе выброшенного угля и крепи горной выработки",
          "Только быстропротекающее разрушение призабойной части угольного пласта, образование в угольном пласте полости, ширина которой меньше ее глубины",
          "Только относительное газовыделение из разрушенного угля, превысившее его природную газоносность",
          "Только отброс угля от забоя на расстояние, превышающее протяженность его размещения под углом естественного откоса",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20603223",
        text: "Какие из событий предшествуют горному удару?",
        answers: [
          "Только повышенное горное давление на крепь горной выработки",
          "Только повышенный выход буровой мелочи и зажатие бурового инструмента",
          "Только удары, трески, толчки в массиве горных пород различной силы и частоты",
          "Только стреляние отслоившихся кусков угля (породы)",
          "Все перечисленные события",
        ],
        correctAnswers: ["Все перечисленные события"],
      },
      {
        code: "20603224",
        text: "На основе чего выполняется геодинамическое районирование?",
        answers: [
          "Только геодезических, геофизических и геохимических полевых инструментальных наблюдений",
          "Только анализа геологических, геофизических, геохимических и картографических материалов",
          "Только дешифрирования космических и аэрофотоснимков, морфометрического анализа земной поверхности",
          "Всего перечисленного",
        ],
        correctAnswers: ["Всего перечисленного"],
      },
      {
        code: "20603225",
        text: 'Какие горные породы относятся к категории "склонные к внезапным выбросам породы и газа"?',
        answers: [
          "Угрожаемые по внезапным выбросам угля и газа угольные пласты, у которых мощность пород основной кровли 10 м и более",
          "Горные породы с пределом прочности на одноосное сжатие 80 МПа или более, залегающие на глубине более 500 м, в породе присутствует кварц и при бурении скважин керн делится на выпукло-вогнутые диски толщиной менее 1/3 диаметра",
          "Угрожаемые по внезапным выбросам угля и газа пласты, на расстоянии до 25 м от которых в почве залегает надрабатываемый газоносный угольный пласт",
          "Горные породы, залегающие на глубине 600 м и более, керн которых, полученный при бурении скважин, делится на диски",
        ],
        correctAnswers: [
          "Горные породы, залегающие на глубине 600 м и более, керн которых, полученный при бурении скважин, делится на диски",
        ],
      },
      {
        code: "20603226",
        text: "На протяжении какого периода времени собирается информация, на основании которой определяются пороговые значения параметров сейсмических событий применяемого метода регионального прогноза для каждого пласта?",
        answers: [
          "Не менее шести месяцев",
          "Не менее пяти месяцев",
          "Не менее трех месяцев",
          "Не менее двух месяцев",
        ],
        correctAnswers: ["Не менее шести месяцев"],
      },
      {
        code: "20603227",
        text: "Кем определяются места расположения скважин и периодичность их бурения при проведении прогноза динамических явлений в месте вскрытия угольных пластов?",
        answers: [
          "Работниками специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении",
          "Специалистами службы прогноза динамических явлений",
          "ИТР геологической службы угледобывающей организации",
          "Техническим руководителем (главным инженером) угледобывающей организации",
        ],
        correctAnswers: ["ИТР геологической службы угледобывающей организации"],
      },
      {
        code: "20603228",
        text: "Каким методом проводится прогноз удароопасности в месте вскрытия склонного к горным ударам угольного пласта подготовительной выработкой?",
        answers: [
          "Методом регионального прогноза по непрерывным сейсмоакустическим наблюдениям",
          "Методом локального прогноза по изменению естественной влаги угля",
          "Методом локального прогноза по выходу буровой мелочи",
          "Методом текущего прогноза по параметрам выбросоопасности",
        ],
        correctAnswers: [
          "Методом локального прогноза по выходу буровой мелочи",
        ],
      },
      {
        code: "20603229",
        text: "В каких горных выработках проводится локальный прогноз удароопасности угольных пластов?",
        answers: [
          "Только в горных выработках действующих выемочных участков",
          "Только в подготовительных горных выработках, проводимых по угольным пластам",
          "Только в поддерживаемых горных выработках",
          "Во всех перечисленных горных выработках",
        ],
        correctAnswers: ["Во всех перечисленных горных выработках"],
      },
      {
        code: "20603230",
        text: "Какое утверждение, относящееся к порядку проведения локального прогноза удароопасности угольных пластов, указано неверно?",
        answers: [
          "Локальный прогноз удароопасности в поддерживаемых горных выработках, пройденных по угрожаемым и опасным по горным ударам угольным пластам, проводится спустя три года после окончания их проведения с периодичностью не реже одного раза в три года",
          "На удароопасных угольных пластах локальный прогноз удароопасности проводится на участках горных выработок, подлежащих погашению",
          "На удароопасных угольных пластах локальный прогноз удароопасности проводится до начала ведения горных работ на участке горной выработки, подлежащем перекреплению",
          "Локальный прогноз в поддерживаемых выработках проводится на сопряжениях поддерживаемых горных выработок в каждый борт горной выработки на расстоянии не более 10 м от сопряжения",
        ],
        correctAnswers: [
          "Локальный прогноз удароопасности в поддерживаемых горных выработках, пройденных по угрожаемым и опасным по горным ударам угольным пластам, проводится спустя три года после окончания их проведения с периодичностью не реже одного раза в три года",
        ],
      },
      {
        code: "20603231",
        text: "В скважинах какой минимальной длины устанавливаются геофоны на выемочных участках для проведения текущего прогноза по акустической эмиссии?",
        answers: ["1 м", "2 м", "3 м", "4 м", "5 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "20603232",
        text: "Через какое максимальное расстояние подвигания забоя подготовительной или очистной выработки пороговые значения прогностических параметров переутверждаются?",
        answers: ["100 м", "200 м", "300 м", "400 м", "500 м"],
        correctAnswers: ["300 м"],
      },
      {
        code: "20603233",
        text: "Кем утверждаются пороговые значения прогностических параметров, установленные по результатам текущего прогноза выбросоопасности по параметрам искусственного акустического сигнала?",
        answers: [
          "ИТР геологической службы угледобывающей организации",
          "Работником службы прогноза динамических явлений",
          "Руководителем угледобывающей организации",
          "Техническим руководителем (главным инженером) угледобывающей организации",
        ],
        correctAnswers: [
          "Техническим руководителем (главным инженером) угледобывающей организации",
        ],
      },
      {
        code: "20603234",
        text: "В соответствии с какой документацией проводится оценка геодинамического состояния массива горных пород?",
        answers: [
          "В соответствии с документацией по расчету пороговых значений прогностических параметров",
          "В соответствии с документацией по прогнозу по параметрам аэрогазового контроля",
          "В соответствии с документацией по проведению геофизического мониторинга",
          "В соответствии с документацией по проведению разведочных и (или) контрольных наблюдений",
          "В соответствии с документацией по определению выбросоопасности по региональному прогнозу",
        ],
        correctAnswers: [
          "В соответствии с документацией по проведению геофизического мониторинга",
        ],
      },
      {
        code: "20603235",
        text: "Какая из перечисленных характеристик соответствует мелкокусковатому типу нарушенности угля?",
        answers: [
          "Слоистость и трещиноватость, как правило, затушеваны. Массив состоит из угловатых кусков угля разнообразной формы. Между кусками может встречаться угольная мелочь, зернистый и даже землистый уголь. Уголь слабоустойчив к механическому воздействию, но осыпается с трудом",
          "Слоистость и трещиноватость затушеваны. Основная масса состоит из мелких зерен угля, между которыми располагается землистый уголь (угольная мука). Достаточно спрессован, между пальцами раздавливается с трудом. Уголь слабоустойчив и склонен к осыпанию",
          "Слоистость и трещиноватость затушеваны. Состоит из тонкоперетертого угля (угольная мука). Неустойчив, осыпается интенсивно. Между пальцами раздавливается легко",
          "Слоистость и трещиноватость затушеваны. Уголь слагается из отдельных линз, поверхность их зеркально отполирована и несет на себе борозды и штрихи скольжения. При механическом воздействии превращается иногда в угольную мелочь",
          "Заметно выраженная слоистость, уголь в массиве монолитный, устойчивый, не осыпается. Разламывается на куски, ограниченные слоистостью и трещинами",
        ],
        correctAnswers: [
          "Слоистость и трещиноватость, как правило, затушеваны. Массив состоит из угловатых кусков угля разнообразной формы. Между кусками может встречаться угольная мелочь, зернистый и даже землистый уголь. Уголь слабоустойчив к механическому воздействию, но осыпается с трудом",
        ],
      },
    ],
    20604: [
      {
        code: "20604000",
        text: "Каким федеральным органом исполнительной власти оформляется горноотводная документация?",
        answers: [
          "Органом государственного горного надзора и уполномоченными органами исполнительной власти субъектов Российской Федерации",
          "Федеральным агентством по недропользованию - Роснедрами",
          "Министерством природных ресурсов и экологии Российской Федерации",
          "Федеральной службой по надзору в сфере защиты прав потребителей и благополучия человека",
        ],
        correctAnswers: [
          "Органом государственного горного надзора и уполномоченными органами исполнительной власти субъектов Российской Федерации",
        ],
      },
      {
        code: "20604001",
        text: "Кем оформляется документация, удостоверяющая уточненные границы горного отвода, если участки недр местного значения расположены на территориях 2 и более субъектов Российской Федерации и если нормативными правовыми актами субъекта Российской Федерации не определен орган исполнительной власти субъекта Российской Федерации?",
        answers: [
          "Органом исполнительной власти любого из причастных к горному отводу субъекта Российской Федерации",
          "Органом государственного горного надзора",
          "Федеральным агентством по недропользованию",
        ],
        correctAnswers: ["Органом государственного горного надзора"],
      },
      {
        code: "20604002",
        text: "На какой срок оформляется документация, удостоверяющая уточненные границы горного отвода?",
        answers: [
          "На 1 год",
          "На срок действия лицензии на пользование недрами",
          "На 2 года",
          "Бессрочно",
        ],
        correctAnswers: ["На срок действия лицензии на пользование недрами"],
      },
      {
        code: "20604003",
        text: "Каким образом осуществляется пересылка документации, содержащей сведения, представляющие государственную тайну?",
        answers: [
          "В соответствии с законодательством Российской Федерации о государственной тайне",
          'Заказным почтовым отправлением через АО "Почта России"',
          "Через курьерскую службу",
          "Любым из перечисленных способов",
        ],
        correctAnswers: [
          "В соответствии с законодательством Российской Федерации о государственной тайне",
        ],
      },
      {
        code: "20604004",
        text: "В какой срок со дня поступления заявления и проекта горного отвода осуществляется оформление документации либо принятие мотивированного решения об отказе?",
        answers: [
          "Не позднее 10 дней",
          "Не позднее 15 дней",
          "Не позднее 25 дней",
          "Не позднее 30 дней",
        ],
        correctAnswers: ["Не позднее 25 дней"],
      },
      {
        code: "20604005",
        text: "В какой срок органом исполнительной власти субъекта Российской Федерации оформленная документация, удостоверяющая уточненные границы горного отвода, передается пользователю недр?",
        answers: [
          "Не позднее 3 дней со дня оформления",
          "Не позднее 5 дней со дня оформления",
          "Не позднее 10 дней со дня оформления",
          "Не позднее 15 дней со дня оформления",
        ],
        correctAnswers: ["Не позднее 5 дней со дня оформления"],
      },
      {
        code: "20604006",
        text: "Сколько экземпляров документации, удостоверяющей уточненные границы горного отвода, оформляется органом исполнительной власти субъекта Российской Федерации?",
        answers: [
          "2 экземпляра",
          "3 экземпляра",
          "4 экземпляра",
          "5 экземпляров",
        ],
        correctAnswers: ["3 экземпляра"],
      },
      {
        code: "20604007",
        text: "В каких случаях документация, удостоверяющая уточненные границы горного отвода, подлежит переоформлению?",
        answers: [
          "Только в случае переоформления лицензии на пользование недрами",
          "Только в случаях изменения геологической информации о недрах, наличия технологических потребностей, условий и факторов, влияющих на безопасное состояние недр, земной поверхности и расположенных на ней объектов, в том числе при изменении технического проекта выполнения работ, связанных с пользованием недрами",
          "Только в случаях изменения срока пользования участком недр, установленного в лицензии на пользование недрами",
          "Во всех перечисленных случаях, а также при выявлении технических ошибок в лицензии на пользование недрами",
        ],
        correctAnswers: [
          "Во всех перечисленных случаях, а также при выявлении технических ошибок в лицензии на пользование недрами",
        ],
      },
      {
        code: "20604008",
        text: "Какие параметры эксплуатации объектов ведения горных работ планами и схемами развития горных работ не определяются?",
        answers: [
          "Штатная численность сотрудников объекта ведения горных работ",
          "Условия безопасного недропользования, технические и технологические решения при эксплуатации объектов ведения горных работ",
          "Направления развития горных работ",
          "Объемы добычи и первичной переработки полезных ископаемых",
        ],
        correctAnswers: [
          "Штатная численность сотрудников объекта ведения горных работ",
        ],
      },
      {
        code: "20604009",
        text: "На какие перечисленные виды работ не составляются планы и схемы горных работ?",
        answers: [
          "На работы по добыче полезных ископаемых",
          "На маркшейдерские работы",
          "На работы, связанные со вторичной переработкой минерального сырья",
          "На вскрышные работы",
        ],
        correctAnswers: [
          "На работы, связанные со вторичной переработкой минерального сырья",
        ],
      },
      {
        code: "20604010",
        text: "На какой срок по решению пользователя недр составляется схема развития горных работ по одному или нескольким видам горных работ?",
        answers: [
          "Не более 3 лет",
          "Не более 5 лет",
          "Не более 1 года",
          "Не регламентируется",
        ],
        correctAnswers: ["Не более 5 лет"],
      },
      {
        code: "20604011",
        text: "На какой максимальный срок составляется схема развития горных работ?",
        answers: ["На 1 год", "На 2 года", "На 5 лет", "На 8 лет"],
        correctAnswers: ["На 5 лет"],
      },
      {
        code: "20604012",
        text: "На основе чего составляются планы и схемы развития горных работ?",
        answers: [
          "На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами",
          "На основе технического регламента",
          "На основе рекомендаций территориальных комиссий по запасам полезных ископаемых Минприроды России",
          "На основе локального плана проведения работ",
        ],
        correctAnswers: [
          "На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами",
        ],
      },
      {
        code: "20604013",
        text: "Какой вид лицензии необходимо иметь для права подготовки планов и схем развития горных работ?",
        answers: [
          "Лицензию на детальное изучение (разведку) месторождений полезных ископаемых",
          "Лицензию на геологическое изучение недр",
          "Лицензию на добычу полезных ископаемых",
          "Лицензию на производство маркшейдерских работ",
        ],
        correctAnswers: ["Лицензию на производство маркшейдерских работ"],
      },
      {
        code: "20604014",
        text: "Кем осуществляются подготовка и утверждение планов и схем развития горных работ?",
        answers: [
          "Подготовка - пользователем недр, утверждение - руководителем территориального органа государственного горного надзора",
          "Подготовка - организацией, привлекаемой пользователем недр, утверждение - руководителем территориального органа государственного горного надзора",
          "Подготовка - пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ, утверждение - руководителем организации - пользователя недр",
          "Подготовка - организацией, привлекаемой пользователем недр, утверждение - представителем местной исполнительной власти",
        ],
        correctAnswers: [
          "Подготовка - пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ, утверждение - руководителем организации - пользователя недр",
        ],
      },
      {
        code: "20604015",
        text: "С кем необходимо согласовывать планы и схемы развития горных работ?",
        answers: [
          "Со специализированными профессиональными аварийно-спасательными формированиями",
          "Со сторонними организациями, расположенными на территории горного отвода",
          "С территориальными органами Ростехнадзора",
          "С органами исполнительной власти субъекта Российской Федерации, на территории которого эксплуатируется разрез",
        ],
        correctAnswers: ["С территориальными органами Ростехнадзора"],
      },
      {
        code: "20604016",
        text: "В какой срок пользователи недр представляют заявление о согласовании планов и схем развития горных работ в орган государственного горного надзора?",
        answers: [
          "До 1 августа текущего года",
          "До 15 августа текущего года",
          "До 1 сентября текущего года",
          "До 15 сентября текущего года",
        ],
        correctAnswers: ["До 1 сентября текущего года"],
      },
      {
        code: "20604017",
        text: "В какой срок пользователи недр письменно уведомляются органом горного надзора о времени и месте рассмотрения планов и (или) схем развития горных работ?",
        answers: [
          "Не позднее чем за 20 дней до установленной даты их рассмотрения",
          "Не позднее чем за 10 дней до установленной даты их рассмотрения",
          "Не позднее чем за 15 дней до установленной даты их рассмотрения",
          "Не позднее чем за 7 дней до установленной даты их рассмотрения",
        ],
        correctAnswers: [
          "Не позднее чем за 20 дней до установленной даты их рассмотрения",
        ],
      },
      {
        code: "20604018",
        text: "В какой срок планы и (или) схемы развития горных работ направляются пользователем недр в орган государственного горного надзора для рассмотрения?",
        answers: [
          "За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
          "За 5 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
          "За 25 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
          "За 45 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
        ],
        correctAnswers: [
          "За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
        ],
      },
      {
        code: "20604019",
        text: "Что из перечисленного не является основанием для принятия решения об отказе в согласовании плана и (или) схемы развития горных работ?",
        answers: [
          "Выявление недостоверных сведений в представленных документах",
          "Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями",
          "Отсутствие обоснования соблюдения условий безопасного недропользования",
          "Отсутствие геологического и маркшейдерского обеспечения горных работ",
        ],
        correctAnswers: [
          "Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями",
        ],
      },
      {
        code: "20604020",
        text: "Кем утверждаются план и схема развития горных работ?",
        answers: [
          "Техническим руководителем организации - пользователя недр",
          "Руководителем организации - пользователя недр",
          "Органом государственного горного надзора",
          "Органом исполнительной власти субъекта Российской Федерации",
        ],
        correctAnswers: ["Руководителем организации - пользователя недр"],
      },
      {
        code: "20604021",
        text: "В состав какой документации должен входить горноотводный акт и графические приложения к нему?",
        answers: [
          "Горноотводной документации",
          "Документации на техническое перевооружение, консервацию и ликвидацию",
          "Проектной документации",
          "Документации к обоснованию безопасности",
        ],
        correctAnswers: ["Горноотводной документации"],
      },
      {
        code: "20604022",
        text: "В какой системе координат должны быть определены уточненные границы горного отвода?",
        answers: [
          "В прямоугольной системе координат",
          "В полярной системе координат (азимут, горизонтальное положение)",
          "В географической системе координат (ширина, долгота)",
        ],
        correctAnswers: ["В прямоугольной системе координат"],
      },
      {
        code: "20604023",
        text: "Что должна содержать пояснительная записка проекта горного отвода в зависимости от видов полезных ископаемых и целей пользования недрами?",
        answers: [
          "Сведения о смежных участках недр, предоставленных в пользование, зонах с особыми условиями использования территорий в границах предоставленного в пользование участка недр (при наличии)",
          "Сведения о пользовании недрами (согласно проектной документации)",
          "Сведения об условиях в области безопасного пользования недрами, рационального использования и охраны недр, содержащихся в неотъемлемых составных частях лицензии на пользование недрами",
          "Геологическую и гидрогеологическую характеристику участка недр и горно-геологических условий, влияющих на параметры сдвижения массива горных пород и деформации объектов, установленные техническим проектом и иной документацией на выполнение работ, связанных с пользованием недрами",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20604024",
        text: "Чем необходимо руководствоваться при подготовке обоснований границ горного отвода?",
        answers: [
          "Площадью проекции горного отвода на земную поверхность, которая определяется в гектарах с точностью до одной сотой",
          "Всем перечисленным",
          "Горный отвод может состоять из нескольких отдельных блоков недр (рассредоточенный горный отвод) в случаях, когда участки ведения работ по добыче полезных ископаемых представлены отдельными участками (рудными телами, пластами) одного месторождения и (или) месторождениями полезных ископаемых (эксплуатационными объектами), находящимися в границах одного участка недр, предоставленного на основании лицензии на пользование недрами, и (или) отдельными участками ведения работ (объектами, сооружениями), связанных с пользованием недрами, предусмотренными проектной документацией и (или) для месторождений твердых полезных ископаемых по решению пользователя недр схемами развития горных работ",
          "Подземными хранилищами соляных пластов границы горного отвода, которые устанавливаются по границам геометрического тела сферической формы, увеличенным не менее чем на 2 м по вертикали и 15 м по горизонтали относительно контура камер выщелачивания",
        ],
        correctAnswers: [
          "Горный отвод может состоять из нескольких отдельных блоков недр (рассредоточенный горный отвод) в случаях, когда участки ведения работ по добыче полезных ископаемых представлены отдельными участками (рудными телами, пластами) одного месторождения и (или) месторождениями полезных ископаемых (эксплуатационными объектами), находящимися в границах одного участка недр, предоставленного на основании лицензии на пользование недрами, и (или) отдельными участками ведения работ (объектами, сооружениями), связанных с пользованием недрами, предусмотренными проектной документацией и (или) для месторождений твердых полезных ископаемых по решению пользователя недр схемами развития горных работ",
        ],
      },
      {
        code: "20604025",
        text: "При каких условиях недропользователю устанавливаются уточненные границы горного отвода?",
        answers: [
          "При одновременном получении лицензии на пользование недрами с целью разработки газовых и нефтяных месторождений",
          "При процессе разработки месторождений нефти или газа по требованию органа, выдавшего лицензию на пользование недрами",
          "После предоставления лицензии на пользование недрами, разработки документов, подтверждающих согласование проектной документации, и получения положительного заключения государственной экспертизы",
          "При ликвидации, консервации скважин и оборудования их устьев и стволов",
        ],
        correctAnswers: [
          "После предоставления лицензии на пользование недрами, разработки документов, подтверждающих согласование проектной документации, и получения положительного заключения государственной экспертизы",
        ],
      },
      {
        code: "20604026",
        text: "Какого формата должен быть план границ горного отвода, на котором должны отображаться: контуры предварительных и уточненных границ горного отвода, угловые точки границ горного отвода?",
        answers: ["А1", "А5", "А4", "А3"],
        correctAnswers: ["А4"],
      },
      {
        code: "20604027",
        text: "В каком документе в зависимости от видов полезных ископаемых и целей пользования недрами должны отображаться контуры месторождения, совмещенный (интегральный) контур поставленных на государственный баланс запасов полезных ископаемых?",
        answers: [
          "В пояснительной записке проекта горного отвода",
          "В приложении к лицензии на пользование недрами",
          "На плане горного отвода",
          "На графических материалах проекта горного отвода",
        ],
        correctAnswers: ["На плане горного отвода"],
      },
      {
        code: "20604028",
        text: "Что указывается на плане горного отвода?",
        answers: [
          "Площадь проекции горного отвода в гектарах на горизонтальную плоскость",
          "Линии вертикальных разрезов (профилей) и проекций",
          "Используемые системы координат и высот, масштаб плана, координатная сетка",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20604029",
        text: "Что должны включать графические материалы проекта горного отвода при разработке месторождений полезных ископаемых, представленных комплексом рудных тел, а также месторождений угля? Укажите все правильные ответы.",
        answers: [
          "Геологическое строение участка недр",
          "Погоризонтные планы рудных тел и пластов",
          "Гипсометрические планы рудных тел и пластов",
          "Контуры запасов полезных ископаемых по категориям геологической изученности",
          "Тектонические нарушения",
        ],
        correctAnswers: [
          "Погоризонтные планы рудных тел и пластов",
          "Гипсометрические планы рудных тел и пластов",
        ],
      },
      {
        code: "20604030",
        text: "Кем подписывается проект горного отвода (пояснительная записка)?",
        answers: [
          "Руководителем организации, испрашивающей горный отвод, и руководителем организации, разработавшей проект горного отвода",
          "Техническим руководителем организации, испрашивающей горный отвод, главным инженером проекта разработки месторождения полезного ископаемого, руководителем организации, разработавшей проект горного отвода",
          "Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода",
          "Главным геологом и главным маркшейдером организации, испрашивающей горный отвод, ответственным специалистом - разработчиком проекта разработки месторождения полезного ископаемого, руководителем организации, разработавшей проект горного отвода",
        ],
        correctAnswers: [
          "Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода",
        ],
      },
      {
        code: "20604031",
        text: "Кем подписывается горноотводная документация?",
        answers: [
          "Уполномоченными должностными лицами территориального органа или структурного подразделения органа государственного горного надзора, в ведении которых находятся вопросы государственного горного надзора",
          "Руководителем эксплуатирующей организации",
          "Главным геологом",
          "Главным маркшейдером",
        ],
        correctAnswers: [
          "Уполномоченными должностными лицами территориального органа или структурного подразделения органа государственного горного надзора, в ведении которых находятся вопросы государственного горного надзора",
        ],
      },
      {
        code: "20604032",
        text: "Что должно входить в состав планов и схем развития горных работ?",
        answers: [
          "Графическая часть и пояснительная записка с табличными материалами",
          "Геодезическая съемка объекта",
          "План-график производства работ",
          "Перечень используемого оборудования",
        ],
        correctAnswers: [
          "Графическая часть и пояснительная записка с табличными материалами",
        ],
      },
      {
        code: "20604033",
        text: "Что из перечисленного должны обеспечивать мероприятия по выполнению основных требований по безопасному ведению работ, связанных с пользованием недрами?",
        answers: [
          "Только применение средств и технологий, повышающих безопасное ведение горных работ и уровень извлечения полезных ископаемых",
          "Все перечисленное, включая ликвидацию (консервацию) отработанных горных выработок, скважин, блоков, горизонтов, объектов обустройства и иных объектов, связанных с горным производством",
          "Только рекультивацию нарушенных горными работами земель (при разработке месторождений твердых полезных ископаемых)",
          "Только защиту объектов ведения горных работ и месторождений полезных ископаемых от затопления, обводнения, взрывов, пожаров, горных ударов",
        ],
        correctAnswers: [
          "Все перечисленное, включая ликвидацию (консервацию) отработанных горных выработок, скважин, блоков, горизонтов, объектов обустройства и иных объектов, связанных с горным производством",
        ],
      },
      {
        code: "20604034",
        text: "Что устанавливается в лицензиях на пользование недрами, технических проектах и иной проектной документации на выполнение работ, связанных с пользованием участками недр?",
        answers: [
          "Дополнительные мероприятия, обеспечивающие, соблюдение требований по технологии ведения работ",
          "Дополнительные мероприятия, обеспечивающие безопасное пользование недрами",
          "Дополнительные мероприятия по прогнозированию и предупреждению опасных ситуаций с соответствующей корректировкой технических и технологических решений",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20604035",
        text: "Из каких документов должны состоять графические материалы планов (схем) развития горных работ в зависимости от видов горных работ и видов полезных ископаемых?",
        answers: [
          "Только из структурных карт, характерных разрезов, проекций, схем",
          "Только из схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ)",
          "Только из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия",
          "Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем",
        ],
        correctAnswers: [
          "Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем",
        ],
      },
      {
        code: "20604036",
        text: "В каком масштабе должны составляться графические материалы для твердых полезных ископаемых?",
        answers: [
          "Не мельче 1:15 000",
          "Не мельче 1:10 000",
          "Не мельче 1:35 000",
          "Не мельче 1:25 000",
        ],
        correctAnswers: ["Не мельче 1:10 000"],
      },
      {
        code: "20604037",
        text: "Что должны отображать графические материалы?",
        answers: [
          "Границы участка недр, установленные при предоставлении лицензии на пользование недрами (предварительные границы горных отводов)",
          "Все перечисленное",
          "Уточненные границы горного отвода",
          "Контуры горных выработок (для твердых полезных ископаемых) и объектов поверхности, включая устья скважин (кусты скважин) различного назначения",
          "Границы опасных зон и зон с особыми условиями использования территорий (при разработке месторождений твердых полезных ископаемых при наличии)",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20604038",
        text: "Какие данные не должны указываться в штампе графических материалов планов развития горных работ?",
        answers: [
          "Название графического документа",
          "Дата составления",
          "Все ответы неверны",
          "Наименования организации, объекта недропользования (участка недр)",
          "Подписи лиц (с указанием должности), ответственных за руководство геологическими и маркшейдерскими работами",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20604039",
        text: "Что не отображается на планах поверхности при составлении планов и схем развития горных работ?",
        answers: [
          "Абсолютные отметки устьев стволов и скважин, околоствольных дворов (руддворов) и горизонтов",
          "Устья выходящих на поверхность действующих, законсервированных и ликвидированных горных выработок",
          "Места образования провалов, оползней, прорыва плывунов",
          "Все перечисленное",
          "Объекты наземных и подземных коммуникаций (при наличии)",
          "Объекты застройки площади залегания полезных ископаемых (горного отвода) с выделением зданий и сооружений, подлежащих охране от вредного влияния горных работ",
        ],
        correctAnswers: [
          "Абсолютные отметки устьев стволов и скважин, околоствольных дворов (руддворов) и горизонтов",
        ],
      },
      {
        code: "20604040",
        text: "С какой периодичностью должны обновляться (пополняться) сводные планы горных работ, планы поверхности в случаях, если ситуация местности (объектовый состав) в границах горного отвода и (или) система наблюдений за состоянием горного отвода и расположенных в его границах горных выработок (скважин), зданий, сооружений и иных объектов не претерпели изменений?",
        answers: [
          "Не реже одного раза в год",
          "Не реже одного раза в 10 лет",
          "Не реже одного раза в 3 года",
          "Не реже одного раза в 5 лет",
        ],
        correctAnswers: ["Не реже одного раза в 5 лет"],
      },
      {
        code: "20604041",
        text: "Какие мероприятия должны включаться в пояснительную записку планов развития горных работ?",
        answers: [
          "Все перечисленные",
          "Консервация и (или) ликвидация горных выработок, в том числе скважин, рекультивация нарушенных горными работами земель (при разработке месторождений твердых полезных ископаемых при наличии)",
          "Организация наблюдений за состоянием горных отводов",
          "Достоверный учет количества добываемого минерального сырья в общем объеме горной массы, в том числе при маркшейдерском, весовом, метрологическом контроле (при разработке месторождений твердых полезных ископаемых)",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20604042",
        text: "Что должны включать табличные материалы пояснительной записки планов (схем) по видам горных работ и видам полезных ископаемых?",
        answers: [
          "Сведения о состоянии и движении вскрытых, подготовленных и готовых к выемке запасов полезных ископаемых по состоянию на начало планируемого периода и ожидаемого на конец этого периода (при добыче твердых полезных ископаемых)",
          "Все перечисленное",
          "Сведения о состоянии и движении фонда скважин (при разработке месторождений углеводородного сырья)",
          "Основные технические показатели на предстоящий период (план) и ожидаемые на конец текущего года (факт)",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20604043",
        text: "Чьи подписи ставятся на титульном листе пояснительной записки планов (схем) развития горных работ?",
        answers: [
          "Только лица пользователя недр, ответственного за руководство геологическими работами",
          "Только лица пользователя недр и (или) подрядной организации, ответственного за руководство горными работами",
          "Только лица пользователя недр и (или) подрядной организации, ответственного за руководство маркшейдерскими работами",
          "Только лица подрядной организации, ответственного за руководство геологическими работами",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "20604044",
        text: "Кем должны быть подписаны планы (схемы) развития горных работ, направляемые пользователем недр в электронном виде?",
        answers: [
          "Техническим руководителем пользователя недр (подрядной организации)",
          "Комиссией пользователя недр (подрядной организации)",
          "Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом",
          "Уполномоченными работниками на объекте",
        ],
        correctAnswers: [
          "Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом",
        ],
      },
      {
        code: "20604045",
        text: "В какой период осуществляется рассмотрение планов развития горных работ?",
        answers: [
          "В период с 20 августа по 25 ноября года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
          "В период с 20 октября по 20 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
          "В период с 10 сентября по 10 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
          "В период с 20 сентября по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
        ],
        correctAnswers: [
          "В период с 20 сентября по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
        ],
      },
      {
        code: "20604046",
        text: "Что является основой классификации на аварии и инциденты происшествий, происходящих на опасных производственных объектах угольной промышленности?",
        answers: [
          "Место происшествия",
          "Вид происшествия",
          "Все перечисленное",
          "Размер экономического ущерба, причиненный происшествием предприятию, выраженный в длительности простоя оборудования",
        ],
        correctAnswers: [
          "Размер экономического ущерба, причиненный происшествием предприятию, выраженный в длительности простоя оборудования",
        ],
      },
      {
        code: "20604047",
        text: "Как квалифицируются происшествия на опасных производственных объектах, сопровождающиеся несчастными (групповыми) случаями?",
        answers: [
          "Происшедшие несчастные случаи не влияют на классификацию, предусмотренную в перечне аварий и в перечне инцидентов",
          "В случае происшествий, сопровождающихся несчастными (групповыми) случаями, их следует квалифицировать как аварии или инцидент, на основании решения технического руководства организации",
          "В случае происшествий, сопровождающихся несчастными (групповыми) случаями, их следует квалифицировать как аварии или инцидент, на основании указания Ростехнадзора",
          "В случае происшествий, сопровождающихся несчастными (групповыми) случаями, их следует квалифицировать как аварии",
        ],
        correctAnswers: [
          "В случае происшествий, сопровождающихся несчастными (групповыми) случаями, их следует квалифицировать как аварии",
        ],
      },
      {
        code: "20604048",
        text: "Какие визуальные и инструментальные наблюдения должны проводиться на объектах ведения открытых горных работ?",
        answers: [
          "За поверхностной эрозией песчано-глинистых рыхлых пород, слагающих борта карьеров",
          "За состоянием бортов, уступов и откосов",
          "За закарстованностью месторождения",
          "За проявлениями удароопасности горных пород, вмещающих полезное ископаемое",
        ],
        correctAnswers: ["За состоянием бортов, уступов и откосов"],
      },
      {
        code: "20604049",
        text: "За чем должны проводиться визуальные и инструментальные наблюдения в процессе эксплуатации объектов ведения открытых горных работ для своевременного выявления опасных зон и прогнозирования опасных ситуаций?",
        answers: [
          "За поверхностной эрозией песчано-глинистых рыхлых пород, слагающих борта карьеров",
          "За проявлениями удароопасности горных пород, вмещающих полезное ископаемое",
          "За устойчивостью бортов, уступов, откосов",
          "За закарстованностью месторождения",
        ],
        correctAnswers: ["За устойчивостью бортов, уступов, откосов"],
      },
      {
        code: "20604050",
        text: "Кем и с какой периодичностью должно проводиться визуальное обследование состояния откосов на карьерах?",
        answers: [
          "Главным инженером карьера ежеквартально",
          "Комиссией в составе главного инженера карьера и главного маркшейдера карьера раз в полугодие",
          "Главным маркшейдером или главным геологом не реже одного раза в полугодие",
          "Специалистами геологической и маркшейдерской служб не реже одного раза в месяц",
        ],
        correctAnswers: [
          "Специалистами геологической и маркшейдерской служб не реже одного раза в месяц",
        ],
      },
      {
        code: "20604051",
        text: "Что является коэффициентом запаса устойчивости при детерминированном подходе?",
        answers: [
          "Допустимая вероятность развития деформаций, которые определяются проектной документацией с учетом размещения элементов инфраструктуры объекта недропользования",
          "Отношение удерживающих и сдвигающих сил на сформировавшейся поверхности скольжения",
          "Действие удерживающих и сдвигающих сил, перенесенное на потенциальную (наиболее напряженную) поверхность скольжения во всех инженерных методах",
        ],
        correctAnswers: [
          "Отношение удерживающих и сдвигающих сил на сформировавшейся поверхности скольжения",
        ],
      },
      {
        code: "20604052",
        text: "В какой документ должны включаться мероприятия при обнаружении признаков нарушения устойчивости или критических деформаций?",
        answers: [
          "В планы развития горных работ на предстоящий календарный период",
          "В журнал осмотра",
          "В пояснительную записку с фотографией деформации",
          "В отчет о проведенных работах",
        ],
        correctAnswers: [
          "В планы развития горных работ на предстоящий календарный период",
        ],
      },
      {
        code: "20604053",
        text: "Что должно быть изучено в результате инженерно-геологических и гидрогеологических исследований?",
        answers: [
          "Физические свойства складируемых пород, прочность их в куске",
          "Сдвиговые характеристики отвальной массы",
          "Строение основания (наклон основания, наличие прослоев слабых пород и их мощность, наличие водоносных горизонтов, возможность образования техногенных водоносных горизонтов в нижней части отвала и в породах основания)",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20604054",
        text: "На каких участках возможных деформаций выполняют закладку наблюдательных станций?",
        answers: [
          "На участках слабых пластичных, обводненных или сильно трещиноватых пород в горном массиве, формирующих борт, уступ или отвал",
          "На участках слабых контактов, поверхностей тектонических нарушений, имеющих наклон в сторону выработанного пространства и простирание, близкое к простиранию борта",
          "На всех перечисленных",
        ],
        correctAnswers: ["На всех перечисленных"],
      },
      {
        code: "20604055",
        text: "В каком случае допускается подработка железных дорог и транспортных сооружений при деформациях земной поверхности, превышающих предельные по Правилам охраны сооружений и природных объектов от вредного влияния подземных горных разработок на угольных месторождениях?",
        answers: [
          "В случае применения горных мер защиты",
          "Подработка не допускается",
          "Подработка производится без ограничений",
          'В случае согласования с ОАО "РЖД"',
        ],
        correctAnswers: ["В случае применения горных мер защиты"],
      },
      {
        code: "20604056",
        text: "Что должен содержать проект мер охраны участков железных дорог общего пользования, намечаемых к подработке?",
        answers: [
          "Акт обследования состояния объектов комиссией",
          "Выкопировки с планов горных работ с нанесением проекта развития горных работ в зоне влияния на железную дорогу",
          "Пояснительную записку с обоснованием выбранных мер охраны",
          "План наблюдательной станции",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20604057",
        text: "В течение какого срока управление железной дороги со дня получения от горного предприятия проекта мер охраны должно направить последнему справку о согласии с выбранными мерами или мотивированное возражение?",
        answers: [
          "В течение 45 дней",
          "В течение 30 дней",
          "В течение 20 дней",
          "В течение 15 дней",
        ],
        correctAnswers: ["В течение 20 дней"],
      },
      {
        code: "20604058",
        text: "В течение какого срока решение об утверждении мер охраны предприятием по добыче угля (сланца) сообщается Управлению железной дороги?",
        answers: [
          "В течение 10 дней",
          "В течение 15 дней",
          "В течение 20 дней",
          "В течение 25 дней",
        ],
        correctAnswers: ["В течение 10 дней"],
      },
      {
        code: "20604059",
        text: "На кого возлагается ответственность за правильность, своевременное оформление и осуществление проекта подработки железной дороги и транспортных сооружений в части мероприятий, осуществляемых эксплуатирующей организацией? Укажите все правильные ответы.",
        answers: [
          "На главного инженера",
          "На главного маркшейдера",
          "На заместителя начальника дороги по региону",
          "На ответственного за осуществление производственного контроля",
          "На главного механика",
        ],
        correctAnswers: ["На главного инженера", "На главного маркшейдера"],
      },
      {
        code: "20604060",
        text: "На какую деятельность на опасных производственных объектах юридических лиц и индивидуальных предпринимателей распространяются Правила безопасности при разработке угольных месторождений открытым способом?",
        answers: [
          "Только на деятельность, связанную с разработкой угольных месторождений открытым способом",
          "Только на конструирование, изготовление, монтаж, эксплуатацию и ремонт технических устройств",
          "Только на проектирование, строительство и эксплуатацию угольного разреза",
          "На все перечисленное",
        ],
        correctAnswers: ["На все перечисленное"],
      },
      {
        code: "20604061",
        text: "На что направлены требования Правил безопасности при разработке угольных месторождений открытым способом?",
        answers: [
          "На все перечисленное",
          "Только на предупреждение аварий и инцидентов на угольных разрезах",
          "Только на обеспечение готовности к локализации и ликвидации последствий аварий",
          "Только на обеспечение промышленной безопасности",
        ],
        correctAnswers: ["На все перечисленное"],
      },
      {
        code: "20604062",
        text: "Что из перечисленного является обязательными условиями принятия решения о консервации и ликвидации разреза? Выберите два правильных варианта ответа.",
        answers: [
          "Наличие проектной документации на консервацию и ликвидацию разреза",
          "Наличие положительного заключения экспертизы промышленной безопасности",
          "Наличие договоров с подрядными организациями",
          "Наличие согласованного и утвержденного плана работ",
        ],
        correctAnswers: [
          "Наличие проектной документации на консервацию и ликвидацию разреза",
          "Наличие положительного заключения экспертизы промышленной безопасности",
        ],
      },
      {
        code: "20604063",
        text: "Куда должен быть направлен план реализации мероприятий, обосновывающих и обеспечивающих безопасную эксплуатацию угольного разреза в соответствии с требованиями Правил безопасности при разработке угольных месторождений открытым способом, до их реализации?",
        answers: [
          "В территориальный орган Ростехнадзора",
          "Руководителю угольного разреза",
          "В профессиональные аварийно-спасательные службы и (или) профессиональные аварийно-спасательные формирования",
          "В органы местного самоуправления, на территории которых располагается разрез",
        ],
        correctAnswers: ["В территориальный орган Ростехнадзора"],
      },
      {
        code: "20604064",
        text: "Кто разрабатывает мероприятия, обосновывающие и обеспечивающие безопасную эксплуатацию угольного разреза до приведения действующего угольного разреза в соответствие с требованиями Правил безопасности при разработке угольных месторождений открытым способом?",
        answers: [
          "Руководитель угольного разреза",
          "Технический руководитель (главный инженер) угольного разреза",
          "Главный механик угольного разреза",
          "Главный энергетик угольного разреза",
        ],
        correctAnswers: [
          "Технический руководитель (главный инженер) угольного разреза",
        ],
      },
      {
        code: "20604065",
        text: "Кто устанавливает сроки приведения действующего угольного разреза в соответствие с требованиями Правил безопасности при разработке угольных месторождений открытым способом? Выберите все варианты ответов.",
        answers: [
          "Представитель территориального органа Ростехнадзора",
          "Представитель органа местного самоуправления, на территории которого располагается разрез",
          "Технический руководитель (главный инженер) угольного разреза",
          "Руководитель угольного разреза",
        ],
        correctAnswers: [
          "Технический руководитель (главный инженер) угольного разреза",
          "Руководитель угольного разреза",
        ],
      },
      {
        code: "20604066",
        text: "С какой периодичностью должно осматриваться каждое рабочее место начальником участка или его заместителем?",
        answers: [
          "Один раз в 2 дня",
          "Один раз в 2 недели",
          "В течение смены",
          "В течение суток",
        ],
        correctAnswers: ["В течение суток"],
      },
      {
        code: "20604067",
        text: "С каким максимальным углом наклона должны устраиваться съезды, предназначенные для сообщения между уступами угольного разреза?",
        answers: ["15°", "20°", "10°", "25°", "30°"],
        correctAnswers: ["20°"],
      },
      {
        code: "20604068",
        text: "Какой минимальной ширины должны быть маршевые лестницы, устраиваемые на высоте более 10 м для сообщения между уступами разреза?",
        answers: ["0,6 м", "0,7 м", "0,8 м", "0,9 м", "1,0 м"],
        correctAnswers: ["0,8 м"],
      },
      {
        code: "20604069",
        text: "Какой должна быть минимальная ширина переходных мостиков через ленточные конвейеры?",
        answers: ["0,8 м", "1,2 м", "1,0 м", "0,7 м"],
        correctAnswers: ["0,8 м"],
      },
      {
        code: "20604070",
        text: "Какой должна быть минимальная высота перил переходных мостиков через ленточные конвейеры?",
        answers: ["0,7 м", "0,8 м", "1,2 м", "1,1 м"],
        correctAnswers: ["1,1 м"],
      },
      {
        code: "20604071",
        text: "Какое из приведенных положений требований безопасности указано неверно?",
        answers: [
          "При невозможности вывода техники в случае угрозы ее повреждения техника должна быть ограждена и установлены знаки, предупреждающие об опасности",
          "На угольном разрезе запрещается нахождение людей в опасной зоне работающих механизмов",
          "При остановке работ на угольном разрезе запрещается нахождение на его территории лиц, не связанных с обеспечением его жизнедеятельности",
        ],
        correctAnswers: [
          "При невозможности вывода техники в случае угрозы ее повреждения техника должна быть ограждена и установлены знаки, предупреждающие об опасности",
        ],
      },
      {
        code: "20604072",
        text: "С кем согласовываются графики проведения учебных тревог на угольном разрезе?",
        answers: [
          "С руководителем профсоюзной организации угольного разреза",
          "С руководителем органа местного самоуправления, на территории которого располагается угольный разрез",
          "С руководителем подразделения ПАСС(Ф), обслуживающего угольный разрез",
          "С руководителем территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "С руководителем подразделения ПАСС(Ф), обслуживающего угольный разрез",
        ],
      },
      {
        code: "20604073",
        text: "В соответствии с каким документом должны осуществляться горные работы по проведению траншей, разработке уступов, отсыпке отвалов?",
        answers: [
          "В соответствии с локальным проектом производства работ (паспортом), утвержденным техническим руководителем разреза",
          "В соответствии с утвержденной техническим руководителем (главным инженером) угольного разреза документацией на производство работ",
          "В соответствии с планом работ по разработке разрезов, согласованным с аварийно-спасательными формированиями",
          "В соответствии с утвержденным планом производства работ, согласованным с органом исполнительной власти муниципального образования, на территории которого эксплуатируется разрез",
          "В соответствии с планом по разработке разрезов, согласованным с Ростехнадзором",
        ],
        correctAnswers: [
          "В соответствии с утвержденной техническим руководителем (главным инженером) угольного разреза документацией на производство работ",
        ],
      },
      {
        code: "20604074",
        text: "Что не учитывается проектом при определении высоты уступа разреза?",
        answers: [
          "Квалификация персонала угольного разреза",
          "Горно-геологические условия залегания горных пород и угля",
          "Параметры оборудования",
          "Результаты исследований физико-механических свойств вмещающих горных пород и угля",
        ],
        correctAnswers: ["Квалификация персонала угольного разреза"],
      },
      {
        code: "20604075",
        text: "Какой должна быть максимальная высота уступа при разработке вручную рыхлых устойчивых плотных пород?",
        answers: ["3 м", "5 м", "6 м", "7 м", "4 м"],
        correctAnswers: ["6 м"],
      },
      {
        code: "20604076",
        text: "Какой должна быть максимальная высота уступа при разработке вручную рыхлых неустойчивых пород?",
        answers: ["3 м", "4 м", "5 м", "7 м"],
        correctAnswers: ["3 м"],
      },
      {
        code: "20604077",
        text: "Что из перечисленного допускается при разработке пород с применением буровзрывных работ при условии, что высота забоя по развалу не превышает максимальную высоту черпания экскаватора?",
        answers: [
          "Только разработка мероприятий по безопасному обрушению козырьков и нависей",
          "Только увеличение высоты уступа до полуторной высоты черпания экскаватора",
          "Только разделение развала по высоте на слои (подуступы)",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20604078",
        text: "По каким данным могут быть скорректированы в процессе эксплуатации предельные углы откосов уступов и бортов угольного разреза (углы устойчивого борта), в том числе временно консервируемых участков бортов? Выберите два правильных варианта ответа.",
        answers: [
          "По результатам дополнительных изысканий инженерно-геологических условий массива борта угольного разреза и отвала",
          "По требованиям дополнений к технической документации ведения горных работ на угольных разрезах, если это не выполнено в проекте на отработку месторождения",
          "По данным геолого-маркшейдерской службы угольного разреза",
          "По данным планов горных работ (в масштабе 1:5000 или 1:2000)",
        ],
        correctAnswers: [
          "По результатам дополнительных изысканий инженерно-геологических условий массива борта угольного разреза и отвала",
          "По данным геолого-маркшейдерской службы угольного разреза",
        ],
      },
      {
        code: "20604079",
        text: "Каким документом определяется ширина рабочих площадок с учетом их назначения, а также расположения на них горнотранспортного оборудования, транспортных коммуникаций, линий электроснабжения и связи?",
        answers: [
          "Техническим проектом разработки месторождения и уточняется в документации на производство работ",
          "Локальным проектом, утвержденным техническим руководителем (главным инженером) угольного разреза",
          "Планом производства работ на объекте угольного разреза",
        ],
        correctAnswers: [
          "Техническим проектом разработки месторождения и уточняется в документации на производство работ",
        ],
      },
      {
        code: "20604080",
        text: "Какое из требований к ведению горных работ по проведению траншей, разработке уступов и отсыпке отвалов указано неверно?",
        answers: [
          "В процессе эксплуатации параметры уступов и предохранительных берм должны уточняться по результатам исследований физико-механических свойств горных пород и должны быть не менее установленных техническим проектом разработки месторождения",
          "Формирование временно нерабочих бортов угольного разреза и возобновление горных работ на них должны проводиться в соответствии с техническим проектом разработки месторождения и по документации на производство работ, предусматривающими необходимые меры безопасности",
          "Расстояние между смежными бермами при погашении уступов и постановке их в предельное положение, ширина, конструкция и порядок обслуживания предохранительных берм должны быть определены в техническом проекте разработки месторождения с учетом обеспечения устойчивости конструкции борта угольного разреза, безопасной механизированной их очистки",
          "Расстояние между нижними бровками откосов уступа отвала до оси железнодорожного пути или оси конвейера устанавливается проектом и должно быть не менее 6 м",
        ],
        correctAnswers: [
          "Расстояние между нижними бровками откосов уступа отвала до оси железнодорожного пути или оси конвейера устанавливается проектом и должно быть не менее 6 м",
        ],
      },
      {
        code: "20604081",
        text: "Какое из перечисленных требований безопасности предъявляется к выполнению работ по ручной оборке уступов от нависей и козырьков?",
        answers: [
          "Ручная оборка допускается при наличии распоряжения под непосредственным наблюдением руководителя смены или бригадира",
          "Ручная оборка уступов не допускается",
          "Ручная оборка допускается по наряду-допуску под непосредственным наблюдением руководителя смены или бригадира",
          "Ручная оборка уступов проводится в дневное время с применением соответствующих мер безопасности под непосредственным наблюдением руководителя смены или бригадира",
        ],
        correctAnswers: [
          "Ручная оборка допускается по наряду-допуску под непосредственным наблюдением руководителя смены или бригадира",
        ],
      },
      {
        code: "20604082",
        text: "Какое минимальное расстояние по горизонтали должно быть между рабочими местами, расположенными на двух смежных по вертикали уступах при ручной разработке?",
        answers: ["10 м", "5 м", "12 м", "3 м", "7 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "20604083",
        text: "Каким должно быть минимальное расстояние по горизонтали между рабочими местами или механизмами, расположенными на двух смежных по вертикали уступах при экскаваторной разработке?",
        answers: [
          "Равным полуторной сумме максимальных радиусов черпания",
          "Равным двойной сумме максимальных радиусов черпания",
          "Равным утроенной сумме максимальных радиусов черпания",
          "Равным максимальному радиусу черпания",
        ],
        correctAnswers: [
          "Равным полуторной сумме максимальных радиусов черпания",
        ],
      },
      {
        code: "20604084",
        text: "По какому из перечисленных проектов следует проводить горные работы вблизи затопленных выработок или водоемов ниже зеркала воды при их максимальном наполнении?",
        answers: [
          "По проекту, предусматривающему сооружение вокруг затопленных выработок или водоемов обваловки",
          "По техническому проекту разработки месторождения и нормативным требованиям к данным условиям разработки с учетом оставления целиков для предотвращения прорыва воды",
          "По проекту, предусматривающему установку откачивающих насосов достаточной производительности",
          "По проекту, предусматривающему сооружение в пониженных местах дамб",
        ],
        correctAnswers: [
          "По техническому проекту разработки месторождения и нормативным требованиям к данным условиям разработки с учетом оставления целиков для предотвращения прорыва воды",
        ],
      },
      {
        code: "20604085",
        text: "Что из перечисленного должно быть обозначено предупредительными знаками, ограждениями или предохранительными валами?",
        answers: [
          "Только участки высокого борта и горные выработки с признаками деформации",
          "Только водоемы и затопленные выработки",
          "Только очаги самонагревания породных отвалов",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20604086",
        text: "Что из перечисленного используется для обозначения границ опасных зон на местности в процессе ведения горных работ на разрезе?",
        answers: [
          "Только предохранительные валы",
          "Только предупредительные знаки",
          "Только ограждения",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20604087",
        text: "Какая из перечисленных зон при ведении открытых горных работ не должна относиться к зонам, опасным по геомеханическим условиям?",
        answers: [
          "Участок экзогенных пожаров",
          "Участок борта, нагруженный отвалами, размещенными в пределах призмы возможного обрушения",
          "Участок повышенной водообильности бортов и отвалов, сложенных мягкими связными и твердыми глинистыми, а также рыхлыми несвязными или слабосцементированными породами",
          "Участок бортов разреза и откосов отвалов, на которых обнаружены признаки деформаций (трещины, заколы или просадки)",
          "Горный массив с наклонным и пологим залеганием слоистости в сторону выработанного пространства с тектоническими трещинами в призме возможного обрушения, секущих уступ протяженностью более 0,25 - 0,30 высоты уступа или ослабленных поверхностей",
        ],
        correctAnswers: ["Участок экзогенных пожаров"],
      },
      {
        code: "20604088",
        text: "Какие из перечисленных участков ведения открытых горных работ должны относиться к зонам, опасным по геомеханическим условиям?",
        answers: [
          "Только участок повышенной водообильности бортов и отвалов, сложенных мягкими связными и твердыми глинистыми, а также рыхлыми несвязными или слабосцементированными породами",
          "Только участок борта, нагруженный отвалами, размещенными в пределах призмы возможного обрушения",
          "Только участок бортов разреза и откосов отвалов, на которых обнаружены признаки деформаций (трещины, заколы или просадки)",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20604089",
        text: "На каком минимальном безопасном расстоянии от верхней бровки уступа, определяемом расчетами или проектом, до ближайшей точки опоры станка на спланированной площадке должен устанавливаться буровой станок?",
        answers: ["5 м", "3 м", "2 м", "1,5 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "20604090",
        text: "Под каким углом к бровке уступа должна быть продольная ось бурового станка при бурении первого ряда скважин?",
        answers: ["60º", "90º", "45º", "35º"],
        correctAnswers: ["90º"],
      },
      {
        code: "20604091",
        text: "Что не соответствует требованиям по выбору и контролю за состоянием подъемного каната бурового станка?",
        answers: [
          "Не реже одного раза в семь дней механик участка или другое назначенное лицо должен проводить наружный осмотр каната и делать запись о результатах осмотра в книге приема-передачи смен или книге осмотра канатов",
          "Выступающие концы проволок должны быть обрезаны",
          "Подъемный канат бурового станка должен рассчитываться на максимальную нагрузку и иметь трехкратный запас прочности",
          "При наличии в подъемном канате более 10% порванных проволок на длине шага свивки его следует заменить",
        ],
        correctAnswers: [
          "Подъемный канат бурового станка должен рассчитываться на максимальную нагрузку и иметь трехкратный запас прочности",
        ],
      },
      {
        code: "20604092",
        text: "Какой должна быть минимальная ширина рабочей бермы при бурении шпуров перфораторами и электросверлами?",
        answers: ["4,5 м", "4,0 м", "3,0 м", "3,5 м"],
        correctAnswers: ["4,0 м"],
      },
      {
        code: "20604093",
        text: "Что из перечисленного влияет на результаты расчетов, устанавливающих проектные высоту отвала и отвальных ярусов, углы откоса и призмы возможного обрушения, скорость продвижения фронта отвальных работ?",
        answers: [
          "Рельеф местности и несущая способность нагруженных отвалов",
          "Способы отвалообразования",
          "Все перечисленное",
          "Физико-механические свойства пород отвала и его основания",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20604094",
        text: "Каким должно быть превышение гребня гидроотвала у верхового откоса над пляжем при выпуске пульпы на пляж для исключения перелива на гребень и низовой откос дамбы?",
        answers: [
          "Не менее диаметра пульповыпуска, но не менее 0,5 м",
          "Не менее диаметра пульповыпуска, но не менее 1 м",
          "Не менее диаметра пульповыпуска, но не менее 1,5 м",
          "Равным диаметру пульповыпуска",
        ],
        correctAnswers: ["Не менее диаметра пульповыпуска, но не менее 0,5 м"],
      },
      {
        code: "20604095",
        text: "Какой должна быть длина надводного пляжа в течение всего срока эксплуатации намывных гидроотвалов?",
        answers: [
          "Не менее 30 м",
          "Должна соответствовать проекту",
          "Не менее 50 м",
          "Должна определяться опытным путем",
          "Не менее 40 м",
        ],
        correctAnswers: ["Должна соответствовать проекту"],
      },
      {
        code: "20604096",
        text: "Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом по организации обслуживания намыва гидроотвала?",
        answers: [
          "Подход к вымоинам, провалам или воронкам, образовавшимся на гидроотвале, а также хождение по льду пруда-отстойника должны осуществляться с особыми мерами предосторожности",
          "Для обеспечения безаварийной работы должны проводиться натурные наблюдения и инструментальный контроль с использованием контрольно-измерительной аппаратуры",
          "Участки намытого гидроотвала должны быть ограждены, и на них установлены предупредительные плакаты и знаки",
          "Для обслуживания намыва гидроотвала устраивают мостики с перилами",
        ],
        correctAnswers: [
          "Подход к вымоинам, провалам или воронкам, образовавшимся на гидроотвале, а также хождение по льду пруда-отстойника должны осуществляться с особыми мерами предосторожности",
        ],
      },
      {
        code: "20604097",
        text: "Кто утверждает мероприятия по своевременному обнаружению очагов самонагревания и самовозгорания угля, по профилактике и тушению пожаров на угольных разрезах, разрабатывающих пласты угля, склонные к самовозгоранию?",
        answers: [
          "Представитель органов исполнительной власти муниципального образования, на территории которого находится разрез",
          "Руководитель территориального органа Ростехнадзора",
          "Технический руководитель (главный инженер) угольного разреза",
          "Командование военизированной горноспасательной части",
          "Командир пожарной части, на территории обслуживания которой находится разрез",
        ],
        correctAnswers: [
          "Технический руководитель (главный инженер) угольного разреза",
        ],
      },
      {
        code: "20604098",
        text: "Какие из перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом по профилактике эндогенных пожаров указаны верно?",
        answers: [
          "Способы замера температуры, концентрации угарного газа должны определяться инспектором Ростехнадзора",
          "На угольных разрезах, разрабатывающих пласты угля, склонные к самовозгоранию, должны выполняться мероприятия по своевременному обнаружению очагов самонагревания и самовозгорания угля, профилактике и тушению пожаров, утвержденные техническим руководителем (главным инженером) угольного разреза",
          "Способы обнаружения очагов пожаров и наблюдений за внешними признаками самонагревания угля должны определяться представителем пожарной части",
          "Места установки датчиков должны определяться представителем подразделения специализированного профессионального аварийно-спасательного формирования",
          "При обнаружении признаков самонагревания угля должны быть приняты меры, предусмотренные планом по профилактике и тушению экзогенных пожаров на угольном разрезе",
        ],
        correctAnswers: [
          "На угольных разрезах, разрабатывающих пласты угля, склонные к самовозгоранию, должны выполняться мероприятия по своевременному обнаружению очагов самонагревания и самовозгорания угля, профилактике и тушению пожаров, утвержденные техническим руководителем (главным инженером) угольного разреза",
        ],
      },
      {
        code: "20604099",
        text: "Что из перечисленного не подлежит профилактической обработке антипирогенами?",
        answers: [
          "Угольные и породно-угольные скопления небольших объемов, оставляемые в разрезе в качестве автотракторных съездов",
          "Угольные и породно-угольные уступы в зоне геологических нарушений или в местах сосредоточения породно-угольных скоплений, сроки отгрузки которых не превышают инкубационный период",
          "Угольные уступы по рабочему борту, имеющие геологические нарушения или нарушения от взрывных работ, со сроком отработки, превышающим продолжительность инкубационного периода их самовозгорания",
          "Угольные и породно-угольные скопления небольших объемов, оставляемые в разрезе в качестве насыпей под временные железнодорожные пути и конвейерные линии",
        ],
        correctAnswers: [
          "Угольные и породно-угольные уступы в зоне геологических нарушений или в местах сосредоточения породно-угольных скоплений, сроки отгрузки которых не превышают инкубационный период",
        ],
      },
      {
        code: "20604100",
        text: "Какие из перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом указаны верно?",
        answers: [
          "К управлению горным и транспортным оборудованием с электрическим приводом, связанному с обслуживанием, оперативным включением и отключением электроустановок, допускаются только машинисты и помощники машинистов после присвоения им соответствующих квалификационных групп по электробезопасности",
          "При временном переводе машинистов и помощников машинистов на другое горное оборудование обслуживание данного оборудования допускается после ознакомления со схемой электроснабжения эксплуатируемого оборудования под подпись",
          "Оперативные переключения кабельных линий в пределах закрепленной за машинистом и помощником машиниста горнотранспортной машины и ее переключательного пункта должны проводиться по наряду-допуску",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20604101",
        text: "Кто из перечисленных лиц назначает комиссию по приемке в эксплуатацию строительно-дорожных машин, горнотранспортного и технологического оборудования после монтажа?",
        answers: [
          "Представитель государственной инспекции труда",
          "Командир специализированного профессионального аварийно-спасательного формирования",
          "Технический руководитель (главный инженер) угольного разреза",
          "Руководитель территориального органа Ростехнадзора",
          "Представитель исполнительной власти муниципального образования, на территории которого находится разрез",
        ],
        correctAnswers: [
          "Технический руководитель (главный инженер) угольного разреза",
        ],
      },
      {
        code: "20604102",
        text: "С какой периодичностью должна проверяться исправность и комплектность машин механиком, энергетиком участка?",
        answers: [
          "Один раз в квартал",
          "Один раз в 2 недели",
          "Еженедельно",
          "Один раз в месяц",
        ],
        correctAnswers: ["Еженедельно"],
      },
      {
        code: "20604103",
        text: "С какой периодичностью должна проверяться исправность и комплектность машин машинистом (оператором, водителем)?",
        answers: [
          "Ежесменно",
          "Один раз в полугодие",
          "Ежемесячно",
          "Один раз в квартал",
          "Один раз в 2 месяца",
        ],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "20604104",
        text: "Что из перечисленного соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к эксплуатации и обслуживанию технологического оборудования, технических устройств, машин и механизмов, используемых на разрезе?",
        answers: [
          "Технические устройства, в том числе иностранного производства, должны иметь заводские паспорта, руководства (инструкции) по монтажу, эксплуатации, техническому обслуживанию и ремонту, оформленные на русском языке с указанием метрической системы измерений",
          "Эксплуатация, обслуживание технологического оборудования, технических устройств, транспортирование и хранение машин и оборудования, их узлов и деталей, а также их монтаж и демонтаж должны производиться в соответствии с документацией организации-изготовителя и технологическими картами, утвержденными техническим руководителем (главным инженером) угольного разреза",
          "Нормируемые организациями - изготовителями технические характеристики должны выдерживаться на протяжении всего периода эксплуатации оборудования",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20604105",
        text: "Каким должно быть расстояние между концом консольного ленточного отвалообразователя с периодическим перемещением и гребнем отвала?",
        answers: [
          "Не менее 1,0 м",
          "Не менее 0,7 м",
          "Не менее 0,5 м",
          "Не менее 1,5 м",
        ],
        correctAnswers: ["Не менее 1,5 м"],
      },
      {
        code: "20604106",
        text: "Каким должно быть расстояние между концом отвальной консоли транспортно-отвального моста и гребнем отвала?",
        answers: [
          "Не менее 1,5 м",
          "Не менее 1,0 м",
          "Не менее 3,0 м",
          "Не менее 2,0 м",
        ],
        correctAnswers: ["Не менее 3,0 м"],
      },
      {
        code: "20604107",
        text: "Каким должно быть расстояние от гидромониторной установки и другого забойного оборудования (скреперов, бульдозеров) до забоя при гидромеханизированном способе разработки глинистых, плотных и лессовидных пород, способных к обрушению глыбами?",
        answers: [
          "Не менее 1,2 высоты уступа",
          "Не менее 0,8 высоты уступа",
          "Не менее 1,0 высоты уступа",
          "Не менее 0,5 высоты уступа",
        ],
        correctAnswers: ["Не менее 1,2 высоты уступа"],
      },
      {
        code: "20604108",
        text: "Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом перед началом работы гидромонитора?",
        answers: [
          "Территория участка на расстоянии не менее дальности действия струи гидромонитора должна ограждаться предупредительными знаками",
          "С места работы гидромонитора должны быть удалены лица, не имеющие отношения к его работе",
          "Из зоны действия струи гидромонитора должны быть удалены все люди",
          "Во время пуска выходное отверстие гидромонитора должно быть всегда направлено в безопасное для окружающих место",
        ],
        correctAnswers: [
          "Территория участка на расстоянии не менее дальности действия струи гидромонитора должна ограждаться предупредительными знаками",
        ],
      },
      {
        code: "20604109",
        text: "Что из перечисленного не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом с использованием гидромеханизированного способа разработки?",
        answers: [
          "Углы откоса уступов отработанных угольных разрезов не должны превышать углов естественного откоса пород",
          "При работе гидромониторов навстречу друг другу работу одного из них следует остановить при сближении на расстояние дальности полета максимальной струи более мощного гидромонитора",
          "Проведение в забое вспомогательных работ допускается только после осмотра забоя, ликвидации козырьков и нависей",
          "Зумпфы землесосов и гидроэлеваторов должны быть закрыты или ограждены в целях исключения падения людей в зумпф",
        ],
        correctAnswers: [
          "При работе гидромониторов навстречу друг другу работу одного из них следует остановить при сближении на расстояние дальности полета максимальной струи более мощного гидромонитора",
        ],
      },
      {
        code: "20604110",
        text: "На каком расстоянии от воздушных линий должен быть расположен гидромонитор без разработки и осуществления мероприятий, обеспечивающих безопасность работ, утвержденных техническим руководителем (главным инженером) угольного разреза?",
        answers: [
          "На расстоянии не менее двукратной дальности полета струи",
          "На расстоянии не менее полуторной дальности полета струи",
          "На расстоянии не менее однократной дальности полета струи",
          "На расстоянии не менее трехкратной дальности полета струи",
        ],
        correctAnswers: [
          "На расстоянии не менее двукратной дальности полета струи",
        ],
      },
      {
        code: "20604111",
        text: "На каком минимальном расстоянии от воздушных линий электропередачи (ЛЭП) и линий связи разрешается укладывать пульпопроводы и водоводы без согласования с владельцами коммуникаций и организациями, осуществляющими контроль за их безопасной эксплуатацией?",
        answers: [
          "На расстоянии 10 м",
          "На расстоянии 15 м",
          "На расстоянии 20 м",
          "На расстоянии 25 м",
        ],
        correctAnswers: ["На расстоянии 25 м"],
      },
      {
        code: "20604112",
        text: "Как должны быть устроены мостки для обслуживания трубопровода и водопроводных лотков, уложенных на эстакадах?",
        answers: [
          "Мостки должны быть шириной 0,5 м, огражденные перилами высотой 1,2 м и обшитые снизу на высоту не менее 0,2 м",
          "Мостки должны быть шириной 0,5 м, огражденные перилами высотой 1,1 м и обшитые снизу на высоту не менее 0,1 м",
          "Мостки должны быть шириной 0,6 м, огражденные перилами высотой 1,0 м и обшитые снизу на высоту не менее 0,1 м",
          "Мостки должны быть шириной 0,4 м, огражденные перилами высотой 1,3 м и обшитые снизу на высоту не менее 0,25 м",
        ],
        correctAnswers: [
          "Мостки должны быть шириной 0,5 м, огражденные перилами высотой 1,2 м и обшитые снизу на высоту не менее 0,2 м",
        ],
      },
      {
        code: "20604113",
        text: "Что из перечисленного соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к прокладке трубопроводов?",
        answers: [
          "Трубопроводы укладываются на выровненном основании на подкладках",
          "При прокладке по откосу уступа или борту разреза трубопровод должен быть закреплен анкерами не реже чем через каждые 35 - 40 м по высоте",
          "На каждом прямолинейном участке трубопровода необходимо не реже чем через 750 м устанавливать компенсатор",
          "Все перечисленное",
        ],
        correctAnswers: [
          "Трубопроводы укладываются на выровненном основании на подкладках",
        ],
      },
      {
        code: "20604114",
        text: "Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом по созданию безопасных условий труда при эксплуатации водосбросных и водосборных сооружений?",
        answers: [
          "Во вновь строящихся помещениях между насосами и землесосными агрегатами, а также между ними и стенками помещения следует предусматривать проходы шириной не менее 0,75 м",
          "На землесосных установках пульповоды и водоводы должны быть оборудованы обратными клапанами",
          "Все водосбросные и водосборные сооружения гидроотвалов должны быть рассчитаны на максимально возможный приток",
          "Помещения насосных и землесосных установок должны иметь средства связи с местом установки гидромониторов и быть оборудованы аварийной сигнализацией",
        ],
        correctAnswers: [
          "Во вновь строящихся помещениях между насосами и землесосными агрегатами, а также между ними и стенками помещения следует предусматривать проходы шириной не менее 0,75 м",
        ],
      },
      {
        code: "20604115",
        text: "В каком случае нарушены требования Правил безопасности при разработке угольных месторождений открытым способом по организации ремонта технологического оборудования?",
        answers: [
          "Ремонт технологического оборудования должен проводиться в соответствии с графиками обслуживания и ремонта оборудования согласно документации завода-изготовителя. Годовые и месячные графики ремонтов утверждает главный механик угольного разреза",
          "На все виды ремонтов основного технологического оборудования, в соответствии с действующим в организации положением о планово-предупредительных ремонтах должны быть разработаны инструкции (технологические карты, руководства, документация организации работ)",
          "В технологических картах, руководствах, документации организации работ указывается количество приспособлений и инструментов, определяется порядок и последовательность работ, обеспечивающих безопасность их проведения",
          "Работники, выполняющие ремонт, должны быть ознакомлены с инструкциями, технологическими картами и проектами организации работ под подпись",
        ],
        correctAnswers: [
          "Ремонт технологического оборудования должен проводиться в соответствии с графиками обслуживания и ремонта оборудования согласно документации завода-изготовителя. Годовые и месячные графики ремонтов утверждает главный механик угольного разреза",
        ],
      },
      {
        code: "20604116",
        text: "Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к проведению ремонта технологического оборудования?",
        answers: [
          "Ремонт и замену частей механизмов допускается проводить только после полной остановки машины, снятия давления в гидравлических и пневматических системах, блокировки пусковых аппаратов, приводящих в движение механизмы, на которых производятся ремонтные работы; допускается при выполнении ремонтных работ подача электроэнергии по документации организации работ",
          "Запрещается проведение ремонтных работ в непосредственной близости от открытых движущихся частей механических установок, а также вблизи электрических проводов и токоведущих частей, находящихся под напряжением, при отсутствии их ограждения",
          "Ремонты, связанные с изменением несущих металлоконструкций основного технологического оборудования, должны проводиться по документации, согласованной с территориальным органом Ростехнадзора, с составлением акта выполненных работ",
          "При выполнении ремонтных работ подрядной организацией ответственные представители заказчика и подрядчика должны оформить на весь период выполнения работ наряд-допуск, разработать и осуществить организационно-технические мероприятия, направленные на повышение безопасного ведения ремонтных работ",
        ],
        correctAnswers: [
          "Ремонты, связанные с изменением несущих металлоконструкций основного технологического оборудования, должны проводиться по документации, согласованной с территориальным органом Ростехнадзора, с составлением акта выполненных работ",
        ],
      },
      {
        code: "20604117",
        text: "Какая организация должна согласовать проект на выполнение ремонтных работ, связанных с изменением несущих металлоконструкций основного технологического оборудования?",
        answers: [
          "Специализированная организация по диагностике оборудования",
          "Завод-изготовитель",
          "Проектная организация",
          "Территориальный орган Ростехнадзора",
        ],
        correctAnswers: ["Завод-изготовитель"],
      },
      {
        code: "20604118",
        text: "Кем утверждается документация, по которой должны производиться ремонты по восстановлению несущих металлоконструкций?",
        answers: [
          "Руководителем завода-изготовителя",
          "Техническим руководителем (главным инженером) угольного разреза",
          "Руководителем проектной организации",
          "Представителем территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "Техническим руководителем (главным инженером) угольного разреза",
        ],
      },
      {
        code: "20604119",
        text: "Кто из перечисленных лиц назначает комиссию по оценке качества проведенного планового текущего и капитального ремонта?",
        answers: [
          "Представитель территориального органа Ростехнадзора",
          "Представитель завода-изготовителя",
          "Главный механик угольного разреза",
          "Технический руководитель (главный инженер) угольного разреза",
        ],
        correctAnswers: [
          "Технический руководитель (главный инженер) угольного разреза",
        ],
      },
      {
        code: "20604120",
        text: "Какие их перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом указаны неверно? Выберите два правильных варианта ответа.",
        answers: [
          "Рабочие площадки приемных и разгрузочных устройств и бункеров обязательно должны быть оборудованы звуковой сигнализацией, предназначенной для оповещения обслуживающего персонала о прибытии железнодорожного состава",
          "Разгрузочные площадки для железнодорожного транспорта и автосамосвалов ограждаются перилами высотой не более 1 м в местах возможного прохода людей с обшивкой их по низу на высоту 0,15 м",
          "Проемы бункеров должны ограждаться с нерабочих сторон перилами высотой не менее 1 м со сплошной обшивкой их по низу полосой на высоту 0,15 м",
          "Сигналы о прибытии железнодорожного состава подаются за 1,5 - 2 минуты до момента прибытия транспортных средств",
          "На приемных бункерах должен быть установлен светофор, разрешающий или запрещающий въезд транспортного средства на площадку бункера под разгрузку",
        ],
        correctAnswers: [
          "Разгрузочные площадки для железнодорожного транспорта и автосамосвалов ограждаются перилами высотой не более 1 м в местах возможного прохода людей с обшивкой их по низу на высоту 0,15 м",
          "Проемы бункеров должны ограждаться с нерабочих сторон перилами высотой не менее 1 м со сплошной обшивкой их по низу полосой на высоту 0,15 м",
        ],
      },
      {
        code: "20604121",
        text: "Какая электрическая система должна применяться для осветительных сетей на разрезе, а также для стационарных световых точек на передвижных машинах, механизмах и агрегатах?",
        answers: [
          "Электрическая система с изолированной нейтралью при линейном напряжении не выше 220 В",
          "Электрическая система с заземленной нейтралью напряжением 127 В",
          "Электрическая система с заземленной нейтралью напряжением 380 В",
          "Электрическая система с изолированной нейтралью при линейном напряжении не выше 127 В",
        ],
        correctAnswers: [
          "Электрическая система с изолированной нейтралью при линейном напряжении не выше 220 В",
        ],
      },
      {
        code: "20604122",
        text: "Что не соответствует требованиям по обеспечению разрезов связью?",
        answers: [
          "Питание устройств связи и сигнализации разреза для исключения помех от осветительной сети должно производиться только от специальных аккумуляторных батарей",
          "Угольный разрез должен быть оборудован комплексом технических средств связи и сигнализации, обеспечивающих эффективное управление технологическими процессами и безопасностью работ, включающих два канала связи с подразделениями профессиональной аварийно-спасательной службы и (или) профессионального аварийно-спасательного формирования ПАСС(Ф), обслуживающими угольный разрез",
          "Для сигнальных устройств, кроме систем централизованных блокировок (СЦБ), питаемых напряжением не выше 24 В, допускается выполнение линий голыми проводами",
          "Установки связи должны обеспечиваться защитой от мешающего и опасного влияния линий высокого напряжения контактной сети, грозовых разрядов и блуждающих токов",
        ],
        correctAnswers: [
          "Питание устройств связи и сигнализации разреза для исключения помех от осветительной сети должно производиться только от специальных аккумуляторных батарей",
        ],
      },
      {
        code: "20604123",
        text: "Какие технические решения должны предусматриваться в проекте для реализации мер по осушению территории производства работ?",
        answers: [
          "Только по строительству сооружений для отвода воды за пределы зоны влияния дренажной системы",
          "Все приведенные решения",
          "Только по понижению уровня подземных вод (при необходимости)",
          "Только по ограждению сооружений, горных выработок и отвалов от поверхностных вод и атмосферных осадков",
        ],
        correctAnswers: ["Все приведенные решения"],
      },
      {
        code: "20604124",
        text: "Решение каких задач не преследуется при проведении работ по осушению территории производства работ?",
        answers: [
          "Создание благоприятных условий для последующей рекультивации земель",
          "Создание безопасных условий работы горнотранспортного оборудования",
          "Снижение влажности полезных ископаемых и вскрышных пород",
          "Обеспечение устойчивости откосов горных выработок и отвалов",
        ],
        correctAnswers: [
          "Создание благоприятных условий для последующей рекультивации земель",
        ],
      },
      {
        code: "20604125",
        text: "Что не соответствует требованиям к сбросу воды, удаляемой из разреза, а также сбросу воды, полученной в результате осушения месторождения?",
        answers: [
          "Устья дренажно-вентиляционных скважин должны быть обсажены перфорированными трубами, выступающими над подошвой уступа на высоту 1 м, трубы окрашены в яркий цвет и на них нанесены номера скважин, а устье трубы должно быть наглухо перекрыто приваренным металлическим листом",
          "Вода, удаляемая из разреза, должна сбрасываться в ближайший водоток или в место, исключающее возможность ее обратного проникновения через трещины, провалы или водопроницаемые породы в выработки и заболачивание прилегающих территорий",
          "Сброс вод, полученных в результате осушения месторождения, должен производиться только после их осветления и очистки от вредных примесей",
        ],
        correctAnswers: [
          "Устья дренажно-вентиляционных скважин должны быть обсажены перфорированными трубами, выступающими над подошвой уступа на высоту 1 м, трубы окрашены в яркий цвет и на них нанесены номера скважин, а устье трубы должно быть наглухо перекрыто приваренным металлическим листом",
        ],
      },
      {
        code: "20604126",
        text: "Какие требования предъявляются к воздуху рабочей зоны разреза?",
        answers: [
          "Воздух рабочей зоны должен содержать по объему около 18% кислорода и не более 0,8% углекислого газа; содержание других вредных газов и пыли не должно превышать предельно допустимых концентраций (ПДК)",
          "Воздух рабочей зоны должен содержать по объему около 17% кислорода и не более 0,7% углекислого газа; содержание других вредных газов и пыли не должно превышать предельно допустимых концентраций (ПДК)",
          "Воздух рабочей зоны должен содержать по объему около 19% кислорода и не более 0,6% углекислого газа; содержание других вредных газов и пыли не должно превышать предельно допустимых концентраций (ПДК)",
          "Воздух рабочей зоны должен содержать по объему около 20% кислорода и не более 0,5% углекислого газа; содержание других вредных газов и пыли не должно превышать предельно допустимых концентраций (ПДК)",
        ],
        correctAnswers: [
          "Воздух рабочей зоны должен содержать по объему около 20% кислорода и не более 0,5% углекислого газа; содержание других вредных газов и пыли не должно превышать предельно допустимых концентраций (ПДК)",
        ],
      },
      {
        code: "20604127",
        text: "Какое из требований безопасности к определению мест и периодичности отбора проб воздуха рабочей зоны угольного разреза указано неверно?",
        answers: [
          "Отбор проб пыли и вредных газов производится не реже одного раза в пять месяцев для угольных разрезов глубиной отработки более 150 м",
          "Отбор проб пыли и вредных газов производится после каждого изменения технологии работ",
          "Места отбора проб пыли и вредных газов и их периодичность устанавливаются графиком, утвержденным техническим руководителем (главным инженером) угольного разреза",
          "Отбор проб пыли и вредных газов производится не реже одного раза в шесть месяцев для угольных разрезов глубиной отработки менее 150 м",
        ],
        correctAnswers: [
          "Отбор проб пыли и вредных газов производится не реже одного раза в пять месяцев для угольных разрезов глубиной отработки более 150 м",
        ],
      },
      {
        code: "20604128",
        text: "Какими средствами индивидуальной защиты органов дыхания (СИЗОД) должен быть обеспечен обслуживающий персонал на рабочих местах, где концентрация пыли превышает установленные предельно допустимые концентрации (ПДК) содержания пыли и вредных газов?",
        answers: [
          "Респираторами",
          "Изолирующими противогазами",
          "Фильтрующими противогазами",
        ],
        correctAnswers: ["Изолирующими противогазами"],
      },
      {
        code: "20604129",
        text: "Какие меры принимаются для снижения пылеобразования при положительной температуре воздуха? Укажите все правильные ответы.",
        answers: [
          "Выполнение работ в СИЗОД изолирующего типа",
          "На автомобильных дорогах проводится полив дорог водой с применением связующих добавок",
          "При сдувании пыли с обнаженных поверхностей на угольном разрезе и на отвале необходимо осуществлять меры по предотвращению пылеобразования, включающие обработку обнаженных поверхностей связующими растворами и их озеленение",
          "Все перечисленные",
        ],
        correctAnswers: [
          "На автомобильных дорогах проводится полив дорог водой с применением связующих добавок",
          "При сдувании пыли с обнаженных поверхностей на угольном разрезе и на отвале необходимо осуществлять меры по предотвращению пылеобразования, включающие обработку обнаженных поверхностей связующими растворами и их озеленение",
        ],
      },
      {
        code: "20604130",
        text: "Какие действия не соответствуют требованиям безопасности при разработке полезных ископаемых с повышенным радиационным фоном?",
        answers: [
          "На угольных разрезах, разрабатывающих сопутствующие полезные ископаемые с повышенным радиационным фоном, осуществляется радиационный контроль; результаты замеров радиационного фона фиксируются в книге учета радиационного фона",
          "Для контроля уровня радиоактивности грунтовых вод должны быть предусмотрены пробоотборные (наблюдательные) скважины по периметру гидроотвала и по направлению потока грунтовых вод",
          "Местоположение и число скважин определяются в зависимости от гидрогеологических условий с таким расчетом, чтобы расстояние между скважинами было не менее 300 м; при этом 2 скважины, диаметрально расположенные, должны быть за пределами санитарно-защитной зоны",
          "Для устранения возможного пылеобразования и разноса радиоактивных аэрозолей с поверхности намывного откоса при эксплуатации гидроотвала его необходимо покрывать по мере намыва до проектных отметок распыляемыми аэрозолями до образования защитной пленки",
        ],
        correctAnswers: [
          "Для устранения возможного пылеобразования и разноса радиоактивных аэрозолей с поверхности намывного откоса при эксплуатации гидроотвала его необходимо покрывать по мере намыва до проектных отметок распыляемыми аэрозолями до образования защитной пленки",
        ],
      },
      {
        code: "20604131",
        text: "Каким должно быть расстояние между пробоотборными (наблюдательными) скважинами, располагаемыми по периметру гидроотвала и по направлению потока грунтовых вод для контроля уровня радиоактивности грунтовых вод?",
        answers: [
          "Не менее 300 м",
          "Не менее 400 м",
          "Не менее 500 м",
          "Не менее 700 м",
        ],
        correctAnswers: ["Не менее 300 м"],
      },
      {
        code: "20604132",
        text: "Чем из перечисленного в обязательном порядке должен быть оборудован пункт первой медицинской помощи на каждом угольном разрезе?",
        answers: [
          "Сатураторной установкой",
          "Телефонной связью",
          "Системами видеонаблюдения",
          "Не регламентируется",
        ],
        correctAnswers: ["Телефонной связью"],
      },
      {
        code: "20604133",
        text: "Для каких горно-геологических и горнотехнических условий в Инструкции по расчету и применению анкерной крепи на угольных шахтах не содержится порядок расчета анкерной крепи для горизонтальных и наклонных (до 35 градусов) подземных горных выработок и их сопряжений?",
        answers: [
          "При проведении горных выработок в зонах разрывных геологических нарушений, при наличии выветренных, пластичных и мерзлых вмещающих пород",
          "При прочности на одноосное сжатие оставляемой угольной пачки в кровле горной выработки или сопряжения не менее 6 МПа",
          "При максимальной расчетной ширине выработки или сопряжения не более 12 м",
          "При отношении глубины проведения горной выработки или сопряжения от дневной поверхности Н, м к расчетному сопротивлению пород и угля в кровле на одноосное сжатие Rc, МПа не более 25",
        ],
        correctAnswers: [
          "При проведении горных выработок в зонах разрывных геологических нарушений, при наличии выветренных, пластичных и мерзлых вмещающих пород",
        ],
      },
      {
        code: "20604134",
        text: "На каком расстоянии от забоя должна быть установлена анкерная крепь при условии наличия в кровле горной выработки неустойчивых пород, обрушающихся в проходческом забое?",
        answers: [
          "Не менее 2 м",
          "Не менее 1,5 м",
          "Не более 1,3 м",
          "Не более 1 м",
        ],
        correctAnswers: ["Не более 1 м"],
      },
      {
        code: "20604135",
        text: "В какой момент допускается проводить затяжку гаек анкера при возведении анкерной крепи?",
        answers: [
          "Через час после окончания твердения состава ампул",
          "После окончания процесса твердения состава ампул, время которого указывается заводом-изготовителем",
          "После первичного отвердения состава ампул, определяемого визуально",
          "Сразу после установки анкера",
        ],
        correctAnswers: [
          "После окончания процесса твердения состава ампул, время которого указывается заводом-изготовителем",
        ],
      },
      {
        code: "20604136",
        text: "Оценка каких параметров проводится при бурении скважин (шпуров)? Укажите все правильные ответы.",
        answers: [
          "Обводненности кровли горной выработки по скорости бурения и выходу буровой мелочи",
          "Несущей способности используемых при креплении горной выработки анкеров",
          "Прочностных свойств пород",
          "Температуры нагрева буровой коронки",
        ],
        correctAnswers: [
          "Обводненности кровли горной выработки по скорости бурения и выходу буровой мелочи",
          "Прочностных свойств пород",
        ],
      },
      {
        code: "20604137",
        text: "С какой периодичностью и при выполнении каких условий должна проводиться оценка несущей способности применяемых анкеров при проведении (восстановлении) выработок?",
        answers: [
          "Оценка проводится при помощи штанговыдергивателя не реже одного раза в 3 месяца через каждые 100 м длины горной выработки",
          "Оценка проводится при помощи штанговыдергивателя не реже двух раз в 3 месяца через каждые 250 м длины горной выработки",
          "Оценка проводится при помощи штанговыдергивателя не реже одного раза в месяц через каждые 200 м длины горной выработки",
          "Оценка проводится при помощи штанговыдергивателя один раз в 2 месяца через каждые 150 м длины горной выработки",
        ],
        correctAnswers: [
          "Оценка проводится при помощи штанговыдергивателя не реже одного раза в месяц через каждые 200 м длины горной выработки",
        ],
      },
      {
        code: "20604138",
        text: "С какой периодичностью должен проводиться визуальный контроль работоспособности анкерной крепи в зонах влияния очистных работ?",
        answers: [
          "Ежесуточно",
          "Еженедельно",
          "Один раз в месяц",
          "Один раз в квартал",
        ],
        correctAnswers: ["Ежесуточно"],
      },
      {
        code: "20604139",
        text: "По истечении какого срока эксплуатации анкерной крепи в выработках должна быть проведена научно-исследовательская работа с оценкой несущей способности анкеров, коррозионного износа и работоспособности анкерной крепи?",
        answers: ["5 лет", "7 лет", "10 лет", "9 лет"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "20604140",
        text: "До какой нагрузки нагружают анкерную крепь при испытании ее несущей способности?",
        answers: [
          "До 0,8 Nа, где Nа - несущая способность анкера, кН",
          "До 1,2 Nа, где Nа - несущая способность анкера, кН",
          "До 0,6 Nа, где Nа - несущая способность анкера, кН",
          "До 1,1 Nа, где Nа - несущая способность анкера, кН",
        ],
        correctAnswers: ["До 0,6 Nа, где Nа - несущая способность анкера, кН"],
      },
      {
        code: "20604141",
        text: "Какая должна быть минимальная длина анкеров первого уровня в случае применения двухуровневой схемы крепи при ширине выработки от 8 до 12 м для I типа кровли?",
        answers: ["2,4 м", "1,8 м", "1,4 м", "2,2 м"],
        correctAnswers: ["2,4 м"],
      },
      {
        code: "20604142",
        text: "К какому типу кровли (по обрушаемости) будет относиться однородная кровля из слоистых преимущественно глинистых, песчано-глинистых и песчаных сланцев?",
        answers: [
          "К I типу кровли",
          "К II типу кровли",
          "К III типу кровли",
          "К IV типу кровли",
        ],
        correctAnswers: ["К I типу кровли"],
      },
      {
        code: "20604143",
        text: "К какому типу кровли по обрушаемости относится кровля при сопротивлении пород одноосному сжатию Rc ≤ 30 МПа?",
        answers: [
          "К I типу кровли",
          "К II типу кровли",
          "К III типу кровли",
          "К IV типу кровли",
        ],
        correctAnswers: ["К III типу кровли"],
      },
      {
        code: "20604144",
        text: 'К какому классу относится "неустойчивая кровля"?',
        answers: ["К I классу", "К II классу", "К III классу"],
        correctAnswers: ["К I классу"],
      },
      {
        code: "20604145",
        text: 'К какому классу относится "кровля средней устойчивости"?',
        answers: ["К I классу", "К II классу", "К III классу"],
        correctAnswers: ["К II классу"],
      },
      {
        code: "20604146",
        text: 'К какому классу относится "устойчивая кровля"?',
        answers: ["К I классу", "К II классу", "К III классу"],
        correctAnswers: ["К III классу"],
      },
      {
        code: "20604147",
        text: "При каком различии отметок участков протяженных горизонтальных и наклонных горных выработок, расчетная глубина для них принимается равной максимальной глубине?",
        answers: ["До 15%", "Свыше 25%", "До 20%", "Свыше 35%"],
        correctAnswers: ["До 15%"],
      },
      {
        code: "20604148",
        text: "Чему равно значение коэффициента разрыхления пород при вынимаемой мощности подрабатывающего пласта до 1 м и прочности пород непосредственной кровли подрабатывающего пласта до 30 МПа?",
        answers: ["1,35 - 1,40", "1,15 - 1,20", "1,30 - 1,35", "1,20 - 1,25"],
        correctAnswers: ["1,15 - 1,20"],
      },
      {
        code: "20604149",
        text: "Чему равно значение коэффициента снижения сопротивления пород сжатию за счет воздействия влаги при сопротивлении пород одноосному сжатию в образце 50 МПа?",
        answers: ["0,5", "0,6", "0,7", "0,8"],
        correctAnswers: ["0,5"],
      },
      {
        code: "20604150",
        text: "Какое определение характеризует смещение кровли более 200 мм, выделяемое при определении и выборе документации крепления в зависимости от расчетных смещений и от интенсивности проявлений горного давления?",
        answers: [
          "Горное давление средней интенсивности",
          "Горное давление повышенной интенсивности",
          "Интенсивное горное давление",
          "Незначительное горное давление",
        ],
        correctAnswers: ["Интенсивное горное давление"],
      },
      {
        code: "20604151",
        text: "Какой должна быть требуемая минимальная плотность установки анкеров в классе неустойчивой кровли?",
        answers: [
          "Не меньше 0,3 анк/м²",
          "Не меньше 0,7 анк/м²",
          "Не меньше 0,5 анк/м²",
          "Не меньше 1 анк/м²",
        ],
        correctAnswers: ["Не меньше 1 анк/м²"],
      },
      {
        code: "20604152",
        text: "На сколько классов устойчивости следует разделять непосредственную кровлю над горными выработками и сопряжениями при определении плотности установки анкеров, конструкции опор, затяжки, технологии крепления кровли при проходке, для всех типов кровли по обрушаемости?",
        answers: ["На три", "На шесть", "На четыре", "На пять"],
        correctAnswers: ["На три"],
      },
      {
        code: "20604153",
        text: "Какая должна быть минимальная длина анкеров первого уровня в случае применения двухуровневой схемы крепи при ширине выработки от 8 до 12 м для II типа кровли?",
        answers: ["2,4 м", "2,0 м", "1,6 м", "1,2 м"],
        correctAnswers: ["2,0 м"],
      },
      {
        code: "20604154",
        text: "От чего зависит надежность поддержания горных выработок в нижнем слое?",
        answers: [
          "Только от прочности угля",
          "Только от трещиноватости угля",
          "Только от мощности межслоевой угольной пачки, оставляемой между штреками верхнего и нижнего слоя",
          "От всего перечисленного",
        ],
        correctAnswers: ["От всего перечисленного"],
      },
      {
        code: "20604155",
        text: "К какой основной схеме подготовки выемочных участков относится проведение спаренных горных выработок в зоне и впереди зоны влияния очистных работ при подготовке выемочных столбов спаренными горными выработками?",
        answers: ["К первой", "Ко второй", "К третьей", "К четвертой"],
        correctAnswers: ["Ко второй"],
      },
      {
        code: "20604156",
        text: "Влияние каких профилактических мероприятий на геомеханическое состояние вмещающих пород не требуется учитывать при расчете параметров анкерной крепи горных выработок, проводимых по пластам, склонным к горным ударам и внезапным выбросам угля (породы) и газа?",
        answers: [
          "Региональная предварительная подработка и (или) надработка массива с последующим проведением в нем подготовительных горных выработок",
          "Бурение разгрузочных скважин в окружающем угольном массиве в период проведения горной выработки",
          "Выполнение гидрообработки угольного массива",
          "Контроль за скоростью вращения инструмента при бурении, давлением на манометре насоса, выносом из скважины крупных обломков породы",
        ],
        correctAnswers: [
          "Контроль за скоростью вращения инструмента при бурении, давлением на манометре насоса, выносом из скважины крупных обломков породы",
        ],
      },
      {
        code: "20604157",
        text: "К какой категории интенсивности проявления горного давления относится горная выработка при расчетном смещении пород кровли свыше 50 до 200 мм?",
        answers: [
          "К II категории (горное давление средней интенсивности)",
          "К I категории (небольшое горное давление)",
          "К III категории (интенсивное горное давление)",
        ],
        correctAnswers: [
          "К II категории (горное давление средней интенсивности)",
        ],
      },
      {
        code: "20604158",
        text: "К какой категории интенсивности проявления горного давления будет относиться горная выработка при расчетном смещении пород кровли свыше 50 до 200 мм?",
        answers: [
          "К I категории (небольшое горное давление)",
          "К II категории (горное давление средней интенсивности)",
          "К III категории (интенсивное горное давление)",
        ],
        correctAnswers: [
          "К II категории (горное давление средней интенсивности)",
        ],
      },
      {
        code: "20604159",
        text: "К какой категории интенсивности проявления горного давления относится горная выработка при расчетном смещении пород кровли свыше 200 мм?",
        answers: [
          "К I категории (небольшое горное давление)",
          "К II категории (горное давление средней интенсивности)",
          "К III категории (интенсивное горное давление)",
        ],
        correctAnswers: ["К III категории (интенсивное горное давление)"],
      },
      {
        code: "20604160",
        text: "К какой категории интенсивности проявления горного давления будет относиться горная выработка при расчетном смещении пород кровли свыше 200 мм?",
        answers: [
          "К I категории (небольшое горное давление)",
          "К II категории (горное давление средней интенсивности)",
          "К III категории (интенсивное горное давление)",
        ],
        correctAnswers: ["К III категории (интенсивное горное давление)"],
      },
      {
        code: "20604161",
        text: "В зависимости от каких характеристик проводится расчет параметров анкерной крепи и дополнительных средств ее усиления?",
        answers: [
          "Способа и параметров охраны горных выработок от влияния очистных работ",
          "Прочности, трещиноватости и устойчивости пород в кровле",
          "Интенсивности горного давления",
          "Всех перечисленных характеристик",
        ],
        correctAnswers: ["Всех перечисленных характеристик"],
      },
      {
        code: "20604162",
        text: "В каких горных выработках применяются анкеры с закреплением стержней минеральной композицией?",
        answers: [
          "Только в горных выработках, пройденных по пластам, весьма склонным к самовозгоранию",
          "Во всех перечисленных",
          "Только в горных выработках со сроком службы более 10 лет",
          "Только в горных выработках с обводненными породами",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "20604163",
        text: "Какую расчетную несущую способность по всей длине скважины должны обеспечивать анкеры из стержней винтового профиля из стали А400С, А500С N 25?",
        answers: ["240 кН", "160 кН", "120 кН", "140 кН"],
        correctAnswers: ["240 кН"],
      },
      {
        code: "20604164",
        text: "Какую расчетную несущую способность по всей длине скважины должны обеспечивать анкеры из стержней диаметром 20 мм с метрической резьбой из стали Ст.5?",
        answers: ["100 кН", "160 кН", "75 кН", "130 кН"],
        correctAnswers: ["100 кН"],
      },
      {
        code: "20604165",
        text: "Какой следует принимать расчетную несущую способность анкера Nа для горных выработок и сопряжений со сроком службы свыше 40 лет и в обводненных породах?",
        answers: [
          "Следует корректировать в соответствии с фактической, определяемой экспериментально в шахтных условиях",
          'Следует принимать на 20% меньше значений, регламентированных в ФНП "Инструкция по расчету и применению анкерной крепи на угольных шахтах"',
          "Следует принимать в соответствии с технической документацией с учетом уменьшения расчетной несущей способности при различных условиях работы анкеров",
        ],
        correctAnswers: [
          'Следует принимать на 20% меньше значений, регламентированных в ФНП "Инструкция по расчету и применению анкерной крепи на угольных шахтах"',
        ],
      },
      {
        code: "20604166",
        text: "Какое расстояние должно быть предусмотрено от кровли выработки до верхнего ряда анкеров?",
        answers: [
          "Не более 0,5 м",
          "Не менее 1,3 м",
          "Не менее 1,0 м",
          "Не более 0,8 м",
        ],
        correctAnswers: ["Не более 0,5 м"],
      },
      {
        code: "20604167",
        text: "В каком случае допускается подвеска к анкерной крепи оборудования?",
        answers: [
          "Допускается подвеска горно-шахтного оборудования, предусмотренного паспортом крепления или специальным проектом",
          "Допускается в случае выполнения аварийно-спасательных работ",
          "Не допускается ни в каком случае",
          "Допускается в любом случае при технической необходимости",
        ],
        correctAnswers: [
          "Допускается подвеска горно-шахтного оборудования, предусмотренного паспортом крепления или специальным проектом",
        ],
      },
      {
        code: "20604168",
        text: "Каким образом целесообразно располагать анкеры в кровле?",
        answers: [
          "С отклонением крайних анкеров от нормали к напластованию на угол 15 - 20° и на расстоянии их от боков не более 0,3 м",
          "С отклонением крайних анкеров от нормали к напластованию на угол 15 - 20° и на расстоянии их от боков не более 0,5 м",
          "С отклонением крайних анкеров от нормали к напластованию на угол 20 - 25° и на расстоянии их от боков не более 0,4 м",
        ],
        correctAnswers: [
          "С отклонением крайних анкеров от нормали к напластованию на угол 15 - 20° и на расстоянии их от боков не более 0,3 м",
        ],
      },
      {
        code: "20604169",
        text: "Какие требования при проектировании паспортов крепления и поддержания подготовительных выработок анкерной крепью указаны неверно?",
        answers: [
          "Анкеры в кровле следует располагать на расстоянии их от боков не более 0,3 м",
          "Анкеры в боках следует располагать так, чтобы они пересекали наиболее слабые слои угля и пород, т. е. с отклонением от напластования на угол 15 - 30°",
          "Анкеры в кровле следует располагать с отклонением крайних анкеров от нормали к напластованию на угол 15 - 20°",
          "Расстояние от кровли выработки до верхнего ряда анкеров должно быть не более 1,5 м",
        ],
        correctAnswers: [
          "Расстояние от кровли выработки до верхнего ряда анкеров должно быть не более 1,5 м",
        ],
      },
      {
        code: "20604170",
        text: "С какой периодичностью вносятся записи в журнал по контролю за состоянием выработки и показаниями реперных станций вне зоны опорного давления?",
        answers: [
          "1 раз в квартал",
          "1 раз в месяц",
          "1 раз в смену",
          "1 раз в неделю",
        ],
        correctAnswers: ["1 раз в месяц"],
      },
      {
        code: "20604171",
        text: "С какой периодичностью вносятся записи в журнал по контролю за состоянием выработки и показаниями реперных станций в зоне опорного давления?",
        answers: [
          "1 раз в неделю",
          "1 раз в квартал",
          "1 раз в сутки",
          "1 раз в месяц",
        ],
        correctAnswers: ["1 раз в месяц"],
      },
      {
        code: "20604172",
        text: "В каком из перечисленных случаев основным критерием определения безопасной высоты уступа являются расчеты с учетом траектории движения рабочего органа (ковша) экскаватора (погрузчика)?",
        answers: [
          "При применении гидравлических экскаваторов и погрузчиков",
          "При разработке пород с применением буровзрывных работ",
          "При применении канатных экскаваторов",
          "При применении экскаваторов с удлиненным рабочим оборудованием",
          "При разработке уступа вручную",
        ],
        correctAnswers: [
          "При применении гидравлических экскаваторов и погрузчиков",
        ],
      },
      {
        code: "20604173",
        text: "Что из перечисленного не должна превышать высота уступа при применении канатных экскаваторов? Выберите два правильных варианта ответа.",
        answers: [
          "Полуторную высоту черпания экскаватора",
          "Высоту траектории движения рабочего органа (ковша) экскаватора (погрузчика)",
          "Максимальную высоту черпания экскаватора",
          "Высоту или глубину черпания драглайна, многоковшовых цепных и роторных экскаваторов",
        ],
        correctAnswers: [
          "Максимальную высоту черпания экскаватора",
          "Высоту или глубину черпания драглайна, многоковшовых цепных и роторных экскаваторов",
        ],
      },
      {
        code: "20604174",
        text: "Что из перечисленного должна обеспечивать высота уступа для экскаваторов с удлиненным рабочим оборудованием?",
        answers: [
          "Возможность контроля ширины рабочих площадок и углов откоса уступов",
          "Видимость соседних рабочих уступов",
          "Видимость транспортных сосудов из кабины машиниста экскаватора",
          "Видимость всех формируемых временно неработающих бортов",
        ],
        correctAnswers: [
          "Видимость транспортных сосудов из кабины машиниста экскаватора",
        ],
      },
      {
        code: "20604175",
        text: "Каким должен быть максимальный угол откосов рабочих уступов при работе экскаваторов типа механической лопаты, драглайна и роторных экскаваторов?",
        answers: ["70°", "80°", "75°", "85°"],
        correctAnswers: ["80°"],
      },
      {
        code: "20604176",
        text: "В каком случае углы откосов рабочих уступов не должны превышать угла естественного откоса разрабатываемых пород? Выберите два правильных варианта ответа.",
        answers: [
          "При работе экскаваторов типа механической лопаты и гидравлических экскаваторов",
          "При работе многоковшовых цепных экскаваторов нижним черпанием",
          "При работе погрузчиков, драглайнов и роторных экскаваторов",
          "При разработке вручную рыхлых и сыпучих пород",
        ],
        correctAnswers: [
          "При работе многоковшовых цепных экскаваторов нижним черпанием",
          "При разработке вручную рыхлых и сыпучих пород",
        ],
      },
      {
        code: "20604177",
        text: "Каким должно быть расстояние от нижней бровки уступа (развала горной массы) и от верхней бровки уступа до оси ближайшего железнодорожного пути?",
        answers: [
          "Не менее 1,8 м",
          "Не менее 2,2 м",
          "Не менее 1,5 м",
          "Не менее 2,5 м",
        ],
        correctAnswers: ["Не менее 2,5 м"],
      },
      {
        code: "20604178",
        text: "Каким должно быть минимальное расстояние по горизонтали между экскаваторами и драглайнами (с учетом величины заброса ковша) или драглайнами с учетом величины заброса ковшов, расположенными на одном горизонте?",
        answers: [
          "Равным сумме их наибольших радиусов действия",
          "Равным удвоенной сумме их наибольших радиусов действия",
          "Равным утроенной сумме их наибольших радиусов действия",
          "Равным полуторной сумме их наибольших радиусов действия",
        ],
        correctAnswers: ["Равным сумме их наибольших радиусов действия"],
      },
      {
        code: "20604179",
        text: "Каким должно быть минимальное расстояние по горизонтали между экскаваторами, расположенными на одном горизонте?",
        answers: [
          "Равным полуторной сумме их наибольших радиусов действия",
          "Равным сумме их наибольших радиусов действия",
          "Равным удвоенной сумме их наибольших радиусов действия",
          "Равным утроенной сумме их наибольших радиусов действия",
        ],
        correctAnswers: ["Равным сумме их наибольших радиусов действия"],
      },
      {
        code: "20604180",
        text: "Какое минимальное расстояние должно быть от оси железнодорожного пути до бровки плужного отвала после каждой передвижки путей при грузоподъемности думпкара более 60 тонн?",
        answers: ["1700 мм", "1900 мм", "1600 мм", "1800 мм", "1500 мм"],
        correctAnswers: ["1800 мм"],
      },
      {
        code: "20604181",
        text: "Какое минимальное расстояние должно быть от оси железнодорожного пути до бровки плужного отвала после каждой передвижки путей при грузоподъемности думпкара до 60 тонн?",
        answers: ["1700 мм", "1600 мм", "1500 мм", "1300 мм", "1400 мм"],
        correctAnswers: ["1600 мм"],
      },
      {
        code: "20604182",
        text: "Какое минимальное расстояние должно быть от оси железнодорожного пути до верхней бровки на отвалах, оборудованных одноковшовыми экскаваторами, в месте разгрузки думпкаров для нормальной колеи 1520 мм?",
        answers: ["1400 мм", "1300 мм", "1700 мм", "1600 мм", "1500 мм"],
        correctAnswers: ["1600 мм"],
      },
      {
        code: "20604183",
        text: "Какое минимальное расстояние должно быть от оси железнодорожного пути до верхней бровки на отвалах, оборудованных одноковшовыми экскаваторами, в месте разгрузки думпкаров для колеи 900 мм?",
        answers: ["1700 мм", "1400 мм", "1300 мм", "1500 мм", "1100 мм"],
        correctAnswers: ["1300 мм"],
      },
      {
        code: "20604184",
        text: "Какое превышение должен иметь внешний рельс разгрузочного пути по отношению к внутреннему?",
        answers: [
          "На 40 - 60 мм",
          "На 100 - 150 мм",
          "На 60 - 80 мм",
          "На 80 - 100 мм",
        ],
        correctAnswers: ["На 100 - 150 мм"],
      },
      {
        code: "20604185",
        text: "На какой высоте от оси пути следует располагать указатели путевого заграждения, располагая их со стороны машиниста локомотива?",
        answers: ["1,5 м", "2,5 м", "2,0 м", "1,0 м"],
        correctAnswers: ["1,5 м"],
      },
      {
        code: "20604186",
        text: "Что из перечисленного не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом?",
        answers: [
          "Опрокидывание кузовов думпкаров и возвращение их в транспортное положение после разгрузки должны производиться без помощи подставок, шпал, рельсов",
          "Для безопасной разгрузки думпкаров, груженных смерзающимися, налипающими породами и крупногабаритными кусками, должны быть разработаны мероприятия, утвержденные техническим руководителем (главным инженером) угольного разреза",
          "Очистка думпкаров вручную на приямках допускается в случаях неисправности механизмов по очистке при соблюдении специально разработанных мер безопасности, утвержденных техническим руководителем разреза",
          "При разгрузке думпкаров люди должны находиться вне зоны развала горной массы. Вдоль железнодорожного пути в месте разгрузки состава с противоположной от приямка стороны должна быть спланирована площадка для обслуживающего состав персонала",
        ],
        correctAnswers: [
          "Очистка думпкаров вручную на приямках допускается в случаях неисправности механизмов по очистке при соблюдении специально разработанных мер безопасности, утвержденных техническим руководителем разреза",
        ],
      },
      {
        code: "20604187",
        text: "В каком случае при работе на отвале нарушены требования Правил безопасности при разработке угольных месторождений открытым способом?",
        answers: [
          "Работа бульдозера должна производиться перпендикулярно верхней бровке откоса площадки",
          "Движение бульдозера во время работы должно производиться только отвалом вперед с одновременным формированием перед отвалом бульдозера предохранительного вала в соответствии с проектом",
          "Подача автосамосвала на разгрузку должна осуществляться задним ходом",
          "При разгрузке автосамосвалов в пределах призмы обрушения при подработанном экскаватором откосе яруса допускается установка автосамосвалов перпендикулярно верхней бровке откоса",
        ],
        correctAnswers: [
          "При разгрузке автосамосвалов в пределах призмы обрушения при подработанном экскаватором откосе яруса допускается установка автосамосвалов перпендикулярно верхней бровке откоса",
        ],
      },
      {
        code: "20604188",
        text: "Какие требования Правил безопасности при разработке угольных месторождений открытым способом к проведению разгрузочных работ в одном секторе указаны верно?",
        answers: [
          "Работа на отвале и перегрузочном пункте должна производиться в соответствии с проектом ведения работ и регулироваться знаками и аншлагами",
          "Одновременная работа в одном секторе на отвале бульдозера и автосамосвалов, а на перегрузочном пункте - бульдозера, автосамосвала и экскаватора (погрузчика) должна проводиться по утвержденному регламенту",
          "Расстояние между стоящими на разгрузке, проезжающими транспортными средствами и работающим бульдозером должно быть не менее 3 м",
          "Все перечисленные",
        ],
        correctAnswers: [
          "Работа на отвале и перегрузочном пункте должна производиться в соответствии с проектом ведения работ и регулироваться знаками и аншлагами",
        ],
      },
      {
        code: "20604189",
        text: "На каком минимальном расстоянии от работающих механизмов на территории складирования горной массы (пород), на разгрузочных площадках, перегрузочных пунктах (складах) должны находиться люди?",
        answers: ["4,0 м", "3,0 м", "Не нормируется", "5,0 м"],
        correctAnswers: ["5,0 м"],
      },
      {
        code: "20604190",
        text: "Кому из перечисленных лиц разрешается проезд в многоместных кабинах транспортных средств, в железнодорожных составах и кабинах локомотивов?",
        answers: [
          "Только лицам, сопровождающим составы",
          "Только сменному надзору",
          "Всем перечисленным лицам",
          "Только отдельным работникам при наличии у них письменного разрешения технического руководителя (главного инженера) угольного разреза",
        ],
        correctAnswers: ["Всем перечисленным лицам"],
      },
      {
        code: "20604191",
        text: "Как должны производиться перегон горнотранспортных и строительно-дорожных машин (экскаваторов, буровых станков) и перевозки их на транспортных средствах на расстояние более 1 км, а также при необходимости отключения пересекаемых воздушных линий электропередачи?",
        answers: [
          "В соответствии с документацией на производство работ, утвержденной техническим руководителем (главным инженером) угольного разреза",
          "В соответствии с техническими инструкциями завода-изготовителя",
          "С обязательным уведомлением территориального органа Ростехнадзора, осуществляющего федеральный государственный надзор в области промышленной безопасности за угольным разрезом",
          "В соответствии с планом мероприятий по локализации и ликвидации последствий аварий",
          "В соответствии с планом развития горных работ",
        ],
        correctAnswers: [
          "В соответствии с документацией на производство работ, утвержденной техническим руководителем (главным инженером) угольного разреза",
        ],
      },
      {
        code: "20604192",
        text: "С какой периодичностью должна проверяться исправность и комплектность машин главным механиком, главным энергетиком разреза или другим назначаемым лицом?",
        answers: [
          "Один раз в полугодие",
          "Один раз в квартал",
          "Ежемесячно",
          "Один раз в 2 месяца",
        ],
        correctAnswers: ["Ежемесячно"],
      },
      {
        code: "20604193",
        text: "Какое из перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом указано неверно?",
        answers: [
          "Предпусковой предупредительный сигнал должен быть звуковым. Продолжительность сигнала должна составлять не менее 3 секунд. Сигнал должен быть слышен по всей опасной зоне",
          "Перед началом работы или движения машины (механизма) машинист обязан убедиться в безопасности персонала, находящегося поблизости",
          "Таблица предупредительных сигналов должна располагаться на работающем механизме или вблизи него. При неправильно поданном или непонятном предупредительном сигнале машины (механизмы) должны быть остановлены",
          "Движущиеся части оборудования, представляющие собой источник опасности для людей, должны быть ограждены, за исключением частей, ограждение которых невозможно из-за их функционального назначения",
          "Перед началом движения машин, железнодорожных составов, транспортных средств, погрузочной техники обязательна подача звуковых и (или) световых предупредительных сигналов, со значением которых должны быть ознакомлены все работники под подпись. Сигналы должны быть слышны (видны) всем работникам в зоне действия машин (механизмов)",
        ],
        correctAnswers: [
          "Предпусковой предупредительный сигнал должен быть звуковым. Продолжительность сигнала должна составлять не менее 3 секунд. Сигнал должен быть слышен по всей опасной зоне",
        ],
      },
      {
        code: "20604194",
        text: "Что не соответствует требованиям к состоянию горнотранспортных и строительно-дорожных машин в нерабочее время?",
        answers: [
          "С питающего кабеля напряжение не снимается",
          "При отсутствии экипажа кабина запирается",
          "Рабочий орган (ковш) должен быть опущен на грунт",
          "Машины должны быть выведены из забоя в безопасное место",
        ],
        correctAnswers: ["С питающего кабеля напряжение не снимается"],
      },
      {
        code: "20604195",
        text: "Что не соответствует требованиям безопасности при эксплуатации горнотранспортных и строительно-дорожных машин?",
        answers: [
          "Копия проекта (документации) производства работ должна находиться только у горного мастера смены",
          'В случае внезапного прекращения подачи электроэнергии персонал, обслуживающий механизмы, обязан немедленно перевести пусковые устройства электродвигателей и рычаги управления в нейтральное положение "Стоп" (нулевое)',
          "Работы с использованием горнотранспортных и строительно-дорожных машин должны вестись в соответствии с документацией на производство работ, копии документов должны находиться в кабинах этих машин",
          "Запрещается присутствие посторонних лиц в кабине и на наружных площадках экскаватора и бурового станка при их работе, кроме руководителя смены и лиц, имеющих разрешение технического руководителя (главного инженера) угольного разреза",
        ],
        correctAnswers: [
          "Копия проекта (документации) производства работ должна находиться только у горного мастера смены",
        ],
      },
      {
        code: "20604196",
        text: "Как должен располагаться привод ходовой тележки при передвижении гусеничного экскаватора по горизонтальному участку или на подъем и при спуске?",
        answers: [
          "При передвижении гусеничного экскаватора по горизонтальному участку или на подъем привод ходовой тележки должен находиться впереди, а при спусках с уклона - сзади",
          "При передвижении гусеничного экскаватора по горизонтальному участку или на подъем, а также при спуске привод ходовой тележки должен находиться сзади",
          "При передвижении гусеничного экскаватора по горизонтальному участку или на подъем привод ходовой тележки должен находиться сзади, а при спусках с уклона - впереди",
          "При передвижении гусеничного экскаватора по горизонтальному участку или на подъем, а также при спуске привод ходовой тележки должен находиться впереди",
        ],
        correctAnswers: [
          "При передвижении гусеничного экскаватора по горизонтальному участку или на подъем привод ходовой тележки должен находиться сзади, а при спусках с уклона - впереди",
        ],
      },
      {
        code: "20604197",
        text: "Какое из перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом к перегону экскаваторов указано неверно?",
        answers: [
          "Для шагающих экскаваторов не допускается передача сигналов от помощника машиниста к машинисту через третьего члена бригады",
          "Перегон должен осуществляться по трассе, расположенной вне призм обрушения",
          "Перегон экскаватора должен производиться по сигналам помощника машиниста или назначенного лица, при этом должна быть обеспечена постоянная видимость между ним и машинистом экскаватора",
          "Уклон трассы не должен превышать величины, допустимые по техническим характеристикам экскаватора, и трасса должна иметь ширину, достаточную для маневров",
        ],
        correctAnswers: [
          "Для шагающих экскаваторов не допускается передача сигналов от помощника машиниста к машинисту через третьего члена бригады",
        ],
      },
      {
        code: "20604198",
        text: "Каким должно быть расстояние между откосом уступа, отвала или транспортным средством и контргрузом экскаватора?",
        answers: [
          "Устанавливается паспортом забоя в зависимости от горно-геологических условий и типа оборудования, но в любом случае должно быть не менее 0,5 м",
          "Устанавливается паспортом забоя в зависимости от горно-геологических условий и типа оборудования, но в любом случае должно быть не менее 1,0 м",
          "Устанавливается паспортом забоя в зависимости от горно-геологических условий и типа оборудования, но в любом случае должно быть не менее 0,75 м",
          "Устанавливается паспортом забоя в зависимости от горно-геологических условий и типа оборудования, но в любом случае должно быть не менее 0,6 м",
        ],
        correctAnswers: [
          "Устанавливается паспортом забоя в зависимости от горно-геологических условий и типа оборудования, но в любом случае должно быть не менее 1,0 м",
        ],
      },
      {
        code: "20604199",
        text: "Кем осуществляется подача сигналов при погрузке экскаваторами в железнодорожные вагоны и разгрузке их на экскаваторных отвалах?",
        answers: [
          "Лицом, назначенным приказом технического руководителя (главного инженера) угольного разреза",
          "Машинистом экскаватора в соответствии с сигналами, установленными при эксплуатации железнодорожного транспорта",
          "Машинистом поезда в соответствии с сигналами, установленными при эксплуатации угольного разреза",
          "Мастером смены",
        ],
        correctAnswers: [
          "Машинистом экскаватора в соответствии с сигналами, установленными при эксплуатации железнодорожного транспорта",
        ],
      },
      {
        code: "20604200",
        text: "Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к канатам подвески стрелы экскаваторов, их осмотру и допуску к эксплуатации?",
        answers: [
          "Канаты подлежат осмотру не реже одного раза в месяц механиком участка",
          "На длине шага свивки допускается не более 15% порванных проволок от их общего числа в канате",
          "Торчащие концы оборванных проволок должны быть отрезаны",
          "Подъемные, тяговые и напорные канаты должны осматриваться в сроки, установленные заводом-изготовителем",
        ],
        correctAnswers: [
          "Канаты подлежат осмотру не реже одного раза в месяц механиком участка",
        ],
      },
      {
        code: "20604201",
        text: "Что из перечисленного не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к эксплуатации экскаваторов?",
        answers: [
          "В случае выявления угрозы подтопления, обрушения или оползания горных выработок во время работы экскаватора машинист экскаватора обязан прекратить работу, отвести экскаватор в безопасное место и поставить в известность руководителя смены",
          "При работе экскаватора на грунтах, не выдерживающих давления гусениц, должны осуществляться меры, отражаемые в документации производства работ, обеспечивающие устойчивое положение экскаватора",
          "Погрузка горной массы экскаватором в забоях с контактными сетями электрифицированного транспорта в думпкары или другие емкости допускается при условии осуществления мероприятий по безопасным приемам работы, включая защиту от прикосновения ковша с контактным проводом",
          "Для вывода экскаватора из забоя необходимо всегда иметь свободный проход. Негабаритные куски горной массы должны укладываться устойчиво не более чем в два-три слоя, не создавая препятствий для перемещения горнотранспортного оборудования на площадке",
        ],
        correctAnswers: [
          "Для вывода экскаватора из забоя необходимо всегда иметь свободный проход. Негабаритные куски горной массы должны укладываться устойчиво не более чем в два-три слоя, не создавая препятствий для перемещения горнотранспортного оборудования на площадке",
        ],
      },
      {
        code: "20604202",
        text: "С какой периодичностью должны проверяться устройства контроля за изменением ширины рельсовых путей и их уклонов?",
        answers: [
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в 2 месяца",
          "Не реже одного раза в тридцать дней",
          "Не реже одного раза в 3 месяца",
        ],
        correctAnswers: ["Не реже одного раза в тридцать дней"],
      },
      {
        code: "20604203",
        text: "Что из перечисленного не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к эксплуатации скреперов, бульдозеров и погрузчиков?",
        answers: [
          "Запрещается движение самоходной техники (скреперов, бульдозеров, погрузчиков) по призме возможного обрушения уступа. При разгрузке скрепер должен передвигаться назад под откос",
          "Во всех случаях при движении транспортного средства и самоходной техники задним ходом должен подаваться звуковой сигнал",
          "Вся самоходная техника (грейдеры, скреперы, бульдозеры, погрузчики) должна иметь руководства по эксплуатации, техническому обслуживанию и ремонту, выданные заводом-изготовителем, содержащие их основные технические и эксплуатационные характеристики",
          "На линию транспортные средства могут выпускаться только при условии, если все их агрегаты и узлы, обеспечивающие безопасность движения, а также безопасность других работ, предусмотренных технологией применения, находятся в технически исправном состоянии",
        ],
        correctAnswers: [
          "Запрещается движение самоходной техники (скреперов, бульдозеров, погрузчиков) по призме возможного обрушения уступа. При разгрузке скрепер должен передвигаться назад под откос",
        ],
      },
      {
        code: "20604204",
        text: "Что из перечисленного соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к эксплуатации бульдозера, скрепера или погрузчика?",
        answers: [
          "Для осмотра рабочего органа (ножа или ковша) снизу его необходимо опустить на надежные подкладки, а двигатель выключить",
          "Запрещается эксплуатация бульдозера (трактора) при отсутствии или неисправности блокировки, исключающей запуск двигателя при включенной коробке передач, или устройства для запуска двигателя из кабины",
          "Допускается работа техники поперек крутых склонов при углах, предусмотренных инструкцией завода-изготовителя",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20604205",
        text: "На каком расстоянии от наружной грани головки крайнего рельса должны находиться грузы при высоте до 1200 мм?",
        answers: [
          "На расстоянии не менее 1,5 м",
          "На расстоянии не менее 1,25 м",
          "На расстоянии не менее 2,0 м",
          "На расстоянии не менее 1,75 м",
        ],
        correctAnswers: ["На расстоянии не менее 2,0 м"],
      },
      {
        code: "20604206",
        text: "На каком расстоянии от наружной грани головки крайнего рельса должны находиться грузы при высоте выше 1200 мм?",
        answers: [
          "На расстоянии не менее 2,5 м",
          "На расстоянии не менее 1,75 м",
          "На расстоянии не менее 2,25 м",
          "На расстоянии не менее 2,0 м",
        ],
        correctAnswers: ["На расстоянии не менее 2,5 м"],
      },
      {
        code: "20604207",
        text: "Как часто должна обновляться схема транспортных коммуникаций на угольных разрезах, нанесенная на план горных работ?",
        answers: [
          "Один раз в 2 месяца",
          "Один раз в месяц",
          "Один раз в полугодие",
          "Один раз в квартал",
          "Должна обновляться при изменении схемы",
        ],
        correctAnswers: ["Должна обновляться при изменении схемы"],
      },
      {
        code: "20604208",
        text: "Что из перечисленного не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к устройству переездов на временных железнодорожных путях? Выберите все варианты ответа.",
        answers: [
          "Переезды должны иметь сплошной настил и угол пересечения с железной дорогой не менее 20°",
          "Продольные уклоны дорог на подходах к переезду должны быть не менее 60‰",
          "На расстоянии не менее длины тормозного пути в обе стороны от переезда должны быть установлены предупредительные знаки о подаче свистка машинистом локомотива",
          "Перелом профиля устраивается на расстоянии 5 м от крайнего рельса",
        ],
        correctAnswers: [
          "Переезды должны иметь сплошной настил и угол пересечения с железной дорогой не менее 20°",
          "Продольные уклоны дорог на подходах к переезду должны быть не менее 60‰",
        ],
      },
      {
        code: "20604209",
        text: "Какой должна быть ширина проезжей дороги на переездах временных железнодорожных путей для автосамосвалов грузоподъемностью до 10 т при двухполосном движении?",
        answers: [
          "Не менее 6,5 м",
          "Не менее 8,0 м",
          "Не менее 10,0 м",
          "Не менее 7,5 м",
        ],
        correctAnswers: ["Не менее 10,0 м"],
      },
      {
        code: "20604210",
        text: "Какой должна быть ширина проезжей дороги на переездах временных железнодорожных путей для автосамосвалов грузоподъемностью до 10 т при однополосном движении?",
        answers: [
          "Не менее 7,5 м",
          "Не менее 5,5 м",
          "Не менее 8,5 м",
          "Не менее 10,0 м",
        ],
        correctAnswers: ["Не менее 7,5 м"],
      },
      {
        code: "20604211",
        text: "Каким должно быть нормальное положение шлагбаумов автоматизированного и неавтоматизированного переездов?",
        answers: [
          "Закрытое у автоматизированного и неавтоматизированного переезда",
          "Открытое у автоматизированного и закрытое у неавтоматизированного переездов",
          "Открытое у автоматизированного и неавтоматизированного переездов",
          "Закрытое у автоматизированного и открытое у неавтоматизированного переездов",
        ],
        correctAnswers: [
          "Открытое у автоматизированного и закрытое у неавтоматизированного переездов",
        ],
      },
      {
        code: "20604212",
        text: "Что из перечисленного не соответствует требованиям по обеспечению безопасности движения подвижного состава при ремонте сооружений и устройств?",
        answers: [
          "Запрещается снимать сигналы, ограждающие места работ, до полного их окончания, а также до проверки состояния пути, контактной сети и соблюдения габарита",
          "Запрещается приступать к работам до ограждения сигналами мест производства работ, опасных для следования подвижного состава",
          "Места производства работ, опасные для следования подвижного состава, должны ограждаться сигналами с обеих сторон только на однопутных участках. На двух- и многопутных участках дорог - только на участках, на которых ожидается следование поезда",
          "Перед началом путевых ремонтных работ руководитель обязан проинструктировать работников об условиях безопасного производства этих работ и указать места, куда работники должны уходить во время прохода поездов, а также предупредить дежурного по станции и согласовать с ним условия работы",
        ],
        correctAnswers: [
          "Места производства работ, опасные для следования подвижного состава, должны ограждаться сигналами с обеих сторон только на однопутных участках. На двух- и многопутных участках дорог - только на участках, на которых ожидается следование поезда",
        ],
      },
      {
        code: "20604213",
        text: "Что из перечисленного учитывается инструкцией по эксплуатации железнодорожного транспорта угольного разреза при установлении скорости движения поездов на железнодорожных путях угольного разреза?",
        answers: [
          "Только применяемый подвижной состав",
          "Только верхнее строение и профиль пути",
          "Только местные условия",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20604214",
        text: "На каком расстоянии от конца рельсов устанавливаются предохранительные упоры на забойных и отвальных железнодорожных путях?",
        answers: [
          "На расстоянии не менее 8 м от конца рельсов",
          "На расстоянии не менее 2 м от конца рельсов",
          "На расстоянии не менее 5 м от конца рельсов",
          "На расстоянии не менее 10 м от конца рельсов",
        ],
        correctAnswers: ["На расстоянии не менее 10 м от конца рельсов"],
      },
      {
        code: "20604215",
        text: "Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к следованию поездов вагонами вперед?",
        answers: [
          "Следование поездов вагонами вперед разрешается при наличии вагонов с тормозной площадкой",
          "Следование поездов вагонами вперед разрешается при наличии на переднем вагоне исправной подножки, которая должна быть обращена в сторону движения поезда, на которой должен находиться составитель (кондуктор)",
          "Допускается следование специализированных технологических поездов вагонами вперед без составителя (кондуктора) при обязательном наличии на переднем вагоне (думпкаре) соответствующих звуковых, а в темное время суток и световых сигналов",
          "Хозяйственный поезд, отправляемый на перегон в тупик погрузки и выгрузки вагонами вперед без составителя (кондуктора) и звуковых и световых сигналов, может состоять из вагонов (думпкаров) с числом осей не более 24",
        ],
        correctAnswers: [
          "Хозяйственный поезд, отправляемый на перегон в тупик погрузки и выгрузки вагонами вперед без составителя (кондуктора) и звуковых и световых сигналов, может состоять из вагонов (думпкаров) с числом осей не более 24",
        ],
      },
      {
        code: "20604216",
        text: "Какой должна быть высота подвески контактного провода на постоянных путях над головкой рельса на станциях и на перегонах соответственно?",
        answers: [
          "Соответственно не менее 5750 мм и не менее 5250 мм",
          "Соответственно не менее 5250 мм и не менее 4750 мм",
          "Соответственно не менее 6250 мм и не менее 5750 мм",
          "Соответственно не менее 4750 мм и не менее 4250 мм",
        ],
        correctAnswers: ["Соответственно не менее 6250 мм и не менее 5750 мм"],
      },
      {
        code: "20604217",
        text: "Какой должна быть высота подвески контактного провода над уровнем головки рельса на передвижных железнодорожных путях при боковой подвеске?",
        answers: [
          "Не менее 4200 мм",
          "Не менее 4000 мм",
          "Не менее 3800 мм",
          "Не менее 4400 мм",
        ],
        correctAnswers: ["Не менее 4400 мм"],
      },
      {
        code: "20604218",
        text: "Каким должно быть расстояние от оси крайнего железнодорожного пути до внутреннего края опор контактной сети на перегонах и железнодорожных станциях?",
        answers: [
          "Не менее 3000 мм",
          "Не менее 3100 мм",
          "Не менее 2800 мм",
          "Не менее 2900 мм",
        ],
        correctAnswers: ["Не менее 3100 мм"],
      },
      {
        code: "20604219",
        text: "При каком расстоянии от металлических конструкций, (мостов, путепроводов, светофоров и т.п.), от частей контактной сети, находящихся под напряжением эти конструкции должны быть заземлены?",
        answers: [
          "На расстоянии более 5 м",
          "На расстоянии 5 м",
          "На расстоянии менее 5 м",
          "На расстоянии 5 м и более",
        ],
        correctAnswers: ["На расстоянии менее 5 м"],
      },
      {
        code: "20604220",
        text: "На какую величину меньше высоты подвески контактного провода должна быть минимальная высота габаритных ворот около переездов с обеих сторон на всех пересечениях электрифицированных путей с автомобильными дорогами и пунктами, где проводятся погрузочно-разгрузочные работы?",
        answers: ["На 0,3 м", "На 0,4 м", "На 0,5 м", "На 0,6 м"],
        correctAnswers: ["На 0,5 м"],
      },
      {
        code: "20604221",
        text: "Какой минимальной ширины должен быть свободный проход с обеих сторон транспортного средства при его движении через временные въезды в траншеи?",
        answers: ["1,5 м", "0,8 м", "2,0 м", "1,7 м"],
        correctAnswers: ["1,5 м"],
      },
      {
        code: "20604222",
        text: "Какой минимальной длины должны быть площадки при уклонах дорог длиной более 600 м и более 60 промилле (‰)?",
        answers: ["50 м", "10 м", "25 м", "75 м"],
        correctAnswers: ["50 м"],
      },
      {
        code: "20604223",
        text: "С каким максимальным интервалом должны устраиваться площадки при уклонах дорог длиной более 600 м и более 60 промилле (‰)?",
        answers: [
          "Через каждые 700 м длины затяжного уклона",
          "Через каждые 400 м длины затяжного уклона",
          "Через каждые 500 м длины затяжного уклона",
          "Через каждые 600 м длины затяжного уклона",
          "Через каждые 300 м длины затяжного уклона",
        ],
        correctAnswers: ["Через каждые 600 м длины затяжного уклона"],
      },
      {
        code: "20604224",
        text: "С каким максимальным уклоном должны устраиваться площадки при уклонах дорог длиной более 600 м и более 60 промилле (‰)?",
        answers: ["20‰", "15‰", "25‰", "10‰"],
        correctAnswers: ["20‰"],
      },
      {
        code: "20604225",
        text: "Какое из перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом к эксплуатации технологического автомобильного транспорта указано неверно?",
        answers: [
          "Допускается использование автомобилей, не имеющих техническую документацию, выданную заводом-изготовителем и содержащую его основные технические и эксплуатационные характеристики, при условии, что все их агрегаты и узлы, обеспечивающие безопасность движения, находятся в технически исправном состоянии",
          "Автомобили должны иметь необходимый запас горючего и комплект инструмента, предусмотренный заводом-изготовителем",
          "На линию автомобили могут выпускаться только при условии, если все их агрегаты и узлы, обеспечивающие безопасность движения, а также безопасность других работ, предусмотренных технологией применения транспортных средств, находятся в технически исправном состоянии",
        ],
        correctAnswers: [
          "Допускается использование автомобилей, не имеющих техническую документацию, выданную заводом-изготовителем и содержащую его основные технические и эксплуатационные характеристики, при условии, что все их агрегаты и узлы, обеспечивающие безопасность движения, находятся в технически исправном состоянии",
        ],
      },
      {
        code: "20604226",
        text: "Что из перечисленного не соответствует условиям погрузки горной массы в автомобили экскаваторами?",
        answers: [
          "Ожидающий погрузки автомобиль должен находиться за пределами радиуса действия экскаватора (погрузчика) и становиться под погрузку только после звукового и (или) светового сигналов машиниста экскаватора (погрузчика) или оператора погрузочного устройства",
          "Запрещается односторонняя или сверхгабаритная, а также превышающая установленную грузоподъемность автомобиля загрузка",
          "Погрузка в кузов автомобиля производится только сзади или, осуществляя перенос экскаваторного ковша, над кабиной",
          "Высота падения груза должна быть минимально возможной и во всех случаях не должна превышать 3 м",
        ],
        correctAnswers: [
          "Погрузка в кузов автомобиля производится только сзади или, осуществляя перенос экскаваторного ковша, над кабиной",
        ],
      },
      {
        code: "20604227",
        text: "Что из перечисленного разрешается при работе автомобиля на линии?",
        answers: [
          "Перевозка посторонних людей в кабине без разрешения руководителя (главного инженера) организации или главного инженера угольного разреза",
          "Движение вдоль железнодорожных путей на расстоянии более 5 м от ближайшего рельса",
          "Переезд кабелей, уложенных по почве и не огражденных специальными предохранительными устройствами",
          "Нахождение персонала под поднятым, незастопоренным кузовом",
          "Выход из кабины автомобиля до полного подъема или опускания кузова",
        ],
        correctAnswers: [
          "Движение вдоль железнодорожных путей на расстоянии более 5 м от ближайшего рельса",
        ],
      },
      {
        code: "20604228",
        text: "Какой минимальной ширины должен быть проход для людей в галереях и эстакадах вновь проектируемых угольных разрезов у конвейера при ширине ленты до 1400 мм?",
        answers: ["700 мм", "1000 мм", "1400 мм", "800 мм"],
        correctAnswers: ["800 мм"],
      },
      {
        code: "20604229",
        text: "До какой максимальной высоты над уровнем земли разрешается использование конвейеров, не оборудованных по всей длине с обеих сторон непрерывными боковыми площадками шириной не менее 0,3 м?",
        answers: ["0,8 м", "1,1 м", "1,8 м", "1,5 м", "1,3 м"],
        correctAnswers: ["1,8 м"],
      },
      {
        code: "20604230",
        text: "С какой периодичностью следует проверять тормоза приводов конвейеров?",
        answers: [
          "Не реже одного раза в полугодие",
          "Не реже одного раза в 2 месяца",
          "Не реже одного раза в квартал",
          "Не реже одного раза в тридцать дней",
        ],
        correctAnswers: ["Не реже одного раза в тридцать дней"],
      },
      {
        code: "20604231",
        text: "Что из перечисленного не соответствует требованиям безопасности к расположению гибкого кабеля, питающего передвижные механизмы?",
        answers: [
          "Гибкие кабели должны подвешиваться с провисом",
          "Расположение кабеля на почве должно исключать возможность его повреждения движущейся машиной",
          "Высота подвеса кабеля должна исключать возможность его повреждения",
          "Допускается располагать на почве часть гибкого кабеля, питающего передвижные механизмы, на протяжении не более 25 м",
        ],
        correctAnswers: [
          "Допускается располагать на почве часть гибкого кабеля, питающего передвижные механизмы, на протяжении не более 25 м",
        ],
      },
      {
        code: "20604232",
        text: "Каким должно быть минимальное расстояние между стоящими на разгрузке и проезжающими транспортными средствами?",
        answers: ["3 м", "2 м", "6 м", "5 м", "4 м"],
        correctAnswers: ["5 м"],
      },
      {
        code: "20604233",
        text: "Какие схемы электроснабжения должны быть в наличии на угольном разрезе?",
        answers: [
          "Только принципиальная однолинейная схема",
          "Только отдельная схема электроснабжения для сезонных электроустановок перед вводом их в работу",
          "Только схема электроснабжения, нанесенная на план горных работ, утвержденная техническим руководителем (главным инженером) угольного разреза",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20604234",
        text: "Что из перечисленного не соответствует требованиям организации безопасного обслуживания электроустановок и сетей?",
        answers: [
          "Лица, ответственные за безопасную эксплуатацию электроустановок, должны быть обучены и аттестованы на знание правил безопасной эксплуатации электроустановок",
          "Лица, работающие в электроустановках и на ЛЭП, должны выполнять организационные и технические мероприятия, предусмотренные требованиями Правил безопасности при разработке угольных месторождений открытым способом и других нормативных правовых актов по безопасной эксплуатации электроустановок",
          "Для организации безопасного обслуживания электроустановок и сетей должны быть определены и оформлены распоряжениями технического руководителя (главного инженера) угольного разреза границы обслуживания электротехническим персоналом, назначены лица, ответственные за безопасную эксплуатацию электроустановок по организации и структурным подразделениям",
          "При изменении электроснабжения электроустановки все изменения необходимо внести в схему электроснабжения в течение трех суток",
        ],
        correctAnswers: [
          "При изменении электроснабжения электроустановки все изменения необходимо внести в схему электроснабжения в течение трех суток",
        ],
      },
      {
        code: "20604235",
        text: "В каких случаях допускается применение сетей напряжением до 1 кВ с глухозаземленной нейтралью?",
        answers: [
          "Для питания стационарных потребителей, расположенных за пределами открытых горных работ",
          "Для питающихся от отдельных трансформаторов установок освещения стационарных перегрузочных пунктов и отвалов",
          "Для въездных (выездных) траншей",
          "Для осветительных установок и сетей СЦБ",
          "Во всех перечисленных",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "20604236",
        text: "В какие сроки должна проверяться исправность действия (срабатывания) реле утечки тока?",
        answers: [
          "Один раз в 10 дней",
          "Один раз в 3 дня",
          "Один раз в сутки",
          "В каждой смене перед началом работы",
        ],
        correctAnswers: ["В каждой смене перед началом работы"],
      },
      {
        code: "20604237",
        text: "Электроприводы каких установок не оборудуются электрической блокировкой, исключающей самозапуск механизмов после подачи напряжения питания?",
        answers: [
          "Электроприводы экскаваторов, буровых станков",
          "Электроприводы отвалообразователей, конвейеров",
          "Электроприводы автоматизированных компрессорных установок, водоотливов",
          "Электроприводы насосов",
        ],
        correctAnswers: [
          "Электроприводы автоматизированных компрессорных установок, водоотливов",
        ],
      },
      {
        code: "20604238",
        text: "Каков минимальный период проверки и контрольной наладки (испытания) основной селективной защиты от замыкания на землю на подстанциях, от которых получают питание передвижные карьерные электроустановки?",
        answers: ["3 месяца", "12 месяцев", "Не нормируется", "6 месяцев"],
        correctAnswers: ["6 месяцев"],
      },
      {
        code: "20604239",
        text: "Каким должно быть максимальное сопротивление общего заземляющего устройства разреза?",
        answers: [
          "Не более 4 Ом",
          "Не более 6 Ом",
          "Не более 8 Ом",
          "Не более 10 Ом",
        ],
        correctAnswers: ["Не более 4 Ом"],
      },
      {
        code: "20604240",
        text: "Какова минимальная периодичность измерения сопротивления общего заземляющего устройства передвижных электроустановок?",
        answers: [
          "1 раз в 30 дней",
          "1 раз в 6 месяцев",
          "1 раз в квартал",
          "1 раз в год",
        ],
        correctAnswers: ["1 раз в 30 дней"],
      },
      {
        code: "20604241",
        text: "В какие сроки проводится измерение сопротивления стационарных электроустановок?",
        answers: [
          "В период наибольшего промерзания грунта зимой",
          "В периоды наибольшего высыхания грунта летом и наибольшего промерзания грунта зимой",
          "В грозовые периоды весной и летом",
          "В период наибольшего высыхания грунта летом",
        ],
        correctAnswers: [
          "В периоды наибольшего высыхания грунта летом и наибольшего промерзания грунта зимой",
        ],
      },
      {
        code: "20604242",
        text: "При каком минимальном расстоянии по воздуху от подъемной или выдвижной части в любом ее положении, в том числе и при наибольшем допускаемом конструкцией подъеме или боковом вылете, до ближайшего провода воздушной линии электропередачи, находящейся под напряжением 35 кВ, допускается работа экскаваторов, погрузчиков, буровых станков?",
        answers: ["1 м", "2 м", "3 м", "4 м"],
        correctAnswers: ["4 м"],
      },
      {
        code: "20604243",
        text: "Каким должно быть расстояние от нижнего фазного провода воздушной линии электропередачи напряжением 35 кВ до верхней точки машин или груза при пересечении (сближении) воздушной линии электропередачи с автомобильными дорогами?",
        answers: [
          "Не менее 1,0 м",
          "Не менее 2,0 м",
          "Не менее 1,5 м",
          "Не менее 2,5 м",
        ],
        correctAnswers: ["Не менее 2,5 м"],
      },
      {
        code: "20604244",
        text: "Каким должно быть расстояние между передвижными опорами временных технологических воздушных линий электропередачи напряжением до 35 кВ?",
        answers: [
          "Определяется по расчету с учетом климатических условий и обеспечения устойчивости опор и, как правило, не должно превышать 90 м",
          "Определяется по расчету с учетом климатических условий и обеспечения устойчивости опор и, как правило, не должно превышать 75 м",
          "Определяется по расчету с учетом климатических условий и обеспечения устойчивости опор и, как правило, не должно превышать 50 м",
          "Определяется по расчету с учетом климатических условий и обеспечения устойчивости опор и, как правило, не должно превышать 60 м",
        ],
        correctAnswers: [
          "Определяется по расчету с учетом климатических условий и обеспечения устойчивости опор и, как правило, не должно превышать 50 м",
        ],
      },
      {
        code: "20604245",
        text: "В каком случае выполняется одинарное крепление проводов?",
        answers: [
          "При пересечении воздушных линий электропередачи с контактной сетью",
          "При пересечении воздушных линий электропередачи с постоянными технологическими дорогами",
          "При пересечении воздушных линий электропередачи с воздушной линией электропередач",
          "При пересечении воздушных линий электропередачи с автомобильными дорогами, проложенными по уступам и отвалам",
        ],
        correctAnswers: [
          "При пересечении воздушных линий электропередачи с автомобильными дорогами, проложенными по уступам и отвалам",
        ],
      },
      {
        code: "20604246",
        text: "В каком случае нарушены требования по прокладке и переноске (перетаскиванию) гибкого кабеля?",
        answers: [
          "Гибкие кабели, питающие передвижные машины, необходимо прокладывать так, чтобы исключалась возможность их повреждения, примерзания, завала породой, наезда на них транспортных средств и механизмов",
          "Переноска (перетаскивание) гибкого кабеля должна производиться с помощью механизмов с применением приспособлений, обеспечивающих ограничение радиуса изгиба кабеля, или вручную",
          "На обводненной площади кабель прокладывается по дну, для исключения самопроизвольного перемещения используются пригрузы",
          "При переноске (перетаскивании) экскаваторного кабеля, находящегося под напряжением, обслуживающий персонал обязан пользоваться диэлектрическими перчатками или специальными устройствами с изолирующими рукоятками",
        ],
        correctAnswers: [
          "На обводненной площади кабель прокладывается по дну, для исключения самопроизвольного перемещения используются пригрузы",
        ],
      },
      {
        code: "20604247",
        text: "Какими должны быть размеры укрытий (труб, коробов и т. п.), применяемых в целях защиты от повреждений кабелей, в местах пересечения с железнодорожными путями и автомобильными дорогами?",
        answers: [
          "Должны превышать ширину железнодорожных путей или автомобильных дорог не менее чем на 1,2 м в каждую сторону",
          "Должны превышать ширину железнодорожных путей или автомобильных дорог не менее чем на 2 м в каждую сторону",
          "Должны превышать ширину железнодорожных путей или автомобильных дорог не менее чем на 1,7 м в каждую сторону",
          "Должны превышать ширину железнодорожных путей или автомобильных дорог не менее чем на 1,5 м в каждую сторону",
        ],
        correctAnswers: [
          "Должны превышать ширину железнодорожных путей или автомобильных дорог не менее чем на 2 м в каждую сторону",
        ],
      },
      {
        code: "20604248",
        text: "Какое максимальное количество соединений допускается в каждом пролете на один провод или трос?",
        answers: [
          "6 соединений",
          "4 соединения",
          "3 соединения",
          "5 соединений",
        ],
        correctAnswers: ["3 соединения"],
      },
      {
        code: "20604249",
        text: "Где разрешается применение напряжения 380/220 В в сети с заземленной нейтралью для освещения?",
        answers: [
          "Для освещения отвалов и автомобильных дорог вне угольного разреза при питании от отдельных трансформаторных подстанций",
          "Для осветительных сетей на угольном разрезе",
          "Для стационарных световых точек на передвижных машинах",
          "Для стационарных световых точек на механизмах и агрегатах",
        ],
        correctAnswers: [
          "Для освещения отвалов и автомобильных дорог вне угольного разреза при питании от отдельных трансформаторных подстанций",
        ],
      },
      {
        code: "20604250",
        text: "В каком случае требования по подвеске проводов электрического освещения и светильников на стационарных и передвижных опорах контактной сети постоянного тока напряжением до 1650 В включительно указаны неверно?",
        answers: [
          "Расстояние от контактного провода до проводов освещения по вертикали должно быть не менее 1,5 м",
          "Изоляторы осветительной сети принимаются на напряжение 1650 В",
          "Расстояние от контактного провода до опоры при боковой подвеске должно быть не менее 0,5 м",
          "Подвеска проводов электрического освещения и светильников допускается выше контактного провода на противоположной от него стороне опоры",
        ],
        correctAnswers: [
          "Расстояние от контактного провода до опоры при боковой подвеске должно быть не менее 0,5 м",
        ],
      },
    ],
    20605: [
      {
        code: "20605000",
        text: "Каким федеральным органом исполнительной власти оформляется горноотводная документация?",
        answers: [
          "Министерством природных ресурсов и экологии Российской Федерации",
          "Федеральным агентством по недропользованию",
          "Органом государственного горного надзора и уполномоченными органами исполнительной власти субъектов Российской Федерации",
          "Федеральным агентством в сфере природопользования",
        ],
        correctAnswers: [
          "Органом государственного горного надзора и уполномоченными органами исполнительной власти субъектов Российской Федерации",
        ],
      },
      {
        code: "20605001",
        text: "Кем оформляется документация, удостоверяющая уточненные границы горного отвода, если участки недр местного значения расположены на территориях 2 и более субъектов Российской Федерации и если нормативными правовыми актами субъекта Российской Федерации не определен орган исполнительной власти субъекта Российской Федерации?",
        answers: [
          "Органом государственного горного надзора",
          "Органами исполнительной власти каждого из субъектов Российской Федерации",
          "Органом исполнительной власти любого из причастных к горному отводу субъектов Российской Федерации",
          "Технической службой организации - пользователя недр",
        ],
        correctAnswers: ["Органом государственного горного надзора"],
      },
      {
        code: "20605002",
        text: "На какой срок оформляется документация, удостоверяющая уточненные границы горного отвода?",
        answers: [
          "На срок действия лицензии на пользование недрами",
          "На 1 год",
          "Не более чем на 3 года",
          "Срок не ограничен",
        ],
        correctAnswers: ["На срок действия лицензии на пользование недрами"],
      },
      {
        code: "20605003",
        text: "Каким образом осуществляется пересылка документации, содержащей сведения, представляющие государственную тайну?",
        answers: [
          'Заказным почтовым отправлением через "Почту России"',
          "В соответствии с законодательством Российской Федерации о государственной тайне",
          "Курьером любой курьерской фирмы",
          "Курьером фирмы, подготовившей документацию",
        ],
        correctAnswers: [
          "В соответствии с законодательством Российской Федерации о государственной тайне",
        ],
      },
      {
        code: "20605004",
        text: "В какой срок со дня поступления заявления осуществляется оформление документации, удостоверяющей уточненные границы горного отвода?",
        answers: [
          "Не более 12 дней",
          "Не более 5 дней",
          "Не более 25 дней",
          "Не более 20 дней",
        ],
        correctAnswers: ["Не более 25 дней"],
      },
      {
        code: "20605005",
        text: "В какой срок органом исполнительной власти субъекта Российской Федерации оформленная документация, удостоверяющая уточненные границы горного отвода, передается пользователю недр?",
        answers: [
          "Не позднее 7 дней со дня оформления",
          "Не позднее 5 дней со дня оформления",
          "Не позднее 20 дней со дня оформления",
          "Не позднее 25 дней со дня оформления",
        ],
        correctAnswers: ["Не позднее 5 дней со дня оформления"],
      },
      {
        code: "20605006",
        text: "Сколько экземпляров документации, удостоверяющей уточненные границы горного отвода, оформляется органом исполнительной власти субъекта Российской Федерации?",
        answers: [
          "2 экземпляра",
          "3 экземпляра",
          "4 экземпляра",
          "5 экземпляров",
        ],
        correctAnswers: ["3 экземпляра"],
      },
      {
        code: "20605007",
        text: "В каких случаях документация, удостоверяющая уточненные границы горного отвода, подлежит переоформлению?",
        answers: [
          "Только при переоформлении лицензии на пользование недрами",
          "Только при изменении геологической информации о недрах, наличии технологических потребностей, условий и факторов, влияющих на безопасное состояние недр, земной поверхности и расположенных на ней объектов",
          "Только при изменении технического проекта выполнения работ, связанных с пользованием недрами, влияющих на безопасное состояние недр, земной поверхности и расположенных на ней объектов",
          "Во всех перечисленных случаях, а также при выявлении технических ошибок в лицензии на пользование недрами",
        ],
        correctAnswers: [
          "Во всех перечисленных случаях, а также при выявлении технических ошибок в лицензии на пользование недрами",
        ],
      },
      {
        code: "20605008",
        text: "Какие параметры эксплуатации объектов ведения горных работ планами и схемами развития горных работ не определяются?",
        answers: [
          "Объемы добычи и первичной переработки полезных ископаемых",
          "Направления развития горных работ",
          "Условия безопасного недропользования, технические и технологические решения при эксплуатации объектов ведения горных работ",
          "Штатная численность сотрудников объекта ведения горных работ",
        ],
        correctAnswers: [
          "Штатная численность сотрудников объекта ведения горных работ",
        ],
      },
      {
        code: "20605009",
        text: "На какие перечисленные виды работ не составляются планы и схемы горных работ?",
        answers: [
          "На работы, связанные со вторичной переработкой минерального сырья",
          "На вскрышные работы",
          "На маркшейдерские работы",
          "На работы по добыче полезных ископаемых",
        ],
        correctAnswers: [
          "На работы, связанные со вторичной переработкой минерального сырья",
        ],
      },
      {
        code: "20605010",
        text: "На какой срок составляется план развития горных работ по всем планируемым видам горных работ?",
        answers: ["На 3 месяца", "На 1 год", "На 5 лет", "На 2 года"],
        correctAnswers: ["На 1 год"],
      },
      {
        code: "20605011",
        text: "На какой максимальный срок составляется схема развития горных работ?",
        answers: ["На 2 года", "На 10 лет", "На 5 лет", "На 6 лет"],
        correctAnswers: ["На 5 лет"],
      },
      {
        code: "20605012",
        text: "На основе чего составляются планы и схемы развития горных работ?",
        answers: [
          "На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами",
          "На основе рекомендаций территориальных комиссий по запасам полезных ископаемых Минприроды России",
          "На основе технического регламента",
          "На основе локального плана проведения работ",
          "На основе рекомендаций Ростехнадзора",
          "На основе ПЛА",
        ],
        correctAnswers: [
          "На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами",
        ],
      },
      {
        code: "20605013",
        text: "Кем осуществляются подготовка и утверждение планов и схем развития горных работ?",
        answers: [
          "Подготовка - организацией, привлекаемой пользователем недр, утверждение - руководителем территориального органа государственного горного надзора",
          "Подготовка - пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ, утверждение - руководителем организации - пользователя недр",
          "Подготовка - организацией, привлекаемой пользователем недр, утверждение - представителем местной исполнительной власти",
          "Подготовка - пользователем недр, утверждение - руководителем территориального органа государственного горного надзора",
        ],
        correctAnswers: [
          "Подготовка - пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ, утверждение - руководителем организации - пользователя недр",
        ],
      },
      {
        code: "20605014",
        text: "С кем необходимо согласовывать планы и схемы развития горных работ?",
        answers: [
          "С территориальными органами Ростехнадзора",
          "Со специализированными организациями МЧС",
          "С органами исполнительной власти субъекта Российской Федерации, на территории которого эксплуатируется разрез",
          "Со сторонними организациями, расположенными на территории горного разреза",
        ],
        correctAnswers: ["С территориальными органами Ростехнадзора"],
      },
      {
        code: "20605015",
        text: "В какой срок пользователи недр представляют заявление о согласовании планов и схем развития горных работ в орган государственного горного надзора?",
        answers: [
          "До 1 апреля текущего года",
          "До 15 августа текущего года",
          "До 1 сентября текущего года",
          "До 15 декабря текущего года",
        ],
        correctAnswers: ["До 1 сентября текущего года"],
      },
      {
        code: "20605016",
        text: "В какой срок планы и (или) схемы развития горных работ направляются пользователем недр в орган государственного горного надзора для рассмотрения?",
        answers: [
          "За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
          "В течение 15 дней с назначенной даты рассмотрения планов и (или) схем развития горных работ",
          "За 5 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
          "В назначенный день рассмотрения планов и (или) схем развития горных работ",
        ],
        correctAnswers: [
          "За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ",
        ],
      },
      {
        code: "20605017",
        text: "Что из перечисленного не является основанием для принятия решения об отказе в согласовании плана и (или) схемы развития горных работ?",
        answers: [
          "Наличие недостоверных сведений в представленных документах",
          "Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями",
          "Отсутствие обоснования соблюдения условий безопасного недропользования",
          "Отсутствие геологического и маркшейдерского обеспечения горных работ",
        ],
        correctAnswers: [
          "Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями",
        ],
      },
      {
        code: "20605018",
        text: "Кем утверждаются план и схема развития горных работ?",
        answers: [
          "Техническим руководителем организации - пользователя недр",
          "Руководителем организации - пользователя недр",
          "Органом государственного горного надзора",
          "Органом исполнительной власти субъекта Российской Федерации",
          "Руководителем проектной организации",
        ],
        correctAnswers: ["Руководителем организации - пользователя недр"],
      },
      {
        code: "20605019",
        text: "Что должно входить в состав планов и схем развития горных работ?",
        answers: [
          "Список с указанием паспортных данных каждого работника",
          "Графическая часть и пояснительная записка с табличными материалами",
          "Фотографии мест дальнейшего производства работ",
          "Наряд-допуск на выполнение работ",
        ],
        correctAnswers: [
          "Графическая часть и пояснительная записка с табличными материалами",
        ],
      },
      {
        code: "20605020",
        text: "В каком виде подготавливаются схемы развития горных работ в отношении вскрышных, подготовительных, рекультивационных работ, а также работ по добыче полезных ископаемых и связанной с ней первичной переработкой минерального сырья, содержащие графическую часть и пояснительную записку с табличными материалами?",
        answers: [
          "Только в виде схем эксплуатации объектов недропользования и (или) их отдельных участков",
          "Во всех перечисленных",
          "Только в виде схем эксплуатации объектов первичной переработки (подготовки) минерального сырья (для месторождений твердых полезных ископаемых, включая общераспространенные)",
          "Только в виде схем эксплуатации объектов обустройства и схемы подготовки углеводородов (для месторождений углеводородного сырья)",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "20605021",
        text: "Что из перечисленного должны обеспечивать мероприятия по выполнению основных требований по безопасному ведению работ, связанных с пользованием недрами?",
        answers: [
          "Только защиту объектов ведения горных работ и месторождений полезных ископаемых от затопления, обводнения, взрывов, пожаров, горных ударов",
          "Только применение средств и технологий, повышающих безопасное ведение горных работ и уровень извлечения полезных ископаемых",
          "Только рекультивацию нарушенных горными работами земель (при разработке месторождений твердых полезных ископаемых)",
          "Все перечисленное, включая ликвидацию (консервацию) отработанных горных выработок, скважин, блоков, горизонтов, объектов обустройства и иных объектов, связанных с горным производством",
        ],
        correctAnswers: [
          "Все перечисленное, включая ликвидацию (консервацию) отработанных горных выработок, скважин, блоков, горизонтов, объектов обустройства и иных объектов, связанных с горным производством",
        ],
      },
      {
        code: "20605022",
        text: "Из чего должны состоять графические материалы планов (схем) развития горных работ в зависимости от видов горных работ и видов полезных ископаемых?",
        answers: [
          "Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия",
          "Из структурных карт, характерных разрезов, проекций, схем",
          "Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем",
          "Из схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ)",
        ],
        correctAnswers: [
          "Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем",
        ],
      },
      {
        code: "20605023",
        text: "Какие данные не должны указываться в штампе графических материалов планов развития горных работ?",
        answers: [
          "Все ответы неверны",
          "Дата составления",
          "Подписи лиц (с указанием должности), ответственных за руководство геологическими и маркшейдерскими работами",
          "Наименования организации, объекта недропользования (участка недр)",
          "Название графического документа",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20605024",
        text: "Кто из перечисленных лиц подписывает титульный лист плана (схемы) развития горных работ?",
        answers: [
          "Лица, ответственные за руководство горными работами пользователя недр",
          "Лица, ответственные за руководство маркшейдерскими работами",
          "Лица, ответственные за руководство горными, геологическими и маркшейдерскими работами пользователя недр и (или) привлекаемые пользователем недр на договорной основе организации для осуществления работ, связанных с пользованием недрами",
          "Лица, ответственные за руководство геологическими работами, и представитель Ростехнадзора",
        ],
        correctAnswers: [
          "Лица, ответственные за руководство горными, геологическими и маркшейдерскими работами пользователя недр и (или) привлекаемые пользователем недр на договорной основе организации для осуществления работ, связанных с пользованием недрами",
        ],
      },
      {
        code: "20605025",
        text: "Кем должны быть подписаны планы (схемы) развития горных работ, направляемые пользователем недр в электронном виде?",
        answers: [
          "Комиссией пользователя недр (подрядной организации)",
          "Техническим руководителем пользователя недр (подрядной организации)",
          "Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом",
          "Руководителем проектной организации",
        ],
        correctAnswers: [
          "Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом",
        ],
      },
      {
        code: "20605026",
        text: "В какой период осуществляется рассмотрение планов развития горных работ?",
        answers: [
          "В период с 20 сентября по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
          "В период с 19 сентября по 30 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
          "В период с 20 августа по 25 ноября года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
          "В период с 20 октября по 25 января года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
        ],
        correctAnswers: [
          "В период с 20 сентября по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ",
        ],
      },
      {
        code: "20605027",
        text: "В какой системе координат должны быть определены уточненные границы горного отвода?",
        answers: [
          "В полярной системе координат (азимут, горизонтальное проложение)",
          "В прямоугольной системе координат",
          "В географической системе координат (ширина, долгота)",
        ],
        correctAnswers: ["В прямоугольной системе координат"],
      },
      {
        code: "20605028",
        text: "При каких условиях недропользователю устанавливаются уточненные границы горного отвода?",
        answers: [
          "Одновременно с получением лицензии на пользование недрами с целью разработки газовых и нефтяных месторождений",
          "В процессе разработки месторождений нефти или газа по требованию органа, выдавшего лицензию на пользование недрами",
          "В ходе ликвидации, консервации скважин и оборудования их устьев и стволов",
          "После предоставления лицензии на пользование недрами, разработки документов, подтверждающих согласование проектной документации, и получения положительного заключения государственной экспертизы",
        ],
        correctAnswers: [
          "После предоставления лицензии на пользование недрами, разработки документов, подтверждающих согласование проектной документации, и получения положительного заключения государственной экспертизы",
        ],
      },
      {
        code: "20605029",
        text: "Кем подписывается проект горного отвода (пояснительная записка)?",
        answers: [
          "Главным геологом и главным маркшейдером организации, испрашивающей горный отвод, ответственным специалистом - разработчиком проекта разработки месторождения полезного ископаемого, руководителем организации, разработавшей проект горного отвода",
          "Техническим руководителем организации, испрашивающей горный отвод, главным инженером проекта разработки месторождения полезного ископаемого, руководителем организации, разработавшей проект горного отвода",
          "Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода",
          "Руководителем организации, испрашивающей горный отвод, и руководителем организации, разработавшей проект горного отвода",
        ],
        correctAnswers: [
          "Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода",
        ],
      },
      {
        code: "20605030",
        text: "Где в целях промышленной безопасности не допускается бурение скважин на нефть на площадях залегания калийных солей?",
        answers: [
          "Только на площадях залегания балансовых запасов калийных солей категорий А, В",
          "На площадях залегания балансовых запасов калийных солей категорий А, В, С1, а также в пределах охранной зоны вокруг указанных площадей",
          "Только в пределах охранной зоны вокруг площадей залегания балансовых запасов калийных солей категорий А, В, С1",
        ],
        correctAnswers: [
          "На площадях залегания балансовых запасов калийных солей категорий А, В, С1, а также в пределах охранной зоны вокруг указанных площадей",
        ],
      },
      {
        code: "20605031",
        text: "На какую глубину простирается охранная зона площадей залегания балансовых запасов калийных солей?",
        answers: [
          "На 300 м ниже подошвы подстилающей каменной соли, но не менее 800 м ниже калийной залежи",
          "На 200 м ниже подошвы подстилающей каменной соли, но не менее 400 м ниже калийной залежи",
          "Не менее 500 м ниже калийной залежи",
          "На 600 м ниже подошвы подстилающей каменной соли",
        ],
        correctAnswers: [
          "На 300 м ниже подошвы подстилающей каменной соли, но не менее 800 м ниже калийной залежи",
        ],
      },
      {
        code: "20605032",
        text: "В каких случаях в целях обеспечения промышленной безопасности не допускается промышленная разработка месторождений нефти на площадях залегания запасов калийных солей?",
        answers: [
          "На площадях залегания калийных солей, разведанных по категории С2",
          "На снятых с государственного баланса запасах",
          "На забалансовых запасах",
          "На неразведанных площадях",
        ],
        correctAnswers: [
          "На площадях залегания калийных солей, разведанных по категории С2",
        ],
      },
      {
        code: "20605033",
        text: "Какое установлено максимальное расстояние между устьями скважин в кусте при промышленном освоении нефтяных месторождений на площадях залегания калийных солей?",
        answers: ["10 м", "12 м", "15 м", "20 м"],
        correctAnswers: ["15 м"],
      },
      {
        code: "20605034",
        text: "В течение какого времени производится закладка наблюдательной станции для проведения маркшейдерских инструментальных наблюдений и определения величин деформаций над вводимыми в разработку нефтяными месторождениями при совместном освоении месторождений нефти и калийных солей?",
        answers: [
          "Не позднее чем за 6 месяцев до начала пробной эксплуатации нефтяной залежи",
          "Не позднее чем за 3 месяца до начала пробной эксплуатации нефтяной залежи",
          "Не позднее чем за 1 месяц до начала пробной эксплуатации нефтяной залежи",
          "По мере необходимости",
        ],
        correctAnswers: [
          "Не позднее чем за 6 месяцев до начала пробной эксплуатации нефтяной залежи",
        ],
      },
      {
        code: "20605035",
        text: "Кем осуществляется контроль за изменением геомеханического и геодинамического состояния недр при освоении месторождений нефти на площадях залегания калийных солей?",
        answers: [
          "Федеральным агентством по недропользованию",
          "Федеральной службой по надзору в сфере природопользования",
          "Организациями, имеющими соответствующую лицензию Ростехнадзора на право производства маркшейдерских работ",
          "Эксплуатирующей организацией",
          "Проектной организацией",
        ],
        correctAnswers: [
          "Организациями, имеющими соответствующую лицензию Ростехнадзора на право производства маркшейдерских работ",
        ],
      },
      {
        code: "20605036",
        text: "Каким образом оценивается состояние ствола скважины при разработке залежей нефти на площадях залегания калийных солей?",
        answers: [
          "По результатам контрольных геофизических исследований скважин",
          "По результатам инструментальных наблюдений",
          "По результатам комплексного анализа",
          "По данным нивелировки устья скважины",
        ],
        correctAnswers: ["По данным нивелировки устья скважины"],
      },
      {
        code: "20605037",
        text: "С какой периодичностью проводится оценка влияния разработки нефтяных месторождений на сохранность калийных солей?",
        answers: [
          "Не реже одного раза в два года",
          "Ежегодно",
          "Не реже одного раза в три года",
          "Не реже одного раза в пять лет",
        ],
        correctAnswers: ["Не реже одного раза в пять лет"],
      },
      {
        code: "20605038",
        text: "Каким проверкам подвергается подводный трубопровод после капитального ремонта?",
        answers: [
          "Проверкам методами неразрушающего контроля",
          "Проверкам роботизированными подводными аппаратами",
          "Проверкам на прочность и герметичность",
        ],
        correctAnswers: ["Проверкам на прочность и герметичность"],
      },
      {
        code: "20605039",
        text: "Какие карты обязана иметь эксплуатирующая организация ОПО МНГК?",
        answers: [
          "Только карты подводных коммуникаций в районе ведения работ",
          "Все карты подводных и надводных коммуникаций в районе ведения работ",
          "Только карты надводных коммуникаций в районе ведения работ",
          "Климатическиe карты района",
          "Топографические карты района",
        ],
        correctAnswers: [
          "Все карты подводных и надводных коммуникаций в районе ведения работ",
        ],
      },
      {
        code: "20605040",
        text: "Каким образом следует располагать здания и сооружения с производственными процессами, выделяющими в атмосферу вредные и (или) горючие вещества при обустройстве нефтяных, газовых и газоконденсатных месторождений?",
        answers: [
          "За пределами аварийной зоны населенных пунктов",
          "За пределами прилегающих народно-хозяйственных объектов",
          "За пределами санитарно-защитной зоны населенных пунктов, объединяя данные здания и сооружения со вспомогательными, складскими и санитарно-бытовыми помещениями",
          "На производственных площадках преимущественно с подветренной стороны от других зданий и сооружений с учетом розы ветров преобладающего направления",
          "В пределах санитарной зоны",
        ],
        correctAnswers: [
          "На производственных площадках преимущественно с подветренной стороны от других зданий и сооружений с учетом розы ветров преобладающего направления",
        ],
      },
      {
        code: "20605041",
        text: "Разрешается ли прокладка заглубленных каналов и тоннелей при обустройстве нефтяных, газовых и газоконденсатных месторождений для размещения кабелей в помещениях и на территории наружных установок, имеющих источники возможного выделения в атмосферу вредных веществ плотностью по воздуху более 0,8, а также источники возможных проливов горючих и серосодержащих жидкостей?",
        answers: [
          "Разрешается по согласованию с проектной организацией",
          "Запрещается, за исключением каналов и тоннелей, подлежащих последующей засыпке",
          "Запрещается в любом случае",
          "Разрешается в любом случае",
        ],
        correctAnswers: [
          "Запрещается, за исключением каналов и тоннелей, подлежащих последующей засыпке",
        ],
      },
      {
        code: "20605042",
        text: "Допускается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений пересечение трубопроводов с токсичными жидкостями и газами с железнодорожными подъездными путями?",
        answers: [
          "Не допускается, за исключением продуктопроводов к двусторонним сливоналивным железнодорожным эстакадам",
          "Допускается",
          "Не допускается",
          "Допускается при условии прокладки их по глухой стене не ниже II степени огнестойкости",
        ],
        correctAnswers: [
          "Не допускается, за исключением продуктопроводов к двусторонним сливоналивным железнодорожным эстакадам",
        ],
      },
      {
        code: "20605043",
        text: "Разрешается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений последовательное соединение заземляющим проводником нескольких аппаратов или резервуаров?",
        answers: [
          "Не допускается",
          "Допускается",
          "Не допускается, за исключением аппаратов или резервуаров, установленных в одном обваловании",
          "Допускается при условии, что общее сопротивление заземляющего проводника не превышает 20 Ом",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "20605044",
        text: "Через какое расстояние эстакады для трубопроводов при обустройстве нефтяных, газовых и газоконденсатных месторождений должны быть электрически соединены с проходящими по ним трубопроводами и заземлены?",
        answers: [
          "Через 200 - 300 м, а также в начале и в конце",
          "Через 360 - 420 м, а также в начале и в конце",
          "Через 170 - 230 м, а также в начале и в конце",
          "Через 550 - 600 м, а также в начале и в конце",
        ],
        correctAnswers: ["Через 200 - 300 м, а также в начале и в конце"],
      },
      {
        code: "20605045",
        text: "С какой целью применяются эксплуатационные колонны скважин, бурящихся на подсолевые нефтегазоносные комплексы?",
        answers: [
          "Для перекрытия надсолевого водоносного комплекса",
          "Для перекрытия безводной солесодержащей части разреза и монтажа противовыбросового оборудования",
          "Для предотвращения размыва устья скважины",
          "Для перекрытия неустойчивых четвертичных отложений",
          "Для разобщения продуктивных пластов от вышерасположенных флюидосодержащих пород",
        ],
        correctAnswers: [
          "Для разобщения продуктивных пластов от вышерасположенных флюидосодержащих пород",
        ],
      },
      {
        code: "20605046",
        text: "К какому типу обсадных колонн относится часть конструкции скважин, бурящихся на подсолевые нефтегазоносные комплексы, спускаемая для перекрытия неустойчивых четвертичных отложений и цементируемая до устья?",
        answers: [
          "Второе направление",
          "Кондуктор",
          "Первое направление",
          "Техническая колонна",
          "Коллектор",
        ],
        correctAnswers: ["Второе направление"],
      },
      {
        code: "20605047",
        text: "В течение какого времени передаются организациям по добыче калийных солей в случае бурения скважин на их горных отводах карта-схема инструментальной привязки устьев скважин и каталог в государственной системе координат?",
        answers: [
          "Не позднее 3 месяцев с момента окончания монтажа буровой установки",
          "Не позднее 1 месяца с момента окончания монтажа буровой установки",
          "Не позднее 6 месяцев с момента окончания монтажа буровой установки",
          "Не регламентируется",
        ],
        correctAnswers: [
          "Не позднее 1 месяца с момента окончания монтажа буровой установки",
        ],
      },
      {
        code: "20605048",
        text: "С какой предельной ошибкой в плане производится вынос скважин в натуру?",
        answers: [
          "Вынос скважин в натуру производится с предельной ошибкой в плане  5,0 м, а привязка - 1,0 м",
          "Вынос скважин в натуру производится с предельной ошибкой в плане  10,0 м, а привязка -  2,0 м",
          "Вынос скважин в натуру производится с предельной ошибкой в плане  3,0 м, а привязка - 1,0 м",
          "Вынос скважин в натуру производится с предельной ошибкой в плане  3,0 м, а привязка - 0,5 м",
        ],
        correctAnswers: [
          "Вынос скважин в натуру производится с предельной ошибкой в плане  5,0 м, а привязка - 1,0 м",
        ],
      },
      {
        code: "20605049",
        text: "Какое значение должен иметь зенитный угол ствола скважины от устья скважины до глубины 100 м при освоении месторождений нефти на площадях залегания калийных солей?",
        answers: [
          "Без ограничения, по расчету профиля ствола скважины",
          "Не более 15°",
          "Не более 7°",
          "Не более 3°",
        ],
        correctAnswers: ["Не более 3°"],
      },
      {
        code: "20605050",
        text: "В каком месте зенитный угол ствола скважины должен быть не более 15° при освоении месторождений нефти на площадях залегания калийных солей?",
        answers: [
          "От глубины 100 м до глубины на 120 м ниже кровли подстилающей каменной соли",
          "От глубины на 120 м ниже кровли подстилающей каменной соли до башмака технической колонны",
          "От устья скважины до глубины 100 м",
          "От башмака технической колонный до забоя",
        ],
        correctAnswers: [
          "От глубины на 120 м ниже кровли подстилающей каменной соли до башмака технической колонны",
        ],
      },
      {
        code: "20605051",
        text: "В соответствии с чем производятся приготовление, контроль качества и использование буровых растворов и тампонажных материалов при освоении месторождений нефти на площадях залегания калийных солей?",
        answers: [
          "В соответствии с заключением экспертизы промышленной безопасности",
          "В соответствии с эксплуатирующей документацией",
          "В соответствии с распоряжениями руководителя эксплуатирующей организации",
          "В соответствии с технологическими регламентами, согласованными с Ростехнадзором",
          "В соответствии с проектом",
        ],
        correctAnswers: [
          "В соответствии с технологическими регламентами, согласованными с Ростехнадзором",
        ],
      },
      {
        code: "20605052",
        text: "Допускается ли пересечение технологических трубопроводов с газом, ЛВЖ, ГЖ с трубопроводами негорючих веществ на ОПО МНГК?",
        answers: [
          "Не допускается",
          "Допускается при наличии разрешения Ростехнадзора",
          "Допускается, если трубопроводы негорючих веществ располагаются снизу",
          "Допускается, если трубопроводы негорючих веществ располагаются сверху",
        ],
        correctAnswers: [
          "Допускается, если трубопроводы негорючих веществ располагаются снизу",
        ],
      },
      {
        code: "20605053",
        text: "С какой регулярностью проводятся повторная и последующие проверки подводного трубопровода?",
        answers: [
          "Повторная проверка подводного трубопровода проводится в срок не позднее шести месяцев с начала эксплуатации, последующие проверки проводятся при необходимости",
          "Повторная проверка подводного трубопровода проводится только в случае экстренной необходимости, последующие проверки проводятся каждые пять лет",
          "Повторная проверка подводного трубопровода проводится в срок не позднее одного года с начала эксплуатации, последующие проверки проводятся с определенной при проектировании периодичностью, но не реже чем раз в восемь лет",
        ],
        correctAnswers: [
          "Повторная проверка подводного трубопровода проводится в срок не позднее одного года с начала эксплуатации, последующие проверки проводятся с определенной при проектировании периодичностью, но не реже чем раз в восемь лет",
        ],
      },
      {
        code: "20605054",
        text: "Кем должны быть приняты законченные строительно-монтажные работы на ОПО МНГК или их составляющих?",
        answers: [
          "Комиссией Ростехнадзора",
          "Комиссией Росприроднадзора",
          "Комиссией, назначенной приказом эксплуатирующей организации",
          "Комиссией проектной организации",
        ],
        correctAnswers: [
          "Комиссией, назначенной приказом эксплуатирующей организации",
        ],
      },
      {
        code: "20605055",
        text: "Какая должна быть кратность воздухообмена в помещениях с взрывоопасными зонами?",
        answers: ["Не менее 6 в час", "Не менее 10 в час", "Не менее 8 в час"],
        correctAnswers: ["Не менее 6 в час"],
      },
      {
        code: "20605056",
        text: "Каким образом должно осуществляться управление энергетическими установками на ОПО МНГК?",
        answers: [
          "Управление энергетическими установками должно осуществляться с пультов, расположенных в помещении жилого блока",
          "Управление энергетическими установками должно осуществляться только с пультов, расположенных в помещениях каждой установки",
          "Управление энергетическими установками должно осуществляться только с центрального пульта управления",
          "Управление энергетическими установками должно осуществляться как с центрального пульта управления, так и с пультов, расположенных в помещениях каждой установки",
          "Управление энергетическими установками должно осуществляться с пультов, расположенных в помещении бытового блока",
        ],
        correctAnswers: [
          "Управление энергетическими установками должно осуществляться как с центрального пульта управления, так и с пультов, расположенных в помещениях каждой установки",
        ],
      },
      {
        code: "20605057",
        text: "Каким требованиям должны соответствовать электрические сети на МСП, ПБУ, МЭ и ПТК?",
        answers: [
          "Должны быть изолированными, в том числе сети трехфазного переменного тока напряжением до 1000 В с изолированной нейтралью. Нейтраль электрических сетей напряжением свыше 1000 В заземляется через высокоомный резистор",
          "Должны быть изолированными, в том числе сети трехфазного переменного тока напряжением до 0,4 кВ с неизолированной нейтралью. Нейтраль электрических сетей напряжением свыше 1000 В заземляется через низкоомный резистор",
          "Должны быть изолированными, в том числе сети двухфазного переменного тока напряжением 220 В",
          "Должны быть изолированными, в том числе сети трехфазного переменного тока напряжением до 0,4 кВ с изолированной нейтралью. Нейтраль электрических сетей напряжением свыше 0,4 кВ заземляется через высокоомный резистор",
        ],
        correctAnswers: [
          "Должны быть изолированными, в том числе сети трехфазного переменного тока напряжением до 1000 В с изолированной нейтралью. Нейтраль электрических сетей напряжением свыше 1000 В заземляется через высокоомный резистор",
        ],
      },
      {
        code: "20605058",
        text: "Какие меры безопасности должны быть предусмотрены при проведении ремонтных работ или укладке кабеля после ремонта?",
        answers: [
          "Включение питания проводится ответственным дежурным по энергоснабжению после устного подтверждения руководителя работ и личного контроля по окончании этих работ",
          'Одновременное проведение испытания и ремонтных работ различными бригадами в пределах одного присоединения допускается по одному наряду-заданию с указанием в строке "Отдельные указания" дополнительных мер, обеспечивающих безопасность работников',
          "При осмотре, ремонтных работах или укладке кабеля после ремонта исключается случайная подача напряжения в укладываемый, осматриваемый или ремонтируемый кабель",
          "До начала ремонтных работ после подъема поврежденного кабеля на борт кабелеукладочного судна кабель отключается хотя бы с одной стороны и заземляется",
        ],
        correctAnswers: [
          "При осмотре, ремонтных работах или укладке кабеля после ремонта исключается случайная подача напряжения в укладываемый, осматриваемый или ремонтируемый кабель",
        ],
      },
      {
        code: "20605059",
        text: "Допускается ли не устанавливать молниеотводное устройство на возвышающихся над МСП, ПБУ, МЭ и ПТК конструкциях?",
        answers: [
          "Допускается не устанавливать молниеотводное устройство, если конструктивно предусмотрен надежный электрический контакт вышки, мачты с металлоконструкцией МСП, ПБУ, МЭ и ПТК",
          "Не допускается",
          "Допускается для отдельно стоящего оборудования",
          "Допускается для взрывозащищенного оборудования",
        ],
        correctAnswers: [
          "Допускается не устанавливать молниеотводное устройство, если конструктивно предусмотрен надежный электрический контакт вышки, мачты с металлоконструкцией МСП, ПБУ, МЭ и ПТК",
        ],
      },
      {
        code: "20605060",
        text: "Какие требования предъявляются для защиты от статического электричества одиночно установленных технических устройств (оборудование, емкость, аппарат, агрегат)?",
        answers: [
          "Одиночно установленное техническое устройство заземляется только самостоятельно",
          "Требования не предъявляются",
          "Одиночно установленное техническое устройство заземляется самостоятельно или присоединяется к общей заземляющей магистрали ОПО МНГК, расположенной вблизи оборудования, при помощи отдельного заземляющего провода (шины)",
          "Одиночно установленное техническое устройство не заземляется",
        ],
        correctAnswers: [
          "Одиночно установленное техническое устройство заземляется самостоятельно или присоединяется к общей заземляющей магистрали ОПО МНГК, расположенной вблизи оборудования, при помощи отдельного заземляющего провода (шины)",
        ],
      },
      {
        code: "20605061",
        text: "Как часто проводится определение технического состояния заземляющего устройства?",
        answers: [
          "Один раз в год",
          "Один раз в полгода",
          "Один раз в три месяца",
          "Один раз в месяц",
        ],
        correctAnswers: ["Один раз в год"],
      },
      {
        code: "20605062",
        text: "Какие требования предъявляются к условиям закладки скважин, предназначенных для поисков, разведки, эксплуатации месторождений нефти, газа и газового конденсата?",
        answers: [
          "Скважины должны закладываться в пределах горного отвода и зоны производственной застройки",
          "Скважины должны закладываться за пределами границ зоны санитарной охраны источников водоснабжения и водопроводов питьевого назначения, водоохранных зон, охранных зон линий электропередач, магистральных нефтегазопроводов, водозаборных, других промышленных и гражданских объектов",
          "Скважины должны закладываться в пределах земельного отвода и зоны магистральных нефтегазопроводов",
        ],
        correctAnswers: [
          "Скважины должны закладываться за пределами границ зоны санитарной охраны источников водоснабжения и водопроводов питьевого назначения, водоохранных зон, охранных зон линий электропередач, магистральных нефтегазопроводов, водозаборных, других промышленных и гражданских объектов",
        ],
      },
      {
        code: "20605063",
        text: "Кем осуществляется надзор за ходом производства буровых работ, качеством выполнения этих работ, уровнем технологических процессов и операций, качеством используемых материалов и технических средств, соблюдением безопасных условий труда?",
        answers: [
          "Территориальным органом Ростехнадзора",
          "Проектной организацией",
          "Региональным центром Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "Пользователем недр (заказчиком), организацией, осуществляющей производство буровых работ, и другими субъектами хозяйственной деятельности, уполномоченными пользователем недр",
        ],
        correctAnswers: [
          "Пользователем недр (заказчиком), организацией, осуществляющей производство буровых работ, и другими субъектами хозяйственной деятельности, уполномоченными пользователем недр",
        ],
      },
      {
        code: "20605064",
        text: "В каком случае буровые работы можно производить без применения дополнительных мер безопасности?",
        answers: [
          "В случае производства буровых работ в многолетнемерзлых породах",
          "Во всех перечисленных случаях производство буровых работ необходимо производить с применением дополнительных мер безопасности",
          "В случае производства буровых работ на месторождениях с содержанием в нефти (газе) 3 % об. сероводорода",
          "В случае производства буровых работ с кустовых площадок",
        ],
        correctAnswers: [
          "В случае производства буровых работ на месторождениях с содержанием в нефти (газе) 3 % об. сероводорода",
        ],
      },
      {
        code: "20605065",
        text: "На кого возлагается общее руководство буровыми работами на ПБУ?",
        answers: [
          "На главного инженера ПБУ",
          "На капитана ПБУ",
          "На начальника ОПО МНГК",
          "На заместителя начальника по технологии бурения",
        ],
        correctAnswers: ["На начальника ОПО МНГК"],
      },
      {
        code: "20605066",
        text: "Какие данные не регистрируются и не контролируются на ПБУ с динамической системой позиционирования и (или) с якорной системой удержания?",
        answers: [
          "Осадка судна",
          "Высота волны",
          "Все ответы неверны",
          "Угол наклона бурового райзера",
          "Скорость течения",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "20605067",
        text: "Кем выдаются разрешения на выполнение отдельных технологических операций и применение ограничений эксплуатации бурового оборудования, а также указания о прекращении бурения и отсоединении бурового райзера по гидрометеорологическим условиям?",
        answers: [
          "Начальником ПБУ",
          "Вахтенным помощником капитана судна",
          "Заместителем начальника по технологии бурения",
          "Капитаном судна",
        ],
        correctAnswers: ["Начальником ПБУ"],
      },
      {
        code: "20605068",
        text: "При применении каких материалов устанавливается герметичное оборудование с устройством для пневмотранспорта при проведении буровых работ?",
        answers: [
          "При применении глинопорошка",
          "При применении цемента",
          "При применении всех перечисленных материалов",
          "При применении химреагентов",
        ],
        correctAnswers: ["При применении всех перечисленных материалов"],
      },
      {
        code: "20605069",
        text: "С каким уклоном допускается устанавливать опорную плиту (при ее наличии) на ровную поверхность дна моря при формировании подводного устья скважины?",
        answers: [
          "Не более 1°",
          "Не более 5°",
          "Не более 3°",
          "Уклон не допускается",
        ],
        correctAnswers: ["Не более 1°"],
      },
      {
        code: "20605070",
        text: "Каким требованиям должны соответствовать трубопроводы, проложенные от устья скважин до технологических установок?",
        answers: [
          "Должны соответствовать всем перечисленным требованиям",
          "Должны быть рассчитаны на полуторакратное рабочее давление",
          "В начале и конце трубопровода краской должны быть нанесены номер скважины и направление потока",
          "Должны быть проложены в один ярус",
        ],
        correctAnswers: [
          "Должны соответствовать всем перечисленным требованиям",
        ],
      },
      {
        code: "20605071",
        text: "При каких условиях допускается повторное использование рабочего проекта при бурении группы скважин на идентичных по геолого-техническим условиям площадях?",
        answers: [
          "При выполнении всех перечисленных условий",
          "При одинаковых проектных глубинах по стволу скважин",
          "При отличии плотности бурового раствора от проектной в пределах ± 0,3 г/см³",
          "При одинаковом назначении и конструкции скважин",
          "При идентичности горно-геологических условий проводки и условий природопользования",
        ],
        correctAnswers: ["При выполнении всех перечисленных условий"],
      },
      {
        code: "20605072",
        text: "Что из нижеперечисленного не содержится в рабочем проекте на производство буровых работ?",
        answers: [
          "Обоснование плотности бурового раствора и диапазон колебаний других параметров промывочной жидкости",
          "Объем исследования стратиграфического разреза в процессе бурения для уточнения пластовых давлений и состава флюида",
          "Географическая и климатическая характеристики района работ",
          "Ф. И. О. ответственного лица за производство буровых работ",
        ],
        correctAnswers: [
          "Ф. И. О. ответственного лица за производство буровых работ",
        ],
      },
      {
        code: "20605073",
        text: "Что должны обеспечивать конструкция и схема колонной устьевой обвязки, фонтанной арматуры?",
        answers: [
          "Возможность обеспечения правильной центровки обсадных колонн в скважине",
          "Оптимальные режимы при эксплуатации и подземном ремонте скважины, а также возможность безопасного проведения технологических операций на скважине и глубинных исследований",
          "Оптимальные режимы работы скважины, возможность герметизации трубного, затрубного и межтрубных пространств, возможность выполнения технологических операций в скважине, глубинные исследования, отбор проб и контроль устьевого давления и температуры",
          "Безопасный отбор проб и контроль устьевого давления и температуры, проведение работ при глушении скважины",
        ],
        correctAnswers: [
          "Оптимальные режимы работы скважины, возможность герметизации трубного, затрубного и межтрубных пространств, возможность выполнения технологических операций в скважине, глубинные исследования, отбор проб и контроль устьевого давления и температуры",
        ],
      },
      {
        code: "20605074",
        text: "Где следует устанавливать башмак обсадной колонны, перекрывающий породы, склонные к текучести?",
        answers: [
          "Только ниже их подошвы",
          "Только в плотных пропластках",
          "Ниже их подошвы или в плотных пропластках",
        ],
        correctAnswers: ["Ниже их подошвы или в плотных пропластках"],
      },
      {
        code: "20605075",
        text: "Какие требования предъявляются к условиям установки подвесного и герметизирующего устройства потайной колонны?",
        answers: [
          "Требования к условиям установки подвесного и герметизирующего устройства потайной колонны (хвостовика) определяется расчетным путем при проектировании конструкции скважины",
          "Требования не нормируются",
          "Подвесное и герметизирующее устройство потайной колонны должно устанавливаться выше предыдущей обсадной колонны не менее чем на 75 м для нефтяных скважин и 250 м для газовых скважин",
        ],
        correctAnswers: [
          "Подвесное и герметизирующее устройство потайной колонны должно устанавливаться выше предыдущей обсадной колонны не менее чем на 75 м для нефтяных скважин и 250 м для газовых скважин",
        ],
      },
      {
        code: "20605076",
        text: "С учетом каких параметров производятся выбор обсадных труб и расчет обсадных колонн на стадиях строительства и эксплуатации скважин?",
        answers: [
          "С учетом минимальных ожидаемых наружных и внутренних давлений при полном замещении раствора пластовым флюидом, снижении уровня осевых нагрузок на трубы",
          "С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при частичном замещении бурового раствора пластовым флюидом и агрессивности флюида",
          "С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при частичном замещении раствора газожидкостной смесью, снижении уровня, а также осевых нагрузок на трубы",
          "С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при полном замещении раствора пластовым флюидом или газожидкостной смесью, снижении уровня, а также осевых нагрузок на трубы и агрессивности флюида",
        ],
        correctAnswers: [
          "С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при полном замещении раствора пластовым флюидом или газожидкостной смесью, снижении уровня, а также осевых нагрузок на трубы и агрессивности флюида",
        ],
      },
      {
        code: "20605077",
        text: "Каким документом устанавливаются периодичность и способы проверки состояния обсадных колонн по мере их износа и необходимые мероприятия по обеспечению безопасной проводки и эксплуатации скважин?",
        answers: [
          "Рабочим проектом или иной документацией, содержащей аналогичные требования",
          "Регламентом, разработанным в соответствии с проектом",
          "Методическими указаниями проектной организации",
          "Правилами безопасности в нефтяной и газовой промышленности",
        ],
        correctAnswers: [
          "Рабочим проектом или иной документацией, содержащей аналогичные требования",
        ],
      },
      {
        code: "20605078",
        text: "Какую площадь должен иметь размер земельного участка для производства буровых работ?",
        answers: [
          "Не более 100 м²",
          "Не более 150 м²",
          "Не более 200 м²",
          "Обеспечивающую соблюдение требований промышленной безопасности",
        ],
        correctAnswers: [
          "Обеспечивающую соблюдение требований промышленной безопасности",
        ],
      },
      {
        code: "20605079",
        text: "Что из перечисленного не учитывается при планировании площадки для монтажа буровой установки?",
        answers: [
          "Естественный уклон местности",
          "Обеспечение движения сточных вод в систему их сбора и очистки",
          "Роза ветров",
        ],
        correctAnswers: ["Роза ветров"],
      },
      {
        code: "20605080",
        text: "Откуда должен осуществляться пуск буровых насосов в работу?",
        answers: [
          "С местного поста управления",
          "С пульта оператора, осуществляющего контроль технологического процесса",
          "С пульта бурильщика",
          "Одновременно с пульта бурильщика и местного поста управления",
        ],
        correctAnswers: ["С местного поста управления"],
      },
      {
        code: "20605081",
        text: "В каком случае должно обеспечиваться полное отключение оборудования и механизмов в закрытых помещениях буровой установки, где возможно возникновение или проникновение воспламеняющихся смесей?",
        answers: [
          "При достижении 20 % от нижнего предела воспламенения смеси воздуха с углеводородами",
          "При достижении 40 % от нижнего предела воспламенения смеси воздуха с углеводородами",
          "При достижении 50 % от нижнего предела воспламенения смеси воздуха с углеводородами",
          "При достижении 30 % от нижнего предела воспламенения смеси воздуха с углеводородами",
        ],
        correctAnswers: [
          "При достижении 50 % от нижнего предела воспламенения смеси воздуха с углеводородами",
        ],
      },
      {
        code: "20605082",
        text: "Должны ли буровые насосы оборудоваться компенсаторами давления? Если да, то какие требования при этом должны соблюдаться?",
        answers: [
          "На всех буровых насосах должны быть установлены гидрокомпенсаторы давления, заполняемые водой, с приспособлениями для контроля давления в компенсаторах",
          "Установка на буровых насосах компенсаторов давления необязательна в том случае, если проводятся мероприятия по обеспечению равномерности подачи промывочной жидкости",
          "Компенсаторы давления должны устанавливаться только на трех поршневых буровых насосах, при этом компенсаторы должны быть заполнены воздухом или инертным газом и иметь приспособления для контроля давления в компенсаторах",
          "На всех буровых насосах должны быть установлены компенсаторы давления, заполняемые воздухом или инертным газом, с конструкцией, предусматривающей установку манометра для измерения давления в газовой полости и обеспечивающей возможность сбрасывания давления до нуля",
        ],
        correctAnswers: [
          "На всех буровых насосах должны быть установлены компенсаторы давления, заполняемые воздухом или инертным газом, с конструкцией, предусматривающей установку манометра для измерения давления в газовой полости и обеспечивающей возможность сбрасывания давления до нуля",
        ],
      },
      {
        code: "20605083",
        text: "Что должно быть указано на корпусах оборудования, входящего в состав талевой системы (кронблок, талевый блок, крюк)?",
        answers: [
          "Дата изготовления",
          "Сроки следующего испытания",
          "Материал изготовления",
          "Допускаемая грузоподъемность",
        ],
        correctAnswers: ["Допускаемая грузоподъемность"],
      },
      {
        code: "20605084",
        text: "Какие показатели должны постоянно контролироваться в процессе проходки ствола скважины?",
        answers: [
          "Взаимное расположение стволов бурящейся и ранее пробуренных соседних скважин",
          "Азимут и зенитный угол ствола скважины",
          "Расход бурового раствора на входе и выходе из скважины и давление в манифольде буровых насосов",
          "Пространственное расположение ствола скважины и дифференциальное давление в системе скважина пласт",
        ],
        correctAnswers: [
          "Расход бурового раствора на входе и выходе из скважины и давление в манифольде буровых насосов",
        ],
      },
      {
        code: "20605085",
        text: "Какие показатели должны контролироваться при бурении наклонно-направленных и горизонтальных скважин?",
        answers: [
          "Азимут, зенитный угол ствола скважины, пространственное расположение ствола скважины, взаимное расположение стволов бурящейся и ранее пробуренных соседних скважин",
          "Плотность, структурно-механические и реологические свойства бурового раствора и пространственное расположение ствола скважины",
          "Крутящий момент на роторе при роторном способе бурения, давление в манифольде буровых насосов и азимут ствола скважины",
          "Расход бурового раствора на входе и выходе из скважины, давление в манифольде буровых насосов и зенитный угол ствола скважины",
        ],
        correctAnswers: [
          "Азимут, зенитный угол ствола скважины, пространственное расположение ствола скважины, взаимное расположение стволов бурящейся и ранее пробуренных соседних скважин",
        ],
      },
      {
        code: "20605086",
        text: "Какие ограничения предусмотрены для перфорации обсадных колонн при проведении ремонтно-изоляционных работ в процессе проводки ствола скважины?",
        answers: [
          "Ограничения не предусмотрены",
          "Запрещается перфорация обсадных колонн при проведении ремонтно-изоляционных работ в интервале проницаемых горизонтов",
          "Запрещается перфорация обсадных колонн при проведении ремонтно-изоляционных работ в интервале возможного разрыва пласта давлением газа, нефти (после вызова их притока) или столба бурового раствора",
        ],
        correctAnswers: [
          "Запрещается перфорация обсадных колонн при проведении ремонтно-изоляционных работ в интервале возможного разрыва пласта давлением газа, нефти (после вызова их притока) или столба бурового раствора",
        ],
      },
      {
        code: "20605087",
        text: "Какой должна быть расчетная продолжительность процесса цементирования обсадной колонны?",
        answers: [
          "Не должна превышать 75 % времени начала загустевания тампонажного раствора",
          "Не должна превышать 95 % времени начала загустевания тампонажного раствора",
          "Не должна превышать 70 % времени начала загустевания тампонажного раствора",
          "Не должна превышать 80 % времени начала загустевания тампонажного раствора",
        ],
        correctAnswers: [
          "Не должна превышать 75 % времени начала загустевания тампонажного раствора",
        ],
      },
      {
        code: "20605088",
        text: "В каком случае запрещается производить спуск технических и эксплуатационных колонн в скважину?",
        answers: [
          "Если скважина осложнена осыпями и обвалами",
          "Если скважина осложнена затяжками и посадками бурильной колонны",
          "Спуск технических и эксплуатационных колонн во всех перечисленных случаях запрещен до ликвидации осложнений",
          "Если скважина осложнена поглощениями бурового раствора с одновременным флюидопроявлением",
        ],
        correctAnswers: [
          "Спуск технических и эксплуатационных колонн во всех перечисленных случаях запрещен до ликвидации осложнений",
        ],
      },
      {
        code: "20605089",
        text: "Какое расстояние должно соблюдаться при креплении скважин между цементировочными агрегатами и цементосмесительными машинами?",
        answers: [
          "Не менее 0,5 м",
          "Не менее 1 м",
          "Не менее 1,5 м",
          "Не менее 0,8 м",
        ],
        correctAnswers: ["Не менее 1,5 м"],
      },
      {
        code: "20605090",
        text: "Какой должна быть длина линий сбросов на факелы от блоков глушения и дросселирования для нефтяных скважин с газовым фактором менее 200 м³/т?",
        answers: [
          "Не менее 10 м",
          "Не менее 25 м",
          "Не менее 20 м",
          "Не менее 30 м",
        ],
        correctAnswers: ["Не менее 30 м"],
      },
      {
        code: "20605091",
        text: "Какое оборудование должно быть установлено на скважинах, где ожидаемое давление на устье превышает 700 кгс/см² (70 МПа)?",
        answers: [
          "Заводской блок с двумя регулируемыми дросселями - один с дистанционным и один с ручным управлением",
          "Заводской блок с тремя регулируемыми дросселями - один с дистанционным и два с ручным управлением",
          "Заводской блок с пятью регулируемыми дросселями - два с дистанционным и три с ручным управлением",
          "Заводской блок с тремя регулируемыми дросселями - два с дистанционным и один с ручным управлением",
        ],
        correctAnswers: [
          "Заводской блок с тремя регулируемыми дросселями - два с дистанционным и один с ручным управлением",
        ],
      },
      {
        code: "20605092",
        text: "Какой верхний предел диапазона измерений должны иметь манометры, устанавливаемые на блоках дросселирования и глушения?",
        answers: [
          "На 10 % превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования",
          "На 30 % превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования",
          "На 80 % превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования",
          "На 50 % превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования",
        ],
        correctAnswers: [
          "На 30 % превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования",
        ],
      },
      {
        code: "20605093",
        text: "На каком расстоянии устанавливаются основной и вспомогательный пульты управления превенторами и гидравлическими задвижками?",
        answers: [
          "Основной пульт управления - на расстоянии не менее 10 м от устья скважины в удобном и безопасном месте, вспомогательный - непосредственно возле пульта бурильщика",
          "Основной пульт управления - на расстоянии не менее 5 м от устья скважины в удобном и безопасном месте, вспомогательный - не менее 2 м от пульта бурильщика",
          "Основной пульт управления - на расстоянии не менее 3 м от устья скважины в удобном и безопасном месте, вспомогательный - не менее 5 м от пульта бурильщика",
          "Основной пульт управления - на расстоянии не менее 8 м от устья скважины в удобном и безопасном месте, вспомогательный - не менее 3 м от пульта бурильщика",
        ],
        correctAnswers: [
          "Основной пульт управления - на расстоянии не менее 10 м от устья скважины в удобном и безопасном месте, вспомогательный - непосредственно возле пульта бурильщика",
        ],
      },
      {
        code: "20605094",
        text: "Какое требование, предъявляемое к применению противовыбросового оборудования, указано неверно?",
        answers: [
          "Противовыбросовый манифольд вместе с линией от сепаратора бурового раствора на желоб оборудуется устройством для продувки",
          "Универсальным превентором обеспечивается срезание наиболее прочной трубы, предполагаемой к спуску в скважину",
          "На ПБУ с подводным расположением устья проводится опрессовка каждого превентора в сборке подводного противовыбросового оборудования (ППВО) на стенде на рабочее давление",
          "При ГНВП разгазированная жидкость через штуцерную линию поступает в систему сепарации и дегазации",
        ],
        correctAnswers: [
          "Универсальным превентором обеспечивается срезание наиболее прочной трубы, предполагаемой к спуску в скважину",
        ],
      },
      {
        code: "20605095",
        text: "Что необходимо предпринять в процессе подъема колонны бурильных труб для предупреждения газонефтеводопроявлений?",
        answers: [
          "Следует ввести промывочные жидкости, инертные по отношению к горным породам",
          "Следует обеспечить большую скорость восходящего потока жидкости в кольцевом пространстве",
          "Следует ввести в промывочную жидкость смазывающие добавки",
          "Следует производить долив бурового раствора в скважину",
        ],
        correctAnswers: [
          "Следует производить долив бурового раствора в скважину",
        ],
      },
      {
        code: "20605096",
        text: "Какие меры из перечисленных входят в комплекс работ по освоению скважин?",
        answers: [
          'Предупреждение прорыва пластовой воды и газа из газовой "шапки" и термогидрогазодинамические исследования по определению количественной и качественной характеристик пласта и его геолого-физических параметров',
          "Исключение закупорки пласта при вторичном вскрытии и сохранение скелета пласта в призабойной зоне",
          "Сохранение, восстановление или повышение проницаемости призабойной зоны и предотвращение неконтролируемых газонефтеводопроявлений и открытых фонтанов",
          "В комплекс работ по освоению скважин входят все перечисленные меры",
        ],
        correctAnswers: [
          "В комплекс работ по освоению скважин входят все перечисленные меры",
        ],
      },
      {
        code: "20605097",
        text: "Каковы требования к производству работ по глубинным измерениям в скважинах с избыточным давлением на устье?",
        answers: [
          "Производятся в соответствии с требованиями заказчика",
          "Производятся с применением лубрикатора, опрессованного на рабочее давление, установленное изготовителем, а после установки - на давление опрессовки колонны",
          "Производятся с применением утяжеленного бурового раствора",
        ],
        correctAnswers: [
          "Производятся с применением лубрикатора, опрессованного на рабочее давление, установленное изготовителем, а после установки - на давление опрессовки колонны",
        ],
      },
      {
        code: "20605098",
        text: "Чем должна определяться технология производства буровых работ в зонах распространения многолетнемерзлых пород?",
        answers: [
          "Мерзлотными и климатическими условиями данного региона",
          "Продолжительностью холодного периода года",
          "Транспортной доступностью места проведения работ",
          "Наличием квалифицированного рабочего персонала в данном регионе",
        ],
        correctAnswers: [
          "Мерзлотными и климатическими условиями данного региона",
        ],
      },
      {
        code: "20605099",
        text: "Каким должно быть расстояние между устьями скважин при размещении кустовых площадок на вечномерзлых грунтах?",
        answers: [
          "Меньше 2 радиусов растепления пород вокруг устья скважин",
          "Не менее 1,2 диаметра растепления пород вокруг устья скважин",
          "Не менее 1 радиуса растепления пород вокруг устья скважин",
        ],
        correctAnswers: [
          "Не менее 1,2 диаметра растепления пород вокруг устья скважин",
        ],
      },
      {
        code: "20605100",
        text: "Какое должно быть расстояние между устьями скважин при расположении систем управления оборудования ПВО при бурении скважин и задвижками фонтанной арматуры эксплуатируемых скважин на одном ярусе при отсутствии обоснованных проектных решений о конкретных расстояниях?",
        answers: [
          "Не менее 2,4 м (для нефтяных)",
          "Не менее 3 м (для газовых и газоконденсатных)",
          "Не менее 5 м",
        ],
        correctAnswers: ["Не менее 5 м"],
      },
      {
        code: "20605101",
        text: "В каких случаях не устанавливается превенторная сборка при бурении скважин для разведки и добычи метана угольных месторождений (площадей, участков)?",
        answers: [
          "В случаях, предусмотренных в рабочем проекте",
          "В случае вскрытия изученного разреза с аномально низким пластовым давлением (АНПД), представленного обводненными угольными пластами",
          "В случае принятия решения об этом буровой организацией",
          "В случае наличия заключения представителя Ростехнадзора",
        ],
        correctAnswers: [
          "В случае вскрытия изученного разреза с аномально низким пластовым давлением (АНПД), представленного обводненными угольными пластами",
        ],
      },
      {
        code: "20605102",
        text: "Какие фонтанные скважины должны оснащаться внутрискважинным оборудованием (пакер и клапан-отсекатель, циркуляционный клапан, станция управления и др.)?",
        answers: [
          "Фонтанные скважины с дебитом 400 т/сут нефти или 500 тыс. м³/сут газа и более, расположенные на расстоянии менее 500 м от населенного пункта",
          "Фонтанные скважины с дебитом 350 т/сут нефти или 400 тыс. м³/сут газа и более",
          "Фонтанные скважины с дебитом 300 т/сут нефти или 400 тыс. м³/сут газа и более, расположенные на расстоянии менее 1 км от населенного пункта",
          "Фонтанные скважины, расположенные на расстоянии менее 2,5 км от населенного пункта",
        ],
        correctAnswers: [
          "Фонтанные скважины с дебитом 400 т/сут нефти или 500 тыс. м³/сут газа и более, расположенные на расстоянии менее 500 м от населенного пункта",
        ],
      },
      {
        code: "20605103",
        text: "Кем утверждается проект и план перевода скважины на газлифтную эксплуатацию?",
        answers: [
          "Техническим руководителем организации",
          "Мастером добычи участка",
          "Представителем Ростехнадзора",
          "Руководителем проектной организации",
        ],
        correctAnswers: ["Техническим руководителем организации"],
      },
      {
        code: "20605104",
        text: "Какие способы соединений труб используются для обвязки скважины и аппаратуры, а также для газопроводов при фонтанной и газлифтной эксплуатации скважин?",
        answers: [
          "Резьбовые соединения типа Батресс",
          "Резьбовые соединения с последующей изоляцией",
          "Соединения на хомутах и сварные соединения",
          "Сварные соединения, а также фланцевые - только в местах установки задвижек и другой арматуры",
        ],
        correctAnswers: [
          "Сварные соединения, а также фланцевые - только в местах установки задвижек и другой арматуры",
        ],
      },
      {
        code: "20605105",
        text: "Что необходимо сделать с газораспределительными трубопроводами после их монтажа?",
        answers: [
          "Продуть кислородом и опрессовать жидкостью на давление, превышающее на 50 % максимальное рабочее",
          "Продуть инертным газом и провести пневматическое испытание на давление, превышающее на 35 % расчетное",
          "Продуть азотом и опрессовать жидкостью на давление, превышающее на 15 % максимальное расчетное",
          "Продуть сжатым воздухом и опрессовать жидкостью на давление, превышающее на 25 % максимальное рабочее",
        ],
        correctAnswers: [
          "Продуть сжатым воздухом и опрессовать жидкостью на давление, превышающее на 25 % максимальное рабочее",
        ],
      },
      {
        code: "20605106",
        text: "Чем должно быть оборудовано устье скважины при эксплуатации ее штанговыми насосами?",
        answers: [
          "Запорной арматурой и обратным клапаном",
          "Устройством для сигнализации об утечках продукта",
          "Перфорационной задвижкой",
          "Шаровым клапаном и двойным торцевым уплотнением для герметизации штока",
          "Запорной арматурой и сальниковым устройством для герметизации штока",
        ],
        correctAnswers: [
          "Запорной арматурой и сальниковым устройством для герметизации штока",
        ],
      },
      {
        code: "20605107",
        text: "Куда должны иметь выход системы замера дебита, контроля пуска, остановки скважин?",
        answers: [
          "На диспетчерский пункт",
          "На пульт насосной станции",
          "На центральный пульт нефтегазодобывающего управления",
          "На пульт групповой замерной установки",
        ],
        correctAnswers: ["На диспетчерский пункт"],
      },
      {
        code: "20605108",
        text: "Какие требования предъявляются к заземлению кондуктора (технической колонны) и рамы станка-качалки?",
        answers: [
          "В качестве заземляющих проводников может применяться сталь любых профилей. Сечение прямоугольного проводника должно быть не менее 48 мм², толщина стенок угловой стали - не менее 4 мм, диаметр круглых заземлителей - не менее 10 мм",
          "Заземляющие проводники, соединяющие раму станка-качалки с кондуктором, должны заглубляться в землю не менее чем на 0,5 м",
          "Соединения заземляющих проводников должны быть доступны для осмотра",
          "Кондуктор и рама станка-качалки должны быть связаны не менее чем двумя заземляющими стальными проводниками, приваренными в разных местах к кондуктору и раме",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "20605109",
        text: "С какой периодичностью и в каком объеме проводятся исследования эксплуатационных скважин на нефтегазодобывающих объектах?",
        answers: [
          "В соответствии с инструкцией по исследованию скважин, утвержденной Минприроды России",
          "Каждые 6 месяцев в полном объеме и ежеквартально в объеме, необходимом геологической службе организации",
          "В соответствии с утвержденным планом работ, разработанным в соответствии с проектной документацией разработки данного месторождения",
          "Не регламентируется",
        ],
        correctAnswers: [
          "В соответствии с утвержденным планом работ, разработанным в соответствии с проектной документацией разработки данного месторождения",
        ],
      },
      {
        code: "20605110",
        text: "Разрешается ли исследование разведочных и эксплуатационных скважин в случае отсутствия утилизации жидкого продукта?",
        answers: [
          "Разрешается по решению территориального органа Ростехнадзора",
          "Запрещается во всех случаях",
          "Разрешается без ограничений",
          "Разрешается по согласованию с противофонтанной службой",
        ],
        correctAnswers: ["Запрещается во всех случаях"],
      },
      {
        code: "20605111",
        text: "Что из нижеперечисленного должно быть указано в плане производства работ по нагнетанию в скважину газа, пара, химических и других агентов?",
        answers: [
          "Ответственный руководитель работ",
          "Порядок подготовительных работ и схема размещения оборудования",
          "Меры безопасности",
          "Технология проведения процесса",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20605112",
        text: "Какие меры должны приниматься в случае производства на скважине работ, требующих давлений, превышающих давления опрессовки обсадной колонны?",
        answers: [
          "На устье устанавливается предохранитель, эксплуатационная колонна защищается специальным наголовником",
          "Проведение таких работ категорически запрещено",
          "На устье устанавливается обратный клапан, эксплуатационная колонна защищается клапаном-отсекателем",
          "На устье устанавливается специальная арматура, а эксплуатационная колонна защищается установкой пакера",
        ],
        correctAnswers: [
          "На устье устанавливается специальная арматура, а эксплуатационная колонна защищается установкой пакера",
        ],
      },
      {
        code: "20605113",
        text: "Какой радиус опасной зоны должен быть установлен вокруг скважины и применяемого оборудования на период тепловой и комплексной обработки?",
        answers: [
          "Не менее 35 м",
          "Не менее 10 м",
          "Не менее 50 м",
          "Не менее 20 м",
        ],
        correctAnswers: ["Не менее 50 м"],
      },
      {
        code: "20605114",
        text: "На каком расстоянии от устья скважины разрешается устанавливать компрессоры и парогенераторные установки?",
        answers: [
          "Не менее 8 м от устья скважины",
          "Не менее 25 м от устья скважины",
          "Не менее 12 м от устья скважины",
          "Не менее 15 м от устья скважины",
        ],
        correctAnswers: ["Не менее 25 м от устья скважины"],
      },
      {
        code: "20605115",
        text: "На каком расстоянии от устья скважины располагаются передвижные насосные установки и каким должно быть расстояние между ними?",
        answers: [
          "Не менее 10 м от устья скважины, расстояние между ними должно быть не менее 1 м",
          "Не менее 25 м от устья скважины, расстояние между ними должно быть не менее 1,5 м",
          "Не менее 35 м от устья скважины, расстояние между ними должно быть не менее 2,5 м",
          "Не менее 15 м от устья скважины, расстояние между ними должно быть не менее 3 м",
        ],
        correctAnswers: [
          "Не менее 10 м от устья скважины, расстояние между ними должно быть не менее 1 м",
        ],
      },
      {
        code: "20605116",
        text: "Допускаются ли вибрация и гидравлические удары в нагнетательных коммуникациях?",
        answers: [
          "Допускаются при наличии в системе компенсаторов",
          "Допускаются при нахождении персонала в безопасной зоне",
          "Не допускаются",
          "Допускаются в пределах, установленных в планах работ",
        ],
        correctAnswers: [
          "Допускаются в пределах, установленных в планах работ",
        ],
      },
      {
        code: "20605117",
        text: "При какой предельно допустимой концентрации содержания диоксида углерода в воздухе закрытого помещения работы в нем должны быть прекращены?",
        answers: [
          "0,5 (объемных) %",
          "0,2 (объемных) %",
          "0,4 (объемных) %",
          "0,1 (объемных) %",
          "0,3 (объемных) %",
        ],
        correctAnswers: ["0,5 (объемных) %"],
      },
      {
        code: "20605118",
        text: "Каким устанавливается радиус опасной зоны вокруг нагнетательной скважины на период инициирования внутрипластового горения?",
        answers: [
          "Не менее 20 м",
          "Не менее 25 м",
          "Не менее 15 м",
          "Не менее 12 м",
        ],
        correctAnswers: ["Не менее 25 м"],
      },
      {
        code: "20605119",
        text: "Какое минимальное расстояние должно быть между парораспределительным пунктом и устьем нагнетательной скважины?",
        answers: ["25 м", "10 м", "20 м", "15 м"],
        correctAnswers: ["25 м"],
      },
      {
        code: "20605120",
        text: "Каким образом должно осуществляться управление запорной арматурой скважины, оборудованной под нагнетание пара или горячей воды?",
        answers: [
          "Дистанционно, но с возможностью ручного управления с применением средств защиты",
          "Вручную с использованием средств защиты",
          "Только дистанционно",
          "Не регламентируется",
        ],
        correctAnswers: ["Только дистанционно"],
      },
      {
        code: "20605121",
        text: "В каком положении должна находиться задвижка на отводе от затрубного пространства при закачке теплоносителя (с установкой пакера)?",
        answers: [
          "Не имеет значения",
          "В открытом",
          "В закрытом",
          "В полуоткрытом",
          "В закрытом и опломбированном",
        ],
        correctAnswers: ["В открытом"],
      },
      {
        code: "20605122",
        text: "Какие требования предъявляются к спуску забойного электронагревателя в скважину?",
        answers: [
          "Операции должны выполняться дистанционно, с использованием специального герметизатора при герметизированном устье",
          "Операции должны выполняться дистанционно, с использованием переносной превенторной установки, с записью результатов",
          "Операции должны быть автоматизированы, с использованием специального обратного клапана, при постоянном наблюдении за устьем",
          "Операции должны быть механизированы, с использованием специального лубрикатора, при герметизированном устье",
        ],
        correctAnswers: [
          "Операции должны быть механизированы, с использованием специального лубрикатора, при герметизированном устье",
        ],
      },
      {
        code: "20605123",
        text: "При каких условиях допускается подключать сетевой кабель к пусковому оборудованию электронагревателя?",
        answers: [
          "После проведения всех подготовительных работ в скважине и на устье",
          "После удаления людей",
          "После выполнения всех условий",
          "После подключения кабель-троса к трансформатору",
          "После заземления электрооборудования",
        ],
        correctAnswers: ["После выполнения всех условий"],
      },
      {
        code: "20605124",
        text: "Какие требования предъявляются правилами к системе контроля состояния воздушной среды для закрытых помещений объектов сбора, подготовки и транспортировки нефти, газа и конденсата?",
        answers: [
          "В системе контроля состояния воздушной среды должно быть предусмотрено дублирование датчиков аварийного включения оборудования и системы приточно-вытяжной вентиляции",
          "Система должна иметь блокировку от включения оборудования, все помещения должны иметь постоянно действующую противопожарную систему",
          "Система должна быть сблокирована с системой выключения оборудования, включая перекрытие клапанов, все помещения должны иметь постоянно действующую систему оповещения и сигнализации",
          "Система должна быть сблокирована с системой звуковой и световой аварийной сигнализации, все помещения должны иметь постоянно действующую систему приточно-вытяжной вентиляции",
          "Система должна иметь блокировку от выключения оборудования, включая перекрытие запорной арматуры, все помещения должны иметь постоянно действующую систему вытяжной вентиляции",
        ],
        correctAnswers: [
          "Система должна быть сблокирована с системой звуковой и световой аварийной сигнализации, все помещения должны иметь постоянно действующую систему приточно-вытяжной вентиляции",
        ],
      },
      {
        code: "20605125",
        text: "Какая документация должна быть размещена на объектах сбора и подготовки нефти и газа (ЦПС, УПН, УКПГ, ГП), насосных и компрессорных станциях (ДНС, КС)?",
        answers: [
          "Технические паспорта и документация на оборудование насосных и компрессорных станций",
          "Технологическая схема, утвержденная техническим руководителем организации, с указанием номеров задвижек, аппаратов, направлений потоков, полностью соответствующих их нумерации в проектной документации",
          "Приказ о назначении лица, ответственного за проведение работ, и технические паспорта на оборудование",
          "План аварийно-спасательных мероприятий, утвержденный руководством организации",
          "Вся перечисленная документация",
        ],
        correctAnswers: [
          "Технологическая схема, утвержденная техническим руководителем организации, с указанием номеров задвижек, аппаратов, направлений потоков, полностью соответствующих их нумерации в проектной документации",
        ],
      },
      {
        code: "20605126",
        text: "В соответствии с какими документами должна устанавливаться скорость изменения технологических параметров?",
        answers: [
          "В соответствии с технологическим регламентом и заводскими инструкциями по эксплуатации оборудования",
          "В соответствии с инструкциями завода-изготовителя по пуску, эксплуатации и остановке установок",
          "В соответствии с инструкциями по пуску, эксплуатации и остановке установок, согласованными с Ростехнадзором",
          "В соответствии с правилами безопасности в нефтяной и газовой промышленности",
          "В соответствии с планами проведения работ, утвержденными техническим руководителем организации и согласованными с Ростехнадзором",
          "В соответствии с проектами",
        ],
        correctAnswers: [
          "В соответствии с технологическим регламентом и заводскими инструкциями по эксплуатации оборудования",
        ],
      },
      {
        code: "20605127",
        text: "В каком случае установка автоматического клапана-отсекателя на выкидной линии газовых и газоконденсатных скважин не требуется?",
        answers: [
          "В случае если выполняется эксплуатационное бурение",
          "В случае если содержание сероводорода в природном газе или газоконденсате составляет не более 2 %",
          "В случае если выполняется разведочное бурение",
          "В случае наличия подземного клапана-отсекателя в составе комплекса подземного оборудования скважины",
        ],
        correctAnswers: [
          "В случае наличия подземного клапана-отсекателя в составе комплекса подземного оборудования скважины",
        ],
      },
      {
        code: "20605128",
        text: "В каком из перечисленных случаев должно проводиться забуривание новых (боковых) стволов в обсаженных скважинах?",
        answers: [
          "При восстановлении бездействующего фонда скважин",
          "В любом из перечисленных случаев",
          "При ликвидации сложных аварий",
          "При вскрытии дополнительных продуктивных мощностей",
        ],
        correctAnswers: ["В любом из перечисленных случаев"],
      },
      {
        code: "20605129",
        text: "Какая информация дополнительно включается в план при ведении работ, связанных с проводкой боковых стволов?",
        answers: [
          'Интервал вырезки "окна" в эксплуатационной колонне',
          "Компоновки колонны труб и низа бурильной колонны",
          "Режимы проходки бокового ствола и утилизации выбуренной породы",
          "Крепление пробуренного ствола (спуск фильтра, технологическая оснастка, сочленение фильтра с эксплуатационной колонной и другие технологические операции)",
          "Все перечисленное дополнительно включается в план работ",
          "Тип породоразрушающего инструмента и его привода",
        ],
        correctAnswers: [
          "Все перечисленное дополнительно включается в план работ",
        ],
      },
      {
        code: "20605130",
        text: "За какое время до начала подготовительных и монтажных работ по ремонту и реконструкции скважин бригаде должна выдаваться схема расположения подземных и наземных коммуникаций?",
        answers: [
          "Не менее чем за двое суток",
          "Не менее чем за трое суток",
          "В день начала производства работ",
          "Не менее чем за одни сутки",
        ],
        correctAnswers: ["Не менее чем за трое суток"],
      },
      {
        code: "20605131",
        text: "В каких случаях необходимо проверять изоляцию электрооборудования и исправность устройства защитного заземления буровой установки или скважины?",
        answers: [
          "Перед проведением промыслово-геофизических работ",
          "Перед началом проведения буровых работ",
          "Перед проведением любых работ на ОПО",
          "Перед проведением газоопасных работ",
        ],
        correctAnswers: ["Перед проведением промыслово-геофизических работ"],
      },
      {
        code: "20605132",
        text: "Кем составляется акт готовности скважины к промыслово-геофизическим работам? Укажите все правильные ответы.",
        answers: [
          "Ростехнадзором",
          "Организацией, проводящей экспертизу промышленной безопасности",
          "Проектной организацией",
          "Организацией-исполнителем буровых работ",
          "Эксплуатирующей организацией",
        ],
        correctAnswers: [
          "Организацией-исполнителем буровых работ",
          "Эксплуатирующей организацией",
        ],
      },
      {
        code: "20605133",
        text: "Какие действия необходимо предпринять, если взрывчатые материалы, завезенные на ОПО МНГК, использованы неполностью?",
        answers: [
          "Вывезти",
          "Утилизировать",
          "Сортировать",
          "Складировать за пределами ОПО",
        ],
        correctAnswers: ["Вывезти"],
      },
      {
        code: "20605134",
        text: "Что должно включаться в опасную зону на период прострелочно-взрывных работ на МСП, ПБУ и МЭ?",
        answers: [
          "Буровая вышка",
          "Место зарядки прострелочно-взрывных аппаратов и подготовки торпед и каротажная лебедка",
          "Все перечисленное",
          "Трасса каротажного кабеля",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "20605135",
        text: "Кем устанавливается состав работников геофизической партии и буровой бригады, находящихся при ПВР в пределах опасной зоны?",
        answers: [
          "Эксплуатирующей организацией",
          "Организацией-исполнителем ПВР",
          "Совместно всеми перечисленными организациями",
          "Организацией-исполнителем буровых работ",
        ],
        correctAnswers: ["Совместно всеми перечисленными организациями"],
      },
      {
        code: "20605136",
        text: "Какое из перечисленных действий перед проведением прострелочно-взрывных работ указано неверно?",
        answers: [
          "Включить активную катодную защиту",
          "Прекратить погрузочно-разгрузочные работы",
          "Отключить мобильные (носимые) радиостанции внутренней связи",
          "Оповестить радиостанции, находящиеся на связи, о прекращении работы радиостанции на МСП, ПБУ или МЭ до специального разрешения",
        ],
        correctAnswers: ["Включить активную катодную защиту"],
      },
      {
        code: "20605137",
        text: "Как оборудуется устье скважины при ликвидации скважин, пробуренных с ПБУ?",
        answers: [
          "На устье скважины устанавливается бетонная тумба с репером высотой не менее 5 м",
          "На устье скважины устанавливается репер",
          "Выступающая над дном моря обсадная колонна (в случае, если при бурении скважины не использовались специальная система придонных подвесок, колонные головки) удаляется на уровень дна моря",
        ],
        correctAnswers: [
          "Выступающая над дном моря обсадная колонна (в случае, если при бурении скважины не использовались специальная система придонных подвесок, колонные головки) удаляется на уровень дна моря",
        ],
      },
      {
        code: "20605138",
        text: "Куда представляется один экземпляр акта обследования дна на отсутствие навигационных опасностей, составленный эксплуатирующей организацией, при ликвидации и консервации морских нефтегазовых скважин?",
        answers: [
          "В специализированную организацию",
          "В проектную организацию",
          "В гидрографическую службу",
          "В Ростехнадзор",
        ],
        correctAnswers: ["В гидрографическую службу"],
      },
      {
        code: "20605139",
        text: "Кем утверждаются инструкции, устанавливающие требования к организации и безопасному ведению работ повышенной опасности на нефтяной шахте?",
        answers: [
          "Начальником участка",
          "Специалистом структурного подразделения, ведущего горные работы или работы по добыче нефти",
          "Техническим руководителем обособленного структурного подразделения",
          "Руководителем эксплуатирующей организации",
          "Сотрудником отдела промышленной безопасности",
        ],
        correctAnswers: [
          "Техническим руководителем обособленного структурного подразделения",
        ],
      },
      {
        code: "20605140",
        text: "С какой периодичностью осуществляется контроль мест ведения работ в горных выработках нефтяной шахты руководителем или заместителем руководителя структурного подразделения, за которым закреплены эти горные выработки?",
        answers: [
          "Каждые 10 часов",
          "Ежесменно",
          "Каждые 3 часа",
          "Ежесуточно",
          "Еженедельно",
        ],
        correctAnswers: ["Ежесуточно"],
      },
      {
        code: "20605141",
        text: "Кому выдается наряд на выполнение работ на горных выработках нефтяной шахты, определенных организационно-распорядительным документом обособленного структурного подразделения?",
        answers: [
          "Не менее чем двум работникам, имеющим стаж работы по профессии не менее 6 месяцев",
          "Не менее чем двум работникам, имеющим стаж работы по профессии не менее 4 месяцев",
          "Не менее чем двум работникам, имеющим стаж работы по профессии не менее 12 месяцев",
          "Не менее чем трем работникам, имеющим стаж работы по профессии не менее 6 месяцев",
        ],
        correctAnswers: [
          "Не менее чем двум работникам, имеющим стаж работы по профессии не менее 6 месяцев",
        ],
      },
      {
        code: "20605142",
        text: "Что требуется для остановки технических устройств, обеспечивающих проветривание и кондиционирование горных выработок, водоснабжение, откачку воды, спуск и подъем работников, работу МФСБ для выполнения ремонтных работ?",
        answers: [
          "Письменное разрешение технического руководителя обособленного структурного подразделения",
          "Письменное распоряжение технического руководителя обособленного структурного подразделения, согласованное с Ростехнадзором",
          "Письменное распоряжение руководителя эксплуатирующей организации",
          "Письменное или устное распоряжение технического руководителя обособленного структурного подразделения",
          "Акт-допуск, подписанный руководителем эксплуатирующей организации",
        ],
        correctAnswers: [
          "Письменное разрешение технического руководителя обособленного структурного подразделения",
        ],
      },
      {
        code: "20605143",
        text: "Чему должна соответствовать документация по ведению горных работ и документация по ведению работ по добыче нефти?",
        answers: [
          "Проектной документации, а также горно-геологическим и горнотехническим условиям",
          "Инструкции по охране окружающей среды при строительстве скважин на нефть и газ",
          "Инструкции по производству маркшейдерских работ",
          "Техническим регламентам таможенного союза",
        ],
        correctAnswers: [
          "Проектной документации, а также горно-геологическим и горнотехническим условиям",
        ],
      },
      {
        code: "20605144",
        text: "Кем утверждается документация по ведению горных работ?",
        answers: [
          "Специалистом, ответственным за осуществление производственного контроля",
          "Главным инженером проекта",
          "Руководителем обособленного структурного подразделения",
          "Главным механиком",
        ],
        correctAnswers: [
          "Руководителем обособленного структурного подразделения",
        ],
      },
      {
        code: "20605145",
        text: "Какие виды работ выполняются при разработке проектной документации на нефтяных месторождениях с породами, склонными к динамическим явлениям?",
        answers: [
          "Обследование разгазированных выработок",
          "Проверка состояния крепи ствола и закрепного пространства",
          "Аэродинамическое обследование",
          "Геодинамическое районирование",
        ],
        correctAnswers: ["Геодинамическое районирование"],
      },
      {
        code: "20605146",
        text: "Какие требования предъявляются Правилами безопасности в нефтяной и газовой промышленности к работникам, выполняющим горные работы?",
        answers: [
          "Горные работы выполняются не менее чем тремя работниками, причем стаж работы по профессии одного из них должен быть не менее 6 месяцев",
          "Горные работы выполняются не менее чем тремя работниками, причем стаж работы по профессии одного из них должен быть не менее 3 месяцев",
          "Горные работы выполняются не менее чем двумя работниками, причем стаж работы по профессии одного из них должен быть не менее 6 месяцев",
          "Горные работы выполняются не менее чем двумя работниками, причем стаж работы по профессии одного из них должен быть не менее 12 месяцев",
        ],
        correctAnswers: [
          "Горные работы выполняются не менее чем двумя работниками, причем стаж работы по профессии одного из них должен быть не менее 6 месяцев",
        ],
      },
      {
        code: "20605147",
        text: "С какой периодичностью проверяется состояние горных выработок, служащих запасными выходами, лицами, назначенными организационно-распорядительным документом обособленного структурного подразделения?",
        answers: [
          "Не реже одного раза в шесть месяцев",
          "Не реже одного раза в три месяца",
          "Не реже одного раза в год",
          "Не реже одного раза в месяц",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "20605148",
        text: "Чем оборудуются наклонные горные выработки, предназначенные для передвижения людей, при углах наклона от 31° до 45°?",
        answers: [
          "Сходнями со ступенями и перилами",
          "Трапами с перилами",
          "Лестницами с горизонтальными ступенями и перилами",
          "Трапами",
        ],
        correctAnswers: ["Лестницами с горизонтальными ступенями и перилами"],
      },
      {
        code: "20605149",
        text: "В соответствии с какими документами осуществляется проведение и крепление горных выработок?",
        answers: [
          "Всеми перечисленными",
          "Проектной документацией",
          "Технологическими регламентами",
          "Документацией по ведению горных работ",
        ],
        correctAnswers: ["Всеми перечисленными"],
      },
      {
        code: "20605150",
        text: "Как Правила безопасности в нефтяной и газовой промышленности регламентируют использование крепи горной выработки в качестве опорной конструкции?",
        answers: [
          "Использование крепи Правилами не регламентируется",
          "Использование крепи не допускается, за исключением подвески вентиляционных труб и кабельной сети",
          "Использование крепи не допускается",
          "Использование крепи допускается",
        ],
        correctAnswers: [
          "Использование крепи не допускается, за исключением подвески вентиляционных труб и кабельной сети",
        ],
      },
      {
        code: "20605151",
        text: "С какой периодичностью необходимо проводить осмотр крепи и армировки вертикальных горных выработок?",
        answers: [
          "Не реже одного раза в год",
          "Не реже одного раза в месяц",
          "Не реже одного раза в неделю",
          "Не реже одного раза в день",
        ],
        correctAnswers: ["Не реже одного раза в неделю"],
      },
      {
        code: "20605152",
        text: "Какое из утверждений по содержанию ремонта горных выработок указано неверно?",
        answers: [
          "Работы по перекреплению вертикальных горных выработок проводятся с закрепленного неподвижного или подвесного полка. На полке предусматривается подвесная лестница до полка лестничного отделения",
          "В кровле и боках незакрепленных горных выработок или выработок, закрепленных анкерной крепью, не допускается наличие отслоившихся кусков породы",
          "Допускается одновременное проведение ремонтных работ в горной выработке с углом наклона 18° и более в двух и более местах под непосредственным руководством работника структурного подразделения, за которым закреплены эти горные выработки",
          "Профилирование проводников и стенок главных вертикальных шахтных стволов проводится не реже одного раза в год, вспомогательных шахтных стволов - не реже одного раза в 2 года",
        ],
        correctAnswers: [
          "Допускается одновременное проведение ремонтных работ в горной выработке с углом наклона 18° и более в двух и более местах под непосредственным руководством работника структурного подразделения, за которым закреплены эти горные выработки",
        ],
      },
      {
        code: "20605153",
        text: "Какие требования к атмосфере в горных выработках нефтяных шахт указаны верно?",
        answers: [
          "Максимально допустимая концентрация диоксида углерода в рудничном воздухе в горных выработках с исходящей струей крыла, горизонта и шахты составляет 0,75%",
          "Все перечисленные",
          "Максимально допустимая концентрация водорода в зарядных камерах составляет 0,5%",
          "Концентрация кислорода в воздухе в горных выработках нефтяных шахт должна составлять не менее 20% (по объему)",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "20605154",
        text: "Какой должна быть скорость воздуха в горных выработках нефтяных шахт длиной менее 30 м?",
        answers: [
          "Не менее 0,1 м/с",
          "Не менее 0,25 м/с",
          "Не менее 0,3 м/с",
          "Не менее 0,4 м/с",
        ],
        correctAnswers: ["Не менее 0,1 м/с"],
      },
      {
        code: "20605155",
        text: "Какая максимальная температура воздуха допускается в добычных, подготовительных и других действующих выработках у мест, где работают люди, при относительной влажности до 90 %?",
        answers: ["28 °C", "24 °C", "25 °C", "26 °C"],
        correctAnswers: ["26 °C"],
      },
      {
        code: "20605156",
        text: "Как Правила безопасности в нефтяной и газовой промышленности регламентируют посещение работниками горных выработок, температура рудничной атмосферы в которых превышает значения 25 °C при относительной влажности свыше 90 %?",
        answers: [
          "Посещение допускается не менее чем двумя работниками",
          "Посещение допускается при скорости воздуха не менее 0,05 м/с",
          "Посещение не допускается",
          "Посещение допускается не более чем на 30 минут",
        ],
        correctAnswers: [
          "Посещение допускается не менее чем двумя работниками",
        ],
      },
      {
        code: "20605157",
        text: "С какой периодичностью на нефтяной шахте проводится плановая депрессионная съемка?",
        answers: [
          "Не реже одного раза в 3 года",
          "Не реже одного раза в полгода",
          "Не реже одного раза в 5 лет",
          "Не реже одного раза в год",
        ],
        correctAnswers: ["Не реже одного раза в 3 года"],
      },
      {
        code: "20605158",
        text: "Кем выполняется вскрытие перемычек и разгазирование изолированных горных выработок?",
        answers: [
          "Профессиональным аварийно-спасательным формированием",
          "Специализированной подрядной организацией",
          "Службой аэрологической безопасности",
          "Эксплуатирующей организацией",
        ],
        correctAnswers: [
          "Профессиональным аварийно-спасательным формированием",
        ],
      },
      {
        code: "20605159",
        text: "Каким образом организуется проветривание тупиковых горных выработок?",
        answers: [
          "Расстояние от конца вентиляционных труб до тупиковой горной выработки, проветриваемой вентиляторной установкой, должно быть не более 30 м",
          "Суммарная концентрация углеводородных газов не должна превышать 15 % от НКПР смеси или 0,5 % (по объему)",
          "Струя воздуха, исходящая из тупиковой горной выработки, должна попадать в горные выработки со свежей струей воздуха, проветривающей горные выработки, в которых проводятся закачка теплоносителя в пласт",
          "Исходящие из тупиковых горных выработок вентиляционные струи не должны поступать в горные выработки, в которых проводится закачка теплоносителя в пласт и (или) отбор продукции добывающих скважин",
        ],
        correctAnswers: [
          "Исходящие из тупиковых горных выработок вентиляционные струи не должны поступать в горные выработки, в которых проводится закачка теплоносителя в пласт и (или) отбор продукции добывающих скважин",
        ],
      },
      {
        code: "20605160",
        text: "Какие средства могут применяться для контроля состава рудничной атмосферы при абсолютной газообильности горных выработок менее 3 м³/мин?",
        answers: [
          "Универсальные средства контроля",
          "Переносные и (или) стационарные средства контроля",
          "Выбор средств контроля суммарного содержания углеводородных газов и паров жидких углеводородов в горных выработках осуществляет технический руководитель обособленного структурного подразделения",
          "Только стационарные средства контроля",
          "Только переносные средства контроля",
        ],
        correctAnswers: ["Переносные и (или) стационарные средства контроля"],
      },
      {
        code: "20605161",
        text: "На основании какого документа осуществляются работы по строительству, эксплуатации и ремонту подземных скважин?",
        answers: [
          "На основании плана работ",
          "На основании технологической инструкции",
          "На основании распоряжения руководителя работ",
          "На основании наряда",
        ],
        correctAnswers: ["На основании наряда"],
      },
      {
        code: "20605162",
        text: "За сколько суток до начала проведения работ по строительству и ремонту подземных скважин, предназначенных для закачивания теплоносителя, на участках, где ведется нагнетание пара в пласт, должно быть прекращено закачивание теплоносителя в соседние скважины?",
        answers: [
          "Не позднее чем за сутки до начала работ",
          "Не позднее чем за 5 суток до начала работ",
          "Не позднее чем за 10 суток до начала работ",
          "Не позднее чем за 15 суток до начала работ",
        ],
        correctAnswers: ["Не позднее чем за 10 суток до начала работ"],
      },
      {
        code: "20605163",
        text: "Какое из перечисленных требований промышленной безопасности допускается при строительстве и эксплуатации подземных скважин?",
        answers: [
          "Допускается устанавливать запорные приспособления между предохранительными клапанами и оборудованием",
          "Допускается устанавливать задвижки без специальных запоров на продувочных стояках, конденсатосборных и конденсатоотводных линиях",
          "Допускается использовать общешахтную сеть сжатого воздуха и (или) передвижные компрессорные установки в качестве источников сжатого воздуха",
          "Допускается устанавливать предохранительные клапаны и контрольно-измерительные приборы на работающем оборудовании",
          "Допускается применять смазочные масла, не соответствующие эксплуатационной документации на оборудование",
        ],
        correctAnswers: [
          "Допускается использовать общешахтную сеть сжатого воздуха и (или) передвижные компрессорные установки в качестве источников сжатого воздуха",
        ],
      },
      {
        code: "20605164",
        text: "С какой периодичностью контролируется состояние крепи горной выработки в месте установки бурового станка работником структурного подразделения, за которым закреплена эта выработка?",
        answers: [
          "В начале и в конце рабочей смены",
          "Состояние крепи горной выработки контролируется только старшим в звене машинистом буровых установок в середине рабочей смены",
          "Постоянно в течение рабочей смены",
          "Один раз в смену",
        ],
        correctAnswers: ["Один раз в смену"],
      },
      {
        code: "20605165",
        text: "Какими способами допускается освоение скважин в нефтяных шахтах? Укажите все правильные ответы.",
        answers: [
          "Свабированием (поршневанием)",
          "Способом аэрации жидкости в скважине",
          "Способом нагнетания сжатого воздуха в скважину",
          "Тартанием желонкой",
        ],
        correctAnswers: [
          "Способом аэрации жидкости в скважине",
          "Способом нагнетания сжатого воздуха в скважину",
        ],
      },
      {
        code: "20605166",
        text: "Какой должен использоваться комплект вспомогательного инструмента при глушении подземной скважины в горной выработке, из которой она пробурена?",
        answers: [
          "Латунный или обмедненный",
          "Чугунный",
          "Бронзовый",
          "Стальной",
        ],
        correctAnswers: ["Латунный или обмедненный"],
      },
      {
        code: "20605167",
        text: "Какое из перечисленных требований к объектам сбора, транспорта, подготовки нефти, попутного нефтяного газа, воды в горных выработках указано верно?",
        answers: [
          "Водоотливные канавки в горных выработках нефтяных шахт II группы опасности по углеводородным газам используются для сбора и отвода жидкости, выделяющейся из горных пород",
          "Нефтеловушки и нефтеперекачивающие станции размещаются в тупиковых горных выработках",
          "При бурении скважин станками с гидроприводом или с промывкой в полевых штреках сброс воды в водоотливные канавки категорически запрещен",
          "Допускается оставлять застой нефти на фундаментах насосов и под фундаментной рамой оборудования и механизмов, если это регламентировано локальной инструкцией организации",
        ],
        correctAnswers: [
          "Водоотливные канавки в горных выработках нефтяных шахт II группы опасности по углеводородным газам используются для сбора и отвода жидкости, выделяющейся из горных пород",
        ],
      },
      {
        code: "20605168",
        text: "Где прокладываются напорные водопроводы и трубопроводы сжатого воздуха?",
        answers: [
          "В скважинах",
          "В наклонных горных выработках",
          "В вертикальных горных выработках, оборудованных клетевым подъемом",
          "В горизонтальных горных выработках",
        ],
        correctAnswers: [
          "В вертикальных горных выработках, оборудованных клетевым подъемом",
        ],
      },
      {
        code: "20605169",
        text: "Какие из перечисленных трубопроводов прокладываются в вентиляционной выработке эксплуатационных уклонов или панелей? Укажите все правильные ответы.",
        answers: [
          "Напорный трубопровод к гидроэлеваторам и буровому станку",
          "Трубопровод сжатого воздуха",
          "Сбросной трубопровод гидроэлеваторов",
          "Трубопровод для теплоносителя",
          "Трубопровод инертного газа",
        ],
        correctAnswers: [
          "Сбросной трубопровод гидроэлеваторов",
          "Трубопровод для теплоносителя",
        ],
      },
      {
        code: "20605170",
        text: "Как должны размещаться трубопроводы, предназначенные для транспортирования газов и водяного пара?",
        answers: [
          "В верхних частях горных выработок",
          "В нижних частях горных выработок",
          "В горных выработках, проведенных по породам, склонных к пучению",
          "В изолированных каналах",
        ],
        correctAnswers: ["В верхних частях горных выработок"],
      },
      {
        code: "20605171",
        text: "У каких лиц устанавливается аппаратура системы общешахтного аварийного оповещения?",
        answers: [
          "У горного диспетчера нефтяной шахты",
          "У технического руководителя обособленного структурного подразделения",
          "У всех перечисленных лиц",
          "У абонентов общешахтной и диспетчерской телефонной связи, определенных техническим руководителем обособленного структурного подразделения",
        ],
        correctAnswers: ["У всех перечисленных лиц"],
      },
      {
        code: "20605172",
        text: "Какие из приведенных помещений (пространств) относятся к зоне 0 (участок, на котором взрывоопасная смесь присутствует постоянно или в течение длительных периодов времени)?",
        answers: [
          "Открытые пространства вокруг закрытых и открытых технических устройств, оборудования",
          "Закрытые помещения, в которых установлены открытые технические устройства, аппараты, емкости или имеются выходы для паров нефти и легковоспламеняющихся газов",
          "Закрытые помещения насосных для сточных вод",
          "Полузакрытые пространства, в которых установлены технические устройства, оборудование, аппараты",
        ],
        correctAnswers: [
          "Закрытые помещения, в которых установлены открытые технические устройства, аппараты, емкости или имеются выходы для паров нефти и легковоспламеняющихся газов",
        ],
      },
      {
        code: "20605173",
        text: "Какие из приведенных помещений (пространств) относятся к зоне 1 (участок, на котором может присутствовать взрывоопасная смесь в нормальном рабочем режиме)?",
        answers: [
          "Закрытые помещения для хранения шлангов для перекачки легковоспламеняющихся жидкостей",
          "Открытые пространства вокруг закрытых и открытых технических устройств, оборудования",
          "Закрытые помещения насосных для сточных вод",
          "Пространство внутри открытых и закрытых технических устройств и емкостей, содержащих нефть, буровой раствор, обработанный нефтью, нефтяные газы или другие легковоспламеняющиеся вещества",
        ],
        correctAnswers: ["Закрытые помещения насосных для сточных вод"],
      },
      {
        code: "20605174",
        text: "Какие из приведенных помещений (пространств) относятся к зоне 2 (участок, на котором присутствие взрывоопасной смеси в нормальном рабочем режиме исключается на открытых площадках и в помещениях)?",
        answers: [
          "Пространство под ротором, ограниченное цилиндром радиусом 3 м от оси скважины, на всю высоту до низа при открытом подроторном пространстве вокруг фонтанной арматуры, ограниченное расстоянием 3 м во все стороны",
          "Закрытые помещения насосных для сточных вод",
          "Пространство внутри открытых и закрытых технических устройств и емкостей, содержащих нефть, буровой раствор, обработанный нефтью, нефтяные газы или другие легковоспламеняющиеся вещества",
          "Закрытые помещения для хранения шлангов для перекачки легковоспламеняющихся жидкостей",
        ],
        correctAnswers: [
          "Пространство под ротором, ограниченное цилиндром радиусом 3 м от оси скважины, на всю высоту до низа при открытом подроторном пространстве вокруг фонтанной арматуры, ограниченное расстоянием 3 м во все стороны",
        ],
      },
    ],
  },
  84: {
    692: [
      {
        code: "66334",
        text: 'На какие организации требования ФНП "Правила безопасности сетей газораспределения и газопотребления" не распространяются?',
        answers: [
          "Осуществляющие деятельность по техническому диагностированию и перевооружению оборудования сетей газораспределения и газопотребления",
          "Осуществляющие деятельность по текущему и капитальному ремонту сетей газораспределения и газопотребления",
          "Осуществляющие деятельность по проектированию, строительству и реконструкции сетей газораспределения и газопотребления",
        ],
        correctAnswers: [
          "Осуществляющие деятельность по проектированию, строительству и реконструкции сетей газораспределения и газопотребления",
        ],
      },
      {
        code: "66335",
        text: "В соответствии с требованиями каких документов должны осуществляться эксплуатация, техническое перевооружение, ремонт, консервация и ликвидация сетей газораспределения и газопотребления?",
        answers: [
          "Технического регламента о безопасности сетей газораспределения и газопотребления",
          'Федерального закона N 116-ФЗ от 21.07.1997 "О промышленной безопасности опасных производственных объектов"',
          'ФНП "Правила безопасности сетей газораспределения и газопотребления"',
          "Всех перечисленных документов",
        ],
        correctAnswers: ["Всех перечисленных документов"],
      },
      {
        code: "66336",
        text: "Кто осуществляет государственный контроль (надзор) при эксплуатации сетей газораспределения и газопотребления?",
        answers: [
          "Федеральный государственный строительный надзор",
          "Федеральное агентство по техническому регулированию и метрологии",
          "Федеральная служба по экологическому, технологическому и атомному надзору",
          "Федеральный государственный энергетический надзор",
        ],
        correctAnswers: [
          "Федеральная служба по экологическому, технологическому и атомному надзору",
        ],
      },
      {
        code: "66337",
        text: "В течение какого времени организация, осуществляющая деятельность по эксплуатации сетей газораспределения и газопотребления, должна хранить проектную и исполнительную документацию?",
        answers: [
          "В течение 50 лет с начала эксплуатации",
          "В течение всего срока эксплуатации опасного производственного объекта (до ликвидации)",
          "В течение 75 лет с момента завершения строительства по проекту",
          "Срок хранения документов зависит от класса опасности объекта",
        ],
        correctAnswers: [
          "В течение всего срока эксплуатации опасного производственного объекта (до ликвидации)",
        ],
      },
      {
        code: "66338",
        text: "Какой документ устанавливает предельные сроки эксплуатации газопроводов, зданий и сооружений, технических и технологических устройств, по истечении которых должно быть обеспечено их техническое диагностирование?",
        answers: [
          "Инструкция по эксплуатации",
          "Проектная документация",
          "Регламент технического обслуживания газопроводов, зданий и сооружений, технических и технологических устройств",
          'ФНП "Правила безопасности сетей газораспределения и газопотребления"',
        ],
        correctAnswers: ["Проектная документация"],
      },
      {
        code: "66339",
        text: "К какой категории относятся газопроводы с давлением газа свыше 0,6 до 1,2 МПа включительно?",
        answers: [
          "Высокого давления 1 категории",
          "Высокого давления 1а категории",
          "Высокого давления 2 категории",
          "Низкого давления",
        ],
        correctAnswers: ["Высокого давления 1 категории"],
      },
      {
        code: "66340",
        text: "К какой категории относятся газопроводы с давлением газа свыше 0,3 до 0,6 МПа включительно?",
        answers: [
          "Высокого давления 1 категории",
          "Высокого давления 2 категории",
          "Высокого давления 1а категории",
          "Среднего давления",
        ],
        correctAnswers: ["Высокого давления 2 категории"],
      },
      {
        code: "66341",
        text: "К какой категории относятся газопроводы с давлением газа свыше 0,005 до 0,3 МПа включительно?",
        answers: [
          "Высокого давления 1 категории",
          "Высокого давления 2 категории",
          "Среднего давления",
          "Высокого давления 1а категории",
        ],
        correctAnswers: ["Среднего давления"],
      },
      {
        code: "66342",
        text: "К какой категории относятся газопроводы с давлением газа до 0,005 МПа включительно?",
        answers: [
          "Высокого давления 1 категории",
          "Высокого давления 1а категории",
          "Среднего давления",
          "Низкого давления",
        ],
        correctAnswers: ["Низкого давления"],
      },
      {
        code: "66343",
        text: "На какие сети, а также на связанные с ними процессы проектирования, строительства, реконструкции, монтажа, эксплуатации (включая техническое обслуживание, текущий ремонт), капитального ремонта, консервации и ликвидации требования Технического регламента о безопасности сетей газораспределения и газопотребления не распространяются?",
        answers: [
          "На сети газораспределения и газопотребления общественных и бытовых зданий",
          "На сети газораспределения жилых зданий",
          "На сети газопотребления жилых зданий",
          "На сети газопотребления парогазовых и газотурбинных установок давлением свыше 1,2 МПа",
        ],
        correctAnswers: ["На сети газопотребления жилых зданий"],
      },
      {
        code: "66344",
        text: "Что из перечисленного не входит в состав сети газораспределения?",
        answers: [
          "Наружные газопроводы",
          "Сооружения",
          "Технические и технологические устройства",
          "Внутренние газопроводы",
        ],
        correctAnswers: ["Внутренние газопроводы"],
      },
      {
        code: "66345",
        text: "Продувочный газопровод - газопровод, предназначенный для:",
        answers: [
          "Для вытеснения газа или воздуха (по условиям эксплуатации) из газопроводов и технических устройств",
          "Для отвода газа или воздуха от предохранительных устройств при повышении давления в газопроводе",
          "Для сброса избыточного давления газа из газопровода и технических устройств",
          "Для вытеснения природного газа из газопровода и технических устройств при их отключении",
        ],
        correctAnswers: [
          "Для вытеснения газа или воздуха (по условиям эксплуатации) из газопроводов и технических устройств",
        ],
      },
      {
        code: "66346",
        text: "По каким существенным признакам сети газораспределения и газопотребления идентифицируются в качестве объекта технического регулирования Технического регламента о безопасности сетей газораспределения и газопотребления?",
        answers: [
          "Только по назначению",
          "Только по составу объектов, входящих в сети газораспределения и газопотребления",
          "Только по давлению газа, определенному в техническом регламенте",
          "По всем указанным признакам, рассматриваемым исключительно в совокупности",
        ],
        correctAnswers: [
          "По всем указанным признакам, рассматриваемым исключительно в совокупности",
        ],
      },
      {
        code: "66347",
        text: "В каком из приведенных случаев объект технического регулирования идентифицируется в качестве сети газораспределения?",
        answers: [
          "Если объект транспортирует природный газ по территориям населенных пунктов с давлением, не превышающим 1,2 МПа",
          "Если объект транспортирует природный газ к производственным площадкам, на которых размещены газотурбинные установки с давлением, не превышающим 1 МПа",
          "Если объект транспортирует природный газ между населенными пунктами с давлением, не превышающим 0,004 МПа",
        ],
        correctAnswers: [
          "Если объект транспортирует природный газ по территориям населенных пунктов с давлением, не превышающим 1,2 МПа",
        ],
      },
      {
        code: "66348",
        text: "В каком из приведенных случаев объект технического регулирования идентифицируется в качестве сети газопотребления?",
        answers: [
          "Если объект транспортирует природный газ между населенными пунктами с давлением, превышающим 0,01 МПа",
          "Если объект транспортирует природный газ к газотурбинным установкам с давлением, не превышающим 5 МПа",
          "Если объект транспортирует природный газ к газоиспользующему оборудованию, размещенному вне зданий, с давлением, не превышающим 1,2 МПа",
        ],
        correctAnswers: [
          "Если объект транспортирует природный газ к газоиспользующему оборудованию, размещенному вне зданий, с давлением, не превышающим 1,2 МПа",
        ],
      },
      {
        code: "66349",
        text: "Каким должно быть максимальное значение величины давления природного газа в сетях газопотребления газоиспользующего оборудования в котельных, отдельно стоящих на территории производственных предприятий?",
        answers: ["2,5 МПа", "1,2 МПа", "0,6 МПа", "0,005 МПа"],
        correctAnswers: ["1,2 МПа"],
      },
      {
        code: "66350",
        text: "Каким должно быть максимальное значение величины давления природного газа в сетях газопотребления газоиспользующего оборудования в котельных, отдельно стоящих на территории поселений?",
        answers: ["2,5 МПа", "1,2 МПа", "0,6 МПа", "0,005 МПа"],
        correctAnswers: ["0,6 МПа"],
      },
      {
        code: "66351",
        text: "Каким должно быть максимальное значение величины давления природного газа в сетях газопотребления газоиспользующего оборудования в котельных, пристроенных к жилым зданиям, крышным котельным жилых зданий?",
        answers: ["2,5 МПа", "1,2 МПа", "0,6 МПа", "0,005 МПа"],
        correctAnswers: ["0,005 МПа"],
      },
      {
        code: "66352",
        text: "Что должны обеспечить сети газораспределения и газопотребления как объекты технического регулирования?",
        answers: [
          "Безопасность и энергетическую эффективность транспортирования природного газа с параметрами по давлению и расходу, определенными проектной документацией и условиями эксплуатации",
          "Экологическую безопасность процесса транспортирования газа с параметрами, определенными проектной документацией",
          "Снабжение газоиспользующих установок природным газом с параметрами по давлению и расходу, определенными проектной документацией",
        ],
        correctAnswers: [
          "Безопасность и энергетическую эффективность транспортирования природного газа с параметрами по давлению и расходу, определенными проектной документацией и условиями эксплуатации",
        ],
      },
      {
        code: "66353",
        text: "В течение какого срока передается оперативное сообщение об аварии, инциденте на опасном производственном объекте?",
        answers: [
          "В течение 24 часов с момента возникновения аварии, инцидента",
          "В течение 36 часов с момента возникновения аварии, инцидента",
          "В течение 12 часов с момента возникновения аварии, инцидента",
          "Немедленно",
        ],
        correctAnswers: [
          "В течение 24 часов с момента возникновения аварии, инцидента",
        ],
      },
      {
        code: "66354",
        text: "Кто возглавляет специальную комиссию по техническому расследованию причин аварии на опасном производственном объекте?",
        answers: [
          "Представитель Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности) или его территориального органа",
          "Работник из числа руководящего персонала организации, эксплуатирующей опасный производственный объект",
          "Общественный инспектор в области промышленной безопасности",
          "Представитель органа исполнительной власти субъекта Российской Федерации",
        ],
        correctAnswers: [
          "Представитель Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности) или его территориального органа",
        ],
      },
      {
        code: "66355",
        text: "Каким образом назначается комиссия по техническому расследованию причин аварии на опасном производственном объекте?",
        answers: [
          "Приказом Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности) или его территориального органа в срок не позднее 24 часов после получения оперативного сообщения об аварии",
          "Приказом организации, эксплуатирующей опасный производственный объект, в течение 12 часов с момента возникновения аварии",
          "Приказом органа исполнительной власти субъекта Российской Федерации, на территории которого произошла авария, в срок не позднее 24 часов после получения оперативного сообщения об аварии",
          "Приказом Ростехнадзора или его территориального органа в течение 24 часов с момента возникновения аварии",
        ],
        correctAnswers: [
          "Приказом Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности) или его территориального органа в срок не позднее 24 часов после получения оперативного сообщения об аварии",
        ],
      },
      {
        code: "66356",
        text: "Какое число членов должно входить в состав комиссии по техническому расследованию причин аварии на опасном производственном объекте?",
        answers: ["Нечетное", "Четное", "Не более 5", "Не менее 3"],
        correctAnswers: ["Нечетное"],
      },
      {
        code: "66357",
        text: "В течение какого срока составляется акт технического расследования причин аварии на опасном производственном объекте?",
        answers: [
          "Не позднее 30 календарных дней",
          "Не позднее 20 рабочих дней",
          "Не позднее 10 календарных дней",
          "Не позднее 5 рабочих дней",
          "Не позднее 45 календарных дней",
        ],
        correctAnswers: ["Не позднее 30 календарных дней"],
      },
      {
        code: "66358",
        text: "На сколько может быть увеличен срок технического расследования причин аварии на опасном производственном объекте?",
        answers: [
          "Не более чем на 15 календарных дней",
          "Не более чем на 5 рабочих дней",
          "Не более чем на 20 рабочих дней",
          "Не более чем на 10 календарных дней",
        ],
        correctAnswers: ["Не более чем на 15 календарных дней"],
      },
      {
        code: "66359",
        text: "В течение какого срока по результатам технического расследования причин аварии руководителем организации издается приказ, определяющий меры по устранению причин и последствий аварии, по обеспечению безаварийной и стабильной работы опасного производственного объекта?",
        answers: [
          "В течение 7 рабочих дней",
          "В течение суток",
          "В течение 5 рабочих дней",
          "В течение 3 суток",
        ],
        correctAnswers: ["В течение 7 рабочих дней"],
      },
      {
        code: "66360",
        text: "Чьим приказом создается комиссия для технического расследования причин инцидентов на опасном производственном объекте?",
        answers: [
          "Приказом (внутренним распорядительным актом) руководителя организации, эксплуатирующей объект",
          "Приказом территориального органа Ростехнадзора",
          "Приказом Государственной инспекции по труду",
        ],
        correctAnswers: [
          "Приказом (внутренним распорядительным актом) руководителя организации, эксплуатирующей объект",
        ],
      },
      {
        code: "66361",
        text: "С какой периодичностью должна направляться информация об инцидентах, происшедших на опасных производственных объектах, в территориальный орган Ростехнадзора?",
        answers: [
          "Не реже одного раза в квартал",
          "Не реже одного раза в месяц",
          "Не реже одного раза в полугодие",
          "Не реже одного раза в год",
        ],
        correctAnswers: ["Не реже одного раза в квартал"],
      },
      {
        code: "66362",
        text: "Чем регламентируется порядок проведения работ по установлению причин инцидентов на опасном производственном объекте?",
        answers: [
          "Документом, утвержденным организацией, эксплуатирующей опасный производственный объект",
          "Документом, утвержденным организацией, эксплуатирующей опасный производственный объект, согласованным с органами Ростехнадзора",
          "Документом, утвержденным организацией, эксплуатирующей опасные производственные объекты, по согласованию с территориальными органами МЧС России",
        ],
        correctAnswers: [
          "Документом, утвержденным организацией, эксплуатирующей опасный производственный объект",
        ],
      },
      {
        code: "66363",
        text: "Какую информацию должен содержать акт по установлению причин инцидента на опасном производственном объекте?",
        answers: [
          "Всю перечисленную информацию",
          "Только дату и место инцидента, его причины и обстоятельства",
          "Только информацию о продолжительности простоя и мерах по устранению причин инцидента",
          "Только информацию о принятых мерах по ликвидации инцидента, а также информацию о материальном ущербе, в том числе вреде, нанесенном окружающей среде",
        ],
        correctAnswers: ["Всю перечисленную информацию"],
      },
      {
        code: "66364",
        text: "Куда передается оперативное сообщение об аварии или инциденте?",
        answers: [
          "В орган местного самоуправления",
          "В территориальный орган Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности), осуществляющий надзор за объектом, либо территориальный орган Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности), на территории деятельности которого произошла авария (при эксплуатации передвижных технических устройств (кранов, подъемников (вышек), передвижных котельных, цистерн, вагонов, локомотивов, автомобилей)",
          "В страховую организацию, с которой заключен договор обязательного страхования гражданской ответственности в соответствии c законодательством Российской Федерации об обязательном страховании гражданской ответственности владельца опасного производственного объекта за причинение вреда в результате аварии на опасном объекте",
          "В федеральный орган исполнительной власти, осуществляющий функции по контролю и надзору в области охраны окружающей среды (при авариях, связанных с выбросом опасных веществ)",
          "Во все перечисленные организации",
        ],
        correctAnswers: ["Во все перечисленные организации"],
      },
      {
        code: "66365",
        text: "Кем осуществляется расчет вреда (экономического и экологического ущерба) от аварии?",
        answers: [
          "Организацией, на объекте которой произошла авария",
          "Федеральной службой по надзору в сфере природопользования",
          "Федеральной службой по экологическому, технологическому и атомному надзору",
          "Специализированной организацией, привлекаемой объектом, на котором произошла авария",
        ],
        correctAnswers: ["Организацией, на объекте которой произошла авария"],
      },
      {
        code: "66366",
        text: "На кого возлагается финансирование расходов на техническое расследование причин аварий?",
        answers: [
          "На организацию, эксплуатирующую опасный производственный объект, на котором произошла авария",
          "На страховщика гражданской ответственности владельца опасного объекта",
          "На органы исполнительной власти субъекта Российской Федерации, на территории которого произошла авария",
          "На юридическое или должностное лицо, которое в ходе технического расследования было признано виновным в аварии",
        ],
        correctAnswers: [
          "На организацию, эксплуатирующую опасный производственный объект, на котором произошла авария",
        ],
      },
      {
        code: "66367",
        text: "Куда организация, на объекте которой произошла авария, после окончания расследования обязана направить экземпляры материалов технического расследования причин аварии?",
        answers: [
          "Во все перечисленные органы",
          "Только в Ростехнадзор (иной федеральный орган исполнительной власти в области промышленной безопасности) или его территориальный орган",
          "Только в другие органы, определенные председателем комиссии",
          "Только в соответствующие органы, представители которых принимали участие в работе комиссии по техническому расследованию",
        ],
        correctAnswers: ["Во все перечисленные органы"],
      },
      {
        code: "66368",
        text: "Что входит в состав газораспределительных сетей?",
        answers: [
          "Только наружные подземные, наземные и надземные распределительные газопроводы, межпоселковые газопроводы, газопроводы-вводы с установленной на них запорной арматурой",
          "Только внеплощадочные газопроводы промышленных предприятий",
          "Только отдельно стоящие газорегуляторные пункты, расположенные на территории и за территорией населенных пунктов, промышленных и иных предприятий, а также газорегуляторные пункты, размещенные в зданиях, шкафах или блоках",
          "Только устройства электрохимической защиты стальных газопроводов от коррозии и средства телемеханизации газораспределительных сетей, объекты их электропривода и энергоснабжения",
          "Только переходы газопроводов через естественные и искусственные препятствия, в том числе через реки, железные и автомобильные дороги",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "66369",
        text: "В каком случае разрешается вмешательство в деятельность, связанную с обеспечением безопасной эксплуатации газораспределительных сетей, не уполномоченных на то органов исполнительной власти субъектов Российской Федерации, органов местного самоуправления, общественных организаций, юридических и физических лиц?",
        answers: [
          "В случае выполнения работ по ремонту, обслуживанию или устранению последствий аварий газораспределительной сети на землях лесного фонда или сельскохозяйственного назначения",
          "В случае расположения в охранных зонах газораспределительных сетей других инженерных коммуникаций, принадлежащих иным организациям",
          "В случае пересечения газораспределительных сетей с другими инженерными коммуникациями, принадлежащими иным организациям",
          "Запрещается в любом случае",
        ],
        correctAnswers: ["Запрещается в любом случае"],
      },
      {
        code: "66370",
        text: "Каким образом могут производиться работы по предотвращению аварий или ликвидации их последствий на газопроводах эксплуатационной организацией газораспределительной сети?",
        answers: [
          "Могут производиться в любое время года с согласованием с собственниками, владельцами или пользователями земельных участков без уведомления их о проводимых работах",
          "Могут производиться в любое время года с согласованием с собственниками, владельцами или пользователями земельных участков, с уведомлением их о проводимых работах",
          "Могут производиться в любое время года без согласования с собственниками, владельцами или пользователями земельных участков, но с уведомлением их о проводимых работах",
          "Не регламентируется",
        ],
        correctAnswers: [
          "Могут производиться в любое время года без согласования с собственниками, владельцами или пользователями земельных участков, но с уведомлением их о проводимых работах",
        ],
      },
      {
        code: "66371",
        text: "При каком условии представители организации, эксплуатирующей опасный производственный объект, принимают участие в техническом расследовании причин аварии?",
        answers: [
          "В составе комиссии по техническому расследованию, но их число не должно превышать 50 % от общего числа членов комиссии",
          "В составе комиссии по техническому расследованию, но их число не должно превышать 30 % от общего числа членов комиссии",
          "В составе комиссии по техническому расследованию, но их число не должно превышать 60 % от общего числа членов комиссии",
          "В составе комиссии по техническому расследованию, но их число не должно превышать 70 % от общего числа членов комиссии",
        ],
        correctAnswers: [
          "В составе комиссии по техническому расследованию, но их число не должно превышать 30 % от общего числа членов комиссии",
        ],
      },
      {
        code: "66372",
        text: "Как назначается специальная комиссия по техническому расследованию причин аварии?",
        answers: [
          "Назначается распорядительным документом руководителя эксплуатирующей организации",
          "Назначается правовым актом уполномоченного органа или его территориального органа",
          "Назначается распоряжением органа местного самоуправления, на территории которого произошел случай",
        ],
        correctAnswers: [
          "Назначается правовым актом уполномоченного органа или его территориального органа",
        ],
      },
      {
        code: "66373",
        text: "В течение какого времени после получения оперативного сообщения об аварии издается приказ органом, производящим расследование?",
        answers: [
          "В срок не позднее 60 часов",
          "В срок не позднее 36 часов",
          "В срок не позднее 48 часов",
          "В срок не позднее 24 часов",
        ],
        correctAnswers: ["В срок не позднее 24 часов"],
      },
      {
        code: "66374",
        text: "В какой срок материалы технического расследования аварии направляются территориальным органом Ростехнадзора в центральный аппарат Ростехнадзора?",
        answers: [
          "В двухнедельный срок",
          "В недельный срок",
          "В месячный срок",
        ],
        correctAnswers: ["В двухнедельный срок"],
      },
      {
        code: "66375",
        text: "В каком случае при пересечении надземных газопроводов высоковольтными линиями электропередачи должны быть предусмотрены защитные устройства, предотвращающие падение на газопровод электропроводов при их обрыве?",
        answers: [
          "При напряжении ВЛ свыше 1 кВ",
          "Только если газопровод относится к категории высокого давления",
          "Только при прокладке газопроводов на производственных территориях",
        ],
        correctAnswers: ["При напряжении ВЛ свыше 1 кВ"],
      },
      {
        code: "66376",
        text: "В каком случае не предусматриваются защитные покрытия и устройства, обеспечивающие сохранность газопровода?",
        answers: [
          "В местах входа и выхода из земли",
          "В местах пересечения с подземными коммуникационными коллекторами",
          "В местах прохода через строительные конструкции здания",
          'В местах наличия подземных неразъемных соединений по типу "полиэтилен-сталь"',
          "В местах пересечения полиэтиленовых газопроводов с нефтепроводами и теплотрассами",
        ],
        correctAnswers: [
          'В местах наличия подземных неразъемных соединений по типу "полиэтилен-сталь"',
        ],
      },
      {
        code: "66377",
        text: "Каким должно быть давление природного газа на входе в газорегуляторную установку?",
        answers: [
          "Не должно превышать 1,2 МПа",
          "Не должно превышать 0,3 МПа",
          "Не должно превышать 1,0 МПа",
          "Не должно превышать 0,6 МПа",
        ],
        correctAnswers: ["Не должно превышать 0,6 МПа"],
      },
      {
        code: "66378",
        text: "Что должно быть установлено на продувочном газопроводе внутреннего газопровода?",
        answers: [
          "Только отключающее устройство",
          "Байпас со штуцером для отбора проб газа",
          "Отключающее устройство, а после него - штуцер с краном для отбора проб газа",
        ],
        correctAnswers: [
          "Отключающее устройство, а после него - штуцер с краном для отбора проб газа",
        ],
      },
      {
        code: "66379",
        text: "В соответствии с требованиями Технического регламента о безопасности сетей газораспределения и газопотребления помещения зданий и сооружений, в которых устанавливается газоиспользующее оборудование, должны быть оснащены системами контроля загазованности с выводом сигнала на пульт управления:",
        answers: [
          "Только по метану",
          "По метану и оксиду серы",
          "По оксиду серы и двуоксиду углерода",
          "По метану и оксиду углерода",
        ],
        correctAnswers: ["По метану и оксиду углерода"],
      },
      {
        code: "66380",
        text: "Какие требования установлены Техническим регламентом о безопасности сетей газораспределения и газопотребления к оснащению газоходов от газоиспользующего оборудования взрывными предохранительными клапанами?",
        answers: [
          "Должны устанавливаться на горизонтальных участках газоходов от газоиспользующей установки; площадь клапанов - не менее 0,04 м² каждый; клапаны должны располагаться на расстоянии не более 20 м друг от друга",
          "Должны устанавливаться на горизонтальных участках газоходов от газоиспользующей установки; площадь клапанов - не менее 0,05 м² каждый; клапаны должны быть оборудованы защитными устройствами на случай срабатывания",
          "Должны устанавливаться на вертикальных участках газоходов от газоиспользующей установки; площадь клапанов - не менее 0,05 м² каждый",
        ],
        correctAnswers: [
          "Должны устанавливаться на горизонтальных участках газоходов от газоиспользующей установки; площадь клапанов - не менее 0,05 м² каждый; клапаны должны быть оборудованы защитными устройствами на случай срабатывания",
        ],
      },
      {
        code: "66381",
        text: "Какой воздухообмен должна обеспечивать вентиляция для помещений котельных, в которых установлено газоиспользующее оборудование, с постоянным присутствием обслуживающего персонала?",
        answers: [
          "Не менее трехкратного в час",
          "Не менее двукратного в час",
          "Не менее семикратного в час",
          "Не менее шестикратного в час",
        ],
        correctAnswers: ["Не менее трехкратного в час"],
      },
      {
        code: "66382",
        text: "За счет чего, в соответствии с требованиями Технического регламента о безопасности сетей газораспределения и газопотребления, обеспечивается энергетическая эффективность построенных, отремонтированных, реконструированных сетей газораспределения и газопотребления?",
        answers: [
          "За счет их герметичности (отсутствия утечек газа)",
          "За счет снижения сопротивления в газопроводе путем уменьшения количества его изгибов",
          "За счет оснащения помещений газоанализаторами",
        ],
        correctAnswers: ["За счет их герметичности (отсутствия утечек газа)"],
      },
      {
        code: "66383",
        text: "Чем должны оснащаться предохранительные сбросные клапаны технологических устройств?",
        answers: [
          "Сбросными газопроводами",
          "Пломбой с указанием даты испытания",
          "Молниезащитой и заземлением",
        ],
        correctAnswers: ["Сбросными газопроводами"],
      },
      {
        code: "66384",
        text: "Чем должны оснащаться технологические устройства систем газораспределения и газопотребления?",
        answers: [
          "Молниезащитой, заземлением и вентиляцией",
          "Только заземлением",
          "Только молниезащитой и вентиляцией",
          "Только молниезащитой",
        ],
        correctAnswers: ["Молниезащитой, заземлением и вентиляцией"],
      },
      {
        code: "66385",
        text: "В каком случае не допускается размещать газорегуляторные пункты шкафные на наружных стенах газифицируемых зданий?",
        answers: [
          "Если входное давление превышает 0,6 МПа",
          "Если входное давление превышает 0,3 МПа",
          "Газорегуляторные пункты допускается размещать на стенах зданий во всех случаях",
        ],
        correctAnswers: ["Если входное давление превышает 0,6 МПа"],
      },
      {
        code: "66386",
        text: "Когда после окончания сварки последнего стыка разрешается производить испытания газопроводов из полиэтиленовых труб?",
        answers: [
          "Не ранее чем через 24 часа",
          "Не ранее чем через 36 часов",
          "Не ранее чем через 12 часов",
          "Не ранее чем через 72 часа",
        ],
        correctAnswers: ["Не ранее чем через 24 часа"],
      },
      {
        code: "66387",
        text: "Какие требования установлены к участкам газопроводов, прокладываемых внутри защитных устройств через ограждающие строительные конструкции здания?",
        answers: [
          "Они не должны иметь сварных стыков, фланцевых и резьбовых соединений",
          "Они могут иметь только фланцевые соединения",
          "Они могут иметь только резьбовые соединения",
        ],
        correctAnswers: [
          "Они не должны иметь сварных стыков, фланцевых и резьбовых соединений",
        ],
      },
      {
        code: "66388",
        text: "Какой из перечисленных терминов относится к газопроводу от места присоединения к распределительному газопроводу до отключающего устройства или наружной конструкции здания либо сооружения потребителя газа, согласно Правилам охраны газораспределительных сетей?",
        answers: [
          "Газопровод-ввод",
          "Распределительные газопроводы",
          "Газорегуляторный пункт",
          "Межпоселковый газопровод",
        ],
        correctAnswers: ["Газопровод-ввод"],
      },
      {
        code: "66389",
        text: "На какое из перечисленных оборудований сетей газораспределения и газопотребления тепловых электрических станций (далее - ТЭС) должен быть составлен паспорт?",
        answers: [
          "Только на каждый газопровод (наружный и внутренний)",
          "Только на технологическое устройство (пункт редуцирования газа,  газорегуляторную установку)",
          "Только на котел",
          "На все перечисленное",
        ],
        correctAnswers: ["На все перечисленное"],
      },
      {
        code: "66390",
        text: "С какой периодичностью производят очистку и восстановление окраски газопроводов и запорной арматуры при текущем ремонте надземных газопроводов ТЭС?",
        answers: [
          "Не реже 1 раза в 10 лет",
          "Не реже 1 раза в 7 лет",
          "Не реже 1 раза в 5 лет",
          "Не реже 1 раза в 15 лет",
        ],
        correctAnswers: ["Не реже 1 раза в 5 лет"],
      },
      {
        code: "66391",
        text: "Кто утверждает графики ремонтов газопроводов и технических устройств сетей газораспределения и газопотребления ТЭС?",
        answers: [
          "Руководитель территориального органа Ростехнадзора",
          "Технический руководитель ТЭС",
          "Руководитель ТЭС",
          "Руководитель подрядной организации, производящей ремонт и обслуживание ТЭС",
        ],
        correctAnswers: ["Технический руководитель ТЭС"],
      },
      {
        code: "66392",
        text: "Какая допускается максимальная остаточная объемная доля газа в продувочном воздухе при освобождении газопроводов сетей газораспределения и газопотребления ТЭС от газа?",
        answers: [
          "Не более 30% нижнего концентрационного предела распространения пламени (НКПРП)",
          "Не более 50% НКПРП",
          "Не более 20% НКПРП",
          "Не более 40% НКПРП",
        ],
        correctAnswers: ["Не более 20% НКПРП"],
      },
      {
        code: "66393",
        text: "С каким резервом по производительности предусматриваются аппараты сетей газораспределения и газопотребления газотурбинных и парогазовых установок в каждой ступени очистки газа?",
        answers: [
          "С 20% резервом",
          "С 40% резервом",
          "С 30% резервом",
          "С 50% резервом",
        ],
        correctAnswers: ["С 50% резервом"],
      },
      {
        code: "66394",
        text: "Какое из перечисленных требований при пуске газовой турбины (далее - ГТ) указано верно? Выберите 2 варианта ответа.",
        answers: [
          "Пуск ГТ должен осуществляться из холодного состояния, при температуре металла корпуса турбины менее 150 °С, после монтажа или ремонта",
          "Пуск ГТ должен осуществляться из неостывшего состояния, при температуре металла корпуса турбины 150 °С - 250 °С",
          "Пуск ГТ должен осуществляться из горячего состояния, при температуре металла корпуса турбины ниже 250 °С",
          "Пуск ГТ должен осуществляться из холодного состояния, при температуре металла корпуса турбины более 150 °С",
        ],
        correctAnswers: [
          "Пуск ГТ должен осуществляться из холодного состояния, при температуре металла корпуса турбины менее 150 °С, после монтажа или ремонта",
          "Пуск ГТ должен осуществляться из неостывшего состояния, при температуре металла корпуса турбины 150 °С - 250 °С",
        ],
      },
      {
        code: "66395",
        text: "Какой документацией определяются способы присоединения вновь построенных газопроводов к действующим?",
        answers: [
          "Производственными инструкциями",
          "Эксплуатационной документацией",
          "Проектной документацией",
          "Технической документацией",
        ],
        correctAnswers: ["Проектной документацией"],
      },
      {
        code: "66396",
        text: "Что из перечисленного должна обеспечивать эксплуатирующая организация при эксплуатации подземных газопроводов в соответствии с Техническим регламентом о безопасности сетей газораспределения и газопотребления?",
        answers: [
          "Только мониторинг и устранение утечек природного газа",
          "Только мониторинг и устранение повреждений изоляции труб газопроводов",
          "Только мониторинг и устранение повреждений сооружений, технических и технологических устройств сетей газораспределения и газопотребления",
          "Должна обеспечивать мониторинг и устранение всех перечисленных неисправностей",
        ],
        correctAnswers: [
          "Должна обеспечивать мониторинг и устранение всех перечисленных неисправностей",
        ],
      },
      {
        code: "66397",
        text: "Какие из перечисленных требований, в соответствии с Техническим регламентом о безопасности сетей газораспределения и газопотребления, должна обеспечить эксплуатирующая организация при эксплуатации надземных газопроводов?",
        answers: [
          "Только мониторинг и устранение неисправностей в работе трубопроводной арматуры",
          "Только мониторинг и устранение перемещения газопроводов за пределы опор",
          "Только мониторинг и устранение повреждения и изгиба опор, нарушающих безопасность газопровода",
          "Должна обеспечивать мониторинг и устранение всех перечисленных неисправностей",
        ],
        correctAnswers: [
          "Должна обеспечивать мониторинг и устранение всех перечисленных неисправностей",
        ],
      },
      {
        code: "66398",
        text: "В соответствии с какими документами должны проводиться проверка срабатывания предохранительных запорных и сбросных клапанов, техническое обслуживание, текущие ремонты и наладка технологических устройств?",
        answers: [
          "В соответствии с документацией, разработанной эксплуатантом",
          "В соответствии с инструкциями изготовителей",
          "В соответствии с документацией, разработанной специализированной организацией, имеющей лицензию Ростехнадзора",
        ],
        correctAnswers: ["В соответствии с инструкциями изготовителей"],
      },
      {
        code: "66399",
        text: "Предохранительные запорные и предохранительные сбросные клапаны должны обеспечить автоматическое и ручное прекращение подачи или сброс природного газа в атмосферу при изменении давления газа до значений, выходящих за пределы, установленные:",
        answers: [
          "В документации завода-изготовителя",
          "В проектной документации",
          "В исполнительной документации",
        ],
        correctAnswers: ["В проектной документации"],
      },
      {
        code: "66400",
        text: "В какие сроки должны быть устранены неисправности регуляторов давления газа, приводящие к изменению давления газа до значений, выходящих за пределы, установленные в проектной документации, а также к утечкам природного газа?",
        answers: [
          "В течение двух часов после их выявления",
          "Незамедлительно при их выявлении",
          "В течение времени достижения предельной концентрации газа в помещении",
          "В течение суток при обеспечении принудительной вентиляции",
        ],
        correctAnswers: ["Незамедлительно при их выявлении"],
      },
      {
        code: "66401",
        text: "Когда должны включаться в работу регуляторы давления при прекращении подачи природного газа?",
        answers: [
          "После отключения предохранительного клапана",
          "Немедленно",
          "После выявления причины срабатывания предохранительного запорного клапана и принятия мер по устранению неисправности",
        ],
        correctAnswers: [
          "После выявления причины срабатывания предохранительного запорного клапана и принятия мер по устранению неисправности",
        ],
      },
      {
        code: "66402",
        text: "В какой документации устанавливаются сроки эксплуатации газопроводов, по истечении которых должно проводиться их техническое диагностирование?",
        answers: [
          "В инструкции по эксплуатации, разработанной эксплуатантом газопровода",
          "В декларации промышленной безопасности на газопровод",
          "В проектной документации",
        ],
        correctAnswers: ["В проектной документации"],
      },
      {
        code: "66403",
        text: "Допускается ли эксплуатация газопроводов, зданий и сооружений и технологических устройств сетей газораспределения и газопотребления по истечении срока, указанного в проектной документации?",
        answers: [
          "Эксплуатация не допускается",
          "Эксплуатация может быть допущена после технического диагностирования газопроводов, зданий и сооружений и технологических устройств",
          "Эксплуатация допускается после проведения экспертизы промышленной безопасности",
          "Эксплуатация допускается после проведения диагностирования и согласования продления срока эксплуатации Ростехнадзором",
        ],
        correctAnswers: [
          "Эксплуатация может быть допущена после технического диагностирования газопроводов, зданий и сооружений и технологических устройств",
        ],
      },
      {
        code: "66404",
        text: "Каким образом устанавливаются предельные сроки дальнейшей эксплуатации газопроводов?",
        answers: [
          "Предельные сроки дальнейшей эксплуатации газопроводов должны устанавливаться по результатам технического диагностирования",
          "Предельные сроки дальнейшей эксплуатации не должны превышать максимальный срок эксплуатации труб, из которых изготовлен газопровод",
          "Предельные сроки дальнейшей эксплуатации газопроводов устанавливаются эксплуатирующей организацией по итогу проведения неразрушающего контроля специализированной лабораторией",
          "Предельные сроки дальнейшей эксплуатации газопроводов не должны превышать четверти всего срока эксплуатации",
        ],
        correctAnswers: [
          "Предельные сроки дальнейшей эксплуатации газопроводов должны устанавливаться по результатам технического диагностирования",
        ],
      },
      {
        code: "66405",
        text: "В каком случае не допускается эксплуатация сети газопотребления?",
        answers: [
          "Только при неисправности газоиспользующего оборудования",
          "Только с отключенными технологическими защитами, блокировками, предусмотренными проектом",
          "Только с отключенными сигнализацией и контрольно-измерительными приборами, предусмотренными проектом",
          "Эксплуатация не допускается в любом из перечисленных случаев",
        ],
        correctAnswers: [
          "Эксплуатация не допускается в любом из перечисленных случаев",
        ],
      },
      {
        code: "66406",
        text: "Что должна обеспечивать автоматика безопасности при ее отключении или неисправности?",
        answers: [
          "Блокировку возможности подачи природного газа на газоиспользующее оборудование в ручном режиме",
          "Подачу природного газа на газоиспользующее оборудование по резервным линиям в автоматическом режиме",
          "Подачу природного газа в ручном режиме по байпасу при наличии автоматического газоанализатора в помещении",
        ],
        correctAnswers: [
          "Блокировку возможности подачи природного газа на газоиспользующее оборудование в ручном режиме",
        ],
      },
      {
        code: "66407",
        text: "При вводе сети газопотребления в эксплуатацию и после выполнения ремонтных работ газопроводы, присоединенные к газоиспользующему оборудованию, должны быть продуты:",
        answers: [
          "Оксидом углерода до вытеснения всего воздуха",
          "Природным газом до вытеснения всего воздуха",
          "Аргоном до вытеснения всего природного газа",
        ],
        correctAnswers: ["Природным газом до вытеснения всего воздуха"],
      },
      {
        code: "66408",
        text: "При каком содержании кислорода в газовоздушной смеси розжиг горелок не допускается?",
        answers: [
          "Более 0,3% по объему",
          "Более 0,9% по объему",
          "Более 1,0% по объему",
        ],
        correctAnswers: ["Более 1,0% по объему"],
      },
      {
        code: "66409",
        text: "Кто принимает решение о консервации и расконсервации сетей газораспределения и сетей газопотребления?",
        answers: [
          "Территориальный орган Ростехнадзора",
          "Эксплуатант совместно со специализированной организацией",
          "Организация-собственник с уведомлением федерального органа исполнительной власти, осуществляющего функции по контролю (надзору) в сфере промышленной безопасности",
        ],
        correctAnswers: [
          "Организация-собственник с уведомлением федерального органа исполнительной власти, осуществляющего функции по контролю (надзору) в сфере промышленной безопасности",
        ],
      },
      {
        code: "66410",
        text: "Какие мероприятия должны быть предусмотрены при консервации сетей газораспределения и сетей газопотребления?",
        answers: [
          "Только обеспечивающие их промышленную и экологическую безопасность",
          "Только обеспечивающие их материальную сохранность и предотвращение их разрушения",
          "Только обеспечивающие восстановление их работоспособности после расконсервации",
          "Все перечисленные мероприятия",
        ],
        correctAnswers: ["Все перечисленные мероприятия"],
      },
      {
        code: "66411",
        text: "В какой форме осуществляется оценка соответствия сетей газораспределения и газопотребления требованиям Технического регламента о безопасности сетей газораспределения и газопотребления при эксплуатации?",
        answers: [
          "В форме государственного энергетического надзора",
          "В форме экологического контроля",
          "В форме государственного контроля (надзора)",
          "В форме ежегодной отчетности",
        ],
        correctAnswers: ["В форме государственного контроля (надзора)"],
      },
      {
        code: "66412",
        text: "По завершении каких работ осуществляется приемка сети газопотребления в эксплуатацию?",
        answers: [
          "Только по завершении пусконаладочных работ",
          "Только по завершении строительных работ",
          "По завершении строительных, монтажных работ, а также пусконаладочных работ и комплексного опробования оборудования",
        ],
        correctAnswers: [
          "По завершении строительных, монтажных работ, а также пусконаладочных работ и комплексного опробования оборудования",
        ],
      },
      {
        code: "66413",
        text: "Представители какого федерального органа исполнительной власти не входят в состав комиссии по приемке сетей газораспределения и газопотребления в эксплуатацию?",
        answers: ["Ростехнадзора", "Минэнерго России", "Росприроднадзора"],
        correctAnswers: ["Минэнерго России"],
      },
      {
        code: "66414",
        text: "Какие из перечисленных документов не входят в состав приемо-сдаточной документации после строительства или реконструкции?",
        answers: [
          "Акты о разбивке и передаче трассы, приемке скрытых работ; приемке специальных работ, приемке внутренней полости газопровода",
          "Технико-эксплуатационная документация изготовителей технических и технологических устройств (паспорта, инструкции по эксплуатации и монтажу)",
          "Проектная документация (исполнительная документация), положительное заключение экспертизы проектной документации",
          "Протоколы проведения испытаний на герметичность сетей газораспределения и газопотребления, проверки сварных соединений и защитных покрытий",
          "Положительное заключение экспертизы промышленной безопасности проектной документации",
        ],
        correctAnswers: [
          "Положительное заключение экспертизы промышленной безопасности проектной документации",
        ],
      },
      {
        code: "66415",
        text: "Что является документальным подтверждением соответствия построенных или реконструированных сетей газораспределения и газопотребления требованиям, установленным в Техническом регламенте о безопасности сетей газораспределения и газопотребления?",
        answers: [
          "Положительное заключение экспертизы промышленной безопасности готового объекта",
          "Заключение органа исполнительной власти, осуществляющего функции по контролю (надзору) в сфере промышленной безопасности",
          "Акт приемки, подписанный всеми членами приемочной комиссии",
          "Акты успешного опробования сетей газораспределения и газопотребления",
        ],
        correctAnswers: [
          "Акт приемки, подписанный всеми членами приемочной комиссии",
        ],
      },
      {
        code: "66416",
        text: "Лицо, ответственное за безопасность эксплуатации сетей газораспределения и газопотребления, должно быть назначено приказом:",
        answers: [
          "До приемки сетей газораспределения и газопотребления",
          "После окончания монтажа оборудования",
          "До начала опробования оборудования",
        ],
        correctAnswers: [
          "До приемки сетей газораспределения и газопотребления",
        ],
      },
      {
        code: "66417",
        text: "Кем составляются и утверждаются перечень газоопасных работ и инструкция, определяющая порядок подготовки и безопасность их проведения применительно к производственным условиям, на тепловых электрических станциях (далее – ТЭС)?",
        answers: [
          "Руководителем такой организации или его уполномоченным заместителем",
          "Техническим руководителем",
          "Начальником смены",
          "Специалистом по промышленной безопасности",
        ],
        correctAnswers: [
          "Руководителем такой организации или его уполномоченным заместителем",
        ],
      },
      {
        code: "66418",
        text: "С какой периодичностью проводится визуальный контроль технического состояния (обход) ГРП, внутренних газопроводов котельной, в случае отсутствия сроков в эксплуатационной документации сети газопотребления ТЭС?",
        answers: [
          "Не реже одного раза в смену",
          "Не реже одного раза в месяц",
          "Не реже одного раза в 7 дней",
          "Не реже двух раз в месяц",
        ],
        correctAnswers: ["Не реже одного раза в смену"],
      },
      {
        code: "66419",
        text: "В каком случае построенные или реконструированные газопроводы должны пройти повторное испытание на герметичность?",
        answers: [
          "Если газопроводы не были введены в эксплуатацию в течение 6 месяцев со дня испытания",
          "Если газопроводы не были введены в эксплуатацию в течение 3 месяцев со дня испытания",
          "Все газопроводы подлежат повторному испытанию непосредственно перед пуском работу",
        ],
        correctAnswers: [
          "Если газопроводы не были введены в эксплуатацию в течение 6 месяцев со дня испытания",
        ],
      },
      {
        code: "66420",
        text: "Каким образом определяется порядок перевода котла с пылеугольного или жидкого топлива на природный газ?",
        answers: [
          "Производственной инструкцией по эксплуатации котла",
          "Технологической картой",
          "Проектной документацией",
          "Порядком выполнения работ",
        ],
        correctAnswers: ["Производственной инструкцией по эксплуатации котла"],
      },
      {
        code: "66421",
        text: "Какие сроки обслуживания сетей газораспределения и газопотребления ТЭС указаны верно?",
        answers: [
          "Техническое обслуживание технических устройств проводится по графику, утвержденному персоналом, осуществляющим осмотр оборудования",
          "Техническое обслуживание газопроводов проводится не реже одного раза в три месяца",
          "Проверка параметров срабатывания ПЗК и ПСК в ГРП проводится не реже одного раза в месяц",
          "Все перечисленные сроки",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "66422",
        text: "В каком случае газотурбинная установка (далее - ГТУ) не должна быть немедленно отключена действием защит или персоналом?",
        answers: [
          "Все ответы неверны",
          "В случае обнаружения трещин газопроводов",
          "В случае слива из любого подшипника или недопустимого повышения температуры любой из колодок упорного подшипника",
          "В случае закрытого положения заслонки на дымовой трубе котла-утилизатора",
          "В случае возникновения помпажа компрессоров",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "66423",
        text: "С какой периодичностью проводится визуальный контроль технического состояния (обход) надземных газопроводов в случае отсутствия сроков в эксплуатационной документации сети газопотребления ТЭС?",
        answers: [
          "Все ответы неверны",
          "Не реже двух раз в смену",
          "Не реже одного раза в 2 дня",
          "Не реже четырех раз в месяц",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "66424",
        text: "Какое из перечисленных требований к эксплуатации сетей газораспределения и газопотребления ТЭС указано неверно?",
        answers: [
          "Сброс удаленной из газопровода жидкости осуществляется в канализацию на основании эксплуатационной документации",
          "Перед входом в помещение должна быть проведена проверка загазованности помещения переносным сигнализатором",
          "Проверка срабатывания устройств технологических защит и действия сигнализации по максимальному и минимальному давлению газа в газопроводах проводится в сроки, установленные изготовителями, но не реже одного раза в шесть месяцев",
          "Контроль загазованности в помещениях ГРП и котельной должен проводиться стационарными сигнализаторами загазованности или переносным прибором из верхней зоны помещений не реже одного раза в смену",
        ],
        correctAnswers: [
          "Сброс удаленной из газопровода жидкости осуществляется в канализацию на основании эксплуатационной документации",
        ],
      },
      {
        code: "66425",
        text: "Какое требование при проведении контрольной опрессовки оборудования сетей газораспределения и газопотребления ТЭС указано верно? Выберите два правильных варианта ответа.",
        answers: [
          "Оборудование и газопроводы ГРП должны подвергаться контрольной опрессовке под давлением 0,01 МПа",
          "Результаты контрольной опрессовки должны записываться в наряде-допуске на проведение газоопасных работ",
          "При значениях падения давления, превышающих допустимые нормы, пуск газа и снятие заглушек на газопроводах разрешаются до устранения причин сверхнормативного падения давления под наблюдением лица, ответственного за производство работ",
          "Подземные и надземные (наружные) газопроводы независимо от расчетного давления подлежат контрольной опрессовке под давлением 0,03 МПа",
        ],
        correctAnswers: [
          "Оборудование и газопроводы ГРП должны подвергаться контрольной опрессовке под давлением 0,01 МПа",
          "Результаты контрольной опрессовки должны записываться в наряде-допуске на проведение газоопасных работ",
        ],
      },
      {
        code: "66426",
        text: "Что из перечисленного должно подвергаться внешнему осмотру перед началом смены? Выберите два правильных варианта ответа.",
        answers: [
          "Технологическое оборудование, трубопроводная арматура, электрооборудование, средства защиты, технологические трубопроводы",
          "Вентиляционные системы",
          "Системы оповещения о пожаре",
          "Средства сигнализации",
        ],
        correctAnswers: [
          "Технологическое оборудование, трубопроводная арматура, электрооборудование, средства защиты, технологические трубопроводы",
          "Вентиляционные системы",
        ],
      },
      {
        code: "66427",
        text: "Какое из перечисленных требований к выводу из работы технологических защит, блокировок и сигнализации на работающем оборудовании сетей газораспределения и газопотребления ТЭС указано верно?",
        answers: [
          "Вывод из работы технологических защит, а также технологических блокировок и сигнализации на работающем оборудовании разрешается только в дневное время и не более одной защиты, блокировки или сигнализации одновременно при работе оборудования в переходных режимах, когда необходимость отключения защиты определена инструкцией по эксплуатации основного оборудования",
          "Отключение должно выполняться по заранее составленному плану отключения технического руководителя ТЭС",
          "Вывод из работы технологических защит, обеспечивающих взрывобезопасность, на работающем оборудовании разрешается только в дневное время в присутствии оперативного руководителя",
          "Допускается производство ремонтных и наладочных работ в цепях включенных защит в случае ликвидации аварийной ситуации",
        ],
        correctAnswers: [
          "Вывод из работы технологических защит, а также технологических блокировок и сигнализации на работающем оборудовании разрешается только в дневное время и не более одной защиты, блокировки или сигнализации одновременно при работе оборудования в переходных режимах, когда необходимость отключения защиты определена инструкцией по эксплуатации основного оборудования",
        ],
      },
      {
        code: "66428",
        text: "С какой периодичностью должен проводиться текущий ремонт на внутренних газопроводах ГТУ и парогазовых установок (далее - ПГУ)?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в три месяца",
          "Не реже одного раза в шесть месяцев",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "66429",
        text: "Кто должен руководить пуском ГТУ после ремонта или проведения регламентных работ?",
        answers: [
          "Начальник цеха или его заместитель",
          "Оперативный руководитель работ",
          "Специалист по промышленной безопасности",
          "Начальник смены",
        ],
        correctAnswers: ["Начальник цеха или его заместитель"],
      },
      {
        code: "66430",
        text: "В каком случае дожимающие компрессоры сетей газопотребления ГТУ и ПГУ подлежат аварийной остановке?",
        answers: [
          "Во всех перечисленных случаях",
          "В случае неисправности механических передач и приводов",
          "В случае вибрации, посторонних шумов",
          "В случае изменения допустимых параметров масла и воды",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "66431",
        text: "Кем должны выполняться испытания на прочность и герметичность газопроводов ТЭС после окончания выполнения работ по техническому обслуживанию?",
        answers: [
          "Работниками, выполнившими ремонтные работы",
          "Специализированной организацией",
          "Работниками, эксплуатирующими оборудование",
          "Специалистами внутреннего инспекционного контроля",
        ],
        correctAnswers: ["Работниками, выполнившими ремонтные работы"],
      },
      {
        code: "66432",
        text: "При каком минимальном содержании кислорода по объему розжиг горелок не допускается?",
        answers: ["1%", "5%", "7%", "10%"],
        correctAnswers: ["1%"],
      },
      {
        code: "66433",
        text: "С какой периодичностью должна производиться проверка срабатывания устройств защиты, блокировок и сигнализации сетей газораспределения и газопотребления ТЭС?",
        answers: [
          "Не реже одного раза в шесть месяцев",
          "Не реже одного раза в год",
          "Не реже одного раза в четыре месяца",
          "Не реже одного раза в месяц",
        ],
        correctAnswers: ["Не реже одного раза в шесть месяцев"],
      },
      {
        code: "66434",
        text: "Кем должна осуществляться эксплуатация сетей газораспределения и газопотребления ТЭС?",
        answers: [
          "Оперативным персоналом и газовой службой организации",
          "Специализированной организацией по договору, оформленному в соответствии с гражданским законодательством",
          "Специальной комиссией, назначенной приказом руководителя организации",
          "Диспетчерским персоналом",
        ],
        correctAnswers: [
          "Оперативным персоналом и газовой службой организации",
        ],
      },
      {
        code: "66435",
        text: "В какие сроки проводится текущий ремонт с разборкой регуляторов давления, предохранительных клапанов и фильтров сетей газораспределения и газопотребления ТЭС?",
        answers: [
          "В сроки, установленные в эксплуатационной документации, но не реже одного раза в двенадцать месяцев, если иное не установлено эксплуатационной документацией организации-изготовителя",
          "В сроки, установленные в эксплуатационной документации, но не реже одного раза в четыре месяца",
          "В сроки, установленные в эксплуатационной документации, но не реже одного раза в шесть месяцев, если иное не установлено эксплуатационной документацией организации-изготовителя",
          "В сроки, установленные в эксплуатационной документации, но не реже одного раза в два года",
        ],
        correctAnswers: [
          "В сроки, установленные в эксплуатационной документации, но не реже одного раза в двенадцать месяцев, если иное не установлено эксплуатационной документацией организации-изготовителя",
        ],
      },
      {
        code: "66436",
        text: "Когда необходимо выполнять капитальный ремонт при эксплуатации пункта подготовки газа сетей газопотребления ГТУ и ПГУ?",
        answers: [
          "При замене оборудования, средств измерений, ремонте здания, систем отопления, вентиляции, освещения, на основании дефектных ведомостей",
          "В сроки, указанные в годовом плане обслуживания",
          "Не реже одного раза в пять лет",
          "По итогам проведения комиссионного обследования пункта подготовки газа",
        ],
        correctAnswers: [
          "При замене оборудования, средств измерений, ремонте здания, систем отопления, вентиляции, освещения, на основании дефектных ведомостей",
        ],
      },
      {
        code: "66437",
        text: "В каком случае пуск газовой турбины осуществляется из холодного состояния? Выберите два правильных варианта ответа.",
        answers: [
          "При температуре металла корпуса турбины менее 150 °C",
          "После монтажа или ремонта",
          "После превышения максимальных значений температуры, указанных изготовителем турбины",
          "При проведении тестового запуска во время технического обслуживания",
        ],
        correctAnswers: [
          "При температуре металла корпуса турбины менее 150 °C",
          "После монтажа или ремонта",
        ],
      },
      {
        code: "66438",
        text: "В каком случае проводится проверка настройки и действия предохранительных устройств газоиспользующего оборудования ТЭС?",
        answers: [
          "Перед пуском газа",
          "После длительного (более одного месяца) перерыва в работе оборудования",
          "При эксплуатации в сроки не реже одного раза в три месяца",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Перед пуском газа"],
      },
      {
        code: "66439",
        text: "При какой минимальной концентрации газа в воздухе рабочих зон помещений ТЭС до начала и в процессе выполнения работ по техническому обслуживанию работы должны быть приостановлены?",
        answers: ["10% НКПРП", "5% НКПРП", "15% НКПРП", "20% НКПРП"],
        correctAnswers: ["10% НКПРП"],
      },
      {
        code: "66440",
        text: "Какие требования должны выполняться перед вскрытием турбин, камеры сгорания, стопорного и регулирующего клапана (далее - РК) сетей газопотребления ГТУ и ПГУ?",
        answers: [
          "Все перечисленные требования",
          "Запорные устройства на подводе газа к газовой турбине должны быть закрыты",
          "На газопроводах должны быть установлены заглушки",
          "Газопроводы должны быть освобождены от газа, арматура на продувочных газопроводах должна быть открыта",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "66441",
        text: "При каком условии должны быть провентилированы с включением всех дымососов, дутьевых вентиляторов и дымососов рециркуляции топка, газоходы отвода продуктов сгорания котла, системы рециркуляции, а также закрытые объемы, в которых размещены коллекторы, перед растопкой котла и после его остановки?",
        answers: [
          "В течение не менее 10 минут при открытых шиберах (клапанах) газовоздушного тракта и расходе воздуха не менее 25% от номинального",
          "В течение не менее 15 минут при открытых шиберах (клапанах) газовоздушного тракта и расходе воздуха не менее 10% от номинального",
          "В течение не менее 20 минут при открытых шиберах (клапанах) газовоздушного тракта и расходе воздуха не менее 20% от номинального",
        ],
        correctAnswers: [
          "В течение не менее 10 минут при открытых шиберах (клапанах) газовоздушного тракта и расходе воздуха не менее 25% от номинального",
        ],
      },
      {
        code: "66442",
        text: "Какое требование должно выполняться при техническом обслуживании и ремонте газоиспользующего оборудования?",
        answers: [
          "Газоиспользующее оборудование должно быть отключено от газопроводов с помощью запорной арматуры, на которой вывешиваются предупреждающие плакаты",
          "Газоиспользующее оборудование должно быть отключено от газопроводов с помощью заглушек",
          "Питание газоиспользующего оборудования должно быть постепенно снижено, газопроводы продуты инертным газом",
        ],
        correctAnswers: [
          "Газоиспользующее оборудование должно быть отключено от газопроводов с помощью заглушек",
        ],
      },
      {
        code: "66443",
        text: "Какие виды работ необходимо выполнять при текущем ремонте запорной арматуры сетей газораспределения и газопотребления ТЭС?",
        answers: [
          "Разборку запорной арматуры, не обеспечивающей плотность закрытия затворов, с притиркой уплотняющих поверхностей",
          "Проверку затяжки (крепежа) фланцевых соединений, смену износившихся и поврежденных болтов и прокладок",
          "Очистку запорной арматуры, ремонт привода и его смазку, набивку сальника, проверку исправности и ремонт приводного устройства",
          "Все перечисленные виды работ",
        ],
        correctAnswers: ["Все перечисленные виды работ"],
      },
      {
        code: "66444",
        text: "Какие охранные зоны установлены Правилами охраны газораспределительных сетей вокруг отдельно стоящих газорегуляторных пунктов?",
        answers: [
          "В виде территории, ограниченной замкнутой линией, проведенной на расстоянии 25 м от границ этих объектов",
          "В виде территории, ограниченной замкнутой линией, проведенной на расстоянии 10 м от границ этих объектов",
          "В виде территории, ограниченной замкнутой линией, проведенной на расстоянии 20 м от границ этих объектов",
          "В виде территории, ограниченной замкнутой линией, проведенной на расстоянии 15 м от границ этих объектов",
        ],
        correctAnswers: [
          "В виде территории, ограниченной замкнутой линией, проведенной на расстоянии 10 м от границ этих объектов",
        ],
      },
      {
        code: "66445",
        text: "Какие охранные зоны установлены Правилами охраны газораспределительных сетей вдоль трасс подземных стальных газопроводов?",
        answers: [
          "В виде территории, ограниченной условными линиями, проходящими на расстоянии 2 м с каждой стороны газопровода",
          "В виде территории, ограниченной условными линиями, проходящими на расстоянии 1 м с каждой стороны газопровода",
          "В виде территории, ограниченной условными линиями, проходящими на расстоянии 3 м с каждой стороны газопровода",
          "В виде территории, ограниченной условными линиями, проходящими на расстоянии 1,5 м с каждой стороны газопровода",
        ],
        correctAnswers: [
          "В виде территории, ограниченной условными линиями, проходящими на расстоянии 2 м с каждой стороны газопровода",
        ],
      },
      {
        code: "66446",
        text: "На каком расстоянии от оси газопроводов устанавливаются навигационные знаки в местах пересечения газопроводов с судоходными и сплавными реками и каналами на обоих берегах?",
        answers: ["60 м", "100 м", "50 м", "80 м"],
        correctAnswers: ["100 м"],
      },
      {
        code: "66447",
        text: "При каком условии производятся работы, связанные с обработкой почвы на глубину менее 0,3 м, собственниками, владельцами или пользователями земельных участков в охранной зоне газораспределительной сети?",
        answers: [
          "При условии предварительного письменного уведомления эксплуатационной организации не менее чем за 3 рабочих дня до начала работ",
          "При условии письменного разрешения Ростехнадзора",
          "При условии письменного разрешения эксплуатационной организации газораспределительных сетей",
          "При условии предварительного письменного уведомления эксплуатационной организации не менее чем за 5 рабочих дня до начала работ",
        ],
        correctAnswers: [
          "При условии предварительного письменного уведомления эксплуатационной организации не менее чем за 3 рабочих дня до начала работ",
        ],
      },
      {
        code: "66448",
        text: "Какие охранные зоны установлены Правилами охраны газораспределительных сетей вдоль трасс подземных газопроводов из полиэтиленовых труб, для обозначения трасс которых используется медный провод?",
        answers: [
          "В виде территории, ограниченной условными линиями, проходящими на расстоянии 5 м от газопровода со стороны провода и 3 м - с противоположной стороны",
          "В виде территории, ограниченной условными линиями, проходящими на расстоянии 3 м от газопровода со стороны провода и 2 м - с противоположной стороны",
          "В виде территории, ограниченной условными линиями, проходящими на расстоянии 2 м от газопровода со стороны провода и 1 м - с противоположной стороны",
          "В виде территории, ограниченной условными линиями, проходящими на расстоянии 2 м от газопровода со стороны провода и 0,5 м - с противоположной стороны",
        ],
        correctAnswers: [
          "В виде территории, ограниченной условными линиями, проходящими на расстоянии 3 м от газопровода со стороны провода и 2 м - с противоположной стороны",
        ],
      },
      {
        code: "66449",
        text: "Какие из перечисленных действий разрешается производить на земельных участках, входящих в охранные зоны газораспределительных сетей?",
        answers: [
          "Сносить и реконструировать мосты, коллекторы, автомобильные и железные дороги с расположенными на них газораспределительными сетями с предварительным выносом этих газопроводов по согласованию с эксплуатационными организациями",
          "Перемещать, повреждать, засыпать и уничтожать опознавательные знаки, контрольно-измерительные пункты и другие устройства газораспределительных сетей",
          "Строить объекты жилищно-гражданского и производственного назначения по согласованию с эксплуатационными организациями",
          "Открывать калитки и двери газорегуляторных пунктов, станций катодной и дренажной защиты, люки подземных колодцев, включать или отключать электроснабжение средств связи, освещения и систем телемеханики",
        ],
        correctAnswers: [
          "Сносить и реконструировать мосты, коллекторы, автомобильные и железные дороги с расположенными на них газораспределительными сетями с предварительным выносом этих газопроводов по согласованию с эксплуатационными организациями",
        ],
      },
      {
        code: "66450",
        text: "После какого простоя должна проводиться проверка срабатывания предохранительного запорного клапана (далее - ПЗК) котлов и горелок ТЭС перед растопкой котла на газе?",
        answers: [
          "При простое более 10 суток",
          "При простое более 5 суток",
          "При простое более 3 суток",
          "При простое более 7 суток",
        ],
        correctAnswers: ["При простое более 3 суток"],
      },
      {
        code: "66451",
        text: "Какое из перечисленных требований при техническом обслуживании сетей газораспределения и газопотребления ТЭС указано верно? Выберите 2 варианта ответа.",
        answers: [
          "Должно проводиться только в светлое время суток или при достаточном искусственном освещении",
          "Должно проводиться бригадой в составе не менее 3 человек под руководством мастера с оформлением наряда-допуска на производство газоопасных работ",
          "Должно проводиться под руководством технического руководителя организации",
          "Должно проводиться с оформлением проекта производства работ",
        ],
        correctAnswers: [
          "Должно проводиться только в светлое время суток или при достаточном искусственном освещении",
          "Должно проводиться бригадой в составе не менее 3 человек под руководством мастера с оформлением наряда-допуска на производство газоопасных работ",
        ],
      },
      {
        code: "66452",
        text: "Где указываются сроки эксплуатации газопроводов, технических и технологических устройств сетей газораспределения и газопотребления ТЭС?",
        answers: [
          "В паспорте объекта",
          "На газопроводе",
          "В эксплутационной документации",
          "В проектной документации",
        ],
        correctAnswers: ["В проектной документации"],
      },
      {
        code: "66453",
        text: "В каком случае допускается избыточное давление газа в газопроводах котла ТЭС при работе на другом топливе?",
        answers: [
          "При условии обеспечения плотности закрытия отключающих устройств перед горелками котла",
          "Если котел находится в резерве или работает на другом виде топлива",
          "При повторной растопке котла",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "При условии обеспечения плотности закрытия отключающих устройств перед горелками котла",
        ],
      },
      {
        code: "66454",
        text: "С какой периодичностью должен проводиться контроль загазованности в помещениях пункта подготовки газа (далее - ППГ) переносным прибором из верхней зоны помещений?",
        answers: [
          "Не реже 1 раза в смену",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в сутки",
          "Не реже 1 раза в неделю",
        ],
        correctAnswers: ["Не реже 1 раза в сутки"],
      },
      {
        code: "66455",
        text: "При обнаружении какой концентрации газа в помещениях ППГ необходимо организовать дополнительную вентиляцию помещения, выявить причину и незамедлительно устранить утечку газа?",
        answers: [
          "10% и более НКРП",
          "15% и более НКРП",
          "20% и более НКРП",
          "25% и более НКРП",
        ],
        correctAnswers: ["10% и более НКРП"],
      },
      {
        code: "66456",
        text: "С какой периодичностью должно проводиться техническое обслуживание газопроводов и технических устройств ППГ?",
        answers: [
          "Не реже 1 раза в 18 месяцев",
          "Не реже 1 раза в год",
          "Не реже 1 раза в 10 месяцев",
          "Не реже 1 раза в 6 месяцев",
        ],
        correctAnswers: ["Не реже 1 раза в 6 месяцев"],
      },
      {
        code: "66457",
        text: "С какой периодичностью должны осматриваться технологическое оборудование, газопроводы, арматура, электрооборудование, вентиляционные системы, средства измерений, противоаварийные защиты, блокировки и сигнализации в производственной зоне ППГ?",
        answers: ["Ежесуточно", "Ежесменно", "Ежемесячно", "Еженедельно"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "66458",
        text: "В соответствии с чем устанавливается периодичность ремонтов оборудования ГТУ и ПГУ?",
        answers: [
          "В соответствии с технической документацией",
          "В соответствии с графиками, утвержденными техническим руководителем ТЭС",
          "В соответствии с техническим заданием",
          "В соответствии с эксплатуционной документацией",
        ],
        correctAnswers: [
          "В соответствии с графиками, утвержденными техническим руководителем ТЭС",
        ],
      },
      {
        code: "66459",
        text: "В каких пределах допускаются колебания давления газа на выходе при настройке регуляторов в ППГ?",
        answers: [
          "В пределах 10% от рабочего давления",
          "В пределах 15% от рабочего давления",
          "В пределах 20% от рабочего давления",
          "В пределах 25% от рабочего давления",
        ],
        correctAnswers: ["В пределах 10% от рабочего давления"],
      },
      {
        code: "66460",
        text: "В каком из перечисленных случаев должна проводиться проверка герметичности затвора стопорного клапана, ПЗК ГТ?",
        answers: [
          "Только перед каждым пуском ГТУ",
          "Только после ремонта с визуальным контролем",
          "Только периодически - не реже 1 раза в месяц",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "66461",
        text: "В каких из перечисленных случаях допускается пуск ГТУ?",
        answers: [
          "После устранения причин отказов после аварийной остановки или сбоя при предыдущем пуске",
          "При температуре масла ниже установленного предела",
          "При давлении топлива выше установленного предела",
          "При отключении хотя бы одной из систем аварийной защиты",
        ],
        correctAnswers: [
          "После устранения причин отказов после аварийной остановки или сбоя при предыдущем пуске",
        ],
      },
      {
        code: "66462",
        text: "По решению кого из должностных лиц должна быть разгружена и остановлена ГТУ в случаях обледенения воздухозаборного устройства, если не удается устранить обледенение при работе ГТУ под нагрузкой?",
        answers: [
          "Руководителя ТЭС",
          "Технического руководителя ТЭС",
          "Руководителя структурного подразделения ТЭС",
          "Оперативного дежурного ТЭС",
        ],
        correctAnswers: ["Технического руководителя ТЭС"],
      },
      {
        code: "66463",
        text: "Какие из перечисленных действий необходимо произвести при аварийной остановке ГТУ или ПГУ с КУ?",
        answers: [
          "Прекратить подачу топлива в камеру сгорания ГТ",
          "Закрыть продувочные газопроводы и трубопроводы безопасности на отключенных газопроводах ГТ и КУ",
          "Включить паровую турбину и генератор, предусмотренные в составе ПГУ",
          "Открыть стопорный клапан, ПЗК и другие запорные устройства на газопроводах ГТ и КУ",
        ],
        correctAnswers: ["Прекратить подачу топлива в камеру сгорания ГТ"],
      },
      {
        code: "66464",
        text: "Какое из перечисленных требований при выводе из работы технологических защит ГТУ и ПГУ указано неверно?",
        answers: [
          "Запорная арматура на продувочных газопроводах и газопроводах безопасности после отключения ГТУ должна постоянно находиться в открытом положении",
          "Вывод из работы технологических защит, на работающем оборудовании запрещается",
          "Вывод из работы технологических защит, обеспечивающих взрывобезопасность, должен производиться на работающем оборудовании",
        ],
        correctAnswers: [
          "Вывод из работы технологических защит, обеспечивающих взрывобезопасность, должен производиться на работающем оборудовании",
        ],
      },
      {
        code: "66465",
        text: "По указанию какого лица производится снятие заглушек, установленных на ответвлениях к потребителям (вводах), после визуального осмотра и опрессовки газопровода?",
        answers: [
          "Технического руководителя эксплуатирующей организации",
          "Руководителя работами по пуску газа",
          "Руководителя пусконаладочной организации",
          "Руководителя потребителя",
        ],
        correctAnswers: ["Руководителя работами по пуску газа"],
      },
      {
        code: "66466",
        text: "Какая информация должна содержаться на опознавательных знаках для обнаружения трасс подземных газопроводов?",
        answers: [
          "Только расстояние от газопровода",
          "Только глубина заложения газопровода",
          "Только телефон аварийно-диспетчерской службы",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "66467",
        text: "Каков количественный состав бригады работников, выполняющих газоопасные работы в колодцах, туннелях и коллекторах?",
        answers: [
          "Газоопасные работы выполняются бригадой в составе не менее 4 работников под руководством специалиста",
          "Газоопасные работы выполняются бригадой в составе не менее 3 работников. Руководство поручается работнику, имеющему наибольший опыт",
          "Газоопасные работы выполняются бригадой в составе не менее 3 работников под руководством инженерно-технического работника (в составе не менее четырех работников)",
          "Газоопасные работы выполняются бригадой в составе не менее 4 работников. Руководство поручается наиболее опытному рабочему",
        ],
        correctAnswers: [
          "Газоопасные работы выполняются бригадой в составе не менее 3 работников под руководством инженерно-технического работника (в составе не менее четырех работников)",
        ],
      },
      {
        code: "66468",
        text: "Какая из перечисленных газоопасных работ может выполняться бригадой из двух рабочих, руководство которой поручается наиболее квалифицированному рабочему?",
        answers: [
          "Проведение работ в траншеях и туннелях",
          "Установка и снятие заглушек на действующих газопроводах",
          "Проверка и откачка конденсата из конденсатосборников",
          "Проведение сварочных работ на ремонтируемых участках",
        ],
        correctAnswers: [
          "Проверка и откачка конденсата из конденсатосборников",
        ],
      },
      {
        code: "66469",
        text: "Какой документ выдается на производство газоопасных работ?",
        answers: ["Талон", "Поручение", "Распоряжение", "Наряд-допуск"],
        correctAnswers: ["Наряд-допуск"],
      },
      {
        code: "66470",
        text: "Кому предоставляется право выдачи нарядов-допусков на производство газоопасных работ?",
        answers: [
          "Руководителю организации",
          "Специалисту по промышленной безопасности",
          "Оперативным руководителям подразделений, чьи работники производят газоопасные работы",
          "Работникам, назначенным распорядительным документом по организации, из числа руководящих работников и инженерно-технических работников, осуществляющих эксплуатацию сетей газораспределения или газопотребления, аттестованных в установленном порядке и имеющих опыт работы на объектах сетей газораспределения и газопотребления не менее одного года",
        ],
        correctAnswers: [
          "Работникам, назначенным распорядительным документом по организации, из числа руководящих работников и инженерно-технических работников, осуществляющих эксплуатацию сетей газораспределения или газопотребления, аттестованных в установленном порядке и имеющих опыт работы на объектах сетей газораспределения и газопотребления не менее одного года",
        ],
      },
      {
        code: "66471",
        text: "Каким документом по газораспределительной организации или организации, имеющей собственную газовую службу, назначаются работники, имеющие право выдачи нарядов-допусков к выполнению газоопасных работ?",
        answers: [
          "Распоряжением технического руководителя",
          "Распоряжением начальника территориального отдела Ростехнадзора",
          "Распорядительным документом по организации",
        ],
        correctAnswers: ["Распорядительным документом по организации"],
      },
      {
        code: "66472",
        text: "Какие газоопасные работы могут выполняться без оформления наряда-допуска по утвержденным производственным инструкциям?",
        answers: [
          "Замена измерительных приборов на штуцерах трубопровода",
          "Периодически повторяющиеся газоопасные работы, выполняемые постоянным составом бригады",
          "Осмотр колодцев со спуском в них",
          "Установка запорной арматуры",
        ],
        correctAnswers: [
          "Периодически повторяющиеся газоопасные работы, выполняемые постоянным составом бригады",
        ],
      },
      {
        code: "66473",
        text: "Какая из перечисленных газоопасных работ может выполняться без оформления наряда-допуска по утвержденной производственной инструкции?",
        answers: [
          "Установка и снятие заглушек без снижения давления",
          "Ремонтные работы на внешнем газопроводе с применением сварки",
          "Разрытия в местах утечек газа до их устранения",
          "Техническое обслуживание запорной арматуры, расположенной вне колодцев",
        ],
        correctAnswers: [
          "Техническое обслуживание запорной арматуры, расположенной вне колодцев",
        ],
      },
      {
        code: "66474",
        text: "Какая из приведенных газоопасных работ выполняется по специальному плану, утвержденному техническим руководителем газораспределительной организации?",
        answers: [
          "Ремонтные работы в ПРГ и ГРУ с применением сварки и газовой резки",
          "Технический осмотр газопроводов и ПРГ",
          "Контроль давления газа в сети газораспределения и газопотребления",
          "Проверка состояния охранных зон газопроводов",
        ],
        correctAnswers: [
          "Ремонтные работы в ПРГ и ГРУ с применением сварки и газовой резки",
        ],
      },
      {
        code: "66475",
        text: "Какая из приведенных газоопасных работ выполняется по специальному плану, утвержденному техническим руководителем газораспределительной организации?",
        answers: [
          "Техническое обслуживание ПРГ (шкафного исполнения), осуществляемое без снижения давления газа у потребителей",
          "Отключение и последующее включение подачи газа на промышленные производства",
          "Техническое обслуживание газопроводов без отключения газа",
          "Техническое обслуживание запорной арматуры и компенсаторов, расположенных вне колодцев (без нарушения герметичности корпуса и фланцевых соединений)",
        ],
        correctAnswers: [
          "Отключение и последующее включение подачи газа на промышленные производства",
        ],
      },
      {
        code: "66476",
        text: "В течение какого времени должны храниться наряды-допуски на производство газоопасных работ?",
        answers: [
          "Все наряды-допуски хранятся в течение 75 лет в исполнительно-технической документации",
          "Наряды-допуски на производство газоопасных работ хранятся в течение 5 лет",
          "Время хранения нарядов-допусков на производство газоопасных работ определяется руководителем организации",
          "Наряд-допуск на производство газоопасных работ (за исключением нарядов-допусков, выдаваемых на первичный пуск газа, врезку в действующий газопровод, отключение газопровода с заваркой наглухо в местах ответвлений) должен храниться не менее одного года с даты его закрытия",
        ],
        correctAnswers: [
          "Наряд-допуск на производство газоопасных работ (за исключением нарядов-допусков, выдаваемых на первичный пуск газа, врезку в действующий газопровод, отключение газопровода с заваркой наглухо в местах ответвлений) должен храниться не менее одного года с даты его закрытия",
        ],
      },
      {
        code: "66477",
        text: "Кем должны выдаваться распоряжения при проведении газоопасной работы?",
        answers: [
          "Всеми инженерно-техническими работниками, участвующими в проведении работ",
          "Только техническим директором",
          "Только лицом, ответственным за работу",
          "Только лицом, выдавшим наряд-допуск",
        ],
        correctAnswers: ["Только лицом, ответственным за работу"],
      },
      {
        code: "66478",
        text: "В какое время суток должны проводиться газоопасные работы?",
        answers: [
          "Газоопасные работы выполняются в любое время суток",
          "Газоопасные работы выполняются в любое время суток и только по наряду-допуску",
          "Газоопасные работы выполняются в любое время суток по письменному распоряжению технического руководителя",
          "Газоопасные работы должны выполняться в дневное время (дневную рабочую смену)",
        ],
        correctAnswers: [
          "Газоопасные работы должны выполняться в дневное время (дневную рабочую смену)",
        ],
      },
      {
        code: "66479",
        text: "Норма контрольной опрессовки внутренних газопроводов промышленных, сельскохозяйственных и других производств, котельных, оборудования и газопроводов газорегуляторных пунктов (далее - ГРП), блочных газорегуляторных пунктов (далее - ГРПБ), шкафных регуляторных пунктов (далее - ШРП), газорегуляторных установок (далее - ГРУ):",
        answers: [
          "Величина давления воздуха (инертного газа) при опрессовке 0,01 МПа, падение давления не должно превышать 0,0006 МПа за 1 час",
          "Величина давления воздуха (инертного газа) при опрессовке 0,02 МПа, падение давления не должно превышать 0,0001 МПа за 1 час",
          "Величина давления воздуха (инертного газа) при опрессовке 0,06 МПа, падение давления не должно превышать 0,0006 МПа за 1 час",
          "Величина давления воздуха (инертного газа) при опрессовке 0,01 МПа, падение давления не должно превышать 0,0001 МПа за 1 час",
        ],
        correctAnswers: [
          "Величина давления воздуха (инертного газа) при опрессовке 0,01 МПа, падение давления не должно превышать 0,0006 МПа за 1 час",
        ],
      },
      {
        code: "66480",
        text: "Норма контрольной опрессовки наружных газопроводов всех давлений:",
        answers: [
          "Величина давления воздуха (инертного газа) при опрессовке 0,01 МПа, падение давления не должно превышать 0,0006 МПа за 1 час",
          "Величина давления воздуха (инертного газа) при опрессовке 0,02 МПа, падение давления не должно превышать 0,0001 МПа за 1 час",
          "Величина давления воздуха (инертного газа) при опрессовке 0,02 МПа, падение давления не должно превышать 0,0006 МПа за 1 час",
          "Величина давления воздуха (инертного газа) при опрессовке 0,01 МПа, падение давления не должно превышать 0,0001 МПа за 1 час",
        ],
        correctAnswers: [
          "Величина давления воздуха (инертного газа) при опрессовке 0,02 МПа, падение давления не должно превышать 0,0001 МПа за 1 час",
        ],
      },
      {
        code: "66481",
        text: "Какое из приведенных требований должно выполняться при ремонтных работах в загазованной среде?",
        answers: [
          "Применение инструмента с рабочей частью, выполненной из цветного металла, не допускается",
          "Электрический инструмент, дающий искрение, возможно применять при наличии защитных устройств",
          "Обувь у лиц, выполняющих газоопасные работы в колодцах, помещениях ПРГ и ГРУ, не должна иметь стальных подковок и гвоздей",
          "При выполнении газоопасных работ следует использовать переносные светильники во взрывозащищенном исполнении с напряжением до 50 В",
        ],
        correctAnswers: [
          "Обувь у лиц, выполняющих газоопасные работы в колодцах, помещениях ПРГ и ГРУ, не должна иметь стальных подковок и гвоздей",
        ],
      },
      {
        code: "66482",
        text: "Какой инструмент следует применять при ремонтных работах в загазованной среде?",
        answers: [
          "Специальных требований к инструменту не предусмотрено",
          "Следует применять инструмент из цветного металла, исключающий искрообразование. При применении инструментов из черного металла их рабочая часть должна обильно смазываться солидолом или другой аналогичной смазкой. Применение электрических инструментов, дающих искрение, не допускается",
          "Электрический инструмент, дающий искрение, следует применять при наличии защитных устройств",
        ],
        correctAnswers: [
          "Следует применять инструмент из цветного металла, исключающий искрообразование. При применении инструментов из черного металла их рабочая часть должна обильно смазываться солидолом или другой аналогичной смазкой. Применение электрических инструментов, дающих искрение, не допускается",
        ],
      },
      {
        code: "66483",
        text: "Какое из приведенных требований должно выполняться при выполнении сварочных работ и газовой резки на газопроводах в колодцах, туннелях, коллекторах?",
        answers: [
          "Перед выполнением сварочных работ газопроводы необходимо отключить и продуть углекислым газом",
          "До начала работ по сварке (резке) газопровода, а также замене арматуры, компенсаторов и изолирующих фланцев в колодцах, туннелях, коллекторах следует снять (демонтировать) перекрытия",
          "При проведении сварочных работ снятие перекрытий не обязательно, если они не мешают проведению работ",
          "Установка заглушек на газопроводе при герметичном закрытии арматуры не обязательна",
        ],
        correctAnswers: [
          "До начала работ по сварке (резке) газопровода, а также замене арматуры, компенсаторов и изолирующих фланцев в колодцах, туннелях, коллекторах следует снять (демонтировать) перекрытия",
        ],
      },
      {
        code: "66484",
        text: "Какие меры необходимо предпринимать, если при проведении газовой резки (сварки) на действующем газопроводе произошло снижение или превышение давления газа сверх установленных пределов: ниже 0,0004 МПа или выше 0,002 МПа?",
        answers: [
          "Необходимо сделать запись в наряде-допуске, принять дополнительные меры безопасности и продолжить работу",
          "При снижении или превышении давления газа сверх установленных пределов работы проводятся с разрешения работника, выдавшего наряд-допуск",
          "Работы могут быть продолжены только в случае устранения аварийной ситуации",
          "Работы следует прекратить",
        ],
        correctAnswers: ["Работы следует прекратить"],
      },
      {
        code: "66485",
        text: "Где должен быть установлен манометр для контроля давления в газопроводе при проведении газовой резки и сварки?",
        answers: [
          "Не далее 20 м от места проведения работ",
          "Не далее 30 м от места проведения работ",
          "Не далее 50 м от места проведения работ",
          "Не далее 100 м от места проведения работ",
        ],
        correctAnswers: ["Не далее 100 м от места проведения работ"],
      },
      {
        code: "66486",
        text: "Каким образом должны проводиться работы по присоединению газоиспользующего оборудования к действующим внутренним газопроводам с использованием сварки (резки)?",
        answers: [
          "Допускается проводить работы после отключения газопроводов без продувки",
          "Газопроводы должны быть отключены с продувкой их воздухом или инертным газом",
          "Допускается проводить работы без отключения газопроводов при обязательном присутствии лица, ответственного за безопасную эксплуатацию сетей газопотребления",
          "Газопроводы должны быть отключены. Продувку воздухом или инертным газом газопроводов низкого давления проводить необязательно при условии проведения непрерывного контроля уровня газа в воздухе рабочей зоны",
        ],
        correctAnswers: [
          "Газопроводы должны быть отключены с продувкой их воздухом или инертным газом",
        ],
      },
      {
        code: "66487",
        text: "Какие меры необходимо предпринять во избежание превышения давления газа в газопроводе при проведении газовой сварки или резки на действующем наружном газопроводе?",
        answers: [
          "Избыточное давление следует сбрасывать в вентиляционную систему",
          "Избыточное давление следует сбрасывать только в конденсатосборники",
          "Избыточное давление следует сбрасывать через фильтрующую установку",
          "Избыточное давление следует сбрасывать на продувочный газопровод",
        ],
        correctAnswers: [
          "Избыточное давление следует сбрасывать на продувочный газопровод",
        ],
      },
      {
        code: "66488",
        text: "Каким образом определяется окончание продувки газопровода при пуске газа?",
        answers: [
          "Только путем анализа с использованием газоанализаторов",
          "Временем продувки, до отсутствия характерного запаха газа",
          "Путем анализа или сжиганием отобранных проб газа",
        ],
        correctAnswers: ["Путем анализа или сжиганием отобранных проб газа"],
      },
      {
        code: "66489",
        text: "Объемная доля кислорода в газопроводе после окончания продувки не должна превышать:",
        answers: [
          "3% по объему",
          "5% по объему",
          "1% по объему",
          "2% по объему",
        ],
        correctAnswers: ["1% по объему"],
      },
      {
        code: "66490",
        text: "При соблюдении каких требований должна производиться разборка (замена) установленного на наружных и внутренних газопроводах оборудования?",
        answers: [
          "При полном отключении и продувке трубопровода установка заглушек необязательна",
          "Только на отключенном участке газопровода с установкой заглушек",
          "На наружном газопроводе разрешается разборка и замена оборудования без его продувки",
          "На отключенном участке газопровода установка заглушек необязательна, если герметичность закрытия обеспечена запорной арматурой",
        ],
        correctAnswers: [
          "Только на отключенном участке газопровода с установкой заглушек",
        ],
      },
      {
        code: "66491",
        text: "Каким требованиям должны соответствовать заглушки, устанавливаемые на газопроводы природного газа?",
        answers: [
          "Должны соответствовать номинальному давлению газа в газопроводе, иметь хвостовики, выступающие за пределы фланцев, и клеймо с указанием даты установки",
          "Должны соответствовать максимальному давлению газа в газопроводе, иметь соответствующее цветовое обозначение при условии установки на наружном трубопроводе и клеймо с указанием диаметра газопровода",
          "Должны соответствовать рабочему давлению газа в газопроводе, иметь хвостовики и клеймо с указанием давления газа и даты технического обслуживания",
          "Должны соответствовать максимальному давлению газа в газопроводе, иметь хвостовики, выступающие за пределы фланцев, и клеймо с указанием давления газа и диаметра газопровода",
        ],
        correctAnswers: [
          "Должны соответствовать максимальному давлению газа в газопроводе, иметь хвостовики, выступающие за пределы фланцев, и клеймо с указанием давления газа и диаметра газопровода",
        ],
      },
      {
        code: "66492",
        text: "Подтягивание или замена сальниковой набивки запорной арматуры, разборка резьбовых соединений конденсатосборников на наружных газопроводах среднего и высокого давлений допускаются при давлении газа:",
        answers: [
          "Не более 0,01 МПа",
          "Не более 0,1 МПа",
          "Не более 0,02 МПа",
          "Не более 0,03 МПа",
        ],
        correctAnswers: ["Не более 0,1 МПа"],
      },
      {
        code: "66493",
        text: "Замена прокладок фланцевых соединений на наружных газопроводах допускается при давлении газа:",
        answers: [
          "Не более 0,01 МПа",
          "Не более 0,1 МПа",
          "Не более 0,02 МПа",
          "0,0002 - 0,004 МПа",
          "0,0004 - 0,002 МПа",
        ],
        correctAnswers: ["0,0004 - 0,002 МПа"],
      },
      {
        code: "66494",
        text: "Допускается ли замена прокладок фланцевых соединений на внутренних газопроводах под давлением газа?",
        answers: [
          "Да, при давлении газа не более 0,005 МПа",
          "Да, при давлении газа 0,0002 - 0,004 МПа",
          "Нет, не допускается",
          "Да, при давлении газа 0,0004 - 0,002 МПа",
        ],
        correctAnswers: ["Нет, не допускается"],
      },
      {
        code: "66495",
        text: "Допускается ли проведение разборки фланцевых, резьбовых соединений и арматуры на внутренних газопроводах без их отключения?",
        answers: [
          "Допускается только для наружных газопроводов",
          "Допускается при снижении давления газа в наружном газопроводе до величины 0,0004 МПа и постоянном контроле газоанализатором",
          "Разборка фланцевых и резьбовых соединений и арматуры должна производиться на отключенном и заглушенном участке внутреннего газопровода",
          "Разборка фланцевых и резьбовых соединений и арматуры должна производиться только на отключенном участке внутреннего газопровода",
        ],
        correctAnswers: [
          "Разборка фланцевых и резьбовых соединений и арматуры должна производиться на отключенном и заглушенном участке внутреннего газопровода",
        ],
      },
      {
        code: "66496",
        text: "При каком давлении газа в газопроводе разрешается устранение в газопроводах закупорок путем шуровки металлическими шомполами, заливки растворителей или подачи пара?",
        answers: [
          "Не более 0,1 МПа",
          "Не более 0,01 МПа",
          "Не более 0,005 МПа",
          "Не более 300 МПа",
        ],
        correctAnswers: ["Не более 0,005 МПа"],
      },
      {
        code: "66497",
        text: "Каким образом должна проверяться герметичность резьбовых и фланцевых соединений, которые разбирались для устранения закупорок?",
        answers: [
          "Акустическим методом",
          "Манометрическим методом",
          "Мыльной эмульсией или с помощью высокочувствительных газоанализаторов (течеискателей)",
          "Любым из указанных способов",
        ],
        correctAnswers: [
          "Мыльной эмульсией или с помощью высокочувствительных газоанализаторов (течеискателей)",
        ],
      },
      {
        code: "66498",
        text: "На кого возлагается ответственность за наличие у рабочих средств индивидуальной защиты, их исправность и применение?",
        answers: [
          "На руководителя организации",
          "На оперативного руководителя",
          "На специалиста по промышленной безопасности",
          "На лицо, ответственное за проведение работ",
        ],
        correctAnswers: ["На лицо, ответственное за проведение работ"],
      },
      {
        code: "66499",
        text: "Продолжительность работы в кислородно-изолирующем противогазе без перерыва не должна превышать:",
        answers: ["Одного часа", "30 минут", "20 минут", "10 минут"],
        correctAnswers: ["30 минут"],
      },
      {
        code: "66500",
        text: "В герметичности шлангового противогаза перед выполнением работ убеждаются:",
        answers: [
          "Путем проведения тестирования до передачи противогаза в работу",
          "Путем визуальной проверки на предмет наличия трещин на противогазе и трубке",
          "При надетом противогазе путем зажима конца гофрированной трубки",
          "При надетом противогазе путем проверки отсутствия конденсата на стеклах маски",
          "При надетом противогазе путем проверки отсутствия подсосов в маске и перегибов и защемлений гофрированной трубки",
        ],
        correctAnswers: [
          "При надетом противогазе путем зажима конца гофрированной трубки",
        ],
      },
      {
        code: "66501",
        text: "Какое из перечисленных требований должно выполняться при работе в шланговом противогазе?",
        answers: [
          "Для всех шланговых противогазов длина шланга не должна превышать 20 метров",
          "Воздухозаборные патрубки шланговых противогазов должны располагаться с подветренной стороны",
          "Воздухозаборные патрубки шланговых противогазов должны располагаться с наветренной стороны и закрепляться",
          "На шланге противогаза допускаются перегибы, если они не мешают подаче воздуха",
        ],
        correctAnswers: [
          "Воздухозаборные патрубки шланговых противогазов должны располагаться с наветренной стороны и закрепляться",
        ],
      },
      {
        code: "66502",
        text: "С какой периодичностью ответственный за выполнение газоопасных работ обязан докладывать о положении дел лицу, выдавшему наряд-допуск, если данные работы проводятся в течение более одного дня?",
        answers: [
          "Ежедневно",
          "В начале и в конце смены",
          "По требованию лица, выдавшего наряд-допуск на проведение газоопасных работ",
          "После каждого этапа выполнения работ",
        ],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "66503",
        text: "Кем проверяется соответствие исполнительной документации, прилагаемой к плану и нарядам-допускам, фактическому расположению газопровода перед началом газоопасных работ?",
        answers: [
          "Лицом, ответственным за проведение газоопасных работ",
          "Лицом, выдающим наряд-допуск на проведение газоопасных работ",
          "Оперативным руководителем",
          "Специалистом по промышленной безопасности",
        ],
        correctAnswers: [
          "Лицом, ответственным за проведение газоопасных работ",
        ],
      },
      {
        code: "66504",
        text: "Кем продлевается наряд-допуск на проведение газоопасных работ при невозможности окончить выполняемые работы в установленный срок?",
        answers: [
          "Лицом, выдавшим наряд-допуск на проведение газоопасных работ",
          "Специалистом по промышленной безопасности",
          "Начальником смены",
          "Техническим руководителем организации",
        ],
        correctAnswers: [
          "Лицом, выдавшим наряд-допуск на проведение газоопасных работ",
        ],
      },
      {
        code: "66505",
        text: "В каком случае работы по установке и снятию заглушек сетей газораспределения и газопотребления ТЭС должны выполняться в шланговых противогазах?",
        answers: [
          "При ПДК газа в воздухе рабочей зоны, превышающей 300 мг/м³",
          "При ПДК газа в воздухе рабочей зоны, превышающей 200 мг/м³",
          "При ПДК газа в воздухе рабочей зоны, превышающей 100 мг/м³",
          "При любой концентрации газа",
        ],
        correctAnswers: [
          "При ПДК газа в воздухе рабочей зоны, превышающей 300 мг/м³",
        ],
      },
      {
        code: "66506",
        text: "В каких противогазах не допускается проводить газоопасные работы по устранению закупорок в газопроводах?",
        answers: [
          "В фильтрующих противогазах",
          "В шланговых противогазах",
          "В кислородно-изолирующих противогазах",
          "Не регламентируется",
        ],
        correctAnswers: ["В фильтрующих противогазах"],
      },
      {
        code: "66507",
        text: "Какое из перечисленных требований при проведении газоопасных работ на сетях газораспределения и газопотребления указано верно?",
        answers: [
          "Все перечисленные",
          "При газовой резке (сварке) на действующих газопроводах во избежание большого пламени места выхода газа затираются шамотной глиной с асбестовой крошкой",
          'Вблизи мест проведения газоопасных работ вывешиваются или выставляются предупредительные знаки "Огнеопасно - газ"',
          "Котлованы должны иметь размеры, удобные для проведения работ и эвакуации рабочих",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "66508",
        text: "С какой периодичностью должен пересматриваться и переутверждаться перечень газоопасных работ?",
        answers: [
          "Не реже одного раза в год",
          "Не реже одного раза в два года",
          "Не реже одного раза в четыре года",
          "Не реже одного раза в пять лет",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "66509",
        text: "Какую информацию не содержит разрешение на производство работ в охранной зоне газораспределительной сети?",
        answers: [
          "Информацию о характере опасных производственных факторов",
          "Информацию о расположении трассы газопровода",
          "Информацию о наличии и содержании инструкций, которыми необходимо руководствоваться при выполнении конкретных видов работ",
          "Информацию из расчетно-проектной документации",
        ],
        correctAnswers: ["Информацию из расчетно-проектной документации"],
      },
      {
        code: "66510",
        text: "За какое время до начала работ в охранной зоне газораспределительной сети лица, имеющие намерение производить данные работы, обязаны пригласить представителя эксплуатационной организации газораспределительной сети на место производства работ?",
        answers: [
          "Не менее чем за 14 рабочих дней до начала работ",
          "Не менее чем за 10 рабочих дней до начала работ",
          "Не менее чем за 5 рабочих дней до начала работ",
          "Не менее чем за 3 рабочих дня до начала работ",
        ],
        correctAnswers: ["Не менее чем за 3 рабочих дня до начала работ"],
      },
      {
        code: "66511",
        text: "Кто немедленно извещается о происшедшем повреждении газораспределительной сети или обнаружении утечки газа при выполнении работ в охранной зоне?",
        answers: [
          "Аварийно-диспетчерская служба эксплуатационной организации газораспределительной сети",
          "Лицо, ответственное за проведение работ в охранной зоне газораспределительной сети",
          "Технический руководитель эксплуатирующей организации",
          "Руководитель эксплуатирующей организации",
        ],
        correctAnswers: [
          "Аварийно-диспетчерская служба эксплуатационной организации газораспределительной сети",
        ],
      },
      {
        code: "66512",
        text: "При какой объемной доле газа в воздухе выполнение газоопасных работ в помещениях ПРГ, колодцах, туннелях, коллекторах не допускается?",
        answers: [
          "Более 20 % от нижнего концентрационного предела распространения пламени",
          "Более 30 % от нижнего концентрационного предела распространения пламени",
          "Более 25 % от нижнего концентрационного предела распространения пламени",
          "Более 35 % от нижнего концентрационного предела распространения пламени",
        ],
        correctAnswers: [
          "Более 20 % от нижнего концентрационного предела распространения пламени",
        ],
      },
      {
        code: "66513",
        text: "Какое количество человек должно находиться снаружи с наветренной стороны для страховки на каждого работающего в колодцах и котлованах и недопущения к месту работы посторонних лиц при проведении газоопасных работ?",
        answers: [
          "По 2 человека на каждого работающего",
          "По 3 человека на каждого работающего",
          "По 1 человеку на каждого работающего",
        ],
        correctAnswers: ["По 2 человека на каждого работающего"],
      },
      {
        code: "66514",
        text: "В каком случае допускается проведение ремонтных и наладочных работ в целях защит, блокировок и сигнализации на действующем оборудовании сетей газораспределения и газопотребления ТЭС без оформления наряда-допуска?",
        answers: [
          "Допускается, если данные работы проводятся под наблюдением оперативного руководителя",
          "Допускается, если в ремонтный журнал будет внесена запись о проделанных работах",
          "Допускается, если во время работ обеспечен постоянный контроль качества воздуха газоанализатором",
          "Ни в каком случае",
        ],
        correctAnswers: ["Ни в каком случае"],
      },
      {
        code: "66515",
        text: "В каком случае допускается проведение ремонтных и наладочных работ в цепях защит, блокировок и сигнализации на действующем оборудовании сетей газораспределения и газопотребления ТЭС без оформления наряда-допуска?",
        answers: [
          "Не допускается ни в каком случае",
          "Допускается, если данные работы будут закончены в течение одной рабочей смены",
          "Допускается, если в ремонтный журнал будет внесена запись о проделанных работах",
          "Допускается, если во время работ обеспечен постоянный контроль качества воздуха газоанализатором",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "66516",
        text: "В каком случае допускается проведение ремонтных и наладочных работ защит, блокировок и сигнализации на действующем оборудовании ГТУ и ПГУ без оформления наряда-допуска?",
        answers: [
          "По письменному разрешению технического руководителя организации",
          "Если данные работы проводятся в течение не более 1 часа",
          "При внесении определенной записи в ремонтный журнал",
          "Ни в каком случае",
        ],
        correctAnswers: ["Ни в каком случае"],
      },
      {
        code: "66517",
        text: "В каком случае допускается проведение ремонтных и наладочных работ в цепях защит, блокировок и сигнализации на действующем оборудовании сетей газораспределения и газопотребления ТЭС без оформления наряда-допуска (распоряжения)?",
        answers: [
          "Ни в каком случае",
          "Допускается, если данные работы проводятся под наблюдением оперативного руководителя",
          "Допускается, если в ремонтный журнал будет внесена запись о проделанных работах",
          "Допускается, если во время работ обеспечен постоянный контроль качества воздуха газоанализатором",
        ],
        correctAnswers: ["Ни в каком случае"],
      },
    ],
    694: [
      {
        code: "66718",
        text: "В каком случае технологическая система объекта, использующего СУГ, должна быть повторно испытана на герметичность?",
        answers: [
          "Если объект не был введен в эксплуатацию в течение 3 месяцев",
          "Если объект не был введен в эксплуатацию в течение 4 месяцев",
          "Если объект не был введен в эксплуатацию в течение 6 месяцев",
          "Повторные испытания на герметичность правилами не предусмотрены",
        ],
        correctAnswers: [
          "Если объект не был введен в эксплуатацию в течение 6 месяцев",
        ],
      },
      {
        code: "66719",
        text: "Кто должен испытывать на герметичность технологическую систему объекта, использующего СУГ, перед проведением пусконаладочных работ?",
        answers: [
          "Пусконаладочная организация",
          "Эксплуатационная организация",
          "Строительно-монтажная организация",
          "Экспертная организация",
        ],
        correctAnswers: ["Строительно-монтажная организация"],
      },
      {
        code: "66720",
        text: "Кем утверждается программа приемочных испытаний (пусконаладочных работ) технологической системы объекта, использующего СУГ?",
        answers: [
          "Пусконаладочной организацией",
          "Застройщиком или заказчиком",
          "Эксплуатационной организацией",
          "Строительно-монтажной организацией",
        ],
        correctAnswers: ["Застройщиком или заказчиком"],
      },
      {
        code: "66721",
        text: "К какому моменту должны быть назначены лица, ответственные за выполнение газоопасных работ, техническое состояние и безопасную эксплуатацию сосудов, работающих под избыточным давлением, за безопасную эксплуатацию электрохозяйства и вентиляционного оборудования?",
        answers: [
          "К моменту приемки объекта, использующего СУГ, по окончании пусконаладочных работ и комплексного опробования",
          "К моменту ввода объекта, использующего СУГ, в эксплуатацию",
          "К моменту проведения пусконаладочных работ",
        ],
        correctAnswers: ["К моменту проведения пусконаладочных работ"],
      },
      {
        code: "66722",
        text: "В течение какого времени при пусконаладочных работах по вводу в эксплуатацию проводится отработка технологических режимов на газонаполнительных станциях (далее - ГНС) и газонаполнительных пунктах (далее - ГНП)?",
        answers: [
          "Не менее 2 часов",
          "Не менее 12 часов",
          "Не менее 24 часов",
          "Не менее 48 часов",
          "Не менее 72 часов",
        ],
        correctAnswers: ["Не менее 72 часов"],
      },
      {
        code: "66723",
        text: "В течение какого времени при пусконаладочных работах по вводу в эксплуатацию проводится отработка технологических режимов на резервуарных установках?",
        answers: [
          "Не менее 2 часов",
          "Не менее 12 часов",
          "Не менее 24 часов",
          "Не менее 48 часов",
          "Не менее 72 часов",
        ],
        correctAnswers: ["Не менее 2 часов"],
      },
      {
        code: "66724",
        text: "Кто является ответственным за безопасное проведение пусконаладочных работ на объектах, использующих СУГ?",
        answers: [
          "Руководитель объекта, использующего СУГ",
          "Руководитель пусконаладочной бригады",
          "Руководитель пусконаладочной организации",
          "Технический руководитель объекта, использующего СУГ",
        ],
        correctAnswers: ["Руководитель пусконаладочной бригады"],
      },
      {
        code: "66725",
        text: "В течение какого срока передается оперативное сообщение об аварии, инциденте на опасном производственном объекте?",
        answers: [
          "В течение 24 часов с момента возникновения аварии, инцидента",
          "В течение 72 часов с момента возникновения аварии, инцидента",
          "В течение 48 часов с момента возникновения аварии, инцидента",
          "Немедленно",
        ],
        correctAnswers: [
          "В течение 24 часов с момента возникновения аварии, инцидента",
        ],
      },
      {
        code: "66726",
        text: "Кто возглавляет комиссию по техническому расследованию причин аварии на опасном производственном объекте?",
        answers: [
          "Представитель Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности) или его территориального органа",
          "Представитель вышестоящего органа или организации, эксплуатирующей опасный производственный объект",
          "Представитель организации, эксплуатирующей опасный производственный объект",
          "Представитель органа исполнительной власти субъекта Российской Федерации и (или) органа местного самоуправления, на территории которых располагается опасный производственный объект",
        ],
        correctAnswers: [
          "Представитель Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности) или его территориального органа",
        ],
      },
      {
        code: "66727",
        text: "Каким образом назначается комиссия по техническому расследованию причин аварии на опасном производственном объекте?",
        answers: [
          "Приказом Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности) или его территориального органа в течение 24 часов с момента возникновения аварии",
          "Приказом организации, эксплуатирующей опасный производственный объект, в течение 24 часов с момента возникновения аварии",
          "Приказом вышестоящего органа или организации, эксплуатирующей опасный производственный объект, в течение 24 часов с момента возникновения аварии",
          "Приказом Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности) или его территориального органа в срок не позднее 24 часов после получения оперативного сообщения об аварии",
        ],
        correctAnswers: [
          "Приказом Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности) или его территориального органа в срок не позднее 24 часов после получения оперативного сообщения об аварии",
        ],
      },
      {
        code: "66728",
        text: "Какое число членов должно входить в состав комиссии по техническому расследованию причин аварии на опасном производственном объекте?",
        answers: ["Нечетное", "Четное", "Не менее трех", "Не более пяти"],
        correctAnswers: ["Нечетное"],
      },
      {
        code: "66729",
        text: "В течение какого срока составляется акт технического расследования причин аварии на опасном производственном объекте?",
        answers: [
          "Не позднее 30 календарных дней",
          "Не позднее 30 рабочих дней",
          "Не позднее 15 календарных дней",
          "Не позднее 3 рабочих дней",
          "Не позднее 45 календарных дней",
        ],
        correctAnswers: ["Не позднее 30 календарных дней"],
      },
      {
        code: "66730",
        text: "На сколько может быть увеличен срок технического расследования причин аварии на опасном производственном объекте?",
        answers: [
          "Не более чем на 15 календарных дней",
          "Не более чем на 15 рабочих дней",
          "Не более чем на 10 рабочих дней",
          "Не более чем на 30 календарных дней",
        ],
        correctAnswers: ["Не более чем на 15 календарных дней"],
      },
      {
        code: "66731",
        text: "В течение какого срока по результатам технического расследования причин аварии руководителем организации издается приказ, определяющий меры по устранению причин и последствий аварии, по обеспечению безаварийной и стабильной работы опасного производственного объекта?",
        answers: [
          "В течение 7 рабочих дней",
          "В суточный срок",
          "В течение 10 рабочих дней",
          "В течение 3 рабочих дней",
        ],
        correctAnswers: ["В течение 7 рабочих дней"],
      },
      {
        code: "66732",
        text: "Чьим приказом создается комиссия для технического расследования причин инцидентов на опасном производственном объекте?",
        answers: [
          "Приказом руководителя организации, эксплуатирующей объект",
          "Приказом Ростехнадзора или его территориального органа",
          "Приказом вышестоящего органа организации, эксплуатирующей опасный производственный объект",
          "Приказом территориального органа Ростехнадзора, в котором этот объект зарегистрирован",
        ],
        correctAnswers: [
          "Приказом руководителя организации, эксплуатирующей объект",
        ],
      },
      {
        code: "66733",
        text: "С какой периодичностью должна направляться информация об инцидентах, происшедших на опасных производственных объектах, в территориальный орган Ростехнадзора?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в квартал",
          "Не реже 1 раза в полугодие",
          "Не реже 1 раза в год",
        ],
        correctAnswers: ["Не реже 1 раза в квартал"],
      },
      {
        code: "66734",
        text: "В каком случае результаты испытания на герметичность считаются положительными по манометру класса точности 0,6?",
        answers: [
          "При отсутствии видимого падения давления в газопроводе",
          "Если падение давления не превышает трех делений шкалы",
          "Если падение давления не превышает двух делений шкалы",
          "Если падение давления не превышает одного деления шкалы",
        ],
        correctAnswers: [
          "При отсутствии видимого падения давления в газопроводе",
        ],
      },
      {
        code: "66735",
        text: "В течение какого времени подвергаются контрольной опрессовке воздухом или инертным газом с избыточным давлением 0,3 МПа газопроводы, резервуары и технические устройства при проведении пусконаладочных работ на объектах, использующих СУГ, перед продувкой паровой фазой СУГ?",
        answers: [
          "В течение 15 минут",
          "В течение часа",
          "В течение 6 часов",
          "В течение 3 часов",
        ],
        correctAnswers: ["В течение часа"],
      },
      {
        code: "66736",
        text: "Каким документом определяется порядок действия в случае инцидента при эксплуатации сосуда?",
        answers: [
          "Инструкцией (руководством) по эксплуатации предприятия - изготовителя сосуда",
          "Производственной инструкцией, утвержденной эксплуатирующей организацией",
          "Инструкцией, утвержденной эксплуатирующей организацией, устанавливающей действия работников в аварийных ситуациях и в случае инцидента при эксплуатации оборудования под давлением",
        ],
        correctAnswers: [
          "Производственной инструкцией, утвержденной эксплуатирующей организацией",
        ],
      },
      {
        code: "66737",
        text: "Каким образом осуществляется учет транспортируемых сосудов (цистерн) в территориальных органах Ростехнадзора (за исключением подлежащих учету в ином федеральном органе исполнительной власти в области промышленной безопасности)?",
        answers: [
          "Цистерны подлежат учету в органе Ростехнадзора как по месту нахождения (регистрации) организации, эксплуатирующей эти цистерны, так и по месту их использования (временный учет) при сроках их использования на этом месте более 3 месяцев",
          "Транспортируемые сосуды (цистерны) не подлежат учету в территориальных органах Ростехнадзора",
          "Цистерны подлежат учету в органе Ростехнадзора по месту нахождения площадки (при наличии) эксплуатирующей организации, на которой производятся работы по ремонту, техническому обслуживанию и освидетельствованию",
          "Цистерны подлежат учету в органе Ростехнадзора по месту нахождения (регистрации) организации, эксплуатирующей эти цистерны",
        ],
        correctAnswers: [
          "Цистерны подлежат учету в органе Ростехнадзора по месту нахождения площадки (при наличии) эксплуатирующей организации, на которой производятся работы по ремонту, техническому обслуживанию и освидетельствованию",
        ],
      },
      {
        code: "66738",
        text: "Куда организация, на объекте которой произошла авария, после окончания расследования обязана направить экземпляры материалов технического расследования причин аварии?",
        answers: [
          "Только в другие органы, определенные председателем комиссии",
          "Только в Ростехнадзор (иной федеральный орган исполнительной власти в области промышленной безопасности) или его территориальный орган",
          "Только в соответствующие органы, представители которых принимали участие в работе комиссии по техническому расследованию",
          "Во все перечисленные органы",
        ],
        correctAnswers: ["Во все перечисленные органы"],
      },
      {
        code: "66739",
        text: "Куда передается оперативное сообщение об аварии или инциденте?",
        answers: [
          "Во все перечисленные организации",
          "В орган местного самоуправления",
          "В страховую организацию, с которой заключен договор обязательного страхования гражданской ответственности в соответствии с законодательством Российской Федерации об обязательном страховании гражданской ответственности владельца опасного производственного объекта за причинение вреда в результате аварии на опасном объекте",
          "В федеральный орган исполнительной власти, осуществляющий функции по контролю и надзору в области охраны окружающей среды (при авариях, связанных с выбросом опасных веществ)",
          "В территориальный орган Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности), осуществляющий надзор за объектом, либо территориальный орган Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности), на территории деятельности которого произошла авария (при эксплуатации передвижных технических устройств (кранов, подъемников (вышек), передвижных котельных, цистерн, вагонов, локомотивов, автомобилей)",
        ],
        correctAnswers: ["Во все перечисленные организации"],
      },
      {
        code: "66740",
        text: "Кто принимает решение о вводе в эксплуатацию сосуда, работающего под давлением?",
        answers: [
          "Уполномоченный представитель Ростехнадзора",
          "Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения)",
          "Уполномоченный представитель Ростехнадзора принимает решение о вводе в эксплуатацию сосудов, подлежащих учету в органах Ростехнадзора, в остальных случаях решение принимает ответственный за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением",
          "Ответственный за исправное состояние и безопасную эксплуатацию сосуда",
        ],
        correctAnswers: [
          "Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения)",
        ],
      },
      {
        code: "66741",
        text: "Что контролируется при проведении проверки готовности сосуда к пуску в работу?",
        answers: [
          "Наличие, соответствие проекту и исправность арматуры, контрольно-измерительных приборов, приборов безопасности и технологических защит",
          "Наличие положительных результатов технического освидетельствования",
          "Правильность установки, размещения, обвязки оборудования и их соответствие требованиям промышленной безопасности, указаниям проектной документации и руководства (инструкции) по эксплуатации организации-изготовителя оборудования",
          "Контролируется все перечисленное",
        ],
        correctAnswers: ["Контролируется все перечисленное"],
      },
      {
        code: "66742",
        text: "Кем осуществляется расчет вреда (экономического и экологического ущерба) от аварии?",
        answers: [
          "Организацией, на объекте которой произошла авария",
          "Специализированной сторонней организацией",
          "Комиссией по техническому расследованию аварий",
          "Территориальным органом Ростехнадзора",
        ],
        correctAnswers: ["Организацией, на объекте которой произошла авария"],
      },
      {
        code: "66743",
        text: "Какую информацию должен содержать акт по установлению причин инцидента на опасном производственном объекте?",
        answers: [
          "Всю перечисленную информацию",
          "Только информацию о принятых мерах по ликвидации инцидента, а также информацию о материальном ущербе, в том числе вреде, нанесенном окружающей среде",
          "Только информацию о продолжительности простоя и мерах по устранению причин инцидента",
          "Только дату и место инцидента, его причины и обстоятельства",
        ],
        correctAnswers: ["Всю перечисленную информацию"],
      },
      {
        code: "66744",
        text: "Кем рассматриваются вопросы устранения неполадок оборудования и продолжения пусконаладочных работ?",
        answers: [
          "Комиссией, созданной из представителей пусконаладочной и эксплуатирующей организаций",
          "Руководителем пусконаладочных работ",
          "Техническим руководителем (главным инженером) организации, эксплуатирующей объекты, использующие СУГ",
          "Руководителем организации, эксплуатирующей объекты, использующие СУГ",
        ],
        correctAnswers: [
          "Комиссией, созданной из представителей пусконаладочной и эксплуатирующей организаций",
        ],
      },
      {
        code: "66745",
        text: "На кого возлагается финансирование расходов на техническое расследование причин аварий?",
        answers: [
          "На организацию, эксплуатирующую опасный производственный объект, на котором произошла авария",
          "На страховую компанию, с которой заключен договор обязательного страхования гражданской ответственности владельца опасного объекта за причинение вреда в результате аварии на опасном объекте",
          "На территориальный орган Ростехнадзора",
          "На организацию, эксплуатирующую опасные производственные объекты, и страховую компанию, в которой застрахована гражданская ответственность этой организации",
        ],
        correctAnswers: [
          "На организацию, эксплуатирующую опасный производственный объект, на котором произошла авария",
        ],
      },
      {
        code: "66746",
        text: "Чем регламентируется порядок проведения работ по установлению причин инцидентов на опасном производственном объекте?",
        answers: [
          "Документом, утвержденным организацией, эксплуатирующей опасный производственный объект",
          "Документом, утвержденным организацией, эксплуатирующей опасные производственные объекты, по согласованию с представительным органом работников данной организации",
          "Документом, утвержденным организацией, эксплуатирующей опасный производственный объект, согласованным с органом исполнительной власти субъекта Российской Федерации, на территории которого находится опасный производственный объект",
        ],
        correctAnswers: [
          "Документом, утвержденным организацией, эксплуатирующей опасный производственный объект",
        ],
      },
      {
        code: "66747",
        text: "Какое из перечисленных требований не должна выполнять эксплуатирующая организация, участвующая в приемочных испытаниях с пусконаладочной организацией, перед началом этих испытаний?",
        answers: [
          "Назначить ответственного за проведение приемочных испытаний",
          "Вывесить на рабочих местах технологические схемы газопроводов и технических устройств",
          "Утвердить должностные и производственные инструкции, графики технического обслуживания и ремонта, планы мероприятий по локализации и ликвидации последствий аварий, обеспечить взаимодействие с пожарной командой, скорой помощью, газораспределительными организациями",
          "Обеспечить объект, использующий СУГ, средствами пожаротушения в соответствии с проектной документацией",
          "Иметь проектную (исполнительную) и эксплуатационную документацию, акты на проверку эффективности вентиляционных систем, электрооборудования, средств автоматики безопасности",
        ],
        correctAnswers: [
          "Назначить ответственного за проведение приемочных испытаний",
        ],
      },
      {
        code: "66748",
        text: "Какая информация о произошедших инцидентах направляется в территориальный орган Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности), на территории деятельности которого располагается эксплуатируемый объект?",
        answers: [
          "Только количество инцидентов",
          "Только анализ причин возникновения инцидентов и принятые меры по их устранению",
          "Только характер инцидентов",
          "Вся перечисленная информация",
        ],
        correctAnswers: ["Вся перечисленная информация"],
      },
      {
        code: "66749",
        text: "Что из перечисленного допускается проверять нанесением пенообразующего раствора или акустическими приборами (течеискателями) при контрольной опрессовке?",
        answers: [
          "Сварные стыки",
          "Резьбовые и фланцевые соединения",
          "Сальниковые уплотнения",
          "Проверяется все перечисленное",
        ],
        correctAnswers: ["Проверяется все перечисленное"],
      },
      {
        code: "66750",
        text: "Что из перечисленного не обязана выполнять организация, эксплуатирующая объекты, использующие СУГ?",
        answers: [
          "Выполнять мероприятия, обеспечивающие содержание в безопасном состоянии объектов, использующих СУГ",
          "Заключать договоры с организациями, выполняющими работы по техническому обслуживанию и ремонту технических устройств, обеспечивающими безопасное их проведение, в случае, если организация, эксплуатирующая объекты, использующие СУГ, не имеет в своем составе газовой службы",
          "Обеспечивать организацию и проведение обследования состояния зданий, сооружений и газопроводов в сроки, установленные в проектной документации",
          "Обязана выполнять все перечисленное",
        ],
        correctAnswers: ["Обязана выполнять все перечисленное"],
      },
      {
        code: "66751",
        text: "При каком условии представители организации, эксплуатирующей опасный производственный объект, принимают участие в техническом расследовании причин аварии?",
        answers: [
          "Нет, они в расследовании не принимают участия",
          "В качестве членов комиссии по расследованию, но их число не должно превышать 25% от общего числа членов комиссии",
          "В качестве членов комиссии по расследованию, но их число не должно превышать 30% от общего числа членов комиссии",
        ],
        correctAnswers: [
          "В качестве членов комиссии по расследованию, но их число не должно превышать 30% от общего числа членов комиссии",
        ],
      },
      {
        code: "66752",
        text: "В какой срок материалы технического расследования аварии направляются территориальным органом Ростехнадзора в центральный аппарат Ростехнадзора?",
        answers: [
          "В месячный срок",
          "В десятидневный срок",
          " В недельный срок",
          " В двухнедельный срок",
        ],
        correctAnswers: [" В двухнедельный срок"],
      },
      {
        code: "66753",
        text: "Какая документация должна быть разработана для лиц, занятых эксплуатацией объектов, использующих СУГ?",
        answers: [
          "Правила выполнения работ",
          "Должностные и производственные инструкции",
          "Регламенты по организации работы",
          "Правила трудового распорядка и коллективный договор",
        ],
        correctAnswers: ["Должностные и производственные инструкции"],
      },
      {
        code: "66754",
        text: "Кем утверждаются производственные инструкции, устанавливающие технологическую последовательность выполнения работ, методы и объемы проверки качества их выполнения?",
        answers: [
          "Руководителем (уполномоченным заместителем руководителя) организации, эксплуатирующей объект",
          "Главным инженером",
          "Инженером по охране труда",
          "Главным технологом",
        ],
        correctAnswers: [
          "Руководителем (уполномоченным заместителем руководителя) организации, эксплуатирующей объект",
        ],
      },
      {
        code: "66755",
        text: "Какой документ должен прилагаться к производственной инструкции?",
        answers: [
          "Технологическая схема газопроводов и технических устройств",
          "Паспорта на технические устройства",
          "Акты проведенных пусконаладочных работ",
          "Все перечисленные документы",
        ],
        correctAnswers: [
          "Технологическая схема газопроводов и технических устройств",
        ],
      },
      {
        code: "66756",
        text: "В течение какого срока должна храниться предъявляемая приемочной комиссии документация, включая проектную и исполнительскую документацию, и акт приемочной комиссии?",
        answers: [
          "В течение 10 лет с начала эксплуатации оборудования",
          "В течение 30 лет с момента приемки оборудования в эксплуатацию",
          "В течение всего срока эксплуатации объекта, использующего СУГ (до его ликвидации)",
          "В течение срока эксплуатации оборудования, указанного в его паспорте",
          "До капитального ремонта оборудования",
        ],
        correctAnswers: [
          "В течение всего срока эксплуатации объекта, использующего СУГ (до его ликвидации)",
        ],
      },
      {
        code: "66757",
        text: "Какие требования установлены к графикам технического обслуживания и ремонта объектов, использующих СУГ?",
        answers: [
          "Графики утверждаются техническим руководителем (главным инженером) объекта и согласовываются при необходимости с организацией, осуществляющей обслуживание и ремонт технических устройств",
          "Графики утверждаются руководителем организации и согласовываются с организацией, осуществляющей обслуживание и ремонт технических устройств",
          "Графики утверждаются техническим руководителем (главным инженером) объекта и согласовываются с территориальным органом Ростехнадзора, осуществляющим надзор за данным объектом",
        ],
        correctAnswers: [
          "Графики утверждаются техническим руководителем (главным инженером) объекта и согласовываются при необходимости с организацией, осуществляющей обслуживание и ремонт технических устройств",
        ],
      },
      {
        code: "66758",
        text: "На какие объекты должны составляться эксплуатационные формуляры (паспорта)?",
        answers: [
          "На каждый наружный газопровод",
          "На каждую электрозащитную установку",
          "На каждый резервуар СУГ",
          "На все перечисленные объекты",
        ],
        correctAnswers: ["На все перечисленные объекты"],
      },
      {
        code: "66759",
        text: "При соблюдении каких условий допускается разборка арматуры, резьбовых и фланцевых соединений на газопроводах СУГ?",
        answers: [
          "После отключения и продувки воздухом",
          "После отключения и продувки инертным газом или паром",
          "После отключения и промывки водой",
          "После отключения. Продувка отключенного газопровода необязательна",
        ],
        correctAnswers: [
          "После отключения и продувки инертным газом или паром",
        ],
      },
      {
        code: "66760",
        text: "С какой периодичностью должны производиться техническое обслуживание и текущий ремонт арматуры?",
        answers: [
          "С  учетом рекомендаций организации-изготовителя, но не реже 1 раза в 6 месяцев",
          "С  учетом рекомендаций организации-изготовителя, но не реже 1 раза в 12 месяцев",
          "С  учетом рекомендаций организации-изготовителя, но не реже 1 раза в 24 месяца",
          "С  учетом рекомендаций организации-изготовителя, но не реже 1 раза в 36 месяцев",
        ],
        correctAnswers: [
          "С  учетом рекомендаций организации-изготовителя, но не реже 1 раза в 12 месяцев",
        ],
      },
      {
        code: "66761",
        text: "С какой периодичностью должна проводиться проверка параметров настройки предохранительных сбросных клапанов резервуаров и их регулировка на стенде?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в 12 месяцев",
        ],
        correctAnswers: ["Не реже 1 раза в 6 месяцев"],
      },
      {
        code: "66762",
        text: "Давление настройки предохранительных сбросных клапанов не должно превышать рабочее давление более чем:",
        answers: ["На 15%", "На 10%", "На 20%", "На 25%"],
        correctAnswers: ["На 15%"],
      },
      {
        code: "66763",
        text: "Кто в организации должен отвечать за безопасную эксплуатацию объектов, использующих СУГ?",
        answers: [
          "Технический руководитель организации",
          "Главный энергетик",
          "Главный механик",
          "Лицо из числа руководителей или инженерно-технических работников, прошедшее аттестацию, назначенное ответственным за безопасную эксплуатацию объектов, использующих СУГ",
        ],
        correctAnswers: [
          "Лицо из числа руководителей или инженерно-технических работников, прошедшее аттестацию, назначенное ответственным за безопасную эксплуатацию объектов, использующих СУГ",
        ],
      },
      {
        code: "66764",
        text: "Какой максимальный срок эксплуатации, по истечении которого должно проводиться диагностирование, установлен Правилами для технических устройств?",
        answers: ["15 лет", "20 лет", "30 лет", "40 лет"],
        correctAnswers: ["20 лет"],
      },
      {
        code: "66765",
        text: "Какой максимальный срок эксплуатации, по истечении которого должно проводиться диагностирование, установлен Правилами для газопроводов?",
        answers: ["15 лет", "20 лет", "30 лет", "40 лет"],
        correctAnswers: ["30 лет"],
      },
      {
        code: "66766",
        text: "С какой периодичностью должен производиться осмотр надземных газопроводов?",
        answers: [
          "Ежесменно",
          "Ежедневно",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 3 месяца",
        ],
        correctAnswers: ["Не реже 1 раза в 3 месяца"],
      },
      {
        code: "66767",
        text: "С какой периодичностью должен производиться наружный осмотр газопроводов и арматуры для выявления неплотностей в сварных швах и фланцевых соединениях?",
        answers: [
          "Ежедневно",
          "Ежесменно",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 3 месяца",
        ],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "66768",
        text: "Требования каких документов необходимо соблюдать при эксплуатации компрессоров, насосов и испарителей?",
        answers: [
          "Только требования эксплуатационной документации",
          'Только требования ФНП "Правила безопасности для объектов, использующих СУГ"',
          "Только требования производственных инструкций",
          "Требования всех перечисленных документов",
        ],
        correctAnswers: ["Требования всех перечисленных документов"],
      },
      {
        code: "66769",
        text: "При какой концентрации газа в помещении не допускается работа компрессоров, насосов и испарителей?",
        answers: [
          "Свыше 2% нижнего концентрационного предела распространения пламени",
          "Свыше 3% нижнего концентрационного предела распространения пламени",
          "Свыше 10% нижнего концентрационного предела распространения пламени",
          "Свыше 5% нижнего концентрационного предела распространения пламени",
        ],
        correctAnswers: [
          "Свыше 10% нижнего концентрационного предела распространения пламени",
        ],
      },
      {
        code: "66770",
        text: "Какой должна быть температура воздуха в рабочее время в насосно-компрессорном отделении с оборудованием с водяным охлаждением?",
        answers: [
          "Не ниже +5 °C",
          "Не ниже +8 °C",
          "Не ниже +10 °C",
          "Не ниже 0 °C",
        ],
        correctAnswers: ["Не ниже +10 °C"],
      },
      {
        code: "66771",
        text: "Какая документация должна быть в насосно-компрессорном и испарительном отделениях?",
        answers: [
          "Только технологические схемы оборудования, трубопроводов и контрольно-измерительных приборов",
          "Только инструкции по эксплуатации установок",
          "Только эксплуатационные журналы",
          "Все перечисленные документы",
        ],
        correctAnswers: ["Все перечисленные документы"],
      },
      {
        code: "66772",
        text: "Какие смазочные масла должны использоваться для компрессоров и насосов?",
        answers: [
          "Любые, имеющиеся в наличии",
          "Только масла, предусмотренные в заводской инструкции к оборудованию",
          "Масла, рекомендованные к применению Ростехнадзором",
        ],
        correctAnswers: [
          "Только масла, предусмотренные в заводской инструкции к оборудованию",
        ],
      },
      {
        code: "66773",
        text: "Кем проводится техническое обслуживание компрессоров, насосов и испарителей?",
        answers: [
          "Ремонтным персоналом организации под руководством квалифицированного рабочего",
          "Персоналом специализированной организации под руководством специалиста",
          "Эксплуатационным персоналом организации под руководством лица, ответственного за проведение этих работ",
        ],
        correctAnswers: [
          "Эксплуатационным персоналом организации под руководством лица, ответственного за проведение этих работ",
        ],
      },
      {
        code: "66774",
        text: "Какие требования необходимо соблюдать при демонтаже насосов и испарителей для ремонта?",
        answers: [
          "Оборудование следует освободить от СУГ и пропарить",
          "Оборудование следует освободить от СУГ, отключить от обвязывающих газопроводов с помощью запорной арматуры и пропарить",
          "Оборудование следует освободить от СУГ, отключить от обвязывающих газопроводов с помощью заглушек и пропарить",
          "Оборудование следует освободить от СУГ, отключить от обвязывающих газопроводов с помощью заглушек и продуть инертным газом",
        ],
        correctAnswers: [
          "Оборудование следует освободить от СУГ, отключить от обвязывающих газопроводов с помощью заглушек и пропарить",
        ],
      },
      {
        code: "66775",
        text: "Кто дает разрешение на пуск и остановку насосов, компрессоров и испарителей?",
        answers: [
          "Руководитель организации",
          "Технический руководитель (главный инженер) объекта, использующего СУГ",
          "Представитель специализированной организации",
          "Представитель Ростехнадзора",
        ],
        correctAnswers: [
          "Технический руководитель (главный инженер) объекта, использующего СУГ",
        ],
      },
      {
        code: "66776",
        text: "Допускается ли работа насосов, компрессоров и испарителей с отключенной автоматикой, аварийной сигнализацией, а также блокировкой с вентиляторами вытяжных систем?",
        answers: [
          "Допускается в течение времени, указанного в производственной инструкции",
          "Не допускается",
          "Допускается по письменному распоряжению технического руководителя объекта, использующего СУГ, на время, которое требуется для ремонта автоматики, аварийной сигнализации и блокировки",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "66777",
        text: "Когда должны включаться в работу приточные системы вентиляции на ГНС и ГНП?",
        answers: [
          "За 20 минут до включения технологического оборудования",
          "Одновременно с включением вытяжной системы вентиляции",
          "Через 15 минут после включения вытяжных вентиляционных систем",
        ],
        correctAnswers: [
          "Через 15 минут после включения вытяжных вентиляционных систем",
        ],
      },
      {
        code: "66778",
        text: "В каких случаях проводятся испытания вентиляционных установок?",
        answers: [
          "Не реже 1 раза в 12 месяцев",
          "После капитального ремонта, наладки",
          "При неудовлетворительных результатах анализа воздушной среды",
          "В любом из перечисленных случаев",
        ],
        correctAnswers: ["В любом из перечисленных случаев"],
      },
      {
        code: "66779",
        text: "Куда заносятся результаты плановых осмотров вентиляционных установок?",
        answers: [
          "В паспорт установки",
          "В ремонтный журнал",
          "В вахтенный журнал",
          "В эксплуатационный журнал",
        ],
        correctAnswers: ["В эксплуатационный журнал"],
      },
      {
        code: "66780",
        text: "Какой воздухообмен в час должна обеспечить вентиляционная установка в рабочее время?",
        answers: ["3-кратный", "5-кратный", "8-кратный", "10-кратный"],
        correctAnswers: ["10-кратный"],
      },
      {
        code: "66781",
        text: "Какой воздухообмен в час должна обеспечить вентиляционная установка в нерабочее время?",
        answers: ["3-кратный", "5-кратный", "8-кратный", "10-кратный"],
        correctAnswers: ["3-кратный"],
      },
      {
        code: "66782",
        text: "Какой воздухообмен в час должна обеспечить аварийная вытяжная вентиляция?",
        answers: ["3-кратный", "5-кратный", "8-кратный", "10-кратный"],
        correctAnswers: ["8-кратный"],
      },
      {
        code: "66783",
        text: "При наличии какой концентрации газа в помещении должна включаться аварийная вентиляция?",
        answers: [
          "Свыше 5% нижнего концентрационного предела распространения пламени (НКПР)",
          "Свыше 10% НКПР",
          "Свыше 15% НКПР",
          "Свыше 20% НКПР",
        ],
        correctAnswers: ["Свыше 10% НКПР"],
      },
      {
        code: "66784",
        text: "Какие требования должны выполняться при эксплуатации резервуаров СУГ?",
        answers: [
          "Резервуары перед наполнением должны быть проверены на наличие избыточного давления, которое должно быть не менее 0,01 МПа",
          "При эксплуатации резервуаров должно осуществляться ежесменное их техническое обслуживание",
          "Резервуары должны вводиться в эксплуатацию на основании письменного разрешения представителя Ростехнадзора после их освидетельствования, проведенного владельцем",
        ],
        correctAnswers: [
          "При эксплуатации резервуаров должно осуществляться ежесменное их техническое обслуживание",
        ],
      },
      {
        code: "66785",
        text: "Кто дает разрешение на ввод резервуаров в эксплуатацию после их освидетельствования?",
        answers: [
          "Руководитель организации",
          "Представитель специализированной организации",
          "Технический руководитель (главный инженер) объекта, использующего СУГ",
          "Представитель Ростехнадзора",
        ],
        correctAnswers: [
          "Технический руководитель (главный инженер) объекта, использующего СУГ",
        ],
      },
      {
        code: "66786",
        text: "Кем производится запись в журнале о проведенном полном осмотре резервуаров с арматурой и КИП в рабочем состоянии?",
        answers: [
          "Лицом, ответственным за исправное состояние и безопасную эксплуатацию сосудов, работающих под избыточным давлением",
          "Главным механиком организации",
          "Представителем Ростехнадзора",
          "Начальником службы производственного контроля",
        ],
        correctAnswers: [
          "Лицом, ответственным за исправное состояние и безопасную эксплуатацию сосудов, работающих под избыточным давлением",
        ],
      },
      {
        code: "66787",
        text: "Каким должен быть максимальный объем жидкой фазы после заполнения резервуара?",
        answers: [
          "70% геометрического объема",
          "80% геометрического объема",
          "85% геометрического объема",
          "90% геометрического объема",
        ],
        correctAnswers: ["85% геометрического объема"],
      },
      {
        code: "66788",
        text: "Какое из требований должно выполняться при обработке резервуаров перед их внутренним осмотром или ремонтом?",
        answers: [
          "Резервуары должны быть отключены от газопроводов обвязки с помощью запорной арматуры. Установка заглушек необязательна",
          "Обработка резервуаров должна производиться путем их пропаривания с последующей продувкой воздухом. Промывка водой не допускается",
          "Обработка резервуаров должна производиться путем их пропаривания или промывки водой с последующей продувкой инертным газом. Применение для дегазации воздуха не допускается",
          "Качество дегазации должно проверяться путем анализа проб, отобранных из верхней части сосуда",
        ],
        correctAnswers: [
          "Обработка резервуаров должна производиться путем их пропаривания или промывки водой с последующей продувкой инертным газом. Применение для дегазации воздуха не допускается",
        ],
      },
      {
        code: "66789",
        text: "Какое из требований должно выполняться при выполнении работ внутри резервуаров?",
        answers: [
          "Работы внутри резервуаров должны проводиться по наряду-допуску бригадой в составе не менее 2 работников под руководством ответственного лица",
          "Спуск в резервуар более 2 работников не допускается",
          "Время пребывания в резервуаре не должно превышать 15 минут",
          "Каждые 15 минут следует проверять резервуар на загазованность",
        ],
        correctAnswers: [
          "Время пребывания в резервуаре не должно превышать 15 минут",
        ],
      },
      {
        code: "66790",
        text: "В какое время суток должен производиться слив СУГ из автомобильных и железнодорожных цистерн в резервуары?",
        answers: [
          "Только в утренние и вечерние часы - в начале и конце смены",
          "Только в светлое время суток",
          "В любое время суток при обязательном освещении места проведения работ",
        ],
        correctAnswers: ["Только в светлое время суток"],
      },
      {
        code: "66791",
        text: "Количество персонала, выполняющего слив СУГ из железнодорожных и автомобильных цистерн и наполнение автомобильных цистерн, должно быть не менее:",
        answers: ["2 рабочих", "3 рабочих", "Не регламентируется", "4 рабочих"],
        correctAnswers: ["3 рабочих"],
      },
      {
        code: "66792",
        text: "Количество персонала, выполняющего слив СУГ из железнодорожных и автомобильных цистерн в резервуары резервуарных установок, должно быть не менее:",
        answers: ["2 рабочих", "3 рабочих", "4 рабочих"],
        correctAnswers: ["2 рабочих"],
      },
      {
        code: "66793",
        text: "В каком случае не допускается выполнение сливоналивных операций из железнодорожных и автомобильных цистерн?",
        answers: [
          "При температуре окружающего воздуха выше +30 °С и ниже -25 °С",
          "Во время грозы и при проведении огневых работ",
          "При порывах ветра более 15 м/с",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во время грозы и при проведении огневых работ"],
      },
      {
        code: "66794",
        text: "Какому испытанию должны подвергаться рукава, применяемые при сливоналивных операциях, и с какой периодичностью?",
        answers: [
          "Гидравлическому испытанию на прочность давлением, равным 1,25 рабочего давления, не реже 1 раза в 3 месяца",
          "Пневматическому испытанию на прочность давлением, равным 1,25 рабочего давления, не реже 1 раза в 3 месяца",
          "Гидравлическому испытанию на прочность давлением, равным 1,5 рабочего давления, не реже 1 раза в месяц",
          "Гидравлическому испытанию на прочность давлением, равным 1,5 рабочего давления, не реже 1 раза в 3 месяца",
        ],
        correctAnswers: [
          "Гидравлическому испытанию на прочность давлением, равным 1,25 рабочего давления, не реже 1 раза в 3 месяца",
        ],
      },
      {
        code: "66795",
        text: "Какие данные должны быть на рукавах, применяемых для проведения сливоналивных операций?",
        answers: [
          "Обозначение с заводским номером, датами проведения (месяц, год) испытания и последующего испытания (месяц, год)",
          "Обозначение с порядковым номером, датой проведения (месяц, год) испытания и данными об организации-владельце",
          "Обозначение с заводским и порядковым номерами, датой проведения (месяц, год) испытания",
          "Обозначение с порядковым номером, датами проведения (месяц, год) испытания и последующего испытания (месяц, год)",
        ],
        correctAnswers: [
          "Обозначение с порядковым номером, датами проведения (месяц, год) испытания и последующего испытания (месяц, год)",
        ],
      },
      {
        code: "66796",
        text: "Каким образом должен осуществляться слив СУГ из цистерн?",
        answers: [
          "Только созданием перепада давления между цистерной и резервуаром при работе компрессора",
          "Только созданием перепада давления между цистерной и резервуаром подогревом паров СУГ в испарителе",
          "Только перекачиванием СУГ насосами",
          "Только самотеком при расположении резервуаров ниже цистерны",
          "Любым из перечисленных способом",
        ],
        correctAnswers: ["Любым из перечисленных способом"],
      },
      {
        code: "66797",
        text: "Каким образом не допускается создавать перепад давлений между цистерной и наполняемым резервуаром?",
        answers: [
          "Работой компрессора",
          "Подогревом паров СУГ в испарителе",
          "Сбросом паровой фазы СУГ из наполняемого резервуара в атмосферу",
          "Всеми перечисленными способами",
        ],
        correctAnswers: [
          "Сбросом паровой фазы СУГ из наполняемого резервуара в атмосферу",
        ],
      },
      {
        code: "66798",
        text: "Какой перепад давления допускается между цистерной и резервуаром во время слива СУГ?",
        answers: [
          "В пределах 0,5 - 0,6 МПа",
          "В пределах 0,3 - 0,5 МПа",
          "В пределах 0,2 - 0,3 МПа",
          "В пределах 0,15 - 0,2 МПа",
        ],
        correctAnswers: ["В пределах 0,15 - 0,2 МПа"],
      },
      {
        code: "66799",
        text: "Каким должно быть остаточное давление паров в цистерне после слива СУГ?",
        answers: [
          "Не ниже 0,1 МПа",
          "Не ниже 0,01 МПа",
          "Не ниже 0,5 МПа",
          "Не ниже 0,05 МПа",
        ],
        correctAnswers: ["Не ниже 0,05 МПа"],
      },
      {
        code: "66800",
        text: "Каким способом не разрешается оттаивать арматуру и сливные газопроводы?",
        answers: [
          "Нагретым песком",
          "Открытым огнем",
          "Горячей водой",
          "Водяным паром",
        ],
        correctAnswers: ["Открытым огнем"],
      },
      {
        code: "66801",
        text: "В каком случае не допускается наполнение СУГ резервуаров?",
        answers: [
          "Только в случае обнаружения трещин, выпучин, пропусков или потения в сварных швах и фланцевых соединениях",
          "Только в случае обнаружения неисправности предохранительных клапанов и уровнемерных устройств",
          "Только в случае неисправности или неполном количестве крепежных деталей на лазах и люках",
          "В случае осадки фундаментов резервуаров и опор подводящих газопроводов",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "66802",
        text: "В каком помещении должно производиться наполнение баллонов СУГ?",
        answers: [
          "В неотапливаемом помещении при температуре воздуха не ниже +5 °С при работающих приточно-вытяжной вентиляции и сигнализации загазованности",
          "В отапливаемом помещении при температуре воздуха не ниже +10 °С при наличии естественной вентиляции",
          "В отапливаемом помещении при температуре воздуха не ниже +15 °С при работающей приточно-вытяжной вентиляции",
          "В отапливаемом помещении при температуре воздуха не ниже +10 °С при работающих приточно-вытяжной вентиляции и сигнализации загазованности",
        ],
        correctAnswers: [
          "В отапливаемом помещении при температуре воздуха не ниже +10 °С при работающих приточно-вытяжной вентиляции и сигнализации загазованности",
        ],
      },
      {
        code: "66803",
        text: "При какой температуре наружного воздуха допускается наполнять баллоны СУГ на открытых площадках?",
        answers: [
          "Не ниже -30 °С",
          "Не ниже -25 °С",
          "Не ниже -20 °С",
          "Не ниже -10 °С",
        ],
        correctAnswers: ["Не ниже -20 °С"],
      },
      {
        code: "66804",
        text: "В каком случае из перечисленных допускается эксплуатация наполнительных установок, установленных на открытых площадках?",
        answers: [
          "При неисправности предохранительных клапанов, манометров, контрольно-измерительных приборов и средств автоматики",
          "При температуре наружного воздуха не ниже -20 °С",
          "При неповеренных контрольно-измерительных приборах",
          "При неполном количестве или неисправности крепежных деталей",
        ],
        correctAnswers: ["При температуре наружного воздуха не ниже -20 °С"],
      },
      {
        code: "66805",
        text: "В каком количестве должны находиться баллоны в наполнительном цехе?",
        answers: [
          "Не больше суммарной часовой производительности наполнительного цеха",
          "Не больше половины суммарной дневной производительности наполнительного цеха",
          "Не больше трети суммарной часовой производительности наполнительного цеха",
          "Не больше половины суммарной часовой производительности наполнительного цеха",
        ],
        correctAnswers: [
          "Не больше половины суммарной часовой производительности наполнительного цеха",
        ],
      },
      {
        code: "66806",
        text: "С какой периодичностью должна производиться проверка срабатывания устройств сигнализации и блокировок автоматики безопасности?",
        answers: [
          "Не реже 1 раза в смену",
          "Не реже 1 раза в неделю",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 3 месяца",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "66807",
        text: "С какой периодичностью должен осуществляться контроль концентрации СУГ в производственных помещениях переносными газоанализаторами в период замены стационарных сигнализаторов загазованности?",
        answers: [
          "Через каждые 15 минут в течение смены",
          "Через каждые 30 минут в течение смены",
          "Через каждый час в течение смены",
          "Через каждые 3 часа в течение смены",
        ],
        correctAnswers: ["Через каждые 30 минут в течение смены"],
      },
      {
        code: "66808",
        text: "Какую шкалу должны иметь манометры, устанавливаемые на оборудовании и газопроводах?",
        answers: [
          "Шкалу, предел измерения рабочего давления которой находится в первой ее трети",
          "Шкалу, предел измерения рабочего давления которой находится во второй ее трети",
          "Шкалу, предел измерения рабочего давления которой находится в третьей ее трети",
        ],
        correctAnswers: [
          "Шкалу, предел измерения рабочего давления которой находится во второй ее трети",
        ],
      },
      {
        code: "66809",
        text: "Что должно быть обозначено краской на шкале или корпусе показывающих манометров?",
        answers: [
          "Значение, соответствующее расчетному давлению",
          "Значение класса точности манометра",
          "Значение, соответствующее рабочему давлению",
          "Условное обозначение прибора",
        ],
        correctAnswers: ["Значение, соответствующее рабочему давлению"],
      },
      {
        code: "66810",
        text: "Кем должен утверждаться график проверки загазованности колодцев всех подземных коммуникаций в пределах территории объекта, использующего СУГ?",
        answers: [
          "Руководителем организации",
          "Инспектором Ростехнадзора",
          "Техническим руководителем объекта, использующего СУГ",
          "Главным механиком объекта, использующего СУГ",
        ],
        correctAnswers: [
          "Техническим руководителем объекта, использующего СУГ",
        ],
      },
      {
        code: "66811",
        text: "С какой периодичностью должен проводиться текущий ремонт наружных сетей водопровода и канализации?",
        answers: [
          "1 раз в 12 месяцев",
          "1 раз в 2 года",
          "1 раз в 3 года",
          "1 раз в 5 лет",
        ],
        correctAnswers: ["1 раз в 2 года"],
      },
      {
        code: "66812",
        text: "Кто на объекте, использующем СУГ, отвечает за эксплуатацию зданий и сооружений, соблюдение сроков и качества их ремонта?",
        answers: [
          "Руководитель объекта, использующего СУГ",
          "Технический руководитель объекта, использующего СУГ",
          "Лицо, назначенное приказом руководителя объекта, использующего СУГ",
          "Главный механик объекта, использующего СУГ",
        ],
        correctAnswers: [
          "Лицо, назначенное приказом руководителя объекта, использующего СУГ",
        ],
      },
      {
        code: "66813",
        text: "По истечении какого срока эксплуатации здания и сооружения должны пройти обследование для установления возможности дальнейшей их эксплуатации, необходимости проведения реконструкции или прекращения эксплуатации?",
        answers: [
          "30 лет",
          "40 лет",
          "Срока, предусмотренного в проектной документации",
          "75 лет",
        ],
        correctAnswers: ["Срока, предусмотренного в проектной документации"],
      },
      {
        code: "66814",
        text: "Какой срок службы устанавливается для баллонов при отсутствии в технической документации сведений о сроке службы баллона, определенном при его проектировании?",
        answers: ["10 лет", "20 лет", "30 лет", "40 лет", "50 лет"],
        correctAnswers: ["20 лет"],
      },
      {
        code: "66815",
        text: "При выявлении каких дефектов во время осмотра наружной и внутренней поверхности баллоны не подлежат отбраковке?",
        answers: [
          "Выявление всех указанных дефектов влечет за собой отбраковку баллона",
          "Ослабление кольца на горловине баллона",
          "Износ резьбы горловины",
          "Наличие надрывов и выщерблений",
          "Наличие трещин, плен, вмятин, отдулин, раковин и рисок глубиной более 10% номинальной толщины стенки",
        ],
        correctAnswers: ["Ослабление кольца на горловине баллона"],
      },
      {
        code: "66816",
        text: "Какой из перечисленных видов ремонта не входит в перечень работ по текущему ремонту газопроводов?",
        answers: [
          "Усиление подвижных и неподвижных опор",
          "Устранение провеса надземных газопроводов",
          "Восстановление или замена креплений надземных газопроводов",
          "Ремонт запорной арматуры",
          "Проверка герметичности резьбовых и фланцевых соединений",
        ],
        correctAnswers: ["Усиление подвижных и неподвижных опор"],
      },
      {
        code: "66817",
        text: "Какое из перечисленных требований при освобождении резервуаров резервуарной установки указано верно?",
        answers: [
          'Освобождение резервуаров резервуарной установки должно производиться выжиганием остатков СУГ на "свече" диаметром не менее 20 мм',
          'Освобождение резервуаров резервуарной установки должно производиться выжиганием остатков СУГ на "свече" высотой 2 м',
          'Освобождение резервуаров резервуарной установки должно производиться выжиганием остатков СУГ на "свече", установленной на расстоянии не менее 10 м от границы резервуарной установки',
          "Освобождение резервуаров резервуарной установки должно производиться сбросом СУГ в атмосферу",
        ],
        correctAnswers: [
          'Освобождение резервуаров резервуарной установки должно производиться выжиганием остатков СУГ на "свече" диаметром не менее 20 мм',
        ],
      },
      {
        code: "66818",
        text: "На каком минимальном расстоянии от ограждения должна быть установлена запорная арматура вне территории ГНС, ГНП при подаче СУГ на ГНС, ГНП по газопроводу?",
        answers: ["50 м", "100 м", "150 м", "200 м"],
        correctAnswers: ["50 м"],
      },
      {
        code: "66819",
        text: "При каком минимальном избыточном давлении в сосуде допускается проведение ремонта сосуда и его элементов?",
        answers: [
          "Не допускается проведение ремонта сосудов и их элементов, подлежащих учету в территориальных органах Ростехнадзора и находящихся под давлением, для остальных сосудов и их элементов - 0,05 МПа",
          "Не допускается проведение ремонта сосудов и их элементов, находящихся под давлением",
          "0,025 МПа",
          "0,05 МПа",
        ],
        correctAnswers: [
          "Не допускается проведение ремонта сосудов и их элементов, находящихся под давлением",
        ],
      },
      {
        code: "66820",
        text: "Какой воздухозабор должен быть обеспечен при проверке степени воздухообмена, создаваемого принудительной вентиляцией?",
        answers: [
          "2/3 воздухозабора вытяжной вентиляцией из нижней зоны помещения и 1/3 - из верхней зоны",
          "1/3 воздухозабора вытяжной вентиляцией из нижней зоны помещения и 2/3 - из верхней зоны",
          "1/2 воздухозабора вытяжной вентиляцией из нижней зоны помещения и 1/2 - из верхней зоны",
          "1/5 воздухозабора вытяжной вентиляцией из нижней зоны помещения и 4/5 - из верхней зоны",
        ],
        correctAnswers: [
          "2/3 воздухозабора вытяжной вентиляцией из нижней зоны помещения и 1/3 - из верхней зоны",
        ],
      },
      {
        code: "66821",
        text: "Какие требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, к оснащению баллонов предохранительным клапаном указаны неверно?",
        answers: [
          "Пропускная способность предохранительного клапана подтверждается расчетом",
          "Баллоны вместимостью более 100 л должны быть оснащены предохранительными клапанами",
          "При групповой установке баллонов предохранительным клапаном обязательно оснащается каждый баллон",
          "При групповой установке баллонов допускается установка предохранительного клапана на всю группу баллонов",
        ],
        correctAnswers: [
          "При групповой установке баллонов предохранительным клапаном обязательно оснащается каждый баллон",
        ],
      },
      {
        code: "66822",
        text: "Какие требования к хранению баллонов на наполнительной станции указаны неверно?",
        answers: [
          "При укладке баллонов в штабеля высота последних не должна превышать 1,5 м, вентили баллонов должны быть обращены в одну сторону",
          "Не допускается хранение баллонов, которые не имеют башмаков, в горизонтальном положении на деревянных рамах или стеллажах",
          "При хранении на открытых площадках разрешается укладывать баллоны с башмаками в штабеля с прокладками из веревки, деревянных брусьев, резины или иных неметаллических материалов, имеющих амортизирующие свойства, между горизонтальными рядами",
        ],
        correctAnswers: [
          "Не допускается хранение баллонов, которые не имеют башмаков, в горизонтальном положении на деревянных рамах или стеллажах",
        ],
      },
      {
        code: "66823",
        text: "Где может проводиться восстановление окраски и надписей на баллонах?",
        answers: [
          "На наполнительной станции",
          "На пунктах освидетельствования баллонов",
          "В любой специализированной ремонтной организации",
          "В эксплуатирующей организации",
        ],
        correctAnswers: ["На пунктах освидетельствования баллонов"],
      },
      {
        code: "66824",
        text: "При какой вместимости баллонов результаты технического освидетельствования баллонов заносятся в паспорт баллона?",
        answers: [
          "Результаты освидетельствования в паспорт баллона не заносятся, ставится только клеймо на баллоне",
          "Свыше 55 до 100 литров",
          "Более 100 литров",
          "До 55 литров",
        ],
        correctAnswers: ["Более 100 литров"],
      },
      {
        code: "66825",
        text: "Каково максимально допустимое значение давления при работающих предохранительных клапанах в сосуде с давлением от 0,3 до 6 МПа включительно?",
        answers: [
          "Превышающее разрешенное давление не более чем на 25%",
          "Превышающее разрешенное давление не более чем на 15%",
          "Превышающее разрешенное давление не более чем на 0,05 МПа",
          "Превышающее разрешенное давление не более чем на 10%",
        ],
        correctAnswers: [
          "Превышающее разрешенное давление не более чем на 15%",
        ],
      },
      {
        code: "66826",
        text: "Какое требование Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, предъявляется к инженерно-техническим работникам, связанным с эксплуатацией оборудования под давлением?",
        answers: [
          "Инженерно-технические работники должны соответствовать квалификационным требованиям",
          "Инженерно-технические работники должны быть не моложе 18-летнего возраста и не иметь медицинских противопоказаний для выполнения работ по обслуживанию оборудования под давлением",
          "Инженерно-технические работники должны пройти аттестацию по промышленной безопасности, в объеме требований промышленной безопасности, необходимых для исполнения трудовых обязанностей (в зависимости от типа конкретного оборудования, к эксплуатации которого они допускаются), знать положения распорядительных документов, инструкций и иных нормативных документов, принятых в организации для обеспечения промышленной безопасности, относящихся к выполняемым обязанностям и выполнять установленные в них требования в процессе выполнения работ",
          "Инженерно-технические работники должны быть допущены к самостоятельной работе в установленном порядке",
          "Все требования указаны верно",
        ],
        correctAnswers: [
          "Инженерно-технические работники должны пройти аттестацию по промышленной безопасности, в объеме требований промышленной безопасности, необходимых для исполнения трудовых обязанностей (в зависимости от типа конкретного оборудования, к эксплуатации которого они допускаются), знать положения распорядительных документов, инструкций и иных нормативных документов, принятых в организации для обеспечения промышленной безопасности, относящихся к выполняемым обязанностям и выполнять установленные в них требования в процессе выполнения работ",
        ],
      },
      {
        code: "66827",
        text: "С какой периодичностью должны осматриваться технические устройства на газонаполнительной станции и газонаполнительном пункте?",
        answers: ["Ежеквартально", "Еженедельно", "Ежесменно", "Ежедневно"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "66828",
        text: "Каким должно быть время выдержки баллонов под пробным давлением при проведении гидравлического испытания?",
        answers: [
          "Устанавливается организацией-изготовителем",
          "Не менее 10 минут",
          "Не менее 15 минут",
          "Не менее 5 минут",
        ],
        correctAnswers: ["Устанавливается организацией-изготовителем"],
      },
      {
        code: "66829",
        text: "Чем осуществляется продувка сосуда, работающего под давлением горючих газов, до начала выполнения работ внутри его корпуса?",
        answers: [
          "Для таких сосудов предусмотрена специальная обработка (дегазация, нейтрализация)",
          "Инертным газом и (или) воздухом",
          "Только воздухом",
        ],
        correctAnswers: ["Инертным газом и (или) воздухом"],
      },
      {
        code: "66830",
        text: "На какое минимальное расстояние должен быть удален локомотив с территории сливной эстакады при подготовке к сливу СУГ из железнодорожных цистерн?",
        answers: ["100 м", "150 м", "50 м", "200 м"],
        correctAnswers: ["100 м"],
      },
      {
        code: "66831",
        text: "Какое требование к наполнению баллонов газами указано неверно?",
        answers: [
          "Наполнение баллонов сжиженными газами должно соответствовать нормам, установленным организацией - изготовителем баллонов и (или) техническими условиями на сжиженные газы",
          "Перед наполнением кислородных баллонов должен быть проведен контроль отсутствия в них примеси горючих газов газоанализатором в порядке, установленном инструкцией",
          "Наполнение баллонов газами должно быть произведено по инструкции, разработанной и утвержденной наполнительной организацией (индивидуальным предпринимателем) в установленном порядке с учетом свойств газа, местных условий и требований руководства (инструкции) по эксплуатации и иной документации изготовителя баллона",
          "При отсутствии установленных организацией - изготовителем баллонов и (или) техническими условиями на сжиженные газы норм наполнения наполнение баллонов производится в соответствии с нормами, установленными ТР ТС 032/2013",
        ],
        correctAnswers: [
          "При отсутствии установленных организацией - изготовителем баллонов и (или) техническими условиями на сжиженные газы норм наполнения наполнение баллонов производится в соответствии с нормами, установленными ТР ТС 032/2013",
        ],
      },
      {
        code: "66832",
        text: "С какой периодичностью проводится проверка параметров настройки предохранительного сбросного клапана резервуаров?",
        answers: [
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в год",
        ],
        correctAnswers: ["Не реже 1 раза в 6 месяцев"],
      },
      {
        code: "66833",
        text: "Как оформляется журнал наполнения, если наполнительная станция производит наполнение баллонов различными газами?",
        answers: [
          "Ведется единый журнал наполнения",
          "Должен вестись отдельный журнал наполнения",
          "В отдельный журнал заносятся только сведения о наполнении баллонов взрывоопасными газами",
        ],
        correctAnswers: ["Должен вестись отдельный журнал наполнения"],
      },
      {
        code: "66834",
        text: "Каким способом определяется фактическая вместимость баллона?",
        answers: [
          "Только по разности между массой баллона, наполненного водой, и массой порожнего баллона",
          "Способ указывается в производственной инструкции",
          "Только с помощью мерных бачков",
          "Всеми перечисленными способами",
        ],
        correctAnswers: ["Всеми перечисленными способами"],
      },
      {
        code: "66835",
        text: "Какие документы вентиляционного оборудования должны храниться на объекте, использующем СУГ?",
        answers: [
          "Все перечисленные документы",
          "Схема системы воздуховодов с указанием размеров, расположения арматуры, компенсаторов, предохранительных и дренажных устройств",
          "Акты приемки в эксплуатацию вентиляционной установки",
          "Паспорта на оборудование, являющееся неотъемлемой частью вентиляционной установки",
        ],
        correctAnswers: ["Все перечисленные документы"],
      },
      {
        code: "66836",
        text: "С какой периодичностью должна производиться проверка включения в работу аварийных вентиляционных установок?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в неделю",
          "Не реже 1 раза в квартал",
          "Не реже 1 раза в полгода",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "66837",
        text: "При какой величине пробного давления проводится гидравлическое испытание баллонов?",
        answers: [
          "Величину пробного давления устанавливает организация-изготовитель, при этом пробное давление должно быть не менее чем 1,25 рабочего давления",
          "Пробное давление должно быть не менее чем рабочее давление",
          "Величину пробного давления устанавливает организация-изготовитель, при этом пробное давление должно быть не менее чем полуторное рабочее давление",
        ],
        correctAnswers: [
          "Величину пробного давления устанавливает организация-изготовитель, при этом пробное давление должно быть не менее чем полуторное рабочее давление",
        ],
      },
      {
        code: "66838",
        text: "С какой периодичностью должна проводиться проверка кратности воздухообмена в помещениях объекта, использующего СУГ?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 2 месяца",
          "Не реже 1 раза 3 месяца",
          "Не реже 1 раза в 4 месяца",
        ],
        correctAnswers: ["Не реже 1 раза 3 месяца"],
      },
      {
        code: "66839",
        text: "Кем утверждается график периодичности обхода трасс подземных газопроводов?",
        answers: [
          "Техническим руководителем (главным инженером)",
          "Инспектором территориального органа Ростехнадзора",
          "Лицом, ответственным за безопасную эксплуатацию объекта, использующего СУГ",
          "Руководителем объекта, использующего СУГ",
        ],
        correctAnswers: ["Техническим руководителем (главным инженером)"],
      },
      {
        code: "66840",
        text: "Где могут храниться наполненные баллоны с газами?",
        answers: [
          "Как в специальных помещениях, так и на открытом воздухе, в последнем случае они должны быть защищены от атмосферных осадков и солнечных лучей",
          "Правила промышленной безопасности при использовании оборудования, работающего под избыточным давлением, не устанавливают дополнительных требований к помещениям для хранения баллонов",
          "Только в специально оборудованном помещении",
        ],
        correctAnswers: [
          "Как в специальных помещениях, так и на открытом воздухе, в последнем случае они должны быть защищены от атмосферных осадков и солнечных лучей",
        ],
      },
      {
        code: "66841",
        text: "Какие требования к окраске баллонов и нанесению надписей указаны неверно?",
        answers: [
          "Окраску баллонов и нанесение надписей при эксплуатации производят организации-изготовители, наполнительные станции (пункты наполнения) или испытательные пункты (пункты проверки) в соответствии с требованиями ТР ТС 032/2013",
          "Баллоны вместимостью более 75 л допускается окрашивать в иные цвета с нанесением надписей и маркировки в соответствии с проектной документацией и руководством (инструкцией) по эксплуатации",
          "Стационарно установленные баллоны вместимостью более 100 л допускается окрашивать в иные цвета с нанесением надписей и маркировки в соответствии с проектной документацией и руководством (инструкцией) по эксплуатации",
          "Требования к окраске и надписям, не установленные ТР ТС 032/2013, устанавливаются проектной документацией и (или) техническими условиями на продукцию, для хранения которой предназначены эти баллоны, и указываются в распорядительных документах",
        ],
        correctAnswers: [
          "Баллоны вместимостью более 75 л допускается окрашивать в иные цвета с нанесением надписей и маркировки в соответствии с проектной документацией и руководством (инструкцией) по эксплуатации",
        ],
      },
      {
        code: "66842",
        text: "Какому требованию должен соответствовать сжатый воздух, использующийся для пневматических устройств, систем автоматического регулирования и контроля, в случае отсутствия требований, установленных в проектной, а также в эксплуатационной документации изготовителя оборудования?",
        answers: [
          "Размер единичной твердой частицы - не более 15 мкм",
          "Содержание твердых примесей - не более 4 мг/м³",
          "Давление - не более 1,3 Мпа",
          "Температура точки росы - не менее -40 °C",
        ],
        correctAnswers: ["Температура точки росы - не менее -40 °C"],
      },
      {
        code: "66843",
        text: "Какие данные не указываются наполнительной станцией, производящей наполнение баллонов сжатыми, сжиженными и растворимыми газами, в журнале наполнения баллонов?",
        answers: [
          "Дата наполнения и номер баллона",
          "Подпись, фамилия и инициалы лица, наполнившего баллон",
          "Масса газа (сжиженного) в баллоне, кг",
          "Дата освидетельствования",
          "Эксплуатирующая организация",
        ],
        correctAnswers: ["Эксплуатирующая организация"],
      },
      {
        code: "66844",
        text: "Какое допускается максимальное превышение давления настройки предохранительного сбросного клапана?",
        answers: [
          "На 15% рабочего давления в резервуарах и газопроводах",
          "На 10% рабочего давления в резервуарах и газопроводах",
          "На 30% рабочего давления в резервуарах и газопроводах",
          "На 25% рабочего давления в резервуарах и газопроводах",
        ],
        correctAnswers: [
          "На 15% рабочего давления в резервуарах и газопроводах",
        ],
      },
      {
        code: "66845",
        text: "В какой документ заносятся результаты проверки исправности предохранительных устройств, установленных на сосуде, и сведения об их настройке?",
        answers: [
          "В сменный (оперативный) журнал или иные эксплуатационные документы",
          "В паспорт сосуда",
          "В соответствующее приложение (проверка исправности и сведения о настройках) к производственной инструкции по эксплуатации предохранительных устройств",
          "В паспорт предохранительного клапана",
        ],
        correctAnswers: [
          "В сменный (оперативный) журнал или иные эксплуатационные документы",
        ],
      },
      {
        code: "66846",
        text: "В каком случае допускается оставлять цистерны присоединенными к газопроводам в период, когда слив СУГ не производится?",
        answers: [
          "Не допускается ни в каком случае",
          "Если период длится не более 1 часа",
          "Если на месте сливоналивных операций присутствует наблюдающий",
          "Если двигатель автомобиля заглушен",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "66847",
        text: "Каково максимально допустимое значение давления при работающих предохранительных клапанах в сосуде с давлением менее 0,3 МПа?",
        answers: [
          "Превышающее разрешенное давление не более чем на 0,05 МПа",
          "Превышающее разрешенное давление не более чем на 10%",
          "Превышающее разрешенное давление не более чем на 15%",
          "Превышающее разрешенное давление не более чем на 25%",
        ],
        correctAnswers: [
          "Превышающее разрешенное давление не более чем на 0,05 МПа",
        ],
      },
      {
        code: "66848",
        text: "В каком случае допускается эксплуатация компрессоров и насосов при отсутствии ограждения на муфте сцепления и клиноременных передач с электродвигателем?",
        answers: [
          "Не допускается ни в каком случае",
          "Если их отсутствие регламентируется производственной инструкцией",
          "Если получено указание технического руководителя (главного инженера)",
          "Если их отсутствие отражено в эксплуатационном журнале",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "66849",
        text: "В каком случае из указанных допускается наполнение баллонов газом?",
        answers: [
          "Если до истечения срока технического освидетельствования остается 1 месяц",
          "Если поврежден корпус баллона",
          "Если отсутствуют установленные клейма",
          "Если отсутствует избыточное давление газа",
        ],
        correctAnswers: [
          "Если до истечения срока технического освидетельствования остается 1 месяц",
        ],
      },
      {
        code: "66850",
        text: "Какие из перечисленных сведений не заносятся в технический паспорт объекта, использующего СУГ?",
        answers: [
          "Сведения о техническом обслуживании",
          "Сведения о капитальном ремонте",
          "Сведения о консервации",
          "Все перечисленные сведения заносятся в технический паспорт объекта",
        ],
        correctAnswers: ["Сведения о техническом обслуживании"],
      },
      {
        code: "66851",
        text: "С какой периодичностью проводятся контроль герметичности приборов, импульсных трубопроводов и арматуры одновременно с проверкой герметичности технических устройств?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 2 раз в месяц",
          "Не реже 1 раза в неделю",
          "Не реже 1 раза в 3 месяца",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "66852",
        text: "Каково максимально допустимое значение давления при работающих предохранительных клапанах в сосуде с давлением более 6 МПа?",
        answers: [
          "Превышающее разрешенное давление не более чем на 25%",
          "Превышающее разрешенное давление не более чем на 0,05 МПа",
          "Превышающее разрешенное давление не более чем на 10%",
          "Превышающее разрешенное давление не более чем на 15%",
        ],
        correctAnswers: [
          "Превышающее разрешенное давление не более чем на 10%",
        ],
      },
      {
        code: "66853",
        text: "На основании какого документа осуществляется пуск (включение) в работу и штатная остановка сосуда?",
        answers: [
          "На основании письменного распоряжения ответственного за исправное состояние и безопасную эксплуатацию сосуда",
          "На основании письменного распоряжения ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования, работающего под давлением",
          "На основании приказа руководителя эксплуатирующей организации",
        ],
        correctAnswers: [
          "На основании письменного распоряжения ответственного за исправное состояние и безопасную эксплуатацию сосуда",
        ],
      },
      {
        code: "66854",
        text: "Существует ли разница в нанесении надписей на баллонах вместимостью более 12 литров и до 12 литров?",
        answers: [
          "Существует, размеры надписей и полос на баллонах вместимостью до 12 л должны определяться в зависимости от величины боковой поверхности баллонов",
          "Разницы нет, надписи наносятся по окружности на длину не менее 1/3 окружности, а полосы - по всей окружности",
          "Существует, размеры надписей на баллонах вместимостью до 12 л наносят на длину не менее 1/2 окружности, а для баллонов более 12 л - на длину не менее 1/3 окружности",
          "Разница только в высоте букв: на баллонах вместимостью до 12 л высота букв должна быть 40 мм, а на баллонах более 12 л - 60 мм",
        ],
        correctAnswers: [
          "Существует, размеры надписей и полос на баллонах вместимостью до 12 л должны определяться в зависимости от величины боковой поверхности баллонов",
        ],
      },
      {
        code: "66855",
        text: "При каком условии допускается дальнейшая эксплуатация забракованных баллонов?",
        answers: [
          "Эксплуатация не допускается, они должны быть приведены в негодность независимо от их назначения любым способом, исключающим возможность их дальнейшего использования, и утилизированы согласно требованиям руководства (инструкции) по эксплуатации",
          "Дальнейшая эксплуатация допускается при величине 0,75 рабочего давления",
          "Дальнейшая эксплуатация допускается только для баллонов специального назначения при условии ежедневного наружного осмотра",
          "Дальнейшая эксплуатация допускается при заполнении баллонов не более чем на 50% их рабочего объема",
        ],
        correctAnswers: [
          "Эксплуатация не допускается, они должны быть приведены в негодность независимо от их назначения любым способом, исключающим возможность их дальнейшего использования, и утилизированы согласно требованиям руководства (инструкции) по эксплуатации",
        ],
      },
      {
        code: "66856",
        text: "Какие сведения из указанных не наносятся на сферическую часть баллона?",
        answers: [
          "Сведения, подлежащие нанесению в соответствии с требованиями ТР ТС 032/2013",
          "Дата проведенного и следующего технического освидетельствования баллона",
          "Клеймо организации (индивидуального предпринимателя), проводившей техническое освидетельствование",
          "Сведения о наполнительной станции: дата наполнения и клеймо организации, проводившей наполнение баллона",
        ],
        correctAnswers: [
          "Сведения о наполнительной станции: дата наполнения и клеймо организации, проводившей наполнение баллона",
        ],
      },
      {
        code: "66857",
        text: "Для каких баллонов допустимая погрешность составляет +/- двадцать граммов?",
        answers: [
          "Для баллонов вместимостью 5 л",
          "Для баллонов вместимостью 1 л",
          "Для баллонов вместимостью 27 л",
          "Для баллонов вместимостью 50 л",
        ],
        correctAnswers: ["Для баллонов вместимостью 5 л"],
      },
      {
        code: "66858",
        text: "Какое из приведенных требований к манометрам, устанавливаемым на сосудах, указано неверно?",
        answers: [
          "Манометр должен быть выбран с такой шкалой, чтобы предел измерения рабочего давления находился во второй трети шкалы",
          "Взамен красной черты разрешается в качестве указателя значения максимально допустимого давления прикреплять к корпусу манометра пластину (скобу) из металла или иного материала достаточной прочности, окрашенную в красный цвет и плотно прилегающую к стеклу манометра",
          "На шкале манометра владельцем сосуда должна быть нанесена красная черта, указывающая разрешенное давление в сосуде",
          "Все приведенные требования верны",
        ],
        correctAnswers: [
          "На шкале манометра владельцем сосуда должна быть нанесена красная черта, указывающая разрешенное давление в сосуде",
        ],
      },
      {
        code: "66859",
        text: "Каково минимальное значение избыточного давления газа, которое должно оставаться в опорожняемых потребителем цистернах и бочках?",
        answers: [
          "0,07 МПа",
          "0,05 МПа",
          "Значение избыточного давления указывается в производственной инструкции организации, осуществляющей наполнение цистерн и бочек",
          "Потребитель, опорожняя цистерны и бочки, обязан срабатывать давление полностью, не оставляя в порожнем оборудовании избыточное давление",
        ],
        correctAnswers: ["0,05 МПа"],
      },
      {
        code: "66860",
        text: "Какое из перечисленных действий допускается при эксплуатации насосов, компрессоров и испарителей?",
        answers: [
          "Передача вращения от двигателя к насосу и компрессору через эластичные муфты или при помощи клиновидных ременных передач",
          "Устройство приямков, подпольных каналов в помещении НКО",
          "Эксплуатация испарителей при попадании жидкой фазы в газопровод паровой фазы",
          "Продувка и дренирование насосов, компрессоров и трубопроводов в насосно-компрессорном помещении",
          "Работа компрессоров и насосов без манометров",
        ],
        correctAnswers: [
          "Передача вращения от двигателя к насосу и компрессору через эластичные муфты или при помощи клиновидных ременных передач",
        ],
      },
      {
        code: "66861",
        text: "Кому сообщается о выявленных неисправностях в работе средств измерений и автоматики безопасности?",
        answers: [
          "Техническому руководителю (главному инженеру) объекта, использующего СУГ",
          "Руководителю пусконаладочной бригады",
          "Лицу, ответственному за безопасную эксплуатацию объекта, использующего СУГ",
          "Руководителю объекта, использующего СУГ",
        ],
        correctAnswers: [
          "Техническому руководителю (главному инженеру) объекта, использующего СУГ",
        ],
      },
      {
        code: "66862",
        text: "Каким образом баллоны, из которых невозможно выпустить газ из-за неисправности вентилей на месте потребления, возвращаются на наполнительную станцию?",
        answers: [
          "Вместе с пустыми (порожними) баллонами с нанесением на них соответствующей временной надписи (маркировки) любым доступным способом, не нарушающим целостность корпуса баллона",
          "Отдельно от пустых (порожних) баллонов с нанесением на них соответствующей временной надписи (маркировки) любым доступным способом, не нарушающим целостность корпуса баллона",
          "Любым способом, обеспечивающим безопасность их транспортирования",
        ],
        correctAnswers: [
          "Отдельно от пустых (порожних) баллонов с нанесением на них соответствующей временной надписи (маркировки) любым доступным способом, не нарушающим целостность корпуса баллона",
        ],
      },
      {
        code: "66863",
        text: "С какой периодичностью должна производиться проверка параметров настройки предохранительных сбросных клапанов резервуаров и регулировка на стенде или по месту с помощью специального приспособления?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в 12 месяцев",
        ],
        correctAnswers: ["Не реже 1 раза в 6 месяцев"],
      },
      {
        code: "66864",
        text: "Какое допускается максимальное давление настройки предохранительных сбросных клапанов резервуаров?",
        answers: [
          "Не более чем на 50% превышающее рабочее давление",
          "Не более чем на 10% превышающее рабочее давление",
          "Не более чем на 15% превышающее рабочее давление",
          "Не более чем на 25% превышающее рабочее давление",
        ],
        correctAnswers: ["Не более чем на 15% превышающее рабочее давление"],
      },
      {
        code: "66865",
        text: "В каком случае насосы и компрессоры в насосно-компрессорном отделении (НКО) допускается эксплуатировать без остановки при ремонтных работах, а также во время производства газоопасных работ в производственной зоне?",
        answers: [
          "При обеспечении дополнительных мер безопасности, исключающих угрозу жизни и здоровью работникам организации и третьим лицам",
          "В случае проведения работ не более 30 минут",
          "В случае присутствия в НКО руководителя организации, эксплуатирующей объект",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "При обеспечении дополнительных мер безопасности, исключающих угрозу жизни и здоровью работникам организации и третьим лицам",
        ],
      },
      {
        code: "66866",
        text: "Что из перечисленного должна предусматривать должностная инструкция лица, ответственного за безопасную эксплуатацию объектов, использующих СУГ?",
        answers: [
          "Разработку производственных инструкций, планов мероприятий по локализации и ликвидации последствий аварий",
          "Приостановку работы неисправных технических устройств",
          "Организацию и проведение тренировок специалистов и рабочих по ликвидации возможных аварий",
          "Выдачу руководителям и инженерно-техническим работникам предписаний по устранению нарушений требований Правил и контроль их выполнения",
          "Должна предусматривать все перечисленное",
        ],
        correctAnswers: ["Должна предусматривать все перечисленное"],
      },
      {
        code: "66867",
        text: "Что из перечисленного не входит в полномочия лиц, ответственных за безопасную эксплуатацию объектов, использующих СУГ?",
        answers: [
          "Требование отстранения от обслуживания технических устройств и выполнения газоопасных работ лиц, не прошедших проверку знаний или показавших неудовлетворительные знания Правил безопасности для объектов, использующих сжиженные углеводородные газы",
          "Осуществление контроля при строительстве, реконструкции, техническом перевооружении, консервации и ликвидации опасных производственных объектов",
          "Назначение ответственного лица за эксплуатацию вентиляционных систем",
          "Все перечисленное",
        ],
        correctAnswers: [
          "Назначение ответственного лица за эксплуатацию вентиляционных систем",
        ],
      },
      {
        code: "66868",
        text: "Какие мероприятия должны осуществляться с законсервированными наружными газопроводами?",
        answers: [
          "Периодические осмотры и приборный контроль",
          "Текущий и капитальный ремонт",
          "Наружный осмотр",
          "Все перечисленные мероприятия",
        ],
        correctAnswers: ["Наружный осмотр"],
      },
      {
        code: "66869",
        text: "Какое допускается давление газа в нагнетательном газопроводе компрессора?",
        answers: [
          "Не превышающее давления конденсации паров СУГ при температуре нагнетания и быть не более 0,7 Мпа",
          "Не превышающее давления конденсации паров СУГ при температуре нагнетания и быть не более 1,2 Мпа",
          "Не превышающее давления конденсации паров СУГ при температуре нагнетания и быть не более 1,6 Мпа",
          "Не превышающее давления конденсации паров СУГ при температуре нагнетания и быть не более 2,5 МПа",
        ],
        correctAnswers: [
          "Не превышающее давления конденсации паров СУГ при температуре нагнетания и быть не более 1,6 Мпа",
        ],
      },
      {
        code: "66870",
        text: "Каким должно быть давление газа на всасывающей линии насоса на объекте, использующем СУГ?",
        answers: [
          "На 0,5 - 0,6 МПа выше упругости насыщенных паров жидкой фазы при данной температуре",
          "На 0,4 - 0,6 МПа выше упругости насыщенных паров жидкой фазы при данной температуре",
          "На 0,1 - 0,2 МПа выше упругости насыщенных паров жидкой фазы при данной температуре",
          "На 1,1 - 1,2 МПа выше упругости насыщенных паров жидкой фазы при данной температуре",
        ],
        correctAnswers: [
          "На 0,1 - 0,2 МПа выше упругости насыщенных паров жидкой фазы при данной температуре",
        ],
      },
      {
        code: "66871",
        text: "Какая документация должна быть оформлена на объекте, использующем СУГ, при эксплуатации насосов, компрессоров и испарителей установок?",
        answers: [
          "Техническое описание оборудования, инструкции по эксплуатации, инструкции по охране труда, техническому обслуживанию, пуску, обкатке, ведомости запасных частей",
          "Акты индивидуальных испытаний",
          "Руководство по ремонту, предельные нормы износа быстроизнашивающихся деталей и узлов",
          "Описание технологического процесса и ремонта оборудования",
          "Вся перечисленная",
        ],
        correctAnswers: ["Вся перечисленная"],
      },
      {
        code: "66872",
        text: "Согласно какому документу должен производиться вывод компрессоров, насосов, испарителей из рабочего режима в резерв?",
        answers: [
          "Производственной инструкции",
          "Эксплуатационному журналу",
          "Проектной документации",
          "Инструкции по охране труда",
        ],
        correctAnswers: ["Производственной инструкции"],
      },
      {
        code: "66873",
        text: "В каких случаях компрессоры и насосы на объектах, использующих СУГ, должны быть остановлены?",
        answers: [
          "В случае повышения или понижения установленного давления газа во всасывающем и напорном газопроводе",
          "В случае утечек газа и неисправностей запорной арматуры",
          "В случае нарушений в работе систем вентиляции",
          "В случае появления вибрации, посторонних шумов и стуков",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "66874",
        text: "В каком случае при установке заглушек на газопроводах обвязки насоса, компрессора, испарителя, подлежащих ремонту, работа остальных насосов, компрессоров, испарителей может не прекращаться?",
        answers: [
          "По решению руководителя организации, эксплуатирующей объект, при обеспечении дополнительных мер безопасности, исключающих угрозу жизни и здоровью работникам организации и третьим лицам",
          "В любом случае работа оборудования должна быть прекращена без исключений",
          "В случае разработки обоснования безопасности и проведения его экспертизы промышленной безопасности",
          "В случае обеспечения работников, выполняющих данные работы, необходимыми средствами индивидуальной защиты",
        ],
        correctAnswers: [
          "По решению руководителя организации, эксплуатирующей объект, при обеспечении дополнительных мер безопасности, исключающих угрозу жизни и здоровью работникам организации и третьим лицам",
        ],
      },
      {
        code: "66875",
        text: "Какие действия, выполняемые перед пуском насосов, компрессоров и испарителей объектов, использующих СУГ, указаны неверно?",
        answers: [
          "За 5 минут до пуска насосов, компрессоров и испарителей включить приточно-вытяжную вентиляцию и проверить состояние воздушной среды в помещениях",
          "Проверить исправность и герметичность арматуры и газопроводов, предохранительных клапанов и КИП, исправность пусковых и заземляющих устройств",
          "Проверить исправность автоматики безопасности и блокировок",
          "Уточнить причины остановки оборудования (по журналу) и убедиться, что неисправность устранена",
          "Все перечисленные действия указаны верно",
        ],
        correctAnswers: [
          "За 5 минут до пуска насосов, компрессоров и испарителей включить приточно-вытяжную вентиляцию и проверить состояние воздушной среды в помещениях",
        ],
      },
      {
        code: "66876",
        text: "Какие из перечисленных действий, которые необходимо выполнить после включения электродвигателя компрессора, указаны неверно?",
        answers: [
          "Убедиться в правильном вращении вала по стрелке на передней крышке картера",
          "Открыть вентиль на нагнетательном патрубке компрессора при достижении номинального числа оборотов вала электродвигателя компрессора",
          "Постепенно открыть вентиль на всасывающем патрубке компрессора",
          "Все перечисленные действия указаны верно",
        ],
        correctAnswers: ["Все перечисленные действия указаны верно"],
      },
      {
        code: "66877",
        text: "Какие требования к эксплуатации вентиляционного оборудования указаны неверно?",
        answers: [
          "Вентиляционные системы должны быть определены по функциональным признакам (приточная, вытяжная, аварийная) с присвоением порядкового номера",
          "На вентиляционные системы должны составляться паспорта. В паспорт вносятся информация о производительности системы, ее схема, характеристика, тип вентилятора и электродвигателя, сведения о ремонтах и наладках",
          "На ГНС и ГНП решением главного механика назначается ответственное лицо за эксплуатацию вентиляционных систем",
          "Все перечисленные требования указаны верно",
        ],
        correctAnswers: [
          "На ГНС и ГНП решением главного механика назначается ответственное лицо за эксплуатацию вентиляционных систем",
        ],
      },
      {
        code: "66878",
        text: "Что из перечисленного должно быть организовано на объекте, использующем СУГ, согласно требованиям к эксплуатации вентиляционного оборудования?",
        answers: [
          "Учет времени работы вентиляционной установки",
          "Учет неисправностей при эксплуатации установки",
          "Учет технического обслуживания и ремонтов оборудования и элементов установки",
          "Должно быть организовано все перечисленное",
        ],
        correctAnswers: ["Должно быть организовано все перечисленное"],
      },
      {
        code: "66879",
        text: "Какие требования к сливу СУГ из цистерны автомобиля указаны неверно?",
        answers: [
          "Не занятый на операции слива персонал не должен находиться на месте производства работ",
          "Включать двигатель автомобиля допускается только после отсоединения шлангов от газопроводов и установки заглушек на сливные штуцера",
          "Во время слива СУГ нахождение водителя в кабине является обязательным",
          "Все перечисленные требования указаны верно",
        ],
        correctAnswers: [
          "Во время слива СУГ нахождение водителя в кабине является обязательным",
        ],
      },
      {
        code: "66880",
        text: "Какие требования к сливу СУГ из цистерны указаны неверно?",
        answers: [
          "По окончании слива запорная арматура на цистерне должна быть заглушена",
          "В период слива СУГ должен осуществляться контроль за давлением и уровнем СУГ в цистерне и приемном резервуаре",
          "Во время слива СУГ не допускается производить работы по уплотнению соединений, находящихся под давлением",
          "Все перечисленные требования указаны верно",
        ],
        correctAnswers: ["Все перечисленные требования указаны верно"],
      },
      {
        code: "66881",
        text: "С какой периодичностью следует наблюдать за осадкой фундаментов зданий, сооружений и оборудования в первые два года эксплуатации?",
        answers: [
          "Не реже 1 раза 3 месяца",
          "Не реже 1 раза 6 месяцев",
          "Не реже 1 раза 12 месяцев",
          "Не реже 1 раза 3 года",
        ],
        correctAnswers: ["Не реже 1 раза 3 месяца"],
      },
      {
        code: "66882",
        text: "Каким требованиям должны соответствовать заглушки, устанавливаемые на газопроводах СУГ?",
        answers: [
          "Должны быть рассчитаны на максимальное рабочее давление и иметь хвостовики, выступающие за пределы фланцев",
          "Должны быть рассчитаны на давление 1,6 МПа и иметь хвостовики, выступающие за пределы фланцев. На хвостовиках должны быть выбиты номер, марка стали, условный диаметр и давление",
          "Должны быть рассчитаны на давление 1,6 МПа и иметь хвостовики, выступающие за пределы фланцев",
          "Должны быть рассчитаны на давление 1,6 МПа и иметь хвостовики, выступающие за пределы фланцев. На хвостовиках должно быть выбито клеймо с указанием давления СУГ",
        ],
        correctAnswers: [
          "Должны быть рассчитаны на давление 1,6 МПа и иметь хвостовики, выступающие за пределы фланцев. На хвостовиках должны быть выбиты номер, марка стали, условный диаметр и давление",
        ],
      },
      {
        code: "66883",
        text: "При какой концентрации газа должны срабатывать газоанализаторы и сигнализаторы, установленные вне помещения?",
        answers: [
          "5% нижнего предела распространения пламени",
          "10% нижнего предела распространения пламени",
          "15% нижнего предела распространения пламени",
          "20% нижнего предела распространения пламени",
        ],
        correctAnswers: ["20% нижнего предела распространения пламени"],
      },
      {
        code: "66884",
        text: "При какой концентрации газа должны срабатывать газоанализаторы и сигнализаторы, установленные в помещении?",
        answers: [
          "5% нижнего предела распространения пламени",
          "10% нижнего предела распространения пламени",
          "15% нижнего предела распространения пламени",
          "20% нижнего предела распространения пламени",
        ],
        correctAnswers: ["10% нижнего предела распространения пламени"],
      },
      {
        code: "66885",
        text: "Какой длины должна быть ввариваемая катушка для ремонта поврежденных участков газопроводов?",
        answers: [
          "Длиной не менее 100 мм",
          "Длиной не менее 200 мм",
          "Длиной не менее 500 мм",
          "Длиной не менее 1 м",
        ],
        correctAnswers: ["Длиной не менее 200 мм"],
      },
      {
        code: "66886",
        text: "Какой документ оформляется на проведение газоопасных работ?",
        answers: [
          "Письменное разрешение",
          "Наряд-допуск",
          "Распоряжение",
          "План проведения работ",
        ],
        correctAnswers: ["Наряд-допуск"],
      },
      {
        code: "66887",
        text: "Какие газоопасные работы могут выполняться без оформления наряда-допуска?",
        answers: [
          "Периодически повторяющиеся газоопасные работы, являющиеся неотъемлемой частью технологического процесса, характеризующиеся аналогичными условиями их проведения, постоянством места и характера работ, определенным составом исполнителей",
          "Только газоопасные работы, связанные с осмотрами и проветриванием колодцев, и работы по устранению утечек СУГ и ликвидации аварий",
          "Все газоопасные работы должны выполняться с обязательным оформлением наряда-допуска",
        ],
        correctAnswers: [
          "Периодически повторяющиеся газоопасные работы, являющиеся неотъемлемой частью технологического процесса, характеризующиеся аналогичными условиями их проведения, постоянством места и характера работ, определенным составом исполнителей",
        ],
      },
      {
        code: "66888",
        text: "Какая из перечисленных газоопасных работ может проводиться без оформления наряда-допуска по утвержденной производственной инструкции?",
        answers: [
          "Техническое обслуживание запорной арматуры, предохранительных клапанов и проверка параметров их настройки",
          "Проведение пусконаладочных работ",
          "Установка и снятие заглушек на действующих газопроводах",
          "Подготовка к техническому освидетельствованию резервуаров СУГ",
        ],
        correctAnswers: [
          "Техническое обслуживание запорной арматуры, предохранительных клапанов и проверка параметров их настройки",
        ],
      },
      {
        code: "66889",
        text: "Какая из перечисленных газоопасных работ проводится по наряду-допуску и специальному плану, утвержденному техническим руководителем объекта, использующего СУГ?",
        answers: [
          "Установка и снятие заглушек на действующих газопроводах",
          "Ремонт, осмотр и проветривание колодцев",
          "Раскопка грунта в местах утечки СУГ до ее устранения",
          "Расконсервация оборудования",
        ],
        correctAnswers: ["Расконсервация оборудования"],
      },
      {
        code: "66890",
        text: "В каком случае наряд-допуск на выполнение газоопасных работ может быть продлен?",
        answers: [
          "В любом случае, если работа не завершена",
          "Наряд-допуск не продлевается, при продлении сроков проведения работ текущий наряд-допуск закрывается, и при возобновлении работ оформляется новый",
          "Если работа не закончена, а условия ее проведения и характер не изменились",
          "Решение о продлении наряда-допуска принимается отдельно по каждому объекту",
        ],
        correctAnswers: [
          "Если работа не закончена, а условия ее проведения и характер не изменились",
        ],
      },
      {
        code: "66891",
        text: "Допускается ли проведение газоопасных работ, выполняемых по нарядам-допускам, в темное время суток?",
        answers: [
          "Нет, не допускается. Данные работы должны выполняться только в светлое время суток",
          "Допускается проведение газоопасных работ, выполняемых по нарядам-допускам, в любое время суток",
          "Допускается в исключительных случаях, если это неотложные работы, при условии выполнения дополнительных мероприятий по обеспечению безопасного проведения работ",
          "Допускается в исключительных случаях, если это неотложные работы",
        ],
        correctAnswers: [
          "Допускается в исключительных случаях, если это неотложные работы, при условии выполнения дополнительных мероприятий по обеспечению безопасного проведения работ",
        ],
      },
      {
        code: "66892",
        text: "Какова норма контрольной опрессовки газопроводов паровой фазы СУГ от резервуарной установки, внутренних газопроводов и газового оборудования для низкого давления?",
        answers: [
          "0,01 МПа. Допустимое падение давления за 1 час не должно превышать 0,002 МПа",
          "0,01 МПа. Допустимое падение давления за 1 час не должно превышать 0,0006 МПа",
          "0,01 МПа. Видимого падения давления по манометру быть не должно",
          "0,02 МПа. Допустимое падение давления за 1 час не должно превышать 0,0006 МПа",
        ],
        correctAnswers: [
          "0,01 МПа. Допустимое падение давления за 1 час не должно превышать 0,0006 МПа",
        ],
      },
      {
        code: "66893",
        text: "Какова норма контрольной опрессовки наружных и внутренних газопроводов паровой и жидкой фазы СУГ ГНС и ГНП, резервуаров СУГ, газопроводов обвязки?",
        answers: [
          "0,3 МПа в течение одного часа. Допустимое падение давления не должно превышать 0,0002 МПа",
          "0,3 МПа в течение одного часа. Видимого падения давления по манометру быть не должно",
          "0,03 МПа в течение одного часа. Видимого падения давления по манометру быть не должно",
          "0,01 МПа в течение одного часа. Допустимое падение давления не должно превышать 0,0006 МПа",
        ],
        correctAnswers: [
          "0,3 МПа в течение одного часа. Видимого падения давления по манометру быть не должно",
        ],
      },
      {
        code: "66894",
        text: "Кто дает распоряжения в процессе выполнения газоопасных работ?",
        answers: [
          "Технический руководитель объекта, использующего СУГ",
          "Лицо, ответственное за проведение газоопасной работы",
          "Руководитель объекта, использующего СУГ",
          "Лицо, ответственное за безопасную эксплуатацию объекта, использующего СУГ",
        ],
        correctAnswers: [
          "Лицо, ответственное за проведение газоопасной работы",
        ],
      },
      {
        code: "66895",
        text: "Какую величину не должна превышать объемная доля кислорода после окончания продувки газопроводов и оборудования СУГ парами СУГ?",
        answers: ["0,5%", "1%", "5%", "10%"],
        correctAnswers: ["1%"],
      },
      {
        code: "66896",
        text: "С какой периодичностью проводятся учебно-тренировочные занятия по локализации и ликвидации последствий аварий на объектах, использующих СУГ?",
        answers: [
          "Не реже 1 раза месяц",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
          "Ежесменно",
        ],
        correctAnswers: ["Не реже 1 раза в 3 месяца"],
      },
      {
        code: "66897",
        text: "Какой документ дает право на выполнение огневых работ?",
        answers: [
          "Наряд-допуск на проведение огневых работ и специальный план, утвержденный техническим руководителем объекта, использующего СУГ",
          "Распоряжение технического руководителя объекта, использующего СУГ",
          "План проведения огневых работ, согласованный со службой пожарной охраны",
          "Разрешение Ростехнадзора",
        ],
        correctAnswers: [
          "Наряд-допуск на проведение огневых работ и специальный план, утвержденный техническим руководителем объекта, использующего СУГ",
        ],
      },
      {
        code: "66898",
        text: "Можно ли проводить приемку СУГ, сливо-наливные операции при проведении огневых работ?",
        answers: [
          "Можно, если есть специальное разрешение Пожнадзора и Ростехнадзора",
          "Можно, если есть письменное разрешение руководителя объекта, использующего СУГ",
          "Можно при соблюдении требований безопасности",
          "Не допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "66899",
        text: "При какой концентрации паров СУГ в воздухе огневые работы должны быть приостановлены?",
        answers: [
          "Свыше 10% нижнего концентрационного предела распространения пламени",
          "Свыше 15% нижнего концентрационного предела распространения пламени",
          "Свыше 20% нижнего концентрационного предела распространения пламени",
          "Независимо от их концентрации",
        ],
        correctAnswers: ["Независимо от их концентрации"],
      },
      {
        code: "66900",
        text: "Какая вентиляция должна действовать при выполнении огневых работ в помещении?",
        answers: [
          "Приточно-вытяжная вентиляция",
          "Приточная вентиляция",
          "Естественная вентиляция",
          "Вытяжная вентиляция",
        ],
        correctAnswers: ["Приточно-вытяжная вентиляция"],
      },
      {
        code: "66901",
        text: "Какие из перечисленных видов работ относятся к газоопасным работам на объектах, использующих СУГ?",
        answers: [
          "Все перечисленные виды работ",
          "Проведение пусконаладочных работ",
          "Удаление закупорок",
          "Раскопка грунта в местах утечки СУГ до ее устранения",
        ],
        correctAnswers: ["Все перечисленные виды работ"],
      },
      {
        code: "66902",
        text: "По чьему указанию производят снятие заглушек после контрольной опрессовки отключенного участка газопровода и оборудования?",
        answers: [
          "Руководителя газоопасных работ",
          "Руководителя объекта, использующего СУГ",
          "Лица, ответственного за безопасную эксплуатацию объекта, использующего СУГ",
          "Технического руководителя (главного инженера) объекта, использующего СУГ",
        ],
        correctAnswers: ["Руководителя газоопасных работ"],
      },
      {
        code: "66903",
        text: "Какое из перечисленных требований к проведению работ в колодцах указано верно?",
        answers: [
          "В колодцах разрешается нахождение не более 3 рабочих в спасательных поясах и шланговых противогазах",
          "Для спуска в колодцы, не имеющие скоб, должны применяться веревочные лестницы",
          "При продолжении работ более 30 минут необходимо периодически проводить проверку загазованности и вентиляцию колодца",
          "С наветренной стороны должны быть 2 человека, которые обязаны держать концы веревок от спасательных поясов рабочих, находящихся внутри колодца, вести наблюдение за ними и не допускать к месту работ посторонних лиц",
        ],
        correctAnswers: [
          "С наветренной стороны должны быть 2 человека, которые обязаны держать концы веревок от спасательных поясов рабочих, находящихся внутри колодца, вести наблюдение за ними и не допускать к месту работ посторонних лиц",
        ],
      },
      {
        code: "66904",
        text: "Какие из перечисленных работ допускается производить в темное время суток?",
        answers: [
          "Все перечисленные",
          "Техническое обслуживание технических устройств",
          "Ремонт технических устройств",
          "Аварийно-восстановительные работы",
        ],
        correctAnswers: ["Аварийно-восстановительные работы"],
      },
      {
        code: "66905",
        text: "В каком документе отражаются результаты контроля отбора проб, а также интенсивность запаха СУГ (одоризация)?",
        answers: [
          "В наряде-допуске",
          "В эксплуатационном журнале",
          "В акте проведения индивидуального опробования технологической системы",
          "В акте индивидуальных испытаний",
        ],
        correctAnswers: ["В эксплуатационном журнале"],
      },
      {
        code: "66906",
        text: "Кем утверждаются графики, согласно которым газопроводы и установленная на них арматура должны подвергаться ремонтам?",
        answers: [
          "Техническим руководителем (главным инженером) объекта",
          "Руководителем организации, эксплуатирующей объект",
          "Руководителем строительно-монтажной организации",
          "Лицом, ответственным за исправное состояние и безопасную эксплуатацию резервуаров",
        ],
        correctAnswers: [
          "Техническим руководителем (главным инженером) объекта",
        ],
      },
      {
        code: "66907",
        text: "Что из перечисленного не должно выполняться ежесменно при техническом обслуживании компрессоров и насосов?",
        answers: [
          "Осмотр компрессоров и насосов, запорной и предохранительной арматуры, средств измерений, автоматики и блокировок в целях выявления неисправностей и утечек СУГ",
          "Очистка компрессоров, насосов и КИП от пыли и загрязнений, проверка наличия и исправности заземления и креплений",
          "Контроль за исправным состоянием и положением запорной арматуры и предохранительных клапанов",
          "Проверка исправности доступных для осмотра движущихся частей",
          "Осмотр всех наружных газопроводов и арматуры",
        ],
        correctAnswers: ["Осмотр всех наружных газопроводов и арматуры"],
      },
      {
        code: "66908",
        text: "Кем утверждается график технического обслуживания вентиляционных установок объекта, использующего СУГ?",
        answers: [
          "Руководителем объекта, использующего СУГ",
          "Лицом, ответственным за проведения газоопасных работ",
          "Техническим руководителем (главным инженером)",
          "Лицом, ответственным за эксплуатацию вентиляционных систем",
        ],
        correctAnswers: ["Техническим руководителем (главным инженером)"],
      },
      {
        code: "66909",
        text: "Какие работы не проводятся при техническом обслуживании вентиляционных установок?",
        answers: [
          "Контроль за температурой подшипников электродвигателей и проверка наличия заземления последних",
          "Контроль за параметрами воздуха, нагнетаемого в помещение",
          "Проверка ограждений вращающихся частей",
          "Определение загрязненности фильтров, пластин и секций у калориферов и проверка заполнения кассет фильтрующим материалом",
          "Разборка и чистка электродвигателей",
        ],
        correctAnswers: ["Разборка и чистка электродвигателей"],
      },
      {
        code: "66910",
        text: "С какой периодичностью должно проводиться техническое обслуживание с записью в журнале венткамер и помещений категории А по пожарной безопасности?",
        answers: ["Ежесменно", "Еженедельно", "Ежемесячно", "1 раз в квартал"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "66911",
        text: "С какой периодичностью должен проводиться текущий ремонт противопожарных нормально открытых клапанов и обратных клапанов с записью в паспорт вентсистемы?",
        answers: [
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в 12 месяцев",
        ],
        correctAnswers: ["Не реже 1 раза в 12 месяцев"],
      },
      {
        code: "66912",
        text: "Что из перечисленного должно выполняться по графику, утвержденному руководителем объекта, использующего СУГ?",
        answers: [
          "Проверка предохранительных клапанов на срабатывание при давлении настройки",
          "Осмотр и профилактика задвижек, кранов и вентилей",
          "Слив конденсата из резервуаров через дренажные устройства",
          "Должно выполняться все перечисленное",
        ],
        correctAnswers: ["Должно выполняться все перечисленное"],
      },
      {
        code: "66913",
        text: "Что включает в себя техническое обслуживание наполнительных установок?",
        answers: [
          "Осмотр и очистку фильтров",
          "Смазку трущихся частей и перенабивку сальников",
          "Проверку плотности закрытия клапана струбцины",
          "Проверку правильности настройки сбросного клапана",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "66914",
        text: "С какой периодичностью проводится проверка работы предохранительных клапанов в рамках технического обслуживания наполнительных установок?",
        answers: [
          "Один раз в месяц",
          "Один раз в квартал",
          "Один раз в полгода",
          "Один раз в неделю",
        ],
        correctAnswers: ["Один раз в месяц"],
      },
    ],
    696: [
      {
        code: "67386",
        text: "Представители какого федерального органа исполнительной власти не входят в состав комиссии по приемке сетей газораспределения и газопотребления в эксплуатацию?",
        answers: ["Минэнерго России", "Ростехнадзора", "Росприроднадзора"],
        correctAnswers: ["Минэнерго России"],
      },
      {
        code: "67387",
        text: "Какие охранные зоны установлены Правилами охраны газораспределительных сетей вокруг отдельно стоящих газорегуляторных пунктов?",
        answers: [
          "В виде территории, ограниченной замкнутой линией, проведенной на расстоянии 10 м от границ этих объектов",
          "Охранная зона не регламентируется",
          "В виде территории, ограниченной замкнутой линией, проведенной на расстоянии 3 м от границ этих объектов",
        ],
        correctAnswers: [
          "В виде территории, ограниченной замкнутой линией, проведенной на расстоянии 10 м от границ этих объектов",
        ],
      },
      {
        code: "67388",
        text: "Какие охранные зоны установлены Правилами охраны газораспределительных сетей вдоль трасс подземных стальных газопроводов?",
        answers: [
          "В виде территории, ограниченной условными линиями, проходящими на расстоянии 1 м с каждой стороны газопровода",
          "В виде территории, ограниченной условными линиями, проходящими на расстоянии 3 м с каждой стороны газопровода",
          "В виде территории, ограниченной условными линиями, проходящими на расстоянии 2 м от газопровода с каждой стороны газопровода",
        ],
        correctAnswers: [
          "В виде территории, ограниченной условными линиями, проходящими на расстоянии 2 м от газопровода с каждой стороны газопровода",
        ],
      },
      {
        code: "67389",
        text: "К какой категории относятся газопроводы с давлением газа свыше 0,6 до 1,2 МПа включительно?",
        answers: [
          "Высокого давления 1 категории",
          "Высокого давления 2 категории",
          "Среднего давления",
          "Высокого давления 1а категории",
        ],
        correctAnswers: ["Высокого давления 1 категории"],
      },
      {
        code: "67390",
        text: "К какой категории относятся газопроводы с давлением газа свыше 0,3 до 0,6 МПа включительно?",
        answers: [
          "Высокого давления 1а категории",
          "Высокого давления 2 категории",
          "Среднего давления",
          "Низкого давления",
        ],
        correctAnswers: ["Высокого давления 2 категории"],
      },
      {
        code: "67391",
        text: "К какой категории относятся газопроводы с давлением газа свыше 0,005 до 0,3 МПа включительно?",
        answers: [
          "Высокого давления 1 категории",
          "Высокого давления 1а категории",
          "Среднего давления",
          "Низкого давления",
        ],
        correctAnswers: ["Среднего давления"],
      },
      {
        code: "67392",
        text: "К какой категории относятся газопроводы с давлением газа до 0,005 МПа включительно?",
        answers: [
          "Высокого давления 1а категории",
          "Высокого давления 2 категории",
          "Среднего давления",
          "Низкого давления",
        ],
        correctAnswers: ["Низкого давления"],
      },
      {
        code: "67393",
        text: "На какие сети, а также на связанные с ними процессы проектирования, строительства, реконструкции, монтажа, эксплуатации (включая техническое обслуживание, текущий ремонт), капитального ремонта, консервации и ликвидации требования Технического регламента о безопасности сетей газораспределения и газопотребления не распространяются?",
        answers: [
          "На сети газораспределения и газопотребления общественных и бытовых зданий",
          "На сети газораспределения жилых зданий",
          "На сети газопотребления жилых зданий",
          "На сети газопотребления парогазовых и газотурбинных установок давлением свыше 1,2 МПа",
        ],
        correctAnswers: ["На сети газопотребления жилых зданий"],
      },
      {
        code: "67394",
        text: "Что из перечисленного не входит в состав сети газораспределения?",
        answers: [
          "Наружные газопроводы",
          "Сооружения",
          "Технические и технологические устройства",
          "Внутренние газопроводы",
        ],
        correctAnswers: ["Внутренние газопроводы"],
      },
      {
        code: "67395",
        text: "Для чего предназначен продувочный газопровод?",
        answers: [
          "Для вытеснения газа или воздуха (по условиям эксплуатации) из газопроводов и технических устройств",
          "Для транспортирования природного газа",
          "Для отвода природного газа от предохранительных сбросных клапанов",
          "Для вытеснения природного газа из газопровода и технических устройств газа при их отключении",
        ],
        correctAnswers: [
          "Для вытеснения газа или воздуха (по условиям эксплуатации) из газопроводов и технических устройств",
        ],
      },
      {
        code: "67396",
        text: "По каким существенным признакам сети газораспределения и газопотребления идентифицируются в качестве объекта технического регулирования Технического регламента о безопасности сетей газораспределения и газопотребления?",
        answers: [
          "Только по назначению",
          "Только по составу объектов, входящих в сети газораспределения и газопотребления",
          "Только по давлению газа, определенному в техническом регламенте",
          "По всем указанным признакам, рассматриваемым исключительно в совокупности",
        ],
        correctAnswers: [
          "По всем указанным признакам, рассматриваемым исключительно в совокупности",
        ],
      },
      {
        code: "67397",
        text: "В каком из приведенных случаев объект технического регулирования идентифицируется в качестве сети газораспределения?",
        answers: [
          "Если объект транспортирует природный газ по территориям населенных пунктов с давлением, не превышающим 1,2 МПа",
          "Если объект транспортирует природный газ к газотурбинным и парогазовым установкам с давлением, не превышающим 2,5 МПа",
          "Если объект транспортирует природный газ к газоиспользующему оборудованию газифицируемых зданий с давлением, не превышающим 1,2 МПа",
        ],
        correctAnswers: [
          "Если объект транспортирует природный газ по территориям населенных пунктов с давлением, не превышающим 1,2 МПа",
        ],
      },
      {
        code: "67398",
        text: "В каком из приведенных случаев объект технического регулирования идентифицируется в качестве сети газопотребления?",
        answers: [
          "Если объект транспортирует природный газ между населенными пунктами с давлением, превышающим 0,005 МПа",
          "Если объект транспортирует природный газ по территориям населенных пунктов исключительно к производственным площадкам, на которых размещены газотурбинные и парогазовые установки, с давлением, превышающим 1,2 МПа",
          "Если объект транспортирует природный газ к газоиспользующему оборудованию, размещенному вне зданий, с давлением, не превышающим 1,2 МПа",
        ],
        correctAnswers: [
          "Если объект транспортирует природный газ к газоиспользующему оборудованию, размещенному вне зданий, с давлением, не превышающим 1,2 МПа",
        ],
      },
      {
        code: "67399",
        text: "Что должны обеспечить сети газораспределения и газопотребления как объекты технического регулирования?",
        answers: [
          "Безопасность и энергетическую эффективность транспортирования природного газа с параметрами по давлению и расходу, определенными проектной документацией и условиями эксплуатации",
          "Экономическую эффективность применения природного газа с параметрами по давлению и расходу, определенными эксплуатирующей документацией",
          "Экологическую безопасность сжигания природного газа в газоиспользующих установках с параметрами по давлению и расходу, определенными проектной документацией",
        ],
        correctAnswers: [
          "Безопасность и энергетическую эффективность транспортирования природного газа с параметрами по давлению и расходу, определенными проектной документацией и условиями эксплуатации",
        ],
      },
      {
        code: "67400",
        text: "Исходя из каких приведенных условий должны определяться места размещения сбросных и продувочных газопроводов?",
        answers: [
          "Места размещения сбросных и продувочных газопроводов должны определяться проектной документацией",
          "Максимальное рассеивание вредных веществ, при этом их концентрация в атмосфере не должна превышать более чем на 10% предельно допустимую максимальную разовую концентрацию",
          "Максимально быстрое удаление газов из сбросных и продувочных газопроводов",
          "Максимальное рассеивание вредных веществ, при этом их концентрация в атмосфере не должна превышать предельно допустимую максимальную разовую концентрацию",
        ],
        correctAnswers: [
          "Максимальное рассеивание вредных веществ, при этом их концентрация в атмосфере не должна превышать предельно допустимую максимальную разовую концентрацию",
        ],
      },
      {
        code: "67401",
        text: "Чем должны оснащаться предохранительные сбросные клапаны технологических устройств?",
        answers: [
          "Сбросными газопроводами",
          "Последовательно установленными обратным клапаном и задвижкой",
          "Звуковой и световой сигнализацией",
        ],
        correctAnswers: ["Сбросными газопроводами"],
      },
      {
        code: "67402",
        text: "Что из перечисленного должны обеспечивать количество, места размещения и вид запорной трубопроводной арматуры на внутренних газопроводах?",
        answers: [
          "Должны обеспечивать все перечисленное",
          "Только возможность отключения газоиспользующего оборудования для его ремонта или замены",
          "Только возможность отключения участков сети газопотребления для проведения ремонта газоиспользующего оборудования и технических устройств",
          "Только возможность отключения участка газопровода для демонтажа и последующей установки технических устройств при необходимости их ремонта или поверки",
        ],
        correctAnswers: ["Должны обеспечивать все перечисленное"],
      },
      {
        code: "67403",
        text: "Какой нормативный документ регламентирует границы зон с особыми условиями использования территории вдоль трассы газопроводов и вокруг других объектов газораспределительной сети, которые должны быть указаны в проектной документации на сети газораспределения?",
        answers: [
          'ФНП "Правила безопасности сетей газораспределения и газопотребления"',
          "Технический регламент о безопасности сетей газораспределения и газопотребления",
          "Правила охраны газораспределительных сетей",
          "СП 62.13330.2011*. Свод правил. Газораспределительные системы. Актуализированная редакция СНиП 42-01-2002 С изменением N 1",
        ],
        correctAnswers: ["Правила охраны газораспределительных сетей"],
      },
      {
        code: "67404",
        text: "Какие охранные зоны установлены Правилами охраны газораспределительных сетей вдоль трасс подземных газопроводов из полиэтиленовых труб, для обозначения трасс которых используется медный провод?",
        answers: [
          "В виде территории, ограниченной условными линиями, проходящими на расстоянии 2 м с каждой стороны газопровода",
          "В виде территории, ограниченной условными линиями, проходящими на расстоянии 3 м от газопровода со стороны провода и 2 м - с противоположной стороны",
          "В виде территории, ограниченной условными линиями, проходящими на расстоянии 3 м с каждой стороны газопровода",
        ],
        correctAnswers: [
          "В виде территории, ограниченной условными линиями, проходящими на расстоянии 3 м от газопровода со стороны провода и 2 м - с противоположной стороны",
        ],
      },
      {
        code: "67405",
        text: "На какие технологические объекты распространяется СП 62.13330.2011. Свод правил. Газораспределительные системы?",
        answers: [
          "На сети газораспределения, газопотребления и объекты СУГ, предназначенные для обеспечения потребителей природным газом давлением до 1,2 МПа включительно и сжиженными углеводородными газами давлением до 1,6 МПа включительно",
          "На магистральные газопроводы давлением свыше 1,2 до 10 МПа включительно",
          "На все перечисленные технологические объекты",
          "На технологические газопроводы, предназначенные для транспортирования газа в пределах химических, нефтехимических и металлургических (кроме производств, где получаются расплавы и сплавы цветных металлов) организаций, на которых природный газ используется в качестве сырья",
        ],
        correctAnswers: [
          "На сети газораспределения, газопотребления и объекты СУГ, предназначенные для обеспечения потребителей природным газом давлением до 1,2 МПа включительно и сжиженными углеводородными газами давлением до 1,6 МПа включительно",
        ],
      },
      {
        code: "67406",
        text: 'Какой из перечисленных терминов соответствует определению "устройство, автоматически поддерживающее рабочее давление газа, необходимое для оптимальной работы газоиспользующего оборудования" согласно СП 62.13330.2011. Свод правил. Газораспределительные системы?',
        answers: [
          "Клапан безопасности (контроллер) расхода газа",
          "Регулятор-стабилизатор",
          "Контрольно-измерительный прибор",
          "Регулятор-монитор",
        ],
        correctAnswers: ["Регулятор-стабилизатор"],
      },
      {
        code: "67407",
        text: "Какие соединения должны применяться для внутренних полимерных многослойных труб?",
        answers: [
          "Соединения, выполненные прессованием",
          "Тавровые соединения",
          "Нахлесточные соединения",
          "Соединения, выполненные высокотемпературной капиллярной пайкой",
        ],
        correctAnswers: ["Соединения, выполненные прессованием"],
      },
      {
        code: "67408",
        text: "Какое из перечисленных требований к прокладке внутренних газопроводов противоречит «СП 62.13330.2011. Свод правил. Газораспределительные системы»?",
        answers: [
          "Скрытая прокладка газопроводов из многослойных металлополимерных труб может производиться с последующей штукатуркой стен",
          "При скрытой прокладке стальных и медных труб необходимо обеспечить вентиляцию штрабы и доступ к газопроводу в процессе эксплуатации",
          "В производственных помещениях допускается скрытая прокладка газовых шлангов и газопроводов СУГ",
          "В местах пересечения строительных конструкций зданий газопроводы рекомендуется прокладывать в футлярах",
          "Трубы в штрабе рекомендуется прокладывать монолитно или свободно (при условии принятия мер по уплотнению штрабы)",
        ],
        correctAnswers: [
          "В производственных помещениях допускается скрытая прокладка газовых шлангов и газопроводов СУГ",
        ],
      },
      {
        code: "67409",
        text: "В каком случае разрешается вмешательство в деятельность, связанную с обеспечением безопасной эксплуатации газораспределительных сетей, не уполномоченных на то органов исполнительной власти субъектов Российской Федерации, органов местного самоуправления, общественных организаций, юридических и физических лиц?",
        answers: [
          "В случае возникновения аварийных ситуаций",
          "В случае устранения утечек газа",
          "Все ответы неверны",
          "В случае необходимости проведения ремонтных работ для предотвращения разрушения газораспределительной сети",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "67410",
        text: "Каким образом могут производиться работы по предотвращению аварий или ликвидации их последствий на газопроводах эксплуатационной организацией газораспределительной сети?",
        answers: [
          "Без согласования с собственниками, владельцами или пользователями земельных участков, но с уведомлением их о проводимых работах",
          "Без согласования с органами исполнительной власти субъектов Российской Федерации, но с уведомлением их о проводимых работах",
          "Без согласования с органами местного самоуправления, но с уведомлением их о проводимых работах",
          "Без согласования с кем-либо о проводимых работах",
        ],
        correctAnswers: [
          "Без согласования с собственниками, владельцами или пользователями земельных участков, но с уведомлением их о проводимых работах",
        ],
      },
      {
        code: "67411",
        text: "Кем утверждаются границы охранных зон газораспределительных сетей и наложение ограничений (обременений) на входящие в них земельные участки для проектируемых газораспределительных сетей?",
        answers: [
          "Органами исполнительной власти субъектов Российской Федерации по согласованию с собственниками, владельцами или пользователями земельных участков",
          "Органами местного самоуправления без согласования с собственниками, владельцами или пользователями земельных участков",
          "Собственниками, владельцами или пользователями земельных участков",
          "Органами исполнительной власти субъектов Российской Федерации без согласования с собственниками, владельцами или пользователями земельных участков",
        ],
        correctAnswers: [
          "Органами исполнительной власти субъектов Российской Федерации по согласованию с собственниками, владельцами или пользователями земельных участков",
        ],
      },
      {
        code: "67412",
        text: "Какой шириной должны создаваться минерализованные полосы по границам просек эксплуатационными организациями за свой счет при прохождении охранных зон газораспределительных сетей по лесам и древесно-кустарниковой растительности?",
        answers: [
          "Не менее 2,0 м",
          "Не менее 1,4 м",
          "Не менее 1,8 м",
          "Не менее 1,0 м",
        ],
        correctAnswers: ["Не менее 1,4 м"],
      },
      {
        code: "67413",
        text: "Какой из перечисленных терминов относится к газопроводу от места присоединения к распределительному газопроводу до отключающего устройства или наружной конструкции здания либо сооружения потребителя газа согласно Правилам охраны газораспределительных сетей?",
        answers: [
          "Газопровод-ввод",
          "Трасса газопровода",
          "Распределительный газопровод",
          "Газорегуляторный пункт",
        ],
        correctAnswers: ["Газопровод-ввод"],
      },
      {
        code: "67414",
        text: "Какой документ устанавливает предельные сроки эксплуатации газопроводов, зданий и сооружений, технических и технологических устройств, по истечении которых должно быть обеспечено их техническое диагностирование?",
        answers: [
          "Технический регламент о безопасности сетей газораспределения и газопотребления",
          "Проектная документация",
          'ФНП "Правила безопасности сетей газораспределения и газопотребления"',
          "Эксплуатационная документация",
        ],
        correctAnswers: ["Проектная документация"],
      },
      {
        code: "67415",
        text: 'На какие организации требования ФНП "Правила безопасности сетей газораспределения и газопотребления" не распространяются?',
        answers: [
          "Осуществляющие деятельность по проектированию, строительству и реконструкции сетей газораспределения и газопотребления",
          "Осуществляющие деятельность по эксплуатации, консервации и ликвидации сетей газораспределения и газопотребления",
          "Осуществляющие деятельность по техническому перевооружению и ремонту сетей газораспределения и газопотребления",
        ],
        correctAnswers: [
          "Осуществляющие деятельность по проектированию, строительству и реконструкции сетей газораспределения и газопотребления",
        ],
      },
      {
        code: "67416",
        text: "Что из перечисленного не относится к материалам идентификации объектов технического регулирования?",
        answers: [
          "Разрешение на ввод в эксплуатацию",
          "Сведения о сетях газораспределения и газопотребления, содержащиеся в Едином государственном реестре недвижимости",
          "Разрешение на строительство",
          "Заключение экспертизы декларации промышленной безопасности",
        ],
        correctAnswers: [
          "Заключение экспертизы декларации промышленной безопасности",
        ],
      },
      {
        code: "67417",
        text: "Где указываются сроки эксплуатации газопроводов, технических и технологических устройств сетей газораспределения и газопотребления ТЭС?",
        answers: [
          "В проектной документации",
          "В техническом регламенте",
          "В распорядительной документации организации",
          "В Правилах безопасности сетей газораспределения и газопотребления",
        ],
        correctAnswers: ["В проектной документации"],
      },
      {
        code: "67418",
        text: "Кто утверждает графики ремонтов газопроводов и технических устройств сетей газораспределения и газопотребления ТЭС?",
        answers: [
          "Технический руководитель ТЭС",
          "Руководитель ТЭС",
          "Руководитель ремонтной бригады",
          "Комиссия ТЭС",
        ],
        correctAnswers: ["Технический руководитель ТЭС"],
      },
      {
        code: "67419",
        text: "Какое количество сварных стыков от общего числа стыков, сваренных каждым сварщиком на подземных газопроводах давлением до 0,1 МПа, прокладываемых на расстоянии от фундаментов зданий менее 2 м, подлежит контролю физическими методами?",
        answers: [
          "100%",
          "50% от общего числа стыков, но не менее одного стыка",
          "25% от общего числа стыков, но не менее одного стыка",
          "10% от общего числа стыков, но не менее одного стыка",
        ],
        correctAnswers: ["100%"],
      },
      {
        code: "67420",
        text: "На основании чего производятся работы, связанные с обработкой почвы на глубину более 0,3 м, собственниками, владельцами или пользователями земельных участков в охранной зоне газораспределительной сети?",
        answers: [
          "На основании письменного разрешения эксплуатационной организации газораспределительных сетей",
          "На основании письменного разрешения органов местного самоуправления",
          "На основании письменного разрешения Ростехнадзора",
          "На основании письменного разрешения Росприроднадзора",
        ],
        correctAnswers: [
          "На основании письменного разрешения эксплуатационной организации газораспределительных сетей",
        ],
      },
      {
        code: "67421",
        text: "Какое минимальное количество выездов должно иметь на объектах с земельным участком более 5 га?",
        answers: ["Два", "Три", "Один", "Четыре"],
        correctAnswers: ["Два"],
      },
      {
        code: "67422",
        text: "Объемная доля кислорода в газопроводе после окончания продувки не должна превышать:",
        answers: [
          "3% по объему",
          "1% по объему",
          "5% по объему",
          "2% по объему",
        ],
        correctAnswers: ["1% по объему"],
      },
      {
        code: "67423",
        text: "В течение какого времени должны храниться наряды-допуски на производство газоопасных работ?",
        answers: [
          "Наряд-допуск на производство газоопасных работ (за исключением нарядов-допусков, выдаваемых на первичный пуск газа, врезку в действующий газопровод, отключение газопровода с заваркой наглухо в местах ответвлений) должен храниться не менее одного года с момента его закрытия",
          "Все наряды-допуски на производство газоопасных работ должны храниться постоянно в исполнительно-технической документации",
          "Наряды-допуски на производство газоопасных работ (за исключением нарядов-допусков, выдаваемых на первичный пуск газа, врезку в действующий газопровод, отключение газопровода с заваркой наглухо в местах ответвлений) должны храниться не менее полугода с момента их закрытия",
          "Время хранения нарядов-допусков на производство газоопасных работ определяется эксплуатирующей организацией",
        ],
        correctAnswers: [
          "Наряд-допуск на производство газоопасных работ (за исключением нарядов-допусков, выдаваемых на первичный пуск газа, врезку в действующий газопровод, отключение газопровода с заваркой наглухо в местах ответвлений) должен храниться не менее одного года с момента его закрытия",
        ],
      },
      {
        code: "67424",
        text: "В каких случаях допускается применять здания, образующие полузамкнутые дворы?",
        answers: [
          "Во всех перечисленных случаях",
          "В случае расположения зданий перпендикулярно или под углом не менее 30° к преобладающему направлению ветров летнего периода года",
          "В тех случаях, когда другое планировочное решение не может быть принято по условиям технологии либо по условиям реконструкции",
          "В случае отсутствия вредных производственных выделений",
        ],
        correctAnswers: [
          "В тех случаях, когда другое планировочное решение не может быть принято по условиям технологии либо по условиям реконструкции",
        ],
      },
      {
        code: "67425",
        text: "Какой документ выдается на производство газоопасных работ?",
        answers: ["Наряд-допуск", "Акт-допуск", "Распоряжение", "Заявка"],
        correctAnswers: ["Наряд-допуск"],
      },
      {
        code: "67426",
        text: "Какие требования к размещению инженерных коммуникаций указаны неверно?",
        answers: [
          "На территории объектов следует предусматривать преимущественно подземный способ размещения инженерных коммуникаций",
          "Для объектов, в том числе размещаемых в индустриальных парках и промышленных кластерах, следует проектировать единую систему размещения инженерных коммуникаций",
          "Не допускается размещение коммуникаций с легковоспламеняющимися и горючими жидкостями и газами под зданиями и сооружениями",
          "Во входных зонах объектов следует предусматривать преимущественно подземное размещение инженерных коммуникаций",
        ],
        correctAnswers: [
          "На территории объектов следует предусматривать преимущественно подземный способ размещения инженерных коммуникаций",
        ],
      },
      {
        code: "67427",
        text: "Норма контрольной опрессовки внутренних газопроводов промышленных, сельскохозяйственных и других производств, котельных, оборудования и газопроводов газорегуляторных пунктов (далее - ГРП), блочных газорегуляторных пунктов (далее - ГРПБ), шкафных регуляторных пунктов (далее - ШРП), газорегуляторных установок (далее - ГРУ):",
        answers: [
          "Величина давления воздуха (инертного газа) при опрессовке - 0,01 МПа, падение давления не должно превышать 0,0001 МПа за 1 час",
          "Величина давления воздуха (инертного газа) при опрессовке - 0,01 МПа, падение давления не должно превышать 0,0006 МПа за 1 час",
          "Величина давления воздуха (инертного газа) при опрессовке - 0,02 МПа, падение давления не должно превышать 0,0001 МПа за 1 час",
          "Величина давления воздуха (инертного газа) при опрессовке - 0,02 МПа, падение давления не должно превышать 0,0006 МПа за 1 час",
        ],
        correctAnswers: [
          "Величина давления воздуха (инертного газа) при опрессовке - 0,01 МПа, падение давления не должно превышать 0,0006 МПа за 1 час",
        ],
      },
      {
        code: "67428",
        text: "В каких местах допускается размещение промышленных объектов и их групп?",
        answers: [
          "В зонах возможного затопления на глубину от 0,5 до 1,2 м",
          "В опасных зонах отвалов породы угольных и сланцевых шахт или обогатительных фабрик",
          "В зонах возможного катастрофического затопления в результате разрушения плотин или дамб",
          "В зеленых зонах городов",
        ],
        correctAnswers: [
          "В зонах возможного затопления на глубину от 0,5 до 1,2 м",
        ],
      },
      {
        code: "67429",
        text: "Какие меры необходимо предпринимать, если при проведении газовой резки (сварки) на действующем газопроводе произошло снижение или превышение давления газа сверх установленных пределов: ниже 0,0004 МПа или выше 0,002 МПа?",
        answers: [
          "Работы следует прекратить",
          "Необходимо сообщить специалисту, ответственному за проведение газоопасных работ, и продолжить работы под его руководством",
          "Необходимо сделать запись в наряде-допуске и продолжать работы, соблюдая меры безопасности, указанные в инструкции по безопасности",
          "При превышении давления газа работы необходимо прекратить, а при снижении - продолжать под руководством специалиста, ответственного за проведение газоопасных работ",
        ],
        correctAnswers: ["Работы следует прекратить"],
      },
      {
        code: "67430",
        text: "Какую расчетную температуру наружного воздуха в районе строительства следует принимать при выборе стали для газопроводов и запорной арматуры сетей газораспределения и газопотребления газотурбинных и парогазовых установок?",
        answers: [
          "Температуру наиболее холодной календарной недели обеспеченностью 0,98",
          "Температуру наиболее холодной пятидневки обеспеченностью 0,92",
          "Температуру наиболее холодной пятидневки обеспеченностью 0,98",
          "Температуру наиболее холодной декады обеспеченностью 0,82",
        ],
        correctAnswers: [
          "Температуру наиболее холодной пятидневки обеспеченностью 0,92",
        ],
      },
      {
        code: "67431",
        text: "Где должен быть установлен манометр для контроля давления в газопроводе при проведении газовой резки и сварки?",
        answers: [
          "Не далее 50 м от места проведения работ",
          "Не далее 100 м от места проведения работ",
          "Не далее 10 м от места проведения работ",
          "Не далее 15 м от места проведения работ",
        ],
        correctAnswers: ["Не далее 100 м от места проведения работ"],
      },
      {
        code: "67432",
        text: "Каким образом определяется окончание продувки газопровода при пуске газа?",
        answers: [
          "Путем анализа или сжиганием отобранных проб газа",
          "Путем анализа с применением газоанализатора или газосигнализатора",
          "Согласно времени продувки, указанном в инструкции",
        ],
        correctAnswers: ["Путем анализа или сжиганием отобранных проб газа"],
      },
      {
        code: "67433",
        text: "Каким требованиям должны соответствовать заглушки, устанавливаемые на газопроводы природного газа?",
        answers: [
          "Должны соответствовать номинальному давлению газа в газопроводе, иметь хвостовики, выступающие за пределы фланцев, и клеймо с указанием давления газа и диаметра газопровода",
          "Должны соответствовать максимальному давлению газа в газопроводе, иметь хвостовики, выступающие за пределы фланцев, и клеймо с указанием диаметра газопровода",
          "Должны соответствовать максимальному давлению газа в газопроводе, иметь хвостовики, выступающие за пределы фланцев, и клеймо с указанием давления газа и диаметра газопровода",
          "Должны соответствовать повышенному давлению газа в газопроводе, иметь хвостовики, выступающие за пределы фланцев, и клеймо с указанием давления газа",
        ],
        correctAnswers: [
          "Должны соответствовать максимальному давлению газа в газопроводе, иметь хвостовики, выступающие за пределы фланцев, и клеймо с указанием давления газа и диаметра газопровода",
        ],
      },
      {
        code: "67434",
        text: "Что должна предусматривать схема транспорта, разрабатываемая в составе проекта, планировочной организации земельного участка объекта, группы объектов?",
        answers: [
          "Все перечисленное",
          "Использование сооружений и устройств, проектируемых для других целей (дамб водохранилищ и плотин, водопропускных сооружений), под земляное полотно и искусственные сооружения железных и автомобильных дорог",
          "Максимальное совмещение транспортных сооружений и устройств для различных видов транспорта (совмещенные автомобильные и железнодорожные или автомобильные и трамвайные мосты и путепроводы, общее земляное полотно для автомобильных дорог и трамвайных путей, кроме скоростных, и др.)",
          "Возможность последующего развития схемы внешнего транспорта",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "67435",
        text: "Подтягивание или замена сальниковой набивки запорной арматуры, разборка резьбовых соединений конденсатосборников на наружных газопроводах среднего и высокого давлений допускаются при давлении газа:",
        answers: [
          "Не более 0,02 МПа",
          "Не более 0,03 МПа",
          "Не более 0,01 МПа",
          "Не более 0,1 МПа",
        ],
        correctAnswers: ["Не более 0,1 МПа"],
      },
      {
        code: "67436",
        text: "С учетом соблюдения каких требований следует, по возможности, размещать здания и сооружения производственных объектов исходя из специфики производства и природных условий?",
        answers: [
          "В районах со снеговым покровом более 50 см или с количеством переносимого снега более 200 м³ на 1 м фронта переноса в год следует предусматривать сквозное проветривание площадки предприятия",
          "Продольные оси аэрационных фонарей и стены зданий с проемами, используемыми для аэрации помещений, следует ориентировать в плане параллельно или под углом не менее 30° к преобладающему направлению ветров летнего периода года",
          "В районах массового переноса песка ветрами наиболее низкие здания необходимо располагать с наветренной стороны площадки перпендикулярно потоку переносимого песка, а также предусматривать полосы зеленых насаждений (шириной не менее 10 м)",
          "Продольные оси здания и световых фонарей следует ориентировать в пределах от 10° до 45° к меридиану",
        ],
        correctAnswers: [
          "В районах со снеговым покровом более 50 см или с количеством переносимого снега более 200 м³ на 1 м фронта переноса в год следует предусматривать сквозное проветривание площадки предприятия",
        ],
      },
      {
        code: "67437",
        text: "Допускается ли проведение разборки фланцевых, резьбовых соединений и арматуры на внутренних газопроводах без их отключения?",
        answers: [
          "Разборка фланцевых и резьбовых соединений и арматуры должна производиться на отключенном и заглушенном участке внутреннего газопровода",
          "Допускается разборка фланцевых и резьбовых соединений и арматуры на отключенном участке внутреннего газопровода без установки заглушек",
          "Допускается только для газопроводов среднего давления",
          "Допускается при снижении давления газа во внутреннем газопроводе до величины 0,005 МПа",
        ],
        correctAnswers: [
          "Разборка фланцевых и резьбовых соединений и арматуры должна производиться на отключенном и заглушенном участке внутреннего газопровода",
        ],
      },
      {
        code: "67438",
        text: "Какой орган согласовывает размещение промышленных объектов на территориях залегания полезных ископаемых?",
        answers: [
          "Роснедра",
          "Ростехнадзор",
          "Минэнерго России",
          "Росприроднадзор",
        ],
        correctAnswers: ["Ростехнадзор"],
      },
      {
        code: "67439",
        text: "Допускается ли замена прокладок фланцевых соединений на внутренних газопроводах под давлением газа?",
        answers: [
          "Нет, не допускается",
          "Да, при давлении не выше 0,1 МПа",
          "Да, при давлении 0,0004 - 0,002 МПа",
          "Да, при давлении не выше 0,005 МПа",
        ],
        correctAnswers: ["Нет, не допускается"],
      },
      {
        code: "67440",
        text: "Какие требования к размещению надземных коммуникаций указаны верно?",
        answers: [
          "Не допускается размещение газопроводов горючих газов по территории складов легковоспламеняющихся и горючих жидкостей и материалов",
          "Трубопроводы с легковоспламеняющимися и горючими жидкостями и газами должны размещаться по покрытиям и стенам зданий категорий А и Б по взрывопожароопасности",
          "Не допускается размещать надземные коммуникации на опорах, эстакадах, в галереях или на стенах зданий и сооружений",
          "Транзитные наружные трубопроводы с легковоспламеняющимися и горючими жидкостями и газами не допускается размещать по стенам и кровлям зданий I, II, IIIа степеней огнестойкости с производствами категорий В, Г и Д",
        ],
        correctAnswers: [
          "Не допускается размещение газопроводов горючих газов по территории складов легковоспламеняющихся и горючих жидкостей и материалов",
        ],
      },
      {
        code: "67441",
        text: "При каком давлении газа в газопроводе разрешается устранение в газопроводах закупорок путем шуровки металлическими шомполами, заливки растворителей или подачи пара?",
        answers: [
          "Не более 0,01 МПа",
          "Не более 300 даПа",
          "Не более 0,005 МПа",
          "Не более 0,1 МПа",
        ],
        correctAnswers: ["Не более 0,005 МПа"],
      },
      {
        code: "67442",
        text: "В каких районах не следует размещать промышленные объекты с источниками загрязнения атмосферного воздуха вредными веществами 1-го и 2-го классов опасности?",
        answers: [
          "Во всех перечисленных районах",
          "В районах с повторяющимися туманами частотой 15% за год",
          "В районах с повторяющимися штилями частотой 10% за год",
          "В районах с преобладающими ветрами со скоростью до 1 м/с",
        ],
        correctAnswers: [
          "В районах с преобладающими ветрами со скоростью до 1 м/с",
        ],
      },
      {
        code: "67443",
        text: "Каким образом должна проверяться герметичность резьбовых и фланцевых соединений, которые разбирались для устранения закупорок?",
        answers: [
          "Любым из перечисленных способов",
          "Испытанием на герметичность",
          "Мыльной эмульсией или с помощью высокочувствительных газоанализаторов (течеискателей)",
          "Опрессовкой",
        ],
        correctAnswers: [
          "Мыльной эмульсией или с помощью высокочувствительных газоанализаторов (течеискателей)",
        ],
      },
      {
        code: "67444",
        text: "Какое минимальное расстояние по горизонтали (в свету) от кабеля до крайнего провода должно быть при прокладке кабельной линии параллельно высоковольтной линии (ВЛ) напряжением 110 кВ и выше?",
        answers: ["15 м", "3 м", "10 м", "8 м", "5 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "67445",
        text: "Какую высоту следует принимать от уровня земли до низа труб или поверхности изоляции, прокладываемых на высоких опорах, в непроезжей части территории, в местах прохода людей?",
        answers: ["5 м", "6 м", "7,1 м", "2,2 м"],
        correctAnswers: ["2,2 м"],
      },
      {
        code: "67446",
        text: "К какому классу взрывоопасной зоны следует относить помещения категории А по взрывопожарной опасности, в которых расположено оборудование сетей газопотребления ГТУ и ПГУ?",
        answers: [
          "К зоне класса 22",
          "К зоне класса 1",
          "К зоне класса 2",
          "К зоне класса 0",
        ],
        correctAnswers: ["К зоне класса 1"],
      },
      {
        code: "67447",
        text: "Под каким углом должны предусматриваться пересечения кабельных эстакад с воздушными линиями электропередачи?",
        answers: [
          "Пересечение надземных коммуникаций не допускается",
          "Под углом не менее 45°",
          "Под углом не менее 30°",
        ],
        correctAnswers: ["Под углом не менее 30°"],
      },
      {
        code: "67448",
        text: "Каким должно быть расстояние между трубопроводами и силовыми кабелями напряжением до 35 кВ и кабелями связи?",
        answers: ["0,8 м", "0,2 м", "0,4 м", "0,5 м"],
        correctAnswers: ["0,5 м"],
      },
      {
        code: "67449",
        text: "На сколько уровень полов первого этажа зданий должен быть выше планировочной отметки примыкающих к зданиям участков?",
        answers: [
          "Не менее чем на 12 см",
          "Не менее чем на 10 см",
          "Не менее чем на 15 см",
        ],
        correctAnswers: ["Не менее чем на 15 см"],
      },
      {
        code: "67450",
        text: "Какой надлежит принимать ширину ворот автомобильных въездов на земельный участок производственного объекта?",
        answers: ["Не менее 5,0 м", "Не менее 4,5 м", "Не менее 4,0 м"],
        correctAnswers: ["Не менее 4,5 м"],
      },
      {
        code: "67451",
        text: "Какие из перечисленных мероприятий необходимо предусматривать при разработке планировочной организации земельных участков производственных объектов?",
        answers: [
          "Только организацию единой сети обслуживания работающих",
          "Только возможность осуществления строительства и ввода в эксплуатацию пусковыми комплексами или очередями",
          "Все перечисленные мероприятия, включая восстановление (рекультивацию) отведенных во временное пользование земель, нарушенных при строительстве",
          "Только защиту прилегающих территорий от эрозии, заболачивания, засоления и загрязнения подземных вод и открытых водоемов сточными водами, отходами и отбросами предприятий",
        ],
        correctAnswers: [
          "Все перечисленные мероприятия, включая восстановление (рекультивацию) отведенных во временное пользование земель, нарушенных при строительстве",
        ],
      },
      {
        code: "67452",
        text: "На каких из перечисленных территорий не допускается размещать производственные объекты?",
        answers: [
          "Только в первом поясе зоны санитарной охраны подземных и наземных источников водоснабжения",
          "Только в опасных зонах отвалов породы угольных и сланцевых шахт или обогатительных фабрик",
          "Только в зонах возможного катастрофического затопления в результате разрушения плотин или дамб",
          "На всех перечисленных, а также в районах развития опасных геологических и инженерно-геологических процессов",
        ],
        correctAnswers: [
          "На всех перечисленных, а также в районах развития опасных геологических и инженерно-геологических процессов",
        ],
      },
      {
        code: "67453",
        text: "Каким должно быть минимальное расстояние по горизонтали (в свету) от канализации до водопровода из железобетонных труб, прокладываемых в глинистых грунтах?",
        answers: ["3 м", "10 м", "5 м", "1,5 м"],
        correctAnswers: ["5 м"],
      },
      {
        code: "67454",
        text: "Какие функционально-технологические зоны следует выделять при разработке планировочной организации земельных участков производственных объектов?",
        answers: [
          "Только подсобную",
          "Только складскую",
          "Только производственную, включая зоны исследовательского назначения и опытных производств",
          "Только входную",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "67455",
        text: "В каких местах следует размещать надземные коммуникации?",
        answers: [
          "Только в галереях",
          "Только на опорах",
          "Только на стенах зданий и сооружений",
          "Только на эстакадах",
          "Во всех перечисленных",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "67456",
        text: "Какое максимальное расстояние должно быть от проходных пунктов до входов в санитарно-бытовые помещения основных цехов?",
        answers: ["600 м", "1500 м", "800 м", "500 м"],
        correctAnswers: ["800 м"],
      },
      {
        code: "67457",
        text: "В каких местах допускается размещать надземные коммуникации?",
        answers: [
          "Газопроводы горючих газов размещаются на территории складов легковоспламеняющихся и горючих жидкостей и материалов",
          "Трубопроводы с горючими жидкими и газообразными продуктами размещаются в галереях, если смешение продуктов может вызвать взрыв или пожар",
          "Все ответы неверны",
          "Во всех перечисленных",
          "Транзитные наружные трубопроводы с легковоспламеняющимися и горючими жидкостями и газами размещаются по эстакадам, отдельно стоящим колоннам и опорам из горючих материалов",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "67458",
        text: "В каких местах допускается размещение отдельно стоящих зданий или сооружений?",
        answers: [
          "В замкнутых и полузамкнутых дворах",
          "Все ответы неверны",
          "Только в полузамкнутых дворах, при этом расстояние от этих сооружений до зданий должно удовлетворять требованиям, предъявляемым к устройству замкнутых дворов",
          "Только в замкнутых дворах",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "67459",
        text: "Газопроводы с каким давлением газа допускается размещать совместно с другими трубопроводами и кабелями связи в каналах и тоннелях?",
        answers: [
          "Размещение газопроводов совместно с другими трубопроводами и кабелями связи в каналах и тоннелях не допускается",
          "До 1,6 МПа",
          "До 1,2 МПа",
          "До 0,6 МПа",
        ],
        correctAnswers: ["До 0,6 МПа"],
      },
      {
        code: "67460",
        text: "Какие трубопроводы допускается размещать в открытых траншеях и лотках?",
        answers: [
          "Трубопроводы бытовой канализации",
          "Трубопроводы, по которым транспортируются кислоты и щелочи",
          "Трубопроводы для горючих газов",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "67461",
        text: "Каким должно быть минимальное расстояние по горизонтали (в свету) от водопроводов и напорной канализации до фундаментов зданий и сооружений?",
        answers: ["4 м", "5 м", "7 м", "3 м"],
        correctAnswers: ["5 м"],
      },
      {
        code: "67462",
        text: "Каким должно быть минимальное расстояние по горизонтали (в свету) от газопроводов среднего давления (св. 0,005 до 0,3 МПа) до фундаментов зданий и сооружений?",
        answers: ["2 м", "7 м", "4 м", "3 м"],
        correctAnswers: ["4 м"],
      },
      {
        code: "67463",
        text: "Какую высоту от уровня земли до низа труб (или поверхности их изоляции), прокладываемых на низких опорах на свободной территории вне проезда транспортных средств и прохода людей, следует принимать при ширине группы труб от 1,5 м и более?",
        answers: ["0,35 м", "0,25 м", "0,75 м", "0,5 м"],
        correctAnswers: ["0,5 м"],
      },
      {
        code: "67464",
        text: "При каком условии производятся работы, связанные с обработкой почвы на глубину менее 0,3 м, собственниками, владельцами или пользователями земельных участков в охранной зоне газораспределительной сети?",
        answers: [
          "При наличии распоряжения эксплуатационной организации газораспределительных сетей",
          "При наличии письменного разрешения Ростехнадзора",
          "При условии предварительного письменного уведомления эксплуатационной организации не менее чем за 3 рабочих дня до начала работ",
          "При условии предварительного письменного уведомления эксплуатационной организации не менее чем за 5 рабочих дней до начала работ",
        ],
        correctAnswers: [
          "При условии предварительного письменного уведомления эксплуатационной организации не менее чем за 3 рабочих дня до начала работ",
        ],
      },
      {
        code: "67465",
        text: "За сколько дней до начала технического обслуживания, ремонта и диагностирования газораспределительных сетей эксплуатационная организация газораспределительной сети отправляет уведомление о производстве работ по почте собственникам, владельцам или пользователям земельных участков, которые расположены в охранных зонах?",
        answers: [
          "За 10 календарных дней",
          "За 3 рабочих дня",
          "За 5 рабочих дней",
          "За 7 календарных дней",
        ],
        correctAnswers: ["За 5 рабочих дней"],
      },
      {
        code: "67466",
        text: "Какие из перечисленных действий разрешается производить на земельных участках, входящих в охранные зоны газораспределительных сетей?",
        answers: [
          "Сносить и реконструировать мосты, коллекторы, автомобильные и железные дороги с расположенными на них газораспределительными сетями с предварительным выносом этих газопроводов по согласованию с эксплуатационными организациями",
          "Перемещать, повреждать, засыпать и уничтожать опознавательные знаки, контрольно-измерительные пункты и другие устройства газораспределительных сетей",
          "Самовольно подключаться к газораспределительным сетям",
          "Рыть погреба, копать и обрабатывать почву сельскохозяйственными и мелиоративными орудиями и механизмами на глубину более 0,3 м",
        ],
        correctAnswers: [
          "Сносить и реконструировать мосты, коллекторы, автомобильные и железные дороги с расположенными на них газораспределительными сетями с предварительным выносом этих газопроводов по согласованию с эксплуатационными организациями",
        ],
      },
      {
        code: "67467",
        text: "Как регламентируется совместная прокладка газопроводов и трубопроводов, транспортирующих легковоспламеняющиеся и горючие вещества, с кабельными линиями?",
        answers: [
          "Совместная прокладка разрешается только в случаях, если диаметр трубопроводов менее 500 мм",
          "Совместная прокладка допускается при разработке мероприятий по безопасности",
          "Совместная прокладка не допускается",
          "Совместная прокладка допускается без ограничений",
        ],
        correctAnswers: ["Совместная прокладка не допускается"],
      },
      {
        code: "67468",
        text: "На каком расстоянии от оси газопроводов устанавливаются навигационные знаки в местах пересечения газопроводов с судоходными и сплавными реками и каналами на обоих берегах?",
        answers: ["100 м", "80 м", "70 м", "50 м"],
        correctAnswers: ["100 м"],
      },
      {
        code: "67469",
        text: "Что входит в состав газораспределительных сетей?",
        answers: [
          "Только отдельно стоящие газорегуляторные пункты, расположенные на территории и за территорией населенных пунктов, промышленных и иных предприятий",
          "Только наружные подземные, наземные и надземные распределительные газопроводы, межпоселковые газопроводы, газопроводы-вводы с установленной на них запорной арматурой",
          "Только переходы газопроводов через естественные и искусственные препятствия, в том числе через реки, железные и автомобильные дороги",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "67470",
        text: "Как регламентируется прокладка трубопроводов с легковоспламеняющимися и горючими жидкостями, а также со сжиженными газами для снабжения промышленных предприятий и складов по селитебной территории?",
        answers: [
          "Прокладка допускается в случае применения для строительства полиэтиленовых трубопроводов",
          "Допускается прокладка трубопроводов в футлярах",
          "Прокладка трубопроводов допускается при глубине их пролегания более 3 м",
          "Прокладка трубопроводов не допускается",
        ],
        correctAnswers: ["Прокладка трубопроводов не допускается"],
      },
      {
        code: "67471",
        text: "Какие размеры земельных участков газонаполнительных станций производительностью 20 тыс. т/год следует принимать по проекту?",
        answers: ["8 га", "7 га", "9 га", "6 га"],
        correctAnswers: ["7 га"],
      },
      {
        code: "67472",
        text: "Какие максимальные размеры земельных участков газонаполнительных пунктов и промежуточных складов баллонов следует принимать по проекту?",
        answers: ["0,7 га", "0,4 га", "0,6 га", "0,8 га"],
        correctAnswers: ["0,6 га"],
      },
      {
        code: "67473",
        text: "В каком случае допускается не проводить испытания подземных газопроводов, прокладываемых в футлярах на участках переходов через искусственные и естественные преграды, после полного монтажа и засыпки перехода?",
        answers: [
          "По согласованию с территориальным органом Ростехнадзора",
          "Не допускается ни в каком случае",
          "По согласованию с эксплуатационной организацией",
          "По согласованию с проектной организацией",
        ],
        correctAnswers: ["По согласованию с эксплуатационной организацией"],
      },
      {
        code: "67474",
        text: "Какой должна быть кратность воздухообмена в закрытых помещениях производственных зданий ГНС и ГНП, в которых обращаются СУГ, в рабочее время?",
        answers: [
          "Не менее 15 обменов в час",
          "Не менее 3 обменов в час",
          "Не менее 10 обменов в час",
          "Не менее 6 обменов в час",
        ],
        correctAnswers: ["Не менее 10 обменов в час"],
      },
      {
        code: "67475",
        text: "Какое из перечисленных расстояний (в свету) должно быть не менее 1 м при размещении в один ряд двух насосов и более или компрессоров? Укажите все правильные ответы.",
        answers: [
          "Между компрессорами",
          "От насосов и компрессоров до стен помещения",
          "Между насосами и компрессорами",
          "Между насосами",
        ],
        correctAnswers: [
          "От насосов и компрессоров до стен помещения",
          "Между насосами и компрессорами",
        ],
      },
      {
        code: "67476",
        text: "Какое из перечисленных помещений и сооружений относится к производственной зоне территории ГНС?",
        answers: [
          "Механическая мастерская",
          "Все ответы неверны",
          "Котельная",
          "Очистные сооружения",
          "Трансформаторная и (или) дизельная подстанция",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "67477",
        text: "В каком месте должен выводиться газ от предохранительной арматуры, предусмотренной системами трубопроводов в ПРГ?",
        answers: [
          "Не менее 1 м выше карниза здания",
          "Не менее 1,5 м выше карниза здания",
          "Не менее 0,5 м выше карниза здания",
          "Определяется проектной документацией",
        ],
        correctAnswers: ["Не менее 1 м выше карниза здания"],
      },
      {
        code: "67478",
        text: "На каких наружных стенах зданий допускается устанавливать ГРПШ с входным давлением газа до 0,6 МПа включительно?",
        answers: [
          "На наружных стенах производственных зданий, котельных, общественных и бытовых зданий производственного назначения с помещениями категорий В4, Г и Д",
          "На наружных стенах газифицируемых жилых, общественных, административных и бытовых зданий независимо от степени огнестойкости и класса конструктивной пожарной опасности при расходе газа до 50 м³/ч",
          "На наружных стенах газифицируемых жилых, общественных, административных и бытовых зданий не ниже степени огнестойкости III и не ниже класса конструктивной пожарной опасности С1 при расходе газа до 400 м³/ч",
          "На наружных стенах зданий устанавливать не допускается",
        ],
        correctAnswers: [
          "На наружных стенах производственных зданий, котельных, общественных и бытовых зданий производственного назначения с помещениями категорий В4, Г и Д",
        ],
      },
      {
        code: "67479",
        text: "Какие соединения должны применяться для подземных медных газопроводов? Укажите все правильные ответы.",
        answers: [
          "Соединения встык нагретым инструментом",
          "Соединения, выполненные сваркой",
          "Соединения, выполненные высокотемпературной капиллярной пайкой",
          "Стыковые соединения",
          "Нахлесточные соединения",
        ],
        correctAnswers: [
          "Соединения, выполненные сваркой",
          "Соединения, выполненные высокотемпературной капиллярной пайкой",
        ],
      },
      {
        code: "67480",
        text: "Какое минимальное расстояние устанавливается от прогнозируемых границ развития оползней, обвалов горных пород и склонов, эрозионных и иных негативных явлений до опор газопровода?",
        answers: ["10 м", "2 м", "5 м", "15 м"],
        correctAnswers: ["5 м"],
      },
      {
        code: "67481",
        text: "Какой вид контроля не включает в себя строительный контроль, осуществляемый в процессе строительства сетей газораспределения, газопотребления и объектов СУГ?",
        answers: [
          "Допускающий контроль",
          "Входной контроль",
          "Приемочный контроль",
          "Операционный контроль",
        ],
        correctAnswers: ["Допускающий контроль"],
      },
      {
        code: "67482",
        text: "Какая устанавливается минимальная глубина заложения подземных резервуаров при использовании испарительных установок?",
        answers: ["0,4 м", "1 м", "0,2 м", "0,6 м"],
        correctAnswers: ["0,2 м"],
      },
      {
        code: "67483",
        text: "В каком случае допускается установка одного резервуара СУГ?",
        answers: [
          "Если вместимость резервуара 10 м³",
          "При достаточной производительности резервуара",
          "Если по условиям эксплуатации допускаются перерывы в потреблении СУГ на длительное время (не менее месяца)",
          "Не допускаются ни в каком случае",
        ],
        correctAnswers: [
          "Если по условиям эксплуатации допускаются перерывы в потреблении СУГ на длительное время (не менее месяца)",
        ],
      },
      {
        code: "67484",
        text: "Какое из перечисленных требований при пересечении газопроводами железнодорожных и трамвайных путей и автомобильных дорог указано верно?",
        answers: [
          "На подводных переходах независимо от способа прокладки следует применять стальные трубы с толщиной стенки на 2 мм больше расчетной, но не менее 5 мм",
          "При прокладке газопровода методом наклонно-направленного бурения отметка должна находиться не менее чем на 1,0 м ниже прогнозируемого профиля дна",
          "Отметка верха газопровода (балласта, футеровки) должна быть не менее чем на 1,0 м, а на переходах через судоходные и сплавные водные преграды - на 1,5 м ниже прогнозируемого профиля дна на весь срок эксплуатации газопровода",
          "Все перечисленные требования",
        ],
        correctAnswers: [
          "На подводных переходах независимо от способа прокладки следует применять стальные трубы с толщиной стенки на 2 мм больше расчетной, но не менее 5 мм",
        ],
      },
      {
        code: "67485",
        text: "Какой коэффициент запаса прочности труб и соединительных деталей устанавливается при прокладке полиэтиленовых газопроводов давлением свыше 0,3 до 0,6 МПа включительно на территориях городов и сельских населенных пунктов?",
        answers: [
          "Не менее 3,2",
          "Не менее 2,7",
          "Не менее 1,8",
          "Устанавливается проектной документацией",
        ],
        correctAnswers: ["Не менее 3,2"],
      },
      {
        code: "67486",
        text: "Каким образом не допускается прокладка газопроводов согласно СП 62.13330.2011. Свод правил. Газораспределительные системы? Укажите все правильные ответы.",
        answers: [
          "На участках переходов через искусственные и естественные преграды, при пересечении сетей инженерно-технического обеспечения",
          "По стенам газифицируемых зданий",
          "Через лоджии и балконы",
          "Через фундаменты зданий и сооружений",
        ],
        correctAnswers: [
          "Через лоджии и балконы",
          "Через фундаменты зданий и сооружений",
        ],
      },
      {
        code: "67487",
        text: "В каком случае могут применяться газопроводы из стальных труб и их соединительные детали для наружной и внутренней прокладки для СУГ?",
        answers: [
          "При давлении до 1,6 МПа",
          "При давлении выше 1,6 МПа",
          "Ни в каком случае, применяются только для природного газа",
          "При любом давлении газа",
        ],
        correctAnswers: ["При давлении до 1,6 МПа"],
      },
      {
        code: "67488",
        text: "Какое из перечисленных требований при проведении контроля физическими методами сварных стыков указано верно?",
        answers: [
          "При получении неудовлетворительных результатов радиографического контроля хотя бы на одном стыке объем контроля следует увеличить до 30% общего числа стыков",
          "В случае повторного выявления дефектных стыков все стыки, сваренные конкретным сварщиком на объекте в течение рабочей недели и проверенные ультразвуковым методом, должны быть подвергнуты радиографическому контролю",
          "Все ответы неверны",
          "Ультразвуковой метод контроля сварных стыков стальных газопроводов должен применяться при условии проведения выборочной проверки не менее 20% стыков радиографическим методом",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "67489",
        text: "Каким должно быть минимальное расстояние между рядами надземных резервуаров для СУГ, размещаемых в два ряда и более?",
        answers: ["2 м", "5 м", "15 м", "10 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "67490",
        text: "Какой должна быть прокладка газопроводов СУГ, а также газопроводов природного газа на ГНС и ГНП?",
        answers: [
          "Надземной",
          "Скрытой",
          "Любой в соответствии с проектной документацией",
          "Подземной",
        ],
        correctAnswers: ["Надземной"],
      },
      {
        code: "67491",
        text: "В каком случае предусматривается резервная линия редуцирования?",
        answers: [
          "При обеспечении непрерывности подачи газа потребителям в ПРГ, пропускная способность которых обеспечивается одной линией редуцирования",
          "При подаче газа на объекты, в работе которых допускается прекращение подачи газа на период выполнения регламентных работ",
          "При подаче газа потребителям по закольцованной схеме газопроводов",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: [
          "При обеспечении непрерывности подачи газа потребителям в ПРГ, пропускная способность которых обеспечивается одной линией редуцирования",
        ],
      },
      {
        code: "67492",
        text: "В каких помещениях должна размещаться ГРУ? Укажите все правильные ответы.",
        answers: [
          "В помещениях категорий В1 - В4, если расположенное в них газоиспользующее оборудование вмонтировано в технологические агрегаты производства",
          "В складских помещениях категорий В1 - В3",
          "В помещениях категорий Г и Д, в которых расположено газоиспользующее оборудование",
          "В помещениях категорий А и Б",
        ],
        correctAnswers: [
          "В помещениях категорий В1 - В4, если расположенное в них газоиспользующее оборудование вмонтировано в технологические агрегаты производства",
          "В помещениях категорий Г и Д, в которых расположено газоиспользующее оборудование",
        ],
      },
      {
        code: "67493",
        text: "При каком максимальном входном давлении газа следует устанавливать ГРУ?",
        answers: [
          "0,3 МПа",
          "0,6 МПа",
          "Должно определяться главным инженером эксплуатирующей организации",
          "1,2 МПа",
        ],
        correctAnswers: ["0,6 МПа"],
      },
      {
        code: "67494",
        text: "На сколько допускается сокращать расстояния до зданий и сооружений при прокладке подземных газопроводов в стесненных, особых природных условиях?",
        answers: [
          "Не более чем на 50%",
          "Не допускается сокращать расстояния до зданий и сооружений",
          "Не более чем на 25%",
          "Не более чем на 15%",
        ],
        correctAnswers: ["Не более чем на 25%"],
      },
      {
        code: "67495",
        text: "Трубы с какой минимальной толщиной стенки следует применять для внутренних газопроводов из меди?",
        answers: ["1 мм", "1,2 мм", "3 мм", "1,5 мм"],
        correctAnswers: ["1 мм"],
      },
      {
        code: "67496",
        text: "Каким освещением следует оборудовать помещения насосно-компрессорного, наполнительного, испарительного и окрасочного отделений ГНС и ГНП?",
        answers: [
          "Только аккумуляторными фонарями напряжением не выше 12 В во взрывозащищенном исполнении",
          "Только рабочим освещением",
          "Всеми перечисленными",
          "Только дополнительным аварийным освещением",
        ],
        correctAnswers: ["Всеми перечисленными"],
      },
      {
        code: "67497",
        text: "Какое из перечисленных требований к размещению насосов и компрессоров для перемещения жидкой и паровой фаз СУГ по трубопроводам ГНС, ГНП указано верно?",
        answers: [
          "Компрессоры, работающие с воздушным охлаждением, и насосы допускается устанавливать на площадках с устройством над ними навеса и по периметру площадки проветриваемого ограждения",
          "Насосы и компрессоры следует устанавливать на фундаментах, связанных с фундаментами другого оборудования и стенами здания",
          "Пол помещения, где размещаются насосы и компрессоры, рекомендуется предусматривать не менее чем на 0,1 м выше планировочных отметок прилегающей территории",
          "Компрессоры, работающие с воздушным охлаждением, и насосы запрещается устанавливать на площадках с устройством над ними навеса и по периметру площадки проветриваемого ограждения",
        ],
        correctAnswers: [
          "Компрессоры, работающие с воздушным охлаждением, и насосы допускается устанавливать на площадках с устройством над ними навеса и по периметру площадки проветриваемого ограждения",
        ],
      },
      {
        code: "67498",
        text: "Какое число газорегуляторных установок (далее – ГРУ) допускается размещать в одном помещении?",
        answers: [
          "Число ГРУ, размещаемых в одном помещении, не ограничивается",
          "2 ГРУ в помещениях категорий А и Б, в остальных помещениях не ограничивается",
          "3 ГРУ в помещениях категорий Г и Д",
          "Не более 1 ГРУ в помещениях любых категорий",
        ],
        correctAnswers: [
          "Число ГРУ, размещаемых в одном помещении, не ограничивается",
        ],
      },
      {
        code: "67499",
        text: "Какие виды грунтов относятся к особым условиям при прокладке газопроводов?",
        answers: [
          "Элювиальные грунты",
          "Слабонабухающие",
          "Просадочные грунты типа I просадочности",
          "Слабопучинистые",
        ],
        correctAnswers: ["Элювиальные грунты"],
      },
      {
        code: "67500",
        text: "Кем решается вопрос о необходимости установки клапана безопасности (контроллера) расхода газа на участках присоединения к распределительному газопроводу газопроводов-вводов к отдельным зданиям различного назначения, многоквартирным зданиям, котельным и производственным потребителям?",
        answers: [
          "Проектной организацией по согласованию с эксплуатационной организацией",
          "Эксплуатационной организацией",
          "Территориальным органом Ростехнадзора",
          "Проектной организацией самостоятельно",
        ],
        correctAnswers: [
          "Проектной организацией по согласованию с эксплуатационной организацией",
        ],
      },
      {
        code: "67501",
        text: "В каких случаях испытания участков переходов через искусственные и естественные преграды допускается проводить в одну стадию вместе с основным газопроводом?",
        answers: [
          "Во всех перечисленных случаях",
          "В случаях отсутствия сварных соединений в пределах перехода",
          "В случаях использования метода наклонно-направленного бурения",
          "В случаях использования в пределах перехода для сварки полиэтиленовых труб деталей с ЗН или сварочного оборудования со средней и высокой степенью автоматизации",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "67502",
        text: "Каким образом следует проводить продувку полости внутренних газопроводов и газопроводов ПРГ перед их монтажом?",
        answers: ["Воздухом", "Кислородом", "Азотом", "Гелием"],
        correctAnswers: ["Воздухом"],
      },
      {
        code: "67503",
        text: "В каких местах допускается установка баллонов СУГ?",
        answers: [
          "В помещениях с естественным освещением",
          "У аварийных выходов",
          "В цокольных и подвальных помещениях",
          "В жилых комнатах и коридорах",
        ],
        correctAnswers: ["В помещениях с естественным освещением"],
      },
      {
        code: "67504",
        text: "Для каких грунтов глубина прокладки газопровода до верха трубы должна быть не менее 0,7 м расчетной глубины промерзания, но не менее 0,9 м?",
        answers: [
          "Для грунтов неодинаковой степени пучинистости",
          "Для среднепучинистых грунтов при равномерной пучинистости грунтов",
          "Для чрезмерно пучинистых грунтов при равномерной пучинистости грунтов",
          "Для сильнопучинистых грунтов при равномерной пучинистости грунтов",
        ],
        correctAnswers: [
          "Для среднепучинистых грунтов при равномерной пучинистости грунтов",
        ],
      },
      {
        code: "67505",
        text: "В каких местах следует предусматривать контрольные трубки при проектировании подземных газопроводов на площадках строительства сейсмичностью более 6 баллов, на подрабатываемых и закарстованных территориях?",
        answers: [
          "На всех перечисленных территориях",
          "Только в местах пересечения с другими сетями инженерно-технического обеспечения",
          "Только в местах подземных вводов в здания",
          "Только в местах расположения переходов полиэтилен-сталь",
        ],
        correctAnswers: ["На всех перечисленных территориях"],
      },
      {
        code: "67506",
        text: "В каком случае рекомендуется укомплектовывать газоиспользующее оборудование производственных зданий автоматикой безопасности, обеспечивающей прекращение подачи газа?",
        answers: [
          "Во всех перечисленных случаях",
          "Только в случае погасания пламени горелок",
          "Только в случае понижения давления воздуха",
          "Только в случае уменьшения разрежения в топке",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "67507",
        text: "Какой класс герметичности затворов должна обеспечивать запорная арматура на газопроводах СУГ?",
        answers: [
          "Не ниже класса С",
          "Не ниже класса D",
          "Не ниже класса А",
          "Не ниже класса В",
        ],
        correctAnswers: ["Не ниже класса А"],
      },
      {
        code: "67508",
        text: "В каком из перечисленных случаев ударная вязкость металла стальных труб и соединительных деталей с толщиной стенки 5 мм и более должна быть не ниже 30 Дж/см² независимо от района строительства сетей газораспределения и газопотребления? Укажите все правильные ответы.",
        answers: [
          "Для газопроводов, прокладываемых на площадках строительства сейсмичностью свыше 3 баллов",
          "На переходах через естественные преграды и в местах пересечений с железными дорогами и автодорогами категорий I - III и магистральных улиц и дорог",
          "Для газопроводов давлением свыше 0,3 МПа и при номинальном диаметре более 420 мм",
          "Для газопроводов, испытывающих вибрационные нагрузки",
        ],
        correctAnswers: [
          "На переходах через естественные преграды и в местах пересечений с железными дорогами и автодорогами категорий I - III и магистральных улиц и дорог",
          "Для газопроводов, испытывающих вибрационные нагрузки",
        ],
      },
      {
        code: "67509",
        text: "Каким образом проводится испытание на герметичность внутренних газопроводов из многослойных труб?",
        answers: [
          "Давлением 0,1 МПа в течение 10 мин",
          "Давлением 0,6 МПа в течение 5 мин",
          "Давлением 0,015 МПа в течение 10 мин",
          "Давлением 0,2 МПа в течение 20 мин",
        ],
        correctAnswers: ["Давлением 0,015 МПа в течение 10 мин"],
      },
      {
        code: "67510",
        text: "На каком расстоянии друг от друга должны размещаться групповые баллонные установки СУГ?",
        answers: [
          "Не менее 5 м",
          "Не менее 10 м",
          "Не менее 15 м",
          "Не менее 20 м",
        ],
        correctAnswers: ["Не менее 15 м"],
      },
      {
        code: "67511",
        text: "Какое требование по размещению ГРП противоречит СП 62.13330.2011. Свод правил. Газораспределительные системы?",
        answers: [
          "ГРП размещают встроенными в двухэтажные газифицируемые производственные здания и котельные",
          "ГРП размещают отдельно стоящими",
          "ГРП размещают пристроенными к газифицируемым производственным зданиям, котельным и общественным зданиям с помещениями производственного назначения",
          "ГРП размещают под навесом на открытых огражденных площадках на территории промышленных организаций при размещении оборудования ПРГ вне зданий",
        ],
        correctAnswers: [
          "ГРП размещают встроенными в двухэтажные газифицируемые производственные здания и котельные",
        ],
      },
      {
        code: "67512",
        text: "При каком содержании кислорода в газовоздушной смеси розжиг горелок не допускается?",
        answers: [
          "Более 1,0% по объему",
          "Более 0,5% по объему",
          "Более 0,8% по объему",
        ],
        correctAnswers: ["Более 1,0% по объему"],
      },
      {
        code: "67513",
        text: "Каким образом допускается транзитная прокладка надземных газопроводов? Укажите все правильные ответы.",
        answers: [
          "Газопроводом всех давлений по стенам помещения категории А здания ГРП",
          "По стенам одного жилого здания газопроводом среднего давления номинальным диаметром 80 мм и на расстоянии ниже кровли (карниза) не менее 0,2 м",
          "Газопроводом номинальным диаметром от 100 до 200 мм по стенам административных и бытовых зданий",
          "Газопроводом низкого давления над кровлями общественных зданий номинальным диаметром от 150 до 200 мм",
        ],
        correctAnswers: [
          "Газопроводом всех давлений по стенам помещения категории А здания ГРП",
          "По стенам одного жилого здания газопроводом среднего давления номинальным диаметром 80 мм и на расстоянии ниже кровли (карниза) не менее 0,2 м",
        ],
      },
      {
        code: "67514",
        text: "Каким образом устанавливаются предельные сроки дальнейшей эксплуатации газопроводов?",
        answers: [
          "Предельные сроки дальнейшей эксплуатации газопроводов должны устанавливаться эксплуатирующей организацией на основании внешнего осмотра газопроводов",
          "Предельные сроки дальнейшей эксплуатации газопроводов должны устанавливаться по результатам технического диагностирования",
          "Предельные сроки дальнейшей эксплуатации газопроводов должны устанавливаться проектной организацией",
          "Предельные сроки дальнейшей эксплуатации газопроводов не должны превышать двойной продолжительности эксплуатации газопроводов, установленной при проектировании",
        ],
        correctAnswers: [
          "Предельные сроки дальнейшей эксплуатации газопроводов должны устанавливаться по результатам технического диагностирования",
        ],
      },
      {
        code: "67515",
        text: "В каком случае допускается прокладка газопроводов в тоннелях, коллекторах и каналах?",
        answers: [
          "Допускается прокладка стальных газопроводов давлением до 0,6 МПа включительно на территории промышленных организаций",
          "Не допускается ни в каком случае",
          "Допускается при отсутствии технической возможности прокладки газопроводов другим способом",
          "Допускается прокладка полиэтиленовых газопроводов давлением до 1,6 МПа включительно",
        ],
        correctAnswers: [
          "Допускается прокладка стальных газопроводов давлением до 0,6 МПа включительно на территории промышленных организаций",
        ],
      },
      {
        code: "67516",
        text: "За счет чего, в соответствии с требованиями Технического регламента о безопасности сетей газораспределения и газопотребления, обеспечивается энергетическая эффективность построенных, отремонтированных, реконструированных сетей газораспределения и газопотребления?",
        answers: [
          "За счет оснащения помещений с газоиспользующим оборудованием счетчиком расхода газа",
          "За счет их герметичности (отсутствия утечек газа)",
          "За счет бесперебойной транспортировки газа с заданными параметрами по расходу и давлению",
        ],
        correctAnswers: ["За счет их герметичности (отсутствия утечек газа)"],
      },
      {
        code: "67517",
        text: "Каким образом следует определять число баллонов в групповой баллонной установке СУГ?",
        answers: [
          "Расчетом",
          "На основании заключения специализированной сторонней организации",
          "На основании разрешения территориального органа Ростехнадзора",
          "На основании проектной документации",
        ],
        correctAnswers: ["Расчетом"],
      },
      {
        code: "67518",
        text: "Какие из перечисленных расстояний от испарительной (смесительной) установки в свету указаны верно? Укажите все правильные ответы.",
        answers: [
          "До ограждения резервуарной установки с надземными резервуарами не менее 2 м",
          "До ограждения резервуарной установки с подземными резервуарами не менее 1,5 м от края откоса засыпки резервуаров",
          "До подземных резервуаров не менее 1 м",
          "До надземных резервуаров не менее 2 м",
        ],
        correctAnswers: [
          "До подземных резервуаров не менее 1 м",
          "До надземных резервуаров не менее 2 м",
        ],
      },
      {
        code: "67519",
        text: "Каким способом могут устанавливаться резервуары для СУГ на ГНС, ГНП?",
        answers: [
          "Только подземным",
          "Только в засыпке грунтом (в обваловании)",
          "Только надземным",
          "Любым способом из перечисленных",
        ],
        correctAnswers: ["Любым способом из перечисленных"],
      },
      {
        code: "67520",
        text: "Какой воздухообмен должна обеспечивать вентиляция для помещений котельных, встраиваемых в здания другого назначения?",
        answers: [
          "Не менее пятикратного в час",
          "Не менее трехкратного в час",
          "Не менее шестикратного в час",
          "Не менее четырехкратного в час",
        ],
        correctAnswers: ["Не менее трехкратного в час"],
      },
      {
        code: "67521",
        text: "Какие требования предъявляются к проектированию взрывных предохранительных клапанов, предусматриваемых на горизонтальных участках газоходов от газоиспользующего оборудования?",
        answers: [
          "Площадь каждого взрывного предохранительного клапана должна быть не менее 0,05 м². Взрывные предохранительные клапаны должны быть оборудованы защитными устройствами на случай их срабатывания",
          "Суммарная площадь взрывных предохранительных клапанов должна быть не менее 0,9 м². Взрывные предохранительные клапаны должны быть оборудованы защитными устройствами на случай их срабатывания",
          "Суммарная площадь взрывных предохранительных клапанов должна обеспечивать полный отвод продуктов сгорания газа из газоходов",
        ],
        correctAnswers: [
          "Площадь каждого взрывного предохранительного клапана должна быть не менее 0,05 м². Взрывные предохранительные клапаны должны быть оборудованы защитными устройствами на случай их срабатывания",
        ],
      },
      {
        code: "67522",
        text: "На каких участках газоходов проектом должна предусматриваться установка предохранительных взрывных клапанов?",
        answers: [
          "На участках газоходов от газоиспользующего оборудования, расположенных горизонтально",
          "На всех участках газоходов от газоиспользующего оборудования",
          "На участках газоходов от газоиспользующего оборудования, расположенных вертикально",
        ],
        correctAnswers: [
          "На участках газоходов от газоиспользующего оборудования, расположенных горизонтально",
        ],
      },
      {
        code: "67523",
        text: "Что должно быть учтено при проектировании помещений, в которых будет размещено газоиспользующее оборудование?",
        answers: [
          "Их оснащение системами контроля загазованности (по метану и оксиду углерода) с выводом сигнала на пульт управления",
          "Их оснащение системами контроля загазованности (по метану) с выводом сигнала на пульт управления",
          "Их оснащение системами контроля загазованности (по оксиду углерода и диоксиду углерода) с выводом сигнала на пульт управления",
        ],
        correctAnswers: [
          "Их оснащение системами контроля загазованности (по метану и оксиду углерода) с выводом сигнала на пульт управления",
        ],
      },
      {
        code: "67524",
        text: "В каких местах на внутренних газопроводах проектом должна предусматриваться установка продувочных газопроводов?",
        answers: [
          "На наиболее удаленных от места ввода участках газопровода",
          "На наиболее приближенных к месту ввода участках газопровода",
          "На ответвлении к газоиспользующему оборудованию до запорной трубопроводной арматуры",
        ],
        correctAnswers: [
          "На наиболее удаленных от места ввода участках газопровода",
        ],
      },
      {
        code: "67525",
        text: "На каких газопроводах проектом должен предусматриваться штуцер с краном для отбора проб?",
        answers: [
          "На внутреннем газопроводе после отключающего устройства",
          "На продувочном газопроводе после отключающего устройства",
          "По усмотрению проектной организации",
        ],
        correctAnswers: [
          "На продувочном газопроводе после отключающего устройства",
        ],
      },
      {
        code: "67526",
        text: "Какое из перечисленных требований к проектированию внутренних газопроводов верно?",
        answers: [
          "На продувочном газопроводе должен предусматриваться штуцер с краном перед отключающим устройством",
          "При установке нескольких единиц газоиспользующего оборудования должна быть обеспечена возможность отключения каждой единицы оборудования",
          "При проектировании внутренних газопроводов по стенам зданий допускается пересечение газопроводами дверных проемов, если высота проемов не менее 3 м",
        ],
        correctAnswers: [
          "При установке нескольких единиц газоиспользующего оборудования должна быть обеспечена возможность отключения каждой единицы оборудования",
        ],
      },
      {
        code: "67527",
        text: "Допускается ли при проектировании внутренних газопроводов по стенам помещения пересечение ими оконных, дверных проемов, вентиляционных решеток?",
        answers: [
          "Не допускается ни при каких условиях",
          "Не допускается, за исключением переплетов и импостов неоткрывающихся окон и оконных проемов, заполненных стеклоблоками",
          "Допускается, если на участке пересечения газопровод не будет иметь разъемных соединений",
        ],
        correctAnswers: [
          "Не допускается, за исключением переплетов и импостов неоткрывающихся окон и оконных проемов, заполненных стеклоблоками",
        ],
      },
      {
        code: "67528",
        text: "В каком из перечисленных случаев не допускается проектирование прокладки внутренних газопроводов?",
        answers: [
          "Через вентиляционные камеры, шахты и каналы",
          "В помещениях категорий В, Г и Д по взрывопожарной опасности",
          "В складских помещениях категорий Г и Д",
        ],
        correctAnswers: ["Через вентиляционные камеры, шахты и каналы"],
      },
      {
        code: "67529",
        text: "В каких из перечисленных помещений допускается проектирование прокладки внутренних газопроводов?",
        answers: [
          "В помещениях категорий В, Г и Д по взрывопожарной опасности",
          "В помещениях категорий А и Б по взрывопожарной опасности",
          "В подвальных, цокольных этажах и технических этажах, расположенных ниже 1-го этажа здания и предназначенных для размещения инженерного оборудования",
          "В складских помещениях категорий А, Б и В1 - В3",
        ],
        correctAnswers: [
          "В помещениях категорий В, Г и Д по взрывопожарной опасности",
        ],
      },
      {
        code: "67530",
        text: "Каким должно быть максимальное значение величины давления природного газа в сетях газопотребления газоиспользующего оборудования в котельных, пристроенных к жилым зданиям, крышным котельным жилых зданий?",
        answers: ["0,6 МПа", "2,5 МПа", "1,2 МПа", "0,005 МПа"],
        correctAnswers: ["0,005 МПа"],
      },
      {
        code: "67531",
        text: "Для каких технологических устройств газопроводов не допускается проектирование обводных газопроводов с запорной арматурой, предназначенных для транспортирования природного газа, минуя основной газопровод на участке его ремонта, и для возвращения потока газа в сеть в конце участка?",
        answers: [
          "В блочных газорегуляторных пунктах",
          "Во встроенных и пристроенных газорегуляторных пунктах и газорегуляторных установках",
          "В газорегуляторных пунктах всех видов и газорегуляторных установках",
        ],
        correctAnswers: [
          "В газорегуляторных пунктах всех видов и газорегуляторных установках",
        ],
      },
      {
        code: "67532",
        text: "Какое из приведенных требований к размещению газорегуляторных установок верно?",
        answers: [
          "Давление природного газа на входе в газорегуляторную установку не должно превышать 0,3 МПа",
          "Допускается проектировать размещение газорегуляторных установок в помещениях категорий А и Б по взрывопожарной опасности",
          "Газорегуляторные установки разрешается размещать в помещениях, в которых устанавливается газоиспользующее оборудование, или в смежных помещениях, соединенных с ними открытыми проемами",
        ],
        correctAnswers: [
          "Газорегуляторные установки разрешается размещать в помещениях, в которых устанавливается газоиспользующее оборудование, или в смежных помещениях, соединенных с ними открытыми проемами",
        ],
      },
      {
        code: "67533",
        text: "К каким зданиям допускается пристраивать газорегуляторные пункты блочные?",
        answers: [
          "К газифицируемым производственным зданиям, котельным и общественным зданиям I и II степеней огнестойкости и класса конструктивной пожарной опасности С0 с помещениями производственного назначения категорий Г и Д",
          "Газорегуляторные пункты блочные должны размещаться отдельно стоящими",
          "К зданиям, для газификации которых они предназначены",
        ],
        correctAnswers: [
          "Газорегуляторные пункты блочные должны размещаться отдельно стоящими",
        ],
      },
      {
        code: "67534",
        text: "В какие здания допускается встраивать газорегуляторные пункты?",
        answers: [
          "В 1-этажные газифицируемые производственные здания и котельные (кроме помещений, расположенных в цокольных и подвальных этажах) I и II степеней огнестойкости и класса конструктивной пожарной опасности С0 с помещениями производственного назначения категорий Г и Д",
          "Встраивать газорегуляторные пункты в здания запрещается",
          "В 1-этажные газифицируемые производственные здания и котельные I и II степеней огнестойкости и класса конструктивной пожарной опасности С0 с помещениями производственного назначения категорий А и В",
        ],
        correctAnswers: [
          "В 1-этажные газифицируемые производственные здания и котельные (кроме помещений, расположенных в цокольных и подвальных этажах) I и II степеней огнестойкости и класса конструктивной пожарной опасности С0 с помещениями производственного назначения категорий Г и Д",
        ],
      },
      {
        code: "67535",
        text: "К каким зданиям допускается пристраивать газорегуляторные пункты?",
        answers: [
          "К газифицируемым производственным зданиям, котельным и общественным зданиям III и IV степеней огнестойкости и класса конструктивной пожарной опасности С1 с помещениями производственного назначения категорий А, Б и В",
          "К газифицируемым производственным зданиям, котельным и общественным зданиям I и II степеней огнестойкости и класса конструктивной пожарной опасности С0 с помещениями производственного назначения категорий Г и Д",
          "К газифицируемым производственным зданиям и котельным",
        ],
        correctAnswers: [
          "К газифицируемым производственным зданиям, котельным и общественным зданиям I и II степеней огнестойкости и класса конструктивной пожарной опасности С0 с помещениями производственного назначения категорий Г и Д",
        ],
      },
      {
        code: "67536",
        text: "Какие требования установлены к стенам и перегородкам газорегуляторного пункта, отделяющим помещение для линии редуцирования от других помещений?",
        answers: [
          "Противопожарная стена без проемов 2-го типа либо противопожарная перегородка 1-го типа",
          "Противопожарная стена 2-го типа с проемами либо противопожарная перегородка 2-го типа",
          "Противопожарная стена без проемов 1-го типа либо противопожарная перегородка 2-го типа",
        ],
        correctAnswers: [
          "Противопожарная стена без проемов 2-го типа либо противопожарная перегородка 1-го типа",
        ],
      },
      {
        code: "67537",
        text: "Что должно быть предусмотрено проектом газорегуляторного пункта в целях обеспечения взрывоустойчивости помещения для размещения линии редуцирования?",
        answers: [
          "Применение усиленных железобетонных строительных конструкций с минимальной площадью оконных проемов",
          "Выполнение стен помещения для размещения линий редуцирования из трудновоспламеняемых строительных материалов группы В1",
          "Легкосбрасываемые конструкции, площадь которых должна быть не менее 0,05 м² на 1 м³ свободного объема помещения",
        ],
        correctAnswers: [
          "Легкосбрасываемые конструкции, площадь которых должна быть не менее 0,05 м² на 1 м³ свободного объема помещения",
        ],
      },
      {
        code: "67538",
        text: "На каких участках технологических устройств проектом должна предусматриваться установка продувочных газопроводов?",
        answers: [
          "После первого отключающего устройства на участках газопровода с техническими устройствами, отключаемыми для технического обслуживания и ремонта, и на обводной линии (байпасе) между двумя отключающими устройствами",
          "Перед первым отключающим устройством и на участках газопровода с техническими устройствами, отключаемыми для технического обслуживания и ремонта",
          "После первого отключающего устройства и на участках газопровода с техническими устройствами, отключаемыми для технического обслуживания и ремонта",
        ],
        correctAnswers: [
          "После первого отключающего устройства и на участках газопровода с техническими устройствами, отключаемыми для технического обслуживания и ремонта",
        ],
      },
      {
        code: "67539",
        text: "Из каких материалов должен выполняться шкаф газорегуляторного пункта?",
        answers: [
          "Из негорючих или трудногорючих материалов",
          "Только из негорючих материалов",
          "В обоснованных случаях - из горючих материалов",
        ],
        correctAnswers: ["Только из негорючих материалов"],
      },
      {
        code: "67540",
        text: "Какие требования предъявляются Техническим регламентом о безопасности сетей газораспределения и газопотребления к конструкциям проектируемых зданий газорегуляторных пунктов, газорегуляторных пунктов блочных и пунктов учета газа?",
        answers: [
          "Конструкции должны обеспечить взрывоустойчивость зданий",
          "Строительные конструкции здания газорегуляторного пункта должны обеспечить этому зданию III и IV степени огнестойкости и класс конструктивной пожарной опасности С0",
          "Конструкции должны обеспечить удобство обслуживания и ремонта технологических устройств",
        ],
        correctAnswers: [
          "Конструкции должны обеспечить взрывоустойчивость зданий",
        ],
      },
      {
        code: "67541",
        text: "Какие требования предъявляются к строительным конструкциям проектируемого здания газорегуляторного пункта?",
        answers: [
          "Строительные конструкции должны обеспечить зданию III и IV степени огнестойкости и класс конструктивной пожарной опасности C0",
          "Строительные конструкции должны обеспечить зданию I и II степени огнестойкости и класс конструктивной пожарной опасности С0",
          "Строительные конструкции должны обеспечить зданию I и II степени огнестойкости и класс конструктивной пожарной опасности C3",
        ],
        correctAnswers: [
          "Строительные конструкции должны обеспечить зданию I и II степени огнестойкости и класс конструктивной пожарной опасности С0",
        ],
      },
      {
        code: "67542",
        text: "При каких условиях допускается проектирование транзитной прокладки наружных газопроводов?",
        answers: [
          "Для газопроводов низкого и среднего давления - по стенам и над кровлями производственных зданий, выполненных из горючих материалов групп Г1 - Г4",
          "Для газопроводов низкого давления по территориям складов легковоспламеняющихся и горючих материалов групп Г1 - Г4",
          "Для газопроводов низкого и среднего давления с номинальным размером диаметра не более 100 мм - по стенам одного жилого здания I - III степеней огнестойкости и класса конструктивной пожарной опасности С0 и на расстоянии до кровли не менее 0,2 м",
        ],
        correctAnswers: [
          "Для газопроводов низкого и среднего давления с номинальным размером диаметра не более 100 мм - по стенам одного жилого здания I - III степеней огнестойкости и класса конструктивной пожарной опасности С0 и на расстоянии до кровли не менее 0,2 м",
        ],
      },
      {
        code: "67543",
        text: "Для каких категорий наружных газопроводов не допускается проектирование их прокладки по пешеходным и автомобильным мостам, построенным из негорючих материалов?",
        answers: [
          "Для газопроводов среднего давления",
          "Для газопроводов низкого давления",
          "Для газопроводов высокого давления, превышающего 0,6 МПа",
        ],
        correctAnswers: [
          "Для газопроводов высокого давления, превышающего 0,6 МПа",
        ],
      },
      {
        code: "67544",
        text: "Допускается ли проектирование прокладки наружных газопроводов по железнодорожным мостам?",
        answers: [
          "Допускается проектирование прокладки только газопроводов низкого давления",
          "Не допускается",
          "Допускается проектирование прокладки только газопроводов среднего давления",
          "Допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "67545",
        text: "Допускается ли проектирование прокладки наружных газопроводов по стенам помещений категорий А и Б по взрывопожарной опасности?",
        answers: [
          "Не допускается",
          "Допускается проектирование прокладки только газопроводов низкого давления",
          "Не допускается проектирование газопроводов всех категорий давлений, за исключением зданий газорегуляторных пунктов и пунктов учета газа",
        ],
        correctAnswers: [
          "Не допускается проектирование газопроводов всех категорий давлений, за исключением зданий газорегуляторных пунктов и пунктов учета газа",
        ],
      },
      {
        code: "67546",
        text: "Какому из перечисленных требований должна соответствовать проектная документация на сети газораспределения?",
        answers: [
          "Проектная документация должна соответствовать требованиям законодательства о градостроительной деятельности",
          "Проектная документация должна соответствовать требованиям законодательства о промышленной безопасности",
          "Проектная документация должна соответствовать требованиям законодательства о техническом регулировании",
        ],
        correctAnswers: [
          "Проектная документация должна соответствовать требованиям законодательства о градостроительной деятельности",
        ],
      },
      {
        code: "67547",
        text: "Какие расчеты должны выполняться при проектировании газопроводов?",
        answers: [
          "Расчеты границ охранных зон газопроводов",
          "Расчеты на пропускную способность, а также расчеты на прочность и устойчивость газопроводов",
          "Расчеты на прочность и герметичность газопроводов",
        ],
        correctAnswers: [
          "Расчеты на пропускную способность, а также расчеты на прочность и устойчивость газопроводов",
        ],
      },
      {
        code: "67548",
        text: "Какой из перечисленных параметров не учитывается при расчете газопроводов на прочность и устойчивость?",
        answers: [
          "Оптимальное соотношение перепада давления на участке рассчитываемого газопровода",
          "Величина и направление действующих на газопроводы нагрузок",
          "Время действия нагрузок на газопроводы",
        ],
        correctAnswers: [
          "Оптимальное соотношение перепада давления на участке рассчитываемого газопровода",
        ],
      },
      {
        code: "67549",
        text: "Какое из перечисленных требований необходимо соблюдать при проектировании заглубления подводного перехода газопровода в дно пересекаемых водных преград?",
        answers: [
          "Заглубление подводного перехода газопровода в дно судоходных рек должно быть не менее чем на 1 м ниже профиля дна, прогнозируемого на срок эксплуатации газопровода",
          "Заглубление подводного перехода газопровода в дно всех пересекаемых водных преград должно быть не менее чем на 0,3 м ниже профиля дна, прогнозируемого на срок эксплуатации газопровода",
          "Заглубление подводного перехода, проектируемого с применением работ наклонно-направленного бурения, должно быть не менее чем на 1 м ниже профиля дна, прогнозируемого на срок эксплуатации газопровода",
        ],
        correctAnswers: [
          "Заглубление подводного перехода газопровода в дно судоходных рек должно быть не менее чем на 1 м ниже профиля дна, прогнозируемого на срок эксплуатации газопровода",
        ],
      },
      {
        code: "67550",
        text: "В каком случае при пересечении надземных газопроводов высоковольтными линиями электропередачи должны быть предусмотрены защитные устройства, предотвращающие падение на газопровод электропроводов при их обрыве?",
        answers: [
          "При отнесении газопровода к категории 1",
          "При напряжении ВЛ свыше 1 кВ",
          "При прокладке газопроводов на территории населенных пунктов",
        ],
        correctAnswers: ["При напряжении ВЛ свыше 1 кВ"],
      },
      {
        code: "67551",
        text: "Какое проектное решение должно предусматриваться в случае пересечения надземных газопроводов с высоковольтными линиями электропередачи?",
        answers: [
          "При напряжении линии электропередачи, превышающем 1 кВ, должны быть предусмотрены защитные устройства, предотвращающие падение на газопровод электропроводов при их обрыве, а также защитные устройства от падения опор линий электропередачи",
          "При напряжении линии электропередачи, превышающем 3 кВ, должны быть предусмотрены защитные устройства, предотвращающие падение на газопровод электропроводов при их обрыве, а также защитные устройства от падения опор линий электропередачи",
          "При напряжении линии электропередачи, превышающем 1 кВ, должно быть предусмотрено либо применение диэлектрических футляров на газопроводах, либо подземная прокладка газопровода на участке пересечения",
        ],
        correctAnswers: [
          "При напряжении линии электропередачи, превышающем 1 кВ, должны быть предусмотрены защитные устройства, предотвращающие падение на газопровод электропроводов при их обрыве, а также защитные устройства от падения опор линий электропередачи",
        ],
      },
      {
        code: "67552",
        text: "В каком случае не предусматриваются защитные покрытия и устройства, обеспечивающие сохранность газопровода?",
        answers: [
          "В местах входа и выхода из земли",
          "В местах прохода через стенки газовых колодцев, прохода через строительные конструкции здания",
          "В местах прохода под дорогами, железнодорожными и трамвайными путями",
          'В местах наличия подземных неразъемных соединений по типу "полиэтилен - сталь"',
          "Должны быть предусмотрены во всех случаях",
        ],
        correctAnswers: [
          'В местах наличия подземных неразъемных соединений по типу "полиэтилен - сталь"',
        ],
      },
      {
        code: "67553",
        text: "Какое проектное решение должно предусматриваться в случае пересечения полиэтиленовых газопроводов с нефтепроводами и теплотрассами?",
        answers: [
          "Запрещается проектирование пересечения полиэтиленовых газопроводов с нефтепроводами и теплотрассами",
          "Специальные проектные решения в этом случае не применяются",
          "Применение защитных покрытий или устройств, стойких к внешним воздействиям и обеспечивающих сохранность газопровода",
        ],
        correctAnswers: [
          "Применение защитных покрытий или устройств, стойких к внешним воздействиям и обеспечивающих сохранность газопровода",
        ],
      },
      {
        code: "67554",
        text: "Чем должны оснащаться технологические устройства систем газораспределения и газопотребления?",
        answers: [
          "Только молниезащитой и заземлением",
          "Только молниезащитой и вентиляцией",
          "Только заземлением и вентиляцией",
          "Молниезащитой, заземлением и вентиляцией",
        ],
        correctAnswers: ["Молниезащитой, заземлением и вентиляцией"],
      },
      {
        code: "67555",
        text: "В каком случае не допускается размещать газорегуляторные пункты шкафные на наружных стенах газифицируемых зданий?",
        answers: [
          "Если входное давление превышает 0,3 МПа",
          "Если входное давление превышает 0,6 МПа",
          "Ни в каком случае, все газорегуляторные пункты шкафные должны размещаться на отдельно стоящих опорах",
        ],
        correctAnswers: ["Если входное давление превышает 0,6 МПа"],
      },
      {
        code: "67556",
        text: "Каким должно быть давление природного газа на входе в газорегуляторную установку?",
        answers: [
          "Не должно превышать 1,2 МПа",
          "Не должно превышать 0,3 МПа",
          "Не должно превышать 1,0 МПа",
          "Не должно превышать 0,6 МПа",
        ],
        correctAnswers: ["Не должно превышать 0,6 МПа"],
      },
      {
        code: "67557",
        text: "Каким должно быть максимальное значение величины давления природного газа в сетях газопотребления газоиспользующего оборудования в котельных, отдельно стоящих на территории производственных предприятий?",
        answers: ["2,5 МПа", "1,2 МПа", "0,6 МПа", "0,005 МПа"],
        correctAnswers: ["1,2 МПа"],
      },
      {
        code: "67558",
        text: "Каким должно быть максимальное значение величины давления природного газа в сетях газопотребления газоиспользующего оборудования в котельных, отдельно стоящих на территории поселений?",
        answers: ["2,5 МПа", "1,2 МПа", "0,6 МПа", "0,005 МПа"],
        correctAnswers: ["0,6 МПа"],
      },
      {
        code: "67559",
        text: "Для каких потребителей природного газа максимальное значение величины давления в сетях газопотребления составляет 1,2 МПа?",
        answers: [
          "Газоиспользующее оборудование производственных зданий, в которых величина давления природного газа обусловлена требованиями производства",
          "Газоиспользующее оборудование котельных, пристроенных к жилым зданиям, и крышных котельных жилых зданий",
          "Газоиспользующее оборудование котельных, отдельно стоящих на территории населенных пунктов",
        ],
        correctAnswers: [
          "Газоиспользующее оборудование производственных зданий, в которых величина давления природного газа обусловлена требованиями производства",
        ],
      },
      {
        code: "67560",
        text: "Для каких потребителей природного газа максимальное значение величины давления в сетях газопотребления составляет 0,6 МПа?",
        answers: [
          "Газоиспользующее оборудование котельных, отдельно стоящих на территории производственных предприятий",
          "Газоиспользующее оборудование котельных, пристроенных к производственным зданиям, встроенных в эти здания",
          "Газоиспользующее оборудование котельных, пристроенных к общественным зданиям, встроенных в эти здания",
        ],
        correctAnswers: [
          "Газоиспользующее оборудование котельных, пристроенных к производственным зданиям, встроенных в эти здания",
        ],
      },
      {
        code: "67561",
        text: "Для каких потребителей природного газа максимальное значение величины давления в сетях газопотребления составляет 0,005 МПа?",
        answers: [
          "Газоиспользующее оборудование котельных, отдельно стоящих на территории населенных пунктов",
          "Газоиспользующее оборудование котельных, пристроенных к производственным зданиям, встроенных в эти здания, и крышных котельных производственных зданий",
          "Газоиспользующее оборудование котельных, пристроенных к жилым зданиям, и крышных котельных жилых зданий",
        ],
        correctAnswers: [
          "Газоиспользующее оборудование котельных, пристроенных к жилым зданиям, и крышных котельных жилых зданий",
        ],
      },
      {
        code: "67562",
        text: "Какой воздухообмен должна обеспечивать вентиляция для помещений котельных, в которых установлено газоиспользующее оборудование, с постоянным присутствием обслуживающего персонала?",
        answers: [
          "Не менее трехкратного в час",
          "Не менее четырехкратного в час",
          "Не менее пятикратного в час",
          "Не менее шестикратного в час",
        ],
        correctAnswers: ["Не менее трехкратного в час"],
      },
      {
        code: "67563",
        text: "Что из перечисленного должно соблюдаться при строительстве сети газораспределения и сети газопотребления?",
        answers: [
          "Только технические решения, предусмотренные проектной документацией",
          "Только требования эксплуатационной документации изготовителей газоиспользующего оборудования, технических и технологических устройств, труб, материалов и соединительных деталей",
          "Только технология строительства, монтажа, капитального ремонта и реконструкции в соответствии с проектом производства работ или технологическими картами",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "67564",
        text: "Какие требования установлены к участкам газопроводов, прокладываемых внутри защитных устройств через ограждающие строительные конструкции здания?",
        answers: [
          "Они могут иметь сварные стыки, но не должны иметь фланцевые и резьбовые соединения",
          "Они не должны иметь сварные стыки, фланцевые и резьбовые соединения",
          "Они могут иметь сварные стыки, фланцевые и резьбовые соединения",
        ],
        correctAnswers: [
          "Они не должны иметь сварные стыки, фланцевые и резьбовые соединения",
        ],
      },
      {
        code: "67565",
        text: "Какие требования установлены к участкам газопроводов, прокладываемых в каналах со съемными перекрытиями и в бороздах стен?",
        answers: [
          "Они могут иметь сварные стыки, но не должны иметь фланцевые и резьбовые соединения",
          "Они могут иметь сварные стыки, фланцевые и резьбовые соединения",
          "Они не должны иметь сварные стыки, фланцевые и резьбовые соединения",
        ],
        correctAnswers: [
          "Они могут иметь сварные стыки, но не должны иметь фланцевые и резьбовые соединения",
        ],
      },
      {
        code: "67566",
        text: "Что должна обеспечивать автоматика безопасности при ее отключении или неисправности?",
        answers: [
          "Блокировку возможности подачи природного газа на газоиспользующее оборудование в ручном режиме",
          "Подачу природного газа на газоиспользующее оборудование в ручном режиме, если отключение автоматики безопасности кратковременное",
          "Подачу природного газа в ручном режиме по обводной линии (байпасу) при условии контроля концентрации природного газа в помещении",
        ],
        correctAnswers: [
          "Блокировку возможности подачи природного газа на газоиспользующее оборудование в ручном режиме",
        ],
      },
      {
        code: "67567",
        text: "В какой форме осуществляется оценка соответствия сетей газораспределения и газопотребления требованиям Технического регламента о безопасности сетей газораспределения и газопотребления при проектировании?",
        answers: [
          "В форме государственного контроля (надзора)",
          "В форме строительного контроля",
          "В форме приемки сетей газораспределения и газопотребления",
          "В форме экспертизы проектной документации и результатов инженерных изысканий в соответствии с законодательством о градостроительной деятельности",
        ],
        correctAnswers: [
          "В форме экспертизы проектной документации и результатов инженерных изысканий в соответствии с законодательством о градостроительной деятельности",
        ],
      },
      {
        code: "67568",
        text: "Что является документальным подтверждением соответствия построенных или реконструированных сетей газораспределения и газопотребления требованиям, установленным в Техническом регламенте о безопасности сетей газораспределения и газопотребления?",
        answers: [
          "Положительное заключение экспертизы проектной документации на строительство или реконструкцию",
          "Заключение органа государственного строительного надзора",
          "Акт приемки, подписанный всеми членами приемочной комиссии",
          "Документы, подтверждающие соответствие используемых труб, технических устройств, сварочных и изоляционных материалов",
        ],
        correctAnswers: [
          "Акт приемки, подписанный всеми членами приемочной комиссии",
        ],
      },
      {
        code: "67569",
        text: "Для каких зданий допускается ввод газопроводов природного газа в помещения подвальных и цокольных этажей?",
        answers: [
          "Для зданий I - III степени огнестойкости с количеством этажей не более двух",
          "Для зданий I - II степени огнестойкости производственного назначения",
          "Для многоквартирных домов",
          "Для одноквартирных и блокированных домов",
        ],
        correctAnswers: ["Для одноквартирных и блокированных домов"],
      },
      {
        code: "67570",
        text: "В каких местах необходимо предусматривать запорную арматуру (отключающие устройства) на газопроводах?",
        answers: [
          "Перед наружным газоиспользующим оборудованием",
          "Перед пунктами редуцирования газа (далее - ПРГ), включая ПРГ предприятий, на ответвлении газопровода к которым имеется отключающее устройство на расстоянии менее 100 м от ПРГ",
          "На ответвлениях от газопроводов к поселениям, отдельным микрорайонам, кварталам, включая отдельные жилые дома с количеством проживающих более 50 человек, а также на ответвлениях к производственным потребителям и котельным",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Перед наружным газоиспользующим оборудованием"],
      },
      {
        code: "67571",
        text: "На каком расстоянии (в радиусе) от дверных и открывающихся оконных проемов следует размещать запорную арматуру на надземных газопроводах низкого давления, проложенных по стенам зданий и на опорах?",
        answers: [
          "Не менее 0,25 м",
          "Не менее 0,5 м",
          "Не менее 1 м",
          "Не менее 3 м",
          "Не менее 5 м",
        ],
        correctAnswers: ["Не менее 0,5 м"],
      },
      {
        code: "67572",
        text: "На каком расстоянии (в радиусе) от дверных и открывающихся оконных проемов следует размещать запорную арматуру на надземных газопроводах среднего давления, проложенных по стенам зданий и на опорах?",
        answers: [
          "Не менее 0,25 м",
          "Не менее 0,5 м",
          "Не менее 1 м",
          "Не менее 3 м",
          "Не менее 5 м",
        ],
        correctAnswers: ["Не менее 1 м"],
      },
      {
        code: "67573",
        text: "Где не допускается устанавливать запорную арматуру на надземных газопроводах?",
        answers: [
          "На участках транзитной прокладки по стенам жилых зданий",
          "На участках прокладки по стенам с открывающимися оконными проемами",
          "На участках транзитной прокладки по стенам зданий любого назначения",
        ],
        correctAnswers: [
          "На участках транзитной прокладки по стенам зданий любого назначения",
        ],
      },
      {
        code: "67574",
        text: "Какова минимальная глубина прокладки наружных подземных газопроводов?",
        answers: [
          "0,8 м до верха газопровода, футляра или балластирующего устройства. Допускается 0,6 м до верха трубы для стальных газопроводов на участках, где не предусмотрено движение транспорта и сельскохозяйственных машин",
          "0,8 м до верха газопровода, футляра или балластирующего устройства. Допускается 0,3 м до верха трубы для полиэтиленовых газопроводов на участках, где не предусмотрено движение транспорта и сельскохозяйственных машин",
          "0,8 м до верха газопровода, футляра или балластирующего устройства. Допускается 0,6 м до верха трубы для полиэтиленовых газопроводов на участках, где не предусмотрено движение транспорта и сельскохозяйственных машин",
        ],
        correctAnswers: [
          "0,8 м до верха газопровода, футляра или балластирующего устройства. Допускается 0,6 м до верха трубы для стальных газопроводов на участках, где не предусмотрено движение транспорта и сельскохозяйственных машин",
        ],
      },
      {
        code: "67575",
        text: "В каком месте футляра предусматривается контрольная трубка, выходящая под защитное устройство?",
        answers: [
          "На одном конце в нижней точке уклона",
          "На обоих концах",
          "На одном конце в верхней точке уклона",
          "В любом месте",
        ],
        correctAnswers: ["На одном конце в верхней точке уклона"],
      },
      {
        code: "67576",
        text: "На какое расстояние должны выводиться концы футляров в местах пересечения газопроводов с подземными коммуникационными коллекторами и каналами различного назначения (за исключением пересечений стенок газовых колодцев)?",
        answers: [
          "Не менее 1 м в обе стороны от наружных стенок пересекаемых сооружений и коммуникаций",
          "Не менее 2 м в обе стороны от наружных стенок пересекаемых сооружений и коммуникаций",
          "Не менее 3 м в обе стороны от наружных стенок пересекаемых сооружений и коммуникаций",
          "Не менее 10 м в обе стороны от наружных стенок пересекаемых сооружений и коммуникаций",
        ],
        correctAnswers: [
          "Не менее 2 м в обе стороны от наружных стенок пересекаемых сооружений и коммуникаций",
        ],
      },
      {
        code: "67577",
        text: "Каким должно быть расстояние по горизонтали (в свету) от отдельно стоящего пункта редуцирования газа до зданий и сооружений при давлении газа на вводе до 0,6 МПа?",
        answers: [
          "Не менее 5 м",
          "Не менее 10 м",
          "Не менее 15 м",
          "Расстояние не нормируется",
        ],
        correctAnswers: ["Не менее 10 м"],
      },
      {
        code: "67578",
        text: "Какое расстояние следует принимать от отдельно стоящего ГРПШ с входным давлением газа до 0,3 МПа включительно до здания, для газоснабжения которого оно предназначено?",
        answers: [
          "Не менее 10 м",
          "Не менее 5 м",
          "Не менее 3 м",
          "Расстояние не нормируется, но ГРПШ следует размещать со смещением от проемов зданий на расстояние не менее 1 м",
        ],
        correctAnswers: [
          "Расстояние не нормируется, но ГРПШ следует размещать со смещением от проемов зданий на расстояние не менее 1 м",
        ],
      },
      {
        code: "67579",
        text: "Каким должно быть расстояние по горизонтали (в свету) от отдельно стоящего пункта редуцирования газа до обочин автомобильных дорог при давлении газа на вводе до 0,6 МПа?",
        answers: [
          "Не менее 5 м",
          "Не менее 10 м",
          "Не менее 15 м",
          "Расстояние не нормируется",
        ],
        correctAnswers: ["Не менее 5 м"],
      },
      {
        code: "67580",
        text: "Каким должно быть расстояние по горизонтали (в свету) от отдельно стоящего пункта редуцирования газа до воздушных линий электропередачи при давлении газа на вводе до 0,6 МПа?",
        answers: [
          "Не менее 0,5 высоты опоры",
          "Не менее 1 высоты опоры",
          "Не менее 2 высот опоры",
          "Не менее 1,5 высоты опоры",
        ],
        correctAnswers: ["Не менее 1,5 высоты опоры"],
      },
      {
        code: "67581",
        text: "В каких местах на внутренних газопроводах должна быть установлена запорная арматура?",
        answers: [
          "Только перед газоиспользующим оборудованием и контрольно-измерительными приборами",
          "Только перед горелками и запальниками газоиспользующего оборудования",
          "Только на продувочных газопроводах",
          "Только на вводе газопровода в помещение при размещении в нем ГРУ или прибора учета газа с запорной арматурой на расстоянии более 10 м от места ввода",
          "В каждом из перечисленных мест",
        ],
        correctAnswers: ["В каждом из перечисленных мест"],
      },
      {
        code: "67582",
        text: "Где запрещается устанавливать запорную арматуру на внутренних газопроводах?",
        answers: [
          "Перед приборами учета газа (если для отключения прибора учета газа нельзя использовать запорную арматуру на вводе)",
          "Перед газоиспользующим оборудованием и контрольно-измерительными приборами",
          "На продувочных газопроводах",
          "На скрытых и транзитных участках газопровода",
        ],
        correctAnswers: ["На скрытых и транзитных участках газопровода"],
      },
      {
        code: "67583",
        text: "Какое количество сварных стыков от общего числа стыков, сваренных каждым сварщиком в течение календарного месяца, отбирается для механических испытаний?",
        answers: [
          "0,5% всех стыков",
          "0,5% всех стыков на газопроводах, не подлежащих контролю физическими методами, но не менее 2 стыков диаметром 50 мм и менее",
          "0,5% всех стыков на газопроводах, не подлежащих контролю физическими методами, но не менее 1 стыка диаметром более 50 мм",
          "0,5% от общего числа стыковых соединений, сваренных каждым сварщиком, но не менее 2 стыков диаметром 50 мм и менее и 1 стыка диаметром свыше 50 мм",
        ],
        correctAnswers: [
          "0,5% от общего числа стыковых соединений, сваренных каждым сварщиком, но не менее 2 стыков диаметром 50 мм и менее и 1 стыка диаметром свыше 50 мм",
        ],
      },
      {
        code: "67584",
        text: "Какие испытания проводятся для определения механических свойств стыков стальных труб с условным проходом до 50 мм включительно?",
        answers: [
          "На изгиб и сплющивание",
          "Только на растяжение",
          "На статическое растяжение и сплющивание",
          "На статическое растяжение и статический изгиб (загиб)",
        ],
        correctAnswers: ["На статическое растяжение и сплющивание"],
      },
      {
        code: "67585",
        text: "Какие испытания проводятся для определения механических свойств стыков стальных труб с условным проходом свыше 50 мм?",
        answers: [
          "На ударный изгиб",
          "На статическое растяжение и сплющивание",
          "На статическое растяжение и статический изгиб",
          "На растяжение, изгиб и сплющивание",
        ],
        correctAnswers: ["На статическое растяжение и статический изгиб"],
      },
      {
        code: "67586",
        text: "Какие сварные стыки стальных газопроводов природного газа не подлежат контролю физическими методами?",
        answers: [
          "Только стыки наружных и внутренних газопроводов природного газа условным проходом 50 и более, давлением до 0,1 МПа",
          "Только стыки внутренних газопроводов природного газа условным проходом 50 и более, давлением до 0,1 МПа",
          "Стыки надземных и внутренних газопроводов природного газа условным проходом 50 и более, давлением до 0,1 МПа",
        ],
        correctAnswers: [
          "Стыки надземных и внутренних газопроводов природного газа условным проходом 50 и более, давлением до 0,1 МПа",
        ],
      },
      {
        code: "67587",
        text: "Какие сварные стыки стальных газопроводов не подлежат контролю физическими методами?",
        answers: [
          "Стыки наружных и внутренних газопроводов природного газа всех давлений с условным проходом менее 50",
          "Только стыки внутренних газопроводов природного газа всех давлений с условным проходом менее 50",
          "Стыки наружных и внутренних газопроводов природного газа условным проходом 50 и более, давлением выше 0,1 МПа",
        ],
        correctAnswers: [
          "Стыки наружных и внутренних газопроводов природного газа всех давлений с условным проходом менее 50",
        ],
      },
      {
        code: "67588",
        text: "Какое количество сварных стыков от общего числа стыков, сваренных каждым сварщиком, на надземных и внутренних газопроводах давлением до 0,1 МПа и условным проходом 50 и более подлежит контролю физическими методами?",
        answers: [
          "10%, но не менее одного стыка",
          "5%, но не менее одного стыка",
          "15%, но не менее одного стыка",
          "Контролю физическими методами не подлежат",
        ],
        correctAnswers: ["Контролю физическими методами не подлежат"],
      },
      {
        code: "67589",
        text: "Какое количество сварных стыков, сваренных каждым сварщиком, на подземном стальном газопроводе давлением свыше 0,1 МПа до 0,3 МПа включительно подлежит контролю физическими методами?",
        answers: [
          "Контролю физическими методами не подлежат",
          "10% от общего числа стыков, но не менее одного стыка",
          "25% от общего числа стыков, но не менее одного стыка",
          "50% от общего числа стыков, но не менее одного стыка",
        ],
        correctAnswers: [
          "50% от общего числа стыков, но не менее одного стыка",
        ],
      },
      {
        code: "67590",
        text: "Какое количество сварных стыков от общего числа стыков, сваренных каждым сварщиком на газопроводах газорегуляторных пунктов (далее - ГРП) и ГРУ, подлежит контролю физическими методами?",
        answers: [
          "100%",
          "20%, но не менее одного стыка",
          "25%, но не менее одного стыка",
          "50%, но не менее одного стыка",
        ],
        correctAnswers: ["100%"],
      },
      {
        code: "67591",
        text: "Какое количество сварных стыков от общего числа стыков, сваренных каждым сварщиком на подземных газопроводах всех давлений, прокладываемых под магистральными дорогами и улицами с капитальными типами дорожных одежд, подлежит контролю физическими методами?",
        answers: [
          "10% от общего числа стыков, но не менее одного стыка",
          "100%",
          "25% от общего числа стыков, но не менее одного стыка",
          "50% от общего числа стыков, но не менее одного стыка",
        ],
        correctAnswers: ["100%"],
      },
      {
        code: "67592",
        text: "Какие стыки стальных газопроводов следует отбирать для проверки физическими методами контроля?",
        answers: [
          "Любые по выбору специалиста неразрушающего контроля",
          "Имеющие лучший внешний вид",
          "Имеющие худший внешний вид",
        ],
        correctAnswers: ["Имеющие худший внешний вид"],
      },
      {
        code: "67593",
        text: "Допускается ли исправление дефектов шва стыков стальных газопроводов, выполненных газовой сваркой?",
        answers: [
          "Допускается путем приварки усилительной муфты на дефектный стык",
          "Не допускается",
          "Допускается путем удаления дефектной части и заварки ее заново с последующей проверкой всего сварного стыка радиографическим методом контроля",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "67594",
        text: "Подлежат ли исправлению дефектные стыковые соединения полиэтиленовых газопроводов?",
        answers: [
          "Нет, не подлежат и должны быть удалены",
          "Подлежат исправлению путем приварки на дефектный стык усилительной муфты с закладными нагревателями",
          "Подлежат исправлению путем наложения на дефектный стык хомута или бандажа",
        ],
        correctAnswers: ["Нет, не подлежат и должны быть удалены"],
      },
      {
        code: "67595",
        text: "Кто должен проводить испытания на герметичность законченных строительством или реконструкцией газопроводов?",
        answers: [
          "Строительная организация совместно с газораспределительной организацией",
          "Эксплуатационная организация",
          "Строительная организация в присутствии представителя строительного контроля со стороны застройщика",
        ],
        correctAnswers: [
          "Строительная организация в присутствии представителя строительного контроля со стороны застройщика",
        ],
      },
      {
        code: "67596",
        text: "Какая норма испытаний на герметичность установлена для подземных стальных газопроводов с давлением до 0,1 МПа включительно?",
        answers: [
          "0,3 МПа, продолжительность испытаний - 12 часов",
          "0,3 МПа, продолжительность испытаний - 24 часа",
          "0,6 МПа, продолжительность испытаний - 24 часа",
          "0,45 МПа, продолжительность испытаний - 1 час",
        ],
        correctAnswers: ["0,6 МПа, продолжительность испытаний - 24 часа"],
      },
      {
        code: "67597",
        text: "Какая норма испытаний на герметичность установлена для подземных стальных газопроводов давлением свыше 0,1 до 0,3 МПа включительно с изоляционным покрытием из битумной мастики или полимерной липкой ленты?",
        answers: [
          "0,3 МПа, продолжительность испытаний - 24 часа",
          "0,6 МПа, продолжительность испытаний - 24 часа",
          "0,75 МПа, продолжительность испытаний - 24 часа",
          "1,5 МПа, продолжительность испытаний - 24 часа",
        ],
        correctAnswers: ["0,6 МПа, продолжительность испытаний - 24 часа"],
      },
      {
        code: "67598",
        text: "Какая норма испытаний на герметичность установлена для полиэтиленовых газопроводов с давлением до 0,1 МПа включительно?",
        answers: [
          "0,3 МПа, продолжительность испытаний - 12 часов",
          "0,3 МПа, продолжительность испытаний - 24 часа",
          "0,6 МПа, продолжительность испытаний - 24 часа",
          "0,45 МПа, продолжительность испытаний - 1 час",
        ],
        correctAnswers: ["0,3 МПа, продолжительность испытаний - 24 часа"],
      },
      {
        code: "67599",
        text: "Какая норма испытаний на герметичность установлена для надземных газопроводов с давлением до 0,1 МПа включительно?",
        answers: [
          "0,3 МПа, продолжительность испытаний - 12 часов",
          "0,3 МПа, продолжительность испытаний - 1 час",
          "0,6 МПа, продолжительность испытаний - 12 часов",
          "0,45 МПа, продолжительность испытаний - 1 час",
        ],
        correctAnswers: ["0,3 МПа, продолжительность испытаний - 1 час"],
      },
      {
        code: "67600",
        text: "Какая норма испытаний на герметичность установлена для газопроводов котельных с давлением свыше 0,1 МПа до 0,3 МПа включительно?",
        answers: [
          "0,3 МПа, продолжительность испытаний - 1 час",
          "0,1 МПа, продолжительность испытаний - 12 часов",
          "1,25 от рабочего давления, но не более 0,3 МПа, продолжительность испытаний - 12 часов",
          "1,25 от рабочего давления, но не более 0,3 МПа, продолжительность испытаний - 1 час",
        ],
        correctAnswers: [
          "1,25 от рабочего давления, но не более 0,3 МПа, продолжительность испытаний - 1 час",
        ],
      },
      {
        code: "67601",
        text: "Какая норма испытаний на герметичность установлена для газопроводов и газового оборудования ГРП давлением свыше 0,1 до 0,3 МПа включительно?",
        answers: [
          "0,3 МПа, продолжительность испытаний - 12 часов",
          "0,45 МПа, продолжительность испытаний - 24 часа",
          "0,45 МПа, продолжительность испытаний - 12 часов",
          "0,75 МПа, продолжительность испытаний - 1 час",
        ],
        correctAnswers: ["0,45 МПа, продолжительность испытаний - 12 часов"],
      },
      {
        code: "67602",
        text: "В каком случае построенные или реконструированные газопроводы должны пройти повторное испытание на герметичность?",
        answers: [
          "Если газопроводы не были введены в эксплуатацию в течение 6 месяцев со дня испытания",
          "Если газопроводы не были введены в эксплуатацию в течение 3 месяцев и срок ввода в эксплуатацию совпал с началом зимнего периода",
          "В любом случае, все газопроводы при вводе в эксплуатацию подлежат повторному испытанию на герметичность вне зависимости от того, какие результаты до этого были получены",
        ],
        correctAnswers: [
          "Если газопроводы не были введены в эксплуатацию в течение 6 месяцев со дня испытания",
        ],
      },
      {
        code: "67603",
        text: "В каком случае результаты испытаний на герметичность считаются положительными?",
        answers: [
          "Если в течение испытания не фиксируется видимое падение давления манометром класса точности 0,6",
          "Если в течение испытания падение давления не составило 1% от испытательного давления",
          "Если в течение испытания не фиксируется видимое падение давления по манометрам класса точности 0,15 и 0,6, а также жидкостным манометром падение давления фиксируется в пределах одного деления шкалы",
          "Если в течение испытания падение давления в газопроводе фиксируется в пределах одного деления шкалы по манометрам классов точности 0,15 и 0,4, а также жидкостным манометрам",
        ],
        correctAnswers: [
          "Если в течение испытания падение давления в газопроводе фиксируется в пределах одного деления шкалы по манометрам классов точности 0,15 и 0,4, а также жидкостным манометрам",
        ],
      },
      {
        code: "67604",
        text: "Что следует предпринять в случае отрицательного результата испытаний газопроводов на герметичность?",
        answers: [
          "Дефекты, обнаруженные в процессе испытаний газопроводов, следует устранять только после снижения давления в газопроводе до атмосферного. После устранения дефектов, обнаруженных в результате испытания газопровода на герметичность, следует провести повторное испытание",
          "Дефекты, обнаруженные в процессе испытаний газопроводов, следует устранять только после снижения давления до атмосферного. После устранения дефектов, обнаруженных в результате испытания газопровода на герметичность, следует выдержать газопровод в течение 10 минут под пробным давлением. Герметичность разъемных соединений следует проверить мыльной эмульсией",
          "Дефекты, обнаруженные в процессе испытаний газопроводов, следует устранять незамедлительно без снижения давления в газопроводе. После устранения дефектов, обнаруженных в результате испытания газопровода на герметичность, следует провести повторное испытание",
        ],
        correctAnswers: [
          "Дефекты, обнаруженные в процессе испытаний газопроводов, следует устранять только после снижения давления в газопроводе до атмосферного. После устранения дефектов, обнаруженных в результате испытания газопровода на герметичность, следует провести повторное испытание",
        ],
      },
      {
        code: "67605",
        text: "Какому виду контроля подлежат сварные стыки газопроводов, сваренные после испытаний на герметичность?",
        answers: [
          "Визуальному осмотру и проверке на герметичность мыльной эмульсией",
          "Только визуальному осмотру",
          "Физическому методу контроля",
        ],
        correctAnswers: ["Физическому методу контроля"],
      },
      {
        code: "67606",
        text: "Какое из перечисленных требований указано верно?",
        answers: [
          "Трассы подземных газопроводов обозначаются опознавательными знаками, нанесенными на постоянные ориентиры или железобетонные столбики высотой не менее 1,5 м (вне городских и сельских поселений), которые устанавливаются в пределах прямой видимости не реже чем через 500 м друг от друга",
          "Трассы подземных газопроводов обозначаются опознавательными знаками, нанесенными на постоянные ориентиры или железобетонные столбики высотой до 1,5 м (вне городских и сельских поселений), которые устанавливаются в пределах прямой видимости не реже чем через 1000 м друг от друга, а также в местах пересечений газопроводов с железными и автомобильными дорогами, на поворотах и у каждого сооружения газопровода (колодцев, коверов, конденсатосборников, устройств электрохимической защиты и др.)",
          "Трассы подземных газопроводов обозначаются опознавательными знаками, нанесенными на постоянные ориентиры или железобетонные столбики высотой до 1,5 м (вне городских и сельских поселений), которые устанавливаются в пределах прямой видимости не реже чем через 500 м друг от друга, а также в местах пересечений газопроводов с железными и автомобильными дорогами, на поворотах и у каждого сооружения газопровода (колодцев, коверов, конденсатосборников, устройств электрохимической защиты и др.)",
        ],
        correctAnswers: [
          "Трассы подземных газопроводов обозначаются опознавательными знаками, нанесенными на постоянные ориентиры или железобетонные столбики высотой до 1,5 м (вне городских и сельских поселений), которые устанавливаются в пределах прямой видимости не реже чем через 500 м друг от друга, а также в местах пересечений газопроводов с железными и автомобильными дорогами, на поворотах и у каждого сооружения газопровода (колодцев, коверов, конденсатосборников, устройств электрохимической защиты и др.)",
        ],
      },
      {
        code: "67607",
        text: "Кто, в соответствии с Правилами охраны газораспределительных сетей, должен устанавливать или наносить на постоянные ориентиры опознавательные знаки в период сооружения газопровода?",
        answers: [
          "Эксплуатационная организация",
          "Газораспределительная организация",
          "Строительная организация",
          "Владелец земельного участка, по которому проходит трасса газопровода",
        ],
        correctAnswers: ["Строительная организация"],
      },
      {
        code: "67608",
        text: "Какую информацию не содержит разрешение на производство работ в охранной зоне газораспределительной сети?",
        answers: [
          "Информацию о характере опасных производственных факторов",
          "Информацию о расположении трассы газопровода и условиях, в которых будут производиться работы",
          "Информацию о мерах предосторожности, наличии и содержании инструкций, которыми необходимо руководствоваться при выполнении конкретных видов работ",
          "Информацию о этапах работ, выполняемых в присутствии и под наблюдением представителя Ростехнадзора",
        ],
        correctAnswers: [
          "Информацию о этапах работ, выполняемых в присутствии и под наблюдением представителя Ростехнадзора",
        ],
      },
      {
        code: "67609",
        text: "За какое время до начала работ в охранной зоне газораспределительной сети лица, имеющие намерение производить данные работы, обязаны пригласить представителя эксплуатационной организации газораспределительной сети на место производства работ?",
        answers: [
          "Не менее чем за 3 рабочих дня",
          "Не менее чем за 3 календарных дня",
          "Не менее чем за 5 рабочих дней",
          "Не менее чем за е календарных дней",
        ],
        correctAnswers: ["Не менее чем за 3 рабочих дня"],
      },
      {
        code: "67610",
        text: "На какое расстояние ниже границ прогнозируемого размыва должен проектироваться подземный газопровод для участков, подверженных эрозии?",
        answers: [
          "На расстояние 0,5 м",
          "На расстояние 1 м",
          "На расстояние 0,7 м",
          "На расстояние 0,3 м",
        ],
        correctAnswers: ["На расстояние 0,5 м"],
      },
      {
        code: "67611",
        text: "С каким резервом по производительности предусматриваются аппараты сетей газораспределения и газопотребления газотурбинных и парогазовых установок в каждой ступени очистки газа?",
        answers: ["С 50%", "С 30%", "С 20%", "С 60%"],
        correctAnswers: ["С 50%"],
      },
      {
        code: "67612",
        text: "При обнаружении какой концентрации газа в помещениях ППГ необходимо организовать дополнительную вентиляцию помещения, выявить причину и незамедлительно устранить утечку газа?",
        answers: [
          "10% и более НКПРП",
          "20% и более НКПРП",
          "30% и более НКПРП",
          "При обнаружении любой концентрации газа",
        ],
        correctAnswers: ["10% и более НКПРП"],
      },
      {
        code: "67613",
        text: "Чем должны быть испытаны сети газораспределения и сети газопотребления по завершении строительства, реконструкции, монтажа и капитального ремонта?",
        answers: ["Воздухом", "Аргоном", "Азотом", "Любым инертным газом"],
        correctAnswers: ["Воздухом"],
      },
      {
        code: "67614",
        text: "В соответствии с чем устанавливается периодичность ремонтов оборудования ГТУ и ПГУ?",
        answers: [
          "В соответствии с графиками, утвержденными техническим руководителем ТЭС",
          "В соответствии с проектной документацией",
          "В соответствии с техническим регламентом",
          "В соответствии с Правилами безопасности сетей газораспределения и газопотребления",
        ],
        correctAnswers: [
          "В соответствии с графиками, утвержденными техническим руководителем ТЭС",
        ],
      },
      {
        code: "67615",
        text: "При какой объемной доле газа в воздухе выполнение газоопасных работ в помещениях ПРГ, колодцах, туннелях, коллекторах не допускается?",
        answers: [
          "Более 10% от НКПРП",
          "Более 20% от НКПРП",
          "Более 15% от НКПРП",
          "Более 5% от НКПРП",
        ],
        correctAnswers: ["Более 20% от НКПРП"],
      },
      {
        code: "67616",
        text: "Какой документацией определяются способы присоединения вновь построенных газопроводов к действующим?",
        answers: [
          "Проектной документацией",
          "Технической документацией",
          "Эксплуатационной документацией",
          "Распоряжением территориального органа Ростехнадзора",
        ],
        correctAnswers: ["Проектной документацией"],
      },
      {
        code: "67617",
        text: "По указанию какого лица производится снятие заглушек, установленных на ответвлениях к потребителям (вводах), после визуального осмотра и опрессовки газопровода?",
        answers: [
          "Лица, руководящего работами по пуску газа",
          "Технического руководителя",
          "Начальника цеха",
          "Начальника смены",
        ],
        correctAnswers: ["Лица, руководящего работами по пуску газа"],
      },
      {
        code: "67618",
        text: "Какое количество человек должно находиться снаружи с наветренной стороны для страховки на каждого работающего в колодцах и котлованах и недопущения к месту работы посторонних лиц при проведении газоопасных работ?",
        answers: [
          "По 1 человеку на каждого работающего",
          "По 2 человека на каждого работающего",
          "По 3 человека на каждого работающего",
          "Не регламентируется",
        ],
        correctAnswers: ["По 2 человека на каждого работающего"],
      },
      {
        code: "67619",
        text: "Какие требования к испарителям и теплообменникам для подогрева СУГ (далее - испарительные установки) указаны неверно?",
        answers: [
          "Испарительные установки, размещаемые вне помещений, располагают на расстоянии не менее 10 м от резервуаров для хранения СУГ",
          "Испарительные установки, размещаемые вне помещений, располагают на расстоянии не менее 1 м от стен здания насосно-компрессорного отделения или наполнительного цеха",
          "Испарительные установки производительностью до 200 кг/ч допускается размещать в насосно-компрессорном отделении или непосредственно на крышках горловин (на штуцерах) подземных и надземных резервуаров, а также в пределах базы хранения на расстоянии не менее 1 м от резервуаров",
          "Расстояние между испарителями принимают не менее 2 диаметров испарителя, но не менее 10 м",
        ],
        correctAnswers: [
          "Расстояние между испарителями принимают не менее 2 диаметров испарителя, но не менее 10 м",
        ],
      },
      {
        code: "67620",
        text: "С каким уклоном в сторону сливного патрубка устанавливают надземные резервуары для хранения СУГ?",
        answers: ["2 - 3%", "0,5 - 1%", "4 - 5%", "6 - 7%"],
        correctAnswers: ["2 - 3%"],
      },
      {
        code: "67621",
        text: "Какой вид запорного устройства предусматривают на газопроводах жидкой фазы для слива газа из железнодорожных цистерн в непосредственной близости от места соединения стационарных газопроводов ГНС со сливными устройствами транспортных средств?",
        answers: [
          "Обратный клапан",
          "Скоростной клапан",
          "Штуцер с запорным органом",
          "Любое из перечисленных устройств",
        ],
        correctAnswers: ["Обратный клапан"],
      },
      {
        code: "67622",
        text: "Где рекомендуется предусматривать размещение помещения для окраски баллонов при реконструкции ГНС?",
        answers: [
          "В отдельном здании",
          "Сблокированным с отделением технического освидетельствования баллонов",
          "В здании наполнительного отделения (цеха)",
          "В любом из перечисленных мест",
        ],
        correctAnswers: ["В отдельном здании"],
      },
      {
        code: "67623",
        text: "Какие типы запорной арматуры рекомендуется предусматривать при проектировании стальных и полиэтиленовых газопроводов?",
        answers: [
          "Только краны конусные сальниковые",
          "Только краны конусные натяжные",
          "Только краны шаровые, задвижки, клапаны (вентили)",
          "Все перечисленные типы",
        ],
        correctAnswers: ["Все перечисленные типы"],
      },
      {
        code: "67624",
        text: "На какой минимальной высоте от поверхности пола допускается размещать края футляров газопроводов?",
        answers: [
          "На высоте 50 мм",
          "На высоте 60 мм",
          "На высоте 70 мм",
          "На высоте 80 мм",
        ],
        correctAnswers: ["На высоте 50 мм"],
      },
      {
        code: "67625",
        text: "В каких местах не рекомендуется предусматривать наружное размещение газового счетчика?",
        answers: [
          "На стене газифицируемого здания на расстоянии по горизонтали не менее 0,5 м от дверных проемов",
          "Под проемами в стенах",
          "На отдельно стоящей опоре на территории потребителя газа",
          "На стене газифицируемого здания на расстоянии по горизонтали не менее 0,5 м от оконных проемов",
        ],
        correctAnswers: ["Под проемами в стенах"],
      },
      {
        code: "67626",
        text: "Что рекомендуется устанавливать на газовых сетях для обеспечения стабильного давления газа перед газовыми горелками газоиспользующего оборудования и котлов производственных зданий и котельных?",
        answers: [
          "Регуляторы-стабилизаторы",
          "Все перечисленное",
          "Предохранительные сбросные клапаны",
          "Предохранительно-запорные клапаны",
        ],
        correctAnswers: ["Регуляторы-стабилизаторы"],
      },
      {
        code: "67627",
        text: "При каком давлении газа допускается предусматривать присоединение КИП с помощью гибких рукавов длиной не более 3 м?",
        answers: [
          "При давлении до 0,1 МПа",
          "При давлении до 0,3 МПа",
          "При давлении до 0,5 МПа",
          "При давлении до 1,2 МПа",
        ],
        correctAnswers: ["При давлении до 0,1 МПа"],
      },
      {
        code: "67628",
        text: "Чем необходимо оборудовать помещения установок по комбинированной выработке электроэнергии и тепла?",
        answers: [
          "Только шумопоглощающими устройствами",
          "Только постоянно действующей вентиляцией с механическим побуждением, сблокированной с автоматическим запорным органом, установленным непосредственно на вводе газопровода в помещение",
          "Только системами по контролю загазованности и пожарной сигнализацией, сблокированной с автоматическим запорным органом на вводе в помещение, с выводом сигнала опасности на диспетчерский пульт",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "67629",
        text: "Какой минимальный срок службы должен быть у гибких рукавов для присоединения газоиспользующего оборудования?",
        answers: ["12 лет", "10 лет", "5 лет", "7 лет"],
        correctAnswers: ["12 лет"],
      },
      {
        code: "67630",
        text: "Какие требования к скрытой прокладке газопроводов указаны неверно?",
        answers: [
          "При скрытой прокладке газопроводов допускается пересечение газопроводов каналами других коммуникаций",
          "При прокладке газопроводов в штрабе стены размер последней принимается из условия обеспечения возможности монтажа, эксплуатации и ремонта газопроводов",
          "При прокладке газопроводов в полах монолитной конструкции толщина подстилающего слоя пола под газопроводом, а также расстояние от металлических сеток (или других конструкций, расположенных в полу) принимается не менее 5 см",
          "При прокладке газопроводов в каналах полов конструкция каналов должна исключать возможность распространения газа в конструкции полов и обеспечивать возможность осмотра и ремонта газопроводов",
        ],
        correctAnswers: [
          "При скрытой прокладке газопроводов допускается пересечение газопроводов каналами других коммуникаций",
        ],
      },
      {
        code: "67631",
        text: "Что необходимо учитывать при выборе оборудования ГРП, ГРПБ, ШРП и ГРУ?",
        answers: [
          "Только рабочее давление газа в газопроводе, к которому подключается объект",
          "Только состав газа, его плотность, температуру точки росы, теплоту сжигания (Qн)",
          "Только потери давления на трение в газопроводе от места подключения до ввода его в ГРП или подвода к ГРУ",
          "Только температурные условия эксплуатации оборудования и приборов КИП ГРП и ГРУ",
          "Все перечисленные параметры",
        ],
        correctAnswers: ["Все перечисленные параметры"],
      },
      {
        code: "67632",
        text: "Какие типы предохранительных сбросных клапанов (ПСК) могут применяться в ГРП, ГРУ, ГРПБ и ШРП?",
        answers: [
          "Мембранные и пружинные",
          "С ручным приводом",
          "Рычажные",
          "С соленоидным приводом",
        ],
        correctAnswers: ["Мембранные и пружинные"],
      },
      {
        code: "67633",
        text: "Какие регулирующие устройства могут применяться в ГРП, ГРУ, ГРПБ и ШРП?",
        answers: [
          "Только регуляторы давления газа с односедельным клапаном",
          "Только клапаны регулирующие двухседельные",
          "Только поворотные заслонки с электронным регулятором и исполнительным механизмом",
          "Все перечисленные устройства",
        ],
        correctAnswers: ["Все перечисленные устройства"],
      },
      {
        code: "67634",
        text: "Что не входит в состав оборудования ГРП, ГРУ, ГРПБ и ШРП?",
        answers: [
          "Датчики замера расхода воздуха и запыленности",
          "Запорная арматура, регуляторы давления",
          "Предохранительно-запорные клапаны, предохранительные сбросные клапаны",
          "Приборы замера расхода газа, приборы КИП",
        ],
        correctAnswers: ["Датчики замера расхода воздуха и запыленности"],
      },
      {
        code: "67635",
        text: "Что устанавливают на газопроводах для снижения напряжений, возникающих в газопроводе в результате температурных, грунтовых и т.п. воздействий, а также удобства монтажа и демонтажа арматуры?",
        answers: ["Компенсаторы", "Сальники", "Эстакады", "Скользящие опоры"],
        correctAnswers: ["Компенсаторы"],
      },
      {
        code: "67636",
        text: "Какие сведения наносятся на опознавательный знак для определения местонахождения газопровода на углах поворота трассы, местах изменения диаметра, установки арматуры и сооружений, принадлежащих газопроводу, а также на прямолинейных участках трассы (через 200 - 500 м)? Выберите два варианта ответа.",
        answers: [
          "Сведения о диаметре, давлении, глубине заложения газопровода",
          "Сведения о материале труб, расстоянии до газопровода, сооружения или характерной точки и другие сведения",
          "Сведения о протяженности трубопровода, дате очередного технического диагностирования",
          "Сведения об эксплуатирующей организации, монтажной организации и сроке службы",
        ],
        correctAnswers: [
          "Сведения о диаметре, давлении, глубине заложения газопровода",
          "Сведения о материале труб, расстоянии до газопровода, сооружения или характерной точки и другие сведения",
        ],
      },
      {
        code: "67637",
        text: "Что должны иметь контролируемые пункты на ГРС, ГРП (ГРУ) и замерных пунктах систем газораспределения?",
        answers: [
          "Только контур заземления",
          "Только отопительную систему, поддерживающую температуру в помещениях не ниже 5 °С",
          "Только телефонный ввод или каналообразующую аппаратуру радиоканала",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "67638",
        text: "Какой следует принимать скорость движения газа при выполнении гидравлического расчета надземных и внутренних газопроводов среднего давления с учетом степени шума, создаваемого движением газа?",
        answers: ["15 м/с", "20 м/с", "25 м/с", "17 м/с"],
        correctAnswers: ["15 м/с"],
      },
      {
        code: "67639",
        text: "Что должен представить приемочной комиссии генеральный подрядчик при приемке в эксплуатацию объекта системы газораспределения?",
        answers: [
          "Комплект исполнительной документации",
          "Акт приемки законченного строительством объекта системы газораспределения",
          "Договоры субподряда",
          "Все перечисленное",
        ],
        correctAnswers: ["Комплект исполнительной документации"],
      },
      {
        code: "67640",
        text: "Из каких материалов не следует сооружать колодцы на газопроводах?",
        answers: [
          "Из полиэтилена",
          "Из железобетона",
          "Из бутовых камней",
          "Из кирпича",
          "Из бетона",
        ],
        correctAnswers: ["Из полиэтилена"],
      },
      {
        code: "67641",
        text: "Какие должны быть размеры приямков для заделки стыков в траншее для газопроводов из полиэтиленовых труб всех диаметров (D)?",
        answers: [
          "Длина 0,6 м, ширина D + 0,5 м, глубина 0,2 м",
          "Длина 1,0 м, ширина D + 1,5 м, глубина 0,5 м",
          "Длина 1,0 м, ширина D + 2 м, глубина 0,7 м",
          "Длина 1,2 м, ширина D + 1 м, глубина 1,0 м",
        ],
        correctAnswers: ["Длина 0,6 м, ширина D + 0,5 м, глубина 0,2 м"],
      },
      {
        code: "67642",
        text: "Что должна предусматривать система контроля качества строительно-монтажных работ при сооружении систем газораспределения?",
        answers: [
          "Только проведение производственного контроля качества работ",
          "Только проведение ведомственного контроля за качеством работ и техникой безопасности",
          "Только проведение технического надзора со стороны эксплуатационной организации",
          "Только контроль со стороны органов Ростехнадзора России",
          "Все перечисленные мероприятия",
        ],
        correctAnswers: ["Все перечисленные мероприятия"],
      },
      {
        code: "67643",
        text: "Какие меры необходимо предпринять во время выполнения монтажных работ на газопроводе при обнаружении действующих подземных коммуникаций и других сооружений, не обозначенных в имеющейся проектной документации?",
        answers: [
          "Приостановить земляные работы, на место работы вызвать представителей организаций, эксплуатирующих эти сооружения",
          "Оградить указанные места от повреждений и продолжить выполнение работ",
          "Предпринимаемые меры определяет производитель работ в зависимости от обнаруженных коммуникаций",
          "Обратиться в территориальный орган Ростехнадзора для согласования дальнейших действий",
        ],
        correctAnswers: [
          "Приостановить земляные работы, на место работы вызвать представителей организаций, эксплуатирующих эти сооружения",
        ],
      },
      {
        code: "67644",
        text: "Какими способами происходит строительство подводных переходов газопроводов? Выберите два варианта ответа.",
        answers: [
          "Открытым (траншейным)",
          "Способом наклонно-направленного бурения (ННБ)",
          "Способом микротоннелирования",
          "Пневмопробивкой с помощью пневмопробойника",
        ],
        correctAnswers: [
          "Открытым (траншейным)",
          "Способом наклонно-направленного бурения (ННБ)",
        ],
      },
      {
        code: "67645",
        text: "В каких местах контролируют толщину битумно-мастичных покрытий на резервуарах приборным методом неразрушающего контроля с применением толщиномеров и других измерительных приборов?",
        answers: [
          "В одной точке на каждом квадратном метре поверхности, а в местах перегибов изоляционных покрытий через 1 м по длине окружности",
          "В пяти точках на 10% поверхности резервуара",
          "На каждом десятом резервуаре одной партии не менее чем в четырех точках по окружности",
          "Любым из перечисленных способов",
        ],
        correctAnswers: [
          "В одной точке на каждом квадратном метре поверхности, а в местах перегибов изоляционных покрытий через 1 м по длине окружности",
        ],
      },
      {
        code: "67646",
        text: "Какими методами определяют качество изоляционных покрытий, нанесенных на стальные трубы?",
        answers: [
          "Внешним осмотром, измерением толщины, проверкой сплошности и адгезии к металлу",
          "Измерением толщины, проверкой сплошности и адгезии к металлу",
          "Внешним осмотром и измерением толщины",
          "Внешним осмотром, измерением толщины и проверкой сплошности",
        ],
        correctAnswers: [
          "Внешним осмотром, измерением толщины, проверкой сплошности и адгезии к металлу",
        ],
      },
      {
        code: "67647",
        text: "Каким документом оформляется приемка в эксплуатацию установок электрохимической защиты?",
        answers: [
          "Актом",
          "Протоколом испытаний",
          "Распорядительным документом руководителя организации",
          "Свидетельством",
        ],
        correctAnswers: ["Актом"],
      },
      {
        code: "67648",
        text: "В соответствии с каким документом принимаются допустимые отклонения от геометрических размеров трубы или соединительной детали (толщина стенки по периметру, наружный диаметр, овальность) при входном контроле труб и соединительных деталей?",
        answers: [
          "В соответствии с ГОСТ (ТУ) на эти изделия",
          "В соответствии с разрешением Ростехнадзора",
          "В соответствии с заключением экспертной организации",
          "В соответствии с руководящим документом",
        ],
        correctAnswers: ["В соответствии с ГОСТ (ТУ) на эти изделия"],
      },
      {
        code: "67649",
        text: "Потоком воздуха какой скорости осуществляется очистка полости наружных газопроводов продувкой воздухом?",
        answers: ["15 - 20 м/с", "10 - 12 м/с", "7 - 10 м/с", "5 - 12 м/с"],
        correctAnswers: ["15 - 20 м/с"],
      },
      {
        code: "67650",
        text: "Каким образом проводится очистка полости наружных газопроводов диаметром 219 мм и более продувкой воздухом?",
        answers: [
          "Продувкой с пропуском очистных устройств",
          "Продувкой с предшествующей механизированной очисткой с помощью штанги",
          "Продувкой без пропуска очистных устройств",
          "Любым из перечисленных способов",
        ],
        correctAnswers: ["Продувкой с пропуском очистных устройств"],
      },
      {
        code: "67651",
        text: "В каких местах не рекомендуется надземная прокладка газопроводов?",
        answers: [
          "По территории поселений на опорах",
          "По стенам зданий внутри жилых дворов и кварталов",
          "На участках переходов через естественные и искусственные преграды",
          "Для межпоселковых газопроводов, расположенных в районах распространения скальных, вечномерзлых грунтов, при наличии оползней, горных выработок, карстов и т.д., где при подземной прокладке по расчетам возможно образование провалов, трещин с напряжениями в газопроводах, превышающими допустимые",
        ],
        correctAnswers: ["По территории поселений на опорах"],
      },
      {
        code: "67652",
        text: "Из каких материалов предусматривается противокоррозионная изоляция вертикальных участков подземных газопроводов и футляров (вводы в здания и ГРП, конденсатосборники, гидрозатворы и др.)?",
        answers: [
          "Из полимерных материалов",
          "Из битумной мастики",
          "Из композиционных материалов",
          "Из всех перечисленных материалов",
        ],
        correctAnswers: ["Из полимерных материалов"],
      },
      {
        code: "67653",
        text: "В каких случаях не рекомендуется применение труб из полуспокойной, кипящей углеродистой стали?",
        answers: [
          "Только при наличии вибрационных нагрузок, на подводных переходах, переходах через автомобильные I-Ill категорий и железные дороги, трамвайные пути и прокладываемых по мостам и гидротехническим сооружениям",
          "Только при изготовлении соединительных деталей, отводов и компенсирующих устройств для газопроводов среднего и высокого давления методом холодного гнутья",
          "Только для подземных газопроводов, прокладываемых в особых грунтовых условиях, на подрабатываемых территориях и в сейсмических районах с сейсмичностью площадки свыше 6 баллов",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "67654",
        text: "При какой минимальной температуре допускается применение медных газопроводов для транспортирования сернистых газов?",
        answers: ["0 °С", "-5 °С", "-10 °С", "-7 °С"],
        correctAnswers: ["0 °С"],
      },
      {
        code: "67655",
        text: "Какая документация предъявляется приемочной комиссии при приемке новых полиэтиленовых газопроводов и реконструированных методом протяжки полиэтиленовых труб изношенных газопроводов?",
        answers: [
          "Только проектная документация в полном объеме",
          "Только акт разбивки трассы, исполнительные чертежи (план, профиль) газопровода с указанием его границ (пикетажа)",
          "Только строительный паспорт газопровода, включая акт испытания его на герметичность",
          "Только российские сертификаты или технические свидетельства на примененные материалы (полиэтиленовые трубы, фитинги и т.д.)",
          "Вся перечисленная",
        ],
        correctAnswers: ["Вся перечисленная"],
      },
      {
        code: "67656",
        text: "Какие соединения полиэтиленовых газопроводов подвергаются ультразвуковому контролю?",
        answers: [
          "Соединения, выполненные в процессе строительства газопровода при помощи деталей с закладными нагревателями",
          "Соединения, выполненные в процессе строительства газопровода сваркой нагретым инструментом встык",
          "Все соединения, выполненные в процессе строительства газопровода любыми способами сварки и соответствующие требованиям визуального контроля (внешнего осмотра)",
          "Соединения, выполненные в процессе строительства газопровода сваркой нагретым инструментом встык и соответствующие требованиям визуального контроля (внешнего осмотра)",
        ],
        correctAnswers: [
          "Соединения, выполненные в процессе строительства газопровода сваркой нагретым инструментом встык и соответствующие требованиям визуального контроля (внешнего осмотра)",
        ],
      },
      {
        code: "67657",
        text: "Какие требования, предъявляемые к внешнему виду сварных соединений полиэтиленовых трубопроводов, выполненных сваркой нагретым инструментом встык, указаны верно?",
        answers: [
          "Цвет валиков должен быть одного цвета с трубой и не иметь трещин, пор, инородных включений",
          "Симметричность шва (отношение ширины наружных валиков грата к общей ширине грата) должна быть в пределах 0,5 - 0,9 в любой точке шва",
          "Смещение наружных кромок свариваемых заготовок не должно превышать 15% толщины стенки трубы (детали)",
          "Угол излома сваренных труб или трубы и соединительной детали не должен превышать 10°",
        ],
        correctAnswers: [
          "Цвет валиков должен быть одного цвета с трубой и не иметь трещин, пор, инородных включений",
        ],
      },
      {
        code: "67658",
        text: "Какие соединения полиэтиленовых газопроводов подвергаются внешнему осмотру?",
        answers: [
          "Все соединения, выполненные в процессе строительства газопровода любым способом сварки",
          "Соединения, выполненные в процессе строительства газопровода сваркой нагретым инструментом встык",
          "Соединения, выполненные в процессе строительства газопровода при помощи деталей с закладными нагревателями",
        ],
        correctAnswers: [
          "Все соединения, выполненные в процессе строительства газопровода любым способом сварки",
        ],
      },
      {
        code: "67659",
        text: "Какой документ необходимо получить строительно-монтажной организации до начала производства работ по реконструкции стальных изношенных газопроводов?",
        answers: [
          "Разрешение, выданное местной администрацией",
          "Разрешение, выданное Ростехнадзором",
          "Свидетельство Национального объединения строителей о допуске к работам по реконструкции данного участка",
          "Все перечисленные документы",
        ],
        correctAnswers: ["Разрешение, выданное местной администрацией"],
      },
      {
        code: "67660",
        text: "При какой температуре наружного воздуха рекомендуется производить работы по укладке газопроводов без подогрева при низких температурах?",
        answers: [
          "Не ниже -15 °С и не выше 30 °С",
          "Не ниже -10 °С и не выше 20 °С",
          "Не ниже -15 °С и не выше 40 °С",
          "Не ниже -25 °С и не выше 30 °С",
        ],
        correctAnswers: ["Не ниже -15 °С и не выше 30 °С"],
      },
      {
        code: "67661",
        text: "При какой минимальной температуре наружного воздуха производятся транспортировка, погрузка и разгрузка одиночных труб?",
        answers: ["-20 °С", "-25 °С", "-30 °С", "-10 °С"],
        correctAnswers: ["-20 °С"],
      },
      {
        code: "67662",
        text: "Каким образом выполняют очистку полости полиэтиленовых газопроводов? Выберите два варианта ответа.",
        answers: [
          "Продувкой воздухом скоростным потоком (15 - 20 м/с) воздуха под давлением",
          "При помощи пропуска очистных поршней из эластичных материалов",
          "Промывкой водой под давлением 1,25 рабочего",
          "Промывкой химическими средствами под давлением, равным рабочему",
          "Пропуском скребкового снаряда с металлическими лопастями",
        ],
        correctAnswers: [
          "Продувкой воздухом скоростным потоком (15 - 20 м/с) воздуха под давлением",
          "При помощи пропуска очистных поршней из эластичных материалов",
        ],
      },
      {
        code: "67663",
        text: "Какой способ монтажа газопровода рекомендуется применять (на прямых участках) при прокладке газопроводов в узкой строительной полосе?",
        answers: [
          "Методом протягивания",
          "Методом прокола",
          "Методом продавливания",
          "Методом наклонно-направленного бурения",
        ],
        correctAnswers: ["Методом протягивания"],
      },
      {
        code: "67664",
        text: "Каким образом проводят входной контроль при поступлении партии труб или соединительных деталей в строительную организацию? Выберите два варианта ответа.",
        answers: [
          "Проводят дефектоскопию неразрушающими методами контроля",
          "Испытывают выборочные образцы каждой марки (модели) из партии на прочность методом изгиба",
          "Проводят внешний осмотр",
          "Проводят измерение основных геометрических параметров изделий на соответствие нормативной документации",
          "Всеми перечисленными методами",
        ],
        correctAnswers: [
          "Проводят внешний осмотр",
          "Проводят измерение основных геометрических параметров изделий на соответствие нормативной документации",
        ],
      },
      {
        code: "67665",
        text: "Какой срок службы принимается при расчетах на прочность и устойчивость газопроводов из полиэтиленовых труб?",
        answers: ["50 лет", "30 лет", "25 лет", "15 лет"],
        correctAnswers: ["50 лет"],
      },
      {
        code: "67666",
        text: "За счет чего обеспечивается прочность и устойчивость газопроводов, проектируемых для прокладки на подрабатываемых территориях? Выберите два варианта ответа.",
        answers: [
          "За счет увеличения подвижности газопровода в грунте",
          "За счет снижения воздействия деформирующегося грунта на газопровод",
          "За счет уменьшения подвижности газопровода в грунте",
          "За счет прямолинейной укладки газопровода по дну траншеи",
        ],
        correctAnswers: [
          "За счет увеличения подвижности газопровода в грунте",
          "За счет снижения воздействия деформирующегося грунта на газопровод",
        ],
      },
      {
        code: "67667",
        text: "Какие принципы рекомендуется соблюдать при разработке проектных решений выходов и вводов газопроводов?",
        answers: [
          "Конструкция футляра должна обеспечивать тепловую изоляцию полиэтиленовых труб с целью предотвращения охлаждения трубы ниже температуры минус 15 °С",
          'Переход "полиэтилен - сталь" должен располагаться таким образом, чтобы место соединения полиэтиленовой и стальной его частей располагалось не выше уровня земли',
          "Футляр газопровода должен быть герметично заделан с двух концов. Для отбора проб воздуха допускается предусматривать контрольную трубку (штуцер)",
          'Подземный участок ввода газопровода, выполненный "свободным изгибом", должен заключаться в жесткий (пластмассовый) футляр, плотно соединяющийся с вертикальным стальным футляром',
          "Все перечисленные принципы",
        ],
        correctAnswers: ["Все перечисленные принципы"],
      },
      {
        code: "67668",
        text: "Какая информация должна содержаться на опознавательных знаках для обнаружения трасс подземных газопроводов?",
        answers: [
          "Только расстояние от газопровода",
          "Только глубина заложения газопровода",
          "Только телефон аварийно-диспетчерской службы",
          "Вся перечисленная информация",
        ],
        correctAnswers: ["Вся перечисленная информация"],
      },
    ],
    698: [
      {
        code: "67825",
        text: "На какие объекты распространяются требования Правил безопасности автогазозаправочных станций газомоторного топлива?",
        answers: [
          "На стационарные АГЗС, АГНКС, КриоАЗС, многотопливные АЗС",
          "Только на стационарные АГЗС и стационарные АГНКС",
          "На стационарные и передвижные АГЗС",
          "Только на стационарные АГЗС",
        ],
        correctAnswers: [
          "На стационарные АГЗС, АГНКС, КриоАЗС, многотопливные АЗС",
        ],
      },
      {
        code: "67826",
        text: "Что устанавливают Правила безопасности автогазозаправочных станций газомоторного топлива?",
        answers: [
          "Обязательные требования к эксплуатации автогазозаправочных станций, в том числе к системам безопасности и работникам, а также к эксплуатации технических устройств, применяемых для сжатия и сжижения природного газа, хранения сжиженных углеводородных газов, компримированного природного газа и сжиженного природного газа на автогазозаправочных станциях",
          "Обязательные требования к эксплуатации автозаправочных станций, а также к эксплуатации технических устройств, применяемых для сжатия и сжижения природного газа, к эксплуатации газопроводов и оборудования подачи природного газа на заправочные станции",
          "Обязательные требования к эксплуатации технических устройств, применяемых для сжатия и сжижения природного газа, к эксплуатации газопроводов и оборудования подачи природного газа на заправочные станции",
          "Обязательные требования к эксплуатации автозаправочных станций, к эксплуатации газопроводов и оборудования подачи природного газа на заправочные станции",
        ],
        correctAnswers: [
          "Обязательные требования к эксплуатации автогазозаправочных станций, в том числе к системам безопасности и работникам, а также к эксплуатации технических устройств, применяемых для сжатия и сжижения природного газа, хранения сжиженных углеводородных газов, компримированного природного газа и сжиженного природного газа на автогазозаправочных станциях",
        ],
      },
      {
        code: "67827",
        text: "Какие документы должны быть разработаны на автогазозаправочных станциях?",
        answers: [
          "Производственные и должностные инструкции, графики по техническому обслуживанию, текущему и капитальному ремонту",
          "Производственные инструкции и ежеквартальные планы работ по техническому обслуживанию и текущему ремонту",
          "Должностные инструкции и ежемесячные планы работ по техническому обслуживанию",
        ],
        correctAnswers: [
          "Производственные и должностные инструкции, графики по техническому обслуживанию, текущему и капитальному ремонту",
        ],
      },
      {
        code: "67828",
        text: "Что должно быть размещено на рабочих местах работников, обслуживающих автогазозаправочные станции?",
        answers: [
          "Ежеквартальные планы технического обслуживания оборудования и газопроводов",
          "Технологические схемы автогазозаправочных станций",
          "Плакаты с правилами пожарной безопасности",
          "Графики дежурств",
        ],
        correctAnswers: ["Технологические схемы автогазозаправочных станций"],
      },
      {
        code: "67829",
        text: "С какой периодичностью следует осматривать технологическое оборудование, газопроводы, арматуру, электрооборудование, вентиляционные системы, средства измерений, блокировок и сигнализации в целях выявления неисправностей и их устранения?",
        answers: ["Ежесменно", "Еженедельно", "2 раза в месяц", "Ежемесячно"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "67830",
        text: "Какие документы должно иметь оборудование, применяемое на автогазозаправочных станциях?",
        answers: [
          "Эксплуатационные паспорта, составленные организацией-владельцем",
          "Паспорта и руководства по эксплуатации организаций-изготовителей",
          "Производственные инструкции по эксплуатации",
        ],
        correctAnswers: [
          "Паспорта и руководства по эксплуатации организаций-изготовителей",
        ],
      },
      {
        code: "67831",
        text: "Что относится к системам безопасности останова станции?",
        answers: [
          "Только устройства хранения газа и технические устройства, которые распределяют (выдают, отпускают) газомоторное топливо в баллоны, установленные на транспортных средствах",
          "Только кнопка аварийной остановки технологической системы автозаправочной станции",
          "Только аварийные автоматические системы безопасности",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "67832",
        text: "В каком случае технологическая система автогазозаправочной станции должна быть повторно испытана на герметичность?",
        answers: [
          "Если объект не был введен в эксплуатацию в течение 1 месяца",
          "Если объект не был введен в эксплуатацию в течение 3 месяцев",
          "Если объект не был введен в эксплуатацию в течение 6 месяцев",
          "Повторные испытания на герметичность Правилами безопасности автогазозаправочных станций газомоторного топлива не предусмотрены",
        ],
        correctAnswers: [
          "Если объект не был введен в эксплуатацию в течение 6 месяцев",
        ],
      },
      {
        code: "67833",
        text: "Кто проводит испытания технологической системы автогазозаправочной станции перед проведением пусконаладочных работ?",
        answers: [
          "Аттестационная комиссия Ростехнадзора",
          "Строительно-монтажная организация",
          "Эксплуатационная организация",
          "Пусконаладочная организация",
          "Экспертная организация",
        ],
        correctAnswers: ["Строительно-монтажная организация"],
      },
      {
        code: "67834",
        text: "Кем утверждается программа приемочных испытаний (пусконаладочных работ) технологической системы автогазозаправочной станции?",
        answers: [
          "Застройщиком или техническим заказчиком",
          "Территориальным органом Ростехнадзора",
          "Строительно-монтажной организацией",
          "Эксплуатационной организацией",
          "Пусконаладочной организацией",
        ],
        correctAnswers: ["Застройщиком или техническим заказчиком"],
      },
      {
        code: "67835",
        text: "К какому моменту на автогазозаправочной станции должны быть назначены лица, ответственные за выполнение газоопасных работ, техническое состояние и безопасную эксплуатацию оборудования, работающего под избыточным давлением, за осуществление производственного контроля, а также лица, ответственные за безопасную эксплуатацию электрохозяйства и вентиляционного оборудования?",
        answers: [
          "К моменту приемки автозаправочной станции по окончании пусконаладочных работ и комплексного опробования",
          "К моменту завершения сооружения автозаправочной станции",
          "К моменту ввода автозаправочной станции в эксплуатацию",
          "К моменту проведения пусконаладочных работ",
        ],
        correctAnswers: ["К моменту проведения пусконаладочных работ"],
      },
      {
        code: "67836",
        text: "К какому моменту должна быть обеспечена подготовка и аттестация работников автогазозаправочной станции в области промышленной безопасности, проведена проверка знаний Правил безопасности автогазозаправочных станций газомоторного топлива?",
        answers: [
          "К моменту приемки автозаправочной станции по окончании пусконаладочных работ и комплексного опробования",
          "К моменту ввода автозаправочной станции в эксплуатацию",
          "К моменту проведения пусконаладочных работ",
        ],
        correctAnswers: ["К моменту проведения пусконаладочных работ"],
      },
      {
        code: "67837",
        text: ". Какие требования устанавливаются к контрольной опрессовке газопроводов, резервуаров и технических устройств автогазозаправочной станции?",
        answers: [
          "Контрольная опрессовка осуществляется избыточным давлением воздуха или инертного газа 0,3 МПа в течение часа",
          "Контрольная опрессовка осуществляется избыточным давлением воздуха или инертного газа 0,5 МПа в течение часа",
          "Контрольная опрессовка осуществляется избыточным давлением инертного газа 0,1 МПа в течение часа",
          "Контрольная опрессовка осуществляется избыточным давлением воздуха 1 МПа в течение 30 минут",
        ],
        correctAnswers: [
          "Контрольная опрессовка осуществляется избыточным давлением воздуха или инертного газа 0,3 МПа в течение часа",
        ],
      },
      {
        code: "67838",
        text: "В каком случае результаты испытания на герметичность газопроводов, резервуаров и технических устройств автогазозаправочной станции считаются положительными?",
        answers: [
          "Если падение давления в газопроводе по манометру класса точности 0,6 не превышает одного деления шкалы, по манометру класса точности 0,4 - двух, а по манометру класса точности 0,15 - трех делений шкалы",
          "При отсутствии видимого падения давления в газопроводе по манометру класса точности 0,6, а по манометрам класса точности 0,15 и 0,4 - если падение давления не превышает одного деления шкалы",
          "Если падение давления в газопроводе по манометру класса точности 0,6 не превышает одного деления шкалы, а по манометрам класса точности 0,4 и 0,15 не превышает двух делений шкалы",
          "Только при отсутствии видимого падения давления в газопроводе",
        ],
        correctAnswers: [
          "При отсутствии видимого падения давления в газопроводе по манометру класса точности 0,6, а по манометрам класса точности 0,15 и 0,4 - если падение давления не превышает одного деления шкалы",
        ],
      },
      {
        code: "67839",
        text: "Каким веществом разбавляют пенообразующий раствор при контрольной опрессовке сварных стыков, резьбовых и фланцевых соединений, сальниковых уплотнений газопроводов и газового оборудования автогазозаправочных станций в условиях отрицательных температур?",
        answers: [
          "Этиленгликолем",
          "Бензпиреном",
          "Глицерином",
          "Изопреном",
          "Спиртом",
        ],
        correctAnswers: ["Спиртом"],
      },
      {
        code: "67840",
        text: "Кто является ответственным за безопасное проведение пусконаладочных работ на автогазозаправочной станции?",
        answers: [
          "Технический руководитель автозаправочной станции",
          "Руководитель пусконаладочной организации",
          "Руководитель пусконаладочной бригады",
          "Руководитель автозаправочной станции",
        ],
        correctAnswers: ["Руководитель пусконаладочной бригады"],
      },
      {
        code: "67841",
        text: "Допускается ли работа автогазозаправочных станций при отключении от сетей водоснабжения?",
        answers: [
          "Допускается при наличии аварийного запаса воды для технологических нужд",
          "Допускается при возможности подвоза воды в автоцистернах",
          "Не допускается",
          "Допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "67842",
        text: "Допускается ли работа автогазозаправочных станций при грозовых явлениях?",
        answers: [
          "Допускается при условии выполнения дополнительных мероприятий по обеспечению безопасности проведения работ",
          "Допускается при условии оформления соответствующего наряда-допуска на выполнение работ",
          "Не допускается",
          "Допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "67843",
        text: "Допускается ли эксплуатация отдельных элементов автогазозаправочных станций при срабатывании автоматики безопасности?",
        answers: [
          "Допускается, если не отключились резервные и аварийные источники питания",
          "Не допускается до устранения причин ее срабатывания",
          "Допускается, если не завершены технологические процессы",
          "Допускается",
        ],
        correctAnswers: ["Не допускается до устранения причин ее срабатывания"],
      },
      {
        code: "67844",
        text: "Какое утверждение противоречит требованиям к эксплуатации газопроводов, арматуры и сетей инженерно-технического обеспечения на автогазозаправочных станциях?",
        answers: [
          "Перед установкой заглушек следует составить схему их установки, которая должна быть утверждена сотрудником, ответственным за подготовку технических устройств к ремонту",
          "Запрещается применение каких-либо дополнительных рычагов при закрытии или открытии запорной арматуры на газопроводах",
          "Запорную арматуру на газопроводах следует открывать и закрывать медленно во избежание гидравлического удара",
          "При разъединении фланцев в первую очередь должны быть освобождены верхние болты",
        ],
        correctAnswers: [
          "При разъединении фланцев в первую очередь должны быть освобождены верхние болты",
        ],
      },
      {
        code: "67845",
        text: "С какой периодичностью должны осматривать газопроводы и арматуру в целях выявления и устранения утечек газа?",
        answers: ["Ежесменно", "Ежесуточно", "Еженедельно", "Ежемесячно"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "67846",
        text: "В каком исполнении должны быть приборы, применяемые для выявления утечек газа?",
        answers: [
          "В воздухонепроницаемом исполнении",
          "Во взрывозащищенном исполнении",
          "В пыленепроницаемом исполнении",
          "В водонепроницаемом исполнении",
        ],
        correctAnswers: ["Во взрывозащищенном исполнении"],
      },
      {
        code: "67847",
        text: "Какие работы выполняются при проведении технического обслуживания газопроводов и арматуры?",
        answers: [
          "Только наружный осмотр газопроводов для выявления неплотностей в сварных стыках, фланцевых и резьбовых соединениях, сальниковых уплотнениях и определения состояния теплоизоляции и окраски",
          "Только осмотр и ремонт арматуры, очистка арматуры и приводного устройства от загрязнения, наледи",
          "Только проверка состояния опор трубопроводов, колодцев",
          "Все перечисленные работы",
        ],
        correctAnswers: ["Все перечисленные работы"],
      },
      {
        code: "67848",
        text: "В какие сроки проводится проверка загазованности колодцев подземных коммуникаций в пределах территории автогазозаправочных станций?",
        answers: [
          "По графику, утвержденному техническим руководителем организации, эксплуатирующей автозаправочную станцию",
          "В начале каждой недели",
          "В начале и в конце каждого месяца",
          "В начале каждого месяца",
        ],
        correctAnswers: [
          "По графику, утвержденному техническим руководителем организации, эксплуатирующей автозаправочную станцию",
        ],
      },
      {
        code: "67849",
        text: "Куда заносятся результаты проверок загазованности колодцев подземных коммуникаций на территории автогазозаправочной станции?",
        answers: [
          "В эксплуатационный журнал",
          "В ремонтный журнал",
          "В сменный журнал",
          "В график дежурств",
        ],
        correctAnswers: ["В эксплуатационный журнал"],
      },
      {
        code: "67850",
        text: "С какой периодичностью должны проверяться действие и исправность предохранительных пружинных клапанов, установленных на газопроводах, резервуарах автогазозаправочной станции?",
        answers: [
          "Еженедельно",
          "Не реже одного раза в месяц",
          "Ежеквартально",
          "Не реже одного раза в полгода",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "67851",
        text: "Каким должно быть значение давления настройки предохранительных сбросных клапанов?",
        answers: [
          "Не должно превышать более чем на 15% рабочее давление в резервуарах и газопроводах",
          "Должно составлять не более 90% максимального давления в резервуарах и газопроводах",
          "Должно быть равным максимальному давлению в резервуарах и газопроводах",
          "Не должно превышать максимальное давление в резервуарах и газопроводах",
        ],
        correctAnswers: [
          "Не должно превышать более чем на 15% рабочее давление в резервуарах и газопроводах",
        ],
      },
      {
        code: "67852",
        text: "Допускается ли работа технических устройств автогазозаправочной станции при неотрегулированных и неисправных предохранительных сбросных клапанах?",
        answers: [
          "Допускается в исключительных случаях на срок не более суток",
          "Допускается на срок не более 1 смены",
          "Допускается",
          "Не допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "67853",
        text: "С какой периодичностью на автогазозаправочной станции должна проводиться проверка параметров настройки клапанов для ПСК резервуаров и их регулировка на стенде или на месте их установки с помощью специального приспособления?",
        answers: [
          "Ежемесячно",
          "Не реже одного раза в 2 месяца",
          "Ежеквартально",
          "Не реже одного раза в 6 месяцев",
          "Ежегодно",
        ],
        correctAnswers: ["Не реже одного раза в 6 месяцев"],
      },
      {
        code: "67854",
        text: "На основании чего определяется срок проведения текущего ремонта газопроводов?",
        answers: [
          "На основании эксплуатационной документации",
          "На основании паспорта газопроводов",
          "На основании результатов осмотра",
        ],
        correctAnswers: ["На основании результатов осмотра"],
      },
      {
        code: "67855",
        text: "С какой периодичностью проводится текущий ремонт запорной арматуры на автогазозаправочных станциях?",
        answers: [
          "Ежемесячно",
          "Ежеквартально",
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в 12 месяцев",
        ],
        correctAnswers: ["Не реже одного раза в 12 месяцев"],
      },
      {
        code: "67856",
        text: "Кем на автогазозаправочной станции утверждается график работ по текущему ремонту?",
        answers: [
          "Работником, ответственным за осуществление производственного контроля за соблюдением требований промышленной безопасности",
          "Техническим руководителем организации, эксплуатирующей автозаправочную станцию",
          "Начальником ремонтного участка",
        ],
        correctAnswers: [
          "Техническим руководителем организации, эксплуатирующей автозаправочную станцию",
        ],
      },
      {
        code: "67857",
        text: "Что включает в себя капитальный ремонт газопроводов?",
        answers: [
          "Только ремонт изоляции на поврежденных участках газопровода",
          "Только замену или усиление подвижных и неподвижных опор",
          "Только замену арматуры (при наличии дефектов)",
          "Только замену участков газопроводов",
          "Все перечисленные мероприятия",
        ],
        correctAnswers: ["Все перечисленные мероприятия"],
      },
      {
        code: "67858",
        text: "Кем утверждается и с кем согласовывается проект производства работ по капитальному ремонту автогазозаправочной станции?",
        answers: [
          "Утверждается организацией, выполняющей капитальный ремонт, и согласовывается с техническим руководителем организации, эксплуатирующей автозаправочную станцию",
          "Утверждается организацией, выполняющей капитальный ремонт, и согласовывается с территориальным органом Росприроднадзора",
          "Утверждается территориальным органом Ростехнадзора и согласовывается с территориальным органом Росприроднадзора",
          "Утверждается организацией, выполняющей капитальный ремонт, и согласовывается с территориальным органом Ростехнадзора",
          "Утверждается территориальным органом Ростехнадзора и согласовывается с организацией, выполняющей ремонт",
        ],
        correctAnswers: [
          "Утверждается организацией, выполняющей капитальный ремонт, и согласовывается с техническим руководителем организации, эксплуатирующей автозаправочную станцию",
        ],
      },
      {
        code: "67859",
        text: "Кем осуществляется контроль за выполнением и приемкой работ по капитальному ремонту автогазозаправочной станции?",
        answers: [
          "Лицом, назначенным техническим руководителем организации, эксплуатирующей автогазозаправочную станцию",
          "Лицом, назначенным техническим руководителем организации, выполняющей капитальный ремонт",
          "Техническим руководителем организации, выполняющей капитальный ремонт",
          "Должностным лицом Ростехнадзора",
        ],
        correctAnswers: [
          "Лицом, назначенным техническим руководителем организации, эксплуатирующей автогазозаправочную станцию",
        ],
      },
      {
        code: "67860",
        text: "В соответствии с требованиями каких документов должен осуществляться контроль за техническим состоянием, освидетельствованием, техническим обслуживанием и ремонтом резервуаров автогазозаправочных станций?",
        answers: [
          "Федеральных норм и правил в области промышленной безопасности, устанавливающих требования при использовании оборудования, работающего под избыточным давлением, Правил безопасности автогазозаправочных станций газомоторного топлива и эксплуатационных документов организаций-изготовителей",
          "Правил промышленной безопасности опасных производственных объектов, на которых используется оборудование, работающее под избыточным давлением",
          "Правил безопасности для объектов, использующих СУГ, и эксплуатационных документов организаций-изготовителей",
          "Правил безопасности автогазозаправочных станций газомоторного топлива и проектной документации",
        ],
        correctAnswers: [
          "Федеральных норм и правил в области промышленной безопасности, устанавливающих требования при использовании оборудования, работающего под избыточным давлением, Правил безопасности автогазозаправочных станций газомоторного топлива и эксплуатационных документов организаций-изготовителей",
        ],
      },
      {
        code: "67861",
        text: "Какое избыточное давление должно быть в резервуарах (кроме новых резервуаров и резервуаров после технического освидетельствования, диагностирования и ремонта) перед их наполнением?",
        answers: [
          "Не менее 0,01 МПа",
          "Не менее 0,05 МПа",
          "Не менее 0,6 МПа",
          "Не менее 1 МПа",
        ],
        correctAnswers: ["Не менее 0,05 МПа"],
      },
      {
        code: "67862",
        text: "На основании чего резервуары автогазозаправочных станций вводятся в эксплуатацию?",
        answers: [
          "На основании письменного разрешения технического руководителя автозаправочной станции, согласованного с инспектором Ростехнадзора",
          "На основании устного разрешения руководителя специализированной организации, проводившей техническое освидетельствование",
          "На основании письменного разрешения ответственного за исправное состояние и безопасную эксплуатацию",
          "На основании устного разрешения руководителя автозаправочной станции",
          "На основании письменного разрешения инспектора Ростехнадзора",
        ],
        correctAnswers: [
          "На основании письменного разрешения ответственного за исправное состояние и безопасную эксплуатацию",
        ],
      },
      {
        code: "67863",
        text: "С какой периодичностью на автогазозаправочных станциях должна осуществляться проверка уровня газа в резервуарах?",
        answers: [
          "Ежесменно",
          "Не реже 2 раз в неделю",
          "Еженедельно",
          "Ежемесячно",
        ],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "67864",
        text: "С какой периодичностью на автогазозаправочных станциях должен осуществляться осмотр резервуаров и арматуры в целях выявления и устранения неисправностей и утечек газа?",
        answers: ["Ежесменно", "2 раза в неделю", "Еженедельно", "Ежемесячно"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "67865",
        text: "С какой периодичностью выполняются работы по проверке настройки предохранительных клапанов резервуаров на срабатывание при давлении?",
        answers: [
          "В соответствии с графиком, утверждаемым техническим руководителем АГЗС (руководителем АГЗС)",
          "Ежесменно",
          "Ежедневно",
          "Еженедельно",
          "Ежемесячно",
        ],
        correctAnswers: [
          "В соответствии с графиком, утверждаемым техническим руководителем АГЗС (руководителем АГЗС)",
        ],
      },
      {
        code: "67866",
        text: "Кем на автогазозаправочной станции проводится наружный осмотр резервуаров с арматурой и контрольно-измерительными приборами в рабочем состоянии?",
        answers: [
          "Лицом, ответственным за осуществление производственного контроля за соблюдением требований промышленной безопасности",
          "Специальной комиссией, утверждаемой техническим руководителем организации",
          "Лицом, ответственным за безопасную эксплуатацию автогазозаправочной станции",
          "Любым инженерно-техническим работником",
          "Техническим руководителем организации",
        ],
        correctAnswers: [
          "Лицом, ответственным за безопасную эксплуатацию автогазозаправочной станции",
        ],
      },
      {
        code: "67867",
        text: "Какие устанавливаются нормы наполнения резервуаров жидкой фазой?",
        answers: [
          "Не более 60% геометрического объема резервуара",
          "Не более 75% геометрического объема резервуара",
          "Устанавливаются проектной документацией, в случае ее отсутствия - не более 85% геометрического объема резервуара",
          "Не более 95% геометрического объема резервуара",
        ],
        correctAnswers: [
          "Устанавливаются проектной документацией, в случае ее отсутствия - не более 85% геометрического объема резервуара",
        ],
      },
      {
        code: "67868",
        text: "Кто на автогазозаправочной станции организует контроль за исправностью средств измерений и своевременностью проведения метрологического контроля и надзора?",
        answers: [
          "Работник, ответственный за метрологическое обеспечение АГЗС",
          "Работник, ответственный за безопасную эксплуатацию АГЗС",
          "Технический руководитель АГЗС (руководитель АГЗС)",
          "Любой инженерно-технический работник",
        ],
        correctAnswers: ["Технический руководитель АГЗС (руководитель АГЗС)"],
      },
      {
        code: "67869",
        text: "Допускается ли на автогазозаправочной станции эксплуатация КИП, не имеющих поверительного клейма (наклейки, оттиска) или с просроченным поверительным клеймом, за исключением КИП, конструктивно не имеющих возможности для размещения поверительного клейма (наклейки, оттиска)?",
        answers: [
          "Допускается в течение периода, установленного техническим руководителем автозаправочной станции",
          "Допускается в течение не более 1 месяца после окончания срока эксплуатации",
          "Не допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "67870",
        text: "С какой периодичностью на автогазозаправочных станциях стационарные и переносные газоанализаторы и сигнализаторы должны проходить проверку контрольными смесями на срабатывание?",
        answers: [
          "Ежемесячно",
          "Не реже одного раза в месяц",
          "Не реже одного раза в три месяца",
          "Не реже одного раза в шесть месяцев",
          "Ежегодно",
        ],
        correctAnswers: ["Не реже одного раза в три месяца"],
      },
      {
        code: "67871",
        text: "С какой периодичностью на автогазозаправочной станции должна производиться проверка срабатывания устройств сигнализации и блокировок автоматики безопасности?",
        answers: [
          "Не реже 1 раза в смену",
          "Не реже 1 раза в неделю",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в квартал",
          "Не реже 1 раза в полугодие",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "67872",
        text: "С какой периодичностью должен проводиться контроль герметичности приборов, импульсных трубопроводов и арматуры КИП на автогазозаправочных станциях?",
        answers: [
          "Одновременно с проверкой герметичности технических устройств, но не реже одного раза в полугодие",
          "Одновременно с проверкой герметичности технических устройств, но не реже одного раза в месяц",
          "Не реже одного раза в квартал",
        ],
        correctAnswers: [
          "Одновременно с проверкой герметичности технических устройств, но не реже одного раза в месяц",
        ],
      },
      {
        code: "67873",
        text: "С какой периодичностью должен осуществляться контроль концентрации газа в производственных помещениях переносными газоанализаторами в период замены стационарных сигнализаторов загазованности?",
        answers: [
          "Через каждые 30 минут в течение рабочей смены",
          "Через каждый час в течение рабочей смены",
          "Через каждые 2 часа в течение рабочей смены",
          "Через каждые 4 часа в течение рабочей смены",
        ],
        correctAnswers: ["Через каждые 30 минут в течение рабочей смены"],
      },
      {
        code: "67874",
        text: "Какую шкалу должны иметь манометры, устанавливаемые на оборудовании и газопроводах?",
        answers: [
          "Шкалу, у которой предел измерения рабочего давления находится во второй трети шкалы",
          "Шкалу, у которой предел измерения рабочего давления находится в третьей трети шкалы",
          "Шкалу, у которой предел измерения рабочего давления находится в четвертой четверти шкалы",
        ],
        correctAnswers: [
          "Шкалу, у которой предел измерения рабочего давления находится во второй трети шкалы",
        ],
      },
      {
        code: "67875",
        text: "В каком случае средства измерения допускаются к применению?",
        answers: [
          "Стрелка при отключении не возвращается к нулевому делению шкалы на величину, не превышающую половину допускаемой погрешности прибора",
          "Просрочен срок поверки или калибровки средства измерения",
          "У средства измерения отсутствует пломба или клеймо",
          "Имеются повреждения прибора",
        ],
        correctAnswers: [
          "Стрелка при отключении не возвращается к нулевому делению шкалы на величину, не превышающую половину допускаемой погрешности прибора",
        ],
      },
      {
        code: "67876",
        text: "С какой периодичностью на автогазозаправочной станции выполняются техническое обслуживание и ремонты КИП?",
        answers: [
          "С периодичностью, установленной графиком, утвержденным техническим организации, эксплуатирующей автогазозаправочную станцию, но не реже чем указано в эксплуатационной документации изготовителей",
          "С периодичностью, установленной в стандартах на приборы",
          "Не реже 1 раза в 3 года",
          "Ежегодно",
        ],
        correctAnswers: [
          "С периодичностью, установленной графиком, утвержденным техническим организации, эксплуатирующей автогазозаправочную станцию, но не реже чем указано в эксплуатационной документации изготовителей",
        ],
      },
      {
        code: "67877",
        text: "Какие условия должны соблюдаться при замене прибора, снятого для ремонта или предоставления на поверку?",
        answers: [
          "Прибор должен быть заменен на имеющий только соответствующий предел измерения",
          "Прибор должен быть заменен на имеющий только соответствующий класс точности",
          "Прибор должен быть заменен на идентичный по условиям эксплуатации",
        ],
        correctAnswers: [
          "Прибор должен быть заменен на идентичный по условиям эксплуатации",
        ],
      },
      {
        code: "67878",
        text: "Допускается ли на автогазозаправочной станции проводить работы по регулировке и ремонту средств автоматизации, противоаварийных защит и сигнализации в условиях загазованности",
        answers: [
          "Допускается при условии постоянного контроля загазованности при помощи переносных газоанализаторов",
          "Допускается в экстренных ситуациях при соблюдении дополнительных мер безопасности",
          "Не допускается",
          "Допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "67879",
        text: "Кем выдается разрешение на пуск и остановку технологического оборудования автогазозаправочной станции?",
        answers: [
          "Работником, ответственным за производство газоопасных работ",
          "Работником, ответственным за безопасную эксплуатацию АГЗС",
          "Техническим руководителем АГЗС (руководителем АГЗС)",
          "Любым инженерно-техническим работником",
          "Сменным мастером",
        ],
        correctAnswers: ["Техническим руководителем АГЗС (руководителем АГЗС)"],
      },
      {
        code: "67880",
        text: "Допускается ли работа насосов, компрессоров и испарителей с отключенной автоматикой, аварийной сигнализацией, а также блокировкой с вентиляторами вытяжных систем?",
        answers: [
          "Допускается только по письменному распоряжению технического руководителя автозаправочной станции на время, которое требуется для ремонта автоматики, аварийной сигнализации и блокировки",
          "Не допускается",
          "Допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "67881",
        text: "Какое утверждение противоречит требованиям безопасности при заправке газобаллонных автомобилей?",
        answers: [
          "Включение АГЗС в работу до эвакуации неисправного транспортного средства на безопасное расстояние допускается только в присутствии ответственного лица",
          "При обнаружении неплотностей в газовом оборудовании автомобиля или переполнении баллона, заправляемого СПГ или СУГ, газ из него должен быть слит в резервуар",
          "Запрещается въезд на территорию автозаправочной станции на автомобиле с неисправным газобаллонным оборудованием",
          "Запрещается включение АГЗС в работу прежде эвакуации неисправного транспортного средства на безопасное расстояние",
        ],
        correctAnswers: [
          "Включение АГЗС в работу до эвакуации неисправного транспортного средства на безопасное расстояние допускается только в присутствии ответственного лица",
        ],
      },
      {
        code: "67882",
        text: "На основании какого документа проводятся газоопасные работы?",
        answers: [
          "Наряда–допуска или без оформления наряда-допуска, но с регистрацией в журнале газоопасных работ",
          "Письменного распоряжения технического руководителя автозаправочной станции",
          "Письменного распоряжения руководителя автозаправочной станции",
          "Плана производства работ",
        ],
        correctAnswers: [
          "Наряда–допуска или без оформления наряда-допуска, но с регистрацией в журнале газоопасных работ",
        ],
      },
      {
        code: "67883",
        text: "Какая газоопасная работа относится к периодически повторяющимся работам на автогазозаправочной станции?",
        answers: [
          "Ремонтные работы без применения сварки и резки в колодцах, траншеях, заглублениях",
          "Ремонт оборудования насосов, компрессоров, испарителей, резервуаров",
          "Отключение газопроводов от действующей сети и их продувка",
          "Ремонт действующих внутренних и наружных газопроводов",
          "Заполнение резервуаров газом после их ремонта",
        ],
        correctAnswers: [
          "Ремонтные работы без применения сварки и резки в колодцах, траншеях, заглублениях",
        ],
      },
      {
        code: "67884",
        text: "Какая из перечисленных газоопасных работ на автогазозаправочной станции может проводиться без оформления наряда-допуска по утвержденной производственной инструкции?",
        answers: [
          "Пуск газа в газопроводы после окончания строительства, реконструкции, капитального ремонта",
          "Подготовка к техническому освидетельствованию резервуаров",
          "Установка и снятие заглушек на действующих газопроводах",
          "Слив газа из переполненных баллонов",
        ],
        correctAnswers: ["Слив газа из переполненных баллонов"],
      },
      {
        code: "67885",
        text: "Какая из перечисленных газоопасных работ проводится по наряду-допуску и плану производства работ, утвержденному техническим руководителем АГЗС (руководителем АГЗС)?",
        answers: [
          "Установка и снятие заглушек на действующих газопроводах",
          "Раскопка грунта в местах утечки газа до ее устранения",
          "Ремонт, осмотр и проветривание колодцев",
          "Расконсервация оборудования",
        ],
        correctAnswers: ["Расконсервация оборудования"],
      },
      {
        code: "67886",
        text: "Какая из перечисленных газоопасных работ проводится по наряду-допуску и плану производства работ, утвержденному техническим руководителем АГЗС (руководителем АГЗС)?",
        answers: [
          "Подготовка к техническому освидетельствованию и техническому диагностированию резервуаров",
          "Слив газа из автоцистерн в резервуары, откачка неиспарившихся остатков",
          "Установка и снятие заглушек на действующих газопроводах",
          "Проведение пусконаладочных работ",
        ],
        correctAnswers: ["Проведение пусконаладочных работ"],
      },
      {
        code: "67887",
        text: "Какие требования устанавливаются для руководителей и инженерно-технических работников автогазозаправочных станций для их допуска к выполнению газоопасных работ?",
        answers: [
          "Должны быть обучены требованиям охраны труда и аттестованы в области промышленной безопасности в объеме Правил безопасности автогазозаправочных станций газомоторного топлива",
          "Должны пройти медицинское освидетельствование и проверку знаний в области промышленной безопасности и охраны труда при выполнении газоопасных работ",
          "Должны быть обучены правилам пользования средствами индивидуальной защиты, способам оказания первой помощи",
        ],
        correctAnswers: [
          "Должны быть обучены правилам пользования средствами индивидуальной защиты, способам оказания первой помощи",
        ],
      },
      {
        code: "67888",
        text: "Какие требования устанавливаются для рабочих автогазозаправочных станций для их допуска к выполнению газоопасных работ?",
        answers: [
          'Должны пройти профессиональное обучение по профессии "слесарь по эксплуатации и ремонту газового оборудования" (слесарь аварийно-восстановительных работ) с правом проведения газоопасных работ и иметь удостоверение, должны быть обучены правилам пользования средствами индивидуальной защиты, способам оказания первой помощи',
          "Должны быть обучены технике проведения газоопасных работ, правилам использования средств индивидуальной защиты, способам оказания первой помощи",
          "Должны быть обучены требованиям охраны труда, технике проведения газоопасных работ, способам оказания первой помощи",
        ],
        correctAnswers: [
          'Должны пройти профессиональное обучение по профессии "слесарь по эксплуатации и ремонту газового оборудования" (слесарь аварийно-восстановительных работ) с правом проведения газоопасных работ и иметь удостоверение, должны быть обучены правилам пользования средствами индивидуальной защиты, способам оказания первой помощи',
        ],
      },
      {
        code: "67889",
        text: "Кто осуществляет координацию и общее руководство газоопасными работами на автогазозаправочной станции, выполняемыми по наряду-допуску и плану производства работ?",
        answers: [
          "Руководитель газоопасной работы, назначенный техническим руководителем АГЗС",
          "Технический руководитель АГЗС (руководитель АГЗС)",
          "Работник, ответственный за пожарную безопасность",
          "Сменный мастер",
        ],
        correctAnswers: ["Технический руководитель АГЗС (руководитель АГЗС)"],
      },
      {
        code: "67890",
        text: "Как проводятся работы по устранению утечек газа и ликвидации последствий аварий?",
        answers: [
          "Проводятся без нарядов-допусков до устранения прямой угрозы причинения вреда жизни, здоровью граждан и по наряду-допуску до устранения прямой угрозы причинения вреда окружающей среде, объектам культурного наследия народов Российской Федерации, зданиям и сооружениям",
          "Проводятся без нарядов-допусков до устранения прямой угрозы причинения вреда жизни, здоровью граждан, вреда животным, растениям, окружающей среде, объектам культурного наследия народов Российской Федерации, зданиям и сооружениям",
          "Проводятся строго по наряду-допуску",
        ],
        correctAnswers: [
          "Проводятся без нарядов-допусков до устранения прямой угрозы причинения вреда жизни, здоровью граждан, вреда животным, растениям, окружающей среде, объектам культурного наследия народов Российской Федерации, зданиям и сооружениям",
        ],
      },
      {
        code: "67891",
        text: "Кем на автогазозаправочной станции выдаются наряды-допуски на проведение газоопасных работ?",
        answers: [
          "Работником, ответственным за осуществление производственного контроля за соблюдением требований промышленной безопасности",
          "Ответственным лицом, утвержденным руководителем организации/структурного подразделения, эксплуатирующей автогазозаправочные станции",
          "Работником, ответственным за безопасную эксплуатацию автозаправочной станции",
          "Сменным мастером",
        ],
        correctAnswers: [
          "Ответственным лицом, утвержденным руководителем организации/структурного подразделения, эксплуатирующей автогазозаправочные станции",
        ],
      },
      {
        code: "67892",
        text: "В каком случае наряд-допуск на производство газоопасных работ может быть продлен?",
        answers: [
          "Если работа не закончена, а условия ее проведения и характер не изменились",
          "Если увеличился объем проводимых работ",
          "Ни в каком случае",
          "В любом случае",
        ],
        correctAnswers: [
          "Если работа не закончена, а условия ее проведения и характер не изменились",
        ],
      },
      {
        code: "67893",
        text: "Кто на автогазозаправочной станции продлевает наряд-допуск на проведение газоопасных работ?",
        answers: [
          "Работник, ответственный за производство газоопасной работы",
          "Любой инженерно-технический работник",
          "Руководитель автозаправочной станции",
          "Лицо, выдавшее наряд-допуск",
          "Сменный мастер",
        ],
        correctAnswers: ["Лицо, выдавшее наряд-допуск"],
      },
      {
        code: "67894",
        text: "Какое утверждение противоречит требованиям безопасности при проведении газоопасных работ на автогазозаправочной станции?",
        answers: [
          "Перед началом газоопасной работы лицо, ответственное за ее проведение, обязано провести инструктаж исполнителей о необходимых мерах безопасности при выполнении работы, а рабочие самостоятельно обязаны проверить наличие и исправность средств индивидуальной защиты",
          "Перед началом газоопасных работ должны быть выполнены подготовительные мероприятия, предусмотренные в наряде-допуске, плане производства работ и производственных инструкциях",
          "Если работа не закончена, а условия ее проведения и характер не изменились, наряд-допуск может быть продлен лицом, его выдавшим",
          "Наряд-допуск на производство газоопасных работ может быть продлен не более чем на 1 дневную рабочую смену",
        ],
        correctAnswers: [
          "Перед началом газоопасной работы лицо, ответственное за ее проведение, обязано провести инструктаж исполнителей о необходимых мерах безопасности при выполнении работы, а рабочие самостоятельно обязаны проверить наличие и исправность средств индивидуальной защиты",
        ],
      },
      {
        code: "67895",
        text: "В течение какого срока на автогазозаправочной станции должны храниться наряды-допуски (за исключением нарядов-допусков на первичный слив газа, производство ремонтных работ с применением сварки на элементах подземных газопроводов и резервуаров)?",
        answers: [
          "В течение одного месяца",
          "В течение трех месяцев",
          "В течение шести месяцев",
          "Не менее одного года",
          "В течение десяти лет",
          "В течение всего срока эксплуатации автозаправочной станции",
        ],
        correctAnswers: ["Не менее одного года"],
      },
      {
        code: "67896",
        text: "В течение какого времени на автогазозаправочных станциях хранятся журналы регистрации нарядов-допусков на проведение газоопасных работ?",
        answers: [
          "В течение 2 лет после окончания",
          "В течение 5 лет после окончания",
          "В течение 10 лет после окончания",
          "Постоянно",
        ],
        correctAnswers: ["В течение 5 лет после окончания"],
      },
      {
        code: "67897",
        text: "Какой минимальный состав бригады установлен для выполнения газоопасных работ на автогазозаправочной станции?",
        answers: ["Два работника", "Три работника", "Четыре работника"],
        correctAnswers: ["Два работника"],
      },
      {
        code: "67898",
        text: "Какие требования установлены к составу бригады при выполнении газоопасных работ в резервуарах, помещениях заправочной станции, а также ремонта с применением газовой сварки и резки?",
        answers: [
          "Не менее двух работников под руководством аттестованного инженерно-технического работника",
          "Не менее трех работников под руководством аттестованного инженерно-технического работника",
          "Не менее трех работников под руководством квалифицированного рабочего",
          "Не менее пяти работников под руководством инженерно-технического работника",
        ],
        correctAnswers: [
          "Не менее трех работников под руководством аттестованного инженерно-технического работника",
        ],
      },
      {
        code: "67899",
        text: "Какую из газоопасных работ на автогазозаправочной станции разрешается проводить бригадой, состоящей из двух работников под руководством наиболее квалифицированного работника?",
        answers: [
          "Проведение технического обслуживания газопроводов и технических устройств",
          "Установку и снятие заглушек на действующих газопроводах",
          "Первичное наполнение резервуаров газом из автоцистерн",
        ],
        correctAnswers: [
          "Проведение технического обслуживания газопроводов и технических устройств",
        ],
      },
      {
        code: "67900",
        text: "Какую из газоопасных работ на автогазозаправочной станции разрешается проводить бригадой, состоящей из двух работников под руководством наиболее квалифицированного работника?",
        answers: [
          "Отсоединение от газопроводов технических устройств и их отдельных узлов",
          "Наполнение резервуаров газом из автоцистерн при эксплуатации",
          "Подготовку резервуаров к техническому освидетельствованию",
          "Раскопку грунта в местах утечек газа до их устранения",
        ],
        correctAnswers: [
          "Наполнение резервуаров газом из автоцистерн при эксплуатации",
        ],
      },
      {
        code: "67901",
        text: "Какое средство индивидуальной защиты должны использовать лица, первый раз входящие в замкнутое пространство для отбора проб воздуха?",
        answers: [
          "Респиратор или фильтрующий противогаз",
          "Шланговый или изолирующий противогаз",
          "Респиратор или изолирующий противогаз",
          "Шланговый противогаз",
        ],
        correctAnswers: ["Шланговый противогаз"],
      },
      {
        code: "67902",
        text: "Допускается ли на автогазозаправочных станциях проведение газоопасных работ, выполняемых по нарядам-допускам, в темное время суток?",
        answers: [
          "Допускается в исключительных случаях, если это неотложные работы, при условии выполнения дополнительных мероприятий по обеспечению безопасного проведения работ",
          "Допускается при наличии достаточного освещения",
          "Допускается в любом случае",
          "Не допускается",
        ],
        correctAnswers: [
          "Допускается в исключительных случаях, если это неотложные работы, при условии выполнения дополнительных мероприятий по обеспечению безопасного проведения работ",
        ],
      },
      {
        code: "67903",
        text: "Какое утверждение противоречит требованиям безопасности при производстве газоопасных работ на автогазозаправочной станции?",
        answers: [
          "Допускается проведение сварки и резки на действующих газопроводах, а также разборка фланцевых и резьбовых соединений после продувки воздухом",
          "Насосы и компрессоры на время производства газоопасных работ в помещении насосов и компрессоров должны быть остановлены",
          "На газопроводах у закрытых отключающих устройств должны быть установлены заглушки с хвостовиками",
          "Сбрасывать газ из участка газопровода, подлежащего ремонту, следует через продувочные свечи",
        ],
        correctAnswers: [
          "Допускается проведение сварки и резки на действующих газопроводах, а также разборка фланцевых и резьбовых соединений после продувки воздухом",
        ],
      },
      {
        code: "67904",
        text: "Чем должны продуваться газопроводы и оборудование автогазозаправочных станций перед пуском газа?",
        answers: [
          "Инертным газом",
          "Кислородом",
          "Воздухом",
          "Паром",
          "Продувка перед пуском газа не предусматривается",
        ],
        correctAnswers: ["Инертным газом"],
      },
      {
        code: "67905",
        text: "Какой документ дает право на выполнение огневых работ?",
        answers: [
          "Наряд-допуск и план производства работ, утвержденный техническим руководителем АГЗС (руководителем АГЗС)",
          "Письменное разрешение руководителя службы пожарной охраны",
          "Только наряд-допуск на проведение огневых работ",
          "Распоряжение технического руководителя АГЗС",
        ],
        correctAnswers: [
          "Наряд-допуск и план производства работ, утвержденный техническим руководителем АГЗС (руководителем АГЗС)",
        ],
      },
      {
        code: "67906",
        text: "В какое время суток должны проводиться огневые работы на автогазозаправочных станциях?",
        answers: [
          "Только в светлое время суток",
          "Только в темное время суток",
          "В светлое время суток, в исключительных случаях - в темное время суток",
        ],
        correctAnswers: ["Только в светлое время суток"],
      },
      {
        code: "67907",
        text: "Допускается ли проводить приемку газа, его слив и заправку автомобилей при проведении огневых работ на автозаправочной станции?",
        answers: [
          "Допускается, если есть специальное разрешение Государственной противопожарной службы и Ростехнадзора",
          "Допускается, если есть письменное разрешение технического руководителя автозаправочной станции",
          "Допускается, если соблюдаются дополнительные требования пожарной безопасности",
          "Не допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "67908",
        text: "В каких местах производства огневых работ на автогазозаправочной станции перед началом и во время их проведения должен проводиться анализ воздушной среды на содержание газа",
        answers: [
          "В помещениях, а также в 20-метровой зоне от рабочего места, на территории",
          "В 30-метровой зоне от рабочего места, на территории автозаправочной станции",
          "Только в помещениях, где проводятся огневые работы",
          "В 50-метровой зоне от рабочего места",
        ],
        correctAnswers: [
          "В помещениях, а также в 20-метровой зоне от рабочего места, на территории",
        ],
      },
      {
        code: "67909",
        text: "С какой периодичностью во время огневых работ на автогазозаправочной станции должен проводиться анализ воздушной среды на содержание газа?",
        answers: [
          "Не реже чем через каждые 10 минут",
          "Не реже чем через каждые 20 минут",
          "Через каждые 30 минут",
          "Не реже чем через каждый час",
        ],
        correctAnswers: ["Не реже чем через каждые 10 минут"],
      },
      {
        code: "67910",
        text: "При какой концентрации газа в воздухе огневые работы на автогазозаправочной станции в помещении (замкнутых пространствах) должны быть приостановлены?",
        answers: [
          "3% НКПР",
          "5% НКПР",
          "10% НКПР",
          "При наличии газа в воздухе, независимо от концентрации",
        ],
        correctAnswers: ["10% НКПР"],
      },
      {
        code: "67911",
        text: "Кто допускается к выполнению сварочных работ на опасном производственном объекте?",
        answers: [
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
          "Сварщики и специалисты сварочного производства, прошедшие обучение мерам пожарной безопасности в объеме пожарно-технического минимума",
          "Любые лица, обладающие необходимыми умениями и ознакомившиеся с требованиями охраны труда при производстве сварочных работ",
          "Сварщики и специалисты сварочного производства не моложе 21 года, имеющие группу по электробезопасности не ниже III",
        ],
        correctAnswers: [
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
        ],
      },
      {
        code: "67912",
        text: 'Какие требования предъявляются ФНП "Требования к производству сварочных работ на опасных производственных объектах" к сварочному оборудованию и сварочным материалам, применяемым при выполнении сварочных работ?',
        answers: [
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
          "Оборудование и материалы должны обеспечивать максимальную производительность работ",
          "Оборудование и материалы должны быть экономичными в эксплуатации",
          "Оборудование и материалы должны быть простыми в ремонте",
        ],
        correctAnswers: [
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "67913",
        text: "Какие функции обязано выполнить лицо, осуществляющее руководство сварочными работами, назначенное распорядительным документом организации или (и) должностной инструкцией которого предусмотрено руководство сварочными работами, перед выполнением сварочных работ?",
        answers: [
          "Только проверить и обеспечить соответствие численного состава и квалификации персонала сварочного производства, сборочного и сварочного оборудования, основных и сварочных материалов, применяемой технологии сварки требованиям производственно-технологической документации",
          "Только ознакомить сварщиков с требованиями технологических карт сварки, а также с внесенными в них изменениями (при наличии), с подтверждением ознакомления подписями сварщиков в применяемых ими технологических картах сварки",
          "Только организовать проведение операционного контроля",
          "Все перечисленные функции",
        ],
        correctAnswers: ["Все перечисленные функции"],
      },
      {
        code: "67914",
        text: "Какую проверку должен пройти сварщик, приступающий к сварке на конкретном объекте впервые или после перерыва в работе продолжительностью более установленного НД, независимо от наличия аттестационного удостоверения, до начала производства работ?",
        answers: [
          "Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте, с получением положительных результатов контроля их качества",
          "Проверку умения определять и устранять видимые дефекты сварного соединения",
          "Проверку знания правил пожарной безопасности",
          "Проверку знания теоретических основ сварки",
        ],
        correctAnswers: [
          "Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте, с получением положительных результатов контроля их качества",
        ],
      },
      {
        code: "67915",
        text: "Какие действия должны быть осуществлены при выполнении многопроходных швов после наложения каждого валика с поверхностью шва и кромкой разделки?",
        answers: [
          "Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)",
          "Поверхности шва и кромки разделки должны быть обработаны солидолом, литолом или тавотом",
          "Поверхности шва и кромки разделки должны быть обезжирены водными смывками",
          "Поверхности шва и кромки разделки должны быть окрашены",
        ],
        correctAnswers: [
          "Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)",
        ],
      },
      {
        code: "67916",
        text: "Каким образом допускается маркировать сварное соединение, выполненное несколькими сварщиками (бригадой сварщиков)?",
        answers: [
          "Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
          "Допускается применение клейма одного из участвовавших в сварке сварщиков по выбору руководителя сварочных работ",
          "Допускается применение только клейма сварщика, выполнившего наибольший объем работ",
          "Допускается маркировать сварное соединение произвольным способом",
        ],
        correctAnswers: [
          "Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
        ],
      },
      {
        code: "67917",
        text: "Какая документация оформляется в процессе выполнения сварочных работ?",
        answers: [
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
          "Только паспорта, акты и заключения по неразрушающему контролю",
          "Только протоколы испытаний сварных соединений",
          "Только журналы сварочных работ",
        ],
        correctAnswers: [
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "67918",
        text: "Что является идентификационным признаком оборудования для работы во взрывоопасных средах?",
        answers: [
          "Наличие средств обеспечения взрывозащиты, указанных в технической документации изготовителя, и маркировки взрывозащиты, нанесенной на оборудование",
          "Внешний вид оборудования (наличие глухих металлических корпусов, кожухов, защитных решеток и т. п.)",
          "Только наличие сертификата взрывозащиты, выданного аккредитованным органом по сертификации",
          "Только наличие маркировки взрывозащиты",
        ],
        correctAnswers: [
          "Наличие средств обеспечения взрывозащиты, указанных в технической документации изготовителя, и маркировки взрывозащиты, нанесенной на оборудование",
        ],
      },
      {
        code: "67919",
        text: 'Какое определение соответствует термину "взрывоопасная зона" в соответствии с ТР ТС 012/2011 "О безопасности оборудования для работы во взрывоопасных средах"?',
        answers: [
          "Часть замкнутого или открытого пространства, в которой присутствует или может образоваться взрывоопасная среда в объеме, требующем специальных мер защиты при конструировании, изготовлении, монтаже и эксплуатации оборудования",
          "Зона с высоким риском воспламенения окружающей взрывоопасной среды, связанного с возможностью причинения вреда и (или) нанесения ущерба",
          "Замкнутое пространство, в котором образовалась взрывоопасная среда и требуется незамедлительное применение средств взрывозащиты",
          "Зона, в которой произошло разрушение оборудования, сопровождающееся выбросом опасных веществ и отключением электроэнергии",
        ],
        correctAnswers: [
          "Часть замкнутого или открытого пространства, в которой присутствует или может образоваться взрывоопасная среда в объеме, требующем специальных мер защиты при конструировании, изготовлении, монтаже и эксплуатации оборудования",
        ],
      },
      {
        code: "67920",
        text: "При каких режимах работы должна обеспечиваться взрывобезопасность оборудования?",
        answers: [
          "В нормальных режимах работы и в пределах отклонений, установленных технической документацией изготовителя",
          "Взрывобезопасность должна быть обеспечена независимо от режимов работы",
          "Только в аварийных режимах работы",
        ],
        correctAnswers: [
          "В нормальных режимах работы и в пределах отклонений, установленных технической документацией изготовителя",
        ],
      },
      {
        code: "67921",
        text: 'Какой должна быть температура поверхности оборудования и (или) его частей с уровнем взрывозащиты "особовзрывобезопасный" ("очень высокий") и "взрывобезопасный" ("высокий")?',
        answers: [
          "Температура должна быть ниже температуры самовоспламенения окружающей взрывоопасной газовой среды и температуры самовоспламенения слоя пыли при эксплуатации",
          "Температура должна быть не выше максимальной температуры окружающей среды в нормальном режиме эксплуатации",
          "Температура должна соответствовать температуре самовоспламенения окружающей взрывоопасной газовой среды",
          "Температура должна соответствовать температуре самовоспламенения слоя пыли при эксплуатации оборудования",
        ],
        correctAnswers: [
          "Температура должна быть ниже температуры самовоспламенения окружающей взрывоопасной газовой среды и температуры самовоспламенения слоя пыли при эксплуатации",
        ],
      },
      {
        code: "67922",
        text: "Выполнение каких требований безопасности к разработке и изготовлению оборудования для работы во взрывоопасных средах не предусмотрено в ТР ТС 012/2011?",
        answers: [
          "Сохранение взрывобезопасности оборудования в изменяющихся условиях окружающей среды и при наличии внешних воздействий с учетом ограничений рабочих условий, установленных изготовителем",
          "Применение оборудования в нормальных условиях, избежание внешних воздействий, вибрации, загрязнений, грозовых и коммутационных перенапряжений и др.",
          "Взрывобезопасность оборудования при эксплуатации в течение всего предполагаемого (расчетного) срока службы",
          "Сохранение функциональности оборудования в прогнозируемых условиях окружающей среды",
        ],
        correctAnswers: [
          "Применение оборудования в нормальных условиях, избежание внешних воздействий, вибрации, загрязнений, грозовых и коммутационных перенапряжений и др.",
        ],
      },
      {
        code: "67923",
        text: "Техническая документация, которая прилагается при поставке оборудования потребителю, не включает:",
        answers: [
          "Перечень критических отказов, возможных ошибок персонала (пользователя), приводящих к аварийным режимам оборудования, и действий, предотвращающих указанные ошибки",
          "Информацию о мерах, которые следует предпринять при обнаружении неисправности оборудования",
          "Обоснование безопасности оборудования, предназначенного для работы во взрывоопасных средах",
          "Назначенные показатели срока службы и (или) назначенный ресурс",
          "Дату изготовления оборудования",
          "Требования к персоналу",
        ],
        correctAnswers: [
          "Обоснование безопасности оборудования, предназначенного для работы во взрывоопасных средах",
        ],
      },
      {
        code: "67924",
        text: 'Какие виды классификаций оборудования для работы во взрывоопасных средах не устанавливает ТР "О безопасности оборудования для работы во взрывоопасных средах"?',
        answers: [
          "Классификацию оборудования по группам (в зависимости от области применения)",
          "Классификацию оборудования по уровням взрывозащиты",
          "Классификацию оборудования по температурным классам",
          "Классификацию оборудования по давлению",
          "Классификацию взрывоопасных зон",
        ],
        correctAnswers: ["Классификацию оборудования по давлению"],
      },
      {
        code: "67925",
        text: 'Какой вид классификации оборудования для работы во взрывоопасных средах не устанавливает ТР ТС 012/2011 "О безопасности оборудования для работы во взрывоопасных средах"?',
        answers: [
          "Классификацию оборудования по группам (в зависимости от области применения)",
          "Классификацию оборудования по давлению взрывоопасной среды",
          "Классификацию оборудования по уровням и видам взрывозащиты",
          "Классификацию взрывоопасных зон",
        ],
        correctAnswers: [
          "Классификацию оборудования по давлению взрывоопасной среды",
        ],
      },
      {
        code: "67926",
        text: "К выполнению каких работ могут быть допущены сварщики и специалисты сварочного производства?",
        answers: [
          "К сварочным работам, на которые они когда-либо были аттестованы независимо от того, истек ли срок действия соответствующих аттестационных удостоверений",
          "К выполнению сварочных работ, указанных в действующих документах",
          "К сварочным работам, указанным в проекте производства работ",
          "К любым сварочным работам",
        ],
        correctAnswers: [
          "К выполнению сварочных работ, указанных в действующих документах",
        ],
      },
      {
        code: "67927",
        text: "Что из перечисленного обозначает личные шифры клейм сварщиков?",
        answers: [
          "Уникальный шифр, содержащий четырехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях",
          "Уникальный шифр, содержащий пятизначное цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и меняющийся при последующих аттестациях",
          "Шифр, содержащий произвольное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации или при приеме на работу",
          "Уникальный шифр, содержащий трехзначное буквенно-цифровое сочетание, присваиваемый сварщику при приеме на работу",
        ],
        correctAnswers: [
          "Уникальный шифр, содержащий четырехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях",
        ],
      },
      {
        code: "67928",
        text: "Что перед началом сварочных работ руководитель сварочных работ обязан проверить?",
        answers: [
          "Только исправность сварочного оборудования",
          "Только наличие на месте сварки огнетушителя",
          "Выполнение всех подготовительных мероприятий",
        ],
        correctAnswers: ["Выполнение всех подготовительных мероприятий"],
      },
      {
        code: "67929",
        text: "Какую проверку должен пройти сварщик, впервые приступающий к сварке, перед допуском к работе?",
        answers: [
          "Проверку путем выполнения и контроля допускного сварного соединения",
          "Проверку умения определять и устранять видимые дефекты сварного соединения",
          "Проверку знания правил пожарной безопасности",
          "Проверку знания теоретических основ сварки",
        ],
        correctAnswers: [
          "Проверку путем выполнения и контроля допускного сварного соединения",
        ],
      },
      {
        code: "67930",
        text: "Кто обеспечивает организацию и выполнение аттестационных процедур согласно требованиям к производству сварочных работ на опасных производственных объектах?",
        answers: [
          "Руководитель независимого аттестационного центра",
          "Технический руководитель организации",
          "Руководитель организации",
          "Инспектор Ростехнадзора",
        ],
        correctAnswers: ["Руководитель независимого аттестационного центра"],
      },
      {
        code: "67931",
        text: "Применение какой маркировки допускается при выполнении одного сварного соединения несколькими сварщиками?",
        answers: [
          "Допускается применение клейма, определенного распорядительным документом организации, выполняющей сварочные работы",
          "Допускается применение клейма одного из участвовавших в сварке сварщиков по выбору руководителя сварочных работ",
          "Должны быть поставлены клейма всех сварщиков, участвовавших в сварке",
          "Допускается применение клейма сварщика, выполнившего наибольший объем работ",
        ],
        correctAnswers: [
          "Допускается применение клейма, определенного распорядительным документом организации, выполняющей сварочные работы",
        ],
      },
      {
        code: "67932",
        text: "Что из перечисленного должно быть в требованиях по сборке деталей под сварку, содержащихся в ПТД?",
        answers: [
          "Порядок и последовательность сборки",
          "Способы крепления деталей",
          "Размеры, количество и расположение прихваток",
          "Используемые при сборке приспособления и оборудование",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "67933",
        text: "В соответствии с чьими указаниями должно содержаться в исправном состоянии, обслуживаться и эксплуатироваться сварочное оборудование?",
        answers: [
          "Ответственного за осуществление производственного контроля",
          "Производителя сварочного оборудования",
          "Руководителя независимого аттестационного центра",
          "Руководителя организации",
        ],
        correctAnswers: ["Производителя сварочного оборудования"],
      },
      {
        code: "67934",
        text: "Чем должно быть укомплектовано место производства сварочных работ?",
        answers: [
          "Сварочным оборудованием, установками пожаротушения и местной приточной вентиляции",
          "Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД",
          "Инструментом, установками пожарной сигнализации, средствами канатного доступа",
        ],
        correctAnswers: [
          "Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД",
        ],
      },
      {
        code: "67935",
        text: "Какая вентиляция должна действовать при выполнении огневых работ в помещении?",
        answers: [
          "Приточно-вытяжная вентиляция",
          "Местная приточная вентиляция",
          "Местная вытяжная вентиляция",
          "Общеобменная вентиляция",
        ],
        correctAnswers: ["Приточно-вытяжная вентиляция"],
      },
      {
        code: "67936",
        text: "Какие виды контроля должны осуществляться при подготовке и выполнении сварочных работ?",
        answers: [
          "Только операционный контроль и приемочный контроль",
          "Только входной контроль и операционный контроль",
          "Только входной контроль и приемочный контроль",
          "Все перечисленные виды контроля",
        ],
        correctAnswers: ["Все перечисленные виды контроля"],
      },
      {
        code: "67937",
        text: "Что из перечисленного должно контролироваться в процессе сварки, как минимум?",
        answers: [
          "Соответствие параметров режима сварки и технологических приемов выполнения сварного соединения",
          "Очередность выполнения сварных швов и участков наплавки",
          "Отсутствие видимых дефектов",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "67938",
        text: "Что из перечисленного должно обеспечить лицо, осуществляющее руководство сварочными работами при производстве сварочных работ?",
        answers: [
          "Только идентификацию мест расположения сварных соединений в конструкции и мест исправлений дефектов сварных соединений",
          "Только выполнение сварных соединений в соответствии с технологическими (операционными) картами сварки",
          "Только идентификацию применяемых сварочных материалов и сварочного оборудования",
          "Только регистрацию сведений о сварщиках, выполняющих сварные соединения",
          "Обеспечить все перечисленное",
        ],
        correctAnswers: ["Обеспечить все перечисленное"],
      },
      {
        code: "67939",
        text: "В течение какого времени должна сохраняться маркировка взрывозащиты, нанесенная на поверхность оборудования или табличку?",
        answers: [
          "В течение 3 лет после начала эксплуатации оборудования",
          "В течение 5 лет после начала эксплуатации оборудования",
          "В течение всего срока службы этого оборудования",
          "Не регламентируется",
        ],
        correctAnswers: ["В течение всего срока службы этого оборудования"],
      },
      {
        code: "67940",
        text: "Что должна включать маркировка, нанесенная на оборудование, предназначенное для работы во взрывоопасных средах?",
        answers: [
          "Только наименование изготовителя или его зарегистрированный товарный знак",
          "Только номер сертификата соответствия и маркировку взрывозащиты",
          "Только обозначение типа оборудования и его заводской номер",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "67941",
        text: 'Какое определение соответствует термину "аварийный режим" в соответствии с ТР ТС 012/2011 «"О безопасности оборудования для работы во взрывоопасных средах"?',
        answers: [
          "Режим, при котором характеристики оборудования для работы во взрывоопасных средах приближаются на определенные в технической документации величины к пределам ограничений, указанных изготовителем",
          "Режим, при котором характеристики оборудования для работы во взрывоопасных средах приближаются к пределам ограничений, указанных изготовителем в технической документации",
          "Режим, при котором характеристики оборудования для работы во взрывоопасных средах выходят за пределы ограничений, указанных изготовителем в технической документации",
        ],
        correctAnswers: [
          "Режим, при котором характеристики оборудования для работы во взрывоопасных средах выходят за пределы ограничений, указанных изготовителем в технической документации",
        ],
      },
      {
        code: "67942",
        text: "По каким уровням взрывозащиты не классифицируется оборудование в зависимости от опасности стать источником воспламенения и условий его применения во взрывоопасных средах?",
        answers: [
          "Взрывобезопасный (высокий)",
          "Низковзрывоопасный (умеренный)",
          "Особовзрывобезопасный (очень высокий)",
          "Повышенная надежность против взрыва (повышенный)",
        ],
        correctAnswers: ["Низковзрывоопасный (умеренный)"],
      },
      {
        code: "67943",
        text: "Какое максимальное избыточное давление установлено Правилами безопасности автогазозаправочных станций газомоторного топлива для сжиженного углеводородного газа, используемого в качестве автомобильного топлива на АГЗС?",
        answers: ["0,5 МПа", "1,6 МПа", "2 МПа", "3,2 МПа"],
        correctAnswers: ["1,6 МПа"],
      },
      {
        code: "67944",
        text: "Какое утверждение противоречит требованиям промышленной безопасности при организации ремонтных работ на АГЗС?",
        answers: [
          "Перед началом продувки и испытаний газопроводов следует определить и обозначить знаками опасные зоны, в которых запрещено находиться людям, не задействованным в проведении данных работ",
          "Газопроводы и арматура на них перед началом ремонта должны быть освобождены от СУГ и продуты инертным газом или паром",
          "Допускается заполнение СУГ резервуаров путем снижения в них давления СУГ за счет сброса паровой фазы СУГ в атмосферу",
          "Запрещается в местах забора воздуха выполнять работы, вызывающие попадание паров СУГ в воздухозаборы",
        ],
        correctAnswers: [
          "Допускается заполнение СУГ резервуаров путем снижения в них давления СУГ за счет сброса паровой фазы СУГ в атмосферу",
        ],
      },
      {
        code: "67945",
        text: "Какое утверждение противоречит требованиям промышленной безопасности при организации ремонтных работ на АГЗС?",
        answers: [
          "Газопроводы и арматура на них перед началом ремонта должны быть освобождены от СУГ и продуты воздухом после отсоединения их от элементов технологической системы с помощью заглушек",
          "Газопроводы и арматура на них перед началом ремонта должны быть освобождены от СУГ и продуты паром после отсоединения их от элементов технологической системы с помощью заглушек",
          "Заглушки, устанавливаемые на газопроводах, должны быть рассчитаны на давление 1,6 МПа и иметь хвостовики, выступающие за пределы фланцев",
          "На хвостовиках заглушек, устанавливаемых на газопроводах, должно быть выбито клеймо с указанием давления СУГ и диаметра газопровода",
          "Запрещается подчеканивать сварные швы газопроводов для устранения дефектов",
          "Запрещается подтягивать фланцевые соединения, находящиеся под давлением",
        ],
        correctAnswers: [
          "Газопроводы и арматура на них перед началом ремонта должны быть освобождены от СУГ и продуты воздухом после отсоединения их от элементов технологической системы с помощью заглушек",
        ],
      },
      {
        code: "67946",
        text: "Каким требованиям на АГЗС должны соответствовать заглушки, устанавливаемые на газопроводах СУГ?",
        answers: [
          "Должны быть рассчитаны на давление 1,6 МПа и иметь хвостовики, выступающие за пределы фланцев. На хвостовиках должно быть выбито клеймо с указанием давления СУГ и диаметра газопровода",
          "Должны быть рассчитаны на рабочее давление в газопроводе и иметь хвостовики, выступающие за пределы фланцев. На хвостовиках должно быть выбито клеймо с указанием производителя",
          "Должны быть рассчитаны на давление 2,5 МПа и иметь хвостовики, на которых должно быть выбито клеймо с указанием года установки",
          "Должны быть рассчитаны на рабочее давление в газопроводе и иметь хвостовики, не выступающие за габариты фланцев",
        ],
        correctAnswers: [
          "Должны быть рассчитаны на давление 1,6 МПа и иметь хвостовики, выступающие за пределы фланцев. На хвостовиках должно быть выбито клеймо с указанием давления СУГ и диаметра газопровода",
        ],
      },
      {
        code: "67947",
        text: "Кто дает разрешение на снятие заглушек после контрольной опрессовки отключенного участка газопровода и оборудования?",
        answers: [
          "Руководитель газоопасных работ",
          "Технический руководитель АГЗС",
          "Инспектор Ростехнадзора",
          "Руководитель АГЗС",
        ],
        correctAnswers: ["Руководитель газоопасных работ"],
      },
      {
        code: "67948",
        text: "Какая концентрация СУГ в воздухе помещений АГЗС считается опасной?",
        answers: ["1% НКПР", "3% НКПР", "5% НКПР", "7% НКПР", "10% НКПР"],
        correctAnswers: ["10% НКПР"],
      },
      {
        code: "67949",
        text: "Какая концентрация СУГ в воздухе вне помещений АГЗС считается опасной?",
        answers: ["5% НКПР", "10% НКПР", "15% НКПР", "20% НКПР"],
        correctAnswers: ["20% НКПР"],
      },
      {
        code: "67950",
        text: "Допускается ли проведение ремонтных работ на АГЗС при утечке СУГ?",
        answers: [
          "Ремонтные работы могут быть продолжены при условии обеспечения дополнительных мер безопасности",
          "Ремонтные работы могут быть продолжены при наличии разрешения технического руководителя АГЗС",
          "Ремонтные работы должны быть прекращены, а работники выведены из опасной зоны",
          "Ремонтные работы могут быть продолжены в любом случае",
        ],
        correctAnswers: [
          "Ремонтные работы должны быть прекращены, а работники выведены из опасной зоны",
        ],
      },
      {
        code: "67951",
        text: "Кем на АГЗС выдается письменное разрешение на включение в работу оборудования и газопроводов после технического обслуживания или ремонта, связанных с их остановкой и отключением СУГ?",
        answers: [
          "Начальником службы производственного контроля организации",
          "Руководителем специализированной сторонней организации",
          "Техническим руководителем АГЗС (руководителем АГЗС)",
          "Руководителем газоопасных работ",
        ],
        correctAnswers: ["Техническим руководителем АГЗС (руководителем АГЗС)"],
      },
      {
        code: "67952",
        text: "Какое утверждение противоречит требованиям промышленной безопасности при организации ремонтных работ на АГЗС, связанных со сваркой?",
        answers: [
          "Поврежденные участки газопровода и деформированные фланцевые соединения следует заменять путем вварки катушек длиной не менее одного диаметра (не менее 200 мм)",
          "Запрещается устанавливать заплаты, заваривать трещины, разрывы и дефекты на поврежденных участках газопровода",
          "Ремонт сварного соединения допускается выполнять двумя сварщиками",
        ],
        correctAnswers: [
          "Ремонт сварного соединения допускается выполнять двумя сварщиками",
        ],
      },
      {
        code: "67953",
        text: "Каким требованиям должна соответствовать катушка, ввариваемая для замены поврежденных участков газопроводов СУГ и деформированных фланцевых соединений?",
        answers: [
          "Должна быть длиной не менее одного диаметра (не менее 200 мм) и быть той же толщины, того же диаметра и выполнена из трубы той же марки стали, что и соединяемые (основные) трубы, а также с разделкой кромок (по возможности)",
          "Должна быть длиной не менее двух диаметров и быть той же толщины, что и соединяемые (основные) трубы, а также с разделкой кромок (по возможности)",
          "Должна быть длиной не менее одного диаметра (не менее 100 мм) и быть толщиной не менее 50% толщины соединяемых (основных) труб",
          "Должна быть меньшего диаметра, чем соединяемые (основные) трубы, и выполнена из трубы той же марки стали",
        ],
        correctAnswers: [
          "Должна быть длиной не менее одного диаметра (не менее 200 мм) и быть той же толщины, того же диаметра и выполнена из трубы той же марки стали, что и соединяемые (основные) трубы, а также с разделкой кромок (по возможности)",
        ],
      },
      {
        code: "67954",
        text: "Каким образом на АГЗС должны ремонтироваться поврежденные участки газопроводов и деформированные фланцевые соединения?",
        answers: [
          "Вваркой катушек длиной не менее одного диаметра (не менее 200 мм). Устанавливать заплаты, заваривать трещины, разрывы и дефекты не допускается",
          "Вваркой катушек длиной не менее половины диаметра (не менее 100 мм)",
          "Установкой заплат на разрывы и дефекты",
          "Завариванием трещин и разрывов",
        ],
        correctAnswers: [
          "Вваркой катушек длиной не менее одного диаметра (не менее 200 мм). Устанавливать заплаты, заваривать трещины, разрывы и дефекты не допускается",
        ],
      },
      {
        code: "67955",
        text: "В какое время суток осуществляется слив СУГ из автомобильных цистерн в резервуары?",
        answers: [
          "В светлое время суток или при обеспечении необходимого освещения, соответствующего освещенности в светлое время суток",
          "В любое время суток вне зависимости от условий",
          "Только в светлое время суток",
          "В темное время суток",
        ],
        correctAnswers: [
          "В светлое время суток или при обеспечении необходимого освещения, соответствующего освещенности в светлое время суток",
        ],
      },
      {
        code: "67956",
        text: "Каким должно быть количество работников, выполняющих первоначальный слив СУГ/СПГ в резервуары из автомобильных цистерн?",
        answers: [
          "Не менее двух человек",
          "Не менее трех человек",
          "Не менее четырех человек",
          "Не менее пяти человек",
        ],
        correctAnswers: ["Не менее трех человек"],
      },
      {
        code: "67957",
        text: "Каким составом бригады разрешается проводить наполнение резервуаров СУГ/СПГ из автоцистерн при эксплуатации АГЗС?",
        answers: [
          "Бригадой из двух работников, при наличии быстросъемных соединений - одним работником",
          "Бригадой не менее чем из трех работников",
          "Бригадой не менее чем из четырех работников",
          "Бригадой не менее чем из пяти работников",
        ],
        correctAnswers: [
          "Бригадой из двух работников, при наличии быстросъемных соединений - одним работником",
        ],
      },
      {
        code: "67958",
        text: "Допускается ли во время грозы или проведения огневых работ на АГЗС проводить сливо-наливные операции?",
        answers: [
          "Допускается в исключительных случаях для завершения слива СУГ в резервуары",
          "Допускается по письменному разрешению технического руководителя АГЗС",
          "Допускается при условии соблюдения дополнительных мер безопасности",
          "Не допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "67959",
        text: "При наличии каких дефектов рукава, применяемые при сливоналивных операциях на АГЗС, подлежат замене?",
        answers: [
          "При наличии трещин, надрезов, вздутий и потертостей",
          "Только при наличии потертостей и вздутий",
          "Только при наличии трещин и надрезов",
          "Только при наличии надрезов",
        ],
        correctAnswers: ["При наличии трещин, надрезов, вздутий и потертостей"],
      },
      {
        code: "67960",
        text: "Какому испытанию должны подвергаться рукава, применяемые при сливо-наливных операциях, и с какой периодичностью?",
        answers: [
          "Гидравлическому испытанию на прочность давлением, равным 2 рабочим давлениям, не реже одного раза в 6 месяцев",
          "Пневматическому испытанию на герметичность давлением, равным рабочему давлению, не реже одного раза в 2 месяца",
          "Гидравлическому испытанию на прочность давлением, равным 1,25 рабочего давления, не реже одного раза в 3 месяца",
          "Гидравлическому испытанию на герметичность давлением, равным 1,5 рабочего давления, не реже одного раза в месяц",
          "Пневматическому испытанию на прочность давлением, равным 1,25 рабочего давления, не реже одного раза в год",
        ],
        correctAnswers: [
          "Гидравлическому испытанию на прочность давлением, равным 1,25 рабочего давления, не реже одного раза в 3 месяца",
        ],
      },
      {
        code: "67961",
        text: "Какие данные должны быть на рукавах, применяемых для проведения сливо-наливных операций?",
        answers: [
          "Обозначение с порядковым номером, датой проведения (месяц, год) испытания и последующего испытания (месяц, год)",
          "Обозначение с заводским номером, датой проведения (год) испытания и последующего испытания (месяц, год)",
          "Обозначение с заводским и порядковым номерами, датой проведения (год) испытания",
          "Обозначение с порядковым номером и данными об организации-владельце",
        ],
        correctAnswers: [
          "Обозначение с порядковым номером, датой проведения (месяц, год) испытания и последующего испытания (месяц, год)",
        ],
      },
      {
        code: "67962",
        text: "Каким образом металлокордовые и резинотканевые рукава, применяемые для сливо-наливных операций на АГЗС, должны быть защищены от статического электричества?",
        answers: [
          "Они должны быть обвиты (или пропущены внутри) медной проволокой диаметром не менее 2 мм или медным тросиком площадью сечения не менее 4 мм² с шагом витка не более 100 мм",
          "Они должны быть обвиты (или пропущены внутри) медной проволокой диаметром не менее 1 мм или медным тросиком площадью сечения не менее 4 мм² с шагом витка не более 200 мм",
          "Они должны быть обвиты (или пропущены внутри) медной проволокой диаметром не менее 1,5 мм или медным тросиком площадью сечения не менее 3 мм² с шагом витка не более 100 мм",
        ],
        correctAnswers: [
          "Они должны быть обвиты (или пропущены внутри) медной проволокой диаметром не менее 2 мм или медным тросиком площадью сечения не менее 4 мм² с шагом витка не более 100 мм",
        ],
      },
      {
        code: "67963",
        text: "Каким образом должен осуществляться слив СУГ/СПГ из автоцистерн на АГЗС?",
        answers: [
          "Только путем передавливания среды из сосуда автоцистерны в резервуар автозаправочной станции",
          "Только самотеком при расположении резервуаров ниже цистерны",
          "Только перекачиванием СУГ/СПГ насосами",
          "Всеми перечисленными способами",
        ],
        correctAnswers: ["Всеми перечисленными способами"],
      },
      {
        code: "67964",
        text: "Каким должно быть давление паров СУГ в цистерне после слива СУГ из нее?",
        answers: [
          "Не ниже 0,005 МПа",
          "Не ниже 0,05 МПа",
          "Не ниже 0,5 МПа",
          "Не ниже 1 МПа",
        ],
        correctAnswers: ["Не ниже 0,05 МПа"],
      },
      {
        code: "67965",
        text: "Какое утверждение противоречит требованиям промышленной безопасности при проведении сливо-наливных операций на АГЗС?",
        answers: [
          "Запрещается оставлять автоцистерны присоединенными к газопроводам технологической системы АГЗС в период, когда слив СУГ не производится",
          "По окончании слива СУГ запорная арматура на автоцистерне должна быть закрыта, а сливные штуцеры заглушены",
          "Во время слива СУГ допускается производить работы по уплотнению соединений, находящихся под давлением",
          "В случае поступления автоцистерны с СУГ, имеющей течь, она должна быть немедленно освобождена от СУГ",
        ],
        correctAnswers: [
          "Во время слива СУГ допускается производить работы по уплотнению соединений, находящихся под давлением",
        ],
      },
      {
        code: "67966",
        text: "Что следует применять при сливо-наливных операциях на АГЗС для оттаивания арматуры и газопроводов при образовании гидратных пробок?",
        answers: [
          "Нагретый песок, горячую воду или водяной пар",
          "Газовую горелку",
          "Паяльную лампу",
          "Факел",
        ],
        correctAnswers: ["Нагретый песок, горячую воду или водяной пар"],
      },
      {
        code: "67967",
        text: "Что должно размещаться в помещении операторной?",
        answers: [
          "Только производственная инструкция, содержащая порядок проведения и меры безопасности при сливе СУГ",
          "Только схема обвязки насосов с раздаточными колонками и резервуарами",
          "Только схема слива СУГ из автомобильных цистерн",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "67968",
        text: "Требования каких документов необходимо соблюдать при эксплуатации насосов на АГЗС?",
        answers: [
          "Эксплуатационной документации организаций-изготовителей, Правил безопасности автогазозаправочных станций газомоторного топлива и производственных инструкций",
          "Правил безопасности автогазозаправочных станций газомоторного топлива, Правил безопасности для объектов, использующих СУГ, и производственных инструкций",
          "Эксплуатационной документации организаций-изготовителей и Правил безопасности для объектов, использующих СУГ",
        ],
        correctAnswers: [
          "Эксплуатационной документации организаций-изготовителей, Правил безопасности автогазозаправочных станций газомоторного топлива и производственных инструкций",
        ],
      },
      {
        code: "67969",
        text: "Каким должно быть давление СУГ на всасывающей линии насоса?",
        answers: [
          "На 0,01 - 0,02 МПа выше упругости насыщенных паров жидкой фазы при данной температуре",
          "На 0,1 - 0,2 МПа выше упругости насыщенных паров жидкой фазы при данной температуре",
          "На 0,2 - 0,5 МПа ниже упругости насыщенных паров жидкой фазы при данной температуре",
          "На 0,5 - 1 МПа выше упругости насыщенных паров жидкой фазы при данной температуре",
        ],
        correctAnswers: [
          "На 0,1 - 0,2 МПа выше упругости насыщенных паров жидкой фазы при данной температуре",
        ],
      },
      {
        code: "67970",
        text: "Какое утверждение противоречит требованиям промышленной безопасности к эксплуатации насосов на АГЗС?",
        answers: [
          "Не допускается работа насосов (при установке их в помещении) при отключенной вентиляции, КИП или их отсутствии, при наличии в помещении концентрации СУГ, превышающей 5% НКПР",
          "В случае превышения предусмотренного проектной документацией давления на нагнетательных линиях насосов электродвигатели должны автоматически отключаться",
          "Сведения о режиме работы, количестве отработанного времени насосов, а также неполадках в работе должны фиксироваться в эксплуатационном журнале",
        ],
        correctAnswers: [
          "Не допускается работа насосов (при установке их в помещении) при отключенной вентиляции, КИП или их отсутствии, при наличии в помещении концентрации СУГ, превышающей 5% НКПР",
        ],
      },
      {
        code: "67971",
        text: "Какие смазочные масла должны использоваться для насосов?",
        answers: [
          "Только масла, предусмотренные инструкциями организаций-изготовителей",
          "Только полусинтетические масла",
          "Только индустриальные масла",
          "Только моторные масла",
        ],
        correctAnswers: [
          "Только масла, предусмотренные инструкциями организаций-изготовителей",
        ],
      },
      {
        code: "67972",
        text: "Кем осуществляется техническое обслуживание насосов на АГЗС? Выберите 2 варианта ответа.",
        answers: [
          "Ремонтным персоналом под руководством лица, ответственного за проведение этих работ",
          "Ремонтным персоналом под руководством наиболее квалифицированного рабочего",
          "Персоналом специализированной организации",
          "Работниками (эксплуатационным персоналом)",
          "Работниками подрядной организации",
        ],
        correctAnswers: [
          "Работниками (эксплуатационным персоналом)",
          "Работниками подрядной организации",
        ],
      },
      {
        code: "67973",
        text: "Как на АГЗС устанавливаются сроки технического обслуживания, текущего и капитального ремонтов насосов?",
        answers: [
          "Графиками, утвержденными техническим руководителем АГЗС (руководителем АГЗС), но не реже указанных в эксплуатационной документации на насосы",
          "Правилами безопасности автогазозаправочных станций газомоторного топлива",
          "Эксплуатационной документацией на насосы и другое оборудование",
          "Графиками, утвержденными руководителем ремонтного персонала",
          "В соответствии с проектной документацией",
        ],
        correctAnswers: [
          "Графиками, утвержденными техническим руководителем АГЗС (руководителем АГЗС), но не реже указанных в эксплуатационной документации на насосы",
        ],
      },
      {
        code: "67974",
        text: "Какое утверждение противоречит требованиям промышленной безопасности при эксплуатации насосов на АГЗС?",
        answers: [
          "Перед демонтажем насоса, работающего на СПГ, должен быть произведен сброс среды в систему газосброса, произведена смена рабочей среды на инертный газ, и насос должен быть отключен от обвязывающих газопроводов заглушками",
          "Аварийная остановка насосов должна быть осуществлена немедленно при внезапном падении напора на нагнетании более чем на 5%",
          "При установке заглушек на газопроводах обвязки насоса, подлежащего ремонту, работа остальных насосов должна быть прекращена",
        ],
        correctAnswers: [
          "Аварийная остановка насосов должна быть осуществлена немедленно при внезапном падении напора на нагнетании более чем на 5%",
        ],
      },
      {
        code: "67975",
        text: "Какие требования необходимо соблюдать перед демонтажем насоса, работающего на СПГ, для ремонта?",
        answers: [
          "Должен быть произведен сброс среды в систему газосброса, произведена смена рабочей среды на инертный газ, и насос должен быть отключен от обвязывающих газопроводов заглушками",
          "Должен быть произведен сброс среды в систему газосброса, затем насос должен быть продут инертным газом и отключен от обвязывающих газопроводов запорной арматурой",
          "Должен быть произведен сброс среды в систему газосброса, затем насос должен быть отключен от обвязывающих газопроводов запорной арматурой и пропарен",
          "Насос должен быть освобожден от СУГ, отключен от обвязывающих газопроводов запорной арматурой и продут инертным газом",
        ],
        correctAnswers: [
          "Должен быть произведен сброс среды в систему газосброса, произведена смена рабочей среды на инертный газ, и насос должен быть отключен от обвязывающих газопроводов заглушками",
        ],
      },
      {
        code: "67976",
        text: "Допускается ли на АГЗС работа остальных насосов при установке заглушек на газопроводах обвязки насоса, подлежащего ремонту?",
        answers: [
          "Работа остальных насосов может быть продолжена при контроле загазованности переносным газоанализатором через каждые 10 минут",
          "Работа остальных насосов может быть продолжена при организации дополнительной вентиляции",
          "Работа остальных насосов должна быть прекращена",
        ],
        correctAnswers: ["Работа остальных насосов должна быть прекращена"],
      },
      {
        code: "67977",
        text: "Кто на АГЗС дает разрешение на пуск и остановку насосов при выводе в ремонт и из ремонта?",
        answers: [
          "Технический руководитель АГЗС (руководитель АГЗС)",
          "Инженерно-технический работник",
          "Руководитель ремонтной бригады",
          "Сменный мастер",
        ],
        correctAnswers: ["Технический руководитель АГЗС (руководитель АГЗС)"],
      },
      {
        code: "67978",
        text: "Каким образом осуществляется включение насосов АГЗС после перерыва в работе более одной смены?",
        answers: [
          "С разрешения технического руководителя АГЗС (руководителя АГЗС) после осмотра резервуаров и газопроводов",
          "С разрешения технического руководителя АГЗС, согласованного с инспектором Ростехнадзора",
          "С разрешения руководителя АГЗС, согласованного с инспектором Ростехнадзора",
          "В обычном порядке",
        ],
        correctAnswers: [
          "С разрешения технического руководителя АГЗС (руководителя АГЗС) после осмотра резервуаров и газопроводов",
        ],
      },
      {
        code: "67979",
        text: "Допускается ли эксплуатация насосов с отключенной автоматикой, аварийной сигнализацией?",
        answers: [
          "Допускается в течение не более 1 смены при соблюдении дополнительных мер безопасности",
          "Допускается по письменному разрешению технического руководителя АГЗС",
          "Допускается в случаях, указанных в производственной инструкции",
          "Не допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "67980",
        text: "Кто отвечает за эксплуатацию зданий и сооружений, соблюдение сроков и качества их ремонта, а также за исправное состояние ограждений территории дорог, тротуаров, отмосток зданий и благоустройство территории АГЗС?",
        answers: [
          "Лицо, ответственное за эксплуатацию зданий и сооружений, назначенное приказом руководителя эксплуатирующей организации",
          "Лицо, ответственное за осуществление производственного контроля за соблюдением требований промышленной безопасности",
          "Лицо, ответственное за безопасную эксплуатацию АГЗС",
          "Технический руководитель АГЗС",
        ],
        correctAnswers: [
          "Лицо, ответственное за эксплуатацию зданий и сооружений, назначенное приказом руководителя эксплуатирующей организации",
        ],
      },
      {
        code: "67981",
        text: "С какой периодичностью на АГЗС в первые два года эксплуатации следует наблюдать за осадкой фундаментов зданий, сооружений и оборудования?",
        answers: [
          "Еженедельно",
          "Ежемесячно",
          "Не реже одного раза в три месяца",
          "Не реже одного раза в полгода",
          "Ежегодно",
        ],
        correctAnswers: ["Не реже одного раза в три месяца"],
      },
      {
        code: "67982",
        text: "С какой периодичностью на АГЗС осматриваются металлические конструкции?",
        answers: [
          "Ежемесячно",
          "Ежеквартально",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в 12 месяцев",
          "Не реже 1 раза в 2 года",
        ],
        correctAnswers: ["Не реже 1 раза в 12 месяцев"],
      },
      {
        code: "67983",
        text: "С какой периодичностью на АГЗС осматриваются железобетонные конструкции?",
        answers: [
          "Ежеквартально",
          "Не реже 1 раза в 6 месяцев",
          "Ежегодно",
          "Не реже 1 раза в 2 года",
        ],
        correctAnswers: ["Не реже 1 раза в 6 месяцев"],
      },
      {
        code: "67984",
        text: "С какой периодичностью необходимо окрашивать наружные металлические конструкции зданий и сооружений с целью предохранения от коррозии?",
        answers: [
          "Ежеквартально",
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в 12 месяцев",
          "Не реже одного раза в 3 года",
          "Не реже одного раза в 5 лет",
        ],
        correctAnswers: ["Не реже одного раза в 12 месяцев"],
      },
      {
        code: "67985",
        text: "С какой периодичностью при продолжении работ в колодце на территории АГЗС более 1 часа необходимо проводить проверку загазованности и вентиляцию колодца?",
        answers: [
          "Каждые 5 минут",
          "Каждые 10 минут",
          "Каждые 15 минут",
          "Каждые 30 минут",
          "Каждый час",
        ],
        correctAnswers: ["Каждые 15 минут"],
      },
      {
        code: "67986",
        text: "Какое утверждение противоречит требованиям безопасности при работах в колодцах на территории АГЗС?",
        answers: [
          "С наветренной стороны колодца должны находиться 2 человека, которые обязаны держать концы веревок от спасательных поясов работников, находящихся внутри колодца, вести наблюдение за ними и не допускать к месту работ посторонних лиц",
          "При продолжении работ в колодцах более 1 часа необходимо периодически, каждые 30 минут, проводить проверку загазованности и вентиляцию колодца",
          "В колодцах разрешается нахождение не более 2 работников в спасательных поясах и шланговых противогазах. Применение открытого огня запрещается",
        ],
        correctAnswers: [
          "При продолжении работ в колодцах более 1 часа необходимо периодически, каждые 30 минут, проводить проверку загазованности и вентиляцию колодца",
        ],
      },
      {
        code: "67987",
        text: "По истечении какого срока эксплуатации здания и сооружения должны пройти обследование для установления возможности дальнейшей их эксплуатации, необходимости проведения реконструкции или прекращения эксплуатации?",
        answers: [
          "По истечении срока, предусмотренного в проектной документации",
          "По истечении 25 лет",
          "По истечении 30 лет",
          "По истечении 40 лет",
          "По истечении 50 лет",
        ],
        correctAnswers: [
          "По истечении срока, предусмотренного в проектной документации",
        ],
      },
      {
        code: "67988",
        text: "Какое утверждение противоречит требованиям безопасности при освидетельствовании резервуаров на АГЗС?",
        answers: [
          "Качество дегазации должно быть проверено анализом проб воздуха, отобранного из нижней части сосуда. Концентрация СУГ пробы после дегазации не должна превышать 20% НКПР",
          "Дегазация резервуаров должна быть проведена после отсоединения их от газопроводов паровой и жидкой фаз СУГ с помощью заглушек путем их пропаривания и продувки инертным газом или заполнения теплой водой",
          "Резервуары перед внутренним осмотром, гидравлическим испытанием, ремонтом или демонтажем должны быть освобождены от СУГ, неиспарившихся остатков и тщательно дегазированы",
          "Разгерметизация резервуаров без предварительного снижения в них давления до атмосферного, а также применение для дегазации воздуха не разрешаются",
        ],
        correctAnswers: [
          "Качество дегазации должно быть проверено анализом проб воздуха, отобранного из нижней части сосуда. Концентрация СУГ пробы после дегазации не должна превышать 20% НКПР",
        ],
      },
      {
        code: "67989",
        text: "Как недопустимо дегазировать резервуары СУГ?",
        answers: [
          "Пропариванием и продувкой инертным газом",
          "Заполнением теплой водой",
          "Продувкой воздухом",
        ],
        correctAnswers: ["Продувкой воздухом"],
      },
      {
        code: "67990",
        text: "Какой должна быть концентрация СУГ пробы после дегазации резервуара?",
        answers: [
          "Не должна превышать 5% НКПР",
          "Не должна превышать 7% НКПР",
          "Не должна превышать 10% НКПР",
          "Должна быть нулевой",
        ],
        correctAnswers: ["Не должна превышать 10% НКПР"],
      },
      {
        code: "67991",
        text: "Как должны проводиться работы внутри резервуара СУГ при его подготовке к освидетельствованию?",
        answers: [
          "Должны проводиться по наряду-допуску или без него бригадой в составе не менее четырех человек под руководством инженерно-технического работника",
          "Должны проводиться по наряду-допуску бригадой в составе не менее трех человек под руководством наиболее квалифицированного работника",
          "Должны проводиться по наряду-допуску бригадой в составе не менее трех человек под руководством инженерно-технического работника",
          "Должны проводиться по наряду-допуску бригадой в составе не менее двух человек под руководством инженерно-технического работника",
        ],
        correctAnswers: [
          "Должны проводиться по наряду-допуску бригадой в составе не менее трех человек под руководством инженерно-технического работника",
        ],
      },
      {
        code: "67992",
        text: "Какое утверждение противоречит требованиям безопасности при освидетельствовании резервуаров СУГ на АГЗС?",
        answers: [
          "Пирофорные отложения, извлеченные из резервуаров, должны поддерживаться во влажном состоянии и вывозиться с территории АГЗС для захоронения в специально отведенном месте",
          "Во время работ в резервуаре допускается нахождение вблизи резервуара, кроме лиц, участвующих в работе, дежурной смены АГЗС",
          "Участки газопроводов с пирофорными отложениями в день их вскрытия должны быть демонтированы и сложены в безопасной зоне",
        ],
        correctAnswers: [
          "Во время работ в резервуаре допускается нахождение вблизи резервуара, кроме лиц, участвующих в работе, дежурной смены АГЗС",
        ],
      },
      {
        code: "67993",
        text: "Какие из перечисленных мероприятий необходимо произвести перед началом приемочных испытаний эксплуатационной организации, участвующей в них совместно с пусконаладочной организацией? Выберите 2 варианта ответа.",
        answers: [
          "Утвердить должностные и производственные инструкции, графики технического обслуживания и ремонта",
          "Вывесить на рабочих местах технологические схемы газопроводов и технических устройств",
          "Отработать технологические режимы по заправке автомобилей",
          "Слить газ в резервуары",
        ],
        correctAnswers: [
          "Утвердить должностные и производственные инструкции, графики технического обслуживания и ремонта",
          "Вывесить на рабочих местах технологические схемы газопроводов и технических устройств",
        ],
      },
      {
        code: "67994",
        text: "Какое максимальное содержание кислорода допускается после продувки технических устройств инертным газом при пусконаладочных работах по вводу в эксплуатацию автогазозаправочных станций?",
        answers: ["0,01", "0,02", "0,05", "0,03"],
        correctAnswers: ["0,01"],
      },
      {
        code: "67995",
        text: "Что из перечисленного не указывается в технологической схеме на автогазозаправочной станции?",
        answers: [
          "Фланцевые и резьбовые соединения",
          "Запорная арматура",
          "Предохранительная арматура",
          "Номера резервуаров",
        ],
        correctAnswers: ["Фланцевые и резьбовые соединения"],
      },
      {
        code: "67996",
        text: "Кому сообщается о выявленных неисправностях в работе средств измерений и автоматики безопасности АГЗС?",
        answers: [
          "Работнику, ответственному за осуществление производственного контроля за соблюдением требований промышленной безопасности",
          "Техническому руководителю АГЗС (руководителю АГЗС)",
          "Должностному лицу Ростехнадзора",
          "Начальнику ремонтного участка",
        ],
        correctAnswers: ["Техническому руководителю АГЗС (руководителю АГЗС)"],
      },
      {
        code: "67997",
        text: "С какой периодичностью проводится регистрация измерений показывающих приборов АГЗС?",
        answers: [
          "Не менее 3 раз в смену",
          "Каждую смену",
          "Не менее 2 раз в неделю",
          "Каждую неделю",
        ],
        correctAnswers: ["Каждую смену"],
      },
      {
        code: "67998",
        text: "В соответствии с требованиями каких документов должен проводиться пуск в эксплуатацию оборудования автогазозаправочной станции (компрессоры, насосы, испарители) после технического обслуживания и ремонта?",
        answers: [
          "Правил безопасности автогазозаправочных станций газомоторного топлива",
          "Инструкций по эксплуатации оборудования организаций-изготовителей",
          "Производственных инструкций",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "67999",
        text: "Кто должен контролировать надлежащее состояние систем наблюдения, оповещения и связи при организации ремонтных работ на АГЗС?",
        answers: [
          "Специальная комиссия, утверждаемая техническим руководителем организации",
          "Технический руководитель АГЗС (руководитель АГЗС)",
          "Руководитель ремонтной бригады",
          "Начальник ремонтного участка",
        ],
        correctAnswers: ["Технический руководитель АГЗС (руководитель АГЗС)"],
      },
      {
        code: "68000",
        text: "Какие действия из перечисленных допускаются при проведении сливо-наливных операций?",
        answers: [
          "Отсоединять заземляющие устройства после окончания сливо-наливных операций и установки заглушек на штуцеры вентилей автоцистерн",
          "Подтягивать накидные гайки рукавов",
          "Отсоединять рукава, находящиеся под давлением",
          "Применять ударный инструмент при навинчивании и отвинчивании гаек",
        ],
        correctAnswers: [
          "Отсоединять заземляющие устройства после окончания сливо-наливных операций и установки заглушек на штуцеры вентилей автоцистерн",
        ],
      },
      {
        code: "68001",
        text: "Что из перечисленного допускается при проведении сливо-наливных операций?",
        answers: [
          "Слив СУГ из автоцистерны в резервуары АГЗС после проверки правильности открытия и закрытия запорной арматуры, связанной с технологической операцией слива СУГ",
          "Создание перепада давления между автоцистерной и резервуаром сбросом в атмосферу паровой фазы СУГ из наполняемого резервуара",
          "Нахождение водителя во время слива СУГ",
          "Сброс СУГ в атмосферу",
        ],
        correctAnswers: [
          "Слив СУГ из автоцистерны в резервуары АГЗС после проверки правильности открытия и закрытия запорной арматуры, связанной с технологической операцией слива СУГ",
        ],
      },
      {
        code: "68002",
        text: "Каким количеством огнетушителей должна быть оснащена автоцистерна с СУГ?",
        answers: [
          "Одним огнетушителем",
          "Двумя огнетушителями",
          "Тремя огнетушителями",
          "Не регламентируется",
        ],
        correctAnswers: ["Двумя огнетушителями"],
      },
      {
        code: "68003",
        text: "Какие действия выполняются ежесменно при техническом обслуживании насосов?",
        answers: [
          "Только осмотр насосов, запорной и предохранительной арматуры, средств измерений, автоматики и блокировок",
          "Только очистка насосов и КИП от пыли и загрязнений, проверка наличия и исправности заземления и креплений",
          "Только контроль за отсутствием посторонних шумов, характерных вибраций, температурой подшипников",
          "Только проверка исправности доступных для осмотра движущихся частей",
          "Только отключение неисправных насосов",
          "Выполняются все перечисленные действия",
        ],
        correctAnswers: ["Выполняются все перечисленные действия"],
      },
      {
        code: "68004",
        text: "Какое максимальное избыточное давление установлено Правилами безопасности автогазозаправочных станций газомоторного топлива для компримированного природного газа, используемого в качестве автомобильного топлива на АГНКС?",
        answers: ["16 МПа", "25 МПа", "120 МПа", "250 МПа"],
        correctAnswers: ["25 МПа"],
      },
      {
        code: "68005",
        text: "Какой документацией, в соответствии с требованиями Правил безопасности автогазозаправочных станций газомоторного топлива, должен быть обеспечен оператор АГНКС?",
        answers: [
          "Письменными инструкциями и руководствами по обеспечению безопасности",
          "Письменными инструкциями и инструкциями изготовителей оборудования",
          "Планом мероприятий по локализации и ликвидации последствий аварий",
        ],
        correctAnswers: [
          "Письменными инструкциями и руководствами по обеспечению безопасности",
        ],
      },
      {
        code: "68006",
        text: "Для чего все трубопроводы АГНКС должны быть испытаны после сборки?",
        answers: [
          "Чтобы установить отсутствие протечек при давлении, равном максимальному рабочему давлению в соответствующей части системы",
          "Чтобы установить отсутствие протечек при максимальном давлении компримированного природного газа на АГНКС, равном 25 МПа",
          "Чтобы проверить прочность трубопроводов в условиях эксплуатации",
        ],
        correctAnswers: [
          "Чтобы установить отсутствие протечек при давлении, равном максимальному рабочему давлению в соответствующей части системы",
        ],
      },
      {
        code: "68007",
        text: "С какой периодичностью дежурный персонал АГНКС должен осматривать технологические газопроводы и арматуру в целях выявления утечек газа?",
        answers: [
          "2 раза в сутки",
          "Ежесменно",
          "Не реже 1 раза в неделю",
          "Еженедельно",
        ],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "68008",
        text: "С какой периодичностью на АГНКС должна проводиться проверка состояния опор трубопроводов, колодцев?",
        answers: [
          "Ежемесячно",
          "Ежеквартально",
          "Не реже 1 раза в полгода",
          "Не реже 1 раза в год",
        ],
        correctAnswers: ["Ежемесячно"],
      },
      {
        code: "68009",
        text: "С какой периодичностью на АГНКС должен проводиться наружный осмотр газопроводов для выявления неплотностей в сварных стыках, фланцевых и резьбовых соединениях, сальниковых уплотнениях и определения состояния теплоизоляции и окраски?",
        answers: [
          "Ежемесячно",
          "Не реже 1 раза в квартал",
          "Не реже 1 раза в полгода",
          "Не реже 1 раза в год",
        ],
        correctAnswers: ["Ежемесячно"],
      },
      {
        code: "68010",
        text: "С кем согласовывается план производства работ при проведении капитального ремонта АГНКС?",
        answers: [
          "С техническим руководителем АГНКС (руководителем АГНКС)",
          "С территориальным органом Ростехнадзора",
          "С проектной организацией",
          "План производства работ не согласовывается",
        ],
        correctAnswers: [
          "С техническим руководителем АГНКС (руководителем АГНКС)",
        ],
      },
      {
        code: "68011",
        text: "Что должны включать в себя производственные инструкции по эксплуатации системы вентиляции АГНКС (при установке оборудования в помещении)?",
        answers: [
          "Требования инструкций заводов - изготовителей вентиляционного оборудования, объем и сроки технического обслуживания, текущего и капитального ремонтов",
          "Требования инструкций заводов - изготовителей вентиляционного оборудования, противопожарные мероприятия",
          "Графики, объемы и сроки технического обслуживания, текущего и капитального ремонтов",
          "Графики текущего и капитального ремонтов, противоаварийные мероприятия",
        ],
        correctAnswers: [
          "Требования инструкций заводов - изготовителей вентиляционного оборудования, объем и сроки технического обслуживания, текущего и капитального ремонтов",
        ],
      },
      {
        code: "68012",
        text: "Допускается ли изменять конструкцию вентиляционных систем на АГНКС?",
        answers: [
          "Допускается при согласовании с территориальным органом Ростехнадзора",
          "Допускается при согласовании с проектной организацией",
          "Не допускается",
          "Допускается",
        ],
        correctAnswers: [
          "Допускается при согласовании с проектной организацией",
        ],
      },
      {
        code: "68013",
        text: "Допускается ли проведение работ на АГНКС при неисправности вентиляционных систем и недостаточном воздухообмене в производственных помещениях?",
        answers: [
          "Допускается в течение времени, необходимого для завершения технологических операций",
          "Допускается по письменному разрешению технического руководителя АГНКС",
          "Допускается в течение не более 1 часа после возникновения неисправности",
          "Не допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "68014",
        text: "Какое утверждение противоречит требованиям Правил безопасности автогазозаправочных станций газомоторного топлива к эксплуатации вентиляционных систем на АГНКС?",
        answers: [
          "При выявлении в помещениях АГНКС опасной концентрации газа необходимо обеспечить дополнительную вентиляцию для обеспечения возможности продолжения работ и устранить причины загазованности",
          "Отсосы воздуха вытяжных вентиляционных систем помещений должны быть закрыты сеткой, предотвращающей попадание в систему посторонних предметов",
          "Мягкие вставки вентиляционных систем должны быть герметичны",
          "Воздуховоды должны быть заземлены",
        ],
        correctAnswers: [
          "При выявлении в помещениях АГНКС опасной концентрации газа необходимо обеспечить дополнительную вентиляцию для обеспечения возможности продолжения работ и устранить причины загазованности",
        ],
      },
      {
        code: "68015",
        text: "Когда на АГНКС должен быть произведен пуск вытяжных вентиляционных систем?",
        answers: [
          "За 15 минут до начала работы технологического оборудования",
          "Одновременно с началом работы технологического оборудования",
          "Через 15 минут после начала работы технологического оборудования",
          "Одновременно с включением приточных систем вентиляции",
          "Через 15 минут после включения приточных систем вентиляции",
        ],
        correctAnswers: [
          "За 15 минут до начала работы технологического оборудования",
        ],
      },
      {
        code: "68016",
        text: "Когда на АГНКС должно производиться включение приточных систем вентиляции?",
        answers: [
          "За 30 минут до включения вытяжных вентиляционных систем",
          "За 15 минут до включения вытяжных вентиляционных систем",
          "Одновременно с включением вытяжных вентиляционных систем",
          "Через 15 минут после включения вытяжных вентиляционных систем",
          "Через 30 минут после включения вытяжных вентиляционных систем",
        ],
        correctAnswers: [
          "Через 15 минут после включения вытяжных вентиляционных систем",
        ],
      },
      {
        code: "68017",
        text: "С какой периодичностью на АГНКС проводится техническое обслуживание вентиляционных камер и вентиляционных систем помещений?",
        answers: ["Ежесменно", "Ежесуточно", "Еженедельно", "Ежемесячно"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "68018",
        text: "Когда на АГНКС должны проводиться испытания вентиляционных систем с определением их эксплуатационных технических характеристик?",
        answers: [
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в год, а также после ремонта, реконструкции",
          "Не реже одного раза в 2 года",
          "Только после ремонта или реконструкции",
        ],
        correctAnswers: [
          "Не реже одного раза в год, а также после ремонта, реконструкции",
        ],
      },
      {
        code: "68019",
        text: "Кем проводятся испытания вентиляционных систем на АГНКС?",
        answers: [
          "Специальной комиссией, назначенной приказом руководителя АГНКС",
          "Эксплуатационным персоналом АГНКС",
          "Специализированной организацией",
        ],
        correctAnswers: ["Специализированной организацией"],
      },
      {
        code: "68020",
        text: "Какой документ составляется по результатам испытаний вентиляционных систем АГНКС?",
        answers: [
          "Технический отчет, содержащий оценку эффективности работы вентиляционных систем с указанием режима их эксплуатации",
          "Производственная инструкция по эксплуатации вентиляционных систем",
          "Акт проверки эффективности работы вентиляционных систем",
        ],
        correctAnswers: [
          "Технический отчет, содержащий оценку эффективности работы вентиляционных систем с указанием режима их эксплуатации",
        ],
      },
      {
        code: "68021",
        text: "Где должны быть отражены сведения о ремонте и наладке вентиляционных систем на АГНКС?",
        answers: [
          "В производственных инструкциях по эксплуатации вентиляционных систем",
          "В отчетах о ремонте и наладке вентиляционных систем",
          "В эксплуатационных журналах вентиляционных систем",
          "В паспорте вентиляционных систем",
        ],
        correctAnswers: ["В паспорте вентиляционных систем"],
      },
      {
        code: "68022",
        text: "Какие обозначения наносятся на входе в каждое производственное помещение на АГНКС?",
        answers: [
          "Обозначения категории помещения по пожарной и взрывопожарной опасности и уровень взрывозащиты оборудования, установленного в этом помещении",
          "Обозначения категории помещения по пожарной и взрывопожарной опасности, а также класса конструктивной пожарной опасности",
          "Обозначения класса взрывоопасных зон",
        ],
        correctAnswers: ["Обозначения класса взрывоопасных зон"],
      },
      {
        code: "68023",
        text: 'С какой периодичностью на АГНКС в помещениях, относящихся к категории "А" по пожарной и взрывопожарной опасности зданий, следует контролировать техническое состояние газонепроницаемых стен, отделяющих помещения категории "А" от помещений с иными категориями, и состояние покрытия пола?',
        answers: [
          "2 раза в смену",
          "Ежесменно",
          "Не реже 1 раза в неделю",
          "Еженедельно",
        ],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "68024",
        text: "Гидравлическим испытаниям каким давлением должны подвергаться газозаправочные рукава (РВД) АГНКС?",
        answers: [
          "Рабочим давлением",
          "Равным 1,25 рабочего давления",
          "Равным 1,5 рабочего давления",
          "Равным 2 рабочим давлениям",
        ],
        correctAnswers: ["Равным 1,25 рабочего давления"],
      },
      {
        code: "68025",
        text: "С какой периодичностью газозаправочные рукава (РВД) АГНКС должны подвергаться гидравлическим испытаниям?",
        answers: [
          "Один раз в 3 месяца",
          "Один раз в 6 месяцев",
          "Один раз в год",
          "Один раз в 2 года",
        ],
        correctAnswers: ["Один раз в 6 месяцев"],
      },
      {
        code: "68026",
        text: "Кто осуществляет контроль за выполнением работ по капитальному ремонту в соответствии с утвержденной технической документацией и требованиями безопасности с последующей приемкой работ?",
        answers: [
          "Аттестационная комиссия Ростехнадзора",
          "Организация, осуществляющая ремонт",
          "Организация, эксплуатирующая АГНКС",
          "Независимая экспертная организация",
        ],
        correctAnswers: ["Организация, эксплуатирующая АГНКС"],
      },
      {
        code: "68027",
        text: "Какое утверждение соответствует требованиям Правил безопасности автогазозаправочных станций газомоторного топлива к хранению СПГ на КриоАЗС?",
        answers: [
          "Хранение СПГ в резервуарах разрешается как с периодическим, так и с непрерывным газосбросом. В обоих случаях избыточное давление не должно быть ниже минимального давления, установленного руководством по эксплуатации резервуара (не менее 0,05 МПа)",
          "Хранение СПГ в резервуарах разрешается как с периодическим, так и с непрерывным газосбросом. В обоих случаях избыточное давление должно быть равным минимальному давлению, установленному руководством по эксплуатации резервуара",
          "Хранение СПГ в резервуарах разрешается только с непрерывным газосбросом. Избыточное давление не должно быть ниже минимального давления, установленного руководством по эксплуатации резервуара (не менее 0,5 МПа)",
          "Хранение СПГ в резервуарах разрешается только с периодическим газосбросом. Избыточное давление не должно быть ниже минимального давления, установленного руководством по эксплуатации резервуара (не менее 1 МПа)",
        ],
        correctAnswers: [
          "Хранение СПГ в резервуарах разрешается как с периодическим, так и с непрерывным газосбросом. В обоих случаях избыточное давление не должно быть ниже минимального давления, установленного руководством по эксплуатации резервуара (не менее 0,05 МПа)",
        ],
      },
      {
        code: "68028",
        text: "Каких пределов не должна превышать степень заполнения резервуаров СПГ на КриоАЗС?",
        answers: [
          "50% геометрического объема резервуара",
          "85% геометрического объема резервуара",
          "95% геометрического объема резервуара",
          "Пределов, указанных в паспортах на оборудование систем хранения, выдачи и регазификации СПГ",
        ],
        correctAnswers: [
          "Пределов, указанных в паспортах на оборудование систем хранения, выдачи и регазификации СПГ",
        ],
      },
      {
        code: "68029",
        text: "Какая максимальная объемная доля кислорода в продувочных газах для резервуаров хранения СПГ установлена Правилами безопасности автогазозаправочных станций газомоторного топлива?",
        answers: [
          "10% минимального взрывоопасного содержания кислорода",
          "20% минимального взрывоопасного содержания кислорода",
          "30% минимального взрывоопасного содержания кислорода",
          "50% минимального взрывоопасного содержания кислорода",
        ],
        correctAnswers: [
          "50% минимального взрывоопасного содержания кислорода",
        ],
      },
      {
        code: "68030",
        text: "Куда на КриоАЗС должны быть направлены сбросы через предохранительные клапаны и другие аварийные устройства технологических блоков?",
        answers: [
          "В систему сброса от газосбросных клапанов резервуаров или в самостоятельную систему, не связанную с системой сброса от газосбросных клапанов резервуаров",
          "В самостоятельную систему, не связанную с системой сброса от газосбросных клапанов резервуаров",
          "В систему сброса от газосбросных клапанов резервуаров",
        ],
        correctAnswers: [
          "В самостоятельную систему, не связанную с системой сброса от газосбросных клапанов резервуаров",
        ],
      },
      {
        code: "68031",
        text: "С какой периодичностью работники автогазозаправочных станций должен участвовать в учебно-тренировочных занятиях по ликвидации аварийных ситуаций, аварий и пожаров, предусмотренных планом мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах I, II и III классов опасности?",
        answers: [
          "Не реже 1 раза в квартал",
          "Не реже 1 раза в полугодие",
          "Не реже 1 раза в год",
        ],
        correctAnswers: ["Не реже 1 раза в квартал"],
      },
      {
        code: "68032",
        text: "На каких автогазозаправочных станциях должны быть разработаны планы мероприятий по локализации и ликвидации последствий аварий?",
        answers: [
          "На всех автогазозаправочных станциях, на которые распространяются требования Правил безопасности автогазозаправочных станций газомоторного топлива, являющихся опасными производственными объектами I, II и III классов опасности, за исключением КриоАЗС",
          "На всех автогазозаправочных станциях, на которые распространяются требования Правил безопасности автогазозаправочных станций газомоторного топлива, являющихся опасными производственными объектами I, II и III классов опасности",
          "Только на автогазозаправочных станциях СУГ, являющихся опасными производственными объектами I, II и III классов опасности",
        ],
        correctAnswers: [
          "На всех автогазозаправочных станциях, на которые распространяются требования Правил безопасности автогазозаправочных станций газомоторного топлива, являющихся опасными производственными объектами I, II и III классов опасности",
        ],
      },
      {
        code: "68033",
        text: "Какое утверждение противоречит требованиям Правил безопасности автогазозаправочных станций газомоторного топлива к выполнению аварийных работ на АГЗС?",
        answers: [
          "Аварийные работы на АГЗС выполняются аварийными службами газораспределительных организаций. Участие персонала АГЗС в аварийных работах на АГЗС устанавливается планом мероприятий газораспределительных организаций по локализации и ликвидации последствий аварий",
          "Действия персонала АГЗС по локализации и ликвидации аварий определяются планом мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах I, II и III классов опасности",
          "Действия персонала АГЗС по локализации и ликвидации аварий определяются инструкцией по действиям работников в аварийных ситуациях для опасных производственных объектов IV класса опасности",
        ],
        correctAnswers: [
          "Аварийные работы на АГЗС выполняются аварийными службами газораспределительных организаций. Участие персонала АГЗС в аварийных работах на АГЗС устанавливается планом мероприятий газораспределительных организаций по локализации и ликвидации последствий аварий",
        ],
      },
      {
        code: "68034",
        text: "Какое утверждение противоречит требованиям Правил безопасности автогазозаправочных станций газомоторного топлива к выполнению аварийных работ на АГНКС?",
        answers: [
          "Аварийные работы на АГНКС выполняются аварийными службами газораспределительных организаций. Участие персонала АГНКС в аварийных работах на АГНКС устанавливается планом мероприятий газораспределительных организаций по локализации и ликвидации последствий аварий",
          "Технический руководитель АГНКС (руководитель АГНКС), относящейся к II, III классу опасности, является лицом, ответственным за своевременный пересмотр плана мероприятий по локализации и ликвидации последствий аварий",
          "Действия по локализации и ликвидации аварий персонала АГНКС, относящихся ко II, III классу опасности, определяются планом мероприятий по локализации и ликвидации последствий аварий на АГНКС",
        ],
        correctAnswers: [
          "Аварийные работы на АГНКС выполняются аварийными службами газораспределительных организаций. Участие персонала АГНКС в аварийных работах на АГНКС устанавливается планом мероприятий газораспределительных организаций по локализации и ликвидации последствий аварий",
        ],
      },
      {
        code: "68035",
        text: "Кто на автогазозаправочной станции является ответственным за своевременный пересмотр плана мероприятий по локализации и ликвидации аварий?",
        answers: [
          "Работник, ответственный за осуществление производственного контроля за соблюдением требований промышленной безопасности",
          "Технический руководитель АГНКС (руководитель АГНКС), относящейся к II, III классу опасности",
          "Руководитель аварийно-спасательного формирования",
        ],
        correctAnswers: [
          "Технический руководитель АГНКС (руководитель АГНКС), относящейся к II, III классу опасности",
        ],
      },
      {
        code: "68036",
        text: "В течение какого срока передается оперативное сообщение об аварии, инциденте на опасном производственном объекте?",
        answers: [
          "В течение 1 часа с момента возникновения аварии, инцидента",
          "В течение 12 часов с момента возникновения аварии, инцидента",
          "В течение 24 часов с момента возникновения аварии, инцидента",
          "В течение 2 суток с момента возникновения аварии, инцидента",
        ],
        correctAnswers: [
          "В течение 24 часов с момента возникновения аварии, инцидента",
        ],
      },
      {
        code: "68037",
        text: "В течение какого времени после получения оперативного сообщения об аварии издается приказ органом, производящим расследование?",
        answers: [
          "Не позднее 6 часов",
          "Не позднее 12 часов",
          "Не позднее 24 часов",
          "Не позднее 2 суток",
          "Не позднее 3 суток",
        ],
        correctAnswers: ["Не позднее 24 часов"],
      },
      {
        code: "68038",
        text: "Кто возглавляет специальную комиссию по техническому расследованию причин аварии на опасном производственном объекте?",
        answers: [
          "Представитель органа исполнительной власти субъекта Российской Федерации и (или) органа местного самоуправления, на территории которых располагается опасный производственный объект",
          "Представитель Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности) или его территориального органа",
          "Представитель вышестоящего органа или организации, эксплуатирующей опасный производственный объект",
          "Представитель организации, эксплуатирующей опасный производственный объект",
        ],
        correctAnswers: [
          "Представитель Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности) или его территориального органа",
        ],
      },
      {
        code: "68039",
        text: "В течение какого срока должен быть составлен акт технического расследования причин аварии на опасных производственных объектах?",
        answers: [
          "Не позднее 10 рабочих дней",
          "Не позднее 14 календарных дней",
          "Не позднее 30 календарных дней",
          "Не позднее 60 календарных дней",
        ],
        correctAnswers: ["Не позднее 30 календарных дней"],
      },
      {
        code: "68040",
        text: "На сколько может быть увеличен срок технического расследования причин аварии на опасном производственном объекте?",
        answers: [
          "Не более чем на 7 календарных дней",
          "Не более чем на 10 рабочих дней",
          "Не более чем на 15 календарных дней",
          "Не более чем на 30 календарных дней",
        ],
        correctAnswers: ["Не более чем на 15 календарных дней"],
      },
      {
        code: "68041",
        text: "Кем осуществляется расчет вреда (экономического и экологического ущерба) от аварии?",
        answers: [
          "Организацией, на объекте которой произошла авария",
          "Комиссией по техническому расследованию аварии",
          "Специализированной сторонней организацией",
          "Территориальным органом Ростехнадзора",
        ],
        correctAnswers: ["Организацией, на объекте которой произошла авария"],
      },
      {
        code: "68042",
        text: "На кого возлагается финансирование расходов на техническое расследование причин аварий?",
        answers: [
          "На страховую компанию, с которой заключен договор обязательного страхования гражданской ответственности владельца опасного объекта за причинение вреда в результате аварии на опасном объекте",
          "На организацию, эксплуатирующую опасные производственные объекты, или страховую компанию, в которой застрахована гражданская ответственность этой организации",
          "На организацию, эксплуатирующую опасный производственный объект, на котором произошла авария",
          "На территориальный орган Ростехнадзора",
        ],
        correctAnswers: [
          "На организацию, эксплуатирующую опасный производственный объект, на котором произошла авария",
        ],
      },
      {
        code: "68043",
        text: "В течение какого срока по результатам технического расследования причин аварии руководителем организации издается приказ, определяющий меры по устранению причин и последствий аварии, по обеспечению безаварийной и стабильной работы опасного производственного объекта?",
        answers: [
          "В течение суток",
          "В течение 3 рабочих дней",
          "В течение 7 рабочих дней",
          "В течение 10 рабочих дней",
        ],
        correctAnswers: ["В течение 7 рабочих дней"],
      },
      {
        code: "68044",
        text: "Куда организация, на объекте которой произошла авария, после окончания расследования обязана направить экземпляры материалов технического расследования причин аварии?",
        answers: [
          "Только в Ростехнадзор (иной федеральный орган исполнительной власти в области промышленной безопасности) или его территориальный орган",
          "Только в соответствующие органы, представители которых принимали участие в работе комиссии по техническому расследованию",
          "Только в органы, определенные председателем комиссии",
          "Во все перечисленные органы",
        ],
        correctAnswers: ["Во все перечисленные органы"],
      },
      {
        code: "68045",
        text: "Чьим приказом создается комиссия для технического расследования причин инцидентов на опасном производственном объекте?",
        answers: [
          "Приказом территориального органа Ростехнадзора, в котором зарегистрирован опасный производственный объект",
          "Приказом вышестоящего органа или организации, эксплуатирующей опасный производственный объект",
          "Приказом руководителя организации, эксплуатирующей объект",
          "Приказом Ростехнадзора или его территориального органа",
        ],
        correctAnswers: [
          "Приказом руководителя организации, эксплуатирующей объект",
        ],
      },
      {
        code: "68046",
        text: "Какую информацию должен содержать акт по установлению причин инцидента на опасном производственном объекте?",
        answers: [
          "Только информацию о принятых мерах по ликвидации инцидента, а также информацию о материальном ущербе, в том числе вреде, нанесенном окружающей среде",
          "Только информацию о продолжительности простоя и мерах по устранению причин инцидента",
          "Только дату и место инцидента, его причины и обстоятельства",
          "Всю перечисленную информацию",
        ],
        correctAnswers: ["Всю перечисленную информацию"],
      },
      {
        code: "68047",
        text: "Какая организация обеспечивает анализ причин возникновения инцидентов на опасном производственном объекте и принятие мер по их профилактике и устранению?",
        answers: [
          "Организация, эксплуатирующая автозаправочную станцию",
          "Аттестационная комиссия Ростехнадзора",
          "Орган местного самоуправления",
          "Ремонтная организация",
        ],
        correctAnswers: [
          "Организация, эксплуатирующая автозаправочную станцию",
        ],
      },
      {
        code: "68048",
        text: "При каком условии представители организации, эксплуатирующей опасный производственный объект, принимают участие в техническом расследовании причин аварии?",
        answers: [
          "В качестве членов комиссии по расследованию, но их число не должно превышать 30 % от общего числа членов комиссии",
          "В качестве членов комиссии по расследованию, но их число не должно превышать 25 % от общего числа членов комиссии",
          "При условии, что их число составляет не более 2",
          "При условии, что их число составляет не более 3",
        ],
        correctAnswers: [
          "В качестве членов комиссии по расследованию, но их число не должно превышать 30 % от общего числа членов комиссии",
        ],
      },
      {
        code: "68049",
        text: "С какой периодичностью должна направляться информация о произошедших инцидентах в территориальный орган Ростехнадзора?",
        answers: [
          "Один раз в месяц",
          "Не реже одного раза в квартал",
          "Не реже одного раза в полгода",
          "Один раз в год",
        ],
        correctAnswers: ["Не реже одного раза в квартал"],
      },
      {
        code: "68050",
        text: "Чем регламентируется порядок проведения работ по установлению причин инцидентов на опасном производственном объекте?",
        answers: [
          "Документом, утвержденным органом исполнительной власти субъекта Российской Федерации, на территории которого находится опасный производственный объект",
          "Документом, утвержденным представительным органом работников организации, эксплуатирующей объект",
          "Документом, утвержденным организацией, эксплуатирующей опасный производственный объект",
        ],
        correctAnswers: [
          "Документом, утвержденным организацией, эксплуатирующей опасный производственный объект",
        ],
      },
      {
        code: "68051",
        text: "В какой срок материалы технического расследования аварии направляются территориальным органом Ростехнадзора в центральный аппарат Ростехнадзора?",
        answers: [
          "В двухнедельный срок",
          "В месячный срок",
          "В десятидневный срок",
          "В недельный срок",
        ],
        correctAnswers: ["В двухнедельный срок"],
      },
      {
        code: "68052",
        text: "Куда организация обязана направить результаты технического расследования причин аварии?",
        answers: [
          "Только в федеральный орган исполнительной власти в области промышленной безопасности",
          "В федеральный орган исполнительной власти в области промышленной безопасности, вышестоящий орган, орган местного самоуправления, государственную инспекцию труда субъекта Российской Федерации, территориальное объединение профсоюза, а также в территориальные органы МЧС России",
          "В центральный аппарат или территориальные органы Ростехнадзора, проводившие расследование, страховую организацию, территориальные органы МЧС России",
          "В уполномоченный орган или его территориальный орган, сформировавший комиссию по проведению технического расследования, в соответствующие органы, представители которых принимали участие в работе комиссии по техническому расследованию, и в другие органы, определенные председателем комиссии",
        ],
        correctAnswers: [
          "В уполномоченный орган или его территориальный орган, сформировавший комиссию по проведению технического расследования, в соответствующие органы, представители которых принимали участие в работе комиссии по техническому расследованию, и в другие органы, определенные председателем комиссии",
        ],
      },
    ],
  },
  85: {
    699: [
      {
        code: "68016",
        text: "На какие процессы не распространяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?",
        answers: [
          "На техническое перевооружение опасных производственных объектов, на которых используются водогрейные котлы",
          "На размещение паровых котлов в зданиях котельных помещений опасных производственных объектов",
          "На разработку (проектирование) прямоточного котла",
        ],
        correctAnswers: ["На разработку (проектирование) прямоточного котла"],
      },
      {
        code: "68017",
        text: "При осуществлении каких процессов на ОПО не применяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?",
        answers: [
          "Реконструкция (модернизация) водогрейных котлов",
          "Техническое освидетельствование паровых котлов",
          "Пусконаладочные работы на содорегенерационных котлах",
          "Утилизация энерготехнологического котла на основании результатов технического диагностирования",
        ],
        correctAnswers: [
          "Утилизация энерготехнологического котла на основании результатов технического диагностирования",
        ],
      },
      {
        code: "68018",
        text: "На какие котлы распространяется действие ФНП ОРПД?",
        answers: [
          "Котел на органическом теплоносителе, с рабочим давлением 0,5 МПа, установленный на плавучей буровой установке",
          "Электрокотел вместимостью не более 0,025 м³",
          "Котел, входящий в состав вооружения и военной техники, применяемый для обеспечения интересов обороны и безопасности государства",
          "Паровозный котел железнодорожного подвижного состава",
        ],
        correctAnswers: [
          "Котел на органическом теплоносителе, с рабочим давлением 0,5 МПа, установленный на плавучей буровой установке",
        ],
      },
      {
        code: "68019",
        text: "На какое оборудование распространяется действие Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?",
        answers: [
          "Котел, установленный на речном судне",
          "Котел объемом парового и водяного пространства 0,001 м³",
          "Автономный экономайзер",
          "Отопительный котел железнодорожного подвижного состава",
        ],
        correctAnswers: ["Автономный экономайзер"],
      },
      {
        code: "68020",
        text: "Кто и на основании чего принимает решение о вводе в эксплуатацию котла?",
        answers: [
          "Уполномоченный представитель Ростехнадзора на основании проверки организации надзора за эксплуатацией котла",
          "Ответственный за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением на основании проверки организации надзора за эксплуатацией котла",
          "Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения) на основании проверки готовности котла к пуску в работу и проверки организации надзора за эксплуатацией котла",
          "Ответственный за исправное состояние и безопасную эксплуатацию оборудования под давлением на основании результатов первичного освидетельствования котла и осмотра котла во время парового опробования",
        ],
        correctAnswers: [
          "Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения) на основании проверки готовности котла к пуску в работу и проверки организации надзора за эксплуатацией котла",
        ],
      },
      {
        code: "68021",
        text: "В каком случае в состав комиссии по проверке готовности котла к пуску в работу и организации надзора за его эксплуатацией включаются уполномоченный (уполномоченные) представитель (представители) Ростехнадзора или его территориального органа?",
        answers: [
          "Участие уполномоченного (уполномоченных) представителя (представителей) Ростехнадзора или его территориального органа в комиссии не предусматривается",
          "При осуществлении проверок любых котлов, на которые распространяется действие Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением",
          "Если участие уполномоченного (уполномоченных) представителя (представителей) Ростехнадзора или его территориального органа в комиссии инициируется руководителем (заместителем руководителя) эксплуатирующей организации",
          "При осуществлении проверок только водогрейных котлов",
        ],
        correctAnswers: [
          "При осуществлении проверок любых котлов, на которые распространяется действие Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением",
        ],
      },
      {
        code: "68022",
        text: "Что не контролируется при проведении проверки готовности котла к пуску в работу?",
        answers: [
          "Документация с результатами пусконаладочных испытаний и комплексного опробования оборудования",
          "Наличие положительных результатов технического освидетельствования",
          "Документация, подтверждающая приемку оборудования после окончания пусконаладочных работ",
          "Наличие должностных инструкций для ответственных лиц и специалистов, осуществляющих эксплуатацию котла",
        ],
        correctAnswers: [
          "Наличие должностных инструкций для ответственных лиц и специалистов, осуществляющих эксплуатацию котла",
        ],
      },
      {
        code: "68023",
        text: "Каким образом должны оформляться результаты проверок готовности котла к пуску в работу и организации надзора за его эксплуатацией?",
        answers: [
          "Приказом эксплуатирующей организации или ее обособленного структурного подразделения",
          "Актом готовности котла к вводу в эксплуатацию",
          "Записью в паспорт котла и ремонтный журнал",
          "Протоколом о готовности котла к пуску в работу и эксплуатации",
        ],
        correctAnswers: ["Актом готовности котла к вводу в эксплуатацию"],
      },
      {
        code: "68024",
        text: "На какой период руководителем эксплуатирующей организации может быть принято решение о возможности эксплуатации котла в режиме опытного применения?",
        answers: [
          "Не более 9 месяцев",
          "Не более 12 месяцев",
          "Не более 6 месяцев",
          "Не более 18 месяцев",
        ],
        correctAnswers: ["Не более 6 месяцев"],
      },
      {
        code: "68025",
        text: "На основании чего осуществляется пуск (включение) в работу и штатная остановка котла?",
        answers: [
          "На основании приказа руководителя (заместителя руководителя) эксплуатирующей организации",
          "На основании письменного указания ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования, работающего под давлением",
          "На основании письменного распоряжения ответственного за исправное состояние и безопасную эксплуатацию котла",
        ],
        correctAnswers: [
          "На основании письменного распоряжения ответственного за исправное состояние и безопасную эксплуатацию котла",
        ],
      },
      {
        code: "68026",
        text: "Что из приведенного не указывается на табличке или не наносится на котел перед пуском его в работу?",
        answers: [
          "Номер котла по системе нумерации, принятой в эксплуатирующей организации",
          "Разрешенные давление и температура рабочей среды",
          "Регистрационный номер и даты проведенных осмотров и гидравлических испытаний",
          "Дата следующего гидравлического испытания",
        ],
        correctAnswers: [
          "Регистрационный номер и даты проведенных осмотров и гидравлических испытаний",
        ],
      },
      {
        code: "68027",
        text: "Каким образом осуществляется учет котлов транспортабельных (передвижных) котельных установок в органах Ростехнадзора?",
        answers: [
          "По месту нахождения (государственной регистрации) эксплуатирующей организации",
          "По каждому фактическому адресу эксплуатации котлов, а также по месту стоянки (хранения) котлов",
          "По месту нахождения площадки эксплуатирующей организации, на которой производятся работы по ремонту, техническому обслуживанию и освидетельствованию котлов",
        ],
        correctAnswers: [
          "По месту нахождения (государственной регистрации) эксплуатирующей организации",
        ],
      },
      {
        code: "68028",
        text: "Какой из приведенных котлов не подлежит учету в органах Ростехнадзора?",
        answers: [
          "Электрокотел вместимостью не более 0,5 м³",
          "Котел, эксплуатируемый на опасном производственном объекте IV класса опасности",
          "Котел передвижной (транспортабельной) котельной установки",
          "Учету в органах Ростехнадзора подлежат все котлы, на которые распространяется действие Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением",
        ],
        correctAnswers: [
          "Учету в органах Ростехнадзора подлежат все котлы, на которые распространяется действие Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением",
        ],
      },
      {
        code: "68029",
        text: "В каком из приведенных случаев допускается одному специалисту совмещать ответственность за осуществление производственного контроля за безопасной эксплуатацией котлов и ответственность за их исправное состояние и безопасную эксплуатацию?",
        answers: [
          "Если котлы эксплуатируются только на одной производственной площадке",
          "Если совмещение согласовано руководителем территориального органа Ростехнадзора",
          "Совмещение не допускается",
          "Если данный случай определяется распоряжением (приказом) руководителя эксплуатирующей организации",
        ],
        correctAnswers: ["Совмещение не допускается"],
      },
      {
        code: "68030",
        text: "Какое требование к специалистам и рабочим, обслуживающим котлы, указано неверно?",
        answers: [
          "Рабочие не должны иметь медицинских противопоказаний для выполнения работ по обслуживанию котлов",
          "Рабочие должны пройти аттестацию по промышленной безопасности в аттестационной комиссии эксплуатирующей организации",
          "Рабочие должны быть допущены к самостоятельной работе в порядке, установленном распорядительными документами организации",
          "Обслуживающий и ремонтный персонал должен соответствовать квалификационным требованиям в зависимости от типа котлов, к эксплуатации которых он допускается",
          "Все требования к специалистам и рабочим указаны верно",
        ],
        correctAnswers: [
          "Рабочие должны пройти аттестацию по промышленной безопасности в аттестационной комиссии эксплуатирующей организации",
        ],
      },
      {
        code: "68031",
        text: "Какие инструкции не разрабатываются в организации, эксплуатирующей котлы?",
        answers: [
          "Инструкция для ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением",
          "Инструкция для ответственного за исправное состояние и безопасную эксплуатацию оборудования под давлением",
          "Производственные инструкции для обслуживающего персонала",
          "Инструкция (руководство) по эксплуатации котла",
        ],
        correctAnswers: ["Инструкция (руководство) по эксплуатации котла"],
      },
      {
        code: "68032",
        text: "Что из приведенного не входит в должностные обязанности ответственного за осуществление производственного контроля за безопасной эксплуатацией котлов?",
        answers: [
          "Проверка соблюдения установленного порядка допуска рабочих к самостоятельной работе, а также выдачи им производственных инструкций",
          "Проверка записи в сменном журнале с росписью в нем",
          "Контроль своевременности и полноты проведения ремонта котлов",
          "Требование отстранения от работ и проведения внеочередной проверки знаний для работников, нарушающих требования промышленной безопасности",
        ],
        correctAnswers: ["Проверка записи в сменном журнале с росписью в нем"],
      },
      {
        code: "68033",
        text: "Что из приведенного не входит в должностные обязанности ответственного за исправное состояние и безопасную эксплуатацию котлов?",
        answers: [
          "Контроль своевременности и полноты проведения ремонта котлов",
          "Периодический осмотр котлов в соответствии с должностной инструкцией, но не реже 1 раза в месяц",
          "Своевременное выполнение предписаний по устранению выявленных нарушений",
          "Ведение учета наработки циклов нагружения котла, эксплуатируемого в циклическом режиме",
        ],
        correctAnswers: [
          "Контроль своевременности и полноты проведения ремонта котлов",
        ],
      },
      {
        code: "68034",
        text: "С какой периодичностью проводится проверка знаний рабочих, обслуживающих котлы?",
        answers: [
          "Периодичность устанавливается распорядительными документами эксплуатирующей организации",
          "Один раз в 12 месяцев",
          "Один раз в 6 месяцев",
          "Один раз в 36 месяцев",
        ],
        correctAnswers: ["Один раз в 12 месяцев"],
      },
      {
        code: "68035",
        text: "Какое из приведенных требований к проверке знаний рабочих, обслуживающих котлы, указано неверно?",
        answers: [
          "Результаты проверки знаний рабочих необходимо оформлять протоколом с отметкой в удостоверении о допуске к самостоятельной работе",
          "Участие представителя Ростехнадзора обязательно при проведении первичной аттестации рабочих, обслуживающих котлы",
          "При переходе рабочего в другую организацию должна проводиться внеочередная проверка знаний",
          "При переводе рабочих на обслуживание котлов другого типа или при переводе обслуживаемого ими котла на сжигание другого вида топлива должна быть проведена внеочередная проверка знаний",
        ],
        correctAnswers: [
          "Участие представителя Ростехнадзора обязательно при проведении первичной аттестации рабочих, обслуживающих котлы",
        ],
      },
      {
        code: "68036",
        text: "В каком из приведенных случаев манометр может быть допущен к применению на сосуде?",
        answers: [
          "Если отсутствует информация о проведении поверки (пломба или клеймо, или документ о проведении поверки)",
          "Если разбито стекло или имеются другие повреждения, которые могут отразиться на правильности показаний манометра",
          "Если стрелка манометра при его отключении не возвращается к нулевой отметке шкалы на величину, не превышающую половины допускаемой погрешности для манометра",
          "Во всех приведенных случаях манометр может быть допущен к применению",
        ],
        correctAnswers: [
          "Если стрелка манометра при его отключении не возвращается к нулевой отметке шкалы на величину, не превышающую половины допускаемой погрешности для манометра",
        ],
      },
      {
        code: "68037",
        text: "В каком из приведенных случаев до проверки знаний рабочий, обслуживающий котлы, должен пройти стажировку?",
        answers: [
          "Только перед допуском к самостоятельной работе после профессионального обучения",
          "Только перед допуском к самостоятельной работе после внеочередной проверки знаний",
          "Только при перерыве в работе по специальности более 12 месяцев",
          "Во всех приведенных случаях проводится стажировка",
          "Стажировка должна проводиться во всех приведенных случаях, за исключением перерыва в работе по специальности более 12 месяцев",
        ],
        correctAnswers: ["Во всех приведенных случаях проводится стажировка"],
      },
      {
        code: "68038",
        text: "В каком случае в здания и помещения, в которых эксплуатируются котлы, могут быть допущены посторонние лица, не имеющие отношения к эксплуатации котлов и иного взаимосвязанного с ними основного и вспомогательного оборудования?",
        answers: [
          "При наличии производственной необходимости допуск таких лиц осуществляется только с разрешения эксплуатирующей организации и в сопровождении ее представителя",
          "Допуск таких лиц осуществляется только при полностью остановленном оборудовании в сопровождении работника, ответственного за осуществление производственного контроля за соблюдением требований промышленной безопасности",
          "Допуск таких лиц запрещается в любом случае",
        ],
        correctAnswers: [
          "При наличии производственной необходимости допуск таких лиц осуществляется только с разрешения эксплуатирующей организации и в сопровождении ее представителя",
        ],
      },
      {
        code: "68039",
        text: "Какие условия должна обеспечивать тепловая изоляция участков элементов котлов и трубопроводов с повышенной температурой поверхности?",
        answers: [
          "Максимальное снижение потерь тепла от поверхности каждого элемента с повышенной температурой в окружающую среду",
          "Температуру наружной поверхности изоляции не более 43 °С",
          "Температуру наружной поверхности изоляции не более 55 °С при температуре окружающей среды не более 25 °С",
          "Максимальную компенсацию тепловых потерь зданий и помещений, в которых эксплуатируются котлы",
        ],
        correctAnswers: [
          "Температуру наружной поверхности изоляции не более 55 °С при температуре окружающей среды не более 25 °С",
        ],
      },
      {
        code: "68040",
        text: "Какое из приведенных требований должно выполняться при эксплуатации паровых котлов с чугунными экономайзерами?",
        answers: [
          "Экономайзеры должны быть неотключаемыми по воде",
          "Температура воды на выходе из экономайзера должна быть не менее чем на 20 °С ниже температуры насыщенного пара",
          "Эксплуатация паровых котлов должна осуществляться только со стальными экономайзерами",
          "Температура воды на выходе из экономайзера должна быть не более 150 °С",
        ],
        correctAnswers: [
          "Температура воды на выходе из экономайзера должна быть не менее чем на 20 °С ниже температуры насыщенного пара",
        ],
      },
      {
        code: "68041",
        text: "Какие виды топлива не должны применяться в качестве растопочного для пылеугольных горелок?",
        answers: [
          "Природный газ",
          "Топочный мазут",
          "Любые виды жидкого топлива с температурой вспышки 80 °С",
          "Легковоспламеняющиеся виды жидкого топлива с температурой вспышки выше 61 °﻿С",
        ],
        correctAnswers: [
          "Легковоспламеняющиеся виды жидкого топлива с температурой вспышки выше 61 °﻿С",
        ],
      },
      {
        code: "68042",
        text: "Что из приведенного в соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, не регламентируется производственной инструкцией по режиму работы и безопасному обслуживанию сосудов?",
        answers: [
          "Порядок проведения технического освидетельствования сосудов, не подлежащих учету в территориальном органе Ростехнадзора",
          "Меры безопасности при выводе оборудования в ремонт",
          "Случаи, требующие немедленной остановки сосуда",
          "Порядок, сроки и способы проверки арматуры, предохранительных устройств, приборов автоматики защиты и сигнализации",
        ],
        correctAnswers: [
          "Порядок проведения технического освидетельствования сосудов, не подлежащих учету в территориальном органе Ростехнадзора",
        ],
      },
      {
        code: "68043",
        text: "Какое из приведенных требований к эксплуатации указателей уровня воды паровых котлов указано неверно?",
        answers: [
          "Указатели уровня воды прямого действия необходимо устанавливать вертикально или с наклоном вперед под углом не более 30°",
          "Указатели уровня воды прямого действия должны быть освещены так, чтобы уровень воды был виден с рабочего места персонала, обслуживающего котлы",
          "Указатели уровня воды прямого действия, установленные на котлах на высоте до 6 м, должны иметь защитный кожух",
          "2 сниженных дистанционных указателя уровня должны быть установлены, если расстояние от площадки, с которой производят наблюдение за уровнем воды в паровом котле, до указателей уровня воды прямого действия более 6 м, а также в случаях плохой видимости приборов",
        ],
        correctAnswers: [
          "Указатели уровня воды прямого действия, установленные на котлах на высоте до 6 м, должны иметь защитный кожух",
        ],
      },
      {
        code: "68044",
        text: "Какое из приведенных требований по установке запорных органов на питательном тракте котла указано неверно?",
        answers: [
          "У экономайзера, отключаемого по воде, обратный клапан и запорный орган следует устанавливать и до, и после экономайзера",
          "На питательном трубопроводе котла должны быть установлены обратный клапан, предотвращающий выход воды из котла, и запорный орган",
          "После неотключаемого по воде экономайзера должны быть установлены обратный клапан и запорный орган",
          "Все приведенные требования указаны верно",
        ],
        correctAnswers: [
          "После неотключаемого по воде экономайзера должны быть установлены обратный клапан и запорный орган",
        ],
      },
      {
        code: "68045",
        text: "Какое требование к эксплуатации манометров на котлах указано неверно?",
        answers: [
          "На шкалу манометра должна быть нанесена красная черта на уровне деления, соответствующего максимально допустимому рабочему давлению для данного элемента с учетом добавочного давления от веса столба жидкости",
          "Шкалу манометра необходимо выбирать с условием, что при рабочем давлении стрелка манометра должна находиться во второй трети шкалы",
          "Взамен красной черты допускается в качестве указателя значения максимально допустимого давления прикреплять к корпусу манометра пластину из металла, окрашенную в красный цвет и плотно прилегающую к стеклу манометра",
          "Манометр должен быть установлен так, чтобы его показания были видны обслуживающему персоналу, при этом шкала его должна быть расположена только вертикально",
        ],
        correctAnswers: [
          "Манометр должен быть установлен так, чтобы его показания были видны обслуживающему персоналу, при этом шкала его должна быть расположена только вертикально",
        ],
      },
      {
        code: "68046",
        text: "Для каких котлов продувочные и дренажные трубопроводы, трубопроводы отбора рабочей среды должны оборудоваться не менее чем двумя запорными органами или одним запорным и одним регулирующим органом?",
        answers: [
          "Для котлов с рабочим давлением более 0,8 МПа",
          "Для водогрейных котлов",
          "Для паровых газомазутных котлов с паропроизводительностью более 420 т/ч",
          "Для паровых котлов с многократной принудительной циркуляцией паропроизводительностью 0,7 т/ч и более",
        ],
        correctAnswers: ["Для котлов с рабочим давлением более 0,8 МПа"],
      },
      {
        code: "68047",
        text: "Манометры какого класса точности необходимо применять при эксплуатации сосудов с рабочим давлением до 2,5 МПа включительно?",
        answers: ["Не ниже 2,5", "Не ниже 1,5", "Не ниже 0,6", "Не ниже 0,4"],
        correctAnswers: ["Не ниже 2,5"],
      },
      {
        code: "68048",
        text: "Каково минимальное значение номинального диаметра корпуса манометра, установленного на высоте менее 2 м от уровня площадки наблюдения?",
        answers: ["100 мм", "160 мм", "250 мм", "80 мм"],
        correctAnswers: ["100 мм"],
      },
      {
        code: "68049",
        text: "Главные парозапорные органы каких котлов должны быть оборудованы дистанционным управлением с рабочего места обслуживающего котел персонала?",
        answers: [
          "Паровых газомазутных котлов",
          "Паровых пылеугольных котлов с паропроизводительностью более 0,7 т/ч",
          "Котлов с рабочим давлением более 10 МПа",
          "Котлов с паропроизводительностью более 4 т/ч",
        ],
        correctAnswers: ["Котлов с паропроизводительностью более 4 т/ч"],
      },
      {
        code: "68050",
        text: "Каково минимальное значение номинального диаметра корпуса манометра, установленного на высоте от 2 до 5 м от уровня площадки наблюдения?",
        answers: ["63 мм", "100 мм", "160 мм", "250 мм"],
        correctAnswers: ["160 мм"],
      },
      {
        code: "68051",
        text: "Каково минимальное значение номинального диаметра корпуса манометра, установленного на высоте более 5 м от уровня площадки наблюдения?",
        answers: [
          "Устанавливается эксплуатирующей организацией по результатам испытаний с учетом полной видимости показаний манометра наблюдающему",
          "100 мм",
          "160 мм",
          "250 мм",
        ],
        correctAnswers: ["250 мм"],
      },
      {
        code: "68052",
        text: "В каком случае на котле помимо рабочего манометра должен устанавливаться сниженный манометр в качестве дублирующего?",
        answers: [
          "Если видимость приборов в котельном помещении не соответствует установленным нормативам",
          "Если рабочий манометр применяется в качестве датчика наличия давления в котле",
          "Если рабочее место машиниста (оператора) котла располагается на расстоянии более 1,5 м от котла",
          "Если рабочий манометр установлен на высоте более 5 м",
        ],
        correctAnswers: [
          "Если рабочий манометр установлен на высоте более 5 м",
        ],
      },
      {
        code: "68053",
        text: "Для какого котла допускается работа с одним электроприводным питательным насосом?",
        answers: [
          "Для водогрейного электрокотла при электрической мощности не более 2,5 МВт",
          "Для парового котла с рабочим давлением менее 0,8 МПа",
          "Для котла паропроизводительностью не более 4 т/ч, если котел оснащен автоматикой безопасности по предельным уровням воды",
          "Работа котлов с одним электроприводным питательным насосом запрещается",
        ],
        correctAnswers: [
          "Для котла паропроизводительностью не более 4 т/ч, если котел оснащен автоматикой безопасности по предельным уровням воды",
        ],
      },
      {
        code: "68054",
        text: "В каком случае перед манометром на котле должна устанавливаться сифонная трубка?",
        answers: [
          "Если манометр предназначен для измерения давления пара",
          "Если манометр установлен на котле с рабочим давлением 4 МПа и более",
          "В любом случае перед каждым манометром, установленным на котле",
          "Если манометр предназначен для измерения давления жидкостей",
        ],
        correctAnswers: [
          "Если манометр предназначен для измерения давления пара",
        ],
      },
      {
        code: "68055",
        text: "Какие котлы перед вводом в эксплуатацию после монтажа подвергаются очистке совместно с основными трубопроводами и другими элементами водопарового тракта?",
        answers: [
          "Прямоточные котлы с рабочим давлением 10 МПа и более",
          "Паровые котлы с рабочим давлением 10 МПа и более",
          "Прямоточные котлы с рабочим давлением 8 МПа и более",
          "Водогрейные котлы с рабочим давлением 8 МПа и более",
        ],
        correctAnswers: ["Паровые котлы с рабочим давлением 10 МПа и более"],
      },
      {
        code: "68056",
        text: "Какое требование к заполнению котла водой перед растопкой указано неверно?",
        answers: [
          "Перед растопкой барабанный котел необходимо заполнить химически очищенной и деаэрированной питательной водой",
          "Перед растопкой прямоточный котел должен быть заполнен деаэрированной водой",
          "Разрешается заполнять чугунные котлы химически очищенной водой, если в котельной отсутствует деаэрационная установка",
          "Допускается заполнять неостывший барабанный котел при температуре металла верха опорожненного барабана не выше 160 °С",
        ],
        correctAnswers: [
          "Перед растопкой прямоточный котел должен быть заполнен деаэрированной водой",
        ],
      },
      {
        code: "68057",
        text: "Какое требование к заполнению прямоточного котла перед его растопкой должно выполняться?",
        answers: [
          "При прямоточном режиме растопки заполнение водой прямоточного котла, удаление из него воздуха, а также операции при промывке от загрязнений необходимо выполнять на участке до встроенных в тракт котла задвижек",
          "При сепараторном режиме растопки заполнение водой прямоточного котла, удаление из него воздуха, а также операции при промывке от загрязнений необходимо выполнять по всему тракту",
          "Растопочный расход воды должен быть не более 45 % от номинального расхода, другое значение может быть определено на основании результатов испытаний",
          "Растопочный расход воды должен быть равен 30 % номинального расхода",
        ],
        correctAnswers: [
          "Растопочный расход воды должен быть равен 30 % номинального расхода",
        ],
      },
      {
        code: "68058",
        text: "В каком случае допускается растопка прямоточных котлов на скользящем давлении?",
        answers: [
          "Допускается только для прямоточных котлов с рабочим давлением 12 МПа",
          "Допускается только для прямоточных котлов на сверхкритическое давление после проведения специальных испытаний",
          "Допускается по согласованию с организацией-изготовителем на основе специальных испытаний",
          "Запрещается в любом случае",
        ],
        correctAnswers: [
          "Допускается по согласованию с организацией-изготовителем на основе специальных испытаний",
        ],
      },
      {
        code: "68059",
        text: "Чему равны минимальные значения расхода воздуха и времени вентилирования при вентиляции газоходов и топки котла перед его растопкой и после его остановки, если иные значения не определены организацией-изготовителем или наладочной организацией?",
        answers: [
          "Время вентилирования 15 минут, расход воздуха 50 %",
          "Время вентилирования 10 минут, расход воздуха 25 %",
          "Время вентилирования 10 минут, расход воздуха 30 %",
          "Время вентилирования 5 минут, расход воздуха 10 %",
        ],
        correctAnswers: ["Время вентилирования 10 минут, расход воздуха 25 %"],
      },
      {
        code: "68060",
        text: "Каким образом должна осуществляться вентиляция топки и газоходов перед растопкой котла из неостывшего состояния при сохранившемся в нем избыточном давлении?",
        answers: [
          "Вентиляцию топки и газоходов запрещается проводить перед растопкой котла из неостывшего состояния",
          "Вентиляция должна начинаться не позднее чем за 10 минут до розжига горелок",
          "Вентиляция должна начинаться не ранее чем за 15 минут до розжига горелок",
          "Вентиляцию топки и газоходов запрещается проводить перед растопкой котла при сохранившемся в нем избыточном давлении",
        ],
        correctAnswers: [
          "Вентиляция должна начинаться не ранее чем за 15 минут до розжига горелок",
        ],
      },
      {
        code: "68061",
        text: "Каким образом должна выполняться продувка верхних водоуказательных приборов при контроле уровня воды в барабане, осуществляемом с момента начала растопки котла с рабочим давлением 4,0 МПа и менее?",
        answers: [
          "Продувка должна выполняться при избыточном давлении в котле 0,1 МПа и перед включением котла в главный паропровод",
          "Продувка должна выполняться при избыточном давлении в котле 0,4 МПа и непрерывно в течение 30 минут до включения котла в главный паропровод",
          "Продувка должна выполняться при избыточном давлении в котле 0,2 МПа и за 20 минут до включения котла в главный паропровод",
          "Продувка должна выполняться при избыточном давлении в котле 0,3 МПа и при давлении 4,0 МПа",
        ],
        correctAnswers: [
          "Продувка должна выполняться при избыточном давлении в котле 0,1 МПа и перед включением котла в главный паропровод",
        ],
      },
      {
        code: "68062",
        text: "Каким образом должна выполняться продувка верхних водоуказательных приборов при контроле уровня воды в барабане, осуществляемом с момента начала растопки котла с рабочим давлением более 4,0 МПа?",
        answers: [
          "Продувка должна выполняться при избыточном давлении в котле 0,2 МПа и перед включением котла в главный паропровод",
          "Продувка должна выполняться непрерывно в течение 30 минут до включения котла в главный паропровод",
          "Продувка должна выполняться при избыточном давлении в котле 0,3 МПа и при избыточном давлении 1,5 - 3,0 МПа",
          "Продувка должна выполняться при избыточном давлении в котле 0,1 МПа и при давлении 1,0 - 2,0 МПа",
        ],
        correctAnswers: [
          "Продувка должна выполняться при избыточном давлении в котле 0,3 МПа и при избыточном давлении 1,5 - 3,0 МПа",
        ],
      },
      {
        code: "68063",
        text: "Какое требование к растопке котла указано неверно?",
        answers: [
          "Растопку котла из различных тепловых состояний необходимо выполнять в соответствии с графиками пуска и на основании производственных инструкций",
          "Тепловое перемещение экранов, барабанов, паропроводов и коллекторов должно проверяться по реперам в процессе растопки котла из холодного состояния после ремонта, но не реже 1 раза в год",
          "Если до пуска котла на нем производили работы, связанные с разборкой фланцевых соединений и лючков, то при избыточном давлении 0,7 - 1,0 МПа должны быть подтянуты болтовые соединения",
          "Запрещается превышать значения скорости прогрева нижней образующей барабана и перепада температур между верхней и нижней образующими барабана, установленные руководством (инструкцией) по эксплуатации",
          "Все требования к растопке котла указаны верно",
        ],
        correctAnswers: [
          "Если до пуска котла на нем производили работы, связанные с разборкой фланцевых соединений и лючков, то при избыточном давлении 0,7 - 1,0 МПа должны быть подтянуты болтовые соединения",
        ],
      },
      {
        code: "68064",
        text: "При выполнении какого условия допускается ускоренное расхолаживание котла при его останове?",
        answers: [
          "При скорости охлаждения во время останова котла не более 20 °С за 10 минут",
          "При перепаде температур между верхней и нижней образующими барабана не более 60 °С",
          "При выполнении режимных параметров ускоренного расхолаживания, указанных в руководстве (инструкции) по эксплуатации",
          "На котлах ускоренное расхолаживание не допускается",
        ],
        correctAnswers: ["На котлах ускоренное расхолаживание не допускается"],
      },
      {
        code: "68065",
        text: "Каково максимально допустимое значение давления при работающих предохранительных клапанах в сосуде с давлением более 6 МПа?",
        answers: [
          "Превышающее разрешенное давление на 10 %",
          "Превышающее разрешенное давление на 20 %",
          "Превышающее разрешенное давление на 16 %",
          "Превышающее разрешенное давление на 13 %",
        ],
        correctAnswers: ["Превышающее разрешенное давление на 10 %"],
      },
      {
        code: "68066",
        text: "Если осуществляется пуск котла после кратковременного простоя, то при каком минимальном значении тепловой нагрузки на растопочном топливе допускается переход на сжигание твердого топлива с выходом летучих масс менее 15 %?",
        answers: [
          "15 % номинальной тепловой нагрузки",
          "30 % номинальной тепловой нагрузки",
          "20 % номинальной тепловой нагрузки",
          "10 % номинальной тепловой нагрузки",
        ],
        correctAnswers: ["15 % номинальной тепловой нагрузки"],
      },
      {
        code: "68067",
        text: "Каково предельное значение разницы в номинальной производительности отдельных форсунок в комплекте, устанавливаемом на мазутный котел?",
        answers: [
          "Не более 0,9 %",
          "Не более 1,5 %",
          "Не более 2,0 %",
          "Не более 3,5 %",
        ],
        correctAnswers: ["Не более 1,5 %"],
      },
      {
        code: "68068",
        text: "Каково нормируемое максимальное значение присосов воздуха в топку парового газомазутного котла, оснащенного цельносварными экранами?",
        answers: [
          "Для парового газомазутного котла, оснащенного цельносварными экранами, паропроизводительностью до 420 т/ч - не более 5 % от теоретически необходимого количества воздуха для номинальной нагрузки котла, а для котла паропроизводительностью более 420 т/ч - не более 3 %",
          "Для каждого парового газомазутного котла, оснащенного цельносварными экранами, - не более 10 % от теоретически необходимого количества воздуха для номинальной нагрузки котла",
          "Для каждого парового газомазутного котла, оснащенного цельносварными экранами, - не более 1,5 % от теоретически необходимого количества воздуха для номинальной нагрузки котла",
          "Топки с цельносварными экранами должны быть бесприсосными",
        ],
        correctAnswers: [
          "Топки с цельносварными экранами должны быть бесприсосными",
        ],
      },
      {
        code: "68069",
        text: "Каково нормируемое максимальное значение присосов воздуха в топку водогрейного газомазутного котла?",
        answers: [
          "Не более 3 % от теоретически необходимого количества воздуха для номинальной нагрузки котла",
          "Не более 5 % от теоретически необходимого количества воздуха для номинальной нагрузки котла",
          "Не более 10 % от теоретически необходимого количества воздуха для номинальной нагрузки котла",
          "Топки водогрейных котлов должны быть бесприсосными",
        ],
        correctAnswers: [
          "Не более 5 % от теоретически необходимого количества воздуха для номинальной нагрузки котла",
        ],
      },
      {
        code: "68070",
        text: "В какие сроки должен проводиться контроль плотности ограждающих поверхностей котла и газоходов и исправности взрывных предохранительных клапанов (при их наличии)?",
        answers: [
          "Не реже одного раза в 12 месяцев",
          "До и после ремонта котла и газоходов",
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в месяц",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "68071",
        text: "В какие сроки проводится проверка исправности действия манометров, предохранительных клапанов, указателей уровня воды и питательных насосов при эксплуатации котлов с рабочим давлением более 1,4 до 4,0 МПа (кроме котлов, установленных на тепловых электростанциях)?",
        answers: [
          "Не реже одного раза в течение 48 часов",
          "Не реже одного раза в сутки",
          "Проверка проводится в соответствии с графиком, утвержденным техническим руководителем (главным инженером) эксплуатирующей организации, но не реже одного раза в 6 месяцев",
          "Проверка манометров, предохранительных клапанов и указателей уровня воды проводится не реже одного раза в течение 24 часов, проверка резервных питательных насосов - перед их пуском",
        ],
        correctAnswers: ["Не реже одного раза в сутки"],
      },
      {
        code: "68072",
        text: "Какое требование к проверке исправности манометра указано неверно?",
        answers: [
          "Проверку исправности манометра следует проводить с помощью трехходового крана или заменяющих его запорных вентилей путем установки стрелки манометра на нуль",
          "Эксплуатирующая организация обязана не реже одного раза в 6 месяцев проводить проверку рабочих манометров контрольным рабочим манометром, имеющим одинаковые с проверяемым манометром шкалу и класс точности",
          "Поверка манометров должна проводиться в установленном порядке не реже одного раза в 12 месяцев (если иные сроки не установлены документацией на конкретный тип манометра)",
        ],
        correctAnswers: [
          "Эксплуатирующая организация обязана не реже одного раза в 6 месяцев проводить проверку рабочих манометров контрольным рабочим манометром, имеющим одинаковые с проверяемым манометром шкалу и класс точности",
        ],
      },
      {
        code: "68073",
        text: "В каком случае допускается превышение разрешенного давления в трубопроводе при полном открывании предохранительного клапана более чем на 10 %?",
        answers: [
          "Если это превышение предусмотрено расчетом на прочность трубопровода",
          "Если эксплуатация трубопровода разрешается на пониженном давлении",
          "Если одновременно открываются все предохранительные клапаны, установленные на защищаемом участке трубопровода",
          "Если установленные предохранительные клапаны предусматривают превышение разрешенного давления более чем на 50 %",
        ],
        correctAnswers: [
          "Если это превышение предусмотрено расчетом на прочность трубопровода",
        ],
      },
      {
        code: "68074",
        text: "В каком из приведенных случаев манометр может быть допущен к применению?",
        answers: [
          "Если на манометре отсутствует пломба или клеймо с отметкой о проведении поверки или истек срок поверки манометра",
          "Если стрелка манометра при его отключении не возвращается к нулевой отметке шкалы на величину, не превышающую половины допускаемой погрешности для манометра",
          "Если разбито стекло манометра или имеются другие повреждения, которые могут отразиться на правильности его показаний",
          "Во всех приведенных случаях запрещается применение манометра",
        ],
        correctAnswers: [
          "Если стрелка манометра при его отключении не возвращается к нулевой отметке шкалы на величину, не превышающую половины допускаемой погрешности для манометра",
        ],
      },
      {
        code: "68075",
        text: "С какой периодичностью осуществляется проверка исправности сигнализации и автоматических защит на котле?",
        answers: [
          "Не реже 1 раза в сутки",
          "Для паровых котлов проверка осуществляется не реже 1 раза в 2 недели, для водогрейных котлов - не реже 1 раза в месяц",
          "Периодичность устанавливается эксплуатирующей организацией и отражается в утвержденном этой организацией графике и инструкции",
          "Периодичность определяется Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением",
        ],
        correctAnswers: [
          "Периодичность устанавливается эксплуатирующей организацией и отражается в утвержденном этой организацией графике и инструкции",
        ],
      },
      {
        code: "68076",
        text: "В каком из приведенных случаев не проводятся эксплуатационные испытания (режимная наладка) котла?",
        answers: [
          "При вводе котла в эксплуатацию",
          "После внесения изменений в конструкцию котла",
          "При переходе на другой вид или марку топлива",
          "При пуске котла после планового ремонта",
          "Эксплуатационные испытания проводятся во всех приведенных случаях",
        ],
        correctAnswers: ["При пуске котла после планового ремонта"],
      },
      {
        code: "68077",
        text: "В каком из приведенных случаев допускается подпитывать остановленный котел с дренированием воды в целях ускорения охлаждения барабана?",
        answers: [
          "Если температура стенки барабана котла не более 160 °С",
          "Если подпитывается барабанный котел с рабочим давлением до 0,8 МПа",
          "Если подпитывается барабанный паровой котел паропроизводительностью не более 0,7 т/ч",
          "Подпитка котла в данных целях не допускается",
        ],
        correctAnswers: ["Подпитка котла в данных целях не допускается"],
      },
      {
        code: "68078",
        text: "При каких условиях разрешается спуск воды из остановленного парового котла с естественной циркуляцией (кроме энергетических котлов, установленных на тепловых электростанциях)?",
        answers: [
          "При понижении избыточного давления в котле до 0,8 МПа",
          "При понижении избыточного давления в котле до 0,3 МПа",
          "При понижении давления в котле до атмосферного давления",
          "При снижении температуры воды в котле до 60 °С",
        ],
        correctAnswers: [
          "При понижении давления в котле до атмосферного давления",
        ],
      },
      {
        code: "68079",
        text: "При каких условиях разрешается спуск воды из остановленного водогрейного котла?",
        answers: [
          "После охлаждения воды в нем до температуры, равной температуре воды в обратном трубопроводе, но не выше 70 °С",
          "После охлаждения воды в нем до температуры, меньшей температуры воды в обратном трубопроводе",
          "После охлаждения воды в нем до температуры не выше 80 °С",
          "При давлении выше атмосферного, верхний предел которого устанавливается руководством (инструкцией) по эксплуатации",
        ],
        correctAnswers: [
          "После охлаждения воды в нем до температуры, равной температуре воды в обратном трубопроводе, но не выше 70 °С",
        ],
      },
      {
        code: "68080",
        text: "Каким образом должно осуществляться расхолаживание котлов после остановки при выводе их в ремонт?",
        answers: [
          "Расхолаживание котлов должно осуществляться тягодутьевыми машинами с условием обеспечения допустимой разности температур металла между верхней и нижней образующими барабана",
          "Расхолаживание котлов должно осуществляться не ранее чем через 3 часа после остановки со скоростью не более 10 °С за 5 минут",
          "Режим расхолаживания котлов должен быть определен руководством (инструкцией) по эксплуатации",
          "Режим расхолаживания котлов определяется территориальными органами Ростехнадзора отдельно по каждому опасному производственному объекту",
        ],
        correctAnswers: [
          "Режим расхолаживания котлов должен быть определен руководством (инструкцией) по эксплуатации",
        ],
      },
      {
        code: "68081",
        text: "Какой организацией должна быть разработана технологическая документация, регламентирующая содержание и порядок выполнения работ по монтажу, ремонту, реконструкции (модернизации) оборудования, работающего под давлением, с применением сварки и термической обработки?",
        answers: [
          "Эксплуатирующей организацией",
          "Организацией - изготовителем оборудования, подлежащего монтажу, ремонту, реконструкции (модернизации)",
          "Уполномоченной специализированной организацией совместно с наладочной организацией, после согласования с Ростехнадзором",
          "Специализированной организацией, выполняющей эти работы, до начала их производства",
        ],
        correctAnswers: [
          "Специализированной организацией, выполняющей эти работы, до начала их производства",
        ],
      },
      {
        code: "68082",
        text: "Какие из приведенных котлов не оборудуются установками для докотловой обработки воды?",
        answers: [
          "Паровые котлы с естественной циркуляцией и паропроизводительностью менее 0,7 т/ч",
          "Водогрейные котлы с рабочим давлением менее 10 МПа",
          "Прямоточные паровые котлы паропроизводительностью не более 2,5 т/ч",
          "Все приведенные котлы должны оборудоваться установками докотловой обработки воды",
        ],
        correctAnswers: [
          "Паровые котлы с естественной циркуляцией и паропроизводительностью менее 0,7 т/ч",
        ],
      },
      {
        code: "68083",
        text: "Какое условие должно выполняться для обеспечения безопасности котлов, не оборудованных установками докотловой обработки воды?",
        answers: [
          "Должен соблюдаться увеличенный расход непрерывной продувки котла, указанный в руководстве (инструкции) по эксплуатации",
          "Периодичность проведения чисток котла не должна быть более такой, чтобы толщина отложений на наиболее теплонапряженных участках к моменту остановки котла превышала 0,8 - 1,2 мм",
          "Должны проводиться периодические чистки котла по графику, основанному на показателях жесткости питательной и щелочности котловой воды",
          "Проведение чисток котла с периодичностью при условии, чтобы толщина отложений на наиболее теплонапряженных участках к моменту остановки котла не превышала 0,5 мм",
        ],
        correctAnswers: [
          "Проведение чисток котла с периодичностью при условии, чтобы толщина отложений на наиболее теплонапряженных участках к моменту остановки котла не превышала 0,5 мм",
        ],
      },
      {
        code: "68084",
        text: "Какое требование к подпитке котлов сырой водой указано неверно?",
        answers: [
          "Подпитка сырой водой котлов, оборудованных устройствами для докотловой обработки воды, не допускается",
          "Каждый случай подпитки котлов сырой водой необходимо фиксировать в журнале по водоподготовке (водно-химическому режиму) с указанием длительности подпитки и качества питательной воды в этот период",
          "Когда проектом предусмотрена в аварийных ситуациях подпитка котла сырой водой, котлы должны работать на сниженных температурных параметрах с температурой теплоносителя на выходе из котла не более 90 °С",
          "Все требования к подпитке котлов сырой водой указаны верно",
        ],
        correctAnswers: [
          "Когда проектом предусмотрена в аварийных ситуациях подпитка котла сырой водой, котлы должны работать на сниженных температурных параметрах с температурой теплоносителя на выходе из котла не более 90 °С",
        ],
      },
      {
        code: "68085",
        text: "Кем устанавливается периодичность отбора проб исходной, химочищенной, котловой, сетевой, питательной и подпиточной воды, конденсата и пара?",
        answers: [
          "Организацией - изготовителем оборудования под давлением",
          "Наладочной организацией",
          "Эксплуатирующей организацией",
          "Ростехнадзором",
        ],
        correctAnswers: ["Наладочной организацией"],
      },
      {
        code: "68086",
        text: "Кем вносятся сведения в ремонтный журнал котла о выполненных ремонтных работах, примененных материалах, сварке и сварщиках, об остановке котлов на чистку и промывку?",
        answers: [
          "Ответственным за осуществление производственного контроля за безопасной эксплуатацией оборудования, работающего под давлением",
          "Ответственным за исправное состояние и безопасную эксплуатацию котла",
          "Уполномоченным работником специализированной организации, которая проводила ремонт котла",
          "Работником, определенным распорядительными документами эксплуатирующей организации",
        ],
        correctAnswers: [
          "Ответственным за исправное состояние и безопасную эксплуатацию котла",
        ],
      },
      {
        code: "68087",
        text: "Какое требование к отключению котла до начала производства работ внутри барабана или коллектора котла, соединенного с другими работающими котлами трубопроводами, указано неверно?",
        answers: [
          "До начала производств работ котел должен быть отсоединен от всех трубопроводов заглушками, если на них установлена фланцевая арматура",
          "Если арматура трубопроводов пара и воды бесфланцевая, отключение котла должно производиться двумя запорными устройствами при наличии между ними дренажного устройства с номинальным диаметром не менее 32 мм, имеющего прямое соединение с атмосферой",
          "Приводы задвижек, вентилей открытых дренажей и линии аварийного слива воды из барабана должны запираться на замок так, чтобы исключалась возможность ослабления их плотности при запертом замке",
          "Ключи от замков должны храниться у ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением, если на предприятии не установлен другой порядок их хранения",
        ],
        correctAnswers: [
          "Ключи от замков должны храниться у ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением, если на предприятии не установлен другой порядок их хранения",
        ],
      },
      {
        code: "68088",
        text: "В соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, в каком из приведенных случаев котел не подлежит аварийной остановке?",
        answers: [
          "В случае повышения уровня воды выше допустимого уровня",
          "В случае снижения давления воды в тракте водогрейного котла ниже допустимого",
          "В случае повышения давления в барабане котла выше разрешенного на 10 % при полностью открытых предохранительных клапанах",
          "В случае обнаружения трещин, выпучин, течей (пропусков) в металле и сварных швах элементов котла, обрыва анкерного болта или связи",
        ],
        correctAnswers: [
          "В случае повышения давления в барабане котла выше разрешенного на 10 % при полностью открытых предохранительных клапанах",
        ],
      },
      {
        code: "68089",
        text: "В соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, в каком из приведенных случаев котел не подлежит аварийной остановке?",
        answers: [
          "В случае обнаружения неисправности предохранительного клапана",
          "В случае прекращения работы одного из питательных насосов",
          "В случае неисправности автоматики безопасности или аварийной сигнализации, включая исчезновение напряжения на этих устройствах",
          "В случае повышения давления в барабане котла выше разрешенного на 10 % и продолжающегося его роста",
        ],
        correctAnswers: [
          "В случае прекращения работы одного из питательных насосов",
        ],
      },
      {
        code: "68090",
        text: "В соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, в каком из приведенных случаев котел подлежит аварийной остановке?",
        answers: [
          "В случае понижения уровня воды в котле до низшего допустимого уровня",
          "В случае повышения температуры воды на выходе из водогрейного котла до значения на 20 °С ниже температуры насыщения, соответствующей рабочему давлению воды в выходном коллекторе котла",
          "В случае повышения давления в барабане котла выше разрешенного на 5 % и продолжающегося его роста",
          "В случае прекращения действия одного из питательных насосов",
        ],
        correctAnswers: [
          "В случае повышения температуры воды на выходе из водогрейного котла до значения на 20 °С ниже температуры насыщения, соответствующей рабочему давлению воды в выходном коллекторе котла",
        ],
      },
      {
        code: "68091",
        text: "Каким документом определяется порядок действий в случае инцидента при эксплуатации котла?",
        answers: [
          "Производственная инструкция, утвержденная эксплуатирующей организацией",
          "Правила промышленной безопасности при использовании оборудования, работающего под избыточным давлением",
          "Инструкция (руководство) предприятия-изготовителя по эксплуатации котла",
        ],
        correctAnswers: [
          "Производственная инструкция, утвержденная эксплуатирующей организацией",
        ],
      },
      {
        code: "68092",
        text: "При каком условии допускается установка переключающего устройства перед мембранными предохранительными устройствами?",
        answers: [
          "Если установлено удвоенное число мембранных устройств с обеспечением при этом защиты сосуда от превышения давления при любом положении переключающего устройства",
          "Если после переключающего устройства и предохранительного клапана устанавливается отводная трубка с манометром, показывающим отсутствие давления (при исправном состоянии мембраны) или наличие давления (при нарушении герметичности (разрушении) мембраны)",
          "Если переключающее устройство устанавливается перед каждым мембранным предохранительным устройством, задействованным в системе",
        ],
        correctAnswers: [
          "Если установлено удвоенное число мембранных устройств с обеспечением при этом защиты сосуда от превышения давления при любом положении переключающего устройства",
        ],
      },
      {
        code: "68093",
        text: "Какие условия должны соблюдаться при установлении срока следующего периодического технического освидетельствования котла и трубопровода?",
        answers: [
          "Допускается превышение срока следующего периодического технического освидетельствования над сроком службы котла, установленным организацией-изготовителем, или заключением экспертизы промышленной безопасности в случае продления срока службы трубопровода или по результатам технического диагностирования, но не более чем на 12 месяцев",
          "Если срок периодического освидетельствования не установлен изготовителем оборудования в руководстве (инструкции) по эксплуатации, то он должен устанавливаться экспертной организацией",
          "Срок следующего периодического технического освидетельствования не должен превышать срока службы, установленного изготовителем или заключением экспертизы промышленной безопасности, оформленным по результатам технического диагностирования при продлении срока службы трубопровода",
        ],
        correctAnswers: [
          "Срок следующего периодического технического освидетельствования не должен превышать срока службы, установленного изготовителем или заключением экспертизы промышленной безопасности, оформленным по результатам технического диагностирования при продлении срока службы трубопровода",
        ],
      },
      {
        code: "68094",
        text: "Каким документом (документами) устанавливается объем работ, порядок и периодичность проведения технических освидетельствований в пределах срока службы котла?",
        answers: [
          "Инструкция (руководство) по эксплуатации предприятия - изготовителя котла и ФНП ОРПД",
          "Программа проведения технического освидетельствования котла и паспорт котла",
          "Производственная инструкция по эксплуатации котла",
        ],
        correctAnswers: [
          "Инструкция (руководство) по эксплуатации предприятия - изготовителя котла и ФНП ОРПД",
        ],
      },
      {
        code: "68095",
        text: "В каком из приведенных случаев не проводится внеочередное техническое освидетельствование котла?",
        answers: [
          "Если котел не эксплуатировался более 12 месяцев",
          "Если проведена установка на новом месте передвижного котла, эксплуатируемого одной и той же организацией",
          "Если проведен ремонт с применением сварки элементов под давлением и заменой одного из питательных насосов",
          "Если при ремонте сменено 25 % анкерных связей одной стенки котла",
        ],
        correctAnswers: [
          "Если проведена установка на новом месте передвижного котла, эксплуатируемого одной и той же организацией",
        ],
      },
      {
        code: "68096",
        text: "В каком случае техническое освидетельствование котла проводится ответственным за осуществление производственного контроля за безопасной эксплуатацией оборудования и ответственным за исправное состояние и безопасную эксплуатацию котла?",
        answers: [
          "В любом случае проводится только уполномоченной специализированной организацией",
          "Только если котел не подлежит учету в Ростехнадзоре",
          "Только если проводится периодическое техническое освидетельствование котла",
          "Только первичное техническое освидетельствование котлов, прибывших на место установки в собранном виде, которые подвергались осмотру и испытаниям организацией-изготовителем",
        ],
        correctAnswers: [
          "Только первичное техническое освидетельствование котлов, прибывших на место установки в собранном виде, которые подвергались осмотру и испытаниям организацией-изготовителем",
        ],
      },
      {
        code: "68097",
        text: "Кем проводятся технические освидетельствования сосудов, не подлежащих учету в территориальном органе Ростехнадзора?",
        answers: [
          "Независимыми экспертами",
          "Аттестованными специалистами специализированной организации",
          "Ответственными специалистами эксплуатирующей организации",
        ],
        correctAnswers: [
          "Ответственными специалистами эксплуатирующей организации",
        ],
      },
      {
        code: "68098",
        text: "В какие сроки проводятся наружный и внутренний осмотры котла, если иные сроки не предусмотрены руководством (инструкцией) по эксплуатации?",
        answers: [
          "Один раз в четыре года наружный и внутренний осмотры",
          "Наружный осмотр - один раз в четыре года, внутренний осмотр - один раз в восемь лет",
          "Наружный осмотр - один раз в восемь лет, внутренний осмотр - один раз в четыре года",
          "Один раз в восемь лет наружный и внутренний осмотры",
        ],
        correctAnswers: [
          "Один раз в четыре года наружный и внутренний осмотры",
        ],
      },
      {
        code: "68099",
        text: "В какие сроки проводится гидравлическое испытание котла, если иные сроки не предусмотрены руководством (инструкцией) по эксплуатации?",
        answers: [
          "Один раз в четыре года",
          "Один раз в восемь лет",
          "Один раз в шесть лет",
          "В сроки, установленные эксплуатирующей организацией, но не реже одного раза в пять лет",
        ],
        correctAnswers: ["Один раз в восемь лет"],
      },
      {
        code: "68100",
        text: "В каком случае ответственный за исправное состояние, безопасную эксплуатацию котла обязан проводить наружный и внутренний осмотры котла?",
        answers: [
          "Ни в каком случае, т. к. наружный и внутренний осмотры котла проводятся только уполномоченной специализированной организацией",
          "В случае проведения первичного или внеочередного освидетельствования котла",
          "В каждом случае, установленном уполномоченной специализированной организацией, но не реже одного раза в 36 месяцев",
          "Перед началом и после окончания планового ремонта, но не реже одного раза в 12 месяцев, если иные сроки не предусмотрены в руководстве (инструкции) по эксплуатации",
        ],
        correctAnswers: [
          "Перед началом и после окончания планового ремонта, но не реже одного раза в 12 месяцев, если иные сроки не предусмотрены в руководстве (инструкции) по эксплуатации",
        ],
      },
      {
        code: "68101",
        text: "Что необходимо предпринять после вскрытия барабана, коллектора или ремонта котла, если характер и объем ремонта не вызывают необходимости проведения внеочередного технического освидетельствования?",
        answers: [
          "Ответственный за исправное состояние и безопасную эксплуатацию котла должен внести данные об отсутствии необходимости во внеочередном техническом освидетельствовании в ремонтный журнал",
          "Ответственный за производственный контроль за безопасной эксплуатацией оборудования под давлением должен провести гидравлическое испытание котла пробным давлением, превышающим рабочее на 5 %",
          "Представитель уполномоченной специализированной организации должен внести данные об этом, а также отметку о датах следующих наружных и внутренних осмотров в паспорт котла",
          "Ответственный за исправное состояние и безопасную эксплуатацию котла должен провести гидравлическое испытание котла рабочим давлением",
        ],
        correctAnswers: [
          "Ответственный за исправное состояние и безопасную эксплуатацию котла должен провести гидравлическое испытание котла рабочим давлением",
        ],
      },
      {
        code: "68102",
        text: "Что дополнительно проводится при техническом освидетельствовании электрокотлов?",
        answers: [
          "Испытания электрической части",
          "Контроль на разрыв, статический изгиб и ударную вязкость",
          "Внутренний осмотр котлов и их элементов",
          "Дополнительные работы или испытания не требуются",
        ],
        correctAnswers: ["Испытания электрической части"],
      },
      {
        code: "68103",
        text: "Для какого из приведенных котлов должна проводиться экспертиза промышленной безопасности перед вводом их в эксплуатацию?",
        answers: [
          "Водогрейный котел, работающий под избыточным давлением воды 0,06 МПа при температуре 105 °С",
          "Отопительный котел железнодорожного подвижного состава",
          "Паровой котел с рабочим избыточным давлением 0,3 МПа, установленный на плавучей драге",
          "Для всех приведенных котлов проводится экспертиза промышленной безопасности перед вводом их в эксплуатацию",
        ],
        correctAnswers: [
          "Паровой котел с рабочим избыточным давлением 0,3 МПа, установленный на плавучей драге",
        ],
      },
      {
        code: "68104",
        text: "В каком из приведенных случаев проводится техническое диагностирование, разрушающий и неразрушающий контроль котла в процессе его эксплуатации в пределах назначенного срока службы?",
        answers: [
          "При проведении эксплуатационного контроля металла элементов котла в случаях, установленных руководством по эксплуатации",
          "Каждый раз при проведении экспертизы промышленной безопасности котла",
          "Если прошло более 5 лет со дня ввода в эксплуатацию котла",
          "Если проведения технического диагностирования, разрушающего и неразрушающего контроля котла потребовал инспектор Ростехнадзора в ходе проверки при выявлении нарушений эксплуатации котла",
        ],
        correctAnswers: [
          "При проведении эксплуатационного контроля металла элементов котла в случаях, установленных руководством по эксплуатации",
        ],
      },
      {
        code: "68105",
        text: "При каком условии в котлах, работающих с высокотемпературными органическими и неорганическими теплоносителями, допускается применение теплоносителей, отличных от указанных в паспорте котла?",
        answers: [
          "Применение теплоносителей, отличных от указанных в паспорте котла, не допускается ни при каком условии",
          "При разрешении их применения по результатам технического освидетельствования котла, а также металлоконструкций его каркаса",
          "При свойствах и характеристиках теплоносителей, подходящих под условия применения в котле, определенные паспортом котла и руководством (инструкцией) по эксплуатации",
          "Применение теплоносителей, не указанных в паспорте котла, допускается только по согласованию с организацией - изготовителем котла",
        ],
        correctAnswers: [
          "Применение теплоносителей, не указанных в паспорте котла, допускается только по согласованию с организацией - изготовителем котла",
        ],
      },
      {
        code: "68106",
        text: "При каких условиях допускается применение сальниковой арматуры на котлах, работающих с высокотемпературными органическими и неорганическими теплоносителями?",
        answers: [
          "При установке на спускной линии теплоносителя на расстоянии не более 1 м от котла не менее 2 запорных органов",
          "При установке арматуры на спускной линии теплоносителя в непосредственной близости от котла на расстоянии не более 1,5 м",
          "Допускается применение сальниковой арматуры на давление не более 1,6 МПа",
          "Допускается применение сальниковой арматуры в качестве дублирующей для арматуры сильфонного типа на давление не более 4 МПа",
        ],
        correctAnswers: [
          "Допускается применение сальниковой арматуры на давление не более 1,6 МПа",
        ],
      },
      {
        code: "68107",
        text: "Какое из требований к оборудованию указателем уровня жидкости котла, работающего с высокотемпературными органическими и неорганическими теплоносителями, указано неверно?",
        answers: [
          "Элементы указателя уровня, соприкасающиеся с теплоносителем, должны быть выполнены из негорючих материалов, устойчивых к воздействию на них теплоносителя при рабочих параметрах температуры и давления",
          "В указателях уровня жидкости прямого действия внутренний диаметр арматуры, служащей для отключения указателя уровня от котла, должен быть не менее 8 мм",
          "Установка пробных кранов или клапанов взамен указателей уровня жидкости допускается только в паровом котле",
          "Проходное сечение запорной арматуры должно быть не менее проходного сечения отверстий в корпусе указателя уровня",
        ],
        correctAnswers: [
          "Установка пробных кранов или клапанов взамен указателей уровня жидкости допускается только в паровом котле",
        ],
      },
      {
        code: "68108",
        text: "Какое из требований к оснащению предохранительными клапанами котла, работающего с высокотемпературными органическими и неорганическими теплоносителями, указано неверно?",
        answers: [
          "Допускается установка предохранительных устройств на расширительном сосуде, не отключаемом от котла",
          "Суммарная пропускная способность предохранительных клапанов, устанавливаемых на расширительном сосуде, должна быть не менее массового потока инертного газа, поступающего в сосуд в аварийном случае",
          "Допускается установка между котлом (сосудом) и предохранительными клапанами трехходового вентиля или другого устройства, исключающего возможность одновременного отключения всех предохранительных клапанов",
          "На жидкостных котлах допускается установка рычажно-грузовых предохранительных клапанов",
        ],
        correctAnswers: [
          "На жидкостных котлах допускается установка рычажно-грузовых предохранительных клапанов",
        ],
      },
      {
        code: "68109",
        text: "Исходя из какого условия выбирается количество питательных насосов при групповой схеме питания паровых котлов, работающих с высокотемпературными органическими и неорганическими теплоносителями?",
        answers: [
          "Чтобы в случае остановки самого мощного насоса суммарная подача оставшихся насосов была не менее 110 % номинальной паропроизводительности всех рабочих котлов",
          "Чтобы суммарная подача питательных насосов обеспечивалась не менее чем двумя насосами, один из которых должен быть резервным",
          "Чтобы суммарная подача питательных насосов обеспечивалась не менее чем четырьмя насосами, два из которых должны быть резервными",
          "Чтобы в случае остановки самого мощного насоса суммарная подача оставшихся насосов была не менее 130 % номинальной паропроизводительности всех рабочих котлов",
        ],
        correctAnswers: [
          "Чтобы в случае остановки самого мощного насоса суммарная подача оставшихся насосов была не менее 110 % номинальной паропроизводительности всех рабочих котлов",
        ],
      },
      {
        code: "68110",
        text: "Исходя из какого условия должна проводиться регенерация теплоносителя в котлах, работающих с высокотемпературными органическими и неорганическими теплоносителями?",
        answers: [
          "Содержание продуктов разложения в теплоносителе не должно превышать 45 %",
          "Содержание продуктов разложения в теплоносителе не должно превышать 30 %",
          "Содержание продуктов разложения в теплоносителе не должно превышать 10 %",
          "Содержание продуктов разложения в теплоносителе не должно превышать 15 %",
        ],
        correctAnswers: [
          "Содержание продуктов разложения в теплоносителе не должно превышать 10 %",
        ],
      },
      {
        code: "68111",
        text: "В какие сроки должны проводиться технический осмотр и очистка поверхностей нагрева в котлах, работающих с высокотемпературными органическими и неорганическими теплоносителями?",
        answers: [
          "Не реже 1 раза в 4 года",
          "Не реже чем через 8000 часов работы котла",
          "Не реже 1 раза в 10 лет",
          "Не реже чем через 10 000 часов работы котла",
        ],
        correctAnswers: ["Не реже чем через 8000 часов работы котла"],
      },
      {
        code: "68112",
        text: "В каком из приведенных случаев содорегенерационный котел должен быть переведен на сжигание вспомогательного топлива?",
        answers: [
          "В случае выхода из строя одного из перекачивающих насосов зеленого щелока или одной третьей части леток плава",
          "В случае выхода из строя 25 % и более леток плава",
          "В случае выхода из строя всех перекачивающих насосов, или одного из дымососов, или одного из вентиляторов",
        ],
        correctAnswers: [
          "В случае выхода из строя всех перекачивающих насосов, или одного из дымососов, или одного из вентиляторов",
        ],
      },
      {
        code: "68113",
        text: "В каком из приведенных случаев содорегенерационный котел должен быть немедленно остановлен и отключен действиями защит или персоналом?",
        answers: [
          "При возникновения опасности выхода из строя леток плава",
          "При прекращении поступления воды в топку",
          "При выходе из строя одного из дымососов или вентиляторов",
          "При течи плава помимо леток или через неплотности топки и невозможности ее устранения",
        ],
        correctAnswers: [
          "При течи плава помимо леток или через неплотности топки и невозможности ее устранения",
        ],
      },
      {
        code: "68114",
        text: "Какое из приведенных требований к электрокотлу указано неверно?",
        answers: [
          "На котлах электрической мощностью более 6 МВт обязательна установка регистрирующего манометра или иных типов средств измерений, регистрирующих давления воды в местах, определенных проектом",
          "В качестве предохранительных устройств при эксплуатации электрических котлов категорически не допускается применение предохранительных устройств с разрушающимися мембранами",
          "Электрокотельные с электрическими котлами должны быть оснащены средствами определения удельного электросопротивления питательной (сетевой) воды",
          "В котельных с водогрейными электрическими котлами суммарной электрической мощностью более 1 МВт должны быть установлены регистрирующие средства измерений температуры воды в местах, определенных проектом",
        ],
        correctAnswers: [
          "В качестве предохранительных устройств при эксплуатации электрических котлов категорически не допускается применение предохранительных устройств с разрушающимися мембранами",
        ],
      },
      {
        code: "68115",
        text: "В соответствии с нормами проведения электрических испытаний электрооборудования электрических котлов когда должно проводиться измерение сопротивления столба воды изолирующей вставки?",
        answers: [
          "После монтажа, профилактического испытания, текущего ремонта, капитального ремонта",
          "Только после капитального ремонта",
          "При проведении технического диагностирования",
          "При проведении экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "После монтажа, профилактического испытания, текущего ремонта, капитального ремонта",
        ],
      },
      {
        code: "68116",
        text: " В соответствии с нормами проведения электрических испытаний электрооборудования электрических котлов когда должно проводиться измерение удельного электрического сопротивления питательной (сетевой) воды?",
        answers: [
          "После монтажа, профилактического испытания, текущего ремонта, капитального ремонта",
          "Только после профилактического испытания и капитального ремонта",
          "Только после монтажа и текущего ремонта",
          "Только после профилактического испытания",
        ],
        correctAnswers: [
          "Только после профилактического испытания и капитального ремонта",
        ],
      },
      {
        code: "68117",
        text: "В соответствии с нормами проведения электрических испытаний электрооборудования электрических котлов когда должны проводиться испытания повышенным напряжением промышленной частоты изолирующих вставок?",
        answers: [
          "Только после текущего и капитального ремонта",
          "Только после профилактического испытания и капитального ремонта",
          "Только после профилактического испытания и текущего ремонта",
          "Только после монтажа и текущего ремонта",
        ],
        correctAnswers: [
          "Только после профилактического испытания и капитального ремонта",
        ],
      },
      {
        code: "68118",
        text: "В соответствии с нормами проведения электрических испытаний электрооборудования электрических котлов когда должно проводиться измерение сопротивления изоляции котла без воды?",
        answers: [
          "Только после монтажа и профилактического испытания",
          "Только после профилактического испытания и капитального ремонта",
          "Только после профилактического испытания и текущего ремонта",
          "Только после монтажа и текущего ремонта",
        ],
        correctAnswers: [
          "Только после профилактического испытания и капитального ремонта",
        ],
      },
      {
        code: "68119",
        text: "В соответствии с нормами проведения электрических испытаний электрооборудования электрических котлов когда должна проводиться проверка действия защитной аппаратуры котла?",
        answers: [
          "После монтажа, профилактического испытания, текущего ремонта, капитального ремонта",
          "Только после текущего и капитального ремонта",
          "Только после профилактического испытания",
          "Только после монтажа и текущего ремонта",
        ],
        correctAnswers: [
          "После монтажа, профилактического испытания, текущего ремонта, капитального ремонта",
        ],
      },
      {
        code: "68120",
        text: "В каком случае проводится внеочередное определение удельного сопротивления воды при эксплуатации электрокотлов?",
        answers: [
          "При резком изменении мощности котлов на 20 % и более от нормальной",
          "При проведении внеочередной чистки котла",
          "При проведении внеочередного технического освидетельствования котла",
        ],
        correctAnswers: [
          "При резком изменении мощности котлов на 20 % и более от нормальной",
        ],
      },
      {
        code: "68121",
        text: "Каким образом при эксплуатации паровых электрокотлов поддерживается необходимое значение величины удельного электрического сопротивления котловой воды?",
        answers: [
          "Путем непрерывной продувки и введения в котловую воду солей, повышающих ее удельное электрическое сопротивление",
          "Путем введения в котловую воду реагентов, снижающих ее удельное электрическое сопротивление",
          "Путем автоматизированной непрерывной продувки, а также периодической продувки",
        ],
        correctAnswers: [
          "Путем автоматизированной непрерывной продувки, а также периодической продувки",
        ],
      },
      {
        code: "68122",
        text: "В каком из приведенных случаев при эксплуатации электрокотлов не допускается снижение удельного электрического сопротивления воды путем введения легкорастворимых солей в питательную и котловую воду?",
        answers: [
          "Для водогрейных котлов напряжением до 1 кВ, работающих по замкнутой схеме теплоснабжения (без водозабора)",
          "Для паровых котлов при их эксплуатации",
          "Для паровых котлов при их запуске для форсирования набора и поддержания мощности",
        ],
        correctAnswers: ["Для паровых котлов при их эксплуатации"],
      },
      {
        code: "68123",
        text: "Какой из приведенных котлов допускается устанавливать внутри производственных помещений?",
        answers: [
          "Паровой котел-утилизатор паропроизводительностью 10 т/ч",
          "Прямоточный котел паропроизводительностью 7 т/ч",
          "Водогрейный котел теплопроизводительностью 15 ГДж/ч",
          "Водогрейный электрокотел электрической мощностью 5 МВт",
        ],
        correctAnswers: [
          "Паровой котел-утилизатор паропроизводительностью 10 т/ч",
        ],
      },
      {
        code: "68124",
        text: "Какое из приведенных требований к размещению котлов внутри производственных помещений указано неверно?",
        answers: [
          "Места расположения выходов и направление открывания дверей должна определять проектная организация",
          "Двери для выхода из помещения, в котором установлены котлы, должны открываться вовнутрь, а двери служебных, бытовых, а также вспомогательных производственных помещений должны открываться в сторону этих помещений",
          "Допускается отделение котлов-утилизаторов от остальной части производственного помещения вместе с печами или агрегатами, с которыми они связаны технологическим процессом",
          "Место установки котлов и обеспечивающих их работу систем, устройств и вспомогательного оборудования внутри производственных помещений должно быть отделено от остальной части помещения несгораемыми перегородками по всей высоте котла, но не ниже 2 м с устройством дверей",
        ],
        correctAnswers: [
          "Двери для выхода из помещения, в котором установлены котлы, должны открываться вовнутрь, а двери служебных, бытовых, а также вспомогательных производственных помещений должны открываться в сторону этих помещений",
        ],
      },
      {
        code: "68125",
        text: "В каком случае допускается обустройство площадки для установки котла ниже планировочной отметки территории, прилегающей к зданию, в котором установлены котлы?",
        answers: [
          "В случае технологической необходимости такого обустройства по решению разработчика проектной документации",
          "В случае внесения изменений в проектную документацию по решению заказчика, согласованному с Ростехнадзором и уполномоченной специализированной организацией",
          "Не допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "68126",
        text: "Какие из приведенных мест не подлежат оборудованию аварийным освещением?",
        answers: [
          "Зольные помещения",
          "Оборудование водоподготовки",
          "Дымососные площадки",
          "Все приведенные места подлежат оборудованию аварийным освещением",
        ],
        correctAnswers: [
          "Все приведенные места подлежат оборудованию аварийным освещением",
        ],
      },
      {
        code: "68127",
        text: "Каково минимально допустимое значение расстояния от выступающих частей горелочных устройств котла, работающего на жидком топливе, до стены котельного помещения?",
        answers: ["1 м", "2,5 м", "2 м", "3 м"],
        correctAnswers: ["1 м"],
      },
      {
        code: "68128",
        text: "Каково минимально допустимое значение расстояния от фронта котла, работающего на газообразном топливе, до противоположной стены котельного помещения?",
        answers: ["1 м", "2 м", "3 м", "5 м"],
        correctAnswers: ["3 м"],
      },
      {
        code: "68129",
        text: "Каково минимально допустимое значение расстояния от противоположной стены котельной до фронта электрокотла мощностью 0,75 МВт?",
        answers: ["1 м", "2 м", "3 м", "4 м"],
        correctAnswers: ["1 м"],
      },
      {
        code: "68130",
        text: "Каково минимально допустимое значение расстояния от фронта котла паропроизводительностью 10 т/ч до противоположной стены котельного помещения?",
        answers: [
          "1 м, если топка с ручной загрузкой твердого топлива обслуживается с фронта и имеет длину не более 1 м",
          "1,5 м, если отсутствует необходимость обслуживания топки с фронта",
          "2 м",
          "3 м",
        ],
        correctAnswers: ["3 м"],
      },
      {
        code: "68131",
        text: "Каково минимальное значение расстояния между фронтом электрокотлов электрической мощностью 5 МВт, расположенных друг против друга?",
        answers: ["1,5 м", "2,5 м", "3 м", "4 м"],
        correctAnswers: ["3 м"],
      },
      {
        code: "68132",
        text: "Каково минимальное значение ширины бокового прохода при установке парового котла паропроизводительностью 8 т/ч, для которого требуется боковое обслуживание?",
        answers: ["1 м", "1,5 м", "2 м", "2,5 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "68133",
        text: "Что должно предусматриваться проектом котельного помещения, если расстояние от нулевой отметки котельного помещения до верхней площадки котлов превышает 20 м?",
        answers: [
          "Металлические стационарные лестницы и площадки в соответствии с проектной документацией",
          "Грузопассажирский лифт грузоподъемностью, соответствующей весу перемещаемых грузов и людей, но не менее 500 кг",
          "Подъемные устройства для подъема людей и грузов грузоподъемностью, соответствующей весу перемещаемых грузов и людей (в случае совместного подъема), но не менее 1000 кг",
        ],
        correctAnswers: [
          "Подъемные устройства для подъема людей и грузов грузоподъемностью, соответствующей весу перемещаемых грузов и людей (в случае совместного подъема), но не менее 1000 кг",
        ],
      },
      {
        code: "68134",
        text: "В каком из приведенных случаев допускается использование при монтаже, ремонте, реконструкции (модернизации) стальных труб и иных материалов, ранее бывших в употреблении?",
        answers: [
          "В случае применения таких труб в составе обводных (байпасных) и продувочных линий, временно обустраиваемых на ограниченный период времени, определенный проектом монтажа, ремонта, реконструкции (модернизации) системы трубопроводов",
          "В случае технологической необходимости с предварительным проведением визуального и радиографического контроля, а также механических испытаний, установивших допустимое для повторного использования качество стальных труб и других материалов, ранее бывших в употреблении",
          "В случае применения таких труб и иных материалов при монтаже, ремонте и реконструкции (модернизации) паровых котлов паропроизводительностью менее 0,7 т/ч",
          "В случае применения труб и иных материалов, бывших в употреблении, чей срок эксплуатации не превышает 25 % от нормативного, с применением необходимых методов контроля для подтверждения соответствия качества",
        ],
        correctAnswers: [
          "В случае применения таких труб в составе обводных (байпасных) и продувочных линий, временно обустраиваемых на ограниченный период времени, определенный проектом монтажа, ремонта, реконструкции (модернизации) системы трубопроводов",
        ],
      },
      {
        code: "68135",
        text: "В каком случае и кем допускаются отклонения от проектной документации при строительстве, реконструкции, капитальном ремонте и эксплуатации ОПО, на которых используется (применяется) оборудование под давлением?",
        answers: [
          "В случае технически обоснованной необходимости, допускаются Ростехнадзором и уполномоченной специализированной организацией",
          "В случае расширения производства, модернизации (реконструкции), допускаются организацией - разработчиком оборудования - на основании экспертных расчетов",
          "Отклонения от проектной документации не допускаются",
        ],
        correctAnswers: ["Отклонения от проектной документации не допускаются"],
      },
      {
        code: "68136",
        text: "Отсутствие какой документации не является препятствием для осуществления монтажа, ремонта, реконструкции (модернизации) оборудования, работающего под давлением, специализированной организацией?",
        answers: [
          "Типовых программ (методик) пусконаладки, испытаний и комплексного опробования монтируемого (ремонтируемого, реконструируемого) оборудования под давлением, проводимых по окончании монтажа, ремонта, реконструкции",
          "Технологической документации на производство заявленных видов работ (технологических инструкций, процессов, карт, проектов производства монтажно-демонтажных работ), подготовленной до начала этих работ",
          "Проектной (конструкторской) и технической документации на оборудование под давлением, монтаж (демонтаж), наладка, ремонт, реконструкция (модернизация) которого осуществляется",
          "Эксплуатационной документации монтируемого, ремонтируемого, реконструируемого (модернизируемого) оборудования",
        ],
        correctAnswers: [
          "Эксплуатационной документации монтируемого, ремонтируемого, реконструируемого (модернизируемого) оборудования",
        ],
      },
      {
        code: "68137",
        text: "Каким образом допускается маркировать сварное соединение, выполненное несколькими сварщиками (бригадой сварщиков)?",
        answers: [
          "Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
          "Допускается применение клейма руководителя бригады сварщиков",
          "На сварных соединениях должны быть поставлены клейма каждого сварщика, выполняющего сварку",
          "Допускается применение клейма любого из сварщиков, выполняющих сварку",
        ],
        correctAnswers: [
          "Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
        ],
      },
      {
        code: "68138",
        text: "Какие функции обязано выполнить лицо, осуществляющее руководство сварочными работами, назначенное распорядительным документом организации или (и) должностной инструкцией которого предусмотрено руководство сварочными работами, перед выполнением сварочных работ?",
        answers: [
          "Проверить и обеспечить соответствие численного состава и квалификации персонала сварочного производства, сборочного и сварочного оборудования, основных и сварочных материалов, применяемой технологии сварки требованиям производственно-технологической документации",
          "Ознакомить сварщиков с требованиями технологических карт сварки, а также с внесенными в них изменениями (при наличии) с подтверждением ознакомления в применяемых ими технологических картах сварки",
          "Организовать проведение операционного контроля",
          "Все перечисленные функции",
        ],
        correctAnswers: ["Все перечисленные функции"],
      },
      {
        code: "68139",
        text: 'Какие требования предъявляются ФНП "Требования к производству сварочных работ на опасных производственных объектах" к сварочному оборудованию и сварочным материалам, применяемым при выполнении сварочных работ?',
        answers: [
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обеспечивать максимальную расчетную производительность работ, установленную НД и (или) проектной (конструкторской) документацией",
          "Оборудование и материалы должны соответствовать применяемым технологиям сварки, обладать сварочно-технологическими характеристиками, экономичностью и ремонтопригодностью в пределах допустимых значений, установленных уполномоченными специализированными организациями",
        ],
        correctAnswers: [
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "68140",
        text: "Какие действия должны быть осуществлены при выполнении многопроходных швов после наложения каждого валика поверхности шва и кромки разделки?",
        answers: [
          "Должны быть зачищены от шлака, брызг металла, обезжирены и проклеймены клеймами каждого сварщика, принимавшего участие в процессе сварки",
          "Должны быть обезжирены водными смывками и проверены ультразвуковым и радиографическим контролем на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)",
          "Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)",
        ],
        correctAnswers: [
          "Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)",
        ],
      },
      {
        code: "68141",
        text: "Какая документация оформляется в процессе выполнения сварочных работ?",
        answers: [
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
          "Только журналы сварочных работ, акты и заключения по неразрушающему контролю",
          "Только паспорта и протоколы испытаний сварных соединений",
        ],
        correctAnswers: [
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "68142",
        text: "Кто допускается к выполнению сварочных работ на опасном производственном объекте?",
        answers: [
          "Сварщики и специалисты сварочного производства, обладающие соответствующей квалификацией, имеющие группу по электробезопасности не ниже III, прошедшие обучение по оказанию первой помощи пострадавшим",
          "Сварщики и специалисты сварочного производства не моложе 18 лет, обладающие квалификацией, соответствующей применяемым технологиям сварки, прошедшие обучение по пожарной безопасности и оказанию первой помощи пострадавшим на производстве, аттестованные в соответствующей области промышленной безопасности",
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
        ],
        correctAnswers: [
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
        ],
      },
      {
        code: "68143",
        text: "К выполнению каких работ могут быть допущены сварщики и специалисты сварочного производства?",
        answers: [
          "К выполнению любых сварочных работ, по которым они когда-либо проходили обучение, независимо от срока действия соответствующих аттестационных удостоверений",
          "К выполнению сварочных работ, указанных в действующих документах",
          "К выполнению сварочных работ, указанных в наряде-допуске, при условии прохождения необходимых инструктажей, проверок знаний, стажировки и при наличии допуска к самостоятельной работе, оформленного в установленном порядке",
        ],
        correctAnswers: [
          "К выполнению сварочных работ, указанных в действующих документах",
        ],
      },
      {
        code: "68144",
        text: "Что должно быть указано в технологических картах сварки?",
        answers: [
          "Последовательность и особенности операций технологического цикла сварочных работ, требования к количеству и качеству сварочных материалов и оборудования, к количеству работников, необходимых для выполнения операций, виды контроля по окончании работ",
          "Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений",
          "Режимы сварки и необходимое количество сварочных материалов на каждом технологическом этапе сварочных работ",
        ],
        correctAnswers: [
          "Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений",
        ],
      },
      {
        code: "68145",
        text: "Какую проверку должен пройти сварщик, впервые приступающий к сварке, перед допуском к работе?",
        answers: [
          "Проверку знания методов и объемов неразрушающего контроля качества сварных соединений и механических испытаний",
          "Проверку путем практического устранения дефектов сварных соединений",
          "Проверку путем выполнения и контроля допускного сварного соединения",
        ],
        correctAnswers: [
          "Проверку путем выполнения и контроля допускного сварного соединения",
        ],
      },
      {
        code: "68146",
        text: "Каким образом оформляются результаты проводимого контроля качества сварных соединений?",
        answers: [
          "Результаты по каждому виду проводимого контроля и места контроля должны фиксироваться в отчетной документации (журналы, формуляры, протоколы, маршрутные паспорта)",
          "Результаты по всем видам проводимого контроля должны фиксироваться в паспорте оборудования и ремонтном журнале",
          "На каждый вид проводимого контроля должна выписываться отдельная ведомость для внесения результатов, которую затем необходимо подшивать в хронологическом порядке и хранить в течение не менее 5 лет",
          "Распорядительными документами эксплуатирующей организации должны устанавливаться отдельные формы для каждого вида проводимого контроля (специальные бланки, наряды-заказы, ведомости, формуляры), а также порядок их заполнения и сроки хранения",
        ],
        correctAnswers: [
          "Результаты по каждому виду проводимого контроля и места контроля должны фиксироваться в отчетной документации (журналы, формуляры, протоколы, маршрутные паспорта)",
        ],
      },
      {
        code: "68147",
        text: "Какое из приведенных требований должно выполняться при проведении визуального и измерительного контроля?",
        answers: [
          "Визуальному и измерительному контролю необходимо подвергать не менее 70 % сварных соединений",
          "Перед визуальным и измерительным контролем поверхности сварного шва и прилегающих к нему участков основного металла шириной 10 - 15 мм в обе стороны от шва должны быть зачищены от шлака и других загрязнений",
          "Поверхностные дефекты, выявленные при визуальном и измерительном контроле, должны быть исправлены до проведения контроля другими неразрушающими методами",
        ],
        correctAnswers: [
          "Поверхностные дефекты, выявленные при визуальном и измерительном контроле, должны быть исправлены до проведения контроля другими неразрушающими методами",
        ],
      },
      {
        code: "68148",
        text: "Какое из приведенных требований должно выполняться в случае обнаружения дефектов при ультразвуковом и радиографическом контроле сварных соединений?",
        answers: [
          "Стыковые сварные соединения, которые были подвергнуты устранению дефекта сварного шва, должны быть проверены ультразвуковой дефектоскопией или радиографическим контролем по всей длине сварных соединений",
          "Не менее половины ремонтных заварок выборок металла, включая зону термического влияния сварки в основном металле, должны быть проверены ультразвуковым или радиографическим контролем",
          "При заварке по всей толщине стенки ультразвуковой и радиографический контроль поверхности должен быть проведен с обеих сторон, за исключением случаев недоступности внутренней или наружной стороны для контроля",
        ],
        correctAnswers: [
          "Стыковые сварные соединения, которые были подвергнуты устранению дефекта сварного шва, должны быть проверены ультразвуковой дефектоскопией или радиографическим контролем по всей длине сварных соединений",
        ],
      },
      {
        code: "68149",
        text: "В каком из приведенных случаев должны проводиться механические испытания?",
        answers: [
          "При аттестации технологии сварки",
          "При получении неудовлетворительных результатов ультразвукового и радиографического контроля",
          "При входном контроле сварочных материалов, используемых при газовой сварке деталей из аустенитных сталей",
        ],
        correctAnswers: ["При аттестации технологии сварки"],
      },
      {
        code: "68150",
        text: "В какой документации указывается необходимость проведения и объем ультразвукового и радиографического контроля, типы и размеры несплошностей (дефектов), подлежащих обнаружению, для конкретного вида (типа) конструкции оборудования под давлением и сварного соединения?",
        answers: [
          "В проектной (конструкторской) и технологической документации",
          "В производственной инструкции и руководстве (инструкции) по эксплуатации",
          "В распорядительной документации эксплуатирующей организации",
        ],
        correctAnswers: [
          "В проектной (конструкторской) и технологической документации",
        ],
      },
      {
        code: "68151",
        text: "В каких случаях допускается замена ультразвукового и радиографического контроля другими методами неразрушающего контроля?",
        answers: [
          "Если применение других методов неразрушающего контроля согласовано с уполномоченной специализированной организацией",
          "Если применение других методов неразрушающего контроля согласовано с разработчиком проектной документации",
          "Не допускается ни в каких случаях",
        ],
        correctAnswers: [
          "Если применение других методов неразрушающего контроля согласовано с разработчиком проектной документации",
        ],
      },
      {
        code: "68152",
        text: "В течение какого времени проводится комплексное опробование котлов, сосудов и трубопроводов пара и горячей воды?",
        answers: [
          "Котлы - в течение 96 часов, трубопроводы тепловых сетей - в течение 48 часов, остальное оборудование - по программе комплексного опробования, разработанной эксплуатирующей организацией и согласованной с организацией, проводящей соответствующие работы",
          "Котлы - в течение 72 часов, трубопроводы тепловых сетей - в течение 24 часов, остальное оборудование - по программе комплексного опробования, разработанной организацией, проводящей соответствующие работы, и согласованной с эксплуатирующей организацией",
          "В течение 72 часов по программе, разработанной эксплуатирующей организацией и согласованной с Ростехнадзором",
        ],
        correctAnswers: [
          "Котлы - в течение 72 часов, трубопроводы тепловых сетей - в течение 24 часов, остальное оборудование - по программе комплексного опробования, разработанной организацией, проводящей соответствующие работы, и согласованной с эксплуатирующей организацией",
        ],
      },
      {
        code: "68153",
        text: "Какое из приведенных требований к программе проведения наладочных работ на оборудовании под давлением указано неверно?",
        answers: [
          "Программа проведения наладочных работ подлежит согласованию с эксплуатирующей организацией в случае, если наладка осуществляется на паровых котлах и сосудах, работающих со средой, отнесенной к группе 1 в соответствии с ТР ТС 032/2013",
          "В программе проведения наладочных работ должны быть отражены содержание и порядок выполнения всех технологических и контрольных операций с обеспечением наладки на всех режимах работы, установленных проектом",
          "Наладка оборудования под давлением должна быть выполнена по программе, разработанной организацией, выполняющей соответствующие работы, и согласована с эксплуатирующей организацией до начала производства работ",
        ],
        correctAnswers: [
          "Программа проведения наладочных работ подлежит согласованию с эксплуатирующей организацией в случае, если наладка осуществляется на паровых котлах и сосудах, работающих со средой, отнесенной к группе 1 в соответствии с ТР ТС 032/2013",
        ],
      },
      {
        code: "68154",
        text: "Каков минимальный срок проведения пусконаладочных работ на оборудовании, работающем под давлением?",
        answers: [
          "Продолжительность проведения пусконаладочных работ определяется разработчиком оборудования, работающего под давлением",
          "Продолжительность проведения пусконаладочных работ определяется программой в зависимости от сложности оборудования",
          "45 календарных дней",
        ],
        correctAnswers: [
          "Продолжительность проведения пусконаладочных работ определяется программой в зависимости от сложности оборудования",
        ],
      },
      {
        code: "68155",
        text: "Какое из приведенных испытаний не является обязательным видом механических испытаний котлов (трубопроводов)?",
        answers: [
          "Испытание на сплющивание",
          "Испытание на ударный изгиб",
          "Испытание на статическое растяжение",
        ],
        correctAnswers: ["Испытание на ударный изгиб"],
      },
      {
        code: "68156",
        text: "Каким документом определяется ответственность за безопасность обслуживания оборудования под давлением в период проведения наладочных работ?",
        answers: [
          "Уголовным кодексом Российской Федерации",
          "Договором на пусконаладочные работы",
          "Программой наладочных работ",
        ],
        correctAnswers: ["Программой наладочных работ"],
      },
      {
        code: "68157",
        text: "При выполнении каких условий допускается заменять гидравлическое испытание сосуда пневматическим испытанием?",
        answers: [
          "Если температура испытания не более чем на 20 °С выше температуры хрупкого излома материалов испытуемого оборудования",
          "Если одновременно с пневматическим испытанием проводится визуальный и измерительный контроль",
          "Если пробное давление превышает рабочее давление сосуда не более чем на 25 %",
          "Если пневматическое испытание одновременно контролируется методом акустической эмиссии",
        ],
        correctAnswers: [
          "Если пневматическое испытание одновременно контролируется методом акустической эмиссии",
        ],
      },
      {
        code: "68158",
        text: "По какой из приведенных формул определяется значение пробного давления () при гидравлическом испытании (периодическое техническое освидетельствование) металлических сосудов (за исключением литых)? Где в формулах: Рраб - рабочее давление сосуда, Р расч - расчетное давление сосуда, ,  - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа.",
        answers: ["= 1,25( / )", "= 1,15( / )", "= 1,5( / )", "= 1,25( / )"],
        correctAnswers: ["= 1,25( / )"],
      },
      {
        code: "68159",
        text: "Какое из приведенных требований должно выполняться при проведении гидравлического испытания сосудов?",
        answers: [
          "В комбинированных сосудах с 2 и более рабочими полостями, рассчитанными на разные давления, гидравлическому испытанию пробным давлением, определяемым в зависимости от расчетного давления полости, должна быть подвергнута как минимум 1 полость",
          "Гидравлическое испытание сосудов, устанавливаемых вертикально, разрешается проводить в горизонтальном положении, при этом должен быть выполнен расчет на прочность корпуса сосуда с учетом принятого способа опирания для проведения гидравлического испытания",
          "Порядок проведения испытания сосудов должен устанавливаться специализированной экспертной организацией и утверждаться эксплуатирующей организацией",
          "Гидравлическое испытание сосудов, устанавливаемых вертикально, допускается проводить только в вертикальном положении",
        ],
        correctAnswers: [
          "Гидравлическое испытание сосудов, устанавливаемых вертикально, разрешается проводить в горизонтальном положении, при этом должен быть выполнен расчет на прочность корпуса сосуда с учетом принятого способа опирания для проведения гидравлического испытания",
        ],
      },
      {
        code: "68160",
        text: "В каком из приведенных случаев при проведении гидравлического испытания при эксплуатации сосудов допускается использовать не воду, а другую жидкость?",
        answers: [
          "В технически обоснованных случаях, предусмотренных организацией-изготовителем",
          "В случаях гидравлических испытаний паровых и водогрейных котлов, температура металла барабанов которых не превышает 160 °С",
          "В случаях проведения гидравлических испытаний при отрицательной температуре окружающего воздуха",
          "В случаях проведения гидравлических испытаний с жидкостью, которая не вызывает коррозии стенок сосудов и вспомогательного оборудования",
        ],
        correctAnswers: [
          "В технически обоснованных случаях, предусмотренных организацией-изготовителем",
        ],
      },
      {
        code: "68161",
        text: "Какое из приведенных требований должно выполняться при проведении гидравлического испытания сосуда?",
        answers: [
          "При заполнении сосуда водой воздух из него должен быть удален не менее чем на 80 %",
          "Давление воды при гидравлическом испытании следует контролировать не менее чем 2 манометрами одинаковых классов точности (не менее 0,6)",
          "Время выдержки под пробным давлением сосуда, находящегося в эксплуатации, должно определяться руководством по эксплуатации",
          "Время выдержки под пробным давлением сосудов поэлементной блочной поставки, доизготовленных при монтаже на месте эксплуатации, должно быть не менее 30 минут при толщине стенки сосуда более 50 мм",
        ],
        correctAnswers: [
          "Время выдержки под пробным давлением сосуда, находящегося в эксплуатации, должно определяться руководством по эксплуатации",
        ],
      },
      {
        code: "68162",
        text: "В каком из приведенных случаев сосуд считается выдержавшим гидравлическое испытание?",
        answers: [
          "В случае обнаружения течи в разъемных соединениях",
          "В случае обнаружения видимых остаточных деформаций",
          "В случае обнаружения падения давления по манометру",
          "Во всех приведенных случаях сосуд считается не выдержавшим гидравлическое испытание",
        ],
        correctAnswers: [
          "Во всех приведенных случаях сосуд считается не выдержавшим гидравлическое испытание",
        ],
      },
      {
        code: "68163",
        text: "Чему равно минимальное значение времени выдержки сосуда под пробным давлением при пневматическом испытании?",
        answers: ["5 минут", "15 минут", "20 минут", "30 минут"],
        correctAnswers: ["15 минут"],
      },
    ],
    700: [
      {
        code: "68163",
        text: "На какие процессы не распространяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?",
        answers: [
          "На капитальный ремонт опасного производственного объекта, на котором используются трубопроводы пара и горячей воды",
          "На консервацию паропровода",
          "На изготовление труб, тройников, отводов",
        ],
        correctAnswers: ["На изготовление труб, тройников, отводов"],
      },
      {
        code: "68164",
        text: "При осуществлении каких процессов на ОПО не применяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?",
        answers: [
          "При реконструкции трубопроводов пара и горячей воды",
          "При техническом перевооружении паропроводов",
          "При пусконаладочных работах на водогрейных и пароводогрейных котлах",
          "При проектировании пароперегревателей трубчатых печей",
        ],
        correctAnswers: [
          "При проектировании пароперегревателей трубчатых печей",
        ],
      },
      {
        code: "68165",
        text: "Кто и на основании чего принимает решение о вводе в эксплуатацию трубопроводов пара и горячей воды?",
        answers: [
          "Председатель комиссии, назначаемой распорядительным документом эксплуатирующей организации, на основании проверки готовности трубопровода к пуску в работу и проверки организации надзора за эксплуатацией трубопровода",
          "Специалист, ответственный за исправное состояние и безопасную эксплуатацию трубопровода, на основании проверки документации и результатов произведенного им технического освидетельствования трубопровода",
          "Работник, ответственный за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением, на основании проверки организации надзора за эксплуатацией трубопровода",
          "Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения) на основании проверки готовности трубопровода к пуску в работу и проверки организации надзора за эксплуатацией трубопровода",
        ],
        correctAnswers: [
          "Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения) на основании проверки готовности трубопровода к пуску в работу и проверки организации надзора за эксплуатацией трубопровода",
        ],
      },
      {
        code: "68166",
        text: "В каком из приведенных случаев проверку готовности трубопровода к пуску в работу и проверку организации надзора за эксплуатацией трубопровода осуществляют ответственные лица или комиссия с их участием?",
        answers: [
          "После монтажа без применения неразъемных соединений трубопровода, демонтированного и установленного на новом месте",
          "После монтажа оборудования, поставляемого отдельными деталями, элементами или блоками, окончательную сборку (доизготовление) которого с применением неразъемных соединений производят при монтаже на месте его установки (использования)",
          "При передаче ОПО и (или) трубопровода для использования другой эксплуатирующей организации",
          "После монтажа с применением неразъемных соединений трубопровода, демонтированного и установленного на новом месте",
        ],
        correctAnswers: [
          "После монтажа без применения неразъемных соединений трубопровода, демонтированного и установленного на новом месте",
        ],
      },
      {
        code: "68167",
        text: "В каком из приведенных случаев проверка готовности трубопровода к пуску в работу и проверка организации надзора за эксплуатацией котла осуществляется только комиссией, назначаемой приказом эксплуатирующей организации?",
        answers: [
          "До начала применения транспортабельного трубопровода",
          "После монтажа трубопровода, подтверждение соответствия которого предусмотрено ТР ТС 032/2013",
          "После капитального ремонта трубопровода с заменой его участков",
          "После демонтажа трубопровода и установки его на новом месте без применения неразъемных соединений",
        ],
        correctAnswers: [
          "После капитального ремонта трубопровода с заменой его участков",
        ],
      },
      {
        code: "68168",
        text: "Что контролируется при проведении проверки готовности трубопровода к пуску в работу? Укажите все правильные ответы.",
        answers: [
          "Наличие, соответствие проекту и исправность арматуры, контрольно-измерительных приборов, приборов безопасности и технологических защит",
          "Наличие документации, удостоверяющей качество монтажа трубопровода",
          "Наличие должностных инструкций для ответственных лиц и специалистов, осуществляющих эксплуатацию оборудования",
          "Наличие распорядительных документов эксплуатирующей организации и аттестованных в установленном порядке специалистов",
        ],
        correctAnswers: [
          "Наличие, соответствие проекту и исправность арматуры, контрольно-измерительных приборов, приборов безопасности и технологических защит",
          "Наличие документации, удостоверяющей качество монтажа трубопровода",
        ],
      },
      {
        code: "68169",
        text: "Каким образом должны оформляться результаты проверок готовности трубопровода к пуску в работу и организации надзора за его эксплуатацией?",
        answers: [
          "Приказом (распорядительным документом) эксплуатирующей организации",
          "Актом готовности трубопровода к вводу в эксплуатацию",
          "Записью в паспорте трубопровода",
          "Протоколом, который является основанием для ввода трубопровода в эксплуатацию. Протокол прилагается к паспорту трубопровода",
        ],
        correctAnswers: [
          "Актом готовности трубопровода к вводу в эксплуатацию",
        ],
      },
      {
        code: "68170",
        text: "На какой период руководителем эксплуатирующей организации может быть принято решение о возможности эксплуатации трубопровода в режиме опытного применения?",
        answers: [
          "Эксплуатация трубопровода в режиме опытного применения запрещается",
          "Не более 12 месяцев",
          "Не более 6 месяцев",
          "Период эксплуатации трубопровода в режиме опытного применения устанавливается проектной организацией",
        ],
        correctAnswers: ["Не более 6 месяцев"],
      },
      {
        code: "68171",
        text: "Что из приведенного не указывается на табличке или не наносится на трубопроводе перед пуском его в работу?",
        answers: [
          "Учетный номер, присвоенный территориальным органом Ростехнадзора",
          "Разрешенное давление (температура рабочей среды)",
          "Регистрационный номер и даты проведенных осмотров и гидравлического испытания",
          "Даты следующего наружного осмотра (НО) трубопроводов",
        ],
        correctAnswers: [
          "Регистрационный номер и даты проведенных осмотров и гидравлического испытания",
        ],
      },
      {
        code: "68172",
        text: "Какие надписи должны быть нанесены на магистральных линиях трубопроводов пара и горячей воды? Укажите неправильный ответ.",
        answers: [
          "Номер магистрали (римская цифра)",
          "Стрелка, указывающая направление движения рабочей среды",
          "Номера агрегатов, к которым направлена рабочая среда",
        ],
        correctAnswers: [
          "Номера агрегатов, к которым направлена рабочая среда",
        ],
      },
      {
        code: "68173",
        text: "Какое из приведенных требований по нанесению надписей на трубопроводы должно выполняться?",
        answers: [
          "Количество надписей на одном и том же трубопроводе должно быть не более 3",
          "Надписи должны быть видимы с мест управления арматурой и иными устройствами в составе конкретного трубопровода",
          "В местах выхода и входа трубопроводов в другое помещение надписи не обязательны",
          "Все приведенные требования должны выполняться",
        ],
        correctAnswers: [
          "Надписи должны быть видимы с мест управления арматурой и иными устройствами в составе конкретного трубопровода",
        ],
      },
      {
        code: "68174",
        text: "Какой трубопровод из приведенных не подлежит учету в органах Ростехнадзора?",
        answers: [
          "Трубопровод пара и горячей воды эксплуатационной категории I с внутренним диаметром более 70 мм",
          "Трубопровод пара и горячей воды эксплуатационной категорий II с внутренним диаметром 150 мм",
          "Трубопроводы пара и горячей воды, у которых параметры рабочей среды не превышают температуру 250 °С и давление 1,6 МПа, с внутренним диаметром 100 мм и менее",
          "Все приведенные трубопроводы подлежат учету в органах Ростехнадзора",
        ],
        correctAnswers: [
          "Трубопроводы пара и горячей воды, у которых параметры рабочей среды не превышают температуру 250 °С и давление 1,6 МПа, с внутренним диаметром 100 мм и менее",
        ],
      },
      {
        code: "68175",
        text: "Какая документация не представляется эксплуатирующей организацией в орган Ростехнадзора для постановки на учет трубопровода, проверка готовности к вводу в эксплуатацию которого проводилась без участия уполномоченного представителя Ростехнадзора?",
        answers: [
          "Наименование или обозначение трубопровода, год изготовления",
          "Основные технические характеристики, расчетные и рабочие (максимальные, номинальные, минимальные) параметры и условия работы трубопровода",
          "Паспорт трубопровода, удостоверение о качестве монтажа, исполнительная схема трубопровода",
          "Сведения о дате и результатах проведения технического освидетельствования или экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "Паспорт трубопровода, удостоверение о качестве монтажа, исполнительная схема трубопровода",
        ],
      },
      {
        code: "68176",
        text: "Чему равно минимальное значение уклона, который должны иметь горизонтальные участки трубопроводов пара и горячей воды (за исключением трубопроводов тепловых сетей)?",
        answers: ["0,001", "0,002", "0,003", "0,004"],
        correctAnswers: ["0,004"],
      },
      {
        code: "68177",
        text: "В каком случае допускается отвод воды продувочным трубопроводом в емкость, работающую под давлением?",
        answers: [
          "Если условный проход продувочных трубопроводов и установленной на них арматуры не менее 5 мм",
          "Если сборный бак защищен от превышения давления выше пробного",
          "Если надежность и эффективность продувки подтверждены соответствующими расчетами",
          "Не допускается отвод воды продувочным трубопроводом в емкость, работающую под давлением",
        ],
        correctAnswers: [
          "Если надежность и эффективность продувки подтверждены соответствующими расчетами",
        ],
      },
      {
        code: "68178",
        text: "Чему равно минимальное значение уклона, который должны иметь горизонтальные участки трубопроводов тепловых сетей?",
        answers: ["0,001", "0,002", "0,003", "0,004"],
        correctAnswers: ["0,002"],
      },
      {
        code: "68179",
        text: "Каково минимальное значение высоты каналов и ширины прохода между изолированными трубопроводами пара и горячей воды при их прокладке в полупроходных каналах?",
        answers: [
          "Высота канала не менее 1,3 м, ширина прохода не менее 0,5 м",
          "Высота канала не менее 1,5 м, ширина прохода не менее 0,6 м",
          "Высота канала не менее 1,0 м, ширина прохода не менее 0,4 м",
          "Высота канала не менее 0,5 м, ширина прохода не менее 0,3 м",
        ],
        correctAnswers: [
          "Высота канала не менее 1,5 м, ширина прохода не менее 0,6 м",
        ],
      },
      {
        code: "68180",
        text: "Каково минимальное значение высоты тоннеля (коллектора) и ширины прохода между изолированными трубопроводами пара и горячей воды при их прокладке в проходных тоннелях (коллекторах)?",
        answers: [
          "Высота тоннеля (коллектора) в свету - 1,3 м, ширина прохода - 0,5 м",
          "Высота тоннеля (коллектора) в свету - 1,5 м, ширина прохода - 0,6 м",
          "Высота тоннеля (коллектора) в свету - 2 м, ширина прохода - 0,7 м",
          "Высота тоннеля (коллектора) в свету - 1,7 м, ширина прохода - 0,4 м",
        ],
        correctAnswers: [
          "Высота тоннеля (коллектора) в свету - 2 м, ширина прохода - 0,7 м",
        ],
      },
      {
        code: "68181",
        text: "Какое из приведенных требований должно выполняться при оснащении проходных каналов для трубопроводов пара и горячей воды входными люками?",
        answers: [
          "Расстояние между люками должно быть не более 350 м, для всех трубопроводов люки также предусматриваются в конечных точках тупиковых участков",
          "Расстояние между люками должно быть не более 400 м, для всех трубопроводов люки также предусматриваются на поворотах трассы",
          "Расстояние между люками должно быть не более 300 м; для всех трубопроводов люки также предусматриваются в конечных точках тупиковых участков, на поворотах трассы и в узлах установки арматуры",
          "Расстояние между люками должно быть не более 500 м, для всех трубопроводов люки также предусматриваются в узлах установки арматуры",
        ],
        correctAnswers: [
          "Расстояние между люками должно быть не более 300 м; для всех трубопроводов люки также предусматриваются в конечных точках тупиковых участков, на поворотах трассы и в узлах установки арматуры",
        ],
      },
      {
        code: "68182",
        text: "Для какой арматуры трубопроводов пара и горячей воды должен быть предусмотрен электро-, гидро- или пневмопривод?",
        answers: [
          "Для задвижек и затворов диаметром 500 мм и более",
          "Для задвижек и затворов диаметром 300 мм и более",
          "Для задвижек и затворов диаметром 250 мм и более",
          "Для задвижек и затворов диаметром 150 мм и более",
        ],
        correctAnswers: ["Для задвижек и затворов диаметром 500 мм и более"],
      },
      {
        code: "68183",
        text: "Какое требование к обеспечению прогрева и продувки паропроводов указано неверно?",
        answers: [
          "Устройство дренажей должно предусматривать возможность контроля за их работой во время прогрева паропровода",
          "Все участки паропроводов, которые могут быть отключены запорными органами для возможности их прогрева и продувки, должны быть снабжены в концевых точках штуцером с запорным устройством",
          "В случаях прогрева участка паропровода в обоих направлениях продувка должна быть предусмотрена в середине участка паропровода",
          "Паропроводы с давлением 20 МПа и выше должны быть обеспечены штуцерами с последовательно расположенными запорным и регулирующим вентилями и дроссельной шайбой",
        ],
        correctAnswers: [
          "В случаях прогрева участка паропровода в обоих направлениях продувка должна быть предусмотрена в середине участка паропровода",
        ],
      },
      {
        code: "68184",
        text: "В каком из приведенных случаев допускается одному специалисту совмещать ответственность за осуществление производственного контроля за безопасной эксплуатацией трубопроводов и ответственность за их исправное состояние и безопасную эксплуатацию?",
        answers: [
          "В случае если совмещение обязанностей определено эксплуатирующей организацией в соответствии с ее распорядительными документами",
          "В случае если трубопроводы эксплуатируются на одной производственной площадке",
          "В случае если ответственный имеет техническое образование, соответствующее обязанностям, возложенным на него распорядительными документами организации",
          "Совмещение не допускается",
        ],
        correctAnswers: ["Совмещение не допускается"],
      },
      {
        code: "68185",
        text: "Какое требование к специалистам и рабочим, обслуживающим трубопроводы, указано неверно?",
        answers: [
          "Специалисты и рабочие должны состоять в штате эксплуатирующей организации",
          "Рабочие должны пройти аттестацию по промышленной безопасности в аттестационной комиссии эксплуатирующей организации",
          "У специалистов и рабочих должны отсутствовать медицинские противопоказания к указанной работе",
          "Специалисты и рабочие должны быть допущены к самостоятельной работе в порядке, установленном распорядительными документами организации",
        ],
        correctAnswers: [
          "Рабочие должны пройти аттестацию по промышленной безопасности в аттестационной комиссии эксплуатирующей организации",
        ],
      },
      {
        code: "68186",
        text: "Что из приведенного не входит в должностные обязанности ответственного за осуществление производственного контроля за безопасной эксплуатацией трубопроводов?",
        answers: [
          "Проверка соблюдения установленного порядка допуска рабочих к самостоятельной работе, а также выдача им производственных инструкций",
          "Проверка записи в сменном журнале с росписью в нем",
          "Проверка правильности ведения технической и эксплуатационной документации при эксплуатации и ремонте трубопроводов",
          "Контроль своевременности и полноты проведения ремонта (реконструкции) трубопроводов",
        ],
        correctAnswers: ["Проверка записи в сменном журнале с росписью в нем"],
      },
      {
        code: "68187",
        text: "Что из приведенного не входит в должностные обязанности ответственного за исправное состояние и безопасную эксплуатацию трубопроводов?",
        answers: [
          "Контроль своевременности и полноты проведения ремонта трубопроводов",
          "Контроль за содержанием трубопроводов в исправном (работоспособном) состоянии",
          "Обеспечение соблюдения безопасных условий и режимов эксплуатации трубопроводов",
          "Хранение паспортов трубопроводов и руководства (инструкции) организаций-изготовителей по монтажу и эксплуатации",
        ],
        correctAnswers: [
          "Контроль своевременности и полноты проведения ремонта трубопроводов",
        ],
      },
      {
        code: "68188",
        text: "С какой периодичностью проводится проверка знаний рабочих, обслуживающих трубопроводы?",
        answers: [
          "1 раз в 5 лет",
          "1 раз в 12 месяцев",
          "1 раз в 6 месяцев",
          "1 раз в 2 года",
        ],
        correctAnswers: ["1 раз в 12 месяцев"],
      },
      {
        code: "68189",
        text: "Какое из приведенных требований к проверке знаний рабочих, обслуживающих трубопроводы, указано неверно?",
        answers: [
          "Работник после проведения проверки знаний должен быть ознакомлен с ее результатами под подпись",
          "Участие представителя Ростехнадзора обязательно при проведении первичной аттестации рабочих, обслуживающих трубопроводы",
          "При проведении проверки знаний конкретного работника в комиссии по проверке знаний обслуживающего и ремонтного персонала должно быть не менее 3 человек",
          "Внеочередная проверка знаний должна проводиться при замене, реконструкции (модернизации) трубопроводов",
        ],
        correctAnswers: [
          "Участие представителя Ростехнадзора обязательно при проведении первичной аттестации рабочих, обслуживающих трубопроводы",
        ],
      },
      {
        code: "68190",
        text: "В каком из приведенных случаев до проверки знаний рабочий, обслуживающий трубопроводы, должен пройти стажировку?",
        answers: [
          "Перед первичным допуском к самостоятельной работе после профессионального обучения",
          "Перед допуском к самостоятельной работе после внеочередной проверки знаний",
          "При перерыве в работе по специальности более 12 месяцев",
          "Стажировка проводится во всех приведенных случаях",
          "Стажировка проводится во всех приведенных случаях, кроме перерыва в работе по специальности более 12 месяцев",
        ],
        correctAnswers: ["Стажировка проводится во всех приведенных случаях"],
      },
      {
        code: "68191",
        text: "Какая организация разрабатывает исполнительную схему (чертеж) трубопровода?",
        answers: [
          "Организация, производившая монтаж трубопровода",
          "Эксплуатирующая организация",
          "Проектная организация",
          "Организация - изготовитель трубопроводов",
        ],
        correctAnswers: ["Организация, производившая монтаж трубопровода"],
      },
      {
        code: "68192",
        text: "Что из перечисленного не указывается в исполнительной схеме (чертеже) трубопровода?",
        answers: [
          "Наружные диаметры, толщины труб и деталей из труб, длину трубопровода",
          "Расположение указателей для контроля тепловых перемещений с указанием проектных величин перемещений",
          "Границы (пределы) трубопровода и направление движения рабочей среды",
          "Расчетный срок службы и расчетное количество пусков трубопровода",
        ],
        correctAnswers: [
          "Расчетный срок службы и расчетное количество пусков трубопровода",
        ],
      },
      {
        code: "68193",
        text: "Для каких трубопроводов в исполнительной схеме (чертеже) указывается расположение указателей для контроля тепловых перемещений с указанием проектных величин перемещений?",
        answers: [
          "Для трубопроводов, которые работают при температурах, вызывающих ползучесть металла",
          "Для всех трубопроводов",
          "Для внутренних систем трубопроводов",
          "Для наружных сетей паропроводов, подлежащих учету в территориальном органе Ростехнадзора",
        ],
        correctAnswers: [
          "Для трубопроводов, которые работают при температурах, вызывающих ползучесть металла",
        ],
      },
      {
        code: "68194",
        text: "Для каких трубопроводов эксплуатирующая организация обязана обеспечить проведение систематических наблюдений (контроля) за ростом остаточных деформаций?",
        answers: [
          "Для паропроводов из углеродистой стали, работающего при температуре пара свыше 420 °С",
          "Для паропроводов из хромомолибденовой стали, работающего при температуре пара 450 °C",
          "Для паропроводов из хромистых и хромоникелевых (аустенитных) сталей при температуре пара 520 °С",
          "Для всех указанных трубопроводов",
        ],
        correctAnswers: [
          "Для паропроводов из углеродистой стали, работающего при температуре пара свыше 420 °С",
        ],
      },
      {
        code: "68195",
        text: "Какие из приведенных трубопроводов должны подвергаться техническому диагностированию, неразрушающему, разрушающему контролю до выработки ими назначенного ресурса?",
        answers: [
          "Паропроводы из углеродистой стали, работающие при температуре пара свыше 520 °С",
          "Паропроводы из углеродистой стали, работающие при температуре пара свыше 420 °С",
          "Паропроводы из хромомолибденованадиевых сталей, работающие при температуре пара 450 °С",
          "Паропроводы из хромистых сталей, работающие при температуре пара 500 °С",
        ],
        correctAnswers: [
          "Паропроводы из углеродистой стали, работающие при температуре пара свыше 420 °С",
        ],
      },
      {
        code: "68196",
        text: "С какой периодичностью проводится контроль степени затяжки пружин подвесок и опор трубопроводов в рабочем и холодном состоянии?",
        answers: [
          "Периодичность контроля степени затяжки пружин подвесок и опор трубопроводов устанавливает проектирующая организация",
          "Не реже 1 раза в 4 года",
          "Не реже 1 раза в 2 года",
          "Периодичность контроля степени затяжки пружин подвесок и опор трубопроводов устанавливает эксплуатирующая организация",
        ],
        correctAnswers: ["Не реже 1 раза в 2 года"],
      },
      {
        code: "68197",
        text: "При заполнении каких трубопроводов должен осуществляться контроль разности температур стенок трубопровода и рабочей среды?",
        answers: [
          "При заполнении всех трубопроводов, подлежащих учету в Ростехнадзоре",
          "При заполнении трубопроводов холодной воды",
          "При заполнении трубопроводов наружных тепловых сетей",
          "При заполнении неостывших паропроводов",
        ],
        correctAnswers: ["При заполнении неостывших паропроводов"],
      },
      {
        code: "68198",
        text: "Какое из приведенных требований к дренажным системам трубопроводов пара и горячей воды указано неверно?",
        answers: [
          "При объединении дренажных линий нескольких трубопроводов допускается установка запорной арматуры на общем дренажном трубопроводе",
          "При замене деталей и элементов трубопроводов необходимо сохранить проектное положение оси трубопровода",
          "При прокладке дренажных линий должно быть учтено направление тепловых перемещений во избежание защемления трубопроводов",
          "При объединении дренажных линий нескольких трубопроводов на каждом из них должна быть установлена запорная арматура",
        ],
        correctAnswers: [
          "При объединении дренажных линий нескольких трубопроводов допускается установка запорной арматуры на общем дренажном трубопроводе",
        ],
      },
      {
        code: "68199",
        text: "Какое из приведенных требований к арматуре трубопроводов указано неверно?",
        answers: [
          "Арматура должна быть доступна для обслуживания",
          "В местах установки арматуры и индикаторов тепловых перемещений паропроводов должны быть установлены площадки обслуживания",
          "Для трубопроводов горячей воды допускается использование запорной арматуры в качестве регулирующей",
          "Арматура должна быть использована в соответствии с ее функциональным назначением, указанным в технической документации",
        ],
        correctAnswers: [
          "Для трубопроводов горячей воды допускается использование запорной арматуры в качестве регулирующей",
        ],
      },
      {
        code: "68200",
        text: "В какие сроки проводится проверка исправности действия манометров и предохранительных клапанов при эксплуатации трубопроводов с рабочим давлением свыше 1,4 до 4,0 МПа включительно?",
        answers: [
          "Не реже 1 раза в сутки",
          "Не реже 1 раза в неделю",
          "В сроки, установленные инструкцией, утвержденной техническим руководителем (главным инженером) организации",
          "В сроки, установленные графиком эксплуатирующей организации, утвержденным главным механиком организации",
        ],
        correctAnswers: ["Не реже 1 раза в сутки"],
      },
      {
        code: "68201",
        text: "В какие сроки проводится проверка исправности действия манометров и предохранительных клапанов при эксплуатации трубопроводов с рабочим давлением свыше 4,0 МПа?",
        answers: [
          "Не реже 1 раза в смену",
          "Не реже 1 раза в сутки",
          "Сроки устанавливаются эксплуатирующей организацией и указываются в инструкции, которая утверждается техническим руководителем эксплуатирующей организации",
          "Не реже 1 раза в неделю",
        ],
        correctAnswers: [
          "Сроки устанавливаются эксплуатирующей организацией и указываются в инструкции, которая утверждается техническим руководителем эксплуатирующей организации",
        ],
      },
      {
        code: "68202",
        text: "В какие сроки проводится проверка исправности действия манометров и предохранительных клапанов при эксплуатации трубопроводов, установленных на тепловых электростанциях?",
        answers: [
          "Не реже 1 раза в сутки",
          "Не реже 1 раза в смену",
          "В соответствии с графиком проверки манометров эксплуатирующей организации",
          "Сроки устанавливаются эксплуатирующей организацией и указываются в инструкции, которая утверждается техническим руководителем эксплуатирующей организации",
        ],
        correctAnswers: [
          "Сроки устанавливаются эксплуатирующей организацией и указываются в инструкции, которая утверждается техническим руководителем эксплуатирующей организации",
        ],
      },
      {
        code: "68203",
        text: "Манометры какого класса точности необходимо применять при эксплуатации трубопроводов с рабочим давлением не более 2,5 МПа?",
        answers: ["Не ниже 1,0", "Не ниже 0,6", "Не ниже 2,5", "Не ниже 4,0"],
        correctAnswers: ["Не ниже 2,5"],
      },
      {
        code: "68204",
        text: "Манометры какого класса точности необходимо применять при эксплуатации трубопроводов с рабочим давлением более 2,5 до 14 МПа?",
        answers: ["Не ниже 1,0", "Не ниже 1,5", "Не ниже 2,5", "Не ниже 4,0"],
        correctAnswers: ["Не ниже 1,5"],
      },
      {
        code: "68205",
        text: "Манометры какого класса точности необходимо применять при эксплуатации трубопроводов с рабочим давлением более 14 МПа?",
        answers: ["Не ниже 1,0", "Не ниже 1,5", "Не ниже 2,5", "Не ниже 4,0"],
        correctAnswers: ["Не ниже 1,0"],
      },
      {
        code: "68206",
        text: "Какое из приведенных требований к манометру указано неверно?",
        answers: [
          "Шкалу манометров выбирают из условия, чтобы при рабочем давлении стрелка манометра находилась либо в первой, либо во второй трети шкалы",
          "На шкале манометра должна быть нанесена красная черта, указывающая разрешенное рабочее давление",
          "Взамен красной черты допускается прикреплять к корпусу манометра пластинку (металлическую или выполненную из композитных материалов), окрашенную в красный цвет и плотно прилегающую к стеклу манометра",
          "Все приведенные требования указаны неверно",
        ],
        correctAnswers: [
          "Шкалу манометров выбирают из условия, чтобы при рабочем давлении стрелка манометра находилась либо в первой, либо во второй трети шкалы",
        ],
      },
      {
        code: "68207",
        text: "Каким образом должен устанавливаться манометр на трубопроводе?",
        answers: [
          "Шкала манометра должна быть расположена вертикально или с наклоном вперед до 45°",
          "Шкала манометра должна быть расположена вертикально или с наклоном вперед от 30° до 45°",
          "Шкала манометра может быть расположена произвольно, но так, чтобы показания манометра были отчетливо видны обслуживающему персоналу",
          "Шкала манометра должна быть расположена вертикально или с наклоном вперед до 30°",
        ],
        correctAnswers: [
          "Шкала манометра должна быть расположена вертикально или с наклоном вперед до 30°",
        ],
      },
      {
        code: "68208",
        text: "Каково минимальное значение номинального диаметра корпуса манометра, установленного на трубопроводе на высоте менее 2 м от уровня площадки наблюдения за манометром?",
        answers: ["50 мм", "100 мм", "80 мм", "60 мм"],
        correctAnswers: ["100 мм"],
      },
      {
        code: "68209",
        text: "Каково минимальное значение номинального диаметра корпуса манометра, установленного на трубопроводе на высоте от 2 до 3 м от уровня площадки наблюдения за манометром?",
        answers: ["80 мм", "100 мм", "160 мм", "50 мм"],
        correctAnswers: ["160 мм"],
      },
      {
        code: "68210",
        text: "Каково минимальное значение номинального диаметра корпуса манометра, установленного на трубопроводе на высоте более 3 до 5 м от уровня площадки наблюдения за манометром?",
        answers: ["200 мм", "100 мм", "150 мм", "250 мм"],
        correctAnswers: ["250 мм"],
      },
      {
        code: "68211",
        text: "Каково минимальное значение номинального диаметра корпуса манометра, установленного на трубопроводе более 5 м от уровня площадки наблюдения за манометром?",
        answers: [
          "250 мм",
          "160 мм",
          "100 мм",
          "Не регламентируется, при этом должен быть установлен сниженный манометр в качестве дублирующего",
        ],
        correctAnswers: [
          "Не регламентируется, при этом должен быть установлен сниженный манометр в качестве дублирующего",
        ],
      },
      {
        code: "68212",
        text: "В каком случае перед манометром на трубопроводе должна устанавливаться сифонная трубка?",
        answers: [
          "Если манометр предназначен для измерения давления пара",
          "Если манометр не оснащен трехходовым краном",
          "Если манометр предназначен для измерения давления холодной воды",
          "Если манометр предназначен для измерения давления горячей воды",
        ],
        correctAnswers: [
          "Если манометр предназначен для измерения давления пара",
        ],
      },
      {
        code: "68213",
        text: "Какое требование к проведению проверки манометров, установленных на трубопроводе, указано неверно?",
        answers: [
          "Обслуживающий персонал должен производить проверку исправности манометра в процессе эксплуатации трубопровода с периодичностью, установленной в производственной инструкции, с помощью трехходового крана или заменяющих его запорных вентилей путем установки стрелки манометра на нуль",
          "Эксплуатирующая организация обязана не реже 1 раза в 6 месяцев проводить дополнительную проверку рабочих манометров контрольным с записью результатов в журнал контрольных проверок манометров",
          "Не реже 1 раза в 12 месяцев (если иные сроки не установлены документацией на манометр) манометры должны быть поверены, и на каждом из них должно быть установлено клеймо или пломба",
          "Все приведенные требования указаны неверно",
        ],
        correctAnswers: [
          "Эксплуатирующая организация обязана не реже 1 раза в 6 месяцев проводить дополнительную проверку рабочих манометров контрольным с записью результатов в журнал контрольных проверок манометров",
        ],
      },
      {
        code: "68214",
        text: "В каком из приведенных случаев манометр может быть допущен к применению?",
        answers: [
          "Если отсутствует информация о проведении поверки (пломба или клеймо, или документ о проведении поверки)",
          "Если стрелка манометра при его отключении не возвращается к нулевой отметке шкалы на величину, не превышающую половины допускаемой погрешности для манометра",
          "Если разбито стекло или имеются другие повреждения манометра, которые могут отразиться на правильности его показаний",
          "Если истек срок поверки манометра",
        ],
        correctAnswers: [
          "Если стрелка манометра при его отключении не возвращается к нулевой отметке шкалы на величину, не превышающую половины допускаемой погрешности для манометра",
        ],
      },
      {
        code: "68215",
        text: "В каком случае исправность установленных на трубопроводе предохранительных клапанов осуществляется путем проверки срабатывания клапана на испытательных стендах?",
        answers: [
          "Если исправность предохранительных клапанов проверяется только принудительным кратковременным их подрывом (открыванием). Проверка на стендах проводится перед установкой клапанов на трубопроводе",
          "Если давление в трубопроводе более 2,5 МПа",
          "Если принудительное открывание клапана нежелательно по условиям технологического процесса",
          "Если предохранительные клапаны установлены на паропроводе высокого давления",
        ],
        correctAnswers: [
          "Если принудительное открывание клапана нежелательно по условиям технологического процесса",
        ],
      },
      {
        code: "68216",
        text: "Каким образом должны быть рассчитаны и отрегулированы предохранительные устройства, установленные на трубопроводе с разрешенным давлением до 0,5 МПа?",
        answers: [
          "Так, чтобы давление в защищаемом элементе не превышало разрешенное более чем на 10 %",
          "Так, чтобы давление в защищаемом элементе не превышало разрешенное более чем на 20 %",
          "Так, чтобы давление в защищаемом элементе не превышало разрешенное более чем 0,05 МПа",
          "Так, чтобы давление в защищаемом элементе не превышало разрешенное более чем 0,15 МПа",
        ],
        correctAnswers: [
          "Так, чтобы давление в защищаемом элементе не превышало разрешенное более чем 0,05 МПа",
        ],
      },
      {
        code: "68217",
        text: "Каким образом должны быть рассчитаны и отрегулированы предохранительные устройства, установленные на трубопроводе с разрешенным давлением свыше 0,5 МПа?",
        answers: [
          "Так, чтобы давление в защищаемом элементе не превышало разрешенное более чем на 10 %",
          "Так, чтобы давление в защищаемом элементе не превышало разрешенное более чем на 20 %",
          "Так, чтобы давление в защищаемом элементе не превышало разрешенное более чем 0,05 МПа",
          "Так, чтобы давление в защищаемом элементе не превышало разрешенное более чем 0,15 МПа",
        ],
        correctAnswers: [
          "Так, чтобы давление в защищаемом элементе не превышало разрешенное более чем на 10 %",
        ],
      },
      {
        code: "68218",
        text: "В каком случае допускается превышение разрешенного давления в трубопроводе при полном открывании предохранительного клапана более чем на 10 %?",
        answers: [
          "Если на защищаемом элементе установлено не менее 2 предохранительных клапанов",
          "Если регулировка предохранительного клапана допускает превышение разрешенного давления не более чем на 15 %",
          "Если предохранительные клапаны установлены на трубопроводе холодной воды",
          "Если это превышение предусмотрено расчетом на прочность трубопровода",
        ],
        correctAnswers: [
          "Если это превышение предусмотрено расчетом на прочность трубопровода",
        ],
      },
      {
        code: "68219",
        text: "Если эксплуатация трубопровода разрешена на пониженном давлении, то каким образом осуществляется регулировка предохранительных устройств?",
        answers: [
          "Регулировка предохранительных устройств должна быть произведена по повышенному давлению, и пропускная способность их должна быть проверена расчетом",
          "Регулировка предохранительных устройств не производится, они подлежат замене",
          "Регулировка предохранительных устройств должна быть произведена по пониженному давлению, и пропускная способность их должна быть проверена расчетом",
        ],
        correctAnswers: [
          "Регулировка предохранительных устройств должна быть произведена по пониженному давлению, и пропускная способность их должна быть проверена расчетом",
        ],
      },
      {
        code: "68220",
        text: "Какое из приведенных требований к эксплуатации предохранительных клапанов, установленных на трубопроводе, указано неверно?",
        answers: [
          "Для отбора среды от патрубка, на котором установлено предохранительное устройство, не допускается установка более одного запорного устройства",
          "Установка запорных устройств на подводе рабочей среды к предохранительному устройству и на трубопроводах между импульсным и главным клапанами импульсных предохранительных устройств запрещается",
          "Предохранительные клапаны должны иметь отводящие трубопроводы, предохраняющие персонал от ожогов при срабатывании клапанов",
          "Установка запорных устройств на отводящих трубопроводах не допускается",
        ],
        correctAnswers: [
          "Для отбора среды от патрубка, на котором установлено предохранительное устройство, не допускается установка более одного запорного устройства",
        ],
      },
      {
        code: "68221",
        text: "Какие меры для обеспечения безопасности должны приниматься при эксплуатации трубопровода, расчетное давление и разрешенное рабочее давление которого меньше давления питающего его источника?",
        answers: [
          "Установка перед защищаемым элементом дополнительно манометра и пружинного предохранительного клапана, отрегулированного на давление, на 15 % превышающее расчетное",
          "Установка автоматического редуцирующего устройства с манометром и предохранительным клапаном на стороне повышенного давления",
          "Установка редуцирующего устройства или редукционно-охладительной установки (при необходимости регулирования давления и температуры) с манометром и предохранительным устройством, установленными на стороне меньшего давления после редуцирующего устройства",
          "Прекращение эксплуатации трубопровода, расчетное давление которого ниже давления питающего его источника",
        ],
        correctAnswers: [
          "Установка редуцирующего устройства или редукционно-охладительной установки (при необходимости регулирования давления и температуры) с манометром и предохранительным устройством, установленными на стороне меньшего давления после редуцирующего устройства",
        ],
      },
      {
        code: "68222",
        text: "Кто осуществляет ведение ремонтного журнала (ремонтных журналов) в эксплуатирующей трубопроводы организации?",
        answers: [
          "Лицо, осуществляющее ведение ремонтного журнала, назначенное приказом эксплуатирующей организации",
          "Уполномоченный представитель специализированной организации, осуществляющей ремонт трубопровода",
          "Специалист, ответственный за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением",
          "Лицо, ответственное за исправное состояние и безопасную эксплуатацию трубопроводов",
        ],
        correctAnswers: [
          "Лицо, ответственное за исправное состояние и безопасную эксплуатацию трубопроводов",
        ],
      },
      {
        code: "68223",
        text: "Какие из приведенных сведений вносятся в ремонтный журнал (ремонтные журналы)?",
        answers: [
          "Сведения о всех выполненных ремонтных работах, в том числе не вызывающих необходимости внеочередного технического освидетельствования",
          "Сведения о выполненных ремонтных работах, вызывающих необходимость проведения внеочередного освидетельствования",
          "Сведения о материалах, использованных при ремонтных работах",
          "Сведения о качестве сварки",
          "Все приведенные сведения",
        ],
        correctAnswers: [
          "Сведения о всех выполненных ремонтных работах, в том числе не вызывающих необходимости внеочередного технического освидетельствования",
        ],
      },
      {
        code: "68224",
        text: "Какое требование к отключению трубопровода до начала производства ремонтных работ указано неверно?",
        answers: [
          "Толщина применяемых при отключении трубопровода заглушек и фланцев должна быть определена расчетом на прочность",
          "Заглушка должна иметь выступающую часть (хвостовик), по которой определяют ее наличие",
          "Прокладки между фланцами и заглушкой должны быть без хвостовиков",
          "Ключи от запирающих устройств должны храниться у ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением",
        ],
        correctAnswers: [
          "Ключи от запирающих устройств должны храниться у ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением",
        ],
      },
      {
        code: "68225",
        text: "По какому документу выполняются ремонт трубопроводов, арматуры и элементов дистанционного управления арматурой, установка и снятие заглушек, отделяющих ремонтируемый участок трубопровода?",
        answers: [
          "Ремонт - по наряду-допуску; установка и снятие заглушек - по распоряжению ответственного за исправное состояние и безопасную эксплуатацию трубопроводов",
          "Ремонт - по приказу эксплуатирующей организации; установка и снятие заглушек - по наряду-допуску",
          "Все работы выполняются по регламенту, разработанному и утвержденному проектирующей организацией",
          "Все работы выполняются по наряду-допуску",
        ],
        correctAnswers: ["Все работы выполняются по наряду-допуску"],
      },
      {
        code: "68226",
        text: "Каким давлением проводится испытание на герметичность арматуры после ее ремонта?",
        answers: [
          "Рабочим давлением - для любой арматуры",
          "Давлением, равным 1,5 номинального давления, но не менее 0,2 МПа, - для арматуры, ремонтируемой без снятия с места установки, и повышенным давлением - для снимаемой с места арматуры",
          "Давлением, равным 1,25 рабочего давления, – для арматуры, снимаемой с места, и рабочим давлением – для арматуры, ремонтируемой без снятия с места установки",
          "Давлением, равным 0,25 рабочего давления, - для арматуры, снимаемой с места, и пробным давлением - для арматуры, ремонтируемой без снятия с места установки",
        ],
        correctAnswers: [
          "Давлением, равным 1,25 рабочего давления, – для арматуры, снимаемой с места, и рабочим давлением – для арматуры, ремонтируемой без снятия с места установки",
        ],
      },
      {
        code: "68227",
        text: "Какое условие должна обеспечивать тепловая изоляция трубопроводов и арматуры?",
        answers: [
          "Максимальное снижение потерь тепла от поверхностей с повышенной температурой в окружающую среду",
          "Температуру наружной поверхности изоляции не более 65 °С при температуре окружающей среды не более 20 °С",
          "Температуру поверхности изоляции не более 55 °С при температуре окружающего воздуха 25 °С",
        ],
        correctAnswers: [
          "Температуру поверхности изоляции не более 55 °С при температуре окружающего воздуха 25 °С",
        ],
      },
      {
        code: "68228",
        text: "Какое из приведенных требований к оснащению трубопроводов съемной тепловой изоляцией указано неверно?",
        answers: [
          "Тепловая изоляция фланцевых соединений, арматуры и участков трубопроводов, подвергающихся периодическому контролю, должна быть съемной",
          "Трубопроводы, расположенные вблизи кабельных линий, должны иметь металлическое покрытие",
          "Трубопроводы, расположенные на открытом воздухе, должны иметь\nсъемную изоляцию",
          "Трубопроводы с температурой рабочей среды ниже температуры окружающего воздуха должны быть защищены от коррозии, иметь гидро- и теплоизоляцию",
        ],
        correctAnswers: [
          "Трубопроводы, расположенные на открытом воздухе, должны иметь\nсъемную изоляцию",
        ],
      },
      {
        code: "68229",
        text: "Какому требованию должна отвечать тепловая изоляция трубопроводов, расположенных на открытом воздухе и вблизи масляных баков, маслопроводов, мазутопроводов?",
        answers: [
          "Тепловая изоляция на этих участках должна иметь металлическое или другое покрытие для предохранения ее от пропитывания влагой или горючими нефтепродуктами",
          "Тепловая изоляция на этих участках должна быть съемной",
          "Трубопроводы на этих участках должны кроме тепловой изоляции иметь гидроизоляцию",
          "На этих участках температура наружной поверхности тепловой изоляции не должна превышать 30 °С",
          "Тепловая изоляция должна соответствовать всем приведенным требованиям",
        ],
        correctAnswers: [
          "Тепловая изоляция на этих участках должна иметь металлическое или другое покрытие для предохранения ее от пропитывания влагой или горючими нефтепродуктами",
        ],
      },
      {
        code: "68230",
        text: "В каком из приведенных случаев в соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, трубопровод не подлежит аварийной остановке?",
        answers: [
          "При защемлении и повышенной вибрации трубопровода",
          "При неисправности манометра на трубопроводе насыщенного пара, оснащенном прибором для измерения температуры",
          "При неисправности дренажных устройств для непрерывного удаления жидкости",
          "При выявлении неисправности предохранительного устройства от повышения давления, неисправности редуцирующего устройства",
        ],
        correctAnswers: [
          "При неисправности манометра на трубопроводе насыщенного пара, оснащенном прибором для измерения температуры",
        ],
      },
      {
        code: "68231",
        text: "В каком из приведенных случаев в соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, трубопровод подлежит аварийной остановке?",
        answers: [
          "В случае неисправности манометра и невозможности определить давление по другим приборам",
          "В случае неисправности предохранительных блокировочных устройств",
          "В случае возникновения пожара, непосредственно угрожающего трубопроводу",
          "Во всех приведенных случаях трубопровод подлежит аварийной остановке",
        ],
        correctAnswers: [
          "Во всех приведенных случаях трубопровод подлежит аварийной остановке",
        ],
      },
      {
        code: "68232",
        text: "Каким документом определяется порядок действий в случае инцидента при эксплуатации трубопровода?",
        answers: [
          "Производственной инструкцией, утвержденной эксплуатирующей организацией",
          "Планом мероприятий по локализации и ликвидации последствий аварий на ОПО, утвержденной проектной организацией",
          "Руководством по эксплуатации трубопровода",
        ],
        correctAnswers: [
          "Производственной инструкцией, утвержденной эксплуатирующей организацией",
        ],
      },
      {
        code: "68233",
        text: "Какое требование необходимо выполнять при установке на одном патрубке (трубопроводе) нескольких предохранительных клапанов?",
        answers: [
          "Площадь поперечного сечения патрубка (трубопровода) должна быть не менее 1,25 суммарной площади сечения клапанов, установленных на нем",
          "Площадь поперечного сечения патрубка (трубопровода) должна быть не более суммарной площади сечения клапанов, установленных на нем",
          "Площадь поперечного сечения патрубка (трубопровода) должна быть не менее 0,75 суммарной площади сечения клапанов, установленных на нем",
        ],
        correctAnswers: [
          "Площадь поперечного сечения патрубка (трубопровода) должна быть не менее 1,25 суммарной площади сечения клапанов, установленных на нем",
        ],
      },
      {
        code: "68234",
        text: "Какое из приведенных требований к организации отвода токсичных, взрыво- и пожароопасных технологических сред, выходящих из предохранительных устройств, указано неверно?",
        answers: [
          "Сбрасываемые токсичные, взрыво- и пожароопасные технологические среды должны направляться в закрытые системы для дальнейшей утилизации или в системы организованного сжигания",
          "В обоснованных проектной документацией случаях допускается сброс сред в атмосферу через сбросные трубопроводы при обеспечении безопасного рассеивания сбрасываемой среды",
          "Среда, выходящая из предохранительных устройств, должна отводиться в безопасное место",
          "Запрещается объединять сбросы, содержащие вещества, которые способны при смешивании образовывать взрывоопасные смеси или нестабильные соединения",
        ],
        correctAnswers: [
          "В обоснованных проектной документацией случаях допускается сброс сред в атмосферу через сбросные трубопроводы при обеспечении безопасного рассеивания сбрасываемой среды",
        ],
      },
      {
        code: "68235",
        text: "Отсутствие какой документации не является препятствием для осуществления монтажа, ремонта, реконструкции (модернизации) оборудования, работающего под давлением, специализированной организацией?",
        answers: [
          "Эксплуатационной документации монтируемого, ремонтируемого, реконструируемого (модернизируемого) оборудования",
          "Типовых программ (методик) пусконаладки, испытаний и комплексного опробования монтируемого (ремонтируемого, реконструируемого) оборудования под давлением, проводимых по окончании монтажа, ремонта, реконструкции",
          "Нормативных документов, необходимость применения которых для обеспечения требований промышленной безопасности, установленных законодательством в области промышленной безопасности, при выполнении соответствующих работ установлена специализированной организацией в виде утвержденного перечня или иного распорядительного документа",
          "Технологической документации на производство заявленных видов работ (технологических инструкций, процессов, карт, проектов производства монтажно-демонтажных работ), разработанной до начала этих работ",
        ],
        correctAnswers: [
          "Эксплуатационной документации монтируемого, ремонтируемого, реконструируемого (модернизируемого) оборудования",
        ],
      },
      {
        code: "68236",
        text: "В каком из приведенных случаев допускается использование при монтаже, ремонте, реконструкции (модернизации) стальных труб и иных материалов, ранее бывших в употреблении?",
        answers: [
          "Ни в каком из приведенных случаев",
          "В случае непревышения срока эксплуатации стальных труб половины расчетного срока службы технического устройства, на котором эти трубы эксплуатировались",
          "В случае оформления на стальные трубы документов, подтверждающих их соответствие и качество (сертификатов, деклараций соответствия)",
          "В случае применения таких труб в составе обводных (байпасных) и продувочных линий, временно обустраиваемых на ограниченный период времени, определенный проектом монтажа, ремонта, реконструкции (модернизации) системы трубопроводов",
        ],
        correctAnswers: [
          "В случае применения таких труб в составе обводных (байпасных) и продувочных линий, временно обустраиваемых на ограниченный период времени, определенный проектом монтажа, ремонта, реконструкции (модернизации) системы трубопроводов",
        ],
      },
      {
        code: "68237",
        text: "В какие сроки проводится проверка исправности действия манометров и предохранительных клапанов при эксплуатации трубопроводов с рабочим давлением до 1,4 МПа?",
        answers: [
          "Не реже 1 раза в смену",
          "Не реже 1 раза в сутки",
          "В сроки, установленные инструкцией, утвержденной техническим руководителем (главным инженером) организации",
          "Проверка манометров проводится не реже 1 раза в смену; проверка предохранительных клапанов – не реже 1 раза в сутки",
        ],
        correctAnswers: ["Не реже 1 раза в смену"],
      },
      {
        code: "68238",
        text: "Каким документом (документами) устанавливается объем работ, порядок и периодичность проведения технических освидетельствований в пределах срока службы трубопровода?",
        answers: [
          "Инструкцией (руководством) по эксплуатации трубопровода и ФНП ОРПД",
          "Программой проведения технического освидетельствования трубопровода, разработанной специализированной организацией до начала проведения освидетельствования",
          "Производственной инструкцией по эксплуатации трубопровода, утвержденной главным инженером эксплуатационной организации",
        ],
        correctAnswers: [
          "Инструкцией (руководством) по эксплуатации трубопровода и ФНП ОРПД",
        ],
      },
      {
        code: "68239",
        text: "В каком из приведенных случаев должно проводиться внеочередное техническое освидетельствование паропровода?",
        answers: [
          "Если паропровод не эксплуатируется более 24 месяцев",
          "Если проведен восстановительный ремонт паропровода после аварии с заменой поврежденного в результате аварии участка паропровода без применения сварки",
          "Если паропровод не эксплуатируется более 12 месяцев",
          "Во всех приведенных случаях",
        ],
        correctAnswers: ["Если паропровод не эксплуатируется более 24 месяцев"],
      },
      {
        code: "68240",
        text: "Какие условия должны соблюдаться при установлении срока следующего периодического технического освидетельствования котла и трубопровода?",
        answers: [
          "Срок следующего периодического технического освидетельствования устанавливается только эксплуатирующей организацией локальными нормативными документами",
          "Срок следующего периодического технического освидетельствования котла может превышать, но не более чем на 2 года, срок его службы, установленный изготовителем или заключением экспертизы промышленной безопасности",
          "Срок следующего периодического технического освидетельствования не должен превышать срока службы, установленного изготовителем или заключением экспертизы промышленной безопасности, оформленным по результатам технического диагностирования при продлении срока службы трубопровода",
        ],
        correctAnswers: [
          "Срок следующего периодического технического освидетельствования не должен превышать срока службы, установленного изготовителем или заключением экспертизы промышленной безопасности, оформленным по результатам технического диагностирования при продлении срока службы трубопровода",
        ],
      },
      {
        code: "68241",
        text: "Что необходимо предпринять, если при техническом освидетельствовании трубопровода будут обнаружены дефекты?",
        answers: [
          "Для установления характера и размеров дефектов должно быть проведено техническое диагностирование трубопровода с применением методов неразрушающего контроля",
          "Эксплуатирующая организация должна перевести режим эксплуатации трубопровода на пониженные параметры, рекомендованные организацией, проводившей техническое освидетельствование",
          "Эксплуатирующая организация должна в течение 3 дней вывести трубопровод из эксплуатации",
        ],
        correctAnswers: [
          "Для установления характера и размеров дефектов должно быть проведено техническое диагностирование трубопровода с применением методов неразрушающего контроля",
        ],
      },
      {
        code: "68242",
        text: "Если при проведении технического диагностирования установлено, что выявленные при техническом освидетельствовании дефекты снижают прочность трубопровода, то в каком из приведенных случаев допускается эксплуатация трубопровода на пониженных параметрах (давление, температура)?",
        answers: [
          "При условии, что возможность безопасной эксплуатации на пониженных параметрах до устранения дефектов (ремонта, замены) допускается технологическим процессом, подтверждена расчетом на прочность с учетом характера и размеров дефектов, а также проведена проверка пропускной способности предохранительных клапанов соответствующим расчетом и их перенастройка (с учетом пониженных параметров)",
          "При условии, что возможность безопасной эксплуатации до устранения дефектов (ремонта, замены) должна быть только подтверждена проверкой пропускной способности предохранительных клапанов соответствующим расчетом и их перенастройкой (с учетом пониженных параметров)",
          "Ни в каком из приведенных случаев",
          "При условии, что возможность безопасной эксплуатации до устранения дефектов (ремонта, замены) допускается в течение 2 недель при возможности его эксплуатации на пониженных параметрах (давлении, температуре), записанных в паспорт по результатам технического диагностирования",
        ],
        correctAnswers: [
          "При условии, что возможность безопасной эксплуатации на пониженных параметрах до устранения дефектов (ремонта, замены) допускается технологическим процессом, подтверждена расчетом на прочность с учетом характера и размеров дефектов, а также проведена проверка пропускной способности предохранительных клапанов соответствующим расчетом и их перенастройка (с учетом пониженных параметров)",
        ],
      },
      {
        code: "68243",
        text: "Что необходимо предпринять, если при техническом освидетельствовании будет установлено, что трубопровод вследствие имеющихся дефектов или нарушений находится в состоянии, опасном для дальнейшей его эксплуатации?",
        answers: [
          "До устранения дефектов и нарушений перевести трубопровод в режим работы на пониженных параметрах (давлении, температуре)",
          "Работа такого трубопровода должна быть запрещена",
          "Перевести трубопровод в режим работы на пониженных параметрах (давлении, температуре) и провести экспертизу промышленной безопасности трубопровода",
        ],
        correctAnswers: ["Работа такого трубопровода должна быть запрещена"],
      },
      {
        code: "68244",
        text: "Что включает в себя техническое освидетельствование трубопровода, проводимое после его реконструкции и ремонта, связанного со сваркой и термической обработкой?",
        answers: [
          "Только наружный осмотр путем вскрытия грунта отдельных участков и снятия изоляции",
          "Наружный осмотр и испытание неразрушающими методами контроля",
          "Наружный осмотр и гидравлическое испытание",
          "В данном случае техническое освидетельствование трубопровода не проводится",
        ],
        correctAnswers: ["Наружный осмотр и гидравлическое испытание"],
      },
      {
        code: "68245",
        text: "Кем проводятся первичное, периодическое и внеочередное технические освидетельствования трубопроводов пара и горячей воды, подлежащих учету в территориальных органах Ростехнадзора?",
        answers: [
          "Первичное, периодическое и внеочередное технические освидетельствования проводит специализированная уполномоченная организация",
          "Первичное и внеочередное технические освидетельствования проводит специализированная уполномоченная организация, а периодическое техническое освидетельствование - ответственный за исправное состояние и безопасную эксплуатацию трубопровода",
          "Первичное, периодическое и внеочередное технические освидетельствования проводит ответственный за исправное состояние и безопасную эксплуатацию трубопровода совместно с ответственным за производственный контроль",
          "Первичное, периодическое технические освидетельствования проводит специализированная уполномоченная организация, а внеочередное освидетельствование - представитель территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "Первичное, периодическое и внеочередное технические освидетельствования проводит специализированная уполномоченная организация",
        ],
      },
      {
        code: "68246",
        text: "Кем проводится техническое освидетельствование трубопроводов, не подлежащих учету в органах Ростехнадзора?",
        answers: [
          "Первичное и внеочередное технические освидетельствования проводит ответственный за осуществление производственного контроля за эксплуатацией сосудов, работающих под давлением",
          "Первичное, периодическое и внеочередное технические освидетельствования проводит специализированная уполномоченная организация",
          "Первичное, периодическое и внеочередное технические освидетельствования проводит ответственный за исправное состояние и безопасную эксплуатацию трубопровода",
          "Первичное, периодическое и внеочередное технические освидетельствования проводит комиссия, назначенная эксплуатирующей организацией, с участием представителя территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "Первичное, периодическое и внеочередное технические освидетельствования проводит ответственный за исправное состояние и безопасную эксплуатацию трубопровода",
        ],
      },
      {
        code: "68247",
        text: "Для каких трубопроводов наружный осмотр может быть произведен без снятия изоляции?",
        answers: [
          "Для трубопроводов, проложенных открытым способом или в проходных и полупроходных каналах",
          "Для трубопроводов, проложенных в непроходных каналах или при их бесканальной прокладке",
          "Ни для каких трубопроводов",
          "Для трубопроводов, проложенных закрытым способом или в проходных и полупроходных каналах",
        ],
        correctAnswers: [
          "Для трубопроводов, проложенных открытым способом или в проходных и полупроходных каналах",
        ],
      },
      {
        code: "68248",
        text: "Каким образом проводится наружный осмотр трубопроводов при прокладке в непроходных каналах или при бесканальной прокладке, если иное не предусмотрено в проектной документации и руководстве (инструкции) по эксплуатации трубопровода?",
        answers: [
          "Путем вскрытия грунта отдельных участков без снятия изоляции не реже чем через каждые 4 км трубопровода",
          "Путем вскрытия грунта отдельных участков и снятия изоляции не реже чем через каждые 2 км трубопровода",
          "Путем вскрытия грунта всего участка и снятия изоляции на любом отрезке трубопровода",
          "Путем вскрытия грунта отдельных участков со снятием изоляции не реже чем через каждые 3 км трубопровода",
        ],
        correctAnswers: [
          "Путем вскрытия грунта отдельных участков и снятия изоляции не реже чем через каждые 2 км трубопровода",
        ],
      },
      {
        code: "68249",
        text: "Чему равна минимальная величина пробного давления при гидравлическом испытании трубопроводов пара и горячей воды?",
        answers: [
          "1,25 рабочего давления (указанного в паспорте организацией-изготовителем или по результатам первичного технического освидетельствования), но не менее 0,2 МПа",
          "1,5 пробного давления (указанного в паспорте организацией-изготовителем или по результатам первичного технического освидетельствования), но не менее 0,3 МПа",
          "1,25 расчетного давления, но не менее 0,5 МПа",
          "0,4 МПа",
        ],
        correctAnswers: [
          "1,25 рабочего давления (указанного в паспорте организацией-изготовителем или по результатам первичного технического освидетельствования), но не менее 0,2 МПа",
        ],
      },
      {
        code: "68250",
        text: "Какое из приведенных требований должно выполняться при гидравлическом испытании паропроводов с рабочим давлением 10 МПа и более?",
        answers: [
          "При гидравлическом испытании паропровода температура воды может быть увеличена по согласованию с проектной организацией до 90 °С",
          "Гидравлическое испытание паропровода должно проводиться только при значении температуры стенки 100 °С",
          "При гидравлическом испытании паропровода температура его стенок должна быть не менее 10 °С",
          "Для данных трубопроводов допускается замена гидравлического испытания пневматическим с обязательным контролем проведения этого испытания методом акустической эмиссии",
        ],
        correctAnswers: [
          "При гидравлическом испытании паропровода температура его стенок должна быть не менее 10 °С",
        ],
      },
      {
        code: "68251",
        text: "Какое из приведенных требований должно выполняться при проведении гидравлического испытания трубопровода?",
        answers: [
          "Время выдержки под пробным давлением трубопроводов пара и горячей воды должно быть не менее 5 минут",
          "Допускается использование сжатого воздуха или другого газа для подъема давления в оборудовании, заполненном водой",
          "При заполнении оборудования водой воздух из него должен быть частично удален",
          "Давление воды при гидравлическом испытании следует контролировать не менее чем двумя манометрами. Оба манометра выбирают одного типа, предела измерения, одинаковых классов точности (не ниже 1,5) и цены деления",
        ],
        correctAnswers: [
          "Давление воды при гидравлическом испытании следует контролировать не менее чем двумя манометрами. Оба манометра выбирают одного типа, предела измерения, одинаковых классов точности (не ниже 1,5) и цены деления",
        ],
      },
      {
        code: "68252",
        text: "Чему равно минимальное время выдержки трубопроводов пара и горячей воды под пробным давлением?",
        answers: ["10 минут", "5 минут", "20 минут", "60 минут"],
        correctAnswers: ["10 минут"],
      },
      {
        code: "68253",
        text: "В каком из приведенных случаев трубопровод считается выдержавшим гидравлическое испытание?",
        answers: [
          "Если обнаружены течи в разъемных соединениях",
          "Если обнаружено падение давления по манометру",
          "Если обнаружены видимые остаточные деформации",
          "Во всех приведенных случаях трубопровод не считается выдержавшим гидравлическое испытание",
        ],
        correctAnswers: [
          "Во всех приведенных случаях трубопровод не считается выдержавшим гидравлическое испытание",
        ],
      },
      {
        code: "68254",
        text: "В каких случаях допускается замена ультразвукового и радиографического контроля другими методами неразрушающего контроля?",
        answers: [
          "Если применение других методов неразрушающего контроля согласовано с разработчиком проектной документации",
          "Ни в каких случаях",
          "Если применение других методов неразрушающего контроля согласовано с ответственным за осуществление производственного контроля за эксплуатацией сосудов, работающих под давлением",
          "Если вместо ультразвуковой дефектоскопии и радиографического контроля проводится пневматическое испытание повышенным давлением",
        ],
        correctAnswers: [
          "Если применение других методов неразрушающего контроля согласовано с разработчиком проектной документации",
        ],
      },
      {
        code: "68255",
        text: "Какое из приведенных испытаний не является обязательным видом механических испытаний котлов (трубопроводов)?",
        answers: [
          "Испытание на ударный изгиб",
          "Испытание на статическое растяжение",
          "Испытание на статический изгиб",
          "Испытание на сплющивание",
        ],
        correctAnswers: ["Испытание на ударный изгиб"],
      },
      {
        code: "68256",
        text: "В течение какого времени проводится комплексное опробование котлов, сосудов и трубопроводов пара и горячей воды?",
        answers: [
          "Котлы - в течение 48 часов, трубопроводы пара и горячей воды - в течение 24 часов, время комплексного опробования сосудов устанавливается совместным приказом эксплуатирующей и наладочной организаций",
          "Котлы - в течение 36 часов, трубопроводы пара и горячей воды - в течение 12 часов, время комплексного опробования сосудов устанавливается совместным приказом эксплуатирующей и наладочной организаций",
          "Котлы - в течение 72 часов, трубопроводы тепловых сетей - в течение 24 часов, остальное оборудование - по программе комплексного опробования, разработанной организацией, проводящей соответствующие работы, и согласованной с эксплуатирующей организацией",
        ],
        correctAnswers: [
          "Котлы - в течение 72 часов, трубопроводы тепловых сетей - в течение 24 часов, остальное оборудование - по программе комплексного опробования, разработанной организацией, проводящей соответствующие работы, и согласованной с эксплуатирующей организацией",
        ],
      },
      {
        code: "68257",
        text: "Какая процедура из указанных при холодном натяге трубопроводов проводится только в случае ее необходимости?",
        answers: [
          "Окончательное закрепление неподвижных опор на концах участка, подлежащего холодному натягу",
          "Термическая обработка сварных соединений",
          "Выполнение всех сварных соединений, за исключением замыкающего",
          "Контроль качества сварных соединений, расположенных по всей длине участка, на котором необходимо произвести холодный натяг",
        ],
        correctAnswers: ["Термическая обработка сварных соединений"],
      },
      {
        code: "68258",
        text: "Где должны быть установлены методы и объемы контроля сварных соединений приварных деталей, не работающих под внутренним давлением?",
        answers: [
          "В паспорте оборудования, работающего под давлением",
          "В руководстве (инструкции) по эксплуатации оборудования, работающего под давлением",
          "Сварные соединения приварных деталей, не работающих под внутренним давлением, не подлежат контролю",
          "В технологической документации",
        ],
        correctAnswers: ["В технологической документации"],
      },
      {
        code: "68259",
        text: "В каких целях проводится магнитопорошковый контроль сварных соединений?",
        answers: [
          "Для определения поверхностных и подповерхностных дефектов в стальных ферромагнитных конструкциях и деталях",
          "Для проверки качества выполнения термической обработки сварных соединений",
          "Для подтверждения соответствия легирования металла сварных швов и элементов оборудования",
        ],
        correctAnswers: [
          "Для определения поверхностных и подповерхностных дефектов в стальных ферромагнитных конструкциях и деталях",
        ],
      },
      {
        code: "68260",
        text: "Каково минимальное значение температуры воды, используемой для гидравлического испытания трубопровода (если конкретное значение не указано в технической документации организации-изготовителя)?",
        answers: ["40 °С", "5 °C", "20 °С", "15 °С"],
        correctAnswers: ["5 °C"],
      },
      {
        code: "68261",
        text: "Какое из приведенных требований должно выполняться при проведении визуального и измерительного контроля?",
        answers: [
          "Поверхностные дефекты, выявленные при визуальном и измерительном контроле, должны быть исправлены до проведения контроля другими неразрушающими методами",
          "Перед визуальным контролем поверхности сварного шва и прилегающих к нему поверхностей, участки основного металла шириной не менее 5 мм в обе стороны от шва должны быть зачищены от шлака и других загрязнений",
          "Визуальный и измерительный контроль сварных соединений должен быть проведен только с наружной стороны по всей длине швов",
        ],
        correctAnswers: [
          "Поверхностные дефекты, выявленные при визуальном и измерительном контроле, должны быть исправлены до проведения контроля другими неразрушающими методами",
        ],
      },
      {
        code: "68262",
        text: "В какой документации указывается необходимость проведения и объем ультразвукового и радиографического контроля, типы и размеры несплошностей (дефектов), подлежащих обнаружению, для конкретного вида (типа) конструкции оборудования под давлением и сварного соединения?",
        answers: [
          "В руководстве (инструкции) по эксплуатации",
          "В проектной (конструкторской) и технологической документации",
          "В паспорте оборудования",
        ],
        correctAnswers: [
          "В проектной (конструкторской) и технологической документации",
        ],
      },
      {
        code: "68263",
        text: "При проведении гидравлического испытания трубопровода каким давлением проводится испытание сосудов, являющихся неотъемлемой частью трубопровода и не имеющих запорных органов?",
        answers: [
          "Пробное давление при гидравлическом испытании трубопровода определяется так же, как и для сосудов, работающих под давлением",
          "Пробное давление увеличивают на 20 %",
          "Испытывают тем же давлением, что и трубопроводы",
        ],
        correctAnswers: ["Испытывают тем же давлением, что и трубопроводы"],
      },
      {
        code: "68264",
        text: "Каким образом допускается маркировать сварное соединение, выполненное несколькими сварщиками (бригадой сварщиков)?",
        answers: [
          "Клеймом одного из сварщиков бригады, определенного бригадиром",
          "Клеймами всех сварщиков, участвовавших в процессе сварки",
          "Клеймом, определенным документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
          "Клеймом одного сварщика, выполнившего наибольший объем работ",
        ],
        correctAnswers: [
          "Клеймом, определенным документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
        ],
      },
      {
        code: "68265",
        text: "Какие функции обязано выполнить лицо, осуществляющее руководство сварочными работами, назначенное распорядительным документом организации или (и) должностной инструкцией которого предусмотрено руководство сварочными работами, перед выполнением сварочных работ?",
        answers: [
          "Проверить и обеспечить соответствие численного состава и квалификации персонала сварочного производства, сборочного и сварочного оборудования, основных и сварочных материалов, применяемой технологии сварки требованиям ПТД",
          "Ознакомить сварщиков с требованиями технологических карт сварки, а также с внесенными в них изменениями (при наличии), с подтверждением ознакомления подписями сварщиков в применяемых ими технологических картах сварки",
          "Организовать проведение операционного контроля",
          "Все перечисленные функции",
        ],
        correctAnswers: ["Все перечисленные функции"],
      },
      {
        code: "68266",
        text: 'Какие требования предъявляются ФНП "Требования к производству сварочных работ на опасных производственных объектах" к сварочному оборудованию и сварочным материалам, применяемым при выполнении сварочных работ?',
        answers: [
          "Сварочное оборудование и материалы должны быть отечественного производства",
          "Оборудование и материалы должны обеспечивать высокую производительность работ",
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
        ],
        correctAnswers: [
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "68267",
        text: "Какие действия должны быть осуществлены при выполнении многопроходных швов после наложения каждого валика поверхности шва и кромки разделки?",
        answers: [
          "Зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)",
          "Обезжирен ацетоном каждый шов, проведен рентген",
          "Проведена ультразвуковая дефектоскопия, промывание водой",
        ],
        correctAnswers: [
          "Зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)",
        ],
      },
      {
        code: "68268",
        text: "Какая документация оформляется в процессе выполнения сварочных работ?",
        answers: [
          "Только документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
          "Только эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю)",
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
        ],
        correctAnswers: [
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "68269",
        text: "Кто допускается к выполнению сварочных работ на опасном производственном объекте?",
        answers: [
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
          "Сварщики и специалисты сварочного производства не моложе 18 лет, имеющие группу по электробезопасности не ниже II и прошедшие обучение мерам пожарной безопасности в объеме пожарно-технического минимума",
          "Любой работник при отсутствии противопоказаний по данному виду работ, обладающий необходимыми навыками",
        ],
        correctAnswers: [
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
        ],
      },
      {
        code: "68270",
        text: "К выполнению каких работ могут быть допущены сварщики и специалисты сварочного производства?",
        answers: [
          "К сварочным работам, указанным в действующих документах",
          "Ко всем сварочным работам при наличии действующего аттестационного удостоверения по одному из способов сварки",
          "К сварочным работам, по виду которых есть опыт производства и проведен целевой инструктаж",
        ],
        correctAnswers: [
          "К сварочным работам, указанным в действующих документах",
        ],
      },
      {
        code: "68271",
        text: "Что должно быть указано в технологических картах сварки?",
        answers: [
          "Графические изображения элементов сварных соединений с указанием размеров и допусков, характеристик применяемых материалов и оборудования",
          "Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений",
          "Режимы выполнения сборки и сварки, нормы оценки качества сварных соединений",
          "Требования к сварочным материалам и сварочному оборудованию",
        ],
        correctAnswers: [
          "Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений",
        ],
      },
      {
        code: "68272",
        text: "Какую проверку должен пройти сварщик, впервые приступающий к сварке, перед допуском к работе?",
        answers: [
          "Проверку путем выполнения и контроля допускного сварного соединения",
          'Проверку знаний ФНП "Требования к производству сварочных работ на опасных производственных объектах"',
          "Проверку знаний конструкции допускных сварных соединений, методов и объема их контроля качества",
        ],
        correctAnswers: [
          "Проверку путем выполнения и контроля допускного сварного соединения",
        ],
      },
    ],
    701: [
      {
        code: "68272",
        text: "При осуществлении каких процессов на ОПО не применяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?",
        answers: [
          "Проектирование ОПО, на которых используются водогрейные котлы",
          "Техническое обслуживание цистерн и бочек для сжатых и сжиженных газов",
          "Капитальный ремонт трубопроводов пара и горячей воды",
          "Проектирование и конструирование сосудов, работающих под давлением",
        ],
        correctAnswers: [
          "Проектирование и конструирование сосудов, работающих под давлением",
        ],
      },
      {
        code: "68273",
        text: "При осуществлении каких процессов на ОПО не применяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?",
        answers: [
          "Изготовление сосуда, работающего под давлением",
          "Разработка проектной документации, определяющей места установки сосудов, работающих под давлением",
          "Ремонт цистерн и бочек для сжатых и сжиженных газов",
          "Техническое перевооружение оборудования, включающего сосуды, работающие под давлением",
        ],
        correctAnswers: ["Изготовление сосуда, работающего под давлением"],
      },
      {
        code: "68274",
        text: "На какой из приведенных сосудов не распространяется действие ФНП ОРПД?",
        answers: [
          "Электрокотел вместимостью 250 литров",
          "Сосуд, объем которого составляет 25 литров, работающий под давлением среды, равным 0,8 МПа",
          "Цистерна для сжиженных газов",
          "Сосуд вместимостью 60 литров, работающий под давлением 0,5 МПа, установленный на плавучей буровой установке",
        ],
        correctAnswers: [
          "Сосуд, объем которого составляет 25 литров, работающий под давлением среды, равным 0,8 МПа",
        ],
      },
      {
        code: "68275",
        text: "На какой из приведенных сосудов, работающих под давлением свыше 0,07 МПа, распространяется действие ФНП ОРПД?",
        answers: [
          "Медицинские стерилизаторы",
          "Отопительный прибор системы водяного отопления",
          "Сосуд, установленный на плавучей драге",
          "Сосуд, работающий под вакуумом",
        ],
        correctAnswers: ["Сосуд, установленный на плавучей драге"],
      },
      {
        code: "68276",
        text: "Кто принимает решение о вводе в эксплуатацию сосуда, работающего под давлением?",
        answers: [
          "Уполномоченный представитель федерального органа исполнительной власти в области промышленной безопасности",
          "Уполномоченный представитель монтажной или ремонтной организации",
          "Ответственный за осуществление производственного контроля",
          "Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения)",
        ],
        correctAnswers: [
          "Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения)",
        ],
      },
      {
        code: "68277",
        text: "На каком основании принимается решение о вводе в эксплуатацию сосуда, работающего под давлением?",
        answers: [
          "На основании результатов проверки готовности сосуда к пуску в работу и проверки организации надзора за эксплуатацией сосуда",
          "На основании результатов диагностирования сосуда, проверки организации технического обслуживания сосуда и надзора за его работой",
          "На основании результатов проведения пусконаладочных испытаний сосуда, работающего под давлением",
          "На основании результатов гидравлических испытаний, проводимых монтажной организацией",
        ],
        correctAnswers: [
          "На основании результатов проверки готовности сосуда к пуску в работу и проверки организации надзора за эксплуатацией сосуда",
        ],
      },
      {
        code: "68278",
        text: "В каком случае проверки готовности сосуда к пуску в работу и организации надзора за эксплуатацией сосуда проводятся ответственными лицами или комиссией с их участием?",
        answers: [
          "При передаче сосуда под давлением для использования другой эксплуатирующей организации",
          "После монтажа без применения сварных соединений сосуда, поставленного на объект эксплуатации в собранном виде",
          "После проведения ремонта сосуда под давлением без замены патрубков, штуцеров сосуда, неразъемно присоединенных к его корпусу",
          "После монтажа сосуда, для которого не предусмотрена процедура подтверждения соответствия требованиям ТР ТС 032/2013",
        ],
        correctAnswers: [
          "После монтажа без применения сварных соединений сосуда, поставленного на объект эксплуатации в собранном виде",
        ],
      },
      {
        code: "68279",
        text: "В каком случае проверки готовности сосуда к пуску в работу и организации надзора за эксплуатацией сосуда проводятся только комиссией, назначаемой приказом эксплуатирующей организации?",
        answers: [
          "До начала применения сосуда под давлением, поставляемого изготовителем в собранном виде",
          "После монтажа оборудования, перевезенного и установленного на новом месте, без применения сварки",
          "После монтажа сосуда, поставляемого отдельными блоками, окончательную сборку которого с применением сварных соединений производят при монтаже на месте его эксплуатации",
          "После монтажа сосуда под давлением, для которого предусмотрена процедура подтверждения соответствия требованиям ТР ТС 032/2013",
        ],
        correctAnswers: [
          "После монтажа сосуда, поставляемого отдельными блоками, окончательную сборку которого с применением сварных соединений производят при монтаже на месте его эксплуатации",
        ],
      },
      {
        code: "68280",
        text: "В каком случае в состав комиссии по проверке готовности сосуда к пуску в работу и организации надзора за его эксплуатацией включаются уполномоченный (уполномоченные) представитель (представители) Ростехнадзора или его территориального органа?",
        answers: [
          "При проведении проверки после монтажа сосудов, работающих под давлением, поставляемых изготовителем в собранном виде",
          "При получении соответствующего запроса организации, выполняющей монтаж оборудования на новом месте",
          "При осуществлении проверок сосудов, подлежащих учету в территориальных органах Ростехнадзора",
          "Участие уполномоченного представителя Ростехнадзора в комиссии, назначаемой приказом эксплуатирующей организации, федеральными нормами не предусматривается",
        ],
        correctAnswers: [
          "При осуществлении проверок сосудов, подлежащих учету в территориальных органах Ростехнадзора",
        ],
      },
      {
        code: "68281",
        text: "Что контролируется при проведении проверки готовности сосуда к пуску в работу?",
        answers: [
          "Наличие у организации, осуществляющей монтаж сосуда под давлением, соответствующих лицензий на выполнение работ",
          "Соответствие производственного помещения, в котором устанавливается сосуд под давлением, требованиям документации организации-изготовителя",
          "Наличие положительных результатов технического освидетельствования сосуда",
          "Наличие подтверждающих документов о прохождении дополнительного профессионального обучения ответственными лицами и специалистами, осуществляющими эксплуатацию сосуда",
        ],
        correctAnswers: [
          "Наличие положительных результатов технического освидетельствования сосуда",
        ],
      },
      {
        code: "68282",
        text: "Каким образом должны оформляться результаты проверок готовности сосуда к пуску в работу и организации надзора за его эксплуатацией?",
        answers: [
          "Актом проведения проверки, составляемым уполномоченным представителем Ростехнадзора",
          "Актом готовности сосуда к вводу в эксплуатацию",
          "Внесением соответствующей записи в паспорт сосуда",
          "Протоколом, который является основанием для ввода сосуда в эксплуатацию (протокол хранится у ответственного за безопасную эксплуатацию сосуда)",
        ],
        correctAnswers: ["Актом готовности сосуда к вводу в эксплуатацию"],
      },
      {
        code: "68283",
        text: "На какой период руководителем эксплуатирующей организации может быть принято решение о возможности эксплуатации сосуда в режиме опытного применения?",
        answers: [
          "Не более 3 месяцев с возможностью дальнейшего продления срока",
          "Период эксплуатации сосуда в режиме опытного применения устанавливается эксплуатирующей организацией самостоятельно, но не более чем на 2 года",
          "Не более 6 месяцев",
          "Эксплуатация сосуда в режиме опытного применения на ОПО не допускается",
        ],
        correctAnswers: ["Не более 6 месяцев"],
      },
      {
        code: "68284",
        text: "На основании какого документа осуществляется пуск (включение) в работу и штатная остановка сосуда?",
        answers: [
          "На основании письменного распоряжения руководителя подразделения, на территории которого располагается оборудование",
          "На основании приказа руководителя предприятия, эксплуатирующего сосуд под давлением",
          "На основании письменного распоряжения ответственного за исправное состояние и безопасную эксплуатацию сосуда",
        ],
        correctAnswers: [
          "На основании письменного распоряжения ответственного за исправное состояние и безопасную эксплуатацию сосуда",
        ],
      },
      {
        code: "68285",
        text: "Что из приведенного не указывается на табличке или не наносится на сосудах (кроме транспортируемых баллонов вместимостью до 100 литров) перед пуском их в работу?",
        answers: [
          "Дата ввода в эксплуатацию",
          "Учетный номер, присвоенный территориальным органом Ростехнадзора",
          "Разрешенные параметры давления и температуры",
          "Дата следующего гидравлического испытания",
        ],
        correctAnswers: ["Дата ввода в эксплуатацию"],
      },
      {
        code: "68286",
        text: "Каким образом осуществляется учет транспортируемых сосудов (цистерн) в территориальных органах Ростехнадзора?",
        answers: [
          "Информация о фактическом адресе эксплуатации транспортируемых сосудов (цистерн) подается в территориальные органы Ростехнадзора в уведомительном порядке в срок, не превышающий 10 рабочих дней после даты принятия решения об их вводе в эксплуатацию и пуска (включения) их в работу",
          "Цистерны подлежат учету в органе Ростехнадзора по месту нахождения площадки (при наличии) эксплуатирующей организации, на которой производятся работы по ремонту, техническому обслуживанию и освидетельствованию этих цистерн",
          "Транспортируемые сосуды (цистерны) подлежат регистрации в территориальном органе Ростехнадзора по месту нахождения (регистрации) эксплуатирующей организации в срок, не превышающий 15 рабочих дней после даты принятия решения об их вводе в эксплуатацию и пуска (включения) их в работу",
          "Транспортируемые сосуды не подлежат учету в территориальных органах Ростехнадзора",
        ],
        correctAnswers: [
          "Цистерны подлежат учету в органе Ростехнадзора по месту нахождения площадки (при наличии) эксплуатирующей организации, на которой производятся работы по ремонту, техническому обслуживанию и освидетельствованию этих цистерн",
        ],
      },
      {
        code: "68287",
        text: "Какой из приведенных сосудов подлежит учету в территориальных органах Ростехнадзора?",
        answers: [
          "Регенератор азотной установки, расположенный внутри теплоизоляционного кожуха",
          "Баллон со сжиженным газом вместимостью 100 литров, установленный на транспортном средстве для обеспечения топливом его двигателя",
          "Воздушный ресивер вместимостью 550 литров, работающий с давлением 2,0 МПа",
          "Одноместные медицинские барокамеры",
        ],
        correctAnswers: [
          "Воздушный ресивер вместимостью 550 литров, работающий с давлением 2,0 МПа",
        ],
      },
      {
        code: "68288",
        text: "Какой из приведенных сосудов не подлежит учету в территориальных органах Ростехнадзора?",
        answers: [
          "Сосуд, работающий со средой 2-й группы (согласно ТР ТС 032/2013) при температуре стенки не выше 200 °С, у которого произведение давления (МПа) на вместимость (м3 ) равно 2",
          "Трубопровод пара эксплуатационной категории Iэ с внутренним диаметром 90 мм",
          "Стационарно установленная бочка для перевозки сжиженных газов вместимостью 120 л",
          "Сосуд вместимостью 36 м3 и с давлением 0,1 МПа, установленный в подземной горной выработке",
        ],
        correctAnswers: [
          "Сосуд вместимостью 36 м3 и с давлением 0,1 МПа, установленный в подземной горной выработке",
        ],
      },
      {
        code: "68289",
        text: "Какая документация не представляется эксплуатирующей организацией в орган Ростехнадзора для постановки на учет сосуда, проверка готовности к вводу в эксплуатацию которого проводилась без участия уполномоченного представителя Ростехнадзора?",
        answers: [
          "Сведения о дате и результатах проведения технического освидетельствования или экспертизы промышленной безопасности и сроках следующего технического освидетельствования и/или сроке безопасной эксплуатации (при наличии), указанном в выводах заключения экспертизы",
          "Паспорт сосуда, удостоверение о качестве монтажа, инструкция изготовителя по монтажу и эксплуатации сосуда",
          "Копии акта готовности сосуда к вводу в эксплуатацию и приказа (распорядительного документа) о вводе его в эксплуатацию",
          "Заявление, содержащее информацию об эксплуатирующей организации с указанием места установки стационарного сосуда",
        ],
        correctAnswers: [
          "Паспорт сосуда, удостоверение о качестве монтажа, инструкция изготовителя по монтажу и эксплуатации сосуда",
        ],
      },
      {
        code: "68290",
        text: "В каком из приведенных случаев допускается одному специалисту совмещать ответственность за осуществление производственного контроля за безопасной эксплуатацией сосудов и ответственность за их исправное состояние и безопасную эксплуатацию?",
        answers: [
          "Если совмещение оформлено распорядительным документом руководителя эксплуатирующей организации",
          "Если совмещение осуществляется на опасных производственных объектах 3 или 4 класса опасности",
          "Если совмещение согласовано с территориальным органом Ростехнадзора",
          "Совмещение не допускается",
        ],
        correctAnswers: ["Совмещение не допускается"],
      },
      {
        code: "68291",
        text: "Какое требование к специалистам и рабочим, обслуживающим сосуды, указано неверно?",
        answers: [
          "Специалисты и рабочие должны быть не моложе 18-летнего возраста и не должны иметь медицинских противопоказаний для выполнения работ по обслуживанию сосудов",
          "Рабочие должны пройти аттестацию по промышленной безопасности в аттестационной комиссии эксплуатирующей организации",
          "Специалисты и рабочие должны быть допущены в установленном распорядительными документами организации порядке к самостоятельной работе",
          "Специалисты должны соответствовать квалификационным требованиям",
        ],
        correctAnswers: [
          "Рабочие должны пройти аттестацию по промышленной безопасности в аттестационной комиссии эксплуатирующей организации",
        ],
      },
      {
        code: "68292",
        text: "Какие инструкции не разрабатываются в организации, эксплуатирующей сосуды?",
        answers: [
          "Инструкция для работника, ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением",
          "Инструкция для работников, ответственных за обслуживание контрольно-измерительных приборов, предохранительных и блокировочных устройств, средств сигнализации и защиты",
          "Производственная инструкция для персонала, осуществляющего обслуживание и ремонт оборудования под давлением",
          "Инструкция (руководство) по эксплуатации сосуда",
        ],
        correctAnswers: ["Инструкция (руководство) по эксплуатации сосуда"],
      },
      {
        code: "68293",
        text: "Что из приведенного не входит в должностные обязанности ответственного за осуществление производственного контроля за безопасной эксплуатацией сосудов?",
        answers: [
          "Проверка соблюдения порядка допуска рабочих к самостоятельной работе",
          "Проверка записи в сменном журнале с росписью в нем",
          "Контроль проведения противоаварийных тренировок",
          "Участие в освидетельствовании оборудования под давлением специализированной организацией",
        ],
        correctAnswers: ["Проверка записи в сменном журнале с росписью в нем"],
      },
      {
        code: "68294",
        text: "Что из приведенного не входит в должностные обязанности ответственного за исправное состояние и безопасную эксплуатацию сосудов?",
        answers: [
          "Осуществление контроля за соблюдением требований Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, и законодательства Российской Федерации в области промышленной безопасности при эксплуатации оборудования под давлением; при выявлении нарушений требований промышленной безопасности выдача обязательных для исполнения предписаний по устранению нарушений и контроль их выполнения",
          "Проверка записей персонала в сменном (оперативном) журнале, журнале дефектов и иных эксплуатационных документах",
          "Обеспечение соблюдения безопасных условий и режимов эксплуатации оборудования под давлением",
          "Подготовка оборудования под давлением к техническому освидетельствованию",
        ],
        correctAnswers: [
          "Осуществление контроля за соблюдением требований Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, и законодательства Российской Федерации в области промышленной безопасности при эксплуатации оборудования под давлением; при выявлении нарушений требований промышленной безопасности выдача обязательных для исполнения предписаний по устранению нарушений и контроль их выполнения",
        ],
      },
      {
        code: "68295",
        text: "С какой периодичностью проводится проверка знаний рабочих, обслуживающих сосуды?",
        answers: [
          "Периодичность устанавливается руководителем эксплуатирующей организации",
          "Один раз в 12 месяцев",
          "Один раз в 6 месяцев",
          "Один раз в 5 лет",
        ],
        correctAnswers: ["Один раз в 12 месяцев"],
      },
      {
        code: "68296",
        text: "Какое из приведенных требований к проверке знаний рабочих, обслуживающих сосуды, указано неверно?",
        answers: [
          "Периодическую проверку знаний персонала (рабочих) необходимо проводить в случае перевода рабочих на обслуживание оборудования другого типа",
          "Участие представителя Ростехнадзора обязательно при проведении первичной аттестации рабочих, обслуживающих сосуды с быстросъемными крышками",
          "Комиссия по проверке знаний обслуживающего и ремонтного персонала (рабочих и специалистов) должна быть назначена распорядительным документом эксплуатирующей организации",
          "Протокол должен быть подписан всеми членами комиссии, проводившими проверку знаний, и должен содержать сведения о проходившем ее работнике в объеме, достаточном для его идентификации",
        ],
        correctAnswers: [
          "Участие представителя Ростехнадзора обязательно при проведении первичной аттестации рабочих, обслуживающих сосуды с быстросъемными крышками",
        ],
      },
      {
        code: "68297",
        text: "В каком из приведенных случаев до проверки знаний рабочий, обслуживающий сосуды, должен пройти стажировку?",
        answers: [
          "Перед первичным допуском к самостоятельной работе после профессионального обучения",
          "Перед первичным допуском к самостоятельной работе после внеочередной проверки знаний",
          "При перерыве в работе по специальности более 12 месяцев",
          "Стажировка проводится во всех приведенных случаях",
        ],
        correctAnswers: ["Стажировка проводится во всех приведенных случаях"],
      },
      {
        code: "68298",
        text: "Что из приведенного, в соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, не регламентируется производственной инструкцией по режиму работы и безопасному обслуживанию сосудов?",
        answers: [
          "Порядок, сроки и способы проверки арматуры, предохранительных устройств, приборов автоматики защиты и сигнализации",
          "Меры безопасности при выводе оборудования в ремонт, а также дополнительные меры безопасности для сосудов с рабочей средой группы 1 (в соответствии с TP ТС 032/2013)",
          "Действия персонала и меры безопасности при подготовке сосуда к техническому освидетельствованию",
          "Порядок пуска в работу и остановки (прекращения работы) сосуда",
        ],
        correctAnswers: [
          "Действия персонала и меры безопасности при подготовке сосуда к техническому освидетельствованию",
        ],
      },
      {
        code: "68299",
        text: "Что из приведенного, в соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, не регламентируется производственной инструкцией по режиму работы и безопасному обслуживанию сосудов?",
        answers: [
          "Порядок проверки исправности обслуживаемых сосудов и относящегося к ним оборудования в рабочем состоянии",
          "Порядок действия персонала в случае аварии или инцидента",
          "Порядок пуска в работу и остановки (прекращения работы) сосуда",
          "Порядок проведения технического освидетельствования сосудов, не подлежащих учету в территориальном органе Ростехнадзора",
        ],
        correctAnswers: [
          "Порядок проведения технического освидетельствования сосудов, не подлежащих учету в территориальном органе Ростехнадзора",
        ],
      },
      {
        code: "68300",
        text: "Какие указания должны быть дополнительно включены в производственные инструкции по режиму работы и безопасному обслуживанию автоклавов с быстросъемными крышками?",
        answers: [
          "Порядок пользования ключ-маркой и замком",
          "Допустимые скорости прогрева и охлаждения автоклава и методы их контроля",
          "Порядок наблюдения за тепловыми перемещениями автоклава",
          "Все приведенные указания должны включаться в производственную инструкцию",
        ],
        correctAnswers: [
          "Все приведенные указания должны включаться в производственную инструкцию",
        ],
      },
      {
        code: "68301",
        text: "Что необходимо обеспечить при эксплуатации сосудов, обогреваемых горячими газами?",
        answers: [
          "Наличие около оборудования теплозащитных экранов",
          "Температуру наружной поверхности оборудования не более 60 °С и температуру окружающей среды не более 25 °С",
          "Надежное охлаждение стенок, находящихся под давлением, не допуская превышение температуры стенки выше допустимых значений",
        ],
        correctAnswers: [
          "Надежное охлаждение стенок, находящихся под давлением, не допуская превышение температуры стенки выше допустимых значений",
        ],
      },
      {
        code: "68302",
        text: "Манометры какого класса точности необходимо применять при эксплуатации сосудов с рабочим давлением до 2,5 МПа включительно?",
        answers: ["Не ниже 1,5", "Не ниже 2,5", "Не ниже 0,25", "Не ниже 1,0"],
        correctAnswers: ["Не ниже 2,5"],
      },
      {
        code: "68303",
        text: "Манометры какого класса точности необходимо применять при эксплуатации сосудов с рабочим давлением более 2,5 МПа?",
        answers: ["Не ниже 1,0", "Не ниже 2,5", "Не ниже 1,5", "Не ниже 0,4"],
        correctAnswers: ["Не ниже 1,5"],
      },
      {
        code: "68304",
        text: "Какое из приведенных требований к манометрам, устанавливаемым на сосудах, указано неверно?",
        answers: [
          "На шкале манометра сосуда должна быть нанесена красная черта, указывающая разрешенное давление в сосуде",
          "Взамен красной черты разрешается прикреплять к корпусу манометра металлическую пластину красного цвета, плотно прилегающую к стеклу манометра",
          "Манометр должен быть подобран с такой шкалой, чтобы предел измерения рабочего давления находился во второй трети шкалы",
        ],
        correctAnswers: [
          "На шкале манометра сосуда должна быть нанесена красная черта, указывающая разрешенное давление в сосуде",
        ],
      },
      {
        code: "68305",
        text: "Каково минимальное значение номинального диаметра манометра, устанавливаемого на сосуде на высоте менее 2 м от уровня площадки наблюдения?",
        answers: ["60 мм", "100 мм", "150 мм", "250 мм"],
        correctAnswers: ["100 мм"],
      },
      {
        code: "68306",
        text: "Каково минимальное значение номинального диаметра манометра, устанавливаемого на сосуде на высоте от 2 до 3 м включительно от уровня площадки наблюдения?",
        answers: ["600 мм", "100 мм", "160 мм", "250 мм"],
        correctAnswers: ["160 мм"],
      },
      {
        code: "68307",
        text: "Каково минимальное значение номинального диаметра манометра, устанавливаемого на сосуде на высоте более 3 м от уровня площадки наблюдения?",
        answers: [
          "150 мм",
          "300 мм",
          "250 мм",
          "Установка манометра на такой высоте не разрешается",
        ],
        correctAnswers: ["Установка манометра на такой высоте не разрешается"],
      },
      {
        code: "68308",
        text: "Для какого из приведенных сосудов допускается установка вместо трехходового крана отдельного штуцера с запорным устройством для подсоединения второго манометра?",
        answers: [
          "Сосуд, работающий под давлением азота, равным 1,0 МПа",
          "Сосуд, работающий под давлением  дистиллированной воды с температурой 70 °С",
          "Сосуд, работающий под давлением сжиженного углеводородного газа, равным 1,5 МПа",
          "Сосуд, работающий под давлением гелия, равным 1,5 МПа",
        ],
        correctAnswers: [
          "Сосуд, работающий под давлением сжиженного углеводородного газа, равным 1,5 МПа",
        ],
      },
      {
        code: "68309",
        text: "Для какого из приведенных сосудов необязательна установка трехходового крана или заменяющего его устройства между манометром и сосудом?",
        answers: [
          "Для сосудов, заполненных сжиженными газами",
          "Для сосудов, работающих со средой, температура которой менее 60 °С",
          "Для сосудов, у которых имеется возможность проверки манометра путем снятия его с места установки",
          "Для сосудов, рабочий объем которых составляет более 200 м3",
        ],
        correctAnswers: [
          "Для сосудов, у которых имеется возможность проверки манометра путем снятия его с места установки",
        ],
      },
      {
        code: "68310",
        text: "В каком из приведенных случаев манометр может быть допущен к применению на сосуде?",
        answers: [
          "Если отсутствует документ о проведении поверки манометра",
          "Если стрелка манометра при его отключении не возвращается к нулевой отметке шкалы на величину, не превышающую половины допускаемой погрешности для манометра",
          "Если истек срок поверки манометра, но не более чем на 30 дней",
          "Если разбитое стекло не мешает перемещению стрелки по шкале",
        ],
        correctAnswers: [
          "Если стрелка манометра при его отключении не возвращается к нулевой отметке шкалы на величину, не превышающую половины допускаемой погрешности для манометра",
        ],
      },
      {
        code: "68311",
        text: "Какое требование к проверке исправности манометра, установленного на сосуде, указано неверно?",
        answers: [
          "Обслуживающий персонал должен производить проверку исправности манометра с помощью трехходового крана или заменяющих его запорных вентилей путем установки стрелки манометра на нуль",
          "Эксплуатирующая организация обязана не реже одного раза в 6 месяцев проводить проверку рабочих манометров контрольным манометром или рабочим манометром, имеющим одинаковые с проверяемым манометром шкалу и класс точности",
          "Поверка манометров с их опломбированием или клеймением должна производится не реже одного раза в 12 месяцев (в случае отсутствия иных сроков в документации на манометр)",
          "Порядок и сроки проверки исправности манометров обслуживающим персоналом в процессе эксплуатации сосудов должны быть определены производственной инструкцией по режиму работы и безопасному обслуживанию сосудов",
        ],
        correctAnswers: [
          "Эксплуатирующая организация обязана не реже одного раза в 6 месяцев проводить проверку рабочих манометров контрольным манометром или рабочим манометром, имеющим одинаковые с проверяемым манометром шкалу и класс точности",
        ],
      },
      {
        code: "68312",
        text: "Каким образом должен осуществляться контроль исправности пружинного предохранительного клапана, если принудительное его открывание недопустимо по условиям технологического процесса?",
        answers: [
          "Исправность пружинного предохранительного клапана контролируется путем снятия его с технологического оборудования и проведения подрывных испытаний в условиях специализированной лаборатории",
          "Исправность пружинного предохранительного клапана контролируется при плановом техническом обслуживании предохранительной арматуры путем замены элементов, подвергаемых нагрузкам",
          "Исправность пружинного предохранительного клапана контролируется путем проверки его срабатывания на стендах",
          "Исправность пружинного предохранительного клапана контролируется путем использования резервного клапана на время осмотра действующего на предмет наличия коррозии или удлинения пружины",
        ],
        correctAnswers: [
          "Исправность пружинного предохранительного клапана контролируется путем проверки его срабатывания на стендах",
        ],
      },
      {
        code: "68313",
        text: "На каком сосуде установка манометра и предохранительного клапана необязательна?",
        answers: [
          "На сосуде, в котором давление рабочей среды не превышает 0,5 МПа",
          "На сосуде, включенном в технологический процесс, в котором поддерживается стабильное давление рабочей среды путем применения редуцирующих и компенсирующих устройств",
          "На сосуде, у которого рабочее давление равно или больше давления питающего источника и при условии исключения возможности повышения давления в сосуде",
          "Наличие манометра и предохранительных устройств обязательно для всех сосудов под давлением",
        ],
        correctAnswers: [
          "На сосуде, у которого рабочее давление равно или больше давления питающего источника и при условии исключения возможности повышения давления в сосуде",
        ],
      },
      {
        code: "68314",
        text: "Какое из приведенных требований к оснащению сосуда, рассчитанного на давление меньше давления питающего его источника, указано неверно?",
        answers: [
          "На подводящем трубопроводе сосуда, рассчитанного на давление меньше давления питающего его источника, необходимо устанавливать редуцирующее устройство, манометр и предохранительный клапан, установленные на стороне меньшего давления после редуцирующего устройства",
          "На подводящем трубопроводе для группы сосудов,  работающих при одном и том же давлении, допускается устанавливать редуцирующее устройство и манометр до первого ответвления к одному из сосудов",
          "На подводящем трубопроводе, включая ответвления от общего трубопровода к каждому сосуду и байпасные линии, должны устанавливаться регуляторы расхода и предохранительные клапаны, отрегулированные на рабочие параметры сосудов",
        ],
        correctAnswers: [
          "На подводящем трубопроводе, включая ответвления от общего трубопровода к каждому сосуду и байпасные линии, должны устанавливаться регуляторы расхода и предохранительные клапаны, отрегулированные на рабочие параметры сосудов",
        ],
      },
      {
        code: "68315",
        text: "Каково максимально допустимое значение давления при работающих предохранительных клапанах в сосуде с давлением менее 0,3 МПа?",
        answers: [
          "Превышающее разрешенное давление не более чем на 0,05 МПа",
          "1,15 разрешенного давления",
          "1,1 разрешенного давления",
          "1,25 разрешенного давления",
        ],
        correctAnswers: [
          "Превышающее разрешенное давление не более чем на 0,05 МПа",
        ],
      },
      {
        code: "68316",
        text: "Каково максимально допустимое значение давления при работающих предохранительных клапанах в сосуде с давлением от 0,3 до 6 МПа включительно?",
        answers: [
          "Превышающее разрешенное давление не более чем на 0,05 МПа",
          "Превышающее разрешенное давление не более чем на 15 %",
          "1,1 разрешенного давления",
          "1,25 разрешенного давления",
        ],
        correctAnswers: [
          "Превышающее разрешенное давление не более чем на 15 %",
        ],
      },
      {
        code: "68317",
        text: "Каково максимально допустимое значение давления при работающих предохранительных клапанах в сосуде с давлением более 6 МПа?",
        answers: [
          "Давление, превышающее разрешенное  не более чем в 1,5 раза",
          "Давление, превышающее разрешенное не более чем на 15 %",
          "Давление, превышающее разрешенное не более чем на 10 %",
          "Давление, превышающее разрешенное  не более чем на 0,05 МПа",
        ],
        correctAnswers: [
          "Давление, превышающее разрешенное не более чем на 10 %",
        ],
      },
      {
        code: "68318",
        text: "Какое требование необходимо выполнять при установке на одном патрубке (трубопроводе) нескольких предохранительных клапанов?",
        answers: [
          "Площадь поперечного сечения патрубка (трубопровода) должна быть не менее 1,25 суммарной площади сечения клапанов, установленных на нем",
          "Площадь поперечного сечения патрубка (трубопровода) должна быть не менее суммарной площади сечения клапанов, установленных на нем, с учетом коэффициента плотности рабочей среды",
          "Установка нескольких предохранительных клапанов на одном патрубке (трубопроводе) возможна только при наличии на нем компенсирующего устройства, установленного после клапанов",
        ],
        correctAnswers: [
          "Площадь поперечного сечения патрубка (трубопровода) должна быть не менее 1,25 суммарной площади сечения клапанов, установленных на нем",
        ],
      },
      {
        code: "68319",
        text: "Какое из приведенных требований к организации отвода токсичных, взрыво- и пожароопасных технологических сред, выходящих из предохранительных устройств, указано неверно?",
        answers: [
          "Токсичные, взрыво- и пожароопасные технологические среды, выходящие из предохранительных устройств, должны направляться в закрытые системы для дальнейшей утилизации или в системы организованного сжигания",
          "В обоснованных проектной документацией случаях допускается сброс сред в атмосферу через сбросные трубопроводы при обеспечении безопасного рассеивания сбрасываемой среды",
          "Сбрасываемые токсичные, взрыво- и пожароопасные технологические среды должны направляться в закрытые системы для дальнейшей утилизации или в системы организованного сжигания",
          "Запрещается организовывать совместное накопление сбросов, содержащих вещества, которые способны при смешивании образовывать взрывоопасные смеси",
        ],
        correctAnswers: [
          "В обоснованных проектной документацией случаях допускается сброс сред в атмосферу через сбросные трубопроводы при обеспечении безопасного рассеивания сбрасываемой среды",
        ],
      },
      {
        code: "68320",
        text: "При каком условии допускается установка переключающего устройства перед мембранными предохранительными устройствами?",
        answers: [
          "При условии установки переключающего устройства в местах, открытых и доступных для осмотра и монтажа (демонтажа)",
          "Если установлено удвоенное число мембранных устройств с обеспечением при этом защиты сосуда от превышения давления при любом положении переключающего устройства",
          "При условии установки контрольного манометра после переключающего устройства",
        ],
        correctAnswers: [
          "Если установлено удвоенное число мембранных устройств с обеспечением при этом защиты сосуда от превышения давления при любом положении переключающего устройства",
        ],
      },
      {
        code: "68321",
        text: "В какой документ заносятся результаты проверки исправности предохранительных устройств, установленных на сосуде, и сведения об их настройке?",
        answers: [
          "В технический паспорт предохранительного устройства",
          "В сменный (оперативный) журнал или иные эксплуатационные документы",
          "В соответствующее приложение паспорта сосуда",
          "В акт проверки исправности предохранительных устройств",
        ],
        correctAnswers: [
          "В сменный (оперативный) журнал или иные эксплуатационные документы",
        ],
      },
      {
        code: "68322",
        text: "Что не требуется обеспечивать при эксплуатации сосудов, имеющих границу раздела сред, у которых необходим контроль за уровнем жидкости?",
        answers: [
          "Видимость показаний указателя уровня жидкости посредством его установки в проектное положение",
          "При оснащении сосуда несколькими указателями уровня по высоте размещение их таким образом, чтобы они обеспечивали непрерывность показаний уровня жидкости",
          "Отвод рабочей среды в безопасное место при проведении продувки арматуры (кранов, вентилей), установленной на указателе уровня",
          "При проведении продувки арматуры, установленной на указателе уровня, должен обеспечиваться отвод рабочей среды, не отнесенной к группе 1 (ТР ТС 032/2013) в емкость, соединенную с атмосферой, для остальных сред среда должна отводиться в безопасное место",
        ],
        correctAnswers: [
          "При проведении продувки арматуры, установленной на указателе уровня, должен обеспечиваться отвод рабочей среды, не отнесенной к группе 1 (ТР ТС 032/2013) в емкость, соединенную с атмосферой, для остальных сред среда должна отводиться в безопасное место",
        ],
      },
      {
        code: "68323",
        text: "При каком минимальном избыточном давлении в сосуде допускается проведение ремонта сосуда и его элементов?",
        answers: [
          "Не допускается проведение ремонта сосудов и их элементов, находящихся под давлением",
          "При давлении 0,2 МПа",
          "При давлении, указанном в техническом паспорте завода-изготовителя",
          "При давлении 0,05 МПа",
        ],
        correctAnswers: [
          "Не допускается проведение ремонта сосудов и их элементов, находящихся под давлением",
        ],
      },
      {
        code: "68324",
        text: "В каком из приведенных случаев, в соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, сосуд не подлежит аварийной остановке?",
        answers: [
          "При повышении давления выше разрешенного и отсутствии снижения, несмотря на меры, принятые персоналом",
          "При выходе из строя одного из указателей уровня жидкости",
          "При обнаружении в сосуде и его элементах, работающих под давлением, трещин, неплотностей, выпучин, разрыва прокладок",
          "При неисправности манометра и невозможности определить давление по другим приборам",
        ],
        correctAnswers: [
          "При выходе из строя одного из указателей уровня жидкости",
        ],
      },
      {
        code: "68325",
        text: "Каким документом определяется порядок действия в случае инцидента при эксплуатации сосуда?",
        answers: [
          "Производственной инструкцией, утвержденной эксплуатирующей организацией",
          "Положением, устанавливающим действия работников в аварийных ситуациях и в случае инцидента при эксплуатации оборудования под давлением",
          "Инструкцией (руководством) по эксплуатации изготовителя сосуда",
        ],
        correctAnswers: [
          "Производственной инструкцией, утвержденной эксплуатирующей организацией",
        ],
      },
      {
        code: "68326",
        text: "Какие из приведенных мест не подлежат оборудованию аварийным освещением?",
        answers: [
          "Помещения для баков и деаэраторов",
          "Дымососные площадки",
          "Площадки и лестницы котлов",
          "Все приведенные места подлежат оборудованию аварийным освещением",
        ],
        correctAnswers: [
          "Все приведенные места подлежат оборудованию аварийным освещением",
        ],
      },
      {
        code: "68327",
        text: "В каком случае допускается отвод воды продувочным трубопроводом в емкость, работающую под давлением?",
        answers: [
          "Если рабочее давление емкости не превышает 1,5 МПа",
          "Если подтверждены надежность и эффективность продувки соответствующими расчетами",
          "Если разность давлений продувочного трубопровода и емкости не более 0,01 МПа",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "Если подтверждены надежность и эффективность продувки соответствующими расчетами",
        ],
      },
      {
        code: "68328",
        text: "Каким документом (документами) устанавливается объем работ, порядок и периодичность проведения технических освидетельствований в пределах срока службы сосуда?",
        answers: [
          "Руководством (инструкцией) по эксплуатации предприятия-изготовителя сосуда и ФНП ОРПД",
          "Программой проведения технического диагностирования оборудования",
          "Графиком проведения технического обслуживания, утвержденным руководителем эксплуатирующей организации",
        ],
        correctAnswers: [
          "Руководством (инструкцией) по эксплуатации предприятия-изготовителя сосуда и ФНП ОРПД",
        ],
      },
      {
        code: "68329",
        text: "В каком из приведенных случаев не проводится внеочередное техническое освидетельствование сосуда?",
        answers: [
          "Если котел не эксплуатировался более 12 месяцев",
          "Если передвижной сосуд установлен на другой площадке эксплуатирующей организации",
          "Если оборудование демонтировано и установлено на новом месте, за исключением транспортабельного оборудования, эксплуатируемого одной и той же организацией",
          "Во всех приведенных случаях проводится внеочередное техническое освидетельствование оборудования",
        ],
        correctAnswers: [
          "Если передвижной сосуд установлен на другой площадке эксплуатирующей организации",
        ],
      },
      {
        code: "68330",
        text: "Какие условия должны соблюдаться при установлении срока следующего периодического технического освидетельствования сосуда?",
        answers: [
          "Срок следующего освидетельствования должен устанавливаться решением руководителя эксплуатирующей организации по итогам проведения производственного контроля",
          "Срок следующего освидетельствования устанавливается относительно должен устанавливаться в зависимости от даты проведения внеочередного технического освидетельствования",
          "Срок следующего освидетельствования не должен превышать срока службы сосуда, установленного либо изготовителем, либо по результатам технического диагностирования сосуда",
        ],
        correctAnswers: [
          "Срок следующего освидетельствования не должен превышать срока службы сосуда, установленного либо изготовителем, либо по результатам технического диагностирования сосуда",
        ],
      },
      {
        code: "68331",
        text: "Что необходимо предпринять, если при освидетельствовании сосуда будут обнаружены дефекты?",
        answers: [
          "Для установления характера и размеров дефектов должно быть проведено техническое диагностирование сосуда с применением методов неразрушающего контроля",
          "Использовать оборудование на пониженных параметрах до проведения ремонтных работ на нем",
          "Проверить работоспособность предохранительных устройств, независимо от сроков плановой проверки",
        ],
        correctAnswers: [
          "Для установления характера и размеров дефектов должно быть проведено техническое диагностирование сосуда с применением методов неразрушающего контроля",
        ],
      },
      {
        code: "68332",
        text: "Каким документом определяется объем, методы и периодичность технических освидетельствований сосудов (за исключением баллонов)?",
        answers: [
          "Правила промышленной безопасности при использовании оборудования, работающего под избыточным давлением",
          "Руководство (инструкция по эксплуатации)",
          "Распорядительный документ руководителя эксплуатирующей организации по итогам проведения экспертизы промышленной безопасности",
          "Программа производственного контроля",
        ],
        correctAnswers: ["Руководство (инструкция по эксплуатации)"],
      },
      {
        code: "68333",
        text: "Кем проводятся технические освидетельствования сосудов, не подлежащих учету в территориальном органе Ростехнадзора?",
        answers: [
          "Комиссией предприятия с привлечением специализированной организации",
          "Ответственными специалистами эксплуатирующей организации",
          "Специализированной организацией, имеющей лицензию Ростехнадзора",
        ],
        correctAnswers: [
          "Ответственными специалистами эксплуатирующей организации",
        ],
      },
      {
        code: "68334",
        text: "Какая из приведенных операций не подлежит обязательному включению в объем работ по первичному техническому освидетельствованию сосудов, смонтированных на месте эксплуатации?",
        answers: [
          "Проведение визуального и измерительного контроля",
          "Контроль толщины стенок элементов сосудов",
          "Проведение гидравлических испытаний",
          "Проверка соответствия оснащения сосуда контрольно-измерительными приборами и предохранительными устройствами сосуда требованиям проектной и технической документации",
          "Все приведенные операции обязательны для выполнения при проведении первичного технического освидетельствования сосудов, смонтированных на месте эксплуатации",
        ],
        correctAnswers: ["Контроль толщины стенок элементов сосудов"],
      },
      {
        code: "68335",
        text: "В каком случае при первичном техническом освидетельствовании допускается не проводить осмотр внутренней поверхности и гидравлическое испытание сосуда?",
        answers: [
          "Если это установлено в требованиях руководства (инструкции) по эксплуатации сосуда, поставляемого в собранном виде, и при этом не нарушены указанные в руководстве сроки и условия консервации",
          "Если гидравлическое испытание и осмотр внутренней поверхности были проведены заводом-изготовителем",
          "Если объем сосуда под давлением не превышает 200 м3",
          "Если рабочая среда не относится к агрессивным средам",
        ],
        correctAnswers: [
          "Если это установлено в требованиях руководства (инструкции) по эксплуатации сосуда, поставляемого в собранном виде, и при этом не нарушены указанные в руководстве сроки и условия консервации",
        ],
      },
      {
        code: "68336",
        text: "Чем определяется объем внеочередного технического освидетельствования?",
        answers: [
          "Объем внеочередного технического освидетельствования определяется уполномоченной специализированной организацией совместно с ответственным за исправное состояние и безопасную эксплуатацию сосуда",
          "Объем внеочередного технического освидетельствования определяется заводом-изготовителем",
          "Объем внеочередного технического освидетельствования определяется причинами, вызвавшими его проведение",
          "Объем внеочередного технического освидетельствования определяется решением территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "Объем внеочередного технического освидетельствования определяется причинами, вызвавшими его проведение",
        ],
      },
      {
        code: "68337",
        text: "Чем осуществляется продувка сосуда, работающего под давлением воздуха или инертных газов, до начала выполнения работ внутри его корпуса?",
        answers: ["Только воздухом", "Только аргоном", "Только гелием"],
        correctAnswers: ["Только воздухом"],
      },
      {
        code: "68338",
        text: "Чем осуществляется продувка сосуда, работающего под давлением горючих газов, до начала выполнения работ внутри его корпуса?",
        answers: [
          "Инертным газом и воздухом или их смесью",
          "Для таких сосудов предусматривается обработка водным раствором",
          "Только инертным газом",
        ],
        correctAnswers: ["Инертным газом и воздухом или их смесью"],
      },
      {
        code: "68339",
        text: "Необходимо ли полностью снимать наружную изоляцию сосуда при проведении его внеочередного технического освидетельствования после ремонта с применением сварки и термической обработки?",
        answers: [
          "Допускается снимать наружную изоляцию частично только в месте, подвергнутом ремонту",
          "Необходимость снятия изоляции определяет уполномоченная специализированная организация по итогам внутреннего осмотра",
          "Наружная изоляция сосуда должна быть снята пропорционально площади, подвергнутой ремонту",
        ],
        correctAnswers: [
          "Допускается снимать наружную изоляцию частично только в месте, подвергнутом ремонту",
        ],
      },
      {
        code: "68340",
        text: "В течение какого времени проводится комплексное опробование котлов, сосудов и трубопроводов пара и горячей воды?",
        answers: [
          "Опробование оборудования под давлением проводится в течение 36 часов",
          "Продолжительность опробования определяется наладочной организацией, но не должна составлять менее 72 часов для котлов и 24 часов для остального оборудования",
          "Котлы - в течение 72 часов, трубопроводы тепловых сетей - в течение 24 часов, остальное оборудование - по программе комплексного опробования, разработанной организацией, проводящей соответствующие работы, и согласованной с эксплуатирующей организацией",
        ],
        correctAnswers: [
          "Котлы - в течение 72 часов, трубопроводы тепловых сетей - в течение 24 часов, остальное оборудование - по программе комплексного опробования, разработанной организацией, проводящей соответствующие работы, и согласованной с эксплуатирующей организацией",
        ],
      },
      {
        code: "68341",
        text: "В каких случаях допускается замена ультразвукового и радиографического контроля другими методами неразрушающего контроля?",
        answers: [
          "Если применение других методов неразрушающего контроля согласовано с разработчиком проектной документации",
          "Если при проведении визуального контроля не были выявлены видимые дефекты корпуса",
          "Ни в каких случаях",
        ],
        correctAnswers: [
          "Если применение других методов неразрушающего контроля согласовано с разработчиком проектной документации",
        ],
      },
      {
        code: "68342",
        text: "По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) металлических сосудов (за исключением литых)?  Где в формулах: Рраб – рабочее давление сосуда, Ррасч – расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 оС и расчетной температуре, МПа.",
        answers: [
          "Рпр = 1,25 Рраб ([σ]20 / [σ]t)",
          "Рпр = 1,5 Ррасч ([σ]20/[σ]t)",
          "Рпр = 1,15 Рраб([σ]20/[σ]t)",
          "Рпр = 1,75 Рраб",
        ],
        correctAnswers: ["Рпр = 1,25 Рраб ([σ]20 / [σ]t)"],
      },
      {
        code: "68343",
        text: "По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) литых и кованых металлических сосудов?   Где в формулах: Рраб – рабочее давление сосуда, Ррасч – расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 оС и расчетной температуре, МПа.",
        answers: [
          "Рпр = 1,5Рраб ([σ]20 / [σ]t)",
          "Рпр= 1,75Ррасч ([σ]20/ [σ]t)",
          "Рпр = 1,25Рраб ([σ]20 / [σ]t)",
          "Рпр = 1,5 Рраб",
        ],
        correctAnswers: ["Рпр = 1,5Рраб ([σ]20 / [σ]t)"],
      },
      {
        code: "68344",
        text: "По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) сосудов, изготовленных из неметаллических материалов с ударной вязкостью более 20 Дж/см2?   Где в формулах: Рраб – рабочее давление сосуда, Ррасч – расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 oС и расчетной температуре, МПа.",
        answers: [
          "Рпр = 1,3Рраб([σ]20 / [σ]t)",
          "Рпр = 1,8Рраб ([σ]20 / [σ]t)",
          "Рпр = 1,25Рраб ([σ]20 / [σ]t)",
          "Рпр = 1,6Ррасч",
        ],
        correctAnswers: ["Рпр = 1,3Рраб([σ]20 / [σ]t)"],
      },
      {
        code: "68345",
        text: "По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) сосудов, изготовленных из неметаллических материалов с ударной вязкостью 20 Дж/см2 и менее?  Где в формулах: Рраб – рабочее давление сосуда, Ррасч – расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 ﻿оС и расчетной температуре, МПа.",
        answers: [
          "Рпр = 1,6Рраб([σ]20 / [σ]t)",
          "Рпр = 1,3Рраб ([σ]20/ [σ]t)",
          "Рпр = 1,8 Рраб ([σ]20 / [σ]t)",
          "Рпр = 1,6 Ррасч",
        ],
        correctAnswers: ["Рпр = 1,6Рраб([σ]20 / [σ]t)"],
      },
      {
        code: "68346",
        text: "По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) криогенных сосудов при наличии вакуума в изоляционном пространстве?   Где в формулах: Рраб – рабочее давление сосуда, Ррасч – расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 оС и расчетной температуре, МПа.",
        answers: [
          "Рпр = 1,25 Р﻿раб – 0,1",
          "Рпр = 1,5 Рраб – 0,1",
          "Рпр = 1,25 Рраб ([σ]20 / [σ]t)",
          "Рпр= 1,25 Рраб + 0,1",
        ],
        correctAnswers: ["Рпр = 1,25 Р﻿раб – 0,1"],
      },
      {
        code: "68347",
        text: "По какой из приведенных формул определяется значение пробного давления (Pпр) при гидравлическом испытании (периодическое техническое освидетельствование) металлопластиковых сосудов, у которых ударная вязкость неметаллических материалов более 20 Дж/см2?  Где в формулах: Рраб – рабочее давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 Cо и расчетной температуре, МПа, Км - отношение массы металлоконструкции к общей массе сосуда.",
        answers: [
          "Р пр = [1,25 Км+ 1,3 (1 - Км )] Рраб ([σ]20 / [σ]t)",
          "Р пр = [1,5 Км + 1,6 (1 - Км )] Рраб ([σ]20 / [σ]t)",
          "Р пр = [1,25Км + 1,6 (1 - Км )] Рраб",
          "Р пр = 1,3 Рраб ([σ]20 / [σ]t)",
        ],
        correctAnswers: ["Р пр = [1,25 Км+ 1,3 (1 - Км )] Рраб ([σ]20 / [σ]t)"],
      },
      {
        code: "68348",
        text: "По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) металлопластиковых сосудов, у которых ударная вязкость неметаллических материалов 20 Дж/см2 и менее?  Где в формулах: Рраб – рабочее давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 оС и расчетной температуре, МПа, Км - отношение массы металлоконструкции к общей массе сосуда.",
        answers: [
          "Рпр = [1,25 Км + 1,6 (1 - Км )] Рраб([σ]20 / [σ]t)",
          "Рпр = [1,25 Км + 1,3 (1 - Км )] Рраб ([σ]20 / [σ]t)",
          "Рпр = [1,25 Км + 1,6 (1 - Км )] Рраб",
          "Рпр = 1,25 Рраб ([σ]20 / [σ]t)",
        ],
        correctAnswers: ["Рпр = [1,25 Км + 1,6 (1 - Км )] Рраб([σ]20 / [σ]t)"],
      },
      {
        code: "68349",
        text: "Какое из приведенных требований должно выполняться при проведении гидравлического испытания сосудов?",
        answers: [
          "В комбинированных сосудах с 2 и более рабочими полостями, рассчитанными на разные давления, гидравлическому испытанию должны подвергаться все полости с давлением, равным максимальному пробному давлению из определяемых для каждой полости",
          "Гидравлическое испытание комбинированных сосудов, устанавливаемых вертикально, запрещается проводить в горизонтальном положении",
          "Гидравлическое испытание сосудов должно проводиться по инструкции, разработанной организацией-эксплуатантом",
          "Гидравлическое испытание сосудов, устанавливаемых вертикально, разрешается проводить в горизонтальном положении, при этом должен быть выполнен расчет на прочность корпуса сосуда с учетом принятого способа опирания для проведения гидравлического испытания",
        ],
        correctAnswers: [
          "Гидравлическое испытание сосудов, устанавливаемых вертикально, разрешается проводить в горизонтальном положении, при этом должен быть выполнен расчет на прочность корпуса сосуда с учетом принятого способа опирания для проведения гидравлического испытания",
        ],
      },
      {
        code: "68350",
        text: "Каково минимальное значение температуры воды, используемой для гидравлического испытания сосуда (если конкретное значение не указано в технической документации организации-изготовителя)?",
        answers: ["6 °C", "5 °C", "10 °C", "60 °C"],
        correctAnswers: ["5 °C"],
      },
      {
        code: "68351",
        text: "В каком из приведенных случаев при проведении гидравлического испытания при эксплуатации сосудов допускается использовать не воду, а другую жидкость?",
        answers: [
          "В случаях, предусмотренных организацией, проводящей гидравлическое испытание",
          "Если гидравлическое испытание проводится на сосудах, рабочей средой которого является жидкость, отличающаяся своими характеристиками от воды",
          "В технически обоснованных случаях, предусмотренных организацией-изготовителем",
          "Гидравлическое испытание проводится только с использованием воды",
        ],
        correctAnswers: [
          "В технически обоснованных случаях, предусмотренных организацией-изготовителем",
        ],
      },
      {
        code: "68352",
        text: "Какое из приведенных требований должно выполняться при проведении гидравлического испытания сосуда?",
        answers: [
          "Время выдержки под пробным давлением сосуда, находящегося в эксплуатации, должно определяться руководством по эксплуатации",
          "Давление воды при гидравлическом испытании следует контролировать одним манометром, класс точности которого не менее 1,5",
          "При проведении гидравлических испытаний трубопроводов пара и горячей воды температура воды должна быть не менее 60 °С",
          "Общее время подъема давления (до значения пробного) должно быть не менее 2 часов",
        ],
        correctAnswers: [
          "Время выдержки под пробным давлением сосуда, находящегося в эксплуатации, должно определяться руководством по эксплуатации",
        ],
      },
      {
        code: "68353",
        text: "Каково минимальное значение времени выдержки под пробным давлением сосуда, имеющего толщину стенки, не превышающую 50 мм (если отсутствуют другие указания в руководстве по эксплуатации)?",
        answers: ["60 минут", "10 минут", "15 минут", "40 минут"],
        correctAnswers: ["10 минут"],
      },
      {
        code: "68354",
        text: "Каково минимальное значение времени выдержки под пробным давлением сосуда, имеющего толщину стенки свыше 50 до 100 мм включительно (если отсутствуют другие указания в руководстве по эксплуатации)?",
        answers: ["5 минут", "10 минут", "20 минут", "30 минут"],
        correctAnswers: ["20 минут"],
      },
      {
        code: "68355",
        text: "Каково минимальное значение времени выдержки под пробным давлением сосуда, имеющего толщину стенки свыше 100 мм (если отсутствуют другие указания в руководстве по эксплуатации)?",
        answers: ["20 минут", "10 минут", "30 минут", "60 минут"],
        correctAnswers: ["30 минут"],
      },
      {
        code: "68356",
        text: "В каком из приведенных случаев сосуд считается выдержавшим гидравлическое испытание?",
        answers: [
          "Если обнаружены единичные трещины на сварном соединении",
          "Если в заклепочных соединениях обнаружено появление капель, не увеличивающихся в размерах",
          "Если в процессе испытаний наблюдались падения давления по манометру, но в процессе они были скомпенсированы",
          "Во всех приведенных случаях сосуд считается не выдержавшим гидравлическое испытание",
        ],
        correctAnswers: [
          "Во всех приведенных случаях сосуд считается не выдержавшим гидравлическое испытание",
        ],
      },
      {
        code: "68357",
        text: "При выполнении каких условий допускается заменять гидравлическое испытание сосуда пневматическим испытанием?",
        answers: [
          "Если пневматическое испытание одновременно контролируется методом акустической эмиссии",
          "Если при гидравлическом испытании есть вероятность хрупкого разрушения сосуда",
          "Если рабочее давление сосуда не превышает 1 МПа",
          "Если необходимость проведения испытания сосуда связана с проведением на нем внеплановых ремонтных работ. Данная возможность должна быть указана в паспорте завода-изготовителя",
        ],
        correctAnswers: [
          "Если пневматическое испытание одновременно контролируется методом акустической эмиссии",
        ],
      },
      {
        code: "68358",
        text: "По какой из приведенных формул определяется значение пробного давления (Рпр) при пневматическом испытании сосудов?  Где в формулах: Рраб – рабочее давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 ﻿оС и расчетной температуре, МПа.",
        answers: [
          "Рпр = 1,15 Рраб([σ]20 / [σ]t)",
          "Рпр = 0,85P﻿раб",
          "Рпр = 1,1 Р﻿раб([σ]20 / [σ]t)",
          "Рпр = 1,15 Рраб",
        ],
        correctAnswers: ["Рпр = 1,15 Рраб([σ]20 / [σ]t)"],
      },
      {
        code: "68359",
        text: "Чему равно минимальное значение времени выдержки сосуда под пробным давлением при пневматическом испытании?",
        answers: [
          "20 минут",
          "5 минут",
          "15 минут",
          "Время выдержки сосуда зависит от скорости падения давления и должно составлять не менее 15 минут",
        ],
        correctAnswers: ["15 минут"],
      },
      {
        code: "68360",
        text: "Какие цистерны должны иметь термоизоляцию или теневую защиту?",
        answers: [
          "Цистерны, заполняемые сжиженным азотом, при температуре, не превышающей в момент окончания наполнения минус 30 оС",
          "Цистерны, заполняемые сжиженным кислородом, вне зависимости от уровня температуры в момент окончания наполнения",
          "Цистерны, наполняемые жидким аммиаком, при температуре, не превышающей в момент окончания наполнения минус 25 оС",
        ],
        correctAnswers: [
          "Цистерны, наполняемые жидким аммиаком, при температуре, не превышающей в момент окончания наполнения минус 25 оС",
        ],
      },
      {
        code: "68361",
        text: "Какое из приведенных требований к предохранительному клапану, установленному на цистерне, указано неверно?",
        answers: [
          "Предохранительный клапан должен сообщаться с газовой фазой цистерны",
          "Предохранительный клапан должен иметь колпак с отверстиями для выпуска газа в случае открывания клапана",
          "Площадь отверстий в колпаке должна быть равной площади рабочего сечения предохранительного клапана",
          "Все приведенные требования указаны верно",
        ],
        correctAnswers: [
          "Площадь отверстий в колпаке должна быть равной площади рабочего сечения предохранительного клапана",
        ],
      },
      {
        code: "68362",
        text: "Для каких бочек наливной и сливной вентили должны оснащаться сифоном?",
        answers: [
          "Бочек, предназначенных для жидких веществ",
          "Бочек, предназначенных для хлора и фосгена",
          "Бочек, транспортируемых в вертикальном состоянии",
          "Бочек, предназначенных для инертных газов",
        ],
        correctAnswers: ["Бочек, предназначенных для хлора и фосгена"],
      },
      {
        code: "68363",
        text: "Каково минимальное значение остаточного избыточного давления, которое должно оставаться в опорожняемых потребителем цистернах и бочках?",
        answers: [
          "0,02 МПа",
          "0,05 МПа",
          "Потребитель, опорожняя цистерны и бочки, обязан привести уровень давления в порожней цистерне на уровень атмосферного",
          "Значение остаточного давления указывается в производственной инструкции организации, но не должно быть более 0,1 МПа",
        ],
        correctAnswers: ["0,05 МПа"],
      },
      {
        code: "68364",
        text: "Какое из приведенных требований к эксплуатации транспортных цистерн и бочек указано неверно?",
        answers: [
          "При эксплуатации транспортных цистерн регистрации в государственном реестре ОПО (по признаку использования оборудования под избыточным давлением) подлежат только те объекты эксплуатирующих организаций, на которых осуществляют хранение и использование цистерн под давлением газов в технологическом процессе, в том числе при проведении сливо-наливных операций",
          "Транспортирование цистерн, а также перевозка бочек под давлением газов по дорогам общего пользования автомобильным (железнодорожным) транспортом является деятельностью в области промышленной безопасности",
          "Перевозка бочек под давлением газов по дорогам общего пользования автомобильным транспортом не регламентируется требованиями ФНП ОРПД",
          "Все приведенные требования указаны неверно",
        ],
        correctAnswers: [
          "Транспортирование цистерн, а также перевозка бочек под давлением газов по дорогам общего пользования автомобильным (железнодорожным) транспортом является деятельностью в области промышленной безопасности",
        ],
      },
      {
        code: "68365",
        text: "В каком из приведенных случаев размещение баллонов с газом на местах потребления должно осуществляться в соответствии с планом (проектом) размещения оборудования?",
        answers: [
          "При размещении групповой баллонной установки",
          "При размещении баллона на оборудованной открытой площадке",
          "При необходимости размещения баллонов на расстоянии менее 1,5 метров от постоянного источника тепла с максимальной температурой поверхности 60  °С",
        ],
        correctAnswers: ["При размещении групповой баллонной установки"],
      },
      {
        code: "68366",
        text: "Какое из приведенных требований к размещению баллонов при их использовании указано неверно?",
        answers: [
          "Не допускается установка баллонов в местах прохода людей",
          "Не допускается установка баллонов в местах проезда транспортных средств",
          "Не допускается установка баллона в помещениях, в которых имеются источники тепла с открытым огнем",
          "Баллоны (при индивидуальной установке) должны находиться на расстоянии не менее 1 метра от радиаторов отопления",
        ],
        correctAnswers: [
          "Не допускается установка баллона в помещениях, в которых имеются источники тепла с открытым огнем",
        ],
      },
      {
        code: "68367",
        text: "Какой баллон из приведенных допускается использовать в горизонтальном положении?",
        answers: [
          "Баллон с кислородом",
          "Баллон с пропаном",
          "Баллон с гелием",
          "Допускается для всех баллонов при условии оборудования подставки, предотвращающей перекатывания баллона",
        ],
        correctAnswers: ["Баллон с кислородом"],
      },
      {
        code: "68368",
        text: "Какое минимальное значение избыточного давления должно оставаться в баллонах (если иное не предусмотрено техническими условиями на газ)?",
        answers: [
          "Газ необходимо вырабатывать полностью",
          "0,08 МПа",
          "0,05 МПа",
          "Требования к минимальному значению избыточного давления устанавливаются организацией, обслуживающей баллон",
        ],
        correctAnswers: ["0,05 МПа"],
      },
      {
        code: "68369",
        text: "Какое из приведенных требований при подаче газа из баллонов в сосуд, который работает с меньшим давлением, указано неверно?",
        answers: [
          "Подача газов из баллона в сосуд, а также в технологическое оборудование с меньшим рабочим давлением должна быть произведена через редуктор, предназначенный для данного газа и окрашенный в соответствующий цвет",
          "Манометр со шкалой, обеспечивающей возможность измерения максимального рабочего давления в баллоне, устанавливается на входе в редуктор",
          "Тип манометра и предохранительного клапана определяется разработчиком проекта и организацией - изготовителем редуктора",
          "Все приведенные требования верны",
        ],
        correctAnswers: ["Все приведенные требования верны"],
      },
      {
        code: "68370",
        text: "Где должны храниться баллоны с ядовитыми газами?",
        answers: [
          "В специальных клетях, находящихся на открытом воздухе, защищенных от доступа посторонних и атмосферных осадков",
          "В складских помещениях вблизи мест их использования",
          "В специальных закрытых помещениях",
        ],
        correctAnswers: ["В специальных закрытых помещениях"],
      },
      {
        code: "68371",
        text: "Какие требования к хранению баллонов указаны неверно?",
        answers: [
          "Не допускается хранение баллонов, которые не имеют башмаков, в горизонтальном положении на деревянных рамах или стеллажах",
          "Вентили баллонов при укладывании в штабеля должны быть обращены в одну сторону",
          "При укладке баллонов высота штабеля не должна превышать 1,5 метра",
        ],
        correctAnswers: [
          "Не допускается хранение баллонов, которые не имеют башмаков, в горизонтальном положении на деревянных рамах или стеллажах",
        ],
      },
      {
        code: "68372",
        text: "Какое требование к складам для хранения баллонов указано неверно?",
        answers: [
          "Склады для баллонов со взрыво- и пожароопасными газами должны находиться в зоне молниезащиты",
          "Стены, перегородки, покрытия складов для хранения газов должны быть из несгораемых материалов, соответствующих проекту; окна и двери должны открываться внутрь",
          "Склады для баллонов, наполненных газом, должны иметь естественную или искусственную вентиляцию",
          "Полы складов должны быть ровными с нескользкой поверхностью, а складов для баллонов с горючими газами - с поверхностью из материалов, исключающих искрообразование при ударе о них какими-либо предметами",
        ],
        correctAnswers: [
          "Стены, перегородки, покрытия складов для хранения газов должны быть из несгораемых материалов, соответствующих проекту; окна и двери должны открываться внутрь",
        ],
      },
      {
        code: "68373",
        text: "Какое требование к складам для хранения баллонов указано неверно?",
        answers: [
          "Оснащение складов для баллонов с горючими газами, опасными в отношении взрывов, определяется проектом",
          "Стены, перегородки, покрытия складов для хранения газов должны быть из несгораемых материалов, соответствующих проекту; окна и двери должны открываться наружу",
          "Полы складов должны быть ровными с нескользкой поверхностью, а складов для баллонов с горючими газами - с поверхностью из материалов, исключающих искрообразование при ударе о них какими-либо предметами",
          "Все требования указаны верно",
        ],
        correctAnswers: ["Все требования указаны верно"],
      },
      {
        code: "68374",
        text: "Какое требование к перемещению баллонов на объектах их применения указано неверно?",
        answers: [
          "Транспортирование баллонов в специальных контейнерах в вертикальном положении разрешается при условии наличия прокладок между ними",
          "Перевозка наполненных газами баллонов в пределах границ ОПО, производственной площадки предприятия должна производиться на рессорном транспорте или на автокарах в горизонтальном положении, обязательно с прокладками между баллонами",
          "Перемещение на автокарах наполненных баллонов, расположенных вертикально, не допускается, даже при наличии контейнеров",
          "Все баллоны во время перевозки должны быть уложены вентилями в одну сторону",
        ],
        correctAnswers: [
          "Перемещение на автокарах наполненных баллонов, расположенных вертикально, не допускается, даже при наличии контейнеров",
        ],
      },
      {
        code: "68375",
        text: "Каким документом устанавливаются дополнительные требования безопасности при эксплуатации, наполнении, хранении и транспортировании баллонов, изготовленных из металлокомпозитных и композитных материалов?",
        answers: [
          "Проектной документацией",
          "Технологией работ",
          "Инструкция (руководство) по эксплуатации",
          "Распорядительным документом организации-эксплуатанта по итогам проведения оценки рисков",
        ],
        correctAnswers: ["Инструкция (руководство) по эксплуатации"],
      },
      {
        code: "68376",
        text: "В каком из приведенных случаев допускается использование при монтаже, ремонте, реконструкции (модернизации) стальных труб и иных материалов, ранее бывших в употреблении?",
        answers: [
          "Применение стальных труб и иных материалов, ранее бывших в употреблении, не допускается",
          "Если на стальные трубы, бывших в употреблении, имеются документы подтверждающие их качество",
          "Если установленные на новом месте трубы будут использоваться для работы под давлением рабочих сред, отнесенных к группе 2",
          "В случае применения таких труб в составе обводных (байпасных) и продувочных линий, временно обустраиваемых на ограниченный период времени, определенный проектом монтажа, ремонта, реконструкции (модернизации) системы трубопроводов.",
        ],
        correctAnswers: [
          "В случае применения таких труб в составе обводных (байпасных) и продувочных линий, временно обустраиваемых на ограниченный период времени, определенный проектом монтажа, ремонта, реконструкции (модернизации) системы трубопроводов.",
        ],
      },
      {
        code: "68377",
        text: "Какой организацией должна быть разработана технологическая документация, регламентирующая содержание и порядок выполнения работ по монтажу, ремонту, реконструкции (модернизации) оборудования, работающего под давлением, с применением сварки и термической обработки?",
        answers: [
          "Специализированной организацией выполняющей эти работы, до начала их производства",
          "Специализированной организацией с привлечением лицензированной проектной организацией",
          "Эксплуатирующей организацией, на основании паспортов и инструкций по эксплуатации завода-изготовителя",
          "Эксплуатирующей организацией совместно со специализированной организацией",
        ],
        correctAnswers: [
          "Специализированной организацией выполняющей эти работы, до начала их производства",
        ],
      },
      {
        code: "68378",
        text: "В каком случае и кем допускаются отклонения от проектной документации при строительстве, реконструкции, капитальном ремонте и эксплуатации ОПО, на которых используется (применяется) оборудование под давлением?",
        answers: [
          "В случае изменения требований законодательства Российской Федерации в области градостроительной деятельности и промышленной безопасности",
          "Отклонения от проектной документации не допускаются",
          "В случае их внесения в соответствующие разделы проектной документации и согласования внесенных изменений с органами Ростехнадзора",
        ],
        correctAnswers: ["Отклонения от проектной документации не допускаются"],
      },
      {
        code: "68379",
        text: "Какой организацией определяются процедуры контроля соблюдения технологических процессов при осуществлении работ по монтажу, ремонту, реконструкции (модернизации) оборудования, работающего под давлением?",
        answers: [
          "Организацией - эксплуатантом на основании технической документации на проведение работ",
          "Организацией - изготовителем оборудования, с описанием процедур в инструкции по эксплуатации",
          "Специализированной организацией, выполняющей работы по монтажу, ремонту, реконструкции (модернизации) оборудования",
          "Организацией, осуществляющей техническое диагностирование оборудования",
        ],
        correctAnswers: [
          "Специализированной организацией, выполняющей работы по монтажу, ремонту, реконструкции (модернизации) оборудования",
        ],
      },
      {
        code: "68380",
        text: "Отсутствие какой документации не является препятствием для осуществления монтажа, ремонта, реконструкции (модернизации) оборудования, работающего под давлением, специализированной организацией?",
        answers: [
          "Проекта производства монтажно-демонтажных работ",
          "Эксплуатационной документации монтируемого, ремонтируемого, реконструируемого (модернизируемого) оборудования",
          "Программы пуско-наладки, испытаний и комплексного опробования монтируемого (ремонтируемого, реконструируемого) оборудования под давлением, проводимых по окончании монтажа, ремонта, реконструкции",
          "Комплекта чертежей организации-изготовителя на заменяемые при ремонте оборудования элементы",
        ],
        correctAnswers: [
          "Эксплуатационной документации монтируемого, ремонтируемого, реконструируемого (модернизируемого) оборудования",
        ],
      },
      {
        code: "68381",
        text: "Каким образом должны выбираться методы и объем контроля качества сварных соединений при монтаже, ремонте, реконструкции (модернизации) оборудования, работающего под давлением?",
        answers: [
          "В соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением",
          "В соответствии с инструкцией по эксплуатации завода - изготовителя оборудования под давлением",
          "В соответствии с требованиями технического регламента",
          "Методы и объем контроля качества сварных соединений определяются  организацией, производившей ремонт оборудования под давлением",
        ],
        correctAnswers: [
          "В соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением",
        ],
      },
      {
        code: "68382",
        text: " Каким образом оформляются результаты проводимого контроля качества сварных соединений?",
        answers: [
          "Результаты оформляются решением комиссии",
          "Оформлением приложения к паспорту завода - изготовителя оборудования",
          "Результаты по каждому виду проводимого контроля и месту контроля должны фиксироваться в отчетной документации (журналы, формуляры, протоколы, маршрутные паспорта)",
        ],
        correctAnswers: [
          "Результаты по каждому виду проводимого контроля и месту контроля должны фиксироваться в отчетной документации (журналы, формуляры, протоколы, маршрутные паспорта)",
        ],
      },
      {
        code: "68383",
        text: "Каково минимальное значение температуры воды, используемой для гидравлического испытания трубопровода (если конкретное значение не указано в технической документации организации-изготовителя)?",
        answers: ["8 °C", "5  °C", "35 °C", "25 °C"],
        correctAnswers: ["5  °C"],
      },
      {
        code: "68384",
        text: "Какое из приведенных требований должно выполняться при исправлении дефектов сварных соединений?",
        answers: [
          "Максимальные размеры и форма подлежащих заварке выборок устанавливаются технологической документацией",
          "Место исправленного дефекта сварного соединения должно иметь обозначения. Вид обозначений устанавливается технологической документацией",
          "Исправление дефектов должен производить сварщик, не производивший сварку соединения, на котором возник дефект",
        ],
        correctAnswers: [
          "Максимальные размеры и форма подлежащих заварке выборок устанавливаются технологической документацией",
        ],
      },
      {
        code: "68385",
        text: "На кого возлагается контроль за соблюдением требований ремонтных рабочих чертежей и технологической документации на ремонт?",
        answers: [
          "На технического руководителя организации",
          "На специалиста организации-эксплуатанта, ответственного за безопасную эксплуатацию оборудования",
          "На подразделение технического контроля организации, выполняющей работы по ремонту, реконструкции (модернизации) оборудования, а также на уполномоченного представителя эксплуатирующей организации",
        ],
        correctAnswers: [
          "На подразделение технического контроля организации, выполняющей работы по ремонту, реконструкции (модернизации) оборудования, а также на уполномоченного представителя эксплуатирующей организации",
        ],
      },
      {
        code: "68386",
        text: "Когда на оборудовании, работающем под давлением, проводятся предусмотренные руководством (инструкцией) по эксплуатации пусконаладочные работы?",
        answers: [
          "После проведения визуального осмотра оборудования на предмет наличия видимых дефектов",
          "После оформления удостоверения о качестве монтажа и проведения первичного технического освидетельствования",
          "После проведения обследования методами неразрушающего контроля",
          "После окончания монтажных работ",
        ],
        correctAnswers: [
          "После оформления удостоверения о качестве монтажа и проведения первичного технического освидетельствования",
        ],
      },
      {
        code: "68387",
        text: "Каким документом определяется ответственность за безопасность обслуживания оборудования под давлением в период проведения наладочных работ?",
        answers: [
          "Распорядительным документом предприятия эксплуатанта в соответствии с требованиями ФНП ОРПД",
          "Программой наладочных работ",
          "Специальным разделом проектной документации",
        ],
        correctAnswers: ["Программой наладочных работ"],
      },
      {
        code: "68388",
        text: "Каким образом допускается маркировать сварное соединение, выполненное несколькими сварщиками (бригадой сварщиков)?",
        answers: [
          "Допускается маркировать сварные соединения несмываемой краской определенного цвета, утвержденного распорядительным документом для данной бригады",
          "Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
          "Сварщик должен маркировать тот участок сварного шва, который он выполнял, предварительно обозначив границы сварки краской",
        ],
        correctAnswers: [
          "Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
        ],
      },
      {
        code: "68389",
        text: "Какие функции обязано выполнить лицо, осуществляющее руководство сварочными работами, назначенное распорядительным документом организации или (и) должностной инструкцией которого предусмотрено руководство сварочными работами, перед выполнением сварочных работ?",
        answers: [
          "Только проверять и обеспечивать соответствие численного состава и квалификации персонала сварочного производства, сборочного и сварочного оборудования, основных и сварочных материалов, применяемой технологии сварки требованиям ПТД",
          "Только знакомить сварщиков с требованиями технологических карт сварки, а также с внесенными в них изменениями с подтверждением ознакомления подписями сварщиков в применяемых ими технологических картах сварки",
          "Только организовывать проведение операционного контроля",
          "Все перечисленные функции",
        ],
        correctAnswers: ["Все перечисленные функции"],
      },
      {
        code: "68390",
        text: 'Какие требования предъявляются ФНП "Требования к производству сварочных работ на опасных производственных объектах" к сварочному оборудованию и сварочным материалам, применяемым при выполнении сварочных работ?',
        answers: [
          "Все сварочные материалы должны быть протестированы в рамках входного контроля путем сварки пробного сварного шва",
          "Сварочное оборудование должно быть зарегистрировано в органах Ростехнадзора",
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
        ],
        correctAnswers: [
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "68391",
        text: "Какие действия должны быть осуществлены при выполнении многопроходных швов после наложения каждого валика поверхности шва и кромки разделки?",
        answers: [
          "Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)",
          "После каждого прохода шов должен обезжириваться",
          "Перед каждым последующим проходом шов должен проверяться методом капиллярного контроля",
        ],
        correctAnswers: [
          "Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)",
        ],
      },
      {
        code: "68392",
        text: "Какая документация оформляется в процессе выполнения сварочных работ?",
        answers: [
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
          "Только акты по неразрушающему контролю",
          "Акты производственного контроля сварочных работ",
        ],
        correctAnswers: [
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "68393",
        text: "Кто допускается к выполнению сварочных работ на опасном производственном объекте?",
        answers: [
          "Сварщики и специалисты сварочного производства не моложе 21 года и при наличии опыта работы на опасных производственных объектах не менее 3 лет",
          "Сварщики и специалисты сварочного производства при условии получения аттестации на соответствующий вид сварочных работ и после прохождения вводного и первичного инструктажей по охране труда на опасном производственном объекте",
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
        ],
        correctAnswers: [
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
        ],
      },
      {
        code: "68394",
        text: "К выполнению каких работ могут быть допущены сварщики и специалисты сварочного производства?",
        answers: [
          "К выполнению любых сварочных работ при условии их выполнения только тем оборудованием, на которое сварщик прошел обучение",
          "К выполнении любых сварочных работ после проведения руководителем целевого инструктажа",
          "К выполнению сварочных работ, указанных в действующих документах",
        ],
        correctAnswers: [
          "К выполнению сварочных работ, указанных в действующих документах",
        ],
      },
      {
        code: "68395",
        text: "Что должно быть указано в технологических картах сварки?",
        answers: [
          "Только режимы сварки и последовательность выполнения операций",
          "Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений",
          "Требования к сварочному оборудованию, квалификационные требования к сварщику, последовательность выполнения операций",
        ],
        correctAnswers: [
          "Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений",
        ],
      },
      {
        code: "68396",
        text: "Какую проверку должен пройти сварщик, впервые приступающий к сварке, перед допуском к работе?",
        answers: [
          "Проверку путем выполнения и контроля допускного сварного соединения",
          "Проверку знаний технологических карт сварки",
          "Проверку знаний порядка действий в случае пожара и навыков применения первичных средств пожаротушения",
        ],
        correctAnswers: [
          "Проверку путем выполнения и контроля допускного сварного соединения",
        ],
      },
    ],
    702: [
      {
        code: "68398",
        text: "При осуществлении каких процессов на ОПО не применяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?",
        answers: [
          "Консервация и ликвидация опасного производственного объекта, на котором используются сосуды, работающие под давлением",
          "Реконструкция (модернизация), наладка, обслуживание и ремонт сосудов, работающих под давлением",
          "Проектирование и конструирование сосудов, работающих под давлением",
          "Техническое освидетельствование и техническое диагностирование сосудов, работающих под давлением",
        ],
        correctAnswers: [
          "Проектирование и конструирование сосудов, работающих под давлением",
        ],
      },
      {
        code: "68399",
        text: "На какой из приведенных сосудов не распространяется действие ФНП ОРПД?",
        answers: [
          "Барокамера вместимостью 0,5 м³",
          "Сосуд, объем которого составляет 25 литров, работающий под давлением среды, равным 0,8 МПа",
          "Сосуд, работающий под давлением 0,7 МПа, установленный на плавучей буровой установке",
          "Сосуд, объем которого составляет 1 м³, работающий под давлением среды 0,5 МПа",
        ],
        correctAnswers: [
          "Сосуд, объем которого составляет 25 литров, работающий под давлением среды, равным 0,8 МПа",
        ],
      },
      {
        code: "68400",
        text: "На какой из приведенных сосудов, работающих под давлением свыше 0,07 МПа, распространяется действие ФНП ОРПД?",
        answers: [
          "Сосуд вместимостью 0,025 м³, установленный на летательном аппарате",
          "Сосуд вместимостью 0,01 м³, используемый для научно-экспериментальных целей",
          "Барокамера вместимостью 0,8 м³, работающая с воздухом под давлением 0,1 МПа",
          "Сосуд вместимостью 0,015 м³, у которого произведение значений рабочего давления (МПа) и вместимости (м³) равняется 0,018",
        ],
        correctAnswers: [
          "Барокамера вместимостью 0,8 м³, работающая с воздухом под давлением 0,1 МПа",
        ],
      },
      {
        code: "68401",
        text: "Кто принимает решение о вводе в эксплуатацию сосуда, работающего под давлением?",
        answers: [
          "Руководитель территориального органа Ростехнадзора",
          "Ответственный за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением",
          "Ответственный за исправное состояние и безопасную эксплуатацию сосуда",
          "Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения)",
        ],
        correctAnswers: [
          "Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения)",
        ],
      },
      {
        code: "68402",
        text: "На каком основании принимается решение о вводе в эксплуатацию сосуда, работающего под давлением?",
        answers: [
          "На основании результатов проверки готовности сосуда к пуску в работу и проверки организации надзора за эксплуатацией сосуда",
          "На основании результатов первичного освидетельствования сосуда и проверки организации его обслуживания",
          "На основании результатов проверки Ростехнадзором организации надзора за эксплуатацией сосуда",
          "На основании результатов технического диагностирования сосуда, проведенного при пробном запуске",
        ],
        correctAnswers: [
          "На основании результатов проверки готовности сосуда к пуску в работу и проверки организации надзора за эксплуатацией сосуда",
        ],
      },
      {
        code: "68403",
        text: "В каком случае проверки готовности сосуда к пуску в работу и организации надзора за эксплуатацией сосуда проводятся ответственными лицами или комиссией с их участием?",
        answers: [
          "При передаче опасного производственного объекта и сосуда под давлением, находившегося в эксплуатации в его составе, для использования другой эксплуатирующей организации",
          "После монтажа без применения сварных соединений сосуда, поставленного на объект эксплуатации в собранном виде",
          "После монтажа сосуда, поставляемого отдельными деталями, элементами или блоками, окончательную сборку (доизготовление) которого с применением неразъемных соединений производят при монтаже на месте его установки (использования)",
          "После реконструкции (модернизации) сосуда под давлением",
        ],
        correctAnswers: [
          "После монтажа без применения сварных соединений сосуда, поставленного на объект эксплуатации в собранном виде",
        ],
      },
      {
        code: "68404",
        text: "В каком случае проверки готовности сосуда к пуску в работу и организации надзора за эксплуатацией сосуда проводятся только комиссией, назначаемой приказом эксплуатирующей организации?",
        answers: [
          "После ремонта с заменой патрубков, штуцеров сосуда, неразъемно присоединенных к его корпусу, с применением сварки",
          "После монтажа без применения неразъемных соединений сосуда, поставленного на объект эксплуатации в собранном виде",
          "После монтажа сосуда, поставляемого отдельными блоками, окончательную сборку которого с применением сварных соединений производят при монтаже на месте его эксплуатации",
          "После монтажа без применения неразъемных соединений сосуда, демонтированного и установленного на новом месте",
        ],
        correctAnswers: [
          "После монтажа сосуда, поставляемого отдельными блоками, окончательную сборку которого с применением сварных соединений производят при монтаже на месте его эксплуатации",
        ],
      },
      {
        code: "68405",
        text: "В каком случае в состав комиссии по проверке готовности сосуда к пуску в работу и организации надзора за его эксплуатацией включаются уполномоченный (уполномоченные) представитель (представители) Ростехнадзора или его территориального органа?",
        answers: [
          "При осуществлении проверок сосудов, работающих под вакуумом, а также установленных на самолетах и других летательных аппаратах",
          "При осуществлении проверок сосудов, эксплуатирующихся в количестве более трех на один опасный производственный объект",
          "При осуществлении проверок сосудов, подлежащих учету в территориальных органах Ростехнадзора",
          "Участие представителей Ростехнадзора в комиссии по проверке готовности сосудов к пуску в работу и организации надзора за их эксплуатацией не предусматривается действующими нормативными документами",
        ],
        correctAnswers: [
          "При осуществлении проверок сосудов, подлежащих учету в территориальных органах Ростехнадзора",
        ],
      },
      {
        code: "68406",
        text: "Что контролируется при проведении проверки готовности сосуда к пуску в работу?",
        answers: [
          "Наличие акта готовности сосуда к вводу в эксплуатацию на основании его фактического состояния и соответствия представленной проектной и технической документации",
          "Наличие производственных инструкций для обслуживающего персонала и эксплуатационной документации, их соответствие требованиям нормативных документов",
          "Правильность установки и включения сосуда в соответствии с паспортом и руководством (инструкцией) по эксплуатации",
          "Наличие положительных результатов технического освидетельствования сосуда",
        ],
        correctAnswers: [
          "Наличие положительных результатов технического освидетельствования сосуда",
        ],
      },
      {
        code: "68407",
        text: "Каким образом должны оформляться результаты проверок готовности сосуда к пуску в работу и организации надзора за его эксплуатацией?",
        answers: [
          "Приказом эксплуатирующей организации или ее обособленного структурного подразделения",
          "Актом готовности сосуда к вводу в эксплуатацию",
          "Записью в паспорт сосуда и ремонтный журнал",
          "Протоколом о готовности сосуда к пуску в работу и эксплуатации",
        ],
        correctAnswers: ["Актом готовности сосуда к вводу в эксплуатацию"],
      },
      {
        code: "68408",
        text: "На основании какого документа осуществляется пуск (включение) в работу и штатная остановка сосуда?",
        answers: [
          "На основании приказа руководителя (заместителя руководителя) эксплуатирующей организации",
          "На основании письменного приказа (распоряжения) ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования, работающего под давлением",
          "На основании письменного распоряжения ответственного за исправное состояние и безопасную эксплуатацию сосуда",
        ],
        correctAnswers: [
          "На основании письменного распоряжения ответственного за исправное состояние и безопасную эксплуатацию сосуда",
        ],
      },
      {
        code: "68409",
        text: "Что из приведенного не указывается на табличке или не наносится на сосуды (кроме транспортируемых баллонов вместимостью до 100 литров) перед пуском их в работу?",
        answers: [
          "Дата ввода в эксплуатацию",
          "Номер сосуда по системе нумерации, принятой в эксплуатирующей организации",
          "Разрешенные давление и температура рабочей среды",
          "Дата истечения срока службы, установленного организацией-изготовителем или указанного в заключении экспертизы промышленной безопасности",
        ],
        correctAnswers: ["Дата ввода в эксплуатацию"],
      },
      {
        code: "68410",
        text: "Какой из приведенных сосудов подлежит учету в территориальных органах Ростехнадзора?",
        answers: [
          "Сосуд, работающий со средой 1-й группы (согласно TP ТС 032/2013) при температуре стенки 150 °С, у которого произведение значений рабочего давления (МПа) и вместимости (м³) равняется 0,03",
          "Баллон вместимостью 90 л, установленный стационарно, предназначенный для хранения сжатых газов",
          "Барокамера вместимостью 0,8 м³, работающая с кислородом давлением 0,3 МПа",
          "Одноместная медицинская барокамера вместимостью 2 м³, работающая под давлением 0,05 МПа",
        ],
        correctAnswers: [
          "Барокамера вместимостью 0,8 м³, работающая с кислородом давлением 0,3 МПа",
        ],
      },
      {
        code: "68411",
        text: "Какая документация не представляется эксплуатирующей организацией в орган Ростехнадзора для постановки на учет сосуда, проверка готовности к вводу в эксплуатацию которого проводилась без участия уполномоченного представителя Ростехнадзора?",
        answers: [
          "Заявление, содержащее информацию об эксплуатирующей организации с указанием места установки стационарного сосуда",
          "Копии акта готовности сосуда к вводу в эксплуатацию и распорядительного документа о вводе его в эксплуатацию, а также реквизиты документации, подтверждающей соответствие оборудования требованиям законодательства РФ о техническом регулировании и статьи 7 Федерального закона N 116-ФЗ",
          "Паспорт сосуда, удостоверение о качестве монтажа, инструкция изготовителя по монтажу и эксплуатации сосуда",
          "Краткие сведения о подлежащем учету сосуде, указанные в паспорте",
        ],
        correctAnswers: [
          "Паспорт сосуда, удостоверение о качестве монтажа, инструкция изготовителя по монтажу и эксплуатации сосуда",
        ],
      },
      {
        code: "68412",
        text: "По какой из приведенных формул определяется значение пробного давления () при гидравлическом испытании (периодическое техническое освидетельствование) литых и кованых металлических сосудов? Где в формулах:  - рабочее давление сосуда,  - расчетное давление сосуда, ,  - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа.",
        answers: ["= 1,5( / )", "= 1,25( / )", "= 1,15( / )", "= 1,15( / )"],
        correctAnswers: ["= 1,5( / )"],
      },
      {
        code: "68413",
        text: "По какой из приведенных формул определяется значение пробного давления () при гидравлическом испытании (периодическое техническое освидетельствование) металлических сосудов (за исключением литых)? Где в формулах:  - рабочее давление сосуда,  - расчетное давление сосуда, ,  - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа.",
        answers: ["= 1,25( / )", "= 1,25( / )", "= 1,15( / )", "= 1,5"],
        correctAnswers: ["= 1,25( / )"],
      },
      {
        code: "68414",
        text: "По какой из приведенных формул определяется значение пробного давления () при гидравлическом испытании (периодическое техническое освидетельствование) сосудов, изготовленных из неметаллических материалов с ударной вязкостью более 20 Дж/см²? Где в формулах:  - рабочее давление сосуда,  - расчетное давление сосуда, ,  - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа.",
        answers: ["= 1,3( / )", "= 1,6( / )", "= 1,6( / )", "= 1,25( / )"],
        correctAnswers: ["= 1,3( / )"],
      },
      {
        code: "68415",
        text: "Чему равно минимальное значение времени выдержки сосуда под пробным давлением при пневматическом испытании?",
        answers: ["5 минут", "15 минут", "20 минут", "30 минут"],
        correctAnswers: ["15 минут"],
      },
      {
        code: "68416",
        text: "В течение какого времени проводится комплексное опробование котлов, сосудов и трубопроводов пара и горячей воды?",
        answers: [
          "Котлы - в течение 96 часов, трубопроводы тепловых сетей - в течение 48 часов, остальное оборудование - по программе комплексного опробования, разработанной эксплуатирующей организацией и согласованной с организацией, проводящей соответствующие работы",
          "Котлы - в течение 72 часов, трубопроводы тепловых сетей - в течение 24 часов, остальное оборудование - по программе комплексного опробования, разработанной организацией, проводящей соответствующие работы, и согласованной с эксплуатирующей организацией",
          "В течение 72 часов по программе, разработанной эксплуатирующей организацией и согласованной с Ростехнадзором",
        ],
        correctAnswers: [
          "Котлы - в течение 72 часов, трубопроводы тепловых сетей - в течение 24 часов, остальное оборудование - по программе комплексного опробования, разработанной организацией, проводящей соответствующие работы, и согласованной с эксплуатирующей организацией",
        ],
      },
      {
        code: "68417",
        text: "С какой периодичностью проводится проверка знаний рабочих, обслуживающих сосуды?",
        answers: [
          "Периодичность устанавливается распорядительными документами эксплуатирующей организации",
          "Один раз в 12 месяцев",
          "Один раз в 6 месяцев",
          "Один раз в 24 месяца",
        ],
        correctAnswers: ["Один раз в 12 месяцев"],
      },
      {
        code: "68418",
        text: "Какое из приведенных требований к проверке знаний рабочих, обслуживающих сосуды, указано неверно?",
        answers: [
          "Удостоверение о допуске к самостоятельной работе должно содержать краткие сведения о видах работ (и оборудования), к самостоятельному выполнению (обслуживанию) которых допущен работник, обслуживающий барокамеры",
          "Участие представителя Ростехнадзора обязательно при проведении первичной аттестации персонала, обслуживающего барокамеры, работающие под давлением кислорода",
          "Внеочередная проверка знаний работника, обслуживающего барокамеру, должна проводиться при его переводе на обслуживание барокамеры другого типа",
          "Работник, обслуживающий барокамеру, после проведения проверки знаний должен быть ознакомлен с ее результатами под подпись",
        ],
        correctAnswers: [
          "Участие представителя Ростехнадзора обязательно при проведении первичной аттестации персонала, обслуживающего барокамеры, работающие под давлением кислорода",
        ],
      },
      {
        code: "68419",
        text: "В каком из приведенных случаев до проверки знаний рабочий, обслуживающий сосуды, должен пройти стажировку?",
        answers: [
          "Только перед допуском к самостоятельной работе после профессионального обучения",
          "Только перед допуском к самостоятельной работе после внеочередной проверки знаний",
          "Только при перерыве в работе по специальности более 12 месяцев",
          "Во всех приведенных случаях проводится стажировка",
        ],
        correctAnswers: ["Во всех приведенных случаях проводится стажировка"],
      },
      {
        code: "68420",
        text: "Манометры какого класса точности необходимо применять при эксплуатации сосудов с рабочим давлением до 2,5 МПа включительно?",
        answers: ["Не ниже 0,6", "Не ниже 2,5", "Не ниже 1,5", "Не ниже 0,4"],
        correctAnswers: ["Не ниже 2,5"],
      },
      {
        code: "68421",
        text: "Манометры какого класса точности необходимо применять при эксплуатации сосудов с рабочим давлением более 2,5 МПа?",
        answers: ["Не ниже 0,6", "Не ниже 2,5", "Не ниже 1,5", "Не ниже 1,0"],
        correctAnswers: ["Не ниже 1,5"],
      },
      {
        code: "68422",
        text: "Какое из приведенных требований к манометрам, устанавливаемым на сосудах, указано неверно?",
        answers: [
          "На шкале манометра сосуда должна быть нанесена красная черта, указывающая расчетное давление в сосуде",
          "Взамен красной черты разрешается прикреплять к корпусу манометра пластину из металла или иного материала достаточной прочности, окрашенную в красный цвет и плотно прилегающую к стеклу манометра",
          "Манометр должен быть выбран с такой шкалой, чтобы предел измерения рабочего давления находился во второй трети шкалы",
          "Все приведенные требования указаны верно",
        ],
        correctAnswers: [
          "На шкале манометра сосуда должна быть нанесена красная черта, указывающая расчетное давление в сосуде",
        ],
      },
      {
        code: "68423",
        text: "Каково минимальное значение номинального диаметра манометра, устанавливаемого на сосуде на высоте менее 2 м от уровня площадки наблюдения?",
        answers: ["80 мм", "100 мм", "160 мм", "250 мм"],
        correctAnswers: ["100 мм"],
      },
      {
        code: "68424",
        text: "Каково минимальное значение номинального диаметра манометра, устанавливаемого на сосуде на высоте от 2 до 3 м включительно от уровня площадки наблюдения?",
        answers: ["63 мм", "100 мм", "160 мм", "250 мм"],
        correctAnswers: ["160 мм"],
      },
      {
        code: "68425",
        text: "Каково минимальное значение номинального диаметра манометра, устанавливаемого на сосуде на высоте более 3 м от уровня площадки наблюдения?",
        answers: [
          "100 мм",
          "160 мм",
          "250 мм",
          "Установка манометра на такой высоте не разрешается",
        ],
        correctAnswers: ["Установка манометра на такой высоте не разрешается"],
      },
      {
        code: "68426",
        text: "В каком из приведенных случаев манометр может быть допущен к применению на сосуде?",
        answers: [
          "Если отсутствует информация о проведении поверки (пломба или клеймо, или документ о проведении поверки)",
          "Если стрелка манометра при его отключении не возвращается к нулевой отметке шкалы на величину, не превышающую половины допускаемой погрешности для манометра",
          "Если разбито стекло или имеются другие повреждения манометра, которые могут отразиться на правильности его показаний",
          "Если истек срок поверки манометра",
        ],
        correctAnswers: [
          "Если стрелка манометра при его отключении не возвращается к нулевой отметке шкалы на величину, не превышающую половины допускаемой погрешности для манометра",
        ],
      },
      {
        code: "68427",
        text: "Какое требование к проверке исправности манометра, установленного на сосуде, указано неверно?",
        answers: [
          "Проверка исправности манометра должна проводиться с помощью трехходового крана или заменяющих его запорных вентилей путем установки стрелки манометра на нуль",
          "Эксплуатирующая организация обязана не реже одного раза в 6 месяцев проводить проверку рабочих манометров контрольным манометром или рабочим манометром, имеющим одинаковые с проверяемым манометром шкалу и класс точности",
          "Поверка манометров с их опломбированием или клеймением должна быть произведена не реже одного раза в 12 месяцев, если иные сроки не установлены в документации на манометр",
          "Все приведенные требования указаны верно",
        ],
        correctAnswers: [
          "Эксплуатирующая организация обязана не реже одного раза в 6 месяцев проводить проверку рабочих манометров контрольным манометром или рабочим манометром, имеющим одинаковые с проверяемым манометром шкалу и класс точности",
        ],
      },
      {
        code: "68428",
        text: "Какое из приведенных требований к оснащению сосуда, рассчитанного на давление, меньше давления питающего его источника, указано неверно?",
        answers: [
          "На подводящем трубопроводе сосуда необходимо устанавливать автоматическое редуцирующее устройство с манометром и предохранительным устройством, установленными на стороне меньшего давления после редуцирующего устройства",
          "Допускается установка одного редуцирующего устройства с манометром и предохранительным клапаном на общем для группы сосудов, работающих при одном и том же давлении, подводящем трубопроводе до первого ответвления к одному из сосудов",
          "На подводящем трубопроводе, включая ответвления от общего трубопровода к каждому сосуду и байпасные линии, должны устанавливаться регуляторы расхода и предохранительные клапаны, отрегулированные на рабочие параметры сосудов",
        ],
        correctAnswers: [
          "На подводящем трубопроводе, включая ответвления от общего трубопровода к каждому сосуду и байпасные линии, должны устанавливаться регуляторы расхода и предохранительные клапаны, отрегулированные на рабочие параметры сосудов",
        ],
      },
      {
        code: "68429",
        text: "При каком минимальном избыточном давлении в сосуде (барокамере) допускается открытие ее люка и проведение ремонта сосуда и его элементов?",
        answers: [
          "Не допускается открывание люка барокамеры и ремонт при наличии в ней давления",
          "При давлении, равном 0,03 МПа",
          "Для сосудов (барокамер), не подлежащих учету в Ростехнадзоре, - при давлении, равном 0,02 МПа, для сосудов (барокамер), подлежащих учету в Ростехнадзоре, не допускается ремонт сосуда (барокамеры) и его элементов, находящихся под давлением",
          "При давлении, равном 0,01 МПа",
        ],
        correctAnswers: [
          "Не допускается открывание люка барокамеры и ремонт при наличии в ней давления",
        ],
      },
      {
        code: "68430",
        text: "Каким документом определяется порядок действия в случае инцидента при эксплуатации сосуда?",
        answers: [
          "Производственной инструкцией, утвержденной эксплуатирующей организацией",
          "Проектной документацией организации - изготовителя сосуда",
          "Инструкцией (руководством) по эксплуатации сосуда",
        ],
        correctAnswers: [
          "Производственной инструкцией, утвержденной эксплуатирующей организацией",
        ],
      },
      {
        code: "68431",
        text: "Что из приведенного не входит в должностные обязанности ответственного за осуществление производственного контроля за безопасной эксплуатацией сосудов?",
        answers: [
          "Проверка соблюдения установленного порядка допуска рабочих к самостоятельной работе, а также выдачи им производственных инструкций",
          "Проверка записи в сменном журнале с росписью в нем",
          "Контроль своевременности и полноты проведения ремонта сосудов",
          "Требование отстранения от работ и проведения внеочередной проверки знаний для работников, нарушающих требования промышленной безопасности",
        ],
        correctAnswers: ["Проверка записи в сменном журнале с росписью в нем"],
      },
      {
        code: "68432",
        text: "Какие из приведенных мест не подлежат оборудованию аварийным освещением?",
        answers: [
          "Щиты и пульты управления",
          "Водоуказательные и измерительные приборы",
          "Места установки насосного оборудования",
          "Все приведенные места подлежат оборудованию аварийным освещением",
        ],
        correctAnswers: [
          "Все приведенные места подлежат оборудованию аварийным освещением",
        ],
      },
      {
        code: "68433",
        text: "Что из приведенного в соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, не регламентируется производственной инструкцией по режиму работы и безопасному обслуживанию сосудов?",
        answers: [
          "Порядок проведения технического освидетельствования сосудов, не подлежащих учету в территориальном органе Ростехнадзора",
          "Меры безопасности при выводе оборудования в ремонт",
          "Случаи, требующие немедленной остановки сосуда",
          "Порядок, сроки и способы проверки арматуры, предохранительных устройств, приборов автоматики защиты и сигнализации",
        ],
        correctAnswers: [
          "Порядок проведения технического освидетельствования сосудов, не подлежащих учету в территориальном органе Ростехнадзора",
        ],
      },
      {
        code: "68434",
        text: "Каково максимально допустимое значение давления при работающих предохранительных клапанах в сосуде с давлением от 0,3 до 6 МПа включительно?",
        answers: [
          "Превышающее разрешенное давление на 15 %",
          "Превышающее разрешенное давление на 20 %",
          "Превышающее разрешенное давление на 25 %",
          "Превышающее разрешенное давление на 30 %",
        ],
        correctAnswers: ["Превышающее разрешенное давление на 15 %"],
      },
      {
        code: "68435",
        text: "Каково максимально допустимое значение давления при работающих предохранительных клапанах в сосуде с давлением менее 0,3 МПа?",
        answers: [
          "Превышающее разрешенное давление на 0,05 МПа",
          "Превышающее разрешенное давление на 10 %",
          "Превышающее разрешенное давление на 15 %",
          "Превышающее разрешенное давление на 0,5 МПа",
        ],
        correctAnswers: ["Превышающее разрешенное давление на 0,05 МПа"],
      },
      {
        code: "68436",
        text: "В какой документ заносятся результаты проверки исправности предохранительных устройств, установленных на сосуде, и сведения об их настройке?",
        answers: [
          "В паспорт сосуда и производственную инструкцию",
          "В журнал технического обслуживания и ремонта сосудов, работающих под давлением",
          "В сменный (оперативный) журнал или иные эксплуатационные документы",
          "В эксплуатационный паспорт предохранительного клапана",
        ],
        correctAnswers: [
          "В сменный (оперативный) журнал или иные эксплуатационные документы",
        ],
      },
      {
        code: "68437",
        text: "Каким документом (документами) устанавливается объем работ, порядок и периодичность проведения технических освидетельствований в пределах срока службы сосуда?",
        answers: [
          "Руководством (инструкцией) по эксплуатации и требованиями ФНП ОРПД",
          "Программой проведения технического освидетельствования сосуда и паспортом сосуда",
          "Производственной инструкцией по эксплуатации сосуда",
        ],
        correctAnswers: [
          "Руководством (инструкцией) по эксплуатации и требованиями ФНП ОРПД",
        ],
      },
      {
        code: "68438",
        text: "Какие условия должны соблюдаться при установлении срока следующего периодического технического освидетельствования сосуда?",
        answers: [
          "Допускается превышение срока следующего периодического технического освидетельствования над сроком службы сосуда, установленным организацией-изготовителем или заключением экспертизы промышленной безопасности, в случае продления срока службы трубопровода или по результатам технического диагностирования, но не более чем на 12 месяцев",
          "Если срок периодического освидетельствования не установлен изготовителем сосуда в руководстве (инструкции) по эксплуатации, то он должен устанавливаться экспертной организацией",
          "Срок следующего освидетельствования не должен превышать срока службы сосуда, установленного либо изготовителем, либо по результатам технического диагностирования сосуда",
        ],
        correctAnswers: [
          "Срок следующего освидетельствования не должен превышать срока службы сосуда, установленного либо изготовителем, либо по результатам технического диагностирования сосуда",
        ],
      },
      {
        code: "68439",
        text: "Что необходимо предпринять, если при освидетельствовании сосуда будут обнаружены дефекты?",
        answers: [
          "Для установления характера и размеров дефектов должно быть проведено техническое диагностирование сосуда с применением методов неразрушающего контроля",
          "Необходимо продолжить эксплуатацию сосуда на пониженных параметрах давления и температуры до ближайшего технического диагностирования или планового ремонта, уведомив о выявленных дефектах территориальный орган Ростехнадзора",
          "Следует немедленно вывести сосуд из эксплуатации до осмотра экспертами специализированной организации совместно с представителем (представителями) территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "Для установления характера и размеров дефектов должно быть проведено техническое диагностирование сосуда с применением методов неразрушающего контроля",
        ],
      },
      {
        code: "68440",
        text: "Каким документом определяются объем, методы и периодичность технических освидетельствований сосудов (за исключением баллонов)?",
        answers: [
          "Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением",
          "Руководством (инструкцией) по эксплуатации",
          "Производственной инструкцией по обслуживанию сосуда",
          "Паспортом сосуда",
        ],
        correctAnswers: ["Руководством (инструкцией) по эксплуатации"],
      },
      {
        code: "68441",
        text: "Кем проводятся технические освидетельствования сосудов, не подлежащих учету в территориальном органе Ростехнадзора?",
        answers: [
          "Аттестованными работниками специализированной организации",
          "Ответственными специалистами эксплуатирующей организации",
          "Независимыми экспертами",
        ],
        correctAnswers: [
          "Ответственными специалистами эксплуатирующей организации",
        ],
      },
      {
        code: "68442",
        text: "Какая из приведенных операций подлежит обязательному включению в объем работ по первичному техническому освидетельствованию сосудов, смонтированных на месте эксплуатации?",
        answers: [
          "Контроль поверхностей сосудов, а также толщины стенок элементов сосудов, работающих под давлением коррозионно-агрессивных сред",
          "Проведение гидравлических испытаний",
          "Проверка соответствия монтажа, обвязки трубопроводами, оснащения контрольно-измерительными приборами и предохранительными устройствами сосуда требованиям проектной и технической документации",
          "Все приведенные операции подлежат обязательному включению при проведении первичного освидетельствования сосуда",
        ],
        correctAnswers: [
          "Все приведенные операции подлежат обязательному включению при проведении первичного освидетельствования сосуда",
        ],
      },
      {
        code: "68443",
        text: "Какое из приведенных требований должно выполняться при проведении гидравлического испытания сосуда?",
        answers: [
          "Время выдержки под пробным давлением сосуда, находящегося в эксплуатации, должно определяться руководством по эксплуатации",
          "Давление воды при гидравлическом испытании следует контролировать не менее чем 2 манометрами одинаковых классов точности (не ниже 0,6)",
          "При заполнении сосуда водой воздух из него должен быть удален не менее чем на 80 %",
          "Общее время подъема давления (до значения пробного) должно быть не менее 45 минут",
        ],
        correctAnswers: [
          "Время выдержки под пробным давлением сосуда, находящегося в эксплуатации, должно определяться руководством по эксплуатации",
        ],
      },
      {
        code: "68444",
        text: "Каким образом допускается маркировать сварное соединение, выполненное несколькими сварщиками (бригадой сварщиков)?",
        answers: [
          "Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
          "Допускается применение клейма руководителя бригады сварщиков",
          "На сварных соединениях должны быть поставлены клейма каждого сварщика, выполняющего сварку",
          "Допускается применение клейма любого из сварщиков, выполняющих сварку",
        ],
        correctAnswers: [
          "Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
        ],
      },
      {
        code: "68445",
        text: "Какие функции обязано выполнить лицо, осуществляющее руководство сварочными работами, назначенное распорядительным документом организации или (и) должностной инструкцией которого предусмотрено руководство сварочными работами, перед выполнением сварочных работ?",
        answers: [
          "Проверить и обеспечить соответствие численного состава и квалификации персонала сварочного производства, сборочного и сварочного оборудования, основных и сварочных материалов, применяемой технологии сварки требованиям производственно-технологической документации",
          "Ознакомить сварщиков с требованиями технологических карт сварки, а также с внесенными в них изменениями (при наличии) с подтверждением ознакомления в применяемых ими технологических картах сварки",
          "Организовать проведение операционного контроля",
          "Все перечисленные функции",
        ],
        correctAnswers: ["Все перечисленные функции"],
      },
      {
        code: "68446",
        text: 'Какие требования предъявляются ФНП "Требования к производству сварочных работ на опасных производственных объектах" к сварочному оборудованию и сварочным материалам, применяемым при выполнении сварочных работ?',
        answers: [
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обеспечивать максимальную расчетную производительность работ, установленную НД и (или) проектной (конструкторской) документацией",
          "Оборудование и материалы должны соответствовать применяемым технологиям сварки, обладать сварочно-технологическими характеристиками, экономичностью и ремонтопригодностью в пределах допустимых значений, установленных уполномоченными специализированными организациями",
        ],
        correctAnswers: [
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "68447",
        text: "Какие действия должны быть осуществлены при выполнении многопроходных швов после наложения каждого валика поверхности шва и кромки разделки?",
        answers: [
          "Должны быть зачищены от шлака, брызг металла, обезжирены и проклеймены клеймами каждого сварщика, принимавшего участие в процессе сварки",
          "Должны быть обезжирены водными смывками и проверены ультразвуковым и радиографическим контролем на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)",
          "Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)",
        ],
        correctAnswers: [
          "Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)",
        ],
      },
      {
        code: "68448",
        text: "Какая документация оформляется в процессе выполнения сварочных работ?",
        answers: [
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
          "Только журналы сварочных работ, акты и заключения по неразрушающему контролю",
          "Только паспорта и протоколы испытаний сварных соединений",
        ],
        correctAnswers: [
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "68449",
        text: "Кто допускается к выполнению сварочных работ на опасном производственном объекте?",
        answers: [
          "Сварщики и специалисты сварочного производства, обладающие соответствующей квалификацией, имеющие группу по электробезопасности не ниже III, прошедшие обучение по оказанию первой помощи пострадавшим",
          "Сварщики и специалисты сварочного производства не моложе 18 лет, обладающие квалификацией, соответствующей применяемым технологиям сварки, прошедшие обучение по пожарной безопасности и оказанию первой помощи пострадавшим на производстве, аттестованные в соответствующей области промышленной безопасности",
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
        ],
        correctAnswers: [
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
        ],
      },
      {
        code: "68450",
        text: "К выполнению каких работ могут быть допущены сварщики и специалисты сварочного производства?",
        answers: [
          "К выполнению любых сварочных работ, по которым они когда-либо проходили обучение, независимо от срока действия соответствующих аттестационных удостоверений",
          "К выполнению сварочных работ, указанных в действующих документах",
          "К выполнению сварочных работ, указанных в наряде-допуске, при условии прохождения необходимых инструктажей, проверок знаний, стажировки и при наличии допуска к самостоятельной работе, оформленного в установленном порядке",
        ],
        correctAnswers: [
          "К выполнению сварочных работ, указанных в действующих документах",
        ],
      },
      {
        code: "68451",
        text: "Что должно быть указано в технологических картах сварки?",
        answers: [
          "Последовательность и особенности операций технологического цикла сварочных работ, требования к количеству и качеству сварочных материалов и оборудования, к количеству работников, необходимых для выполнения операций, виды контроля по окончании работ",
          "Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений",
          "Режимы сварки и необходимое количество сварочных материалов на каждом технологическом этапе сварочных работ",
        ],
        correctAnswers: [
          "Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений",
        ],
      },
      {
        code: "68452",
        text: "Какую проверку должен пройти сварщик, впервые приступающий к сварке, перед допуском к работе?",
        answers: [
          "Проверку знания методов и объемов неразрушающего контроля качества сварных соединений и механических испытаний",
          "Проверку путем практического устранения дефектов сварных соединений",
          "Проверку путем выполнения и контроля допускного сварного соединения",
        ],
        correctAnswers: [
          "Проверку путем выполнения и контроля допускного сварного соединения",
        ],
      },
      {
        code: "68453",
        text: "Требования какого документа должны обеспечиваться при изготовлении одноместных медицинских барокамер?",
        answers: [
          "Одноместные медицинские барокамеры должны быть изготовлены в соответствии с требованиями технического регламента ТР ТС 032/2013",
          "Одноместные медицинские барокамеры должны быть изготовлены в соответствии с требованиями ФНП",
          "Одноместные медицинские барокамеры должны быть изготовлены в соответствии с требованиями ФНП и технического регламента ТР ТС 032/2013",
        ],
        correctAnswers: [
          "Одноместные медицинские барокамеры должны быть изготовлены в соответствии с требованиями ФНП",
        ],
      },
      {
        code: "68454",
        text: "Порядок применения многоместных медицинских барокамер, выпущенных до вступления в силу технического регламента ТР ТС 032/2013?",
        answers: [
          "При эксплуатации барокамер, изготовленных и введенных в эксплуатацию до вступления в силу ТР ТС 032/2013, должно обеспечиваться их соответствие требованиям технической документации предприятия-изготовителя",
          "Для дальнейшей эксплуатации барокамер, изготовленных и введенных в эксплуатацию до вступления в силу ТР ТС 032/2013, должно быть получено разрешение Ростехнадзора после положительного заключения экспертизы промышленной безопасности",
          "Барокамеры, изготовленные и введенные в эксплуатацию до вступления в силу ТР ТС 032/2013, должны пройти внеочередное техническое освидетельствование для подтверждения соответствия требованиям ТР ТС 032/2013",
          "Барокамеры, изготовленные и введенные в эксплуатацию до вступления в силу ТР ТС 032/2013, необходимо реконструировать (модернизировать) для приведения их в соответствие требованиям ТР ТС 032/2013",
        ],
        correctAnswers: [
          "При эксплуатации барокамер, изготовленных и введенных в эксплуатацию до вступления в силу ТР ТС 032/2013, должно обеспечиваться их соответствие требованиям технической документации предприятия-изготовителя",
        ],
      },
      {
        code: "68455",
        text: "В соответствии с каким документом должна осуществляться эксплуатация медицинских стационарных барокамер?",
        answers: [
          "В соответствии с требованиями технического регламента ТР ТС 032/2013",
          "В соответствии с технической документацией изготовителя и требованиями ФНП",
          "В соответствии со всеми перечисленными документами и индивидуальными дополнительными требованиями к барокамерам, разработанными специализированными организациями в зависимости от мест их установки",
        ],
        correctAnswers: [
          "В соответствии с технической документацией изготовителя и требованиями ФНП",
        ],
      },
      {
        code: "68456",
        text: "Какая организация может осуществлять монтаж, наладку, техническое обслуживание и ремонт медицинских барокамер?",
        answers: [
          "Любая организация, имеющая лицензию на техническое обслуживание медицинской техники",
          "Специализированная организация, соответствующая требованиям главы IV ФНП ОРПД, имеющая лицензию на техническое обслуживание медицинской техники и согласование с территориальным органом Ростехнадзора",
          "Специализированная организация, соответствующая требованиям главы III ФНП ОРПД и имеющая лицензию на техническое обслуживание данного вида медицинской техники",
          "Любая организация, соответствующая требованиям раздела III ФНП ОРПД",
          "Специализированная организация, имеющая лицензию на деятельность по проведению экспертизы промышленной безопасности данного вида барокамер",
        ],
        correctAnswers: [
          "Специализированная организация, соответствующая требованиям главы III ФНП ОРПД и имеющая лицензию на техническое обслуживание данного вида медицинской техники",
        ],
      },
      {
        code: "68457",
        text: " Кто проводит проверку готовности одноместной медицинской барокамеры к работе после монтажа?",
        answers: [
          "Специализированная организация, имеющая лицензию на техническое обслуживание барокамер",
          "Специалисты эксплуатирующей организации",
          "Специализированная организация, имеющая лицензию на право проведения экспертизы промышленной безопасности медицинской техники",
          "Специалисты организации, осуществляющей монтаж барокамеры",
          "Уполномоченный специалист (специалисты) территориального органа Ростехнадзора",
        ],
        correctAnswers: ["Специалисты эксплуатирующей организации"],
      },
      {
        code: "68458",
        text: "Кто принимает решение о вводе в эксплуатацию одноместной медицинской барокамеры после монтажа?",
        answers: [
          "Специализированная организация, имеющая лицензию на техническое обслуживание барокамер",
          "Ответственный за исправное состояние и безопасную эксплуатацию барокамеры",
          "Специализированная организация, имеющая лицензию на право проведения экспертизы промышленной безопасности медицинской техники",
          "Ответственный за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением",
          "Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения)",
          "Уполномоченный представитель Ростехнадзора",
        ],
        correctAnswers: [
          "Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения)",
        ],
      },
      {
        code: "68459",
        text: "Каким должно быть значение расчетного давления одноместной медицинской барокамеры?",
        answers: [
          "Расчетное давление должно быть на 10 % выше, чем рабочее давление",
          "Расчетное давление должно быть не менее рабочего давления",
          "Расчетное давление должно составлять не менее 1,3 рабочего давления",
          "Расчетное давление должно быть равно минимальному рабочему давлению",
          "Расчетное давление должно быть равно максимальному рабочему давлению",
        ],
        correctAnswers: [
          "Расчетное давление должно быть на 10 % выше, чем рабочее давление",
        ],
      },
      {
        code: "68460",
        text: "На каком сосуде установка манометра и предохранительного клапана необязательна?",
        answers: [
          "На сосуде, у которого рабочее давление меньше давления питающего источника, и при условии, что в этом сосуде исключена возможность повышения давления до равного или большего, чем в питающем источнике",
          "На сосуде, у которого рабочее давление равно или больше давления питающего источника, и при условии исключения возможности повышения давления в сосуде",
          "Манометры и предохранительные клапаны должны устанавливаться на всех сосудах",
          "На сосуде, входящем в группу аналогичных сосудов, если манометр и предохранительный клапан установлены на общем трубопроводе до ответвлений к любому из сосудов группы",
        ],
        correctAnswers: [
          "На сосуде, у которого рабочее давление равно или больше давления питающего источника, и при условии исключения возможности повышения давления в сосуде",
        ],
      },
      {
        code: "68461",
        text: "Каким должно быть значение давления испытания на прочность одноместной медицинской барокамеры?",
        answers: [
          "Давление испытания барокамеры на прочность должно составлять 1,5 рабочего давления",
          "Давление испытания барокамеры на прочность должно быть на 10 % выше, чем рабочее давление",
          "Давление испытания барокамеры на прочность должно составлять 1,15 рабочего давления",
          "Давление испытания барокамеры на прочность должно быть равно максимальному рабочему давлению или больше него",
          "Давление испытания барокамеры на прочность должно быть не менее двойного рабочего давления",
        ],
        correctAnswers: [
          "Давление испытания барокамеры на прочность должно составлять 1,5 рабочего давления",
        ],
      },
      {
        code: "68462",
        text: "На какое значение давления срабатывания должен быть настроен предохранительный клапан одноместной медицинской барокамеры?",
        answers: [
          "Предохранительный клапан должен быть настроен на давление срабатывания не более 10 % от рабочего давления барокамеры",
          "Предохранительный клапан должен быть настроен на давление срабатывания не более 20 % от расчетного давления барокамеры",
          "Предохранительный клапан должен быть настроен на давление срабатывания не менее 15 % от рабочего давления барокамеры",
          "Давление срабатывания предохранительного клапана зависит от показателей, установленных организацией-изготовителем в руководстве (инструкции) по его эксплуатации",
        ],
        correctAnswers: [
          "Предохранительный клапан должен быть настроен на давление срабатывания не более 10 % от рабочего давления барокамеры",
        ],
      },
      {
        code: "68463",
        text: "С какой целью в ФНП установлены требования к геометрическим размерам одноместной медицинской барокамеры?",
        answers: [
          "Требования к размерам одноместной барокамеры устанавливаются с целью учета технологических особенностей производственного цикла организации-изготовителя",
          "Геометрические размеры барокамеры должны обеспечивать безопасное нахождение размещаемого внутри камеры человека",
          "Геометрические размеры барокамеры должны обеспечивать свободное размещение человека с учетом отклонений от стандартных размеров, а также с учетом параметров (объема, размеров, геометрических решений) помещения, в котором она устанавливается",
        ],
        correctAnswers: [
          "Геометрические размеры барокамеры должны обеспечивать безопасное нахождение размещаемого внутри камеры человека",
        ],
      },
      {
        code: "68464",
        text: "Для каких барокамер в ФНП допускается внутренний диаметр 600 мм?",
        answers: [
          "Для барокамер, устанавливаемых в помещениях площадью менее 12 м²",
          "Для барокамер, устанавливаемых в помещениях площадью менее 15 м²",
          "Для барокамер, устанавливаемых в помещениях медицинских организаций, рассчитанных на оказание помощи менее чем 10 000 человек",
          "Для барокамер, изготовленных и введенных в эксплуатацию до принятия ФНП",
          "Для барокамер, изготавливаемых на производстве с технологическими особенностями, не позволяющими выпускать барокамеры с большим диаметром",
        ],
        correctAnswers: [
          "Для барокамер, изготовленных и введенных в эксплуатацию до принятия ФНП",
        ],
      },
      {
        code: "68465",
        text: "Для каких барокамер в ФНП допускается внутренний диаметр менее 700 мм?",
        answers: [
          "Для барокамер, предназначенных для размещения в них пациентов весом не более 70 кг",
          "Для барокамер, предназначенных для размещения в них пациентов весом не более 80 кг",
          "Для барокамер, предназначенных для размещения в них пациентов весом не более 65 кг",
          "Для барокамер, устанавливаемых в помещениях площадью менее 10 м²",
          "Для барокамер, изготовленных и введенных в эксплуатацию до принятия ФНП",
        ],
        correctAnswers: [
          "Для барокамер, изготовленных и введенных в эксплуатацию до принятия ФНП",
        ],
      },
      {
        code: "68466",
        text: "Какие барокамеры должны оборудоваться устройствами оптической и звуковой сигнализации о превышении концентрации кислорода?",
        answers: [
          "Все барокамеры",
          "Барокамеры, работающие со средой сжатого кислорода",
          "Барокамеры, работающие со средой сжатого воздуха",
          "Многоместные медицинские барокамеры",
        ],
        correctAnswers: ["Барокамеры, работающие со средой сжатого воздуха"],
      },
      {
        code: "68467",
        text: "Чем должна быть оснащена барокамера для обеспечения контроля состояния находящегося в ней пациента?",
        answers: [
          "Барокамера должна быть оснащена автоматической системой мониторинга состояния пациента с передачей сигнала на пульт управления барокамерой",
          "Барокамера должна быть оснащена системой связи пациента с оператором в постоянном (фоновом) режиме, а также прозрачным куполом или иллюминаторами",
          "Барокамера должна быть оснащена автоматической дублирующей (резервной) системой подачи кислорода (сжатого воздуха), а также смотровым окном на одной из боковых стенок в области расположения головы пациента, обеспечивающим возможность наблюдения за его состоянием",
          "Барокамера должна быть оснащена запасным люком для эвакуации пациента, обеспечивающим механическое открытие барокамеры как изнутри, так и снаружи",
        ],
        correctAnswers: [
          "Барокамера должна быть оснащена системой связи пациента с оператором в постоянном (фоновом) режиме, а также прозрачным куполом или иллюминаторами",
        ],
      },
      {
        code: "68468",
        text: "Какие отсеки многоместной барокамеры допускается не оснащать предохранительным клапаном?",
        answers: [
          "Отсеки барокамеры, в случае если расчетное давление стенки их корпуса на 10 % выше рабочего давления",
          "Отсеки барокамеры, в случае если расчетное давление стенки их корпуса на 20 % выше максимального рабочего давления",
          "Каждый отсек барокамеры должен быть оснащен предохранительным клапаном",
          "Предкамеру или второй отсек барокамеры, в случае если предохранительный клапан установлен в основном отсеке (отсеках)",
        ],
        correctAnswers: [
          "Каждый отсек барокамеры должен быть оснащен предохранительным клапаном",
        ],
      },
      {
        code: "68469",
        text: "Чем должны быть оснащены устройства подачи воздуха в барокамеру или сброса воздуха из многоместной барокамеры для предотвращения травмирования пациента?",
        answers: [
          "Защитными решетками и прослойкой из плотной жесткой ткани",
          "Предохранительными клапанами",
          "Приспособлениями для герметизации",
          "Пневмоглушителями и защитными сетками",
        ],
        correctAnswers: ["Пневмоглушителями и защитными сетками"],
      },
      {
        code: "68470",
        text: "Какое напряжение электрического тока допускается для питания электрического оборудования, применяемого внутри многоместной барокамеры?",
        answers: ["Не более 42 В", "Не более 12 В", "Не более 36 В", "220 В"],
        correctAnswers: ["Не более 42 В"],
      },
      {
        code: "68471",
        text: "Где должен размещаться пульт управления подачей газа многоместной барокамеры?",
        answers: [
          "В каждом отсеке многоместной барокамеры",
          "Вне многоместной барокамеры",
          "В предкамере, а при ее отсутствии - в отсеке, указанном в проекте",
        ],
        correctAnswers: ["Вне многоместной барокамеры"],
      },
      {
        code: "68472",
        text: "Какими приборами для контроля газовой среды должны оснащаться многоместные барокамеры?",
        answers: [
          "Приборы контроля содержания кислорода и углекислого газа",
          "Приборы контроля содержания углекислого газа и азота",
          "Приборы контроля содержания кислорода и инертных газов",
        ],
        correctAnswers: [
          "Приборы контроля содержания кислорода и углекислого газа",
        ],
      },
      {
        code: "68473",
        text: "В каких случаях может быть предусмотрен периодический контроль газовой среды в многоместной барокамере?",
        answers: [
          "В случае пребывания пациентов в барокамере в общей сложности менее 5 часов в сутки",
          "Контроль процентного содержания кислорода и углекислого газа должен быть непрерывным",
          "В случае оборудования каждого отсека многоместной барокамеры системой видеонаблюдения и согласования графика периодического контроля с территориальным органом Ростехнадзора",
          "В случае если периодический контроль предусматривается руководством (инструкцией) по эксплуатации организации-изготовителя",
        ],
        correctAnswers: [
          "Контроль процентного содержания кислорода и углекислого газа должен быть непрерывным",
        ],
      },
      {
        code: "68474",
        text: "Какое из перечисленных требований указано неверно?",
        answers: [
          "Установка медицинских барокамер должна производиться стационарно в зданиях, предназначенных и оборудованных для размещения пациентов, а также в специальных транспортабельных контейнерах",
          "Запрещается размещать барокамеры в подвальных этажах",
          "Эксплуатация медицинских барокамер, установленных с нарушением проектной документации, допускается на основании обоснования безопасности, разработанного в соответствии с законодательством о промышленной безопасности",
          "Требования к размещению барокамеры в транспортабельных контейнерах, устанавливаемых на различных шасси или стационарно, а также к конструкции контейнера должны определяться разработчиком проекта и организацией-изготовителем",
          "Размещение многоместных медицинских барокамер должно обеспечивать удобство их монтажа и установки на первом этаже здания, за исключением случаев, обоснованных технологией их применения и проектной документацией, при этом должен быть проведен расчет на возможность установки барокамеры в помещении",
        ],
        correctAnswers: [
          "Эксплуатация медицинских барокамер, установленных с нарушением проектной документации, допускается на основании обоснования безопасности, разработанного в соответствии с законодательством о промышленной безопасности",
        ],
      },
      {
        code: "68475",
        text: "В каких случаях допускается размещение барокамер в цокольных этажах?",
        answers: [
          "Если обеспечено наличие хотя бы одного эвакуационного выхода из помещения",
          "Если вместимость барокамеры не более 1,6 м³",
          "Если барокамера размещена в цокольном этаже до вступления в силу ФНП ОРПД",
          "Размещение барокамер в подвальных и цокольных этажах не допускается",
          "Если барокамера оборудована сигнализацией о превышении или понижении пороговых значений процентного содержания кислорода и углекислого газа",
        ],
        correctAnswers: [
          "Размещение барокамер в подвальных и цокольных этажах не допускается",
        ],
      },
      {
        code: "68476",
        text: "Какое из перечисленных требований указано неверно?",
        answers: [
          "Все двери в барозале должны открываться наружу, при этом площадь окон и дверей определяется расчетом при проектировании и должна обеспечивать выпуск максимально возможного количества сжатого газа при его аварийном сбросе в случае аварии барокамеры",
          "Ширина дверных проемов эвакуационных выходов из барозалов, а также проходов в барозале между установленным оборудованием должна обеспечивать возможность беспрепятственного перемещения одноместных барокамер, в том числе для их эвакуации с пациентами при возникновении аварийной ситуации",
          "Допускается наличие одного эвакуационного выхода в обоснованных проектом случаях, если планировкой помещения барозала предусмотрено наличие двух и более проходов к эвакуационному выходу, исключающих необходимость передвижения людей мимо аварийно разгерметизировавшегося или загоревшегося оборудования",
          "Должно быть обеспечено наличие не менее двух эвакуационных выходов для барозалов, в которых предусмотрено размещение двух и более одноместных барокамер, и барозалов с многоместными барокамерами",
        ],
        correctAnswers: [
          "Должно быть обеспечено наличие не менее двух эвакуационных выходов для барозалов, в которых предусмотрено размещение двух и более одноместных барокамер, и барозалов с многоместными барокамерами",
        ],
      },
      {
        code: "68477",
        text: "Какое из перечисленных требований указано неверно?",
        answers: [
          "Размещение барокамер в помещении барозала должно определяться проектом",
          "Минимальное расстояние от выступающих частей барокамеры до стены или стационарно установленной медицинской аппаратуры должно составлять 1 метр",
          "Расстояние между барокамерами должно составлять не менее 1 метра, а от отопительных приборов и других источников тепла до барокамер - не менее 1,5 метра",
        ],
        correctAnswers: [
          "Расстояние между барокамерами должно составлять не менее 1 метра, а от отопительных приборов и других источников тепла до барокамер - не менее 1,5 метра",
        ],
      },
      {
        code: "68478",
        text: "Какое из перечисленных требований указано неверно?",
        answers: [
          "Барозал должен быть оборудован приточной и вытяжной вентиляцией",
          "Барозалы должны оснащаться системами связи, аварийной сигнализации, газового анализа (сигнализаторами) для контроля температуры, влажности и содержания кислорода в атмосфере барозала",
          "В помещении барозала, в котором установлены многоместные барокамеры, должен быть размещен индивидуальный изолирующий дыхательный аппарат или аппарат-самоспасатель оператора барокамеры",
          "Отопление барозалов должно осуществляться с применением водяного теплоносителя, температура которого не превышает 95 °C, а также электрических нагревательных приборов при условии их заземления",
          "В барозале должна быть предусмотрена система защитного заземления электрооборудования и независимая от нее система защиты барокамер и персонала от накопления статического электричества",
        ],
        correctAnswers: [
          "Отопление барозалов должно осуществляться с применением водяного теплоносителя, температура которого не превышает 95 °C, а также электрических нагревательных приборов при условии их заземления",
        ],
      },
      {
        code: "68479",
        text: "Какая из перечисленных обязанностей организации, эксплуатирующей медицинские барокамеры, указана неверно?",
        answers: [
          "Укомплектовать подразделение гипербарической оксигенации (ГБО) персоналом, обученным и допущенным в установленном порядке к самостоятельной работе на барокамере",
          "Установить порядок безопасного допуска пациентов в помещение барозала и их нахождения непосредственно в барокамере",
          "Обеспечить разработку и наличие инструкций по эксплуатации и техническому обслуживанию барокамер и технических систем отделения ГБО в соответствии с требованиями нормативной документации и руководств (инструкций) по эксплуатации оборудования",
          "Назначить приказом ответственного за безопасную эксплуатацию барокамеры (из числа технических специалистов подразделения ГБО или подразделения технической службы)",
        ],
        correctAnswers: [
          "Назначить приказом ответственного за безопасную эксплуатацию барокамеры (из числа технических специалистов подразделения ГБО или подразделения технической службы)",
        ],
      },
      {
        code: "68480",
        text: "Что из перечисленных обязанностей ответственного за безопасную эксплуатацию барокамеры указано неверно?",
        answers: [
          "Обеспечивать организацию и проведение проверки знаний персонала, непосредственно работающего на барокамере",
          "Участвовать в проведении периодического (планового) контроля барокамеры, барозалов и технических систем жизнеобеспечения подразделений гипербарической оксигенации",
          "При выявлении нарушений требований эксплуатационной документации выдавать указания по их устранению персоналу, непосредственно работающему на барокамере",
          "Обеспечивать выполнение предписаний",
          "Ежедневно проводить технический сеанс на барокамере с выдачей персоналу разрешения на допуск к работе",
        ],
        correctAnswers: [
          "Ежедневно проводить технический сеанс на барокамере с выдачей персоналу разрешения на допуск к работе",
        ],
      },
      {
        code: "68481",
        text: "Что из перечисленных обязанностей ответственного за исправное техническое состояние барокамеры указано неверно?",
        answers: [
          "Обеспечивать подготовку барокамеры к техническому освидетельствованию и (или) техническому диагностированию",
          "Ежедневно проводить проверку эксплуатационной готовности барозала, установленных в нем оборудования и технологических систем жизнеобеспечения подразделения гипербарической оксигенации (ГБО)",
          "Проводить периодический (плановый) контроль барокамеры, барозалов и технических систем жизнеобеспечения подразделения ГБО",
          "Периодически (не реже 1 раза в месяц) контролировать проведение ежедневного технического сеанса на барокамере",
          "Хранить проектную, приемо-сдаточную документацию на помещение, оборудование и технические системы подразделения ГБО",
        ],
        correctAnswers: [
          "Периодически (не реже 1 раза в месяц) контролировать проведение ежедневного технического сеанса на барокамере",
        ],
      },
      {
        code: "68482",
        text: "Какое из перечисленных требований к персоналу указано неверно?",
        answers: [
          "К обслуживанию барокамеры приказом руководителя медицинской и иной организации независимо от их формы собственности и ведомственной принадлежности (МО) допускается медицинский и технический персонал организации, имеющий квалификацию, соответствующую выполняемой ими работе",
          "В состав комиссии по проверке знаний персонала включают ответственного за осуществление производственного контроля, ответственных лиц отделения гипербарической оксигенации и централизованных технических служб (подразделений) медицинского учреждения, а также других специалистов по решению руководителя",
          "Периодическая проверка знаний должна проводиться 1 раз в 12 месяцев",
          "Первичную проверку знаний медицинского персонала проводят перед допуском к стажировке",
          "По предписанию ответственного за осуществление производственного контроля в случаях выявления нарушений требований безопасности должна проводиться внеочередная проверка знаний",
        ],
        correctAnswers: [
          "Первичную проверку знаний медицинского персонала проводят перед допуском к стажировке",
        ],
      },
      {
        code: "68483",
        text: "В соответствии с какой документацией осуществляется эксплуатация барокамеры?",
        answers: [
          "В соответствии с инструкцией по эксплуатации, разрабатываемой на основании требований технической документации на каждый конкретный тип барокамеры, с учетом местных условий",
          "В соответствии с проектной документацией барозала",
          "В соответствии с типовой инструкцией по эксплуатации барокамер с учетом требований территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "В соответствии с инструкцией по эксплуатации, разрабатываемой на основании требований технической документации на каждый конкретный тип барокамеры, с учетом местных условий",
        ],
      },
      {
        code: "68484",
        text: "Что из перечисленных работ, выполняемых медицинским работником ежедневно перед проведением первого лечебного сеанса с пациентом, указано неверно?",
        answers: [
          "Проверка записей в журнале регистрации сеансов гипербарической оксигенации",
          "Осмотр барокамеры",
          "Проверка исходного состояния барокамеры",
          "Технический сеанс (в течение 35 - 40 минут без пациента с проверкой исправности предохранительного клапана на отсутствие заклинивания)",
          "Проверка исправности системы связи при открытой крышке барокамеры",
        ],
        correctAnswers: [
          "Технический сеанс (в течение 35 - 40 минут без пациента с проверкой исправности предохранительного клапана на отсутствие заклинивания)",
        ],
      },
      {
        code: "68485",
        text: "Какой из приведенных случаев, при которых не допускается работа барокамеры, указан неверно?",
        answers: [
          "Наличие механических повреждений, которые могут привести к снижению прочности узлов, находящихся в процессе работы под давлением",
          "Неисправность системы кислородоснабжения, в том числе отсутствие штатного давления в подающем трубопроводе",
          "Неисправность системы связи с пациентом",
          "Отсутствие, повреждение или неисправность контрольно-измерительных приборов",
          "Неисправность всех предохранительных клапанов, установленных на барокамере и подводящих трубопроводах",
        ],
        correctAnswers: [
          "Неисправность всех предохранительных клапанов, установленных на барокамере и подводящих трубопроводах",
        ],
      },
      {
        code: "68486",
        text: "С какой периодичностью ответственный за исправное техническое состояние барокамеры должен проводить плановый (периодический) контроль технического состояния и исправности барокамеры?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в неделю",
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в 12 месяцев",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "68487",
        text: "Что из перечисленных работ, выполняемых ответственным за исправное техническое состояние барокамеры при плановом (периодическом) контроле технического состояния и исправности барокамеры, указано неверно?",
        answers: [
          "Проверка герметичности барокамеры",
          "Проверка исправности систем и узлов барокамеры, в том числе запорной и запорно-регулирующей арматуры и контрольно-измерительных приборов (манометров)",
          "Технический сеанс при выдержке барокамеры без пациента при рабочем давлении в течение 15 - 20 минут и кратковременном повышении давления до величины срабатывания предохранительного клапана",
        ],
        correctAnswers: [
          "Технический сеанс при выдержке барокамеры без пациента при рабочем давлении в течение 15 - 20 минут и кратковременном повышении давления до величины срабатывания предохранительного клапана",
        ],
      },
      {
        code: "68488",
        text: "Какое из перечисленных требований к проведению первичного технического освидетельствования барокамеры указано неверно?",
        answers: [
          "Первичное техническое освидетельствование поставляемой в сборе барокамеры должно проводиться организацией-изготовителем до установки на месте ее применения или специализированной организацией",
          "Первичное техническое освидетельствование должно включать проверку качества изготовления, осмотр, гидравлические испытания на прочность и пневматические испытания на герметичность и плотность",
          "О результатах первичного технического освидетельствования делают запись в паспорте барокамеры",
          "После установки барокамеры на месте ее применения проводят проверку технической документации, правильности установки и подключения барокамеры, осмотр барокамеры и гидравлические испытания",
        ],
        correctAnswers: [
          "После установки барокамеры на месте ее применения проводят проверку технической документации, правильности установки и подключения барокамеры, осмотр барокамеры и гидравлические испытания",
        ],
      },
      {
        code: "68489",
        text: "Какой из перечисленных случаев проведения внеочередного технического освидетельствования барокамеры указан неверно?",
        answers: [
          "Перед пуском в работу, если барокамера не эксплуатировалась более 12 месяцев",
          "Если барокамера была демонтирована и установлена в новом месте",
          "По требованию ответственных лиц эксплуатирующей организации",
          "При наличии повреждений, полученных в процессе эксплуатации, влияющих на безопасность эксплуатации барокамеры",
        ],
        correctAnswers: [
          "При наличии повреждений, полученных в процессе эксплуатации, влияющих на безопасность эксплуатации барокамеры",
        ],
      },
      {
        code: "68490",
        text: "Какой минимальный объем работ выполняется при периодическом техническом освидетельствовании барокамеры?",
        answers: [
          "Визуальный осмотр, проверка барокамеры и систем жизнеобеспечения в действии, проверка герметичности барокамеры рабочим давлением среды",
          "Визуальный осмотр и гидравлические испытания",
          "Техническое диагностирование и проверка действия систем жизнеобеспечения",
        ],
        correctAnswers: [
          "Визуальный осмотр, проверка барокамеры и систем жизнеобеспечения в действии, проверка герметичности барокамеры рабочим давлением среды",
        ],
      },
      {
        code: "68491",
        text: "Какое из требований к помещению для установки водолазных барокамер указано неверно?",
        answers: [
          "Барозалы должны иметь систему электроснабжения по 1 категории надежности в соответствии с требованиями Правил устройства электроустановок",
          "Все окна и двери в помещении барокамеры (барозале) должны открываться наружу, при этом необходимо производить расчет площади окон и дверей в случае, если проектом предусмотрен сброс сжатого газа из барокамер в помещение барозала",
          "Запрещается прокладка трубопроводов высокого давления кислорода и других газов с повышенным содержанием кислорода в помещении барозала, максимальное давление газов в таких трубопроводах допускается не более 7,0 МПа",
          "Количество эвакуационных выходов из помещения, где расположена барокамера, должно быть не менее двух",
        ],
        correctAnswers: [
          "Все окна и двери в помещении барокамеры (барозале) должны открываться наружу, при этом необходимо производить расчет площади окон и дверей в случае, если проектом предусмотрен сброс сжатого газа из барокамер в помещение барозала",
        ],
      },
      {
        code: "68492",
        text: "В каких случаях допускается предусматривать сброс газов кислорода или его смесей из барокамеры в помещение барозала?",
        answers: [
          "Если внутренний объем барокамер не превышает 20 % объема помещения барозала, в котором они установлены",
          "Если суммарная вместимость барокамер (м³) не превышает 15 % объема помещения барозала, в котором они установлены",
          "Если в помещении барозала установлено не более одной одноместной барокамеры (без учета его площади) или не более одной многоместной барокамеры при площади барозала не менее 15 м²",
          "Если на каждую одноместную барокамеру или на каждый отсек многоместной барокамеры приходится не менее 6 м² площади помещения барозала, в котором они установлены",
          "Для сброса газов из барокамеры в барозале должны быть проложены специальные трубопроводы, обеспечивающие отвод газов за пределы барозала",
        ],
        correctAnswers: [
          "Для сброса газов из барокамеры в барозале должны быть проложены специальные трубопроводы, обеспечивающие отвод газов за пределы барозала",
        ],
      },
      {
        code: "68493",
        text: "Какое из требований к арматуре, установленной на водолазной барокамере, указано неверно?",
        answers: [
          "Барокамеры диаметром 1200 мм и более должны быть оборудованы запорной арматурой, устанавливаемой непосредственно на корпусе барокамеры, как снаружи, так и внутри барокамеры",
          "Арматура должна быть установлена снаружи непосредственно на корпусе барокамеры или на подводящих трубопроводах и опломбирована в рабочем положении",
          "Перечень арматуры барокамеры и трубопроводов барозала, подлежащих опломбированию, и ее рабочее положение должны быть указаны в эксплуатационной документации",
          "Вся арматура систем подачи кислорода высокого давления (за исключением корпусов манометров и других изделий, не имеющих прямого контакта с кислородом), применяемая для подачи кислорода, должна быть выполнена из материалов, исключающих ее возгорание и горение в среде кислорода (повышенного его содержания)",
        ],
        correctAnswers: [
          "Арматура должна быть установлена снаружи непосредственно на корпусе барокамеры или на подводящих трубопроводах и опломбирована в рабочем положении",
        ],
      },
      {
        code: "68494",
        text: "Какое из требований к арматуре, установленной на водолазной барокамере, указано неверно?",
        answers: [
          "Вентили, устанавливаемые на кислородные трубопроводы, должны обеспечивать плавное повышение давления после их открывания, использование шаровых кранов не допускается",
          "Подключение оборудования и систем жизнеобеспечения к барокамере должно осуществляться с помощью запорных вентилей, установленных на корпусе барокамеры",
          "Все перепускные вентили должны иметь отличительный красный цвет ручек или выделяться красным квадратом на мнемосхеме панели",
        ],
        correctAnswers: [
          "Вентили, устанавливаемые на кислородные трубопроводы, должны обеспечивать плавное повышение давления после их открывания, использование шаровых кранов не допускается",
        ],
      },
      {
        code: "68495",
        text: "Какое из требований к установке на водолазной барокамере предохранительного клапана указано неверно?",
        answers: [
          "Предохранительные клапаны отсеков водолазной барокамеры должны быть подключены с помощью запорного клапана, обеспечивающего мгновенное запирание барокамеры в случае отказа предохранительного клапана (неправильного срабатывания)",
          "Предохранительные клапаны отсеков водолазной барокамеры должны быть присоединены напрямую к сбросным патрубкам, установка запорной арматуры до и после предохранительного клапана не допускается",
          "Для сброса газов из барокамеры в барозале должны быть проложены специальные трубопроводы, обеспечивающие отвод газов за пределы барозала, при этом не допускается совмещать сбросные трубопроводы воздуха и кислорода",
        ],
        correctAnswers: [
          "Предохранительные клапаны отсеков водолазной барокамеры должны быть присоединены напрямую к сбросным патрубкам, установка запорной арматуры до и после предохранительного клапана не допускается",
        ],
      },
      {
        code: "68496",
        text: "Какие работы выполняются при первичном техническом освидетельствовании барокамеры?",
        answers: [
          "Проверка технической документации, наружный и внутренний осмотр, гидравлические испытания на прочность, пневматические испытания на герметичность и плотность, проверка барокамеры в действии",
          "Наружный и внутренний осмотр, пневматические испытания на прочность",
          "Проверка технической документации, наружный осмотр, гидравлические испытания на прочность",
          "Наружный и внутренний осмотр, пневматические испытания на герметичность и плотность, проверка барокамеры в действии",
        ],
        correctAnswers: [
          "Проверка технической документации, наружный и внутренний осмотр, гидравлические испытания на прочность, пневматические испытания на герметичность и плотность, проверка барокамеры в действии",
        ],
      },
      {
        code: "68497",
        text: "Где может проводиться первичное техническое освидетельствование барокамеры?",
        answers: [
          "На предприятии-изготовителе",
          "После монтажа на месте установки",
          "В эксплуатирующей организации",
          "Все перечисленные случаи",
        ],
        correctAnswers: ["Все перечисленные случаи"],
      },
      {
        code: "68498",
        text: "В какие сроки проводится периодическое техническое освидетельствование водолазной барокамеры?",
        answers: [
          "Не реже 1 раза в 15 лет",
          "С периодичностью, установленной в руководстве по эксплуатации или иной технической документации изготовителя конкретного типа барокамеры, но не позднее 10 лет с начала эксплуатации",
          "С периодичностью, установленной в паспорте организации-изготовителя конкретного типа барокамеры, но не позднее 12 лет с начала эксплуатации",
        ],
        correctAnswers: [
          "С периодичностью, установленной в руководстве по эксплуатации или иной технической документации изготовителя конкретного типа барокамеры, но не позднее 10 лет с начала эксплуатации",
        ],
      },
      {
        code: "68499",
        text: "При каком значении пробного давления проводятся гидравлические испытания водолазной барокамеры при проведении периодического технического освидетельствования?",
        answers: [
          "1,25 от рабочего давления",
          "1,2 от минимального избыточного давления",
          "1,15 от рабочего давления",
          "1,45 от минимального избыточного давления",
        ],
        correctAnswers: ["1,25 от рабочего давления"],
      },
      {
        code: "68500",
        text: "Какие среды могут использоваться при проведении пневматических испытаний на герметичность и плотность барокамеры?",
        answers: ["Воздух", "Азот", "Гелий", "Все перечисленное"],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "68501",
        text: "Кто осуществляет проверку барокамеры в действии после монтажа?",
        answers: [
          "Комиссия, состав которой определяется распорядительным документом эксплуатирующей организации",
          "Работник, ответственный за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением",
          "Главный инженер эксплуатирующей организации совместно с представителем монтажной организации",
          "Ответственные специалисты монтажной организации",
          "Комиссия, состав которой определяется представителем территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "Комиссия, состав которой определяется распорядительным документом эксплуатирующей организации",
        ],
      },
    ],
    703: [
      {
        code: "68502",
        text: "Какие требования ФНП ОРПД к оснащению баллонов предохранительным клапаном указаны неверно?",
        answers: [
          "Баллоны вместимостью более 100 литров должны быть оснащены предохранительными клапанами",
          "При групповой установке баллонов допускается установка предохранительного клапана на всю группу баллонов",
          "Пропускная способность предохранительного клапана подтверждается расчетом",
          "При групповой установке баллонов предохранительным клапаном обязательно оснащается каждый баллон",
        ],
        correctAnswers: [
          "При групповой установке баллонов предохранительным клапаном обязательно оснащается каждый баллон",
        ],
      },
      {
        code: "68503",
        text: "На какие процессы не распространяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?",
        answers: [
          "На разработку (проектирование) прямоточного котла",
          "На размещение паровых котлов в здании котельного помещения опасного производственного объекта",
          "На техническое перевооружение опасного производственного объекта, на котором используются водогрейные котлы",
        ],
        correctAnswers: ["На разработку (проектирование) прямоточного котла"],
      },
      {
        code: "68504",
        text: "На какое оборудование, работающее под избыточным давлением, не распространяется действие Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?",
        answers: [
          "Сосуды вместимостью не более 0,025 м³, у которых произведение значений рабочего давления (МПа) и вместимости (м³) не превышает 0,02",
          "Цистерны и сосуды для сжатых, сжиженных газов, жидкостей и сыпучих тел, в которых избыточное давление создается периодически для их опорожнения",
          "Баллоны, предназначенные для сжатых, сжиженных и растворенных под давлением газов",
          "Цистерны и бочки для сжатых и сжиженных газов",
          "На все перечисленное оборудование",
        ],
        correctAnswers: [
          "Сосуды вместимостью не более 0,025 м³, у которых произведение значений рабочего давления (МПа) и вместимости (м³) не превышает 0,02",
        ],
      },
      {
        code: "68505",
        text: "Какую резьбу должны иметь боковые штуцера вентилей для баллонов, наполняемых водородом и кислородом?",
        answers: [
          "Для баллонов, наполняемых водородом, - правую, для баллонов, наполняемых кислородом, - левую",
          "Для баллонов, наполняемых водородом, - левую, для баллонов, наполняемых кислородом, - правую",
          "Для всех баллонов, независимо от наполняемого газа, боковые штуцера вентилей должны иметь левую резьбу",
          "Для всех баллонов, независимо от наполняемого газа, боковые штуцера вентилей должны иметь правую резьбу",
        ],
        correctAnswers: [
          "Для баллонов, наполняемых водородом, - левую, для баллонов, наполняемых кислородом, - правую",
        ],
      },
      {
        code: "68506",
        text: "Какие сведения из указанных не наносятся на верхнюю сферическую часть баллона?",
        answers: [
          "Сведения, подлежащие нанесению в соответствии с требованиями ТР ТС 032/2013",
          "Сведения о проведенном техническом освидетельствовании баллона: дата проведения; клеймо организации (индивидуального предпринимателя), проводившей техническое освидетельствование",
          "Сведения о наполнительной станции: дата наполнения и клеймо организации, проводившей наполнение баллона",
          "Масса баллона с учетом массы нанесенной краски, кольца для колпака и башмака, если таковые предусмотрены конструкцией, но без массы вентиля и колпака",
        ],
        correctAnswers: [
          "Сведения о наполнительной станции: дата наполнения и клеймо организации, проводившей наполнение баллона",
        ],
      },
      {
        code: "68507",
        text: "Какие действия должны выполняться ответственными лицами перед каждой заправкой баллонов, установленных стационарно, а также установленных постоянно на передвижных средствах, в которых хранятся сжатый воздух, кислород, азот, аргон и гелий с температурой точки росы минус 35°С и ниже, замеренной при давлении 15 МПа (150 кгс/см ) и выше, а также баллонов с обезвоженной углекислотой?",
        answers: [
          "Наружный осмотр",
          "Внутренний осмотр",
          "Гидравлическое испытание пробным давлением",
          "Все перечисленное",
          "Все ответы неверны",
        ],
        correctAnswers: ["Наружный осмотр"],
      },
      {
        code: "68508",
        text: "Что включает в себя масса баллона, за исключением баллона для ацетилена, указываемая на его верхней сферической части?",
        answers: [
          "Массу пустого баллона",
          "Масса пустого баллона с учетом массы нанесенной краски, кольца для колпака и башмака, если таковые предусмотрены конструкцией, но без массы вентиля и колпака",
          "Массу пустого баллона с учетом массы нанесенной краски, кольца для колпака и башмака, если таковые предусмотрены конструкцией, массы вентиля и колпака",
        ],
        correctAnswers: [
          "Масса пустого баллона с учетом массы нанесенной краски, кольца для колпака и башмака, если таковые предусмотрены конструкцией, но без массы вентиля и колпака",
        ],
      },
      {
        code: "68509",
        text: "Что включает в себя масса баллона для ацетилена, указываемая на его верхней сферической части?",
        answers: [
          "Указывается только масса пустого баллона",
          "Масса баллона без колпака, но с пористой массой и растворителем, башмаком, кольцом и вентилем",
          "Масса баллона с пористой массой и растворителем, башмаком, кольцом, но без учета массы вентиля и колпака",
        ],
        correctAnswers: [
          "Масса баллона без колпака, но с пористой массой и растворителем, башмаком, кольцом и вентилем",
        ],
      },
      {
        code: "68510",
        text: "Какое максимальное количество баллонов с негорючими и неядовитыми газами объемом 40 л допускается хранить в одном отсеке складского помещения?",
        answers: [
          "1000 баллонов",
          "2000 баллонов",
          "1700 баллонов",
          "1500 баллонов",
          "Все ответы неверны",
        ],
        correctAnswers: ["1000 баллонов"],
      },
      {
        code: "68511",
        text: "Перегородками какой высоты допускается отделять отсеки для хранения баллонов с негорючими и неядовитыми газами?",
        answers: [
          "Не менее 1,0 м",
          "Не менее 1,5 м",
          "Не менее 2,5 м",
          "Не более 2,0 м",
          "Не более 3,5 м",
        ],
        correctAnswers: ["Не менее 2,5 м"],
      },
      {
        code: "68512",
        text: "Какие требования к окраске надписей и баллонов указаны неверно?",
        answers: [
          "Окраску баллонов и нанесение надписей при эксплуатации производят организации-изготовители, наполнительные станции (пункты наполнения) или испытательные пункты (пункты проверки) в соответствии с требованиями ТР ТС 032/2013",
          "Требования к окраске и надписям, не установленные ТР ТС 032/2013, устанавливаются проектной документацией и (или) техническими условиями на продукцию, для хранения которой предназначены эти баллоны, и указываются в распорядительных документах",
          "Стационарно установленные баллоны вместимостью более 100 л допускается окрашивать в иные цвета с нанесением надписей и маркировки в соответствии с проектной документацией и руководством (инструкцией) по эксплуатации",
          "Баллоны вместимостью более 75 л допускается окрашивать в иные цвета с нанесением надписей и маркировки в соответствии с проектной документацией и руководством (инструкцией) по эксплуатации",
        ],
        correctAnswers: [
          "Баллоны вместимостью более 75 л допускается окрашивать в иные цвета с нанесением надписей и маркировки в соответствии с проектной документацией и руководством (инструкцией) по эксплуатации",
        ],
      },
      {
        code: "68513",
        text: "Кто обязан вести учет присвоенных шифров в журнале учета шифров клейм?",
        answers: [
          "Ответственный за проведение освидетельствования из числа специалистов, аттестованных в установленном порядке",
          "Организации - изготовители баллонов",
          "Территориальный орган Ростехнадзора",
          "Уполномоченные в установленном порядке специализированные организации, имеющие наполнительные станции (пункты наполнения)",
          "Уполномоченные в установленном порядке специализированные организации, имеющие испытательные пункты (пункты проверки)",
        ],
        correctAnswers: ["Территориальный орган Ростехнадзора"],
      },
      {
        code: "68514",
        text: "Существует ли разница в нанесении надписей на баллонах вместимостью более 12 литров и до 12 литров?",
        answers: [
          "Существует, размеры надписей и полос на баллонах вместимостью до 12 литров должны определяться в зависимости от величины боковой поверхности баллонов",
          "Разницы нет, надписи наносятся по окружности на длину не менее 1/4 окружности, а полосы - по всей окружности",
          "Существует, размеры надписей на баллонах вместимостью до 12 литров наносят на длину не менее 1/2 окружности, а для баллонов более 12 литров - на длину не менее 1/4 окружности",
          "Разница только в высоте букв: на баллонах вместимостью до 12 литров высота букв должна быть 40 мм, а на баллонах более 12 литров - 60 мм",
        ],
        correctAnswers: [
          "Существует, размеры надписей и полос на баллонах вместимостью до 12 литров должны определяться в зависимости от величины боковой поверхности баллонов",
        ],
      },
      {
        code: "68515",
        text: "Если изготовителем не установлено, то с какой периодичностью проводится гидравлическое испытание пробным давлением в процессе технического освидетельствования подлежащих учету в органах Ростехнадзора металлических баллонов, установленных стационарно, а также установленных постоянно на передвижных средствах, в которых хранится сжатый природный газ (компримированный)?",
        answers: [
          "1 раз в 5 лет",
          "1 раз в год",
          "1 раз в 3 года",
          "Все ответы неверны",
          "1 раз в 8 лет",
        ],
        correctAnswers: ["1 раз в 5 лет"],
      },
      {
        code: "68516",
        text: "Какой срок службы устанавливается для баллонов при отсутствии указаний организации-изготовителя?",
        answers: ["10 лет", "30 лет", "20 лет", "40 лет", "5 лет"],
        correctAnswers: ["20 лет"],
      },
      {
        code: "68517",
        text: "Какое подключаемое оборудование, трубопроводы и (или) гибкие рукава используются для подключения баллонов с горючими газами и кислородом с целью недопущения возгорания и взрыва?",
        answers: [
          "Взрывозащищенное оборудование, прошедшее оценку соответствия",
          "Оборудование, изготовленное из материала, отношение временного сопротивления к пределу текучести которого более 2, выдерживающее давление 1,25 от рабочего",
          "Все ответы неверны",
          "Соответствующее по материалам и прочности используемому газу в подключаемых баллонах",
          "Оборудование, соответствующее всему перечисленному",
        ],
        correctAnswers: [
          "Соответствующее по материалам и прочности используемому газу в подключаемых баллонах",
        ],
      },
      {
        code: "68518",
        text: "Допускается ли эксплуатация баллонов вместимостью менее 50 литров за пределами назначенного срока службы?",
        answers: [
          "Допускается на основании результатов экспертизы промышленной безопасности",
          "Не допускается",
          "Допускается, но только для баллонов специального назначения, конструкция которых определена индивидуальным проектом и не отвечает типовым конструкциям баллонов и экспертизу (техническое диагностирование) которых проводят по истечении срока службы, а также в случаях, установленных руководством (инструкцией) по эксплуатации оборудования, в составе которого они используются",
          "Допускается по результатам экспертизы промышленной безопасности, за исключением баллонов специального назначения, конструкция которых определена индивидуальным проектом и не отвечает типовым конструкциям баллонов и экспертизу (техническое диагностирование) которых не проводят по истечении срока службы",
        ],
        correctAnswers: [
          "Допускается, но только для баллонов специального назначения, конструкция которых определена индивидуальным проектом и не отвечает типовым конструкциям баллонов и экспертизу (техническое диагностирование) которых проводят по истечении срока службы, а также в случаях, установленных руководством (инструкцией) по эксплуатации оборудования, в составе которого они используются",
        ],
      },
      {
        code: "68519",
        text: "Какие требования к организациям, осуществляющим освидетельствование (испытания) баллонов, являются избыточными?",
        answers: [
          "Организация должна располагать производственными помещениями, а также техническими средствами, обеспечивающими возможность проведения освидетельствования баллонов",
          "В организации должны быть назначены приказом лица, ответственные за проведение освидетельствования, из числа специалистов, аттестованных в установленном порядке, и рабочих соответствующей квалификации",
          "Организация должна иметь клеймо с индивидуальным шифром",
          "Организация должна иметь не менее трех специалистов с соответствующим высшим профессиональным образованием",
          "В организации должна быть разработана производственная инструкция по проведению технического освидетельствования баллонов, устанавливающая объем и порядок проведения работ, составленная на основании методик разработчика проекта и (или) изготовителя конкретного типа баллонов",
        ],
        correctAnswers: [
          "Организация должна иметь не менее трех специалистов с соответствующим высшим профессиональным образованием",
        ],
      },
      {
        code: "68520",
        text: "Какая процедура из указанных не проводится при освидетельствовании стальных бесшовных баллонов до 12 литров включительно и свыше 55 литров, а также сварных баллонов независимо от вместимости?",
        answers: [
          "Осмотр внутренней поверхности баллона",
          "Проверка массы и вместимости баллона",
          "Гидравлическое испытание баллона",
        ],
        correctAnswers: ["Проверка массы и вместимости баллона"],
      },
      {
        code: "68521",
        text: "Какие данные выбивает (наносит) на баллоне организация, проводившая освидетельствование, при удовлетворительных результатах?",
        answers: [
          "Клеймо круглой формы диаметром 12 мм, дату проведенного и следующего освидетельствования",
          "Клеймо круглой формы диаметром 12 мм, дату проведенного освидетельствования",
          "Клеймо круглой формы диаметром 12 мм, дату следующего освидетельствования",
        ],
        correctAnswers: [
          "Клеймо круглой формы диаметром 12 мм, дату проведенного и следующего освидетельствования",
        ],
      },
      {
        code: "68522",
        text: "При какой вместимости баллонов результаты освидетельствования баллонов заносятся в паспорт баллона?",
        answers: [
          "Более 100 литров",
          "Менее 100 литров",
          "Результаты освидетельствования в паспорт баллона не заносятся, ставится только клеймо на баллоне",
        ],
        correctAnswers: ["Более 100 литров"],
      },
      {
        code: "68523",
        text: "В какие сроки проводится освидетельствование баллонов для ацетилена на ацетиленовых наполнительных станциях?",
        answers: [
          "В сроки, установленные изготовителем, но не реже чем через 3 года",
          "В сроки, установленные изготовителем, но не реже чем через 5 лет",
          "Не реже чем через год",
          "Не реже чем через 2 года",
        ],
        correctAnswers: [
          "В сроки, установленные изготовителем, но не реже чем через 5 лет",
        ],
      },
      {
        code: "68524",
        text: "Какая процедура из указанных не проводится при освидетельствовании баллонов для ацетилена?",
        answers: [
          "Осмотр наружной поверхности баллона",
          "Проверка пористой массы баллона",
          "Гидравлическое испытание баллона",
          "Пневматическое испытание баллона",
        ],
        correctAnswers: ["Гидравлическое испытание баллона"],
      },
      {
        code: "68525",
        text: "С какой периодичностью должно проверяться на ацетиленовых наполнительных станциях состояние пористой массы в баллонах для растворенного ацетилена?",
        answers: [
          "Не реже чем через 3 года",
          "Не реже чем через 5 лет",
          "Не реже чем через 24 месяца",
          "Не реже чем через 18 месяцев",
        ],
        correctAnswers: ["Не реже чем через 24 месяца"],
      },
      {
        code: "68526",
        text: "Где может быть проверено состояние пористой массы в баллонах для растворенного ацетилена?",
        answers: [
          "На любой наполнительной станции",
          "На ацетиленовой наполнительной станции",
          "Только в организации, занимающейся изготовлением таких баллонов",
          "В любой специализированной организации, имеющей испытательный пункт",
        ],
        correctAnswers: ["На ацетиленовой наполнительной станции"],
      },
      {
        code: "68527",
        text: "Какие данные выбиваются на ацетиленовых баллонах при удовлетворительном состоянии пористой массы?",
        answers: [
          'Год и месяц проверки пористой массы; индивидуальное клеймо наполнительной станции; клеймо диаметром 12 мм с изображением букв "Пм", удостоверяющее проверку пористой массы',
          'Год проверки пористой массы; индивидуальное клеймо наполнительной станции; клеймо диаметром 12 мм с изображением букв "МП", удостоверяющее проверку пористой массы',
          'Только год и месяц проверки пористой массы; клеймо диаметром 16 мм с изображением букв "Пм", удостоверяющее проверку пористой массы',
        ],
        correctAnswers: [
          'Год и месяц проверки пористой массы; индивидуальное клеймо наполнительной станции; клеймо диаметром 12 мм с изображением букв "Пм", удостоверяющее проверку пористой массы',
        ],
      },
      {
        code: "68528",
        text: "Чем испытывают баллоны для ацетилена, наполненные пористой массой, при освидетельствовании?",
        answers: ["Воздухом", "Азотом", "Гелием", "Любым инертным газом"],
        correctAnswers: ["Азотом"],
      },
      {
        code: "68529",
        text: "Под каким давлением азота проводится испытание баллона для ацетилена, наполненного пористой массой?",
        answers: [
          "2,5 МПа",
          "3,5 МПа",
          "2,0 МПа",
          "Устанавливается организацией-изготовителем",
        ],
        correctAnswers: ["3,5 МПа"],
      },
      {
        code: "68530",
        text: "Какой должна быть чистота азота, применяемого для испытания баллонов для растворенного ацетилена?",
        answers: [
          "Не ниже 90 % по объему",
          "Не ниже 95 % по объему",
          "Не ниже 97 % по объему",
          "Устанавливается организацией-изготовителем",
        ],
        correctAnswers: ["Не ниже 97 % по объему"],
      },
      {
        code: "68531",
        text: "Каким образом должны быть подготовлены баллоны, предназначенные для сред, отнесенных к группе 1 в соответствии с ТР ТС 032/2013, к проведению осмотра?",
        answers: [
          "Должны быть очищены и промыты водой",
          "Должны быть промыты соответствующим растворителем или дегазированы",
          "Должны быть продуты воздухом",
        ],
        correctAnswers: [
          "Должны быть промыты соответствующим растворителем или дегазированы",
        ],
      },
      {
        code: "68532",
        text: "Кем устанавливаются требования к освидетельствованию, браковке и маркировке баллонов, изготовленных из металлокомпозитных и композитных материалов?",
        answers: [
          "Организацией, осуществляющей освидетельствование баллонов, или испытательным пунктом (пунктом проверки)",
          "Территориальным органом Ростехнадзора",
          "Разработчиком проекта и (или) изготовителем баллона",
        ],
        correctAnswers: ["Разработчиком проекта и (или) изготовителем баллона"],
      },
      {
        code: "68533",
        text: "Каким требованиям должно отвечать помещение, используемое для освидетельствования баллонов?",
        answers: [
          "Оно должно быть отдельным и специально оборудованным в соответствии с проектом, температура воздуха в этих помещениях должна быть не ниже +12 °С",
          "Освидетельствование баллонов проводится только на открытом воздухе при температуре окружающего воздуха не ниже -5 °С",
          "Требования к помещению, используемому для освидетельствования баллонов, не устанавливаются",
        ],
        correctAnswers: [
          "Оно должно быть отдельным и специально оборудованным в соответствии с проектом, температура воздуха в этих помещениях должна быть не ниже +12 °С",
        ],
      },
      {
        code: "68534",
        text: "В каком количестве наполненные газом баллоны, находящиеся на длительном складском хранении, при наступлении очередных сроков периодического освидетельствования подвергают освидетельствованию в выборочном порядке из партии до 100 баллонов?",
        answers: [
          "В количестве не менее 5 штук",
          "В количестве не менее 10 штук",
          "В количестве не менее 15 штук",
          "В количестве не менее 20 штук",
        ],
        correctAnswers: ["В количестве не менее 5 штук"],
      },
      {
        code: "68535",
        text: "В каком количестве наполненные газом баллоны, находящиеся на длительном складском хранении, при наступлении очередных сроков периодического освидетельствования подвергают освидетельствованию в выборочном порядке из партии до 500 баллонов?",
        answers: [
          "В количестве не менее 5 штук",
          "В количестве не менее 10 штук",
          "В количестве не менее 15 штук",
          "В количестве не менее 20 штук",
        ],
        correctAnswers: ["В количестве не менее 10 штук"],
      },
      {
        code: "68536",
        text: "В каком количестве наполненные газом баллоны, находящиеся на длительном складском хранении, при наступлении очередных сроков периодического освидетельствования подвергают освидетельствованию в выборочном порядке из партии свыше 500 баллонов?",
        answers: [
          "В количестве не менее 5 штук",
          "В количестве не менее 10 штук",
          "В количестве не менее 15 штук",
          "В количестве не менее 20 штук",
        ],
        correctAnswers: ["В количестве не менее 20 штук"],
      },
      {
        code: "68537",
        text: "Какой устанавливается срок хранения наполненных газом баллонов, находящихся на длительном складском хранении, при удовлетворительных результатах периодического освидетельствования?",
        answers: [
          "Устанавливается лицом, производившим освидетельствование, но не более чем 2 года",
          "Не более чем 6 месяцев",
          "Не более чем год",
          "Устанавливается организацией-изготовителем, но не более чем 36 месяцев",
        ],
        correctAnswers: [
          "Устанавливается лицом, производившим освидетельствование, но не более чем 2 года",
        ],
      },
      {
        code: "68538",
        text: "В каком количестве при неудовлетворительных результатах первого периодического освидетельствования наполненные газом баллоны, находящиеся на длительном складском хранении, подвергают повторному освидетельствованию?",
        answers: [
          "В таком же количестве, что и при первом освидетельствовании",
          "Количество выборочно отобранных баллонов увеличивается в 2 раза",
          "Повторное освидетельствование не проводится, дальнейшее хранение баллонов не допускается",
        ],
        correctAnswers: [
          "В таком же количестве, что и при первом освидетельствовании",
        ],
      },
      {
        code: "68539",
        text: "Какие меры принимаются при неудовлетворительных результатах повторного периодического освидетельствования наполненных газом баллонов, находящихся на длительном складском хранении?",
        answers: [
          "Дальнейшее хранение всей партии баллонов не допускается, газ из баллонов должен быть удален в срок, указанный лицом, производившим освидетельствование, после чего баллоны должны быть подвергнуты техническому освидетельствованию каждый в отдельности",
          "Дальнейшее хранение всей партии баллонов не допускается, газ из баллонов должен быть удален в срок, указанный лицом, производившим освидетельствование, после чего баллоны должны быть подвергнуты утилизации",
          "Баллоны, не прошедшие освидетельствование, дальнейшему хранению не подлежат, газ из баллонов должен быть удален в срок, указанный лицом, производившим освидетельствование, после чего баллоны должны быть подвергнуты утилизации, остальные баллоны из этой партии подлежат освидетельствованию в выборочном порядке через три месяца",
        ],
        correctAnswers: [
          "Дальнейшее хранение всей партии баллонов не допускается, газ из баллонов должен быть удален в срок, указанный лицом, производившим освидетельствование, после чего баллоны должны быть подвергнуты техническому освидетельствованию каждый в отдельности",
        ],
      },
      {
        code: "68540",
        text: "Каким образом баллоны, из которых невозможно выпустить газ из-за неисправности вентилей на месте потребления, возвращаются на наполнительную станцию?",
        answers: [
          "Вместе с пустыми (порожними) баллонами с нанесением на них соответствующей временной надписи (маркировки) любым доступным способом, не нарушающим целостность корпуса баллона",
          "Отдельно от пустых (порожних) баллонов с нанесением на них соответствующей временной надписи (маркировки) любым доступным способом, не нарушающим целостность корпуса баллона",
          "Любым способом, обеспечивающим безопасность их транспортирования",
        ],
        correctAnswers: [
          "Отдельно от пустых (порожних) баллонов с нанесением на них соответствующей временной надписи (маркировки) любым доступным способом, не нарушающим целостность корпуса баллона",
        ],
      },
      {
        code: "68541",
        text: "Кем присваивается шифр клейма?",
        answers: [
          "Центральным аппаратом Ростехнадзора",
          "Территориальным органом Ростехнадзора",
          "Аккредитованным органом по сертификации",
          "Аккредитованной испытательной лабораторией",
        ],
        correctAnswers: ["Территориальным органом Ростехнадзора"],
      },
      {
        code: "68542",
        text: "С применением каких материалов должны ввертываться запорные клапаны в баллонах для кислорода?",
        answers: [
          "Уплотняющих материалов, рассчитанных на давление от 8 до 24 МПа, не имеющих следов загрязнений, масла, коррозии, дополнительно обезжиренных специальным составом",
          'Уплотняющих материалов, сертифицированных в соответствии с техническим регламентом Таможенного союза "О безопасности оборудования для работы во взрывоопасных средах"',
          "Любых сертифицированных уплотняющих материалов",
          "Уплотняющих материалов, возгорание которых в среде кислорода исключено",
        ],
        correctAnswers: [
          "Уплотняющих материалов, возгорание которых в среде кислорода исключено",
        ],
      },
      {
        code: "68543",
        text: "Какие сведения не указывает в своем заявлении о присвоении шифра клейма организация, планирующая осуществление освидетельствования баллонов?",
        answers: [
          'Сведения об организационно-технической готовности к данному виду деятельности в соответствии с требованиями ФНП "Правила промышленной безопасности при использовании оборудования, работающего под избыточным давлением"',
          "Характеристики баллонов, освидетельствование которых готова осуществлять организация (тип или марка баллонов, вместимость баллонов, наименование и назначение газов, для которых они предназначены)",
          "Перечень лиц, непосредственно участвующих в освидетельствовании баллонов",
          "Организация указывает все перечисленные сведения",
        ],
        correctAnswers: [
          "Перечень лиц, непосредственно участвующих в освидетельствовании баллонов",
        ],
      },
      {
        code: "68544",
        text: "Чем должны быть укомплектованы баллоны? Укажите все правильные ответы.",
        answers: [
          "Вентилями, плотно ввернутыми в расходно-наполнительные штуцера у специальных баллонов, не имеющих горловины",
          "Вентилями, плотно ввернутыми в отверстия горловины",
          "Предохранительными клапанами (баллоны вместимостью более 50 л)",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Вентилями, плотно ввернутыми в расходно-наполнительные штуцера у специальных баллонов, не имеющих горловины",
          "Вентилями, плотно ввернутыми в отверстия горловины",
        ],
      },
      {
        code: "68545",
        text: "Какие требования по порядку выдачи и возвращения клейм с шифрами противоречат требованиям ФНП ОРПД?",
        answers: [
          "В организации должно быть обеспечено ведение журнала учета выдачи и возвращения клейм с шифрами",
          "Клеймо с шифром выдается лицу, прошедшему подготовку и аттестацию по промышленной безопасности в установленном порядке и назначенному приказом (распоряжением) руководителя организации для проведения освидетельствования баллонов",
          "Клейма одного шифра закрепляются за одним лицом на все время выполнения им освидетельствования баллонов",
          "Разовые или временные передачи клейм для клеймения баллонов другим лицам без соответствующего приказа (распоряжения) руководителя организации (индивидуального предпринимателя) не допускаются",
          "Порядок, обеспечивающий сохранность клейм и журнала учета выдачи и возвращения клейм с шифрами, определяется методическими указаниями Ростехнадзора",
        ],
        correctAnswers: [
          "Порядок, обеспечивающий сохранность клейм и журнала учета выдачи и возвращения клейм с шифрами, определяется методическими указаниями Ростехнадзора",
        ],
      },
      {
        code: "68546",
        text: "Куда должна направить организация, прекратившая освидетельствование баллонов, один экземпляр акта по уничтожению оставшихся клейм с шифрами?",
        answers: [
          "Не регламентируется",
          "В территориальный орган Ростехнадзора, присвоивший шифр клейма",
          "В аккредитованный орган по сертификации, присвоивший шифр клейма",
          "В аккредитованную испытательную лабораторию, присвоившую шифр клейма",
        ],
        correctAnswers: [
          "В территориальный орган Ростехнадзора, присвоивший шифр клейма",
        ],
      },
      {
        code: "68547",
        text: "Каким документом должна руководствоваться организация при осуществлении деятельности по эксплуатации (наполнению, хранению, транспортированию и использованию) баллонов?",
        answers: [
          "Собственной инструкцией",
          "Инструкцией наполнительной станции",
          "Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением",
          "Все ответы неверны",
          "Всеми перечисленными документами",
        ],
        correctAnswers: ["Собственной инструкцией"],
      },
      {
        code: "68548",
        text: "Какое требование ФНП ОРПД к шифру клейма указано неверно?",
        answers: [
          'Шифры клейм состоят из цифровой части - арабских цифр в виде чисел от 01 до 98, и буквенной части - заглавных букв русского или латинского алфавитов, кроме букв русского алфавита "З", "О", "Ч", "Е", "И", "X", "Ь", "Ъ", "Ы", с применением заглавных букв латинского алфавита "W", "U", "S", "F", "L", "Z", "V", "N"',
          "Шифр клейма имеет три знака одного размера (высотой 6 мм), располагаемых в ряд в круге диаметром 12 мм, и состоит из двух цифр (цифровая часть шифра) и одной заглавной буквы (буквенная часть шифра)",
          "Допускается включение в шифр тире, точек, замена цифры 0 буквой О",
          "Перестановка в шифре цифр местами (например, замена цифровой части шифра 12 числом 21) не допускается",
        ],
        correctAnswers: [
          "Допускается включение в шифр тире, точек, замена цифры 0 буквой О",
        ],
      },
      {
        code: "68549",
        text: 'Какой орган осуществляет контроль за соблюдением требований "Правил промышленной безопасности опасных производственных объектов, на которых используется оборудование, работающее под избыточным давлением" при проведении технического освидетельствования, ремонта и наполнения баллонов?',
        answers: [
          "Ростехнадзор",
          "Росатом",
          "Росаккредитация",
          "Росстандарт",
          "Орган по сертификации в рамках проведения инспекционного контроля",
        ],
        correctAnswers: ["Ростехнадзор"],
      },
      {
        code: "68550",
        text: "Какие требования предъявляются ФНП ОРПД к клейму для выбраковки баллонов?",
        answers: [
          'Клеймо круглой формы диаметром 12 мм с буквой "X"',
          'Клеймо круглой формы диаметром 20 мм с буквой "Z"',
          'Клеймо квадратной формы диаметром 12 мм с цифрами "00"',
          'Клеймо круглой формы диаметром 12 мм с буквой "X" и цифрами "00"',
        ],
        correctAnswers: ['Клеймо круглой формы диаметром 12 мм с буквой "X"'],
      },
      {
        code: "68551",
        text: "Каково минимальное значение ширины свободного прохода площадок для обслуживания арматуры, контрольно-измерительных приборов и другого оборудования?",
        answers: ["600 мм", "500 мм", "700 мм", "800 мм"],
        correctAnswers: ["800 мм"],
      },
      {
        code: "68552",
        text: "Какое из приведенных требований должно выполняться при проведении визуального и измерительного контроля?",
        answers: [
          "Поверхностные дефекты, выявленные при визуальном и измерительном контроле, должны быть исправлены до проведения контроля другими неразрушающими методами",
          "В случае невозможности контроля сварного соединения с двух сторон его контроль должен быть проведен в порядке, предусмотренном технологической документацией на сварку",
          "Перед визуальным контролем поверхность сварного шва и прилегающие к нему участки основного металла шириной не менее 10 мм в обе стороны от шва должны быть зачищены от шлака и других загрязнений",
        ],
        correctAnswers: [
          "Поверхностные дефекты, выявленные при визуальном и измерительном контроле, должны быть исправлены до проведения контроля другими неразрушающими методами",
        ],
      },
      {
        code: "68553",
        text: 'Какое определение соответствует термину "границы сосуда"?',
        answers: [
          "Входные и выходные штуцера, а также присоединенные к ним патрубки (трубопроводы обвязки) с установленными на них арматурой, предохранительными и иными устройствами (при их наличии в случаях, установленных проектом), входящие в состав конструкции сосуда и указанные организацией-изготовителем в паспорте и чертежах общего вида сосуда",
          "Запорные устройства питательных, дренажных и других трубопроводов, а также предохранительные и другие клапаны и задвижки, ограничивающие внутренние полости элементов сосуда и присоединенных к ним трубопроводов",
          "Запорная арматура, предохранительные и другие устройства, отделяющие (дистанцирующие) сосуд на входе и выходе от подключенного к нему оборудования и (или) трубопроводов",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Входные и выходные штуцера, а также присоединенные к ним патрубки (трубопроводы обвязки) с установленными на них арматурой, предохранительными и иными устройствами (при их наличии в случаях, установленных проектом), входящие в состав конструкции сосуда и указанные организацией-изготовителем в паспорте и чертежах общего вида сосуда",
        ],
      },
      {
        code: "68554",
        text: "Каково минимальное значение свободной высоты от уровня земли, пола здания (помещения), площадок (мостиков) и ступеней лестниц обслуживания?",
        answers: ["2,0 м", "1,5 м", "1.8 м"],
        correctAnswers: ["2,0 м"],
      },
      {
        code: "68555",
        text: "Какой должна быть минимальная ширина свободного прохода площадок (мостиков) для обслуживания арматуры, контрольно-измерительных приборов и другого оборудования?",
        answers: ["800 мм", "1000 мм", "600 мм", "1200 мм", "950 мм"],
        correctAnswers: ["800 мм"],
      },
      {
        code: "68556",
        text: "Какой должна быть максимальная масса пропана на 1 л вместимости баллона?",
        answers: ["0,770 кг", "0,425 кг", "1,250 кг", "0,850 кг", "0,305 кг"],
        correctAnswers: ["0,425 кг"],
      },
      {
        code: "68557",
        text: "Какие графы не содержатся в журнале испытаний при освидетельствовании баллонов?",
        answers: [
          "Давление баллона при испытаниях, МПа; нормы отбраковки баллона",
          "Товарный знак или наименование изготовителя; номер баллона",
          "Фамилия, инициалы и подпись представителя организации (индивидуального предпринимателя), проводившей освидетельствование",
          "Дата (месяц, год) изготовления баллона; дата произведенного и следующего освидетельствования",
          "Масса, выбитая на баллоне, кг; масса баллона, установленная при освидетельствовании, кг",
          "Все перечисленные графы",
        ],
        correctAnswers: [
          "Давление баллона при испытаниях, МПа; нормы отбраковки баллона",
        ],
      },
      {
        code: "68558",
        text: "Какие сведения из указанных не заносятся в журнал испытаний по результатам освидетельствования баллонов для растворенного ацетилена?",
        answers: [
          "Номер баллона и товарный знак организации-изготовителя",
          "Эксплуатирующая организация",
          "Дата (месяц и год) изготовления баллона",
          "Фамилия, инициалы и подпись представителя организации (индивидуального предпринимателя), проводившей освидетельствование",
          "Дата проведенного и следующего освидетельствования баллона",
        ],
        correctAnswers: ["Эксплуатирующая организация"],
      },
      {
        code: "68559",
        text: "При выявлении каких дефектов во время осмотра наружной и внутренней поверхности баллоны не подлежат отбраковке?",
        answers: [
          "Наличие трещин, плен, вмятин, отдулин, раковин и рисок глубиной более 10 % номинальной толщины стенки",
          "Наличие надрывов и выщерблений",
          "Износ резьбы горловины",
          "Ослабление кольца на горловине баллона",
          "Выявление всех указанных дефектов влечет за собой отбраковку баллона",
        ],
        correctAnswers: ["Ослабление кольца на горловине баллона"],
      },
      {
        code: "68560",
        text: "При отсутствии каких данных на верхней сферической части баллоны могут быть допущены к эксплуатации?",
        answers: [
          "При отсутствии клейма организации (индивидуального предпринимателя), проводившей техническое освидетельствование",
          "При отсутствии указания массы пустого баллона",
          "При отсутствии даты проведения технического освидетельствования",
          "Эксплуатация баллонов запрещается при отсутствии любых из перечисленных данных",
        ],
        correctAnswers: [
          "Эксплуатация баллонов запрещается при отсутствии любых из перечисленных данных",
        ],
      },
      {
        code: "68561",
        text: "В каком случае при отсутствии указаний организации-изготовителя на браковку стальные бесшовные стандартные баллоны вместимостью от 12 до 55 литров бракуют и изымают из эксплуатации?",
        answers: [
          "Только при уменьшении массы на 7,5 % и выше",
          "При уменьшении массы на 7,5 % и выше, а также при увеличении их вместимости более чем на 1 %",
          "Только при увеличении их вместимости более чем на 3 %",
        ],
        correctAnswers: [
          "При уменьшении массы на 7,5 % и выше, а также при увеличении их вместимости более чем на 1 %",
        ],
      },
      {
        code: "68562",
        text: "Каким способом определяется фактическая вместимость баллона?",
        answers: [
          "Только с помощью мерных бачков",
          "Способом, указанным в руководстве по эксплуатации завода-изготовителя",
          "По разности между массой баллона, наполненного водой, и массой порожнего баллона; с помощью мерных бачков или иным, установленным в производственной инструкции способом, обеспечивающим необходимую точность измерения",
          "Только по разности между массой баллона, наполненного водой, и массой порожнего баллона",
        ],
        correctAnswers: [
          "По разности между массой баллона, наполненного водой, и массой порожнего баллона; с помощью мерных бачков или иным, установленным в производственной инструкции способом, обеспечивающим необходимую точность измерения",
        ],
      },
      {
        code: "68563",
        text: "При какой величине пробного давления проводится гидравлическое испытание баллонов?",
        answers: [
          "Величину пробного давления устанавливает изготовитель, при этом пробное давление должно быть не менее чем полуторное рабочее давление",
          "Пробное давление должно быть не менее, чем рабочее давление",
          "Величину пробного давления устанавливает изготовитель, при этом пробное давление должно быть не менее чем 1,15 рабочего давления",
        ],
        correctAnswers: [
          "Величину пробного давления устанавливает изготовитель, при этом пробное давление должно быть не менее чем полуторное рабочее давление",
        ],
      },
      {
        code: "68564",
        text: "До какой величины может быть снижено пробное давление для баллонов, изготовленных из материала, отношение временного сопротивления к пределу текучести которого более 2?",
        answers: [
          "До рабочего давления",
          "До 1,25 рабочего давления",
          "До 1,5 рабочего давления",
          "До 1,75 рабочего давления",
        ],
        correctAnswers: ["До 1,25 рабочего давления"],
      },
      {
        code: "68565",
        text: "Какие данные не указываются наполнительной станцией, производящей наполнение баллонов сжатыми, сжиженными и растворимыми газами, в журнале наполнения баллонов?",
        answers: [
          "Дата наполнения и номер баллона",
          "Эксплуатирующая организация",
          "Дата освидетельствования",
          "Масса газа (сжиженного) в баллоне, кг",
          "Подпись, фамилия и инициалы лица, наполнившего баллон",
        ],
        correctAnswers: ["Эксплуатирующая организация"],
      },
      {
        code: "68566",
        text: "Как оформляется журнал наполнения, если наполнительная станция производит наполнение баллонов различными газами?",
        answers: [
          "Должен вестись отдельный журнал наполнения",
          "Должен вестись единый журнал наполнения",
          "В отдельный журнал должны заноситься сведения только о наполнении баллонов взрывоопасными газами",
        ],
        correctAnswers: ["Должен вестись отдельный журнал наполнения"],
      },
      {
        code: "68567",
        text: "Какое требование к наполнению баллонов газами указано неверно?",
        answers: [
          "Наполнение баллонов газами должно быть произведено по инструкции, разработанной и утвержденной наполнительной организацией (индивидуальным предпринимателем) в установленном порядке с учетом свойств газа, местных условий и требований руководства (инструкции) по эксплуатации и иной документации изготовителя баллона",
          "Перед наполнением кислородных баллонов должен быть проведен контроль отсутствия в них примеси горючих газов газоанализатором в порядке, установленном инструкцией",
          "Наполнение баллонов сжиженными газами должно соответствовать нормам, установленным изготовителем баллонов и (или) техническими условиями на сжиженные газы",
          "При отсутствии норм наполнения баллонов, установленных организацией-изготовителем и (или) техническими условиями на сжиженные газы, наполнение баллонов производится в соответствии с нормами, установленными ТР ТС 032/2013",
        ],
        correctAnswers: [
          "При отсутствии норм наполнения баллонов, установленных организацией-изготовителем и (или) техническими условиями на сжиженные газы, наполнение баллонов производится в соответствии с нормами, установленными ТР ТС 032/2013",
        ],
      },
      {
        code: "68568",
        text: "В каком случае из указанных допускается наполнение баллонов газом?",
        answers: [
          "Если неисправны вентили баллона",
          "Если до истечения срока технического освидетельствования остается 1 месяц",
          "Если отсутствует надлежащая окраска или надписи",
          "Если истек срок проверки пористой массы",
        ],
        correctAnswers: [
          "Если до истечения срока технического освидетельствования остается 1 месяц",
        ],
      },
      {
        code: "68569",
        text: "Где проводится перенасадка башмаков и колец для колпаков, а также замена вентилей на баллонах?",
        answers: [
          "На наполнительной станции",
          "В эксплуатирующей организации",
          "На пунктах освидетельствования баллонов",
          "В любой специализированной ремонтной организации",
        ],
        correctAnswers: ["На пунктах освидетельствования баллонов"],
      },
      {
        code: "68570",
        text: "Где может проводиться восстановление окраски и надписей на баллонах?",
        answers: [
          "На наполнительной станции",
          "В эксплуатирующей организации",
          "На пунктах освидетельствования баллонов",
          "В любой специализированной ремонтной организации",
        ],
        correctAnswers: ["На пунктах освидетельствования баллонов"],
      },
      {
        code: "68571",
        text: "При каком давлении проводится проверка на плотность вентиля после ремонта, связанного с его разборкой?",
        answers: [
          "При рабочем давлении",
          "При давлении, равном 1,25 рабочего давления",
          "При избыточном давлении",
          "При атмосферном давлении",
        ],
        correctAnswers: ["При рабочем давлении"],
      },
      {
        code: "68572",
        text: "Какие требования безопасности необходимо соблюдать при очистке и окраске наполненных газом баллонов?",
        answers: [
          "Баллоны должны находиться только в горизонтальном положении",
          "Очистка и окраска баллонов должна производиться только на открытом воздухе",
          "Очистка и окраска наполненных газом баллонов запрещается",
          "Процедура должна проводиться в специально оборудованном помещении, защищенном от проникновения солнечных лучей",
        ],
        correctAnswers: [
          "Очистка и окраска наполненных газом баллонов запрещается",
        ],
      },
      {
        code: "68573",
        text: "Где могут храниться наполненные баллоны с газами?",
        answers: [
          "Как в специальных помещениях, так и на открытом воздухе, в последнем случае они должны быть защищены от атмосферных осадков и солнечных лучей",
          "Только в специально оборудованном помещении",
          "Не установлено дополнительных требований к помещениям для хранения баллонов",
        ],
        correctAnswers: [
          "Как в специальных помещениях, так и на открытом воздухе, в последнем случае они должны быть защищены от атмосферных осадков и солнечных лучей",
        ],
      },
      {
        code: "68574",
        text: "Где должны храниться баллоны с ядовитыми газами?",
        answers: [
          "На открытом воздухе, если они защищены от атмосферных осадков и солнечных лучей",
          "В складских помещениях совместно с кислородными баллонами",
          "В специальных закрытых помещениях",
        ],
        correctAnswers: ["В специальных закрытых помещениях"],
      },
      {
        code: "68575",
        text: "Какие требования к хранению баллонов на наполнительной станции указаны неверно?",
        answers: [
          "Не допускается хранение баллонов, которые не имеют башмаков, в горизонтальном положении на деревянных рамах или стеллажах",
          "При хранении на открытых площадках разрешается укладывать баллоны с башмаками в штабеля с прокладками из веревки, деревянных брусьев, резины или иных неметаллических материалов, имеющих амортизирующие свойства, между горизонтальными рядами",
          "При укладке баллонов в штабеля высота последних не должна превышать 1,5 м, вентили баллонов должны быть обращены в одну сторону",
        ],
        correctAnswers: [
          "Не допускается хранение баллонов, которые не имеют башмаков, в горизонтальном положении на деревянных рамах или стеллажах",
        ],
      },
      {
        code: "68576",
        text: "Если изготовителем не установлено, то с какой периодичностью проводятся наружный и внутренний осмотры в процессе технического освидетельствования не подлежащих учету в органах Ростехнадзора баллонов, находящихся в эксплуатации для наполнения газами, вызывающими разрушение и физико-химическое превращение материала со скоростью не более 0,1 мм/год?",
        answers: [
          "1 раз в год",
          "1 раз в 2 года",
          "1 раз в 5 лет",
          "1 раз в 3 года",
          "1 раз в 10 лет",
        ],
        correctAnswers: ["1 раз в 5 лет"],
      },
      {
        code: "68577",
        text: "Если изготовителем не установлено, то с какой периодичностью проводится гидравлическое испытание пробным давлением в процессе технического освидетельствования не подлежащих учету в органах Ростехнадзора баллонов, находящихся в эксплуатации для наполнения газами, вызывающими разрушение и физико-химическое превращение материала со скоростью более 0,1 мм/год?",
        answers: [
          "1 раз в год",
          "1 раз в 2 года",
          "1 раз в 5 лет",
          "1 раз в 3 года",
          "1 раз в 10 лет",
        ],
        correctAnswers: ["1 раз в 2 года"],
      },
      {
        code: "68578",
        text: "Если изготовителем не установлено, то с какой периодичностью проводятся наружный и внутренний осмотры в процессе технического освидетельствования не подлежащих учету в органах Ростехнадзора баллонов для сжиженного газа, предназначенных для обеспечения топливом двигателей транспортных средств?",
        answers: [
          "1 раз в год",
          "1 раз в 2 года",
          "1 раз в 5 лет",
          "1 раз в 3 года",
          "1 раз в 10 лет",
        ],
        correctAnswers: ["1 раз в 2 года"],
      },
      {
        code: "68579",
        text: "Если изготовителем не установлено, то с какой периодичностью проводятся наружный и внутренний осмотры в процессе технического освидетельствования не подлежащих учету в органах Ростехнадзора баллонов для сжатого газа, изготовленных из углеродистых сталей и металлокомпозитных материалов и предназначенных для обеспечения топливом двигателей, на которых они установлены?",
        answers: [
          "1 раз в год",
          "1 раз в 2 года",
          "1 раз в 5 лет",
          "1 раз в 3 года",
          "1 раз в 10 лет",
        ],
        correctAnswers: ["1 раз в 3 года"],
      },
      {
        code: "68580",
        text: "Если изготовителем не установлено, то с какой периодичностью проводится гидравлическое испытание пробным давлением в процессе технического освидетельствования не подлежащих учету в органах Ростехнадзора баллонов, находящихся в эксплуатации для наполнения газами, вызывающими разрушение и физико-химическое превращение материала со скоростью менее 0,1 мм/год, в которых давление выше 0,07 МПа создается периодически для их опорожнения?",
        answers: [
          "1 раз в год",
          "1 раз в 2 года",
          "1 раз в 5 лет",
          "1 раз в 3 года",
          "1 раз в 10 лет",
        ],
        correctAnswers: ["1 раз в 10 лет"],
      },
      {
        code: "68581",
        text: "Если изготовителем не установлено, то с какой периодичностью проводится гидравлическое испытание пробным давлением в процессе технического освидетельствования не подлежащих учету в органах Ростехнадзора баллонов, установленных стационарно, в которых хранятся сжатый воздух, кислород, аргон, азот, гелий с температурой точки росы - 35 °С и ниже, замеренной при давлении 15 МПа и выше?",
        answers: [
          "1 раз в год",
          "1 раз в 2 года",
          "1 раз в 5 лет",
          "1 раз в 3 года",
          "1 раз в 10 лет",
        ],
        correctAnswers: ["1 раз в 10 лет"],
      },
      {
        code: "68582",
        text: "Если изготовителем не установлено, то с какой периодичностью проводятся наружный и внутренний осмотры в процессе технического освидетельствования подлежащих учету в органах Ростехнадзора баллонов, установленных стационарно, в которых хранятся сжатый воздух, кислород, аргон, азот, гелий с температурой точки росы - 35 °С и ниже, замеренной при давлении 15 МПа и выше?",
        answers: [
          "1 раз в год",
          "1 раз в 2 года",
          "1 раз в 5 лет",
          "1 раз в 3 года",
          "1 раз в 10 лет",
        ],
        correctAnswers: ["1 раз в 10 лет"],
      },
      {
        code: "68583",
        text: "Если изготовителем не установлено, то с какой периодичностью проводится гидравлическое испытание пробным давлением в процессе технического освидетельствования подлежащих учету в органах Ростехнадзора баллонов со средой, вызывающей разрушение и физико-химическое превращение материалов (коррозия и т.п.) со скоростью не более 0,1 мм/год?",
        answers: [
          "1 раз в 8 лет",
          "1 раз в 2 года",
          "1 раз в 5 лет",
          "1 раз в 3 года",
          "1 раз в 10 лет",
        ],
        correctAnswers: ["1 раз в 8 лет"],
      },
      {
        code: "68584",
        text: "В каком случае и кем допускаются отклонения от проектной документации при строительстве, реконструкции, капитальном ремонте и эксплуатации ОПО, на которых используется (применяется) оборудование под давлением?",
        answers: [
          "Отклонения от проектной документации не допускаются",
          "Отклонения от проектной документации оформляются в виде специальных технических условий, подлежащих утверждению в установленном порядке",
          "В технически обоснованных случаях отклонение от проектной документации согласуется с ее разработчиком или со специализированной экспертной организацией",
        ],
        correctAnswers: ["Отклонения от проектной документации не допускаются"],
      },
      {
        code: "68585",
        text: "Какое из приведенных требований к площадкам и лестницам для обслуживания, осмотра и ремонта оборудования указано неверно?",
        answers: [
          "Площадки и ступени лестниц для обслуживания, осмотра, ремонта оборудования под давлением должны быть выполнены из гладких или рифленых листов стали, прутковой (круглой) или полосовой стали",
          "Площадки и лестницы для обслуживания, осмотра, ремонта оборудования под давлением должны быть выполнены с ограждением перилами высотой не менее 900 мм со сплошной обшивкой по низу на высоту не менее 100 мм",
          "Переходные площадки и лестницы должны иметь перила с обеих сторон. Площадки котлов длиной более 5 м должны иметь не менее двух лестниц (двух выходов), расположенных в противоположных концах",
          "Все приведенные требования указаны верно",
        ],
        correctAnswers: [
          "Площадки и ступени лестниц для обслуживания, осмотра, ремонта оборудования под давлением должны быть выполнены из гладких или рифленых листов стали, прутковой (круглой) или полосовой стали",
        ],
      },
      {
        code: "68586",
        text: "Каковы предельно допустимые значения ширины, высоты между ступенями и ширины ступеней лестниц для обслуживания, осмотра и ремонта оборудования под давлением?",
        answers: [
          "Лестницы должны иметь ширину не менее 600 мм, высоту между ступенями не более 200 мм, ширину ступеней не менее 80 мм",
          "Лестницы должны иметь ширину не менее 500 мм, ширину ступеней не более 100 мм, ширину ступеней не менее 50 мм",
          "Лестницы должны иметь ширину не менее 600 мм, высоту между ступенями не более 150 мм, ширину ступеней не менее 60 мм",
        ],
        correctAnswers: [
          "Лестницы должны иметь ширину не менее 600 мм, высоту между ступенями не более 200 мм, ширину ступеней не менее 80 мм",
        ],
      },
      {
        code: "68587",
        text: "Применение каких площадок и ступеней лестниц на оборудовании, работающем под избыточным давлением, запрещается?",
        answers: [
          "Из прутковой круглой стали или гладких ступеней лестниц",
          "Из просечно-вытяжного листа",
          "Из рифленой листовой стали",
          "Из сотовой или полосовой (на ребро) стали",
        ],
        correctAnswers: [
          "Из прутковой круглой стали или гладких ступеней лестниц",
        ],
      },
      {
        code: "68588",
        text: "При каком минимальном избыточном давлении в сосуде допускается проведение ремонта сосуда и его элементов?",
        answers: [
          "Не допускается проведение ремонта сосудов и их элементов, находящихся под давлением",
          "Не допускается проведение ремонта сосудов, подлежащих учету в территориальных органах Ростехнадзора, и их элементов, находящихся под давлением; ремонт остальных сосудов - при давлении 0,05 МПа",
          "При остаточном давлении",
          "При рабочем давлении",
        ],
        correctAnswers: [
          "Не допускается проведение ремонта сосудов и их элементов, находящихся под давлением",
        ],
      },
      {
        code: "68589",
        text: "Каким документом определяется порядок действий в случае инцидента при эксплуатации сосуда?",
        answers: [
          "Инструкцией (руководством) по эксплуатации организации - изготовителя сосуда",
          "Производственной инструкцией, утвержденной эксплуатирующей организацией",
          "Планом мероприятий по локализации и ликвидации последствий аварий на ОПО",
        ],
        correctAnswers: [
          "Производственной инструкцией, утвержденной эксплуатирующей организацией",
        ],
      },
      {
        code: "68590",
        text: "Чем осуществляется продувка сосуда, работающего под давлением горючих газов, до начала выполнения работ внутри его корпуса?",
        answers: [
          "Кислородом",
          "Инертным газом и воздухом или их смесью",
          "Углекислым газом",
        ],
        correctAnswers: ["Инертным газом и воздухом или их смесью"],
      },
      {
        code: "68591",
        text: "В каком из приведенных случаев сосуд считается выдержавшим гидравлическое испытание?",
        answers: [
          "Если обнаружены течи, потения в сварных, развальцованных, заклепочных соединениях и в основном металле",
          "Если видимая остаточная деформация не превышает 10 %",
          "Если обнаружены лишь единичные трещины в сварном соединении, а на основном металле трещины не обнаружены",
          "Во всех приведенных случаях сосуд считается не выдержавшим гидравлическое испытание",
        ],
        correctAnswers: [
          "Во всех приведенных случаях сосуд считается не выдержавшим гидравлическое испытание",
        ],
      },
      {
        code: "68592",
        text: "Каким образом допускается хранить наполненные баллоны до выдачи их потребителям?",
        answers: [
          "Без предохранительных колпаков",
          "С навернутыми колпаками",
          "С навернутыми колпаками или иной защитой запорного органа баллона",
          "Любым из перечисленных способов",
        ],
        correctAnswers: ["Любым из перечисленных способов"],
      },
      {
        code: "68593",
        text: "Какое требование к складам для хранения баллонов с углекислотой указано неверно?",
        answers: [
          "Склады для баллонов должны находиться в зоне молниезащиты",
          "В складах должны быть вывешены инструкции, правила и плакаты по обращению с баллонами, находящимися на складе",
          "Склады для баллонов, наполненных газом, должны иметь естественную или искусственную вентиляцию",
          "Все требования указаны верно",
        ],
        correctAnswers: [
          "Склады для баллонов должны находиться в зоне молниезащиты",
        ],
      },
      {
        code: "68594",
        text: "В каком из приведенных случаев размещение баллонов с газом на местах потребления должно осуществляться в соответствии с планом (проектом) размещения оборудования?",
        answers: [
          "При размещении баллона на расстоянии менее 5 метров от источников открытого огня",
          "При размещении групповой баллонной установки",
          "При размещении баллонов в местах проезда транспортных средств",
        ],
        correctAnswers: ["При размещении групповой баллонной установки"],
      },
      {
        code: "68595",
        text: "Какое из приведенных требований к размещению баллонов при их использовании указано неверно?",
        answers: [
          "Баллоны при индивидуальной установке должны находиться на расстоянии не менее 1 метра от радиаторов отопления и других отопительных приборов, печей",
          "Не допускается установка баллонов в местах прохода людей",
          "Не допускается установка баллона в помещениях, в которых имеются источники тепла с открытым огнем",
          "Не допускается установка в местах перемещения грузов и проезда транспортных средств",
        ],
        correctAnswers: [
          "Не допускается установка баллона в помещениях, в которых имеются источники тепла с открытым огнем",
        ],
      },
      {
        code: "68596",
        text: "Какой баллон из приведенных допускается использовать в горизонтальном положении?",
        answers: [
          "Допускается использовать все баллоны в горизонтальном положении",
          "Баллон с кислородом",
          "Баллон с ацетиленом",
          "Баллон с пропан-бутаном",
        ],
        correctAnswers: ["Баллон с кислородом"],
      },
      {
        code: "68597",
        text: "Какое минимальное значение избыточного давления должно оставаться в баллонах (если иное не предусмотрено техническими условиями на газ)?",
        answers: ["ФНП не регламентировано", "0,1 МПа", "0,05 МПа", "0,08 МПа"],
        correctAnswers: ["0,05 МПа"],
      },
      {
        code: "68598",
        text: "Чем должно быть обеспечено соответствие выполнения работ по монтажу, ремонту, реконструкции (модернизации) оборудования с применением сварки и термической обработки требованиям технологической документации?",
        answers: [
          "Установленной распорядительными документами специализированной организации системой контроля качества (входной, операционный, приемочный)",
          "Декларированием специализированной организацией политики качества, обеспечивающей выполнение работ в соответствии с Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением, и технологической документацией",
          "Разработанной в специализированной организации в соответствии с международными стандартами ISO системой качества",
          "Системой подтверждения соответствия выполняемых работ требованиям технологической документации и Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, разработанной и утвержденной специализированной организацией",
        ],
        correctAnswers: [
          "Установленной распорядительными документами специализированной организации системой контроля качества (входной, операционный, приемочный)",
        ],
      },
      {
        code: "68599",
        text: "Какое из приведенных требований при подаче газа из баллонов в сосуд, который работает с меньшим давлением, указано неверно?",
        answers: [
          "На камере низкого давления редуктора должен быть установлен пружинный предохранительный клапан, отрегулированный на соответствующее разрешенное давление в сосуде, а также соответствующий данному давлению манометр",
          "На входе в редуктор должен быть установлен манометр со шкалой, обеспечивающей возможность измерения максимального рабочего давления в баллоне",
          "Подача газов должна производиться через редуктор, предназначенный для данного газа и окрашенный в соответствующий цвет",
          "Все приведенные требования верны",
        ],
        correctAnswers: ["Все приведенные требования верны"],
      },
      {
        code: "68600",
        text: "Каким образом оформляются результаты проводимого контроля качества сварных соединений?",
        answers: [
          "Результаты по каждому виду проводимого контроля и месту контроля должны фиксироваться в отчетной документации (журналы, формуляры, протоколы, маршрутные паспорта)",
          "Результаты проводимого контроля качества сварных соединений оформляются по усмотрению организации, осуществляющей монтаж, ремонт, реконструкцию (модернизацию) оборудования",
          "Общие результаты по контролю качества сварных соединений оформляются отчетом, прилагаемым к паспорту монтируемого, ремонтируемого, реконструируемого (модернизируемого) оборудования",
        ],
        correctAnswers: [
          "Результаты по каждому виду проводимого контроля и месту контроля должны фиксироваться в отчетной документации (журналы, формуляры, протоколы, маршрутные паспорта)",
        ],
      },
      {
        code: "68601",
        text: "По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) металлических сосудов (за исключением литых)? Где в формулах: Рраб - рабочее давление сосуда, Р расч - расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа.",
        answers: [
          "Рпр = 1,25 Рраб ([σ]20 / [σ]t)",
          "Рпр = 1,25 Рраcч ([σ]20 / [σ]t)",
          "Рпр = 1,5 Рраб ([σ]20 / [σ]t)",
          "Рпр = 1,25 Рраб",
        ],
        correctAnswers: ["Рпр = 1,25 Рраб ([σ]20 / [σ]t)"],
      },
      {
        code: "68602",
        text: "Какие требования к хранению баллонов указаны неверно?",
        answers: [
          "Не допускается хранение баллонов, которые не имеют башмаков, в горизонтальном положении на деревянных рамах или стеллажах",
          "При хранении на открытых площадках разрешается укладывать баллоны с башмаками в штабеля с прокладками из веревки, деревянных брусьев, резины или иных неметаллических материалов, имеющих амортизирующие свойства, между горизонтальными рядами",
          "При укладке баллонов в штабеля высота последних не должна превышать 1,5 метра, вентили баллонов должны быть обращены в одну сторону",
        ],
        correctAnswers: [
          "Не допускается хранение баллонов, которые не имеют башмаков, в горизонтальном положении на деревянных рамах или стеллажах",
        ],
      },
      {
        code: "68603",
        text: "По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) сосудов, изготовленных из неметаллических материалов с ударной вязкостью более 20 Дж/см²? Где в формулах: Рраб – рабочее давление сосуда, Р расч – расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа.",
        answers: [
          "Рпр = 1,3 Рраб ([σ]20 / [σ]t)",
          "Рпр = 1,3 Рраcч ([σ]20 / [σ]t)",
          "Рпр = 1,6 Рраб",
          "Рпр = 1,6 Рраб ([σ]20 / [σ]t)",
        ],
        correctAnswers: ["Рпр = 1,3 Рраб ([σ]20 / [σ]t)"],
      },
      {
        code: "68604",
        text: "Какие требования к складам хранения баллонов, наполненных газами, указаны неверно?",
        answers: [
          "Здание склада должно быть одноэтажным с покрытиями легкого типа и не иметь чердачных помещений",
          "Оконные и дверные стекла должны быть матовыми или закрашены белой краской",
          "Стены, перегородки, покрытия складов для хранения газов должны быть из несгораемых материалов, соответствующих проекту",
          "Полы складов должны быть ровными с нескользкой поверхностью, а складов для баллонов с горючими газами - с поверхностью из материалов, исключающих искрообразование при ударе о них какими-либо предметами",
          "Окна и двери должны открываться внутрь",
          "Высота складских помещений для баллонов должна быть не менее 3,25 м от пола до нижних выступающих частей кровельного покрытия",
        ],
        correctAnswers: ["Окна и двери должны открываться внутрь"],
      },
      {
        code: "68605",
        text: "По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) сосудов, изготовленных из неметаллических материалов с ударной вязкостью 20 Дж/см² и менее? Где в формулах: Рраб – рабочее давление сосуда, Р расч – расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа.",
        answers: [
          "Рпр = 1,6 Рраб ([σ]20 / [σ]t)",
          "Рпр = 1,3 Рраб ([σ]20 / [σ]t)",
          "Рпр = 1,3 Рраcч ([σ]20 / [σ]t)",
          "Рпр = 1,6 Рраб",
        ],
        correctAnswers: ["Рпр = 1,6 Рраб ([σ]20 / [σ]t)"],
      },
      {
        code: "68606",
        text: "По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) металлопластиковых сосудов, у которых ударная вязкость неметаллических материалов 20 Дж/см² и менее? Где в формулах: Рраб – рабочее давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа, Км - отношение массы металлоконструкции к общей массе сосуда.",
        answers: [
          "Рпр = 1,6 Рраб ([σ]20 / [σ]t)",
          "Рпр = [1,25 Км + 1,3 (1 - Км )] Рраб ([σ]20 / [σ]t)",
          "Рпр = [1,25 Км + 1,6 (1 - Км )] Рраб ([σ]20 / [σ]t)",
          "Рпр = [1,5 Км + 1,6 (1 - Км )] Рраб",
        ],
        correctAnswers: ["Рпр = [1,25 Км + 1,6 (1 - Км )] Рраб ([σ]20 / [σ]t)"],
      },
      {
        code: "68607",
        text: "Какое требование к перемещению баллонов на объектах их применения указано неверно?",
        answers: [
          "Перемещение на автокарах наполненных баллонов, расположенных вертикально, не допускается даже при наличии контейнеров",
          "Все приведенные требования указаны верно",
          "Перемещение наполненных баллонов в пределах производственной площадки должно производиться на рессорном транспорте или на автокарах в горизонтальном положении обязательно с прокладками между баллонами",
          "Перемещение баллонов должно производиться на специально приспособленных для этого тележках или с помощью других устройств, обеспечивающих безопасность транспортирования",
        ],
        correctAnswers: [
          "Перемещение на автокарах наполненных баллонов, расположенных вертикально, не допускается даже при наличии контейнеров",
        ],
      },
      {
        code: "68608",
        text: "Каким документом устанавливаются дополнительные требования безопасности при эксплуатации, наполнении, хранении и транспортировании баллонов, изготовленных из металлокомпозитных и композитных материалов?",
        answers: [
          "Руководством (инструкцией) по эксплуатации",
          "Соответствующими государственными стандартами и сводами правил",
          "Для данных баллонов дополнительные требования безопасности не устанавливаются",
          'Техническим регламентом Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"',
        ],
        correctAnswers: ["Руководством (инструкцией) по эксплуатации"],
      },
      {
        code: "68609",
        text: "Кем устанавливаются дополнительные требования при эксплуатации, наполнении, хранении и транспортировании баллонов, изготовленных из металлокомпозитных и композитных материалов? Укажите все правильные ответы.",
        answers: [
          "Организацией - изготовителем баллона",
          "Разработчиком проекта",
          "Газонаполнительной станцией",
          "Эксплуатирующей организацией",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Организацией - изготовителем баллона",
          "Разработчиком проекта",
        ],
      },
      {
        code: "68610",
        text: "Какой организацией должна быть разработана технологическая документация, регламентирующая содержание и порядок выполнения работ по монтажу, ремонту, реконструкции (модернизации) оборудования, работающего под давлением, с применением сварки и термической обработки?",
        answers: [
          "Эксплуатирующей организацией на основании руководства (инструкции) по эксплуатации оборудования, работающего под давлением, с последующим согласованием со специализированной организацией, выполняющей эти работы",
          "Специализированной организацией, выполняющей эти работы, до начала их производства",
          "Организацией - изготовителем оборудования, работающего под давлением",
          "Совместно специализированной организацией, выполняющей эти работы, и эксплуатирующей организацией",
        ],
        correctAnswers: [
          "Специализированной организацией, выполняющей эти работы, до начала их производства",
        ],
      },
      {
        code: "68611",
        text: "Каким образом допускается маркировать сварное соединение, выполненное несколькими сварщиками (бригадой сварщиков)?",
        answers: [
          "Допускается применение клейма одного из бригады сварщиков, выполнившего наибольший объем работ",
          "Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
          "Допускается применение клейма одного из участвовавших в сварке сварщиков, назначенного распоряжением",
          "Должны быть поставлены клейма всех сварщиков, участвовавших в сварке",
        ],
        correctAnswers: [
          "Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
        ],
      },
      {
        code: "68612",
        text: "Какие функции обязано выполнить лицо, осуществляющее руководство сварочными работами, назначенное распорядительным документом организации или (и) должностной инструкцией которого предусмотрено руководство сварочными работами, перед выполнением сварочных работ?",
        answers: [
          "Проверить и обеспечить соответствие численного состава и квалификации персонала сварочного производства, сборочного и сварочного оборудования, основных и сварочных материалов, применяемой технологии сварки требованиям ПТД",
          "Организовать проведение операционного контроля",
          "Ознакомить сварщиков с требованиями технологических карт сварки, а также с внесенными в них изменениями (при наличии) с подтверждением ознакомления подписями сварщиков в применяемых ими технологических картах сварки",
          "Все перечисленные функции",
        ],
        correctAnswers: ["Все перечисленные функции"],
      },
      {
        code: "68613",
        text: 'Какие требования предъявляются ФНП "Требования к производству сварочных работ на опасных производственных объектах" к сварочному оборудованию и сварочным материалам, применяемым при выполнении сварочных работ?',
        answers: [
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
          "Оборудование и материалы должны быть российского производства",
          "Оборудование и материалы должны быть экономичными в использовании, простыми в ремонте",
        ],
        correctAnswers: [
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "68614",
        text: "Какие действия должны быть осуществлены при выполнении многопроходных швов после наложения каждого валика поверхности шва и кромки разделки?",
        answers: [
          "Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)",
          "Должны быть проверены методами неразрушающего контроля",
          "Должны быть обезжирены водными смывками",
        ],
        correctAnswers: [
          "Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)",
        ],
      },
      {
        code: "68615",
        text: "Какая документация оформляется в процессе выполнения сварочных работ?",
        answers: [
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
          "Только протоколы испытаний сварных соединений",
          "Только журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю",
        ],
        correctAnswers: [
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "68616",
        text: "Кто допускается к выполнению сварочных работ на опасном производственном объекте?",
        answers: [
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
          "Сварщики и специалисты сварочного производства не моложе 21 года, имеющие группу по электробезопасности не ниже II и прошедшие обучение мерам пожарной безопасности в объеме пожарно-технического минимума",
          "Любые работники, обладающие необходимыми навыками и ознакомившиеся с требованиями охраны труда при производстве сварочных работ",
        ],
        correctAnswers: [
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
        ],
      },
      {
        code: "68617",
        text: "К выполнению каких работ могут быть допущены сварщики и специалисты сварочного производства?",
        answers: [
          "К любым сварочным работам при наличии действующих аттестационных удостоверений по какому-либо способу сварки",
          "К выполнению сварочных работ, указанных в действующих документах",
          "К сварочным работам, на которые они аттестованы, независимо от того, истек ли срок действия соответствующих аттестационных удостоверений",
        ],
        correctAnswers: [
          "К выполнению сварочных работ, указанных в действующих документах",
        ],
      },
      {
        code: "68618",
        text: "Что должно быть указано в технологических картах сварки?",
        answers: [
          "Требования к сварочным материалам и сварочному оборудованию, режимы сварки, последовательность операций, технические приемы контроля качества сварных соединений",
          "Требования к применяемым сварочным технологиям, последовательность операций, технические приемы, особенности процесса сварки, обеспечивающие качество сварных соединений",
          "Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений",
        ],
        correctAnswers: [
          "Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений",
        ],
      },
      {
        code: "68619",
        text: "Какую проверку должен пройти сварщик, впервые приступающий к сварке, перед допуском к работе?",
        answers: [
          "Проверку путем выполнения и контроля допускного сварного соединения",
          "Проверку знания теоретических основ сварки",
          "Проверку умения определять и устранять видимые дефекты сварного соединения",
        ],
        correctAnswers: [
          "Проверку путем выполнения и контроля допускного сварного соединения",
        ],
      },
    ],
    704: [
      {
        code: "68620",
        text: "При осуществлении каких процессов на ОПО не применяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?",
        answers: [
          "При техническом перевооружении ОПО, на котором используются сосуды, работающие под давлением",
          "При техническом освидетельствовании сосудов, работающих под давлением",
          "При проектировании и конструировании сосудов, работающих под давлением",
          "При техническом диагностировании сосудов, работающих под давлением",
        ],
        correctAnswers: [
          "При проектировании и конструировании сосудов, работающих под давлением",
        ],
      },
      {
        code: "68621",
        text: "При осуществлении каких процессов на ОПО не применяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?",
        answers: [
          "При изготовлении сосуда, работающего под давлением",
          "При проектировании размещения сосудов, работающих под давлением",
          "При ремонте сосудов, работающих под давлением",
          "При наладочных работах на технологическом оборудовании, включающем сосуды, работающие под давлением",
        ],
        correctAnswers: ["При изготовлении сосуда, работающего под давлением"],
      },
      {
        code: "68622",
        text: "При осуществлении каких процессов на ОПО не применяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?",
        answers: [
          "При реконструкции (модернизации) паровых котлов",
          "При реконструкции котлов-утилизаторов",
          "При пусконаладочных работах на водогрейных котлах",
          "При утилизации энерготехнологического котла на основании результатов технического диагностирования",
        ],
        correctAnswers: [
          "При утилизации энерготехнологического котла на основании результатов технического диагностирования",
        ],
      },
      {
        code: "68623",
        text: "При осуществлении каких процессов на ОПО не применяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?",
        answers: [
          "При техническом перевооружении ОПО, на котором используются водогрейные котлы",
          "При проведении экспертизы промышленной безопасности оборудования на ОПО",
          "При разработке (проектировании) прямоточного котла",
        ],
        correctAnswers: ["При разработке (проектировании) прямоточного котла"],
      },
      {
        code: "68624",
        text: "На какие процессы не распространяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?",
        answers: [
          "На техническое перевооружение опасного производственного объекта, на котором используются трубопроводы пара и горячей воды",
          "На монтаж паропровода",
          "На изготовление труб, тройников, отводов",
        ],
        correctAnswers: ["На изготовление труб, тройников, отводов"],
      },
      {
        code: "68625",
        text: "При осуществлении каких процессов на ОПО не применяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?",
        answers: [
          "При проведении экспертизы промышленной безопасности зданий и сооружений на ОПО",
          "При техническом освидетельствовании трубопроводов пара и горячей воды",
          "При пусконаладочных работах на трубопроводах пара и горячей воды",
          "При проектировании оборудования, изготовленного (произведенного) из неметаллической гибкой (эластичной) оболочки",
        ],
        correctAnswers: [
          "При проектировании оборудования, изготовленного (произведенного) из неметаллической гибкой (эластичной) оболочки",
        ],
      },
      {
        code: "68626",
        text: "На какие из приведенных ОПО не распространяется действие ФНП ОРПД?",
        answers: [
          "Только на ОПО, на которых используются трубопроводы пара и горячей воды с наружным диаметром менее 76 мм, у которых параметры рабочей среды не превышают температуру 450 °С и давление 8,0 МПа",
          "Только на ОПО, на которых используются трубопроводы пара и горячей воды наружным диаметром менее 51 мм, у которых температура рабочей среды не превышает 450 °С при давлении рабочей среды более 8,0 МПа",
          "Только на ОПО, на которых используются трубопроводы пара и горячей воды наружным диаметром менее 51 мм, у которых температура рабочей среды превышает 450 °С без ограничения давления рабочей среды",
          "На все приведенные ОПО действие ФНП не распространяется",
        ],
        correctAnswers: [
          "На все приведенные ОПО действие ФНП не распространяется",
        ],
      },
      {
        code: "68627",
        text: "На какие из приведенных ОПО распространяется действие ФНП ОРПД?",
        answers: [
          "На ОПО подводного применения",
          "На на которых используются сосуды, работающие под давлением, создающимся при взрыве внутри них в соответствии с технологическим процессом",
          "На плавучую буровую установку, на которой установлен котел на органическом теплоносителе с рабочим давлением 0,5 МПа",
          "На которых используются сосуды и трубопроводы, работающие под вакуумом",
        ],
        correctAnswers: [
          "На плавучую буровую установку, на которой установлен котел на органическом теплоносителе с рабочим давлением 0,5 МПа",
        ],
      },
      {
        code: "68628",
        text: "На какие из приведенных ОПО распространяется действие ФНП ОРПД?",
        answers: [
          "На ОПО, на которых используются водотрубные котлы с естественной циркуляцией (котлы-бойлеры) и рабочим давлением пара до 4 МПа",
          "На ОПО, на которых используются электрокотлы вместимостью не более 0,025 м3",
          "На ОПО, на которых используется оборудование под давлением, входящее в состав вооружения и военной техники, применяемое для обеспечения интересов обороны и безопасности государства",
          "На ОПО атомных электростанций, на которых используются сосуды, работающие с радиоактивной средой",
        ],
        correctAnswers: [
          "На ОПО, на которых используются водотрубные котлы с естественной циркуляцией (котлы-бойлеры) и рабочим давлением пара до 4 МПа",
        ],
      },
      {
        code: "68629",
        text: "На какие из приведенных ОПО не распространяется действие ФНП ОРПД?",
        answers: [
          "На ОПО, на которых используются сосуды вместимостью более 0,05 м3 (независимо от давления) для научно-экспериментальных целей",
          "На ОПО, на которых используются сосуды вместимостью не более 0,025 м3 (независимо от давления) для научно-экспериментальных целей",
          "На ОПО, на которых используются электрокотлы вместимостью не менее 0,55 м3",
          "На ОПО, на которых используются барокамеры",
        ],
        correctAnswers: [
          "На ОПО, на которых используются сосуды вместимостью не более 0,025 м3 (независимо от давления) для научно-экспериментальных целей",
        ],
      },
      {
        code: "68630",
        text: "Для какого из приведенных котлов должна проводиться экспертиза промышленной безопасности перед вводом их в эксплуатацию?",
        answers: [
          "Паровой котел с рабочим избыточным давлением 0,3 МПа, установленный на плавучей драге",
          "Сосуды вместимостью не более 0,025 м³",
          "Водогрейный котел, предназначенный для получения горячей воды с температурой 100 °C",
          "Электрокотлы вместимостью не более 0,025 м³",
        ],
        correctAnswers: [
          "Паровой котел с рабочим избыточным давлением 0,3 МПа, установленный на плавучей драге",
        ],
      },
      {
        code: "68631",
        text: "Какие из приведенных требований к площадкам и лестницам для обслуживания, осмотра и ремонта оборудования указаны неверно?",
        answers: [
          "Площадки и ступени лестниц для обслуживания, осмотра, ремонта оборудования под давлением должны быть выполнены из гладких или рифленых листов стали, прутковой (круглой) или полосовой стали",
          "Площадки и лестницы для обслуживания, осмотра, ремонта оборудования под давлением должны ограждаться перилами высотой не менее 900 мм со сплошной обшивкой по низу на высоту не менее 100 мм",
          "Площадки и лестницы для обслуживания, осмотра, ремонта оборудования должны иметь в местах прохода персонала, обслуживающего оборудование ширину свободного прохода не менее 600 мм",
          "Площадки и лестницы для обслуживания, осмотра, ремонта оборудования должны иметь в местах установки арматуры, контрольно-измерительных приборов, других устройств и оборудования - не менее 800 мм",
        ],
        correctAnswers: [
          "Площадки и ступени лестниц для обслуживания, осмотра, ремонта оборудования под давлением должны быть выполнены из гладких или рифленых листов стали, прутковой (круглой) или полосовой стали",
        ],
      },
      {
        code: "68632",
        text: "Каково минимальное значение ширины свободного прохода площадок для обслуживания арматуры, контрольно-измерительных приборов и другого оборудования?",
        answers: ["350 мм", "500 мм", "800 мм", "100 мм"],
        correctAnswers: ["800 мм"],
      },
      {
        code: "68633",
        text: "В каком случае и кем допускаются отклонения от проектной документации при строительстве, реконструкции, капитальном ремонте и эксплуатации ОПО, на которых используется (применяется) оборудование под давлением?",
        answers: [
          "Отклонения от проектной документации не допускаются",
          "Проектной организацией, если технические характеристики, указанные в проектной документации, соответствуют условиям эксплуатации оборудования, работающего под давлением",
          "Проектной организацией, если эти отклонения согласованы со специализированной экспертной организацией",
        ],
        correctAnswers: ["Отклонения от проектной документации не допускаются"],
      },
      {
        code: "68634",
        text: "Применение каких площадок и ступеней лестниц на оборудовании, работающем под избыточным давлением, запрещается?",
        answers: [
          "Из просечно-вытяжного листа",
          "Из рифленой листовой стали",
          "Из прутковой круглой стали или гладких ступеней лестниц",
          "Из сотовой или полосовой (на ребро) стали",
        ],
        correctAnswers: [
          "Из прутковой круглой стали или гладких ступеней лестниц",
        ],
      },
      {
        code: "68635",
        text: "Каковы предельно допустимые значения ширины, высоты между ступенями и ширины ступеней лестниц для обслуживания, осмотра и ремонта оборудования под давлением?",
        answers: [
          "Лестницы должны иметь ширину не менее 800 мм, ширину ступеней не более 80 мм, высота ступеней лестницы должна быть не более 250 мм",
          "Лестницы должны иметь ширину не менее 600 мм, высоту между ступенями не более 200 мм, ширину ступеней не менее 80 мм",
          "Лестницы должны иметь ширину не менее 500 мм, ширину ступеней не менее 50 мм, высоту между ступенями не более 200 мм",
        ],
        correctAnswers: [
          "Лестницы должны иметь ширину не менее 600 мм, высоту между ступенями не более 200 мм, ширину ступеней не менее 80 мм",
        ],
      },
      {
        code: "68636",
        text: "Каково минимальное значение свободной высоты от уровня земли, пола здания (помещения), площадок (мостиков) и ступеней лестниц обслуживания?",
        answers: ["1,3 м", "2,0 м", "1,8 м"],
        correctAnswers: ["2,0 м"],
      },
      {
        code: "68637",
        text: "Какой из приведенных котлов допускается устанавливать внутри производственных помещений?",
        answers: [
          "Прямоточный котел паропроизводительностью не более 5 тонны пара в час",
          "Прямоточный котел паропроизводительностью не более 7 тонн пара в час",
          "Прямоточный котел паропроизводительностью не более 4 тонн пара в час",
          "Прямоточный котел паропроизводительностью не более 10 тонн пара в час",
        ],
        correctAnswers: [
          "Прямоточный котел паропроизводительностью не более 4 тонн пара в час",
        ],
      },
      {
        code: "68638",
        text: "Какое из приведенных требований к размещению котлов внутри производственных помещений указано неверно?",
        answers: [
          "Двери для выхода из помещения, в котором установлены котлы, должны открываться наружу. Двери служебных, бытовых, а также вспомогательных производственных помещений в котельную должны открываться в сторону котельной",
          "Котлы-утилизаторы могут быть отделены от остальной части производственного помещения вместе с печами или агрегатами, с которыми они связаны технологическим процессом",
          "Двери для выхода из помещения, в котором установлены котлы, должны открываться вовнутрь, а двери служебных, бытовых, а также вспомогательных производственных помещений должны открываться в сторону этих помещений",
          "Места установки котлов внутри производственных помещений должны быть отделены от остальной части помещения несгораемыми перегородками по всей высоте котла, но не ниже 2 м с устройством дверей",
        ],
        correctAnswers: [
          "Двери для выхода из помещения, в котором установлены котлы, должны открываться вовнутрь, а двери служебных, бытовых, а также вспомогательных производственных помещений должны открываться в сторону этих помещений",
        ],
      },
      {
        code: "68639",
        text: "В каком случае допускается обустройство площадки для установки котла ниже планировочной отметки территории, прилегающей к зданию, в котором установлены котлы?",
        answers: [
          "Не допускается",
          "Если в здании расположены водогрейные и пароводогрейные котлы",
          "Если в здании расположены котлы-утилизаторы",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "68640",
        text: "Какие из приведенных мест не подлежат оборудованию аварийным освещением?",
        answers: [
          "Водоуказательные и измерительные приборы",
          "Дымососные площадки",
          "Помещения для баков и деаэраторов",
          "Все приведенные места подлежат оборудованию аварийным освещением",
        ],
        correctAnswers: [
          "Все приведенные места подлежат оборудованию аварийным освещением",
        ],
      },
      {
        code: "68641",
        text: "Каково минимально допустимое значение расстояния от фронта котла паропроизводительностью 10 тонн в час до противоположной стены котельного помещения?",
        answers: ["1 м", "1,8 м", "3 м", "5 м"],
        correctAnswers: ["3 м"],
      },
      {
        code: "68642",
        text: "Каково минимально допустимое значение расстояния от фронта котла, работающего на газообразном топливе, до противоположной стены котельного помещения?",
        answers: ["3 м", "5 м", "1,3 м", "1,8 м"],
        correctAnswers: ["3 м"],
      },
      {
        code: "68643",
        text: "Каково минимально допустимое значение расстояния от выступающих частей горелочных устройств котла, работающего на жидком топливе, до стены котельного помещения?",
        answers: ["5 м", "1,5 м", "1 м", "0,5 м"],
        correctAnswers: ["1 м"],
      },
      {
        code: "68644",
        text: "Каково минимально допустимое значение расстояния от противоположной стены котельной до фронта электрокотла мощностью 0,75 МВт?",
        answers: ["1,3 м", "2,5 м", "1 м", "4 м"],
        correctAnswers: ["1 м"],
      },
      {
        code: "68645",
        text: "Каковы должны быть минимально допустимые расстояния между фронтом котлов и выступающими частями топок котлов, расположенных друг против друга, а также расстояние между горелочными устройствами при установке котлов, работающих на газообразном топливе?",
        answers: [
          "Расстояние между фронтом котлов и выступающими частями топок - 2,5 м, расстояние между горелочными устройствами - 2,5 м",
          "Расстояние между фронтом котлов и выступающими частями топок - 4 м, расстояние между горелочными устройствами - 2 м",
          "Расстояние между фронтом котлов и выступающими частями топок - 1,5 м, расстояние между горелочными устройствами - 0,5 м",
          "Расстояние между фронтом котлов и выступающими частями топок - 2 м, расстояние между горелочными устройствами - 4 м",
        ],
        correctAnswers: [
          "Расстояние между фронтом котлов и выступающими частями топок - 4 м, расстояние между горелочными устройствами - 2 м",
        ],
      },
      {
        code: "68646",
        text: "Каково минимальное значение расстояния между фронтом электрокотлов электрической мощностью 5 МВт, расположенных друг против друга?",
        answers: ["1 м", "2 м", "3 м", "5 м"],
        correctAnswers: ["3 м"],
      },
      {
        code: "68647",
        text: "Каково минимальное значение ширины свободных проходов вдоль фронта котла при установке котельного вспомогательного оборудования и щитов управления перед фронтом котлов?",
        answers: ["3,0 м", "1,8 м", "1,5 м", "2,5 м"],
        correctAnswers: ["1,5 м"],
      },
      {
        code: "68648",
        text: "Каково минимальное значение ширины бокового прохода при установке парового котла паропроизводительностью 8 тонн в час, для которого требуется боковое обслуживание?",
        answers: ["3 м", "1,5 м", "2,5 м", "2 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "68649",
        text: "Что должно предусматриваться проектом котельного помещения, если расстояние от нулевой отметки котельного помещения до верхней площадки котлов превышает 20 м?",
        answers: [
          "Подъемные устройства для подъема людей и грузов грузоподъемностью, соответствующей весу перемещаемых грузов и людей (в случае совместного подъема), но не менее 1000 кг",
          "Подъемные устройства для подъема людей и грузов грузоподъемностью, соответствующей весу перемещаемых грузов и людей (в случае совместного подъема), но не менее 500 кг",
          "Установление на высоте стационарных площадок для обслуживания котлов",
        ],
        correctAnswers: [
          "Подъемные устройства для подъема людей и грузов грузоподъемностью, соответствующей весу перемещаемых грузов и людей (в случае совместного подъема), но не менее 1000 кг",
        ],
      },
      {
        code: "68650",
        text: "В каком случае допускается отвод воды продувочным трубопроводом в емкость, работающую под давлением?",
        answers: [
          "Если разность избыточных давлений элемента, подлежащего продувке, и емкости составляет не более 0,5 МПа",
          "Если барабан котла не имеет солевых отсеков и рабочее давление котла не превышает 0,3 МПа",
          "Если подтверждены надежность и эффективность продувки соответствующими расчетами",
          "Если барабан котла имеет солевых отсеков и рабочее давление котла не превышает 0,5 МПа",
        ],
        correctAnswers: [
          "Если подтверждены надежность и эффективность продувки соответствующими расчетами",
        ],
      },
      {
        code: "68651",
        text: "Какое из приведенных требований по установке запорных органов на питательном тракте котла указано неверно?",
        answers: [
          "На питательном трубопроводе котла должны быть установлены обратный клапан и запорный орган",
          "После неотключаемого по воде экономайзера должны быть установлены обратный клапан и запорный орган",
          "У экономайзера, отключаемого по воде, обратный клапан и запорный орган устанавливаются как до, так и после экономайзера",
          "Все приведенные требования указаны верно",
        ],
        correctAnswers: [
          "После неотключаемого по воде экономайзера должны быть установлены обратный клапан и запорный орган",
        ],
      },
      {
        code: "68652",
        text: "Для каких котлов продувочные и дренажные трубопроводы, трубопроводы отбора рабочей среды должны оборудоваться не менее чем двумя запорными органами или одним запорным и одним регулирующим органом?",
        answers: [
          "Для котлов с рабочим давлением более 0,5 МПа",
          "Для котлов с рабочим давлением более 0,8 МПа",
          "Для котлов с рабочим давлением более 0,1 МПа",
          "Для котлов с рабочим давлением более 0,3 МПа",
        ],
        correctAnswers: ["Для котлов с рабочим давлением более 0,8 МПа"],
      },
      {
        code: "68653",
        text: "Главные парозапорные органы каких котлов должны быть оборудованы дистанционным управлением с рабочего места обслуживающего котел персонала?",
        answers: [
          "Котлов с паропроизводительностью более 1 тонны в час",
          "Котлов с паропроизводительностью более 0,5 тонн в час",
          "Котлов с паропроизводительностью более 4 тонн в час",
          "Котлов с паропроизводительностью более 2,5 тонн в час",
        ],
        correctAnswers: ["Котлов с паропроизводительностью более 4 тонн в час"],
      },
      {
        code: "68654",
        text: "Для каких котлов регулирующая арматура на питательной линии не устанавливается?",
        answers: [
          "Для котлов с паропроизводительностью не более 4 тонн в час, у которых проектом котла предусмотрено автоматическое регулирование уровня воды включением и выключением насоса",
          "Для котлов с паропроизводительностью более 2,5 тонн в час, у которых проектом котла предусмотрено автоматическое регулирование уровня воды подачей и напором питательного насоса",
          "Для котлов с паропроизводительностью более 4 тонн в час, у которых проектом котла предусмотрено автоматическое регулирование уровня воды подачей и напором питательного насоса",
          "Для котлов с паропроизводительностью не более 2,5 тонн в час, у которых проектом котла предусмотрено автоматическое регулирование уровня воды включением и выключением насоса",
        ],
        correctAnswers: [
          "Для котлов с паропроизводительностью не более 2,5 тонн в час, у которых проектом котла предусмотрено автоматическое регулирование уровня воды включением и выключением насоса",
        ],
      },
      {
        code: "68655",
        text: "Где должен быть установлен обратный клапан при установке нескольких питательных насосов, имеющих общие всасывающие и нагнетательные трубопроводы?",
        answers: [
          "На стороне всасывания каждого центробежного насоса до запорного органа",
          "На стороне нагнетания каждого центробежного насоса до запорного органа",
          "На стороне всасывания и на стороне нагнетания каждого насоса до запорного органа",
          "На общем нагнетательном трубопроводе после запорных органов на стороне нагнетания центробежных насосов",
        ],
        correctAnswers: [
          "На стороне нагнетания каждого центробежного насоса до запорного органа",
        ],
      },
      {
        code: "68656",
        text: "Для какого котла допускается работа с одним электроприводным питательным насосом?",
        answers: [
          "Для котла паропроизводительностью не более 4 тонны в час, если котел оснащен автоматикой безопасности по предельным уровням воды",
          "Для котла паропроизводительностью не более 5 тонн в час, если котел оснащен автоматикой безопасности по предельным уровням воды",
          "Для всех водогрейных котлов",
          "Для котла паропроизводительностью не более 7,5 тонн в час, если котел оснащен автоматикой безопасности по предельным уровням воды",
        ],
        correctAnswers: [
          "Для котла паропроизводительностью не более 4 тонны в час, если котел оснащен автоматикой безопасности по предельным уровням воды",
        ],
      },
      {
        code: "68657",
        text: "Какое из приведенных требований к выбору напора питательного насоса при групповом питании котлов указано неверно?",
        answers: [
          "Напор, создаваемый насосом, должен обеспечивать питание котла водой при рабочем давлении за котлом с учетом гидростатической высоты и потерь давления в тракте котла, регулирующем устройстве и в тракте питательной воды",
          "Характеристика насоса должна также обеспечивать отсутствие перерывов в питании котла при срабатывании предохранительных клапанов с учетом наибольшего повышения давления при их полном открывании",
          "Напор насоса должен выбираться исходя из условия обеспечения питания котла с наибольшим рабочим давлением или наибольшей потерей напора в питательном трубопроводе",
          "Все приведенные требования указаны верно",
        ],
        correctAnswers: ["Все приведенные требования указаны верно"],
      },
      {
        code: "68658",
        text: "Какое из приведенных требований к установке воздухосборников указано неверно?",
        answers: [
          "Если воздухосборник размещается в одном здании с питающим его источником, то расстояние между воздухосборником и стеной здания должно быть не более 0,5 метра",
          "При установке нескольких воздухосборников расстояние между ними должно быть не менее 1,5 метра",
          "Ограждение воздухосборника должно находиться на расстоянии не менее 2 метров от воздухосборника в сторону проезда или прохода",
          "Все приведенные требования указаны верно",
        ],
        correctAnswers: [
          "Если воздухосборник размещается в одном здании с питающим его источником, то расстояние между воздухосборником и стеной здания должно быть не более 0,5 метра",
        ],
      },
      {
        code: "68659",
        text: "Для какого из приведенных сосудов допускается установка вместо трехходового крана отдельного штуцера с запорным устройством для подсоединения второго манометра?",
        answers: [
          "Сосуд, работающий под давлением более 1,5 МПа",
          "Сосуд, работающий под давлением более 2,5 МПа.",
          "Сосуд, работающий под давлением более 1,3 МПа",
          "Сосуд, работающий под давлением более 0,7 МПа",
        ],
        correctAnswers: ["Сосуд, работающий под давлением более 2,5 МПа."],
      },
      {
        code: "68660",
        text: "Каким образом должен осуществляться контроль исправности пружинного предохранительного клапана, если принудительное его открывание недопустимо по условиям технологического процесса?",
        answers: [
          "Исправность пружинного предохранительного клапана контролируется путем проверки его срабатывания на испытательном стенде",
          "Исправность пружинного предохранительного клапана контролируется путем увеличения рабочих параметров (температура, давление) в сосуде до значений, при которых произойдет срабатывание предохранительного клапана",
          "Исправность пружинного предохранительного клапана контролируется путем установки на сосуде двух клапанов, один из которых является контрольным",
          "Исправность пружинного предохранительного клапана контролируется при его отключении от сосуда с помощью отключающей арматуры, установленной между сосудом и предохранительным клапаном",
        ],
        correctAnswers: [
          "Исправность пружинного предохранительного клапана контролируется путем проверки его срабатывания на испытательном стенде",
        ],
      },
      {
        code: "68661",
        text: "В каком из приведенных случаев запрещается установка сосудов, работающих под давлением, подлежащих учету в территориальных органах Ростехнадзора?",
        answers: [
          "Во всех приведенных случаях",
          "Установка сосуда в помещениях, примыкающих к общественным и бытовым зданиям, при условии отделения их капитальной стеной, конструктивная прочность которой определена проектной документацией",
          "Установка сосудов в производственных помещениях, включая помещения котельных, тепловых и гидравлических электростанций (ТЭС, ГЭС)",
          "Установка сосуда с заглублением в грунт при условии обеспечения доступа к арматуре и защиты стенок сосуда от коррозии",
        ],
        correctAnswers: [
          "Установка сосуда в помещениях, примыкающих к общественным и бытовым зданиям, при условии отделения их капитальной стеной, конструктивная прочность которой определена проектной документацией",
        ],
      },
      {
        code: "68662",
        text: "В каком случае проектом размещения сосуда допускается его установка в производственных помещениях?",
        answers: [
          "Если объем производственного помещения не менее чем в 2 раза больше вместимости сосуда",
          "Если рабочее давление сосуда не превышает 1,7 МПа",
          "Если по условиям технологического процесса или условиям эксплуатации невозможна установка сосуда вне производственных помещений",
          "Если установка сосуда в производственных помещениях предусмотрена проектом производства работ",
        ],
        correctAnswers: [
          "Если по условиям технологического процесса или условиям эксплуатации невозможна установка сосуда вне производственных помещений",
        ],
      },
      {
        code: "68663",
        text: "Какая организация может осуществлять монтаж, наладку, техническое обслуживание и ремонт медицинских барокамер?",
        answers: [
          "Организация, имеющая лицензию на право проведения экспертизы промышленной безопасности барокамер",
          "Организация, имеющая аттестованных специалистов на право проведения данного вида работ",
          "Организация, зарегистрированная в реестре организаций, осуществляющих обслуживание медицинской техники",
          "Специализированная организация, соответствующая требованиям главы III ФНП ОРПД и имеющая лицензию на техническое обслуживание данного вида медицинской техники",
          "Любая организация, имеющая право на обслуживание сосудов под давлением",
        ],
        correctAnswers: [
          "Специализированная организация, соответствующая требованиям главы III ФНП ОРПД и имеющая лицензию на техническое обслуживание данного вида медицинской техники",
        ],
      },
      {
        code: "68664",
        text: "В каком случае допускается не оснащать обратным клапаном линию подвода рабочей среды, отнесенной к группе 1 в соответствии с техническим регламентом Таможенного союза «О безопасности оборудования, работающего под избыточным давлением», к сосуду?",
        answers: [
          "Если рабочей средой в сосуде является сжиженный углекислый газ",
          "Если рабочее давление в сосуде не превышает 0,7 МПа",
          "Если рабочее давление в сосуде не превышает 0,3 МПа",
          "Если рабочей средой в сосуде является сжиженный природный газ",
        ],
        correctAnswers: [
          "Если рабочей средой в сосуде является сжиженный природный газ",
        ],
      },
      {
        code: "68665",
        text: "Каково минимальное значение уклона горизонтальных участков труб тепловых сетей?",
        answers: ["0,002", "0,004", "0,001", "0,006"],
        correctAnswers: ["0,002"],
      },
      {
        code: "68666",
        text: "Каково минимальное значение высоты каналов и ширины прохода между изолированными трубопроводами пара и горячей воды при их прокладке в полупроходных каналах?",
        answers: [
          "Высота канала 2,5 метра, ширина прохода 0,5 метра",
          "Высота канала 1,5 метра, ширина прохода 0,6 метра",
          "Высота канала 2,0 метра, ширина прохода 0,4 метра",
          "Высота канала 1,8 метра, ширина прохода 0,7 метра",
        ],
        correctAnswers: ["Высота канала 1,5 метра, ширина прохода 0,6 метра"],
      },
      {
        code: "68667",
        text: "Каково минимальное значение высоты тоннеля (коллектора) и ширины прохода между изолированными трубопроводами пара и горячей воды при их прокладке в проходных тоннелях (коллекторах)?",
        answers: [
          "Высота тоннеля (коллектора) в свету - 1,8 метра, ширина прохода - 0,5 метра",
          "Высота тоннеля (коллектора) в свету - 1 метра, ширина прохода - 0,3 метра",
          "Высота тоннеля (коллектора) в свету 2 метра, ширина прохода 0,7 метра",
          "Высота тоннеля (коллектора) в свету - 2,5 метра, ширина прохода - 0,9 метра",
        ],
        correctAnswers: [
          "Высота тоннеля (коллектора) в свету 2 метра, ширина прохода 0,7 метра",
        ],
      },
      {
        code: "68668",
        text: "Какое из приведенных требований должно выполняться при оснащении проходных каналов для трубопроводов пара и горячей воды входными люками?",
        answers: [
          "Расстояние между люками должно быть не более 700 метров; для всех трубопроводов люки также предусматриваются в конечных точках тупиковых участков, на поворотах трассы и в узлах установки арматуры",
          "Расстояние между люками должно быть не более 500 метров; для всех трубопроводов люки также предусматриваются в конечных точках тупиковых участков, на поворотах трассы и в узлах установки арматуры",
          "Расстояние между люками должно быть не более 300 метров; для всех трубопроводов люки также предусматриваются в конечных точках тупиковых участков, на поворотах трассы и в узлах установки арматуры",
        ],
        correctAnswers: [
          "Расстояние между люками должно быть не более 300 метров; для всех трубопроводов люки также предусматриваются в конечных точках тупиковых участков, на поворотах трассы и в узлах установки арматуры",
        ],
      },
      {
        code: "68669",
        text: "Какие трубопроводы должны быть оснащены указателями перемещений?",
        answers: [
          "Паропроводы диаметром 150 мм и более и температурой пара 300 °С и выше",
          "Все паропроводы должны оснащаться указателями перемещений",
          "Паропроводы диаметром до 100 мм и температурой пара до 150 °С",
        ],
        correctAnswers: [
          "Паропроводы диаметром 150 мм и более и температурой пара 300 °С и выше",
        ],
      },
      {
        code: "68670",
        text: "Какое требование к установке запорной арматуры на тепловых сетях указано неверно?",
        answers: [
          "Установка запорной арматуры предусматривается в водяных и паровых тепловых сетях в узлах на трубопроводах пара или горячей воды диаметром свыше 100 мм",
          "Установка запорной арматуры предусматривается на трубопроводах водяных сетей условным диаметром 100 мм и более на расстоянии не более 1000 м",
          "Установка запорной арматуры предусматривается на конденсатопроводах условным диаметром свыше 100 мм на вводе к сборному баку конденсата",
          "Установка запорной арматуры предусматривается в водяных и паровых тепловых сетях в узлах на трубопроводах ответвлений к отдельным зданиям независимо от диаметра трубопровода",
        ],
        correctAnswers: [
          "Установка запорной арматуры предусматривается на конденсатопроводах условным диаметром свыше 100 мм на вводе к сборному баку конденсата",
        ],
      },
      {
        code: "68671",
        text: "Для какой арматуры трубопроводов пара и горячей воды должен быть предусмотрен электро-, гидро- или пневмопривод?",
        answers: [
          "Для задвижек и затворов диаметром 500 мм и более",
          "Для задвижек и затворов диаметром 100 мм и более",
          "Для распределительной арматуры тепловых сетей, проложенных в непроходных и полупроходных каналах",
          "Для задвижек и затворов диаметром 300 мм и более",
        ],
        correctAnswers: ["Для задвижек и затворов диаметром 500 мм и более"],
      },
      {
        code: "68672",
        text: "Какое требование к обеспечению прогрева и продувки паропроводов указано неверно?",
        answers: [
          "Паропроводы при давлении 20 МПа и выше должны быть обеспечены штуцерами с последовательно расположенными запорным и регулирующим вентилями и дроссельной шайбой",
          "Все участки паропроводов, которые могут быть отключены запорными органами, для возможности их прогрева и продувки должны быть снабжены в концевых точках штуцером с вентилем",
          "В случаях прогрева участка паропровода в обоих направлениях продувка должна быть предусмотрена в середине участка паропровода",
          "Паропроводы при давлении свыше 2,2 МПа должны быть снабжены штуцером и двумя последовательно расположенными вентилями: запорным и регулирующим",
        ],
        correctAnswers: [
          "В случаях прогрева участка паропровода в обоих направлениях продувка должна быть предусмотрена в середине участка паропровода",
        ],
      },
      {
        code: "68673",
        text: "Кем вносятся сведения в ремонтный журнал котла о выполненных ремонтных работах, примененных материалах, сварке и сварщиках, об остановке котлов на чистку и промывку?",
        answers: [
          "Ответственным за исправное состояние и безопасную эксплуатацию котла",
          "Ответственным за осуществление производственного контроля за безопасной эксплуатацией оборудования, работающего под давлением",
          "Ремонтным персоналом специализированной ремонтной организации",
          "Представителем Ростехнадзора",
        ],
        correctAnswers: [
          "Ответственным за исправное состояние и безопасную эксплуатацию котла",
        ],
      },
      {
        code: "68674",
        text: "С кем должно согласовываться применение при ремонте оборудования под давлением материалов, не установленных требованиями технической документации изготовителя и проектной документации?",
        answers: [
          "С научно-исследовательской организацией",
          "С Ростехнадзором",
          "С проектной организацией",
          "Применение таких материалов допускается при условии согласования возможности их применения с разработчиком проекта и (или) изготовителем, а в случае их отсутствия - на основании заключения научно-исследовательской организации, специализирующейся в области материаловедения",
        ],
        correctAnswers: [
          "Применение таких материалов допускается при условии согласования возможности их применения с разработчиком проекта и (или) изготовителем, а в случае их отсутствия - на основании заключения научно-исследовательской организации, специализирующейся в области материаловедения",
        ],
      },
      {
        code: "68675",
        text: "В каком случае допускается применение при монтаже, ремонте и реконструкции (модернизации) оборудования под давлением полуфабрикатов, изготовленных из новых материалов?",
        answers: [
          "Допускается на основании результатов исследований (исследовательской аттестации), выполненных научно-исследовательской организацией, подтверждающих обеспечение безопасных эксплуатационных параметров, а также положительного опыта их применения при изготовлении оборудования под давлением",
          "Если есть согласование с проектной организацией или изготовителем оборудования",
          "Если существуют документы, подтверждающие соответствие полуфабрикатов, изготовленных из новых материалов, требованиям ТР ТС 032/2013",
          "Категорически не допускается",
        ],
        correctAnswers: [
          "Допускается на основании результатов исследований (исследовательской аттестации), выполненных научно-исследовательской организацией, подтверждающих обеспечение безопасных эксплуатационных параметров, а также положительного опыта их применения при изготовлении оборудования под давлением",
        ],
      },
      {
        code: "68676",
        text: "Каким документом устанавливается численность персонала специализированной организации, осуществляющей монтаж, ремонт, реконструкцию (модернизацию) оборудования под давлением?",
        answers: [
          "Регламентом на проведение работ",
          "Договором со специализированной организацией",
          "Распорядительным документом Ростехнадзора",
          "Распорядительным документом специализированной организации",
        ],
        correctAnswers: [
          "Распорядительным документом специализированной организации",
        ],
      },
      {
        code: "68677",
        text: "Какой организацией определяются процедуры контроля соблюдения технологических процессов при осуществлении работ по монтажу, ремонту, реконструкции (модернизации) оборудования, работающего под давлением?",
        answers: [
          "Организацией, эксплуатирующей оборудование, работающее под давлением",
          "Специализированной организацией, выполняющей работы по монтажу, ремонту, реконструкции (модернизации) оборудования",
          "Специализированной организацией, проводящей специальную оценку условий труда",
          "Организацией, осуществляющей экспертизу промышленной безопасности",
        ],
        correctAnswers: [
          "Специализированной организацией, выполняющей работы по монтажу, ремонту, реконструкции (модернизации) оборудования",
        ],
      },
      {
        code: "68678",
        text: "В каком из приведенных случаев допускается использование при монтаже, ремонте, реконструкции (модернизации) стальных труб и иных материалов, ранее бывших в употреблении?",
        answers: [
          "В случае применения стальных труб только при монтаже, ремонте, реконструкции (модернизации) водогрейных котлов и трубопроводов горячей воды",
          "В случае применения стальных труб с оформленными документами, подтверждающими их соответствие и качество (сертификаты, декларации соответствия)",
          "В случае применения стальных труб со сроком эксплуатации, не превышающем половины расчетного срока службы технического устройства, на котором эти трубы употреблялись",
          "В случае применения таких труб в составе обводных (байпасных) и продувочных линий, временно обустраиваемых на ограниченный период времени, определенный проектом монтажа, ремонта, реконструкции (модернизации) системы трубопроводов",
        ],
        correctAnswers: [
          "В случае применения таких труб в составе обводных (байпасных) и продувочных линий, временно обустраиваемых на ограниченный период времени, определенный проектом монтажа, ремонта, реконструкции (модернизации) системы трубопроводов",
        ],
      },
      {
        code: "68679",
        text: "Отсутствие какой документации не является препятствием для осуществления монтажа, ремонта, реконструкции (модернизации) оборудования, работающего под давлением, специализированной организацией?",
        answers: [
          "Эксплуатационной документации монтируемого, ремонтируемого, реконструируемого (модернизируемого) оборудования",
          "Проектной документации",
          "Нормативной документации, применяемой при выполнении соответствующих работ в специализированной организации",
          "Технической документации",
        ],
        correctAnswers: [
          "Эксплуатационной документации монтируемого, ремонтируемого, реконструируемого (модернизируемого) оборудования",
        ],
      },
      {
        code: "68680",
        text: "Какой организацией должна быть разработана технологическая документация, регламентирующая содержание и порядок выполнения работ по монтажу, ремонту, реконструкции (модернизации) оборудования, работающего под давлением, с применением сварки и термической обработки?",
        answers: [
          "Эксплуатирующей организацией",
          "Эксплуатирующей организацией на основании руководства (инструкции) по эксплуатации оборудования, работающего под давлением, с последующим согласованием со специализированной организацией, выполняющей эти работы",
          "Специализированной организацией, выполняющей эти работы, до начала их производства",
          "Организацией - изготовителем оборудования, работающего под давлением",
        ],
        correctAnswers: [
          "Специализированной организацией, выполняющей эти работы, до начала их производства",
        ],
      },
      {
        code: "68681",
        text: "Чем должно быть обеспечено соответствие выполнения работ по монтажу, ремонту, реконструкции (модернизации) оборудования с применением сварки и термической обработки требованиям технологической документации?",
        answers: [
          "Декларированием специализированной организацией политики качества, обеспечивающей выполнение работ",
          "Системой подтверждения соответствия выполняемых работ",
          "Разработкой в специализированной организации в соответствии с международными стандартами ISO системой качества",
          "Установленной распорядительными документами специализированной организации системой контроля качества (входной, операционный, приемочный)",
        ],
        correctAnswers: [
          "Установленной распорядительными документами специализированной организации системой контроля качества (входной, операционный, приемочный)",
        ],
      },
      {
        code: "68682",
        text: "К выполнению каких работ могут быть допущены сварщики и специалисты сварочного производства?",
        answers: [
          "К выполнению сварочных работ, указанных в действующих документах",
          "К выполнению работ на высоте",
          "К выполнению земляных работ",
          "К выполнению работ в электроустановках",
        ],
        correctAnswers: [
          "К выполнению сварочных работ, указанных в действующих документах",
        ],
      },
      {
        code: "68683",
        text: "Каким способом может производиться резка листов, труб и других полуфабрикатов, а также вырезка отверстий при монтаже, ремонте, реконструкции (модернизации) оборудования под давлением?",
        answers: [
          "Вырезка отверстий производится любым способом (механическим, газопламенным, электродуговым, плазменным). Способ резки устанавливается технологической документацией в зависимости от классов сталей",
          "Резка листов, труб и других полуфабрикатов, а также вырезка отверстий могут быть произведены любым способом (механическим, газопламенным, электродуговым, плазменным), применяемым специализированной организацией",
          "Резка листов, труб и других полуфабрикатов, а также вырезка отверстий при монтаже, ремонте, реконструкции (модернизации) котлов могут осуществляться только электродуговым и газопламенным способом",
        ],
        correctAnswers: [
          "Вырезка отверстий производится любым способом (механическим, газопламенным, электродуговым, плазменным). Способ резки устанавливается технологической документацией в зависимости от классов сталей",
        ],
      },
      {
        code: "68684",
        text: "Какая процедура из указанных при холодном натяге трубопроводов проводится только в случае ее необходимости?",
        answers: [
          "Радиографический контроль сварных соединений",
          "Термическая обработка сварных соединений",
          "Визуальный контроль сварных соединений",
          "Капиллярный контроль сварных соединений",
        ],
        correctAnswers: ["Термическая обработка сварных соединений"],
      },
      {
        code: "68685",
        text: "Какую проверку должен пройти сварщик, впервые приступающий к сварке, перед допуском к работе?",
        answers: [
          "Проверку путем выполнения и контроля допускного сварного соединения",
          "Проверку знаний по охране труда и технике безопасности",
          "Проверку готовности к применению аттестованных технологий сварки",
        ],
        correctAnswers: [
          "Проверку путем выполнения и контроля допускного сварного соединения",
        ],
      },
      {
        code: "68686",
        text: "Какие требования, касающиеся подготовительных работ, должна предусматривать технологическая документация на сварку?",
        answers: [
          "При сборке стыковых соединений труб с односторонней разделкой кромок не допускается смещение (несовпадение) внутренних кромок",
          "Перед началом сварки должно быть проверено качество сборки соединяемых элементов, а также состояние стыкуемых ударным способом кромок и прилегающих к ним поверхностей, подвергающихся местному нагреву",
          "Глубина механической обработки после термической резки (строжки) при подготовке кромок под сварку должна быть не более 5 % от толщины свариваемых элементов",
          "Подготовка кромок и поверхностей под сварку должна быть выполнена механической обработкой либо путем термической резки или строжки с последующей механической обработкой",
        ],
        correctAnswers: [
          "Подготовка кромок и поверхностей под сварку должна быть выполнена механической обработкой либо путем термической резки или строжки с последующей механической обработкой",
        ],
      },
      {
        code: "68687",
        text: "Какие требования, касающиеся приварки и удаления вспомогательных элементов, а также прихватки собранных под сварку элементов, должна предусматривать технологическая документация на сварку?",
        answers: [
          "Прихватки при дальнейшем проведении сварочных работ удаляют или переплавляют основным швом",
          "Прихватка собранных под сварку элементов должна быть выполнена с использованием таких же сварочных материалов, которые используются в основном изделии",
          "При приварке вспомогательных элементов размеры закалочных зон в металле оборудования должны быть минимальными",
        ],
        correctAnswers: [
          "Прихватки при дальнейшем проведении сварочных работ удаляют или переплавляют основным швом",
        ],
      },
      {
        code: "68688",
        text: "Что должно быть указано в технологических картах сварки?",
        answers: [
          "Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений",
          "Требования к сварочным аппаратам и используемым материалам, последовательность операций",
          "Требования к персоналу, проводящему сварочные работы",
        ],
        correctAnswers: [
          "Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений",
        ],
      },
      {
        code: "68689",
        text: "Каким образом допускается маркировать сварное соединение, выполненное несколькими сварщиками (бригадой сварщиков)?",
        answers: [
          "Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
          "Допускается применение клейма одного из сварщиков",
          "Допускается применение клейма бригадира сварочной бригады",
          "Допускается применение клейма всех сварщиков сварочной бригады",
        ],
        correctAnswers: [
          "Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
        ],
      },
      {
        code: "68690",
        text: "Что необходимо предпринять, если при техническом освидетельствовании будет установлено, что трубопровод вследствие имеющихся дефектов или нарушений находится в состоянии, опасном для дальнейшей его эксплуатации?",
        answers: [
          "Работа такого трубопровода должна быть запрещена",
          "Снизить параметры рабочей среды в трубопроводе (температуры, давления)",
          "Провести повторное техническое освидетельствование",
        ],
        correctAnswers: ["Работа такого трубопровода должна быть запрещена"],
      },
      {
        code: "68691",
        text: "Отсутствие какого из приведенных требований не является препятствием для допуска к применению технологии сварки при монтаже, ремонте, реконструкции (модернизации) трубопровода?",
        answers: [
          "Отсутствие клейма у одного из сварщиков",
          "Аттестация технологии сварки в соответствии с установленными требованиями",
          "Наличие системы качества в соответствии с международными стандартами серии ISO 9000",
          "Ежегодное подтверждение сварщиками знаний в объеме инструкции по охране труда",
        ],
        correctAnswers: [
          "Наличие системы качества в соответствии с международными стандартами серии ISO 9000",
        ],
      },
      {
        code: "68692",
        text: "В каких целях проводится производственная аттестация технологии сварки?",
        answers: [
          "Для подтверждения того, что организация, занимающаяся монтажом, ремонтом, реконструкцией (модернизацией) оборудования под давлением, обладает техническими, организационными возможностями и квалифицированными кадрами для производства сварки по аттестуемым технологиям, а также проверки того, что сварные соединения (наплавки), выполненные в условиях конкретного производства по аттестуемой технологии, обеспечивают соответствие требованиям Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, нормативных документов и технологической документации по сварке",
          "Для проверки соответствия применяемой технологии сварки результатам, полученным при исследовательской аттестации сварки",
          "Для определения характеристик сварных соединений, необходимых для расчетов при проектировании и выдаче технологических рекомендаций",
          "Для выдачи рекомендаций, необходимых для практического применения данной технологии другими специализированными организациями",
        ],
        correctAnswers: [
          "Для подтверждения того, что организация, занимающаяся монтажом, ремонтом, реконструкцией (модернизацией) оборудования под давлением, обладает техническими, организационными возможностями и квалифицированными кадрами для производства сварки по аттестуемым технологиям, а также проверки того, что сварные соединения (наплавки), выполненные в условиях конкретного производства по аттестуемой технологии, обеспечивают соответствие требованиям Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, нормативных документов и технологической документации по сварке",
        ],
      },
      {
        code: "68693",
        text: "Какой документ определяет порядок проведения производственной аттестации технологии сварки?",
        answers: [
          "Проектная документация, разработанная проектной организацией",
          "Технологическая документация, разработанная специализированной организацией",
          "Эксплуатационная документация",
          "Нормативная документация",
        ],
        correctAnswers: [
          "Технологическая документация, разработанная специализированной организацией",
        ],
      },
      {
        code: "68694",
        text: "Что должна дополнительно предусматривать программа производственной аттестации технологии газовой сварки для деталей из аустенитных сталей и высокохромистых сталей мартенситного и мартенситно-ферритного классов?",
        answers: [
          "Для деталей из аустенитных сталей и высокохромистых сталей мартенситного и мартенситно-ферритного классов не допускается применение газовой сварки",
          "Определение механических свойств при нормальной (10 ± 10 °С) и рабочей температуре, в том числе временного сопротивления разрыву, предела текучести, относительного удлинения и относительного сужения металла шва, ударной вязкости металла шва и зоны термического влияния сварки",
          "Проведение испытаний на стойкость против межкристаллитной коррозии",
          "Определение интенсивности окисляемости в рабочей среде",
        ],
        correctAnswers: [
          "Для деталей из аустенитных сталей и высокохромистых сталей мартенситного и мартенситно-ферритного классов не допускается применение газовой сварки",
        ],
      },
      {
        code: "68695",
        text: "Какое требование к выполнению сварки в условиях отрицательной температуры указано неверно?",
        answers: [
          "При отрицательной температуре окружающего воздуха подогрев производят в тех же случаях, что и при положительной, при этом температура подогрева должна быть выше на 50 °С",
          "При отрицательной температуре окружающего воздуха металл в районе сварного соединения перед сваркой должен быть просушен и прогрет с доведением температуры до положительного значения",
          "При отрицательной температуре окружающего воздуха подогрев производят в тех же случаях, что и при положительной, при этом температура подогрева должна быть выше на 30 °С",
        ],
        correctAnswers: [
          "При отрицательной температуре окружающего воздуха подогрев производят в тех же случаях, что и при положительной, при этом температура подогрева должна быть выше на 30 °С",
        ],
      },
      {
        code: "68696",
        text: "Что должно учитываться в технологической документации в случае необходимости проведения термической обработки элементов оборудования при его монтаже, ремонте, реконструкции (модернизации)?",
        answers: [
          "Рекомендации организации-изготовителя, указанные в руководстве (инструкции) по эксплуатации",
          "Требования по режиму и технологии термической обработки элементов оборудования, указанные в Правилах промышленной безопасности при использовании оборудования, работающего под избыточным давлением",
          "Данные, полученные методом неразрушающего контроля",
          "Данные, указанные в документации по эксплуатации на данное оборудование",
        ],
        correctAnswers: [
          "Рекомендации организации-изготовителя, указанные в руководстве (инструкции) по эксплуатации",
        ],
      },
      {
        code: "68697",
        text: "Каким образом должны выбираться методы и объем контроля качества сварных соединений при монтаже, ремонте, реконструкции (модернизации) оборудования, работающего под давлением?",
        answers: [
          'В соответствии с требованиями технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" (ТР ТС 032/2013)',
          "В соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением",
          "По решению руководителя организации, осуществляющей монтаж, ремонт, реконструкцию (модернизацию) оборудования под давлением",
          "В соответствии с требованиями государственных стандартов",
        ],
        correctAnswers: [
          "В соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением",
        ],
      },
      {
        code: "68698",
        text: "Каким образом оформляются результаты проводимого контроля качества сварных соединений?",
        answers: [
          "Оформление результатов качества сварных соединений не регламентируется",
          "Результаты по каждому виду проводимого контроля и месту контроля оформляются в виде отчета",
          "Результаты по каждому виду проводимого контроля и месту контроля должны фиксироваться в отчетной документации (журналы, формуляры, протоколы, маршрутные паспорта)",
        ],
        correctAnswers: [
          "Результаты по каждому виду проводимого контроля и месту контроля должны фиксироваться в отчетной документации (журналы, формуляры, протоколы, маршрутные паспорта)",
        ],
      },
      {
        code: "68699",
        text: "Где должны быть установлены методы и объемы контроля сварных соединений приварных деталей, не работающих под внутренним давлением?",
        answers: [
          "В нормативной документации",
          "В эксплуатационной документации",
          "В проектной документации",
          "В технологической документации",
        ],
        correctAnswers: ["В технологической документации"],
      },
      {
        code: "68700",
        text: "Какое из приведенных требований должно выполняться при проведении визуального и измерительного контроля?",
        answers: [
          "Поверхностные дефекты, выявленные при визуальном и измерительном контроле, должны быть исправлены до проведения контроля другими неразрушающими методами",
          "В случае невозможности осмотра и измерения сварного соединения с двух сторон его контроль должен быть проведен в порядке, предусмотренном технологической документацией на сварку",
          "Перед визуальным контролем поверхности сварного шва и прилегающих к нему участков основного металла шириной не менее 5 мм в обе стороны от шва должны быть зачищены",
        ],
        correctAnswers: [
          "Поверхностные дефекты, выявленные при визуальном и измерительном контроле, должны быть исправлены до проведения контроля другими неразрушающими методами",
        ],
      },
      {
        code: "68701",
        text: "В какой документации указывается необходимость проведения и объем ультразвукового и радиографического контроля, типы и размеры несплошностей (дефектов), подлежащих обнаружению, для конкретного вида (типа) конструкции оборудования под давлением и сварного соединения?",
        answers: [
          "Эксплуатационной документацией",
          "Проектной (конструкторской) и технологической документацией",
          "Нормативной документацией",
        ],
        correctAnswers: [
          "Проектной (конструкторской) и технологической документацией",
        ],
      },
      {
        code: "68702",
        text: "В каких случаях допускается замена ультразвукового и радиографического контроля другими методами неразрушающего контроля?",
        answers: [
          "Если применение других методов неразрушающего контроля согласовано с разработчиком проектной документации",
          "Если применение других методов контроля не представляется возможным",
          "Если проводится гидравлическое испытание пробным давлением",
        ],
        correctAnswers: [
          "Если применение других методов неразрушающего контроля согласовано с разработчиком проектной документации",
        ],
      },
      {
        code: "68703",
        text: "Какое из приведенных требований должно выполняться в случае обнаружения дефектов при ультразвуковом и радиографическом контроле сварных соединений?",
        answers: [
          "Стыковые сварные соединения, которые были подвергнуты устранению дефекта сварного шва, должны быть проверены ультразвуковой дефектоскопией или радиографическим контролем по всей длине сварных соединений",
          "Проведение ультразвукового и радиографического контроля с целью выявления в сварных соединениях из металлов и сплавов несоответствия формы и размеров шва требованиям технологической документации",
          "Ремонтные заварки выборок металла должны быть проверены стилоскопированием по всему участку заварки",
        ],
        correctAnswers: [
          "Стыковые сварные соединения, которые были подвергнуты устранению дефекта сварного шва, должны быть проверены ультразвуковой дефектоскопией или радиографическим контролем по всей длине сварных соединений",
        ],
      },
      {
        code: "68704",
        text: "В каких целях проводится магнитопорошковый контроль сварных соединений?",
        answers: [
          "Для определения поверхностных и подповерхностных дефектов в стальных ферромагнитных конструкциях и деталях",
          "Для выявления в сварных соединениях из металлов и сплавов несплошностей, в том числе: трещин, непроваров, пор, неметаллических и металлических включений, прожогов подкладного кольца",
          "Для проверки качества выполнения термической обработки сварных соединений",
        ],
        correctAnswers: [
          "Для определения поверхностных и подповерхностных дефектов в стальных ферромагнитных конструкциях и деталях",
        ],
      },
      {
        code: "68705",
        text: "В каких целях проводится контроль сварных швов стилоскопированием?",
        answers: [
          "Для подтверждения соответствия металла сварных швов и деталей, элементов оборудования под давлением из легированной стали требованиям проектной (конструкторской) и технологической документации",
          "Для выявления свищей и пористости наружной поверхности шва, наплывов, прожогов",
          "Для проверки качества выполнения термической обработки сварных соединений",
        ],
        correctAnswers: [
          "Для подтверждения соответствия металла сварных швов и деталей, элементов оборудования под давлением из легированной стали требованиям проектной (конструкторской) и технологической документации",
        ],
      },
      {
        code: "68706",
        text: "В каких целях проводится измерение твердости при контроле сварных соединений?",
        answers: [
          "Для проверки качества выполнения термической обработки сварных соединений",
          "Для проверки соответствия механических свойств сварных соединений требованиям конструкторской и технологической документации",
          "Для определения поверхностных или подповерхностных дефектов",
        ],
        correctAnswers: [
          "Для проверки качества выполнения термической обработки сварных соединений",
        ],
      },
      {
        code: "68707",
        text: "Какое требование к складам для хранения баллонов с углекислотой указано неверно?",
        answers: [
          "В складах должны быть вывешены инструкции, правила и плакаты по обращению с баллонами, находящимися на складе",
          "Склады для баллонов, наполненных газом, должны иметь естественную или искусственную вентиляцию",
          "Склады для хранения баллонов должны быть разделены несгораемыми стенами на отсеки, в каждом из которых допускается хранение не более 500 баллонов с горючими или ядовитыми газами и не более 1000 баллонов с негорючими и неядовитыми газами",
          "Склады для баллонов должны находиться в зоне молниезащиты",
        ],
        correctAnswers: [
          "Склады для баллонов должны находиться в зоне молниезащиты",
        ],
      },
      {
        code: "68708",
        text: "Какое из приведенных испытаний не является обязательным видом механических испытаний котлов (трубопроводов)?",
        answers: [
          "Испытание на ударный изгиб",
          "Испытание на статический изгиб",
          "Испытание на статическое растяжение",
        ],
        correctAnswers: ["Испытание на ударный изгиб"],
      },
      {
        code: "68709",
        text: "В каком из приведенных случаев должны проводиться механические испытания?",
        answers: [
          "При исправлении дефектов сварных соединений",
          "При визуальном контроле сварных стыковых соединений",
          "При аттестации технологии сварки",
        ],
        correctAnswers: ["При аттестации технологии сварки"],
      },
      {
        code: "68710",
        text: "Какое из приведенных требований должно выполняться при исправлении дефектов сварных соединений?",
        answers: [
          "Методы и качество устранения дефектов должны обеспечивать необходимую надежность и безопасность работы оборудования",
          "Технология устранения дефектов устанавливается проектной документацией",
          "Полнота удаления дефектов должна быть проконтролирована визуально и методом разрушающего контроля",
        ],
        correctAnswers: [
          "Методы и качество устранения дефектов должны обеспечивать необходимую надежность и безопасность работы оборудования",
        ],
      },
      {
        code: "68711",
        text: "Какое из приведенных требований должно выполняться при исправлении дефектов сварных соединений?",
        answers: [
          "Удаление дефектов следует проводить только способом кислородной резки",
          "Максимальные размеры и форма подлежащих заварке выборок устанавливаются технологической документацией",
          "Технология устранения дефектов устанавливается нормативной документацией",
        ],
        correctAnswers: [
          "Максимальные размеры и форма подлежащих заварке выборок устанавливаются технологической документацией",
        ],
      },
      {
        code: "68712",
        text: "Что не включается в состав итоговой документации, подтверждающей контроль качества ремонта оборудования с применением сварки и термической обработки?",
        answers: [
          "Документы по результатам контроля качества работ",
          "Ремонтные рабочие чертежи и формуляры",
          "Технологическая документация и протокол производственной аттестации сварки",
        ],
        correctAnswers: [
          "Технологическая документация и протокол производственной аттестации сварки",
        ],
      },
      {
        code: "68713",
        text: "На кого возлагается контроль за соблюдением требований ремонтных рабочих чертежей и технологической документации на ремонт?",
        answers: [
          "На представителя Ростехнадзора",
          "На подразделение технического контроля организации, выполняющей работы по ремонту, реконструкции (модернизации) оборудования, а также на уполномоченного представителя эксплуатирующей организации",
          "На ответственного за безопасную эксплуатацию оборудования, работающего под избыточным давлением",
        ],
        correctAnswers: [
          "На подразделение технического контроля организации, выполняющей работы по ремонту, реконструкции (модернизации) оборудования, а также на уполномоченного представителя эксплуатирующей организации",
        ],
      },
      {
        code: "68714",
        text: "Когда на оборудовании, работающем под давлением, проводятся предусмотренные руководством (инструкцией) по эксплуатации пусконаладочные работы?",
        answers: [
          "После окончания монтажных работ с оформлением удостоверения о качестве монтажа",
          "После составления акта о вводе оборудования в эксплуатацию",
          "После оформления удостоверения о качестве монтажа и проведения первичного технического освидетельствования",
        ],
        correctAnswers: [
          "После оформления удостоверения о качестве монтажа и проведения первичного технического освидетельствования",
        ],
      },
      {
        code: "68715",
        text: "Какое из приведенных требований к программе проведения наладочных работ на оборудовании под давлением указано неверно?",
        answers: [
          "Программу разрабатывает организация, выполняющая соответствующие работы, и согласовывает ее с эксплуатирующей организацией до начала производства работ",
          "В программе должны быть отражены содержание и порядок выполнения всех технологических и контрольных операций с обеспечением наладки на всех режимах работы, установленных проектом",
          "Программа проведения наладочных работ подлежит согласованию с эксплуатирующей организацией в случае, если наладка осуществляется на паровых котлах и сосудах, работающих со средой, отнесенной к группе 1 в соответствии с ТР ТС 032/2013",
        ],
        correctAnswers: [
          "Программа проведения наладочных работ подлежит согласованию с эксплуатирующей организацией в случае, если наладка осуществляется на паровых котлах и сосудах, работающих со средой, отнесенной к группе 1 в соответствии с ТР ТС 032/2013",
        ],
      },
      {
        code: "68716",
        text: "Каков минимальный срок проведения пусконаладочных работ на оборудовании, работающем под давлением?",
        answers: [
          "Продолжительность проведения пусконаладочных работ определяется организацией, выполняющей пусконаладочные работы",
          "Продолжительность проведения пусконаладочных работ определяется эксплуатирующей организацией исходя из сроков ввода оборудования в эксплуатацию",
          "Продолжительность проведения пусконаладочных работ определяется программой в зависимости от сложности оборудования",
        ],
        correctAnswers: [
          "Продолжительность проведения пусконаладочных работ определяется программой в зависимости от сложности оборудования",
        ],
      },
      {
        code: "68717",
        text: "Каким документом определяется ответственность за безопасность обслуживания оборудования под давлением в период проведения наладочных работ?",
        answers: [
          "Программой наладочных работ",
          "Планом производства работ",
          "Приказом по предприятию",
        ],
        correctAnswers: ["Программой наладочных работ"],
      },
      {
        code: "68718",
        text: "Какая из приведенных операций не подлежит обязательному включению в программу наладочных работ, а проводится в случаях, предусмотренных проектом и руководством по эксплуатации?",
        answers: [
          "Промывка и продувка оборудования и трубопроводов",
          "Вывод технологического процесса на устойчивый режим работы с производительностью, соответствующей проектным требованиям",
          "Проверка измерительных приборов, настройка и проверка работоспособности систем автоматизации, сигнализации, защит, блокировок, управления, а также регулировка предохранительных клапанов",
          "Отработка и стабилизация технологического режима, регистрация и анализ количественных и качественных показателей технологического режима",
        ],
        correctAnswers: ["Промывка и продувка оборудования и трубопроводов"],
      },
      {
        code: "68719",
        text: "Что кроме мер безопасности должно быть предусмотрено программой наладочных работ при проведении наладки оборудования, работающего с применением опасных веществ или во взрывоопасных зонах?",
        answers: [
          "Должно быть предусмотрено предварительное опробование стадий технологического процесса на инертных средах с последующей наладкой на рабочих средах",
          "Должно быть предусмотрено предварительное опробование стадий технологического процесса на пониженных параметрах (температура, давление) с последующей наладкой на рабочих параметрах",
          "Должны быть предусмотрены предварительное опробование стадий технологического процесса на инертных средах, предварительная наладка на инертных средах и окончательная наладка на рабочих средах",
        ],
        correctAnswers: [
          "Должно быть предусмотрено предварительное опробование стадий технологического процесса на инертных средах с последующей наладкой на рабочих средах",
        ],
      },
      {
        code: "68720",
        text: "В течение какого времени проводится комплексное опробование котлов, сосудов и трубопроводов пара и горячей воды?",
        answers: [
          "Котлы - в течение 12 часов, трубопроводы тепловых сетей - в течение 72 часов, остальное оборудование - по программе комплексного опробования, разработанной организацией, проводящей соответствующие работы, и согласованной с эксплуатирующей организацией",
          "Котлы - в течение 48 часов, трубопроводы тепловых сетей - в течение 36 часов, остальное оборудование - по программе комплексного опробования, разработанной организацией, проводящей соответствующие работы, и согласованной с эксплуатирующей организацией",
          "Котлы - в течение 72 часов, трубопроводы тепловых сетей - в течение 24 часов, остальное оборудование - по программе комплексного опробования, разработанной организацией, проводящей соответствующие работы, и согласованной с эксплуатирующей организацией",
        ],
        correctAnswers: [
          "Котлы - в течение 72 часов, трубопроводы тепловых сетей - в течение 24 часов, остальное оборудование - по программе комплексного опробования, разработанной организацией, проводящей соответствующие работы, и согласованной с эксплуатирующей организацией",
        ],
      },
      {
        code: "68721",
        text: "По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) металлических сосудов (за исключением литых)? Где в формулах: Рраб – рабочее давление сосуда, Ррасч – расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа.",
        answers: [
          "Рпр = 1,25 Рраб ([σ]20 / [σ]t)",
          "Рпр = 0,75 Ррасч ([σ]20 / [σ]t)",
          "Рпр = 1,05 Рраб ([σ]20 / [σ]t)",
          "Рпр = 1,5 Рраб ([σ]20 / [σ]t)",
        ],
        correctAnswers: ["Рпр = 1,25 Рраб ([σ]20 / [σ]t)"],
      },
      {
        code: "68722",
        text: "По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) литых и кованых металлических сосудов? Где в формулах: Рраб – рабочее давление сосуда, Ррасч – расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа.",
        answers: [
          "Рпр = 1,5 Рраб ([σ]20 / [σ]t)",
          "Рпр = 0,5 Рраб ([σ]20 / [σ]t)",
          "Рпр = 1,75 Ррасч ([σ]20 / [σ]t)",
          "Рпр = 1,05 Рраб ([σ]20 / [σ]t)",
        ],
        correctAnswers: ["Рпр = 1,5 Рраб ([σ]20 / [σ]t)"],
      },
      {
        code: "68723",
        text: "По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) сосудов, изготовленных из неметаллических материалов с ударной вязкостью 20 Дж/см² и менее? Где в формулах: Рраб – рабочее давление сосуда, Ррасч – расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа.",
        answers: [
          "Рпр = 1,6 Рраб ([σ]20 / [σ]t)",
          "Рпр = 1,2 Ррасч ([σ]20 / [σ]t)",
          "Рпр = 0,6 Рраб ([σ]20 / [σ]t)",
          "Рпр = 1,25 Рраб ([σ]20 / [σ]t)",
        ],
        correctAnswers: ["Рпр = 1,6 Рраб ([σ]20 / [σ]t)"],
      },
      {
        code: "68724",
        text: "По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) сосудов, изготовленных из неметаллических материалов с ударной вязкостью более 20 Дж/см²? Где в формулах: Рраб – рабочее давление сосуда, Ррасч – расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа.",
        answers: [
          "Рпр = 1,3 Рраб ([σ]20 / [σ]t)",
          "Рпр = 0,5 Рраб ([σ]20 / [σ]t)",
          "Рпр = 1,5 Рраб ([σ]20 / [σ]t)",
          "Рпр = 1,25 Ррасч ([σ]20 / [σ]t)",
        ],
        correctAnswers: ["Рпр = 1,3 Рраб ([σ]20 / [σ]t)"],
      },
      {
        code: "68725",
        text: "По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) криогенных сосудов при наличии вакуума в изоляционном пространстве? Где в формулах: Рраб – рабочее давление сосуда, Ррасч – расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа.",
        answers: [
          "Рпр = 1,25 Рраб – 0,1",
          "Рпр = 1,25 Рраб + 0,1",
          "Рпр = 1,5 Рраб – 0,1",
          "Рпр = 1,05 Ррасч + 0,1",
        ],
        correctAnswers: ["Рпр = 1,25 Рраб – 0,1"],
      },
      {
        code: "68726",
        text: "По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) металлопластиковых сосудов, у которых ударная вязкость неметаллических материалов 20 Дж/см² и менее? Где в формулах: Рраб – рабочее давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа, Км - отношение массы металлоконструкции к общей массе сосуда.",
        answers: [
          "Рпр = [1,25 Км + 1,6 (1 - Км)] Рраб ([σ]20 / [σ]t)",
          "Рпр = [1,05 Км + 1,6 (1 - Км)] Рраб ([σ]20 / [σ]t)",
          "Рпр = [0,5 Км + 1,6 (1 - Км)] Рраб ([σ]20 / [σ]t)",
          "Рпр = [1,5 Км + 1,6 (1 - Км)] Рраб ([σ]20 / [σ]t)",
        ],
        correctAnswers: ["Рпр = [1,25 Км + 1,6 (1 - Км)] Рраб ([σ]20 / [σ]t)"],
      },
      {
        code: "68727",
        text: "По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) металлопластиковых сосудов, у которых ударная вязкость неметаллических материалов более 20 Дж/см²? Где в формулах: Рраб – рабочее давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа, Км - отношение массы металлоконструкции к общей массе сосуда.",
        answers: [
          "Рпр = [1,05 Км + 1,3 (1 - Км)] Рраб ([σ]20 / [σ]t)",
          "Рпр = [1,5 Км + 1,3 (1 - Км)] Рраб ([σ]20 / [σ]t)",
          "Рпр = [1,75 Км + 1,3 (1 - Км)] Рраб ([σ]20 / [σ]t)",
          "Рпр = [1,25 Км + 1,3 (1 - Км)] Рраб ([σ]20 / [σ]t)",
        ],
        correctAnswers: ["Рпр = [1,25 Км + 1,3 (1 - Км)] Рраб ([σ]20 / [σ]t)"],
      },
      {
        code: "68728",
        text: "Какое из приведенных требований должно выполняться при проведении гидравлического испытания сосудов?",
        answers: [
          "Гидравлическое испытание сосудов, устанавливаемых вертикально, разрешается проводить в горизонтальном положении, при этом должен быть выполнен расчет на прочность корпуса сосуда с учетом принятого способа опирания для проведения гидравлического испытания",
          "Порядок проведения гидравлического (пневматического) испытания сосудов должен быть установлен организацией, эксплуатирующей сосуд, и указан в паспорте сосуда",
          "Гидравлическому испытанию должна быть подвергнута каждая полость максимальным давлением в комбинированных сосудах с двумя и более рабочими полостями, рассчитанными на разные давления",
          "Минимальная величина пробного давления при гидравлическом испытании трубопроводов пара и горячей воды должна составлять 1,5 рабочего давления (указанного в паспорте организацией-изготовителем или по результатам первичного технического освидетельствования), но не менее 0,2 МПа",
        ],
        correctAnswers: [
          "Гидравлическое испытание сосудов, устанавливаемых вертикально, разрешается проводить в горизонтальном положении, при этом должен быть выполнен расчет на прочность корпуса сосуда с учетом принятого способа опирания для проведения гидравлического испытания",
        ],
      },
      {
        code: "68729",
        text: "Чему равна минимальная величина пробного давления при гидравлическом испытании трубопроводов пара и горячей воды?",
        answers: [
          "1,25 рабочего давления (указанного в паспорте организацией-изготовителем или по результатам первичного технического освидетельствования), но не менее 0,2 МПа",
          "1,25 расчетного давления (указанного в паспорте организацией-изготовителем или по результатам первичного технического освидетельствования), но не менее 0,2 МПа",
          "1,5 рабочего давления (указанного в паспорте организацией-изготовителем или по результатам первичного технического освидетельствования), но не менее 0,2 МПа",
          "0,75 рабочего давления (указанного в паспорте организацией-изготовителем или по результатам первичного технического освидетельствования), но не менее 0,2 МПа",
        ],
        correctAnswers: [
          "1,25 рабочего давления (указанного в паспорте организацией-изготовителем или по результатам первичного технического освидетельствования), но не менее 0,2 МПа",
        ],
      },
      {
        code: "68730",
        text: "Каково минимальное значение времени выдержки под пробным давлением сосуда, имеющего толщину стенки, не превышающую 50 мм (если отсутствуют другие указания в руководстве по эксплуатации)?",
        answers: ["10 минут", "30 минут", "60 минут", "20 минут"],
        correctAnswers: ["10 минут"],
      },
      {
        code: "68731",
        text: "Чему равно минимальное время выдержки трубопроводов пара и горячей воды под пробным давлением?",
        answers: ["10 минут", "15 минут", "60 минут", "30 минут"],
        correctAnswers: ["10 минут"],
      },
      {
        code: "68732",
        text: "Какое из приведенных требований должно выполняться при проведении гидравлического испытания трубопровода?",
        answers: [
          "Давление воды при гидравлическом испытании следует контролировать не менее чем двумя манометрами. Оба манометра выбирают одного типа, предела измерения, одинаковых классов точности (не ниже 1,5) и цены деления",
          "Для подъема давления в оборудовании, заполненном водой, применяют сжатый воздух или другой газ",
          "Для гидравлического испытания оборудования под давлением используют воду с температурой не ниже 0 °С и не выше 50 °С, если в технической документации организации - изготовителя оборудования не указано конкретное значение температуры",
          "При гидравлическом испытании паропроводов с рабочим давлением 10 МПа и более температура их стенок должна быть не менее 5 °С",
        ],
        correctAnswers: [
          "Давление воды при гидравлическом испытании следует контролировать не менее чем двумя манометрами. Оба манометра выбирают одного типа, предела измерения, одинаковых классов точности (не ниже 1,5) и цены деления",
        ],
      },
      {
        code: "68733",
        text: "Кем устанавливается периодичность отбора проб исходной, химочищенной, котловой, сетевой, питательной и подпиточной воды, конденсата и пара?",
        answers: [
          "Наладочной организацией",
          "Эксплуатирующей организацией",
          "Проектной организацией",
          "Контролирующей организацией",
        ],
        correctAnswers: ["Наладочной организацией"],
      },
      {
        code: "68734",
        text: "При каком условии допускается установка переключающего устройства перед мембранными предохранительными устройствами?",
        answers: [
          "Если установлено удвоенное число мембранных устройств с обеспечением при этом защиты сосуда от превышения давления при любом положении переключающего устройства",
          "Если установлено утроенное число мембранных устройств с обеспечением при этом защиты сосуда от превышения давления при любом положении переключающего устройства",
          "Установка переключающего устройства не допускается",
        ],
        correctAnswers: [
          "Если установлено удвоенное число мембранных устройств с обеспечением при этом защиты сосуда от превышения давления при любом положении переключающего устройства",
        ],
      },
      {
        code: "68735",
        text: "При каком минимальном избыточном давлении в сосуде допускается проведение ремонта сосуда и его элементов?",
        answers: [
          "Не допускается проведение ремонта сосудов и их элементов, находящихся под давлением",
          "При давлении 0,5 МПа",
          "При давлении 1,3 МПа",
          "При давлении 0,75 МПа",
        ],
        correctAnswers: [
          "Не допускается проведение ремонта сосудов и их элементов, находящихся под давлением",
        ],
      },
      {
        code: "68736",
        text: "Какое требование к отключению трубопровода до начала производства ремонтных работ указано неверно?",
        answers: [
          "Ключи от запирающих устройств должны храниться у ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением",
          "Прокладки между фланцами и заглушкой должны быть без хвостовиков",
          "Приводы закрытых задвижек, а также запорной арматуры открытых дренажей должны быть блокированы запирающим устройством так, чтобы исключалась возможность их открытия или закрытия",
          "До начала ремонтных работ на трубопроводе он должен быть отделен от всех других трубопроводов заглушками или отсоединен от действующего оборудования",
        ],
        correctAnswers: [
          "Ключи от запирающих устройств должны храниться у ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением",
        ],
      },
      {
        code: "68737",
        text: "По какому документу выполняются ремонт трубопроводов, арматуры и элементов дистанционного управления арматурой, установка и снятие заглушек, отделяющих ремонтируемый участок трубопровода?",
        answers: [
          "Все работы по наряду-допуску",
          "Все работы по разрешению",
          "Все работы по распоряжению",
          "Все работы по акту-допуску",
        ],
        correctAnswers: ["Все работы по наряду-допуску"],
      },
      {
        code: "68738",
        text: "Что необходимо предпринять после вскрытия барабана, коллектора или ремонта котла, если характер и объем ремонта не вызывают необходимости проведения внеочередного технического освидетельствования?",
        answers: [
          "Ответственный за исправное состояние и безопасную эксплуатацию котла должен провести гидравлическое испытание котла рабочим давлением",
          "Ответственный за исправное состояние и безопасную эксплуатацию котла должен провести гидравлическое испытание котла максимальным давлением",
          "Ответственный за исправное состояние и безопасную эксплуатацию котла должен провести гидравлическое испытание котла расчетным давлением",
          "Ответственный за исправное состояние и безопасную эксплуатацию котла должен провести осмотр оборудования и сделать соответствующую запись в наряде-допуске",
        ],
        correctAnswers: [
          "Ответственный за исправное состояние и безопасную эксплуатацию котла должен провести гидравлическое испытание котла рабочим давлением",
        ],
      },
      {
        code: "68739",
        text: "Исходя из какого условия выбирается количество питательных насосов при групповой схеме питания паровых котлов, работающих с высокотемпературными органическими и неорганическими теплоносителями?",
        answers: [
          "Чтобы в случае остановки самого мощного насоса суммарная подача оставшихся насосов была не менее 110 % номинальной паропроизводительности всех рабочих котлов",
          "Чтобы в случае остановки самого мощного насоса суммарная подача оставшихся насосов была не менее 50 % номинальной паропроизводительности всех рабочих котлов",
          "Чтобы в случае остановки самого мощного насоса суммарная подача оставшихся насосов была 100 % номинальной паропроизводительности всех рабочих котлов",
          "Чтобы в случае остановки самого мощного насоса суммарная подача оставшихся насосов была 80 % номинальной паропроизводительности всех рабочих котлов",
        ],
        correctAnswers: [
          "Чтобы в случае остановки самого мощного насоса суммарная подача оставшихся насосов была не менее 110 % номинальной паропроизводительности всех рабочих котлов",
        ],
      },
      {
        code: "68740",
        text: "При каком давлении проводится проверка на плотность вентиля баллона после ремонта, связанного с его разборкой?",
        answers: [
          "При рабочем давлении",
          "При максимальном давлении",
          "При расчетном давлении",
          "При предельно допустимом давлении",
        ],
        correctAnswers: ["При рабочем давлении"],
      },
      {
        code: "68741",
        text: "Каково минимальное значение температуры воды, используемой для гидравлического испытания трубопровода (если конкретное значение не указано в технической документации организации-изготовителя)?",
        answers: ["5 °C", "25 °C", "38 °C", "50 °C"],
        correctAnswers: ["5 °C"],
      },
      {
        code: "68742",
        text: "В каком из приведенных случаев при проведении гидравлического испытания при эксплуатации сосудов допускается использовать не воду, а другую жидкость?",
        answers: [
          "В технически обоснованных случаях, предусмотренных организацией-изготовителем",
          "Если эта жидкость не вызывает интенсивную коррозию сосуда",
          "Если эта жидкость не вступает во взаимодействие с материалом, из которого изготовлен сосуд",
          "Если эта жидкость не является токсичной",
        ],
        correctAnswers: [
          "В технически обоснованных случаях, предусмотренных организацией-изготовителем",
        ],
      },
      {
        code: "68743",
        text: "В каком из приведенных случаев трубопровод считается выдержавшим гидравлическое испытание?",
        answers: [
          "Во всех приведенных случаях трубопровод не считается выдержавшим гидравлическое испытание",
          "Если будут обнаружены трещины или признаки разрыва",
          "Если будет обнаружено падение давления по манометру",
          "Если будут обнаружены видимые остаточные деформации",
        ],
        correctAnswers: [
          "Во всех приведенных случаях трубопровод не считается выдержавшим гидравлическое испытание",
        ],
      },
      {
        code: "68744",
        text: "В каком из приведенных случаев сосуд считается выдержавшим гидравлическое испытание?",
        answers: [
          "Во всех приведенных случаях сосуд считается не выдержавшим гидравлическое испытание",
          "Если будут обнаружены течи в сварных, развальцованных соединениях",
          "Если будут обнаружены потения в заклепочных соединениях и в основном металле",
          "Если будут обнаружены течи в разъемных соединениях",
        ],
        correctAnswers: [
          "Во всех приведенных случаях сосуд считается не выдержавшим гидравлическое испытание",
        ],
      },
      {
        code: "68745",
        text: "При выполнении каких условий допускается заменять гидравлическое испытание сосуда пневматическим испытанием?",
        answers: [
          "Если пневматическое испытание одновременно контролируется методом акустической эмиссии",
          "Если проведение гидравлического испытания не представляется возможным",
          "Если давление в сосуде не превышает 0,03 МПа",
          "Не допускается заменять гидравлическое испытание сосуда пневматическим",
        ],
        correctAnswers: [
          "Если пневматическое испытание одновременно контролируется методом акустической эмиссии",
        ],
      },
      {
        code: "68746",
        text: "Чему равно минимальное значение времени выдержки сосуда под пробным давлением при пневматическом испытании?",
        answers: ["15 минут", "20 минут", "1 минута", "60 минут"],
        correctAnswers: ["15 минут"],
      },
      {
        code: "68747",
        text: "Кто принимает решение о вводе в эксплуатацию сосуда, работающего под давлением?",
        answers: [
          "Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения)",
          "Представитель Ростехнадзора",
          "Профсоюзная организация",
          "Организация-изготовитель",
        ],
        correctAnswers: [
          "Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения)",
        ],
      },
      {
        code: "68748",
        text: "В каком случае проверки готовности сосуда к пуску в работу и организации надзора за эксплуатацией сосуда проводятся ответственными лицами или комиссией с их участием?",
        answers: [
          "После монтажа без применения сварных соединений сосуда, поставленного на объект эксплуатации в собранном виде",
          "После монтажа с применением сварных соединений сосуда, поставленного на объект эксплуатации в собранном виде",
          "После монтажа без применения сварных соединений сосуда, поставленного на объект эксплуатации в разобранном виде",
          "После капитального ремонта сосуда",
        ],
        correctAnswers: [
          "После монтажа без применения сварных соединений сосуда, поставленного на объект эксплуатации в собранном виде",
        ],
      },
      {
        code: "68749",
        text: "Каким образом должны оформляться результаты проверок готовности котла к пуску в работу и организации надзора за его эксплуатацией?",
        answers: [
          "Актом готовности котла к вводу в эксплуатацию",
          "Фиксацией в отчетной документации (журналах, протоколах)",
          "Записью в паспорте котла",
          "Приказом, подписанным руководителем эксплуатирующей организации и руководителем специализированной пусконаладочной организации",
        ],
        correctAnswers: ["Актом готовности котла к вводу в эксплуатацию"],
      },
      {
        code: "68750",
        text: "На основании какого документа осуществляется пуск (включение) в работу и штатная остановка сосуда?",
        answers: [
          "На основании письменного распоряжения ответственного за исправное состояние и безопасную эксплуатацию сосуда",
          "На основании приказа руководителя эксплуатирующей организации",
          "На основании приказа, подписанного руководителем эксплуатирующей организации и руководителем специализированной пусконаладочной организации",
          "На основании устного распоряжения руководителя специализированной пусконаладочной организации",
        ],
        correctAnswers: [
          "На основании письменного распоряжения ответственного за исправное состояние и безопасную эксплуатацию сосуда",
        ],
      },
      {
        code: "68751",
        text: "Что из приведенного не указывается на табличке или не наносится на котле перед пуском его в работу?",
        answers: [
          "Регистрационный номер и даты проведенных осмотров и гидравлических испытаний",
          "Номер оборудования по системе нумерации, принятой в эксплуатирующей организации",
          "Дата наружного и внутреннего осмотров",
          "Дата истечения срока службы, установленного организацией-изготовителем",
        ],
        correctAnswers: [
          "Регистрационный номер и даты проведенных осмотров и гидравлических испытаний",
        ],
      },
      {
        code: "68752",
        text: "Каким образом должно осуществляться расхолаживание котлов после остановки при выводе их в ремонт?",
        answers: [
          "Режим расхолаживания котлов должен быть определен руководством (инструкцией) по эксплуатации",
          "Режим расхолаживания котлов осуществляется в соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением",
          "Режим расхолаживания котлов осуществляется в соответствии с проектной документацией",
          "Режим расхолаживания котлов осуществляется в соответствии с проектом производства работ",
        ],
        correctAnswers: [
          "Режим расхолаживания котлов должен быть определен руководством (инструкцией) по эксплуатации",
        ],
      },
      {
        code: "68753",
        text: "Какие из приведенных котлов не оборудуются установками для докотловой обработки воды?",
        answers: [
          "Паровые котлы с естественной циркуляцией паропроизводительностью менее 0,7 т/час",
          "Паровые котлы с естественной и многократной принудительной циркуляцией паропроизводительностью 0,7 т/ч и более",
          "Прямоточные паровые котлы независимо от паропроизводительности",
          "Водогрейные котлы",
        ],
        correctAnswers: [
          "Паровые котлы с естественной циркуляцией паропроизводительностью менее 0,7 т/час",
        ],
      },
      {
        code: "68754",
        text: "Какое требование к подпитке котлов сырой водой указано неверно?",
        answers: [
          "Когда проектом предусмотрена в аварийных ситуациях подпитка водогрейного котла сырой водой, котлы должны работать на сниженных температурных параметрах с температурой теплоносителя на выходе из котла не более 90 °С",
          "Подпитка сырой водой котлов, оборудованных устройствами для докотловой обработки воды, не допускается",
          "Во время нормальной эксплуатации запорные органы должны находиться в закрытом положении и быть опломбированы, а контрольный кран - открыт",
          "Каждый случай подпитки котлов сырой водой должен фиксироваться в журнале по водоподготовке (водно-химическому режиму) с указанием длительности подпитки и качества питательной воды в этот период",
        ],
        correctAnswers: [
          "Когда проектом предусмотрена в аварийных ситуациях подпитка водогрейного котла сырой водой, котлы должны работать на сниженных температурных параметрах с температурой теплоносителя на выходе из котла не более 90 °С",
        ],
      },
      {
        code: "68755",
        text: "Для каких трубопроводов эксплуатирующая организация обязана обеспечить проведение систематических наблюдений (контроля) за ростом остаточных деформаций?",
        answers: [
          "Паропровод из углеродистой стали, работающий при температуре пара свыше 420 °С",
          "Паропровод из легированной стали, работающий при температуре пара свыше 420 °С",
          "Паропровод из углеродистой стали, работающий при температуре пара не выше 400 °С",
          "Паропровод из легированной стали, работающий при температуре пара не выше 400 °С",
        ],
        correctAnswers: [
          "Паропровод из углеродистой стали, работающий при температуре пара свыше 420 °С",
        ],
      },
      {
        code: "68756",
        text: "Какое из приведенных требований к манометру указано неверно?",
        answers: [
          "Шкалу манометров выбирают из условия, чтобы при рабочем давлении стрелка манометра находилась либо в первой, либо во второй трети шкалы",
          "При эксплуатации трубопроводов с рабочим давлением более 14 МПа необходимо применять манометры классом точности не ниже 1",
          "При эксплуатации трубопроводов с рабочим давлением не более 2,5 МПа необходимо применять манометры с классом точности не ниже 2,5",
          "При эксплуатации трубопроводов с рабочим давлением от 2,5 до 14 МПа включительно необходимо применять манометры с классом точности не ниже 1,5",
        ],
        correctAnswers: [
          "Шкалу манометров выбирают из условия, чтобы при рабочем давлении стрелка манометра находилась либо в первой, либо во второй трети шкалы",
        ],
      },
      {
        code: "68757",
        text: "Какие функции обязано выполнить лицо, осуществляющее руководство сварочными работами, назначенное распорядительным документом организации или (и) должностной инструкцией которого предусмотрено руководство сварочными работами, перед выполнением сварочных работ?",
        answers: [
          "Только проверить и обеспечить соответствие численного состава и квалификации персонала сварочного производства, сборочного и сварочного оборудования, основных и сварочных материалов, применяемой технологии сварки требованиям ПТД",
          "Только ознакомить сварщиков с требованиями технологических карт сварки, а также с внесенными в них изменениями (при наличии), с подтверждением ознакомления подписями сварщиков в применяемых ими технологических картах сварки",
          "Только организовать проведение операционного контроля",
          "Все перечисленные функции",
        ],
        correctAnswers: ["Все перечисленные функции"],
      },
      {
        code: "68758",
        text: "Какая организация разрабатывает исполнительную схему (чертеж) трубопровода?",
        answers: [
          "Организация, производившая монтаж трубопровода",
          "Проектная организация",
          "Организация, эксплуатирующая трубопровод",
        ],
        correctAnswers: ["Организация, производившая монтаж трубопровода"],
      },
      {
        code: "68759",
        text: 'Какие требования предъявляются ФНП "Требования к производству сварочных работ на опасных производственных объектах" к сварочному оборудованию и сварочным материалам, применяемым при выполнении сварочных работ?',
        answers: [
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
          "Оборудование и материалы должны быть недорогими и простыми в использовании",
          "Оборудование и материалы должны быть российского производства",
        ],
        correctAnswers: [
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "68760",
        text: "Какая документация оформляется в процессе выполнения сварочных работ?",
        answers: [
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
          "Только журналы сварочных работ",
          "Только акты и заключения по неразрушающему контролю",
        ],
        correctAnswers: [
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "68761",
        text: "Кто допускается к выполнению сварочных работ на опасном производственном объекте?",
        answers: [
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
          "Сварщики и специалисты сварочного производства, имеющие стаж работы более 3 лет",
          "Сварщики и специалисты сварочного производства, имеющие группу по электробезопасности не ниже III",
        ],
        correctAnswers: [
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
        ],
      },
      {
        code: "68762",
        text: "Какие действия должны быть осуществлены при выполнении многопроходных швов после наложения каждого валика поверхности шва и кромки разделки?",
        answers: [
          "Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)",
          "Должен быть произведен дефектоскопический контроль",
          "Должен быть произведен радиографический контроль",
        ],
        correctAnswers: [
          "Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)",
        ],
      },
    ],
  },
  86: {
    709: [
      {
        code: "69128",
        text: "Какой допускается максимальный упругий прогиб фартука балюстрады под воздействием нагрузки 1500 H, приложенной между опорами фартука перпендикулярно к его поверхности на площади 25 см²?",
        answers: ["4 мм", "5 мм", "3 мм", "7 мм"],
        correctAnswers: ["4 мм"],
      },
      {
        code: "69129",
        text: "Для чего предназначен рабочий тормоз эскалатора?",
        answers: [
          "Для остановки лестничного полотна при каждом отключении главного или вспомогательного привода, а также при обесточивании цепи управления",
          "Для регулирования скорости движения лестничного полотна",
          "Для остановки лестничного полотна эскалатора при срабатывании блокировки скорости и самопроизвольном изменении направления движения лестничного полотна эскалатора, который работает на подъем",
          "Для остановки эскалатора, работающего на спуск, в случае увеличения скорости лестничного полотна на 40% номинальной скорости",
        ],
        correctAnswers: [
          "Для остановки лестничного полотна при каждом отключении главного или вспомогательного привода, а также при обесточивании цепи управления",
        ],
      },
      {
        code: "69130",
        text: "В каком случае допускается пуск эскалатора с пассажирами на полотне с пульта дистанционного управления?",
        answers: [
          "Если обеспечена возможность наблюдения за пассажирами на эскалаторе или обеспечена переговорная связь с персоналом, находящимся у эскалатора, с выдачей информации, предупреждающей пассажиров о пуске",
          "Ни в каком случае",
          "Если исключен пуск эскалатора в обратном направлении",
          "Если установлены несамовозвратные выключатели для экстренной остановки эскалатора",
        ],
        correctAnswers: [
          "Если обеспечена возможность наблюдения за пассажирами на эскалаторе или обеспечена переговорная связь с персоналом, находящимся у эскалатора, с выдачей информации, предупреждающей пассажиров о пуске",
        ],
      },
      {
        code: "69131",
        text: "В каком случае после срабатывания блокировочного устройства повторный пуск эскалатора в работу возможен без принудительного приведения блокировочного устройства в исходное положение?",
        answers: [
          "При срабатывании рабочего тормоза и блокировок входных площадок",
          "При сходе поручня с направляющих на нижнем криволинейном участке",
          "При сползании обода основного или вспомогательного бегунка",
          "При подъеме или опускании ступеней перед входными площадками",
        ],
        correctAnswers: [
          "При срабатывании рабочего тормоза и блокировок входных площадок",
        ],
      },
      {
        code: "69132",
        text: "Каким образом должны быть устроены входные вертикальные лестницы натяжных камер эскалатора?",
        answers: [
          "Должны быть шириной не менее 600 мм и иметь расстояние между ступенями не более 300 мм, шаг ступеней должен быть выдержан по всей высоте лестницы. Ступени вертикальной лестницы должны отстоять от стен и других строительных конструкций не менее чем на 150 мм",
          "Должны быть шириной не менее 500 мм и иметь расстояние между ступенями не более 250 мм, шаг ступеней должен быть выдержан по всей высоте лестницы",
          "Должны быть шириной не менее 400 мм и иметь расстояние между ступенями не более 350 мм, шаг ступеней должен быть выдержан по всей высоте лестницы",
          "Должны быть шириной не менее 300 мм и иметь расстояние между ступенями не более 150 мм, шаг ступеней должен быть выдержан по всей высоте лестницы. Ступени вертикальной лестницы должны отстоять от стен и других строительных конструкций не менее чем на 100 мм",
        ],
        correctAnswers: [
          "Должны быть шириной не менее 600 мм и иметь расстояние между ступенями не более 300 мм, шаг ступеней должен быть выдержан по всей высоте лестницы. Ступени вертикальной лестницы должны отстоять от стен и других строительных конструкций не менее чем на 150 мм",
        ],
      },
      {
        code: "69133",
        text: "Для каких целей предназначен главный привод эскалатора?",
        answers: [
          "Для передвижения лестничного полотна эскалатора с номинальной скоростью",
          "Для передвижения лестничного полотна эскалатора с ремонтной скоростью при монтажных и демонтажных работах",
          "Для растормаживания аварийного тормоза",
          "Для передвижения лестничного полотна эскалатора с ремонтной скоростью при проведении работ по техническому обслуживанию",
        ],
        correctAnswers: [
          "Для передвижения лестничного полотна эскалатора с номинальной скоростью",
        ],
      },
      {
        code: "69134",
        text: "Какая величина ускорения, независимо от пассажирской нагрузки, установлена для лестничного полотна эскалатора?",
        answers: [
          "Не более 0,75 м/с²",
          "Не более 0,85 м/с²",
          "Не более 0,95 м/с²",
          "Не более 0,80 м/с²",
        ],
        correctAnswers: ["Не более 0,75 м/с²"],
      },
      {
        code: "69135",
        text: "Начиная с какой высоты, на вертикальных лестницах натяжной камеры при высоте лестницы более 5 м должны быть установлены ограждения в виде дуг?",
        answers: [
          "С высоты 3,0 м",
          "С высоты 1,5 м",
          "С высоты 2,0 м",
          "С высоты 1,3 м",
        ],
        correctAnswers: ["С высоты 3,0 м"],
      },
      {
        code: "69136",
        text: "Какие требования к лестничному полотну эскалатора указаны неверно?",
        answers: [
          "Допускается применение пружин растяжения для натяжения цепей",
          "Для обеспечения постоянного натяжения лестничного полотна предусматривается натяжное устройство",
          "Лестничное полотно эскалатора должно состоять из ступеней, соединенных с помощью не менее чем 2 тяговых цепей, причем с каждой стороны ступени должно быть не менее 1 тяговой цепи",
        ],
        correctAnswers: [
          "Допускается применение пружин растяжения для натяжения цепей",
        ],
      },
      {
        code: "69137",
        text: "Какое из перечисленных требований к определению фактического запаса прочности тяговой цепи указано верно?",
        answers: [
          "Каждый отрезок цепи должен быть проверен на стенде под нагрузкой, превышающей в два раза наибольшее расчетное натяжение при нагрузке ",
          "Во время испытаний цепи необходимо доводить до разрушения при любой нагрузке",
          "При перемещении натяжного устройства в сторону привода или в обратном направлении на расстояние не более 50 мм эскалатор должен останавливаться",
          "После испытаний цепь должна иметь остаточные деформации или распрессовку деталей цепи",
        ],
        correctAnswers: [
          "Каждый отрезок цепи должен быть проверен на стенде под нагрузкой, превышающей в два раза наибольшее расчетное натяжение при нагрузке ",
        ],
      },
      {
        code: "69138",
        text: "Какой должна быть длина горизонтальных участков ступеней в зоне входных площадок при высоте транспортирования пассажиров не более 6 м и при номинальной скорости не более 0,5 м/с?",
        answers: [
          "Не менее 800 мм",
          "Не менее 500 мм",
          "Не менее 600 мм",
          "Не менее 700 мм",
        ],
        correctAnswers: ["Не менее 800 мм"],
      },
      {
        code: "69139",
        text: "Какую величину, независимо от пассажирской нагрузки, не должно превышать замедление лестничного полотна эскалатора при торможении рабочими тормозами при работе на спуск?",
        answers: ["0,60 м/с²", "1,00 м/с²", "0,75 м/с²", "0,90 м/с²"],
        correctAnswers: ["0,60 м/с²"],
      },
      {
        code: "69140",
        text: "Какая устанавливается ширина свободного прохода в машинном помещении с одной стороны для эскалаторов с высотой подъема до 15 м при установке трех эскалаторов?",
        answers: ["До 750 мм", "До 850 мм", "До 950 мм"],
        correctAnswers: ["До 750 мм"],
      },
      {
        code: "69141",
        text: "Каким должно быть расстояние по вертикали от уровня настила ступеней эскалатора до потолка галереи, тоннеля или выступающих частей (балок, архитектурных украшений, осветительной арматуры) по всей длине и ширине эскалатора по наружным кромкам поручня?",
        answers: [
          "Не менее 2300 мм",
          "Не менее 2100 мм",
          "Не менее 2000 мм",
          "Не менее 2150 мм",
        ],
        correctAnswers: ["Не менее 2300 мм"],
      },
      {
        code: "69142",
        text: "Как должны быть выполнены ступени по наклонной части эскалаторного тоннеля в проходах между эскалаторами, а также между крайним эскалатором и строительными конструкциями?",
        answers: [
          "Шириной не менее 350 мм и высотой не более 200 мм с постоянным углом наклона",
          "Шириной не менее 400 мм и высотой не более 1500 мм с постоянным углом наклона",
          "Шириной не менее 250 мм и высотой не более 250 мм с постоянным углом наклона",
          "Шириной не менее 300 мм и высотой не более 300 мм с постоянным углом наклона",
        ],
        correctAnswers: [
          "Шириной не менее 350 мм и высотой не более 200 мм с постоянным углом наклона",
        ],
      },
      {
        code: "69143",
        text: "Какая номинальная скорость движения установлена для лестничного полотна эскалатора?",
        answers: [
          "Не более 0,75 м/с",
          "Не более 1,0 м/с",
          "Не более 1,2 м/с",
          "Не более 0,9 м/с",
        ],
        correctAnswers: ["Не более 0,75 м/с"],
      },
      {
        code: "69144",
        text: "В каком случае в процессе монтажа эскалаторов допускаются отступления от требований конструкторской документации?",
        answers: [
          "При согласовании этих изменений с владельцем ОПО, а также организацией - изготовителем эскалатора или организацией - разработчиком данной документации, либо со специализированной по эскалаторам проектной организацией",
          "Только при согласовании этих изменений с организацией-изготовителем или организацией-разработчиком",
          "Только при согласовании этих изменений с владельцем ОПО",
        ],
        correctAnswers: [
          "При согласовании этих изменений с владельцем ОПО, а также организацией - изготовителем эскалатора или организацией - разработчиком данной документации, либо со специализированной по эскалаторам проектной организацией",
        ],
      },
      {
        code: "69145",
        text: "Для чего не предназначается дополнительный (аварийный) тормоз эскалатора?",
        answers: [
          "Для остановки лестничного полотна эскалатора при отключении электродвигателя",
          "Для остановки эскалатора при увеличении номинальной скорости движения лестничного полотна более чем в 1,2 раза",
          "Для остановки эскалатора при срабатывании блокировки превышения допустимых тормозных путей или расчетного времени рабочего торможения более чем на 20%",
          "Для остановки эскалатора при самопроизвольном изменении направления движения лестничного полотна работающего на подъем эскалатора",
        ],
        correctAnswers: [
          "Для остановки лестничного полотна эскалатора при отключении электродвигателя",
        ],
      },
      {
        code: "69146",
        text: 'На какие процессы в области промышленной безопасности на ОПО не распространяется действие Федеральных норм и правил "Правила безопасности эскалаторов в метрополитенах"?',
        answers: [
          "Связанные с перевозкой и хранением",
          "Связанные с проведением экспертизы промышленной безопасности",
          "Связанные с эксплуатацией, капитальным ремонтом, техническим перевооружением, консервацией и ликвидацией",
          "Связанные с изготовлением, монтажом, наладкой, обслуживанием и ремонтом эскалаторов",
        ],
        correctAnswers: ["Связанные с перевозкой и хранением"],
      },
      {
        code: "69147",
        text: "Какие требования к эскалаторам указаны неверно?",
        answers: [
          "Легкодоступные части эскалатора, находящиеся в движении, должны быть закрыты ограждениями, обеспечивающими безопасность обслуживающего персонала. Запрещается устанавливать съемные ограждения",
          "Составные части эскалатора должны быть защищены от коррозии в соответствии с условиями их эксплуатации, хранения и транспортирования в части воздействия климатических факторов внешней среды",
          "Неподвижные оси, служащие опорой для блоков, роликов и прочих вращающихся деталей, должны быть надежно укреплены и снабжены приспособлениями, обеспечивающими их фиксацию",
          "К механизмам, предохранительным устройствам, электрооборудованию, требующим обслуживания, должен быть обеспечен безопасный доступ",
        ],
        correctAnswers: [
          "Легкодоступные части эскалатора, находящиеся в движении, должны быть закрыты ограждениями, обеспечивающими безопасность обслуживающего персонала. Запрещается устанавливать съемные ограждения",
        ],
      },
      {
        code: "69148",
        text: "Какой минимальный коэффициент запаса прочности должен быть для тяговых и приводных цепей?",
        answers: ["7", "8", "9", "6", "5"],
        correctAnswers: ["7"],
      },
      {
        code: "69149",
        text: "Какая ремонтная скорость движения установлена для лестничного полотна эскалатора?",
        answers: [
          "Не более 0,1 м/с",
          "Не более 0,04 м/с",
          "Не более 0,08 м/с",
          "Не более 0,06 м/с",
        ],
        correctAnswers: ["Не более 0,04 м/с"],
      },
      {
        code: "69150",
        text: "В каком из приведенных случаев дополнительный (аварийный) тормоз должен останавливать эскалатор?",
        answers: [
          "Только в случае увеличения номинальной скорости движения лестничного полотна более чем в 1,2 раза",
          "Только в случае самопроизвольного изменения направления движения лестничного полотна работающего на подъем эскалатора",
          "Только в случае срабатывания блокировки превышения допустимых тормозных путей или расчетного времени рабочего торможения более чем на 20%",
          "В любом из приведенных случаев",
        ],
        correctAnswers: ["В любом из приведенных случаев"],
      },
      {
        code: "69151",
        text: "Что запрещается при обустройстве балюстрады?",
        answers: [
          "Устанавливать на фартуках планки и штапики, обращенные к лестничному полотну",
          "Выполнять конструкцию балюстрады в местах, требующих технического обслуживания, в легкоразборном исполнении",
          "Устанавливать решетки для громкоговорящей связи, осветителей и розеток",
          "Предусматривать дефлекторы в конструкции балюстрады, препятствующие затягиванию элементов одежды и обуви в зазор между ступенью и фартуком эскалатора",
        ],
        correctAnswers: [
          "Устанавливать на фартуках планки и штапики, обращенные к лестничному полотну",
        ],
      },
      {
        code: "69152",
        text: "Какое из перечисленных требований к балюстраде эскалатора указано неверно?",
        answers: [
          "Перепады плоскостей между элементами балюстрады (щиты, планки, штапики) со стороны лестничного полотна допускаются более 3 мм, если плоскости балюстрады выполнены вертикальными или расширенными кверху",
          "Зазор в стыках щитов и фартуков балюстрады должен быть не более 4 мм",
          "Внутренние плоскости балюстрады должны быть вертикальными или расширенными кверху",
          "Расстояние между верхними кромками боковых щитов балюстрады не должно превышать ширину ступени более чем на 200 мм",
        ],
        correctAnswers: [
          "Перепады плоскостей между элементами балюстрады (щиты, планки, штапики) со стороны лестничного полотна допускаются более 3 мм, если плоскости балюстрады выполнены вертикальными или расширенными кверху",
        ],
      },
      {
        code: "69153",
        text: "В каком из перечисленных случаев допускается одновременная работа главного и вспомогательного приводов?",
        answers: [
          "Одновременная работа главного и вспомогательного привода не допускается ни в каком случае",
          "Только в случае снижения мощности главного привода",
          "Только в случае обкатки эскалатора",
          "Только в случае перегрузки эскалатора",
        ],
        correctAnswers: [
          "Одновременная работа главного и вспомогательного привода не допускается ни в каком случае",
        ],
      },
      {
        code: "69154",
        text: "Какие требования предъявляются к входным площадкам эскалатора?",
        answers: [
          "Конструкция входной площадки должна иметь устройство, обеспечивающее правильное направление настила ступени относительно зубьев гребенки",
          "Освещенность входных площадок на уровне пола должна быть не менее 30 лк",
          "Освещенность у входных площадок, за исключением гребенок, должна соответствовать освещенности, создаваемой освещением в этой зоне",
          "Поверхность входных площадок должна быть горизонтальной и ровной",
        ],
        correctAnswers: [
          "Конструкция входной площадки должна иметь устройство, обеспечивающее правильное направление настила ступени относительно зубьев гребенки",
        ],
      },
      {
        code: "69155",
        text: "Сколько должен составлять перепад по высоте двух смежных ступеней на горизонтальном участке эскалатора?",
        answers: [
          "Не должен превышать 4 мм",
          "Не должен превышать 5 мм",
          "Не должен превышать 6 мм",
          "Не должен превышать 7 мм",
        ],
        correctAnswers: ["Не должен превышать 4 мм"],
      },
      {
        code: "69156",
        text: "Какая величина угла наклона установлена для эскалатора?",
        answers: [
          "Не более 30°",
          "Не более 38°",
          "Не более 35°",
          "Не более 45°",
        ],
        correctAnswers: ["Не более 30°"],
      },
      {
        code: "69157",
        text: "Каким образом в процессе эксплуатации эскалаторов должно определяться их соответствие требованиям промышленной безопасности?",
        answers: [
          "Проведением экспертизы промышленной безопасности",
          "Проведением декларирования",
          "Проведением сертификации",
        ],
        correctAnswers: ["Проведением экспертизы промышленной безопасности"],
      },
      {
        code: "69158",
        text: "Какая величина замедления установлена для лестничного полотна эскалатора, загруженного максимальной эксплуатационной нагрузкой, при нарушении кинематической связи между приводом и главным валом и торможении аварийным тормозом?",
        answers: [
          "Не более 2 м/с²",
          "Не более 2,5 м/с²",
          "Не более 1,5 м/с²",
          "Не более 1 м/с²",
        ],
        correctAnswers: ["Не более 2 м/с²"],
      },
      {
        code: "69159",
        text: "Допускаются ли при обкатке вновь установленного эскалатора остановки?",
        answers: [
          "Допускаются остановки для наладки и регулирования общей продолжительностью не более 90 минут",
          "Допускаются остановки для наладки и регулирования общей продолжительностью не более 120 минут",
          "Допускаются остановки для наладки и регулирования общей продолжительностью не более 180 минут",
          "Не допускаются",
        ],
        correctAnswers: [
          "Допускаются остановки для наладки и регулирования общей продолжительностью не более 90 минут",
        ],
      },
      {
        code: "69160",
        text: "Сколько должна составлять скорость движения поручневого устройства эскалатора?",
        answers: [
          "Не должна отличаться от скорости движения лестничного полотна более чем на 2%",
          "Не должна отличаться от скорости движения лестничного полотна более чем на 3%",
          "Не должна отличаться от скорости движения лестничного полотна более чем на 4%",
          "Не должна отличаться от скорости движения лестничного полотна более чем на 5%",
        ],
        correctAnswers: [
          "Не должна отличаться от скорости движения лестничного полотна более чем на 2%",
        ],
      },
      {
        code: "69161",
        text: "При каком торможении на спуск с максимальной нагрузкой qм.э. на пути (в метрах) каждый из тормозов должен останавливать лестничное полотно при применении двух и более рабочих тормозов?",
        answers: ["Не более 6V", "Не более 7V", "Не более 8V", "Не более 5V"],
        correctAnswers: ["Не более 6V"],
      },
      {
        code: "69162",
        text: "Какой должна быть максимальная стрела упругого прогиба под действием нагрузки  для пролетных металлоконструкций, включая плиты входных площадок, эскалаторов?",
        answers: [
          "1/750 пролета",
          "1/1000 пролета",
          "1/1500 пролета",
          "1/2000 пролета",
        ],
        correctAnswers: ["1/750 пролета"],
      },
      {
        code: "69163",
        text: "Каким должно быть напряжение цепей штепсельных розеток для питания переносных ламп?",
        answers: [
          "Не более 50В",
          "Не более 110В",
          "Не более 123В",
          "Не более 220В",
        ],
        correctAnswers: ["Не более 50В"],
      },
      {
        code: "69164",
        text: "В каком случае допускается осуществлять электропитание дополнительного (аварийного) тормоза (тормозов) от одного источника?",
        answers: [
          "При использовании нормального замкнутого тормоза",
          "При установке тормоза на главном валу",
          "В случаях, определенных инструкцией по эксплуатации",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["При использовании нормального замкнутого тормоза"],
      },
      {
        code: "69165",
        text: "Какой должна быть длина горизонтальных участков ступеней в зоне входных площадок при высоте транспортирования пассажиров более 6 м и при номинальной скорости более 0,5 м/с (но не более 0,65 м/с для эскалаторов)?",
        answers: [
          "Не менее 1200 мм",
          "Не менее 1000 мм",
          "Не менее 800 мм",
          "Не менее 600 мм",
        ],
        correctAnswers: ["Не менее 1200 мм"],
      },
      {
        code: "69166",
        text: "Какой привод эскалатора не может использоваться для обеспечения ремонтной скорости при выполнении монтажных и демонтажных работ и техническом обслуживании эскалатора?",
        answers: [
          "Ремонтный привод",
          "Главный привод",
          "Отдельный вспомогательный привод",
        ],
        correctAnswers: ["Ремонтный привод"],
      },
      {
        code: "69167",
        text: "Какие из перечисленных конструкций относятся к ответственным (расчетным) сварным конструкциям?",
        answers: [
          "Только каркас ступени",
          "Только входная площадка",
          "Только рычаги и рамы тормозов",
          "Только конструкции подъемно-транспортного оборудования машинного помещения",
          "Все перечисленные конструкции",
        ],
        correctAnswers: ["Все перечисленные конструкции"],
      },
      {
        code: "69168",
        text: "С кем владелец ОПО, на котором используется эскалатор в метрополитенах, должен согласовать изменения, вносимые при изготовлении, модернизации или реконструкции эскалатора в его конструкцию?",
        answers: [
          "С организацией-разработчиком или специализированной проектной организацией",
          "С организацией-изготовителем",
          "С монтажно-наладочной организацией",
          "Со всеми перечисленными организациями",
        ],
        correctAnswers: [
          "С организацией-разработчиком или специализированной проектной организацией",
        ],
      },
      {
        code: "69169",
        text: "В каком случае допускается применение посадок с гарантированным натягом без дополнительного крепления в составных частях привода эскалатора, передающих крутящий момент?",
        answers: [
          "Если это соответствует требованиям проектной (конструкторской) документации",
          "Если обеспечен контроль выполнения технологических операций",
          "Если это соответствует требованиям Правил безопасности эскалаторов в метрополитенах",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "69170",
        text: "От какого количества независимых источников питания должно осуществляться электроснабжение комплекса (блока) эскалаторов?",
        answers: [
          "Не менее чем от двух",
          "Не менее чем от трех",
          "Не менее чем от четырех",
          "Не нормируется",
        ],
        correctAnswers: ["Не менее чем от двух"],
      },
      {
        code: "69171",
        text: "Какой должен быть расчетный запас прочности тяговой цепи эскалатора?",
        answers: ["Не менее 9", "Не менее 8", "Не менее 7", "Не менее 6"],
        correctAnswers: ["Не менее 7"],
      },
      {
        code: "69172",
        text: "Какой должна быть освещенность входных площадок эскалатора на уровне пола, измеренная по оси лестничного полотна?",
        answers: [
          "Не менее 50 лк",
          "Не менее 45 лк",
          "Не менее 40 лк",
          "Не менее 35 лк",
        ],
        correctAnswers: ["Не менее 50 лк"],
      },
      {
        code: "69173",
        text: "Какой допускается зазор в стыках щитов и фартуков балюстрады эскалатора?",
        answers: [
          "Не более 4 мм",
          "Не более 5 мм",
          "Не более 6 мм",
          "Не более 7 мм",
        ],
        correctAnswers: ["Не более 4 мм"],
      },
      {
        code: "69174",
        text: "В каком случае на балюстраде эскалатора разрешается установка решеток для громкоговорящей связи, осветителей и розеток?",
        answers: [
          "В случае согласования с разработчиком эскалатора",
          "Установка таких элементов не допускается",
          "Установка таких элементов допускается без согласования с разработчиком эскаватора",
          "Установка таких элементов не нормируется",
        ],
        correctAnswers: ["В случае согласования с разработчиком эскалатора"],
      },
      {
        code: "69175",
        text: "В каком случае допускается пуск эскалатора с пассажирами с любого пульта управления в обратном направлении?",
        answers: [
          "При срабатывании рабочего или дополнительного (аварийного) тормоза",
          "При нерасторможенном рабочем или дополнительном (аварийном) тормозе",
          "Не допускается ни в каком случае",
          "При защемлении инородных предметов в месте входа поручня в устье",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "69176",
        text: "Какое из перечисленных эскалаторных помещений может не предусматриваться?",
        answers: [
          "Машинное помещение",
          "Бытовое помещение",
          "Вспомогательное помещение",
          "Гардеробное помещение",
        ],
        correctAnswers: ["Гардеробное помещение"],
      },
      {
        code: "69177",
        text: "Какую величину составляет минимальное расстояние от холостой ветви поручня эскалатора до вспомогательных коммуникаций, не относящихся к эскалаторам?",
        answers: ["500 мм", "300 мм", "200 мм", "150 мм"],
        correctAnswers: ["200 мм"],
      },
      {
        code: "69178",
        text: "Какая устанавливается минимальная ширина свободного прохода между фундаментами или выступающими частями привода эскалатора и стенами машинного помещения (или оборудованием и коммуникациями, размещенными на стенах машинного помещения), торцевой стеной натяжной камеры (или оборудованием и коммуникациями, размещенными на стенах натяжной камеры)?",
        answers: ["900 мм", "800 мм", "700 мм", "500 мм"],
        correctAnswers: ["900 мм"],
      },
      {
        code: "69179",
        text: "С какой скоростью должны двигаться, а затем ускоряться эскалаторы, работающие в режиме ожидания, при пересечении пассажиром гребенки?",
        answers: [
          "Двигаться со скоростью не менее 20 % номинальной скорости, ускоряться до номинальной скорости с ускорением не более 0,5 м/с²",
          "Двигаться со скоростью не менее 15 % номинальной скорости, ускоряться до номинальной скорости с ускорением не более 0,5 м/с²",
          "Двигаться со скоростью не менее 20 % номинальной скорости, ускоряться до номинальной скорости с ускорением не более 0,7 м/с²",
          "Двигаться со скоростью не менее 15 % номинальной скорости, ускоряться до номинальной скорости с ускорением не более 0,6 м/с²",
        ],
        correctAnswers: [
          "Двигаться со скоростью не менее 20 % номинальной скорости, ускоряться до номинальной скорости с ускорением не более 0,5 м/с²",
        ],
      },
      {
        code: "69180",
        text: "В каком из перечисленных документов должен содержаться перечень неисправностей эскалатора, а также других причин, при которых эксплуатация эскалатора запрещается? Выберите 2 варианта ответа.",
        answers: [
          "В руководстве по эксплуатации эскалатора",
          "В перечне критических отказов эскалатора",
          "В перечне быстро изнашиваемых элементов эскалатора",
          "В производственной инструкции машиниста эскалатора",
        ],
        correctAnswers: [
          "В руководстве по эксплуатации эскалатора",
          "В производственной инструкции машиниста эскалатора",
        ],
      },
      {
        code: "69181",
        text: "Какие эскалаторы тяжелого режима работы должны использоваться в метрополитенах?",
        answers: [
          "С номинальной скоростью не более 0,75 м/с, шириной лестничного полотна 1000 ± 10 мм и углом наклона не более 30°",
          "С номинальной скоростью не более 0,65 м/с, шириной лестничного полотна 1000 ± 10 мм и углом наклона не более 30°",
          "С номинальной скоростью не более 0,75 м/с, шириной лестничного полотна 1000 ± 10 мм и углом наклона не более 40°",
          "С номинальной скоростью не более 0,75 м/с, шириной лестничного полотна 800 ± 10 мм и углом наклона не более 30°",
        ],
        correctAnswers: [
          "С номинальной скоростью не более 0,75 м/с, шириной лестничного полотна 1000 ± 10 мм и углом наклона не более 30°",
        ],
      },
      {
        code: "69182",
        text: "Что должен включать комплект документации на каждый изготовленный эскалатор?",
        answers: [
          "Ведомость комплекта запасных изделий для пусконаладочных работ",
          "Должностную инструкцию работника, ответственного за содержание эскалатора в исправном состоянии и его безопасную эксплуатацию",
          "Рекомендуемую штатную расстановку обслуживающего персонала",
          "Инструкцию по действию персонала в случае пожара",
        ],
        correctAnswers: [
          "Ведомость комплекта запасных изделий для пусконаладочных работ",
        ],
      },
      {
        code: "69183",
        text: "Какие данные не указываются в паспорте эскалатора?",
        answers: [
          "Квалификация представителя организации, выполнившей монтаж",
          "Характеристика поручней",
          "Характеристика тормозов",
          "Род электрического тока и напряжение",
        ],
        correctAnswers: [
          "Квалификация представителя организации, выполнившей монтаж",
        ],
      },
      {
        code: "69184",
        text: "Какой документ составляется по результатам работы комиссии?",
        answers: [
          "Акт о возможности ввода в эксплуатацию эскалатора",
          "Акт технической готовности",
          "Акт приемо-сдаточных испытаний",
          "Свидетельство об обкатке",
        ],
        correctAnswers: ["Акт о возможности ввода в эксплуатацию эскалатора"],
      },
      {
        code: "69185",
        text: "В какие сроки должно проводиться периодическое техническое освидетельствование эскалатора после ввода его в эксплуатацию?",
        answers: [
          "Не реже одного раза в 12 месяцев",
          "Не реже одного раза в 2 года",
          "Не реже одного раза в 3 года",
          "Не реже одного раза в 5 лет",
        ],
        correctAnswers: ["Не реже одного раза в 12 месяцев"],
      },
      {
        code: "69186",
        text: "Какие нормативные документы регламентируют порядок и объем работ периодического технического освидетельствования эскалаторов в метрополитенах?",
        answers: [
          'Руководство по эксплуатации эскалатора и Федеральные нормы и правила в области промышленной безопасности "Правила безопасности эскалаторов в метрополитенах"',
          'Федеральные нормы и правила в области промышленной безопасности "Правила безопасности эскалаторов в метрополитенах" и технический регламент "О безопасности машин и оборудования"',
          "Руководство (инструкция) по монтажу, пуску, регулированию и обкатке и обоснование безопасности и руководство (инструкция) по ремонту",
          'Технический регламент "О безопасности машин и оборудования" и руководство (инструкция) по монтажу, пуску, регулированию',
        ],
        correctAnswers: [
          'Руководство по эксплуатации эскалатора и Федеральные нормы и правила в области промышленной безопасности "Правила безопасности эскалаторов в метрополитенах"',
        ],
      },
      {
        code: "69187",
        text: "Какое положение по эксплуатации эскалатора не соответствует требованиям Правил безопасности эскалаторов в метрополитенах?",
        answers: [
          "Основанием для остановки на капитальный ремонт эскалатора является только фактическое техническое состояние эскалатора",
          "Ввод эскалатора в эксплуатацию после капитального ремонта, а также по окончании срока работы, установленного предыдущим техническим освидетельствованием, осуществляет владелец ОПО при наличии квалифицированного персонала или специализированная организация после осмотра и проверки в объеме технического освидетельствования",
          "Техническое обслуживание должно включать в себя осмотр, смазку, замер износа, очистку, регулировку и замену узлов и деталей (по результатам осмотра и замеров)",
          "Техническое обслуживание проводится в сроки, устанавливаемые в руководстве по эксплуатации или в инструкции по техническому обслуживанию",
        ],
        correctAnswers: [
          "Основанием для остановки на капитальный ремонт эскалатора является только фактическое техническое состояние эскалатора",
        ],
      },
      {
        code: "69188",
        text: "Кто принимает решение о вводе в эксплуатацию эскалатора метрополитена?",
        answers: [
          "Лицо, ответственное за осуществление контроля за соблюдением требований промышленной безопасности при эксплуатации опасного производственного объекта",
          "Лицо, ответственное за содержание эскалатора в исправном состоянии",
          "Лицо, ответственное за безопасную эксплуатацию эскалатора",
        ],
        correctAnswers: [
          "Лицо, ответственное за осуществление контроля за соблюдением требований промышленной безопасности при эксплуатации опасного производственного объекта",
        ],
      },
      {
        code: "69189",
        text: "В соответствии с каким документом должно проводиться техническое обслуживание и ремонт эскалатора?",
        answers: [
          "В соответствии с руководством по эксплуатации эскалатора организации-изготовителя или специализированной организации",
          "В соответствии с инструкцией по охране труда",
          "В соответствии с рекомендациями организации, выполнившей монтаж эскалатора",
          "В соответствии с рекомендациями специализированной организации, проводившей экспертизу промышленной безопасности",
        ],
        correctAnswers: [
          "В соответствии с руководством по эксплуатации эскалатора организации-изготовителя или специализированной организации",
        ],
      },
      {
        code: "69190",
        text: "В каких целях не проводится полное техническое освидетельствование эскалатора после изготовления (монтажа), реконструкции, модернизации?",
        answers: [
          'В целях подтверждения соответствия эскалатора и его устройства требованиям технического регламента "О безопасности машин и оборудования"',
          "В целях подтверждения соответствия эксплуатации, содержания и обслуживания эскалатора требованиям Правил безопасности эскалаторов в метрополитенах",
          "В целях подтверждения технического состояния эскалатора, обеспечивающего его безопасное использование по назначению",
          "В целях подтверждения соответствия эскалатора и его устройства требованиям Правил безопасности эскалаторов в метрополитенах и паспортным данным",
        ],
        correctAnswers: [
          'В целях подтверждения соответствия эскалатора и его устройства требованиям технического регламента "О безопасности машин и оборудования"',
        ],
      },
      {
        code: "69191",
        text: "В каком случае эскалатор метрополитена не подлежит экспертизе промышленной безопасности, если техническим регламентом не установлена иная форма оценки соответствия эскалатора?",
        answers: [
          "При отсутствии в технической документации данных о сроке службы эскалатора, если фактический срок его службы составляет 10 лет",
          "После проведения работ, связанных с изменением конструкции, заменой материала несущих элементов эскалатора, либо восстановительного ремонта после аварии на ОПО, в результате которых был поврежден эскалатор",
          "По истечении срока службы или при превышении количества циклов нагрузки эскалатора, установленных организацией - изготовителем",
          "До начала применения на опасном производственном объекте (ОПО)",
        ],
        correctAnswers: [
          "При отсутствии в технической документации данных о сроке службы эскалатора, если фактический срок его службы составляет 10 лет",
        ],
      },
      {
        code: "69192",
        text: "Какие сведения не содержат эксплуатационные документы эскалатора метрополитена?",
        answers: [
          "Программу специальной подготовки персонала",
          "Указания по монтажу или сборке, наладке или регулировке",
          "Действия персонала по ликвидации последствий инцидента или аварии",
          "Возможные ошибочные действия (бездействие) персонала, приводящие к инциденту или аварии",
          "Указания по выводу из эксплуатации перед утилизацией",
        ],
        correctAnswers: ["Программу специальной подготовки персонала"],
      },
      {
        code: "69193",
        text: "Кто не входит в состав комиссии, принимающей решение о возможности ввода эскалатора в эксплуатацию?",
        answers: [
          "Уполномоченный представитель страховой организации",
          "Уполномоченный представитель организации, выполнившей пусконаладочные работы",
          "Уполномоченный представитель организации - изготовителя эскалатора",
          "Уполномоченный представитель эксплуатирующей организации",
          "Уполномоченный представитель организации, выполнившей монтаж эскалатора",
        ],
        correctAnswers: ["Уполномоченный представитель страховой организации"],
      },
      {
        code: "69194",
        text: "В каком случае допускается эксплуатация эскалатора метрополитена?",
        answers: [
          "При отсутствии трещин в металлоконструкции и элементах оборудования",
          "При истекшем сроке технического освидетельствования",
          "При условиях окружающей среды, не соответствующих требованиям эксплуатационной документации",
          "При отсутствии персонала, прошедшего проверку знаний в установленном порядке",
        ],
        correctAnswers: [
          "При отсутствии трещин в металлоконструкции и элементах оборудования",
        ],
      },
      {
        code: "69195",
        text: "Что не относится к основным техническим данным и характеристикам, указываемым в паспорте эскалатора?",
        answers: [
          "Характеристика помещений для установки эскалатора",
          "Скорость движения лестничного полотна, м/с",
          "Тип передачи к главному валу эскалатора",
          "Фактическая производительность человеко-час",
        ],
        correctAnswers: ["Характеристика помещений для установки эскалатора"],
      },
      {
        code: "69196",
        text: "В каком объеме должны проводиться осмотр и проверка эскалатора при вводе его в эксплуатацию после капитального ремонта?",
        answers: [
          "Технического освидетельствования",
          "Планового технического осмотра",
          "Экспертизы промышленной безопасности",
        ],
        correctAnswers: ["Технического освидетельствования"],
      },
      {
        code: "69197",
        text: "Где должна быть сделана запись о выполнении ежедневного контроля за состоянием эскалатора?",
        answers: [
          "В журнале суточного учета работы эскалаторов",
          "В паспорте эскалатора",
          "В акте технической готовности эскалатора",
          "В сменном журнале",
        ],
        correctAnswers: ["В журнале суточного учета работы эскалаторов"],
      },
      {
        code: "69198",
        text: "Каким образом должна проводиться обкатка каждого вновь установленного эскалатора?",
        answers: [
          "На месте применения в течение 48 часов непрерывной работы от главного привода, по 24 часа в каждом направлении от постоянных источников питания",
          "На месте применения в течение 24 часов непрерывной работы от главного привода, по 12 часов в каждом направлении от постоянных источников питания",
          "На месте применения в течение 12 часов непрерывной работы от главного привода, по 6 часов в каждом направлении от постоянных источников питания",
          "На месте применения в течение 24 часов непрерывной работы от главного привода, по 24 часа в каждом направлении от постоянных источников питания",
        ],
        correctAnswers: [
          "На месте применения в течение 48 часов непрерывной работы от главного привода, по 24 часа в каждом направлении от постоянных источников питания",
        ],
      },
      {
        code: "69199",
        text: "Каким образом не может быть установлен объем текущего, среднего и капитального ремонта?",
        answers: [
          "В порядке нерегламентированного технического обслуживания",
          "В порядке экспертизы промышленной безопасности",
          "В порядке технического освидетельствования",
          "В порядке планового технического осмотра",
        ],
        correctAnswers: [
          "В порядке нерегламентированного технического обслуживания",
        ],
      },
      {
        code: "69200",
        text: "В соответствии с какими нормативными документами должна осуществляться эксплуатация эскалатора?",
        answers: [
          "В соответствии с Правилами безопасности эскалаторов в метрополитенах и эксплуатационной документацией",
          "Только в соответствии с эксплуатационной документацией",
          "В соответствии с проектной документацией",
          "Только в соответствии с Правилами безопасности эскалаторов в метрополитенах",
        ],
        correctAnswers: [
          "В соответствии с Правилами безопасности эскалаторов в метрополитенах и эксплуатационной документацией",
        ],
      },
      {
        code: "69201",
        text: "Какие требования не должны устанавливаться в эксплуатационных документах эскалатора метрополитена?",
        answers: [
          "Требования к изготовлению",
          "Требования к утилизации",
          "Требования к условиям перевозки",
          "Требования к условиям хранения",
        ],
        correctAnswers: ["Требования к изготовлению"],
      },
      {
        code: "69202",
        text: "Каким требованиям должен соответствовать персонал, допущенный к управлению эскалатором в метрополитене?",
        answers: [
          "Персонал должен быть квалифицированным, достигшим возраста 18 лет, не имеющим медицинских противопоказаний и прошедшим проверку знаний",
          "Персонал должен уметь оказывать медицинскую помощь пострадавшим",
          "Персонал должен быть не моложе 17 лет",
          "Персонал должен иметь высшее образование",
        ],
        correctAnswers: [
          "Персонал должен быть квалифицированным, достигшим возраста 18 лет, не имеющим медицинских противопоказаний и прошедшим проверку знаний",
        ],
      },
      {
        code: "69203",
        text: "Какой организацией определяется численность службы производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО, на котором используется эскалатор, и ее структура?",
        answers: [
          "Контролирующей организацией",
          "Эксплуатирующей организацией",
          "Специализированной организацией",
          "Проектной организацией",
        ],
        correctAnswers: ["Эксплуатирующей организацией"],
      },
      {
        code: "69204",
        text: "Сколько должна составлять норма межремонтного пробега для капитального ремонта, если она не установлена изготовителем эскалатора?",
        answers: [
          "Не более 150 000 км",
          "Не более 175 000 км",
          "Не более 160 000 км",
          "Не более 165 000 км",
        ],
        correctAnswers: ["Не более 150 000 км"],
      },
      {
        code: "69205",
        text: "Когда машинисту (дежурному у эскалатора) необходимо проводить контроль за состоянием эскалатора?",
        answers: [
          "Ежедневно перед началом работы",
          "2 раза в неделю",
          "1 раз в 3 дня",
          "1 раз в неделю",
        ],
        correctAnswers: ["Ежедневно перед началом работы"],
      },
      {
        code: "69206",
        text: "Какое требование к управлению эскалатором указано неверно?",
        answers: [
          "Устанавливать пульт управления эскалатором в отдельно вынесенное помещение допускается только для эскалаторов с высотой подъема от 6 м, при этом должны быть предусмотрены видеокамеры по наклонному ходу, на верхних и нижних площадках, с интеграцией изображений на мониторы в данное помещение",
          "Управление эскалатором, оборудованным системой дистанционного управления, должен осуществлять дежурный оператор у эскалатора с пульта, установленного в кабине персонала, при обеспечении постоянного визуального наблюдения за пассажирами на лестничном полотне",
          "В зоне нижней площадки схода эскалатора необходимо обеспечивать постоянное наблюдение за пассажирами, находящимися на лестничном полотне",
          "Работник, осуществляющий наблюдение за пассажирами на эскалаторе, обязан останавливать эскалатор в случае падения пассажира или возникновения опасности нанесения травм пассажирам",
        ],
        correctAnswers: [
          "Устанавливать пульт управления эскалатором в отдельно вынесенное помещение допускается только для эскалаторов с высотой подъема от 6 м, при этом должны быть предусмотрены видеокамеры по наклонному ходу, на верхних и нижних площадках, с интеграцией изображений на мониторы в данное помещение",
        ],
      },
      {
        code: "69207",
        text: "Какой документ составляется по окончании устранения неисправностей, выявленных в ходе осмотра, проверок и испытаний эскалаторов?",
        answers: [
          "Акт технической готовности",
          "Декларация пожарной безопасности",
          "Акт выполненных работ",
          "Свидетельство об обкатке",
        ],
        correctAnswers: ["Акт технической готовности"],
      },
      {
        code: "69208",
        text: "Кто осуществляет пуск эскалатора в работу?",
        answers: [
          "Машинист эскалатора или работник, имеющий право управления эскалатором в соответствии с производственной инструкцией",
          "Работник, ответственный за осуществление производственного контроля в эксплуатирующей организации",
          "Работник, ответственный за содержание эскалаторов в исправном состоянии",
          "Дежурный оператор",
        ],
        correctAnswers: [
          "Машинист эскалатора или работник, имеющий право управления эскалатором в соответствии с производственной инструкцией",
        ],
      },
      {
        code: "69209",
        text: "Какая организация обязана обеспечить содержание эскалатора в исправном состоянии путем организации обслуживания, ремонта, технического освидетельствования?",
        answers: [
          "Организация, эксплуатирующая эскалатор, самостоятельно либо с привлечением специализированной организации",
          "Завод-изготовитель",
          "Проектная организация",
          "Организация, выполнившая монтаж эскалатора",
        ],
        correctAnswers: [
          "Организация, эксплуатирующая эскалатор, самостоятельно либо с привлечением специализированной организации",
        ],
      },
      {
        code: "69210",
        text: "Что запрещается организации, эксплуатирующей ОПО, на котором используется эскалатор, в целях обеспечения безопасных условий его эксплуатации?",
        answers: [
          "Отступать от требований конструкторской документации в процессе монтажа, ремонта, модернизации и реконструкции эскалаторов без согласования с организацией-изготовителем или организацией - разработчиком данной конструкторской документации и владельцем ОПО",
          "Обеспечивать специалистов должностными инструкциями, нормативными правовыми актами, устанавливающими требования промышленной безопасности, правилами ведения работ на опасном производственном объекте (ОПО)",
          "Назначать лиц, ответственных за содержание эскалаторов в исправном состоянии и за безопасную эксплуатацию",
          "Организовывать проведение периодических осмотров, технического обслуживания и ремонтов эскалаторов",
          "Назначать лиц, ответственных за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации эскалатора",
        ],
        correctAnswers: [
          "Отступать от требований конструкторской документации в процессе монтажа, ремонта, модернизации и реконструкции эскалаторов без согласования с организацией-изготовителем или организацией - разработчиком данной конструкторской документации и владельцем ОПО",
        ],
      },
      {
        code: "69211",
        text: "Кем проводятся осмотр, проверка и испытания эскалатора?",
        answers: [
          "Организацией, выполнившей монтаж или реконструкцию эскалатора",
          "Эксплуатирующей организацией",
          "Организацией-изготовителем",
          "Проектной организацией",
        ],
        correctAnswers: [
          "Организацией, выполнившей монтаж или реконструкцию эскалатора",
        ],
      },
      {
        code: "69212",
        text: "Каким образом необходимо проводить обкатку модернизированного или реконструированного эскалатора, или эскалатора после проведенного капитального (капитально-восстановительного) ремонта?",
        answers: [
          "В течение 12 часов непрерывной работы от главного привода, по 6 часов в каждую сторону",
          "В течение 24 часов непрерывной работы от главного привода, по 12 часов в каждую сторону",
          "В течение 48 часов непрерывной работы от главного привода, по 24 часа в каждую сторону",
          "В течение 6 часов непрерывной работы от главного привода, по 2 часа в каждую сторону",
        ],
        correctAnswers: [
          "В течение 12 часов непрерывной работы от главного привода, по 6 часов в каждую сторону",
        ],
      },
      {
        code: "69213",
        text: "Какие действия необходимо предпринять при возникновении неисправностей, представляющих опасность при пользовании эскалатором?",
        answers: [
          "Эскалатор должен быть остановлен, а пассажиры с него удалены",
          "Эскалатор должен быть остановлен, пассажиры не должны покидать его до выяснения причины неисправности",
          "Эскалатор должен быть остановлен, пассажиры не должны покидать его до устранения неисправности",
        ],
        correctAnswers: [
          "Эскалатор должен быть остановлен, а пассажиры с него удалены",
        ],
      },
      {
        code: "69214",
        text: "Какая из перечисленных записей не ставится в паспорте эскалатора уполномоченным лицом владельца ОПО, проводившим освидетельствование?",
        answers: [
          "Запись о методах контроля и диагностики, применяемых в процессе эксплуатации эскалатора",
          "Запись о сроке очередного периодического технического освидетельствования",
          "Запись о результатах полного технического освидетельствования",
          "Запись о вводе эскалатора в эксплуатацию",
        ],
        correctAnswers: [
          "Запись о методах контроля и диагностики, применяемых в процессе эксплуатации эскалатора",
        ],
      },
      {
        code: "69215",
        text: "С какой периодичностью должна проводиться проверка знаний персонала, обслуживающего эскалаторы, в объеме производственных инструкций?",
        answers: [
          "Не реже одного раза в год",
          "Не реже одного раза в 2 года",
          "Не реже одного раза в 3 года",
          "Не реже одного раза в 5 лет",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "69216",
        text: "В каком случае повторная (внеочередная) проверка знаний персонала, обслуживающего эскалаторы, не проводится?",
        answers: [
          "При перерыве в работе по должности 3 месяца",
          "По требованию лица, ответственного за осуществление производственного контроля",
          "При переводе на обслуживание эскалаторов других типов",
          "При переходе из одной организации в другую",
        ],
        correctAnswers: ["При перерыве в работе по должности 3 месяца"],
      },
      {
        code: "69217",
        text: "При перемещении на какое расстояние натяжного устройства в сторону привода или в обратном направлении эскалатор должен останавливаться?",
        answers: [
          "Не более 30 мм",
          "Не более 25 мм",
          "Не более 20 мм",
          "Не нормируется",
        ],
        correctAnswers: ["Не более 30 мм"],
      },
      {
        code: "69218",
        text: "Какой нагрузкой нагружают две ступени эскалатора от изготавливаемой партии для проверки фактического запаса прочности?",
        answers: [
          "Не менее 5qмэ",
          "Не менее 4,5qмэ",
          "Не менее 4qмэ",
          "Не менее 3,5qмэ",
        ],
        correctAnswers: ["Не менее 5qмэ"],
      },
      {
        code: "69219",
        text: "Выберите допустимое значение остаточной деформации, измеренной у поверхности настила, после испытаний ступеней эскалатора.",
        answers: ["4 мм", "4,5 мм", "5 мм", "5,5 мм"],
        correctAnswers: ["4 мм"],
      },
      {
        code: "69220",
        text: "Какое должно быть общее время электрического торможения до срабатывания рабочего тормоза эскалатора?",
        answers: [
          "Не должно превышать 4 с",
          "Не должно превышать 4,5 с",
          "Не должно превышать 5 с",
          "Не должно превышать 5,5 с",
        ],
        correctAnswers: ["Не должно превышать 4 с"],
      },
      {
        code: "69221",
        text: "При каком замедлении лестничного полотна при торможении допускается совместное срабатывание дополнительного (аварийного) и рабочего тормозов?",
        answers: ["1 м/с²", "1,5 м/с²", "2 м/с²", "2,5 м/с²"],
        correctAnswers: ["1 м/с²"],
      },
      {
        code: "69222",
        text: "Сколько должна составлять общая тормозная нагрузка для обеспечения пути разгона и торможения лестничного полотна при испытаниях тормозной системы эскалатора?",
        answers: [
          "Не более 400 кг на ступень",
          "Не более 350 кг на ступень",
          "Не более 300 кг на ступень",
          "Не более 250 кг на ступень",
        ],
        correctAnswers: ["Не более 300 кг на ступень"],
      },
      {
        code: "69223",
        text: "Кто из перечисленных лиц может оказывать первую помощь при наличии соответствующей подготовки и/или навыков при эксплуатации эскалатора?",
        answers: [
          "Только машинист",
          "Только мастер",
          "Только дежурный у экскаватора",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
    ],
    1000: [
      {
        code: "1000000",
        text: "Какие требования не должны устанавливаться в эксплуатационных документах эскалатора метрополитена?",
        answers: [
          "Требования к изготовлению",
          "Требования к условиям перевозки и хранения",
          "Требования к консервации",
          "Требования к утилизации",
        ],
        correctAnswers: ["Требования к изготовлению"],
      },
      {
        code: "1000001",
        text: "Что должен включать комплект документации на каждый изготовленный эскалатор?",
        answers: [
          "Должностную инструкцию лица, ответственного за содержание эскалатора в исправном состоянии и его безопасную эксплуатацию",
          "Ведомость комплекта запасных изделий для пусконаладочных работ",
          "Производственные инструкции обслуживающего персонала",
          "План мероприятий по действиям персонала в случае аварийной остановки эскалатора",
        ],
        correctAnswers: [
          "Ведомость комплекта запасных изделий для пусконаладочных работ",
        ],
      },
      {
        code: "1000002",
        text: "С кем владелец ОПО, на котором используется эскалатор в метрополитенах, должен согласовать изменения, вносимые при изготовлении, модернизации или реконструкции эскалатора в его конструкцию?",
        answers: [
          "С экспертной организацией",
          "С организацией-разработчиком или специализированной проектной организацией",
          "С организацией по монтажно-наладочным работам",
          "С территориальным органом Ростехнадзора",
        ],
        correctAnswers: [
          "С организацией-разработчиком или специализированной проектной организацией",
        ],
      },
      {
        code: "1000003",
        text: "Что не относится к основным техническим данным и характеристикам, указываемым в паспорте эскалатора?",
        answers: [
          "Тип передачи к главному валу эскалатора",
          "Скорость движения лестничного полотна м/с",
          "Фактическая производительность чел/час",
          "Характеристика помещений для установки эскалатора",
        ],
        correctAnswers: ["Характеристика помещений для установки эскалатора"],
      },
      {
        code: "1000004",
        text: 'На какие процессы в области промышленной безопасности на ОПО не распространяется действие федеральных норм и правил "Правила безопасности эскалаторов в метрополитенах"?',
        answers: [
          "Связанные с изготовлением, монтажом, наладкой, обслуживанием и ремонтом эскалаторов",
          "Связанные с эксплуатацией, капитальным ремонтом, техническим перевооружением, консервацией и ликвидацией",
          "Связанные с перевозкой и хранением",
          "Связанные с проведением экспертизы промышленной безопасности",
        ],
        correctAnswers: ["Связанные с перевозкой и хранением"],
      },
      {
        code: "1000005",
        text: "Что не входит в перечень документации, поставляемой с эскалатором изготовителем?",
        answers: [
          "Нормы оценки качества сварных соединений",
          "Руководство по эксплуатации",
          "Методика грузовых испытаний",
          "Техническое описание",
        ],
        correctAnswers: ["Нормы оценки качества сварных соединений"],
      },
      {
        code: "1000006",
        text: "В каком случае в процессе монтажа эскалаторов допускаются отступления от требований конструкторской документации?",
        answers: [
          "При согласовании этих изменений с владельцем ОПО, а также организацией - изготовителем эскалатора или организацией - разработчиком данной документации, либо со специализированной по эскалаторам проектной организацией",
          "При согласовании этих изменений только со специализированной по эскалаторам проектной организацией",
          "При согласовании этих изменений только с организацией - изготовителем эскалатора",
        ],
        correctAnswers: [
          "При согласовании этих изменений с владельцем ОПО, а также организацией - изготовителем эскалатора или организацией - разработчиком данной документации, либо со специализированной по эскалаторам проектной организацией",
        ],
      },
      {
        code: "1000007",
        text: "Какие данные не указываются в паспорте эскалатора?",
        answers: [
          "Характеристика электродвигателей",
          "Сведения об устройствах безопасности",
          "Характеристика тормозов",
          "Квалификация представителя организации, выполнившей монтаж",
        ],
        correctAnswers: [
          "Квалификация представителя организации, выполнившей монтаж",
        ],
      },
      {
        code: "1000008",
        text: "В каком случае допускается применение посадок с гарантированным натягом без дополнительного крепления в составных частях привода эскалатора, передающих крутящий момент?",
        answers: [
          "Не допускается ни в каком случае",
          "Только если это соответствует требованиям проектной документации",
          'Только если это соответствует требованиям "Правил безопасности эскалаторов в метрополитенах"',
          "Только если обеспечен контроль безопасности выполнения технологических операций",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "1000009",
        text: "Какие требования к эскалаторам указаны неверно?",
        answers: [
          "Неподвижные оси, служащие опорой для блоков, роликов и прочих вращающихся деталей, должны быть надежно укреплены и снабжены приспособлениями, обеспечивающими их фиксацию",
          "Составные части эскалатора должны быть защищены от коррозии в соответствии с условиями их эксплуатации, хранения и транспортирования в части воздействия климатических факторов внешней среды",
          "Легкодоступные части эскалатора, находящиеся в движении, должны быть закрыты ограждениями, обеспечивающими безопасность обслуживающего персонала. Запрещается устанавливать съемные ограждения",
          "К механизмам, предохранительным устройствам, электрооборудованию, требующим обслуживания, должен быть обеспечен безопасный доступ. Для этой цели должны быть устроены площадки, съемные ограждения, лестницы и специальные приспособления",
          "Все перечисленные требования",
        ],
        correctAnswers: [
          "Легкодоступные части эскалатора, находящиеся в движении, должны быть закрыты ограждениями, обеспечивающими безопасность обслуживающего персонала. Запрещается устанавливать съемные ограждения",
        ],
      },
      {
        code: "1000010",
        text: "Какие сведения не содержат эксплуатационные документы эскалатора метрополитена?",
        answers: [
          "Программу специальной подготовки персонала",
          "Критерии предельных состояний",
          "Назначенные показатели, срок службы, ресурс эскалатора",
          "Действия персонала по ликвидации последствий инцидента или аварии",
          "Указания по монтажу или сборке, наладке или регулировке, техническому обслуживанию и ремонту",
        ],
        correctAnswers: ["Программу специальной подготовки персонала"],
      },
      {
        code: "1000011",
        text: "Каким образом в процессе эксплуатации эскалаторов должно определяться их соответствие требованиям промышленной безопасности?",
        answers: [
          "Проведением сертификации",
          "Проведением декларирования",
          "Проведением экспертизы промышленной безопасности",
        ],
        correctAnswers: ["Проведением экспертизы промышленной безопасности"],
      },
      {
        code: "1000012",
        text: "Какая величина замедления установлена для лестничного полотна эскалатора, загруженного максимальной эксплуатационной нагрузкой, при нарушении кинематической связи между приводом и главным валом и торможении аварийным тормозом?",
        answers: [
          "Не более 2,5 м/с²",
          "Не более 1,5 м/с²",
          "Не более 2 м/с²",
          "Не более 1 м/с²",
        ],
        correctAnswers: ["Не более 2 м/с²"],
      },
      {
        code: "1000013",
        text: "Какая величина ускорения, независимо от пассажирской нагрузки, установлена для лестничного полотна эскалатора?",
        answers: [
          "Не более 0,60 м/с²",
          "Не более 0,65 м/с²",
          "Не более 0,70 м/с²",
          "Не более 0,75 м/с²",
        ],
        correctAnswers: ["Не более 0,75 м/с²"],
      },
      {
        code: "1000014",
        text: "Какое из перечисленных требований к определению фактического запаса прочности тяговой цепи указано верно?",
        answers: [
          "Длина отобранных для испытаний отрезков цепи должна быть не менее 400 мм",
          "Остаточные деформации или распрессовка деталей цепи после испытаний допускаются в пределах, указанных в руководстве (инструкции) по эксплуатации",
          "Каждый отрезок цепи должен быть проверен на стенде под нагрузкой, превышающей в два раза наибольшее расчетное натяжение при нагрузке qм.э",
          "Разрешается не доводить цепи до разрушения, если нагрузка при испытаниях превысила на 10% расчетную или указанную в проектной документации",
        ],
        correctAnswers: [
          "Каждый отрезок цепи должен быть проверен на стенде под нагрузкой, превышающей в два раза наибольшее расчетное натяжение при нагрузке qм.э",
        ],
      },
      {
        code: "1000015",
        text: "Что запрещается при обустройстве балюстрады?",
        answers: [
          "Устанавливать на фартуках планки и штапики, обращенные к лестничному полотну",
          "Выполнять конструкцию балюстрады в легкоразборном исполнении в местах, требующих технического обслуживания",
          "Устанавливать дефлекторы, препятствующие затягиванию элементов одежды и обуви в зазор между ступенью и фартуком эскалатора",
          "Устанавливать решетки для громкоговорящей связи, осветителей и розеток (по согласованию с разработчиком эскалатора)",
        ],
        correctAnswers: [
          "Устанавливать на фартуках планки и штапики, обращенные к лестничному полотну",
        ],
      },
      {
        code: "1000016",
        text: "Каким образом должны быть устроены входные вертикальные лестницы натяжных камер эскалатора?",
        answers: [
          "Должны быть шириной не менее 650 мм и иметь расстояние между ступенями не менее 350 мм, шаг ступеней должен быть выдержан по всей высоте лестницы",
          "Должны быть шириной не менее 550 мм и иметь расстояние между ступенями не более 250 мм, шаг ступеней должен быть выдержан по всей высоте лестницы. Ступени вертикальной лестницы должны отстоять от стен и других строительных конструкций не менее чем на 150 мм",
          "Должны быть шириной не менее 500 мм и иметь расстояние между ступенями не более 200 мм, шаг ступеней должен быть выдержан по всей высоте лестницы",
          "Должны быть шириной не менее 600 мм и иметь расстояние между ступенями не более 300 мм, шаг ступеней должен быть выдержан по всей высоте лестницы. Ступени вертикальной лестницы должны отстоять от стен и других строительных конструкций не менее чем на 150 мм",
        ],
        correctAnswers: [
          "Должны быть шириной не менее 600 мм и иметь расстояние между ступенями не более 300 мм, шаг ступеней должен быть выдержан по всей высоте лестницы. Ступени вертикальной лестницы должны отстоять от стен и других строительных конструкций не менее чем на 150 мм",
        ],
      },
      {
        code: "1000017",
        text: "Какой допускается максимальный упругий прогиб фартука балюстрады под воздействием нагрузки 1500 H, приложенной между опорами фартука перпендикулярно к его поверхности на площади 25 см²?",
        answers: ["4 мм", "3 мм", "5 мм", "7 мм"],
        correctAnswers: ["4 мм"],
      },
      {
        code: "1000018",
        text: "Начиная с какой высоты, на вертикальных лестницах натяжной камеры при высоте лестницы более 5 м должны быть установлены ограждения в виде дуг?",
        answers: [
          "С высоты 1,3 м",
          "С высоты 2,0 м",
          "С высоты 1,5 м",
          "С высоты 3,0 м",
        ],
        correctAnswers: ["С высоты 3,0 м"],
      },
      {
        code: "1000019",
        text: "При каком торможении на спуск с максимальной нагрузкой qмэ на пути (в метрах) каждый из тормозов должен останавливать лестничное полотно при применении двух и более рабочих тормозов?",
        answers: ["Не более 6V", "Не более 5V", "Не более 7V", "Не более 8V"],
        correctAnswers: ["Не более 6V"],
      },
      {
        code: "1000020",
        text: "Какой должна быть максимальная стрела упругого прогиба под действием нагрузки qc для пролетных металлоконструкций, включая плиты входных площадок, эскалаторов?",
        answers: [
          "1/1000 пролета",
          "1/750 пролета",
          "1/1500 пролета",
          "1/2000 пролета",
        ],
        correctAnswers: ["1/750 пролета"],
      },
      {
        code: "1000021",
        text: "Какой минимальный коэффициент запаса прочности должен быть для тяговых и приводных цепей?",
        answers: ["5", "6", "7", "8", "9"],
        correctAnswers: ["7"],
      },
      {
        code: "1000022",
        text: "Какие требования к лестничному полотну эскалатора указаны неверно?",
        answers: [
          "Для обеспечения постоянного натяжения лестничного полотна следует предусматривать натяжное устройство",
          "Рабочую поверхность ступени необходимо выполнять в виде выступов и впадин с расположением их вдоль оси эскалатора",
          "Допускается применение пружин растяжения для натяжения цепей",
        ],
        correctAnswers: [
          "Допускается применение пружин растяжения для натяжения цепей",
        ],
      },
      {
        code: "1000023",
        text: "Какое из перечисленных требований к балюстраде эскалатора указано неверно?",
        answers: [
          "Конструкция балюстрады в местах, требующих технического обслуживания, выполняется в легкоразборном исполнении",
          "Перепады плоскостей между элементами балюстрады (щиты, планки, штапики) со стороны лестничного полотна допускаются более 3 мм, если плоскости балюстрады выполнены вертикальными или расширенными кверху",
          "Расстояние между верхними кромками боковых щитов балюстрады не должно превышать ширину ступени более чем на 200 мм",
          "Стыки фартуков не должны иметь перепадов более 0,5 мм и должно быть исключено их взаимное смещение",
        ],
        correctAnswers: [
          "Перепады плоскостей между элементами балюстрады (щиты, планки, штапики) со стороны лестничного полотна допускаются более 3 мм, если плоскости балюстрады выполнены вертикальными или расширенными кверху",
        ],
      },
      {
        code: "1000024",
        text: "Какая номинальная скорость движения установлена для лестничного полотна эскалатора?",
        answers: [
          "Не более 0,9 м/с",
          "Не более 1,0 м/с",
          "Не более 1,2 м/с",
          "Не более 0,75 м/с",
        ],
        correctAnswers: ["Не более 0,75 м/с"],
      },
      {
        code: "1000025",
        text: "Каким должно быть расстояние по вертикали от уровня настила ступеней эскалатора до потолка галереи, тоннеля или выступающих частей (балок, архитектурных украшений, осветительной арматуры) по всей длине и ширине эскалатора по наружным кромкам поручня?",
        answers: [
          "Не менее 2300 мм",
          "Не менее 2150 мм",
          "Не менее 2100 мм",
          "Не менее 2000 мм",
        ],
        correctAnswers: ["Не менее 2300 мм"],
      },
      {
        code: "1000026",
        text: "В каком случае после срабатывания блокировочного устройства повторный пуск эскалатора в работу возможен без принудительного приведения блокировочного устройства в исходное положение?",
        answers: [
          "При подъеме или опускании ступеней перед входными площадками",
          "При срабатывании рабочего тормоза и блокировок входных площадок",
          "При нерасторможенном рабочем или дополнительном (аварийном) тормозе",
          "При сходе поручня с направляющих на нижнем криволинейном участке",
        ],
        correctAnswers: [
          "При срабатывании рабочего тормоза и блокировок входных площадок",
        ],
      },
      {
        code: "1000027",
        text: "В каком случае допускается осуществлять электропитание дополнительного (аварийного) тормоза (тормозов) от одного источника?",
        answers: [
          "Если тормоз установлен на главном валу",
          "В случаях, предусмотренных проектной документацией",
          "При использовании нормального замкнутого тормоза",
          "При использовании нормального разомкнутого тормоза",
        ],
        correctAnswers: ["При использовании нормального замкнутого тормоза"],
      },
      {
        code: "1000028",
        text: "Какой должна быть длина горизонтальных участков ступеней в зоне входных площадок при высоте транспортирования пассажиров более 6 м и при номинальной скорости более 0,5 м/с (но не более 0,65 м/с для эскалаторов)?",
        answers: [
          "Не менее 1200 мм",
          "Не менее 1000 мм",
          "Не менее 800 мм",
          "Не менее 600 мм",
        ],
        correctAnswers: ["Не менее 1200 мм"],
      },
      {
        code: "1000029",
        text: "Сколько должна составлять скорость движения поручневого устройства эскалатора?",
        answers: [
          "Не должна отличаться от скорости движения лестничного полотна более чем на 5%",
          "Не должна отличаться от скорости движения лестничного полотна более чем на 4%",
          "Не должна отличаться от скорости движения лестничного полотна более чем на 2%",
          "Не должна отличаться от скорости движения лестничного полотна более чем на 3%",
        ],
        correctAnswers: [
          "Не должна отличаться от скорости движения лестничного полотна более чем на 2%",
        ],
      },
      {
        code: "1000030",
        text: "Какой должна быть длина горизонтальных участков ступеней в зоне входных площадок при высоте транспортирования пассажиров не более 6 м и при номинальной скорости не более 0,5 м/с?",
        answers: [
          "Не менее 800 мм",
          "Не менее 700 мм",
          "Не менее 600 мм",
          "Не менее 500 мм",
        ],
        correctAnswers: ["Не менее 800 мм"],
      },
      {
        code: "1000031",
        text: "Какая устанавливается ширина свободного прохода в машинном помещении с одной стороны для эскалаторов с высотой подъема до 15 м при установке трех эскалаторов?",
        answers: ["До 750 мм", "До 850 мм", "До 950 мм"],
        correctAnswers: ["До 750 мм"],
      },
      {
        code: "1000032",
        text: "Какие требования предъявляются к входным площадкам эскалатора?",
        answers: [
          "Освещенность у входных площадок, не включая гребенки, должна соответствовать освещенности, создаваемой освещением в этой зоне",
          "Конструкция входной площадки должна иметь устройство, обеспечивающее правильное направление настила ступени относительно зубьев гребенки",
          "При возникновении опасности повреждения входной площадки/ступени оператор должен остановить эскалатор",
          "Поверхность входных площадок не должна быть рифленой",
        ],
        correctAnswers: [
          "Конструкция входной площадки должна иметь устройство, обеспечивающее правильное направление настила ступени относительно зубьев гребенки",
        ],
      },
      {
        code: "1000033",
        text: "Какую величину, независимо от пассажирской нагрузки, не должно превышать замедление лестничного полотна эскалатора при торможении рабочими тормозами при работе на спуск?",
        answers: ["1,00 м/с²", "0,90 м/с²", "0,75 м/с²", "0,60 м/с²"],
        correctAnswers: ["0,60 м/с²"],
      },
      {
        code: "1000034",
        text: "Сколько должен составлять перепад по высоте двух смежных ступеней на горизонтальном участке эскалатора?",
        answers: [
          "Не должен превышать 7 мм",
          "Не должен превышать 4 мм",
          "Не должен превышать 5 мм",
          "Не должен превышать 6 мм",
        ],
        correctAnswers: ["Не должен превышать 4 мм"],
      },
      {
        code: "1000035",
        text: "Какая ремонтная скорость движения установлена для лестничного полотна эскалатора?",
        answers: [
          "Не более 0,08 м/с",
          "Не более 0,06 м/с",
          "Не более 1,0 м/с",
          "Не более 0,04 м/с",
        ],
        correctAnswers: ["Не более 0,04 м/с"],
      },
      {
        code: "1000036",
        text: "Какую величину составляет минимальное расстояние от холостой ветви поручня эскалатора до вспомогательных коммуникаций, не относящихся к эскалаторам?",
        answers: ["400 мм", "300 мм", "200 мм", "100 мм"],
        correctAnswers: ["200 мм"],
      },
      {
        code: "1000037",
        text: "В каком из перечисленных случаев допускается одновременная работа главного и вспомогательного приводов?",
        answers: [
          "Только в случае превышения максимально допустимой нагрузки эскалатора",
          "Только в случае проведения работ по обкатке эскалатора",
          "Только в случае снижения мощности двигателя главного привода",
          "Одновременная работа главного и вспомогательного привода не допускается ни в каком случае",
        ],
        correctAnswers: [
          "Одновременная работа главного и вспомогательного привода не допускается ни в каком случае",
        ],
      },
      {
        code: "1000038",
        text: "Для чего предназначен рабочий тормоз эскалатора?",
        answers: [
          "Для регулирования скорости движения лестничного полотна и его остановки при отключении электродвигателя",
          "Для блокирования движения эскалатора, двигающегося вниз, в случае увеличения скорости лестничного полотна на 20% номинальной скорости",
          "Для остановки лестничного полотна при самопроизвольном изменении направления его движения при подъеме эскалатора",
          "Для остановки лестничного полотна при каждом отключении главного или вспомогательного привода, а также при обесточивании цепи управления",
        ],
        correctAnswers: [
          "Для остановки лестничного полотна при каждом отключении главного или вспомогательного привода, а также при обесточивании цепи управления",
        ],
      },
      {
        code: "1000039",
        text: "Для каких целей предназначен главный привод эскалатора?",
        answers: [
          "Для передвижения лестничного полотна эскалатора с номинальной скоростью",
          "Для обеспечения ремонтной скорости при монтажных и демонтажных работах",
          "Для движения лестничного полотна с ремонтной скоростью при проведении технического обслуживания",
          "Для растормаживания аварийного тормоза",
        ],
        correctAnswers: [
          "Для передвижения лестничного полотна эскалатора с номинальной скоростью",
        ],
      },
      {
        code: "1000040",
        text: "Для чего не предназначается дополнительный (аварийный) тормоз эскалатора?",
        answers: [
          "Для остановки лестничного полотна эскалатора при отключении электродвигателя",
          "Для остановки эскалатора при увеличении номинальной скорости движения лестничного полотна более чем в 1,2 раза",
          "Для остановки эскалатора при срабатывании блокировки превышения допустимых тормозных путей или расчетного времени рабочего торможения более чем на 20%",
          "Для остановки эскалатора при самопроизвольном изменении направления движения лестничного полотна работающего на подъем эскалатора",
        ],
        correctAnswers: [
          "Для остановки лестничного полотна эскалатора при отключении электродвигателя",
        ],
      },
      {
        code: "1000041",
        text: "Как должны быть выполнены ступени по наклонной части эскалаторного тоннеля в проходах между эскалаторами, а также между крайним эскалатором и строительными конструкциями?",
        answers: [
          "Шириной не менее 250 мм и высотой не более 250 мм с постоянным углом наклона",
          "Шириной не менее 350 мм и высотой не более 200 мм с постоянным углом наклона",
          "Шириной не менее 300 мм и высотой не более 300 мм с постоянным углом наклона",
          "Шириной не менее 400 мм и высотой не более 150 мм с постоянным углом наклона",
        ],
        correctAnswers: [
          "Шириной не менее 350 мм и высотой не более 200 мм с постоянным углом наклона",
        ],
      },
      {
        code: "1000042",
        text: "Какие из перечисленных конструкций относятся к ответственным (расчетным) сварным конструкциям?",
        answers: [
          "Только каркас ступени",
          "Только конструкции подъемно-транспортного оборудования машинного помещения",
          "Только рычаги и рамы тормозов",
          "Только входная площадка",
          "Все перечисленные конструкции",
        ],
        correctAnswers: ["Все перечисленные конструкции"],
      },
      {
        code: "1000043",
        text: "В каком случае допускается пуск эскалатора с пассажирами на полотне с пульта дистанционного управления?",
        answers: [
          "Если обеспечена возможность наблюдения за пассажирами на эскалаторе или обеспечена переговорная связь с персоналом, находящимся у эскалатора, с выдачей информации, предупреждающей пассажиров о пуске",
          "Если исключен пуск эскалатора в обратном направлении",
          "Если установлены несамовозвратные выключатели для экстренной остановки эскалатора",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "Если обеспечена возможность наблюдения за пассажирами на эскалаторе или обеспечена переговорная связь с персоналом, находящимся у эскалатора, с выдачей информации, предупреждающей пассажиров о пуске",
        ],
      },
      {
        code: "1000044",
        text: "Каким должно быть напряжение цепей штепсельных розеток для питания переносных ламп?",
        answers: [
          "Не более 110 В",
          "Не более 50 В",
          "Не более 127 В",
          "Не более 120 В",
        ],
        correctAnswers: ["Не более 50 В"],
      },
      {
        code: "1000045",
        text: "Какая величина угла наклона установлена для эскалатора?",
        answers: [
          "Не более 35°",
          "Не более 45°",
          "Не более 30°",
          "Не более 40°",
        ],
        correctAnswers: ["Не более 30°"],
      },
      {
        code: "1000046",
        text: "Кто не входит в состав комиссии, принимающей решение о возможности ввода эскалатора в эксплуатацию?",
        answers: [
          "Уполномоченный представитель страховой организации",
          "Уполномоченный представитель эксплуатирующей организации",
          "Уполномоченный представитель организации, выполнившей строительно-монтажные работы",
          "Уполномоченный представитель федерального органа исполнительной власти в области промышленной безопасности",
          "Уполномоченный представитель организации, выполнившей монтаж эскалатора",
        ],
        correctAnswers: ["Уполномоченный представитель страховой организации"],
      },
      {
        code: "1000047",
        text: "Что запрещается организации, эксплуатирующей ОПО, на котором используется эскалатор, в целях обеспечения безопасных условий его эксплуатации?",
        answers: [
          "Организовывать проведение периодических осмотров, технического обслуживания и ремонтов эскалаторов",
          "Отступать от требований конструкторской документации в процессе монтажа, ремонта, модернизации и реконструкции эскалаторов без согласования с организацией-изготовителем или организацией - разработчиком данной конструкторской документации и владельцем ОПО",
          "Обеспечивать специалистов должностными инструкциями, нормативными правовыми актами, устанавливающими требования промышленной безопасности, правилами ведения работ на опасных производственных объектах (ОПО)",
          "Назначать лиц, ответственных за содержание эскалаторов в исправном состоянии и за безопасную эксплуатацию",
          "Назначать лиц, ответственных за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации эскалатора",
        ],
        correctAnswers: [
          "Отступать от требований конструкторской документации в процессе монтажа, ремонта, модернизации и реконструкции эскалаторов без согласования с организацией-изготовителем или организацией - разработчиком данной конструкторской документации и владельцем ОПО",
        ],
      },
      {
        code: "1000048",
        text: "Каким образом должна проводиться обкатка каждого вновь установленного эскалатора?",
        answers: [
          "На месте применения в течение 24 часов непрерывной работы от вспомогательного привода, по 12 часов в каждом направлении от постоянных источников питания",
          "На месте применения в течение 12 часов непрерывной работы от главного привода, по 6 часов в каждую сторону",
          "На месте применения в течение 24 часов от вспомогательного привода, следующие 24 часа от главного привода",
          "На месте применения в течение 48 часов непрерывной работы от главного привода, по 24 часа в каждом направлении от постоянных источников питания",
        ],
        correctAnswers: [
          "На месте применения в течение 48 часов непрерывной работы от главного привода, по 24 часа в каждом направлении от постоянных источников питания",
        ],
      },
      {
        code: "1000049",
        text: "С какой периодичностью должна проводиться проверка знаний персонала, обслуживающего эскалаторы, в объеме производственных инструкций?",
        answers: [
          "Не реже одного раза в три года",
          "Не реже одного раза в год",
          "Не реже одного раза в полугодие",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "1000050",
        text: "Сколько должна составлять норма межремонтного пробега для капитального ремонта, если она не установлена изготовителем эскалатора?",
        answers: [
          "Не более 175 000 км",
          "Не более 165 000 км",
          "Не более 150 000 км",
          "Не более 170 000 км",
        ],
        correctAnswers: ["Не более 150 000 км"],
      },
      {
        code: "1000051",
        text: "Какой документ составляется по окончании устранения неисправностей, выявленных в ходе осмотра, проверок и испытаний эскалаторов?",
        answers: [
          "Акт ввода в эксплуатацию эскалатора",
          "Акт приемо-сдаточных испытаний",
          "Акт технической готовности",
          "Протокол осмотра эскалаторов",
        ],
        correctAnswers: ["Акт технической готовности"],
      },
      {
        code: "1000052",
        text: "Допускаются ли при обкатке вновь установленного эскалатора остановки?",
        answers: [
          "Допускаются остановки только для проверки правильности регулировки и действия рабочего и дополнительного тормозов, входных площадок, блокировочных устройств с замером регламентированных зазоров и размеров составных частей",
          "Допускаются остановки для наладки и регулирования общей продолжительностью не более 45 минут",
          "Допускаются остановки для наладки и регулирования общей продолжительностью не более 90 минут",
          "Допускаются остановки для регулирования после проведенного капитально-восстановительного ремонта",
        ],
        correctAnswers: [
          "Допускаются остановки для наладки и регулирования общей продолжительностью не более 90 минут",
        ],
      },
      {
        code: "1000053",
        text: "В каком случае повторная (внеочередная) проверка знаний персонала, обслуживающего эскалаторы, не проводится?",
        answers: [
          "По требованию работника, ответственного за осуществление производственного контроля",
          "При переводе на обслуживание эскалаторов других типов",
          "При перерыве в работе по должности 3 месяца",
          "При переходе из одной организации в другую",
        ],
        correctAnswers: ["При перерыве в работе по должности 3 месяца"],
      },
      {
        code: "1000054",
        text: "Когда машинисту (дежурному у эскалатора) необходимо проводить контроль за состоянием эскалатора?",
        answers: [
          "Ежедневно перед началом работы",
          "Один раз в неделю",
          "Один раз в три дня",
          "Ежесменно: до начала и по окончании работы",
        ],
        correctAnswers: ["Ежедневно перед началом работы"],
      },
      {
        code: "1000055",
        text: "В каком случае эскалатор метрополитена не подлежит экспертизе промышленной безопасности, если техническим регламентом не установлена иная форма оценки соответствия эскалатора?",
        answers: [
          "До начала применения на опасном производственном объекте (ОПО)",
          "По истечении срока службы или при превышении количества циклов нагрузки эскалатора, установленных организацией-изготовителем",
          "При отсутствии в технической документации данных о сроке службы эскалатора, если фактический срок его службы составляет 10 лет",
          "После проведения работ, связанных с изменением конструкции, заменой материала несущих элементов эскалатора, либо восстановительного ремонта после аварии на ОПО, в результате которых был поврежден эскалатор",
        ],
        correctAnswers: [
          "При отсутствии в технической документации данных о сроке службы эскалатора, если фактический срок его службы составляет 10 лет",
        ],
      },
      {
        code: "1000056",
        text: "Кем проводятся осмотр, проверка и испытания эскалатора?",
        answers: [
          "Организацией, имеющей лицензию на право проведения экспертизы промышленной безопасности",
          "Организацией, эксплуатирующей эскалаторы",
          "Специализированной организацией по обслуживанию подъемно-транспортного оборудования",
          "Организацией, выполнившей монтаж или реконструкцию эскалатора",
        ],
        correctAnswers: [
          "Организацией, выполнившей монтаж или реконструкцию эскалатора",
        ],
      },
      {
        code: "1000057",
        text: "Кто принимает решение о вводе в эксплуатацию эскалатора метрополитена?",
        answers: [
          "Лицо, ответственное за исправное состояние эскалатора",
          "Лицо, ответственное за осуществление контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО",
          "Лицо, ответственное за содержание эскалатора в исправном состоянии",
        ],
        correctAnswers: [
          "Лицо, ответственное за осуществление контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО",
        ],
      },
      {
        code: "1000058",
        text: "Какие нормативные документы регламентируют порядок и объем работ периодического технического освидетельствования эскалаторов в метрополитенах?",
        answers: [
          'Федеральные нормы и правила в области промышленной безопасности "Правила безопасности эскалаторов в метрополитенах" и технический регламент "О безопасности машин и оборудования"',
          'ГОСТ 33966.1-2016 "Межгосударственный стандарт. Эскалаторы и пассажирские конвейеры. Требования безопасности к устройству и установке" и технический регламент "О безопасности машин и оборудования"',
          "Инструкция по монтажу и руководство по эксплуатации эскалатора",
          'Руководство по эксплуатации эскалатора и федеральные нормы и правила в области промышленной безопасности "Правила безопасности эскалаторов в метрополитенах"',
        ],
        correctAnswers: [
          'Руководство по эксплуатации эскалатора и федеральные нормы и правила в области промышленной безопасности "Правила безопасности эскалаторов в метрополитенах"',
        ],
      },
      {
        code: "1000059",
        text: "Какое требование к управлению эскалатором указано неверно?",
        answers: [
          "Устанавливать пульт управления эскалатором в отдельно вынесенное помещение допускается только для эскалаторов с высотой подъема от 6 м, при этом должны быть предусмотрены видеокамеры по наклонному ходу, на верхних и нижних площадках, с интеграцией изображений на мониторы в данное помещение",
          "Управление эскалатором, оборудованным системой дистанционного управления, должен осуществлять дежурный оператор у эскалатора с пульта, установленного в кабине персонала, при обеспечении постоянного визуального наблюдения за пассажирами на лестничном полотне",
          "В зоне нижней площадки схода эскалатора должно обеспечиваться постоянное наблюдение за пассажирами, находящимися на лестничном полотне",
          "Работник, осуществляющий наблюдение за пассажирами на эскалаторе, обязан останавливать эскалатор в случае падения пассажира или возникновения опасности нанесения травм пассажирам",
        ],
        correctAnswers: [
          "Устанавливать пульт управления эскалатором в отдельно вынесенное помещение допускается только для эскалаторов с высотой подъема от 6 м, при этом должны быть предусмотрены видеокамеры по наклонному ходу, на верхних и нижних площадках, с интеграцией изображений на мониторы в данное помещение",
        ],
      },
      {
        code: "1000060",
        text: "Какой организацией определяется численность службы производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО, на котором используется эскалатор, и ее структура?",
        answers: [
          "Организацией, проводившей модернизацию (реконструкцию) или монтаж эскалатора",
          "Эксплуатирующей организацией",
          "Специализированной проектной организацией",
          "Лицензированной организацией, имеющей право на проведение экспертизы промышленной безопасности",
        ],
        correctAnswers: ["Эксплуатирующей организацией"],
      },
      {
        code: "1000061",
        text: "Какая из перечисленных записей не ставится в паспорте эскалатора уполномоченным лицом владельца ОПО, проводившим освидетельствование?",
        answers: [
          "Запись о результатах полного технического освидетельствования",
          "Запись о вводе эскалатора в эксплуатацию",
          "Запись о методах контроля и диагностики, применяемых в процессе эксплуатации эскалатора",
          "Запись о сроке очередного периодического технического освидетельствования",
        ],
        correctAnswers: [
          "Запись о методах контроля и диагностики, применяемых в процессе эксплуатации эскалатора",
        ],
      },
      {
        code: "1000062",
        text: "Кто осуществляет пуск эскалатора в работу?",
        answers: [
          "Работник, ответственный за содержание эскалаторов в исправном состоянии",
          "Мастер специализированной проектной организации",
          "Специалист, ответственный за осуществление производственного контроля в эксплуатирующей организации",
          "Машинист эскалатора или работник, имеющий право управления эскалатором в соответствии с производственной инструкцией",
        ],
        correctAnswers: [
          "Машинист эскалатора или работник, имеющий право управления эскалатором в соответствии с производственной инструкцией",
        ],
      },
      {
        code: "1000063",
        text: "В какие сроки должно проводиться периодическое техническое освидетельствование эскалатора после ввода его в эксплуатацию?",
        answers: [
          "Не реже одного раза в 12 месяцев",
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в 24 месяца",
        ],
        correctAnswers: ["Не реже одного раза в 12 месяцев"],
      },
      {
        code: "1000064",
        text: "В соответствии с каким документом должно проводиться техническое обслуживание и ремонт эскалатора?",
        answers: [
          "В соответствии с заключением специализированной организации, проводившей экспертизу промышленной безопасности",
          "В соответствии с проектной документацией завода-изготовителя",
          "В соответствии с руководством по эксплуатации эскалатора организации-изготовителя или специализированной организации",
          "В соответствии с инструкцией по охране труда при выполнении работ по обслуживанию эскалатора",
        ],
        correctAnswers: [
          "В соответствии с руководством по эксплуатации эскалатора организации-изготовителя или специализированной организации",
        ],
      },
      {
        code: "1000065",
        text: "В каких целях не проводится полное техническое освидетельствование эскалатора после изготовления (монтажа), реконструкции, модернизации?",
        answers: [
          'В целях подтверждения соответствия эксплуатации, содержания и обслуживания эскалатора требованиям федеральных норм и правил в области промышленной безопасности "Правила безопасности эскалаторов в метрополитенах"',
          'В целях подтверждения соответствия эскалатора и его устройства требованиям технического регламента "О безопасности машин и оборудования"',
          "В целях подтверждения технического состояния эскалатора, обеспечивающего его безопасное использование по назначению",
          'В целях подтверждения соответствия эскалатора и его устройства требованиям федеральных норм и правил в области промышленной безопасности "Правила безопасности эскалаторов в метрополитенах" и паспортным данным',
        ],
        correctAnswers: [
          'В целях подтверждения соответствия эскалатора и его устройства требованиям технического регламента "О безопасности машин и оборудования"',
        ],
      },
      {
        code: "1000066",
        text: "Какое положение по эксплуатации эскалатора не соответствует требованиям Правил безопасности эскалаторов в метрополитенах?",
        answers: [
          "Основанием для остановки на капитальный ремонт эскалатора является только фактическое техническое состояние эскалатора",
          "Техническое обслуживание должно включать в себя осмотр, смазку, замер износа, очистку, регулировку и замену узлов и деталей (по результатам осмотра и замеров)",
          "Ввод эскалатора в эксплуатацию после капитального ремонта, а также по окончании срока работы, установленного предыдущим техническим освидетельствованием, должен осуществлять владелец опасного производственного объекта при наличии квалифицированного персонала или специализированная организация после осмотра и проверки в объеме технического освидетельствования",
          "Техническое обслуживание должно проводиться в сроки, устанавливаемые в руководстве по эксплуатации или в инструкции по техническому обслуживанию",
        ],
        correctAnswers: [
          "Основанием для остановки на капитальный ремонт эскалатора является только фактическое техническое состояние эскалатора",
        ],
      },
      {
        code: "1000067",
        text: "В соответствии с какими нормативными документами должна осуществляться эксплуатация эскалатора?",
        answers: [
          "В соответствии с проектной документацией и инструкциями по охране труда",
          'В соответствии с "Правилами безопасности эскалаторов в метрополитена" и эксплуатационной документацией',
          "В соответствии с эксплуатационной и проектной документацией",
          'В соответствии с производственными инструкциями и "Правилами безопасности эскалаторов в метрополитенах"',
        ],
        correctAnswers: [
          'В соответствии с "Правилами безопасности эскалаторов в метрополитена" и эксплуатационной документацией',
        ],
      },
      {
        code: "1000068",
        text: "Каким образом определяются численность и структура службы производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО, на котором используется эскалатор?",
        answers: [
          "Численность и структура службы производственного контроля определяются исходя из квалификационных характеристик специалистов",
          "Численность и структура службы производственного контроля определяются исходя из класса опасности ОПО",
          "Численность и структура службы производственного контроля определяются организацией, эксплуатирующей эскалатор",
          "Численность и структура службы производственного контроля определяются исходя из результатов целевых проверок состояния промышленной безопасности и опасных производственных факторов на рабочих местах",
        ],
        correctAnswers: [
          "Численность и структура службы производственного контроля определяются организацией, эксплуатирующей эскалатор",
        ],
      },
      {
        code: "1000069",
        text: "В каком случае допускается эксплуатация эскалатора метрополитена?",
        answers: [
          "При условиях окружающей среды, не соответствующих требованиям эксплуатационной документации",
          "При истекшем сроке технического освидетельствования",
          "При отсутствии персонала, прошедшего проверку знаний в установленном порядке",
          "При отсутствии трещин в металлоконструкции и элементах оборудования",
        ],
        correctAnswers: [
          "При отсутствии трещин в металлоконструкции и элементах оборудования",
        ],
      },
      {
        code: "1000070",
        text: "Какой документ составляется по результатам работы комиссии?",
        answers: [
          "Акт о возможности ввода в эксплуатацию эскалатора",
          "Акт о положительном заключении готовности эскалатора к вводу в эксплуатацию",
          "Акт приемо-сдаточных испытаний эскалатора",
          "Акт технической готовности эскалатора",
        ],
        correctAnswers: ["Акт о возможности ввода в эксплуатацию эскалатора"],
      },
      {
        code: "1000071",
        text: "Какие из перечисленных документов должны быть в наличии у владельца ОПО до начала применения эскалатора?",
        answers: [
          "Только протокол осмотра и проверки элементов заземления (зануления) оборудования, включая балюстраду, выполненную из металлических листов",
          "Только протокол проверки сопротивления изоляции силового электрооборудования, цепей управления и сигнализации, силовой и осветительной электропроводки",
          "Только паспорт эскалатора и руководство по эксплуатации",
          "Только протокол маркшейдерских замеров установки направляющих лестничного полотна",
          "Все перечисленные, а также акт освидетельствования скрытых работ и акт технической готовности",
        ],
        correctAnswers: [
          "Все перечисленные, а также акт освидетельствования скрытых работ и акт технической готовности",
        ],
      },
      {
        code: "1000072",
        text: "Какая организация обязана обеспечить содержание эскалатора в исправном состоянии путем организации обслуживания, ремонта, технического освидетельствования?",
        answers: [
          "Организация, выполнившая изготовление эскалатора",
          "Организация, эксплуатирующая эскалатор, самостоятельно либо с привлечением специализированной организации",
          "Организация, занимающаяся модернизацией или реконструкцией эскалатора",
          "Организация, выполнившая монтаж эскалатора",
        ],
        correctAnswers: [
          "Организация, эксплуатирующая эскалатор, самостоятельно либо с привлечением специализированной организации",
        ],
      },
      {
        code: "1000073",
        text: "Каким образом необходимо проводить обкатку модернизированного или реконструированного эскалатора, или эскалатора после проведенного капитального (капитально-восстановительного) ремонта?",
        answers: [
          "В течение первых 6 часов от вспомогательного привода, а следующих 6 часов - от главного привода",
          "В течение 48 часов непрерывной работы от главного привода, по 24 часа в каждом направлении",
          "В течение 12 часов непрерывной работы от главного привода, по 6 часов в каждую сторону",
          "В течение 24 часов непрерывной работы от главного привода, по 12 часов в каждую сторону",
        ],
        correctAnswers: [
          "В течение 12 часов непрерывной работы от главного привода, по 6 часов в каждую сторону",
        ],
      },
      {
        code: "1000074",
        text: "Какие действия необходимо предпринять при возникновении неисправностей, представляющих опасность при пользовании эскалатором?",
        answers: [
          "Эскалатор должен быть остановлен только после спуска (подъема) оставшихся на нем пассажиров",
          "Эскалатор должен быть остановлен, пассажиры не должны покидать эскалатор до устранения неисправности",
          "Эскалатор должен быть остановлен, а пассажиры с него удалены",
        ],
        correctAnswers: [
          "Эскалатор должен быть остановлен, а пассажиры с него удалены",
        ],
      },
      {
        code: "1000075",
        text: "Каким образом не может быть установлен объем текущего, среднего и капитального ремонта?",
        answers: [
          "В порядке технического освидетельствования",
          "В порядке нерегламентированного технического обслуживания",
          "В порядке планового технического осмотра",
          "В порядке экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "В порядке нерегламентированного технического обслуживания",
        ],
      },
      {
        code: "1000076",
        text: "В каком объеме должны проводиться осмотр и проверка эскалатора при вводе его в эксплуатацию после капитального ремонта?",
        answers: [
          "Внеочередного технического обслуживания",
          "Планового технического осмотра",
          "Технического освидетельствования",
        ],
        correctAnswers: ["Технического освидетельствования"],
      },
      {
        code: "1000077",
        text: "Каким требованиям должен соответствовать персонал, допущенный к управлению эскалатором в метрополитене?",
        answers: [
          "Персонал должен иметь опыт работы в сфере управления эскалатором не менее 3 лет",
          "Персонал должен иметь на руках производственную инструкцию и инструкцию по эксплуатации",
          "Персонал должен быть не моложе 16 лет",
          "Персонал должен быть квалифицированным, достигшим возраста 18 лет, не имеющим медицинских противопоказаний и прошедшим проверку знаний",
        ],
        correctAnswers: [
          "Персонал должен быть квалифицированным, достигшим возраста 18 лет, не имеющим медицинских противопоказаний и прошедшим проверку знаний",
        ],
      },
      {
        code: "1000078",
        text: "Какое значение не должна превышать стрела упругого прогиба плит перекрытия между входными площадками эскалатора и строительными конструкциями под действием нагрузки qc и массы строительного покрытия?",
        answers: [
          "1/600 пролета",
          "1/500 пролета",
          "1/800 пролета",
          "1/1000 пролета",
        ],
        correctAnswers: ["1/600 пролета"],
      },
      {
        code: "1000079",
        text: "Какое значение не должна превышать стрела упругого прогиба направляющих бегунков под действием нагрузки qМЭ?",
        answers: [
          "1/1000 величины пролета",
          "1/800 величины пролета",
          "1/600 величины пролета",
          "1/500 величины пролета",
        ],
        correctAnswers: ["1/1000 величины пролета"],
      },
      {
        code: "1000080",
        text: "Каким должен быть тормозной путь (в метрах) движущегося на спуск лестничного полотна эскалатора при торможении рабочим(и) тормозом (тормозами) с нагрузкой qмэ?",
        answers: [
          "Не более 2,4V",
          "Не более 1,2V",
          "Не более 3,6V",
          "Не более 4,5V",
        ],
        correctAnswers: ["Не более 2,4V"],
      },
      {
        code: "1000081",
        text: "Каким приложением однократного усилия проверяют прочность изготовленных ступеней эскалатора выборочно или каждой отремонтированной ступени?",
        answers: ["3000 Н", "1000 Н", "2000 Н", "5000 Н"],
        correctAnswers: ["3000 Н"],
      },
      {
        code: "1000082",
        text: "Какая допускается остаточная деформация подступенка эскалатора при испытании?",
        answers: [
          "Остаточная деформация не допускается",
          "1 мм",
          "4 мм",
          "2 мм",
        ],
        correctAnswers: ["Остаточная деформация не допускается"],
      },
      {
        code: "1000083",
        text: "Какая должна быть ширина свободного прохода между выступающими частями смежных эскалаторов и их ограждениями, а также ширина боковых проходов у крайних эскалаторов при высоте не менее 1800 мм?",
        answers: [
          "Не менее 500 мм",
          "Не менее 300 мм",
          "Не менее 700 мм",
          "Не менее 900 мм",
        ],
        correctAnswers: ["Не менее 500 мм"],
      },
      {
        code: "1000084",
        text: "Какая должна быть минимальная ширина свободного прохода между натяжными устройствами и в местах между приводами (у главного приводного вала и между фундаментами) эскалатора?",
        answers: ["400 мм", "500 мм", "700 мм", "900 мм"],
        correctAnswers: ["400 мм"],
      },
      {
        code: "1000085",
        text: "Каким должен быть угол наклона входной лестницы в машинное помещение?",
        answers: [
          "Не более 45°",
          "Не более 30°",
          "Не более 60°",
          "Не более 25°",
        ],
        correctAnswers: ["Не более 45°"],
      },
      {
        code: "1000086",
        text: "Какая площадка должна быть предусмотрена перед входом на эскалатор от выступающего оборудования (барьеров, кабин)?",
        answers: [
          "Шириной не менее расстояния между наружными краями поручней плюс 80 мм с каждой стороны и глубиной не менее 4,50 м от конца балюстрады",
          "Шириной не менее расстояния между наружными краями поручней плюс 60 мм с каждой стороны и глубиной не менее 4 м от конца балюстрады",
          "Шириной не менее расстояния между наружными краями поручней плюс 80 мм с каждой стороны и глубиной не менее 2,50 м от конца балюстрады",
          "Шириной не менее расстояния между наружными краями поручней плюс 100 мм с каждой стороны и глубиной не менее 5,50 м от конца балюстрады",
        ],
        correctAnswers: [
          "Шириной не менее расстояния между наружными краями поручней плюс 80 мм с каждой стороны и глубиной не менее 4,50 м от конца балюстрады",
        ],
      },
      {
        code: "1000087",
        text: "Какое должно быть расстояние по вертикали от уровня настила ступеней эскалатора до потолка галереи, тоннеля или выступающих частей (балок, архитектурных украшений, осветительной арматуры), измеряемое у края ступени со стороны, примыкающей к стене тоннеля, для круглых наклонных тоннелей?",
        answers: [
          "Не менее 2000 мм",
          "Не менее 1500 мм",
          "Не менее 2500 мм",
          "Не менее 3000 мм",
        ],
        correctAnswers: ["Не менее 2000 мм"],
      },
      {
        code: "1000088",
        text: "На каком расстоянии должны быть установлены штепсельные розетки по эскалаторному тоннелю?",
        answers: [
          "Не более 20 м друг от друга",
          "Не более 30 м друг от друга",
          "Не более 40 м друг от друга",
          "Не более 50 м друг от друга",
        ],
        correctAnswers: ["Не более 20 м друг от друга"],
      },
      {
        code: "1000089",
        text: "Что не проверяется при грузовых испытаниях для эскалаторов высотой подъема 6 м и менее?",
        answers: [
          "Срабатывание дополнительного (аварийного) тормоза в случаях, предусмотренных пунктом 68 ФНП, а также возможность эффективной остановки и удержания в неподвижном состоянии несущего полотна эскалатора с максимальной эксплуатационной нагрузкой аварийным тормозом",
          "Ускорение несущего полотна при пуске",
          "Возможность пуска и работа на номинальной скорости в любом направлении с максимальной эксплуатационной нагрузкой",
          "Тормозные пути рабочего тормоза, замедление при торможении, требование по равномерному распределению тормозного момента между тормозами при использовании двух и более рабочих тормозов",
        ],
        correctAnswers: [
          "Срабатывание дополнительного (аварийного) тормоза в случаях, предусмотренных пунктом 68 ФНП, а также возможность эффективной остановки и удержания в неподвижном состоянии несущего полотна эскалатора с максимальной эксплуатационной нагрузкой аварийным тормозом",
        ],
      },
      {
        code: "1000090",
        text: "Какие эскалаторы тяжелого режима работы должны использоваться в метрополитенах?",
        answers: [
          "С номинальной скоростью не более 0,75 м/с, шириной лестничного полотна 1000 ± 10 мм и углом наклона не более 30°",
          "С номинальной скоростью не более 0,50 м/с, шириной лестничного полотна 1000 ± 10 мм и углом наклона не более 30°",
          "С номинальной скоростью не более 1,05 м/с, шириной лестничного полотна 900 ± 10 мм и углом наклона не более 30°",
          "С номинальной скоростью не более 0,75 м/с, шириной лестничного полотна 900 ± 10 мм и углом наклона не более 30°",
        ],
        correctAnswers: [
          "С номинальной скоростью не более 0,75 м/с, шириной лестничного полотна 1000 ± 10 мм и углом наклона не более 30°",
        ],
      },
      {
        code: "1000091",
        text: "От какого количества независимых источников питания должно осуществляться электроснабжение комплекса (блока) эскалаторов?",
        answers: [
          "Не менее чем от двух",
          "Не менее чем от одного",
          "Не менее чем от трех",
          "Не менее чем от четырех",
        ],
        correctAnswers: ["Не менее чем от двух"],
      },
      {
        code: "1000092",
        text: "Какой должен быть расчетный запас прочности тяговой цепи эскалатора?",
        answers: ["Не менее 7", "Не менее 5", "Не менее 3", "Не менее 9"],
        correctAnswers: ["Не менее 7"],
      },
      {
        code: "1000093",
        text: "При перемещении на какое расстояние натяжного устройства в сторону привода или в обратном направлении эскалатор должен останавливаться?",
        answers: [
          "Не более 30 мм",
          "Не более 40 мм",
          "Не более 50 мм",
          "Не более 60 мм",
        ],
        correctAnswers: ["Не более 30 мм"],
      },
      {
        code: "1000094",
        text: "Какой нагрузкой нагружают две ступени эскалатора от изготавливаемой партии для проверки фактического запаса прочности?",
        answers: [
          "Не менее 5qмэ",
          "Не менее 3qмэ",
          "Не менее 2qмэ",
          "Не менее 7qмэ",
        ],
        correctAnswers: ["Не менее 5qмэ"],
      },
      {
        code: "1000095",
        text: "Какая допускается остаточная деформация ступеней эскалатора, измеренной у поверхности настила, после испытаний?",
        answers: [
          "Не более 4 мм",
          "Не более 8 мм",
          "Не более 10 мм",
          "Не более 15 мм",
        ],
        correctAnswers: ["Не более 4 мм"],
      },
      {
        code: "1000096",
        text: "Какое должно быть общее время электрического торможения до срабатывания рабочего тормоза эскалатора?",
        answers: [
          "Не более 4 с",
          "Не более 7 с",
          "Не более 9 с",
          "Не более 12 с",
        ],
        correctAnswers: ["Не более 4 с"],
      },
      {
        code: "1000097",
        text: "При каком замедлении лестничного полотна при торможении допускается совместное срабатывание дополнительного (аварийного) и рабочего тормозов?",
        answers: [
          "Не более 1 м/с²",
          "Не более 3 м/с²",
          "Не более 0,75 м/с²",
          "Не более 0,5 м/с²",
        ],
        correctAnswers: ["Не более 1 м/с²"],
      },
      {
        code: "1000098",
        text: "Сколько должна составлять общая тормозная нагрузка для обеспечения пути разгона и торможения лестничного полотна при испытаниях тормозной системы эскалатора?",
        answers: [
          "Не более 300 кг на ступень",
          "Не более 200 кг на ступень",
          "Не более 400 кг на ступень",
          "Не более 500 кг на ступень",
        ],
        correctAnswers: ["Не более 300 кг на ступень"],
      },
      {
        code: "1000099",
        text: "Какой должна быть освещенность входных площадок эскалатора на уровне пола, измеренная по оси лестничного полотна?",
        answers: [
          "Не менее 50 лк",
          "Не менее 30 лк",
          "Не менее 70 лк",
          "Не менее 100 лк",
        ],
        correctAnswers: ["Не менее 50 лк"],
      },
      {
        code: "1000100",
        text: "Какой допускается зазор в стыках щитов и фартуков балюстрады эскалатора?",
        answers: [
          "Не более 4 мм",
          "Не более 2 мм",
          "Не более 6 мм",
          "Не более 10 мм",
        ],
        correctAnswers: ["Не более 4 мм"],
      },
      {
        code: "1000101",
        text: "В каком случае на балюстраде эскалатора разрешается установка решеток для громкоговорящей связи, осветителей и розеток?",
        answers: [
          "По согласованию с разработчиком эскалатора",
          "По согласованию с организацией, выполняющей монтаж эскалатора",
          "По согласованию с эксплуатирующей организацией",
          "По согласованию с испытательной лабораторией",
        ],
        correctAnswers: ["По согласованию с разработчиком эскалатора"],
      },
      {
        code: "1000102",
        text: "Каким должен быть коэффициент запаса прочности поручня эскалатора?",
        answers: ["Не менее 5", "Не менее 3", "Не менее 7", "Не менее 9"],
        correctAnswers: ["Не менее 5"],
      },
      {
        code: "1000103",
        text: "В каком случае допускается пуск эскалатора с пассажирами с любого пульта управления в обратном направлении?",
        answers: [
          "Не допускается ни в каком случае",
          "В случае возникновения аварийной ситуации",
          "В случае перегруза ступеней эскалатора",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "1000104",
        text: "С какой скоростью должны двигаться, а затем ускоряться эскалаторы, работающие в режиме ожидания, при пересечении пассажиром гребенки?",
        answers: [
          "Со скоростью, составляющей не менее 20 % номинальной скорости, а затем ускоряться до номинальной скорости с ускорением не более 0,5 м/с²",
          "Со скоростью, составляющей не менее 10 % номинальной скорости, а затем ускоряться до номинальной скорости с ускорением не более 0,5 м/с²",
          "Со скоростью, составляющей не менее 20 % номинальной скорости, а затем ускоряться до номинальной скорости с ускорением не более 1,5 м/с²",
          "Со скоростью, составляющей не менее 15 % номинальной скорости, а затем ускоряться до номинальной скорости с ускорением не более 1,0 м/с²",
        ],
        correctAnswers: [
          "Со скоростью, составляющей не менее 20 % номинальной скорости, а затем ускоряться до номинальной скорости с ускорением не более 0,5 м/с²",
        ],
      },
      {
        code: "1000105",
        text: "Какое из перечисленных эскалаторных помещений может не предусматриваться?",
        answers: [
          "Помещение неотложной помощи",
          "Помещения для установки привода (машинное помещение), электрооборудования, аппаратуры управления и телемеханики",
          "Помещение для установки натяжного устройства (натяжная камера)",
          "Демонтажные проходы (демонтажные шахты и демонтажная камера)",
        ],
        correctAnswers: ["Помещение неотложной помощи"],
      },
      {
        code: "1000106",
        text: "Кем проводятся грузовые испытания на эскалаторе? Выберите 2 варианта ответа.",
        answers: [
          "Разработчиком/изготовителем эскалатора",
          "Испытательной лабораторией",
          "Организацией, выполнившей монтаж эскалатора",
          "Эксплуатирующей организацией",
        ],
        correctAnswers: [
          "Разработчиком/изготовителем эскалатора",
          "Испытательной лабораторией",
        ],
      },
      {
        code: "1000107",
        text: "Какие из перечисленных представителей должны включаться в состав комиссии при проведении грузовых испытаний эскалатора? Выберите 2 варианта ответа.",
        answers: [
          "Представитель заказчика",
          "Представитель организации, осуществляющей транспортировку эскалатора",
          "Представитель эксплуатирующей организации",
          "Представитель местного управления пожарной охраны",
        ],
        correctAnswers: [
          "Представитель заказчика",
          "Представитель эксплуатирующей организации",
        ],
      },
      {
        code: "1000108",
        text: "Какая устанавливается минимальная ширина свободного прохода между фундаментами или выступающими частями привода эскалатора и стенами машинного помещения (или оборудованием и коммуникациями, размещенными на стенах машинного помещения), торцевой стеной натяжной камеры (или оборудованием и коммуникациями, размещенными на стенах натяжной камеры)?",
        answers: ["900 мм", "700 мм", "500 мм", "1000 мм"],
        correctAnswers: ["900 мм"],
      },
    ],
    710: [
      {
        code: "69190",
        text: 'На какие из перечисленных ниже опасные производственные объекты (далее - ОПО) не распространяются требования федеральных норм и правил в области промышленной безопасности "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения" (далее - ФНП ПС)?',
        answers: [
          "На ОПО, где эксплуатируются грузоподъемные краны",
          "На ОПО, где эксплуатируются строительные подъемники",
          "На ОПО, где эксплуатируются канатные дороги",
          "На ОПО, где эксплуатируются подъемники (вышки)",
          "На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления",
        ],
        correctAnswers: ["На ОПО, где эксплуатируются канатные дороги"],
      },
      {
        code: "69191",
        text: "На какие из перечисленных ОПО распространяются требования ФНП ПС?",
        answers: [
          "На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления",
          "На ОПО, где эксплуатируются ПС, применяемые в интересах обороны и безопасности государства",
          "На ОПО, где эксплуатируются ПС, установленные в шахтах и на любых плавучих средствах",
          "На ОПО, где в работе применяются эскалаторы",
          "На ОПО, где эксплуатируются краны для подъема створов (затворов) плотин без осуществления зацепления их крюками",
        ],
        correctAnswers: [
          "На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления",
        ],
      },
      {
        code: "69192",
        text: 'Каким нормативным правовым актом регламентируются обязательные для применения требования для ПС, введенных в обращение до вступления в силу Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"?',
        answers: [
          "Ранее действующими правилами устройства и безопасной эксплуатации ПС Госгортехнадзора России для проектирования и изготовления этих ПС, а для остальных стадий жизненного цикла ПС - Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения",
          "Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения, для всех стадий жизненного цикла этих ПС",
          "Методическими рекомендациями по экспертному обследованию грузоподъемных машин. Часть 2. Краны стреловые общего назначения и краны-манипуляторы грузоподъемные (РД 10-112-2-09)",
          'Федеральным законом "О промышленной безопасности опасных производственных объектов" от 21 июля 1997 г. N 116-ФЗ',
        ],
        correctAnswers: [
          "Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения, для всех стадий жизненного цикла этих ПС",
        ],
      },
      {
        code: "69193",
        text: "Требованиям какого документа должны соответствовать общие требования к транспортировке и хранению ПС, их отдельных сборочных единиц, материалов и комплектующих для их ремонта, реконструкции и (или) модернизации?",
        answers: [
          "Технических условий ПС",
          "Руководства (инструкции) по эксплуатации ПС",
          "Паспорта ПС",
          "Формуляра ПС",
        ],
        correctAnswers: ["Руководства (инструкции) по эксплуатации ПС"],
      },
      {
        code: "69194",
        text: "Какие из перечисленных ПС не подлежат учету в органах Ростехнадзора?",
        answers: [
          "Автомобильные краны",
          "Краны мостового типа",
          "Краны на железнодорожном ходу",
          "Краны-трубоукладчики",
        ],
        correctAnswers: ["Краны-трубоукладчики"],
      },
      {
        code: "69195",
        text: "Какие из перечисленных ПС подлежат учету в органах Ростехнадзора?",
        answers: [
          "Краны стрелового типа грузоподъемностью до 1 т включительно",
          "Переставные краны для монтажа мачт, башен, труб, устанавливаемые на монтируемом сооружении",
          "Краны стрелового типа с постоянным вылетом или не снабженные механизмом поворота",
          "Подъемники и вышки, предназначенные для перемещения людей",
        ],
        correctAnswers: [
          "Подъемники и вышки, предназначенные для перемещения людей",
        ],
      },
      {
        code: "69196",
        text: "Имеет ли право организация, эксплуатирующая ОПО с ПС, привлекать специалистов сторонних организаций в качестве: специалиста, ответственного за осуществление производственного контроля при эксплуатации ПС; специалиста, ответственного за содержание ПС в работоспособном состоянии; специалиста, ответственного за безопасное производство работ с применением ПС?",
        answers: [
          "Имеет право привлекать всех указанных специалистов",
          "Имеет право привлекать специалиста, ответственного за содержание ПС в работоспособном состоянии, и специалиста, ответственного за безопасное производство работ с применением ПС",
          "Имеет право привлекать только специалиста, ответственного за безопасное производство работ с применением ПС",
          "Имеет право привлекать только специалиста, ответственного за содержание ПС в работоспособном состоянии",
          "Не имеет права",
        ],
        correctAnswers: ["Не имеет права"],
      },
      {
        code: "69197",
        text: "Какие обязанности организации, эксплуатирующей подъемные сооружения (далее – ПС), указаны неверно?",
        answers: [
          "Устанавливать порядок контроля обучения и периодической проверки знаний персонала, работающего с ограничителями, указателями и регистраторами",
          "Обеспечивать соблюдение технологического процесса транспортировки грузов и приостановку работы ПС в случае возникновения угрозы аварийной ситуации",
          "Организовывать считывание данных с регистратора параметров работы ПС не реже сроков, указанных в руководстве (инструкции) по эксплуатации регистратора",
          "При отсутствии в эксплуатационных документах регистраторов указаний о сроках считывания данных выполнять такие операции не реже одного раза в год",
          "Все перечисленные",
        ],
        correctAnswers: [
          "При отсутствии в эксплуатационных документах регистраторов указаний о сроках считывания данных выполнять такие операции не реже одного раза в год",
        ],
      },
      {
        code: "69198",
        text: "Какие мероприятия должна выполнять эксплуатирующая организация для содержания ПС в работоспособном состоянии и обеспечения безопасных условий их работы?",
        answers: [
          "Только устанавливать порядок периодических осмотров, технических обслуживаний и ремонтов, обеспечивающих содержание ПС, рельсовых путей, грузозахватных органов, приспособлений и тары в работоспособном состоянии",
          "Только разрабатывать и утверждать журналы, программы, графики выполнения планово-предупредительных ремонтов, ППР, ТК, схемы строповки и складирования, должностные инструкции для инженерно-технических работников, а также производственные инструкции для персонала, на основе паспорта, руководства (инструкции) по эксплуатации конкретного ПС, с учетом особенностей технологических процессов, установленных проектной и технологической документацией",
          "Только обеспечивать наличие у инженерно-технических работников должностных инструкций и руководящих указаний по безопасной эксплуатации ПС, а у персонала - производственных инструкций и создавать условия выполнения инженерно-техническими работниками требований настоящих ФНП, должностных инструкций, а персоналом - производственных инструкций",
          "Только обеспечивать установленный порядок проверки знаний и допуска к самостоятельной работе персонала с выдачей удостоверений, в которых указывается тип ПС, а также виды работ и оборудования, к работам на которых они допущены",
          "Все перечисленные мероприятия",
        ],
        correctAnswers: ["Все перечисленные мероприятия"],
      },
      {
        code: "69199",
        text: "Каким документом должна определяться численность инженерно-технических работников эксплуатирующей организации?",
        answers: [
          "Внутренним распорядительным актом эксплуатирующей организации",
          "Предписанием Ростехнадзора",
          "Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения",
          "Технологическим регламентом",
        ],
        correctAnswers: [
          "Внутренним распорядительным актом эксплуатирующей организации",
        ],
      },
      {
        code: "69200",
        text: "Какие требования, установленные для специализированной организации, осуществляющей деятельность по монтажу (демонтажу), наладке, ремонту, реконструкции или модернизации ПС в процессе эксплуатации ОПО, указаны верно?",
        answers: [
          "Только определять процедуры контроля соблюдения технологических процессов",
          "Только устанавливать ответственность, полномочия и взаимоотношения работников, занятых в управлении, выполнении или проверке выполнения работ",
          "Только располагать необходимым персоналом, а также руководителями и специалистами, имеющими полномочия, необходимые для выполнения своих обязанностей, в том числе выявления случаев отступлений от требований к качеству работ, от процедур проведения работ, и для принятия мер по предупреждению или сокращению таких отступлений",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "69201",
        text: "Кого относят к работникам специализированных организаций, занимающихся выполнением работ по монтажу (демонтажу), наладке либо ремонту, реконструкции или модернизации в процессе эксплуатации ПС?",
        answers: [
          "Инженерно-технических работников, имеющих высшее или среднее профессиональное образование, и персонал - лиц рабочих профессий, непосредственно занятых на выполнении работ",
          "Сотрудников сторонних организаций, прошедших обучение по охране труда в учебном центре",
          "Аттестованных инженерно-технических работников",
          "Физических лиц основных служб организации, непосредственно занятых на выполнении работ",
          "Всех сотрудников организации, эксплуатирующей ПС",
        ],
        correctAnswers: [
          "Инженерно-технических работников, имеющих высшее или среднее профессиональное образование, и персонал - лиц рабочих профессий, непосредственно занятых на выполнении работ",
        ],
      },
      {
        code: "69202",
        text: "Кто должен выполнять работы на регистраторах, ограничителях и указателях ПС?",
        answers: [
          "Работники, прошедшие обучение и проверку знаний в учебном центре по работам на регистраторах, ограничителях и указателях ПС",
          "Работники организации, эксплуатирующей ПС, прошедшие аттестацию и проверку знаний по промышленной безопасности",
          "Работники специализированных организаций, квалификация которых соответствует требованиям изготовителей (разработчиков), изложенным в эксплуатационных документах ПС, регистраторов, ограничителей и указателей",
          "Работники специализированных организаций, имеющих допуск на производство работ на регистраторах, ограничителях и указателях ПС, выданный заводами-изготовителями",
        ],
        correctAnswers: [
          "Работники специализированных организаций, квалификация которых соответствует требованиям изготовителей (разработчиков), изложенным в эксплуатационных документах ПС, регистраторов, ограничителей и указателей",
        ],
      },
      {
        code: "69203",
        text: "Куда вносятся отметки о монтаже и наладке ограничителя, указателя и регистратора ПС? Укажите все правильные ответы.",
        answers: [
          "В заключение экспертизы промышленной безопасности",
          "В паспорт ограничителя, указателя или регистратора",
          "В эксплуатационный журнал ПС",
          "В паспорт ПС",
          "В руководство по эксплуатации",
        ],
        correctAnswers: [
          "В паспорт ограничителя, указателя или регистратора",
          "В паспорт ПС",
        ],
      },
      {
        code: "69204",
        text: "На какую организацию возлагается ответственность за эксплуатацию ПС, не оборудованного ограничителями, указателями и регистраторами, необходимыми для обеспечения промышленной безопасности технологического процесса, в котором используется ПС?",
        answers: [
          "На экспертную организацию, проводившую экспертизу промышленной безопасности ПС",
          "На сертификационный центр и испытательную лабораторию, выдавших сертификат/декларацию соответствия ПС",
          "На эксплуатирующую ПС организацию",
          "На специализированную организацию, выполнившую ремонт и реконструкцию ПС",
          "На территориальный орган Ростехнадзора",
        ],
        correctAnswers: ["На эксплуатирующую ПС организацию"],
      },
      {
        code: "69205",
        text: "В какой документации устанавливаются нормы, согласно которым определяется качество ремонтных сварных соединений ПС?",
        answers: [
          "В проекте ремонта, реконструкции или модернизации ПС",
          "В эксплуатационной документации",
          "Во всей перечисленной документации",
        ],
        correctAnswers: ["Во всей перечисленной документации"],
      },
      {
        code: "69206",
        text: "С какой периодичностью должно осуществляться проведение плановых ремонтов ПС? Укажите все правильные ответы.",
        answers: [
          "После наработки определенного числа машино-часов (циклов) согласно руководству по эксплуатации ПС",
          "Не реже 1 раза в 3 года",
          "Через установленный инструкцией по эксплуатации ПС интервал времени",
          "Внепланово по распоряжению технического руководителя организации, эксплуатирующей ПС",
          "После наработки 1500 машино-часов (циклов)",
        ],
        correctAnswers: [
          "После наработки определенного числа машино-часов (циклов) согласно руководству по эксплуатации ПС",
          "Через установленный инструкцией по эксплуатации ПС интервал времени",
        ],
      },
      {
        code: "69207",
        text: "Чем запрещается оснащать краны, в зоне работы которых находятся производственные или другие помещения?",
        answers: [
          "Системой радиоуправления",
          "Тяговой лебедкой",
          "Механизмами блокировки (стабилизаторами) колес заднего моста",
          "Грузовым электромагнитом",
        ],
        correctAnswers: ["Грузовым электромагнитом"],
      },
      {
        code: "69208",
        text: "Кто разрабатывает проект для реконструкции или модернизации ограничителя, указателя или регистратора в случае, когда изготовителя ПС установить невозможно?",
        answers: [
          "Территориальный орган Ростехнадзора",
          "Специализированная организация",
          "Эксплуатирующая организация",
          "Организация - собственник ПС",
        ],
        correctAnswers: ["Специализированная организация"],
      },
      {
        code: "69209",
        text: "Кто дает разрешение на пуск ПС в работу после окончания ремонта, реконструкции или модернизации ограничителя, указателя или регистратора?",
        answers: [
          "Специалист, ответственный за осуществление производственного контроля при эксплуатации ПС",
          "Руководитель организации, эксплуатирующей ПС",
          "Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии",
          "Специалист организации, выполнившей работы по ремонту, реконструкции или модернизации ограничителя, указателя или регистратора",
        ],
        correctAnswers: [
          "Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии",
        ],
      },
      {
        code: "69210",
        text: "Какая организация имеет право вносить изменения в разработанный проект производства работ (далее – ППР) ПС для выполнения строительно-монтажных работ?",
        answers: [
          "Только эксплуатирующая организация",
          "Только организация - разработчик ППР",
          "Только проектная организация",
          "Только специализированная экспертная организация",
          "Только специализированная организация",
        ],
        correctAnswers: ["Только организация - разработчик ППР"],
      },
      {
        code: "69211",
        text: "В соответствии с требованиями каких документов должна проводиться установка ПС в зданиях, на открытых площадках и других участках производства работ? Выберите 2 варианта ответа.",
        answers: [
          "Руководства (инструкции) по эксплуатации ПС",
          "Технологического регламента",
          "Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения",
          "ППР",
        ],
        correctAnswers: [
          "Руководства (инструкции) по эксплуатации ПС",
          "Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения",
        ],
      },
      {
        code: "69212",
        text: "При установке опорных мостовых кранов с какой фактической группой классификации (режима) в пролетах зданий должны быть устроены галереи для прохода вдоль рельсового пути с обеих сторон пролета?",
        answers: ["A4 и более", "A6 и более", "А3 и более", "А5 и более"],
        correctAnswers: ["A6 и более"],
      },
      {
        code: "69213",
        text: "На сколько выше встречающихся на пути предметов и оборудования должны находиться стрелы кранов при их повороте или перемещении?",
        answers: [
          "Не менее чем на 300 мм",
          "Не менее чем на 400 мм",
          "Не менее чем на 500 мм",
          "Не менее чем на 600 мм",
        ],
        correctAnswers: ["Не менее чем на 500 мм"],
      },
      {
        code: "69214",
        text: "Какое из перечисленных требований к люку (проему) для подъема и опускания грузов, находящегося в перекрытии производственного помещения, указано неверно?",
        answers: [
          "Должен иметь постоянное ограждение высотой не менее 1,1 м",
          "Обязательное устройство световой сигнализации",
          "Должен иметь сплошное ограждение понизу на высоту 0,15 м",
          "Должен иметь надписи габаритов проема",
        ],
        correctAnswers: ["Должен иметь надписи габаритов проема"],
      },
      {
        code: "69215",
        text: "При каком положении крана на надземном рельсовом пути следует проверять соответствие расстояния от выступающих частей торцов крана до колонн, стен здания и перил проходных галерей?",
        answers: [
          "При положении крана, когда колеса одной из концевых балок максимально смещены в поперечном направлении относительно рельса",
          "При положении крана, которое соответствует наибольшему уширению колеи рельсового пути в зоне, обслуживаемой краном",
          "При фактическом расположении колес крана относительно рельса во время проведения измерений",
          "При симметричном расположении колес крана относительно рельса",
        ],
        correctAnswers: [
          "При симметричном расположении колес крана относительно рельса",
        ],
      },
      {
        code: "69216",
        text: "Какое расстояние установлено от верхней точки крана, передвигающегося по надземному рельсовому пути, до потолка здания или предметов конструкции здания над краном?",
        answers: [
          "Не менее 100 мм",
          "Не менее 80 мм",
          "Не менее 60 мм",
          "Не менее 40 мм",
        ],
        correctAnswers: ["Не менее 100 мм"],
      },
      {
        code: "69217",
        text: "Какое расстояние установлено от нижней точки крана (не считая грузозахватного органа), передвигающегося по надземному рельсовому пути, до пола цеха или площадок, на которых во время работы крана могут находиться люди (за исключением площадок, предназначенных для ремонта крана)?",
        answers: [
          "Не менее 2000 мм",
          "Не менее 1800 мм",
          "Не менее 1600 мм",
          "Не менее 2500 мм",
        ],
        correctAnswers: ["Не менее 2000 мм"],
      },
      {
        code: "69218",
        text: "Какое расстояние установлено от нижних выступающих частей крана (не считая грузозахватного органа), передвигающегося по надземному рельсовому пути, до расположенного в зоне действия оборудования?",
        answers: [
          "Не менее 400 мм",
          "Не менее 350 мм",
          "Не менее 300 мм",
          "Не менее 250 мм",
        ],
        correctAnswers: ["Не менее 400 мм"],
      },
      {
        code: "69219",
        text: "Какое расстояние установлено по горизонтали между выступающими частями крана, передвигающегося по наземному крановому пути, и штабелями грузов, расположенными на высоте до 2000 мм от уровня рабочих площадок?",
        answers: [
          "Не менее 200 мм",
          "Не менее 400 мм",
          "Не менее 500 мм",
          "Не менее 700 мм",
        ],
        correctAnswers: ["Не менее 700 мм"],
      },
      {
        code: "69220",
        text: "Какое расстояние установлено по вертикали от консоли противовеса башенного крана до площадок, на которых могут находиться люди?",
        answers: [
          "Не менее 1000 мм",
          "Не менее 1700 мм",
          "Не менее 1500 мм",
          "Не менее 2000 мм",
        ],
        correctAnswers: ["Не менее 2000 мм"],
      },
      {
        code: "69221",
        text: "На каком расстоянии от элементов здания, оборудования и штабелей грузов следует устанавливать электрические тали и монорельсовые тележки с автоматическим или полуавтоматическим управлением, если во время движения указанные ПС не сопровождаются оператором?",
        answers: [
          "В соответствии с требованиями инструкции по эксплуатации",
          "Таким образом, чтобы во время движения исключить возможность задевания грузом элементов здания, оборудования и штабелей грузов",
          "Только с учетом максимальных габаритов транспортируемых грузов",
          "С учетом максимальных габаритов транспортируемых грузов и ширины прохода вдоль цеха для работников ОПО, если такой проход предусмотрен",
          "На расстоянии не менее 1500 мм",
        ],
        correctAnswers: [
          "Таким образом, чтобы во время движения исключить возможность задевания грузом элементов здания, оборудования и штабелей грузов",
        ],
      },
      {
        code: "69222",
        text: "С кем следует согласовывать установку кранов, передвигающихся по рельсовому пути, в охранной зоне воздушных линий (далее – ВЛ) электропередачи?",
        answers: [
          "С органом муниципального управления, по территории которого проходит ВЛ",
          "С организацией, эксплуатирующей ВЛ",
          "С владельцем линии",
          "С территориальным органом Ростехнадзора",
        ],
        correctAnswers: ["С владельцем линии"],
      },
      {
        code: "69223",
        text: "Если в руководстве (инструкции) по эксплуатации ПС отсутствуют требования к его установке на выносные опоры, то в каком случае разрешается установка стрелового крана, крана-манипулятора только на две или три выносные опоры?",
        answers: [
          "При наличии допустимого уклона одной из частей площадки установки",
          "При отсутствии места на площадке установки для всех четырех опор",
          "Если подъем и перемещение груза будет выполняться только в одном положении стрелы",
          "Если отсутствует одна из инвентарных подкладок, устанавливаемых под опору",
          "Не разрешается, ПС устанавливается на все выносные опоры",
        ],
        correctAnswers: [
          "Не разрешается, ПС устанавливается на все выносные опоры",
        ],
      },
      {
        code: "69224",
        text: "Какие меры следует принять к установке подъемников (вышек) при невозможности соблюдения безопасных расстояний, указанных в ФНП ПС, если глубина котлована более 5 м?",
        answers: [
          "Установить подъемник (вышку) для производства работ, если откос дополнительно укреплен в соответствии с ППР",
          "Установить подъемник (вышку) для производства работ, если получено письменное распоряжение технического руководителя",
          "Установить подъемник (вышку) для производства работ, если присутствует инженерно-технический работник, ответственный за работоспособное состояние ПС",
          "Установить подъемник (вышку) для производства работ, если они проводятся под руководством технического руководителя организации, эксплуатирующей ПС",
          "Установить подъемник (вышку) для производства работ, если на площадке находится сигнальщик, не выполняющий другие работы",
        ],
        correctAnswers: [
          "Установить подъемник (вышку) для производства работ, если откос дополнительно укреплен в соответствии с ППР",
        ],
      },
      {
        code: "69225",
        text: "Кто определяет порядок работы крана вблизи линии электропередачи, выполненной гибким изолированным кабелем?",
        answers: [
          "Разработчик проекта производства работ",
          "Эксплуатирующая организация",
          "Специализированная экспертная организация",
          "Владелец линии",
          "Определение порядка работы не требуется, поскольку изолированный кабель безопасен",
        ],
        correctAnswers: ["Владелец линии"],
      },
      {
        code: "69226",
        text: "Какое расстояние должно соблюдаться между стрелой крана и контактными проводами при работе кранов стрелового типа под включенными контактными проводами городского транспорта при наличии ограничителя (упора)?",
        answers: [
          "Не менее 0,7 м",
          "Не менее 1,0 м",
          "Не менее 0,8 м",
          "Не менее 0,5 м",
        ],
        correctAnswers: ["Не менее 1,0 м"],
      },
      {
        code: "69227",
        text: "Кем выдается наряд-допуск на работу вблизи находящихся под напряжением проводов и оборудования в случаях, когда работы с применением кранов стрелового типа, кранов-манипуляторов, подъемников (вышек) ведутся на действующих электростанциях, подстанциях и линиях электропередачи?",
        answers: [
          "Организацией, эксплуатирующей электростанцию, подстанцию, линию электропередачи",
          "Ростехнадзором",
          "Специализированной организацией",
          "Организацией, эксплуатирующей ПС",
        ],
        correctAnswers: [
          "Организацией, эксплуатирующей электростанцию, подстанцию, линию электропередачи",
        ],
      },
      {
        code: "69228",
        text: "На какую высоту следует предварительно поднять груз перед началом перемещения (с последующей остановкой) для проверки правильности строповки и надежности действия тормоза ПС?",
        answers: [
          "Не более 450 - 550 мм",
          "Не более 200 - 300 мм",
          "Не более 550 - 750 мм",
          "Не более 300 - 500 мм",
        ],
        correctAnswers: ["Не более 200 - 300 мм"],
      },
      {
        code: "69229",
        text: "При каком условии допускается нахождение стропальщика возле груза во время его подъема или опускания?",
        answers: [
          "Если груз поднят на высоту не более 1 м от уровня площадки",
          "Если груз поднят на высоту не более 1,5 м от уровня площадки",
          "Если груз поднят на высоту не более 2 м от уровня площадки",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "Если груз поднят на высоту не более 1 м от уровня площадки",
        ],
      },
      {
        code: "69230",
        text: "Какое из перечисленных требований запрещается при перемещении груза ПС? Выберите 2 варианта ответа.",
        answers: [
          "Горизонтальное перемещение груза на 0,5 м выше встречающихся на пути предметов",
          "Перемещение груза при нахождении под ним людей",
          "Подъем груза, масса которого неизвестна",
          "Кантовка грузов с применением ПС на весу по заранее разработанным ППР или технологической документации",
          "Перемещение кирпича на поддонах без ограждения при разгрузке транспортных средств на землю",
        ],
        correctAnswers: [
          "Перемещение груза при нахождении под ним людей",
          "Подъем груза, масса которого неизвестна",
        ],
      },
      {
        code: "69231",
        text: "На каком расстоянии сбоку от кантуемого груза должен находиться стропальщик?",
        answers: [
          "На расстоянии, равном высоте груза плюс 1,5 м",
          "На расстоянии, равном высоте груза плюс 0,5 м",
          "На расстоянии, равном высоте груза плюс 2 м",
          "На расстоянии, равном высоте груза плюс 1 м",
        ],
        correctAnswers: ["На расстоянии, равном высоте груза плюс 1 м"],
      },
      {
        code: "69232",
        text: "Под чьим руководством следует производить кантовку грузов массой более 75% от паспортной грузоподъемности ПС и грузов со смещением центра тяжести?",
        answers: [
          "Инженерно-технического работника, ответственного за безопасное производство работ с применением ПС",
          "Инженерно-технического работника, ответственного за содержание ПС в работоспособном состоянии",
          "Инженерно-технического работника, ответственного за осуществление производственного контроля при эксплуатации ПС",
          "Технического руководителя организации",
        ],
        correctAnswers: [
          "Инженерно-технического работника, ответственного за безопасное производство работ с применением ПС",
        ],
      },
      {
        code: "69233",
        text: "Что из перечисленного допускается при выполнении работ с применением ПС?",
        answers: [
          "Подъем груза непосредственно с места его установки (с земли, площадки, штабеля) только механизмом телескопирования стрелы",
          "Перемещение подъемников и вышек с людьми вдоль контактной сети или конструкций моста на минимальной скорости согласно требованиям разработанного для этого ППР",
          "Освобождение с применением ПС защемленных грузом стропов, канатов или цепей",
          "Перемещение груза, подвешенного за один рог двурогого крюка",
        ],
        correctAnswers: [
          "Перемещение подъемников и вышек с людьми вдоль контактной сети или конструкций моста на минимальной скорости согласно требованиям разработанного для этого ППР",
        ],
      },
      {
        code: "69234",
        text: "В каких случаях стреловым краном не разрешается подъем груза непосредственно с места его установки (с земли, площадки, штабеля)?",
        answers: [
          "Если подъем груза осуществляется на номинальной скорости механизма",
          "Если осуществляется подъем длинномерного груза с применением траверсы",
          "Если угол между стропами при подъеме груза составляет 80°",
          "Если подъем груза осуществляется только механизмом телескопирования стрелы",
          "Если кран установлен не на асфальтовой площадке",
        ],
        correctAnswers: [
          "Если подъем груза осуществляется только механизмом телескопирования стрелы",
        ],
      },
      {
        code: "69235",
        text: "В каких случаях разрешается подтаскивание груза по земле, полу или рельсам крюками ПС?",
        answers: [
          "Только в случаях применения ПС, не склонных к опрокидыванию (потере устойчивости)",
          "Только в случаях, если канатный барабан механизма подъема ПС снабжен канатоукладчиком",
          "Только в случаях применения направляющих блоков, обеспечивающих вертикальное положение грузовых канатов",
          "Только в случаях перемещения груза (тележки) по рельсам, т. к. нагрузка от трения качения мала и ПС перегрузить невозможно",
          "Не разрешается во всех случаях",
        ],
        correctAnswers: [
          "Только в случаях применения направляющих блоков, обеспечивающих вертикальное положение грузовых канатов",
        ],
      },
      {
        code: "69236",
        text: "В каких случаях разрешается разворот поднятого груза руками?",
        answers: [
          "В случаях, когда масса груза составляет не более половины грузоподъемности крана",
          "В случаях, когда поднятый груз удален от стен здания и выступающих частей оборудования",
          "В случаях, когда разворот выполняет специалист, ответственный за безопасную эксплуатацию ПС",
          "В случаях, когда груз разворачивают в кузове автомобиля или полувагона",
          "В случаях, когда груз поднят на высоту не более 1000 мм",
        ],
        correctAnswers: [
          "В случаях, когда груз поднят на высоту не более 1000 мм",
        ],
      },
      {
        code: "69237",
        text: "Какое требование по безопасной эксплуатации ПС указано неверно?",
        answers: [
          "При управлении подъемным сооружением с пола вдоль всего пути его следования должен быть обеспечен свободный проход для работника, управляющего им",
          "У находящихся в эксплуатации галерей мостовых кранов выходы должны быть закрыты",
          "Эксплуатирующая организация должна разработать мероприятия по безопасному спуску крановщиков из кабины при вынужденной остановке крана не у посадочной площадки",
          "Мероприятия по безопасному спуску крановщиков должны быть указаны в технологической карте",
        ],
        correctAnswers: [
          "Мероприятия по безопасному спуску крановщиков должны быть указаны в технологической карте",
        ],
      },
      {
        code: "69238",
        text: "Что не указывается на табличках, которыми должны быть снабжены находящиеся в эксплуатации ПС?",
        answers: [
          "Заводской номер ПС",
          "Паспортная грузоподъемность",
          "Дата следующего полного технического освидетельствования",
          "Дата следующего частичного технического освидетельствования",
          "Учетный номер ПС",
          "Указывается все перечисленное",
        ],
        correctAnswers: ["Указывается все перечисленное"],
      },
      {
        code: "69239",
        text: "Какие меры промышленной безопасности должны быть приняты для ПС, установленных на открытом воздухе и находящихся в нерабочем состоянии?",
        answers: [
          "Подъемные сооружения должны быть установлены на аутригеры, при этом стрела устанавливается на минимальный вылет",
          "ПС должны быть обесточены и приняты меры по предотвращению их угона ветром",
          "Подъемные сооружения должны быть установлены на аутригеры, если давление в колесах не соответствует норме",
          "Меры определяются ветровым районом установки ПС",
          "Меры определяются высотой установки ПС над уровнем моря",
        ],
        correctAnswers: [
          "ПС должны быть обесточены и приняты меры по предотвращению их угона ветром",
        ],
      },
      {
        code: "69240",
        text: "Каким оборудованием в эксплуатирующей организации должны быть обеспечены ее стропальщики с целью обеспечения промышленной безопасности технологических процессов строповки?",
        answers: [
          "Ломами, кувалдами, молотками и др.",
          "Цепными стропами, соответствующими массе перемещаемых грузов",
          "Грузозахватными приспособлениями, соответствующими массе перемещаемых грузов",
          "Испытанными и маркированными грузозахватными приспособлениями и тарой, соответствующими массе и характеру перемещаемых грузов",
          "Оборудованием, применяемым в технологических процессах транспортировки грузов, принятых в эксплуатирующей организации",
        ],
        correctAnswers: [
          "Испытанными и маркированными грузозахватными приспособлениями и тарой, соответствующими массе и характеру перемещаемых грузов",
        ],
      },
      {
        code: "69241",
        text: "В каких местах должны быть установлены стационарные эстакады или навесные площадки для стропальщиков?",
        answers: [
          "В местах постоянной погрузки и разгрузки автомашин и полувагонов",
          "В любых местах, кроме мест хранения напольного технологического оборудования",
          "В любых местах, кроме мест для прохода персонала",
          "В местах, которые предусмотрены для эвакуации в случае возникновения аварийной ситуации",
          "Установка стационарных эстакад или навесных площадок для стропальщиков не обязательна",
        ],
        correctAnswers: [
          "В местах постоянной погрузки и разгрузки автомашин и полувагонов",
        ],
      },
      {
        code: "69242",
        text: "В каких случаях разрешается строповка пакетов металлопроката или труб за элементы упаковки (скрутки, стяжки)?",
        answers: [
          "В случае выполнения скрутки из мягкой стальной проволоки и при количестве скруток не менее 3",
          'В случае, если по техническим условиям на строповку они являются "хомутами"',
          "В случае подъема и перемещения пакетов металлопроката или труб с использованием траверсы, крюки которой застропованы в скрутки",
          "В случае применения их всего для двух последующих перегрузок: для загрузки в определенное место и последующей разгрузки",
          "Запрещается во всех случаях",
        ],
        correctAnswers: ["Запрещается во всех случаях"],
      },
      {
        code: "69243",
        text: "Как должна распределяться нагрузка на каждое из ПС, если подъем и перемещение груза осуществляют двумя ПС?",
        answers: [
          "Нагрузка должна быть не более 0,45 % грузоподъемности ПС, имеющего меньшую грузоподъемность",
          "Нагрузка должна быть по возможности одинаковой",
          "Нагрузка, приходящаяся на каждое ПС, не должна превышать грузоподъемность ПС",
          "Нагрузка должна быть выровнена несимметричной строповкой груза",
          "Нагрузка должна контролироваться взвешивающими устройствами подъемных сооружений",
        ],
        correctAnswers: [
          "Нагрузка, приходящаяся на каждое ПС, не должна превышать грузоподъемность ПС",
        ],
      },
      {
        code: "69244",
        text: "Допускается ли при выполнении строительно-монтажных или погрузочно-разгрузочных работ перемещение грузов с применением ПС над перекрытиями, под которыми размещены производственные, жилые или служебные помещения, где могут находиться люди?",
        answers: [
          "Допускается, если работами руководит специалист, ответственный за безопасное производство работ с применением ПС",
          "Допускается, если место производства работ будет ограждено и обозначено предупредительными знаками",
          "Допускается, если численность возможно находящихся людей не превышает 3 человек",
          "Не допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "69245",
        text: "В каких случаях зоны работающих ПС должны быть ограждены и обозначены предупредительными знаками, при этом нахождение в зоне работы людей не допускается?",
        answers: [
          "Во время выполнения работы ПС на краю откосов",
          "В случае истекшего срока эксплуатации ПС, указанного в паспорте завода-изготовителя",
          "Во время эксплуатации ПС в ночное время суток",
          "Во время работы ПС, оснащенных грейфером или электромагнитом",
          "В случае транспортировки крупногабаритных грузов",
        ],
        correctAnswers: [
          "Во время работы ПС, оснащенных грейфером или электромагнитом",
        ],
      },
      {
        code: "69246",
        text: "В каких случаях необходимо прекращать работу ПС, установленных на открытом воздухе?",
        answers: [
          "Только при температуре окружающей среды ниже предельно допустимой температуры, указанной в паспорте ПС",
          "Только при скорости ветра, превышающей предельно допустимую скорость, указанную в паспорте ПС",
          "Только при снегопаде, дожде, тумане, в случаях, когда крановщик (машинист, оператор) плохо различает сигналы стропальщика или перемещаемый груз",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "69247",
        text: "Какие краны, не оборудованные координатной защитой, запрещается применять для работы в стесненных условиях?",
        answers: [
          "Только башенные краны",
          "Только грузоподъемные краны на пневмоколесном и гусеничном ходу",
          "Только грузоподъемные краны, установленные на автомобильные шасси, специальные шасси автомобильного типа",
          "Все перечисленные краны",
        ],
        correctAnswers: ["Все перечисленные краны"],
      },
      {
        code: "69248",
        text: "Кем выдается решение о пуске в работу ПС, отбор мощности у которых для собственного передвижения и работы механизмов осуществляется от собственного источника энергии, после перестановки их на новый объект?",
        answers: [
          "Инженерно-техническим работником, ответственным за осуществление производственного контроля при эксплуатации ПС",
          "Инженерно-техническим работником, ответственным за содержание ПС в работоспособном состоянии",
          "Инженерно-техническим работником, ответственным за безопасное производство работ с применением ПС",
          "Инспектором Ростехнадзора",
        ],
        correctAnswers: [
          "Инженерно-техническим работником, ответственным за безопасное производство работ с применением ПС",
        ],
      },
      {
        code: "69249",
        text: "Куда записывается решение о вводе в эксплуатацию грузозахватных приспособлений, тары?",
        answers: [
          "В эксплуатационный паспорт грузозахватного приспособления, тары",
          "В специальный журнал учета и осмотра",
          "В распорядительный акт эксплуатирующей организации",
          "В положение о контроле качества технологических процессов, принимаемое эксплуатирующей организацией",
          "На бирку грузозахватного приспособления, тары",
        ],
        correctAnswers: ["В специальный журнал учета и осмотра"],
      },
      {
        code: "69250",
        text: "Что служит основанием для решения о пуске в работу после установки на объекте кранов мостового типа и портальных кранов?",
        answers: [
          "Предложение комиссии о возможности пуска",
          "Наличие положительного заключения экспертизы промышленной безопасности",
          "Акт-разрешение территориального органа Ростехнадзора",
          "Заключение завода-изготовителя о возможности пуска",
        ],
        correctAnswers: ["Предложение комиссии о возможности пуска"],
      },
      {
        code: "69251",
        text: "Кто является председателем комиссии, на основании предложений которой принимается решение о пуске в работу после установки на объекте кранов мостового типа и портальных кранов?",
        answers: [
          "Представитель территориального органа Ростехнадзора",
          "Уполномоченный представитель специализированной организации",
          "Уполномоченный представитель эксплуатирующей организации",
          "Специалист, ответственный за безопасную эксплуатацию ПС",
        ],
        correctAnswers: [
          "Уполномоченный представитель эксплуатирующей организации",
        ],
      },
      {
        code: "69252",
        text: "Кто назначается председателем комиссии, на основании предложений которой принимается решение о пуске в работу ПС, отработавшего срок службы, при смене эксплуатирующей организации?",
        answers: [
          "Представитель территориального органа Ростехнадзора",
          "Уполномоченный представитель специализированной организации",
          "Уполномоченный представитель эксплуатирующей организации",
          "Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС",
        ],
        correctAnswers: [
          "Уполномоченный представитель эксплуатирующей организации",
        ],
      },
      {
        code: "69253",
        text: "За сколько дней до начала работы комиссии по пуску ПС в работу эксплуатирующая организация должна письменно уведомить организации, представители которых включены в состав комиссии, о дате работы комиссии?",
        answers: [
          "Не менее чем за 3 дня",
          "Не менее чем за 10 дней",
          "Не менее чем за 7 дней",
          "Не менее чем за 5 дней",
        ],
        correctAnswers: ["Не менее чем за 10 дней"],
      },
      {
        code: "69254",
        text: "В каком документе содержатся результаты работы комиссии, принимающей решение о возможности пуска ПС в работу?",
        answers: [
          "В акте пуска ПС в работу",
          "В протоколе осмотра ПС",
          "В акте-наряде",
          "В специальном разрешении на пуск в работу",
        ],
        correctAnswers: ["В акте пуска ПС в работу"],
      },
      {
        code: "69255",
        text: "Когда выдаются производственные инструкции персоналу, обслуживающему ПС?",
        answers: [
          "Перед допуском к работе, под расписку",
          "После прохождения повторного инструктажа",
          "При прохождении вводного инструктажа",
          "Перед проведением первичного инструктажа на рабочем месте",
        ],
        correctAnswers: ["Перед допуском к работе, под расписку"],
      },
      {
        code: "69256",
        text: "Кто должен назначать сигнальщика в случаях, когда зона, обслуживаемая ПС, полностью не просматривается из кабины управления (с места управления), и при отсутствии между крановщиком и стропальщиком радио- или телефонной связи?",
        answers: [
          "Специалист, ответственный за содержание ПС в работоспособном состоянии",
          "Специалист, ответственный за осуществление производственного контроля при эксплуатации ПС",
          "Специалист, ответственный за безопасное выполнение работ с применением ПС",
          "Инспектор территориального органа Ростехнадзора",
          "Руководитель организации, эксплуатирующей ПС",
        ],
        correctAnswers: [
          "Специалист, ответственный за безопасное выполнение работ с применением ПС",
        ],
      },
      {
        code: "69257",
        text: "Что должно быть предпринято в случае, когда зона, обслуживаемая ПС, полностью не просматривается из кабины управления (с места управления), и при отсутствии между оператором (крановщиком) и стропальщиком радио- или телефонной связи?",
        answers: [
          "Должен быть назначен сигнальщик из числа стропальщиков",
          "Должно быть назначено лицо, ответственное за безопасное производство работ",
          "Должны быть разработаны условные обозначения для передачи сигнала",
          "Должен быть разработан план действий для крановщика и стропальщика в аварийных ситуациях",
        ],
        correctAnswers: [
          "Должен быть назначен сигнальщик из числа стропальщиков",
        ],
      },
      {
        code: "69258",
        text: "Кем назначаются сигнальщики из числа стропальщиков для передачи сигнала оператору (крановщику)?",
        answers: [
          "Техническим руководителем",
          "Инженерно-техническим работником, ответственным за содержание ПС в работоспособном состоянии",
          "Инженерно-техническим работником, ответственным за осуществление производственного контроля при эксплуатации ПС",
          "Инженерно-техническим работником, ответственным за безопасное производство работ с применением ПС",
        ],
        correctAnswers: [
          "Инженерно-техническим работником, ответственным за безопасное производство работ с применением ПС",
        ],
      },
      {
        code: "69259",
        text: "Каким должно быть безопасное расстояние от низа перемещаемого груза до перекрытий и площадок, где могут находиться люди?",
        answers: [
          "Не менее 2 м",
          "Не менее 1,5 м",
          "Не менее 1,8 м",
          "Не менее 2,3 м",
        ],
        correctAnswers: ["Не менее 2,3 м"],
      },
      {
        code: "69260",
        text: "Каким должно быть безопасное расстояние от низа перемещаемого груза до наиболее выступающих по вертикали частей здания или сооружения?",
        answers: [
          "Не менее 0,2 м",
          "Не менее 0,3 м",
          "Не менее 0,4 м",
          "Не менее 0,5 м",
        ],
        correctAnswers: ["Не менее 0,5 м"],
      },
      {
        code: "69261",
        text: "Кем должны быть утверждены ППР, ТК на погрузочно-разгрузочные работы и выданы на участки, где будут использоваться ПС, до начала ведения работ?",
        answers: [
          "Проектной организацией",
          "Эксплуатирующей ПС организацией",
          "Ростехнадзором",
          "Специализированной организацией",
        ],
        correctAnswers: ["Эксплуатирующей ПС организацией"],
      },
      {
        code: "69262",
        text: "Кто из специалистов и персонала до начала производства работ ПС в обязательном порядке должен быть ознакомлен под подпись с ППР?",
        answers: [
          "Инженерно-технические работники, ответственные за безопасное производство работ с применением ПС, крановщики (операторы) и стропальщики",
          "Инженерно-технические работники, ответственные за безопасное производство работ с применением ПС, крановщики (операторы)",
          "Инженерно-технические работники, ответственные за безопасное производство работ с применением ПС, стропальщики",
          "Крановщики (операторы) и стропальщики",
          "Ознакомление с ППР под подпись не требуется",
        ],
        correctAnswers: [
          "Инженерно-технические работники, ответственные за безопасное производство работ с применением ПС, крановщики (операторы) и стропальщики",
        ],
      },
      {
        code: "69263",
        text: "Какой параметр из паспорта ПС (в виде выписки) в обязательном порядке должен быть включен в раздел ППР и технологической карты (далее – ТК), связанный с организацией безопасного производства работ с применением ПС?",
        answers: [
          "Высота подъема груза",
          "Вылет стрелы",
          "Максимальная грузоподъемность ПС",
          "Справка об установленных указателях, ограничителях и регистраторах",
          "Сила ветра, при которой его работа не допускается",
        ],
        correctAnswers: ["Сила ветра, при которой его работа не допускается"],
      },
      {
        code: "69264",
        text: "В каком случае допускается движение стрелового самоходного крана с места при раскачивающемся грузе?",
        answers: [
          "В соответствии с руководством (инструкцией) по эксплуатации крана",
          "С разрешения инженерно-технического работника, ответственного за безопасное производство работ с применением ПС",
          "В соответствии с ППР",
          "В соответствии с Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "69265",
        text: "Какие требования к передвижению стрелового самоходного крана указаны неверно?",
        answers: [
          "Стреловым самоходным кранам допускается перемещение с грузом на крюке",
          "Возможность перемещения с грузом на крюке устанавливается в соответствии с руководством (инструкцией) по эксплуатации крана",
          "Движение крана с места при раскачивающемся грузе разрешается при отклонении последнего на угол, не более указанного в руководстве (инструкции) по эксплуатации крана",
          "Движение крана с места при раскачивающемся грузе запрещается",
          "Основание, по которому перемещается кран с грузом, должно быть ровным и иметь уклон не более указанного в руководстве (инструкции) по эксплуатации крана",
        ],
        correctAnswers: [
          "Движение крана с места при раскачивающемся грузе разрешается при отклонении последнего на угол, не более указанного в руководстве (инструкции) по эксплуатации крана",
        ],
      },
      {
        code: "69266",
        text: "В каких случаях разрешается подача грузов в проемы (люки) перекрытий?",
        answers: [
          "Если ниже перекрытия исключено присутствие людей",
          "Если у перекрытия находится сигнальщик",
          "Если это предусмотрено специально разработанным ППР",
          "Если работа выполняется под руководством лица, ответственного за безопасное выполнение работ с применением ПС",
          "Не разрешается в любом случае",
        ],
        correctAnswers: ["Если это предусмотрено специально разработанным ППР"],
      },
      {
        code: "69267",
        text: "Каким документом определяется объем работ, порядок и периодичность проведения технических освидетельствований ПС?",
        answers: [
          'Федеральными нормами и правилами в области промышленной безопасности "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения", утвержденными приказом Ростехнадзора от 12 ноября 2013 г. N 533',
          'Техническим регламентом Таможенного союза ТР ТС 010/2011 "О безопасности машин и оборудования"',
          '"Положением о Федеральной службе по экологическому, технологическому и атомному надзору", утвержденным постановлением Правительства Российской Федерации от 30 июля 2004 г. N 401',
          "Паспортом ПС",
          "Руководством (инструкцией) по эксплуатации ПС, а при отсутствии в ней указаний - требованиями ФНП ПС",
        ],
        correctAnswers: [
          "Руководством (инструкцией) по эксплуатации ПС, а при отсутствии в ней указаний - требованиями ФНП ПС",
        ],
      },
      {
        code: "69268",
        text: "Какая периодичность частичного технического освидетельствования установлена для ПС в течение всего срока службы?",
        answers: [
          "Не реже одного раза в 15 месяцев",
          "Не реже одного раза в 12 месяцев",
          "Не реже одного раза в 18 месяцев",
          "Не реже одного раза в 16 месяцев",
        ],
        correctAnswers: ["Не реже одного раза в 12 месяцев"],
      },
      {
        code: "69269",
        text: "Какая периодичность полного технического освидетельствования установлена для ПС в течение всего срока службы?",
        answers: [
          "Не реже 1 раза в 6 лет",
          "Не реже 1 раза в 5 лет, за исключением редко используемых ПС",
          "Не реже одного раза в 3 года, за исключением редко используемых ПС",
          "Не реже 1 раза в 8 лет",
        ],
        correctAnswers: [
          "Не реже одного раза в 3 года, за исключением редко используемых ПС",
        ],
      },
      {
        code: "69270",
        text: "В каких из перечисленных случаев эксплуатирующая организация имеет право допустить ПС в работу?",
        answers: [
          "Обслуживание ПС ведется неаттестованным персоналом",
          "Для редко используемого ПС прошло более трех лет с момента проведения предыдущего технического освидетельствования",
          "Отсутствует экспертиза промышленной безопасности ПС, отработавшего срок службы",
          "На ПС выявлены трещины в расчетных элементах металлоконструкции",
          "Отсутствуют соответствующие массе и виду перемещаемых грузов съемные грузозахватные приспособления и тара",
        ],
        correctAnswers: [
          "Для редко используемого ПС прошло более трех лет с момента проведения предыдущего технического освидетельствования",
        ],
      },
      {
        code: "69271",
        text: "Что должно проводиться после реконструкции ПС?",
        answers: [
          "Внеочередное частичное техническое освидетельствование",
          "Внеочередное полное техническое освидетельствование",
          "Периодическое частичное техническое освидетельствование ПС",
          "Периодическое техническое освидетельствование",
        ],
        correctAnswers: ["Внеочередное полное техническое освидетельствование"],
      },
      {
        code: "69272",
        text: "В каком из перечисленных случаев при внеочередном полном техническом освидетельствовании ПС проводятся только статические испытания?",
        answers: [
          "После реконструкции ПС",
          "После установки сменного стрелового оборудования",
          "После капитального ремонта",
          "После замены грузозахватного органа",
          "После замены вантовых канатов кранов кабельного типа",
        ],
        correctAnswers: ["После замены грузозахватного органа"],
      },
      {
        code: "69273",
        text: "Кто должен проводить техническое освидетельствование ПС?",
        answers: [
          "Комиссия эксплуатирующей организации, утвержденная ее руководителем",
          "Комиссия эксплуатирующей организации, в состав которой должен входить представитель Ростехнадзора",
          "Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС, при участии специалиста, ответственного за содержание ПС в работоспособном состоянии",
          "Инспектор Ростехнадзора совместно со инженерно-техническим работником, ответственным за содержание ПС в работоспособном состоянии",
          "Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС, при участии специалиста, ответственного за безопасное производство работ",
        ],
        correctAnswers: [
          "Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС, при участии специалиста, ответственного за содержание ПС в работоспособном состоянии",
        ],
      },
      {
        code: "69274",
        text: "Каким проверкам должны подвергаться ПС при полном техническом освидетельствовании?",
        answers: [
          "Только осмотру и испытаниям на устойчивость",
          "Только динамическим испытаниям",
          "Только статическим испытаниям",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "69275",
        text: "Для каких подъемников при проведении технического освидетельствования необходимо проверять точность остановки кабины с нагрузкой и без нагрузки?",
        answers: [
          "Для строительных подъемников",
          "Для ножничных подъемников",
          "Для подъемников с пневматическим приводом",
          "Для гидравлических подъемников",
          "Для подъемников, применяемых на железнодорожных станциях",
        ],
        correctAnswers: ["Для строительных подъемников"],
      },
      {
        code: "69276",
        text: "Какой нагрузкой должны проводиться статические испытания ПС всех типов (кроме подъемников и кранов-трубоукладчиков)?",
        answers: [
          "200 процентов по отношению к номинальной паспортной грузоподъемности ПС",
          "145 процентов по отношению к номинальной паспортной грузоподъемности ПС",
          "125 процентов по отношению к номинальной паспортной грузоподъемности ПС",
          "160 процентов по отношению к номинальной паспортной грузоподъемности ПС",
        ],
        correctAnswers: [
          "125 процентов по отношению к номинальной паспортной грузоподъемности ПС",
        ],
      },
      {
        code: "69277",
        text: "В каких случаях при наличии на ПС двух механизмов подъема их статические испытания следует проводить одновременно?",
        answers: [
          "Если статические испытания проводятся для контейнерных кранов, спредер которых поднимают одновременно два механизма подъема",
          "Если статические испытания проводятся для литейных кранов, у которых вспомогательный подъем осуществляет поворот поднятого ковша",
          "Если это предусмотрено паспортом ПС",
          "Если статические испытания проводятся для кранов, оснащенных двухканатным грейфером",
          "Во всех перечисленных случаях запрещено",
        ],
        correctAnswers: ["Если это предусмотрено паспортом ПС"],
      },
      {
        code: "69278",
        text: "С помощью чего делается высотная засечка положения одного из поясов главной балки при проведении статического испытания мостового крана?",
        answers: [
          "Лазерным дальномером",
          "Оптическим прибором",
          "С помощью металлической струны",
          "Всего перечисленного",
        ],
        correctAnswers: ["Всего перечисленного"],
      },
      {
        code: "69279",
        text: "Какова продолжительность статических испытаний кабельных кранов?",
        answers: ["10 минут", "15 минут", "25 минут", "30 минут", "40 минут"],
        correctAnswers: ["30 минут"],
      },
      {
        code: "69280",
        text: "В течение какого времени должен находиться в первоначально поднятом состоянии над землей груз при проведении статического испытания кабельных кранов?",
        answers: [
          "В течение 30 минут",
          "В течение 20 минут",
          "В течение 60 минут",
          "В течение 10 минут",
        ],
        correctAnswers: ["В течение 30 минут"],
      },
      {
        code: "69281",
        text: "Сколько высотных засечек положения одного из поясов главной балки делается при проведении статического испытания мостового крана?",
        answers: ["Одна", "Две", "Три", "Четыре"],
        correctAnswers: ["Три"],
      },
      {
        code: "69282",
        text: "Какие требования к статическим испытаниям кранов указаны верно?",
        answers: [
          "Испытания кранов, имеющих сменное стреловое оборудование, проводятся с установленным стреловым оборудованием, а также после замены стрелового оборудования",
          "Испытания кранов стрелового типа, не имеющих механизма изменения вылета, проводятся при установленных для испытаний вылетах",
          "Статические испытания крана стрелового типа, имеющего одну или несколько грузовых характеристик, при периодическом или внеочередном техническом освидетельствовании проводятся в положении, соответствующем наибольшей грузоподъемности крана и (или) наибольшему грузовому моменту, если это не противоречит требованиям, изложенным в руководстве (инструкции) по эксплуатации ПС",
          "Все перечисленные требования указаны верно",
        ],
        correctAnswers: ["Все перечисленные требования указаны верно"],
      },
      {
        code: "69283",
        text: "Когда результат статических испытаний крана стрелового типа считается положительным?",
        answers: [
          "При отсутствии трещин, остаточных деформаций и других повреждений стрелы",
          "Если в течение 5 минут поднятый груз не опустится на землю",
          "Если в течение 10 минут поднятый груз не опустится на землю, а также не обнаружено трещин, остаточных деформаций и других повреждений металлоконструкций и механизмов",
          "При устойчивости крана во время всего периода испытаний, независимо от состояния металлоконструкций",
        ],
        correctAnswers: [
          "Если в течение 10 минут поднятый груз не опустится на землю, а также не обнаружено трещин, остаточных деформаций и других повреждений металлоконструкций и механизмов",
        ],
      },
      {
        code: "69284",
        text: "Какие требования к статическим испытаниям крана-трубоукладчика или крана-манипулятора указаны неверно?",
        answers: [
          "Кран-трубоукладчик и кран-манипулятор считаются выдержавшими испытания, если в течение 10 минут поднятый груз не опустился, а также не обнаружено трещин, остаточных деформаций и других повреждений",
          "После установки на кран-трубоукладчик (кран-манипулятор) сменного стрелового оборудования испытания проводятся в положении, соответствующем наибольшей грузоподъемности, при установленном оборудовании",
          "Крюком поднимают груз на высоту 100 - 150 мм от земли и выдерживают в течение не менее 5 минут",
          "Все перечисленные требования указаны верно",
        ],
        correctAnswers: [
          "Крюком поднимают груз на высоту 100 - 150 мм от земли и выдерживают в течение не менее 5 минут",
        ],
      },
      {
        code: "69285",
        text: "В течение какого времени груз должен находиться на неподвижном грузонесущем устройстве без смещения грузонесущего устройства и без обнаружения трещин, остаточных деформаций и других повреждений металлоконструкций и механизмов, чтобы строительный подъемник считался выдержавшим статические испытания?",
        answers: [
          "В течение 15 минут",
          "В течение 20 минут",
          "В течение 10 минут",
          "В течение 5 минут",
        ],
        correctAnswers: ["В течение 10 минут"],
      },
      {
        code: "69286",
        text: "На какой высоте над уровнем нижней посадочной площадки (земли) должен находиться груз на неподвижном грузонесущем устройстве при статических испытаниях строительного подъемника?",
        answers: [
          "Не более 155 мм",
          "Не более 150 мм",
          "Не более 165 мм",
          "Не более 160 мм",
        ],
        correctAnswers: ["Не более 150 мм"],
      },
      {
        code: "69287",
        text: "Считается ли отрыв одной из опор подъемника при проведении испытаний признаком потери устойчивости?",
        answers: [
          "Считается, если отрыв произошел в течение первых 5 минут испытаний",
          "Не считается, если отрыв произошел в течение первых 5 минут испытаний",
          "Считается, если одна из противоположных опор подъемника во время испытаний погрузилась в грунт",
          "Не считается, если в течение 10 минут поднятый груз не опустился, а также если в металлоконструкциях не обнаружены повреждения",
          "Не считается, если при отрыве опоры сработал ограничитель грузового момента",
        ],
        correctAnswers: [
          "Не считается, если в течение 10 минут поднятый груз не опустился, а также если в металлоконструкциях не обнаружены повреждения",
        ],
      },
      {
        code: "69288",
        text: "Для каких типов подъемников при проведении статических испытаний часть испытательного груза подвешивают к люльке на гибкой подвеске?",
        answers: [
          "Только для строительных подъемников",
          "Только для подъемников ножничного типа",
          "Для всех подъемников, оборудованных люлькой, кроме строительных",
          "Только для автогидроподъемников",
          "Только для подъемников, применяемых для перемещения людей",
        ],
        correctAnswers: [
          "Для всех подъемников, оборудованных люлькой, кроме строительных",
        ],
      },
      {
        code: "69289",
        text: "Каким грузом следует проводить динамические испытания стреловых самоходных кранов?",
        answers: [
          "Масса которого на 10% превышает его паспортную грузоподъемность",
          "Масса которого на 8% превышает его паспортную грузоподъемность",
          "Масса которого на 6% превышает его паспортную грузоподъемность",
          "Масса которого на 5% превышает его паспортную грузоподъемность",
        ],
        correctAnswers: [
          "Масса которого на 10% превышает его паспортную грузоподъемность",
        ],
      },
      {
        code: "69290",
        text: "Каким испытаниям подлежат механизмы подъема ПС, если предусмотрена их раздельная работа?",
        answers: [
          "Только статической нагрузкой",
          "Только динамической нагрузкой",
          "Виды нагрузок не регламентируются нормативными документами",
          "Каждый механизм должен быть испытан статической и динамической нагрузкой",
        ],
        correctAnswers: [
          "Каждый механизм должен быть испытан статической и динамической нагрузкой",
        ],
      },
      {
        code: "69291",
        text: "В каких случаях не проводятся динамические испытания ПС?",
        answers: [
          "В случаях использования ПС для перемещения крупногабаритных грузов",
          "Если ПС используется только для подъема и опускания груза (подъем затворов на гидроэлектростанции)",
          "В случаях использования ПС в технологических процессах",
          "Если ПС используется для перемещения людей",
        ],
        correctAnswers: [
          "Если ПС используется только для подъема и опускания груза (подъем затворов на гидроэлектростанции)",
        ],
      },
      {
        code: "69292",
        text: "С каким грузозахватным органом проводят испытания при повторных периодических технических освидетельствованиях ПС, имеющих несколько грузозахватных органов?",
        answers: [
          "С каждым грузозахватным органом поочередно",
          "С тем, который установлен на момент проведения испытаний",
          "С грузозахватным органом, наиболее часто применяющимся в технологии перегрузочного процесса",
          "Сменные грузозахватные органы подлежат только осмотру",
        ],
        correctAnswers: [
          "С тем, который установлен на момент проведения испытаний",
        ],
      },
      {
        code: "69293",
        text: "Какая организация обеспечивает наличие комплекта испытательных (контрольных) грузов с указанием их фактической массы для проведения статических и динамических испытаний ПС на территории специализированной организации, осуществляющей ремонт или реконструкцию?",
        answers: [
          "Контролирующая организация",
          "Эксплуатирующая организация",
          "Экспертная организация",
          "Проектная организация",
          "Данная специализированная организация",
        ],
        correctAnswers: ["Данная специализированная организация"],
      },
      {
        code: "69294",
        text: "С какой перегрузкой проводятся испытания на строительных подъемниках при проведении полного технического освидетельствования и проверки работоспособности ловителей (аварийных остановов)?",
        answers: ["0,25", "0,2", "0,15", "0,1"],
        correctAnswers: ["0,1"],
      },
      {
        code: "69295",
        text: "В каких случаях проводятся испытания на грузовую устойчивость при первичном техническом освидетельствовании стрелового самоходного крана?",
        answers: [
          "В случаях, когда в его паспорте отсутствуют ссылки на результаты таких ранее проведенных испытаний",
          "В случаях, когда конструкция крана (стрела, гусек, аутригеры или опорно-поворотное устройство) была подвергнута ремонту расчетных элементов металлоконструкций с заменой элементов или с применением сварки",
          "В случаях, когда конструкция крана (стрела, гусек, аутригеры или опорно-поворотное устройство) была подвергнута изменению первоначальных геометрических размеров",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "69296",
        text: "В каком случае проводятся испытания на устойчивость при повторных технических освидетельствованиях для всех кранов стрелового типа и подъемников (вышек), у которых люлька закреплена на оголовке стрелы?",
        answers: [
          "По требованию территориального органа Ростехнадзора",
          "Если это указано в их руководстве (инструкции) по эксплуатации",
          "По требованию технического руководителя",
          "Не проводятся ни в каком случае",
        ],
        correctAnswers: [
          "Если это указано в их руководстве (инструкции) по эксплуатации",
        ],
      },
      {
        code: "69297",
        text: "Кто выдает разрешение на дальнейшую эксплуатацию ПС после проведения периодического планового технического освидетельствования?",
        answers: [
          "Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии, при участии специалиста, ответственного за безопасное производство работ с применением ПС",
          "Руководитель эксплуатирующей организации, которой принадлежит подъемник (вышка)",
          "Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии",
          "Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС",
          "Инспектор территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС",
        ],
      },
      {
        code: "69298",
        text: "Какие требования предъявляются к стальным канатам, устанавливаемым на ПС при замене ранее установленных?",
        answers: [
          "Стальные канаты должны быть сертифицированы и испытаны в соответствии с требованиями Правил устройства и безопасной эксплуатации грузоподъемных кранов",
          "Стальные канаты должны соответствовать по длине, марке, диаметру и разрывному усилию, указанным в паспорте ПС, и иметь сертификат предприятия-изготовителя",
          "Стальные канаты должны иметь разрывное усилие на 15 % больше указанного в паспорте ПС для заменяемого каната",
          "Стальные канаты должны соответствовать только технологии применения ПС",
        ],
        correctAnswers: [
          "Стальные канаты должны соответствовать по длине, марке, диаметру и разрывному усилию, указанным в паспорте ПС, и иметь сертификат предприятия-изготовителя",
        ],
      },
      {
        code: "69299",
        text: "Согласно требованиям какого документа следует выполнять браковку стальных канатов в эксплуатации?",
        answers: [
          "Технологического регламента",
          "Технического регламента",
          "Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения",
          "Проекта",
        ],
        correctAnswers: [
          "Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения",
        ],
      },
      {
        code: "69300",
        text: "Каким требованиям должны соответствовать стальные цепи, устанавливаемые на ПС?",
        answers: [
          "Стальные цепи должны соответствовать по марке и разрывному усилию указанным в паспорте ПС, иметь сертификат предприятия-изготовителя цепи",
          "Стальные цепи должны быть сертифицированы и испытаны в соответствии с требованиями Правил устройства и безопасной эксплуатации грузоподъемных кранов",
          "Стальные цепи должны соответствовать диаметру и разрывному усилию, указанным в паспорте ПС",
          "Стальные цепи должны быть сертифицированы, коэффициент запаса прочности должен быть не менее 6",
        ],
        correctAnswers: [
          "Стальные цепи должны соответствовать по марке и разрывному усилию указанным в паспорте ПС, иметь сертификат предприятия-изготовителя цепи",
        ],
      },
      {
        code: "69301",
        text: "Какие требования предъявляются к испытанию стальных цепей, устанавливаемых на ПС, после их сращивания электросваркой?",
        answers: [
          "Цепь должна быть испытана нагрузкой, в 1,25 раза превышающей ее расчетное натяжение, в течение 10 минут",
          "Цепь должна быть испытана нагрузкой, в 2 раза превышающей ее расчетное натяжение, в течение 5 минут",
          "Цепь должна быть испытана нагрузкой, в 1,5 раза превышающей ее расчетное натяжение, в течение 15 минут",
          "Цепь должна быть испытана нагрузкой, в 1,75 раза превышающей ее расчетное натяжение, в течение 10 минут",
        ],
        correctAnswers: [
          "Цепь должна быть испытана нагрузкой, в 1,25 раза превышающей ее расчетное натяжение, в течение 10 минут",
        ],
      },
      {
        code: "69302",
        text: "Каким требованиям должны соответствовать устройство и размеры лестниц, посадочных площадок и галерей надземных рельсовых путей?",
        answers: [
          "Требованиям руководства (инструкции) по эксплуатации ПС",
          "Требованиям утвержденного проекта производства работ с применением ПС",
          "Требованиям проектной и эксплуатационной документации на рельсовый путь",
          "Требованиям заключения экспертизы промышленной безопасности ПС",
          "Требованиям строительных норм и правил применения ПС",
        ],
        correctAnswers: [
          "Требованиям проектной и эксплуатационной документации на рельсовый путь",
        ],
      },
      {
        code: "69303",
        text: "Каким требованиям из перечисленных должны отвечать рельсовый путь ПС (исключая рельсовые пути башенных и железнодорожных кранов) и рельсовый путь грузовых подвесных тележек или электрических талей, оборудованный стрелками или поворотными кругами, а также места перехода ПС или его грузовой тележки с одного пути на другой?",
        answers: [
          "Только обеспечивать управление переводом стрелки или поворотного круга от сигнала системы управления грузовой тележкой",
          "Только иметь автоматически включаемую блокировку, исключающую сход грузовой тележки (электрической тали) с рельса при выезде ее на консоль расстыкованного участка пути",
          "Только быть оборудованными единым выключателем для подачи напряжения на троллеи (или электрический кабель) грузовой тележки (электрической тали), на механизмы управления стрелок и электрические аппараты блокировочных устройств",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "69304",
        text: "Какое количество тупиковых упоров, ограничивающих рабочую зону, обслуживаемую ПС, должно быть установлено на каждой рельсовой нити рельсового пути?",
        answers: [
          "По одному тупиковому упору",
          "По два тупиковых упора",
          "По три тупиковых упора",
          "По четыре тупиковых упора",
        ],
        correctAnswers: ["По два тупиковых упора"],
      },
      {
        code: "69305",
        text: "Кем должен быть обустроен переезд транспортных средств через пути ПС, передвигающихся по наземному рельсовому пути?",
        answers: [
          "Эксплуатирующей организацией",
          "Проектной организацией",
          "Экспертной организацией",
          "Администрацией субъекта, на котором расположено ПС",
        ],
        correctAnswers: ["Эксплуатирующей организацией"],
      },
      {
        code: "69306",
        text: "Допускается ли пересечение путей козловых, башенных и портальных кранов с рельсовыми путями заводского транспорта?",
        answers: [
          "Допускается только при условии выполнения мероприятий по безопасному производству работ на рельсовых путях",
          "Допускается только после разработки мероприятий по предупреждению столкновения работающих кранов с подвижным составом и согласованию с организацией, в ведении которой находится организация движения на железнодорожных путях",
          "Допускается только при наличии согласования работ с территориальным органом Ростехнадзора",
          "Не допускается",
        ],
        correctAnswers: [
          "Допускается только после разработки мероприятий по предупреждению столкновения работающих кранов с подвижным составом и согласованию с организацией, в ведении которой находится организация движения на железнодорожных путях",
        ],
      },
      {
        code: "69307",
        text: "В каких случаях к акту сдачи-приемки рельсового пути, определяющему его готовность к эксплуатации, должны прикладываться данные планово-высотной съемки?",
        answers: [
          "Только после планового ремонта",
          "Только после модернизации",
          "Только после реконструкции",
          "Только после проведения комплексного обследования его состояния",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "69308",
        text: "Какой документ подтверждает готовность рельсового пути к эксплуатации, в том числе после ремонта (реконструкции)?",
        answers: [
          "Акт осмотра",
          "Распоряжение на ввод в эксплуатацию",
          "Акт сдачи-приемки",
          "Протокол испытаний",
        ],
        correctAnswers: ["Акт сдачи-приемки"],
      },
      {
        code: "69309",
        text: "В каких случаях рельсовые пути ПС, передвигающихся по рельсам, должны подвергаться ремонту?",
        answers: [
          "После истечения срока эксплуатации ПС",
          "После проведения внеочередных технических освидетельствований",
          "При необходимости",
          "После проведения комплексных обследований",
          "После установки на рельсовых путях дополнительных ПС",
        ],
        correctAnswers: ["При необходимости"],
      },
      {
        code: "69310",
        text: "Что включает в себя проверка состояния рельсового пути, находящегося в эксплуатации? Укажите все правильные ответы.",
        answers: [
          "Ежесменный осмотр",
          "Плановую или внеочередную проверку состояния",
          "Испытания контрольным грузом",
          "Проверку соответствия проектной документации",
        ],
        correctAnswers: [
          "Ежесменный осмотр",
          "Плановую или внеочередную проверку состояния",
        ],
      },
      {
        code: "69311",
        text: "Кем осуществляется ежесменный осмотр рельсового пути ПС?",
        answers: [
          "Начальником производственного цеха или участка",
          "Службой эксплуатации, являющейся ответственной за состояние рельсовых путей",
          "Крановщиком (оператором) в объеме, предусмотренном производственной инструкцией",
          "Инженерно-техническим работником, ответственным за безопасное производство работ с применением ПС",
        ],
        correctAnswers: [
          "Крановщиком (оператором) в объеме, предусмотренном производственной инструкцией",
        ],
      },
      {
        code: "69312",
        text: "Когда проводится осмотр состояния рельсовых путей ПС под руководством специалиста, ответственного за содержание ПС в работоспособном состоянии?",
        answers: [
          "После каждых 24 смен работы",
          "После каждых 12 смен работы",
          "После каждых 32 смен работы",
          "После каждых 48 смен работы",
        ],
        correctAnswers: ["После каждых 24 смен работы"],
      },
      {
        code: "69313",
        text: "С какой периодичностью проводится плановая проверка состояния рельсового пути?",
        answers: [
          "Не реже одного раза в три года",
          "Не реже одного раза в квартал",
          "Не реже одного раза в год",
          "Не реже одного раза в полугодие",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "69314",
        text: "С какой периодичностью результаты осмотров рельсовых путей заносятся в вахтенные журналы крановщика (оператора) всех ПС, установленных на одном рельсовом пути?",
        answers: [
          "После каждых 18 смен работы",
          "После каждых 14 смен работы",
          "После каждых 24 смен работы",
          "После каждых 16 смен работы",
        ],
        correctAnswers: ["После каждых 24 смен работы"],
      },
      {
        code: "69315",
        text: "В каких случаях внеочередная проверка наземных рельсовых путей проводится в объеме плановой проверки?",
        answers: [
          "По распоряжению территориального органа Ростехнадзора",
          "По окончании летнего периода",
          "После продолжительных ливней или зимних оттепелей, отрицательно влияющих на состояние земляного полотна и балластного слоя",
          "После длительных морозов или метелей",
          "После 10 смен работы ПС в условиях высоких температур (свыше 36 °C)",
        ],
        correctAnswers: [
          "После продолжительных ливней или зимних оттепелей, отрицательно влияющих на состояние земляного полотна и балластного слоя",
        ],
      },
      {
        code: "69316",
        text: "Какая организация осуществляет периодическое комплексное обследование рельсовых путей ПС?",
        answers: [
          "Эксплуатирующая ПС организация",
          "Экспертная организация",
          "Специализированная организация",
          "Ростехнадзор",
        ],
        correctAnswers: ["Специализированная организация"],
      },
      {
        code: "69317",
        text: "С какой периодичностью должно проводиться комплексное обследование рельсовых путей ПС?",
        answers: [
          "Не реже одного раза в три года",
          "Не реже одного раза в пять лет",
          "Не реже одного раза в семь лет",
          "Не реже одного раза в десять лет",
        ],
        correctAnswers: ["Не реже одного раза в три года"],
      },
      {
        code: "69318",
        text: "Какое количество ветвей для стропов с числом ветвей более трех учитывают в расчете их грузоподъемности?",
        answers: [
          "Не более двух ветвей",
          "Не более трех ветвей",
          "Не более четырех ветвей",
          "Все ветви, если груз крупногабаритный и несимметричный",
          "В соответствии с руководством (инструкцией) по эксплуатации стропа",
        ],
        correctAnswers: ["Не более трех ветвей"],
      },
      {
        code: "69319",
        text: "С какой периодичностью производятся частичная разборка, осмотр и ревизия элементов, узлов и соединений грузозахватных приспособлений (клещи, траверсы, захваты) для контроля технического состояния, которое невозможно определить в собранном виде?",
        answers: ["Ежемесячно", "Ежеквартально", "Ежегодно", "Ежесменно"],
        correctAnswers: ["Ежегодно"],
      },
      {
        code: "69320",
        text: "С какой нагрузкой по отношению к номинальной паспортной грузоподъемности должна проводиться проверка качества выполненного ремонта грузозахватных приспособлений с проведением статических испытаний?",
        answers: ["1,25", "1,5", "1,1", "2"],
        correctAnswers: ["1,25"],
      },
      {
        code: "69321",
        text: "С какой периодичностью в эксплуатации следует осматривать траверсы, клещи, захваты и тару?",
        answers: [
          "Ежеквартально",
          "Ежедневно",
          "Каждые 10 дней",
          "Каждый месяц",
          "Ежегодно",
        ],
        correctAnswers: ["Каждый месяц"],
      },
      {
        code: "69322",
        text: "В какой документ вносится запись о результатах осмотра съемных грузозахватных приспособлений и тары?",
        answers: [
          "Акт осмотра",
          "Протокол осмотра",
          "Журнал осмотра грузозахватных приспособлений",
          "Документальная фиксация осмотра не предусмотрена",
        ],
        correctAnswers: ["Журнал осмотра грузозахватных приспособлений"],
      },
      {
        code: "69323",
        text: "При каких величинах суммарной массы тары с перемещаемым грузом допускается применение башенных кранов с тарой, разгружаемой на весу, в пределах группы классификации (режима), указанного в паспорте крана, при числе циклов работы крана не более 8 в час?",
        answers: [
          "Для тары без вибраторов (исключая грейферы) - в пределах грузоподъемности крана",
          "Для одноканатных грейферов, не допускающих разгрузку на весу, - не более 50 % грузоподъемности крана",
          "Для тары с вибратором - не более 50 % от максимальной грузоподъемности крана",
          "Применение допускается во всех перечисленных случаях",
        ],
        correctAnswers: [
          "Применение допускается во всех перечисленных случаях",
        ],
      },
      {
        code: "69324",
        text: "Каким образом должны быть расположены ветви многоветвевых стропов при испытаниях?",
        answers: [
          "Под углом 90º по вертикали друг к другу",
          "Под углом 60º по вертикали друг к другу",
          "Под углом 45º по вертикали друг к другу",
          "Под углом 30º по вертикали друг к другу",
        ],
        correctAnswers: ["Под углом 90º по вертикали друг к другу"],
      },
      {
        code: "69325",
        text: "Какое допускается максимальное отклонение по массе изделий, являющихся составной частью испытательного груза, при испытании специальных грузозахватных приспособлений?",
        answers: ["0,07", "0,03", "0,05", "0,04"],
        correctAnswers: ["0,03"],
      },
      {
        code: "69326",
        text: "Какие требования к статическим испытаниям грузозахватного приспособления указаны верно?",
        answers: [
          "Испытательный груз, зацепленный (охваченный, обвязанный) испытываемым грузозахватным приспособлением или подвешенный к нему, с возможно меньшими ускорениями поднимают ПС на высоту 150 - 250 мм",
          "Испытательный груз, зацепленный (охваченный, обвязанный) испытываемым грузозахватным приспособлением или подвешенный к нему, с ускорениями поднимается на высоту 20 - 50 мм и выдерживается в таком положении не менее 10 минут",
          "Испытательный груз, зацепленный (охваченный, обвязанный) испытываемым грузозахватным приспособлением или подвешенный к нему, с возможно меньшими ускорениями поднимается на высоту 50 - 100 мм и выдерживается в таком положении не менее 15 минут",
          "Испытательный груз, зацепленный (охваченный, обвязанный) испытываемым грузозахватным приспособлением, с возможно меньшими ускорениями поднимается на высоту 50 - 150 мм и выдерживается в таком положении не менее 15 минут",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "69327",
        text: "Каким документом оформляются результаты испытаний грузозахватных приспособлений от приложения статической нагрузкой?",
        answers: [
          "Только актом испытания",
          "Только протоколом испытания",
          "Актом или протоколом",
          "Распоряжением о проведенном полном техническом освидетельствовании",
          "Положительным заключением экспертизы промышленной безопасности",
        ],
        correctAnswers: ["Актом или протоколом"],
      },
      {
        code: "69328",
        text: "В каких случаях разрешены подъем и транспортировка людей с применением ПС, в паспорте которых отсутствует разрешение на транспортировку людей?",
        answers: [
          "Только в случае монтажа и обслуживания отдельно стоящих буровых и иных установок нефтегазодобычи",
          "Только в случае перемещения персонала для крепления и раскрепления контейнеров и грузов",
          "Только в случае проведения диагностирования и ремонта металлоконструкций ПС, когда применение других средств подмащивания невозможно",
          "Только в случае аварийной транспортировки людей, которые не в состоянии передвигаться самостоятельно",
          "Во всех перечисленных",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "69329",
        text: "Какие действия не включает в себя проверка состояния люльки (кабины)?",
        answers: [
          "Ежесменный осмотр",
          "Грузовые испытания",
          "Плановую проверку состояния",
          "Динамические испытания",
          "Внеплановую проверку состояния, если люлька (кабина) не эксплуатировалась более полугода",
        ],
        correctAnswers: ["Динамические испытания"],
      },
      {
        code: "69330",
        text: "По каким точкам грузовой характеристики должна проводиться проверка ограничителя грузового момента, если грузоподъемность ПС изменяется в зависимости от вылета, положения грузовой тележки или пространственного положения элемента ПС?",
        answers: [
          "Не менее чем в двух точках его грузовой характеристики",
          "Не менее чем в трех точках его грузовой характеристики",
          "Только в двух точках его грузовой характеристики",
          "Проверка ограничителя грузового момента нормативными документами не регламентируется",
        ],
        correctAnswers: [
          "Не менее чем в трех точках его грузовой характеристики",
        ],
      },
      {
        code: "69331",
        text: "Какие требования к проверке ограничителя предельного верхнего положения грузозахватного органа указаны неверно?",
        answers: [
          "Проверка производится путем контроля с замером расстояния между верхней точкой грузозахватного органа и упором",
          "Проверка производится путем контроля с замером расстояния между верхней точкой грузозахватного органа и нижней частью металлоконструкции (после остановки механизма)",
          "Проверка осуществляется путем контроля с замером расстояния между нижней точкой грузозахватного органа и упором",
          "Проверка проводится без груза",
        ],
        correctAnswers: [
          "Проверка осуществляется путем контроля с замером расстояния между нижней точкой грузозахватного органа и упором",
        ],
      },
      {
        code: "69332",
        text: "Согласно требованиям какого документа должна выполняться оценка работоспособности ограничителя или указателя опасного приближения к линии электропередачи при эксплуатации ПС?",
        answers: [
          "Заключения экспертизы промышленной безопасности",
          "Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения",
          "Паспорта",
          "Руководства (инструкции) по эксплуатации",
        ],
        correctAnswers: [
          "Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения",
        ],
      },
      {
        code: "69333",
        text: "В соответствии с требованиями какого документа выполняется проверка работоспособности указателя (сигнализатора) предельной скорости ветра (анемометра) и креномера (указателя угла наклона ПС)?",
        answers: [
          "Руководств (инструкций) по эксплуатации анемометра и креномера",
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
          "Руководства (инструкции) по эксплуатации ПС",
          "Руководства по качеству организации и выполнения калибровочных работ",
        ],
        correctAnswers: [
          "Руководств (инструкций) по эксплуатации анемометра и креномера",
        ],
      },
      {
        code: "69334",
        text: "Согласно требованиям какого документа должна выполняться проверка работоспособности регистратора параметров работы ПС?",
        answers: [
          "Руководства (инструкции) по эксплуатации",
          "Заключения экспертизы промышленной безопасности",
          "Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения",
          "Паспорта",
        ],
        correctAnswers: ["Руководства (инструкции) по эксплуатации"],
      },
      {
        code: "69335",
        text: "Что из перечисленного является условием для браковки каната крана, подвергавшегося поверхностному изнашиванию или коррозии?",
        answers: [
          "Уменьшение диаметра каната на 3 % по сравнению с номинальным диаметром",
          "Уменьшение диаметра каната на 7 % и более по сравнению с номинальным диаметром даже при отсутствии видимых обрывов проволок",
          "Уменьшение диаметра каната на 4 % по сравнению с номинальным диаметром",
          "Уменьшение диаметра каната на 6 % и более по сравнению с номинальным диаметром только при наличии видимых обрывов проволок",
          "Уменьшение диаметра каната на 5 % по сравнению с номинальным диаметром",
        ],
        correctAnswers: [
          "Уменьшение диаметра каната на 7 % и более по сравнению с номинальным диаметром даже при отсутствии видимых обрывов проволок",
        ],
      },
      {
        code: "69336",
        text: "При каком удлинении звена цепи от первоначального размера цепной строп подлежит браковке?",
        answers: [
          "Более 1,5% от первоначального размера",
          "Более 2,0% от первоначального размера",
          "Более 2,5% от первоначального размера",
          "Более 3,0% от первоначального размера",
        ],
        correctAnswers: ["Более 3,0% от первоначального размера"],
      },
      {
        code: "69337",
        text: "В каком случае допускается эксплуатация текстильных стропов на полимерной основе?",
        answers: [
          "Имеются продольные порезы или разрывы ленты, суммарная длина которых превышает 10 % длины ленты ветви стропа",
          "Присутствует выпучивание нитей из ленты стропа на расстояние более 10 % ширины ленты",
          "Имеются поверхностные обрывы нитей ленты общей длиной более 10 % ширины ленты, вызванные механическим воздействием (трением) острых кромок груза",
          "Отсутствует клеймо (бирка) или не читаются сведения о стропе, которые содержат информацию об изготовителе, грузоподъемности",
          "Эксплуатация запрещается во всех перечисленных случаях",
        ],
        correctAnswers: [
          "Эксплуатация запрещается во всех перечисленных случаях",
        ],
      },
      {
        code: "69338",
        text: "Чему равен допустимый остаточный прогиб пролетного строения кранов мостового типа в вертикальной плоскости, где L - пролет крана?",
        answers: ["0,002L", "0,0035L", "0,005L", "0,0055L"],
        correctAnswers: ["0,0035L"],
      },
      {
        code: "69339",
        text: "Какой износ головки рельса является условием для браковки кранового пути опорных кранов?",
        answers: [
          "15% и более от величины соответствующего размера неизношенного профиля",
          "22% и более от величины соответствующего размера неизношенного профиля",
          "17% и более от величины соответствующего размера неизношенного профиля",
          "30% и более от величины соответствующего размера неизношенного профиля",
          "25% и более от величины соответствующего размера неизношенного профиля",
        ],
        correctAnswers: [
          "15% и более от величины соответствующего размера неизношенного профиля",
        ],
      },
      {
        code: "69340",
        text: "Требованиям какого документа должны соответствовать общие требования к утилизации (ликвидации) ПС? Укажите все правильные ответы.",
        answers: [
          "Проектной документации",
          "Формуляра ПС",
          "Руководства (инструкции) по эксплуатации ПС",
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
        ],
        correctAnswers: [
          "Руководства (инструкции) по эксплуатации ПС",
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
        ],
      },
      {
        code: "69341",
        text: "С учетом требований какого документа должна выполняться утилизация (ликвидация) ПС? Укажите все правильные ответы.",
        answers: [
          'Федерального закона "О промышленной безопасности опасных производственных объектов" от 21 июля 1997 г. N 116-ФЗ',
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
          "Руководства (инструкции) по эксплуатации ПС",
          'Федеральных норм и правил в области промышленной безопасности "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения"',
        ],
        correctAnswers: [
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
          "Руководства (инструкции) по эксплуатации ПС",
        ],
      },
      {
        code: "69342",
        text: "Какие действия необходимо выполнить для утилизации (ликвидации) подъемника (вышки)?",
        answers: [
          "Согласовать ППР в территориальном органе Ростехнадзора",
          "Получить письменное распоряжение владельца ОПО",
          "Провести диагностику состояния ПС",
          "Демонтировать подъемник (вышку)",
          "Провести внеочередное комплексное обследование рельсовых путей",
        ],
        correctAnswers: ["Демонтировать подъемник (вышку)"],
      },
      {
        code: "69343",
        text: "В каких организациях, эксплуатирующих подъемники (вышки), должны быть разработаны и доведены под подпись до каждого работника инструкции, определяющие действия работников в аварийных ситуациях?",
        answers: [
          "Только в организациях, эксплуатирующих ОПО, зарегистрированные в государственном реестре",
          "Только в организациях, применяющих подъемники (вышки) в стесненных условиях",
          "Только в организациях, эксплуатирующих подъемники (вышки) одновременно с другими ПС, подлежащими постановке на учет",
          "Только в организациях, стационарно применяющих подъемники (вышки) для обслуживания технологического оборудования, находящегося в эксплуатации",
          "Во всех организациях, эксплуатирующих подъемники (вышки)",
        ],
        correctAnswers: [
          "Во всех организациях, эксплуатирующих подъемники (вышки)",
        ],
      },
      {
        code: "69344",
        text: "Какие из перечисленных сведений должны быть указаны в инструкциях, определяющих действия работников в аварийных ситуациях в организации, эксплуатирующей ОПО с ПС?",
        answers: [
          "Оперативные действия по предотвращению и локализации аварий",
          "Порядок использования системы пожаротушения в случае локальных возгораний оборудования ОПО",
          "Порядок приведения ПС в безопасное положение в нерабочем состоянии, схема и порядок эвакуации крановщика (оператора), покидающего кабину управления ПС",
          "Места, отведенные в ОПО, для нахождения ПС в нерабочем состоянии",
          "Все перечисленные сведения",
        ],
        correctAnswers: ["Все перечисленные сведения"],
      },
      {
        code: "69345",
        text: "Кого в обязательном порядке должны информировать работники ОПО, непосредственно занимающиеся эксплуатацией ПС, об угрозе возникновения аварийной ситуации?",
        answers: [
          "Специалиста, ответственного за осуществление производственного контроля при эксплуатации ПС",
          "Специалиста, ответственного за содержание ПС в работоспособном состоянии",
          "Своего непосредственного руководителя",
          "Специалиста по охране труда",
          "Представителя территориального органа Ростехнадзора",
        ],
        correctAnswers: ["Своего непосредственного руководителя"],
      },
    ],
    711: [
      {
        code: "69336",
        text: "Требованиям какого документа должны соответствовать общие требования к транспортировке и хранению ПС, их отдельных сборочных единиц, материалов и комплектующих для их ремонта, реконструкции и (или) модернизации?",
        answers: [
          "Паспорта ПС",
          "Технических условий ПС",
          "Руководства (инструкция) по эксплуатации ПС",
          "Формуляра ПС",
        ],
        correctAnswers: ["Руководства (инструкция) по эксплуатации ПС"],
      },
      {
        code: "69337",
        text: "Какие меры промышленной безопасности должны быть приняты для ПС, установленных на открытом воздухе и находящихся в нерабочем состоянии?",
        answers: [
          "ПС должны быть обесточены и приняты меры по предотвращению их угона ветром",
          "Если давление в колесах не соответствует норме, ПС должны быть установлены на аутригеры",
          "Меры промышленной безопасности определяются ветровым районом установки ПС",
          "Меры промышленной безопасности определяются ветровым районом и высотой установки ПС над уровнем моря",
          "ПС должны быть установлены на аутригеры, а стрела на -  минимальный вылет",
        ],
        correctAnswers: [
          "ПС должны быть обесточены и приняты меры по предотвращению их угона ветром",
        ],
      },
      {
        code: "69338",
        text: 'На какие из перечисленных ниже опасных производственных объектов (далее - ОПО) не распространяются требования Федеральных норм и правил в области промышленной безопасности "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения"?',
        answers: [
          "На ОПО, где эксплуатируются канатные дороги",
          "На ОПО, где эксплуатируются краны-манипуляторы",
          "На ОПО, где эксплуатируются краны-экскаваторы, предназначенные для работы с крюком",
          "На ОПО, где эксплуатируются электрические тали",
        ],
        correctAnswers: ["На ОПО, где эксплуатируются канатные дороги"],
      },
      {
        code: "69339",
        text: "На какие из перечисленных ОПО не распространяются требования Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения?",
        answers: [
          "На ОПО, где эксплуатируются мостовые краны-штабелеры",
          "На ОПО, где эксплуатируются краны-манипуляторы",
          "На ОПО, где эксплуатируются канатные дороги",
          "На ОПО, где эксплуатируются подъемники (вышки), предназначенные для перемещения людей, людей и груза (подъемники с рабочими платформами)",
          "На ОПО, где эксплуатируются электрические тали",
        ],
        correctAnswers: ["На ОПО, где эксплуатируются канатные дороги"],
      },
      {
        code: "69340",
        text: "На какие из перечисленных ОПО распространяются требования Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения?",
        answers: [
          "На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления",
          "На ОПО, где эксплуатируются ПС, установленные в шахтах и на любых плавучих средствах",
          "На ОПО, где эксплуатируются домкраты",
          "На ОПО, где эксплуатируются манипуляторы, используемые в технологических процессах",
          "На ОПО, где эксплуатируются ПС, предназначенные для работы только с навесным оборудованием (вибропогружателями, шпунтовыдергивателями, буровым оборудованием)",
        ],
        correctAnswers: [
          "На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления",
        ],
      },
      {
        code: "69341",
        text: "Какой документ подтверждает соответствие ПС требованиям технических регламентов?",
        answers: [
          "Паспорт ПС",
          "Руководство (инструкция) по эксплуатации ПС",
          "Сертификат или декларация соответствия",
          "Акт выполнения монтажных работ",
        ],
        correctAnswers: ["Сертификат или декларация соответствия"],
      },
      {
        code: "69342",
        text: 'Каким нормативным правовым актом регламентируются обязательные для применения требования для ПС, введенных в обращение до вступления в силу Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"?',
        answers: [
          "Ранее действующими правилами устройства и безопасной эксплуатации ПС Госгортехнадзора России для всех стадий жизненного цикла этих ПС",
          "Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения, для всех стадий жизненного цикла этих ПС",
          "Ранее действующими правилами устройства и безопасной эксплуатации ПС Госгортехнадзора России для проектирования и изготовления этих ПС, а для остальных стадий жизненного цикла ПС - ФНП ПС",
          'Такие ПС должны быть приведены в соответствие с требованиями ТР ТС 010/2011 "О безопасности машин и оборудования"',
        ],
        correctAnswers: [
          "Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения, для всех стадий жизненного цикла этих ПС",
        ],
      },
      {
        code: "69343",
        text: "Какие из перечисленных ПС не подлежат учету в органах Ростехнадзора?",
        answers: [
          "Автомобильные краны",
          "Краны мостового типа",
          "Краны на железнодорожном ходу",
          "Краны-трубоукладчики",
        ],
        correctAnswers: ["Краны-трубоукладчики"],
      },
      {
        code: "69344",
        text: "Каким документом должна определяться численность инженерно-технических работников эксплуатирующей организации?",
        answers: [
          "Внутренним распорядительным актом эксплуатирующей организации",
          "Приказом территориального органа Ростехнадзора",
          "Распоряжением уполномоченного представителя Ростехнадзора",
          "Решением комиссии эксплуатирующей организации, в состав которой должен входить представитель Ростехнадзора",
        ],
        correctAnswers: [
          "Внутренним распорядительным актом эксплуатирующей организации",
        ],
      },
      {
        code: "69345",
        text: "В каком документе содержатся результаты работы комиссии, принимающей решение о возможности пуска ПС в работу?",
        answers: [
          "В акте пуска ПС в работу",
          "В руководстве (инструкции) по эксплуатации ПС",
          "В журнале осмотра подъемных сооружений",
          "В журнале осмотра, технического обслуживания и ремонта грузоподъемных кранов",
        ],
        correctAnswers: ["В акте пуска ПС в работу"],
      },
      {
        code: "69346",
        text: "Какие обязанности эксплуатирующей ПС организации указаны неверно?",
        answers: [
          "Устанавливать порядок контроля обучения и периодической проверки знаний персонала, работающего с ограничителями, указателями и регистраторами",
          "Обеспечивать соблюдение технологического процесса транспортировки грузов и приостановку работы ПС в случае возникновения угрозы аварийной ситуации",
          'При выявлении нарушений требований к эксплуатации ПС, изложенных в ФНП "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения", принимать меры по их устранению и предупреждению, в том числе проводить внеочередную проверку знаний работников, допустивших такие нарушения',
          "При отсутствии в эксплуатационных документах регистраторов указаний о сроках считывания данных выполнять такие операции не реже одного раза в год",
        ],
        correctAnswers: [
          "При отсутствии в эксплуатационных документах регистраторов указаний о сроках считывания данных выполнять такие операции не реже одного раза в год",
        ],
      },
      {
        code: "69347",
        text: "Какие из перечисленных ПС подлежат учету в органах Ростехнадзора?",
        answers: [
          "Мостовые краны-штабелеры",
          "ПС, используемые в учебных целях на полигонах учебных заведений",
          "Краны стрелового типа с постоянным вылетом или не оборудованные механизмом поворота",
          "Подъемники и вышки, предназначенные для перемещения людей",
        ],
        correctAnswers: [
          "Подъемники и вышки, предназначенные для перемещения людей",
        ],
      },
      {
        code: "69348",
        text: "Имеет ли право организация, эксплуатирующая ОПО с ПС, привлекать специалистов сторонних организаций в качестве: специалистов, ответственных за осуществление производственного контроля при эксплуатации ПС; специалистов, ответственных за содержание ПС в работоспособном состоянии; специалистов, ответственных за безопасное производство работ с применением ПС?",
        answers: [
          "Имеет право привлекать всех указанных специалистов",
          "Имеет право привлекать специалиста, ответственного за содержание ПС в работоспособном состоянии, и специалиста, ответственного за безопасное производство работ с применением ПС",
          "Имеет право привлекать только специалиста, ответственного за безопасное производство работ с применением ПС",
          "Не имеет права",
          "Имеет право привлекать только специалиста, ответственного за содержание ПС в работоспособном состоянии",
        ],
        correctAnswers: ["Не имеет права"],
      },
      {
        code: "69349",
        text: "Кого в обязательном порядке должны информировать работники ОПО, непосредственно занимающиеся эксплуатацией ПС, об угрозе возникновения аварийной ситуации?",
        answers: [
          "Инженерно-технического работника, ответственного за осуществление производственного контроля при эксплуатации ПС",
          "Инженерно-технического работника, ответственного за содержание ПС в работоспособном состоянии",
          "Своего непосредственного руководителя",
          "Руководителя эксплуатирующей организации",
          "Руководителя ОПО",
        ],
        correctAnswers: ["Своего непосредственного руководителя"],
      },
      {
        code: "69350",
        text: "Какая организация осуществляет периодическое комплексное обследование рельсовых путей ПС?",
        answers: [
          "Специализированная организация",
          "Организация, эксплуатирующая ПС",
          "Организация-изготовитель",
          "Проектная организация",
        ],
        correctAnswers: ["Специализированная организация"],
      },
      {
        code: "69351",
        text: "Требованиям какого документа должны соответствовать общие требования к утилизации (ликвидации) ПС? Укажите все правильные ответы.",
        answers: [
          "Технического регламента ТР ТС 010/2011",
          "Руководства (инструкции) по эксплуатации ПС",
          "Паспорта ПС",
          "ППР",
        ],
        correctAnswers: [
          "Технического регламента ТР ТС 010/2011",
          "Руководства (инструкции) по эксплуатации ПС",
        ],
      },
      {
        code: "69352",
        text: "В каких организациях, эксплуатирующих подъемники (вышки), должны быть разработаны и доведены под подпись до каждого работника инструкции, определяющие действия работников в аварийных ситуациях?",
        answers: [
          "Только в организациях, эксплуатирующих подъемники (вышки) в охранной зоне воздушных линий электропередачи",
          "Только в организациях, эксплуатирующих ПС, подлежащие регистрации в Ростехнадзоре",
          "Только в организациях, эксплуатирующих подъемники (вышки) в стесненных условиях",
          "Во всех организациях, эксплуатирующих подъемники (вышки)",
          "Только в организациях, эксплуатирующих ОПО, зарегистрированные в государственном реестре",
        ],
        correctAnswers: [
          "Во всех организациях, эксплуатирующих подъемники (вышки)",
        ],
      },
      {
        code: "69353",
        text: "Что должно быть предпринято в случае, когда зона, обслуживаемая ПС, полностью не просматривается из кабины управления (с места управления), и при отсутствии между оператором (крановщиком) и стропальщиком радио- или телефонной связи?",
        answers: [
          "Должен быть назначен сигнальщик из числа стропальщиков",
          "Должна быть остановлена работа",
          "Должны быть переданы сигналы голосом",
          "Должен быть разработан план действий для крановщика и стропальщика",
        ],
        correctAnswers: [
          "Должен быть назначен сигнальщик из числа стропальщиков",
        ],
      },
      {
        code: "69354",
        text: "Каким требованиям промышленной безопасности должна отвечать люлька для подъема и транспортировки людей кранами в случаях, когда транспортируемый персонал занимает положение у одной из сторон люльки, создавая наибольший опрокидывающий момент?",
        answers: [
          "Исключать возможность недопустимого раскачивания",
          "Исключать возможность крена",
          "Исключать возможность опрокидывания",
          "Иметь специальные устройства для сохранения горизонтального положения пола",
          "Ограничивать положение персонала своей центральной частью после начала ее подъема краном",
        ],
        correctAnswers: ["Исключать возможность опрокидывания"],
      },
      {
        code: "69355",
        text: "В каких случаях необходимо прекращать работу ПС, установленных на открытом воздухе?",
        answers: [
          "При скорости ветра, превышающей предельно допустимую скорость, указанную в паспорте ПС",
          "При температуре окружающей среды ниже предельно допустимой температуры, указанной в паспорте ПС",
          "При снегопаде, дожде, тумане в случаях, когда крановщик (машинист, оператор) плохо различает сигналы стропальщика или перемещаемый груз",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "69356",
        text: "В каких случаях разрешено использовать тару для транспортировки людей?",
        answers: [
          "Если это указано в паспорте ПС или тары",
          "Если произошла авария и необходимо транспортировать пострадавшего, который не может самостоятельно передвигаться, с верхних ярусов здания",
          "Запрещено во всех случаях",
          "Если проводится осмотр или экспертиза промышленной безопасности металлоконструкций ПС",
          "Если есть письменное разрешение эксплуатирующей организации",
        ],
        correctAnswers: ["Запрещено во всех случаях"],
      },
      {
        code: "69357",
        text: "Куда записывается решение о вводе в эксплуатацию специальных съемных кабин и люлек (для подъема и перемещения людей кранами)?",
        answers: [
          "В журнал учета и периодического осмотра СГП и тары",
          "В распорядительный акт эксплуатирующей организации",
          "На бирку люльки",
          "В паспорт кабины или люльки",
        ],
        correctAnswers: ["В паспорт кабины или люльки"],
      },
      {
        code: "69358",
        text: "Каким способом должны быть закреплены концы канатных стропов подвески люльки, используемой для подъема и транспортировки кранами людей?",
        answers: [
          "Одним из способов, разрешенных нормативными документами по изготовлению стропов",
          "При помощи заплетенных коушей или коушей с зажимами",
          "При помощи обжимных втулок",
          "При помощи заплетенных коушей или обжимных втулок",
          "При помощи коушей с зажимами или обжимных втулок",
        ],
        correctAnswers: ["При помощи заплетенных коушей или коушей с зажимами"],
      },
      {
        code: "69359",
        text: "В каких случаях между крановщиком и людьми, транспортируемыми в подвесной люльке краном, должна быть установлена постоянная телефонная или радиосвязь?",
        answers: [
          "Во всех случаях",
          "Если крановщик видит люльку с людьми не в течение всей операции транспортировки",
          "Если крановщик видит люльку с людьми во время всей операции транспортировки, но ему недоступна для обзора зона начала подъема или зона опускания люльки",
          "Если это дополнительно указано в ППР с перемещением люльки",
          "Если транспортировка людей в люльке осуществляется при неблагоприятных погодных условиях",
        ],
        correctAnswers: ["Во всех случаях"],
      },
      {
        code: "69360",
        text: "Какова должна быть длина фала страховочного устройства человека, соединяющего его пояс с местом крепления в подвесной люльке при ее транспортировке краном?",
        answers: [
          "Не более 1,5 м",
          "Не более 2 м",
          "Длина фала страховочного устройства должна быть такой, чтобы человек в случае аварии люльки мог беспрепятственно выбраться наружу",
          "Длина фала страховочного устройства должна быть такой, чтобы человек в любом случае оставался в пределах люльки",
          "Длина фала определяется требованиями руководства по эксплуатации (инструкции) люльки",
        ],
        correctAnswers: [
          "Длина фала страховочного устройства должна быть такой, чтобы человек в любом случае оставался в пределах люльки",
        ],
      },
      {
        code: "69361",
        text: "В каких случаях разрешается нахождение инструментов и материалов совместно с людьми в подвесных люльках, транспортируемых кранами?",
        answers: [
          "Во всех случаях, если инструменты и материалы надежно закреплены",
          "В случае если это позволяет грузоподъемность люльки",
          "Ни в каком случае",
          "В случае если инструменты и материалы находятся в руках транспортируемых людей",
          "В случае транспортировки людей для проведения диагностирования и ремонта металлоконструкций ПС, когда применение других средств подмащивания невозможно",
        ],
        correctAnswers: [
          "Во всех случаях, если инструменты и материалы надежно закреплены",
        ],
      },
      {
        code: "69362",
        text: "Как следует поступить, если во время грузовых испытаний люльки для транспортировки людей кранами выявлены дефекты и повреждения?",
        answers: [
          "Утилизировать люльку",
          "Запретить дальнейшую эксплуатацию люльки",
          "Эксплуатировать люльку до выполнения ремонта с ограничением по грузоподъемности",
          "Поставить в известность (письменно) руководителя эксплуатирующей организации о выявленных дефектах и повреждениях люльки",
          "Поставить в известность (под подпись) о выявленных дефектах и повреждениях люльки руководителя ремонтной службы эксплуатирующей организации",
        ],
        correctAnswers: ["Запретить дальнейшую эксплуатацию люльки"],
      },
      {
        code: "69363",
        text: "Куда следует заносить результаты плановых осмотров люльки, предназначенной для транспортировки людей кранами?",
        answers: [
          "В паспорт люльки",
          "В паспорт крана",
          "В журнал осмотра люльки",
          "В вахтенный журнал",
          "Никуда, поскольку записи следует делать только по результатам проведенных технических освидетельствований",
        ],
        correctAnswers: ["В журнал осмотра люльки"],
      },
      {
        code: "69364",
        text: "Каким образом должна поддерживаться связь между персоналом в люльке и крановщиком (оператором) при подъеме люльки на высоту более 22 метров?",
        answers: [
          "Голосом",
          "Знаковой сигнализацией",
          "Радио- или телефонной связью",
          "Любым из перечисленных способов",
        ],
        correctAnswers: ["Радио- или телефонной связью"],
      },
      {
        code: "69365",
        text: "В каких случаях должна поддерживаться радио- или телефонная связь между оператором подъемника и персоналом в люльке?",
        answers: [
          "При подъеме люльки на высоту более 15 метров",
          "При подъеме люльки на высоту более 22 метров",
          "При работе подъемника в стесненных условиях",
          "При работе подъемника в темное время суток",
          "При работе подъемника вблизи ЛЭП",
        ],
        correctAnswers: ["При подъеме люльки на высоту более 22 метров"],
      },
      {
        code: "69366",
        text: "Какие из перечисленных ниже нарушений не могут служить причиной остановки эксплуатации подъемника?",
        answers: [
          "ПС не соответствует технологическому процессу, в котором задействовано",
          "Не выполнены предписания по обеспечению безопасной эксплуатации подъемника, выданные эксплуатирующей организацией",
          "Отсутствует экспертиза промышленной безопасности нового подъемника, введенного в эксплуатацию",
          "Не проведено техническое освидетельствование ПС",
        ],
        correctAnswers: [
          "Отсутствует экспертиза промышленной безопасности нового подъемника, введенного в эксплуатацию",
        ],
      },
      {
        code: "69367",
        text: "Какие действия необходимо выполнить для утилизации (ликвидации) подъемника (вышки)?",
        answers: [
          "Направить письменное обращение в Ростехнадзор",
          "Получить письменное указание эксплуатирующей организации и отправить в металлолом",
          "Демонтировать подъемник (вышку)",
          "Запретить эксплуатацию подъемника (вышки) по истечении срока службы, указанного в его паспорте",
          "Отказаться от ремонта подъемника (вышки), предписанного его экспертизой промышленной безопасности",
        ],
        correctAnswers: ["Демонтировать подъемник (вышку)"],
      },
      {
        code: "69368",
        text: "Кем осуществляется ежесменный осмотр рельсового пути ПС?",
        answers: [
          "Крановщиком (оператором) в объеме, предусмотренном производственной инструкцией",
          "Инженерно-техническим работником, ответственным за осуществление производственного контроля при эксплуатации ПС",
          "Инженерно-техническим работником, ответственным за содержание ПС в работоспособном состоянии",
          "Инженерно-техническим работником, ответственным за безопасное производство работ с применением ПС",
        ],
        correctAnswers: [
          "Крановщиком (оператором) в объеме, предусмотренном производственной инструкцией",
        ],
      },
      {
        code: "69369",
        text: "Допускается ли пересечение путей козловых, башенных и портальных кранов с рельсовыми путями заводского транспорта?",
        answers: [
          "Допускается, только после разработки мероприятий по предупреждению столкновения работающих кранов с подвижным составом и согласованию с организацией, в ведении которой находится организация движения на железнодорожных путях",
          "Допускается только после согласования с организацией, в ведении которой находится организация движения на железнодорожных путях",
          "Не допускается ни при каких условиях",
          "Допускается",
        ],
        correctAnswers: [
          "Допускается, только после разработки мероприятий по предупреждению столкновения работающих кранов с подвижным составом и согласованию с организацией, в ведении которой находится организация движения на железнодорожных путях",
        ],
      },
      {
        code: "69370",
        text: "Когда результат статических испытаний крана стрелового типа считается положительным?",
        answers: [
          "Если в течение 10 минут поднятый груз не опустится на землю, а также не обнаружено трещин, остаточных деформаций и других повреждений металлоконструкций и механизмов",
          "Если в течение 5 минут поднятый груз не опустится на землю, а также не обнаружено трещин, остаточных деформаций и других повреждений металлоконструкций и механизмов",
          "Если в течение 3 минут поднятый груз не опустится на землю, а также не обнаружено трещин, остаточных деформаций и других повреждений металлоконструкций и механизмов",
          "Если поднятый груз не опустится на землю, а также не обнаружено трещин, остаточных деформаций и других повреждений металлоконструкций и механизмов",
        ],
        correctAnswers: [
          "Если в течение 10 минут поднятый груз не опустится на землю, а также не обнаружено трещин, остаточных деформаций и других повреждений металлоконструкций и механизмов",
        ],
      },
      {
        code: "69371",
        text: "В каких случаях зоны работающих ПС должны быть ограждены и обозначены предупредительными знаками, при этом нахождение в зоне работы людей не допускается?",
        answers: [
          "Во время работы ПС, оснащенных грейфером или электромагнитом",
          "Во время перемещения грузов стреловыми кранами",
          "Во время работы ПС в зоне линии электропередачи",
          "Во время перемещения грузов любыми ПС",
          "Во время работы ПС в темное время суток",
        ],
        correctAnswers: [
          "Во время работы ПС, оснащенных грейфером или электромагнитом",
        ],
      },
      {
        code: "69372",
        text: "Каким грузом следует проверять действие ловителей на строительных подъемниках?",
        answers: [
          "Масса которого на 10 % превышает паспортную грузоподъемность",
          "Масса которого на 5 % превышает паспортную грузоподъемность",
          "Масса которого на 20 % превышает паспортную грузоподъемность",
          "Номинальным грузом без перегрузки",
        ],
        correctAnswers: [
          "Масса которого на 10 % превышает паспортную грузоподъемность",
        ],
      },
      {
        code: "69373",
        text: "С кем следует согласовывать установку кранов, передвигающихся по рельсовому пути, в охранной зоне воздушных линий (далее - ВЛ) электропередачи?",
        answers: [
          "С владельцем линии",
          "С главным энергетиком эксплуатирующей ВЛ организации",
          "С территориальным органом Ростехнадзора",
          "С местным филиалом Россети",
        ],
        correctAnswers: ["С владельцем линии"],
      },
      {
        code: "69374",
        text: "Какая периодичность частичного технического освидетельствования установлена для ПС в течение всего срока службы?",
        answers: [
          "Не реже одного раза в 12 месяцев",
          "Не реже одного раза в 24 месяца",
          "Не реже одного раза в 36 месяцев",
          "Не реже одного раза в 60 месяцев",
        ],
        correctAnswers: ["Не реже одного раза в 12 месяцев"],
      },
      {
        code: "69375",
        text: "Каким должно быть безопасное расстояние от низа перемещаемого груза до перекрытий и площадок, где могут находиться люди?",
        answers: [
          "Не менее 2,3 м",
          "Не менее 2 м",
          "Не менее 1,5 м",
          "Не менее 1 м",
        ],
        correctAnswers: ["Не менее 2,3 м"],
      },
      {
        code: "69376",
        text: "Какое должно быть минимальное расстояние между поворотной частью ПС при любом его положении, в том числе в нагруженном состоянии, и строениями, штабелями грузов и другими предметами?",
        answers: ["1000 мм", "800 мм", "600 мм", "1200 мм"],
        correctAnswers: ["1000 мм"],
      },
      {
        code: "69377",
        text: "Если в руководстве (инструкции) по эксплуатации ПС отсутствуют требования к его установке на выносные опоры, то в каком случае разрешается установка подъемников (вышек) только на две или три выносные опоры?",
        answers: [
          "Не разрешается, ПС устанавливается на все выносные опоры",
          "Если одна из частей площадки установки находится под допустимым уклоном",
          "Если отсутствует место на площадке установки для всех четырех опор",
          "Если отсутствует одна из инвентарных подкладок, устанавливаемых под опору",
          "Если подъем и перемещение будут выполняться только в одном положении стрелы",
        ],
        correctAnswers: [
          "Не разрешается, ПС устанавливается на все выносные опоры",
        ],
      },
      {
        code: "69378",
        text: "Каким проверкам должны подвергаться ПС при полном техническом освидетельствовании?",
        answers: [
          "Только осмотру",
          "Только статическим испытаниям",
          "Только динамическим испытаниям",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "69379",
        text: "Если в руководстве (инструкции) по эксплуатации ПС отсутствуют требования к его установке на выносные опоры, то в каком случае разрешается установка стрелового крана, крана-манипулятора только на две или три выносные опоры?",
        answers: [
          "Если одна из частей площадки установки находится под допустимым уклоном",
          "Если отсутствует место на площадке установки для всех четырех опор",
          "Если подъем и перемещение груза будет выполняться только в одном положении стрелы",
          "Если отсутствует одна из инвентарных подкладок, устанавливаемых под опору",
          "Не разрешается, ПС устанавливается на все выносные опоры",
        ],
        correctAnswers: [
          "Не разрешается, ПС устанавливается на все выносные опоры",
        ],
      },
      {
        code: "69380",
        text: "Какие меры следует принять к установке подъемников (вышек) при невозможности соблюдения безопасных расстояний, указанных в ФНП ПС, если глубина котлована более 5 м?",
        answers: [
          "Не устанавливать подъемник (вышку) для производства работ",
          "Установить подъемник (вышку) для производства работ, если получено письменное разрешение специалиста, ответственного за безопасное производство работ с применением ПС",
          "Установить подъемник (вышку) для производства работ, если откос дополнительно укреплен в соответствии с ППР",
          "Установить подъемник (вышку) для производства работ, если присутствует специалист, ответственный за безопасное производство работ с применением ПС",
          "Установить подъемник (вышку) для производства работ, если на площадке находится сигнальщик, освобожденный от выполнения других работ",
        ],
        correctAnswers: [
          "Установить подъемник (вышку) для производства работ, если откос дополнительно укреплен в соответствии с ППР",
        ],
      },
      {
        code: "69381",
        text: "Кем определяется время действия наряда-допуска на работу подъемника (вышки) вблизи линий электропередачи?",
        answers: [
          "Организацией, эксплуатирующей линию электропередачи",
          "Организацией, его выдавшей",
          "Крановщиком (машинистом)",
          "Инженерно-техническим работником, ответственным за безопасное производство работ с применением ПС",
          "Инженерно-техническим работником, ответственным за содержание ПС в работоспособном состоянии",
        ],
        correctAnswers: ["Организацией, его выдавшей"],
      },
      {
        code: "69382",
        text: "Кто назначается председателем комиссии, на основании предложений которой принимается решение о пуске в работу ПС, отработавшего срок службы, при смене эксплуатирующей организации?",
        answers: [
          "Уполномоченный представитель Ростехнадзора",
          "Уполномоченный представитель проектной организации",
          "Уполномоченный представитель эксплуатирующей организации",
          "Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС",
        ],
        correctAnswers: [
          "Уполномоченный представитель эксплуатирующей организации",
        ],
      },
      {
        code: "69383",
        text: "Когда выдаются производственные инструкции персоналу, обслуживающему ПС?",
        answers: [
          "Перед допуском к работе, под подпись",
          "Перед прохождением повторного инструктажа",
          "После прохождения вводного инструктажа",
          "Перед прохождением первичного инструктажа на рабочем месте",
        ],
        correctAnswers: ["Перед допуском к работе, под подпись"],
      },
      {
        code: "69384",
        text: "Каким документом определяется объем работ, порядок и периодичность проведения технических освидетельствований ПС?",
        answers: [
          'Федеральными нормами и правилами в области промышленной безопасности "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения" от 12 ноября 2013 г. N 533',
          'Техническим регламентом Таможенного союза ТР ТС 010/2011 "О безопасности машин и оборудования"',
          "Паспортом ПС",
          "ППР",
          "Руководством (инструкцией) по эксплуатации ПС, а при отсутствии в нем указаний - требованиями ФНП ПС",
        ],
        correctAnswers: [
          "Руководством (инструкцией) по эксплуатации ПС, а при отсутствии в нем указаний - требованиями ФНП ПС",
        ],
      },
      {
        code: "69385",
        text: "Что должно проводиться после реконструкции ПС?",
        answers: [
          "Внеочередное частичное техническое освидетельствование",
          "Внеочередное полное техническое освидетельствование",
          "Периодическое частичное техническое освидетельствование",
          "Периодическое техническое освидетельствование",
        ],
        correctAnswers: ["Внеочередное полное техническое освидетельствование"],
      },
      {
        code: "69386",
        text: "Кто должен проводить техническое освидетельствование подъемника (вышки)?",
        answers: [
          "Комиссия эксплуатирующей организации, состав которой утверждает руководитель эксплуатирующей организации",
          "Комиссия эксплуатирующей организации, в состав которой должен входить представитель Ростехнадзора",
          "Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС, при участии инженерно-технического работника, ответственного за содержание ПС в работоспособном состоянии",
          "Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии",
          "Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС, при участии инженерно-технического работника, ответственного за безопасное производство работ с применением ПС",
        ],
        correctAnswers: [
          "Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС, при участии инженерно-технического работника, ответственного за содержание ПС в работоспособном состоянии",
        ],
      },
      {
        code: "69387",
        text: "Для каких подъемников при проведении технического освидетельствования необходимо проверять точность остановки кабины с нагрузкой и без нагрузки?",
        answers: [
          "Для прицепных подъемников",
          "Для строительных подъемников",
          "Для подъемников ножничного типа, управление которыми осуществляется из люльки",
          "Для телескопических подъемников",
          "Для передвижных подъемников",
        ],
        correctAnswers: ["Для строительных подъемников"],
      },
      {
        code: "69388",
        text: "С какой нагрузкой следует выполнять статические испытания грузопассажирских и фасадных строительных подъемников?",
        answers: [
          "125 % по отношению к номинальной паспортной грузоподъемности",
          "140 % по отношению к номинальной паспортной грузоподъемности",
          "150 % по отношению к номинальной паспортной грузоподъемности",
          "175 % по отношению к номинальной паспортной грузоподъемности",
          "200 % по отношению к номинальной паспортной грузоподъемности",
        ],
        correctAnswers: [
          "200 % по отношению к номинальной паспортной грузоподъемности",
        ],
      },
      {
        code: "69389",
        text: "В каком положении выполняют статические испытания подъемников (кроме строительных)?",
        answers: [
          "В положении, отвечающем его наименьшей расчетной устойчивости",
          "В положении, отвечающем его наибольшей расчетной устойчивости",
          "В положении продольной оси стрелы вдоль продольной оси подъемника",
          "В положении продольной оси стрелы, составляющей с продольной осью подъемника угол в 45°",
          "В положении, приведенном в руководстве (инструкции) по эксплуатации",
        ],
        correctAnswers: [
          "В положении, отвечающем его наименьшей расчетной устойчивости",
        ],
      },
      {
        code: "69390",
        text: "Для каких типов подъемников при проведении статических испытаний часть испытательного груза подвешивают к люльке на гибкой подвеске?",
        answers: [
          "Только для подъемников, имеющих специальный крюк",
          "Для строительных подъемников",
          "Для всех подъемников, оборудованных люлькой, кроме строительных",
          "Для подъемников ножничного типа",
          "Для телескопических подъемников",
        ],
        correctAnswers: [
          "Для всех подъемников, оборудованных люлькой, кроме строительных",
        ],
      },
      {
        code: "69391",
        text: "Считается ли отрыв одной из опор подъемника при проведении испытаний признаком потери устойчивости?",
        answers: [
          "Не считается, если в металлоконструкциях не обнаружены повреждения",
          "Не считается, если в течение 10 минут поднятый груз не опустился, а также если в металлоконструкциях не обнаружены повреждения",
          "Не считается, если в течение 5 минут поднятый груз не опустился, а также если в металлоконструкциях не обнаружены повреждения",
          "Считается, если остальные опоры подъемника при этом погрузились в грунт",
          "Считается, если опора при отрыве переместилась горизонтально",
        ],
        correctAnswers: [
          "Не считается, если в течение 10 минут поднятый груз не опустился, а также если в металлоконструкциях не обнаружены повреждения",
        ],
      },
      {
        code: "69392",
        text: "Каким грузом следует проводить динамические испытания подъемника (вышки)?",
        answers: [
          "Масса которого на 10 % превышает его паспортную грузоподъемность",
          "Масса которого на 5 % превышает его паспортную грузоподъемность",
          "Масса которого на 25 % превышает его паспортную грузоподъемность",
          "Масса которого на 20 % превышает его паспортную грузоподъемность",
        ],
        correctAnswers: [
          "Масса которого на 10 % превышает его паспортную грузоподъемность",
        ],
      },
      {
        code: "69393",
        text: "Кто выдает разрешение на дальнейшую эксплуатацию подъемника (вышки) по завершении выполнения периодического планового технического освидетельствования?",
        answers: [
          "Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии",
          "Инженерно-технический работник, ответственный за безопасное производство работ с применением ПС",
          "Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии, при участии специалиста, ответственного за безопасное производство работ с применением ПС",
          "Руководитель эксплуатирующей организации, которой принадлежит подъемник (вышка)",
          "Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС",
        ],
        correctAnswers: [
          "Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС",
        ],
      },
      {
        code: "69394",
        text: "Каким запасам по грузоподъемности должен удовлетворять грузоподъемный кран, ПС для их возможного использования при транспортировке людей, по сравнению с суммой массы самой люльки (кабины), массы устройства, предназначенного для подвешивания люльки (кабины), и паспортной номинальной грузоподъемности люльки (кабины), согласно требованиям, установленным ФНП ПС?",
        answers: [
          "Не менее чем двукратный запас",
          "Не менее чем трехкратный запас",
          "Не менее чем полуторакратный запас, в том числе и для тормозов его механизма подъема",
          "Не регламентируется, если кран оснащен ограничителем грузоподъемности (грузового момента)",
          "Не менее чем девятикратный запас",
        ],
        correctAnswers: ["Не менее чем двукратный запас"],
      },
      {
        code: "69395",
        text: "Каким требованиям должны отвечать перила ограждения по всему периметру пола люльки для подъема и транспортировки людей кранами?",
        answers: [
          "Перила ограждения должны быть высотой не менее 1100 мм и гибкими, чтобы предотвратить травмирование персонала при раскачке люльки ветром во время транспортировки",
          "Перила ограждения должны быть гибкими и выдерживать (на разрыв) горизонтальную нагрузку не менее половины паспортной грузоподъемности люльки",
          "Конструкция перил люльки должна определяться разработчиком и согласовываться с Ростехнадзором при согласовании ППР по транспортировке персонала",
          "Требования к конструкции ограждения люльки должны определяться по аналогии с требованиями, изложенными в стандартах на ограждения кабин кранов",
          "Перила ограждения должны быть жесткими, высотой не менее 1100 мм по всему периметру пола люльки, исключающие случайное выскальзывание персонала при раскачивании люльки во время транспортировки. До высоты 0,5 м ограждение должно быть сплошным",
        ],
        correctAnswers: [
          "Перила ограждения должны быть жесткими, высотой не менее 1100 мм по всему периметру пола люльки, исключающие случайное выскальзывание персонала при раскачивании люльки во время транспортировки. До высоты 0,5 м ограждение должно быть сплошным",
        ],
      },
      {
        code: "69396",
        text: "С какой периодичностью проводится плановая проверка состояния люльки (кабины)?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в 3 месяца",
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в год",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "69397",
        text: "С какой периодичностью проводятся грузовые испытания люльки (кабины)?",
        answers: [
          "Не реже одного раза в месяц",
          "Не реже одного раза в квартал",
          "Не реже одного раза в полугодие",
          "Не реже одного раза в год",
        ],
        correctAnswers: ["Не реже одного раза в полугодие"],
      },
      {
        code: "69398",
        text: "Грузом какой массы следует выполнять грузовые испытания люльки (кабины) для транспортировки кранами людей?",
        answers: [
          "В 1,25 раза превышающей грузоподъемность люльки",
          "Равной грузоподъемности люльки",
          "В два раза превышающей грузоподъемность люльки",
          "В три раза превышающей грузоподъемность люльки",
        ],
        correctAnswers: ["В два раза превышающей грузоподъемность люльки"],
      },
      {
        code: "69399",
        text: "В какой документ заносятся результаты грузовых испытаний люльки (кабины)?",
        answers: [
          "В вахтенный журнал",
          "В журнал учета и осмотра грузозахватных приспособлений",
          "В журнал осмотра люльки (кабины)",
          "В паспорт люльки (кабины)",
        ],
        correctAnswers: ["В паспорт люльки (кабины)"],
      },
      {
        code: "69400",
        text: "В соответствии с требованиями каких документов должна проводиться установка ПС в зданиях, на открытых площадках и других участках производства работ? Выберите 2 варианта ответа.",
        answers: [
          "В соответствии с руководством (инструкцией) по эксплуатации ПС",
          "В соответствии с проектом производства работ, разработанным эксплуатирующей организацией",
          'В соответствии с требованиями ФНП "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения"',
          "В соответствии с паспортом ПС",
        ],
        correctAnswers: [
          "В соответствии с руководством (инструкцией) по эксплуатации ПС",
          'В соответствии с требованиями ФНП "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения"',
        ],
      },
      {
        code: "69401",
        text: "При установке опорных мостовых кранов с какой фактической группой классификации (режима) в пролетах зданий должны быть устроены галереи для прохода вдоль рельсового пути с обеих сторон пролета?",
        answers: [
          "С фактической группой классификации (режима) A4 и более",
          "С фактической группой классификации (режима) A6 и более",
          "С фактической группой классификации (режима) A6 и менее",
          "С фактической группой классификации (режима) A4 и менее",
        ],
        correctAnswers: [
          "С фактической группой классификации (режима) A6 и более",
        ],
      },
      {
        code: "69402",
        text: "Какое из перечисленных требований к люку (проему) для подъема и опускания грузов, находящегося в перекрытии производственного помещения, указано неверно?",
        answers: [
          "Люк в перекрытии должен иметь постоянное ограждение высотой не менее 1,1 м",
          "Установка кранов над производственными помещениями для подъема и опускания грузов через люк (проем) в перекрытии разрешается при расположении одного помещения непосредственно над другим",
          "Ограждение люка должно иметь световую сигнализацию, предупреждающую как о нахождении груза над люком, так и об опускании груза",
          "Люк в перекрытии должен иметь постоянное ограждение высотой не менее 1 м со сплошным ограждением понизу на высоту 0,1 м",
        ],
        correctAnswers: [
          "Люк в перекрытии должен иметь постоянное ограждение высотой не менее 1 м со сплошным ограждением понизу на высоту 0,1 м",
        ],
      },
      {
        code: "69403",
        text: "Кем выдается наряд-допуск на работу вблизи находящихся под напряжением проводов и оборудования в случаях, когда работы с применением кранов стрелового типа, кранов-манипуляторов, подъемников (вышек) ведутся на действующих электростанциях, подстанциях и линиях электропередачи?",
        answers: [
          "Организацией, эксплуатирующей электростанцию, подстанцию, линию электропередачи",
          "Инженерно-техническим работником, ответственным за безопасное производство работ с применением ПС",
          "Инженерно-техническим работником, ответственным за содержание ПС в работоспособном состоянии",
          "Инженерно-техническим работником, ответственным за осуществление производственного контроля при эксплуатации ПС",
        ],
        correctAnswers: [
          "Организацией, эксплуатирующей электростанцию, подстанцию, линию электропередачи",
        ],
      },
      {
        code: "69404",
        text: "При какой скорости ветра запрещается использовать люльки (кабины) с целью перемещения в них людей?",
        answers: ["Выше 5 м/с", "Выше 10 м/с", "Выше 7 м/с", "Выше 8 м/с"],
        correctAnswers: ["Выше 10 м/с"],
      },
      {
        code: "69405",
        text: "Какие из перечисленных требований, предъявляемых к ПС, выбираемым для транспортирования людей при проведении диагностирования и ремонта металлоконструкций других ПС, указаны неверно?",
        answers: [
          "ПС должно иметь систему управления механизмами, обеспечивающую их плавный пуск",
          "ПС должно иметь систему управления механизмами, обеспечивающую их плавную остановку",
          "ПС должно иметь не менее чем полуторакратный запас по грузоподъемности по сравнению с суммой массы самой люльки (кабины), массы устройства, предназначенного для подвешивания люльки (кабины) и паспортной номинальной грузоподъемности люльки (кабины)",
          "ПС должно обеспечивать скорость перемещения кабины по вертикали не более 20 м в минуту",
        ],
        correctAnswers: [
          "ПС должно иметь не менее чем полуторакратный запас по грузоподъемности по сравнению с суммой массы самой люльки (кабины), массы устройства, предназначенного для подвешивания люльки (кабины) и паспортной номинальной грузоподъемности люльки (кабины)",
        ],
      },
      {
        code: "69406",
        text: "Какие из перечисленных сведений должны быть указаны в инструкциях, определяющих действия работников в аварийных ситуациях в организации, эксплуатирующей ОПО с ПС?",
        answers: [
          "Оперативные действия по предотвращению и локализации аварий",
          "Способы и методы ликвидации аварий",
          "Схемы эвакуации в случае возникновения взрыва, пожара, выброса токсичных веществ в помещении или на площадке, обслуживаемой ПС, если аварийная ситуация не может быть локализована или ликвидирована",
          "Все перечисленные сведения",
        ],
        correctAnswers: ["Все перечисленные сведения"],
      },
      {
        code: "69407",
        text: "Согласно требованиям какого документа следует выполнять браковку стальных канатов в эксплуатации?",
        answers: [
          "Согласно требованиям руководства (инструкции) по эксплуатации ПС",
          "Согласно требованиям паспорта ПС",
          'Согласно требованиям ФНП "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения"',
          "Согласно требованиям сертификата предприятия-изготовителя каната",
        ],
        correctAnswers: [
          'Согласно требованиям ФНП "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения"',
        ],
      },
      {
        code: "69408",
        text: "Кем должны быть утверждены ППР, ТК на погрузочно-разгрузочные работы и выданы на участки, где будут использоваться ПС, до начала ведения работ?",
        answers: [
          "Инженерно-техническим работником, ответственным за безопасное производство работ с применением ПС",
          "Организацией, эксплуатирующей ПС",
          "Представителем территориального органа Ростехнадзора",
          "Специализированной организацией",
        ],
        correctAnswers: ["Организацией, эксплуатирующей ПС"],
      },
      {
        code: "69409",
        text: "В соответствии с требованиями какого документа выполняется проверка работоспособности указателя (сигнализатора) предельной скорости ветра (анемометра) и креномера (указателя угла наклона ПС)?",
        answers: [
          "Руководств (инструкций) по эксплуатации анемометра и креномера",
          "ТР ТС 010/2011",
          'Федеральных норм и правил в области промышленной безопасности "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения"',
          "Паспорта ПС",
        ],
        correctAnswers: [
          "Руководств (инструкций) по эксплуатации анемометра и креномера",
        ],
      },
      {
        code: "69410",
        text: "В каком случае допускается эксплуатация текстильных стропов на полимерной основе?",
        answers: [
          "Если имеются узлы на несущих лентах стропов",
          "Если имеются сквозные отверстия диаметром 15% ширины ленты от воздействия острых предметов",
          "Если присутствует выпучивание нитей из ленты стропа на расстояние 20% ширины ленты",
          "Если имеется загрязнение лент (нефтепродуктами, смолами, красками, цементом, грунтом) 25% длины стропа",
        ],
        correctAnswers: [
          "Если имеется загрязнение лент (нефтепродуктами, смолами, красками, цементом, грунтом) 25% длины стропа",
        ],
      },
      {
        code: "69411",
        text: "Согласно требованиям какого документа должна выполняться проверка работоспособности регистратора параметров работы ПС?",
        answers: [
          "Согласно требованиям руководства (инструкции) по эксплуатации ПС",
          "Согласно требованиям паспорта ПС",
          'Согласно требованиям ФНП "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения"',
          "Согласно требованиям вахтенного журнала ПС",
        ],
        correctAnswers: [
          "Согласно требованиям руководства (инструкции) по эксплуатации ПС",
        ],
      },
      {
        code: "69412",
        text: "Согласно требованиям какого документа должна выполняться оценка работоспособности ограничителя или указателя опасного приближения к линии электропередачи при эксплуатации ПС?",
        answers: [
          "Согласно требованиям проекта производства работ, разработанного эксплуатирующей организацией",
          'Согласно требованиям приложения N 9 к ФНП "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения"',
          "Согласно требованиям руководства (инструкции) по эксплуатации ПС",
          "Согласно требованиям паспорта ПС",
        ],
        correctAnswers: [
          'Согласно требованиям приложения N 9 к ФНП "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения"',
        ],
      },
    ],
    712: [
      {
        code: "69416",
        text: "Кто должен принять меры, предупреждающие причинение вреда населению и окружающей среде, при прекращении эксплуатации здания или сооружения согласно Техническому регламенту о безопасности зданий и сооружений?",
        answers: [
          "Лицо, осуществившее подготовку проектной документации",
          "Руководитель подрядной организации осуществляющей оценку соответствия зданий и сооружений",
          "Собственник здания или сооружения",
          "Руководитель эксплуатирующей организации",
        ],
        correctAnswers: ["Собственник здания или сооружения"],
      },
      {
        code: "69417",
        text: "Что входит в обязанности лица, осуществляющего строительство здания или сооружения, в соответствии с законодательством о градостроительной деятельности?",
        answers: [
          "Все перечисленное",
          "Контроль за соответствием применяемых строительных материалов и изделий, в том числе строительных материалов, производимых на территории, на которой осуществляется строительство, требованиям проектной документации в течение всего процесса строительства",
          "Оценка соответствия здания или сооружения в процессе строительства и при его окончании",
          "Оценка соответствия результатов инженерных изысканий",
        ],
        correctAnswers: [
          "Контроль за соответствием применяемых строительных материалов и изделий, в том числе строительных материалов, производимых на территории, на которой осуществляется строительство, требованиям проектной документации в течение всего процесса строительства",
        ],
      },
      {
        code: "69418",
        text: "На каком этапе осуществляется оценка соответствия зданий и сооружений, а также связанных со зданиями и сооружениями процессов проектирования (включая изыскания) в форме заявления о соответствии проектной документации требованиям Технического регламента о безопасности зданий и сооружений?",
        answers: [
          "На усмотрение лица, подготовившего проектную документацию",
          "После утверждения проектной документации в соответствии с законодательством о градостроительной деятельности",
          "До утверждения проектной документации в соответствии с законодательством о градостроительной деятельности",
        ],
        correctAnswers: [
          "До утверждения проектной документации в соответствии с законодательством о градостроительной деятельности",
        ],
      },
      {
        code: "69419",
        text: "Кем осуществляется обязательная оценка соответствия зданий и сооружений, а также связанных со зданиями и сооружениями процессов строительства, монтажа, наладки в форме заявления о соответствии построенного, реконструированного или отремонтированного здания или сооружения проектной документации?",
        answers: [
          "Лицом, подготовившим проектную документацию",
          "Лицом, осуществившим строительство",
          "Лицом эксплуатационного контроля",
          "Лицом государственного контроля (надзора)",
        ],
        correctAnswers: ["Лицом, осуществившим строительство"],
      },
      {
        code: "69420",
        text: "К какому уровню ответственности относятся здания и сооружения, отнесенные в соответствии с Градостроительным кодексом Российской Федерации к особо опасным, технически сложным или уникальным объектам?",
        answers: [
          "К повышенному уровню",
          "К нормальному уровню",
          "К пониженному уровню",
        ],
        correctAnswers: ["К повышенному уровню"],
      },
      {
        code: "69421",
        text: "Какое из перечисленных состояний должно быть принято за предельное состояние строительных конструкций и основания по прочности и устойчивости?",
        answers: [
          "Состояние, характеризующееся разрушением любого характера",
          "Состояние, характеризующееся потерей устойчивости формы",
          "Состояние, характеризующееся потерей устойчивости положения",
          "Состояние, характеризующееся нарушением эксплуатационной пригодности и иными явлениями, связанными с угрозой причинения вреда жизни и здоровью людей, имуществу физических или юридических лиц, государственному или муниципальному имуществу, окружающей среде, жизни и здоровью животных и растений",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "69422",
        text: "Что из перечисленного должно быть предусмотрено в проектной документации лицом, осуществляющим подготовку проектной документации? Укажите все правильные ответы.",
        answers: [
          "Оценка риска возникновения опасных природных процессов и явлений и (или) техногенных воздействий",
          "Минимальная периодичность осуществления проверок, осмотров и освидетельствований состояния строительных конструкций, основания, сетей инженерно-технического обеспечения и систем инженерно-технического обеспечения здания или сооружения",
          "Расчеты и (или) испытания, выполненные по сертифицированным или апробированным иным способом методикам",
          "Необходимость проведения мониторинга компонентов окружающей среды, состояния основания, строительных конструкций и систем инженерно-технического обеспечения в процессе эксплуатации здания или сооружения",
        ],
        correctAnswers: [
          "Минимальная периодичность осуществления проверок, осмотров и освидетельствований состояния строительных конструкций, основания, сетей инженерно-технического обеспечения и систем инженерно-технического обеспечения здания или сооружения",
          "Необходимость проведения мониторинга компонентов окружающей среды, состояния основания, строительных конструкций и систем инженерно-технического обеспечения в процессе эксплуатации здания или сооружения",
        ],
      },
      {
        code: "69423",
        text: "Каким образом должна обеспечиваться безопасность здания или сооружения в процессе эксплуатации?",
        answers: [
          "Только посредством периодических осмотров строительных конструкций",
          "Только посредством проведения экспертизы промышленной безопасности",
          "Посредством проведения всех перечисленных мероприятий, включая проведение текущих ремонтов здания или сооружения",
          "Только посредством технического обслуживания систем инженерно-технического обеспечения",
          "Только посредством мониторинга состояния основания",
        ],
        correctAnswers: [
          "Посредством проведения всех перечисленных мероприятий, включая проведение текущих ремонтов здания или сооружения",
        ],
      },
      {
        code: "69424",
        text: "Кем указываются такие идентификационные признаки зданий и сооружений, как назначение, принадлежность к опасным производственным объектам, пожарная и взрывопожарная опасность, уровень ответственности? Укажите все правильные ответы.",
        answers: [
          "Лицом эксплуатационного контроля",
          "Застройщиком (заказчиком)",
          "Лицом, осуществляющим подготовку проектной документации",
          "Лицом государственного контроля (надзора)",
        ],
        correctAnswers: [
          "Застройщиком (заказчиком)",
          "Лицом, осуществляющим подготовку проектной документации",
        ],
      },
      {
        code: "69425",
        text: "В какой форме осуществляется обязательная оценка соответствия зданий и сооружений, а также связанных со зданиями и с сооружениями процессов эксплуатации?",
        answers: [
          "В форме государственной экспертизы результатов инженерных изысканий и проектной документации",
          "В форме государственного контроля (надзора)",
          "В форме заявления о соответствии построенного, реконструированного или отремонтированного здания или сооружения проектной документации",
          "В форме строительного контроля",
        ],
        correctAnswers: ["В форме государственного контроля (надзора)"],
      },
      {
        code: "69426",
        text: "Какое минимальное значение коэффициента надежности по ответственности устанавливается в отношении особо опасных и технически сложных объектов?",
        answers: ["1,1", "1,0", "0,8", "0,9"],
        correctAnswers: ["1,1"],
      },
      {
        code: "69427",
        text: "Какое из перечисленных требований безопасности для пользователей зданиями и сооружениями, согласно Техническому регламенту о безопасности зданий и сооружений, указано верно? Укажите все правильные ответы.",
        answers: [
          "Для обеспечения свободного перемещения людей должна быть предусмотрена достаточная ширина незаполняемых проемов в стенах",
          "Конструкция ограждений в соответствии с требованиями, должна ограничивать возможность случайного падения с высоты (в том числе с крыш зданий) предметов, которые могут нанести травму людям, находящимся вблизи здания",
          "В проектной документации зданий и сооружений должны быть предусмотрены устройства для предупреждения случайного движения подвижных элементов оборудования здания или сооружения при отказе устройств автоматического торможения",
          "Для обеспечения безопасности в аварийных ситуациях в проектной документации должно быть предусмотрено дежурное освещение",
        ],
        correctAnswers: [
          "Для обеспечения свободного перемещения людей должна быть предусмотрена достаточная ширина незаполняемых проемов в стенах",
          "В проектной документации зданий и сооружений должны быть предусмотрены устройства для предупреждения случайного движения подвижных элементов оборудования здания или сооружения при отказе устройств автоматического торможения",
        ],
      },
      {
        code: "69428",
        text: "Какие здания и сооружения могут предусматривать необходимость научного сопровождения инженерных изысканий и (или) проектирования и строительства здания или сооружения?",
        answers: [
          "Здания и сооружения нормального уровня ответственности",
          "Здания и сооружения повышенного уровня ответственности",
          "Здания и сооружения пониженного уровня ответственности",
        ],
        correctAnswers: [
          "Здания и сооружения повышенного уровня ответственности",
        ],
      },
      {
        code: "69429",
        text: "В соответствии с требованиями какого документа должна осуществляться эксплуатация дымовых и вентиляционных промышленных труб?",
        answers: [
          'В соответствии с Федеральным законом от 30.12.2009 N 384-ФЗ "Технический регламент о безопасности зданий и сооружений"',
          "В соответствии с Правилами технической эксплуатации тепловых энергоустановок",
          "В соответствии с Правилами техники безопасности при эксплуатации тепломеханического оборудования электростанций и тепловых сетей",
        ],
        correctAnswers: [
          'В соответствии с Федеральным законом от 30.12.2009 N 384-ФЗ "Технический регламент о безопасности зданий и сооружений"',
        ],
      },
      {
        code: "69430",
        text: "Кем определяется необходимость разработки требований к содержанию разделов проектной документации, наличие которых согласно Положению о составе разделов проектной документации и требованиях к их содержанию не является обязательным?",
        answers: [
          "Определяется представителями Ростехнадзора",
          "Определяется по согласованию между заказчиком и Ростехнадзором",
          "Определяется по согласованию между проектной организацией и заказчиком такой документации",
          "Определяется по согласованию между Ростехнадзором и проектной организацией такой документации",
        ],
        correctAnswers: [
          "Определяется по согласованию между проектной организацией и заказчиком такой документации",
        ],
      },
      {
        code: "69431",
        text: "Из какого количества разделов состоит проектная документация на объекты капитального строительства производственного назначения?",
        answers: ["8", "10", "12", "14"],
        correctAnswers: ["12"],
      },
      {
        code: "69432",
        text: "На какие из перечисленных ОПО не распространяются требования Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения?",
        answers: [
          "На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления",
          "На ОПО, где эксплуатируются подъемники (вышки)",
          "На ОПО, где эксплуатируются строительные подъемники",
          "На ОПО, где эксплуатируются канатные дороги",
        ],
        correctAnswers: ["На ОПО, где эксплуатируются канатные дороги"],
      },
      {
        code: "69433",
        text: "На какие из перечисленных ОПО распространяются требования Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения?",
        answers: [
          "На ОПО, где эксплуатируются ПС, установленные в шахтах и на любых плавучих средствах",
          "На ОПО, где эксплуатируются краны для подъема и опускания затворов гидротехнических сооружений без осуществления зацепления их крюками, оборудованные единственным механизмом подъема и не имеющие механизма передвижения крана",
          "На ОПО, где эксплуатируются подъемные сооружения, установленные в шахтах",
          "На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления",
        ],
        correctAnswers: [
          "На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления",
        ],
      },
      {
        code: "69434",
        text: 'На какие из перечисленных ниже опасные производственные объекты (далее - ОПО) не распространяются требования Федеральных норм и правил в области промышленной безопасности "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения" (далее - ФНП ПС)?',
        answers: [
          "На ОПО, где эксплуатируются подъемники (вышки)",
          "На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления",
          "На ОПО, где эксплуатируются грузоподъемные краны",
          "На ОПО, где эксплуатируются канатные дороги",
        ],
        correctAnswers: ["На ОПО, где эксплуатируются канатные дороги"],
      },
      {
        code: "69435",
        text: 'Каким нормативным правовым актом регламентируются обязательные для применения требования для ПС, введенных в обращение до вступления в силу Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"?',
        answers: [
          "Ранее действующими правилами устройства и безопасной эксплуатации ПС Ростехнадзора для всех стадий жизненного цикла этих ПС",
          "Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения, для всех стадий жизненного цикла этих ПС",
          "Ранее действующими правилами устройства и безопасной эксплуатации ПС Ростехнадзора для проектирования и изготовления этих ПС, а для остальных стадий жизненного цикла ПС - ФНП ПС",
          'Такие ПС должны быть приведены в соответствие с требованиями Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
        ],
        correctAnswers: [
          "Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения, для всех стадий жизненного цикла этих ПС",
        ],
      },
      {
        code: "69436",
        text: "Требованиям какого документа должны соответствовать общие требования к транспортировке и хранению ПС, их отдельных сборочных единиц, материалов и комплектующих для их ремонта, реконструкции и (или) модернизации?",
        answers: [
          "Паспорта ПС",
          "Руководства (инструкции) по эксплуатации ПС",
          "Технических условий ПС",
          "Формуляра ПС",
        ],
        correctAnswers: ["Руководства (инструкции) по эксплуатации ПС"],
      },
      {
        code: "69437",
        text: "Имеет ли право организация, эксплуатирующая ОПО с ПС, привлекать специалистов сторонних организаций в качестве: специалиста, ответственного за осуществление производственного контроля при эксплуатации ПС; специалиста, ответственного за содержание ПС в работоспособном состоянии; специалиста, ответственного за безопасное производство работ с применением ПС?",
        answers: [
          "Имеет право привлекать всех указанных специалистов",
          "Имеет право привлекать специалистов, ответственных за содержание ПС в работоспособном состоянии; специалистов, ответственных за безопасное производство работ с применением ПС",
          "Имеет право привлекать только специалистов, ответственных за безопасное производство работ с применением ПС",
          "Не имеет право",
        ],
        correctAnswers: ["Не имеет право"],
      },
      {
        code: "69438",
        text: "Кого в обязательном порядке должны информировать работники ОПО, непосредственно занимающиеся эксплуатацией ПС, об угрозе возникновения аварийной ситуации?",
        answers: [
          "Руководителя ОПО",
          "Своего непосредственного руководителя",
          "Руководителя эксплуатирующей организации",
          "Специалиста, ответственного за содержание ПС в работоспособном состоянии",
        ],
        correctAnswers: ["Своего непосредственного руководителя"],
      },
      {
        code: "69439",
        text: "Кто разрабатывает инструкцию, согласно которой осуществляется обслуживание систем дистанционного управления (радиоуправления) при эксплуатации ПС?",
        answers: [
          "Руководитель эксплуатирующей организация, в ведении которого находится ПС",
          "Изготовитель системы дистанционного управления (радиоуправления) ПС",
          "Руководитель подрядной организации, обслуживающий ПС",
          "Не регламентируется",
        ],
        correctAnswers: [
          "Изготовитель системы дистанционного управления (радиоуправления) ПС",
        ],
      },
      {
        code: "69440",
        text: "С какого места должно выполняться управление ПС в период монтажа?",
        answers: [
          "Только с монтажной площадки",
          "Только из кабины машиниста",
          "Только с места, указанного в эксплуатационной документации",
          "С любого из перечисленных мест",
        ],
        correctAnswers: [
          "Только с места, указанного в эксплуатационной документации",
        ],
      },
      {
        code: "69441",
        text: "Кто определяет состав необходимого набора инструментов и приборов, необходимых для монтажа ограничителей, указателей и регистраторов параметров ПС?",
        answers: [
          "Руководитель организации эксплуатирующей ПС",
          "Руководитель подрядной организации обслуживающей ПС",
          "Работники, выполняющие монтаж",
          "Не регламентируется",
        ],
        correctAnswers: ["Работники, выполняющие монтаж"],
      },
      {
        code: "69442",
        text: "С чем в обязательном порядке должны быть ознакомлены работники, выполняющие работы по монтажу (демонтажу) и наладке ПС?",
        answers: [
          "Только с рабочими процедурами (характеристикой работ)",
          "Только с руководством (инструкцией) по монтажу, регламентирующим технологическую последовательность операций (технологическим регламентом)",
          "Только с проектом производства работ или технологической картой на монтаж (демонтаж) ПС",
          "Только с дополнительными требованиями промышленной безопасности всего комплекса работ, связанных с монтажом (демонтажем) либо наладкой конкретного ПС",
          "Со всем перечисленным",
        ],
        correctAnswers: ["Со всем перечисленным"],
      },
      {
        code: "69443",
        text: "Какие работы должны быть проведены после завершения монтажа и наладки системы дистанционного управления (радиоуправления) ПС?",
        answers: [
          "Проверка всех команд управления и аварийной защиты при работе системы дистанционного управления (радиоуправления) ПС",
          "Наладка тормозов, ограничителей, указателей и регистраторов параметров",
          "Обновление информации ограничителя или указателя со встроенным регистратором либо автономного регистратора",
          "Все перечисленные работы",
        ],
        correctAnswers: [
          "Проверка всех команд управления и аварийной защиты при работе системы дистанционного управления (радиоуправления) ПС",
        ],
      },
      {
        code: "69444",
        text: "Результаты каких наладочных работ необходимо отразить в акте по окончании монтажа ПС?",
        answers: [
          "Сведения о монтаже и наладке ограничителя, указателя и регистратора",
          "Подтверждающие работоспособность систем управления ПС, электро-, пневмо-, гидрооборудования, механизмов, а также имеющихся в наличии ограничителей, указателей и регистраторов",
          "Сведения о проверке всех команд управления и аварийной защиты при работе ПС в режиме дистанционного управления",
        ],
        correctAnswers: [
          "Подтверждающие работоспособность систем управления ПС, электро-, пневмо-, гидрооборудования, механизмов, а также имеющихся в наличии ограничителей, указателей и регистраторов",
        ],
      },
      {
        code: "69445",
        text: "Каким требованиям должны соответствовать такелажная оснастка и вспомогательные механизмы, используемые при выполнении ремонта и реконструкции или модернизации ПС?",
        answers: [
          "Эксплуатационным документам ПС",
          "Должны пройти статические и динамические испытания",
          "Должны быть проведены дополнительные расчеты такелажной оснастки и вспомогательных механизмов",
          "Всем перечисленным требованиям",
        ],
        correctAnswers: ["Эксплуатационным документам ПС"],
      },
      {
        code: "69446",
        text: "Кто имеет право вносить изменения в ППР и ТК в процессе монтажа?",
        answers: [
          "Организация, отвечающая за выполнение монтажа",
          "Эксплуатирующая организация",
          "Специализированная организация",
          "Проектная организация",
        ],
        correctAnswers: ["Организация, отвечающая за выполнение монтажа"],
      },
      {
        code: "69447",
        text: "Каким требованиям должна отвечать площадка для выполнения монтажа ПС?",
        answers: [
          "Соответствовать геометрическим размерам монтируемого ПС, с учетом дополнительных проходов вокруг него для персонала, занятого на монтаже",
          "Соответствовать требованиям площадки установки вспомогательных ПС, используемых на монтаже",
          "Соответствовать руководству (инструкции) по монтажу ПС, а также ППР или ТК на монтаж",
          "Не иметь в непосредственной близости складируемых горючих материалов и материалов, поддерживающих горение, если монтаж выполняется с применением сварки",
        ],
        correctAnswers: [
          "Соответствовать руководству (инструкции) по монтажу ПС, а также ППР или ТК на монтаж",
        ],
      },
      {
        code: "69448",
        text: "Каким критериям должен соответствовать выбор оборудования для безопасного выполнения работ по монтажу (демонтажу) ПС?",
        answers: [
          "Конкретному монтируемому ПС",
          "Эксплуатационным документам ПС",
          "Составу работ, предусмотренному руководством (инструкцией) по эксплуатации ПС",
          "Всем перечисленным критериям",
        ],
        correctAnswers: ["Всем перечисленным критериям"],
      },
      {
        code: "69449",
        text: "На какую организацию возлагается ответственность за эксплуатацию ПС, не оборудованного ограничителями, указателями и регистраторами, необходимыми для обеспечения промышленной безопасности технологического процесса, в котором используется ПС?",
        answers: [
          "На эксплуатирующую ПС организацию",
          "На экспертную организацию, проводившую экспертизу промышленной безопасности ПС",
          "На сертификационный центр и испытательную лабораторию, выдавших сертификат/декларацию соответствия ПС",
          "На специализированную организацию, выполнившую ремонт ПС",
        ],
        correctAnswers: ["На эксплуатирующую ПС организацию"],
      },
      {
        code: "69450",
        text: "Как необходимо ограждать зону площадки для выполнения работ по монтажу ПС?",
        answers: [
          "Специальными ограждениями, исключающими свободный проход",
          "По периметру, снабдив поясняющими надписями",
          'Перед входом в зону вывесить табличку с надписью "Проход запрещен!"',
          "По периметру, а на ограждениях вывешивать знаки безопасности и таблички",
        ],
        correctAnswers: [
          "По периметру, а на ограждениях вывешивать знаки безопасности и таблички",
        ],
      },
      {
        code: "69451",
        text: "Кто должен руководить погрузочно-разгрузочными работами при выполнении монтажа ПС?",
        answers: [
          "Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС",
          "Инженерно-технический работник, ответственный за безопасное производство работ с применением ПС",
          "Специалист, ответственный за содержание ПС в работоспособном состоянии",
          "Начальник участка, на котором выполняется монтаж данного ПС",
        ],
        correctAnswers: [
          "Инженерно-технический работник, ответственный за безопасное производство работ с применением ПС",
        ],
      },
      {
        code: "69452",
        text: "Требованиям какого документа должно соответствовать выполнение погрузочно-разгрузочных работ на монтаже с применением ПС?",
        answers: [
          "Руководства (инструкции) по монтажу ПС",
          "Проекта производства работ",
          "Паспорта ПС",
          "Технологической карты",
        ],
        correctAnswers: ["Руководства (инструкции) по монтажу ПС"],
      },
      {
        code: "69453",
        text: "Куда вносятся отметки о монтаже и наладке ограничителя, указателя и регистратора подъемного сооружения (ПС)? Укажите все правильные ответы.",
        answers: [
          "В паспорт ПС",
          "В акт выполненных работ",
          "В паспорт ограничителя, указателя или регистратора",
          "В руководство по эксплуатации",
          "В паспорт безопасности ОПО, на котором эксплуатируется ПС",
        ],
        correctAnswers: [
          "В паспорт ПС",
          "В паспорт ограничителя, указателя или регистратора",
        ],
      },
      {
        code: "69454",
        text: "Какие из указанных действий допускается предпринимать во время проведения монтажных работ ПС, если на монтажной площадке имеются действующие переходы (проезды) и выходы из прилегающих зданий? Укажите все правильные ответы.",
        answers: [
          "Работать с ПС запрещается",
          "Закрыть переходы (проезды) и выходы из прилегающих зданий",
          "Оборудовать переходы (проезды) и выходы из прилегающих зданий средствами, обеспечивающими безопасность (козырьками, галереями)",
          "Должен быть назначен сигнальщик",
        ],
        correctAnswers: [
          "Закрыть переходы (проезды) и выходы из прилегающих зданий",
          "Оборудовать переходы (проезды) и выходы из прилегающих зданий средствами, обеспечивающими безопасность (козырьками, галереями)",
        ],
      },
      {
        code: "69455",
        text: "Каким документом должно быть подтверждено соответствие проекту рельсового пути (для ПС на рельсовом ходу) по результатам выполнения монтажа?",
        answers: [
          "Техническим отчетом",
          "Актом сдачи-приемки рельсового пути",
          "Экспертным заключением",
        ],
        correctAnswers: ["Актом сдачи-приемки рельсового пути"],
      },
      {
        code: "69456",
        text: "К каким возможным последствиям не должен приводить любой отказ (поломка) смонтированного ограничителя, указателя или регистратора в процессе эксплуатации?",
        answers: [
          "К аварии ПС, в том числе к падению ПС, его частей и/или груза",
          "К невозможности опускания поднятого груза, если его масса выше паспортной грузоподъемности ПС",
          "К случайным перегрузкам ПС",
          "К аварии ПС или инциденту на ПС",
        ],
        correctAnswers: [
          "К аварии ПС, в том числе к падению ПС, его частей и/или груза",
        ],
      },
      {
        code: "69457",
        text: "Кто может проводить монтаж временных электрических сетей?",
        answers: [
          "Аттестованный электромонтер",
          "Аттестованный специалист по монтажу",
          "Электрик организации, эксплуатирующей ПС",
          "Аттестованный специалист по монтажу и наладке электрооборудования ПС",
        ],
        correctAnswers: ["Аттестованный электромонтер"],
      },
      {
        code: "69458",
        text: "Что должно быть указано в акте о приемке противовеса и балласта, если они изготовлены эксплуатирующей организацией?",
        answers: [
          "Фактическая масса плит",
          "Данные о материале, из которого изготовлены плиты",
          "Габариты плит",
          "Соответствие плит требованиям руководства (инструкции) по эксплуатации ПС",
        ],
        correctAnswers: ["Фактическая масса плит"],
      },
      {
        code: "69459",
        text: "Какие требования к ограничителям, указателям и регистраторам указаны неверно?",
        answers: [
          "После монтажа или реконструкции ограничителя, указателя или регистратора должна проводиться наладка и проверка его работоспособности с подтверждением соответствия его характеристик паспортным данным",
          "Информационные табло (элементы визуального контроля) указателей, ограничителей и регистраторов должно быть установлено в поле зрения крановщика (оператора), при этом оно не должно затруднять управление ПС и наблюдение за грузозахватным органом и грузом",
          "Ограничители, указатели и регистраторы, а также их составные части должны устанавливаться в доступных для осмотра и обслуживания местах, защищенных от внешних воздействий",
          "При перестановке ограничителя или указателя со встроенным регистратором на другое ПС должна быть осуществлена замена микропроцессорного блока устройства",
        ],
        correctAnswers: [
          "При перестановке ограничителя или указателя со встроенным регистратором на другое ПС должна быть осуществлена замена микропроцессорного блока устройства",
        ],
      },
      {
        code: "69460",
        text: "Что из перечисленного в составе подъемного сооружения не требуется опломбировать после монтажа, наладки, реконструкции или модернизации?",
        answers: [
          "Ограничитель",
          "Указатель",
          "Регистратор",
          "Концевые выключатели электромеханического типа, применяемые в ограничителях рабочих движений",
        ],
        correctAnswers: [
          "Концевые выключатели электромеханического типа, применяемые в ограничителях рабочих движений",
        ],
      },
      {
        code: "69461",
        text: "В соответствии с требованиями какого документа выполняется проверка работоспособности указателя (сигнализатора) предельной скорости ветра (анемометра) и креномера (указателя угла наклона ПС)?",
        answers: [
          "Руководства (инструкций) по эксплуатации анемометра и креномера",
          'Федеральных норм и правил в области промышленной безопасности "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения"',
          "Руководства по эксплуатации ПС",
          "Руководства по качеству организации и выполнения калибровочных работ",
        ],
        correctAnswers: [
          "Руководства (инструкций) по эксплуатации анемометра и креномера",
        ],
      },
      {
        code: "69462",
        text: "Какие из перечисленных ниже ПС не подлежат экспертизе промышленной безопасности?",
        answers: [
          "Управляемые с пола или по радиоканалу",
          "Изготовленные для собственных нужд",
          "Электрические тали",
          "Не подлежащие учету в органах Ростехнадзора",
        ],
        correctAnswers: ["Не подлежащие учету в органах Ростехнадзора"],
      },
      {
        code: "69463",
        text: "Какие требования к проверке ограничителя предельного верхнего положения грузозахватного органа указаны неверно?",
        answers: [
          "Проверка осуществляется путем контроля с замером расстояния между нижней точкой грузозахватного органа и упором",
          "Проверка ограничителя должна проводиться без груза",
          "Проверка осуществляется путем контроля с замером расстояния между верхней точкой грузозахватного органа и нижней частью металлоконструкции",
        ],
        correctAnswers: [
          "Проверка осуществляется путем контроля с замером расстояния между нижней точкой грузозахватного органа и упором",
        ],
      },
      {
        code: "69464",
        text: "Кем определяется набор инструментов и приборов, необходимых для ремонта, реконструкции или модернизации ограничителей, указателей, регистраторов параметров ПС?",
        answers: [
          "Руководителем подрядной организации обслуживающей ПС",
          "Руководителем организации эксплуатирующей ПС",
          "Работниками, выполняющими монтаж",
          "Работниками организаций, выполняющими указанные работы с учетом требований эксплуатационных документов",
        ],
        correctAnswers: [
          "Работниками организаций, выполняющими указанные работы с учетом требований эксплуатационных документов",
        ],
      },
      {
        code: "69465",
        text: "Кто разрабатывает проект для реконструкции или модернизации ограничителя, указателя или регистратора в случае, когда изготовителя ПС установить невозможно?",
        answers: [
          "Работники специализированной организации",
          "Работники эксплуатирующей организации",
          "Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии",
          "Изготовитель аналогичного оборудования, характеристики которого соответствуют заводским",
        ],
        correctAnswers: ["Работники специализированной организации"],
      },
      {
        code: "69466",
        text: "Кто обязан сделать запись в паспорте ПС по завершении его ремонта, реконструкции или модернизации о проведенной работе, и предоставить копии сертификатов о примененных материалах, замененных и отремонтированных запчастях, узлах и агрегатах?",
        answers: [
          "Специализированная организация, выполнившая указанные работы",
          "Работники эксплуатирующей организации",
          "Ответственный за содержание ПС в работоспособном состоянии",
          "Лицо, руководившее выполнением полного технического освидетельствования",
        ],
        correctAnswers: [
          "Специализированная организация, выполнившая указанные работы",
        ],
      },
      {
        code: "69467",
        text: "Какой документ регламентирует интервал проведения плановых ремонтов ПС?",
        answers: [
          "Протокол технического освидетельствования",
          "Паспорт ПС",
          "Ведомость дефектов",
          "Руководство (инструкция) по эксплуатации ПС",
        ],
        correctAnswers: ["Руководство (инструкция) по эксплуатации ПС"],
      },
      {
        code: "69468",
        text: "Что указывается на ремонтных чертежах элементов металлоконструкции ПС?",
        answers: [
          "Только поврежденные участки, подлежащие ремонту или замене",
          "Только типы сварных соединений и способы их выполнения",
          "Только допускаемые отклонения от номинальных размеров",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "69469",
        text: "Кем делается запись в паспорте ПС о проведенной работе по завершении выполнения ремонта, реконструкции или модернизации ПС?",
        answers: [
          "Эксплуатирующей организацией",
          "Специализированной организацией",
          "Экспертной организацией",
        ],
        correctAnswers: ["Специализированной организацией"],
      },
      {
        code: "69470",
        text: "Какими нормативными документами необходимо руководствоваться при выборе оборудования для безопасного выполнения работ по ремонту, реконструкции или модернизации ПС?",
        answers: [
          'Техническим регламентом Таможенного союза ТР ТС 010/2011 "О безопасности машин и оборудования"',
          '"Положением о Федеральной службе по экологическому, технологическому и атомному надзору", от 30 июля 2004 г. № 401',
          "Паспортом ПС",
          "Указаниями по ремонту, а также требованиями к составу работ, приведенными в руководстве (инструкции) по эксплуатации данного ПС",
        ],
        correctAnswers: [
          "Указаниями по ремонту, а также требованиями к составу работ, приведенными в руководстве (инструкции) по эксплуатации данного ПС",
        ],
      },
      {
        code: "69471",
        text: "Какие требования к сборке и соединению сборочных единиц ПС указаны неверно?",
        answers: [
          "Сборка и соединение отдельных сборочных единиц ПС должны выполняться согласно требованиям руководства (инструкции) по эксплуатации и другой эксплуатационной документации ПС",
          "Крупногабаритные сборочные единицы ПС должны укладываться на место последующего монтажа с применением грузоподъемных механизмов",
          "Сборка и монтаж металлоконструкций самомонтируемых козловых и башенных кранов должна выполняться на участке подготовленного наземного рельсового пути согласно указаниям специалиста, ответственного за безопасное производство работ с применением ПС",
          "Фактическая несоосность (непараллельность) стыкуемых сборочных единиц не должна превышать величин соответствующих допусков, приведенных в руководстве (инструкции) по эксплуатации ПС",
        ],
        correctAnswers: [
          "Сборка и монтаж металлоконструкций самомонтируемых козловых и башенных кранов должна выполняться на участке подготовленного наземного рельсового пути согласно указаниям специалиста, ответственного за безопасное производство работ с применением ПС",
        ],
      },
      {
        code: "69472",
        text: "Кто дает разрешение на пуск в работу ПС по окончании ремонта, реконструкции или модернизации ограничителя, указателя или регистратора?",
        answers: [
          "Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии",
          "Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС",
          "Инженерно-технический работник, ответственный за безопасное производство работ с применением ПС",
        ],
        correctAnswers: [
          "Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии",
        ],
      },
      {
        code: "69473",
        text: "Что из перечисленного не включает итоговая документация по результатам выполненных ремонтных работ?",
        answers: [
          "Ремонтные рабочие чертежи",
          "Технологические карты на выполнение ответственных операций",
          "Протокол испытания механических свойств контрольных образцов для каждого сварщика, выполняющего сварку несущих элементов металлоконструкций",
        ],
        correctAnswers: [
          "Протокол испытания механических свойств контрольных образцов для каждого сварщика, выполняющего сварку несущих элементов металлоконструкций",
        ],
      },
      {
        code: "69474",
        text: "В каких случаях при выполнении ремонта должна выполняться замена отдельных элементов ПС, если на них не обнаружено видимых повреждений?",
        answers: [
          "Если отдельные элементы ПС сложно продиагностировать методами неразрушающего контроля",
          "Если в руководстве (инструкции) по эксплуатации ПС, указано, что при достижении определенной наработки должна выполняться замена отдельных элементов ПС",
          "Если сборочную единицу ПС разобрать невозможно",
          "Если сборочная единица подверглась термоциклическому нагружению от воздействия либо низких, либо высоких температур, превышающих указанные в паспорте ПС",
        ],
        correctAnswers: [
          "Если в руководстве (инструкции) по эксплуатации ПС, указано, что при достижении определенной наработки должна выполняться замена отдельных элементов ПС",
        ],
      },
      {
        code: "69475",
        text: "Когда проводится осмотр состояния рельсовых путей ПС под руководством специалиста, ответственного за содержание ПС в работоспособном состоянии?",
        answers: [
          "После каждых 24 смен работы",
          "После каждых 32 смен работы",
          "После каждых 46 смен работы",
          "После каждых 50 смен работы",
        ],
        correctAnswers: ["После каждых 24 смен работы"],
      },
      {
        code: "69476",
        text: "Какой износ головки рельса является условием для браковки кранового пути опорных кранов?",
        answers: [
          "15% и более от величины соответствующего размера неизношенного профиля",
          "18% и более от величины соответствующего размера неизношенного профиля",
          "20% и более от величины соответствующего размера неизношенного профиля",
          "22% и более от величины соответствующего размера неизношенного профиля",
        ],
        correctAnswers: [
          "15% и более от величины соответствующего размера неизношенного профиля",
        ],
      },
      {
        code: "69477",
        text: "Какие работы относятся к работам в местах действия опасных факторов?",
        answers: [
          "Монтаж конструкций, имеющих большую парусность и габариты",
          "Монтаж в зоне примыкания к эксплуатируемым зданиям",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "69478",
        text: "Какие мероприятия должна выполнять эксплуатирующая организация для содержания ПС в работоспособном состоянии и обеспечения безопасных условий их работы?",
        answers: [
          "Разработать должностные инструкции для специалистов и производственные инструкции для персонала, журналы, программы выполнения планово-предупредительных ремонтов, ППР, ТК, схемы строповки и складирования",
          'Обеспечить наличие у специалистов Федеральных норм и правил в области промышленной безопасности "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения" должностных инструкций и руководящих указаний по безопасной эксплуатации ПС',
          "Обеспечить установленный порядок аттестации (специалисты) и допуска к самостоятельной работе (персонал) с выдачей соответствующих удостоверений",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "69479",
        text: "Какие из перечисленных ПС подлежат учету в органах Ростехнадзора?",
        answers: [
          "Краны стрелового типа грузоподъемностью до 1 т включительно",
          "Переставные краны для монтажа мачт, башен, труб, устанавливаемые на монтируемом сооружении",
          "Краны стрелового типа с постоянным вылетом или не снабженные механизмом поворота",
          "Подъемники и вышки, предназначенные для перемещения людей",
        ],
        correctAnswers: [
          "Подъемники и вышки, предназначенные для перемещения людей",
        ],
      },
      {
        code: "69480",
        text: "Какое требование по безопасной эксплуатации ПС указано неверно?",
        answers: [
          "При эксплуатации ПС, управляемых с пола, вдоль всего пути следования ПС должен быть обеспечен свободный проход для работника, управляющего ПС",
          "Мероприятия по безопасному спуску крановщиков должны быть указаны в технологической карте",
          "Выходы на галереи мостовых кранов, находящихся в работе, должны быть закрыты",
          "Для каждого цеха (пролета), не оборудованного проходными галереями вдоль рельсового пути эксплуатирующая организация должна разработать мероприятия по безопасному спуску крановщиков из кабины при вынужденной остановке крана не у посадочной площадки",
        ],
        correctAnswers: [
          "Мероприятия по безопасному спуску крановщиков должны быть указаны в технологической карте",
        ],
      },
      {
        code: "69481",
        text: "В каких случаях при наличии на ПС двух механизмов подъема их статические испытания следует проводить одновременно?",
        answers: [
          "Только для кранов, оснащенных двухканатным грейфером (с механизмами подъема и замыкания грейфера)",
          "Только для литейных кранов, у которых вспомогательный подъем осуществляет поворот поднятого ковша",
          "Если это предусмотрено паспортом ПС",
          "Только для контейнерных кранов, спредер которых поднимают одновременно два механизма подъема",
        ],
        correctAnswers: ["Если это предусмотрено паспортом ПС"],
      },
      {
        code: "69482",
        text: "Каким требованиям должны соответствовать стальные цепи, устанавливаемые на ПС?",
        answers: [
          "Должны иметь сертификат и пройти испытание в соответствии с требованиями Правил устройства и безопасной эксплуатации грузоподъемных кранов",
          "Должны соответствовать по марке и разрывному усилию указанным в паспорте ПС, иметь сертификат предприятия - изготовителя цепи",
          "Должны соответствовать по марке, диаметру и разрывному усилию указанным в паспорте ПС",
          "Должны иметь сертификат и иметь коэффициент запаса прочности не менее 3",
        ],
        correctAnswers: [
          "Должны соответствовать по марке и разрывному усилию указанным в паспорте ПС, иметь сертификат предприятия - изготовителя цепи",
        ],
      },
      {
        code: "69483",
        text: "Кто дает разрешение на пуск ПС в работу после окончания ремонта, реконструкции или модернизации ограничителя, указателя или регистратора?",
        answers: [
          "Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС",
          "Инженерно-технический работник, ответственный за безопасное производство работ с применением ПС",
          "Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии",
        ],
        correctAnswers: [
          "Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии",
        ],
      },
      {
        code: "69484",
        text: "Какой параметр из паспорта ПС (в виде выписки) в обязательном порядке должен быть включен в раздел ППР и ТК, связанный с организацией безопасного производства работ?",
        answers: [
          "Максимальная грузоподъемность или максимальный грузовой момент",
          "Справка об установленных указателях, ограничителях и регистраторах",
          "Сила ветра, при котором его работа не допускается",
          "Высота подъема",
        ],
        correctAnswers: ["Сила ветра, при котором его работа не допускается"],
      },
      {
        code: "69485",
        text: "Каким документом определяется объем работ, порядок и периодичность проведения технических освидетельствований ПС?",
        answers: [
          'Техническим регламентом Таможенного союза ТР ТС 010/2011 "О безопасности машин и оборудования"',
          '"Положением о Федеральной службе по экологическому, технологическому и атомному надзору", от 30 июля 2004 г. № 401',
          "Паспортом ПС",
          "Руководством (инструкцией) по эксплуатации ПС, а при отсутствии в ней указаний, требованиями ФНП ПС",
        ],
        correctAnswers: [
          "Руководством (инструкцией) по эксплуатации ПС, а при отсутствии в ней указаний, требованиями ФНП ПС",
        ],
      },
      {
        code: "69486",
        text: "Какие требования предъявляются к стальным канатам, устанавливаемым на ПС при замене ранее установленных?",
        answers: [
          "Стальные канаты должны иметь сертификат предприятия - изготовителя каната и пройти испытание в соответствии с требованиями Правил устройства и безопасной эксплуатации грузоподъемных кранов",
          "Стальные канаты должны соответствовать по назначению, длине и диаметру, указанным в паспорте ПС, и коэффициенту использования по разрывному усилию не ниже установленного для группы классификации режима работы механизма ПС, иметь сертификат предприятия-изготовителя каната.",
          "Стальные канаты должны иметь разрывное усилие на 10% больше указанного в паспорте ПС для заменяемого каната",
          "Стальные канаты должны соответствовать только технологии использования ПС",
        ],
        correctAnswers: [
          "Стальные канаты должны соответствовать по назначению, длине и диаметру, указанным в паспорте ПС, и коэффициенту использования по разрывному усилию не ниже установленного для группы классификации режима работы механизма ПС, иметь сертификат предприятия-изготовителя каната.",
        ],
      },
      {
        code: "69487",
        text: "Какая периодичность частичного технического освидетельствования установлена для ПС в течение всего срока службы?",
        answers: [
          "Не реже одного раза в 24 месяца",
          "Не реже одного раза в 12 месяцев",
          "Не реже одного раза в 18 месяцев",
          "Не реже одного раза в 16 месяцев",
        ],
        correctAnswers: ["Не реже одного раза в 12 месяцев"],
      },
      {
        code: "69488",
        text: "Что должно проводиться после реконструкции ПС?",
        answers: [
          "Внеочередное частичное техническое освидетельствование",
          "Внеочередное полное техническое освидетельствование",
          "Периодическое частичное техническое освидетельствование",
          "Периодическое техническое освидетельствование",
        ],
        correctAnswers: ["Внеочередное полное техническое освидетельствование"],
      },
      {
        code: "69489",
        text: "Какая организация обеспечивает наличие комплекта испытательных (контрольных) грузов с указанием их фактической массы для проведения статических и динамических испытаний ПС на территории специализированной организации, осуществляющей ремонт или реконструкцию?",
        answers: [
          "Данная специализированная организация",
          "Ростехнадзор",
          "Эксплуатирующая организация",
          "Любая сторонняя организация",
        ],
        correctAnswers: ["Данная специализированная организация"],
      },
      {
        code: "69490",
        text: "Что не указывается на табличках, которыми должны быть снабжены находящиеся в эксплуатации подъемные сооружения (ПС)?",
        answers: [
          "Учетный номер",
          "Заводской номер ПС и паспортная грузоподъемность",
          "Дата следующего полного и частичного технического освидетельствований",
          "Указывается все перечисленное",
        ],
        correctAnswers: ["Указывается все перечисленное"],
      },
      {
        code: "69491",
        text: "Какой нагрузкой должны проводиться статические испытания ПС всех типов (кроме подъемников и кранов-трубоукладчиков)?",
        answers: [
          "200% по отношению к номинальной паспортной грузоподъемности ПС",
          "150% по отношению к номинальной паспортной грузоподъемности ПС",
          "140% по отношению к номинальной паспортной грузоподъемности ПС",
          "125% по отношению к номинальной паспортной грузоподъемности ПС",
        ],
        correctAnswers: [
          "125% по отношению к номинальной паспортной грузоподъемности ПС",
        ],
      },
      {
        code: "69492",
        text: "С учетом требований какого документа должна выполняться утилизация (ликвидация) ПС? Укажите все правильные ответы.",
        answers: [
          "Руководства (инструкции) по эксплуатации ПС",
          "Распоряжения Ростехнадзора",
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
          "Проектной документации",
        ],
        correctAnswers: [
          "Руководства (инструкции) по эксплуатации ПС",
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
        ],
      },
      {
        code: "69493",
        text: "С каким грузозахватным органом проводят испытания при повторных периодических технических освидетельствованиях ПС, имеющих несколько грузозахватных органов?",
        answers: [
          "Поочередно, с каждым из них",
          "С тем, который установлен на момент проведения",
          "С тем, который наиболее часто применяется в технологии перегрузочного процесса эксплуатирующей организации",
          "Без сменного грузозахватного органа, а сменные грузозахватные органы испытывают отдельно",
        ],
        correctAnswers: ["С тем, который установлен на момент проведения"],
      },
      {
        code: "69494",
        text: "Какие из нижеперечисленных ПС не подлежат учету в органах Ростехнадзора?",
        answers: [
          "Автомобильные краны",
          "Краны мостового типа",
          "Краны на железнодорожном ходу",
          "Краны-трубоукладчики",
        ],
        correctAnswers: ["Краны-трубоукладчики"],
      },
      {
        code: "69495",
        text: "Кто назначается председателем комиссии, на основании предложений которой принимается решение о пуске в работу ПС, отработавшего срок службы, при смене эксплуатирующей организации?",
        answers: [
          "Уполномоченный представитель Ростехнадзора",
          "Уполномоченный представитель специализированной организации",
          "Специалист, ответственный за осуществление производственного контроля при эксплуатации ПС",
          "Уполномоченный представитель эксплуатирующей организации",
        ],
        correctAnswers: [
          "Уполномоченный представитель эксплуатирующей организации",
        ],
      },
      {
        code: "69496",
        text: "В каких из перечисленных случаев эксплуатирующая организация имеет право допустить ПС в работу?",
        answers: [
          "Для редко используемого ПС прошло более 3 лет с момента проведения предыдущего технического освидетельствования",
          "Отсутствует экспертиза промышленной безопасности ПС, отработавшего срок службы",
          "На ПС выявлены трещины в расчетных элементах металлоконструкции",
          "Отсутствуют соответствующие массе и виду перемещаемых грузов съемные грузозахватные приспособления и тара",
        ],
        correctAnswers: [
          "Для редко используемого ПС прошло более 3 лет с момента проведения предыдущего технического освидетельствования",
        ],
      },
      {
        code: "69497",
        text: "Каким проверкам должны подвергаться ПС при полном техническом освидетельствовании?",
        answers: [
          "Осмотру",
          "Статическим испытаниям",
          "Динамическим испытаниям",
          "Испытаниям на устойчивость",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "69498",
        text: "Какие из нижеперечисленных ПС подлежат учету в органах Ростехнадзора?",
        answers: [
          "Краны стрелового типа грузоподъемностью до 1 т включительно",
          "Переставные краны для монтажа мачт, башен, труб, устанавливаемые на монтируемом сооружении",
          "Краны стрелового типа с постоянным вылетом или не снабженные механизмом поворота",
          "Подъемники и вышки, предназначенные для перемещения людей",
        ],
        correctAnswers: [
          "Подъемники и вышки, предназначенные для перемещения людей",
        ],
      },
      {
        code: "69499",
        text: "Какие из перечисленных ПС не подлежат учету в органах Ростехнадзора?",
        answers: [
          "Краны-трубоукладчики",
          "Краны на железнодорожном ходу",
          "Краны мостового типа",
          "Автомобильные краны",
        ],
        correctAnswers: ["Краны-трубоукладчики"],
      },
      {
        code: "69500",
        text: "Что служит основанием для решения о пуске в работу после установки на объекте кранов мостового типа и портальных кранов?",
        answers: [
          "Предложение комиссии о возможности пуска",
          "Положительное заключение экспертизы промышленной безопасности",
          "Предписание территориального органа Ростехнадзора",
          "Заключение завода-изготовителя о возможности пуска",
        ],
        correctAnswers: ["Предложение комиссии о возможности пуска"],
      },
      {
        code: "69501",
        text: "В каком документе содержатся результаты работы комиссии, принимающей решение о возможности пуска ПС в работу?",
        answers: [
          "В протоколе",
          "В заключении комиссии",
          "В акте пуска ПС в работу",
          "В руководстве (инструкции) по эксплуатации ПС",
        ],
        correctAnswers: ["В акте пуска ПС в работу"],
      },
      {
        code: "69502",
        text: "Когда выдаются производственные инструкции персоналу, обслуживающему ПС?",
        answers: [
          "Перед прохождением периодического инструктажа",
          "Перед допуском к работе, под расписку",
          "После прохождения вводного инструктажа",
          "Перед проведением первичного инструктажа на рабочем месте",
        ],
        correctAnswers: ["Перед допуском к работе, под расписку"],
      },
      {
        code: "69503",
        text: "В каком из перечисленных случаев при внеочередном полном техническом освидетельствовании ПС проводятся только статические испытания?",
        answers: [
          "После установки сменного стрелового оборудования или замены стрелы",
          "После замены грузозахватного органа",
          "После капитального ремонта или замены грузовой или стреловой лебедки",
          "После замены несущих или вантовых канатов кранов кабельного типа",
        ],
        correctAnswers: ["После замены грузозахватного органа"],
      },
      {
        code: "69504",
        text: "При каком положении крана на надземном рельсовом пути следует проверять соответствие расстояния от выступающих частей торцов крана до колонн, стен здания и перил проходных галерей?",
        answers: [
          "При положении крана, когда колеса одной из концевых балок максимально смещены в поперечном направлении относительно рельса",
          "В положении крана, который соответствует наибольшему уширению колеи рельсового пути в зоне, обслуживаемой краном",
          "При фактическом расположении колес крана относительно рельса во время проведения измерений",
          "При симметричном расположении колес крана относительно рельса",
        ],
        correctAnswers: [
          "При симметричном расположении колес крана относительно рельса",
        ],
      },
      {
        code: "69505",
        text: "Какая организация осуществляет периодическое комплексное обследование рельсовых путей ПС?",
        answers: [
          "Эксплуатирующая организация",
          "Экспертная организация",
          "Специализированная организация",
          "Проектная организация",
        ],
        correctAnswers: ["Специализированная организация"],
      },
      {
        code: "69506",
        text: "С кем следует согласовывать установку кранов, передвигающихся по рельсовому пути, в охранной зоне воздушных линий (далее - ВЛ) электропередачи?",
        answers: [
          "С органом муниципального управления, по территории которого проходит ВЛ",
          "С организацией, эксплуатирующей ВЛ",
          "С владельцем линии",
          "С территориальным органом Ростехнадзора",
        ],
        correctAnswers: ["С владельцем линии"],
      },
      {
        code: "69507",
        text: "Каким требованиям из перечисленных должны отвечать рельсовый путь ПС (исключая рельсовые пути башенных и железнодорожных кранов) и рельсовый путь грузовых подвесных тележек или электрических талей, оборудованный стрелками или поворотными кругами, а также места перехода ПС или его грузовой тележки с одного пути на другой?",
        answers: [
          "Обеспечивать плавный, без заеданий, проезд",
          "Быть оборудованными замками с электрической блокировкой, исключающей переезд при незапертом замке",
          "Иметь автоматически включаемую блокировку, исключающую сход грузовой тележки (электрической тали) с рельса при выезде ее на консоль расстыкованного участка пути",
          "Обеспечивать управление переводом стрелки или поворотного круга от сигнала системы управления грузовой тележкой (электрической талью)",
          "Быть оборудованными единым выключателем для подачи напряжения на троллеи (или электрический кабель) грузовой тележки (электрической тали), на механизмы управления стрелок и электрические аппараты блокировочных устройств",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "69508",
        text: "С какой периодичностью результаты осмотров рельсовых путей заносятся в вахтенные журналы крановщика (оператора) всех ПС, установленных на одном рельсовом пути?",
        answers: [
          "После каждых 10 смен",
          "После каждых 15 смен",
          "После каждых 20 смен",
          "После каждых 24 смен",
        ],
        correctAnswers: ["После каждых 24 смен"],
      },
      {
        code: "69509",
        text: "В каких случаях к акту сдачи-приемки рельсового пути, определяющему его готовность к эксплуатации, должны прикладываться данные планово-высотной съемки?",
        answers: [
          "Только после ремонта",
          "Только после реконструкции",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "69510",
        text: "Допускается ли пересечение путей козловых, башенных и портальных кранов с рельсовыми путями заводского транспорта?",
        answers: [
          "Допускается, только после выполнения мероприятий по безопасному ведению работ на рельсовых путях",
          "Допускается, только после разработки мероприятий по предупреждению столкновения работающих кранов с подвижным составом и согласованию с организацией, в ведении которой находится организация движения на железнодорожных путях",
          "Допускается, только при согласовании с территориальным органом Ростехнадзора",
          "Не допускается",
        ],
        correctAnswers: [
          "Допускается, только после разработки мероприятий по предупреждению столкновения работающих кранов с подвижным составом и согласованию с организацией, в ведении которой находится организация движения на железнодорожных путях",
        ],
      },
      {
        code: "69511",
        text: "Что включает в себя проверка состояния рельсового пути, находящегося в эксплуатации? Укажите все правильные ответы.",
        answers: [
          "Ежесменный осмотр",
          "Техническое обслуживание",
          "Плановую или внеочередную проверку состояния",
          "Периодическое комплексное обследование",
        ],
        correctAnswers: [
          "Ежесменный осмотр",
          "Плановую или внеочередную проверку состояния",
        ],
      },
      {
        code: "69512",
        text: "Какой документ подтверждает готовность рельсового пути к эксплуатации, в том числе после ремонта (реконструкции)?",
        answers: [
          "Акт сдачи-приемки",
          "Заключение комиссии",
          "Протокол",
          "Руководство (инструкция) по эксплуатации ПС с соответствующей отметкой",
        ],
        correctAnswers: ["Акт сдачи-приемки"],
      },
      {
        code: "69513",
        text: "Кем осуществляется ежесменный осмотр рельсового пути ПС?",
        answers: [
          "Начальником цеха (участка)",
          "Службой эксплуатации, отвечающей за состояние рельсовых путей",
          "Крановщиком (оператором) в объеме, предусмотренном производственной инструкцией",
          "Специалистом, ответственным за безопасное производство работ с применением ПС",
        ],
        correctAnswers: [
          "Крановщиком (оператором) в объеме, предусмотренном производственной инструкцией",
        ],
      },
      {
        code: "69514",
        text: "Какой запас по грузоподъемности должны иметь канатные стропы, используемые для подвеса люльки?",
        answers: ["Не менее 10", "Не менее 8", "Не менее 6", "Не менее 5"],
        correctAnswers: ["Не менее 10"],
      },
      {
        code: "69515",
        text: "В каких случаях разрешается нахождение инструментов и материалов совместно с людьми в подвесных люльках, транспортируемых кранами?",
        answers: [
          "Во всех случаях, если инструменты и материалы надежно закреплены",
          "Во всех случаях запрещено",
          "Если инструменты и материалы находятся в руках транспортируемых людей",
          "Только в случаях транспортировки людей для проведения диагностирования и ремонта металлоконструкций ПС, когда применение других средств подмащивания невозможно",
        ],
        correctAnswers: [
          "Во всех случаях, если инструменты и материалы надежно закреплены",
        ],
      },
      {
        code: "69516",
        text: "Куда записывается решение о вводе в эксплуатацию специальных съемных кабин и люлек (для подъема и перемещения людей кранами)?",
        answers: [
          "В паспорт кабины или люльки",
          "В специальный журнал учета и осмотра",
          "В положение о контроле качества технологических процессов, принимаемом эксплуатирующей организацией",
        ],
        correctAnswers: ["В паспорт кабины или люльки"],
      },
      {
        code: "69517",
        text: "Кто выдает разрешение на дальнейшую эксплуатацию подъемника (вышки) по завершению выполнения периодического планового технического освидетельствования?",
        answers: [
          "Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии",
          "Инженерно-технический работник, ответственный за безопасное производство работ с применением ПС",
          "Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии при участии специалиста, ответственного за безопасное производство работ с применением ПС",
          "Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС",
        ],
        correctAnswers: [
          "Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС",
        ],
      },
      {
        code: "69518",
        text: "В каком положении выполняют статические испытания подъемников (кроме строительных)?",
        answers: [
          "В положении, отвечающем его наименьшей расчетной устойчивости",
          "В положении, отвечающем его наибольшей расчетной устойчивости",
          "В положении продольной оси стрелы вдоль продольной оси подъемника",
          "В положении продольной оси стрелы, составляющей угол 45° с продольной осью подъемника",
        ],
        correctAnswers: [
          "В положении, отвечающем его наименьшей расчетной устойчивости",
        ],
      },
      {
        code: "69519",
        text: "В какой документ заносятся результаты грузовых испытаний люльки (кабины)?",
        answers: [
          "В вахтенный журнал",
          "В журнал осмотра люльки (кабины)",
          "В журнал учета и осмотра грузозахватных приспособлений",
          "В паспорт люльки (кабины)",
        ],
        correctAnswers: ["В паспорт люльки (кабины)"],
      },
      {
        code: "69520",
        text: "С какой периодичностью проводится плановая проверка состояния люльки (кабины)?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в 3 месяца",
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в год",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "69521",
        text: "Для каких типов подъемников при проведении статических испытаний часть испытательного груза подвешивают к люльке на гибкой подвеске?",
        answers: [
          "Для строительных подъемников",
          "Для всех подъемников, оборудованных люлькой, кроме строительных",
          "Для подъемников ножничного типа",
          "Для подъемников, имеющих специальный крюк",
        ],
        correctAnswers: [
          "Для всех подъемников, оборудованных люлькой, кроме строительных",
        ],
      },
      {
        code: "69522",
        text: "В каких случаях должна поддерживаться радио- или телефонная связь между оператором подъемника и персоналом в люльке?",
        answers: [
          "При подъеме люльки более 10 м",
          "При подъеме люльки более 22 м",
          "При работе подъемника в стесненных условиях",
          "При работе подъемника в условиях плохой видимости",
        ],
        correctAnswers: ["При подъеме люльки более 22 м"],
      },
      {
        code: "69523",
        text: "Какова должна быть длина фала страховочного устройства человека, соединяющего его пояс с местом крепления в подвесной люльке при ее транспортировке краном?",
        answers: [
          "Не более 1,5 м",
          "Не более 2 м",
          "Длина фала страховочного устройства должна быть такой, чтобы человек в случае аварии люльки мог беспрепятственно выбраться наружу",
          "Длина фала страховочного устройства должна быть такой, чтобы человек в любом случае оставался в пределах люльки",
        ],
        correctAnswers: [
          "Длина фала страховочного устройства должна быть такой, чтобы человек в любом случае оставался в пределах люльки",
        ],
      },
      {
        code: "69524",
        text: "Каким грузом следует проводить динамические испытания подъемника (вышки)?",
        answers: [
          "Грузом, масса которого на 20% превышает его паспортную грузоподъемность",
          "Грузом, масса которого на 10% превышает его паспортную грузоподъемность",
          "Грузом, масса которого на 5% превышает его паспортную грузоподъемность",
          "Грузом, масса которого на 25% превышает его паспортную грузоподъемность",
        ],
        correctAnswers: [
          "Грузом, масса которого на 10% превышает его паспортную грузоподъемность",
        ],
      },
      {
        code: "69525",
        text: "Грузом какой массы следует выполнять грузовые испытания люльки (кабины) для транспортировки кранами людей?",
        answers: [
          "Равной грузоподъемности люльки",
          "В 1,25 раза превышающей грузоподъемность люльки",
          "В 2 раза превышающей грузоподъемность люльки",
          "В 3 раза превышающей грузоподъемность люльки",
        ],
        correctAnswers: ["В 2 раза превышающей грузоподъемность люльки"],
      },
      {
        code: "69526",
        text: "В каких случаях между крановщиком и людьми, транспортируемыми в подвесной люльке краном, должна быть установлена постоянная телефонная или радиосвязь?",
        answers: [
          "Если крановщик видит люльку с людьми во время всей операции транспортировки, но ему недоступна для обзора зона начала подъема или зона опускания люльки",
          "Если это дополнительно указано в ППР с перемещением люльки",
          "Если транспортировка людей в люльке осуществляется при неблагоприятных погодных условиях",
          "Если люлька находится вне зоны видимости во время операции транспортировки",
          "Во всех случаях",
        ],
        correctAnswers: ["Во всех случаях"],
      },
      {
        code: "69527",
        text: "Какие действия не включает в себя проверка состояния люльки (кабины)?",
        answers: [
          "Ежесменный осмотр",
          "Грузовые испытания",
          "Плановую проверку состояния",
          "Внеплановую проверку состояния (если люлька (кабина) находилась на консервации и не эксплуатировалась сроком более 6 месяцев)",
          "Динамические испытания",
        ],
        correctAnswers: ["Динамические испытания"],
      },
      {
        code: "69528",
        text: "Каким требованиям промышленной безопасности должна отвечать люлька для подъема и транспортировки людей кранами в случаях, когда транспортируемый персонал занимает положение у одной из сторон люльки, создавая наибольший опрокидывающий момент?",
        answers: [
          "Исключать возможность крена",
          "Исключать возможность опрокидывания",
          "Исключать возможность раскачивания",
          "Ограничивать положение персонала центральной частью люльки после начала ее подъема краном",
        ],
        correctAnswers: ["Исключать возможность опрокидывания"],
      },
      {
        code: "69529",
        text: "Какие требования к статическим испытаниям подъемников (вышек) (кроме строительных) указаны неверно?",
        answers: [
          "Статические испытания проводят при установке подъемника (вышки) на горизонтальной площадке в положении, отвечающем наименьшей расчетной его устойчивости",
          "На подъемниках (вышках), оборудованных люлькой, груз массой, равной 110% от номинальной грузоподъемности, располагается в люльке, а второй груз массой, равной 40% от номинальной грузоподъемности, подвешивается к люльке на гибкой подвеске",
          "После начала подъема и отрыва второго груза от земли на высоту 50 - 100 мм, подъем останавливается с последующей выдержкой суммарного груза в течение 10 минут",
          "Все перечисленные требования указаны верно",
        ],
        correctAnswers: ["Все перечисленные требования указаны верно"],
      },
      {
        code: "69530",
        text: "Куда следует заносить результаты плановых осмотров люльки, предназначенной для транспортировки людей кранами?",
        answers: [
          "В вахтенный журнал",
          "В паспорт крана",
          "В журнал осмотра люльки",
          "В паспорт люльки",
        ],
        correctAnswers: ["В журнал осмотра люльки"],
      },
      {
        code: "69531",
        text: "Кем определяется время действия наряда-допуска на работу подъемника (вышки) вблизи линий электропередачи (далее - ЛЭП)?",
        answers: [
          "Организацией, его выдавшей",
          "Организацией, эксплуатирующей линию электропередачи",
          "Специалистом, ответственным за безопасное производство работ с применением ПС",
          "Разработчиком ППР на данный вид работ",
        ],
        correctAnswers: ["Организацией, его выдавшей"],
      },
      {
        code: "69532",
        text: "Для каких подъемников при проведении технического освидетельствования необходимо проверять точность остановки кабины с нагрузкой и без нагрузки?",
        answers: [
          "Для автогидроподъемников",
          "Для строительных подъемников",
          "Для подъемников ножничного типа, управление которыми осуществляется из люльки",
          "Для подъемников, предназначенных для осмотра контактной сети железных дорог",
        ],
        correctAnswers: ["Для строительных подъемников"],
      },
      {
        code: "69533",
        text: "Какие действия необходимо выполнить для утилизации (ликвидации) подъемника (вышки)?",
        answers: [
          "Получить отметку Ростехнадзора в паспорте подъемника (вышки) и отправить в металлолом",
          "Демонтировать подъемник (вышку)",
          "Получить письменное указание владельца ОПО и отправить в металлолом",
          "Не продлевать срок эксплуатации подъемника (вышки) по истечению срока службы, указанного в его паспорте",
        ],
        correctAnswers: ["Демонтировать подъемник (вышку)"],
      },
      {
        code: "69534",
        text: "Каким запасам по грузоподъемности должен удовлетворять грузоподъемный кран, ПС для его возможного использования при транспортировке людей, по сравнению с суммой массы самой люльки (кабины), массы устройства, предназначенного для подвешивания люльки (кабины), и паспортной номинальной грузоподъемности люльки (кабины) согласно требованиям, установленным ФНП ПС?",
        answers: [
          "Не менее чем двукратный запас",
          "Не менее чем трехкратный запас",
          "Не менее чем полуторакратный, в том числе и для тормозов его механизма подъема",
          "Не регламентируется, если кран оснащен ограничителем грузоподъемности (грузового момента)",
        ],
        correctAnswers: ["Не менее чем двукратный запас"],
      },
      {
        code: "69535",
        text: "С какой перегрузкой проводятся испытания на строительных подъемниках при проведении полного технического освидетельствования и проверки работоспособности ловителей (аварийных остановов)?",
        answers: [
          "C перегрузкой 10%",
          "C перегрузкой 15%",
          "C перегрузкой 20%",
          "C перегрузкой 25%",
        ],
        correctAnswers: ["C перегрузкой 10%"],
      },
      {
        code: "69536",
        text: "Какие из перечисленных ниже нарушений не могут служить причиной остановки эксплуатации подъемника?",
        answers: [
          "Отсутствует экспертиза промышленной безопасности нового подъемника, введенного в эксплуатацию",
          "Не выполнены предписания по обеспечению безопасной эксплуатации подъемника, выданные контролирующей организацией",
          "Обслуживание подъемника ведется необученным персоналом",
          "На ПС выявлены технические неисправности",
        ],
        correctAnswers: [
          "Отсутствует экспертиза промышленной безопасности нового подъемника, введенного в эксплуатацию",
        ],
      },
      {
        code: "69537",
        text: "Каким грузом следует проверять действие ловителей на строительных подъемниках?",
        answers: [
          "Масса которого на 20% превышает паспортную грузоподъемность",
          "Масса которого на 5% превышает паспортную грузоподъемность",
          "Масса которого на 10% превышает паспортную грузоподъемность",
          "Масса которого на 25% превышает паспортную грузоподъемность",
        ],
        correctAnswers: [
          "Масса которого на 10% превышает паспортную грузоподъемность",
        ],
      },
      {
        code: "69538",
        text: "Что необходимо предпринять в случае, когда зона, обслуживаемая подъемником (вышкой), не просматривается с места управления оператора (машиниста подъемника)?",
        answers: [
          "Для передачи сигналов оператору (машинисту подъемника или персоналу, находящемуся в люльке подъемника) должны быть назначены сигнальщики",
          "Осуществлять работу подъемника (вышки) под непосредственным руководством специалиста, ответственного за безопасное производство работ с применением ПС",
          "Для передачи сигналов оператору (машинисту подъемника или персоналу, находящемуся в люльке подъемника) должна использоваться радио- или телефонная связь",
          "Осуществлять работу подъемника (вышки) с использованием координатной защиты",
        ],
        correctAnswers: [
          "Для передачи сигналов оператору (машинисту подъемника или персоналу, находящемуся в люльке подъемника) должна использоваться радио- или телефонная связь",
        ],
      },
      {
        code: "69539",
        text: "Как следует поступить, если во время грузовых испытаний люльки для транспортировки людей кранами выявлены дефекты и повреждения?",
        answers: [
          "Поставить в известность ремонтную службу эксплуатирующей организации о выявленных дефектах и повреждениях люльки",
          "Эксплуатировать люльку до выполнения ремонта с ограничением по грузоподъемности",
          "Поставить в известность руководителя эксплуатирующей организации о выявленных дефектах и повреждениях люльки",
          "Запретить дальнейшую эксплуатацию люльки",
        ],
        correctAnswers: ["Запретить дальнейшую эксплуатацию люльки"],
      },
      {
        code: "69540",
        text: "Кто должен проводить техническое освидетельствование подъемника (вышки)?",
        answers: [
          "Специалист, ответственный за содержание ПС в работоспособном состоянии",
          "Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС, при участии специалиста, ответственного за содержание ПС в работоспособном состоянии",
          "Специалист, ответственный за осуществление производственного контроля при эксплуатации ПС, при участии специалиста, ответственного за безопасное производство работ с применением ПС",
          "Комиссия эксплуатирующей организации, в состав которой должен входить представитель органов Ростехнадзора",
        ],
        correctAnswers: [
          "Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС, при участии специалиста, ответственного за содержание ПС в работоспособном состоянии",
        ],
      },
      {
        code: "69541",
        text: "С какой периодичностью проводятся грузовые испытания люльки (кабины)?",
        answers: [
          "Не реже 1 раза в квартал",
          "Не реже 1 раза в год",
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в полугодие",
        ],
        correctAnswers: ["Не реже 1 раза в полугодие"],
      },
      {
        code: "69542",
        text: "Какие требования к статическим испытаниям кранов указаны верно?",
        answers: [
          "Испытания кранов стрелового типа, не имеющих механизма изменения вылета (стрела поддерживается растяжкой), проводятся при установленных для испытаний вылетах",
          "Статические испытания крана стрелового типа, имеющего одну или несколько грузовых характеристик, при периодическом или внеочередном техническом освидетельствовании проводятся в положении, соответствующем наибольшей грузоподъемности крана и (или) наибольшему грузовому моменту, если это не противоречит требованиям, изложенным в руководстве (инструкции) по эксплуатации ПС",
          "Испытания кранов, имеющих сменное стреловое оборудование, проводятся с установленным стреловым оборудованием, а также после замены стрелового оборудования",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "69543",
        text: "Каким образом должны быть расположены ветви многоветвевых стропов при испытаниях?",
        answers: [
          "Под углом 90° по вертикали друг к другу",
          "Под углом 120° по вертикали друг к другу",
          "Под углом 120° по горизонтали друг к другу",
          "Под углом 90° по горизонтали друг к другу",
        ],
        correctAnswers: ["Под углом 90° по вертикали друг к другу"],
      },
      {
        code: "69544",
        text: "Кто определяет порядок работы крана вблизи линии электропередачи, выполненной гибким изолированным кабелем?",
        answers: [
          "Эксплуатирующая организация",
          "Разработчик ППР",
          "Владелец линии",
          "Специализированная организация",
        ],
        correctAnswers: ["Владелец линии"],
      },
      {
        code: "69545",
        text: "При каких величинах суммарной массы тары с перемещаемым грузом допускается применение башенных кранов с тарой, разгружаемой на весу, в пределах группы классификации (режима), указанного в паспорте крана, при числе циклов работы крана не более 8 в час?",
        answers: [
          "Для тары с вибратором - не более 50% от максимальной грузоподъемности крана",
          "Для одноканатных грейферов, не допускающих разгрузку на весу, - не более 50% грузоподъемности крана",
          "Для тары без вибраторов (исключая грейферы) - в пределах грузоподъемности крана",
          "Применение допускается во всех перечисленных случаях",
        ],
        correctAnswers: [
          "Применение допускается во всех перечисленных случаях",
        ],
      },
      {
        code: "69546",
        text: "Какие требования к статическим испытаниям грузозахватного приспособления указаны верно?",
        answers: [
          "Испытательный груз, зацепленный (охваченный, обвязанный) испытываемым грузозахватным приспособлением или подвешенный к нему, с возможно меньшими ускорениями поднимают ПС на высоту 150-250 мм",
          "Испытания проводятся статической нагрузкой, превышающей его грузоподъемность на 50%",
          "Испытательный груз, поднятый на высоту 200 мм, выдерживают в таком положении не менее 5 минут",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "69547",
        text: "В каких случаях разрешается строповка пакетов металлопроката или труб за элементы упаковки (скрутки, стяжки)?",
        answers: [
          "Если они применяются всего для двух последующих перегрузок: например, для загрузки в полувагон и последующей разгрузки",
          "Если подъем и перемещение пакетов металлопроката или труб выполняют с помощью траверсы, крюки которой застропованы в скрутки",
          "Если скрутки выполнены из мягкой стальной проволоки и их не менее четырех",
          "Запрещается во всех случаях",
        ],
        correctAnswers: ["Запрещается во всех случаях"],
      },
      {
        code: "69548",
        text: "Каким оборудованием в эксплуатирующей организации должны быть обеспечены ее стропальщики, с целью обеспечения промышленной безопасности технологических процессов строповки?",
        answers: [
          "Канатными и цепными стропами, соответствующими массе перемещаемых грузов",
          "Испытанными грузозахватными приспособлениями и тарой, соответствующими массе перемещаемых грузов",
          "Определяется технологическими процессами транспортировки грузов, принятыми в эксплуатирующей организации",
          "Испытанными и маркированными грузозахватными приспособлениями и тарой, соответствующими массе и характеру перемещаемых грузов",
        ],
        correctAnswers: [
          "Испытанными и маркированными грузозахватными приспособлениями и тарой, соответствующими массе и характеру перемещаемых грузов",
        ],
      },
      {
        code: "69549",
        text: "В каких случаях разрешается подтаскивание груза по земле, полу или рельсам крюками ПС?",
        answers: [
          "Только в случаях применения ПС, не склонных к опрокидыванию (потере общей устойчивости)",
          "Только в случаях применения направляющих блоков, обеспечивающих вертикальное положение грузовых канатов",
          "Только в случаях, если канатный барабан механизма подъема ПС снабжен канатоукладчиком",
          "Только в случаях перемещения груза (тележки) по рельсам",
        ],
        correctAnswers: [
          "Только в случаях применения направляющих блоков, обеспечивающих вертикальное положение грузовых канатов",
        ],
      },
      {
        code: "69550",
        text: "Что должно быть предпринято в случае, когда зона, обслуживаемая ПС, полностью не просматривается из кабины управления (с места управления), и при отсутствии между оператором (крановщиком) и стропальщиком радио- или телефонной связи?",
        answers: [
          "Должен быть назначен специалист, ответственный за безопасное производство работ",
          "Должны быть разработаны условные обозначения для передачи сигнала",
          "Должен быть назначен сигнальщик из числа стропальщиков",
          "Должен быть разработан план действий для крановщика и стропальщика",
        ],
        correctAnswers: [
          "Должен быть назначен сигнальщик из числа стропальщиков",
        ],
      },
      {
        code: "69551",
        text: "На какой высоте над уровнем нижней посадочной площадки (земли) должен находиться груз на неподвижном грузонесущем устройстве при статических испытаниях строительного подъемника?",
        answers: [
          "Не более 50 мм",
          "Не более 250 мм",
          "Не более 200 мм",
          "Не более 150 мм",
        ],
        correctAnswers: ["Не более 150 мм"],
      },
      {
        code: "69552",
        text: "Куда записывается решение о вводе в эксплуатацию грузозахватных приспособлений, тары?",
        answers: [
          "В специальный журнал учета и осмотра",
          "На бирку грузозахватного приспособления, тары",
          "В паспорт грузозахватного приспособления, тары",
        ],
        correctAnswers: ["В специальный журнал учета и осмотра"],
      },
      {
        code: "69553",
        text: "Кто должен назначать сигнальщика в случаях, когда зона, обслуживаемая ПС, полностью не просматривается из кабины управления (с места управления), и при отсутствии между крановщиком и стропальщиком радио- или телефонной связи?",
        answers: [
          "Специалист, ответственный за осуществление производственного контроля при эксплуатации ПС",
          "Специалист, ответственный за безопасное выполнение работ с применением ПС",
          "Руководитель эксплуатирующей организации",
          "Специалист, ответственный за содержание ПС в работоспособном состоянии",
        ],
        correctAnswers: [
          "Специалист, ответственный за безопасное выполнение работ с применением ПС",
        ],
      },
      {
        code: "69554",
        text: "Какие краны, не оборудованные координатной защитой, запрещается применять для работы в стесненных условиях?",
        answers: [
          "Башенные краны, установленные на автомобильные шасси",
          "Грузоподъемные краны на пневмоколесном ходу",
          "Грузоподъемные краны на гусеничном ходу",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "69555",
        text: "С какой периодичностью в эксплуатации следует осматривать траверсы, клещи, захваты и тару?",
        answers: [
          "Ежемесячно",
          "Ежегодно",
          "Еженедельно",
          "Перед началом каждой рабочей смены",
          "Каждые 10 дней",
        ],
        correctAnswers: ["Ежемесячно"],
      },
      {
        code: "69556",
        text: "Каким документом оформляются результаты испытаний грузозахватных приспособлений от приложения статической нагрузкой?",
        answers: [
          "Заключением экспертизы промышленной безопасности",
          "Документом о проведении полного технического освидетельствования",
          "Актом или протоколом",
        ],
        correctAnswers: ["Актом или протоколом"],
      },
      {
        code: "69557",
        text: "В каких случаях проводятся испытания на грузовую устойчивость при первичном техническом освидетельствовании стрелового самоходного крана?",
        answers: [
          "Когда конструкция крана (стрела, гусек, аутригеры или опорно-поворотное устройство) была подвергнута ремонту расчетных элементов металлоконструкций с применением сварки",
          "Когда конструкция крана (стрела, гусек, аутригеры или опорно-поворотное устройство) была подвергнута изменению первоначальных геометрических размеров",
          "Когда конструкция крана (стрела, гусек, аутригеры или опорно-поворотное устройство) была подвергнута ремонту расчетных элементов металлоконструкций с заменой элементов",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "69558",
        text: "Когда результат статических испытаний крана стрелового типа считается положительным?",
        answers: [
          "Если в течение 10 минут поднятый груз не опустится на землю, а также не обнаружено трещин, остаточных деформаций и других повреждений металлоконструкций и механизмов",
          "Если кран не потеряет устойчивость за весь период испытаний",
          "Если не обнаружено трещин, остаточных деформаций и других повреждений стрелы",
          "Если в течение 5 минут поднятый груз не опустится на землю",
        ],
        correctAnswers: [
          "Если в течение 10 минут поднятый груз не опустится на землю, а также не обнаружено трещин, остаточных деформаций и других повреждений металлоконструкций и механизмов",
        ],
      },
      {
        code: "69559",
        text: "В каких случаях стреловым краном не разрешается подъем груза непосредственно с места его установки (с земли, площадки, штабеля)?",
        answers: [
          "Если осуществляется подъем длинномерного груза с применением траверсы",
          "Если подъем груза осуществляется на номинальной скорости механизма",
          "Если угол между стропами при подъеме груза составляет 80°",
          "Если подъем груза осуществляется только механизмом телескопирования стрелы",
        ],
        correctAnswers: [
          "Если подъем груза осуществляется только механизмом телескопирования стрелы",
        ],
      },
      {
        code: "69560",
        text: "В каких случаях разрешены подъем и транспортировка людей с применением ПС, в паспорте которых отсутствует разрешение на транспортировку людей?",
        answers: [
          "При монтаже, строительстве и возведении уникальных объектов, когда иные способы доставки рабочих в зону выполнения работ не могут быть применены",
          "При проведении диагностирования и ремонта металлоконструкций ПС, когда применение других средств подмащивания невозможно",
          "При перемещении персонала для крепления и раскрепления контейнеров и грузов",
          "Во всех перечисленных",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "69561",
        text: "С какой периодичностью производятся частичная разборка, осмотр и ревизия элементов, узлов и соединений грузозахватных приспособлений (клещи, траверсы, захваты) для контроля технического состояния, которое невозможно определить в собранном виде?",
        answers: ["Еженедельно", "Ежемесячно", "Ежеквартально", "Ежегодно"],
        correctAnswers: ["Ежегодно"],
      },
      {
        code: "69562",
        text: "Если в руководстве (инструкции) по эксплуатации ПС отсутствуют требования к его установке на выносные опоры, то в каком случае разрешается установка подъемников (вышек) только на две или три выносные опоры?",
        answers: [
          "При наличии допустимого уклона одной из частей площадки установки",
          "Если подъем и перемещение груза будет выполняться только в одном положении стрелы",
          "Если отсутствует одна из инвентарных подкладок, устанавливаемых под опору",
          "Не разрешается, ПС устанавливается на все выносные опоры",
        ],
        correctAnswers: [
          "Не разрешается, ПС устанавливается на все выносные опоры",
        ],
      },
      {
        code: "69563",
        text: "В каких случаях необходимо прекращать работу ПС, установленных на открытом воздухе?",
        answers: [
          "При температуре окружающей среды ниже предельно допустимой температуры, указанной в паспорте ПС",
          "При снегопаде, дожде, тумане, в случаях, когда крановщик (машинист, оператор) плохо различает сигналы стропальщика или перемещаемый груз",
          "При скорости ветра, превышающей предельно допустимую скорость, указанную в паспорте ПС",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "69564",
        text: "Если в руководстве (инструкции) по эксплуатации ПС отсутствуют требования к его установке на выносные опоры, то в каком случае разрешается установка стрелового крана, крана-манипулятора только на две или три выносные опоры?",
        answers: [
          "Если подъем и перемещение груза будет выполняться только в одном положении стрелы",
          "При отсутствии места на площадке установки для всех четырех опор",
          "Если отсутствует одна из инвентарных подкладок, устанавливаемых под опору",
          "Не разрешается, ПС устанавливается на все выносные опоры",
        ],
        correctAnswers: [
          "Не разрешается, ПС устанавливается на все выносные опоры",
        ],
      },
      {
        code: "69565",
        text: "Каким грузом следует проводить динамические испытания стреловых самоходных кранов?",
        answers: [
          "Масса которого на 10% превышает его паспортную грузоподъемность",
          "Масса которого на 15% превышает его паспортную грузоподъемность",
          "Масса которого на 25% превышает его паспортную грузоподъемность",
          "Масса которого на 20% превышает его паспортную грузоподъемность",
        ],
        correctAnswers: [
          "Масса которого на 10% превышает его паспортную грузоподъемность",
        ],
      },
      {
        code: "69566",
        text: "Какое расстояние установлено от верхней точки крана, передвигающегося по надземному рельсовому пути, до потолка здания или предметов конструкции здания над краном?",
        answers: [
          "Не менее 80 мм",
          "Не менее 40 мм",
          "Не менее 60 мм",
          "Не менее 100 мм",
        ],
        correctAnswers: ["Не менее 100 мм"],
      },
      {
        code: "69567",
        text: "На каком расстоянии от элементов здания, оборудования и штабелей грузов следует устанавливать электрические тали и монорельсовые тележки с автоматическим или полуавтоматическим управлением, если во время движения указанные ПС не сопровождаются оператором?",
        answers: [
          "В соответствии с требованиями руководства (инструкции) по эксплуатации",
          "Таким образом, чтобы во время движения исключить возможность задевания грузом элементов здания, оборудования и штабелей грузов",
          "С учетом максимальных габаритов транспортируемых грузов",
          "С учетом максимальных габаритов транспортируемых грузов и ширины прохода вдоль цеха для работников ОПО, если такой проход предусмотрен",
        ],
        correctAnswers: [
          "Таким образом, чтобы во время движения исключить возможность задевания грузом элементов здания, оборудования и штабелей грузов",
        ],
      },
      {
        code: "69568",
        text: "Какие требования к статическим испытаниям крана-трубоукладчика или крана-манипулятора указаны неверно?",
        answers: [
          "Статические испытания крана-трубоукладчика или крана-манипулятора должны проводиться при его установке на горизонтальной площадке",
          "Испытания должны проводиться в положении, соответствующем наибольшей грузоподъемности",
          "Груз должен быть поднят на высоту 50 - 100 мм от земли",
          "Груз должен быть выдержан в течение не менее 10 минут",
          "Все перечисленное верно",
        ],
        correctAnswers: ["Все перечисленное верно"],
      },
      {
        code: "69569",
        text: "На какую высоту следует предварительно поднять груз перед началом перемещения (с последующей остановкой) для проверки правильности строповки и надежности действия тормоза ПС?",
        answers: [
          "Не более 0,2-0,3 м",
          "Не более 0,6-0,8 м",
          "Не более 0,8-1,0 м",
          "Не более 1,0-1,5 м",
        ],
        correctAnswers: ["Не более 0,2-0,3 м"],
      },
      {
        code: "69570",
        text: "Какова продолжительность статических испытаний кабельных кранов?",
        answers: ["40 минут", "30 минут", "20 минут", "10 минут"],
        correctAnswers: ["30 минут"],
      },
      {
        code: "69571",
        text: "Как должна распределяться нагрузка на каждое из ПС, если подъем и перемещение груза осуществляют двумя ПС?",
        answers: [
          "Нагрузка, приходящаяся на каждое из них, не должна превышать 0,75% грузоподъемности ПС, имеющего меньшую грузоподъемность",
          "Нагрузка, приходящаяся на каждое ПС, не должна превышать грузоподъемность ПС",
          "Нагрузка, приходящаяся на каждое из них, должна быть выровнена несимметричной строповкой груза и быть по возможности одинаковой",
          "Нагрузка, приходящаяся на каждое из них, должна контролироваться взвешивающими устройствами ПС",
        ],
        correctAnswers: [
          "Нагрузка, приходящаяся на каждое ПС, не должна превышать грузоподъемность ПС",
        ],
      },
      {
        code: "69572",
        text: "Какое количество ветвей для стропов с числом ветвей более трех, учитывают в расчете их грузоподъемности?",
        answers: [
          "Не более 2 ветвей",
          "Не более 4 ветвей",
          "Не более 3 ветвей",
          "Все ветви, если груз несимметричен",
        ],
        correctAnswers: ["Не более 3 ветвей"],
      },
      {
        code: "69573",
        text: "Каким должно быть минимальное расстояние по горизонтали между ПС, их стрелами, стрелой одного ПС и перемещаемым грузом на стреле другого ПС, а также перемещаемыми грузами при совместной работе ПС на строительном объекте?",
        answers: ["2 м", "3 м", "4 м", "5 м"],
        correctAnswers: ["5 м"],
      },
      {
        code: "69574",
        text: "В каких местах должны быть установлены стационарные эстакады или навесные площадки для стропальщиков?",
        answers: [
          "В любых местах, кроме мест скопления напольного технологического оборудования",
          "В местах, которые отвечают требованиям промышленной безопасности во время возникновения аварийных ситуаций",
          "В любых местах, кроме проходов для персонала",
          "В местах постоянной погрузки и разгрузки автомашин и полувагонов",
        ],
        correctAnswers: [
          "В местах постоянной погрузки и разгрузки автомашин и полувагонов",
        ],
      },
      {
        code: "69575",
        text: "В каких случаях разрешается перемещение грузов, находящихся в неустойчивом положении?",
        answers: [
          "Только в случаях выравнивая несимметрично уложенного груза, при задевании им о борта кузова автомобиля или полувагона",
          "Только в аварийных ситуациях, когда необходимо как можно скорее переместить груз от источника возникновения аварии",
          "Только в случае частичного подъема и разворота груза без полного его отрыва от земли",
          "Только в случае, когда осуществляется кантовка этого груза",
          "Запрещается",
        ],
        correctAnswers: ["Запрещается"],
      },
      {
        code: "69576",
        text: "Какое расстояние установлено по вертикали от консоли противовеса башенного крана до площадок, на которых могут находиться люди?",
        answers: [
          "Не менее 1,5 м",
          "Не менее 1,7 м",
          "Не менее 1,0 м",
          "Не менее 2,0 м",
        ],
        correctAnswers: ["Не менее 2,0 м"],
      },
      {
        code: "69577",
        text: "Допускается ли при выполнении строительно-монтажных или погрузочно-разгрузочных работ перемещение грузов с применением ПС над перекрытиями, под которыми размещены производственные, жилые или служебные помещения, где могут находиться люди?",
        answers: [
          "Допускается, в исключительных случаях в присутствии и под руководством специалиста, ответственного за безопасное производство работ с применением ПС",
          "Допускается при условии, что место производства работ будут ограждены и обозначены предупредительными знаками",
          "Допускается",
          "Не допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "69578",
        text: "Насколько выше встречающихся на пути предметов и оборудования должны находиться стрелы кранов при их повороте или перемещении?",
        answers: [
          "Не менее чем на 0,4 м",
          "Не менее чем на 1,0 м",
          "Не менее чем на 0,5 м",
          "Не менее чем на 0,3 м",
        ],
        correctAnswers: ["Не менее чем на 0,5 м"],
      },
      {
        code: "69579",
        text: "Каким должно быть безопасное расстояние от низа перемещаемого груза до наиболее выступающих по вертикали частей здания или сооружения?",
        answers: [
          "Не менее 2,5 м",
          "Не менее 1,0 м",
          "Не менее 0,5 м",
          "Не менее 1,5 м",
        ],
        correctAnswers: ["Не менее 0,5 м"],
      },
      {
        code: "69580",
        text: "По каким точкам грузовой характеристики должна проводиться проверка ограничителя грузового момента, если грузоподъемность ПС изменяется в зависимости от вылета, положения грузовой тележки или пространственного положения элемента ПС?",
        answers: [
          "Не менее чем в 3 точках его грузовой характеристики",
          "Не менее чем в 2 точках его грузовой характеристики",
          "Только в 2 точках его грузовой характеристики",
          "Правилами не регламентируется",
        ],
        correctAnswers: ["Не менее чем в 3 точках его грузовой характеристики"],
      },
      {
        code: "69581",
        text: "Какие требования к передвижению стрелового самоходного крана указаны неверно?",
        answers: [
          "Основание, по которому перемещается кран с грузом, должно быть ровным и иметь уклон не более указанного в руководстве (инструкции) по эксплуатации крана",
          "Движение крана с места при раскачивающемся грузе разрешается при отклонении последнего на угол, не более указанного в руководстве (инструкции) по эксплуатации крана",
          "Основание, по которому перемещается кран с грузом, должно иметь твердое покрытие, выдерживающее без просадки удельное давление не менее величин, указанных в паспорте или руководстве (инструкции) по эксплуатации крана",
          "Стреловым самоходным кранам разрешается перемещаться с грузом на крюке",
        ],
        correctAnswers: [
          "Движение крана с места при раскачивающемся грузе разрешается при отклонении последнего на угол, не более указанного в руководстве (инструкции) по эксплуатации крана",
        ],
      },
      {
        code: "69582",
        text: "С какой нагрузкой по отношению к номинальной паспортной грузоподъемности должна проводиться проверка качества выполненного ремонта грузозахватных приспособлений с проведением статических испытаний?",
        answers: ["150%", "125%", "130%", "140%"],
        correctAnswers: ["125%"],
      },
      {
        code: "69583",
        text: "Какие требования предъявляются к испытанию стальных цепей, устанавливаемых на ПС, после их сращивания электросваркой?",
        answers: [
          "Цепь должна быть испытана нагрузкой, в 2 раза превышающей ее расчетное натяжение, в течение 5 мин",
          "Цепь должна быть испытана нагрузкой, в 1,25 раза превышающей ее расчетное натяжение, в течение 10 мин",
          "Цепь должна быть испытана нагрузкой, в 3 раза превышающей ее расчетное натяжение, в течение 15 мин",
          "Цепь должна быть испытана нагрузкой, в 1,5 раза превышающей ее расчетное натяжение, в течение 15 мин",
        ],
        correctAnswers: [
          "Цепь должна быть испытана нагрузкой, в 1,25 раза превышающей ее расчетное натяжение, в течение 10 мин",
        ],
      },
      {
        code: "69584",
        text: "Какие требования, установленные для специализированной организации, осуществляющей деятельность по монтажу (демонтажу), наладке, ремонту, реконструкции или модернизации ПС в процессе эксплуатации ОПО, указаны верно?",
        answers: [
          "Располагать необходимым персоналом, а также руководителями и специалистами, имеющими полномочия, необходимые для выполнения своих обязанностей, в том числе выявления случаев отступлений от требований к качеству работ, от процедур проведения работ, и для принятия мер по предупреждению или сокращению таких отступлений",
          "Определять процедуры контроля соблюдения технологических процессов",
          "Устанавливать ответственность, полномочия и взаимоотношения работников, занятых в управлении, выполнении или проверке выполнения работ",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "69585",
        text: "Какая организация должна располагать контрольно-измерительными приборами, позволяющими оценивать работоспособность и регулировку оборудования ПС при проведении ремонта (монтажа)?",
        answers: [
          "Специализированная организация",
          "Эксплуатирующая организация",
          "Надзорная организация",
        ],
        correctAnswers: ["Специализированная организация"],
      },
      {
        code: "69586",
        text: "На кого возлагаются организация и проведение испытаний ПС по завершении выполненных работ по монтажу ПС?",
        answers: [
          "На эксплуатирующую организацию",
          "На специализированную организацию, осуществившую монтаж ПС",
          "На субподрядную организацию, имеющую в наличии тарированные грузы для проведения контрольных грузовых испытаний",
          "На специализированную экспертную организацию",
        ],
        correctAnswers: [
          "На специализированную организацию, осуществившую монтаж ПС",
        ],
      },
      {
        code: "69587",
        text: 'Кто в ФНП ПС определен термином "работники" специализированной организации?',
        answers: [
          "Все сотрудники организации, включая лиц рабочих профессий",
          "Аттестованные специалисты и лица рабочих профессий",
          "Руководители, специалисты и персонал - лиц рабочих профессий основных служб организации",
          "Физические лица основных служб организации, непосредственно занятые на выполнении работ",
          "Инженерно-технические работники, имеющие высшее или среднее профессиональное образование, и персонал - лица рабочих профессий, непосредственно занятые на выполнении работ",
        ],
        correctAnswers: [
          "Инженерно-технические работники, имеющие высшее или среднее профессиональное образование, и персонал - лица рабочих профессий, непосредственно занятые на выполнении работ",
        ],
      },
      {
        code: "69588",
        text: "Кого относят к работникам специализированных организаций, занимающихся выполнением работ по монтажу (демонтажу), наладке либо ремонту, реконструкции или модернизации в процессе эксплуатации ПС?",
        answers: [
          "Инженерно-технических работников, имеющих высшее или среднее профессиональное образование, и персонал - лиц рабочих профессий, непосредственно занятых на выполнении работ",
          "Аттестованных специалистов и лиц рабочих профессий",
          "Руководителей, специалистов и персонал - лиц рабочих профессий основных служб организации",
          "Физических лиц основных служб организации, непосредственно занятых на выполнении работ",
        ],
        correctAnswers: [
          "Инженерно-технических работников, имеющих высшее или среднее профессиональное образование, и персонал - лиц рабочих профессий, непосредственно занятых на выполнении работ",
        ],
      },
      {
        code: "69589",
        text: "Каким из нижеприведенных требований должны отвечать работники, непосредственно выполняющие работы по монтажу?",
        answers: [
          "Знать и уметь оценивать остаточный ресурс ПС",
          "Быть аттестованными по экспертизе промышленной безопасности",
          "Быть аттестованными на право управления монтируемого ПС",
          "Знать и уметь применять такелажные и монтажные приспособления",
        ],
        correctAnswers: [
          "Знать и уметь применять такелажные и монтажные приспособления",
        ],
      },
      {
        code: "69590",
        text: "Кто должен выполнять работы на регистраторах, ограничителях и указателях ПС?",
        answers: [
          "Работники, допущенные учебным центром к работе на регистраторах, ограничителях и указателях на основании проверки знаний",
          "Только работники эксплуатирующей ПС организации, прошедшие соответствующую аттестацию и проверку знаний по промышленной безопасности",
          "Работники специализированных или эксплуатирующих организаций, квалификация которых соответствует требованиям изготовителей (разработчиков), изложенным в эксплуатационных документах ПС, регистраторов, ограничителей и указателей",
          "Работники специализированных организаций, имеющих допуск на производство этих работ, выданный изготовителями регистраторов, ограничителей и указателей",
        ],
        correctAnswers: [
          "Работники специализированных или эксплуатирующих организаций, квалификация которых соответствует требованиям изготовителей (разработчиков), изложенным в эксплуатационных документах ПС, регистраторов, ограничителей и указателей",
        ],
      },
      {
        code: "69591",
        text: "Кто может заниматься деятельностью по монтажу, наладке, ремонту, реконструкции или модернизации ПС в процессе эксплуатации ОПО?",
        answers: [
          "Специализированные организации",
          "Физические лица и индивидуальные предприниматели",
          "Специализированные организации и физические лица",
          "Юридические и физические лица",
        ],
        correctAnswers: ["Специализированные организации"],
      },
      {
        code: "69592",
        text: "Каким образом необходимо исключать в процессе работы специализированной организации использование материалов и изделий, на которые отсутствуют сертификаты, паспорта и другие документы, подтверждающие их качество?",
        answers: [
          "Наличием квалификации специалистов и персонала",
          "Принятой системой обучения и аттестации работников",
          "Технологической подготовкой производства и установленным производственным процессом",
          "Ответственностью специалистов и персонала",
        ],
        correctAnswers: [
          "Технологической подготовкой производства и установленным производственным процессом",
        ],
      },
      {
        code: "69593",
        text: "В каком документе должно быть определено распределение ответственности работников организации, осуществляющей монтаж, наладку, ремонт, реконструкцию или модернизацию ПС в процессе эксплуатации ОПО?",
        answers: [
          "В положении об ОТК организации",
          "В положении о контроле соблюдения технологических процессов специализированной организации",
          "В положении о промышленной безопасности организации",
          "В положении о требованиях к работникам организации",
        ],
        correctAnswers: [
          "В положении о контроле соблюдения технологических процессов специализированной организации",
        ],
      },
      {
        code: "69594",
        text: "Каким документом определено распределение ответственности работников специализированной организации?",
        answers: [
          "Положением о требованиях к работникам организации",
          "Положением о промышленной безопасности организации",
          "Положением о контроле соблюдения технологических процессов",
          "Положением об отделе технического контроля организации",
        ],
        correctAnswers: [
          "Положением о контроле соблюдения технологических процессов",
        ],
      },
      {
        code: "69595",
        text: "Чем определяется конкретный перечень требований к специализированной организации, которая будет заниматься деятельностью по монтажу, наладке, ремонту, реконструкции или модернизации ПС в процессе эксплуатации ОПО?",
        answers: [
          "Номенклатурой ПС и технологией сварки, заявленными организацией для своей последующей деятельности",
          "Номенклатурой ПС и технологическими процессами, заявленными организацией для своей последующей деятельности",
          "Номенклатурой ПС, наличием необходимого оборудования и аттестованных специалистов",
          "Номенклатурой ПС, наличием необходимого оборудования и квалификацией работников",
        ],
        correctAnswers: [
          "Номенклатурой ПС и технологическими процессами, заявленными организацией для своей последующей деятельности",
        ],
      },
      {
        code: "69596",
        text: "Какие обязанности эксплуатирующей ПС организации указаны неверно?",
        answers: [
          "При отсутствии в эксплуатационных документах регистраторов указаний о сроках считывания данных выполнять такие операции не реже одного раза в год",
          "Обеспечивать соблюдение технологического процесса транспортировки грузов и приостановку работы ПС в случае возникновения угрозы аварийной ситуации",
          "Устанавливать порядок контроля обучения и периодических проверок знаний специалистов и персонала, работающих с ограничителями, указателями и регистраторами",
          'При выявлении нарушений требований к эксплуатации ПС, изложенных в ФНП "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения", принимать меры по их устранению и предупреждению, в том числе проводить внеочередную проверку знаний работников, допустивших такие нарушения',
        ],
        correctAnswers: [
          "При отсутствии в эксплуатационных документах регистраторов указаний о сроках считывания данных выполнять такие операции не реже одного раза в год",
        ],
      },
      {
        code: "69597",
        text: "Кто может выполнять работы по неразрушающему контролю, если работы по монтажу, ремонту, реконструкции или модернизации выполнялись с применением сварки?",
        answers: [
          "Собственная аттестованная лаборатория или аттестованная лаборатория, привлекаемая на договорной основе",
          "Собственная аттестованная лаборатория, имеющая поверенные приборы неразрушающего контроля",
          "Любые специалисты по неразрушающему контролю, если выполнение работ включает необходимость контроля стыковых сварных швов",
          "Физические лица, аттестованные в установленном порядке по выполнению неразрушающего контроля",
        ],
        correctAnswers: [
          "Собственная аттестованная лаборатория или аттестованная лаборатория, привлекаемая на договорной основе",
        ],
      },
      {
        code: "69598",
        text: "Чем запрещается оснащать краны, в зоне работы которых находятся производственные или другие помещения?",
        answers: [
          "Грузовым электромагнитом",
          "Концевым выключателем электромеханического типа",
          "Ограничителем грузоподъемности",
          "Регистратором параметров",
        ],
        correctAnswers: ["Грузовым электромагнитом"],
      },
      {
        code: "69599",
        text: "Что необходимо предпринять, если при ремонте регистратора параметров не представляется возможным восстановление информации долговременного хранения?",
        answers: [
          "Эксплуатирующей организацией должна быть проведена корректировка программного обеспечения",
          "Специализированной организацией должна быть сделана соответствующая запись в паспорте ПС",
          "Специализированной и эксплуатирующей организациями должен быть составлен соответствующий протокол",
        ],
        correctAnswers: [
          "Специализированной организацией должна быть сделана соответствующая запись в паспорте ПС",
        ],
      },
      {
        code: "69600",
        text: "В течение какого времени допускается временное хранение профильного проката на открытом воздухе?",
        answers: [
          "В течение 6 месяцев со дня поставки",
          "В течение 5 месяцев со дня поставки",
          "В течение 4 месяцев со дня поставки",
          "В течение 3 месяцев со дня поставки",
        ],
        correctAnswers: ["В течение 3 месяцев со дня поставки"],
      },
      {
        code: "69601",
        text: "В течение какого времени груз должен находиться на неподвижном грузонесущем устройстве без смещения грузонесущего устройства и без обнаружения трещин, остаточных деформаций и других повреждений металлоконструкций и механизмов, чтобы строительный подъемник считался выдержавшим статические испытания?",
        answers: [
          "В течении 3 минут",
          "В течении 5 минут",
          "В течении 10 минут",
          "В течении 15 минут",
        ],
        correctAnswers: ["В течении 10 минут"],
      },
      {
        code: "69602",
        text: "В каком случае допускается эксплуатация текстильных стропов на полимерной основе?",
        answers: [
          "Присутствуют выпучивания нитей из ленты стропа на расстояние более 10% ширины ленты",
          " Присутствует износ более 10% ширины петель стропа",
          "Имеются узлы на несущих лентах стропов",
          "Присутствует клеймо (бирка) и сведения о стропе, которые содержит информацию об изготовителе и грузоподъемности",
        ],
        correctAnswers: [
          "Присутствует клеймо (бирка) и сведения о стропе, которые содержит информацию об изготовителе и грузоподъемности",
        ],
      },
      {
        code: "69603",
        text: "Согласно требованиям какого документа должна выполняться проверка работоспособности регистратора параметров работы ПС?",
        answers: [
          "Требованиям ГОСТа",
          "Требованиям методических рекомендаций",
          "Требованиям руководства (инструкции) по эксплуатации",
          "Требованиям Федеральных норм и правил в области промышленной безопасности",
        ],
        correctAnswers: [
          "Требованиям руководства (инструкции) по эксплуатации",
        ],
      },
      {
        code: "69604",
        text: "В каком случае проводятся испытания на устойчивость при повторных технических освидетельствованиях для всех кранов стрелового типа и подъемников (вышек), у которых люлька закреплена на оголовке стрелы?",
        answers: [
          "После проведения ремонта расчетных элементов металлоконструкций с заменой элементов",
          "После проведения ремонта с применением сварки",
          "По требованию представителя Ростехнадзора",
          "Ни в каком случае не проводятся, если иное не указано в их руководстве (инструкции) по эксплуатации",
        ],
        correctAnswers: [
          "Ни в каком случае не проводятся, если иное не указано в их руководстве (инструкции) по эксплуатации",
        ],
      },
      {
        code: "69605",
        text: "Чему равен допустимый остаточный прогиб пролетного строения кранов мостового типа в вертикальной плоскости, где L - пролет крана?",
        answers: ["0,002L", "0,003L", "0,0025L", "0,0035L"],
        correctAnswers: ["0,0035L"],
      },
      {
        code: "69606",
        text: "Какая документация оформляется в процессе выполнения сварочных работ?",
        answers: [
          "Только журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю",
          "Только протоколы испытаний сварных соединений",
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
        ],
        correctAnswers: [
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "69607",
        text: "Кто допускается к выполнению сварочных работ на опасном производственном объекте?",
        answers: [
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
          "Сварщики и специалисты сварочного производства не моложе 21 года, имеющие группу по электробезопасности не ниже III и прошедшие обучение мерам пожарной безопасности в объеме пожарно-технического минимума",
          "Любые лица, обладающие необходимыми умениями и ознакомившиеся с требованиями охраны труда при производстве сварочных работ",
        ],
        correctAnswers: [
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
        ],
      },
      {
        code: "69608",
        text: "Каким образом допускается маркировать сварное соединение, выполненное несколькими сварщиками (бригадой сварщиков)?",
        answers: [
          "Должны быть поставлены клейма всех сварщиков, участвовавших в сварке",
          "Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
          "Допускается применение клейма одного из участвовавших в сварке сварщиков по выбору руководителя сварочных работ",
          "Допускается применение клейма сварщика, выполнившего наибольший объем работ",
        ],
        correctAnswers: [
          "Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
        ],
      },
      {
        code: "69609",
        text: "Чем должно быть укомплектовано место производства сварочных работ?",
        answers: [
          "Необходимым сварочным оборудованием и наглядными пособиями (плакатами, схемами и т. д.) по вопросу соблюдения противопожарного режима на объекте",
          "Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД",
          "На рабочем месте не должно быть ничего, кроме исправного сварочного оборудования",
        ],
        correctAnswers: [
          "Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД",
        ],
      },
      {
        code: "69610",
        text: "Какую проверку должен пройти сварщик, приступающий к сварке на конкретном объекте впервые или после перерыва в работе продолжительностью более установленного НД, независимо от наличия аттестационного удостоверения, до начала производства работ?",
        answers: [
          "Проверку знания теоретических основ сварки",
          "Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте с получением положительных результатов контроля их качества",
          "Проверку умения определять и устранять видимые дефекты сварного соединения",
        ],
        correctAnswers: [
          "Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте с получением положительных результатов контроля их качества",
        ],
      },
      {
        code: "69611",
        text: 'Какие требования предъявляются ФНП "Требования к производству сварочных работ на опасных производственных объектах" к сварочному оборудованию и сварочным материалам, применяемым при выполнении сварочных работ?',
        answers: [
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
          "Оборудование и материалы должны быть экономичными в использовании, простыми в ремонте",
          "Оборудование и материалы должны обеспечивать максимальную производительность работ",
        ],
        correctAnswers: [
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "69612",
        text: "Что должно быть указано в технологических картах сварки?",
        answers: [
          "Требования к применяемым сварочным технологиям, последовательность операций, технические приемы, особенности процесса сварки, обеспечивающие качество сварных соединений",
          "Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений",
          "Требования к сварочным материалам и сварочному оборудованию, режимы сварки, последовательность операций, технические приемы контроля качества сварных соединений",
        ],
        correctAnswers: [
          "Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений",
        ],
      },
      {
        code: "69613",
        text: "Какие функции обязано выполнить лицо, осуществляющее руководство сварочными работами, назначенное распорядительным документом организации или (и) должностной инструкцией которого предусмотрено руководство сварочными работами, перед выполнением сварочных работ?",
        answers: [
          "Ознакомить сварщиков с требованиями технологических карт сварки, а также с внесенными в них изменениями (при наличии), с подтверждением ознакомления подписями сварщиков в применяемых ими технологических картах сварки",
          "Проверить и обеспечить соответствие численного состава и квалификации персонала сварочного производства, сборочного и сварочного оборудования, основных и сварочных материалов, применяемой технологии сварки требованиям ПТД",
          "Организовать проведение операционного контроля",
          "Все перечисленные функции",
        ],
        correctAnswers: ["Все перечисленные функции"],
      },
      {
        code: "69614",
        text: "Что обязано проверить и обеспечить лицо, осуществляющее руководство сварочными работами, перед выполнением сварочных работ?",
        answers: [
          "Соответствие основных и сварочных материалов, применяемой технологии сварки требованиям ПТД",
          "Соответствие сборочного и сварочного оборудования, применяемой технологии сварки требованиям ПТД",
          "Соответствие численного состава и квалификации персонала сварочного производств",
          "Выполнение всех подготовительных мероприятий",
        ],
        correctAnswers: ["Выполнение всех подготовительных мероприятий"],
      },
      {
        code: "69615",
        text: "Что из нижеперечисленного не содержит в себе проект ремонта, реконструкции или модернизации ПС с применением сварки?",
        answers: [
          "Нормы браковки сварных соединений",
          "Порядок приемки из ремонта отдельных узлов и готовых изделий",
          "Проектно-сметную документацию на ремонтные работы",
          "Указания о применяемых металлах и сварочных материалах",
        ],
        correctAnswers: ["Проектно-сметную документацию на ремонтные работы"],
      },
      {
        code: "69616",
        text: "Сколько раз допускается повторная сварка на одном и том же участке сварного соединения?",
        answers: [
          "Не более 3 раз",
          "Не более 2 раз",
          "Не более 4 раз",
          "Повторная сварка запрещена",
        ],
        correctAnswers: ["Не более 2 раз"],
      },
      {
        code: "69617",
        text: "Какой объем ремонтных сварных соединений элементов металлоконструкций из высокопрочных сталей подвергается ультразвуковому и магнитопорошковому контролю?",
        answers: [
          "50% сварных соединений",
          "80% сварных соединений",
          "100% сварных соединений",
          "70% сварных соединений",
        ],
        correctAnswers: ["100% сварных соединений"],
      },
      {
        code: "69618",
        text: "В какой документации устанавливаются нормы, согласно которым определяется качество ремонтных сварных соединений ПС?",
        answers: [
          "В проекте ремонта, реконструкции или модернизации ПС",
          "В эксплуатационной документации",
          "Во всей перечисленной документации",
        ],
        correctAnswers: ["Во всей перечисленной документации"],
      },
      {
        code: "69619",
        text: "В каких случаях для контроля качества сварных швов допустимо применение капиллярного неразрушающего контроля?",
        answers: [
          "Применение капиллярного контроля сварных швов предпочтительно, если неразрушающий контроль необходимо выполнить при отрицательных температурах окружающего воздуха",
          "Применение капиллярного контроля сварных швов (кроме стыковых) устанавливается специализированной организацией в проекте ремонта, реконструкции или модернизации ПС",
          "Применение капиллярного контроля сварных швов (кроме стыковых) возможно, если другие методы неразрушающего контроля применить невозможно или нецелесообразно",
          "Применение капиллярного контроля при ремонте, реконструкции или модернизации ПС для контроля качества сварных швов запрещается",
        ],
        correctAnswers: [
          "Применение капиллярного контроля сварных швов (кроме стыковых) устанавливается специализированной организацией в проекте ремонта, реконструкции или модернизации ПС",
        ],
      },
      {
        code: "69620",
        text: "Где указывается суммарная длина контролируемых участков сварных соединений?",
        answers: [
          "В паспорте ПС",
          "В проекте ремонта, реконструкции или модернизации ПС",
          "В стандарте организации, разработанном специализированной организацией",
          "В технологической карте на реконструкцию",
        ],
        correctAnswers: [
          "В проекте ремонта, реконструкции или модернизации ПС",
        ],
      },
      {
        code: "69621",
        text: "В соответствии с какими документами должен проводиться контроль стыковых сварных соединений радиографическим или ультразвуковым методом?",
        answers: [
          "В соответствии с проектом ремонта, реконструкции или модернизации ПС, разработанным специализированной организацией",
          'В соответствии с ГОСТ "Контроль неразрушающий. Соединения сварные. Методы ультразвуковые"',
          'В соответствии с ГОСТ "Контроль неразрушающий. Соединения сварные. Радиографический метод"',
          "В соответствии с указаниями проектно-технологической документации эксплуатирующей организации",
        ],
        correctAnswers: [
          "В соответствии с проектом ремонта, реконструкции или модернизации ПС, разработанным специализированной организацией",
        ],
      },
      {
        code: "69622",
        text: "Что из перечисленного обозначает личный шифр клейм сварщиков?",
        answers: [
          "Уникальный шифр, содержащий двухзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях",
          "Уникальный шифр, содержащий трехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях",
          "Уникальный шифр, содержащий четырехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях",
          "Уникальный шифр, содержащий пятизначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях",
        ],
        correctAnswers: [
          "Уникальный шифр, содержащий четырехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях",
        ],
      },
      {
        code: "69623",
        text: "В соответствии с чьими указаниями должно содержаться в исправном состоянии, обслуживаться и эксплуатироваться сварочное оборудование?",
        answers: [
          "Представителя Ростехнадзора",
          "Руководителя независимого аттестационного центра",
          "Производителя сварочного оборудования",
          "Руководителя организации",
        ],
        correctAnswers: ["Производителя сварочного оборудования"],
      },
      {
        code: "69624",
        text: "Кем осуществляется проверка готовности к применению аттестованных технологий сварки с целью определения наличия у организации или индивидуального предпринимателя технических, организационных и квалификационных возможностей для выполнения сварочных (наплавочных) работ по применяемым им аттестованным технологиям, а также соответствия качества выполненных при аттестации контрольных сварных соединений (наплавок) требованиям НД и (или) проектной (конструкторской) документации на сварные конструкции?",
        answers: [
          "Эксплуатирующей организацией",
          "Независимыми аттестационными центрами",
          "Ростехнадзором",
          "Специализированной организацией",
        ],
        correctAnswers: ["Независимыми аттестационными центрами"],
      },
      {
        code: "69625",
        text: "Какие виды контроля должны осуществляться при подготовке и выполнении сварочных работ?",
        answers: [
          "Входной",
          "Операционный",
          "Приемочный",
          "Все перечисленные виды",
        ],
        correctAnswers: ["Все перечисленные виды"],
      },
      {
        code: "69626",
        text: "Что из перечисленного должно контролироваться в процессе сварки, как минимум?",
        answers: [
          "Соответствия параметров режима сварки и технологических приемов выполнения сварного соединения",
          "Очередности выполнения сварных швов и участков наплавки",
          "Отсутствия видимых дефектов",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "69627",
        text: "Кто обеспечивает организацию и выполнение аттестационных процедур согласно требованиям к производству сварочных работ на опасных производственных объектах?",
        answers: [
          "Технический руководитель",
          "Руководитель эксплуатирующей организации",
          "Руководитель независимого аттестационного центра или уполномоченное им должностное лицо",
          "Представитель Ростехнадзора",
        ],
        correctAnswers: [
          "Руководитель независимого аттестационного центра или уполномоченное им должностное лицо",
        ],
      },
      {
        code: "69628",
        text: "Что из перечисленного должно быть в требованиях по сборке деталей под сварку, содержащихся в производственно-технологической документации по сварке (далее – ПТД)?",
        answers: [
          "Способы подготовки поверхностей деталей под сварку",
          "Способы сварки, сварочные материалы и режимы сварки при выполнении прихваток и приварке временных технологических креплений",
          "Методы контроля качества сборки",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "69629",
        text: "Какие из перечисленных видов контроля не осуществляются при подготовке и выполнении сварочных работ?",
        answers: ["Входной", "Операционный", "Приемочный", "Эксплуатационный"],
        correctAnswers: ["Эксплуатационный"],
      },
      {
        code: "69630",
        text: "Что из перечисленного должно обеспечить лицо, осуществляющее руководство сварочными работами при производстве сварочных работ?",
        answers: [
          "Выполнение сварных соединений в соответствии с технологическими (операционными) картами сварки",
          "Регистрацию сведений о сварщиках, выполняющих сварные соединения",
          "Регистрацию результатов качества сварных соединений, включая результаты контроля исправлений дефектов сварных соединений",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
    ],
    714: [
      {
        code: "69705",
        text: "На какие из нижеперечисленных ОПО не распространяются требования Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения (далее - ФНП ПС)?",
        answers: [
          "На ОПО, где эксплуатируются грузоподъемные краны",
          "На ОПО, где эксплуатируются строительные подъемники",
          "На ОПО, где эксплуатируются канатные дороги",
          "На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления",
          "На ОПО, где эксплуатируются подъемники (вышки)",
        ],
        correctAnswers: ["На ОПО, где эксплуатируются канатные дороги"],
      },
      {
        code: "69706",
        text: "Какие из нижеперечисленных ПС не подлежат учету в органах Ростехнадзора?",
        answers: [
          "Автомобильные краны",
          "Краны мостового типа",
          "Краны на железнодорожном ходу",
          "Краны-трубоукладчики",
        ],
        correctAnswers: ["Краны-трубоукладчики"],
      },
      {
        code: "69707",
        text: "Какие из нижеперечисленных ПС подлежат учету в органах Ростехнадзора?",
        answers: [
          "Краны стрелового типа грузоподъемностью до 1 т включительно",
          "Переставные краны для монтажа мачт, башен, труб, устанавливаемые на монтируемом сооружении",
          "Краны стрелового типа с постоянным вылетом или краны, не снабженные механизмом поворота",
          "Подъемники и вышки, предназначенные для перемещения людей",
        ],
        correctAnswers: [
          "Подъемники и вышки, предназначенные для перемещения людей",
        ],
      },
      {
        code: "69708",
        text: "На какие из перечисленных ОПО распространяются требования ФНП ПС?",
        answers: [
          "На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления",
          "На ОПО, где эксплуатируются подъемные сооружения, установленные в шахтах",
          "На ОПО, где эксплуатируются подъемные сооружения, установленные на любых плавучих средствах",
          "На ОПО, где эксплуатируются манипуляторы, используемые в технологических процессах",
          "На ОПО, где эксплуатируются подъемники (вышки), предназначенные для перемещения людей, людей и груза (подъемники с рабочими платформами) с высотой подъема до 6 м включительно",
        ],
        correctAnswers: [
          "На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления",
        ],
      },
      {
        code: "69709",
        text: "Какой документ подтверждает соответствие ПС требованиям технических регламентов?",
        answers: [
          "Паспорт ПС",
          "Протокол испытаний, проведенных изготовителем",
          "Сертификат или декларация соответствия",
          "Акт технического освидетельствования",
        ],
        correctAnswers: ["Сертификат или декларация соответствия"],
      },
      {
        code: "69710",
        text: 'Каким нормативным правовым актом регламентируются обязательные для применения требования для ПС, введенных в обращение до вступления в силу Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"?',
        answers: [
          "Ранее действующими правилами устройства и безопасной эксплуатации ПС Госгортехнадзора России для проектирования и изготовления этих ПС, а для остальных стадий жизненного цикла ПС – Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения",
          "Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения, для всех стадий жизненного цикла этих ПС",
          "Такие ПС должны быть приведены в соответствие с требованиями Технического регламента ТР ТС 010/2011 «О безопасности машин и оборудования»",
          "Ранее действующими правилами устройства и безопасной эксплуатации ПС Госгортехнадзора России для всех стадий жизненного цикла этих ПС",
        ],
        correctAnswers: [
          "Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения, для всех стадий жизненного цикла этих ПС",
        ],
      },
      {
        code: "69711",
        text: "Требованиям какого документа должны соответствовать общие требования к транспортировке и хранению ПС, их отдельных сборочных единиц, материалов и комплектующих для их ремонта, реконструкции и (или) модернизации?",
        answers: [
          "Формуляра ПС",
          "Технических условий ПС",
          "Паспорта ПС",
          "Руководства (инструкции) по эксплуатации ПС",
        ],
        correctAnswers: ["Руководства (инструкции) по эксплуатации ПС"],
      },
      {
        code: "69712",
        text: "Требованиям какого документа должны соответствовать общие требования к утилизации (ликвидации) ПС? Укажите все правильные ответы.",
        answers: [
          "Руководства (инструкции) по эксплуатации ПС",
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
          'Федерального закона "О промышленной безопасности опасных производственных объектов" от 21 июля 1997 г. N 116-ФЗ',
          'Технического регламента ТР ТС 018/2011 "О безопасности колесных транспортных средств"',
        ],
        correctAnswers: [
          "Руководства (инструкции) по эксплуатации ПС",
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
        ],
      },
      {
        code: "69713",
        text: "Кто из работников специализированной организации должен быть аттестован в установленном порядке на знание требований настоящих ФНП ПС, касающихся заявленных видов работ на ПС?",
        answers: [
          "Все работники",
          "Инженерно-технические работники",
          "Служащие",
          "Специалисты",
        ],
        correctAnswers: ["Инженерно-технические работники"],
      },
      {
        code: "69714",
        text: "Требованиям какого документа должно соответствовать выполнение погрузочно-разгрузочных работ на монтаже с применением подъемных сооружений (далее – ПС)?",
        answers: [
          "Руководства (инструкции) по монтажу ПС",
          "Технических условий",
          "Паспорта ПС",
          "Руководства (инструкции) по эксплуатации ПС",
        ],
        correctAnswers: ["Руководства (инструкции) по монтажу ПС"],
      },
      {
        code: "69715",
        text: "Какие требования к сборке и соединению сборочных единиц ПС указаны неверно?",
        answers: [
          "Сборка и монтаж металлоконструкций самомонтируемых козловых и башенных кранов должна выполняться на участке подготовленного наземного рельсового пути согласно указаниям специалиста, ответственного за безопасное производство работ с применением ПС",
          "Крупногабаритные сборочные единицы ПС должны укладываться на место последующего монтажа с применением грузоподъемных механизмов, при этом положение стыкуемых элементов по высоте должно регулироваться в соответствии с требованиями руководства (инструкции) по эксплуатации ПС",
          "Фактическая несоосность (непараллельность) стыкуемых сборочных единиц не должна превышать величин соответствующих допусков, приведенных в руководстве (инструкции) по эксплуатации ПС",
          "Сборка и соединение отдельных сборочных единиц ПС должна выполняться согласно требованиям руководства (инструкции) по эксплуатации и другой эксплуатационной документации ПС",
          "Все перечисленные требования",
        ],
        correctAnswers: [
          "Сборка и монтаж металлоконструкций самомонтируемых козловых и башенных кранов должна выполняться на участке подготовленного наземного рельсового пути согласно указаниям специалиста, ответственного за безопасное производство работ с применением ПС",
        ],
      },
      {
        code: "69716",
        text: "С какой периодичностью должно осуществляться проведение плановых ремонтов ПС? Укажите все правильные ответы.",
        answers: [
          "После наработки определенного числа машино-часов (циклов) согласно руководству по эксплуатации ПС",
          "Через установленный инструкцией по эксплуатации ПС интервал времени",
          "2 раза в год при подготовке крана к использованию в летний или зимний периоды",
          "Не реже чем 1 раз в 5 лет",
          "После наработки 1200 машино-часов (циклов)",
        ],
        correctAnswers: [
          "После наработки определенного числа машино-часов (циклов) согласно руководству по эксплуатации ПС",
          "Через установленный инструкцией по эксплуатации ПС интервал времени",
        ],
      },
      {
        code: "69717",
        text: "Чем запрещается оснащать краны, в зоне работы которых находятся производственные или другие помещения?",
        answers: [
          "Грузовым электромагнитом",
          "Концевым выключателем электромеханического типа",
          "Регистратором параметров",
          "Ограничителем грузоподъемности",
        ],
        correctAnswers: ["Грузовым электромагнитом"],
      },
      {
        code: "69718",
        text: "Кто имеет право вносить изменения в ППР и ТК в процессе монтажа?",
        answers: [
          "Организация, отвечающая за выполнение монтажа",
          "Специалист, ответственный за содержание ПС в работоспособном состоянии",
          "Изготовитель монтируемого ПС",
          "Специалист организации, которая будет эксплуатировать ПС по окончании его монтажа",
          "Специалист, ответственный за безопасное производство работ с применением ПС",
        ],
        correctAnswers: ["Организация, отвечающая за выполнение монтажа"],
      },
      {
        code: "69719",
        text: "Допускается ли при выполнении строительно-монтажных или погрузочно-разгрузочных работ перемещение грузов с применением ПС над перекрытиями, под которыми размещены производственные, жилые или служебные помещения, где могут находиться люди?",
        answers: [
          "Не допускается",
          "Допускается в исключительных случаях в присутствии и под руководством специалиста, ответственного за безопасное производство работ с применением ПС",
          "Допускается в исключительных случаях в присутствии и под руководством специалиста, ответственного за исправное состояние ПС",
          "Допускается при условии, что места производства работ будут ограждены и обозначены предупредительными знаками",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "69720",
        text: "Кто является председателем комиссии, на основании предложений которой принимается решение о пуске в работу после установки на объекте кранов мостового типа и портальных кранов?",
        answers: [
          "Уполномоченный представитель эксплуатирующей организации",
          "Уполномоченный представитель Ростехнадзора",
          "Уполномоченный представитель специализированной организации",
          "Специалист, ответственный за осуществление производственного контроля при эксплуатации ПС",
        ],
        correctAnswers: [
          "Уполномоченный представитель эксплуатирующей организации",
        ],
      },
      {
        code: "69721",
        text: "На какой высоте над уровнем нижней посадочной площадки (земли) должен находиться груз на неподвижном грузонесущем устройстве при статических испытаниях строительного подъемника?",
        answers: [
          "Не более 150 мм",
          "Не более 300 мм",
          "Не более 180 мм",
          "Не более 200 мм",
        ],
        correctAnswers: ["Не более 150 мм"],
      },
      {
        code: "69722",
        text: "Чем определяется конкретный перечень требований к специализированной организации, которая будет заниматься деятельностью по монтажу, наладке, ремонту, реконструкции или модернизации ПС в процессе эксплуатации ОПО?",
        answers: [
          "Номенклатурой ПС и технологией сварки, заявленными организацией для своей последующей деятельности",
          "Наличием необходимого оборудования",
          "Видами, типами, моделями ПС и технологическими процессами",
          "Номенклатурой ПС и квалификацией работников",
          "Наличием аттестованных специалистов",
        ],
        correctAnswers: [
          "Видами, типами, моделями ПС и технологическими процессами",
        ],
      },
      {
        code: "69723",
        text: "Кто может заниматься деятельностью по монтажу, наладке, ремонту, реконструкции или модернизации ПС в процессе эксплуатации ОПО?",
        answers: [
          "Физические лица и индивидуальные предприниматели",
          "Специализированные организации",
          "Специализированные организации и физические лица",
          "Юридические и физические лица",
          "Индивидуальные предприниматели, физические лица, юридические лица",
        ],
        correctAnswers: ["Специализированные организации"],
      },
      {
        code: "69724",
        text: "Что должно проводиться после реконструкции ПС?",
        answers: [
          "Внеочередное частичное техническое освидетельствование",
          "Внеочередное полное техническое освидетельствование",
          "Периодическое техническое освидетельствование",
          "Периодическое частичное техническое освидетельствование",
        ],
        correctAnswers: ["Внеочередное полное техническое освидетельствование"],
      },
      {
        code: "69725",
        text: "В какой документ вносится запись о результатах осмотра съемных грузозахватных приспособлений и тары?",
        answers: [
          "Журнал осмотра грузозахватных приспособлений",
          "Во все перечисленные документы",
          "Паспорт ПС",
          "Паспорт каждого грузозахватного приспособления",
          "Руководство по эксплуатации грузозахватных приспособлений и тары",
        ],
        correctAnswers: ["Журнал осмотра грузозахватных приспособлений"],
      },
      {
        code: "69726",
        text: "При каких величинах суммарной массы тары с перемещаемым грузом допускается применение башенных кранов с тарой, разгружаемой на весу, в пределах группы классификации (режима), указанного в паспорте крана, при числе циклов работы крана не более 8 в час?",
        answers: [
          "Для одноканатных грейферов, не допускающих разгрузку на весу, - при суммарной массе не более 50% грузоподъемности крана",
          "Для тары с вибратором - при суммарной массе не более 50% от максимальной грузоподъемности крана",
          "Для тары без вибраторов (исключая грейферы) - при суммарной массе в пределах грузоподъемности крана",
          "Применение допускается во всех перечисленных случаях",
        ],
        correctAnswers: [
          "Применение допускается во всех перечисленных случаях",
        ],
      },
      {
        code: "69727",
        text: "Каким образом должны быть расположены ветви многоветвевых стропов при испытаниях?",
        answers: [
          "Под углом 90° по вертикали друг к другу",
          "Под углом 45° по горизонтали друг к другу",
          "Под углом 90° по горизонтали друг к другу",
          "Под углом 45° по вертикали друг к другу",
        ],
        correctAnswers: ["Под углом 90° по вертикали друг к другу"],
      },
      {
        code: "69728",
        text: "Какое допускается максимальное отклонение по массе изделий, являющихся составной частью испытательного груза, при испытании специальных грузозахватных приспособлений?",
        answers: ["3%", "5%", "7%", "1%"],
        correctAnswers: ["3%"],
      },
      {
        code: "69729",
        text: "Какие из перечисленных ниже нарушений не могут служить причиной остановки эксплуатации подъемника?",
        answers: [
          "Обслуживание подъемника ведется неаттестованным персоналом",
          "Отсутствует экспертиза промышленной безопасности нового подъемника, введенного в эксплуатацию",
          "Не выполнены предписания по обеспечению безопасной эксплуатации подъемника, выданные эксплуатирующей организации",
          "Истек срок технического освидетельствования подъемника",
        ],
        correctAnswers: [
          "Отсутствует экспертиза промышленной безопасности нового подъемника, введенного в эксплуатацию",
        ],
      },
      {
        code: "69730",
        text: "В каких из перечисленных случаев эксплуатирующая организация имеет право допустить ПС в работу?",
        answers: [
          "Для редко используемого ПС прошло более трех лет с момента проведения предыдущего технического освидетельствования",
          "На ПС выявлены трещины или остаточные деформации металлоконструкций",
          "Обслуживание ПС ведется неаттестованным персоналом",
          "На ПС выявлен недопустимый износ крюков, ходовых колес, канатов, цепей, элементов механизмов и тормозов",
          "Отсутствуют соответствующие массе и виду перемещаемых грузов съемные грузозахватные приспособления и тара",
        ],
        correctAnswers: [
          "Для редко используемого ПС прошло более трех лет с момента проведения предыдущего технического освидетельствования",
        ],
      },
      {
        code: "69731",
        text: "С учетом требований какого документа должна выполняться утилизация (ликвидация) ПС? Укажите все правильные ответы.",
        answers: [
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
          "Руководства (инструкции) по эксплуатации ПС",
          "Проектной документации",
          "Всех перечисленных документов",
        ],
        correctAnswers: [
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
          "Руководства (инструкции) по эксплуатации ПС",
        ],
      },
      {
        code: "69732",
        text: "Требованиям какого документа должны соответствовать общие требования к утилизации (ликвидации) ПС? Укажите все правильные ответы.",
        answers: [
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
          "Руководства (инструкции) по эксплуатации ПС",
          'Технического регламента ТР ТС 018/2011 "О безопасности колесных транспортных средств"',
          "Формуляра ПС",
        ],
        correctAnswers: [
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
          "Руководства (инструкции) по эксплуатации ПС",
        ],
      },
      {
        code: "69733",
        text: "Какие требования к статическим испытаниям подъемников (вышек) (кроме строительных) указаны неверно?",
        answers: [
          "Статические испытания подъемников (вышек) (кроме строительных) должны проводиться при установке подъемника (вышки) на горизонтальной площадке в положении, отвечающем наименьшей расчетной его устойчивости",
          "На подъемниках (вышках), оборудованных люлькой, груз массой, равной 110 % от номинальной грузоподъемности, следует располагать в люльке, а второй груз массой, равной 40 % от номинальной грузоподъемности, следует подвешивать к люльке на гибкой подвеске",
          "После начала подъема и отрыва второго груза от земли на высоту 50 - 100 мм, следует делать остановку с последующей выдержкой суммарного груза в течение 10 минут",
          "Все перечисленные требования указаны верно",
        ],
        correctAnswers: ["Все перечисленные требования указаны верно"],
      },
      {
        code: "69734",
        text: "С какой перегрузкой проводятся испытания на строительных подъемниках при проведении полного технического освидетельствования и проверки работоспособности ловителей (аварийных остановов)?",
        answers: ["15%", "10%", "5%", "25%"],
        correctAnswers: ["10%"],
      },
      {
        code: "69735",
        text: "С какой нагрузкой по отношению к номинальной паспортной грузоподъемности должна проводиться проверка качества выполненного ремонта грузозахватных приспособлений с проведением статических испытаний?",
        answers: ["125%", "105%", "120%", "150%"],
        correctAnswers: ["125%"],
      },
      {
        code: "69736",
        text: "Какие требования к статическим испытаниям грузозахватного приспособления указаны верно?",
        answers: [
          "Испытательный груз, зацепленный (охваченный, обвязанный) испытываемым грузозахватным приспособлением или подвешенный к нему, с возможно меньшими ускорениями следует поднимать ПС на высоту 150 - 250 мм",
          "Испытательный груз, поднятый на высоту 200 мм, следует выдерживать в таком положении не менее 5 минут",
          "Испытания следует проводить статической нагрузкой, превышающей его грузоподъемность на 50%",
          "По истечении 5 минут испытательный груз следует оставить в подвешенном состоянии на высоте не более 10 мм",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "69737",
        text: "Какие требования, установленные для специализированной организации, осуществляющей деятельность по монтажу (демонтажу), наладке, ремонту, реконструкции или модернизации ПС в процессе эксплуатации ОПО, указаны верно?",
        answers: [
          "Только требование располагать необходимым персоналом, а также руководителями и специалистами, имеющими полномочия, необходимые для выполнения своих обязанностей, в том числе выявления случаев отступлений от требований к качеству работ, от процедур проведения работ, и для принятия мер по предупреждению или сокращению таких отступлений",
          "Только требование устанавливать ответственность, полномочия и взаимоотношения работников, занятых в управлении, выполнении или проверке выполнения работ",
          "Только требование определять процедуры контроля соблюдения технологических процессов",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "69738",
        text: "Какие требования к средствам измерений, используемым в процессе испытаний ПС, указаны верно?",
        answers: [
          "Средства измерений должны быть поверены или калиброваны",
          "Все перечисленные требования",
          "Средства измерений должны быть аттестованы в установленном порядке",
        ],
        correctAnswers: [
          "Средства измерений должны быть поверены или калиброваны",
        ],
      },
      {
        code: "69739",
        text: "Какие документы должны быть разработаны для выполнения работ по монтажу, демонтажу, ремонту оборудования с применением ПС? Укажите все правильные ответы.",
        answers: [
          "Проект производства работ (ППР)",
          "Технологическая карта (ТК)",
          "Технологические инструкции",
          "Технические условия (ТУ)",
        ],
        correctAnswers: [
          "Проект производства работ (ППР)",
          "Технологическая карта (ТК)",
        ],
      },
      {
        code: "69740",
        text: "Какая организация имеет право вносить изменения в разработанный проект производства работ (далее - ППР) ПС для выполнения строительно-монтажных работ?",
        answers: [
          "Только организация - разработчик ППР",
          "Только специализированная организация",
          "Только эксплуатирующая организация",
          "Только проектная организация",
          "Только специализированная экспертная организация",
        ],
        correctAnswers: ["Только организация - разработчик ППР"],
      },
      {
        code: "69741",
        text: "Каким лицом должны быть внесены изменения после реконструкции ПС, связанной с переводом ПС на дистанционное управление (радиоуправление), в паспорт и руководство (инструкцию) по эксплуатации ПС?",
        answers: [
          "Лицом, выполнившим указанные работы",
          "Лицом, которое разработало изменения по переводу ПС на дистанционное управление",
          "Лицом, ответственным за исправное состояние ПС",
          "Лицом, управляющим данным ПС",
        ],
        correctAnswers: ["Лицом, выполнившим указанные работы"],
      },
      {
        code: "69742",
        text: "В соответствии с требованиями каких документов должна проводиться установка ПС в зданиях, на открытых площадках и других участках производства работ? Выберите 2 варианта ответа.",
        answers: [
          "Руководства (инструкции) по эксплуатации",
          "Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения",
          "Проекта",
          "Должностной инструкции",
        ],
        correctAnswers: [
          "Руководства (инструкции) по эксплуатации",
          "Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения",
        ],
      },
      {
        code: "69743",
        text: "В течение какого времени груз должен находиться на неподвижном грузонесущем устройстве без смещения грузонесущего устройства и без обнаружения трещин, остаточных деформаций и других повреждений металлоконструкций и механизмов, чтобы строительный подъемник считался выдержавшим статические испытания?",
        answers: [
          "В течение 10 минут",
          "В течение 5 минут",
          "В течение 7 минут",
          "В течение 15 минут",
        ],
        correctAnswers: ["В течение 10 минут"],
      },
      {
        code: "69744",
        text: "В каком случае допускается эксплуатация текстильных стропов на полимерной основе?",
        answers: [
          "Если присутствует размочаливание или износ 5 процентов ширины петель стропа",
          "Если имеются продольные порезы или разрывы ленты, суммарная длина которых превышает 10 процентов длины ленты ветви стропа, а также единичные порезы или разрывы длиной 50 мм",
          "Если имеются сквозные отверстия диаметром 20 процентов ширины ленты от воздействия острых предметов",
          "Если имеются местные расслоения лент стропа (кроме мест заделки краев лент) на суммарной длине 0,5 м на одном крайнем шве или на двух и более внутренних швах, сопровождаемые разрывом трех и более строчек шва",
        ],
        correctAnswers: [
          "Если присутствует размочаливание или износ 5 процентов ширины петель стропа",
        ],
      },
      {
        code: "69745",
        text: "В каком случае проводятся испытания на устойчивость при повторных технических освидетельствованиях для всех кранов стрелового типа и подъемников (вышек), у которых люлька закреплена на оголовке стрелы?",
        answers: [
          "Если в паспорте отсутствуют ссылки на результаты таких ранее проведенных испытаний",
          "Не проводятся, если иное не указано в руководстве (инструкции) по эксплуатации ПС",
          "Если конструкция крана (стрела, гусек, аутригеры или опорно-поворотное устройство) была подвергнута ремонту расчетных элементов металлоконструкций с заменой элементов",
          "Если люлька была подвергнута ремонту с применением сварки либо изменению первоначальных геометрических размеров",
        ],
        correctAnswers: [
          "Не проводятся, если иное не указано в руководстве (инструкции) по эксплуатации ПС",
        ],
      },
      {
        code: "69746",
        text: "Какое количество тупиковых упоров, ограничивающих рабочую зону, обслуживаемую ПС, должно быть установлено на каждой рельсовой нити рельсового пути?",
        answers: [
          "2 тупиковых упора",
          "1 тупиковый упор",
          "3 тупиковых упора",
          "Не регламентируется",
        ],
        correctAnswers: ["2 тупиковых упора"],
      },
      {
        code: "69747",
        text: "Согласно требованиям какого документа должна выполняться проверка работоспособности регистратора параметров работы ПС?",
        answers: [
          "Руководства (инструкции) по эксплуатации",
          "Паспорта ПС",
          "Журнала осмотра",
        ],
        correctAnswers: ["Руководства (инструкции) по эксплуатации"],
      },
      {
        code: "69748",
        text: "Каким документом определено распределение ответственности работников специализированной организации?",
        answers: [
          "Положением о промышленной безопасности организации",
          "Положением о контроле соблюдения технологических процессов",
          "Положением о действиях в аварийных ситуациях",
          "Положением об отделе технического контроля организации",
          "Положением о требованиях к работникам организации",
        ],
        correctAnswers: [
          "Положением о контроле соблюдения технологических процессов",
        ],
      },
      {
        code: "69749",
        text: "В каком документе должно быть определено распределение ответственности работников организации, осуществляющей монтаж, наладку, ремонт, реконструкцию или модернизацию ПС в процессе эксплуатации ОПО?",
        answers: [
          "В положении о контроле соблюдения технологических процессов специализированной организации",
          "В положении о производственном контроле",
          "В требованиях руководства (инструкции) по эксплуатации ПС",
          "В проектной документации",
        ],
        correctAnswers: [
          "В положении о контроле соблюдения технологических процессов специализированной организации",
        ],
      },
      {
        code: "69750",
        text: "Каким из нижеприведенных требований должны отвечать работники, непосредственно выполняющие работы по монтажу?",
        answers: [
          "Знать основные признаки отправки ПС на утилизацию (ликвидацию)",
          "Знать и уметь оценивать остаточный ресурс ПС",
          "Быть аттестованными по экспертизе промышленной безопасности",
          "Быть аттестованными на право управления монтируемого ПС",
          "Знать и уметь применять такелажные и монтажные приспособления",
        ],
        correctAnswers: [
          "Знать и уметь применять такелажные и монтажные приспособления",
        ],
      },
      {
        code: "69751",
        text: "Кого относят к работникам специализированных организаций, занимающихся выполнением работ по монтажу (демонтажу), наладке либо ремонту, реконструкции или модернизации в процессе эксплуатации ПС?",
        answers: [
          "Всех сотрудников организации, включая лиц рабочих профессий",
          "Аттестованных специалистов и лиц рабочих профессий",
          "Инженерно-технических работников, имеющих высшее или среднее профессиональное образование, и персонал - лиц рабочих профессий, непосредственно занятых на выполнении работ",
          "Руководителей, специалистов и персонал - лиц рабочих профессий основных служб организации",
          "Физических лиц основных служб организации, непосредственно занятых на выполнении работ",
        ],
        correctAnswers: [
          "Инженерно-технических работников, имеющих высшее или среднее профессиональное образование, и персонал - лиц рабочих профессий, непосредственно занятых на выполнении работ",
        ],
      },
      {
        code: "69752",
        text: "Каким критериям должен соответствовать выбор оборудования для безопасного выполнения работ по монтажу (демонтажу) ПС?",
        answers: [
          "Только конкретному монтируемому ПС",
          "Только составу работ, предусмотренному руководством (инструкцией) по эксплуатации ПС",
          "Только эксплуатационным документам ПС",
          "Всем перечисленным критериям",
        ],
        correctAnswers: ["Всем перечисленным критериям"],
      },
      {
        code: "69753",
        text: "Какие действия до начала работы необходимо выполнить с такелажной оснасткой и вспомогательными механизмами, используемыми при выполнении монтажа ПС?",
        answers: [
          "Проверить их соответствие эксплуатационным документам",
          "Провести статические и динамические испытания",
          "Убедиться в наличии разрешения на применение",
        ],
        correctAnswers: [
          "Проверить их соответствие эксплуатационным документам",
        ],
      },
      {
        code: "69754",
        text: "С чем в обязательном порядке должны быть ознакомлены работники, выполняющие работы по монтажу (демонтажу) и наладке ПС?",
        answers: [
          "С руководством (инструкцией) по монтажу",
          "С производственными инструкциями",
          "С должностными инструкциями",
          "С рабочими процедурами (характеристикой работ)",
          "Со всем перечисленным",
        ],
        correctAnswers: ["Со всем перечисленным"],
      },
      {
        code: "69755",
        text: "Какие из указанных действий допускается предпринимать во время проведения монтажных работ ПС, если на монтажной площадке имеются действующие переходы (проезды) и выходы из прилегающих зданий? Укажите все правильные ответы.",
        answers: [
          "Закрыть переходы (проезды) и выходы из прилегающих зданий",
          "Оборудовать переходы (проезды) и выходы из прилегающих зданий средствами, обеспечивающими безопасность (козырьками, галереями)",
          "Перемещать груз при нахождении под ним людей",
          "Установить дополнительные блокировки на подъемные сооружения, исключающие его перемещение при нахождении на действующих переходах людей",
        ],
        correctAnswers: [
          "Закрыть переходы (проезды) и выходы из прилегающих зданий",
          "Оборудовать переходы (проезды) и выходы из прилегающих зданий средствами, обеспечивающими безопасность (козырьками, галереями)",
        ],
      },
      {
        code: "69756",
        text: "Каким документом должно быть подтверждено соответствие проекту рельсового пути (для ПС на рельсовом ходу) по результатам выполнения монтажа?",
        answers: [
          "Актом сдачи-приемки монтажного участка пути или актом сдачи-приемки рельсового пути под монтаж, если к проведению монтажа путь монтировался на всю рабочую длину",
          "Актом освидетельствования скрытых работ",
          "Актом освидетельствования работ рельсового пути и соответствия требованиям руководства (инструкции) по эксплуатации ПС",
        ],
        correctAnswers: [
          "Актом сдачи-приемки монтажного участка пути или актом сдачи-приемки рельсового пути под монтаж, если к проведению монтажа путь монтировался на всю рабочую длину",
        ],
      },
      {
        code: "69757",
        text: "Кто может осуществлять монтаж и наладку регистраторов, ограничителей и указателей?",
        answers: [
          "Разработчик приборов",
          "Изготовитель приборов",
          "Изготовитель ПС",
          "Специализированная организация",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "69758",
        text: "Куда вносятся отметки о монтаже и наладке ограничителя, указателя и регистратора ПС? Укажите все правильные ответы.",
        answers: [
          "В паспорт ПС",
          "В паспорт ограничителя, указателя или регистратора",
          "В заключение экспертизы промышленной безопасности",
          "В паспорт безопасности ОПО, на котором эксплуатируется ПС",
        ],
        correctAnswers: [
          "В паспорт ПС",
          "В паспорт ограничителя, указателя или регистратора",
        ],
      },
      {
        code: "69759",
        text: "Кто разрабатывает инструкцию, согласно которой осуществляется обслуживание систем дистанционного управления (радиоуправления) при эксплуатации ПС?",
        answers: [
          "Изготовитель",
          "Монтажная (специализированная) организация",
          "Экспертная организация",
          "Эксплуатирующая организация",
        ],
        correctAnswers: ["Изготовитель"],
      },
      {
        code: "69760",
        text: "Кем в специализированной организации должен осуществляться контроль соблюдения специализированной организацией требований проекта, ремонтных чертежей и технологии производства ремонтных работ?",
        answers: [
          "Службой отдела технического контроля",
          "Эксплуатирующей организацией",
          "Службой производственного контроля",
          "Производственно-техническим отделом",
        ],
        correctAnswers: ["Службой отдела технического контроля"],
      },
      {
        code: "69761",
        text: "Какие краны, не оборудованные координатной защитой, запрещается применять для работы в стесненных условиях?",
        answers: [
          "Только башенные краны, установленные на специальные шасси автомобильного типа, на автомобильные шасси",
          "Только грузоподъемные краны, установленные на автомобильные шасси",
          "Только грузоподъемные краны на пневмоколесном ходу и гусеничном ходу",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "69762",
        text: "Что служит основанием для решения о пуске в работу после установки на объекте кранов мостового типа и портальных кранов?",
        answers: [
          "Предложение комиссии о возможности пуска",
          "Заключение завода-изготовителя о возможности пуска",
          "Положительное заключение экспертизы промышленной безопасности",
          "Предписание территориального органа Ростехнадзора",
        ],
        correctAnswers: ["Предложение комиссии о возможности пуска"],
      },
      {
        code: "69763",
        text: "За сколько дней до начала работы комиссии по пуску ПС в работу эксплуатирующая организация должна письменно уведомить организации, представители которых включены в состав комиссии, о дате работы комиссии?",
        answers: [
          "Не менее чем за 10 дней",
          "Не менее чем за 3 дня",
          "Не менее чем за 7 дней",
          "Не менее чем за 5 дней",
        ],
        correctAnswers: ["Не менее чем за 10 дней"],
      },
      {
        code: "69764",
        text: "Какие мероприятия должна выполнять эксплуатирующая организация для содержания ПС в работоспособном состоянии и обеспечения безопасных условий их работы?",
        answers: [
          "Только устанавливать порядок периодических осмотров, технического обслуживания и ремонта ПС",
          "Только устанавливать порядок проверки знаний персонала и его допуска к самостоятельной работе",
          "Только обеспечивать наличие у инженерно-технических работников должностных инструкций и руководящих указаний по безопасной эксплуатации ПС",
          "Только обеспечивать наличие у персонала производственных инструкций",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "69765",
        text: "Какие работы относятся к работам в местах действия опасных факторов?",
        answers: [
          "Монтаж конструкций, имеющих большую парусность и габариты (витражей, ферм, перегородок, стеновых панелей)",
          "Монтаж в зоне примыкания к эксплуатируемым зданиям (сооружениям)",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "69766",
        text: "В каких случаях не проводятся динамические испытания ПС?",
        answers: [
          "Если ПС используется только для подъема и опускания груза",
          "Если паспортная грузоподъемность ПС не превышает 10 т",
          "Если ПС оборудовано не более чем двумя механизмами подъема и если предусмотрена их раздельная работа",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: [
          "Если ПС используется только для подъема и опускания груза",
        ],
      },
      {
        code: "69767",
        text: "Что не указывается на табличках, которыми должны быть снабжены находящиеся в эксплуатации ПС?",
        answers: [
          "Учетный номер ПС",
          "Заводской номер ПС",
          "Дата следующего полного технического освидетельствования",
          "Паспортная грузоподъемность",
          "Дата следующего частичного технического освидетельствования",
          "Указывается все перечисленное",
        ],
        correctAnswers: ["Указывается все перечисленное"],
      },
      {
        code: "69768",
        text: "Как должна распределяться нагрузка на каждое из ПС, если подъем и перемещение груза осуществляют двумя ПС?",
        answers: [
          "Нагрузка, приходящаяся на каждое из ПС, не должна превышать 0,75 % грузоподъемности ПС, имеющего меньшую грузоподъемность",
          "Нагрузка, приходящаяся на каждое ПС, не должна превышать грузоподъемность ПС",
          "Нагрузка, приходящаяся на каждое из ПС, должна быть по возможности одинаковой",
          "Нагрузка, приходящаяся на каждое из ПС, должна контролироваться взвешивающими устройствами ПС",
          "Нагрузка, приходящаяся на каждое из ПС, должна быть выровнена несимметричной строповкой груза и быть по возможности одинаковой",
        ],
        correctAnswers: [
          "Нагрузка, приходящаяся на каждое ПС, не должна превышать грузоподъемность ПС",
        ],
      },
      {
        code: "69769",
        text: "Какие требования к передвижению стрелового самоходного крана указаны неверно?",
        answers: [
          "Движение крана с места при раскачивающемся грузе разрешается при отклонении последнего на угол не более указанного в руководстве (инструкции) по эксплуатации крана",
          "Стреловым самоходным кранам разрешается перемещаться с грузом на крюке",
          "Основание, по которому перемещается кран с грузом, должно иметь твердое покрытие, способное выдержать без просадки удельное давление не менее величин, указанных в паспорте или руководстве (инструкции) по эксплуатации крана",
          "Основание, по которому перемещается кран с грузом, должно быть ровным и иметь уклон не более указанного в руководстве (инструкции) по эксплуатации крана",
        ],
        correctAnswers: [
          "Движение крана с места при раскачивающемся грузе разрешается при отклонении последнего на угол не более указанного в руководстве (инструкции) по эксплуатации крана",
        ],
      },
      {
        code: "69770",
        text: "Какие требования к статическим испытаниям кранов указаны верно?",
        answers: [
          "Испытания кранов стрелового типа, не имеющих механизма изменения вылета (стрела поддерживается растяжкой), проводятся при установленных для испытаний вылетах",
          "Испытания кранов, имеющих сменное стреловое оборудование, проводятся с установленным стреловым оборудованием, а также после замены стрелового оборудования",
          "Статические испытания крана стрелового типа, имеющего одну или несколько грузовых характеристик, при периодическом или внеочередном техническом освидетельствовании проводятся в положении, соответствующем наибольшей грузоподъемности крана и/или наибольшему грузовому моменту, если это не противоречит требованиям, изложенным в руководстве (инструкции) по эксплуатации ПС",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "69771",
        text: "Какие требования к статическим испытаниям крана-трубоукладчика или крана-манипулятора указаны неверно?",
        answers: [
          "Все перечисленные требования указаны верно",
          "Кран-трубоукладчик и кран-манипулятор считаются выдержавшими испытания, если в течение 10 минут поднятый груз не опустился, а также не обнаружено трещин, остаточных деформаций и других повреждений",
          "После установки на кран-трубоукладчик (кран-манипулятор) сменного стрелового оборудования испытания проводятся в положении, соответствующем наибольшей грузоподъемности, при установленном оборудовании",
          "Крюком поднимают груз на высоту 100 - 150 мм от земли и выдерживают в течение не менее 5 минут",
        ],
        correctAnswers: [
          "Крюком поднимают груз на высоту 100 - 150 мм от земли и выдерживают в течение не менее 5 минут",
        ],
      },
      {
        code: "69772",
        text: "Какая организация обеспечивает наличие комплекта испытательных (контрольных) грузов с указанием их фактической массы для проведения статических и динамических испытаний ПС на территории специализированной организации, осуществляющей ремонт или реконструкцию?",
        answers: [
          "Данная специализированная организация",
          "Изготовитель ПС",
          "Эксплуатирующая организация",
          "Экспертная организация",
        ],
        correctAnswers: ["Данная специализированная организация"],
      },
      {
        code: "69773",
        text: "В каких случаях проводятся испытания на грузовую устойчивость при первичном техническом освидетельствовании стрелового самоходного крана?",
        answers: [
          "Когда в его паспорте отсутствуют ссылки на результаты ранее проведенных испытаний",
          "Когда конструкция крана (стрела, гусек, аутригеры или опорно-поворотное устройство) была подвергнута ремонту расчетных элементов металлоконструкций с применением сварки",
          "Когда конструкция крана (стрела, гусек, аутригеры или опорно-поворотное устройство) была подвергнута изменению первоначальных геометрических размеров",
          "Когда конструкция крана (стрела, гусек, аутригеры или опорно-поворотное устройство) была подвергнута ремонту расчетных элементов металлоконструкций с заменой элементов",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "69774",
        text: "Каким требованиям из перечисленных должны отвечать рельсовый путь ПС (исключая рельсовые пути башенных и железнодорожных кранов) и рельсовый путь грузовых подвесных тележек или электрических талей, оборудованный стрелками или поворотными кругами, а также места перехода ПС или его грузовой тележки с одного пути на другой?",
        answers: [
          "Только обеспечивать плавный, без заеданий, проезд",
          "Только иметь автоматически включаемую блокировку, исключающую сход грузовой тележки с рельса при выезде ее на консоль расстыкованного участка пути",
          "Только обеспечивать управление переводом стрелки или поворотного круга от сигнала системы управления грузовой тележкой",
          "Всем перечисленным",
        ],
        correctAnswers: ["Всем перечисленным"],
      },
      {
        code: "69775",
        text: "Допускается ли пересечение путей козловых, башенных и портальных кранов с рельсовыми путями заводского транспорта?",
        answers: [
          "Допускается, только после разработки мероприятий по предупреждению столкновения работающих кранов с подвижным составом и согласования с организацией, в ведении которой находится организация движения на железнодорожных путях",
          "Допускается, только при согласовании с территориальным органом Ростехнадзора",
          "Допускается, только после выполнения мероприятий по безопасному ведению работ на рельсовых путях",
          "Не допускается",
        ],
        correctAnswers: [
          "Допускается, только после разработки мероприятий по предупреждению столкновения работающих кранов с подвижным составом и согласования с организацией, в ведении которой находится организация движения на железнодорожных путях",
        ],
      },
      {
        code: "69776",
        text: "Какой документ подтверждает готовность рельсового пути к эксплуатации, в том числе после ремонта (реконструкции)?",
        answers: [
          "Руководство (инструкция) по эксплуатации ПС с соответствующей отметкой",
          "Протокол проверки готовности рельсового пути",
          "Экспертное заключение",
          "Акт сдачи-приемки",
        ],
        correctAnswers: ["Акт сдачи-приемки"],
      },
      {
        code: "69777",
        text: "В каких случаях к акту сдачи-приемки рельсового пути, определяющему его готовность к эксплуатации, должны прикладываться данные планово-высотной съемки?",
        answers: [
          "Только после реконструкции",
          "Только после ремонта",
          "Только при подтверждении готовности к эксплуатации",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "69778",
        text: "В каких случаях рельсовые пути ПС, передвигающихся по рельсам, должны подвергаться ремонту?",
        answers: [
          "После проведения плановых и внеочередных технических освидетельствований",
          "При необходимости",
          "После проведения комплексных обследований",
          "После установки на них дополнительных ПС",
          "Каждые 3 года",
        ],
        correctAnswers: ["При необходимости"],
      },
      {
        code: "69779",
        text: "Что включает в себя проверка состояния рельсового пути, находящегося в эксплуатации? Укажите все правильные ответы.",
        answers: [
          "Ежесменный осмотр",
          "Плановую или внеочередную проверку состояния",
          "Техническое обслуживание",
          "Периодическое комплексное обследование",
        ],
        correctAnswers: [
          "Ежесменный осмотр",
          "Плановую или внеочередную проверку состояния",
        ],
      },
      {
        code: "69780",
        text: "С какой периодичностью результаты осмотров рельсовых путей заносятся в вахтенные журналы крановщика (оператора) всех ПС, установленных на одном рельсовом пути?",
        answers: [
          "После каждых 24 смен работы",
          "После каждых 30 смен работы",
          "После каждых 20 смен работы",
          "После каждых 48 смен работы",
        ],
        correctAnswers: ["После каждых 24 смен работы"],
      },
      {
        code: "69781",
        text: "С какой периодичностью производятся частичная разборка, осмотр и ревизия элементов, узлов и соединений грузозахватных приспособлений (клещи, траверсы, захваты) для контроля технического состояния, которое невозможно определить в собранном виде?",
        answers: ["Ежегодно", "Ежемесячно", "Ежеквартально", "Еженедельно"],
        correctAnswers: ["Ежегодно"],
      },
      {
        code: "69782",
        text: "В каких случаях разрешены подъем и транспортировка людей с применением ПС, в паспорте которых отсутствует разрешение на транспортировку людей?",
        answers: [
          "При перемещении персонала для крепления и раскрепления контейнеров и грузов",
          "При проведении диагностирования и ремонта металлоконструкций ПС, когда применение других средств подмащивания невозможно",
          "При монтаже, строительстве и возведении уникальных объектов, когда иные способы доставки рабочих в зону выполнения работ не могут быть применены",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "69783",
        text: "Какие из перечисленных требований, предъявляемых к ПС, выбираемым для транспортирования людей при проведении диагностирования и ремонта металлоконструкций других ПС, указаны неверно?",
        answers: [
          "Подъем и транспортирование людей с применением подъемных сооружений, в паспорте которых отсутствует разрешение на транспортирование людей, разрешается при транспортировке людей, которые в состоянии передвигаться самостоятельно",
          "Подъем и транспортирование людей с применением подъемных сооружений, в паспорте которых отсутствует разрешение на транспортирование людей, разрешается при перемещении персонала для крепления и раскрепления контейнеров и грузов",
          "Подъем и транспортирование людей с применением подъемных сооружений, в паспорте которых отсутствует разрешение на транспортирование людей, разрешается при проведении диагностирования и ремонта металлоконструкций ПС, когда применение других средств подмащивания невозможно",
          "Подъем и транспортирование людей с применением подъемных сооружений, в паспорте которых отсутствует разрешение на транспортирование людей, разрешается при монтаже и обслуживании отдельно стоящих буровых и иных установок нефтегазодобычи",
        ],
        correctAnswers: [
          "Подъем и транспортирование людей с применением подъемных сооружений, в паспорте которых отсутствует разрешение на транспортирование людей, разрешается при транспортировке людей, которые в состоянии передвигаться самостоятельно",
        ],
      },
      {
        code: "69784",
        text: "Какие действия не включает в себя проверка состояния люльки (кабины)?",
        answers: [
          "Плановую проверку состояния",
          "Грузовые испытания",
          "Ежесменный осмотр",
          "Динамические испытания",
        ],
        correctAnswers: ["Динамические испытания"],
      },
      {
        code: "69785",
        text: "Какие требования к проверке ограничителя предельного верхнего положения грузозахватного органа указаны неверно?",
        answers: [
          "Проверка осуществляется путем контроля с замером расстояния между нижней точкой грузозахватного органа и упором",
          "Проверка ограничителя должна проводиться без груза",
          "Проверка осуществляется путем контроля с замером расстояния между верхней точкой грузозахватного органа и нижней частью металлоконструкции",
          "Все перечисленные",
        ],
        correctAnswers: [
          "Проверка осуществляется путем контроля с замером расстояния между нижней точкой грузозахватного органа и упором",
        ],
      },
      {
        code: "69786",
        text: "Каким образом необходимо исключать в процессе работы специализированной организации использование материалов и изделий, на которые отсутствуют сертификаты, паспорта и другие документы, подтверждающие их качество?",
        answers: [
          "Наличием квалификации специалистов и персонала",
          "Принятой системой обучения и аттестации работников",
          "Применением неразрушающего контроля",
          "Технологической подготовкой производства и установленным производственным процессом",
          "Ответственностью специалистов и персонала",
        ],
        correctAnswers: [
          "Технологической подготовкой производства и установленным производственным процессом",
        ],
      },
      {
        code: "69787",
        text: "Кто может выполнять работы по неразрушающему контролю, если работы по монтажу, ремонту, реконструкции или модернизации выполнялись с применением сварки?",
        answers: [
          "Только собственная аттестованная лаборатория, имеющая поверенные приборы неразрушающего контроля",
          "Физические лица, аттестованные в установленном порядке по выполнению неразрушающего контроля",
          "Собственная аттестованная лаборатория или привлекаемая аттестованная лаборатория",
          "Любые специалисты по неразрушающему контролю, если выполнение работ включает необходимость контроля стыковых сварных швов",
          "Только аттестованная лаборатория, если необходимость контроля качества сварки указана в чертежах",
        ],
        correctAnswers: [
          "Собственная аттестованная лаборатория или привлекаемая аттестованная лаборатория",
        ],
      },
      {
        code: "69788",
        text: "Какая технология сварки допускается в процессе монтажа и ремонта ПС?",
        answers: [
          "Любая технология сварки, освоенная монтажной (ремонтной) организацией",
          "Технология сварки, аттестованная в установленном порядке",
          "Ручная электросварка",
          "Технология сварки, разработанная владельцем ПС",
          "Ручная электро- и газовая сварка",
        ],
        correctAnswers: [
          "Технология сварки, аттестованная в установленном порядке",
        ],
      },
      {
        code: "69789",
        text: "Какая организация должна располагать контрольно-измерительными приборами, позволяющими оценивать работоспособность и регулировку оборудования ПС при проведении ремонта (монтажа)?",
        answers: [
          "Специализированная организация",
          "Организация, осуществляющая эксплуатацию ПС",
          "Организация - поставщик ПС",
          "Организация, имеющая аттестованного электромеханика",
          "Организация, имеющая аттестованных электромеханика и гидравлика",
        ],
        correctAnswers: ["Специализированная организация"],
      },
      {
        code: "69790",
        text: "На кого возлагаются организация и проведение испытаний ПС по завершении выполненных работ по монтажу ПС?",
        answers: [
          "На субподрядную организацию, имеющую в наличии тарированные грузы для проведения контрольных грузовых испытаний",
          "На эксплуатирующую организацию",
          "На специализированную экспертную организацию",
          "ФНП не регламентируется",
          "На специализированную организацию, осуществившую монтаж ПС",
        ],
        correctAnswers: [
          "На специализированную организацию, осуществившую монтаж ПС",
        ],
      },
      {
        code: "69791",
        text: "Какие требования предъявляются к испытанию стальных цепей, устанавливаемых на ПС, после их сращивания электросваркой?",
        answers: [
          "Цепь должна быть испытана нагрузкой, в 1,25 раза превышающей ее расчетное натяжение, в течение 10 минут",
          "Цепь должна быть испытана нагрузкой, в 1,5 раза превышающей ее расчетное натяжение, в течение 10 минут",
          "Цепь должна быть испытана нагрузкой, в 2,5 раза превышающей ее расчетное натяжение, в течение 10 минут",
          "Цепь должна быть испытана нагрузкой, в 1,5 раза превышающей ее расчетное натяжение, в течение 15 минут",
        ],
        correctAnswers: [
          "Цепь должна быть испытана нагрузкой, в 1,25 раза превышающей ее расчетное натяжение, в течение 10 минут",
        ],
      },
      {
        code: "69792",
        text: 'Кто в ФНП ПС определен термином "работники" специализированной организации?',
        answers: [
          "Все сотрудники организации, включая лиц рабочих профессий",
          "Только аттестованные специалисты и лица рабочих профессий",
          "Инженерно-технические работники, имеющие высшее или среднее профессиональное образование, и персонал - лица рабочих профессий, непосредственно занятые на выполнении работ",
          "Руководители, специалисты и персонал - лица рабочих профессий основных служб организации",
          "Физические лица основных служб организации, непосредственно занятые на выполнении работ",
        ],
        correctAnswers: [
          "Инженерно-технические работники, имеющие высшее или среднее профессиональное образование, и персонал - лица рабочих профессий, непосредственно занятые на выполнении работ",
        ],
      },
      {
        code: "69793",
        text: "Кто должен выполнять работы на регистраторах, ограничителях и указателях ПС?",
        answers: [
          "Работники, допущенные учебным центром по регистраторам, ограничителям и указателям на основании проверки знаний",
          "Только работники эксплуатирующей ПС организации, прошедшие соответствующую аттестацию и проверку знаний по промышленной безопасности",
          "Работники специализированных организаций, квалификация которых соответствует требованиям изготовителей (разработчиков), изложенным в эксплуатационных документах ПС, регистраторов, ограничителей и указателей",
          "Работники специализированных организаций, имеющих допуск на производство этих работ, выданный изготовителями регистраторов, ограничителей и указателей",
        ],
        correctAnswers: [
          "Работники специализированных организаций, квалификация которых соответствует требованиям изготовителей (разработчиков), изложенным в эксплуатационных документах ПС, регистраторов, ограничителей и указателей",
        ],
      },
      {
        code: "69794",
        text: "Кто должен руководить погрузочно-разгрузочными работами при выполнении монтажа ПС?",
        answers: [
          "Инженерно-технический работник, ответственный за безопасное производство работ с применением ПС",
          "Начальник участка, на котором выполняется монтаж данного ПС",
          "Производитель работ",
          "Главный механик",
        ],
        correctAnswers: [
          "Инженерно-технический работник, ответственный за безопасное производство работ с применением ПС",
        ],
      },
      {
        code: "69795",
        text: "Кто определяет состав необходимого набора инструментов и приборов, необходимых для монтажа ограничителей, указателей и регистраторов параметров ПС?",
        answers: [
          "Работники специализированной экспертной организации",
          "Работники, выполняющие их монтаж",
          "Руководство монтажной организации в соответствии с требованиями, изложенными в эксплуатационной документации",
          "Руководство монтажной организации в соответствии с назначением соответствующего ограничителя, указателя или регистратора параметров",
          "Руководство монтажной организации",
        ],
        correctAnswers: ["Работники, выполняющие их монтаж"],
      },
      {
        code: "69796",
        text: "Каким требованиям должна отвечать площадка для выполнения монтажа ПС?",
        answers: [
          "Соответствовать геометрическим размерам монтируемого ПС с учетом дополнительных проходов вокруг него для персонала, занятого на монтаже",
          "Соответствовать требованиям площадки установки вспомогательных ПС, используемых на монтаже",
          "Соответствовать руководству (инструкции) по монтажу ПС, а также ППР или ТК на монтаж",
          "Не иметь в непосредственной близости складируемых горючих материалов и материалов, поддерживающих горение, если монтаж выполняется с применением сварки",
          "Соответствовать установленным требованиям противопожарной безопасности и охраны труда",
        ],
        correctAnswers: [
          "Соответствовать руководству (инструкции) по монтажу ПС, а также ППР или ТК на монтаж",
        ],
      },
      {
        code: "69797",
        text: "Как необходимо ограждать зону площадки для выполнения работ по монтажу ПС?",
        answers: [
          "Если доступ работников и третьих лиц на монтажную площадку исключен, ее можно не ограждать",
          "По периметру, снабдив поясняющими надписями",
          'Перед входом в зону должна быть табличка с надписью "Проход запрещен!"',
          "По периметру, а на ограждениях вывешивать знаки безопасности и таблички",
          "Специальными ограждениями, исключающими свободный проход",
        ],
        correctAnswers: [
          "По периметру, а на ограждениях вывешивать знаки безопасности и таблички",
        ],
      },
      {
        code: "69798",
        text: "Что должно быть указано в акте о приемке противовеса и балласта, если они изготовлены эксплуатирующей организацией?",
        answers: [
          "Габариты плит",
          "Соответствие плит требованиям паспорта ПС",
          "Соответствие плит требованиям руководства (инструкции) по эксплуатации ПС",
          "Данные о материале, из которого изготовлены плиты",
          "Фактическая масса плит",
        ],
        correctAnswers: ["Фактическая масса плит"],
      },
      {
        code: "69799",
        text: "Кто может проводить монтаж временных электрических сетей?",
        answers: [
          "Аттестованный специалист по монтажу",
          "Аттестованный специалист по монтажу и наладке электрооборудования ПС",
          "Аттестованный электромонтер",
          "Электрик организации, эксплуатирующей ПС",
          "Любой электромонтер организации, эксплуатирующей ПС",
        ],
        correctAnswers: ["Аттестованный электромонтер"],
      },
      {
        code: "69800",
        text: "С какого места должно выполняться управление ПС в период монтажа?",
        answers: [
          "С места, указанного в проекте производства работ",
          "С выносного пульта",
          "Из кабины",
          "По радио",
          "С места, указанного в эксплуатационной документации",
        ],
        correctAnswers: ["С места, указанного в эксплуатационной документации"],
      },
      {
        code: "69801",
        text: "Кто имеет право разработать проект на монтаж регистратора, ограничителя или указателя ПС при отсутствии необходимых указаний в эксплуатационных документах?",
        answers: [
          "Разработчик или изготовитель ПС, разработчик или изготовитель регистраторов, ограничителей и указателей ПС",
          "Специализированная организация, имеющая аттестованных специалистов для выполнения указанных работ",
          "Разработчик или изготовитель регистраторов, ограничителей и указателей ПС, либо специализированная организация",
          "Авторизованный сервисный центр, имеющий аттестованных специалистов для выполнения указанных работ",
        ],
        correctAnswers: [
          "Разработчик или изготовитель регистраторов, ограничителей и указателей ПС, либо специализированная организация",
        ],
      },
      {
        code: "69802",
        text: "Какие работы должны быть проведены после завершения монтажа и наладки системы дистанционного управления (радиоуправления) ПС?",
        answers: [
          "Полное техническое освидетельствование ПС, управляемого системой дистанционного управления (радиоуправления)",
          "Частичное техническое освидетельствование ПС, управляемого системой дистанционного управления (радиоуправления)",
          "Проверка возможности управления ПС с использованием прежней системы управления ПС, если она не демонтирована",
          "Проверка возможности управления ПС с использованием прежней системы управления ПС, если она не демонтирована, и установленной системой дистанционного управления (радиоуправления) ПС",
          "Проверка всех команд управления и аварийной защиты при работе системы дистанционного управления (радиоуправления) ПС",
        ],
        correctAnswers: [
          "Проверка всех команд управления и аварийной защиты при работе системы дистанционного управления (радиоуправления) ПС",
        ],
      },
      {
        code: "69803",
        text: "Результаты каких наладочных работ необходимо отразить в акте по окончании монтажа ПС?",
        answers: [
          "Подтверждающие работоспособность и возможность выполнения полного технического освидетельствования ПС",
          "Подтверждающие работоспособность систем управления ПС, электро-, пневмо-, гидрооборудования, механизмов, а также имеющихся в наличии ограничителей, указателей и регистраторов",
          "Подтверждающие исправность ПС и возможность передачи его в эксплуатацию",
          "Подтверждающие возможность проведения грузовых испытаний смонтированного ПС",
          "Результаты наладочных работ отражению в акте по окончании монтажа не подлежат",
        ],
        correctAnswers: [
          "Подтверждающие работоспособность систем управления ПС, электро-, пневмо-, гидрооборудования, механизмов, а также имеющихся в наличии ограничителей, указателей и регистраторов",
        ],
      },
      {
        code: "69804",
        text: "При установке опорных мостовых кранов с какой фактической группой классификации (режима) в пролетах зданий должны быть устроены галереи для прохода вдоль рельсового пути с обеих сторон пролета?",
        answers: [
          "С группой А6 и более",
          "С группой А1 и более",
          "С группой А4 и более",
          "С группой А3 и более",
        ],
        correctAnswers: ["С группой А6 и более"],
      },
      {
        code: "69805",
        text: "Какое из перечисленных требований к люку (проему) для подъема и опускания грузов, находящегося в перекрытии производственного помещения, указано неверно?",
        answers: [
          "Люк в перекрытии должен иметь постоянное ограждение высотой не менее 1,1 м",
          "Люк в перекрытии должен иметь сплошное ограждение понизу на высоту 0,15 м",
          "Люк в перекрытии должен иметь световую сигнализацию",
          "Люк в перекрытии должен иметь ограждение высотой 1,5 м",
        ],
        correctAnswers: [
          "Люк в перекрытии должен иметь ограждение высотой 1,5 м",
        ],
      },
      {
        code: "69806",
        text: "Кем выдается наряд-допуск на работу вблизи находящихся под напряжением проводов и оборудования в случаях, когда работы с применением кранов стрелового типа, кранов-манипуляторов, подъемников (вышек) ведутся на действующих электростанциях, подстанциях и линиях электропередачи?",
        answers: [
          "Организацией, эксплуатирующей электростанцию, подстанцию, линию электропередачи",
          "Организацией, эксплуатирующей кран",
          "Организацией изготовителем крана",
          "Представителем территориального отделения Ростехнадзора",
        ],
        correctAnswers: [
          "Организацией, эксплуатирующей электростанцию, подстанцию, линию электропередачи",
        ],
      },
      {
        code: "69807",
        text: "На основании какой документации должны осуществляться монтаж и наладка системы дистанционного управления (радиоуправления) ПС? Выберите 2 варианта ответа.",
        answers: [
          "Документации на эксплуатацию ПС",
          "Документации на систему дистанционного управления (радиоуправления)",
          "Ремонтной ведомости на ПС",
          "Сертификата соответствия на систему дистанционного управления (радиоуправления)",
        ],
        correctAnswers: [
          "Документации на эксплуатацию ПС",
          "Документации на систему дистанционного управления (радиоуправления)",
        ],
      },
      {
        code: "69808",
        text: "Согласно требованиям какого документа должна выполняться оценка работоспособности ограничителя или указателя опасного приближения к линии электропередачи при эксплуатации ПС?",
        answers: [
          "Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения",
          "Эксплуатационной документации",
          "Паспорта объекта",
        ],
        correctAnswers: [
          "Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения",
        ],
      },
      {
        code: "69809",
        text: "Чему равен допустимый остаточный прогиб пролетного строения кранов мостового типа в вертикальной плоскости, где L - пролет крана?",
        answers: ["0,0035L", "0,002L", "0,003L", "0,0025L"],
        correctAnswers: ["0,0035L"],
      },
      {
        code: "69810",
        text: "Кем должен быть обустроен переезд транспортных средств через пути ПС, передвигающихся по наземному рельсовому пути?",
        answers: [
          "Эксплуатирующей организацией",
          "Строительной организацией",
          "Проектной организацией",
          "Монтажной организацией",
        ],
        correctAnswers: ["Эксплуатирующей организацией"],
      },
      {
        code: "69811",
        text: "На кого возлагается ответственность за приведение в соответствие ПС, изготовленного по ранее разработанным проектам и не оборудованного ограничителями, указателями и регистраторами, необходимыми для обеспечения промышленной безопасности технологического процесса, в котором используется ПС?",
        answers: [
          "На эксплуатирующую организацию",
          "На экспертную организацию",
          "На проектную организацию",
          "На монтажную организацию",
        ],
        correctAnswers: ["На эксплуатирующую организацию"],
      },
      {
        code: "69812",
        text: "В течение какого времени допускается временное хранение профильного проката на открытом воздухе?",
        answers: [
          "В течение 3 месяцев со дня поставки",
          "В течение 4 месяцев со дня поставки",
          "В течение 5 месяцев со дня поставки",
          "В течение 7 месяцев со дня поставки",
        ],
        correctAnswers: ["В течение 3 месяцев со дня поставки"],
      },
      {
        code: "69813",
        text: "В какой документации устанавливаются нормы, согласно которым определяется качество ремонтных сварных соединений ПС?",
        answers: [
          "Только в эксплуатационной документации",
          "Только в проекте ремонта, реконструкции или модернизации ПС",
          "Во всей перечисленной документации",
        ],
        correctAnswers: ["Во всей перечисленной документации"],
      },
      {
        code: "69814",
        text: "Что необходимо предпринять, если при ремонте регистратора параметров не представляется возможным восстановление информации долговременного хранения?",
        answers: [
          "В этом случае регистратор параметров для дальнейшего применения не допускается",
          "Эксплуатирующей организацией должна быть проведена корректировка программного обеспечения",
          "Специализированной организацией должна быть сделана соответствующая запись в паспорте ПС",
          "Специализированной и эксплуатирующей организациями должен быть составлен и подписан соответствующий протокол",
        ],
        correctAnswers: [
          "Специализированной организацией должна быть сделана соответствующая запись в паспорте ПС",
        ],
      },
      {
        code: "69815",
        text: "Кто разрабатывает проект для реконструкции или модернизации ограничителя, указателя или регистратора в случае, когда изготовителя ПС установить невозможно?",
        answers: [
          "Специализированная организация",
          "Эксплуатирующая организация",
          "Изготовитель аналогичного оборудования",
          "Любая проектная организация",
        ],
        correctAnswers: ["Специализированная организация"],
      },
      {
        code: "69816",
        text: "Что из нижеперечисленного не содержит в себе проект ремонта, реконструкции или модернизации ПС с применением сварки?",
        answers: [
          "Проектно-сметную документацию на ремонтные работы",
          "Указания о применяемых металлах и сварочных материалах",
          "Способы контроля качества сварки",
          "Порядок приемки из ремонта отдельных узлов и готовых изделий",
          "Нормы браковки сварных соединений",
        ],
        correctAnswers: ["Проектно-сметную документацию на ремонтные работы"],
      },
      {
        code: "69817",
        text: "Что указывается на ремонтных чертежах элементов металлоконструкции ПС?",
        answers: [
          "Только поврежденные участки, подлежащие ремонту или замене",
          "Только типы сварных соединений и способы их выполнения",
          "Только допускаемые отклонения от номинальных размеров",
          "Только деформированные элементы и участки элементов, подлежащие исправлению правкой",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "69818",
        text: "Кем определяется набор инструментов и приборов, необходимых для ремонта, реконструкции или модернизации ограничителей, указателей, регистраторов параметров ПС?",
        answers: [
          "Ответственным за содержание ПС в работоспособном состоянии с учетом указаний технологической документации на ремонт (реконструкцию)",
          "Работниками, выполняющими работы по ремонту (реконструкции) с учетом указаний типовых рекомендаций по выбору инструментов и приборов, необходимых для ремонта (реконструкции)",
          "Работниками организаций, выполняющими указанные работы с учетом требований эксплуатационных документов",
          "Ответственным за осуществление производственного контроля за безопасной эксплуатацией ПС с учетом указаний инструкций по эксплуатации ПС",
        ],
        correctAnswers: [
          "Работниками организаций, выполняющими указанные работы с учетом требований эксплуатационных документов",
        ],
      },
      {
        code: "69819",
        text: "Какими нормативными документами необходимо руководствоваться при выборе оборудования для безопасного выполнения работ по ремонту, реконструкции или модернизации ПС?",
        answers: [
          "Общими техническими условиями",
          "Указаниями по ремонту, а также требованиями к составу работ, приведенными в руководстве (инструкции) по эксплуатации данного ПС",
          "Методическими рекомендациями по организации и выполнению работ",
          "Инструкцией по надзору за изготовлением, ремонтом и монтажом подъемных сооружений",
        ],
        correctAnswers: [
          "Указаниями по ремонту, а также требованиями к составу работ, приведенными в руководстве (инструкции) по эксплуатации данного ПС",
        ],
      },
      {
        code: "69820",
        text: "Каким требованиям должны соответствовать такелажная оснастка и вспомогательные механизмы, используемые при выполнении ремонта и реконструкции или модернизации ПС?",
        answers: [
          "Эксплуатационным документам ПС",
          "Должны пройти статические и динамические испытания",
          "Должны быть проведены дополнительные расчеты такелажной оснастки и вспомогательных механизмов",
          "Всем перечисленным требованиям",
        ],
        correctAnswers: ["Эксплуатационным документам ПС"],
      },
      {
        code: "69821",
        text: "Кто допускается к выполнению сварочных работ на опасном производственном объекте?",
        answers: [
          "Сварщики и специалисты сварочного производства не моложе 21 года, имеющие группу по электробезопасности не ниже III и прошедшие обучение мерам пожарной безопасности в объеме пожарно-технического минимума",
          "Любые лица, обладающие необходимыми умениями и ознакомившиеся с требованиями охраны труда при производстве сварочных работ",
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
        ],
        correctAnswers: [
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
        ],
      },
      {
        code: "69822",
        text: "Кем осуществляется проверка готовности к применению аттестованных технологий сварки с целью определения наличия у организации или индивидуального предпринимателя технических, организационных и квалификационных возможностей для выполнения сварочных (наплавочных) работ по применяемым им аттестованным технологиям, а также соответствия качества выполненных при аттестации контрольных сварных соединений (наплавок) требованиям НД и (или) проектной (конструкторской) документации на сварные конструкции?",
        answers: [
          "Независимыми аттестационными центрами",
          "Эксплуатирующей организацией",
          "Научно-исследовательской организацией",
          "Специализированной организацией",
        ],
        correctAnswers: ["Независимыми аттестационными центрами"],
      },
      {
        code: "69823",
        text: "Кто обеспечивает организацию и выполнение аттестационных процедур согласно требованиям к производству сварочных работ на опасных производственных объектах?",
        answers: [
          "Руководитель независимого аттестационного центра",
          "Представитель Ростехнадзора",
          "Руководитель организации",
          "Технический руководитель организации",
        ],
        correctAnswers: ["Руководитель независимого аттестационного центра"],
      },
      {
        code: "69824",
        text: "Что должно быть указано в технологических картах сварки?",
        answers: [
          "Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений",
          "Требования к сварочным материалам и сварочному оборудованию, режимы сварки, последовательность операций, технические приемы контроля качества сварных соединений",
          "Требования к применяемым сварочным технологиям, последовательность операций, технические приемы, особенности процесса сварки, обеспечивающие качество сварных соединений",
        ],
        correctAnswers: [
          "Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений",
        ],
      },
      {
        code: "69825",
        text: "Что из перечисленного должно быть в требованиях по сборке деталей под сварку, содержащихся в ПТД?",
        answers: [
          "Только способы подготовки поверхностей деталей под сварку",
          "Только порядок и последовательность сборки",
          "Только способы крепления деталей",
          "Только размеры, количество и расположение прихваток",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "69826",
        text: 'Какие требования предъявляются федеральными нормами и правилами в области промышленной безопасности "Требования к производству сварочных работ на опасных производственных объектах" к сварочному оборудованию и сварочным материалам, применяемым при выполнении сварочных работ?',
        answers: [
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
          "Оборудование и материалы должны обеспечивать максимальную производительность работ",
          "Оборудование и материалы должны быть экономичными в использовании и простыми в ремонте",
        ],
        correctAnswers: [
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "69827",
        text: "В соответствии с чьими указаниями должно содержаться в исправном состоянии, обслуживаться и эксплуатироваться сварочное оборудование?",
        answers: [
          "Производителя сварочного оборудования",
          "Инспектора Ростехнадзора",
          "Руководителя независимого аттестационного центра",
          "Руководителя организации",
        ],
        correctAnswers: ["Производителя сварочного оборудования"],
      },
      {
        code: "69828",
        text: "Что обязано проверить и обеспечить лицо, осуществляющее руководство сварочными работами, перед выполнением сварочных работ?",
        answers: [
          "Только соответствие численного состава и квалификации персонала сварочного производства требованиям производственно-технической документации",
          "Только соответствие сборочного и сварочного оборудования требованиям производственно-технической документации",
          "Только соответствие основных и сварочных материалов, применяемой технологии сварки требованиям производственно-технической документации",
          "Выполнение всех подготовительных мероприятий",
          "Только организацию проведения операционного контроля",
          "Только ознакомление сварщиков с требованиями технологических карт сварки, а также с внесенными в них изменениями (при наличии), с подтверждением ознакомления подписями сварщиков в применяемых ими технологических картах сварки",
        ],
        correctAnswers: ["Выполнение всех подготовительных мероприятий"],
      },
      {
        code: "69829",
        text: "Какие функции обязано выполнить лицо, осуществляющее руководство сварочными работами, назначенное распорядительным документом организации или (и) должностной инструкцией которого предусмотрено руководство сварочными работами, перед выполнением сварочных работ?",
        answers: [
          "Только проверить и обеспечить соответствие численного состава и квалификации персонала сварочного производства, сборочного и сварочного оборудования, основных и сварочных материалов, применяемой технологии сварки требованиям ПТД",
          "Только ознакомить сварщиков с требованиями технологических карт сварки, а также с внесенными в них изменениями (при наличии) с подтверждением ознакомления подписям сварщиков в применяемых ими технологических картах сварки",
          "Только организовать проведение операционного контроля",
          "Все перечисленные функции",
        ],
        correctAnswers: ["Все перечисленные функции"],
      },
      {
        code: "69830",
        text: "Какие виды контроля должны осуществляться при подготовке и выполнении сварочных работ?",
        answers: [
          "Только входной контроль",
          "Только операционный контроль",
          "Только приемочный контроль",
          "Все перечисленные виды контроля",
        ],
        correctAnswers: ["Все перечисленные виды контроля"],
      },
      {
        code: "69831",
        text: "Какие из перечисленных видов контроля не осуществляются при подготовке и выполнении сварочных работ?",
        answers: [
          "Входной контроль",
          "Операционный контроль",
          "Приемочный контроль",
          "Эксплуатационный контроль",
        ],
        correctAnswers: ["Эксплуатационный контроль"],
      },
      {
        code: "69832",
        text: "Что из перечисленного должно контролироваться в процессе сварки, как минимум?",
        answers: [
          "Только соответствие параметров режима сварки и технологических приемов выполнения сварного соединения",
          "Только очередность выполнения сварных швов и участков наплавки",
          "Только отсутствие видимых дефектов",
          "Только контроль параметров, предусмотренных в технологических (операционных) картах сварки",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "69833",
        text: "Каким образом допускается маркировать сварное соединение, выполненное несколькими сварщиками (бригадой сварщиков)?",
        answers: [
          "Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
          "Допускается применение клейма сварщика, выполнившего наибольший объем работ",
          "Должны быть поставлены клейма всех сварщиков, участвовавших в сварке",
          "Допускается применение клейма одного из участвовавших в сварке сварщиков по выбору руководителя сварочных работ",
        ],
        correctAnswers: [
          "Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
        ],
      },
      {
        code: "69834",
        text: "Что из перечисленного должно обеспечить лицо, осуществляющее руководство сварочными работами, при производстве сварочных работ?",
        answers: [
          "Только идентификацию применяемых сварочных материалов и сварочного оборудования",
          "Только регистрацию сведений о сварщиках, выполняющих сварные соединения",
          "Только регистрацию результатов качества сварных соединений, включая результаты контроля исправлений дефектов сварных соединений",
          "Только идентификацию мест расположения сварных соединений в конструкции и мест исправлений дефектов сварных соединений",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "69835",
        text: "Какая документация оформляется в процессе выполнения сварочных работ?",
        answers: [
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
          "Только журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю",
          "Только протоколы испытаний сварных соединений",
        ],
        correctAnswers: [
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "69836",
        text: "Чем должно быть укомплектовано место производства сварочных работ?",
        answers: [
          "Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД",
          "Необходимым сборочно-сварочным оборудованием и наглядными пособиями (плакатами, схемами и т. д.) по вопросу соблюдения противопожарного режима на объекте",
          "Ничем, кроме сварочного оборудования",
        ],
        correctAnswers: [
          "Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД",
        ],
      },
      {
        code: "69837",
        text: "Какую проверку должен пройти сварщик, приступающий к сварке на конкретном объекте впервые или после перерыва в работе продолжительностью более установленного НД, независимо от наличия аттестационного удостоверения, до начала производства работ?",
        answers: [
          "Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте с получением положительных результатов контроля их качества",
          "Проверку знания теоретических основ сварки",
          "Проверку умения определять и устранять видимые дефекты сварного соединения",
        ],
        correctAnswers: [
          "Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте с получением положительных результатов контроля их качества",
        ],
      },
      {
        code: "69838",
        text: "Какую проверку должен пройти сварщик, впервые приступающий к сварке, перед допуском к работе?",
        answers: [
          "Проверку знания теоретических основ сварки",
          "Проверку умения определять и устранять видимые дефекты сварного соединения",
          "Проверку путем выполнения и контроля допускного сварного соединения",
        ],
        correctAnswers: [
          "Проверку путем выполнения и контроля допускного сварного соединения",
        ],
      },
      {
        code: "69839",
        text: "В соответствии с какими документами должен проводиться контроль стыковых сварных соединений радиографическим или ультразвуковым методом?",
        answers: [
          'В соответствии с ГОСТ "Контроль неразрушающий. Соединения сварные. Методы ультразвуковые"',
          "В соответствии с указаниями проектно-технологической документации эксплуатирующей организации",
          'В соответствии с ГОСТ "Контроль неразрушающий. Соединения сварные. Радиографический метод"',
          "В соответствии с проектом ремонта, реконструкции или модернизации ПС, разработанным специализированной организацией",
        ],
        correctAnswers: [
          "В соответствии с проектом ремонта, реконструкции или модернизации ПС, разработанным специализированной организацией",
        ],
      },
      {
        code: "69840",
        text: "Где указывается суммарная длина контролируемых участков сварных соединений?",
        answers: [
          "В проекте ремонта, реконструкции или модернизации ПС",
          "В технологической карте на реконструкцию",
          "В стандарте организации, разработанном специализированной организацией",
          "В паспорте ПС",
        ],
        correctAnswers: [
          "В проекте ремонта, реконструкции или модернизации ПС",
        ],
      },
      {
        code: "69841",
        text: "Какой объем ремонтных сварных соединений элементов металлоконструкций из высокопрочных сталей подвергается ультразвуковому и магнитопорошковому контролю?",
        answers: [
          "55 % сварных соединений",
          "75 % сварных соединений",
          "60 % сварных соединений",
          "100 % сварных соединений",
        ],
        correctAnswers: ["100 % сварных соединений"],
      },
      {
        code: "69842",
        text: "В каких случаях для контроля качества сварных швов допустимо применение капиллярного неразрушающего контроля?",
        answers: [
          "Применение капиллярного контроля устанавливается в ТУ на ремонт, реконструкцию или модернизацию ПС для любых типов сварных швов",
          "Применение капиллярного контроля при ремонте, реконструкции или модернизации ПС для контроля качества сварных швов запрещается",
          "Применение капиллярного контроля сварных швов (кроме стыковых) устанавливается специализированной организацией в проекте ремонта, реконструкции или модернизации ПС",
          "Применение капиллярного контроля сварных швов (кроме стыковых) возможно, если другие методы неразрушающего контроля применить невозможно или нецелесообразно",
          "Применение капиллярного контроля сварных швов предпочтительно, если неразрушающий контроль необходимо выполнить при отрицательных температурах окружающего воздуха",
        ],
        correctAnswers: [
          "Применение капиллярного контроля сварных швов (кроме стыковых) устанавливается специализированной организацией в проекте ремонта, реконструкции или модернизации ПС",
        ],
      },
      {
        code: "69843",
        text: "Сколько раз допускается повторная сварка на одном и том же участке сварного соединения?",
        answers: [
          "Повторная сварка запрещена",
          "Не более трех раз",
          "Не более четырех раз",
          "Не более двух раз",
        ],
        correctAnswers: ["Не более двух раз"],
      },
      {
        code: "69844",
        text: "Какой документ регламентирует интервал проведения плановых ремонтов ПС?",
        answers: [
          "Протокол технического освидетельствования",
          "Паспорт ПС",
          "Ведомость дефектов",
          "Руководство (инструкция) по эксплуатации ПС",
        ],
        correctAnswers: ["Руководство (инструкция) по эксплуатации ПС"],
      },
      {
        code: "69845",
        text: "В каких случаях при выполнении ремонта должна выполняться замена отдельных элементов ПС, если на них не обнаружено видимых повреждений?",
        answers: [
          "Если их сложно продиагностировать методами неразрушающего контроля",
          "Если в руководстве (инструкции) по эксплуатации ПС указано, что при достижении определенной наработки должна выполняться их замена",
          "Если сборочную единицу ПС разобрать невозможно",
          "Если сборочная единица подверглась термоциклическому нагружению от воздействия либо низких, либо высоких температур, превышающих указанные в паспорте ПС",
          "Если установлено, что сборочная единица эксплуатировалась без необходимой смазки",
        ],
        correctAnswers: [
          "Если в руководстве (инструкции) по эксплуатации ПС указано, что при достижении определенной наработки должна выполняться их замена",
        ],
      },
      {
        code: "69846",
        text: "Кто дает разрешение на пуск в работу ПС по окончании ремонта, реконструкции или модернизации ограничителя, указателя или регистратора?",
        answers: [
          "Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии",
          "Специалист, ответственный за осуществление производственного контроля при эксплуатации ПС",
          "Специалист, ответственный за безопасное производство работ с применением ПС",
          "Руководитель ОПО или его заместитель",
          "Руководитель специализированной организации, выполнившей ремонт",
        ],
        correctAnswers: [
          "Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии",
        ],
      },
      {
        code: "69847",
        text: "Что из перечисленного не включает итоговая документация по результатам выполненных ремонтных работ?",
        answers: [
          "Ремонтные рабочие чертежи",
          "Технологические карты",
          "Протокол испытания механических свойств контрольных образцов для каждого сварщика, выполняющего сварку несущих элементов металлоконструкций",
        ],
        correctAnswers: [
          "Протокол испытания механических свойств контрольных образцов для каждого сварщика, выполняющего сварку несущих элементов металлоконструкций",
        ],
      },
      {
        code: "69848",
        text: "Какой документ подтверждает качество ремонта рельсового пути (для ПС, передвигающихся по рельсам)?",
        answers: [
          "Акт сдачи-приемки рельсового пути",
          "Технический отчет",
          "Экспертное заключение",
        ],
        correctAnswers: ["Акт сдачи-приемки рельсового пути"],
      },
      {
        code: "69849",
        text: "Кем делается запись в паспорте ПС о проведенной работе по завершении выполнения ремонта, реконструкции или модернизации ПС?",
        answers: [
          "Эксплуатирующей организацией",
          "Специализированной организацией",
          "Экспертной организацией",
        ],
        correctAnswers: ["Специализированной организацией"],
      },
      {
        code: "69850",
        text: "Кто обязан сделать запись в паспорте ПС по завершении его ремонта, реконструкции или модернизации о проведенной работе, и предоставить копии сертификатов о примененных материалах, замененных и отремонтированных запчастях, узлах и агрегатах?",
        answers: [
          "Специалист эксплуатирующей организации, ответственный за содержание ПС в работоспособном состоянии и осуществивший его приемку",
          "Представитель Ростехнадзора, разрешающий пуск ПС в работу",
          "Специализированная организация, выполнившая указанные работы",
          "Лицо, руководившее выполнением полного технического освидетельствования",
          "Руководитель службы ОТК организации, выполнявшей работу",
        ],
        correctAnswers: [
          "Специализированная организация, выполнившая указанные работы",
        ],
      },
      {
        code: "69851",
        text: "При каком условии допускается правка стального проката местным нагревом?",
        answers: [
          "При наличии технологии, разработанной специализированной организацией",
          "При наличии инструкции по проведению данных работ",
          "При наличии разрешения Ростехнадзора на проведение данной операции",
          "Ни при каком условии не допускается",
        ],
        correctAnswers: [
          "При наличии технологии, разработанной специализированной организацией",
        ],
      },
      {
        code: "69852",
        text: "Какие завалы допускаются на торцах деталей из профильного металлопроката?",
        answers: [
          "Не более 2 мм",
          "Не более 1 мм",
          "Не более 3 мм",
          "Не регламентированы",
        ],
        correctAnswers: ["Не более 1 мм"],
      },
      {
        code: "69853",
        text: "Какие организации не осуществляют ремонт ограничителей, указателей и регистраторов ПС?",
        answers: [
          "Изготовители ПС",
          "Сервисные центры",
          "Специализированные организации",
          "Эксплуатирующие организации",
        ],
        correctAnswers: ["Эксплуатирующие организации"],
      },
    ],
    1001: [
      {
        code: "1001000",
        text: "На какие дороги действие Правил безопасности пассажирских канатных дорог и фуникулеров не распространяется?",
        answers: [
          "На безопорные буксировочные канатные дороги",
          "На грузовые подвесные канатные дороги",
          "На канатные дороги, предназначенные для перемещения пассажиров в вагонах по наклонному рельсовому пути тяговым канатом",
          "На комбинированные канатные дороги, совмещающие свойства подвесных и буксировочных",
        ],
        correctAnswers: ["На грузовые подвесные канатные дороги"],
      },
      {
        code: "1001001",
        text: "Каким требованиям должно соответствовать оборудование канатных дорог, приобретаемых за рубежом?*",
        answers: [
          'Требованиям ФНП "Безопасность пассажирских канатных дорог и фуникулеров"',
          "Требованиям Европейских норм, действующих в области канатных дорог",
          "Требованиям норм Таможенного союза",
          "Требованиям норм страны-производителя",
        ],
        correctAnswers: [
          'Требованиям ФНП "Безопасность пассажирских канатных дорог и фуникулеров"',
        ],
      },
      {
        code: "1001002",
        text: "Кто несет ответственность за содержание канатной дороги в исправном состоянии и соблюдение безопасных условий работы?",
        answers: [
          "Юридическое лицо или индивидуальный предприниматель, осуществляющие эксплуатацию КД в составе ОПО, находящуюся у нее во владении на праве собственности или ином законном основании",
          "Специализированная организация",
          "Организация по ремонту и обслуживанию канатных дорог",
        ],
        correctAnswers: [
          "Юридическое лицо или индивидуальный предприниматель, осуществляющие эксплуатацию КД в составе ОПО, находящуюся у нее во владении на праве собственности или ином законном основании",
        ],
      },
      {
        code: "1001003",
        text: "Кто утверждает правила перевозки пассажиров и багажа канатной дорогой?",
        answers: [
          "Эксплуатирующая организация",
          "Проектная организация",
          "Ростехнадзор",
          "Организация, имеющая лицензию на проведение экспертизы промышленной безопасности",
        ],
        correctAnswers: ["Эксплуатирующая организация"],
      },
      {
        code: "1001004",
        text: "Какой максимальный продольный уклон буксировочной дорожки допускается в общем случае для дорог с двухместными буксировочными устройствами?",
        answers: ["26°", "30°", "22°", "14° у шкивов и 22° на линии"],
        correctAnswers: ["26°"],
      },
      {
        code: "1001005",
        text: "Какой угол отклонения в плане на опоре допускается для несуще-тяговых канатов пассажирских подвесных канатных дорог (далее - ППКД)?",
        answers: ["30’", "17’", "20’", "45’"],
        correctAnswers: ["30’"],
      },
      {
        code: "1001006",
        text: "Какой угол отклонения в плане на опоре допускается для несущих канатов ППКД?",
        answers: ["17’", "30’", "25’", "19’"],
        correctAnswers: ["17’"],
      },
      {
        code: "1001007",
        text: "Какие требования предъявляются к трассе КД при прохождении ее в лесистой местности с целью обеспечения промышленной безопасности ОПО с КД?",
        answers: [
          "При прохождении КД в лесистой местности должна исключаться возможность падения деревьев на линию КД",
          "При прохождении КД в лесистой местности должна исключаться возможность падения деревьев на элементы, оборудование и подвижной состав КД",
          "Просека под линией канатной дороги должна иметь ширину не менее колеи КД с учетом поперечных колебаний канатов и подвижного состава, за исключением случаев, когда проектом КД не предусмотрена вертикальная эвакуация пассажиров с подвижного состава",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "1001008",
        text: "Какое минимальное расстояние между буксировочными дорожками допускается при параллельном прохождении линий БКД и ББКД?",
        answers: ["2 м", "3,2 м", "8 м", "15 м"],
        correctAnswers: ["15 м"],
      },
      {
        code: "1001009",
        text: "В каком случае допускается прохождение линии ППКД над территорией детских дошкольных и образовательных организаций?",
        answers: [
          "Прохождение линии ППКД над территорией таких организаций запрещено",
          "Если расстояние от низшей точки подвижного состава до земли с учетом снежного покрова составляет не менее 3 м",
          "Если расстояние от низшей точки подвижного состава до высшей точки зданий и сооружений составляет не менее 2 м",
          "Если исключена возможность присутствия людей",
        ],
        correctAnswers: [
          "Прохождение линии ППКД над территорией таких организаций запрещено",
        ],
      },
      {
        code: "1001010",
        text: "Что необходимо предпринять, если буксировочная дорожка проходит рядом с местом катания лыжников?",
        answers: [
          "Буксировочную дорожку необходимо выделить (обозначить)",
          "Установить силовое ограждение вдоль всего участка приближения",
          "Установить информационные и запрещающие знаки",
          "Перенести зону катания в другое место",
        ],
        correctAnswers: [
          "Буксировочную дорожку необходимо выделить (обозначить)",
        ],
      },
      {
        code: "1001011",
        text: "В каких случаях допускается увеличивать высоту от низа подвижного состава ППКД с открытыми креслами до земли на 10 м от максимально допустимой?",
        answers: [
          "На отдельных участках протяженностью не более 1/3 пролета",
          "В пролетах длиной до 100 м",
          "В пролетах длиной свыше 200 м",
          "Ни в каких случаях",
        ],
        correctAnswers: [
          "На отдельных участках протяженностью не более 1/3 пролета",
        ],
      },
      {
        code: "1001012",
        text: "Какое минимальное расстояние от низшей точки подвижного состава ППКД до земли (с учетом снежного покрова) допускается в зонах, где исключено присутствие людей?",
        answers: ["2 м", "1,5 м", "2,5 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "1001013",
        text: "На какое минимальное расстояние допускается приближение строений или естественных препятствий к габаритам БКД?",
        answers: ["2 м", "1 м", "1,5 м", "2,5 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "1001014",
        text: "Какой величины не должна превышать высота прохождения тягового каната над землей с учетом снежного покрова на БКД с буксировочными устройствами штангового типа?",
        answers: [
          "Высота прохождения тягового каната не должна превышать длины буксировочного устройства с нагрузкой на опорную тарелку или траверсу 200 Н",
          "Высота прохождения тягового каната не должна превышать длины собранного буксировочного устройства",
          "Высота прохождения тягового каната не должна превышать длины полностью вытянутого буксировочного устройства",
          "Высота прохождения тягового каната не должна превышать 4 м",
        ],
        correctAnswers: [
          "Высота прохождения тягового каната не должна превышать длины буксировочного устройства с нагрузкой на опорную тарелку или траверсу 200 Н",
        ],
      },
      {
        code: "1001015",
        text: "Как должны оборудоваться проходы и площадки для пассажиров, которые находятся выше уровня земли?",
        answers: [
          "Их необходимо выделить (обозначить)",
          "Их ограждают перилами, минимальная высота которых должна быть на 1 м выше снежного покрова",
          "Их ограждают маркировочными сетками",
          "Их ограждают перилами высотой не менее 1 м и сплошным ограждением по низу на высоту не менее 0,1 м",
        ],
        correctAnswers: [
          "Их ограждают перилами высотой не менее 1 м и сплошным ограждением по низу на высоту не менее 0,1 м",
        ],
      },
      {
        code: "1001016",
        text: "Какое максимальное расстояние допускается между краем посадочной платформы и подвижным составом на канатных дорогах с кабинами или вагонами?",
        answers: ["0,2 м", "0,15 м", "0,1 м", "0,05 м"],
        correctAnswers: ["0,05 м"],
      },
      {
        code: "1001017",
        text: "Каким должно быть расстояние по вертикали от середины передней кромки нагруженного кресла до земли с учетом снежного покрова в зоне посадки-высадки у кресельных канатных дорог?",
        answers: ["40 ± 5 см", "50 ± 5 см", "37 ± 5 см", "46 ± 5 см"],
        correctAnswers: ["50 ± 5 см"],
      },
      {
        code: "1001018",
        text: "Какой знак не устанавливается в зоне посадки кресельных ППКД?",
        answers: [
          '"Поднять носки лыж"',
          '"Опустить скобу безопасности или защитный колпак"',
          '"Не прыгать из кресла"',
          '"Не раскачиваться"',
        ],
        correctAnswers: ['"Поднять носки лыж"'],
      },
      {
        code: "1001019",
        text: "Какой знак не устанавливается в зоне высадки кресельных ППКД?",
        answers: [
          '"Езда "слаломом" запрещена"',
          '"Поднять носки лыж"',
          '"Открыть скобу безопасности или защитный колпак"',
          '"Место высадки"',
        ],
        correctAnswers: ['"Езда "слаломом" запрещена"'],
      },
      {
        code: "1001020",
        text: "Какой знак не устанавливается в зоне посадки БКД?",
        answers: [
          '"При падении немедленно покинуть буксировочную дорожку"',
          '"Взять лыжные палки в одну руку"',
          '"Запрещено применять стиль "слалом" при движении по дорожке"',
          '"Отпустить буксировочное устройство"',
        ],
        correctAnswers: ['"Отпустить буксировочное устройство"'],
      },
      {
        code: "1001021",
        text: "Какой знак не устанавливается в зоне высадки БКД?",
        answers: [
          "Не выходить за пределы буксировочной дорожки",
          "Предварительное оповещение о месте высадки",
          "Отпустить буксировочное устройство",
          "Не задерживаться в месте высадки",
        ],
        correctAnswers: ["Не выходить за пределы буксировочной дорожки"],
      },
      {
        code: "1001022",
        text: "Какой минимальной величине должна соответствовать длина счаленного участка тяговых и несуще-тяговых канатов относительно диаметра каната (dk)?",
        answers: ["1200 dk", "1000 dk", "1300 dk", "3000 dk"],
        correctAnswers: ["1200 dk"],
      },
      {
        code: "1001023",
        text: "Каким должно быть минимальное расстояние между двумя соседними счалками тяговых и несуще-тяговых канатов относительно диаметра каната (dk)?",
        answers: ["3000 dk", "1200 dk", "1300 dk", "5000 dk"],
        correctAnswers: ["3000 dk"],
      },
      {
        code: "1001024",
        text: "Каким образом запрещается производить сращивание концов тягового каната ББКД?",
        answers: [
          "С применением винтового зажима",
          "Счаливанием",
          "С использованием специальных приспособлений с клиновыми замками",
        ],
        correctAnswers: ["С применением винтового зажима"],
      },
      {
        code: "1001025",
        text: "В каком случае проводится дефектоскопия каната?",
        answers: [
          "При остаточном удлинении каната более чем на 0,5% рабочей длины после первоначальной вытяжки в начальный период эксплуатации и выполненной пересчалки",
          "При обнаружении в канате одной или нескольких оборванных прядей",
          "При уменьшении диаметра каната двойной свивки в результате поверхностного износа или коррозии на 7% и более по сравнению с номинальным диаметром",
          "После любого внешнего воздействия (укорачивание, ремонт, удар молнии, деформирование) перед началом эксплуатации",
        ],
        correctAnswers: [
          "После любого внешнего воздействия (укорачивание, ремонт, удар молнии, деформирование) перед началом эксплуатации",
        ],
      },
      {
        code: "1001026",
        text: "В течение какого времени должен приводиться в действие аварийный привод?",
        answers: [
          "В течение 15 минут",
          "В течение 5 минут",
          "В течение 30 минут",
          "В течение 3 часов",
        ],
        correctAnswers: ["В течение 15 минут"],
      },
      {
        code: "1001027",
        text: "Какое устройство на наземных канатных дорогах (далее - НКД) и маятниковых ППКД должно срабатывать при переезде регулировочной точки?",
        answers: [
          "Аварийный тормоз",
          "Устройство контроля превышения скорости",
          "Рабочий тормоз",
          "Аварийный привод",
        ],
        correctAnswers: ["Аварийный тормоз"],
      },
      {
        code: "1001028",
        text: "Каким должен быть минимальный интервал времени между движущимися буксировочными устройствами одноместных БКД?",
        answers: ["4 секунды", "5 секунд", "6 секунд", "3 секунды"],
        correctAnswers: ["4 секунды"],
      },
      {
        code: "1001029",
        text: "Каким должен быть минимальный интервал времени между движущимися буксировочными устройствами двухместных БКД?",
        answers: ["3 секунды", "4 секунды", "5 секунд", "6 секунд"],
        correctAnswers: ["6 секунд"],
      },
      {
        code: "1001030",
        text: "Каким должен быть минимальный интервал времени между движущимися буксировочными устройствами ББКД?",
        answers: ["3 секунды", "4 секунды", "5 секунд", "6 секунд"],
        correctAnswers: ["5 секунд"],
      },
      {
        code: "1001031",
        text: "Какие характеристики фиксированного зажима КД должны быть указаны в руководстве по эксплуатации?",
        answers: [
          "Только номинальный диаметр каната",
          "Только величина момента затяжки зажима",
          "Только допустимый износ зажимных губок",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "1001032",
        text: "Каким устройством должны быть оснащены все типы канатных дорог?",
        answers: [
          "Счетчиком моточасов",
          "Анемометром или анеморумбометром",
          "Аварийным приводом",
          "Аварийным тормозом",
        ],
        correctAnswers: ["Счетчиком моточасов"],
      },
      {
        code: "1001033",
        text: "Чем не должны оснащаться вагоны с сопровождающим персоналом наземных канатных дорог? Укажите все правильные ответы.",
        answers: [
          "Правилами перевозки пассажиров",
          "Устройствами, автоматически останавливающими КД при столкновении с препятствиями на трассе",
          "Пунктом управления",
          "Указаниями о допустимом количестве пассажиров",
        ],
        correctAnswers: [
          "Правилами перевозки пассажиров",
          "Устройствами, автоматически останавливающими КД при столкновении с препятствиями на трассе",
        ],
      },
      {
        code: "1001034",
        text: "Какая устанавливается минимальная скорость пассажирских подвесных канатных дорог и фуникулеров при плавном регулировании скорости в пределах ее паспортных величин?",
        answers: ["0,75 м/с", "0,5 м/с", "1,5 м/с", "1,0 м/с"],
        correctAnswers: ["0,5 м/с"],
      },
      {
        code: "1001035",
        text: "Каким должен быть минимальный интервал времени между движущимися буксировочными устройствами ББКД?",
        answers: ["3 секунды", "4 секунды", "5 секунд", "6 секунд"],
        correctAnswers: ["5 секунд"],
      },
      {
        code: "1001036",
        text: "Какая максимальная скорость движения кольцевых канатных дорог с отцепляемым подвижным составом на трассе для открытых кабин и кресел?",
        answers: ["7 м/с", "5 м/с", "6 м/с", "8 м/с"],
        correctAnswers: ["6 м/с"],
      },
      {
        code: "1001037",
        text: "Какие из перечисленных значений соответствуют тяговому канату диаметром свыше 16 мм на буксировочных канатных дорогах?",
        answers: [
          "Минимальный диаметр роликов составляет 150 мм, а перегиб каната на одном ролике - 9°30'",
          "Минимальный диаметр роликов составляет 200 мм, а перегиб каната на одном ролике - 8°30'",
          "Минимальный диаметр роликов составляет 250 мм, а перегиб каната на одном ролике - 5°30'",
          "Минимальный диаметр роликов составляет 300 мм, а перегиб каната на одном ролике - 3°30'",
        ],
        correctAnswers: [
          "Минимальный диаметр роликов составляет 250 мм, а перегиб каната на одном ролике - 5°30'",
        ],
      },
      {
        code: "1001038",
        text: "Какой диапазон досягаемости рукой пассажира составляет при полностью открывающихся окнах и в открытых вагонах наземных канатных дорог?",
        answers: ["0,2 м", "0,5 м", "1,2 м", "1 м"],
        correctAnswers: ["1 м"],
      },
      {
        code: "1001039",
        text: "Для какой канатной дороги минимальный коэффициент запаса прочности тяговых канатов соответствует значению 4?",
        answers: [
          "Для маятниковой КД без тормоза ловителя на несущем канате",
          "Для маятниковой КД с тормозом ловителя на несущем канате",
          "Для наземной КД",
          "Для двухканатной КД замкнутого цикла только для одного направления",
        ],
        correctAnswers: [
          "Для двухканатной КД замкнутого цикла только для одного направления",
        ],
      },
      {
        code: "1001040",
        text: "Какое из перечисленных требований к зонам посадки и высадки буксировочных канатных дорог (далее - БКД) и безопорных буксировочных канатных дорог (далее - ББКД) указано верно?",
        answers: [
          "В зоне высадки БКД с буксировочными устройствами барабанного типа барабаны должны проходить на высоте не менее 2,5 м от поверхности снежного покрова",
          "На БКД с буксировочными устройствами барабанного типа и самообслуживанием расстояние между опорной тарелкой или траверсой и поверхностью снежного покрова в зоне посадки должно составлять 0,6 - 1,0 м",
          "После высадки пассажира вытяжной канат должен перехлестываться с конструкциями дороги и буксировочными устройствами на стороне спуска",
          "Расстояние от тягового каната БКД со штанговыми буксировочными устройствами до поверхности снежного покрова должно быть меньше длины собранного буксировочного устройства в соответствии с технической документацией",
        ],
        correctAnswers: [
          "На БКД с буксировочными устройствами барабанного типа и самообслуживанием расстояние между опорной тарелкой или траверсой и поверхностью снежного покрова в зоне посадки должно составлять 0,6 - 1,0 м",
        ],
      },
      {
        code: "1001041",
        text: "При каком уклоне проходов для пассажиров и персонала, используемых пешеходами, они должны быть оборудованы лестницами?",
        answers: ["Более 3°", "Более 6°", "Более 4°", "Более 5°"],
        correctAnswers: ["Более 5°"],
      },
      {
        code: "1001042",
        text: "Какое количество полных витков должно быть предусмотрено для крепления концов несущих и натяжных канатов на тумбе?",
        answers: [
          "Не менее двух",
          "Не менее четырех",
          "Не менее трех",
          "Не менее пяти",
        ],
        correctAnswers: ["Не менее трех"],
      },
      {
        code: "1001043",
        text: "Какой должен быть минимальный коэффициент запаса надежности сцепления каждого клеммного зажима несущего каната?",
        answers: ["2", "4", "3", "5"],
        correctAnswers: ["3"],
      },
      {
        code: "1001044",
        text: "В каком из перечисленных случаев несущие канаты закрытой конструкции не подлежат замене?",
        answers: [
          "На участке длиной, равной 30 dк, одна десятая часть проволок наружного слоя замка оборвана",
          "Имеется выход из замка одной и более проволок наружного слоя без ее обрыва",
          "На участке длиной, равной 6 dк, имеются разрывы двух и более смежных проволок наружного слоя",
          "Оборванные концы наружных проволок каната выступают",
        ],
        correctAnswers: [
          "На участке длиной, равной 30 dк, одна десятая часть проволок наружного слоя замка оборвана",
        ],
      },
      {
        code: "1001045",
        text: "Распространяется ли действие Правил безопасности пассажирских канатных дорог и фуникулеров на безопорные буксировочные канатные дороги?",
        answers: [
          "Да, распространяется",
          "Нет, действие Правил распространяется только на подвесные канатные дороги",
          "Нет, действие Правил распространяется только на буксировочные дороги с промежуточными опорами и подвесные канатные дороги",
          "Нет, действие Правил распространяется только на буксировочные дороги с промежуточными опорами, подвесные канатные дороги и фуникулеры",
        ],
        correctAnswers: ["Да, распространяется"],
      },
      {
        code: "1001046",
        text: "Какая максимально допустимая разность скорости зажима и движущегося каната при прицеплении зажима к канату на канатных дорогах с отцепляемыми зажимами?",
        answers: ["0,2 м/с", "0,4 м/с", "0,3 м/с", "0,5 м/с"],
        correctAnswers: ["0,3 м/с"],
      },
      {
        code: "1001047",
        text: "Какая должна быть минимальная длина счалки при сращивании тяговых и несуще-тяговых канатов, где dk - номинальный диаметр каната?",
        answers: ["1000 dk", "1200 dk", "1500 dk", "3000 dk"],
        correctAnswers: ["1200 dk"],
      },
      {
        code: "1001048",
        text: "Какое из перечисленных требований к направляющим роликам пассажирских подвесных канатных дорог указано верно? Укажите все правильные ответы.",
        answers: [
          "Выступание реборды ролика над его бандажом должно быть не менее 2 мм и составлять 1/10 от диаметра несуще-тягового каната",
          "Реборды роликов для несуще-тяговых канатов одноканатных дорог с кольцевым движением при наличии новых бандажей роликов должны допускать поперечное раскачивание зажимов подвесок не менее 11°30'",
          "Глубина канавки в бандаже ролика должна быть не менее 1/15 от диаметра несуще-тягового каната",
          "Общая глубина канавки направляющих роликов ППКД (D1 - D2) / 2 для несуще-тяговых канатов составляет 1/3 диаметра каната, но не менее 10 мм",
        ],
        correctAnswers: [
          "Реборды роликов для несуще-тяговых канатов одноканатных дорог с кольцевым движением при наличии новых бандажей роликов должны допускать поперечное раскачивание зажимов подвесок не менее 11°30'",
          "Общая глубина канавки направляющих роликов ППКД (D1 - D2) / 2 для несуще-тяговых канатов составляет 1/3 диаметра каната, но не менее 10 мм",
        ],
      },
      {
        code: "1001049",
        text: "Какую добавочную величину провеса несущих канатов следует учитывать при равномерном движении груженого подвижного состава?",
        answers: ["0,1", "0,05", "0,3", "0,2"],
        correctAnswers: ["0,1"],
      },
      {
        code: "1001050",
        text: "Кем определяется численность службы производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО, на котором используется КД и ее структура?",
        answers: [
          "Проектной организацией",
          "Организацией, эксплуатирующей КД",
          "Организацией, имеющей лицензию на проведение экспертизы промышленной безопасности",
          "Ростехнадзором",
        ],
        correctAnswers: ["Организацией, эксплуатирующей КД"],
      },
      {
        code: "1001051",
        text: "Каким минимальным количеством двигателей с независимыми источниками питания - основным и аварийным - оборудуются пассажирские подвесные дороги и наземные канатные дороги?",
        answers: ["Тремя", "Двумя", "Одним", "Не регламентируется"],
        correctAnswers: ["Двумя"],
      },
      {
        code: "1001052",
        text: "Какой минимальный коэффициент устойчивости подвижного состава во всех направлениях с учетом неблагоприятного действия на него нагрузок должен быть с учетом силы ветра при движении подвижного состава?",
        answers: ["2,2", "1,4", "2", "1"],
        correctAnswers: ["2"],
      },
      {
        code: "1001053",
        text: "Какое количество пунктов управления должна иметь канатная дорога, с которых должны осуществляться ее управление и остановка, а также все виды контроля?",
        answers: [
          "Не менее четырех пунктов управления",
          "Не менее трех пунктов управления",
          "Не менее одного пункта управления",
          "Не менее двух пунктов управления",
        ],
        correctAnswers: ["Не менее одного пункта управления"],
      },
      {
        code: "1001054",
        text: "Сращивание каких канатов не допускается? Укажите все правильные ответы.",
        answers: [
          "Несуще-тяговых канатов",
          "Спасательного каната",
          "Несущего каната",
          "Натяжных канатов",
          "Тяговых канатов",
        ],
        correctAnswers: ["Несущего каната", "Натяжных канатов"],
      },
      {
        code: "1001055",
        text: "В каком случае тормоза должны приводиться в действие автоматически на пассажирских подвесных канатных дорогах и наземных канатных дорогах?",
        answers: [
          "Только в случае падения давления в гидравлических или пневматических тормозах ниже допустимого значения",
          "Только в случае размыкания в цепи безопасности",
          "Только в случае прекращения подачи электроэнергии к главному двигателю",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "1001056",
        text: "Какой допускается поперечный уклон буксировочной дорожки?",
        answers: [
          "Установленный технической документацией",
          "С одноместными буксировочными устройствами - 30°",
          "С двухместными буксировочными устройствами - 26°",
          "Поперечный уклон не допускается",
        ],
        correctAnswers: ["Поперечный уклон не допускается"],
      },
      {
        code: "1001057",
        text: "Какой максимальный фактический продольный уклон рельсового пути допускается для фуникулеров?",
        answers: ["11° 30’", "30’", "60°", "17’"],
        correctAnswers: ["60°"],
      },
      {
        code: "1001058",
        text: "В какие сроки проводятся испытания на стягивание для отцепляемых зажимов?",
        answers: [
          "После отработки 15 000 моточасов, но не позднее чем через 10 лет",
          "После отработки 9 000 моточасов, но не позднее чем через 10 лет",
          "После отработки 9 000 моточасов, но не позднее чем через 5 лет",
          "После отработки 5 000 моточасов, но не позднее чем через 3 года",
        ],
        correctAnswers: [
          "После отработки 9 000 моточасов, но не позднее чем через 5 лет",
        ],
      },
      {
        code: "1001059",
        text: "В каком случае разрешается выход на линию средства подвижного состава с недостаточным усилием зацепления после прохождения участка прицепления на наземной канатной дороге?",
        answers: [
          "В случае установки необходимых направляющих для каната и зажима",
          "В случае установки механических контрольных устройств, препятствующих отцеплению",
          "Не разрешается ни в каком случае",
          "В случае установки автоматического блокирующего устройства",
        ],
        correctAnswers: ["Не разрешается ни в каком случае"],
      },
      {
        code: "1001060",
        text: "Какое устанавливается максимальное значение угла поперечного раскачивания буксировочных устройств на буксировочных канатных дорогах?",
        answers: ["19°30'", "11°30'", "6°50'", "8°35'"],
        correctAnswers: ["11°30'"],
      },
      {
        code: "1001061",
        text: "Какое утверждение, относящееся к ходу натяжной тележки КД, указано верно?",
        answers: [
          "Натяжные устройства при любых условиях нормальной эксплуатации КД должны достигать упоров",
          "При срабатывании устройства безопасности должно происходить замедление КД",
          "При заякоривании несущего каната с обеих сторон натяжение каната в зоне анкерных устройств необходимо контролировать с периодичностью, предусмотренной руководством по эксплуатации",
          "Возврат в рабочее положение устройства безопасности должен производиться автоматически",
        ],
        correctAnswers: [
          "При заякоривании несущего каната с обеих сторон натяжение каната в зоне анкерных устройств необходимо контролировать с периодичностью, предусмотренной руководством по эксплуатации",
        ],
      },
      {
        code: "1001062",
        text: "В каком случае контроль расстояния между единицами подвижного состава на пассажирских подвесных канатных дорогах с кольцевым движением отцепляемого на станциях подвижного состава осуществляет обслуживающий персонал?",
        answers: [
          "В случае отсутствия в зонах станции автоматических устройств контроля",
          "В случае движения подвижного состава со скоростью не более 5 м/с",
          "Ни в каком случае, расстояние между единицами подвижного состава контролируется только автоматически",
          "В случае неисправности посадочного конвейера",
        ],
        correctAnswers: [
          "В случае отсутствия в зонах станции автоматических устройств контроля",
        ],
      },
      {
        code: "1001063",
        text: "Какая устанавливается максимальная рабочая скорость движения буксировочных устройств?",
        answers: ["6 м/с", "5 м/с", "4 м/с", "3 м/с"],
        correctAnswers: ["4 м/с"],
      },
      {
        code: "1001064",
        text: "Какое из перечисленных требований к приводам пассажирских подвесных дорог и наземных канатных дорог указано верно? Укажите все правильные ответы.",
        answers: [
          "Вспомогательный привод должен обеспечивать скорость не менее чем 1/2 скорости при нормальных условиях эксплуатации",
          "Переключение приводов должно происходить при включенной КД",
          "Одновременная работа аварийного и главного приводов допускается требованиями технической документации",
          "Гидропривод должен плавно приводить в движение ППКД и НКД в обоих направлениях и также их остановку",
          "Вспомогательный привод должен обеспечивать начало движения с ускорением не более 0,3 м/c² при максимальной нагрузке",
        ],
        correctAnswers: [
          "Вспомогательный привод должен обеспечивать скорость не менее чем 1/2 скорости при нормальных условиях эксплуатации",
          "Гидропривод должен плавно приводить в движение ППКД и НКД в обоих направлениях и также их остановку",
        ],
      },
      {
        code: "1001065",
        text: "Какой минимальный коэффициент запаса прочности тяговых канатов соответствует наземной канатной дороге?",
        answers: ["4,5", "3,8", "4,2", "4"],
        correctAnswers: ["4,2"],
      },
      {
        code: "1001066",
        text: "В каком случае максимальное значение углов поперечного раскачивания подвижного состава для закрытых кабин и открытого подвижного состава без пассажиров, равное 19°30', может быть уменьшено?",
        answers: [
          "В случае установления данной возможности в технической документации",
          "В случае установки на оборудовании направляющих без проводника",
          "В случае учета ветровых нагрузок",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: [
          "В случае установки на оборудовании направляющих без проводника",
        ],
      },
      {
        code: "1001067",
        text: "Какая длина зажимных губок для фиксированных зажимов подвесных канатных дорог?",
        answers: ["Не менее 3d", "Не менее 2d", "Не более 3d", "Не менее 4d"],
        correctAnswers: ["Не менее 2d"],
      },
      {
        code: "1001068",
        text: "В каких местах устанавливаются кнопки аварийной остановки на буксировочных канатных дорогах?",
        answers: [
          "На пунктах управления",
          "На лестницах к приводному и обводному шкивам",
          "В машинном помещении",
          "На приводе",
        ],
        correctAnswers: ["На пунктах управления"],
      },
      {
        code: "1001069",
        text: "В каком случае отношение между минимальным натяжением каната и весом единицы груженого подвижного состава рассчитывают с минимальным коэффициентом 10?",
        answers: [
          "В случае несущего каната с натяжным устройством",
          "В случае несущего каната с заякоренными концами",
          "В случае тягового каната",
          "В случае несуще-тягового каната",
        ],
        correctAnswers: ["В случае несущего каната с натяжным устройством"],
      },
      {
        code: "1001070",
        text: "Какое из перечисленных требований к трассе наземных канатных дорог указано верно?",
        answers: [
          "Боковое ускорение не должно превышать 0,65 м/c². В рабочем режиме движения ускорение/замедление не должно превышать 0,35 м/c²",
          "Радиус поворота пути на линии вне зон разъезда и приближения к станциям должен составлять не менее 100 м",
          "Динамические габариты трассы, определяемые габаритами подвижного состава, должны составлять +/- 0,5 м",
          "В аварийном режиме замедление не должно превышать 4 м/c²",
        ],
        correctAnswers: [
          "Боковое ускорение не должно превышать 0,65 м/c². В рабочем режиме движения ускорение/замедление не должно превышать 0,35 м/c²",
        ],
      },
      {
        code: "1001071",
        text: "Какое требование соответствует проходам для персонала и эвакуации пассажиров вдоль трассы наземных канатных дорог?",
        answers: [
          "Должны иметь ширину не менее 0,4 м и высоту не менее 1,8 м",
          "Должны иметь ширину не менее 0,8 м и высоту не менее 2,2 м",
          "Должны иметь ширину не менее 1,0 м и высоту не менее 2,4 м",
          "Должны иметь ширину не менее 0,6 м и высоту не менее 2,0 м",
        ],
        correctAnswers: [
          "Должны иметь ширину не менее 0,6 м и высоту не менее 2,0 м",
        ],
      },
      {
        code: "1001072",
        text: "Какая установлена максимальная длина безопорных буксировочных канатных дорог в плане?",
        answers: ["500 м", "200 м", "400 м", "300 м"],
        correctAnswers: ["400 м"],
      },
      {
        code: "1001073",
        text: "Какие из перечисленных требований к нанесению надписей и информационным знакам на КД указаны неверно?",
        answers: [
          "Опоры КД нумеруются по порядку, начиная с верхней опоры",
          "На элементах КД должны быть нанесены четкие и нестираемые надписи о видах опасности",
          "Информационные знаки следует устанавливать в местах КД, где существует угроза опасности",
          "Опоры КД нумеруются по порядку, начиная с нижней опоры",
        ],
        correctAnswers: [
          "Опоры КД нумеруются по порядку, начиная с верхней опоры",
        ],
      },
      {
        code: "1001074",
        text: "Какие требования к размерам и размещению окон в кабине кольцевых КД указаны верно? Выберите 2 варианта ответа.",
        answers: [
          "Окна в кабине должны быть открывающимися и расположены на высоте не менее 1,1 м от пола",
          "В кабинах с закрывающимися или неотпираемыми окнами должна быть предусмотрена вентиляция",
          "Открывающиеся окна в кабине должны иметь конструкцию и размеры, исключающие прохождение через них шара диаметром 0,3 м",
          "Окна в кабине должны быть открывающимися и расположены на высоте не менее 0,8 м от пола",
        ],
        correctAnswers: [
          "Окна в кабине должны быть открывающимися и расположены на высоте не менее 1,1 м от пола",
          "В кабинах с закрывающимися или неотпираемыми окнами должна быть предусмотрена вентиляция",
        ],
      },
      {
        code: "1001075",
        text: "Какую ширину дверного проема должны иметь кабины, предназначенные для перевозки пассажиров в креслах-каталках?",
        answers: [
          "Не менее 0,8 м",
          "Не менее 0,7 м",
          "Не менее 0,6 м",
          "Не менее 0,5 м",
        ],
        correctAnswers: ["Не менее 0,8 м"],
      },
      {
        code: "1001076",
        text: "Какие требования предъявляются к опорным элементам несущих канатов ППКД?",
        answers: [
          "Опорные элементы должны быть регулируемыми",
          "Опорные элементы не должны препятствовать продольному раскачиванию подвижного состава",
          "Опорные элементы не должны препятствовать поперечному раскачиванию подвижного состава",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "1001077",
        text: "Какие требования к натяжным устройствам КД указаны неверно?",
        answers: [
          "Для проведения профилактических работ натяжное устройство должно быть нагружено",
          "Коэффициент запаса надежности сцепления каждого клеммного зажима несущего каната должен быть не менее 3",
          "Конструкции контргрузов и натяжных тележек должны исключать возможность схода, зажатия, опрокидывания, наклона, закручивания",
          "Для крепления концов несущих и натяжных канатов на тумбе должно быть предусмотрено не менее трех полных витков",
        ],
        correctAnswers: [
          "Для проведения профилактических работ натяжное устройство должно быть нагружено",
        ],
      },
      {
        code: "1001078",
        text: "Какой класс прочности должны иметь болты для соединения ответственных деталей подвижного состава КД?",
        answers: [
          "Не менее 8,8",
          "Не менее 7,8",
          "Не менее 8,7",
          "Не менее 8,6",
        ],
        correctAnswers: ["Не менее 8,8"],
      },
      {
        code: "1001079",
        text: "Какие требования к расстоянию от защитного элемента до реборды ролика на трассе БКД указаны верно? Выберите 2  варианта ответа.",
        answers: [
          "Расстояние от защитного элемента до реборды ролика не должно превышать 1/4 диаметра каната",
          "Расстояние от защитного элемента до реборды ролика должно составлять не более 8 мм",
          "Расстояние от защитного элемента до реборды ролика не должно превышать 1/2 диаметра каната",
          "Расстояние от защитного элемента до реборды ролика должно составлять не более 10 мм",
        ],
        correctAnswers: [
          "Расстояние от защитного элемента до реборды ролика не должно превышать 1/4 диаметра каната",
          "Расстояние от защитного элемента до реборды ролика должно составлять не более 8 мм",
        ],
      },
      {
        code: "1001080",
        text: "Какие из перечисленных требований к башмакам несущего каната ППКД указаны неверно?",
        answers: [
          "На двухканатных дорогах нагрузка от несущего каната на опорный башмак должна оставаться положительной с учетом давления ветра снизу до 700 Па",
          "Концы башмаков каната должны иметь радиус скругления, соответствующий пятикратному диаметру несущего каната",
          "Длина радиуса скругления концов башмака должна соответствовать трехкратному диаметру каната",
          "На двухканатных дорогах нагрузка от несущего каната на опорный башмак должна оставаться положительной с учетом давления ветра снизу до 500 Па",
        ],
        correctAnswers: [
          "На двухканатных дорогах нагрузка от несущего каната на опорный башмак должна оставаться положительной с учетом давления ветра снизу до 700 Па",
        ],
      },
      {
        code: "1001081",
        text: "Какие из перечисленных требований к индикаторному устройству, обеспечивающему индикацию нахождения средств подвижного состава на трассе маятниковых КД, указаны неверно?",
        answers: [
          "Индикаторное устройство должно иметь шкалу с соответствующими делениями, где расстояние от средства подвижного состава до приводной станции выражено в сантиметрах",
          "Индикаторное устройство должно распознавать направление движения, в том числе при его изменении",
          "Индикаторное устройство должно давать индикацию положения опор, начала замедления на въезде, контроля фиксированной точки, некоторых важных мест контроля въезда, а также важных мест на трассе",
          "Индикаторное устройство положения подвижного состава должно работать вне зависимости от типа используемого привода",
        ],
        correctAnswers: [
          "Индикаторное устройство должно иметь шкалу с соответствующими делениями, где расстояние от средства подвижного состава до приводной станции выражено в сантиметрах",
        ],
      },
      {
        code: "1001082",
        text: "Какие упоры должен иметь узел соединения буксировочного устройства с тяговым канатом?",
        answers: [
          "Должен иметь упоры, ограничивающие продольное качание жесткой части устройства на угол +/- 80° от вертикали",
          "Должен иметь упоры, ограничивающие продольное качание жесткой части устройства на угол +/- 70° от вертикали",
          "Должен иметь упоры, ограничивающие продольное качание жесткой части устройства на угол +/- 50° от вертикали",
          "Должен иметь упоры, ограничивающие продольное качание жесткой части устройства на угол +/- 60° от вертикали",
        ],
        correctAnswers: [
          "Должен иметь упоры, ограничивающие продольное качание жесткой части устройства на угол +/- 80° от вертикали",
        ],
      },
      {
        code: "1001083",
        text: "Чему должно быть равно отношение между минимальным натяжением несуще-тягового или тягового каната ППКД и нагрузкой на ролик балансира?",
        answers: ["Не менее 15", "Не менее 5", "Не менее 10", "Не менее 8"],
        correctAnswers: ["Не менее 15"],
      },
      {
        code: "1001084",
        text: "Чему должен быть равен коэффициент запаса надежности сцепления тягового и несуще-тягового канатов с приводным шкивом?",
        answers: [
          "Не менее 1,25",
          "Не менее 1,1",
          "Не менее 1,2",
          "Не менее 0,75",
        ],
        correctAnswers: ["Не менее 1,25"],
      },
      {
        code: "1001085",
        text: "Какие из перечисленных видимых признаков повреждения или ухудшения состояния тягового каната ББКД, выполненного из синтетических материалов, являются критерием браковки каната?",
        answers: [
          "Только изменение диаметра прядей",
          "Только изменение формы прядей",
          "Только изменения расположения прядей",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "1001086",
        text: "Какие из перечисленных специальных требований для фиксированных зажимов КД указаны неверно?",
        answers: [
          "Зажимы должны охватывать канат настолько, чтобы расстояние между обоими концами зажимных губок не превышало 1,75d",
          "Зажимы должны охватывать канат настолько, чтобы расстояние между обоими концами зажимных губок не превышало 0,75d",
          "Зажимное усилие губок, создаваемое тарельчатыми пружинами, не должно снижаться более чем на 15% при выходе из строя отдельных тарельчатых пружин",
          "При отклонениях номинального диаметра каната от -10% до +6% величина закрытия зажимных губок до их соприкосновения должна составлять не менее 1 мм",
        ],
        correctAnswers: [
          "Зажимы должны охватывать канат настолько, чтобы расстояние между обоими концами зажимных губок не превышало 1,75d",
        ],
      },
      {
        code: "1001087",
        text: "Каким должен быть коэффициент запаса надежности сцепления для винтовых зажимов КД?",
        answers: ["3,0", "2,5", "1,5", "1,75"],
        correctAnswers: ["3,0"],
      },
      {
        code: "1001088",
        text: "Какие требования к участкам отцепления-прицепления зажима и каната у НКД указаны неверно?",
        answers: [
          "Участники должны обеспечивать отцепление и прицепление зажима при увеличении нагрузки на единицу подвижного состава на 25%",
          "Участники должны обеспечивать отцепление и прицепление зажима вне зависимости от скорости движения",
          "Участники должны иметь защиту от атмосферного воздействия",
          "Все перечисленное верно",
        ],
        correctAnswers: [
          "Участники должны обеспечивать отцепление и прицепление зажима при увеличении нагрузки на единицу подвижного состава на 25%",
        ],
      },
      {
        code: "1001089",
        text: "Чему должно быть равно расстояние между двумя следующими друг за другом буксировочными устройствами? Выберите 2 варианта ответа.",
        answers: [
          "Не менее чем на 10% больше, чем длина полностью вытянутого буксировочного каната барабанного устройства",
          "На 30% больше, чем длина полностью вытянутой буксировочной штанги",
          "Не менее чем на 5% больше, чем длина полностью вытянутого буксировочного каната барабанного устройства",
          "На 20% больше, чем длина полностью вытянутой буксировочной штанги",
        ],
        correctAnswers: [
          "Не менее чем на 10% больше, чем длина полностью вытянутого буксировочного каната барабанного устройства",
          "На 30% больше, чем длина полностью вытянутой буксировочной штанги",
        ],
      },
      {
        code: "1001090",
        text: "Что должен сделать владелец опасного производственного объекта до ввода КД в эксплуатацию?",
        answers: [
          "Обеспечить проведение экспертизы промышленной безопасности КД",
          "Обеспечить проведение полного технического освидетельствования КД",
          "Обеспечить проведение ежегодного технического освидетельствования КД",
          "Обеспечить отработку КД в тестовом режиме с полной загрузкой не менее 1 месяца",
        ],
        correctAnswers: [
          "Обеспечить проведение экспертизы промышленной безопасности КД",
        ],
      },
      {
        code: "1001091",
        text: "Что не требуется при работе комиссии, принимающей решение о возможности ввода в эксплуатацию ББКД?",
        answers: [
          "Присутствие представителя владельца ОПО",
          "Присутствие представителя органов исполнительной власти в области промышленной безопасности",
          "Проведение экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "Присутствие представителя органов исполнительной власти в области промышленной безопасности",
        ],
      },
      {
        code: "1001092",
        text: "При каком условии КД допускается к работе по перевозке пассажиров?",
        answers: [
          "Только при выполнении проверки и пробного пуска КД",
          "Только при наличии персонала на рабочих местах",
          "Только при выполнении условий безопасной перевозки пассажиров на КД",
          "При всех перечисленных условиях",
        ],
        correctAnswers: ["При всех перечисленных условиях"],
      },
      {
        code: "1001093",
        text: "В каком случае оператор должен присутствовать у пульта управления КД?",
        answers: [
          "Оператор должен постоянно присутствовать у пульта управления КД",
          "Оператор должен присутствовать у пульта управления только во время запуска КД",
          "Оператор должен присутствовать у пульта управления только во время проведения спасательной операции",
          "Оператор должен присутствовать у пульта управления, только когда на дороге находятся пассажиры",
        ],
        correctAnswers: [
          "Оператор должен постоянно присутствовать у пульта управления КД",
        ],
      },
      {
        code: "1001094",
        text: "Какое из перечисленных требований к органам управления КД указано верно?",
        answers: [
          "Орган управления аварийной остановкой должен останавливать КД путем наложения тормоза на приводной шкив",
          "Одновременно может быть задействовано лишь два вида управления",
          "Переключение из одного режима движения в другой и от одного вида управления к другому должно производиться кратковременно на запущенной КД",
          "Орган управления аварийной остановкой должен возвращаться в исходное положение персоналом, приводя к запуску КД",
        ],
        correctAnswers: [
          "Орган управления аварийной остановкой должен останавливать КД путем наложения тормоза на приводной шкив",
        ],
      },
      {
        code: "1001095",
        text: "Кто должен возобновлять движение дороги после ее остановки вследствие срабатывания устройства безопасности?",
        answers: [
          "Оператор с пульта управления",
          "Дежурный по станции или проводник в вагоне",
          "Ответственный за безопасную эксплуатацию КД",
          "Руководитель предприятия - владельца КД",
        ],
        correctAnswers: ["Оператор с пульта управления"],
      },
      {
        code: "1001096",
        text: "Какое действие должно быть осуществлено первым при возникновении аварии или инцидента на КД?",
        answers: [
          "Дорога должна быть немедленно остановлена",
          "Должен быть проинформирован ответственный за исправное состояние и безопасную эксплуатацию КД",
          "Должны быть проинформированы пассажиры, находящиеся на канатной дороге",
          "Должен быть введен в действие план спасательной операции",
        ],
        correctAnswers: ["Дорога должна быть немедленно остановлена"],
      },
      {
        code: "1001097",
        text: "Какое условие может не выполняться при эксплуатации ППКД и НКД в темное время суток?",
        answers: [
          "Станции посадки и высадки пассажиров должны быть обеспечены эксплуатационным освещением и, в случае его отказа, аварийным освещением",
          "Эксплуатационное и аварийное освещение должно обеспечивать освещение подвижного состава на подходах к станции и в зонах замедления подвижного состава",
          "Подвижной состав ППКД, кроме кресел, должен иметь внутреннее освещение",
          "Должны быть освещены фундаменты станций и опор",
        ],
        correctAnswers: ["Должны быть освещены фундаменты станций и опор"],
      },
      {
        code: "1001098",
        text: "Какое условие должно быть выполнено при эксплуатации БКД в темное время суток?",
        answers: [
          "Лыжная дорожка БКД должна быть освещена на всем протяжении",
          "Должны быть освещены фундаменты станций и опор",
          "Должны быть освещены части опор, мимо которых проходит подвижной состав",
          "Подвижной состав должен иметь внешнее освещение",
        ],
        correctAnswers: [
          "Лыжная дорожка БКД должна быть освещена на всем протяжении",
        ],
      },
      {
        code: "1001099",
        text: "Где проводится проверка знаний персонала канатной дороги?",
        answers: [
          "В центральной или территориальной комиссии Ростехнадзора",
          "В комиссии предприятия, эксплуатирующего канатную дорогу",
          "В комиссии специализированной организации",
          "В комиссии учебного комбината",
        ],
        correctAnswers: [
          "В комиссии предприятия, эксплуатирующего канатную дорогу",
        ],
      },
      {
        code: "1001100",
        text: "При перевозке двух детей, рост которых не превышает 1,25 м, как они должны располагаться на кресельной КД относительно сопровождающего?",
        answers: [
          "Перевозка двух детей с одним сопровождающим запрещается",
          "Дети должны располагаться справа от сопровождающего, при этом между ними не должно быть пустого места",
          "Дети должны располагаться слева от сопровождающего, при этом между ними не должно быть пустого места",
          "Дети должны располагаться по обе стороны от сопровождающего, при этом между ними не должно быть пустого места",
        ],
        correctAnswers: [
          "Дети должны располагаться по обе стороны от сопровождающего, при этом между ними не должно быть пустого места",
        ],
      },
      {
        code: "1001101",
        text: "Кому в своей работе подчиняется вспомогательный персонал канатной дороги (дежурные по станции, контролеры, проводники)?",
        answers: [
          "Оператору канатной дороги",
          "Владельцу канатной дороги",
          "Ответственному за исправное состояние и безопасную эксплуатацию канатной дороги",
          "Ответственному за соблюдение требований промышленной безопасности на канатной дороге",
        ],
        correctAnswers: ["Оператору канатной дороги"],
      },
      {
        code: "1001102",
        text: "Каким требованиям должен отвечать персонал КД по ремонту, обслуживанию и надзору за пассажирами?",
        answers: [
          "Персонал должен быть старше возраста 18 лет, не имеющий медицинских противопоказаний и прошедший проверку знаний в объеме соответствующих производственных инструкций",
          "Персонал должен иметь высшее образование и стаж работы ИТР не менее 1 года",
          "Персонал должен иметь высшее техническое образование",
          "Персонал должен иметь среднее специальное образование и пройти стажировку в течение 3 месяцев на действующей канатной дороге",
        ],
        correctAnswers: [
          "Персонал должен быть старше возраста 18 лет, не имеющий медицинских противопоказаний и прошедший проверку знаний в объеме соответствующих производственных инструкций",
        ],
      },
      {
        code: "1001103",
        text: "Какой стаж работы специалистом на канатной дороге должен иметь ответственный за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации КД?",
        answers: [
          "Не менее трех лет",
          "Не менее шести лет",
          "Не менее одного года",
          "Не менее пяти лет",
        ],
        correctAnswers: ["Не менее трех лет"],
      },
      {
        code: "1001104",
        text: "В течение какого срока ответственный за исправное состояние и безопасную эксплуатацию КД должен проходить стажировку на действующей КД того же типа при отсутствии опыта работы на КД?",
        answers: [
          "В течение срока не менее года",
          "В течение срока не менее 3 месяцев",
          "В течение срока не менее 9 месяцев",
          "В течение срока не менее 6 месяцев",
        ],
        correctAnswers: ["В течение срока не менее 3 месяцев"],
      },
      {
        code: "1001105",
        text: "Какое из перечисленных требований в зоне посадки лыжников с посадочным конвейером на кресельных канатных дорогах с неотцепляемым подвижным составом указано верно?",
        answers: [
          "Необходимо установить длину посадочного конвейера и устройства регулировки входов и их взаимодействие так, чтобы посадка заканчивалась не дальше чем за 2 м до конца ленты конвейера",
          "Скорость конвейера должна зависеть от скорости несуще-тягового каната и не должна превышать 1,5 м/с",
          "Вход лыжников на посадку должен быть расположен по направлению к посадочному конвейеру",
          "При неисправности автоматического управления входами использование посадочного конвейера допускается с разрешения лица, ответственного за безопасное производство работ",
        ],
        correctAnswers: [
          "Вход лыжников на посадку должен быть расположен по направлению к посадочному конвейеру",
        ],
      },
      {
        code: "1001106",
        text: "В каких случаях за зоной высадки устанавливают устройства контроля, автоматически останавливающие дорогу? Укажите все правильные ответы.",
        answers: [
          "Не сработало предварительное оповещение о месте высадки",
          "Буксировочное устройство не вернулось в исходное состояние",
          "Пассажир отпустил буксировочное устройство раньше времени",
          "Пассажир не успел отпустить буксировочное устройство",
        ],
        correctAnswers: [
          "Буксировочное устройство не вернулось в исходное состояние",
          "Пассажир не успел отпустить буксировочное устройство",
        ],
      },
      {
        code: "1001107",
        text: "Какая установлена максимальная скорость ускорения при разгоне и торможении на КД на участках прицепления и отцепления?",
        answers: ["2 м/с²", "2,5 м/с²", "3 м/с²", "3,5 м/с²", "1,5 м/c²"],
        correctAnswers: ["1,5 м/c²"],
      },
      {
        code: "1001108",
        text: "Каким должен оставаться угол между вытянутым вытяжным канатом и нормалью при всех условиях эксплуатации?",
        answers: [
          "Не менее 17°",
          "Не менее 20°",
          "Не менее 23°",
          "Не менее 25°",
        ],
        correctAnswers: ["Не менее 17°"],
      },
      {
        code: "1001109",
        text: "Какая максимальная скорость движения двухканатных маятниковых канатных дорог с проводником при прохождении опор?",
        answers: ["12 м/с", "10 м/с", "8,5 м/с", "6 м/с"],
        correctAnswers: ["10 м/с"],
      },
      {
        code: "1001110",
        text: "Какая максимальная скорость движения наземных канатных дорог составляет для систем с автоматическим управлением?",
        answers: ["15 м/с", "10 м/с", "5 м/с", "20 м/с"],
        correctAnswers: ["15 м/с"],
      },
      {
        code: "1001111",
        text: "В каком из перечисленных случаев максимальная скорость движения двухканатных кольцевых дорог без проводника составляет 6 м/с?",
        answers: [
          "При прохождении опор с одним несущим канатом",
          "При прохождении опор с двумя несущими канатами",
          "При посадке/высадке на станциях",
          "При прохождении пролетов",
        ],
        correctAnswers: ["При прохождении опор с одним несущим канатом"],
      },
      {
        code: "1001112",
        text: "Какие перечисленные размеры проходов и рабочих зон канатных дорог указаны верно? Укажите все правильные ответы.",
        answers: [
          "Минимальная ширина проходов для пассажиров должна соответствовать пропускной способности дороги и быть не менее 1,25 м",
          "Контрольные зоны для предъявления проездных документов и входы для КД с креслами должны быть шириной не менее 1,2 м",
          "Высота проходов для пассажиров под транспортными путями и рабочими зонами должна быть не менее 3,0 м",
          "На рабочих площадках обслуживания подвижного состава и в местах расположения проходов для персонала, где нависают детали конструкции, достаточно прохода высотой 2 м",
        ],
        correctAnswers: [
          "Минимальная ширина проходов для пассажиров должна соответствовать пропускной способности дороги и быть не менее 1,25 м",
          "На рабочих площадках обслуживания подвижного состава и в местах расположения проходов для персонала, где нависают детали конструкции, достаточно прохода высотой 2 м",
        ],
      },
      {
        code: "1001113",
        text: "Каким должно быть боковое безопасное расстояние на станциях кресельных канатных дорог между свободно висящим креслом без пассажиров и неподвижными деталями пассажирской подвесной канатной дороги на уровне сиденья со стороны оси дороги?",
        answers: [
          "Не менее 1 м от края кресла",
          "Не менее 1,2 м от края кресла",
          "Не менее 1,5 м от края кресла",
          "Не менее 0,8 м от края кресла",
        ],
        correctAnswers: ["Не менее 0,8 м от края кресла"],
      },
      {
        code: "1001114",
        text: "Какое из перечисленных требований к посадочному конвейеру является верным?",
        answers: [
          "При движении КД в обратном направлении посадочный конвейер должен двигаться с замедлением не более 0,5 м/с²",
          "Ширина посадочного конвейера должна быть меньше ширины подвижного состава",
          "Остановка посадочного конвейера осуществляется с замедлением не более 0,5 м/с2",
          "Все перечисленные требования",
        ],
        correctAnswers: [
          "Остановка посадочного конвейера осуществляется с замедлением не более 0,5 м/с2",
        ],
      },
      {
        code: "1001115",
        text: "Какому требованию должен отвечать оператор, допускаемый к самостоятельной работе?",
        answers: [
          "Должен пройти стажировку на рабочем месте сроком не менее двух недель. При этом во время стажировки должны быть отработаны все возможные виды отказов КД",
          "Должен иметь высшее образование и пройти стажировку в течение трех месяцев на действующей КД",
          "Должен иметь допуск к работам на высоте и опыт таких работ не менее трех лет",
          "Должен иметь высшее образование и стаж работы ИТР не менее трех лет",
        ],
        correctAnswers: [
          "Должен пройти стажировку на рабочем месте сроком не менее двух недель. При этом во время стажировки должны быть отработаны все возможные виды отказов КД",
        ],
      },
      {
        code: "1001116",
        text: "Кто принимает решение о возобновлении дальнейшей эксплуатации или введении в действие плана мероприятий по эвакуации в результате рассмотрения причин остановки КД?",
        answers: [
          "Ответственный за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации КД",
          "Руководитель организации, эксплуатирующей КД",
          "Ответственный за исправное состояние и безопасную эксплуатацию КД",
          "Специализированная организация, выполняющая ремонт и обслуживание КД",
        ],
        correctAnswers: [
          "Ответственный за исправное состояние и безопасную эксплуатацию КД",
        ],
      },
      {
        code: "1001117",
        text: "Кем проводится ревизия при эксплуатации КД?",
        answers: [
          "Эксплуатирующей организацией",
          "Проектной организацией",
          "Ростехнадзором",
          "Специализированной организацией",
        ],
        correctAnswers: ["Эксплуатирующей организацией"],
      },
      {
        code: "1001118",
        text: "Какая скорость устанавливается при обратном ходе пассажирских подвесных канатных дорог и наземных канатных дорог?",
        answers: [
          "Не более 0,5 м/с",
          "Не более 2,5 м/с",
          "Не более 1,5 м/с",
          "Не более 1,0 м/с",
        ],
        correctAnswers: ["Не более 1,5 м/с"],
      },
      {
        code: "1001119",
        text: "Какое из перечисленных требований к двигателям, редукторам, трансмиссиям, применяемым на канатных дорогах, указано неверно?",
        answers: [
          "В трансмиссии для передачи усилий должны использоваться плоские ремни",
          "Конечные положения переключающей (сцепной) муфты должны блокироваться механически и контролироваться системой управления КД",
          "Двигатели внутреннего сгорания должны быть жестко установлены на раме и должны иметь электростартерную систему пуска с питанием от аккумуляторной батареи",
          "В помещении станции, где установлен двигатель внутреннего сгорания, должен быть обеспечен приток воздуха",
        ],
        correctAnswers: [
          "В трансмиссии для передачи усилий должны использоваться плоские ремни",
        ],
      },
      {
        code: "1001120",
        text: "Какое из перечисленных условий для начала движения КД указано неверно?",
        answers: [
          "Электрооборудование управления движением установлено в исходное положение",
          "Получены сигналы готовности со всех станций",
          "Канатная дорога остановлена",
          "Рабочий тормоз открыт",
        ],
        correctAnswers: ["Рабочий тормоз открыт"],
      },
      {
        code: "1001121",
        text: "В каком случае допускается эксплуатация канатной дороги?",
        answers: [
          "В случае истечения срока ежегодного или полного технического освидетельствования",
          "В случае истечения назначенного срока эксплуатации",
          "В случае отсутствия на рабочем месте ответственного за исправное состояние и безопасную эксплуатацию",
          "В случае отсутствия ответственных лиц и (или) персонала, прошедших соответственно аттестацию в области промышленной безопасности и проверку знаний, необходимых для эксплуатации КД, ее осмотра и технического обслуживания",
        ],
        correctAnswers: [
          "В случае отсутствия на рабочем месте ответственного за исправное состояние и безопасную эксплуатацию",
        ],
      },
      {
        code: "1001122",
        text: "В каком случае максимальная скорость движения для кольцевых канатных дорог (КД) с неотцепляемыми креслами составляет 2 м/с?",
        answers: [
          "При перевозке пешеходов для КД с более чем двухместными креслами",
          "При перевозке пешеходов для КД с двухместными креслами",
          "При перевозке лыжников для КД с трех- или четырехместными креслами",
          "При перевозке лыжников для КД с двухместными креслами",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "1001123",
        text: "В каком из перечисленных случаев максимальная скорость движения пульсирующих дорог с закрытыми неотцепляемыми кабинами составляет 1,3 м/с?",
        answers: [
          "На трассе с одним несуще-тяговым канатом",
          "Для полуоткрытых кабин и кресел на станциях",
          "Для полуоткрытых кабин и кресел на трассе",
          "На станциях при посадке-высадке",
        ],
        correctAnswers: ["Для полуоткрытых кабин и кресел на трассе"],
      },
      {
        code: "1001124",
        text: "Какую величину составляет максимальное значение угла поперечного раскачивания для кабин с проводником и кабин, оснащенных дистанционным управлением, с помощью которого можно остановить дорогу или регулировать скорость движения, при скорости меньше или равной 7 м/с?",
        answers: ["6°50'", "11°30'", "8°35'", "14°20'"],
        correctAnswers: ["6°50'"],
      },
      {
        code: "1001125",
        text: "Какая ревизия должна быть проведена, если КД не эксплуатировали более полугода?",
        answers: [
          "Ежемесячная ревизия",
          "Еженедельная ревизия",
          "Ежегодная ревизия",
          "Полугодовая ревизия",
        ],
        correctAnswers: ["Ежегодная ревизия"],
      },
      {
        code: "1001126",
        text: "Какое максимальное расстояние необходимо выдерживать до земли в зоне стабилизации и в зоне безопасности?",
        answers: [
          "В зоне стабилизации - не более 2 м, в зоне безопасности - не более 3,5 м",
          "В зоне стабилизации - не более 1 м, в зоне безопасности - не более 2,5 м",
          "В зоне стабилизации - не более 2,5 м, в зоне безопасности - не более 4 м",
          "В зоне стабилизации - не более 1,5 м, в зоне безопасности - не более 3 м",
        ],
        correctAnswers: [
          "В зоне стабилизации - не более 1,5 м, в зоне безопасности - не более 3 м",
        ],
      },
      {
        code: "1001127",
        text: "При каком увеличении скорости относительно номинальной должно срабатывать устройство контроля превышения скорости при работе на аварийном приводе на пассажирских подвесных канатных дорогах и фуникулерах?",
        answers: ["На 5%", "На 20%", "На 15%", "На 10%"],
        correctAnswers: ["На 20%"],
      },
      {
        code: "1001128",
        text: "На сколько должна быть увеличена ширина буксировочной дорожки на мостах и во впадинах?",
        answers: ["На 1,5 м", "На 1 м", "На 0,5 м", "На 2 м"],
        correctAnswers: ["На 0,5 м"],
      },
      {
        code: "1001129",
        text: "Какое из перечисленных условий для обеспечения надежности прилегания каната к балансиру на пассажирских подвесных канатных дорогах является неверным?",
        answers: [
          "Допускается уменьшение значений величин нагрузки в три раза при наличии в балансире прижимных роликов, удерживающих канат от выпадения из роликов",
          "Для тяговых и несуще-тяговых канатов ППКД перегиб каната на одном ролике балансира должен быть не более 4°30'",
          "Нагрузка от несуще-тягового каната на балансир при наиболее неблагоприятных условиях нормальной работы ППКД должна быть не менее 2000 Н, а на опорный ролик - не менее 500 Н",
          "При превышении натяжения несуще-тягового каната на 40% нагрузка на нижний балансир должна оставаться положительной",
        ],
        correctAnswers: [
          "Допускается уменьшение значений величин нагрузки в три раза при наличии в балансире прижимных роликов, удерживающих канат от выпадения из роликов",
        ],
      },
      {
        code: "1001130",
        text: "Кто принимает решение о возобновлении дальнейшей эксплуатации канатной дороги в результате рассмотрения причин ее остановки?",
        answers: [
          "Ответственный за исправное состояние и безопасную эксплуатацию канатной дороги",
          "Начальник ремонтной бригады подрядной организации",
          "Комиссия эксплуатирующей организации",
          "Технический руководитель эксплуатирующей организации",
        ],
        correctAnswers: [
          "Ответственный за исправное состояние и безопасную эксплуатацию канатной дороги",
        ],
      },
      {
        code: "1001131",
        text: "Какая из перечисленных обязанностей относится к вспомогательному персоналу канатной дороги?",
        answers: [
          "Знать порядок ремонта и обслуживания канатной дороги",
          "Делать записи в вахтенном журнале учета работы канатной дороги",
          "Осуществлять пуск и остановку канатной дороги",
          "Знать устройство и принципы работы канатной дороги и ее отдельных узлов и механизмов",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "1001132",
        text: "В каких вагонах сопровождающий персонал должен находиться у пульта управления на возвышении по отношению к уровню пола вагона маятниковых канатных дорог?",
        answers: [
          "Вместимостью более 50 человек",
          "Вместимостью более 80 человек",
          "Вместимостью более 30 человек",
          "Вместимостью более 60 человек",
        ],
        correctAnswers: ["Вместимостью более 80 человек"],
      },
      {
        code: "1001133",
        text: "Какое требование должно быть выполнено при пересечении и сближении высоковольтной линии электропередачи с пассажирской подвесной канатной дорогой?",
        answers: [
          "Крепление мостков на опорах высоковольтной линии допускается при согласовании с владельцем ВЛ",
          "Расстояние от подвижных частей пассажирской подвесной канатной дороги до опор ВЛ не должно быть меньше 5 м",
          "Провода ВЛ напряжением свыше 1 кВ должны располагаться над ППКД и иметь заземленные защитные устройства, предотвращающие падение проводов на элементы ППКД",
          "Угол пересечения в плане линии пассажирской подвесной канатной дороги и линии ВЛ не должен быть меньше 20°",
        ],
        correctAnswers: [
          "Провода ВЛ напряжением свыше 1 кВ должны располагаться над ППКД и иметь заземленные защитные устройства, предотвращающие падение проводов на элементы ППКД",
        ],
      },
      {
        code: "1001134",
        text: "Чем должны быть оборудованы подъездные пути для доступа пассажиров в креслах-каталках?",
        answers: [
          "Проходами в контрольных зонах для предъявления проездных документов и входами для КД шириной не менее 1,9 м",
          "Пандусом с уклоном до 10° с горизонтальными площадками длиной 2 м через каждые 10 м",
          "Полом с горизонтальной поверхностью или поверхностью с уклоном не более 3°, исключающим скольжение кресел-каталок",
          "Порогами высотой не более 0,2 м",
        ],
        correctAnswers: [
          "Полом с горизонтальной поверхностью или поверхностью с уклоном не более 3°, исключающим скольжение кресел-каталок",
        ],
      },
      {
        code: "1001135",
        text: "Кем принимается решение и вносится запись в паспорт КД о вводе ее в эксплуатацию?",
        answers: [
          "Владельцем КД",
          "Ответственным за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО",
          "Ответственным за исправное состояние КД",
          "Представителем территориального органа Ростехнадзора",
        ],
        correctAnswers: [
          "Ответственным за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО",
        ],
      },
      {
        code: "1001136",
        text: "Какие из перечисленных обязанностей возлагаются на лицо, ответственное за исправное состояние и безопасную эксплуатацию КД?",
        answers: [
          "Только проведение тренировок вспомогательного персонала, привлекаемого при проведении эвакуации и борьбы с пожаром",
          "Только обеспечение выполнения персоналом своих обязанностей согласно инструкции",
          "Только обеспечение обслуживающего персонала нормативно-технической документацией",
          "Все перечисленные обязанности",
        ],
        correctAnswers: ["Все перечисленные обязанности"],
      },
      {
        code: "1001137",
        text: "С какой периодичностью проводятся повторные проверки знаний персонала?",
        answers: [
          "Не реже 1 раза в 5 лет",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в год",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "1001138",
        text: "Что из перечисленного необходимо контролировать обслуживающему КД персоналу? Выберите 2 варианта ответа.",
        answers: [
          "Минимально допустимое расстояние между единицами подвижного состава на трассе",
          "Достаточное безопасное расстояние между единицами подвижного состава на станциях",
          "Состояние путей",
          "Расписание движения составов",
        ],
        correctAnswers: [
          "Минимально допустимое расстояние между единицами подвижного состава на трассе",
          "Достаточное безопасное расстояние между единицами подвижного состава на станциях",
        ],
      },
      {
        code: "1001139",
        text: "Вагоны маятниковых КД с какой вместимостью должны сопровождаться проводником?",
        answers: [
          "Более 40 человек",
          "Более 20 человек",
          "Более 30 человек",
          "Более 35 человек",
        ],
        correctAnswers: ["Более 40 человек"],
      },
      {
        code: "1001140",
        text: "В каких случаях при эксплуатации КД устанавливаются защитные сетки? Выберите два варианта ответа.",
        answers: [
          "При перепаде высот в зоне высадки более чем на 1 м в начале наклонного участка",
          "При расстоянии до земли более 3 м",
          "При перепаде высот в зоне высадки более чем на 0,8 м в начале наклонного участка",
          "При расстоянии до земли более 2 м",
        ],
        correctAnswers: [
          "При перепаде высот в зоне высадки более чем на 1 м в начале наклонного участка",
          "При расстоянии до земли более 3 м",
        ],
      },
      {
        code: "1001141",
        text: "В каких местах БКД должны быть установлены ограждения для предотвращения травмирования персонала?",
        answers: [
          "Только со стороны буксировочных устройств",
          "Только со стороны натяжного груза",
          "Только со стороны других подвижных частей, механизмов и элементов БКД при их неконтролируемом движении в зонах станций БКД",
          "Все перечисленное верно",
        ],
        correctAnswers: ["Все перечисленное верно"],
      },
      {
        code: "1001142",
        text: "Сколько должна составлять длина зоны посадки пешеходов и лыжников у КД?",
        answers: [
          "От 1,5 до 2,5 м",
          "От 2,5 до 3,5 м",
          "От 3,5 до 4,0 м",
          "От 2,5 до 5,0 м",
        ],
        correctAnswers: ["От 2,5 до 3,5 м"],
      },
      {
        code: "1001143",
        text: "Какое допускается максимальное значение угла поперечного раскачивания для груженых открытых кабин и кресел с учетом диапазона досягаемости рукой, ногой пассажира и лыжами?",
        answers: ["11°30'", "12°30'", "11°50'", "13°30'"],
        correctAnswers: ["11°30'"],
      },
      {
        code: "1001144",
        text: "На каком расстоянии от подвижного состава должны быть размещены неподвижные конструкции устройств регулировки входов (турникеты) у КД?",
        answers: [
          "На расстоянии не менее 0,6 м",
          "На расстоянии не менее 0,5 м",
          "На расстоянии не менее 0,4 м",
          "На расстоянии не менее 0,3 м",
        ],
        correctAnswers: ["На расстоянии не менее 0,6 м"],
      },
      {
        code: "1001145",
        text: "Какой минимальный просвет должен быть на линии ППКД при отклонении подвижного состава в поперечном направлении на 11°30' между крайней точкой подвижного состава и иными элементами КД внутри колеи КД?",
        answers: ["0,3 м", "0,25 м", "0,15 м", "0,2 м"],
        correctAnswers: ["0,3 м"],
      },
      {
        code: "1001146",
        text: "Сколько должен составлять минимальный габарит от зон досягаемости рукой до препятствий по горизонтали и вертикали, а также между зонами досягаемости рукой движущихся навстречу вагонов при эксплуатации НКД?",
        answers: ["0,1 м", "0,2 м", "0,3 м", "0,4 м"],
        correctAnswers: ["0,1 м"],
      },
      {
        code: "1001147",
        text: "Какое значение не должно превышать максимальное расстояние от низшей точки подвижного состава до поверхности земли или воды для ППКД с закрытым подвижным составом?",
        answers: ["60 м", "70 м", "80 м", "75 м"],
        correctAnswers: ["60 м"],
      },
      {
        code: "1001148",
        text: "С чем допускаются пересечения БКД на одном уровне?",
        answers: [
          "Пересечение БКД с трассами для катания и путями передвижения людей и транспорта на одном уровне не допускается",
          "Пересечение БКД на одном уровне не допускается только с трассами для катания",
          "Пересечение БКД на одном уровне не допускается только с путями передвижения людей",
          "Пересечение БКД на одном уровне не допускается только с путями передвижения транспорта",
        ],
        correctAnswers: [
          "Пересечение БКД с трассами для катания и путями передвижения людей и транспорта на одном уровне не допускается",
        ],
      },
      {
        code: "1001149",
        text: "С каким ускорением должно осуществляться начало движения БКД вне зависимости от нагрузки?",
        answers: [
          "С ускорением не более 0,4 м/с2",
          "С ускорением не более 0,5 м/с2",
          "С ускорением не более 0,6 м/с2",
          "С ускорением не более 0,7 м/с2",
        ],
        correctAnswers: ["С ускорением не более 0,4 м/с2"],
      },
      {
        code: "1001150",
        text: "С каким ускорением главный привод должен обеспечить пуск дороги, как порожней, так и загруженной, для всех ППКД и НКД с ручным управлением?",
        answers: [
          "С ускорением не более 0,5 м/с2",
          "С ускорением не более 0,75 м/с2",
          "С ускорением не более 0,55 м/с2",
          "С ускорением не более 0,6 м/с2",
        ],
        correctAnswers: ["С ускорением не более 0,5 м/с2"],
      },
      {
        code: "1001151",
        text: "Какую величину составляет максимально допустимая скорость для БКД с промежуточными опорами?",
        answers: ["4 м/с", "5 м/с", "6 м/с", "4,5 м/с"],
        correctAnswers: ["4 м/с"],
      },
      {
        code: "1001152",
        text: "При выполнении каких условий разрешается возобновление движения БКД после остановки?",
        answers: [
          "БКД остановлена и все электрическое оборудование для предварительного задания скорости находится в исходном положении",
          "Рабочий тормоз наложен (в случаях, когда его наличие предусмотрено конструкцией БКД)",
          "Получены сигналы готовности к эксплуатации со всех станций",
          "При выполнении всех перечисленных условий",
        ],
        correctAnswers: ["При выполнении всех перечисленных условий"],
      },
      {
        code: "1001153",
        text: "Какое должно быть номинальное значение интервала времени прибытия на станцию подвижного состава у ППКД с отцепляемым составом?",
        answers: [
          "Не менее 5 секунд",
          "Не менее 4 секунд",
          "Не менее 3 секунд",
          "Не нормируется",
        ],
        correctAnswers: ["Не менее 5 секунд"],
      },
      {
        code: "1001154",
        text: "В каком случае должен приводиться в действие тормоз, предотвращающий самопроизвольный обратный ход дороги, автоматически?",
        answers: [
          "Только при прекращении подачи электроэнергии к главному двигателю",
          "Только при падении давления в гидравлических или пневматических тормозах ниже допустимого значения",
          "Только при размыкании в цепи безопасности",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "1001155",
        text: "На каких БКД должно быть установлено устройство контроля скорости, останавливающее КД при превышении номинальной скорости на 10%?",
        answers: [
          "На БКД при номинальной скорости свыше 3 м/с",
          "На БКД, расположенных на 5 м и выше над землей",
          "На БКД при номинальной скорости свыше 5 м/с",
          "На БКД, угол наклона которых составляет более 5°",
        ],
        correctAnswers: ["На БКД при номинальной скорости свыше 3 м/с"],
      },
      {
        code: "1001156",
        text: "Что из перечисленного должны обеспечивать устройства безопасности контроля скорости подвижного состава, если они установлены, для маятниковых КД и КД с пульсирующим режимом работы?",
        answers: [
          "Только контроль фиксированных точек",
          "Только контроль равномерного (синхронного) хода, параллельного хода",
          "Только контроль установки в нулевое положение",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "1001157",
        text: "На каком минимальном расстоянии над поверхностью снежного покрова по всей длине трассы должна обеспечить прохождение опорных тарелок и траверс высота прохождения тягового каната у БКД с барабанными буксировочными устройствами?",
        answers: ["2,3 м", "2,1 м", "1,8 м", "2,2 м"],
        correctAnswers: ["2,3 м"],
      },
      {
        code: "1001158",
        text: "Какое допускается значение замедления на приводном шкиве при остановке ППКД в нормальном (рабочем) режиме?",
        answers: [
          "Не более 1,0 м/с2",
          "Не более 2,0 м/с2",
          "Не более 1,5 м/с2",
          "Не более 1,8 м/с2",
        ],
        correctAnswers: ["Не более 1,0 м/с2"],
      },
      {
        code: "1001159",
        text: "Какое допускается значение замедления на приводном шкиве при аварийной остановке маятниковой КД?",
        answers: [
          "Не более 3 м/с2",
          "Не более 4 м/с2",
          "Не более 3,5 м/с2",
          "Не более 5 м/с2",
        ],
        correctAnswers: ["Не более 3 м/с2"],
      },
      {
        code: "1001160",
        text: "Что из перечисленного не относится к регламентным работам?",
        answers: [
          "Профилактическое обслуживание",
          "Проверки и ревизии",
          "Текущий ремонт",
          "Модернизация и реконструкция",
        ],
        correctAnswers: ["Модернизация и реконструкция"],
      },
      {
        code: "1001161",
        text: "В какие сроки проводится проверка средств связи на канатной дороге?",
        answers: ["Ежедневно", "Еженедельно", "Ежемесячно", "Ежегодно"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "1001162",
        text: "В какие сроки проводится проверка работоспособности аварийного двигателя, контроль уровней охлаждающей жидкости, масла, топлива?",
        answers: ["Ежедневно", "Еженедельно", "Ежемесячно", "Ежегодно"],
        correctAnswers: ["Еженедельно"],
      },
      {
        code: "1001163",
        text: "В какие сроки проводится проверка соединений канатов, крепление концов канатов?",
        answers: ["Ежедневно", "Еженедельно", "Ежемесячно", "Ежегодно"],
        correctAnswers: ["Ежемесячно"],
      },
      {
        code: "1001164",
        text: "В какие сроки проводится проверка на наличие трещин всех зажимов подвижного состава КД неразрушающими методами контроля?",
        answers: [
          "Ежегодно, во время технического освидетельствования",
          "Во время проведения экспертизы промышленной безопасности перед вводом в эксплуатацию, а затем после окончания установленного срока службы",
          "Через 10 лет после начала эксплуатации (с учетом предшествующей эксплуатации зажима) и далее через каждые два года",
          "Раз в два года в первые 15 лет эксплуатации и далее - ежегодно",
        ],
        correctAnswers: [
          "Через 10 лет после начала эксплуатации (с учетом предшествующей эксплуатации зажима) и далее через каждые два года",
        ],
      },
      {
        code: "1001165",
        text: "В какие сроки проводится повторная магнитная дефектоскопия канатов?",
        answers: [
          "Ежегодно, во время технического освидетельствования",
          "Во время проведения экспертизы промышленной безопасности перед вводом в эксплуатацию, а затем после окончания установленного срока службы КД",
          "Через 10 лет после начала эксплуатации и далее через каждые 2 года",
          "Раз в 3 года в первые 15 лет эксплуатации и далее - ежегодно",
        ],
        correctAnswers: [
          "Раз в 3 года в первые 15 лет эксплуатации и далее - ежегодно",
        ],
      },
      {
        code: "1001166",
        text: "С кем согласовывается изменение конструкции отдельных элементов или паспортных характеристик КД?",
        answers: [
          "С территориальным органом Ростехнадзора",
          "С экспертной организацией",
          "С организацией, выполнившей монтаж КД",
          "С разработчиком проекта, а в случае его отсутствия - со специализированной организацией",
        ],
        correctAnswers: [
          "С разработчиком проекта, а в случае его отсутствия - со специализированной организацией",
        ],
      },
      {
        code: "1001167",
        text: "В какие сроки проводится первое полное техническое освидетельствование?",
        answers: [
          "Через год после ввода в эксплуатацию",
          "После отработки 22 500 моточасов с момента первого ввода в эксплуатацию, но не позднее, чем через 15 лет",
          "После отработки 15 000 моточасов, но не позднее, чем через 10 лет",
          "После отработки 7 500 моточасов, но не позднее, чем через 5 лет",
        ],
        correctAnswers: [
          "После отработки 22 500 моточасов с момента первого ввода в эксплуатацию, но не позднее, чем через 15 лет",
        ],
      },
      {
        code: "1001168",
        text: "В каком случае проводятся статические испытания?",
        answers: [
          "При проведении ежегодного технического освидетельствования",
          "При проведении ежемесячной ревизии",
          "При проведении еженедельной проверки",
          "При проведении полного технического освидетельствования",
        ],
        correctAnswers: [
          "При проведении полного технического освидетельствования",
        ],
      },
      {
        code: "1001169",
        text: "В каком случае проводятся динамические испытания?",
        answers: [
          "При проведении ежегодного технического освидетельствования",
          "При проведении ежемесячной ревизии",
          "При проведении еженедельной проверки",
          "При проведении пробного пуска",
        ],
        correctAnswers: [
          "При проведении ежегодного технического освидетельствования",
        ],
      },
      {
        code: "1001170",
        text: "Какой вид проверки проводится после ремонта приводов и расчетных элементов металлоконструкций с применением сварки?",
        answers: [
          "Экспертиза промышленной безопасности",
          "Внеочередное техническое освидетельствование",
          "Ежегодная ревизия",
          "Динамические испытания",
        ],
        correctAnswers: ["Внеочередное техническое освидетельствование"],
      },
      {
        code: "1001171",
        text: "Куда заносятся сведения о результатах и сроке следующего технического освидетельствования?",
        answers: [
          "В вахтенный журнал",
          "В журнал ремонта и осмотров",
          "В паспорт дороги",
          "В приказ о вводе КД в эксплуатацию",
        ],
        correctAnswers: ["В паспорт дороги"],
      },
      {
        code: "1001172",
        text: "При каком уменьшении диаметра несуще-тяговых канатов в результате повреждения сердечника, внутреннего износа, обмятия по сравнению с номинальным диаметром канат подлежит браковке даже при отсутствии видимых обрывов проволок?",
        answers: ["На 5%", "На 7%", "На 3%", "На 10%"],
        correctAnswers: ["На 10%"],
      },
      {
        code: "1001173",
        text: "Кто выдает документ о проверке сварных соединений, а также литых и кованых деталей канатных дорог на наличие внутренних дефектов материала неразрушающими методами контроля? Укажите все правильные ответы.",
        answers: [
          "Национальное агентство контроля сварки",
          "Лаборатория неразрушающего контроля",
          "Организация-изготовитель",
          "Эксплуатирующая организация",
        ],
        correctAnswers: [
          "Лаборатория неразрушающего контроля",
          "Организация-изготовитель",
        ],
      },
      {
        code: "1001174",
        text: "Какие показатели не должны контролироваться ежедневно во время эксплуатации КД?",
        answers: [
          "Функционирование всех кнопок остановки",
          "Состояние зон посадки и высадки",
          "Плавность работы привода, шкивов и роликов на станциях",
          "Наличие шумов, скрежета и звуков, не характерных для нормальной работы дороги",
        ],
        correctAnswers: ["Функционирование всех кнопок остановки"],
      },
      {
        code: "1001175",
        text: "В каком случае разрешено использовать навесные или приставные лестницы для обслуживания балансиров вместо устройства стационарных лестниц и площадок у опор буксировочных канатных дорог (БКД)?",
        answers: [
          "У опор БКД с высотой прохождения тягового каната менее 5 м",
          "В случае проведения работ в присутствии лица, ответственного за безопасное производство работ",
          "У опор БКД с высотой прохождения тягового каната менее 4 м",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "У опор БКД с высотой прохождения тягового каната менее 4 м",
        ],
      },
      {
        code: "1001176",
        text: "Какому значению должна соответствовать дополнительная (резервная) длина каната для возможности перемещения несущих канатов?",
        answers: [
          "Пятикратной длине самого длинного башмака несущего каната или седловины роликовой цепи плюс 3 м",
          "Трехкратной длине самого длинного башмака несущего каната или седловины роликовой цепи плюс 5 м",
          "Шестикратной длине самого длинного башмака несущего каната или седловины роликовой цепи плюс 5 м",
          "Десятикратной длине самого длинного башмака несущего каната или седловины роликовой цепи плюс 10 м",
        ],
        correctAnswers: [
          "Шестикратной длине самого длинного башмака несущего каната или седловины роликовой цепи плюс 5 м",
        ],
      },
      {
        code: "1001177",
        text: "Какие действия из перечисленных включает в себя ревизия при эксплуатации КД? Укажите все правильные ответы.",
        answers: [
          "Чистка, консервация, смазка деталей",
          "Регулировка",
          "Замена деталей",
          "Контроль и определение фактического состояния в целом КД",
          "Измерение фактического состояния деталей, узлов, конструкций КД",
        ],
        correctAnswers: [
          "Контроль и определение фактического состояния в целом КД",
          "Измерение фактического состояния деталей, узлов, конструкций КД",
        ],
      },
      {
        code: "1001178",
        text: "В каком случае должна быть проведена ежемесячная ревизия КД?",
        answers: [
          "Если КД не эксплуатировали более недели",
          "Если КД не эксплуатировали более полугода",
          "Если КД не эксплуатировали более одного месяца",
          "Если КД не эксплуатировали более года",
        ],
        correctAnswers: ["Если КД не эксплуатировали более одного месяца"],
      },
      {
        code: "1001179",
        text: "В каком случае допускается не устанавливать вертикальные ролики и шкивы перед отклонением тягового каната и после него на буксировочных канатных дорогах?",
        answers: [
          "У самоустанавливающихся роликов и шкивов",
          "Для тяговых канатов диаметром до 18 мм",
          "Если это обосновано проектной документацией",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["У самоустанавливающихся роликов и шкивов"],
      },
      {
        code: "1001180",
        text: "Какая из перечисленных документаций поставляется (прилагается) с паспортом канатной дороги?",
        answers: [
          "Сертификаты, паспорта на отдельные узлы и элементы оборудования КД",
          "Паспорта на приборы и устройства безопасности",
          "Руководство по эксплуатации КД",
          "Вся перечисленная документация",
        ],
        correctAnswers: ["Вся перечисленная документация"],
      },
      {
        code: "1001181",
        text: "В каком месте должны быть расположены выключатели для проведения профилактических работ на пассажирских подвесных канатных дорогах и наземных канатных дорогах?",
        answers: [
          "В машинном помещении",
          "На профилактических площадках и платформах вблизи от подвижных механических устройств на всех станциях и промежуточных остановках",
          "На пультах управления подвижного состава",
          "На посту управления",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "1001182",
        text: "Где должны храниться паспорт КД и иная техническая документация по КД?",
        answers: [
          "На рабочем месте ответственного за безопасную эксплуатацию КД",
          "На рабочем месте лица, ответственного за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации КД",
          "У руководителя организации, эксплуатирующей КД",
          "На рабочем месте оператора КД",
        ],
        correctAnswers: [
          "На рабочем месте ответственного за безопасную эксплуатацию КД",
        ],
      },
      {
        code: "1001183",
        text: "Какой из перечисленных документов не должен храниться на рабочем месте оператора канатных дорог (далее - КД)?",
        answers: [
          "Журнал ремонта и осмотров или контрольные листы осмотров",
          "Указания по действию персонала в экстренных ситуациях",
          "Производственные инструкции персонала КД",
          "Руководство (руководства) по эксплуатации КД",
        ],
        correctAnswers: ["Руководство (руководства) по эксплуатации КД"],
      },
      {
        code: "1001184",
        text: "С какой периодичностью осуществляют перестановку зажимов КД с неотцепляемым подвижным составом?",
        answers: [
          "Каждые 500 моточасов функционирования КД",
          "Каждые 300 моточасов функционирования КД",
          "Каждые 200 моточасов функционирования КД",
          "Каждые 100 моточасов функционирования КД",
        ],
        correctAnswers: ["Каждые 200 моточасов функционирования КД"],
      },
      {
        code: "1001185",
        text: "Какое из перечисленных дополнительных требований для отцепляемых зажимов буксировочных канатных дорог указано неверно?",
        answers: [
          "Диаметр отверстия втулки зажима должен быть в пределах от 1,18d до 1,28d",
          "Допустимые отклонения диаметра каната и нормы износа втулок зажима должны быть указаны в технологическом регламенте",
          "Для каната диаметром от 12 до 18 мм наружный диаметр втулки зажима должен составлять 40 - 46 мм",
          "Для каната диаметром от 12 до 18 мм длина втулки зажима должна быть равна 40 ± 1 мм",
        ],
        correctAnswers: [
          "Допустимые отклонения диаметра каната и нормы износа втулок зажима должны быть указаны в технологическом регламенте",
        ],
      },
      {
        code: "1001186",
        text: "Какой блок считается как один отцепляемый зажим на пассажирских подвесных канатных дорогах?",
        answers: [
          "Если между центрами двух зажимов расстояние меньше 30 диаметров каната",
          "Если между центрами двух зажимов расстояние меньше 15 диаметров каната",
          "Если между центрами двух зажимов расстояние меньше 25 диаметров каната",
          "Никакой блок не может считаться за один зажим",
        ],
        correctAnswers: [
          "Если между центрами двух зажимов расстояние меньше 15 диаметров каната",
        ],
      },
      {
        code: "1001187",
        text: "Какие из перечисленных требований необходимо соблюдать при установке направляющих подвижного состава пассажирской подвесной канатной дороги?",
        answers: [
          "Направляющие на станциях должны быть установлены таким образом, чтобы кабины при продольных колебаниях 14°20' и одновременных поперечных колебаниях 14°20' не могли соприкасаться",
          "Направляющие на линейном оборудовании трассы устанавливают для ограничения поперечного раскачивания кабин вне диапазона досягаемости рукой и на высоте нахождения центра тяжести кабины",
          "Направляющие на станциях устанавливают для ограничения поперечного раскачивания кресел на 28°30'",
          "Направляющие на станциях или в непосредственной близости от них необходимо устанавливать вне диапазона досягаемости рукой",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "1001188",
        text: "В каких местах канатных дорог должны быть установлены индикаторные устройства?",
        answers: [
          "На пункте управления",
          "На наблюдательных пунктах КД",
          "На пульте оператора",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "1001189",
        text: "Какой документ устанавливает методы контроля и диагностики во время эксплуатации КД и при техническом освидетельствовании?",
        answers: [
          "Паспорт пассажирской КД",
          "Заключение экспертизы промышленной безопасности",
          "Руководство по эксплуатации КД",
          "Правила безопасности КД и фуникулеров",
        ],
        correctAnswers: ["Руководство по эксплуатации КД"],
      },
      {
        code: "1001190",
        text: "Кем проводится техническое освидетельствование КД?",
        answers: [
          "Ростехнадзором",
          "Эксплуатирующей организацией",
          "Проектной организацией",
          "Специализированной организацией",
        ],
        correctAnswers: ["Специализированной организацией"],
      },
      {
        code: "1001191",
        text: "Какой из перечисленных параметров КД может контролироваться перед, во время и после процесса сцепления зажимов?",
        answers: [
          "Отделение зажима от каната",
          "Открытое положение губок зажима",
          "Зажимное усилие",
          "Правильное охватывание каната губками зажима",
        ],
        correctAnswers: ["Зажимное усилие"],
      },
      {
        code: "1001192",
        text: "В каком случае допустимый коэффициент трения для приводных шкивов с резиновой футеровкой буксировочных канатных дорог составляет 0,22?",
        answers: [
          "При использовании отцепляемых зажимов с учетом кручения каната (втулки поводковых патронов)",
          "При использовании фиксированных зажимов без учета кручения каната",
          "При диаметре тягового каната до 18 мм и при использовании фиксированных зажимов без учета кручения каната",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: [
          "При использовании фиксированных зажимов без учета кручения каната",
        ],
      },
      {
        code: "1001193",
        text: "В каком случае КД не подлежит экспертизе промышленной безопасности?",
        answers: [
          "В случае отсутствия в технической документации данных о сроке службы КД, если фактический срок ее службы превышает пятнадцать лет",
          "До начала применения КД на ОПО",
          "В случае истечения срока службы или при превышении количества циклов нагрузки КД, установленных организацией-изготовителем",
          "После проведения работ, связанных с изменением конструкции, заменой материала несущих элементов КД",
        ],
        correctAnswers: [
          "В случае отсутствия в технической документации данных о сроке службы КД, если фактический срок ее службы превышает пятнадцать лет",
        ],
      },
      {
        code: "1001194",
        text: "В каких целях эксплуатирующей организацией организуется проведение технического освидетельствования КД?",
        answers: [
          "В целях подтверждения соответствия КД и ее устройства паспортным характеристикам",
          "В целях подтверждения технического состояния КД, обеспечивающего ее безопасное использование по назначению",
          "В целях подтверждения соответствия эксплуатации, содержания и обслуживания КД требованиям ФНП",
          "Все перечисленное верно",
        ],
        correctAnswers: ["Все перечисленное верно"],
      },
      {
        code: "1001195",
        text: "Автоматический контроль каких режимов должен быть обеспечен на ППКД и НКД?",
        answers: [
          "Отключения при превышении скорости",
          "Срабатывания рабочих тормозов",
          "Все перечисленное верно",
          "Срабатывание аварийных тормозов",
          "Отслеживания замедления движения",
        ],
        correctAnswers: ["Все перечисленное верно"],
      },
      {
        code: "1001196",
        text: "В каком случае работа сигнала готовности на пульте управления при эксплуатации ППКД и НКД неисправна?",
        answers: [
          "Только если сигнал готовности не отключается сигналом остановки КД",
          "Только если сигнал готовности действует в системе управления, в случае готовности КД к работе",
          "Только если сигнал готовности гаснет самостоятельно, если по истечении 30 секунд не поступило сигнала начала движения",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: [
          "Только если сигнал готовности не отключается сигналом остановки КД",
        ],
      },
      {
        code: "1001197",
        text: "Какой максимальный срок установлен для проведения эвакуации на канатной дороге?",
        answers: ["30 минут", "3 часа", "4 часа", "5 часов"],
        correctAnswers: ["4 часа"],
      },
      {
        code: "1001198",
        text: "В какой срок пассажиры должны быть оповещены об обстоятельствах сложившейся нештатной ситуации?",
        answers: [
          "В течение 30 минут с момента ее возникновения",
          "В течение 15 минут с момента ее возникновения",
          "В течение 4 часов с момента ее возникновения",
          "В течение 10 минут с момента ее возникновения",
        ],
        correctAnswers: ["В течение 15 минут с момента ее возникновения"],
      },
      {
        code: "1001199",
        text: "Каким методом допускается информировать пассажиров о сложившейся нештатной ситуации?",
        answers: [
          "Только с поверхности земли персоналом, направленным для этого и имеющим ручной громкоговоритель",
          "Только громкоговорителями на линейных опорах",
          "Только системами оповещения в подвижном составе или проводниками в кабинах и вагонах с сопровождением",
          "Всеми перечисленными способами",
        ],
        correctAnswers: ["Всеми перечисленными способами"],
      },
      {
        code: "1001200",
        text: "В каком месте допускается не располагать кнопки аварийной остановки в непосредственной близости от персонала на пассажирских подвесных канатных дорогах и наземных канатных дорогах?",
        answers: [
          "На промежуточных остановках",
          "На пультах управления подвижного состава",
          "На станциях",
          "В машинном помещении",
        ],
        correctAnswers: ["В машинном помещении"],
      },
      {
        code: "1001201",
        text: "В каком случае допускается ручное управление гидравлической системой натяжения для поддержания давления?",
        answers: [
          "На аварийном приводе",
          "На вспомогательном приводе",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["На аварийном приводе"],
      },
      {
        code: "1001202",
        text: "Какое из перечисленных требований к наклонному съезду, устраиваемому при перевозке лыжников после зоны высадки, указано верно?",
        answers: [
          "Длина наклонного съезда должна быть достаточной для ухода лыжников из зоны возможного контакта с креслом до обеспечения безопасного расстояния с ним и составлять не менее 0,7 м",
          "Для КД с креслами с неотцепляемыми зажимами наклонный спуск должен начинаться не менее чем за 2,5 секунды до прохождения креслом оси обводного шкива",
          "Уклон съезда должен составлять от 10% до 15%",
          "Для КД с креслами с отцепляемыми зажимами наклонный спуск должен начинаться за 2,0 секунды до изменения направления движения кресел",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "1001203",
        text: "На каких канатных дорогах не требуется установка системы информирования пассажиров?",
        answers: [
          "На канатных дорогах с кольцевым движением",
          "На маятниковых канатных дорогах",
          "На фуникулерах",
          "На буксировочных канатных дорогах",
        ],
        correctAnswers: ["На буксировочных канатных дорогах"],
      },
      {
        code: "1001204",
        text: 'Какая цветовая индикация индикаторных устройств, за исключением определенных случаев, имеет значение "нейтрально"? Укажите все правильные ответы.',
        answers: ["Белая", "Зеленая", "Синяя", "Желтая", "Черная"],
        correctAnswers: ["Белая", "Черная"],
      },
      {
        code: "1001205",
        text: "Какое значение имеет синяя цветовая индикация индикаторных устройств, за исключением определенных случаев?",
        answers: [
          "Аварийный случай",
          "Норма, безопасно",
          "Вынужденное отключение функций безопасности",
          "Нейтрально",
        ],
        correctAnswers: ["Вынужденное отключение функций безопасности"],
      },
      {
        code: "1001206",
        text: "При какой минимальной скорости ветра запрещается посадка пассажиров и на станциях КД осуществляется высадка всех пассажиров из подвижного состава, если такие сведения отсутствуют в паспорте?",
        answers: ["15 м/с", "25 м/с", "22 м/с", "20 м/с"],
        correctAnswers: ["15 м/с"],
      },
      {
        code: "1001207",
        text: "В каком случае в плане мероприятий по эвакуации пассажиров может не предусматриваться спасение людей методом эвакуации с подвижного состава?",
        answers: [
          "Если в кабине или вагоне находится сопровождающий",
          "Если расстояние от низа подвижного состава до земли не превышает 3 м",
          "Если в кабине или вагоне имеются эвакуационные парашюты",
          "Если на КД предусмотрена доставка пассажиров на конечные станции при любом виде отказов или аварий",
        ],
        correctAnswers: [
          "Если на КД предусмотрена доставка пассажиров на конечные станции при любом виде отказов или аварий",
        ],
      },
      {
        code: "1001208",
        text: "На какие типы дорог распространяются требования к организации и проведению эвакуационных мероприятий?",
        answers: [
          "На маятниковые дороги",
          "На ППКД и НКД",
          "На фуникулеры",
          "На БКД и ББКД",
        ],
        correctAnswers: ["На ППКД и НКД"],
      },
      {
        code: "1001209",
        text: "Какое из перечисленных требований к процессу эвакуации пассажиров с подвижного состава КД указано неверно?",
        answers: [
          "КД должна быть оснащена осветительным оборудованием, позволяющим проводить эвакуацию в условиях плохой видимости",
          "Снятие пассажиров с подвижного состава на землю осуществляют только одной бригадой спасателей",
          "Во время снятия пассажиров КД должна быть остановлена",
        ],
        correctAnswers: [
          "Снятие пассажиров с подвижного состава на землю осуществляют только одной бригадой спасателей",
        ],
      },
      {
        code: "1001210",
        text: "С какой периодичностью должна проводиться учебная эвакуация в месте КД, где эвакуация пассажиров наиболее затруднена?",
        answers: [
          "Не реже одного раза в три года",
          "Не реже одного раза в год",
          "Не реже одного раза в два года",
          "Не реже одного раза в полтора года",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "1001211",
        text: "Какое значение должен составлять диаметр счаленных (замкнутых) спасательных канатов?",
        answers: [
          "Не менее 20 мм",
          "Не менее 25 мм",
          "Не менее 15 мм",
          "Не менее 10 мм",
        ],
        correctAnswers: ["Не менее 15 мм"],
      },
      {
        code: "1001212",
        text: "Кто должен разработать план мероприятий по эвакуации пассажиров для КД и обеспечить его исполнение?",
        answers: [
          "Ответственный за исправное состояние и безопасную эксплуатацию канатной дороги",
          "Комиссия эксплуатирующей организации",
          "Ответственный за соблюдение требований промышленной безопасности на ОПО",
          "Оператор канатной дороги",
        ],
        correctAnswers: [
          "Ответственный за исправное состояние и безопасную эксплуатацию канатной дороги",
        ],
      },
      {
        code: "1001213",
        text: "Какие меры безопасности предпринимаются при прохождении трассы для перемещения пассажиров БКД?",
        answers: [
          "Трассу необходимо оградить сеткой, высота которой должна быть на 1 м выше снежного покрова",
          "На мостиках трасса должна иметь сплошное ограждение в виде стенки высотой не менее 1,1 м от снежного покрытия",
          "Трассу необходимо выделить (обозначить)",
        ],
        correctAnswers: [
          "На мостиках трасса должна иметь сплошное ограждение в виде стенки высотой не менее 1,1 м от снежного покрытия",
        ],
      },
      {
        code: "1001214",
        text: "Чему равен минимальный коэффициент запаса прочности для спасательных и эвакуационных канатов на канатных лебедках?",
        answers: ["5", "3", "2,75", "3,75"],
        correctAnswers: ["5"],
      },
    ],
    1002: [
      {
        code: "1002000",
        text: "Распространяется ли действие Правил безопасности пассажирских канатных дорог и фуникулеров на безопорные буксировочные канатные дороги?",
        answers: [
          "Да, распространяется",
          "Нет, действие Правил распространяется только на подвесные канатные дороги",
          "Нет, действие Правил распространяется только на буксировочные дороги с промежуточными опорами и подвесные канатные дороги",
          "Нет, действие Правил распространяется только на буксировочные дороги с промежуточными опорами, подвесные канатные дороги и фуникулеры",
        ],
        correctAnswers: ["Да, распространяется"],
      },
      {
        code: "1002001",
        text: "На какие дороги действие Правил безопасности пассажирских канатных дорог и фуникулеров не распространяется?",
        answers: [
          "На безопорные буксировочные канатные дороги",
          "На грузовые канатные дороги",
          "На канатные дороги, предназначенные для перемещения пассажиров в вагонах по наклонному рельсовому пути тяговым канатом",
          "На комбинированные канатные дороги, совмещающие свойства подвесных и буксировочных",
        ],
        correctAnswers: ["На грузовые канатные дороги"],
      },
      {
        code: "1002002",
        text: "Каким требованиям должно соответствовать оборудование канатных дорог, приобретаемых за рубежом?",
        answers: [
          "Требованиям Правил безопасности пассажирских канатных дорог и фуникулеров",
          "Требованиям Европейских норм, действующих в области канатных дорог",
          "Требованиям норм Таможенного союза",
          "Требованиям норм страны-производителя",
        ],
        correctAnswers: [
          "Требованиям Правил безопасности пассажирских канатных дорог и фуникулеров",
        ],
      },
      {
        code: "1002003",
        text: "Кто несет ответственность за содержание канатной дороги в исправном состоянии и соблюдение безопасных условий работы?",
        answers: [
          "Юридическое лицо или индивидуальный предприниматель, осуществляющие эксплуатацию КД в составе ОПО, находящуюся у нее во владении на праве собственности или ином законном основании (эксплуатирующая организация)",
          "Юридическое лицо",
          "Специализированная организация",
          "Организация по ремонту и обслуживанию канатных дорог",
        ],
        correctAnswers: [
          "Юридическое лицо или индивидуальный предприниматель, осуществляющие эксплуатацию КД в составе ОПО, находящуюся у нее во владении на праве собственности или ином законном основании (эксплуатирующая организация)",
        ],
      },
      {
        code: "1002004",
        text: "На каких канатных дорогах не требуется установка системы информирования пассажиров?",
        answers: [
          "На фуникулерах",
          "На канатных дорогах с кольцевым движением",
          "На маятниковых канатных дорогах",
          "На буксировочных канатных дорогах",
          "Все ответы неверны",
        ],
        correctAnswers: ["На буксировочных канатных дорогах"],
      },
      {
        code: "1002005",
        text: "Какое из перечисленных требований к трассе наземных канатных дорог указано верно?",
        answers: [
          "Радиус поворота пути на линии вне зон разъезда и приближения к станциям должен составлять не менее 100 м",
          "Боковое ускорение не должно превышать 0,65 м/c². В рабочем режиме движения ускорение/замедление не должно превышать 0,35 м/c²",
          "Динамические габариты трассы, определяемые габаритами подвижного состава, составляют +/- 0,5 м",
          "В аварийном режиме замедление не должно превышать 4 м/c²",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Боковое ускорение не должно превышать 0,65 м/c². В рабочем режиме движения ускорение/замедление не должно превышать 0,35 м/c²",
        ],
      },
      {
        code: "1002006",
        text: "Какой диапазон досягаемости рукой пассажира составляет при полностью открывающихся окнах и в открытых вагонах наземных канатных дорог?",
        answers: ["0,2 м", "0,5 м", "1,0 м", "1,2 м", "Все ответы неверны"],
        correctAnswers: ["1,0 м"],
      },
      {
        code: "1002007",
        text: "Какой минимальный коэффициент устойчивости подвижного состава во всех направлениях с учетом неблагоприятного действия на него нагрузок должен быть с учетом силы ветра при движении подвижного состава?",
        answers: ["1", "2", "1,4", "2,2", "Все ответы неверны"],
        correctAnswers: ["2"],
      },
      {
        code: "1002008",
        text: "Какой максимальный фактический продольный уклон рельсового пути допускается для фуникулеров?",
        answers: ["17'", "30'", "11°30'", "60°"],
        correctAnswers: ["60°"],
      },
      {
        code: "1002009",
        text: "Какой угол отклонения в плане на опоре допускается для несуще-тяговых канатов пассажирских подвесных канатных дорог (далее - ППКД)?",
        answers: ["17’", "30’", "11о 30’", "19о 30’"],
        correctAnswers: ["30’"],
      },
      {
        code: "1002010",
        text: "Какой угол отклонения в плане на опоре допускается для несущих канатов ППКД?",
        answers: ["17’", "30’", "11о 30’", "19о 30’"],
        correctAnswers: ["17’"],
      },
      {
        code: "1002011",
        text: "Какую добавочную величину провеса несущих канатов следует учитывать при равномерном движении груженого подвижного состава?",
        answers: ["5%", "10%", "20%", "30%", "Все ответы неверны"],
        correctAnswers: ["10%"],
      },
      {
        code: "1002012",
        text: "В каком случае максимальное значение углов поперечного раскачивания подвижного состава для закрытых кабин и открытого подвижного состава без пассажиров, равное 19°30', может быть уменьшено?",
        answers: [
          "В случае установления данной возможности в технической документации",
          "В случае установки на оборудовании направляющих без проводника",
          "В случае учета ветровых нагрузок",
          "Ни в каком случае",
        ],
        correctAnswers: [
          "В случае установки на оборудовании направляющих без проводника",
        ],
      },
      {
        code: "1002013",
        text: "Какую величину составляет максимальное значение угла поперечного раскачивания для кабин с проводником и кабин, оснащенных дистанционным управлением, с помощью которого можно остановить дорогу или регулировать скорость движения, при скорости меньше или равной 7 м/с?",
        answers: ["6°50'", "8°35'", "11°30'", "14°20'", "Все ответы неверны"],
        correctAnswers: ["6°50'"],
      },
      {
        code: "1002014",
        text: "Какая установлена максимальная длина безопорных буксировочных канатных дорог в плане?",
        answers: ["200 м", "300 м", "400 м", "500 м", "Все ответы неверны"],
        correctAnswers: ["400 м"],
      },
      {
        code: "1002015",
        text: "Какой максимальный продольный уклон буксировочной дорожки допускается в общем случае для дорог с двухместными буксировочными устройствами?",
        answers: ["30°", "26°", "22°", "14° у шкивов и 22° на линии"],
        correctAnswers: ["26°"],
      },
      {
        code: "1002016",
        text: "Какой допускается поперечный уклон буксировочной дорожки?",
        answers: [
          "Установленный технической документацией",
          "С одноместными буксировочными устройствами - 30°",
          "С двухместными буксировочными устройствами - 26°",
          "Поперечный уклон не допускается",
        ],
        correctAnswers: ["Поперечный уклон не допускается"],
      },
      {
        code: "1002017",
        text: "На сколько должна быть увеличена ширина буксировочной дорожки на мостах и во впадинах?",
        answers: [
          "На 0,5 м",
          "На 1,0 м",
          "На 1,5 м",
          "Ширина буксировочной дорожки на мостах и во впадинах не увеличивается",
        ],
        correctAnswers: ["На 0,5 м"],
      },
      {
        code: "1002018",
        text: "Какое устанавливается максимальное значение угла поперечного раскачивания буксировочных устройств на буксировочных канатных дорогах?",
        answers: ["6°50'", "8°35'", "11°30'", "19°30'", "Все ответы неверны"],
        correctAnswers: ["11°30'"],
      },
      {
        code: "1002019",
        text: "Какие требования предъявляются к трассе канатных дорог (далее - КД) при прохождении ее в лесистой местности с целью обеспечения промышленной безопасности ОПО с КД?",
        answers: [
          "При прохождении КД в лесистой местности должна исключаться возможность падения деревьев на линию КД",
          "При прохождении КД в лесистой местности должна исключаться возможность падения деревьев на элементы, оборудование и подвижной состав КД",
          "Просека под линией канатной дороги должна быть освобождена от лесонасаждений и иметь ширину не менее колеи КД с учетом поперечных колебаний канатов и подвижного состава, за исключением случаев, когда проектом КД не предусмотрена вертикальная эвакуация пассажиров с подвижного состава",
          "Все вышеперечисленное",
        ],
        correctAnswers: ["Все вышеперечисленное"],
      },
      {
        code: "1002020",
        text: "Какое минимальное расстояние между буксировочными дорожками допускается при параллельном прохождении линий БКД и ББКД?",
        answers: ["2 м", "3,2 м", "8 м", "15 м"],
        correctAnswers: ["15 м"],
      },
      {
        code: "1002021",
        text: "В каком случае допускается прохождение линии ППКД над территорией детских дошкольных и образовательных организаций?",
        answers: [
          "Прохождение линии ППКД над территорией таких организаций запрещено",
          "Там, где расстояние от низшей точки подвижного состава до земли, с учетом снежного покрова составляет не менее 3 м",
          "Там, где расстояние от низшей точки подвижного состава до высшей точки зданий и сооружений составляет не менее 2 м",
          "Там, где исключена возможность присутствия людей",
        ],
        correctAnswers: [
          "Прохождение линии ППКД над территорией таких организаций запрещено",
        ],
      },
      {
        code: "1002022",
        text: "Какое требование должно быть выполнено при пересечении и сближении высоковольтной линии электропередачи с пассажирской подвесной канатной дорогой?",
        answers: [
          "Расстояние от подвижных частей ППКД до опор высоковольтной линии (ВЛ) не должно быть меньше 5 м",
          "Угол пересечения в плане линии ППКД и линии ВЛ не должен быть меньше 20°",
          "Крепление мостков на опорах ВЛ допускается при согласовании с владельцем ВЛ",
          "Провода ВЛ напряжением свыше 1 кВ должны располагаться над ППКД и иметь заземленные защитные устройства, предотвращающие падение проводов на элементы ППКД",
        ],
        correctAnswers: [
          "Провода ВЛ напряжением свыше 1 кВ должны располагаться над ППКД и иметь заземленные защитные устройства, предотвращающие падение проводов на элементы ППКД",
        ],
      },
      {
        code: "1002023",
        text: "Какое требование соответствует проходам для персонала и эвакуации пассажиров вдоль трассы наземных канатных дорог?",
        answers: [
          "Должны иметь ширину не менее 1,0 м и высоту не менее 2,4 м",
          "Должны иметь ширину не менее 0,8 м и высоту не менее 2,2 м",
          "Должны иметь ширину не менее 0,6 м и высоту не менее 2,0 м",
          "Должны иметь ширину не менее 0,4 м и высоту не менее 1,8 м",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Должны иметь ширину не менее 0,6 м и высоту не менее 2,0 м",
        ],
      },
      {
        code: "1002024",
        text: "Каким должно быть боковое безопасное расстояние на станциях кресельных канатных дорог между свободно висящим креслом без пассажиров и неподвижными деталями пассажирской подвесной канатной дороги на уровне сиденья со стороны оси дороги?",
        answers: [
          "Не менее 0,8 м от края кресла",
          "Не менее 1,0 м от края кресла",
          "Не менее 1,2 м от края кресла",
          "Не менее 1,5 м от края кресла",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не менее 0,8 м от края кресла"],
      },
      {
        code: "1002025",
        text: "Какие из перечисленных требований необходимо соблюдать при установке направляющих подвижного состава пассажирской подвесной канатной дороги?",
        answers: [
          "Направляющие на станциях или в непосредственной близости от них необходимо устанавливать вне диапазона досягаемости рукой",
          "Направляющие на станциях должны быть установлены таким образом, чтобы кабины при продольных колебаниях 14°20' и одновременных поперечных колебаниях 14°20' не могли соприкасаться",
          "Направляющие на линейном оборудовании трассы устанавливают для ограничения поперечного раскачивания кабин вне диапазона досягаемости рукой и на высоте нахождения центра тяжести кабины",
          "Направляющие на станциях устанавливают для ограничения поперечного раскачивания кресел на 28°30'",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "1002026",
        text: "Какое минимальное расстояние от низшей точки подвижного состава ППКД до земли (с учетом снежного покрова) допускается в зонах, где исключено присутствие людей?",
        answers: ["3 м", "2 м", "1,5 м", "1 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "1002027",
        text: "В каких случаях допускается увеличивать высоту от низа подвижного состава ППКД с открытыми креслами до земли на 10 м от максимально допустимой?",
        answers: [
          "На участках протяженностью не более 1/3 длины пролета ППКД",
          "В пролетах длиной до 100 м",
          "В пролетах длиной свыше 200 м",
          "Высоту от низа подвижного состава до земли на таких ППКД увеличивать нельзя",
        ],
        correctAnswers: [
          "На участках протяженностью не более 1/3 длины пролета ППКД",
        ],
      },
      {
        code: "1002028",
        text: "На какое минимальное расстояние допускается приближение строений или естественных препятствий к габаритам БКД?",
        answers: ["1 м", "1,5 м", "2 м", "2,5 м"],
        correctAnswers: ["2 м"],
      },
      {
        code: "1002029",
        text: "Что необходимо предпринять, если буксировочная дорожка проходит рядом с местом катания лыжников?",
        answers: [
          "Буксировочную дорожку необходимо обозначить (выделить)",
          "Установить силовое ограждение вдоль всего участка приближения",
          "Установить информационные и запрещающие знаки",
          "Перенести зону катания в другое место",
        ],
        correctAnswers: [
          "Буксировочную дорожку необходимо обозначить (выделить)",
        ],
      },
      {
        code: "1002030",
        text: "Каким должен оставаться угол между вытянутым вытяжным канатом и нормалью при всех условиях эксплуатации?",
        answers: [
          "Не менее 17°",
          "Не менее 20°",
          "Не менее 23°",
          "Не менее 25°",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не менее 17°"],
      },
      {
        code: "1002031",
        text: "Какой величины не должна превышать высота прохождения тягового каната над землей, с учетом снежного покрова на БКД с буксировочными устройствами штангового типа?",
        answers: [
          "Высота прохождения тягового каната не должна превышать длины буксировочного устройства с нагрузкой на опорную тарелку или траверсу 200 Н",
          "Высота прохождения тягового каната не должна превышать длины собранного буксировочного устройства",
          "Высота прохождения тягового каната не должна превышать длины полностью вытянутого буксировочного устройства",
          "Высота прохождения тягового каната не должна превышать 4 м",
        ],
        correctAnswers: [
          "Высота прохождения тягового каната не должна превышать длины буксировочного устройства с нагрузкой на опорную тарелку или траверсу 200 Н",
        ],
      },
      {
        code: "1002032",
        text: "При каком уклоне проходов для пассажиров и персонала, используемых пешеходами, они должны быть оборудованы лестницами?",
        answers: [
          "Более 3°",
          "Более 4°",
          "Более 5°",
          "Проходы для пассажиров и персонала, используемые пешеходами, должны быть оборудованы лестницами при любом уклоне",
        ],
        correctAnswers: ["Более 5°"],
      },
      {
        code: "1002033",
        text: "Какие перечисленные размеры проходов и рабочих зон канатных дорог указаны верно? Укажите все правильные ответы.",
        answers: [
          "Высота проходов для пассажиров под транспортными путями и рабочими зонами должна быть не менее 3,0 м",
          "На рабочих площадках обслуживания подвижного состава и в местах расположения проходов для персонала, где нависают детали конструкции, достаточно прохода высотой 2 м",
          "Минимальная ширина проходов для пассажиров должна соответствовать пропускной способности дороги и быть не менее 1,25 м",
          "Контрольные зоны для предъявления проездных документов и входы для КД с креслами должны быть шириной не менее 1,2 м",
        ],
        correctAnswers: [
          "На рабочих площадках обслуживания подвижного состава и в местах расположения проходов для персонала, где нависают детали конструкции, достаточно прохода высотой 2 м",
          "Минимальная ширина проходов для пассажиров должна соответствовать пропускной способности дороги и быть не менее 1,25 м",
        ],
      },
      {
        code: "1002034",
        text: "Чем должны быть оборудованы подъездные пути для доступа пассажиров в креслах-каталках?",
        answers: [
          "Полом с горизонтальной поверхностью или поверхностью с уклоном не более 3°, исключающим скольжение кресел-каталок",
          "Пандусом с уклоном до 10° с горизонтальными площадками длиной 2 м через каждые 10 м",
          "Порогами высотой не более 0,2 м",
          "Проходами в контрольных зонах для предъявления проездных документов и входами для КД шириной не менее 1,9 м",
        ],
        correctAnswers: [
          "Полом с горизонтальной поверхностью или поверхностью с уклоном не более 3°, исключающим скольжение кресел-каталок",
        ],
      },
      {
        code: "1002035",
        text: "Как должны оборудоваться проходы и площадки для пассажиров, которые находятся выше уровня земли?",
        answers: [
          "Их необходимо выделить (обозначить)",
          "Их ограждают перилами, минимальная высота которых должна быть на 2 м выше снежного покрова",
          "Их ограждают маркировочными сетками",
          "Их ограждают перилами высотой не менее 1 м и сплошным ограждением по низу на высоту не менее 0,1 м",
        ],
        correctAnswers: [
          "Их ограждают перилами высотой не менее 1 м и сплошным ограждением по низу на высоту не менее 0,1 м",
        ],
      },
      {
        code: "1002036",
        text: "Какое максимальное расстояние допускается между краем посадочной платформы и подвижным составом на канатных дорогах с кабинами или вагонами?",
        answers: ["0,2 м", "0,15 м", "0,1 м", "0,05 м"],
        correctAnswers: ["0,05 м"],
      },
      {
        code: "1002037",
        text: "Каким должно быть расстояние по вертикали от середины передней кромки нагруженного кресла до земли с учетом снежного покрова в зоне посадки-высадки у кресельных канатных дорог?",
        answers: [
          "370 +/- 50 мм",
          "400 +/- 50 мм",
          "460 +/- 50 мм",
          "500 +/- 50 мм",
        ],
        correctAnswers: ["500 +/- 50 мм"],
      },
      {
        code: "1002038",
        text: "Какое максимальное расстояние необходимо выдерживать до земли в зоне стабилизации и в зоне безопасности?",
        answers: [
          "В зоне стабилизации - не более 1 м, в зоне безопасности - не более 2,5 м",
          "В зоне стабилизации - не более 1,5 м, в зоне безопасности - не более 3 м",
          "В зоне стабилизации - не более 2 м, в зоне безопасности - не более 3,5 м",
          "В зоне стабилизации - не более 2,5 м, в зоне безопасности - не более 4 м",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "В зоне стабилизации - не более 1,5 м, в зоне безопасности - не более 3 м",
        ],
      },
      {
        code: "1002039",
        text: "Какое из перечисленных требований в зоне посадки лыжников с посадочным конвейером на кресельных канатных дорогах с неотцепляемым подвижным составом указано верно?",
        answers: [
          "Скорость конвейера должна зависеть от скорости несуще-тягового каната и не должна превышать 2 м/с",
          "Необходимо установить длину посадочного конвейера и устройства регулировки входов и их взаимодействие так, чтобы посадка заканчивалась не дальше, чем за 1,5 м до конца ленты конвейера",
          "Вход лыжников на посадку должен быть расположен по направлению к посадочному конвейеру",
          "При неисправности автоматического управления входами использование посадочного конвейера допускается лицом, ответственным за безопасное производство работ",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Вход лыжников на посадку должен быть расположен по направлению к посадочному конвейеру",
        ],
      },
      {
        code: "1002040",
        text: "Какой знак не устанавливается в зоне посадки кресельных ППКД?",
        answers: [
          '"Поднять носки лыж"',
          '"Опустить скобу безопасности или защитный колпак"',
          '"Не прыгать из кресла"',
          '"Не раскачиваться"',
        ],
        correctAnswers: ['"Поднять носки лыж"'],
      },
      {
        code: "1002041",
        text: "Какое из перечисленных требований к наклонному съезду, устраиваемому при перевозке лыжников после зоны высадки, указано верно?",
        answers: [
          "Уклон съезда должен составлять от 10% до 15%",
          "Длина наклонного съезда должна быть достаточной для ухода лыжников из зоны возможного контакта с креслом до обеспечения безопасного расстояния с ним и составлять не менее 0,8 м",
          "Для КД с креслами с неотцепляемыми зажимами наклонный спуск должен начинаться не менее чем за 1,0 секунду до прохождения креслом оси обводного шкива",
          "Для КД с креслами с отцепляемыми зажимами наклонный спуск должен начинаться за 1,0 секунду до изменения направления движения кресел",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "1002042",
        text: "Какой знак не устанавливается в зоне высадки кресельных ППКД?",
        answers: [
          '"Езда "слаломом" запрещена"',
          '"Поднять носки лыж"',
          '"Открыть скобу безопасности или защитный колпак"',
          '"Место высадки"',
        ],
        correctAnswers: ['"Езда "слаломом" запрещена"'],
      },
      {
        code: "1002043",
        text: "Какой знак не устанавливается в зоне посадки БКД?",
        answers: [
          '"При падении немедленно покинуть буксировочную дорожку"',
          '"Взять лыжные палки в одну руку"',
          '"Запрещено применять стиль "слалом" при движении по дорожке"',
          '"Отпустить буксировочное устройство"',
        ],
        correctAnswers: ['"Отпустить буксировочное устройство"'],
      },
      {
        code: "1002044",
        text: "В каких случаях за зоной высадки устанавливают устройства контроля, автоматически останавливающие дорогу? Укажите все правильные ответы.",
        answers: [
          "Не сработало предварительное оповещение о месте высадки",
          "Пассажир задержался в месте высадки",
          "Пассажир не успел отпустить буксировочное устройство",
          "Буксировочное устройство не вернулось в исходное состояние",
        ],
        correctAnswers: [
          "Пассажир не успел отпустить буксировочное устройство",
          "Буксировочное устройство не вернулось в исходное состояние",
        ],
      },
      {
        code: "1002045",
        text: "Какой знак не устанавливается в зоне высадки БКД?",
        answers: [
          "Не выходить за пределы буксировочной дорожки",
          "Предварительное оповещение о месте высадки",
          "Отпустить буксировочное устройство",
          "Не задерживаться в месте высадки",
        ],
        correctAnswers: ["Не выходить за пределы буксировочной дорожки"],
      },
      {
        code: "1002046",
        text: "Какое из перечисленных требований к зонам посадки и высадки БКД и ББКД указано верно?",
        answers: [
          "Расстояние от тягового каната БКД со штанговыми буксировочными устройствами до поверхности снежного покрова может быть меньше длины собранного буксировочного устройства в соответствии с технической документацией",
          "В зоне высадки БКД с буксировочными устройствами барабанного типа барабаны должны проходить на высоте не менее 2,5 м от поверхности снежного покрова",
          "На БКД с буксировочными устройствами барабанного типа и самообслуживанием расстояние между опорной тарелкой или траверсой и поверхностью снежного покрова в зоне посадки должно составлять 0,6 - 1,0 м",
          "После высадки пассажира вытяжной канат может перехлестываться с конструкциями дороги и буксировочными устройствами на стороне спуска",
        ],
        correctAnswers: [
          "На БКД с буксировочными устройствами барабанного типа и самообслуживанием расстояние между опорной тарелкой или траверсой и поверхностью снежного покрова в зоне посадки должно составлять 0,6 - 1,0 м",
        ],
      },
      {
        code: "1002047",
        text: "Какой минимальной величине должна соответствовать длина счаленного участка тяговых и несуще-тяговых канатов относительно диаметра каната dk?",
        answers: ["1000 dk", "1200 dk", "1300 dk", "3000 dk"],
        correctAnswers: ["1200 dk"],
      },
      {
        code: "1002048",
        text: "Какая должна быть минимальная длина счалки при сращивании тяговых и несуще-тяговых канатов, где dk - номинальный диаметр каната?",
        answers: ["1000 dk", "1200 dk", "1300 dk", "3000 dk"],
        correctAnswers: ["1200 dk"],
      },
      {
        code: "1002049",
        text: "Каким должно быть минимальное расстояние между двумя соседними счалками тяговых и несуще-тяговых канатов относительно диаметра каната dk?",
        answers: ["1200 dk", "1300 dk", "3000 dk", "5000 dk"],
        correctAnswers: ["3000 dk"],
      },
      {
        code: "1002050",
        text: "Каким образом запрещается производить сращивание концов тягового каната ББКД?",
        answers: [
          "С применением винтового зажима",
          "Счаливанием",
          "С использованием специальных приспособлений с клиновыми замками",
        ],
        correctAnswers: ["С применением винтового зажима"],
      },
      {
        code: "1002051",
        text: "Сращивание каких канатов не допускается? Укажите все правильные ответы.",
        answers: [
          "Натяжных канатов",
          "Несуще-тяговых канатов",
          "Несущего каната",
          "Тяговых канатов",
          "Спасательного каната",
        ],
        correctAnswers: ["Натяжных канатов", "Несущего каната"],
      },
      {
        code: "1002052",
        text: "Для какой канатной дороги минимальный коэффициент запаса прочности тяговых канатов соответствует значению 4?",
        answers: [
          "Для маятниковой КД без тормоза ловителя на несущем канате",
          "Для маятниковой КД с тормозом ловителя на несущем канате",
          "Для двухканатной КД замкнутого цикла только для одного направления",
          "Для наземной КД",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Для двухканатной КД замкнутого цикла только для одного направления",
        ],
      },
      {
        code: "1002053",
        text: "Какой минимальный коэффициент запаса прочности тяговых канатов соответствует наземной канатной дороге?",
        answers: ["3,8", "4", "4,2", "4,5", "Все ответы неверны"],
        correctAnswers: ["4,2"],
      },
      {
        code: "1002054",
        text: "Какое значение должен составлять диаметр счаленных (замкнутых) спасательных канатов?",
        answers: [
          "Не менее 10 мм",
          "Не менее 15 мм",
          "Не менее 20 мм",
          "Не менее 25 мм",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не менее 15 мм"],
      },
      {
        code: "1002055",
        text: "В каком случае отношение между минимальным натяжением каната и весом единицы груженого подвижного состава рассчитывают с минимальным коэффициентом 10?",
        answers: [
          "В случае несущего каната с заякоренными концами",
          "В случае несущего каната с натяжным устройством",
          "В случае несуще-тягового каната",
          "В случае тягового каната",
          "Все ответы неверны",
        ],
        correctAnswers: ["В случае несущего каната с натяжным устройством"],
      },
      {
        code: "1002056",
        text: "При каком уменьшении диаметра несуще-тяговых канатов в результате повреждения сердечника, внутреннего износа, обмятия по сравнению с номинальным диаметром канат подлежит браковке даже при отсутствии видимых обрывов проволок?",
        answers: ["На 3%", "На 5%", "На 7%", "На 10%", "Все ответы неверны"],
        correctAnswers: ["На 10%"],
      },
      {
        code: "1002057",
        text: "В каком из перечисленных случаев несущие канаты закрытой конструкции не подлежат замене?",
        answers: [
          "На участке, длиной равной 6 dk, имеются разрывы двух и более смежных проволок наружного слоя",
          "Оборванные концы наружных проволок каната выступают",
          "На участке длиной, равной 30 dк, одна десятая часть проволок наружного слоя замка оборвана",
          "Имеется выход из замка одной и более проволок наружного слоя без ее обрыва",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "На участке длиной, равной 30 dк, одна десятая часть проволок наружного слоя замка оборвана",
        ],
      },
      {
        code: "1002058",
        text: "В каком случае проводится дефектоскопия каната?",
        answers: [
          "При остаточном удлинении каната более чем на 0,5% рабочей длины после первоначальной вытяжки в начальный период эксплуатации и выполненной пересчалки",
          "При обнаружении в канате одной или нескольких оборванных прядей",
          "При уменьшении диаметра каната двойной свивки в результате поверхностного износа или коррозии на 7% и более по сравнению с номинальным диаметром",
          "После любого внешнего воздействия (укорачивание, ремонт, удар молнии, деформирование) на несуще-тяговые, тяговые и несущие канаты перед началом эксплуатации",
        ],
        correctAnswers: [
          "После любого внешнего воздействия (укорачивание, ремонт, удар молнии, деформирование) на несуще-тяговые, тяговые и несущие канаты перед началом эксплуатации",
        ],
      },
      {
        code: "1002059",
        text: "Какое из перечисленных требований к двигателям, редукторам, трансмиссиям, применяемым на канатных дорогах, указано неверно?",
        answers: [
          "Двигатели внутреннего сгорания должны быть жестко установлены на раме и должны иметь электростартерную систему пуска с питанием от аккумуляторной батареи",
          "В помещении станции, где установлен двигатель внутреннего сгорания, должен быть обеспечен приток воздуха",
          "В трансмиссии для передачи усилий должны использоваться плоские ремни",
          "Конечные положения переключающей (сцепной) муфты должны блокироваться механически и контролироваться системой управления КД",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "В трансмиссии для передачи усилий должны использоваться плоские ремни",
        ],
      },
      {
        code: "1002060",
        text: "Каким минимальным количеством двигателей с независимыми источниками питания - основным и аварийным - оборудуются пассажирские подвесные дороги и наземные канатные дороги?",
        answers: ["Одним", "Двумя", "Тремя", "Четырьмя"],
        correctAnswers: ["Двумя"],
      },
      {
        code: "1002061",
        text: "Какая устанавливается минимальная скорость пассажирских подвесных канатных дорог и фуникулеров при плавном регулировании скорости в пределах ее паспортных величин?",
        answers: [
          "0,5 м/с",
          "0,75 м/с",
          "1,0 м/с",
          "1,5 м/с",
          "Все ответы неверны",
        ],
        correctAnswers: ["0,5 м/с"],
      },
      {
        code: "1002062",
        text: "В течение какого времени должен приводиться в действие аварийный привод?",
        answers: [
          "В течение 5 минут",
          "В течение 15 минут",
          "В течение 30 минут",
          "В течение 3 часов",
        ],
        correctAnswers: ["В течение 15 минут"],
      },
      {
        code: "1002063",
        text: "При каком увеличении скорости относительно номинальной должно срабатывать устройство контроля превышения скорости при работе на аварийном приводе на пассажирских подвесных канатных дорогах и фуникулерах?",
        answers: ["На 5%", "На 10%", "На 15%", "На 20%", "Все ответы неверны"],
        correctAnswers: ["На 20%"],
      },
      {
        code: "1002064",
        text: "Какое из перечисленных требований к приводам пассажирских подвесных дорог и наземных канатных дорог указано верно? Укажите все правильные ответы.",
        answers: [
          "Переключение приводов должно происходить при включенной КД",
          "Гидропривод должен плавно приводить в движение ППКД и НКД в обоих направлениях и также их остановку",
          "Одновременная работа аварийного и главного приводов допускается требованиями технической документации",
          "Вспомогательный привод должен обеспечивать скорость не менее чем 1/2 скорости при нормальных условиях эксплуатации",
          "Вспомогательный привод должен обеспечивать начало движения с ускорением не более 0,3 м/c² при максимальной нагрузке",
        ],
        correctAnswers: [
          "Гидропривод должен плавно приводить в движение ППКД и НКД в обоих направлениях и также их остановку",
          "Вспомогательный привод должен обеспечивать скорость не менее чем 1/2 скорости при нормальных условиях эксплуатации",
        ],
      },
      {
        code: "1002065",
        text: "Какое устройство на наземных канатных дорогах (далее - НКД) и маятниковых ППКД должно срабатывать при переезде регулировочной точки?",
        answers: [
          "Устройство контроля превышения скорости",
          "Рабочий тормоз",
          "Аварийный привод",
          "Аварийный тормоз",
        ],
        correctAnswers: ["Аварийный тормоз"],
      },
      {
        code: "1002066",
        text: "В каком случае допустимый коэффициент трения для приводных шкивов с резиновой футеровкой буксировочных канатных дорог составляет 0,22?",
        answers: [
          "При использовании фиксированных зажимов, без учета кручения каната",
          "При использовании отцепляемых зажимов с учетом кручения каната (втулки поводковых патронов)",
          "При диаметре тягового каната до 18 мм и при использовании фиксированных зажимов без учета кручения каната",
          "Во всех перечисленных случаях",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "При использовании фиксированных зажимов, без учета кручения каната",
        ],
      },
      {
        code: "1002067",
        text: "Какая устанавливается максимальная рабочая скорость движения буксировочных устройств?",
        answers: ["3 м/с", "4 м/с", "5 м/с", "6 м/с", "Все ответы неверны"],
        correctAnswers: ["4 м/с"],
      },
      {
        code: "1002068",
        text: "Какая максимальная скорость движения наземных канатных дорог составляет для систем с автоматическим управлением?",
        answers: ["5 м/с", "10 м/с", "15 м/с", "20 м/с", "Все ответы неверны"],
        correctAnswers: ["15 м/с"],
      },
      {
        code: "1002069",
        text: "Какая максимальная скорость движения двухканатных маятниковых канатных дорог с проводником при прохождении опор?",
        answers: ["6 м/с", "8,5 м/с", "10 м/с", "12 м/с", "Все ответы неверны"],
        correctAnswers: ["10 м/с"],
      },
      {
        code: "1002070",
        text: "В каком из перечисленных случаев максимальная скорость движения двухканатных кольцевых дорог без проводника составляет 6 м/с?",
        answers: [
          "При прохождении опор с одним несущим канатом",
          "При прохождении опор с двумя несущими канатами",
          "На станциях при посадке - высадке",
          "В пролетах",
          "Все ответы неверны",
        ],
        correctAnswers: ["При прохождении опор с одним несущим канатом"],
      },
      {
        code: "1002071",
        text: "В каком из перечисленных случаев максимальная скорость движения пульсирующих дорог с закрытыми неотцепляемыми кабинами составляет 1,3 м/с?",
        answers: [
          "Для полуоткрытых кабин и кресел на станциях",
          "Для полуоткрытых кабин и кресел на трассе",
          "На станциях при посадке-высадке",
          "На трассе с одним несуще-тяговым канатом",
          "Все ответы неверны",
        ],
        correctAnswers: ["Для полуоткрытых кабин и кресел на трассе"],
      },
      {
        code: "1002072",
        text: "Какая максимальная скорость движения кольцевых канатных дорог с отцепляемым подвижным составом на трассе для открытых кабин и кресел?",
        answers: ["9 м/с", "7 м/с", "6 м/с", "5 м/с", "Все ответы неверны"],
        correctAnswers: ["6 м/с"],
      },
      {
        code: "1002073",
        text: "В каком случае максимальная скорость движения для кольцевых канатных дорог (КД) с неотцепляемыми креслами составляет 2 м/с?",
        answers: [
          "При перевозке пешеходов для КД с более чем двухместными креслами",
          "При перевозке пешеходов для КД с двухместными креслами",
          "При перевозке лыжников для КД с двухместными креслами",
          "При перевозке лыжников для КД с трех- или четырехместными креслами",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "1002074",
        text: "Какая скорость устанавливается при обратном ходе пассажирских подвесных канатных дорог и наземных канатных дорог?",
        answers: [
          "Не более 0,5 м/с",
          "Не более 1,0 м/с",
          "Не более 1,5 м/с",
          "Не более 2,5 м/с",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не более 1,5 м/с"],
      },
      {
        code: "1002075",
        text: "Каким должен быть минимальный интервал времени между движущимися буксировочными устройствами ББКД?",
        answers: ["3 секунды", "4 секунды", "5 секунд", "7 секунд"],
        correctAnswers: ["5 секунд"],
      },
      {
        code: "1002076",
        text: "В каком случае контроль расстояния между единицами подвижного состава на пассажирских подвесных канатных дорогах с кольцевым движением отцепляемого на станциях подвижного состава осуществляет обслуживающий персонал?",
        answers: [
          "При скорости движения подвижного состава не более 5 м/с",
          "При неисправности посадочного конвейера",
          "Контроль расстояния между единицами подвижного состава контролируется только автоматически",
          "В случае отсутствия в зонах станции автоматических устройств контроля",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "В случае отсутствия в зонах станции автоматических устройств контроля",
        ],
      },
      {
        code: "1002077",
        text: "Каким должен быть минимальный интервал времени между движущимися буксировочными устройствами одноместных БКД?",
        answers: ["4 секунды", "5 секунд", "6 секунд", "3 секунды"],
        correctAnswers: ["4 секунды"],
      },
      {
        code: "1002078",
        text: "Каким должен быть минимальный интервал времени между движущимися буксировочными устройствами двухместных БКД?",
        answers: ["3 секунды", "4 секунды", "5 секунд", "6 секунд"],
        correctAnswers: ["6 секунд"],
      },
      {
        code: "1002079",
        text: "Каким должен быть минимальный интервал времени между движущимися буксировочными устройствами ББКД?",
        answers: ["3 секунды", "4 секунды", "5 секунд", "6 секунд"],
        correctAnswers: ["5 секунд"],
      },
      {
        code: "1002080",
        text: "В каком случае тормоза должны приводиться в действие автоматически на пассажирских подвесных канатных дорогах и наземных канатных дорогах?",
        answers: [
          "При прекращении подачи электроэнергии к главному двигателю",
          "При падении давления в гидравлических или пневматических тормозах ниже допустимого значения",
          "При размыкании в цепи безопасности",
          "Во всех перечисленных случаях",
          "Все ответы неверны",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "1002081",
        text: "Какое из перечисленных требований к направляющим роликам пассажирских подвесных канатных дорог указано верно? Укажите все правильные ответы.",
        answers: [
          "Общая глубина канавки направляющих роликов ППКД (D1 - D2) / 2 для несуще-тяговых канатов составляет 1/3 диаметра каната, но не менее 10 мм",
          "Глубина канавки в бандаже ролика должна быть не менее 1/15 от диаметра несуще-тягового каната",
          "Выступание реборды ролика над его бандажом должно быть не менее 2 мм и составлять 1/10 от диаметра несуще-тягового каната",
          "Реборды роликов для несуще-тяговых канатов одноканатных дорог с кольцевым движением при наличии новых бандажей роликов должны допускать поперечное раскачивание зажимов подвесок не менее 11°30'",
        ],
        correctAnswers: [
          "Общая глубина канавки направляющих роликов ППКД (D1 - D2) / 2 для несуще-тяговых канатов составляет 1/3 диаметра каната, но не менее 10 мм",
          "Реборды роликов для несуще-тяговых канатов одноканатных дорог с кольцевым движением при наличии новых бандажей роликов должны допускать поперечное раскачивание зажимов подвесок не менее 11°30'",
        ],
      },
      {
        code: "1002082",
        text: "Какое из перечисленных условий для обеспечения надежности прилегания каната к балансиру на пассажирских подвесных канатных дорогах является неверным?",
        answers: [
          "При превышении натяжения несуще-тягового каната на 40% нагрузка на нижний балансир должна оставаться положительной",
          "Нагрузка от несуще-тягового каната на балансир при наиболее неблагоприятных условиях нормальной работы ППКД должна быть не менее 2000 Н, а на опорный ролик - не менее 500 Н",
          "Для тяговых и несуще-тяговых канатов ППКД перегиб каната на одном ролике балансира должен быть не более 4°30'",
          "Допускается уменьшение значений величин нагрузки в 3 раза при наличии в балансире прижимных роликов, удерживающих канат от выпадения из роликов",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Допускается уменьшение значений величин нагрузки в 3 раза при наличии в балансире прижимных роликов, удерживающих канат от выпадения из роликов",
        ],
      },
      {
        code: "1002083",
        text: "В каком случае допускается не устанавливать вертикальные ролики и шкивы перед отклонением тягового каната и после него на буксировочных канатных дорогах?",
        answers: [
          "Если это обосновано проектной документацией",
          "Для тяговых канатов диаметром до 16 мм",
          "У самоустанавливающихся роликов и шкивов",
          "Не допускается ни в каком случае",
          "Все ответы неверны",
        ],
        correctAnswers: ["У самоустанавливающихся роликов и шкивов"],
      },
      {
        code: "1002084",
        text: "Какие из перечисленных значений соответствуют тяговому канату диаметром свыше 16 мм на буксировочных канатных дорогах?",
        answers: [
          "Минимальный диаметр роликов составляет 150 мм, а перегиб каната на одном ролике 9°30'",
          "Минимальный диаметр роликов составляет 200 мм, а перегиб каната на одном ролике 8°30'",
          "Минимальный диаметр роликов составляет 250 мм, а перегиб каната на одном ролике 5°30'",
          "Минимальный диаметр роликов составляет 300 мм, а перегиб каната на одном ролике 3°30'",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Минимальный диаметр роликов составляет 250 мм, а перегиб каната на одном ролике 5°30'",
        ],
      },
      {
        code: "1002085",
        text: "В каком случае разрешено использовать навесные или приставные лестницы для обслуживания балансиров вместо устройства стационарных лестниц и площадок у опор буксировочных канатных дорог?",
        answers: [
          "В случае проведения работ в присутствии лица, ответственного за безопасное производство работ",
          "У опор БКД с высотой прохождения тягового каната менее 4 м",
          "У опор БКД с высотой прохождения тягового каната менее 5 м",
          "Не допускается ни в каком случае",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "У опор БКД с высотой прохождения тягового каната менее 4 м",
        ],
      },
      {
        code: "1002086",
        text: "В каком случае разрешается выход на линию средства подвижного состава с недостаточным усилием зацепления после прохождения участка прицепления на наземной канатной дороге?",
        answers: [
          "В присутствии обслуживающего персонала",
          "В случае установки необходимых направляющих для каната и зажима",
          "В случае установки механических контрольных устройств, препятствующих отцеплению",
          "Не разрешается ни в каком случае",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не разрешается ни в каком случае"],
      },
      {
        code: "1002087",
        text: "Какое из перечисленных требований к посадочному конвейеру является верным?",
        answers: [
          "Остановка посадочного конвейера осуществляется с замедлением не более 0,5 м/с²",
          "Ширина посадочного конвейера должна быть меньше ширины подвижного состава",
          "При движении канатной дороги в обратном направлении посадочный конвейер должен двигаться с замедлением не более 0,5 м/с²",
          "Все перечисленные",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Остановка посадочного конвейера осуществляется с замедлением не более 0,5 м/с²",
        ],
      },
      {
        code: "1002088",
        text: "Какое утверждение, относящееся к ходу натяжной тележки КД, указано верно?",
        answers: [
          "Возврат в рабочее положение устройства безопасности должен производиться автоматически",
          "При срабатывании устройства безопасности должно происходить замедление КД",
          "При заякоривании несущего каната с обеих сторон натяжение каната в зоне анкерных устройств необходимо контролировать с периодичностью, предусмотренной руководством по эксплуатации",
          "Натяжные устройства при любых условиях нормальной эксплуатации КД должны достигать упоров",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "При заякоривании несущего каната с обеих сторон натяжение каната в зоне анкерных устройств необходимо контролировать с периодичностью, предусмотренной руководством по эксплуатации",
        ],
      },
      {
        code: "1002089",
        text: "Какой должен быть минимальный коэффициент запаса надежности сцепления каждого клеммного зажима несущего каната?",
        answers: ["2", "3", "4", "5", "Все ответы неверны"],
        correctAnswers: ["3"],
      },
      {
        code: "1002090",
        text: "Какому значению должна соответствовать дополнительная (резервная) длина каната для возможности перемещения несущих канатов?",
        answers: [
          "Трехкратной длине самого длинного башмака несущего каната или седловины роликовой цепи плюс 5 м",
          "Пятикратной длине самого длинного башмака несущего каната или седловины роликовой цепи плюс 3 м",
          "Шестикратной длине самого длинного башмака несущего каната или седловины роликовой цепи плюс 5 м",
          "Десятикратной длине самого длинного башмака несущего каната или седловины роликовой цепи плюс 10 м",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Шестикратной длине самого длинного башмака несущего каната или седловины роликовой цепи плюс 5 м",
        ],
      },
      {
        code: "1002091",
        text: "Какое количество полных витков должно быть предусмотрено для крепления концов несущих и натяжных канатов на тумбе?",
        answers: [
          "Не менее двух",
          "Не менее трех",
          "Не менее четырех",
          "Не менее пяти",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не менее трех"],
      },
      {
        code: "1002092",
        text: "В каком случае допускается ручное управление гидравлической системой натяжения для поддержания давления?",
        answers: [
          "На вспомогательном приводе",
          "На аварийном приводе",
          "Не допускается ни в каком случае",
          "Все ответы неверны",
        ],
        correctAnswers: ["На аварийном приводе"],
      },
      {
        code: "1002093",
        text: "Какая длина зажимных губок для фиксированных зажимов подвесных канатных дорог?",
        answers: [
          "Не менее 2d",
          "Не менее 3d",
          "Не более 3d",
          "Не менее 4d",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не менее 2d"],
      },
      {
        code: "1002094",
        text: "Какой блок считается как один отцепляемый зажим на пассажирских подвесных канатных дорогах?",
        answers: [
          "Никакой блок не может считаться за один зажим",
          "Если между центрами двух зажимов расстояние меньше 25 диаметров каната",
          "Если между центрами двух зажимов расстояние меньше 20 диаметров каната",
          "Если между центрами двух зажимов расстояние меньше 15 диаметров каната",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Если между центрами двух зажимов расстояние меньше 15 диаметров каната",
        ],
      },
      {
        code: "1002095",
        text: "Какие характеристики неотцепляемого зажима должны быть указаны в руководстве по эксплуатации?",
        answers: [
          "Номинальный диаметр каната",
          "Величина момента затяжки зажима",
          "Допустимый износ зажимных губок",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "1002096",
        text: "Какое из перечисленных дополнительных требований для отцепляемых зажимов буксировочных канатных дорог указано неверно?",
        answers: [
          "Допустимые отклонения диаметра каната и нормы износа втулок зажима должны быть указаны в технологическом регламенте",
          "Диаметр отверстия втулки зажима должен быть в пределах от 1,18d до 1,28d",
          "Для каната диаметром от 12 до 18 мм наружный диаметр втулки зажима должен составлять 40 - 46 мм",
          "Для каната диаметром от 12 до 18 мм длина втулки зажима должна быть равна 40 ± 1 мм",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Допустимые отклонения диаметра каната и нормы износа втулок зажима должны быть указаны в технологическом регламенте",
        ],
      },
      {
        code: "1002097",
        text: "В каких вагонах сопровождающий персонал должен находиться у пульта управления на возвышении по отношению к уровню пола вагона маятниковых канатных дорог?",
        answers: [
          "Вместимостью более 30 человек",
          "Вместимостью более 50 человек",
          "Вместимостью более 80 человек",
          "Не регламентируется",
          "Все ответы неверны",
        ],
        correctAnswers: ["Вместимостью более 80 человек"],
      },
      {
        code: "1002098",
        text: "Чем не должны оснащаться вагоны с сопровождающим персоналом наземных канатных дорог? Укажите все правильные ответы.",
        answers: [
          "Правилами перевозки пассажиров",
          "Указаниями о допустимом количестве пассажиров",
          "Указаниями о полезной нагрузке в килограммах",
          "Устройствами, автоматически останавливающими КД при столкновении с препятствиями на трассе",
          "Пунктом управления",
        ],
        correctAnswers: [
          "Правилами перевозки пассажиров",
          "Устройствами, автоматически останавливающими КД при столкновении с препятствиями на трассе",
        ],
      },
      {
        code: "1002099",
        text: "Какое количество пунктов управления должна иметь канатная дорога, с которых должны осуществляться ее управление и остановка, а также все виды контроля?",
        answers: [
          "Не менее одного пункта управления",
          "Не менее двух пунктов управления",
          "Не менее трех пунктов управления",
          "Не менее четырех пунктов управления",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не менее одного пункта управления"],
      },
      {
        code: "1002100",
        text: "В каких местах канатных дорог должны быть установлены индикаторные устройства?",
        answers: [
          "На пульте оператора",
          "На пункте управления",
          "На наблюдательных пунктах канатных дорог",
          "Во всех перечисленных местах",
          "Все ответы неверны",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "1002101",
        text: "Какой из перечисленных параметров КД может контролироваться перед, во время и после процесса сцепления зажимов?",
        answers: [
          "Правильное охватывание каната губками зажима",
          "Открытое положение губок зажима",
          "Отделение зажима от каната",
          "Зажимное усилие",
        ],
        correctAnswers: ["Зажимное усилие"],
      },
      {
        code: "1002102",
        text: "Какая установлена максимальная скорость ускорения при разгоне и торможении на КД на участках прицепления и отцепления?",
        answers: ["1,5 м/с²", "2,0 м/с²", "2,5 м/с²", "3,0 м/с²", "3,5 м/с²"],
        correctAnswers: ["1,5 м/с²"],
      },
      {
        code: "1002103",
        text: "Какая максимально допустимая разность скорости зажима и движущегося каната при прицеплении зажима к канату на канатных дорогах с отцепляемыми зажимами?",
        answers: [
          "0,2 м/с",
          "0,3 м/с",
          "0,4 м/с",
          "0,5 м/с",
          "Все ответы неверны",
        ],
        correctAnswers: ["0,3 м/с"],
      },
      {
        code: "1002104",
        text: "В каком месте должны быть расположены выключатели для проведения профилактических работ на пассажирских подвесных канатных дорогах и наземных канатных дорогах?",
        answers: [
          "В машинном помещении",
          "На профилактических площадках и платформах вблизи от подвижных механических устройств на всех станциях и промежуточных остановках",
          "На пультах управления подвижного состава",
          "На посту управления",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "1002105",
        text: "В каком месте допускается не располагать кнопки аварийной остановки в непосредственной близости от персонала на пассажирских подвесных канатных дорогах и наземных канатных дорогах?",
        answers: [
          "На пультах управления подвижного состава",
          "На промежуточных остановках",
          "На станциях",
          "В машинном помещении",
          "Все ответы неверны",
        ],
        correctAnswers: ["В машинном помещении"],
      },
      {
        code: "1002106",
        text: "В каких местах устанавливаются кнопки аварийной остановки на буксировочных канатных дорогах?",
        answers: [
          "На пунктах управления",
          "На лестницах к приводному и обводному шкивам",
          "На приводе",
          "В машинном помещении",
        ],
        correctAnswers: ["На пунктах управления"],
      },
      {
        code: "1002107",
        text: "Каким устройством должны быть оснащены все типы канатных дорог?",
        answers: [
          "Счетчиком моточасов",
          "Анемометром или анеморумбометром",
          "Аварийным приводом",
          "Аварийным тормозом",
        ],
        correctAnswers: ["Счетчиком моточасов"],
      },
      {
        code: "1002108",
        text: "Какое допускается максимальное значение угла поперечного раскачивания для груженых открытых кабин и кресел с учетом диапазона досягаемости рукой, ногой пассажира и лыжами?",
        answers: ["11°30'", "12°30'", "11°50'", "13°30'"],
        correctAnswers: ["11°30'"],
      },
      {
        code: "1002109",
        text: "Чему равна нагрузка на пол кабины для перевозки пассажиров стоя?",
        answers: [" 4500 Н/м²", " 4600 Н/м²", " 5500 Н/м²", " 4700 Н/м²"],
        correctAnswers: [" 4500 Н/м²"],
      },
      {
        code: "1002110",
        text: "Автоматический контроль каких режимов должен быть обеспечен на ППКД и НКД?",
        answers: [
          "Отключения при превышении скорости",
          "Срабатывания рабочих тормозов",
          "Срабатывания аварийных тормозов",
          "Всех перечисленных режимов",
          "Отслеживания замедления движения",
        ],
        correctAnswers: ["Всех перечисленных режимов"],
      },
      {
        code: "1002111",
        text: "Какие из перечисленных требований к индикаторному устройству, обеспечивающему индикацию нахождения средств подвижного состава на трассе маятниковых КД, указаны неверно?",
        answers: [
          "Индикаторное устройство должно иметь шкалу с соответствующими делениями, где расстояние от средства подвижного состава до приводной станции выражено в сантиметрах",
          "Индикаторное устройство должно распознавать направление движения, в том числе при его изменении",
          "Индикаторное устройство должно давать индикацию положения опор, начала замедления на въезде, контроля фиксированной точки, некоторых важных мест контроля въезда, а также важных мест на трассе",
          "Индикаторное устройство положения подвижного состава должно работать вне зависимости от типа используемого привода",
        ],
        correctAnswers: [
          "Индикаторное устройство должно иметь шкалу с соответствующими делениями, где расстояние от средства подвижного состава до приводной станции выражено в сантиметрах",
        ],
      },
      {
        code: "1002112",
        text: "Какие требования к размерам и размещению окон в кабине кольцевых КД указаны верно? Выберите 2 варианта ответа.",
        answers: [
          "Открывающиеся окна в кабине должны быть расположены на высоте не менее 1,1 м от пола",
          "Открывающиеся окна в кабине должны иметь конструкцию и размеры, исключающие прохождение через них шара диаметром 0,2 м",
          "Открывающиеся окна в кабине должны иметь конструкцию и размеры, исключающие прохождение через них шара диаметром 0,3 м",
          "Окна в кабине должны быть открывающимися и расположены на высоте не менее 0,8 м от пола",
        ],
        correctAnswers: [
          "Открывающиеся окна в кабине должны быть расположены на высоте не менее 1,1 м от пола",
          "Открывающиеся окна в кабине должны иметь конструкцию и размеры, исключающие прохождение через них шара диаметром 0,2 м",
        ],
      },
      {
        code: "1002113",
        text: "Какую ширину дверного проема должны иметь кабины, предназначенные для перевозки пассажиров в креслах-каталках?",
        answers: [
          "Не менее 0,8 м",
          "Не менее 0,7 м",
          "Не менее 0,6 м",
          "Не менее 0,5 м",
        ],
        correctAnswers: ["Не менее 0,8 м"],
      },
      {
        code: "1002114",
        text: "Какой класс прочности должны иметь болты для соединения ответственных деталей подвижного состава КД?",
        answers: [
          "Не менее 8,8",
          "Не менее 7,8",
          "Не менее 8,7",
          "Не менее 8,6",
        ],
        correctAnswers: ["Не менее 8,8"],
      },
      {
        code: "1002115",
        text: "Какие требования к натяжным устройствам КД указаны неверно?",
        answers: [
          "Для проведения профилактических работ натяжное устройство должно быть нагружено",
          "Коэффициент запаса надежности сцепления каждого клеммного зажима несущего каната должен быть не менее 3",
          "Конструкции контргрузов и натяжных тележек должны исключать возможность схода, зажатия, опрокидывания, наклона, закручивания",
          "Для крепления концов несущих и натяжных канатов на тумбе должно быть предусмотрено не менее трех полных витков",
        ],
        correctAnswers: [
          "Для проведения профилактических работ натяжное устройство должно быть нагружено",
        ],
      },
      {
        code: "1002116",
        text: "Какие требования к участкам отцепления-прицепления зажима и каната у НКД указаны неверно?",
        answers: [
          "Участники должны обеспечивать отцепление и прицепление зажима при увеличении нагрузки на единицу подвижного состава на 25%",
          "Участники должны обеспечивать отцепление и прицепление зажима вне зависимости от скорости движения",
          "Участники должны иметь защиту от атмосферного воздействия",
          "Все перечисленное верно",
        ],
        correctAnswers: [
          "Участники должны обеспечивать отцепление и прицепление зажима при увеличении нагрузки на единицу подвижного состава на 25%",
        ],
      },
      {
        code: "1002117",
        text: "Какие требования к расстоянию от защитного элемента до реборды ролика на трассе БКД указаны верно? Выберите 2  варианта ответа.",
        answers: [
          "Расстояние от защитного элемента до реборды ролика не должно превышать 1/4 диаметра каната",
          "Расстояние от защитного элемента до реборды ролика должно составлять не более 8 мм",
          "Расстояние от защитного элемента до реборды ролика не должно превышать 1/2 диаметра каната",
          "Расстояние от защитного элемента до реборды ролика должно составлять не более 10 мм",
        ],
        correctAnswers: [
          "Расстояние от защитного элемента до реборды ролика не должно превышать 1/4 диаметра каната",
          "Расстояние от защитного элемента до реборды ролика должно составлять не более 8 мм",
        ],
      },
      {
        code: "1002118",
        text: "Какие из перечисленных требований к башмакам несущего каната ППКД указаны неверно?",
        answers: [
          "На двухканатных дорогах нагрузка от несущего каната на опорный башмак должна оставаться положительной с учетом давления ветра снизу до 700 Па",
          "Концы башмаков каната должны иметь радиус скругления, соответствующий пятикратному диаметру несущего каната",
          "Длина радиуса скругления концов башмака должна соответствовать трехкратному диаметру каната",
          "На двухканатных дорогах нагрузка от несущего каната на опорный башмак должна оставаться положительной с учетом давления ветра снизу до 500 Па",
        ],
        correctAnswers: [
          "На двухканатных дорогах нагрузка от несущего каната на опорный башмак должна оставаться положительной с учетом давления ветра снизу до 700 Па",
        ],
      },
      {
        code: "1002119",
        text: "Какие требования предъявляются к опорным элементам несущих канатов ППКД?",
        answers: [
          "Опорные элементы должны быть регулируемыми",
          "Опорные элементы не должны препятствовать продольному раскачиванию подвижного состава",
          "Опорные элементы не должны препятствовать поперечному раскачиванию подвижного состава",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "1002120",
        text: "В каком случае должен приводиться в действие тормоз, предотвращающий самопроизвольный обратный ход дороги, автоматически?",
        answers: [
          "Только при прекращении подачи электроэнергии к главному двигателю",
          "Только при падении давления в гидравлических или пневматических тормозах ниже допустимого значения",
          "Только при размыкании в цепи безопасности",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "1002121",
        text: "На каких БКД должно быть установлено устройство контроля скорости, останавливающее КД при превышении номинальной скорости на 10%?",
        answers: [
          "На БКД при номинальной скорости свыше 3 м/с",
          "На БКД, расположенных на 5 м и выше над землей",
          "На БКД при номинальной скорости свыше 5 м/с",
          "На БКД, угол наклона которых составляет более 5°",
        ],
        correctAnswers: ["На БКД при номинальной скорости свыше 3 м/с"],
      },
      {
        code: "1002122",
        text: "Что из перечисленного должны обеспечивать устройства безопасности контроля скорости подвижного состава, если они установлены, для маятниковых КД и КД с пульсирующим режимом работы?",
        answers: [
          "Только контроль фиксированных точек",
          "Только контроль равномерного (синхронного) хода, параллельного хода",
          "Только контроль установки в нулевое положение",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "1002123",
        text: "С каким ускорением главный привод должен обеспечить пуск дороги, как порожней, так и загруженной, для всех ППКД и НКД с ручным управлением?",
        answers: [
          "С ускорением не более 0,5 м/с﻿2",
          "С ускорением не более 0,75 м/с2",
          "С ускорением не более 0,55 м/с2",
          "С ускорением не более 0,6 м/с﻿2",
        ],
        correctAnswers: ["С ускорением не более 0,5 м/с﻿2"],
      },
      {
        code: "1002124",
        text: "Чему должно быть равно отношение между минимальным натяжением несуще-тягового или тягового каната ППКД и нагрузкой на ролик балансира?",
        answers: ["Не менее 15", "Не менее 5", "Не менее 10", "Не менее 8"],
        correctAnswers: ["Не менее 15"],
      },
      {
        code: "1002125",
        text: "Чему равен минимальный коэффициент запаса прочности для спасательных и эвакуационных канатов на канатных лебедках?",
        answers: ["5", "3", "2,75", "3,75"],
        correctAnswers: ["5"],
      },
      {
        code: "1002126",
        text: "Сколько должен составлять минимальный габарит от зон досягаемости рукой до препятствий по горизонтали и вертикали, а также между зонами досягаемости рукой движущихся навстречу вагонов при эксплуатации НКД?",
        answers: ["0,1 м", "0,2 м", "0,3 м", "0,4 м"],
        correctAnswers: ["0,1 м"],
      },
      {
        code: "1002127",
        text: "Какой минимальный просвет должен быть на линии ППКД при отклонении подвижного состава в поперечном направлении на 11°30' между крайней точкой подвижного состава и иными элементами КД внутри колеи КД?",
        answers: ["0,3 м", "0,25 м", "0,15 м", "0,2 м"],
        correctAnswers: ["0,3 м"],
      },
      {
        code: "1002128",
        text: "Что из перечисленного необходимо контролировать обслуживающему КД персоналу? Выберите 2 варианта ответа.",
        answers: [
          "Минимально допустимое расстояние между единицами подвижного состава на трассе",
          "Достаточное безопасное расстояние между единицами подвижного состава на станциях",
          "Состояние путей",
          "Расписание движения составов",
        ],
        correctAnswers: [
          "Минимально допустимое расстояние между единицами подвижного состава на трассе",
          "Достаточное безопасное расстояние между единицами подвижного состава на станциях",
        ],
      },
      {
        code: "1002129",
        text: "Кто выдает документ о проверке сварных соединений, а также литых и кованых деталей канатных дорог на наличие внутренних дефектов материала неразрушающими методами контроля? Укажите все правильные ответы.",
        answers: [
          "Организация-изготовитель",
          "Эксплуатирующая организация",
          "Лаборатория неразрушающего контроля",
          "Ростехнадзор",
          "Национальное агенство контроля сварки",
        ],
        correctAnswers: [
          "Организация-изготовитель",
          "Лаборатория неразрушающего контроля",
        ],
      },
      {
        code: "1002130",
        text: "Что должен сделать владелец опасного производственного объекта (далее - ОПО) до ввода КД в эксплуатацию?",
        answers: [
          "Обеспечить проведение экспертизы промышленной безопасности КД",
          "Обеспечить проведение полного технического освидетельствования",
          "Обеспечить проведение ежегодного технического освидетельствования",
          "Отработать в тестовом режиме с полной загрузкой не менее 1 месяца",
        ],
        correctAnswers: [
          "Обеспечить проведение экспертизы промышленной безопасности КД",
        ],
      },
      {
        code: "1002131",
        text: "Что не требуется при работе комиссии, принимающей решение о возможности ввода в эксплуатацию ББКД?",
        answers: [
          "Не требуется присутствия представителя владельца",
          "Не требуется участие уполномоченного представителя федерального органа исполнительной власти в области промышленной безопасности в составе комиссии",
          "Не требуется проведения экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "Не требуется участие уполномоченного представителя федерального органа исполнительной власти в области промышленной безопасности в составе комиссии",
        ],
      },
      {
        code: "1002132",
        text: "Кем принимается решение и вносится запись в паспорт КД о вводе ее в эксплуатацию?",
        answers: [
          "Ответственным за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО",
          "Представителем территориального органа Ростехнадзора",
          "Ответственным за исправное состояние и безопасную эксплуатацию КД",
          "Оператором КД",
        ],
        correctAnswers: [
          "Ответственным за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО",
        ],
      },
      {
        code: "1002133",
        text: "При какой минимальной скорости ветра запрещается посадка пассажиров и на станциях КД осуществляется высадка всех пассажиров из подвижного состава, если такие сведения отсутствуют в паспорте?",
        answers: ["15 м/с", "20 м/с", "22 м/с", "25 м/с"],
        correctAnswers: ["15 м/с"],
      },
      {
        code: "1002134",
        text: "Кем определяются численность службы производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО, на котором используется КД, и ее структура?",
        answers: [
          "Организацией, имеющей лицензию на проведение экспертизы промышленной безопасности",
          "Организацией, эксплуатирующей КД",
          "Проектной организацией",
          "Ростехнадзором",
          "Все ответы неверны",
        ],
        correctAnswers: ["Организацией, эксплуатирующей КД"],
      },
      {
        code: "1002135",
        text: "Какой из перечисленных документов не должен храниться на рабочем месте оператора КД?",
        answers: [
          "Вахтенный журнал",
          "Журнал ремонта и осмотров или контрольные листы осмотров (акты осмотров)",
          "Производственные инструкции персонала КД",
          "Указания по действию персонала в экстренных ситуациях",
          "Руководство (руководства) по эксплуатации КД",
        ],
        correctAnswers: ["Руководство (руководства) по эксплуатации КД"],
      },
      {
        code: "1002136",
        text: "Где должны храниться паспорт КД и иная техническая документация по КД?",
        answers: [
          "На рабочем месте ответственного за безопасную эксплуатацию КД",
          "На рабочем месте оператора КД",
          "На рабочем месте лица, ответственного за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации КД",
          "У руководителя организации, эксплуатирующей КД",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "На рабочем месте ответственного за безопасную эксплуатацию КД",
        ],
      },
      {
        code: "1002137",
        text: "При каком условии КД допускается к работе по перевозке пассажиров?",
        answers: [
          "При выполнении проверки и пробного пуска КД",
          "При наличии персонала на рабочих местах",
          "При выполнении условии безопасной перевозки пассажиров на КД",
          "При всех перечисленных условиях",
        ],
        correctAnswers: ["При всех перечисленных условиях"],
      },
      {
        code: "1002138",
        text: "В каком случае оператор должен присутствовать у пульта управления КД?",
        answers: [
          "Оператор должен постоянно присутствовать у пульта управления КД",
          "Оператор должен присутствовать у пульта управления только во время запуска КД",
          "Оператор должен присутствовать у пульта управления только во время проведения спасательной операции",
          "Оператор должен присутствовать у пульта управления только когда на дороге находятся пассажиры",
        ],
        correctAnswers: [
          "Оператор должен постоянно присутствовать у пульта управления КД",
        ],
      },
      {
        code: "1002139",
        text: "Кто должен возобновлять движение дороги после ее остановки вследствие срабатывания устройства безопасности?",
        answers: [
          "Оператор с пульта управления",
          "Дежурный по станции или проводник в вагоне",
          "Ответственный за безопасную эксплуатацию КД",
          "Руководитель предприятия-владельца КД",
        ],
        correctAnswers: ["Оператор с пульта управления"],
      },
      {
        code: "1002140",
        text: "Какое действие должно быть осуществлено первым при возникновении аварии или инцидента на КД?",
        answers: [
          "Дорога должна быть немедленно остановлена",
          "Должен быть проинформирован ответственный за исправное состояние и безопасную эксплуатацию КД",
          "Должны быть проинформированы пассажиры, находящиеся на КД",
          "Должен быть введен в действие план спасательной операции",
        ],
        correctAnswers: ["Дорога должна быть немедленно остановлена"],
      },
      {
        code: "1002141",
        text: "Кто принимает решение о возобновлении дальнейшей эксплуатации или введении в действие плана мероприятий по эвакуации в результате рассмотрения причин остановки КД?",
        answers: [
          "Руководитель организации, эксплуатирующей КД",
          "Специализированная организация, выполняющая ремонт и обслуживание КД",
          "Ответственный за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации КД",
          "Ответственный за исправное состояние и безопасную эксплуатацию КД",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Ответственный за исправное состояние и безопасную эксплуатацию КД",
        ],
      },
      {
        code: "1002142",
        text: "Какое условие может не выполняться при эксплуатации ППКД и НКД в темное время суток?",
        answers: [
          "Станции посадки и высадки пассажиров должны быть обеспечены эксплуатационным освещением и, в случае его отказа, аварийным освещением",
          "Эксплуатационное и аварийное освещение должно обеспечивать освещение подвижного состава на подходах к станции и в зонах замедления подвижного состава",
          "Подвижной состав ППКД и НКД, кроме кресел, должен иметь внутреннее освещение",
          "Должны быть освещены фундаменты станций и опор",
        ],
        correctAnswers: ["Должны быть освещены фундаменты станций и опор"],
      },
      {
        code: "1002143",
        text: "Какое условие должно быть выполнено при эксплуатации БКД в темное время суток?",
        answers: [
          "Лыжная дорожка БКД должна быть освещена на всем протяжении",
          "Станции посадки и высадки пассажиров должны быть обеспечены естественным освещением и, в случае его отсутствия, искусственным освещением",
          "Должны быть освещены фундаменты станций и опор",
          "Должны быть освещены части опор, мимо которых проходит подвижной состав",
        ],
        correctAnswers: [
          "Лыжная дорожка БКД должна быть освещена на всем протяжении",
        ],
      },
      {
        code: "1002144",
        text: "В каком случае допускается эксплуатация канатной дороги?",
        answers: [
          "В случае отсутствия на рабочем месте ответственного за исправное состояние и безопасную эксплуатацию",
          "В случае отсутствия ответственных лиц и (или) персонала, прошедших соответственно аттестацию в области промышленной безопасности и проверку знаний, необходимых для эксплуатации КД, ее осмотра и технического обслуживания",
          "Если истек срок ежегодного или полного технического освидетельствования",
          "Если истек назначенный срок эксплуатации",
        ],
        correctAnswers: [
          "В случае отсутствия на рабочем месте ответственного за исправное состояние и безопасную эксплуатацию",
        ],
      },
      {
        code: "1002145",
        text: "Какие действия из перечисленных включает в себя ревизия при эксплуатации КД? Укажите все правильные ответы.",
        answers: [
          "Чистка, консервация, смазка деталей",
          "Замена деталей",
          "Измерение фактического состояния деталей, узлов, конструкций КД",
          "Контроль и определение фактического состояния в целом КД",
          "Регулировка",
        ],
        correctAnswers: [
          "Измерение фактического состояния деталей, узлов, конструкций КД",
          "Контроль и определение фактического состояния в целом КД",
        ],
      },
      {
        code: "1002146",
        text: "Где проводится проверка знаний персонала канатной дороги?",
        answers: [
          "В центральной или территориальной комиссии Ростехнадзора",
          "В комиссии организации (предприятия), эксплуатирующей канатную дорогу",
          "В комиссии специализированной организации",
          "В комиссии учебного комбината",
        ],
        correctAnswers: [
          "В комиссии организации (предприятия), эксплуатирующей канатную дорогу",
        ],
      },
      {
        code: "1002147",
        text: "Каким требованиям должен отвечать персонал КД по ремонту, обслуживанию и надзору за пассажирами?",
        answers: [
          "Персонал должен иметь высшее образование и пройти стажировку в течение 3-х месяцев на действующей КД",
          "Персонал должен достигнуть возраста 18 лет, не иметь медицинских противопоказаний и пройти проверку знаний в объеме соответствующих производственных инструкций",
          "Персонал должен иметь высшее образование и стаж работы ИТР не менее 3 лет",
          "Персонал должен иметь среднее техническое образование",
        ],
        correctAnswers: [
          "Персонал должен достигнуть возраста 18 лет, не иметь медицинских противопоказаний и пройти проверку знаний в объеме соответствующих производственных инструкций",
        ],
      },
      {
        code: "1002148",
        text: "Какой стаж работы специалистом на канатной дороге должен иметь ответственный за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации КД?",
        answers: [
          "Не менее 1 года",
          "Не менее 3 лет",
          "Не менее 5 лет",
          "Не менее 6 лет",
          "Все ответы неверны",
        ],
        correctAnswers: ["Не менее 3 лет"],
      },
      {
        code: "1002149",
        text: "В течение какого срока ответственный за исправное состояние и безопасную эксплуатацию КД должен проходить стажировку на действующей КД того же типа при отсутствии опыта работы на КД?",
        answers: [
          "В течение срока не менее 3 месяцев",
          "В течение срока не менее 6 месяцев",
          "В течение срока не менее 9 месяцев",
          "В течение срока не менее года",
          "Все ответы неверны",
        ],
        correctAnswers: ["В течение срока не менее 3 месяцев"],
      },
      {
        code: "1002150",
        text: "Какие из перечисленных обязанностей возлагаются на лицо, ответственное за исправное состояние и безопасную эксплуатацию КД?",
        answers: [
          "Обеспечение выполнения персоналом своих обязанностей согласно инструкции",
          "Обеспечение обслуживающего персонала нормативно-технической документацией",
          "Проведение тренировок вспомогательного персонала, привлекаемого при проведении эвакуации и борьбы с пожаром",
          "Все перечисленные обязанности",
        ],
        correctAnswers: ["Все перечисленные обязанности"],
      },
      {
        code: "1002151",
        text: "Какому требованию должен отвечать оператор, допускаемый к самостоятельной работе?",
        answers: [
          "Иметь высшее образование и пройти стажировку в течение 3 месяцев на действующей канатной дороге",
          "Иметь высшее образование и стаж работы на КД того же типа не менее 3 лет",
          "Должен пройти стажировку на рабочем месте сроком не менее 2 недель. При этом во время стажировки должны быть отработаны все возможные виды отказов КД",
          "Должен иметь допуск к работам на высоте и иметь опыт таких работ не менее 3 лет",
        ],
        correctAnswers: [
          "Должен пройти стажировку на рабочем месте сроком не менее 2 недель. При этом во время стажировки должны быть отработаны все возможные виды отказов КД",
        ],
      },
      {
        code: "1002152",
        text: "С какой периодичностью проводятся повторные проверки знаний персонала?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 3 года",
          "Не реже 1 раза в 5 лет",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "1002153",
        text: "Кому в своей работе подчиняется вспомогательный персонал канатной дороги (дежурные по станции, контролеры, проводники)?",
        answers: [
          "Ответственному за соблюдение требований промышленной безопасности на ОПО",
          "Ответственному за исправное состояние и безопасную эксплуатацию канатной дороги",
          "Оператору канатной дороги",
          "Все ответы неверны",
        ],
        correctAnswers: ["Оператору канатной дороги"],
      },
      {
        code: "1002154",
        text: "Какая из перечисленных обязанностей относится к вспомогательному персоналу канатной дороги?",
        answers: [
          "Осуществлять пуск и остановку КД",
          "Знать порядок ремонта и обслуживания КД",
          "Знать устройство и принципы работы КД и ее отдельных узлов и механизмов",
          "Делать записи в вахтенном журнале учета работы КД и передачи смены",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "1002155",
        text: "При перевозке двух детей, рост которых не превышает 1,25 м, как они должны располагаться на кресельной КД относительно сопровождающего?",
        answers: [
          "Перевозка двух детей с одним сопровождающим запрещена",
          "Дети должны располагаться справа от сопровождающего, при этом между ними не должно быть пустого места",
          "Дети должны располагаться слева от сопровождающего, при этом между ними не должно быть пустого места",
          "Дети должны располагаться по обе стороны от сопровождающего, при этом между ними не должно быть пустого места",
        ],
        correctAnswers: [
          "Дети должны располагаться по обе стороны от сопровождающего, при этом между ними не должно быть пустого места",
        ],
      },
      {
        code: "1002156",
        text: "Какое из перечисленных условий для начала движения КД указано неверно?",
        answers: [
          "Канатная дорога остановлена",
          "Электрооборудование управления движением установлено в исходное положение",
          "Рабочий тормоз открыт",
          "Получены сигналы готовности со всех станций",
          "Все ответы неверны",
        ],
        correctAnswers: ["Рабочий тормоз открыт"],
      },
      {
        code: "1002157",
        text: "Какое из перечисленных требований к органам управления КД указано верно?",
        answers: [
          "Орган управления аварийной остановкой должен возвращаться в исходное положение персоналом, приводя к запуску КД",
          "Переключение из одного режима движения в другой и от одного вида управления к другому должно производиться кратковременно на запущенной КД",
          "Орган управления аварийной остановкой должен останавливать КД путем наложения тормоза на приводной шкив",
          "Одновременно может быть задействовано лишь 2 вида управления",
        ],
        correctAnswers: [
          "Орган управления аварийной остановкой должен останавливать КД путем наложения тормоза на приводной шкив",
        ],
      },
      {
        code: "1002158",
        text: 'Какая цветовая индикация индикаторных устройств, за исключением определенных случаев, имеет значение "нейтрально"? Укажите все правильные ответы.',
        answers: ["Белый", "Синий", "Черный", "Зеленый", "Желтый"],
        correctAnswers: ["Белый", "Черный"],
      },
      {
        code: "1002159",
        text: "Какое значение имеет синяя цветовая индикация индикаторных устройств, за исключением определенных случаев?",
        answers: [
          "Вынужденное отключение функций безопасности",
          "Аварийный случай",
          "Отклонение от нормы",
          "Норма, безопасно",
          "Нейтрально",
        ],
        correctAnswers: ["Вынужденное отключение функций безопасности"],
      },
      {
        code: "1002160",
        text: "Кто принимает решение о возобновлении дальнейшей эксплуатации канатной дороги в результате рассмотрения причин ее остановки?",
        answers: [
          "Технический руководитель",
          "Ответственный за исправное состояние и безопасную эксплуатацию канатной дороги",
          "Начальник ремонтной бригады",
          "Комиссия эксплуатирующей организации",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Ответственный за исправное состояние и безопасную эксплуатацию канатной дороги",
        ],
      },
      {
        code: "1002161",
        text: "Какие из перечисленных требований к нанесению надписей и информационным знакам на КД указаны неверно?",
        answers: [
          "Опоры КД нумеруются по порядку, начиная с верхней опоры",
          "На элементах КД должны быть нанесены четкие и нестираемые надписи о видах опасности",
          "Информационные знаки следует устанавливать в местах КД, где существует угроза опасности",
          "Опоры КД нумеруются по порядку, начиная с нижней опоры",
        ],
        correctAnswers: [
          "Опоры КД нумеруются по порядку, начиная с верхней опоры",
        ],
      },
      {
        code: "1002162",
        text: "В каком случае работа сигнала готовности на пульте управления при эксплуатации ППКД и НКД неисправна?",
        answers: [
          "Только если сигнал готовности не отключается сигналом остановки КД",
          "Только если сигнал готовности действует в системе управления при готовности КД к работе",
          "Только если сигнал готовности гаснет самостоятельно при не поступлении по истечении 30 секунд сигнала начала движения",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: [
          "Только если сигнал готовности не отключается сигналом остановки КД",
        ],
      },
      {
        code: "1002163",
        text: "Какое допускается значение замедления на приводном шкиве при аварийной остановке маятниковой КД?",
        answers: [
          "Не более 3 м/с﻿2",
          "Не более 4 м/с﻿2",
          "Не более 3,5 м/с﻿2",
          "Не более 5 м/с﻿2",
        ],
        correctAnswers: ["Не более 3 м/с﻿2"],
      },
      {
        code: "1002164",
        text: "Какое допускается значение замедления на приводном шкиве при остановке ППКД в нормальном (рабочем) режиме?",
        answers: [
          "Не более 1,0 м/с﻿2",
          "Не более 2,0 м/с﻿2",
          "Не более 1,5 м/с﻿2",
          "Не более 1,8 м/с﻿2",
        ],
        correctAnswers: ["Не более 1,0 м/с﻿2"],
      },
      {
        code: "1002165",
        text: "Чему должно быть равно расстояние между двумя следующими друг за другом буксировочными устройствами? Выберите 2 варианта ответа.",
        answers: [
          "Не менее чем на 10% больше, чем длина полностью вытянутого буксировочного каната барабанного устройства",
          "На 30% больше, чем длина полностью вытянутой буксировочной штанги",
          "Не менее чем на 5% больше, чем длина полностью вытянутого буксировочного каната барабанного устройства",
          "На 20% больше, чем длина полностью вытянутой буксировочной штанги",
        ],
        correctAnswers: [
          "Не менее чем на 10% больше, чем длина полностью вытянутого буксировочного каната барабанного устройства",
          "На 30% больше, чем длина полностью вытянутой буксировочной штанги",
        ],
      },
      {
        code: "1002166",
        text: "Какое должно быть номинальное значение интервала времени прибытия на станцию подвижного состава у ППКД с отцепляемым составом?",
        answers: [
          "Не менее 5 секунд",
          "Не менее 4 секунд",
          "Не менее 3 секунд",
          "Не нормируется",
        ],
        correctAnswers: ["Не менее 5 секунд"],
      },
      {
        code: "1002167",
        text: "Какую величину составляет максимально допустимая скорость для БКД с промежуточными опорами?",
        answers: ["4 м/с", "5 м/с", "6 м/с", "4,5 м/с"],
        correctAnswers: ["4 м/с"],
      },
      {
        code: "1002168",
        text: "С каким ускорением должно осуществляться начало движения БКД вне зависимости от нагрузки?",
        answers: [
          "С ускорением не более 0,4 м/с﻿2",
          "С ускорением не более 0,5 м/с2",
          "С ускорением не более 0,6 м/с﻿2",
          "С ускорением не более 0,7 м/с﻿2",
        ],
        correctAnswers: ["С ускорением не более 0,4 м/с﻿2"],
      },
      {
        code: "1002169",
        text: "Чему должен быть равен коэффициент запаса надежности сцепления тягового и несуще-тягового канатов с приводным шкивом?",
        answers: [
          "Не менее 1,25",
          "Не менее 1,1",
          "Не менее 1,2",
          "Не менее 0,75",
        ],
        correctAnswers: ["Не менее 1,25"],
      },
      {
        code: "1002170",
        text: "В каких случаях при эксплуатации КД устанавливаются защитные сетки? Выберите 2 варианта ответа.",
        answers: [
          "При перепаде высот в зоне высадки более чем на 1 м в начале наклонного участка",
          "При расстоянии до земли более 3 м",
          "При перепаде высот в зоне высадки более чем на 0,8 м в начале наклонного участка",
          "При расстоянии до земли более 2 м",
        ],
        correctAnswers: [
          "При перепаде высот в зоне высадки более чем на 1 м в начале наклонного участка",
          "При расстоянии до земли более 3 м",
        ],
      },
      {
        code: "1002171",
        text: "На каком расстоянии от подвижного состава должны быть размещены неподвижные конструкции устройств регулировки входов (турникеты) у КД?",
        answers: [
          "На расстоянии не менее 0,6 м",
          "На расстоянии не менее 0,5 м",
          "На расстоянии не менее 0,4 м",
          "На расстоянии не менее 0,3 м",
        ],
        correctAnswers: ["На расстоянии не менее 0,6 м"],
      },
      {
        code: "1002172",
        text: "Сколько должна составлять длина зоны посадки пешеходов и лыжников у КД?",
        answers: [
          "От 1,5 до 2,5 м",
          "От 2,5 до 3,5 м",
          "От 3,5 до 4,0 м",
          "От 2,5 до 5,0 м",
        ],
        correctAnswers: ["От 2,5 до 3,5 м"],
      },
      {
        code: "1002173",
        text: "В каких местах БКД должны быть установлены ограждения для предотвращения травмирования персонала?",
        answers: [
          "Только со стороны буксировочных устройств",
          "Только со стороны натяжного груза",
          "Только со стороны других подвижных частей, механизмов и элементов БКД при их неконтролируемом движении в зонах станций БКД",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "1002174",
        text: "На каком минимальном расстоянии над поверхностью снежного покрова по всей длине трассы должна обеспечить прохождение опорных тарелок и траверс высота прохождения тягового каната у БКД с барабанными буксировочными устройствами?",
        answers: ["2,3 м", "2,1 м", "1,8 м", "2,2 м"],
        correctAnswers: ["2,3 м"],
      },
      {
        code: "1002175",
        text: "С чем допускаются пересечения БКД на одном уровне?",
        answers: [
          "Пересечение БКД с трассами для катания и путями передвижения людей и транспорта на одном уровне не допускается",
          "Пересечение БКД на одном уровне не допускается только с трассами для катания",
          "Пересечение БКД на одном уровне не допускается только с путями передвижения людей",
          "Пересечение БКД на одном уровне не допускается только с путями передвижения транспорта",
        ],
        correctAnswers: [
          "Пересечение БКД с трассами для катания и путями передвижения людей и транспорта на одном уровне не допускается",
        ],
      },
      {
        code: "1002176",
        text: "Какое значение не должно превышать максимальное расстояние от низшей точки подвижного состава до поверхности земли или воды для ППКД с закрытым подвижным составом?",
        answers: ["60 м", "70 м", "80 м", "75 м"],
        correctAnswers: ["60 м"],
      },
      {
        code: "1002177",
        text: "Какие упоры должен иметь узел соединения буксировочного устройства с тяговым канатом?",
        answers: [
          "Упоры, ограничивающие продольное качание жесткой части устройства на угол +/- 80° от вертикали",
          "Упоры, ограничивающие продольное качание жесткой части устройства на угол +/- 70° от вертикали",
          "Упоры, ограничивающие продольное качание жесткой части устройства на угол +/- 50° от вертикали",
          "Упоры, ограничивающие продольное качание жесткой части устройства на угол +/- 60° от вертикали",
        ],
        correctAnswers: [
          "Упоры, ограничивающие продольное качание жесткой части устройства на угол +/- 80° от вертикали",
        ],
      },
      {
        code: "1002178",
        text: "Что из перечисленного не относится к регламентным работам?",
        answers: [
          "Профилактическое обслуживание",
          "Проверки и ревизии",
          "Текущий ремонт",
          "Модернизация и реконструкция",
        ],
        correctAnswers: ["Модернизация и реконструкция"],
      },
      {
        code: "1002179",
        text: "Кем проводится ревизия при эксплуатации КД?",
        answers: [
          "Эксплуатирующей организацией",
          "Специализированной организацией",
          "Проектной организацией",
          "Ростехнадзором",
          "Все ответы неверны",
        ],
        correctAnswers: ["Эксплуатирующей организацией"],
      },
      {
        code: "1002180",
        text: "В какие сроки проводится проверка средств связи на канатной дороге?",
        answers: ["Ежедневно", "Еженедельно", "Ежемесячно", "Ежегодно"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "1002181",
        text: "Какие показатели не должны контролироваться ежедневно во время эксплуатации КД?",
        answers: [
          "Плавность работы привода, шкивов и роликов на станциях",
          "Прохождение подвижного состава по станции",
          "Изменение климатических условий",
          "Отсутствие повреждений подвижного состава",
          "Функционирование всех кнопок остановки",
        ],
        correctAnswers: ["Функционирование всех кнопок остановки"],
      },
      {
        code: "1002182",
        text: "В какие сроки проводится проверка работоспособности аварийного двигателя, контроль уровней охлаждающей жидкости, масла, топлива?",
        answers: ["Ежедневно", "Еженедельно", "Ежемесячно", "Ежегодно"],
        correctAnswers: ["Еженедельно"],
      },
      {
        code: "1002183",
        text: "В какие сроки проводится проверка соединений канатов, крепление концов канатов?",
        answers: ["Ежедневно", "Еженедельно", "Ежемесячно", "Ежегодно"],
        correctAnswers: ["Ежемесячно"],
      },
      {
        code: "1002184",
        text: "В каком случае должна быть проведена ежемесячная ревизия КД?",
        answers: [
          "Если КД не эксплуатировали более недели",
          "Если КД не эксплуатировали более одного месяца",
          "Если КД не эксплуатировали более полугода",
          "Если КД не эксплуатировали более года",
        ],
        correctAnswers: ["Если КД не эксплуатировали более одного месяца"],
      },
      {
        code: "1002185",
        text: "Какая ревизия должна быть проведена, если КД не эксплуатировали более полугода?",
        answers: [
          "Ежедневная ревизия",
          "Еженедельная ревизия",
          "Ежемесячная ревизия",
          "Ежегодная ревизия",
          "Все ответы неверны",
        ],
        correctAnswers: ["Ежегодная ревизия"],
      },
      {
        code: "1002186",
        text: "В какие сроки проводится проверка на наличие трещин всех зажимов подвижного состава КД неразрушающими методами контроля?",
        answers: [
          "Ежегодно, во время технического освидетельствования",
          "Во время проведения экспертизы промышленной безопасности перед вводом в эксплуатацию, а затем после окончания установленного срока службы",
          "Через 10 лет после начала эксплуатации (с учетом предшествующей эксплуатации зажима) и далее через каждые 2 года",
          "Через 3 года в первые 15 лет эксплуатации и далее ежегодно",
        ],
        correctAnswers: [
          "Через 10 лет после начала эксплуатации (с учетом предшествующей эксплуатации зажима) и далее через каждые 2 года",
        ],
      },
      {
        code: "1002187",
        text: "В какие сроки проводится повторная магнитная дефектоскопия канатов?",
        answers: [
          "Ежегодно, во время технического освидетельствования",
          "Во время проведения экспертизы промышленной безопасности перед вводом в эксплуатацию, а затем после окончания установленного срока службы КД",
          "Через 10 лет после начала эксплуатации (с учетом предшествующей эксплуатации зажима) и далее через каждые 2 года",
          "Через каждые 3 года в первые 15 лет эксплуатации и далее ежегодно",
        ],
        correctAnswers: [
          "Через каждые 3 года в первые 15 лет эксплуатации и далее ежегодно",
        ],
      },
      {
        code: "1002188",
        text: "С какой периодичностью осуществляют перестановку зажимов КД с неотцепляемым подвижным составом?",
        answers: [
          "Каждые 100 моточасов функционирования КД",
          "Каждые 200 моточасов функционирования КД",
          "Каждые 300 моточасов функционирования КД",
          "Каждые 500 моточасов функционирования КД",
          "Все ответы неверны",
        ],
        correctAnswers: ["Каждые 200 моточасов функционирования КД"],
      },
      {
        code: "1002189",
        text: "В какие сроки проводятся испытания на стягивание для отцепляемых зажимов?",
        answers: [
          "После отработки 18000 моточасов, но не позднее чем через 10 лет",
          "После отработки 13000 моточасов, но не позднее чем через 10 лет",
          "После отработки 5000 моточасов, но не позднее чем через 3 года",
          "После отработки 9000 моточасов, но не позднее чем через 5 лет",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "После отработки 9000 моточасов, но не позднее чем через 5 лет",
        ],
      },
      {
        code: "1002190",
        text: "С кем согласовывается изменение конструкции отдельных элементов или паспортных характеристик КД?",
        answers: [
          "С территориальным органом Ростехнадзора",
          "С экспертной организацией",
          "С организацией, выполнившей монтаж КД",
          "С разработчиком проекта, а в случае его отсутствия - со специализированной организацией",
        ],
        correctAnswers: [
          "С разработчиком проекта, а в случае его отсутствия - со специализированной организацией",
        ],
      },
      {
        code: "1002191",
        text: "Какой документ устанавливает методы контроля и диагностики во время эксплуатации КД и при техническом освидетельствовании?",
        answers: [
          "Паспорт КД",
          "Руководство по эксплуатации КД",
          "Правила безопасности пассажирских канатных дорог и фуникулеров",
          "Правила проведения экспертизы промышленной безопасности",
        ],
        correctAnswers: ["Руководство по эксплуатации КД"],
      },
      {
        code: "1002192",
        text: "В какие сроки проводится первое полное техническое освидетельствование?",
        answers: [
          "Через год после ввода в эксплуатацию",
          "После отработки 22500 часов с момента первого ввода в эксплуатацию, но не позднее чем через 15 лет",
          "После отработки 15000 часов после первого, но не позднее чем через 10 лет",
          "После отработки 7500 часов, но не позднее чем через 5 лет",
        ],
        correctAnswers: [
          "После отработки 22500 часов с момента первого ввода в эксплуатацию, но не позднее чем через 15 лет",
        ],
      },
      {
        code: "1002193",
        text: "В каком случае проводятся статические испытания?",
        answers: [
          "При проведении ежегодного технического освидетельствования",
          "При проведении ежемесячной ревизии",
          "При проведении еженедельной проверки",
          "При проведении полного технического освидетельствования",
        ],
        correctAnswers: [
          "При проведении полного технического освидетельствования",
        ],
      },
      {
        code: "1002194",
        text: "В каком случае проводятся динамические испытания?",
        answers: [
          "При проведении ежегодного технического освидетельствования",
          "При проведении ежемесячной ревизии",
          "При проведении еженедельной проверки",
          "При проведении пробного пуска",
        ],
        correctAnswers: [
          "При проведении ежегодного технического освидетельствования",
        ],
      },
      {
        code: "1002195",
        text: "Какой вид проверки проводится после ремонта приводов и расчетных элементов металлоконструкций с применением сварки?",
        answers: [
          "Экспертиза промышленной безопасности",
          "Внеочередное техническое освидетельствование",
          "Ежегодная ревизия",
          "Динамические испытания",
        ],
        correctAnswers: ["Внеочередное техническое освидетельствование"],
      },
      {
        code: "1002196",
        text: "Кем проводится техническое освидетельствование КД?",
        answers: [
          "Специализированной организацией",
          "Проектной организацией",
          "Эксплуатирующей организацией",
          "Ростехнадзором",
          "Все ответы неверны",
        ],
        correctAnswers: ["Специализированной организацией"],
      },
      {
        code: "1002197",
        text: "Куда заносятся сведения о результатах и сроке следующего технического освидетельствования?",
        answers: [
          "В вахтенный журнал",
          "В журнал ремонта и осмотров",
          "В паспорт канатной дороги",
          "В приказ о вводе канатной дороги в эксплуатацию",
        ],
        correctAnswers: ["В паспорт канатной дороги"],
      },
      {
        code: "1002198",
        text: "В каком случае КД не подлежит экспертизе промышленной безопасности?",
        answers: [
          "В случае отсутствия в технической документации данных о сроке службы КД, если фактический срок ее службы превышает пятнадцать лет",
          "До начала применения КД на ОПО",
          "В случае истечения срока службы или при превышении количества циклов нагрузки КД, установленных организацией-изготовителем",
          "В случае отсутствия в технической документации данных о сроке службы КД, если фактический срок ее службы превышает двадцать лет",
        ],
        correctAnswers: [
          "В случае отсутствия в технической документации данных о сроке службы КД, если фактический срок ее службы превышает пятнадцать лет",
        ],
      },
      {
        code: "1002199",
        text: "Какие из перечисленных специальных требований для фиксированных зажимов КД указаны неверно?",
        answers: [
          "Зажимы должны охватывать канат настолько, чтобы расстояние между обоими концами зажимных губок не превышало 1,75d",
          "Зажимы должны охватывать канат настолько, чтобы расстояние между обоими концами зажимных губок не превышало 0,75d",
          "Зажимное усилие губок, создаваемое тарельчатыми пружинами, не должно снижаться более чем на 15% при выходе из строя отдельных тарельчатых пружин",
          "При отклонениях номинального диаметра каната от -10% до +6% величина закрытия зажимных губок до их соприкосновения должна составлять не менее 1 мм",
        ],
        correctAnswers: [
          "Зажимы должны охватывать канат настолько, чтобы расстояние между обоими концами зажимных губок не превышало 1,75d",
        ],
      },
      {
        code: "1002200",
        text: "Каким должен быть коэффициент запаса надежности сцепления для винтовых зажимов КД?",
        answers: ["3,0", "2,5", "1,5", "1,75"],
        correctAnswers: ["3,0"],
      },
      {
        code: "1002201",
        text: "Кто утверждает правила перевозки пассажиров и багажа канатной дорогой?",
        answers: [
          "Организация, имеющая лицензию на проведение экспертизы промышленной безопасности",
          "Эксплуатирующая организация",
          "Проектная организация",
          "Ростехнадзор",
          "Все ответы неверны",
        ],
        correctAnswers: ["Эксплуатирующая организация"],
      },
      {
        code: "1002202",
        text: "На какие типы дорог распространяются требования к организации и проведению эвакуационных мероприятий?",
        answers: [
          "На маятниковые дороги и фуникулеры",
          "На все типы канатных дорог",
          "На буксировочные канатные дороги (БКД) и безопорные буксировочные канатные дороги (ББКД)",
          "На пассажирские подвесные канатные дороги (ППКД) и наземные канатные дороги (НКД)",
        ],
        correctAnswers: [
          "На пассажирские подвесные канатные дороги (ППКД) и наземные канатные дороги (НКД)",
        ],
      },
      {
        code: "1002203",
        text: "Кто должен разработать план мероприятий по эвакуации пассажиров для КД и обеспечить его исполнение?",
        answers: [
          "Ответственный за исправное состояние и безопасную эксплуатацию КД",
          "Ответственный за соблюдение требований промышленной безопасности на ОПО",
          "Комиссия эксплуатирующей организации",
          "Оператор канатной дороги",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Ответственный за исправное состояние и безопасную эксплуатацию КД",
        ],
      },
      {
        code: "1002204",
        text: "В каком случае в плане мероприятий по эвакуации пассажиров может не предусматриваться спасение людей методом эвакуации с подвижного состава?",
        answers: [
          "Если расстояние от низа подвижного состава до земли не превышает 3 м",
          "Если в кабине или вагоне находится сопровождающий",
          "Если на КД предусмотрена доставка пассажиров на конечные станции при любом виде отказов или аварий",
          "Если в кабине или вагоне имеются эвакуационные парашюты",
        ],
        correctAnswers: [
          "Если на КД предусмотрена доставка пассажиров на конечные станции при любом виде отказов или аварий",
        ],
      },
      {
        code: "1002205",
        text: "Какой максимальный срок установлен для проведения эвакуации на канатной дороге?",
        answers: ["15 минут", "3 часа", "4 часа", "5 часов"],
        correctAnswers: ["4 часа"],
      },
      {
        code: "1002206",
        text: "В какой срок пассажиры должны быть оповещены об обстоятельствах сложившейся нештатной ситуации?",
        answers: [
          "В течение 10 минут с момента ее возникновения",
          "В течение 15 минут с момента ее возникновения",
          "В течение 30 минут с момента ее возникновения",
          "В течение 4 часов с момента ее возникновения",
        ],
        correctAnswers: ["В течение 15 минут с момента ее возникновения"],
      },
      {
        code: "1002207",
        text: "Каким методом допускается информировать пассажиров о сложившейся нештатной ситуации?",
        answers: [
          "С поверхности земли персоналом, направленным для этого и имеющим ручной громкоговоритель",
          "Громкоговорителями на линейных опорах",
          "Системами оповещения в подвижном составе или проводниками в кабинах и вагонах с сопровождением",
          "Всеми перечисленными способами",
        ],
        correctAnswers: ["Всеми перечисленными способами"],
      },
      {
        code: "1002208",
        text: "Какие меры безопасности предпринимаются при прохождении трассы для перемещения пассажиров БКД?",
        answers: [
          "Трасса для перемещения пассажиров БКД на мостиках должна иметь сплошное ограждение в виде стенки высотой не менее 1,1 м от снежного покрытия",
          "Трассу необходимо оградить сеткой, высота которой должна быть на 1 м выше снежного покрова",
          "Трассу необходимо выделить (обозначить)",
          "Ничего не предпринимается",
        ],
        correctAnswers: [
          "Трасса для перемещения пассажиров БКД на мостиках должна иметь сплошное ограждение в виде стенки высотой не менее 1,1 м от снежного покрытия",
        ],
      },
      {
        code: "1002209",
        text: "С какой периодичностью должна проводиться учебная эвакуация в месте КД, где эвакуация пассажиров наиболее затруднена?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в 1,5 года",
          "Не реже 1 раза в 2 года",
          "Не реже 1 раза в 3 года",
        ],
        correctAnswers: ["Не реже 1 раза в год"],
      },
      {
        code: "1002210",
        text: "Какое из перечисленных требований к процессу эвакуации пассажиров с подвижного состава КД указано неверно?",
        answers: [
          "КД должна быть оснащена осветительным оборудованием, позволяющим проводить эвакуацию в условиях плохой видимости",
          "Снятие пассажиров с подвижного состава на землю осуществляют только одной бригадой спасателей",
          "Во время снятия пассажиров КД должна быть остановлена",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Снятие пассажиров с подвижного состава на землю осуществляют только одной бригадой спасателей",
        ],
      },
      {
        code: "1002211",
        text: "Какая из перечисленных документаций поставляется (прилагается) с паспортом канатной дороги?",
        answers: [
          "Ведомость на запчасти и инструменты",
          "Чертежи быстроизнашивающихся деталей",
          "Инструкции по счалке и заливке легкоплавким сплавом в муфты канатов",
          "Вся перечисленная документация",
          "Все ответы неверны",
        ],
        correctAnswers: ["Вся перечисленная документация"],
      },
    ],
    1003: [
      {
        code: "1003000",
        text: "В соответствии с требованиями каких из перечисленных документов должны осуществляться транспортировка к месту монтажа, а также хранение оборудования ГПКД, узлов и деталей?",
        answers: [
          'ФНП "Правила безопасности грузовых подвесных канатных дорог"',
          "Внутренних правил перевозчика",
          "Инструкции изготовителя",
          "Проектной (конструкторской) документации",
        ],
        correctAnswers: ["Проектной (конструкторской) документации"],
      },
      {
        code: "1003001",
        text: "Какая максимальная скорость движения груженых вагонеток на линии установлена для двухканатных кольцевых ГПКД при наличии на их станциях горизонтальных обводных шкивов?",
        answers: ["1,0 м/с", "1,6 м/с", "2,0 м/с", "2,5 м/с"],
        correctAnswers: ["1,6 м/с"],
      },
      {
        code: "1003002",
        text: "Какая максимальная скорость движения вагонеток на линии установлена для двухканатной кольцевой ГПКД при наличии на линии линейных муфт?",
        answers: ["1,5 м/с", "2,0 м/с", "3,0 м/с", "3,5 м/с"],
        correctAnswers: ["3,5 м/с"],
      },
      {
        code: "1003003",
        text: "Какой величины должен быть зазор между габаритом вагонетки (с учетом поперечного и продольного качания и полного круга вращения ее кузова) и полом станции?",
        answers: [
          "Не менее 0,1 м",
          "Не менее 0,15 м",
          "Не менее 0,2 м",
          "Не менее 0,25 м",
        ],
        correctAnswers: ["Не менее 0,1 м"],
      },
      {
        code: "1003004",
        text: "Какое минимальное расстояние по вертикали от низшей точки вагонетки на трассе ГПКД должно быть при прохождении над зданиями и сооружениями?",
        answers: ["0,5 м", "1,0 м", "1,5 м", "2,0 м"],
        correctAnswers: ["2,0 м"],
      },
      {
        code: "1003005",
        text: "Какой должна быть минимальная ширина полосы, свободной от зданий, сооружений, растительности и других препятствий, в каждую сторону от оси канатной дороги при отсутствии предохранительных устройств и ширине колеи 4 м?",
        answers: ["4,0 м", "4,5 м", "5,0 м", "6,0 м"],
        correctAnswers: ["4,0 м"],
      },
      {
        code: "1003006",
        text: "Что из перечисленного должно быть предусмотрено на станциях ГПКД?",
        answers: [
          "Устройства для автоматического выпуска вагонеток на линию",
          "Механизация загрузки и разгрузки вагонеток",
          "Механизация возвращения кузова в первоначальное положение после разгрузки",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "1003007",
        text: "Для каких целей предназначена предупредительная сигнализация, устанавливаемая на ГПКД?",
        answers: [
          "Для оповещения персонала о возникновении короткого замыкания",
          "Для оповещения персонала о превышении установленной скорости движения",
          "Для оповещения персонала о предстоящем включении дороги в работу",
        ],
        correctAnswers: [
          "Для оповещения персонала о предстоящем включении дороги в работу",
        ],
      },
      {
        code: "1003008",
        text: "В каких местах не должны быть установлены кнопки аварийный СТОП?",
        answers: [
          "У линейных опор",
          "В помещениях приводов",
          "Вблизи включателей и выключателей вагонеток",
          "У мест загрузки и разгрузки вагонеток",
        ],
        correctAnswers: ["У линейных опор"],
      },
      {
        code: "1003009",
        text: "Какой конструкции должны быть несущие канаты ГПКД?",
        answers: [
          "Прядевыми двойной свивки",
          "Прядевыми тройной свивки с металлическим сердечником",
          "Спиральными закрытой конструкции",
          "Прядевыми с органическим сердечником",
        ],
        correctAnswers: ["Спиральными закрытой конструкции"],
      },
      {
        code: "1003010",
        text: "Какой конструкции должны быть натяжные канаты несущих канатов ГПКД?",
        answers: [
          "Прядевыми двойной свивки с металлическим сердечником",
          "Прядевыми тройной свивки с органическим сердечником",
          "Закрытой конструкции",
          "Закрытой конструкции с двумя слоями клиновидной проволоки",
        ],
        correctAnswers: ["Прядевыми тройной свивки с органическим сердечником"],
      },
      {
        code: "1003011",
        text: "Каким должен быть минимальный коэффициент запаса прочности для несущего каната?",
        answers: ["1,8", "2,0", "2,5", "2,8"],
        correctAnswers: ["2,8"],
      },
      {
        code: "1003012",
        text: "Каким должен быть минимальный коэффициент запаса прочности для тягового каната?",
        answers: ["1,5", "2,5", "3,0", "4,0"],
        correctAnswers: ["4,0"],
      },
      {
        code: "1003013",
        text: "Каким должен быть минимальный коэффициент запаса прочности натяжного каната для несущих канатов?",
        answers: ["1,5", "2,5", "3,0", "3,5"],
        correctAnswers: ["3,5"],
      },
      {
        code: "1003014",
        text: "Каким должен быть минимальный коэффициент запаса прочности натяжного каната для тяговых канатов?",
        answers: ["1,5", "2,5", "3,0", "4,0"],
        correctAnswers: ["4,0"],
      },
      {
        code: "1003015",
        text: "Каким способом не должны закрепляться концы несущего каната?",
        answers: [
          "Муфтами",
          "Коушами с зажимами",
          "Якорными барабанами и зажимами",
          "Многоболтовыми зажимами",
        ],
        correctAnswers: ["Коушами с зажимами"],
      },
      {
        code: "1003016",
        text: "Каким способом не должны закрепляться концы натяжных, сетевых и расчалочных канатов?",
        answers: [
          "Муфтами",
          "Коушами с зажимами",
          "Многоболтовыми зажимами",
          "Якорными барабанами",
        ],
        correctAnswers: ["Якорными барабанами"],
      },
      {
        code: "1003017",
        text: "Какое может быть наибольшее число соединений тягового каната на 1 км его длины при частичной замене во время эксплуатации?",
        answers: ["5", "7", "9", "10"],
        correctAnswers: ["5"],
      },
      {
        code: "1003018",
        text: "Какая должна быть минимальная длина счалки тягового каната?",
        answers: [
          "500 диаметров каната",
          "700 диаметров каната",
          "900 диаметров каната",
          "1000 диаметров каната",
        ],
        correctAnswers: ["1000 диаметров каната"],
      },
      {
        code: "1003019",
        text: "Каким способом должен соединяться натяжной канат с несущим канатом?",
        answers: [
          "Коушами с зажимом",
          "Переходной муфтой",
          "Якорными барабанами",
          "Концевой муфтой",
        ],
        correctAnswers: ["Переходной муфтой"],
      },
      {
        code: "1003020",
        text: "Какое минимальное число витков каната должно быть на якорном барабане?",
        answers: ["1,5", "2", "2,5", "3"],
        correctAnswers: ["3"],
      },
      {
        code: "1003021",
        text: "Каким должно быть минимальное натяжение тягового каната кольцевых ГПКД?",
        answers: [
          "От 500q до 600q (q - погонный вес тягового каната)",
          "Не должно быть меньше 500q (q - погонный вес тягового каната)",
          "Не должно быть меньше 600q (q - погонный вес тягового каната)",
          "От 400q до 700q (q - погонный вес тягового каната)",
        ],
        correctAnswers: [
          "Не должно быть меньше 600q (q - погонный вес тягового каната)",
        ],
      },
      {
        code: "1003022",
        text: "Какие документы регламентируют закрепление несущих канатов в муфтах?",
        answers: [
          "Рекомендации заводов - изготовителей канатов",
          "Декларации промышленной безопасности",
          "Технологии работ",
          "Инструкции по монтажу",
        ],
        correctAnswers: ["Рекомендации заводов - изготовителей канатов"],
      },
      {
        code: "1003023",
        text: "Какое должно быть минимальное соотношение диаметра огибаемого шкива ролика или барабана к диаметру несущего каната?",
        answers: ["10", "30", "40", "50"],
        correctAnswers: ["50"],
      },
      {
        code: "1003024",
        text: "Какая может быть наибольшая величина ревизионной скорости тягового каната?",
        answers: ["1 м/с", "1,1 м/с", "1,2 м/с", "1,5 м/с"],
        correctAnswers: ["1 м/с"],
      },
      {
        code: "1003025",
        text: "При каком процентном увеличении скорости тягового каната при работе дороги в тормозном режиме должна обеспечиваться автоматическая остановка ГПКД?",
        answers: ["2%", "10%", "20%", "25%"],
        correctAnswers: ["20%"],
      },
      {
        code: "1003026",
        text: "Какое из перечисленных требований, установленных к ГПКД с маятниковым движением подвижного состава, указано неверно?",
        answers: [
          "Грузовые подвесные канатные дороги с маятниковым движением подвижного состава должны автоматически останавливаться при возникновении неисправности в системе контроля скорости",
          "Приводы грузовых подвесных канатных дорог с маятниковым движением подвижного состава должны обеспечивать возможность работы канатной дороги с ревизионной скоростью не более 3 м/с",
          "Привод грузовой подвесной канатной дороги с маятниковым движением подвижного состава должен быть оснащен системой регулирования и контроля скорости с учетом ее снижения при подходе к станции",
          "На грузовых подвесных канатных дорогах с маятниковым движением подвижного состава конечные положения вагонетки должны контролироваться автоматически",
        ],
        correctAnswers: [
          "Приводы грузовых подвесных канатных дорог с маятниковым движением подвижного состава должны обеспечивать возможность работы канатной дороги с ревизионной скоростью не более 3 м/с",
        ],
      },
      {
        code: "1003027",
        text: "Какой наибольший размер транспортируемого груза должны удерживать предохранительные устройства при его просыпании из кузова вагонетки?",
        answers: ["3 мм", "10 мм", "20 мм", "30 мм"],
        correctAnswers: ["20 мм"],
      },
      {
        code: "1003028",
        text: "Каким образом должен производиться повторный запуск ГПКД после устранения причин аварийного отключения?",
        answers: [
          "Вручную, оператором грузовой подвесной канатной дороги",
          "Автоматически, после подачи определенных сигналов",
          "Пуск может быть произведен как автоматически, так и вручную",
          "Только вручную, любым работником ремонтных подразделений грузовой подвесной канатной дороги",
        ],
        correctAnswers: [
          "Вручную, оператором грузовой подвесной канатной дороги",
        ],
      },
      {
        code: "1003029",
        text: "Кем составляется руководство по эксплуатации ГПКД?",
        answers: [
          "Организацией, эксплуатирующей грузовые подвесные канатные дороги",
          "Только организацией, разработавшей проект грузовой подвесной канатной дороги",
          "Только специализированной организацией, аккредитованной Ростехнадзором",
          "Предприятием-изготовителем или организацией, разработавшей проект грузовой подвесной канатной дороги, а при их отсутствии специализированной организацией",
        ],
        correctAnswers: [
          "Предприятием-изготовителем или организацией, разработавшей проект грузовой подвесной канатной дороги, а при их отсутствии специализированной организацией",
        ],
      },
      {
        code: "1003030",
        text: "Какие факторы не влияют на запрет эксплуатации ГПКД?",
        answers: [
          "Отсутствие на рабочем месте технического руководителя",
          "Наличие трещин в расчетных элементах металлоконструкций",
          "Отсутствие персонала, прошедшего проверку знаний в установленном порядке",
          "Неблагоприятные погодные условия, оговоренные в паспорте грузовой подвесной канатной дороги",
        ],
        correctAnswers: [
          "Отсутствие на рабочем месте технического руководителя",
        ],
      },
      {
        code: "1003031",
        text: "Каким документом регламентируется ежедневный контроль состояния дороги?",
        answers: [
          "Технологией работ",
          "Приказом по предприятию",
          "Актом осмотра",
          "Руководством по эксплуатации",
        ],
        correctAnswers: ["Руководством по эксплуатации"],
      },
      {
        code: "1003032",
        text: "Какие виды работ могут выполнять работники неспециализированных организаций и ремонтных служб?",
        answers: [
          "Ремонт несущих металлоконструкций",
          "Смазка вагонетки",
          "Ремонт приборов безопасности",
          "Все виды сварочных работ",
        ],
        correctAnswers: ["Смазка вагонетки"],
      },
      {
        code: "1003033",
        text: "В какие сроки должен производиться работниками, обслуживающими канатную дорогу, плановый осмотр несущего каната?",
        answers: ["30 дней", "40 дней", "50 дней", "60 дней"],
        correctAnswers: ["30 дней"],
      },
      {
        code: "1003034",
        text: "В какие сроки должен производиться работниками, обслуживающими канатную дорогу, плановый осмотр приборов и устройств безопасности, сигнализации, связи?",
        answers: ["7 дней", "10 дней", "14 дней", "30 дней"],
        correctAnswers: ["7 дней"],
      },
      {
        code: "1003035",
        text: "Что не включает в себя техническое обслуживание?",
        answers: [
          "Замер износа узлов и деталей",
          "Дефектоскопию канатов",
          "Регулировку узлов и деталей",
          "Смазку узлов и деталей",
        ],
        correctAnswers: ["Дефектоскопию канатов"],
      },
      {
        code: "1003036",
        text: "Какой документ регламентирует сроки проведения технического обслуживания?",
        answers: [
          "Заключение экспертизы промышленной безопасности",
          "Руководство по эксплуатации",
          "Технологическая карта",
          "Паспорт канатной дороги",
        ],
        correctAnswers: ["Руководство по эксплуатации"],
      },
      {
        code: "1003037",
        text: "В какие сроки осуществляется техническое обслуживание ГПКД?",
        answers: [
          "В срок не более 70 дней",
          "В срок не более 60 дней",
          "В сроки, устанавливаемые в руководстве по эксплуатации грузовой подвесной канатной дороги",
          "В срок не более 90 дней",
        ],
        correctAnswers: [
          "В сроки, устанавливаемые в руководстве по эксплуатации грузовой подвесной канатной дороги",
        ],
      },
      {
        code: "1003038",
        text: "При обнаружении каких из перечисленных видов деформаций канаты могут быть допущены к работе?",
        answers: [
          "При обнаружении износа высоты наружной фасонной проволоки замка на 10% в канате закрытой конструкции",
          "При обнаружении местного уменьшения диаметра каната",
          "При обнаружении корзинообразной деформации",
          "При обнаружении выдавливания проволок или расслоения прядей",
        ],
        correctAnswers: [
          "При обнаружении износа высоты наружной фасонной проволоки замка на 10% в канате закрытой конструкции",
        ],
      },
      {
        code: "1003039",
        text: "В каком из перечисленных случаев несущие канаты закрытой конструкции не подлежат замене?",
        answers: [
          "В случае если на участке длиной, равной 6dk, имеются разрывы двух и более смежных проволок наружного слоя",
          "В случае если имеется выход из замка одной и более проволок наружного слоя без ее обрыва",
          "В случае если на участке длиной, равной 10dk, одна шестая часть (16,6%) проволок наружного слоя замка оборваны",
          "В случае если оборванные концы наружных проволок каната выступают",
        ],
        correctAnswers: [
          "В случае если на участке длиной, равной 10dk, одна шестая часть (16,6%) проволок наружного слоя замка оборваны",
        ],
      },
      {
        code: "1003040",
        text: "Кто входит в состав комиссии, принимающей решение о проведении ремонта канатов?",
        answers: [
          "Представители эксплуатирующей организации и представители специализированной организации",
          "Представители эксплуатирующей организации",
          "Представители эксплуатирующей организации и представители территориального органа Ростехнадзора",
          "Представители владельца, организации, разработавшей проект, и (или) специализированной организации",
        ],
        correctAnswers: [
          "Представители владельца, организации, разработавшей проект, и (или) специализированной организации",
        ],
      },
      {
        code: "1003041",
        text: "Что из перечисленного входит в перечень работ, выполняемых специализированными организациями или ремонтными службами (подразделениями) эксплуатирующей организации?",
        answers: [
          "Работы, связанные с наплавкой, напылением и последующей механической обработкой деталей",
          "Работы, связанные с нагревом (охлаждением) деталей и узлов",
          "Монтаж приборов безопасности, средств сигнализации и связи",
          "Ремонт натяжных и анкерных устройств для всех видов канатов",
          "Все перечисленные виды работ",
        ],
        correctAnswers: ["Все перечисленные виды работ"],
      },
      {
        code: "1003042",
        text: "Выберите обязательные конструктивные особенности полов на станциях в местах прохода людей, имеющих уклон свыше 10 %. Укажите все правильные ответы.",
        answers: [
          "Ступенчатые",
          "Ребристые",
          "С деревянным настилом",
          "С нескользящим покрытием",
          "Заземленные",
        ],
        correctAnswers: ["Ступенчатые", "Ребристые"],
      },
      {
        code: "1003043",
        text: "В каком из перечисленных случаев стальной канат двойной свивки ГПКД не подлежит браковке?",
        answers: [
          "При износе высоты наружной фасонной проволоки замка на 50% и более у каната закрытой конструкции",
          "При уменьшении первоначального диаметра наружных проволок в результате износа на 40% и более",
          "При уменьшении диаметра каната в результате повреждения сердечника (внутреннего износа, обмятия, разрыва) на 2% от номинального диаметра у малокрутящихся канатов",
          "При уменьшении диаметра каната в результате поверхностного износа или коррозии на 7% и более, по сравнению с номинальным диаметром, даже при отсутствии видимых обрывов проволок",
        ],
        correctAnswers: [
          "При уменьшении диаметра каната в результате повреждения сердечника (внутреннего износа, обмятия, разрыва) на 2% от номинального диаметра у малокрутящихся канатов",
        ],
      },
      {
        code: "1003044",
        text: "При каком наклоне к горизонту рельсовые пути галерей и станций должны быть оборудованы ловителями, препятствующими обратному ходу вагонеток при движении их на подъем?",
        answers: ["Свыше 10%", "Свыше 20%", "Свыше 5%", "Свыше 15%"],
        correctAnswers: ["Свыше 10%"],
      },
      {
        code: "1003045",
        text: "К какой деятельности в области промышленной безопасности Правила безопасности грузовых подвесных канатных дорог требования не устанавливают?",
        answers: [
          "К изготовлению, монтажу, наладке, обслуживанию и ремонту",
          "К проектированию грузовых подвесных канатных дорог",
          "К эксплуатации, капитальному ремонту, техническому перевооружению, консервации и ликвидации",
          "К проведению экспертизы промышленной безопасности",
        ],
        correctAnswers: ["К проектированию грузовых подвесных канатных дорог"],
      },
      {
        code: "1003046",
        text: "Какое утверждение соответствует требованиям к ширине предохранительных мостов?",
        answers: [
          "Должна обеспечивать перекрытие пространства на 2 м в каждую сторону от оси несущего каната",
          "Должна обеспечивать перекрытие пространства на 1,25 м в каждую сторону от оси несущего каната",
          "Должна обеспечивать перекрытие пространства на 2,25 м в каждую сторону от оси несущего каната",
          "Должна обеспечивать перекрытие пространства на 1,5 м в каждую сторону от оси несущего каната",
        ],
        correctAnswers: [
          "Должна обеспечивать перекрытие пространства на 1,25 м в каждую сторону от оси несущего каната",
        ],
      },
      {
        code: "1003047",
        text: "Какое допускается максимальное центростремительное ускорение тележки вагонетки?",
        answers: ["3,0 м/c²", "1,5 м/c²", "2,5 м/c²", "3,5 м/c²"],
        correctAnswers: ["2,5 м/c²"],
      },
      {
        code: "1003048",
        text: "Какой ширины должны быть безопасные проходы для людей, обслуживающих оборудование ГПКД?",
        answers: [
          "От 0,5 м до 0,8 м",
          "Не менее 0,8 м",
          "Не менее 0,5 м",
          "От 0,7 м до 0,9 м",
        ],
        correctAnswers: ["Не менее 0,8 м"],
      },
      {
        code: "1003049",
        text: "Каким условиям должна соответствовать длина натяжного участка несущих канатов (канат, по которому перемещается подвижной состав)?",
        answers: [
          "Условиям, при которых увеличение или уменьшение натяжения любого из несущих канатов от трения на шкивах и башмаках (башмак (опорный, отклоняющий) - закрепленное жестко или качающееся устройство на опоре или станции, обеспечивающее поддержку или отклонение несущего каната) не превышает 40% от расчетного",
          "Условиям, при которых увеличение или уменьшение натяжения любого из несущих канатов от трения на шкивах и башмаках (башмак (опорный, отклоняющий) - закрепленное жестко или качающееся устройство на опоре или станции, обеспечивающее поддержку или отклонение несущего каната) не превышает 10% от расчетного",
          "Условиям, при которых увеличение или уменьшение натяжения любого из несущих канатов от трения на шкивах и башмаках (башмак (опорный, отклоняющий) - закрепленное жестко или качающееся устройство на опоре или станции, обеспечивающее поддержку или отклонение несущего каната) не превышает 30% от расчетного",
          "Условиям, при которых увеличение или уменьшение натяжения любого из несущих канатов от трения на шкивах и башмаках (башмак (опорный, отклоняющий) - закрепленное жестко или качающееся устройство на опоре или станции, обеспечивающее поддержку или отклонение несущего каната) не превышает 20% от расчетного",
        ],
        correctAnswers: [
          "Условиям, при которых увеличение или уменьшение натяжения любого из несущих канатов от трения на шкивах и башмаках (башмак (опорный, отклоняющий) - закрепленное жестко или качающееся устройство на опоре или станции, обеспечивающее поддержку или отклонение несущего каната) не превышает 30% от расчетного",
        ],
      },
      {
        code: "1003050",
        text: "Что из перечисленного, согласно Правилам безопасности грузовых подвесных канатных дорог, не проводится при техническом освидетельствовании ГПКД?",
        answers: [
          "Проверка технического состояния оборудования путем осмотра и измерений",
          "Статические и динамические испытания",
          "Поверка контрольных (испытательных) грузов",
          "Учебная операция по отработке действий работников грузовых подвесных канатных дорог в случае возникновения возможной чрезвычайной ситуации",
          "Проверка эксплуатационной документации",
        ],
        correctAnswers: ["Поверка контрольных (испытательных) грузов"],
      },
      {
        code: "1003051",
        text: "Кем проводится техническое освидетельствование грузовых подвесных канатных дорог? Укажите все правильные ответы.",
        answers: [
          "Эксплуатирующей организацией при наличии квалифицированного персонала",
          "Инспектором Ростехнадзора",
          "Специализированной организацией в присутствии специалиста, ответственного за исправное состояние и безопасную эксплуатацию грузовых подъемных канатных дорог",
          "Специализированной организацией совместно с инспектором Ростехнадзора",
        ],
        correctAnswers: [
          "Эксплуатирующей организацией при наличии квалифицированного персонала",
          "Специализированной организацией в присутствии специалиста, ответственного за исправное состояние и безопасную эксплуатацию грузовых подъемных канатных дорог",
        ],
      },
      {
        code: "1003052",
        text: "Какое из перечисленных утверждений к лестницам для подъема на опоры и станции грузовых подвесных канатных дорог указано верно?",
        answers: [
          "Вертикальные лестницы опор высотой до 30 м и линейных станций при высоте более 5 м должны иметь, начиная с высоты 3 м, ограждения в виде дуг",
          "Лестницы высотой более 30 м должны иметь ограждения, начиная с высоты 1,8 м",
          "Лестницы высотой менее 30 м должны быть выполнены в виде маршей с площадками",
          "Все перечисленные утверждения верны",
        ],
        correctAnswers: [
          "Вертикальные лестницы опор высотой до 30 м и линейных станций при высоте более 5 м должны иметь, начиная с высоты 3 м, ограждения в виде дуг",
        ],
      },
      {
        code: "1003053",
        text: "С какой периодичностью необходимо контролировать и корректировать величину натяжения несущего каната при заякоривании обоих концов каната?",
        answers: [
          "Не менее одного раза в год",
          "Не менее одного раза в шесть месяцев",
          "Не менее одного раза в месяц",
          "Не менее одного раза в три месяца",
        ],
        correctAnswers: ["Не менее одного раза в шесть месяцев"],
      },
      {
        code: "1003054",
        text: "Какие сетки должны быть установлены в местах входа и выхода вагонеток при превышении уровня пола станции над уровнем земли более чем 0,5 м?",
        answers: [
          "Шириной не менее 3 м",
          "Шириной не менее 2 м",
          "Не регламентируется",
          "Шириной не менее 1 м",
        ],
        correctAnswers: ["Шириной не менее 1 м"],
      },
      {
        code: "1003055",
        text: "Каким образом должна осуществляться возможность останова привода грузовых подвесных канатных дорог?",
        answers: [
          "С пульта управления",
          "Всеми перечисленными способами",
          'От кнопок "СТОП", установленных на каждой станции',
          'От аварийных выключателей "Аварийный стоп"',
        ],
        correctAnswers: ["Всеми перечисленными способами"],
      },
      {
        code: "1003056",
        text: "Какую информацию должно содержать руководство по эксплуатации грузовых подвесных канатных дорог?",
        answers: [
          "Методы контроля и диагностики состояния элементов и узлов грузовых подвесных канатных дорог",
          "Всю перечисленную информацию",
          "Указания по выводу из эксплуатации и утилизации",
          "Программы и методики испытаний",
        ],
        correctAnswers: ["Всю перечисленную информацию"],
      },
      {
        code: "1003057",
        text: "Какое минимальное соотношение между натяжением несущего каната и весом груженой вагонетки?",
        answers: ["9", "10", "6", "7", "8"],
        correctAnswers: ["8"],
      },
      {
        code: "1003058",
        text: "Для каких нагрузок учитывается повышающий коэффициент нагрузок 1,1, соответствующий требованиям по надежности грузовых подвесных канатных дорог?",
        answers: [
          "Для нагрузок от натяжения тягового каната",
          "Для нагрузок от трения канатов по башмаку",
          "Для нагрузок от веса оборудования",
          "Для нагрузок от натяжения расчалочных канатов",
          "Для нагрузок от натяжения сетевых канатов",
        ],
        correctAnswers: ["Для нагрузок от веса оборудования"],
      },
      {
        code: "1003059",
        text: "Кто может быть председателем комиссии по принятию решения о возможности ввода ГПКД в эксплуатацию?",
        answers: [
          "Представитель организации-застройщика",
          "Представитель собственника (владельца)",
          "Представитель генерального подрядчика",
          "Представитель территориального органа Ростехнадзора",
        ],
        correctAnswers: ["Представитель собственника (владельца)"],
      },
      {
        code: "1003060",
        text: "Каким должно быть свободное боковое пространство между вагонеткой (с учетом поперечного качания каната и вагонеток) и сооружениями или естественными препятствиями в местах, где возможен проход людей?",
        answers: [
          "Не менее 2 м",
          "Не более 1,5 м",
          "Не более 1 м",
          "Не менее 1 м",
        ],
        correctAnswers: ["Не менее 2 м"],
      },
      {
        code: "1003061",
        text: "Какой повышающий коэффициент нагрузок соответствует требованиям по надежности для нагрузок от натяжения тягового каната?",
        answers: ["1", "1,2", "1,3", "1,4", "1,1"],
        correctAnswers: ["1,4"],
      },
      {
        code: "1003062",
        text: "В каком документе указывается срок очередного технического освидетельствования?",
        answers: [
          "В инструкции по эксплуатации",
          "В приказе о вводе в эксплуатацию",
          "В паспорте канатной дороги",
          "В плане-графике обслуживания грузовых подвесных канатных дорог",
        ],
        correctAnswers: ["В паспорте канатной дороги"],
      },
      {
        code: "1003063",
        text: "Какое из перечисленных требований к безопасности технологических процессов грузовых подвесных канатных дорог указано неверно?",
        answers: [
          "Минимальное усилие со стороны каната на каждый поддерживающий ролик одноканатных грузовых подвесных канатных дорог должно быть не менее 500 Н",
          "Увеличение натяжения каната на 40% приводит к отрыву каната и потере его контакта с роликом на опоре, наивысшая точка которой находится ниже хорды, соединяющей две соседние с ней опоры",
          "Роликовые балансиры на опорах одноканатных грузовых подвесных канатных дорог должны состоять не менее чем из двух роликов",
          "Поддерживающие ролики не должны вызывать отклонение несуще-тяговых и тяговых канатов более чем на 10%",
        ],
        correctAnswers: [
          "Увеличение натяжения каната на 40% приводит к отрыву каната и потере его контакта с роликом на опоре, наивысшая точка которой находится ниже хорды, соединяющей две соседние с ней опоры",
        ],
      },
      {
        code: "1003064",
        text: "Какой документ на ГПКД не является обязательным?",
        answers: [
          "Должностные инструкции для специалистов",
          "Производственные инструкции для персонала",
          "Журнал работы ГПКД и передачи смен",
          "График внепланового осмотра оборудования",
        ],
        correctAnswers: ["График внепланового осмотра оборудования"],
      },
      {
        code: "1003065",
        text: "Какими должны быть тяговые и несуще-тяговые канаты?",
        answers: [
          "Прядевыми двойной свивки с органическим сердечником",
          "Закрытой конструкции с двумя слоями клиновидной проволоки",
          "Тройной свивки с металлическим сердечником",
          "Двойной свивки с металлическим сердечником",
        ],
        correctAnswers: ["Прядевыми двойной свивки с органическим сердечником"],
      },
      {
        code: "1003066",
        text: "Кто должен по результатам экспертизы промышленной безопасности обеспечивать выполнение мероприятий по повышению уровня безопасности ГПКД в установленные сроки либо принимать решение о выводе ее из эксплуатации?",
        answers: [
          "Эксплуатирующая организация",
          "Организация, проводящая экспертизу промышленной безопасности",
          "Инспектор территориального органа Ростехнадзора",
        ],
        correctAnswers: ["Эксплуатирующая организация"],
      },
      {
        code: "1003067",
        text: "Какая устанавливается минимальная высота бортов предохранительных сетей?",
        answers: ["1,8 м", "1,2 м", "1 м", "1,5 м"],
        correctAnswers: ["1,2 м"],
      },
      {
        code: "1003068",
        text: "В ходе каких мероприятий устанавливаются сроки и объем текущего, среднего и капитального ремонта оборудования, металлоконструкций, сооружений? Выберите несколько вариантов ответа.",
        answers: [
          "При экспертизе промышленной безопасности",
          "При техническом освидетельствовании",
          "При плановом техническом осмотре",
          "При техническом обслуживании",
          "При проведении аварийно-спасательных работ",
          "При сезонных осмотрах",
        ],
        correctAnswers: [
          "При экспертизе промышленной безопасности",
          "При техническом освидетельствовании",
          "При плановом техническом осмотре",
        ],
      },
      {
        code: "1003069",
        text: "На какие виды грузовых канатных дорог не распространяются требования Правил безопасности грузовых подвесных канатных дорог?",
        answers: [
          "Трехканатные кольцевые подвесные канатные дороги с отцепляемым на станциях подвижным составом",
          "Одноканатные кольцевые грузовые подвесные канатные дороги с неотцепляемым на станциях подвижным составом",
          "Двухканатные подвесные канатные дороги с отцепляемым на станциях подвижным составом",
          "Двухканатные грузовые подвесные канатные дороги с реверсивным (маятниковым) движением подвижного состава",
        ],
        correctAnswers: [
          "Трехканатные кольцевые подвесные канатные дороги с отцепляемым на станциях подвижным составом",
        ],
      },
      {
        code: "1003070",
        text: "Кем принимается решение о вводе в эксплуатацию грузовых подвесных канатных дорог?",
        answers: [
          "Ответственным за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО",
          "Руководителем организации-эксплуатанта",
          "Территориальным органом Ростехнадзора",
          "Техническим руководителем",
        ],
        correctAnswers: [
          "Ответственным за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО",
        ],
      },
      {
        code: "1003071",
        text: "При каком минимальном зазоре между встречными вагонетками при поперечном раскачивании канатов с вагонетками внутрь колеи от воздействия ветра, допустимого для эксплуатации грузовых подвесных канатных дорог, расстояние между двумя ветвями дороги будет удовлетворять данному условию?",
        answers: ["0,5 м", "1 м", "1,5 м", "0,3 м"],
        correctAnswers: ["0,5 м"],
      },
      {
        code: "1003072",
        text: "Какие требования установлены Правилами безопасности грузовых подвесных канатных дорог к площадкам для обслуживания оборудования ГПКД, расположенным на высоте более 2 м?",
        answers: [
          "Площадки должны иметь ограждение высотой не менее 0,5 м и сплошную зашивку по верху",
          "Площадки должны иметь ограждение высотой не менее 0,8 м",
          "Площадки должны иметь ограждение высотой не менее 0,8 м и сплошную зашивку",
          "Площадки должны иметь ограждение высотой не менее 1 м и сплошную зашивку понизу на высоте не менее 0,1 м",
        ],
        correctAnswers: [
          "Площадки должны иметь ограждение высотой не менее 1 м и сплошную зашивку понизу на высоте не менее 0,1 м",
        ],
      },
      {
        code: "1003073",
        text: "Какое из перечисленных требований к грузовым подвесным канатным дорогам указано верно?",
        answers: [
          "В машинном отделении должно быть устроено аварийное освещение",
          "Канат не требуется заземлять, если у него органический сердечник",
          "Не обязательно освещать участки входа и выхода вагонеток, если они двигаются на открытой местности",
          "Нумерация опор обязательна только при условии, что их количество больше 10",
        ],
        correctAnswers: [
          "В машинном отделении должно быть устроено аварийное освещение",
        ],
      },
      {
        code: "1003074",
        text: "Какой должен быть коэффициент запаса сцепления тягового каната с приводным шкивом?",
        answers: [
          "Не менее 1,1",
          "Не менее 1,25",
          "Не менее 1,5",
          "Не менее 2,0",
        ],
        correctAnswers: ["Не менее 1,1"],
      },
      {
        code: "1003075",
        text: "Какой запас прочности должны иметь зажимы на ГПКД с учетом возможного уменьшения диаметра каната?",
        answers: [
          "Не менее 1,5",
          "Не менее 1,1",
          "Не менее 2,0",
          "Не менее 1,7",
        ],
        correctAnswers: ["Не менее 1,5"],
      },
      {
        code: "1003076",
        text: "В каких случаях допускается транспортировка людей в вагонетках при эксплуатации ГПКД?",
        answers: [
          "Если транспортируются работники, выполняющие проверку состояния канатов",
          "Если транспортируются работники для выполнения регламентных работ",
          "Если транспортируются работники, вынужденные использовать ГПКД для проезда к своему месту работы",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "1003077",
        text: "С какой целью проводится техническое освидетельствование ГКПД?",
        answers: [
          "С целью установления соответствия ГКПД и ее устройства Правилам безопасности грузовых подвесных канатных дорог и паспортным данным",
          "С целью установления, что ГКПД находится в технически исправном состоянии, обеспечивающем ее безопасную работу",
          "С целью установления, что эксплуатация, содержание и обслуживание ГКПД соответствует требованиям Правил безопасности грузовых подвесных канатных дорог",
          "Со всеми перечисленными целями",
        ],
        correctAnswers: ["Со всеми перечисленными целями"],
      },
      {
        code: "1003078",
        text: 'Какие требования предъявляются федеральными нормами и правилами в области промышленной безопасности "Требования к производству сварочных работ на опасных производственных объектах" к сварочному оборудованию и сварочным материалам, применяемым при выполнении сварочных работ?',
        answers: [
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
          "Оборудование и материалы должны быть задекларированы",
          "Оборудование и материалы должны иметь сертификаты безопасности",
        ],
        correctAnswers: [
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "1003079",
        text: "Каким образом допускается маркировать сварное соединение, выполненное несколькими сварщиками (бригадой сварщиков)?",
        answers: [
          "Допускается маркировка нанесением краски (символы и цвет определяются распорядительным документом организации)",
          "Допускается маркировка нанесением металлической таблички с номерами клейм сварщиков",
          "Допускается применение клейма сварщика, имеющего наибольший опыт работы",
          "Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
        ],
        correctAnswers: [
          "Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
        ],
      },
      {
        code: "1003080",
        text: "Какая документация оформляется в процессе выполнения сварочных работ?",
        answers: [
          "Только заключения по неразрушающему контролю",
          "Только протоколы сварочных работ",
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
        ],
        correctAnswers: [
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "1003081",
        text: "Кто допускается к выполнению сварочных работ на опасном производственном объекте?",
        answers: [
          "Только сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ",
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
          "Любые работники, аттестованные для соответствующих способов сварки",
        ],
        correctAnswers: [
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
        ],
      },
      {
        code: "1003082",
        text: "Какую проверку должен пройти сварщик, приступающий к сварке на конкретном объекте впервые или после перерыва в работе продолжительностью более установленного НД, независимо от наличия аттестационного удостоверения, до начала производства работ?",
        answers: [
          "Проверку знания технологий сварки",
          "Проверку путем выполнения контрольного сварного соединения и проверку знаний по охране труда",
          "Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте, с получением положительных результатов контроля их качества",
        ],
        correctAnswers: [
          "Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте, с получением положительных результатов контроля их качества",
        ],
      },
      {
        code: "1003083",
        text: "К выполнению каких работ могут быть допущены сварщики и специалисты сварочного производства?",
        answers: [
          "К выполнению любых сварочных работ при наличии действующего удостоверения сварщика",
          "К выполнению сварочных работ, указанных в действующих документах",
          "К выполнению любых сварочных работ при наличии удостоверения сварщика и после выполнения контрольного сварного соединения",
        ],
        correctAnswers: [
          "К выполнению сварочных работ, указанных в действующих документах",
        ],
      },
      {
        code: "1003084",
        text: "Что обязано проверить и обеспечить лицо, осуществляющее руководство сварочными работами, перед выполнением сварочных работ?",
        answers: [
          "Соответствие основных и сварочных материалов, применяемой технологии сварки требованиям производственно-технологической документации",
          "Соответствие численного состава и квалификации персонала сварочного производства",
          "Соответствие сборочного и сварочного оборудования, применяемой технологии сварки требованиям производственно-технологической документации",
          "Выполнение всех подготовительных мероприятий",
        ],
        correctAnswers: ["Выполнение всех подготовительных мероприятий"],
      },
      {
        code: "1003085",
        text: "Чем должно быть укомплектовано место производства сварочных работ?",
        answers: [
          "Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД",
          "Только сварочным оборудованием",
          "Только сварочным оборудованием и аптечкой первой помощи",
        ],
        correctAnswers: [
          "Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД",
        ],
      },
      {
        code: "1003086",
        text: "Какие действия должны быть осуществлены при выполнении многопроходных швов после наложения каждого валика поверхности шва и кромки разделки?",
        answers: [
          "Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)",
          "Должны быть проверены ультразвуком",
          "Должны быть смочены керосином, проверены на предмет пор и обезжирены",
        ],
        correctAnswers: [
          "Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)",
        ],
      },
      {
        code: "1003087",
        text: "Что должно быть указано в технологических картах сварки?",
        answers: [
          "Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений",
          "Требования к работнику, выполняющему сварку, используемые материалы и необходимое оборудование",
          "Требования к оборудованию, режимы сварки и последовательность действий",
        ],
        correctAnswers: [
          "Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений",
        ],
      },
      {
        code: "1003088",
        text: "Какие функции обязано выполнить лицо, осуществляющее руководство сварочными работами, назначенное распорядительным документом организации или (и) должностной инструкцией которого предусмотрено руководство сварочными работами, перед выполнением сварочных работ?",
        answers: [
          "Организовать проведение операционного контроля",
          "Проверить и обеспечить соответствие численного состава и квалификации персонала сварочного производства, сборочного и сварочного оборудования, основных и сварочных материалов, применяемой технологии сварки требованиям производственно-технологической документации",
          "Ознакомить сварщиков с требованиями технологических карт сварки, а также с внесенными в них изменениями (при наличии) с подтверждением ознакомления подписями сварщиков в применяемых ими технологических картах сварки",
          "Все перечисленные функции",
        ],
        correctAnswers: ["Все перечисленные функции"],
      },
      {
        code: "1003089",
        text: "Что из перечисленного обозначает личный шифр клейм сварщиков?",
        answers: [
          "Уникальный шифр, содержащий четырехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях",
          "Уникальный шифр, содержащий трехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях",
          "Уникальный шифр, содержащий двухзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях",
          "Уникальный шифр, содержащий пятизначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях",
        ],
        correctAnswers: [
          "Уникальный шифр, содержащий четырехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях",
        ],
      },
      {
        code: "1003090",
        text: "В соответствии с чьими указаниями должно содержаться в исправном состоянии, обслуживаться и эксплуатироваться сварочное оборудование?",
        answers: [
          "С указаниями производителя сварочного оборудования",
          "С указаниями владельца сварочного оборудования",
          "С указаниями органа по сертификации сварочного оборудования",
          "С указанияими территориального управления Ростехнадзора",
        ],
        correctAnswers: ["С указаниями производителя сварочного оборудования"],
      },
      {
        code: "1003091",
        text: "Кем осуществляется проверка готовности к применению аттестованных технологий сварки с целью определения наличия у организации или индивидуального предпринимателя технических, организационных и квалификационных возможностей для выполнения сварочных (наплавочных) работ по применяемым им аттестованным технологиям, а также соответствия качества выполненных при аттестации контрольных сварных соединений (наплавок) требованиям НД и (или) проектной (конструкторской) документации на сварные конструкции?",
        answers: [
          "Независимыми аттестационными центрами",
          "Проектной организацией",
          "Контролирующей организацией",
          "Монтажной организацией",
        ],
        correctAnswers: ["Независимыми аттестационными центрами"],
      },
      {
        code: "1003092",
        text: "Какие виды контроля должны осуществляться при подготовке и выполнении сварочных работ?",
        answers: [
          "Только входной контроль",
          "Только операционный контроль",
          "Только приемочный контроль",
          "Все перечисленные виды контроля",
        ],
        correctAnswers: ["Все перечисленные виды контроля"],
      },
      {
        code: "1003093",
        text: "Что из перечисленного должно контролироваться в процессе сварки, как минимум?",
        answers: [
          "Только соответствие параметров режима сварки и технологических приемов выполнения сварного соединения",
          "Только очередность выполнения сварных швов и участков наплавки, отсутствие видимых дефектов",
          "Только параметры, предусмотренные в технологических (операционных) картах сварки",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "1003094",
        text: "Кто обеспечивает организацию и выполнение аттестационных процедур согласно требованиям к производству сварочных работ на опасных производственных объектах?",
        answers: [
          "Руководитель независимого аттестационного центра или уполномоченное им должностное лицо",
          "Руководитель организации, владелец оборудования",
          "Продавец сварочного оборудования",
          "Производитель сварочного оборудования",
        ],
        correctAnswers: [
          "Руководитель независимого аттестационного центра или уполномоченное им должностное лицо",
        ],
      },
      {
        code: "1003095",
        text: "Что из перечисленного должно быть в требованиях по сборке деталей под сварку, содержащихся в ПТД?",
        answers: [
          "Только способы подготовки поверхностей деталей под сварку и используемые при сборке приспособления и оборудование",
          "Только порядок и последовательность сборки, способы крепления деталей, размеры, количество и расположение прихваток, методы контроля качества сборки",
          "Только способы сварки, сварочные материалы и режимы сварки при выполнении прихваток и приварке временных технологических креплений",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "1003096",
        text: "Какие из перечисленных видов контроля не осуществляются при подготовке и выполнении сварочных работ?",
        answers: [
          "Входной контроль",
          "Операционный контроль",
          "Приемочный контроль",
          "Выборочный контроль",
        ],
        correctAnswers: ["Выборочный контроль"],
      },
      {
        code: "1003097",
        text: "Что из перечисленного должно обеспечить лицо, осуществляющее руководство сварочными работами при производстве сварочных работ?",
        answers: [
          "Только регистрацию результатов качества сварных соединений, включая результаты контроля исправлений дефектов сварных соединений",
          "Только идентификацию применяемых сварочных материалов и сварочного оборудования, выполнение сварных соединений в соответствии с технологическими (операционными) картами сварки",
          "Только регистрацию сведений о сварщиках, выполняющих сварные соединения",
          "Все перечисленное",
          "Идентификацию мест расположения сварных соединений в конструкции и мест исправлений дефектов сварных соединений",
        ],
        correctAnswers: ["Все перечисленное"],
      },
    ],
    1004: [
      {
        code: "1004000",
        text: "В соответствии с требованиями каких из перечисленных документов должны осуществляться транспортировка к месту монтажа, а также хранение оборудования ГПКД, узлов и деталей?",
        answers: [
          'ФНП "Правила безопасности грузовых подвесных канатных дорог"',
          "Внутренних правил перевозчика",
          "Инструкции изготовителя",
          "Проектной (конструкторской) документации",
        ],
        correctAnswers: ["Проектной (конструкторской) документации"],
      },
      {
        code: "1004001",
        text: "Какая максимальная скорость движения груженых вагонеток на линии установлена для двухканатных кольцевых ГПКД при наличии на их станциях горизонтальных обводных шкивов?",
        answers: ["1,0 м/с", "1,6 м/с", "2,0 м/с", "2,5 м/с"],
        correctAnswers: ["1,6 м/с"],
      },
      {
        code: "1004002",
        text: "Какая максимальная скорость движения вагонеток на линии установлена для двухканатной кольцевой ГПКД при наличии на линии линейных муфт?",
        answers: ["1,5 м/с", "2,0 м/с", "3,0 м/с", "3,5 м/с"],
        correctAnswers: ["3,5 м/с"],
      },
      {
        code: "1004003",
        text: "Какой величины должен быть зазор между габаритом вагонетки (с учетом поперечного и продольного качания и полного круга вращения ее кузова) и полом станции?",
        answers: [
          "Не менее 0,1 м",
          "Не менее 0,15 м",
          "Не менее 0,2 м",
          "Не менее 0,25 м",
        ],
        correctAnswers: ["Не менее 0,1 м"],
      },
      {
        code: "1004004",
        text: "Какое минимальное расстояние по вертикали от низшей точки вагонетки на трассе ГПКД должно быть при прохождении над зданиями и сооружениями?",
        answers: ["0,5 м", "1,0 м", "1,5 м", "2,0 м"],
        correctAnswers: ["2,0 м"],
      },
      {
        code: "1004005",
        text: "Какой должна быть минимальная ширина полосы свободной от зданий, сооружений, растительности и других препятствий, в каждую сторону от оси канатной дороги отсутствии предохранительных устройств и ширине колеи 4 м?",
        answers: ["4,0 м", "4,5 м", "5,0 м", "6,0 м"],
        correctAnswers: ["4,0 м"],
      },
      {
        code: "1004006",
        text: "Что из перечисленного должно быть предусмотрено на станциях ГПКД?",
        answers: [
          "Только устройства для автоматического выпуска вагонеток на линию",
          "Только механизация загрузки и разгрузки вагонеток",
          "Только механизация возвращение кузова в первоначальное положение после разгрузки",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "1004007",
        text: "Для каких целей предназначена предупредительная сигнализация, устанавливаемая на ГПКД?",
        answers: [
          "Для оповещения персонала о возникновении короткого замыкания",
          "Для оповещения персонала о превышении установленной скорости движения",
          "Для оповещения персонала о предстоящем включении дороги в работу",
        ],
        correctAnswers: [
          "Для оповещения персонала о предстоящем включении дороги в работу",
        ],
      },
      {
        code: "1004008",
        text: "В каких местах не должны быть установлены кнопки «Аварийный СТОП»?",
        answers: [
          "У линейных опор",
          "В помещениях приводов",
          "Вблизи включателей и выключателей вагонеток",
          "У мест загрузки и разгрузки вагонеток",
        ],
        correctAnswers: ["У линейных опор"],
      },
      {
        code: "1004009",
        text: "Какой конструкции должны быть несущие канаты ГПКД?",
        answers: [
          "Прядевыми двойной свивки",
          "Прядевыми тройной свивки с металлическим сердечником",
          "Спиральными закрытой конструкции",
          "Прядевыми с органическим сердечником",
        ],
        correctAnswers: ["Спиральными закрытой конструкции"],
      },
      {
        code: "1004010",
        text: "Какой конструкции должны быть натяжные канаты несущих канатов ГПКД?",
        answers: [
          "Прядевыми двойной свивки с металлическим сердечником",
          "Прядевыми тройной свивки с органическим сердечником",
          "Закрытой конструкции",
          "Закрытой конструкции с двумя слоями клиновидной проволоки",
        ],
        correctAnswers: ["Прядевыми тройной свивки с органическим сердечником"],
      },
      {
        code: "1004011",
        text: "Каким должен быть минимальный коэффициент запаса прочности для несущего каната?",
        answers: ["1,8", "2,0", "2,5", "2,8"],
        correctAnswers: ["2,8"],
      },
      {
        code: "1004012",
        text: "Каким должен быть минимальный коэффициент запаса прочности для тягового каната?",
        answers: ["1,5", "2,5", "3,0", "4,0"],
        correctAnswers: ["4,0"],
      },
      {
        code: "1004013",
        text: "Каким должен быть минимальный коэффициент запаса прочности натяжного каната для несущих канатов?",
        answers: ["1,5", "2,5", "3,0", "3,5"],
        correctAnswers: ["3,5"],
      },
      {
        code: "1004014",
        text: "Каким должен быть минимальный коэффициент запаса прочности натяжного каната для тяговых канатов?",
        answers: ["1,5", "2,5", "3,0", "4,0"],
        correctAnswers: ["4,0"],
      },
      {
        code: "1004015",
        text: "Каким способом не должны закрепляться концы несущего каната?",
        answers: [
          "Муфтами",
          "Коушами с зажимами",
          "Якорными барабанами и зажимами",
          "Многоболтовыми зажимами",
        ],
        correctAnswers: ["Коушами с зажимами"],
      },
      {
        code: "1004016",
        text: "Каким способом не должны закрепляться концы натяжных, сетевых и расчалочных канатов?",
        answers: [
          "Муфтами",
          "Коушами с зажимами",
          "Многоболтовыми зажимами",
          "Якорными барабанами",
        ],
        correctAnswers: ["Якорными барабанами"],
      },
      {
        code: "1004017",
        text: "Какое может быть наибольшее число соединений тягового каната на 1 км его длины при частичной замене во время эксплуатации?",
        answers: ["5", "7", "9", "10"],
        correctAnswers: ["5"],
      },
      {
        code: "1004018",
        text: "Какая должна быть минимальная длина счалки тягового каната?",
        answers: [
          "500 диаметров каната",
          "700 диаметров каната",
          "900 диаметров каната",
          "1000 диаметров каната",
        ],
        correctAnswers: ["1000 диаметров каната"],
      },
      {
        code: "1004019",
        text: "Каким способом должен соединяться натяжной канат с несущим канатом?",
        answers: [
          "Коушами с зажимом",
          "Переходной муфтой",
          "Якорными барабанами",
          "Концевой муфтой",
        ],
        correctAnswers: ["Переходной муфтой"],
      },
      {
        code: "1004020",
        text: "Какое минимальное число витков каната должно быть на якорном барабане?",
        answers: ["1,5", "2", "2,5", "3"],
        correctAnswers: ["3"],
      },
      {
        code: "1004021",
        text: "Каким должно быть минимальное натяжение тягового каната кольцевых ГПКД?",
        answers: [
          "От 500 q до 600 q (q - погонный вес тягового каната)",
          "Не должно быть меньше 500 q (q - погонный вес тягового каната)",
          "Не должно быть меньше 600 q (q - погонный вес тягового каната)",
          "От 400 q до 700 q (q - погонный вес тягового каната)",
        ],
        correctAnswers: [
          "Не должно быть меньше 600 q (q - погонный вес тягового каната)",
        ],
      },
      {
        code: "1004022",
        text: "Какие документы регламентируют закрепление несущих канатов в муфтах?",
        answers: [
          "Рекомендации заводов - изготовителей канатов",
          "Декларации промышленной безопасности",
          "Сертификаты безопасности",
          "Инструкции по монтажу",
        ],
        correctAnswers: ["Рекомендации заводов - изготовителей канатов"],
      },
      {
        code: "1004023",
        text: "Какое должно быть минимальное соотношение диаметра огибаемого шкива ролика или барабана к диаметру несущего каната?",
        answers: ["10", "30", "40", "50"],
        correctAnswers: ["50"],
      },
      {
        code: "1004024",
        text: "Какая может быть наибольшая величина ревизионной скорости тягового каната?",
        answers: ["1 м/с", "1,1 м/с", "1,2 м/с", "1,5 м/с"],
        correctAnswers: ["1 м/с"],
      },
      {
        code: "1004025",
        text: "При каком процентном увеличении скорости тягового каната при работе дороги в тормозном режиме должна обеспечиваться автоматическая остановка ГПКД?",
        answers: ["2%", "10%", "20%", "25%"],
        correctAnswers: ["20%"],
      },
      {
        code: "1004026",
        text: "Какое из перечисленных требований, установленных к ГПКД с маятниковым движением подвижного состава, указано неверно?",
        answers: [
          "Грузовые подвесные канатные дороги с маятниковым движением подвижного состава должны автоматически останавливаться при возникновении неисправности в системе контроля скорости",
          "Приводы грузовых подвесных канатных дорог с маятниковым движением подвижного состава должны обеспечивать возможность работы канатной дороги с ревизионной скоростью не более 3 м/с",
          "Привод грузовой подвесной канатной дороги с маятниковым движением подвижного состава должен быть оснащен системой регулирования и контроля скорости с учетом ее снижения при подходе к станции",
          "На грузовых подвесных канатных дорогах с маятниковым движением подвижного состава конечные положения вагонетки должны контролироваться автоматически",
        ],
        correctAnswers: [
          "Приводы грузовых подвесных канатных дорог с маятниковым движением подвижного состава должны обеспечивать возможность работы канатной дороги с ревизионной скоростью не более 3 м/с",
        ],
      },
      {
        code: "1004027",
        text: "Какой наибольший размер транспортируемого груза должны удерживать предохранительные устройства при его просыпании из кузова вагонетки?",
        answers: ["3 мм", "10 мм", "20 мм", "30 мм"],
        correctAnswers: ["20 мм"],
      },
      {
        code: "1004028",
        text: "Каким образом должен производиться повторный запуск ГПКД после устранения причин аварийного отключения?",
        answers: [
          "Вручную, оператором грузовой подвесной канатной дороги",
          "Автоматически, после подачи определенных сигналов",
          "Как автоматически, так и вручную",
          "Только вручную, любым работником ремонтных подразделений грузовой подвесной канатной дороги",
        ],
        correctAnswers: [
          "Вручную, оператором грузовой подвесной канатной дороги",
        ],
      },
      {
        code: "1004029",
        text: "Кем составляется руководство по эксплуатации ГПКД?",
        answers: [
          "Организацией, эксплуатирующей грузовые подвесные канатные дороги",
          "Только организацией, разработавшей проект грузовой подвесной канатной дороги",
          "Только специализированной организацией, аккредитованной Ростехнадзором",
          "Предприятием-изготовителем или организацией, разработавшей проект грузовой подвесной канатной дороги, а при их отсутствии специализированной организацией",
        ],
        correctAnswers: [
          "Предприятием-изготовителем или организацией, разработавшей проект грузовой подвесной канатной дороги, а при их отсутствии специализированной организацией",
        ],
      },
      {
        code: "1004030",
        text: "Какие факторы не влияют на запрет эксплуатации ГПКД?",
        answers: [
          "Отсутствие на рабочем месте технического руководителя",
          "Наличие трещин в расчетных элементах металлоконструкций",
          "Отсутствие персонала, прошедшего проверку знаний в установленном порядке",
          "Неблагоприятные погодные условия, оговоренные в паспорте грузовой подвесной канатной дороги",
        ],
        correctAnswers: [
          "Отсутствие на рабочем месте технического руководителя",
        ],
      },
      {
        code: "1004031",
        text: "Каким документом регламентируется ежедневный контроль состояния дороги?",
        answers: [
          "Сертификатом безопасности",
          "Приказом по предприятию",
          "Актом осмотра",
          "Руководством по эксплуатации",
        ],
        correctAnswers: ["Руководством по эксплуатации"],
      },
      {
        code: "1004032",
        text: "Какие виды работ могут выполнять работники неспециализированных организаций и ремонтных служб?",
        answers: [
          "Ремонт несущих металлоконструкций",
          "Смазку вагонетки",
          "Ремонт приборов безопасности",
          "Все виды сварочных работ",
        ],
        correctAnswers: ["Смазку вагонетки"],
      },
      {
        code: "1004033",
        text: "В какие сроки должен производиться работниками, обслуживающими канатную дорогу, плановый осмотр несущего каната?",
        answers: ["30 дней", "40 дней", "50 дней", "60 дней"],
        correctAnswers: ["30 дней"],
      },
      {
        code: "1004034",
        text: "В какие сроки должен производиться работниками, обслуживающими канатную дорогу, плановый осмотр приборов и устройств безопасности, сигнализации, связи?",
        answers: [
          "В сроки, не превышающие 7 дней",
          "В сроки, не превышающие 10 дней",
          "В сроки, не превышающие 14 дней",
          "В сроки, не превышающие 30 дней",
        ],
        correctAnswers: ["В сроки, не превышающие 7 дней"],
      },
      {
        code: "1004035",
        text: "Что не включает в себя техническое обслуживание?",
        answers: [
          "Замер износа узлов и деталей",
          "Дефектоскопию канатов",
          "Регулировку узлов и деталей",
          "Смазку узлов и деталей",
        ],
        correctAnswers: ["Дефектоскопию канатов"],
      },
      {
        code: "1004036",
        text: "Какой документ регламентирует сроки проведения технического обслуживания?",
        answers: [
          "Заключение экспертизы промышленной безопасности",
          "Руководство по эксплуатации",
          "Технологическая карта",
          "Паспорт канатной дороги",
        ],
        correctAnswers: ["Руководство по эксплуатации"],
      },
      {
        code: "1004037",
        text: "В какие сроки осуществляется техническое обслуживание ГПКД?",
        answers: [
          "В срок не более 70 дней",
          "В срок не более 60 дней",
          "В сроки, устанавливаемые в руководстве по эксплуатации грузовой подвесной канатной дороги",
          "В срок не более 90 дней",
        ],
        correctAnswers: [
          "В сроки, устанавливаемые в руководстве по эксплуатации грузовой подвесной канатной дороги",
        ],
      },
      {
        code: "1004038",
        text: "При обнаружении каких из перечисленных видов деформаций канаты могут быть допущены к работе?",
        answers: [
          "При обнаружении износа высоты наружной фасонной проволоки замка на 10% в канате закрытой конструкции",
          "При обнаружении местного уменьшения диаметра каната",
          "При обнаружении корзинообразной деформации",
          "При обнаружении выдавливания проволок или расслоения прядей",
        ],
        correctAnswers: [
          "При обнаружении износа высоты наружной фасонной проволоки замка на 10% в канате закрытой конструкции",
        ],
      },
      {
        code: "1004039",
        text: "В каком из перечисленных случаев несущие канаты закрытой конструкции не подлежат замене?",
        answers: [
          "В случае если на участке длиной, равной 6dk, имеются разрывы двух и более смежных проволок наружного слоя",
          "В случае если имеется выход из замка одной и более проволок наружного слоя без ее обрыва",
          "В случае если на участке длиной, равной 10dk, одна шестая часть (16,6%) проволок наружного слоя замка оборваны",
          "В случае если оборванные концы наружных проволок каната выступают",
        ],
        correctAnswers: [
          "В случае если на участке длиной, равной 10dk, одна шестая часть (16,6%) проволок наружного слоя замка оборваны",
        ],
      },
      {
        code: "1004040",
        text: "Кто входит в состав комиссии, принимающей решение о проведении ремонта канатов?",
        answers: [
          "Представители эксплуатирующей организации и представители специализированной организации",
          "Только представители эксплуатирующей организации",
          "Представители эксплуатирующей организации и представители территориального органа Ростехнадзора",
          "Представители владельца, организации, разработавшей проект, и (или) специализированной организации",
        ],
        correctAnswers: [
          "Представители владельца, организации, разработавшей проект, и (или) специализированной организации",
        ],
      },
      {
        code: "1004041",
        text: "Что из перечисленного входит в перечень работ, выполняемых специализированными организациями или ремонтными службами (подразделениями) эксплуатирующей организации?",
        answers: [
          "Работы, связанные с наплавкой, напылением и последующей механической обработкой деталей",
          "Работы, связанные с нагревом (охлаждением) деталей и узлов",
          "Монтаж приборов безопасности, средств сигнализации и связи",
          "Ремонт натяжных и анкерных устройств для всех видов канатов",
          "Все перечисленные виды работ",
        ],
        correctAnswers: ["Все перечисленные виды работ"],
      },
      {
        code: "1004042",
        text: "Выберите обязательные конструктивные особенности полов на станциях в местах прохода людей, имеющих уклон свыше 10 %. Укажите все правильные ответы.",
        answers: [
          "Пол должен быть ступенчатым",
          "Пол должен быть ребристым",
          "Пол должен быть с деревянным настилом",
          "Пол должен быть с нескользящим покрытием",
          "Пол должен быть заземленным",
        ],
        correctAnswers: [
          "Пол должен быть ступенчатым",
          "Пол должен быть ребристым",
        ],
      },
      {
        code: "1004043",
        text: "В каком из перечисленных случаев стальной канат двойной свивки ГПКД не подлежит браковке?",
        answers: [
          "При износе высоты наружной фасонной проволоки замка на 50% и более у каната закрытой конструкции",
          "При уменьшении первоначального диаметра наружных проволок в результате износа на 40% и более",
          "При уменьшении диаметра каната в результате повреждения сердечника (внутреннего износа, обмятия, разрыва) на 2% от номинального диаметра у малокрутящихся канатов",
          "При уменьшении диаметра каната в результате поверхностного износа или коррозии на 7% и более по сравнению с номинальным диаметром даже при отсутствии видимых обрывов проволок",
        ],
        correctAnswers: [
          "При уменьшении диаметра каната в результате повреждения сердечника (внутреннего износа, обмятия, разрыва) на 2% от номинального диаметра у малокрутящихся канатов",
        ],
      },
      {
        code: "1004044",
        text: "При каком наклоне к горизонту рельсовые пути галерей и станций должны быть оборудованы ловителями, препятствующими обратному ходу вагонеток при движении их на подъем?",
        answers: ["Свыше 10%", "Свыше 20%", "Свыше 5%", "Свыше 15%"],
        correctAnswers: ["Свыше 10%"],
      },
      {
        code: "1004045",
        text: "К какой деятельности в области промышленной безопасности Правила безопасности грузовых подвесных канатных дорог требования не устанавливают?",
        answers: [
          "К изготовлению, монтажу, наладке, обслуживанию и ремонту",
          "К проектированию грузовых подвесных канатных дорог",
          "К эксплуатации, капитальному ремонту, техническому перевооружению, консервации и ликвидации",
          "К проведению экспертизы промышленной безопасности",
        ],
        correctAnswers: ["К проектированию грузовых подвесных канатных дорог"],
      },
      {
        code: "1004046",
        text: "Какое утверждение соответствует требованиям к ширине предохранительных мостов?",
        answers: [
          "Должна обеспечивать перекрытие пространства на 2 м в каждую сторону от оси несущего каната",
          "Должна обеспечивать перекрытие пространства на 1,25 м в каждую сторону от оси несущего каната",
          "Должна обеспечивать перекрытие пространства на 2,25 м в каждую сторону от оси несущего каната",
          "Должна обеспечивать перекрытие пространства на 1,5 м в каждую сторону от оси несущего каната",
        ],
        correctAnswers: [
          "Должна обеспечивать перекрытие пространства на 1,25 м в каждую сторону от оси несущего каната",
        ],
      },
      {
        code: "1004047",
        text: "Какое допускается максимальное центростремительное ускорение тележки вагонетки?",
        answers: ["3,0 м/c²", "1,5 м/c²", "2,5 м/c²", "3,5 м/c²"],
        correctAnswers: ["2,5 м/c²"],
      },
      {
        code: "1004048",
        text: "Какой ширины должны быть безопасные проходы для людей, обслуживающих оборудование ГПКД?",
        answers: [
          "От 0,5 м до 0,8 м",
          "Не менее 0,8 м",
          "Не менее 0,5 м",
          "От 0,7 м до 0,9 м",
        ],
        correctAnswers: ["Не менее 0,8 м"],
      },
      {
        code: "1004049",
        text: "Каким условиям должна соответствовать длина натяжного участка несущих канатов (канат, по которому перемещается подвижной состав)?",
        answers: [
          "Условиям, при которых увеличение или уменьшение натяжения любого из несущих канатов от трения на шкивах и башмаках (башмак (опорный, отклоняющий) - закрепленное жестко или качающееся устройство на опоре или станции, обеспечивающее поддержку или отклонение несущего каната) не превышает 40% от расчетного",
          "Условиям, при которых увеличение или уменьшение натяжения любого из несущих канатов от трения на шкивах и башмаках (башмак (опорный, отклоняющий) - закрепленное жестко или качающееся устройство на опоре или станции, обеспечивающее поддержку или отклонение несущего каната) не превышает 10% от расчетного",
          "Условиям, при которых увеличение или уменьшение натяжения любого из несущих канатов от трения на шкивах и башмаках (башмак (опорный, отклоняющий) - закрепленное жестко или качающееся устройство на опоре или станции, обеспечивающее поддержку или отклонение несущего каната) не превышает 30% от расчетного",
          "Условиям, при которых увеличение или уменьшение натяжения любого из несущих канатов от трения на шкивах и башмаках (башмак (опорный, отклоняющий) - закрепленное жестко или качающееся устройство на опоре или станции, обеспечивающее поддержку или отклонение несущего каната) не превышает 20% от расчетного",
        ],
        correctAnswers: [
          "Условиям, при которых увеличение или уменьшение натяжения любого из несущих канатов от трения на шкивах и башмаках (башмак (опорный, отклоняющий) - закрепленное жестко или качающееся устройство на опоре или станции, обеспечивающее поддержку или отклонение несущего каната) не превышает 30% от расчетного",
        ],
      },
      {
        code: "1004050",
        text: "Что из перечисленного согласно Правилам безопасности грузовых подвесных канатных дорог не проводится при техническом освидетельствовании ГПКД?",
        answers: [
          "Проверка технического состояния оборудования путем осмотра и измерений",
          "Статические и динамические испытания",
          "Поверка контрольных (испытательных) грузов",
          "Учебная операция по отработке действий работников грузовых подвесных канатных дорог в случае возникновения возможной чрезвычайной ситуации",
          "Проверка эксплуатационной документации",
        ],
        correctAnswers: ["Поверка контрольных (испытательных) грузов"],
      },
      {
        code: "1004051",
        text: "Кем проводится техническое освидетельствование грузовых подвесных канатных дорог? Укажите все правильные ответы.",
        answers: [
          "Эксплуатирующей организацией при наличии квалифицированного персонала",
          "Инспектором Ростехнадзора",
          "Специализированной организацией в присутствии специалиста, ответственного за исправное состояние и безопасную эксплуатацию грузовых подъемных канатных дорог",
          "Специализированной организацией совместно с инспектором Ростехнадзора",
        ],
        correctAnswers: [
          "Эксплуатирующей организацией при наличии квалифицированного персонала",
          "Специализированной организацией в присутствии специалиста, ответственного за исправное состояние и безопасную эксплуатацию грузовых подъемных канатных дорог",
        ],
      },
      {
        code: "1004052",
        text: "Какое из перечисленных утверждений к лестницам для подъема на опоры и станции грузовых подвесных канатных дорог указано верно?",
        answers: [
          "Вертикальные лестницы опор высотой до 30 м и линейных станций при высоте более 5 м должны иметь, начиная с высоты 3 м, ограждения в виде дуг",
          "Лестницы высотой более 30 м должны иметь ограждения, начиная с высоты 1,8 м",
          "Лестницы высотой менее 30 м должны быть выполнены в виде маршей с площадками",
          "Все перечисленные утверждения верны",
        ],
        correctAnswers: [
          "Вертикальные лестницы опор высотой до 30 м и линейных станций при высоте более 5 м должны иметь, начиная с высоты 3 м, ограждения в виде дуг",
        ],
      },
      {
        code: "1004053",
        text: "С какой периодичностью необходимо контролировать и корректировать величину натяжения несущего каната при заякоривании обоих концов каната?",
        answers: [
          "Не менее одного раза в год",
          "Не менее одного раза в шесть месяцев",
          "Не менее одного раза в месяц",
          "Не менее одного раза в три месяца",
        ],
        correctAnswers: ["Не менее одного раза в шесть месяцев"],
      },
      {
        code: "1004054",
        text: "Какие сетки должны быть установлены в местах входа и выхода вагонеток при превышении уровня пола станции над уровнем земли более чем 0,5 м?",
        answers: [
          "Шириной не менее 3 м",
          "Шириной не менее 2 м",
          "Не регламентируется",
          "Шириной не менее 1 м",
        ],
        correctAnswers: ["Шириной не менее 1 м"],
      },
      {
        code: "1004055",
        text: "Каким образом должна осуществляться возможность останова привода грузовых подвесных канатных дорог?",
        answers: [
          "С пульта управления",
          "Всеми перечисленными способами",
          'От кнопок "СТОП", установленных на каждой станции',
          'От аварийных выключателей "Аварийный стоп"',
        ],
        correctAnswers: ["Всеми перечисленными способами"],
      },
      {
        code: "1004056",
        text: "Какую информацию должно содержать руководство по эксплуатации грузовых подвесных канатных дорог?",
        answers: [
          "Методы контроля и диагностики состояния элементов и узлов грузовых подвесных канатных дорог",
          "Всю перечисленную информацию",
          "Указания по выводу из эксплуатации и утилизации",
          "Программы и методики испытаний",
        ],
        correctAnswers: ["Всю перечисленную информацию"],
      },
      {
        code: "1004057",
        text: "Какое минимальное соотношение между натяжением несущего каната и весом груженой вагонетки?",
        answers: ["9", "10", "6", "7", "8"],
        correctAnswers: ["8"],
      },
      {
        code: "1004058",
        text: "Для каких нагрузок учитывается повышающий коэффициент нагрузок 1,1, соответствующий требованиям по надежности грузовых подвесных канатных дорог?",
        answers: [
          "Для нагрузок от натяжения тягового каната",
          "Для нагрузок от трения канатов по башмаку",
          "Для нагрузок от веса оборудования",
          "Для нагрузок от натяжения расчалочных канатов",
          "Для нагрузок от натяжения сетевых канатов",
        ],
        correctAnswers: ["Для нагрузок от веса оборудования"],
      },
      {
        code: "1004059",
        text: "Кто может быть председателем комиссии по принятию решения о возможности ввода ГПКД в эксплуатацию?",
        answers: [
          "Представитель организации-застройщика",
          "Представитель собственника (владельца)",
          "Представитель генерального подрядчика",
          "Представитель территориального органа Ростехнадзора",
        ],
        correctAnswers: ["Представитель собственника (владельца)"],
      },
      {
        code: "1004060",
        text: "Каким должно быть свободное боковое пространство между вагонеткой (с учетом поперечного качания каната и вагонеток) и сооружениями или естественными препятствиями в местах, где возможен проход людей?",
        answers: [
          "Не менее 2 м",
          "Не более 1,5 м",
          "Не более 1 м",
          "Не менее 1 м",
        ],
        correctAnswers: ["Не менее 2 м"],
      },
      {
        code: "1004061",
        text: "Какой повышающий коэффициент нагрузок соответствует требованиям по надежности для нагрузок от натяжения тягового каната?",
        answers: ["1", "1,2", "1,3", "1,4", "1,1"],
        correctAnswers: ["1,4"],
      },
      {
        code: "1004062",
        text: "В каком документе указывается срок очередного технического освидетельствования?",
        answers: [
          "В инструкции по эксплуатации",
          "В приказе о вводе в эксплуатацию",
          "В паспорте канатной дороги",
          "В плане-графике обслуживания грузовых подвесных канатных дорог",
        ],
        correctAnswers: ["В паспорте канатной дороги"],
      },
      {
        code: "1004063",
        text: "Какое из перечисленных требований к безопасности технологических процессов грузовых подвесных канатных дорог указано неверно?",
        answers: [
          "Минимальное усилие со стороны каната на каждый поддерживающий ролик одноканатных грузовых подвесных канатных дорог должно быть не менее 500 Н",
          "Увеличение натяжения каната на 40% приводит к отрыву каната и потере его контакта с роликом на опоре, наивысшая точка которой находится ниже хорды, соединяющей две соседние с ней опоры",
          "Роликовые балансиры на опорах одноканатных грузовых подвесных канатных дорог должны состоять не менее чем из двух роликов",
          "Поддерживающие ролики не должны вызывать отклонение несуще-тяговых и тяговых канатов более чем на 10%",
        ],
        correctAnswers: [
          "Увеличение натяжения каната на 40% приводит к отрыву каната и потере его контакта с роликом на опоре, наивысшая точка которой находится ниже хорды, соединяющей две соседние с ней опоры",
        ],
      },
      {
        code: "1004064",
        text: "Какой документ на ГПКД не является обязательным?",
        answers: [
          "Должностные инструкции для специалистов",
          "Производственные инструкции для персонала",
          "Журнал работы ГПКД и передачи смен",
          "График внепланового осмотра оборудования",
        ],
        correctAnswers: ["График внепланового осмотра оборудования"],
      },
      {
        code: "1004065",
        text: "Какими должны быть тяговые и несуще-тяговые канаты?",
        answers: [
          "Прядевыми двойной свивки с органическим сердечником",
          "Закрытой конструкции с двумя слоями клиновидной проволоки",
          "Тройной свивки с металлическим сердечником",
          "Двойной свивки с металлическим сердечником",
        ],
        correctAnswers: ["Прядевыми двойной свивки с органическим сердечником"],
      },
      {
        code: "1004066",
        text: "Кто должен по результатам экспертизы промышленной безопасности обеспечивать выполнение мероприятий по повышению уровня безопасности ГПКД в установленные сроки либо принимать решение о выводе ее из эксплуатации?",
        answers: [
          "Эксплуатирующая организация",
          "Организация, проводящая экспертизу промышленной безопасности",
          "Инспектор территориального органа Ростехнадзора",
        ],
        correctAnswers: ["Эксплуатирующая организация"],
      },
      {
        code: "1004067",
        text: "Какая устанавливается минимальная высота бортов предохранительных сетей?",
        answers: ["1,8 м", "1,2 м", "1 м", "1,5 м"],
        correctAnswers: ["1,2 м"],
      },
      {
        code: "1004068",
        text: "В ходе каких мероприятий устанавливаются сроки и объем текущего, среднего и капитального ремонта оборудования, металлоконструкций, сооружений? Выберите несколько вариантов ответа.",
        answers: [
          "Экспертиза промышленной безопасности",
          "Техническое освидетельствование",
          "Ежесменный технический осмотр",
          "Техническое обслуживание",
        ],
        correctAnswers: [
          "Экспертиза промышленной безопасности",
          "Техническое освидетельствование",
        ],
      },
      {
        code: "1004069",
        text: "На какие виды грузовых канатных дорог не распространяются требования Правил безопасности грузовых подвесных канатных дорог?",
        answers: [
          "Трехканатные кольцевые подвесные канатные дороги с отцепляемым на станциях подвижным составом",
          "Одноканатные кольцевые грузовые подвесные канатные дороги с неотцепляемым на станциях подвижным составом",
          "Двухканатные подвесные канатные дороги с отцепляемым на станциях подвижным составом",
          "Двухканатные грузовые подвесные канатные дороги с реверсивным (маятниковым) движением подвижного состава",
        ],
        correctAnswers: [
          "Трехканатные кольцевые подвесные канатные дороги с отцепляемым на станциях подвижным составом",
        ],
      },
      {
        code: "1004070",
        text: "Кем принимается решение о вводе в эксплуатацию грузовых подвесных канатных дорог?",
        answers: [
          "Ответственным за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО",
          "Руководителем организации-эксплуатанта",
          "Территориальным органом Ростехнадзора",
          "Техническим руководителем",
        ],
        correctAnswers: [
          "Ответственным за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО",
        ],
      },
      {
        code: "1004071",
        text: "При каком минимальном зазоре между встречными вагонетками при поперечном раскачивании канатов с вагонетками внутрь колеи от воздействия ветра, допустимого для эксплуатации грузовых подвесных канатных дорог, расстояние между двумя ветвями дороги будет удовлетворять данному условию?",
        answers: ["0,5 м", "1 м", "1,5 м", "0,3 м"],
        correctAnswers: ["0,5 м"],
      },
      {
        code: "1004072",
        text: "Какие требования установлены Правилами безопасности грузовых подвесных канатных дорог к площадкам для обслуживания оборудования ГПКД, расположенным на высоте более 2 м?",
        answers: [
          "Площадки должны иметь ограждение высотой не менее 0,5 м и сплошную зашивку по верху",
          "Площадки должны иметь ограждение высотой не менее 0,8 м",
          "Площадки должны иметь ограждение высотой не менее 0,8 м и сплошную зашивку",
          "Площадки должны иметь ограждение высотой не менее 1 м и сплошную зашивку понизу на высоте не менее 0,1 м",
        ],
        correctAnswers: [
          "Площадки должны иметь ограждение высотой не менее 1 м и сплошную зашивку понизу на высоте не менее 0,1 м",
        ],
      },
      {
        code: "1004073",
        text: "Какое из перечисленных требований к грузовым подвесным канатным дорогам указано верно?",
        answers: [
          "В машинном отделении должно быть устроено аварийное освещение",
          "Канат не требуется заземлять, если у него органический сердечник",
          "Не обязательно освещать участки входа и выхода вагонеток, если они двигаются на открытой местности",
          "Нумерация опор обязательна только при условии, что их количество больше 10",
        ],
        correctAnswers: [
          "В машинном отделении должно быть устроено аварийное освещение",
        ],
      },
      {
        code: "1004074",
        text: "С какой целью проводится техническое освидетельствование ГПКД?",
        answers: [
          "С целью установления соответствия ГКПД и ее устройства требованиям Правил безопасности грузовых подвесных канатных дорог и паспортным данным",
          "С целью установления запаса прочности каната",
          "С целью установления соответствия ГКПД и ее устройства требованиям сертификатов безопасности",
        ],
        correctAnswers: [
          "С целью установления соответствия ГКПД и ее устройства требованиям Правил безопасности грузовых подвесных канатных дорог и паспортным данным",
        ],
      },
      {
        code: "1004075",
        text: "Какой запас прочности должны иметь зажимы на ГПКД с учетом возможного уменьшения диаметра каната?",
        answers: [
          "Не менее 1,5",
          "Не менее 1,1",
          "Не менее 2,0",
          "Не менее 2,5",
        ],
        correctAnswers: ["Не менее 1,5"],
      },
      {
        code: "1004076",
        text: "Какой должен быть коэффициент запаса сцепления тягового каната с приводным шкивом?",
        answers: [
          "Не менее 1,1",
          "Не менее 1,5",
          "Не менее 2,1",
          "Не менее 2,5",
        ],
        correctAnswers: ["Не менее 1,1"],
      },
      {
        code: "1004077",
        text: "Какие требования предъявляются федеральными нормами и правилами в области промышленной безопасности «Требования к производству сварочных работ на опасных производственных объектах» к сварочному оборудованию и сварочным материалам, применяемым при выполнении сварочных работ?",
        answers: [
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
          "Оборудование и материалы должны быть задекларированы",
          "Оборудование и материалы должны иметь сертификаты безопасности",
        ],
        correctAnswers: [
          "Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "1004078",
        text: "Каким образом допускается маркировать сварное соединение, выполненное несколькими сварщиками (бригадой сварщиков)?",
        answers: [
          "Допускается маркировка нанесением краски (символы и цвет определяются распорядительным документом организации)",
          "Допускается маркировка нанесением металлической таблички с номерами клейм сварщиков",
          "Допускается применение клейма сварщика, имеющего наибольший опыт работы",
          "Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
        ],
        correctAnswers: [
          "Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы",
        ],
      },
      {
        code: "1004079",
        text: "Какая документация оформляется в процессе выполнения сварочных работ?",
        answers: [
          "Только заключения по неразрушающему контролю",
          "Только протоколы сварочных работ",
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
        ],
        correctAnswers: [
          "Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации",
        ],
      },
      {
        code: "1004080",
        text: "Кто допускается к выполнению сварочных работ на опасном производственном объекте?",
        answers: [
          "Только сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ",
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
          "Любые работники, аттестованные для соответствующих способов сварки",
        ],
        correctAnswers: [
          "Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов",
        ],
      },
      {
        code: "1004081",
        text: "Какую проверку должен пройти сварщик, приступающий к сварке на конкретном объекте впервые или после перерыва в работе продолжительностью более установленного НД, независимо от наличия аттестационного удостоверения, до начала производства работ?",
        answers: [
          "Проверку знания технологий сварки",
          "Проверку путем выполнения контрольного сварного соединения и проверку знаний по охране труда",
          "Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте, с получением положительных результатов контроля их качества",
        ],
        correctAnswers: [
          "Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте, с получением положительных результатов контроля их качества",
        ],
      },
      {
        code: "1004082",
        text: "К выполнению каких работ могут быть допущены сварщики и специалисты сварочного производства?",
        answers: [
          "К выполнению любых сварочных работ при наличии действующего удостоверения сварщика",
          "К выполнению сварочных работ, указанных в действующих документах",
          "К выполнению любых сварочных работ при наличии удостоверения сварщика и после выполнения контрольного сварного соединения",
        ],
        correctAnswers: [
          "К выполнению сварочных работ, указанных в действующих документах",
        ],
      },
      {
        code: "1004083",
        text: "Что обязано проверить и обеспечить лицо, осуществляющее руководство сварочными работами, перед выполнением сварочных работ?",
        answers: [
          "Соответствие основных и сварочных материалов, применяемой технологии сварки требованиям производственно-технологической документации",
          "Соответствие численного состава и квалификации персонала сварочного производства",
          "Соответствие сборочного и сварочного оборудования, применяемой технологии сварки требованиям производственно-технологической документации",
          "Выполнение всех подготовительных мероприятий",
        ],
        correctAnswers: ["Выполнение всех подготовительных мероприятий"],
      },
      {
        code: "1004084",
        text: "Чем должно быть укомплектовано место производства сварочных работ?",
        answers: [
          "Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД",
          "Только сварочным оборудованием",
          "Только сварочным оборудованием и аптечкой первой помощи",
        ],
        correctAnswers: [
          "Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД",
        ],
      },
      {
        code: "1004085",
        text: "Какие действия должны быть осуществлены при выполнении многопроходных швов после наложения каждого валика поверхности шва и кромки разделки?",
        answers: [
          "Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)",
          "Должны быть проверены ультразвуком",
          "Должны быть смочены керосином, проверены на предмет пор и обезжирены",
        ],
        correctAnswers: [
          "Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)",
        ],
      },
      {
        code: "1004086",
        text: "Что должно быть указано в технологических картах сварки?",
        answers: [
          "Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений",
          "Требования к работнику, выполняющему сварку, используемые материалы и необходимое оборудование",
          "Требования к оборудованию, режимы сварки и последовательность действий",
        ],
        correctAnswers: [
          "Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений",
        ],
      },
      {
        code: "1004087",
        text: "Какие функции обязано выполнить лицо, осуществляющее руководство сварочными работами, назначенное распорядительным документом организации или (и) должностной инструкцией которого предусмотрено руководство сварочными работами, перед выполнением сварочных работ?",
        answers: [
          "Организовать проведение операционного контроля",
          "Проверить и обеспечить соответствие численного состава и квалификации персонала сварочного производства, сборочного и сварочного оборудования, основных и сварочных материалов, применяемой технологии сварки требованиям производственно-технологической документации",
          "Ознакомить сварщиков с требованиями технологических карт сварки, а также с внесенными в них изменениями (при наличии) с подтверждением ознакомления подписями сварщиков в применяемых ими технологических картах сварки",
          "Все перечисленные функции",
        ],
        correctAnswers: ["Все перечисленные функции"],
      },
    ],
  },
  87: {
    21001: [
      {
        code: "21001000",
        text: "Какой нормативный документ устанавливает правовые и организационные условия функционирования железнодорожного транспорта?",
        answers: [
          'Федеральный закон от 21 июля 1997 г. N 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
          'Федеральный закон от 10 января 2003 г. N 17-ФЗ "О железнодорожном транспорте в Российской Федерации"',
          'Федеральный закон от 10 января 2003 г. N 18-ФЗ "Устав железнодорожного транспорта Российской Федерации"',
        ],
        correctAnswers: [
          'Федеральный закон от 10 января 2003 г. N 17-ФЗ "О железнодорожном транспорте в Российской Федерации"',
        ],
      },
      {
        code: "21001001",
        text: "Какой нормативный документ регулирует отношения, возникающие между перевозчиками, грузоотправителями, грузополучателями?",
        answers: [
          'Федеральный закон от 10 января 2003 г. N 17-ФЗ "О железнодорожном транспорте в Российской Федерации"',
          'Федеральный закон от 10 января 2003 г. N 18-ФЗ "Устав железнодорожного транспорта Российской Федерации"',
          "Правила технической эксплуатации железных дорог Российской Федерации",
        ],
        correctAnswers: [
          'Федеральный закон от 10 января 2003 г. N 18-ФЗ "Устав железнодорожного транспорта Российской Федерации"',
        ],
      },
      {
        code: "21001002",
        text: "На кого возлагается ответственность при подготовке груза к перевозке железнодорожным транспортом?",
        answers: [
          "На руководство железнодорожной станции, принимающей груз к перевозке",
          "На грузоотправителя (отправителя)",
          "На грузополучателя (получателя)",
          "На владельца железнодорожного пути",
        ],
        correctAnswers: ["На грузоотправителя (отправителя)"],
      },
      {
        code: "21001003",
        text: "В каком случае разрешается следование поезда с вагонами, загруженными взрывчатыми материалами, имеющими неисправности, выявленные средствами автоматического контроля технического состояния железнодорожного подвижного состава и его ходовых частей?",
        answers: [
          "В любом случае разрешается",
          "В любом случае запрещается",
          "В случае наличия письменного согласия владельцев инфраструктуры или владельцев железнодорожных путей",
          "В случае согласования с территориальными органами Федеральной службы по экологическому, технологическому и атомному надзору",
        ],
        correctAnswers: ["В любом случае запрещается"],
      },
      {
        code: "21001004",
        text: "Что следует предпринять при обнаружении в движущемся поезде с вагонами, загруженными взрывчатыми материалами, возгорания какого-либо груза или железнодорожного подвижного состава?",
        answers: [
          "Остановку поезда с учетом наименьших последствий, представляющих только угрозу загрязнения окружающей среды",
          "Остановку поезда с учетом наименьших последствий, представляющих угрозу поражения людей и загрязнения окружающей среды, повреждения тоннелей, мостов, жилых и станционных зданий, складов, находящегося на путях железнодорожного подвижного состава",
          "Остановку поезда с учетом наименьших последствий, представляющих только угрозу поражения людей",
        ],
        correctAnswers: [
          "Остановку поезда с учетом наименьших последствий, представляющих угрозу поражения людей и загрязнения окружающей среды, повреждения тоннелей, мостов, жилых и станционных зданий, складов, находящегося на путях железнодорожного подвижного состава",
        ],
      },
      {
        code: "21001005",
        text: "На какое безопасное расстояние должны быть удалены вагоны с взрывчатыми материалами из опасной зоны в случае возникновения пожара в вагоне, не загруженном взрывчатыми материалами, или в рядом расположенном здании?",
        answers: [
          "Не менее чем на 50 м",
          "Не менее чем на 70 м",
          "Не менее чем на 100 м",
        ],
        correctAnswers: ["Не менее чем на 100 м"],
      },
      {
        code: "21001006",
        text: "В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "В целях координации действий персонала при возникновении аварии",
          "В целях регулирования действий подразделений муниципальной пожарной охраны при возникновении аварии",
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
          "В целях обеспечения соответствия объекта требованиям промышленной безопасности",
        ],
        correctAnswers: [
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
        ],
      },
      {
        code: "21001007",
        text: "Когда план мероприятий по локализации и ликвидации последствий аварий считается принятым?",
        answers: [
          "После утверждения руководителем организации, эксплуатирующей опасные производственные объекты, или руководителями обособленных подразделений",
          "После утверждения руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях) и согласования руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
          "После утверждения руководителем организации, эксплуатирующей опасные производственные объекты, или руководителями обособленных подразделений и согласования с органами Федеральной службы по экологическому, технологическому и атомному надзору",
        ],
        correctAnswers: [
          "После утверждения руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях) и согласования руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
        ],
      },
      {
        code: "21001008",
        text: "Из каких разделов состоит план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Только изобщих разделов",
          "Из разделов, предусмотренных требованиями Федеральных норм и правил в области промышленной безопасности",
          "Из общих и специальных разделов",
          "Только изспециальных разделов",
        ],
        correctAnswers: ["Из общих и специальных разделов"],
      },
      {
        code: "21001009",
        text: "Что из перечисленного не содержится в аварийной карточке на перевозку опасного груза?",
        answers: [
          "Номер ООН",
          "Наименование опасного груза",
          "Предприятие - производитель опасного груза",
          "Указания по применению средств индивидуальной защиты",
        ],
        correctAnswers: ["Предприятие - производитель опасного груза"],
      },
      {
        code: "21001010",
        text: "Что из перечисленного не регламентируется по действиям при аварийной ситуации?",
        answers: [
          "Указания по действиям при пожаре",
          "Указания по нейтрализации опасных веществ",
          "Указания по применению средств индивидуальной защиты",
          "Указания по мерам первой помощи",
        ],
        correctAnswers: [
          "Указания по применению средств индивидуальной защиты",
        ],
      },
      {
        code: "21001011",
        text: "Где приведены основные формы проявления транспортной опасности грузов, а также конкретные меры предосторожности, которые должны соблюдаться при ликвидации аварийных ситуаций с опасными грузами?",
        answers: [
          "В групповых или индивидуальных аварийных карточках",
          "В Положении о допуске перевозчиков к осуществлению железнодорожных перевозок",
          "В Плане мероприятий по локализации и ликвидации последствий аварий",
          "В Правилах безопасности при перевозке опасных грузов железнодорожным транспортом",
        ],
        correctAnswers: ["В групповых или индивидуальных аварийных карточках"],
      },
      {
        code: "21001012",
        text: "Каким из перечисленных способов осуществляется поиск необходимой аварийной карточки на опасные грузы, перевозимые по железным дорогам?",
        answers: [
          "Только по указателю поиска аварийной карточки по наименованию опасного груза",
          "Только по указателю поиска аварийной карточки по номеру ООН",
          "Только по указателю поиска аварийной карточки на взрывчатые материалы по условному номеру или номеру ООН",
          "Любым из перечисленных способов",
        ],
        correctAnswers: ["Любым из перечисленных способов"],
      },
      {
        code: "21001013",
        text: "Какой из перечисленных национальных стандартов, в соответствии с которым осуществляется классификация опасных грузов, указан верно?",
        answers: [
          "ГОСТ Р 57479-2017. Грузы опасные. Маркировка",
          "ГОСТ 19433-88. Грузы опасные. Классификация и маркировка",
          "ГОСТ 26319-2020. Грузы опасные. Упаковка",
        ],
        correctAnswers: [
          "ГОСТ 19433-88. Грузы опасные. Классификация и маркировка",
        ],
      },
      {
        code: "21001014",
        text: 'Сколько классов опасных грузов определяется по ГОСТ 19433-88 "Грузы опасные. Классификация и маркировка"?',
        answers: ["4 класса", "8 классов", "9 классов", "6 классов"],
        correctAnswers: ["9 классов"],
      },
      {
        code: "21001015",
        text: "Каковы критерии отнесения опасного груза к классу и подклассу?",
        answers: [
          "По виду опасности",
          "По объему перевозимого груза и степени опасности",
          "По виду и степени опасности груза, по установленному приоритету вида опасности",
          "По двум или более видам опасности",
        ],
        correctAnswers: [
          "По виду и степени опасности груза, по установленному приоритету вида опасности",
        ],
      },
      {
        code: "21001016",
        text: "Какие грузы относятся к 2 классу опасности?",
        answers: [
          "Газы сжатые, сжиженные и растворенные под давлением",
          "Ядовитые вещества",
          "Легковоспламеняющиеся жидкости",
          "Окисляющие вещества",
        ],
        correctAnswers: ["Газы сжатые, сжиженные и растворенные под давлением"],
      },
      {
        code: "21001017",
        text: "Какие опасные грузы относятся к 3 классу опасности?",
        answers: [
          "Твердые легковоспламеняющиеся вещества",
          "Жидкие легковоспламеняющиеся вещества",
          "Самовозгорающиеся вещества",
          "Газы сжатые, сжиженные и растворенные под давлением",
        ],
        correctAnswers: ["Жидкие легковоспламеняющиеся вещества"],
      },
      {
        code: "21001018",
        text: "Какие опасные грузы относятся к 4 классу опасности?",
        answers: [
          "Взрывчатые материалы",
          "Окисляющие вещества",
          "Токсичные вещества",
          "Легковоспламеняющиеся твердые вещества, самовозгорающиеся вещества, вещества, выделяющие воспламеняющиеся газы при взаимодействии с водой",
        ],
        correctAnswers: [
          "Легковоспламеняющиеся твердые вещества, самовозгорающиеся вещества, вещества, выделяющие воспламеняющиеся газы при взаимодействии с водой",
        ],
      },
      {
        code: "21001019",
        text: "Какие опасные грузы относятся к 5 классу опасности?",
        answers: [
          "Ядовитые вещества",
          "Жидкие легковоспламеняющиеся вещества",
          "Самовозгорающиеся вещества",
          "Окисляющие вещества и органические пероксиды",
        ],
        correctAnswers: ["Окисляющие вещества и органические пероксиды"],
      },
      {
        code: "21001020",
        text: "Какие опасные грузы относятся к 6 классу опасности?",
        answers: [
          "Взрывчатые вещества",
          "Легковоспламеняющиеся вещества",
          "Ядовитые и инфекционные вещества",
          "Радиоактивные материалы",
        ],
        correctAnswers: ["Ядовитые и инфекционные вещества"],
      },
      {
        code: "21001021",
        text: "Какие опасные грузы относятся к 7 классу опасности?",
        answers: [
          "Твердые легковоспламеняющиеся вещества",
          "Радиоактивные материалы",
          "Взрывчатые вещества",
          "Окисляющие вещества",
        ],
        correctAnswers: ["Радиоактивные материалы"],
      },
      {
        code: "21001022",
        text: "Какие опасные грузы относятся к 8 классу опасности?",
        answers: [
          "Вещества, выделяющие воспламеняющиеся газы при взаимодействии с водой",
          "Ядовитые вещества",
          "Окисляющие вещества",
          "Едкие и коррозионные вещества",
        ],
        correctAnswers: ["Едкие и коррозионные вещества"],
      },
      {
        code: "21001023",
        text: "Какую маркировку должна содержать каждая грузовая единица, содержащая опасный груз?",
        answers: [
          "Транспортное наименование груза",
          "Вид и степень опасности груза",
          "Номер аварийной карточки",
          "Адрес получателя груза",
        ],
        correctAnswers: ["Вид и степень опасности груза"],
      },
      {
        code: "21001024",
        text: "В каком случае допускается совмещение маркировки, характеризующей вид и степень опасности, с транспортной маркировкой и маркировкой, характеризующей груз, на одном ярлыке?",
        answers: [
          "Допускается, размер ярлыка должен быть увеличен на величину, кратную количеству знаков",
          "Не допускается ни в каком случае",
          "Допускается в случае согласования с Министерством транспорта Российской Федерации",
          "Допускается только в случае, если груз класса опасности 1",
        ],
        correctAnswers: [
          "Допускается, размер ярлыка должен быть увеличен на величину, кратную количеству знаков",
        ],
      },
      {
        code: "21001025",
        text: "Знак опасности какого класса имеет чередующиеся равноотстоящие черные и белые полосы?",
        answers: [
          "Класс опасности 4",
          "Класс опасности 5",
          "Класс опасности 7",
          "Класс опасности 9",
          "Класс опасности 3",
        ],
        correctAnswers: ["Класс опасности 9"],
      },
      {
        code: "21001026",
        text: "Каким образом образовываются классификационные шифры опасных грузов класса 1?",
        answers: [
          "Набором цифр, первые две из которых соответствуют подклассу, третья - номеру категории, четвертая - группе, и буквенным обозначением группы совместимости",
          "Набором цифр, первые две из которых соответствуют подклассу, третья - номеру категории, и буквенным обозначением группы совместимости",
          "Двумя цифрами, соответствующими подклассу, и буквенным обозначением группы совместимости",
          "Набором цифр, первые две из которых соответствуют подклассу, третья - номеру категории, четвертая – группе",
        ],
        correctAnswers: [
          "Двумя цифрами, соответствующими подклассу, и буквенным обозначением группы совместимости",
        ],
      },
      {
        code: "21001027",
        text: "К какому классу опасности относятся пиротехнические вещества, составы и изделия?",
        answers: [
          "К классу опасности 1",
          "К классу опасности 2",
          "К классу опасности 3",
          "К классу опасности 9",
          "К классу опасности 5",
        ],
        correctAnswers: ["К классу опасности 1"],
      },
      {
        code: "21001028",
        text: "По каким показателям определяется степень опасности легковоспламеняющихся жидкостей?",
        answers: [
          "По температуре вспышки и температуре кипения",
          "По текучести и вязкости",
          "По объему и плотности",
          "По температуре горения",
        ],
        correctAnswers: ["По температуре вспышки и температуре кипения"],
      },
      {
        code: "21001029",
        text: "По какому показателю определяют степень опасности веществ, выделяющих легковоспламеняющиеся газы при соприкосновении с водой?",
        answers: [
          "По температуре газообразования",
          "По относительному объему газовыделения",
          "По интенсивности газовыделения",
          "По уровню водостойкости",
        ],
        correctAnswers: ["По интенсивности газовыделения"],
      },
      {
        code: "21001030",
        text: "Куда наносится маркировка, характеризующая вид и степень опасности груза, при транспортировании груза в контейнере?",
        answers: [
          "На дверь, боковую стенку и, если позволяет конструкция, крышу",
          "На днище и боковую стенку",
          "На торец вагона контрастного фона или ярлык рядом с манипуляционными знаками по ГОСТ 14192-77",
          "Только на дверь контейнера",
        ],
        correctAnswers: [
          "На дверь, боковую стенку и, если позволяет конструкция, крышу",
        ],
      },
      {
        code: "21001031",
        text: "В каком случае допускается совмещение маркировки, характеризующей вид и степень опасности, с транспортной маркировкой и маркировкой, характеризующей груз, на одном ярлыке?",
        answers: [
          "Допускается только после согласования с Министерством транспорта Российской Федерации",
          "Допускается только для грузов классов опасности 1 и 2",
          "Допускается, размер ярлыка должен быть увеличен на величину, кратную количеству знаков",
          "Не допускается в любом случае",
        ],
        correctAnswers: [
          "Допускается, размер ярлыка должен быть увеличен на величину, кратную количеству знаков",
        ],
      },
      {
        code: "21001032",
        text: "На сколько допускается уменьшать размер знака опасности?",
        answers: [
          "До 15 мм",
          "До 25 мм",
          "До 20 мм",
          "Уменьшать размер знака опасности не допускается",
        ],
        correctAnswers: ["До 25 мм"],
      },
      {
        code: "21001033",
        text: "В каком случае в нормативно-технической документации на продукцию указывают температуру вспышки?",
        answers: [
          "Температуру вспышки, если она не более 61 °С",
          "Температуру вспышки, если она более 61 °С",
          "Температуру вспышки, если она более 75 °С",
          "Температуру вспышки, если она не более 80 °С",
        ],
        correctAnswers: ["Температуру вспышки, если она не более 61 °С"],
      },
      {
        code: "21001034",
        text: "Какие данные необходимо установить для присвоения опасному грузу классификационного шифра?",
        answers: [
          "Только виды опасности, характеризующие груз и степень опасности",
          "Только класс (подкласс), к которому относится груз",
          "Только категорию, характеризующую дополнительный вид опасности",
          "Только группу, характеризующую степень опасности груза",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "21001035",
        text: "Какой из перечисленных газов относится к группе 3 опасных грузов класса 2?",
        answers: [
          "Сжатые газы, критическая температура которых менее -10 °С",
          "Сжиженные охлажденные газы, транспортируемые под давлением, близким к атмосферному",
          "Сжиженные газы, критическая температура которых не менее 70 °С",
          "Сжиженные газы, критическая температура которых не менее -10 °С, но менее 70 °С",
        ],
        correctAnswers: [
          "Сжиженные газы, критическая температура которых не менее 70 °С",
        ],
      },
      {
        code: "21001036",
        text: "Какие вещества относятся к категории 912 опасных грузов класса 9?",
        answers: [
          "Жидкости с температурой вспышки более 61 °C, но не более 90 °C",
          "Твердые вещества, воспламеняющиеся от действия (не менее 30 секунд, но не более 120 секунд) газовой горелки",
          "Вещества, которые в условиях специальных испытаний способны самонагреваться до температуры более 150 °С, но не более 200 °С за время не более 24 часов при температуре окружающей среды 140 °С",
          "Воспламеняющиеся, ядовитые, едкие и (или) коррозионные вещества в аэрозольной упаковке вместимостью от 50 до 1000 см³",
        ],
        correctAnswers: [
          "Жидкости с температурой вспышки более 61 °C, но не более 90 °C",
        ],
      },
      {
        code: "21001037",
        text: "В каком месте основного знака опасности указывается номер класса (подкласса), к которому отнесен груз?",
        answers: [
          "Не регламентируется",
          "В верхнем углу",
          "В нижнем углу",
          "Вдоль условной горизонтальной диагонали",
        ],
        correctAnswers: ["В нижнем углу"],
      },
      {
        code: "21001038",
        text: "В соответствии с чем устанавливаются обязательные требования к железнодорожному подвижному составу, используемому для организации перевозочного процесса, и формы подтверждения соответствия?",
        answers: [
          "С законодательством Российской Федерации о техническом регулировании",
          "С законодательством Российской Федерации о безопасности движения и эксплуатации железнодорожного транспорта, сохранности перевозимых грузов",
          "С законодательством Российской Федерации об экологической безопасности и охране окружающей среды",
          "С законодательством Российской Федерации об охране труда, безопасности жизни и здоровья граждан",
        ],
        correctAnswers: [
          "С законодательством Российской Федерации о техническом регулировании",
        ],
      },
      {
        code: "21001039",
        text: "В течение какого времени после наполнения жидким хлором осуществляется отстой вагонов-цистерн с проведением визуального осмотра и контролем утечек хлора?",
        answers: [
          "Не менее 4 часов",
          "Не менее 8 часов",
          "Не менее 12 часов",
          "Не менее суток",
        ],
        correctAnswers: ["Не менее суток"],
      },
      {
        code: "21001040",
        text: "Проведение каких работ является обязательным при постановке вагона-цистерны на пункт слива-налива?",
        answers: [
          "Проверка работоспособности крепительных устройств крышки люка",
          "Надежное закрепление вагона-цистерны ручными тормозами или башмаками с обеих сторон и заземление",
          "Проверка исправности ходовой части вагона-цистерны",
          "Проверка наличия знаков опасности, надписей и трафаретов",
        ],
        correctAnswers: [
          "Надежное закрепление вагона-цистерны ручными тормозами или башмаками с обеих сторон и заземление",
        ],
      },
      {
        code: "21001041",
        text: "Каким способом хранение жидкого хлора в резервуарах (танках, контейнерах-цистернах) не осуществляется?",
        answers: [
          "В захоложенном состоянии при температуре ниже температуры окружающей среды",
          "При температуре кипения жидкого хлора при атмосферном давлении (изотермическом способе хранения)",
          "При температуре плавления жидкого хлора",
          "При температуре окружающей среды",
        ],
        correctAnswers: ["При температуре плавления жидкого хлора"],
      },
      {
        code: "21001042",
        text: "Размещение какого количества одновременно отправляемых или поступающих вагонов-цистерн с жидким хлором должен обеспечивать отстойный путь (тупик)?",
        answers: [
          "Всей партии, но не более 12 штук",
          "Всей партии, но не более 10 штук",
          "Половины партии, при этом не более 15 штук",
          "Половины партии, при этом не более 14 штук",
        ],
        correctAnswers: ["Всей партии, но не более 10 штук"],
      },
      {
        code: "21001043",
        text: "В каком случае допускается использование парковой связи железнодорожных станций для оповещения о работах, производимых с вагонами, загруженными взрывчатыми веществами?",
        answers: [
          "В случае согласования с владельцем железнодорожных путей",
          "В случае отсутствия других средств связи",
          "В случае согласования с территориальными органами Федеральной службы по экологическому, технологическому и атомному надзору",
          "Ни в каком случае",
        ],
        correctAnswers: ["В случае отсутствия других средств связи"],
      },
      {
        code: "21001044",
        text: "В каких поездах должны перевозиться опасные грузы класса 1 (взрывчатые материалы)?",
        answers: [
          "В пассажирских и почтово-багажных (кроме перевозок табельного оружия и боеприпасов к нему, воинских караулов и команд Министерства обороны Российской Федерации и Министерства внутренних дел Российской Федерации, других государственных военизированных организаций и нарядов военизированной охраны железнодорожного транспорта)",
          "В людских, а также имеющих в составе (кроме воинских эшелонов) отдельные вагоны с людьми (кроме вагонов, занятых личным составом эшелона)",
          "В грузовых поездах одиночными вагонами, группами вагонов с постановкой во всех случаях соответствующего прикрытия, сформированных в пределах норм по весу и длине, предусмотренных графиком движения поездов, а также маршрутами, не превышающими вместимость приемоотправочных путей на участках их следования",
          "В соединенных поездах",
          "В поездах с негабаритными грузами верхней третьей, нижней третьей и больших степеней, боковой четвертой и больших степеней негабаритности",
          "В поездах, превышающих длину, установленную графиком движения поездов",
        ],
        correctAnswers: [
          "В грузовых поездах одиночными вагонами, группами вагонов с постановкой во всех случаях соответствующего прикрытия, сформированных в пределах норм по весу и длине, предусмотренных графиком движения поездов, а также маршрутами, не превышающими вместимость приемоотправочных путей на участках их следования",
        ],
      },
      {
        code: "21001045",
        text: "Допускается ли оставление поездов с вагонами, загруженными взрывчатыми веществами, без локомотивов на промежуточных железнодорожных станциях?",
        answers: [
          "В любом случае не допускается",
          "В случае согласования с территориальными органами Федеральной службы по экологическому, технологическому и атомному надзору",
          "Только в случае неисправности локомотива",
          "В исключительных случаях (при возникновении аварийной ситуации) состав поезда с взрывчатыми материалами может быть временно оставлен на промежуточной железнодорожной станции без локомотива по письменному приказу владельца инфраструктуры или владельца железнодорожных путей необщего пользования",
        ],
        correctAnswers: [
          "В исключительных случаях (при возникновении аварийной ситуации) состав поезда с взрывчатыми материалами может быть временно оставлен на промежуточной железнодорожной станции без локомотива по письменному приказу владельца инфраструктуры или владельца железнодорожных путей необщего пользования",
        ],
      },
      {
        code: "21001046",
        text: "Что из перечисленного проводится владельцем инфраструктуры при осуществлении осмотра железнодорожного подвижного состава для решения вопроса о допуске его на железнодорожные пути общего пользования?",
        answers: [
          "Только сверка идентификационных номеров составных частей, определенных данными информационных систем учета железнодорожного подвижного состава",
          "Только идентификация железнодорожного подвижного состава по техническим и эксплуатационным характеристикам, указанным в техническом паспорте (формуляре)",
          "Все перечисленное, а также проверка наличия документов об обязательном подтверждении соответствия вновь изготовленного, модернизированного железнодорожного подвижного состава",
        ],
        correctAnswers: [
          "Все перечисленное, а также проверка наличия документов об обязательном подтверждении соответствия вновь изготовленного, модернизированного железнодорожного подвижного состава",
        ],
      },
      {
        code: "21001047",
        text: "Кто обязан провести промывку и дезинфекцию вагонов, контейнеров после выгрузки опасных грузов в случаях, предусмотренных правилами перевозок грузов железнодорожным транспортом?",
        answers: [
          "Грузополучатель",
          "Грузоотправитель",
          "Перевозчик",
          "Владелец инфраструктуры",
        ],
        correctAnswers: ["Грузополучатель"],
      },
      {
        code: "21001048",
        text: "Какие буквы добавляются к номеру поезда при сообщении в случае наличия в нем вагонов с опасными грузами класса 1 (взрывчатыми материалами)?",
        answers: ["ВМ", "ПМ", "ПД", "ТТ", "ПБ"],
        correctAnswers: ["ВМ"],
      },
      {
        code: "21001049",
        text: "Кем должен обслуживаться сборный поезд, в состав которого включены вагоны со взрывчатыми материалами?",
        answers: [
          "Только работником составительской бригады",
          "Только работником кондукторской бригады",
          "Только помощником машиниста, выполняющим обязанности главного кондуктора",
          "Всеми перечисленными лицами",
        ],
        correctAnswers: ["Всеми перечисленными лицами"],
      },
      {
        code: "21001050",
        text: "Кем устанавливается порядок ликвидации аварийных ситуаций с опасными грузами при перевозках их по железным дорогам?",
        answers: [
          "Железнодорожной администрацией по согласованию с компетентными органами государства",
          "Представителями организации, осуществляющей транспортно-экспедиционное обслуживание грузоотправителей и грузополучателей",
          "Представителями МЧС России и Министерства транспорта РФ по двустороннему соглашению",
          "Представителями Ростехнадзора и Министерства транспорта РФ по двустроннему соглашению",
        ],
        correctAnswers: [
          "Железнодорожной администрацией по согласованию с компетентными органами государства",
        ],
      },
      {
        code: "21001051",
        text: "Каким документом устанавливается перечень опасных грузов, погрузка и выгрузка которых в местах общего и необщего пользования не допускаются?",
        answers: [
          "Правилами перевозок грузов железнодорожным транспортом",
          "Правилами технической эксплуатации железных дорог Российской Федерации",
          "Правилами по охране труда в хозяйстве перевозок федерального железнодорожного транспорта",
          "Всеми перечисленными документами",
        ],
        correctAnswers: [
          "Правилами перевозок грузов железнодорожным транспортом",
        ],
      },
      {
        code: "21001052",
        text: "Каким документом устанавливаются меры безопасности, подлежащие соблюдению при приеме и отправлении поездов с негабаритными и опасными грузами класса 1 (взрывчатые материалы) на железнодорожных путях необщего пользования?",
        answers: [
          "Распоряжением руководителя службы производственного контроля",
          'Федеральным законом от 10 января 2003 г. N 17-ФЗ "О железнодорожном транспорте в Российской Федерации"',
          "Инструкцией о порядке работы с вагонами, загруженными опасными грузами",
          "Распоряжением начальника службы охраны труда",
        ],
        correctAnswers: [
          "Инструкцией о порядке работы с вагонами, загруженными опасными грузами",
        ],
      },
      {
        code: "21001053",
        text: "На каком расстоянии должен ставиться вагон с тормозной площадкой или специально выделенный порожний крытый вагон от вагонов с взрывчатыми материалами, сопровождаемые ведомственной охраной железнодорожного транспорта Российской Федерации?",
        answers: [
          "Не далее чем за 8 вагонов от вагонов со взрывчатыми материалами",
          "По усмотрению грузоотправителя (грузополучателя)",
          "Не далее чем за 6 вагонов от вагонов со взрывчатыми материалами",
          "Не далее чем за 5 вагонов от вагонов со взрывчатыми материалами",
        ],
        correctAnswers: [
          "Не далее чем за 5 вагонов от вагонов со взрывчатыми материалами",
        ],
      },
      {
        code: "21001054",
        text: "В каком случае допускается ставить в поезда грузопассажирские вагоны с опасными грузами класса I (взрывчатыми материалами)?",
        answers: [
          "В исключительном случае на малоинтенсивных линиях (участках), где никакие другие поезда, кроме грузопассажирских, не обращаются",
          "В случае наличия решения владельца железнодорожных путей необщего пользования и в порядке, им установленном",
          "В случае наличия соответствующих указаний в техническо-распорядительном акте",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "21001055",
        text: "Какое из перечисленных требований к вагонам с опасными грузами класса I (взрывчатыми материалами) указано неверно?",
        answers: [
          "Вагоны с опасными грузами класса I (взрывчатыми материалами) при стоянке на железнодорожной станции вне поездов, за исключением находящихся под накоплением на железнодорожных путях сортировочных парков, должны устанавливаться на специально выделенных железнодорожных путях, указанных в техническо-распорядительном акте железнодорожной станции",
          "Стрелки, ведущие на железнодорожный путь стоянки таких вагонов, устанавливаются в положение, допускающее возможность заезда на этот железнодорожный путь железнодорожного подвижного состава",
          "Вагоны с опасными грузами класса I (взрывчатыми материалами) при стоянке на железнодорожной станции вне поездов, за исключением находящихся под накоплением на железнодорожных путях сортировочных парков, должны быть ограждены переносными сигналами остановки",
          "Все перечисленные требования указаны верно",
        ],
        correctAnswers: [
          "Стрелки, ведущие на железнодорожный путь стоянки таких вагонов, устанавливаются в положение, допускающее возможность заезда на этот железнодорожный путь железнодорожного подвижного состава",
        ],
      },
      {
        code: "21001056",
        text: "Каким образом ограждаются вагоны с опасными грузами класса I (взрывчатыми материалами), стоящие на отдельных железнодорожных путях?",
        answers: [
          "Переносными красными сигналами, устанавливаемыми на железнодорожных путях общего пользования на оси железнодорожного пути на расстоянии не менее 30 м, а на железнодорожных путях необщего пользования - не менее 10 м",
          "Переносными красными сигналами, устанавливаемыми на железнодорожных путях общего пользования на оси железнодорожного пути на расстоянии не менее 50 м, а на железнодорожных путях необщего пользования - не менее 15 м",
          "Переносными желтыми сигналами, устанавливаемыми на железнодорожных путях общего пользования на оси железнодорожного пути на расстоянии не менее 50 м, а на железнодорожных путях необщего пользования - не менее 15 м",
          "Переносными желтыми сигналами, устанавливаемыми на железнодорожных путях общего пользования на оси железнодорожного пути на расстоянии не менее 30 м, а на железнодорожных путях необщего пользования - не менее 10 м",
        ],
        correctAnswers: [
          "Переносными красными сигналами, устанавливаемыми на железнодорожных путях общего пользования на оси железнодорожного пути на расстоянии не менее 50 м, а на железнодорожных путях необщего пользования - не менее 15 м",
        ],
      },
      {
        code: "21001057",
        text: "На каком максимальном расстоянии ставятся вагоны с нарядами охраны или со специалистами грузоотправителя (грузополучателя) от сопровождаемых ими вагонов с взрывчатыми материалами?",
        answers: ["50 м", "140 м", "80 м", "100 м"],
        correctAnswers: ["50 м"],
      },
      {
        code: "21001058",
        text: "Что может являться прикрытием в поездах, в которых следуют вагоны с взрывчатыми материалами? Укажите все правильные ответы.",
        answers: [
          "Порожние вагоны",
          "Вагоны, загруженные неопасными грузами",
          "Специальные цистерны",
          "Цистерны, специализированные для перевозки опасных грузов",
        ],
        correctAnswers: [
          "Порожние вагоны",
          "Вагоны, загруженные неопасными грузами",
        ],
      },
      {
        code: "21001059",
        text: "При перевозке каких опасных грузов сливоналивные устройства вагонов-цистерн оборудуются скоростными клапанами, исключающими выход продукта при разрыве трубопровода?",
        answers: [
          "Опасных грузов класса 6",
          "Опасных грузов класса 2",
          "Опасных грузов класса 3",
          "Опасных грузов класса 5",
          "Любых опасных грузов",
        ],
        correctAnswers: ["Опасных грузов класса 2"],
      },
      {
        code: "21001060",
        text: "Какую информацию не должна содержать маркировка вагона-цистерны, содержащая опасный груз?",
        answers: [
          "Серийный номер ООН",
          "Номер аварийной карточки",
          "Наименование организации-владельца",
          "Знак опасности по ГОСТ 19433",
        ],
        correctAnswers: ["Наименование организации-владельца"],
      },
      {
        code: "21001061",
        text: "Какие буквы добавляются к номеру поезда при сообщении в случае наличия в нем вагонов с опасными грузами класса 1 (взрывчатыми материалами)?",
        answers: ["СП", "ВМ", "ПМ", "ПД", "ТТ"],
        correctAnswers: ["ВМ"],
      },
      {
        code: "21001062",
        text: "Где разрешается использование железнодорожных цистерн с легковоспламеняющимися жидкостями, находящимися на железнодорожных путях, в качестве стационарных, складских (расходных) емкостей?",
        answers: [
          "В местах проведения погрузочно-разгрузочных работ",
          "На станционных тупиковых путях",
          "На путях специального назначения",
          "Нигде не разрешается",
        ],
        correctAnswers: ["Нигде не разрешается"],
      },
      {
        code: "21001063",
        text: "Каким оборудованием оснащаются цистерны, предназначенные для перевозки по железным дорогам сжиженного углеводородного газа и легковоспламеняющихся жидкостей?",
        answers: [
          "Только средствами контроля уровня налива",
          "Арматурой, средствами контроля, сливоналивными, защитными и другими устройствами",
          "Только предохранительными клапанами",
          "Только сливоналивными устройствами",
        ],
        correctAnswers: [
          "Арматурой, средствами контроля, сливоналивными, защитными и другими устройствами",
        ],
      },
      {
        code: "21001064",
        text: "В течение какого минимального срока выдерживаются на складе вновь скомплектованные партии наполненных жидким хлором контейнеров или баллонов?",
        answers: [
          "Не менее одних суток",
          "Не менее двух суток",
          "Не менее трех суток",
          "Срок не регламентируется",
        ],
        correctAnswers: ["Не менее одних суток"],
      },
      {
        code: "21001065",
        text: "Какие из перечисленных вагонов-цистерн, контейнеров-цистерн, контейнеров (бочек) и баллонов допускается использовать для транспортировки жидкого хлора?",
        answers: [
          "У которых отсутствуют надлежащая окраска и надписи, а также невозможно прочтение клейма",
          "У которых истек срок технического освидетельствования",
          "У которых неисправна запорная или предохранительная арматура",
          "На которых демонтированы сифонные трубки из баллонов",
        ],
        correctAnswers: [
          "На которых демонтированы сифонные трубки из баллонов",
        ],
      },
      {
        code: "21001066",
        text: "Какая устанавливается максимальная норма налива жидкого хлора в емкость (резервуар, танк, сборник, вагон-цистерна, контейнер-цистерна)?",
        answers: ["1,25 кг/дм³", "1,30 кг/дм³", "1,45 кг/дм³", "1 кг/дм³"],
        correctAnswers: ["1,25 кг/дм³"],
      },
      {
        code: "21001067",
        text: "Кем должен быть осмотрен прибывший в организацию вагон-цистерна для перевозки жидкого хлора с целью проверки исправности ходовой части вагона-цистерны, а также крепления котла вагона-цистерны к раме?",
        answers: [
          "Уполномоченными лицами организации-грузоотправителя",
          "Работником, ответственным за обеспечение безопасности при проведении погрузочно-разгрузочных работ",
          "Представителем организации-грузополучателя",
          "Начальником смены на станции",
        ],
        correctAnswers: ["Уполномоченными лицами организации-грузоотправителя"],
      },
      {
        code: "21001068",
        text: "Оснащение какими устройствами вагонов-цистерн и контейнеров-цистерн для перевозки жидкого хлора указано верно?",
        answers: [
          "Двумя запорными клапанами для налива (слива) жидкого хлора с сифонными трубами и скоростными или внутренними отсечными клапанами, автоматически прекращающими выход жидкого хлора при разрыве трубопровода",
          "Тремя предохранительными клапанами",
          "Тремя вентилями, предназначенными для выпуска газообразного хлора (абгазов) или подачи газа для передавливания жидкого хлора, соединенными с укороченными сифонами, исключающими переполнение вагона-цистерны сверх установленной нормы налива",
          "Двумя штуцерами с вентилем для съемного манометра",
        ],
        correctAnswers: [
          "Двумя запорными клапанами для налива (слива) жидкого хлора с сифонными трубами и скоростными или внутренними отсечными клапанами, автоматически прекращающими выход жидкого хлора при разрыве трубопровода",
        ],
      },
      {
        code: "21001069",
        text: "Кто определяет давление срабатывания мембраны и открытия предохранительного клапана, его пропускную способность для вагонов-цистерн и контейнеров-цистерн?",
        answers: [
          "Разработчик оборудования",
          "Владелец оборудования",
          "Руководитель бригады осмотра состава",
          "Не регламентируется",
        ],
        correctAnswers: ["Разработчик оборудования"],
      },
      {
        code: "21001070",
        text: "Какой документ устанавливает систему организации движения поездов, функционирования сооружений и устройств инфраструктуры железнодорожного транспорта, железнодорожного подвижного состава, а также определяет действия работников железнодорожного транспорта при технической эксплуатации железнодорожного транспорта Российской Федерации необщего пользования?",
        answers: [
          "Правила технической эксплуатации железных дорог Российской Федерации",
          'Федеральный закон от 10 января 2003 г. N 17-ФЗ "О железнодорожном транспорте в Российской Федерации"',
          'Федеральный закон от 10 января 2003 г. N 18-ФЗ "Устав железнодорожного транспорта Российской Федерации"',
          'Федеральный закон от 21 июля 1997 г. N 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
        ],
        correctAnswers: [
          "Правила технической эксплуатации железных дорог Российской Федерации",
        ],
      },
      {
        code: "21001071",
        text: "Кто должен содержать в исправном техническом состоянии железнодорожные пути необщего пользования и расположенные на них сооружения, устройства, механизмы и оборудование железнодорожного транспорта?",
        answers: [
          "Работники, которые осуществляют производственную деятельность, непосредственно связанную с движением поездов и маневровой работой",
          "Владельцы железнодорожного пути",
          "Работники железнодорожного транспорта, непосредственно обслуживающие железнодорожные пути",
          "Работники, связанные с приемом, отправлением, пропуском и обслуживанием поездов",
        ],
        correctAnswers: ["Владельцы железнодорожного пути"],
      },
      {
        code: "21001072",
        text: "Кто не должен производить ограждение места препятствия для движения поездов, возникшего на смежном железнодорожном пути необщего пользования, при вынужденной остановке поезда на двухпутном или многопутном перегоне вследствие схода с рельсов, столкновения, развалившегося груза?",
        answers: [
          "Помощник машиниста укладкой петард на смежном железнодорожном пути со стороны ожидаемого по этому железнодорожному пути поезда на расстоянии 1000 м от места препятствия",
          'Со стороны головы поезда - помощник машиниста, а с хвоста - составитель, которые отходят на расстояние "Т" и показывают ручной красный сигнал в сторону перегона соответственно с головы и хвоста поезда',
          'Помощник машиниста со стороны ожидаемого поезда на расстоянии "Т", если поезд не сопровождается составителем',
        ],
        correctAnswers: [
          "Помощник машиниста укладкой петард на смежном железнодорожном пути со стороны ожидаемого по этому железнодорожному пути поезда на расстоянии 1000 м от места препятствия",
        ],
      },
      {
        code: "21001073",
        text: "Каким образом днем на железнодорожных путях необщего пользования обозначается голова специализированного поезда (вертушки), обращающегося на открытых горных разработках на однопутных, двухпутных и многопутных участках, при движении локомотивом вперед и вагонами вперед?",
        answers: [
          "2 прозрачно-белыми огнями фонарей у буферного бруса локомотива",
          "1 прозрачно-белым огнем у буферного бруса вагона с правой стороны, при этом головной вагон дополняется устройством звуковой сигнализации",
          "Диском желтого цвета у буферного бруса вагона с правой стороны или 2 красными огнями на буферном брусе локомотива",
          "Локомотив сигналами не обозначается, а вагон обозначается диском красного цвета у буферного бруса вагона с правой стороны",
        ],
        correctAnswers: [
          "Локомотив сигналами не обозначается, а вагон обозначается диском красного цвета у буферного бруса вагона с правой стороны",
        ],
      },
      {
        code: "21001074",
        text: "При каких размерах рельсовой колеи запрещается эксплуатация железнодорожного пути на опасном производственном объекте?",
        answers: [
          "Менее 1512 мм и более 1550 мм",
          "Менее 1514 мм и более 1544 мм",
          "Менее 1513 мм и более 1545 мм",
          "Менее 1512 мм и более 1548 мм",
        ],
        correctAnswers: ["Менее 1512 мм и более 1548 мм"],
      },
    ],
    21002: [
      {
        code: "21002000",
        text: "На какие виды перевозок опасных грузов автомобильным транспортом распространяются требования Европейского соглашения о международной дорожной перевозке опасных грузов (далее - ДОПОГ)?",
        answers: [
          "Только на внутригосударственные перевозки",
          "Только на международные перевозки",
          "Только на перевозки между странами СНГ",
          "На внутригосударственные и международные перевозки",
          "На внутригосударственные перевозки и перевозки между странами СНГ",
        ],
        correctAnswers: ["На внутригосударственные и международные перевозки"],
      },
      {
        code: "21002001",
        text: "К каким из перечисленных транспортных операций с опасными грузами могут быть применимы положения ДОПОГ?",
        answers: [
          "К перевозкам, осуществляемым автомобилями техпомощи, перевозящими потерпевшие аварию транспортные средства, содержащие опасные грузы",
          "К перевозкам опасных грузов частными лицами, если эти грузы упакованы для розничной продажи при условии, что приняты меры для предотвращения утечки содержимого",
          "К перевозкам с целью локализации и сбора опасных грузов в случае инцидента или аварии и перемещения их в ближайшее безопасное место",
          "К перевозкам опасных грузов с целью их захоронения, обезвреживания и утилизации",
        ],
        correctAnswers: [
          "К перевозкам опасных грузов с целью их захоронения, обезвреживания и утилизации",
        ],
      },
      {
        code: "21002002",
        text: "К перевозке каких из перечисленных газов могут быть применимы положения ДОПОГ?",
        answers: [
          "Газов, содержащихся в топливных баках перевозимых транспортных средств",
          "Газов, содержащихся в оборудовании, используемом для эксплуатации транспортных средств",
          "Газов, содержащихся в электрических лампочках (при соблюдении требований упаковки)",
          "Газов, являющихся сильнотоксичными или пирофорными веществами",
        ],
        correctAnswers: [
          "Газов, являющихся сильнотоксичными или пирофорными веществами",
        ],
      },
      {
        code: "21002003",
        text: "Что обозначает знак Организации Объединенных Наций на транспортном средстве?",
        answers: [
          "Данный вид опасного груза запрещен к перевозкам",
          "Данный вид опасного груза допущен к перевозкам",
          "Данный вид груза идентифицирован в Типовых правилах Организации Объединенных Наций",
          "Данный вид груза отнесен к особо опасным грузам",
        ],
        correctAnswers: [
          "Данный вид груза идентифицирован в Типовых правилах Организации Объединенных Наций",
        ],
      },
      {
        code: "21002004",
        text: "Какие действия должны незамедлительно предпринять участники перевозки опасных грузов, если в процессе перевозки создается прямая угроза для общественной безопасности?",
        answers: [
          "Уведомить об этом свое непосредственное руководство и принять меры по устранению возникшей угрозы",
          "Уведомить об этом свое непосредственное руководство и покинуть место возникновения угрозы",
          "Уведомить об этом аварийно-спасательные службы и предоставить информацию, необходимую для принятия соответствующих мер",
          "Уведомить об этом население и подготовить его к эвакуации",
        ],
        correctAnswers: [
          "Уведомить об этом аварийно-спасательные службы и предоставить информацию, необходимую для принятия соответствующих мер",
        ],
      },
      {
        code: "21002005",
        text: 'Что в ДОПОГ понимается под определением "опасные грузы"?',
        answers: [
          "Взрыво- и пожароопасные грузы, которые допускаются к перевозке только соответствующими компетентными органами",
          "Вещества и изделия, которые не допускаются к перевозке согласно ДОПОГ или допускаются к ней только с соблюдением предписанных в ДОПОГ условий",
          "Только жидкие или газообразные вещества, которые не допускаются к перевозке согласно ДОПОГ",
          "Только изделия, которые допускаются к перевозке с соблюдением предписанных в ДОПОГ условий",
        ],
        correctAnswers: [
          "Вещества и изделия, которые не допускаются к перевозке согласно ДОПОГ или допускаются к ней только с соблюдением предписанных в ДОПОГ условий",
        ],
      },
      {
        code: "21002006",
        text: "В зависимости от каких факторов выбирается форма подготовки (общее ознакомление, специализированная подготовка или подготовка в области безопасности) работников, участвующих в перевозке опасных грузов?",
        answers: [
          "В зависимости от функций и обязанностей соответствующих лиц",
          "В зависимости от вида договора найма на работу соответствующих лиц",
          "В зависимости от дальности перевозки опасного груза",
          "В зависимости от класса перевозимого опасного груза",
        ],
        correctAnswers: [
          "В зависимости от функций и обязанностей соответствующих лиц",
        ],
      },
      {
        code: "21002007",
        text: "Что в том числе требуется для получения специального разрешения, выдаваемого уполномоченным компетентным органом, для движения по автомобильным дорогам транспортного средства, осуществляющего перевозку опасных грузов?",
        answers: [
          "Согласование общего объема опасных грузов",
          "Согласование классов опасных грузов",
          "Согласование скорости движения транспортного средства",
          "Согласование маршрута транспортного средства",
        ],
        correctAnswers: ["Согласование маршрута транспортного средства"],
      },
      {
        code: "21002008",
        text: "Допускается ли установление постоянных маршрутов транспортных средств, осуществляющих перевозки опасных грузов?",
        answers: [
          "Допускается для всех классов опасных грузов",
          "Допускается для всех классов опасных грузов, кроме инфекционных веществ",
          "Допускается только для газов и окисляющих веществ",
          "Не допускается",
        ],
        correctAnswers: ["Допускается для всех классов опасных грузов"],
      },
      {
        code: "21002009",
        text: "Кем осуществляется выдача специального разрешения для движения по автомобильным дорогам федерального значения транспортного средства, осуществляющего перевозку опасных грузов?",
        answers: [
          "Федеральным органом исполнительной власти, осуществляющим функции по оказанию государственных услуг и управлению государственным имуществом в сфере дорожного хозяйства",
          "Уполномоченным федеральным органом исполнительной власти",
          "Органами исполнительной власти субъектов Российской Федерации",
          "Органами местного самоуправления муниципальных районов",
        ],
        correctAnswers: [
          "Уполномоченным федеральным органом исполнительной власти",
        ],
      },
      {
        code: "21002010",
        text: "Какой код присваивается всему опасному грузу, если в одной транспортной единице содержатся опасные грузы, которым назначены разные коды ограничения проезда через автодорожные туннели?",
        answers: [
          "Код опасного груза, имеющего в транспортной единице наибольший объем",
          "Код опасного груза, имеющего в транспортной единице наибольшую массу",
          "Наименее ограничительный из этих кодов",
          "Наиболее ограничительный из этих кодов",
        ],
        correctAnswers: ["Наиболее ограничительный из этих кодов"],
      },
      {
        code: "21002011",
        text: "В каком случае перевозка опасного груза считается достаточно безопасной?",
        answers: [
          "Если на борту транспортного средства имеется все необходимое для очистки транспортного средства или контейнера от вытекших, пролитых или рассыпанных опасных грузов",
          "Если приняты надлежащие меры для предотвращения неконтролируемого выхода наружу вытекших, пролитых или рассыпанных опасных грузов",
          "Если в плане локализации и ликвидации аварий четко прописаны и доведены до экипажа его действия в случае неконтролируемого выхода наружу вытекших, пролитых или рассыпанных опасных грузов",
          "Если неконтролируемый выход наружу вытекших, пролитых или рассыпанных опасных грузов не может нанести существенный ущерб окружающей среде и населению",
        ],
        correctAnswers: [
          "Если приняты надлежащие меры для предотвращения неконтролируемого выхода наружу вытекших, пролитых или рассыпанных опасных грузов",
        ],
      },
      {
        code: "21002012",
        text: "Когда план мероприятий по локализации и ликвидации последствий аварий считается принятым?",
        answers: [
          "После утверждения руководителем организации, эксплуатирующей опасные производственные объекты, или руководителями обособленных подразделений",
          "После утверждения руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях) и согласования руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
          "После утверждения руководителем организации, эксплуатирующей опасные производственные объекты, или руководителями обособленных подразделений и согласования с органами Ростехнадзора",
        ],
        correctAnswers: [
          "После утверждения руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях) и согласования руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
        ],
      },
      {
        code: "21002013",
        text: "В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "В целях регламентации действий персонала при возникновении аварии",
          "В целях регламентации действий подразделений муниципальной пожарной охраны при возникновении аварии",
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
          "В целях обеспечения соответствия объекта требованиям промышленной безопасности",
        ],
        correctAnswers: [
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
        ],
      },
      {
        code: "21002014",
        text: "Из каких разделов состоит план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Только изобщих разделов",
          "Из разделов, предусмотренных требованиями федеральных норм и правил в области промышленной безопасности",
          "Из общих и специальных разделов",
          "Только изспециальных разделов",
        ],
        correctAnswers: ["Из общих и специальных разделов"],
      },
      {
        code: "21002015",
        text: "В каком случае допускается совмещение маркировки, характеризующей вид и степень опасности, с транспортной маркировкой и маркировкой, характеризующей груз, на одном ярлыке?",
        answers: [
          "Допускается, размер ярлыка должен быть увеличен на величину, кратную количеству знаков",
          "Допускается только для класса опасности 1",
          "Допускается только при согласовании с Минтрансом России",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "Допускается, размер ярлыка должен быть увеличен на величину, кратную количеству знаков",
        ],
      },
      {
        code: "21002016",
        text: 'Сколько классов опасных грузов определяется по ГОСТ 19433-88 "Грузы опасные. Классификация и маркировка"?',
        answers: ["9 классов", "5 классов", "8 классов", "7 классов"],
        correctAnswers: ["9 классов"],
      },
      {
        code: "21002017",
        text: "Каковы критерии отнесения опасного груза к классу и подклассу?",
        answers: [
          "По виду и степени опасности груза, по установленному приоритету вида опасности",
          "По двум или более видам опасности груза",
          "Только по виду опасности груза",
          "По степени опасности и объему перевозимого груза",
        ],
        correctAnswers: [
          "По виду и степени опасности груза, по установленному приоритету вида опасности",
        ],
      },
      {
        code: "21002018",
        text: "Какие грузы относятся к 2 классу опасности?",
        answers: [
          "Газы сжатые, сжиженные и растворенные под давлением",
          "Вещества, выделяющие воспламеняющиеся газы при взаимодействии с водой",
          "Легковоспламеняющиеся жидкости",
          "Радиоактивные материалы",
        ],
        correctAnswers: ["Газы сжатые, сжиженные и растворенные под давлением"],
      },
      {
        code: "21002019",
        text: "Какие опасные грузы относятся к 6 классу опасности?",
        answers: [
          "Взрывчатые материалы",
          "Радиоактивные материалы",
          "Ядовитые и инфекционные вещества",
          "Окисляющие вещества и органические пероксиды",
        ],
        correctAnswers: ["Ядовитые и инфекционные вещества"],
      },
      {
        code: "21002020",
        text: "Какие опасные грузы относятся к 7 классу опасности?",
        answers: [
          "Радиоактивные материалы",
          "Легковоспламеняющиеся твердые вещества",
          "Самовозгорающиеся вещества",
          "Едкие и (или) коррозионные вещества",
        ],
        correctAnswers: ["Радиоактивные материалы"],
      },
      {
        code: "21002021",
        text: "Какие опасные грузы относятся к 8 классу опасности?",
        answers: [
          "Едкие и коррозионные вещества",
          "Прочие опасные вещества",
          "Взрывчатые материалы",
          "Легковоспламеняющиеся жидкости",
        ],
        correctAnswers: ["Едкие и коррозионные вещества"],
      },
      {
        code: "21002022",
        text: "Какую маркировку должна содержать каждая грузовая единица, содержащая опасный груз?",
        answers: [
          "Вид и степень опасности груза",
          "Размер груза",
          "Агрегатное состояние груза",
          "Транспортное наименование груза",
        ],
        correctAnswers: ["Вид и степень опасности груза"],
      },
      {
        code: "21002023",
        text: "Какой из перечисленных национальных стандартов, в соответствии с которым осуществляется классификация опасных грузов, указан верно?",
        answers: [
          "ГОСТ 19433-88. Грузы опасные. Классификация и маркировка",
          "ГОСТ 14192-96. Межгосударственный стандарт. Маркировка грузов",
          "ГОСТ 26319-84. Грузы опасные. Упаковка",
        ],
        correctAnswers: [
          "ГОСТ 19433-88. Грузы опасные. Классификация и маркировка",
        ],
      },
      {
        code: "21002024",
        text: "В каком случае допускается перевозка опасных грузов без специальных разрешений пользователям автомобильными дорогами?",
        answers: [
          "Не допускается ни в каком случае",
          "Если стаж вождения водителя не менее 3 лет",
          "Если груз сопровождает сопровождает военизированная охрана, выделенная грузоотправителем (грузополучателем)",
          "Если органы государственной власти Российской Федерации в области использования автомобильных дорог и осуществления дорожной деятельности получают оперативную информацию о маршрутах транспортных средств по автомобильным дорогам",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "21002025",
        text: "В каком случае могут устанавливаться временное ограничение или прекращение движения транспортных средств по автомобильным дорогам?",
        answers: [
          "Только в период возникновения неблагоприятных природно-климатических условий",
          "Только в период повышенной интенсивности движения транспортных средств накануне нерабочих праздничных и выходных дней",
          "Только в часы максимальной загрузки автомобильных дорог",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "21002026",
        text: "Какой из перечисленных органов определяет порядок установления постоянных маршрутов транспортных средств, осуществляющих перевозки опасных грузов?",
        answers: [
          "Орган государственной власти Российской Федерации в области использования автомобильных дорог и осуществления дорожной деятельности",
          "Ростехнадзор",
          "Минприроды России",
          "МЧС России",
        ],
        correctAnswers: [
          "Орган государственной власти Российской Федерации в области использования автомобильных дорог и осуществления дорожной деятельности",
        ],
      },
      {
        code: "21002027",
        text: "По какому показателю определяют степень опасности веществ, выделяющих воспламеняющиеся газы при соприкосновении с водой?",
        answers: [
          "По уровню водостойкости",
          "По коэффициенту возможности ингаляционного отравления",
          "По интенсивности газовыделения",
          "По средней смертельной концентрации",
        ],
        correctAnswers: ["По интенсивности газовыделения"],
      },
      {
        code: "21002028",
        text: "Какие опасные грузы относятся к 4 классу опасности?",
        answers: [
          "Легковоспламеняющиеся твердые вещества, самовозгорающиеся вещества, вещества, выделяющие воспламеняющиеся газы при взаимодействии с водой",
          "Радиоактивные материалы",
          "Едкие и (или) коррозионные вещества",
          "Легковоспламеняющиеся жидкости",
        ],
        correctAnswers: [
          "Легковоспламеняющиеся твердые вещества, самовозгорающиеся вещества, вещества, выделяющие воспламеняющиеся газы при взаимодействии с водой",
        ],
      },
      {
        code: "21002029",
        text: "Какие опасные грузы относятся к 5 классу опасности?",
        answers: [
          "Окисляющие вещества и органические пероксиды",
          "Ядовитые вещества и инфекционные вещества",
          "Радиоактивные материалы",
          "Легковоспламеняющиеся жидкости",
        ],
        correctAnswers: ["Окисляющие вещества и органические пероксиды"],
      },
      {
        code: "21002030",
        text: "Какие опасные грузы относятся к 3 классу опасности?",
        answers: [
          "Легковоспламеняющиеся жидкости",
          "Газы сжатые, сжиженные и растворенные под давлением",
          "Вещества, выделяющие воспламеняющиеся газы при взаимодействии с водой",
          "Органические пероксиды",
        ],
        correctAnswers: ["Легковоспламеняющиеся жидкости"],
      },
      {
        code: "21002031",
        text: "В зависимости от какого параметра назначаются группы упаковки веществам в соответствии с принципами классификации опасных грузов ДОПОГ?",
        answers: [
          "В зависимости от степени опасности веществ",
          "В зависимости от объема вмещаемых веществ",
          "В зависимости от агрегатного состояния веществ",
          "В зависимости от дальности перевозки веществ",
        ],
        correctAnswers: ["В зависимости от степени опасности веществ"],
      },
      {
        code: "21002032",
        text: "Для каких веществ назначается группа упаковки III?",
        answers: [
          "Для веществ с высокой степенью опасности",
          "Для веществ со средней степенью опасности",
          "Для веществ с низкой степенью опасности",
          "Для веществ с нулевой степенью опасности",
        ],
        correctAnswers: ["Для веществ с низкой степенью опасности"],
      },
      {
        code: "21002033",
        text: "К какому классу должны быть отнесены вещество, раствор или смесь, если определить их физические и химические свойства посредством измерения или расчета невозможно без несоразмерных затрат или усилий?",
        answers: [
          "К классу компонента, представляющего наибольшую опасность",
          "К классу компонента, представляющего наименьшую опасность",
          "К классу компонента, составляющего наибольшую долю общего объема",
          "К классу компонента, составляющего наименьшую долю общего объема",
        ],
        correctAnswers: [
          "К классу компонента, представляющего наибольшую опасность",
        ],
      },
      {
        code: "21002034",
        text: "Какие вещества, согласно положениям о классе 1 (взрывчатые вещества и изделия), относятся к взрывчатым веществам?",
        answers: [
          "Вещества или смеси веществ, предназначенные для производства эффекта в виде тепла, света, звука, газа или дыма или их комбинации в результате самоподдерживающихся экзотермических химических реакций, протекающих без детонации",
          "Только твердые вещества, которые способны к химической реакции с ощутимым выделением газов при любой температуре, давлении и скорости реакции, что вызывает разрушение окружающих предметов",
          "Только жидкие вещества (или смеси веществ), которые способны к химической реакции с ощутимым выделением тепла при любом давлении и скорости реакции, что вызывает возгорание окружающих предметов",
          "Твердые или жидкие вещества, которые способны к химической реакции с выделением газов при такой температуре, таком давлении и с такой скоростью, что это вызывает повреждение окружающих предметов",
        ],
        correctAnswers: [
          "Твердые или жидкие вещества, которые способны к химической реакции с выделением газов при такой температуре, таком давлении и с такой скоростью, что это вызывает повреждение окружающих предметов",
        ],
      },
      {
        code: "21002035",
        text: "Какие взрывчатые изделия не допускаются к перевозке?",
        answers: [
          "Изделия, содержащие как взрывчатое вещество, так и белый фосфор",
          "Изделия, содержащие как взрывчатое вещество, так и токсичный химический агент",
          "Изделия, содержащие как взрывчатое вещество, так и легковоспламеняющиеся жидкости или гель",
          "Все перечисленные изделия",
        ],
        correctAnswers: [
          "Изделия, содержащие как взрывчатое вещество, так и токсичный химический агент",
        ],
      },
      {
        code: "21002036",
        text: "Какие взрывчатые вещества не допускаются к перевозке?",
        answers: [
          "Первичное взрывчатое вещество",
          "Вторичное детонирующее взрывчатое вещество",
          "Вещества, обладающие чрезмерной чувствительностью или способные к самопроизвольной реакции",
          "Вещества, представляющие особую опасность в связи с их водоактивностью",
        ],
        correctAnswers: [
          "Вещества, обладающие чрезмерной чувствительностью или способные к самопроизвольной реакции",
        ],
      },
      {
        code: "21002037",
        text: "Могут ли взрывчатые изделия при перевозке снабжаться собственными средствами воспламенения или упаковываться вместе с ними?",
        answers: [
          "Могут",
          "Могут при условии, что срабатывание средств воспламенения при нормальных условиях перевозки исключено",
          "Могут при условии, что взрывчатые изделия относятся по степени опасности к подклассу 1.3 и ниже",
          "Не могут",
        ],
        correctAnswers: [
          "Могут при условии, что срабатывание средств воспламенения при нормальных условиях перевозки исключено",
        ],
      },
      {
        code: "21002038",
        text: "Какие аэрозоли не допускаются к перевозке?",
        answers: [
          "Аэрозоли, в которых в качестве газов-вытеснителей используются токсичные или пирофорные газы",
          "Аэрозоли, содержимое которых не удовлетворяет критериям группы упаковки I в отношении токсичности",
          "Аэрозоли, содержимое которых не удовлетворяет критериям группы упаковки II в отношении коррозионности",
          "Все перечисленные аэрозоли",
        ],
        correctAnswers: [
          "Аэрозоли, в которых в качестве газов-вытеснителей используются токсичные или пирофорные газы",
        ],
      },
      {
        code: "21002039",
        text: "В каком случае допускается перевозка химически неустойчивых легковоспламеняющихся жидкостей?",
        answers: [
          "В случае если приняты необходимые меры для предотвращения их опасного разложения или полимеризации во время перевозки",
          "В случае если приняты необходимые меры для предотвращения их возгорания во время погрузки",
          "В случае если приняты необходимые меры для предотвращения их контакта с воздухом во время погрузки и перевозки",
          "В случае если приняты необходимые меры для предотвращения их встряхивания и взбалтывания во время перевозки",
        ],
        correctAnswers: [
          "В случае если приняты необходимые меры для предотвращения их опасного разложения или полимеризации во время перевозки",
        ],
      },
      {
        code: "21002040",
        text: "По каким показателям определяется степень опасности легковоспламеняющихся жидкостей?",
        answers: [
          "По плотности и объему",
          "По вязкости и текучести",
          "По температуре вспышки и температуре кипения",
          "По температуре горения и температуре плавления",
        ],
        correctAnswers: ["По температуре вспышки и температуре кипения"],
      },
      {
        code: "21002041",
        text: "Какие вещества называются пирофорными?",
        answers: [
          "Вещества, которые даже в малых количествах воспламеняются при контакте с воздухом в течение 5 минут",
          "Вещества, которые даже в малых количествах воспламеняются при контакте с водой в течение 5 минут",
          "Вещества, которые в больших количествах воспламеняются при контакте с углекислым газом",
          "Вещества, которые при контакте с воздухом без подвода энергии извне способны к самонагреванию",
        ],
        correctAnswers: [
          "Вещества, которые даже в малых количествах воспламеняются при контакте с воздухом в течение 5 минут",
        ],
      },
      {
        code: "21002042",
        text: "Какие меры необходимо принять для предотвращения опасного разложения или полимеризации химически неустойчивых веществ класса 4.1 во время перевозки?",
        answers: [
          "Обеспечить, чтобы в сосудах и цистернах не содержалось веществ, способных активировать эти реакции",
          "Обеспечить, чтобы сосуды и цистерны были сделаны из химически неактивных материалов",
          "Обеспечить устойчивое положение сосудов и цистерн",
          "Обеспечить защищенность сосудов и цистерн от ударов",
        ],
        correctAnswers: [
          "Обеспечить, чтобы в сосудах и цистернах не содержалось веществ, способных активировать эти реакции",
        ],
      },
      {
        code: "21002043",
        text: "Какие вещества называются окисляющими?",
        answers: [
          "Вещества, которые могут создавать сильную коррозионную среду вне зависимости от присутствия воздуха",
          "Вещества, которые могут подвергать коррозии любые другие вещества при соприкосновении",
          "Горючие вещества, которые могут вызывать горение других веществ и материалов при участии катализатора",
          "Вещества, которые, сами по себе необязательно являясь горючими, могут вызывать или поддерживать горение других материалов",
        ],
        correctAnswers: [
          "Вещества, которые, сами по себе необязательно являясь горючими, могут вызывать или поддерживать горение других материалов",
        ],
      },
      {
        code: "21002044",
        text: "Допускается ли перевозка химически неустойчивых окисляющих веществ класса 5.1?",
        answers: [
          "Допускается",
          "Допускается, если приняты необходимые меры для предотвращения их опасного разложения или полимеризации в ходе перевозки",
          "Допускается, если приняты меры для предотвращения их вибрации при перевозке",
          "Не допускается",
        ],
        correctAnswers: [
          "Допускается, если приняты необходимые меры для предотвращения их опасного разложения или полимеризации в ходе перевозки",
        ],
      },
      {
        code: "21002045",
        text: "К какой группе упаковки следует относить сильнотоксичные вещества?",
        answers: [
          "К группе упаковки I",
          "Только к группе упаковки II",
          "Только к группе упаковки III",
          "К группе упаковки II или III",
        ],
        correctAnswers: ["К группе упаковки I"],
      },
      {
        code: "21002046",
        text: "В каких контейнерах должны перевозиться навалом или насыпью коррозионные вещества?",
        answers: [
          "В закрытых пластиковых контейнерах",
          "В контейнерах с брезентовым покрытием",
          "В водонепроницаемых контейнерах для массовых грузов",
          "В неметаллических контейнерах для массовых грузов",
        ],
        correctAnswers: ["В водонепроницаемых контейнерах для массовых грузов"],
      },
      {
        code: "21002047",
        text: "Какие меры необходимо принять для предотвращения опасного разложения или полимеризации химически неустойчивых токсичных веществ во время перевозки?",
        answers: [
          "Обеспечить, чтобы в сосудах и цистернах не содержалось веществ, способных активировать эти реакции",
          "Обеспечить, чтобы для перевозки использовались сосуды и цистерны были сделаны из химически неактивных материалов",
          "Обеспечить устойчивое положение сосудов и цистерн",
          "Все перечисленные меры",
        ],
        correctAnswers: [
          "Обеспечить, чтобы в сосудах и цистернах не содержалось веществ, способных активировать эти реакции",
        ],
      },
      {
        code: "21002048",
        text: "Какие вещества относятся к классу инфекционных веществ?",
        answers: [
          "Радиоактивные отходы и лекарственные препараты",
          "Клинические отходы и биологические препараты",
          "Бытовые отходы и небиологические препараты",
          "Производственные отходы и противомикробные препараты",
        ],
        correctAnswers: ["Клинические отходы и биологические препараты"],
      },
      {
        code: "21002049",
        text: "Какие из перечисленных веществ допускаются к перевозке?",
        answers: [
          "Серная кислота, регенерированная из кислого гудрона",
          "Водный раствор хлорной кислоты, содержащий более 72 % чистой кислоты по массе",
          "Химически неустойчивые нитрующие кислотные смеси или неденитрированные смеси остаточных серной и азотной кислот",
          "Химически неустойчивые смеси отработанной серной кислоты",
        ],
        correctAnswers: ["Серная кислота, регенерированная из кислого гудрона"],
      },
      {
        code: "21002050",
        text: "Допускается ли использовать коммерческие названия в качестве технического названия груза?",
        answers: [
          "Допускается",
          "Допускается если это название не используется в периодических изданиях",
          "Допускается для некоторых грузов",
          "Не допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "21002051",
        text: 'На каких языках, кроме официального языка страны происхождения груза, должна быть выполнена маркировочная надпись "ТРАНСПОРТНЫЙ ПАКЕТ"?',
        answers: [
          "На английском, французском или немецком",
          "На английском, немецком или русском",
          "На русском и английском",
          "На английском и испанском",
        ],
        correctAnswers: ["На английском, французском или немецком"],
      },
      {
        code: "21002052",
        text: "Какими знаками опасности и маркировочными надписями должна быть снабжена упаковка, если два и более опасных груза помещаются в одну и ту же наружную тару?",
        answers: [
          "Знаками и надписями, которые требуются для наиболее опасного вещества или изделия",
          "Знаками и надписями, которые требуются для каждого вещества или изделия",
          "Знаками и надписями, требующимися для вещества или изделия, объем которого больше",
          "Знаками и надписями, требующимися вещества или изделия, масса которого больше",
        ],
        correctAnswers: [
          "Знаками и надписями, которые требуются для каждого вещества или изделия",
        ],
      },
      {
        code: "21002053",
        text: "Куда наносится маркировка, характеризующая вид и степень опасности груза, при транспортировании груза в контейнере?",
        answers: [
          "На дверь и, если позволяет конструкция, днище",
          "На дверь, боковую стенку и, если позволяет конструкция, крышу",
          "Только на боковую стенку",
          "Только на дверь",
        ],
        correctAnswers: [
          "На дверь, боковую стенку и, если позволяет конструкция, крышу",
        ],
      },
      {
        code: "21002054",
        text: "В каком случае в нормативно-технической документации на продукцию указывают температуру вспышки?",
        answers: [
          "Температуру вспышки, если она не более 61 °С",
          "Температуру вспышки, если она более 61 °С",
          "Температуру вспышки, если она не более 75 °С",
          "Температуру вспышки, если она более 75 °С",
        ],
        correctAnswers: ["Температуру вспышки, если она не более 61 °С"],
      },
      {
        code: "21002055",
        text: "Какие сведения должны быть нанесены на каждую упаковку в виде разборчивой и долговечной маркировки, если в ДОПОГ не предусмотрено иное?",
        answers: [
          "Точное наименование опасного груза в соответствии с накладной",
          "Номер Организации Объединенных Наций, соответствующий содержащимся в упаковке опасным грузам",
          "Класс опасности, соответствующий содержащимся в упаковке опасным грузам",
          "Все перечисленные сведения",
        ],
        correctAnswers: [
          "Номер Организации Объединенных Наций, соответствующий содержащимся в упаковке опасным грузам",
        ],
      },
      {
        code: "21002056",
        text: "Как должен выглядеть маркировочный знак вещества, опасного для окружающей среды, расположенный рядом с маркировочными надписями на упаковке опасного груза?",
        answers: [
          "Рыба и дерево в равностороннем ромбе",
          "Череп и кости в равностороннем ромбе",
          "Крест в равнобедренном треугольнике",
          "Восклицательный знак в равнобедренном треугольнике",
        ],
        correctAnswers: ["Рыба и дерево в равностороннем ромбе"],
      },
      {
        code: "21002057",
        text: "Какие вещества обозначаются знаком опасности в виде черного или белого пламени на синем фоне?",
        answers: [
          "Легковоспламеняющиеся твердые вещества",
          "Самовозгорающиеся вещества",
          "Ядовитые вещества",
          "Вещества, выделяющие легковоспламеняющиеся газы при соприкосновении с водой",
        ],
        correctAnswers: [
          "Вещества, выделяющие легковоспламеняющиеся газы при соприкосновении с водой",
        ],
      },
      {
        code: "21002058",
        text: "Какие вещества обозначаются знаком опасности в виде черного пламени на белом фоне с семью вертикальными красными полосами?",
        answers: [
          "Самореактивные вещества",
          "Окисляющие вещества",
          "Самовозгорающиеся вещества",
          "Ядовитые вещества",
        ],
        correctAnswers: ["Самореактивные вещества"],
      },
      {
        code: "21002059",
        text: "На каком основании разрешается совместная погрузка упаковки с различными знаками опасности в одно и то же транспортное средство или контейнер?",
        answers: [
          "На основании распоряжения лица, ответственного за погрузку",
          "На основании разрешения руководителя транспортной компании, осуществляющей перевозку",
          'На основании классификации опасных грузов ГОСТ 19433-88 "Грузы опасные. Классификация и маркировка"',
          "На основании таблицы совместимости знаков опасности ДОПОГ",
        ],
        correctAnswers: [
          "На основании таблицы совместимости знаков опасности ДОПОГ",
        ],
      },
      {
        code: "21002060",
        text: "В каком случае допускается совмещение маркировки, характеризующей вид и степень опасности, с транспортной маркировкой и маркировкой, характеризующей груз, на одном ярлыке?",
        answers: [
          "Допускается, размер ярлыка должен быть увеличен на величину, кратную количеству знаков",
          "Допускается только для класса опасности 1",
          "Допускается только при согласовании с Минтрансом России",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "Допускается, размер ярлыка должен быть увеличен на величину, кратную количеству знаков",
        ],
      },
      {
        code: "21002061",
        text: "Какие данные необходимо установить для присвоения опасному грузу классификационного шифра?",
        answers: [
          "Все перечисленные",
          "Только виды опасности, характеризующие груз и степень опасности",
          "Только класс (подкласс), к которому относится груз",
          "Только категорию, характеризующую дополнительный вид опасности",
          "Только группу, характеризующую степень опасности груза",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "21002062",
        text: "Какую маркировку не должны содержать упаковка и (или) транспортный пакет при транспортировке автомобильным транспортом?",
        answers: [
          "Номер аварийной карточки",
          "Знак опасности",
          "Транспортное наименование груза",
          "Номер ООН",
          "Классификационный шифр",
        ],
        correctAnswers: ["Номер аварийной карточки"],
      },
      {
        code: "21002063",
        text: "Знак опасности какого класса имеет чередующиеся равноотстоящие черные и белые полосы?",
        answers: [
          "Класс опасности 9",
          "Класс опасности 8",
          "Класс опасности 7",
          "Класс опасности 6",
          "Класс опасности 4",
        ],
        correctAnswers: ["Класс опасности 9"],
      },
      {
        code: "21002064",
        text: "На сколько допускается уменьшать размер знака опасности?",
        answers: [
          "До 25 мм",
          "До 20 мм",
          "До 15 мм",
          "Не допускается ни на сколько",
        ],
        correctAnswers: ["До 25 мм"],
      },
      {
        code: "21002065",
        text: "Каким образом образовываются классификационные шифры опасных грузов класса 1?",
        answers: [
          "Двумя цифрами, соответствующими подклассу, и буквенным обозначением группы совместимости",
          "Набором цифр: первые две соответствуют подклассу, третья - номеру категории четвертая - группе",
          "Набором цифр (первые две соответствуют подклассу, третья - номеру категории) и буквенным обозначением группы совместимости",
          "Набором цифр (первые две соответствуют подклассу, третья - номеру категории, четвертая - группе) и буквенным обозначением группы совместимости",
        ],
        correctAnswers: [
          "Двумя цифрами, соответствующими подклассу, и буквенным обозначением группы совместимости",
        ],
      },
      {
        code: "21002066",
        text: "К какому классу опасности относятся пиротехнические вещества, составы и изделия?",
        answers: [
          "К классу опасности 1",
          "К классу опасности 6",
          "К классу опасности 3",
          "К классу опасности 2",
          "К классу опасности 9",
        ],
        correctAnswers: ["К классу опасности 1"],
      },
      {
        code: "21002067",
        text: "Какую маркировку не должна содержать упаковка и (или) транспортный пакет при транспортировке автомобильным транспортом?",
        answers: [
          "Номер аварийной карточки",
          "Знак опасности",
          "Транспортное наименование груза",
          "Номер ООН",
          "Классификационный шифр",
        ],
        correctAnswers: ["Номер аварийной карточки"],
      },
      {
        code: "21002068",
        text: "Каким способом хранение жидкого хлора в резервуарах (танках, контейнерах-цистернах) не осуществляется?",
        answers: [
          "При температуре плавления жидкого хлора",
          "При температуре окружающей среды",
          "В захоложенном состоянии при температуре ниже температуры окружающей среды",
          "При температуре кипения жидкого хлора при атмосферном давлении (изотермический способ хранения)",
        ],
        correctAnswers: ["При температуре плавления жидкого хлора"],
      },
      {
        code: "21002069",
        text: "Какая устанавливается максимальная норма налива жидкого хлора в емкость (резервуар, танк, сборник, вагон-цистерна, контейнер-цистерна)?",
        answers: ["1,25 кг/дм³", "1,55 кг/дм³", "2,25 кг/дм³", "1,75 кг/дм³"],
        correctAnswers: ["1,25 кг/дм³"],
      },
      {
        code: "21002070",
        text: "Оснащение какими устройствами вагонов-цистерн и контейнеров-цистерн для перевозки жидкого хлора указано верно?",
        answers: [
          "Двумя штуцерами с вентилем для съемного манометра",
          "Двумя запорными клапанами для налива (слива) жидкого хлора с сифонными трубами и скоростными или внутренними отсечными клапанами, автоматически прекращающими выход жидкого хлора при разрыве трубопровода",
          "Тремя предохранительными клапанами",
          "Тремя вентилями, предназначенными для выпуска газообразного хлора (абгазов) или подачи газа для передавливания жидкого хлора, соединенными с укороченными сифонами, исключающими переполнение вагона-цистерны сверх установленной нормы налива",
        ],
        correctAnswers: [
          "Двумя запорными клапанами для налива (слива) жидкого хлора с сифонными трубами и скоростными или внутренними отсечными клапанами, автоматически прекращающими выход жидкого хлора при разрыве трубопровода",
        ],
      },
      {
        code: "21002071",
        text: "В каком случае допускается перевозка сосудов с истекшим сроком технического освидетельствования, заполненных хлором?",
        answers: [
          "Не допускается ни в каком случае",
          "В случае заполнения их хлором ниже установленного предела",
          "В случае если срок технического освидетельствования истек месяц назад",
          "В случае согласования с Ростехнадзором",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "21002072",
        text: "Кто определяет давление срабатывания мембраны и открытия предохранительного клапана, его пропускную способность для вагонов-цистерн и контейнеров-цистерн?",
        answers: [
          "Разработчик оборудования",
          "Грузоперевозчик",
          "Владелец оборудования",
          "Грузоотправитель",
        ],
        correctAnswers: ["Разработчик оборудования"],
      },
      {
        code: "21002073",
        text: "Какие из перечисленных вагонов-цистерн, контейнеров-цистерн, контейнеров (бочек) и баллонов допускается использовать для транспортировки жидкого хлора?",
        answers: [
          "На которых демонтированы сифонные трубки из баллонов",
          "Которые исправны, предназначены для транспортировки любых продуктов",
          "На которых истек срок ревизии предохранительного клапана и мембраны для вагонов-цистерн и контейнеров-цистерн",
          "На которых имеются механические повреждения",
        ],
        correctAnswers: [
          "На которых демонтированы сифонные трубки из баллонов",
        ],
      },
      {
        code: "21002074",
        text: "В течение какого срока выдерживаются на складе вновь скомплектованные партии наполненных жидким хлором контейнеров или баллонов?",
        answers: [
          "Не менее одних суток",
          "Не менее двух суток",
          "Не более трех суток",
          "Не более пяти суток",
        ],
        correctAnswers: ["Не менее одних суток"],
      },
      {
        code: "21002075",
        text: "В соответствии с каким документом должны выполняться требования к конструкции и оборудованию транспортного средства, перевозящего опасные грузы?",
        answers: [
          'С ГОСТ 19433-88 "Грузы опасные. Классификация и маркировка"',
          "С Правилами перевозок грузов автомобильным транспортом",
          "С Европейским соглашением о международной дорожной перевозке опасных грузов (ДОПОГ)",
          'С техническим регламентом Таможенного союза "О безопасности колесных транспортных средств"',
        ],
        correctAnswers: [
          "С Европейским соглашением о международной дорожной перевозке опасных грузов (ДОПОГ)",
        ],
      },
      {
        code: "21002076",
        text: "Чем должны быть оснащены транспортные средства, используемые для перевозки опасных грузов?",
        answers: [
          "Автоматическим компьютерным управлением двигателем",
          "Средствами видеорегистрации",
          "Средствами звукового и речевого оповещения населения",
          "Аппаратурой спутниковой навигации",
        ],
        correctAnswers: ["Аппаратурой спутниковой навигации"],
      },
      {
        code: "21002077",
        text: "Что не допускается по отношению к кондиционерам и холодильному оборудованию, применяемым на транспортных средствах?",
        answers: [
          "Наличие в их составе аммиака в качестве хладагента",
          "Наличие в их составе вредных веществ",
          "Наличие в их составе озоноразрушающих веществ и материалов, перечень которых утвержден Комиссией Таможенного союза",
          "Все перечисленное",
        ],
        correctAnswers: [
          "Наличие в их составе озоноразрушающих веществ и материалов, перечень которых утвержден Комиссией Таможенного союза",
        ],
      },
      {
        code: "21002078",
        text: "Сколько прицепов или полуприцепов может включать одна транспортная единица, загруженная опасными грузами?",
        answers: [
          "Не более одного",
          "Не более двух",
          "Более одного, если перевозимые грузы не относятся к взрывчатым веществам",
          "Более одного, если перевозимые грузы не относится к легковоспламеняющимся веществам",
        ],
        correctAnswers: ["Не более одного"],
      },
      {
        code: "21002079",
        text: "Какова одна из основных целей базовой подготовки водителей транспортных средств, перевозящих опасные грузы?",
        answers: [
          "Ознакомить водителей с воздействиями, которые оказывают опасные вещества на окружающую среду",
          "Ознакомить водителей с рисками, связанными с перевозкой опасных грузов",
          "Ознакомить водителей с химическим составом опасных веществ",
          "Ознакомить водителей с принципами классификации опасных грузов",
        ],
        correctAnswers: [
          "Ознакомить водителей с рисками, связанными с перевозкой опасных грузов",
        ],
      },
      {
        code: "21002080",
        text: "Допускается ли вскрытие водителем и помощником водителя упаковки с опасным грузом?",
        answers: [
          "Допускается",
          "Допускается только в случае аварийной ситуации",
          "Допускается в случае, если необходимо предъявление целостности груза",
          "Не допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "21002081",
        text: "Какое минимальное расстояние должно соблюдаться при движении между следующими друг за другом транспортными средствами, перевозящими взрывчатые вещества и изделия, в составе автоколонны?",
        answers: [
          "Не менее 15 м",
          "Не менее 25 м",
          "Не менее 50 м",
          "Не менее 30 м",
        ],
        correctAnswers: ["Не менее 50 м"],
      },
      {
        code: "21002082",
        text: 'Что называется "транспортным средством EX/II"?',
        answers: [
          "Транспортное средство, предназначенное для перевозки взрывчатых веществ и изделий (класс 1)",
          "Транспортное средство, предназначенное для перевозки токсичных веществ (класс 6.1)",
          "Транспортное средство, предназначенное для перевозки инфекционных веществ (класс 6.2)",
          "Транспортное средство, предназначенное для перевозки прочих опасных веществ и изделий (класс 9)",
        ],
        correctAnswers: [
          "Транспортное средство, предназначенное для перевозки взрывчатых веществ и изделий (класс 1)",
        ],
      },
      {
        code: "21002083",
        text: "Как должны быть отрегулированы устройства ограничения скорости автотранспортных средств, перевозящих опасные грузы, максимальной массой более 3,5 т?",
        answers: [
          "Так, чтобы при увеличении скорости до 90 км/ч раздавался звуковой сигнал",
          "Так, чтобы при увеличении скорости до 120 км/ч раздавался звуковой сигнал",
          "Так, чтобы скорость не могла превысить 90 км/ч",
          "Так, чтобы скорость не могла превысить 120 км/ч",
        ],
        correctAnswers: ["Так, чтобы скорость не могла превысить 90 км/ч"],
      },
      {
        code: "21002084",
        text: "Какую поверхность или покрытие должен иметь пол контейнера при перевозке сыпучих порошкообразных веществ, а также пиротехнических средств?",
        answers: [
          "Металлическую поверхность или покрытие",
          "Неметаллическую поверхность или покрытие",
          "Только цинковую поверхность или покрытие",
          "Только деревянную поверхность или покрытие",
        ],
        correctAnswers: ["Неметаллическую поверхность или покрытие"],
      },
      {
        code: "21002085",
        text: "Когда должна пройти соответствующее испытание на герметичность при нормативных уровнях испытаний каждая единица тары, предназначенная для наполнения жидкостями?",
        answers: [
          "До первого использования в целях перевозки и после реконструкции, перед ее очередным использованием в целях перевозки",
          "Только до первого использования в целях перевозки",
          "Только после реконструкции, перед ее очередным использованием в целях перевозки",
          "Перед каждым очередным наполнением",
        ],
        correctAnswers: [
          "До первого использования в целях перевозки и после реконструкции, перед ее очередным использованием в целях перевозки",
        ],
      },
      {
        code: "21002086",
        text: "С какой периодичностью аварийные сосуды под давлением должны подвергаться очистке, продувке и визуальной проверке их внешнего и внутреннего состояния?",
        answers: [
          "Не реже 1 раза в год",
          "Не реже 1 раза в месяц",
          "Только перед первым использованием",
          "После каждого использования",
        ],
        correctAnswers: ["После каждого использования"],
      },
      {
        code: "21002087",
        text: "Какие меры должны быть предприняты, если контейнер средней грузоподъемности для массовых грузов используется для перевозки жидкостей с температурой вспышки 60 ºС (закрытый сосуд) или ниже либо для перевозки порошков, пыль которых является взрывоопасной?",
        answers: [
          "Меры по ограничению скорости транспортного средства до 90 км/ч",
          "Меры для предотвращения опасного электрического разряда",
          "Меры по недопущению тряски контейнера во время перевозки",
          "Меры по недопущению их перевозки вместе с другими грузами",
        ],
        correctAnswers: [
          "Меры для предотвращения опасного электрического разряда",
        ],
      },
      {
        code: "21002088",
        text: "Какие грузовые транспортные единицы должны использоваться для перевозки фумигированного груза?",
        answers: [
          "Закрывающиеся таким образом, чтобы выпуск газа был сокращен до минимума",
          "Закрывающиеся таким образом, чтобы во время перевозки осуществлялось постоянное проветривание груза",
          "С брезентовым покрытием кузова",
          "Любые из перечисленных транспортных единиц",
        ],
        correctAnswers: [
          "Закрывающиеся таким образом, чтобы выпуск газа был сокращен до минимума",
        ],
      },
      {
        code: "21002089",
        text: "В каком случае переносные цистерны могут быть предъявлены к перевозке?",
        answers: [
          "В случае если наружная поверхность корпуса или сервисное оборудование загрязнены ранее перевозимыми веществами",
          "В случае если повреждения таковы, что это не скажется на целостности цистерны и ее крепежных приспособлений",
          "В случае если повреждения таковы, что это может сказаться на целостности цистерны и ее подъемных приспособлений",
          "В случае если сервисное оборудование не проверено",
        ],
        correctAnswers: [
          "В случае если повреждения таковы, что это не скажется на целостности цистерны и ее крепежных приспособлений",
        ],
      },
      {
        code: "21002090",
        text: "Как в переносной цистерне должно располагаться сервисное оборудование, такое как клапаны и наружный трубопровод?",
        answers: [
          "Только сверху цистерны",
          "Только по бокам цистерны",
          "Так, чтобы вещество не оставалось в них после заполнения цистерны",
          "Не регламентируется",
        ],
        correctAnswers: [
          "Так, чтобы вещество не оставалось в них после заполнения цистерны",
        ],
      },
      {
        code: "21002091",
        text: "Допускается ли перевозка пищевых продуктов в металлических цистернах, использовавшихся для перевозки опасных веществ?",
        answers: [
          "Допускается только после перевозки грузов 1 - 3 классов",
          "Допускается, только если приняты необходимые меры для предотвращения нанесения какого-либо вреда здоровью людей",
          "Допускается, только если исключена возможность вступления остатков опасных веществ в опасные реакции с пищевыми продуктами",
          "Не допускается",
        ],
        correctAnswers: [
          "Допускается, только если приняты необходимые меры для предотвращения нанесения какого-либо вреда здоровью людей",
        ],
      },
      {
        code: "21002092",
        text: "Допускается ли перевозка веществ, способных вступать в опасную реакцию друг с другом, в смежных секциях металлических цистерн?",
        answers: [
          "Допускается",
          "Допускается, если возможная опасная реакция не может сопровождаться взрывом, горением или разрушением стенок цистерны",
          "Допускается, если толщина перегородки между секциями равна толщине стенок цистерны или превышает ее",
          "Не допускается",
        ],
        correctAnswers: [
          "Допускается, если толщина перегородки между секциями равна толщине стенок цистерны или превышает ее",
        ],
      },
      {
        code: "21002093",
        text: "Каким должно быть испытательное давление для металлических цистерн, предназначенных для перевозки охлажденных сжиженных газов, по отношению к максимально допустимому рабочему давлению, указанному на цистерне?",
        answers: [
          "Превышать максимально допустимое давление минимум в 1,3 раза, но составлять не менее 300 кПа",
          "Превышать максимально допустимое давление минимум в 1,5 раза, но составлять не менее 500 кПа",
          "Превышать максимально допустимое давление минимум в 2 раза без нижнего фактического порога",
          "Быть равным максимально допустимому давлению без нижнего фактического порога",
        ],
        correctAnswers: [
          "Превышать максимально допустимое давление минимум в 1,3 раза, но составлять не менее 300 кПа",
        ],
      },
      {
        code: "21002094",
        text: "Какое максимальное сопротивление заземляющего устройства вместе с контуром заземления должно быть у транспортного средства для перевозки опасных грузов?",
        answers: ["100 Ом", "50 Ом", "150 Ом", "200 Ом"],
        correctAnswers: ["100 Ом"],
      },
      {
        code: "21002095",
        text: "Как должно фиксироваться запорное устройство загрузочного люка цистерны транспортного средства для перевозки опасных грузов?",
        answers: [
          "В закрытом и открытом положениях",
          "Только в закрытом положении",
          "В закрытом, открытом и не менее чем в 2 промежуточных положениях",
          "В закрытом, открытом и не менее чем в 3 промежуточных положениях",
        ],
        correctAnswers: ["В закрытом и открытом положениях"],
      },
      {
        code: "21002096",
        text: "Каким количеством и емкостью переносных огнетушителей должны комплектоваться транспортные средства для перевозки опасных грузов технически допустимой максимальной массой более 7,5 т?",
        answers: [
          "Не менее чем одним огнетушителем емкостью не менее 12 кг или двумя огнетушителями емкостью каждого не менее 6 кг",
          "Одним огнетушителем или более минимальной емкостью 8 кг или двумя огнетушителями, из которых один емкостью не менее 6 кг",
          "Одним огнетушителем или более общей емкостью не менее 4 кг",
          "Одним огнетушителем или более емкостью не менее 2 кг, пригодным для тушения пожара в двигателе или кабине транспортного средства",
        ],
        correctAnswers: [
          "Не менее чем одним огнетушителем емкостью не менее 12 кг или двумя огнетушителями емкостью каждого не менее 6 кг",
        ],
      },
      {
        code: "21002097",
        text: "Какое максимальное номинальное напряжение электрооборудования может быть у транспортного средства для перевозки опасных грузов?",
        answers: ["24 В", "12 В", "36 В", "50 В", "42 В"],
        correctAnswers: ["24 В"],
      },
      {
        code: "21002098",
        text: 'Какие требования предъявляются Техническим регламентом Таможенного союза "О безопасности колесных транспортных средств" к установке на транспортном средстве для перевозки опасных грузов дополнительных топливных баков, не предусмотренных изготовителем транспортного средства?',
        answers: [
          "Установка запрещается",
          "Установка допускается на специализированной станции технического обслуживания",
          "Установка допускается только по согласованию с центром технической экспертизы",
          "Допускается установка дополнительных топливных баков емкостью не более 50 % от объема основного бака",
        ],
        correctAnswers: ["Установка запрещается"],
      },
      {
        code: "21002099",
        text: "Какое минимальное расстояние должно быть между задней стенкой цистерны и задней частью защитного устройства (от крайней задней точки стенки цистерны или от выступающей арматуры, соприкасающейся с перевозимым грузом)?",
        answers: ["100 мм", "80 мм", "120 мм", "150 мм"],
        correctAnswers: ["100 мм"],
      },
      {
        code: "21002100",
        text: "Какие требования к транспортным средствам-цистернам для перевозки и заправки сжиженных углеводородных газов указаны верно?",
        answers: [
          "На обеих сторонах сосуда от шва переднего днища до шва заднего днища должны быть нанесены отличительные полосы красного цвета шириной 200 мм вниз от продольной оси сосуда",
          'Надпись "Опасно" на заднем днище сосуда и надпись черного цвета "Опасно - сжиженный газ" над отличительными полосами должны быть читаемы',
          "Наружная поверхность сосуда должна окрашиваться эмалью желтого цвета",
          "Все перечисленные требования",
        ],
        correctAnswers: [
          "На обеих сторонах сосуда от шва переднего днища до шва заднего днища должны быть нанесены отличительные полосы красного цвета шириной 200 мм вниз от продольной оси сосуда",
        ],
      },
      {
        code: "21002101",
        text: "Какими средствами комплектуется транспортное средство для перевозки опасных грузов?",
        answers: [
          "Всеми перечисленными",
          "Только не менее чем двумя противооткатными упорами на каждое транспортное средство (звено автопоезда), размеры которых соответствуют диаметру колес",
          "Только двумя знаками аварийной остановки; средствами нейтрализации перевозимых опасных грузов",
          "Только набором ручного инструмента для аварийного ремонта транспортного средства; одеждой яркого цвета для каждого члена экипажа",
          "Только двумя фонарями автономного питания с мигающими или постоянными огнями оранжевого цвета; карманными фонарями для каждого члена экипажа",
        ],
        correctAnswers: ["Всеми перечисленными"],
      },
      {
        code: "21002102",
        text: "В каких случаях допускается эксплуатация транспортного средства для перевозки опасных грузов?",
        answers: [
          "С установленными оградительными сетками и решетками вокруг ламп накаливания внутри кузова транспортного средства",
          "Без защитной непроницаемой перегородки между топливным баком и аккумуляторной батареей",
          "Без защитного кожуха под днищем и с боков топливного бака",
          "С установленными на транспортном средстве лампами накаливания с винтовыми цоколями",
          "С поврежденными кронштейнами для крепления таблиц системы информации об опасности, расположенными спереди (на бампере)",
        ],
        correctAnswers: [
          "С установленными оградительными сетками и решетками вокруг ламп накаливания внутри кузова транспортного средства",
        ],
      },
      {
        code: "21002103",
        text: "Что должно быть установлено по всему периметру цистерны на автоцистернах и прицепах (полуприцепах) - цистернах, на транспортных средствах для перевозки съемных цистерн и транспортных средствах - батареях?",
        answers: [
          "Боковые или задние защитные устройства",
          "Передние защитные устройства",
          "Проблесковые маячки",
          "Светоотражающая лента",
        ],
        correctAnswers: ["Боковые или задние защитные устройства"],
      },
      {
        code: "21002104",
        text: "Каким образом необходимо укрывать тентом транспортное средство для перевозки опасных грузов?",
        answers: [
          "Тент должен быть натянут, перекрывать борта кузова со всех сторон не менее чем на 200 мм и удерживаться фиксирующими приспособлениями",
          "Тент должен быть натянут, перекрывать борта кузова со всех сторон не менее чем на 150 мм",
          "Тент должен быть натянут, перекрывать борта кузова со всех сторон не менее чем на 100 мм",
          "Не регламентируется",
        ],
        correctAnswers: [
          "Тент должен быть натянут, перекрывать борта кузова со всех сторон не менее чем на 200 мм и удерживаться фиксирующими приспособлениями",
        ],
      },
      {
        code: "21002105",
        text: "Какие требования к транспортным средствам-цистернам для перевозки и заправки нефтепродуктов указаны верно?",
        answers: [
          'На цистерне должны размещаться два знака "Опасность", знак "Ограничение скорости", мигающий фонарь красного цвета или знак аварийной остановки, кошма, емкость для песка массой не менее 25 кг',
          "Сопротивление электрической цепи, образуемой электропроводящим покрытием между переходником и замком рукава, должно быть не более 10 Ом; на цистернах, снабженных антистатическими рукавами, сопротивление обозначенной цепи должно быть не более указанного в эксплуатационной документации; сопротивление отдельных участков цепи должно быть не более 100 Ом",
          'Сопротивление каждого из звеньев электрических цепей "рама шасси - штырь", "цистерна - рама шасси", "рама шасси - контакты вилки провода заземления" не должно превышать 50 Ом',
          'Надпись "Огнеопасно" на боковых сторонах и заднем днище сосуда должна быть читаема. Надписи выполняются на французском языке',
        ],
        correctAnswers: [
          'На цистерне должны размещаться два знака "Опасность", знак "Ограничение скорости", мигающий фонарь красного цвета или знак аварийной остановки, кошма, емкость для песка массой не менее 25 кг',
        ],
      },
    ],
  },
  88: {
    21101: [
      {
        code: "21101000",
        text: 'На какие объекты распространяются Федеральные нормы и правила в области промышленной безопасности "Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья"?',
        answers: [
          "На приемно-отпускные устройства для приема и отпуска растительного сырья и продуктов его переработки с железнодорожного, автомобильного и водного транспорта, рабочие здания, силосные корпуса элеваторов, склады силосного типа, склады напольные для бестарного хранения и галереи для перемещения растительного сырья и продуктов его переработки",
          "На цехи, отделения, блочно-модульные и агрегатные установки по производству муки, крупы, солода, комбикормов и кормовых смесей, семяобрабатывающие и кукурузообрабатывающие производства",
          "На подготовительные, подработочные, дробильные отделения по очистке, измельчению солода, зерна, шелушению маслосемян, отделения для растаривания, взвешивания, просеивания муки, размола сахарного песка, зерносушильные установки, приемно-очистительные и сушильно-очистительные башни, цехи отходов, пыли, очистки и сортировки мешкотары",
          "На опасные производственные объекты, на которых осуществляются хранение и (или) переработка растительного сырья, в процессе которых образуются взрывоопасные пылевоздушные смеси, способные самовозгораться, возгораться от источника зажигания и самостоятельно гореть после его удаления, а также осуществляется хранение зерна, продуктов его переработки и комбикормового сырья, склонных к самосогреванию и самовозгоранию",
        ],
        correctAnswers: [
          "На опасные производственные объекты, на которых осуществляются хранение и (или) переработка растительного сырья, в процессе которых образуются взрывоопасные пылевоздушные смеси, способные самовозгораться, возгораться от источника зажигания и самостоятельно гореть после его удаления, а также осуществляется хранение зерна, продуктов его переработки и комбикормового сырья, склонных к самосогреванию и самовозгоранию",
        ],
      },
      {
        code: "21101001",
        text: "В каких целях должны проектироваться аспирационные установки на предприятиях по хранению и переработке зерна и предприятиях хлебопекарной промышленности?",
        answers: [
          "Только для создания разрежения внутри технологического оборудования и транспортирующих машин для предотвращения выделения пыли в производственные помещения",
          "Только для очистки зерна и сортирования воздушными потоками продуктов размола и шелушения",
          "Только для удаления избыточного тепла и влаги из оборудования",
          "Для достижения всех перечисленных целей, создания необходимых санитарно-гигиенических условий и предупреждения возникновения пожаров и взрывов пылевоздушных смесей",
        ],
        correctAnswers: [
          "Для достижения всех перечисленных целей, создания необходимых санитарно-гигиенических условий и предупреждения возникновения пожаров и взрывов пылевоздушных смесей",
        ],
      },
      {
        code: "21101002",
        text: "Какое из перечисленных мероприятий, предусматриваемых проектными решениями в целях повышения эффективности работы аспирационных установок, указано неверно?",
        answers: [
          "Максимальная герметизация источников пылеобразования",
          "Применение пылеотделителей с высоким коэффициентом очистки",
          "Установка пылеотделителей после вентиляторов",
          "Применение вентиляторов с наибольшим коэффициентом полезного действия",
        ],
        correctAnswers: ["Установка пылеотделителей после вентиляторов"],
      },
      {
        code: "21101003",
        text: "Какую систему отопления необходимо проектировать для ликвидации вакуума и снижения запыленности воздуха в рабочих помещениях?",
        answers: [
          "Систему воздушного отопления",
          "Систему парового отопления",
          "Систему водяного отопления",
          "Систему панельного отопления",
        ],
        correctAnswers: ["Систему воздушного отопления"],
      },
      {
        code: "21101004",
        text: "Какие ширина и высота должны быть у крытых проездов автомобильных весов и приемных устройств при выполнении погрузочно-разгрузочных работ на автомобильном транспорте на территории взрывопожароопасных производственных объектов хранения и переработки растительного сырья?",
        answers: [
          "Ширина 3,5 м и высота не более 4 м",
          "Ширина 3,0 м и высота более 4,5 м",
          "Ширина 2,5 м и высота не более 5 м",
          "Ширина 5 м и высота не более 10 м",
        ],
        correctAnswers: ["Ширина 3,5 м и высота не более 4 м"],
      },
      {
        code: "21101005",
        text: "Какие требования предъявляются к настилам, устроенным в местах пересечения автомобильных дорог и пешеходных дорожек с железнодорожными путями на территории взрывопожароопасных производственных объектов хранения и переработки растительного сырья?",
        answers: [
          "В местах пересечения автомобильных дорог и пешеходных дорожек с железнодорожными путями должны быть устроены сплошные настилы, уложенные на уровне головки рельсов",
          "В местах пересечения автомобильных дорог и пешеходных дорожек с железнодорожными путями должны быть устроены сплошные настилы, уложенные ниже уровня головки рельсов на 50 мм",
          "В местах пересечения автомобильных дорог и пешеходных дорожек с железнодорожными путями должны быть устроены сплошные настилы, уложенные выше уровня головки рельсов на 50 мм",
          "В местах пересечения автомобильных дорог и пешеходных дорожек с железнодорожными путями должны быть устроены сплошные настилы, уложенные ниже уровня головки рельсов на 20 мм",
        ],
        correctAnswers: [
          "В местах пересечения автомобильных дорог и пешеходных дорожек с железнодорожными путями должны быть устроены сплошные настилы, уложенные на уровне головки рельсов",
        ],
      },
      {
        code: "21101006",
        text: "Какими должны быть интервалы при расстановке автомобилей на площади разгрузки и погрузки взрывопожароопасных производственных объектов хранения и переработки растительного сырья?",
        answers: [
          "Между автомобилями, стоящими друг за другом, в глубину - не менее 1 м, между автомобилями, стоящими рядом по фронту, - не менее 1,5 м",
          "Между автомобилями, стоящими друг за другом, в глубину - не менее 0,8 м, между автомобилями, стоящими рядом по фронту, - не менее 1,2 м",
          "Между автомобилями, стоящими друг за другом, в глубину - не менее 0,6 м, между автомобилями, стоящими рядом по фронту, - не менее 1,0 м",
          "Между автомобилями, стоящими друг за другом, в глубину - не менее 0,5 м, между автомобилями, стоящими рядом по фронту, - не менее 0,5 м",
        ],
        correctAnswers: [
          "Между автомобилями, стоящими друг за другом, в глубину - не менее 1 м, между автомобилями, стоящими рядом по фронту, - не менее 1,5 м",
        ],
      },
      {
        code: "21101007",
        text: "Какие требования предъявляются к настилу на всем протяжении железнодорожных путей в случае применения на путях ручной подкатки вагонов?",
        answers: [
          "Настил должен обеспечивать безопасность передвижения по всей ширине шпал, быть выше уровня головки рельсов",
          "Настил должен быть ниже уровня головки рельсов",
          "Настил должен быть на уровне головки рельсов",
          "Настил должен обеспечивать безопасность передвижения по всей ширине колеи, быть ниже уровня головки рельсов на 50 мм",
        ],
        correctAnswers: [
          "Настил должен обеспечивать безопасность передвижения по всей ширине шпал, быть выше уровня головки рельсов",
        ],
      },
      {
        code: "21101008",
        text: 'Какие требования предъявляются к помещениям, где составляют суспензии и обогатительные смеси, в соответствии с Федеральными нормами и правилами в области промышленной безопасности "Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья"?',
        answers: [
          "Помещения должны быть изолированы от основных производственных помещений, в нерабочее время - запираться на замок, иметь приточно-вытяжную вентиляцию, стены должны быть облицованы глазурованной плиткой",
          "Помещения могут располагаться совместно с основными производственными помещениями, но должны при этом иметь дополнительную охрану, приточно-вытяжную вентиляцию, стены должны быть покрыты металлическими листами",
          "Помещения могут быть не изолированы от основных производственных помещений, стены должны быть отштукатурены и покрыты водоэмульсионной краской, на полы должен быть положен деревянный настил",
        ],
        correctAnswers: [
          "Помещения должны быть изолированы от основных производственных помещений, в нерабочее время - запираться на замок, иметь приточно-вытяжную вентиляцию, стены должны быть облицованы глазурованной плиткой",
        ],
      },
      {
        code: "21101009",
        text: 'В помещениях какой категории запрещается устройство выбоя отходов производства в тару в соответствии с Федеральными нормами и правилами в области промышленной безопасности "Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья"?',
        answers: [
          "В помещениях категории В",
          "В помещениях категории А",
          "В помещениях категории Б",
          "В помещениях категорий А и Б",
          "В помещениях категории Г",
        ],
        correctAnswers: ["В помещениях категории В"],
      },
      {
        code: "21101010",
        text: "Что из перечисленного не указывается в графиках уборки пыли в производственных помещениях взрывопожароопасных производственных объектов хранения и переработки растительного сырья?",
        answers: [
          "Периодичность текущих и генеральных уборок",
          "Объемы уборки",
          "Ф. И. О. и должность работника, выполняющего уборку",
        ],
        correctAnswers: ["Ф. И. О. и должность работника, выполняющего уборку"],
      },
      {
        code: "21101011",
        text: "Кто утверждает графики уборки пыли в производственных помещениях объектов хранения и переработки растительного сырья?",
        answers: [
          "Руководитель эксплуатирующей организации (технический директор)",
          "Представитель территориального управления Федеральной службы по экологическому, технологическому и атомному надзору",
          "Лицо, ответственное за промышленную безопасность в производственном помещении (цехе)",
          "Начальник смены",
        ],
        correctAnswers: [
          "Руководитель эксплуатирующей организации (технический директор)",
        ],
      },
      {
        code: "21101012",
        text: "Что из перечисленного запрещается использовать для уборки пыли в производственных помещениях объектов хранения и переработки растительного сырья?",
        answers: [
          "Только горючие жидкости",
          "Только сжатый воздух",
          "Только сжатый газ",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21101013",
        text: "Какое требование к лазовым и загрузочным люкам силосов, бункеров указано неверно?",
        answers: [
          "Люки силосов, бункеров в перекрытиях производственных помещений должны закрываться крышками в уровень с полом",
          "Лазовые и загрузочные люки силосов, бункеров и других устройств, независимо от мест их расположения, помимо крышек должны иметь прочные металлические решетки с ячейками размером не более 250 x 75 мм. Решетки люков не рекомендуется углублять более чем на 60 мм от поверхности пола помещения",
          "Лазовые люки должны закрываться на замок",
          "Лазовые люки должны быть круглого сечения диаметром не менее 500 мм",
        ],
        correctAnswers: [
          "Лазовые люки должны быть круглого сечения диаметром не менее 500 мм",
        ],
      },
      {
        code: "21101014",
        text: "На каком расстоянии друг от друга должны размещаться мостики через конвейеры в производственных помещениях, а также в галереях и эстакадах?",
        answers: [
          "Не более 50 м - в производственных помещениях, не более 100 м в галереях и на эстакадах",
          "Не более 75 м - в производственных помещениях, галереях и на эстакадах",
          "Не более 100 м - в производственных помещениях, не более 110 м в галереях и на эстакадах",
          "Не более 55 м - в производственных помещениях, не более 120 м в галереях и на эстакадах",
        ],
        correctAnswers: [
          "Не более 50 м - в производственных помещениях, не более 100 м в галереях и на эстакадах",
        ],
      },
      {
        code: "21101015",
        text: "Что из перечисленного не предусматривает план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Организацию взаимодействия сил и средств",
          "Организацию управления, связи и оповещения при аварии на объекте, первоочередные действия при получении сигнала об аварии на объекте",
          "Порядок проведения восстановительных работ по окончании ликвидации аварии на объекте",
          "Состав и дислокацию сил и средств",
          "Достаточное количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте",
          "Мероприятия, направленные на обеспечение безопасности населения, возможные сценарии возникновения и развития аварий на объекте",
        ],
        correctAnswers: [
          "Порядок проведения восстановительных работ по окончании ликвидации аварии на объекте",
        ],
      },
      {
        code: "21101016",
        text: "Кем утверждаются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Только руководителями (заместителями руководителей) организаций, эксплуатирующих объекты",
          "Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
          "Руководителями структурных подразделений объекта",
          "Специалистами поднадзорных организаций совместно со специалистами надзорных органов",
        ],
        correctAnswers: [
          "Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
        ],
      },
      {
        code: "21101017",
        text: "Сколько необходимо разработать планов мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, в случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?",
        answers: [
          "Разрабатывается единый план мероприятий только для объектов, находящихся на расстоянии менее 100 м друг от друга",
          "Разрабатывается единый план мероприятий для объектов, расположенных на одном участке, и несколько для объектов на смежных участках",
          "Разрабатываются планы мероприятий для каждого объекта отдельно",
          "Разрабатывается единый план мероприятий для двух и более объектов",
        ],
        correctAnswers: [
          "Разрабатывается единый план мероприятий для двух и более объектов",
        ],
      },
      {
        code: "21101018",
        text: "Что из перечисленного содержится в специальном разделе мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
          "Характеристика объектов, в отношении которых разрабатывается план мероприятий",
          "Возможные сценарии возникновения и развития аварий на объектах, а также источники (места) возникновения аварий",
          "Характеристики аварийности, присущие объектам, в отношении которых разрабатывается План мероприятий, и травматизма на таких объектах",
        ],
        correctAnswers: [
          "Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
        ],
      },
      {
        code: "21101019",
        text: "В каком из перечисленных случаев план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах должен быть пересмотрен?",
        answers: [
          "Не менее чем за 30 календарных дней до истечения срока действия предыдущего плана мероприятий",
          "Не позднее 10 дней после внесения изменений в системы управления технологическими процессами на объекте",
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
          "После назначения нового руководителя организации, эксплуатирующей объект",
        ],
        correctAnswers: [
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства",
        ],
      },
      {
        code: "21101020",
        text: "Кем должны согласовываться планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
          "Руководителями территориальных органов Федеральной службы по экологическому, технологическому и атомному надзору",
          "Руководителями территориальных органов Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "Руководителями муниципальных образований, на территории которых расположен объект",
        ],
        correctAnswers: [
          "Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
        ],
      },
      {
        code: "21101021",
        text: "Какой инструктаж проводится при выполнении разовых работ, работ по локализации и ликвидации последствий аварий, стихийных бедствий и работ, на которые оформляются наряд-допуск, разрешение или другие специальные документы?",
        answers: [
          "Повторный инструктаж",
          "Внеплановый инструктаж",
          "Целевой инструктаж",
          "Вводный инструктаж",
        ],
        correctAnswers: ["Целевой инструктаж"],
      },
      {
        code: "21101022",
        text: "Из каких разделов состоит план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Из специализированных разделов",
          "Из частных разделов",
          "Из разделов, предусмотренных требованиями федеральных норм и правил в области промышленной безопасности",
          " Из общих и специальных разделов",
        ],
        correctAnswers: [" Из общих и специальных разделов"],
      },
      {
        code: "21101023",
        text: "Кем составляется перечень контролируемых параметров, определяющих взрывоопасность процесса в каждом конкретном случае?",
        answers: [
          "Организацией, осуществляющей экспертизу промышленной безопасности",
          "Разработчиком процесса",
          "Все ответы неверны",
          "Комиссией эксплуатирующей организации",
          "Техническим руководителем эксплуатирующей организации",
        ],
        correctAnswers: ["Разработчиком процесса"],
      },
      {
        code: "21101024",
        text: "В каком случае допускается применение на объекте средств ДАУ, блокировки, контроля и противоаварийной защиты, производственной и аварийной сигнализации, связи, оповещения об аварийных ситуациях, отработавших назначенный срок службы?",
        answers: [
          "В случае особенностей технологического процесса",
          "В случае установления разработчиком процесса",
          "Не допускается ни в каком случае",
          "В случае установления контроля, объем которого должен обеспечить их надежную работу",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "21101025",
        text: "В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "В целях регламентации действий персонала при возникновении аварии",
          "В целях регламентации действий подразделений муниципальной пожарной охраны при возникновении аварии",
          "В целях обеспечения соответствия объекта требованиям промышленной безопасности",
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
        ],
        correctAnswers: [
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
        ],
      },
      {
        code: "21101026",
        text: "Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?",
        answers: [
          "Только для ОПО I и II классов опасности",
          "Для всех ОПО без исключения",
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "21101027",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов I класса опасности (за исключением объектов, на которых ведутся горные работы)?",
        answers: ["5 лет", "6 месяцев", "1 год", "2 года"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "21101028",
        text: "Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?",
        answers: [
          "5 лет",
          "Наименьший срок, предусмотренный для этих объектов",
          "3 года",
          "1,5 года",
        ],
        correctAnswers: ["Наименьший срок, предусмотренный для этих объектов"],
      },
      {
        code: "21101029",
        text: "Кто проводит проверку знания действий персонала в предаварийных и аварийных ситуациях?",
        answers: [
          "Пожарно-спасательная комиссия МЧС",
          "Учебно-экзаменационная комиссия обучающей организации",
          "Квалификационная (экзаменационная) комиссия эксплуатирующей организации",
          "Аттестационная комиссия Ростехнадзора",
        ],
        correctAnswers: [
          "Квалификационная (экзаменационная) комиссия эксплуатирующей организации",
        ],
      },
      {
        code: "21101030",
        text: "Какой должна быть температура наружных поверхностей горячих конструктивных частей зерносушилок, вентиляторов?",
        answers: [
          "Не должна превышать 70 °С",
          "Не должна превышать 55 °С",
          "Не должна превышать 50 °С",
          "Не должна превышать 45 °С",
        ],
        correctAnswers: ["Не должна превышать 45 °С"],
      },
      {
        code: "21101031",
        text: "В какой цвет должны быть окрашены органы управления аварийного выключения оборудования взрывопожароопасных производственных объектов хранения и переработки растительного сырья?",
        answers: [
          "В серый цвет",
          "В красный цвет",
          "В синий цвет",
          "В зеленый цвет",
        ],
        correctAnswers: ["В красный цвет"],
      },
      {
        code: "21101032",
        text: "Каким из перечисленных способов должно производиться сращивание концов приводных ремней оборудования взрывопожароопасных производственных объектов хранения и переработки растительного сырья?",
        answers: [
          "При помощи стальных соединителей",
          "Путем склеивания или сыромятными сшивками",
          "Путем горячей вулканизации",
          "При помощи медных или алюминиевых соединителей",
          "Всеми перечисленными способами",
        ],
        correctAnswers: ["Путем склеивания или сыромятными сшивками"],
      },
      {
        code: "21101033",
        text: "Чем должны быть снабжены части станков, машин, аппаратов, а также механизмы, требующие смазки?",
        answers: [
          "Приспособлениями, исключающими возможность заклинивания",
          "Ремнедержателями",
          "Автоматическими смазочными приборами или масленками с резервуарами достаточной вместительности, которые заполняются смазкой во время остановки этого оборудования",
          "Ворошителями или вибраторами",
        ],
        correctAnswers: [
          "Автоматическими смазочными приборами или масленками с резервуарами достаточной вместительности, которые заполняются смазкой во время остановки этого оборудования",
        ],
      },
      {
        code: "21101034",
        text: "Что должны иметь дверцы, смотровые лючки и выпускные устройства оборудования?",
        answers: [
          "Рукоятки, скобы и другие устройства для удобного и безопасного удержания их при снятии и установке",
          "Концевые выключатели",
          "Уплотнения, не пропускающие пыль",
          "Устройства, исключающие их случайное снятие или открывание",
        ],
        correctAnswers: ["Уплотнения, не пропускающие пыль"],
      },
      {
        code: "21101035",
        text: "Какие из перечисленных требований предъявляются к вальцовым станкам?",
        answers: [
          "Должны иметь постоянный нерегулируемый зазор между вальцами",
          "Должны иметь световую сигнализацию холостого хода",
          "Должны иметь звуковую сигнализацию холостого хода",
          "Должны иметь устройство останова при отсутствии продукта",
          "Должны иметь регулируемый зазор между вальцами в пределах от 1,0 до 3,0 мм",
        ],
        correctAnswers: ["Должны иметь световую сигнализацию холостого хода"],
      },
      {
        code: "21101036",
        text: "Кто может разрешить пуск вновь установленного оборудования или оборудования после ремонта?",
        answers: [
          "Главный механик эксплуатирующей организации",
          "Руководитель, технический руководитель или главный инженер эксплуатирующей организации",
          "Главный энергетик эксплуатирующей организации",
          "Начальник службы производственного контроля эксплуатирующей организации",
          "Руководитель территориального управления Федеральной службы по экологическому, технологическому и атомному надзору",
        ],
        correctAnswers: [
          "Руководитель, технический руководитель или главный инженер эксплуатирующей организации",
        ],
      },
      {
        code: "21101037",
        text: "Запрещается ли снимать или надевать приводные ремни на ходу, регулировать натяжение ремней или цепей машин во время их работы при эксплуатации технологического, аспирационного и транспортного оборудования?",
        answers: [
          "Разрешается при условии, что работы производятся при наличии наряда-допуска",
          "Разрешается при условии, что работы производятся под наблюдением лица, ответственного за безопасную эксплуатацию оборудования",
          "Разрешается при условии, что данные работы не угрожают безопасности обслуживающего персонала",
          "Запрещается",
        ],
        correctAnswers: ["Запрещается"],
      },
      {
        code: "21101038",
        text: "Какое требование предъявляется к объему загрузки зерна в подогреватель зерна перед пуском в него пара?",
        answers: [
          "Подогреватель должен быть загружен зерном доверху",
          "Подогреватель должен быть загружен зерном наполовину",
          "Подогреватель должен быть загружен зерном на 1/4 всего объема",
          "Подогреватель должен быть загружен зерном на 3/4 всего объема",
        ],
        correctAnswers: ["Подогреватель должен быть загружен зерном доверху"],
      },
      {
        code: "21101039",
        text: "В каком из перечисленных случаев не допускается включение электромагнитных сепараторов под напряжение?",
        answers: [
          "При отсутствии или неисправности световой сигнализации",
          "При отсутствии защитных ограждений",
          "При отсутствии постоянного контроля за работой со стороны обслуживающего персонала",
          "При сопротивлении изоляции обмоток не менее 0,5 МОм",
        ],
        correctAnswers: [
          "При отсутствии или неисправности световой сигнализации",
        ],
      },
      {
        code: "21101040",
        text: "Что из перечисленного не должно применяться для очистки магнитных колонок от металломагнитных примесей?",
        answers: [
          "Специальные щетки",
          "Струя воды под сильным напором",
          "Деревянные скребки",
        ],
        correctAnswers: ["Струя воды под сильным напором"],
      },
      {
        code: "21101041",
        text: "Какие требования к рассеву указаны неверно?",
        answers: [
          "Разрешается в период разбега останавливать рассев, работающий исправно, а также повторно включать рассев до его полной остановки",
          "При пусках и остановках рассевов запрещается находиться в проходе между рассевами",
          "Рассев должен вращаться равномерно без ударов и стуков. При нарушении нормального хода или появлении стуков рассев необходимо немедленно остановить, прекратив подачу продукта",
          "Перед пуском рассева надлежит проверить отсутствие в машине посторонних предметов",
        ],
        correctAnswers: [
          "Разрешается в период разбега останавливать рассев, работающий исправно, а также повторно включать рассев до его полной остановки",
        ],
      },
      {
        code: "21101042",
        text: "Какие требования безопасности при работе вальцедекового станка указаны неверно?",
        answers: [
          "Необходимо следить за уравновешенным ходом барабана вальцедекового станка. При возникновении стуков или неравномерного хода станок должен быть немедленно остановлен",
          "На выходе продуктов из зоны шелушения вальцедекового станка следует устанавливать отражатель, препятствующий разбрасыванию продукта",
          "Разрешается подхватывать руками посторонние предметы, попавшие в рабочую зону станка (между вальцом и декой), до полной остановки станка при соблюдении дополнительных мер безопасности",
          "Абразивные части вальцедекового станка (барабан и дека) должны быть прочными, не иметь выбоин и трещин и должны быть скреплены с чугунными основаниями. Запрещается допускать к эксплуатации вальцы и деки, имеющие трещины",
        ],
        correctAnswers: [
          "Разрешается подхватывать руками посторонние предметы, попавшие в рабочую зону станка (между вальцом и декой), до полной остановки станка при соблюдении дополнительных мер безопасности",
        ],
      },
      {
        code: "21101043",
        text: "Что из перечисленного не должно соблюдаться при работе пропаривателей, паровых сушилок, запарных и варочных котлов?",
        answers: [
          "Надежная герметизация оборудования, паро- и теплопроводов и их соединений, исключающая проникновение пара в рабочее помещение",
          "Тщательная термоизоляция всех горячих участков машин, аппаратов, паро- и теплопроводов",
          "Исправность предохранительных клапанов контрольных и измерительных приборов (манометров, термометров и т. д.)",
          "Давление пара должно поддерживаться выше контрольной отметки, указанной на шкале манометра",
          "Надежность работы запорных вентилей, шлюзовых затворов на поступлении и выпуске продукции и исправность ограждений приводных ремней, шкивов и цепных передач",
          "Все перечисленное",
        ],
        correctAnswers: [
          "Давление пара должно поддерживаться выше контрольной отметки, указанной на шкале манометра",
        ],
      },
      {
        code: "21101044",
        text: "Какими совками разрешается брать пробу из лючка выпускного патрубка паровой сушилки?",
        answers: [
          "Деревянными",
          "Оловянными",
          "Металлическими",
          "Керамическими",
        ],
        correctAnswers: ["Деревянными"],
      },
      {
        code: "21101045",
        text: "На какую высоту должны быть ограждены люки в полах выбойных и фасовочных отделений, через которые подаются мешки на конвейер?",
        answers: [
          "Не менее 0,5 м",
          "Не менее 1,0 м",
          "Не менее 0,85 м",
          "Не менее 0,75 м",
        ],
        correctAnswers: ["Не менее 1,0 м"],
      },
      {
        code: "21101046",
        text: "Какие требования к швейным машинам для ремонта тканевой тары указаны неверно?",
        answers: [
          "Швейные машины для ремонта тканевой тары устанавливаются на общем столе длиной не более 25 м, допускается не закреплять машины на столах при условии обеспечения их устойчивости",
          "У каждой машины должен быть местный отсос для удаления пыли и тканевого ворса",
          "Швейные машины должны быть обеспечены предохранительными приспособлениями, исключающими попадание рук под иглу",
          "Швейные машины должны иметь быстродействующие тормозные устройства",
          "У лопаты иглодержателя швейных машин должна быть прикреплена дугообразная пластина, а у ножки иглодержателя должно быть прикреплено лезвие для обрезания нитки",
        ],
        correctAnswers: [
          "Швейные машины для ремонта тканевой тары устанавливаются на общем столе длиной не более 25 м, допускается не закреплять машины на столах при условии обеспечения их устойчивости",
        ],
      },
      {
        code: "21101047",
        text: "В каком из перечисленных случаев не допускается работа фильтров с механическим встряхиванием рукавов?",
        answers: [
          "Только при неисправном встряхивающем механизме",
          "Только при работе встряхивающего механизма со сниженным числом ударов",
          "При неисправном встряхивающем механизме либо при работе встряхивающего механизма со сниженным числом ударов",
        ],
        correctAnswers: [
          "При неисправном встряхивающем механизме либо при работе встряхивающего механизма со сниженным числом ударов",
        ],
      },
      {
        code: "21101048",
        text: "С какой периодичностью из водомаслоотделителя необходимо удалять воду, масло и грязь?",
        answers: ["Ежесменно", "Ежедневно", "1 раз в 3 дня", "1 раз в неделю"],
        correctAnswers: ["Ежедневно"],
      },
      {
        code: "21101049",
        text: "С какой периодичностью следует тщательно очищать воздушный ресивер?",
        answers: [
          "Не реже 1 раза в 6 месяцев",
          "Не реже 1 раза в 8 месяцев",
          "Не реже 1 раза в 9 месяцев",
          "Не реже 1 раза в 12 месяцев",
        ],
        correctAnswers: ["Не реже 1 раза в 6 месяцев"],
      },
      {
        code: "21101050",
        text: "Что из перечисленного должно быть установлено между вентилятором и присоединяемыми к нему трубами?",
        answers: [
          "Гибкие патрубки (вставки) из недиэлектрических материалов",
          "Гибкие патрубки (вставки), обвитые снаружи проволокой с шагом витков не более 100 мм",
          "Негибкие патрубки (вставки), обвитые снаружи проволокой с шагом витков не более 50 мм",
          "Гибкие патрубки (вставки) из воздухонепроницаемого материала (резины, прорезиненной ткани, синтетической ткани с полимерным или иным, схожим по характеристикам, покрытием)",
          "Металлическая сетка с размерами ячеек 20 х 20 мм",
        ],
        correctAnswers: [
          "Гибкие патрубки (вставки) из воздухонепроницаемого материала (резины, прорезиненной ткани, синтетической ткани с полимерным или иным, схожим по характеристикам, покрытием)",
        ],
      },
      {
        code: "21101051",
        text: "Разрешается ли смазывать вяжущими веществами (смолой, канифолью) приводные барабаны стационарных ленточных конвейеров при ослаблении натяжения ленты?",
        answers: [
          "Запрещается",
          "Разрешается",
          "Разрешается только по согласованию с главным инженером",
          "Разрешается, если расстояние от нижней ленты конвейера до пола менее 150 мм",
        ],
        correctAnswers: ["Запрещается"],
      },
      {
        code: "21101052",
        text: "После какого времени продувки топки вентилятором допускается зажигание топлива при обслуживании топок зерносушилки?",
        answers: [
          "После 3 минут",
          "После 5 минут",
          "После 10 минут",
          "После 15 минут",
        ],
        correctAnswers: ["После 10 минут"],
      },
      {
        code: "21101053",
        text: "В каком из перечисленных случаев может производиться спуск рабочих в силосы и бункеры?",
        answers: [
          "В случае проверки температуры хранящегося сырья (плановая зачистка после опорожнения силосов или бункеров от остатков сырья предыдущего хранения, обслуживание (ремонт) внутренних поверхностей силосов и бункеров)",
          "В случае проведения анализа газовой среды",
          "В случае отбора проб сырья",
          "В исключительных случаях при обоснованной производственной необходимости (плановая зачистка после опорожнения силосов или бункеров от остатков сырья предыдущего хранения, обслуживание (ремонт) внутренних поверхностей силосов и бункеров)",
        ],
        correctAnswers: [
          "В исключительных случаях при обоснованной производственной необходимости (плановая зачистка после опорожнения силосов или бункеров от остатков сырья предыдущего хранения, обслуживание (ремонт) внутренних поверхностей силосов и бункеров)",
        ],
      },
      {
        code: "21101054",
        text: "Кто должен присутствовать при спуске рабочих в силосы и бункеры?",
        answers: [
          "Главный инженер",
          "Представитель территориального управления Ростехнадзора",
          "Руководитель отдела охраны труда и промышленного контроля",
          "Ответственный руководитель работ",
        ],
        correctAnswers: ["Ответственный руководитель работ"],
      },
      {
        code: "21101055",
        text: "Что из перечисленного должен сделать ответственный руководитель работ до начала спуска в силос?",
        answers: [
          "Проинструктировать на рабочем месте всех участвующих в спуске рабочих",
          "Лично следить за соблюдением каждым в отдельности рабочим всех мер безопасности при подготовке к спуску, опускании и производстве работ в силосе",
          "Лично проверить состояние применяемых для спуска людей в силосы устройств, страховочной привязи и средств индивидуальной (коллективной) защиты, обеспечивающих безопасность работ на высоте",
          "Выполнить все перечисленные требования",
        ],
        correctAnswers: ["Выполнить все перечисленные требования"],
      },
      {
        code: "21101056",
        text: "При каком условии должен производиться доступ рабочих в силосы и бункеры через нижний люк для производства огневых работ?",
        answers: [
          "Только при наличии наряда-допуска, оформленного в порядке, определенном Правилами противопожарного режима в Российской Федерации, утвержденными постановлением Правительства Российской Федерации от 16 сентября 2020 г. N 1479, и разрешения ответственного руководителя работ и под его наблюдением",
          "Доступ рабочих в силосы и бункеры через нижний люк запрещается",
          "Только в присутствии главного инженера эксплуатирующей организации и представителя территориального органа Федеральной службы по экологическому, технологическому и атомному надзору",
          "Только при наличии разрешения территориального органа Федеральной службы по экологическому, технологическому и атомному надзору и в присутствии наблюдающего",
        ],
        correctAnswers: [
          "Только при наличии наряда-допуска, оформленного в порядке, определенном Правилами противопожарного режима в Российской Федерации, утвержденными постановлением Правительства Российской Федерации от 16 сентября 2020 г. N 1479, и разрешения ответственного руководителя работ и под его наблюдением",
        ],
      },
      {
        code: "21101057",
        text: "Какие требования необходимо соблюдать при разрушении сводов и зависших масс зерна или других продуктов?",
        answers: [
          "Лазовые и загрузочные люки силосов и бункеров должны быть открыты",
          "Лазовые и загрузочные люки силосов и бункеров должны быть закрыты",
          "Лазовые люки должны быть открыты, а загрузочные люки - закрыты",
          "Лазовые люки должны быть закрыты, а загрузочные люки - открыты",
        ],
        correctAnswers: [
          "Лазовые и загрузочные люки силосов и бункеров должны быть открыты",
        ],
      },
      {
        code: "21101058",
        text: "В каком из перечисленных случаев в процессе выполнения работ в силосах и бункерах подачу воздуха в противогаз осуществляют при помощи воздуходувки?",
        answers: [
          "При длине шланга 12 м и более",
          "При спуске рабочих в силосы и бункеры высотой 3 м и более",
          "При спуске рабочих в силосы и бункеры высотой 7 м и более",
          "При длине шланга 5 м и более",
        ],
        correctAnswers: ["При длине шланга 12 м и более"],
      },
      {
        code: "21101059",
        text: "Какой допускается применять минимальный диаметр воздуховода аспирационных установок при соответствующем обосновании?",
        answers: ["80 мм", "50 мм", "150 мм", "120 мм"],
        correctAnswers: ["80 мм"],
      },
      {
        code: "21101060",
        text: "Какая из перечисленных характеристик не относится к аспирационным установкам элеваторов?",
        answers: [
          "Ни одна из перечисленных характеристик",
          "Содержание в обращающемся продукте большого количества сорной и минеральной примеси",
          "Наличие оборудования и участков, не подлежащих герметизации",
          "Невысокая мощность транспортных потоков и коммуникаций",
        ],
        correctAnswers: [
          "Невысокая мощность транспортных потоков и коммуникаций",
        ],
      },
      {
        code: "21101061",
        text: "Какое из перечисленных условий компоновки аспирационных установок указано неверно?",
        answers: [
          "В состав установки следует включать преимущественно оборудование, работающее одновременно",
          "Аспирационные установки для повышения надежности работ и удобства эксплуатации рекомендуется проектировать с максимально возможным количеством точек отсоса",
          "При проектировании аспирационных установок необходимо стремиться к минимальной протяженности воздуховодов",
          "В одну установку по возможности включают однородные машины",
          "Следует стремиться к симметричному расположению воздуховодов относительно главной магистрали",
        ],
        correctAnswers: [
          "Аспирационные установки для повышения надежности работ и удобства эксплуатации рекомендуется проектировать с максимально возможным количеством точек отсоса",
        ],
      },
      {
        code: "21101062",
        text: "Из каких сплавов металлов изготовляются воздуховоды и фасонные детали аспирационных установок?",
        answers: [
          "Из оцинкованной стали",
          "Из стеклопластика",
          "Из нержавеющей стали",
          "Из алюминия",
        ],
        correctAnswers: ["Из оцинкованной стали"],
      },
      {
        code: "21101063",
        text: 'В каком случае допускается объединять в одну аспирационную установку оборудование первичной ("черной") и окончательной ("белой") очистки зерна в зерноочистительных отделениях мукомольных и крупяных заводов?',
        answers: [
          "На предприятиях малой мощности",
          "На предприятиях, оборудованных внутрицеховым пневматическим транспортом",
          "Не допускается",
          "На предприятиях высокой мощности",
          "При горизонтальном расположении воздуховодов",
        ],
        correctAnswers: ["На предприятиях малой мощности"],
      },
      {
        code: "21101064",
        text: "В каких воздуховодах аспирационных установок элеваторов следует принимать скорость воздуха до пылеотделителя не менее 12 м/с при расчете? Выберите два правильных варианта ответа.",
        answers: [
          "В горизонтальных",
          "В вертикальных",
          "В наклонных (более 60°)",
          "В наклонных (менее 60°)",
        ],
        correctAnswers: ["В вертикальных", "В наклонных (более 60°)"],
      },
      {
        code: "21101065",
        text: "Какое из перечисленных условий организованного подвода воздуха в помещения элеваторов указано верно?",
        answers: [
          "Подачу воздуха в помещение следует производить в нижнюю зону",
          "Специальные приточные устройства следует предусматривать при воздухообмене, превышающем 1 обмен в час",
          "Приточные устройства рекомендуется устанавливать в зонах с наибольшим загрязнением наружного воздуха",
          "Наружные отверстия приточных устройств следует закрывать сеткой с отверстиями 10 x 10 мм",
        ],
        correctAnswers: [
          "Специальные приточные устройства следует предусматривать при воздухообмене, превышающем 1 обмен в час",
        ],
      },
      {
        code: "21101066",
        text: "Какая принимается минимальная скорость воздуха в воздуховодах аспирационных установок в размольном отделении мукомольных и крупяных заводов при вертикальном и наклонном (более 60°) расположении воздуховодов?",
        answers: ["8 м/с", "10 м/с", "12 м/с", "16 м/с"],
        correctAnswers: ["10 м/с"],
      },
      {
        code: "21101067",
        text: "Какими фланцами рекомендуется соединять между собой звенья воздуховодов аспирационных установок диаметром до 280 мм?",
        answers: [
          "Из цветных металлов",
          "Из полосовой стали",
          "Из угловой стали",
          "Из сталеалюминевых сплавов",
        ],
        correctAnswers: ["Из полосовой стали"],
      },
      {
        code: "21101068",
        text: "Каким способом рекомендуется осуществлять аспирацию силосов?",
        answers: [
          "Всеми перечисленными",
          "Путем организации отсоса воздуха непосредственно от силоса или группы силосов",
          "Путем организации отсоса воздуха от транспортирующих механизмов, загружающих силосы",
          "Путем вывода воздуховодов диаметром 500 - 700 мм на 1 м выше конька кровли надсилосного этажа и надсилосных галерей",
        ],
        correctAnswers: ["Всеми перечисленными"],
      },
      {
        code: "21101069",
        text: "Какие требования к проектированию ограждений, расположенных внутри производственных зданий площадок, антресолей, приямков, на которых размещено технологическое оборудование, указаны верно?",
        answers: [
          "Ограждения следует проектировать алюминиевыми",
          "Ограждения должны быть сплошными на высоту не менее 150 мм от пола",
          "Все перечисленные указаны верно",
          "Ограждения следует проектировать высотой 0,5 м",
        ],
        correctAnswers: [
          "Ограждения должны быть сплошными на высоту не менее 150 мм от пола",
        ],
      },
      {
        code: "21101070",
        text: "Какую минимальную площадь легкосбрасываемых конструкций следует принимать при отсутствии расчетных данных на 1 м³ взрывоопасного помещения?",
        answers: ["0,04 м²", "0,03 м²", "0,25 м²", "0,3 м²"],
        correctAnswers: ["0,03 м²"],
      },
      {
        code: "21101071",
        text: "Что следует предусматривать при проектировании искусственного освещения зданий и сооружений по хранению и переработке зерна?",
        answers: [
          "Применение энергосберегающих ламп и оборудования",
          "Светильники с лампами накаливания для комбикормовых заводов и других зданий и помещений",
          "Светильники с люминесцентными и светодиодными лампами для производственных помещений мельниц, крупяных заводов и диспетчерских помещений",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21101072",
        text: "Какое из перечисленных требований к взрывопожароопасным производственным объектам хранения и переработки растительного сырья указано неверно?",
        answers: [
          "Доступ на территорию организации, эксплуатирующей объекты, посторонним лицам запрещен",
          "Необходимые для производственных целей подземные резервуары, колодцы, пожарные водоемы должны быть закрыты или ограждены со всех сторон",
          "Устройство железнодорожных путей, переездов и переходов через них, а также организация и эксплуатация железнодорожного хозяйства организации должны соответствовать требованиям нормативных правовых актов в сфере транспорта",
          "В организации, эксплуатирующей объекты, должно быть обеспечено исправное состояние отводов атмосферных осадков от зданий и сооружений к водостокам, дорог для транспорта, пожарных проездов, рельсовых путей (с соблюдением требуемых габаритов, допускаемых уклонов и радиусов закруглений), сетей наружного освещения, пешеходных дорожек, пожарных и хозяйственных водопроводов, а также ограждений территории объектов и организации",
          "К зданиям, сооружениям и строениям объектов должен быть обеспечен подъезд пожарных автомобилей, пожарные водоемы в ночное время должны иметь звуковой указатель",
        ],
        correctAnswers: [
          "К зданиям, сооружениям и строениям объектов должен быть обеспечен подъезд пожарных автомобилей, пожарные водоемы в ночное время должны иметь звуковой указатель",
        ],
      },
      {
        code: "21101073",
        text: "Кто несет ответственность за выполнение (соблюдение) мероприятий, предусмотренных актом-допуском, оформленным перед началом работ на территории эксплуатирующей объекты организации?",
        answers: [
          "Только руководители строительно-монтажной организации",
          "Только заказчик",
          "Руководители строительно-монтажной организации и заказчик",
          "Генеральный подрядчик",
        ],
        correctAnswers: [
          "Руководители строительно-монтажной организации и заказчик",
        ],
      },
      {
        code: "21101074",
        text: "Какое из перечисленных требований к топкам, работающим на жидком и газообразном топливе, указано верно?",
        answers: [
          "На магистрали, подводящей жидкое или газообразное топливо, должен быть головной запорный вентиль, установленный у выхода из топочного помещения, на расстоянии не менее 3 м от топки",
          "Повторная подача топлива в топку и розжиг его после устранения причины неисправности осуществляется только после проветривания топки в течение 30 минут",
          "Если жидкое или газообразное топливо при розжиге топки не загорается в течение 1 минуты, система контроля и автоматики горения топлива должна отключить подачу его в форсунку",
          "Допускается оставлять работающую топку без присмотра в течение 10 минут",
        ],
        correctAnswers: [
          "На магистрали, подводящей жидкое или газообразное топливо, должен быть головной запорный вентиль, установленный у выхода из топочного помещения, на расстоянии не менее 3 м от топки",
        ],
      },
      {
        code: "21101075",
        text: "В каком случае необходимо пользоваться специальными скребками и щетками при обслуживании машин?",
        answers: [
          "Во всех перечисленных случаях",
          "При прочистке зазора между заслонкой и питающим валком вальцового станка",
          "При очистке на холостом ходу верхних плоскостей решет сепараторов",
          "При расчистке завалов в башмаках норий и конвейерах",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "21101076",
        text: "Какое устанавливается максимальное усилие, необходимое для перемещения несамоходной разгрузочной тележки?",
        answers: [
          "150 Н (15 кгс)",
          "100 Н (10 кгс)",
          "200 Н (20 кгс)",
          "250 Н (25 кгс)",
        ],
        correctAnswers: ["150 Н (15 кгс)"],
      },
      {
        code: "21101077",
        text: "После выполнения каких действий допускается включение электромагнитного сепаратора под напряжение? Укажите все правильные ответы.",
        answers: [
          "После визуального осмотра ремонтной бригадой",
          "После измерения сопротивления изоляции обмоток электромагнитов, которое должно быть менее 0,5 МОм",
          "После проверки соответствия всей электрической части техническим регламентам и другим нормативно-техническим документам по устройству электроустановок",
          "После испытания изоляции",
        ],
        correctAnswers: [
          "После проверки соответствия всей электрической части техническим регламентам и другим нормативно-техническим документам по устройству электроустановок",
          "После испытания изоляции",
        ],
      },
      {
        code: "21101078",
        text: "Какие должны быть ограждения, расположенные внутри производственных зданий, площадок, антресолей, приямков, на которых размещено технологическое оборудование?",
        answers: [
          "Стальными решетчатыми высотой 1 м, при этом ограждения должны быть сплошными на высоту не менее 0,15 м от пола",
          "Дюралюминиевые сплошные ограждения высотой 1,5 м",
          "Стальные решетчатые ограждения высотой 1,5 м, при этом ограждения должны быть сплошными на высоту не менее 0,25 м от пола",
          "Стальные сплошные ограждения высотой 1 м",
        ],
        correctAnswers: [
          "Стальными решетчатыми высотой 1 м, при этом ограждения должны быть сплошными на высоту не менее 0,15 м от пола",
        ],
      },
      {
        code: "21101079",
        text: "Какие явления допускаются при эксплуатации металлических силосов?",
        answers: [
          "Неплотности в зоне опоры стенок силосных емкостей на фундаменты",
          "Все ответы неверны",
          "Подтеки на внутренних поверхностях стен",
          "Неплотности в местах крепления дефлекторов и термоподвесок",
          "Подтеки на днище",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21101080",
        text: "В каком случае допускается одновременная разборка или ремонт конструкций, оборудования в двух или более ярусах по одной вертикали?",
        answers: [
          "Не допускается ни в каком случае",
          "При согласовании с лицами, ответственными за эксплуатацию",
          "При наличии соответствующих защитных устройств (настилы, сетки, козырьки), обеспечивающих безопасную работу на всех отметках",
          "При обосновании проектной документацией",
        ],
        correctAnswers: [
          "При наличии соответствующих защитных устройств (настилы, сетки, козырьки), обеспечивающих безопасную работу на всех отметках",
        ],
      },
      {
        code: "21101081",
        text: "В каком случае необходимо устанавливать крюки для подвески талей, блоков над съемными деталями оборудования?",
        answers: [
          "Если съемные детали оборудования массой более 50 кг",
          "Все ответы неверны",
          "В любом случае вне зависимости от их массы съемных деталей",
          "Если съемные детали оборудования массой более 20 кг",
        ],
        correctAnswers: ["Если съемные детали оборудования массой более 50 кг"],
      },
      {
        code: "21101082",
        text: "С какой периодичностью должен испытываться и проверяться квалифицированным персоналом пневматический инструмент?",
        answers: [
          "Ежемесячно",
          "Ежесменно",
          "Перед каждым применением",
          "1 раз в 3 месяца",
        ],
        correctAnswers: ["Ежемесячно"],
      },
      {
        code: "21101083",
        text: "Какое из перечисленных требований при работе с ручным немеханизированным инструментом указано неверно? Выберите 2 варианта ответа.",
        answers: [
          "Выколотка должна быть из твердого металла",
          "Губки гаечных ключей должны быть закатаны. Разводные ключи должны быть ослаблены в своих подвижных частях",
          "Напильники, ножовки, стамески, долота и другие ручные инструменты должны быть прочно закреплены в деревянной рукоятке с наложенным на нее стальным кольцом",
          "При запрессовке и распрессовке деталей (подшипники, втулки) с помощью кувалды и выколотки последнюю следует держать клещами или специальным захватом",
        ],
        correctAnswers: [
          "Выколотка должна быть из твердого металла",
          "Губки гаечных ключей должны быть закатаны. Разводные ключи должны быть ослаблены в своих подвижных частях",
        ],
      },
      {
        code: "21101084",
        text: "Какое из перечисленных действий разрешается при производстве работ в силосах и бункерах?",
        answers: [
          "Нахождение людей под силосом или бункером при разрушении сводов и зависших масс зерна или других продуктов",
          "Хождение по насыпи зерна или других продуктов хранения",
          "Доступ в силосы и бункеры через нижний люк",
          "Нахождение людей, не участвующих в разрушении сводов или зависших масс, в зоне лазовых и загрузочных люков",
        ],
        correctAnswers: ["Доступ в силосы и бункеры через нижний люк"],
      },
      {
        code: "21101085",
        text: "С какой периодичностью следует проверять переносные электроинструменты, лампы, трансформаторы на стенде или прибором в отношении исправности их заземляющих проводов и отсутствия замыкания между проводами?",
        answers: [
          "Не реже 1 раза в месяц",
          "Не реже 1 раза в смену",
          "Не реже 1 раза в сутки",
          "1 раз в 10 дней",
        ],
        correctAnswers: ["Не реже 1 раза в месяц"],
      },
      {
        code: "21101086",
        text: "Когда должна отключаться приточно-вытяжная вентиляция аккумуляторных помещений после окончания заряда зарядных устройств?",
        answers: [
          "Не ранее чем через 1,5 часа",
          "Не ранее чем через 1 час",
          "Не ранее чем через 2 часа",
          "Не ранее чем через 30 минут",
        ],
        correctAnswers: ["Не ранее чем через 1,5 часа"],
      },
      {
        code: "21101087",
        text: "При каком количестве напольных машин, имеющих тяговые аккумуляторные батареи, их заряжают как в отдельных помещениях с естественной вентиляцией, так и в общих невзрывопожароопасных производственных помещениях?",
        answers: ["До 6", "До 10", "До 15", "До 20"],
        correctAnswers: ["До 6"],
      },
      {
        code: "21101088",
        text: "В каком случае допускается размещать масляные, сухие, а также с негорючей жидкостью комплектные трансформаторные подстанции в общем помещении с распределительными устройствами?",
        answers: [
          "Только при наличии аварийной сигнализации",
          "Только если трансформаторы отделены перегородками",
          "Только если предусмотрена приточно-вытяжная вентиляция",
          "Допускается размещать в любом случае",
          "Ни в каком случае",
        ],
        correctAnswers: ["Допускается размещать в любом случае"],
      },
      {
        code: "21101089",
        text: "Какой должна быть степень защиты оболочки переносных светильников прожекторного типа, использующихся для освещения бункеров и силосов сверху через люки?",
        answers: [
          "Не ниже IP64",
          "Не ниже IP54",
          "Не ниже IP24",
          "Не ниже IP44",
        ],
        correctAnswers: ["Не ниже IP54"],
      },
      {
        code: "21101090",
        text: "Какие из перечисленных вариантов размещения и прокладки оборудования допускаются на объектах хранения и переработки растительного сырья?",
        answers: [
          "Прокладка транзитных воздуховодов через помещения складов сырья и готовой продукции",
          "Прокладка транзитных воздуховодов через помещения разных категорий",
          "Размещение оборудования аспирационных установок (фильтров, циклонов, вентиляторов) в производственных помещениях категорий Б и В совместно с транспортным и технологическим оборудованием",
          "Размещение вентиляторов и пылеуловителей отдельно стоящих зерносушилок в рабочих зданиях элеваторов",
        ],
        correctAnswers: [
          "Размещение оборудования аспирационных установок (фильтров, циклонов, вентиляторов) в производственных помещениях категорий Б и В совместно с транспортным и технологическим оборудованием",
        ],
      },
      {
        code: "21101091",
        text: "Какая часть конвейера должна ограждаться на высоту не менее 2 м от пола?",
        answers: [
          "Все ответы неверны",
          "Опорные ролики",
          "Шкивы",
          "Концы валов",
          "Муфты",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21101092",
        text: "На какой высоте от пола устанавливаются смотровые люки для удобства наблюдения за ходом ленты в норийных трубах?",
        answers: ["1,0 м", "1,6 м", "1,2 м", "0,9 м"],
        correctAnswers: ["1,6 м"],
      },
      {
        code: "21101093",
        text: "Какое из перечисленных требований к обустройству головок норий с целью их обслуживания указано неверно?",
        answers: [
          "Оси приводных барабанов, которые расположены на высоте от пола более 1,5 м, предусматривают специальные площадки",
          "Специальные площадки должны иметь зашивку внизу на 0,15 м с обеспечением проходов для обслуживания",
          "Для подъема на площадки должны быть устроены стационарные лестницы с перилами шириной не менее 1,0 м",
        ],
        correctAnswers: [
          "Для подъема на площадки должны быть устроены стационарные лестницы с перилами шириной не менее 1,0 м",
        ],
      },
      {
        code: "21101094",
        text: "Какими сетками должны быть закрыты открытые всасывающие отверстия при работе вентиляторов?",
        answers: [
          "С размером ячеек 30 x 30 мм",
          "С размером ячеек 20 x 20 мм",
          "С размером ячеек 25 x 25 мм",
          "С размером ячеек 15 x 15 мм",
        ],
        correctAnswers: ["С размером ячеек 20 x 20 мм"],
      },
      {
        code: "21101095",
        text: "Какой из перечисленных элементов не входит в состав ротационных воздуходувок?",
        answers: [
          "Обратный клапан",
          "Глушитель",
          "Система смазки",
          "Предохранительный клапан",
          "Манометр",
        ],
        correctAnswers: ["Обратный клапан"],
      },
      {
        code: "21101096",
        text: "С какой периодичностью необходимо очищать и промывать содовым раствором трубу между компрессором и ресивером?",
        answers: [
          "Каждый месяц",
          "Каждые 6 месяцев",
          "Каждые 3 месяца",
          "Каждые 10 дней",
        ],
        correctAnswers: ["Каждые 6 месяцев"],
      },
      {
        code: "21101097",
        text: "Какой вид клапана устанавливается перед водомаслоотделителем?",
        answers: [
          "Регулирующий клапан",
          "Обратный клапан",
          "Предохранительный клапан",
          "Пружинно-предохранительный",
        ],
        correctAnswers: ["Обратный клапан"],
      },
      {
        code: "21101098",
        text: "Каким образом следует производить очистку шлюзового затвора от продукта, налипшего на стенки крыльчатки?",
        answers: [
          "Путем продувки его воздухом",
          "Путем пропарки",
          "Путем промывки",
          "Путем продувки инертным газом",
        ],
        correctAnswers: ["Путем продувки его воздухом"],
      },
      {
        code: "21101099",
        text: "Какое из перечисленных требований к станкам для резки заплат указано верно? Выберите 2 варианта ответа.",
        answers: [
          "Между кромкой стола и ограждением ножевого диска станка для резки заплат должен быть зазор не более 3 мм",
          "Приводной вал с дисковыми ножами станка должен быть отбалансирован",
          "Станок со всех сторон должен иметь проходы не менее 1,5 м. Диски должны быть отбалансированы",
          "Между кромкой стола и ограждением ножевого диска станка для резки заплат должен быть зазор не более 5 мм",
        ],
        correctAnswers: [
          "Между кромкой стола и ограждением ножевого диска станка для резки заплат должен быть зазор не более 3 мм",
          "Приводной вал с дисковыми ножами станка должен быть отбалансирован",
        ],
      },
      {
        code: "21101100",
        text: "Какой вентиляцией должны быть оборудованы помещения, где размещены пропариватели, сушилки, запарные и варочные аппараты?",
        answers: [
          "Естественной вентиляцией",
          "Общеобменной вентиляцией",
          "Вытяжной вентиляцией",
          "Приточной вентиляцией",
        ],
        correctAnswers: ["Приточной вентиляцией"],
      },
      {
        code: "21101101",
        text: "Какие работы допускается производить на ходу падди-машин?",
        answers: [
          "Устранять неравномерность хода",
          "Регулировать корпуса падди-машин, подвешенных на качалках",
          "Производить смазку",
          "Передвигать параллели при сработке ползуна",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21101102",
        text: "Какое должно быть расстояние от пола до монорельса?",
        answers: [
          "Не менее 3,2 м",
          "Не менее 1,2 м",
          "Не менее 2,2 м",
          "Не менее 5,5 м",
        ],
        correctAnswers: ["Не менее 3,2 м"],
      },
      {
        code: "21101103",
        text: "С помощью какого приспособления осуществляются выемка из станка, перемещение и установка вальцов?",
        answers: [
          "С помощью любых перечисленных приспособлений",
          "С помощью монорельсовых путей",
          "С помощью талей",
          "С помощью крановых и других тележек",
        ],
        correctAnswers: ["С помощью любых перечисленных приспособлений"],
      },
      {
        code: "21101104",
        text: "Какой допустимый предел зазора между вальцами?",
        answers: [
          "От 0,05 до 0,5 мм",
          "От 0,25 до 1,0 мм",
          "От 0,1 до 1,0 мм",
          "От 0,2 до 1,5 мм",
        ],
        correctAnswers: ["От 0,1 до 1,0 мм"],
      },
      {
        code: "21101105",
        text: "Какой допускается перекос общих осей поверхностей цапф (диаметром 65 мм) мелющих вальцов в каждой половине вальцового станка?",
        answers: [
          "Не более 0,25 мм по длине 1000 мм",
          "Не более 0,2 мм по длине 800 мм",
          "Не более 0,3 мм по длине 900 мм",
          "Не более 0,15 мм по длине 1000 мм",
        ],
        correctAnswers: ["Не более 0,25 мм по длине 1000 мм"],
      },
      {
        code: "21101106",
        text: "Какое действие необходимо произвести в первую очередь при появлении стуков или других признаков неисправности в сепараторе?",
        answers: [
          "Немедленно остановить",
          "Вызвать ремонтную бригаду",
          "Оповестить лицо, ответственное за безопасное производство работ",
          "Попытаться устранить неполадки",
        ],
        correctAnswers: ["Немедленно остановить"],
      },
      {
        code: "21101107",
        text: "При нагревании до какой максимальной температуры деталей, соприкасающихся с продукцией, допускается работа электромагнитных сепараторов?",
        answers: [
          "Не выше 50 °C",
          "Не выше 60 °C",
          "Не выше 70 °C",
          "Не выше 90 °C",
        ],
        correctAnswers: ["Не выше 50 °C"],
      },
      {
        code: "21101108",
        text: "При нагревании магнитопровода до какой максимальной температуры допускается работа электромагнитных сепараторов?",
        answers: [" До 60 °C", " До 70 °C", " До 90 °C", " До 100 °C"],
        correctAnswers: [" До 60 °C"],
      },
      {
        code: "21101109",
        text: "Что из перечисленного разрешается при эксплуатации технологического, аспирационного и транспортного оборудования?",
        answers: [
          "Пуск и работа машин, выделяющих пыль, с открытыми люками",
          "Ручной отбор проб зерна, продуктов размола, зерна и крупы из оборудования, имеющего в месте отбора или непосредственной близости движущиеся части",
          "Все ответы неверны",
          "Заклеивание и забивание специально устраиваемых в крышках машин аспирационных щелей",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21101110",
        text: "На каком минимальном расстоянии от сушилки допускается хранение топлива и смазочных материалов?",
        answers: ["20 м", "15 м", "10 м", "5 м"],
        correctAnswers: ["20 м"],
      },
      {
        code: "21101111",
        text: "Какое из перечисленных действий, которые необходимо произвести в случае загорания зерна в сушилке, указано неверно?",
        answers: [
          "Прекратить подачу сырого зерна в зерносушилку",
          "Все ответы неверны",
          "Выключить все вентиляторы",
          "Прекратить подачу зерна из сушилки в элеватор или на склад",
          "Закрыть задвижки в воздуховоде от топки и сушилки",
        ],
        correctAnswers: ["Прекратить подачу сырого зерна в зерносушилку"],
      },
      {
        code: "21101112",
        text: "Кто должен присутствовать при осмотре или ремонте в надсушильных, подсушильных бункерах и тепловлагообменниках?",
        answers: [
          "Начальник цеха или смены",
          "Любой работник цеха или смены",
          "Представитель проектной организации",
          "Технический руководитель организации",
        ],
        correctAnswers: ["Начальник цеха или смены"],
      },
      {
        code: "21101113",
        text: "В каком случае ширина проходов для обслуживания конвейеров может быть 0,7 м?",
        answers: [
          "Между параллельно установленными конвейерами, закрытыми по всей трассе жесткими коробами или сетчатыми ограждениями",
          "Между параллельно установленными конвейерами",
          "Для ленточных конвейеров",
          "Для цепных конвейеров",
        ],
        correctAnswers: [
          "Между параллельно установленными конвейерами, закрытыми по всей трассе жесткими коробами или сетчатыми ограждениями",
        ],
      },
      {
        code: "21101114",
        text: "Для каких сепараторов проход со стороны выпуска зерна должен быть шириной не менее 0,7 м?",
        answers: [
          "Для всех сепараторов",
          "Для сепараторов с боковой выемкой решет",
          "Для сепараторов с круговым вращением решет",
          "Для сепараторов производительностью до 50 т/ч",
        ],
        correctAnswers: ["Для всех сепараторов"],
      },
      {
        code: "21101115",
        text: "В каком случае не должна срабатывать автоматика безопасности горения, установленная на топках зерносушилок на жидком или газообразном топливе?",
        answers: [
          "При зажигании топлива с предварительным запуском вентилятора и продувкой топки для удаления застоявшихся паров топлива",
          "При выбросе горячего топлива в предтопочное пространство с предварительным запуском вентилятора и продувкой топки для удаления застоявшихся паров топлива",
          "При протекании топлива в топку при потухшем факеле с предварительным запуском вентилятора и продувкой топки для удаления застоявшихся паров топлива",
        ],
        correctAnswers: [
          "При зажигании топлива с предварительным запуском вентилятора и продувкой топки для удаления застоявшихся паров топлива",
        ],
      },
      {
        code: "21101116",
        text: "На площадках с каким уклоном допускается проведение погрузочно-разгрузочных работ регулярного перемещения передвижных транспортных механизмов (конвейеры, самоподаватели, электропогрузчики)?",
        answers: [
          "На площадках с уклоном не более 7°",
          "На площадках с уклоном не более 4°",
          "Все ответы неверны",
          "На площадках с уклоном не более 6°",
          "На площадках с уклоном не более 5°",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21101117",
        text: "Кем должны подтверждаться эффективность и надежность технических средств блокировки, контроля и противоаварийной защиты объекта хранения и переработки растительного сырья испытанием промышленных образцов оборудования на взрывозащищенность?",
        answers: [
          "Организацией-изготовителем",
          "Эксплуатирующей организацией",
          "Специализированной подрядной организацией",
        ],
        correctAnswers: ["Организацией-изготовителем"],
      },
      {
        code: "21101118",
        text: "Что определяется внутренними распорядительными документами организации, эксплуатирующей взрывопожароопасные производственные объекты хранения и переработки растительного сырья?",
        answers: [
          "Только организация работ по поддержанию надежного и безопасного уровня эксплуатации и ремонта оборудования, средств дистанционного автоматизированного управления, блокировки",
          "Только перечень и объем эксплуатационной, ремонтной и другой технической документации",
          "Только организация работ по поддержанию надежного и безопасного уровня контроля и противоаварийной защиты, производственной и аварийной сигнализации, оповещения об аварийных ситуациях, средств связи, энергообеспечения, а также зданий и сооружений",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21101119",
        text: "Размещение помещений какой категории по взрывопожароопасности допускается в подвальных и цокольных этажах, и при этом не требуется дополнительного обоснования в проектной документации?",
        answers: [
          "Категории А",
          "Категории В1",
          "Никакой из перечисленных категорий",
          "Категории Б",
        ],
        correctAnswers: ["Категории В1"],
      },
      {
        code: "21101120",
        text: "Какой должна быть минимальная площадь оконного стекла толщиной 5 мм, используемого в качестве легкосбрасываемой конструкции?",
        answers: ["1,5 м²", "1,0 м²", "0,8 м²", "1,2 м²"],
        correctAnswers: ["1,5 м²"],
      },
      {
        code: "21101121",
        text: "Размещение помещений какой категории по взрывопожароопасности допускается в подвальных и цокольных этажах, и при этом не требуется дополнительного обоснования в проектной документации при техническом перевооружении и реконструкции объектов?",
        answers: ["Категории А", "Категории Г", "Категории Б"],
        correctAnswers: ["Категории Г"],
      },
      {
        code: "21101122",
        text: "Какие из перечисленных сведений отражаются в технологическом регламенте, разрабатываемом на объекте хранения и переработки растительного сырья?",
        answers: [
          "Описание технологического процесса производства",
          "Сведения о возможных инцидентах в работе и способах их ликвидации",
          "Все перечисленные",
          "Нормы расхода основных видов сырья",
          "Характеристики производства, используемого сырья и выпускаемой (производимой) продукции",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "21101123",
        text: "При помощи чего должен производиться спуск работников в силосы и бункеры (для хранения зерна, муки, отрубей, комбикормов и других продуктов)?",
        answers: [
          "Устройств, предназначенных для проведения работ на высоте",
          "Веревочных лестниц",
          "Складных лестниц",
        ],
        correctAnswers: [
          "Устройств, предназначенных для проведения работ на высоте",
        ],
      },
      {
        code: "21101124",
        text: "Каким требованиям должны отвечать устройства, применяемые для спуска людей в силосы?",
        answers: [
          "Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при работе на высоте",
          "Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при выполнении огневых и газоопасных работ",
          "Требованиям, установленным нормативными правовыми актами, содержащими требования промышленной безопасности при проведении работ с инструментом и приспособлениями",
          "Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при работе в замкнутом пространстве",
        ],
        correctAnswers: [
          "Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при работе на высоте",
        ],
      },
      {
        code: "21101125",
        text: "С учетом ограничений каких документов эксплуатирующей организацией в технологическом регламенте определяются сроки, в которые проверяется температура сырья? Выберите 2 варианта ответа.",
        answers: [
          "Технических регламентов",
          "Документов по стандартизации",
          "Технического паспорта взрывобезопасности опасного производственного объекта",
          "Правил безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья",
        ],
        correctAnswers: [
          "Технических регламентов",
          "Документов по стандартизации",
        ],
      },
      {
        code: "21101126",
        text: "На основании какой документации осуществляются периодические перемещения (перекачивания) шрота, жмыха и другого мучнистого сырья, склонного к самовозгоранию, из занимаемых ими емкостей в свободные?",
        answers: [
          "Планов-графиков, разработанных на основании допустимых сроков непрерывного хранения сырья, установленных организацией в технологическом регламенте",
          "Все ответы неверны",
          "Планов-графиков, разработанных на основании Правил безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья",
          "Планов-графиков, разработанных организацией и утвержденных в территориальных органах Ростехнадзора",
        ],
        correctAnswers: [
          "Планов-графиков, разработанных на основании допустимых сроков непрерывного хранения сырья, установленных организацией в технологическом регламенте",
        ],
      },
      {
        code: "21101127",
        text: "Какие действия необходимо предпринять в случае превышения допустимой температуры заложенного на хранение растительного сырья, продуктов его переработки и комбикормового сырья, указанной для соответствующего вида сырья (продукта) в технологическом регламенте? Выберите все правильные варианты ответов.",
        answers: [
          "Производить перекачку сырья (продукта) из одного силоса (бункера) в другой или с площадки на площадку",
          "Применить активное вентилирование, сушку",
          "Периодически увлажнять сырье (продукт) до снижения температуры в допустимых пределах",
          "Обеспечить постоянный контакт сырья (продукта) с токопроводящей поверхностью стенки бункера",
        ],
        correctAnswers: [
          "Производить перекачку сырья (продукта) из одного силоса (бункера) в другой или с площадки на площадку",
          "Применить активное вентилирование, сушку",
        ],
      },
      {
        code: "21101128",
        text: "Какие из перечисленных действий допускаются при эксплуатации объектов хранения и переработки растительного сырья?",
        answers: [
          "Временное размещение сырого и влажного зерна в накопительных емкостях при условии разработки комплекса организационно-технических мер по обеспечению безопасности эксплуатации таких объектов",
          "Хранение сырого зерна на складах силосного типа",
          "Все перечисленные действия",
          "Хранение влажного зерна в силосах элеватора",
        ],
        correctAnswers: [
          "Временное размещение сырого и влажного зерна в накопительных емкостях при условии разработки комплекса организационно-технических мер по обеспечению безопасности эксплуатации таких объектов",
        ],
      },
      {
        code: "21101129",
        text: "Каким документом определяются порог чувствительности датчиков системы контроля утечек хлора, их количество и месторасположение?",
        answers: [
          "Техническим регламентом",
          "Проектом",
          "Правилами безопасности производства хлора",
          "Заключением метрологической лаборатории",
        ],
        correctAnswers: ["Проектом"],
      },
      {
        code: "21101130",
        text: "На каком из следующих устройств необходимо установить взрыворазрядители?",
        answers: [
          "На нориях со свободным объемом менее 0,25 м³",
          "На фильтр-циклонах со свободным объемом более 0,5 м³",
          "На дробилках с объемом рабочей зоны и выпускного (поддробильного) бункера менее 0,3 м³",
          "На нориях подачи и измельчения минерального сырья",
        ],
        correctAnswers: [
          "На фильтр-циклонах со свободным объемом более 0,5 м³",
        ],
      },
      {
        code: "21101131",
        text: "Что из перечисленного устанавливают на производственном оборудовании с целью защиты его от разрушения и обеспечения выброса (отвода) пламени и высокотемпературных продуктов взрывного горения пылевоздушной смеси в безопасную зону (за пределы помещений)?",
        answers: [
          "Взрыворазрядители",
          "Огнепреграждающие устройства",
          "Систему локализации взрывов",
          "Систему автоматического пожаротушения",
        ],
        correctAnswers: ["Взрыворазрядители"],
      },
      {
        code: "21101132",
        text: "При выполнении какого из перечисленных условий допускается выводить взрыворазрядные устройства в производственные помещения?",
        answers: [
          "При наличии тамбур-шлюзов на выходе из этих производственных помещений",
          "При отсутствии в этих помещениях обслуживающего персонала",
          "При нормативном оснащении производственных помещений легкосбрасываемыми конструкциями",
          "При условии установки на них исправных огнепреграждающих (пламеотсекающих) устройств, снижающих температуру выбрасываемых продуктов взрывного горения и подавляющих пламя, если выброс (отвод) продуктов взрывного горения осуществляется в зону производственного помещения, где не предусмотрено пребывание людей для осуществления технологического процесса, проведения работ по обслуживанию оборудования, и при наличии соответствующих положительных заключений экспертиз",
        ],
        correctAnswers: [
          "При условии установки на них исправных огнепреграждающих (пламеотсекающих) устройств, снижающих температуру выбрасываемых продуктов взрывного горения и подавляющих пламя, если выброс (отвод) продуктов взрывного горения осуществляется в зону производственного помещения, где не предусмотрено пребывание людей для осуществления технологического процесса, проведения работ по обслуживанию оборудования, и при наличии соответствующих положительных заключений экспертиз",
        ],
      },
      {
        code: "21101133",
        text: "Норийные трубы каких норий должны быть рассчитаны на внутреннее остаточное давление пылевоздушного взрыва?",
        answers: [
          "Норий транспортировки минерального сырья",
          "Норий, установленных в рабочих зданиях элеваторов",
          "Норий (кроме норий минерального сырья), проходящих внутри силосов, бункеров и шахт",
          "Норий высотой более 20 м вне зависимости от места установки",
        ],
        correctAnswers: [
          "Норий (кроме норий минерального сырья), проходящих внутри силосов, бункеров и шахт",
        ],
      },
      {
        code: "21101134",
        text: "Какие из перечисленных устройств могут использоваться в качестве огнепреграждающих (пламеотсекающих) устройств в составе системы локализации взрывов?",
        answers: [
          "Только шлюзовые затворы",
          "Только винтовые конвейеры",
          "Только порционные весы",
          "Все перечисленные, включая другие технические устройства, предотвращающие возможность распространения взрыва",
        ],
        correctAnswers: [
          "Все перечисленные, включая другие технические устройства, предотвращающие возможность распространения взрыва",
        ],
      },
      {
        code: "21101135",
        text: "На какие из перечисленных коммуникаций не устанавливаются огнепреграждающие (пламеотсекающие) устройства?",
        answers: [
          "На трубопроводы подачи зерна с головок норий и из дробилок, через которые непосредственно ведется загрузка бункеров (в том числе надвесовых) и силосов",
          "На трубопроводы подачи зерна с головок норий и из дробилок на цепные и закрытые ленточные конвейеры, через которые ведется загрузка бункеров и силосов",
          "На все коммуникации минерального сырья",
          "На трубопроводы подачи зерна с головок норий в надсушильные бункеры встроенных зерносушилок",
        ],
        correctAnswers: ["На все коммуникации минерального сырья"],
      },
      {
        code: "21101136",
        text: "Какие из перечисленных устройств должны входить в комплект серийно изготавливаемых норий, конвейеров?",
        answers: [
          "Только датчики подпора",
          "Только устройства контроля сбегания ленты",
          "Только устройства контроля обрыва цепи",
          "Все перечисленные устройства, включая реле контроля скорости",
        ],
        correctAnswers: [
          "Все перечисленные устройства, включая реле контроля скорости",
        ],
      },
      {
        code: "21101137",
        text: "Какие нории необходимо оснащать автоматически действующими тормозными устройствами?",
        answers: [
          "Нории производительностью менее 50 т/ч",
          "Нории, не оснащенные реле контроля скорости, датчиками подпора",
          "Нории, оснащенные реле контроля скорости, датчиками подпора",
          "Все виды норий",
          "Сдвоенные нории, имеющие возможность раздельного натяжения лент",
        ],
        correctAnswers: ["Все виды норий"],
      },
      {
        code: "21101138",
        text: "Где следует устанавливать датчики подпора на нориях?",
        answers: [
          "В головке нории",
          "На приемном патрубке",
          "На восходящей ветви на высоте 300 - 400 мм от башмака нории",
          "На нисходящей ветви на высоте 300 - 400 мм от башмака нории",
        ],
        correctAnswers: [
          "На восходящей ветви на высоте 300 - 400 мм от башмака нории",
        ],
      },
      {
        code: "21101139",
        text: "Какие конвейеры должны быть оснащены реле контроля скорости?",
        answers: [
          "Стационарные ленточные конвейеры со скоростью движения ленты 1 м/с и более",
          "Винтовые конвейеры длиной более 15 м",
          "Цепные конвейеры со скоростью движения цепи 1 м/с и более",
          "Цепные конвейеры, установленные на подсилосных этажах деревянных элеваторов",
          "Винтовые конвейеры, установленные в производственных помещениях комбикормового производства",
        ],
        correctAnswers: [
          "Стационарные ленточные конвейеры со скоростью движения ленты 1 м/с и более",
        ],
      },
      {
        code: "21101140",
        text: "На каких конвейерах должны быть предусмотрены устройства, предохраняющие конвейеры от переполнения короба продуктом?",
        answers: [
          "Только на цепных конвейерах",
          "Только на винтовых конвейерах",
          "На закрытых ленточных конвейерах",
          "На цепных и винтовых конвейерах",
        ],
        correctAnswers: ["На цепных и винтовых конвейерах"],
      },
      {
        code: "21101141",
        text: "Что из перечисленного необходимо предусматривать на цепных конвейерах?",
        answers: [
          "Установку взрыворазрядного устройства",
          "Установку независимых реле контроля скорости",
          "Установку устройства контроля обрыва цепи",
          "Установку устройства контроля схода цепи",
        ],
        correctAnswers: ["Установку устройства контроля обрыва цепи"],
      },
      {
        code: "21101142",
        text: "Что из перечисленного должно быть предусмотрено в конструкции сдвоенной нории?",
        answers: [
          "Усиление норийных труб, выполненное по методикам, согласованным с территориальным управлением Федеральной службы по экологическому, технологическому и атомному надзору",
          "Единое устройство натяжения обеих лент",
          "Система локализации взрывов, исключающая распространение высокотемпературных продуктов взрывного горения из одной трубы в другую",
          "Возможность раздельного натяжения каждой ленты",
          "Индивидуальный привод каждой ленты",
        ],
        correctAnswers: ["Возможность раздельного натяжения каждой ленты"],
      },
      {
        code: "21101143",
        text: "С какими из перечисленных устройств должны быть сблокированы электроприводы молотковых дробилок?",
        answers: [
          "С датчиками нижнего уровня наддробильных бункеров",
          "С датчиками верхнего уровня поддробильных бункеров",
          "С устройством контроля температуры подшипников",
          "С датчиками нижнего уровня поддробильных бункеров",
          "С датчиками верхнего уровня наддробильных бункеров",
        ],
        correctAnswers: ["С датчиками нижнего уровня наддробильных бункеров"],
      },
      {
        code: "21101144",
        text: "В каком из перечисленных случаев не допускается работа вальцовых станков?",
        answers: [
          "Без продукта с прижатыми вальцами с перекосом и смещением их вдоль оси",
          "При перепаде температур входного и измельченного продукта менее 12 °С",
          "При перепаде температур входного и измельченного продукта менее 10 °С",
          "При отсутствии устройства контроля температуры подшипников валков",
        ],
        correctAnswers: [
          "Без продукта с прижатыми вальцами с перекосом и смещением их вдоль оси",
        ],
      },
      {
        code: "21101145",
        text: "Какие мероприятия должны быть предусмотрены при эксплуатации действующих норий в соответствии с требованиями промышленной безопасности?",
        answers: [
          "Необходимо обеспечить натяжение и регулировку норийной ленты, исключающие возможность ее пробуксовки на барабане и задевание ленты и ковшей о норийные трубы, кожух головки и башмака",
          "Необходимо провести внеплановый инструктаж и проверку знаний обслуживающего персонала",
          "Необходимо обеспечить периодическую круглосуточную уборку пыли в производственных помещениях",
          "Необходимо предусмотреть дополнительную аспирацию башмака нории",
        ],
        correctAnswers: [
          "Необходимо обеспечить натяжение и регулировку норийной ленты, исключающие возможность ее пробуксовки на барабане и задевание ленты и ковшей о норийные трубы, кожух головки и башмака",
        ],
      },
      {
        code: "21101146",
        text: "В какой из перечисленных нештатных ситуаций оборудование должно быть остановлено?",
        answers: [
          "При появлении несвойственного шума и вибрации",
          "При завале, подпоре и перегрузке продуктом",
          "При поломках и неисправностях",
          "При попадании в рабочие органы посторонних предметов",
          "При срабатывании датчиков - индикаторов взрыва",
          "Во всех перечисленных ситуациях, а также при неработающей аспирации",
        ],
        correctAnswers: [
          "Во всех перечисленных ситуациях, а также при неработающей аспирации",
        ],
      },
      {
        code: "21101147",
        text: "В каком случае вентиляторы аспирационных систем помещений категории Б допускается устанавливать до пылеуловителей?",
        answers: [
          "При оснащении пылеуловителей взрыворазрядным устройством в соответствии с требованиями нормативных документов",
          "При свободном объеме пылеуловителя менее 0,5 м³",
          "При свободном объеме пылеуловителя менее 0,25 м³",
          'При условии обеспечения взрывозащиты электрического и неэлектрического оборудования вентиляторов в соответствии с требованиями Технического регламента Таможенного союза "О безопасности оборудования для работы во взрывоопасных средах"',
        ],
        correctAnswers: [
          'При условии обеспечения взрывозащиты электрического и неэлектрического оборудования вентиляторов в соответствии с требованиями Технического регламента Таможенного союза "О безопасности оборудования для работы во взрывоопасных средах"',
        ],
      },
      {
        code: "21101148",
        text: "Что из перечисленного не допускается объединять в одну аспирационную установку?",
        answers: [
          "Только обеспыливание потенциально опасного оборудования и бункеров",
          "Только обеспыливание потенциально опасного оборудования и другого оборудования бункерного типа (гравитационных смесителей, весов)",
          "Только обеспыливание потенциально опасного оборудования и силосов",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21101149",
        text: "Размещение каких из перечисленных устройств после пылеулавливающих установок не допускается?",
        answers: [
          "Аспирационных пылеосадочных шахт, камер, коробов",
          "Воздуходувных машин и вентиляторов",
          "Искробезопасных вентиляторов",
          "Взрывозащищенных вентиляторов",
          "Вихревых инерционных пылеуловителей на встречных закрученных потоках",
        ],
        correctAnswers: ["Аспирационных пылеосадочных шахт, камер, коробов"],
      },
      {
        code: "21101150",
        text: "Что из перечисленного не допускается в соединениях между элементами пневмотранспортных установок?",
        answers: [
          "Использование металлических шайб под болты из металла, окрашенных неэлектропроводными красками",
          "Использование металлических шайб под металлические болты, окрашенных электропроводными красками",
          "Использование шайб под болты из диэлектрических материалов, окрашенных неэлектропроводными красками",
          "Использование шайб под болты из диэлектрических материалов, окрашенных электропроводными красками",
        ],
        correctAnswers: [
          "Использование шайб под болты из диэлектрических материалов, окрашенных неэлектропроводными красками",
        ],
      },
      {
        code: "21101151",
        text: "В каком из перечисленных случаев не допускается отключение аспирационных установок?",
        answers: [
          "При работе технологического и транспортного оборудования",
          "При повышенной запыленности производственных помещений",
          "При проведении огневых работ",
          "При наличии в производственных помещениях обслуживающего персонала",
        ],
        correctAnswers: [
          "При работе технологического и транспортного оборудования",
        ],
      },
      {
        code: "21101152",
        text: "В каком из перечисленных случаев допускается объединение в одну установку аспирации оперативных емкостей и оборудования?",
        answers: [
          "При обеспыливании потенциально опасного оборудования (норий, дробилок, вальцовых станков и других машин ударного действия) и бункеров",
          "При обеспыливании потенциально опасного оборудования и другого оборудования бункерного типа (гравитационных смесителей, весов и т. п.)",
          "При обеспыливании потенциально опасного оборудования и бункеров, имеющих свободный объем менее 0,01 объема производственного помещения",
          "При обеспыливании потенциально опасного оборудования и силосов",
          "При обеспыливании оперативных емкостей с оборудованием, в котором отсутствуют вращающиеся детали",
        ],
        correctAnswers: [
          "При обеспыливании оперативных емкостей с оборудованием, в котором отсутствуют вращающиеся детали",
        ],
      },
      {
        code: "21101153",
        text: "Кто должен проверять аспирационную установку после замены оборудования?",
        answers: [
          "Представитель проектной организации",
          "Руководитель эксплуатирующей организации",
          "Инженер по аспирации или другое лицо, назначенное руководителем эксплуатирующей организации",
          "Представитель территориального органа Федеральной службы по экологическому, технологическому и атомному надзору",
        ],
        correctAnswers: [
          "Инженер по аспирации или другое лицо, назначенное руководителем эксплуатирующей организации",
        ],
      },
      {
        code: "21101154",
        text: "Для каких помещений системы приточной вентиляции совмещают с воздушным отоплением?",
        answers: [
          "Для взрывопожароопасных производственных помещений с трехсменным режимом работы",
          "Для взрывопожароопасных производственных помещений с режимом работы в одну смену",
          "Для любых производственных и вспомогательных помещений",
        ],
        correctAnswers: [
          "Для взрывопожароопасных производственных помещений с трехсменным режимом работы",
        ],
      },
      {
        code: "21101155",
        text: "В каком из перечисленных случаев допускается размещение приточных установок?",
        answers: [
          "В обслуживаемых помещениях категорий А и Б при условии их взрывозащищенного исполнения",
          "В необслуживаемых помещениях категорий А и Б",
          "В обслуживаемых помещениях категории А",
          "В обслуживаемых помещениях категории Б",
          "В необслуживаемых помещениях категории В",
        ],
        correctAnswers: [
          "В обслуживаемых помещениях категорий А и Б при условии их взрывозащищенного исполнения",
        ],
      },
      {
        code: "21101156",
        text: "Какие светильники допускается использовать внутри металлических и железобетонных емкостей при выключенных разгрузочных механизмах и оборудовании?",
        answers: [
          "Переносные светильники при напряжении в сети 42 В с металлической защитной сеткой стеклянного колпака",
          "Переносные светильники при напряжении в сети не более 24 В",
          "Переносные светильники повышенной надежности против взрыва при напряжении в сети не выше 12 В и металлической защитой стеклянного колпака",
          "Переносные светильники повышенной надежности против взрыва при напряжении в сети не более 127 В",
          "Переносные светильники повышенной надежности против взрыва со степенью защиты оболочки не ниже IP44 и металлической защитой стеклянного колпака",
        ],
        correctAnswers: [
          "Переносные светильники повышенной надежности против взрыва при напряжении в сети не выше 12 В и металлической защитой стеклянного колпака",
        ],
      },
      {
        code: "21101157",
        text: "Какие светильники допускается использовать внутри деревянных емкостей при выключенных разгрузочных механизмах и оборудовании?",
        answers: [
          "Переносные светильники при напряжении в сети 42 В повышенной надежности против взрыва, а их стеклянные колпаки должны быть защищены металлической сеткой",
          "Переносные светильники при напряжении в сети не более 24 В повышенной надежности против взрыва со степенью защиты оболочки не ниже IР44",
          "Переносные светильники при напряжении в сети не более 12 В",
          "Переносные светильники повышенной надежности против взрыва со степенью защиты оболочки не ниже IP44 и металлической защитой стеклянного колпака",
        ],
        correctAnswers: [
          "Переносные светильники при напряжении в сети 42 В повышенной надежности против взрыва, а их стеклянные колпаки должны быть защищены металлической сеткой",
        ],
      },
      {
        code: "21101158",
        text: "На какой высоте располагают светильники в помещениях?",
        answers: [
          "На высоте не более 2,0 м",
          "На высоте 1,7 м",
          "На высоте 2,0 м",
          "На высоте не менее 2,5 м",
        ],
        correctAnswers: ["На высоте не менее 2,5 м"],
      },
      {
        code: "21101159",
        text: "Где не допускается прокладка трубопроводов с пожаро- и взрывоопасными веществами (смесями)?",
        answers: [
          "Только через распределительные устройства",
          "Только через трансформаторные подстанции",
          "Только через комплектные трансформаторные подстанции",
          "Через распределительные устройства, трансформаторные подстанции и преобразовательные подстанции",
        ],
        correctAnswers: [
          "Через распределительные устройства, трансформаторные подстанции и преобразовательные подстанции",
        ],
      },
      {
        code: "21101160",
        text: "При каком условии допускается устройство порогов в дверных проемах распределительных устройств, трансформаторных подстанций и комплектных трансформаторных подстанций, диспетчерских и тамбурах при них?",
        answers: [
          "Устройство порогов в дверях распределительных устройств, трансформаторных подстанций и комплектных трансформаторных подстанций, диспетчерских и тамбурах при них не допускается",
          "При условии наличия несгораемого козырька с вылетом 70 см",
          "При условии обязательного согласования с территориальным управлением Федеральной службы по экологическому, технологическому и атомному надзору",
          "При условии перекрытия съемными деревянными щитами, подшитыми с наружной стороны листовым железом",
        ],
        correctAnswers: [
          "Устройство порогов в дверях распределительных устройств, трансформаторных подстанций и комплектных трансформаторных подстанций, диспетчерских и тамбурах при них не допускается",
        ],
      },
      {
        code: "21101161",
        text: "Какие требования предъявляются к электрическим рубильникам?",
        answers: [
          "Электрические рубильники должны иметь закрытые кожухи",
          "Электрические рубильники должны иметь несгораемый козырек",
          "Электрические рубильники должны иметь степень защиты оболочки шкафа не ниже IP65",
          "Электрические рубильники должны быть заземлены не менее чем в двух местах",
          "Электрические рубильники должны быть окрашены неэлектропроводными красками",
        ],
        correctAnswers: [
          "Электрические рубильники должны иметь закрытые кожухи",
        ],
      },
      {
        code: "21101162",
        text: "Для каких напряжений штепсельные соединения, предназначенные для подключения электроинструмента и переносных светильников, должны иметь зануляющий контакт?",
        answers: [
          "Только для напряжения 42 В",
          "Только для напряжения 127 В",
          "Только для напряжения 220 В",
          "Для напряжений 127 В и 220 В",
          "Для напряжений 42 В, 127 В и 220 В",
        ],
        correctAnswers: ["Для напряжений 127 В и 220 В"],
      },
      {
        code: "21101163",
        text: "От каких штепсельных соединений должны отличаться штепсельные соединения (розетки, вилки) по своему конструктивному исполнению?",
        answers: [
          "Штепсельные соединения, применяемые для напряжения 42 В, от штепсельных соединений, предназначенных для напряжения 12 В",
          "Штепсельные соединения, применяемые на напряжение 12 В, от штепсельных соединений, предназначенных для напряжения 42 В",
          "Штепсельные соединения, применяемые на напряжение 42 В, от штепсельных соединений, предназначенных для напряжения 127 В и 220 В",
          "Штепсельные соединения, применяемые на напряжения 12 В, от штепсельных соединений, предназначенных для напряжения 36 В",
        ],
        correctAnswers: [
          "Штепсельные соединения, применяемые на напряжение 42 В, от штепсельных соединений, предназначенных для напряжения 127 В и 220 В",
        ],
      },
      {
        code: "21101164",
        text: "Где допускается покрывать полы линолеумом или паркетом?",
        answers: [
          "В галереях",
          "В тоннелях",
          "На лестничных клетках многоэтажных производственных зданий",
          "В помещениях диспетчерских щитов управления",
          "В помещениях рабочего здания элеватора",
        ],
        correctAnswers: ["В помещениях диспетчерских щитов управления"],
      },
      {
        code: "21101165",
        text: "Какие кабели применяют для подключения к сети передвижных и переносных электроприемников?",
        answers: [
          "Гибкие шланговые кабели",
          "Гибкие шланговые кабели специального назначения",
          "Гибкие шланговые кабели с медными или алюминиевыми жилами и с полиэтиленовой изоляцией",
          "Гибкие шланговые кабели только с алюминиевыми жилами, полиэтиленовой изоляцией и резиновой оболочкой",
          "Силовые монтажные кабели с медной жилой и полиэтиленовой оболочкой",
        ],
        correctAnswers: ["Гибкие шланговые кабели"],
      },
      {
        code: "21101166",
        text: "Электроустановки с каким напряжением питания запрещается использовать внутри силосов и бункеров, других емкостей и сушилок?",
        answers: [
          "С напряжением питания более 42 В",
          "Внутри силосов и бункеров, других емкостей и сушилок запрещается использование любых электроустановок",
          "С напряжением питания 127 В",
        ],
        correctAnswers: ["С напряжением питания более 42 В"],
      },
      {
        code: "21101167",
        text: "Каким способом следует заземлять электросварочные установки?",
        answers: [
          "Электросварочные установки следует надежно заземлять гибкими медными проводами, снабженными зажимами, обеспечивающими надежный контакт",
          "Электросварочные установки следует надежно заземлять гибкими алюминиевыми проводами сечением не менее 25 мм",
          "Электросварочные установки следует надежно заземлять           жесткими медными проводниками сечением не менее 35 мм",
          "Электросварочные установки следует надежно заземлять жесткими алюминиевыми проводниками с клеммными соединениями",
        ],
        correctAnswers: [
          "Электросварочные установки следует надежно заземлять гибкими медными проводами, снабженными зажимами, обеспечивающими надежный контакт",
        ],
      },
      {
        code: "21101168",
        text: "Что допускается делать при эксплуатации электроустановок?",
        answers: [
          "Включать электроустановки без обеспечения их защиты от механических повреждений",
          "Отключать электроустановки от сети при исчезновении напряжения",
          "Подключать к трансформаторам, питающим искробезопасные приборы, другие цепи и приборы, не входящие в комплект искробезопасных приборов",
          "Пускать в работу электроустановки при неисправностях блокировки крышек аппаратов и блокировки пуска машин",
        ],
        correctAnswers: [
          "Отключать электроустановки от сети при исчезновении напряжения",
        ],
      },
      {
        code: "21101169",
        text: "На кого возлагается ответственность за техническое состояние, эксплуатацию и своевременный ремонт взрыворазрядителей?",
        answers: [
          "На технического руководителя организации",
          "На главного технолога организации",
          "На инженера по охране труда организации",
          "На соответствующее должностное лицо, назначенное руководителем эксплуатирующей организации",
        ],
        correctAnswers: [
          "На соответствующее должностное лицо, назначенное руководителем эксплуатирующей организации",
        ],
      },
      {
        code: "21101170",
        text: "Какой уровень влажности не должна превышать влажность пшеницы, ржи, ячменя, риса-зерна, гречихи при их хранении до года?",
        answers: ["17%", "14,5%", "15%", "16%", "16,5%"],
        correctAnswers: ["14,5%"],
      },
      {
        code: "21101171",
        text: "Какой уровень влажности не должна превышать влажность кукурузы в зерне, проса, сорго, овса при их хранении до года?",
        answers: ["13,5%", "14,5%", "15%", "14%", "15,5%"],
        correctAnswers: ["13,5%"],
      },
      {
        code: "21101172",
        text: "Какой уровень влажности не должна превышать влажность пшеницы, ржи, ячменя, овса, гречихи при их длительном хранении (более года)?",
        answers: ["16%", "14,5%", "13,5%", "13%", "15%"],
        correctAnswers: ["13%"],
      },
      {
        code: "21101173",
        text: "Какой уровень влажности не должна превышать влажность кукурузы и проса при их длительном хранении (более года)?",
        answers: ["14,5%", "13%", "12%", "12,5%", "15%"],
        correctAnswers: ["12%"],
      },
      {
        code: "21101174",
        text: "Какие требования предъявляются к совместному складированию различных продуктов в одном и том же силосе (бункере)?",
        answers: [
          "Запрещается совместное складирование различных продуктов",
          "Разрешается совместное складирование различных продуктов",
          "Разрешается совместное кратковременное хранение различных продуктов",
          "Разрешаются совместное складирование и кратковременное хранение различных продуктов при условии оснащения силоса (бункера) устройством дистанционного контроля температуры",
        ],
        correctAnswers: [
          "Запрещается совместное складирование различных продуктов",
        ],
      },
      {
        code: "21101175",
        text: "Какие требования предъявляются к хранению рисовой, просяной, ячменной, гречневой лузги?",
        answers: [
          "Рисовая, просяная, ячменная, гречневая лузга должна храниться в бункерах вместимостью на 1 - 2 суток работы завода",
          "Рисовая, просяная, ячменная, гречневая лузга должна храниться в бункерах вместимостью на 1 смену работы завода",
          "Рисовая, просяная, ячменная, гречневая лузга должна храниться в бункерах и силосах в объеме, не превышающем 3 смены работы завода",
          "Рисовая, просяная, ячменная, гречневая лузга должна храниться на складах бестарного напольного хранения",
        ],
        correctAnswers: [
          "Рисовая, просяная, ячменная, гречневая лузга должна храниться в бункерах вместимостью на 1 - 2 суток работы завода",
        ],
      },
      {
        code: "21101176",
        text: "Какие мероприятия рекомендуется предусматривать перед закладкой на хранение просушенного зерна?",
        answers: [
          "Перемешивать (перекачивать из занимаемых им емкостей в свободные)",
          "Предусматривать минимальное число его перемещений",
          "Пропускать через воздушно-ситовые машины независимо от степени его засоренности",
          "Транспортировать на открытых ленточных конвейерах",
          "Обеспыливать с целью недопущения выхода пыли в помещение",
        ],
        correctAnswers: [
          "Пропускать через воздушно-ситовые машины независимо от степени его засоренности",
        ],
      },
      {
        code: "21101177",
        text: "Какие мероприятия необходимо предусматривать в случае обнаружения в мучнистом или гранулированном сырье повышения температуры, связанного с признаками самосогревания?",
        answers: [
          "Производить ежесуточную проверку температуры в данной партии с извлечением этой партии сырья из емкостей в первую очередь",
          "Производить перемещение сырья из занимаемых им емкостей в свободные независимо от температуры очага самосогревания",
          "Производить перемещение сырья в тот же силос",
          "Производить ежесуточную проверку влажности в данной партии",
          "Производить выборочную проверку температуры и влажности в данной партии",
        ],
        correctAnswers: [
          "Производить ежесуточную проверку температуры в данной партии с извлечением этой партии сырья из емкостей в первую очередь",
        ],
      },
      {
        code: "21101178",
        text: "Какие требования предъявляются к совместному складированию в одном и том же силосе (бункере) различных продуктов?",
        answers: [
          "Совместное складирование в одном и том же силосе (бункере) различных продуктов разрешается при условии, что уровень влажности в них не превышает 18%",
          "Совместное складирование в одном и том же силосе (бункере) различных продуктов разрешается, если силосы и бункеры оснащены устройствами дистанционного контроля температуры продукта",
          "Совместное складирование в одном и том же силосе (бункере) различных продуктов разрешается, если перед засыпкой продуктом емкости были тщательно зачищены, проветрены и просушены",
          "Совместное складирование в одном и том же силосе (бункере) различных продуктов разрешается, если они доведены до параметров, обеспечивающих возможность их хранения",
          "Совместное складирование в одном и том же силосе (бункере) различных продуктов запрещается",
        ],
        correctAnswers: [
          "Совместное складирование в одном и том же силосе (бункере) различных продуктов запрещается",
        ],
      },
      {
        code: "21101179",
        text: "В каких целях используют наружные силосы сборных корпусов элеваторов?",
        answers: [
          "В целях длительного хранения обработанного зерна",
          "В целях размещения свежеубранных партий после их обработки",
          "В целях размещения свежеубранных партий до их обработки",
          "В целях размещения партий, не предназначенных для первоочередной реализации",
          "В целях длительного хранения необработанного зерна",
        ],
        correctAnswers: [
          "В целях размещения свежеубранных партий до их обработки",
        ],
      },
      {
        code: "21101180",
        text: "Какое мероприятие из перечисленных не исключает причины, приводящие к самосогреванию растительного сырья?",
        answers: [
          "Контроль температуры продукта в силосах (бункерах, складах)",
          "Контроль влажности продукта в силосах (бункерах, складах)",
          "Недопущение вентилирования и перемещения продукта из силоса в силос",
          "Контроль газовой среды в свободных объемах силосов (бункеров) переносными или стационарными газоанализаторами",
          "Вентилирование и перемещение продукта из силоса в силос (в случаях обоснованной необходимости)",
          "Контроль зараженности зерна вредителями",
        ],
        correctAnswers: [
          "Недопущение вентилирования и перемещения продукта из силоса в силос",
        ],
      },
      {
        code: "21101181",
        text: "Где применяют устройства дистанционного контроля температуры зерна?",
        answers: [
          "Только в силосах элеваторов",
          "Только в силосах складов силосного типа",
          "Только в металлических силосах",
          "Только в складах напольного типа",
          "В силосах, бункерах и складах, используемых в качестве накопительных емкостей при приемке и формировании партий свежеубранного зерна",
        ],
        correctAnswers: [
          "В силосах, бункерах и складах, используемых в качестве накопительных емкостей при приемке и формировании партий свежеубранного зерна",
        ],
      },
      {
        code: "21101182",
        text: "В какие сроки проверяют температуру сырья?",
        answers: [
          "В сроки, установленные для сырья, предназначенного для длительного хранения",
          "В сроки, установленные для свежеубранного сырья",
          "В сроки, установленные для каждого вида сырья в зависимости от состояния влажности",
          "1 раз в 3 дня при температуре сырья 10 °С и ниже",
          "1 раз в 7 дней при температуре сырья выше 10 °С",
        ],
        correctAnswers: [
          "В сроки, установленные для каждого вида сырья в зависимости от состояния влажности",
        ],
      },
      {
        code: "21101183",
        text: "С какой периодичностью проводят контроль температуры сырья в сухом состоянии в металлических силосах при температуре выше 10 °С?",
        answers: [
          "1 раз в день",
          "1 раз в 2 дня",
          "1 раз в 3 дня",
          "1 раз в 7 дней",
          "1 раз в 5 дней",
        ],
        correctAnswers: ["1 раз в 3 дня"],
      },
      {
        code: "21101184",
        text: "При какой температуре очага самосогревания зерна ситуация считается аварийной и производственные процессы останавливаются?",
        answers: [
          "При температуре более 45 °С",
          "При температуре более 55 °С",
          "При температуре более 80 °С",
          "При температуре более 95 °С",
          "При температуре более 100 °С",
        ],
        correctAnswers: ["При температуре более 100 °С"],
      },
      {
        code: "21101185",
        text: "Чем должны быть оборудованы силосы, бункеры и склады, используемые в качестве накопительных емкостей при приемке и формировании партий свежеубранного зерна?",
        answers: [
          "Термоподвесками",
          "Переносными измерительными приборами, подключаемыми к термоподвескам",
          "Стационарными устройствами контроля температуры с ЭВМ",
          "Цифровыми устройствами контроля температуры с радиоканалом",
          "Средствами дистанционного контроля температуры",
        ],
        correctAnswers: ["Средствами дистанционного контроля температуры"],
      },
      {
        code: "21101186",
        text: "Каким образом определяется очаг самовозгорания в силосах и бункерах?",
        answers: [
          "Только на основе измерения температуры в массе продукта и обработки измерительной информации",
          "Только при визуальном наблюдении по выходу дыма и пара через неплотности в конструкции силоса, по изменению цвета ограждающих конструкций, образованию в них трещин, обгоранию краски",
          "Только по едкому, резкому и неприятному запаху продуктов, свойственному запаху продуктов сухой перегонки растительного сырья",
          "Всеми перечисленными способами",
        ],
        correctAnswers: ["Всеми перечисленными способами"],
      },
      {
        code: "21101187",
        text: "Как осуществляется флегматизация горючей газовоздушной смеси в свободных объемах силоса?",
        answers: [
          "Путем его заполнения инертными газами и снижения содержания кислорода до оптимального значения, равного 15% объема",
          "Воздушно-механической пеной, подаваемой в силос снизу",
          "Путем его заполнения инертными газами и снижения содержания кислорода до оптимального значения, равного 8% объема и менее, а также воздушно-механической пеной, подаваемой в силос сверху через загрузочный люк",
          "Путем распыления с помощью стволов с насадками воды через загрузочные люки",
          "Путем герметизации технологических люков силосов",
        ],
        correctAnswers: [
          "Путем его заполнения инертными газами и снижения содержания кислорода до оптимального значения, равного 8% объема и менее, а также воздушно-механической пеной, подаваемой в силос сверху через загрузочный люк",
        ],
      },
      {
        code: "21101188",
        text: "Какие мероприятия необходимо осуществить перед началом выгрузки горящего продукта из силоса?",
        answers: [
          "Весь свободный объем надсводного пространства только аварийного силоса заполняется воздушно-механической пеной",
          "Весь свободный объем надсводного пространства аварийного и смежного с ним силосов заполняется воздушно-механической пеной, подаваемой сверху через загрузочные люки",
          "Весь свободный объем надсводного пространства аварийного силоса заполняется свежей партией продукта",
          "Весь свободный объем надсводного пространства аварийного и смежного с ним силосов заполняется свежей партией продукта",
          "В аварийном и смежном с ним силосе обеспечивают непрерывное распыление воды с помощью стволов с насадками через загрузочные люки",
        ],
        correctAnswers: [
          "Весь свободный объем надсводного пространства аварийного и смежного с ним силосов заполняется воздушно-механической пеной, подаваемой сверху через загрузочные люки",
        ],
      },
      {
        code: "21101189",
        text: "Что из перечисленного включает в себя подготовка помещений и рабочего места к проведению огневых работ?",
        answers: [
          "Покрытие ветошью пола и сгораемых конструкций в радиусе не менее 15 м от места проведения работ",
          "Покрытие металлическими листами пола и сгораемых конструкций по всему помещению, в котором будут проводиться огневые работы",
          "Покрытие мокрыми мешками пола и сгораемых конструкций в радиусе не менее 10 м от места проведения работ",
          "Покрытие фанерой пола и сгораемых конструкций в радиусе не менее 20 м от места проведения работ",
        ],
        correctAnswers: [
          "Покрытие мокрыми мешками пола и сгораемых конструкций в радиусе не менее 10 м от места проведения работ",
        ],
      },
      {
        code: "21101190",
        text: "В каком случае допускается проводить огневые работы?",
        answers: [
          "После открытия всех смотровых и базовых проемов и люков в помещениях, где проводятся огневые работы",
          "Только после проветривания и остановки всего оборудования с вывешиванием предупредительных надписей и плакатов в помещении, где проводятся огневые работы",
          "Только после отключения пусковой аппаратуры, машин и механизмов с вывешиванием предупредительных надписей и плакатов в помещении, в котором будут проводиться огневые работы",
          "После остановки всего оборудования объекта, отключения и обесточивания пусковой аппаратуры, машин и механизмов с вывешиванием предупредительных надписей и плакатов, предупреждающих возможность их пуска",
        ],
        correctAnswers: [
          "После остановки всего оборудования объекта, отключения и обесточивания пусковой аппаратуры, машин и механизмов с вывешиванием предупредительных надписей и плакатов, предупреждающих возможность их пуска",
        ],
      },
      {
        code: "21101191",
        text: "Допускается ли эксплуатация неисправных и утративших целостность взрыворазрядителей и их конструктивных элементов?",
        answers: [
          "Не допускается",
          "Допускается в светлое время суток",
          "Допускается только при соблюдении дополнительных требований",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "21101192",
        text: "Что необходимо предпринять при отклонениях от нормальной работы оборудования (завал продукта, интенсивное пыление, повышенные вибрации и другие подобные причины)?",
        answers: [
          "Продолжить работу, сообщив ответственному специалисту об отклонениях",
          "Остановить оборудование и незамедлительно покинуть помещение",
          "Остановить оборудование и незамедлительно проверить взрыворазрядители, при необходимости заменить разрывные предохранительные мембраны или другие поврежденные элементы конструкций",
          "Оповестить представителя территориального органа Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий об отклонениях и продолжить работу",
        ],
        correctAnswers: [
          "Остановить оборудование и незамедлительно проверить взрыворазрядители, при необходимости заменить разрывные предохранительные мембраны или другие поврежденные элементы конструкций",
        ],
      },
      {
        code: "21101193",
        text: "Какой должна быть температура нагрева корпусов подшипников во время работы взрывопожароопасного оборудования?",
        answers: [
          "Не больше 60 °C",
          "Не больше 45 °C",
          "Не больше 50 °C",
          "Не больше 70 °C",
        ],
        correctAnswers: ["Не больше 60 °C"],
      },
      {
        code: "21101194",
        text: "С какой периодичностью пыль и другие относы должны выводиться из пылеуловителей?",
        answers: [
          "1 раз в смену",
          "Непрерывно",
          "1 раз в сутки",
          "Каждые 2 часа",
        ],
        correctAnswers: ["Непрерывно"],
      },
      {
        code: "21101195",
        text: "Как следует защищать матерчатые фильтры, на которых осаждается мука, от накопления зарядов статического электричества?",
        answers: [
          "Прошивать медной проволокой и соединять ее с системой заземления",
          "Только заземлять",
          "Выполнять окантовку фильтра из электропроводной резины",
          "Прошивать алюминиевой проволокой",
        ],
        correctAnswers: [
          "Прошивать медной проволокой и соединять ее с системой заземления",
        ],
      },
      {
        code: "21101196",
        text: "С какой периодичностью следует осуществлять контроль целостности мембран, герметизирующих прокладок, подвижности откидных клапанов и поворотных створок комбинированных взрыворазрядителей, отсутствия накоплений на мембранах и в отводящих трубопроводах пыли или продукта?",
        answers: [
          "1 раз в сутки",
          "1 раз в месяц",
          "1 раз в смену",
          "1 раз в квартал",
        ],
        correctAnswers: ["1 раз в месяц"],
      },
      {
        code: "21101197",
        text: "Каким образом следует распределять взрыворазрядители на камерах нагрева при установке нескольких взрыворазрядителей?",
        answers: [
          "Равномерно по ширине камеры",
          "Равномерно по длине камеры",
          "В соответствии с указаниями лица, ответственного за безопасное производство работ",
          "Равномерно по высоте камеры",
        ],
        correctAnswers: ["Равномерно по высоте камеры"],
      },
      {
        code: "21101198",
        text: "Какая устанавливается максимальная длина каждого отводящего трубопровода от защищаемого оборудования до коллектора?",
        answers: ["3 м", "5 м", "7 м", "10 м"],
        correctAnswers: ["3 м"],
      },
      {
        code: "21101199",
        text: "На основании письменного решения какого лица осуществляется ввод в эксплуатацию аварийного участка производства (объекта) после проведения восстановительных работ?",
        answers: [
          "Руководителя аварийно-спасательных формирований",
          "Руководителя эксплуатирующей организации",
          "Инспектора территориального органа Ростехнадзора",
          "Технического руководителя эксплуатирующей организации",
        ],
        correctAnswers: ["Руководителя эксплуатирующей организации"],
      },
      {
        code: "21101200",
        text: "Чем тушат горящий продукт в подсилосном этаже в целях предотвращения возможного образования пылевого облака при выходе продукта из силоса?",
        answers: [
          "Направленной струей воды",
          "Распыленной водой",
          "Углекислой пеной",
          "Азотом",
        ],
        correctAnswers: ["Распыленной водой"],
      },
      {
        code: "21101201",
        text: "По указанию какого лица с момента поступления информации об аварийной ситуации производятся выключение или включение электроэнергии, обеспечиваются бесперебойное действие связи, исправное состояние водопровода, бесперебойная работа необходимого электромеханического оборудования и подвижных транспортных средств?",
        answers: [
          "Все ответы неверны",
          "Технического руководителя организации",
          "Главного энергетика",
          "Главного механика",
          "Главного технолога",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21101202",
        text: "Какое из перечисленных действий допускается при проведении огневых работ на объектах хранения и переработки растительного сырья? Выберите 2 варианта ответа.",
        answers: [
          "Прокладка электрических проводов на расстоянии 1,0 м от горячих трубопроводов и баллонов с кислородом",
          "Проведение уборки помещений",
          "Прокладка электрических проводов на расстоянии менее 1,0 м от баллонов с горючими газами",
          "Плавное опускание на пол (землю) оборудования, сооружений и их частей, демонтируемых посредством электро- или газорезательных работ",
          "Использование в качестве обратного провода сети заземления или зануления металлических конструкций зданий, коммуникаций и технологического оборудования",
        ],
        correctAnswers: [
          "Прокладка электрических проводов на расстоянии 1,0 м от горячих трубопроводов и баллонов с кислородом",
          "Плавное опускание на пол (землю) оборудования, сооружений и их частей, демонтируемых посредством электро- или газорезательных работ",
        ],
      },
      {
        code: "21101203",
        text: "Какие нагрузки не учитываются при расчете силосов?",
        answers: [
          "Нагрузки, возникающие при изготовлении, перевозке и монтаже сборных конструкций",
          "Нагрузки, возникающие при изменении температур наружного воздуха",
          "Нагрузки, возникающие от давления при взрыве",
          "Нагрузки, возникающие от столкновений транспортных средств с частями сооружения",
        ],
        correctAnswers: [
          "Нагрузки, возникающие от столкновений транспортных средств с частями сооружения",
        ],
      },
      {
        code: "21101204",
        text: "В каком положении нарушены требования Свода правил СП 43.13330.2012 при расчете конструкций силосов и их элементов?",
        answers: [
          "Следует предусматривать мероприятия по взрывозащите и защите от статического электричества при проектировании силосов для сыпучих материалов, пыль которых способна образовать взрывоопасные концентрации",
          "Расчет оснований сблокированных и отдельно стоящих силосов, возводимых на нескальных грунтах, должен производиться по предельным состояниям второй группы (по деформациям) с учетом ветровой нагрузки",
          "По периметру наружных стен силосных корпусов высотой до верха карниза более 10 м следует предусматривать на кровле решетчатые ограждения высотой не менее 0,6 м из негорючих материалов",
          "При определении давления на грунт под подошвой фундамента силосов следует учитывать только нагрузку, возникающую при полной загрузке силосов сыпучими материалами",
        ],
        correctAnswers: [
          "При определении давления на грунт под подошвой фундамента силосов следует учитывать только нагрузку, возникающую при полной загрузке силосов сыпучими материалами",
        ],
      },
      {
        code: "21101205",
        text: "В каком случае допускается размещать предприятия, здания и сооружения по хранению и переработке зерна в санитарно-защитной зоне предприятий, относимых по выделению производственных вредностей в окружающую среду к I и II классу?",
        answers: [
          "Только при соответствующем обосновании в проектной документации",
          "Только при согласовании с территориальным органом Федеральной службы по экологическому, технологическому и атомному надзору",
          "Не допускается ни в каком случае",
          "Допускается в любом случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "21101206",
        text: "Какое требование по оснащению производственных помещений лифтами указано неверно?",
        answers: [
          "В производственных зданиях предусматривается пассажирский лифт для постоянно работающих людей на этажах, расположенных выше 15 м от уровня входа в здание",
          "Тамбур-шлюзы могут устраиваться общими для двух помещений (при условии, что в помещении категории Б имеется второй эвакуационный выход)",
          "Грузовой лифт в производственных зданиях следует предусматривать при наличии требований технологии производства",
          "В производственных зданиях высотой более 15 м один из лифтов должен быть рассчитан на перевозку пожарных подразделений и отвечать требованиям регламента",
        ],
        correctAnswers: [
          "В производственных зданиях высотой более 15 м один из лифтов должен быть рассчитан на перевозку пожарных подразделений и отвечать требованиям регламента",
        ],
      },
      {
        code: "21101207",
        text: "Что допускается размещать при проектировании многорядных силосных корпусов с круглыми в плане силосами в пространстве между ними (звездочках)?",
        answers: [
          "Лестницы",
          "Установки технологического оборудования, требующего обслуживания",
          "Установки для хранения связных сыпучих материалов",
          "Все ответы неверны",
        ],
        correctAnswers: ["Лестницы"],
      },
      {
        code: "21101208",
        text: "Какие требования к проектированию складских зданий зерноскладов указаны неверно?",
        answers: [
          "Площадь зданий зерноскладов между противопожарными стенами следует принимать не более 5000 м2",
          "Противокапиллярную гидроизоляцию несущих стен зданий зерноскладов следует предусматривать из цементного раствора состава 1 : 2 толщиной 20 мм",
          "Вынос кровли (за наружную поверхность стен) для зерноскладов должен быть не менее 0,7 м",
          "Полы в складских зданиях следует проектировать, как правило, асфальтобетонными с толщиной покрытия 25 мм в зерноскладах и 50 мм - в складах тарных грузов",
          "Проекты зерноскладов должны содержать указания о нанесении на стены ярких линий и надписей, ограничивающих предельную высоту зерновой насыпи",
          "В покрытиях полов не допускается применение дегтей и дегтевых мастик",
        ],
        correctAnswers: [
          "Площадь зданий зерноскладов между противопожарными стенами следует принимать не более 5000 м2",
        ],
      },
      {
        code: "21101209",
        text: "Какой фундамент следует предусматривать, если расчетные деформации естественного основания силосов и силосных корпусов превышают предельные или не обеспечивается его устойчивость, а также при наличии просадочных грунтов и в других случаях при соответствующем технико-экономическом обосновании?",
        answers: [
          "Свайный фундамент",
          "Ленточный фундамент",
          "Монолитный фундамент",
          "Сборный фундамент",
          "Кольцевой фундамент",
        ],
        correctAnswers: ["Свайный фундамент"],
      },
      {
        code: "21101210",
        text: "Каким образом следует назначать длину разгрузочной железнодорожной эстакады?",
        answers: [
          "В соответствии с технологическими расчетами и с учетом местных условий строительства эстакады",
          "В зависимости от материала конструкций и блоков эстакады",
          "Равной 1,8, 3, 6, 9 м",
          "В соответствии с требованиями внутренних инструкций",
        ],
        correctAnswers: [
          "В соответствии с технологическими расчетами и с учетом местных условий строительства эстакады",
        ],
      },
      {
        code: "21101211",
        text: "Для каких водонапорных башен высота опор от уровня земли до верха опоры бака устанавливается кратной 3 м?",
        answers: [
          "Для башен с баками вместимостью 100 м³",
          "Для башен с баками вместимостью от 15 до 50 м³",
          "Для башен с баками вместимостью до 10 м³",
          "Для башен с баками вместимостью до 5 м³",
        ],
        correctAnswers: ["Для башен с баками вместимостью от 15 до 50 м³"],
      },
      {
        code: "21101212",
        text: "Какой материал допускается использовать для опор (колонн) водонапорных башен?",
        answers: [
          "Монолитный железобетон",
          "Кирпич",
          "Сталь",
          "Любой из перечисленных",
        ],
        correctAnswers: ["Любой из перечисленных"],
      },
      {
        code: "21101213",
        text: "Какую наименьшую высоту компактной струи на уровне наивысшей точки следует принимать для тушения пожара рабочего здания элеватора высотой свыше 50 м от гидрантов с помощью насосов при расчетном расходе воды 5 л/с?",
        answers: ["10 м", "15 м", "5 м", "20 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "21101214",
        text: "Какое расстояние между температурно-усадочными швами следует принимать в монолитных и сборно-монолитных железобетонных конструкциях стен?",
        answers: [
          "Не более 25 м",
          "Не более 10 м",
          "Не более 20 м",
          "Не более 30 м",
        ],
        correctAnswers: ["Не более 25 м"],
      },
      {
        code: "21101215",
        text: "Какие из перечисленных расстояний при устройстве подпорных стен соответствуют СП 43.13330.2012. Свод правил. Сооружения промышленных предприятий?",
        answers: [
          "В местах, где возможно движение пешеходов, подпорные стены должны иметь ограждение высотой 1 м",
          "Высота подпорных стен для грузовых рамп автомобильного транспорта со стороны подъезда автомобилей должна быть равной 1,5 м от уровня поверхности проезжей части дорог или погрузочно-разгрузочной площадки",
          "Минимальное расстояние от оси ближайшего железнодорожного пути до внутренней грани подпорной стены на прямых участках следует принимать не менее 1,5 м",
          "При расположении автодорог вдоль подпорной стены у нее следует предусматривать тротуар шириной не менее 1 м с бортовым камнем высотой не менее 0,6 м",
        ],
        correctAnswers: [
          "В местах, где возможно движение пешеходов, подпорные стены должны иметь ограждение высотой 1 м",
        ],
      },
      {
        code: "21101216",
        text: "Какие тоннели и каналы должны быть заглублены от поверхности до верха перекрытия не менее чем на 1 м?",
        answers: [
          "Тоннели и каналы, располагаемые под железными дорогами",
          "Тоннели и каналы, располагаемые под автомобильными дорогами",
          "Тоннели и каналы, располагаемые вне зданий и дорог",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Тоннели и каналы, располагаемые под железными дорогами",
        ],
      },
      {
        code: "21101217",
        text: "Покрытия каких силосов допускается проектировать в виде оболочек? Укажите все правильные ответы.",
        answers: [
          "Покрытия отдельно стоящих круглых силосов при отсутствии надсилосного помещения",
          "Покрытия силосов диаметром более 12 м",
          "Все ответы неверны",
          "Группы силосов, объединенных в силосный корпус",
          "Отдельно стоящих круглых силосов, надсилосные помещения которых спроектированы с применением облегченных стеновых ограждений",
        ],
        correctAnswers: [
          "Покрытия отдельно стоящих круглых силосов при отсутствии надсилосного помещения",
          "Покрытия силосов диаметром более 12 м",
        ],
      },
      {
        code: "21101218",
        text: "Какой следует принимать коэффициент надежности по нагрузке для давления сыпучих материалов на стены и днища силосов, бункеров и зерноскладов при расчете на прочность?",
        answers: ["1,3", "1,1", "1,2", "1,5"],
        correctAnswers: ["1,3"],
      },
      {
        code: "21101219",
        text: "В каких из перечисленных конструкциях расстояние между температурно-усадочными швами следует принимать не более 10 м?",
        answers: [
          "В монолитных бутобетонных и бетонных подпорных стенах без конструктивного армирования",
          "В монолитных бетонных конструкциях при наличии конструктивного армирования",
          "В монолитных и сборно-монолитных железобетонных конструкциях стен",
          "В сборных железобетонных конструкциях",
        ],
        correctAnswers: [
          "В монолитных бутобетонных и бетонных подпорных стенах без конструктивного армирования",
        ],
      },
      {
        code: "21101220",
        text: "Кратными какому значению рекомендуется принимать высоту и ширину тоннелей, каналов (между выступающими частями несущих конструкций)?",
        answers: [
          "Кратными 100 мм",
          "Кратными 300 мм",
          "Кратными 200 мм",
          "Кратными 150 мм",
        ],
        correctAnswers: ["Кратными 300 мм"],
      },
      {
        code: "21101221",
        text: "В каком случае допускается выполнять бункер для хранения и перегрузки сыпучего материала без перекрытия, но с обязательным устройством сплошного ограждения?",
        answers: [
          "Если загрузка производится средствами из непрерывного транспорта (вагоны, машины, грейферы)",
          "Если загрузка производится периодически",
          "Все ответы неверны",
          "В случае загрузки связных сыпучих материалов",
        ],
        correctAnswers: [
          "Если загрузка производится средствами из непрерывного транспорта (вагоны, машины, грейферы)",
        ],
      },
      {
        code: "21101222",
        text: "Какое допускается максимальное содержание арматуры железобетонных колонн подсилосного этажа?",
        answers: ["5%", "3%", "7%", "9%"],
        correctAnswers: ["3%"],
      },
      {
        code: "21101223",
        text: "При каком способе закрепления строительных конструкций и технологического оборудования на фундаментах не требуется соответствующее обоснование?",
        answers: [
          "На анкерных болтах",
          "На виброгасителях или на клею",
          "Сваркой",
          "Все ответы неверны",
        ],
        correctAnswers: ["На анкерных болтах"],
      },
      {
        code: "21101224",
        text: "При какой минимальной нагрузке на пол не следует размещать подвалы производственного назначения в зданиях и сооружениях?",
        answers: [
          "100 кПа (10 тс/м²)",
          "80 кПа (8 тс/м²)",
          "150 кПа (15 тс/м²)",
          "50 кПа (5 тс/м²)",
        ],
        correctAnswers: ["100 кПа (10 тс/м²)"],
      },
      {
        code: "21101225",
        text: "Какие требования предъявляются к выходам из конвейерных, коммуникационных (кроме кабельных) тоннелей?",
        answers: [
          "Выходы должны предусматриваться не реже чем через 50 м",
          "Выходы должны предусматриваться не реже чем через 100 м, но не менее 2",
          "Выходы должны предусматриваться не реже чем через 150 м, но не менее 3",
          "Выходы должны предусматриваться не реже чем через 200 м, но не менее 3",
        ],
        correctAnswers: [
          "Выходы должны предусматриваться не реже чем через 100 м, но не менее 2",
        ],
      },
      {
        code: "21101226",
        text: "Какое из перечисленных требований к устройству закромов для хранения сыпучих материалов указано неверно?",
        answers: [
          "Полы закромов надлежит выполнять из камня грубого окола или грунтовыми",
          "Стены с внутренней стороны и сверху должны быть защищены деревянными брусьями",
          "Стены закромов должны быть рассчитаны также на горизонтальное давление грунта с учетом временной нормативной нагрузки на поверхности земли интенсивностью не менее 20 кПа (2 тс/м²) при опорожненном закроме",
          "Все ответы неверны",
          "При загрузке и выгрузке материалов грейферными кранами следует предусматривать буферный слой из хранимого материала внутри закромов толщиной не менее 0,3 м",
        ],
        correctAnswers: [
          "Стены с внутренней стороны и сверху должны быть защищены деревянными брусьями",
        ],
      },
      {
        code: "21101227",
        text: "Какие из перечисленных материалов относятся к связным сыпучим материалам?",
        answers: [
          "Все ответы неверны",
          "Песок с крупностью зерен до 2 мм и влажностью до 2%",
          "Галька",
          "Щебень",
          "Материалы с крупностью зерен 3 мм",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21101228",
        text: "На проектирование каких сооружений распространяется СП 43.13330.2012. Свод правил. Сооружения промышленных предприятий? Укажите все правильные ответы.",
        answers: [
          "На проектирование сооружений промышленных предприятий, предназначенных для строительства в особых условиях",
          "Все ответы неверны",
          "На проектирование сооружений специального назначения",
          "На проектирование емкостных сооружений для водоснабжения и канализации",
          "На проектирование емкостных сооружений для жидкостей и газов",
          "На проектирование сооружений со сроком эксплуатации до 5 лет",
        ],
        correctAnswers: [
          "На проектирование сооружений промышленных предприятий, предназначенных для строительства в особых условиях",
          "На проектирование емкостных сооружений для жидкостей и газов",
        ],
      },
      {
        code: "21101229",
        text: "В каком случае допускается использовать пространство под баками водонапорных башен для размещения служебных и конторских помещений, складов, производственных помещений?",
        answers: [
          "В случае применения сплошных конструкций опор (монолитный железобетон или кирпич), исключающих образование пыли, дыма и газовыделений",
          "В случае вместимости бака от 15 до 50 м³",
          "В случае соответствующего технико-экономического обоснования",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "В случае применения сплошных конструкций опор (монолитный железобетон или кирпич), исключающих образование пыли, дыма и газовыделений",
        ],
      },
      {
        code: "21101230",
        text: "Какие характеристики определяются по расчетным схемам, учитывающим наличие наружных стен и днища опускных колодцев, при строительстве опускных колодцев? Укажите все правильные ответы.",
        answers: [
          "Сдвиг по подошве при односторонней выемке грунта вблизи колодца (если она предусматривается проектом)",
          "Всплытие колодца",
          "Погружение колодца",
          "Устойчивость формы цилиндрической оболочки колодцев, погружаемых в тиксотропной рубашке",
        ],
        correctAnswers: [
          "Сдвиг по подошве при односторонней выемке грунта вблизи колодца (если она предусматривается проектом)",
          "Всплытие колодца",
        ],
      },
      {
        code: "21101231",
        text: "Какие проверки необходимо производить при расчете подпорных стен по предельным состояниям второй группы (по пригодности к эксплуатации)? Укажите все правильные ответы.",
        answers: [
          "Основания на допустимые деформации",
          "Железобетонных элементов на допустимые величины раскрытия трещин",
          "Устойчивости положения стены против сдвига",
          "Прочности скального основания",
        ],
        correctAnswers: [
          "Основания на допустимые деформации",
          "Железобетонных элементов на допустимые величины раскрытия трещин",
        ],
      },
      {
        code: "21101232",
        text: "Из какого материала следует выполнять подвалы производственного назначения при их устройстве в сложных гидрогеологических условиях строительной площадки, при больших нагрузках на пол цеха или при наличии разнообразных проемов в стенах и перекрытиях, а также при особых технологических требованиях?",
        answers: [
          "Из бетонных блоков",
          "Из монолитного железобетона",
          "Из кирпича",
          "Из несущих железобетонных панелей",
        ],
        correctAnswers: ["Из монолитного железобетона"],
      },
      {
        code: "21101233",
        text: "Какие бункеры для хранения и перегрузки сыпучего материала допускается проектировать стальными?",
        answers: [
          "Все перечисленные",
          "Параболические (висячие бункеры)",
          "Бункеры, которые по технологическим условиям подвергаются механическим, химическим и температурным воздействиям сыпучего материала",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "21101234",
        text: "Какой должен быть минимальный зооветеринарный разрыв между предприятиями и ветеринарно-санитарными утилизационными заводами для городских поселений и других муниципальных образований?",
        answers: ["1000 м", "150 м", "600 м", "300 м"],
        correctAnswers: ["1000 м"],
      },
      {
        code: "21101235",
        text: "На какой коэффициент умножается расчетная нагрузка от веса сыпучих материалов при расчете на сжатие нижней зоны стен силосов?",
        answers: ["0,9", "0,3", "0,5", "0,7"],
        correctAnswers: ["0,9"],
      },
      {
        code: "21101236",
        text: 'Что в соответствии с СП 108.13330.2012 определяется как "саморазгружающееся емкостное сооружение с высотой вертикальной части, не превышающей полуторную величину диаметра или меньшего размера"?',
        answers: ["Бункер", "Силос", "Башня", "Элеватор"],
        correctAnswers: ["Силос"],
      },
      {
        code: "21101237",
        text: "В каком порядке следует производить расчет опускного колодца?",
        answers: [
          "Не регламентируется",
          "Определять глубину колодца, наружные размеры (диаметр) колодца, толщину стенок оболочки, рассчитывать отдельные конструктивные элементы оболочки",
          "Рассчитывать отдельные конструктивные элементы оболочки, определять глубину колодца, наружные размеры (диаметр) колодца, толщину стенок оболочки",
          "Рассчитывать отдельные конструктивные элементы оболочки, глубину колодца, определять наружные размеры (диаметр) колодца",
        ],
        correctAnswers: [
          "Определять глубину колодца, наружные размеры (диаметр) колодца, толщину стенок оболочки, рассчитывать отдельные конструктивные элементы оболочки",
        ],
      },
      {
        code: "21101238",
        text: "Какое количество буровых скважин закладывают при опускных колодцах диаметром более 10 - 15 м?",
        answers: [
          "Не менее 2 буровых скважин",
          "Не менее 3 буровых скважин",
          "Не менее 4 буровых скважин",
          "1 буровую скважину",
        ],
        correctAnswers: ["Не менее 3 буровых скважин"],
      },
      {
        code: "21101239",
        text: "Какие требования надлежит принимать при проектировании отдельно стоящих силосов и силосных корпусов?",
        answers: [
          "Все перечисленные",
          "Наружные диаметры круглых отдельно стоящих силосов, равные 6, 9, 12, 18 и 24 м",
          "Сетки разбивочных осей, проходящих через центры сблокированных в силосные корпуса силосов, размером 3 x 3, 6 x 6, 9 x 9 и 12 x 12 м",
          "Высоту стен силосов, подсилосных и надсилосных этажей, кратную 0,6 м",
          "В силосных корпусах для хранения сырья и готовой продукции мукомольно-крупяных предприятий и комбикормовых заводов с двумя подсилосными этажами и более допускается принимать каркас по типу производственных зданий с сеткой колонн 6 x 3 м",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "21101240",
        text: "С каким покрытием следует проектировать автомобильные дороги на площадках мукомольно-крупяных и комбикормовых предприятий по санитарным условиям?",
        answers: [
          "Гравийным",
          "Асфальтобетонным или бетонным",
          "Щебеночным",
          "Шлаковым",
        ],
        correctAnswers: ["Асфальтобетонным или бетонным"],
      },
      {
        code: "21101241",
        text: "В какой таре допускается транспортирование горючих веществ?",
        answers: [
          "В металлических бочках",
          "В бутылях",
          "В бидонах",
          "В ведрах",
        ],
        correctAnswers: ["В металлических бочках"],
      },
      {
        code: "21101242",
        text: "Каким образом следует осуществлять досмотр порожних вагонов-зерновозов?",
        answers: [
          "Единолично или бригадой в составе не более 2 человек",
          "Посредством их освещения через загрузочные люки аккумуляторными фонарями",
          "Обязательно спустившись внутрь вагона",
          "Бригадой в составе 3 человек",
        ],
        correctAnswers: [
          "Посредством их освещения через загрузочные люки аккумуляторными фонарями",
        ],
      },
      {
        code: "21101243",
        text: "Использование каких перечисленных инструментов допускается при производстве погрузочно-разгрузочных работ с зерном и другими сыпучими продуктами на железнодорожном транспорте?",
        answers: [
          "Использование вибраторов для удаления зависшего в вагоне продукта",
          "Допускается использование всех перечисленных инструментов",
          "Использование рычагов для вращения штурвалов",
          "Использование удлинителей для фиксации крышки загрузочного люка",
        ],
        correctAnswers: [
          "Использование вибраторов для удаления зависшего в вагоне продукта",
        ],
      },
      {
        code: "21101244",
        text: "При каком минимальном повышении содержания бензина в шроте следует немедленно поставить в известность руководство эксплуатирующей организации, открыть двери и люки вагонов для его проветривания?",
        answers: ["0,05%", "0,1%", "0,3%", "0,2%"],
        correctAnswers: ["0,1%"],
      },
    ],
    21102: [
      {
        code: "21102000",
        text: "Что из перечисленного устанавливают на производственном оборудовании с целью его защиты от разрушения и обеспечения выброса (отвода) пламени и высокотемпературных продуктов взрывного горения пылевоздушной смеси в безопасную зону (за пределы помещений)?",
        answers: [
          "Взрыворазрядители",
          "Систему локализации взрывов",
          "Систему автоматического пожаротушения",
          "Систему подавления взрывов",
          "Газоанализаторы",
        ],
        correctAnswers: ["Взрыворазрядители"],
      },
      {
        code: "21102001",
        text: "Норийные трубы каких норий должны быть рассчитаны на внутреннее остаточное давление пылевоздушного взрыва?",
        answers: [
          "Норий транспортировки минерального сырья",
          "Норий, установленных в рабочих зданиях элеваторов",
          "Норий (кроме норий минерального сырья), проходящих внутри силосов, бункеров и шахт",
          "Норий высотой более 20 м вне зависимости от места установки",
          "Норий с трубами круглого сечения и толщиной стенки более 2 мм",
        ],
        correctAnswers: [
          "Норий (кроме норий минерального сырья), проходящих внутри силосов, бункеров и шахт",
        ],
      },
      {
        code: "21102002",
        text: "Какие из перечисленных устройств могут использоваться в качестве огнепреграждающих в составе системы локализации взрывов?",
        answers: [
          "Порционные весы",
          "Цепной конвейер",
          "Закрытый ленточный конвейер",
          "Поворотные и распределительные устройства",
          "Пресс-грануляторы",
        ],
        correctAnswers: ["Порционные весы"],
      },
      {
        code: "21102003",
        text: "Где не следует устанавливать огнепреграждающие устройства?",
        answers: [
          "На трубопроводах подачи зерна с головок норий и из дробилок, через которые непосредственно ведется загрузка бункеров (в том числе надвесовых) и силосов",
          "На трубопроводах подачи зерна с головок норий и из дробилок на цепные и закрытые ленточные конвейеры, через которые ведется загрузка бункеров и силосов",
          "На трубопроводах подачи зерна с головок норий в надсушильные бункеры встроенных зерносушилок",
          "На трубопроводах подачи минерального сырья",
          "На трубопроводах, соединяющих (в том числе и через цепные и закрытые ленточные конвейеры) бункеры, силосы или нории элеватора с отдельно стоящими зерносушилками",
        ],
        correctAnswers: ["На трубопроводах подачи минерального сырья"],
      },
      {
        code: "21102004",
        text: "Какие из перечисленных устройств не должны входить в комплект серийно изготавливаемых норий, конвейеров?",
        answers: [
          "Реле контроля скорости",
          "Датчики подпора",
          "Световая и звуковая сигнализации",
          "Устройства контроля сбегания ленты",
          "Устройства контроля обрыва цепи",
          "Все перечисленные устройства",
        ],
        correctAnswers: ["Световая и звуковая сигнализации"],
      },
      {
        code: "21102005",
        text: "Какие нории оснащаются автоматически действующими тормозными устройствами?",
        answers: [
          "Нории производительностью более 50 т/ч",
          "Нории, проходящие внутри силосов или бункеров",
          "Нории со скоростью движения ленты менее 1 м/с",
          "Все нории должны быть оснащены тормозными устройствами",
          "Нории с производительностью менее 50 т/ч и скоростью движения ленты более 1 м/с",
        ],
        correctAnswers: [
          "Все нории должны быть оснащены тормозными устройствами",
        ],
      },
      {
        code: "21102006",
        text: "Что из перечисленного должно быть предусмотрено в конструкции сдвоенной нории?",
        answers: [
          "Усиление норийных труб, выполненное по методикам, согласованным с территориальным органом Федеральной службы по экологическому, технологическому и атомному надзору",
          "Единое устройство натяжения обеих лент",
          "Система локализации взрывов, исключающая распространение высокотемпературных продуктов взрывного горения из одной трубы в другую",
          "Раздельное натяжение каждой ленты",
          "Индивидуальный привод каждой ленты",
        ],
        correctAnswers: ["Раздельное натяжение каждой ленты"],
      },
      {
        code: "21102007",
        text: "Какие конвейеры должны быть оснащены реле контроля скорости?",
        answers: [
          "Стационарные ленточные конвейеры со скоростью движения ленты 1 м/с и более",
          "Винтовые конвейеры длиной более 15 м",
          "Цепные конвейеры со скоростью движения цепи 1 м/с и более",
          "Цепные конвейеры, установленные на подсилосных этажах деревянных элеваторов",
        ],
        correctAnswers: [
          "Стационарные ленточные конвейеры со скоростью движения ленты 1 м/с и более",
        ],
      },
      {
        code: "21102008",
        text: "На каких конвейерах должны быть установлены устройства, предохраняющие конвейеры от переполнения короба продуктом?",
        answers: [
          "Только на винтовых конвейерах",
          "На закрытых ленточных конвейерах",
          "Только на цепных конвейерах",
          "На цепных и винтовых конвейерах",
          "На цепных, винтовых и ленточных конвейерах",
        ],
        correctAnswers: ["На цепных и винтовых конвейерах"],
      },
      {
        code: "21102009",
        text: "На каком расстоянии от корпуса защищаемого оборудования необходимо устанавливать предохранительную мембрану или клапан?",
        answers: [
          "На минимально возможном расстоянии",
          "На максимально возможном расстоянии",
          "На расстоянии не более 1 м",
          "На расстоянии не менее 2 м",
          "На расстоянии 5 м",
        ],
        correctAnswers: ["На минимально возможном расстоянии"],
      },
      {
        code: "21102010",
        text: "При каком свободном объеме на нориях допускается не устанавливать взрыворазрядители?",
        answers: ["Менее 0,25 м3", "0,3 м3", "0,5 м3", "1,0 м3"],
        correctAnswers: ["Менее 0,25 м3"],
      },
      {
        code: "21102011",
        text: "Что из перечисленного не соответствует Правилам безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья?",
        answers: [
          "На нориях с высотой норийных труб не более 36 м взрыворазрядители следует устанавливать на головке нории или на обеих норийных трубах в любом месте по высоте нории, удобном для монтажа и обслуживания взрыворазрядителей",
          "На нориях с высотой норийных труб более 36 м взрыворазрядители следует устанавливать на головке нории (или на норийных трубах на расстоянии не более 1/3 высоты норийных труб от головки нории) и на обеих норийных трубах на расстоянии не более 1/3 высоты норийных труб от башмака нории в любом месте, удобном для монтажа и обслуживания взрыворазрядителей",
          "Установка взрыворазрядителя на крышке головки нории не должна приводить к обратной сыпи и попаданию продукта на предохранительную мембрану",
          "Взрыворазрядители на норийных трубах устанавливаются на фланцах входных патрубков таким образом, чтобы норийная лента не препятствовала выбросу продуктов взрывного горения и несгоревшей смеси",
          "На головке сдвоенной нории не допускается устанавливать общий для обеих частей нории взрыворазрядитель",
          "При установке взрыворазрядителей на норийных трубах объединение двух норийных труб одной нории допускается в месте расположения общего взрыворазрядителя",
        ],
        correctAnswers: [
          "На головке сдвоенной нории не допускается устанавливать общий для обеих частей нории взрыворазрядитель",
        ],
      },
      {
        code: "21102012",
        text: "Чего не должны допускать форма и расположение переходных патрубков для присоединения взрыворазрядителей при нормальном режиме работы оборудования?",
        answers: [
          "Только накопления в них пыли",
          "Только накопления в них продукта",
          "Только механического повреждения предохранительной мембраны из-за попадания на нее продукта",
          "Всего перечисленного",
        ],
        correctAnswers: ["Всего перечисленного"],
      },
      {
        code: "21102013",
        text: "Что не учитывается при определении диаметров (площадей) проходных сечений взрыворазрядителей для оборудования?",
        answers: [
          "Величина защищаемого объема",
          "Допустимое давление взрыва, статическое давление вскрытия мембраны (клапана)",
          "Тип взрыворазрядителя",
          "Длина, количество и величина углов поворота отводящих трубопроводов",
          "Температура и влажность в месте установки оборудования",
        ],
        correctAnswers: [
          "Температура и влажность в месте установки оборудования",
        ],
      },
      {
        code: "21102014",
        text: "Что необходимо использовать в качестве отводящих трубопроводов взрыворазрядителей?",
        answers: [
          "Стальные сварные трубы круглого сечения с толщиной стенок не менее 1 мм",
          "Гибкие резиновые шланги",
          "Только медные металлополимерные трубы",
          "Только керамические трубы",
        ],
        correctAnswers: [
          "Стальные сварные трубы круглого сечения с толщиной стенок не менее 1 мм",
        ],
      },
      {
        code: "21102015",
        text: "Каким образом следует располагать торец отводящего трубопровода при применении (установке) взрыворазрядителей на первых этажах?",
        answers: [
          "Не ниже 2,5 м от планировочной отметки",
          "Не выше 2,0 м от планировочной отметки",
          "Не ниже 1,5 м от планировочной отметки",
          "Не выше 0,5 м от планировочной отметки",
        ],
        correctAnswers: ["Не ниже 2,5 м от планировочной отметки"],
      },
      {
        code: "21102016",
        text: "Где устанавливаются взрыворазрядители для взрывозащиты зерносушилок?",
        answers: [
          "Только на каскадных нагревателях",
          "Только на камерах нагрева",
          "Только на надсушильных бункерах: на верхней крышке или на боковой стенке в верхней части бункера",
          "На камерах нагрева, подогревателях, каскадных нагревателях, осадочных камерах, топках, надсушильных бункерах и на нориях, обслуживающих зерносушилки",
          "Не регламентируется",
        ],
        correctAnswers: [
          "На камерах нагрева, подогревателях, каскадных нагревателях, осадочных камерах, топках, надсушильных бункерах и на нориях, обслуживающих зерносушилки",
        ],
      },
      {
        code: "21102017",
        text: "Где должны устанавливаться взрыворазрядители на надсушильных бункерах для взрывозащиты зерносушилок?",
        answers: [
          "На верхней крышке или на боковой стенке в верхней части бункера",
          "Только на верхней крышке бункера",
          "На боковой стенке в нижней части бункера",
          "На боковой стенке со стороны, противоположной входу агента сушки",
        ],
        correctAnswers: [
          "На верхней крышке или на боковой стенке в верхней части бункера",
        ],
      },
      {
        code: "21102018",
        text: "Где должны устанавливаться взрыворазрядители на подогревателях для взрывозащиты зерносушилок?",
        answers: [
          "На боковой стенке секций подогревателя со стороны входа агента сушки",
          "На боковой стенке секций подогревателя со стороны, противоположной входу агента сушки",
          "На боковой стенке секций только в нижней части подогревателя",
          "На минимальном расстоянии от входа агента сушки",
        ],
        correctAnswers: [
          "На боковой стенке секций подогревателя со стороны, противоположной входу агента сушки",
        ],
      },
      {
        code: "21102019",
        text: "На кого возлагается ответственность за техническое состояние, эксплуатацию и своевременный ремонт взрыворазрядителей?",
        answers: [
          "На должностное лицо, назначенное руководителем эксплуатирующей организации",
          "На технического руководителя эксплуатирующей организации",
          "На должностное лицо организации-изготовителя",
          "На представителя территориального управления Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "На должностное лицо специализированной подрядной организации",
        ],
        correctAnswers: [
          "На должностное лицо, назначенное руководителем эксплуатирующей организации",
        ],
      },
      {
        code: "21102020",
        text: "Допускается ли эксплуатация неисправных и утративших целостность взрыворазрядителей и их конструктивных элементов?",
        answers: [
          "Допускается только в светлое время суток",
          "Не допускается",
          "Допускается только в темное время суток",
          "Допускается при согласовании с территориальным органом Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "21102021",
        text: "В каком случае разрывные предохранительные мембраны из полиэтиленовой пленки или алюминиевой фольги подлежат обязательной замене с отражением действий в журнале периодических осмотров и ремонтов взрыворазрядителей?",
        answers: [
          "По истечении 1 года эксплуатации",
          "По истечении 3 лет эксплуатации",
          "По истечении 5 лет эксплуатации",
          "По истечении 7 лет эксплуатации",
        ],
        correctAnswers: ["По истечении 1 года эксплуатации"],
      },
      {
        code: "21102022",
        text: "Что необходимо предпринять при отклонениях от нормальной работы оборудования (завал продукта, интенсивное пыление, повышенные вибрации и другие подобные причины)?",
        answers: [
          "Остановить оборудование и незамедлительно проверить взрыворазрядители, при необходимости заменить разрывные предохранительные мембраны или другие поврежденные элементы конструкций",
          "Остановить оборудование и незамедлительно покинуть помещение",
          "Продолжить работу, сообщив ответственному специалисту об отклонениях",
          "Оповестить представителя территориального органа Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий об отклонениях и продолжить работу",
        ],
        correctAnswers: [
          "Остановить оборудование и незамедлительно проверить взрыворазрядители, при необходимости заменить разрывные предохранительные мембраны или другие поврежденные элементы конструкций",
        ],
      },
      {
        code: "21102023",
        text: "Кто должен обеспечивать целостность и исправность взрыворазрядителей при остановке объекта (краткосрочной, среднесрочной или длительной) в соответствии с требованиями Правил безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья?",
        answers: [
          "Организация-изготовитель",
          "Эксплуатирующая организация",
          "Сотрудники Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "Проектная организация",
        ],
        correctAnswers: ["Эксплуатирующая организация"],
      },
      {
        code: "21102024",
        text: "Что устанавливают перед предохранительной мембраной взрыворазрядителя при систематических нарушениях целостности мембран вследствие разрежения внутри оборудования или значительных пульсаций давления?",
        answers: [
          "Защитную сетку из проволоки толщиной 1 - 2 мм с ячейками размером 30 x 30 мм",
          "Защитную сетку из капрона толщиной 1 мм с ячейками размером 20 x 20 мм",
          "Защитную сетку из базальтового волокна толщиной 1 мм с ячейками размером 50 x 50 мм",
        ],
        correctAnswers: [
          "Защитную сетку из проволоки толщиной 1 - 2 мм с ячейками размером 30 x 30 мм",
        ],
      },
      {
        code: "21102025",
        text: "С какой периодичностью следует осуществлять контроль целостности мембран, герметизирующих прокладок, подвижности откидных клапанов и поворотных створок комбинированных взрыворазрядителей, отсутствие накоплений на мембранах и в отводящих трубопроводах пыли или продукта?",
        answers: [
          "1 раз в год",
          "1 раз в квартал",
          "2 раза в год",
          "1 раз в месяц",
        ],
        correctAnswers: ["1 раз в месяц"],
      },
      {
        code: "21102026",
        text: "Какой документ должен оформляться на каждый установленный взрыворазрядитель?",
        answers: [
          "Паспорт",
          "Формуляр",
          "Операционная карта",
          "Технологическая инструкция",
          "Заключение",
          "Акт",
        ],
        correctAnswers: ["Паспорт"],
      },
      {
        code: "21102027",
        text: "Какое требование предъявляется к взрыворазрядителям после их установки?",
        answers: [
          "Все взрыворазрядители пломбируются, а шиберные и бандажные - нумеруются",
          "Все взрыворазрядители пломбируются, а шиберные - нумеруются",
          "Все взрыворазрядители нумеруются, а шиберные и бандажные - пломбируются",
        ],
        correctAnswers: [
          "Все взрыворазрядители нумеруются, а шиберные и бандажные - пломбируются",
        ],
      },
      {
        code: "21102028",
        text: "При помощи чего должен производиться спуск работников в силосы и бункеры (для хранения зерна, муки, отрубей, комбикормов и других продуктов)?",
        answers: [
          "Устройств, предназначенных для проведения работ на высоте",
          "Устройств, утвержденных для этих целей руководителем эксплуатирующей организации",
          "Веревочных складных лестниц",
        ],
        correctAnswers: [
          "Устройств, предназначенных для проведения работ на высоте",
        ],
      },
      {
        code: "21102029",
        text: "При выполнении какого из перечисленных условий допускается выводить взрыворазрядные устройства в производственные помещения?",
        answers: [
          "При наличии тамбур-шлюзов на выходе из этих производственных помещений",
          "При нормативном оснащении производственных помещений легкосбрасываемыми конструкциями",
          "При отсутствии в этих помещениях обслуживающего персонала",
          "При условии установки на них исправных огнепреграждающих (пламеотсекающих) устройств, снижающих температуру выбрасываемых продуктов взрывного горения и подавляющих пламя, если выброс (отвод) продуктов взрывного горения осуществляется в зону производственного помещения, где не предусмотрено пребывание людей для осуществления технологического процесса, проведения работ по обслуживанию оборудования, и при наличии соответствующих положительных заключений экспертиз",
        ],
        correctAnswers: [
          "При условии установки на них исправных огнепреграждающих (пламеотсекающих) устройств, снижающих температуру выбрасываемых продуктов взрывного горения и подавляющих пламя, если выброс (отвод) продуктов взрывного горения осуществляется в зону производственного помещения, где не предусмотрено пребывание людей для осуществления технологического процесса, проведения работ по обслуживанию оборудования, и при наличии соответствующих положительных заключений экспертиз",
        ],
      },
      {
        code: "21102030",
        text: "На основании какой документации осуществляются периодические перемещения (перекачивания) шрота, жмыха и другого мучнистого сырья, склонного к самовозгоранию, из занимаемых ими емкостей в свободные?",
        answers: [
          "Планов-графиков, разработанных на основании Правил безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья",
          "Планов-графиков, разработанных организацией и утвержденных в территориальных органах Ростехнадзора",
          "Планов-графиков, разработанных на основании допустимых сроков непрерывного хранения сырья, установленных организацией в технологическом регламенте",
        ],
        correctAnswers: [
          "Планов-графиков, разработанных на основании допустимых сроков непрерывного хранения сырья, установленных организацией в технологическом регламенте",
        ],
      },
      {
        code: "21102031",
        text: "На каком расстоянии от стен и другого оборудования должны устанавливаться мешковыбивальные машины?",
        answers: [
          "Не менее 0,5 м",
          "Не менее 0,9 м",
          "Не менее 0,8 м",
          "Не менее 0,7 м",
        ],
        correctAnswers: ["Не менее 0,9 м"],
      },
      {
        code: "21102032",
        text: "Каким образом следует распределять взрыворазрядители на камерах нагрева при установке нескольких взрыворазрядителей?",
        answers: [
          "Равномерно по длине камеры",
          "Равномерно по ширине камеры",
          "Равномерно по высоте камеры",
          "В соответствии с указаниями лица, ответственного за безопасное производство работ",
        ],
        correctAnswers: ["Равномерно по высоте камеры"],
      },
      {
        code: "21102033",
        text: "Чем должны быть оборудованы все силосы элеваторов и склады силосного типа для хранения сырья?",
        answers: [
          "Защитным кожухом",
          "Устройством дистанционного контроля температуры",
          "Двойными уплотняющими прокладками",
        ],
        correctAnswers: ["Устройством дистанционного контроля температуры"],
      },
      {
        code: "21102034",
        text: "Какие из перечисленных сведений отражаются в технологическом регламенте, разрабатываемом на объекте хранения и переработки растительного сырья?",
        answers: [
          "Все перечисленные",
          "Нормы расхода основных видов сырья",
          "Характеристики производства, используемого в производстве сырья и выпускаемой (производимой) продукции",
          "Сведения о возможных инцидентах в работе и способах их ликвидации",
          "Описание технологического процесса производства",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "21102035",
        text: "Какое из перечисленных требований к взрывопожароопасным производственным объектам хранения и переработки растительного сырья указано неверно?",
        answers: [
          "К зданиям, сооружениям и строениям объектов должен быть обеспечен подъезд пожарных автомобилей, пожарные водоемы в ночное время должны иметь звуковой указатель",
          "Устройство железнодорожных путей, переездов и переходов через них, а также организация и эксплуатация железнодорожного хозяйства организации должны соответствовать требованиям нормативных правовых актов в сфере транспорта",
          "Необходимые для производственных целей подземные резервуары, колодцы, пожарные водоемы должны быть закрыты или ограждены со всех сторон",
          "В организации, эксплуатирующей объекты, должно быть обеспечено исправное состояние отводов атмосферных осадков от зданий и сооружений к водостокам, дорог для транспорта, пожарных проездов, рельсовых путей (с соблюдением требуемых габаритов, допускаемых уклонов и радиусов закруглений), сетей наружного освещения, пешеходных дорожек, пожарных и хозяйственных водопроводов, а также ограждений территории объектов и организации",
          "Доступ на территорию организации, эксплуатирующей объекты, посторонним лицам запрещен",
        ],
        correctAnswers: [
          "К зданиям, сооружениям и строениям объектов должен быть обеспечен подъезд пожарных автомобилей, пожарные водоемы в ночное время должны иметь звуковой указатель",
        ],
      },
      {
        code: "21102036",
        text: "Размещение помещений какой категории по взрывопожароопасности допускается в подвальных и цокольных этажах, и при этом не требуется дополнительного обоснования в проектной документации?",
        answers: [
          "В подвальных и цокольных этажах не допускается размещение помещений всех перечисленных категорий",
          "Категории В1",
          "Категории А",
          "Категории Б",
        ],
        correctAnswers: ["Категории В1"],
      },
      {
        code: "21102037",
        text: "Где не допускается прокладка трубопроводов с пожаро- и взрывоопасными веществами (смесями)?",
        answers: [
          "Через распределительные устройства, трансформаторные подстанции и преобразовательные подстанции",
          "Только через комплектные трансформаторные подстанции",
          "Только через распределительные устройства",
          "Только через трансформаторные подстанции",
        ],
        correctAnswers: [
          "Через распределительные устройства, трансформаторные подстанции и преобразовательные подстанции",
        ],
      },
      {
        code: "21102038",
        text: "На каком расстоянии друг от друга должны размещаться мостики через конвейеры в производственных помещениях, а также в галереях и эстакадах?",
        answers: [
          "Не более 50 м в производственных помещениях, не более 100 м в галереях и эстакадах",
          "Не более 75 м в производственных помещениях, галереях и эстакадах",
          "Не более 55 м в производственных помещениях, не более 120 м в галереях и эстакадах",
          "Не более 90 м в производственных помещениях, не более 150 м в галереях и эстакадах",
        ],
        correctAnswers: [
          "Не более 50 м в производственных помещениях, не более 100 м в галереях и эстакадах",
        ],
      },
      {
        code: "21102039",
        text: "Каким требованиям должны отвечать устройства, применяемые для спуска людей в силосы?",
        answers: [
          "Требованиям, установленным нормативными правовыми актами, содержащими требования промышленной безопасности при проведении работ с инструментом и приспособлениями",
          "Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при выполнении огневых и газоопасных работ",
          "Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при работе на высоте",
          "Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при работе в замкнутом пространстве",
        ],
        correctAnswers: [
          "Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при работе на высоте",
        ],
      },
      {
        code: "21102040",
        text: "Для каких помещений системы приточной вентиляции совмещают с воздушным отоплением?",
        answers: [
          "Для любых производственных и вспомогательных помещений",
          "Для взрывопожароопасных производственных помещений с трехсменным режимом работы",
          "Для взрывопожароопасных производственных помещений с режимом работы в одну смену",
        ],
        correctAnswers: [
          "Для взрывопожароопасных производственных помещений с трехсменным режимом работы",
        ],
      },
      {
        code: "21102041",
        text: "Размещение помещений какой категории по взрывопожароопасности допускается в подвальных и цокольных этажах, и при этом не требуется дополнительного обоснования в проектной документации при техническом перевооружении и реконструкции объектов?",
        answers: [
          "Категории А",
          "Категории Б",
          "В подвальных и цокольных этажах допускается размещение помещений всех перечисленных категорий",
          "Категории Г",
        ],
        correctAnswers: ["Категории Г"],
      },
      {
        code: "21102042",
        text: 'В помещениях какой категории запрещается устройство выбоя отходов производства в тару в соответствии с Федеральными нормами и правилами в области промышленной безопасности "Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья"?',
        answers: [
          "В помещениях категории Г",
          "В помещениях категорий А и Б",
          "В помещениях категории В",
          "В помещениях категории А",
          "В помещениях категории Б",
          "В помещениях категории Д",
        ],
        correctAnswers: ["В помещениях категории В"],
      },
      {
        code: "21102043",
        text: "При каком условии должен производиться доступ рабочих в силосы и бункеры через нижний люк для производства огневых работ?",
        answers: [
          "Только при наличии разрешения территориального органа Федеральной службы по экологическому, технологическому и атомному надзору и в присутствии наблюдающего",
          "Только при наличии наряда-допуска, оформленного в порядке, определенном Правилами противопожарного режима в Российской Федерации, утвержденными постановлением Правительства Российской Федерации от 16 сентября 2020 г. N 1479, и разрешения ответственного руководителя работ и под его наблюдением",
          "Доступ рабочих в силосы и бункеры через нижний люк запрещается",
          "Только в присутствии главного инженера эксплуатирующей организации и представителя территориального органа Федеральной службы по экологическому, технологическому и атомному надзору",
        ],
        correctAnswers: [
          "Только при наличии наряда-допуска, оформленного в порядке, определенном Правилами противопожарного режима в Российской Федерации, утвержденными постановлением Правительства Российской Федерации от 16 сентября 2020 г. N 1479, и разрешения ответственного руководителя работ и под его наблюдением",
        ],
      },
      {
        code: "21102044",
        text: "Какой должна быть минимальная площадь оконного стекла толщиной 5 мм, используемого в качестве легкосбрасываемой конструкции?",
        answers: ["1,5 м²", "1,2 м²", "0,8 м²", "1,9 м²"],
        correctAnswers: ["1,5 м²"],
      },
      {
        code: "21102045",
        text: "В каком из перечисленных случаев может производиться спуск рабочих в силосы и бункеры?",
        answers: [
          "Если спуск производится для проверки температуры хранящегося сырья",
          "Если спуск производится для отбора проб сырья",
          "В исключительных случаях при обоснованной производственной необходимости (плановая зачистка после опорожнения силосов или бункеров от остатков сырья предыдущего хранения, обслуживание (ремонт) внутренних поверхностей силосов и бункеров)",
          "Если спуск производится для проведения анализа газовой среды",
        ],
        correctAnswers: [
          "В исключительных случаях при обоснованной производственной необходимости (плановая зачистка после опорожнения силосов или бункеров от остатков сырья предыдущего хранения, обслуживание (ремонт) внутренних поверхностей силосов и бункеров)",
        ],
      },
      {
        code: "21102046",
        text: "С учетом ограничений каких документов эксплуатирующей организацией в технологическом регламенте определяются сроки, в которые проверяется температура сырья? Выберите 2 варианта ответа.",
        answers: [
          "Документов по стандартизации",
          "Технического паспорта",
          "Технических регламентов",
          "Правил безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья",
          "Инструкций",
        ],
        correctAnswers: [
          "Документов по стандартизации",
          "Технических регламентов",
        ],
      },
      {
        code: "21102047",
        text: "Какие действия необходимо предпринять в случае превышения допустимой температуры заложенного на хранение растительного сырья, продуктов его переработки и комбикормового сырья, указанной для соответствующего вида сырья (продукта) в технологическом регламенте? Выберите все правильные варианты ответов.",
        answers: [
          "Применить активное вентилирование, сушку",
          "Периодически увлажнять сырье (продукт) до снижения температуры до допустимых значений",
          "Обеспечить постоянный контакт сырья (продукта) с токопроводящей поверхностью стенки бункера",
          "Производить перекачку сырья (продукта) из одного силоса (бункера) в другой или с площадки на площадку",
        ],
        correctAnswers: [
          "Применить активное вентилирование, сушку",
          "Производить перекачку сырья (продукта) из одного силоса (бункера) в другой или с площадки на площадку",
        ],
      },
      {
        code: "21102048",
        text: "Какие из перечисленных действий допускаются при эксплуатации объектов хранения и переработки растительного сырья?",
        answers: [
          "Хранение сырого зерна на складах силосного типа",
          "Временное размещение сырого и влажного зерна в накопительных емкостях при условии разработки комплекса организационно-технических мер по обеспечению безопасности эксплуатации таких объектов",
          "Временное хранение зерна на открытых площадках при наличии специальных навесов",
          "Хранение влажного зерна в силосах элеватора",
        ],
        correctAnswers: [
          "Временное размещение сырого и влажного зерна в накопительных емкостях при условии разработки комплекса организационно-технических мер по обеспечению безопасности эксплуатации таких объектов",
        ],
      },
      {
        code: "21102049",
        text: "Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов I класса опасности (за исключением объектов, на которых ведутся горные работы)?",
        answers: ["5 лет", "3 года", "3 месяца", "2 года"],
        correctAnswers: ["5 лет"],
      },
      {
        code: "21102050",
        text: "Что из перечисленного не предусматривает план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Организацию взаимодействия сил и средств",
          "Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий",
          "Порядок проведения восстановительных работ по окончании ликвидации аварии на объекте",
          "Состав и дислокацию сил и средств",
          "Количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте",
          "Мероприятия, направленные на обеспечение безопасности населения",
        ],
        correctAnswers: [
          "Порядок проведения восстановительных работ по окончании ликвидации аварии на объекте",
        ],
      },
      {
        code: "21102051",
        text: "Из каких разделов состоит план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Из общих и специальных разделов",
          "Из частных разделов",
          "Из специализированных разделов",
          "Из разделов, предусмотренных требованиями федеральных норм и правил в области промышленной безопасности",
          "Из графических разделов",
        ],
        correctAnswers: ["Из общих и специальных разделов"],
      },
      {
        code: "21102052",
        text: "Кем утверждаются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Только руководителями (заместителями руководителей) организаций, эксплуатирующих объекты",
          "Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
          "Руководителями структурных подразделений объекта",
          "Специалистами поднадзорных организаций совместно со специалистами надзорных органов",
        ],
        correctAnswers: [
          "Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)",
        ],
      },
      {
        code: "21102053",
        text: "Каким образом необходимо разработать план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах в случае, если 2 и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?",
        answers: [
          "Разрабатывается единый план мероприятий только для объектов, находящихся на расстоянии менее 100 м друг от друга",
          "Разрабатывается один план мероприятий для объектов, расположенных на одном участке, и несколько для объектов на смежных участках",
          "Необходимо разработать планы мероприятий для каждого объекта отдельно",
          "Допускается разработка единого плана мероприятий для 2 и более объектов",
        ],
        correctAnswers: [
          "Допускается разработка единого плана мероприятий для 2 и более объектов",
        ],
      },
      {
        code: "21102054",
        text: "Что из перечисленного содержится в специальном разделе мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
          "Характеристика объектов, в отношении которых разрабатывается план мероприятий",
          "Возможные сценарии возникновения и развития аварий на объектах, а также источники (места) возникновения аварий",
          "Характеристики аварийности, присущие объектам, в отношении которых разрабатывается План мероприятий, и травматизма на таких объектах",
        ],
        correctAnswers: [
          "Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности",
        ],
      },
      {
        code: "21102055",
        text: "В каком из перечисленных случаев план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах должен быть пересмотрен?",
        answers: [
          "Не менее чем за 30 календарных дней до истечения срока действия предыдущего плана мероприятий",
          "Не позднее 10 дней после внесения изменений в системы управления технологическими процессами на объекте",
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства.",
          "После назначения нового руководителя организации, эксплуатирующей объект",
        ],
        correctAnswers: [
          "Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства.",
        ],
      },
      {
        code: "21102056",
        text: "Кем должны согласовываться планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
          "Руководителями территориальных органов Федеральной службы по экологическому, технологическому и атомному надзору",
          "Руководителями территориальных органов Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "Руководителями муниципальных образований, на территории которых расположен объект",
        ],
        correctAnswers: [
          "Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте",
        ],
      },
      {
        code: "21102057",
        text: "Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?",
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          "Только для ОПО I и II классов опасности",
          "Для всех ОПО без исключения",
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: "21102058",
        text: "Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?",
        answers: [
          "1,5 года",
          "4 года",
          "Наибольший срок, предусмотренный для этих объектов",
          "Наименьший срок, предусмотренный для этих объектов",
          "2 года",
        ],
        correctAnswers: ["Наименьший срок, предусмотренный для этих объектов"],
      },
      {
        code: "21102059",
        text: "В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?",
        answers: [
          "В целях регламентации действий персонала при возникновении аварии",
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
          "В целях обеспечения соответствия объекта требованиям промышленной безопасности",
          "В целях регламентации действий подразделений муниципальной пожарной охраны при возникновении аварии",
          "В целях минимизации последствий аварий",
        ],
        correctAnswers: [
          "В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах",
        ],
      },
      {
        code: "21102060",
        text: "При расположении на какой высоте от пола осей приводных барабанов необходимо предусматривать специальные площадки с перилами высотой не менее 1 м с зашивкой внизу на 0,15 м с обеспечением проходов для обслуживания головок норий?",
        answers: [
          "На высоте более 1,5 м",
          "На высоте более 1,0 м",
          "На высоте более 1,2 м",
          "На высоте более 0,5 м",
        ],
        correctAnswers: ["На высоте более 1,5 м"],
      },
      {
        code: "21102061",
        text: "Какие должны быть проходы у башмака нории?",
        answers: [
          "Шириной не менее 0,7 м",
          "Шириной не менее 0,5 м",
          "Шириной не менее 0,6 м",
          "Шириной не менее 0,4 м",
        ],
        correctAnswers: ["Шириной не менее 0,7 м"],
      },
      {
        code: "21102062",
        text: "Какой шириной должны быть разрывы между шкивами вальцовых станков, расположенных в группе при трансмиссионном приводе или при приводе от индивидуальных электродвигателей, расположенных на нижележащем этаже?",
        answers: [
          "Не менее 0,20 м",
          "Не менее 0,25 м",
          "Не менее 0,35 м",
          "Не менее 0,15 м",
        ],
        correctAnswers: ["Не менее 0,35 м"],
      },
      {
        code: "21102063",
        text: "Что из перечисленного не допускается на объектах хранения и переработки растительного сырья?",
        answers: [
          "Внесение изменений в конструкцию защищаемого оборудования",
          "Внесение изменений в технологический регламент и схемы размещения оборудования, средств ДАУ, блокировки, контроля и противоаварийной защиты, производственной и аварийной сигнализации, оповещения об аварийных ситуациях",
          "Вывод взрыворазрядителей в производственное помещение",
          "Не предусматривать приточно-вытяжную или вытяжную вентиляцию для приямков, предназначенных для обслуживания норий, цепных конвейеров, расположенных в помещениях категории Б",
        ],
        correctAnswers: [
          "Внесение изменений в конструкцию защищаемого оборудования",
        ],
      },
      {
        code: "21102064",
        text: "В каких случаях могут применяться Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья?",
        answers: [
          "В случаях разработки технологических процессов, разработки документации, эксплуатации, капитального ремонта, технического перевооружения, консервации и ликвидации",
          "В случаях изготовления, монтажа, наладки, обслуживания и ремонта технических устройств, применяемых на объектах",
          "В случаях проведения экспертизы промышленной безопасности, разработки обоснования безопасности объектов",
          "В случаях подготовки и переподготовки работников объектов в необразовательных учреждениях",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "21102065",
        text: "Что из перечисленного не отражается в паспортах аспирационных установок, находящихся в эксплуатации?",
        answers: [
          "Принципиальные схемы установки и спецификации оборудования",
          "Описание проведенного ремонта",
          "Результаты осмотра установки",
          "Внутренние диаметры продуктопроводов",
        ],
        correctAnswers: ["Внутренние диаметры продуктопроводов"],
      },
      {
        code: "21102066",
        text: "В соответствии с чем составляются паспорта аспирационных и пневмотранспортных установок?",
        answers: [
          "Инструкцией по эксплуатации",
          "Внутренними распорядительными документами эксплуатирующей объект организации",
          "Технологическим регламентом",
          "Проектной документацией",
        ],
        correctAnswers: [
          "Внутренними распорядительными документами эксплуатирующей объект организации",
        ],
      },
      {
        code: "21102067",
        text: "В каком случае допускается спуск людей в силосы и бункеры без их письменного согласия?",
        answers: [
          "В случае плановой зачистки после опорожнения силосов",
          "В случае зачистки бункеров от остатков сырья предыдущего хранения",
          "В любом случае спуск людей в силосы и бункеры без их письменного согласия запрещается",
          "В случае обслуживания (ремонта) внутренних поверхностей силосов и бункеров",
        ],
        correctAnswers: [
          "В любом случае спуск людей в силосы и бункеры без их письменного согласия запрещается",
        ],
      },
      {
        code: "21102068",
        text: "Как оформляется готовность организации (объектов) к работе по приемке и размещению свежеубранного (нового урожая) зерна?",
        answers: [
          "Внутренним актом эксплуатирующей организации",
          "Техническим регламентом",
          "Проектной документацией",
          "Приказом (распоряжением) по предприятию",
        ],
        correctAnswers: ["Внутренним актом эксплуатирующей организации"],
      },
      {
        code: "21102069",
        text: "Контроль температуры подшипников каких устройств на объектах необходимо осуществлять устройствами дистанционного автоматического контроля температуры?",
        answers: [
          "Только турбокомпрессоров",
          "Только турбовоздуходувных машин",
          "Только дробилок",
          "Только вальцевых станков",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "21102070",
        text: "На основании чего в организации разрабатывается технологический регламент?",
        answers: [
          "Внутреннего акта эксплуатирующей организации",
          "Инструкции по эксплуатации оборудования",
          "Проектной документации",
          "Паспорта оборудования",
        ],
        correctAnswers: ["Проектной документации"],
      },
      {
        code: "21102071",
        text: "Что из перечисленного не отражается в технологической схеме производства графически?",
        answers: [
          "Движение сырья, готовой продукции",
          "Технологическое, аспирационное оборудование с указанием моделей и их основных характеристик",
          "Средства взрывопредупреждения",
          "Нормы расхода основных видов сырья",
        ],
        correctAnswers: ["Нормы расхода основных видов сырья"],
      },
      {
        code: "21102072",
        text: "Что из перечисленного не отражается в технологическом регламенте?",
        answers: [
          "Описание технологического процесса производства",
          "Информация о контроле и управлении технологическим процессом",
          "Условия безопасной эксплуатации производства",
          "Движение сырья, готовой продукции",
        ],
        correctAnswers: ["Движение сырья, готовой продукции"],
      },
      {
        code: "21102073",
        text: "Что из перечисленного не относится к организационным и организационно-техническим мероприятиям, которые должны выполняться для обеспечения взрывобезопасности объекта?",
        answers: [
          "Разработка нормативно-технических документов",
          "Применение средств оповещения об аварийных ситуациях",
          "Производственный контроль за соблюдением требований безопасности",
          "Организацию планово-предупредительного ремонта",
        ],
        correctAnswers: ["Организацию планово-предупредительного ремонта"],
      },
      {
        code: "21102074",
        text: "Каким документом определяются лица, ответственные за выполнение плана мероприятий по доведению объекта до нормативных требований промышленной безопасности, своевременное внесение соответствующих дополнений (изменений)?",
        answers: [
          "Внутренним распорядительным документом эксплуатирующей объект организации",
          "Техническим регламентом",
          "Техническим паспортом",
        ],
        correctAnswers: [
          "Внутренним распорядительным документом эксплуатирующей объект организации",
        ],
      },
      {
        code: "21102075",
        text: "Чем определяются методы (способы) управления, объемы автоматизации и используемая для этих целей элементная база?",
        answers: [
          "Техническим регламентом",
          "Внутренними распорядительными документами эксплуатирующей организации",
          "Правилами безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья",
          "Техническим заданием на проектирование",
        ],
        correctAnswers: ["Техническим заданием на проектирование"],
      },
      {
        code: "21102076",
        text: "Какие требования, установленные Правилами безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья, при наличии в проходе между конвейерами строительных конструкций (колонны, пилястры), создающих местное сужение прохода, указаны неверно?",
        answers: [
          "Расстояние между конвейерами и строительными конструкциям должно быть не менее 0,5 м",
          "Допускается не ограждать места прохода между конвейерами и строительными конструкциями при расстоянии между ними более 0,5 м",
          "Расстояние между конвейерами и строительными конструкциями должно быть по длине прохода до 1 м",
        ],
        correctAnswers: [
          "Допускается не ограждать места прохода между конвейерами и строительными конструкциями при расстоянии между ними более 0,5 м",
        ],
      },
      {
        code: "21102077",
        text: "В помещениях какой категории допускается применять отопительные приборы с гладкой поверхностью и размещать их на высоте, обеспечивающей возможность их очистки от пыли?",
        answers: [
          "Только в помещениях категории В",
          "В помещениях категории А, Б и В",
          "Только в помещениях категории А",
          "Только в помещениях категории Б",
        ],
        correctAnswers: ["В помещениях категории А, Б и В"],
      },
      {
        code: "21102078",
        text: "В помещениях какой категории допускается не предусматривать приточно-вытяжную или вытяжную вентиляцию для приямков, предназначенных для обслуживания норий и цепных конвейеров?",
        answers: [
          "В помещениях категории А",
          "В помещениях категории Б",
          "В помещениях категории В",
          "В помещениях категории Г",
        ],
        correctAnswers: ["В помещениях категории Б"],
      },
      {
        code: "21102079",
        text: "В помещениях какой категории по пожарной и взрывопожарной опасности на объектах хранения и переработки растительного сырья должны предусматриваются наружные легкосбрасываемые конструкции? Выберите 2 варианта ответа.",
        answers: [
          "В помещениях категории А",
          "В помещениях категории Б",
          "В помещениях категории В",
          "В помещениях категории Г",
        ],
        correctAnswers: [
          "В помещениях категории А",
          "В помещениях категории Б",
        ],
      },
      {
        code: "21102080",
        text: "В помещения каких классов вводы кабелей и труб электропроводки из взрывоопасных зон всех классов не должны быть плотно заделаны несгораемыми материалами? Выберите 2 варианта ответа.",
        answers: [
          "В помещениях классов В-Iа",
          "В помещениях классов В-Iб",
          "В помещениях классов В-IIа",
          "В помещениях классов В-Iг",
          "В помещениях классов В-II",
        ],
        correctAnswers: [
          "В помещениях классов В-Iг",
          "В помещениях классов В-II",
        ],
      },
      {
        code: "21102081",
        text: "В помещениях какой категории запрещается складирование мешков с сырьем или готовой продукцией, пустых мешков или других горючих материалов, если это не связано с необходимостью ведения технологического процесса?",
        answers: [
          "В помещениях категории А",
          "В помещении категории Б",
          "В помещении категории В",
          "В помещении категории Г",
        ],
        correctAnswers: ["В помещении категории Б"],
      },
      {
        code: "21102082",
        text: "Что из перечисленного допускается хранить на территории эксплуатирующей организации открытым способом?",
        answers: [
          "Зерновые отходы",
          "Лузгу",
          "Пыль",
          "Ничего из перечисленного",
        ],
        correctAnswers: ["Ничего из перечисленного"],
      },
      {
        code: "21102083",
        text: "Какие из перечисленных документов разрабатываются (составляются) в целях обеспечения безопасности производственных процессов на объектах организации?",
        answers: [
          "Технологические регламенты и схемы",
          "Паспорта на аспирационные и пневмотранспортные установки",
          "Паспорта на взрыворазрядные устройства",
          "Документ, отражающий фактические данные о наличии и техническом состоянии средств взрывопредупреждения и взрывозащиты производственных зданий, сооружений и оборудования объектов",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "21102084",
        text: "Кто проводит обследование объекта перед разработкой технического паспорта взрывобезопасности?",
        answers: [
          "Комиссия, назначенная приказом эксплуатирующей организации",
          "Главный инженер",
          "Технический руководитель",
          "Руководитель эксплуатирующей организации",
        ],
        correctAnswers: [
          "Комиссия, назначенная приказом эксплуатирующей организации",
        ],
      },
      {
        code: "21102085",
        text: "Каким документом определяется порядок (очередность) проведения обследования объекта перед разработкой технического паспорта взрывобезопасности?",
        answers: [
          "Внутренним распорядительным документом эксплуатирующей организации",
          "Техническим регламентом",
          "Инструкцией по эксплуатации оборудования",
          "Документом по стандартизации",
        ],
        correctAnswers: [
          "Внутренним распорядительным документом эксплуатирующей организации",
        ],
      },
      {
        code: "21102086",
        text: "Кем утверждается технический паспорт взрывобезопасности?",
        answers: [
          "Руководителем эксплуатирующей организации",
          "Главным инженером",
          "Технологом",
          "Механиком",
        ],
        correctAnswers: ["Руководителем эксплуатирующей организации"],
      },
      {
        code: "21102087",
        text: "Что должно предусматривать взрывопредупреждение?",
        answers: [
          "Регламентацию огневых работ",
          "Применение ДАУ производственными процессами",
          "Организацию планово-предупредительного ремонта",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21102088",
        text: "Какие требования к хранению и сушке кукурузы указаны неверно?",
        answers: [
          "Кукурузу в зерне следует сушить только в шахтных прямоточных отдельно стоящих сушилках",
          "При хранении зерна кукурузы должно предусматриваться минимальное число ее перемещений",
          "Уровень влажности при хранении зерна кукурузы до года не должен превышать 15%",
          "Уровень влажности при длительном хранении (более года) для кукурузы не должен превышать 12%",
        ],
        correctAnswers: [
          "Уровень влажности при хранении зерна кукурузы до года не должен превышать 15%",
        ],
      },
      {
        code: "21102089",
        text: "При каком условии допускается проведение обеззараживания зерна?",
        answers: [
          "При условии разработки и соблюдения комплекса организационно-технических мер по обеспечению безопасности при проведении работ с применением химических препаратов",
          "При условии разработки комплекса организационно-технических мер по обеспечению безопасности эксплуатации объекта",
          "При условии разработки и осуществления компенсирующих мер организационно-технического характера, направленных на повышение уровня взрывопожаробезопасности",
        ],
        correctAnswers: [
          "При условии разработки и соблюдения комплекса организационно-технических мер по обеспечению безопасности при проведении работ с применением химических препаратов",
        ],
      },
      {
        code: "21102090",
        text: "В каком случае допускается временное размещение семян подсолнечника в силосах элеваторов и складах силосного типа, оборудованных устройствами автоматизированного дистанционного контроля температуры?",
        answers: [
          "В любом случае не допускается",
          "В случае охлаждения семян подсолнечника до температуры, превышающей температуру наружного воздуха не более чем на 10 °С",
          "В случае влажности семян подсолнечника не более 8% и при условии разработки комплекса организацонно-технических мер по обеспечению безопасности эксплуатации объектов",
          "В случае если уровень влажности хранения зерна до года не превышает 7%",
        ],
        correctAnswers: [
          "В случае влажности семян подсолнечника не более 8% и при условии разработки комплекса организацонно-технических мер по обеспечению безопасности эксплуатации объектов",
        ],
      },
      {
        code: "21102091",
        text: "Инертные газы с каким расходом необходимо подавать в технологические люки конусной части аварийных силосов, бункеров для доступа воздуха в случае обрушения выгружаемого продукта в процессе выгрузки?",
        answers: [
          "С расходом, в 2 раза превышающим расходы на флегматизацию",
          "С расходом, в 3 раза превышающими расходы на флегматизацию",
          "С расходом, в 4 раза превышающими расходы на флегматизацию",
          "С расходом, в 1,5 раза превышающие расходы на флегматизацию",
        ],
        correctAnswers: [
          "С расходом, в 3 раза превышающими расходы на флегматизацию",
        ],
      },
      {
        code: "21102092",
        text: "Чем определяются режимы вентилирования зерна?",
        answers: [
          "Технологическим регламентом",
          "Инструкцией",
          "Паспортом оборудования",
          "Эксплуатационной документацией",
        ],
        correctAnswers: ["Технологическим регламентом"],
      },
      {
        code: "21102093",
        text: "Что из перечисленного не допускается размещать в производственных зданиях?",
        answers: [
          "Бытовые помещения и помещения с массовым (временным или постоянным) пребыванием людей (комнаты для собраний, для приема пищи)",
          "Административно-бытовые помещения, расположенные в пристройках в торце производственных зданий",
          "Диспетчерскую",
          "Помещения для обогрева рабочих, вальцерезную мастерскую, а также подсобные помещения без постоянного пребывания людей",
        ],
        correctAnswers: [
          "Бытовые помещения и помещения с массовым (временным или постоянным) пребыванием людей (комнаты для собраний, для приема пищи)",
        ],
      },
      {
        code: "21102094",
        text: "Что из перечисленного допускается размещать во взрывопожароопасных зданиях и сооружениях?",
        answers: [
          "Ничего из перечисленного",
          "Бункеры для хранения аспирационных относов",
          "Бункеры для хранения пыли",
          "Бункеры для хранения пылевидных продуктов",
        ],
        correctAnswers: ["Ничего из перечисленного"],
      },
      {
        code: "21102095",
        text: "Что из перечисленного не учитывается при анализе риска аварий на объектах?",
        answers: [
          "Источники опасности",
          "Факторы риска",
          "Условия возникновения аварий и их сценарии",
          "Мероприятия по предупреждению аварий, локализации и ликвидации их последствий",
        ],
        correctAnswers: [
          "Мероприятия по предупреждению аварий, локализации и ликвидации их последствий",
        ],
      },
      {
        code: "21102096",
        text: "Какие из перечисленных средств ДАУ, блокировки, контроля и противоаварийной защиты, производственной и аварийной сигнализации не должны предусматриваться (определяться на стадиях разработки процесса) на объектах?",
        answers: [
          "Аварийная остановка всех электродвигателей цеха с любого этажа и пульта управления (диспетчерской)",
          "Местное управление электроприводом каждой единицы оборудования",
          "Дистанционный контроль за верхним и нижним уровнями сырья и продуктов в силосах и бункерах",
          "Система светозвуковой сигнализации об остановке оборудования",
        ],
        correctAnswers: [
          "Система светозвуковой сигнализации об остановке оборудования",
        ],
      },
      {
        code: "21102097",
        text: "Кем определяется рациональный выбор средств ДАУ, блокировки, контроля и противоаварийной защиты?",
        answers: [
          "Разработчиками средств",
          "Руководителем эксплуатирующей организации",
          "Главным инженером",
        ],
        correctAnswers: ["Разработчиками средств"],
      },
      {
        code: "21102098",
        text: "Что является критерием эффективности магнитной защиты при приеме сырья с автомобильного, водного и железнодорожного транспорта?",
        answers: [
          "Полное извлечение металломагнитных примесей, способных инициировать искру",
          "Условия, исключающие возникновение разрядов статического электричества",
          "Заземление рабочих органов, узлов и элементов конструкций, выполненных из электропроводящих материалов",
        ],
        correctAnswers: [
          "Полное извлечение металломагнитных примесей, способных инициировать искру",
        ],
      },
      {
        code: "21102099",
        text: "Каким должно быть сопротивление изоляции обмоток электромагнитов?",
        answers: [
          "Не менее 0,3 МОм",
          "Не менее 0,4 МОм",
          "Не менее 0,5 МОм",
          "Не менее 0,2 МОм",
        ],
        correctAnswers: ["Не менее 0,5 МОм"],
      },
      {
        code: "21102100",
        text: "Без устройства чего в зданиях категории Б не допускается предусматривать соединяющие их с другими зданиями и помещениями тоннели и подземные галереи? Выберите 2 варианта ответа.",
        answers: [
          "Огнепреграждающих клапанов",
          "Тамбур-шлюзов",
          "Воздушной зоны",
          "Вентиляционных камер",
        ],
        correctAnswers: ["Огнепреграждающих клапанов", "Тамбур-шлюзов"],
      },
      {
        code: "21102101",
        text: "Какие требования электростатической искробезопасности на объектах хранения и переработки растительного сырья указаны неверно?",
        answers: [
          "Гибкие рукава, служащие для соединения автомуковоза с системой приема, должны быть токопроводными и выполнены в виде металлорукава из электропроводной резины",
          "Для отвода статических зарядов необходимо матерчатые фильтры прошивать проволокой из алюминий и соединять ее с системой заземления",
          "Защитное заземление должно быть основным средством защиты от опасных проявлений статического электричества",
          "Оборудование из электропроводящих материалов, а также его рабочие органы, узлы и элементы конструкций, выполненные из электропроводящих материалов, подлежат заземлению",
        ],
        correctAnswers: [
          "Для отвода статических зарядов необходимо матерчатые фильтры прошивать проволокой из алюминий и соединять ее с системой заземления",
        ],
      },
      {
        code: "21102102",
        text: "Какую степень защиты оболочки должны иметь применяемые во взрыво- и пожароопасных зонах ручные и переносные аппараты и приборы?",
        answers: [
          "Не ниже IP54",
          "Не ниже IP44",
          "Не ниже IP23",
          "Не ниже IP53",
        ],
        correctAnswers: ["Не ниже IP54"],
      },
      {
        code: "21102103",
        text: "Какой класс бетона по прочности на сжатие должен быть предусмотрен для сборных фундаментов?",
        answers: [
          "Не ниже В12,5",
          "Не ниже В15",
          "Не ниже В7,5",
          "Не ниже В10",
        ],
        correctAnswers: ["Не ниже В15"],
      },
      {
        code: "21102104",
        text: "Какое оборудование допускается устанавливать без фундаментов на подстилающий слой полов промышленных зданий при обосновании расчетом?",
        answers: [
          "Все перечисленное",
          "С вращающимися частями",
          "Станочное оборудование, агрегируемое на железобетонных опорных плитах",
          "С кривошипно-шатунными механизмами",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21102105",
        text: "Что допускается при расчете амплитуд колебаний фундаментов вертикальных машин? Выберите два правильных варианта ответа.",
        answers: [
          "Расчет амплитуд горизонтальных колебаний ограничить только для направления, параллельного главному валу машины",
          "Расчет амплитуд вертикальных колебаний производить только с учетом влияния вертикальной составляющей возмущающих сил",
          "Расчет амплитуд горизонтальных колебаний ограничить только для направления, перпендикулярного главному валу машины",
          "Расчет амплитуд вертикальных колебаний производить только с учетом влияния горизонтальной составляющей возмущающих сил",
        ],
        correctAnswers: [
          "Расчет амплитуд вертикальных колебаний производить только с учетом влияния вертикальной составляющей возмущающих сил",
          "Расчет амплитуд горизонтальных колебаний ограничить только для направления, перпендикулярного главному валу машины",
        ],
      },
      {
        code: "21102106",
        text: "Какой формы следует предусматривать подошву отдельных фундаментов конусных дробилок?",
        answers: ["Круглой", "Прямоугольной", "Овальной", "Квадратной"],
        correctAnswers: ["Квадратной"],
      },
      {
        code: "21102107",
        text: "Какими следует проектировать фундаменты мельничных установок? Выберите два правильных варианта ответа.",
        answers: [
          "Кирпичными",
          "Монолитными",
          "Сборно-монолитными",
          "Ячеисто-бетонными",
        ],
        correctAnswers: ["Монолитными", "Сборно-монолитными"],
      },
      {
        code: "21102108",
        text: "Какие показатели не входят в число расчетных статических нагрузок?",
        answers: [
          "Коэффициент надежности по нагрузке",
          "Вес грунта на обрезах фундамента",
          "Вес фундамента",
          "Вес машины и вес вспомогательного оборудования",
        ],
        correctAnswers: ["Коэффициент надежности по нагрузке"],
      },
      {
        code: "21102109",
        text: "Какими рекомендуется проектировать сборно-монолитные фундаменты дробилок? Выберите два правильных варианта ответа.",
        answers: ["Рамными", "Ригельными", "Блочными", "Стенчатыми"],
        correctAnswers: ["Рамными", "Стенчатыми"],
      },
      {
        code: "21102110",
        text: "Какой минимальный диаметр продольных и поперечных стержней следует принимать для армирования подошвы фундаментов при стороне подошвы менее 3 м?",
        answers: ["9 мм", "16 мм", "10 мм", "5 мм"],
        correctAnswers: ["10 мм"],
      },
      {
        code: "21102111",
        text: "На основании чего и с учетом каких данных должны проектироваться фундаменты машин с динамическими нагрузками?",
        answers: [
          "Только на основании результатов инженерных изысканий для строительства, включая экологические и санитарно-эпидемиологические требования",
          "Только с учетом нагрузок, действующих на фундаменты машин, окружающей застройки и влияния на нее вновь строящихся и реконструируемых фундаментов машин",
          "На основании всех перечисленных данных, включая данные, характеризующие назначение, конструктивные и технологические особенности машин с динамическими нагрузками, а также условия их эксплуатации",
        ],
        correctAnswers: [
          "На основании всех перечисленных данных, включая данные, характеризующие назначение, конструктивные и технологические особенности машин с динамическими нагрузками, а также условия их эксплуатации",
        ],
      },
      {
        code: "21102112",
        text: "Какие из перечисленных данных служат исходными для проектирования фундаментов машин с динамическими нагрузками?",
        answers: [
          "Только техническая характеристика машины (наименование, тип, число оборотов в минуту, мощность, общая масса и масса движущихся частей, кинематическая схема оборудования с привязкой движущихся масс, скорость ударяющих частей и т. п.)",
          "Только данные о привязке проектируемого фундамента к конструкциям здания (сооружения), в частности, к его фундаментам, данные об особенностях здания (сооружения), в том числе о виде и расположении имеющихся в нем оборудования и коммуникаций",
          "Только чертежи габаритов фундамента в пределах расположения машины, элементов ее крепления, а также вспомогательного оборудования и коммуникаций с указанием расположения и размеров выемок, каналов и отверстий, размеров подливки и пр.",
          "Все перечисленные, включая специальные требования к защите фундамента и его приямков от подземных вод, воздействия агрессивных сред, промышленных стоков, температурных воздействий и другие, вытекающие из специфики каждого вида машин",
        ],
        correctAnswers: [
          "Все перечисленные, включая специальные требования к защите фундамента и его приямков от подземных вод, воздействия агрессивных сред, промышленных стоков, температурных воздействий и другие, вытекающие из специфики каждого вида машин",
        ],
      },
      {
        code: "21102113",
        text: "Какое требование к проектированию фундаментов указано неверно?",
        answers: [
          "Фундаменты машин допускается проектировать отдельными под каждую машину (агрегат) или общими под несколько машин (агрегатов)",
          "При соответствующем обосновании рекомендуется предусматривать виброзащиту фундаментов",
          "Фундаменты машин должны быть отделены сквозным швом от смежных фундаментов здания, сооружения и оборудования, а также от пола",
          "При соответствующем обосновании допускается использование в конструкциях фундаментов машин бывших в употреблении металлоконструкций",
        ],
        correctAnswers: [
          "При соответствующем обосновании допускается использование в конструкциях фундаментов машин бывших в употреблении металлоконструкций",
        ],
      },
      {
        code: "21102114",
        text: "Какой класс бетона (по прочности на сжатие) допускается применять для монолитных фундаментов?",
        answers: [
          "Не ниже В12,5",
          "Не ниже В10",
          "Не ниже В7,5",
          "Не ниже В40",
        ],
        correctAnswers: ["Не ниже В12,5"],
      },
      {
        code: "21102115",
        text: "Какой тип фундаментов не применяют при проектировании машин с динамическими нагрузками?",
        answers: ["Свайный винтовой", "Стенчатый", "Рамный", "Безростверковый"],
        correctAnswers: ["Свайный винтовой"],
      },
      {
        code: "21102116",
        text: "Какие рекомендации по проектированию стенчатых и рамных фундаментов не соответствуют своду правил?",
        answers: [
          "При проектировании рамных фундаментов необходимо располагать ригели поперечных рам симметрично по отношению к осям стоек и избегать передачи нагрузок на ригели и балки с эксцентриситетом",
          "При проектировании рамных фундаментов высоту опорного сечения консоли при отсутствии соответствующих расчетов следует принимать не менее 0,75 ее вылета",
          "Высоту нижней фундаментной плиты в стенчатых и рамных фундаментах следует принимать по расчету, но не менее 0,4 м и не менее толщины стены или большего размера стоек",
          "При динамических нагрузках, требующих установки болтов диаметром не менее 42 мм, следует применять съемные фундаментные болты. Расстояние от нижних концов болтов до подошвы фундамента должно быть не менее 70 мм",
        ],
        correctAnswers: [
          "При динамических нагрузках, требующих установки болтов диаметром не менее 42 мм, следует применять съемные фундаментные болты. Расстояние от нижних концов болтов до подошвы фундамента должно быть не менее 70 мм",
        ],
      },
      {
        code: "21102117",
        text: "В каком случае нарушены требования к выполнению армирования элементов стенчатых и рамных фундаментов?",
        answers: [
          "Стойки следует армировать симметричной продольной арматурой с шагом не более 300 мм",
          "По боковым граням балок и ригелей не реже чем через 400 мм по высоте сечения следует устанавливать промежуточные стержни диаметром не менее 10 мм",
          "При конструктивном армировании стен стенчатого фундамента диаметр вертикальных стержней должен быть не менее 12 мм, а горизонтальных - не менее 10 мм. Шаг стержней в обоих направлениях следует принимать равным 200 мм",
        ],
        correctAnswers: [
          "По боковым граням балок и ригелей не реже чем через 400 мм по высоте сечения следует устанавливать промежуточные стержни диаметром не менее 10 мм",
        ],
      },
      {
        code: "21102118",
        text: "На каких расстояниях рекомендуется предусматривать температурно-усадочные швы для монолитных бетонных фундаментов?",
        answers: ["20 м", "45 м", "58 м", "10 м", "5 м"],
        correctAnswers: ["20 м"],
      },
      {
        code: "21102119",
        text: "Что недопустимо при проектировании фундаментов машин с вращающимися частями?",
        answers: [
          "Опирать стойки площадок обслуживания машин и перекрытия над подвалом на нижние плиты (или ростверки) рамных фундаментов машин",
          "Опирать вкладные участки перекрытия на элементы верхнего строения фундаментов машин",
          "Возводить фундаменты машин при устройстве под всем машинным залом общей фундаментной плиты непосредственно на этой плите",
          "Связывать элементы верхнего строения фундаментов с элементами и конструкциями здания",
        ],
        correctAnswers: [
          "Связывать элементы верхнего строения фундаментов с элементами и конструкциями здания",
        ],
      },
      {
        code: "21102120",
        text: "Каким фундаментам должно отдаваться предпочтение при проектировании фундаментов дробилок?",
        answers: ["Стенчатым", "Плитным", "Столбчатым", "Ленточным"],
        correctAnswers: ["Стенчатым"],
      },
      {
        code: "21102121",
        text: "К определению какого показателя сводится расчет колебаний фундаментов дробилок?",
        answers: [
          "Наибольшей амплитуды горизонтальных колебаний верхней грани фундамента",
          "Динамических нагрузок",
          "Среднеквадратического значения амплитуды колебаний",
        ],
        correctAnswers: [
          "Наибольшей амплитуды горизонтальных колебаний верхней грани фундамента",
        ],
      },
      {
        code: "21102122",
        text: "Что не допускается при расчете колебаний фундаментов машин?",
        answers: [
          "При действии на фундамент машины одновременно нескольких возмущающих сил и отсутствии данных об их фазовом соотношении рассматривать варианты синфазного и противофазного действия сил, вызывающие наиболее неблагоприятные формы колебаний",
          "Полностью пренебрегать эксцентриситетом в распределении масс фундамента",
          "Рассматривать основание как упруго-вязкое линейно деформируемое, свойства которого определяются коэффициентами упругого равномерного и неравномерного сжатия, упругого равномерного и неравномерного сдвига и коэффициентами, характеризующими демпфирование",
          "При упругом неравномерном сжатии (повороте подошвы фундамента относительно горизонтальной оси, проходящей через центр тяжести подошвы фундамента перпендикулярно плоскости колебаний) принимать, что плоскость колебаний параллельна линии действия возмущающей силы или плоскости действия возмущающего момента",
        ],
        correctAnswers: [
          "Полностью пренебрегать эксцентриситетом в распределении масс фундамента",
        ],
      },
      {
        code: "21102123",
        text: "Для каких водонапорных башен высота опор от уровня земли до верха опоры бака устанавливается кратной 3 м?",
        answers: [
          "Для башен с баками вместимостью от 50 до 150 м³",
          "Для башен с баками вместимостью 150 м³ и более",
          "Для башен с баками вместимостью от 15 до 50 м³",
          "Для башен с баками вместимостью от 100 до 150 м³",
        ],
        correctAnswers: ["Для башен с баками вместимостью от 15 до 50 м³"],
      },
      {
        code: "21102124",
        text: "Какие тоннели и каналы должны быть заглублены от поверхности до верха перекрытия не менее чем на 1 м?",
        answers: [
          "Тоннели и каналы, располагаемые вне зданий и дорог",
          "Тоннели и каналы, располагаемые под автомобильными дорогами",
          "Тоннели и каналы, располагаемые внутри цехов",
          "Тоннели и каналы, располагаемые под железным дорогами",
        ],
        correctAnswers: [
          "Тоннели и каналы, располагаемые под железным дорогами",
        ],
      },
      {
        code: "21102125",
        text: "Какие проверки необходимо производить при расчете подпорных стен по предельным состояниям второй группы (по пригодности к эксплуатации)? Выберите два правильных варианта ответа.",
        answers: [
          "Железобетонных элементов на допустимые величины раскрытия трещин",
          "Прочности элементов конструкции и узлов соединений",
          "Устойчивости положения стены против сдвига, опрокидывания и поворота",
          "Основания на допустимые деформации",
        ],
        correctAnswers: [
          "Железобетонных элементов на допустимые величины раскрытия трещин",
          "Основания на допустимые деформации",
        ],
      },
      {
        code: "21102126",
        text: "Каким образом должен располагаться низ опорной плиты стальных опор открытых сооружений при проектировании сооружений промышленных предприятий?",
        answers: [
          "Выше планировочной отметки земли, как правило, не менее чем на 150 мм",
          "Ниже планировочной отметки земли на 50 мм",
          "Выше планировочной отметки земли на 100 мм",
          "Выше планировочной отметки земли на 70 мм",
        ],
        correctAnswers: [
          "Выше планировочной отметки земли, как правило, не менее чем на 150 мм",
        ],
      },
      {
        code: "21102127",
        text: "Кратными какому значению рекомендуется принимать высоту и ширину тоннелей, каналов (между выступающими частями несущих конструкций)?",
        answers: ["100 мм", "200 мм", "400 мм", "300 мм"],
        correctAnswers: ["300 мм"],
      },
      {
        code: "21102128",
        text: "Какими должны приниматься размеры пешеходных галерей или тоннелей при необходимости наличия в проектах?",
        answers: [
          "В зависимости от пропускной способности, но не более 2 м",
          "Высота - не менее 3 м, ширина - не менее 2 м",
          "Высота - не менее 2 м, ширина - не менее 1,5 м",
          "Высота - не менее 4 м, ширина - не менее 2,5 м",
        ],
        correctAnswers: ["Высота - не менее 2 м, ширина - не менее 1,5 м"],
      },
      {
        code: "21102129",
        text: "В каком случае требования по проектированию разгрузочных железнодорожных эстакад указаны неверно?",
        answers: [
          "При проектировании разгрузочных железнодорожных эстакад должны предусматриваться помещения для защиты работающих от неблагоприятных метеорологических воздействий",
          "На железобетонных конструкциях сооружений должны предусматриваться гидроизоляция и сливы, обеспечивающие свободный сток воды",
          "Настил обслуживающих площадок разгрузочных железнодорожных эстакад следует проектировать с таким расчетом, чтобы исключалось скольжение при ходьбе",
          "Марка бетона устанавливается проектом и не зависит от значения расчетных зимних температур наружного воздуха в районе строительства",
        ],
        correctAnswers: [
          "Марка бетона устанавливается проектом и не зависит от значения расчетных зимних температур наружного воздуха в районе строительства",
        ],
      },
      {
        code: "21102130",
        text: "Что запрещается при проектировании подвалов производственного назначения?",
        answers: [
          "Предусматривать высоту проходов в подвалах (в чистоте) не менее 2 м",
          "Защищать подвал гидроизоляцией при наличии грунтовых вод",
          "Предусматривать полы с уклоном к трапам (приямкам) канализации с обособленной системой отвода воды",
          "Непосредственно соединять приямки с ливневой и другими типами канализации",
        ],
        correctAnswers: [
          "Непосредственно соединять приямки с ливневой и другими типами канализации",
        ],
      },
      {
        code: "21102131",
        text: "Какой не следует принимать высоту стен закромов при проектировании сооружений промышленных предприятий?",
        answers: ["2,5 м", "3,6 м", "4,8 м", "6,0 м"],
        correctAnswers: ["2,5 м"],
      },
      {
        code: "21102132",
        text: "Из какого материала, как правило, следует проектировать закрома?",
        answers: [
          "Из шамотного кирпича",
          "Из листового металла",
          "Из железобетона",
          "Из поликарбоната",
          "Из стеклопластика",
        ],
        correctAnswers: ["Из железобетона"],
      },
      {
        code: "21102133",
        text: "Какие бункеры для хранения и перегрузки сыпучего материала допускается проектировать стальными?",
        answers: [
          "Параболические (висячие бункеры)",
          "Все перечисленные",
          "Бункеры, которые по технологическим условиям подвергаются механическим, химическим и температурным воздействиям сыпучего материала",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "21102134",
        text: "Какие существуют формы истечения сыпучего материала из бункера?",
        answers: [
          "В зависимости от типа движения сыпучего материала во всем объеме бункера (гидравлическая и негидравлическая)",
          "В зависимости от формы бункера и его воронки (низкопроизводительная и высокопроизводительная)",
          "В зависимости от физико-механических характеристик сыпучего материала (быстрого и медленного истечения)",
        ],
        correctAnswers: [
          "В зависимости от типа движения сыпучего материала во всем объеме бункера (гидравлическая и негидравлическая)",
        ],
      },
      {
        code: "21102135",
        text: "Бункеры какой формы не допускается проектировать для связных материалов гидравлического истечения?",
        answers: ["Конической", "Пирамидальной", "Лотковой", "Параболической"],
        correctAnswers: ["Параболической"],
      },
      {
        code: "21102136",
        text: "На какие нагрузки должна быть рассчитана конструкция бункера?",
        answers: [
          "Только на действие постоянной нагрузки от собственного веса конструкций, веса футеровки, а также на действие постоянных и временных нагрузок надбункерного перекрытия",
          "Только на действие постоянной нагрузки от собственного веса конструкций, временной нагрузки от веса сыпучего материала, заполняющего бункер, и нагрузки надбункерного перекрытия",
          "На действие временной нагрузки от веса сыпучего материала, заполняющего бункер, постоянных нагрузок от собственного веса конструкций, веса футеровки, а также на действие постоянных и временных нагрузок надбункерного перекрытия",
        ],
        correctAnswers: [
          "На действие временной нагрузки от веса сыпучего материала, заполняющего бункер, постоянных нагрузок от собственного веса конструкций, веса футеровки, а также на действие постоянных и временных нагрузок надбункерного перекрытия",
        ],
      },
      {
        code: "21102137",
        text: "Какую зону бункера необходимо защищать шлакоситаллом или другими материалами?",
        answers: [
          "I зону - участок, подвергающийся ударам потока сыпучего материала при загрузке бункера и истиранию при его разгрузке",
          "II зону - участок, подвергающийся истиранию сыпучим материалом в процессе разгрузки бункера",
          "III зону - участок не подвергающийся износу",
        ],
        correctAnswers: [
          "II зону - участок, подвергающийся истиранию сыпучим материалом в процессе разгрузки бункера",
        ],
      },
      {
        code: "21102138",
        text: "При каком способе закрепления строительных конструкций и технологического оборудования на фундаментах не требуется соответствующее обоснование?",
        answers: [
          "На виброгасителях",
          "На анкерных болтах",
          "При всех перечисленных способах",
          "На клею",
        ],
        correctAnswers: ["На анкерных болтах"],
      },
      {
        code: "21102139",
        text: "Какое требование по проектированию бункеров указано неверно?",
        answers: [
          "При эксплуатации бункеров в агрессивной и газовой средах их наружные поверхности следует защищать от коррозии",
          "При сочетании истирающего воздействия, высокой температуры и химической агрессии сыпучего материала внутренние поверхности бункеров следует защищать плитами из шлакокаменного литья, износостойкого и жаростойкого бетона",
          "При проектировании бункеров для связных материалов, поступающих в нагретом или смерзшемся состоянии, необходимо предусматривать теплоизоляцию стен бункеров в соответствии с теплотехническим расчетом",
          "При проектировании бункеров для влажных сыпучих материалов, располагаемых в неотапливаемых помещениях, необходимо предусматривать специальную защитную облицовку-футеровку",
        ],
        correctAnswers: [
          "При проектировании бункеров для влажных сыпучих материалов, располагаемых в неотапливаемых помещениях, необходимо предусматривать специальную защитную облицовку-футеровку",
        ],
      },
      {
        code: "21102140",
        text: "Каким образом следует назначать длину разгрузочной железнодорожной эстакады?",
        answers: [
          "В соответствии с требованиями инструкций по эксплуатации",
          "В зависимости от материала конструкций эстакады",
          "Равной 1,8, 3, 6, 9 м",
          "В соответствии с технологическими расчетами и с учетом местных условий строительства эстакады",
          "В зависимости от высоты эстакады",
        ],
        correctAnswers: [
          "В соответствии с технологическими расчетами и с учетом местных условий строительства эстакады",
        ],
      },
      {
        code: "21102141",
        text: "Какие нагрузки не учитываются при расчете силосов?",
        answers: [
          "Возникающие при изготовлении, перевозке и монтаже сборных конструкций",
          "Возникающие при изменении температур наружного воздуха",
          "Возникающие от давления при взрыве",
          "Возникающие от столкновений транспортных средств с частями сооружения",
        ],
        correctAnswers: [
          "Возникающие от столкновений транспортных средств с частями сооружения",
        ],
      },
      {
        code: "21102142",
        text: "Какую марку бетона следует принимать при проектировании сборных железобетонных элементов стен силосов?",
        answers: [
          "Не ниже В12,5",
          "Не ниже В15",
          "Не ниже В20",
          "Не ниже В25",
          "Не ниже В40",
        ],
        correctAnswers: ["Не ниже В25"],
      },
      {
        code: "21102143",
        text: "В каком положении нарушены требования Свода правил СП 43.13330.2012 при расчете конструкций силосов и их элементов?",
        answers: [
          "При определении давления на грунт под подошвой фундамента силосов следует учитывать только нагрузку, возникающую при полной загрузке силосов сыпучими материалами",
          "Расчет оснований сблокированных и отдельно стоящих силосов, возводимых на нескальных грунтах, должен производиться по предельным состояниям второй группы (по деформациям) с учетом ветровой нагрузки",
          "По периметру наружных стен силосных корпусов высотой до верха карниза более 10 м следует предусматривать на кровле решетчатые ограждения высотой не менее 0,6 м из негорючих материалов",
          "При проектировании силосов для сыпучих материалов, пыль которых способна образовывать взрывоопасные концентрации, следует предусматривать мероприятия по взрывозащите и защите от статического электричества",
        ],
        correctAnswers: [
          "При определении давления на грунт под подошвой фундамента силосов следует учитывать только нагрузку, возникающую при полной загрузке силосов сыпучими материалами",
        ],
      },
      {
        code: "21102144",
        text: "Какие нормы по проектированию наружных и располагаемых внутри зданий этажерок указаны неверно?",
        answers: [
          "По наружному периметру этажерок и площадок необходимо предусматривать ограждения высотой 1 м. Нижняя часть ограждения должна иметь сплошной борт высотой 0,14 м",
          "Площадь перекрытия этажерок должна обеспечивать проход шириной не менее 1 м при постоянном обслуживании оборудования",
          "Наружные этажерки следует рассчитывать на снеговую и ветровую нагрузки. На верхнем ярусе снеговую нагрузку надлежит учитывать полностью, а на промежуточных ярусах - в размере 50%",
          "Опирание площадок и лестниц на оборудование, являющееся источником вибрации, допускается в исключительных случаях",
        ],
        correctAnswers: [
          "Опирание площадок и лестниц на оборудование, являющееся источником вибрации, допускается в исключительных случаях",
        ],
      },
      {
        code: "21102145",
        text: "На какие из перечисленных нагрузок следует рассчитывать пролетные строения и опоры конвейерных и пешеходных галерей и эстакад?",
        answers: [
          "Только на нагрузки от атмосферных воздействий",
          "Только на продольные нагрузки, передающиеся от ленточных конвейеров, и на динамические, создаваемые подвижными частями конвейера",
          "Только на нагрузки, возникающие от веса транспортируемого на ленте груза, веса просыпи, ремонтных материалов и людей",
          "На все перечисленные нагрузки, включая вертикальные нагрузки от собственного веса галерей и конвейера",
        ],
        correctAnswers: [
          "На все перечисленные нагрузки, включая вертикальные нагрузки от собственного веса галерей и конвейера",
        ],
      },
      {
        code: "21102146",
        text: "Какие требования к проектированию эстакад под железную дорогу колеи 1520 мм не соответствуют Своду правил?",
        answers: [
          "Эстакады высотой до 3 м следует, как правило, проектировать из железобетонных конструкций или бетонных блоков, располагаемых с обеих сторон железнодорожного пути и связанных между собой, с заполнением пространства между ними утрамбованным дренирующим материалом",
          "При обращении и разгрузке на эстакаде вагонов-самосвалов дополнительно следует производить расчет на нагрузку от вагонов-самосвалов в момент разгрузки, принимая нормативное значение вертикального давления на упорный рельс 70%",
          "Нормативную временную вертикальную нагрузку следует принимать по СП 35.13330, но не менее СК = 14. Нормативную горизонтальную поперечную нагрузку от ударов подвижного состава следует определять в зависимости от расчетной скорости движения по эстакаде",
        ],
        correctAnswers: [
          "При обращении и разгрузке на эстакаде вагонов-самосвалов дополнительно следует производить расчет на нагрузку от вагонов-самосвалов в момент разгрузки, принимая нормативное значение вертикального давления на упорный рельс 70%",
        ],
      },
      {
        code: "21102147",
        text: "Какие требования предъявляются к выходам из конвейерных, коммуникационных (кроме кабельных) тоннелей?",
        answers: [
          "Выходы должны предусматриваться не реже чем через 30 м, но не менее двух",
          "Выходы должны предусматриваться не реже чем через 60 м, но не менее двух",
          "Выходы должны предусматриваться не реже чем через 100 м, но не менее двух",
          "Выходы должны предусматриваться не реже чем через 150 м",
        ],
        correctAnswers: [
          "Выходы должны предусматриваться не реже чем через 100 м, но не менее двух",
        ],
      },
      {
        code: "21102148",
        text: "Какое количество буровых скважин закладывают при опускных колодцах диаметром более 10-15 м?",
        answers: [
          "Не менее 4 буровых скважин",
          "Не менее 2 буровых скважин",
          "Не менее 3 буровых скважин",
          "1 буровую скважину",
        ],
        correctAnswers: ["Не менее 3 буровых скважин"],
      },
      {
        code: "21102149",
        text: "Что допускается размещать при проектировании многорядных силосных корпусов с круглыми в плане силосами в пространстве между ними (звездочках)?",
        answers: [
          "Установки для хранения связных сыпучих материалов",
          "Установки технологического оборудования, требующего обслуживания",
          "Лестницы",
          "Площадки",
        ],
        correctAnswers: ["Лестницы"],
      },
      {
        code: "21102150",
        text: "В каком случае допускается выполнять бункер для хранения и перегрузки сыпучего материала без перекрытия, но с обязательным устройством сплошного ограждения?",
        answers: [
          "Не допускается ни в каком случае",
          "Если загрузка производится средствами из непрерывного транспорта (вагоны, машины, грейферы)",
          "Если производится загрузка несвязных сыпучих материалов",
          "Если производится загрузка связных сыпучих материалов",
        ],
        correctAnswers: [
          "Если загрузка производится средствами из непрерывного транспорта (вагоны, машины, грейферы)",
        ],
      },
      {
        code: "21102151",
        text: "Из какого материала следует выполнять подвалы производственного назначения при их устройстве в сложных гидрогеологических условиях строительной площадки, при больших нагрузках на пол цеха или при наличии разнообразных проемов в стенах и перекрытиях, а также при особых технологических требованиях?",
        answers: [
          "Из несущих железобетонных панелей",
          "Из материала определенного в проектной документации",
          "Из бетонных блоков",
          "Из монолитного железобетона",
        ],
        correctAnswers: ["Из монолитного железобетона"],
      },
      {
        code: "21102152",
        text: "В каком порядке следует производить расчет опускного колодца?",
        answers: [
          "Определять глубину колодца, наружные размеры (диаметр) колодца, толщину стенок оболочки, рассчитывать отдельные конструктивные элементы оболочки",
          "Рассчитывать отдельные конструктивные элементы оболочки, определять глубину колодца, наружные размеры (диаметр) колодца, толщину стенок оболочки",
          "Порядок не регламентируется",
          "Рассчитывать отдельные конструктивные элементы оболочки, глубину колодца, определять наружные размеры (диаметр) колодца",
        ],
        correctAnswers: [
          "Определять глубину колодца, наружные размеры (диаметр) колодца, толщину стенок оболочки, рассчитывать отдельные конструктивные элементы оболочки",
        ],
      },
      {
        code: "21102153",
        text: "Какой фундамент следует предусматривать, если расчетные деформации естественного основания силосов и силосных корпусов превышают предельные или не обеспечивается его устойчивость, а также при наличии просадочных грунтов и в других случаях при соответствующем технико-экономическом обосновании?",
        answers: [
          "Сборный фундамент",
          "Ленточный фундамент",
          "Кольцевой фундамент",
          "Свайный фундамент",
        ],
        correctAnswers: ["Свайный фундамент"],
      },
      {
        code: "21102154",
        text: "На проектирование каких сооружений распространяется СП 43.13330.2012. Свод правил. Сооружения промышленных предприятий? Выберите два правильных варианта ответа.",
        answers: [
          "На проектирование сооружений со сроком эксплуатации до 3 лет",
          "На проектирование емкостных сооружений для водоснабжения и канализации",
          "На проектирование емкостных сооружений для жидкостей и газов",
          "На проектирование сооружений специального назначения",
          "На проектирование сооружений промышленных предприятий, предназначенных для строительства в особых условиях",
        ],
        correctAnswers: [
          "На проектирование емкостных сооружений для жидкостей и газов",
          "На проектирование сооружений промышленных предприятий, предназначенных для строительства в особых условиях",
        ],
      },
      {
        code: "21102155",
        text: "Какое из перечисленных требований к устройству закромов для хранения сыпучих материалов указано неверно?",
        answers: [
          "При загрузке и выгрузке материалов грейферными кранами следует предусматривать буферный слой из хранимого материала внутри закромов толщиной не менее 0,3 м",
          "Стены закромов должны быть рассчитаны также на горизонтальное давление грунта с учетом временной нормативной нагрузки на поверхности земли интенсивностью не менее 20 кПа (2 тс/м²) при опорожненном закроме",
          "Полы закромов надлежит выполнять из камня грубого окола или грунтовыми",
          "Стены с внутренней стороны и сверху должны быть защищены деревянными брусьями",
        ],
        correctAnswers: [
          "Стены с внутренней стороны и сверху должны быть защищены деревянными брусьями",
        ],
      },
      {
        code: "21102156",
        text: "Какие характеристики определяются по расчетным схемам, учитывающим наличие наружных стен и днища опускных колодцев, при строительстве опускных колодцев? Выберите два правильных варианта ответа.",
        answers: [
          "Сдвиг по подошве при односторонней выемке грунта вблизи колодца (если она предусматривается проектом)",
          "Устойчивость формы цилиндрической оболочки колодцев, погружаемых в тиксотропной рубашке",
          "Всплытие колодца",
          "Погружение колодца",
        ],
        correctAnswers: [
          "Сдвиг по подошве при односторонней выемке грунта вблизи колодца (если она предусматривается проектом)",
          "Всплытие колодца",
        ],
      },
      {
        code: "21102157",
        text: "В каком случае допускается использовать пространство под баками водонапорных башен для размещения служебных и конторских помещений, складов, производственных помещений?",
        answers: [
          "В случае вместимости бака от 15 до 50 м³",
          "Не допускается ни в каком случае",
          "В случае применения сплошных конструкций опор (монолитный железобетон или кирпич), исключающих образование пыли, дыма и газовыделений",
          "В случае соответствующего технико-экономического обоснования",
        ],
        correctAnswers: [
          "В случае применения сплошных конструкций опор (монолитный железобетон или кирпич), исключающих образование пыли, дыма и газовыделений",
        ],
      },
      {
        code: "21102158",
        text: "Какое расстояние между температурно-усадочными швами следует принимать в монолитных и сборно-монолитных железобетонных конструкциях стен?",
        answers: [
          "Не более 3,5 м",
          "Не более 15 м",
          "Не более 20 м",
          "Не более 25 м",
        ],
        correctAnswers: ["Не более 25 м"],
      },
      {
        code: "21102159",
        text: "Какие из перечисленных расстояний при устройстве подпорных стен соответствуют СП 43.13330.2012. Свод правил. Сооружения промышленных предприятий?",
        answers: [
          "В местах, где возможно движение пешеходов, подпорные стены должны иметь ограждение высотой 1 м",
          "Минимальное расстояние от оси ближайшего железнодорожного пути до внутренней грани подпорной стены на прямых участках следует принимать не менее 1,5 м",
          "Высота подпорных стен для грузовых рамп автомобильного транспорта со стороны подъезда автомобилей должна быть равной 1,5 м от уровня поверхности проезжей части дорог или погрузочно-разгрузочной площадки",
          "При расположении автодорог вдоль подпорной стены у нее следует предусматривать тротуар шириной не менее 1 м с бортовым камнем высотой не менее 0,6 м",
        ],
        correctAnswers: [
          "В местах, где возможно движение пешеходов, подпорные стены должны иметь ограждение высотой 1 м",
        ],
      },
      {
        code: "21102160",
        text: "Какие из перечисленных материалов относятся к связным сыпучим материалам?",
        answers: [
          "Все ответы неверны",
          "Материалы с крупностью зерен 3 мм",
          "Песок с крупностью зерен до 2 мм и влажностью до 2%",
          "Щебень",
          "Галька",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21102161",
        text: "При какой минимальной нагрузке на пол не следует размещать подвалы производственного назначения в зданиях и сооружениях?",
        answers: [
          "100 кПа (10 тс/м²)",
          "200 кПа (20 тс/м²)",
          "150 кПа (15 тс/м²)",
          "50 кПа (5 тс/м²)",
        ],
        correctAnswers: ["100 кПа (10 тс/м²)"],
      },
      {
        code: "21102162",
        text: "Покрытия каких силосов допускается проектировать в виде оболочек? Выберите два правильных варианта ответа.",
        answers: [
          "Группы силосов, объединенных в силосный корпус",
          "Силосов диаметром более 12 м",
          "Отдельно стоящих круглых силосов при отсутствии надсилосного помещения",
          "Отдельно стоящих круглых силосов, надсилосные помещения которых спроектированы с применением облегченных стеновых ограждений",
        ],
        correctAnswers: [
          "Силосов диаметром более 12 м",
          "Отдельно стоящих круглых силосов при отсутствии надсилосного помещения",
        ],
      },
      {
        code: "21102163",
        text: "Какие требования к проектированию складских зданий зерноскладов указаны неверно?",
        answers: [
          "Площадь зданий зерноскладов между противопожарными стенами следует принимать по нормативным документам по пожарной безопасности, но не более 5000 м²",
          "Полы в складских зданиях следует проектировать, как правило, асфальтобетонными с толщиной покрытия 25 мм в зерноскладах и 50 мм - в складах тарных грузов. В покрытиях полов не допускается применение дегтей и дегтевых мастик",
          "Проекты зерноскладов должны содержать указания о нанесении на стены ярких линий и надписей, ограничивающих предельную высоту зерновой насыпи",
          "Вынос кровли (за наружную поверхность стен) для зерноскладов должен быть не менее 0,7 м",
          "Противокапиллярную гидроизоляцию несущих стен зданий зерноскладов следует предусматривать из цементного раствора состава 1 : 2 толщиной 20 мм",
        ],
        correctAnswers: [
          "Площадь зданий зерноскладов между противопожарными стенами следует принимать по нормативным документам по пожарной безопасности, но не более 5000 м²",
        ],
      },
      {
        code: "21102164",
        text: "С каким пределом огнестойкости должны проектироваться ограждающие конструкции лестничных клеток?",
        answers: [
          "Не менее RE 115",
          "Не менее RE 145",
          "Не менее RE 130",
          "Не менее RE 125",
        ],
        correctAnswers: ["Не менее RE 145"],
      },
      {
        code: "21102165",
        text: "Какие требования надлежит принимать при проектировании отдельно стоящих силосов и силосных корпусов?",
        answers: [
          "Все перечисленные",
          "Наружные диаметры круглых отдельно стоящих силосов, равные 6, 9, 12, 18 и 24 м",
          "Высоту стен силосов, подсилосных и надсилосных этажей кратную 0,6 м",
          "Сетки разбивочных осей, проходящих через центры сблокированных в силосные корпуса силосов, размером 3 x 3, 6 x 6, 9 x 9 и 12 x 12 м",
          "В силосных корпусах для хранения сырья и готовой продукции мукомольно-крупяных предприятий и комбикормовых заводов с двумя подсилосными этажами и более допускается принимать каркас по типу производственных зданий с сеткой колонн 6 x 3 м",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "21102166",
        text: "Какую минимальную площадь легкосбрасываемых конструкций следует принимать при отсутствии расчетных данных на 1 м³ взрывоопасного помещения?",
        answers: ["0,01 м²", "0,03 м²", "0,07 м²", "0,02 м²"],
        correctAnswers: ["0,03 м²"],
      },
      {
        code: "21102167",
        text: "На какой коэффициент умножается расчетная нагрузка от веса сыпучих материалов при расчете на сжатие нижней зоны стен силосов?",
        answers: ["0,4", "0,3", "0,25", "0,9", "0,6"],
        correctAnswers: ["0,9"],
      },
      {
        code: "21102168",
        text: "Какие требования к проектированию ограждений, расположенных внутри производственных зданий площадок, антресолей, приямков, на которых размещено технологическое оборудование, указаны верно?",
        answers: [
          "Ограждения следует проектировать высотой 0,5 м",
          "Ограждения следует проектировать алюминиевыми",
          "Ограждения должны быть сплошными на высоту не менее 150 мм от пола",
          "Ограждения следует проектировать высотой 2,0 м",
        ],
        correctAnswers: [
          "Ограждения должны быть сплошными на высоту не менее 150 мм от пола",
        ],
      },
      {
        code: "21102169",
        text: "Какой этажности допускается проектировать здания I и II степеней огнестойкости категории Б мукомольно-крупяной и комбикормовой промышленности?",
        answers: [
          "До 5 включительно",
          "Не регламентируется",
          "До 3 включительно",
          "До 8 включительно",
          "До 10 включительно",
        ],
        correctAnswers: ["До 8 включительно"],
      },
      {
        code: "21102170",
        text: "Какой должен быть минимальный зооветеринарный разрыв между предприятиями и ветеринарно-санитарными утилизационными заводами для городских поселений и других муниципальных образований?",
        answers: ["500 м", "1200 м", "160 м", "700 м", "1000 м"],
        correctAnswers: ["1000 м"],
      },
      {
        code: "21102171",
        text: "С каким покрытием следует проектировать автомобильные дороги на площадках мукомольно-крупяных и комбикормовых предприятий по санитарным условиям?",
        answers: [
          "Шлаковым",
          "Асфальтобетонным или бетонным",
          "Гравийным",
          "Щебеночным",
        ],
        correctAnswers: ["Асфальтобетонным или бетонным"],
      },
      {
        code: "21102172",
        text: "Какой следует принимать коэффициент надежности по нагрузке для давления сыпучих материалов на стены и днища силосов, бункеров и зерноскладов при расчете на прочность?",
        answers: ["2,4", "1,3", "1,2", "1,7"],
        correctAnswers: ["1,3"],
      },
      {
        code: "21102173",
        text: "Какое допускается максимальное содержание арматуры железобетонных колонн подсилосного этажа?",
        answers: ["3%", "10%", "5%", "15%"],
        correctAnswers: ["3%"],
      },
      {
        code: "21102174",
        text: "Каким образом следует проектировать лестничные марши для эвакуации не более 50 человек?",
        answers: [
          "Допускается принимать ширину лестничных маршей 0,7 м и уклон 1:1,5",
          "Допускается принимать ширину лестничных маршей 0,9 м и уклон 1:1,5",
          "Допускается принимать ширину лестничных маршей 1,0 м и уклон 1:1,1",
          "Допускается принимать ширину лестничных маршей 1,2 м и уклон 1:1,2",
        ],
        correctAnswers: [
          "Допускается принимать ширину лестничных маршей 0,9 м и уклон 1:1,5",
        ],
      },
      {
        code: "21102175",
        text: "Какое требование по оснащению производственных помещений лифтами указано неверно?",
        answers: [
          "В производственных зданиях предусматривается пассажирский лифт для постоянно работающих людей на этажах, расположенных выше 15 м от уровня входа в здание",
          "Тамбур-шлюзы могут устраиваться общими для двух помещений (при условии, что в помещении категории Б имеется второй эвакуационный выход)",
          "Грузовой лифт в производственных зданиях следует предусматривать при наличии требований технологии производства",
          "В производственных зданиях высотой более 15 м один из лифтов должен быть рассчитан на перевозку пожарных подразделений и отвечать требованиям регламента",
        ],
        correctAnswers: [
          "В производственных зданиях высотой более 15 м один из лифтов должен быть рассчитан на перевозку пожарных подразделений и отвечать требованиям регламента",
        ],
      },
      {
        code: "21102176",
        text: "Что запрещено при проектировании путей эвакуации зданий и сооружений по хранению и переработке зерна?",
        answers: [
          "Предусматривать не менее двух выходов из каждого производственного помещения",
          "Предусматривать в качестве второго эвакуационного выхода с третьего этажа зданий с помещениями категории В или Б и количеством работающих на каждом этаже более 15 человек наружную открытую лестницу",
          "Предусматривать один эвакуационный выход по незадымляемой лестничной клетке или наружной открытой лестнице 3-го типа в зданиях и сооружениях, где на этаже выше первого нет постоянных рабочих мест",
        ],
        correctAnswers: [
          "Предусматривать в качестве второго эвакуационного выхода с третьего этажа зданий с помещениями категории В или Б и количеством работающих на каждом этаже более 15 человек наружную открытую лестницу",
        ],
      },
      {
        code: "21102177",
        text: "Какими следует проектировать участки перекрытий производственных зданий с большим числом технологических отверстий?",
        answers: [
          "Только сборными",
          "Только сборно-монолитными",
          "Только монолитными",
          "Сборно-монолитными или монолитными",
        ],
        correctAnswers: ["Сборно-монолитными или монолитными"],
      },
      {
        code: "21102178",
        text: "При каком диаметре силосы следует проектировать отдельно стоящими?",
        answers: ["7 м", "10 м", "Более 12 м", "5 м", "8 м"],
        correctAnswers: ["Более 12 м"],
      },
      {
        code: "21102179",
        text: "Что из перечисленного должны содержать и предусматривать проекты силосов и силосных корпусов?",
        answers: [
          "Только указания по режиму первичной и эксплуатационной загрузок и разгрузки силосов",
          "Только указания по наблюдению за осадками этих сооружений",
          "Только установку осадочных марок",
          "Только установку реперов",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21102180",
        text: "Каким должно быть минимальное расстояние от верха насыпи зерна до низа несущих конструкций покрытия?",
        answers: ["1,2 м", "1,0 м", "0,8 м", "0,5 м", "0,05 м"],
        correctAnswers: ["0,5 м"],
      },
      {
        code: "21102181",
        text: "Какую наименьшую высоту компактной струи на уровне наивысшей точки следует принимать для тушения пожара рабочего здания элеватора высотой свыше 50 м от гидрантов с помощью насосов при расчетном расходе воды 5 л/с?",
        answers: ["10 м", "35 м", "5 м", "30 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "21102182",
        text: "Что запрещается при проектировании складов по хранению и переработке зерна?",
        answers: [
          "Располагать у торца зданий тарных складов на первом этаже зарядную станцию для аккумуляторных погрузчиков",
          "Принимать сетку колонн 6 х 6 м и высоту этажей 4,8 м для многоэтажных складов тарных грузов",
          "Применять перекрытия складов тарных грузов сборно-монолитными с устройством монолитного железобетонного слоя поверх сборных железобетонных плит",
          "Проектировать здания половохранилищ двухэтажными и располагать в них вспомогательные помещения",
        ],
        correctAnswers: [
          "Проектировать здания половохранилищ двухэтажными и располагать в них вспомогательные помещения",
        ],
      },
      {
        code: "21102183",
        text: "Какой крен допускается для фундаментных плит силосных корпусов с несколькими подсилосными этажами?",
        answers: [
          "Не более 0,002",
          "Не более 0,0025",
          "Не более 0,003",
          "Не более 0,004",
          "Не более 0,005",
        ],
        correctAnswers: ["Не более 0,002"],
      },
      {
        code: "21102184",
        text: "Какую температуру теплоносителя следует принимать для систем отопления и теплоснабжения воздухонагревателей приточных установок по условиям обеспечения пожарной безопасности помещений категорий А и Б?",
        answers: [
          "Не более 110 °С",
          "Не более 130 °С",
          "Не более 150 °С",
          "Не более 145 °С",
          "Не более 90 °С",
        ],
        correctAnswers: ["Не более 110 °С"],
      },
      {
        code: "21102185",
        text: "К какой категории по надежности электроснабжения следует относить электроприемники предприятий по хранению и переработке зерна?",
        answers: ["К I категории", "К II категории", "К III категории"],
        correctAnswers: ["К II категории"],
      },
      {
        code: "21102186",
        text: "Что следует предусматривать при проектировании искусственного освещения зданий и сооружений по хранению и переработке зерна?",
        answers: [
          "Все перечисленное",
          "Светильники с лампами накаливания для комбикормовых заводов и других зданий и помещений",
          "Применение энергосберегающих ламп и оборудования",
          "Светильники с люминесцентными и светодиодными лампами для производственных помещений мельниц, крупяных заводов и диспетчерских помещений",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21102187",
        text: 'Что в соответствии с СП 108.13330.2012 определяется как "саморазгружающееся емкостное сооружение с высотой вертикальной части, не превышающей полуторную величину диаметра или меньшего размера"?',
        answers: [
          "Элеватор",
          "Комбикормовое предприятие",
          "Полова",
          "Силос",
          "Бункер",
        ],
        correctAnswers: ["Силос"],
      },
      {
        code: "21102188",
        text: "В каком случае допускается размещать предприятия, здания и сооружения по хранению и переработке зерна в санитарно-защитной зоне предприятий, относимых по выделению производственных вредностей в окружающую среду к I и II классу?",
        answers: [
          "Только при согласовании с территориальным органом Федеральной службы по экологическому, технологическому и атомному надзору",
          "Не допускается ни в каком случае",
          "Допускается в любом случае",
          "Только при соответствующем обосновании в проектной документации",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "21102189",
        text: "К какой категории по сейсмическим свойствам относятся твердомерзлые грунты при температуре выше -2 °С при строительстве и эксплуатации по принципу I при скорости поперечных волн от 250 до 700 м/с?",
        answers: ["К I категории", "К II категории", "К III категории"],
        correctAnswers: ["К II категории"],
      },
      {
        code: "21102190",
        text: "В каком случае у зданий и сооружений антисейсмические швы допускается не устраивать?",
        answers: [
          "Если здание или сооружение имеет сложную форму в плане",
          "Если смежные участки здания или сооружения имеют перепады высоты 5 м и более",
          "Если смежные участки здания или сооружения имеют существенные отличия друг от друга по жесткости и (или) массе",
          "Если одноэтажные здания высотой до 10 м имеют расчетную сейсмичность 7 баллов",
        ],
        correctAnswers: [
          "Если одноэтажные здания высотой до 10 м имеют расчетную сейсмичность 7 баллов",
        ],
      },
      {
        code: "21102191",
        text: "Какое требование к проектированию оснований, фундаментов и стен подвалов в сейсмических районах указано неверно?",
        answers: [
          "В фундаментах и стенах подвалов из крупных блоков должна быть обеспечена перевязка кладки в каждом ряду, а также во всех углах и пересечениях на глубину не менее 1/2 высоты блока",
          "В зданиях при расчетной сейсмичности 9 баллов должна предусматриваться укладка в горизонтальные швы в углах и пересечениях стен подвалов арматурных сеток длиной 2 м с продольной арматурой общей площадью сечения не менее 1 см²",
          "По верху сборных ленточных фундаментов следует укладывать слой цементного раствора марки 200 толщиной не менее 40 мм и продольную арматуру диаметром 10 мм в количестве трех стержней при расчетной сейсмичности 7 баллов",
        ],
        correctAnswers: [
          "По верху сборных ленточных фундаментов следует укладывать слой цементного раствора марки 200 толщиной не менее 40 мм и продольную арматуру диаметром 10 мм в количестве трех стержней при расчетной сейсмичности 7 баллов",
        ],
      },
      {
        code: "21102192",
        text: "Что запрещено при устройстве лестниц производственных зданий и сооружений, проектируемых в сейсмических районах?",
        answers: [
          "Выполнять лестничные клетки в виде отдельно стоящих сооружений",
          "Заделывать в стены на глубину не менее 250 мм и заанкеривать междуэтажные лестничные площадки в каменных зданиях",
          "Выполнять лестницы с применением металлических или железобетонных косоуров с наборными ступенями",
          "Выполнять лестницы из монолитного железобетона",
        ],
        correctAnswers: [
          "Выполнять лестничные клетки в виде отдельно стоящих сооружений",
        ],
      },
      {
        code: "21102193",
        text: "К какой категории по сейсмическим свойствам относятся глинистые грунты с показателем консистенции IL больше 0,5?",
        answers: ["К I категории", "К II категории", "К III категории"],
        correctAnswers: ["К III категории"],
      },
      {
        code: "21102194",
        text: "При какой сейсмичности запрещаются выполнение кладки несущих, самонесущих стен, заполнение каркаса и перегородок, в том числе усиленных армированием или железобетонными включениями, из кирпича (камня, блоков)?",
        answers: [
          "При сейсмичности 6 баллов",
          "При сейсмичности 7 баллов",
          "При сейсмичности 8 баллов",
          "При сейсмичности 9 баллов",
          "При сейсмичности 5 баллов",
        ],
        correctAnswers: ["При сейсмичности 9 баллов"],
      },
      {
        code: "21102195",
        text: "На каких из перечисленных территорий не допускается размещать производственные объекты?",
        answers: [
          "Только в первом поясе зоны санитарной охраны подземных и наземных источников водоснабжения",
          "Только в зеленых зонах городов",
          "Только на землях особо охраняемых природных территорий",
          "На всех перечисленных, а также в районах развития опасных геологических и инженерно-геологических процессов",
        ],
        correctAnswers: [
          "На всех перечисленных, а также в районах развития опасных геологических и инженерно-геологических процессов",
        ],
      },
      {
        code: "21102196",
        text: "Какое должно быть минимальное расстояние по горизонтали (в свету) от газопроводов среднего давления (св. 0,005 до 0,3 МПа) до фундаментов зданий и сооружений?",
        answers: ["7 м", "4 м", "2 м", "3 м", "10 м"],
        correctAnswers: ["4 м"],
      },
      {
        code: "21102197",
        text: "Какой надлежит принимать ширину ворот автомобильных въездов на земельный участок производственного объекта?",
        answers: [
          "Не менее 5,0 м",
          "Не менее 4,5 м",
          "По наибольшей ширине применяемых автомобилей",
          "Не менее 3,5 м",
        ],
        correctAnswers: ["Не менее 4,5 м"],
      },
      {
        code: "21102198",
        text: "Какой надлежит принимать ширину ворот для железнодорожных въездов?",
        answers: [
          "Не менее 4,9 м",
          "Не менее 4,5 м",
          "Не менее 4,0 м",
          "Не менее 5,0 м",
        ],
        correctAnswers: ["Не менее 4,9 м"],
      },
      {
        code: "21102199",
        text: "На сколько уровень полов первого этажа зданий должен быть выше планировочной отметки примыкающих к зданиям участков?",
        answers: [
          "Не менее чем на 15 см",
          "Не менее чем на 12 см",
          "Не менее чем на 10 см",
          "Не менее чем на 8 см",
        ],
        correctAnswers: ["Не менее чем на 15 см"],
      },
      {
        code: "21102200",
        text: "Какое должно быть минимальное расстояние по горизонтали (в свету) от канализации до водопровода из железобетонных труб, прокладываемых в глинистых грунтах?",
        answers: ["1,5 м", "6 м", "8 м", "5 м", "25 м"],
        correctAnswers: ["5 м"],
      },
      {
        code: "21102201",
        text: "На сколько отметка пола подвальных помещений должна быть выше уровня грунтовых вод?",
        answers: [
          "Не менее чем на 0,5 м",
          "Не менее чем на 0,4 м",
          "Не менее чем на 0,3 м",
          "Не менее чем на 0,05 м",
        ],
        correctAnswers: ["Не менее чем на 0,5 м"],
      },
      {
        code: "21102202",
        text: "Каким должно быть расстояние между трубопроводами и силовыми кабелями напряжением до 35 кВ и кабелями связи?",
        answers: ["0,9 м", "0,5 м", "0,6 м", "0,3 м", "1,1 м"],
        correctAnswers: ["0,5 м"],
      },
      {
        code: "21102203",
        text: "Под каким углом должны предусматриваться пересечения трубопроводов с железнодорожными путями и автодорогами?",
        answers: [
          "Под углом 90°",
          "Под углом 65°",
          "Под углом 45°",
          "Под углом 50°",
        ],
        correctAnswers: ["Под углом 90°"],
      },
      {
        code: "21102204",
        text: "На каком расстоянии от кромки полотна автомобильной дороги должен выходить на поверхность кабель в случае перехода кабельной линии, непосредственно прокладываемой в земле, в воздушную линию?",
        answers: [
          "Не менее 3,5 м",
          "Не менее 3,1 м",
          "Не менее 2,2 м",
          "Не менее 1,7 м",
        ],
        correctAnswers: ["Не менее 3,5 м"],
      },
      {
        code: "21102205",
        text: "Под каким углом должны предусматриваться пересечения кабельных эстакад с воздушными линиями электропередачи?",
        answers: [
          "В зависимости от конструкции эстакад, но не более 45°",
          "Пересечение надземных коммуникаций не допускается",
          "Под углом не менее 30°",
          "Под углом не менее 20°",
        ],
        correctAnswers: ["Под углом не менее 30°"],
      },
      {
        code: "21102206",
        text: "Под каким углом должны предусматриваться пересечения кабельных эстакад с внутризаводскими железными и автомобильными дорогами?",
        answers: [
          "Под углом не менее 75° к оси пути",
          "При соответствующем обосновании - под углом 45°",
          "Под углом не менее 30°",
          "Под углом не менее 25°",
        ],
        correctAnswers: ["Под углом не менее 30°"],
      },
      {
        code: "21102207",
        text: "Какое максимальное расстояние должно быть от проходных пунктов до входов в санитарно-бытовые помещения основных цехов?",
        answers: ["800 м", "550 м", "1300 м", "700 м"],
        correctAnswers: ["800 м"],
      },
      {
        code: "21102208",
        text: "В каких местах следует размещать надземные коммуникации?",
        answers: [
          "На эстакадах",
          "В галереях",
          "На стенах зданий и сооружений",
          "Во всех перечисленных",
          "На опорах",
        ],
        correctAnswers: ["Во всех перечисленных"],
      },
      {
        code: "21102209",
        text: "В каких местах допускается размещать надземные коммуникации?",
        answers: [
          "Транзитные наружные трубопроводы с легковоспламеняющимися и горючими жидкостями и газами прокладываются по эстакадам, отдельно стоящим колоннам и опорам из горючих материалов",
          "Все ответы неверны",
          "Во всех перечисленных местах",
          "Трубопроводы с горючими жидкими и газообразными продуктами размещаются в галереях, если смешение продуктов может вызвать взрыв или пожар",
          "Газопроводы горючих газов размещаются по территории складов легковоспламеняющихся и горючих жидкостей и материалов",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21102210",
        text: "Какую высоту от уровня земли до низа труб (или поверхности их изоляции), прокладываемых на низких опорах на свободной территории вне проезда транспортных средств и прохода людей, следует принимать при ширине группы труб от 1,5 м и более?",
        answers: ["0,2 м", "0,5 м", "0,6 м", "0,1 м", "1,2 м"],
        correctAnswers: ["0,5 м"],
      },
      {
        code: "21102211",
        text: "Какие из перечисленных мероприятий необходимо предусматривать при разработке планировочной организации земельных участков производственных объектов?",
        answers: [
          "Все перечисленные мероприятия, включая восстановление (рекультивацию) отведенных во временное пользование земель, нарушенных при строительстве",
          "Только рациональные производственные, транспортные и инженерные связи на объектах и между ними",
          "Только защиту прилегающих территорий от эрозии, заболачивания, засоления и загрязнения подземных вод и открытых водоемов сточными водами, отходами и отбросами предприятий",
          "Только функционально-технологическое зонирование земельного участка с учетом технологических связей, санитарно-гигиенических и противопожарных требований, грузооборота и видов транспорта",
        ],
        correctAnswers: [
          "Все перечисленные мероприятия, включая восстановление (рекультивацию) отведенных во временное пользование земель, нарушенных при строительстве",
        ],
      },
      {
        code: "21102212",
        text: "Какой орган согласовывает размещение промышленных объектов на территориях залегания полезных ископаемых?",
        answers: [
          "Ростехнадзор",
          "Минэнерго России",
          "Росприроднадзор",
          "Роснедра",
          "Роспотребнадзор",
        ],
        correctAnswers: ["Ростехнадзор"],
      },
      {
        code: "21102213",
        text: "В каких случаях допускается применять здания, образующие полузамкнутые дворы?",
        answers: [
          "В случае расположения зданий перпендикулярно или под углом не менее 30° к преобладающему направлению ветров летнего периода года",
          "Во всех перечисленных случаях",
          "В тех случаях, когда другое планировочное решение не может быть принято по условиям технологии либо по условиям реконструкции",
          "В случае отсутствия вредных производственных выделений",
        ],
        correctAnswers: [
          "В тех случаях, когда другое планировочное решение не может быть принято по условиям технологии либо по условиям реконструкции",
        ],
      },
      {
        code: "21102214",
        text: "С учетом соблюдения каких требований следует, по возможности, размещать здания и сооружения производственных объектов исходя из специфики производства и природных условий?",
        answers: [
          "В районах массового переноса песка ветрами наиболее низкие здания необходимо располагать с наветренной стороны площадки перпендикулярно потоку переносимого песка, а также предусматривать полосы зеленых насаждений (шириной не менее 5 м)",
          "Продольные оси аэрационных фонарей и стены зданий с проемами, используемыми для аэрации помещений, следует ориентировать в плане параллельно или под углом не менее 30° к преобладающему направлению ветров летнего периода года",
          "Продольные оси здания и световых фонарей следует ориентировать в пределах от 10 до 45° к меридиану",
          "В районах со снеговым покровом более 50 см или с количеством переносимого снега более 200 м³ на 1 м фронта переноса в год следует предусматривать сквозное проветривание площадки предприятия",
        ],
        correctAnswers: [
          "В районах со снеговым покровом более 50 см или с количеством переносимого снега более 200 м³ на 1 м фронта переноса в год следует предусматривать сквозное проветривание площадки предприятия",
        ],
      },
      {
        code: "21102215",
        text: "Какие трубопроводы допускается размещать в открытых траншеях и лотках?",
        answers: [
          "Трубопроводы для горючих газов",
          "Все ответы неверны",
          "Трубопроводы, по которым транспортируются кислоты и щелочи",
          "Трубопроводы бытовой канализации",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21102216",
        text: "Какие требования к размещению надземных коммуникаций указаны верно?",
        answers: [
          "В случае если смешение продуктов может вызвать взрыв или пожар, трубопроводы с горючими жидкими и газообразными продуктами следует размещать в галереях",
          "Транзитные наружные трубопроводы с легковоспламеняющимися и горючими жидкостями и газами допускается размещать по эстакадам",
          "Трубопроводы с легковоспламеняющимися и горючими жидкостями и газами следует  размещать по покрытиям и стенам зданий категорий А и Б по взрывопожароопасности",
          "Не допускается размещение газопроводов горючих газов по территории складов легковоспламеняющихся и горючих жидкостей и материалов",
        ],
        correctAnswers: [
          "Не допускается размещение газопроводов горючих газов по территории складов легковоспламеняющихся и горючих жидкостей и материалов",
        ],
      },
      {
        code: "21102217",
        text: "Какое минимальное количество выездов должно иметь на объектах с земельным участком более 5 га?",
        answers: ["2", "4", "1", "3"],
        correctAnswers: ["2"],
      },
      {
        code: "21102218",
        text: "В каких местах допускается размещение промышленных объектов и их групп?",
        answers: [
          "В зеленых зонах городов",
          "На землях особо охраняемых природных территорий, в т. ч. заповедников и их охранных зон",
          "В зонах возможного затопления на глубину от 0,5 до 1,2 м",
          "В первом поясе зоны санитарной охраны подземных и наземных источников водоснабжения",
        ],
        correctAnswers: [
          "В зонах возможного затопления на глубину от 0,5 до 1,2 м",
        ],
      },
      {
        code: "21102219",
        text: "Какое должно быть минимальное расстояние по горизонтали (в свету) от водопроводов и напорной канализации до фундаментов зданий и сооружений?",
        answers: ["7 м", "5 м", "4 м", "3 м", "2 м"],
        correctAnswers: ["5 м"],
      },
      {
        code: "21102220",
        text: "Какую высоту следует принимать от уровня земли до низа труб или поверхности изоляции, прокладываемых на высоких опорах, в непроезжей части территории, в местах прохода людей?",
        answers: ["2,2 м", "3 м", "5,5 м", "8 м", "7 м"],
        correctAnswers: ["2,2 м"],
      },
      {
        code: "21102221",
        text: "Какое минимальное расстояние по горизонтали (в свету) от кабеля до крайнего провода должно быть при прокладке кабельной линии параллельно высоковольтной линии (ВЛ) напряжением 110 кВ и выше?",
        answers: ["5 м", "10 м", "8 м", "12 м", "25 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "21102222",
        text: "В каких районах не следует размещать промышленные объекты с источниками загрязнения атмосферного воздуха вредными веществами 1-го и 2-го классов опасности?",
        answers: [
          "В районах с повторяющимися туманами частотой 25% за год",
          "В районах с относительной среднегодовой влажностью воздуха более 70%",
          "В районах с повторяющимися штилями частотой 20% за год",
          "В районах с преобладающими ветрами со скоростью до 1 м/с",
        ],
        correctAnswers: [
          "В районах с преобладающими ветрами со скоростью до 1 м/с",
        ],
      },
      {
        code: "21102223",
        text: "Какие функционально-технологические зоны следует выделять при разработке планировочной организации земельных участков производственных объектов?",
        answers: [
          "Входную",
          "Все перечисленные",
          "Складскую",
          "Производственную, включая зоны исследовательского назначения и опытных производств",
          "Подсобную",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "21102224",
        text: "Что должна предусматривать схема транспорта, разрабатываемая в составе проекта, планировочной организации земельного участка объекта, группы объектов?",
        answers: [
          "Возможность последующего развития схемы внешнего транспорта",
          "Использование сооружений и устройств, проектируемых для других целей (дамб водохранилищ и плотин, водопропускных сооружений), под земляное полотно и искусственные сооружения железных и автомобильных дорог",
          "Максимальное совмещение транспортных сооружений и устройств для различных видов транспорта (совмещенные автомобильные и железнодорожные или автомобильные и трамвайные мосты и путепроводы, общее земляное полотно для автомобильных дорог и трамвайных путей, кроме скоростных, и др.)",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21102225",
        text: "В каких местах допускается размещение отдельно стоящих зданий или сооружений?",
        answers: [
          "В замкнутых дворах",
          "Все ответы неверны",
          "В полузамкнутых дворах, при этом расстояние от этих сооружений до зданий должно удовлетворять требованиям, предъявляемым к устройству замкнутых дворов",
          "В замкнутых и полузамкнутых дворах",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21102226",
        text: "Какие требования к размещению инженерных коммуникаций указаны неверно?",
        answers: [
          "Размещение коммуникаций с легковоспламеняющимися и горючими жидкостями и газами под зданиями и сооружениями не допускается",
          "Допускается совместное подземное размещение трубопроводов оборотного водоснабжения, тепловых сетей и газопроводов с технологическими трубопроводами, независимо от параметров теплоносителя и параметров среды в технологических трубопроводах",
          "Во входных зонах объектов следует предусматривать преимущественно подземное размещение инженерных коммуникаций",
          "На территории объектов следует предусматривать преимущественно подземный способ размещения инженерных коммуникаций",
        ],
        correctAnswers: [
          "На территории объектов следует предусматривать преимущественно подземный способ размещения инженерных коммуникаций",
        ],
      },
      {
        code: "21102227",
        text: "Что из перечисленного допускается прокладывать через бытовые, подсобные и административно-хозяйственные помещения, помещения пультов управления, электрораспределительных устройств и вентиляционных камер, через лестничные клетки и тамбур-шлюзы?",
        answers: [
          "Воздуховоды воздушного отопления",
          "Материалопроводы",
          "Самотечные трубы",
          "Ничего из перечисленного",
        ],
        correctAnswers: ["Ничего из перечисленного"],
      },
      {
        code: "21102228",
        text: "Что из перечисленного не допускается в шахтах для прокладки кабелей?",
        answers: [
          "Установка норий",
          "Проход самотечных труб",
          "Проход аспирационных воздуходувов",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21102229",
        text: "Какой должен быть объем производственного помещения на каждого работающего и площадь помещения?",
        answers: [
          "Не менее 15 м2",
          "Не менее 10 м2",
          "Не менее 9 м2",
          "Не менее 5 м2",
        ],
        correctAnswers: ["Не менее 15 м2"],
      },
      {
        code: "21102230",
        text: "Какая должна быть высота помещений от пола до низа выступающих конструкций перекрытия (покрытия)?",
        answers: [
          "Не менее 2,0 м",
          "Не менее 2,2 м",
          "Не менее 1,5 м",
          "Не менее 1,0 м",
        ],
        correctAnswers: ["Не менее 2,2 м"],
      },
      {
        code: "21102231",
        text: "Какое количество входов-выходов должны иметь галереи и площадки, имеющие длину более 20 м и расположенные на высоте свыше 2 м от уровня земли или пола помещения?",
        answers: [
          "Не менее 2 входов-выходов, устроенных 1 в начале и другой в конце галереи или площадки",
          "Не менее 4 входов-выходов, устроенных 2 в начале и 2 в конце галереи или площадки",
          "Не менее 2 входов-выходов, устроенных 1 в начале и другой в середине галереи или площадки",
          "Не менее 3 входов-выходов, устроенных 1 в начале, 1 в середине и 1 в конце галереи или площадки",
        ],
        correctAnswers: [
          "Не менее 2 входов-выходов, устроенных 1 в начале и другой в конце галереи или площадки",
        ],
      },
      {
        code: "21102232",
        text: "Какие материалы допускается использовать в качестве легкосбрасываемых конструкций при недостаточной площади остекления?",
        answers: [
          "Стальные, алюминиевые и асбестоцементные листы (шифер)",
          "Утеплитель",
          "Профильные металлические листы",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "21102233",
        text: "Чему должна быть равна площадь легкосбрасываемых конструкций при отсутствии расчетных данных?",
        answers: [
          "Не менее 0,050 м2 на 1 м3 объема помещений категории А и не менее 0,03 м2 на 1,0 м3 объема помещений категории Б",
          "Не менее 0,040 м2 на 1 м3 объема помещений категории А и не менее 0,02 м2 на 1,5 м3 объема помещений категории Б",
          "Не менее 0,035 м2 на 2 м3 объема помещений категории А и не менее 0,015 м2 на 3,0 м3 объема помещений категории Б",
          "Не менее 0,025 м2 на 1,5 м3 объема помещений категории А и не менее 0,01 м2 на 2,0 м3 объема помещений категории Б",
        ],
        correctAnswers: [
          "Не менее 0,050 м2 на 1 м3 объема помещений категории А и не менее 0,03 м2 на 1,0 м3 объема помещений категории Б",
        ],
      },
      {
        code: "21102234",
        text: "Какие размеры должна иметь вертикальная колонна в механизированных зерновых складах с плоскими полами?",
        answers: [
          "Должна иметь высоту 4000 мм, наружный диаметр 300 мм и шаг между кольцами 135 мм",
          "Должна иметь высоту 5400 мм, наружный диаметр 380 мм и шаг между кольцами 160 мм",
          "Должна иметь высоту 5500 мм, наружный диаметр 394 мм и шаг между кольцами 165 мм",
          "Должна иметь высоту 4500 мм, наружный диаметр 375 мм и шаг между кольцами 155 мм",
        ],
        correctAnswers: [
          "Должна иметь высоту 5500 мм, наружный диаметр 394 мм и шаг между кольцами 165 мм",
        ],
      },
      {
        code: "21102235",
        text: 'Через какие промежутки следует предусматривать установку кнопок "Стоп" вдоль подсилосных и надсилосных, нижних и верхних конвейеров, складов?',
        answers: [
          "Через каждые 10 м",
          "Через каждые 5 м",
          "Через каждые 15 м",
          "Через каждые 13 м",
        ],
        correctAnswers: ["Через каждые 10 м"],
      },
      {
        code: "21102236",
        text: "В каких из перечисленных конструкциях расстояние между температурно-усадочными швами следует принимать не более 10 м?",
        answers: [
          "В монолитных бетонных конструкциях при наличии конструктивного армирования",
          "В монолитных бутобетонных и бетонных подпорных стенах без конструктивного армирования",
          "В монолитных и сборно-монолитных железобетонных конструкциях стен",
          "В сборных железобетонных конструкциях",
        ],
        correctAnswers: [
          "В монолитных бутобетонных и бетонных подпорных стенах без конструктивного армирования",
        ],
      },
      {
        code: "21102237",
        text: "Какое углубление решеток люков силосов, бункеров и других устройств от поверхности пола помещения должно быть обосновано?",
        answers: ["Более 60 мм", "Более 80 мм", "Более 90 мм", "Более 70 мм"],
        correctAnswers: ["Более 60 мм"],
      },
      {
        code: "21102238",
        text: "На каком расстоянии от корпуса затворов размещаются люки в самотеках, по которым поступает и выводится продукт из шлюзовых затворов?",
        answers: [
          "На расстоянии не менее 250 мм",
          "На расстоянии не менее 200 мм",
          "На расстоянии не менее 100 мм",
          "На расстоянии не менее 150 мм",
        ],
        correctAnswers: ["На расстоянии не менее 250 мм"],
      },
      {
        code: "21102239",
        text: "Какой материал допускается использовать для опор (колонн) водонапорных башен?",
        answers: [
          "Монолитный железобетон",
          "Кирпичную кладь",
          "Сталь",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "21102240",
        text: "Чем должна быть обоснована протяженность горизонтальных участков воздуховодов?",
        answers: [
          "Проектными решениями",
          "Внутренними распорядительными документами",
          "Инструкциями",
          "Техническим регламентом",
        ],
        correctAnswers: ["Проектными решениями"],
      },
      {
        code: "21102241",
        text: "Через какие перечисленные помещения допускается прокладка транзитных воздуховодов?",
        answers: [
          "Складов сырья",
          "Складов готовой продукции",
          "Помещения разных категорий",
          "Ничего из перечисленного",
        ],
        correctAnswers: ["Ничего из перечисленного"],
      },
    ],
    21103: [
      {
        code: "21103000",
        text: "Что должно быть графически отражено в технологической схеме производства?",
        answers: [
          "Только технологическое, аспирационное, транспортное оборудование с указанием моделей и их основных характеристик",
          "Только движение сырья, готовой продукции",
          "Только средства взрывопредупреждения, взрывозащиты и другие технические средства, обеспечивающие блокировку, контроль и противоаварийную защиту",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21103001",
        text: "В каком случае может осуществляться внесение изменений в технологический регламент и схемы размещения оборудования, средств дистанционного автоматизированного управления, блокировки, контроля и противоаварийной защиты, производственной и аварийной сигнализации, оповещения об аварийных ситуациях?",
        answers: [
          "Только после согласования с руководителем территориального органа Федеральной службы по экологическому, технологическому и атомному надзору",
          "Только после согласования с проектной и экспертными организациями",
          "Только после внесения изменений в документацию на техническое перевооружение объекта при наличии положительного заключения экспертизы промышленной безопасности разработанной документации",
          "Только после принятия совместного решения по итогам совещания с представителями проектной организации, экспертных организаций, эксплуатирующей организации и представителями территориального органа Федеральной службы по экологическому, технологическому и атомному надзору",
        ],
        correctAnswers: [
          "Только после внесения изменений в документацию на техническое перевооружение объекта при наличии положительного заключения экспертизы промышленной безопасности разработанной документации",
        ],
      },
      {
        code: "21103002",
        text: "Что разрабатывает организация при наличии в технологическом оборудовании (силосных и (или) бункерных емкостях) опасных веществ (газов) или возможности их образования?",
        answers: [
          "Дополнительные правила ведения технологических процессов",
          "Мероприятия по дальнейшей безопасной эксплуатации объектов",
          "Меры защиты персонала от воздействия этих веществ при взрывах, пожарах и других авариях",
          "Меры по поддержанию надежного и безопасного уровня контроля и противоаварийной защиты, производственной и аварийной сигнализации",
        ],
        correctAnswers: [
          "Меры защиты персонала от воздействия этих веществ при взрывах, пожарах и других авариях",
        ],
      },
      {
        code: "21103003",
        text: "Что определяется внутренними распорядительными документами организации, эксплуатирующей взрывопожароопасные производственные объекты хранения и переработки растительного сырья?",
        answers: [
          "Только организация работ по поддержанию надежного и безопасного уровня эксплуатации и ремонта оборудования, средств дистанционного автоматизированного управления, блокировки",
          "Только организация работ по поддержанию надежного и безопасного уровня контроля и противоаварийной защиты, производственной и аварийной сигнализации, оповещения об аварийных ситуациях, средств связи, энергообеспечения, а также зданий и сооружений",
          "Только перечень и объем эксплуатационной, ремонтной и другой технической документации",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21103004",
        text: "В каком случае допускается эксплуатация оборудования и осуществление (ведение) технологических процессов с неисправными или отключенными средствами, обеспечивающими противоаварийную защиту объекта?",
        answers: [
          "Только в случае чрезвычайной ситуации",
          "Только в случае если присутствует наблюдающий",
          "Только с случае если получено разрешение ответственного за безопасное выполнение работ",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "21103005",
        text: "Кто несет ответственность за выполнение (соблюдение) мероприятий, предусмотренных актом-допуском, оформленным перед началом работ на территории эксплуатирующей объекты организации?",
        answers: [
          "Только заказчик",
          "Только руководители строительно-монтажной организации",
          "Руководители строительно-монтажной организации и заказчик",
          "Генеральный подрядчик",
        ],
        correctAnswers: [
          "Руководители строительно-монтажной организации и заказчик",
        ],
      },
      {
        code: "21103006",
        text: "Какой инструктаж проводится при выполнении разовых работ, работ по локализации и ликвидации последствий аварий, стихийных бедствий и работ, на которые оформляются наряд-допуск, разрешение или другие специальные документы?",
        answers: [
          "Первичный инструктаж на рабочем месте",
          "Вводный инструктаж",
          "Внеплановый инструктаж",
          "Целевой инструктаж",
        ],
        correctAnswers: ["Целевой инструктаж"],
      },
      {
        code: "21103007",
        text: "Кем утверждается наряд-допуск на производство работ повышенной опасности?",
        answers: [
          "Руководителем подразделения, где выполняются работы",
          "Должностным лицом эксплуатирующей организации, ответственным за промышленную безопасность (технический руководитель, главный инженер)",
          "Руководителем эксплуатирующей организации",
        ],
        correctAnswers: [
          "Должностным лицом эксплуатирующей организации, ответственным за промышленную безопасность (технический руководитель, главный инженер)",
        ],
      },
      {
        code: "21103008",
        text: "Что из перечисленного устанавливают на производственном оборудовании с целью защиты его от разрушения и обеспечения выброса (отвода) пламени и высокотемпературных продуктов взрывного горения пылевоздушной смеси в безопасную зону (за пределы помещений)?",
        answers: [
          "Взрыворазрядители",
          "Огнепреграждающие устройства",
          "Систему локализации взрывов",
          "Систему автоматического пожаротушения",
        ],
        correctAnswers: ["Взрыворазрядители"],
      },
      {
        code: "21103009",
        text: "На кого возлагается ответственность за техническое состояние, эксплуатацию и своевременный ремонт взрыворазрядителей?",
        answers: [
          "На должностное лицо, назначенное руководителем эксплуатирующей организации",
          "На технического руководителя эксплуатирующей организации",
          "На должностное лицо организации-изготовителя",
          "На представителя территориального органа Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
        ],
        correctAnswers: [
          "На должностное лицо, назначенное руководителем эксплуатирующей организации",
        ],
      },
      {
        code: "21103010",
        text: "Какое требование предъявляется к взрыворазрядителям после их установки?",
        answers: [
          "Все взрыворазрядители пломбируются, а шиберные и бандажные нумеруются",
          "Все взрыворазрядители пломбируются, а шиберные нумеруются",
          "Все взрыворазрядители нумеруются, а шиберные и бандажные пломбируются",
        ],
        correctAnswers: [
          "Все взрыворазрядители нумеруются, а шиберные и бандажные пломбируются",
        ],
      },
      {
        code: "21103011",
        text: "Какой документ должен оформляться на каждый установленный взрыворазрядитель?",
        answers: [
          "Паспорт",
          "Формуляр",
          "Операционная карта",
          "Технологическая инструкция",
        ],
        correctAnswers: ["Паспорт"],
      },
      {
        code: "21103012",
        text: "С какой периодичностью следует осуществлять контроль целостности мембран, герметизирующих прокладок, подвижности откидных клапанов и поворотных створок комбинированных взрыворазрядителей, отсутствия накоплений на мембранах и в отводящих трубопроводах пыли или продукта?",
        answers: [
          "Один раз в два года",
          "Один раз в квартал",
          "Два раза в год",
          "Один раз в месяц",
          "Один раз в неделю",
        ],
        correctAnswers: ["Один раз в месяц"],
      },
      {
        code: "21103013",
        text: "Как должны быть заземлены технологическое оборудование и продуктопроводы, расположенные во взрыво- и пожароопасных зонах всех классов?",
        answers: [
          "Не менее чем в четырех местах",
          "В одном месте",
          "Не менее чем в трех местах с выравниванием потенциалов до безопасных значений",
          "Не менее чем в двух местах с выравниванием потенциалов до безопасных значений",
        ],
        correctAnswers: [
          "Не менее чем в двух местах с выравниванием потенциалов до безопасных значений",
        ],
      },
      {
        code: "21103014",
        text: "Какое из перечисленных требований к электростатической искробезопасности технических устройств объектов хранения и переработки растительного сырья указано верно?",
        answers: [
          "Запрещается использование общего заземляющего устройства для защиты от статического электричества, первичных и вторичных воздействий молнии и защитного заземления электроустановок",
          "Все ответы неверны",
          "Во фланцевых соединениях допускается применение шайб, изготовленных из диэлектриков",
          "Фланцевые соединения на трубах, аппаратах, соединения крышек с корпусами, соединения на разбортовке требуют дополнительных устройств для создания непрерывной электрической цепи",
          "Во фланцевых соединениях допускается применение шайб, окрашенных неэлектропроводными красками",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21103015",
        text: "Где должны устанавливаться взрыворазрядители на подогревателях для взрывозащиты зерносушилок?",
        answers: [
          "На минимальном расстоянии от входа агента сушки",
          "На боковой стенке секций только в нижней части подогревателя",
          "На боковой стенке секций подогревателя со стороны, противоположной входу агента сушки",
          "На боковой стенке секций подогревателя со стороны входа агента сушки",
        ],
        correctAnswers: [
          "На боковой стенке секций подогревателя со стороны, противоположной входу агента сушки",
        ],
      },
      {
        code: "21103016",
        text: "Допускается ли эксплуатация неисправных и утративших целостность взрыворазрядителей и их конструктивных элементов?",
        answers: [
          "Допускается при согласовании с территориальным органом Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "Допускается только в светлое время суток",
          "Допускается только в темное время суток",
          "Не допускается",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "21103017",
        text: "В каком из перечисленных случаев не допускается включение электромагнитных сепараторов под напряжение?",
        answers: [
          "При отсутствии постоянного контроля за работой со стороны обслуживающего персонала",
          "При отсутствии защитных ограждений",
          "При сопротивлении изоляции обмоток не менее 0,5 МОм",
          "При отсутствии или неисправности световой сигнализации",
        ],
        correctAnswers: [
          "При отсутствии или неисправности световой сигнализации",
        ],
      },
      {
        code: "21103018",
        text: "Для каких сепараторов проход со стороны выпуска зерна должен быть шириной не менее 0,7 м?",
        answers: [
          "Для сепараторов с круговым вращением решет",
          "Для сепараторов с боковой выемкой решет",
          "Для сепараторов производительностью до 50 т/ч (при расчете на элеваторную очистку) с возвратно-поступательным движением решет",
          "Для всех сепараторов",
        ],
        correctAnswers: ["Для всех сепараторов"],
      },
      {
        code: "21103019",
        text: "При каком количестве напольных машин, имеющих тяговые аккумуляторные батареи, их заряжают как в отдельных помещениях с естественной вентиляцией, так и в общих невзрывопожароопасных производственных помещениях?",
        answers: ["До 12", "До 8", "До 10", "До 6"],
        correctAnswers: ["До 6"],
      },
      {
        code: "21103020",
        text: "Каким из перечисленных транспортов запрещается транспортирование отходов производства?",
        answers: [
          "Пневмотранспортом",
          "Цепным конвейером",
          "Винтовым конвейером",
          "Открытым ленточным конвейером",
        ],
        correctAnswers: ["Открытым ленточным конвейером"],
      },
      {
        code: "21103021",
        text: "Какие явления допускаются при эксплуатации металлических силосов?",
        answers: [
          "Все ответы неверны",
          "Подтеки на внутренних поверхностях стен",
          "Подтеки на днище",
          "Неплотности в зоне опоры стенок силосных емкостей на фундаменты",
          "Неплотности в местах крепления дефлекторов и термоподвесок",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21103022",
        text: "Что из перечисленного разрешается при эксплуатации технологического, аспирационного и транспортного оборудования?",
        answers: [
          "Заклеивание и забивание устраиваемых в крышках машин аспирационных щелей",
          "Пуск и работа машин, выделяющих пыль, с открытыми люками",
          "Ручной отбор проб зерна, продуктов размола, зерна и крупы из оборудования, имеющего в месте отбора или непосредственной близости движущиеся части",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21103023",
        text: "Какие из перечисленных расстояний при размещении оборудования объектов хранения и переработки растительного сырья указаны верно? Укажите все правильные ответы.",
        answers: [
          "Проходы у весового карусельного устройства для фасовки и упаковки продукции со всех сторон должны быть шириной не менее 2 м",
          "В топочных помещениях стационарных зерносушилок с топками, работающими на твердом топливе, проходы со стороны зольников должны быть не менее 1 м, а проход перед топкой - не менее 2,2 м",
          "Для создания условий обслуживания при ремонте от привода шнека гранулятора до стены должен быть проход шириной не менее 1,7 м и от охладителя со стороны привода разгрузочного устройства до стены - не менее 1,6 м",
          "При использовании жидкого или газообразного топлива расстояние от выступающих частей форсунок, газовых горелок или арматуры топок до стен или других частей здания, а также до оборудования на действующих зерносушилках должно быть не менее 1,2 м",
        ],
        correctAnswers: [
          "Проходы у весового карусельного устройства для фасовки и упаковки продукции со всех сторон должны быть шириной не менее 2 м",
          "Для создания условий обслуживания при ремонте от привода шнека гранулятора до стены должен быть проход шириной не менее 1,7 м и от охладителя со стороны привода разгрузочного устройства до стены - не менее 1,6 м",
        ],
      },
      {
        code: "21103024",
        text: "Что из перечисленного должно быть установлено между вентилятором и присоединяемыми к нему трубами?",
        answers: [
          "Гибкие патрубки (вставки), обвитые снаружи проволокой с шагом витков не более 100 мм",
          "Гибкие патрубки (вставки) из недиэлектрических материалов",
          "Металлическая сетка с размерами ячеек 20 х 20 мм",
          "Гибкие патрубки (вставки) из воздухонепроницаемого материала (резины, прорезиненной ткани, синтетической ткани с полимерным или иным, схожим по характеристикам, покрытием)",
          "Негибкие патрубки (вставки), обвитые снаружи проволокой с шагом витков не более 50 мм",
        ],
        correctAnswers: [
          "Гибкие патрубки (вставки) из воздухонепроницаемого материала (резины, прорезиненной ткани, синтетической ткани с полимерным или иным, схожим по характеристикам, покрытием)",
        ],
      },
      {
        code: "21103025",
        text: "В каком случае допускается размещать масляные, сухие, а также с негорючей жидкостью комплектные трансформаторные подстанции в общем помещении с распределительными устройствами?",
        answers: [
          "Только если установлена аварийная свето-звуковая сигнализация",
          "Допускается размещать в любом случае",
          "Только если предусмотрена приточно-вытяжная вентиляция",
          "Только если трансформаторы отделены перегородками",
        ],
        correctAnswers: ["Допускается размещать в любом случае"],
      },
      {
        code: "21103026",
        text: "Какими сетками должны быть закрыты открытые всасывающие отверстия при работе вентиляторов?",
        answers: [
          "С размером ячеек 25 х 25 мм",
          "С размером ячеек 20 х 20 мм",
          "С размером ячеек 15 х 15 мм",
          "С размером ячеек 35 х 35 мм",
        ],
        correctAnswers: ["С размером ячеек 20 х 20 мм"],
      },
      {
        code: "21103027",
        text: "Что должны иметь дверцы, смотровые лючки и выпускные устройства оборудования?",
        answers: [
          "Устройства, исключающие их случайное снятие или открывание",
          "Уплотнения, не пропускающие пыль",
          "Концевые выключатели",
          "Рукоятки, скобы и другие устройства для удобного и безопасного удержания их при снятии и установке",
        ],
        correctAnswers: ["Уплотнения, не пропускающие пыль"],
      },
      {
        code: "21103028",
        text: "При нагревании до какой максимальной температуры деталей, соприкасающихся с продукцией, допускается работа электромагнитных сепараторов?",
        answers: ["До 30 °C", "До 50 °C", "До 65 °C", "До 55 °C"],
        correctAnswers: ["До 50 °C"],
      },
      {
        code: "21103029",
        text: "Какая устанавливается максимальная длина каждого отводящего трубопровода от защищаемого оборудования до коллектора?",
        answers: ["3 м", "6 м", "5 м", "1,5 м"],
        correctAnswers: ["3 м"],
      },
      {
        code: "21103030",
        text: "Каким образом следует осуществлять досмотр порожних вагонов-зерновозов?",
        answers: [
          "Необходимо обязательно спускаться внутрь вагона",
          "Единолично или бригадой в составе не более двух человек",
          "Посредством их освещения через загрузочные люки аккумуляторными фонарями",
          "Бригадой в составе трех человек",
        ],
        correctAnswers: [
          "Посредством их освещения через загрузочные люки аккумуляторными фонарями",
        ],
      },
      {
        code: "21103031",
        text: "С учетом ограничений каких документов эксплуатирующей организацией в технологическом регламенте определяются сроки, в которые проверяется температура сырья? Выберите 2 варианта ответа.",
        answers: [
          "Правил безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья",
          "Технических регламентов",
          "Документов по стандартизации",
          "Технического паспорта взрывобезопасности опасного производственного объекта",
        ],
        correctAnswers: [
          "Технических регламентов",
          "Документов по стандартизации",
        ],
      },
      {
        code: "21103032",
        text: "Использование каких перечисленных инструментов допускается при производстве погрузочно-разгрузочных работ с зерном и другими сыпучими продуктами на железнодорожном транспорте?",
        answers: [
          "Использование вибраторов для удаления зависшего в вагоне продукта",
          "Использование рычагов для вращения штурвалов",
          "Использование удлинителей для фиксации крышки загрузочного люка",
          "Допускается использование всех перечисленных инструментов",
        ],
        correctAnswers: [
          "Использование вибраторов для удаления зависшего в вагоне продукта",
        ],
      },
      {
        code: "21103033",
        text: "На каких конвейерах должны быть предусмотрены устройства, предохраняющие конвейеры от переполнения короба продуктом?",
        answers: [
          "На закрытых ленточных конвейерах",
          "На цепных и винтовых конвейерах",
          "Только на винтовых конвейерах",
          "Только на цепных конвейерах",
        ],
        correctAnswers: ["На цепных и винтовых конвейерах"],
      },
      {
        code: "21103034",
        text: "Что из перечисленного допускается при использовании клиновых ремней технических устройств на объектах хранения и переработки растительного сырья?",
        answers: [
          "Работа привода с неполным комплектом клиновых ремней",
          "Одинаковое натяжение всех клиновых ремней в клиноременных передачах",
          "Замена отдельных клиновых ремней",
          "Применение ремней с профилем, не соответствующим профилю канавок шкивов",
        ],
        correctAnswers: [
          "Одинаковое натяжение всех клиновых ремней в клиноременных передачах",
        ],
      },
      {
        code: "21103035",
        text: "Какие требования безопасности при работе вальцедекового станка указаны неверно?",
        answers: [
          "Разрешается подхватывать руками посторонние предметы, попавшие в рабочую зону станка (между вальцом и декой), до полной остановки станка при соблюдении дополнительных мер безопасности",
          "Необходимо следить за уравновешенным ходом барабана вальцедекового станка. При возникновении стуков или неравномерного хода станок должен быть немедленно остановлен",
          "На выходе продуктов из зоны шелушения вальцедекового станка следует устанавливать отражатель, препятствующий разбрасыванию продукта",
          "Абразивные части вальцедекового станка (барабан и дека) должны быть прочными, не иметь выбоин и трещин и должны быть прочно скреплены с чугунными основаниями. Запрещается допускать в эксплуатацию вальцы и деки, имеющие трещины",
        ],
        correctAnswers: [
          "Разрешается подхватывать руками посторонние предметы, попавшие в рабочую зону станка (между вальцом и декой), до полной остановки станка при соблюдении дополнительных мер безопасности",
        ],
      },
      {
        code: "21103036",
        text: "Кем составляется перечень контролируемых параметров, определяющих взрывоопасность процесса в каждом конкретном случае?",
        answers: [
          "Комиссией эксплуатирующей организации",
          "Разработчиком процесса",
          "Организацией, осуществляющей экспертизу промышленной безопасности",
          "Техническим руководителем эксплуатирующей организации",
          "Организацией-изготовителем оборудования",
        ],
        correctAnswers: ["Разработчиком процесса"],
      },
      {
        code: "21103037",
        text: "Кем устанавливаются данные о сроке службы оборудования производственных объектов хранения и переработки растительного сырья?",
        answers: [
          "Организацией-изготовителем",
          "Эксплуатирующей организацией",
          "Организацией, осуществляющей экспертизу промышленной безопасности",
          "Организацией-изготовителем совместно с территориальным органом Ростехнадзора",
          "Проектной организацией",
        ],
        correctAnswers: ["Организацией-изготовителем"],
      },
      {
        code: "21103038",
        text: "Чего не должны допускать форма и расположение переходных патрубков для присоединения взрыворазрядителей при нормальном режиме работы оборудования?",
        answers: [
          "Только механического повреждения предохранительной мембраны из-за попадания на нее продукта",
          "Только накопления в них пыли",
          "Всего перечисленного",
          "Только накопления в них продукта",
        ],
        correctAnswers: ["Всего перечисленного"],
      },
      {
        code: "21103039",
        text: "Чем должны быть оборудованы нории, установленные снаружи зданий?",
        answers: [
          "Площадками с перилами высотой не менее 0,8 м и лестницами с поручнями высотой подъема не более 5 м и уклоном маршей 65°",
          "Площадками с перилами высотой не менее 1 м и лестницами с поручнями высотой подъема не более 6 м и уклоном маршей 60°",
          "Площадками с перилами высотой не менее 1,5 м и лестницами с поручнями высотой подъема не более 5 м и уклоном маршей 55°",
          "Площадками с перилами высотой не менее 1,2 м и лестницами с поручнями высотой подъема не более 7 м и уклоном маршей 60°",
        ],
        correctAnswers: [
          "Площадками с перилами высотой не менее 1 м и лестницами с поручнями высотой подъема не более 6 м и уклоном маршей 60°",
        ],
      },
      {
        code: "21103040",
        text: "Какие из перечисленных действий допускаются при эксплуатации объектов хранения и переработки растительного сырья?",
        answers: [
          "Хранение сырого зерна на складах силосного типа",
          "Все перечисленные действия",
          "Хранение влажного зерна в силосах элеватора",
          "Временное размещение сырого и влажного зерна в накопительных емкостях при условии разработки комплекса организационно-технических мер по обеспечению безопасности эксплуатации таких объектов",
          "Хранение сырого зерна в бункерах",
        ],
        correctAnswers: [
          "Временное размещение сырого и влажного зерна в накопительных емкостях при условии разработки комплекса организационно-технических мер по обеспечению безопасности эксплуатации таких объектов",
        ],
      },
      {
        code: "21103041",
        text: "На каком расстоянии от корпуса защищаемого оборудования необходимо устанавливать предохранительную мембрану или клапан?",
        answers: [
          "На максимально возможном расстоянии",
          "На расстоянии не менее 3 м",
          "На минимально возможном расстоянии",
          "На расстоянии не более 1,5 м",
          "Не регламентируется",
        ],
        correctAnswers: ["На минимально возможном расстоянии"],
      },
      {
        code: "21103042",
        text: "Какая часть конвейера должна ограждаться на высоту не менее 2 м от пола?",
        answers: [
          "Муфты",
          "Шкивы",
          "Концы валов",
          "Опорные ролики",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21103043",
        text: "Какие мероприятия должны быть предусмотрены при эксплуатации действующих норий в соответствии с требованиями промышленной безопасности?",
        answers: [
          "Необходимо обеспечить периодическую круглосуточную уборку пыли в производственных помещениях",
          "Необходимо провести внеплановый инструктаж и проверку знаний обслуживающего персонала",
          "Необходимо предусмотреть дополнительную аспирацию башмака нории",
          "Необходимо обеспечить натяжение и регулировку норийной ленты, исключающие возможность ее пробуксовки на барабане и задевание ленты и ковшей о норийные трубы, кожух головки и башмака",
        ],
        correctAnswers: [
          "Необходимо обеспечить натяжение и регулировку норийной ленты, исключающие возможность ее пробуксовки на барабане и задевание ленты и ковшей о норийные трубы, кожух головки и башмака",
        ],
      },
      {
        code: "21103044",
        text: "Какое должно быть расстояние от пола до монорельса?",
        answers: [
          "Не менее 3,6 м",
          "Не менее 3,2 м",
          "Не менее 2,4 м",
          "Не менее 5,5 м",
        ],
        correctAnswers: ["Не менее 3,2 м"],
      },
      {
        code: "21103045",
        text: "В какой таре допускается транспортирование горючих веществ?",
        answers: [
          "В металлических бидонах",
          "В металлических бочках",
          "В бутылях",
          "В металлических ведрах",
        ],
        correctAnswers: ["В металлических бочках"],
      },
      {
        code: "21103046",
        text: "Что из перечисленного необходимо предусматривать на цепных конвейерах?",
        answers: [
          "Установку независимых реле контроля скорости",
          "Установку устройства контроля схода цепи",
          "Установку взрыворазрядного устройства",
          "Установку устройства контроля обрыва цепи",
        ],
        correctAnswers: ["Установку устройства контроля обрыва цепи"],
      },
      {
        code: "21103047",
        text: "На площадках с каким уклоном допускается проведение погрузочно-разгрузочных работ регулярного перемещения передвижных транспортных механизмов (конвейеры, самоподаватели, электропогрузчики)?",
        answers: [
          "Не более 10°",
          "Не более 6°",
          "Все ответы неверны",
          "Не более 8°",
          "Не более 2°",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21103048",
        text: "На основании какой документации осуществляются периодические перемещения (перекачивания) шрота, жмыха и другого мучнистого сырья, склонного к самовозгоранию, из занимаемых ими емкостей в свободные?",
        answers: [
          "Планов-графиков, разработанных на основании допустимых сроков непрерывного хранения сырья, установленных организацией в технологическом регламенте",
          "Планов-графиков, разработанных на основании Правил безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья",
          "Планов-графиков, разработанных организацией и утвержденных в территориальных органах Ростехнадзора",
        ],
        correctAnswers: [
          "Планов-графиков, разработанных на основании допустимых сроков непрерывного хранения сырья, установленных организацией в технологическом регламенте",
        ],
      },
      {
        code: "21103049",
        text: "Какие из перечисленных сведений отражаются в технологическом регламенте, разрабатываемом на объекте хранения и переработки растительного сырья?",
        answers: [
          "Все перечисленные",
          "Описание технологического процесса производства",
          "Нормы расхода основных видов сырья",
          "Сведения о возможных инцидентах в работе и способах их ликвидации",
          "Характеристики производства, используемого в производстве сырья и выпускаемой (производимой) продукции",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "21103050",
        text: "При каком минимальном повышении содержания бензина в шроте следует немедленно поставить в известность руководство эксплуатирующей организации, открыть двери и люки вагонов для его проветривания?",
        answers: ["0,7 %", "0,1 %", "0,4 %", "0,6 %"],
        correctAnswers: ["0,1 %"],
      },
      {
        code: "21103051",
        text: "С какой периодичностью необходимо очищать и промывать содовым раствором трубу между компрессором и ресивером?",
        answers: [
          "Каждый год",
          "Каждые 6 месяцев",
          "Каждые 3 месяца",
          "Каждый месяц",
          "Каждые 10 дней",
        ],
        correctAnswers: ["Каждые 6 месяцев"],
      },
      {
        code: "21103052",
        text: "Что необходимо использовать в качестве отводящих трубопроводов взрыворазрядителей?",
        answers: [
          "Гибкие резиновые шланги",
          "Только керамические трубы",
          "Только медные металлополимерные трубы",
          "Стальные сварные трубы круглого сечения с толщиной стенок не менее 1 мм",
        ],
        correctAnswers: [
          "Стальные сварные трубы круглого сечения с толщиной стенок не менее 1 мм",
        ],
      },
      {
        code: "21103053",
        text: "В каком случае допускается подтягивание болтовых соединений, устранение неисправностей на движущихся частях оборудования без его полной остановки?",
        answers: [
          "Если обеспечена безопасность подхода к местам неисправностей",
          "Если осуществляется дистанционное управление",
          "Если обеспечен надзор во время проведения работ",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "21103054",
        text: "Какой должна быть минимальная площадь оконного стекла толщиной 5 мм, используемого в качестве легкосбрасываемой конструкции?",
        answers: ["1,5 м²", "1,25 м²", "0,9 м²", "1,3 м²"],
        correctAnswers: ["1,5 м²"],
      },
      {
        code: "21103055",
        text: "Какие узлы оборудования (шкивы, рабочие колеса вентиляторов со шкивами, щеточные и бичевые барабаны, вальцы со шкивами и шестернями, валы дисковых триеров с дисками, барабаны шлифовальных и полировочных машин) должны быть статически отбалансированы как в собранном виде, так и отдельными частями?",
        answers: [
          "Со скоростью вращения выше 2 м/с",
          "Со скоростью вращения выше 6 м/с",
          "Все узлы независимо от скорости вращения",
          "Со скоростью вращения выше 5 м/с",
        ],
        correctAnswers: ["Со скоростью вращения выше 5 м/с"],
      },
      {
        code: "21103056",
        text: "При нагревании магнитопровода до какой максимальной температуры допускается работа электромагнитных сепараторов?",
        answers: ["До 90 °C", "До 55 °C", "До 45 °C", "До 60 °C"],
        correctAnswers: ["До 60 °C"],
      },
      {
        code: "21103057",
        text: "Где следует устанавливать датчики подпора на нориях?",
        answers: [
          "В головке нории",
          "На приемном патрубке",
          "На нисходящей ветви на высоте 300 - 400 мм от башмака нории",
          "На восходящей ветви на высоте 300 - 400 мм от башмака нории",
        ],
        correctAnswers: [
          "На восходящей ветви на высоте 300 - 400 мм от башмака нории",
        ],
      },
      {
        code: "21103058",
        text: "Какое оборудование объектов хранения и переработки растительного сырья допускается устанавливать группами?",
        answers: [
          "Рассевы",
          "Сепараторы",
          "Вертикальные круглые щеточные машины",
          "Обоечные и моечные машины",
        ],
        correctAnswers: ["Вертикальные круглые щеточные машины"],
      },
      {
        code: "21103059",
        text: "По указанию какого лица с момента поступления информации об аварийной ситуации производятся выключение или включение электроэнергии, обеспечиваются бесперебойное действие связи, исправное состояние водопровода, бесперебойная работа необходимого электромеханического оборудования и подвижных транспортных средств?",
        answers: [
          "Главного энергетика",
          "Все ответы неверны",
          "Главного технолога",
          "Главного механика",
          "Технического руководителя организации",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21103060",
        text: "Какие из перечисленных требований предъявляются к вальцовым станкам?",
        answers: [
          "Должны иметь регулируемый зазор между вальцами в пределах от 1,0 до 3,0 мм",
          "Должны иметь световую сигнализацию холостого хода",
          "Должны иметь постоянный нерегулируемый зазор между вальцами",
          "Должны иметь звуковую сигнализацию холостого хода",
          "Должны иметь устройство останова при отсутствии продукта",
        ],
        correctAnswers: ["Должны иметь световую сигнализацию холостого хода"],
      },
      {
        code: "21103061",
        text: "Какой вид клапана устанавливается перед водомаслоотделителем?",
        answers: [
          "Запорный клапан",
          "Регулирующий клапан",
          "Пружинно-предохранительный клапан",
          "Обратный клапан",
        ],
        correctAnswers: ["Обратный клапан"],
      },
      {
        code: "21103062",
        text: "Какое из перечисленных требований к обустройству головок норий с целью их обслуживания указано неверно?",
        answers: [
          "Для подъема на площадки должны быть устроены стационарные лестницы с перилами шириной не менее 1,0 м",
          "Специальные площадки должны иметь перила высотой не менее 1,0 м",
          "Оси приводных барабанов, которые расположены на высоте от пола более 1,5 м, предусматривают специальные площадки",
          "Специальные площадки должны иметь зашивку внизу на 0,15 м с обеспечением проходов для обслуживания",
        ],
        correctAnswers: [
          "Для подъема на площадки должны быть устроены стационарные лестницы с перилами шириной не менее 1,0 м",
        ],
      },
      {
        code: "21103063",
        text: "С помощью какого приспособления осуществляются выемка из станка, перемещение и установка вальцов?",
        answers: [
          "С помощью крановых и других тележек",
          "С помощью любых перечисленных приспособлений",
          "С помощью талей",
          "С помощью монорельсовых путей",
        ],
        correctAnswers: ["С помощью любых перечисленных приспособлений"],
      },
      {
        code: "21103064",
        text: "В каком случае вальцовые станки могут быть установлены группами с учетом возможности проводить работы по смене рабочих валков на любом станке, не останавливая работы остальных станков в группе?",
        answers: [
          "Если в каждой группе будет не более пяти станков общей длиной вместе с электродвигателями не более 15 м",
          "Если в каждой группе будет не более четырех станков общей длиной вместе с электродвигателями не более 25 м",
          "Если в каждой группе будет не более семи станков общей длиной вместе с электродвигателями не более 30 м",
          "Если в каждой группе будет не более шести станков общей длиной вместе с электродвигателями не более 10 м",
        ],
        correctAnswers: [
          "Если в каждой группе будет не более пяти станков общей длиной вместе с электродвигателями не более 15 м",
        ],
      },
      {
        code: "21103065",
        text: "Перед какими из перечисленных машин должны быть установлены магнитная защита и устройства отбора посторонних предметов?",
        answers: [
          "Перед пальцевыми и штифтовыми измельчителями",
          "Перед обоечными машинами",
          "Перед дробилками",
          "Перед всеми перечисленными машинами",
        ],
        correctAnswers: ["Перед всеми перечисленными машинами"],
      },
      {
        code: "21103066",
        text: "Какое действие необходимо произвести в первую очередь при появлении стуков или других признаков неисправности в сепараторе?",
        answers: [
          "Оповестить ответственного за безопасное производство работ",
          "Вызвать ремонтную бригаду",
          "Немедленно остановить",
          "Попытаться устранить неполадки",
        ],
        correctAnswers: ["Немедленно остановить"],
      },
      {
        code: "21103067",
        text: " Какие конвейеры должны быть оснащены реле контроля скорости?",
        answers: [
          "Винтовые конвейеры длиной более 15 м",
          "Цепные конвейеры, установленные на подсилосных этажах деревянных элеваторов",
          "Стационарные ленточные конвейеры со скоростью движения ленты 1 м/с и более",
          "Цепные конвейеры со скоростью движения цепи 1 м/с и более",
        ],
        correctAnswers: [
          "Стационарные ленточные конвейеры со скоростью движения ленты 1 м/с и более",
        ],
      },
      {
        code: "21103068",
        text: "Какое требование к лазовым и загрузочным люкам силосов, бункеров указано неверно?",
        answers: [
          "Лазовые и загрузочные люки силосов, бункеров и других устройств, независимо от мест их расположения, помимо крышек должны иметь прочные металлические решетки с ячейками размером не более 250 x 75 мм. Решетки люков не рекомендуется углублять более чем на 60 мм от поверхности пола помещения",
          "Люки силосов, бункеров в перекрытиях производственных помещений должны закрываться крышками в уровень с полом",
          "Лазовые люки должны быть круглого сечения диаметром не менее 500 мм",
          "Лазовые люки должны закрываться на замок",
        ],
        correctAnswers: [
          "Лазовые люки должны быть круглого сечения диаметром не менее 500 мм",
        ],
      },
      {
        code: "21103069",
        text: "Какое из перечисленных требований к взрывопожароопасным производственным объектам хранения и переработки растительного сырья указано неверно?",
        answers: [
          "Устройство железнодорожных путей, переездов и переходов через них, а также организация и эксплуатация железнодорожного хозяйства организации должны соответствовать требованиям нормативных правовых актов в сфере транспорта",
          "К зданиям, сооружениям и строениям объектов должен быть обеспечен подъезд пожарных автомобилей, пожарные водоемы в ночное время должны иметь звуковой указатель",
          "Доступ на территорию организации, эксплуатирующей объекты, посторонним лицам запрещен",
          "Необходимые для производственных целей подземные резервуары, колодцы, пожарные водоемы должны быть закрыты или ограждены со всех сторон",
          "В организации, эксплуатирующей объекты, должно быть обеспечено исправное состояние отводов атмосферных осадков от зданий и сооружений к водостокам, дорог для транспорта, пожарных проездов, рельсовых путей (с соблюдением требуемых габаритов, допускаемых уклонов и радиусов закруглений), сетей наружного освещения, пешеходных дорожек, пожарных и хозяйственных водопроводов, а также ограждений территории объектов и организации",
        ],
        correctAnswers: [
          "К зданиям, сооружениям и строениям объектов должен быть обеспечен подъезд пожарных автомобилей, пожарные водоемы в ночное время должны иметь звуковой указатель",
        ],
      },
      {
        code: "21103070",
        text: "Чем должны быть снабжены части станков, машин, аппаратов, а также механизмы, требующие смазки?",
        answers: [
          "Автоматическими смазочными приборами или масленками с резервуарами достаточной вместительности, которые заполняются смазкой во время остановки этого оборудования",
          "Приспособлениями, исключающими возможность заклинивания",
          "Ремнедержателями",
          "Ворошителями или вибраторами",
        ],
        correctAnswers: [
          "Автоматическими смазочными приборами или масленками с резервуарами достаточной вместительности, которые заполняются смазкой во время остановки этого оборудования",
        ],
      },
      {
        code: "21103071",
        text: "Какие должны быть ограждения, расположенные внутри производственных зданий, площадок, антресолей, приямков, на которых размещено технологическое оборудование?",
        answers: [
          "Стальные сплошные ограждения высотой 1 м",
          "Стальные решетчатые ограждения высотой 1,5 м, при этом ограждения должны быть сплошными на высоту не менее 0,25 м от пола",
          "Стальные решетчатые ограждения высотой 1 м, при этом ограждения должны быть сплошными на высоту не менее 0,15 м от пола",
          "Дюралюминиевые сплошные ограждения высотой 1,5 м",
        ],
        correctAnswers: [
          "Стальные решетчатые ограждения высотой 1 м, при этом ограждения должны быть сплошными на высоту не менее 0,15 м от пола",
        ],
      },
      {
        code: "21103072",
        text: "Какой допустимый предел зазора между вальцами?",
        answers: [
          "От 0,1 до 0,9 мм",
          "От 0,1 до 1,0 мм",
          "От 0,05 до 1,25 мм",
          "От 0,15 до 2,5 мм",
        ],
        correctAnswers: ["От 0,1 до 1,0 мм"],
      },
      {
        code: "21103073",
        text: "Какой допускается перекос общих осей поверхностей цапф (диаметром 65 мм) мелющих вальцов в каждой половине вальцового станка?",
        answers: [
          "Не более 0,25 мм по длине 1000 мм",
          "Не более 0,35 мм по длине 1000 мм",
          "Не более 0,4 мм по длине 1000 мм",
          "Не более 1,5 мм по длине 1000 мм",
        ],
        correctAnswers: ["Не более 0,25 мм по длине 1000 мм"],
      },
      {
        code: "21103074",
        text: "Какой из перечисленных элементов не входит в состав ротационных воздуходувок?",
        answers: [
          "Обратный клапан",
          "Манометр",
          "Система смазки",
          "Глушитель",
          "Индивидуальный электропривод",
        ],
        correctAnswers: ["Обратный клапан"],
      },
      {
        code: "21103075",
        text: "Какое оборудование может быть расположено сторонами, не требующими обслуживания, у стен и колонн с разрывом от них не менее 0,25 м?",
        answers: [
          "Самотечный трубопровод",
          "Материало- и воздухопроводы",
          "Все перечисленное оборудование",
          "Норийные трубы",
        ],
        correctAnswers: ["Все перечисленное оборудование"],
      },
      {
        code: "21103076",
        text: "В каком случае не должна срабатывать автоматика безопасности горения, установленная на топках зерносушилок на жидком или газообразном топливе?",
        answers: [
          "При протекании топлива в топку при потухшем факеле с предварительным запуском вентилятора и продувкой топки для удаления застоявшихся паров топлива",
          "При зажигании топлива с предварительным запуском вентилятора и продувкой топки для удаления застоявшихся паров топлива",
          "При выбросе горячего топлива в предтопочное пространство с предварительным запуском вентилятора и продувкой топки для удаления застоявшихся паров топлива",
        ],
        correctAnswers: [
          "При зажигании топлива с предварительным запуском вентилятора и продувкой топки для удаления застоявшихся паров топлива",
        ],
      },
      {
        code: "21103077",
        text: "В каком из перечисленных случаев не допускается работа фильтров с механическим встряхиванием рукавов?",
        answers: [
          "Только при работе встряхивающего механизма со сниженным числом ударов",
          "При неисправном встряхивающем механизме либо при работе встряхивающего механизма со сниженным числом ударов",
          "Только при неисправном встряхивающем механизме",
        ],
        correctAnswers: [
          "При неисправном встряхивающем механизме либо при работе встряхивающего механизма со сниженным числом ударов",
        ],
      },
      {
        code: "21103078",
        text: "В каком случае необходимо пользоваться специальными скребками и щетками при обслуживании машин?",
        answers: [
          "При расчистке завалов в башмаках норий и конвейерах",
          "При прочистке зазора между заслонкой и питающим валком вальцового станка",
          "Во всех перечисленных случаях",
          "При очистке на холостом ходу верхних плоскостей решет сепараторов",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "21103079",
        text: "На каком минимальном расстоянии от сушилки допускается хранение топлива и смазочных материалов?",
        answers: ["35 м", "10 м", "5 м", "20 м"],
        correctAnswers: ["20 м"],
      },
      {
        code: "21103080",
        text: "Каким образом следует распределять взрыворазрядители на камерах нагрева при установке нескольких взрыворазрядителей?",
        answers: [
          "Равномерно по длине камеры",
          "Равномерно по высоте камеры",
          "Равномерно по ширине камеры",
          "В соответствии с указаниями лица, ответственного за безопасное производство работ",
          "Не регламентируется",
        ],
        correctAnswers: ["Равномерно по высоте камеры"],
      },
      {
        code: "21103081",
        text: "Что необходимо предпринять при отклонениях от нормальной работы оборудования (завал продукта, интенсивное пыление, повышенные вибрации и другие подобные причины)?",
        answers: [
          "Оповестить представителя территориального органа Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий об отклонениях и продолжить работу",
          "Остановить оборудование и незамедлительно покинуть помещение",
          "Остановить оборудование и незамедлительно проверить взрыворазрядители, при необходимости заменить разрывные предохранительные мембраны или другие поврежденные элементы конструкций",
          "Продолжить работу, сообщив ответственному специалисту об отклонениях",
        ],
        correctAnswers: [
          "Остановить оборудование и незамедлительно проверить взрыворазрядители, при необходимости заменить разрывные предохранительные мембраны или другие поврежденные элементы конструкций",
        ],
      },
      {
        code: "21103082",
        text: "В каком случае ширина проходов для обслуживания конвейеров может быть 0,7 м?",
        answers: [
          "Между цепными конвейерами",
          "Между параллельно установленными конвейерами",
          "Между ленточными конвейерами",
          "Между параллельно установленными конвейерами, закрытыми по всей трассе жесткими коробами или сетчатыми ограждениями",
        ],
        correctAnswers: [
          "Между параллельно установленными конвейерами, закрытыми по всей трассе жесткими коробами или сетчатыми ограждениями",
        ],
      },
      {
        code: "21103083",
        text: "Размещение помещений какой категории по взрывопожароопасности допускается в подвальных и цокольных этажах и при этом не требуется дополнительного обоснования в проектной документации при техническом перевооружении и реконструкции объектов?",
        answers: [
          "Категории А",
          "Категории Б",
          "Категории Г",
          "Допускается размещение помещений всех перечисленных категорий",
        ],
        correctAnswers: ["Категории Г"],
      },
      {
        code: "21103084",
        text: "Какой вентиляцией должны быть оборудованы помещения, где размещены пропариватели, сушилки, запарные и варочные аппараты?",
        answers: [
          "Общеобменной вентиляцией",
          "Естественной вентиляцией",
          "Вытяжной вентиляцией",
          "Приточной вентиляцией",
          "Аварийной вентиляцией",
        ],
        correctAnswers: ["Приточной вентиляцией"],
      },
      {
        code: "21103085",
        text: "Какой должна быть степень защиты оболочки переносных светильников прожекторного типа, использующихся для освещения бункеров и силосов сверху через люки?",
        answers: [
          "Не ниже 1P25",
          "Не ниже 1P60",
          "Не ниже 1P54",
          "Не ниже 1P40",
        ],
        correctAnswers: ["Не ниже 1P54"],
      },
      {
        code: "21103086",
        text: "Какие действия необходимо предпринять в случае превышения допустимой температуры заложенного на хранение растительного сырья, продуктов его переработки и комбикормового сырья, указанной для соответствующего вида сырья (продукта) в технологическом регламенте? Выберите все правильные варианты ответов.",
        answers: [
          "Периодически увлажнять сырье (продукт) до снижения температуры в допустимых пределах",
          "Производить перекачку сырья (продукта) из одного силоса (бункера) в другой или с площадки на площадку",
          "Обеспечить постоянный контакт сырья (продукта) с токопроводящей поверхностью стенки бункера",
          "Применить активное вентилирование, сушку",
        ],
        correctAnswers: [
          "Производить перекачку сырья (продукта) из одного силоса (бункера) в другой или с площадки на площадку",
          "Применить активное вентилирование, сушку",
        ],
      },
      {
        code: "21103087",
        text: "Каким из перечисленных способов должно производиться сращивание концов приводных ремней оборудования взрывопожароопасных производственных объектов хранения и переработки растительного сырья?",
        answers: [
          "Путем склеивания или сыромятными сшивками",
          "Всеми перечисленными способами",
          "При помощи стальных соединителей",
          "При помощи медных или алюминиевых соединителей",
          "Путем горячей вулканизации",
        ],
        correctAnswers: ["Путем склеивания или сыромятными сшивками"],
      },
      {
        code: "21103088",
        text: "Размещение помещений какой категории по взрывопожароопасности допускается в подвальных и цокольных этажах и при этом не требуется дополнительного обоснования в проектной документации?",
        answers: [
          "Не допускается размещение помещений всех перечисленных категорий",
          "Категории В1",
          "Категории А",
          "Категории Б",
        ],
        correctAnswers: ["Категории В1"],
      },
      {
        code: "21103089",
        text: "Какие работы допускается производить на ходу падди-машин?",
        answers: [
          "Все ответы неверны",
          "Устранять неравномерность хода",
          "Передвигать параллели при сработке ползуна",
          "Регулировать корпуса падди-машин, подвешенных на качалках",
          "Производить смазку",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21103090",
        text: "Какое из перечисленных требований к топкам, работающим на жидком и газообразном топливе, указано верно?",
        answers: [
          "На магистрали, подводящей жидкое или газообразное топливо, должен быть головной запорный вентиль, установленный у выхода из топочного помещения, на расстоянии не менее 3 м от топки",
          "Если жидкое или газообразное топливо при розжиге топки не загорается в течение 1 мин., система контроля и автоматики горения топлива должна отключить подачу его в форсунку",
          "Повторную подачу топлива в топку и розжиг его после устранения причины неисправности осуществляют только после проветривания топки в течение 30 минут",
          "Допускается оставлять работающую топку без присмотра в течение 10 минут",
        ],
        correctAnswers: [
          "На магистрали, подводящей жидкое или газообразное топливо, должен быть головной запорный вентиль, установленный у выхода из топочного помещения, на расстоянии не менее 3 м от топки",
        ],
      },
      {
        code: "21103091",
        text: "На какой высоте от пола устанавливаются смотровые люки для удобства наблюдения за ходом ленты в норийных трубах?",
        answers: ["1,2 м", "1,6 м", "1,4 м", "1,9 м"],
        correctAnswers: ["1,6 м"],
      },
      {
        code: "21103092",
        text: "В каком случае допускается применение в складских помещениях погрузчиков с двигателями внутреннего сгорания?",
        answers: [
          "Ни в каком случае",
          "Если реализуются мероприятия, снижающие запыление территории",
          "Если погрузчики оборудованы искрогасителями, находящимися в исправном состоянии",
          "Если их применение определяется внутренними распорядительными документами эксплуатирующей организации",
        ],
        correctAnswers: [
          "Если погрузчики оборудованы искрогасителями, находящимися в исправном состоянии",
        ],
      },
      {
        code: "21103093",
        text: "Какие из перечисленных документов разрабатываются (составляются) в целях обеспечения безопасности производственных процессов на объектах организации?",
        answers: [
          "Только документ, отражающий фактические данные о наличии и техническом состоянии средств взрывопредупреждения и взрывозащиты производственных зданий, сооружений и оборудования объектов (показатели, характеризующие взрывобезопасность и противоаварийную защиту объекта) (далее - технический паспорт взрывобезопасности опасного производственного объекта)",
          "Только технологические регламенты и схемы",
          "Только паспорта на аспирационные и пневмотранспортные установки",
          "Только паспорта на взрыворазрядные устройства",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "21103094",
        text: "Каким документом определяется порядок (очередность) проведения обследования объекта перед разработкой технического паспорта взрывобезопасности?",
        answers: [
          "Документами проектной организации",
          "Внутренними распорядительными документами эксплуатирующей объект организации",
          "Приказом Ростехнадзора",
          "Актом",
        ],
        correctAnswers: [
          "Внутренними распорядительными документами эксплуатирующей объект организации",
        ],
      },
      {
        code: "21103095",
        text: "Кто проводит обследование объекта перед разработкой технического паспорта взрывобезопасности?",
        answers: [
          "Комиссия, назначенная приказом эксплуатирующей организации",
          "Ростехнадзор",
          "Комиссия проектной организации",
          "Представители пусконаладочной организации",
        ],
        correctAnswers: [
          "Комиссия, назначенная приказом эксплуатирующей организации",
        ],
      },
      {
        code: "21103096",
        text: "Каким документом определяются лица, ответственные за выполнение плана мероприятий по доведению объекта до нормативных требований промышленной безопасности, своевременное внесение соответствующих дополнений (изменений)?",
        answers: [
          "Приказом Ростехнадзора",
          "Распоряжением проектной организации",
          "Внутренним распорядительным документом эксплуатирующей объект организации",
          "Актом по результатам экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "Внутренним распорядительным документом эксплуатирующей объект организации",
        ],
      },
      {
        code: "21103097",
        text: "Кем утверждается технический паспорт взрывобезопасности?",
        answers: [
          "Главным инженером",
          "Руководителем",
          "Техническим директором",
        ],
        correctAnswers: ["Руководителем"],
      },
      {
        code: "21103098",
        text: "В каких случаях могут применяться Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья?",
        answers: [
          "Только при разработке технологических процессов, разработке документации, эксплуатации, капитальном ремонте, техническом перевооружении, консервации и ликвидации объектов",
          "Только при изготовлении, монтаже, наладке, обслуживании и ремонте технических устройств, применяемых на объектах",
          "Только при разработке обоснования безопасности объектов",
          "Только при проведении экспертизы промышленной безопасности",
          "Во всех перечисленных случаях",
          "Только при подготовке и переподготовке работников объектов в необразовательных учреждениях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "21103099",
        text: "Что из перечисленного не отражается в технологической схеме производства графически?",
        answers: [
          "Технологическое, аспирационное, транспортное оборудование с указанием моделей и их основных характеристик",
          "Движение сырья, готовой продукции",
          "Сведения и возможных инцидентах в работе и способах их ликвидации",
          "Средства взрывопредупреждения, взрывозащиты и другие технические средства, обеспечивающие блокировку, контроль и противоаварийную защиту",
        ],
        correctAnswers: [
          "Сведения и возможных инцидентах в работе и способах их ликвидации",
        ],
      },
      {
        code: "21103100",
        text: "На основании чего в организации разрабатывается технологический регламент?",
        answers: [
          "На основании эксплуатационной документации",
          "На основании проектной документации",
          "На основании рекомендаций Ростехнадзора",
          "На основании распорядительных документов организации, эксплуатирующей объект",
        ],
        correctAnswers: ["На основании проектной документации"],
      },
      {
        code: "21103101",
        text: "Что из перечисленного не отражается в технологическом регламенте?",
        answers: [
          "Характеристики производства, используемого в производстве сырья и выпускаемой (производимой) продукции",
          "Сведения и возможных инцидентах в работе и способах их ликвидации",
          "Информация о продолжительности межремонтных циклов объекта",
          "Перечень обязательных инструкций, спецификации основного технологического оборудования (технических устройств), технологические схемы производства (графическая часть)",
          "Нормы расхода основных видов сырья",
          "Информация о контроле и управлении технологическим процессом",
        ],
        correctAnswers: [
          "Информация о продолжительности межремонтных циклов объекта",
        ],
      },
      {
        code: "21103102",
        text: "Что из перечисленного не относится к организационным и организационно-техническим мероприятиям, которые должны выполняться для обеспечения взрывобезопасности объекта?",
        answers: [
          "Обучение, инструктаж и проверку уровня знаний работников объектов",
          "Обеспечение работников средствами индивидуальной защиты",
          "Производственный контроль за соблюдением требований безопасности",
          "Разработка нормативно-технических документов и наглядной агитации",
          "Проведение капитального ремонта оборудования, ревизия трубопроводов",
          "Применение средств оповещения об аварийных ситуациях",
        ],
        correctAnswers: [
          "Проведение капитального ремонта оборудования, ревизия трубопроводов",
        ],
      },
      {
        code: "21103103",
        text: "Под руководством какого лица допускается проведение огневых работ, связанных с локализацией и ликвидацией последствий аварии на объектах?",
        answers: [
          "Под непосредственным руководством представителя пожарной службы",
          "Под непосредственным руководством лица, выдавшего наряд-допуск",
          "Под непосредственным руководством специалиста отдела охраны труда и промышленной безопасности",
          "Под непосредственным руководством руководителя организации",
        ],
        correctAnswers: [
          "Под непосредственным руководством лица, выдавшего наряд-допуск",
        ],
      },
      {
        code: "21103104",
        text: "Кто проводит проверку знания действий персонала в предаварийных и аварийных ситуациях?",
        answers: [
          "Представитель Ростехнадзора",
          "Квалификационная (экзаменационная) комиссия эксплуатирующей организации",
          "Непосредственный руководитель, начальник подразделения, производства",
          "Лицо, назначенное руководителем организации за общее руководство объектом в предаварийных и аварийных ситуациях",
        ],
        correctAnswers: [
          "Квалификационная (экзаменационная) комиссия эксплуатирующей организации",
        ],
      },
      {
        code: "21103105",
        text: "Что должно предусматривать взрывопредупреждение?",
        answers: [
          "Установку производственной и аварийной сигнализации",
          "Исключение условий образования взрывоопасной среды в производственных помещениях применением герметичного оборудования, рабочей вентиляции и аспирации, технических средств пылеподавления, контролем за отложениями пыли (обеспечение пылевого режима)",
          "Регламентирование условий хранения и соблюдение схемы размещения и правил хранения зерна, продуктов его переработки и комбикормового сырья, склонных к самосогреванию и самовозгоранию",
          "Применение средств автоматизированного дистанционного контроля температуры зерна, продуктов его переработки и комбикормового сырья, обеспечивающих обнаружение очага самосогревания на ранних стадиях",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21103106",
        text: "В соответствии с чем должен осуществляться выбор оборудования на объектах хранения и переработки растительного сырья?",
        answers: [
          "Только в соответствии с исходными данными на проектирование",
          "Только в соответствии с требованиями нормативных правовых актов в области промышленной безопасности",
          "Только в соответствии с правилами безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья",
          "В соответствии со всем перечисленным",
        ],
        correctAnswers: ["В соответствии со всем перечисленным"],
      },
      {
        code: "21103107",
        text: "Что из перечисленного допускается при эксплуатации технологического оборудования?",
        answers: [
          "Снимать или надевать приводные ремни на ходу, регулировать натяжение ремней или цепей машин во время их работы",
          "Подтягивание болтовых соединений, устранение неисправностей на движущихся частях разрешается выполнять только при полной остановке оборудования",
          "Вести регулярный надзор в целях своевременного устранения дефектов, которые могут вызвать увеличение шума или перегрев вращающихся деталей",
          "Расчищать от завалов, запрессованного продукта или от попавших предметов конвейеры, башмаки норий и другие машины во время их работы",
        ],
        correctAnswers: [
          "Вести регулярный надзор в целях своевременного устранения дефектов, которые могут вызвать увеличение шума или перегрев вращающихся деталей",
        ],
      },
      {
        code: "21103108",
        text: "Что из перечисленного необходимо проверить перед пуском смесителя?",
        answers: [
          "Только затяжку болтов всех уплотняющих устройств",
          "Все перечисленное",
          "Только наличие смазки в редукторе и маслораспределителе",
          "Только срабатывание конечных выключателей, фиксирующих плотность закрывания нижней крышки и ее открывания",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21103109",
        text: "Какое из перечисленных требований допускается при эксплуатации грануляторов? Выберите 2 варианта ответа.",
        answers: [
          "Перед пуском установки для гранулирования комбикормов необходимо убедиться в отсутствии посторонних предметов в машине, исправности механизмов и приборов",
          "Для замены матриц следует пользоваться грейфером",
          "Регулирование зазоров между роликами и матрицей допускается производить лишь при остановленном грануляторе",
          "Допускается заменять предохранительные штифты металлическим стержнем",
        ],
        correctAnswers: [
          "Перед пуском установки для гранулирования комбикормов необходимо убедиться в отсутствии посторонних предметов в машине, исправности механизмов и приборов",
          "Регулирование зазоров между роликами и матрицей допускается производить лишь при остановленном грануляторе",
        ],
      },
      {
        code: "21103110",
        text: "В каком случае разрешается пуск охладителя?",
        answers: [
          "После проверки герметичности",
          "При работающем вентиляторе и шлюзовом затворе",
          "После проверки готовности всасывающей линии",
        ],
        correctAnswers: ["При работающем вентиляторе и шлюзовом затворе"],
      },
      {
        code: "21103111",
        text: "Какие действия из перечисленных разрешается осуществлять при работе автомата для фасовки муки и крупы?",
        answers: [
          "Снимать испорченные пакеты, производить смазку и обтирку частей машины",
          "Работать только при отключенной вентиляции",
          "У каждой машины должен быть местный отсос для удаления пыли и тканевого ворса",
        ],
        correctAnswers: [
          "У каждой машины должен быть местный отсос для удаления пыли и тканевого ворса",
        ],
      },
      {
        code: "21103112",
        text: "Каким образом должны быть подвешены кузова рассевов?",
        answers: [
          "Таким образом, чтобы нижняя плоскость кузова находилась от уровня пола на высоте не менее 0,35 м",
          "Таким образом, чтобы верхняя плоскость кузова находилась от препятствий не менее 0,15 м",
          "Стальными канатами",
        ],
        correctAnswers: [
          "Таким образом, чтобы нижняя плоскость кузова находилась от уровня пола на высоте не менее 0,35 м",
        ],
      },
      {
        code: "21103113",
        text: "Какие требования к гибким соединениям кузовов рассевов и камнеотборников указаны неверно?",
        answers: [
          "Должны быть закреплены ремнями",
          "Должны быть из материалов, не пропускающих пыль, закреплены на штуцерах кузовов и патрубках",
          "Должны быть из материалов, пропускающих пыль",
        ],
        correctAnswers: [
          "Должны быть из материалов, не пропускающих пыль, закреплены на штуцерах кузовов и патрубках",
        ],
      },
      {
        code: "21103114",
        text: "Какое из перечисленных требований к эксплуатации мешковыбивальных машин указано неверно?",
        answers: [
          "Бичевые барабаны мешковыбивальных машин должны быть отбалансированы",
          "Бичи должны свободно проходить между решетками и не задевать неподвижных частей машин",
          "Крышки, лючки и дверки мешковыбивальных машин должны быть уплотнены резиновыми и тканевыми прокладками и не пропускать пыль",
          "Во время аварийной ситуации нужно сорвать мешки с мешкодержателей или крюков цепи конвейера и механизма сброса",
        ],
        correctAnswers: [
          "Во время аварийной ситуации нужно сорвать мешки с мешкодержателей или крюков цепи конвейера и механизма сброса",
        ],
      },
      {
        code: "21103115",
        text: "На каком расстоянии от корпуса затворов размещаются люки в самотеках, по которым поступает и выводится продукт из шлюзовых затворов?",
        answers: [
          "Не менее 300 мм",
          "Не менее 250 мм",
          "Не менее 350 мм",
          "Не менее 400 мм",
        ],
        correctAnswers: ["Не менее 250 мм"],
      },
      {
        code: "21103116",
        text: "При расположении на какой высоте от пола осей приводных барабанов необходимо предусматривать специальные площадки с перилами высотой не менее 1 м с зашивкой внизу на 0,15 м с обеспечением проходов для обслуживания головок норий?",
        answers: ["Более 0,5 м", "Более 1,0 м", "Более 1,5 м", "Более 1,2 м"],
        correctAnswers: ["Более 1,5 м"],
      },
      {
        code: "21103117",
        text: "Через какие промежутки следует предусматривать установку кнопок «Стоп» вдоль подсилосных и надсилосных, нижних и верхних конвейеров, складов?",
        answers: [
          "Через каждые 15 м",
          "Через каждые 10 м",
          "Через каждые 5 м",
          "Через каждые 20 м",
        ],
        correctAnswers: ["Через каждые 10 м"],
      },
      {
        code: "21103118",
        text: "Работа какого перечисленного оборудования допускается при открытых крышках?",
        answers: [
          "Смесителей",
          "Шнеков",
          "Цепных конвейеров",
          "Открывать крышки во время работы оборудования запрещается",
          "Аэрожелобов",
        ],
        correctAnswers: [
          "Открывать крышки во время работы оборудования запрещается",
        ],
      },
      {
        code: "21103119",
        text: "Что из перечисленного допускается при эксплуатации оборудования?",
        answers: [
          "Подтягивать болтовые соединения, устранять неисправности на движущихся частях",
          "Cнимать или надевать приводные ремни на ходу, регулировать натяжение ремней или цепей машин во время их работы",
          "Вести регулярный надзор в целях своевременного устранения дефектов, которые могут вызвать увеличение шума или перегрев вращающихся деталей",
          "Расчищать от завалов, запрессованного продукта или от попавших предметов конвейеры, башмаки норий и другие машины во время их работы",
        ],
        correctAnswers: [
          "Вести регулярный надзор в целях своевременного устранения дефектов, которые могут вызвать увеличение шума или перегрев вращающихся деталей",
        ],
      },
      {
        code: "21103120",
        text: "Какое требование к эксплуатации оборудования на объектах хранения и переработки растительного сырья указано неверно?",
        answers: [
          "Оборудование должно быть отрегулировано",
          "Оборудование должно работать без несвойственного ему шума, вибрации и повышенного трения движущихся частей",
          "Оборудование должно постоянно находиться в исправном состоянии",
          "Оборудование должно быть установлено в металлических защитных кожухах",
        ],
        correctAnswers: [
          "Оборудование должно быть установлено в металлических защитных кожухах",
        ],
      },
      {
        code: "21103121",
        text: "Какое из перечисленных требований к взрыворазрядителям указано неверно?",
        answers: [
          "Диаметры (площади) проходных сечений взрыворазрядителей для оборудования определяются расчетом с учетом величины защищаемого объема",
          "Диаметры (площади) проходных сечений взрыворазрядителей для оборудования определяются расчетом с учетом допустимого давления взрыва",
          "Диаметры (площади) проходных сечений взрыворазрядителей для оборудования определяются расчетом с учетом формы проходного сечения",
          "Диаметры (площади) проходных сечений взрыворазрядителей для оборудования определяются расчетом с учетом длины, количества и величины углов поворота отводящих трубопроводов",
          "Диаметры (площади) проходных сечений взрыворазрядителей для оборудования определяются расчетом с учетом динамического давления вскрытия мембраны (клапана)",
        ],
        correctAnswers: [
          "Диаметры (площади) проходных сечений взрыворазрядителей для оборудования определяются расчетом с учетом динамического давления вскрытия мембраны (клапана)",
        ],
      },
      {
        code: "21103122",
        text: "В каком случае трос подвески кузова у камнеотборников и сепараторов с круговым поступательным движением следует заменять новым?",
        answers: [
          "Если количество оборванных проволок достигает 10 % от общего их числа на участке, равном шагу свивки.",
          "Если количество оборванных проволок достигает 5 % от общего их числа на участке, равном шагу свивки",
          "Если количество оборванных проволок достигает 7 % от общего их числа на участке, равном шагу свивки",
        ],
        correctAnswers: [
          "Если количество оборванных проволок достигает 5 % от общего их числа на участке, равном шагу свивки",
        ],
      },
      {
        code: "21103123",
        text: "При каком виде работ машины могут не отключаться от сети электропитания?",
        answers: [
          "Запрещено при любом виде работ",
          "При испытаниях",
          "При внутреннем осмотре машин, их ремонте, выключении на продолжительное время, при неисправности машин",
          "Не регламентируется",
        ],
        correctAnswers: ["Запрещено при любом виде работ"],
      },
      {
        code: "21103124",
        text: "Какие требования к хранению и сушке кукурузы указаны неверно?",
        answers: [
          "Только, если уровень влажности при хранении зерна до года не должен превышать: кукурузы в зерне, проса, сорго, овса - 12%, при длительном хранении (более 1 года)-15%",
          "Только, если уровень влажности при хранении зерна до года не должен превышать: кукурузы в зерне, проса, сорго, овса - 14%, при длительном хранении (более 1 года)-13%",
          "Только, если уровень влажности при хранении зерна до года не должен превышать: кукурузы в зерне, проса, сорго, овса - 11%, при длительном хранении (более 1 года)-14%",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "21103125",
        text: "Чем должна быть обоснована протяженность горизонтальных участков воздуховодов?",
        answers: [
          "Климатическими условиями",
          "Проектными решениями",
          "Характеристиками оборудования",
          "Сейсмической активностью",
        ],
        correctAnswers: ["Проектными решениями"],
      },
      {
        code: "21103126",
        text: "Через какие перечисленные помещения допускается прокладка транзитных воздуховодов?",
        answers: [
          "Через производственные помещения категории Г",
          "Через производственные помещения категории Д",
          "Через производственные помещения складов категории Д",
          "Через все перечисленные помещения",
        ],
        correctAnswers: ["Через производственные помещения категории Д"],
      },
      {
        code: "21103127",
        text: "Что из перечисленного не отражается в паспортах аспирационных установок, находящихся в эксплуатации?",
        answers: [
          "Принципиальные схемы установки",
          "Спецификации оборудования",
          "Производительность вентилятора и развиваемое им давление",
          "Результаты периодического осмотра установки",
          "ЗИП и их характеристики",
        ],
        correctAnswers: ["ЗИП и их характеристики"],
      },
      {
        code: "21103128",
        text: "В соответствии с чем составляются паспорта аспирационных и пневмотранспортных установок?",
        answers: [
          "В соответствии с проектом",
          "В соответствии с результатами испытаний",
          "В соответствии с внутренними распорядительными документами эксплуатирующей объект организации",
          "В соответствии с заключением экспертизы промышленной безопасности",
        ],
        correctAnswers: [
          "В соответствии с внутренними распорядительными документами эксплуатирующей объект организации",
        ],
      },
      {
        code: "21103129",
        text: "При каком условии допускается проведение обеззараживания зерна?",
        answers: [
          "При условии обеспечения работников спецодеждой",
          "При условии полной засыпки силосов и бункеров продуктом",
          "При условии разработки и соблюдения комплекса организационно-технических мер по обеспечению безопасности при проведении работ с применением химических препаратов",
        ],
        correctAnswers: [
          "При условии разработки и соблюдения комплекса организационно-технических мер по обеспечению безопасности при проведении работ с применением химических препаратов",
        ],
      },
      {
        code: "21103130",
        text: "В каком случае допускается временное размещение семян подсолнечника в силосах элеваторов и складах силосного типа, оборудованных устройствами автоматизированного дистанционного контроля температуры?",
        answers: [
          "При наличии автоматизированного дистанционного контроля температуры",
          "При условии разработки комплекса организационно-технических мер по обеспечению безопасности",
          "При влажности от 8 до15%",
          "При влажности не более 8%",
        ],
        correctAnswers: ["При влажности не более 8%"],
      },
      {
        code: "21103131",
        text: "Что является критерием эффективности магнитной защиты при приеме сырья с автомобильного, водного и железнодорожного транспорта?",
        answers: [
          "Категория электроснабжения",
          "Система блокировок и противоаварийной защиты",
          "Заземление",
          "Полное извлечение металломагнитных примесей, способных инициировать искру",
        ],
        correctAnswers: [
          "Полное извлечение металломагнитных примесей, способных инициировать искру",
        ],
      },
      {
        code: "21103132",
        text: "Чему должна быть равна площадь легкосбрасываемых конструкций при отсутствии расчетных данных?",
        answers: [
          "Не менее 0,07 м² на 1,0 м³ объема помещения категории А и не менее 0,09 м² на 1,0 м³ объема помещения категории Б",
          "Не менее 0,05 м² на 1,0 м³ объема помещения категории А и не менее 0,03 м² на 1,0 м³ объема помещения категории Б",
          "Не менее 0,02 м² на 1,0 м³ объема помещения категории А и не менее 0,04 м² на 1,0 м³ объема помещения категории Б",
        ],
        correctAnswers: [
          "Не менее 0,05 м² на 1,0 м³ объема помещения категории А и не менее 0,03 м² на 1,0 м³ объема помещения категории Б",
        ],
      },
      {
        code: "21103133",
        text: "Какие материалы допускается использовать в качестве легкосбрасываемых конструкций при недостаточной площади остекления?",
        answers: [
          "Только конструкции из облегченных материалов (например, профильный металлический лист), вскрывающиеся или разрушающиеся при избыточном давлении внутри помещения не более 2 кПа (200 кгс/м²)",
          "Только конструкции покрытий из стальных, алюминиевых листов",
          "Только конструкции из асбестоцементных листов (шифер)",
          "Все перечисленные конструкции",
        ],
        correctAnswers: ["Все перечисленные конструкции"],
      },
      {
        code: "21103134",
        text: "Что из перечисленного не учитывается при анализе риска аварий на объектах?",
        answers: [
          "Условия возникновения аварий и их сценарии",
          "Расчетное время прибытия специализированных профессиональных служб (формирований)",
          "Численность и размещение производственного персонала",
          "Численность специализированных профессиональных служб (формирований)",
        ],
        correctAnswers: [
          "Численность специализированных профессиональных служб (формирований)",
        ],
      },
      {
        code: "21103135",
        text: "Какой шириной должны быть разрывы между шкивами вальцовых станков, расположенных в группе при трансмиссионном приводе или при приводе от индивидуальных электродвигателей, расположенных на нижележащем этаже?",
        answers: [
          "Не менее 0,15 м",
          "Не менее 0,35 м",
          "Не менее 0,25 м",
          "Не менее 0,12 м",
        ],
        correctAnswers: ["Не менее 0,35 м"],
      },
      {
        code: "21103136",
        text: "Какую длину носика должны иметь ручные масленки для заполнения подшипников на ходу при эксплуатации технологического оборудования?",
        answers: [
          "Не менее 100 мм",
          "Не менее 200 мм",
          "Не менее 50 мм",
          "Не менее 150 мм",
        ],
        correctAnswers: ["Не менее 200 мм"],
      },
      {
        code: "21103137",
        text: "Что из перечисленного допускается хранить на территории эксплуатирующей организации открытым способом?",
        answers: [
          "Мешков с сырьем",
          "Зерновых отходов, лузги и пыли",
          "Готовой продукции",
        ],
        correctAnswers: ["Мешков с сырьем"],
      },
      {
        code: "21103138",
        text: "В помещениях какой категории запрещается складирование мешков с сырьем или готовой продукцией, пустых мешков или других горючих материалов, если это не связано с необходимостью ведения технологического процесса?",
        answers: [
          "В производственных помещениях категории Д",
          "В производственных помещениях категории Б",
          "В производственных помещениях категории А",
          "В производственных помещениях категории Г",
        ],
        correctAnswers: ["В производственных помещениях категории Б"],
      },
      {
        code: "21103139",
        text: "В помещения каких классов вводы кабелей и труб электропроводки из взрывоопасных зон всех классов не должны быть плотно заделаны несгораемыми материалами? Выберите 2 варианта ответа.",
        answers: [
          "В помещения классов В-Iа, В-Iб и В-IIа",
          "В помещения пожароопасных зон РУ, ТП",
          "В-Iг",
          "В-IIб",
        ],
        correctAnswers: ["В-Iг", "В-IIб"],
      },
      {
        code: "21103140",
        text: "Без устройства чего в зданиях категории Б не допускается предусматривать соединяющие их с другими зданиями и помещениями тоннели и подземные галереи? Выберите 2 варианта ответа.",
        answers: [
          "Переходных мостиков",
          "Металлической герметической двери",
          "Тамбур-шлюзов",
          "Огнепреграждающих клапанов",
        ],
        correctAnswers: ["Тамбур-шлюзов", "Огнепреграждающих клапанов"],
      },
      {
        code: "21103141",
        text: "Какой должен быть объем производственного помещения на каждого работающего и площадь помещения?",
        answers: [
          "Не менее 20 м³, а площадь помещения - не менее 2,5 м²",
          "Не менее 10 м³, а площадь помещения - не менее 3,5 м²",
          "Не менее 15 м³, а площадь помещения - не менее 4,5 м²",
          "Не менее 25 м³, а площадь помещения - не менее 5,5 м²",
        ],
        correctAnswers: [
          "Не менее 15 м³, а площадь помещения - не менее 4,5 м²",
        ],
      },
      {
        code: "21103142",
        text: "Какая должна быть высота помещений от пола до низа выступающих конструкций перекрытия (покрытия)?",
        answers: [
          "Не менее 3,0 м",
          "Не менее 4,5 м",
          "Не менее 4,8 м",
          "Не менее 2,2 м",
        ],
        correctAnswers: ["Не менее 2,2 м"],
      },
      {
        code: "21103143",
        text: "Какие размеры должна иметь вертикальная колонна в механизированных зерновых складах с плоскими полами?",
        answers: [
          "Высоту 4500 мм, наружный диаметр 245 мм",
          "Высоту 6000 мм, наружный диаметр 400 мм",
          "Высоту 5500 мм, наружный диаметр 394 мм",
          "Высоту 6200 мм, наружный диаметр 554 мм",
        ],
        correctAnswers: ["Высоту 5500 мм, наружный диаметр 394 мм"],
      },
      {
        code: "21103144",
        text: "Какое углубление решеток люков силосов, бункеров и других устройств от поверхности пола помещения должно быть обосновано?",
        answers: ["До 40 мм", "До 55 мм", "До 60 мм", "Более 60 мм"],
        correctAnswers: ["Более 60 мм"],
      },
      {
        code: "21103145",
        text: "Какую степень защиты оболочки должны иметь применяемые во взрыво- и пожароопасных зонах ручные и переносные аппараты и приборы?",
        answers: [
          "Не ниже IP65",
          "Не ниже IP22",
          "Не ниже IP68",
          "Не ниже IP54",
        ],
        correctAnswers: ["Не ниже IP54"],
      },
      {
        code: "21103146",
        text: "Применение каких переносных светильников во взрывобезопасном исполнении допускается использовать в трюмах судов и барж при разгрузке и загрузке?",
        answers: [
          "Не выше 24 В",
          "Не выше 220 В",
          "Не выше 12 В",
          "Не выше 36 В",
        ],
        correctAnswers: ["Не выше 12 В"],
      },
      {
        code: "21103147",
        text: "Какие должны быть проходы у башмака нории?",
        answers: ["Не менее 0,8 м", "Не менее 0,6 м", "Не менее 0,7 м"],
        correctAnswers: ["Не менее 0,7 м"],
      },
      {
        code: "21103148",
        text: "Какая допускается мощность стационарно установленных электрообогревателей для обогревания рабочих в помещениях (кабинах), расположенных в рабочих зданиях элеваторов и неотапливаемых складах?",
        answers: ["До 0,5 кВт", "До 1 кВт", "До 1,5 кВт"],
        correctAnswers: ["До 1 кВт"],
      },
      {
        code: "21103149",
        text: "В помещениях какой категории по пожарной и взрывопожарной опасности на объектах хранения и переработки растительного сырья должны предусматриваются наружные легкосбрасываемые конструкции? Выберите 2 варианта ответа.",
        answers: [
          "В помещениях категорий Г",
          "В помещениях категорий А",
          "В помещениях категорий Б",
          "В помещениях категорий Д",
        ],
        correctAnswers: [
          "В помещениях категорий А",
          "В помещениях категорий Б",
        ],
      },
      {
        code: "21103150",
        text: "В помещениях какой категории допускается применять отопительные приборы с гладкой поверхностью и размещать их на высоте, обеспечивающей возможность их очистки от пыли?",
        answers: [
          "В помещениях категорий Д",
          "В помещениях категорий В",
          "В помещениях категорий А",
          "В помещениях категорий А, Б и В",
        ],
        correctAnswers: ["В помещениях категорий А, Б и В"],
      },
      {
        code: "21103151",
        text: "В помещениях какой категории допускается не предусматривать приточно-вытяжную или вытяжную вентиляцию для приямков, предназначенных для обслуживания норий и цепных конвейеров?",
        answers: ["А", "Б", "Д", "Г"],
        correctAnswers: ["Б"],
      },
      {
        code: "21103152",
        text: "Что из перечисленного допускается размещать во взрывопожароопасных зданиях и сооружениях?",
        answers: [
          "Встраивать смежные производства при соблюдении норм и требований взрывобезопасности",
          "Бункера для хранения аспирационных относов, пыли и пылевидных продуктов",
          "Курильное помещение",
          "Насосную станцию",
        ],
        correctAnswers: [
          "Встраивать смежные производства при соблюдении норм и требований взрывобезопасности",
        ],
      },
      {
        code: "21103153",
        text: "Что из перечисленного не допускается размещать в производственных зданиях?",
        answers: [
          "Вальцерезную мастерскую",
          "Диспетчерскую",
          "Механизированные склады напольного типа при хранении комбикормов, лузги, мучки, шрота, жмыха",
          "Бункера для хранения аспирационных относов, пыли и пылевидных продуктов во взрывопожароопасных зданиях и сооружениях",
        ],
        correctAnswers: [
          "Бункера для хранения аспирационных относов, пыли и пылевидных продуктов во взрывопожароопасных зданиях и сооружениях",
        ],
      },
      {
        code: "21103154",
        text: "Что из перечисленного не допускается в шахтах для прокладки кабелей?",
        answers: [
          "Только норий",
          "Только оборудования",
          "Только самотечных труб",
          "Только аспирационных воздуховодов",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21103155",
        text: "Что из перечисленного не допускается на объектах хранения и переработки растительного сырья?",
        answers: [
          "На нориях с высотой норийных труб более 36 м взрыворазрядители следует устанавливать на головке нории (или на норийных трубах на расстоянии не более 1/3 высоты норийных труб от головки нории) и на обеих норийных трубах на расстоянии не более 1/3 высоты норийных труб от башмака нории в любом месте, удобном для монтажа и обслуживания взрыворазрядителей",
          "Установка взрыворазрядителя на крышке головки нории не должна приводить к обратной сыпи и попаданию продукта на предохранительную мембрану",
          "Взрыворазрядители на норийных трубах устанавливаются на фланцах входных патрубков таким образом, чтобы норийная лента не препятствовала выбросу продуктов взрывного горения и несгоревшей смеси.",
          "На головке сдвоенной нории не допускается устанавливать общий для обеих частей нории взрыворазрядитель",
        ],
        correctAnswers: [
          "На головке сдвоенной нории не допускается устанавливать общий для обеих частей нории взрыворазрядитель",
        ],
      },
      {
        code: "21103156",
        text: 'Что необходимо предпринять при бестарном приеме и отпуске зерна, комбикормового, мучнистого сырья и готовой продукции (а также при погрузке отходов) на железнодорожном и автомобильном транспорте согласно Федеральным нормам и правилам в области промышленной безопасности "Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья"?',
        answers: [
          "Установить световую сигнализацию",
          "Мероприятия, снижающие запыление территории",
          "Установить дополнительные емкости для загрузки",
          "Установить периодичность погрузки и разгрузки",
        ],
        correctAnswers: ["Мероприятия, снижающие запыление территории"],
      },
      {
        code: "21103157",
        text: "На каком расстоянии от стен и другого оборудования должны устанавливаться мешковыбивальные машины?",
        answers: [
          "Не менее 0,5 м",
          "Не менее 0,7 м",
          "Не менее 0,8 м",
          "Не менее 0,9 м",
        ],
        correctAnswers: ["Не менее 0,9 м"],
      },
      {
        code: "21103158",
        text: "Что допускается использовать при монтаже оборудования во взрывопожароопасных помещениях, в которых работает оборудование?",
        answers: [
          "Открытый огонь",
          "Механизмы и приспособления, которые могут вызвать искрообразование",
          "Отогревание узлов и частей оборудования и устройств паром или горячей водой",
          "Промасленные протирочные материалы",
        ],
        correctAnswers: [
          "Отогревание узлов и частей оборудования и устройств паром или горячей водой",
        ],
      },
      {
        code: "21103159",
        text: "В какой цвет должны быть окрашены органы управления аварийного выключения оборудования взрывопожароопасных производственных объектов хранения или переработки растительного сырья?",
        answers: [
          "В черный цвет",
          "В желтый цвет",
          "В красный цвет",
          "В белый цвет",
          "В зеленый цвет",
        ],
        correctAnswers: ["В красный цвет"],
      },
      {
        code: "21103160",
        text: 'Какие требования предъявляются к зубчатым передачам, являющимся источником опасности, согласно Федеральным нормам и правилам в области промышленной безопасности "Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья"?',
        answers: [
          "Зубчатые передачи должны быть закрыты глухими кожухами, прочно прикрепленными к станине или другой неподвижной части машины",
          "Зубчатые передачи должны размещаться внутри машины",
          "Зубчатые передачи должны заключаться в прочные и надежно укрепленные ограждения",
          "Зубчатые передачи должны иметь съемные, откидные или раздвижные ограждения",
        ],
        correctAnswers: [
          "Зубчатые передачи должны быть закрыты глухими кожухами, прочно прикрепленными к станине или другой неподвижной части машины",
        ],
      },
      {
        code: "21103161",
        text: "Какой должна быть температура нагрева корпусов подшипников во время работы взрывопожароопасного оборудования?",
        answers: [
          "Не более 60 °С",
          "Не более 67 °С",
          "Не более 75 °С",
          "Не более 90 °С",
        ],
        correctAnswers: ["Не более 60 °С"],
      },
      {
        code: "21103162",
        text: "Какой должна быть высота бортов металлических или бетонных корыт, в которых установлены моечные машины?",
        answers: ["10 - 25 мм", "30 - 45 мм", "50 - 75 мм", "80 - 95 мм"],
        correctAnswers: ["50 - 75 мм"],
      },
      {
        code: "21103163",
        text: 'Что должна обеспечивать конструкция вальцового станка с полой бочкой согласно Федеральным нормам и правилам в области промышленной безопасности "Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья"?',
        answers: [
          "Прохождение между вальцами инородных тел размером не более 5 мм",
          "Прохождение между вальцами инородных тел размером не более 3 мм",
          "Прохождение между вальцами инородных тел размером не более 9 мм",
          "Прохождение между вальцами инородных тел размером не более 4 мм",
        ],
        correctAnswers: [
          "Прохождение между вальцами инородных тел размером не более 5 мм",
        ],
      },
      {
        code: "21103164",
        text: "Какой перепад температуры входного и измельченного продукта должна обеспечивать конструкция устройства охлаждения вальцов с водяным охлаждением?",
        answers: [
          "Не более 25 °С",
          "Не более 18 °С",
          "Не более 15 °С",
          "Не более 12 °С",
        ],
        correctAnswers: ["Не более 12 °С"],
      },
      {
        code: "21103165",
        text: "Какое устройство должны иметь загрузочные воронки дробилок для предотвращения попадания посторонних предметов?",
        answers: [
          "Заслонку",
          "Проволочную сетку",
          "Защитную решетку",
          "Запорную арматуру",
        ],
        correctAnswers: ["Защитную решетку"],
      },
      {
        code: "21103166",
        text: "От чего защищают производственное оборудование, в котором возможно возникновение источника зажигания пылевоздушной смеси?",
        answers: [
          "От разрушения и выброса из него горючих веществ в производственное помещение",
          "От воздействия на него атмосферных осадков",
          "От перегрузки",
          "От неосторожного обращения работников с его элементами и частями",
        ],
        correctAnswers: [
          "От разрушения и выброса из него горючих веществ в производственное помещение",
        ],
      },
      {
        code: "21103167",
        text: "На каком оборудовании из перечисленного устанавливают взрыворазрядители?",
        answers: [
          "На нориях со свободным объемом менее 0,25 м3",
          "На дробилках с объемом рабочей зоны и выпускного (поддробильного) бункера менее 0,3 м3",
          "На дробилках подачи и измельчения минерального сырья",
          "На фильтр-циклонах со свободным объемом более 0,5 м3",
        ],
        correctAnswers: [
          "На фильтр-циклонах со свободным объемом более 0,5 м3",
        ],
      },
      {
        code: "21103168",
        text: "Каким образом следует располагать торец отводящего трубопровода при применении (установке) взрыворазрядителей на первых этажах?",
        answers: [
          " Не ниже 2,5 м от планировочной отметки",
          " Не выше 2,0 м от планировочной отметки",
          " Не ниже 1,5 м от планировочной отметки",
          " Не выше 0,5 м от планировочной отметки",
        ],
        correctAnswers: [" Не ниже 2,5 м от планировочной отметки"],
      },
      {
        code: "21103169",
        text: "Что не учитывается при определении диаметров (площадей) проходных сечений взрыворазрядителей для оборудования?",
        answers: [
          "Величина защищаемого объема",
          "Допустимое давление взрыва, статическое давление вскрытия мембраны (клапана)",
          "Форма проходного сечения",
          "Длина, количество и величина углов поворота отводящих трубопроводов",
          "Температура и влажность в месте установки оборудования",
        ],
        correctAnswers: [
          "Температура и влажность в месте установки оборудования",
        ],
      },
      {
        code: "21103170",
        text: "Где устанавливаются взрыворазрядители для взрывозащиты зерносушилок?",
        answers: [
          "Только на каскадных нагревателях",
          "Только на камерах нагрева",
          "Только на надсушильных бункерах: на верхней крышке или на боковой стенке в верхней части бункера",
          "На камерах нагрева, подогревателях, каскадных нагревателях, осадочных камерах, топках, надсушильных бункерах и на нориях, обслуживающих зерносушилки",
        ],
        correctAnswers: [
          "На камерах нагрева, подогревателях, каскадных нагревателях, осадочных камерах, топках, надсушильных бункерах и на нориях, обслуживающих зерносушилки",
        ],
      },
      {
        code: "21103171",
        text: "Где должны устанавливаться взрыворазрядители на надсушильных бункерах для взрывозащиты зерносушилок?",
        answers: [
          "На верхней крышке или на боковой стенке в верхней части бункера",
          "Только на верхней крышке бункера",
          "На боковой стенке в нижней части бункера",
          "На боковой стенке со стороны, противоположной входу агента сушки",
          "На днище бункера",
        ],
        correctAnswers: [
          "На верхней крышке или на боковой стенке в верхней части бункера",
        ],
      },
      {
        code: "21103172",
        text: "Что устанавливают перед предохранительной мембраной взрыворазрядителя при систематических нарушениях целостности мембран вследствие разрежения внутри оборудования или значительных пульсаций давления?",
        answers: [
          "Защитную сетку из проволоки толщиной 1 - 2 мм с ячейками размером 30 x 30 мм",
          "Защитную сетку из капрона толщиной 1 мм с ячейками размером 20 x 20 мм",
          "Защитную сетку из базальтового волокна толщиной 1 мм с ячейками размером 50 x 50 мм",
        ],
        correctAnswers: [
          "Защитную сетку из проволоки толщиной 1 - 2 мм с ячейками размером 30 x 30 мм",
        ],
      },
      {
        code: "21103173",
        text: "Когда следует осуществлять обязательную замену разрывных предохранительных мембран из полиэтиленовой пленки или алюминиевой фольги с отражением действий в журнале периодических осмотров и ремонтов взрыворазрядителей?",
        answers: [
          "По истечении одного года эксплуатации",
          "По истечении трех лет эксплуатации",
          "По истечении пяти лет эксплуатации",
        ],
        correctAnswers: ["По истечении одного года эксплуатации"],
      },
      {
        code: "21103174",
        text: "Какой должна быть толщина стенки норийных труб сварного круглого сечения при отсутствии расчетных данных для защиты норий от разрушения?",
        answers: [
          "Не менее 1 мм",
          "Не менее 1,5 мм",
          "Не менее 1,7 мм",
          "Не менее 2 мм",
        ],
        correctAnswers: ["Не менее 2 мм"],
      },
      {
        code: "21103175",
        text: "Что из перечисленного не используется в качестве огнепреграждающих (пламеотсекающих) устройств для систем локализации взрывов?",
        answers: [
          "Шлюзовые затворы",
          "Винтовые конвейеры",
          "Порционные весы",
          "Самотечные трубопроводы",
          "Быстродействующие устройства, предотвращающие возможность распространения взрыва",
        ],
        correctAnswers: ["Самотечные трубопроводы"],
      },
      {
        code: "21103176",
        text: 'Где не устанавливаются огнепреграждающие (пламеотсекающие) устройства согласно Федеральным нормам и правилам в области промышленной безопасности "Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья"?',
        answers: [
          "На трубопроводах с головок норий и из дробилок, через которые непосредственно ведется загрузка бункеров и силосов",
          "На трубопроводах с головок норий и из дробилок на цепные и закрытые ленточные конвейеры, через которые ведется загрузка бункеров и силосов",
          "На коммуникациях минерального сырья",
          "На трубопроводах с головок норий на надвесовые бункеры",
          "На трубопроводах подачи зерна с головок норий в надсушильные бункеры встроенных зерносушилок",
          "На трубопроводах, соединяющих (в том числе и через цепные и закрытые ленточные конвейеры) бункеры, силосы или нории элеватора с отдельно стоящими зерносушилками",
        ],
        correctAnswers: ["На коммуникациях минерального сырья"],
      },
      {
        code: "21103177",
        text: "Какие устройства должны быть установлены на нориях для предотвращения обратного хода ленты при внезапных остановках норий?",
        answers: [
          "Реле контроля скорости",
          "Устройства контроля сбегания ленты",
          "Датчики подпора",
          "Автоматически действующие тормозные устройства",
        ],
        correctAnswers: ["Автоматически действующие тормозные устройства"],
      },
      {
        code: "21103178",
        text: "При какой температуре наружной поверхности оборудование, емкости, продуктопроводы теплоизолируются?",
        answers: ["Выше 40 °С", "Выше 45 °С", "Выше 25 °С", "Выше 30 °С"],
        correctAnswers: ["Выше 45 °С"],
      },
      {
        code: "21103179",
        text: 'Какие требования, предъявляемые к оборудованию взрывопожароопасных производственных объектов хранения или переработки растительного сырья, не соответствуют Федеральным нормам и правилам в области промышленной безопасности "Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья"?',
        answers: [
          "Оборудование должно постоянно находиться в исправном состоянии",
          "Оборудование должно быть установлено в металлических защитных кожухах",
          "Оборудование должно быть отрегулировано",
          "Оборудование должно работать без несвойственного ему шума, вибрации и повышенного трения движущихся частей",
        ],
        correctAnswers: [
          "Оборудование должно быть установлено в металлических защитных кожухах",
        ],
      },
      {
        code: "21103180",
        text: "С чем должны быть сблокированы электроприводы молотковых дробилок?",
        answers: [
          "С магнитными заграждениями",
          "С грануляторами",
          "С энтолейтором",
          "С устройством загрузки",
          "С датчиками нижнего уровня наддробильных бункеров",
        ],
        correctAnswers: ["С датчиками нижнего уровня наддробильных бункеров"],
      },
      {
        code: "21103181",
        text: "В каких нештатных ситуациях оборудование взрывопожароопасных производственных объектов хранения или переработки растительного сырья должно останавливаться?",
        answers: [
          "При появлении несвойственного шума и вибрации",
          "При завале, подпоре и перегрузке продуктом",
          "При поломках и неисправностях",
          "При попадании в рабочие органы посторонних предметов",
          "Во всех перечисленных ситуациях, включая неработающую аспирацию",
          "При срабатывании датчиков-индикаторов взрыва",
        ],
        correctAnswers: [
          "Во всех перечисленных ситуациях, включая неработающую аспирацию",
        ],
      },
      {
        code: "21103182",
        text: "Что из перечисленного не обеспечивает дистанционное управление работой оборудования взрывопожароопасных производственных объектов хранения или переработки растительного сырья?",
        answers: [
          "Возможность проведения контроля работоспособности средств дистанционного автоматизированного управления, блокировки, контроля и противоаварийной защиты, производственной и аварийной сигнализации, оповещения об аварийных ситуациях",
          "Постоянный контроль за параметрами технологического процесса и управление режимами для поддержания их регламентированных значений",
          "Регистрацию срабатывания и определение устройства, выдавшего сигнал на автоматическое аварийное отключение технологической линии или оборудования",
          "Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений",
          "Возможность повторного включения конвейера при возникновении аварийной ситуации до полного схода с него транспортируемого продукта",
        ],
        correctAnswers: [
          "Возможность повторного включения конвейера при возникновении аварийной ситуации до полного схода с него транспортируемого продукта",
        ],
      },
      {
        code: "21103183",
        text: "Чем должны быть оборудованы все силосы элеваторов и склады силосного типа для хранения сырья?",
        answers: [
          "Устройством дистанционного контроля температуры",
          "Защитным кожухом",
          "Двойными уплотняющими прокладками",
        ],
        correctAnswers: ["Устройством дистанционного контроля температуры"],
      },
      {
        code: "21103184",
        text: "Допускается ли устройство аспирационных пылеосадочных шахт, камер, коробов, размещаемых после пылеулавливающих установок?",
        answers: [
          "Не допускается",
          "Допускается",
          "Допускается только при специальном обосновании в проектной документации",
          "Допускается только при согласовании с территориальными органами Федеральной службы по экологическому, технологическому и атомному надзору",
        ],
        correctAnswers: ["Не допускается"],
      },
      {
        code: "21103185",
        text: "С какой периодичностью пыль и другие относы должны выводиться из пылеуловителей?",
        answers: [
          "Ежедневно",
          "Ежесменно",
          "Ежемесячно",
          "Непрерывно",
          "Еженедельно",
        ],
        correctAnswers: ["Непрерывно"],
      },
      {
        code: "21103186",
        text: "Каким образом должны быть заземлены воздуховоды и материалопроводы взрывопожароопасных производственных объектов хранения или переработки растительного сырья?",
        answers: [
          "Не менее чем в двух местах",
          "Через каждые 10 м по всей длине",
          "Только в одном месте",
          "В трех местах",
        ],
        correctAnswers: ["Не менее чем в двух местах"],
      },
      {
        code: "21103187",
        text: "С каким опережением должны включаться в работу аспирационные установки, сблокированные с технологическим и транспортным оборудованием?",
        answers: [
          "С опережением на 25 - 30 с включения технологического и транспортного оборудования",
          "С опережением на 5 - 10 с включения технологического и транспортного оборудования",
          "С опережением на 10 - 15 с включения технологического и транспортного оборудования",
          "С опережением на 15 - 20 с включения технологического и транспортного оборудования",
        ],
        correctAnswers: [
          "С опережением на 15 - 20 с включения технологического и транспортного оборудования",
        ],
      },
      {
        code: "21103188",
        text: 'Что является основным средством защиты от опасных проявлений статического электричества согласно Федеральным нормам и правилам в области промышленной безопасности "Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья"?',
        answers: [
          "Влажная уборка",
          "Ионизаторы воздуха",
          "Заземление",
          "Напольные покрытия из электропроводящих материалов",
        ],
        correctAnswers: ["Заземление"],
      },
      {
        code: "21103189",
        text: "Как следует защищать матерчатые фильтры, на которых осаждается мука, от накопления зарядов статического электричества?",
        answers: [
          "Заземлять",
          "Выполнять окантовку фильтра из электропроводной резины",
          "Прошивать алюминиевой проволокой",
          "Прошивать медной проволокой и соединять ее с системой заземления",
        ],
        correctAnswers: [
          "Прошивать медной проволокой и соединять ее с системой заземления",
        ],
      },
      {
        code: "21103190",
        text: "Кто должен дать разрешение для проведения ремонтных работ в помещениях действующего производства?",
        answers: [
          "Только главный инженер организации",
          "Только технический руководитель объекта",
          "Представитель территориального органа Федеральной службы по экологическому, технологическому и атомному надзору",
          "Руководитель или должностное лицо эксплуатирующей организации, ответственное за промышленную безопасность (технический руководитель, главный инженер)",
        ],
        correctAnswers: [
          "Руководитель или должностное лицо эксплуатирующей организации, ответственное за промышленную безопасность (технический руководитель, главный инженер)",
        ],
      },
      {
        code: "21103191",
        text: "После чего производятся работы по ремонту оборудования взрывопожароопасных производственных объектов хранения или переработки растительного сырья?",
        answers: [
          "Только после полной остановки оборудования",
          "Только после отключения напряжения",
          "Только после снятия приводных ремней и обеспечении необходимых мер взрывопожаробезопасности",
          "Только после полной его остановки, при выключенном напряжении, снятых приводных ремнях и обеспечения необходимых мер взрывопожаробезопасности",
        ],
        correctAnswers: [
          "Только после полной его остановки, при выключенном напряжении, снятых приводных ремнях и обеспечения необходимых мер взрывопожаробезопасности",
        ],
      },
      {
        code: "21103192",
        text: 'Какая предупредительная надпись должна быть вывешена у пускового устройства в помещении распределительного пункта и на пункте диспетчерского управления с начала ремонта оборудования и до его окончания согласно Федеральным нормам и правилам в области промышленной безопасности "Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья"?',
        answers: [
          '"Опасно для жизни!"',
          '"Стой!"',
          '"Не включать, ремонт!"',
          '"Ведутся работы"',
          '"Обесточено"',
        ],
        correctAnswers: ['"Не включать, ремонт!"'],
      },
      {
        code: "21103193",
        text: "Какое из перечисленных требований при работе с ручным немеханизированным инструментом указано неверно? Выберите 2 варианта ответа.",
        answers: [
          "Губки гаечных ключей должны быть закатаны. Разводные ключи должны быть ослаблены в своих подвижных частях",
          "При запрессовке и распрессовке деталей (подшипники, втулки) с помощью кувалды и выколотки последнюю следует держать клещами или специальным захватом",
          "Напильники, ножовки, стамески, долота и другие ручные инструменты должны быть прочно закреплены в деревянной рукоятке с наложенным на нее стальным кольцом",
          "Выколотка должна быть из твердого металла",
          "Не допускается удаление ключей с помощью труб и других предметов, завертывание гайки ключом больших размеров",
        ],
        correctAnswers: [
          "Губки гаечных ключей должны быть закатаны. Разводные ключи должны быть ослаблены в своих подвижных частях",
          "Выколотка должна быть из твердого металла",
        ],
      },
      {
        code: "21103194",
        text: "В каком случае допускается применение на объекте средств ДАУ, блокировки, контроля и противоаварийной защиты, производственной и аварийной сигнализации, связи, оповещения об аварийных ситуациях, отработавших назначенный срок службы?",
        answers: [
          "Не допускается ни в каком случае",
          "В случае упрощения технологического процесса",
          "В случае разрешения их применения разработчиком процесса",
          "В случае установления контроля, объем которого должен обеспечить их надежную работу",
          "В случае проведения работ в особых климатических условиях",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "21103195",
        text: "Какое из перечисленных требований к станкам для резки заплат указано верно? Выберите 2 варианта ответа.",
        answers: [
          "Приводной вал с дисковыми ножами станка должен быть отбалансирован",
          "Станок со всех сторон должен иметь проходы не менее 3 м",
          "Между кромкой стола и ограждением ножевого диска станка для резки заплат должен быть зазор не более 3 мм",
          "Станки должны иметь быстродействующие тормозные устройства",
        ],
        correctAnswers: [
          "Приводной вал с дисковыми ножами станка должен быть отбалансирован",
          "Между кромкой стола и ограждением ножевого диска станка для резки заплат должен быть зазор не более 3 мм",
        ],
      },
      {
        code: "21103196",
        text: "В каком из перечисленных случаев не допускается работа вальцовых станков?",
        answers: [
          "Без продукта с прижатыми вальцами с перекосом и смещением их вдоль оси",
          "При перепаде температур входного и измельченного продукта менее 10 °С",
          "При перепаде температур входного и измельченного продукта менее 12 °С",
          "При отсутствии устройства контроля температуры подшипников валков",
        ],
        correctAnswers: [
          "Без продукта с прижатыми вальцами с перекосом и смещением их вдоль оси",
        ],
      },
      {
        code: "21103197",
        text: "В каком случае допускается одновременная разборка или ремонт конструкций, оборудования в двух или более ярусах по одной вертикали?",
        answers: [
          "При согласовании с лицами, ответственными за эксплуатацию",
          "Если это обосновано проектной документацией",
          "При наличии соответствующих защитных устройств (настилы, сетки, козырьки), обеспечивающих безопасную работу на всех отметках",
          "Ни в каких случаях",
        ],
        correctAnswers: [
          "При наличии соответствующих защитных устройств (настилы, сетки, козырьки), обеспечивающих безопасную работу на всех отметках",
        ],
      },
      {
        code: "21103198",
        text: "Каким образом следует производить очистку шлюзового затвора от продукта, налипшего на стенки крыльчатки?",
        answers: [
          "Струей воды",
          "Путем продувки его воздухом",
          "Специальными скребками",
          "Специальными щетками",
          "Путем пропарки",
        ],
        correctAnswers: ["Путем продувки его воздухом"],
      },
      {
        code: "21103199",
        text: "Какое из перечисленных действий, которые необходимо произвести в случае загорания зерна в сушилке, указано неверно?",
        answers: [
          "Прекратить подачу зерна из сушилки в элеватор или склад",
          "Закрыть задвижки в воздуховоде от топки и сушилки",
          "Прекратить подачу сырого зерна в зерносушилку",
          "Выключить все вентиляторы",
        ],
        correctAnswers: ["Прекратить подачу сырого зерна в зерносушилку"],
      },
      {
        code: "21103200",
        text: "Кем должны подтверждаться эффективность и надежность технических средств блокировки, контроля и противоаварийной защиты объекта хранения и переработки растительного сырья испытанием промышленных образцов оборудования на взрывозащищенность?",
        answers: [
          "Организацией-изготовителем",
          "Проектной организацией",
          "Специально созданной комиссией под председательством технического руководителя организации",
          "Эксплуатирующей организацией",
          "Территориальным органом Ростехнадзора",
        ],
        correctAnswers: ["Организацией-изготовителем"],
      },
      {
        code: "21103201",
        text: "Какое из перечисленных действий допускается при проведении огневых работ на объектах хранения и переработки растительного сырья? Выберите 2 варианта ответа.",
        answers: [
          "Прокладка электрических проводов на расстоянии 1,0 м от горячих трубопроводов и баллонов с кислородом",
          "Использование в качестве обратного провода сети заземления или зануления металлических конструкций зданий, коммуникаций и технологического оборудования",
          "Прокладка электрических проводов на расстоянии менее 1,0 м от баллонов с горючими газами",
          "Проведение уборки помещений",
          "Плавное опускание на пол (землю) оборудования, сооружений и их частей, демонтируемых посредством электро- или газорезательных работ",
        ],
        correctAnswers: [
          "Прокладка электрических проводов на расстоянии 1,0 м от горячих трубопроводов и баллонов с кислородом",
          "Плавное опускание на пол (землю) оборудования, сооружений и их частей, демонтируемых посредством электро- или газорезательных работ",
        ],
      },
      {
        code: "21103202",
        text: "Каким требованиям должны отвечать устройства, применяемые для спуска людей в силосы?",
        answers: [
          "Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при выполнении огневых и газоопасных работ",
          "Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при работе в замкнутом пространстве",
          "Требованиям, установленным нормативными правовыми актами, содержащими требования промышленной безопасности при проведении работ с инструментом и приспособлениями",
          "Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при работе на высоте",
        ],
        correctAnswers: [
          "Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при работе на высоте",
        ],
      },
      {
        code: "21103203",
        text: "В каком из перечисленных случаев может производиться спуск рабочих в силосы и бункеры?",
        answers: [
          "В случае проверки температуры хранящегося сырья",
          "В случае проведения анализа газовой среды",
          "В исключительных случаях при обоснованной производственной необходимости (плановая зачистка после опорожнения силосов или бункеров от остатков сырья предыдущего хранения, обслуживание (ремонт) внутренних поверхностей силосов и бункеров)",
          "В случае отбора проб сырья",
        ],
        correctAnswers: [
          "В исключительных случаях при обоснованной производственной необходимости (плановая зачистка после опорожнения силосов или бункеров от остатков сырья предыдущего хранения, обслуживание (ремонт) внутренних поверхностей силосов и бункеров)",
        ],
      },
      {
        code: "21103204",
        text: "При каком условии должен производиться доступ рабочих в силосы и бункеры через нижний люк для производства огневых работ?",
        answers: [
          "Только при наличии разрешения территориального органа Федеральной службы по экологическому, технологическому и атомному надзору и в присутствии наблюдающего",
          "Только в присутствии главного инженера эксплуатирующей организации и представителя территориального органа Федеральной службы по экологическому, технологическому и атомному надзору",
          "Доступ рабочих в силосы и бункеры через нижний люк запрещен",
          "Только при наличии наряда-допуска, оформленного в порядке, определенном Правилами противопожарного режима в Российской Федерации, утвержденными постановлением Правительства Российской Федерации от 16 сентября 2020 г. N 1479, и разрешения ответственного руководителя работ и под его наблюдением",
        ],
        correctAnswers: [
          "Только при наличии наряда-допуска, оформленного в порядке, определенном Правилами противопожарного режима в Российской Федерации, утвержденными постановлением Правительства Российской Федерации от 16 сентября 2020 г. N 1479, и разрешения ответственного руководителя работ и под его наблюдением",
        ],
      },
      {
        code: "21103205",
        text: "На основании письменного решения какого лица осуществляется ввод в эксплуатацию аварийного участка производства (объекта) после проведения восстановительных работ?",
        answers: [
          "Технического руководителя эксплуатирующей организации",
          "Инспектора территориального органа Ростехнадзора",
          "Руководителя аварийно-спасательных формирований",
          "Руководителя эксплуатирующей организации",
          "Руководителя проектной организации",
        ],
        correctAnswers: ["Руководителя эксплуатирующей организации"],
      },
      {
        code: "21103206",
        text: "Когда должна отключаться приточно-вытяжная вентиляция аккумуляторных помещений после окончания заряда зарядных устройств?",
        answers: [
          "Не ранее чем через 3,5 часа",
          "Не ранее чем через 1,5 часа",
          "Не ранее чем через 30 минут",
          "Не ранее чем через 1,2 часа",
        ],
        correctAnswers: ["Не ранее чем через 1,5 часа"],
      },
      {
        code: "21103207",
        text: "Запрещается ли снимать или надевать приводные ремни на ходу, регулировать натяжение ремней или цепей машин во время их работы при эксплуатации технологического, аспирационного и транспортного оборудования?",
        answers: [
          "Разрешается при условии, что работы производятся под наблюдением лица, ответственного за безопасную эксплуатацию оборудования",
          "Запрещается",
          "Разрешается при условии, что данные работы не угрожают безопасности обслуживающего персонала",
          "Разрешается при условии, что работы производятся при наличии наряда-допуска",
        ],
        correctAnswers: ["Запрещается"],
      },
      {
        code: "21103208",
        text: "При помощи чего должен производиться спуск работников в силосы и бункеры (для хранения зерна, муки, отрубей, комбикормов и других продуктов)?",
        answers: [
          "Устройств, утвержденных для этих целей руководителем эксплуатирующей организации",
          "Веревочных складных лестниц",
          "Устройств, предназначенных для проведения работ на высоте",
        ],
        correctAnswers: [
          "Устройств, предназначенных для проведения работ на высоте",
        ],
      },
      {
        code: "21103209",
        text: "В каком случае необходимо устанавливать крюки для подвески талей, блоков над съемными деталями оборудования?",
        answers: [
          "Во всех случаях вне зависимости от массы",
          "Если съемные детали оборудования массой более 35 кг",
          "Если съемные детали оборудования массой более 50 кг",
          "Если съемные детали оборудования массой более 40 кг",
        ],
        correctAnswers: ["Если съемные детали оборудования массой более 50 кг"],
      },
      {
        code: "21103210",
        text: "Какое из перечисленных действий разрешается при производстве работ в силосах и бункерах?",
        answers: [
          "Хождение по насыпи зерна или других продуктов хранения",
          "Нахождение людей под силосом или бункером при разрушении сводов и зависших масс зерна или других продуктов",
          "Доступ в силосы и бункеры через нижний люк",
          "Нахождение людей, не участвующих в разрушении сводов или зависших масс, в зоне лазовых и загрузочных люков",
        ],
        correctAnswers: ["Доступ в силосы и бункеры через нижний люк"],
      },
      {
        code: "21103211",
        text: "Кто должен присутствовать при осмотре или ремонте в надсушильных, подсушильных бункерах и тепловлагообменниках?",
        answers: [
          "Представитель проектной организации",
          "Любой работник цеха или смены",
          "Технический руководитель организации",
          "Начальник цеха или смены",
          "Инженер отдела технического надзора",
        ],
        correctAnswers: ["Начальник цеха или смены"],
      },
      {
        code: "21103212",
        text: "Какая ширина проходов должна быть между отдельными машинами и станками, применяемыми на объектах хранения и переработки растительного сырья?",
        answers: [
          "Все ответы неверны",
          "Не менее 1,5 м",
          "Не менее 1,2 м",
          "Не менее 1 м",
          "Не менее 1,8 м",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21103213",
        text: "Какие из перечисленных вариантов размещения и прокладки оборудования допускаются на объектах хранения и переработки растительного сырья?",
        answers: [
          "Прокладка транзитных воздуховодов через помещения складов сырья и готовой продукции",
          "Размещение оборудования аспирационных установок (фильтров, циклонов, вентиляторов) в производственных помещениях категорий Б и В совместно с транспортным и технологическим оборудованием",
          "Размещение вентиляторов и пылеуловителей отдельно стоящих зерносушилок в рабочих зданиях элеваторов",
          "Прокладка транзитных воздуховодов через помещения разных категорий",
        ],
        correctAnswers: [
          "Размещение оборудования аспирационных установок (фильтров, циклонов, вентиляторов) в производственных помещениях категорий Б и В совместно с транспортным и технологическим оборудованием",
        ],
      },
      {
        code: "21103214",
        text: "С какой периодичностью следует проверять переносные электроинструменты, лампы, трансформаторы на стенде или прибором в отношении исправности их заземляющих проводов и отсутствия замыкания между проводами?",
        answers: [
          "Не реже одного раза в неделю",
          "Не реже одного раза в квартал",
          "Ежедневно",
          "Не реже одного раза в месяц",
          "Не реже одного раза в 10 дней",
        ],
        correctAnswers: ["Не реже одного раза в месяц"],
      },
      {
        code: "21103215",
        text: "После выполнения каких действий допускается включение электромагнитного сепаратора под напряжение? Укажите все правильные ответы.",
        answers: [
          "После измерения сопротивления изоляции обмоток электромагнитов, которое должно быть менее 0,5 МОм",
          "После визуального осмотра ремонтной бригадой",
          "После испытания изоляции",
          "После проверки соответствия всей электрической части техническим регламентам и другим нормативно-техническим документам по устройству электроустановок",
        ],
        correctAnswers: [
          "После испытания изоляции",
          "После проверки соответствия всей электрической части техническим регламентам и другим нормативно-техническим документам по устройству электроустановок",
        ],
      },
      {
        code: "21103216",
        text: "С какой периодичностью должен испытываться и проверяться квалифицированным персоналом пневматический инструмент?",
        answers: [
          "Ежеквартально",
          "Ежемесячно",
          "Ежедневно",
          "Еженедельно",
          "Ежесменно",
        ],
        correctAnswers: ["Ежемесячно"],
      },
      {
        code: "21103217",
        text: "Кто может разрешить пуск вновь установленного оборудования или оборудования после ремонта?",
        answers: [
          "Руководитель территориального управления Федеральной службы по экологическому, технологическому и атомному надзору",
          "Главный энергетик эксплуатирующей организации",
          "Главный механик эксплуатирующей организации",
          "Начальник службы производственного контроля эксплуатирующей организации",
          "Руководитель, технический руководитель или главный инженер эксплуатирующей организации",
        ],
        correctAnswers: [
          "Руководитель, технический руководитель или главный инженер эксплуатирующей организации",
        ],
      },
      {
        code: "21103218",
        text: "Какое устанавливается максимальное усилие, необходимое для перемещения несамоходной разгрузочной тележки?",
        answers: [
          "230 Н (23 кгс)",
          "120 Н (12 кгс)",
          "150 Н (15 кгс)",
          "180 Н (18 кгс)",
        ],
        correctAnswers: ["150 Н (15 кгс)"],
      },
      {
        code: "21103219",
        text: "Какая ширина проходов должна быть между группами машин и станков, применяемых на объектах хранения и переработки растительного сырья?",
        answers: [
          "Не менее 2,5 м",
          "Не менее 0,7 м",
          "Не менее 1,5 м",
          "Не менее 1 м",
        ],
        correctAnswers: ["Не менее 1 м"],
      },
      {
        code: "21103220",
        text: "Чем тушат горящий продукт в подсилосном этаже в целях предотвращения возможного образования пылевого облака при выходе продукта из силоса?",
        answers: [
          "Распыленной водой",
          "Углекислой пеной",
          "Азотом",
          "Направленной струей воды",
          "Паром",
        ],
        correctAnswers: ["Распыленной водой"],
      },
      {
        code: "21103221",
        text: "По разрешению какого лица должно выдаваться сварочное оборудование?",
        answers: [
          "Лица, выдавшего наряд-допуск",
          "Главного инженера (или лица, его замещающего) во время проведения огневых работ",
          "Лица, ответственного за безопасное производство работ.",
        ],
        correctAnswers: [
          "Главного инженера (или лица, его замещающего) во время проведения огневых работ",
        ],
      },
      {
        code: "21103222",
        text: "При каком условии при производстве монтажных работ допускается использовать для закрепления технологической и монтажной оснастки оборудование и трубопроводы, а также технологические и строительные конструкции?",
        answers: [
          "Если работы производятся только обученным персоналом",
          "Если работы производятся только в присутствии ответственного за эксплуатацию оборудования и трубопроводов,  технологических и строительных конструкций",
          "Если производство монтажных работ согласовано с лицами, ответственными за эксплуатацию оборудования и трубопроводов, технологических и строительных конструкций",
        ],
        correctAnswers: [
          "Если производство монтажных работ согласовано с лицами, ответственными за эксплуатацию оборудования и трубопроводов, технологических и строительных конструкций",
        ],
      },
      {
        code: "21103223",
        text: "Работы на какой минимальной высоте должны проводиться с подмостей, огражденных перилами, высотой не менее 1 м с обшивкой по низу не менее 0,15 м?",
        answers: [
          "На высоте 1,0 м и более",
          "На высоте 1,3 м и более",
          "На высоте 1,8 м и более",
        ],
        correctAnswers: ["На высоте 1,3 м и более"],
      },
      {
        code: "21103224",
        text: "Какое требование, установленное Правилами безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья, при наличии в проходе между конвейерами строительных конструкций (колонны, пилястры), создающих местное сужение прохода, указано неверно?",
        answers: [
          "Расстояние между конвейерами и строительными конструкциями должно быть не менее 0,5 м; по длине прохода до 1 м",
          "Расстояние между конвейерами и строительными конструкциями должно быть не менее 0,6 м; по длине прохода до 1,2 м",
          "Места суженного прохода должны быть ограждены",
        ],
        correctAnswers: [
          "Расстояние между конвейерами и строительными конструкциями должно быть не менее 0,6 м; по длине прохода до 1,2 м",
        ],
      },
      {
        code: "21103225",
        text: "Контроль температуры подшипников каких устройств на объектах необходимо осуществлять устройствами дистанционного автоматического контроля температуры?",
        answers: [
          "Турбокомпрессоров",
          "Турбовоздуходувных машин",
          "Дробилок",
          "Вальцовых станков",
          "Всех перечисленных устройств",
        ],
        correctAnswers: ["Всех перечисленных устройств"],
      },
      {
        code: "21103226",
        text: "При каком условии в галереях, тоннелях, под и над площадками светильники подвешиваются на высоте не менее 1,7 м?",
        answers: [
          "При условии, что галереи и тоннели оборудованы аварийной сигнализацией",
          "При условии, что крепление арматуры не мешает движению обслуживающего персонала в проходах",
          "При условии, что применяются светильники, в которых доступ к лампе и токоведущим частям возможен без электроремонтного инструмента",
        ],
        correctAnswers: [
          "При условии, что крепление арматуры не мешает движению обслуживающего персонала в проходах",
        ],
      },
      {
        code: "21103227",
        text: "Чем определяются методы (способы) управления, объемы автоматизации и используемая для этих целей элементная база?",
        answers: [
          "Уровнем взрывобезопасности",
          "Техническим заданием на проектирование",
          "Инструкцией завода-изготовителя",
          "Особенностями технологического процесса",
        ],
        correctAnswers: ["Техническим заданием на проектирование"],
      },
      {
        code: "21103228",
        text: "Какое требование электростатической искробезопасности на объектах хранения и переработки растительного сырья указано неверно?",
        answers: [
          "Допускается использование общего заземляющего устройства для защиты от статического электричества, первичных и вторичных воздействий молнии и защитного заземления электроустановок",
          "Во фланцевых соединениях допускается применение шайб, изготовленных из диэлектриков",
          "Вставки из брезента, резины, установленные на аспирационных воздуховодах, должны иметь перемычки из проволоки или троса",
          "Технологическое оборудование и продуктопроводы, расположенные во взрыво- и пожароопасных зонах всех классов, должны быть заземлены не менее чем в двух местах, с выравниванием потенциалов до безопасных значений",
        ],
        correctAnswers: [
          "Во фланцевых соединениях допускается применение шайб, изготовленных из диэлектриков",
        ],
      },
      {
        code: "21103229",
        text: "Кто осуществляет контроль за исправностью инструмента, находящегося в работе?",
        answers: [
          "Руководитель подразделения",
          "Руководитель (производитель) работ",
          "Непосредственно работники",
          "Мастер ремонтного цеха или лицо, назначенное главным инженером",
        ],
        correctAnswers: [
          "Мастер ремонтного цеха или лицо, назначенное главным инженером",
        ],
      },
      {
        code: "21103230",
        text: "Какое из предъявляемых требований к крепежу деталей в машинах на объектах хранения и переработки растительного сырья указано неверно?",
        answers: [
          "В конструкции машин (снаружи и внутри) должны быть предусмотрены меры, исключающие самоотвинчивание крепежных деталей",
          "Вертикально расположенные болты должны устанавливаться вниз головкой",
          "Болты должны иметь выступающую нарезную часть не более двух - двух с половиной витков резьбы",
        ],
        correctAnswers: [
          "Вертикально расположенные болты должны устанавливаться вниз головкой",
        ],
      },
      {
        code: "21103231",
        text: "Какое минимальное количество металлических стяжных болтов, установленных под ступенями, должны иметь переносные деревянные лестницы и раздвижные лестницы-стремянки длиной более 3 м?",
        answers: ["Не менее четырех", "Не менее трех", "Не менее двух"],
        correctAnswers: ["Не менее двух"],
      },
      {
        code: "21103232",
        text: "Под непосредственным наблюдением какого лица должны осуществляться работы на высоте, а также по подъему и перемещению оборудования и других тяжеловесных грузов?",
        answers: [
          "Бригадира",
          "Специально назначенного работника ответственного за работу на высоте",
          "Ответственного за соблюдение мер безопасности",
        ],
        correctAnswers: ["Ответственного за соблюдение мер безопасности"],
      },
      {
        code: "21103233",
        text: "Какие из перечисленных средств ДАУ, блокировки, контроля и противоаварийной защиты, производственной и аварийной сигнализации не должны предусматриваться (определяться на стадиях разработки процесса) на объектах?",
        answers: [
          "Контроль за работой норий",
          "Дистанционный контроль за верхним и нижним уровнями сырья и продуктов в силосах и бункерах",
          "Аварийная остановка всех электродвигателей цеха с любого этажа и пульта управления (диспетчерской)",
          "Контроль за ошибочными действиями персонала в аварийных случаях",
        ],
        correctAnswers: [
          "Контроль за ошибочными действиями персонала в аварийных случаях",
        ],
      },
      {
        code: "21103234",
        text: "Кем определяется рациональный выбор средств ДАУ, блокировки, контроля и противоаварийной защиты?",
        answers: [
          "Проектной организацией",
          "Главным инженером",
          "Ростехнадзором",
          "Разработчиками ДАУ",
        ],
        correctAnswers: ["Разработчиками ДАУ"],
      },
      {
        code: "21103235",
        text: "Какое количество человек должно участвовать в работе по отбору проб зерна из трюма, склада или бунта?",
        answers: [
          "1 человек",
          "Не менее 2 человек",
          "Назначается ответственным лицом в каждом отдельном случае",
          "Не регламентируется",
        ],
        correctAnswers: ["Не менее 2 человек"],
      },
      {
        code: "21103236",
        text: "При какой объемной доле горючих газов в свободном объеме горящего и смежных с ним силосов, бункеров производится выгрузка продукта хранения, подвергшегося самосогреванию (самовозгоранию)?",
        answers: [
          "Не превышающей 18%",
          "Не превышающей 10%",
          "Не превышающей 5%",
        ],
        correctAnswers: ["Не превышающей 5%"],
      },
      {
        code: "21103237",
        text: "В каком случае допускается спуск людей в силосы и бункеры без их письменного согласия?",
        answers: [
          "В случаях, связанных со спасением пострадавших",
          "В аварийных случаях",
          "Запрещается в любых случаях",
        ],
        correctAnswers: ["Запрещается в любых случаях"],
      },
      {
        code: "21103238",
        text: "Каким должно быть сопротивление изоляции обмоток электромагнитов?",
        answers: ["Не менее 0,1 МОм", "Не менее 0,3 МОм", "Не менее 0,5 МОм"],
        correctAnswers: ["Не менее 0,5 МОм"],
      },
      {
        code: "21103239",
        text: "Что из перечисленного должна предусматривать система планово-предупредительного ремонта?",
        answers: [
          "Только поддержание оборудования в рабочем состоянии, а также текущее наблюдение и периодический осмотр состояния оборудования объектов целях своевременного выявления неисправностей и их устранения",
          "Только подготовку зданий и сооружений к использованию, а машин и оборудования - к работе; защиту от атмосферных, тепловых и прочих воздействий внешней среды",
          "Только уход за зданиями, сооружениями и оборудованием во время их эксплуатации с соблюдением установленных режимов использования, наблюдения, смазки",
          "Все перечисленное",
          "Только своевременное и качественное проведение текущего и капитального ремонтов, осуществляемых в планово-предупредительном порядке",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21103240",
        text: "Какой решеткой должна быть ограждена открытая часть шнека, применяемого для погрузки в железнодорожные вагоны или автомашины отрубей, мучки, комбикормов и других сыпучих грузов?",
        answers: [
          "Решеткой с размером ячеек 100 x 50 мм",
          "Решеткой с размером ячеек 150 x 60 мм",
          "Решеткой с размером ячеек 250 x 75 мм",
        ],
        correctAnswers: ["Решеткой с размером ячеек 250 x 75 мм"],
      },
      {
        code: "21103241",
        text: "Что из перечисленного допускается при погрузочно-разгрузочных работах с зерном и другими сыпучими продуктами на железнодорожном транспорте? Выберите 2 варианта ответа.",
        answers: [
          "Зависший в вагоне продукт следует удалять ломами",
          "Допускается доступ работающих внутрь вагонов-зерновозов при наличии в них продукта",
          "При погрузочно-разгрузочных работах с зерном и другими сыпучими продуктами на железнодорожном транспорте необходимо открывать два загрузочных люка",
          "К погрузочно-разгрузочным работам с зерном и другими сыпучими продуктами на железнодорожном транспорте допускаются только лица, обученные безопасным методам работы и обеспеченные средствами индивидуальной защиты",
        ],
        correctAnswers: [
          "К погрузочно-разгрузочным работам с зерном и другими сыпучими продуктами на железнодорожном транспорте допускаются только лица, обученные безопасным методам работы и обеспеченные средствами индивидуальной защиты",
        ],
      },
      {
        code: "21103242",
        text: "Какое из перечисленных действий допускается при аварийной выгрузке продукта хранения из силосов и бункеров?",
        answers: [
          "Прерывать процесс флегматизации аварийного и смежных с ним силосов, бункеров до полного их освобождения от продукта",
          "Прерывать выгрузку продукта и оставлять частично разгруженные силосы, бункеры",
          "Тушение загорания компактной направленной струей воды",
          "Производить выгрузку при одновременной подаче инертных газов в подсводное, а в необходимых случаях и в надсводное пространство силосов и бункеров",
        ],
        correctAnswers: [
          "Производить выгрузку при одновременной подаче инертных газов в подсводное, а в необходимых случаях и в надсводное пространство силосов и бункеров",
        ],
      },
    ],
  },
  89: {
    21201: [
      {
        code: "21201000",
        text: "Каким федеральным органом исполнительной власти осуществляется лицензирование деятельности, связанной с обращением взрывчатых материалов промышленного назначения?",
        answers: [
          "Ростехнадзором",
          "МЧС России",
          "Совместно Ростехнадзором и МЧС России",
          "Минприроды России",
        ],
        correctAnswers: ["Ростехнадзором"],
      },
      {
        code: "21201001",
        text: "Кем выдается разрешение на ведение работ со взрывчатыми материалами промышленного назначения?",
        answers: [
          "Территориальным органом Ростехнадзора",
          "Центральным аппаратом Ростехнадзора",
          "Органами МВД России",
          "Ростехнадзором по согласованию с органами МВД России",
        ],
        correctAnswers: ["Территориальным органом Ростехнадзора"],
      },
      {
        code: "21201002",
        text: "Какой максимальный срок предоставления государственной услуги при выдаче (отказе в выдаче) разрешения на ведение работ со взрывчатыми материалами промышленного назначения со дня регистрации заявления?",
        answers: [
          "Не более 30 рабочих дней",
          "Не более 45 рабочих дней",
          "Не более 50 рабочих дней",
          "Не более 60 рабочих дней",
        ],
        correctAnswers: ["Не более 30 рабочих дней"],
      },
      {
        code: "21201003",
        text: "Что из перечисленных документов прилагается к заявлению на выдачу разрешения на ведение работ со взрывчатыми материалами промышленного назначения при взрывных работах в подземных условиях?",
        answers: [
          "Сведения об опасности шахты (рудника, объекта геолого-разведочных работ) по газу и пыли",
          "План местности с нанесением мест производства взрывных работ",
          "Схемы профилей работ, типовая схема охраны опасной зоны",
          "Проект на взрывные работы",
        ],
        correctAnswers: [
          "Сведения об опасности шахты (рудника, объекта геолого-разведочных работ) по газу и пыли",
        ],
      },
      {
        code: "21201004",
        text: "Что является основанием для отказа в выдаче разрешения на ведение работ со взрывчатыми материалами промышленного назначения при соответствии заявительных документов требованиям законодательства Российской Федерации и нормативных правовых актов в области промышленной безопасности опасных производственных объектов?",
        answers: [
          "Планирование взрывных работ в прибрежной водоохранной зоне",
          "Планирование взрывных работ в районе населенных пунктов",
          "Истечение срока рассмотрения заявления",
          "Наличие в составе материалов заявителя неполных, искаженных или недостоверных сведений",
        ],
        correctAnswers: [
          "Наличие в составе материалов заявителя неполных, искаженных или недостоверных сведений",
        ],
      },
      {
        code: "21201005",
        text: "На какой срок выдается разрешение на ведение работ со взрывчатыми материалами промышленного назначения?",
        answers: [
          "Срок действия разрешения устанавливается территориальным органом Ростехнадзора в зависимости от характера взрывных работ, но не более чем на один год",
          "Срок действия разрешения устанавливается территориальным органом Ростехнадзора не более чем на два года",
          "Срок действия разрешения устанавливается территориальным органом Ростехнадзора в зависимости от характера взрывных работ, но не более чем на шесть месяцев",
        ],
        correctAnswers: [
          "Срок действия разрешения устанавливается территориальным органом Ростехнадзора в зависимости от характера взрывных работ, но не более чем на один год",
        ],
      },
      {
        code: "21201006",
        text: "Кто может получить профессию взрывника, имея стаж работы в шахтах, опасных по газу и пыли?",
        answers: [
          "Лица мужского пола не моложе 16 лет, имеющие среднее специальное образование",
          "Лица мужского пола не моложе 21 года, имеющие стаж работы не менее одного года по специальности, соответствующей характеру работы организации",
          "Лица мужского пола старше 18 лет, имеющие образование не ниже среднего и стаж на подземных работах проходчика или рабочего очистного забоя не менее 2 лет",
          "Лица мужского пола, имеющие высшее горно-техническое образование и стаж не менее одного года по специальности",
        ],
        correctAnswers: [
          "Лица мужского пола старше 18 лет, имеющие образование не ниже среднего и стаж на подземных работах проходчика или рабочего очистного забоя не менее 2 лет",
        ],
      },
      {
        code: "21201007",
        text: "В течение какого времени взрывник должен отработать стажером под руководством опытного взрывника перед допуском к самостоятельному производству взрывных работ,в том числе после обучения на новый вид взрывных работ?",
        answers: [
          "В течение 2 недель",
          "В течение 1 месяца",
          "В течение 3 недель",
          "В течение 2 месяцев",
        ],
        correctAnswers: ["В течение 1 месяца"],
      },
      {
        code: "21201008",
        text: "Кто может получить Единую книжку взрывника на право руководства взрывными работами?",
        answers: [
          "Лица, имеющие высшее или среднее профессиональное горнотехническое образование, либо высшее или среднее профессиональное образование, связанное с обращением взрывчатых материалов",
          "Лица, окончившие средние специальные учебные заведения или получившие образование по очной форме, не связанное с обращением взрывчатых материалов",
          "Лица, окончившие любые высшие учебные заведения",
          "Все перечисленные лица",
        ],
        correctAnswers: [
          "Лица, имеющие высшее или среднее профессиональное горнотехническое образование, либо высшее или среднее профессиональное образование, связанное с обращением взрывчатых материалов",
        ],
      },
      {
        code: "21201009",
        text: "В каком из перечисленных случаев у взрывника может быть изъята Единая книжка взрывника?",
        answers: [
          "За неоднократные нарушения режима рабочего времени",
          "За нарушение установленного порядка хранения, транспортирования, использования или учета взрывчатых материалов, которое привело или могло привести к несчастному случаю, аварии или утрате взрывчатых материалов",
          "За появление на рабочем месте в состоянии алкогольного опьянения",
          "За нарушения требований охраны труда при выполнении взрывных работ",
        ],
        correctAnswers: [
          "За нарушение установленного порядка хранения, транспортирования, использования или учета взрывчатых материалов, которое привело или могло привести к несчастному случаю, аварии или утрате взрывчатых материалов",
        ],
      },
      {
        code: "21201010",
        text: "Какую информацию должен содержать акт по установлению причин инцидента на опасном производственном объекте?",
        answers: [
          "Только дату и место инцидента, его причины и обстоятельства",
          "Только сведения о принятых мерах по ликвидации инцидента",
          "Только сведения о продолжительности простоя и материальном ущербе",
          "Всю перечисленную информацию, а также информацию о вреде, нанесенном окружающей среде, и мерах по ликвидации инцидента",
        ],
        correctAnswers: [
          "Всю перечисленную информацию, а также информацию о вреде, нанесенном окружающей среде, и мерах по ликвидации инцидента",
        ],
      },
      {
        code: "21201011",
        text: "С какой периодичностью должна направляться информация о произошедших инцидентах в территориальный орган Ростехнадзора?",
        answers: [
          "Не реже одного раза в квартал",
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в год",
          "Информация направляется только при наличии инцидентов сразу после их наступления",
        ],
        correctAnswers: ["Не реже одного раза в квартал"],
      },
      {
        code: "21201012",
        text: "На какую из перечисленных областей направлено техническое расследование случаев утраты взрывчатых материалов промышленного назначения?",
        answers: [
          "Только на установление обстоятельств и причин утраты взрывчатых материалов промышленного назначения",
          "Только на выявление организационных и технических недостатков, приведших к нарушению действующего порядка хранения, перевозки использования и учета взрывчатых материалов промышленного назначения",
          "Только на определение мероприятий по недопущению подобных случаев утраты взрывчатых материалов промышленного назначения в дальнейшем",
          "На все перечисленное",
        ],
        correctAnswers: ["На все перечисленное"],
      },
      {
        code: "21201013",
        text: "Какие из перечисленных случаев утрат взрывчатых материалов промышленного назначения, произошедшие в организациях и на объектах, поднадзорных Ростехнадзору, подлежат техническому расследованию и учету?",
        answers: [
          "Только утраты в результате пожаров",
          "Только утраты в результате стихийных бедствий",
          "Только утраты в результате промышленных аварий",
          "Все случаи утрат взрывчатых материалов",
        ],
        correctAnswers: ["Все случаи утрат взрывчатых материалов"],
      },
      {
        code: "21201014",
        text: "В какие сроки после получения оперативного сообщения об утрате взрывчатых материалов промышленного назначения должна быть сформирована комиссия по техническому расследованию обстоятельств и причин утраты взрывчатых материалов?",
        answers: [
          "Не позднее 24 часов",
          "Не позднее 36 часов",
          "Не позднее 48 часов",
          "Не позднее 72 часов",
        ],
        correctAnswers: ["Не позднее 24 часов"],
      },
      {
        code: "21201015",
        text: "Представители какой организации должны быть включены в состав комиссии по техническому расследованию обстоятельств и причин утраты взрывчатых материалов промышленного назначения?",
        answers: [
          "Только представители организации, в которой произошла утрата взрывчатых материалов",
          "Только представители территориальных органов ФСБ России",
          "Только представитель МВД России",
          "Все перечисленные представители",
        ],
        correctAnswers: ["Все перечисленные представители"],
      },
      {
        code: "21201016",
        text: "Какие полномочия имеет комиссия по техническому расследованию обстоятельств и причин утраты взрывчатых материалов промышленного назначения в ходе технического расследования?",
        answers: [
          "Только привлечение к расследованию независимых экспертных организаций",
          "Только привлечение к расследованию независимых экспертов, специалистов",
          "Только проведение опросов и получение объяснений работников организации для уточнения обстоятельств утраты взрывчатых материалов, выявления недостатков в организации производства взрывных работ, а также для определения конкретных причин нарушения установленного порядка хранения, перевозки, использования и учета взрывчатых материалов промышленного назначения",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "21201017",
        text: 'На что не распространяется действие технического регламента Таможенного союза от 20.07.2012 № 028/2012 "О безопасности взрывчатых веществ и изделий на их основе"?',
        answers: [
          "На взрывчатые вещества и изделия на их основе, разрабатываемые (проектируемые) и изготавливаемые для использования энергии взрыва в промышленных целях",
          "На взрывчатые вещества и изделия на их основе, относящиеся к оборонной продукции, и на пиротехнические изделия",
          "На взрывчатые вещества, непосредственно не применяемые для использования энергии взрыва в промышленных целях, но используемые для производства взрывчатых веществ и изделий для такого применения",
          "На эмульсии и матрицы окислителя на основе нитрата аммония, разрабатываемые (проектируемые) и изготавливаемые для получения водоэмульсионных и водногелевых взрывчатых веществ",
        ],
        correctAnswers: [
          "На взрывчатые вещества и изделия на их основе, относящиеся к оборонной продукции, и на пиротехнические изделия",
        ],
      },
      {
        code: "21201018",
        text: "Какой документ должен быть выдан на взрывчатые вещества и изделия на их основе, разрабатываемые (проектируемые) и изготавливаемые для использования энергии взрыва в промышленных целях?",
        answers: [
          "Инструкция по применению на всех государственных языках государств - членов Таможенного союза",
          "Лицензию на применение, выданную Министерством РФ по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "Разрешение на постоянное применение, выданное одним из уполномоченных органов в области промышленной безопасности государства - члена Таможенного союза",
          "Разрешение на постоянное применение, выданное всеми уполномоченными органами в области промышленной безопасности государств - членов Таможенного союза",
        ],
        correctAnswers: [
          "Разрешение на постоянное применение, выданное одним из уполномоченных органов в области промышленной безопасности государства - члена Таможенного союза",
        ],
      },
      {
        code: "21201019",
        text: 'В каком случае не требуется подтверждение соответствия взрывчатых веществ требованиям технического регламента Таможенного союза от 20.07.2012 N 028/2012 "О безопасности взрывчатых веществ и изделий на их основе"?',
        answers: [
          "Для взрывчатых веществ и изделий для использования энергии взрыва в промышленных целях",
          "Для взрывчатых веществ, выпускаемых в обращение на единой таможенной территории государств - членов Таможенного союза",
          "Для взрывчатых веществ и изделий на их основе, изготавливаемых для собственных нужд",
          "Подтверждение соответствия требуется в любом случае",
        ],
        correctAnswers: [
          "Для взрывчатых веществ и изделий на их основе, изготавливаемых для собственных нужд",
        ],
      },
      {
        code: "21201020",
        text: "На какой максимальный срок устанавливается срок действия сертификата соответствия взрывчатых веществ?",
        answers: ["На 1 год", "На 2 года", "На 3 года", "На 5 лет"],
        correctAnswers: ["На 3 года"],
      },
      {
        code: "21201021",
        text: "В течение какого времени комиссия по техническому расследованию обстоятельств и причин утраты взрывчатых материалов промышленного назначения должна составить акт технического расследования случая утраты?",
        answers: [
          "В течение 15 рабочих дней",
          "В течение 20 рабочих дней",
          "В течение 25 рабочих дней",
          "В течение 30 рабочих дней",
        ],
        correctAnswers: ["В течение 15 рабочих дней"],
      },
      {
        code: "21201022",
        text: "С какой периодичностью должны проверяться знания требований безопасности работниками, связанными с обращением со взрывчатыми материалами и имеющими Единую книжку взрывника (за исключением заведующих складами взрывчатых материалов, пунктами производства взрывчатых материалов и руководителей взрывных работ) специальной комиссией организации с участием представителя территориального органа Ростехнадзора?",
        answers: [
          "Не реже одного раза в два года",
          "Не реже одного раза в два с половиной года",
          "Не реже одного раза в три года",
          "Не реже одного раза в пять лет",
        ],
        correctAnswers: ["Не реже одного раза в два года"],
      },
      {
        code: "21201023",
        text: "Какое из перечисленных лицензионных требований не предъявляется к соискателю лицензии на осуществление лицензируемого вида деятельности, связанной с обращением взрывчатых материалов промышленного назначения?",
        answers: [
          "Предоставление сведений об организации",
          "Указание должности, фамилии, иммени и (при наличии) отчества руководителя организации",
          "Указание наименования взрывчатого материала",
          "Предоставление сведений о разработчике и об изготовителе (изготовителях) взрывчатого материала",
          "Предоставление документов и информации, которые находятся в распоряжении органов местного самоуправления",
        ],
        correctAnswers: [
          "Предоставление документов и информации, которые находятся в распоряжении органов местного самоуправления",
        ],
      },
      {
        code: "21201024",
        text: "С какой периодичностью проводится пересмотр Регламента технологического процесса производства и подготовки взрывчатых веществ?",
        answers: [
          "Не реже 1 раза в 5 лет",
          "Не реже 1 раза в 7 лет",
          "Не реже 1 раза в 10 лет",
          "Не нормируется",
        ],
        correctAnswers: ["Не реже 1 раза в 5 лет"],
      },
      {
        code: "21201025",
        text: "Каким лицам право руководства взрывными работами предоставляется после дополнительного обучения по программе, согласованной с федеральным органом исполнительной власти в области промышленной безопасности?",
        answers: [
          'Лицам, окончившим средние специальные учебные заведения по специальности "Подземная разработка месторождений полезных ископаемых"',
          'Лицам, окончившим высшие учебные заведения по специальности "Открытые горные работы"',
          'Лицам, окончившим средние специальные учебные заведения по специальности "Шахтное строительство"',
          'Лицам, окончившим высшие учебные заведения по специальности "Подземная разработка пластовых месторождений"',
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21201026",
        text: "В течение какого времени должна проводиться стажировка взрывников перед допуском к самостоятельному производству взрывных работ, в том числе после обучения на новый вид взрывных работ?",
        answers: [
          "В течение 2 недель",
          "В течение одного месяца",
          "В течение 3 недель",
          "В течение 2 месяцев",
        ],
        correctAnswers: ["В течение одного месяца"],
      },
      {
        code: "21201027",
        text: "Куда передается акт об уничтожении взрывчатых материалов?",
        answers: [
          "В бухгалтерию предприятия",
          "В отдел охраны труда и техники безопасности",
          "В дирекцию организации",
          "На склад взрывчатых материалов",
        ],
        correctAnswers: ["На склад взрывчатых материалов"],
      },
      {
        code: "21201028",
        text: "В течение какого срока в организации хранятся проекты буровзрывных (взрывных) работ, паспорта, схемы, в соответствии с которыми осуществляются взрывные работы?",
        answers: [
          "Не менее трех месяцев",
          "Не менее шести месяцев",
          "Не менее девяти месяцев",
          "Не менее года",
        ],
        correctAnswers: ["Не менее года"],
      },
      {
        code: "21201029",
        text: "Кто утверждает регламент технологического процесса производства и подготовки взрывчатых веществ и изменения в него?",
        answers: [
          "Руководитель (технический руководитель) эксплуатирующей организации",
          "Начальник участка охраны труда и техники безопасности",
          "Начальник пункта производства и (или) подготовки взрывчатых веществ",
          "Руководитель Ростехнадзора",
        ],
        correctAnswers: [
          "Руководитель (технический руководитель) эксплуатирующей организации",
        ],
      },
      {
        code: "21201030",
        text: "Какой срок устанавливается для составления акта технического расследования причин аварии на опасном производственном объекте?",
        answers: [
          "Не позднее 30 календарных дней со дня завершения технического расследования причин аварии",
          "Не позднее 35 календарных дней со дня завершения технического расследования причин аварии",
          "Не позднее 40 календарных дней со дня завершения технического расследования причин аварии",
          "Не позднее 60 календарных дней со дня завершения технического расследования причин аварии",
        ],
        correctAnswers: [
          "Не позднее 30 календарных дней со дня завершения технического расследования причин аварии",
        ],
      },
      {
        code: "21201031",
        text: "Чьим приказом назначается комиссия по расследованию причин инцидентов на опасном производственном объекте?",
        answers: [
          "Приказом руководителя Ростехнадзора",
          "Приказом руководителя местного муниципального органа",
          "Приказом руководителя организации, эксплуатирующей объект",
          "Приказом руководителя профессиональной аварийно-спасательной службы",
        ],
        correctAnswers: [
          "Приказом руководителя организации, эксплуатирующей объект",
        ],
      },
      {
        code: "21201032",
        text: "Кто возглавляет комиссию по техническому расследованию причин аварии на опасном производственном объекте?",
        answers: [
          "Представитель Ростехнадзора или ее территориального органа",
          "Руководитель опасного производственного объекта",
          "Технический руководитель опасного производственного объекта",
          "Представитель профессиональной аварийно-спасательной службы",
        ],
        correctAnswers: [
          "Представитель Ростехнадзора или ее территориального органа",
        ],
      },
      {
        code: "21201033",
        text: "Какое число членов должно входить в состав комиссии по техническому расследованию причин аварии на опасном производственном объекте?",
        answers: [
          "Не менее трех человек",
          "Не менее пяти человек",
          "Не менее шести человек",
          "Не менее десяти человек",
        ],
        correctAnswers: ["Не менее пяти человек"],
      },
      {
        code: "21201034",
        text: "На сколько может быть увеличен срок технического расследования причин аварии на опасном производственном объекте?",
        answers: [
          "Не более чем на 15 календарных дней",
          "Не более чем на 20 календарных дней",
          "Не более чем на 25 календарных дней",
          "Не более чем на 35 календарных дней",
        ],
        correctAnswers: ["Не более чем на 15 календарных дней"],
      },
      {
        code: "21201035",
        text: "В течение какого срока передается оперативное сообщение об аварии, инциденте на опасном производственном объекте?",
        answers: [
          "В течение 24 часов с момента возникновения аварии, инцидента",
          "В течение 36 часов с момента возникновения аварии, инцидента",
          "В течение 48 часов с момента возникновения аварии, инцидента",
          "В течение 60 часов с момента возникновения аварии, инцидента",
        ],
        correctAnswers: [
          "В течение 24 часов с момента возникновения аварии, инцидента",
        ],
      },
      {
        code: "21201036",
        text: "Куда организация обязана направить результаты технического расследования причин аварии?",
        answers: [
          "В местный муниципальный орган",
          "В профессиональную аварийно-спасательную службу",
          "В МЧС",
          "В территориальный орган уполномоченного органа",
        ],
        correctAnswers: ["В территориальный орган уполномоченного органа"],
      },
      {
        code: "21201037",
        text: "Чем регламентируется порядок проведения работ по установлению причин инцидентов на опасном производственном объекте?",
        answers: [
          "Правилами безопасности при производстве, хранении и применении взрывчатых материалов промышленного назначения",
          "Регламентом технологического процесса производства и подготовки взрывчатых веществ",
          "Постановление Правительства РФ от 15 сентября 2020 г. № 1435 О лицензировании деятельности, связанной с обращением взрывчатых материалов промышленного назначения",
          "Документами, утвержденными организацией, эксплуатирующей объект.",
        ],
        correctAnswers: [
          "Документами, утвержденными организацией, эксплуатирующей объект.",
        ],
      },
      {
        code: "21201038",
        text: "Куда передается оперативное сообщение об аварии?",
        answers: [
          "Только в территориальный орган федерального органа исполнительной власти в области промышленной безопасности, вышестоящий орган или организацию (при наличии) и орган местного самоуправления, на территории которого располагается опасный производственный объект",
          "Только в страховую организацию, с которой заключен договор обязательного страхования гражданской ответственности и федеральный орган исполнительной власти, осуществляющий функции по контролю и надзору в области охраны окружающей среды (при авариях, связанных с выбросом опасных веществ)",
          "Только в комиссию по предупреждению и ликвидации чрезвычайных ситуаций и обеспечению пожарной безопасности субъекта Российской Федерации (при авариях)",
          "Только в территориальный орган Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий, на территории деятельности которого произошла авария",
          "Во все перечисленные организации",
        ],
        correctAnswers: ["Во все перечисленные организации"],
      },
      {
        code: "21201039",
        text: "В течение какого времени после получения оперативного сообщения об аварии издается приказ Службы или ее территориального органа?",
        answers: [
          "Не позднее 24 часов",
          "Не позднее 36 часов",
          "Не позднее 48 часов",
          "Не позднее 60 часов",
        ],
        correctAnswers: ["Не позднее 24 часов"],
      },
      {
        code: "21201040",
        text: "Где хранятся подлинники документов, включенных в материалы технического расследования случаев утраты взрывчатых материалов промышленного назначения?",
        answers: [
          "В Ростехнадзоре",
          "В архиве территориального муниципального образования",
          "В организации, где произошел случай утраты взрывчатых материалов промышленного назначения",
          "В профессиональной аварийно-спасательной службе",
        ],
        correctAnswers: [
          "В организации, где произошел случай утраты взрывчатых материалов промышленного назначения",
        ],
      },
      {
        code: "21201041",
        text: "Кем осуществляется расчет вреда (экономического и экологического ущерба) от аварии?",
        answers: [
          "Сторонней организаций, имеющей лицензию на выполнение экономических расчетов",
          "Ростехнадзором",
          "Организацией, на объекте которой произошла авария",
          "Муниципальным образованием",
        ],
        correctAnswers: ["Организацией, на объекте которой произошла авария"],
      },
      {
        code: "21201042",
        text: "По каким адресам организация, в которой имел место случай утраты взрывчатых материалов промышленного назначения, рассылает материалы технического расследования и копию своего приказа по результатам расследования (если он издавался)?",
        answers: [
          "Только в вышестоящий орган или организацию (при наличии)",
          "Только в территориальный орган федерального органа в области промышленной безопасности, проводивший расследование",
          "Только в территориальный орган Министерства внутренних дел Российской Федерации по месту совершения предполагаемого факта хищения взрывчатых материалов промышленного назначения",
          "По всем перечисленным адресам",
        ],
        correctAnswers: ["По всем перечисленным адресам"],
      },
      {
        code: "21201043",
        text: "На кого возлагается финансирование расходов на техническое расследование причин аварий?",
        answers: [
          "На организацию, эксплуатирующую опасный производственный объект, на котором произошла авария",
          "На территориальный орган Ростехнадзора",
          "На организацию, эксплуатирующую опасные производственные объекты, или страховую компанию, в которой застрахована гражданская ответственность этой организации",
          "На страховую компанию, с которой заключен договор обязательного страхования гражданской ответственности владельца опасного объекта за причинение вреда в результате аварии на опасном объекте",
        ],
        correctAnswers: [
          "На организацию, эксплуатирующую опасный производственный объект, на котором произошла авария",
        ],
      },
      {
        code: "21201044",
        text: "В какой срок материалы технического расследования аварии направляются территориальным органом Ростехнадзора в центральный аппарат Ростехнадзора?",
        answers: [
          "В двухнедельный срок",
          "В трехнедельный срок",
          "В месячный срок",
          "По мере готовности",
        ],
        correctAnswers: ["В двухнедельный срок"],
      },
      {
        code: "21201045",
        text: "В течение какого срока с даты подписания акта технического расследования причин аварии руководителем организации издается приказ, определяющий меры по устранению причин и последствий аварии, по обеспечению безаварийной и стабильной работы опасного производственного объекта?",
        answers: [
          "В течение 7 рабочих дней",
          "В течение 9 рабочих дней",
          "В течение 15 рабочих дней",
          "В течение 30 рабочих дней",
        ],
        correctAnswers: ["В течение 7 рабочих дней"],
      },
      {
        code: "21201046",
        text: "Что из перечисленных документов прилагается к заявлению о выдаче разрешения на постоянное применение взрывчатых веществ и изделий на их основе?",
        answers: [
          "Программа и методика приемочных испытаний; акт приемочных испытаний опытной партии; экспертное заключение по промышленной безопасности; технические условия (стандарт) (для взрывчатых материалов иностранного производства - при наличии); руководство (инструкция) по применению",
          "Акт приемочных испытаний опытной партии; экспертное заключение по промышленной безопасности; технические условия; руководство (инструкция) по применению",
          "Акт приемочных испытаний опытной партии; экспертное заключение по промышленной безопасности; технические условия (стандарт) (для взрывчатых материалов иностранного производства - при наличии); руководство (инструкция) по применению",
          "Акт контрольных испытаний; акт приемочных испытаний опытной партии; экспертное заключение по промышленной безопасности; технические условия (стандарт) (для взрывчатых материалов иностранного производства - при наличии); руководство (инструкция) по применению",
        ],
        correctAnswers: [
          "Акт приемочных испытаний опытной партии; экспертное заключение по промышленной безопасности; технические условия (стандарт) (для взрывчатых материалов иностранного производства - при наличии); руководство (инструкция) по применению",
        ],
      },
      {
        code: "21201047",
        text: "Кто является заявителем государственной услуги по выдаче разрешений на постоянное применение взрывчатых веществ и изделий на их основе?",
        answers: [
          "Только юридические лица, осуществляющие изготовление взрывчатых материалов, либо их законные представители",
          "Только юридические лица, являющиеся потребителями взрывчатых материалов, либо их законные представители",
          "Только физические лица, ведущие взрывные работы и имеющие лицензии в области взрывчатых материалов промышленного назначения",
          "Любой из перечисленных",
        ],
        correctAnswers: [
          "Только юридические лица, осуществляющие изготовление взрывчатых материалов, либо их законные представители",
        ],
      },
      {
        code: "21201048",
        text: "Что в обязательном порядке указывается в разрешении на постоянное применение взрывчатых веществ и изделий на их основе?",
        answers: [
          "Номер лицензии",
          "Изготовитель",
          "Сведения о сертификате",
          "Сведения о маркировке",
        ],
        correctAnswers: ["Изготовитель"],
      },
      {
        code: "21201049",
        text: "К какой группе совместимости (опасности) относятся изделия, содержащие инициирующие взрывчатые вещества и имеющие менее двух независимых предохранительных устройств?",
        answers: ["К группе А", "К группе B", "К группе C", "К группе D"],
        correctAnswers: ["К группе B"],
      },
      {
        code: "21201050",
        text: "К какому подклассу относятся взрывчатые вещества и изделия на их основе, способные взрываться массой?",
        answers: [
          "К подклассу 1.1",
          "К подклассу 1.2",
          "К подклассу 1.3",
          "К подклассу 1.4",
        ],
        correctAnswers: ["К подклассу 1.1"],
      },
      {
        code: "21201051",
        text: "В каком из перечисленных случаев взрывчатые материалы не должны подвергаться испытаниям?",
        answers: [
          "При поступлении на склад взрывчатых материалов",
          "При возникновении сомнений в доброкачественности",
          "Перед истечением гарантийного срока",
        ],
        correctAnswers: ["При поступлении на склад взрывчатых материалов"],
      },
      {
        code: "21201052",
        text: "Какой цвет отличительной полосы или оболочек патронов (пачек) должны иметь предохранительные взрывчатые вещества для взрывания только по породе в забоях подземных выработок, в которых имеется выделение горючих газов, но отсутствует взрывчатая угольная (сланцевая) пыль?",
        answers: ["Синий", "Красный", "Белый", "Желтый"],
        correctAnswers: ["Синий"],
      },
      {
        code: "21201053",
        text: "На каком расстоянии от греющих поверхностей (печей, труб, радиаторов) должны находиться столы и полки, на которых раскладываются при сушке взрывчатые вещества в помещении?",
        answers: [
          "Не менее 1,0 м",
          "Не менее 0,9 м",
          "Не менее 0,7 м",
          "Не менее 0,5 м",
        ],
        correctAnswers: ["Не менее 1,0 м"],
      },
      {
        code: "21201054",
        text: "Какой должна быть температура воздуха в помещениях для сушки взрывчатых веществ?",
        answers: [
          "Не выше 50 °С",
          "Не выше 55 °С",
          "Не выше 60 °С",
          "Не выше 70 °С",
        ],
        correctAnswers: ["Не выше 50 °С"],
      },
      {
        code: "21201055",
        text: "Какой должна быть температура воздуха в помещениях для сушки дымного пороха?",
        answers: [
          "Не выше 40 °С",
          "Не выше 45 °С",
          "Не выше 50 °С",
          "Не выше 55 °С",
        ],
        correctAnswers: ["Не выше 40 °С"],
      },
      {
        code: "21201056",
        text: "При какой температуре воздуха должно проводиться оттаивание взрывчатых веществ, находящихся в заводской упаковке, в поверхностных складах в отапливаемых помещениях?",
        answers: [
          "Не выше 30 °С",
          "Не выше 35 °С",
          "Не выше 40 °С",
          "Не выше 50 °С",
        ],
        correctAnswers: ["Не выше 30 °С"],
      },
      {
        code: "21201057",
        text: "На каком расстоянии разрешается располагать рубильники в нормальном исполнении от места погрузки (выгрузки) взрывчатых материалов?",
        answers: [
          "Не ближе 30 м",
          "Не ближе 40 м",
          "Не ближе 45 м",
          "Не ближе 50 м",
        ],
        correctAnswers: ["Не ближе 50 м"],
      },
      {
        code: "21201058",
        text: "Каким требованиям должно отвечать ограждение погрузочно-разгрузочной площадки взрывчатых материалов?",
        answers: [
          "Должно быть выполнено из сплошного металлического забора, находиться на расстоянии не менее 12 м от места погрузки (выгрузки) транспортных средств, высота ограды должна составлять не менее 1,8 м",
          "Должно быть выполнено из колючей проволоки, находиться на расстоянии не менее 15 м от места погрузки (выгрузки) транспортных средств, высота ограды должна составлять не менее 2 м",
          "Должно быть выполнено из колючей проволоки, находиться на расстоянии не менее 12 м от места погрузки (выгрузки) транспортных средств, высота ограды должна составлять не менее 1,8 м",
          "Должно быть выполнено из сплошного металлического забора, находиться на расстоянии не менее 10 м от места погрузки (выгрузки) транспортных средств, высота ограды должна составлять не менее 1,5 м",
        ],
        correctAnswers: [
          "Должно быть выполнено из колючей проволоки, находиться на расстоянии не менее 15 м от места погрузки (выгрузки) транспортных средств, высота ограды должна составлять не менее 2 м",
        ],
      },
      {
        code: "21201059",
        text: "Где должно размещаться на погрузочно-разгрузочной площадке караульное помещение с телефонной связью за исключением площадок, расположенных на территории складов взрывчатых материалов, в околоствольных дворах шахт, рудников, штолен?",
        answers: [
          "Не далее 50 м от места погрузки (выгрузки) взрывчатых материалов",
          "Не далее 55 м от места погрузки (выгрузки) взрывчатых материалов",
          "Не далее 60 м от места погрузки (выгрузки) взрывчатых материалов",
          "Не далее 70 м от места погрузки (выгрузки) взрывчатых материалов",
        ],
        correctAnswers: [
          "Не далее 50 м от места погрузки (выгрузки) взрывчатых материалов",
        ],
      },
      {
        code: "21201060",
        text: "Какую массу взрывчатых материалов допускается переносить взрывнику при одновременной доставке вручную средств инициирования и взрывчатых веществ?",
        answers: [
          "Не более 10 кг",
          "Не более 14 кг",
          "Не более 16 кг",
          "Не более 20 кг",
        ],
        correctAnswers: ["Не более 10 кг"],
      },
      {
        code: "21201061",
        text: "Какую максимальную массу взрывник может переносить при переноске в сумках взрывчатых веществ без средств инициирования?",
        answers: ["24 кг", "28 кг", "32 кг", "36 кг"],
        correctAnswers: ["24 кг"],
      },
      {
        code: "21201062",
        text: "Присутствие каких лиц допускается при погрузке, разгрузке, перемещении взрывчатых материалов по стволу шахты в околоствольном дворе и надшахтном здании около ствола?",
        answers: [
          "Только взрывника, раздатчика, рукоятчика",
          "Только нагружающих и разгружающих взрывчатые материалы рабочих",
          "Только стволового и лица, ответственного за доставку взрывчатых материалов",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "21201063",
        text: "Что из перечисленного не соответствует требованиям промышленной безопасности при транспортировании взрывчатых материалов по стволу шахты?",
        answers: [
          "Спуск-подъем взрывчатых материалов по стволу шахты должен проводиться только после извещения об этом диспетчера (дежурного по шахте) лицом, назначенным ответственным за доставку (подъем, спуск) взрывчатых материалов",
          "Ящики и мешки с взрывчатыми материалами должны занимать не более 2/3 высоты этажа клети, но не более высоты дверей клети",
          "Средства инициирования следует спускать (поднимать) отдельно от взрывчатых веществ",
          "Транспортирование взрывчатых материалов по стволу шахты во время спуска и подъема людей допускается с дополнительными мерами предосторожности",
        ],
        correctAnswers: [
          "Транспортирование взрывчатых материалов по стволу шахты во время спуска и подъема людей допускается с дополнительными мерами предосторожности",
        ],
      },
      {
        code: "21201064",
        text: "Каким должно быть расстояние между вагонетками со взрывчатыми веществами и средствами инициирования, разделенными порожними вагонетками, а также между вагонетками с взрывчатыми веществами и средствами инициирования, и локомотивом при их перевозке в одном железнодорожном составе?",
        answers: [
          "Не менее 3 м",
          "Не менее 2 м",
          "Не менее 1,5 м",
          "Не менее 1 м",
        ],
        correctAnswers: ["Не менее 3 м"],
      },
      {
        code: "21201065",
        text: "Кто может находиться в поезде  при транспортировании взрывчатых материалов рельсовым транспортом?",
        answers: [
          "Руководство организации",
          "Представители Ростехнадзора и ВГСЧ",
          "Лица, непосредственно связанные с перевозкой взрывчатых материалов",
          "Все перечисленные лица",
        ],
        correctAnswers: [
          "Лица, непосредственно связанные с перевозкой взрывчатых материалов",
        ],
      },
      {
        code: "21201066",
        text: "Какое из перечисленных условий не соответствует требованиям промышленной безопасности к спуску-подъему взрывчатых материалов при проходке шурфов, оборудованных ручными воротками и лебедками?",
        answers: [
          "В забое не должны находиться лица, не связанные со взрывными работами",
          "Спуск-подъем взрывчатых материалов должен осуществляться не менее чем двумя лицами",
          "Вороток или лебедку необходимо оборудовать храповыми устройствами или автоматически действующими тормозами, а прицепной крюк - предохранительным замком",
          "При проведении спуска-подъема взрывчатых веществ и средств инициирования одновременно спуск-подъем взрывчатых материалов должен проводиться с дополнительными мерами предосторожности",
        ],
        correctAnswers: [
          "При проведении спуска-подъема взрывчатых веществ и средств инициирования одновременно спуск-подъем взрывчатых материалов должен проводиться с дополнительными мерами предосторожности",
        ],
      },
      {
        code: "21201067",
        text: "На каком расстоянии от ствола шахты или устья штольни (тоннеля) при их проходке разрешается размещать в будках или под навесами взрывчатые материалы в размере сменной потребности?",
        answers: [
          "Не ближе 20 м",
          "Не ближе 30 м",
          "Не ближе 40 м",
          "Не ближе 50 м",
        ],
        correctAnswers: ["Не ближе 50 м"],
      },
      {
        code: "21201068",
        text: "С какой периодичностью должна проводиться проверка правильности учета, хранения и наличия взрывчатых материалов на складах лицами, назначенными распорядительным документом организации?",
        answers: [
          "Ежемесячно",
          "Один раз в 3 месяца",
          "Один раз в 6 месяцев",
          "Один раз в год",
        ],
        correctAnswers: ["Ежемесячно"],
      },
      {
        code: "21201069",
        text: "В каком из положений нарушены требования по приему, отпуску и учету взрывчатых материалов?",
        answers: [
          "Доставленные на места хранения взрывчатые материалы должны быть приняты по количеству, массе, оприходованы и помещены в хранилища, на площадки",
          "Доставленные на места хранения взрывчатые материалы должны быть немедленно переданы на места проведения взрывных работ и выданы взрывникам",
          "Индивидуальные заводские номера изделий с взрывчатыми веществами при выдаче взрывникам должны регистрироваться в Книге учета выдачи и возврата взрывчатых материалов",
          "Электродетонаторам и капсюлям-детонаторам перед выдачей взрывникам должны быть присвоены и нанесены индивидуальные индексы",
        ],
        correctAnswers: [
          "Доставленные на места хранения взрывчатые материалы должны быть немедленно переданы на места проведения взрывных работ и выданы взрывникам",
        ],
      },
      {
        code: "21201070",
        text: "Каким образом осуществляется передача взрывчатых материалов с одного склада на другой, принадлежащий одной и той же организации?",
        answers: [
          "По письменному распоряжению заведующего складом взрывчатых материалов",
          "По письменному распоряжению руководителя горными и взрывными работам",
          "Оформлением наряда-накладной",
          "Оформлением наряда-путевки",
        ],
        correctAnswers: ["Оформлением наряда-накладной"],
      },
      {
        code: "21201071",
        text: "Какие способы, согласно требованиям стандартов и технических условий,  применяются при уничтожении взрывчатых материалов?",
        answers: [
          "Взрывание",
          "Сжигание",
          "Растворение в воде",
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "21201072",
        text: "Как должно производиться  уничтожение   пороха  сжиганием?",
        answers: [
          "Пороха при сжигании должны рассыпаться дорожками шириной не более 30 см при толщине слоя до 10 см и расстоянии между ними не менее 5 м",
          "Пороха при сжигании должны рассыпаться дорожками шириной не более 40 см при толщине слоя до 12 см и расстоянии между ними не менее 6 м. Одновременно разрешается поджигать не более четырех дорожек с порохами",
          "Пороха при сжигании должны рассыпаться дорожками шириной не более 50 см при толщине слоя до 15 см и расстоянии между ними не менее 4 м. Одновременно разрешается поджигать не более четырех дорожек с порохами",
          "Пороха при сжигании должны рассыпаться дорожками шириной не более 60 см при толщине слоя до 20 см и расстоянии между ними не менее 5 м. Одновременно разрешается поджигать не более трех дорожек с порохами",
        ],
        correctAnswers: [
          "Пороха при сжигании должны рассыпаться дорожками шириной не более 30 см при толщине слоя до 10 см и расстоянии между ними не менее 5 м",
        ],
      },
      {
        code: "21201073",
        text: "Какую массу огнепроводных шнуров разрешается сжигать на костре за один прием?",
        answers: [
          "Не более 20 кг",
          "Не более 25 кг",
          "Не более 30 кг",
          "Не более 40 кг",
        ],
        correctAnswers: ["Не более 20 кг"],
      },
      {
        code: "21201074",
        text: "При каком максимальном расстоянии от места нахождения взрывчатых материалов запрещается применять открытый огонь и курить?",
        answers: ["100 м", "110 м", "125 м", "150 м"],
        correctAnswers: ["100 м"],
      },
      {
        code: "21201075",
        text: ' Что из перечисленного является определением понятия "средства инициирования" согласно техническому регламенту Таможенного союза от 20.07.2012 N 028/2012 "О безопасности взрывчатых веществ и изделий на их основе"?',
        answers: [
          "Изделия, содержащие взрывчатое вещество и предназначенные для возбуждения или передачи и возбуждения детонации",
          "Компактная масса взрывчатого вещества конечных размеров, заключенная в оболочку или без нее, предназначенная для использования в изготовленном виде самостоятельно или в сочетании с другими взрывчатыми веществами",
          "Высокочувствительное взрывчатое вещество, легко детонирующее от простейших начальных импульсов (удар, трение, нагрев, искровой разряд), предназначенное для возбуждения детонации или воспламенения других взрывчатых веществ",
          "Средство или комплекс средств, предназначенных для защиты взрывчатых веществ и изделий на их основе от повреждений и исключения воздействия атмосферных явлений",
        ],
        correctAnswers: [
          "Изделия, содержащие взрывчатое вещество и предназначенные для возбуждения или передачи и возбуждения детонации",
        ],
      },
      {
        code: "21201076",
        text: "В каком случае допускается применять и хранить взрывчатые вещества и изделия на их основе с истекшим гарантийным сроком хранения?",
        answers: [
          "Допускается при хранении в подземных хранилищах",
          "Допускается при снижении количества хранящихся взрывчатых веществ в 2 раза от рекомендованного",
          "Допускается в случае проведения испытаний, предусмотренных технической документацией",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "Допускается в случае проведения испытаний, предусмотренных технической документацией",
        ],
      },
      {
        code: "21201077",
        text: "Как должно быть отмечено специально выделенное место для временного хранения на складах пришедших в негодность и бракованных взрывчатых веществ и изделий на их основе?",
        answers: [
          "Металлическим ограждением",
          'Предупредительной надписью "ВНИМАНИЕ БРАК"',
          "Предупредительными огнями в виде светильников красного цвета",
          "Временное хранение таких веществ и изделий не допускается",
        ],
        correctAnswers: ['Предупредительной надписью "ВНИМАНИЕ БРАК"'],
      },
      {
        code: "21201078",
        text: "Какой цвет отличительной полосы или оболочек патронов (пачек) должны иметь непредохранительные взрывчатые вещества для взрывания только на земной поверхности?",
        answers: ["Белый", "Желтый", "Красный", "Синий"],
        correctAnswers: ["Белый"],
      },
      {
        code: "21201079",
        text: "Какие требования, предъявляемые к характеристикам электродетонаторов указаны неверно?",
        answers: [
          "Значение безопасного тока не менее 0,18 А;",
          "Длительный воспламеняющий ток не менее 0,22 А;",
          "Безопасный импульс воспламенения не менее 0,6 А2 .мс",
          "Электродетонаторы не должны возбуждать детонацию боковой поверхностью контактирующих с ней взрывчатых веществ и других средств инициирования",
        ],
        correctAnswers: [
          "Электродетонаторы не должны возбуждать детонацию боковой поверхностью контактирующих с ней взрывчатых веществ и других средств инициирования",
        ],
      },
      {
        code: "21201080",
        text: "Что должна включать маркировка упаковки взрывчатых веществ и изделий на их основе, а также изделий на основе взрывчатых веществ?",
        answers: [
          "Только наименование (условное обозначение) взрывчатого вещества или изделия, а также гарантийный срок хранения",
          "Только наименование предприятия-изготовителя (поставщика и (или) импортера), а также товарный знак (при его наличии) и их юридический адрес",
          "Только классификационные обозначения в соответствии с ТР ТС 028-2012 и обозначение соответствия транспортной тары по механической прочности",
          "Только обозначение технических условий или стандартов, в соответствии с которыми изготовлена продукция если эти стандарты были применены",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21201081",
        text: "В каких местах при взрывных работах могут использоваться слежавшиеся порошкообразные взрывчатые вещества, содержащие гексоген или жидкие нитроэфиры, без размятия или измельчения?",
        answers: [
          "В шахтах (рудниках), не опасных по газу",
          "В шахтах (рудниках), разрабатывающих пласты (рудные тела), не опасные по взрывам пыли",
          "На земной поверхности",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["На земной поверхности"],
      },
      {
        code: "21201082",
        text: "С какой периодичностью должна проверяться техническая исправность транспортных средств, используемых для доставки взрывчатых материалов, лицом, назначенным распорядительным документом организации?",
        answers: ["Ежесменно", "Ежесуточно", "Еженедельно", "Ежемесячно"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "21201083",
        text: "Каким документом должны определяться место погрузки-выгрузки, меры безопасности, а также порядок погрузки-разгрузки взрывчатых материалов в околоствольных дворах шахт, рудников, штолен и надшахтных зданиях?",
        answers: [
          "Приказом Ростехнадзора",
          "Распоряжением МЧС",
          "Распорядительным документом шахты (рудника)",
          "Указанием профессиональной аварийно-спасательной службы и формирования",
        ],
        correctAnswers: ["Распорядительным документом шахты (рудника)"],
      },
      {
        code: "21201084",
        text: "Кто устанавливает маршруты транспортирования взрывчатых материалов от склада ВМ на места работ (в пределах опасного производственного объекта)?",
        answers: [
          "Ростехнадзор",
          "МЧС",
          "Руководитель (технический руководитель) организации, эксплуатирующей опасный производственный объект",
          "Профессиональные аварийно-спасательные службы и формирования",
        ],
        correctAnswers: [
          "Руководитель (технический руководитель) организации, эксплуатирующей опасный производственный объект",
        ],
      },
      {
        code: "21201085",
        text: "Какими лицами переносятся средства инициирования?",
        answers: [
          "Взрывниками",
          "Проинструктированными рабочими под наблюдением взрывников",
          "Раздатчиками взрывных материалов",
          "Всеми перечисленными лицами",
        ],
        correctAnswers: ["Взрывниками"],
      },
      {
        code: "21201086",
        text: "В каком месте необходимо располагать зарядную для аккумуляторных погрузчиков, а также постоянную стоянку зарядных машин (смесительно-зарядных машин, транспортно-зарядных машин)?",
        answers: [
          "За территорией поверхностных пунктов производства и подготовки взрывчатых веществ на расстоянии не ближе 20 м от здания подготовки и (или) производства взрывчатых веществ",
          "За территорией поверхностных пунктов производства и подготовки взрывчатых веществ на расстоянии не ближе 30 м от здания подготовки и (или) производства взрывчатых веществ",
          "За территорией поверхностных пунктов производства и подготовки взрывчатых веществ на расстоянии не ближе 40 м от здания подготовки и (или) производства взрывчатых веществ",
          "За территорией поверхностных пунктов производства и подготовки взрывчатых веществ на расстоянии не ближе 50 м от здания подготовки и (или) производства взрывчатых веществ",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "За территорией поверхностных пунктов производства и подготовки взрывчатых веществ на расстоянии не ближе 50 м от здания подготовки и (или) производства взрывчатых веществ",
        ],
      },
      {
        code: "21201087",
        text: "Какой из перечисленных индексов, наносимый на гильзы электродетонаторов и капсюлей-детонаторов, обозначает номер взрывника?",
        answers: [
          "Два цифровых индекса",
          "Два буквенных индекса слева от цифровых",
          "Два буквенных индекса справа от цифровых",
          "Буквенные индексы слева и справа от цифровых",
        ],
        correctAnswers: ["Два буквенных индекса справа от цифровых"],
      },
      {
        code: "21201088",
        text: "Как поступают с неиспользованными боевиками после взрывания зарядов?",
        answers: [
          "Боевики сдаются на хранение на постоянный склад взрывчатых материалов",
          "Боевики сдаются на хранение на кратковременный склад взрывчатых материалов",
          "Боевики сдаются на хранение на передвижной склад взрывчатых материалов",
          "Боевики подлежат уничтожению взрыванием в установленном порядке",
        ],
        correctAnswers: [
          "Боевики подлежат уничтожению взрыванием в установленном порядке",
        ],
      },
      {
        code: "21201089",
        text: "Какой способ ликвидации отказавших скважинных зарядов указан неверно?",
        answers: [
          "Если отказ произошел в результате нарушения целостности внешней взрывной сети",
          "Разборкой породы в месте нахождения скважины с отказавшим зарядом с извлечением последнего вручную",
          "Вымывание заряда из скважины при взрывании дымного пороха с применением детонирующего шнура",
          "При взрывании взрывчатых веществ группы совместимости D (кроме дымного пороха) с применением детонирующего шнура - вымыванием заряда из скважины",
        ],
        correctAnswers: [
          "Вымывание заряда из скважины при взрывании дымного пороха с применением детонирующего шнура",
        ],
      },
      {
        code: "21201090",
        text: "Какие из перечисленных взрывчатых веществ и изделий относятся к группе совместимости (опасности) С?",
        answers: [
          "Огнепроводные шнуры",
          "Сигнальные ракеты",
          "Метательные взрывчатые вещества и изделия (бездымный порох)",
          "Детонирующие шнуры",
        ],
        correctAnswers: [
          "Метательные взрывчатые вещества и изделия (бездымный порох)",
        ],
      },
      {
        code: "21201091",
        text: "Какое из перечисленных требований к порядку присвоения и нанесения индивидуальных индексов электродетонаторов и капсюлей-детонаторов указано верно?",
        answers: [
          "Работы по нанесению индивидуальных индексов средств инициирования необходимо проводить в помещении, отдельном от мест хранения взрывчатых материалов",
          "Нанесение индивидуальных индексов на электродетонаторы с помощью устройств обжимного типа должно производиться на 4,0 мм ниже нижнего зига заводской обжимки гильзы",
          "В случае появления трещин во внешней оболочке изделий они должны быть отремонтированы",
          "Все перечисленные",
        ],
        correctAnswers: [
          "Работы по нанесению индивидуальных индексов средств инициирования необходимо проводить в помещении, отдельном от мест хранения взрывчатых материалов",
        ],
      },
      {
        code: "21201092",
        text: "В каком случае разрешается доставка аммиачно-селитренных взрывчатых веществ к местам проведения взрывных работ в подземных выработках в ковшах погрузочно-доставочных машин от участковых пунктов хранения и мест выгрузки взрывчатых материалов?",
        answers: [
          "При условии загрузки ковша не более 2/3 по его высоте",
          "При условии совместной доставки аммиачно-селитренных взрывчатых веществ и средств инициирования",
          "При условии одновременной доставки средств инициирования и аммиачно-селитренных взрывчатых веществ в ковше может быть не более 10 кг взрывчатых материалов",
          "При условии доставки аммиачно-селитренных взрывчатых веществ без средств инициирования в ковше может быть не более 24 кг взрывчатых материалов",
        ],
        correctAnswers: [
          "При условии загрузки ковша не более 2/3 по его высоте",
        ],
      },
      {
        code: "21201093",
        text: "Какое из перечисленных требований к транспортированию взрывчатых материалов указано верно? Укажите все правильные ответы.",
        answers: [
          "Транспортирование взрывчатых материалов по подземным выработкам должно осуществляться со скоростью не более 10 м/с",
          "При спуске-подъеме взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами по наклонным выработкам в людских вагонетках на каждом сиденье должно находиться не более одного взрывника или подносчика",
          "Разрешается одновременно спускаться или подниматься в одной клети не более одного взрывника или подносчика с сумками с взрывчатыми материалами.",
          "Спуск-подъем взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами должен проводиться вне очереди",
        ],
        correctAnswers: [
          "При спуске-подъеме взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами по наклонным выработкам в людских вагонетках на каждом сиденье должно находиться не более одного взрывника или подносчика",
          "Спуск-подъем взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами должен проводиться вне очереди",
        ],
      },
      {
        code: "21201094",
        text: "Работа каких конвейеров, транспортирующих пожаровзрывоопасные вещества, допускается без устройства блокировочных устройств?",
        answers: [
          "Ленточных конвейеров",
          "Винтовых конвейеров",
          "Цепных конвейеров",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21201095",
        text: "Какое из перечисленных требований к уничтожению взрывчатых материалов указано верно?",
        answers: [
          "За один прием разрешается сжигать не более 30 кг взрывчатых материалов.",
          "Взрывчатые вещества, огнепроводные шнуры и детонирующие шнуры необходимо сжигать совместно",
          "Запрещается подход к месту сжигания до полного прекращения горения костра с взрывчатыми материалами",
          "Одновременно разрешается поджигать не более пяти дорожек с порохами",
        ],
        correctAnswers: [
          "Запрещается подход к месту сжигания до полного прекращения горения костра с взрывчатыми материалами",
        ],
      },
      {
        code: "21201096",
        text: "К какому классу опасности относятся все взрывчатые вещества и изделия на их основе?",
        answers: [
          "К 1 классу опасности",
          "Ко 2 классу опасности",
          "К 3 классу опасности",
          "К 4 классу опасности",
        ],
        correctAnswers: ["К 1 классу опасности"],
      },
      {
        code: "21201097",
        text: "Какие из перечисленных веществ относятся к группе совместимости взрывчатых веществ и изделий на их основе D?",
        answers: [
          "Взрывчатые вещества и изделия на их основе без средств инициирования и метательных зарядов",
          "Метательные взрывчатые вещества и изделия (бездымный порох)",
          "Пиротехнические вещества и изделия, содержащие их",
          "Изделия, содержащие взрывчатые вещества без средств инициирования, но с метательным зарядом",
        ],
        correctAnswers: [
          "Взрывчатые вещества и изделия на их основе без средств инициирования и метательных зарядов",
        ],
      },
      {
        code: "21201098",
        text: "К какой группе совместимости взрывчатых веществ и изделий на их основе относятся изделия, содержащие инициирующие взрывчатые вещества и имеющие менее двух независимых предохранительных устройств?",
        answers: ["К группе B", "К группе C", "К группе D", "К группе F"],
        correctAnswers: ["К группе B"],
      },
      {
        code: "21201099",
        text: "Для какой из перечисленных областей применения взрывчатых веществ минимальная масса партии взрывчатых веществ, необходимая для проведения приемочных испытаний, составляет 5 тонн? Укажите все правильные ответы.",
        answers: [
          "Взрывчатые вещества для подземных работ при ручном заряжании шпуров и скважин",
          "Взрывчатые вещества для подземных работ при механизированном заряжании шпуров и скважин",
          "Предохранительные взрывчатые вещества",
          "Взрывчатые вещества, предназначенные для открытых работ",
          "Патронированные взрывчатые вещества, шашки и другие штучные взрывчатые вещества, предназначенные для изготовления боевиков на открытых и подземных работах",
          "Неэлектрические системы инициирования",
        ],
        correctAnswers: [
          "Взрывчатые вещества для подземных работ при механизированном заряжании шпуров и скважин",
          "Патронированные взрывчатые вещества, шашки и другие штучные взрывчатые вещества, предназначенные для изготовления боевиков на открытых и подземных работах",
        ],
      },
      {
        code: "21201100",
        text: "Где проводятся приемочные испытания взрывчатых веществ и изделий на их основе?",
        answers: [
          "В лабораторных условиях организаций, ведущих исследовательские работы свойств взрывчатых материалов",
          "На испытательных полигонах экспертными организациями",
          "Только в производственных условиях",
          "На специальных стендах изготовителями взрывчатых материалов",
        ],
        correctAnswers: ["Только в производственных условиях"],
      },
      {
        code: "21201101",
        text: "В каких видах испытаний взрывчатых веществ и изделий на их основе принимает участие экспертная организация?",
        answers: [
          "В стендовых испытаниях",
          "В полигонных испытаниях",
          "В контрольных и приемочных испытаниях",
        ],
        correctAnswers: ["В контрольных и приемочных испытаниях"],
      },
      {
        code: "21201102",
        text: "Что входит в перечень работ, которые должны быть проведены с электродетонаторами перед их выдачей?",
        answers: [
          "Электродетонаторы должны быть проверены только на отсутствие повреждений корпуса",
          "Электродетонаторы должны быть проверены только на отсутствие следов коррозии",
          "Электродетонаторам должны быть только присвоены и нанесены индивидуальные индексы",
          "Все виды перечисленных работ",
        ],
        correctAnswers: ["Все виды перечисленных работ"],
      },
      {
        code: "21201103",
        text: "Что из перечисленного запрещается Правилами безопасности при производстве, хранении и применении взрывчатых материалов промышленного назначения во время монтажа электровзрывной сети?",
        answers: [
          "Электровзрывная сеть должна быть двухпроводной",
          "Электровзрывную сеть монтируют в направлении от источника тока или включающего ток устройства к заряду",
          "В шахтах (рудниках), опасных по газу или пыли, провода электродетонаторов и электровзрывной сети необходимо соединять только с применением контактных зажимов",
        ],
        correctAnswers: [
          "Электровзрывную сеть монтируют в направлении от источника тока или включающего ток устройства к заряду",
        ],
      },
      {
        code: "21201104",
        text: "При каком расхождении величин измеренного и расчетного сопротивлений перед взрыванием скважинных и камерных зарядов необходимо устранить неисправности, вызывающие отклонения от расчетного сопротивления электровзрывной сети?",
        answers: [
          "Более чем на 10 %",
          "Более чем на 8 %",
          "Более чем на 6 %",
          "Более чем на 5 %",
        ],
        correctAnswers: ["Более чем на 10 %"],
      },
      {
        code: "21201105",
        text: "Что входит в зону монтажа электровзрывной сети на земной поверхности?",
        answers: [
          "Поверхность, ограниченная контуром, на 50 м превышающим контур электровзрывной сети",
          "Поверхность, ограниченная контуром, на 55 м превышающим контур электровзрывной сети",
          "Поверхность, ограниченная контуром, на 60 м превышающим контур электровзрывной сети",
          "Поверхность, ограниченная контуром, на 65 м превышающим контур электровзрывной сети",
        ],
        correctAnswers: [
          "Поверхность, ограниченная контуром, на 50 м превышающим контур электровзрывной сети",
        ],
      },
      {
        code: "21201106",
        text: "Каким из перечисленных способов производится электрическое взрывание?",
        answers: [
          "Непосредственно от силовой сети",
          "Непосредственно от осветительной сети",
          "С применением взрывных приборов (машинок)",
          "Непосредственно от контактной сети",
        ],
        correctAnswers: ["С применением взрывных приборов (машинок)"],
      },
      {
        code: "21201107",
        text: "Какими должны быть действия взрывника,  если при подаче напряжения взрыва не произошло?",
        answers: [
          "Отсоединить от прибора (источника тока) электровзрывную сеть, замкнуть накоротко ее концы, взять с собой ключ от прибора (ящика, в котором находится взрывное устройство) и только после этого выяснить причину отказа",
          "Отсоединить от источника тока электровзрывную сеть, взять с собой ключ от взрывного прибора и только после этого выяснить причину отказа",
          "Отсоединить от источника тока электровзрывную сеть         и проверить ее электрическое сопротивление, при обнаружении неисправности принять меры к ее устранению",
          "Периодически (не менее трех раз) подавать напряжение в электровзрывную сеть, при отсутствии взрыва отключить взрывной прибор, взять с собой ключ от взрывного прибора и только после этого выяснить причину отказа",
        ],
        correctAnswers: [
          "Отсоединить от прибора (источника тока) электровзрывную сеть, замкнуть накоротко ее концы, взять с собой ключ от прибора (ящика, в котором находится взрывное устройство) и только после этого выяснить причину отказа",
        ],
      },
      {
        code: "21201108",
        text: "С какой периодичностью должны проверяться в местах их установки взрывные приборы стационарных взрывных пунктов на угольных, сланцевых шахтах и объектах геологоразведки, опасных по газу или пыли?",
        answers: [
          "Не реже одного раза в 15 дней",
          "Не реже одного раза в 20 дней",
          "Не реже одного раза в 25 дней",
          "Не реже одного раза в 30 дней",
        ],
        correctAnswers: ["Не реже одного раза в 15 дней"],
      },
      {
        code: "21201109",
        text: "Какой должна быть длина огнепроводного шнура контрольной трубки, используемой для контроля времени, затрачиваемого на зажигание,при поджигании пяти трубок и более на земной поверхности?",
        answers: [
          "Не менее чем на 45 см короче по сравнению со шнуром самой короткой из применяемых зажигательных трубок, но не менее 25 см",
          "Не менее чем на 50 см короче по сравнению со шнуром самой короткой из применяемых зажигательных трубок, но не менее 30 см",
          "Не менее чем на 55 см короче по сравнению со шнуром самой короткой из применяемых зажигательных трубок, но не менее 35 см",
          "Не менее чем на 60 см короче по сравнению со шнуром самой короткой из применяемых зажигательных трубок, но не менее 40 см",
        ],
        correctAnswers: [
          "Не менее чем на 60 см короче по сравнению со шнуром самой короткой из применяемых зажигательных трубок, но не менее 40 см",
        ],
      },
      {
        code: "21201110",
        text: "Когда взрывник может подойти к месту взрыва при ведении счета взорвавшихся зарядов и отсутствии отказов?",
        answers: [
          "Через 2 мин после последнего взрыва",
          "Через 3 мин после последнего взрыва",
          "Через 4 мин после последнего взрыва",
          "Через 5 мин после последнего взрыва",
        ],
        correctAnswers: ["Через 5 мин после последнего взрыва"],
      },
      {
        code: "21201111",
        text: "Через какое время разрешается подходить к месту взрыва при ведении счета взорвавшихся зарядов в случае, если какой-либо заряд не взорвался или вести счет взорвавшихся зарядов невозможно?",
        answers: [
          "Не ранее чем через 15 мин после последнего взрыва",
          "Не ранее чем через 12 мин после последнего взрыва",
          "Не ранее чем через 10 мин после последнего взрыва",
          "Не ранее чем через 5 мин после последнего взрыва",
        ],
        correctAnswers: ["Не ранее чем через 15 мин после последнего взрыва"],
      },
      {
        code: "21201112",
        text: "Какое из условий должно соблюдаться  при изготовлении зажигательных и контрольных трубок?",
        answers: [
          'Только условие: "на столе исполнителя должно находиться не более одной коробки капсюлей-детонаторов с соответствующим количеством отрезков огнепроводного шнура"',
          'Только условие: "резка огнепроводного шнура должна осуществляться при отсутствии на столе капсюлей-детонаторов"',
          'Только условие: "каждая контрольная трубка должна иметь четко видимый невооруженным глазом отличительный знак"',
          "Все перечисленные",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "21201113",
        text: "Как должно производиться удаление каких-либо частиц на внутренней поверхности гильзы капсюля-детонатора перед изготовлением зажигательной (контрольной) трубки?",
        answers: [
          "Легким постукиванием открытым дульцем капсюля-детонатора о ноготь пальца",
          "Выдуванием",
          "С помощью медицинского пинцета",
          "С помощью иглы",
        ],
        correctAnswers: [
          "Легким постукиванием открытым дульцем капсюля-детонатора о ноготь пальца",
        ],
      },
      {
        code: "21201114",
        text: "Как следует вводить огнепроводный шнур в капсюль-детонатор?",
        answers: [
          "До соприкосновения с чашечкой капсюля-детонатора прямым движением без вращения",
          "До соприкосновения с чашечкой капсюля-детонатора вращением в одну сторону",
          "До соприкосновения с чашечкой капсюля-детонатора попеременным вращением в разные стороны",
          "Любым из перечисленных способов",
        ],
        correctAnswers: [
          "До соприкосновения с чашечкой капсюля-детонатора прямым движением без вращения",
        ],
      },
      {
        code: "21201115",
        text: "Какое из перечисленных требований при применении средств инициирования запрещается?",
        answers: [
          "Расширять гнезда патронов (шашек) заводского изготовления, из которых изготовлены боевики из прессованных или литых взрывчатых веществ с инициированием от детонаторов",
          "При изготовлении промежуточных детонаторов из порошкообразных патронированных взрывчатых веществ с применением детонирующего шнура конец детонирующего шнура в патроне складывать вдвое",
          "Обматывать детонирующий шнур вокруг патрона взрывчатых веществ",
          "При изготовлении промежуточных детонаторов из порошкообразных патронированных взрывчатых веществ с применением детонирующего шнура конец детонирующего шнура в патроне завязывать узлом",
          "Все перечисленные требования",
        ],
        correctAnswers: [
          "Расширять гнезда патронов (шашек) заводского изготовления, из которых изготовлены боевики из прессованных или литых взрывчатых веществ с инициированием от детонаторов",
        ],
      },
      {
        code: "21201116",
        text: "Какие электродетонаторы разрешается применять в бутовых штреках с подрывкой кровли?",
        answers: [
          "Электродетонаторы короткозамедленного действия с интервалом замедления не более 30 мс (по номиналу)",
          "Электродетонаторы короткозамедленного действия с интервалом замедления не более 45 мс (по номиналу)",
          "Электродетонаторы короткозамедленного действия с интервалом замедления не более 60 мс (по номиналу)",
          "Эектродетонаторы мгновенного действия",
        ],
        correctAnswers: ["Эектродетонаторы мгновенного действия"],
      },
      {
        code: "21201117",
        text: "Через какое время разрешается подход взрывника к месту взрыва, если взрыва не произошло, при взрывании электронными детонаторами, электродетонаторами и капсюлями-детонаторами?",
        answers: [
          "Сразу после отказа взрывания",
          "Не ранее чем через 5 минут",
          "Не ранее чем через 10 минут",
          "Не ранее чем через 15 минут",
        ],
        correctAnswers: ["Не ранее чем через 15 минут"],
      },
      {
        code: "21201118",
        text: "Какой вид взрывных работ следует относить к массовым взрывам зарядов взрывчатых веществ на земной поверхности?",
        answers: [
          "Единичные заряды в выработках протяженностью более 7 м",
          "Взрывание смонтированных в общую взрывную сеть двух и более скважинных, котловых или камерных зарядов, независимо от протяженности заряжаемой выработки, а также единичных зарядов в выработках протяженностью более 10 м",
          "Взрывы, при осуществлении которых требуется большее время для проветривания и возобновления работ в руднике (шахте, участке), чем это предусмотрено в расчете при повседневной организации работ",
          "Специальные взрывы по обрушению потолочин камер, междуэтажных целиков на всю высоту этажа",
        ],
        correctAnswers: [
          "Взрывание смонтированных в общую взрывную сеть двух и более скважинных, котловых или камерных зарядов, независимо от протяженности заряжаемой выработки, а также единичных зарядов в выработках протяженностью более 10 м",
        ],
      },
      {
        code: "21201119",
        text: "Что является основанием для проведения взрывных работ?",
        answers: [
          "Распоряжение руководителя подразделения организации, в котором производятся взрывные работы",
          "Запись в сменном журнале лицом технического надзора",
          "Указание технического руководителя организации",
          "Письменный наряд (задание на выполнение работ) с ознакомлением под подпись и соответствующий наряд-путевка",
        ],
        correctAnswers: [
          "Письменный наряд (задание на выполнение работ) с ознакомлением под подпись и соответствующий наряд-путевка",
        ],
      },
      {
        code: "21201120",
        text: "С каким документом под подпись должны ознакомиться взрывники перед началом производства взрывных работ?",
        answers: [
          "С производственной инструкцией и сертификатами соответствия взрывчатых материалов",
          "С планом проведения взрывных работ и разрешением Ростехнадзора на проведение взрывных работ на конкретных опасных производственных объектах",
          "С нарядом (заданием на выполнение работ)",
          "С проектом технологического массового взрыва и декларацией о соответствии взрывчатых веществ требованиям технического регламента Таможенного союза",
        ],
        correctAnswers: ["С нарядом (заданием на выполнение работ)"],
      },
      {
        code: "21201121",
        text: "При выполнении каких взрывных работ на объектах взрывание зарядов должно проводиться по утвержденным проектам (техническим проектам)?",
        answers: [
          "При проведении разовых взрывов зарядов в целях ликвидации отказов",
          "При проведении дноуглубительных и ледоходных работ",
          "При проведении разовых взрывов зарядов для выравнивания почвы выработки",
          "При проведении разовых взрывов зарядов в шпурах для доведения контура выработки до размеров, предусмотренных проектом",
        ],
        correctAnswers: ["При проведении дноуглубительных и ледоходных работ"],
      },
      {
        code: "21201122",
        text: "Что является базовым документом для разработки паспортов и проектов буровзрывных (взрывных) работ, в том числе и проектов массовых взрывов, за исключением специальных и экспериментальных массовых взрывов в подземных выработках, выполняемых в конкретных условиях?",
        answers: [
          "Правила безопасности при взрывных работах",
          "Программа проведения взрывных работ",
          "Руководство по проведению буровзрывных работ",
          "Типовой проект буровзрывных (взрывных) работ",
        ],
        correctAnswers: ["Типовой проект буровзрывных (взрывных) работ"],
      },
      {
        code: "21201123",
        text: "Кем должен утверждаться типовой проект буровзрывных (взрывных) работ?",
        answers: [
          "Руководителем (техническим руководителем) организации, ведущей взрывные работы, или лицом, его замещающим",
          "Только руководителем подразделения организации, проводящей взрывные работы",
          "Только лицом технического надзора",
          "Только руководителем службы охраны труда и промышленной безопасности",
        ],
        correctAnswers: [
          "Руководителем (техническим руководителем) организации, ведущей взрывные работы, или лицом, его замещающим",
        ],
      },
      {
        code: "21201124",
        text: "Кого должна информировать о проводимых массовых взрывах организация, ведущая взрывные работы?",
        answers: [
          "Территориальные органы МВД России",
          "Территориальный орган исполнительной власти в области промышленной безопасности",
          "Федеральную службу государственной регистрации, кадастра и картографии",
          "Федеральную службу по гидрометеорологии и мониторингу окружающей среды",
        ],
        correctAnswers: [
          "Территориальный орган исполнительной власти в области промышленной безопасности",
        ],
      },
      {
        code: "21201125",
        text: "Каким образом при попадании объектов другой организации в опасную зону   ее руководитель должен быть оповещен о месте и времени производства взрывных работ?",
        answers: [
          "Письменно, не менее чем за сутки",
          "Устным или письменным сообщением, не менее чем за 20 часов",
          "Только посредством телефонной связи, не менее чем за 20 часов",
          "Любым возможным способом, обеспечивающим доведение информации о производстве взрывных работ до руководителя другой организации, но не менее чем за 12 часов",
        ],
        correctAnswers: ["Письменно, не менее чем за сутки"],
      },
      {
        code: "21201126",
        text: "Что из перечисленного должен включать в себя паспорт взрывных работ?",
        answers: [
          "Схему расположения шпуров или наружных зарядов;         наименования взрывчатых материалов; данные о способе заряжания, числе шпуров, их глубине и диаметре, массе и конструкции зарядов и боевиков",
          "Состав бригады взрывников, требования к их квалификации",
          "Перечень средств индивидуальной защиты работников при выполнении взрывных работ",
          "Все перечисленное",
        ],
        correctAnswers: [
          "Схему расположения шпуров или наружных зарядов;         наименования взрывчатых материалов; данные о способе заряжания, числе шпуров, их глубине и диаметре, массе и конструкции зарядов и боевиков",
        ],
      },
      {
        code: "21201127",
        text: "В каком случае разрешается проводить взрывные работы по схемам?",
        answers: [
          "При проведении разовых взрывов зарядов в шпурах для удаления навесов, выравнивания забоя",
          "При проведении разовых взрывов зарядов для выравнивания почвы выработки",
          "При проведении разовых взрывов зарядов в целях ликвидации отказов",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "21201128",
        text: "На каком расстоянии от зарядного оборудования при механизированном заряжании вводится запретная зона?",
        answers: [
          "Не ближе 30 м",
          "Определяется проектом (паспортом) буровзрывных (взрывных) работ, но не ближе 50 м",
          "Не ближе 10 м",
          "Не ближе 45 м",
        ],
        correctAnswers: [
          "Определяется проектом (паспортом) буровзрывных (взрывных) работ, но не ближе 50 м",
        ],
      },
      {
        code: "21201129",
        text: "Что из перечисленного разрешается делать взрывникам при пневмозаряжании?",
        answers: [
          "Производить одновременное заряжание взрывчатых веществ, содержащих в своем составе тротил или алюминиевую пудру",
          "Использовать для механизированного заряжания взрывчатые вещества, собранные из просыпи",
          "Входить в камеры при пневмозаряжании только после прекращения процесса механизированного заряжания и полного проветривания камеры",
          "Производить одновременно в одном забое ручное формирование основного заряда с применением высокочувствительных к механическим воздействиям взрывчатых веществ и пневматическое заряжание",
        ],
        correctAnswers: [
          "Входить в камеры при пневмозаряжании только после прекращения процесса механизированного заряжания и полного проветривания камеры",
        ],
      },
      {
        code: "21201130",
        text: "Каково минимальное расстояние границ запретной зоны в подземных выработках?",
        answers: ["50 м", "45 м", "40 м", "30 м"],
        correctAnswers: ["50 м"],
      },
      {
        code: "21201131",
        text: "Какие сигналы для оповещения людей должны подаваться в темное время суток  при производстве взрывных работ?",
        answers: [
          "Сигналы, подаваемые голосом",
          "Звуковые и световые сигналы",
          "Сигналы с применением взрывчатых материалов",
          "Все перечисленные",
        ],
        correctAnswers: ["Звуковые и световые сигналы"],
      },
      {
        code: "21201132",
        text: "Какие сигналы подаются по  окончании взрывных работ?",
        answers: [
          "Один продолжительный",
          "Два коротких",
          "Два продолжительных",
          "Три коротких",
        ],
        correctAnswers: ["Три коротких"],
      },
      {
        code: "21201133",
        text: "Кем может разрешаться допуск людей к месту взрыва после его проведения?",
        answers: [
          "Лицом, осуществляющим руководство взрывными работами, или по его поручению взрывником",
          "Техническим руководителем организации",
          "Руководителем подразделения организации, в котором производились взрывные работы",
          "После подачи сигнала отбой в специальном допуске нет необходимости",
        ],
        correctAnswers: [
          "Лицом, осуществляющим руководство взрывными работами, или по его поручению взрывником",
        ],
      },
      {
        code: "21201134",
        text: "По истечении какого времени после взрыва на земной поверхности ответственный руководитель массового взрыва организует осмотр взорванных блоков с принятием мер, предотвращающих отравление газами проверяющих работников?",
        answers: [
          "Не ранее чем через 3 мин",
          "Не ранее чем через 5 мин",
          "Не ранее чем через 10 мин",
          "Не ранее чем через 15 мин",
        ],
        correctAnswers: ["Не ранее чем через 15 мин"],
      },
      {
        code: "21201135",
        text: "При какой глубине взрывной скважины обязательно дублирование внутрискважинной сети?",
        answers: ["Более 15 м", "Более 12 м", "Более 8 м", "Более 5 м"],
        correctAnswers: ["Более 15 м"],
      },
      {
        code: "21201136",
        text: "Каким образом должен поступить взрывник, если электровзрывная сеть была смонтирована перед наступлением грозы?",
        answers: [
          "Произвести взрывание во время грозы",
          "Произвести взрывание вне зависимости от погодных условий",
          "Провести взрывание перед грозой или отсоединить участковые провода от магистральных, концы тщательно изолировать, людей удалить за пределы опасной зоны или в укрытие",
          "Людей удалить в укрытие и произвести взрывание во время грозы",
        ],
        correctAnswers: [
          "Провести взрывание перед грозой или отсоединить участковые провода от магистральных, концы тщательно изолировать, людей удалить за пределы опасной зоны или в укрытие",
        ],
      },
      {
        code: "21201137",
        text: "В каком документе фиксируются отказы зарядов  при взрывных работах?",
        answers: [
          "В журнале регистрации отказов при взрывных работах",
          "В сменном журнале, наряде-путевке и журнале осмотра технического состояния и учета работы оборудования",
          "В акте выполненных работ",
          "В ведомости учета зарядов",
        ],
        correctAnswers: ["В журнале регистрации отказов при взрывных работах"],
      },
      {
        code: "21201138",
        text: "Каким образом должна проводиться ликвидация отказавших шпуровых зарядов?",
        answers: [
          "Взрыванием зарядов во вспомогательных шпурах, пробуренных параллельно отказавшим на расстоянии не ближе 30 см",
          "Взрыванием зарядов во вспомогательных шпурах, пробуренных параллельно отказавшим на расстоянии не ближе 25 см",
          "Взрыванием зарядов во вспомогательных шпурах, пробуренных параллельно отказавшим на расстоянии не ближе 20 см",
          "Взрыванием зарядов во вспомогательных шпурах, пробуренных параллельно отказавшим на расстоянии не ближе 15 см",
        ],
        correctAnswers: [
          "Взрыванием зарядов во вспомогательных шпурах, пробуренных параллельно отказавшим на расстоянии не ближе 30 см",
        ],
      },
      {
        code: "21201139",
        text: "На какую длину разрешается вынимать из шпуров отказавших зарядов забоечный материал для установления направления вспомогательных шпуров?",
        answers: [
          "На длину до 20 см от устья         шпура",
          "На длину до 25 см от устья   шпура",
          "На длину до 40 см от устья   шпура",
          "На длину до 50 см от устья   шпура",
        ],
        correctAnswers: ["На длину до 20 см от устья         шпура"],
      },
      {
        code: "21201140",
        text: "Что должно быть предпринято в случае,  когда работы по ликвидации отказа не могут быть закончены в данной смене?",
        answers: [
          "Разрешается поручать их продолжение взрывнику очередной смены с соответствующим инструктажем и отметкой в выдаваемой ему наряде-путевке",
          "Разрешается продолжать работу только взрывнику данной смены до ее выполнения",
          "Разрешается только совместное продолжение работы по ликвидации отказа со взрывником очередной смены до ее выполнения",
          "Продолжение работ поручается только в смену выполнявшего ее работника, с ее переносом на другой день",
        ],
        correctAnswers: [
          "Разрешается поручать их продолжение взрывнику очередной смены с соответствующим инструктажем и отметкой в выдаваемой ему наряде-путевке",
        ],
      },
      {
        code: "21201141",
        text: "Что из перечисленного целесообразно отражать в инструкции  по ликвидации отказавших зарядов взрывчатых веществ?",
        answers: [
          "Только основные мероприятия по предупреждению отказавших зарядов, порядок обнаружения невзорвавшихся зарядов, методы ликвидации отказов для каждого вида взрывных работ",
          "Только величину радиуса опасной зоны при ликвидации отказа, порядок ее обозначения на местности и в подземных выработках, а также ее охране, организацию работ по ликвидации отказов",
          "Только порядок сбора, учета и уничтожения остатков взрывчатых материалов, извлеченных при ликвидации отказа, мероприятия по безопасности работ",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21201142",
        text: "Что необходимо сделать перед началом заряжания шпуров и скважин при ведении взрывных работ в подземных выработках?",
        answers: [
          "Только обеспечить проветривание забоя",
          "Только убрать ранее взорванную в забое горную массу",
          "Только вывести людей, не связанных с выполнением взрывных работ, за пределы опасной зоны, в места, определенные паспортом буровзрывных (взрывных) работ",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21201143",
        text: "В каком случае перед началом заряжания шпуров в одном из встречных забоев все люди, не связанные с выполнением взрывных работ, должны быть удалены из встречных забоев на безопасное расстояние, и у входа в противоположный забой должен быть выставлен пост?",
        answers: [
          "В случае сближения забоев на расстояние 15 м",
          "В случае сближения забоев на расстояние 12 м",
          "В случае сближения забоев на расстояние 10 м",
          "В случае сближения забоев на расстояние 8 м",
        ],
        correctAnswers: ["В случае сближения забоев на расстояние 15 м"],
      },
      {
        code: "21201144",
        text: "При каком размере целика между встречными забоями работы должны проводиться только из одного забоя?",
        answers: ["7 м", "6 м", "5 м", "4 м"],
        correctAnswers: ["7 м"],
      },
      {
        code: "21201145",
        text: "Каким должно быть расстояние между параллельно проводимыми выработками угольных и нефтяных шахт, при котором взрывание зарядов в каждом забое должно проводиться только после вывода людей из забоя параллельной выработки и выставления постов охраны, предусмотренных паспортами буровзрывных работ?",
        answers: [
          "15 м и менее",
          "20 м и менее",
          "25 м и менее",
          "30 м и менее",
        ],
        correctAnswers: ["15 м и менее"],
      },
      {
        code: "21201146",
        text: "На какое расстояние должен отставать забой выработки от забоя, где проводится взрывание, при параллельной выработке угольных и нефтяных шахт, при котором разрешается не выводить людей из выработки?",
        answers: [
          "На расстояние не менее 35 м",
          "На расстояние не менее 40 м",
          "На расстояние не менее 45 м",
          "На расстояние более 50 м",
        ],
        correctAnswers: ["На расстояние более 50 м"],
      },
      {
        code: "21201147",
        text: "С какой скоростью разрешается спуск-подъем боевиков в опрокидывающихся бадьях  при движении по направляющим?",
        answers: [
          "Со скоростью, не превышающей 3,5 м/с",
          "Со скоростью, не превышающей 3,0 м/с",
          "Со скоростью, не превышающей 2,5 м/с",
          "Со скоростью, не превышающей 2,0 м/с",
        ],
        correctAnswers: ["Со скоростью, не превышающей 2,0 м/с"],
      },
      {
        code: "21201148",
        text: "Что из перечисленного содержит распорядительный документ организации, устанавливающий специальный режим, при соблюдении которого допускается проводить взрывные работы в выработках, в которых имеется газовыделение или взрывчатая пыль?",
        answers: [
          "Только наименование забоя, тип применяемого взрывчатого вещества и средств взрывания",
          "Только установленное время ведения взрывных работ, включая начало заряжания, время проветривания, осмотра забоя",
          "Только места вывода людей и место укрытия взрывника, наличие людей на пути движения исходящей струи воздуха",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21201149",
        text: "Какой должна быть минимальная величина забойки для всех забоечных материалов при взрывании по породе при глубине шпуров более 1 м?",
        answers: ["0,5 м", "0,4 м", "0,3 м", "0,2 м"],
        correctAnswers: ["0,5 м"],
      },
      {
        code: "21201150",
        text: "Каким должно быть расстояние от заряда взрывчатых веществ до ближайшей поверхности по породе?",
        answers: [
          "Не менее 0,3 м",
          "Не менее 0,25 м",
          "Не менее 0,22 м",
          "Не менее 0,15 м",
        ],
        correctAnswers: ["Не менее 0,3 м"],
      },
      {
        code: "21201151",
        text: "Какие действия необходимо произвести при проведении сотрясательного взрывания?",
        answers: [
          "Перед началом заряжания во всех выработках шахты, расположенных в пределах опасной зоны, электроэнергия должна быть отключена",
          "При проведении взрывания должны быть отключены вентиляторы местного проветривания",
          "При проведении взрывания должны быть отключены         приборы автоматического контроля содержания метана",
          "Все перечисленные",
        ],
        correctAnswers: [
          "Перед началом заряжания во всех выработках шахты, расположенных в пределах опасной зоны, электроэнергия должна быть отключена",
        ],
      },
      {
        code: "21201152",
        text: "Какие требования предъявляются к состоянию выработки, в которой проводится сотрясательное взрывание, перед взрывными работами?",
        answers: [
          "Выработка должна быть освобождена на протяжении не менее 100 м от забоя от вагонеток и других предметов, загромождающих ее более чем на 1/3 площади поперечного сечения",
          "Выработка должна быть освобождена на протяжении не менее 90 м от забоя от вагонеток и других предметов, загромождающих ее более чем на одну треть площади поперечного сечения",
          "Выработка должна быть освобождена на протяжении не менее 75 м от забоя от вагонеток и других предметов, загромождающих ее более чем на одну треть площади поперечного сечения",
          "Выработка должна быть освобождена на протяжении не менее 50 м от забоя от вагонеток и других предметов, загромождающих ее более чем на одну треть площади поперечного сечения",
        ],
        correctAnswers: [
          "Выработка должна быть освобождена на протяжении не менее 100 м от забоя от вагонеток и других предметов, загромождающих ее более чем на 1/3 площади поперечного сечения",
        ],
      },
      {
        code: "21201153",
        text: "Кто дает разрешение  на проведение сотрясательного взрывания  в подземных выработках?",
        answers: [
          "Непосредственный руководитель сотрясательным взрыванием в забое",
          "Руководитель сотрясательным взрыванием с поверхности шахты",
          "Лицо технического надзора",
          "Технический руководитель организации",
        ],
        correctAnswers: [
          "Руководитель сотрясательным взрыванием с поверхности шахты",
        ],
      },
      {
        code: "21201154",
        text: "При выполнении каких условий  после окончания сотрясательного взрывания по разрешению руководителя сотрясательным взрыванием с поверхности должен проводиться  осмотр выработки?",
        answers: [
          "Не ранее чем через 30 мин после взрыва и при концентрации метана менее 2,0 %",
          "Не ранее чем через 25 мин после взрыва и при концентрации метана менее 2,5 %",
          "Не ранее чем через 20 мин после взрыва и при концентрации метана менее 2,5 %",
          "Не ранее чем через 15 мин после взрыва и при концентрации метана менее 3,0 %",
        ],
        correctAnswers: [
          "Не ранее чем через 30 мин после взрыва и при концентрации метана менее 2,0 %",
        ],
      },
      {
        code: "21201155",
        text: "Где разрешается применять предохранительные взрывчатые вещества III класса?",
        answers: [
          "В угольных и смешанных забоях выработок, проводимых по угольным пластам, опасным по взрывам пыли, при отсутствии выделения метана в этих выработках",
          "В бутовых штреках с нижней подрывкой пород",
          "При взрывании по породе в смешанных забоях выработок, проводимых по пластам, опасным по внезапным выбросам угля и газа, при опережающем породном забое",
          "В забоях стволов, проводимых только по породе, при их углубке с действующих горизонтов и выделении в них метана",
        ],
        correctAnswers: [
          "В забоях стволов, проводимых только по породе, при их углубке с действующих горизонтов и выделении в них метана",
        ],
      },
      {
        code: "21201156",
        text: "Где разрешается применять предохранительные взрывчатые вещества IV класса?",
        answers: [
          "В нишах лав, не отнесенных к забоям с повышенным выделением метана",
          "В забоях выработок, проводимых только по породе, в том числе и по выбросоопасным породам, при выделении метана и отсутствии взрывчатой пыли",
          "В угольных и смешанных забоях восстающих (10° и более) выработок, в которых выделяется метан, при проведении их с предварительно пробуренными скважинами, обеспечивающими проветривание выработок за счет общешахтной депрессии",
          "В угольных и смешанных забоях горизонтальных, наклонных и восстающих (до 10°) выработок, проводимых по пластам, опасным по газу или пыли, в которых отсутствует повышенное выделение метана при взрывных работах",
        ],
        correctAnswers: [
          "В угольных и смешанных забоях горизонтальных, наклонных и восстающих (до 10°) выработок, проводимых по пластам, опасным по газу или пыли, в которых отсутствует повышенное выделение метана при взрывных работах",
        ],
      },
      {
        code: "21201157",
        text: "Где разрешается применять предохранительные взрывчатые вещества V класса?",
        answers: [
          "В верхних нишах лав с повышенным выделением метана",
          "В забоях выработок, проводимых по нарушенному массиву (в том числе и в забоях выработок, проводимых в присечку к нарушенному массиву), при выделении в них метана",
          "В угольных забоях восстающих (10° и более) выработок, в которых выделяется метан, при проведении их без предварительно пробуренных скважин",
          "В нишах лав, не отнесенных к забоям с повышенным выделением метана",
        ],
        correctAnswers: [
          "В нишах лав, не отнесенных к забоям с повышенным выделением метана",
        ],
      },
      {
        code: "21201158",
        text: "Какие требования предъявляются к оросителям, установленным на противопожарном водопроводе в каждом забое продуктивного пласта?",
        answers: [
          "Устанавливается два оросителя на расстоянии не более 8 м от забоя, водоразбрызгиватели должны приводиться в действие за 5 мин до взрывания зарядов",
          "Устанавливается один ороситель на расстоянии не более 8 м от забоя, водоразбрызгиватель должен приводиться в действие за 10 мин до взрывания зарядов",
          "Устанавливается один ороситель на расстоянии не более 10 м от забоя, водоразбрызгиватель должен приводиться в действие за 15 мин до взрывания зарядов",
          "Устанавливается не менее двух оросителей на расстоянии не более 10 м от забоя, водоразбрызгиватели должны приводиться в действие за 8 мин до взрывания зарядов",
        ],
        correctAnswers: [
          "Устанавливается два оросителя на расстоянии не более 8 м от забоя, водоразбрызгиватели должны приводиться в действие за 5 мин до взрывания зарядов",
        ],
      },
      {
        code: "21201159",
        text: "На каком расстоянии от жилых и производственных строений, от главных стационарных железнодорожных путей  должны размещаться места  (площадки) выгрузки, погрузки и  отстоя  железнодорожных вагонов со взрывчатыми материалами?",
        answers: [
          "Не менее 50 м",
          "Не менее 75 м",
          "Не менее 100 м",
          "Не менее 125 м",
        ],
        correctAnswers: ["Не менее 125 м"],
      },
      {
        code: "21201160",
        text: "Каким образом очищается оборудование при наличии несмываемых остатков взрывчатых веществ по окончании заряжания? Укажите все правильные ответы.",
        answers: [
          "Паром",
          "Продувкой сжатым воздухом",
          "Горячей водой",
          "Холодным мыльным раствором",
        ],
        correctAnswers: ["Паром", "Горячей водой"],
      },
      {
        code: "21201161",
        text: "Какое действие из перечисленных допускается при ведении взрывных работ?",
        answers: [
          "Переломы выходящих из зарядов концов детонирующего шнура",
          "Применять рассредоточенные заряды в шахтах, опасных по газу или пыли, в породных забоях выработок, в которых отсутствует выделение горючих газов, во врубовых шпурах",
          "Все ответы неверны",
          "Взрывание зарядов без забойки на шахтах (рудниках), опасных по газу и пыли",
          "Тянуть провода электронных детонаторов и электродетонаторов, введенные в боевики",
        ],
        correctAnswers: [
          "Применять рассредоточенные заряды в шахтах, опасных по газу или пыли, в породных забоях выработок, в которых отсутствует выделение горючих газов, во врубовых шпурах",
        ],
      },
      {
        code: "21201162",
        text: "Кем проверяется подготовленный к массовому взрыву подэтаж (блок, панель)?",
        answers: [
          "Руководителем рудника (шахты, объекта строительства)",
          "Техническим руководителем рудника (шахты, объекта строительства)",
          "Руководителем массового взрыва",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21201163",
        text: "Кем и с какой периодичностью осматриваются помещения и площадки, где проводится обработка металлов, рассчитанные на взрыв максимально допустимого заряда?",
        answers: [
          "Ростехнадзором не реже одного раза в месяц",
          "Комиссиями организаций ежегодно",
          "Муниципальными органами не реже одного раза в шесть месяцев",
          "Профессиональными аварийно-спасательными службами и формированиями каждые три месяца",
        ],
        correctAnswers: ["Комиссиями организаций ежегодно"],
      },
      {
        code: "21201164",
        text: "Что из перечисленного допускается в процессе пневмотранспортирования или пневмозаряжания?",
        answers: [
          "Производить одновременное заряжание взрывчатых веществ, содержащих в своем составе тротил или алюминиевую пудру",
          "Одновременно производить в одном забое ручное формирование основного заряда с применением высокочувствительных к механическим воздействиям взрывчатых веществ и пневматическое заряжание",
          "Смешивать два и более типа взрывчатых веществ",
          "Расстилать брезент на месте заряжания под восходящими скважинами",
        ],
        correctAnswers: [
          "Расстилать брезент на месте заряжания под восходящими скважинами",
        ],
      },
      {
        code: "21201165",
        text: "Каким образом запрещается производить заряжание шпуров (скважин) и монтаж взрывной сети на высоте более 2 м?",
        answers: [
          "С оборудованных подъемных площадок (помостов)",
          "С полков, примыкающих к забою",
          "С площадок подъемных механизмов или с применением погрузочно-доставочной техники",
          "С применением погрузочно-доставочной техники",
          "С лестниц",
        ],
        correctAnswers: ["С лестниц"],
      },
      {
        code: "21201166",
        text: "Какая допускается максимальная температура сжатого воздуха (при работе с автономным компрессором), а также нагрева узлов зарядных устройств, через которые проходят взрывчатые вещества?",
        answers: ["+40 °C", "+50 °C", "+60 °C", "+70 °C"],
        correctAnswers: ["+60 °C"],
      },
      {
        code: "21201167",
        text: "На каком минимальном расстоянии от места взрыва должно находиться место укрытия взрывников при пропуске угля и породы в восстающих выработках?",
        answers: ["50 м", "100 м", "150 м", "200 м"],
        correctAnswers: ["150 м"],
      },
      {
        code: "21201168",
        text: "Какое количество приемов допускается при проведении взрывания по породе выработок, в которых отсутствует выделение метана, с применением электродетонаторов мгновенного, короткозамедленного и замедленного действия со временем замедления до 2 с?",
        answers: [
          "Не более четырех приемов",
          "Не более трех приемов",
          "Не более двух приемов",
          "Без ограничения количества приемов",
        ],
        correctAnswers: ["Без ограничения количества приемов"],
      },
      {
        code: "21201169",
        text: "При какой температуре запрещается заряжание и взрывание зарядов в шпурах при ведении взрывных работ по металлу?",
        answers: ["До 60 °C", "Более 80 °C", "До 180 °C", "Cвыше 200 °C"],
        correctAnswers: ["Cвыше 200 °C"],
      },
      {
        code: "21201170",
        text: "Какие прострелочно-взрывные аппараты должны подниматься над устьем скважины и опускаться с помощью грузоподъемных механизмов?",
        answers: [
          "Прострелочно-взрывные аппараты массой более 40 кг до 50 кг или длиной более 1,5 м до 2 м",
          "Прострелочно-взрывные аппараты массой более 30 кг до 40 кг или длиной более 1 м до 1,5 м",
          "Прострелочно-взрывные аппараты массой более 20 кг до 30 кг или длиной более 0,5 м до 1 м",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21201171",
        text: "Какие действия обязаны предпринять работники подземного рудника (шахты), обнаружившие отказавший заряд? Укажите все правильные ответы.",
        answers: [
          "Прекратить все работы",
          "Закрестить выработку",
          "Выставить отличительный знак у невзорвавшегося заряда",
          "Немедленно поставить в известность технического руководителя организации (шахты, рудника, карьера, разреза) или лицо, его замещающее",
        ],
        correctAnswers: ["Прекратить все работы", "Закрестить выработку"],
      },
      {
        code: "21201172",
        text: "Где должен находиться руководитель сотрясательного взрывания в забое, замеряющий содержание метана, при продвижении к забою для осмотра его после сотрясательного взрывания?",
        answers: [
          "Рядом со взрывником",
          "На расстоянии 8 м позади взрывника",
          "На расстоянии 3 м впереди взрывника",
          "Должен продвигаться к забою без взрывника",
        ],
        correctAnswers: ["На расстоянии 3 м впереди взрывника"],
      },
      {
        code: "21201173",
        text: "Кем разрабатывается и согласовывается с командиром обслуживающего аварийно-спасательного формирования план по обслуживанию массового взрыва силами аварийно-спасательных формирований? Укажите все правильные ответы.",
        answers: [
          "Руководителем рудника, шахты, объекта строительства",
          "Техническим руководителем рудника, шахты, объекта строительства",
          "Инспектором территориального органа Ростехнадзора.",
          "Ответственным руководителем взрыва",
        ],
        correctAnswers: [
          "Техническим руководителем рудника, шахты, объекта строительства",
          "Ответственным руководителем взрыва",
        ],
      },
      {
        code: "21201174",
        text: "При каких условиях допускается проведение прострелочных или взрывных работ в скважинах?",
        answers: [
          "Без применения лубрикаторов в сухих газирующих и поглощающих раствор скважинах",
          "Во время туманов (при видимости более 50 м) при выполнении работ в закрытых помещениях буровых",
          "Во время пурги, буранов",
          "Во время грозы",
        ],
        correctAnswers: [
          "Во время туманов (при видимости более 50 м) при выполнении работ в закрытых помещениях буровых",
        ],
      },
      {
        code: "21201175",
        text: "Кем проверяется состояние зарядных устройств не реже одного раза в месяц?",
        answers: [
          "Ростехнадзором",
          "Комиссией организации, эксплуатирующей зарядное оборудование",
          "Профессиональной аварийно-спасательной службой (формированием)",
          "Предприятием-изготовителем оборудования",
        ],
        correctAnswers: [
          "Комиссией организации, эксплуатирующей зарядное оборудование",
        ],
      },
      {
        code: "21201176",
        text: "В каких количествах разрешается размещать взрывчатые вещества непосредственно у зарядного оборудования?",
        answers: [
          "Не более сменной производительности оборудования",
          "Не более недельной производительности оборудования",
          "Не более декадной производительности оборудования",
          "Не более месячной производительности оборудования",
        ],
        correctAnswers: ["Не более сменной производительности оборудования"],
      },
      {
        code: "21201177",
        text: "Из чего состоит проект буровзрывных (взрывных) работ (проект массового взрыва) для конкретных условий?",
        answers: [
          "Только из технического расчета со схемой расположения скважин и графическими материалами",
          "Только таблицы параметров взрывных работ",
          "Только распорядка проведения массового взрыва",
          "Из всего перечисленного",
        ],
        correctAnswers: ["Из всего перечисленного"],
      },
      {
        code: "21201178",
        text: "Какое из перечисленных требований запрещается при ведении взрывных работ на угольных шахтах и рудниках, опасных по газу и (или) пыли? Укажите все правильные ответы.",
        answers: [
          "Перед каждым заряжанием шпуров, их взрыванием и при осмотре забоя после взрывания проводить замер концентрации метана по всему сечению забоя",
          "Выполнять взрывные работы при содержании метана 1% и более в забоях и в примыкающих выработках на протяжении 20 м от них",
          "Предварительно рыхлить угольный массив в очистных забоях впереди комбайнов, стругов",
          "Взрывные работы проводятся только в забоях выработок, непрерывно и устойчиво проветриваемых, и при осуществлении необходимых мер пылевзрывозащиты",
        ],
        correctAnswers: [
          "Выполнять взрывные работы при содержании метана 1% и более в забоях и в примыкающих выработках на протяжении 20 м от них",
          "Предварительно рыхлить угольный массив в очистных забоях впереди комбайнов, стругов",
        ],
      },
      {
        code: "21201179",
        text: "Каким образом должен упаковываться заряд при температуре более 80 °C в шпуре (скважине, рукаве) при ведении взрывных работ по металлу?",
        answers: [
          "В оберточную бумагу",
          "Внутри изолирующей оболочки патрона-боевика и в общей оболочке скручивается и свертывается огнепроводный шнур",
          "В термоизолирующую оболочку",
          "В пергаментную бумагу.",
        ],
        correctAnswers: ["В термоизолирующую оболочку"],
      },
      {
        code: "21201180",
        text: "Какое количество зарядов разрешается одновременно заряжать и взрывать при температуре в шпуре ниже 80 °C при ведении взрывных работ по металлу?",
        answers: [
          "Не более пяти зарядов",
          "Не более шести зарядов",
          "Не более семи зарядов",
          "Не более десяти зарядов",
        ],
        correctAnswers: ["Не более пяти зарядов"],
      },
      {
        code: "21201181",
        text: "В каком случае должно проводиться сотрясательное взрывание при отработке пластов, опасных по внезапным выбросам угля, породы и газа? Укажите все правильные ответы.",
        answers: [
          "При вскрытии угрожаемых угольных пластов, если прогнозом установлены опасные значения показателей выбросоопасности или прогноз перед вскрытием не проводился",
          "При проведении восстающих выработок с углом наклона 15°",
          "При вскрытии песчаников на глубине 300 м и более, если прогноз выбросоопасности перед вскрытием не осуществлялся",
          "При вскрытии выбросоопасных угольных пластов мощностью более 0,1 м",
        ],
        correctAnswers: [
          "При вскрытии угрожаемых угольных пластов, если прогнозом установлены опасные значения показателей выбросоопасности или прогноз перед вскрытием не проводился",
          "При вскрытии выбросоопасных угольных пластов мощностью более 0,1 м",
        ],
      },
      {
        code: "21201182",
        text: "Кто должен осмотреть состояние кровли и стенок выработки и принять меры по приведению их в безопасное состояние перед началом работы по механизированному заряжанию шпуров, скважин или камер? Укажите все правильные ответы.",
        answers: [
          "Взрывник",
          "Специально проинструктированный рабочий",
          "Руководитель взрывных работ",
          "Помощник взрывник по подноске ВВ",
        ],
        correctAnswers: ["Взрывник", "Руководитель взрывных работ"],
      },
      {
        code: "21201183",
        text: "После чего начинаются загрузка бункера зарядного оборудования и непосредственно заряжание?",
        answers: [
          "После того, как взрывник и специально проинструктированный рабочий доставят средства инициирования и взрывчатые материалы к месту производства взрывных работ",
          "После того, как взрывник и руководитель взрывных работ осмотреть состояние кровли и стенок выработки и принять меры по приведению их в безопасное состояние",
          "После того, как руководитель взрывных работ убедится в том, что блок и зарядные устройства к этим работам подготовлены",
          "После того, как взрывник ознакомится с техническим состоянием зарядного оборудования и проверит состояние узлов оборудования",
        ],
        correctAnswers: [
          "После того, как руководитель взрывных работ убедится в том, что блок и зарядные устройства к этим работам подготовлены",
        ],
      },
      {
        code: "21201184",
        text: "На каком расстоянии от места заряжания скважин и стоянки зарядной машины и трубопровода запрещается производить какие-либо работы, непосредственно не связанные с заряжанием?",
        answers: [
          "Не ближе 50 м",
          "Не ближе 60 м",
          "Не ближе 70 м",
          "Не ближе 100 м",
        ],
        correctAnswers: ["Не ближе 50 м"],
      },
      {
        code: "21201185",
        text: "Какое из перечисленных условий допуска работников на рабочие места после производства массовых взрывов указано верно?",
        answers: [
          "Не ранее чем через 15 минут после взрыва и осмотра взорванных блоков с принятием мер, предотвращающих отравление газами проверяющих работников",
          "После осмотра места взрыва на предмет отсутствия отказов скважинных зарядов и снижения концентрации ядовитых продуктов взрыва в воздухе до установленных норм",
          "После снятия постов охраны опасной зоны",
          "После получения ответственным руководителем массового взрыва сообщения от представителя вспомогательной горно-спасательной команды о снижении концентрации ядовитых продуктов взрыва в воздухе до установленных ПДК, но не ранее чем через 30 минут после взрыва, рассеивания пылевого облака и полного восстановления видимости, а также осмотра мест (места) взрыва.",
        ],
        correctAnswers: [
          "После получения ответственным руководителем массового взрыва сообщения от представителя вспомогательной горно-спасательной команды о снижении концентрации ядовитых продуктов взрыва в воздухе до установленных ПДК, но не ранее чем через 30 минут после взрыва, рассеивания пылевого облака и полного восстановления видимости, а также осмотра мест (места) взрыва.",
        ],
      },
      {
        code: "21201186",
        text: "Какие условия должны соблюдаться в забоях выработок, где имеется газовыделение или взрывчатая угольная пыль?",
        answers: [
          "В подготовительных выработках, проводимых по углю с подрывкой боковых пород, взрывание зарядов в шпурах по углю и породе проводится только раздельно, раздельное взрывание должно осуществляться только по разрешению технического руководителя шахты при числе циклов не более одного по углю и одного по породе, за исключением случаев создания опережающих заходок в начале проведения выработок, но не более 10 м",
          "Общее максимальное время замедления электродетонаторов короткозамедленного действия с учетом разброса по времени срабатывания не должно превышать при применении взрывчатых веществ IV класса - 320 мс",
          "При протяженности угольного забоя более 5 м разрешается его делить по длине на участки, и взрывание в каждом из них производить раздельно",
          "Все перечисленные условия",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "При протяженности угольного забоя более 5 м разрешается его делить по длине на участки, и взрывание в каждом из них производить раздельно",
        ],
      },
      {
        code: "21201187",
        text: "Какой документ служит для отпуска взрывчатых материалов взрывникам для производства взрывных работ?",
        answers: [
          "Наряд-накладная на выдачу и перемещение взрывчатых материалов",
          "Единая книжка взрывника",
          "Наряд-путевка",
          "Акт о готовности забоя к заряжанию",
        ],
        correctAnswers: ["Наряд-путевка"],
      },
      {
        code: "21201188",
        text: "При каком условии разрешается выход взрывника из укрытия после взрыва при взрывании с применением электродетонаторов?",
        answers: [
          "Не ранее чем через 5 мин",
          "После проветривания",
          "После отсоединения электровзрывной сети от источника тока и замыкания ее накоротко",
          "При выполнении всех перечисленных условий",
        ],
        correctAnswers: ["При выполнении всех перечисленных условий"],
      },
      {
        code: "21201189",
        text: "Кем должны проводиться заряжание и забойка при ведении взрывных работ по металлу?",
        answers: [
          "Одним взрывником",
          "Одним взрывником в присутствии руководителя взрывных работ",
          "Двумя взрывниками в присутствии руководителя взрывных работ",
          "Тремя взрывниками в присутствии руководителя взрывных работ",
        ],
        correctAnswers: [
          "Двумя взрывниками в присутствии руководителя взрывных работ",
        ],
      },
      {
        code: "21201190",
        text: "Кому должны быть сданы остатки взрывчатых материалов взрывниками по окончании взрывных работ?",
        answers: [
          "Лицу, ответственному за ведение взрывных работ",
          "Взрывнику следующей смены",
          "Лично лицу, ответственному за выдачу и приемку взрывчатых материалов (раздатчику, заведующему складом взрывчатых материалов)",
          "Горному мастеру",
        ],
        correctAnswers: [
          "Лично лицу, ответственному за выдачу и приемку взрывчатых материалов (раздатчику, заведующему складом взрывчатых материалов)",
        ],
      },
      {
        code: "21201191",
        text: "Какое действие необходимо выполнять для уменьшения пыления и просыпи взрывчатых веществ?",
        answers: [
          "При пневмозаряжании алюмо- и тротилсодержащими рассыпными гранулированными взрывчатыми веществами добавлять во взрывчатые вещества воду или смачивающий раствор",
          "Выдерживать оптимальное расстояние от конца шланга до заряда",
          "При заряжании центрировать конец зарядного трубопровода относительно оси скважины",
          "Все перечисленные действия",
        ],
        correctAnswers: ["Все перечисленные действия"],
      },
      {
        code: "21201192",
        text: "Кому должен сообщить взрывник при возникновении аварийной ситуации в процессе заряжания?",
        answers: [
          "Руководителю взрывных работ",
          "Раздатчику на склад взрывчатых материалов",
          "Горному диспетчеру",
          "Взрывнику следующей смены",
        ],
        correctAnswers: ["Руководителю взрывных работ"],
      },
      {
        code: "21201193",
        text: "Каким способом разрешается проводить взрывание камерных зарядов?",
        answers: [
          "Только с применением детонирующего шнура,",
          "Только с применением электродетонаторов",
          "Только с применением неэлектрических систем инициирования",
          "Всеми перечисленными способами",
        ],
        correctAnswers: ["Всеми перечисленными способами"],
      },
      {
        code: "21201194",
        text: "Какие взрывчатые вещества должны использоваться при разупрочнении труднообрушаемых пород любой крепости?",
        answers: [
          "Взрывчатые вещества, не содержащие сенсибилизаторов, более чувствительных, чем тротил",
          "Взрывчатые вещества, содержащие гексоген",
          "Взрывчатые вещества, содержащие нитроэфиры",
          "Любые взрывчатые вещества",
        ],
        correctAnswers: [
          "Взрывчатые вещества, не содержащие сенсибилизаторов, более чувствительных, чем тротил",
        ],
      },
      {
        code: "21201195",
        text: "На какое минимальное расстояние от скважины должны быть убраны буровые установки, не имеющие приспособления для заряжания, в сложных горно-геологических условиях?",
        answers: ["5 м", "15 м", "10 м", "20 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "21201196",
        text: "Какое из перечисленных расстояний при ведении взрывных работ в подземных выработках указано верно?",
        answers: [
          "Запрещается нахождение людей в раздаточной камере при взрывных работах, проводящихся на расстоянии ближе 100 м от них",
          "При сближении забоев на расстояние 35 м перед началом заряжания шпуров в одном из встречных забоев все не связанные с выполнением взрывных работ люди должны быть удалены из этих забоев на безопасное расстояние и у входа в противоположный забой выставлен пост",
          "В параллельно проводимых выработках угольных и нефтяных шахт при расстоянии между выработками 35 м и менее взрывание зарядов в каждом забое должно проводиться только после вывода людей из забоя параллельной выработки и выставления постов охраны, предусмотренных паспортами буровзрывных работ",
          "Разрешается не выводить людей из параллельной выработки, забой которой отстает на расстояние более 30 м от забоя, где проводится взрывание",
        ],
        correctAnswers: [
          "Запрещается нахождение людей в раздаточной камере при взрывных работах, проводящихся на расстоянии ближе 100 м от них",
        ],
      },
      {
        code: "21201197",
        text: "Какое из перечисленных мест укрытия взрывников должно располагаться от места взрыва на расстоянии не менее 200 м?",
        answers: [
          "При пропуске угля и породы в восстающих выработках",
          "В лавах (слоях) с углом залегания до 18°",
          "В очистных забоях камерного типа, а также при погашении угольных целиков",
          "При проведении стволов (шурфов) с поверхности",
        ],
        correctAnswers: [
          "В очистных забоях камерного типа, а также при погашении угольных целиков",
        ],
      },
      {
        code: "21201198",
        text: "Каким образом следует проводить бурение шпуров по углю для сотрясательного взрывания?",
        answers: [
          "Вращательным способом",
          "Допускается только с применением ударных инструментов",
          "Допускается только с применением ударновращательных инструментов",
          "Любым из перечисленных способов",
        ],
        correctAnswers: ["Вращательным способом"],
      },
      {
        code: "21201199",
        text: "Какое из перечисленных требований во время взрывания при образовании каналов, канав и котлованов указано верно?",
        answers: [
          "При формировании траншейных зарядов расстояние между экскаватором (траншеекопателем) и краном, укладывающим взрывчатые материалы в траншею, должно быть не менее 1,0 суммы радиусов разворота ковша экскаватора с вытянутой рукоятью и разворота крана",
          "При формировании траншейных зарядов расстояние между краном и бульдозером, выполняющим забойку, - не менее 1,0 радиуса разворота крана.",
          "В сложных горно-геологических условиях при взрывании неэлектрическими системами инициирования и детонирующим шнуром зарядов взрывчатых веществ группы D (кроме дымного пороха) допускается размещение удлиненных горизонтальных зарядов (траншейных, щелевых) непосредственно вслед за проведением горных выработок",
          "Отставание заряда от многоковшового экскаватора должно быть не менее 10 м",
          "Отставание заряда от одноковшового экскаватора должно быть не менее 5 м",
        ],
        correctAnswers: [
          "В сложных горно-геологических условиях при взрывании неэлектрическими системами инициирования и детонирующим шнуром зарядов взрывчатых веществ группы D (кроме дымного пороха) допускается размещение удлиненных горизонтальных зарядов (траншейных, щелевых) непосредственно вслед за проведением горных выработок",
        ],
      },
      {
        code: "21201200",
        text: "Какое из перечисленных условий указано неверно при вскрытии пластов сотрясательным взрыванием с применением рассредоточенных (двухъярусных) зарядов взрывчатых веществ?",
        answers: [
          "При использовании допущенных для соответствующих условий взрывчатых веществ III и IV классов длина забойки между рассредоточенными зарядами должна быть не менее 0,75 м",
          "При использовании допущенных для соответствующих условий взрывчатых веществ III и IV классов масса первого от устья шпура заряда должна быть не более 1,2 кг.",
          "При использовании взрывчатых веществ II класса длина забойки между рассредоточенными зарядами должна быть не менее 1 м",
          "При использовании взрывчатых веществ II класса масса первого от устья шпура заряда взрывчатых веществ должна быть не более 1 кг;",
          "В шпурах с рассредоточенными зарядами замедление в донном заряде должно быть меньше, чем в первом заряде от устья",
        ],
        correctAnswers: [
          "В шпурах с рассредоточенными зарядами замедление в донном заряде должно быть меньше, чем в первом заряде от устья",
        ],
      },
      {
        code: "21201201",
        text: "В каком случае допускается оставлять взрывчатые материалы на местах работ без постоянного надзора (охраны)?",
        answers: [
          "Если взрывчатые материалы хранятся в отдельных металлических ящиках и контейнерах (сейфах)",
          "Если взрывчатые материалы защищены от атмосферных осадков",
          "В случаях, утвержденных распорядительным документом организации, ведущей взрывные работы",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21201202",
        text: "Какое действие необходимо предпринять если при проверке линии наименьшего сопротивления выявится возможность опасного разлета кусков горной массы или воздействия ударной воздушной волны при ликвидации отказавших камерных зарядов?",
        answers: [
          "Разборку забойки с последующим вводом нового боевика и взрывание в обычном порядке",
          "Провести ликвидацию по специально разработанным проектам, утвержденным руководителем (техническим руководителем) организации, ведущей взрывные работы, или назначенным им лицом",
          "Разборку забойки с последующим извлечением взрывчатых веществ",
          "Взрывание дополнительно опущенного накладного заряда",
        ],
        correctAnswers: [
          "Разборку забойки с последующим извлечением взрывчатых веществ",
        ],
      },
      {
        code: "21201203",
        text: "Для каких видов взрывных работ применяются предохранительные взрывчатые вещества VI класса?",
        answers: [
          "Для верхней и смешанной подрывки пород с f = 4 и менее в вентиляционных штреках, проводимых вслед за лавой",
          "Для ликвидации зависаний горной массы в углеспускных выработках;",
          "Для дробления негабаритов наружными зарядами",
          "Для взрывного перебивания деревянных стоек при посадке кровли",
        ],
        correctAnswers: [
          "Для верхней и смешанной подрывки пород с f = 4 и менее в вентиляционных штреках, проводимых вслед за лавой",
        ],
      },
      {
        code: "21201204",
        text: "Какое из перечисленных требований к условиям заряжания, массе зарядов взрывчатых веществ и длине забойки указано верно?",
        answers: [
          "В шпурах глубиной от 1 до 1,5 м заряд должен занимать не более половины их длины",
          "При наличии в забое нескольких обнаженных поверхностей линия наименьшего сопротивления от любой точки заряда до ближайшей обнаженной поверхности должна быть не менее 0,5 м в продуктивном пласте и не менее 0,3 м по породе",
          "В шпурах глубиной более 1,5 м заряд должен занимать не более 2/3 их длины",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "21201205",
        text: "В каком случае допускается одновременное взрывание в обоих крыльях калотты?",
        answers: [
          "При рассечке верхних штолен из восстающих",
          "При ведении горных работ в городских условиях и наличии большого притока воды",
          "При проходке тоннелей с применением электровзрывания",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "21201206",
        text: "Куда должны быть занесены машинистом погрузочной техники время обнаружения отказа, принятые меры безопасности, а также данные о том, кому сообщено об обнаружении отказа?",
        answers: [
          "Только в книгу учета прихода и расхода взрывчатых материалов",
          "Только в журнал трехступенчатого контроля за состоянием охраны и условий труда на рабочем месте",
          "Только в журнал приема-сдачи смен",
          "Во все перечисленные журналы",
        ],
        correctAnswers: ["Только в журнал приема-сдачи смен"],
      },
      {
        code: "21201207",
        text: "Как часто должны проверяться взрывные приборы на соответствие установленным техническим характеристикам?",
        answers: [
          "Не реже одного раза в 15 дней",
          "Не реже одного раза в 20 дней",
          "Не реже одного раза в 25 дней",
          "Не реже одного раза в 30 дней",
        ],
        correctAnswers: ["Не реже одного раза в 15 дней"],
      },
      {
        code: "21201208",
        text: "При какой толщине породной пробки между забоем выработки и крутым пластом (пропластком) необходимо производить вскрытие и пересечение пластов при помощи буровзрывных работ?",
        answers: [
          "Не менее 0,5 м",
          "Не менее 1 м",
          "Не менее 1,5 м",
          "Не менее 2 м",
        ],
        correctAnswers: ["Не менее 2 м"],
      },
      {
        code: "21201209",
        text: "Кто утверждает типовой проект буровзрывных (взрывных) работ?",
        answers: [
          "Руководитель (технический руководитель) организации, ведущей взрывные работы, или лицо, его замещающее",
          "Ростехнадзор",
          "Начальник отдела охраны труда и техники безопасности организации",
          "Руководитель профессиональной аварийно-спасательной службы",
        ],
        correctAnswers: [
          "Руководитель (технический руководитель) организации, ведущей взрывные работы, или лицо, его замещающее",
        ],
      },
      {
        code: "21201210",
        text: "При выполнении какого из перечисленных условий ответственный руководитель взрыва дает указание о подаче боевого сигнала?",
        answers: [
          "Только при получении донесений от лиц, ответственных за заряжание и подготовку к взрыву блоков, за охрану опасной зоны и выставление постов, а также за вывод людей с территории опасной зоны",
          "Только при условии ознакомления с заполненной таблицей параметров взрывных работ",
          "Только при условии выполнения мероприятий, перечисленных в распорядке проведения массового взрыва",
          "При выполнении всех перечисленных условий",
        ],
        correctAnswers: ["При выполнении всех перечисленных условий"],
      },
      {
        code: "21201211",
        text: "Где необходимо производить замер содержания углеводородных газов, паров жидких углеводородов перед заряжанием шпуров, а также перед взрыванием зарядов?",
        answers: [
          "Только в забое",
          "Только в примыкающих к забою выработках на протяжении 20 м",
          "Только в месте нахождения взрывника",
          "Во всех перечисленных местах",
        ],
        correctAnswers: ["Во всех перечисленных местах"],
      },
      {
        code: "21201212",
        text: "При каком содержании метана в забоях запрещается выполнять взрывные работы?",
        answers: [
          "При содержании метана 0,1 % и более",
          "При содержании метана 0,25 % и более",
          "При содержании метана 0,5 % и более",
          "При содержании метана 1 % и более",
        ],
        correctAnswers: ["При содержании метана 1 % и более"],
      },
      {
        code: "21201213",
        text: "Когда должен проводиться замер концентрации метана в месте укрытия взрывника?",
        answers: [
          "Перед каждым подключением электровзрывной сети к взрывному прибору",
          "Через каждые четыре часа",
          "Один раз в сутки",
          "Один раз в смену",
        ],
        correctAnswers: [
          "Перед каждым подключением электровзрывной сети к взрывному прибору",
        ],
      },
      {
        code: "21201214",
        text: "Какому эквивалентному значению приравнивается 1 л диоксида азота при проверке вредных продуктов взрыва в выработке (забое)?",
        answers: [
          "6,5 л метана",
          "6,5 л водорода",
          "6,5 л оксида углерода",
          "6,5 л азота",
        ],
        correctAnswers: ["6,5 л оксида углерода"],
      },
      {
        code: "21201215",
        text: "При какой минимальной концентрации метана руководитель сотрясательного взрывания в забое, замеряющий содержание метана, при продвижении к забою для осмотра его после сотрясательного взрывания, обязан немедленно возвратиться в выработку со свежей струей воздуха?",
        answers: ["1 %", "1,5 %", "2 %", "2,2 %"],
        correctAnswers: ["2 %"],
      },
      {
        code: "21201216",
        text: "С какой периодичностью проводится определение относительной влажности воздуха в забойном пространстве после внедрения пневматического заряжания?",
        answers: [
          "Не реже одного раза в квартал",
          "Не реже одного раза в шесть месяцев",
          "Не реже одного раза в год",
          "Не реже одного раза в два года",
        ],
        correctAnswers: ["Не реже одного раза в квартал"],
      },
      {
        code: "21201217",
        text: "Какого вида взрыва, после производства которого требуется больше времени для проветривания и возобновления работ в руднике (шахте, участке), чем это предусмотрено графиком проветривания горных выработок при повседневной организации работ, не существует?",
        answers: [
          "Технологического взрыва - по отбойке основного массива, его отрезке, подсечке (подрезке), а также по обрушению потолочин и целиков в пределах подэтажа",
          "Экспериментального взрыва для определения параметров буровзрывных работ при массовых взрывах",
          "Пробные взрывы",
          "Специального взрыва - по обрушению потолочин камер, междукамерных целиков на всю высоту этажа, по ликвидации пустот в пределах блока (группы блоков), по ликвидации аварийных ситуаций",
        ],
        correctAnswers: ["Пробные взрывы"],
      },
      {
        code: "21201218",
        text: "Как подразделяются склады взрывчатых материалов по месту расположения относительно земной поверхности?",
        answers: [
          "На поверхностные, полууглубленные, углубленные и подземные",
          "На надземные, полуподземные и подземные",
          "На углубленные, полууглубленные и неуглубленные",
          "На наземные, полузаглубленные и заглубленные",
        ],
        correctAnswers: [
          "На поверхностные, полууглубленные, углубленные и подземные",
        ],
      },
      {
        code: "21201219",
        text: "У каких складов взрывчатых материалов толща грунта над хранилищем составляет менее 15 м?",
        answers: ["У подземных", "У углубленных", "У полууглубленных"],
        correctAnswers: ["У углубленных"],
      },
      {
        code: "21201220",
        text: 'Какой срок эксплуатации предусматривается "Правилами безопасности при производстве, хранении и применении взрывчатых материалов промышленного назначения" для временных складов взрывчатых материалов?',
        answers: [
          "До одного года",
          "До двух лет",
          "До трех лет",
          "До пяти лет",
        ],
        correctAnswers: ["До трех лет"],
      },
      {
        code: "21201221",
        text: "В течение какого срока незатаренная аммиачная селитра может храниться в бункере без перегрузки или рыхления?",
        answers: [
          "Не более 10 дней",
          "Не более 12 дней",
          "Не более 15 дней",
          "Не более 20 дней",
        ],
        correctAnswers: ["Не более 10 дней"],
      },
      {
        code: "21201222",
        text: "На каком расстоянии от хранилищ взрывчатых материалов (ВМ) должно находиться здание выдачи ВМ (для проведения операций по выдаче ВМ взрывникам и приемке от них неизрасходованных взрывчатых веществ, средств инициирования, прострелочных и взрывных аппаратов) при оборудовании на складе ВМ этого здания вблизи въезда (входа) на склад?",
        answers: [
          "Не ближе 20 м",
          "Не ближе 5 м",
          "Не ближе 10 м",
          "Не ближе 15 м",
        ],
        correctAnswers: ["Не ближе 20 м"],
      },
      {
        code: "21201223",
        text: "Какие требования предъявляются к устройству хранилищ складов взрывчатых материалов?",
        answers: [
          "В хранилищах складов взрывчатых материалов полы должны быть без щелей, ровные, а стены - побелены или покрашены",
          "Каждое хранилище взрывчатых материалов должно проветриваться и защищаться от проникновения воды и снега",
          "Хранилища взрывчатых материалов должны обеспечиваться приточно-вытяжным естественным проветриванием",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "21201224",
        text: "Как должны располагаться стеллажи для взрывчатых веществ и средств инициирования на складах взрывчатых материалов?",
        answers: [
          "Они должны отстоять от стен не менее чем на 20 см, а от пола - не менее чем на 10 см",
          "Они должны отстоять от стен не менее чем на 15 см, а от пола - не менее чем на 8 см",
          "Они должны отстоять от стен не менее чем на 10 см, а от пола - не менее чем на 5 см",
          "Они должны отстоять от стен не менее чем на 5 см, а от пола - не менее чем на 2 см",
        ],
        correctAnswers: [
          "Они должны отстоять от стен не менее чем на 20 см, а от пола - не менее чем на 10 см",
        ],
      },
      {
        code: "21201225",
        text: "Какой должна быть высота штабеля для взрывчатых веществ в хранилище?",
        answers: [
          "Не должна превышать 2,8 м",
          "Не должна превышать 2,5 м",
          "Не должна превышать 2,2 м",
          "Не должна превышать 2,0 м",
        ],
        correctAnswers: ["Не должна превышать 2,0 м"],
      },
      {
        code: "21201226",
        text: "Какое количество рядов ящиков со взрывчатыми материалами можно устанавливать по ширине полки стеллажа?",
        answers: [
          "Не более двух рядов",
          "Не более трех рядов",
          "Не более четырех рядов",
          "Не более пяти рядов",
        ],
        correctAnswers: ["Не более двух рядов"],
      },
      {
        code: "21201227",
        text: 'Что должно в соответствии с "Правилами безопасности при производстве, хранении и применении взрывчатых материалов промышленного назначения" указываться на табличках, вывешиваемых возле камер, стеллажей и штабелей на складе взрывчатых материалов?',
        answers: [
          "Наименование взрывчатых веществ, средств инициирования или прострелочных и взрывных аппаратов, их количество, номер партии, наименование изготовителя",
          "Наименование взрывчатых веществ, средств инициирования или прострелочных и взрывных аппаратов, их количество, наименование изготовителя и дата изготовления",
          "Наименование взрывчатых веществ, средств инициирования или прострелочных и взрывных аппаратов, их количество, номер партии, дата изготовления и гарантийный срок хранения",
        ],
        correctAnswers: [
          "Наименование взрывчатых веществ, средств инициирования или прострелочных и взрывных аппаратов, их количество, номер партии, дата изготовления и гарантийный срок хранения",
        ],
      },
      {
        code: "21201228",
        text: "Какой должна быть температура воздуха в хранилищах складов и контейнеров со взрывчатыми веществами на основе аммиачной селитры?",
        answers: [
          "Она не должна превышать 30 °С",
          "Она не должна превышать 32 °С",
          "Она не должна превышать 35 °С",
          "Она не должна превышать 36 °С",
        ],
        correctAnswers: ["Она не должна превышать 30 °С"],
      },
      {
        code: "21201229",
        text: "Что из перечисленного должно располагаться на территории склада взрывчатых материалов (в пределах ограды)?",
        answers: [
          "Полигон для испытаний и уничтожения взрывчатых материалов",
          "Административно-бытовое помещение для персонала, обслуживающего склад",
          "Здание (помещение) для выдачи взрывчатых материалов",
          "Водопроводные и канализационные насосные станции",
        ],
        correctAnswers: ["Здание (помещение) для выдачи взрывчатых материалов"],
      },
      {
        code: "21201230",
        text: "Что из перечисленного должно располагаться за запретной зоной склада?",
        answers: [
          "Хранилища взрывчатых веществ",
          "Хранилища                                       средств инициирования",
          "Здание (помещение) для выдачи взрывчатых материалов",
          "Административно-бытовое помещение",
        ],
        correctAnswers: ["Административно-бытовое помещение"],
      },
      {
        code: "21201231",
        text: "Каким должно быть расстояние от ограды до ближайшего хранилища на складе взрывчатых материалов?",
        answers: [
          "Не менее 40 м",
          "Не менее 35 м",
          "Не менее 30 м",
          "Не менее 25 м",
        ],
        correctAnswers: ["Не менее 40 м"],
      },
      {
        code: "21201232",
        text: "Какие требования предъявляются к прокладке колючей проволоки (ленты), натянутой по верху ограды хранилища на складе взрывчатых материалов?",
        answers: [
          "Должна натягиваться колючая проволока в четыре нитки либо спиральная колючая проволока (лента) в один ряд на металлические стержни высотой не менее 0,5 м",
          "Должна натягиваться колючая проволока в четыре нитки на металлические стержни высотой не менее 0,4 м",
          "Должна натягиваться спиральная колючая проволока (лента) в два ряда на металлические стержни высотой не менее 2 м",
          "Должна натягиваться колючая проволока в две нитки на металлические стержни высотой не менее 0,4 м",
        ],
        correctAnswers: [
          "Должна натягиваться колючая проволока в четыре нитки либо спиральная колючая проволока (лента) в один ряд на металлические стержни высотой не менее 0,5 м",
        ],
      },
      {
        code: "21201233",
        text: "Какими должны быть   полы  в хранилищах взрывчатых материалов?",
        answers: [
          "Только деревянными",
          "Только бетонными",
          "Только глинобитными",
          "Любыми из перечисленных или глинобитными",
        ],
        correctAnswers: ["Любыми из перечисленных или глинобитными"],
      },
      {
        code: "21201234",
        text: "Каким должно быть максимальное расстояние от входа в хранилище взрывчатых материалов до наиболее удаленной точки одного помещения по проходам?",
        answers: [
          "Не более 15 м",
          "Не более 18 м",
          "Не более 25 м",
          "Не более 30 м",
        ],
        correctAnswers: ["Не более 15 м"],
      },
      {
        code: "21201235",
        text: "Каким должен быть диаметр прутка стальной решетки у окон хранилищ  складов взрывчатых материалов?",
        answers: [
          "Не менее 15 мм",
          "Не менее 12 мм",
          "Не менее 10 мм",
          "Не менее 8 мм",
        ],
        correctAnswers: ["Не менее 15 мм"],
      },
      {
        code: "21201236",
        text: "Какие источники освещения запрещается использовать в качестве аварийного освещения для хранилищ склада взрывчатых материалов?",
        answers: [
          "Рудничные аккумуляторные светильники",
          "Фонари с сухими батареями (при металлических корпусах - в резиновых чехлах)",
          "Ручные переносные лампы, питаемые от электросети",
        ],
        correctAnswers: ["Ручные переносные лампы, питаемые от электросети"],
      },
      {
        code: "21201237",
        text: "На каком расстоянии вокруг каждого здания складов взрывчатых материалов должен быть снят дерн для предохранения от лесных и напольных пожаров?",
        answers: [
          "Не менее 3,0 м",
          "Не менее 4,0 м",
          "Не менее 4,5 м",
          "Не менее 5,0 м",
        ],
        correctAnswers: ["Не менее 5,0 м"],
      },
      {
        code: "21201238",
        text: "Какие размеры должны быть у канавы, оборудованной вокруг территории склада взрывчатых материалов на расстоянии 10 м от его ограды, для предохранения от лесных и напольных пожаров?",
        answers: [
          "Шириной по верху не менее 1,5 м и глубиной не менее 0,5 м",
          "Шириной по верху не менее 1,25 м и глубиной не менее 0,75 м",
          "Шириной по верху не менее 1,2 м и глубиной не менее 0,5 м",
          "Шириной по верху не менее 1,25 м и глубиной не менее 0,45 м",
        ],
        correctAnswers: [
          "Шириной по верху не менее 1,5 м и глубиной не менее 0,5 м",
        ],
      },
      {
        code: "21201239",
        text: "В течение какого срока допускается кратковременное хранение взрывчатых материалов на специальных площадках для производства геофизических и других разовых работ?",
        answers: [
          "До 150 суток",
          "До 120 суток",
          "До 100 суток",
          "До 90 суток",
        ],
        correctAnswers: ["До 90 суток"],
      },
      {
        code: "21201240",
        text: "Где должны размещаться  взрывчатые материалы  в период кратковременного хранения на площадках?",
        answers: [
          "На деревянном настиле высотой не менее 20 см от земли и под навесом или брезентовым покрытием",
          "В передвижных прицепах",
          "В специальных временных деревянных сараях",
          "В передвижных прицепах",
        ],
        correctAnswers: [
          "На деревянном настиле высотой не менее 20 см от земли и под навесом или брезентовым покрытием",
        ],
      },
      {
        code: "21201241",
        text: "Какой воздухообмен должен быть обеспечен для проветривания подземного склада взрывчатых материалов?",
        answers: [
          "Четырехкратный часовой обмен во всех выработках",
          "Трехкратный часовой обмен во всех выработках",
          "Двукратный часовой обмен во всех выработках",
          "Однократный часовой обмен во всех выработках",
        ],
        correctAnswers: ["Четырехкратный часовой обмен во всех выработках"],
      },
      {
        code: "21201242",
        text: "На каком расстоянии от мест посадки людей в пассажирские вагоны и погрузки-выгрузки горной массы должны устраиваться раздаточные камеры?",
        answers: [
          "Не ближе 200 м",
          "Не ближе 180 м",
          "Не ближе 150 м",
          "Не ближе 120 м",
        ],
        correctAnswers: ["Не ближе 200 м"],
      },
      {
        code: "21201243",
        text: "На каком расстоянии от выработок, служащих для постоянного прохода людей, должна размещаться раздаточная камера вместимостью более 1000 кг взрывчатых веществ?",
        answers: [
          "Не менее 25 м",
          "Не менее 22 м",
          "Не менее 20 м",
          "Не менее 15 м",
        ],
        correctAnswers: ["Не менее 25 м"],
      },
      {
        code: "21201244",
        text: "На протяжении какого расстояния от раздаточных камер подводящие выработки должны быть закреплены несгораемой крепью?",
        answers: [
          "Не менее 5 м",
          "Не менее 4,5 м",
          "Не менее 4 м",
          "Не менее 3,5 м",
        ],
        correctAnswers: ["Не менее 5 м"],
      },
      {
        code: "21201245",
        text: "В каком из перечисленных положений нарушены требования к размещению площадок пунктов производства и подготовки взрывчатых веществ?",
        answers: [
          "Поверхностные пункты производства и (или) подготовки взрывчатых веществ должны располагаться на самостоятельных площадках",
          "Поверхностные пункты производства и подготовки взрывчатых веществ допускается располагать на территории склада взрывчатых материалов, а также в запретной зоне склада взрывчатых материалов, на безопасном расстоянии хранилищ взрывчатых веществ и средств инициирования",
          "Поверхностные пункты производства и подготовки взрывчатых веществ могут размещаться над действующими горными выработками",
          "Внутренние безопасные расстояния между зданиями, в которых производятся или подготавливаются взрывчатые вещества рассчитываются из условия непередачи детонации",
        ],
        correctAnswers: [
          "Поверхностные пункты производства и подготовки взрывчатых веществ могут размещаться над действующими горными выработками",
        ],
      },
      {
        code: "21201246",
        text: "На каком расстоянии должны располагаться оборудуемые отдельно подземные пункты производства и (или) подготовки взрывчатых веществ?",
        answers: [
          "Не ближе 25 м от выработок, служащих для постоянного прохода людей",
          "Не ближе 25 м от поверхности",
          "Не ближе 30 м от мест посадки людей в пассажирские вагоны и мест погрузки-выгрузки горной массы",
          "Не ближе 50 м от ствола шахты, околоствольных выработок и вентиляционных дверей, регулирующих приток свежего воздуха на всю шахту или значительные участки",
        ],
        correctAnswers: [
          "Не ближе 25 м от выработок, служащих для постоянного прохода людей",
        ],
      },
      {
        code: "21201247",
        text: "На каких складах взрывчатых материалов должна устраиваться молниезащита?",
        answers: [
          "Только на поверхностных складах",
          "Только на складах, расположенных в черте населенного пункта",
          "На всех складах с высокой грозовой активностью местности",
          "На всех складах, независимо от грозовой активности местности, за исключением складов, расположенных выше 66°33' северной широты, которые оборудовать молниезащитой не обязательно",
        ],
        correctAnswers: [
          "На всех складах, независимо от грозовой активности местности, за исключением складов, расположенных выше 66°33' северной широты, которые оборудовать молниезащитой не обязательно",
        ],
      },
      {
        code: "21201248",
        text: "Что из перечисленного  должно защищаться  как от прямых ударов, так и от вторичных воздействий молний?",
        answers: [
          "Площадки для хранения взрывчатых материалов в контейнерах",
          "Пункты отстоя транспортных средств с взрывчатыми материалами",
          "Пункты изготовления боевиков с электродетонаторами",
        ],
        correctAnswers: ["Пункты изготовления боевиков с электродетонаторами"],
      },
      {
        code: "21201249",
        text: "Что из перечисленного  должно защищаться  только от прямого удара молнии?",
        answers: [
          "Хранилища постоянных поверхностных складов взрывчатых материалов",
          "Площадки для хранения взрывчатых материалов в контейнерах",
          "Хранилища временных полууглубленных складов взрывчатых материалов",
          "Пункты изготовления боевиков с электродетонаторами",
        ],
        correctAnswers: [
          "Площадки для хранения взрывчатых материалов в контейнерах",
        ],
      },
      {
        code: "21201250",
        text: "Когда должна проверяться молниезащита?",
        answers: [
          "В предгрозовой период, но не реже одного раза в год, а также после выявления повреждений комиссией, назначенной руководителем (техническим руководителем) организации",
          "Не реже двух раз в год: в предгрозовой и послегрозовой периоды",
          "Не реже двух раз в год: весной и осенью",
          "В предгрозовой период, а затем не реже одного раза в месяц в течение грозового периода, а также после выявления повреждений комиссией, назначенной руководителем организации",
        ],
        correctAnswers: [
          "В предгрозовой период, но не реже одного раза в год, а также после выявления повреждений комиссией, назначенной руководителем (техническим руководителем) организации",
        ],
      },
      {
        code: "21201251",
        text: "Когда и кем проводится наружный осмотр молниезащитных устройств склада  взрывчатых материалов?",
        answers: [
          "Не реже одного раза в месяц заведующим складом взрывчатых материалов",
          "Не реже одного раза в квартал комиссией, назначенной руководителем организации",
          "Не реже одного раза в неделю службой энергетика организации",
          "Не реже одного раза в месяц руководителем взрывных работ, в ведении которого находится склад взрывчатых материалов",
        ],
        correctAnswers: [
          "Не реже одного раза в месяц заведующим складом взрывчатых материалов",
        ],
      },
      {
        code: "21201252",
        text: "Что из перечисленного входит в  проверку молниезащиты  складов взрывчатых материалов?",
        answers: [
          "Только наружный осмотр молниезащитных устройств",
          "Только измерение сопротивления заземлителей молниезащиты",
          "Только проверка переходного сопротивления контактов устройств защиты от вторичных воздействий молнии",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21201253",
        text: "В каком случае после осмотра молниеприемников молниеотвод должен быть заменен новым?",
        answers: [
          "Если у молниеотвода оплавился наконечник",
          "Если у молниеотвода оплавлен или поврежден конический наконечник, а молниеотвод поврежден ржавчиной более чем на 1/3 площади поперечного сечения",
          "Если молниеотвод и   поверхность в соединениях на болтах   покрыты ржавчиной",
        ],
        correctAnswers: [
          "Если у молниеотвода оплавлен или поврежден конический наконечник, а молниеотвод поврежден ржавчиной более чем на 1/3 площади поперечного сечения",
        ],
      },
      {
        code: "21201254",
        text: "В какой период времени должно проводиться измерение сопротивления заземлителей?",
        answers: [
          "В предгрозовой период",
          "Один раз в год: в весенний период",
          "В период наибольшего просыхания грунта",
          "Два раза в год: в весенний и осенний периоды",
        ],
        correctAnswers: ["В период наибольшего просыхания грунта"],
      },
      {
        code: "21201255",
        text: "При какой площади поперечного сечения токоотводы, поврежденные ржавчиной, должны быть заменены новыми?",
        answers: [
          "Менее 65 мм2",
          "Менее 60 мм2",
          "Менее 55 мм2",
          "Менее 50 мм2",
        ],
        correctAnswers: ["Менее 50 мм2"],
      },
      {
        code: "21201256",
        text: "В какой документ заносятся измеренные сопротивления заземлителей молниезащиты?",
        answers: [
          "В ремонтный журнал",
          "В сменный журнал",
          "В ведомость состояния заземлителей молниезащиты",
          "В протокол осмотра заземлителей",
        ],
        correctAnswers: ["В ведомость состояния заземлителей молниезащиты"],
      },
      {
        code: "21201257",
        text: "Какие из перечисленных камер относятся к вспомогательным камерам подземного склада?",
        answers: [
          "Камеры для размещения средств механизации взрывных работ",
          "Камеры для хранения средств инициирования",
          "Камеры для хранения взрывчатых веществ",
          "Все перечисленные",
        ],
        correctAnswers: [
          "Камеры для размещения средств механизации взрывных работ",
        ],
      },
      {
        code: "21201258",
        text: "Какое расстояние   должно быть  от любой ближайшей точки камерного склада до ствола шахты и околоствольных выработок, а также до вентиляционных дверей?",
        answers: [
          "Не менее 60 м",
          "Не менее 75 м",
          "Не менее 90 м",
          "Не менее 100 м",
        ],
        correctAnswers: ["Не менее 100 м"],
      },
      {
        code: "21201259",
        text: "Каким должно быть расстояние  от ближайшей камеры склада камерного типа  до выработок, служащих для постоянного прохода людей?",
        answers: [
          "Не менее 15 м",
          "Не менее 20 м",
          "Не менее 22 м",
          "Не менее 25 м",
        ],
        correctAnswers: ["Не менее 25 м"],
      },
      {
        code: "21201260",
        text: "Каким должно быть расстояние от склада камерного типа до поверхности?",
        answers: [
          "Не менее 15 м",
          "Не менее 20 м",
          "Не менее 25 м",
          "Не менее 30 м",
        ],
        correctAnswers: ["Не менее 30 м"],
      },
      {
        code: "21201261",
        text: "Какие размеры должна иметь основная выработка склада взрывчатых материалов, в которой применяются погрузчики?",
        answers: [
          "Превышающие максимальные размеры погрузочно-разгрузочных механизмов с грузами, в том числе на криволинейных участках, не менее чем на 60 см с каждой стороны по ширине и 50 см по высоте от светильников",
          "Превышающие максимальные размеры погрузочно-разгрузочных механизмов с грузами, в том числе на криволинейных участках, не менее чем на 55 см с каждой стороны по ширине и 45 см по высоте от светильников",
          "Превышающие максимальные размеры погрузочно-разгрузочных механизмов с грузами, в том числе на криволинейных участках, не менее чем на 50 см с каждой стороны по ширине и 40 см по высоте от светильников",
          "Превышающие максимальные размеры погрузочно-разгрузочных механизмов с грузами, в том числе на криволинейных участках, не менее чем на 40 см с каждой стороны по ширине и 35 см по высоте от светильников",
        ],
        correctAnswers: [
          "Превышающие максимальные размеры погрузочно-разгрузочных механизмов с грузами, в том числе на криволинейных участках, не менее чем на 60 см с каждой стороны по ширине и 50 см по высоте от светильников",
        ],
      },
      {
        code: "21201262",
        text: "На каком расстоянии от складов взрывчатых материалов, раздаточных камер или участковых пунктов хранения взрывчатых материалов при наличии в них взрывчатых веществ (средств инициирования) запрещается вести взрывные работы?",
        answers: ["Ближе 30 м", "Ближе 28 м", "Ближе 25 м", "Ближе 20 м"],
        correctAnswers: ["Ближе 30 м"],
      },
      {
        code: "21201263",
        text: "Какое из перечисленных требований к углубленным складам взрывчатых материалов указано верно?",
        answers: [
          "Устья выработок, ведущих к углубленному складу взрывчатых материалов, должны быть оборудованы двойными дверями, открывающимися наружу",
          "Если расстояние от входа в углубленный склад взрывчатых материалов до ближайшей камеры хранения взрывчатых материалов более 15 м, склад должен иметь только один выход",
          "Наружные двери устья выработок, ведущих к углубленному складу взрывчатых материалов должны быть решетчаттыми",
          "Внутренние двери устья выработок, ведущих к углубленному складу взрывчатых материалов должны быть сплошными металлическими или деревянными, обитыми кровельной сталью",
        ],
        correctAnswers: [
          "Устья выработок, ведущих к углубленному складу взрывчатых материалов, должны быть оборудованы двойными дверями, открывающимися наружу",
        ],
      },
      {
        code: "21201264",
        text: "В каких хранилищах взрывчатых материалов запрещается пользоваться открытым огнем?",
        answers: [
          "В хранилищах натриевой селитры",
          "В хранилищах кальциевой селитры.",
          "В хранилищах аммиачной селитры.",
          "Все ответы неверны.",
        ],
        correctAnswers: ["В хранилищах аммиачной селитры."],
      },
      {
        code: "21201265",
        text: "Какое из перечисленных требований к мерам защиты от статического электричества технологического оборудования при взрывных работах указано неверно?",
        answers: [
          "В каждом производственном здании должна быть составлена схема (карта) заземления",
          "Допускается последовательное включение в заземляющую шину (провод) нескольких заземляющих аппаратов, агрегатов или трубопроводов",
          "Места для присоединения заземляющих проводников и способ их крепления должны быть указаны в технической документации оборудования",
          "Допускается объединение заземляющих устройств для защиты от статического электричества с защитным заземлением электрооборудования",
        ],
        correctAnswers: [
          "Допускается последовательное включение в заземляющую шину (провод) нескольких заземляющих аппаратов, агрегатов или трубопроводов",
        ],
      },
      {
        code: "21201266",
        text: "Какая допускается максимальная скорость движения железнодорожного подвижного состава с опасными грузами на территории пункта подготовки взрывчатых веществ?",
        answers: ["5 км/ч", "15 км/ч", "25 км/ч", "35 км/ч"],
        correctAnswers: ["15 км/ч"],
      },
      {
        code: "21201267",
        text: "Какие материалы допускается использовать для насыпки валов складов взрывчатых материалов?",
        answers: ["Камень", "Щебень", "Пластичные или сыпучие грунты"],
        correctAnswers: ["Пластичные или сыпучие грунты"],
      },
      {
        code: "21201268",
        text: "Прокладка каких труб и кабелей допускается в производственных зданиях (помещениях), где ведутся работы с окислителями или их растворами?",
        answers: [
          "Медных импульсных и командных труб",
          "Кабелей, бронированных с оцинкованной броней",
          "Кабелей, бронированных с открытой свинцовой оболочкой",
          "Пластмассовых импульсных труб",
        ],
        correctAnswers: ["Пластмассовых импульсных труб"],
      },
      {
        code: "21201269",
        text: "Каким образом должны быть окрашены заземляющие проводники, предназначенные для защиты от статического электричества, в местах присоединения к технологическому оборудованию и внутреннему контуру заземления при взрывных работах?",
        answers: [
          "Одной продольной полосой шириной 25 мм черного цвета",
          "Двумя поперечными полосами синего цвета, каждая шириной 5 мм",
          "Одной поперечной полосой шириной 15 мм красного цвета",
          "Одной продольной полосой шириной 20 мм зеленого цвета",
        ],
        correctAnswers: [
          "Одной поперечной полосой шириной 15 мм красного цвета",
        ],
      },
      {
        code: "21201270",
        text: "В течение какого срока должны храниться в организации Книга учета прихода и расхода взрывчатых материалов и Книга учета выдачи и возврата взрывчатых материалов?",
        answers: [
          "Не менее шести месяцев",
          "Не менее одного года",
          "Не менее двух лет",
          "Не менее трех лет",
        ],
        correctAnswers: ["Не менее трех лет"],
      },
      {
        code: "21201271",
        text: "Какие требования при вместимости складов и камер взрывчатых материалов указаны верно? Укажите все правильные ответы.",
        answers: [
          "Вместимость камеры в складах камерного типа не должна превышать 4000 кг взрывчатых веществ",
          "На угольных и сланцевых шахтах вместимость склада без учета емкости раздаточных камер не должна превышать семисуточного запаса взрывчатых веществ и пятнадцатисуточного запаса средств инициирования",
          "Вместимость камеры в складах камерного типа не должна превышать 2000 кг взрывчатых веществ",
          "Раздаточные камеры вместимостью до 2000 кг взрывчатых веществ могут оборудоваться на расширении выработок горизонтов",
        ],
        correctAnswers: [
          "На угольных и сланцевых шахтах вместимость склада без учета емкости раздаточных камер не должна превышать семисуточного запаса взрывчатых веществ и пятнадцатисуточного запаса средств инициирования",
          "Вместимость камеры в складах камерного типа не должна превышать 2000 кг взрывчатых веществ",
        ],
      },
      {
        code: "21201272",
        text: "Какие из перечисленных мест хранения взрывчатых материалов должны быть приняты в эксплуатацию комиссией по оценке соответствия места хранения установленным требованиям и проектной документации?",
        answers: [
          "Места размещения взрывчатых материалов на местах производства взрывных работ",
          "Передвижные склады",
          "Места сменного хранения, размещаемые вблизи мест ведения взрывных работ",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21201273",
        text: "Какая устанавливается максимальная суммарная загрузка здания, в котором производятся или подготавливаются взрывчатые вещества, с учетом взрывчатых веществ, находящихся в вагоне, смесительно-зарядной машине или другом транспортном средстве и накопительных емкостях?",
        answers: ["20 т", "40 т", "60 т", "80 т"],
        correctAnswers: ["60 т"],
      },
      {
        code: "21201274",
        text: "Какое из перечисленных условий допускается при заземлении и защите от образования статического электричества в пневмозарядных устройствах?",
        answers: [
          "Пневмозаряжание по одному трубопроводу взрывчатых веществ разных типов",
          "Производить пневматическое заряжание шпуров в подземных горных выработках при относительной влажности рудничного воздуха более 50% зарядчиками с металлической зарядной трубкой или с электропроводящим зарядным трубопроводом длиной не более 5 м",
          "Пневмозаряжание гранулированными алюмо- и тротилсодержащими взрывчатыми веществами без предварительного их увлажнения",
          "Применение полиэтиленовых и резиновых трубопроводов (шлангов) с удельным объемным электрическим сопротивлением не более 10⁷ Ом x м",
        ],
        correctAnswers: [
          "Применение полиэтиленовых и резиновых трубопроводов (шлангов) с удельным объемным электрическим сопротивлением не более 10⁷ Ом x м",
        ],
      },
      {
        code: "21201275",
        text: "В каких из перечисленных помещениях допускается хранение взрывчатых материалов?",
        answers: [
          "В тамбурах хранилищ",
          "В здании выдачи взрывчатых материалов",
          "В здании подготовки взрывчатых материалов",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21201276",
        text: "В каком случае транспортные пути для перевозок взрывчатых материалов на территории поверхностных пунктов производства (подготовки) должны располагаться на расстоянии не менее 3 м от зданий?",
        answers: [
          "В случае, если пути проходят у зданий (помещений), в которых изготавливаются или перерабатываются взрывчатые вещества",
          "В случае, если пути проходят у зданий, где имеются открытые огневые топки и источники открытого огня или где производятся работы с открытым огнем (кузницы, котельные, сварочные мастерские)",
          "В случае, если пути предназначены для подъезда к этим зданиям",
          "В случае, если пути проходят у вспомогательных зданий, находящихся на территории пункта",
        ],
        correctAnswers: [
          "В случае, если пути предназначены для подъезда к этим зданиям",
        ],
      },
      {
        code: "21201277",
        text: "В течение какого срока допускается размещать зарядные машины, загруженные взрывчатыми веществами, на специально выделенной площадке на территории склада ВМ?",
        answers: [
          "Не более суток",
          "Не более двух суток",
          "Не более трех суток",
          "Не более пяти суток",
        ],
        correctAnswers: ["Не более двух суток"],
      },
      {
        code: "21201278",
        text: "Какое из перечисленных требований к поверхностным постоянным складам указано неверно?",
        answers: [
          "Должны иметь противопожарный водоем (резервуар, скважину, насосы, гидранты)",
          "Хранилища следует располагать так, чтобы обеспечивался свободный подход и подъезд к каждому из них",
          "Расстояния между отдельными хранилищами, между иными зданиями и сооружениями на территории склада, а также до объектов за территорией должны быть установлены техническим руководителем организации",
          "На границах запретной зоны должны устанавливаться предупредительные знаки",
        ],
        correctAnswers: [
          "Расстояния между отдельными хранилищами, между иными зданиями и сооружениями на территории склада, а также до объектов за территорией должны быть установлены техническим руководителем организации",
        ],
      },
      {
        code: "21201279",
        text: "Какое требование относится к хранилищам, имеющим рампы и средства механизации погрузочно-разгрузочных работ?",
        answers: [
          "Необходимо оборудовать не менее одного тамбура",
          "Необходимо оборудовать не менее одного тамбура, вход через тамбур следует оборудовать металлической дверью",
          "Необходимо оборудовать не менее двух тамбуров, вход через тамбуры следует оборудовать не менее чем двумя двустворчатыми решетчатыми дверями",
          "Устройство тамбуров не обязательно, но обе двери подлежат установке",
        ],
        correctAnswers: [
          "Устройство тамбуров не обязательно, но обе двери подлежат установке",
        ],
      },
      {
        code: "21201280",
        text: "В каком случае допускается использовать для освещения в углубленном складе индивидуальные рудничные аккумуляторные светильники при отсутствии стационарных источников электроэнергии?",
        answers: [
          "По разрешению Ростехнадзора",
          "По разрешению руководителя (технического руководителя) организации",
          "По разрешению начальника участка охраны труда и техники безопасности",
          "По разрешению начальника углубленного склада",
        ],
        correctAnswers: [
          "По разрешению руководителя (технического руководителя) организации",
        ],
      },
      {
        code: "21201281",
        text: "Какие из перечисленных помещений разрешается размещать в зданиях пунктов производства и подготовки взрывчатых веществ, за исключением зданий, в которых непосредственно производятся или подготавливаются взрывчатые вещества? Выберите 2 варианта ответа.",
        answers: [
          "Слесарные мастерские для мелкого текущего ремонта",
          "Мастерские для производства сварочных работ",
          "Места постоянного хранения тары, отведенные в рабочем помещении",
          "Помещения временного пребывания дежурных слесарей и электриков",
        ],
        correctAnswers: [
          "Слесарные мастерские для мелкого текущего ремонта",
          "Помещения временного пребывания дежурных слесарей и электриков",
        ],
      },
      {
        code: "21201282",
        text: "Какие условия во временных складах взрывчатых материалов указаны верно? Укажите все правильные ответы.",
        answers: [
          "Устройство противопожарных водоемов (резервуаров, скважин) необязательно",
          "Рабочее освещение внутри хранилищ не может осуществляться рудничными аккумуляторными светильниками",
          "Деревянные стены и крыши могут не покрываться огнезащитным составом",
          "Устройство тамбуров не обязательно, двери могут быть одинарными",
        ],
        correctAnswers: [
          "Устройство противопожарных водоемов (резервуаров, скважин) необязательно",
          "Устройство тамбуров не обязательно, двери могут быть одинарными",
        ],
      },
      {
        code: "21201283",
        text: "Что из перечисленного может использоваться на участковых пунктах в качестве шкафов (контейнеров) для взрывчатых материалов? Укажите все правильные ответы.",
        answers: [
          "Прочные деревянные шкафы с металлическими полками",
          "Металлические сейфы или ящики, изготовленные из металлических листов толщиной не менее 2 мм",
          "Шахтные вагонетки, оборудованные металлическими крышками",
          "Металлические стеллажи, изготовленные из металлических листов толщиной не менее 2 мм",
        ],
        correctAnswers: [
          "Металлические сейфы или ящики, изготовленные из металлических листов толщиной не менее 2 мм",
          "Шахтные вагонетки, оборудованные металлическими крышками",
        ],
      },
      {
        code: "21201284",
        text: "Какое совместное хранение взрывчатых материалов не допускается?",
        answers: [
          "Детонаторы для взрывных работ, сборки детонаторов для взрывных работ и капсюли-воспламенители (группа совместимости В) с первичным взрывчатым веществом",
          "Средств инициирования группы совместимости S и средств инициирования группы совместимости В",
          "Дымных (группа совместимости D) и бездымных (группа совместимости C) порохов",
          "Детонирующего шнура групп совместимости D и S со средствами инициирования групп совместимости B и S",
        ],
        correctAnswers: [
          "Детонаторы для взрывных работ, сборки детонаторов для взрывных работ и капсюли-воспламенители (группа совместимости В) с первичным взрывчатым веществом",
        ],
      },
      {
        code: "21201285",
        text: "В каком случае оборудование и здание пунктов производства и подготовки взрывчатых веществ в целом должны быть полностью освобождены от взрывоопасных продуктов?",
        answers: [
          "Перед нерабочей сменой",
          "При необходимости поддержания части оборудования в рабочем состоянии за этим оборудованием",
          "При остановке пункта на период более двух суток",
          "Перед проверкой контролирующими органами",
        ],
        correctAnswers: ["При остановке пункта на период более двух суток"],
      },
      {
        code: "21201286",
        text: "С какой периодичностью места хранения селитры должны подвергаться очистке?",
        answers: [
          "Не реже одного раза в год",
          "Не реже одного раза в 2 года",
          "Не реже одного раза в 3 года",
          "Не реже одного раза в 4 года",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "21201287",
        text: "В каком случае обеспечивается защита хранилищ взрывчатых материалов от заноса высоких потенциалов при вводе в них электрических сетей освещения присоединением металлической брони и оболочки кабеля к заземлителю защиты от вторичных воздействий?",
        answers: [
          "При бронированных кабелях, проложенных в земле",
          "При небронированных кабелях",
          "При кабелях, присоединенных к воздушной линии",
          "Все ответы неверны",
        ],
        correctAnswers: ["При бронированных кабелях, проложенных в земле"],
      },
      {
        code: "21201288",
        text: "Взрывчатые вещества каких составов разрешается производить на пунктах производства взрывчатых веществ?",
        answers: [
          "Смеси холодного смешения гранулированной аммиачной селитры с жидкими и твердыми нефтяными, порошкообразными или другого происхождения невзрывчатыми горючими",
          "Водосодержащие смеси-суспензии, эмульсии и эмульсионные взрывчатые вещества на основе раствора аммиачной селитры или ее раствора с добавками кальциевой или натриевой селитры или карбамида с порошкообразными и жидкими невзрывчатыми горючими",
          "Всех перечисленных",
          "Смеси холодного смешения гранулированной аммиачной селитры с гранулированным (чешуированным) тротилом, жидкими и твердыми нефтяными, порошкообразными или другого происхождения невзрывчатыми горючими",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "21201289",
        text: "Кто должен вести книгу учета прихода и расхода взрывчатых материалов?",
        answers: [
          "Заведующие и раздатчики складов взрывчатых материалов",
          "Специально обученные и проинструктированные рабочие",
          "Взрывники",
          "Начальники участков буровзрывных работ",
        ],
        correctAnswers: [
          "Заведующие и раздатчики складов взрывчатых материалов",
        ],
      },
      {
        code: "21201290",
        text: "С какой периодичностью проводится измерение параметров электризации в условиях производства?",
        answers: [
          "Не реже двух раз в год",
          "Не реже одного раза в год",
          "Не реже одного раза в два года",
          "Не реже одного раза в три года",
        ],
        correctAnswers: ["Не реже двух раз в год"],
      },
    ],
    21202: [
      {
        code: "21202000",
        text: "Каким федеральным органом исполнительной власти осуществляется лицензирование деятельности, связанной с обращением взрывчатых материалов промышленного назначения?",
        answers: [
          "Ростехнадзором",
          "МЧС России",
          "Роспотребнадзором",
          "Минприроды России",
        ],
        correctAnswers: ["Ростехнадзором"],
      },
      {
        code: "21202001",
        text: "Кем выдается разрешение на ведение работ со взрывчатыми материалами промышленного назначения?",
        answers: [
          "Территориальными органами Ростехнадзора",
          "Центральным аппаратом Ростехнадзора",
          "Органами МВД России",
          "Ростехнадзором по согласованию с органами МВД России",
        ],
        correctAnswers: ["Территориальными органами Ростехнадзора"],
      },
      {
        code: "21202002",
        text: "Какой максимальный срок предоставления Ростехнадзором государственной услуги при выдаче (отказе в выдаче) разрешения на ведение работ со взрывчатыми материалами промышленного назначения установлен со дня регистрации заявления?",
        answers: [
          "Не более 60 рабочих дней",
          "Не более 45 календарных дней",
          "Не более 30 рабочих дней",
          "Не более 15 календарных дней",
        ],
        correctAnswers: ["Не более 30 рабочих дней"],
      },
      {
        code: "21202003",
        text: "Что из перечисленных документов прилагается к заявлению на выдачу разрешения на ведение работ со взрывчатыми материалами промышленного назначения на земной поверхности?",
        answers: [
          "Справка об отсутствии на участке проведения работ сельскохозяйственных угодий и особо охраняемых природных территорий, заверенная территориальным уполномоченным органом",
          "План местности с нанесением мест производства взрывных работ, границ опасной зоны и находящихся в ее пределах жилых и производственных зданий, сооружений, железных и шоссейных дорог, трубопроводов, линий электропередачи",
          "Схемы профилей работ, типовая схема охраны опасной зоны",
          "Справка об отсутствии на участке проведения работ объектов военного назначения, заверенная территориальным уполномоченным органом",
        ],
        correctAnswers: [
          "План местности с нанесением мест производства взрывных работ, границ опасной зоны и находящихся в ее пределах жилых и производственных зданий, сооружений, железных и шоссейных дорог, трубопроводов, линий электропередачи",
        ],
      },
      {
        code: "21202004",
        text: "Что является основанием для отказа в выдаче разрешения на ведение работ со взрывчатыми материалами промышленного назначения при соответствии заявительных документов требованиям законодательства Российской Федерации и нормативных правовых актов в области промышленной безопасности опасных производственных объектов?",
        answers: [
          "Наличие в составе материалов заявителя сведений об оплате государственной услуги",
          "Планирование взрывных работ в районе населенных пунктов",
          "Истечение срока рассмотрения заявления",
          "Наличие в составе материалов заявителя неполных, искаженных или недостоверных сведений",
        ],
        correctAnswers: [
          "Наличие в составе материалов заявителя неполных, искаженных или недостоверных сведений",
        ],
      },
      {
        code: "21202005",
        text: "На какой максимальный срок устанавливается срок действия сертификата соответствия взрывчатых веществ?",
        answers: ["На 2 года", "На 3 года", "На 4 года", "На 5 лет"],
        correctAnswers: ["На 3 года"],
      },
      {
        code: "21202006",
        text: "На какой срок выдается разрешение на ведение работ со взрывчатыми материалами промышленного назначения?",
        answers: [
          "Срок действия разрешения устанавливается территориальным органом Ростехнадзора в зависимости от характера взрывных работ, но не более чем на один год",
          "Срок действия разрешения устанавливается органом местного самоуправления на два года",
          "Срок действия разрешения устанавливается в зависимости от характера взрывных работ, но не более чем на шесть месяцев",
        ],
        correctAnswers: [
          "Срок действия разрешения устанавливается территориальным органом Ростехнадзора в зависимости от характера взрывных работ, но не более чем на один год",
        ],
      },
      {
        code: "21202007",
        text: "Какие из перечисленных функций включает в себя понятие руководства взрывными работами?",
        answers: [
          "Непосредственное управление технологическими процессами на производственных объектах, а также разработку, согласование и утверждение технических, методических и иных документов, регламентирующих порядок хранения взрывчатых веществ",
          "Разработку, согласование и утверждение порядка подготовки и проверки знаний специалистов по вопросам безопасного ведения работ в области взрывного дела",
          "Непосредственное управление технологическими процессами, связанными с обращением со взрывчатыми материалами, разработка, согласование и утверждение технических, проектных, методических и иных документов, регламентирующих порядок выполнения взрывных работ и работ со взрывчатыми материалами",
          "Обучение и подготовка руководителей горных и взрывных работ с выдачей лицензии на право выполнения данной деятельности, получаемой в установленном порядке",
        ],
        correctAnswers: [
          "Непосредственное управление технологическими процессами, связанными с обращением со взрывчатыми материалами, разработка, согласование и утверждение технических, проектных, методических и иных документов, регламентирующих порядок выполнения взрывных работ и работ со взрывчатыми материалами",
        ],
      },
      {
        code: "21202008",
        text: "Ктоможетполучить Единую книжку взрывника на право руководства взрывными работами?",
        answers: [
          "Лица, имеющие горнотехническое (высшее или среднее профессиональное) образование, либо образование (высшее или среднее профессиональное), связанное с обращением взрывчатых материалов",
          "Лица, имеющие любое техническое (высшее или среднее специальное) образование, не связанное с обращением взрывчатых материалов, и полученное в очной форме",
          "Лица, окончившие любые высшие учебные заведения",
          "Все перечисленные лица",
        ],
        correctAnswers: [
          "Лица, имеющие горнотехническое (высшее или среднее профессиональное) образование, либо образование (высшее или среднее профессиональное), связанное с обращением взрывчатых материалов",
        ],
      },
      {
        code: "21202009",
        text: "Кто может получить профессию взрывника, имея стаж работы в шахтах, опасных по газу и пыли?",
        answers: [
          "Лица мужского пола не моложе 16 лет, имеющие среднее специальное образование",
          "Лица мужского пола, имеющие среднее образование и стаж на подземных работах проходчика или рабочего очистного забоя не менее 2 лет",
          "Лица, не имеющие медицинских противопоказаний и имеющие стаж работы не менее одного года по специальности, соответствующей профилю работ организации",
          "Лица, имеющие высшее техническое образование и стаж не менее одного года по специальности",
        ],
        correctAnswers: [
          "Лица мужского пола, имеющие среднее образование и стаж на подземных работах проходчика или рабочего очистного забоя не менее 2 лет",
        ],
      },
      {
        code: "21202010",
        text: "В течение какого времени взрывник должен отработать стажером под руководством опытного взрывника перед допуском к самостоятельному производству взрывных работ, в том числе после обучения на новый вид взрывных работ?",
        answers: [
          "В течение 3 недель",
          "В течение 1 месяца",
          "В течение 3 месяцев",
          "В течение 9 месяцев",
        ],
        correctAnswers: ["В течение 1 месяца"],
      },
      {
        code: "21202011",
        text: "В каком из перечисленных случаев у взрывника может быть изъята Единая книжка взрывника?",
        answers: [
          "За нарушения режима рабочего времени",
          "За нарушение установленного порядка хранения, транспортирования, использования или учета взрывчатых материалов, которое привело или могло привести к несчастному случаю, аварии или утрате взрывчатых материалов",
          "За появление на рабочем месте в состоянии алкогольного или наркотического опьянения",
          "При переходе на работу в другую организацию, ведущую работы со взрывчатыми материалами",
        ],
        correctAnswers: [
          "За нарушение установленного порядка хранения, транспортирования, использования или учета взрывчатых материалов, которое привело или могло привести к несчастному случаю, аварии или утрате взрывчатых материалов",
        ],
      },
      {
        code: "21202012",
        text: "Какую информацию должен содержать акт по установлению причин инцидента на опасном производственном объекте?",
        answers: [
          "Только дату и место инцидента, его причины и обстоятельства",
          "Только информацию о продолжительности простоя и  мерах по устранению причин инцидента",
          "Только информацию о принятых мерах по ликвидации инцидента,  а также информацию о материальном ущербе, в том числе вреде, нанесенном окружающей среде",
          "Всю перечисленную информацию",
          "Только сведения о лицах, ответственных за указанный инцидент, о разработанных мероприятиях по предупреждению аналогичных инцидентов",
        ],
        correctAnswers: ["Всю перечисленную информацию"],
      },
      {
        code: "21202013",
        text: "С какой периодичностью должна направляться информация о произошедших инцидентах в территориальный орган Ростехнадзора?",
        answers: [
          "Не реже одного раза в квартал",
          "Не реже одного раза в 9 месяцев",
          "Не реже одного раза в год",
          "Не реже одного раза в 2 года",
        ],
        correctAnswers: ["Не реже одного раза в квартал"],
      },
      {
        code: "21202014",
        text: "Какую информацию должен содержать отчет о произошедших инцидентах, направляемый в территориальный орган Ростехнадзора, на территории деятельности которого располагается эксплуатируемый объект?",
        answers: [
          "Только количество произошедших инцидентов",
          "Только характер инцидентов",
          "Только анализ причин возникновения инцидентов и принятые меры по их устранению",
          "Всю перечисленную информацию",
        ],
        correctAnswers: ["Всю перечисленную информацию"],
      },
      {
        code: "21202015",
        text: "На какую из перечисленных областей направлено техническое расследование случаев утраты взрывчатых материалов промышленного назначения?",
        answers: [
          "Только на установление обстоятельств и причин утраты взрывчатых материалов промышленного назначения",
          "Только на выявление организационных и технических недостатков, приведших к нарушению действующего порядка хранения, перевозки и учета взрывчатых материалов промышленного назначения",
          "Только на определение мероприятий по недопущению подобных случаев в дальнейшем",
          "На все перечисленное",
        ],
        correctAnswers: ["На все перечисленное"],
      },
      {
        code: "21202016",
        text: "Какие из перечисленных случаев утрат взрывчатых материалов промышленного назначения, произошедших в организациях и на объектах, подлежат техническому расследованию и учету?",
        answers: [
          "Только утраты в результате пожаров",
          "Только утраты в результате стихийных бедствий",
          "Только утраты в результате промышленных аварий",
          "Все случаи утрат взрывчатых материалов",
        ],
        correctAnswers: ["Все случаи утрат взрывчатых материалов"],
      },
      {
        code: "21202017",
        text: "В какие сроки после получения оперативного сообщения об утрате взрывчатых материалов промышленного назначения должна быть сформирована комиссия по техническому расследованию обстоятельств и причин утраты взрывчатых материалов?",
        answers: [
          "Не позднее 24 часов",
          "Не позднее 36 часов",
          "Не позднее 48 часов",
          "Не позднее 72 часов",
        ],
        correctAnswers: ["Не позднее 24 часов"],
      },
      {
        code: "21202018",
        text: "Представители какой организации должны быть включены в состав комиссии по техническому расследованию обстоятельств и причин утраты взрывчатых материалов промышленного назначения?",
        answers: [
          "Только организации, в которой произошла утрата взрывчатых материалов",
          "Только территориальных органов ФСБ России",
          "Только МВД России (по согласованию)",
          "Всех перечисленных организаций, а также других организаций в соответствии с законодательством Российской Федерации",
        ],
        correctAnswers: [
          "Всех перечисленных организаций, а также других организаций в соответствии с законодательством Российской Федерации",
        ],
      },
      {
        code: "21202019",
        text: "Какие полномочия имеет комиссия по техническому расследованию обстоятельств и причин утраты взрывчатых материалов промышленного назначения в ходе технического расследования?",
        answers: [
          "Только привлекать к расследованию независимых экспертных организаций, независимых экспертов, специалистов",
          "Только проведить опросы и получать объяснения работников организации для уточнения обстоятельств утраты взрывчатых материалов, выявление недостатков в организации производства взрывных работ",
          "Все перечисленные",
          "Только проведить опросы и получать объяснения работников организации для определения конкретных причин нарушения установленного порядка хранения, перевозки, использования и учета взрывчатых материалов  промышленного назначения",
        ],
        correctAnswers: ["Все перечисленные"],
      },
      {
        code: "21202020",
        text: "В течение какого времени комиссия по техническому расследованию обстоятельств и причин утраты взрывчатых материалов промышленного назначения должна составить акт технического расследования случая утраты?",
        answers: [
          "В течение 15 рабочих дней",
          "В течение 25 рабочих дней",
          "В течение 30 рабочих дней",
          "В течение 35 рабочих дней",
        ],
        correctAnswers: ["В течение 15 рабочих дней"],
      },
      {
        code: "21202021",
        text: 'На что не распространяется действие технического регламента Таможенного союза от 20.07.2012 N 028/2012 "О безопасности взрывчатых веществ и изделий на их основе"?',
        answers: [
          "На взрывчатые вещества и изделия на их основе, разрабатываемые (проектируемые) и изготавливаемые для использования энергии взрыва в промышленных целях",
          "На взрывчатые вещества и изделия на их основе, относящиеся к оборонной продукции, и на пиротехнические изделия",
          "На взрывчатые вещества, непосредственно не применяемые для использования энергии взрыва в промышленных целях, но используемые для производства взрывчатых веществ и изделий для такого применения",
          "На эмульсии и матрицы окислителя на основе нитрата аммония, разрабатываемые (проектируемые) и изготавливаемые для получения водоэмульсионных и водногелевых взрывчатых веществ",
        ],
        correctAnswers: [
          "На взрывчатые вещества и изделия на их основе, относящиеся к оборонной продукции, и на пиротехнические изделия",
        ],
      },
      {
        code: "21202022",
        text: "Какой документ должен быть выдан на взрывчатые вещества и изделия на их основе, разрабатываемые (проектируемые) и изготавливаемые для использования энергии взрыва в промышленных целях?",
        answers: [
          "Инструкция по применению на всех государственных языках государств - членов Таможенного союза",
          "Лицензия на применение, выданная Министерством РФ по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий",
          "Разрешение на постоянное применение, выданное одним из уполномоченных органов в области промышленной безопасности государства - члена Таможенного союза",
          "Разрешение на постоянное применение, выданное всеми уполномоченными органами в области промышленной безопасности государств - членов Таможенного союза",
        ],
        correctAnswers: [
          "Разрешение на постоянное применение, выданное одним из уполномоченных органов в области промышленной безопасности государства - члена Таможенного союза",
        ],
      },
      {
        code: "21202023",
        text: 'В каком случае не требуется подтверждение соответствия взрывчатых веществ требованиям технического регламента Таможенного союза от 20.07.2012 N 028/2012 "О безопасности взрывчатых веществ и изделий на их основе"?',
        answers: [
          "Для взрывчатых веществ и изделий для использования энергии взрыва в промышленных целях",
          "Для взрывчатых веществ, выпускаемых в обращение на единой таможенной территории государств - членов Таможенного союза",
          "Для взрывчатых веществ и изделий на их основе, изготавливаемых для собственных нужд",
          "Подтверждение соответствия требуется в любом случае",
        ],
        correctAnswers: [
          "Для взрывчатых веществ и изделий на их основе, изготавливаемых для собственных нужд",
        ],
      },
      {
        code: "21202024",
        text: "На какие вещества распространяется действие технического регламента «О безопасности взрывчатых веществ и изделий на их основе»? Укажите все правильные ответы.",
        answers: [
          "На взрывчатые вещества и изделия на их основе, относящиеся к оборонной продукции",
          "На взрывчатые вещества и изделия на их основе, разрабатываемые (проектируемые) и изготавливаемые для использования энергии взрыва в промышленных целях",
          "На эмульсии и матрицы окислителя на основе нитрата аммония, разрабатываемые (проектируемые) и изготавливаемые для получения водоэмульсионных и водногелевых взрывчатых веществ",
          "На пиротехнические изделия",
        ],
        correctAnswers: [
          "На взрывчатые вещества и изделия на их основе, разрабатываемые (проектируемые) и изготавливаемые для использования энергии взрыва в промышленных целях",
          "На эмульсии и матрицы окислителя на основе нитрата аммония, разрабатываемые (проектируемые) и изготавливаемые для получения водоэмульсионных и водногелевых взрывчатых веществ",
        ],
      },
      {
        code: "21202025",
        text: " Кто должен быть включен в состав комиссии по проведению контрольных и приемочных испытаний в производственных условиях для получения Разрешения на постоянное применение взрывчатых веществ и изделий на их основе?",
        answers: [
          "Представитель организации, в которой проводятся испытания",
          "Представитель уполномоченного органа в области промышленной безопасности государства - члена Таможенного союза",
          "Представитель экспертной организации",
          "Все перечисленные лица",
        ],
        correctAnswers: ["Все перечисленные лица"],
      },
      {
        code: "21202026",
        text: "Какое из перечисленных лицензионных требований не предъявляется к соискателю лицензии на осуществлениие лицензируемого вида деятельности, связанной с обращением взрывчатых материалов промышленного назначения?",
        answers: [
          "Наличие у соискателя лицензии помещений, зданий, сооружений и иных объектов, являющихся объектами жилищного фонда и принадлежащих ему на праве собственности или на ином законном основании",
          'Соответствие технических устройств соискателя лицензии обязательным требованиям технических регламентов и (или) наличие у соискателя лицензии положительных заключений экспертизы промышленной безопасности, внесенных в реестр заключений экспертизы промышленной безопасности, на технические устройства в случаях, предусмотренных Федеральным законом "О промышленной безопасности опасных производственных объектов"',
          "Наличие у соискателя лицензии обеспечивающих выполнение заявленных видов работ технических устройств и контрольно-проверочной аппаратуры, принадлежащих ему на праве собственности или на ином законном основании, и технической документации",
          'Создание соискателем системы управления промышленной безопасностью в случаях, предусмотренных статьей 11 Федерального закона "О промышленной безопасности опасных производственных объектов", и организация соискателем лицензии производственного контроля',
        ],
        correctAnswers: [
          "Наличие у соискателя лицензии помещений, зданий, сооружений и иных объектов, являющихся объектами жилищного фонда и принадлежащих ему на праве собственности или на ином законном основании",
        ],
      },
      {
        code: "21202027",
        text: "С какой периодичностью должны проверяться знания требований безопасности работниками, связанными с обращением со взрывчатыми материалами и имеющими Единую книжку взрывника (за исключением заведующих складами взрывчатых материалов, пунктами производства взрывчатых материалов и руководителей взрывных работ) специальной комиссией организации с участием представителя территориального органа Ростехнадзора?",
        answers: [
          "Не реже одного раза в два года",
          "Не реже одного раза в три года",
          "Не реже одного раза в четыре года",
          "Не реже одного раза в пять лет",
        ],
        correctAnswers: ["Не реже одного раза в два года"],
      },
      {
        code: "21202028",
        text: "В течение какого времени должна проводиться стажировка взрывников перед допуском к самостоятельному производству взрывных работ, в том числе после обучения на новый вид взрывных работ?",
        answers: [
          "В течение 1 месяца",
          "Не менее 2 месяцев",
          "Не менее 2 недель",
          "Не менее 1 недели",
          "Все ответы неверны",
        ],
        correctAnswers: ["В течение 1 месяца"],
      },
      {
        code: "21202029",
        text: "При каком условии представители организации, эксплуатирующей опасный производственный объект, принимают участие в техническом расследовании причин аварии?",
        answers: [
          "В качестве членов комиссии по расследованию, но их число не должно превышать 30 % от общего числа членов комиссии",
          "Если в состав комиссии не включаются представители страховщика, с которым организация, эксплуатирующая опасный производственный объект или гидротехническое сооружение, заключила договор обязательного страхования гражданской ответственности",
          "Представители организации, эксплуатирующей опасный производственный объект не принимают участия в техническом расследовании причин аварии",
        ],
        correctAnswers: [
          "В качестве членов комиссии по расследованию, но их число не должно превышать 30 % от общего числа членов комиссии",
        ],
      },
      {
        code: "21202030",
        text: "Куда организация обязана направить результаты технического расследования причин аварии?",
        answers: [
          "В центральный аппарат или территориальный орган Ростехнадзора, проводивший расследование, в соответствующие органы (организации), представители которых принимали участие в работе комиссии по техническому расследованию причин аварии, в соответствующий орган прокуратуры и в другие органы (организации), определенные председателем комиссии",
          "В федеральный орган исполнительной власти в области промышленной безопасности, вышестоящий орган, орган местного самоуправления, государственную инспекцию труда субъекта Российской Федерации, территориальное объединение профсоюза, а также в территориальные органы МЧС России",
          "В центральный аппарат или территориальные органы Ростехнадзора, проводившие расследование, страховую организацию, территориальные органы МЧС России",
          "Только в федеральный орган исполнительной власти в области промышленной безопасности",
        ],
        correctAnswers: [
          "В центральный аппарат или территориальный орган Ростехнадзора, проводивший расследование, в соответствующие органы (организации), представители которых принимали участие в работе комиссии по техническому расследованию причин аварии, в соответствующий орган прокуратуры и в другие органы (организации), определенные председателем комиссии",
        ],
      },
      {
        code: "21202031",
        text: "Как назначается специальная комиссия по техническому расследованию причин аварии?",
        answers: [
          "Приказом территориального органа Ростехнадзора или, в зависимости от характера и возможных последствий аварии, приказом Ростехнадзора",
          "Постановлением Правительства Российской Федерации",
          "Решением руководителя организации",
          "Приказом МЧС и Минприроды",
        ],
        correctAnswers: [
          "Приказом территориального органа Ростехнадзора или, в зависимости от характера и возможных последствий аварии, приказом Ростехнадзора",
        ],
      },
      {
        code: "21202032",
        text: "Каким лицам право руководства взрывными работами предоставляется после дополнительного обучения по программе, согласованной с федеральным органом исполнительной власти в области промышленной безопасности?",
        answers: [
          "Все ответы неверны",
          'Лицам, окончившим высшие учебные заведения по направлению подготовки "Технологии геологической разведки"',
          "Лицам, окончившим средние специальные учебные заведения по специальности открытые горные работы, шахтное строительство или подземная разработка месторождений полезных ископаемых",
          'Лицам, окончившим высшие учебные заведения по направлениям подготовки "Нефтегазовое дело", "Горное дело"',
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21202033",
        text: "Чем регламентируется порядок проведения работ по установлению причин инцидентов на опасном производственном объекте?",
        answers: [
          "Документом, утвержденным организацией, эксплуатирующей опасный производственный объект",
          "Правововым актом федерального органа исполнительной власти в области промышленной безопасности",
          "Правововым актом федерального органа  исполнительной власти в области охраны труда",
          "Правововым актом федерального органа  исполнительной власти в области охраны недр",
        ],
        correctAnswers: [
          "Документом, утвержденным организацией, эксплуатирующей опасный производственный объект",
        ],
      },
      {
        code: "21202034",
        text: "Какой срок устанавливается для составления акта технического расследования причин аварии на опасном производственном объекте?",
        answers: [
          "30 календарных дней",
          "20 рабочих дней",
          "15 календарных дней",
          "10 рабочих дней",
        ],
        correctAnswers: ["30 календарных дней"],
      },
      {
        code: "21202035",
        text: "Чьим приказом назначается комиссия по расследованию причин инцидентов на опасном производственном объекте?",
        answers: [
          "Приказом руководителя организации, эксплуатирующей объект",
          "Приказом МЧС",
          "Приказом технического руководителя организации",
          "Приказом органа местного самоуправления, на территории которого находится производственный объект",
        ],
        correctAnswers: [
          "Приказом руководителя организации, эксплуатирующей объект",
        ],
      },
      {
        code: "21202036",
        text: "Кем осуществляется расчет вреда (экономического и экологического ущерба) от аварии?",
        answers: [
          "Организацией, на объекте которой произошла авария",
          "Страховщиком, с которым организация, эксплуатирующая опасный производственный объект заключила договор обязательного страхования гражданской ответственности",
          "Органом местного самоуправления",
          "Территориальным органом Ростехнадзора",
        ],
        correctAnswers: ["Организацией, на объекте которой произошла авария"],
      },
      {
        code: "21202037",
        text: "Кто возглавляет комиссию по техническому расследованию причин аварии на опасном производственном объекте?",
        answers: [
          "Представитель Ростехнадзора или его территориального органа",
          "Представитель органа исполнительной власти субъекта Российской Федерации",
          "Представитель органа местного самоуправления, на территории которых располагается опасный производственный объект",
          "Руководитель организации, эксплуатирующей опасный производственный объект",
        ],
        correctAnswers: [
          "Представитель Ростехнадзора или его территориального органа",
        ],
      },
      {
        code: "21202038",
        text: "Где хранятся подлинники документов, включенных в материалы технического расследования случаев утраты взрывчатых материалов промышленного назначения?",
        answers: [
          "В организации, где произошел случай утраты взрывчатых материалов промышленного назначения",
          "В прокуратуре",
          "В территориальном Органе внутренних дел Российской Федерации",
          "В территориальном органе Ростехнадзора",
        ],
        correctAnswers: [
          "В организации, где произошел случай утраты взрывчатых материалов промышленного назначения",
        ],
      },
      {
        code: "21202039",
        text: "Куда передается оперативное сообщение об аварии?",
        answers: [
          "В вышестоящий орган или организацию (при наличии таковых), а также в профсоюзную организацию",
          "В орган местного самоуправления, на территории которого располагается опасный производственный объект.",
          "Во все перечисленные организации",
          "В страховую компанию, с которой заключен договор обязательного страхования гражданской ответственности",
          "В территориальный орган Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности), осуществляющий надзор за объектом, либо территориальный орган Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности), на территории деятельности которого произошла авария (при эксплуатации передвижных технических устройств (кранов, подъемников (вышек), передвижных котельных, цистерн, вагонов, локомотивов, автомобилей)",
        ],
        correctAnswers: ["Во все перечисленные организации"],
      },
      {
        code: "21202040",
        text: "В какой срок материалы технического расследования аварии направляются территориальным органом Ростехнадзора в центральный аппарат Ростехнадзора?",
        answers: [
          "В двухнедельный срок",
          "В недельный срок",
          "В двадцатидневный срок",
          "В двухмесячный срок",
        ],
        correctAnswers: ["В двухнедельный срок"],
      },
      {
        code: "21202041",
        text: "В течение какого срока передается оперативное сообщение об аварии, инциденте на опасном производственном объекте?",
        answers: [
          "Немедленно",
          "В течение 36 часов с момента возникновения аварии, инцидента",
          "В течение 60 часов с момента возникновения аварии, инцидента",
          "В течение 24 часов с момента возникновения аварии, инцидента",
        ],
        correctAnswers: [
          "В течение 24 часов с момента возникновения аварии, инцидента",
        ],
      },
      {
        code: "21202042",
        text: "Что в обязательном порядке указывается в разрешении на постоянное применение взрывчатых веществ и изделий на их основе?",
        answers: [
          "Сведения о лицензии",
          "Данные о маркировке",
          "Изготовитель",
          "Все перечисленное",
        ],
        correctAnswers: ["Изготовитель"],
      },
      {
        code: "21202043",
        text: "В каких видах испытаний взрывчатых веществ и изделий на их основе принимает участие экспертная организация?",
        answers: [
          "Только в приемочных испытаниях",
          "Только в контрольных испытаниях",
          "В контрольных и приемочных испытаниях",
          "Экспертная организация ни в каких видах испытания участия не принимает",
        ],
        correctAnswers: ["В контрольных и приемочных испытаниях"],
      },
      {
        code: "21202044",
        text: "В течение какого срока с даты подписания акта технического расследования причин аварии руководителем организации издается приказ, определяющий меры по устранению причин и последствий аварии, по обеспечению безаварийной и стабильной работы опасного производственного объекта?",
        answers: [
          "В течение 7 рабочих дней",
          "В течение 7 календарных дней",
          "В течение 3 рабочих дней",
          "В течение 3 календарных дней",
        ],
        correctAnswers: ["В течение 7 рабочих дней"],
      },
      {
        code: "21202045",
        text: "Где проводятся приемочные испытания взрывчатых веществ и изделий на их основе?",
        answers: [
          "Только в производственных условиях",
          "Только экспертных организациях",
          "Только на полигонах",
          "Только в лабораториях",
        ],
        correctAnswers: ["Только в производственных условиях"],
      },
      {
        code: "21202046",
        text: "На сколько может быть увеличен срок технического расследования причин аварии на опасном производственном объекте?",
        answers: [
          "Не более чем на 15 календарных дней",
          "Не более чем на 15 рабочих дней",
          "Не более чем на 30 рабочих дней",
          "Не более чем на 30 календарных дней",
        ],
        correctAnswers: ["Не более чем на 15 календарных дней"],
      },
      {
        code: "21202047",
        text: "Кто является заявителем государственной услуги по выдаче разрешений на постоянное применение взрывчатых веществ и изделий на их основе?",
        answers: [
          "Только юридические лица, являющиеся потребителями взрывчатых материалов, либо их законные представители",
          "Только юридические лица, осуществляющие изготовление взрывчатых материалов, либо их законные представители",
          "Любой из перечисленных",
          "Только физические лица, являющиеся потребителями взрывчатых материалов, либо их законные представители",
        ],
        correctAnswers: [
          "Только юридические лица, осуществляющие изготовление взрывчатых материалов, либо их законные представители",
        ],
      },
      {
        code: "21202048",
        text: "Что из перечисленных документов прилагается к заявлению о выдаче разрешения на постоянное применение взрывчатых веществ и изделий на их основе?",
        answers: [
          "Акт контрольных испытаний; акт приемочных испытаний опытной партии; экспертное заключение по промышленной безопасности; технические условия (стандарт) (для взрывчатых материалов иностранного производства - при наличии); руководство (инструкция) по применению",
          "Программа и методика приемочных испытаний; акт приемочных испытаний опытной партии; экспертное заключение по промышленной безопасности; технические условия (стандарт) (для взрывчатых материалов иностранного производства - при наличии); руководство (инструкция) по применению",
          "Акт приемочных испытаний опытной партии; экспертное заключение по промышленной безопасности; технические условия (стандарт) (для взрывчатых материалов иностранного производства - при наличии); руководство (инструкция) по применению",
          "Акт приемочных испытаний опытной партии; экспертное заключение по промышленной безопасности; технические условия; руководство (инструкция) по применению",
        ],
        correctAnswers: [
          "Акт приемочных испытаний опытной партии; экспертное заключение по промышленной безопасности; технические условия (стандарт) (для взрывчатых материалов иностранного производства - при наличии); руководство (инструкция) по применению",
        ],
      },
      {
        code: "21202049",
        text: "На кого возлагается финансирование расходов на техническое расследование причин аварий?",
        answers: [
          "На страховую компанию, с которой заключен договор обязательного страхования гражданской ответственности владельца опасного объекта",
          "На территориальный орган Ростехнадзора",
          "На организацию, эксплуатирующую опасные производственные объекты",
          "На орган местного самоуправления",
        ],
        correctAnswers: [
          "На организацию, эксплуатирующую опасные производственные объекты",
        ],
      },
      {
        code: "21202050",
        text: "К какому классу опасности относятся все взрывчатые вещества и изделия на их основе?",
        answers: ["К первому", "Ко второму", "К третьему", "К четвертому"],
        correctAnswers: ["К первому"],
      },
      {
        code: "21202051",
        text: "К какой группе совместимости (опасности) относятся изделия, содержащие инициирующие взрывчатые вещества и имеющие менее двух независимых предохранительных устройств?",
        answers: ["К группе А", "К группе В", "К группе С", "К группе D"],
        correctAnswers: ["К группе В"],
      },
      {
        code: "21202052",
        text: "Какой цвет отличительной полосы или оболочек патронов (пачек) должны иметь непредохранительные взрывчатые вещества для взрывания только на земной поверхности?",
        answers: ["Красный", "Белый", "Синий", "Зеленый"],
        correctAnswers: ["Белый"],
      },
      {
        code: "21202053",
        text: "На каком расстоянии от греющих поверхностей (печей, труб, радиаторов) должны находиться столы и полки, на которых раскладываются при сушке взрывчатые вещества в помещении?",
        answers: [
          "Не менее 1,0 м",
          "Не менее 0,8 м",
          "Не менее 0,6 м",
          "Не менее 0,4 м",
        ],
        correctAnswers: ["Не менее 1,0 м"],
      },
      {
        code: "21202054",
        text: "При какой температуре воздуха в помещениях для сушки взрывчатых веществ должна осуществляться сушка дымного пороха?",
        answers: [
          "Не выше 40 °С",
          "Не выше 75 °С",
          "Не выше 55 °С",
          "Не выше 60 °С",
        ],
        correctAnswers: ["Не выше 40 °С"],
      },
      {
        code: "21202055",
        text: "Какой должна быть температура воздуха, при которой проводится оттаивание взрывчатых веществ, находящихся в заводской упаковке, в поверхностных складах в отапливаемых помещениях?",
        answers: [
          "Не выше 30 °С",
          "Не выше   35 °С",
          "Не выше 45   °С",
          "Не выше 40     °С",
        ],
        correctAnswers: ["Не выше 30 °С"],
      },
      {
        code: "21202056",
        text: "Какой должна быть высота ограждения погрузочно-разгрузочной площадки взрывчатых материалов, за исключением площадок, расположенных на территории складов взрывчатых материалов, в околоствольных дворах шахт, рудников, штолен?",
        answers: [
          "Не менее 2 м",
          "Не менее 1,8 м",
          "Не менее 1,75 м",
          "Не менее 1,5 м",
        ],
        correctAnswers: ["Не менее 2 м"],
      },
      {
        code: "21202057",
        text: "На каком расстоянии от места погрузки (выгрузки) транспортных средств, перевозящих взрывчатые материалы, должна ограждаться погрузочно-разгрузочная площадка,  за исключением площадок, расположенных на территории складов взрывчатых материалов, в околоствольных дворах шахт, рудников, штолен ?",
        answers: [
          "Не менее 15 м",
          "Не менее 12 м",
          "Не менее 9 м",
          "Не менее 7 м",
        ],
        correctAnswers: ["Не менее 15 м"],
      },
      {
        code: "21202058",
        text: "Чем должно определяться расстояние от объектов до мест взрывания и сжигания на площадках для испытаний и (или) уничтожения взрывчатых веществ?",
        answers: [
          "Проектом",
          "Приказом или распоряжением по предприятию",
          "Инструкцией, утвержденной руководителем взрывных работ",
          "Указанием территориальных органов Ростехнадзора",
        ],
        correctAnswers: ["Проектом"],
      },
      {
        code: "21202059",
        text: "Какое из перечисленных положений не относится к требованиям по хранению взрывчатых материалов, устанавливаемых Правилами безопасности при производстве, хранении и применении взрывчатых материалов промышленного назначения?",
        answers: [
          "Хранилища с взрывчатыми материалами должны запираться на замки и опломбироваться (опечатываться). В складах взрывчатых материалов с круглосуточной выдачей и приемкой взрывчатых материалов и постоянным дежурством раздатчиков опломбирование или опечатывание хранилищ может не проводиться",
          "В научных и образовательных организациях не допускается совместное (в одном сейфе) хранение вновь изготовленных взрывчатых материалов с взрывчатыми материалами, на которые имеются разрешения",
          "При прекращении работ, связанных с использованием взрывчатых материалов, на срок более 2 месяцев оставшиеся на складе взрывчатые материалы должны быть вывезены на другое место хранения взрывчатых материалов",
          "Взрывчатые материалы на складах должны размещаться на стеллажах или настилах (поддонах) в заводской упаковке или иной таре",
        ],
        correctAnswers: [
          "При прекращении работ, связанных с использованием взрывчатых материалов, на срок более 2 месяцев оставшиеся на складе взрывчатые материалы должны быть вывезены на другое место хранения взрывчатых материалов",
        ],
      },
      {
        code: "21202060",
        text: "Допускается ли хранение эмульсии для производства взрывчатых веществ на территории поверхностного пункта производства?",
        answers: [
          "Допускается временное хранение эмульсии в передвижных емкостях (смесительно-зарядных машинах) на расстоянии, безопасном по передаче детонации друг от друга",
          "Допускается без ограничений",
          "Допускается в специально оборудованных для этих целей хорошо освещенных помещениях",
          "Запрещается",
        ],
        correctAnswers: [
          "Допускается временное хранение эмульсии в передвижных емкостях (смесительно-зарядных машинах) на расстоянии, безопасном по передаче детонации друг от друга",
        ],
      },
      {
        code: "21202061",
        text: "Кем должна быть выдана наряд-накладная для отпуска взрывчатых материалов с одного места хранения на другое?",
        answers: [
          "Руководителем предприятия",
          "Бухгалтерией предприятия",
          "Заведующим складами взрывчатых материалов",
          "Раздатчиком базисных и расходных складов взрывчатых материалов",
        ],
        correctAnswers: ["Бухгалтерией предприятия"],
      },
      {
        code: "21202062",
        text: "Где должно размещаться на погрузочно-разгрузочной площадке караульное помещение с телефонной связью, за исключением площадок, расположенных на территории складов взрывчатых материалов, в околоствольных дворах шахт, рудников, штолен?",
        answers: [
          "Не далее 50 м от места погрузки (выгрузки) взрывчатых материалов",
          "Не далее 90 м от места погрузки (выгрузки) взрывчатых материалов",
          "Не далее 60 м от места погрузки (выгрузки) взрывчатых материалов",
          "Не далее 75 м от места погрузки (выгрузки) взрывчатых материалов",
        ],
        correctAnswers: [
          "Не далее 50 м от места погрузки (выгрузки) взрывчатых материалов",
        ],
      },
      {
        code: "21202063",
        text: "Какую максимальную массу взрывник может переносить при переноске в сумках взрывчатых веществ без средств инициирования?",
        answers: ["24 кг", "27 кг", "30 кг", "40 кг"],
        correctAnswers: ["24 кг"],
      },
      {
        code: "21202064",
        text: "Какую массу взрывчатых материалов допускается переносить взрывнику при одновременной доставке вручную средств инициирования и взрывчатых веществ?",
        answers: [
          "Не более 10 кг",
          "Не более 15 кг",
          "Не более 18 кг",
          "Не более 20 кг",
        ],
        correctAnswers: ["Не более 10 кг"],
      },
      {
        code: "21202065",
        text: "Какая загрузка транспортного средства допускается при совместном транспортировании в пределах опасного производственного объекта взрывчатых веществ, средств инициирования и прострелочно-взрывной аппаратуры?",
        answers: [
          "Не более 2/3 его грузоподъемности",
          "Не более 3/4 его грузоподъемности",
          "Не более 4/5 его грузоподъемности",
          "Не более его номинальной грузоподъемности",
        ],
        correctAnswers: ["Не более 2/3 его грузоподъемности"],
      },
      {
        code: "21202066",
        text: "Какими способами, в соответствии с установленными требованиями, производится уничтожение взрывчатых материалов?",
        answers: [
          "Взрыванием, сжиганием или растворением в воде",
          "Растворением в кислоте или в щелочи",
          "Растворением в бензине, измельчением с последующим распылением",
          "Измельчением с последующим распылением, биологической обработкой",
        ],
        correctAnswers: ["Взрыванием, сжиганием или растворением в воде"],
      },
      {
        code: "21202067",
        text: "Каким образом проводится одновременное сжигание взрывчатых веществ, огнепроводных и детонирующих шнуров?",
        answers: [
          "Все взрывчатые материалы сжигаются в одном костре, общая масса за один прием не должна превышать 45 кг",
          "Все взрывчатые материалы сжигаются раздельно, за один прием допускается сжигать не более 35 кг",
          "Все взрывчатые материалы сжигаются раздельно, за один прием допускается сжигать не более 20 кг",
          "Все взрывчатые материалы сжигаются в одном костре, общая масса за один прием не должна превышать 10 кг",
        ],
        correctAnswers: [
          "Все взрывчатые материалы сжигаются раздельно, за один прием допускается сжигать не более 20 кг",
        ],
      },
      {
        code: "21202068",
        text: "На каком расстоянии от места нахождения взрывчатых материалов запрещается применять открытый огонь и курить?",
        answers: ["Ближе 100 м", "Ближе 90 м", "Ближе 75 м", "Ближе 50 м"],
        correctAnswers: ["Ближе 100 м"],
      },
      {
        code: "21202069",
        text: "В каком из положений нарушены требования по приему, отпуску и учету взрывчатых материалов?",
        answers: [
          "Доставленные на места хранения взрывчатые материалы должны быть немедленно переданы на места проведения взрывных работ и выданы взрывникам",
          "Доставленные на места хранения взрывчатые материалы должны быть помещены в хранилища, на площадки и оприходованы",
          "Индивидуальные заводские номера изделий со взрывчатыми веществами при выдаче взрывникам должны регистрироваться в Книге учета выдачи и возврата взрывчатых материалов",
          "Электродетонаторам и капсюлям-детонаторам для проведения огневого взрывания перед выдачей взрывникам должны быть присвоены и нанесены индивидуальные индексы",
        ],
        correctAnswers: [
          "Доставленные на места хранения взрывчатые материалы должны быть немедленно переданы на места проведения взрывных работ и выданы взрывникам",
        ],
      },
      {
        code: "21202070",
        text: "Кто должен вести книгу учета прихода и расхода взрывчатых материалов?",
        answers: [
          "Руководитель взрывных работ",
          "Начальники участков, на которых проводятся взрывные работы",
          "Заведующие и раздатчики складов взрывчатых материалов",
          "Работники складов взрывчатых материалов",
        ],
        correctAnswers: [
          "Заведующие и раздатчики складов взрывчатых материалов",
        ],
      },
      {
        code: "21202071",
        text: "Каким образом осуществляется передача взрывчатых материалов с одного склада на другой, принадлежащий одной и той же организации?",
        answers: [
          "Оформлением наряда-накладной в 3 экземплярах",
          "Оформлением наряда-путевки в 4 экземплярах",
          "По распоряжению руководителя горными и взрывными работами",
          "По распоряжению заведующего складом взрывчатых материалов",
          "Передача взрывчатых материалов с одного склада на другой, принадлежащий одной и той же организации запрещается",
        ],
        correctAnswers: ["Оформлением наряда-накладной в 3 экземплярах"],
      },
      {
        code: "21202072",
        text: "С какой периодичностью должна проводиться проверка правильности учета, хранения и наличия взрывчатых материалов на складах лицами, назначенными распорядительным документом организации?",
        answers: ["Ежемесячно", "Ежеквартально", "Ежедекадно", "Еженедельно"],
        correctAnswers: ["Ежемесячно"],
      },
      {
        code: "21202073",
        text: 'Что из перечисленного является определением понятия "средства инициирования" согласно техническому регламенту Таможенного союза от 20.07.2012 N 028/2012 "О безопасности взрывчатых веществ и изделий на их основе"?',
        answers: [
          "Изделия, содержащие взрывчатое вещество и предназначенные для возбуждения или передачи и возбуждения детонации",
          "Компактная масса взрывчатого вещества конечных размеров, заключенная в оболочку или без нее, предназначенная для использования в изготовленном виде самостоятельно или в сочетании с другими взрывчатыми веществами",
          "Высокочувствительное взрывчатое вещество, легко детонирующее от простейших начальных импульсов (удар, трение, нагрев, искровой разряд), предназначенное для возбуждения детонации или воспламенения других взрывчатых веществ",
          "Средство или комплекс средств, предназначенных для защиты взрывчатых веществ и изделий на их основе от повреждений и исключения воздействия атмосферных явлений",
        ],
        correctAnswers: [
          "Изделия, содержащие взрывчатое вещество и предназначенные для возбуждения или передачи и возбуждения детонации",
        ],
      },
      {
        code: "21202074",
        text: "К какой группе совместимости взрывчатых веществ и изделий на их основе относятся изделия, содержащие инициирующие взрывчатые вещества и имеющие менее двух независимых предохранительных устройств?",
        answers: ["К группе А", "К группе В", "К группе С", "К группе D"],
        correctAnswers: ["К группе В"],
      },
      {
        code: "21202075",
        text: "В каком случае допускается применять и хранить взрывчатые вещества и изделия на их основе с истекшим гарантийным сроком хранения?",
        answers: [
          "Допускается при хранении в полуподземных хранилищах",
          "Допускается при снижении количества хранящихся взрывчатых веществ в 3 раза от рекомендованного",
          "Допускается в случае проведения испытаний, предусмотренных технической документацией",
          "Не допускается ни в каком случае",
        ],
        correctAnswers: [
          "Допускается в случае проведения испытаний, предусмотренных технической документацией",
        ],
      },
      {
        code: "21202076",
        text: "Как должно быть отмечено специально выделенное место для временного хранения на складах пришедших в негодность и бракованных взрывчатых веществ и изделий на их основе?",
        answers: [
          "Металлическим ограждением",
          'Предупредительной надписью "ВНИМАНИЕ БРАК"',
          "Предупредительными огнями в виде светильников красного цвета",
          "Временное хранение таких веществ и изделий не допускается",
        ],
        correctAnswers: ['Предупредительной надписью "ВНИМАНИЕ БРАК"'],
      },
      {
        code: "21202077",
        text: "Какой цвет отличительной полосы или оболочек патронов (пачек) должны иметь предохранительные взрывчатые вещества для взрывания только по породе в забоях подземных выработок, в которых имеется выделение горючих газов, но отсутствует взрывчатая угольная (сланцевая) пыль?",
        answers: ["Белый", "Желтый", "Красный", "Синий"],
        correctAnswers: ["Синий"],
      },
      {
        code: "21202078",
        text: "Для какой из перечисленных областей применения взрывчатых веществ минимальная масса партии взрывчатых веществ, необходимая для проведения приемочных испытаний, составляет 5 тонн? Укажите все правильные ответы.",
        answers: [
          "Патронированные взрывчатые вещества, шашки и другие штучные взрывчатые вещества, предназначенные для изготовления боевиков на открытых и подземных работах",
          "Взрывчатые вещества для подземных работ при механизированном заряжании шпуров и скважин",
          "Взрывчатые вещества для подземных работ при ручном заряжании шпуров и скважин",
          "Предохранительные взрывчатые вещества",
          "Взрывчатые вещества, предназначенные для открытых работ",
        ],
        correctAnswers: [
          "Патронированные взрывчатые вещества, шашки и другие штучные взрывчатые вещества, предназначенные для изготовления боевиков на открытых и подземных работах",
          "Взрывчатые вещества для подземных работ при механизированном заряжании шпуров и скважин",
        ],
      },
      {
        code: "21202079",
        text: "В каких количествах разрешается размещать взрывчатые вещества непосредственно у зарядного оборудования?",
        answers: [
          "Не более сменной производительности оборудования",
          "Не более суточной производительности оборудования",
          "Не более  недельной производительности оборудования",
          "Размещать взрывчатые вещества у зарядного оборудования запрещается",
        ],
        correctAnswers: ["Не более сменной производительности оборудования"],
      },
      {
        code: "21202080",
        text: "Что должна включать маркировка упаковки взрывчатых веществ и изделий на их основе, а также изделий на основе взрывчатых веществ?",
        answers: [
          "Все перечисленное",
          "Наименование (условное обозначение) взрывчатого вещества или изделия",
          "Обозначение соответствия транспортной тары по механической прочности",
          "Информацию о подтверждении соответствия продукции требованиям технического регламента",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21202081",
        text: "На какие из перечисленных веществ оформляется руководство (инструкция) по применению?",
        answers: [
          "На взрывчатые вещества и изделия на их основе, разрабатываемые (проектируемые) и изготавливаемые для использования энергии взрыва в промышленных целях",
          "На  взрывчатые вещества, непосредственно не применяемые для использования энергии взрыва в промышленных целях",
          "На эмульсии и матрицы окислителя на основе нитрата аммония, разрабатываемые (проектируемые) и изготавливаемые для получения водоэмульсионных и водногелевых взрывчатых веществ",
          "На все перечисленные вещества",
        ],
        correctAnswers: [
          "На взрывчатые вещества и изделия на их основе, разрабатываемые (проектируемые) и изготавливаемые для использования энергии взрыва в промышленных целях",
        ],
      },
      {
        code: "21202082",
        text: "Какое из перечисленных испытаний взрывчатых веществ и изделий на их основе не проводится в целях определения безопасности при их хранении и применении в соответствии с показателями технической документации?",
        answers: [
          "После истечения гарантийного срока хранения",
          "При поступлении от изготовителя",
          "При возникновении сомнений в доброкачественности",
          "При возникновении сомнений в доброкачественности (по внешнему осмотру)",
        ],
        correctAnswers: ["После истечения гарантийного срока хранения"],
      },
      {
        code: "21202083",
        text: "Какую перечисленную информацию должно содержать руководство (инструкция) по применению взрывчатых веществ?",
        answers: [
          "Всю перечисленную информацию",
          "Показатели пожаровзрывоопасности и электростатической опасности",
          "Применение механизированных операций на складах и на месте применения с указанием способа механизации",
          "Порядок действия персонала при аварийных ситуациях",
        ],
        correctAnswers: ["Всю перечисленную информацию"],
      },
      {
        code: "21202084",
        text: "Какие взрывчатые вещества не допускаются для применения по результатам испытаний?",
        answers: [
          "При испытаниях на чувствительность к удару нижний предел составляет менее 100 мм, а при испытаниях на чувствительность к трению нижний предел составляет менее 200 МПа",
          "Те, у которых нижний предел чувствительности к удару составляет менее 125 мм, а нижний предел чувствительности к трению менее 225 МПа",
          "Те, у которых нижний предел чувствительности к удару составляет менее 275 мм, а нижний предел чувствительности к трению менее 375МПа",
          "Те, у которых нижний предел чувствительности к удару составляет менее 200 мм, а нижний предел чувствительности к трению менее 300 МПа",
        ],
        correctAnswers: [
          "При испытаниях на чувствительность к удару нижний предел составляет менее 100 мм, а при испытаниях на чувствительность к трению нижний предел составляет менее 200 МПа",
        ],
      },
      {
        code: "21202085",
        text: "Какие из перечисленных характеристик электродетонаторов взрывчатых веществ указаны верно?",
        answers: [
          "Длительный воспламеняющий ток не менее 0,18 А",
          "Все ответы неверны",
          "Значение безопасного тока не менее 0,22 А",
          "Безопасный импульс воспламенения не менее 0,4 А2 ⋅ мс",
          "Все перечисленные характеристики",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21202086",
        text: "Какие действия необходимо осуществить в отношении взрывчатых веществ и изделий на их основе при несоответствии показателей, полученных в результате испытаний, показателям, указанным в технической документации?",
        answers: [
          "Должны быть уничтожены в минимально возможные сроки",
          "Возвращаются изготовителю",
          "Подвергаются повторным испытаниям",
          "Должны быть использованы в кратчайшие сроки",
        ],
        correctAnswers: ["Должны быть уничтожены в минимально возможные сроки"],
      },
      {
        code: "21202087",
        text: "Какие из перечисленных веществ относятся к группе совместимости взрывчатых веществ и изделий на их основе D?",
        answers: [
          "Взрывчатые вещества и изделия на их основе без средств инициирования и метательных зарядов",
          "Метательные взрывчатые вещества и изделия (бездымный порох)",
          "Изделия, содержащие взрывчатые вещества без средств инициирования, но с метательным зарядом",
          "Взрывчатые вещества или изделия, упакованные или сконструированные так, что при случайном срабатывании любое опасное проявление ограничено самой упаковкой, а если тара разрушена огнем, то эффект взрыва или разбрасывания ограничен, что не препятствует проведению аварийных мер или тушению пожара в непосредственной близости от упаковки",
        ],
        correctAnswers: [
          "Взрывчатые вещества и изделия на их основе без средств инициирования и метательных зарядов",
        ],
      },
      {
        code: "21202088",
        text: "Какой способ ликвидации отказавших скважинных зарядов указан неверно?",
        answers: [
          "Вымывание заряда из скважины при взрывании дымного пороха с применением детонирующего шнура",
          "Взрывание заряда в скважине, пробуренной параллельно на расстоянии не менее 3 м от скважины с отказавшим зарядом",
          "По специально разработанному проекту, утвержденному руководителем (техническим руководителем) организации",
          "Все перечисленные способы",
        ],
        correctAnswers: [
          "Вымывание заряда из скважины при взрывании дымного пороха с применением детонирующего шнура",
        ],
      },
      {
        code: "21202089",
        text: "Какое из перечисленных требований к уничтожению взрывчатых материалов указано верно?",
        answers: [
          "Запрещается подход к месту сжигания до полного прекращения горения костра с взрывчатыми материалами",
          "Взрывчатые материалы допускается сжигать в их таре",
          "Допускается сжигать взрывчатые материалы с находящимися в них средствами инициирования",
          "Все перечисленнве требования",
        ],
        correctAnswers: [
          "Запрещается подход к месту сжигания до полного прекращения горения костра с взрывчатыми материалами",
        ],
      },
      {
        code: "21202090",
        text: "Для каких видов взрывных работ применяются предохранительные взрывчатые вещества VI класса?",
        answers: [
          "Для верхней и смешанной подрывки пород с f = 4 и менее в вентиляционных штреках, проводимых вслед за лавой",
          "В забоях выработок, проводимых только по породе, в том числе и по выбросоопасным породам, при выделении метана и отсутствии взрывчатой пыли",
          "В забоях стволов, проводимых только по породе, при их углубке с действующих горизонтов и выделении в них метана",
          "При вскрытии пластов, опасных по внезапным выбросам угля и газа, до обнажения пласта при условии применения водораспылительных завес и наличии между пластом и забоем выработки породной пробки по всей площади сечения выработк",
        ],
        correctAnswers: [
          "Для верхней и смешанной подрывки пород с f = 4 и менее в вентиляционных штреках, проводимых вслед за лавой",
        ],
      },
      {
        code: "21202091",
        text: "Какие из перечисленных взрывчатых веществ и изделий относятся к группе совместимости (опасности) С?",
        answers: [
          "Метательные взрывчатые вещества и изделия (бездымный порох)",
          "Взрывчатые вещества и изделия на их основе без средств инициирования и метательных зарядов; изделия, содержащие инициирующие взрывчатые вещества и имеющие два или более независимых предохранительных устройства",
          "Изделия, содержащие взрывчатые вещества без средств инициирования, но с метательным зарядом (кроме содержащих легковоспламеняющуюся жидкость или гель или самовоспламеняющуюся жидкость",
          "Изделия, содержащие взрывчатые вещества чрезвычайно низкой чувствительности",
        ],
        correctAnswers: [
          "Метательные взрывчатые вещества и изделия (бездымный порох)",
        ],
      },
      {
        code: "21202092",
        text: "Куда передается акт об уничтожении взрывчатых материалов?",
        answers: [
          "На склад взрывчатых материалов",
          "Руководителю организации",
          "Руководителю взрывных работ",
          "В территориальный орган Ростехнадзора",
        ],
        correctAnswers: ["На склад взрывчатых материалов"],
      },
      {
        code: "21202093",
        text: "Какими лицами переносятся средства инициирования?",
        answers: [
          "Взрывниками",
          "Только руководителем взрывных работ",
          "Только проинструктированными рабочими под наблюдением взрывника",
          "Всеми перечисленными лицами",
        ],
        correctAnswers: ["Взрывниками"],
      },
      {
        code: "21202094",
        text: "С какой периодичностью должна проверяться техническая исправность транспортных средств, используемых для доставки взрывчатых материалов, лицом, назначенным распорядительным документом организации?",
        answers: ["Ежесменно", "Еженедельно", "Ежемесячно", "Ежедекадно"],
        correctAnswers: ["Ежесменно"],
      },
      {
        code: "21202095",
        text: "Каким документом должны определяться место погрузки-выгрузки, меры безопасности, а также порядок погрузки-разгрузки взрывчатых материалов в околоствольных дворах шахт, рудников, штолен и надшахтных зданиях?",
        answers: [
          "Распорядительным документом организации (шахты, рудника)",
          "Проектом",
          "Приказом Ростехнадзора",
          "Нарядом-путевкой",
        ],
        correctAnswers: [
          "Распорядительным документом организации (шахты, рудника)",
        ],
      },
      {
        code: "21202096",
        text: "Кто устанавливает маршруты транспортирования взрывчатых материалов от склада ВМ на места работ (в пределах опасного производственного объекта)?",
        answers: [
          "Руководитель (технический руководитель) организации, эксплуатирующей опасный производственный объект",
          "Территориальный орган Ростехнадзора",
          "Руководитель взрывных работ",
          "Орган местного самоуправления, на территории которого располагается опасный производственный объект",
        ],
        correctAnswers: [
          "Руководитель (технический руководитель) организации, эксплуатирующей опасный производственный объект",
        ],
      },
      {
        code: "21202097",
        text: "Присутствие каких лиц допускается при погрузке, разгрузке, перемещении взрывчатых материалов по стволу шахты в околоствольном дворе и надшахтном здании около ствола?",
        answers: [
          "Только стволового и лица, ответственного за доставку взрывчатых материалов",
          "Только рабочих, нагружающих и разгружающих взрывчатые материалы",
          "Только рукоятчика, взрывника, раздатчика",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "21202098",
        text: "Каким должно быть расстояние между вагонетками со взрывчатыми веществами и средствами инициирования, разделенными порожними вагонетками, а также между вагонетками с взрывчатыми веществами и средствами инициирования, и локомотивом при их перевозке в одном железнодорожном составе?",
        answers: [
          "Не менее 3 м",
          "Не менее 2 м",
          "Не менее 1 м",
          "Не менее 5 м",
        ],
        correctAnswers: ["Не менее 3 м"],
      },
      {
        code: "21202099",
        text: "В каком из перечисленных случаев взрывчатые материалы не должны подвергаться испытаниям?",
        answers: [
          "При поступлении на склад взрывчатых материалов",
          "При возникновении сомнений в их качестве",
          "За месяц до истечения гарантийного срока",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: ["При поступлении на склад взрывчатых материалов"],
      },
      {
        code: "21202100",
        text: "Какое количество электродетонаторов может находиться на рабочем столе проверяющего при их проверке по внешнему виду и электрическому сопротивлению?",
        answers: [
          "Не более 100",
          "Не более 110",
          "Не более 125",
          "Не более 150",
        ],
        correctAnswers: ["Не более 100"],
      },
      {
        code: "21202101",
        text: "Что из перечисленного запрещается Правилами безопасности при производстве, хранении и применении взрывчатых материалов промышленного назначения во время монтажа электровзрывной сети?",
        answers: [
          "Электровзрывная сеть должна быть двухпроводной",
          "Электровзрывную сеть монтируют в направлении от источника тока или включающего ток устройства к заряду",
          "В шахтах (рудниках), опасных по газу или пыли, должны применяться электродетонаторы только с медными проводами",
          "Электровзрывную сеть монтируют в направлении от заряда к источнику тока",
        ],
        correctAnswers: [
          "Электровзрывную сеть монтируют в направлении от источника тока или включающего ток устройства к заряду",
        ],
      },
      {
        code: "21202102",
        text: "При какой величине расхождения измеренного и расчетного сопротивлений перед взрыванием скважинных и камерных зарядов необходимо устранить неисправности, вызывающие отклонения от расчетного сопротивления электровзрывной сети?",
        answers: [
          "Более чем на 10 %",
          "Более чем на 9 %",
          "Более чем на 8 %",
          "Более чем на 7 %",
        ],
        correctAnswers: ["Более чем на 10 %"],
      },
      {
        code: "21202103",
        text: "Каким из перечисленных способов должно осуществляться проведение электрического взрывания?",
        answers: [
          "От силовой сети",
          "От осветительной сети",
          "С применением взрывных приборов (машинок)",
          "От аккумуляторов",
        ],
        correctAnswers: ["С применением взрывных приборов (машинок)"],
      },
      {
        code: "21202104",
        text: "Какими должны быть действия взрывника, если при подаче напряжения взрыва не произошло?",
        answers: [
          "Отсоединить от источника тока электровзрывную сеть, замкнуть накоротко ее концы, взять с собой ключ от взрывного прибора и только после этого выяснить причину отказа",
          "Отсоединить от источника тока электровзрывную сеть, взять с собой ключ от взрывного прибора и только после этого выяснить причину отказа",
          "Отсоединить от источника тока электровзрывную сеть и проверить ее электрическое сопротивление, при обнаружении неисправности принять меры к ее устранению",
          "Периодически (не менее трех раз) подать напряжение в электровзрывную сеть, при отсутствии взрыва отключить взрывной прибор, взять с собой ключ от взрывного прибора и только после этого выяснить причину отказа",
        ],
        correctAnswers: [
          "Отсоединить от источника тока электровзрывную сеть, замкнуть накоротко ее концы, взять с собой ключ от взрывного прибора и только после этого выяснить причину отказа",
        ],
      },
      {
        code: "21202105",
        text: "Как часто должны проверяться взрывные приборы на соответствие установленным техническим характеристикам?",
        answers: [
          "Перед каждой выдачей взрывникам",
          "Не реже одного раза в неделю",
          "Не реже одного раза в декаду",
          "Не реже одного раза в квартал",
        ],
        correctAnswers: ["Перед каждой выдачей взрывникам"],
      },
      {
        code: "21202106",
        text: "Когда взрывник может подойти к месту взрыва при ведении счета взорвавшихся зарядов и отсутствии отказов?",
        answers: [
          "Через 2 минуты после последнего взрыва",
          "Через 3 минуты после последнего взрыва",
          "Через 4 минуты после последнего взрыва",
          "Через 5 минут после последнего взрыва",
        ],
        correctAnswers: ["Через 5 минут после последнего взрыва"],
      },
      {
        code: "21202107",
        text: "Через какое время разрешается подходить к месту взрыва при ведении счета взорвавшихся зарядов в случае, если какой-либо заряд не взорвался или вести счет взорвавшихся зарядов невозможно?",
        answers: [
          "Не ранее чем через 15 минут после последнего взрыва",
          "Не ранее чем через 12 минут после последнего взрыва",
          "Не ранее чем через 10 минут после последнего взрыва",
          "Не ранее чем через 5 минут после последнего взрыва",
        ],
        correctAnswers: ["Не ранее чем через 15 минут после последнего взрыва"],
      },
      {
        code: "21202108",
        text: "Как поступают с неиспользованными боевиками после взрывания зарядов?",
        answers: [
          "Боевики передаются на другие объекты взрывания",
          "Боевики подлежат уничтожению взрыванием в установленном порядке",
          "Боевики необходимо разрядить",
          "Боевики подлежат хранению в бронированных ящиках для последующего использования",
        ],
        correctAnswers: [
          "Боевики подлежат уничтожению взрыванием в установленном порядке",
        ],
      },
      {
        code: "21202109",
        text: "Как должно производиться удаление каких-либо частиц на внутренней поверхности гильзы капсюля-детонатора перед изготовлением зажигательной (контрольной) трубки?",
        answers: [
          "Легким постукиванием открытым дульцем капсюля-детонатора о ноготь пальца",
          "Выдуванием",
          "С помощью пинцета",
          "С помощью деревянной палочки",
        ],
        correctAnswers: [
          "Легким постукиванием открытым дульцем капсюля-детонатора о ноготь пальца",
        ],
      },
      {
        code: "21202110",
        text: "Какие электродетонаторы разрешается применять в бутовых штреках с подрывкой кровли?",
        answers: [
          "Электродетонаторы мгновенного действия",
          "Электродетонаторы короткозамедленного действия",
          "Электродетонаторы замедленного действия",
          "Электродетонаторы в бутовых штреках с подрывкой кровли применять запрещается",
        ],
        correctAnswers: ["Электродетонаторы мгновенного действия"],
      },
      {
        code: "21202111",
        text: "Каким образом должен упаковываться заряд при температуре более 80 °C в шпуре (скважине, рукаве) при ведении взрывных работ по металлу?",
        answers: [
          "В термоизолирующую оболочку",
          "В пергаментную или оберточную бумагу",
          "В крафтцеллюлозную бумагу",
          "Заряд не должен упаковываться",
        ],
        correctAnswers: ["В термоизолирующую оболочку"],
      },
      {
        code: "21202112",
        text: "Какие требования, предъявляемые к характеристикам электродетонаторов указаны неверно?",
        answers: [
          "Электродетонаторы не должны возбуждать детонацию боковой поверхностью контактирующих с ней взрывчатых веществ и других средств инициирования",
          "Значение безопасного тока не менее 0,18 А",
          "Безопасный импульс воспламенения не менее 0,6 А² х мс",
          "Длительный воспламеняющий ток не менее 0,22 А",
        ],
        correctAnswers: [
          "Электродетонаторы не должны возбуждать детонацию боковой поверхностью контактирующих с ней взрывчатых веществ и других средств инициирования",
        ],
      },
      {
        code: "21202113",
        text: "Какое из перечисленных требований при применении средств инициирования запрещается?",
        answers: [
          "Расширять гнезда патронов (шашек) заводского изготовления, из которых изготовлены боевики из прессованных или литых взрывчатых веществ с инициированием от детонаторов",
          "При изготовлении промежуточных детонаторов из порошкообразных патронированных взрывчатых веществ с применением детонирующего шнура конец детонирующего шнура в патроне складывать вдвое",
          "Обматывать детонирующий шнур вокруг патрона взрывчатых веществ",
          "При изготовлении промежуточных детонаторов из порошкообразных патронированных взрывчатых веществ с применением детонирующего шнура конец детонирующего шнура в патроне завязывать узлом",
          "Все перечисленные требования",
        ],
        correctAnswers: [
          "Расширять гнезда патронов (шашек) заводского изготовления, из которых изготовлены боевики из прессованных или литых взрывчатых веществ с инициированием от детонаторов",
        ],
      },
      {
        code: "21202114",
        text: "Какое количество зарядов разрешается одновременно заряжать и взрывать при температуре в шпуре ниже 80 °C при ведении взрывных работ по металлу?",
        answers: [
          "Не более 5 зарядов",
          "Не более 10 зарядов",
          "Не более 1 заряда",
          "Не более 12 зарядов",
        ],
        correctAnswers: ["Не более 5 зарядов"],
      },
      {
        code: "21202115",
        text: "Через какое время разрешается подход взрывника к месту взрыва, если взрыва не произошло, при взрывании электронными детонаторами, электродетонаторами и капсюлями-детонаторами?",
        answers: [
          "Не ранее чем через 15 минут",
          "Не ранее чем через 5 минут",
          "Не ранее чем через 7 минут",
          "Не ранее чем через 10 минут",
        ],
        correctAnswers: ["Не ранее чем через 15 минут"],
      },
      {
        code: "21202116",
        text: "При каком условии разрешается выход взрывника из укрытия после взрыва при взрывании с применением электродетонаторов?",
        answers: [
          "При выполнении всех перечисленных условий",
          "После отсоединения электровзрывной сети от источника тока и замыкания ее накоротко",
          "После проветривания",
          "Не ранее чем через 5 мин",
        ],
        correctAnswers: ["При выполнении всех перечисленных условий"],
      },
      {
        code: "21202117",
        text: "Какой из перечисленных индексов, наносимый на гильзы электродетонаторов и капсюлей-детонаторов, обозначает номер взрывника?",
        answers: [
          "Два буквенных индекса справа от цифровых",
          "Два цифровых индекса",
          "Два буквенных индекса слева от цифровых",
          "Второй буквенный индекс слева от цифровых",
        ],
        correctAnswers: ["Два буквенных индекса справа от цифровых"],
      },
      {
        code: "21202118",
        text: "Какое из перечисленных требований к порядку присвоения и нанесения индивидуальных индексов электродетонаторов и капсюлей-детонаторов указано верно?",
        answers: [
          "Работы по нанесению индивидуальных индексов средств инициирования необходимо проводить в помещении, отдельном от мест хранения взрывчатых материалов",
          "Нанесение индивидуальных индексов на электродетонаторы с помощью устройств обжимного типа должно производиться на 4,0 мм ниже нижнего зига заводской обжимки гильзы",
          "В случае появления трещин во внешней оболочке изделий они должны быть отремонтированы",
          "Все перечисленные",
        ],
        correctAnswers: [
          "Работы по нанесению индивидуальных индексов средств инициирования необходимо проводить в помещении, отдельном от мест хранения взрывчатых материалов",
        ],
      },
      {
        code: "21202119",
        text: "На какое минимальное расстояние от скважины должны быть убраны буровые установки, не имеющие приспособления для заряжания, в сложных горно-геологических условиях?",
        answers: ["10 м", " 8 м", " 5 м", " 3 м"],
        correctAnswers: ["10 м"],
      },
      {
        code: "21202120",
        text: "Что входит в зону монтажа электровзрывной сети на земной поверхности?",
        answers: [
          "Поверхность, ограниченная контуром, на 50 м превышающим контур электровзрывной сети",
          "Поверхность, ограниченная контуром, на 150 м превышающим контур электровзрывной сети",
          "Поверхность, ограниченная контуром, на 75 м превышающим контур электровзрывной сети",
          "Зона монтажа не устанавливается",
        ],
        correctAnswers: [
          "Поверхность, ограниченная контуром, на 50 м превышающим контур электровзрывной сети",
        ],
      },
      {
        code: "21202121",
        text: "Что из перечисленного запрещается Правилами безопасности при производстве, хранении и применении взрывчатых материалов промышленного назначения во время монтажа электровзрывной сети?",
        answers: [
          "Электровзрывная сеть должна быть двухпроводной",
          "Электровзрывная сеть монтируется в направлении от источника тока или включающего ток устройства к заряду",
          "В шахтах (рудниках), опасных по газу или пыли, должны применяться электродетонаторы только с медными проводами",
          "Электровзрывная сеть монтируется в направлении от заряда к источнику тока",
        ],
        correctAnswers: [
          "Электровзрывная сеть монтируется в направлении от источника тока или включающего ток устройства к заряду",
        ],
      },
      {
        code: "21202122",
        text: "Какой вид взрывных работ следует относить к массовым взрывам зарядов взрывчатых веществ на земной поверхности?",
        answers: [
          "Взрывание единичных зарядов в выработках протяженностью более 7 м",
          "Взрывание смонтированных в общую взрывную сеть двух и более скважинных, котловых или камерных зарядов, независимо от протяженности заряжаемой выработки, а также единичных зарядов в выработках протяженностью более 10 м",
          "Взрывы, при осуществлении которых требуется большее время для проветривания и возобновления работ в руднике (шахте, участке), чем это предусмотрено в расчете при повседневной организации работ",
          "Специальные взрывы по обрушению потолочин камер, междуэтажных целиков на всю высоту этажа",
        ],
        correctAnswers: [
          "Взрывание смонтированных в общую взрывную сеть двух и более скважинных, котловых или камерных зарядов, независимо от протяженности заряжаемой выработки, а также единичных зарядов в выработках протяженностью более 10 м",
        ],
      },
      {
        code: "21202123",
        text: "Какие мероприятия необходимо проводить при производстве взрывных работ?",
        answers: [
          "Только мероприятия по обеспечению безопасности персонала",
          "Только мероприятия по предупреждению отравлений людей пылью взрывчатых веществ и ядовитыми продуктами взрывов",
          "Только мероприятия по предупреждению загораний и взрывов сульфидной пыли",
          "Все перечисленные мероприятия",
        ],
        correctAnswers: ["Все перечисленные мероприятия"],
      },
      {
        code: "21202124",
        text: "Что является основанием для проведения взрывных работ в местах, отвечающих требованиям правил и инструкций по безопасности взрывных работ?",
        answers: [
          "Распоряжение лица технического надзора",
          "Запись в сменном журнале",
          "Указание технического руководителя организации",
          "Письменный наряд (задание на выполнение работ) с ознакомлением с ним под подпись и соответствующие наряд-путевки",
        ],
        correctAnswers: [
          "Письменный наряд (задание на выполнение работ) с ознакомлением с ним под подпись и соответствующие наряд-путевки",
        ],
      },
      {
        code: "21202125",
        text: "Какой документ является базовым для разработки паспортов и проектов буровзрывных (взрывных) работ, в том числе и проектов массовых взрывов, за исключением специальных и экспериментальных массовых взрывов в подземных выработках, выполняемых в конкретных условиях?",
        answers: [
          "Лицензия на проведение взрывных работ",
          "Согласованная с Ростехнадзором программа проведения взрывных работ",
          "Руководство по проведению буровзрывных работ ",
          "Типовой проект буровзрывных (взрывных) работ",
        ],
        correctAnswers: ["Типовой проект буровзрывных (взрывных) работ"],
      },
      {
        code: "21202126",
        text: "Кто утверждает типовой проект буровзрывных (взрывных) работ?",
        answers: [
          "Представитель военизированной горноспасательной части",
          "Руководитель (технический руководитель) организации, ведущей взрывные работы, или лицо, его замещающее",
          "Руководитель территориального органа Ростехнадзора",
          "Представитель технического надзора",
        ],
        correctAnswers: [
          "Руководитель (технический руководитель) организации, ведущей взрывные работы, или лицо, его замещающее",
        ],
      },
      {
        code: "21202127",
        text: "При выполнении каких взрывных работ на объектах взрывание зарядов должно проводиться по утвержденным проектам (техническим проектам)?",
        answers: [
          "При проведении разовых взрывов зарядов в целях ликвидации отказов",
          "При проведении дноуглубительных и ледоходных работ",
          "При проведении разовых взрывов зарядов для выравнивания почвы выработки",
          "При проведении разовых взрывов зарядов в шпурах для доведения контура выработки до размеров, предусмотренных проектом",
        ],
        correctAnswers: ["При проведении дноуглубительных и ледоходных работ"],
      },
      {
        code: "21202128",
        text: "Каким образом при попадании объектов другой организации в опасную зону ее руководитель должен быть оповещен о месте и времени производства взрывных работ?",
        answers: [
          "Письменно не менее чем за сутки",
          "Устным или письменным сообщением, не менее чем за 20 часов",
          "Только посредством телефонной связи, не менее чем за 20 часов",
          "Любым возможным способом, обеспечивающим доведение информации о производстве взрывных работ до руководителя другой организации, но не менее чем за 12 часов",
        ],
        correctAnswers: ["Письменно не менее чем за сутки"],
      },
      {
        code: "21202129",
        text: "Что из перечисленного входит в перечень данных, которые должен включать в себя паспорт взрывных работ?",
        answers: [
          "Схема расположения шпуров или наружных зарядов,         наименования взрывчатых материалов, данные о способе заряжания, числе шпуров, их глубине и диаметре, массе и конструкции зарядов и боевиков",
          "Состав бригады взрывников, требования к их квалификации",
          "Перечень средств индивидуальной защиты работников при выполнении взрывных работ",
          "Перечень устройств и техники, необходимых для производства взрывных работ",
        ],
        correctAnswers: [
          "Схема расположения шпуров или наружных зарядов,         наименования взрывчатых материалов, данные о способе заряжания, числе шпуров, их глубине и диаметре, массе и конструкции зарядов и боевиков",
        ],
      },
      {
        code: "21202130",
        text: "В каком из перечисленных случаев взрывные работы разрешается проводить по схемам?",
        answers: [
          "Во всех перечисленных случаях",
          "Только при проведении разовых взрывов зарядов в шпурах для удаления навесей, выравнивания забоя",
          "Только при проведении разовых взрывов зарядов для выравнивания почвы выработки",
          "Только при проведении разовых взрывов зарядов в целях ликвидации отказов",
        ],
        correctAnswers: ["Во всех перечисленных случаях"],
      },
      {
        code: "21202131",
        text: "Что из перечисленного включается в схему проведения взрывных работ?",
        answers: [
          "Список устройств для контроля выполнения работ",
          "Расположение шпуров, масса и конструкция зарядов, места расположения постов и укрытия взрывника",
          "Количественный состав бригады взрывников",
          "Требования к квалификации взрывников",
        ],
        correctAnswers: [
          "Расположение шпуров, масса и конструкция зарядов, места расположения постов и укрытия взрывника",
        ],
      },
      {
        code: "21202132",
        text: "Каким образом должна осуществляться охрана запретной зоны, расположенной на земной поверхности?",
        answers: [
          "Перед началом заряжания на границах запретной (опасной) зоны должны быть выставлены посты",
          "Перед началом проведения взрывных работ на границах запретной (опасной) зоны должны быть установлены предупреждающие плакаты   с надписями, запрещающими вход в опасную зону",
          "Перед началом заряжания на границах запретной (опасной) зоны должны быть установлены ограждения, освещаемые в ночное время",
        ],
        correctAnswers: [
          "Перед началом заряжания на границах запретной (опасной) зоны должны быть выставлены посты",
        ],
      },
      {
        code: "21202133",
        text: "Какой величины должна быть запретная зона на открытых горных работах?",
        answers: [
          "Не менее 20 м от ближайшего заряда",
          "Не менее 18 м от ближайшего заряда",
          "Не менее 16 м от ближайшего заряда",
          "Не менее 10 м от ближайшего заряда",
        ],
        correctAnswers: ["Не менее 20 м от ближайшего заряда"],
      },
      {
        code: "21202134",
        text: "При выполнении какого из перечисленных условий ответственный руководитель взрыва дает указание о подаче боевого сигнала?",
        answers: [
          "Только при получении донесений взрывников, ответственных за заряжание и подготовку к взрыву блоков, за охрану опасной зоны и выставление постов, а также за вывод людей с территории опасной зоны",
          "Только при условии ознакомления с заполненной таблицей параметров взрывных работ",
          "Только при условии выполнения мероприятий, перечисленных в распорядке проведения массового взрыва",
          "При выполнении всех перечисленных условий",
        ],
        correctAnswers: ["При выполнении всех перечисленных условий"],
      },
      {
        code: "21202135",
        text: "Какой сигнал подается при вводе опасной зоны?",
        answers: [
          "Один продолжительный",
          "Два коротких и один продолжительный",
          "Три продолжительных",
          "Три коротких и один продолжительный",
        ],
        correctAnswers: ["Один продолжительный"],
      },
      {
        code: "21202136",
        text: "Какой сигнал подается по окончании взрывных работ?",
        answers: [
          "Один продолжительный",
          "Два коротких",
          "Два продолжительных",
          "Три коротких",
        ],
        correctAnswers: ["Три коротких"],
      },
      {
        code: "21202137",
        text: "Кому разрешено осуществлять допуск людей к месту взрыва после его проведения?",
        answers: [
          "Лицу, осуществляющему непосредственное руководство взрывными работами в данной смене, или по его поручению взрывником",
          "Специалисту военизированной горноспасательной части",
          "Руководителю подразделения организации, в котором производились взрывные работы",
          "Только мастеру-взрывнику",
        ],
        correctAnswers: [
          "Лицу, осуществляющему непосредственное руководство взрывными работами в данной смене, или по его поручению взрывником",
        ],
      },
      {
        code: "21202138",
        text: "По истечении какого времени после взрыва на земной поверхности ответственный руководитель массового взрыва организует осмотр взорванных блоков с принятием мер, предотвращающих отравление газами проверяющих работников?",
        answers: [
          "Не ранее чем через 3 минуты",
          "Не ранее чем через 6 минут",
          "Не ранее чем через 12 минут",
          "Не ранее чем через 15 минут",
        ],
        correctAnswers: ["Не ранее чем через 15 минут"],
      },
      {
        code: "21202139",
        text: "Как должен поступить взрывник, если электровзрывная сеть была смонтирована перед наступлением грозы?",
        answers: [
          "Произвести взрывание во время грозы",
          "Произвести взрывание вне зависимости от погодных условий",
          "Провести взрывание перед грозой или отсоединить участковые провода от магистральных, концы тщательно изолировать, людей удалить за пределы опасной зоны или в укрытие",
          "Людей удалить в укрытие и произвести взрывание во время грозы",
        ],
        correctAnswers: [
          "Провести взрывание перед грозой или отсоединить участковые провода от магистральных, концы тщательно изолировать, людей удалить за пределы опасной зоны или в укрытие",
        ],
      },
      {
        code: "21202140",
        text: "В каком документе фиксируются отказы зарядов при взрывных работах?",
        answers: [
          "В журнале регистрации отказов при взрывных работах",
          "В сменном журнале",
          "В акте выполненных работ",
          "В ведомости учета зарядов",
        ],
        correctAnswers: ["В журнале регистрации отказов при взрывных работах"],
      },
      {
        code: "21202141",
        text: "Как должен быть обозначен взрывником невзорвавшийся заряд на земной поверхности при обнаружении отказа?",
        answers: [
          "Возле него должна быть выставлена охрана",
          "Возле него должен быть выставлен отличительный знак",
          "Вокруг него должно быть выставлено ограждение",
        ],
        correctAnswers: ["Возле него должен быть выставлен отличительный знак"],
      },
      {
        code: "21202142",
        text: "На каком расстоянии от отказавших шпуровых зарядов разрешается размещать вспомогательные шпуры для их ликвидации?",
        answers: [
          "Не ближе 30 см",
          "Не ближе 24 см",
          "Не ближе 20 см",
          "Не ближе 12 см",
        ],
        correctAnswers: ["Не ближе 30 см"],
      },
      {
        code: "21202143",
        text: "На какую длину разрешается вынимать из шпуров отказавших зарядов забоечный материал для установления направления отказавших шпуров?",
        answers: [
          "На длину до 20 см от устья         шпура",
          "На длину до 30 см от устья   шпура",
          "На длину до 45 см от устья   шпура",
          "На длину до 55 см от устья   шпура",
        ],
        correctAnswers: ["На длину до 20 см от устья         шпура"],
      },
      {
        code: "21202144",
        text: "Что должно быть предпринято в случае, когда работы по ликвидации отказа не могут быть закончены в данной смене?",
        answers: [
          "Разрешается поручать их продолжение взрывнику очередной смены с соответствующим инструктажем и отметкой в выдаваемой ему наряд-путевке",
          "Разрешается продолжать работу только взрывнику данной смены до ее выполнения",
          "Разрешается только совместное продолжение работы по ликвидации отказа со взрывником очередной смены до  ее выполнения",
          "Продолжение работ поручается только в смену выполнявшего ее работника, с ее переносом на другой день",
        ],
        correctAnswers: [
          "Разрешается поручать их продолжение взрывнику очередной смены с соответствующим инструктажем и отметкой в выдаваемой ему наряд-путевке",
        ],
      },
      {
        code: "21202145",
        text: "Что из перечисленного не отражается в инструкции по ликвидации отказавших зарядов взрывчатых веществ?",
        answers: [
          "Порядок обнаружения невзорвавшихся зарядов,   методы ликвидации отказов для каждого вида взрывных работ",
          "Величина радиуса опасной зоны при ликвидации отказа, порядок ее обозначения на местности",
          "Порядок сбора, учета и уничтожения остатков взрывчатых материалов, извлеченных при ликвидации отказа",
          "Требования к квалификации лиц, участвующих в ликвидации неразорвавшихся зарядов",
        ],
        correctAnswers: [
          "Требования к квалификации лиц, участвующих в ликвидации неразорвавшихся зарядов",
        ],
      },
      {
        code: "21202146",
        text: "Что из перечисленного целесообразно отражать в инструкции по ликвидации отказавших зарядов взрывчатых веществ?",
        answers: [
          "Только основные мероприятия по предупреждению отказавших зарядов, порядок обнаружения невзорвавшихся зарядов, методы ликвидации отказов для каждого вида взрывных работ",
          "Только величину радиуса опасной зоны при ликвидации отказа, порядок ее обозначения на местности, а также ее охрану, организацию работ по ликвидации отказов",
          "Только порядок сбора, учета и уничтожения остатков взрывчатых материалов, извлеченных при ликвидации отказа, мероприятия по безопасности работ",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21202147",
        text: "Каким должен быть минимально допустимый радиус опасной зоны при проведении открытых взрывных работ с применением наружных зарядов?",
        answers: ["100 м", "220 м", "275 м", "300 м"],
        correctAnswers: ["300 м"],
      },
      {
        code: "21202148",
        text: "Какое из перечисленных требований запрещается при ведении взрывных работ на угольных шахтах и рудниках, опасных по газу и (или) пыли? Укажите все правильные ответы.",
        answers: [
          "Размещать в одном шпуре взрывчатые вещества различных классов",
          "Размещать в одном шпуре при сплошном заряде более одного патрона-боевика",
          "Устанавливать шпуры при взрывании по углю и породе на глубине 0,8 м",
          "Досылать отдельно боевик, при введении в шпур заряда, состоящего из 2 и более патронов взрывчатого вещества",
        ],
        correctAnswers: [
          "Размещать в одном шпуре взрывчатые вещества различных классов",
          "Размещать в одном шпуре при сплошном заряде более одного патрона-боевика",
        ],
      },
      {
        code: "21202149",
        text: "Кем разрабатывается и согласовывается с командиром обслуживающего аварийно-спасательного формирования план по обслуживанию массового взрыва силами аварийно-спасательных формирований? Укажите все правильные ответы.",
        answers: [
          "Горным мастером",
          "Инспектором Ростехнадзора",
          "Техническим руководителем рудника, шахты, объекта строительства",
          "Ответственным руководителем взрыва",
        ],
        correctAnswers: [
          "Техническим руководителем рудника, шахты, объекта строительства",
          "Ответственным руководителем взрыва",
        ],
      },
      {
        code: "21202150",
        text: "При каких условиях допускается проведение прострелочных или взрывных работ в скважинах?",
        answers: [
          "Во время туманов (при видимости более 50 м) при выполнении работ в закрытых помещениях буровых",
          "Во время грозы",
          "Во время пурги, буранов",
          "В сухих газирующих и поглощающих раствор скважинах без применения лубрикаторов",
        ],
        correctAnswers: [
          "Во время туманов (при видимости более 50 м) при выполнении работ в закрытых помещениях буровых",
        ],
      },
      {
        code: "21202151",
        text: "Кем проверяется состояние зарядных устройств не реже одного раза в месяц?",
        answers: [
          "Комиссией организации, эксплуатирующей зарядное оборудование",
          "Руководителем взрывных работ организации, эксплуатирующей зарядное оборудование",
          "Представителем органа, выдавшего лицензию на осуществление деятельности, связанной с обращением взрывчатых материалов промышленного назначения",
          "Представителем Роспотребнадзора",
        ],
        correctAnswers: [
          "Комиссией организации, эксплуатирующей зарядное оборудование",
        ],
      },
      {
        code: "21202152",
        text: "С какой периодичностью проводится определение относительной влажности воздуха в забойном пространстве после внедрения пневматического заряжания?",
        answers: [
          "Не реже одного раза в 6 месяцев",
          "Не реже одного раза в 9 месяцев",
          "Не реже одного раза в 12 месяцев",
          "Не реже одного раза в квартал",
        ],
        correctAnswers: ["Не реже одного раза в квартал"],
      },
      {
        code: "21202153",
        text: "Какие из перечисленных помещений разрешается размещать в зданиях пунктов производства и подготовки взрывчатых веществ, за исключением зданий, в которых непосредственно производятся или подготавливаются взрывчатые вещества? Выберите 2 варианта ответа.",
        answers: [
          "Слесарные мастерские для мелкого текущего ремонта",
          "Помещения временного пребывания дежурных слесарей и электриков",
          "Слесарные мастерские для мелкого текущего ремонта с применением сварочного оборудования",
          "Помещение для временного хранение сгораемой и несгораемой тары из-под взрывчатых веществ и окислителей",
        ],
        correctAnswers: [
          "Слесарные мастерские для мелкого текущего ремонта",
          "Помещения временного пребывания дежурных слесарей и электриков",
        ],
      },
      {
        code: "21202154",
        text: "Что из перечисленного допускается в процессе пневмотранспортирования или пневмозаряжания?",
        answers: [
          "Расстилать брезент на месте заряжания под восходящими скважинами",
          "Производить одновременное заряжание взрывчатых веществ, содержащих в своем составе тротил или алюминиевую пудру",
          "Смешивать два и более типа взрывчатых веществ",
          "Применять зарядные трубопроводы, покрашенные красками или другими диэлектрическими материалами с удельным электрическим сопротивлением более 106 Ом x см",
        ],
        correctAnswers: [
          "Расстилать брезент на месте заряжания под восходящими скважинами",
        ],
      },
      {
        code: "21202155",
        text: "В каком случае должно проводиться сотрясательное взрывание при отработке пластов, опасных по внезапным выбросам угля, породы и газа? Укажите все правильные ответы.",
        answers: [
          "При вскрытии выбросоопасных угольных пластов мощностью более 0,1 м",
          "При вскрытии угрожаемых угольных пластов, если прогнозом установлены опасные значения показателей выбросоопасности или прогноз перед вскрытием не проводился",
          "При проведении восстающих выработок с углом наклона 25° и более",
          "При вскрытии угольных пластов, если прогнозом установлены неопасные значения показателей выбросоопасности",
        ],
        correctAnswers: [
          "При вскрытии выбросоопасных угольных пластов мощностью более 0,1 м",
          "При вскрытии угрожаемых угольных пластов, если прогнозом установлены опасные значения показателей выбросоопасности или прогноз перед вскрытием не проводился",
        ],
      },
      {
        code: "21202156",
        text: "Какая допускается максимальная скорость движения железнодорожного подвижного состава с опасными грузами на территории пункта подготовки взрывчатых веществ?",
        answers: ["15 км/ч", "25 км/ч", "20 км/ч", "30 км/ч"],
        correctAnswers: ["15 км/ч"],
      },
      {
        code: "21202157",
        text: "Кем должны проводиться заряжание и забойка при ведении взрывных работ по металлу?",
        answers: [
          "Двумя взрывниками в присутствии руководителя взрывных работ",
          "Одним взрывником",
          "Двумя взрывниками",
          "Одним взрывником  в присутствии руководителя взрывных работ",
        ],
        correctAnswers: [
          "Двумя взрывниками в присутствии руководителя взрывных работ",
        ],
      },
      {
        code: "21202158",
        text: "В каком случае оборудование и здание пунктов производства и подготовки взрывчатых веществ в целом должны быть полностью освобождены от взрывоопасных продуктов?",
        answers: [
          "При остановке пункта на период более двух суток",
          "При остановке пункта на период более 3 суток",
          "Перед нерабочей сменой",
          "После окончания каждой смены",
          "Если не может быть организовано постоянное наблюдение",
        ],
        correctAnswers: ["При остановке пункта на период более двух суток"],
      },
      {
        code: "21202159",
        text: "Кто должен осмотреть состояние кровли и стенок выработки и принять меры по приведению их в безопасное состояние перед началом работы по механизированному заряжанию шпуров, скважин или камер? Укажите все правильные ответы.",
        answers: [
          "Руководитель взрывных работ",
          "Взрывник",
          "Горный мастер",
          "Специалист отдела охраны труда и промышленной безопасности",
        ],
        correctAnswers: ["Руководитель взрывных работ", "Взрывник"],
      },
      {
        code: "21202160",
        text: "После чего начинаются загрузка бункера зарядного оборудования и непосредственно заряжание?",
        answers: [
          "После того, как руководитель взрывных работ убедится в том, что блок и зарядные устройства к этим работам подготовлены",
          "После того, как горный мастер убедится в том, что блок и зарядные устройства к этим работам подготовлены",
          "После того, как специалист отдела охраны труда и промышленной безопасности убедится в том, что блок и зарядные устройства к этим работам подготовлены",
          "После того, как руководитель взрывных работ убедится в том, что температура сжатого воздуха, а также нагрев узлов зарядных устройств, через которые проходят взрывчатые вещества, превышает 60 °C",
        ],
        correctAnswers: [
          "После того, как руководитель взрывных работ убедится в том, что блок и зарядные устройства к этим работам подготовлены",
        ],
      },
      {
        code: "21202161",
        text: "На каком расстоянии от места заряжания скважин и стоянки зарядной машины и трубопровода запрещается производить какие-либо работы, непосредственно не связанные с заряжанием?",
        answers: [
          "Не ближе 50 м",
          "Не ближе 60 м",
          "Не ближе 75 м",
          "Не ближе 100 м",
        ],
        correctAnswers: ["Не ближе 50 м"],
      },
      {
        code: "21202162",
        text: "В каком месте необходимо располагать зарядную для аккумуляторных погрузчиков, а также постоянную стоянку зарядных машин (смесительно-зарядных машин, транспортно-зарядных машин)?",
        answers: [
          "За территорией пунктов производства и подготовки взрывчатых веществ на расстоянии не ближе 50 м от здания подготовки и (или) производства взрывчатых веществ",
          "На территории поверхностных пунктов производства и подготовки взрывчатых веществ, на расстоянии не ближе 50 м от здания подготовки и (или) производства взрывчатых веществ",
          "За территорией поверхностных пунктов производства и подготовки взрывчатых веществ на расстоянии не ближе 25 м от здания подготовки и (или) производства взрывчатых веществ",
          "На территории поверхностных пунктов производства и подготовки взрывчатых веществ, на расстоянии не ближе 25 м от здания подготовки и (или) производства взрывчатых веществ",
        ],
        correctAnswers: [
          "За территорией пунктов производства и подготовки взрывчатых веществ на расстоянии не ближе 50 м от здания подготовки и (или) производства взрывчатых веществ",
        ],
      },
      {
        code: "21202163",
        text: "Какое из перечисленных условий допускается при заземлении и защите от образования статического электричества в пневмозарядных устройствах?",
        answers: [
          "Применение полиэтиленовых и резиновых трубопроводов (шлангов) с удельным объемным электрическим сопротивлением не более 10⁷ Ом x м",
          "Пневмозаряжание шпуров в подземных горных выработках при относительной влажности рудничного воздуха более 50% зарядчиками с металлической зарядной трубкой или с электропроводящим зарядным трубопроводом длиной не более 5 м",
          "Пневмозаряжание по одному трубопроводу взрывчатых веществ разных типов",
          "Пневмозаряжание гранулированными алюмо- и тротилсодержащими взрывчатыми веществами без предварительного их увлажнения",
        ],
        correctAnswers: [
          "Применение полиэтиленовых и резиновых трубопроводов (шлангов) с удельным объемным электрическим сопротивлением не более 10⁷ Ом x м",
        ],
      },
      {
        code: "21202164",
        text: "Каким способом разрешается проводить взрывание камерных зарядов?",
        answers: [
          "Всеми перечисленными способами",
          "С применением электродетонаторов",
          "С применением детонирующего шнура",
          "С применением неэлектрических систем инициирования",
        ],
        correctAnswers: ["Всеми перечисленными способами"],
      },
      {
        code: "21202165",
        text: "Какие взрывчатые вещества должны использоваться при разупрочнении труднообрушаемых пород любой крепости?",
        answers: [
          "Взрывчатые вещества, не содержащие сенсибилизаторов, более чувствительных, чем тротил",
          "Взрывчатые вещества, содержащие в своем составе гексоген или нитроэфиры",
          "Любые взрывчатые вещества",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "Взрывчатые вещества, не содержащие сенсибилизаторов, более чувствительных, чем тротил",
        ],
      },
      {
        code: "21202166",
        text: "Какие условия должны соблюдаться в забоях выработок, где имеется газовыделение или взрывчатая угольная пыль?",
        answers: [
          "При протяженности угольного забоя более 5 м разрешается его делить по длине на участки, и взрывание в каждом из них производить раздельно",
          "Общее максимальное время замедления электродетонаторов короткозамедленного действия с учетом разброса по времени срабатывания не должно превышать при применении взрывчатых веществ IV класса - 320 мс",
          "В подготовительных выработках, проводимых по углю с подрывкой боковых пород, взрывание зарядов в шпурах по углю и породе проводится только раздельно",
          "Одновременное взрывание должно осуществляться только по разрешению технического руководителя шахты",
        ],
        correctAnswers: [
          "При протяженности угольного забоя более 5 м разрешается его делить по длине на участки, и взрывание в каждом из них производить раздельно",
        ],
      },
      {
        code: "21202167",
        text: "В каком случае разрешается доставка аммиачно-селитренных взрывчатых веществ к местам проведения взрывных работ в подземных выработках в ковшах погрузочно-доставочных машин от участковых пунктов хранения и мест выгрузки взрывчатых материалов?",
        answers: [
          "При условии загрузки ковша не более 2/3 по его высоте",
          "При условии загрузки ковша не более 1/3 по его высоте",
          "При условии загрузки ковша не более 3/4 по его высоте",
          "Запрещается в любом случае",
        ],
        correctAnswers: [
          "При условии загрузки ковша не более 2/3 по его высоте",
        ],
      },
      {
        code: "21202168",
        text: "Какое из перечисленных требований к транспортированию взрывчатых материалов указано верно? Укажите все правильные ответы.",
        answers: [
          "Спуск-подъем взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами должен проводиться вне очереди",
          "При спуске-подъеме взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами по наклонным выработкам в людских вагонетках на каждом сиденье должно находиться не более одного взрывника или подносчика",
          "Разрешается одновременно спускаться или подниматься в одной клети только одному взрывнику или подносчику с сумками с взрывчатыми материалами",
          "Спуск-подъем взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами должен проводиться в последнюю очередь",
        ],
        correctAnswers: [
          "Спуск-подъем взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами должен проводиться вне очереди",
          "При спуске-подъеме взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами по наклонным выработкам в людских вагонетках на каждом сиденье должно находиться не более одного взрывника или подносчика",
        ],
      },
      {
        code: "21202169",
        text: "Какие действия обязаны предпринять работники подземного рудника (шахты), обнаружившие отказавший заряд? Укажите все правильные ответы.",
        answers: [
          "Закрестить выработку",
          "Прекратить все работы",
          "Выставить знак у отказавшего заряда",
          "Поместить на него новый заряд и провести взрывание в обычном порядке",
        ],
        correctAnswers: ["Закрестить выработку", "Прекратить все работы"],
      },
      {
        code: "21202170",
        text: "Какое из перечисленных условий указано неверно при вскрытии пластов сотрясательным взрыванием с применением рассредоточенных (двухъярусных) зарядов взрывчатых веществ?",
        answers: [
          "В шпурах с рассредоточенными зарядами замедление в донном заряде должно быть меньше, чем в первом заряде от устья",
          "Длина шпуров с рассредоточенными зарядами должна составлять не менее 3 м",
          "Инициирование зарядов осуществляют электродетонаторами мгновенного и короткозамедленного действия",
          "Все перечисленные условия",
        ],
        correctAnswers: [
          "В шпурах с рассредоточенными зарядами замедление в донном заряде должно быть меньше, чем в первом заряде от устья",
        ],
      },
      {
        code: "21202171",
        text: "В каком случае транспортные пути для перевозок взрывчатых материалов на территории поверхностных пунктов производства (подготовки) должны располагаться на расстоянии не менее 3 м от зданий?",
        answers: [
          "В случае, если пути предназначены для подъезда к этим зданиям",
          "Если в этих зданиях (помещениях) изготавливаются или перерабатываются взрывчатые вещества",
          "Если в этих зданиях (помещениях) имеются открытые огневые топки и источники открытого огня",
          "Если в этих зданиях (помещениях) расположены хранилища горючих и легковоспламеняющихся веществ",
          "Все ответы неверны",
        ],
        correctAnswers: [
          "В случае, если пути предназначены для подъезда к этим зданиям",
        ],
      },
      {
        code: "21202172",
        text: "С какой периодичностью проводится измерение параметров электризации в условиях производства?",
        answers: [
          "Не реже двух раз в год",
          "Не реже одного раза в год",
          "Не реже одного раза в два года",
          "Не реже одного раза в три года",
        ],
        correctAnswers: ["Не реже двух раз в год"],
      },
      {
        code: "21202173",
        text: "Какое действие необходимо выполнять для уменьшения пыления и просыпи взрывчатых веществ?",
        answers: [
          "Все перечисленные действия",
          "При пневмозаряжании алюмо- и тротилсодержащими рассыпными гранулированными взрывчатыми веществами добавлять во взрывчатые вещества воду или смачивающий раствор",
          "Выдерживать оптимальное расстояние от конца шланга до заряда",
          "При заряжании центрировать конец зарядного трубопровода относительно оси скважины",
        ],
        correctAnswers: ["Все перечисленные действия"],
      },
      {
        code: "21202174",
        text: "Кому должен сообщить взрывник при возникновении аварийной ситуации в процессе заряжания?",
        answers: [
          "Руководителю взрывных работ",
          "Горному мастеру",
          "Лицу технического надзора",
          "Руководителю службы вентиляции",
        ],
        correctAnswers: ["Руководителю взрывных работ"],
      },
      {
        code: "21202175",
        text: "Взрывчатые вещества каких составов разрешается производить на пунктах производства взрывчатых веществ?",
        answers: [
          "Только смеси холодного смешения гранулированной аммиачной селитры с гранулированным (чешуированным) тротилом, жидкими и твердыми нефтяными, порошкообразными или другого происхождения невзрывчатыми горючими",
          "Только водосодержащие смеси-суспензии, эмульсии и эмульсионные взрывчатые вещества на основе раствора аммиачной селитры или ее раствора с добавками кальциевой или натриевой селитры или карбамида с порошкообразными и жидкими невзрывчатыми горючими",
          "Только смеси холодного смешения гранулированной аммиачной селитры с жидкими и твердыми нефтяными, порошкообразными или другого происхождения невзрывчатыми горючими",
          "Всех перечисленных",
        ],
        correctAnswers: ["Всех перечисленных"],
      },
      {
        code: "21202176",
        text: "Кто утверждает регламент технологического процесса производства и подготовки взрывчатых веществ и изменения в него?",
        answers: [
          "Руководитель (технический руководитель) эксплуатирующей организации",
          "Руководитель взрывных работ",
          "Руководитель службы вентиляции",
          "Горный мастер",
        ],
        correctAnswers: [
          "Руководитель (технический руководитель) эксплуатирующей организации",
        ],
      },
      {
        code: "21202177",
        text: "Какое из перечисленных расстояний при ведении взрывных работ в подземных выработках указано верно?",
        answers: [
          "Запрещается нахождение людей в раздаточной камере при взрывных работах, проводящихся на расстоянии ближе 100 м от них",
          "Запрещается ведение взрывных работ на расстоянии менее 10 м от склада взрывчатых материалов",
          "Запрещается взрывание зарядов, если на расстоянии менее 10 м от места их заложения находятся неубранная отбитая горная масса",
          "Все перечисленные",
        ],
        correctAnswers: [
          "Запрещается нахождение людей в раздаточной камере при взрывных работах, проводящихся на расстоянии ближе 100 м от них",
        ],
      },
      {
        code: "21202178",
        text: "Какое из перечисленных мест укрытия взрывников должно располагаться от места взрыва на расстоянии не менее 200 м?",
        answers: [
          "В очистных забоях камерного типа, а также при погашении угольных целиков",
          "В горизонтальных и наклонных (до 10°) подготовительных выработках, при дроблении негабаритов наружными зарядами, а также при взрывной посадке кровли в лавах",
          "В лавах (слоях) с углом залегания 18° и более",
          "Все перечисленные места",
        ],
        correctAnswers: [
          "В очистных забоях камерного типа, а также при погашении угольных целиков",
        ],
      },
      {
        code: "21202179",
        text: "Каким образом следует проводить бурение шпуров по углю для сотрясательного взрывания?",
        answers: [
          "Вращательным способом",
          "Только ударным способом",
          "Только ударновращательным способом",
          "Любым из перечисленных способов",
        ],
        correctAnswers: ["Вращательным способом"],
      },
      {
        code: "21202180",
        text: "Какое из перечисленных требований во время взрывания при образовании каналов, канав и котлованов указано верно?",
        answers: [
          "В сложных горно-геологических условиях при взрывании неэлектрическими системами инициирования и детонирующим шнуром зарядов взрывчатых веществ группы D (кроме дымного пороха) допускается размещение удлиненных горизонтальных зарядов (траншейных, щелевых) непосредственно вслед за проведением горных выработок",
          "При заряжании линейных зарядов более 2 суток боевики укладываются в день производства взрыва.",
          "Отставание заряда от многоковшового экскаватора должно быть не менее 3 м, одноковшового - 5 м",
          "Все перечисленные",
        ],
        correctAnswers: [
          "В сложных горно-геологических условиях при взрывании неэлектрическими системами инициирования и детонирующим шнуром зарядов взрывчатых веществ группы D (кроме дымного пороха) допускается размещение удлиненных горизонтальных зарядов (траншейных, щелевых) непосредственно вслед за проведением горных выработок",
        ],
      },
      {
        code: "21202181",
        text: "В течение какого срока в организации хранятся проекты буровзрывных (взрывных) работ, паспорта, схемы, в соответствии с которыми осуществляются взрывные работы?",
        answers: [
          "Не менее года",
          "Не менее 6 месяцев",
          "Не менее 3 месяцев",
          "Не менее 1 месяца",
        ],
        correctAnswers: ["Не менее года"],
      },
      {
        code: "21202182",
        text: "Где должен находиться руководитель сотрясательного взрывания в забое, замеряющий содержание метана, при продвижении к забою для осмотра его после сотрясательного взрывания?",
        answers: [
          "На расстоянии 3 м впереди взрывника",
          "На расстоянии 1 м впереди взрывника",
          "На расстоянии 3 м позади взрывника",
          "На расстоянии 1 м позади взрывника",
        ],
        correctAnswers: ["На расстоянии 3 м впереди взрывника"],
      },
      {
        code: "21202183",
        text: "В каком случае допускается оставлять взрывчатые материалы на местах работ без постоянного надзора (охраны)?",
        answers: [
          "Все ответы неверны",
          "В случае, если закончилась смена",
          "В случае, если взрывчатые материалы остаются без надзора (охраны) на срок не более 1 часа",
          "В случае, если взрывчатые материалы остаются без надзора (охраны) на срок не более 1 смены",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21202184",
        text: "Кем проверяется подготовленный к массовому взрыву подэтаж (блок, панель)?",
        answers: [
          "Все ответы неверны",
          "Руководителем рудника (шахты, объекта строительства)",
          "Лицом технического надзора",
          "Руководителем взрывных работ",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21202185",
        text: "Кем и с какой периодичностью осматриваются помещения и площадки, где проводится обработка металлов, рассчитанные на взрыв максимально допустимого заряда?",
        answers: [
          "Комиссией организации ежегодно",
          "Руководителем рудника (шахты, объекта строительства), ежегодно",
          "Лицом технического надзора, ежеквартально",
          "Руководителем взрывных работ, ежемесячно",
        ],
        correctAnswers: ["Комиссией организации ежегодно"],
      },
      {
        code: "21202186",
        text: "Какое действие необходимо предпринять если при проверке линии наименьшего сопротивления выявится возможность опасного разлета кусков горной массы или воздействия ударной воздушной волны при ликвидации отказавших камерных зарядов?",
        answers: [
          "Разборку забойки с последующим извлечением взрывчатых веществ",
          "Взорвать заряд в скважине, пробуренной параллельно",
          "Разобрать забойку, ввести новый боевик, забойку и взорвать в обычном порядке",
          "Осуществить вымывание заряда из камеры",
        ],
        correctAnswers: [
          "Разборку забойки с последующим извлечением взрывчатых веществ",
        ],
      },
      {
        code: "21202187",
        text: "Какое из перечисленных условий допуска работников на рабочие места после производства массовых взрывов указано верно?",
        answers: [
          "Получение ответственным руководителем массового взрыва сообщения о снижении концентрации ядовитых продуктов взрыва в воздухе до установленных ПДК",
          "Не ранее, чем через 5 минут после взрыва",
          "Частичное восстановление видимости",
          "Все перечисленные",
        ],
        correctAnswers: [
          "Получение ответственным руководителем массового взрыва сообщения о снижении концентрации ядовитых продуктов взрыва в воздухе до установленных ПДК",
        ],
      },
      {
        code: "21202188",
        text: "В каких местах при взрывных работах могут использоваться слежавшиеся порошкообразные взрывчатые вещества, содержащие гексоген или жидкие нитроэфиры, без размятия или измельчения?",
        answers: [
          "На земной поверхности",
          "Только в шахтах (рудниках), не опасных по газу",
          "Только в шахтах (рудниках), разрабатывающих пласты (рудные тела), не опасные по взрывам пыли",
          "В любом из перечисленных мест",
        ],
        correctAnswers: ["На земной поверхности"],
      },
      {
        code: "21202189",
        text: "Какое действие из перечисленных допускается при ведении взрывных работ?",
        answers: [
          "Применять рассредоточенные заряды в шахтах, опасных по газу или пыли, в породных забоях выработок, в которых отсутствует выделение горючих газов, во врубовых шпурах",
          "Допускать переломы выходящих из зарядов концов детонирующего шнура",
          "На шахтах (рудниках), опасных по газу и пыли взрывать заряды без забойки",
          "Выдергивать или тянуть детонирующий шнур, волноводы, провода электронных детонаторов и электродетонаторов, введенные в боевики",
        ],
        correctAnswers: [
          "Применять рассредоточенные заряды в шахтах, опасных по газу или пыли, в породных забоях выработок, в которых отсутствует выделение горючих газов, во врубовых шпурах",
        ],
      },
      {
        code: "21202190",
        text: "Каким образом запрещается производить заряжание шпуров (скважин) и монтаж взрывной сети на высоте более 2 м?",
        answers: [
          "С лестниц",
          "С оборудованных подъемных площадок (помостов), полков",
          "С площадок подъемных механизмов",
          "С применением погрузочно-доставочной техники в случаях, если это допускается технической документацией на указанную технику",
        ],
        correctAnswers: ["С лестниц"],
      },
      {
        code: "21202191",
        text: "Какая устанавливается максимальная суммарная загрузка здания, в котором производятся или подготавливаются взрывчатые вещества, с учетом взрывчатых веществ, находящихся в вагоне, смесительно-зарядной машине или другом транспортном средстве и накопительных емкостях?",
        answers: ["60 т", "70 т", "40 т", "50 т"],
        correctAnswers: ["60 т"],
      },
      {
        code: "21202192",
        text: "Какие прострелочно-взрывные аппараты должны подниматься над устьем скважины и опускаться с помощью грузоподъемных механизмов?",
        answers: [
          "Все ответы неверны",
          "Массой более 30 кг",
          "Массой более 20 кг или длиной более 1,5 м",
          "Массой более 35 кг или длиной более 1 м",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21202193",
        text: "Какому эквивалентному значению приравнивается 1 л диоксида азота при проверке вредных продуктов взрыва в выработке (забое)?",
        answers: [
          "6,5 л оксида углерода",
          "5,5 л оксида углерода",
          "4,5 л оксида углерода",
          "7,5 л оксида углерода",
        ],
        correctAnswers: ["6,5 л оксида углерода"],
      },
      {
        code: "21202194",
        text: "При какой минимальной концентрации метана руководитель сотрясательного взрывания в забое, замеряющий содержание метана, при продвижении к забою для осмотра его после сотрясательного взрывания, обязан немедленно возвратиться в выработку со свежей струей воздуха?",
        answers: ["2 %", "8 %", "4 %", "1 %"],
        correctAnswers: ["2 %"],
      },
      {
        code: "21202195",
        text: "На каком минимальном расстоянии от места взрыва должно находиться место укрытия взрывников при пропуске угля и породы в восстающих выработках?",
        answers: ["150 м", "100 м", "75 м", "50 м"],
        correctAnswers: ["150 м"],
      },
      {
        code: "21202196",
        text: "Какое количество приемов допускается при проведении взрывания по породе выработок, в которых отсутствует выделение метана, с применением электродетонаторов мгновенного, короткозамедленного и замедленного действия со временем замедления до 2 с?",
        answers: [
          "Без ограничения количества приемов",
          "2 приема",
          "3 приема",
          "1 прием",
        ],
        correctAnswers: ["Без ограничения количества приемов"],
      },
      {
        code: "21202197",
        text: "При какой температуре запрещается заряжание и взрывание зарядов в шпурах при ведении взрывных работ по металлу?",
        answers: [
          "Свыше 200 °C",
          "Свыше 220 °C",
          "Свыше 250 °C",
          "Свыше 150 °C",
        ],
        correctAnswers: ["Свыше 200 °C"],
      },
      {
        code: "21202198",
        text: "Какого вида взрыва, после производства которого требуется больше времени для проветривания и возобновления работ в руднике (шахте, участке), чем это предусмотрено графиком проветривания горных выработок при повседневной организации работ, не существует?",
        answers: [
          "Пробные взрывы",
          "Технологические взрывы",
          "Специальные взрывы",
          "Экспериментальные взрывы",
        ],
        correctAnswers: ["Пробные взрывы"],
      },
      {
        code: "21202199",
        text: "Какое из перечисленных требований к условиям заряжания, массе зарядов взрывчатых веществ и длине забойки указано верно?",
        answers: [
          "В шпурах глубиной более 1,5 м заряд должен занимать не более 2/3 их длины, оставшаяся часть шпура должна быть заполнена забойкой",
          "При наличии в забое нескольких обнаженных поверхностей линия наименьшего сопротивления от любой точки заряда до ближайшей обнаженной поверхности должна быть не менее 0,5 м в продуктивном пласте и не менее 0,3 м по породе",
          "В шпурах глубиной от 1 до 1,5 м заряд должен занимать не более половины их длины, оставшаяся часть шпура должна быть заполнена забойкой",
          "Все перечисленные требования",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "21202200",
        text: "В каком случае допускается одновременное взрывание в обоих крыльях калотты?",
        answers: [
          "По распоряжению лица технического надзора",
          "По распоряжению руководителя взрывных работ",
          "Не допускается ни в каком случае",
          "По распоряжению руководителя организации",
        ],
        correctAnswers: ["Не допускается ни в каком случае"],
      },
      {
        code: "21202201",
        text: "Куда должны быть занесены машинистом погрузочной техники время обнаружения отказа, принятые меры безопасности, а также данные о том, кому сообщено об обнаружении отказа?",
        answers: [
          "В журнал приема-сдачи смен",
          "В наряд-путевку",
          "В акт обнаружения отказа",
          "В журнал регистрации отказов при взрывных работах",
        ],
        correctAnswers: ["В журнал приема-сдачи смен"],
      },
      {
        code: "21202202",
        text: "Из чего состоит проект буровзрывных (взрывных) работ (проект массового взрыва) для конкретных условий?",
        answers: [
          "Из распорядка проведения массового взрыва",
          "Из всего перечисленного",
          "Из таблицы параметров взрывных работ",
          "Из технического расчета со схемой расположения скважин и графическими материалами",
        ],
        correctAnswers: ["Из всего перечисленного"],
      },
      {
        code: "21202203",
        text: "Как следует вводить огнепроводный шнур в капсюль-детонатор?",
        answers: [
          "До соприкосновения с чашечкой капсюля-детонатора прямым движением без вращения",
          "До соприкосновения с чашечкой капсюль-детонатора, поступательно-вращательным движением по часовой стрелке",
          "До соприкосновения с чашечкой капсюль-детонатора, поступательно-вращательным движением против часовой стрелки",
          "Прямым движением без вращения, не доводя до соприкосновения с чашечкой капсюль-детонатора на 5 мм",
        ],
        correctAnswers: [
          "До соприкосновения с чашечкой капсюля-детонатора прямым движением без вращения",
        ],
      },
      {
        code: "21202204",
        text: "В каких случаях при взрывании скважинных зарядов на поверхности обязательно дублирование внутрискважинной сети?",
        answers: [
          "При глубине взрывных скважин более 15 м",
          "При взрывании скважинных зарядов независимо от их глубины",
          "При взрывании нескольких скважинных зарядов при глубине взрывных скважин более 10 м",
          "Необходимость дублирования определяется руководителем взрывных работ",
        ],
        correctAnswers: ["При глубине взрывных скважин более 15 м"],
      },
      {
        code: "21202205",
        text: "Какой должна быть длина огнепроводного шнура контрольной трубки, используемой для контроля времени, затрачиваемого на зажигание, при поджигании пяти трубок и более на земной поверхности?",
        answers: [
          "Не менее чем на 60 см короче по сравнению со шнуром самой короткой из применяемых зажигательных трубок, но не менее 40 см",
          "Не менее чем на 50 см короче по сравнению со шнуром самой короткой из применяемых зажигательных трубок, но не менее 35 см",
          "Не менее чем на 40 см короче по сравнению со шнуром самой короткой из применяемых зажигательных трубок, но не менее 60 см",
          "Не менее чем на 35 см короче по сравнению со шнуром самой короткой из применяемых зажигательных трубок, но не менее 50 см",
        ],
        correctAnswers: [
          "Не менее чем на 60 см короче по сравнению со шнуром самой короткой из применяемых зажигательных трубок, но не менее 40 см",
        ],
      },
      {
        code: "21202206",
        text: "Какая допускается максимальная температура сжатого воздуха (при работе с автономным компрессором), а также нагрева узлов зарядных устройств, через которые проходят взрывчатые вещества?",
        answers: ["60 °C", "70 °C", "80 °C", "40 °C", "Все ответы неверны"],
        correctAnswers: ["60 °C"],
      },
      {
        code: "21202207",
        text: "Как подразделяются склады взрывчатых материалов по месту расположения относительно земной поверхности?",
        answers: [
          "На наземные и подземные",
          "На поверхностные, полууглубленные, углубленные и подземные",
          "На наземные и полуподземные",
          "На полууглубленные и углубленные",
        ],
        correctAnswers: [
          "На поверхностные, полууглубленные, углубленные и подземные",
        ],
      },
      {
        code: "21202208",
        text: "У какого из складов толща грунта над хранилищем составляет более 15 м?",
        answers: ["У подземного", "У углубленного", "У полууглубленного"],
        correctAnswers: ["У подземного"],
      },
      {
        code: "21202209",
        text: "Какие склады, в зависимости от срока эксплуатации, относятся к кратковременным?",
        answers: [
          "Эксплуатируемые до одного года",
          "Эксплуатируемые более двух лет",
          "Эксплуатируемые три года",
          "Эксплуатируемые пять лет",
        ],
        correctAnswers: ["Эксплуатируемые до одного года"],
      },
      {
        code: "21202210",
        text: "В каком из перечисленных положений нарушены требования к размещению площадок пунктов производства и подготовки взрывчатых веществ?",
        answers: [
          "Поверхностные пункты производства и (или) подготовки взрывчатых веществ должны располагаться на самостоятельных площадках",
          "Поверхностные пункты производства и подготовки взрывчатых веществ допускается располагать в запретной зоне склада взрывчатых материалов, на безопасном расстоянии от разгрузочных рамп, хранилищ взрывчатых веществ и средств инициирования",
          "Поверхностные пункты производства и подготовки взрывчатых веществ могут размещаться над действующими горными выработками",
          "Территория пунктов производства и подготовки взрывчатых веществ, расположенных на самостоятельных площадках, по внешнему периметру должна ограждаться и охраняться",
        ],
        correctAnswers: [
          "Поверхностные пункты производства и подготовки взрывчатых веществ могут размещаться над действующими горными выработками",
        ],
      },
      {
        code: "21202211",
        text: "Какие склады взрывчатых материалов должны оборудоваться молниезащитой?",
        answers: [
          "Только поверхностные",
          "Только склады, расположенные в черте населенного пункта",
          "Все склады с высокой грозовой активностью местности",
          "Все склады независимо от грозовой активности местности, за исключением складов, расположенных выше 66° 33’ северной широты, которые оборудовать молниезащитой необязательно",
        ],
        correctAnswers: [
          "Все склады независимо от грозовой активности местности, за исключением складов, расположенных выше 66° 33’ северной широты, которые оборудовать молниезащитой необязательно",
        ],
      },
      {
        code: "21202212",
        text: "Какие объекты должны защищаться как от прямых ударов, так и от вторичных воздействий молний?",
        answers: [
          "Площадки для хранения взрывчатых материалов в контейнерах",
          "Кратковременные склады взрывчатых материалов",
          "Пункты изготовления боевиков с электродетонаторами",
          "Все перечисленные",
        ],
        correctAnswers: ["Пункты изготовления боевиков с электродетонаторами"],
      },
      {
        code: "21202213",
        text: "Какие объекты должны защищаться только от прямого удара молнии?",
        answers: [
          "Хранилища постоянных поверхностных складов взрывчатых материалов",
          "Площадки для хранения взрывчатых материалов в контейнерах",
          "Хранилища временных полууглубленных складов взрывчатых материалов",
          "Пункты изготовления боевиков с электродетонаторами",
        ],
        correctAnswers: [
          "Площадки для хранения взрывчатых материалов в контейнерах",
        ],
      },
      {
        code: "21202214",
        text: "В какие сроки и кем должна проверяться молниезащита складов взрывчатых материалов?",
        answers: [
          "В предгрозовой период, но не реже одного раза в год, а также после выявления повреждений комиссией, назначенной руководителем (техническим руководителем) организации",
          "Не реже двух раз в год (в предгрозовой и послегрозовой периоды) руководителем взрывных работ",
          "Не реже двух раз в год (весной и осенью) службой охраны труда и промышленной безопасности",
          "Ежегодно комиссией, назначенной руководителем организации",
        ],
        correctAnswers: [
          "В предгрозовой период, но не реже одного раза в год, а также после выявления повреждений комиссией, назначенной руководителем (техническим руководителем) организации",
        ],
      },
      {
        code: "21202215",
        text: "С какой периодичностью и кем проводится наружный осмотр молниезащитных устройств склада взрывчатых материалов?",
        answers: [
          "Не реже одного раза в месяц заведующим складом взрывчатых материалов",
          "Не реже одного раза в квартал комиссией, назначенной руководителем организации",
          "Не реже одного раза в полугодие службой энергетика организации",
          "Не реже одного раза в месяц руководителем взрывных работ, в ведении которого находится склад взрывчатых материалов",
        ],
        correctAnswers: [
          "Не реже одного раза в месяц заведующим складом взрывчатых материалов",
        ],
      },
      {
        code: "21202216",
        text: "Что входит в перечень работ при проведении проверки молниезащиты складов взрывчатых материалов?",
        answers: [
          "Только наружный осмотр молниезащитных устройств",
          "Только измерение сопротивления заземлителей молниезащиты",
          "Только проверка переходного сопротивления контактов устройств защиты от вторичных воздействий молнии",
          "Все перечисленное",
        ],
        correctAnswers: ["Все перечисленное"],
      },
      {
        code: "21202217",
        text: "При наличии каких повреждений, выявленных в результате осмотра молниеприемников, молниеотвод должен быть заменен?",
        answers: [
          "Если у молниеотвода оплавился наконечник",
          "Если у молниеотвода оплавился или поврежден конический наконечник, а молниеотвод поврежден ржавчиной более чем на 1/3 площади поперечного сечения",
          "Если молниеотвод и   поверхность в соединениях на болтах   покрыты ржавчиной",
          "Во всех перечисленных случаях",
        ],
        correctAnswers: [
          "Если у молниеотвода оплавился или поврежден конический наконечник, а молниеотвод поврежден ржавчиной более чем на 1/3 площади поперечного сечения",
        ],
      },
      {
        code: "21202218",
        text: "В какой период времени должно проводиться измерение сопротивления заземлителей?",
        answers: [
          "Ежеквартально",
          "Один раз в год в весенний период",
          "В период наибольшего просыхания грунта",
          "Два раза в год в весенний и осенний периоды",
        ],
        correctAnswers: ["В период наибольшего просыхания грунта"],
      },
      {
        code: "21202219",
        text: "При какой площади поперечного сечения токоотводы, поврежденные ржавчиной, должны быть заменены новыми?",
        answers: [
          "Менее 65 мм2",
          "Менее 60 мм2",
          "Менее 55 мм2",
          "Менее 50 мм2",
        ],
        correctAnswers: ["Менее 50 мм2"],
      },
      {
        code: "21202220",
        text: "В какой документ вносятся результаты измерения сопротивлений заземлителей молниезащиты?",
        answers: [
          "В акт проведения измерений сопротивления заземлителей",
          "В сменный журнал",
          "В ведомость состояния заземлителей молниезащиты",
          "В протокол осмотра заземлителей",
        ],
        correctAnswers: ["В ведомость состояния заземлителей молниезащиты"],
      },
      {
        code: "21202221",
        text: "В течение какого срока допускается размещать зарядные машины, загруженные взрывчатыми веществами, на специально выделенной площадке на территории склада ВМ?",
        answers: [
          "На срок не более двух суток",
          "На срок не более трех суток",
          "На срок не более пяти суток",
          "На срок не более десяти суток",
        ],
        correctAnswers: ["На срок не более двух суток"],
      },
      {
        code: "21202222",
        text: "Какое из перечисленных требований к поверхностным постоянным складам указано неверно?",
        answers: [
          "Склады должны ограждаться и иметь запретную зону шириной от ограды не менее 50 м",
          "Хранилища следует располагать так, чтобы обеспечивался свободный подход и подъезд к каждому из них",
          "Расстояния между отдельными хранилищами, между иными зданиями и сооружениями на территории склада, а также до объектов за территорией должны быть установлены техническим руководителем организации",
          "Должны иметь противопожарный водоем (резервуар, скважину, насосы, гидранты)",
        ],
        correctAnswers: [
          "Расстояния между отдельными хранилищами, между иными зданиями и сооружениями на территории склада, а также до объектов за территорией должны быть установлены техническим руководителем организации",
        ],
      },
      {
        code: "21202223",
        text: "Какое требование относится к хранилищам, имеющим рампы и средства механизации погрузочно-разгрузочных работ?",
        answers: [
          "В хранилищах, имеющих рампы и средства механизации погрузочно-разгрузочных работ, устройство тамбуров не обязательно, но обе двери подлежат установке",
          "В хранилищах, предназначенных для выдачи взрывчатых материалов мелкими партиями, необходимо оборудовать не менее одного тамбура",
          "Вход через тамбур следует оборудовать не менее чем двумя двустворчатыми дверями, открывающимися наружу: одна из них ведет снаружи в тамбур, вторая - из тамбура в хранилище",
          "Тамбур должен иметь размер 2 x 2 м и сооружаться из несгораемых материалов",
        ],
        correctAnswers: [
          "В хранилищах, имеющих рампы и средства механизации погрузочно-разгрузочных работ, устройство тамбуров не обязательно, но обе двери подлежат установке",
        ],
      },
      {
        code: "21202224",
        text: "В каком случае допускается использовать для освещения в углубленном складе индивидуальные рудничные аккумуляторные светильники при отсутствии стационарных источников электроэнергии?",
        answers: [
          "По разрешению руководителя (технического руководителя) организации",
          "По разрешению взрывника, или назначенного им лица",
          "По разрешению руководителя взрывных работ, или назначенного им лица",
          "Допускается в любом случае",
        ],
        correctAnswers: [
          "По разрешению руководителя (технического руководителя) организации",
        ],
      },
      {
        code: "21202225",
        text: "Какие условия во временных складах взрывчатых материалов указаны верно? Укажите все правильные ответы.",
        answers: [
          "Устройство противопожарных водоемов (резервуаров, скважин) необязательно",
          "Устройство тамбуров не обязательно, двери могут быть одинарными",
          "Освещение внутри хранилищ запрещается осуществлять рудничными аккумуляторными светильниками",
          "Допускается не покрывать деревянные стены и крыши огнезащитным составом",
        ],
        correctAnswers: [
          "Устройство противопожарных водоемов (резервуаров, скважин) необязательно",
          "Устройство тамбуров не обязательно, двери могут быть одинарными",
        ],
      },
      {
        code: "21202226",
        text: "Работа каких конвейеров, транспортирующих пожаровзрывоопасные вещества, допускается без устройства блокировочных устройств?",
        answers: [
          "Все ответы неверны",
          "Ленточных конвейеров",
          "Винтовых конвейеров",
          "Цепных конвейеров",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21202227",
        text: "Что из перечисленного может использоваться на участковых пунктах в качестве шкафов (контейнеров) для взрывчатых материалов? Укажите все правильные ответы.",
        answers: [
          "Металлические сейфы, изготовленные из металлических листов толщиной не менее 2 мм",
          "Шахтные вагонетки, оборудованные металлическими крышками",
          "Деревянные шкафы (ящики)",
          "Пластиковые контейнеры без крышек и замков",
          "Все перечисленное",
        ],
        correctAnswers: [
          "Металлические сейфы, изготовленные из металлических листов толщиной не менее 2 мм",
          "Шахтные вагонетки, оборудованные металлическими крышками",
        ],
      },
      {
        code: "21202228",
        text: "Какой документ служит для отпуска взрывчатых материалов взрывникам для производства взрывных работ?",
        answers: ["Наряд-путевка", "Наряд-допуск", "Наряд-заказ", "Накладная"],
        correctAnswers: ["Наряд-путевка"],
      },
      {
        code: "21202229",
        text: "Кому должны быть сданы остатки взрывчатых материалов взрывниками по окончании взрывных работ?",
        answers: [
          "Лично лицу, ответственному за выдачу и приемку взрывчатых материалов (раздатчику, заведующему складом взрывчатых материалов)",
          "Руководителю взрывных работ",
          "Руководителю службы вентиляции",
          "Техническому руководителю",
        ],
        correctAnswers: [
          "Лично лицу, ответственному за выдачу и приемку взрывчатых материалов (раздатчику, заведующему складом взрывчатых материалов)",
        ],
      },
      {
        code: "21202230",
        text: "Какое из перечисленных требований к мерам защиты от статического электричества технологического оборудования при взрывных работах указано неверно?",
        answers: [
          "Допускается последовательное включение в заземляющую шину (провод) нескольких заземляющих аппаратов, агрегатов или трубопроводов",
          "В каждом производственном здании должна быть составлена схема (карта) заземления",
          "Запрещается последовательное включение в заземляющую шину (провод) нескольких заземляющих аппаратов, агрегатов или трубопроводов",
          "Все перечисленные",
        ],
        correctAnswers: [
          "Допускается последовательное включение в заземляющую шину (провод) нескольких заземляющих аппаратов, агрегатов или трубопроводов",
        ],
      },
      {
        code: "21202231",
        text: "В каком случае обеспечивается защита хранилищ взрывчатых материалов от заноса высоких потенциалов при вводе в них электрических сетей освещения присоединением металлической брони и оболочки кабеля к заземлителю защиты от вторичных воздействий?",
        answers: [
          "При бронированных кабелях, проложенных в земле",
          "При небронированных кабелях",
          "При кабелях, присоединенных к воздушной линии",
          "Во всех случаях",
        ],
        correctAnswers: ["При бронированных кабелях, проложенных в земле"],
      },
      {
        code: "21202232",
        text: "Какое совместное хранение взрывчатых материалов не допускается?",
        answers: [
          "Детонаторы для взрывных работ, сборки детонаторов для взрывных работ и капсюли-воспламенители (группа совместимости В) с первичным взрывчатым веществом",
          "Дымных (группа совместимости D) и бездымных (группа совместимости C) порохов в соответствии с требованиями к наиболее чувствительным из них",
          "Детонирующего шнура групп совместимости D и S со средствами инициирования групп совместимости B и S",
          "Огнепроводного шнура, средств зажигания его и порохов, сигнальных и пороховых патронов и сигнальных ракет (группа совместимости G) с взрывчатыми материалами групп совместимости B, C и D",
        ],
        correctAnswers: [
          "Детонаторы для взрывных работ, сборки детонаторов для взрывных работ и капсюли-воспламенители (группа совместимости В) с первичным взрывчатым веществом",
        ],
      },
      {
        code: "21202233",
        text: "Какие материалы допускается использовать для насыпки валов складов взрывчатых материалов?",
        answers: ["Пластичные грунты", "Камень", "Щебень", "Горючие материалы"],
        correctAnswers: ["Пластичные грунты"],
      },
      {
        code: "21202234",
        text: "Прокладка каких труб и кабелей допускается в производственных зданиях (помещениях), где ведутся работы с окислителями или их растворами?",
        answers: [
          "Пластмассовых импульсных труб",
          "Медных импульсных и командных труб",
          "Бронированных кабелей с оцинкованной броней",
          "Кабелей с открытой свинцовой оболочкой",
        ],
        correctAnswers: ["Пластмассовых импульсных труб"],
      },
      {
        code: "21202235",
        text: "Каким образом должны быть окрашены заземляющие проводники, предназначенные для защиты от статического электричества, в местах присоединения к технологическому оборудованию и внутреннему контуру заземления при взрывных работах?",
        answers: [
          "Одной поперечной полосой шириной 15 мм красного цвета",
          "Одной поперечной полосой шириной 15 мм желтого цвета",
          "Одной поперечной полосой шириной 15 мм синего цвета",
          "Двумя поперечными полосами шириной 15 мм зеленого цвета",
        ],
        correctAnswers: [
          "Одной поперечной полосой шириной 15 мм красного цвета",
        ],
      },
      {
        code: "21202236",
        text: "Каким образом очищается оборудование при наличии несмываемых остатков взрывчатых веществ по окончании заряжания? Укажите все правильные ответы.",
        answers: [
          "Горячей водой",
          "Паром",
          "Любым из перечисленных способов",
          "С помощью металлических предметов",
        ],
        correctAnswers: ["Горячей водой", "Паром"],
      },
      {
        code: "21202237",
        text: "Какое из перечисленных требований к углубленным складам взрывчатых материалов указано верно?",
        answers: [
          "Устья выработок, ведущих к углубленному складу, должны быть оборудованы двойными дверями, открывающимися наружу",
          "Наружная дверь выработок, ведущих к углубленному складу, должна быть деревянной, а внутренняя - металлической",
          "Если расстояние от входа в углубленный склад до ближайшей камеры хранения взрывчатых материалов менее 5 м, склад должен иметь два выхода",
          "Перед устьем выработки, ведущей к углубленному складу, запрещается устраивать защитный вал",
        ],
        correctAnswers: [
          "Устья выработок, ведущих к углубленному складу, должны быть оборудованы двойными дверями, открывающимися наружу",
        ],
      },
      {
        code: "21202238",
        text: "В каких хранилищах взрывчатых материалов запрещается пользоваться открытым огнем?",
        answers: [
          "В хранилищах аммиачной селитры",
          "В хранилищах натриевой селитры",
          "В хранилищах кальциевой селитры",
          "Все ответы не верны",
        ],
        correctAnswers: ["В хранилищах аммиачной селитры"],
      },
      {
        code: "21202239",
        text: "С какой периодичностью места хранения селитры должны подвергаться очистке?",
        answers: [
          "Не реже одного раза в год",
          "Не реже одного раза в два года",
          "Не реже одного раза в три года",
          "Не реже одного раза в пять лет",
        ],
        correctAnswers: ["Не реже одного раза в год"],
      },
      {
        code: "21202240",
        text: "В течение какого срока должны храниться в организации Книга учета прихода и расхода взрывчатых материалов и Книга учета выдачи и возврата взрывчатых материалов?",
        answers: [
          "В течение не менее трех лет",
          "В течение не менее двух лет",
          "В течение не менее одного года",
          "В течение не более одного года",
        ],
        correctAnswers: ["В течение не менее трех лет"],
      },
      {
        code: "21202241",
        text: "Какие из перечисленных мест хранения взрывчатых материалов должны быть приняты в эксплуатацию комиссией по оценке соответствия места хранения установленным требованиям и проектной документации?",
        answers: [
          "Места сменного хранения, размещаемые вблизи мест ведения взрывных работ",
          "Места размещения взрывчатых материалов на местах производства взрывных работ",
          "Передвижные склады",
          "Все ответы неверны",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21202242",
        text: "Какие требования при вместимости складов и камер взрывчатых материалов указаны верно? Укажите все правильные ответы.",
        answers: [
          "Вместимость камеры в складах камерного типа не должна превышать 2000 кг взрывчатых веществ",
          "На угольных и сланцевых шахтах вместимость склада без учета емкости раздаточных камер не должна превышать семисуточного запаса взрывчатых веществ и пятнадцатисуточного запаса средств инициирования",
          "В складах ячейкового типа в каждой ячейке разрешается хранить не более 500 кг взрывчатых веществ",
          "Предельная вместимость отдельной раздаточной камеры в подземных выработках не должна превышать 3000 кг взрывчатых веществ и соответствующего количества средств инициирования",
        ],
        correctAnswers: [
          "Вместимость камеры в складах камерного типа не должна превышать 2000 кг взрывчатых веществ",
          "На угольных и сланцевых шахтах вместимость склада без учета емкости раздаточных камер не должна превышать семисуточного запаса взрывчатых веществ и пятнадцатисуточного запаса средств инициирования",
        ],
      },
      {
        code: "21202243",
        text: "В каких из перечисленных помещениях допускается хранение взрывчатых материалов?",
        answers: [
          "В тамбурах хранилищ",
          "Все ответы неверны",
          "В здании подготовки взрывчатых материалов",
          "В здании выдачи взрывчатых материалов",
        ],
        correctAnswers: ["Все ответы неверны"],
      },
      {
        code: "21202244",
        text: "С какой периодичностью проводится пересмотр Регламента технологического процесса производства и подготовки взрывчатых веществ?",
        answers: [
          "Не реже 1 раза в 5 лет",
          "Не реже 1 раза в 6 лет",
          "Не реже 1 раза в 8 лет",
          "Не реже 1 раза в 10 лет",
        ],
        correctAnswers: ["Не реже 1 раза в 5 лет"],
      },
      {
        code: "21202245",
        text: "Какие требования предъявляются к устройству хранилищ складов взрывчатых материалов?",
        answers: [
          "Все перечисленные требования",
          "В помещениях для хранения ВМ полы должны быть без щелей, ровные, а стены - побелены или покрашены",
          "Каждое хранилище взрывчатых материалов должно проветриваться и защищаться от проникновения осадков",
          "Хранилища взрывчатых материалов должны обеспечиваться приточно-вытяжным естественным проветриванием",
        ],
        correctAnswers: ["Все перечисленные требования"],
      },
      {
        code: "21202246",
        text: "Как должны располагаться стеллажи для взрывчатых веществ и средств инициирования на складах взрывчатых материалов?",
        answers: [
          "Они должны отстоять от стен не менее чем на 20 см, а от пола - не менее чем на 10 см",
          "Они должны отстоять от стен не менее чем на 18 см, а от пола - не менее чем на 8 см",
          "Они должны отстоять от стен не менее чем на 15 см, а от пола - не менее чем на 5 см",
          "Они должны отстоять от стен не менее чем на 10 см, а от пола - не менее чем на 1 см",
        ],
        correctAnswers: [
          "Они должны отстоять от стен не менее чем на 20 см, а от пола - не менее чем на 10 см",
        ],
      },
      {
        code: "21202247",
        text: "Какое количество рядов ящиков со взрывчатыми материалами можно устанавливать по ширине полки стеллажа?",
        answers: [
          "Не более двух рядов",
          "Не более трех рядов",
          "Не более четырех рядов",
          "Не более пяти  рядов",
        ],
        correctAnswers: ["Не более двух рядов"],
      },
      {
        code: "21202248",
        text: "Что из перечисленного должно располагаться за запретной зоной склада?",
        answers: [
          "Административно-бытовое помещение",
          "Сарай, для хранения отстрелянных модулей перфораторов",
          "Контейнер, для хранения комплектующих модулей перфораторов",
          "Стеллажи или навес для хранения тары",
        ],
        correctAnswers: ["Административно-бытовое помещение"],
      },
      {
        code: "21202249",
        text: "Где должны размещаться взрывчатые материалы в период кратковременного хранения на площадках?",
        answers: [
          "На деревянном настиле высотой не менее 20 см от земли и под навесом или брезентовым покрытием",
          "В специальных деревянных сараях",
          "В передвижных полуприцепах",
          "На земле",
        ],
        correctAnswers: [
          "На деревянном настиле высотой не менее 20 см от земли и под навесом или брезентовым покрытием",
        ],
      },
      {
        code: "21202250",
        text: "В течение какого срока допускается кратковременное хранение взрывчатых материалов на специальных площадках для производства геофизических и других разовых работ?",
        answers: [
          "До 90 суток",
          "До 110 суток",
          "До 145 суток",
          "До 180 суток",
        ],
        correctAnswers: ["До 90 суток"],
      },
    ],
  },
};
