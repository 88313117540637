export default [
  {
    id: 76,
    title: "Блок А",
    description: "Общие требования промышленной безопасности",
  },
  {
    id: 78,
    title: 'Блок Б1',
    description: 'Требования промышленной безопасности в химической, нефтехимической и нефтегазоперерабатывающей промышленности'
  },
  {
    id: 79,
    title: 'Блок Б2',
    description: 'Требования промышленной безопасности в нефтяной и газовой промышленности '
  },
  {
    id: 80,
    title: 'Блок Б3',
    description: 'Требования промышленной безопасности в металлургической промышленности '
  },
  {
    id: 81,
    title: 'Блок Б4',
    description: 'Требования промышленной безопасности в горнорудной промышленности'
  },
  {
    id: 82,
    title: 'Блок Б5',
    description: 'Требования промышленной безопасности в угольной промышленности'
  },
  {
    id: 83,
    title: 'Блок Б6',
    description: 'Требования по маркшейдерскому обеспечению безопасного ведения горных работ '
  },
  {
    id: 84,
    title: 'Блок Б7',
    description: 'Требования промышленной безопасности на объектах газораспределения и газопотребления',
  },
  {
    id: 85,
    title: 'Блок Б8',
    description: 'Требования промышленной безопасности к оборудованию, работающему под давлением',
  },
  {
    id: 86,
    title: 'Блок Б9',
    description: 'Требования промышленной безопасности к подъемным сооружениям',
  },
  {
    id: 87,
    title: 'Блок Б10',
    description: 'Требования промышленной безопасности при транспортировании опасных веществ'
  },
  {
    id: 88,
    title: 'Блок Б11',
    description: 'Требования промышленной безопасности на объектах хранения и переработки растительного сырья'
  },
  {
    id: 89,
    title: 'Блок Б12',
    description: 'Требования промышленной безопасности, относящиеся к взрывным работам'
  },
];
