import React from 'react';

import { View, TouchableOpacity, TextInput } from 'react-native';
import Search from '@images/icons/search.svg';
import Cancel from '@images/icons/cancel.svg';
import { useNavigation } from '@react-navigation/native';

import { styles } from './styles';

export const FindQuestion = ({
  handleChangeText,
  value,
  resetValue,
  style = {},
}) => {
  return (
    <View style={[styles.container, style]}>
      <Search />
      <View style={styles.inputContainer}>
        <TextInput
          onChangeText={handleChangeText}
          value={value}
          placeholder="Поиск..."
          style={styles.input}
        />
        <TouchableOpacity onPress={resetValue} style={styles.cancel}>
          <Cancel />
        </TouchableOpacity>
      </View>
    </View>
  );
}; 
