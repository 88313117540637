import axios from 'axios';
import { createAction } from 'redux-actions';

export const instance = axios.create({
  baseURL: 'https://psstver.ru',
  // withCredentials: false,
  headers: {"Content-Type": "application/json"}
});

// NOTE: ONLY GET
export const instanceMock = axios.create({
  baseURL: 'localhost',
  crossDomain: true,
});

export const createApiActions = (name) => ({
  request: createAction(`REQUEST_${name}`),
  success: createAction(`SUCCESS_${name}`),
  failure: createAction(`FAILURE_${name}`),
});
