import React from 'react';
import { Tests, Questions } from '../../screens/main';
import { createStackNavigator } from '@react-navigation/stack';
import blocks from '@data/blocks.js';
import { HeaderProfile } from '@components/organisms/headers';

const TestStack = createStackNavigator();

export const TestStackScreen = () => (
  <TestStack.Navigator
    initialRouteName="Tests"
    backBehavior="initialRoute"
    screenOptions={{
      headerShown: true,
      headerShadowVisible: false,
      headerStyle: {
        height: 60,
        borderBottomWidth: 0,
      },
      headerTitleStyle: {
        fontWeight: 'bold',
        // marginTop: 45,
      },
      headerTransparent: false,
      // headerTitleAlign: 'center',
      swipeEnabled: false,
      header: ({ navigation, route, options }) => {
        return <HeaderProfile welcome />;
      },
    }}
  >
    <TestStack.Screen
      name="Tests"
      //options={{ title: 'Тесты' }}
      options={({ route }) => ({ title: route.params.name || 'Тесты' })}
      component={Tests}
      initialParams={{ blocks, screen: 1, id: '76' }}
    />
    <TestStack.Screen
      name="Questions"
      options={({ route }) => ({ title: route.params.name || 'Блок' })}
      component={Questions}
      initialParams={{ code: '632', id: '76' }}
    />
  </TestStack.Navigator>
);
