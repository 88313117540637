import React from 'react';
import { View, StatusBar } from 'react-native';
import { styles } from './styles';

export const ModalTemplate = ({ children }) => (
  <>
    <StatusBar backgroundColor="transparent" />
    <View style={styles.root}>{children}</View>
  </>
);
