import React from 'react';
import {
  View,
  SafeAreaView,
  Image,
  Text,
  // StatusBar,
  useColorScheme,
  useWindowDimensions,
  // StyleSheet,
} from 'react-native';

import { Button } from '@components/atoms';
import { AuthTemplate } from '../../../components/templates/auth-template';
import Logo from '@images/logo.svg';
import translations from '@translations/ru.json';
import { styles } from './styles';

const texts = translations.authScreen;

const Colors = {
  white: '#fff',
  black: '#000',
  light: '#ddd',
  dark: '#333',
  lighter: '#eee',
  darker: '#111',
};

export const FirstScreen = ({ navigation }) => {
  const isDarkMode = useColorScheme() === 'dark';
  const { height, scale, width } = useWindowDimensions();

  const backgroundStyle = {
    flex: 1,
    backgroundColor: isDarkMode ? Colors.darker : Colors.lighter,
  };

  return (
    <AuthTemplate imageBackground height={height} width={width}>
      {/* <Header /> */}
      <View style={styles.container}>
        <Text style={styles.name}>ПромСтройСервис</Text>
        <View style={styles.logo}>
          <Logo />
        </View>

        <View style={styles.titleContainer}>
          <Text style={styles.title}>
            Бесплатная подготовка к тестированию по вопросам промышленной
            безопасности (тесты Ростехнадзора)
          </Text>
          <Text style={styles.titleFor}>
            для руководителей и специалистов организаций по промышленной
            безопасности 2022 год
          </Text>
        </View>
        <View style={styles.btns}>
          <Button
            onPress={() => navigation.navigate('SignIn')}
            title={texts.signIn}
            customStyles={styles.btn}
          />
          <Button
            onPress={() => navigation.navigate('SignUp')}
            light
            customStyles={styles.btnSignUp}
            title={texts.signUp}
          />
        </View>
      </View>
    </AuthTemplate>
  );
};
