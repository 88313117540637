import React, { useEffect, useState } from 'react';

import { View, Text, useWindowDimensions } from 'react-native';
import { connect } from 'react-redux';

import { TestStackScreen } from '../../lib/navigation/testStackScreen';
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
} from '@react-navigation/drawer';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { MainMenu, Profile, Favourites, Settings, Find, Questions } from './';

import { HeaderProfile } from '@components/organisms/headers';

import Home from '@components/atoms/tab-icon/Home';
import ProfileIcon from '@components/atoms/tab-icon/Profile';
import Test from '@components/atoms/tab-icon/Test';
import FavouritesIcon from '@components/atoms/tab-icon/Favourites';
import SettingsIcon from '@components/atoms/tab-icon/Settings';
import Logo from '@components/atoms/icons/Logo';
import FindIcon from '@components/atoms/tab-icon/Find';

import { styles } from './styles';

const { Navigator, Screen } = createDrawerNavigator();

// const Tab = createMaterialTopTabNavigator();
const FavouritesStack = createNativeStackNavigator();

const CustomDrawerContent = ({ isMobile, ...props }) => {
  return (
    <DrawerContentScrollView {...props}>
      {!isMobile && (
        <View style={styles.logo}>
          <Logo />
        </View>
      )}
      <DrawerItemList {...props} />
    </DrawerContentScrollView>
  );
};

const FavouritesStackScreen = () => (
  <FavouritesStack.Navigator
    initialRouteName="Favourites"
    backBehavior="initialRoute"
    screenOptions={{
      headerShown: true,
      headerShadowVisible: false,
      headerStyle: {
        height: 60,
        borderBottomWidth: 0,
      },
      headerTitleStyle: {
        fontWeight: 'bold',
        // marginTop: 45,
      },
      headerTransparent: false,
      // headerTitleAlign: 'center',
      swipeEnabled: false,
      header: ({ navigation, route, options }) => {
        return <HeaderProfile welcome />;
      },
    }}
  >
    <FavouritesStack.Screen
      name="Favourites"
      options={({ route }) => ({
        title: 'Избранное',
        headerShown: false,
      })}
      component={Favourites}
      // initialParams={{ code: '632', id: '76' }}
    />
    <FavouritesStack.Screen
      name="Questions"
      options={({ route }) => ({
        title: route.params.name || 'Тест по избранному',
      })}
      component={Questions}
      initialParams={{ code: '632', id: '76', mode: 'favourites' }}
    />
  </FavouritesStack.Navigator>
);

const MainView = ({ user, isLogin, displayName }) => {
  const { width, height } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const isSmall = width < 1070;
    setIsMobile(isSmall);
  }, [width]);

  return (
    <Navigator
      drawerContent={(props) => (
        <CustomDrawerContent
          isMobile={isMobile}
          style={{ width: isMobile ? 53 : 230 }}
          {...props}
        />
      )}
      backBehavior="none"
      defaultStatus="closed"
      initialRouteName="MainMenu"
      screenOptions={{
        drawerType: isMobile ? 'front' : 'permanent',
        //drawerType: 'slide',
        headerShown: false,
        drawerActiveTintColor: '#34A853',
        drawerActiveBackgroundColor: 'transparent',
        drawerItemStyle: { width: isMobile ? 100 : 220 },
        drawerStyle: {
          width: isMobile ? 58 : 235,
          height,
          paddingLeft: 0,
          backgroundColor: '#F3F3F3',
        },
        header: ({ navigation, route, options }) => {
          return (
            <HeaderProfile
              welcome
              details={!isMobile}
              displayName={displayName}
            />
          );
        },
      }}
    >
      <Screen
        name="MainMenu"
        component={MainMenu}
        options={{
          // headerShown: true,
          drawerIcon: ({ color, size }) => <Home color={color} />,
          drawerLabel: 'Главный экран',
        }}
      />
      <Screen
        name="TestStackScreen"
        component={TestStackScreen}
        options={{
          drawerIcon: ({ color, size }) => <Test color={color} />,
          drawerLabel: 'Тесты',
        }}
      />
      <Screen
        name="Profile"
        component={Profile}
        options={{
          headerShown: false,
          drawerIcon: ({ color, size }) => <ProfileIcon color={color} />,
          drawerLabel: 'Профиль',
        }}
      />
      <Screen
        name="Favourites"
        component={FavouritesStackScreen}
        options={{
          drawerIcon: ({ color, size }) => <FavouritesIcon color={color} />,
          drawerLabel: 'Избранное',
          // headerTitleAlign: 'center',
          // headerShadowVisible: false,
        }}
      />
      <Screen
        name="Settings"
        component={Settings}
        options={{
          headerShown: false,
          drawerIcon: ({ color, size }) => <SettingsIcon color={color} />,
          drawerLabel: 'Настройки',
          drawerLabelStyle: { marginLeft: -5 },
        }}
      />
      <Screen
        name="Search"
        component={Find}
        options={{
          // headerShown: true,
          drawerIcon: ({ color, size }) => (
            <View style={{ backgroundColor: '#4FAA9F' }}>
              <FindIcon />
            </View>
          ),
          drawerLabel: () =>
            isMobile ? null : (
              <Text
                style={{
                  color: '#FFFFFF',
                  // backgroundColor: 'pink',
                  marginLeft: -10,
                  marginRight: -15,
                }}
              >
                Поиск по вопросам
              </Text>
            ),
          drawerItemStyle: {
            width: isMobile ? 47 : 220,
            marginLeft: 6,
            paddingRight: -10,
            backgroundColor: '#4FAA9F',
            borderRadius: 25,
          },
        }}
      />
    </Navigator>
  );
};

const mapStateToProps = ({ user }) => ({
  user,
  isLogin: !!user.id,
  displayName: user.displayName,
});

export const Main = connect(mapStateToProps)(MainView);
