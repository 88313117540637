export default {
  76: [
    {
      code: "632",
      title: "А.1.",
      description: "Основы промышленной безопасности",
    },
  ],
  78: [
    {
      code: "20101",
      title: "Б.1.1.",
      description: "Эксплуатация химически опасных производственных объектов",
    },
    {
      code: "20102",
      title: "Б.1.2.",
      description:
        "Эксплуатация опасных производственных объектов нефтегазоперерабатывающих и нефтехимических производств",
    },
    {
      code: "20103",
      title: "Б.1.3.",
      description:
        "Эксплуатация опасных производственных объектов сжиженного природного газа",
    },
    {
      code: "20104",
      title: "Б.1.4.",
      description: "Эксплуатация хлорных объектов",
    },
    {
      code: "20105",
      title: "Б.1.5.",
      description: "Эксплуатация производств минеральных удобрений",
    },
    {
      code: "20106",
      title: "Б.1.6.",
      description: "Эксплуатация аммиачных холодильных установок",
    },
    {
      code: "20107",
      title: "Б.1.7.",
      description:
        "Эксплуатация опасных производственных объектов складов нефти и нефтепродуктов",
    },
    {
      code: "20108",
      title: "Б.1.8.",
      description: "Проектирование химически опасных производственных объектов",
    },
    {
      code: "20109",
      title: "Б.1.9.",
      description:
        "Строительство, реконструкция, техническое перевооружение, капитальный ремонт, консервация и ликвидация химически опасных производственных объектов",
    },
    {
      code: "20110",
      title: "Б.1.10.",
      description:
        "Проектирование, строительство, реконструкция, техническое перевооружение, капитальный ремонт, консервация и ликвидация опасных производственных объектов нефтегазоперерабатывающих и нефтехимических производств",
    },
    {
      code: "20111",
      title: "Б.1.11.",
      description: "Безопасное ведение газоопасных, огневых и ремонтных работ",
    },
    {
      code: "20112",
      title: "Б.1.12.",
      description:
        "Эксплуатация компрессорных установок с поршневыми компрессорами, работающими на взрывоопасных и вредных газах",
    },
    {
      code: "20113",
      title: "Б.1.13.",
      description:
        "Эксплуатация стационарных компрессорных установок, воздухопроводов и газопроводов",
    },
    {
      code: "20114",
      title: "Б.1.14.",
      description: "Производство водорода методом электролиза воды",
    },
    {
      code: "20115",
      title: "Б.1.15.",
      description:
        "Эксплуатация опасных производственных объектов, на которых осуществляются технологические процессы нитрования",
    },
    {
      code: "20116",
      title: "Б.1.16.",
      description:
        "Эксплуатация объектов маслоэкстракционных производств и производств гидрогенизации жиров",
    },
    {
      code: "20117",
      title: "Б.1.17.",
      description: "Производство и потребление продуктов разделения воздуха",
    },
    {
      code: "20118",
      title: "Б.1.18.",
      description:
        "Эксплуатация опасных производственных объектов производства шин, резинотехнических и латексных изделий",
    },
  ],
  79: [
    {
      code: "20201",
      title: "Б.2.1.",
      description: "Эксплуатация объектов нефтяной и газовой промышленности",
    },
    {
      code: "20202",
      title: "Б.2.2.",
      description: "Ремонт нефтяных и газовых скважин",
    },
    {
      code: "20203",
      title: "Б.2.3.",
      description: "Проектирование объектов нефтегазодобычи",
    },
    {
      code: "20204",
      title: "Б.2.4.",
      description: "Бурение нефтяных и газовых скважин",
    },
    {
      code: "20205",
      title: "Б.2.5.",
      description: "Нефтепромысловые трубопроводы для транспорта нефти и газа",
    },
    {
      code: "20206",
      title: "Б.2.6.",
      description:
        "Разведка и разработка морских месторождений углеводородного сырья",
    },
    {
      code: "20207",
      title: "Б.2.7.",
      description: "Магистральные нефтепроводы и нефтепродуктопроводы",
    },
    {
      code: "20208",
      title: "Б.2.8.",
      description: "Магистральные газопроводы",
    },
    {
      code: "20209",
      title: "Б.2.9.",
      description:
        "Магистральные трубопроводы для транспортировки жидкого аммиака",
    },
    {
      code: "20210",
      title: "Б.2.10.",
      description: "Подземные хранилища газа",
    },
  ],
  80: [
    {
      code: "20301",
      title: "Б.3.1.",
      description: "Литейное производство черных и цветных металлов",
    },
    {
      code: "20302",
      title: "Б.3.2.",
      description: "Медно-никелевое производство",
    },
    {
      code: "20303",
      title: "Б.3.3.",
      description: "Коксохимическое производство",
    },
    {
      code: "20304",
      title: "Б.3.4.",
      description: "Производство первичного алюминия",
    },
    {
      code: "20305",
      title: "Б.3.5.",
      description: "Производство редких, благородных и других цветных металлов",
    },
    {
      code: "20306",
      title: "Б.3.6.",
      description: "Доменное и сталеплавильное производство",
    },
    {
      code: "20307",
      title: "Б.3.7.",
      description: "Производство ферросплавов",
    },
    {
      code: "20308",
      title: "Б.3.8.",
      description: "Производство с полным металлургическим циклом",
    },
    {
      code: "20309",
      title: "Б.3.9.",
      description:
        "Проектирование, строительство, реконструкция, капитальный ремонт объектов металлургической промышленности",
    },
    {
      code: "20310",
      title: "Б.3.10.",
      description: "Энергетические службы металлургических предприятий",
    },
  ],
  81: [
    {
      code: "20401",
      title: "Б.4.1.",
      description: "Обогащение полезных ископаемых",
    },
    {
      code: "20402",
      title: "Б.4.2.",
      description:
        "Строительство, реконструкция, капитальный ремонт подземных сооружений",
    },
    {
      code: "20403",
      title: "Б.4.3.",
      description:
        "Разработка месторождений полезных ископаемых открытым способом",
    },
    {
      code: "20404",
      title: "Б.4.4.",
      description:
        "Разработка месторождений полезных ископаемых подземным способом",
    },
    {
      code: "20405",
      title: "Б.4.5.",
      description:
        "Проектирование опасных производственных объектов горной промышленности",
    },
  ],
  82: [
    {
      code: "20501",
      title: "Б.5.1.",
      description: "Разработка угольных месторождений открытым способом",
    },
    {
      code: "20502",
      title: "Б.5.2.",
      description: "Обогащение и брикетирование углей (сланцев)",
    },
    {
      code: "20503",
      title: "Б.5.3.",
      description: "Разработка угольных месторождений подземным способом",
    },
  ],
  83: [
    {
      code: "20601",
      title: "Б.6.1.",
      description:
        "Маркшейдерское обеспечение безопасного ведения горных работ при осуществлении работ, связанных с пользованием недрами и их проектированием",
    },
    {
      code: "20602",
      title: "Б.6.2.",
      description:
        "Маркшейдерское обеспечение безопасного ведения горных работ при осуществлении пользования недрами в целях, не связанных с добычей полезных ископаемых, а также строительства и эксплуатации гидротехнических сооружений",
    },
    {
      code: "20603",
      title: "Б.6.3.",
      description:
        "Маркшейдерское обеспечение безопасного ведения горных работ при осуществлении разработки месторождений полезных ископаемых подземным способом",
    },
    {
      code: "20604",
      title: "Б.6.4.",
      description:
        "Маркшейдерское обеспечение безопасного ведения горных работ при осуществлении разработки месторождений полезных ископаемых открытым способом",
    },
    {
      code: "20605",
      title: "Б.6.5.",
      description:
        "Маркшейдерское обеспечение безопасного ведения горных работ при осуществлении разработки месторождений углеводородного сырья и гидроминеральных ресурсов",
    },
  ],
  84: [
    {
      code: "692",
      title: "Б.7.1.",
      description: "Эксплуатация сетей газораспределения и газопотребления",
    },
    {
      code: "694",
      title: "Б.7.2.",
      description:
        "Эксплуатация объектов, использующих сжиженные углеводородные газы",
    },
    {
      code: "696",
      title: "Б.7.3.",
      description:
        "Проектирование, строительство, реконструкция, техническое перевооружение и капитальный ремонт сетей газораспределения и газопотребления",
    },
    {
      code: "698",
      title: "Б.7.4.",
      description:
        "Эксплуатация автогазозаправочных станций газомоторного топлива",
    },
  ],
  85: [
    {
      code: "699",
      title: "Б.8.1.",
      description: "Эксплуатация ОПО, на которых используются котлы",
    },
    {
      code: "700",
      title: "Б.8.2.",
      description:
        "Эксплуатация ОПО, на которых используются трубопроводы пара и горячей воды",
    },
    {
      code: "701",
      title: "Б.8.3.",
      description:
        "Эксплуатация ОПО, на которых используются сосуды, работающие под избыточным давлением",
    },
    {
      code: "702",
      title: "Б.8.4.",
      description:
        "Эксплуатация ОПО, на которых используются медицинские и водолазные барокамеры",
    },
    {
      code: "703",
      title: "Б.8.5.",
      description:
        "Наполнение, техническое освидетельствование и ремонт баллонов для хранения и транспортирования сжатых, сжиженных и растворенных под давлением газов",
    },
    {
      code: "704",
      title: "Б.8.6.",
      description:
        "Проектирование, строительство, реконструкция, капитальный ремонт и техническое перевооружение ОПО, изготовление, монтаж (демонтаж), наладка, обслуживание и ремонт (реконструкция) оборудования, работающего под избыточным давлением",
    },
  ],
  86: [
    {
      code: "709",
      title: "Б.9.1.",
      description:
        "Эксплуатация и капитальный ремонт ОПО, на которых используются эскалаторы в метрополитенах, эксплуатация (в том числе обслуживание и ремонт) эскалаторов в метрополитенах",
    },
    {
      code: "1000",
      title: "Б.9.2.",
      description:
        "Проектирование, строительство, реконструкция, техническое перевооружение, консервация и ликвидация ОПО, на которых используются эскалаторы в метрополитенах, а также изготовление, монтаж и наладка эскалаторов",
    },
    {
      code: "710",
      title: "Б.9.3.",
      description:
        "Эксплуатация ОПО, на которых используются подъемные сооружения, предназначенные для подъема и перемещения грузов",
    },
    {
      code: "711",
      title: "Б.9.4.",
      description:
        "Эксплуатация ОПО, на которых используются подъемные сооружения, предназначенные для подъема и транспортировки людей",
    },
    {
      code: "712",
      title: "Б.9.5.",
      description:
        "Проектирование, строительство, реконструкция, техническое перевооружение, капитальный ремонт, консервация, ликвидация ОПО, на которых используются подъемные сооружения",
    },
    {
      code: "714",
      title: "Б.9.6.",
      description:
        "Монтаж, наладка, обслуживание, ремонт, реконструкция или модернизация подъемных сооружений, применяемых на ОПО",
    },
    {
      code: "1001",
      title: "Б.9.7.",
      description:
        "Эксплуатация и капитальный ремонт ОПО, на которых используются пассажирские канатные дороги и (или) фуникулеры, эксплуатация (в том числе обслуживание и ремонт) пассажирских канатных дорог и (или) фуникулеров",
    },
    {
      code: "1002",
      title: "Б.9.8.",
      description:
        "Проектирование, строительство, реконструкция, техническое перевооружение, консервация и ликвидация ОПО, на которых используются пассажирские канатные дороги и (или) фуникулеры, а также изготовление, монтаж и наладка пассажирских канатных дорог и (или) фуникулеров",
    },
    {
      code: "1003",
      title: "Б.9.9.",
      description:
        "Эксплуатация и капитальный ремонт ОПО, на которых используются грузовые подвесные канатные дороги, эксплуатация (в том числе обслуживание и ремонт) грузовых подвесных канатных дорог",
    },
    {
      code: "1004",
      title: "Б.9.10.",
      description:
        "Проектирование, строительство, реконструкция, техническое перевооружение, консервация и ликвидация ОПО, на которых используются грузовые подвесные канатные дороги, а также изготовление, монтаж и наладка грузовых подвесных канатных дорог",
    },
  ],
  87: [
    {
      code: "21001",
      title: "Б.10.1.",
      description:
        "Транспортирование опасных веществ железнодорожным транспортом",
    },
    {
      code: "21002",
      title: "Б.10.2.",
      description:
        "Транспортирование опасных веществ автомобильным транспортом",
    },
  ],
  88: [
    {
      code: "21101",
      title: "Б.11.1.",
      description:
        "Строительство, эксплуатация, реконструкция, капитальный ремонт, техническое перевооружение, консервация и ликвидация объектов хранения и переработки растительного сырья",
    },
    {
      code: "21102",
      title: "Б.11.2.",
      description:
        "Разработка проектной, конструкторской и иной документации для опасных объектов хранения и переработки растительного сырья",
    },
    {
      code: "21103",
      title: "Б.11.3.",
      description:
        "Изготовление, монтаж, наладка, ремонт, техническое освидетельствование, реконструкция и эксплуатация технических устройств (машин и оборудования), применяемых на объектах хранения и переработки растительного сырья",
    },
  ],
  89: [
    {
      code: "21201",
      title: "Б.12.1.",
      description:
        "Взрывные работы в подземных выработках и на поверхности рудников (объектах горнорудной и нерудной промышленности), угольных и сланцевых шахт, опасных (не опасных) по газу или пыли, и специальные взрывные работы",
    },
    {
      code: "21202",
      title: "Б.12.2.",
      description:
        "Взрывные работы на открытых горных разработках и специальные взрывные работы",
    },
  ],
};
