import { createStore, applyMiddleware, combineReducers, compose } from 'redux';

import { persistStore, persistReducer } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';

import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { promises } from './promises';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as reducers from './reducers';

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (action.type === 'RESET_DATA') {
    return appReducer({ favourites: state.favourites }, action);
  }

  return appReducer(state, action);
};

const middlewares = [promises, thunk];

/*if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}
*/
// const composed = compose(applyMiddleware(...middlewares));

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const enhancer = composeWithDevTools({})(applyMiddleware(...middlewares));

const configureStore = () => {
  const store = createStore(persistedReducer, enhancer);
  const persistor = persistStore(store);
  // persistor.purge();

  return { store, persistor };
};
export default configureStore;
