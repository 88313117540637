import React, { useState, useEffect, useRef } from 'react';
import {
  FlatList,
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { GridTemplate } from '@components/templates';
import { FindQuestion } from '@components/organisms';
import { HeaderTestFlow, HeaderProfile } from '@components/organisms/headers';
import { ModeFlow } from '@components/modals/mode-flow';
import _ from 'lodash';
import * as actions from '@actions';
import blocks from '@data/blocks.js';
import data from '@data/questions';
import theme from '@const/theme';
import ArrowButton from '@images/icons/arrowButton.svg';
import Checked from '@images/icons/check.svg';
import { styles } from './styles';

const FindView = ({ route, navigation, fontSize }) => {
  const scrollRef = useRef();

  const { width, height } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(width < 800);
  const [results, setResults] = useState([]);
  const [findQuestion, setFindQuestion] = useState('');
  const [questions, setQuestions] = useState([]);
  const [filter, setFilter] = useState('все блоки');
  const [filters, setFilters] = useState([]);
  const [isOpenedFilters, toggleFilters] = useState(false);

  useEffect(() => {
    let newQuestions = [];
    // console.log('filters->', filters);
    //-- создание списка вопросов с фильтрацией по блокам
    _.forEach(data, (value, key) => {
      if (!filters.length || filters.includes(key)) {
        _.forEach(value, (item) => {
          newQuestions = [...newQuestions, ...item].map((item) => ({
            text: item.text,
            correctAnswers: item.correctAnswers,
          }));
        });
      }
    });
    // console.log('questionsLength ->', newQuestions.length);
    setQuestions(newQuestions);
  }, [data, filters]);

  // -- если поменялась ширина экрана
  useEffect(() => {
    setIsMobile(width < 1070);
  }, [width]);

  //-- поиск текста в вопросах если более 2-х символов
  useEffect(() => {
    if (findQuestion.length > 2) {
      // console.log('findQuestion!');
      const newResults = questions.filter((item) =>
        item.text.toLowerCase().includes(findQuestion.toLowerCase()),
      );
      setResults(newResults);
    } else {
      // console.log('reset!');
      results.length && setResults([]);
    }
  }, [findQuestion, questions]);

  useEffect(() => {
    // console.log(JSON.stringify(results, null, 4));
  }, [results]);

  const renderItem = ({ item, index }) => (
    <TouchableOpacity
      key={index}
      style={styles.testContainer}
      onPress={() => {}}
    >
      <Text style={[styles.titleTest, styles.titleDetail, { fontSize }]}>
        {item.text}
      </Text>
      {item.correctAnswers.map((answer) => (
        <View style={styles.answersContainer}>
          <View style={{ flex: 1, alignItems: 'center' }}>
            <Checked />
          </View>
          <View style={{ flex: 6 }}>
            <Text style={{ fontSize }}>{answer}</Text>
          </View>
        </View>
      ))}
    </TouchableOpacity>
  );

  return (
    <View style={styles.root}>
      <HeaderProfile welcome />
      <HeaderTestFlow leftTitles={['Поиск']} />`
      <View
        style={[
          { alignSelf: 'center', height: height - 180 },
          isMobile ? { alignSelf: 'stretch', paddingHorizontal: 20 } : { alignSelf: 'center', width: 600 },
        ]}
      >
        <FindQuestion
          style={{ marginHorizontal: 0 }}
          value={findQuestion}
          handleChangeText={setFindQuestion}
          resetValue={() => setFindQuestion('')}
        />
        <View style={styles.filter}>
          <TouchableOpacity
            style={[styles.filterBtn, styles.container]}
            activeOpacity={0.8}
            onPress={() => toggleFilters(!isOpenedFilters)}
          >
            {!filters.length ? (
              <Text style={styles.filterText}>все блоки</Text>
            ) : (
              <Text style={styles.filterText}>
                {
                  blocks.find((block) => block.id.toString() === filters[0])
                    ?.title
                }
              </Text>
            )}
            <View
              style={[
                isOpenedFilters
                  ? {
                      transform: [{ rotate: '180deg' }],
                    }
                  : {},
              ]}
            >
              <ArrowButton />
            </View>
          </TouchableOpacity>
          {isOpenedFilters && (
            <View style={styles.filterModal}>
              <ScrollView
                indicatorStyle={theme.indicatorStyle}
                style={styles.filterModalScroll}
                contentContainerStyle={styles.filterValuesList}
              >
                <TouchableOpacity
                  activeOpacity={0.8}
                  onPress={() => {
                    setFilters([]);
                    toggleFilters(false);
                  }}
                >
                  <GridTemplate>
                    <Text style={[styles.fitlerValue]}>все блоки</Text>
                  </GridTemplate>
                </TouchableOpacity>
                {blocks.map((item) => (
                  <TouchableOpacity
                    activeOpacity={0.8}
                    onPress={() => {
                      toggleFilters(false);
                      setFilters([item.id.toString()]);
                    }}
                  >
                    <GridTemplate>
                      <Text style={[styles.fitlerValue]}>{item.title}</Text>
                    </GridTemplate>
                  </TouchableOpacity>
                ))}
              </ScrollView>
            </View>
          )}
        </View>
        <FlatList
          ref={scrollRef}
          data={results}
          renderItem={renderItem}
          initialNumToRender={5}
          maxToRenderPerBatch={5}
          keyExtractor={(item, index) => index}
          removeClippedSubviews={true}
          windowSize={5}
          /* getItemLayout={(data, index) => ({
          length: 80,
          offset: 80 * index,
          index,
        })} */
          showsVerticalScrollIndicator={false}
          updateCellsBatchingPeriod={100}
          // style={{ backgroundColor: 'pink' }}
        />
      </View>
    </View>
  );
};

const mapStateToProps = ({ results, settings: { fontSize } }) => ({
  testResults: results,
  fontSize,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetResults: actions.resetResults,
    },
    dispatch,
  );

export const Find = connect(mapStateToProps, mapDispatchToProps)(FindView);
